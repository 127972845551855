import { filterTables, lazyFetch } from "../../../../utils";

export const fetchDataAPI = {
  all: [
    {
      function: lazyFetch,
      tableName: "projects",
      resultKey: "projects",
      listOfKeys: ["projectId", "projectName"],
    },
    {
      function: lazyFetch,
      tableName: "scheduling",
      resultKey: "schedules",
      listOfKeys: ["scheduleId", "scheduleAddress"],
    },
  ],
  Claims: [
    {
      function: lazyFetch,
      tableName: "accounts",
      resultKey: "accounts",
      filterKey: "accountRecordType",
      filterValue: "Subcontractors",
      listOfKeys: ["accountId", "accountName"],
    },
  ],
  Incidents: [
    {
      function: lazyFetch,
      tableName: "drivers",
      resultKey: "drivers",
      listOfKeys: ["driverId", "driverName"],
    },
  ],
  Violations: [
    {
      function: lazyFetch,
      tableName: "drivers",
      resultKey: "drivers",
      listOfKeys: ["driverId", "driverName"],
    },
    {
      function: lazyFetch,
      tableName: "fleetFields",
      resultKey: "fleetFields",
    },
  ],
  Opportunities: [
    {
      function: lazyFetch,
      tableName: "opportunities",
      resultKey: "opportunities",
      listOfKeys: ["opportunityId", "opportunityAddress"],
    },
    {
      function: lazyFetch,
      tableName: "drivers",
      resultKey: "drivers",
      listOfKeys: ["peId", "firstName", "lastName"],
    },
  ],
};
