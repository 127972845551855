import { ClockCircleOutlined } from "@ant-design/icons";
import { Alert, Button, message, Modal, Space, Timeline } from "antd";
import { API } from "aws-amplify";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { CheckIconModal } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import { useRedux } from "../../../../../../SidebarPages/hooks";
import DifferencesFinderWrapper from "../../../../ProposalSidebar/components/DifferencesFinder/DifferencesFinderWrapper";
import "./ProposalAssistant.scss";
const alertStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  // width: "500px",
};

function ProposalAssitant({ visible, closeModal, editorRef = "" }) {
  const [proposalData, setProposalData] = useRedux("proposalData");
  const [issues, setIssues] = useState({
    missingServices: [],
    appliedServices: [],
    takeOffServices: [],
  });

  const onCancel = () => {
    closeModal();
  };

  const findMissingTemplates = async (matches) => {
    let takeOffServices = proposalData?.takeOff?.services || [];
    await API.get("proposalTemplates", "/proposalTemplates").then(
      (response) => {
        let filteredServices = response.filter((service) => {
          console.log("service", { service, matches });
          return matches.includes(service.templateId);
        });
        // let missingServices = takeOffServices.filter((service) => {

        // });
        let missingServices = takeOffServices.filter((service) => {
          return !filteredServices.find(
            (filteredService) =>
              filteredService.additionalDetails?.service?.workId?.toString() ===
              service.serviceId?.toString()
          );
        });
        console.log("filteredServices", {
          filteredServices,
          takeOffServices,
          missingServices,
        });
        setIssues({
          ...issues,
          missingServices,
          appliedServices: filteredServices,
          takeOffServices,
        });
        console.log("response", { response, matches });
      }
    );
  };

  const onApplyLegacyTemplates = async () => {
    console.log("applyLegacyTemplates", { issues, proposalData, editorRef });
    editorRef.current.component.selection.insertHTML(
      "<p>This is inserted HTML content</p>"
    );
    Swal.fire({
      title: "Are you sure?",
      text: "This will apply legacy templates to the services",
      icon: "warning",
    });
  };

  useEffect(() => {
    let wrappedHTML = proposalData?.parentTemplateContent || "";
    let pattern = /\{\{([^_]+_[^}]+)\}\}/g;
    let matches =
      wrappedHTML.match(pattern)?.map((e) => {
        let parts = e.replace(/[{}]/g, "").split("_");
        console.log("parts", { parts });
        return parts[parts.length - 1];
      }) || [];
    findMissingTemplates(matches);
    console.log("matches", { matches, proposalData });
  }, [proposalData]);

  console.log("ProposalAssitantModal", proposalData);
  return (
    <div className="proposalAssistantModalWrapper">
      {/* <DifferencesFinderWrapper /> */}
      <Timeline
        className="proposalAssistantTimeline"
        items={[
          {
            children: (
              <div className="alertAssistantProposalWrapper">
                <Alert
                  style={alertStyle}
                  message="1. Pick a viable Estimate"
                  type="success"
                  description="The estimate should be viable and should have all the necessary details filled by Engineers, Architects and Account Managers."
                  showIcon
                />
              </div>
            ),
            color: "green",
          },
          {
            children: (
              <div className="alertAssistantProposalWrapper">
                <Alert
                  style={alertStyle}
                  message="2. Make sure all the services are applied"
                  description="All the services should be applied to the estimate. If any service is missing, it will be shown in the list below."
                  // description="Detailed description and advice about successful copywriting."
                  type="success"
                  showIcon
                />
              </div>
            ),
            color: "green",
          },

          {
            color: issues.missingServices.length > 0 ? "red" : "green",
            children: (
              <div className="alertAssistantProposalWrapper">
                <Alert
                  style={alertStyle}
                  message={
                    issues.missingServices.length > 0
                      ? "3. Missing Services Templates"
                      : "3. All Services Applied"
                  }
                  type={issues.missingServices.length > 0 ? "error" : "success"}
                  showIcon
                  action={
                    <MondayButton
                      className="mondayButtonBlue"
                      onClick={onApplyLegacyTemplates}
                      Icon={<CheckIconModal />}
                    >
                      Apply Legacy Templates
                    </MondayButton>
                  }
                  description={
                    issues.missingServices.length > 0
                      ? `Some services are missing templates. Please apply the templates to the services: ${issues.missingServices
                          .map((e) => {
                            console.log("e", e);
                            return e?.label || "test";
                          })
                          .join(", ")}`
                      : "All services have templates applied."
                  }
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default ProposalAssitant;
