export const secondaryIdentifiers = [
  "#",
  "apt",
  "apartment",
  "bldg",
  "building",
  "fl",
  "floor",
  "ste",
  "suite",
  "unit",
  "rm",
  "room",
  "dept",
];

export const splitKeys = {
  billing: {
    aptNumber: "aptNumber",
    city: "billingCity",
    state: "billingState",
    street: "billingStreet",
    zipCode: "billingZip",
  },
  shipping: {
    aptNumber: "shippingAddApartmentNr",
    city: "shippingCity",
    state: "shippingState",
    street: "shippingStreet",
    zipCode: "shippingZip",
  },
  opportunity: {
    city: "opportunityAddCity",
    state: "opportunityAddState",
    street: "opportunityAddStreet",
    // zipCode: "opportunityZip",
  },
  project: {
    city: "projectAddCity",
    state: "projectAddState",
    street: "projectAddStreet",
    zipCode: "projectAddPostalCode",
  },
};
