import "./Switcher.scss";
import { Switch, Tooltip } from "antd";

const Switcher = ({ label, status, onClick = () => {} }) => {
  return (
    <div
      className={`switcher-container switcher-container-${status}`}
      style={{ background: status ? "#01c06a" : "#fe4c4a" }}
    >
      <Tooltip title={label}>
        <span className="switch-label">{label}</span>
      </Tooltip>

      <div className={`switcher switcher-${status}`}>
        <Switch onClick={onClick} checked={status} />
      </div>
    </div>
  );
};

export default Switcher;
