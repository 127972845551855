import { checkPageSize } from "../../../pages/Settings/settingsComponents/ReportTemplates/utils";

const pageOrientation = {
  Portrait: { width: "8.5in", height: "11in" },
  Landscape: { width: "11in", height: "8.5in" },
};

export const attachCurrentTemplateKeys = (reportObj, template) => {
  if (reportObj && Object.keys(template).length > 0) {
    const width = pageOrientation[template?.pageOrientation].width;
    const height = pageOrientation[template?.pageOrientation].height;

    return {
      ...reportObj,
      definition: {
        ...reportObj.definition,
        EmbeddedImages: [
          ...(reportObj?.definition?.EmbeddedImages || []),
          ...(template?.EmbeddedImages || []),
        ],
        ReportSections: reportObj.definition.ReportSections.map((section) => ({
          ...section,
          Page: {
            ...section.Page,
            PageWidth: width || section.Page.PageWidth,
            PageHeight: height || section.Page.PageHeight,
          },
          PageFooter: {
            ...template.PageFooter,
            Width: width || section.Page.PageWidth,
          },
          PageHeader: {
            ...template.PageHeader,
            Width: width || section.Page.PageWidth,
          },
        })),
      },
    };
  }

  return reportObj;
};

export const attachCurrentTemplate = (report, template) => {
  if (report && template) {
    return attachCurrentTemplateKeys(report, template);
  }

  return report?.definition;
};

export const attachTemplateKeys = (reportObj, template) => {
  const reportSections = reportObj?.definition?.ReportSections;
  if (
    reportObj &&
    Object.keys(template).length > 0 &&
    reportSections?.length > 0
  )
    return {
      ...reportObj,
      definition: {
        ...reportObj.definition,
        EmbeddedImages: [
          ...(reportObj?.definition?.EmbeddedImages || []),
          ...(template?.EmbeddedImages || []),
        ],
        ReportSections: reportSections.map((section) => ({
          ...section,
          PageFooter: {
            ...(template?.PageFooter || {}),
            Width: section.Page.PageWidth,
          },
          PageHeader: {
            ...(template?.PageHeader || {}),
            Width: section.Page.PageWidth,
          },
        })),
      },
    };

  return reportObj;
};

export const attachTemplate = (report, templates, isTemplate) => {
  if (report && templates) {
    const { reportObj } = report;

    const pageOrientation = checkPageSize(
      reportObj?.definition?.ReportSections?.[0]?.Page?.PageWidth || 0
    );
    const defaultTemplate = templates.find(
      (template) => template.pageOrientation === pageOrientation
    );

    if (defaultTemplate) {
      return {
        ...report,
        reportObj: attachTemplateKeys(reportObj, defaultTemplate),
      };
    }

    return report;
  }

  return report;
};
