/**
 * Checks if a given form item name corresponds to a date input field.
 *
 * @param {string} formItemName - The name of the form item to check.
 * @param {Array} fields - An array of fields to search for the form item name.
 * @returns {boolean} - Returns true if the form item name corresponds to a date input field, otherwise false.
 */
function checkDateInput(formItemName = "", fields = []) {
  let dynamicField = fields?.find(
    ({ formItemName: fN }) => fN === formItemName
  );
  if (dynamicField) {
    return dynamicField?.type?.toLowerCase() === "dateinput";
  }
  return false;
}

export default checkDateInput;
