import { footerDataGenerator } from "./serviceFooterFunctions";
import { formatCurrency } from "../../../../../../../../utils";
import { useStateContext } from "../../../../Context/Context";
import "./ServiceFooter.scss";

import AnimatedNumber from "react-animated-number";

const ServiceFooter = ({ service }) => {
  const { darkMode } = useStateContext();

  //here we extract the data from footerDataGenerator function
  const {
    totalThisPeriod,
    totalFromPrevious,
    totalCompleated,
    collectableAmount,
    retainageBalance = 0,
    paymentDue,
  } = footerDataGenerator({ service }) || {};

  return (
    <div
      className={
        darkMode
          ? "application-content-footer-dark"
          : "application-content-footer-light"
      }
    >
      <div className="footerSectionContainer">
        <div className="label">Previous requisition:</div>
        <div
          className={
            totalFromPrevious === 0
              ? "amount-equal-with-0"
              : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={totalFromPrevious}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>
      <div className="footerSectionContainer">
        <div className="label">This period:</div>
        <div
          className={
            totalThisPeriod === 0 ? "amount-equal-with-0" : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={totalThisPeriod}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>
      <div className="footerSectionContainer">
        <div className="label">Completed and stored:</div>
        <div
          className={
            totalCompleated === 0 ? "amount-equal-with-0" : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={totalCompleated}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>
      {/* <div className="footerSectionContainer">
        <div className="label">Collectable amount:</div>
        <div
          className={
            collectableAmount <= 0
              ? "amount-equal-with-0"
              : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={collectableAmount}
            formatValue={(value) => formatCurrency(value > 0 ? value : 0)}
          />
        </div>
      </div> */}
      <div className="footerSectionContainer">
        <div className="label">Payment Due:</div>
        <div
          className={
            paymentDue <= 0 ? "amount-equal-with-0" : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={paymentDue}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>
      <div className="footerSectionContainer">
        <div className="label">Retainage Balance</div>
        <div
          className={
            retainageBalance <= 0 ? "amount-equal-with-0" : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={retainageBalance}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceFooter;
