import ScheduleDay from "../../../Scheduling/models/ScheduleDay";

/**
 * Handles the selection of a schedule.
 *
 * @param {string} val - The value of the selected schedule.
 * @param {boolean} [clear=false] - Indicates whether to clear the selected team and fields.
 * @param {Array} [schedules=[]] - The list of schedules.
 * @param {Function} [setFieldsValue=()=>{}] - The function to set field values.
 * @param {Function} [setSelectedTeam=()=>{}] - The function to set the selected team.
 * @param {Function} [dispatchRequestData=()=>{}] - The function to dispatch request data.
 */
const onScheduleSelect = ({
  val,
  clear = false,
  schedules = [],
  setFieldsValue = () => {},
  setSelectedTeam = () => {},
  dispatchRequestData = () => {},
}) => {
  if (clear === true) {
    setSelectedTeam([]);

    setFieldsValue({
      projectExecutive: "",
      typeOfWork: "",
      projectManager: [],
      client: "",
      team: [],
    });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: null,
    });
  } else {
    const {
      accountId = "",
      projectId = "",
      typeOfWork = "",
      scheduleDays = [],
      scheduleName = "",
      toBeScheduled = "",
      scheduleAddress = "",
      projectExecutive = "",
      teamsConfiguration = [],
      projectManagers: projectManager = [],
    } = (schedules || [])?.find(({ scheduleId }) => scheduleId === val) || {};

    const newScheduleDays = scheduleDays.map((el) =>
      Object.assign(new ScheduleDay(), el)
    );

    setFieldsValue({
      projectExecutive,
      typeOfWork,
      projectManager,
      client: accountId,
      team: teamsConfiguration.map((team) => ({
        value: team?.value,
      })),
    });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        projectId,
        scheduleName,
        scheduleId: val,
        recordId: val,
        toBeScheduled,
        scheduleAddress,
        scheduleDays: newScheduleDays,
        recordName: `${scheduleName} - ${scheduleAddress}`,
      },
    });
  }
};

export default onScheduleSelect;
