import { preventDuplicate } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { formatCurrency, getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";
import {
  costChartData,
  getProjectCostData,
} from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const projectCost = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  jobsites
) => {
  const data = updatedDynamicStates?.[IdHandler] || [];

  return {
    companyEmployees: () => ({
      title: "Number of Employees by Company",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Project Cost",
        field: "companyEmployees",
        labels: uniq(data?.map((a) => a.company)),
        datasets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                preventDuplicate(data, "employeeId"),
                "company"
              )
            ),
            backgroundColor: uniq(data?.map((a) => a.company))?.map(() =>
              getRandomColor()
            ),
            borderWidth: 1,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    jobsiteAddress: () => ({
      title: "Number of Employees by Jobsite Address",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Project Cost",
        field: "jobsiteAddress",
        labels: uniq(data?.map((a) => a?.jobsiteMatch?.jobAddress))?.filter(
          Boolean
        ),
        dataSets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                preventDuplicate(data, "employeeId"),
                "jobsiteId"
              )
            ),
            backgroundColor: uniq(
              data?.map((a) => a?.jobsiteMatch?.jobAddress)
            )?.map(() => getRandomColor()),
          },
        ],
      },
      width: "790px",
    }),

    punchType: () => ({
      title: "Number of Employees by Punch Type",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Project Cost",
        field: "punchType",
        labels: uniq(data?.map((a) => a?.punchType))?.filter(Boolean),
        dataSets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                preventDuplicate(data, "employeeId"),
                "punchType"
              )
            ),
            backgroundColor: uniq(data?.map((a) => a?.punchType))
              ?.filter(Boolean)
              ?.map(() => getRandomColor()),
          },
        ],
      },
      width: "790px",
    }),

    role: () => ({
      title: "Number of Employees by Role",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Project Cost",
        field: "role",
        labels: uniq(data?.map((a) => a?.role))?.filter(Boolean),
        dataSets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(preventDuplicate(data, "employeeId"), "role")
            ),
            backgroundColor: uniq(data?.map((a) => a?.role))
              ?.filter(Boolean)
              ?.map(() => getRandomColor()),
          },
        ],
      },
      width: "790px",
    }),

    jobsiteTotalCost: () => {
      const costDispersion = getProjectCostData({ data, jobsites });

      const dataSets = costChartData(
        costDispersion,
        "jobsiteAddress",
        "total",
        formatCurrency
      );

      return {
        title: "Total Cost by Jobsite",
        BodyComponent: "BarChart",
        type: "BarChart",
        id: IdHandler,
        BodyComponentParams: {
          table: "Project Cost",
          field: "jobsiteTotalCost",
          labels: dataSets?.labels,
          dataSets: [
            {
              label: "My First Dataset",
              data: dataSets?.data,
              backgroundColor: dataSets?.backgroundColor,
            },
          ],
        },
        width: "790px",
      };
    },

    jobsiteTotalHours: () => {
      const costDispersion = getProjectCostData({ data, jobsites });

      const dataSets = costChartData(
        costDispersion,
        "jobsiteAddress",
        "totalWorkHours"
      );

      return {
        title: "Total Work Hours by Jobsite",
        BodyComponent: "BarChart",
        type: "BarChart",
        id: IdHandler,
        BodyComponentParams: {
          table: "Project Cost",
          field: "jobsiteTotalHours",
          labels: dataSets?.labels,
          dataSets: [
            {
              label: "My First Dataset",
              data: dataSets?.data,
              backgroundColor: dataSets?.backgroundColor,
            },
          ],
        },
        width: "790px",
      };
    },
  };
};
