export const applications = (
  circleProgressPicker,
  IdHandler,
  updatedDynamicStates,
  applicationsData
) => {
  return {
    applicationStatus: () => ({
      title: "Requisition - Requisition Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Applications",
        field: "applicationStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (applicationsData?.filter(
            (a) => a?.applicationStatus === circleProgressPicker
          )?.length /
            applicationsData?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: applicationsData?.filter(
          (a) => a?.applicationStatus === circleProgressPicker
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //applicationStatus
  };
};
