export const leads = ({ tables, circleProgressPicker }) => {
  return {
    leadStatus: () => ({
      percent: (
        (tables?.Leads?.filter((a) => a?.leadStatus === circleProgressPicker)?.length /
          tables?.Leads?.length) *
        100
      )?.toFixed(1),
    }), //leadStatus
    leadAddress: () => {
      return {
        percent: (
          (tables?.Leads?.filter(
            (a) => a?.borough?.toLocaleLowerCase() === circleProgressPicker.toLocaleLowerCase()
          )?.length /
            tables?.Leads?.length) *
          100
        )?.toFixed(1),
      }
    },
  }
}
