import uniq from "lodash/uniq";
import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const workOrders = (
  workOrdersData,
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  return {
    workOrderStatus: () => ({
      title: "Work Order Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Work Orders",
        field: "workOrderStatus",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (a) => a?.workOrderStatus
          )
        ),
        datasets: [
          {
            label: "Work Orders",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (a) => a?.workOrderStatus
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a.workOrderStatus?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    fleetName: () => ({
      title: "Work Orders: Fleet Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Work Orders",
        field: "fleetName",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.fleetName)
        ),
        datasets: [
          {
            label: "Work Orders",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (a) => a?.fleetName
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    mechanicName: () => ({
      title: "Work Orders: Mechanic Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Work Orders",
        field: "mechanicName",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (a) => a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
          )
        ),
        datasets: [
          {
            label: "Work Orders",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (a) =>
                  a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    (
                      a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
                    )?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    dueDate: () => ({
      title: "Work Orders: DueDate",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Work Orders",
        field: "dueDate",
        labels: months,
        datasets: [
          {
            label: "Work Order",
            data: (() => {
              let data = [];
              for (let i = 0; i < 12; i++) {
                data = [
                  ...data,
                  (updatedDynamicStates?.[IdHandler] || [])
                    ?.map((a) => a?.dueDate)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length,
                ];
              }
              return data;
            })(),
            backgroundColor: months.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
