import { useState } from "react";

import { PlusCircleOutlined } from "@ant-design/icons";
import { FloatButton, Modal } from "antd";
import ProposalStepper from "./ProposalStepper/ProposalStepper";
import { NormalSizedModal } from "../../SidebarPages/Fleet/components";
const INITIAL_PROPOSAL_STATE = {
  modalVisible: false,
  hasAcces: true,
  modalTitle: "New Proposal",
};

function NewProposal() {
  const [controls, setControls] = useState(INITIAL_PROPOSAL_STATE);

  const initializeModalEntry = () => {
    setControls((prev) => ({
      ...prev,
      modalVisible: true,
    }));
  };

  const onCancelModalCleanUp = () => {
    setControls((prev) => ({
      ...prev,
      modalVisible: false,
    }));
  };

  return (
    <div className="new-proposal-wrapper">
      <div className="new-proposal">
        <div className="new-proposal__floating-btn">
          {" "}
          <FloatButton
            className="new-proposal-float-btn"
            icon={<PlusCircleOutlined />}
            onClick={initializeModalEntry}
          />
        </div>
        <div className="new-proposal-modal-wrapper">
          {controls.modalVisible && (
            <NormalSizedModal
              title={controls.modalTitle || ""}
              open={controls.modalVisible}
              maskClosable={false}
              onCancel={onCancelModalCleanUp}
              className="new-proposal-modal"
              width={1800}
              destroyOnClose
              centered
              closable={true}
              customFooter={<div></div>}
            >
              <div className="new-proposal-modal-headerStepper">
                <ProposalStepper />
              </div>
            </NormalSizedModal>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewProposal;
