import { secondaryIdentifiers } from "../components/SidebarPages/Fleet/utils/secondaryIdentifiers";

//THE CONSTRUCTOR METHOD TAKES AN ADDRESS AND A DEFAULT APT NUMBER
//THE MOMENT WE SELECT AN ADDRESS, IT NEEDS TO ASSIGN NEW VALUES TO THE CLASS
export class AddressClass {
  constructor(address = "", aptNumber = "", street = "") {
    this.address = address;
    this.aptNumber = aptNumber;
    this.street = street;

    this.setAddress(address);
    this.setAptNumber(aptNumber);
    this.setStreet(street);
  }

  //GETTERS
  get addressComponents() {
    return {
      address: this.address,
      aptNumber: this.aptNumber,
      street: this.street,
    };
  }

  getAddress() {
    return this.address;
  }

  getAptNumber() {
    return this.aptNumber;
  }

  setAddress(value) {
    this.address = value;
  }

  setStreet(value) {
    this.street = value;
  }

  setAptNumber(value) {
    this.aptNumber = value;
  }

  //METHODS
  #getAddressComponents() {
    let splitAddress = this.address.split(this.street);
    splitAddress = splitAddress[1].split(",");
    splitAddress.splice(0, 1);
    return [this.street, splitAddress.join(",").trim()];
  }

  #getCleanAddress() {
    let tmpCleanAddress = this.#getAddressComponents();
    return tmpCleanAddress[0] + ", " + tmpCleanAddress[1];
  }

  addressFormatter(aptNumber = "") {
    if (!aptNumber) {
      return this.#getCleanAddress();
    }

    let manualIdentifier = "";
    secondaryIdentifiers.forEach((el) => {
      aptNumber.includes(el) && (manualIdentifier = el);
    });

    let tmp = this.#getAddressComponents(this.address, this.street);

    //if the user put the identifier inside the apt number input
    if (!!manualIdentifier) {
      return `${tmp[0]} ${aptNumber}, ${tmp[1]}`;
    } else {
      return `${tmp[0]} apt ${aptNumber}, ${tmp[1]}`;
    }
  }
}
