import { useSelector } from "react-redux";
import PieChart from "./ViolationPieChart";

function ViolationPieChart(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  let { rowData } = props?.params || {};

  let {
    amount_due,
    payment_amount,
    fine_amount,
    interest_amount,
    penalty_amount,
    reduction_amount,
  } = rowData || {};

  const total = parseInt(amount_due);

  // parseInt(amount_due) + parseInt(payment_amount);

  return (
    <div style={{ position: "relative", padding: "10px 0 10px 0" }}>
      <PieChart params={props?.params} isDarkMode={isDarkMode}></PieChart>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, 12px)",
          fontSize: 22,
          fontWeight: 600,
          color: isDarkMode ? "#fff" : "#000",
        }}
      >
        {`$${total}`}
      </div>
    </div>
  );
}

export default ViolationPieChart;
