import moment from "moment";
import { PERIOD_FILTER } from "./actionsComponents/periodDateLogic/periodDateLogic";
import { defaultColors } from "../../../../../utils";
import { formatCurrency, formatNumberToPercentage } from "../../../utils";
import { API } from "aws-amplify";
import { updateProgramFieldByName } from "src/actions/programFields";
import { message } from "antd";

export const formatCardValue = (elements, type) => {
  if (type === "date") {
    return moment(elements).fromNow();
  } else if (type === "count") {
    return elements?.length || 0;
  } else if (type === "toLocaleString") {
    return elements.toLocaleString();
  } else if (type === "percent") {
    return formatNumberToPercentage(elements);
  } else if (type === "currency") {
    return formatCurrency(elements);
  } else {
    return elements;
  }
};

//function to find type of field and first field with non empty value
export const findNonEmptyValue = (arrayData, fieldName) => {
  const types = {};
  let mostUsedType = null;
  let nonEmptyFieldValue = null;

  arrayData.forEach((obj) => {
    const fieldValue = obj[fieldName];

    if (
      typeof fieldValue !== "undefined" &&
      (typeof fieldValue === "number" ? true : !!fieldValue)
    ) {
      const fieldType = typeof fieldValue;
      if (fieldType in types) {
        types[fieldType]++;
      } else {
        types[fieldType] = 1;
      }

      if (!mostUsedType || types[fieldType] > types[mostUsedType]) {
        mostUsedType = fieldType;
        nonEmptyFieldValue = fieldValue;
      }
    }
  });

  return {
    mostUsedType,
    nonEmptyFieldValue,
  };
};

export const handleFilter = (filters = [], rowData, setFilteredRowData) => {
  const filteredDates = PERIOD_FILTER();

  let filteredData = rowData;

  if (filters.length === 0) {
    setFilteredRowData(rowData);
    return;
  }

  filters.forEach((filter) => {
    const { filterType, value } = filter;
    if (filterType === "period") {
      const [startDate, endDate] = filteredDates[value];
      filteredData = filteredData?.filter((row) => {
        const { createdAt, updatedAt } = row;
        const timestampDate = !!createdAt
          ? moment(createdAt, "MM/DD/YYYY")
          : updatedAt;

        return timestampDate >= startDate && timestampDate <= endDate;
      });
    } else {
      filteredData = filteredData.filter((row) =>
        Array.isArray(row[filterType])
          ? row[filterType]?.some((obj) =>
              typeof obj === "object"
                ? Array.isArray(value)
                  ? value.includes(obj.nameOfUser)
                  : obj?.nameOfUser === value
                : Array.isArray(value)
                ? value.includes(obj)
                : obj === value
            )
          : Array.isArray(value)
          ? value.includes(row[filterType])
          : row[filterType] === value
      );
    }
  });

  console.log({ filteredData });

  setFilteredRowData(filteredData);
};

export const getStatusColors = (
  programFields,
  overviewName,
  nestedStatusColors
) => {
  const findedColors = programFields?.find(
    ({ fieldName }) => fieldName === "Status colors"
  )?.fieldOptions[overviewName];

  return !!findedColors
    ? (!!nestedStatusColors && !Array.isArray(findedColors)
        ? findedColors[nestedStatusColors]
        : findedColors) || []
    : defaultColors.map((statusColor, i) => ({
        statusId: i,
        statusName: i,
        statusColor,
      }));
};

/**
 * Value may contain HTML tags
 */
export const sanitizeLabel = (value) => {
  if (typeof value === "string" || typeof value === "number") {
    return value.toString().replace(/<\/?[^>]+(>|$)/g, "");
  }

  return value;
};

export const searchRecords = (label, searchValue) => {
  if (label) {
    if (typeof label === "string" || typeof label === "number") {
      return label.toString().toLowerCase().includes(searchValue.toLowerCase());
    } else if (Array.isArray(label)) {
      const labels = label
        .find((item) =>
          item.toString().toLowerCase().includes(searchValue.toLowerCase())
        )
        .map((label) => label);

      return labels;
    }
  }

  return false;
};

export const getLabel = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value.map((item) => item?.label).join(", ");
    }
    return value;
  }
  return;
};

export const savePermanentFilters = ({
  overviewCards,
  cardContent,
  filters,
  programFields,
  overviewName,
  dispatch,
}) => {
  const cardConfigs = programFields?.find(
    ({ fieldName }) => fieldName === "Overviews Configuration"
  );

  const updatedCards = overviewCards.map((card) =>
    card.id === cardContent?.id ? { ...card, permanentFilters: filters } : card
  );
  const updatedFieldOptions = {
    ...cardConfigs.fieldOptions,
    overviewCards: {
      ...cardConfigs.fieldOptions?.overviewCards,
      [overviewName]: updatedCards,
    },
  };

  API.patch("programFields", `/programFields/${cardConfigs?.fieldId}`, {
    body: { fieldOptions: updatedFieldOptions },
  })
    .then(() => {
      dispatch(
        updateProgramFieldByName({
          fieldName: "Overviews Configuration",
          fieldOptions: updatedFieldOptions,
        })
      );
      message.success("Filters saved for all users!");
    })
    .catch((err) => {
      console.log({ err });
      message.error("There was a problem with saving filters!");
    });
};

export const generateTooltipData = (object) => {
  return {
    ...(object?.createdBy && { createdBy: object?.createdBy }),
    ...(object?.createdAt && { createdAt: object?.createdAt }),
    ...(object?.totalPrice && { totalPrice: object?.totalPrice }),
    ...(object?.projectName && { projectName: object?.projectName }),
    ...(object?.projectAddress && { projectAddress: object?.projectAddress }),
    ...(object?.applicationDateXlsx && {
      applicationDateXlsx: object?.applicationDateXlsx,
    }),
    ...(object?.applicationStatus && {
      applicationStatus: object?.applicationStatus,
    }),
    ...(object?.dueDate && { dueDate: object?.dueDate }),
    ...(object?.chargeType && { chargeType: object?.chargeType }),
    ...(object?.chargeStatus && { chargeStatus: object?.chargeStatus }),
    ...(object?.chargeAmount && { chargeAmount: object?.chargeAmount }),
    ...(object?.appliedAmount && { appliedAmount: object?.appliedAmount }),
    ...(object?.remainingAmount && {
      remainingAmount: object?.remainingAmount,
    }),
    ...(object?.invoiceDate && { invoiceDate: object?.invoiceDate }),
    ...(object?.accountName && { accountName: object?.accountName }),
    ...(object?.subtotal && { subtotal: object?.subtotal }),
    ...(object?.retainage && { retainage: object?.retainage }),
    ...(object?.amountDue && { amountDue: object?.amountDue }),
    ...(object?.invoiceStatus && { invoiceStatus: object?.invoiceStatus }),
  };
};
