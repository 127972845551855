import { Button } from "antd";
import { dayjsNY } from "../../../../../../../../../DateComponents/contants/DayjsNY";

/**
 * Holds the details column defs for the schedule dispatch modal
 * @param {Array} dispatches The list of dispatches for the day
 * @param {Function} getRouteInfo Opens the route information modal for a dispatch
 * @param {String} grid "truck" | "driver"
 */
function dispatchesDetails({ dispatches, getRouteInfo, grid }) {
  return {
    detailGridOptions: {
      columnDefs: [
        grid === "driver" && {
          headerName: "Truck #",
          field: "fleetName",
          resizable: true,
        },
        {
          headerName: "Planned Departure",
          resizable: true,
          valueGetter({ data }) {
            return dayjsNY(data?.routes[0]?.departAt)?.format("hh:mm a");
          },
        },
        {
          headerName: "Planned Arrival",
          resizable: true,
          valueGetter({ data }) {
            return dayjsNY(
              data?.routes[data?.routes?.length - 1]?.arriveBy
            )?.format("hh:mm a");
          },
        },
        grid === "truck" && {
          headerName: "Driver",
          resizable: true,
          valueGetter({ data }) {
            return data?.routes[0]?.driverName;
          },
        },
        {
          headerName: "Show Routes",
          resizable: true,
          cellRenderer({ data }) {
            return (
              <Button
                type="primary"
                onClick={() => {
                  getRouteInfo({ dispatch: data });
                }}
              >
                Show Routes
              </Button>
            );
          },
        },
      ].filter(Boolean),
    },
    defaultColDef: {
      flex: 1,
      resizable: true,
      enableColResize: true,
    },
    getDetailRowData(params) {
      let dispatchesToShow = [];
      if (grid === "truck") {
        dispatchesToShow = dispatches?.filter(
          ({ fleetId }) => fleetId === params?.data?.fleetId
        );
      } else if (grid === "driver") {
        dispatchesToShow = dispatches?.filter(({ routes }) =>
          routes?.some(({ driverId }) => driverId === params?.data?.driverId)
        );
      }
      params.successCallback(dispatchesToShow);
    },
  };
}

export default dispatchesDetails;
