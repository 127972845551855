import { API } from "aws-amplify";
import { REQUEST_API_KEYS } from "./constants";

/**
 *
 * @param {String} category - The category of the request we are viewing
 * @param {String} recordId - The id of the record we are looking for
 * @param {Object} reducedRes - The reduced response object
 * @param {String} requestType - The type of the request
 * @param {Array} topicCategories - The topic categories we get from the redux store
 * @returns if the record is not found in the reduced response, we fetch it from the server
 */
const handleMissingRecord = async ({
  category = "",
  recordId = "",
  reducedRes = {},
  requestType = "",
  topicCategories = [],
  dispatchRequestData = () => {},
}) => {
  /**
   * If the request type is Documentation, we return as this is handled in the form population functions
   */
  if (requestType === "Documentation") return;

  const categoryData = topicCategories?.find(
    (el) => el?.categoryName?.toLowerCase() === category?.toLowerCase()
  );

  /**
   * If the category data is not found, we return the reduced response as is
   */
  if (!categoryData) {
    return dispatchRequestData({
      type: "GET_DATA",
      payload: reducedRes,
    });
  }

  const { apiName = "", primaryKey = "", rowName = "" } = categoryData || {};

  /**
   * If the apiName, primaryKey or rowName is not found, we return the reduced response as is
   */
  if (!apiName || !primaryKey || !rowName) {
    return dispatchRequestData({
      type: "GET_DATA",
      payload: reducedRes,
    });
  }

  const fetchedDataKey = apiName === "scheduling" ? "schedules" : apiName;

  /**
   * If the record is not found in the reduced response, we fetch it from the server
   */
  if (
    !reducedRes?.[fetchedDataKey]?.filter((el) => el[primaryKey] === recordId)
      ?.length
  ) {
    await API.get(apiName, `/${apiName}/${recordId}`, {
      queryStringParameters: {
        keysToInclude: REQUEST_API_KEYS[requestType]?.[apiName],
      },
    })
      .then((res) => {
        reducedRes?.[fetchedDataKey].push(res);
      })
      .catch((err) => {
        console.error("Error getting missing record: ", err);
      });
  }

  dispatchRequestData({
    type: "GET_DATA",
    payload: reducedRes,
  });
};

export default handleMissingRecord;
