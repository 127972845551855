import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const inspections = ({ tables }) => ({
  inspectionType: () => {
    const data = repeatedElementSum(
      tables?.[IdHandler] || [],
      "inspectionType"
    );

    return {
      content: Object.entries(data)?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: tables?.[IdHandler]?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Inspection for " + key,
        contentsHandler: " Inspections for " + key,
      })),
    };
  },

  inspectionStatus: () => {
    const data = repeatedElementSum(
      tables?.[IdHandler] || [],
      "inspectionStatus"
    );

    return {
      content: Object.entries(data)?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: tables?.[IdHandler]?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Inspection for " + key,
        contentsHandler: " Inspections for " + key,
      })),
    };
  },

  inspectedBy: () => {
    const data = repeatedElementSum(
      tables?.[IdHandler]?.map((el) => el?.nameOfUser) || [],
      "nameOfUser"
    );

    return {
      content: Object.entries(data)?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: tables?.[IdHandler]?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Inspection By " + key,
        contentsHandler: " Inspections By " + key,
      })),
    };
  },
});
