import { useState } from "react";
import { MondayButton } from "../../../../../../../commonComponents";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../../Communication/assets";

export function ModalFooter({
  removePreviusDataOnDestroy,
  currentCharge,
  saveCharge,
}) {
  const [alreadySaved, setAlreadySaved] = useState(false);

  const handleSave = () => {
    setAlreadySaved(true);
    saveCharge();
  };

  return (
    <div className="NewItemChargeFooter">
      <MondayButton
        onClick={removePreviusDataOnDestroy}
        className="NewItemChargeFooterCancel"
        Icon={<XIcon />}
      >
        Cancel Changes
      </MondayButton>
    </div>
  );
}
