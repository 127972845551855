import WarningModal from "../../../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../../../commonComponents";
import { XIcon } from "../../../../../Communication/assets";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { ReactComponent as WarningIcon } from "../../../../../../../icons/warningTriangle.svg";

function DriverSwitchModal({
  open,
  onCancel,
  driverToSwitch,
  switchHandler,
  prevDriver,
}) {
  return (
    <>
      <WarningModal
        visible={open}
        setVisible={onCancel}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p style={{ textAlign: "center" }}>
            <strong>{driverToSwitch}</strong> will take over for{" "}
            <strong>{prevDriver}</strong> in all of their routes following this
            location.
          </p>
          <p style={{ textAlign: "center" }}>
            <strong>{driverToSwitch}</strong> has missing routes from this
            location to the yard. Are you sure you want to continue?
          </p>
          <div className="buttons">
            <MondayButton
              onClick={onCancel}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={switchHandler} Icon={<TickIcon />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </>
  );
}

export default DriverSwitchModal;
