import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { MapMarkerIcon, FlagIcon } from "src/assets";
import { WarningIcon } from "src/icons";

function SideFenceActivity({
  isDarkMode,
  activeFence,
  truckActivities,
  onActivityClick = () => {},
}) {
  function toggleTripInfo(index) {
    if (activeFence === index) {
      onActivityClick(+index, true);
    } else {
      onActivityClick(+index);
    }
  }

  return (
    <div className="sidebar-route-history">
      {truckActivities?.flatMap((alertArr = [], index) => {
        if (!alertArr?.length) {
          return [];
        }
        let lastAlert = alertArr?.[alertArr?.length - 1];

        return (
          <div
            className={`single-route-details-container ${
              index === activeFence
                ? "single-route-selected"
                : "single-route-unselected"
            }`}
            id={`single-route-details-${index}`}
            key={`${index}`}
            onClick={() => {
              toggleTripInfo(index);
            }}
          >
            <div className="route-location-title">
              <div className="title-marker-icon">
                <MapMarkerIcon fill={isDarkMode ? "#fff" : "#323338"} />
              </div>
              <div className="title-arrive-info">
                <div className="arrive-location">
                  {lastAlert?.["fenceName"] || "Current..."}
                </div>
                <div className="arrive-time">
                  {dayjsNY(lastAlert?.["alertDateTime"]).format(
                    "MMM D, hh:mm a"
                  )}
                </div>
              </div>
            </div>
            <div
              className={`expandable-info ${
                index === activeFence ? "info-expanded" : "info-collapsed"
              }`}
              id={`sidebar-trip-info-${index}`}
            >
              {alertArr?.map((alert, i) => {
                let IconToRender = WarningIcon;
                if (alert?.["alertCode"]?.includes("GEOFENCE")) {
                  IconToRender = FlagIcon;
                }
                return (
                  <div className="trip-alert-info" key={`${index}-${i}`}>
                    <div className="alert-icon">
                      <IconToRender
                        height={14}
                        width={14}
                        fill={isDarkMode && "#fff"}
                      />
                    </div>
                    <div className="alert-info">
                      <div className="alert-text">{alert?.["alertDesc"]}</div>
                      <div className="alert-date-time">
                        {dayjsNY(alert?.["alertDateTime"]).format("hh:mm a")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SideFenceActivity;
