import { useSelector } from "react-redux";
import { getContent } from "../../../components/Notifications/utils";
import XLSX from "sheetjs-style";
import createPDF from "src/integrations/createPDF";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import DrawerHeaderActionsMobile from "../DrawerHeaderActionsMobile";
import { Input, Tour } from "antd";
import { SearchIcon } from "../../../../SidebarPages/Communication/assets";
import PdfHeadersModal from "../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import useExportHeadersModal from "../../../../../hooks/useExportHeadersModal";
import { tableHeaders, tableHeadersExcel } from "../helpers/constants";
import { generateDocDefinition } from "../../../../../utils/generateDocDefinition";
import { defaultExcelBodyStyle } from "../../../../../helpers/constants/defaultExcelBodyStyle";
import { excelCellFitToColumn } from "../../../../../utils/excelCellFitToColumn";
import { exportExcel } from "../../../../../utils/exportExcel";
import { ExportOrEmailDropdown } from "../../../../commonComponents";
import { useModalOnceObject } from "../../../../../hooks/useModalOnce";
import { useMultipleRefs } from "../../../../../hooks/useMultipleRefs";
import { findTutorialSteps } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { MondayButton } from "../../../../commonComponents";
import { QuestionCircleFilled } from "@ant-design/icons";

const ActionsComponent = ({
  filteredNotifications,
  activeNotificationsTab,
  setCurrentPage,
  setSearchValue,
  searchValue,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { tab } = useSelector((state) => state.notifications);

  function removeHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { base64 } = useSelector((state) => state.base64);

  const [tourOpen, setTourOpen] = useModalOnceObject("Tour");
  const [allRef, hiddenRef, mentionedRef, assignedRef] = useMultipleRefs(4);

  function stepsMapHelper(title) {
    if (title?.includes("All")) {
      return allRef.current;
    } else if (title?.includes("Hidden")) {
      return hiddenRef.current;
    } else if (title?.includes("I was mentioned")) {
      return mentionedRef.current;
    } else if (title?.includes("Assigned to me")) {
      return assignedRef.current;
    }
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        class: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const dbSteps = mapRefs(
    findTutorialSteps("Notifications Tour", programFields)
  );

  const onGeneratePDF = (action, getDocDefinition = false, headersData) => {
    const columnsToInclude =
      headersData || tableHeaders.map((item, index) => index);

    const tableData = filteredNotifications?.map((notification) => {
      const descriptionHtml = removeHtmlTags(getContent(notification));
      return {
        category: notification?.category || "",
        description: descriptionHtml || "",
        createdAt: moment(notification?.createdAt).format("MM/DD/YYYY") || "",
        priority: notification?.priority || "",
      };
    });

    const docDefinition = generateDocDefinition(
      base64,
      `Notifications / ${activeNotificationsTab}`,
      columnsToInclude,
      tableHeaders,
      tableData.map(Object.values)
    );

    if (getDocDefinition) {
      return docDefinition;
    }

    createPDF({
      action,
      docDefinition,
      title: action.includes("download") && "Notifications.pdf",
    });
  };

  const exportToExcel = (getDocDefinition = false, headersData) => {
    if (getDocDefinition) return onGeneratePDF("download", getDocDefinition);

    const columnsToInclude =
      headersData || tableHeadersExcel.map((item, index) => index);

    const rows = filteredNotifications.map((notification) => {
      const descriptionHtml = removeHtmlTags(getContent(notification));
      return [
        { v: notification.category || "", s: defaultExcelBodyStyle },
        { v: descriptionHtml || "", s: defaultExcelBodyStyle },
        {
          v: moment(notification?.createdAt).format("MM/DD/YYYY") || "",
          s: defaultExcelBodyStyle,
        },
        { v: notification.priority || "", s: defaultExcelBodyStyle },
      ];
    });

    exportExcel(
      tableHeadersExcel,
      columnsToInclude,
      rows,
      "Notifications",
      "Notifications"
    );
  };

  const uploadExcelToDrive = async (driveRequest, driveFolderId) => {
    try {
      const rows = filteredNotifications.map((notification) => {
        const descriptionHtml = removeHtmlTags(getContent(notification));
        return [
          { v: notification.category || "", s: defaultExcelBodyStyle },
          { v: descriptionHtml || "", s: defaultExcelBodyStyle },
          {
            v: moment(notification?.createdAt).format("MM/DD/YYYY") || "",
            s: defaultExcelBodyStyle,
          },
          { v: notification.priority || "", s: defaultExcelBodyStyle },
        ];
      });

      const worksheet = XLSX.utils.aoa_to_sheet([tableHeadersExcel, ...rows]);

      worksheet["!cols"] = excelCellFitToColumn([tableHeadersExcel, ...rows]);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Notifications");

      // Convert the workbook to an Excel binary array
      const excelArrayBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Convert the array buffer to a blob with the correct MIME type
      const excelBlob = new Blob([excelArrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Upload the Excel file to Google Drive
      const uploadResponse = await driveRequest.uploadExcelFile(
        excelBlob,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        {
          name: "Notifications.xlsx",
          parents: [driveFolderId],
        }
      );

      // Extract and log information about the uploaded file
      const { id, name } = await uploadResponse.json();

      // Update state or perform any necessary actions with the file ID
      return { id, name };
    } catch (error) {
      console.error("Error uploading Excel file to Google Drive:", error);
    }
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData: false,
      onGeneratePDF,
      exportToExcel,
    });

  // email
  return (
    <div>
      {isMobile ? (
        <DrawerHeaderActionsMobile
          setCurrentPage={setCurrentPage}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          onGeneratePDF={onGeneratePDF}
          filteredNotifications={filteredNotifications}
          exportToExcel={exportToExcel}
          uploadExcelToDrive={uploadExcelToDrive}
        />
      ) : (
        <div className="header-actions-container">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<QuestionCircleFilled />}
            onClick={() => {
              setTourOpen(true);
            }}
          >
            Guide
          </MondayButton>
          <ExportOrEmailDropdown
            {...{
              onGeneratePDF: (action, getDocDefinition) =>
                getDocDefinition
                  ? onGeneratePDF(action, getDocDefinition)
                  : getHeadersModal(action, getDocDefinition),
              exportGridToExcel: (getDocDefinition) =>
                getDocDefinition
                  ? exportToExcel(additionalData, getDocDefinition)
                  : getHeadersModal(false, getDocDefinition, "excel"),
              uploadExcelToDrive,
              rowData: filteredNotifications,
              defaultDocDefinition: true,
              title: "Notifications",
            }}
          />

          <div className="headerSearch right-container">
            <Input
              className="searchInput"
              placeholder={`Search ${
                tab?.toLowerCase() === "communication"
                  ? "emails"
                  : "notifications"
              }`}
              allowClear
              onChange={(e) => {
                setCurrentPage(1);
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              prefix={
                <SearchIcon width={15} height={15} style={{ marginRight: 5 }} />
              }
              style={{
                height: 32,
              }}
            />
          </div>
          {openModal && (
            <PdfHeadersModal
              {...{
                isOpen: openModal,
                onClose: () => handleCloseModal(),
                onFinish: (data) => handleFinishModal(data),
                headers: tableHeaders,
                title: "Notifications",
              }}
            />
          )}
        </div>
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => {
            setTourOpen(false);
          }}
          steps={dbSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
    </div>
  );
};

export default ActionsComponent;
