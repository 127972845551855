import { Button } from "antd";
import { AddIcon } from "../../../Header/components/GoogleEvents/assets";

const NewButton = ({ onClick, onBlur, collapsed }) => (
  <div className="new-button-container">
    <Button
      onBlur={onBlur}
      className="add-button"
      onClick={onClick}
      icon={<AddIcon />}
      type="ghost"
    >
      {!collapsed && <p className="add-button-text">Create New</p>}
    </Button>
  </div>
);

export default NewButton;
