import { getMonthsTimestamps } from "../../../../../utils"
import { months } from "../../../../addWidgetsModalData"

export const violations = ({ tables, circleProgressPicker }) => {
  return {
    amount_due: () => ({
      percent: (
        ((() => {
          const filteredArray = tables?.Violations?.filter(
            (b) =>
              b.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
              b.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
          )?.map((a) => a.amount_due)

          return !!filteredArray?.length
            ? filteredArray?.reduce(
                (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue)
              )
            : 0
        })() /
          (() => {
            const filteredArray = tables?.Violations?.filter(
              (b) => b?.issue_date >= getMonthsTimestamps()[0]
            )?.map((a) => a?.amount_due)

            return !!filteredArray?.length
              ? filteredArray?.reduce(
                  (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue)
                )
              : 0
          })()) *
        100
      ).toFixed(1),

      data: (() => {
        const filteredArray = tables?.Violations?.filter(
          (b) =>
            b.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            b.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        )?.map((a) => a.amount_due)

        return !!filteredArray?.length
          ? filteredArray?.reduce(
              (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue)
            )
          : 0
      })(),
    }), //amount_due
    issue_date: () => ({
      percent: (
        (tables?.Violations?.filter(
          (a) =>
            a?.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length /
          tables?.Violations?.filter((a) => a?.issue_date >= getMonthsTimestamps()[0]).length) *
        100
      ).toFixed(1),

      data: tables?.Violations?.filter(
        (a) =>
          a?.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
          a?.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
      ).length,
    }), //issue_date
  } //Violations
}
