function hasContent(str) {
  try {
    if (typeof str !== "string") {
      return false;
      throw new Error("Input must be a string");
    }

    const regex = /(<([^>]+)>)/gi;
    const content = str.replace(regex, "");

    if (typeof content !== "string") {
      throw new Error("Unexpected error: content is not a string");
    }

    return Boolean(content.trim());
  } catch (error) {
    console.error(error.message);
    return false; // Return false if an error occurs
  }
}

export default hasContent;
