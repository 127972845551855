import { boroughs } from "../../../addWidgetsModalData";
import { uniq } from "lodash";

export const leads = (updatedDynamicStates, IdHandler, programFields) => {
  return {
    leadAddress: () => ({
      title: "Leads",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Leads",
        field: "leadAddress",
        title: "Leads Address",
        subtitle: "",
        content: boroughs?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
          ).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
    leadStatus: () => ({
      title: "Leads",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Leads",
        field: "leadStatus",
        title: "Leads Status ",
        subtitle: "",
        content: programFields
          ?.find((a) => a?.fieldName === "Lead Status Types")
          ?.fieldOptions?.map((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (a) => a?.leadStatus === status
              ).length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: status,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.leadStatus === status
            ).length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }),

    projectExecutive: () => ({
      title: "Project Executive",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Leads",
        field: "projectExecutive",
        title: "Project Executive",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (a) => a?.projectExecutive
          )
        )
          ?.filter(Boolean)
          ?.map((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (a) => a?.projectExecutive === status
              ).length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: status,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.projectExecutive === status
            ).length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
