export const CHANGED_ENTRIES_INIT = {
  newEntries: [],
  editedEntries: [],
  removedEntries: [],
};

function ENTRIES_REDUCER(state, action) {
  switch (action.type) {
    case "new":
      const newUpdatedEntries = [...(state?.newEntries || [])];
      for (const entry of action?.entry || []) {
        newUpdatedEntries.push({ ...entry });
      }

      return {
        ...state,
        newEntries: newUpdatedEntries,
      };
    case "edit":
      const newEntries = [...(state?.newEntries || [])];
      const editedEntries = [...(state?.editedEntries || [])];

      for (const entry of action?.entry || []) {
        const indexInNew = newEntries.findIndex(
          ({ entryId }) => entryId === entry?.entryId
        );
        const indexInEdit = editedEntries.findIndex(
          ({ entryId }) => entryId === entry?.entryId
        );

        if (indexInNew > -1) {
          newEntries.splice(indexInNew, 1, { ...entry });
        }
        if (indexInEdit > -1) {
          editedEntries.splice(indexInEdit, 1, { ...entry });
        }
        if (indexInNew === -1 && indexInEdit === -1) {
          editedEntries.splice(editedEntries.length, 0, { ...entry });
        }
      }

      return {
        ...state,
        newEntries,
        editedEntries,
      };
    case "remove":
      const removedEntries = [...(state?.removedEntries || [])];
      const newEntriesInRemove = [...(state?.newEntries || [])];
      const editedEntriesInRemove = [...(state?.editedEntries || [])];

      for (const entry of action?.entry || []) {
        const editIndex = editedEntriesInRemove.findIndex(
          ({ entryId }) => entryId === entry?.entryId
        );
        const newIndex = newEntriesInRemove.findIndex(
          ({ entryId }) => entryId === entry?.entryId
        );

        if (editIndex > -1) {
          editedEntriesInRemove.splice(editIndex, 1);
        }

        if (newIndex > -1) {
          newEntriesInRemove.splice(newIndex, 1);
        }

        if (newIndex === -1) {
          removedEntries.splice(removedEntries?.length, 0, { ...entry });
        }
      }

      return {
        ...state,
        removedEntries,
        newEntries: newEntriesInRemove,
        editedEntries: editedEntriesInRemove,
      };

    case "updateState":
      return {
        ...state,
        ...action.entry,
      };
  }
}

export default ENTRIES_REDUCER;
