import { useMediaQuery } from "react-responsive";

import { ReloadIcon } from "../../../../../assets";
import "./AssistantErrorView.scss";

const AssistantErrorView = () => {
  const mobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="assistant-error-view">
      <ReloadIcon height={70} width={70} className="reconnect-icon" />
      <div className="error-message">
        <span>
          An error occurred while trying to connect you to our virtual
          assistant.
        </span>
        {!mobile ? <br /> : <span>&nbsp;</span>}
        <span>
          Please check your internet connection and try again. If the problem
          persists, contact support.
        </span>
      </div>
    </div>
  );
};

export default AssistantErrorView;
