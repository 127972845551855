import { formatCurrency } from "../../../../utils";
import { getSums, groupBy } from "../../calculatingFunctions";

export const charges = ({ tables, programFields }) => {
  const chargesStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Charge.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = chargesStatus?.statusName?.map((status) =>
    tables?.charges?.filter((a) => a?.categoryFrom === status)
  );

  return {
    categoryFrom: () => [
      {
        labels: chargesStatus?.statusName,
        data: statusData?.map((el) => el?.length),
        backgroundColor: chargesStatus?.statusColor,
        custom:
          !!statusData[0] &&
          groupBy(statusData, "projectAddress")?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Charge Amount With Tax": formatCurrency(
                    getSums({ data: statusData, key: "chargeAmount" })[index] ||
                      0
                  ),
                  "Total Tax": formatCurrency(
                    getSums({ data: statusData, key: "taxAmount" })[index] || 0
                  ),
                  "Charge Amount Without Tax": formatCurrency(
                    (getSums({ data: statusData, key: "chargeAmount" })[
                      index
                    ] || 0) -
                      (getSums({ data: statusData, key: "taxAmount" })[index] ||
                        0)
                  ),
                },
              },
              ...Object.entries(el).map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Charge Amount With Tax": formatCurrency(
                      getSums({ data: value, key: "chargeAmount" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "taxAmount" })
                    ),
                    "Charge Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "chargeAmount" }) -
                        getSums({ data: value, key: "taxAmount" })
                    ),
                  },
                };
              }),
            ];
          }),
      },
    ],
  };
};
