const fleetKeys = [
  "fleetName",
  "licensePlate",
  "vinNumber",
  "odometerReading",
  "maxCapacity",
  "fleetBodyType",
  "fleetNumber",
];

/**
 * Function that returns a formatted string of the truck information
 */
function getFormattedFleetInfo(truck) {
  return (
    <p style={{ width: "fit-content" }}>
      {fleetKeys.map((key, i) => {
        let t = key.replace(/([A-Z])/g, " $1");
        t = t.charAt(0).toUpperCase() + t.slice(1);
        let value = truck[key];
        if (key === "odometerReading") {
          value = parseInt(value);
        }
        return (
          <p key={i}>
            <strong>{`${t}:`}&nbsp;</strong>
            <span>{value}</span>
          </p>
        );
      })}
    </p>
  );
}

export default getFormattedFleetInfo;
