import { useSelector } from "react-redux";

const LogItem = ({ nameOfUser, updatedAt, styles }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      className={
        styles[isDarkMode ? "view-logs-item-body-dark" : "view-logs-item-body"]
      }
      style={{ overflow: "auto" }}
    >
      <div
        className={
          styles[isDarkMode ? "view-logs-item-dark" : "view-logs-item"]
        }
      >
        {nameOfUser}
      </div>
      <div
        className={
          styles[isDarkMode ? "view-logs-item-dark" : "view-logs-item"]
        }
      >
        {updatedAt}
      </div>
    </div>
  );
};

export default LogItem;
