import { getRandomColor } from "../../../../utils";
import { uniq } from "lodash";
import { boroughs } from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const scheduling = ({ tables, programFields }) => {
  return {
    typeOfWork: () => ({
      labels: programFields
        ?.find((a) => a?.fieldName == "Scheduling Types")
        ?.fieldOptions?.map((a) => a.typeName),
      data: programFields
        ?.find((a) => a?.fieldName == "Scheduling Types")
        ?.fieldOptions?.map((a) => a.typeName)
        ?.map(
          (status) =>
            tables?.Scheduling?.filter(
              (a) => a.typeOfWork?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: programFields
        ?.find((a) => a?.fieldName == "Scheduling Types")
        ?.fieldOptions?.map((a) => getRandomColor()),
    }), //typeOfWork

    projectExecutive: () => ({
      labels: uniq(tables?.Scheduling?.map((a) => a.projectExecutive)),
      data: Object.values(
        repeatedElementSum(tables?.Scheduling, "projectExecutive")
      ),
      backgroundColor: tables?.Scheduling?.map((a) => getRandomColor()),
    }),

    projectManager: () => ({
      labels: uniq(tables?.Scheduling?.map((a) => a.projectManagers)?.flat()),
      data: Object.values(
        repeatedElementSum(tables?.Scheduling, "projectManagers")
      ),
      backgroundColor: tables?.Scheduling?.map((a) => getRandomColor()),
    }),
  }; //scheduling
};
