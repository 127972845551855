export const estimations = ({ tables, circleProgressPicker }) => {
  return {
    estSTATUS: () => ({
      percent: (
        (tables?.Estimations?.filter(
          (a) => a?.estSTATUS?.toLowerCase() === circleProgressPicker?.toLocaleLowerCase()
        )?.length /
          tables?.Estimations?.length) *
        100
      )?.toFixed(1),

      data: tables?.Estimations?.filter(
        (a) => a?.estSTATUS?.toLowerCase() === circleProgressPicker?.toLocaleLowerCase()
      )?.length,
    }), //estSTATUS
    archAssignedTo: () => ({
      percent: (
        (tables?.Estimations?.filter(
          (a) => a?.archAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Estimations?.length) *
        100
      )?.toFixed(1),

      data: tables?.Estimations?.filter(
        (a) => a?.archAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //archAssignedTo
    engAssignedTo: () => ({
      percent: (
        (tables?.Estimations?.filter(
          (a) => a?.engAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Estimations?.length) *
        100
      )?.toFixed(1),

      data: tables?.Estimations?.filter(
        (a) => a?.engAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //engAssignedTo
    statusByArch: () => ({
      percent: (
        (tables?.Estimations?.filter(
          (a) => a?.statusByArch?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Estimations?.length) *
        100
      )?.toFixed(1),

      data: tables?.Estimations?.filter(
        (a) => a?.statusByArch?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //statusByArch
    statusByEng: () => ({
      percent: (
        (tables?.Estimations?.filter(
          (a) => a?.statusByEng?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Estimations?.length) *
        100
      )?.toFixed(1),

      data: tables?.Estimations?.filter(
        (a) => a?.statusByEng?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //statusByEng
  } //Estimations
}
