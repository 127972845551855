import { groupBy } from "lodash";
import { fetchAllData } from "../../../../utils";

const fetchToBeScheduledDataForeman = async (scheduleId) => {
  const fetchEst = await fetchAllData({
    endpoint: "toBeScheduled",
    resultPosition: "toBeScheduled",
    resultId: "toBeScheduledId",
    otherStringParams: {
      filters: JSON.stringify([
        {
          conditions: [
            {
              column: "scheduleId",
              value: scheduleId,
              formula: "is",
            },
          ],
        },
      ]),
    },
  });
  const est = fetchEst?.filter((sch) => sch?.scheduleId === scheduleId);
  return { toBeScheduled: groupBy(est, "estimationId"), est };
};

export default fetchToBeScheduledDataForeman;
