const wsTestRoute = new WebSocket(
  "wss://leadmanager-express-backend.ue.r.appspot.com/ws/echo"
);

export const isWsReady = () => {
  return new Promise((resolve) => {
    try {
      if (wsTestRoute.readyState === WebSocket.OPEN) {
        //if no resolved response within 5 seconds something is not correct so return false
        const wsTimeout = setTimeout(() => {
          console.error("WebSocket operation took too much time to connect.");
          resolve(false);
        }, 5000);

        wsTestRoute.send("WebSocket Is Ready!");

        wsTestRoute.onmessage = (event) => {
          clearTimeout(wsTimeout);
          try {
            resolve(event.data);
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
            resolve(false);
          }
        };

        wsTestRoute.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        wsTestRoute.onclose = (msg) => {
          console.log("WebSocket closed:", msg);
        };
      } else {
        console.log("Websocket is in closing state!");
        resolve(false);
      }
    } catch (e) {
      resolve(false);
      console.error("WebSocket Error: ", e);
    }
  });
};
