import { useState, useCallback, useMemo } from "react";
import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { dateFormat } from "../utils/routeFields";
import { specificSelectionColumns } from "./specificSelectionColumns";
import { MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { InfoIcon } from "../../../../../Inspections/assets";
import { useSelector } from "react-redux";

function SpecificScheduleSelectModal({
  open,
  onSelect,
  onCancel,
  rowData,
  projects,
  selectedDate,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    param?.columnApi?.autoSizeAllColumns(false);
  }, []);

  const columnDefs = useMemo(() => {
    return specificSelectionColumns({ selectedDate, projects });
  }, [selectedDate, projects]);

  const onFirstDataRendered = useCallback((param) => {
    setTimeout(() => {
      param?.columnApi?.autoSizeAllColumns(false);
    }, 100);
  }, []);

  const onSelectionChanged = useCallback((param) => {
    setConfirmDisabled(!param.api.getSelectedNodes()?.length);
    // param?.columnApi?.autoSizeAllColumns(false);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableColResize: true,
      enableRowGroup: false,
      sortable: true,
      filter: true,
      flex: 1,
      suppressSizeToFit: true,
      minWidth: 130,
      suppressDragLeaveHidesColumns: true,
    };
  }, []);

  function confirmHandler() {
    onSelect(gridApi.getSelectedNodes()?.[0]?.data);
  }

  return (
    <Modal
      {...{
        open,
        closable: false,
        centered: true,
        maskClosable: false,
        keyboard: false,
        title: `Specific Schedule Selection - ${dayjsNY(selectedDate).format(
          dateFormat
        )}`,
        className: `routeShowerForSwitchModal ${
          isDarkMode && "routeShowerForSwitchModalDark"
        }`,
        footer: [
          <MondayButton
            {...{
              Icon: <TickIcon />,
              disabled: confirmDisabled,
              onClick: confirmHandler,
              key: "confirm",
            }}
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <div className="switchTableContainer">
        <div className="infoSection">
          <InfoIcon />{" "}
          <strong>
            There are more than one schedules to the selected address, please
            specify the schedule you want to link this route to
          </strong>
        </div>
        <div
          className={`agGridContainer ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
        >
          <AgGridReact
            {...{
              rowData,
              columnDefs,
              onGridReady,
              onFirstDataRendered,
              rowSelection: "single",
              onSelectionChanged,
              defaultColDef,
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default SpecificScheduleSelectModal;
