import uniq from "lodash/uniq";

export const safety = (programFields, updatedDynamicStates, IdHandler) => {
  const safetyStatuses = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    safetyApplicationStatus: () => ({
      title: "Safety Application Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Safety",
        field: "safetyApplicationStatus",
        content: safetyStatuses?.map((status, index) => ({
          key: index,
          title: status?.statusName,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.safetyApplicationStatus === status?.statusName
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status?.statusName} Safety`,
          contentsHandler: ` ${status?.statusName} Safeties`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  };
};
