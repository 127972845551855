import { Tooltip, Tour } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { QuestionCircleFilled } from "@ant-design/icons";
import { XIcon } from "../../../../../../Communication/assets";
import { VideoTutorialIcon } from "../../../../../../../../icons";
import MondayButton from "../../../../../../../commonComponents/MondayButton/MondayButton";
import { PlayVideoTutorial } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

const ModalHeader = ({ type }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);

  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Project Management")
    ?.subCategories[5].videos.find(
      (item) => item.videoName === "Charges"
    )?.videoLink;

  const tourSteps = [
    {
      title: "Charges Tutorial",
      description: (
        <div>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => {
                setShowVideoTutorial(true);
                setIsTourOpen(false);
              },
              style: { display: "flex", justifyContent: " center" },
              Icon: <VideoTutorialIcon />,
            }}
          >
            Watch Video
          </MondayButton>
        </div>
      ),
      className: "custom-tour-light",
      placement: "center",
    },
  ];

  return (
    <div className="modalHeader">
      {type === "Charge" ? "Create a new charge" : "Create a new credit memo"}

      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <Tooltip title="Guide">
          <QuestionCircleFilled
            style={{ fontSize: 23, color: "#1264A3", cursor: "pointer" }}
            onClick={() => setIsTourOpen(true)}
          />
        </Tooltip>
        <XIcon width="17" height="17" fill={isDarkMode ? "#fff" : "#323338"} />
      </div>

      {isTourOpen && (
        <Tour
          open={isTourOpen}
          onClose={() => setIsTourOpen(false)}
          steps={tourSteps}
        />
      )}

      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Charges Tutorial",
          }}
        />
      )}
    </div>
  );
};

export default ModalHeader;
