import { InputComponent } from "../../../../../Fleet/components";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

function WorkOrderAssign({ mechanics, isDarkMode, form }) {
  return (
    <div className="workOrderAssignContainer">
      <InputComponent
        {...{
          type: "datePicker",
          label: "Issue Date",
          required: true,
          allowClear: false,
          formItemName: "issueDate",
          getPopupContainer: document.body,
          dropdownClassName: isDarkMode && "darkDateDropDown",
          initialValue: parseInTz(new Date()).startOf("day"),
          disabledDate(e) {
            if (!form.getFieldValue("dueDate")) {
              return false;
            }

            return (
              parseInTz(e).startOf("day").valueOf() >
              form.getFieldValue("dueDate").startOf("day").valueOf()
            );
          },
        }}
      />
      <InputComponent
        {...{
          type: "datePicker",
          label: "Due Date",
          required: true,
          allowClear: false,
          formItemName: "dueDate",
          initialValue: parseInTz(new Date()).add(1, "day").startOf("day"),
          dropdownClassName: isDarkMode && "darkDateDropDown",
          getPopupContainer: document.body,
          disabledDate(e) {
            return (
              parseInTz(e).startOf("day").valueOf() <
              form.getFieldValue("issueDate").valueOf()
            );
          },
        }}
      />
      <InputComponent
        {...{
          type: "select",
          label: "Assigned to",
          formItemName: "assignee",
          dropdownClassName: isDarkMode && "darkDropDown",
          required: true,
          allowClear: true,
          showSearch: true,
          getPopupContainer: document.body,
          customOptions: mechanics?.map((el) => ({
            label: el?.name,
            value: el?.id,
          })),
        }}
      />
    </div>
  );
}

export default WorkOrderAssign;
