import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { compareIncluding } from "../../../utils";

/**
 * Translates the fields of a pre-safety inspection based on the category and safety object.
 * @param {string} category - The category of the safety inspection.
 * @param {Object} safety - The safety object containing the inspection details.
 * @returns {Object} - The translated safety object fields.
 */
export function preSafetyInspectionTranslateFields({ category, safety }) {
  let safetyObjectFields = {};

  if (category === "Vehicle Damage") {
    safetyObjectFields = {
      projectId: safety?.projectId || "",
      scheduleId: safety?.scheduleId || "",
      accountId: safety?.accountId || "",
      safetyApplicationId: safety?.safetyApplicationId || "",
      inspectionCategory: safety?.safetyApplicationCategory,
      inspectionObject: {
        inspectionAddress: safety?.safetyApplicationObject?.addressOfIncident,
      },
      inspectionStatus: "Draft",
    };
  } else if (category === "Personal Injury") {
    safetyObjectFields = {
      projectId: safety?.projectId || "",
      scheduleId: safety?.scheduleId || "",
      accountId: safety?.accountId || "",
      safetyApplicationId: safety?.safetyApplicationId || "",
      inspectionCategory: safety?.safetyApplicationCategory,
      inspectionObject: {
        inspectionAddress: safety?.safetyApplicationObject?.addressOfInjury,
      },
      inspectionStatus: "Draft",
    };
  } else if (category === "Property Damage") {
    safetyObjectFields = {
      projectId: safety?.projectId || "",
      scheduleId: safety?.scheduleId || "",
      accountId: safety?.accountId || "",
      safetyApplicationId: safety?.safetyApplicationId || "",
      inspectionCategory: safety?.safetyApplicationCategory,
      inspectionObject: {
        inspectionAddress:
          safety?.safetyApplicationObject?.addressOfDamagedProperty,
      },
      inspectionStatus: "Draft",
    };
  } else if (category === "Other Trade Incident") {
    safetyObjectFields = {
      projectId: safety?.projectId || "",
      scheduleId: safety?.scheduleId || "",
      accountId: safety?.accountId || "",
      safetyApplicationId: safety?.safetyApplicationId || "",
      inspectionCategory: safety?.safetyApplicationCategory,
      inspectionObject: {
        inspectionAddress: safety?.safetyApplicationObject?.incidentAddress,
      },
      inspectionStatus: "Draft",
    };
  }

  return safetyObjectFields;
}
