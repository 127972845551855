import { useState, useCallback } from "react";
import { Modal } from "antd";
const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const open = useCallback(() => {
    setIsVisible(true);
  }, []);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const CustomModal = ({
    children,
    modalTitle,
    modalHeader,
    modalFooter,
    className = "custom-modal",
    ...rest
  }) => {
    return isVisible ? (
      <Modal
        title={modalTitle}
        header={modalHeader}
        open={isVisible}
        onCancel={close}
        footer={modalFooter}
        className={className}
        {...rest}
      >
        <div className="my-custom-modal">
          <div className="modal-content">{children}</div>
        </div>
      </Modal>
    ) : null;
  };

  return {
    CustomModal,
    open,
    close,
  };
};

export default useModal;
