import { message } from "antd";
import _ from "lodash";

const service = (service) => {
  let string = "";
  string += service?.label + "\n";
  string += "Options: \n";

  // console.log("l", service?.label);
  let optionsString = "";
  for (let i = 0; i < service.serviceOptions?.length; i++) {
    const option = service.serviceOptions[i];
    if (i === 0) {
      optionsString += "\tPrimary:\n";
    } else {
      optionsString += "\tAlternate " + i + ":\n";
    }
    for (const elevation of option) {
      optionsString += "\t\t Elevation: " + elevation.elevationLabel + "\n";

      let items;
      // if(service.serviceId.toString() === "3") {
      //   items = elevation.item["test"].items
      // } else {
      items = elevation.items;
      // }
      for (const item of items) {
        if (item.approved === true) {
          optionsString +=
            "\t\t\t" +
            JSON.stringify(
              item,
              (key, value) => {
                switch (key) {
                  case "id":
                    return undefined;
                  case "serviceId":
                    return undefined;
                  case "addons":
                    let v = _.cloneDeep(value);
                    for (const addon of v) {
                      addon.approved = undefined;
                      addon.name = addon?.name?.values?.find(
                        (f) => f?.name?.toLowerCase() === "name"
                      )?.value;
                      addon.type = undefined;
                    }
                    return v;
                  default:
                    return value;
                }
              },
              "\t\t\t"
            )?.replaceAll(/["{}\[\]]/g, "");
        }
      }
    }

    optionsString += "\n\n";
  }
  string += optionsString;

  string += "Addons: \n";
  string +=
    JSON.stringify(
      service?.serviceAddons,
      (key, value) => {
        if (key?.toLowerCase() === "id") return undefined;
        if (key?.toLowerCase() === "type") return undefined;
        if (key?.toLowerCase() === "name") {
          value = value?.name?.values?.find(
            (f) => f?.name?.toLowerCase() === "name"
          )?.value;
        }
        return value;
      },
      "\t"
    )?.replaceAll(/["{}\[\]]/g, "") + "\n";

  string += "Documentation: \n";
  string +=
    JSON.stringify(
      service?.documentationAddons,
      (key, value) => {
        if (key?.toLowerCase() === "id") return undefined;
        if (key?.toLowerCase() === "type") return undefined;
        if (key?.toLowerCase() === "name") {
          value = value.name?.values?.find(
            (f) => f?.name?.toLowerCase() === "name"
          )?.value;
        }
        return value;
      },
      "\t"
    )?.replaceAll(/["{}\[\]]/g, "") + "\n";

  navigator.clipboard
    .writeText(string)
    .then((s) => {
      message.success(`${service.label} successfully copied to clipboard.`);
    })
    .catch((e) => {
      message.error(`${service.label} could not be copied!.`);
      console.error("could not be copied to clipboard: ", e);
    });
};

export default service;
