import { isEmpty } from "lodash";

function getStringErrorMessage(error, reason = "") {
  if (typeof error === "string" && !isEmpty(error)) {
    return error;
  } else if (typeof error === "object") {
    if (typeof error?.message === "string" && !isEmpty(error?.message)) {
      return error?.message;
    } else if (typeof error?.response?.data?.message === "string") {
      return error?.response?.data?.message;
    } else {
      return reason;
    }
  } else {
    return reason;
  }
}

export default getStringErrorMessage;
