import { useCallback, useEffect, useRef, useState } from "react";

import {
  onPolygonDraw,
  onPolygonEdit,
  onPolygonLoad,
  onPolygonUndo,
  onPolygonUnmount,
} from "./helpers/polygonFunctions";

export const usePolygonFunctions = (selectedShape = "") => {
  const polygonRef = useRef(null);
  const listenerRef = useRef(null);

  const [pathPoints, setPathPoints] = useState([]);
  const [history, setHistory] = useState([]);
  const [currentStep, setCurrentStep] = useState(-1);

  const historyDispatcher = ({ newData }) => {
    const newHistory = history.slice(0, currentStep + 1);
    if (
      newHistory.length !== history.length ||
      newHistory[currentStep] !== newData
    ) {
      setHistory([...newHistory, newData]);
      setCurrentStep(newHistory.length);
    }
  };

  const onMapClick = (event) => {
    onPolygonDraw({
      event,
      dispatch: setPathPoints,
      selectedShape,
      historyDispatcher,
    });
  };

  const onPolygonUndoCallback = useCallback(() => {
    if (currentStep !== -1) {
      onPolygonUndo({
        currentStep,
        setPathPoints,
        history,
        setCurrentStep,
      });
      if (currentStep - 1 === -1) {
        setHistory([]);
      }
    }
  }, [currentStep, history, setPathPoints]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace") {
        onPolygonUndoCallback();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onPolygonUndoCallback]);

  const onEdit = useCallback(() => {
    onPolygonEdit({
      polygonRef,
      dispatch: setPathPoints,
      historyDispatcher,
    });
  }, [polygonRef, setPathPoints, historyDispatcher]);

  const onLoad = useCallback(
    (polygon) => {
      onPolygonLoad({
        polygon,
        polygonRef,
        listenerRef,
      });
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    onPolygonUnmount({ listenerRef, polygonRef });
  }, []);

  const discardChanges = () => {
    cleanUp();
  };

  function cleanUp() {
    setPathPoints([]);
    setHistory([]);
    setCurrentStep(-1);
  }

  return {
    pathPoints,
    onMapClick,
    onEdit,
    onLoad,
    onUnmount,
    discardChanges,
    setPathPoints,
  };
};
