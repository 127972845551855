import { useEffect, useState } from "react";
// import { NormalSizedModal } from "../../../../Fleet/components";
import { Form, message, Modal, Tooltip, Tour } from "antd";
import "./TemplateSelectorModal.scss";
import { AnimatedCheckBox } from "../../../../Fleet/components/InputComponent/components";
import { getRandomColor } from "../../../../utils";
import { MondayButton } from "../../../../../commonComponents";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import API from "@aws-amplify/api";
import { useSelector } from "react-redux";
import { apiRoutes } from "../../../../Fleet/utils";
import { uniqBy } from "lodash";
import {
  antdModalProps,
  getCognitosForNotification,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../utils";
import { filterPeopleList } from "../../../../utils/filterPeopleList";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { XIcon } from "../../../../Communication/assets";
import CustomModalHeader, {
  findTutorialSteps,
} from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { driveApi } from "../../../../../../integrations/DriveRequest";

const DOC_TYPE = "docType";

const TemplateSelectorModal = ({
  visible,
  setVisible,
  recordId,
  recordValue,
  path,
  viewTitle,
  categoryConfiguration = {},
  setDocumentation,
  record,
}) => {
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [tourOpen, setTourOpen] = useState(false);

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;
  const driveRequest = driveApi({ accessToken });

  const { getFolderIdOrCreate } = driveRequest;

  const { googleDriveFolderIds = {} } = record;
  const parentFolderId = googleDriveFolderIds?.docObject || "";

  const [form] = Form.useForm();

  async function createExtraFolders({ folderNames, parentFolderId }) {
    if (!folderNames) return;
    const allFolders = {};

    if (Array.isArray(folderNames)) {
      showLoadingMsg({ content: "Creating folders...", key: "createFolders" });
      await Promise.all(
        folderNames.map(async (name) => {
          const folder = await getFolderIdOrCreate({
            name,
            parents: [parentFolderId],
          });

          allFolders[name] = {
            id: folder,
          };
        })
      );
    }
    if (typeof folderNames === "string") {
      const folder = await getFolderIdOrCreate({
        name: folderNames,
        parents: [parentFolderId],
      });
      allFolders[folderNames] = {
        id: folder,
      };
    }

    return { ...allFolders };
  }

  const {
    categoryName,
    templatesAvailable = [],
    documentationsAvailable = [],
  } = categoryConfiguration;

  const onCancel = () => setVisible(false);

  const onSave = async () => {
    const selectedTemplates = templatesAvailable.filter(
      ({ templateName }) => !!form.getFieldValue(templateName)
    );
    function docBody(docType, folderId = null) {
      const currentDoc = documentationsAvailable.find(
        ({ docName }) => docName === docType
      );
      return {
        docType,
        categoryName,
        recordId,
        folderId,
        recordName:
          path?.split("/")[1] === "permitdrawings" ? viewTitle : recordValue,
        docObject: currentDoc?.docObject,
        expirationDate: currentDoc?.expirationDate,
      };
    }
    if (!!selectedTemplates.length) {
      const documentations = uniqBy(
        selectedTemplates.flatMap(({ templateDocs = [] }) => templateDocs)
      );
      if (!parentFolderId) {
        showErrorMsg({
          content: "Error creating folders, Parent folder not found",
          key: "createFolders",
        });
        return;
      }
      await createExtraFolders({
        folderNames: documentations,
        parentFolderId,
      })
        .then(async (res) => {
          showSuccessMsg({
            content: "Folders created successfully",
            key: "createFolders",
          });
          const postArr = Object.entries(res).map(([name, { id }]) => {
            return docBody(name, id);
          });
          showLoadingMsg({
            content: "Creating documentation...",
            key: "createDocumentation",
          });
          await API.post(
            apiRoutes.documentation,
            `/${apiRoutes.documentation}`,
            {
              body: { documentations: postArr },
            }
          )
            .then((r) => {
              let handleCategoryName =
                categoryName === "Clients" ? "Accounts" : categoryName;
              broadcastNotification(
                "12",
                "onDocTemplateCreation",
                [
                  {
                    common: user,
                    commonNext: categoryName,
                    address: recordValue,
                    cognitos: getCognitosForNotification(
                      userConfiguration,
                      record?.teamsConfiguration || []
                    ),
                  },
                  {
                    userName: user,
                    currentUser: authenticatedUser?.sub,
                    tab: "Documentation",
                  },
                ],
                `${handleCategoryName.toLowerCase()}/${recordId}`
              );
              showSuccessMsg({
                content: "Documentation created successfully",
                key: "createDocumentation",
              });
              setVisible(false);
              setDocumentation(r);
            })
            .catch((e) => {
              console.error(e);
              showErrorMsg();
            });
        })
        .catch(async (e) => {
          console.error(e);
          showErrorMsg({
            content: "Error creating folders",
            key: "createFolders",
          });
          const postArr = documentations?.map((docType) => {
            return docBody(docType);
          });

          await API.post(
            apiRoutes.documentation,
            `/${apiRoutes.documentation}`,
            {
              body: { documentations: postArr },
            }
          )
            .then((r) => {
              let handleCategoryName =
                categoryName === "Clients" ? "Accounts" : categoryName;
              broadcastNotification(
                "12",
                "onDocTemplateCreation",
                [
                  {
                    common: user,
                    commonNext: categoryName,
                    address: recordValue,
                  },
                  {
                    userName: user,
                    currentUser: authenticatedUser?.sub,
                    tab: "Documentation",
                    cognitos: getCognitosForNotification(
                      userConfiguration,
                      record?.teamsConfiguration || []
                    ),
                  },
                ],
                `${handleCategoryName.toLowerCase()}/${recordId}`
              );
              showSuccessMsg();
              setVisible(false);
              setDocumentation(r);
            })
            .catch((e) => {
              console.error(e);
              showErrorMsg();
            });
        });
    } else {
      message.warning("Please select a template!");
    }
  };

  function stepsMapHelper(title) {
    const stepsMap = {
      Templates: document.querySelector(".checkboxesContainer"),
      Template: document.querySelector(".checkboxesContainer :first-child"),
      "Don't want to select a template?":
        document.querySelector(".rejectButton"),
      Submit: document.querySelector(".submitButton"),
    };
    return stepsMap[title] || null;
  }
  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const tourSteps = mapRefs(
    findTutorialSteps("documentationTemplatesModal", programFields)
  );

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        setVisible,
        // title: "Select a Template",
        title: (
          <CustomModalHeader
            title="Select a Template"
            onClick={() => {
              tourSteps.length > 0
                ? setTourOpen(true)
                : message.info("No guide available for this form");
            }}
          />
        ),
        wrapClassName: `cardLayoutModal documentationTemplatesModal ${
          isDarkMode && "documentationTemplatesModalDark"
        }`,
        closeIcon: <XIcon />,
        onCancel,
      }}
    >
      <Form {...{ form, preserve: false }}>
        <div className="documentationTemplatesModalContainer">
          <div className="checkboxesContainer">
            {templatesAvailable?.map(
              ({ templateName, templateDocs }, index) => {
                let color = getRandomColor();

                return (
                  <AnimatedCheckBox
                    key={index}
                    {...{
                      label: (
                        <div className="checkboxLabel">
                          <div className="templateLabel">{`${templateName}`}</div>
                          <Tooltip
                            title={
                              <div className="tooltipContent">
                                {templateDocs?.map((docName, i) => (
                                  <div key={i}>{docName}</div>
                                ))}
                              </div>
                            }
                            {...{
                              placement: "right",
                              color,
                              mouseEnterDelay: 0.5,
                              mouseLeaveDelay: 0,
                            }}
                          >
                            <div className="docsLengthLabel">{` | ${templateDocs.length} DOC`}</div>
                          </Tooltip>
                        </div>
                      ),
                      form,
                      formItemName: templateName,
                      containerClassName: "templateCheckbox",
                      tooltipVisible: false,
                      primaryStyle: { backgroundColor: color },
                      secondaryStyle: { backgroundColor: "#FFF" },
                    }}
                  />
                );
              }
            )}
          </div>

          <div className="templateModalActionButtons">
            <MondayButton
              {...{
                className: "rejectButton",
                Icon: <CloseOutlined />,
                onClick: onCancel,
              }}
            >
              I don't want to select one
            </MondayButton>
            <MondayButton
              {...{
                className: "submitButton",
                Icon: <CheckOutlined />,
                onClick: onSave,
              }}
            >
              Submit
            </MondayButton>
          </div>
        </div>
        {tourOpen && (
          <Tour
            open={tourOpen}
            onClose={() => setTourOpen(false)}
            steps={tourSteps}
            mask={{ color: "#2a2b3a71" }}
          />
        )}
      </Form>
    </Modal>
  );
};

export default TemplateSelectorModal;
