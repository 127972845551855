import Swal from "sweetalert2";

export function forceLogoutPopup({
  logoutUser = async () => {},
  resetPassword,
}) {
  Swal.fire({
    title: "Logged out alert!",
    text: resetPassword
      ? "You have been logged out by an administrator and will be required to reset your password upon your next login."
      : "You have been logged out by an administrator",
    icon: "error",
    showConfirmButton: false,
    timer: 20000,
    allowOutsideClick: false,
  }).then(async () => {
    await logoutUser();
  });
}

export function askToLogoutPopup({ logoutUser, convertTime }) {
  Swal.fire({
    title: "Logged out alert!",
    html: `You will be logged out in <b>${convertTime}</b> minutes. Please save your work and prepare to log out.`,
    confirmButtonColor: "#71CF48",
    cancelButtonColor: "#FE4C4A",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Log out now",
    cancelButtonText: "Close popup",
    timerProgressBar: true,
    reverseButtons: true,
    allowOutsideClick: false,
    timer: 20000,
  }).then(async (result) => {
    if (result.isConfirmed) {
      await logoutUser();
    }
  });
}

export function messagePopup({ message, ws, userId }) {
  Swal.fire({
    title: "New message",
    input: "text",
    html: `<div>${message}</div>`,
    inputValue: "",
    showCancelButton: true,
    confirmButtonText: "Send",
    cancelButtonText: "Close",
    inputValidator: (value) => {
      if (!value) {
        return "You need to write something!";
      }
    },
  }).then((res) => {
    if (res.isConfirmed) {
      try {
        ws.send(
          JSON.stringify({
            request: "send-message",
            body: { message: res.value, userId },
          })
        );
      } catch (e) {
        console.error("Error sending ask to logout request", e);
      }
    }
  });
}
