import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";

const InspectionViewBreadcrumb = ({ inspectionType, projectId }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb
      className="inspectionViewBreadcrumb"
      items={[
        {
          title: "Project View",
          className: "cursorPointer",
          onClick: () => navigate(`/projects/${projectId}`),
        },
        {
          title: "Inspections",
          className: "cursorPointer",
          onClick: () =>
            navigate(`/projects/${projectId}`, {
              tabPosition: "Inspections",
            }),
        },
      ]}
    />
  );
};

export default InspectionViewBreadcrumb;
