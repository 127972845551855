import { SimplePLI } from "./GeneralModels";

export class RoadPlatesPLI extends SimplePLI {
  serviceId = 74;

  width;
  length;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateRoadPlatesPLI extends SimplePLI {
  serviceId = 74;

  width;
  length;

  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
