import { CloseOutlined } from "@ant-design/icons";
import { Badge, Drawer, Pagination, Tabs } from "antd";
import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleSignIn } from "../../../../hooks/useGoogleSignIn";
import { showInfoMsg } from "../../../../utils";
import { callGmailAPI } from "../../../SidebarPages/Communication/functions";
import ActionsComponent from "../Notifications/NotificationData/ActionsComponent";
import { ConfirmationModal } from "./ConfirmationModal";
import { FilterTypes, TabData } from "./NotificationData";
import { Filters } from "./components";
import GoogleUserUi from "./components/google-user-ui/GoogleUserUi";
import NotificationsLayout from "./components/notifications-layout/NotificationsLayout";
import { getFilterByValue, getTabValue } from "./utils/helpers";

import SelectRecordCategory from "./components/SelectRecordCategory/SelectRecordCategory";
import apiService from "./utils/notifications-api";

import "./Notifications.scss";
import {
  getEmailsAsNotifications,
  getSearchValueFilteredNotifications,
  handleFilterClick,
  handleMarkAllAsRead,
  handlePaginationChange,
  handleTabChange,
  paginatedNotifications,
  updateFilters,
} from "./helpers/notifications-drawer-helpers";
import { useNotificationsDrawer } from "./providers/NotificationsDrawerProvider";

const totalNotificationsPerPage = 24;

const NotificationsDrawer = ({
  open,
  // setOpen,
  fetchData,
  notificationsData,
  lastEvaluatedKey,
}) => {
  const dispatch = useDispatch();
  const { googleAccessToken, onGoogleSignIn, onGoogleSignOut, userLoggedIn } =
    useGoogleSignIn();
  const { tab, filterBy, notifications, communication } = useSelector(
    (state) => state.notifications
  );
  const { toggleDrawer } = useNotificationsDrawer();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [type, setType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRecordCategory, setSelectedRecordCategory] =
    useState("projects");
  const [filteredEmails, setFilteredEmails] = useState([]);

  // search notifications input filtering data when triggered
  let allNotifications = searchValue
    ? getSearchValueFilteredNotifications(notificationsData, searchValue)
    : notificationsData;

  let authData =
    userLoggedIn && googleAccessToken
      ? Buffer.from(JSON.stringify(googleAccessToken)).toString("base64")
      : null;

  useEffect(() => {
    getEmailsAsNotifications(
      dispatch,
      selectedRecordCategory,
      authData,
      userConfiguration
    );
  }, [googleAccessToken]);

  const handleUpdateFilters = (tab, filterBy) => {
    updateFilters(dispatch, tab, filterBy);
  };

  const onFilterClick = async (title) => {
    handleFilterClick(
      title,
      setType,
      type,
      tab,
      handleUpdateFilters,
      filterBy,
      setCurrentPage
    );
  };

  const onTabChange = async (title) => {
    handleTabChange(
      title,
      handleUpdateFilters,
      getTabValue,
      filterBy,
      getFilterByValue,
      setCurrentPage,
      setType,
      type
    );
  };

  const TabItems = TabData({
    notifications: notifications,
    userConfiguration,
    communication: communication,
  }).map(({ title, length }) => {
    return {
      key: title,
      label: (
        <div className={`badge-notification-count`}>
          <Badge
            style={{ top: -3 }}
            className="ignore-onclickoutside"
            count={length}
            size="small"
            onClick={() => {
              onTabChange(title);
            }}
          >
            <span className="tab-label">{title}</span>
          </Badge>
        </div>
      ),
      children: (
        <div className="notifications-body">
          <div className="notifications-filters">
            <div className="filters">
              <span className="filter-text">Filter By:</span>
              {FilterTypes?.filter(({ title }) => {
                if (
                  tab.toLowerCase() === "communication" &&
                  (title.toLowerCase() === "critical" ||
                    title.toLowerCase() === "info")
                ) {
                  return false;
                }
                return true;
              }).map(({ title, Icon, color }, i) => (
                <Filters
                  key={i}
                  {...{
                    title,
                    Icon,
                    i,
                    color,
                    type,
                    setType: (e) => {
                      handleUpdateFilters(tab, getFilterByValue(e));
                      setType(e);
                      setCurrentPage(1);
                    },
                    isDarkMode,
                    onClick: () => {
                      onFilterClick(title);
                    },
                  }}
                />
              ))}

              {!!filterBy && <Badge count={notificationsData?.length} />}
            </div>

            {tab?.toLowerCase() !== "communication" && (
              <div style={{ display: "flex", gap: "5px" }}>
                <div
                  onClick={() => {
                    const newArray = allNotifications.filter(
                      (notification) => notification.seen === false
                    );

                    if (newArray.length !== 0) {
                      setShowModal(true);
                      // setIconClicked("Mark All As Read");
                    } else {
                      showInfoMsg({
                        content: "Notifications are already read.",
                      });
                    }
                  }}
                  className="mark-read-btn"
                >
                  Mark All As Read
                </div>
              </div>
            )}

            {tab?.toLowerCase() === "communication" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <SelectRecordCategory
                  fetchData={(selectedValue) =>
                    getEmailsAsNotifications(
                      dispatch,
                      selectedValue,
                      authData,
                      userConfiguration
                    )
                  }
                  selectedRecordCategory={selectedRecordCategory}
                  setSelectedRecordCategory={setSelectedRecordCategory}
                />

                <GoogleUserUi
                  onGoogleSignOut={onGoogleSignOut}
                  onGoogleSignIn={onGoogleSignIn}
                  getEmailsAsNotifications={() =>
                    getEmailsAsNotifications(
                      dispatch,
                      selectedRecordCategory,
                      authData,
                      userConfiguration
                    )
                  }
                  setSelectedRecordCategory={setSelectedRecordCategory}
                />
              </div>
            )}
          </div>

          <NotificationsLayout
            paginatedNotifications={paginatedNotifications(
              currentPage,
              totalNotificationsPerPage,
              allNotifications
            )}
            allNotifications={allNotifications}
            // setOpen={setOpen}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            tabTitle={title}
            activeTab={tab?.toLowerCase()}
            currentPage={currentPage}
            searchValue={searchValue}
            filteredEmails={filteredEmails}
            setFilteredEmails={setFilteredEmails}
          />
        </div>
      ),
    };
  });

  const total = allNotifications?.length;

  return (
    <div className="notifications-container">
      <Drawer
        title="Notifications"
        extra={
          <ActionsComponent
            {...{
              filteredNotifications: allNotifications,
              activeNotificationsTab: tab,
              setCurrentPage,
              setSearchValue,
              searchValue,
            }}
          />
        }
        width={900}
        onClose={() => toggleDrawer()}
        open={open}
        placement="right"
        closeIcon={<CloseOutlined />}
        rootClassName={`notifications-drawer ${
          isDarkMode && "notifications-drawer-dark"
        }`}
      >
        <div className="notification-tabs">
          <Tabs
            defaultActiveKey="1"
            onChange={(e) => {
              setCurrentPage(1);
              handleUpdateFilters(getTabValue(e), filterBy);
            }}
            items={TabItems}
          />
        </div>
        <div className="notifications-footer">
          <Pagination
            current={currentPage}
            pageSize={totalNotificationsPerPage}
            total={
              tab?.toLowerCase() === "communication"
                ? filteredEmails?.length
                : lastEvaluatedKey !== null
                ? total + 24
                : total
            }
            onChange={(page) =>
              handlePaginationChange(
                page,
                tab,
                setCurrentPage,
                totalNotificationsPerPage,
                total,
                fetchData
              )
            }
            showSizeChanger={false}
          />
        </div>
      </Drawer>

      {showModal ? (
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="Mark All As Read"
          onOK={() =>
            handleMarkAllAsRead(
              authenticatedUser.sub,
              dispatch,
              allNotifications,
              apiService
            )
          }
        />
      ) : null}
    </div>
  );
};

export default NotificationsDrawer;
