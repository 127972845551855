export const convertSubContractorToVendor = (subContractors) => {
  return subContractors.map((contractor) => ({
    vendorName: contractor.accountName,
    vendorType: contractor.accountRecordType,
    userId: contractor.userId,
    createdAt: contractor.createdAt,
    vendorId: contractor.accountId,
    vendorAddress: contractor.billingAddress,
    isAccount: true,
  }));
};
