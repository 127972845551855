import CLOUDY from "../../../../../icons/Weather_Icons/Cloudy.webp";
import MOSTLY_CLOUDY from "../../../../../icons/Weather_Icons/MostlyCloudy.webp";
import MOSTLY_RAINY from "../../../../../icons/Weather_Icons/MostlyRainy.webp";
import PARTLY_CLOUDY from "../../../../../icons/Weather_Icons/PartlyCloudy.webp";
import RAINY from "../../../../../icons/Weather_Icons/Rainy.webp";
import RAINY_THUNDER from "../../../../../icons/Weather_Icons/RainyThunder.webp";
import SUNNY from "../../../../../icons/Weather_Icons/Sunny.webp";
import THUNDER from "../../../../../icons/Weather_Icons/Thunder.webp";

/**
 * Getting weather icon depending of its description
 * @constructor
 * @param {string} description - Weather description
 */
export const getWeatherIcon = (description = "") => {
  const weatherCases = [
    ["partly", "cloudy"],
    ["mostly", "cloudy"],
    ["mostly", "rainy"],
    ["chance", "rain", "showers", "sunny"],
    ["cloudy"],
    ["rainy", "thunder"],
    ["rainy"],
    ["partly", "sunny"],
    ["thunder"],
    ["chance", "rain"],
    ["rain"],
    ["mostly", "clear"],
    ["sunny"],
    ["chance", "showers", "thunderstorms"],
    ["showers", "thunderstorms", "likely"],
    ["clear"],
    ["isolated", "showers", "thunderstorms"],
    ["slight"],
    ["Areas", "of", "fog"],
  ];
  //   const descWeatherCases = weatherCases.sort((a,b) => b.length - a.length)      //sorting by the longest array first because short ones can accomplish case that long ones accomplish too
  const findInDescription = (d) =>
    description?.toLowerCase().split(" ")?.includes(d?.toLowerCase());
  const caseFound = weatherCases.findIndex((w) => w?.every(findInDescription));
  switch (caseFound) {
    case 0:
      return PARTLY_CLOUDY;
    case 1:
      return MOSTLY_CLOUDY;
    case 2:
      return MOSTLY_RAINY;
    case 3:
      return PARTLY_CLOUDY;
    case 4:
      return CLOUDY;
    case 5:
      return RAINY_THUNDER;
    case 6:
      return RAINY;
    case 7:
      return PARTLY_CLOUDY;
    case 8:
      return THUNDER;
    case 9:
      return RAINY;
    case 10:
      return RAINY;
    case 11:
      return PARTLY_CLOUDY;
    case 12:
      return SUNNY;
    case 13:
      return RAINY_THUNDER;
    case 14:
      return RAINY_THUNDER;
    case 15:
      return SUNNY;
    case 16:
      return RAINY_THUNDER;
    case 17:
      return MOSTLY_CLOUDY;
    case 18:
      return MOSTLY_CLOUDY;
    default:
      return "No icon for this usecase";
  }
};

export default getWeatherIcon;
