import { API } from "aws-amplify";
import { apiRoutes } from "../../../../SidebarPages/Fleet/utils";
import dayjs from "dayjs";
import { getChangedData } from "../../../../SidebarPages/Accounting/components/utilities";
import { message } from "antd";

/**
 * Edits a request.
 *
 * @param {WebSocket} ws - The WebSocket connection.
 * @param {string} from - The source of the request.
 * @param {string} action - The action to perform.
 * @param {Object} tmpBody - The temporary body data.
 * @param {Object} requestForm - The request form data.
 * @param {Function} setVisible - The function to set the visibility.
 * @param {Object} userConfiguration - The user configuration data.
 * @param {Function} fileHandler - The file handler function.
 * @param {Function} saveAddedLogs - The function to save added logs.
 * @returns {Promise<void>} - A promise that resolves when the request is edited.
 */
const editRequest = async ({
  ws = null,
  from = "",
  action = "",
  tmpBody = {},
  requestForm = {},
  setVisible = () => {},
  userConfiguration = {},
  fileHandler = () => {},
  saveAddedLogs = async () => {},
}) => {
  const { requestId = "" } = requestForm;

  const newEditLogData = {
    recordId: requestForm?.requestId,
    recordName: requestForm?.jobsiteAddress,
    previousData: {},
    currentData: {},
    category: "Approvals",
    actionType: "Edit",
    topic: requestForm?.requestType,
    label: "",
    member: userConfiguration?.nameOfUser,
    nameOfUser: userConfiguration?.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    previousData: {},
    updatedAt: dayjs().valueOf(),
    updatedKeys: [],
  };

  for (let key in tmpBody) {
    if (key !== "requestObject") {
      let result = getChangedData(tmpBody[key], requestForm[key]);
      if (result !== false) {
        newEditLogData.currentData[key] = result.curr;
        newEditLogData.previousData[key] = result.prev;
        newEditLogData.updatedKeys.push(key);
      }
    }
  }

  const responseFrom =
    action === "Draft"
      ? "no response"
      : action === "Reviewed"
      ? "no response"
      : "declined";

  if (from === "header") {
    Object.assign(tmpBody, {
      responseFrom,
      userRequesting: userConfiguration?.cognitoUserId,
    });

    Object.assign(tmpBody.requestObject, {
      status:
        action === "Needs Review" &&
        requestForm?.createdBy !== requestForm?.userRequesting
          ? "Needs Review"
          : action,
    });
  }

  await API.patch(
    apiRoutes.formRequests,
    `/${apiRoutes.formRequests}/${requestId}`,
    {
      body: tmpBody,
    }
  )
    .then(() => {
      ws.send(
        JSON.stringify({
          request: "new",
          body: {
            ...tmpBody,
            requestId,
            userRequesting: userConfiguration?.cognitoUserId,
          },
        })
      );

      fileHandler();

      if (from === "header") {
        saveAddedLogs({
          recordName: tmpBody?.requestObject?.recordName,
          category: "Approvals Action",
          topic: tmpBody?.requestType,
          actionType: "Reviewed",
          currentData: {
            requestedBy: tmpBody?.userRequesting,
            category: tmpBody?.category,
            recordId: tmpBody?.recordId,
            requestId,
          },
        });
      }

      setVisible(false);
    })
    .catch((err) => {
      message.error("Something went wrong!");
      console.error("ERROR UPDATING REQUEST : ", err);
      setVisible(true);
    });
};

export default editRequest;
