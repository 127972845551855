import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { generateCellValue } from "../../formatters/formula";
import {
  dimensionFormatterAddons,
  evaluateMathExpressionAddons,
} from "../../formatters/evaluators";
import { ChangeOrderIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { FormulaIcon, PriceIconSuggestion } from "../../../../../../../assets";
import { Button, Modal, Popover, Select, Tooltip, message } from "antd";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  applyPLIAddonFormulas,
  applyPLIAddonFormulas2,
  attachPriceToPLI,
} from "../../../subcomponents/elevationPanel/PLIAddon/PLIAddon";
import { MondayButton } from "../../../../../../commonComponents";
import { attachSpanPriceToPLI } from "../../../subcomponents/elevationPanel/PLIAddon/gridHelpers";
import { useMemo, useState } from "react";
import { ArrowDownOutlined } from "@ant-design/icons";
import { PLIAddonSuggestor } from "./PLIAddonSuggestor";
import HoistFloorAddon from "../../../subcomponents/HoistForm/subcomponents/HoistFloorAddon/HoistFloorAddon";

/**
 * @param pricingData {Array<{pricingObject: PricingObject, serviceId: number}>}
 * @param service {Service}
 * @param onAddonChange {function} - callback function used in dataEntryGrid onServiceAddonChange. Addons inside PLI does not need it because they use onCellChange event
 * @param addonType {'documentation' | 'service addon' | 'pli'}
 * @param isWritable {boolean}
 * @param selectOptions {{units: {unitName: String, unitDescription: String, formula: String, unitId: String}[]}=}
 * @param onAddonRichTextChange {function=}
 * @param priceView {boolean}
 * @param priceEdit {boolean}
 * @param agGridTheme {String=} - What theme are we using
 * */
export function getAddonsColumns(
  pricingData,
  service,
  onAddonChange,
  addonType,
  isWritable,
  selectOptionsUnfiltered,
  onAddonRichTextChange,
  { priceView, priceEdit, agGridTheme },
  parentAPI,
  parentData,
  indexes,
  handleSave,
  canViewPrice = true
) {
  let selectOptions = selectOptionsUnfiltered?.units?.filter(
    (e) => e?.addonType === "pli"
  );
  const serviceId = service.serviceId;
  if (pricingData === undefined) {
    console.warning("getAddonsColumns: pricingData === undefined");
    return [];
  }
  const correctPricingObject = pricingData.find(
    (p) => p.serviceId.toString() === serviceId.toString()
  )?.pricingObject;
  if (correctPricingObject) {
    let columns = [];

    let whenAddonChange;
    let addonsList;

    if (addonType === AddonType.pli) {
      addonsList = correctPricingObject.addonsList.filter(
        (a) => a.addonType === AddonType.pli
      );

      whenAddonChange = (api, valueFromNameChange) =>
        onAddonChange(api, valueFromNameChange);
    } else if (
      addonType === AddonType.serviceAddon ||
      addonType === "documentation"
    ) {
      whenAddonChange = (id, api, addonData) =>
        onAddonChange(serviceId, id, api, addonData, addonType);
      addonsList = correctPricingObject.addonsList.filter(
        (a) => a.type === AddonType.serviceAddon || a.type === "documentation"
      );
    }

    //hoist
    columns.push({
      headerName: "ID",
      field: "id",
      minWidth: 35,
      width: 35,
      editable: false,
      cellClass: "id-column",
    });
    if (serviceId === 3) {
      addonsList =
        pricingData?.find((e) => e?.serviceId === "3")?.pricingObject
          ?.addonsList || [];
      columns.push({
        headerName: "Actions",
        cellRenderer: (params) => {
          return (
            <PLIActionsHoist
              {...{
                params,
                service,
                parentAPI,
                parentData,
                indexes,
                handleSave,
              }}
            />
          );
        },
      });
      columns.push({
        headerName: "Floor Addon",
        cellRenderer: (params) => {
          return (
            <HoistFloorAddon
              {...{
                params,
                service,
                parentAPI,
                parentData,
                indexes,
                handleSave,
                addonsList,
                parentAPI,
              }}
            />
          );
        },
      });
      columns.push({
        headerName: "Addon Name",
        field: "addonname",
        valueGetter: (params) => {
          if (!!params?.data?.addonname) {
            return params?.data?.addonname;
          } else {
            // check old structure
            let tempName = params?.data?.name?.values?.find(
              (el) => el?.name?.toLowerCase() === "name"
            )?.value;
            if (!!tempName) {
              params.data.addonname = tempName;
              return tempName;
            } else {
              return "Select Addon";
            }
          }
        },
        // cellRenderer: (params) => {
        //   return (
        //     <HoistFloorAddon
        //       {...{
        //         params,
        //         service,
        //         parentAPI,
        //         parentData,
        //         indexes,
        //         handleSave,
        //         addonsList,
        //         parentAPI,
        //       }}
        //     />
        //   );
        // },
      });
      //empty column
    }

    console.log("addonsList", addonsList);
    if (serviceId !== 3) {
      columns.push({
        headerName: "Name",
        field: "name",
        width: 220,
        // editable: false,
        cellRenderer: "GeneralAddonEditor",
        cellRendererParams: {
          addonsList: addonsList,
          onAddonChange: whenAddonChange,
          addonType: addonType,
          isWritable: isWritable,
        },
        valueFormatter: (params) => {
          if (params.value) {
            return params?.value?.values?.find(
              (c) => c?.name?.toLowerCase() === "name"
            ).value;
          } else {
            return "Select Addon";
          }
        },
      });
    }
    //    console.log(correctPricingObject.addonsColumnDefs)

    for (const cd of correctPricingObject.addonsColumnDefs.filter(
      (col) => col.addonType === addonType
    )) {
      if (cd.name.toLowerCase() === "name") continue; //has been added before

      // //remove any pricing column
      // if(cd.name.toLowerCase() === "ppu" || cd.name.toLowerCase() === "rent" || cd.name.toLowerCase() === "price" || cd.name.toLowerCase() === "lock") {
      //     continue; //skip pricing
      // }

      // //skip W H L if we are in documentation
      // if(addonType === AddonType.documentation &&
      //     (cd.name.toLowerCase() === "length" || cd.name.toLowerCase() === "width" || cd.name.toLowerCase() === "height")) continue;
      console.log("cdInputs", cd?.inputs);
      if (cd.type.toLowerCase() === "select") {
        //TODO make a custom editor which calculate changes
        columns.push({
          headerName: cd?.name?.toUpperCase(),
          field: cd.name.toLowerCase(),
          // width: 80,
          editable: true,
          cellRenderer: "SelectEditor",
          cellRendererParams: {
            values: Array.isArray(cd?.inputs)
              ? cd?.inputs?.flatMap((el) => el?.value)
              : [],
          },
        });
        continue;
      }
      console.log("cdtype", cd);
      if (cd.type.toLowerCase() === "text" && cd?.addonType !== "pli") {
        columns.push({
          headerName: cd?.name?.toUpperCase(),
          field: cd.name.toLowerCase(),
          // width: 130,
          editable: false,
          cellRenderer: "rtfEditorAddon",
          cellRendererParams: {
            service: service,
            isWritable: isWritable,
            onAddonRichTextChange: onAddonRichTextChange,
            addonType: addonType,
            type: "addon",
            agGridTheme,
          },
        });
        continue;
      }

      //check if there is a field matching any unit
      let matchingUnit = selectOptions?.units?.find(
        (u) => u.unitName === cd.name
      );
      console.log("matchingUnit", selectOptions?.units);
      if (matchingUnit) {
        columns.push({
          headerName: cd?.name?.toUpperCase(),
          field: cd.name.toLowerCase(),
          // width: 80,
          editable: true,
          valueGetter: (params) => {
            return generateCellValue(params.data, matchingUnit);
          },
        });
        continue;
      }

      if (
        cd.name.toLowerCase() === "width" ||
        cd.name.toLowerCase() === "height" ||
        cd.name.toLowerCase() === "length" ||
        cd.name.toLowerCase() === "price" ||
        cd.name.toLowerCase() === "quantity" ||
        cd.name.toLowerCase() === "total" ||
        cd.name.toLowerCase() === "total price"
      ) {
        // cd.valueParser = evaluateMathExpression2
        columns.push({
          headerName: cd?.name?.toUpperCase(),
          field: cd.name.toLowerCase(),
          width: 80,
          // editable: false,
          valueParser: evaluateMathExpressionAddons,
          valueFormatter: dimensionFormatterAddons,
          cellClassRules: {
            "invalid-price": (params) => {
              let value = parseFloat(params?.value);
              return isNaN(value) || value === Infinity || value <= 0;
            },
          },
        });
        continue;
      }

      let tempColumn = {
        headerName: cd?.name?.toUpperCase(),
        field: cd.name.toLowerCase(),
        width: 80,
        editable: true,
      };
      if (cd.name?.toLowerCase() === "ppu" && !!service?.PLIAddonScheme) {
        console.log("ppuuu service", service);
        let { x, y, z } = service?.PLIAddonScheme || {};
        if (Array.isArray(x) && Array.isArray(y) && Array.isArray(z)) {
          tempColumn.cellRenderer = (params) => {
            return (
              <PLIAddonSuggestor
                {...{ params, service, parentAPI, parentData, handleSave }}
              />
            );
          };
        }
      }

      columns.push(tempColumn);
    }

    if (serviceId !== 3) {
      columns.push({
        headerName: "Actions",
        cellRenderer: (params) => {
          return (
            <PLIActions
              {...{
                params,
                service,
                parentAPI,
                parentData,
                indexes,
                handleSave,
              }}
            />
          );
        },
      }); //empty column
    }
    console.log("columns", columns);
    return columns?.map((c) => {
      const field = c?.field?.toLowerCase();
      const canNotSee =
        field?.includes("price") || (field?.includes("ppu") && !canViewPrice);
      return {
        ...c,
        hide: canNotSee,
        cellRendererFramework: (params) => (
          <CellRendererFormula params={params} />
        ),
      };
    });
  }
  return [];
}

const CellRendererFormula = (props) => {
  console.log("cellRendererFormula", props);
  let currDef = props?.params?.colDef?.field || false;
  let vals = props?.params?.data?.name?.values || [];
  let hasFormula = vals?.find(
    (v) => v?.name?.toLowerCase() === currDef
  )?.formula;

  console.log("hasFormula", {
    currDef,
    vals,
    hasFormula,
    props,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
      }}
    >
      {props?.params?.value}{" "}
      {!!hasFormula && (
        <Tooltip
          title={`Formula = ${
            Array.isArray(hasFormula) &&
            hasFormula?.length > 0 &&
            hasFormula?.join(" ")
          }`}
        >
          <span
            style={{
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#18AA73",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <FormulaIcon />
          </span>{" "}
        </Tooltip>
      )}
    </div>
  );
};

let oldVals = [
  // {
  //   service: {},
  //   id: "",
  //   val: 0,
  // }
];

// export const PLIAddonSuggestor = ({
//   params = {},
//   service = {},
//   parentData = {},
//   parentAPI = {},
//   isSpan = false,
// }) => {
//   const [indic, setIndic] = useState(0);
//   const numVal = getPLIAddonPriceSuggestion(params, service);
//   const dataVal = params?.data?.[isSpan === true ? "spanPrice" : "ppu"];
//   let keyRef = `${service?.label || ""}_${params?.column?.colId || ""}_${
//     parentData?.id || ""
//   }_${params?.data?.id || ""}`;

//   const changeIndic = useMemo(() => {
//     const temp = {
//       arrow: null,
//       value: 0,
//       difference: 0,
//       suggestedPrice: 0,
//     };
//     temp.value = dataVal;
//     temp.suggestedPrice = numVal;
//     if (temp.value > temp.suggestedPrice) {
//       temp.arrow = (
//         <img alt="..." className="data-grid-price-arrow" src={ArrowUpward} />
//       );
//     } else if (temp.value < temp.suggestedPrice) {
//       temp.arrow = (
//         <img
//           alt="..."
//           className="data-grid-price-arrow"
//           src={ArrowDownOutlined}
//         />
//       );
//     } else if (temp.value === temp.suggestedPrice) {
//       temp.arrow = <Remove />;
//     } else
//       temp.arrow = <IndeterminateCheckBox style={{ visibility: "hidden" }} />;

//     temp.difference =
//       Math.round(Math.abs(temp.suggestedPrice - temp.value) * 100) / 100;
//     if (isNaN(temp.difference) || temp.difference === 0) temp.difference = "";
//     return temp;
//   }, [numVal, dataVal]);

//   const onAcceptSuggestion = (suggestion) => {
//     let oldVal = oldVals?.findIndex((v) => v?.id === keyRef);
//     let indicVal = forceToNumber(suggestion) - forceToNumber(dataVal);
//     setIndic(indicVal);
//     if (oldVal !== -1) {
//       oldVals[oldVal] = {
//         ...oldVals[oldVal],
//         value: indicVal,
//       };
//     } else {
//       oldVals.push({
//         id: keyRef,
//         value: indicVal,
//       });
//     }
//     if (isSpan === true) {
//       params.data.spanPrice = numVal;
//       attachSpanPriceToPLI({
//         data: parentData,
//         api: parentAPI,
//         addons: parentData.addons,
//       });
//       params.api.refreshCells({
//         rowNodes: [params.node],
//         force: true,
//       });
//     } else {
//       message.success("Suggestion accepted");
//       params.data.ppu = numVal;
//       // applyPLIAddonFormulas2(props?.params?.data);
//       applyPLIAddonFormulas2(params?.data);
//       attachPriceToPLI({
//         data: parentData,
//         api: parentAPI,
//         addons: parentData?.addons,
//       });
//       params.api.refreshCells({
//         rowNodes: [params.node],
//         force: true,
//       });
//     }
//   };

//   const { arrow, value, difference, suggestedPrice } = changeIndic;

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         gap: "10px",
//         alignContent: "center",
//         alignItems: "center",
//       }}
//     >
//       {/* {oldVals?.find((v) => v?.id === keyRef)?.value !== dataVal &&
//         oldVals?.find((v) => v?.id === keyRef)?.value} */}{" "}
//       <span className="ppu-difference">
//         {arrow} {difference}
//       </span>
//       {forceToNumber(dataVal) !== forceToNumber(numVal) ? (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             gap: "10px",
//             alignContent: "center",
//             alignItems: "center",
//           }}
//         >
//           {dataVal}{" "}
//           <MondayButton
//             className="mondayButtonBlue"
//             onClick={onAcceptSuggestion}
//             Icon={<PriceIconSuggestion />}
//           >
//             {numVal}
//           </MondayButton>
//         </div>
//       ) : (
//         <>
//           <MondayButton
//             // className="mondayButtonGree"
//             // onClick={onAcceptSuggestion}
//             disabled
//             Icon={<PriceIconSuggestion />}
//           >
//             <span style={{ color: "white" }}>{numVal}</span>
//           </MondayButton>
//         </>
//       )}
//     </div>
//   );
// };

export const getPLIAddonPriceSuggestion = (params, service) => {
  let trackIdxs = {
    x: 0,
    y: 0,
    z: 0,
  };
  let { x, y, z } = service?.PLIAddonScheme || {};

  let length = params?.data?.length || false;
  let width = params?.data?.width || false;
  if (!!length && !!width) {
    // do nothing
    for (const el of x) {
      if (forceToNumber(el) === width) {
        trackIdxs.x = x.indexOf(el);
        break;
      } else if (forceToNumber(el) > forceToNumber(width)) {
        trackIdxs.x = x.indexOf(el);
        break;
      }
    }
    for (const el of y) {
      if (forceToNumber(el) === forceToNumber(length)) {
        trackIdxs.y = y.indexOf(el);
        break;
      } else if (forceToNumber(el) > forceToNumber(length)) {
        trackIdxs.y = y.indexOf(el);
        break;
      }
    }
  }
  return forceToNumber(z?.[trackIdxs.y]?.[trackIdxs.x] || 0);
};

const PLIActionsHoist = (props) => {
  //params, service, parentAPI, parentData
  console.log("PLIActionsHoist", props);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [floorsToAdd, setFloorsToAdd] = useState([]);
  const { optionIndex = 0, elevationIndex = 0 } = props?.indexes;
  const onDeleteAddon = () => {
    let parentAddons = props?.parentData?.addons || [];
    let newAddons = parentAddons.filter(
      (el) => el.id !== props?.params?.data?.id
    );
    props.parentData.addons = newAddons;
    props.parentAPI.applyTransaction({
      update: [props.parentData],
    });
    props.handleSave(false, true);
  };

  const onCleanUp = () => {
    setFloorsToAdd([]);
    setIsModalOpen(false);
  };

  const handleCloneAddon = () => {
    let toClone = floorsToAdd || [];
    if (Array.isArray(toClone) && toClone.length > 0) {
      let rows = [];
      props?.parentAPI?.forEachNode((node) => {
        rows.push(node?.data);
      });
      rows?.forEach((row, idx) => {
        console.log("handleCloneAddon", {
          rows,
          toClone,
        });
        if (toClone.includes(row?.floor)) {
          let newAddon = { ...props?.params?.data };
          console.log("handleCloneAddon__NEW", newAddon);
          if (
            newAddon?.addonname === "Common Platform" ||
            newAddon?.addonname === "Runbacks"
          ) {
            if (forceToNumber(row?.floor_height) !== 0) {
              newAddon.height = forceToNumber(rows[idx - 1]?.floor_height);
            }
          } else {
            if (forceToNumber(row?.floor_height) !== 0) {
              newAddon.height = forceToNumber(row?.floor_height);
            }
          }
          row.addons.push(newAddon);
        }
      });
      console.log("handleCloneAddon", rows);
      props?.parentAPI?.applyTransaction({
        update: rows,
      });
      props?.parentAPI?.refreshCells({
        force: true,
        columns: ["addons"],
      });
      props.handleSave(false, true);
    } else {
      message.error(
        "Please select at least one floor to clone this addon into"
      );
    }
  };

  const onSelectAll = () => {
    let allToAdd = [];
    props?.service?.serviceOptions?.[optionIndex]?.[
      elevationIndex
    ]?.items?.forEach(
      (el, idx) => {
        if (el?.floor !== props?.parentData?.floor) {
          allToAdd.push(el?.floor);
        }
      }
      // ({
      //   value: el?.floor,
      //   label: `Floor ${el?.floor}`,
      //   disabled: el?.floor === props?.parentData.floor,
      // })
    );
    setFloorsToAdd(allToAdd?.map((e) => forceToNumber(e)));
  };

  return (
    <div
      className="actions_pli_hoist_addon"
      style={{
        display: "flex",
        gap: "10px",
      }}
    >
      <MondayButton
        className="mondayButtonRed"
        hasIcon={false}
        onClick={onDeleteAddon}
      >
        Delete
      </MondayButton>
      <MondayButton
        className="mondayButtonBlue"
        hasIcon={false}
        onClick={() => setIsModalOpen(true)}
      >
        Clone to...
      </MondayButton>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          title={"Clone this Addon to other Floors"}
          onCancel={onCleanUp}
          centered
          onOk={handleCloneAddon}
        >
          <div>Pick which Floors to clone this addon into </div>
          <br />
          <div>
            <Select
              placeholder="Select floors..."
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              // defaultValue={}
              value={floorsToAdd}
              options={props?.service?.serviceOptions?.[optionIndex]?.[
                elevationIndex
              ]?.items?.map((el, idx) => ({
                value: el?.floor,
                label: `Floor ${el?.floor}`,
                disabled: el?.floor === props?.parentData.floor,
              }))}
              onChange={(e) => {
                console.log("e57339", e);
                if (Array.isArray(e) && e.length > 0) {
                  setFloorsToAdd(e?.map((el) => forceToNumber(el)));
                } else {
                  setVals([]);
                }
              }}
              // onChange={handleChange}
              // options={options}
            />{" "}
            <Button type="link" block onClick={onSelectAll}>
              {" "}
              Select All
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

const PLIActions = (props) => {
  //params, service, parentAPI, parentData
  console.log("PLIActionsHoist", props);

  const onDeleteAddon = () => {
    let parentAddons = props?.parentData?.addons || [];
    let newAddons = parentAddons.filter(
      (el) => el.id !== props?.params?.data?.id
    );
    props.parentData.addons = newAddons;
    attachPriceToPLI({
      data: props.parentData,
      api: props.parentAPI,
      addons: newAddons,
    });
    props.parentAPI.applyTransaction({
      update: [props.parentData],
    });
    props.parentAPI.refreshCells({
      force: true,
    });
    props.handleSave(false, true);
  };

  return (
    <div
      className="actions_pli_hoist_addon"
      style={{
        display: "flex",
        gap: "10px",
      }}
    >
      <MondayButton
        className="mondayButtonRed"
        hasIcon={false}
        onClick={onDeleteAddon}
      >
        Delete
      </MondayButton>
    </div>
  );
};
