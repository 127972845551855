import {
  onDateFilter,
  onFilter,
  onNumberRangeFilter,
  onTimeFilter,
} from "../../../SidebarPages/utils";
import { keys } from "../../Settings/settingsComponents/Roles/RolesData";
import dayjs from "dayjs";

function filterGrid({ gridApi, filtersData, gridFilters }) {
  if (gridApi) {
    let activeFilters;
    let newFiltersObject = [];
    if (keys(filtersData).find((el) => filtersData[el] !== null)) {
      keys(filtersData)
        .map((e) => {
          if (e.includes("end")) {
            return { [`${e}end`]: filtersData[e] };
          } else if (e.includes("start")) {
            return { [`${e}start`]: filtersData[e] };
          } else if (e.includes("range1")) {
            return { [`${e}range1`]: filtersData[e] };
          } else if (e.includes("range2")) {
            return { [`${e}range2`]: filtersData[e] };
          } else {
            return { [e]: filtersData[e] };
          }
        })
        .filter((e) => e !== undefined)
        .filter((e) => {
          return gridFilters?.find((el) => {
            if (keys(e)[0].includes("end")) {
              return el.key === keys(e)[0].split("end")[0];
            } else if (keys(e)[0].includes("start")) {
              return el.key === keys(e)[0].split("start")[0];
            } else if (keys(e)[0].includes("range1")) {
              return el.key === keys(e)[0].split("range1")[0];
            } else if (keys(e)[0].includes("range2")) {
              return el.key === keys(e)[0].split("range2")[0];
            } else {
              return el.key === keys(e)[0];
            }
          });
        })
        .map((e) => {
          if (keys(e)[0].includes("start")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...gridFilters.find(
                  ({ key }) => key === keys(e)[0].split("start")[0]
                ),
                value: [dayjs(e[keys(e)[0]] || "")],
              },
            ];
          } else if (keys(e)[0].includes("end")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...gridFilters.find(
                  ({ key }) => key === keys(e)[0].split("end")[0]
                ),
                value: [
                  ...newFiltersObject.find(
                    ({ key }) => key === keys(e)[0].split("end")[0]
                  )?.value,
                  dayjs(e[keys(e)[0]] || ""),
                ],
              },
            ];
          } else if (keys(e)[0].includes("range1")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...gridFilters.find(
                  ({ key }) => key === keys(e)[0].split("range1")[0]
                ),
                value: [e[keys(e)[0]]],
              },
            ];
          } else if (keys(e)[0].includes("range2")) {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...gridFilters.find(
                  ({ key }) => key === keys(e)[0].split("range2")[0]
                ),
                value: [
                  ...(newFiltersObject.find(
                    ({ key }) => key === keys(e)[0].split("range2")[0]
                  )?.value || {}),
                  e[keys(e)[0]],
                ],
              },
            ];
          } else {
            newFiltersObject = [
              ...newFiltersObject,
              {
                ...gridFilters.find(
                  ({ key }) => key === keys(e)[0].split("start")[0]
                ),
                value: e[keys(e)[0]],
              },
            ];
          }
          return gridFilters;
        });
      activeFilters = [
        ...new Set(
          newFiltersObject?.map((item) => ({
            key: item.key,
            type: item.type,
            value: item.value,
            masterDetail: !!item?.masterDetail,
          }))
        ),
      ].filter(({ value }) => value !== undefined);
    } else {
      activeFilters = undefined;
    }
    if (activeFilters) {
      activeFilters?.map?.(({ key, type, value, masterDetail }) => {
        type === "Dropdown"
          ? onFilter({
              gridApi,
              column: key,
              current: value,
              type: type,
              masterDetail,
            })
          : type === "DateRange"
          ? onDateFilter?.({
              gridApi,
              column: key,
              dateArray: value,
              masterDetail,
            })
          : type === "NumberRange"
          ? onNumberRangeFilter?.({
              gridApi,
              column: key,
              numberArray: value,
              masterDetail,
            })
          : type === "TimeRange"
          ? onTimeFilter?.({
              gridApi,
              column: key,
              dateArray: value,
              activeFilters,
              gridFilters,
            })
          : onFilter?.({
              column: key,
              gridApi,
              type: "equal",
              current: dayjs(value).startOf("day"),
            });
      });
    } else {
      gridApi?.setFilterModel(null);
    }
  }
}

export default filterGrid;
