import { useMemo } from "react";
import { WithTooltip } from "../../../../../../../commonComponents";
import { Divider } from "antd";

function CustomHeader({
  title,
  labelsKeys,
  dataSrc,
  style = {},
  color,
  fontSize,
  type,
  isDarkMode,
  tooltipCategory,
}) {
  const headerData = useMemo(() => {
    if (!!labelsKeys && !!dataSrc) {
      let result = [];
      labelsKeys?.forEach((label) => {
        if (dataSrc.some((el) => el.hasOwnProperty(label?.key))) {
          getMathfulAction(
            label?.mathAction,
            label,
            dataSrc,
            result,
            label?.type
          );
        }
      });
      return result;
    }
  }, [labelsKeys, dataSrc]);

  return (
    <div className="agGridHeaderDiv">
      <span className="agGridHeaderText">{title}</span>
      <div className="agGridHeaderData">
        {!!headerData &&
          Array.isArray(headerData) &&
          headerData?.map((header, index) => (
            <div className="header-cont" key={index}>
              <WithTooltip
                tooltipCategory={tooltipCategory}
                tooltipKey={header?.tooltipKey}
              >
                <span className="header-val" style={{ color, fontSize }}>
                  {header?.value}
                </span>
              </WithTooltip>
              <span className="header-name" style={{ color, fontSize }}>
                {header?.name}
              </span>
              {/* <Divider className="agGridHeaderDivider" type="vertical" /> */}
            </div>
          ))}
      </div>
    </div>
  );
}
export default CustomHeader;

const getMathfulAction = (mathAction, label, dataSrc, result, type) => {
  if (mathAction === "sum") {
    let sum = dataSrc?.reduce((acc, cur) => {
      return acc + cur[label?.key] || 0;
    }, 0);
    result.push({
      key: label?.key,
      name: label?.name,
      value: type !== "currency" ? sum : formatCurrency(sum),
      tooltipKey: label?.tooltipKey,
    });
  }
};

const formatCurrency = (amount, currency = "USD") => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(
    amount
  );
};
