import _ from "lodash";
import { formatCurrency } from "../../utils";
import {
  workOrderCols,
  fleetInspectionCols,
  assignedMechanicsCols,
  reminderColumns,
  mismatchColumns,
} from "../data";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import {
  UserType,
  ChartData,
  LayoutType,
  DefectType,
  ChartTypes,
  DatasetType,
  ListInfoType,
  WorkOrderType,
  VehicleReport,
  SmallInfoCardType,
  FleetInspectionType,
  MaintenanceReducerType,
  LocationType,
} from "../types";
import {
  MaintenanceIcon,
  InspectionsWhite,
  FleetInspectionsBlankIcon,
} from "src/icons";
import { hexToRgba, camelToTitle } from "src/utils";
import { AccountingSideBarIconBlank } from "src/assets";
import { getRandomColor } from "src/components/Header/forms/Scheduling/helpers/creators";

type Dictionary<T> = {
  [key: string]: T;
};

type OverviewAnalyticsType = Dictionary<Dictionary<number>>;

type DevicesMismatch = {
  fleetName: string;
  fleetId: string;
  deviceSerialNumber: string;
  vinInSystem: string;
  foundVin: string | null;
};

function positionSmallCards(smallCards: SmallInfoCardType[]): LayoutType {
  return smallCards.map((params, index) => {
    return {
      h: 3,
      y: 0,
      isResizable: false,
      w: 12 / smallCards.length,
      i: `${params.title}-Small`,
      x: (smallCards.length - 1) * index,
      contentData: {
        type: "Small",
        params,
      },
    };
  });
}

function positionListCards(listCards: ListInfoType[]): LayoutType {
  return listCards.map((params, index) => {
    return {
      h: 9,
      x: 0,
      w: 6,
      minW: 4,
      minH: 7,
      i: `${params.cardTitle}-List`,
      y: 3 + (listCards.length - 1) * index * 9,
      contentData: {
        type: "List",
        params,
      },
    };
  });
}

function positionChartCards(chartCards: ChartData[]): LayoutType {
  return chartCards.map((params, index) => {
    return {
      h: 9,
      x: 6,
      w: 6,
      minW: 4,
      minH: 7,
      i: `${params.cardTitle}-Chart`,
      y: 3 + (chartCards.length - 1) * index * 9,
      contentData: {
        type: "Chart",
        params,
      },
    };
  });
}

function processAnalytics(
  analytics: OverviewAnalyticsType,
  chartTypes: Dictionary<ChartTypes>
): ChartData[] {
  const chartsData: ChartData[] = [];

  for (const key in analytics) {
    const cardTitle = camelToTitle(key);
    const labels = Object.keys(analytics[key]).map(camelToTitle) as string[];

    const backgroundColor = [];
    const borderColor = [];
    const data = [];

    const dataset: DatasetType = {
      backgroundColor,
      borderColor,
      data,
      borderWidth: 2,
      label: cardTitle,
    };

    for (const results in analytics[key]) {
      const color = getRandomColor();
      const bck = hexToRgba(color, 0.4);
      backgroundColor.push(bck);
      borderColor.push(color);
      data.push(analytics[key][results]);
    }

    chartsData.push({
      cardTitle,
      labels,
      datasets: [dataset],
      chartType: chartTypes[key],
    });
  }

  return chartsData;
}

type ConfigParam = {
  userId: string;
  mechanicUsers: UserType[];
  customConfiguration?: LayoutType[];
};

/**
 * Function responsible for generating the default grid layout.
 * Also accepts a custom positioning
 */
function generateDefaultOverview(
  data: MaintenanceReducerType,
  config: ConfigParam
): LayoutType {
  let layout: LayoutType = [];

  const {
    fleet,
    reports,
    defects,
    locations,
    workOrders,
    inspections,
    inventoryItems,
    servicePrograms,
  } = data;

  const { mechanicUsers, userId } = config;

  const today = dayjsNY().startOf("day").valueOf();

  const ongoingWorkOrders = workOrders.filter(
    ({ dueDate }) => dayjsNY(dueDate).endOf("day").valueOf() >= today
  );

  const newDefects = defects.filter(
    ({ defectStatus }) => defectStatus === "New"
  );

  const laborCost = workOrders.reduce(
    (acc, { laborInformation }) =>
      acc +
      laborInformation.reduce(
        (allInfo, currentInfo) => allInfo + Number(currentInfo.total),
        0
      ),
    0
  );

  const inventoryDictionary: Dictionary<string> = inventoryItems.reduce(
    (acc, val) => ({
      ...acc,
      [val.itemId]: val.itemName,
    }),
    {}
  );

  const locationCollection = locations.reduce(
    (acc, val) => ({
      ...acc,
      [val.deviceSerialNumber]: val,
    }),
    {} as Record<string, LocationType>
  );

  const mismatchRows: DevicesMismatch[] = [];

  for (const vehicle of fleet) {
    if (
      !vehicle?.deviceSerialNumber ||
      !locationCollection?.[vehicle?.deviceSerialNumber]
    ) {
      mismatchRows.push({
        fleetId: vehicle.fleetId,
        fleetName: vehicle.fleetName,
        deviceSerialNumber: vehicle.deviceSerialNumber,
        foundVin: null,
        vinInSystem: vehicle.vinNumber,
      });
    } else {
      if (
        locationCollection[vehicle.deviceSerialNumber]["vin"] !==
        vehicle["vinNumber"]
      ) {
        mismatchRows.push({
          deviceSerialNumber: vehicle.deviceSerialNumber,
          fleetId: vehicle.fleetId,
          fleetName: vehicle.fleetName,
          foundVin: locationCollection[vehicle.deviceSerialNumber]["vin"],
          vinInSystem: vehicle.vinNumber,
        });
      }
    }
  }

  /**
   * Here we define the small cards params that will sit on the top of the screen
   */
  const dashboardSmallCards: SmallInfoCardType[] = [
    {
      color: "#6960d7",
      content: `${ongoingWorkOrders.length} ongoing`,
      title: "Work Orders",
      icon: MaintenanceIcon,
    },
    {
      color: "#71cf48",
      content: `${formatCurrency(laborCost)} total`,
      title: "Labor Cost",
      icon: AccountingSideBarIconBlank,
    },
    {
      color: "#ff9f43",
      content: `${newDefects.length} new`,
      title: "Defects",
      icon: FleetInspectionsBlankIcon,
    },
    {
      color: "#11bfe9",
      content: `${inspections.length} scheduled`,
      title: "Inspections",
      icon: InspectionsWhite,
    },
  ];

  /**
   * These are the table card params, by default they extend
   * vertically on the left side of the screen
   */
  const assignedMechanics = [];
  const groupedMechanics = _.countBy(
    workOrders,
    (wo: WorkOrderType) => wo.mechanicId
  );

  for (const mechanicId in groupedMechanics) {
    const user = mechanicUsers.find(
      ({ cognitoUserId }) => cognitoUserId === mechanicId
    );
    if (user) {
      assignedMechanics.push({
        mechanic: user?.nameOfUser,
        googleDriveFileId: user?.googleDriveFileId,
        jobs: groupedMechanics[mechanicId],
        userName: user?.userName,
      });
    } else {
      const pastWorkOrder = workOrders.find(
        ({ mechanicId: id }) => mechanicId === id
      );
      if (pastWorkOrder) {
        assignedMechanics.push({
          mechanic: pastWorkOrder.mechanicInfo.mechanic,
          jobs: groupedMechanics[mechanicId],
        });
      }
    }
  }

  const reportsByVehicle: Dictionary<VehicleReport> = _.keyBy(
    reports.vehicleReports,
    "fleetId"
  );

  const remindersDataSource = servicePrograms.flatMap((e) => {
    if (
      !e.recipients.includes(userId) ||
      !e.reminderChannels.includes("Dashboard")
    ) {
      return [];
    }

    const reminders = Object.values(e.executionData).filter((x) =>
      x.dateReminder ? true : x.milesReminder || x.dateReminderForMiles
    );

    if (!reminders.length) {
      return [];
    }

    return reminders.map((v) => {
      const odometerReading = Number(
        reportsByVehicle?.[v?.fleetId]?.["odometerReading"]
      ).toFixed(2);

      let daysToReachMiles: number;
      if (odometerReading && v.nextExecutionMiles) {
        daysToReachMiles =
          (Number(v.nextExecutionMiles) -
            Number(reportsByVehicle?.[v?.fleetId]?.["odometerReading"])) /
          reportsByVehicle[v.fleetId]["averageMilesPerDay"];
      }

      return {
        programName: e.programName,
        programId: e.programId,
        fleetName: v.fleetName,
        daysToReachMiles,
        odometerReading: Number(
          reportsByVehicle?.[v?.fleetId]?.["odometerReading"]
        ).toFixed(2),
        nextExecutionMiles: v.nextExecutionMiles,
        nextExecutionDate: v.nextExecutionDate
          ? dayjsNY(v.nextExecutionDate).format("MMM DD, YYYY")
          : "N/A",
      };
    });
  });

  const dashboardListCards: ListInfoType[] = [
    remindersDataSource.length && {
      cardTitle: "Service Program Reminders",
      columns: reminderColumns,
      dataSource: remindersDataSource,
    },
    mismatchRows.length && {
      cardTitle: "Data mismatch",
      columns: mismatchColumns,
      dataSource: mismatchRows,
    },
    {
      cardTitle: "Assigned Mechanics",
      dataSource: assignedMechanics,
      columns: assignedMechanicsCols,
      showHeader: false,
    },
    {
      cardTitle: "Inspections",
      columns: fleetInspectionCols,
      dataSource: inspections,
    },
    {
      cardTitle: "Work Orders",
      columns: workOrderCols,
      dataSource: workOrders,
    },
  ].filter(Boolean);

  /**
   * Here we define the chart params
   */
  const defectsPerType = _.countBy(
    defects,
    (defect: DefectType) => defect.defectName
  );

  const inspectionsMechanics = _.countBy(
    inspections,
    (inspection: FleetInspectionType) => inspection.mechanic
  );

  const inventoryPartsUsed = {};
  for (const workOrder of workOrders) {
    for (const item of workOrder["inventoryItems"]) {
      if (!inventoryDictionary?.[item.itemId]) {
        continue;
      }

      inventoryPartsUsed[inventoryDictionary[item.itemId]] =
        (inventoryPartsUsed[item.itemId] || 0) + Number(item.quantity);
    }
  }

  const chartParams = processAnalytics(
    {
      defectsPerType,
      inspectionsMechanics,
      inventoryPartsUsed,
    },
    {
      defectsPerType: "Pie",
      inspectionsMechanics: "Vertical",
      inventoryPartsUsed: "Vertical",
    }
  );

  layout = [
    ...positionSmallCards(dashboardSmallCards),
    ...positionListCards(dashboardListCards),
    ...positionChartCards(chartParams),
  ];

  return layout;
}

export default generateDefaultOverview;
