//@ts-check
import { message } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";

import UndoRedo from "./UndoRedo/UndoRedo";
import createPDF, {
  PREVIEW,
} from "../../../../../../../integrations/createPDF";
import InvoiceDataPanel from "./InvoiceDataPanel";
import { EmailIcon, Pdf } from "../../../../../BasePage/src";
import { savePdfToDrive } from "../../../../../Safety/safetyPdf";
import { documentDefinition } from "./utils/documentDefinition";
import { sendEmailNotification } from "./utils/sendNotification";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import EmailBox from "../../../../../Communication/components/EmailBox/EmailBox";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import MondayButton from "../../../../../../commonComponents/MondayButton/MondayButton";
import MultiLevelTreeLogs from "../../../../../../commonComponents/MultiLevelTreeLogs/MultiLevelTreeLogs";
import Report from "../../../../../DynamicView/Pages/Overview/components/Report/Report";
import { compareIncluding } from "../../../../../utils/compareIncluding";

import "./ControlPanel.scss";
import { fetchAllData } from "../../../../../../../utils";

const ControlPanel = () => {
  const [isEmailBoxOpen, setIsEmailBoxOpen] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  const [visibleReport, setVisibleReport] = useState(false);

  const [editLogs, setEditLogs] = useState(false);
  const [pdfId, setPdfId] = useState("");
  const [emailBoxes, setEmailBoxes] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const { base64 } = useSelector((state) => state.base64);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const { invoiceData, projectData } = useInitialInvoiceContext();

  const driveRequest = driveApi({ accessToken });

  const { invoiceId } = invoiceData || {};

  const { googleDriveFolderIds } = projectData || {};
  const { projectId: tempGoogleDriveFolderId } = googleDriveFolderIds || {};

  const generateInvoicePdfHandler = async () => {
    const pdfTitle = `Invoice #${invoiceData.invoiceNumber}`; // Account Name - ${accountName}`;
    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;

    createPDF({
      action: PREVIEW,
      docDefinition: documentDefinition(pdfTitle, companyLogo, invoiceData),
    });

    await savePdfToDrive({
      docDefinition: documentDefinition(pdfTitle, companyLogo, invoiceData),
      accessToken,
      folderId: tempGoogleDriveFolderId,
      title: `Invoice #${invoiceData.invoiceNumber}`,
    }).then((res) => {
      setPdfId(res?.id);
    });
  };

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setIsEmailBoxOpen(false);
    }
  }

  const Icons = [
    {
      title: "Email",
      Icon: EmailIcon,
      onClick: () => setIsEmailBoxOpen((prev) => !prev),
      visible: !!invoiceId,
    },
    {
      title: "View Report",
      Icon: Pdf,
      onClick: () => setVisibleReport(true),
      visible: !!invoiceId,
    },
    {
      title: "Export to PDF",
      Icon: Pdf,
      onClick: () =>
        generateInvoicePdfHandler({
          base64,
          invoiceData,
          accessToken,
          tempGoogleDriveFolderId,
        }).then((res) => {
          setPdfId(res?.id);
        }),
      visible: !!invoiceId,
    },
  ]?.filter(Boolean);

  const openEditLogsHandler = () => {
    const hideLoading = message.loading(
      "Retrieving log data. Please wait...",
      0
    );

    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: invoiceId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((result) => {
        setEditLogs(result);
        setLogsVisible(true);
        hideLoading();
      })
      .catch((err) => {
        hideLoading();
        message.error(
          "Sorry, we couldn't retrieve the logs at the moment. Please try again later."
        );
      });
  };

  const sendEmailProps = {
    email: {
      onClose: () => {
        if (pdfId) driveRequest.deleteDriveItem(pdfId);
        setIsEmailBoxOpen(false);
      },
      setEmails: () => {},
      rowData: invoiceData,
      onSave: (e, type, setLoading) => {
        onEmailSave({ e, type, setLoading });
      },
      sendEmailNotification: () =>
        sendEmailNotification({
          userConfiguration,
          authenticatedUser,
          invoiceData,
        }),
      isRedirected: [pdfId],
      isFromCharge: true,
      proppedTemplateCat: "Invoices",
    },

    reports: {
      emailBoxes,
      onClose: () => {
        if (attachments.length > 0) setAttachments([]);
        setEmailBoxes([]);
      },
      rowData: invoiceData,
      setEmailBoxes,
      attachments,
      setAttachments,
      visibleReport,
    },
  };

  return (
    <>
      <div
        className={
          isDarkMode ? "invoiceFormContainer-dark" : "invoiceFormContainer"
        }
      >
        <div className="control-panel-container">
          <div className="control-panel-left">
            <UndoRedo />
          </div>
          <div className="control-panel-right">
            {invoiceData && (
              <InvoiceDataPanel {...{ openEditLogsHandler, invoiceData }} />
            )}
            {Icons.map(({ title, onClick, Icon, visible, disabled }, index) => {
              return (
                <MondayButton
                  key={index}
                  Icon={<Icon />}
                  className="mondayButtonBlue"
                  onClick={onClick}
                  style={{ display: visible ? "block" : "none" }}
                >
                  {title}
                </MondayButton>
              );
            })}
          </div>
        </div>
        {(isEmailBoxOpen || emailBoxes?.length > 0) && (
          <div>
            <EmailBox
              {...(isEmailBoxOpen
                ? { ...sendEmailProps?.email }
                : { ...sendEmailProps?.reports })}
            />
          </div>
        )}
      </div>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: editLogs,
            title: "Invoice Logs",
          }}
        />
      )}

      {visibleReport && (
        <Report
          {...{
            isModalVisible: visibleReport,
            onCancel: () => setVisibleReport(false),
            customCategoryName: "Invoice Report",
            customPartitionKeys: { identityId: "invoiceId" },
            recordId: invoiceId,
            setVisibleReport,
            emailBoxes,
            setEmailBoxes,
            setAttachments,
            sendByEmail: true,
          }}
        />
      )}
    </>
  );
};

export default ControlPanel;
