export function removeKeys(data, keysToRemove) {
  if (Array.isArray(data)) {
    return data.map((item) => removeKeys(item, keysToRemove)).filter(Boolean);
  }

  if (typeof data === "object" && data !== null) {
    const newData = Object.entries(data).reduce((acc, [key, value]) => {
      if (!keysToRemove.includes(key)) {
        const newValue = removeKeys(value, keysToRemove);
        if (newValue !== undefined) {
          acc[key] = newValue;
        }
      }
      return acc;
    }, {});

    if (Object.keys(newData).length === 0) {
      return undefined;
    }

    return newData;
  }

  return data;
}
