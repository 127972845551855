import { useProgramFields } from "../../../../../hooks";
import { Select } from "antd";
import { DropdownIcon } from "../../../BasePage/src";

const StatusFilter = ({
  table = "",
  filterStates = {},
  tmpFilterStates = {},
  filterValueTurnery = false,
  setFilterStates = () => {},
}) => {
  const { ["Statuses Of Categories"]: statusCategories = [] } =
    useProgramFields();

  const statuses = statusCategories?.[table] || [];

  return statuses.length ? (
    <div className="filterComponents">
      <div className="tittle">Filter By Status</div>
      <div className="filterContainer">
        <div className="leftSideContainer">
          <div className="tittleContainer">Select Status</div>
        </div>

        <div className="rightSideContainer">
          <Select
            {...{
              popupClassName: "dashDropDown",
              placeholder: "Select Status",
              suffixIcon: <DropdownIcon />,
              onSelect: (e) =>
                setFilterStates((prev) => ({
                  ...prev,
                  filterByStatus: e,
                })),
              showSearch: true,
              className: "status-selector",
              value: filterValueTurnery
                ? filterStates?.filterByStatus
                : tmpFilterStates?.filterByStatus,
              options: statuses?.map((el) => ({
                label: el?.statusName,
                value: el?.statusName,
              })),
            }}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default StatusFilter;
