import { changeBodyOrderAndFilter } from "./changeBodyOrderAndFilter";
import { changeHeaderOrderAndFilter } from "./changeHeaderOrderAndFilter";
import { excelCellFitToColumn } from "./excelCellFitToColumn";
import XLSX from "sheetjs-style";

export const exportExcel = (
  headers,
  columnsToInclude,
  rows,
  sheetName,
  title,
  download = true
) => {
  const filteredHeader = changeHeaderOrderAndFilter(headers, columnsToInclude);
  const filteredBody = changeBodyOrderAndFilter(rows, columnsToInclude);

  const updatedData = [filteredHeader, ...filteredBody];

  const worksheet = XLSX.utils.aoa_to_sheet(updatedData);
  worksheet["!cols"] = excelCellFitToColumn(updatedData);

  const workbook = XLSX.utils.book_new();

  if (!!download) {
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(workbook, `${title}.xlsx`);
  } else {
    return worksheet;
  }
};

export const uploadExcel = async (
  driveRequest,
  driveFolderId,
  headers,
  rows,
  sheetName,
  name
) => {
  try {
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    worksheet["!cols"] = excelCellFitToColumn([headers, ...rows]);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Convert the workbook to an Excel binary array
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the array buffer to a blob with the correct MIME type
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Upload the Excel file to Google Drive
    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name,
        parents: [driveFolderId],
      }
    );

    // Extract and log information about the uploaded file
    const { id, newName } = await uploadResponse.json();

    // Update state or perform any necessary actions with the file ID
    return { id, name: newName };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};
