import { AddonType } from "../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";

export class SidewalkShedService {
  serviceId = 1;
  label = "Sidewalk Shed";
  visible = true;

  serviceOptions = [];

  //serviceAddons = [] for shoring
}

export class SidewalkShedPLI {
  id;
  approved = false;
  serviceId = 1;

  length;
  height;
  width;

  addons = [];
  description;
  note;

  isJump = false;

  lock = false;
  ppu;
  rent;
  price;
  taxRate;
  taxAmount = 0;
  totalPrice = 0;

  constructor(id, taxRate = 0) {
    this.id = id;
    this.taxRate = taxRate;
  }
}

export class AlternateSidewalkShedPLI {
  id;
  approved = false;
  serviceId = 1;

  length;
  height;
  width;

  isAlternate = true;
  addons = [];
  description;
  note;

  isJump = false;

  lock = false;
  ppu;
  rent;
  price;
  taxRate;
  taxAmount = 0;
  totalPrice = 0;

  constructor(id, taxRate = 0) {
    this.id = id;
    this.taxRate = taxRate;
  }
}

export class SidewalkShedPLIAddon {
  id;
  approved = false;

  type = AddonType.pli;

  length;
  height;
  width;

  span = []; //a list of spans

  description = "";
  note = "";

  price;

  constructor(id) {
    this.id = id;
  }
}

export class SidewalkShedPLIAddonSpan {
  id;

  length = "";
  height = "";
  width = "";

  description = "";
  note = "";
  constructor(id) {
    this.id = id;
  }
}
