import { message } from "antd";
import { isWindows } from "react-device-detect";

export const EditableModeWrapper = ({
  isWritable,
  invoiceData,
  children,
  className = "",
}) => (
  <div
    onClick={() => {
      !isWritable && invoiceData && message.error("Please enable Edit Mode!");
    }}
    style={{
      cursor: isWindows ? "default" : "not-allowed",
    }}
    className={className}
  >
    {children}
  </div>
);
