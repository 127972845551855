import { message } from "antd";

import { getErrors } from "../../../../utils/getErrors";
import { updateFakeSOV } from "../utils";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const removeRetainageEvent = (
  eventId,
  isWritable,
  isLoading,
  service,
  fakeSOV,
  setSOV,
  setErrors
) => {
  if (!isWritable) {
    message.error("Please enable write mode");
  } else if (isLoading) {
    message.error("Please wait until it finishes updating");
  } else {
    // Find service that we want to edit
    const serviceToEdit = fakeSOV?.services?.find(
      (sovService) =>
        sovService.serviceId.toString() === service.serviceId.toString() &&
        sovService.estimationId === service.estimationId
    );
    let amounts = serviceToEdit.amounts;
    let event = amounts[eventId];
    event.retainage = 0;
    event.retainagePercentage = 0;
    event.collectable_amount =
      forceToNumber(event.amount) - forceToNumber(event.retainage);
    // Find the service that we are editing and update its amounts
    const newSOV = updateFakeSOV(fakeSOV, service, amounts);

    // Get errors for each service
    const errors = getErrors(newSOV);

    // Set new SOV
    setSOV(newSOV);

    // If none of services have errors set errors to null, otherwise set errors to errors object of all services
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      setErrors(null);
    } else {
      setErrors(errors);
    }
  }
};
