export const findCategoryName = (categories, word) => {
  const words = word.toLowerCase().split(/\s+/);

  for (const category of categories) {
    const commonKey = category.commonKey.toLowerCase();
    const categoryName = category.categoryName.toLowerCase();
    for (const w of words) {
      if (commonKey.includes(w) || categoryName.includes(w)) {
        return category;
      }
    }
  }

  return null;
};
