import { Image as AntdImage, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function Image({
  src,
  alt,
  width,
  height,
  fallback,
  placeholder,
  preview,
  onError,
}) {
  return (
    <div
      className="image-container"
      style={{
        borderRadius: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {src ? (
        <AntdImage
          src={src}
          alt={alt}
          width={width}
          height={height}
          fallback={fallback}
          placeholder={placeholder}
          preview={preview}
          onError={onError}
        />
      ) : (
        <div style={{ padding: 10 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
}
