import { useState } from "react";
import { DeleteIcon } from "../../../../../../Roles/src";
import { WarningModal } from "../../../../../../../../../commonComponents";
import { WarningTriangle } from "../../../../../../../../../SidebarPages/DynamicView/src";
import { TickIcon } from "../../../../../../Roles/src";
import { XIcon } from "../../../../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { useSelector } from "react-redux";

const LabelRenderer = ({ id, name, value, onPriceSchemeDeletion }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [deletionModalVisible, setDeletionModalVisible] = useState(false);

  return (
    <>
      {id !== value ? (
        <div className="price-selector-config-label">
          <div>{name}</div>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();
              setDeletionModalVisible(true);
            }}
          />
        </div>
      ) : (
        name
      )}
      <WarningModal
        visible={deletionModalVisible}
        setVisible={setDeletionModalVisible}
        title={name}
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to delete this price scheme?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => {
                setDeletionModalVisible(false);
              }}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={(e) => {
                onPriceSchemeDeletion(e);
                setDeletionModalVisible(false);
              }}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </>
  );
};

export default LabelRenderer;
