import {
  defaultColDef,
  columnDefsHoist,
  frameworkComponents,
} from "../../GridData";
import {
  useStateContext,
  useDispatchContext,
} from "../../../../Context/Context";
import { cellEditingStarted } from "./GridData/GridUtils/onCellEditingStarted";
import { getRowNodeId, onCellKeyPress } from "../../GridData/GridUtils";
import { cellValueChanged } from "./GridData/GridUtils";
import "./ServiceContent.scss";
import "../../../../../../../../Documentation/DocumentationPage.scss";

import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useState } from "react";
import AgGridMathful from "../../Service/ServiceContent/AgGridSum/AgGridMathful";

const ServiceContent = ({ service, elevation, color }) => {
  const { isLoading } = useSelector((state) => state.applications);
  const [selected, setSelected] = useState(false); // Context state & dispatch

  // Context state & dispatch
  const { fakeApplication, isWritable, darkMode } = useStateContext();
  const dispatchContext = useDispatchContext();

  const setApplication = (newApplication) => {
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
  };

  const dynamicStyle = darkMode
    ? "dark-ag-theme documentationsTable"
    : "grey-ag-theme documentationsTable";

  const rowData = cloneDeep(elevation?.amounts) || [];
  // console.log("Row Data", rowData);

  const onRangeSelectionChanged = (event) => {
    // console.log("hoist selection changed", event);
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  };
  return (
    // <ResizableBox
    //   className="applicationsBodyResizableHoist"
    //   minConstraints={[0, 0]}
    //   maxConstraints={[0, 1500]}
    //   height={270}
    //   width={1800}
    //   axis="y"
    //   handle={
    //     <div className="applicationBodyResizableGripContainerHoist">
    //       <div className="leftSize" style={{ background: color }} />
    //       <div className="grabberContainer" style={{ background: color }}>
    //         <div className="gripLines" />
    //         <div className="gripLines" />
    //         <div className="gripLines" />
    //       </div>
    //       <div className="rightSize" style={{ background: color }} />
    //     </div>
    //   }
    // >
    <div className={`agGridContainer ${dynamicStyle} ag-theme-alpine`}>
      {selected && <AgGridMathful darkMode={darkMode} event={selected} />}
      <AgGridReact
        immutableData={true}
        domLayout="normal"
        undoRedoCellEditing={true}
        enableRangeSelection={true}
        rowSelection="single"
        enableCellChangeFlash={true}
        undoRedoCellEditingLimit={50}
        // rowHeight={60}
        rowHeight={40}
        headerHeight={90}
        defaultColDef={defaultColDef}
        rowClassRules={{
          "ag-grid-this-period-req-row": (params) => params.data.thisPeriod > 0,
          "ag-grid-has-previous-req-row": (params) =>
            params.data.fromPrevious > 0,
        }}
        rowData={rowData}
        columnDefs={columnDefsHoist({ service, elevation })}
        onCellEditingStarted={(params) =>
          cellEditingStarted({ params, isWritable, isLoading })
        }
        onCellValueChanged={(params) =>
          cellValueChanged({
            eventId: params?.data?.id,
            fakeApplication,
            serviceId: service?.serviceId,
            estimationId: service?.estimationId,
            elevationId: elevation?.elevationId,
            setApplication,
            params,
          })
        }
        onRangeSelectionChanged={(event) => onRangeSelectionChanged(event)}
        frameworkComponents={frameworkComponents}
        getRowNodeId={getRowNodeId}
        onCellKeyPress={onCellKeyPress}
        stopEditingWhenGridLosesFocus={true}
        suppressDragLeaveHidesColumns={true}
        allowDragFromColumnsToolPanel={true}
        // rowGroupPanelShow={"always"}
        // sideBar={{
        //   toolPanels: [
        //     {
        //       id: "columns",
        //       labelDefault: "Columns",
        //       labelKey: "columns",
        //       iconKey: "columns",
        //       toolPanel: "agColumnsToolPanel",
        //     },
        //     {
        //       id: "filters",
        //       labelDefault: "Filters",
        //       labelKey: "filters",
        //       iconKey: "filter",
        //       toolPanel: "agFiltersToolPanel",
        //     },
        //   ],
        // }}
      />
    </div>
    // </ResizableBox>
  );
};

export default ServiceContent;
