import moment from "moment";
import XLSX from "sheetjs-style";
import createPDF from "../../../../../integrations/createPDF";
import { tableHeaders, tableHeadersExcel } from "../constants/tableHeaders";
import { defaultExcelBodyStyle } from "../../../../../helpers/constants/defaultExcelBodyStyle";
import { excelCellFitToColumn } from "../../../../../utils/excelCellFitToColumn";
import { generateDocDefinition } from "../../../../../utils/generateDocDefinition";

const PDF_TITLE = "Logged Tasks";

export const onGeneratePDF = (
  action,
  additionalData,
  getDocDefinition = false,
  headersData
) => {
  const { tasks, base64 } = additionalData;

  const columnsToInclude =
    headersData || tableHeaders.map((item, index) => index);

  const tableData = tasks.map((task) => [
    task.taskTitle,
    task.taskTopic,
    task.taskStatus,
    task.createdBy,
    moment(task.createdAt).tz("America/New_York").format("MM/DD/YYYY"),
  ]);

  const docDefinition = generateDocDefinition(
    base64,
    PDF_TITLE,
    columnsToInclude,
    tableHeaders,
    tableData
  );

  if (getDocDefinition) {
    return docDefinition;
  }

  createPDF({
    action,
    docDefinition,
    title: action === "print" ? false : "Logged Tasks.pdf",
  });
};

export const exportToExcel = (
  additionalData,
  getDocDefinition = false,
  headersData
) => {
  const { tasks } = additionalData;

  if (getDocDefinition)
    return onGeneratePDF("download", additionalData, getDocDefinition);

  const columnsToInclude =
    headersData || tableHeadersExcel.map((item, index) => index);

  const rows = tasks.map((task) => {
    return [
      { v: task?.taskTitle || "", s: defaultExcelBodyStyle },
      { v: task?.taskTopic || "", s: defaultExcelBodyStyle },
      { v: task?.taskStatus || "", s: defaultExcelBodyStyle },
      { v: task?.createdBy || "", s: defaultExcelBodyStyle },
      {
        v:
          moment(task.createdAt).tz("America/New_York").format("MM/DD/YYYY") ||
          "",
        s: defaultExcelBodyStyle,
      },
    ];
  });

  const worksheet = XLSX.utils.aoa_to_sheet([
    tableHeadersExcel.filter((col, index) => columnsToInclude.includes(index)),
    ...rows.map((row) =>
      row.filter((col, index) => columnsToInclude.includes(index))
    ),
  ]);

  worksheet["!cols"] = excelCellFitToColumn([
    tableHeadersExcel.filter((col, index) => columnsToInclude.includes(index)),
    ...rows.map((row) =>
      row.filter((col, index) => columnsToInclude.includes(index))
    ),
  ]);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, "Logged Tasks.xlsx");
};

export const uploadExcelToDrive = async (
  events,
  driveRequest,
  driveFolderId
) => {
  try {
    const rows = tasks.map((task) => {
      return [
        { v: task?.taskTitle || "", s: defaultExcelBodyStyle },
        { v: task?.taskTopic || "", s: defaultExcelBodyStyle },
        { v: task?.taskStatus || "", s: defaultExcelBodyStyle },
        { v: task?.createdBy || "", s: defaultExcelBodyStyle },
        {
          v:
            moment(task.createdAt)
              .tz("America/New_York")
              .format("MM/DD/YYYY") || "",
          s: defaultExcelBodyStyle,
        },
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([tableHeadersExcel, ...rows]);
    worksheet["!cols"] = excelCellFitToColumn([tableHeadersExcel, ...rows]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to an Excel binary array
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the array buffer to a blob with the correct MIME type
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Upload the Excel file to Google Drive
    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name: "Logged Tasks.xlsx",
        parents: [driveFolderId],
      }
    );

    // Extract and log information about the uploaded file
    const { id, newName } = await uploadResponse.json();

    // Update state or perform any necessary actions with the file ID
    return { id, name: newName };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};
