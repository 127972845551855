import { v4 as uuidv4 } from "uuid";
import { reportObj } from "./generateReport";
import { showErrorMsg } from "src/utils";

export function calculateValue(data) {
  // Check if the array is not empty
  if (data.length === 0) {
    showErrorMsg({
      content: "Array is empty. Cannot calculate report widths.",
    });
    return null;
  }

  // Calculate the result based on the number of keys in the first element
  const firstElementKeys = data.length;
  const result = 10.5 / (firstElementKeys || 1);

  return result;
}

export function filterRowDataKeys(rowData, tableColumns) {
  // Filter keys of rowData based on tableColumns
  const filteredRowData = rowData.map((row) => {
    const filteredRow = {};
    tableColumns.forEach((option) => {
      if (row.hasOwnProperty(option)) {
        filteredRow[option] = row[option];
      }
    });
    return filteredRow;
  });

  return filteredRowData;
}

const formatColumnName = (columnName) => {
  if (typeof columnName === "string") {
    const words = columnName.split(/_|(?=[A-Z])/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
};

export const extractColumnNames = (rowData) => {
  if (rowData.length > 0)
    return rowData.map((column, index) => {
      return {
        column,
        name: formatColumnName(column),
        id: index,
      };
    });
  return [];
};

export const extractColumnNamesFromTableData = (data) => {
  if (data && data.length > 0)
    return data.map((data, index) => {
      return {
        column: data,
        name: formatColumnName(data),
        id: index,
      };
    });
  return undefined;
};

export const generateDataSets = (
  dataSets,
  tableParameters,
  tableColumns,
  dataSet,
  title
) => {
  if (dataSets.length === 0) {
    return [dataSet];
  }

  return dataSets.map((dataset, index) => {
    const filters =
      index > 0
        ? tableParameters.slice(0, index).map((parameter) => ({
            FilterExpression: `=Fields!${parameter}.Value`,
            Operator: "In",
            FilterValues: [`=Parameters!${parameter}.Value`],
          }))
        : [];

    return {
      ...(filters.length > 0 && { Filters: filters }),
      WidthSensitivity: "Auto",
      Fields: tableColumns.map((column) => ({
        DataField: column,
        Name: column,
      })),
      Query: {
        CommandText: "jpath=$.*",
        DataSourceName: title,
      },
      KanatypeSensitivity: "Auto",
      CaseSensitivity: "Auto",
      AccentSensitivity: "Auto",
      Name: dataset,
    };
  });
};

export const generateReport = ({
  title,
  dataSet,
  columnWidth,
  tableColumns,
  tableParameters,
  userConfiguration,
}) => {
  const trimmedTitle = title.replace(/\s/g, "");
  const parameters = generateParameters(tableParameters, dataSet);

  const dataSets = parameters.map(
    (parameter) =>
      parameter?.ValidValues?.DataSetReference?.DataSetName || dataSet
  );

  const allDataSets = generateDataSets(
    dataSets,
    tableParameters,
    tableColumns,
    dataSet,
    title
  );
  const dataSources =
    dataSets.length > 0 ? dataSets.map((data) => title) : [title];

  const newReport = {
    categoryName: title,
    createdAt: Date.now(),
    createdBy: userConfiguration?.nameOfUser,
    datasets: allDataSets,
    datasources: parameters.length > 0 ? dataSources : [title],
    hasSelectedFavorite: [],
    reportId: uuidv4(),
    reportName: "Base Page Report",
    reportObj: {
      definition: reportObj({
        title,
        parameters,
        dataSets: allDataSets,
        dataSources,
        columnWidth,
        tableColumns,
        tableParameters,
      }).definition,
      displayName: `${trimmedTitle}Report`,
      id: "7e2cf0b8-a913-4b9e-99af-4caff2c0223f",
    },
    reportParameters: tableParameters,
    thumbnail: "",
    userId:
      "16d08e02a73ab9d99f7160dc07ffd50c9acd1b275899078c58a144cd7f70788bef9dd8409b2e3f1c875bfd9c30cdd59a",
  };
  return newReport;
};

export const findParentCategoryForProject = (
  reportCategorization,
  projectName
) => {
  for (const parentCategory in reportCategorization) {
    const foundProject = reportCategorization[parentCategory].find(
      (item) => item.categoryName === projectName
    );

    if (foundProject) {
      return parentCategory;
    }
  }

  return null; // Return null if the project is not found
};

export const generateParameter = (
  dataSetName,
  labelField,
  valueField,
  prompt,
  multiValue
) => {
  return {
    DataType: "String",
    Prompt: prompt,
    ValidValues: {
      OrderBy: {
        Condition: "Label",
      },
      DataSetReference: {
        LabelField: labelField,
        DataSetName: dataSetName,
        ValueField: valueField,
      },
    },
    MultiValue: multiValue,
    Name: labelField,
  };
};

export const generateParameters = (inputList, dataSet) => {
  if (!inputList.length) {
    return [];
  }
  return inputList.map((labelField) => {
    const dataSetName = dataSet.Name + labelField;
    const valueField = labelField;
    const prompt = `Select ${labelField
      .charAt(0)
      .toUpperCase()}${labelField.slice(1)}`;

    return generateParameter(dataSetName, labelField, valueField, prompt, true);
  });
};

export const generateFilters = (data) => {
  var filterArray = [];

  data.forEach(function (field) {
    var filterItem = {
      FilterExpression: "=Fields!" + field + ".Value",
      Operator: "In",
      FilterValues: ["=Parameters!" + field + ".Value"],
    };
    filterArray.push(filterItem);
  });

  return filterArray;
};
