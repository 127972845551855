import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Checkbox } from "antd";
import LoginButton from "../../../../components/LoginButton";
import { emailRegex } from "../../../../components/SidebarPages/utils/validURL";
import { ReactComponent as UserIcon } from "../../../../icons/Auth_icons/user_icon.svg";
import { ReactComponent as LockIcon } from "../../../../icons/Auth_icons/lock_icon.svg";
import { ReactComponent as EyeIcon } from "../../../../icons/Auth_icons/eye_icon.svg";
import { ReactComponent as EyeClosedIcon } from "../../../../icons/Auth_icons/eye_closed_icon.svg";

export const LoginFormData = ({
  onFinish,
  handleKeyPress,
  isLoading,
  timeLeft,
}) => {
  const [form] = Form.useForm();

  return !timeLeft ? (
    <Form form={form} onFinish={onFinish} initialValues={{ remember: false }}>
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            pattern: new RegExp(emailRegex),
            message: "Invalid username",
          },
        ]}
      >
        <Input
          placeholder="Username/Email..."
          prefix={<UserIcon style={{ height: 25 }} />}
          onKeyPress={(e) => handleKeyPress(e)}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password
          placeholder="Password..."
          prefix={<LockIcon style={{ height: 25 }} />}
          iconRender={(visible) => (visible ? <EyeIcon /> : <EyeClosedIcon />)}
          onKeyPress={(e) => handleKeyPress(e)}
        />
      </Form.Item>
      <div className="signin_options">
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <Link to="/forgotpassword">
          <p>Forgot password?</p>
        </Link>
      </div>
      <Form.Item>
        <LoginButton
          type="primary"
          className="signin-btn"
          htmlType="submit"
          isLoading={isLoading}
          text="Sign In"
          loadingText="Signing In"
        />
      </Form.Item>
    </Form>
  ) : (
    <div className="attempt_exceeded_wrapper">
      <p>
        Your password attempts have been exceeded. You can try again in{" "}
        <b>{timeLeft}</b> or reset your password for immediate access.
      </p>

      <Link to="/forgotpassword">
        <p>Forgot password?</p>
      </Link>
    </div>
  );
};
