import React from "react";
import { Badge } from "antd";

import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import MondayButton from "../../../MondayButton/MondayButton";

const TableStepHeader = ({
  recordsConflicts,
  onFilterTextChange,
  setConflictsTableVisible,
}) => {
  return (
    <div className="table-control-panel">
      <InputComponent
        type="input"
        placeholder="Search record..."
        onChange={(e) => onFilterTextChange(e.target.value)}
      />
      {!!recordsConflicts?.newRecords?.length && (
        <Badge count={recordsConflicts?.newRecords?.length}>
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={() => setConflictsTableVisible(true)}
          >
            Conflicts
          </MondayButton>
        </Badge>
      )}
    </div>
  );
};

export default TableStepHeader;
