export const claims = ({ tables, circleProgressPicker }) => {
  return {
    claimStatus: () => ({
      percent: (
        (tables?.Claims?.filter((e) => e?.claimStatus === circleProgressPicker)
          ?.length /
          tables?.Claims?.length) *
        100
      )?.toFixed(1),
    }),
  };
};
