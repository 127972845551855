import ServiceButton from "./Components/ServiceButton/ServiceButton";
import { useRedux } from "../../../../../../hooks";
import "./SideBar.scss";
import { useMemo } from "react";

// import { useStateContext } from "../../Context/Context"
// import { useLayoutEffect, useRef } from "react"

const SideBar = ({ proposedTypeOfWork, retract }) => {
  const [fakeRentals] = useRedux("fakeRentals");
  const [darkMode] = useRedux("rentalsDarkMode");
  const [color] = useRedux("rentalsColor");

  // const { fakeRentals, color, darkMode } = useStateContext()
  //TODO: Add isChange order icon
  //extract all services that are in this rental
  const services = useMemo(() => {
    return fakeRentals?.services?.map(
      ({ label, estimationNumber = false, serviceId, isScope = false }) => ({
        label,
        estimationNumber,
        serviceId,
        isScope,
      })
    );
  }, [fakeRentals?.services]);

  return (
    <div
      className={
        darkMode
          ? retract
            ? "sideBarContainerRetractedDark"
            : "sideBarContainerDark"
          : retract
          ? "sideBarContainerRetractedLight"
          : "sideBarContainerLight"
      }
      style={{
        boxShadow: !retract ? `0px 0px 6px 3px ${color}` : "unset",
      }}
    >
      <div className="headerContainer">Services</div>
      <div className="servicePickerBody">
        {services?.map((service, index) => (
          <ServiceButton
            {...{
              service,
              darkMode,
              proposedTypeOfWork,
              key: index + "keywow",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SideBar;
