import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useRedux } from "../../../hooks";
import { XIcon } from "../../../Communication/assets";
import { navigateToRoute } from "./utils/generateRoute";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { AccountingConfirmationModal } from "../../../../commonComponents";
import AccountingWriteMode from "../../../../commonComponents/AccountingWriteMode/AccountingWriteMode";

import "./Header.scss";

const Header = ({ closeModal }) => {
  const [isWritable, setIsWritable] = useRedux("billIsWritable");
  const [confirmData, setConfirmData] = useState();
  let isSaveButtonClicked = false;

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { rowData, handleSave } = useCreateBillContext();

  const { billData } = useInitialBillContext();

  const navigate = useNavigate();

  const handleCancel = () => {
    closeModal();
    navigateToRoute(billData, navigate);
    setConfirmData();
  };

  return (
    <div className="bill-modal-header">
      <div className="bill-header-left">
        <div>
          {billData ? `Edit Bill # ${billData?.billNumber}` : "New Bill"}
        </div>
        {billData && (
          <AccountingWriteMode
            {...{
              isWritable,
              recordId: billData?.billId,
              onSwitchFunction: setIsWritable,
            }}
          />
        )}
        {billData && <div>{billData.vendorName}</div>}
      </div>
      <div
        className="close-btn"
        data-testid="close-btn"
        onClick={() => {
          rowData.length > 0
            ? setConfirmData({
                ...{
                  visible: true,
                  setVisible: setConfirmData,
                  onConfirm: () => {
                    if (!isSaveButtonClicked) {
                      isSaveButtonClicked = true;
                      handleSave()
                        .then(() => {
                          isSaveButtonClicked = false;
                        })
                        .then(setConfirmData());
                    }
                  },
                  onCancel: () => {
                    setConfirmData();
                  },
                  onDiscard: () => {
                    handleCancel();
                  },
                  text: billData
                    ? "Any change that you have made will be lost?"
                    : "This bill will be discarded",
                  title: "Confirm Close",
                },
              })
            : handleCancel();
        }}
      >
        <XIcon />
      </div>
      {confirmData && rowData.length > 0 && (
        <AccountingConfirmationModal
          {...{
            ...confirmData,
            callBackFunc: () => setConfirmData(),
            darkMode: isDarkMode,
          }}
        />
      )}
    </div>
  );
};

export default Header;
