import { ScopeIcon } from "../../../../../../../../../../icons";
import { useRedux } from "../../../../../../../../hooks";
import "./ServiceButton.scss";

// import { useDispatchContext, useStateContext } from "../../../../Context/Context"

import { Checkbox } from "antd";

const ServiceButton = ({ service, darkMode, proposedTypeOfWork }) => {
  const [selectedService, setSelectedService] = useRedux(
    "rentalsSelectedService"
  );
  // const [darkMode] = useRedux("rentalsDarkMode");
  const [, setColor] = useRedux("rentalsColor");

  // const { selectedService, darkMode } = useStateContext()
  // const dispatchContext = useDispatchContext()

  const color =
    proposedTypeOfWork?.find(({ workName }) => workName === service?.label)
      ?.colorCode || "#fff";

  //is called when we pres one of the services
  const servicePickerHandler = () => {
    //here we change the selected service
    setSelectedService(service?.label);
    //here we set the color to the selected service color
    setColor(color);

    // dispatchContext({
    //   type: "SET_SELECTED_SERVICE",
    //   payload: service,
    // })
    // dispatchContext({
    //   type: "SET_COLOR",
    //   payload: color,
    // })
  };

  console.log("service444", service);

  return (
    <div
      className={
        darkMode
          ? "serviceButtonContainerRentalsDark"
          : "serviceButtonContainerRentalsLight"
      }
    >
      <button
        className={
          service?.label === selectedService
            ? "selectedService"
            : "selectServiceButton"
        }
        onClick={servicePickerHandler}
      >
        <Checkbox checked={service?.label === selectedService} />{" "}
        {service?.label} {service?.isScope && <ScopeIcon />}
        {service?.estimationNumber && `[Est. #${service?.estimationNumber}]`}
        <div className="suffix" style={{ backgroundColor: color }} />
      </button>
    </div>
  );
};

export default ServiceButton;
