export const filtersOptions = ({ rowData, key }) => {
  return [
    ...new Set(
      rowData
        ?.flatMap(({ [key]: keyDatas } = {}) => {
          if (Array.isArray(keyDatas)) {
            return keyDatas?.map((obj) =>
              typeof obj === "object" && obj?.hasOwnProperty("nameOfUser")
                ? obj.nameOfUser
                : typeof obj === "string" || typeof obj === "number"
                ? obj
                : false
            );
          } else if (typeof keyDatas === "boolean") {
            return keyDatas ? "yes" : "no";
          } else if (typeof keyDatas === "object") {
            return keyDatas?.hasOwnProperty("name")
              ? keyDatas.name
              : keyDatas?.hasOwnProperty("nameOfUser")
              ? keyDatas.nameOfUser
              : typeof keyDatas === "string" || typeof keyDatas === "number"
              ? keyDatas
              : false;
          } else {
            return keyDatas;
          }
        })
        .filter(Boolean)
    ),
  ].map((el) => {
    return {
      value: el,
      label: el,
    };
  });
};
