import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const defects = (
  defectsData,
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  const defectStatuses = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Defects;

  const defectPriority = programFields?.find?.(
    ({ fieldName }) => fieldName === "Priority Status"
  )?.fieldOptions;

  return {
    defectStatus: () => ({
      title: "Defects Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Defects",
        field: "defectStatus",
        labels: defectStatuses?.map?.(({ statusName }) => statusName),
        datasets: [
          {
            label: "Defects",
            data: uniq(defectsData?.map((a) => a?.defectStatus))?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (a) => a.defectStatus?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: defectStatuses?.map((a) => a?.statusColor),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    defectName: () => ({
      title: "Defects Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Defects",
        field: "defectName",
        labels: uniq(defectsData?.map((a) => a?.defectName)),
        datasets: [
          {
            label: "Defects",
            data: uniq(defectsData?.map((a) => a?.defectName))?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (a) => a?.defectName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(defectsData?.map((a) => getRandomColor)),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    driverName: () => ({
      title: "Defects: Driver Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Defects",
        field: "driverName",
        labels: uniq(defectsData?.map((a) => a?.driverName)),
        datasets: [
          {
            label: "Defects",
            data: uniq(defectsData?.map((a) => a?.driverName))?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (a) => a?.driverName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(defectsData?.map((a) => getRandomColor)),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    fleetName: () => ({
      title: "Defects: Fleet Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Defects",
        field: "fleetName",
        labels: uniq(defectsData?.map((a) => a?.fleetName)),
        datasets: [
          {
            label: "Defects",
            data: uniq(defectsData?.map((a) => a?.fleetName))?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(defectsData?.map((a) => getRandomColor)),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    inspectionName: () => ({
      title: "Defects: Inspection Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Defects",
        field: "inspectionName",
        labels: uniq(defectsData?.map((a) => a?.inspectionName)),
        datasets: [
          {
            label: "Defects",
            data: uniq(defectsData?.map((a) => a?.inspectionName))?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (a) =>
                    a?.inspectionName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(defectsData?.map((a) => getRandomColor)),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    defectPriority: () => ({
      title: "Defects Priority",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Defects",
        field: "defectPriority",
        labels: defectPriority?.map?.(({ statusName }) => statusName),
        datasets: [
          {
            label: "Defects",
            data: uniq(defectsData?.map((a) => a?.defectPriority))?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (a) =>
                    a?.defectPriority?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(defectsData?.map((a) => getRandomColor)),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
