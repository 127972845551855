const claimFooterButtons = ({ onCancel, saving, onSave, suffix }) => [
  {
    text: "Cancel",
    cancel: true,
    onClick: onCancel,
    className: "mondayButtonRed",
    tooltipCategory: "Claims",
    tooltipKey: "cancel",
  },
  {
    text: "Save",
    primary: true,
    onClick: onSave,
    disabled: !!saving,
    suffix: suffix,
    className: "saveButton",
    tooltipCategory: "Claims",
    tooltipKey: "save",
  },
];

export default claimFooterButtons;
