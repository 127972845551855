import "./FormCard.scss";
import { UpOutlined } from "@ant-design/icons";
import { useState } from "react";

const FormCard = ({
  title,
  titleClassName = "",
  children,
  childrenClassName = "",
  style = {},
  className = "",
  childrenStyle = {},
  collapsible = false,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState();
  return (
    <div
      {...props}
      className={`formCard ${className}`.trim()}
      style={!collapsed ? style : { ...style, height: 77 }}
    >
      {!!title && (
        <div className={`formCardTitle ${titleClassName}`.trim()}>{title}</div>
      )}
      {!!collapsible ? (
        <UpOutlined
          className="collapseIcon"
          onClick={() => setCollapsed(!collapsed)}
          style={!!collapsed ? { transform: "rotate(180deg)" } : {}}
        />
      ) : null}
      <div
        className={`childrenContainer ${childrenClassName}`.trim()}
        style={
          !collapsed
            ? { ...childrenStyle }
            : { visibility: "hidden", height: 0 }
        }
      >
        {children}
      </div>
    </div>
  );
};

export default FormCard;
