import dayjs from "dayjs";
import { API } from "aws-amplify";

import {
  showErrorMsg,
  showLoadingMsg,
} from "../../../../../../utils/windowMessages";
import { filterTables } from "../../../../../../utils/filterTables";

export async function updateInvoices(updateInvoice) {
  try {
    await Promise.all(
      updateInvoice.map(async (invoice) => {
        const newInvoiceDueAmount = invoice.invoiceAmountDue;

        const hasOtherPayments =
          newInvoiceDueAmount === invoice.totalInvoiceAmount;

        await API.put("invoices", `/invoices/${invoice?.invoiceId}`, {
          body: {
            amountDue: newInvoiceDueAmount,
            invoiceStatus: hasOtherPayments ? "Created" : "Paid",
          },
        });
      })
    );
  } catch (err) {
    showErrorMsg({ content: "There was a problem updating Invoices" });
  }
}

export async function updateClientBalance(selectedData) {
  showLoadingMsg({ content: "Updating client balance. Please wait..." });

  try {
    const account = await filterTables(
      "accounts",
      "accountId",
      selectedData?.accountId
    );
    const updatedBalance =
      account[0].clientBalance + selectedData.paymentAmount;

    await API.put("accounts", `/accounts/${selectedData?.accountId}`, {
      body: {
        clientBalance: updatedBalance,
      },
    });
  } catch (error) {
    showErrorMsg({ content: "There was a problem updating clientBalance" });
  }
}

export async function deletePayment(selectedData, setData, setAllPayments) {
  try {
    await API.del("payments", `/payments/${selectedData.paymentId}`).then(
      () => {
        setData((prev) =>
          prev.filter(({ paymentId }) => paymentId !== selectedData?.paymentId)
        );
        setAllPayments((prev) =>
          prev.filter(({ paymentId }) => paymentId !== selectedData?.paymentId)
        );
      }
    );
  } catch (err) {
    showErrorMsg({ content: "There was a problem deleting payment" });
  }
}

export async function createEditLog(selectedData, saveAddedLogs) {
  try {
    await saveAddedLogs({
      recordId: selectedData.paymentId,
      recordName: `No. ${selectedData?.quickBooksNumber}`,
      actionType: "Delete",
      topic: selectedData?.projectName,
      category: "Payments",
    });
  } catch (err) {
    showErrorMsg({ content: "There was a problem creating edit log" });
  }
}
