import { API } from "aws-amplify";
import dayjs from "dayjs";
import { getWeeksDiff } from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/columnDefs";
import moment from "moment-timezone";
import AmountReq from "../../../../../ServiceList/Service/ServiceContent/GridData/GridUtils/amountFactory";
// var duration = require("dayjs/plugin/duration");
// dayjs.extend(duration);

moment.tz.setDefault("America/New_York");

//here we extract the label and the total price from rentals that we selected
export const rentalDataExtractor = ({ rental }) => {
  const temp = rental?.services?.map((serviceData) => {
    const { label, serviceOptions, retainage = 0 } = serviceData;
    //total amount of the service
    const appliedAmount =
      serviceOptions
        ?.flatMap(({ items }) =>
          // from the extracted services we extract all weeks and take the amount from those weeks
          items?.flatMap(({ rentalDetails }) =>
            rentalDetails?.details?.flatMap(({ days }) =>
              days?.map(({ dailyAmount = 0 }) => dailyAmount)
            )
          )
        )
        ?.reduce((acc, curr) => acc + curr, 0) || 0;

    const weeks = serviceOptions
      ?.flatMap(({ items }) => items)
      ?.flatMap(({ rentalDetails }) => rentalDetails?.details)
      ?.map((d) => {
        return {
          ...d,
          days: d?.days?.map((day) => ({
            ...day,
            // day: moment(day?.day).add(7, "hours").valueOf(),
          })),
        };
      });

    //here we extract the smallest timestamp of pli's, initially we put a very big value in the acc, and compare the curr
    //value with the acc, if the acc is bigger than curr that curr will become the new acc else the acc will remain unchanged
    const startDate = !!weeks?.length
      ? weeks?.reduce(
          (acc, curr) =>
            acc > curr?.weekStart ? (acc = curr?.weekStart) : acc,
          99999999999999999999999
        )
      : undefined;

    //here we extract the smallest timestamp of pli's, initially we put a very small value in the acc, and compare the curr
    //value with the acc, if the acc is smaller than curr that curr will become the new acc else the acc will remain unchanged
    const endDate = !!weeks?.length
      ? weeks?.reduce(
          (acc, curr) => (acc < curr?.weekEnd ? (acc = curr?.weekEnd) : acc),
          -9999999999999999999999
        )
      : undefined;

    // const weeksNo = +dayjs
    // 	?.duration(endDate - startDate)
    // 	?.asWeeks()
    // 	?.toFixed(1);
    const msInWeek = moment(endDate).diff(moment(startDate), "days") + 1;
    let temp = (Math.abs(msInWeek) / 7).toFixed(1);

    let myInitializor = new AmountReq({
      label,
      appliedAmount,
      startDate: moment(startDate).add(7, "hours").valueOf(),
      endDate: moment(endDate).add(7, "hours").valueOf(),
      weeks: temp !== "0.1" ? temp : 0,
      serviceOptions,
      taxRate: rental?.taxRate,
      retainage: retainage,
    });
    myInitializor.includeRental(_, serviceData);
    return {
      ...myInitializor.getData(),
    };
  });
  return temp;
};

export const redirectToRental = ({ rentalId }) => {
  window.open(`/rentals/${rentalId}`, "_blank");
};

//this function is called when
export const confirmIncludedRentalsHandler = ({
  updateApplication,
  setIsModalVisible,
  fakeApplication,
  selectedRentals,
  dispatchContext,
  rentals,
  dispatch,
  applications,
  saveAddedLogs,
}) => {
  const applicationId = fakeApplication?.applicationId;

  //here we generate the new application with the added key(included rentals) that we will save
  const newApplication = {
    ...fakeApplication,
    applicationId,
    includedRentals: selectedRentals?.map((selected) => {
      const rental = rentals?.find((rental) => rental?.rentalId === selected);

      return {
        rentalNumber: rental?.rentalNumber || 0,
        rentalId: rental?.rentalId,
        rentalStatus: rental?.rentalStatus,
        services: rentalDataExtractor({ rental }),
      };
    }),
  };

  //here we extrackt all rental id  that we exklude from this application
  const removingFromInclude = fakeApplication?.includedRentals
    ?.map(({ rentalId }) => rentalId)
    ?.filter(
      (previouslyData) =>
        !selectedRentals?.some(
          (selectedData) => previouslyData === selectedData
        )
    );

  //with this function we updates all rentals that we exclude in this application
  removingFromInclude?.forEach((thiRental) => {
    API.put("rentals", `/rentals/${thiRental}`, {
      body: { includedTo: "" },
    });
  });

  //updates application on state
  //REMOVED CAUSING INFINITE LOOP (WILL BE ADDED TO STATE BY UNDO/REDO FUNCTION)
  //dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
  ///updated this application on db
  dispatch(
    updateApplication(
      applicationId,
      newApplication,
      applications,
      saveAddedLogs
    )
  );
  //when this function is called the modal is closed
  setIsModalVisible(false);

  //with this function we updates all rentals that we include in this application
  selectedRentals?.forEach((thiRental) => {
    API.put("rentals", `/rentals/${thiRental}`, {
      body: { includedTo: applicationId },
    });
  });
};

/// Unchain Rental from application
export const unchainRentalsFromApp = async (appliedRentals) => {
  appliedRentals.forEach(async (rental) => {
    await API.put("rentals", `/rentals/${rental}`, {
      body: {
        includedTo: "",
      },
    });
  });
};
