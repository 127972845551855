import uniq from "lodash/uniq";
import {
  calculateInvoiceStatus,
  categoriesName,
  getSums,
  groupBy,
} from "../../../../Dashboard/logicDataFunctions/calculatingFunctions";
import {
  formatCurrency,
  getMonthsTimestamps,
  getRandomColor,
} from "../../../../utils";
import { preventDuplicate } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import {
  costChartData,
  getProjectCostData,
  months,
} from "../../../../Dashboard/addWidgetsModalData";
import { repeatedElementSum } from "../../../../Dashboard/NewDashboardFunctions";
//this return the filtered data fo the specific category and subCategory (example .Leads.LeadStatus)
export const PieFilterFunctions = ({
  tables,
  programFields,
  updatedDynamicStates,
  id,
  boroughs,
  // projectsData,
  // fleetData,
  // claimsData,
  // incidentsData,
  // workOrdersData,
  // defectsData,
  // inspectionsData,
  // engData,
  // archData,
  dynamicStates,
  // dispatchData,
  clear = false,
  userConfiguration,
}) => {
  const dataToFilter = clear ? dynamicStates[id] : updatedDynamicStates[id];
  const statusesOfCategories = programFields?.find(
    (el) => el?.fieldName === "Statuses Of Categories"
  )?.fieldOptions;

  return {
    Leads: () => ({
      leadStatus: () => ({
        data: programFields
          ?.find((a) => a?.fieldName === "Lead Status Types")
          ?.fieldOptions?.map(
            (status) =>
              dataToFilter?.filter((a) => a?.leadStatus === status)?.length
          ),
        labels: programFields?.find((a) => a?.fieldName === "Lead Status Types")
          ?.fieldOptions,
        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "Status colors")
          ?.fieldOptions?.Leads?.map((a) => a.statusColor),
      }),

      leadAddress: () => ({
        data: boroughs?.map(
          (status) =>
            dataToFilter?.filter(
              (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
        labels: boroughs,
        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "NY Boroughs")
          ?.fieldOptions?.map((a) => a.boroughColor),
      }),

      projectExecutive: () => ({
        labels: Object.keys(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        data: Object.values(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        backgroundColor: uniq(dataToFilter?.map((a) => getRandomColor())),
      }),
    }), //Leads

    Claims: () => ({
      claimStatus: () => ({
        data: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Claims?.map(
            (status) =>
              dataToFilter?.filter((a) => a?.claimStatus === status.statusName)
                ?.length
          ),
        labels: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Claims?.map((a) => a.statusName),
        backgroundColor: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Claims?.map((e) => e?.statusColor),
      }),
    }),

    Defects: () => ({
      defectStatus: () => ({
        data: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Defects?.map(
            (status) =>
              dataToFilter?.filter((a) => a?.defectStatus === status.statusName)
                ?.length
          ),
        labels: programFields
          ?.find?.(({ fieldName }) => fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Defects?.map((a) => a.statusName),
        backgroundColor: programFields
          ?.find?.(({ fieldName }) => fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Defects?.map((a) => a.statusColor),
      }),
    }),

    "Work Orders": () => ({
      workOrderStatus: () => ({
        data: uniq(dataToFilter?.map((a) => a?.workOrderStatus))?.map(
          (status) =>
            dataToFilter?.filter(
              (a) => a.workOrderStatus?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
        labels: uniq(dataToFilter?.map((a) => a?.workOrderStatus)),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),
    }),
    Applications: () => {
      const applicationData = programFields
        ?.find((a) => a?.fieldName === "Status of Requisition")
        ?.fieldOptions?.map(({ value }) =>
          dataToFilter?.filter((a) => a?.applicationStatus === value)
        );
      return {
        applicationStatus: () => [
          {
            data: applicationData?.map((e) => e?.length),
            custom: groupBy(applicationData, "projectName")?.map(
              (el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Current Payment": formatCurrency(
                        getSums({ data: applicationData, key: "totalAmount" })[
                          index
                        ]
                      ),
                      "Total Retainage": formatCurrency(
                        getSums({
                          data: applicationData,
                          key: "totalRetainage",
                        })[index]
                      ),
                      "Total Rental With Tax": formatCurrency(
                        getSums({
                          data: applicationData,
                          key: "totalRentalWithTax",
                        })[index]
                      ),
                    },
                  },
                  ...Object?.entries(el)?.map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Current Payment": formatCurrency(
                          getSums({ data: value, key: "totalAmount" })
                        ),
                        "Total Retainage": formatCurrency(
                          getSums({ data: value, key: "totalRetainage" })
                        ),
                        "Total Rental With Tax": formatCurrency(
                          getSums({ data: value, key: "totalRentalWithTax" })
                        ),
                      },
                    };
                  }),
                ];
              }
            ),
          },
        ],
      };
    },

    Rentals: () => {
      const rentalsData = programFields
        ?.find((a) => a?.fieldName === "Status of Rentals")
        ?.fieldOptions?.map(({ statusName }) =>
          dataToFilter?.filter((a) => a?.rentalStatus === statusName)
        );

      return {
        rentalStatus: () => [
          {
            data: rentalsData.map((e) => e?.length),
            custom:
              !!rentalsData[0] &&
              groupBy(rentalsData, "projectAddress").map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Total Price": formatCurrency(
                        getSums({ data: rentalsData, key: "totalPrice" })[index]
                      ),
                      "Total Applied Rent": formatCurrency(
                        getSums({
                          data: rentalsData,
                          key: "totalAppliedRent",
                        })[index]
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: rentalsData, key: "totalTax" })[index]
                      ),
                      "Retainage Amount": formatCurrency(
                        getSums({
                          data: rentalsData,
                          key: "retainageAmount",
                        })[index]
                      ),
                    },
                  },
                  ...Object?.entries(el)?.map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Total Price": formatCurrency(
                          getSums({ data: value, key: "totalPrice" })
                        ),
                        "Total Applied Rent": formatCurrency(
                          getSums({ data: value, key: "totalAppliedRent" })
                        ),
                        "Total Tax": formatCurrency(
                          getSums({ data: value, key: "totalTax" })
                        ),
                        "Retainage Amount": formatCurrency(
                          getSums({ data: value, key: "retainageAmount" })
                        ),
                      },
                    };
                  }),
                ];
              }),
          },
        ],
      };
    },

    Charges: () => {
      const chargesData = programFields
        ?.find((a) => a?.fieldName === "Status of Charges")
        ?.fieldOptions?.map(({ statusName }) =>
          dataToFilter?.filter((a) => a?.categoryFrom === statusName)
        );

      return {
        categoryFrom: () => [
          {
            data: chargesData?.map((e) => e?.length),
            custom: groupBy(chargesData, "projectAddress").map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Charge Amount With Tax": formatCurrency(
                      getSums({ data: chargesData, key: "chargeAmount" })[
                        index
                      ] || 0
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: chargesData, key: "taxAmount" })[index] ||
                        0
                    ),
                    "Charge Amount Without Tax": formatCurrency(
                      (getSums({ data: chargesData, key: "chargeAmount" })[
                        index
                      ] || 0) -
                        (getSums({ data: chargesData, key: "taxAmount" })[
                          index
                        ] || 0)
                    ),
                  },
                },
                ...Object.entries(el).map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Charge Amount With Tax": formatCurrency(
                        getSums({ data: value, key: "chargeAmount" })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: value, key: "taxAmount" })
                      ),
                      "Charge Amount Without Tax": formatCurrency(
                        getSums({ data: value, key: "chargeAmount" }) -
                          getSums({ data: value, key: "taxAmount" })
                      ),
                    },
                  };
                }),
              ];
            }),
          },
        ],
      };
    },
    Invoices: () => {
      const invoicesData = programFields
        ?.find((a) => a?.fieldName === "Status of Invoices")
        ?.fieldOptions?.map(({ statusName }) =>
          calculateInvoiceStatus({
            table: dataToFilter,
            status: statusName,
          })
        );

      return {
        invoiceStatus: () => [
          {
            data: invoicesData.map((e) => e?.length),
            custom: groupBy(invoicesData, "projectName")?.map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({
                        data: invoicesData,
                        key: "totalInvoiceAmount",
                      })[index] || 0
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: invoicesData, key: "subtotal" })[index] ||
                        0
                    ),
                    "Total Tax": formatCurrency(
                      (getSums({
                        data: invoicesData,
                        key: "totalInvoiceAmount",
                      })[index] || 0) -
                        (getSums({ data: invoicesData, key: "subtotal" })[
                          index
                        ] || 0)
                    ),
                  },
                },
                ...Object.entries(el).map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Invoice Amount With Tax": formatCurrency(
                        getSums({ data: value, key: "totalInvoiceAmount" })
                      ),
                      "Invoice Amount Without Tax": formatCurrency(
                        getSums({ data: value, key: "subtotal" })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: value, key: "totalInvoiceAmount" }) -
                          getSums({ data: value, key: "subtotal" })
                      ),
                    },
                  };
                }),
              ];
            }),
          },
        ],
      };
    },

    Opportunities: () => ({
      opportunityStatus: () => ({
        data: programFields
          ?.find((a) => a?.fieldName === "Status of opportunities")
          ?.fieldOptions?.map((a) => a?.statusName)
          ?.map(
            (status) =>
              dataToFilter?.filter(
                (a) =>
                  a?.opportunityStatus?.toLowerCase() === status?.toLowerCase()
              )?.length
          ),
        labels: programFields
          ?.find((a) => a?.fieldName === "Status of opportunities")
          ?.fieldOptions?.map((a) => a?.statusName),
        backgroundColor: programFields
          ?.find((a) => a?.fieldName == "Status of opportunities")
          ?.fieldOptions?.map((a) => getRandomColor()),
      }),

      opportunityStage: () => ({
        data: programFields
          ?.find((a) => a?.fieldName === "Opportunity Stage")
          ?.fieldOptions?.map((a) => a?.stageName)
          ?.map(
            (status) =>
              dataToFilter?.filter(
                (a) =>
                  a?.opportunityStage?.toLowerCase() === status?.toLowerCase()
              )?.length
          ),
        labels: programFields
          ?.find((a) => a?.fieldName === "Opportunity Stage")
          ?.fieldOptions?.map((a) => a?.detailedStageName),
        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "Status colors")
          ?.fieldOptions?.Opportunities?.map((a) => a.statusColor),
      }),
      projectExecutive: () => ({
        data: Object.values(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        labels: Object.keys(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      projectManager: () => ({
        data: Object.values(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ),
        labels: Object.keys(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      geoFenceInfo: () => ({
        data: [
          dataToFilter?.reduce(
            (sum, { geoFenceInfo }) => sum + (geoFenceInfo?.length > 0 ? 1 : 0),
            0
          ),
          dataToFilter?.reduce(
            (sum, { geoFenceInfo }) =>
              sum + (geoFenceInfo?.length === 0 ? 1 : 0),
            0
          ),
        ],

        labels: [
          "Opportunities With Geofences",
          "Opportunities Without Geofences",
        ],

        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "Status of opportunities")
          ?.fieldOptions?.map((a) => a?.statusColor),
      }),
    }), //Opportunities
    Projects: () => ({
      projectStatus: () => ({
        data: programFields
          ?.find((a) => a?.fieldName === "Project Status")
          ?.fieldOptions?.map((a) => a?.statusName)
          ?.map(
            (status) =>
              dataToFilter?.filter(
                (a) => a?.projectStatus?.toLowerCase() === status?.toLowerCase()
              )?.length
          ),
        labels: programFields
          ?.find((a) => a?.fieldName == "Project Status")
          ?.fieldOptions?.map((a) => a.statusName),
        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "Status colors")
          ?.fieldOptions?.Projects?.map((a) => a.statusColor),
      }),

      attorney: () => ({
        data: Object.values(repeatedElementSum(dataToFilter || [], "attorney")),
        labels: Object.keys(repeatedElementSum(dataToFilter || [], "attorney")),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      expeditor: () => ({
        data: Object.values(
          repeatedElementSum(dataToFilter || [], "expeditor")
        ),
        labels: Object.keys(
          repeatedElementSum(dataToFilter || [], "expeditor")
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),
      projectExecutive: () => ({
        data: Object.values(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        labels: Object.keys(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      projectManager: () => ({
        data: Object.values(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ),
        labels: Object.keys(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      geoFenceInfo: () => ({
        data: [
          dataToFilter?.reduce(
            (sum, { geoFenceInfo }) => sum + (geoFenceInfo?.length > 0 ? 1 : 0),
            0
          ),
          dataToFilter?.reduce(
            (sum, { geoFenceInfo }) =>
              sum + (geoFenceInfo?.length === 0 ? 1 : 0),
            0
          ),
        ],

        labels: ["Projects With Geofences", "Projects Without Geofences"],

        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),
    }), //Projects
    Documentation: () => ({
      docType: () => {
        const data = repeatedElementSum(dataToFilter, "docType");
        return {
          data: Object.values(data),
          labels: Object.keys(data),
          backgroundColor: Object.keys(data)?.map(getRandomColor),
        };
      },

      docStatus: () => {
        const data = repeatedElementSum(dataToFilter, "docStatus");
        return {
          data: Object.values(data),
          labels: Object.keys(data),
          backgroundColor: Object.keys(data)?.map(getRandomColor),
        };
      },
    }), //Documentation

    Scheduling: () => ({
      typeOfWork: () => ({
        data: programFields
          ?.find((a) => a?.fieldName === "Scheduling Types")
          ?.fieldOptions?.map((a) => a?.typeName)
          ?.map(
            (status) =>
              dataToFilter?.filter(
                (a) => a?.typeOfWork?.toLowerCase() === status?.toLowerCase()
              )?.length
          ),
        labels: programFields
          ?.find((a) => a?.fieldName == "Scheduling Types")
          ?.fieldOptions?.map((a) => a.typeName),
        backgroundColor: programFields
          ?.find((a) => a?.fieldName == "Scheduling Types")
          ?.fieldOptions?.map((a) => getRandomColor()),
      }),

      projectExecutive: () => ({
        data: Object.values(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        labels: Object.keys(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      projectManager: () => ({
        data: Object.values(
          repeatedElementSum(dataToFilter, "projectManagers")
        ),
        labels: Object.keys(
          repeatedElementSum(dataToFilter, "projectManagers")
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),
    }), //scheduling

    Dispatching: () => ({
      fleetName: () => ({
        data: uniq(dataToFilter?.map((a) => a?.fleetName))?.map(
          (status) =>
            dataToFilter?.filter(
              (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
        labels: uniq(dataToFilter?.map((a) => a?.fleetName)),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      driverName: () => ({
        data: uniq(
          dataToFilter?.map((a) => a?.routes?.map((e) => e?.driverName))?.flat()
        )?.map(
          (status) =>
            dataToFilter
              .map((a) => a?.routes?.map((e) => e?.driverName))
              ?.flat()
              ?.filter((a) => a?.toLowerCase() === status?.toLowerCase())
              ?.length
        ),
        labels: uniq(
          dataToFilter?.map((a) => a?.routes?.map((e) => e?.driverName))?.flat()
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      dispatchedBy: () => ({
        data: uniq(dataToFilter?.map((a) => a?.dispatchedBy))?.map(
          (status) =>
            dataToFilter?.filter(
              (a) => a?.dispatchedBy?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
        labels: uniq(dataToFilter?.map((a) => a?.dispatchedBy)),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),
    }), //Dispatching

    Fleet: () => ({
      fleetYear: () => ({
        data: uniq(dataToFilter?.map((a) => a?.fleetYear))?.map(
          (status) =>
            dataToFilter?.filter(
              (a) => a?.fleetYear?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
        labels: uniq(dataToFilter?.map((a) => a?.fleetYear))?.sort(),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      registrationExpDate: () => ({
        data: (() => {
          let registrationExpDate = [];
          for (let i = 0; i < 12; i++) {
            registrationExpDate = [
              ...registrationExpDate,
              dataToFilter
                ?.map((a) => a?.registrationExpDate)
                ?.filter(
                  (b) =>
                    b >= getMonthsTimestamps()[i] &&
                    b <= getMonthsTimestamps("end")[i]
                )?.length,
            ];
          }
          return registrationExpDate;
        })(),
        labels: months,
        backgroundColor: months.map((a) => getRandomColor()),
      }),

      inspectionExpDate: () => ({
        data: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              dataToFilter
                ?.map((a) => a?.inspectionExpDate)
                ?.filter(
                  (b) =>
                    b >= getMonthsTimestamps()[i] &&
                    b <= getMonthsTimestamps("end")[i]
                )?.length,
            ];
          }
          return data;
        })(),
        labels: months,
        backgroundColor: months.map((a) => getRandomColor()),
      }),
    }), //Fleet

    "Fleet Inspections": () => ({
      inspectedBy: () => {
        const data = repeatedElementSum(
          dataToFilter?.map((el) => el?.inspectedBy),
          "name"
        );

        return {
          data: Object.keys(data),
          labels: Object.values(data),
          backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
        };
      },

      driverName: () => {
        const data = repeatedElementSum(
          dataToFilter?.map((el) => el?.driverInfo),
          "driver"
        );

        return {
          data: Object.keys(data),
          labels: Object.values(data),
          backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
        };
      },

      createdAt: () => ({
        data: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              dataToFilter
                ?.map((a) => a?.createdAt)
                ?.filter(
                  (b) =>
                    b >= getMonthsTimestamps()[i] &&
                    b <= getMonthsTimestamps("end")[i]
                )?.length,
            ];
          }
          return data;
        })(),
        labels: months,
        backgroundColor: months.map((a) => getRandomColor()),
      }),

      fleetName: () => {
        const data = repeatedElementSum(dataToFilter, "fleetName");

        return {
          labels: Object.keys(data),
          data: Object.values(data),
          backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
        };
      }, //fleetName

      inspectionStatus: () => ({
        labels: statusesOfCategories?.Inspections?.map((a) => a?.statusName),
        data: statusesOfCategories?.Inspections?.map(
          (status) =>
            dataToFilter?.filter(
              (a) => a?.inspectionStatus === status?.statusName
            )?.length
        ),
        backgroundColor: statusesOfCategories?.Inspections?.map(
          (el) => el?.statusColor || getRandomColor()
        ),
      }), //inspectionStatus

      inspectionType: () => {
        const data = repeatedElementSum(dataToFilter, "inspectionType");

        return {
          labels: Object.keys(data),
          data: Object.values(data),
          backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
        };
      }, //inspectionType

      mechanic: () => {
        const data = repeatedElementSum(
          dataToFilter?.map((el) => el?.mechanicInfo),
          "mechanic"
        );

        return {
          labels: Object.keys(data),
          data: Object.values(data),
          backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
        };
      }, //mechanic
    }), //Inspections

    Maintenance: () => ({
      createdAt: () => ({
        data: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              dataToFilter
                ?.map((a) => a?.createdAt)
                ?.filter(
                  (b) =>
                    b >= getMonthsTimestamps()[i] &&
                    b <= getMonthsTimestamps("end")[i]
                )?.length,
            ];
          }
          return data;
        })(),
        labels: months,
        backgroundColor: months.map((a) => getRandomColor()),
      }),
    }), //Maintenance

    Violations: () => ({
      amount_due: () => ({
        data: (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              (() => {
                const amount = dataToFilter?.filter(
                  (b) =>
                    b?.issue_date >= getMonthsTimestamps()[i] &&
                    b?.issue_date <= getMonthsTimestamps("end")[i]
                );

                return !!amount?.length
                  ? amount
                      ?.map((a) => a.amount_due)
                      ?.reduce(
                        (previousValue, currentValue) =>
                          parseInt(previousValue) + parseInt(currentValue)
                      )
                  : 0;
              })(),
            ];
          }
          return violations;
        })(),
        labels: months,
        backgroundColor: months.map((a) => getRandomColor()),
      }),

      issue_date: () => ({
        data: (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              dataToFilter
                ?.map((a) => a?.issue_date)
                ?.filter(
                  (b) =>
                    b >= getMonthsTimestamps()[i] &&
                    b <= getMonthsTimestamps("end")[i]
                )?.length,
            ];
          }
          return violations;
        })(),
        labels: months,
        backgroundColor: months.map((a) => getRandomColor()),
      }),
    }), //Violations

    Estimations: () => ({
      estSTATUS: () => ({
        data: programFields
          ?.find((a) => a?.fieldName === "Estimation Status")
          ?.fieldOptions?.map((a) => a?.statusName)
          ?.map(
            (status) =>
              dataToFilter?.filter(
                (a) => a?.estSTATUS?.toLowerCase() === status?.toLowerCase()
              )?.length
          ),
        labels: programFields
          ?.find((a) => a?.fieldName === "Estimation Status")
          ?.fieldOptions?.map((a) => a.statusName),
        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "Status colors")
          ?.fieldOptions?.Estimations?.map((a) => a.statusColor),
      }),

      archAssignedTo: () => ({
        data: Object.values(repeatedElementSum(dataToFilter, "archAssignedTo")),
        labels: Object.keys(repeatedElementSum(dataToFilter, "archAssignedTo")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "archAssignedTo")
        )?.map(() => getRandomColor()),
      }),

      engAssignedTo: () => ({
        data: Object.values(repeatedElementSum(dataToFilter, "engAssignedTo")),
        labels: Object.keys(repeatedElementSum(dataToFilter, "engAssignedTo")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "engAssignedTo")
        )?.map(() => getRandomColor()),
      }),

      statusByArch: () =>
        programFields
          ?.find((a) => a?.fieldName === "Status by Architecture")
          ?.fieldOptions?.map((a) => a?.statusName)
          ?.map(
            (status) =>
              dataToFilter?.filter(
                (a) => a?.statusByArch?.toLowerCase() === status?.toLowerCase()
              )?.length
          ),

      statusByEng: () => ({
        data: programFields
          ?.find((a) => a?.fieldName === "Status by Engineering")
          ?.fieldOptions?.map((a) => a?.statusName)
          ?.map(
            (status) =>
              dataToFilter?.filter(
                (a) => a?.statusByEng?.toLowerCase() === status?.toLowerCase()
              )?.length
          ),
        labels: programFields
          ?.find((a) => a?.fieldName === "Status by Architecture")
          ?.fieldOptions?.map((a) => a.statusName),
        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "Status by Architecture")
          ?.fieldOptions?.map((a) => getRandomColor()),
      }),

      projectExecutive: () => ({
        data: Object.values(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        labels: Object.keys(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ),
        backgroundColor: uniq(dataToFilter?.map(() => getRandomColor())),
      }),

      projectManager: () => ({
        labels: Object.keys(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ),
        data: Object.values(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ),
        backgroundColor: Object.keys(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        )?.map((a) => getRandomColor()),
      }),
    }), //Estimations

    "Add-ons": () => ({
      amount: () =>
        uniq(
          dataToFilter
            ?.map((a) => a?.services)
            ?.flat(1)
            ?.map((b) => b?.label)
        )
          ?.map((status) =>
            dataToFilter
              ?.map((c) => c?.services)
              ?.flat(1)
              ?.filter((d) => d?.label === status && status)
          )
          ?.map((e) => e?.map((f) => f?.serviceOptions))
          ?.map((g) =>
            g
              ?.flat(2)
              ?.map((h) => h?.items)
              ?.flat(1)
              ?.map((i) => parseInt(i?.price) || 0)
              ?.reduce((c, p) => c + p, 0)
          ), //amount
    }), //Add-ons

    Safety: () => ({
      safetyApplicationStatus: () => ({
        data: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Safety?.map(({ statusName }) => statusName)
          ?.map(
            (status) =>
              dataToFilter?.filter((a) => a?.safetyApplicationStatus === status)
                ?.length
          ),
        labels: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Safety?.map((a) => a?.statusName),
        backgroundColor: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Safety?.map((a) => a?.statusColor),
      }),
    }),

    Incidents: () => ({
      incidentStatus: () => ({
        data: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Incidents?.map(({ statusName }) => statusName)
          ?.map(
            (status) =>
              dataToFilter?.filter((a) => a?.incidentStatus === status)?.length
          ),
        labels: programFields
          ?.find((a) => a?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Incidents?.map((a) => a?.statusName),
        backgroundColor: programFields
          ?.find((a) => a?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Incidents?.map((a) => a?.statusColor),
      }),

      incidentCategory: () => ({
        labels: Object.keys(
          repeatedElementSum(dataToFilter, "incidentCategory")
        ),
        data: Object?.values(
          repeatedElementSum(dataToFilter, "incidentCategory")
        ),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "incidentCategory")
        )?.map((a) => getRandomColor()),
      }),

      driverName: () => ({
        labels: Object.keys(repeatedElementSum(dataToFilter, "driverName")),
        data: Object.values(repeatedElementSum(dataToFilter, "driverName")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "driverName")
        )?.map(() => getRandomColor()),
      }),

      fleetName: () => ({
        labels: Object.keys(repeatedElementSum(dataToFilter, "fleetName")),
        data: Object.values(repeatedElementSum(dataToFilter, "fleetName")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "fleetName")
        )?.map(() => getRandomColor()),
      }),

      incidentName: () => ({
        labels: Object.keys(repeatedElementSum(dataToFilter, "incidentName")),
        data: Object.values(repeatedElementSum(dataToFilter, "incidentName")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "incidentName")
        )?.map(() => getRandomColor()),
      }),

      projectName: () => ({
        labels: Object.keys(
          repeatedElementSum(
            dataToFilter?.map((el) => el?.incidentObject),
            "projectName"
          )
        ),
        data: Object.values(
          repeatedElementSum(
            dataToFilter?.map((el) => el?.incidentObject),
            "projectName"
          )
        ),
        backgroundColor: Object.keys(
          repeatedElementSum(
            dataToFilter?.map((el) => el?.incidentObject),
            "projectName"
          )
        )?.map(() => getRandomColor()),
      }),
    }),

    "To Do": () => ({
      todoStatus: () => ({
        labels: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.["To Do"]?.map?.((el) => el?.statusName),
        data: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.["To Do"]?.map?.(
            (status) =>
              tables?.["To Do"]?.filter?.((a) => a.todoStatus === status)
                ?.length
          ),
        backgroundColor: programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.["To Do"]?.map((el) => el?.statusColor),
      }),
      todoCategory: () => ({
        labels: Object.keys(repeatedElementSum(dataToFilter, "todoCategory")),
        data: Object.values(repeatedElementSum(dataToFilter, "todoCategory")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "todoCategory")
        ).map((a) => getRandomColor()),
      }),
      todoUser: () => ({
        labels: Object.keys(repeatedElementSum(dataToFilter, "todoUser")),
        data: Object.values(repeatedElementSum(dataToFilter, "todoUser")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "todoUser")
        ).map((a) => getRandomColor()),
      }),
      relatedTo: () => ({
        labels: Object.keys(repeatedElementSum(dataToFilter, "relatedTo")),
        data: Object.values(repeatedElementSum(dataToFilter, "relatedTo")),
        backgroundColor: Object.keys(
          repeatedElementSum(dataToFilter, "relatedTo")
        ).map((a) => getRandomColor()),
      }),
    }),

    "Project Cost": () => ({
      companyEmployees: () => ({
        labels: uniq(dataToFilter?.map((a) => a?.company)),
        data: Object.values(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "company"
          )
        ),
        backgroundColor: uniq(dataToFilter?.map((a) => a?.company))?.map(() =>
          getRandomColor()
        ),
      }),

      jobsiteAddress: () => ({
        labels: uniq(
          dataToFilter?.map((a) => a?.jobsiteMatch?.jobAddress)
        )?.filter(Boolean),
        data: Object.values(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "jobsiteId"
          )
        ),
        backgroundColor: uniq(
          dataToFilter?.map((a) => a?.jobsiteMatch?.jobAddress)
        )
          ?.filter(Boolean)
          ?.map(() => getRandomColor()),
      }),

      punchType: () => ({
        labels: uniq(dataToFilter?.map((a) => a?.punchType))?.filter(Boolean),
        data: Object.values(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "punchType"
          )
        ),
        backgroundColor: uniq(dataToFilter?.map((a) => a?.punchType))
          ?.filter(Boolean)
          ?.map(() => getRandomColor()),
      }),

      role: () => ({
        labels: uniq(dataToFilter?.map((a) => a?.role))?.filter(Boolean),
        data: Object.values(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "role"
          )
        ),
        backgroundColor: uniq(dataToFilter?.map((a) => a?.role))
          ?.filter(Boolean)
          ?.map(() => getRandomColor()),
      }),

      jobsiteTotalCost: () => {
        const costDispersion = getProjectCostData({
          data: dataToFilter,
          jobsites: tables?.Jobsites,
        });

        const dataSets = costChartData(
          costDispersion,
          "jobsiteAddress",
          "total",
          formatCurrency
        );

        return {
          labels: dataSets?.labels,
          data: dataSets?.data,
          backgroundColor: dataSets?.backgroundColor,
        };
      },

      jobsiteTotalHours: () => {
        const costDispersion = getProjectCostData({
          data: dataToFilter,
          jobsites: tables?.Jobsites,
        });

        const dataSets = costChartData(
          costDispersion,
          "jobsiteAddress",
          "totalWorkHours"
        );

        return {
          labels: dataSets?.labels,
          data: dataSets?.data,
          backgroundColor: dataSets?.backgroundColor,
        };
      },
    }),
    Approvals: () => {
      const reducedUsers = userConfiguration?.allUsers?.Items?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.cognitoUserId]: curr,
        }),
        {}
      );
      return {
        userRequesting: () => {
          const data = repeatedElementSum(dataToFilter, "userRequesting");
          return {
            labels: Object.keys(data)?.map(
              (el) => reducedUsers?.[el]?.nameOfUser
            ),
            data: Object.values(data),
            backgroundColor: Object.keys(data).map(() => getRandomColor()),
          };
        },

        assignedTo: () => {
          const data = repeatedElementSum(dataToFilter, "assignedTo");
          return {
            labels: Object.keys(data)?.map(
              (el) => reducedUsers?.[el]?.nameOfUser
            ),
            data: Object.values(data),
            backgroundColor: Object.keys(data).map(() => getRandomColor()),
          };
        },

        category: () => {
          const data = repeatedElementSum(dataToFilter, "category");
          return {
            labels: Object.keys(data)?.map((el) => el?.capitalize() || el),
            data: Object.values(data),
            backgroundColor: Object.keys(data).map(() => getRandomColor()),
          };
        },

        responseFrom: () => {
          const data = repeatedElementSum(dataToFilter, "responseFrom");
          return {
            labels: Object.keys(data)?.map((el) => el || "No Response"),
            data: Object.values(data),
            backgroundColor: Object.keys(data).map(() => getRandomColor()),
          };
        },

        requestType: () => {
          const data = repeatedElementSum(dataToFilter, "requestType");
          const statusColors =
            programFields?.find((el) => el?.fieldName === "Status colors")
              ?.fieldOptions?.Approvals?.requestType || [];

          return {
            labels: Object.keys(data),
            data: Object.values(data),
            backgroundColor: Object.keys(data).map(
              (key) =>
                statusColors?.find((el) => el?.statusName === key)?.statusColor
            ),
          };
        },
      };
    },

    Inspections: () => ({
      inspectionType: () => {
        const data = repeatedElementSum(dataToFilter, "inspectionType");
        return {
          labels: Object.keys(data),
          data: Object.values(data),
          backgroundColor: Object.keys(data).map(getRandomColor),
        };
      },

      inspectionStatus: () => {
        const data = repeatedElementSum(dataToFilter, "inspectionStatus");

        const statuses = (statusesOfCategories?.Inspection || [])?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr?.statusName]: curr?.statusColor,
          }),
          {}
        );

        return {
          labels: Object.keys(data),
          data: Object.values(data),
          backgroundColor: Object.keys(data).map(
            (key) => statuses?.[key] || getRandomColor()
          ),
        };
      },

      inspectedBy: () => {
        const data = repeatedElementSum(
          dataToFilter?.map((el) => el?.inspectedBy),
          "nameOfUser"
        );

        return {
          labels: Object.keys(data),
          data: Object.values(data),
          backgroundColor: Object.keys(data).map(getRandomColor),
        };
      },
    }),
  };
};
