import axesTooltip, { pieTooltip } from "../../../../helpers/axesTooltip";

const countOnDate = {
  title: {
    text: "Total Number of Tasks",
  },
  subtitle: {
    text: "Based on Start Date",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "dynamicTime",
        yKey: ["taskTitle"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "dynamicTime",
        angleKey: ["taskTitle"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const chartingOptions = [
  {
    optionTitle: "Show total subcontractor count based on creation date",
    params: countOnDate,
  },
  {
    dynamicKeys: [
      {
        key: "dynamicTime",
        keyFunction: "dateGetter",
        optionalParams: [["accountName"]],
      },
    ],
  },
];

export default chartingOptions;
