import types from '../types';

const opportunities = (state = [], action) => {
    switch (action.type) {
        case types.SET_OPPORTUNITIES:
            return action.payload
        default: return state;
    }
}

export default opportunities;