export const GOOGLE_SCOPES =
  "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.photos.readonly https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.apps.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.insert https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.readonly https://mail.google.com/ https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets.readonly";

export const CLIENT_ID =
  "202644885433-htcu0omqe0tnbi7pn9gnj5boabpnp711.apps.googleusercontent.com";
export const REFRESH_TOKEN =
  "1//04ajDQOYhv_cbCgYIARAAGAQSNwF-L9IrDykq-r4CKW0eHzKOppeMAhOWbFM8TIDnHB45ey3aN_EhbpQhGV6XeU2S2VtUaMIC9a0";
export const CLIENT_SECRET = "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";

export const GOOGLE_USER_INFO_URL =
  "https://www.googleapis.com/oauth2/v1/userinfo?access_token=";

export const GOOGLE_TOKEN_AUTH_URL = "https://oauth2.googleapis.com/token";

export const GOOGLE_API_KEY = "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo";
