function svgString(color, dir) {
  if ("up" === dir) {
    return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='${color}'><polygon points='50,00 0,50 100,50'/></svg>")`;
  } else if (dir === "down") {
    return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='${color}'><polygon points='0,0 100,0 50,50'/></svg>")`;
  } else if ("left" === dir) {
    return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='${color}'><polygon points='0,50 50,100 50,0'/></svg>")`;
  } else if ("right" === dir)
    return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='${color}'><polygon points='0,0 0,100 50,50'/></svg>")`;
}

function changeScrollbarStyle() {
  const darkMode = localStorage.getItem("darkmode");
  const darkModeVal = JSON.parse(darkMode) || "";
  const root = document.getElementById("root");
  if (!!darkModeVal) {
    root.style.setProperty("--scroll-thumb-bg", "#1F2A44");
    root.style.setProperty("--scrollbar-track-bg", "#41435D");
    root.style.setProperty(
      "--scrollbar-thumb-svg-up",
      svgString("rgb(249, 249, 249)", "up")
    );
    root.style.setProperty(
      "--scrollbar-thumb-svg-down",
      svgString("rgb(249, 249, 249)", "down")
    );
    root.style.setProperty(
      "--scrollbar-thumb-svg-left",
      svgString("rgb(249, 249, 249)", "left")
    );
    root.style.setProperty(
      "--scrollbar-thumb-svg-right",
      svgString("rgb(249, 249, 249)", "right")
    );
  } else {
    root.style.setProperty("--scroll-thumb-bg", "#222A44");
    root.style.setProperty("--scrollbar-track-bg", "#F1F1F1");
    root.style.setProperty(
      "--scrollbar-thumb-svg-up",
      svgString("rgb(34, 42, 68)", "up")
    );
    root.style.setProperty(
      "--scrollbar-thumb-svg-down",
      svgString("rgb(34, 42, 68)", "down")
    );
    root.style.setProperty(
      "--scrollbar-thumb-svg-left",
      svgString("rgb(34, 42, 68)", "left")
    );
    root.style.setProperty(
      "--scrollbar-thumb-svg-right",
      svgString("rgb(34, 42, 68)", "right")
    );
  }
}

export default changeScrollbarStyle;
