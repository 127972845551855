import { useStateContext } from "../../Context/Context";
import HoistService from "./HoistService/HoistService";
import NoServices from "./NoServices/NoServices";
import { useSelector } from "react-redux";
import Service from "./Service/Service";
import "./service-list.scss";

import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { checkIfServiceIsHoist } from "../../../../../../Estimations/DataEntryGrid/models/Service";

const ServiceList = () => {
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const [defaultSOVEvents, setDefaultSOVEvents] = useState([]);
  const [hoistDetails, setHoistDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);

  // Context state
  const { proposedTypeOfWork, fakeSOV, darkMode } = useStateContext();

  // Fetch service definitions on mount
  useEffect(() => {
    (async () => {
      if (fakeSOV?.projectId) {
        const [{ value: project }] = await Promise?.allSettled([
          API?.get("projects", `/projects/${fakeSOV?.projectId}`),
        ]);
        setProject(project);
        setHoistDetails(project?.hoistDetails);
        setIsLoading(false);
      }
    })();
  }, [fakeSOV?.projectId]);

  useEffect(() => {
    const sovEvents = serviceDefinitions?.map((item) => ({
      serviceId: item?.serviceId,
      serviceName: item?.serviceName,
      scheduleOfValues: item?.scheduleOfValues,
      listOfEvents: item?.listOfEvents,
    }));

    setDefaultSOVEvents(sovEvents);
  }, [serviceDefinitions]);

  const someServicesVisible = fakeSOV?.services?.some(
    (service) => service.visible
  );

  return (
    <div className="sov-service-list">
      {!someServicesVisible ? (
        <NoServices {...{ darkMode }} />
      ) : (
        !!proposedTypeOfWork?.length &&
        !isLoading &&
        fakeSOV?.services?.map((service) => {
          if (service?.visible) {
            const borderColor =
              proposedTypeOfWork?.find(
                (ptw) =>
                  ptw?.workId?.toString() === service?.serviceId?.toString()
              )?.colorCode || "white";
            // Find default events for each services
            const serviceEvents = defaultSOVEvents?.find(
              (sovEvent) =>
                sovEvent.serviceId?.toString() ===
                service?.serviceId?.toString()
            );
            if (checkIfServiceIsHoist(service)) {
              return (
                <HoistService
                  key={`${service?.label}/${service?.estimationId}`}
                  service={service}
                  borderColor={borderColor}
                  serviceEvents={serviceEvents}
                  hoistDetails={hoistDetails}
                  project={project}
                />
              );
            } else {
              return (
                <Service
                  key={`${service?.label}/${service?.estimationId}`}
                  service={service}
                  borderColor={borderColor}
                  serviceEvents={serviceEvents}
                  project={project}
                />
              );
            }
          } else return null;
        })
      )}
    </div>
  );
};

export default ServiceList;
