import { PauseOutlined, StepForwardOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { ConfirmationModal } from "../../../../../commonComponents";
import { useDispatchProgramFields, useSaveLogs } from "../../../../../../hooks";
import { findFieldOptionByNameOrId } from "../../../../../../utils";
import { useState } from "react";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { useSelector } from "react-redux";

const Execution = ({ data }) => {
  const { sendLogToApi } = useSaveLogs("Post Events Automations");
  const defaultFieldOptions = findFieldOptionByNameOrId({
    fieldName: "Post Events Automations Configurations",
  });
  const { dispatchFields } = useDispatchProgramFields(
    "34534rger+dfgdfgdf+3464redgrehgrhrt",
    "Post Events Automations Configurations"
  );
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const isActive = data.shouldPostExecute;

  const handleToggler = (event) => {
    setLoading(true);
    const updatedList = defaultFieldOptions.map((field) => {
      if (field.uid === showConfirmationPopup.uid) {
        return {
          ...field,
          shouldPostExecute: event,
        };
      } else {
        return field;
      }
    });

    dispatchFields(updatedList).then(() => {
      sendLogToApi({
        recordId: showConfirmationPopup.uid,
        recordName: showConfirmationPopup.automationName,
        actionType: "Toggle",
        currentData: {
          [showConfirmationPopup.categoryName]: {
            status: !isActive ? "Active" : "Stopped",
          },
        },
        previousData: {
          [showConfirmationPopup.categoryName]: {
            status: isActive ? "Active" : "Stopped",
          },
        },
        updatedKeys: [],
      });
      setLoading(false);
    });

    const commonNext = `has changed a post event automation named ${
      data.automationName
    } for category ${data.categoryName} from ${
      event ? "stopped" : "active"
    } to ${event ? "active" : "stopped"}`;

    broadcastNotification(
      "119",
      "onExecution",
      [
        {
          common: userConfiguration.nameOfUser,
          commonNext,
        },
        {
          userName: userConfiguration.nameOfUser,
          currentUser: userConfiguration.cognitoUserId,
        },
      ],
      null
    );
  };

  return (
    <>
      {isActive ? (
        <Button
          data-testid="pause-button"
          danger
          onClick={() => {
            setShowConfirmationPopup(data);
          }}
          icon={
            loading ? (
              <Spin indicator={<PauseOutlined spin />} />
            ) : (
              <PauseOutlined />
            )
          }
        />
      ) : (
        <Button
          type="primary"
          ghost
          onClick={() => {
            setShowConfirmationPopup(data);
          }}
          icon={
            loading ? (
              <Spin indicator={<StepForwardOutlined spin />} />
            ) : (
              <StepForwardOutlined />
            )
          }
          data-testid="start-button"
        />
      )}

      {showConfirmationPopup && (
        <ConfirmationModal
          {...{
            visible: showConfirmationPopup,
            setVisible: setShowConfirmationPopup,
            title: `Event Automation`,
            text: `Are you sure you want to ${
              isActive ? "Stop" : "Start"
            } this Event (${data.automationName}) Automation?`,
            onCancel: () => setShowConfirmationPopup(false),
            onConfirm: () => {
              handleToggler(!isActive);
            },
          }}
        />
      )}
    </>
  );
};

export default Execution;
