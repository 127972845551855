import { AgGridReact } from "ag-grid-react";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import RentalBreakdown from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/RentalBreakdown";
import { columnDefs } from "./agGrid";
import "./ElevationLevelCredit.scss";
import { cloneDeep } from "lodash";
import { updateApplication } from "../../../../../../../../../../../../actions/applications";
import { useStateContext } from "../../../../../../Context/Context";
import { useRedux } from "../../../../../../../../../../hooks";
import { useParams } from "react-router-dom";
import CustomFooter from "../../../../../../../ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import { updateDocumentTitle } from "../../../../../../../../../../../../utils";
import {
  CloseCircleFilled,
  ExclamationCircleFilled,
  SaveFilled,
} from "@ant-design/icons";
import { useEditLogs } from "../../../../../../../../../../../../hooks";

export default function ElevationLevelCredit({
  params,
  rental,
  setRowData,
  rowData,
  setServiceVis,
}) {
  const [visible, setVisible] = useState(false);
  const { programFields } = useSelector((state) => state.programFields);
  const init = cloneDeep(params?.data?.serviceOptions);
  const { applications } = useSelector((state) => state.applications);
  const [savedData, setSavedData] = useState([]);
  const { fakeApplication } = useStateContext();
  const [currentRental, setCurrentRental] = useRedux("currentRental");
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { saveAddedLogs } = useEditLogs();

  const saveElevationCreditHandler = () => {
    console.log("params234", { params, currentRental, rental, savedData });
    let currServices = currentRental || [];
    let result = 0;
    const temp = cloneDeep(fakeApplication);
    temp?.includedRentals?.forEach((incRent) => {
      if (incRent?.rentalId === rental?.rentalId) {
        incRent?.services?.forEach((service) => {
          if (service?.label === params?.data?.label) {
            savedData?.forEach((e) => {
              let amount = e.items.reduce((acc, curr) => {
                console.log({ acc, curr });
                if (!!curr?.elevationLevelCredit) {
                  return acc + curr?.elevationLevelCredit;
                } else return acc;
              }, 0);
              service.serviceOptions = savedData;
              service.serviceLevelCredit = amount;
              result += amount;
              console.log("amount33333", amount);
            });
          }
        });
      }
    });
    currServices.forEach((service) => {
      if (service?.label === params?.data?.label) {
        service.serviceLevelCredit = result;
        service.serviceOptions = savedData;
      }
    });
    setCurrentRental(currServices);
    dispatch(
      updateApplication(applicationId, { ...temp }, applications, saveAddedLogs)
    );
    params.data.serviceLevelCredit = result;
    params.api.refreshCells({ force: true });
  };

  useEffect(() => {
    let currRental = currentRental
      ? currentRental?.find((el) => el.label === params.data.label)
      : false;
    if (currRental && savedData.length === 0) {
      setSavedData(currRental?.serviceOptions || []);
    }
  }, [fakeApplication, currentRental]);

  console.log("savedData", savedData);

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  return (
    <div className="elevation-level-credit">
      <span className="elevation-level-button">
        <MondayButton
          className="detailsBtn"
          onClick={() => setVisible(true)}
          Icon={<ExclamationCircleFilled />}
        >
          Details
        </MondayButton>
      </span>
      <Modal
        className="rentalCreditModalDark"
        title={`Applying Credit on PLI Level! - (${params?.data?.label || ""})`}
        afterOpenChange={(event) => {
          event &&
            updateDocumentTitle({
              newTitle: `Applying Credit on PLI Level! - (${
                params?.data?.label || ""
              })`,
            });
        }}
        open={visible}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        onCancel={closeModal}
        footer={
          <div
            className="elevation-level-footer-btns"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {" "}
            <MondayButton
              className={"cancelButton"}
              onClick={closeModal}
              Icon={<CloseCircleFilled />}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={saveElevationCreditHandler}
              Icon={<SaveFilled />}
            >
              Save Changes
            </MondayButton>
          </div>
        }
        width={"1700px"}
      >
        <div
          style={{
            maxHeight: "700px",
            overflowY: "scroll",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          {" "}
          {params?.data &&
            visible &&
            !!programFields &&
            savedData?.map((elevation) => (
              <div>
                <h5
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0 50px 0",
                  }}
                >
                  {elevation?.elevationLabel}
                </h5>{" "}
                <div className="includeRentalsAgGridContainerDark agGridRentalsDetailsStyleDark ag-theme-alpine-dark">
                  <AgGridReact
                    suppressSizeToFit={true}
                    rowSelection="multiple"
                    paginationPageSize={14}
                    headerHeight={50}
                    columnDefs={columnDefs({
                      elevationData: elevation?.items,
                      programFields,
                      selectedService: params?.data?.label,
                    })}
                    rowData={elevation?.items?.map((item) => ({
                      ...item,
                      taxRate: params?.data?.taxRate || 0,
                      retainage: params?.data?.retainage || 0,
                    }))}
                    rowHeight={40}
                    onCellValueChanged={(params) => {
                      let temp = cloneDeep(savedData);
                      temp?.forEach((t) => {
                        if (t.elevationLabel === elevation.elevationLabel) {
                          t.items.forEach((item) => {
                            if (item.id === params.data.id) {
                              item.elevationLevelCredit = params.newValue;
                            }
                          });
                        }
                      });
                      setSavedData(temp);
                      console.log("tempppp", temp);
                    }}
                    defaultColDef={{
                      flex: 1,
                      sortable: true,
                      resizable: true,
                      enablePivot: true,
                      filter: true,
                      enableColResize: true,
                      enableRowGroup: true,
                      rowHeight: 40,
                    }}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            ))}
        </div>
        <CustomFooter
          style={{
            height: "50px",
            marginTop: "0px",
            // color: "#68C412",
            marginRight: "50px",
            position: "relative",
            width: "100%",
            justifyContent: "center",
            fontSize: "13px",
            backgroundColor: "#12131b",
          }}
          color="#71cf48"
          dataSrc={savedData?.map((el) => {
            let creditTotal =
              el?.items?.reduce(
                (a, b) => a + (b?.elevationLevelCredit || 0),
                0
              ) || 0;
            let appliedTotal =
              el?.items?.reduce((a, b) => a + (b?.appliedAmount || 0), 0) || 0;
            return {
              ...el,
              elevationLevelCredit: creditTotal || 0,
              appliedTotal,
            };
          })}
          labelsKeys={[
            {
              key: "elevationLevelCredit",
              name: "Credit Amount",
              type: "currency",
              mathAction: "sum",
            },
          ]}
        />
      </Modal>
    </div>
  );
}
