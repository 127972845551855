import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Checkbox, Modal } from "antd";
import {
  SearchIcon,
  XIcon,
} from "../../../../../../../SidebarPages/Communication/assets";
import { InfoIconBlue } from "./HelpingComponents/icons";
import * as moment from "moment";
import { Tick } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { MondayButton } from "../../../../../../../commonComponents";
import { sortBy } from "lodash";
import { updateDocumentTitle } from "../../../../../../../../utils";

const SelectDriverModal = ({
  visible,
  setVisible,
  drivers,
  handleSelectDriver,
  fleet,
  setSelectDriverPerFleet,
}) => {
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [checkedDriver, setCheckedDriver] = useState({});

  const onSearch = (string) => {
    setFilteredDrivers(
      drivers.filter((driver) =>
        driver.driverName.toLowerCase().includes(string.toLowerCase())
      ) || []
    );
  };

  const onCancel = () => {
    setFilteredDrivers([]);
    setCheckedDriver({});
    // setSelectDriverPerFleet({});
    setVisible(false);
    updateDocumentTitle(); // reset document title to "Lead Manager";
  };
  const onConfirm = (driver, fleetId) => {
    handleSelectDriver(driver, fleetId);
    onCancel();
    // setSelectDriverPerFleet({});
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={false}
      title="Select Driver"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Select Driver" });
      }}
      className="crew-fleet-popover"
      maskClosable={false}
      centered
      closeIcon={<XIcon />}
    >
      <div className="trucks-modal-body">
        <div className="trucks-modal-body-header select-driver-modal-header">
          <div className="trucks-modal-body-header-info">
            <span>
              <InfoIconBlue />
            </span>
            <p>
              You're selecting a driver for <b>{fleet?.fleetName}</b>.
            </p>
          </div>
          <div className="trucks-modal-body-header-functionality">
            <div className="trucks-modal-search-div select-driver-search-div">
              <AutoComplete
                className="trucks-modal-search-comp select-driver-search-comp"
                placeholder="Search filters"
                onChange={(e) => onSearch(e)}
              ></AutoComplete>
              <div className="trucks-modal-search-logo select-driver-search-logo">
                <SearchIcon />
              </div>
            </div>
            <MondayButton
              Icon={<Tick />}
              disabled={!checkedDriver?.driverId}
              className="trucks-modal-button"
              onClick={() =>
                !!checkedDriver?.driverId &&
                onConfirm(checkedDriver, fleet?.fleetId)
              }
            >
              Confirm
            </MondayButton>
          </div>
        </div>
        <div className="trucks-modal-body-content">
          <table className="trucks-modal-body-content-table">
            <thead>
              <tr>
                <th>Select</th>
                <th>Full Name</th>
                <th>Rating</th>
                <th>Status</th>
                <th>DL Issued Date</th>
                <th>DL Expiration Date</th>
                <th>MC Issued Date</th>
                <th>MC Expiration Date</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(
                [filteredDrivers?.length ? filteredDrivers : drivers].flat(),
                (obj) => !obj?.driverStatus.includes("Ready")
              ).map((driver) => {
                return (
                  <tr key={driver?.driverId}>
                    <td>
                      <Checkbox
                        className="trucks-modal-checkbox"
                        checked={checkedDriver?.driverId === driver?.driverId}
                        onChange={() =>
                          checkedDriver?.driverId === driver?.driverId
                            ? setCheckedDriver({})
                            : setCheckedDriver(driver)
                        }
                      ></Checkbox>
                    </td>
                    <td>{driver?.driverName}</td>
                    <td>
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: `${
                            driver?.driverRating?.includes("Pro")
                              ? "#71CF48"
                              : driver?.driverRating?.includes("Basic")
                              ? "#FFBE0B"
                              : "#FE4C4A"
                          } 0% 0% no-repeat padding-box`,
                          borderRadius: 5,
                          width: 112,
                          height: 32,
                        }}
                        icon={
                          <p
                            style={{
                              font: "normal normal normal 16px/22px Open Sans",
                              color: "#FFFFFF",
                              letterSpacing: 0,
                            }}
                          >
                            {driver?.driverRating}
                          </p>
                        }
                      ></Button>
                    </td>
                    <td>
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: `${
                            driver?.driverStatus?.includes("Ready")
                              ? "#71CF48"
                              : driver?.driverStatus?.includes("Terminated")
                              ? "#FE4C4A"
                              : driver?.driverStatus?.includes("Suspended")
                              ? "#FFBE0B"
                              : driver?.driverStatus?.includes("Approved")
                              ? "#1264A3"
                              : ""
                          } 0% 0% no-repeat padding-box`,
                          borderRadius: 5,
                          width: 129,
                          height: 32,
                        }}
                        icon={
                          <p
                            style={{
                              font: "normal normal normal 16px/22px Open Sans",
                              color: "#FFFFFF",
                              letterSpacing: 0,
                            }}
                          >
                            {driver?.driverStatus}
                          </p>
                        }
                      ></Button>
                    </td>
                    <td>
                      {!moment(driver?.DlIssuedDate).isValid()
                        ? ""
                        : moment(driver?.DlIssuedDate).format("MM/DD/YYYY")}
                    </td>
                    <td>
                      {!moment(driver?.DlExpirationDate).isValid()
                        ? ""
                        : moment(driver?.DlExpirationDate).format("MM/DD/YYYY")}
                    </td>
                    <td>
                      {!moment(driver?.McIssuedDate).isValid()
                        ? ""
                        : moment(driver?.McIssuedDate).format("MM/DD/YYYY")}
                    </td>
                    <td>
                      {!moment(driver?.McExpirationDate).isValid()
                        ? ""
                        : moment(driver?.McExpirationDate).format("MM/DD/YYYY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default SelectDriverModal;
