/**
 * Creates the loading component html string
 * to be used as grid overlayLoadingTemplate
 */
function gridCustomOverlayLoading() {
  const gridLoading = document.createElement("div");
  gridLoading.classList.add("smallLoadingDiamonds");

  if (JSON.parse(localStorage.getItem("darkmode"))) {
    gridLoading.classList.add("smallLoadingDiamondsDark");
  }

  for (let i = 0; i < 9; i++) {
    gridLoading.appendChild(document.createElement("span"));
  }

  return new XMLSerializer().serializeToString(gridLoading);
}

export default gridCustomOverlayLoading;
