import "./ExecutedAutomations.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import AutomationResponses from "../AutomationResponses/AutomationResponses";

const ExecutedAutomations = ({ responses }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [clickedIndex, setClickedIndex] = useState();

  const { groupName } = userConfiguration;

  const isAdmin = groupName.toLowerCase() === "admin";

  return (
    <div className="executed-automations-container">
      <AutomationResponses
        {...{
          title: (isAdmin ? "All Automation" : "My Automation") + " Responses",
          responses,
          clickedIndex,
          setClickedIndex,
        }}
      />
    </div>
  );
};

export default ExecutedAutomations;
