import {
  EventCellRenderer,
  EventCellEditor,
  AmountCellRenderer,
  AmountCellEditor,
  RateCellRenderer,
  RateCellEditor,
  DifferenceCellRenderer
} from './GridComponents'

export const frameworkComponents = {
  EventCellRenderer,
  EventCellEditor,
  AmountCellRenderer,
  AmountCellEditor,
  RateCellRenderer,
  RateCellEditor,
  DifferenceCellRenderer
}