import React from "react"
import { InspectionModal } from "../../../../../XComponents"

const DescriptionModal = ({ description, visible, setVisible }) => {
  return (
    <InspectionModal
      className="newInspectionModal"
      title="Description"
      visible={visible}
      setVisible={setVisible}
      footer={[
        {
          text: "close",
          onClick: () => setVisible(false),
        },
      ]}
    >
      {description}
    </InspectionModal>
  )
}
export default DescriptionModal
