import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ChangeDepartment from "./ChangeDepartment";
import ChangePinAndIdleTime from "./ChangePinAndIdleTime";
import {
  MondayButton,
  RedWarningModal,
} from "../../../../../../../../commonComponents";
import AllowedIpAddresses from "./AllowedIpAddresses/AllowedIpAddresses";
import {
  useEditLogs,
  useSessionSocket,
} from "../../../../../../../../../hooks";
import {
  changeUserActivationStatus,
  logoutAllSessions,
  sessionButtons,
  sessionHelperObject,
} from "./helpers";
import axios from "axios";
import config from "../../../../../../../../../config";
import { useAuth } from "../../../../../../../../../hooks/useAuth";
import { updateUserKeysUser } from "../../../../../../../../../actions";
import {
  apiPut,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../utils";

const ControlPanel = ({ user: proppedUser }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { socket: wsSessions } = useSessionSocket();

  const dispatch = useDispatch();
  const [departmentVisible, setDepartmentVisible] = useState(false);
  const [pinVisible, setPinVisible] = useState(false);
  const [user, setUser] = useState(proppedUser);
  const [allowedIpAddressVisible, setAllowedIpAddressVisible] = useState(false);
  const [modalType, setModalType] = useState(false);

  const { saveAddedLogs } = useEditLogs();
  const { user: currentUser, checkMFAStatus } = useAuth();

  const {
    nameOfUser,
    activeSessions = [],
    isSuspended,
    identityId,
    suspendedDate,
    reactivatedDate,
    ipAddressWhitelist = [],
    is2FaEnabled = false,
    cognitoUserId = "",
    userName = "",
  } = user;

  const warningModalContent = useMemo(() => {
    return sessionHelperObject?.[modalType];
  }, [modalType]);

  const disable2Fa = async () => {
    try {
      showLoadingMsg({ content: "Disabling 2FA..." });
      await axios
        .post(
          "https://leadmanager-express-backend.ue.r.appspot.com/api/disableTwoFa",
          // "http://localhost:8080/api/disableTwoFa",
          {
            username: userName,
            cognitoUserId,
            userPoolId: config.cognito.USER_POOL_ID,
          }
        )
        .then(async (res) => {
          await apiPut("userConfiguration", identityId, {
            is2FaEnabled: false,
          });
          dispatch(updateUserKeysUser({ identityId, is2FaEnabled: false }));
          if (currentUser?.attributes?.sub === cognitoUserId) {
            await checkMFAStatus(currentUser);
          }
          showSuccessMsg({ content: "2FA Disabled Successfully" });
        });
    } catch (error) {
      showErrorMsg({ content: "Error disabling 2FA" });

      console.log(error);
    }
  };

  const buttons = useMemo(() => {
    return sessionButtons({
      setAllowedIpAddressVisible,
      isSuspended,
      setModalType,
      setDepartmentVisible,
      setPinVisible,
      disable2Fa,
      is2FaEnabled,
    });
  }, [isSuspended]);

  useEffect(() => {
    const foundUser = userConfiguration.allUsers.Items.find((el) => {
      return el.identityId === proppedUser?.identityId;
    });
    setUser((prev) => ({ ...prev, ...foundUser }));
  }, [userConfiguration]);

  const handleOnConfirm = async () => {
    if (modalType === "Logout") {
      await logoutAllSessions({
        user,
        wsSessions,
        saveAddedLogs,
        dispatch,
        userConfiguration,
      });
    } else if (modalType === "Force Reset Password") {
      await logoutAllSessions({
        forceResetPassword: true,
        user,
        wsSessions,
        saveAddedLogs,
        dispatch,
        userConfiguration,
      });
    } else if (modalType === "Suspend" || modalType === "Reactivate") {
      await changeUserActivationStatus({
        user,
        wsSessions,
        saveAddedLogs,
        dispatch,
      });
    }

    setModalType(false);
  };

  // changeUserActivationStatus
  return (
    <div className="controlPanelWrapper-sessions">
      <div className="controlPanelInfo">
        <InfoCircleFilled style={{ color: "#1264a3", fontSize: "20px" }} />
        <span>
          {user.isSuspended === true ? (
            <span>
              This user is suspend on{" "}
              {moment(suspendedDate).format("MM/DD/YYYY") || ""}
              {". "}
            </span>
          ) : null}
          {user.isSuspended === false && !!user?.reactivatedDate ? (
            <span>
              This user is reactivated on{" "}
              {moment(reactivatedDate).format("MM/DD/YYYY") || ""}
              {". "}
            </span>
          ) : null}
          Here, you can perform critical account actions such as logging out,
          resetting passwords, and managing device sessions. Use the tools
          provided to customize security settings and departmental assignments,
          ensuring the user has the appropriate access and privileges.
        </span>
      </div>
      <div className="controlPanel">
        {buttons.map(({ id, className, Icon, onClick, text }, i) => (
          <MondayButton
            key={i}
            id={id}
            className={className}
            Icon={Icon}
            onClick={onClick}
          >
            {text}
          </MondayButton>
        ))}
        {/* <MondayButton className="mondayButtonRed" Icon={<DeleteIcon />}>
      Delete User
    </MondayButton> */}
        {pinVisible && (
          <ChangePinAndIdleTime
            {...{
              visible: pinVisible,
              setVisible: setPinVisible,
              user,
            }}
          />
        )}

        {departmentVisible && (
          <ChangeDepartment
            {...{
              visible: departmentVisible,
              setVisible: setDepartmentVisible,
              user,
              userConfiguration,
              nameOfUser,
            }}
          />
        )}

        {allowedIpAddressVisible && (
          <AllowedIpAddresses
            {...{
              identityId,
              ipAddressWhitelist,
              allowedIpAddressVisible,
              setAllowedIpAddressVisible,
            }}
          />
        )}
      </div>
      {!!modalType && (
        <RedWarningModal
          visible={!!modalType}
          titleText={warningModalContent?.title}
          TitleIcon={warningModalContent?.TitleIcon}
          onCancel={() => setModalType(false)}
          footerProps={{
            onConfirm: async () => await handleOnConfirm(),
          }}
        >
          {warningModalContent?.text}
        </RedWarningModal>
      )}
    </div>
  );
};

export default ControlPanel;
