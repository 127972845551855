import { read } from "xlsx";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useContext, useMemo, useState } from "react";
import { Upload, Form, message } from "antd";

import EmployeeUploadContext from "../../EmployeeUploadContext";
import {
  TrashIcon,
  UploadFiles,
} from "../../../../../../../../SidebarPages/Communication/assets";
import { employeeUploadParser } from "../../../../../../../../../utils";
import { MondayButton } from "../../../../../../../../commonComponents";
import { ExcelIcon, VectorIcon } from "../../../../../../../../../assets";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { getActiveEmployeesData } from "../../../../../../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";

import "./UploadEmployeesStep.scss";

const { Dragger } = Upload;

function UploadEmployeeStep() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const programFields = useSelector(
    (store) => store.programFields.programFields
  );

  const {
    form,
    companies,
    uploading,
    uploadInfo,
    fetchedData,
    setUploading,
    setUploadInfo,
    employeesList,
    setFetchedData,
    setUploadedEmployees,
    setEmployeesConflicts,
  } = useContext(EmployeeUploadContext);

  const accountName = Form.useWatch("accountName", form);

  function removeFetchedData(file) {
    setFetchedData((prev) => prev.filter((el) => el?.uid !== file?.uid));
    setUploadInfo((prev) =>
      prev.filter((el) => el?.uploadId !== file?.uploadId)
    );
    setUploadedEmployees((prev) =>
      prev.filter(
        (el) => el?.uploadId !== file?.uid && el?.uploadId !== file?.uploadId
      )
    );
    setEmployeesConflicts((prev) => {
      return {
        existingEmployees: prev?.existingEmployees.filter(
          (el) => el.uploadId !== file?.uid && el?.uploadId !== file?.uploadId
        ),
        newEmployees: prev?.newEmployees.filter(
          (el) => el.uploadId !== file.uid && el?.uploadId !== file?.uploadId
        ),
      };
    });
  }

  async function getEmployeesFromFingerCheck() {
    message.loading({
      key: "fingerCheckFetch",
      duration: 0,
      content: "Getting employees from Finger Check...",
    });
    setUploading(true);
    try {
      const clientKey = clientConfigs.find(
        (el) => el.clientName === accountName
      ).clientKey;
      const result = await getActiveEmployeesData(clientKey);
      const uploadId = uuidv4();
      const resultData = result?.data?.map((el) => ({
        ...el,
        accountName,
        uploadId,
        employeeId: `${accountName}-${parseFloat(el?.employeeId)}`,
      }));

      let newEmployeesUploaded = [];
      let newEmployeeConflicts = [];
      let existingEmployees = [];

      for (let employee of resultData) {
        if (!!employee?.crewName) {
          const conflictData = employeesList.find((emp) => {
            const employeeId = emp?.employeeId;
            const fingerCheckId = emp?.fingerCheckId;
            return (
              fingerCheckId === employee?.fingerCheckId ||
              employeeId === employee?.employeeId
              // ||
              // employeeId === employee?.employeeId.replace(`${accountName}-`, "")
            );
          });
          if (!!conflictData) {
            let hasDifference = false;
            for (const key of Object.keys(employee)) {
              if (
                [
                  "crewId",
                  "uploadId",
                  "crewTeam",
                  "key",
                  "members",
                  "uploadName",
                  "teamsConfiguration",
                ].includes(key)
              ) {
                continue;
              }
              if (employee[key] !== conflictData?.[key]) {
                hasDifference = true;
                break;
              }
            }
            if (hasDifference) {
              newEmployeeConflicts.push(employee);
              existingEmployees.push(conflictData);
            }
          } else {
            newEmployeesUploaded.push(employee);
          }
        }
      }
      setUploadedEmployees((prev) => [...prev, ...newEmployeesUploaded]);
      setEmployeesConflicts((prev) => ({
        existingEmployees: (prev?.existingEmployees || []).concat(
          existingEmployees
        ),
        newEmployees: (prev?.newEmployees || []).concat(newEmployeeConflicts),
      }));

      let fileSize = (376 * resultData?.length || 0) / 1024;
      let suffix = "KB";

      if (fileSize / 1024 >= 1) {
        fileSize = fileSize / 1024;
        suffix = "MB";
      }

      let tmpUploadInfo = {
        uid: uploadId,
        size: `${fileSize.toFixed(2)} ${suffix}`,
        status: "done",
        name: "FingerCheck Upload",
        rowLength: resultData?.length,
        columnLength: 12,
      };

      setFetchedData((prev) => [...prev, tmpUploadInfo]);

      message.success({
        key: "fingerCheckFetch",
        content: "Employees Fetched successfully!",
      });
      setUploading(false);
    } catch (error) {
      console.log("error: ", error);
      message.error({
        key: "fingerCheckFetch",
        content: "There was a problem getting data from Finger Check!",
      });
      setUploading(false);
    }
  }

  const clientConfigs = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field.fieldName === "Payroll Configuration"
      );
      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  return (
    <main
      className={`upload-employees-step ${
        isDarkMode && "upload-employees-step-dark"
      }`}
    >
      <Form form={form}>
        <div className="uploadCompanyDiv">
          <InputComponent
            type="select"
            label="Company"
            placeholder="Select company..."
            formItemName={"accountName"}
            dropdownClassName={isDarkMode && "darkDropDown"}
            className="uploadCompanySelect"
            form={form}
            customOptions={clientConfigs.map(({ clientName }, key) => ({
              key,
              label: clientName,
              value: clientName,
            }))}
          />
          <div
            className={
              !accountName
                ? "uploadFingerCheckButtonDiv"
                : "uploadFingerCheckButton" || uploading
            }
            style={{
              cursor: !accountName ? "not-allowed" : "pointer",
            }}
            onClick={getEmployeesFromFingerCheck}
          >
            <span className="uploadFingerCheckText">
              Upload from FingerCheck
            </span>
            <VectorIcon
              className={
                !accountName ? "vectorIconDisable" : "vectorIcon" || uploading
              }
            />
          </div>
        </div>
      </Form>
      <section className="upload-dragger-container">
        {!!accountName && (
          <Dragger
            {...{
              name: "file",
              accept: ".xlsx",
              multiple: true,
              disabled: !accountName,
              customRequest({ file, onSuccess, onError }) {
                let fileReader = new FileReader();

                fileReader.addEventListener("load", () => {
                  let { Sheets } = read(fileReader.result, {
                    sheets: "Sheet1",
                  });
                  if (Sheets.Sheet1) {
                    employeeUploadParser(Sheets.Sheet1, file);
                    const { parsedData } = employeeUploadParser(
                      Sheets.Sheet1,
                      file,
                      accountName
                    );

                    let newEmployeesUploaded = [];
                    let newEmployeeConflicts = [];
                    let existingEmployees = [];

                    for (let employee of parsedData) {
                      if (!employee?.crewName?.includes("undefined")) {
                        const conflictData = employeesList.find(
                          ({ crewName = "", employeeId = "" }) =>
                            crewName?.toLowerCase() ===
                              employee?.crewName?.toLowerCase() ||
                            employeeId === employee?.employeeId
                          // ||
                          // employeeId ===
                          //   employee?.employeeId.replace(
                          //     `${accountName}-`,
                          //     ""
                          // )
                        );
                        if (!!conflictData) {
                          let hasDifference = false;
                          for (const key of Object.keys(employee)) {
                            if (
                              [
                                "crewId",
                                "uploadId",
                                "crewTeam",
                                "key",
                                "members",
                                "uploadName",
                                "teamsConfiguration",
                              ].includes(key)
                            ) {
                              continue;
                            }
                            if (employee[key] !== conflictData?.[key]) {
                              hasDifference = true;
                              break;
                            }
                          }
                          if (hasDifference) {
                            newEmployeeConflicts.push(employee);
                            existingEmployees.push(conflictData);
                          }
                        } else {
                          newEmployeesUploaded.push(employee);
                        }
                      }
                    }

                    setUploadedEmployees((prev) => [
                      ...prev,
                      ...newEmployeesUploaded,
                    ]);
                    setEmployeesConflicts((prev) => ({
                      existingEmployees: (prev?.existingEmployees || []).concat(
                        existingEmployees
                      ),
                      newEmployees: (prev?.newEmployees || []).concat(
                        newEmployeeConflicts
                      ),
                    }));

                    let fileSize = file.size / 1024;
                    let suffix = "KB";

                    if (fileSize / 1024 >= 1) {
                      fileSize = fileSize / 1024;
                      suffix = "MB";
                    }

                    let tmpData = {
                      uploadId: file.uid,
                      size: `${fileSize.toFixed(2)} ${suffix}`,
                      name: file.name,
                      rowLength: parsedData?.length,
                      columnLength: Object.keys(parsedData?.[0] || {}).length,
                    };
                    if (!!tmpData?.rowLength) {
                      setUploadInfo((prev) => [...prev, tmpData]);
                    }
                    return onSuccess();
                  } else {
                    message.error("Invalid File!");
                    return onError();
                  }
                });

                fileReader.readAsArrayBuffer(file);
              },
              itemRender(notUsableData, file, notUsableData2, actions) {
                let fileData = uploadInfo?.find(
                  ({ uploadId = "" }) => uploadId === file?.uid
                );
                return fileData ? (
                  <div className="ant-file-preview">
                    <div
                      className="ant-file-name"
                      style={isDarkMode ? { color: "#fff" } : {}}
                    >
                      <ExcelIcon width={18} height={18} />
                      &nbsp;{fileData?.name}
                    </div>
                    <div
                      className="ant-file-data"
                      style={isDarkMode ? { color: "#fff" } : {}}
                    >
                      {fileData?.size}
                      {fileData?.columnLength}
                      {fileData?.rowLength}
                    </div>

                    <TrashIcon
                      onClick={() => {
                        actions.remove(file);
                        removeFetchedData(fileData);
                      }}
                      width={16}
                      height={16}
                      fill="#FE4C4A"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ) : null;
              },
            }}
          >
            <div className="ant-upload-drag-div">
              <UploadFiles /> Drag and drop files here
              <MondayButton hasIcon={false} className="mondayButtonBlue">
                Browse
              </MondayButton>
            </div>
          </Dragger>
        )}
        {!!fetchedData?.length &&
          fetchedData.map((fileData) => {
            return (
              <div className="ant-file-preview">
                <div className="ant-file-div">
                  <div
                    className="ant-file-name"
                    style={isDarkMode ? { color: "#fff" } : {}}
                  >
                    <ExcelIcon width={18} height={18} />
                    &nbsp;{fileData?.name}
                  </div>
                  <div
                    className="ant-file-data"
                    style={isDarkMode ? { color: "#fff" } : {}}
                  >
                    <span className="fileEl ant-file-size">
                      <span className="bolded-text">
                        {fileData?.size.split(" ")[0]}
                      </span>
                      <span>{fileData?.size.split(" ")[1]}</span>
                    </span>
                    <span className="fileEl ant-file-column">
                      <span className="bolded-text">
                        {fileData?.columnLength}
                      </span>
                      <span>columns</span>
                    </span>
                    <span className="ant-file-entries">
                      <span className="bolded-text">{fileData?.rowLength}</span>
                      <span>entries</span>
                    </span>
                  </div>
                </div>

                <TrashIcon
                  onClick={() => removeFetchedData(fileData)}
                  width={14}
                  height={18}
                  fill="#FE4C4A"
                  style={{ cursor: "pointer", marginRight: 7 }}
                />
              </div>
            );
          })}
      </section>
    </main>
  );
}

export default UploadEmployeeStep;
