import { CalendarIcon, ClockIcon } from "../../FilterView/FilterIcons";
import { boroughs } from "../../../../Dashboard/addWidgetsModalData";
import { BarFilterFunctions } from "./BarChartData";
import { Menu, Dropdown, Button, Select } from "antd";
import { DatePicker } from "antd";
import { useEffect, useMemo, useState } from "react";
import "../FilterView.scss";

import {
  datePickerProgressHandler,
  dateRangeDateProgressHandler,
  filterByProjectManagerHandler,
  filterOptions,
  getDashboardRangePresets,
  pmTables,
} from "../filtersData";
import { useSelector } from "react-redux";
import { DropdownIcon } from "../../../../BasePage/src";
import { compareObjects } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import dayjs from "dayjs";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import ProjectManagerFilter from "../ProjectManagerFilter";
import StatusFilter from "../StatusFilter";
import AddressFilter from "../AddressFilter";

const BarChart = ({
  params,
  id,
  dynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // workOrdersData,
  // defectsData,
  inspectionsData,
  archData,
  engData,
  filterStates,
  setFilterStates,
  tmpFilterStates,
  defaultFilterStates,
  clearFilterChart,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const url = window.location.pathname.split("/")[1];
  const { RangePicker } = DatePicker;
  // const [filtering, setFiltering] = useState(false);

  const table = params?.table;

  const filterValueTurnery = compareObjects(
    tmpFilterStates,
    defaultFilterStates
  );

  const rangeToDisplay = useMemo(() => {
    if (filterValueTurnery) {
      if (Array.isArray(filterStates?.filterByRange)) {
        return [
          dayjs(filterStates?.filterByRange?.[0]),
          dayjs(filterStates?.filterByRange?.[1]),
        ];
      }

      return undefined;
    } else {
      if (Array.isArray(tmpFilterStates?.filterByRange)) {
        return [
          dayjs(tmpFilterStates?.filterByRange?.[0]),
          dayjs(tmpFilterStates?.filterByRange?.[1]),
        ];
      }

      return undefined;
    }
  }, [
    filterStates?.filterByRange,
    tmpFilterStates?.filterByRange,
    filterValueTurnery,
  ]);

  return (
    <div className="filterComponents">
      <div className="tittle">Filter date</div>
      <div className="filterContainer">
        <div className="leftSideContainer">
          <div className="tittleContainer">Filter time</div>
          <div className="tittleContainer">Filter range</div>
          <div className="tittleContainer">Filter date</div>
        </div>
        <div className="rightSideContainer">
          <Dropdown
            menu={{
              style: { borderRadius: "10px" },
              items: filterOptions(
                setUpdatedDynamicStates,
                updatedDynamicStates,
                id,
                dynamicStates,
                setFilterStates,
                table
              ).map((a, index) => ({
                key: index,
                label: a?.value,
                onClick: () => {
                  // setFiltering(true);
                  a?.filter();
                  a?.buttonName();
                },
              })),
            }}
            placement="bottomCenter"
            suffixIcon={<CalendarIcon />}
            className="filter-dropdown"
            arrow
          >
            <Button>
              {filterValueTurnery
                ? filterStates?.filterByTime
                : tmpFilterStates?.filterByTime}
              <ClockIcon />
            </Button>
          </Dropdown>

          <RangePicker
            presets={
              url === "dashboard"
                ? getDashboardRangePresets()
                : getRangePresets()
            }
            value={rangeToDisplay}
            disabledDate={(current) => {
              return current > dayjs().endOf("day");
            }}
            allowClear={false}
            format="MM/DD/YYYY"
            suffixIcon={<CalendarIcon />}
            onChange={(e) => {
              dateRangeDateProgressHandler(
                e,
                setUpdatedDynamicStates,
                updatedDynamicStates,
                dynamicStates,
                id,
                setFilterStates
              );
              // setFiltering(true);
            }}
            className="filter-by-range-picker"
            popupClassName={`filter-by-date-picker-dropdown ${
              isDarkMode && "dateDropDownDark"
            }`}
          />
          <DatePicker
            value={
              filterValueTurnery
                ? filterStates?.filterByDay
                : tmpFilterStates?.filterByDay
            }
            allowClear={false}
            format="MM/DD/YYYY"
            suffixIcon={<CalendarIcon />}
            disabledDate={(current) => {
              return current > dayjs().endOf("day");
            }}
            onChange={(e) => {
              datePickerProgressHandler(
                e,
                setUpdatedDynamicStates,
                updatedDynamicStates,
                dynamicStates,
                id,
                setFilterStates
              );
              // setFiltering(true);
            }}
            className="filter-by-date-picker"
            popupClassName={`filter-by-date-picker-dropdown ${
              isDarkMode && "dateDropDownDark"
            }`}
          />
        </div>
      </div>
      <AddressFilter
        {...{
          id,
          table,
          filterStates,
          tmpFilterStates,
          filterValueTurnery,
          setFilterStates,
          dynamicStates,
        }}
      />

      {pmTables?.[table] ? (
        <ProjectManagerFilter
          {...{
            id,
            table,
            filterStates,
            dynamicStates,
            tmpFilterStates,
            filterValueTurnery,
            setFilterStates,
            setUpdatedDynamicStates,
            table,
          }}
        />
      ) : null}

      <StatusFilter
        {...{
          table,
          filterStates,
          tmpFilterStates,
          filterValueTurnery,
          setFilterStates,
        }}
      />
      {/* {checkIfToShowPm && (
          <div className="filterComponents">
            <div className="tittle">Filter by Project Managers</div>
            <div className="filterContainer">
              <div className="leftSideContainer">
                <div className="tittleContainer">Filter by Project Manager</div>
              </div>
              <div className="rightSideContainer">
                <Select
                  popupClassName="dashDropDown"
                  placeholder="Pick option"
                  suffixIcon={<DropdownIcon />}
                  onSelect={(e) => {
                    filterByProjectManagerHandler(
                      e,
                      setUpdatedDynamicStates,
                      id,
                      thirdField,
                      dynamicStates,
                      setFilterStates,
                      filterStates
                    );
                    // setFiltering(true);
                  }}
                  showSearch
                  className="status-selector"
                  value={
                    filterValueTurnery
                      ? filterStates?.filterByProjectManager
                      : tmpFilterStates?.filterByProjectManager
                  }
                  options={uniqueProjectManagers.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                />
              </div>
            </div>
          </div>
        )} */}
    </div>
  );
};

export default BarChart;
