import "./MondayButton.scss";
import React from "react";
import { cloneElement } from "react";
import { Button, Spin } from "antd";
import { titleToCamelCase } from "../../../utils";
import { WithTooltip } from "..";

//function to find text of button
const findButtonText = (element) => {
  if (typeof element === "string") {
    return element;
  }
  const newEl = element?.props?.children;
  return newEl && findButtonText(newEl);
};

//////////////////
/////
// NOTE : If you change text for displayed button, tooltip will be missing!
/////
//////////////////

const MondayButton = ({
  children,
  disabled = false,
  Icon = null,
  onClick = () => {},
  className = "mondayButtonGreen",
  containerClassName = "",
  style = {},
  buttonStyle = {},
  middleLineStyle = {},
  hasIcon = true,
  loading = false,
  tooltipCategory,
  tooltipKey,
  noTooltip = true,
  ...rest
}) => {
  const isDarkMode = localStorage.getItem("darkmode") === "true";

  const foundText = children && findButtonText(children);

  const finalTooltipKey =
    tooltipKey || (!!foundText && titleToCamelCase(foundText));

  const buttonToReturn = (
    <Button
      style={buttonStyle}
      onClick={onClick}
      className={`mondayButtonStyle ${
        isDarkMode && "mondayButtonStyleDark"
      } ${className}`?.trim()}
      disabled={disabled}
      // data-testid={rest?.["data-testid"] || "monday-button"}
      {...rest}
    >
      {children && (
        <div className="mondayButtonText">
          {/* 1. Added spin for loading state especially for e-mail communication in order to prevent multiple clicks */}
          {loading && <Spin size="small" data-testid="monday-button-spinner" />}
          {children}
        </div>
      )}

      {children && hasIcon && (
        <div
          className="mondayButtonDivider"
          style={
            !!disabled
              ? { backgroundColor: "transparent !important" }
              : { middleLineStyle }
          }
        />
      )}
      {Icon && cloneElement(Icon, { className: "mondayButtonIcon" })}
    </Button>
  );

  return (
    <div
      className={`mondayButtonContainer ${containerClassName}`}
      style={style}
      data-testid="monday-button-container"
    >
      {!noTooltip && !!finalTooltipKey ? (
        <WithTooltip {...{ tooltipCategory, tooltipKey: finalTooltipKey }}>
          {buttonToReturn}
        </WithTooltip>
      ) : (
        buttonToReturn
      )}
    </div>
  );
};

export default MondayButton;
