import { Button } from "antd"
import { compareIncluding } from "../../../../SidebarPages/utils"
import "./ArchEngPopoverContent.scss"

const ArchEngPopoverContent = ({
  existingJobsites = [],
  assignedPerson,
  setVisible,
  onPopoverRejection,
  taskType,
}) => {
  const assignedEstimations = existingJobsites.filter(
    ({ estSTATUS, permitStatus }) =>
      compareIncluding(estSTATUS, "ready to start") ||
      compareIncluding(permitStatus, "not started")
  ).length
  const pendingEstimations = existingJobsites.length - assignedEstimations

  return (
    <div className="archEngPopoverContent">
      <span className="popoverText">
        <span className="coreInfo">{assignedPerson}</span> has{" "}
        {!!assignedEstimations ? (
          <span>
            <span className="coreInfo">{assignedEstimations}</span> pending{" "}
          </span>
        ) : (
          ""
        )}
        {!!assignedEstimations && !!pendingEstimations ? "and " : ""}
        {!!pendingEstimations ? (
          <span>
            <span className="coreInfo">{pendingEstimations}</span> in progress{" "}
          </span>
        ) : (
          ""
        )}
        {taskType}
        {existingJobsites.length > 1 ? "s" : ""}.
      </span>
      <span className="popoverText">Do you want to proceed?</span>
      <div className="buttonsContainer">
        <Button type="primary" onClick={onPopoverRejection} danger>
          No
        </Button>
        <Button
          type="primary"
          className="popoverButton"
          onClick={() => setVisible(false)}
        >
          Yes
        </Button>
      </div>
    </div>
  )
}

export default ArchEngPopoverContent
