/**
 * @param {Array} programFields Programfields array state
 * @param {String} title Title of Table to get panels from programFields
 * @returns {Object} panelObject of current table
 */
export const getPanelObject = (programFields, title) => {
  return (
    programFields?.find((prog) => prog.fieldName === "Base Page Panel Object")
      ?.fieldOptions[title] || {}
  );
};
