import { useNavigate } from "react-router-dom";

import PreviewAvatar from "../../../components/pages/Settings/settingsComponents/TeamManagement/components/TeamPreview/PreviewAvatar";

const UserCard = ({ user, allUsers }) => {
  const navigate = useNavigate();

  const { groupName, identityId, departmentName, googleDriveFileId } = user;

  const onUserClickHandler = () => {
    navigate(
      `/settings/userAccess/${departmentName}/${groupName}/${identityId}`
    );
  };

  return (
    <div onClick={onUserClickHandler}>
      <PreviewAvatar
        {...{
          googleDriveFileId: googleDriveFileId,
          member: user,
          key: identityId,
          users: allUsers,
        }}
      />
    </div>
  );
};

export default UserCard;
