import { ColDef } from "ag-grid-enterprise";

/** @type {ColDef[]} */
const columnDefs = [
  {
    headerName: "Project Name",
    field: "projectName",
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "Account Name",
    field: "accountName",
  },
  {
    headerName: "Project Executive",
    field: "projectExecutive",
  },
];

export default columnDefs;
