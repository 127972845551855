import React, { useState } from "react";
import ReactQuill from "react-quill";
import { message, Popover } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import ClassRedux from "../../../../../utils/classRedux";

const colorsList = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
];
import { useSelector } from "react-redux";
import { useRedux } from "../../../../../hooks/useRedux";

function rtfEditor(props) {
  const [state, setState] = useState({
    notes: props?.value || {},
  });
  const [isWritable] = useRedux("estimationsIsWritable");
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const rowNode = props.node;
  const gridApi = props.api;
  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        { color: colorsList },
        { background: colorsList },
        "clean",
      ],
    ],
    keyboard: {
      bindings: {
        // tab: false,
        // handleEnter: {
        //     key: 13,
        //     handler: function () {
        //     }
        // },
      },
    },
  };
  const formats = ["bold", "italic", "underline", "color", "background"];

  const afterGuiAttached = () => {};

  const keyPressEventHandler = (event) => {};
  const handleChange = (content) => {
    setState({
      ...state,
      notes: {
        ...state.notes,
        note: content,
        createdBy: userConfiguration?.nameOfUser,
      },
    });
  };

  const handleQuit = (params) => {
    console.log("handleQuit", { params, props });
    if (params) return;
    if (props.type === "addon") {
      let addon = _.cloneDeep(props.data);
      addon[props.colDef.field] = {
        ...state.notes,
        note: state.notes?.note,
        createdAt: dayjs().valueOf(),
        createdBy: userConfiguration?.nameOfUser,
      };

      props.api.applyTransaction({ update: [addon] });
      props.api.refreshCells();

      if (props.addonType === AddonType.serviceAddon) {
        props.onAddonRichTextChange({ service: props.service });
      } else if (props.addonType === "documentation") {
        props.onAddonRichTextChange({ service: props.service });
      } else if (props.addonType === AddonType.pli) {
        props.onAddonRichTextChange(addon);
      }
    } else {
      const { serviceIndex, optionIndex, elevationIndex } = props;
      let pli = _.cloneDeep(props.data);

      if (pli?.[props?.colDef?.field] === state?.notes?.note) return; //if there is no change
      pli[props.colDef.field] = {
        ...state?.notes,
        note: state.notes?.note,
        createdAt: dayjs().valueOf(),
        createdBy: state?.userConfiguration?.nameOfUser,
      };

      let items = [];
      props.api.forEachNode((n) => {
        if (n?.data?.id === pli?.id) {
          Object.assign(n.data, pli);
          items.push(pli);
        } else items.push(n.data);
      });
      // const { gridData, updateStateAndSave } = this.props;

      const gridDataCopy = structuredClone(gridData);
      gridDataCopy[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items = items;

      // props.api.applyTransaction({ update: [pli] });

      setTakeOffTableData(gridDataCopy);
      props.api.refreshCells();

      // props.saveDataFromRTDEditor(
      //   items,
      //   serviceIndex,
      //   optionIndex,
      //   elevationIndex
      // );
    }
  };

  const getValue = (props) => {
    return props.data[props.colDef.field];
  };

  return (
    <>
      <Popover
        content={
          <ReactQuill
            // ref={(el) => { this.reactQuillRef = el }}
            onBlur={handleQuit}
            theme="snow"
            onChange={handleChange}
            // onKeyDown={this.keyPressEventHandler}
            className={"dataEntryQuill darkModeQuill " + props.agGridTheme}
            modules={modules}
            formats={formats}
            value={state.notes.note || ""}
            // bounds=".ag-popup-editor"
            selection={{ start: 0, end: 0 }}
            readOnly={!isWritable}
          />
        }
        overlayClassName="no-padding-antd-popover"
        style={{
          position: "absolute",
        }}
        trigger="click"
        placement="bottom"
        arrowPointAtCenter={false}
        onOpenChange={handleQuit}
      >
        <div className="rtf-editor-closed-text">
          {extractContent(state.notes?.note || "")}
        </div>
      </Popover>
    </>
  );
}

export default rtfEditor;

export function extractContent(s) {
  let span = document.createElement("span");
  span.innerHTML = s;
  return span?.textContent || span?.innerText || "Add content here...";
}
