export const tableHeaders = [
  "Member",
  "Response",
  "Topic",
  "Record Name",
  "Requested By",
];

export const tableHeadersRequest = [
  "Category",
  "Assigned To",
  "Assigned By",
  "Record Name",
];
