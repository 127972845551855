import { driveApi } from "../../../../../integrations/DriveRequest";
import { batchUpdateItems } from "../../../../../utils";

/**
 *
 * @param {String} requestId - The ID of the request
 * @param {String} filesToMove - The files to move gDriveUpload from the requestObject
 * @param {String} newParentId - The ID of the new parent folder
 * @param {String} accessToken - The access
 */
async function moveRequestFiles({
  requestId = "",
  filesToMove = [],
  newParentId = "",
  accessToken = "",
}) {
  const { moveDriveItemPromise } = driveApi({ accessToken });

  if (filesToMove.length) {
    try {
      const allFiles = [];

      filesToMove.forEach((file) => {
        allFiles.push(
          moveDriveItemPromise({
            fileId: file.id,
            newParentId,
            oldParentId: "18D0dEt-mKLpJjB21J5E3kBjCv_2TJ1aN",
          })
        );
      });

      let failedFiles = [];
      await Promise.allSettled(allFiles).then(async (res) => {
        //In case one of the files does not transfer we do not remove them from the object
        failedFiles =
          res
            .map((file) => {
              if (file.value?.ok === false) {
                const fileId = file.value?.url
                  ?.split("files/")[1]
                  ?.split("?")[0];

                return filesToMove?.find((el) => el.id === fileId);
              }
              return null;
            })
            ?.filter(Boolean) || [];

        if (!!requestId) {
          await batchUpdateItems({
            tableName: "formRequests",
            conditionKey: "requestId",
            updateData: {
              [requestId]: {
                "requestObject.gDriveUploads": failedFiles,
              },
            },
          })
            .then(() => {})
            .catch((err) => console.error(err));
        }
      });

      return failedFiles;
    } catch (err) {
      console.error(err);
      return [];
    }
  } else {
    return [];
  }
}

export default moveRequestFiles;
