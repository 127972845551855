import { Avatar } from "antd";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../integrations/DriveRequest";
import { useEffect, useState } from "react";
import { WithTooltip } from "../../../commonComponents";

function UserAvatar() {
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);
  const { accessToken } = useSelector((state) => state.accessToken);

  const [currentFile, setCurrentFile] = useState({});

  const driveRequest = driveApi({ accessToken });
  const userInitials =
    authenticatedUser?.given_name &&
    authenticatedUser?.given_name.charAt(0).toUpperCase();

  useEffect(() => {
    //setCurrentFile only if we have googleDriveFileId
    if (userConfiguration?.googleDriveFileId && accessToken)
      driveRequest
        .getImageSrc(userConfiguration.googleDriveFileId)
        .then((image) => setCurrentFile({ ...image }));
    else setCurrentFile();
  }, [userConfiguration?.googleDriveFileId, accessToken]);

  if (!authenticatedUser) return <></>;

  return (
    <div
      className="icon-container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <WithTooltip tooltipCategory="HEADER" tooltipKey="profile">
        {userConfiguration?.googleDriveFileId && currentFile?.src ? (
          <img
            width={30}
            height={30}
            style={{ borderRadius: "50%" }}
            src={currentFile?.src}
            alt="Core"
            className="profile-img-settings"
          />
        ) : (
          <Avatar
            style={{
              background: preferences.preferences?.profileColor || "#4895ef",
              display: "flex",
              alignItems: "center",
              height: 30,
              width: 30,
            }}
          >
            {userInitials}
          </Avatar>
        )}
      </WithTooltip>
    </div>
  );
}

export default UserAvatar;
