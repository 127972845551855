import { Drawer } from "antd";
import React from "react";
import { CloseIconModal } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import { CloseIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import "./MoreOptions.scss";
function MoreOptionsDrawer({ placement, open, onClose, customRender }) {
  return (
    <div className="moreOptionsDrawerContent">
      <Drawer
        title="Elevation Advanced Tools"
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
        height={160}
        maskClosable={true}
        getContainer={false}
        closeIcon={<CloseIcon />}
        z-index={20000}
        className="moreOptionsDrawer"
        // extra={
        //   <MondayButton>
        //     <CloseIcon />
        //   </MondayButton>
        // }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            // padding: 20,
          }}
        >
          {customRender?.map((item, index) => {
            return typeof item === "function" ? item() : item;
          })}
          <MondayButton
            onClick={onClose}
            className="mondayButtonRed"
            Icon={<CloseIconModal />}
          >
            Close{" "}
          </MondayButton>
        </div>
      </Drawer>
    </div>
  );
}

export default MoreOptionsDrawer;
