function updateSessions({ allSessions = [], statuses, identityId }) {
  const updatedUsers = [
    ...allSessions?.map((session) => {
      if (!!statuses?.[identityId]?.sessions?.[session?.sessionId]) {
        return {
          ...session,
          wsStatus:
            statuses?.[identityId]?.sessions?.[session?.sessionId]?.isOnline,
          isIdle:
            statuses?.[identityId]?.sessions?.[session?.sessionId]?.isIdle,
        };
      } else return session;
    }),
    ...(statuses?.[identityId]?.currentAction?.type === "open" &&
    !allSessions?.some(
      (el) =>
        el?.sessionId ===
        statuses?.[identityId]?.currentAction?.newSessionBody?.sessionId
    )
      ? [
          {
            ...statuses?.[identityId]?.currentAction?.newSessionBody,
            wsStatus: true,
            isIdle: false,
          },
        ]
      : []),
  ];
  if (statuses?.[identityId]?.currentAction?.type === "logout") {
    return updatedUsers?.filter(
      (session) =>
        session?.sessionId !== statuses?.[identityId]?.currentAction?.sessionId
    );
  } else {
    return updatedUsers;
  }
}

export default updateSessions;
