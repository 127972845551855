const gridFilters = [
  {
    globalKeyId: "6cd212b9-3a39-4b4b-a1a1-0bc49e143171",
    label: "Vehicle",
    type: "Dropdown",
    key: "fleetName",
  },
  {
    globalKeyId: "8ef44956-1a43-4e8d-aab3-44b785e68185",
    label: "From Location",
    type: "Dropdown",
    key: "fenceExit",
  },
  {
    globalKeyId: "57461831-49d0-4070-801b-60f05ed2c1c7",
    label: "To Location",
    type: "Dropdown",
    key: "fenceEnter",
  },
];

export default gridFilters;
