import { Button, Modal } from "antd";

import List from "./List";

export default function ElevationsModal({
  open,
  onClose,
  estimation,
  activeService,
}) {
  return (
    <Modal
      data-testid="elevation-modal"
      title="Elevations"
      open={open}
      centered
      width={1000}
      onCancel={onClose}
      footer={() => (
        <Button type="default" onClick={onClose}>
          Close
        </Button>
      )}
    >
      <List estimation={estimation} activeService={activeService} />
    </Modal>
  );
}
