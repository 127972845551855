import React from "react";
import { useNavigate } from "react-router-dom";
import "./ActionLogRow.scss";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

/**
 * Renders a single row in the action logs component.
 *
 * @component
 * @param {Object} log - The log object containing information about the action log.
 * @param {number} index - The index of the action log row.
 * @param {Object} reducedUsers - The object containing reduced user information.
 * @returns {JSX.Element} The rendered ActionLogRow component.
 */
const ActionLogRow = ({ log, index, reducedUsers }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const navigate = useNavigate();

  const redirect = (category, recordId) => {
    if (category && recordId) {
      navigate(`/${category}/${recordId}`);
    }
  };

  return (
    <div
      className={`action-log-row ${isDarkMode ? "action-log-row-dark" : ""}`}
      key={index}
      data-testid={`action-log-row-${index}`}
    >
      <div className="action-log-content">
        {`${log?.nameOfUser || ""} has `}
        <b>{log?.actionType || ""}</b> {`a ${log?.topic} request regarding `}
        <b
          onClick={() =>
            redirect(log?.currentData?.category, log?.currentData?.recordId)
          }
          data-testid={`action-log-record-name-${index}`}
        >
          {log?.recordName || ""}
        </b>
        {`  requested by ${reducedUsers[log?.currentData?.requestedBy] || ""}`}
      </div>
      <div className="action-log-time">
        {dayjs(log?.updatedAt)?.format("MM/DD/YYYY hh:mm A")}
      </div>
    </div>
  );
};

export default ActionLogRow;
