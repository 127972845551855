import { Modal, Table } from "antd";
import { useSelector } from "react-redux";
import { useState, useContext } from "react";

import locationColumns from "./locationColumns";
import DegModalContext from "../DegModalContext";
import JobsiteModal from "../../../../Jobsite/components/JobsiteModal";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";

import "./DegLocationsModal.scss";

function DegLocationsModal({ open, onCancel }) {
  const {
    analytics: { employeeNotFoundLocations },
    updateJobsitesHandler,
  } = useContext(DegModalContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedLocation, setSelectedLocation] = useState(null);

  return (
    <>
      <Modal
        {...{
          title: "Not found locations",
          closable: true,
          footer: null,
          centered: true,
          ["data-testid"]: "deg-locations-modal",
          className: `specificWarningsModal ${
            isDarkMode && "specificWarningsModalDark"
          }`,
          wrapClassName: "specificWarningsWrap",
          open,
          onCancel,
          closeIcon: <XIcon />,
        }}
      >
        <Table
          {...{
            dataSource: employeeNotFoundLocations.reduce(
              (acc, location) => [...acc, { location }],
              []
            ),
            pagination: {
              pageSize: 4,
            },
            columns: locationColumns({
              locationCreate(param) {
                setSelectedLocation(param);
              },
            }),
          }}
        />
      </Modal>
      {selectedLocation && (
        <JobsiteModal
          {...{
            open: selectedLocation,
            onCancel() {
              setSelectedLocation(null);
            },
            rowToEdit: {
              jobAddress: selectedLocation,
              jobName: selectedLocation,
            },
            editMode: true,
            refreshTable: updateJobsitesHandler,
          }}
        />
      )}
    </>
  );
}

export default DegLocationsModal;
