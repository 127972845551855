import moment from "moment";
import dayjs from "dayjs";
import { compareIncluding } from "../../../../utils";
import { getMomentDayTimestamp } from "../../../utils";

//Action buttons at the footer of the modal
export const footerButtons = ({
  onCancel,
  onSaveAndContinue,
  onOptionClick,
  suffix,
  saving = false,
  reverseSaveButtons,
}) => [
  {
    text: "cancel",
    cancel: true,
    onClick: onCancel,
    tooltipCategory: "Fleets",
    tooltipKey: "cancel",
  },

  {
    text: reverseSaveButtons ? "Save & Close" : "Finish",
    primary: true,
    dropdownDisabled: !!saving,
    disabled: !!saving,
    onClick: onSaveAndContinue,
    options: [reverseSaveButtons ? "Finish" : "Save & Close", "Save & New"],
    onOptionClick: onOptionClick,
    suffix: suffix,
    tooltipCategory: "Fleets",
    tooltipKey: reverseSaveButtons ? "save" : "finish",
  },
];

export const subtitle =
  "Use this page to name your vehicles. This is the name that will show on the map.";

// export const ownershipTypes = ["Rented", "Financed", "Owned", "Employee owned"];

const disabledDate = (curr) => curr > moment();

export const fleetBodyObj = ({ form, fileNames }) => {
  const fieldsValue = form.getFieldsValue();

  const {
    fleetYear,
    vinNumber,
    registrationDate,
    registrationExpDate,
    inspectionDate,
    inspectionExpDate,
    insuranceDate,
    insuranceExp,
    fEInspectionDate,
    fEExpirationData,
    fleetManufacturer,
    maxCapacity,
    deviceSerialNumber,
  } = fieldsValue;

  return {
    ...fieldsValue,
    fleetYear: dayjs(fleetYear).format("YYYY"),
    vinNumber: vinNumber.toUpperCase(),
    deviceSerialNumber,
    // registrationDate: getMomentDayTimestamp(registrationDate),
    // registrationExpDate: getMomentDayTimestamp(registrationExpDate),
    // inspectionDate: getMomentDayTimestamp(inspectionDate),
    // inspectionExpDate: getMomentDayTimestamp(inspectionExpDate),
    // insuranceDate: getMomentDayTimestamp(insuranceDate),
    // insuranceExp: getMomentDayTimestamp(insuranceExp),
    // fEInspectionDate: getMomentDayTimestamp(fEInspectionDate),
    // fEExpirationData: getMomentDayTimestamp(fEExpirationData),
    registrationDate: null,
    registrationExpDate: null,
    inspectionDate: null,
    inspectionExpDate: null,
    insuranceDate: null,
    insuranceExp: null,
    fEInspectionDate: null,
    fEExpirationData: null,
    maxCapacity: `${maxCapacity}lb`,
    driveFileId: fileNames
      ?.filter(
        ({ uploaderId }) =>
          compareIncluding(uploaderId, "Vehicle Documents") ||
          compareIncluding(uploaderId, "FE Documents")
      )
      .map(({ uploaderId, files }) => ({
        filesInfo: uploaderId,
        files,
      })),
  };
};

export const documentationBodyObj = ({ uploaderId, files, form, fleetId }) => ({
  fleetId,
  documentInfo: uploaderId,
  documentStatus: "",
  expirationDate:
    uploaderId === "Registration Documents"
      ? moment(form.getFieldValue("registrationExpDate")).startOf("day").unix()
      : uploaderId === "Inspection Documents"
      ? moment(form.getFieldValue("inspectionExpDate")).startOf("day").unix()
      : uploaderId === "Insurance Documents"
      ? moment(form.getFieldValue("insuranceExp")).startOf("day").unix()
      : "",
  receiveDate: "",
  receiveBy: "",
  driveFileId: files,
});

export const fieldComponents = ({
  form,
  accessToken,
  parentId,
  fileNames,
  onYearSelect,
  onVehicleMakeSelect,
  vehicleMakes,
  ownershipOptions,
  fetchVinNumber,
  vehicleModels,
  vehicleManufacturers,
  bodyTypes,
  selectOwnerShip,
  onPickerSuccess,
  onManufacturerSelect,
  onChange,
  fuelTypes,
  accounts,
  contacts,
  vinObject,
  fleetsNameArr,
  licence,
  drivers,
  companies,
  onDelete,
  linxupgps,
  filterLinxup,
  onChangeFleetName,
  fleetNameStr,
  onSelectFleetNameGps,
  selectedLinxupVehicle,
  onTeamSelect,
  onTeamDeselect,
  getAccessRights,
  teamsOptions = [],
  isDarkMode,
}) => [
  {
    category: "Vehicle Information",
    fields: [
      {
        label: "Vehicle Name",
        placeholder: "Vehicle Name",
        formItemName: "fleetName",
        type: "input",
        required: true,
        onChange: (e) => onChangeFleetName(e),
        style: { width: 260 },
        rules: [
          {
            validator: (_, value) => {
              return (
                fleetsNameArr
                  // ?.filter?.((el) => !!el?.fleetName)
                  ?.some?.(
                    (el) =>
                      el?.fleetName?.toLowerCase() === value?.toLowerCase()
                  )
                  ? Promise.reject(new Error("Vehicle name already exists!"))
                  : Promise.resolve()
              );
            },
          },
          // {
          //   validator: (_, value) => {
          //     return linxupgps?.some?.(
          //       (el) => el?.personName?.toLowerCase() === value?.toLowerCase()
          //     )
          //       ? Promise.resolve()
          //       : Promise.reject(new Error("This truck is not on linxup!"));
          //   },
          // },
        ],
        style: { width: 220 },
      },
      {
        label: "Vehicle based on Linxup GPS",
        placeholder: "Vehicle Name",
        formItemName: "linxupfleetName",
        type: "select",
        dropdownClassName: isDarkMode && "darkDropDown",
        showSearch: true,
        // mode: "tags",
        // required: true,
        onSelect: (e) => {
          selectedLinxupVehicle(e);
          // onSelectFleetNameGps(e);
        },
        options: !fleetNameStr
          ? [...filterLinxup?.map(({ personName }) => personName)]
          : [
              ...linxupgps
                ?.map(({ personName = "" }) => personName)
                ?.filter((el) =>
                  el
                    ?.toLowerCase()
                    ?.includes(form.getFieldValue("fleetName")?.toLowerCase())
                ),
            ],
        style: { minWidth: 220, width: 260 },
        // disabled: true,
      },
      {
        label: "VIN",
        formItemName: "vinNumber",
        placeholder: "VIN",
        required: true,
        type: "input",
        className: "uppercaseInput",
        maxLength: 17,
        rules: [
          {
            validator: (_, value) =>
              value?.length === 17
                ? Promise.resolve()
                : Promise.reject(
                    new Error("The vin number must be 17 characters long!")
                  ),
          },
          {
            validator: (_, value) => {
              return vinObject
                ?.filter((el) => !!el?.vinNumber)
                ?.some(
                  (el) => el?.vinNumber?.toLowerCase() === value?.toLowerCase()
                )
                ? Promise.reject(new Error("Vin number already exists!"))
                : Promise.resolve();
            },
          },
        ],
        style: { minWidth: 116, width: 156 },
        onPressEnter: fetchVinNumber,
      },
      {
        label: "Manufacturer",
        formItemName: "fleetManufacturer",
        placeholder: "Select...",
        required: true,
        type: "select",
        showSearch: true,
        dropdownClassName: isDarkMode && "darkDropDown",
        onSelect: onManufacturerSelect,
        options: vehicleManufacturers,
        style: { minWidth: 244, width: 284 },
      },
      {
        label: "Year",
        formItemName: "fleetYear",
        required: true,
        type: "datepicker",
        dropdownClassName: isDarkMode && "darkDateDropDown",
        picker: "year",
        format: "YYYY",
        disabledDate,
        onChange: onYearSelect,
        style: { minWidth: 160, width: 200 },
      },
      {
        label: "Make",
        formItemName: "fleetMake",
        placeholder: "Select...",
        required: true,
        type: "select",
        dropdownClassName: isDarkMode && "darkDropDown",
        showSearch: true,
        onSelect: onVehicleMakeSelect,
        options: vehicleMakes,
        style: { minWidth: 240, width: 280 },
      },
      {
        label: "Model",
        formItemName: "fleetModel",
        placeholder: "Select...",
        required: true,
        type: "select",
        dropdownClassName: isDarkMode && "darkDropDown",
        showSearch: true,
        options: vehicleModels,
        style: { minWidth: 240, width: 280 },
      },
      {
        label: "Body Type",
        formItemName: "fleetBodyType",
        placeholder: "Select...",
        required: true,
        type: "select",
        dropdownClassName: isDarkMode && "darkDropDown",
        showSearch: true,
        options: bodyTypes,
        style: { minWidth: 180, width: 220 },
      },
      {
        label: "Tracker Serial Number",
        formItemName: "deviceSerialNumber",
        placeholder: "Tracker Serial Number...",
        required: false,
        type: "input",
        style: { minWidth: 180, width: 220 },
      },
      {
        label: "License Plate",
        formItemName: "licensePlate",
        placeholder: "License Plate",
        type: "input",
        required: true,
        style: { minWidth: 220, width: 260 },
        rules: [
          {
            validator: (_, value) => {
              return licence
                ?.filter((el) => !!el?.licensePlate)
                ?.some(
                  (el) => el?.licensePlate.toLowerCase() === value.toLowerCase()
                )
                ? Promise.reject(new Error("License plate already exists!"))
                : Promise.resolve();
            },
          },
        ],
      },
      {
        label: "Title",
        formItemName: "fleetTitle",
        placeholder: "Title",
        type: "input",
        required: true,
        style: { minWidth: 180, width: 220 },
      },
      {
        label: "Title Issued",
        formItemName: "fleetTitleIssued",
        placeholder: "Title Issued",
        type: "input",
        required: true,
        style: { minWidth: 180, width: 220 },
      },
      {
        label: "Number",
        formItemName: "fleetNumber",
        placeholder: "Number",
        type: "input",
        required: true,
        style: { minWidth: 160, width: 200 },
      },
      {
        label: "Fuel Type",
        formItemName: "fuelType",
        placeholder: "Select...",
        type: "select",
        required: true,
        showSearch: true,
        options: fuelTypes,
        dropdownClassName: isDarkMode && "darkDropDown",
        style: { minWidth: 180, width: 220 },
      },
      {
        label: "Assigned to driver",
        formItemName: "assignedDriver",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        customOptions: drivers,
        dropdownClassName: isDarkMode && "darkDropDown",
        style: { minWidth: 180, width: 220 },
      },
      {
        label: "Ownership",
        formItemName: "ownership",
        type: "select",
        showSearch: true,
        options: ownershipOptions,
        required: true,
        onSelect: selectOwnerShip,
        dropdownClassName: isDarkMode && "darkDropDown",
        style: { minWidth: 180, width: 220 },
      },
      {
        label: "Odometer Reading",
        formItemName: "odometerReading",
        type: "input",
        typeNumber: "number",
        required: true,
        placeholder: "Odometer Reading",
        style: { minWidth: 180, width: 220 },
      },
      {
        type: "GPickerButton",
        required: false,
        accessToken,
        uploaderId: "Vehicle Documents",
        onPickerSuccess,
        fileNames,
        parentId,
        onDelete,
        formItemName: "uploadButton",
        label: "Upload",
        // style: {
        //   minWidth: 230,

        //   width: 270,
        // }
      },
      getAccessRights("Teams")?.write && {
        label: "Team",
        formItemName: "fleetTeam",
        placeholder: "Select team...",
        mode: "multiple",
        type: "select",
        showSearch: true,
        dropdownClassName: isDarkMode && "darkDropDown",
        customOptions: teamsOptions,
        onSelect: onTeamSelect,
        onDeselect: onTeamDeselect,
        required: true,
        allowClear: false,
      },
      {
        label: "Connected",
        formItemName: "linxupStatus",
        // placeholder: "Connected...",
        type: "select",
        dropdownClassName: isDarkMode && "darkDropDown",
        customOptions: [
          { key: 0, label: "Connected", value: true },
          { key: 0, label: "Not Connected", value: false },
        ],
        required: true,
      },
    ].filter(Boolean),
  },
  {
    category: "DMV and Tolls",
    style: { width: "50%", marginRight: "0" },
    fields: [
      {
        label: "HUT STICK#",
        formItemName: "dmvNumber",
        placeholder: "HUT STICK#",
        type: "input",
        required: true,
      },
      {
        label: "EZPass tag#",
        formItemName: "ezPassTag",
        placeholder: "EZPass tag#",
        type: "input",
        required: true,
      },

      {
        label: "Company Registered",
        formItemName: "companyRegistered",
        placeholder:
          form.getFieldValue("ownership") === "Employee owned"
            ? "Employee owned"
            : "Select...",
        required:
          form.getFieldValue("ownership") === "Employee owned" ? false : true,
        type:
          form.getFieldValue("ownership") === "Employee owned"
            ? "input"
            : "select",
        dropdownClassName: isDarkMode && "darkDropDown",
        showSearch: true,
        disabled:
          form.getFieldValue("ownership") === "Employee owned" ? true : false,
        options: companies,
      },
      {
        label: "Max Capacity",
        formItemName: "maxCapacity",
        placeholder: "Max Capacity",
        addonAfter: "lb",
        type: "input",
        required: true,
        typeNumber: true,
        rules: [
          {
            validator: (_, value) =>
              !!value && parseInt(value) > 0
                ? Promise.resolve()
                : Promise.reject(new Error("Please enter a valid value!")),
          },
        ],
      },
    ],
  },
];
