import types from '../types';
import { combineReducers } from 'redux';

let initialStates = [];
const tasks = (state = initialStates, action) => {
    switch (action.type) {
        case types.ADD_TASK:
            state.push(action.payload)
            return state
        case types.SET_TASK_STATUS:
            state = [...state];
            state[action.payload].status = 'completed'
            state[action.payload].badgeColor = 'danger'
            return state
        default: return state;
    }
}

let initialSubjects = [
    { label: "Call", value: "0" }, { label: "Email", value: "1" }, { label: "Office Meeting", value: "2" }
]
const subjects = (state = initialSubjects, action) => {
    switch (action.type) {
        
        default: return state;
    }
}

const projects = combineReducers({
    tasks,
    subjects,
})

export default projects;