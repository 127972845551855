export const applications = (
  programFields,
  updatedDynamicStates,
  applicationsData,
  IdHandler
) => {
  const data = updatedDynamicStates?.[IdHandler];

  const applicationStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    applicationStatus: () => ({
      title: "Requisition - Requisition Status",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Applications",
        field: "applicationStatus",
        title: "Requisitions Status",
        subtitle: "",
        content: applicationStatus?.statusName?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (applicationsData?.filter((a) => a?.applicationStatus === status)
              .length /
              applicationsData?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: applicationsData?.filter((a) => a?.applicationStatus === status)
            .length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //applicationStatus
  };
};
