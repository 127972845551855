/**
 * Returns the record suffix based on the element and category.
 * If the element has an "accountName" property and the category is not "Accounts",
 * it returns a string with the format " - Client: {accountName}".
 * Other use cases can be added as needed.
 * Otherwise, it returns an empty string.
 *
 * @param {Object} el - The element to check for the "accountName" property.
 * @param {string} category - The category of the record.
 * @returns {string} - The record suffix.
 */
const getRecordSuffix = (el, category) => {
  if (
    !!el?.hasOwnProperty("accountName") &&
    el?.accountName !== undefined &&
    category !== "Clients"
  ) {
    return ` - Client: ${el?.accountName}`;
  } else if (el?.hasOwnProperty("contactAccountName")) {
    if (!!el?.leadId) {
      return ` - Lead: ${el?.contactAccountName}`;
    } else {
      return ` - Client: ${el?.contactAccountName}`;
    }
  } else if (el?.hasOwnProperty("leadCompany")) {
    return ` - Client: ${el?.leadCompany}`;
  } else if (el?.hasOwnProperty("workOrderId")) {
    return ` - Fleet: ${el?.fleetName}`;
  }
  return "";
};

export default getRecordSuffix;
