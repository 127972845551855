import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

import { DefectType, StoreType } from "../../types";
import SmallInfoCard from "../SmallInfoCard/SmallInfoCard";
import { MondayButton } from "src/components/commonComponents";
import SmallVehicleInformation from "../SmallVehicleInformation/SmallVehicleInformation";
import { GoTasks } from "src/icons";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "./DefectInfoViewModal.scss";

type InfoProps = {
  open: boolean;
  defect: DefectType;
  onCancel: () => any;
};

function DefectInfoViewModal({ defect, onCancel, open }: InfoProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const navigate = useNavigate();

  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        footer: (
          <>
            {/** @ts-ignore */}
            <MondayButton
              {...{
                Icon: <GoTasks />,
                className: "mondayButtonBlue",
                onClick() {
                  navigate(`/defects/${defect.defectId}`);
                },
              }}
            >
              Open Defect
            </MondayButton>
          </>
        ),
        closeIcon: <XIcon />,
        title: `Defect - ${defect.defectId}`,
        className: `maintenance-view-info-modal defect-info ${
          isDarkMode ? "maintenance-info-dark" : ""
        }`,
      }}
    >
      <SmallVehicleInformation fleetId={defect.fleetId} />
      <SmallInfoCard
        {...{
          isDarkMode,
          title: "Defect Information",
          data: [
            {
              title: "Defect Name",
              value: defect.defectName,
            },
            {
              title: "Defect Status",
              value: defect.defectStatus,
            },
            {
              title: "Defect Priority",
              value: defect.defectPriority,
            },
            {
              title: "Defect Severity",
              value: defect.defectSeverity,
            },
            {
              title: "Inspection Id",
              value: defect.inspectionId,
            },
            {
              title: "Inspection Type",
              value: defect.inspectionName,
            },
          ],
        }}
      />
    </Modal>
  );
}

export default DefectInfoViewModal;
