import moment from "moment";
import {
  getChangedData,
  parseText,
} from "../../../../Accounting/components/utilities";
import { API } from "aws-amplify";
import {
  createEventFromTask,
  executeGetEvents,
} from "../../../../../Header/components/GoogleEvents/googleClientAPI/execute";
import { message } from "antd";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../../../pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { compareIncluding } from "../../../../utils";
import createPDF from "../../../../../../integrations/createPDF";

// Function to create the log object for task updates
//region createLogObject
export const createLogObject = (updatedTask, selectedTask) => {
  const logObject = {
    recordId: selectedTask.taskId,
    recordName: selectedTask.taskTitle,
    category: "Tasks",
    actionType: "Edit",
    topic: selectedTask.taskRelatedTo,
    currentData: {},
    previousData: {},
    updatedKeys: [],
  };

  for (let key in updatedTask) {
    let result;
    if (!!updatedTask?.[key] && !!selectedTask?.[key]) {
      result = getChangedData(updatedTask?.[key], selectedTask?.[key]);
    } else {
      continue;
    }
    if (result !== false) {
      logObject.currentData[key] = result.curr;
      logObject.previousData[key] = result.prev;
      logObject.updatedKeys.push(key);
    }
  }

  return logObject;
};

// Function to handle event updates
//region handleEventUpdate
export const handleEventUpdate = async (
  updatedTask,
  userConfiguration,
  userDetails
) => {
  try {
    const response = await executeGetEvents(
      userDetails,
      "primary",
      "all",
      false
    );
    const eventOfTask = response?.result?.items.filter(
      (event) =>
        event?.extendedProperties?.private?.taskId === updatedTask.taskId
    );
    if (!!eventOfTask && eventOfTask?.length > 0) {
      deleteTaskEvents(eventOfTask, 700);
    }
    return createEventFromTask(updatedTask, userConfiguration, true, "primary");
  } catch (err) {
    console.error(err);
    message.error("Error while getting events");
  }
};
const deleteTaskEvents = (eventsList, delay) => {
  const delayEventAdding = (fn, delay) => {
    return (event, preTime) => {
      setTimeout(() => {
        fn(event?.id, "primary"); //preferences?.calendarPreference);
      }, preTime * delay);
    };
  };
  eventsList?.forEach(delayEventAdding(deleteEvent, delay));
};

//region sendTaskEditNotification
export const sendTaskEditNotification = (
  selectedTask,
  userConfiguration,
  updatedTask,
  updateProgressStatus
) => {
  const {
    taskId,
    taskTitle,
    taskAssignedTo,
    createdByCognito,
    createdBy,
    taskStatus,
  } = updatedTask || {};

  //get assigned users in this task
  const assignedUsersAdd = taskAssignedTo?.filter(
    (oldUser) =>
      !selectedTask?.taskAssignedTo?.some(
        (newUser) => oldUser.cognitoUserId === newUser.cognitoUserId
      )
  );
  const assignedUsersAddedCognitos = taskAssignedTo?.filter(
    ({ cognitoUserId }) =>
      !selectedTask?.taskAssignedTo?.some(
        (assignedUser) => assignedUser.cognitoUserId === cognitoUserId
      )
  );

  const topicLowerCase = selectedTask.taskTopic.toLowerCase();
  const goToLink =
    topicLowerCase === "general"
      ? `${topicLowerCase.replace(/\s/g, "")}/${selectedTask.taskTopicId}`
      : "tasksManager?tab=Tasks";

  const defaultNotObj = {
    userName: userConfiguration.nameOfUser,
    currentUser: userConfiguration?.cognitoUserId,
    tab: "Tasks",
    selectedId: taskId,
    rowSelected: updatedTask,
  };

  // send notification for Task Update
  updateProgressStatus({ sendingNotification: "executing" });
  broadcastNotification(
    "5",
    "onTaskUpdate",
    [
      {
        common: userConfiguration.nameOfUser,
        commonNext: taskTitle,
      },
      {
        ...defaultNotObj,
        cognitos: Array.from(
          new Set([
            ...(taskAssignedTo?.map(({ cognitoUserId }) => cognitoUserId) ||
              []),
            //user who created the task
            userConfiguration?.allUsers?.Items?.find(
              ({ nameOfUser, cognitoUserId }) =>
                !!createdByCognito
                  ? cognitoUserId === createdByCognito
                  : nameOfUser === createdBy
            )?.cognitoUserId,
          ])
        ),
      },
    ],
    goToLink
  ).then((notificationSent) => {
    updateProgressStatus({
      sendingNotification: !!notificationSent ? "finished" : "hasError",
    });
  });

  // send notification for Task Completion
  if (selectedTask?.taskStatus !== taskStatus && taskStatus === "Completed") {
    broadcastNotification(
      "5",
      "onTaskCompletion",
      [
        {
          common: userConfiguration.nameOfUser,
        },
        {
          ...defaultNotObj,
          cognitos:
            taskAssignedTo?.map(({ cognitoUserId }) => cognitoUserId) || [],
        },
      ],
      goToLink
    );
  }

  // send notification for Task Cancellation
  if (selectedTask?.taskStatus !== taskStatus && taskStatus === "Cancelled") {
    broadcastNotification(
      "5",
      "onTaskCancellation",
      [
        {
          common: userConfiguration.nameOfUser,
        },
        {
          ...defaultNotObj,
          cognitos:
            taskAssignedTo?.map(({ cognitoUserId }) => cognitoUserId) || [],
        },
      ],
      goToLink
    );
  }

  // send notification to assigned users
  if (assignedUsersAdd.length > 0) {
    broadcastNotification(
      "5",
      "onTaskAssignation",
      [
        {
          common: userConfiguration.nameOfUser,
        },
        {
          cognitos: assignedUsersAddedCognitos,
        },
        assignedUsersAdd,
      ],
      goToLink
    );
  }
};

//region taskDocDefinition
export const taskDocDefinition = (title, dynamicPdf, companyLogo) => {
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: `${title ? title : ""}`,
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
            style: ["strong", "large"],
          },
          {
            text: `Date : ${dayjsNY().format(DEG_DATE_FORMAT)}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      dynamicPdf?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
      category: { fontSize: 10, color: "#4a4a4a" },
      commentContent: { margin: [10, 0, 10, 10] },
      replyContent: { margin: [20, 0, 10, 10] },
      replyHeader: { fontSize: 12, color: "#4a4a4a" },
    },
  };
};

//region pdfTaskBorderedSection
const pdfTaskBorderedSection = ({ body = [], noColor = false }) => {
  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };

  const formattedBody = [
    {
      text: body.taskTopic,
      style: ["lastColumn", "strong", "subheader"],
    },
    {
      columns: [
        {
          width: "60%",
          text: body?.taskSubTopic,
          alignment: "left",
          style: ["category"],
        },
        {
          width: "20%",
          text: `Status:`,
          alignment: "right",
          style: ["strong", "lastColumn"],
        },
        {
          width: "20%",
          text: `${body?.taskStatus}`,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          width: "50%",
          text: `Start Date:`,
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          width: "50%",
          text: `${dayjsNY(body?.taskStartDate).format(
            `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
          )}`,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          width: "50%",
          text: `End Date:`,
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          width: "50%",
          text: `${dayjsNY(body?.taskEndDate).format(
            `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
          )}`,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          width: "50%",
          text: `Deadline:`,
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          width: "50%",
          text: `${dayjsNY(body?.taskDeadline).format(
            `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
          )}`,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          width: "50%",
          text: `Related To:`,
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          width: "50%",
          text: body?.taskRelatedTo,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    },
    {
      width: "100%",
      text: `Task Description: ${body?.taskDescription}`,
      alignment: "left",
      style: ["lastColumn", "strong"],
    },
    {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
      width: 525,
      height: 2,
      style: ["subheader"],
    },
    {
      text: "Total Time Logged: ",
      width: "100%",
      alignment: "left",
      style: ["lastColumn", "strong"],
    },
    {
      width: "100%",
      alignment: "left",
      columns: [
        {
          width: "63%",
          text: `Started By: ${body.totalTimeLogged?.startedBy}`,
          style: ["lastColumn"],
        },
        {
          width: "37%",
          text: `Started At: ${dayjsNY(
            body.totalTimeLogged?.timeStarted
          ).format(`${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`)}`,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      width: "100%",
      alignment: "left",
      columns: [
        {
          width: "63%",
          text: `Finished By: ${body.totalTimeLogged?.finishedBy}`,
          style: ["lastColumn"],
        },
        {
          width: "37%",
          text: `Finished At: ${dayjsNY(
            body.totalTimeLogged?.timeFinished
          ).format(`${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`)}`,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      width: "100%",
      columns: [
        {
          width: "50%",
          text: "Reminder: ",
          alignment: "left",
          style: ["lastColumn", "strong"],
        },
        {
          width: "50%",
          text: `${dayjsNY(body.remindMe?.reminderTime).format(
            `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
          )}`,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    },
    {
      text: "Task Assigned To: ",
      width: "100%",
      alignment: "left",
      style: ["lastColumn", "strong"],
    },
    ...body?.taskAssignedTo.map((member) => ({
      columns: [
        {
          width: "50%",
          text: member?.nameOfUser,
          alignment: "left",
          style: ["lastColumn", "replyContent"],
        },
        {
          width: "50%",
          text: member?.userName,
          alignment: "left",
          style: ["lastColumn", "replyContent"],
        },
      ],
    })),
    {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
      width: 525,
      height: 2,
      style: ["subheader"],
    },
    ...body?.taskThread?.map((item) => {
      return [
        {
          text: item.author,
          style: ["lastColumn", "strong"],
        },
        {
          columns: [
            {
              width: "30%",
              text: item.category,
              alignment: "left",
              style: ["category"],
            },
            {
              width: "70%",
              text: dayjsNY(item.published).format(
                `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
              ),
              alignment: "right",
              style: ["category"],
            },
          ],
        },
        {
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
            <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
            </svg>`,
          width: 525,
          height: 2,
          style: ["subheader"],
        },
        {
          text: "Comment: ",
          width: "100%",
          alignment: "left",
          style: ["lastColumn"],
        },
        {
          columns: [
            {
              width: item?.reply ? "50%" : "10%",
              text: item.reply,
              alignment: "left",
              style: ["lastColumn", "replyContent"],
            },
          ],
        },

        {
          text: "Replies: ",
          width: "100%",
          alignment: "left",
          style: ["lastColumn"],
        },
        ...item?.replies?.map((reply) => {
          return [
            {
              width: "100%",
              columns: [
                {
                  width: "50%",
                  text: reply?.author,
                  alignment: "left",
                  style: ["strong", "replyHeader", "replyContent"],
                },
                {
                  width: "50%",
                  text: dayjsNY(reply.published).format(
                    `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
                  ),
                  alignment: "right",
                  style: ["category", "replyContent"],
                },
              ],
            },
            {
              width: reply.noteComment ? "50%" : "10%",
              text: reply?.content,
              alignment: "left",
              style: ["replyHeader", "replyContent"],
              styles: {
                margins: [30, 0, 0, 0],
              },
            },
          ];
        }),
        {
          width: "100%",
          text: "",
          style: ["outerTable"],
        },
      ];
    }),
  ];
  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: formattedBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

//region configPdfData
const configPdfData = ({ title, body }) => [
  pdfTaskBorderedSection({
    title,
    body,
  }),
];

//region generateTaskPdf
export const generateTaskPdf = ({ body, base64 }) => {
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  createPDF({
    action: "open",
    docDefinition: taskDocDefinition(
      body?.taskTitle,
      // "TITLE",
      configPdfData({ title: "Tasks", body }),
      (companyLogo = companyLogo)
    ),
  });
};
