import { message } from "antd";
import { API } from "aws-amplify";

export const chainRentals = async ({ rentalsArr = [] }) => {
  await API.post("includedRentals", "/includedRentals", {
    body: rentalsArr?.length > 0 ? rentalsArr : { ...(rentalsArr[0] || {}) },
  })
    .then(async (res) => {
      message.success("Rentals successfully chained");
      console.log("res", res);
      // message.loading("Updating rentals", 0);
      rentalsArr.forEach(async (rental) => {
        await API.put("rentals", `/rentals/${rental.rentalId}`, {
          body: {
            includedTo: rental.applicationId,
          },
        }).then((res) => {
          message.success("Rentals successfully logged");
          message.destroy();
        });
      });
    })
    .catch((err) => {
      message.error("Error chaining rentals");
      console.error("err", err);
    });
};
