export const wsEndpoints = {
  locale:
    process.env.NODE_ENV === "production"
      ? "ws://localhost:8080/ws/subscribe-notifications-prod"
      : "ws://localhost:8080/ws/subscribe-notifications",
  online:
    process.env.NODE_ENV === "production"
      ? "wss://leadmanager-express-backend.ue.r.appspot.com/ws/subscribe-notifications-prod"
      : "wss://leadmanager-express-backend.ue.r.appspot.com/ws/subscribe-notifications",
};

export const wsFleetsLive = {
  locale:
    process.env.NODE_ENV === "production"
      ? "ws://localhost:8080/ws/fleets-live-prod"
      : "ws://localhost:8080/ws/fleets-live",
  online:
    process.env.NODE_ENV === "production"
      ? "wss://leadmanager-sockets.ue.r.appspot.com/ws/fleets-live-prod"
      : "wss://leadmanager-sockets.ue.r.appspot.com/ws/fleets-live",
};

export const wsFormRequests = {
  locale: "ws://localhost:8080/ws/form-requests",
  online: "wss://leadmanager-sockets.ue.r.appspot.com/ws/form-requests",
};

export const wsUserSessions = {
  locale: "ws://10.0.0.84:8080/ws/user-sessions",
  online: "wss://leadmanager-sockets.ue.r.appspot.com/ws/user-sessions",
};

export const wsDispatches = {
  locale: "ws://localhost:8080/ws/dispatches",
  online: "wss://leadmanager-sockets.ue.r.appspot.com/ws/dispatches",
};

export const wsEndpointsAndBackUps = {
  backUpIPS: [
    // "ws://localhost:8080",
    "wss://leadmanager-sockets.ue.r.appspot.com",
    "wss://leadmanager-sockets.ue.r.appspot.com",
    // "ws://10.0.0.85:8080",
    // "ws://10.0.0.153:8080",
    // "ws://10.0.0.183:8080",
  ],
};
