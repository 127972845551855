import {
  DocusealBuilder,
  DocusealForm as DocusealForm2,
} from "@docuseal/react";
import { Modal } from "antd";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MondayButton } from "../../../../../../commonComponents";
import Proposal from "../../RealTimeTakeOffPreview/Proposal";
const apiKey = "A3v7emLeGXkaZN38hRGwJfbZsP6cby1KDiKmtMvd8BR";
const htmlContent = "<p>Your HTML content here</p>";
import "./DocuSealForm.scss";
const requestOptions = {
  method: "POST",
  url: "https://api.docuseal.co/templates/html",
  headers: {
    "X-Auth-Token": "A3v7emLeGXkaZN38hRGwJfbZsP6cby1KDiKmtMvd8BR",
    "content-type": "application/json",
  },
  data: {
    html: '<p>Lorem Ipsum is simply dummy text of the\n<text-field\n  name="Industry"\n  role="First Party"\n  required="false"\n  style="width: 80px; height: 16px; display: inline-block; margin-bottom: -4px">\n</text-field>\nand typesetting industry</p>\n',
    name: "Test Template",
  },
};
function DocuSealForm({
  externalId,
  initialBtnText = "DocuSeal",
  enable,
  setEnable,
}) {
  const [token, setToken] = useState(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoibGVkaW9AZmxleC5hbCIsImludGVncmF0aW9uX2VtYWlsIjoibGVkaW9AZmxleC5hbCIsImV4dGVybmFsX2lkIjoiVGVzdEZvcm0xMjM0NSIsIm5hbWUiOiJJbnRlZ3JhdGlvbiBXLTkgVGVzdCBGb3JtIiwiZG9jdW1lbnRfdXJscyI6W10sImlhdCI6MTcyMzEyNzA4MCwiZXhwIjoxNzIzMTMwNjgwfQ.FTDqVhOmca0jVUV-gmJsANGHEPe4uxgYOhcRYVm55UA"
  );

  const onTest = async (htmlContent) => {
    try {
      const response = await axios.post(
        `https://leadmanager-express-backend.ue.r.appspot.com/api/convertThis`,
        // `https://leadmanager-express-backend.ue.r.appspot.com/api/${endPoint}`,
        {}
      );

      console.log("response", response);

      //   if (!response.ok) {
      //     const errorBody = await response.text();
      //     throw new Error(
      //       `HTTP error! Status: ${response.status}, Body: ${errorBody}`
      //     );
      //   }

      //   const data = await response.json();
      //   console.log("Document conversion successful:", data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error converting HTML to document:", error.message);
    }
  };

  const onGetToken = async () => {
    // Example usage
    const data = {
      user_email: "ledio@flex.al",
      integration_email: "ledio@flex.al",
      // external_id: externalId,
      external_id: "TestForm12345",
      name: "Integration W-9 Test Form",
      document_urls: [],
    };
    try {
      const response = await fetch(
        `http://10.0.0.85:8080/api/generateToken`,

        // "https://leadmanager-express-backend.ue.r.appspot.com/api/generateToken",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      console.log("tokenresponse", response);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Generated Token:", result.token);
      setToken(result.token);
      return result.token;
    } catch (error) {
      console.error("Error generating token:", error);
    }
  };

  const openModal = () => {
    // setVisible(true);
    onGetToken();
  };
  useEffect(() => {
    setTimeout(() => {
      onGetToken();
    }, 500);
  }, [enable]);
  return (
    <div>
      {enable && (
        <Modal
          title="DocuSeal Form"
          open={enable}
          className="docuseal-modal"
          width={1200}
          onOk={() => setEnable(false)}
          onCancel={() => {
            setEnable(false);
            setToken(false);
          }}
          footer={null}
        >
          {!!token && <DocusealBuilder token={token} />}
          {/* <DocusealForm2 token={token} /> */}
          {/* <Proposal /> */}
        </Modal>
      )}
    </div>
  );
}
export default DocuSealForm;
