import { formatCurrency } from "../../../../../../../../../utils";
import { useRedux } from "../../../../../../../../../hooks";
import { CheckAll, HeaderDateInput } from "./Components";
import "./RentalBreakdownHeader.scss";

// import { useStateContext } from "../../../../../Context/Context"

import AnimatedNumber from "react-animated-number";
import { Tooltip } from "antd";

const RentalBreakdownHeader = ({ elevationData, canRedo }) => {
  // const { darkMode, fakeRentals } = useStateContext()
  const [darkMode] = useRedux("rentalsDarkMode");
  console.log("elevationData", elevationData);

  return (
    <div
      className={
        darkMode ? "rentalBreakdownHeaderDark" : "rentalBreakdownHeaderLight"
      }
    >
      {/* <div className="tittle">
        {`${
          elevationData?.elevationLabel === "Hoist"
            ? ""
            : elevationData?.elevationId
        } ${elevationData?.elevationLabel}`}{" "}
      </div> */}
      {/* <div className="information">
				Total Rent:
				<div
					className={
						!!totalRent ? "currencyPercentageContainer" : "currencyPercentageContainerZero"
					}
				>
					<Tooltip title="Rent for 4 weeks">
						<AnimatedNumber value={totalRent} formatValue={(value) => formatCurrency(value)} />
					</Tooltip>
				</div>
			</div> */}
      <HeaderDateInput {...{ elevationData, canRedo }} />{" "}
      <CheckAll {...{ elevationData }} />
      {/* <label
				style={{
					color: "#EFD652",
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
					// width: "100%",
					gap: "5px",
					marginTop: "6px",
				}}
			>
				<Info />
				Is ProRated
			</label> */}
    </div>
  );
};

export default RentalBreakdownHeader;
