import { formatCurrency } from "../../../../../utils";
import { getSums, groupBy } from "../../../calculatingFunctions";

export const applications = ({ tables, circleProgressPicker }) => {
  const filteredData = tables?.applications?.filter(
    (a) => a?.applicationStatus === circleProgressPicker
  );

  return {
    applicationStatus: () => ({
      percent: (
        (filteredData?.length / tables?.applications?.length) *
        100
      )?.toFixed(1),
      custom:
        filteredData &&
        groupBy([filteredData], "projectName")?.map((el, index) => {
          return [
            {
              "Total Amount": {
                "Current Payment": formatCurrency(
                  getSums({ data: [filteredData], key: "totalAmount" })[index]
                ),
                "Total Retainage": formatCurrency(
                  getSums({
                    data: [filteredData],
                    key: "totalRetainage",
                  })[index]
                ),
                "Total Rental With Tax": formatCurrency(
                  getSums({
                    data: [filteredData],
                    key: "totalRentalWithTax",
                  })[index]
                ),
              },
            },
            ...Object?.entries(el)?.map(([key, value]) => {
              return {
                [key]: {
                  projectId: value?.[0]?.projectId,
                  "Current Payment": formatCurrency(
                    getSums({ data: value, key: "totalAmount" })
                  ),
                  "Total Retainage": formatCurrency(
                    getSums({ data: value, key: "totalRetainage" })
                  ),
                  "Total Rental With Tax": formatCurrency(
                    getSums({ data: value, key: "totalRentalWithTax" })
                  ),
                },
              };
            }),
          ];
        }),
    }), //applicationStatus
  };
};
