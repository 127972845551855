import { Tooltip } from "antd";
import { useContext } from "react";
import { RedoOutlined, UndoOutlined } from "@ant-design/icons";

import DegModalContext from "../../DegModalContext";
import { MondayButton } from "../../../../../../../../commonComponents";

function PayrollUndoRedo() {
  const {
    setRowData,
    degGridApi,
    currentStep,
    entriesActions,
    addEntryAction,
    actionsRegister,
    addActionToRegister,
  } = useContext(DegModalContext);

  const { actions, index } = actionsRegister;

  function redoUndoNew(action, actionToUpdate) {
    if (action === "redo") {
      addEntryAction({ type: "new", entry: actionToUpdate.action });
      if (currentStep === 1) {
        degGridApi.applyTransaction({
          add: actionToUpdate.action,
          addIndex: 0,
        });
      } else {
        setRowData((prev) => actionToUpdate.action.concat(prev));
      }
    }

    if (action === "undo") {
      addEntryAction({ type: "remove", entry: actionToUpdate.action });
      if (currentStep === 1) {
        degGridApi.applyTransaction({ remove: actionToUpdate.action });
      }
      setRowData((prev) =>
        prev.filter((entry) => {
          const elIndex = actionToUpdate?.action?.findIndex(
            (el) => el?.entryId === entry?.entryId
          );
          return elIndex > -1 ? false : true;
        })
      );
    }
  }

  function redoUndoEdit(action, actionToUpdate) {
    if (action === "redo") {
      const editData = actionToUpdate.action.map(({ curr }) => curr);
      addEntryAction({ type: "edit", entry: editData });
      if (currentStep === 1) {
        degGridApi.applyTransaction({ update: editData });
      } else {
        setRowData((prev) =>
          prev.map((entry) => {
            let elIndex = editData.findIndex(
              (el) => el?.entryId === entry?.entryId
            );
            return elIndex > -1 ? editData[elIndex] : entry;
          })
        );
      }
    }
    if (action === "undo") {
      const editData = actionToUpdate.action.map(({ prev }) => prev);
      addEntryAction({ type: "edit", entry: editData });
      if (currentStep === 1) {
        degGridApi.applyTransaction({ update: editData });
      } else {
        setRowData((prev) =>
          prev.map((entry) => {
            let elIndex = editData.findIndex(
              (el) => el?.entryId === entry?.entryId
            );
            return elIndex > -1 ? editData[elIndex] : entry;
          })
        );
      }
    }
  }

  function redoUndoRemove(action, actionToUpdate) {
    const { newEntries, editedEntries, removedEntries } = actionToUpdate.action;
    const allRemovedEntries = newEntries
      .concat(editedEntries)
      .concat(removedEntries);

    if (action === "redo") {
      addEntryAction({ type: "remove", entry: allRemovedEntries });
      if (currentStep === 1) {
        degGridApi.applyTransaction({ remove: allRemovedEntries });
      } else {
        setRowData((prev) =>
          prev.filter((entry) => {
            return (
              allRemovedEntries.findIndex(
                (el) => el.entryId === entry.entryId
              ) === -1
            );
          })
        );
      }
    }

    if (action === "undo") {
      if (newEntries?.length) {
        addEntryAction({ type: "new", entry: newEntries });
      }
      if (editedEntries?.length) {
        addEntryAction({ type: "edit", entry: editedEntries });
      }

      addEntryAction({
        type: "updateState",
        entry: {
          removedEntries: entriesActions.removedEntries.filter(
            (entry) =>
              allRemovedEntries.findIndex(
                (el) => el.entryId === entry.entryId
              ) === -1
          ),
        },
      });

      if (currentStep === 1) {
        degGridApi.applyTransaction({ addIndex: 0, add: allRemovedEntries });
      } else {
        setRowData((prev) => allRemovedEntries.concat(prev));
      }
    }
  }

  function redoUndoMassChanges(action, actionToUpdate) {
    const { newEntries, editedEntries, removedEntries } = actionToUpdate.action;
    let dataToUpdate = [];
    let dataToRemove = [];
    let dataToAdd = [];

    if (action === "redo") {
      if (newEntries?.length) {
        addEntryAction({ type: "new", entry: newEntries });
        dataToAdd = dataToAdd.concat(newEntries);
      }

      if (editedEntries?.curr?.length) {
        addEntryAction({ type: "edit", entry: editedEntries.curr });
        dataToUpdate = dataToUpdate.concat(editedEntries?.curr);
      }

      if (
        removedEntries?.newEntries?.length ||
        removedEntries?.editedEntries?.length ||
        removedEntries?.removedEntries?.length
      ) {
        const allRemovedEntries = removedEntries?.newEntries
          .concat(removedEntries?.editedEntries || [])
          .concat(removedEntries?.removedEntries || []);

        addEntryAction({ type: "remove", entry: allRemovedEntries });
        dataToRemove = dataToRemove.concat(allRemovedEntries);
      }
    }
    if (action === "undo") {
      if (newEntries?.length) {
        addEntryAction({ type: "remove", entry: newEntries });
        dataToRemove = dataToRemove.concat(newEntries);
      }

      if (!!editedEntries.prev?.length || !!editedEntries.curr?.length) {
        // Change existing edited entries with the previous state
        addEntryAction({ type: "edit", entry: editedEntries?.prev || [] });
        // Remove newly added edited entries from actionsState
        addEntryAction({
          type: "updateState",
          entry: {
            editedEntries: entriesActions.editedEntries.filter(
              (el) =>
                editedEntries.editActions.newEditedEntries.findIndex(
                  (entry) => el.entryId === entry.entryId
                ) === -1
            ),
          },
        });
        dataToUpdate = dataToUpdate.concat(editedEntries?.prev || []);
      }

      if (
        removedEntries?.newEntries?.length ||
        removedEntries?.editedEntries?.length ||
        removedEntries?.removedEntries?.length
      ) {
        const allRemovedEntries = removedEntries?.newEntries
          .concat(removedEntries?.editedEntries || [])
          .concat(removedEntries?.removedEntries || []);

        dataToAdd = dataToAdd.concat(allRemovedEntries);

        // Restore new and edited entries that where removed from entryActions state
        addEntryAction({ type: "new", entry: removedEntries?.newEntries });
        addEntryAction({ type: "edit", entry: removedEntries?.editedEntries });
        addEntryAction({
          type: "updateState",
          entry: {
            removedEntries: entriesActions.removedEntries.filter(
              (entry) =>
                allRemovedEntries.findIndex(
                  (el) => el.entryId === entry.entryId
                ) === -1
            ),
          },
        });
      }
    }
    setRowData((prev) => {
      return dataToAdd.concat(
        prev.flatMap((entry) => {
          let entryToUpdate = dataToUpdate.findIndex(
            (el) => el.entryId === entry.entryId
          );
          let entryToRemove = dataToRemove.findIndex(
            (el) => el.entryId === entry.entryId
          );

          if (entryToRemove > -1) {
            return [];
          }
          if (entryToUpdate > -1) {
            return dataToUpdate[entryToUpdate];
          }
          return entry;
        })
      );
    });
  }

  const actionFunctions = {
    ["new"]: redoUndoNew,
    ["edit"]: redoUndoEdit,
    ["remove"]: redoUndoRemove,
    ["massChanges"]: redoUndoMassChanges,
  };

  function onRedo() {
    const actionToUpdate = actions?.[index + 1];
    if (actionToUpdate) {
      actionFunctions[actionToUpdate.type]("redo", actionToUpdate);
      addActionToRegister({ type: "redo" });
    }
  }

  function onUndo() {
    const actionToUpdate = actions[index];
    if (actionToUpdate) {
      actionFunctions[actionToUpdate.type]("undo", actionToUpdate);
      addActionToRegister({ type: "undo" });
    }
  }

  return (
    <div
      style={{
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        gap: 20,
      }}
    >
      <Tooltip title="Undo">
        <MondayButton
          className="mondayButtonBlue"
          disabled={!actions?.length || index === 0}
          Icon={<UndoOutlined style={{ marginInlineStart: 0 }} />}
          onClick={onUndo}
        >
          {""}
        </MondayButton>
      </Tooltip>
      <Tooltip title="Redo">
        <MondayButton
          className="mondayButtonBlue"
          disabled={!actions?.length || actions?.length === index + 1}
          Icon={<RedoOutlined style={{ marginInlineStart: 0 }} />}
          onClick={onRedo}
        >
          {""}
        </MondayButton>
      </Tooltip>
    </div>
  );
}

export default PayrollUndoRedo;
