import { useMemo, useState } from "react";
import { getPLIAddonPriceSuggestion } from "./AddonsColumnDefiner";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { attachSpanPriceToPLI } from "../../../subcomponents/elevationPanel/PLIAddon/gridHelpers";
import {
  applyPLIAddonFormulas2,
  attachPriceToPLI,
} from "../../../subcomponents/elevationPanel/PLIAddon/PLIAddon";
import { MondayButton } from "../../../../../../commonComponents";
import { PriceIconSuggestion } from "../../../../../../../assets";
import { message } from "antd";
import ArrowUp from "../../../../../../../icons/DataGrid_Up_Green.webp";
import ArrowDown from "../../../../../../../icons/DataGrid_Down_Red.webp";

var oldVals = [];
export const PLIAddonSuggestor = ({
  params = {},
  service = {},
  parentData = {},
  parentAPI = {},
  isSpan = false,
  handleSave = () => {},
}) => {
  const [indic, setIndic] = useState(0);
  const numVal = getPLIAddonPriceSuggestion(params, service);
  const dataVal = params?.data?.[isSpan === true ? "spanPrice" : "ppu"];
  let keyRef = `${service?.label || ""}_${params?.column?.colId || ""}_${
    parentData?.id || ""
  }_${params?.data?.id || ""}`;

  // const changeIndic = useMemo(() => {
  //   const temp = {
  //     arrow: null,
  //     value: 0,
  //     difference: 0,
  //     suggestedPrice: 0,
  //   };
  //   temp.value = dataVal;
  //   temp.suggestedPrice = numVal;
  //   if (temp.value > temp.suggestedPrice) {
  //     temp.arrow = (
  //       <img alt="..." className="data-grid-price-arrow" src={ArrowUpward} />
  //     );
  //   } else if (temp.value < temp.suggestedPrice) {
  //     temp.arrow = (
  //       <img
  //         alt="..."
  //         className="data-grid-price-arrow"
  //         src={ArrowDownOutlined}
  //       />
  //     );
  //   } else if (temp.value === temp.suggestedPrice) {
  //     temp.arrow = <Remove />;
  //   } else
  //     temp.arrow = <IndeterminateCheckBox style={{ visibility: "hidden" }} />;

  //   temp.difference =
  //     Math.round(Math.abs(temp.suggestedPrice - temp.value) * 100) / 100;
  //   if (isNaN(temp.difference) || temp.difference === 0) temp.difference = "";
  //   return temp;
  // }, [numVal, dataVal]);

  const onAcceptSuggestion = (suggestion) => {
    let oldVal = oldVals?.findIndex((v) => v?.id === keyRef);
    let indicVal = forceToNumber(numVal) - forceToNumber(dataVal);
    setIndic(indicVal);
    if (oldVal !== -1) {
      oldVals[oldVal] = {
        ...oldVals[oldVal],
        value: indicVal,
      };
    } else {
      oldVals.push({
        id: keyRef,
        value: indicVal,
      });
    }
    if (isSpan === true) {
      params.data.spanPrice = numVal;
      message.success("Suggestion accepted");
      attachSpanPriceToPLI({
        data: parentData,
        api: parentAPI,
        addons: parentData.addons,
      });
      params.api.refreshCells({
        rowNodes: [params.node],
        force: true,
      });
      setTimeout(() => {
        handleSave();
      }, 200);
    } else {
      message.success("Suggestion accepted");
      params.data.ppu = numVal;
      // applyPLIAddonFormulas2(props?.params?.data);
      applyPLIAddonFormulas2(params?.data);
      attachPriceToPLI({
        data: parentData,
        api: parentAPI,
        addons: parentData?.addons,
      });
      params.api.refreshCells({
        rowNodes: [params.node],
        force: true,
      });
      setTimeout(() => {
        handleSave();
      }, 200);
    }
  };

  // const { arrow, value, difference, suggestedPrice } = changeIndic;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {oldVals?.find((v) => v?.id === keyRef)?.value !== dataVal &&
        oldVals?.find((v) => v?.id === keyRef)?.value}
      {/* <span className="ppu-difference">
          {arrow} {difference}
        </span> */}
      {forceToNumber(dataVal) !== forceToNumber(numVal) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {dataVal}{" "}
          <MondayButton
            className="mondayButtonBlue"
            onClick={onAcceptSuggestion}
            Icon={<PriceIconSuggestion />}
          >
            {numVal}
          </MondayButton>
        </div>
      ) : (
        <>
          <MondayButton
            className="mondayButtonYellow"
            // onClick={onAcceptSuggestion}
            Icon={<PriceIconSuggestion />}
          >
            <span style={{ color: "white" }}>{numVal}</span>
          </MondayButton>
        </>
      )}
    </div>
  );
};
