import { CopyFilled } from "@ant-design/icons";
import React from "react";
import { PlusIcon } from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";
import { StartOverIcon } from "../../FirstProposalPage/assets";
import "../MergePdfs.scss";
function ActionsHeader({
  onStartOver,
  onMergeAll,
  onSendToDocuSeal,
  onAddCurrentProposal,
}) {
  return (
    <div className="document_merger_actions_header">
      {" "}
      <MondayButton
        className="mondayButtonBlue"
        Icon={<PlusIcon />}
        onClick={(e) => {
          e.preventDefault();
          onAddCurrentProposal();
        }}
      >
        Add Current Proposal
      </MondayButton>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<StartOverIcon />}
        onClick={(e) => {
          e.preventDefault();
          onStartOver();
        }}
      >
        Start Over
      </MondayButton>
      <MondayButton
        className="mondayButtonGreen"
        Icon={<CopyFilled />}
        onClick={(e) => {
          e.preventDefault();
          onMergeAll();
        }}
      >
        Merge documents
      </MondayButton>{" "}
      <MondayButton
        className="mondayButtonBlue"
        Icon={<CopyFilled />}
        onClick={(e) => {
          e.preventDefault();
          onSendToDocuSeal();
        }}
      >
        Send to DocuSeal
      </MondayButton>
    </div>
  );
}

export default ActionsHeader;
