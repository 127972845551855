export const defaultGridRow = {
  itemId: 1,
  itemName: "",
  itemCategory: "",
  itemDescription: "",
  amount: 0.0,
  taxAmount: 0.0,
  tax: false,
  billable: false,
  total: 0,
};
