import { Popover } from "antd";
import { getSurface } from "../../helpers/creators";

export const PliHistoryPopover = (params) => {
  const { history } = params.data;
  const { d1, d2, typeOfWorkObject } = params;

  let fromEstimation, fromEstimationLabel, forSchedule;

  if (d2 === undefined) {
    fromEstimation = params.data.originalPli?.[d1];
    if (fromEstimation === undefined) {
      fromEstimation = 100;
    }
    forSchedule = params.data[d1];
  } else {
    fromEstimation = getSurface(params.data.originalPli, { d1, d2 });
    forSchedule = params.data["surface"];
  }

  return (
    <div>
      <Popover
        overlayClassName={"pli-history-popover"}
        trigger={["hover", "click"]}
        content={
          <div>
            <div>From estimation: {fromEstimation}</div>
            {history?.map?.((hd) => (
              <div className={"schedule-history"}>
                <span className={"schedule-history-element"}>
                  {hd.scheduleName}
                </span>
                <span
                  className={
                    "schedule-history-element " + hd.typeOfWork.toLowerCase()
                  }
                >
                  {hd.typeOfWork}
                </span>
                <span
                  className={
                    "schedule-history-element " + hd.typeOfWork.toLowerCase()
                  }
                >
                  {hd.pliTotalProgress}
                </span>
                <ul className={"schedule-history-progressByDay"}>
                  {hd.progressByDay.map((dayData) => {
                    if (dayData.scheduleDay === undefined) {
                      console.log("ERROR", history, hd, dayData);
                      return "";
                    }
                    const pd1 = dayData[d1],
                      pd2 = dayData[d2];
                    const {
                      scheduleDay: { startDate },
                    } = dayData;

                    return (
                      <li>
                        {" "}
                        <strong>
                          {new Date(startDate).toLocaleDateString()}
                        </strong>{" "}
                        {d1}: {pd1}{" "}
                        {d2 ? (
                          <span>
                            {d2}: {pd2}
                          </span>
                        ) : (
                          ""
                        )}{" "}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
            <div>
              For {typeOfWorkObject?.label}: {forSchedule}{" "}
            </div>
          </div>
        }
      >
        <span className={"schedule-history-button"}>{params?.value}</span>
      </Popover>
    </div>
  );
};
