import { API } from "aws-amplify";
import { UserConfig } from "src/actions";
import { saveStepLogs } from "./saveStepLogs";

export const googleSignInStepFinished = ({
  dispatch,
  userConfiguration,
  saveAddedLogs,
}) => {
  const stepConfigs = userConfiguration?.boardingConfigs || {};

  if (!stepConfigs?.googleSignInStep?.finished) {
    const boardingConfigs = {
      ...stepConfigs,
      googleSignInStep: {
        ...(stepConfigs?.googleSignInStep || {}),
        finished: true,
      },
    };

    API.patch("userConfiguration", "/userConfiguration", {
      body: { boardingConfigs },
    }).then(() => {
      dispatch(UserConfig({ ...userConfiguration, boardingConfigs }));
      saveStepLogs(
        [
          {
            key: "finished",
            stepKey: "googleSignInStep",
            stepTitle: "Google Sign-In Configuration",
          },
        ],
        saveAddedLogs,
        userConfiguration
      );
    });
  }
};
