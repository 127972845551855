import "./PopoverLinksContent.scss";
const PopoverLinksContent = (props) => {
  const { popoverProps: links, isDarkMode } = props;

  const data = Object.keys(links).filter((el) => links[el]) || [];

  const helperObj = {
    opportunityId: { pathName: "opportunities", label: "Opportunity" },
    accountId: { pathName: "accounts", label: "Client" },
    projectId: { pathName: "projects", label: "Project" },
    leadId: { pathName: "leads", label: "Lead" },
    fleetId: { pathName: "fleets", label: "Fleet" },
    summonsImage: { label: "Document", pathName: "" },
    scheduleId: { pathName: "scheduling", label: "Schedule" },
    incidentId: { pathName: "incidents", label: "Incident" },
  };
  return (
    <div className={`linksContainer ${isDarkMode && "linksContainerDark"}`}>
      {data.map((key, index) => {
        const { label, pathName } = helperObj[key];

        return (
          <div
            key={index}
            onClick={() =>
              key === "summonsImage"
                ? window.open(links[key]?.url, "_blank")
                : window.open(`/${pathName}/${links[key]}`, "_blank")
            }
            className="popoverContentItem"
          >
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default PopoverLinksContent;
