//@ts-check

import {
  getEmailNotificationsFailure,
  getEmailNotificationsRequest,
  getEmailNotificationsRequestFinished,
  getEmailNotificationsSuccess,
  setFilters,
  updateNotifications,
} from "../../../../../actions/notificationsActions";
import { showInfoMsg } from "../../../../../utils";
import { callGmailAPI } from "../../../../SidebarPages/Communication/functions";
import { getContent } from "../utils";
import { getFilterByValue } from "../utils/helpers";

export const getSearchValueFilteredNotifications = (
  notificationsData,
  searchValue
) => {
  const searchLower = searchValue.toLowerCase().trim();

  return notificationsData.filter((obj) => {
    // Get content from utils
    const content = getContent(obj);

    // If content is not available or is not a string, skip this notification
    if (typeof content !== "string") return false;

    // Remove HTML tags from content and convert to lowercase for comparison
    const cleanedContent = content
      .replace(/<[^>]+>/g, "")
      .replace(/'/g, "")
      .toLowerCase();

    // Check if cleanedContent includes searchLower
    return cleanedContent.includes(searchLower);
  });
};

export const paginatedNotifications = (
  currentPage,
  totalNotificationsPerPage,
  allNotifications
) => {
  const startIndex = (currentPage - 1) * totalNotificationsPerPage;
  const endIndex = Math.min(
    startIndex + totalNotificationsPerPage,
    allNotifications?.length || 0
  );

  return allNotifications?.slice(startIndex, endIndex) || [];
};

export const handleMarkAllAsRead = async (
  sub,
  dispatch,
  allNotifications,
  apiService
) => {
  if (!allNotifications) return;

  const payload = {
    action: "modify",
    id: sub,
    modValue: true,
    modifyKey: "hasBeenSeen",
  };

  showInfoMsg({
    content: "All notifications marked as read",
  });

  dispatch(
    updateNotifications({
      notifications: allNotifications
        ?.filter((notification) => !notification.seen)
        .map((notification) => notification.id),
      action: "mark all as read",
    })
  );
  apiService.modifyAll(payload);
};

export const getEmailsAsNotifications = async (
  dispatch,
  category,
  authData,
  userConfiguration
) => {
  dispatch(getEmailNotificationsRequest());

  try {
    const { data } = await callGmailAPI("getEmailsAsNotifications", {
      authData,
      userConfiguration,
      category,
    });
    const { emails, unreadLength } = data;

    dispatch(getEmailNotificationsSuccess(emails, unreadLength));
  } catch (error) {
    const message = error?.response?.data?.message;

    // showInfoMsg({
    //   content: message || "Error while getting emails",
    // });
    dispatch(getEmailNotificationsFailure(error));
    console.log("caught an error", {
      error,
      message: message || "Error while getting emails",
    });
  } finally {
    dispatch(getEmailNotificationsRequestFinished());
  }
};

export const updateFilters = (dispatch, tab, filterBy) => {
  dispatch(
    setFilters({
      tab,
      filterBy,
    })
  );
};

export const handlePaginationChange = (
  page,
  tab,
  setCurrentPage,
  totalNotificationsPerPage,
  total,
  fetchData
) => {
  if (tab?.toLowerCase() === "communication") {
    setCurrentPage(page);
  } else {
    if (page * totalNotificationsPerPage > total) {
      setCurrentPage(page);
      fetchData();
    } else {
      setCurrentPage(page);
    }
  }
};

export const handleTabChange = async (
  title,
  handleUpdateFilters,
  getTabValue,
  filterBy,
  getFilterByValue,
  setCurrentPage,
  setType,
  type
) => {
  if (title?.toLowerCase() === "communication") {
    handleUpdateFilters(
      getTabValue(title),
      filterBy && filterBy === title?.toLowerCase()
        ? null
        : getFilterByValue(title)
    );
  } else {
    setCurrentPage(1);
    handleUpdateFilters(
      getTabValue(title),
      filterBy && filterBy === title?.toLowerCase()
        ? null
        : getFilterByValue(title)
    );
    setType(type === title ? null : title);
  }
};

export const handleFilterClick = async (
  title,
  setType,
  type,
  tab,
  handleUpdateFilters,
  filterBy,
  setCurrentPage
) => {
  setType(type === title ? null : title);
  if (tab?.toLowerCase() === "communication") {
    handleUpdateFilters(
      "communication",
      filterBy?.toLowerCase() === getFilterByValue(title).toLowerCase()
        ? null
        : getFilterByValue(title)
    );
  } else {
    setCurrentPage(1);
    handleUpdateFilters(tab, type === title ? null : getFilterByValue(title));
  }
};
