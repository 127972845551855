import { createContext } from "react";
import { ASSISTANT_STATUSES } from ".";
import {
  DriveFile,
  UserType,
} from "../../../SidebarPages/FleetMaintenanceView/types";

/**
 * @typedef AssistantMessageType
 * @property {string} messageId
 * @property {string} text
 * @property {string|undefined} senderId
 * @property {string} senderName
 * @property {string|undefined} roomId
 * @property {string|undefined} roomName
 * @property {boolean} botMessage
 * @property {string} parentFolderId
 * @property {DriveFile[]} attachments
 * @property {boolean} messageRetried
 * @property {number} createdAt
 * @property {"sent"|"cancelled"|"typing"|"pending"} messageStatus
 */

/**
 * @typedef VirtualAssistantContext
 * @property {typeof ASSISTANT_STATUSES[number]} status
 * @property {() => void} closeAssistant
 * @property {AssistantMessageType[]} messages
 * @property {UserType|undefined} connectedUser
 * @property {(userId: string) => void} updateConnectedUser
 * @property {(message: AssistantMessageType) => void} updateMessages
 * @property {() => void} toggleResize
 * @property {boolean} chatResized
 * @property {() => void} __temp_ask_default__ Temporary dummy function used to render the messages on screen (TO BE DELETED)
 * @property {() => Promise<void>} employeeConnectHandler
 */

/**
 * @type {VirtualAssistantContext}
 */
const context = {};

const VirtualAssistantContext = createContext(context);

export default VirtualAssistantContext;
