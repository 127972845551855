import { useState } from "react";
import { Switch, Tooltip } from "antd";
import AmountChangeInput from "./AmountChangeInput";
import { useRedux } from "../../../../../../../hooks";
import PercentageChangeInput from "./PercentageChangeInput";
import { showErrorMsg } from "../../../../../../../../../utils";

const ChargeHeader = ({
  rowData = [],
  selectedService,
  charge,
  percentage,
  setPercentage = () => {},
  currentCharge,
  isWritable,
  selectedAmount,
  setSelectedAmount = () => {},
  type = "service",
}) => {
  const [chargeItemsModal] = useRedux("chargeItemsModal");
  const { categoryFrom } = chargeItemsModal;
  const [isPercentageVisible, setIsPercentageVisible] = useState(true);

  const onSelectedAmountChangeHandler = (e) => {
    const totalAmount =
      charge?.totalities?.totalPrice ||
      charge?.totalRentalAmount ||
      charge?.totalPrice ||
      0;
    const newPercentage = (e.target.value / totalAmount) * 100;
    if (newPercentage < 0) {
      showErrorMsg({
        content:
          "Please provide a valid value. Negative values are not allowed.",
      });
      return;
    }
    if (newPercentage >= 100) {
      setPercentage(100);
      setSelectedAmount(totalAmount);
      return;
    }

    setPercentage(newPercentage);
    setSelectedAmount(e);
  };

  const onSwitchChange = (event) => {
    setIsPercentageVisible(!isPercentageVisible);
  };

  return (
    <div className="tableHeader headerTable" data-testid="charge-header">
      <div className="informationChargeHeaderContainer">
        <div
          className="titleHeaderLabel"
          style={{ width: "50%" }}
          data-testid="charge-title-header"
        >
          {(() => {
            const elevation =
              categoryFrom !== "Other"
                ? `${rowData?.elevationLabel} ${
                    (selectedService?.service !== "Hoist" &&
                      rowData?.elevationId) ||
                    ""
                  }`
                : "Other Scopes";

            const title = elevation.trim() !== "undefined" ? elevation : "";

            return (
              <Tooltip {...{ title }}>
                {charge?.label + " → " || ""}{" "}
                {type === "service" ? title : type}
              </Tooltip>
            );
          })()}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          {(charge.serviceOptions?.length === 0 ||
            (charge.serviceOptions?.length > 0 && type === "service")) && (
            <>
              {isPercentageVisible ? (
                <PercentageChangeInput
                  {...{
                    charge,
                    isWritable,
                    percentage,
                    setPercentage,
                    currentCharge,
                    setSelectedAmount,
                  }}
                />
              ) : (
                <AmountChangeInput
                  {...{
                    selectedAmount,
                    onSelectedAmountChangeHandler,
                    currentCharge,
                    isWritable,
                  }}
                />
              )}
              <Switch
                data-testid="charge-switch"
                className={
                  isPercentageVisible
                    ? "ant-switch-checked"
                    : "ant-switch-unchecked"
                }
                checkedChildren="Percentage"
                unCheckedChildren="Amount"
                defaultChecked
                onChange={onSwitchChange}
                style={{ marginTop: "3px !important" }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChargeHeader;
