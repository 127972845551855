import axios from "axios";
import { useState, useEffect } from "react";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  GOOGLE_SCOPES,
  REFRESH_TOKEN,
  GOOGLE_TOKEN_AUTH_URL,
  GOOGLE_USER_INFO_URL,
} from "../helpers/constants/googleScopes";
import { useDispatch } from "react-redux";
import types from "../types";
import { useSelector } from "react-redux";
import { useEditLogs } from "./useEditLogs";

const google = window.google;
const gapi = window.gapi;

const LOCAL_STORAGE_ACCESS_TOKEN = "googleAuthAccessToken2";
const LOCAL_STORAGE_TOKEN_EXPIRE = "googleAccessTokenExpire2";

/*
  DO NOT CHANGE THIS HOOK WITHOUT CONSULTING THE CREATOR
*/
export const useGoogleSignIn = () => {
  const dispatch = useDispatch();
  const [googleAccessToken, setGoogleAccessToken] = useState(null);
  const [userDetails, setUserDetails] = useState();
  const { isLoggedIn } = useSelector((state) => state.googleAuth);

  const { saveAddedLogs } = useEditLogs();

  useEffect(() => {
    try {
      const accessTkn = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
      if (accessTkn) {
        setGoogleAccessToken(JSON.parse(accessTkn));
      }
    } catch (error) {
      console.error("Error retrieving and parsing access token:", error);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (googleAccessToken?.access_token) {
      gapi.auth.authorize({
        client_id: CLIENT_ID,
        scope: GOOGLE_SCOPES,
        immediate: true,
      });
    }
  }, [googleAccessToken, userDetails]);

  const onGoogleSignIn = (callbackFn = () => {}) => {
    const timestampInMilliseconds = new Date().getTime();
    /* client google */
    const client = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: GOOGLE_SCOPES,
      callback: (tokenResponse) => {
        if (tokenResponse && tokenResponse?.access_token) {
          saveAddedLogs({
            actionType: "Sign In",
            category: "Google Account",
            label: "User Profile",
          });
          callbackFn(); // callback a fn after user signed in
          dispatch({
            type: types.LOGIN,
          });
          setGoogleAccessToken(tokenResponse);
          localStorage.setItem(
            LOCAL_STORAGE_ACCESS_TOKEN,
            JSON.stringify(tokenResponse)
          );
          localStorage.setItem(
            LOCAL_STORAGE_TOKEN_EXPIRE,
            JSON.stringify(timestampInMilliseconds)
          );
        }
      },
    });

    client.requestAccessToken();
  };

  const onGoogleSignOut = () => {
    google.accounts.id.disableAutoSelect();
    // google.accounts.oauth2.revoke(googleAccessToken.access_token);
    setGoogleAccessToken("");
    setUserDetails("");
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRE);
    dispatch({
      type: types.LOGOUT,
    });
    saveAddedLogs({
      actionType: "Sign Out",
      category: "Google Account",
      label: "User Profile",
    });
  };

  useEffect(() => {
    // refresh access token before it expires
    const intervalId = setInterval(() => {
      const expireTime = Number(
        localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRE)
      );
      const timeLimit = expireTime + 3580 * 1000; // Convert 3580 seconds to milliseconds and add to expireTime
      const timeNow = new Date().getTime();
      if (googleAccessToken?.access_token && timeNow > timeLimit) {
        axios
          .post(GOOGLE_TOKEN_AUTH_URL, {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: "refresh_token",
            refresh_token: REFRESH_TOKEN,
          })
          .then((res) => {
            setGoogleAccessToken((prev) => {
              return {
                ...prev,
                access_token: res.data.access_token,
                expires_in: res.data.expires_in,
                token_type: res.data.token_type,
                scope: res.data.scope,
              };
            });
            localStorage.setItem(
              LOCAL_STORAGE_ACCESS_TOKEN,
              JSON.stringify(res.data)
            );
            localStorage.setItem(
              LOCAL_STORAGE_TOKEN_EXPIRE,
              JSON.stringify(timeNow)
            );
          })
          .catch((err) => {
            console.log(err);

            setGoogleAccessToken("");
            setUserDetails("");
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRE);
            dispatch({
              type: types.LOGOUT,
            });
          });
      }
    }, 10000); // Refresh every 10 seconds

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, [googleAccessToken]);

  useEffect(() => {
    if (googleAccessToken?.access_token) {
      fetch(`${GOOGLE_USER_INFO_URL}${googleAccessToken.access_token}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((content) => {
          setUserDetails(content);
        })
        .catch((err) => {
          onGoogleSignOut();
        });
    }
  }, [googleAccessToken]);

  return {
    googleAccessToken,
    onGoogleSignIn,
    onGoogleSignOut,
    userDetails,
    authInfo: userDetails,
    userLoggedIn: isLoggedIn,
  };
};
