const keysToFilter = {
  "job application filing": [
    "applicant_full_name",
    "filing_representative_full_name",
    "filing_representative_full_address",
    "Coordinates",
    "jobsite_full_address",
    "owners_billing_address",
  ],

  "issued permits": [
    "jobsite_full_address",
    "owners_full_name",
    "full_coordinates",
    "permit_fullName",
  ],

  "approved permits": [
    "jobsite_full_address",
    "applicant_fullName",
    "filing_representative_fullName",
  ],
  "electrical permits": [
    "jobsite_full_address",
    "owners_full_name",
    "full_coordinates",
    "applicant_fullName",
  ],
};

export const filterKeys = (data, selectedCategory) => {
  if (!keysToFilter[selectedCategory]) {
    console.warn("Selected category does not exist in keysToFilter.");
    return data;
  }

  const filteredKeys = keysToFilter[selectedCategory];

  return data.filter((item) => !filteredKeys.includes(item.field));
};
