import { lengthOfItems } from "../utils";
import { countSovItems } from "./countSovItems";
import { filterDuplicatedChargeItems } from "./filterDuplicatedChargeItems";

export const lengthOfItemsSelected = (
  label,
  estimationId,
  chargeToSave,
  categoryFrom
) => {
  return categoryFrom === "Requisition"
    ? chargeToSave?.chargeItems?.filter(
        (e) => e.label === label && e.estimationId === estimationId
      )?.length
    : categoryFrom === "Schedule Of Value"
    ? countSovItems(chargeToSave?.chargeItems, label, estimationId)
    : lengthOfItems(
        filterDuplicatedChargeItems(
          chargeToSave,
          categoryFrom
        )?.chargeItems?.find(
          (e) => e.label === label && e.estimationId === estimationId
        )?.serviceOptions,
        "items",
        true
      ) || 0;
};
