import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import some from "lodash/some";

import { violationsGridColDefs } from "../../dobStepData";
import CheckmarkLabel from "../CheckmarkLabel/CheckmarkLabel";
import { clearFilter, onFilter } from "../../../../../../../SidebarPages/utils";
import { gridSidebar } from "../../../../../../../../utils";

const ViolationsTable = ({
  rowData = [],
  setSelectedRows,
  dobViolations = [],
}) => {
  const [gridApi, setGridApi] = useState();
  const [filterActive, setFilterActive] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    setSelectedRows(gridApi.getSelectedRows());
  };

  const filterActiveViolations = () => {
    !filterActive
      ? onFilter({ gridApi, column: "renderedStatus", current: "active" })
      : clearFilter({ gridApi, column: "renderedStatus" });
    setFilterActive((prev) => !prev);
  };

  const selectExistingViolations = () => {
    gridApi.forEachNode((node) => {
      const { data } = node;
      some(dobViolations, data) && node.setSelected(true);
    });
  };

  return (
    <div className="violationsPanel">
      <CheckmarkLabel
        {...{
          violations: rowData,
          onClick: () => {
            try {
              filterActiveViolations();
            } catch (e) {}
          },
        }}
      />
      {!!rowData.length && (
        <div className="agGridContainerScroll">
          <div
            className="solidGreyGridTheme ag-theme-alpine"
            style={{ width: "100%", height: "400px", overflow: "hidden" }}
          >
            <AgGridReact
              columnDefs={violationsGridColDefs}
              defaultColDef={{ flex: 1 }}
              animateRows
              rowData={rowData.sort((a, b) => b.issue_date - a.issue_date)}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
              onFirstDataRendered={selectExistingViolations}
              rowHeight={40}
              rowSelection="multiple"
              alwaysShowVerticalScroll={true}
              suppressDragLeaveHidesColumns={true}
              // rowGroupPanelShow="always"
              // pagination={true}
              sideBar={gridSidebar}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViolationsTable;
