import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import MondayButton from "../../../MondayButton/MondayButton";
import NoteCommentModal from "./NoteCommentModal";

const WriteNoteContainer = ({
  handlePostNote,
  noteCategories,
  hasWriteAccess,
  usersWithAccess,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <MondayButton
        disabled={!!!hasWriteAccess}
        onClick={() => setIsModalVisible(true)}
        Icon={<PlusOutlined />}
        className="write-note-btn"
        tooltipCategory="Notes"
        tooltipKey="writeNote"
        style={{ alignSelf: "center" }}
      >
        Write a note
      </MondayButton>
      {isModalVisible && (
        <NoteCommentModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onPost={handlePostNote}
          noteCategories={noteCategories}
          usersWithAccess={usersWithAccess}
        />
      )}
    </>
  );
};

export default WriteNoteContainer;
