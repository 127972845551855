import dayjs from "dayjs";

const rowDataWithFilters = (rowData, filters) => {
  if (Object.keys(filters)?.length > 0) {
    return rowData?.filter((el) => {
      return Object.entries(filters)?.every(([key, value]) => {
        if (value?.length > 0) {
          const filterDate = dayjs(filters?.date, "M/D/YYYY") || null;
          const startDateFilter = filters?.dates?.[0] || null;
          const endDateFilter = filters?.dates?.[1] || null;
          return key === "dates"
            ? el?.schedules?.some((schedule) =>
                schedule?.days?.some((day) =>
                  dayjs(day?.endDate)?.isBetween(
                    startDateFilter,
                    endDateFilter,
                    null,
                    "[]"
                  )
                )
              )
            : key === "service"
            ? el?.schedules?.some((schedule) =>
                schedule?.days?.some((day) =>
                  day?.dayServices?.some((label) => label === filters?.service)
                )
              )
            : key === "date"
            ? el?.schedules?.some((schedule) =>
                schedule?.days?.some((day) =>
                  dayjs(day?.endDate)?.isSame(filterDate, "day")
                )
              )
            : el;
        }
        return true;
      });
    });
  }
  return rowData;
};

export default rowDataWithFilters;
