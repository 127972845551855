import React from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

import { useRedux } from "../../../../../hooks/useRedux";
import { LogsIcon, NotesIcon } from "../../../../../DynamicView/src";
import { Notes } from "../../../../../../commonComponents/Notes/Notes";
import { CheckAccessRights } from "../../../../../../../utils/CheckAccessRights";
import { HeaderTasks } from "../../../../../components/HeaderTasks/HeaderTasks";

const InvoiceDataPanel = ({ openEditLogsHandler, invoiceData }) => {
  const [isWritable] = useRedux("invoiceIsWritable");
  const { projectName, invoiceNumber, invoiceId } = invoiceData || {};

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <>
      <Tooltip title={"Show Logs"} className="control-panel-logs">
        <span onClick={openEditLogsHandler}>
          <LogsIcon
            style={{ fill: "#fff", borderRadius: "5px", margin: "auto" }}
          />
        </span>
      </Tooltip>
      {CheckAccessRights(
        userConfiguration?.routeConfig?.find(
          ({ title }) => title === "Notes/View"
        )?.children,
        "Invoices"
      ) && (
        <Notes
          noteModalName={`${projectName} | Invoice ${invoiceNumber}`}
          rowId={invoiceId}
          topicCategory="Invoices"
          disableNotes={!isWritable}
          customIcon={<NotesIcon />}
        />
      )}
      <div className="control-panel-item">
        <HeaderTasks
          rowData={invoiceData}
          {...{ darkMode: isDarkMode }}
          taskProjectName={projectName}
          taskItemId={invoiceId}
          taskTopic={"Invoices"}
        />
      </div>
    </>
  );
};

export default InvoiceDataPanel;
