import { Modal, message } from "antd";
import { useSelector } from "react-redux";
import React, { createContext, useMemo, useState } from "react";

import { compare } from "./utils/compare";
import { useRedux } from "../../../../../hooks";
import { startsWithVowel } from "./utils/startsWithVowel";
import InfoMessage from "../../../../../../commonComponents/InfoMessage/InfoMessage";
import DynamicAgGridTable from "../../../../../../commonComponents/DynamicAgGridTable/DynamicAgGridTable";
import { AgGridTableProvider } from "../../../../../../commonComponents/DynamicAgGridTable/context/AgGridTableContext";

import {
  NewChooseChargeProvider,
  useNewChargeContext,
} from "./context/NewChooseChargeContext";
import ModalHeader from "./components/ModalHeader";
import ModalFooter from "./components/ModalFooter";
import { getColumnDefs } from "./utils/columnDefs";
import { SovDescription } from "./components/SovDescription";
import { RentalDescription } from "./components/RentalDescription";
import { InvoiceDescription } from "./components/InvoiceDescription";
import { EstimationDescription } from "./components/EstimationDescription";
import { RequisitionDescription } from "./components/RequisitionDescription";
import { calculateTotalForServiceRequisition } from "../../../../../Accounting/components/utilities";

import "./ChooseChargeFromModal.scss";
import { onRowTableClicked } from "./utils/onRowTableClicked";
import { calculateStatusForEstimationAndRental } from "./utils/calculateStatusForEstimationAndRental";
import { GroupsProvider } from "./context/GroupsContext";

const DescriptionComponent = {
  Rental: RentalDescription,
  Requisition: RequisitionDescription,
  Estimation: EstimationDescription,
  Invoice: InvoiceDescription,
  "Schedule Of Value": SovDescription,
};

export const GroupsContext = createContext();

const NewChooseChargeFromModal = (props) => {
  return (
    <AgGridTableProvider>
      <NewChooseChargeProvider {...{ ...props }}>
        <ChooseChargeFromModal />
      </NewChooseChargeProvider>
    </AgGridTableProvider>
  );
};

const ChooseChargeFromModal = () => {
  const [visible, setVisible] = useRedux("newChargeVisible");
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  const [selectedCatFrom, setSelectedCatFrom] = useState([]);
  const [formChanged, setFormChanged] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const {
    groups,
    categoriesResponse,
    dataToCompare,
    fromObject,
    requisitions,
    charges,
    setSelectedData,
    selectedGroup,
    setSelectedGroup,
  } = useNewChargeContext();

  const newRowData = useMemo(() => {
    if (!groups || !visible?.categoryFrom) return undefined;
    let newRows = groups?.sort(compare).map((el) => ({
      ...el,
      description: DescriptionComponent[visible?.categoryFrom]({
        groups,
        selectedGroup: el.id,
        proposedTypeOfWork,
        objData: categoriesResponse,
        calculateTotalForServiceRequisition,
        includedRentals: el?.includedRentals,
        ...el,
      }),
    }));
    return newRows;
  }, [groups, visible?.categoryFrom]);

  const columnDefs = useMemo(() => getColumnDefs({ visible }), []);

  const onRowSelected = (e) => {
    if (
      e.node.selected === true &&
      visible?.categoryFrom === "Invoice" &&
      visible?.type !== "Credit Memo"
    ) {
      const data = e?.node?.data;
      if (
        calculateStatusForEstimationAndRental(data?.data, data)?.title ===
        "Fully Charged"
      ) {
        message.error("This estimations is fully charged!");
        e?.node.setSelected(false);
      }
    }
    onRowTableClicked({
      e,
      visible,
      dataToCompare,
      fromObject,
      requisitions,
      charges,
      setSelectedGroup,
      setSelectedCatFrom,
      setSelectedData,
      setFormChanged,
      setSelectedInvoices,
    });
  };

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
      wrapClassName="newChargeModalWrapper"
      className={`newChargeModal commonModal ${
        isDarkMode && "newChargeModalDark"
      }`}
      title={<ModalHeader type={visible?.type} />}
      footer={
        <ModalFooter
          formChanged={formChanged}
          selectedCatFrom={selectedCatFrom}
          selectedInvoices={selectedInvoices}
          selectedGroup={selectedGroup}
          setFormChanged={setFormChanged}
          setSelectedGroup={setSelectedGroup}
        />
      }
    >
      <InfoMessage
        text={` Select            ${
          visible?.categoryFrom && startsWithVowel(visible?.categoryFrom)
            ? "an"
            : "a"
        }            ${visible?.categoryFrom?.toLowerCase()} to proceed with the charge`}
      />
      <GroupsProvider {...{ groups, selectedGroup, selectedInvoices }}>
        <DynamicAgGridTable
          rowData={newRowData}
          columnDefs={columnDefs}
          customClassName={"choseChargeAgGrid"}
          onRowSelected={onRowSelected}
          rowSelection={
            visible?.categoryFrom === "Invoice" ? "multiple" : "single"
          }
          defaultColDef={{ lockVisible: true, autoHeight: true }}
          autoResize={false}
        />
      </GroupsProvider>
    </Modal>
  );
};

export default NewChooseChargeFromModal;
