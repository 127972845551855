import { API } from "aws-amplify";
import { message } from "antd";

export async function deleteEmployee(
  id,
  rowToDelete,
  setRowToDelete,
  employeeType,
  rowData,
  setRowData,
  refreshTable
) {
  message.loading({ content: "Deleting Employee...", duration: 0, key: "1" });

  let path = employeeType;

  return await API.del(path, `/${path}/${id}`)
    .then(async (r) => {
      setRowData(
        rowData?.filter(
          (el) =>
            el?.engineerId !== id &&
            el?.architectId !== id &&
            el?.driverId !== id
        )
      );
    })
    .then((res) => {
      //   broadcastNotification(
      //     "20",
      //     "onCrewDeletion",
      //     [
      //       {
      //         common: userConfiguration.nameOfUser,
      //         commonNext: rowToDelete?.crewName,
      //       },
      //       {
      //         userName: userConfiguration.nameOfUser,
      //         currentUser: userConfiguration.cognitoUserId,
      //       },
      //     ],
      //     ""
      //   );
      //   saveAddedLogs({
      //     recordId: rowToDelete?.crewId,
      //     recordName: rowToDelete?.crewName,
      //     category: "Crews",
      //     actionType: "Delete",
      //   });

      setRowToDelete(false);
      refreshTable();
      message.success({
        content: `${
          rowToDelete?.nameOfUser || rowToDelete?.crewName
        } deleted successfully`,
        key: "1",
      });
    })
    .catch((e) => {
      console.error(e);
      message.error({ content: "Something went wrong!", key: "1" });
    });
}
