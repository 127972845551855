import { uniq } from "lodash";
import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const opportunities = ({ tables, programFields }) => {
  return {
    opportunityStatus: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Status of opportunities")
        ?.fieldOptions?.map(({ statusName }) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Opportunities?.filter(
              (a) => a?.opportunityStatus === statusName
            ).length /
              tables?.Opportunities?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: statusName,
          done: tables?.Opportunities?.filter(
            (a) => a?.opportunityStatus === statusName
          ).length,
        })),
    }),
    opportunityStage: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Opportunity Stage")
        ?.fieldOptions?.map(({ detailedStageName }) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Opportunities?.filter(
              (a) => a?.opportunityStage === detailedStageName
            ).length /
              tables?.Opportunities?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: detailedStageName,
          done: tables?.Opportunities?.filter(
            (a) => a?.opportunityStage === detailedStageName
          ).length,
        })),
    }),

    projectExecutive: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Opportunities, "projectExecutive")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Opportunities?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    projectManager: () => ({
      content: Object.entries(
        repeatedElementSum(
          tables?.Opportunities?.map((a) => a?.projectManager)?.flat(),
          "label"
        )
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Opportunities?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
  }; //opportunities
};
