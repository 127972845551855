import React, { useState, useEffect } from "react";
import { MondayButton } from "../../../../../../commonComponents";
import { ClockIcon } from "../../../../../DynamicView/components/FilterView/FilterIcons";
import { useRedux } from "../../../../../hooks";

function LastChange() {
  const [gridData] = useRedux("takeOffTableData");
  const [lastChange, setLastChange] = useState(new Date());

  useEffect(() => {
    if (gridData) {
      setLastChange(new Date());
    }
  }, [gridData]);

  return (
    <>
      <MondayButton className="mondayButtonPrismarine" Icon={<ClockIcon />}>
        Last Activity {lastChange.toLocaleString()}
      </MondayButton>
    </>
  );
}

export default LastChange;
