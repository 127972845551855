import { Button, Tooltip } from "antd";
import React from "react";
import "./AddonButton.scss";

/**
 * This is used in ag-grid tables in schedule. It is used for addons or breakdowns. This will usually render how many
 * addons have we selected (selected days for them), how many addons have we completed and how many addons are available
 * These numbers are given dynamically from numbers object which gives the count and description
 * */
export const AddonButton = ({ numbers, icon, onClick, disabled }) => {
  return (
    <Tooltip
      title={
        <div>
          {numbers?.map(({ number, description }) => {
            return (
              <div>
                {description} : {number}
              </div>
            );
          })}
        </div>
      }
    >
      <Button
        disabled={disabled}
        {...{ onClick }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#1264A3",
          width: 54,
          height: 26,
          color: "#fff",
          borderRadius: 5,
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {numbers.map(({ number }, i) => {
            let extraClass = "";
            if (number === 0) extraClass = "zero";
            else if (number === numbers[i + 1]?.number) {
              extraClass = "completed";
            }

            return (
              <>
                <div>
                  <span
                    key={i + number}
                    //   className={"addon-button-number " + extraClass}
                  >
                    {number}
                  </span>{" "}
                  {i < numbers?.length - 1 ? "/" : ""}
                </div>
              </>
            );
          })}
        </div>
        {/* {icon || ""} */}
      </Button>
    </Tooltip>
  );
};
