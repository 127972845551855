import { RichTextEditor } from "../../../../commonComponents";
import { LabeledInput } from "../../../Fleet/components";

/**
 * Generates an array of field objects for a witness form.
 *
 * @param {number} [index=0] - The index of the witness.
 * @param {Object} form - The form object.
 * @param {string} formName - The name of the form.
 * @param {string} labelName - The label name for the witness.
 * @param {boolean} disableFields - Indicates whether the fields should be disabled.
 * @param {boolean} [isDarkMode=false] - Indicates whether the dark mode is enabled.
 * @param {Object} [rowObject={}] - The row object.
 * @returns {Array} An array of field objects for the witness form.
 */
export function witnessFieldsData({
  index = 0,
  // statements = [],
  // setStatements = () => {},
  form,
  formName,
  labelName,
  disableFields,
  isDarkMode = false,
  rowObject = {},
}) {
  return [
    {
      label: labelName,
      placeholder: labelName,
      formItemName: `${formName}-${index}`,
      type: "input",
      required: false,
      style: { width: 350, maxWidth: "100%" },
      disabled: disableFields,
    },
    {
      label: "Last Name",
      placeholder: "Last Name",
      formItemName: `${formName}-lastName-${index}`,
      type: "input",
      required: false,
      style: { width: 350, maxWidth: "100%" },
      disabled: disableFields,
    },
    {
      label: "Phone Number",
      placeholder: "Phone Number",
      formItemName: `${formName}-phoneNumber-${index}`,
      type: "phone",
      required: false,
      style: { width: 350, maxWidth: "100%" },
      disabled: disableFields,
    },
    {
      label: "Email Address",
      placeholder: "Email Address",
      formItemName: `${formName}-emailAddress-${index}`,
      type: "input",
      required: false,
      rules: [
        {
          type: "email",
          message: "The input is not a valid email",
        },
      ],
      style: { width: 350, maxWidth: "100%" },
      disabled: disableFields,
    },
    // {
    //   // label: "Statement",
    //   // placeHolder: "Statement",
    //   // formItemName: `${formName}Statement-${index}`,
    //   // type: "textEditor",
    //   // value: statements[index],
    //   // required: false,
    //   // onChange(val) {
    //   //   setStatements((prev) => {
    //   //     let tPrev = structuredClone(prev);
    //   //     tPrev[index] = val;
    //   //     return tPrev;
    //   //   });
    //   // },
    //   customComponent: () => (
    //     <LabeledInput
    //       {...{
    //         style: { width: "100%" },
    //         label: "Statement",
    //         formItemName: `${formName}Statement-${index}`,
    //         disabled: disableFields,
    //       }}
    //     >
    //       {" "}
    //       <RichTextEditor
    //         {...{
    //           form,
    //           isDarkMode,
    //           formItemName: `${formName}Statement-${index}`,
    //           initialValue:
    //             rowObject?.safetyApplicationObject?.[
    //               `${formName}Statement-${index}`
    //             ] || "",
    //           disabled: disableFields,
    //         }}
    //       />
    //     </LabeledInput>
    //   ),
    // },
  ];
}
