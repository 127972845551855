export class NeedleBeamPLI {
  id;
  approved = false;
  serviceId = 7;

  length;
  width = 8;

  onFloor;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}

export class AlternateNeedleBeamPLI {
  id;
  approved = false;
  serviceId = 7;

  length;
  width = 8;

  onFloor;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;
  isAlternate = true;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
