/**
 * @param {Object} param
 * @param {Array} param.users - The users array
 * @param {String} param.accessTitle - The access title
 * @param {String} param.currentUser - The current user
 * @param {boolean} [param.selfAssign=false] - Possibility to self assign
 */
const filterUsersByAccess = ({
  users = [],
  accessTitle = "",
  currentUser = "",
  selfAssign = false,
}) => {
  const filteredUsers = (users || [])?.filter((user) =>
    user?.routeConfig?.find(
      (el) =>
        (el?.title === "Approvals/View" && el?.write === true) ||
        user?.groupName === "Admin" ||
        user?.groupName === "Super Admin"
    )
  );

  return (filteredUsers || [])
    ?.filter(
      (user) =>
        !!user?.routeConfig?.some(
          ({ title = "", write = "" }) =>
            title === accessTitle && write === true
        ) &&
        // user?.cognitoUserId !== currentUser &&
        !user?.isSuspended
    )
    ?.map((el) => ({
      label: el?.nameOfUser,
      value: el?.cognitoUserId,
      disabled: selfAssign ? false : el?.cognitoUserId === currentUser,
    }));
};

export default filterUsersByAccess;
