import { receiveSessionNotification } from "../../../utils/devUsers";

const TabData = ({ notifications, userConfiguration, communication }) => {
  return [
    {
      title: "All",
      length: notifications.all.unreadLength,
    },
    {
      title: "Hidden",
      length: notifications.hidden.unreadLength,
    },
    {
      title: "I was mentioned",
      length: notifications.iWasMentioned.unreadLength,
    },
    {
      title: "Assigned to me",
      length: notifications.assignedToMe.unreadLength,
    },
    // receiveSessionNotification?.includes(userConfiguration?.nameOfUser) && {
    //   title: "Sessions",
    //   length: notifications.sessions.unreadLength,
    // },
    {
      title: "Communication",
      length: communication.unreadLength,
    },
  ].filter(Boolean);
};
export default TabData;
