import { API } from "aws-amplify";
import moment from "moment";

export const checkIfIsExecuting = (progressArray) =>
  progressArray.some(({ progressStatus }) => progressStatus === "executing");

export const saveEditLogs = (
  findedCategory,
  datas,
  actionType,
  progressName,
  saveAddedLogs
) => {
  if (!!findedCategory?.categoryName && typeof datas === "object") {
    const {
      primaryKey,
      rowDataKeys: { rowTitle },
      rowName,
    } = findedCategory;

    const logsData = {
      recordId: datas?.[primaryKey],
      recordName: datas?.[rowTitle || rowName] || "Empty Record Name",
      category: findedCategory.categoryName,
      actionType,
    };
    saveAddedLogs({
      ...logsData,
      currentData: {},
      label: `${progressName} Failed!`,
      topic: "Progress Component",
    });
  }
};
