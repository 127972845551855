import { getWeeksTotal, getDegTotals } from "../utils";

function getCostDispersion({ analytics, jobsites }) {
  const { employeesHoursPerDay } = analytics;
  let jobSet = new Set();
  let groupedEmployees = {};
  let unmatchedDataPerRole = {};
  let jobTotalHours = 0;
  let res = [];

  /**
   * We group all the employee ids. For each employee we get all
   * the jobsites where they worked and the days in which they worked in those
   * jobsites
   */
  for (const employeeId in employeesHoursPerDay) {
    let mappedEntriesPerEmployee = [];
    for (const day in employeesHoursPerDay[employeeId]) {
      for (const job of employeesHoursPerDay[employeeId][day]["jobsitesInfo"]) {
        mappedEntriesPerEmployee.push({ jobsiteId: job?.inJobsiteId, day });
      }
    }

    groupedEmployees[employeeId] = _.groupBy(
      mappedEntriesPerEmployee,
      (entry) => entry?.jobsiteId
    );

    for (const jobId in groupedEmployees[employeeId]) {
      groupedEmployees[employeeId][jobId] = groupedEmployees[employeeId][
        jobId
      ]?.map(({ day }) => day);
      jobSet.add(jobId);
    }
  }

  /**
   * We need to create a copy of the employees hours per day analytic,
   * but we simply find the entries that match the jobsite id
   */
  const jobs = jobSet.entries();
  for (const [jobsiteId] of jobs) {
    let jobsiteMatch = jobsites?.find(({ jobsiteId: id = null }) => id === jobsiteId);
    let totalEntriesPerJobsite = {};
    let rolesPerEmployee = {};

    for (const employeeId in groupedEmployees) {
      totalEntriesPerJobsite[employeeId] = {};
      let employeeRole = "";

      let daysInJobsite = groupedEmployees[employeeId]?.[jobsiteId] || [];
      for (const day of daysInJobsite) {
        let jobInfoPerDay =
          employeesHoursPerDay[employeeId][day]["jobsitesInfo"];
        let jobIndex = jobInfoPerDay?.findIndex(
          ({ inJobsiteId }) => `${inJobsiteId}` === jobsiteId
        );

        if (jobIndex > -1) {
          totalEntriesPerJobsite[employeeId][day] = {
            jobsitesInfo: [
              {
                ...jobInfoPerDay[jobIndex],
              },
            ],
          };
        }
        employeeRole = employeesHoursPerDay[employeeId][day]["employeeRole"];
      }

      if (employeeRole) {
        if (!rolesPerEmployee[employeeRole]) {
          rolesPerEmployee[employeeRole] = [employeeId];
        } else {
          rolesPerEmployee[employeeRole] = [
            ...rolesPerEmployee[employeeRole],
            employeeId,
          ];
        }
      }
    }

    const weeksTotalForJob = getWeeksTotal({
      workerList: Object.keys(totalEntriesPerJobsite),
      allEntries: totalEntriesPerJobsite,
    });

    const totalPerJobsite = getDegTotals({
      employeeWeekTotals: weeksTotalForJob,
    });

    let details = [];

    for (const employeeRole in rolesPerEmployee) {
      let weeksTotalForRole = getWeeksTotal({
        workerList: rolesPerEmployee[employeeRole],
        allEntries: totalEntriesPerJobsite,
      });

      let totalsPerRole = getDegTotals({
        employeeWeekTotals: weeksTotalForRole,
      });

      if (!jobsiteMatch) {
        if (!unmatchedDataPerRole[employeeRole]) {
          unmatchedDataPerRole[employeeRole] = {
            unmatchedHours: totalsPerRole.totalWorkHoursWOvh,
            totalUnmatched: totalsPerRole.totalWOvh,
          };
        } else {
          let el = unmatchedDataPerRole[employeeRole];
          unmatchedDataPerRole[employeeRole] = {
            unmatchedHours:
              el?.unmatchedHours + totalsPerRole.totalWorkHoursWOvh,
            totalUnmatched: el?.totalUnmatched + totalsPerRole.totalWOvh,
          };
        }
      } else {
        details.push({
          employeeRole,
          hoursWorked: totalsPerRole?.totalWorkHoursWOvh,
          regAmount: totalsPerRole?.totalReg,
          otAmount: totalsPerRole?.totalOt,
          totalAmount: totalsPerRole?.total,
          totalOverhead: totalsPerRole?.totalOvh,
        });
      }
    }

    if (jobsiteMatch) {
      let newEntry = {
        jobsiteId,
        jobsiteName: jobsiteMatch?.jobName,
        jobsiteAddress: jobsiteMatch?.jobAddress,
        payrollType: jobsiteMatch?.payrollType,
        accountName: jobsiteMatch?.accountName,
        total: totalPerJobsite?.total,
        totalWorkHours: totalPerJobsite?.totalWorkHoursWOvh,
        totalOvertime: totalPerJobsite?.totalOt || 0,
        totalOvertimeHours: totalPerJobsite?.totalOtHours || 0,
        details,
      };
      jobTotalHours = jobTotalHours + totalPerJobsite?.totalWorkHoursWOvh;
      res.push(newEntry);
    }
  }

  for (const entry of res) {
    let jobPercentage = Number(
      (entry.totalWorkHours / jobTotalHours).toFixed(2)
    );
    let details = entry["details"];
    for (const employeeRole in unmatchedDataPerRole) {
      let tmpDetail = details.findIndex(
        ({ employeeRole: r }) => employeeRole === r
      );
      if (tmpDetail > -1) {
        details[tmpDetail] = {
          ...details[tmpDetail],
          unmatchedHours:
            unmatchedDataPerRole[employeeRole]["unmatchedHours"] *
            jobPercentage,
          totalUnmatched:
            unmatchedDataPerRole[employeeRole]["totalUnmatched"] *
            jobPercentage,
          totalOverhead:
            details[tmpDetail]?.["totalOverhead"] +
            unmatchedDataPerRole[employeeRole]["totalUnmatched"] *
              jobPercentage,
        };
      } else {
        details.push({
          employeeRole,
          hoursWorked: 0,
          regAmount: 0,
          otAmount: 0,
          totalAmount: 0,
          totalOverhead:
            unmatchedDataPerRole?.[employeeRole]?.["totalUnmatched"] *
            jobPercentage,
          unmatchedHours:
            unmatchedDataPerRole?.[employeeRole]?.["unmatchedHours"] *
            jobPercentage,
          totalUnmatched:
            unmatchedDataPerRole?.[employeeRole]?.["totalUnmatched"] *
            jobPercentage,
        });
      }
    }
  }

  return res;
}

export default getCostDispersion;
