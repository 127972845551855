import React from "react";
import ReactQuill from "react-quill";
import { message, Popover } from "antd";
import _ from "lodash";
import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { TakeOffContext } from "../../../context";

const colorsList = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
];

export default class rtfEditorAddon extends React.Component {
  static contextType = TakeOffContext;
  constructor(props) {
    super(props);
    this.rowNode = this.props.node;
    this.gridApi = this.props.api;
    this.modules = {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          { color: colorsList },
          { background: colorsList },
          "clean",
        ],
      ],
      keyboard: {
        bindings: {
          // tab: false,
          // handleEnter: {
          //     key: 13,
          //     handler: function () {
          //     }
          // },
        },
      },
    };
    // const { gridData, updateStateAndSave } = this.context;
    this.formats = ["bold", "italic", "underline", "color", "background"];
    this.state = {
      notes: this.props.value || "",
    };
    this.quillRef = null;
  }

  componentDidMount() {}

  afterGuiAttached = () => {
    // this.editorRef.current.focus();
    // this.resizeRow()
  };

  keyPressEventHandler = () => {
    if (!this.props.isWritable) {
      message.error("Please enable write mode to start editing");
    }

    // if (ev.keyCode === 13 && !ev.shiftKey) {
    //     ev.preventDefault();
    //     this.gridApi.tabToNextCell();
    // }
  };

  handleChange = (content, delta, source, editor) => {
    this.setState({ notes: content });
  };

  getValue = (props) => {
    return props.data[props.colDef.field];
  };

  render() {
    const { gridData, updateStateAndSave, isWritable } = this.context;

    const handleQuit = () => {
      try {
        const { serviceIndex, optionIndex, elevationIndex } = this.props;
        let pli = _.cloneDeep(this.props.data);

        if (pli[this.props.colDef.field] === this.state.notes) return; //if there is no change
        pli[this.props.colDef.field] = this.state.notes;

        let items = [];
        this.props.api.forEachNode((n) => {
          if (n.data.id === this.props.node?.id) {
            n.data = pli;
          }
          items.push(n.data);
        });

        console.log("pli", pli);
        this.props.api.applyTransaction({ update: [pli] });
        this.props.api.redrawRows();
        let updateData = gridData?.map?.((el) => {
          if (el?.serviceId === this?.props?.service?.serviceId) {
            return {
              ...el,
              serviceAddons: el?.serviceAddons?.map?.((sc) =>
                sc?.id === pli?.id ? pli : sc
              ),
            };
          } else {
            return el;
          }
        });
        updateStateAndSave(updateData);
      } catch (e) {
        console.warning(e);
      }
    };

    return (
      <Popover
        content={
          <ReactQuill
            // ref={(el) => { this.reactQuillRef = el }}
            onBlur={() => handleQuit()}
            theme="snow"
            onChange={this.handleChange}
            // onKeyDown={this.keyPressEventHandler}
            className={"dataEntryQuill darkModeQuill " + this.props.agGridTheme}
            modules={this.modules}
            formats={this.formats}
            value={this.state.notes}
            // bounds=".ag-popup-editor"
            selection={{ start: 0, end: 0 }}
            readOnly={!this.props.isWritable}
          />
        }
        overlayClassName="no-padding-antd-popover"
        trigger="click"
        placement="bottom"
        arrowPointAtCente={false}
        // onVisibleChange={() => handleQuit()}
      >
        {console.log(isWritable)}
        <div className="rtf-editor-closed-text">
          {extractContent(this.state.notes)}
        </div>
      </Popover>
    );
  }
}

export function extractContent(s) {
  let span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText || "-";
}
