import { message } from "antd";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { useEffect, useState, useContext } from "react";

import { useRedux } from "../../../../../hooks/useRedux";
import { onRowSelectError } from "./utils/onRowSelectError";
import { SelectedCategoryGroupContext } from "./InvoiceGroup";
import { columnDefsInitial } from "./utils/groupTablesColumnDefs";
import { updateGroupTablesColumnDefs } from "./utils/updateGroupTablesColumnDefs";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import { groupTableRowData } from "../ControlPanel/utils/groupTableRowData";
import { firstLetterToUpperCase } from "./utils/firstLetterToUpperCase";

import "./GroupsTable.scss";

export default ({ categoriesName, selectedCategory, servicesGroups }) => {
  const { onSelectionChanged, isDarkMode } = useContext(
    SelectedCategoryGroupContext
  );
  const { products, setProducts: setRowData } = useCreateInvoiceContext();

  const { categoryData, invoiceData, scheduleOfValues } =
    useInitialInvoiceContext();

  const [gridApi, setGridApi] = useState();
  const [canWrite] = useRedux("invoiceIsWritable");
  const [_, setFormChanged] = useRedux("invoiceFormChanged");

  const isWritable = !!invoiceData ? canWrite : true;

  const rowData = groupTableRowData({
    servicesGroups,
    selectedCategory,
    scheduleOfValues,
    invoiceData,
  });

  const [columnDefs, setColumnDefs] = useState(columnDefsInitial);
  //Changes columnDefs depending on the selected category
  useEffect(
    () =>
      setColumnDefs((columnDefs) =>
        updateGroupTablesColumnDefs(columnDefs, selectedCategory)
      ),
    [selectedCategory]
  );

  useEffect(() => {
    gridApi?.forEachNode(function (node) {
      const willBeSelected = products?.some(({ group }) => {
        return (
          group ===
          `${firstLetterToUpperCase(categoriesName[selectedCategory])} ${
            node?.data?.number
          }${
            selectedCategory === "applications"
              ? " SOV no. " + node?.data?.SOVNo
              : ""
          }`
        );
      });
      node?.setSelected(!!willBeSelected);
    });
  }, [products, selectedCategory]);

  const defaultColDef = {
    resizable: true,
    enableColResize: true,
    sortable: true,
    flex: 1,
    filter: true,
    suppressSizeToFit: true,
  };

  const onRowSelect = ({ node, data, ...rest }) => {
    if (isWritable) {
      if (node?.selected === false) {
        setRowData(
          (prev) =>
            prev
              ?.filter(
                ({ group }) =>
                  !(
                    group ===
                    `${firstLetterToUpperCase(
                      categoriesName[selectedCategory]
                    )} ${data.number}${
                      selectedCategory === "applications"
                        ? " SOV no. " + data?.SOVNo
                        : ""
                    }`
                  )
              )
              ?.map((el, i) => ({ ...el, id: i + 1 })) || []
        );
        return;
      }
      onRowSelectError({
        node,
        data,
        selectedCategory,
        scheduleOfValues,
        categoryData,
      });
    } else message.error("Enable Write Mode");
  };

  return (
    <div
      className={`groupTableContainer ${
        isDarkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
      id="agGridId"
      style={{
        height: "181px",
      }}
    >
      <AgGridReact
        onGridReady={({ api }) => setGridApi(api)}
        getRowNodeId={(data) => data["id"]}
        rowData={rowData} // Row Data for Rows
        rowHeight={30}
        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows\
        immutableData={true}
        pagination={false}
        rowBuffer={5}
        onRowSelected={onRowSelect}
        suppressDragLeaveHidesColumns={true}
        onSelectionChanged={(props) => {
          if (isWritable) {
            const selectedRows = props.api.getSelectedRows();
            setFormChanged(true);
            selectedRows
              ?.filter(
                (serviceGroup) =>
                  !products?.find(({ group }) => {
                    return (
                      group ===
                      `${firstLetterToUpperCase(
                        categoriesName[selectedCategory]
                      )} ${serviceGroup.number}${
                        selectedCategory === "applications"
                          ? " SOV no. " + serviceGroup?.SOVNo
                          : ""
                      }`
                    );
                  })
              )
              ?.forEach((row, index) => onSelectionChanged({ row, index }));
          } else message.error("Enable Write Mode");
        }}
      />
    </div>
  );
};
