import { preventDuplicate } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { formatCurrency } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
import {
  costChartData,
  getProjectCostData,
} from "../../../addWidgetsModalData";

export const projectCost = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  jobsites
) => {
  const data = updatedDynamicStates?.[IdHandler] || [];

  return {
    companyEmployees: () => ({
      title: "Number of Employees by Company",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Project Cost",
        field: "companyEmployees",
        id: IdHandler,
        statusData: Object.entries(
          repeatedElementSum(preventDuplicate(data, "employeeId"), "company")
        ).map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        })),
      },
      id: IdHandler,
      width: "795px",
    }),

    jobsiteAddress: () => ({
      title: "Number of Employees by Jobsite Address",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Project Cost",
        field: "jobsiteAddress",
        id: IdHandler,
        statusData: Object.entries(
          repeatedElementSum(preventDuplicate(data, "employeeId"), "jobsiteId")
        )?.map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        })),
      },
      id: IdHandler,
      width: "795px",
    }),

    punchType: () => ({
      title: "Number of Employees by Punch Type",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Project Cost",
        field: "punchType",
        id: IdHandler,
        statusData: Object.entries(
          repeatedElementSum(preventDuplicate(data, "employeeId"), "punchType")
        ).map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        })),
      },
      id: IdHandler,
      width: "795px",
    }),

    role: () => ({
      title: "Number of Employees by Role",
      BodyComponent: "Progress",
      type: "Progress",
      id: IdHandler,
      BodyComponentParams: {
        table: "Project Cost",
        field: "role",
        id: IdHandler,
        statusData: Object.entries(
          repeatedElementSum(preventDuplicate(data, "employeeId"), "role")
        ).map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        })),
      },
      width: "795px",
    }),

    jobsiteTotalCost: () => {
      const costDispersion = getProjectCostData({ data, jobsites });

      const { statusData = [] } = costChartData(
        costDispersion,
        "jobsiteAddress",
        "total",
        formatCurrency
      );

      return {
        title: "Jobsite Total Cost",
        BodyComponent: "Progress",
        type: "Progress",
        id: IdHandler,
        BodyComponentParams: {
          table: "Project Cost",
          field: "jobsiteTotalCost",
          id: IdHandler,
          statusData,
        },
        width: "795px",
      };
    },

    jobsiteTotalHours: () => {
      const costDispersion = getProjectCostData({ data, jobsites });

      const { statusData = [] } = costChartData(
        costDispersion,
        "jobsiteAddress",
        "totalWorkHours"
      );

      return {
        title: "Jobsite Total Hours",
        BodyComponent: "Progress",
        type: "Progress",
        id: IdHandler,
        BodyComponentParams: {
          table: "Project Cost",
          field: "jobsiteTotalHours",
          id: IdHandler,
          statusData,
        },
        width: "795px",
      };
    },

    jobsiteAccount: () => {
      const costDispersion = getProjectCostData({ data, jobsites });

      return {
        title: "Jobsite Account",
        BodyComponent: "Progress",
        type: "Progress",
        id: IdHandler,
        BodyComponentParams: {
          table: "Project Cost",
          field: "jobsiteAccount",
          id: IdHandler,
          statusData: costDispersion?.map((el) => ({
            label: el?.jobsiteAddress,
            value: el?.accountName?.accountName || "",
            color: "#6D8299",
          })),
        },
        width: "795px",
      };
    },

    jobsiteAccountType: () => {
      const costDispersion = getProjectCostData({ data, jobsites });

      return {
        title: "Jobsite Account Type",
        BodyComponent: "Progress",
        type: "Progress",
        id: IdHandler,
        BodyComponentParams: {
          table: "Project Cost",
          field: "jobsiteAccountType",
          id: IdHandler,
          statusData: costDispersion?.map((el) => ({
            label: `${el.jobsiteAddress} - ${
              el?.accountName?.accountName || ""
            }`,
            value: el?.accountName?.accountRecordType,
            color: "#6D8299",
          })),
        },
        width: "795px",
      };
    },
  };
};
