import { useEffect, useState } from "react";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import {
  ERROR_OPEN_STEP_MSG,
  OPEN_STEP_MSG,
  SUCCESS_OPEN_STEP_MSG,
} from "../helpers/constants";

export const useActiveStep = ({ selectedSteps, handleStepExit }) => {
  const [isSaved, setIsSaved] = useState(false);
  const [showComponent, setShowComponent] = useState(false);

  const openNextStep = async () => {
    try {
      showLoadingMsg({ content: OPEN_STEP_MSG });
      // Simulating an asynchronous operation with a timeout
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShowComponent(true);
      showSuccessMsg({ content: SUCCESS_OPEN_STEP_MSG });
    } catch (error) {
      showErrorMsg({ content: ERROR_OPEN_STEP_MSG });
    }
  };

  useEffect(() => {
    if (isSaved) {
      if (selectedSteps.length >= 1) {
        openNextStep();
        return;
      }
      if (selectedSteps.length === 0) {
        handleStepExit();
      }
    }
  }, [selectedSteps]);

  return { setIsSaved, showComponent, setShowComponent };
};
