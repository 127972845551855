import { useRef, createRef } from "react";

export const useMultipleRefs = (count) => {
  const refs = useRef([]);

  if (refs.current.length !== count) {
    refs.current = Array(count)
      .fill()
      .map((_, index) => refs.current[index] || createRef());
  }

  let temp = refs.current;
  return refs.current;
};
