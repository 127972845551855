import { API } from "aws-amplify";
import { findNestedElement, recursivelyFindObj } from "../../../utils";

// Regular expression to match UUID format
var uuidPattern =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

function getStringBeforeUUID(url) {
  var parts = url.split(uuidPattern); // Split the URL by the first UUID
  return parts[0]; // Return the first part (string before the first UUID)
}

export const updatedTopics = (topic) => {
  const updatedTopicCategories = {
    Violations: "Fleets Violations",
    Drivers: "Fleets Drivers",
    "Fleets Maintenances": "Fleet Maintenance",
    Requisition: "Applications",
    Charge: "Charges",
    Invoice: "Invoices",
    "Receive Payments": "Payments",
    Activity: "Fleet Activity",
    Accounts: "Clients",
  };
  return updatedTopicCategories?.[topic] || topic;
};

export const getTooltipCat = (tooltipsData, tooltipCategory) => {
  if (Array.isArray(tooltipCategory)) {
    const toReturn = findNestedElement(tooltipsData, tooltipCategory);
    return toReturn;
  } else {
    return tooltipsData?.[tooltipCategory];
  }
};

export const getCategoryFromPath = ({
  tooltipsData,
  location,
  userConfiguration,
}) => {
  const pathElements = location?.pathname?.split("/") || [];

  const locationPathName = pathElements[1];

  const isViewRecord = uuidPattern.test(location?.pathname);

  let pathNameCat = [];

  if (locationPathName === "settings") {
    pathNameCat = [
      "SETTINGS",
      ...(recursivelyFindObj(
        userConfiguration.routeConfig.find(({ path }) => path === "/settings")
          .children,
        "path",
        location?.pathname,
        "children",
        "title"
      )?.keys || []),
    ];
  } else {
    const findedCategory = userConfiguration?.routeConfig
      ?.find(
        (el) =>
          el?.path === `/${locationPathName}` ||
          el?.path === `/${locationPathName}/:id` ||
          el?.endpoint === locationPathName
      )
      ?.title?.replace("/View", "");

    const viewCat = userConfiguration?.routeConfig
      ?.find((el) =>
        el?.path.includes(`${getStringBeforeUUID(location?.pathname)}:`)
      )
      ?.title.replace("/View", "")
      .replace("View", "")
      .trim();

    if (isViewRecord && viewCat) {
      pathNameCat = [`${viewCat} View`];
    } else {
      pathNameCat = [findedCategory];
    }
  }

  return {
    config: getTooltipCat(tooltipsData, updatedTopics(pathNameCat)),
    category: Array.isArray(pathNameCat) ? pathNameCat : [pathNameCat],
  };
};
