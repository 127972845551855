import { Fragment } from "react";
import { MondayButton } from "src/components/commonComponents";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { PrintIcon } from "src/components/SidebarPages/BasePage/src";
import { ExcelIconWhite } from "src/assets";

function ReportsModalFooter({ onCancel, onConfirm, onlyAllowTable, onExport }) {
  return (
    <Fragment>
      <MondayButton
        {...{
          className: "mondayButtonRed",
          Icon: <XIcon />,
          onClick: onCancel,
        }}
        key="cancelButton"
      >
        Cancel
      </MondayButton>
      <div className="nested-footer">
        {onlyAllowTable && (
          <MondayButton
            className="mondayButtonBlue"
            Icon={<ExcelIconWhite />}
            onClick={onExport}
          >
            Export
          </MondayButton>
        )}
        <MondayButton
          {...{
            className: "mondayButtonBlue",
            Icon: <PrintIcon />,
            onClick: onConfirm,
          }}
          key="confirmButton"
        >
          Print
        </MondayButton>
      </div>
    </Fragment>
  );
}

export default ReportsModalFooter;
