import { Button, Tooltip } from "antd";
import "./SignControllerButtons.scss";

const SignControllerButtons = ({
  setSignatureModalVisible,
  sign,
  setSign,
  existingSignature,
  setWhichSignToChange,
  signDisabled,
  removeDisabled,
  setEditSignIn,
}) => (
  <div className="vehicleInspectionSignControllers">
    <Tooltip title={!!sign ? "Remove exisiting signature to start signin" : ""}>
      <Button
        onClick={() => {
          setWhichSignToChange({ setFunc: setSign });
          setSignatureModalVisible(true);
        }}
        disabled={signDisabled || !!sign}
        type="primary"
      >
        Sign
      </Button>
    </Tooltip>
    {!!sign && (
      <Button
        primary
        onClick={() => {
          setWhichSignToChange({ setFunc: setSign });
          setEditSignIn(true);
          setSignatureModalVisible(true);
        }}
      >
        Edit Signature
      </Button>
    )}
    <Button
      danger
      disabled={removeDisabled || !!!sign}
      onClick={() => {
        setSign(null);
        setEditSignIn(false);
      }}
    >
      Remove Signature
    </Button>

    {!!existingSignature && (
      <Tooltip title={!!sign ? "There is no existing sign" : ""}>
        <Button
          onClick={() => setSign(existingSignature)}
          disabled={!!sign}
          type="primary"
        >
          Use existing
        </Button>
      </Tooltip>
    )}
  </div>
);

export default SignControllerButtons;
