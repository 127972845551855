import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, message, Select, Radio, Tour, Tooltip } from "antd";
import {
  BorderedTextCard,
  GPlacesAutocompleteInput,
  InputComponent,
  NormalSizedModal,
  SimplePlacesInput,
} from "../../../../../../components";
import { filterTables } from "../../../../../../../../../utils/filterTables";
import "./EditActivityModal.scss";
import dayjs from "dayjs";
import { fromToArrow, truck } from "../../../../../../assets";
import API from "@aws-amplify/api";
import { apiRoutes, fetchData, validateForm } from "../../../../../../utils";
import {
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
  SortModalFields,
  Stepper,
} from "../../../../../../../../commonComponents";
import { useSelector } from "react-redux";
import { compareIncluding } from "../../../../../../../utils";
import WarningModal from "../../../../../../../../commonComponents/WarningModal/WarningModal";
import {
  DeleteIcon,
  LogsIcon,
  TransferIconNew,
  WarningTriangle,
} from "../../../../../../../DynamicView/src";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { VehicleInspectionModal } from "../../../SingleTruckInspections/modals/index";
import { AddIcon } from "../../../../../../../../Header/components/GoogleEvents/assets";

import { getChangedData } from "../../../../../../../Accounting/components/utilities";

import { cargoState } from "../../../../../../Dispatch/modals/NewDispatchModal/OldModal/newDispatchModalData";
import { showSuccessMsg } from "../../../../../../../../../utils/windowMessages";
import { XIcon } from "../../../../../../../Communication/assets/index";
import broadcastNotification from "../../../../../../../../../helpers/controllers/broadcastNotification";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../../../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import {
  getCognitosForNotification,
  updateTeamsConfiguration,
} from "../../../../../../../../../utils";
import { DynamicAvatars } from "../../../../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { fetchAllData } from "../../../../../../utils/fetchAllData";
import {
  parseInTz,
  setDateMonth,
} from "../../../../../../Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { CloseOutlined } from "@ant-design/icons";
import { PdfDocIcon } from "../../../../../../../../../assets";
import { generateActivityPdf } from "./editActivityModalData";
import {
  getSelectedBaseRecords,
  handlePopupScroll,
  searchFetchInput,
} from "src/utils/searchFetchForInput";
import debounce from "lodash.debounce";
import { TaskIcon } from "src/icons/NotificationIcons";
import ToDoModal from "src/components/SidebarPages/ToDos/ToDoModal";
import { fieldsJSON } from "./activityFields";
import { RenderDynamicComponents } from "../../../../../../../../Header/forms/Components";
import {
  useEditLogs,
  useProgressComponent,
} from "../../../../../../../../../hooks";
import ToDoButton from "../../../../../../../ToDos/components/ToDoButton/ToDoButton";
import { deleteAllRelatedToDos } from "../../../../../../../ToDos/forms/helpers";

const EditActivityModal = ({
  visible,
  setVisible,
  rowObject = {},
  refreshTable,
  setRowData,
  rowData,
  chooseTable = () => {},
  savedFleetActivity,
  deleteRow,
}) => {
  //Object that gets passed if we are editing an activity
  const {
    activityId,
    pickUpLocation,
    dropOffLocation,
    driverName,
    dispatchedBy,
    activityDate,
    startingTime,
    timeAtLocation,
    timeExitingTheLocation,
    dispatchNotes,
    fleetName,
    notes,
    activityStatus,
    paperworkType,
    paperworkCollectStatus,
    paperworkSoftwareStatus,
    dispatchId,
    preTripDone,
    postTripDone,
    teamsConfiguration = [],
  } = rowObject;

  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { yards } = useSelector((state) => state.yards);

  const [disabled, setDisabled] = useState(false);
  const [projects, setProjects] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [users, setUsers] = useState([]);
  const [saving, setSaving] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [currentStep, setCurrentStep] = useState(0);
  const [statusFields, setStatusFields] = useState([]);
  const [logs, setLogs] = useState(false);
  const [yardValue, setYardValue] = useState("");
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const [inspectionModalOpen, setInspectionModalOpen] = useState(false);
  const [inspectionModalData, setInspectionModalData] = useState();
  const [collectOptions, setCollectOptions] = useState([]);
  const [softwareOptions, setSoftwareOptions] = useState([]);
  const [selectedFleet, setSelectedFleet] = useState();
  const [accessRight] = useState(
    userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Fleets Activities")
      ?.children?.find(({ title }) => title === "Overview")
  );
  const [currentPageKey, setCurrentPageKey] = useState("");

  const [inspectionState, setInspectionState] = useState({
    preTripDone:
      !!preTripDone && Object.keys(preTripDone)?.length > 1
        ? preTripDone
        : false,
    postTripDone:
      !!postTripDone && Object.keys(postTripDone)?.length > 1
        ? postTripDone
        : false,
  });
  // const [preTripDefects, setPreTripDefects] = useState(false);
  // const [postTripDefects, setPostTripDefects] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const { programFields } = useSelector((state) => state.programFields);

  const [fleetActivityTeam, setFleetActivityTeam] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(
    teamsConfiguration?.filter(Boolean)
  );
  console.log("selectedTeam:", { selectedTeam, teamsConfiguration });
  const [sortOpen, setSortOpen] = useState(false);
  const [detailsFields, setDetailsFields] = useState([]);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.activityModal || {}
  );

  const [form] = Form.useForm();
  const { Option } = Select;
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Fleet Activity",
    actionType: !!disabled ? "Update" : "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Fleet Management")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Activity"
    )?.videoLink;

  //gets the fleetId by URL
  let { pathname } = useLocation();

  const splitPathname = pathname.split("/").reverse();

  const fleetId = selectedFleet?.fleetId;

  const driverId = compareIncluding(pathname, "driver") && splitPathname[0];

  function getAccessRights(childTitle) {
    return (accessRight?.children || [])?.find(
      ({ title }) => title === childTitle
    );
  }

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data?.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onDeselect = (_, data) => {
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== data.value));
    if (form.getFieldValue("activityTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  //changes the paperwork collect status and the paperwork software status on cargo change
  const onChangeCargoValue = (e) => {
    if (e.target.value === "Empty") {
      form.setFieldsValue({
        paperworkSoftwareStatus: "Not Required",
        paperworkCollectStatus: "Not Required",
        cargoWeight: 0,
      });
    } else {
      form.setFieldsValue({
        paperworkSoftwareStatus: "Pending",
        paperworkCollectStatus: "Waiting Paperwork",
      });
    }
    setCorrectOptionsToPaperwork(
      form.getFieldValue("paperworkType"),
      e.target.value
    );
  };

  const dynamicFields = fieldsJSON({
    onChangeCargoValue,
    driversOptions: drivers?.map(({ driverName }) => driverName),
    disabled,
    isDarkMode,
    fleet,
    setSelectedFleet,
    setSelectedTeam,
    form,
    users,
    cargoWeightValue: isNaN(parseInt(rowObject?.cargoWeight))
      ? 0
      : parseInt(rowObject?.cargoWeight),
    pickUpLocation,
    rowObject,
    setCorrectOptionsToPaperwork,
    setSoftwareOptions,
    collectOptions,
    softwareOptions,
    fleetActivityTeam,
    selectedTeam,
    onSelect,
    onDeselect,
    getAccessRights,
  });

  const activityFields = dynamicFields;
  activityFields?.sort((a, b) => {
    if (Object.keys(sortFieldsObj).length === 0) {
      return 0;
    }
    const aIndex = sortFieldsObj[a.formItemName];
    const bIndex = sortFieldsObj[b.formItemName];
    return aIndex - bIndex;
  });

  //validates input fields and then calls onSuccessFunc
  const validateActivityForm = (onSuccessFunc) => {
    validateForm(form, onSuccessFunc);
  };

  function bodyObj() {
    let statusIndex = statusFields?.find?.((_, index) => index === currentStep);

    return {
      startingTime: setDateMonth(
        parseInTz(form.getFieldValue("startingTime")),
        dayjsNY(form.getFieldValue("date"))
      ).format(),
      timeScheduled: form.getFieldValue("timeScheduled"),
      cargo: form.getFieldValue("cargo"),
      timeAtLocation: setDateMonth(
        parseInTz(form.getFieldValue("timeAtLocation")),
        dayjsNY(form.getFieldValue("date"))
      ).format(),
      paperworkType: form.getFieldValue("paperworkType"),
      paperworkCollectStatus: form.getFieldValue("paperworkCollectStatus"),
      paperworkSoftwareStatus: form.getFieldValue("paperworkSoftwareStatus"),
      cargoWeight: form.getFieldValue("cargoWeight"),
      timeExitingTheLocation: form.getFieldValue("timeExitingTheLocation")
        ? setDateMonth(
            parseInTz(form.getFieldValue("timeExitingTheLocation")),
            dayjsNY(form.getFieldValue("date"))
          ).format()
        : "",
      fleetId,
      layover: form.getFieldValue("layover"),
      activityLogs: [],
      notes: form.getFieldValue("notes") || "",
      isEdited: true,
      activityStatus: statusIndex?.title || "",
      activityTeam: teamsConfiguration?.map((item) => item?.value),
      lastModifiedBy: {
        id: authenticatedUser?.sub,
        name:
          authenticatedUser?.given_name + " " + authenticatedUser?.family_name,
        date: Date.now(),
      },
      teamsConfiguration: selectedTeam,
    };
  }

  //if we are editing an activity, it updates that activity, if not it creates a new one
  const onSave = async () => {
    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam
    );

    // const newActivityLogs = [
    //   {
    //     member: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
    //     updatedAt: Date.now(),
    //     updatedKeys: dataLogs(),
    //   },
    // ];
    setSaving(true);

    const key = "savingMsg";
    message.loading({ content: "Saving...", key });
    let statusIndex = statusFields?.find?.((_, index) => index === currentStep);

    let driverValue = driverName ?? form.getFieldValue("driver");
    let fleetValue = fleetName ?? form.getFieldValue("fleet");

    setVisibleCreationProgress(rowObject);
    updateProgressStatus({ updatingRecord: "executing" });
    if (!!disabled) {
      let newEditLog = {
        recordId: activityId,
        recordName: `${pickUpLocation} - ${dropOffLocation}`,
        category: "Fleet Activity",
        actionType: "Edit",
        label: "",
        topic: fleetName,
        currentData: {},
        member: `${authenticatedUser?.given_name} ${authenticatedUser.family_name}`,
        nameOfUser: `${authenticatedUser?.given_name} ${authenticatedUser.family_name}`,
        cognitoUserId: authenticatedUser?.sub,
        previousData: {},
        updatedAt: Date.now(),
        updatedKeys: [],
      };

      let currentData = bodyObj();

      for (let key in rowObject) {
        let result;
        if (
          (!!rowObject[key] && !!currentData[key]) ||
          key !== "lastModifiedBy"
        ) {
          result = getChangedData(currentData[key], rowObject[key]);
        } else {
          continue;
        }
        if (result !== false) {
          newEditLog.currentData[key] = result.curr;
          newEditLog.previousData[key] = result.prev;
          newEditLog.updatedKeys.push(key);
        }
      }
      saveAddedLogs(newEditLog);

      await API.put(
        apiRoutes.fleetActivity,
        `/${apiRoutes.fleetActivity}/${activityId}`,
        { body: { ...bodyObj() } }
      )
        .then(async (r) => {
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          await broadcastNotification(
            "11",
            "onActivityEdit",
            [
              {
                common: userConfiguration?.nameOfUser,
                commonNext: driverValue,
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "activity",
                currentUser: userConfiguration?.cognitoUserId,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  teamsConfiguration
                ),
              },
            ],
            !!fleetId ? `fleets/${fleetId}` : `drivers/${driverId}`
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });
          setRowData((prev) =>
            prev?.map((item) =>
              item?.activityId === activityId
                ? {
                    ...item,
                    ...bodyObj(),
                  }
                : item
            )
          );
          message.success("Saved!");
          if (splitPathname[1] === "drivers") {
            chooseTable();
          }
        })
        .catch((error) => {
          console.log({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });
    } else {
      await API.post(apiRoutes.fleetActivity, `/${apiRoutes.fleetActivity}`, {
        body: {
          teamsConfiguration,
          pickUpLocation: form.getFieldValue("pickUpLocation"),
          dropOffLocation: form.getFieldValue("dropOffLocation"),
          cargoWeight: form.getFieldValue("cargoWeight"),
          projectId: selectedProjectId,
          paperworkType: form.getFieldValue("paperworkType"),
          paperworkCollectStatus: form.getFieldValue("paperworkCollectStatus"),
          paperworkSoftwareStatus: form.getFieldValue(
            "paperworkSoftwareStatus"
          ),
          driverName: driverValue,
          activityStatus: statusIndex?.title || "Draft",
          driverId:
            driverId ||
            drivers?.find(({ driverName }) => driverName === driverValue)
              ?.driverId,
          driverSub:
            drivers?.find(({ driverName }) => driverName === driverValue)
              ?.driverSub ?? "",
          fleetId,
          activityDate: dayjsNY(form.getFieldValue("date")).format(),
          startingTime: setDateMonth(
            parseInTz(form.getFieldValue("startingTime")),
            dayjsNY(form.getFieldValue("date"))
          ).format(),
          timeScheduled: form.getFieldValue("timeScheduled"),
          timeAtLocation: setDateMonth(
            parseInTz(form.getFieldValue("timeAtLocation")),
            dayjsNY(form.getFieldValue("date"))
          ).format(),
          timeExitingTheLocation: form.getFieldValue("timeExitingTheLocation")
            ? setDateMonth(
                parseInTz(form.getFieldValue("timeExitingTheLocation")),
                dayjsNY(form.getFieldValue("date"))
              ).format()
            : "",
          layover: form.getFieldValue("layover") || "",
          dispatchedBy: form.getFieldValue("dispatchedBy"),
          fleetName: fleetValue,
          notes: form.getFieldValue("notes") || "",
        },
      })
        .then((r) => {
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          }); //set progress of creating record as 100%
          setVisibleCreationProgress(r); // save datas to generate logs in case of any fail

          saveAddedLogs({
            recordId: r?.activityId,
            recordName: `${r?.pickUpLocation} - ${r?.dropOffLocation}`,
            category: "Fleet Activity",
            topic: r?.fleetName,
          });

          broadcastNotification(
            "11",
            "onActivityCreation",
            [
              {
                common: userConfiguration?.nameOfUser,
                commonNext: driverValue,
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "activity",
                currentUser: userConfiguration?.cognitoUserId,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  teamsConfiguration
                ),
              },
            ],
            !!fleetId ? `fleets/${fleetId}` : `drivers/${driverId}`
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          message.success({ content: "Saved!", key, duration: 3 });
          setSaving(false);

          let tmpData = rowData?.concat(r);
          refreshTable([r]);
          setRowData(tmpData);
        })
        .catch((error) => {
          console.log({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });
    }

    onCancel();
  };

  //Hmmm
  const onCancel = () => {
    setVisible(false);
    setCancelModalVisible(false);
    form.resetFields();
  };

  const onCancelDelete = () => {
    deleteActivity();
    setDeleteWarningModal(false);
    setVisible(false);
  };

  let getDispatchDocumentationStatus = programFields?.filter(
    ({ fieldName }) => fieldName === "Dispatch Documentation Status"
  );

  const handleChangeYard = (e) => {
    setYardValue(e);
  };

  function setCorrectOptionsToPaperwork(type, cargo) {
    if (cargo === "Loaded") {
      let tmpOptions = [
        { value: "Cancelled" },
        { value: "Waiting Paperwork" },
        { value: "Received - From Foreman" },
        { value: "Received - From Yard" },
        { value: "Received - Foreman Refused" },
        { value: "Not Required" },
      ];
      if (type === "Outbound") {
        tmpOptions.push({ value: "Same Truckload" });
        setCollectOptions(tmpOptions);
      } else {
        setCollectOptions(tmpOptions);
      }
      setSoftwareOptions([
        { value: "Pending" },
        { value: "Entered" },
        { value: "Not Required" },
      ]);
    } else {
      setCollectOptions([{ value: "Not Required" }, { value: "Cancelled" }]);
      setSoftwareOptions([{ value: "Not Required" }]);
    }
  }

  //fetches data from the API and populates the input fields if we are editing an activity
  useEffect(() => {
    if (!!visible) {
      if (!!pickUpLocation) {
        form.setFieldsValue({
          pickUpLocation,
          dropOffLocation,
          driver: driverName,
          fleet: fleetName,
          dispatchNotes,
          paperworkType,
          cargo:
            paperworkSoftwareStatus === "Not Required" ? "Empty" : "Loaded",
          dispatchedBy:
            splitPathname[1] === "drivers" ? dispatchedBy : dispatchedBy.name,
          date: dayjsNY(activityDate),
          startingTime: dayjsNY(startingTime),
          timeAtLocation: dayjsNY(timeAtLocation),
          timeExitingTheLocation: timeExitingTheLocation
            ? dayjsNY(timeExitingTheLocation)
            : "",
          paperworkSoftwareStatus,
          paperworkCollectStatus,
          notes,
          timeScheduled: dayjs
            .utc(
              dayjs
                .duration(dayjs(timeAtLocation).diff(dayjs(startingTime)))
                .asMilliseconds()
            )
            .format("HH:mm"),
          layover: timeExitingTheLocation
            ? dayjs
                .utc(
                  dayjs
                    .duration(
                      dayjs(timeExitingTheLocation).diff(dayjs(timeAtLocation))
                    )
                    .asMilliseconds()
                )
                .format("HH:mm")
            : "",
          teamsConfiguration: teamsConfiguration?.map((item) => item?.value),
        });
        setDisabled(true);
      } else {
        form.setFieldsValue({
          driver: driverName,
          fleet: fleetName,
        });
      }

      if (!!rowObject?.paperworkType) {
        setCorrectOptionsToPaperwork(
          rowObject?.paperworkType,
          rowObject?.cargo
        );
      }

      Promise.all([
        // fetchData("projects", "projects", "projectId"),
        getSelectedBaseRecords({
          idKey: "projectId",
          table: "projects",
          setRecords: setProjects,
          userConfiguration,
          setCurrentPageKey,
        }),
        fetchData("drivers"),
        fetchData("fleet"),
      ])
        .then(
          ([
            // projectsData,
            _,
            driversData,
            fleetData,
          ]) => {
            // setProjects(projectsData.projects || []);
            setDrivers(driversData);
            setFleet(fleetData);

            let defaultSelectedFleet = undefined;

            if (rowObject?.fleetId) {
              defaultSelectedFleet = fleetData.find(
                ({ fleetId: id }) => id === rowObject?.fleetId
              );
              setSelectedFleet(defaultSelectedFleet);
            } else if (rowObject?.fleetName) {
              defaultSelectedFleet = fleetData.find(
                ({ fleetName: name }) => name === rowObject?.fleetName
              );
              setSelectedFleet(defaultSelectedFleet);
            }

            if ((fleetId || defaultSelectedFleet) && !activityId) {
              defaultSelectedFleet =
                defaultSelectedFleet ||
                fleetData?.find((truck) => truck?.fleetId === fleetId);

              if (!!defaultSelectedFleet) {
                setSelectedTeam(defaultSelectedFleet?.teamsConfiguration || []);
                form.setFieldValue(
                  "activityTeam",
                  (defaultSelectedFleet?.teamsConfiguration || [])?.map(
                    (item) => item?.value
                  )
                );
              }
            }
          }
        )
        .catch((error) => {
          console.log("Error fetching data: ", error);
        });

      if (!!activityId) {
        fetchAllData({
          endpoint: "editLogs",
          resultPosition: "editLogs",
          resultId: "logId",
          otherStringParams: {
            getMaxLimit: "true",
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "recordId",
                    value: activityId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        })
          .then((r) => {
            setActivityLogs(r);
          })
          .catch((e) => console.log("Error Fetching Logs: ", e));
      }
    }
  }, [visible, JSON.stringify(rowObject)]);

  const yardOptions = yards?.map(({ address }, index) => ({
    id: index,
    value: address,
  }));

  const deleteActivity = () => {
    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam
    );

    if (!!disabled) {
      let deletionLog = {
        recordId: activityId,
        recordName: `${pickUpLocation} - ${dropOffLocation}`,
        category: "Fleet Activity",
        actionType: "Delete",
        label: "",
        topic: fleetName,
        currentData: {},
        member: `${authenticatedUser?.given_name} ${authenticatedUser.family_name}`,
        nameOfUser: `${authenticatedUser?.given_name} ${authenticatedUser.family_name}`,
        cognitoUserId: authenticatedUser?.sub,
        previousData: {},
        updatedAt: Date.now(),
        updatedKeys: [],
      };
      API.del(
        apiRoutes.fleetActivity,
        `/${apiRoutes.fleetActivity}/${activityId}`
      ).then((r) => {
        saveAddedLogs(deletionLog);

        deleteAllRelatedToDos({ recordId: activityId });

        for (const key in inspectionState) {
          if (inspectionState[key] !== false) {
            API.del(
              "fleetInspections",
              `/fleetInspections/${inspectionState?.[key]?.["inspectionId"]}`
            )
              .then(() => {
                saveAddedLogs({
                  ...deletionLog,
                  category: "Fleet Inspections",
                  recordId: inspectionState?.[key]?.["inspectionId"],
                  recordName: rowObject?.fleetName,
                  topic: "Automatic delete from activity",
                });
              })
              .catch((err) => {
                console.log("Could not delete inspection: ", err);
              });
          }
        }

        let tmpData = rowData?.filter(
          (el) => el?.activityId !== rowObject?.activityId
        );

        setRowData(tmpData);

        broadcastNotification(
          "11",
          "onActivityDelete",
          [
            {
              common: userConfiguration?.nameOfUser,
              commonNext: form.getFieldValue("driver"),
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
              cognitos: getCognitosForNotification(
                userConfiguration,
                teamsConfiguration
              ),
            },
          ],
          null
        );
        showSuccessMsg({ content: "Deleted successfully!" });
        // deleteRow(rowObject);
        form.resetFields();
      });
    }
  };

  const yardOption = useMemo(() => {
    let data = yardOptions
      ?.filter((el) => el?.value !== yardValue)
      ?.map((dt) => {
        return {
          key: dt?.id,
          value: dt?.value,
        };
      });
    return data;
  }, [yardValue]);

  const yardOptionPickUP = useMemo(() => {
    let data = yardOptions.map((dt) => {
      return {
        key: dt?.id,
        value: dt?.value,
      };
    });
    return data;
  }, [yardValue]);

  useEffect(() => {
    setUsers(userConfiguration?.allUsers?.Items);
    !form.getFieldValue("dispatchedBy") &&
      form.setFieldsValue({ dispatchedBy: userConfiguration?.nameOfUser });
  }, [userConfiguration]);

  useEffect(() => {
    if (!!programFields) {
      setStatusFields(
        programFields
          ?.find(({ fieldName }) =>
            compareIncluding(fieldName, "Statuses Of Categories")
          )
          ?.fieldOptions?.Activity?.map(({ statusName, statusColor }) => ({
            title: statusName,
            color: statusColor,
          }))
      );
    }
    fetchData("fleetDefects").then((res) => {
      const preTripDefectElements = !!res.filter(
        (defect) =>
          defect.inspectionId === inspectionState?.preTripDone?.inspectionId
      ).length;
      const postTripDefectElements = !!res.filter(
        (defect) =>
          defect.inspectionId === inspectionState?.postTripDone?.inspectionId
      ).length;
      // setPreTripDefects(preTripDefectElements);
      // setPostTripDefects(postTripDefectElements);
    });
  }, [programFields]);

  useEffect(() => {
    API.get("teams", "/teams")
      .then((teams) => {
        setFleetActivityTeam(
          teams?.map((team) => ({
            label: team.teamName,
            value: team.teamName,
            members: team.members?.map(
              ({ identityId, nameOfUser, cognitoUserId = "" }) => {
                return {
                  identityId,
                  nameOfUser,
                  cognitoUserId,
                };
              }
            ),
          }))
        );

        if (!teamsConfiguration?.length && selectedTeam?.length === 0) {
          setSelectedTeam([
            teams?.find(({ teamName }) => teamName === "Fleet Team"),
          ]);
        }
      })
      .catch((err) => {
        console.log("Error getting teams: ", err);
      });
  }, []);

  const ClearOptions = () => {
    setOpen(false);
  };

  /**
   * Handles the opening of the inspection modals
   * @param {String} openKey The inspection type key in the row object
   */
  async function openInspection(openKey) {
    if (!!inspectionState[openKey]["inspectionId"]) {
      filterTables(
        "fleetInspections",
        "inspectionId",
        inspectionState[openKey]?.inspectionId
      )
        .then((res) => {
          setInspectionModalData({
            ...res[0],
            pickUpLocation: rowObject?.pickUpLocation,
            dropOffLocation: rowObject?.dropOffLocation,
          });
          setInspectionModalOpen(true);
        })
        .catch((err) => {
          console.log("Error Opening Modal: ", err);
        });
    } else {
      setInspectionModalOpen(true);
      setInspectionModalData({
        activityId: rowObject?.activityId,
        fleetId: rowObject?.fleetId,
        fleetName: rowObject?.fleetName,
        pickUpLocation: rowObject?.pickUpLocation,
        dropOffLocation: rowObject?.dropOffLocation,
        departAt: rowObject?.timeExitingTheLocation,
        dispatchId,
        driverInfo: {
          driver: rowObject?.driverName,
          driverId: rowObject?.driverId,
          signature: "",
        },
        inspectionType: openKey?.includes("pre") ? "Pre-trip" : "Post trip",
      });
    }
  }

  /**
   * Function that updates the modal data and the activities row data
   * @param {Object} inspection The inspection object
   * @param {String} type "Edit" | "Delete"
   */
  function updateInspectionState(inspection, type) {
    let key =
      inspection?.inspectionType === "Pre-trip"
        ? "preTripDone"
        : "postTripDone";
    let tmp = structuredClone(inspectionState);
    if (type === "Edit") {
      tmp[key] = {
        inspectionId: inspection?.inspectionId,
        status: inspection?.inspectionStatus,
        value: true,
      };
    } else if (type === "Delete") {
      tmp[key] = false;
    }
    setRowData((prev) =>
      prev?.map((el) =>
        el?.activityId === inspection?.activityId ? { ...el, ...tmp } : el
      )
    );
    setInspectionState(tmp);
  }

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "fleetActivityModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Activity Status": document.querySelector(".antCustomStepper"),
      "Pickup Location and Drop-off Location":
        document.querySelector(".locationsPicker"),
      "Other Details": document.querySelector(".detailsCard"),
      Notes: document.querySelector(".notesCard"),
      Save: document.querySelector(".saveButton"),
    };
    return stepsMap[title] || [];
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  const openMemberModal = () => {
    setOpen(true);
  };

  function generatePdf() {
    generateActivityPdf({ body: rowObject, base64 });
  }

  return (
    <>
      <NormalSizedModal
        visible={visible}
        title={
          <CustomModalHeader
            {...{
              title: (
                <div className="modalTitle">
                  <span className="title">Fleet Activity</span>
                  <span style={{ color: "#ff4d4f", fontWeight: "550" }}>
                    {!!rowObject?.routeName
                      ? `(${rowObject?.routeName}${
                          rowObject?.hoistRoute ? " for hoist" : ""
                        })`
                      : ""}
                  </span>
                </div>
              ),
              headerInfo: () => {
                return (
                  userConfiguration.groupName === "Admin" && (
                    <Tooltip title="Sort Fields">
                      <TransferIconNew
                        {...{
                          className: "sortFieldsIcon",
                          onClick: () => {
                            setSortOpen(true);
                          },
                        }}
                      />
                    </Tooltip>
                  )
                );
              },
              onClick: () => {
                setTourOpen(true);
              },
            }}
          />
        }
        className={`editActivityModalContainer ${
          isDarkMode && "editActivityModalContainerDark"
        }`}
        destroyOnClose
        onCancel={() => {
          if (formValuesChanged) {
            setCancelModalVisible(true);
          } else {
            setVisible(false);
          }
        }}
        footerModal={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {!!disabled && (
                <MondayButton
                  className="mondayButtonRed"
                  middleLineStyle={{ background: "white" }}
                  Icon={<DeleteIcon />}
                  onClick={() => setDeleteWarningModal(true)}
                  tooltipCategory="Fleet Activity"
                  tooltipKey="delete"
                >
                  Delete
                </MondayButton>
              )}
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <ToDoButton
                {...{
                  recordId: rowObject?.activityId,
                  recordName: rowObject?.fleetName,
                  tooltipCategory: "Fleet Activity",
                }}
              />
              {!!disabled && getAccessRights("Logs") && (
                <MondayButton
                  className="mondayButtonBlue"
                  middleLineStyle={{ background: "white" }}
                  Icon={<LogsIcon />}
                  onClick={() => {
                    setLogs(true);
                  }}
                  tooltipCategory="Fleet Activity"
                  tooltipKey="showLogs"
                >
                  Show Logs
                </MondayButton>
              )}
              <MondayButton
                className="mondayButtonPdf"
                Icon={<PdfDocIcon />}
                onClick={generatePdf}
                tooltipCategory="Fleet Activity"
                tooltipKey="exportPdf"
              >
                Export To Pdf
              </MondayButton>
              <MondayButton
                className="mondayButtonRed"
                middleLineStyle={{ background: "white" }}
                Icon={<XIcon />}
                onClick={() => {
                  if (formValuesChanged) {
                    setCancelModalVisible(true);
                  } else {
                    setVisible(false);
                  }
                }}
                tooltipCategory="Fleet Activity"
                tooltipKey="cancel"
              >
                Cancel
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue saveButton"
                middleLineStyle={{ background: "white" }}
                Icon={<TickIcon width={19} height={19} />}
                onClick={() => validateActivityForm(onSave)}
                tooltipCategory="Fleet Activity"
                tooltipKey="save"
              >
                Save
              </MondayButton>
            </div>
          </div>
        }
        centered
        closable={true}
      >
        <div className="formContainer">
          <Form
            form={form}
            onFieldsChange={() => {
              setFormValuesChanged(true);
            }}
          >
            <Stepper
              stepRenderer={false}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              steps={statusFields}
              setFormChanged={setFormValuesChanged}
            />
            <BorderedTextCard
              className="detailsCard"
              style={{ maxWidth: "900px" }}
              title={"Details"}
              isDarkMode={isDarkMode}
            >
              <div className="inputContainer locationsPicker">
                <SimplePlacesInput
                  form={form}
                  placeholder="Click and Search..."
                  popupClassName={isDarkMode && "darkDropDown"}
                  label="Pickup Location"
                  onChange={(e) => handleChangeYard(e)}
                  customOnSearch={debounce((e) => {
                    return searchFetchInput({
                      table: "projects",
                      idKey: "projectId",
                      setRecords: setProjects,
                      userConfiguration,
                      searchValue: e,
                      searchKey: "projectName",
                    });
                  }, 500)}
                  formItemName="pickUpLocation"
                  disabled={disabled}
                  groupedOptions={[
                    {
                      label: "Main Yard",
                      options: yardOptionPickUP?.map?.((dt) => {
                        return {
                          key: dt.id,
                          value: dt?.value,
                        };
                      }),
                    },
                    {
                      label: "Projects",
                      options: projects?.map((el, i) => ({
                        key: i,
                        value: el?.projectName,
                      })),
                    },
                  ]}
                  onPopupScroll={(e) => {
                    handlePopupScroll({
                      e,
                      table: "projects",
                      idKey: "projectId",
                      setRecords: setProjects,
                      userConfiguration,
                      setCurrentPageKey,
                      currentPageKey,
                      userConfiguration,
                    });
                  }}
                  keepOptions={true}
                  required
                  style={{ minWidth: 315 }}
                />
                <img src={fromToArrow} alt="..." className="iconContainer" />
                <SimplePlacesInput
                  form={form}
                  label="Drop off Location"
                  placeholder="Click and Search..."
                  formItemName="dropOffLocation"
                  popupClassName={isDarkMode && "darkDropDown"}
                  disabled={disabled}
                  onSelect={(val, option) => setSelectedProjectId(option.key)}
                  allowSearch={true}
                  customOnSearch={debounce((e) => {
                    return searchFetchInput({
                      table: "projects",
                      idKey: "projectId",
                      setRecords: setProjects,
                      userConfiguration,
                      searchValue: e,
                      searchKey: "projectName",
                    });
                  }, 500)}
                  onPopupScroll={(e) => {
                    handlePopupScroll({
                      e,
                      table: "projects",
                      idKey: "projectId",
                      setRecords: setProjects,
                      userConfiguration,
                      setCurrentPageKey,
                      currentPageKey,
                      userConfiguration,
                    });
                  }}
                  groupedOptions={[
                    {
                      label: "Yards",
                      options: yardOption?.map((dt) => {
                        return {
                          key: dt?.id,
                          value: dt?.value,
                        };
                      }),
                    },
                    {
                      label: "Projects",
                      options: projects
                        ?.filter((el) => el?.projectName !== yardValue)
                        ?.map(({ projectName, projectId }) => ({
                          key: projectId,
                          value: projectName,
                        })),
                    },
                  ]}
                  required
                  style={{ minWidth: 315 }}
                />
              </div>
              {RenderDynamicComponents(activityFields, { form })}
              {!!selectedTeam?.length && getAccessRights("Teams")?.write ? (
                <MondayButton
                  key={14}
                  onClick={openMemberModal}
                  style={{ marginRight: "1rem" }}
                  Icon={<AddIcon />}
                  className="membersButton"
                >
                  Members
                </MondayButton>
              ) : (
                <></>
              )}
              {getAccessRights("Teams") && (
                <DynamicAvatars
                  title={"Edit Activity Team"}
                  isDarkMode={isDarkMode}
                  members={selectedTeam.reduce(
                    (acc, { members = [] }) => [...acc, ...members],
                    []
                  )}
                />
              )}
            </BorderedTextCard>
            <BorderedTextCard
              className="dispatchNotes"
              title={"Dispatch Notes"}
              isDarkMode={isDarkMode}
            >
              <InputComponent
                key={13}
                formItemName="dispatchNotes"
                placeholder="Dispatch notes..."
                type="textarea"
                style={{ minWidth: "80%", minHeight: "60px" }}
                rows={3}
                disabled
              />
            </BorderedTextCard>
            <BorderedTextCard
              className="notesCard"
              title={"Paperwork Notes"}
              isDarkMode={isDarkMode}
            >
              <InputComponent
                key={14}
                formItemName="notes"
                placeholder="Write down your notes here ..."
                type="textarea"
                style={{ minWidth: "80%", minHeight: "60px" }}
                rows={3}
              />
            </BorderedTextCard>
            {/* </div> */}
            {logs && (
              <MultiLevelTreeLogs
                {...{
                  visible: logs,
                  setVisible: setLogs,
                  logsData: activityLogs || [],
                  title: "Activity Logs",
                }}
              />
            )}
          </Form>
        </div>
      </NormalSizedModal>
      {inspectionModalOpen && (
        <VehicleInspectionModal
          {...{
            visible: inspectionModalOpen,
            setVisible() {
              setInspectionModalOpen(false);
              setInspectionModalData();
            },
            rowObject: inspectionModalData,
            refreshTable(res) {
              let inspection = {};
              if (Array.isArray(res)) {
                inspection = { ...res[0] };
              } else if (res?.hasOwnProperty("update")) {
                inspection = { ...res["update"][0] };
              }

              updateInspectionState(inspection, "Edit");
            },
            deleteRow(inspection) {
              updateInspectionState(inspection, "Delete");
            },
            globalDisabled:
              !!inspectionModalData?.inspectionId &&
              userConfiguration.groupName === "Driver",
          }}
        />
      )}
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setCancelModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancel}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      <WarningModal
        visible={deleteWarningModal}
        setVisible={setDeleteWarningModal}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to delete?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setDeleteWarningModal(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancelDelete}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            setSelectedTeam,
            ClearOptions,
            isDarkMode,
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Activity Tutorial",
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Fleet Activity",
            rowData: activityFields,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "activityModal",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Fleet Activity",
            actionType: !!disabled ? "Update" : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
            },
          }}
        />
      )}
    </>
  );
};

export default EditActivityModal;

export const formatLogs = (logs) => {
  let listOfLogs = [];
  logs.forEach((log) => {
    log?.updatedKeys?.forEach((val) => {
      listOfLogs.push({
        member: log?.member,
        nameOfUser: log?.nameOfUser,
        updatedAt: val?.updatedAt || log?.updatedAt,
        updatedKeys: {
          ...val,
        },
      });
    });
  });
  return logs;
};
