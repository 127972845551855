import { filterTables } from "../../../../../utils";
import { apiRoutes } from "../../../../SidebarPages/Fleet/utils";

async function getForemanDocuments({ projectId }) {
  const returnObj = {};
  await filterTables(apiRoutes.permitDrawings, "projectId", projectId).then(
    (permitDrawings) => {
      permitDrawings.forEach(async (dr) => {
        returnObj[dr.sow] = {};
        await filterTables(
          apiRoutes.documentation,
          "recordId",
          dr.permitId
        ).then((docs) => {
          docs.forEach((doc) => {
            returnObj[dr.sow][doc.docType] = [];
            if (!!doc?.enableOnField) {
              const docArray = doc?.googleDriveUploads || [];
              docArray.forEach((d) => {
                returnObj[dr.sow][doc.docType].push(d);
              });
            }
          });
        });
      });
    }
  );
  await filterTables(apiRoutes.estimations, "projectId", projectId).then(
    (estimations) => {
      console.log("getForemanDocuments ~ estimations:", { estimations });
      estimations.forEach(async (estimation) => {
        returnObj["estimations"] = {};
        await filterTables(
          apiRoutes.documentation,
          "recordId",
          estimation.estimationId
        ).then((docs) => {
          console.log(").then ~ docs:", { docs });
          docs.forEach((doc) => {
            returnObj["estimations"][doc.docType] = [];
            if (!!doc?.enableOnField) {
              const docArray = doc?.googleDriveUploads || [];
              docArray.forEach((d) => {
                returnObj["estimations"][doc.docType].push(d);
              });
            }
          });
        });
      });
    }
  );
  return returnObj;
}

export default getForemanDocuments;
