import React from "react";
import { Modal } from "antd";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import "../../../../PLIPerService/scheduleGrid.scss";
import * as moment from "moment";
import { updateDocumentTitle } from "../../../../../../../../utils";

const CrewActivityModal = ({ visible, setVisible, activities, schedules }) => {
  return (
    <Modal
      title="Crews Activity"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Crews Activity" });
      }}
      footer={false}
      open={visible}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        setVisible(false);
      }}
      className="crew-fleet-popover"
      closeIcon={<XIcon />}
      centered
    >
      <div className="trucks-modal-body">
        <div className="trucks-modal-body-content">
          <table className="trucks-modal-body-content-table">
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Date</th>
                <th style={{ paddingRight: 20 }}>Start Time</th>
                <th>End Time</th>
                <th>Job Site Address</th>
              </tr>
            </thead>
            <tbody>
              {activities?.map((act) => {
                const { startDate, endDate, scheduleId } = act;
                const scheduleAddress = schedules?.find(
                  (sch) => sch?.scheduleId === scheduleId
                )?.scheduleAddress;
                return (
                  <tr>
                    <td style={{ paddingLeft: 20 }}>
                      {moment(startDate)?.format("MM/DD/YYYY")}
                    </td>
                    <td style={{ paddingRight: 20 }}>
                      {moment(startDate)?.format("HH:mm")}
                    </td>
                    <td>{moment(endDate)?.format("HH:mm")}</td>
                    <td>{scheduleAddress}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default CrewActivityModal;
