export const totalitiesExtractor = (currentService, currentRental) => {
  const temp = {
    ...currentRental,
    services:
      currentRental?.services?.filter(
        (el) => el.label === currentService.label
      ) || [],
  };
  const result = totalitiesTotalTaxGen({ fakeRentals: temp });
  return result;
  console.log("result", result);
};

export const totalitiesForService = (rentals = []) => {
  if (Array.isArray(rentals)) {
    const res = rentals?.map((rental) => {
      const temp = {};

      const services = rental?.services || [];
      if (Array.isArray(services)) {
        services?.forEach((s) => {
          let totalities = totalitiesExtractor(s, rental);
          console.log("totalities", totalities);
          console.log("this service", s);
          console.log("temp", temp);
          if (Object.hasOwnProperty(s?.label)) {
            temp[s?.label] = {
              appliedRent:
                temp[`${s?.label}`]?.appliedRent ||
                0 + totalities?.appliedRent ||
                0,
              totalPrice:
                temp[`${s?.label}`]?.totalPrice ||
                0 + totalities?.totalPrice ||
                0,
              totalTax:
                temp[`${s?.label}`]?.totalTax || 0 + totalities?.totalTax || 0,
              retainage:
                temp[`${s?.label}`]?.retainage ||
                0 + ((s?.retainage || 0) / 100) * (totalities?.totalPrice || 0),
            };
          } else {
            temp[`${s?.label}`] = {
              appliedRent: totalities?.appliedRent || 0,
              totalPrice: totalities?.totalPrice || 0,
              totalTax: totalities?.totalTax || 0,
              retainage: !isNaN(s?.retainage)
                ? ((s?.retainage || 0) / 100) * (totalities?.totalPrice || 0)
                : 0,
            };
          }
        });
      }
      return {
        ...rental,
        amounts: temp,
      };
    });
    return res;
  }
};

//in this function we calculate the full amount applied on this rental
const appliedAmountGenerator = ({ fakeRentals }) => {
  return (
    fakeRentals?.services
      // here we extract all services
      ?.flatMap(({ serviceOptions }) =>
        serviceOptions?.flatMap(({ items }) =>
          // from the extracted services we extract all weeks and take the amount from those weeks
          items?.flatMap(({ rentalDetails }) =>
            rentalDetails?.details?.flatMap(({ days }) =>
              days?.map(({ dailyAmount = 0 }) => dailyAmount)
            )
          )
        )
      )
      //here we add all extracted weekAmounts together
      ?.reduce((acc, curr) => acc + curr, 0)
  );
};

export const totalitiesTotalTaxGen = ({ fakeRentals }) => {
  // console.log("to result", { fakeRentals, elevationData, selectedService });

  let carriedProjectTax = fakeRentals?.taxRate || 0;
  let result = {
    totalTax: 0,
    totalPrice: appliedAmountGenerator({ fakeRentals }) || 0,
    appliedRent: appliedAmountGenerator({ fakeRentals }) || 0,
  };
  console.log("result", result);
  if (carriedProjectTax > 0) {
    result.totalTax = result.totalPrice * carriedProjectTax;
    result.totalPrice = result.totalPrice + result.totalTax;
  }
  return result;
};
