import Decimal from "decimal.js-light";

import {
  formatCurrency,
  formatNumberToPercentage,
} from "../../../../../../../../utils";
import { useStateContext } from "../../../../Context/Context";
import "./ServiceFooter.scss";
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { DoneAllIcon } from "../../../../../../../../../../icons";

Decimal.config({ precision: 32 });

const ServiceFooter = ({ service, events }) => {
  const { totalRate, totalAmount } = service?.amounts?.reduce(
    (acc, cur) => ({
      totalRate: Number(
        new Decimal(acc.totalRate).plus(new Decimal(cur?.rate || 0))
      ),
      totalAmount: Number(
        new Decimal(acc.totalAmount).plus(new Decimal(cur.amount))
      ),
    }),
    { totalRate: new Decimal(0), totalAmount: new Decimal(0) }
  );

  const canAddEvents = service?.amounts?.length < events?.length;
  const eventsToAdd = canAddEvents && events?.length - service?.amounts?.length;
  const { darkMode } = useStateContext();

  return (
    <div
      className={
        darkMode
          ? "sov-service-content-info-details-dark-mode"
          : "sov-service-content-info-details-light-mode"
      }
    >
      <div className="sovServicesContentInfoDetailsEvent">
        {canAddEvents
          ? `You can add ${eventsToAdd} more event${
              eventsToAdd === 1 ? "" : "s"
            }`
          : `You can't add anymore events`}
      </div>
      <div className="sovServicesContentInfoDetailsEvent">
        Total rate is:
        <div className="amountContainer">
          {" "}
          {formatNumberToPercentage(totalRate)}
        </div>
        {Number(totalRate || 0).toFixed(2) === 100 && (
          <DoneAllIcon style={{ color: "#68C142", marginLeft: "5px" }} />
        )}
        {Number(totalRate || 0).toFixed(2) > 100 && (
          <span
            className="sov-service-content-info-details-rate-error"
            style={{ color: "#FE4C4A" }}
          >
            +{" "}
            {formatNumberToPercentage(Number(totalRate || 0).toFixed(2) - 100)}
            <RiseOutlined style={{ color: "#FE4C4A" }} />
          </span>
        )}
        {Number(totalRate || 0).toFixed(2) < 100 && (
          <span
            className="sov-service-content-info-details-rate-error"
            style={{ color: "#ECDE7C" }}
          >
            -{" "}
            {formatNumberToPercentage(100 - Number(totalRate || 0).toFixed(2))}
            <FallOutlined style={{ color: "#ECDE7C" }} />
          </span>
        )}
      </div>

      <div className="sovServicesContentInfoDetailsEvent">
        Total amount is:{" "}
        <div className="amountContainer">
          {formatCurrency(totalAmount, "USD")}
        </div>
        {totalAmount === service.totalPrice && (
          <DoneAllIcon style={{ color: "#68C142", marginLeft: "5px" }} />
        )}
        {totalAmount > service.totalPrice && (
          <div
            className="sov-service-content-info-details-amount-error"
            style={{ color: "#FE4C4A" }}
          >
            + {formatCurrency(totalAmount - service.totalPrice, "USD")}
            <RiseOutlined style={{ color: "#FE4C4A" }} />
          </div>
        )}
        {totalAmount < service.totalPrice && (
          <div
            className="sov-service-content-info-details-amount-error"
            style={{ color: "#ECDE7C" }}
          >
            - {formatCurrency(service.totalPrice - totalAmount, "USD")}
            <FallOutlined style={{ color: "#ECDE7C" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceFooter;
