import { getMonthsTimestamps, getRandomColor } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const maintenance = (IdHandler, updatedDynamicStates) => {
  return {
    createdAt: () => ({
      title: "Maintenance Creation Date",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Maintenance",
        field: "createdAt",
        labels: months,

        datasets: [
          {
            label: "My First Dataset",
            data: (() => {
              let data = []
              for (let i = 0; i < 12; i++) {
                data = [
                  ...data,
                  updatedDynamicStates[IdHandler]?.map((a) => a?.createdAt)?.filter(
                    (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
                  )?.length,
                ]
              }
              return data
            })(),
            backgroundColor: months?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //createdAt
  } //Maintenance
}
