import { useRequestModalContext } from "../../../../store";
import { MondayButton } from "../../../../../../../commonComponents";
import { PlusIcon } from "../../../../../../../../assets";
import ScheduleDay from "../../../../../Scheduling/models/ScheduleDay";
import ScheduleDaysList from "./ScheduleDaysList";

/**
 * Renders the RequestScheduleDays component.
 *
 * @param {Function} setChanges - The function to set changes.
 * @returns {JSX.Element} The rendered RequestScheduleDays component.
 */
const RequestScheduleDays = ({ setChanges = () => {} }) => {
  const {
    requestData: { selectedMainField: selectedSchedule = null },
    dispatchRequestData = () => {},
  } = useRequestModalContext();

  function addScheduleDay() {
    const newScheduleDays = [...(selectedSchedule?.scheduleDays || [])];

    newScheduleDays.push(
      ScheduleDay.NextScheduleDay(
        newScheduleDays?.[newScheduleDays.length - 1],

        []
      )
    );

    setChanges(true);

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        ...selectedSchedule,
        scheduleDays: newScheduleDays,
      },
    });
  }

  return (
    <div className="scheduleDaysWrapper">
      <div className="scheduleDaysHeader">
        <div className="scheduleDaysHeaderTitle">
          <span className="line"></span>
          <span className="text">Schedule Days</span>
          <span className="line"></span>
        </div>

        <MondayButton
          {...{
            Icon: <PlusIcon />,
            onClick: addScheduleDay,
          }}
        >
          Add Day
        </MondayButton>
      </div>
      <div className="schedule-days-list">
        <ScheduleDaysList setChanges={setChanges} />
      </div>
    </div>
  );
};

export default RequestScheduleDays;
