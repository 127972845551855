import { MondayButton } from "../../../../../../../commonComponents";
import { AddIcon } from "../../../../../../../Header/components/GoogleEvents/assets";

export function notFoundColumns({ createHandler }) {
  return [
    {
      title: "Name",
      dataIndex: "employeeFullName",
    },
    {
      title: "Employee Number",
      dataIndex: "employeeNumber",
    },
    {
      title: "Employee Id",
      dataIndex: "employeeId",
    },
    {
      title: "Company",
      dataIndex: "accountName",
    },
    {
      title: "Create",
      render(_, record) {
        return (
          <MondayButton
            data-testid="create-not-found-employee"
            Icon={<AddIcon />}
            onClick={() => {
              createHandler(record);
            }}
          >
            Create Employee
          </MondayButton>
        );
      },
    },
  ];
}
