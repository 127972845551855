const saveGlobal = (varNames, varValues = false) => {
  if (varValues) {
    if (typeof varNames === 'string') {
      window[varNames] = () => varValues
    } else {
      varNames.map((e, i) => (window[varNames[i]] = () => varValues[i]))
    }
  } else {
    if (typeof varNames === 'string') {
      return window[varNames] && window[varNames]()
    } else {
      let elementsToReturn = {}
      varNames.map((e) => (elementsToReturn[e] = window[e] && window[e]()))
      return elementsToReturn
    }
  }
}
export default saveGlobal
