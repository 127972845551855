import React, { createContext, useContext, useState } from "react";

import { defaultServiceRow } from "../utils/constants";
import { useInitialInvoiceContext } from "./InvoiceInitialContext";

const InvoiceFormDataContext = createContext();

export const useInvoiceFormDataContext = () => {
  return useContext(InvoiceFormDataContext);
};

export const InvoiceFormDataProvider = ({ children }) => {
  const { invoiceData } = useInitialInvoiceContext();

  const [formData, setFormData] = useState(
    invoiceData ? {} : defaultServiceRow
  );
  return (
    <InvoiceFormDataContext.Provider
      value={{
        formData,
        setFormData,
      }}
    >
      {children}
    </InvoiceFormDataContext.Provider>
  );
};
