import htmlParser from "../../../../../utils/htmlParser";
import { parseText } from "../../../Accounting/components/utilities";

/**
 * Parses the safety note and adds it to the notesArray.
 * Recursively parses replies if they exist.
 *
 * @param {Object} note - The safety note object.
 * @param {Array} notesArray - The array to store the parsed notes.
 * @param {number} index - The current index for indentation.
 */
const safetyExcelNoteParser = (note, notesArray, index) => {
  notesArray.push(
    `${"-".repeat(index) + note?.author}: ${parseText(
      htmlParser(note?.noteComment || note?.replyContent)
    )}`
  );
  if (note?.replies?.length > 0) {
    const { replies = [] } = note;
    replies?.map((reply) =>
      safetyExcelNoteParser(reply, notesArray, index + 1)
    );
  } else return;
};

export default safetyExcelNoteParser;
