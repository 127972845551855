export const eventCheckerHandler = ({ selectedEvent }) => {
  const { serviceAddons, serviceOptions } = selectedEvent || {}

  //here we check if some of addons are charged and if all of addons are charged
  const addonsChecker = {
    some: !!serviceAddons?.length ? serviceAddons?.some(({ charged }) => !!charged) : false,
    every: !!serviceAddons?.length ? serviceAddons?.every(({ charged }) => !!charged) : true,
  }

  //here we check if some of plis are charged and if all of plis are charged
  const plis = serviceOptions?.flatMap(({ items }) => items)

  const pliChecker = {
    some: !!plis?.length ? plis?.some(({ charged }) => !!charged) : false,
    every: !!plis?.length ? plis?.every(({ charged }) => !!charged) : true,
  }

  return {
    fullyCharged: addonsChecker?.every && pliChecker?.every,
    partiallyCharged: addonsChecker?.some || pliChecker?.some,
  }
}

export const pliCheckerHandler = ({ selectedEvent, id, pliId }) => {
  const { charged: fullyCharged } =
    selectedEvent?.serviceOptions
      ?.find(({ elevationId }) => elevationId === id)
      ?.items?.find(({ id }) => id === pliId) || {}

  return {
    fullyCharged,
    partiallyCharged: false,
  }
}

export const addonsCheckerHandler = ({ selectedEvent, id }) => {
  const { charged: fullyCharged } =
    selectedEvent?.serviceAddons?.find((addonsData) => addonsData?.id === id) || {}

  return {
    fullyCharged,
    partiallyCharged: false,
  }
}

export const hoistCheckerHandler = ({ selectedEvent }) => {
  const { charged: fullyCharged } = selectedEvent || {}

  return {
    fullyCharged,
    partiallyCharged: false,
  }
}
