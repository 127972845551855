import { Tooltip, Upload } from "antd";
import React, { useEffect } from "react";
import { useEmailBox } from "../../../../providers/EmailBoxProvider";
import { getLength } from "../../../../../../functions";
import { AttachIcon } from "../../../../../../assets";
import { useEmailTemplates } from "../../../../providers/EmailTemplatesProvider";
import { fetchBlobData } from "../../../../utils/emailbox-helpers";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { useInitialEmailBoxProps } from "../../../../providers/InitialEmailBoxProvider";

import { useDocumentationTypes } from "../../../../providers/DocumentationTypesProvider";
import { useAttachments } from "../../../../providers/AttachmentsProvider";
import { useFilePreviewModal } from "../../../../providers/FilePreviewModalProvider";
import { useDriveDocuments } from "../../../../providers/DriveDocumentsProvider";

import "./attach-files.scss";

function AttachFiles({ isRedirected = [] }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const { setSelectedFile, setFilePreview } = useFilePreviewModal();
  const { setDriveDocuments } = useDriveDocuments();
  const { attachments, setAttachments } = useAttachments();
  const { loading } = useEmailTemplates();
  const { setDocumentationTypesFiles } = useDocumentationTypes();

  const beforeUploadFunction = (file) => {
    let { uid, name, type, size } = file;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ({ target: { result } }) => {
      setAttachments((prev) => [
        ...prev,
        {
          id: uid,
          name,
          type,
          mimeType: type,
          size: size
            ? size
            : getLength(Buffer.from(result.substring(result.indexOf(",") + 1))),
          blob: result,
          status: 400,
        },
      ]);
    };
    return false;
  };

  useEffect(() => {
    const fetchFilesForIds = async (fileIds) => {
      const promises = fileIds.map(async (fileId) => {
        const redirectedFiles = await driveRequest.getDriveItem(fileId);
        const filesToFetchAsBlob = await redirectedFiles.json();
        const filesFetchedAsBlob = await fetchBlobData(
          [filesToFetchAsBlob],
          driveRequest
        );
        return filesFetchedAsBlob;
      });

      return Promise.all(promises);
    };

    const fetchRedirectedFiles = async () => {
      try {
        const filesFetchedAsBlob = await fetchFilesForIds(isRedirected.flat());
        setAttachments(filesFetchedAsBlob.flat());
      } catch (error) {}
    };

    if (isRedirected.length > 0) {
      fetchRedirectedFiles();
    }
  }, []);

  const loadingSkeletonFiles = Array.from({ length: 6 }, (_, index) => ({
    uid: (index + 1).toString(),
    name: "Loading...",
    status: "uploading",
  }));

  return (
    <div className="attach-files-container">
      <Tooltip placement="top" {...{ title: "Attach files" }}>
        <Upload
          {...{
            multiple: true,
            fileList: loading ? loadingSkeletonFiles : attachments,
            //   className: documentsActive
            //     ? "uploadDocumentsClass"
            //     : "uploadDocumentsClassNone",
            className: "uploadDocumentsClassNone",
            onPreview: ({ id, type, blob, name }) => {
              setSelectedFile({
                src: blob,
                mimeType: type,
                name,
                id,
                url: `https://drive.google.com/file/d/${id}/view?usp=sharing`,
              });
              setFilePreview(true);
            },
            onRemove: ({ id }) => {
              setAttachments(attachments.filter((e) => e.id !== id));

              setDriveDocuments((driveDocument) =>
                driveDocument.filter((e) => e.id !== id)
              );

              setDocumentationTypesFiles((docTypesFiles) =>
                docTypesFiles.filter((e) => e.id !== id)
              );
            },
            beforeUpload: beforeUploadFunction,
          }}
        >
          <AttachIcon
            className={`attach-files-icon ${isDarkMode ? "dark-mode" : ""}`}
          />
        </Upload>
      </Tooltip>
    </div>
  );
}

export default AttachFiles;
