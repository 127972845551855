import React from "react";
import {
  appliedRentalGenerator,
  totalitiesTaxGen,
} from "../../../../../RentalBreakdownList/RentalBreakdown/Components/RentalFooter/RentalBreakdownFooterFunctions";
import { formatCurrency } from "../../../../../../../../../../utils";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { totalitiesExtractor } from "../../../../../RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/utils";
import {
  appliedAmountGenerator,
  totalitiesTotalTaxGen,
} from "../../ControlPanelFunctions";
import { getRandomColor } from "../../../../../../../../../../../Header/forms/Scheduling/helpers/creators";
import { formatDimensions } from "../../../../../../../../../../Estimations/DataEntryGrid/tools/exporters/exportToPDF";

export const getRentalPreview = ({
  // address = "153 Franklin",
  accountName = "Test",
  rentalObj = {},
  serviceDefinitions,
  showDimensions = true,
  darkMode = false,
}) => {
  console.log("rentalObj", rentalObj);
  const htmlData = [];
  let address = rentalObj?.projectAddress ?? "";
  if (!!address) {
    htmlData.push(
      <div className="address_preview_takeoff">
        <p>{address}</p>
      </div>
    );
  }
  const appliedAmount = appliedAmountGenerator({ fakeRentals: rentalObj });
  const totalities = totalitiesTotalTaxGen({ fakeRentals: rentalObj });
  if (!!accountName) {
    htmlData.push(
      <div className="client_preview_takeoff">
        <strong>{`Rental Applied Amount: ${formatCurrency(
          forceToNumber(appliedAmount?.toFixed(2))
        )}       Tax: ${formatCurrency(
          forceToNumber(totalities?.totalTax?.toFixed(2))
        )}       Total: ${formatCurrency(
          forceToNumber(totalities?.totalPrice?.toFixed(2))
        )}`}</strong>
      </div>
    );
  }

  rentalObj?.services?.map((service) => {
    const servicesChildren = [];
    const totalities = totalitiesExtractor(service, rentalObj);

    let ServColor = serviceColor(serviceDefinitions, service.serviceId);
    servicesChildren.push(
      <div className="takeoff_preview__title">
        <div
          className="circleshape"
          style={{
            backgroundColor: ServColor,
          }}
        ></div>{" "}
        <div>
          {service?.label}
          {"   -  "}
          {formatCurrency(
            forceToNumber(totalities?.totalPrice?.toFixed(2))
          )}{" "}
          (where tax is{" "}
          {formatCurrency(forceToNumber(totalities?.totalTax?.toFixed(2)))})
        </div>
      </div>
    );
    console.log("totalities", totalities);
    let serviceOptions = service?.serviceOptions ?? [];
    if (serviceOptions?.length > 0) {
      serviceOptions.forEach((elevationData) => {
        const appliedToRows = appliedRentalGenerator({
          fakeRentals: rentalObj,
          elevationData,
          selectedService: service?.label,
        });
        const totalitiesAndTax = totalitiesTaxGen({
          fakeRentals: rentalObj,
          elevationData,
          selectedService: service?.label,
        });
        console.log("testinggg", {
          appliedToRows,
          totalitiesAndTax,
          elevationData,
        });
        servicesChildren.push(
          createDynamicHtmlElement(
            "p",
            { style: { fontSize: "11px", color: "#52C41A" } },
            `${elevationData?.elevationId ?? ""} ${
              elevationData?.elevationLabel ?? ""
            }`
          )
        );
        if (Array.isArray(elevationData?.items) && showDimensions) {
          elevationData?.items?.forEach((item, idx) => {
            let tempText = "";

            tempText += `PLI ${idx + 1} - ${formatDimensions(
              item?.length,
              item?.height,
              item?.width
            )} `;
            servicesChildren.push(
              createDynamicHtmlElement(
                "p",
                {
                  style: {
                    fontSize: "11px",
                    color: darkMode ? "#fff" : "#000",
                    marginLeft: "40px",
                  },
                },
                tempText
              )
            );
          });
        }

        servicesChildren.push(
          createDynamicHtmlElement(
            "p",
            {
              style: {
                fontSize: "11px",
                color: darkMode ? "#fff" : "#000",
                marginLeft: "40px",
              },
            },
            `Applied Rent: ${formatCurrency(
              forceToNumber(appliedToRows?.toFixed(2))
            )}`
          )
        );
        servicesChildren.push(
          createDynamicHtmlElement(
            "p",
            {
              style: {
                fontSize: "11px",
                color: darkMode ? "#fff" : "#000",
                marginLeft: "40px",
              },
            },
            `Tax: ${formatCurrency(
              forceToNumber(totalitiesAndTax?.totalTax?.toFixed(2))
            )}`
          )
        );
        servicesChildren.push(
          createDynamicHtmlElement(
            "p",
            {
              style: {
                fontSize: "11px",
                color: darkMode ? "#fff" : "#000",
                marginLeft: "40px",
              },
            },
            `Total Price: ${formatCurrency(
              forceToNumber(totalitiesAndTax?.totalPrice?.toFixed(2))
            )}`
          )
        );
        servicesChildren.push(
          createDynamicHtmlElement(
            "p",
            {
              style: {
                fontSize: "11px",
                color: darkMode ? "#fff" : "#000",
                marginLeft: "40px",
              },
            },
            `Retainage: ${formatCurrency(
              forceToNumber(totalitiesAndTax?.retainageAmount?.toFixed(2))
            )} (${forceToNumber(totalitiesAndTax?.retainage)} %)`
          )
        );
      });
    }

    htmlData.push(
      createDynamicHtmlElement(
        "div",
        {
          className: "serviceParent__takeoffPreview",
          style: {
            marginTop: "20px",
            width: "960px",
            minHeight: "73px",
            // height: "273px",
            background: "#FFFFFF",
            boxShadow: `0px 0px 5px 2px ${ServColor}`,
            borderRadius: "10px",
          },
        },
        servicesChildren
      )
    );
  });

  return htmlData;
};
function createDynamicHtmlElement(
  tagName,
  attributes,
  children = [],
  isHtml = false
) {
  const props = { ...attributes };

  if (isHtml) {
    props.dangerouslySetInnerHTML = { __html: children };
  } else {
    props.children = children;
  }

  const element = React.createElement(tagName, props);

  return element;
}
function serviceColor(serviceDefs, serviceId) {
  let def = serviceDefs?.filter(
    (serviceDef) => parseInt(serviceDef["workId"]) === serviceId
  )[0];
  console.log("def", serviceDefs);
  return def?.colorCode ?? getRandomColor();
  //  {
  //   backgroundColor: def?.foreGroundColor?.substring(1),
  //   color: def?.colorCode?.substring(1),
  // };
}
