export const setEstimations = estimations => ({
    type: 'SET_ESTIMATIONS',
    payload: estimations
})
export const setEstimationStatusToStart = estimationDetail => ({
    type: 'SET_ESTIMATION_STATUS_TO_START',
    payload: estimationDetail
})
export const setEstimationStatusToHoldOn = estimationDetail => ({
    type: 'SET_ESTIMATION_STATUS_TO_HOLDON',
    payload: estimationDetail
})
export const setEstimationStatusToPaused = estimationDetail => ({
    type: 'SET_ESTIMATION_STATUS_TO_PAUSED',
    payload: estimationDetail
})
export const setEstimationStatusToStopped = estimationDetail => ({
    type: 'SET_ESTIMATION_STATUS_TO_STOPPED',
    payload: estimationDetail
})
export const setEstimationStatusToFinished = estimationDetail => ({
    type: 'SET_ESTIMATION_STATUS_TO_FINISHED',
    payload: estimationDetail
})