import dayjs from "dayjs";
import { otherScopeServiceTotalPriceMapper } from "../../../../../../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/utils";
import { getServiceLegacyPrice } from "../../../../../calculations/servicePrices";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../../../../Estimations/DataEntryGrid/models/Service";

//this function creates sov object
export const handleSubmit = async ({
  setRedirectToScheduleOfValue,
  previousScheduleOfValue,
  setSelectedEstimations,
  createScheduleOfValue,
  selectedEstimations,
  authUser,
  estimations,
  buttonType,
  closeModal,
  projectId,
  dispatch,
  isNextStep,
  getApplicationId,
  saveAddedLogs,
}) => {
  const scheduleOfValue = {
    projectId, // Get project id from url
    projectName: estimations[0].jobSiteAddress, // Get project name from estimation
    SOVNo: !!previousScheduleOfValue ? previousScheduleOfValue?.SOVNo + 1 : 1,
    editLogs: [
      {
        member: `${authUser.given_name} ${authUser.family_name}`,
        nameOfUser: `${authUser.given_name} ${authUser.family_name}`,
        cognitoUserId: authUser?.sub,
        updatedAt: dayjs().valueOf(),
        currentData: {},
        id: projectId,
        previusData: {},
      },
    ],
    rentalPaymentTerms: {
      //here we create the object
      name: undefined,
      start: undefined,
      end: undefined,
      description: undefined,
    },
    estimationsSelected: selectedEstimations, // Get selected estimations
    services: estimations
      ?.filter(
        (estimation) =>
          selectedEstimations.indexOf(estimation.estimationId) > -1
      ) // Filter only selected estimations, from list
      ?.flatMap((estimation, estimationIndex) =>
        estimation?.services?.map((service, serviceIndex) => {
          console.log("service", service);
          return {
            // Modify and return services of each estimation
            ...service, // Spread service properties that dont need to change
            estimationId: estimation.estimationId, // Add estimation id to each service
            amounts: [], // Initialize and empty array for amounts of each service
            rentalPaymentTerms: service?.rentalPaymentTerms || {}, // Initialize rental payment terms
            visible: estimationIndex === 0 && serviceIndex === 0 ? true : false, // Set visible to true only to the first service
            totalPrice: (() => {
              // const { serviceAddons = [], serviceOptions = [] } = service || {};
              let serviceTotalPrice = 0;
              console.log("service123", service);
              serviceTotalPrice += getServiceLegacyPrice(service);
              // if (service?.isScope) {
              //   serviceTotalPrice = otherScopeServiceTotalPriceMapper(service);
              //   console.log("serviceTotalPrice", serviceTotalPrice);
              // } else {
              //   serviceTotalPrice = [
              //     ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
              //       items?.flatMap(({ totalPrice }) => +totalPrice)
              //     ),
              //     ...serviceAddons?.map(({ totalPrice }) => +totalPrice),
              //   ]
              //     ?.filter(Boolean)
              //     ?.reduce((acc, curr) => (acc += +curr), 0);
              // }
              console.log("onCreationPrice", {
                serviceTotalPrice,
                service,
              });

              return serviceTotalPrice;
            })(),
            price: (() => {
              const { serviceAddons = [], serviceOptions = [] } = service || {};

              const serviceTotalPrice = [
                ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                  items?.flatMap(({ price }) => +price)
                ),
                ...serviceAddons?.map(({ price }) => +price),
              ]
                ?.filter(Boolean)
                ?.reduce((acc, curr) => (acc += +curr), 0);

              return serviceTotalPrice;
            })(),
            typeOfSOV: "", // Type of SOV is empty initially,
            retainage: 10, // Default retainage 10%
            isChangeOrder: estimation?.isChangeOrder,
            serviceOptions: getModifiedServiceOptions(
              service,
              estimation,
              serviceIndex
            ),
          };
        })
      ),
  };

  console.log("scheduleOfValue", scheduleOfValue);
  // Save schedule of value to db
  dispatch(
    createScheduleOfValue(
      scheduleOfValue,
      isNextStep,
      getApplicationId,
      saveAddedLogs
    )
  );

  // Close modal and reset selected estimations
  if (buttonType === "Save & Close") {
    setSelectedEstimations([]);
    closeModal();
  }
  // Redirect to newly created SOV
  else if (buttonType === "Finish") {
    setRedirectToScheduleOfValue(true);
  }
  // Only reset selected estimations
  else if (buttonType === "Save & New") {
    setSelectedEstimations([]);
  }
};

// asssume that hoist service addons are elevations

export const getModifiedServiceOptions = (service) => {
  let toReturn = [
    ...service?.serviceOptions[0]?.map((elevationData) => ({
      ...elevationData,
      items: elevationData?.items?.map((pliData) => ({
        ...pliData,
        totalPrice: +pliData?.totalPrice,
        price: +pliData?.price,
        approved: !!service?.isScope ? true : pliData?.approved,
        charges: [],
      })),
    })),
  ];
  if (checkIfServiceIsHoist(service)) {
    toReturn = [...toReturn, getHoistServiceAddonsAsElevations(service)];
  }
  console.log("toReturn", toReturn);
  return [toReturn];
};

export const getHoistServiceAddonsAsElevations = (service) => {
  let addonsPrice = service?.serviceAddons?.map(({ totalPrice = 0 }) =>
    forceToNumber(totalPrice)
  );

  return {
    elevationId: "SERVICE ADDONS",
    elevationLabel: "SERVICE ADDONS",
    items: [],
    totalPrice: addonsPrice?.reduce((acc, curr) => (acc += curr), 0) || 0,
    areServiceAddons: true,
  };
};
