import { upperCaseFirstLetter } from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";

function translateWord(word, translations) {
  if (!translations?.[word] && !translations?.[upperCaseFirstLetter(word)]) {
    if (word) {
      const missingTranslations =
        JSON.parse(localStorage.getItem("missingTranslations")) || [];
      const updatedMissingTranslations = [
        ...new Set([...missingTranslations, word]),
      ];
      localStorage.setItem(
        "missingTranslations",
        JSON.stringify(updatedMissingTranslations)
      );
    }
  } else {
    const missingTranslations =
      JSON.parse(localStorage.getItem("missingTranslations")) || [];
    const updatedMissingTranslations = missingTranslations.filter(
      (el) => el !== word
    );
    localStorage.setItem(
      "missingTranslations",
      JSON.stringify(updatedMissingTranslations)
    );
  }
  return (
    translations?.[word] || translations?.[upperCaseFirstLetter(word)] || word
  );
}

export default translateWord;
