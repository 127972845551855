import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import "./CrewNrForDay.scss";
import {
  openInNewTab,
  updateDocumentTitle,
} from "../../../../../../../../utils";
import { useSelector } from "react-redux";
const CrewNrForDay = ({
  crewNrVisible,
  setCrewNrForVisible,
  scheduleDay,
  totalCrewNrForDay,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={crewNrVisible}
        onCancel={() => {
          updateDocumentTitle(); // reset document title to "Lead Manager";
          setCrewNrForVisible(false);
        }}
        footer={null}
        title={`All Crews for ${dayjsNY(scheduleDay?.startDate).format(
          "MM/DD/YYYY"
        )}`}
        afterOpenChange={(event) => {
          event &&
            updateDocumentTitle({
              newTitle: `All Crews for ${dayjsNY(scheduleDay?.startDate).format(
                "DD/MM/YYYY"
              )}`,
            });
        }}
        centered
        closeIcon={<XIcon />}
        width={1200}
        height="fit-content"
        className={`crewNrForDay ${isDarkMode && "crewNrForDayDark"}`}
      >
        <table
          style={{
            width: "100%",
          }}
        >
          <tr
            style={
              {
                // display: "flex",
                // justifyContent: "space-around",
                // width: "100%",
              }
            }
          >
            <th>Schedule Address</th>
            <th>Schedule Name</th>
            <th>Type of Work</th>
            <th>Crew Name</th>
            <th>Crew Position</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
          {totalCrewNrForDay?.map((schedule, index) => {
            return schedule?.crews?.map((crewName, i) => {
              return (
                <tr
                  style={
                    {
                      // display: "flex",
                      // gap: 30,
                      // width: "100%",
                    }
                  }
                >
                  <td
                    style={{
                      color: "#1264a3",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() =>
                      openInNewTab(`/scheduling/${schedule?.scheduleId}`)
                    }
                  >
                    {schedule?.scheduleAddress}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {schedule?.scheduleName}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {schedule?.typeOfWork}
                  </td>
                  <td style={{ textAlign: "center" }}>{crewName?.crewName}</td>
                  <td style={{ textAlign: "center" }}>
                    {crewName?.crewPosition}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {!!crewName?.startTime
                      ? dayjsNY(crewName?.startTime).format("h:mm a")
                      : ""}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {!!crewName?.endTime
                      ? dayjsNY(crewName?.endTime).format("h:mm a")
                      : ""}
                  </td>
                </tr>
              );
            });
          })}
        </table>
      </Modal>
    </div>
  );
};

export default CrewNrForDay;
