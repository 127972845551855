import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRedux } from "../../../hooks";
import FooterAmounts from "./FooterAmounts";
import { navigateToRoute } from "./utils/generateRoute";
import { XIcon } from "../../../Communication/assets";
import { WarningTriangle } from "../../../DynamicView/src";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";

import "./Footer.scss";

const Footer = ({ closeModal }) => {
  const [confirmData, setConfirmData] = useState();
  const [isWritable] = useRedux("billIsWritable");

  const { billData } = useInitialBillContext();
  const { handleSave } = useCreateBillContext();

  let isSaveButtonClicked = false;

  const navigate = useNavigate();

  const onSaveClickHandler = (type) => {
    setConfirmData({
      visible: true,
      setVisible: () => {},
      onConfirm: () => {
        if (!isSaveButtonClicked) {
          isSaveButtonClicked = true;
          setConfirmData();
          handleSave(type).then(() => {
            isSaveButtonClicked = false;
          });
        }
      },
      onCancel: () => {
        setConfirmData();
      },
      text:
        "Are you sure" +
        (billData ? " you want to save changes?" : " you want to save bill?"),
      title: billData ? "Confirm Changes" : "CONFIRMATION",
    });
  };

  const onCancel = (type) => {
    setConfirmData({
      visible: true,
      setVisible: () => {},
      onConfirm: () => {
        closeModal();
        navigateToRoute(billData, navigate);
        setConfirmData();
      },
      onCancel: () => {
        setConfirmData();
      },
      text: billData
        ? "Any change that you have made will be lost?"
        : "This bill will be discarded",
      title: "Confirm Close",
    });
  };

  return (
    <div className="bill-footer-container">
      <FooterAmounts />
      <div className="bill-footer-buttons">
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={() => onCancel()}
          tooltipCategory="Bills"
          tooltipKey="cancel"
        >
          Cancel
        </MondayButton>
        <MondayButton
          Icon={<TickIcon />}
          onClick={() => onSaveClickHandler()}
          disabled={!isWritable && billData}
          tooltipCategory="Bills"
          tooltipKey="saveAndClose"
        >
          Save & Close
        </MondayButton>
        {confirmData && (
          <WarningModal
            visible={confirmData?.visible}
            setVisible={confirmData?.setVisible}
            title={confirmData?.title}
            closable={true}
            className="logout-warning-modal"
          >
            <div className="logout-modal-body">
              <span>
                <WarningTriangle />
              </span>
              <p>{confirmData?.text}</p>
              <div className="buttons">
                <MondayButton
                  onClick={confirmData?.onCancel}
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={confirmData?.onConfirm}
                  Icon={<TickIcon width={17} height={17} />}
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}
      </div>
    </div>
  );
};

export default Footer;
