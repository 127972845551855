import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useImperativeHandle, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { useDynamicViewContext } from "../../utils/dynamicViewContext";
import "./Small.scss";

const Small = ({ edited, id, params, setNewValues }) => {
  const data = params.data;
  const [values, setValues] = useState(params);
  const Icon = data?.Icon;
  const { statusRef } = useDynamicViewContext();
  const [rowData, setRowData] = useState(null);

  const currentURL = window?.location?.pathname;

  useImperativeHandle(statusRef, () => ({
    setCustomValue: (value) => {
      setValues((prev) => ({ data: { ...prev.data, value } }));
      setNewValues({ data: { ...values.data, value } });
    },
  }));

  const getRowData = async () => {
    await API.get(
      `${
        currentURL.split("/")[1].includes("permit")
          ? "permitDrawings"
          : currentURL.split("/")[1]
      }`,
      `/${
        currentURL.split("/")[1].includes("permit")
          ? "permitDrawings"
          : currentURL.split("/")[1]
      }/${currentURL.split("/")[2]}`
    ).then((r) => setRowData(r));
  };

  useEffect(() => {
    if (!!currentURL) {
      getRowData();
    }
  }, [currentURL, edited]);

  useEffect(() => {
    if (edited) {
      keys(edited).map((el) => {
        values.data.value = edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
      getRowData();
    }
  }, [JSON.stringify(edited)]);

  return (
    <div className="smallContainer">
      {Icon && (
        <span className="IconStyle">
          <Icon />
        </span>
      )}
      <span id="smallLabelId" className="labelStyle">
        {values.data?.value}
      </span>
      <div className="lastChangeStatus">
        {!!rowData?.editLogs
          ?.sort((a, b) => b?.updatedAt - a?.updatedAt)
          .find((e) => e?.label?.includes("Status"))?.updatedAt
          ? moment(
              rowData?.editLogs?.find((e) => e?.label.includes("Status"))
                ?.updatedAt
            ).format("MM/DD/YYYY hh:mm a")
          : ""}
      </div>
      <div className="lastChangeStage">
        {!!rowData?.editLogs
          ?.sort((a, b) => b?.updatedAt - a?.updatedAt)
          .find((e) => e?.label?.includes("Stage"))?.updatedAt
          ? moment(
              rowData?.editLogs?.find((e) => e?.label.includes("Stage"))
                ?.updatedAt
            ).format("MM/DD/YYYY hh:mm a")
          : ""}
      </div>
    </div>
  );
};
export default Small;
