import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { getChangedData } from "../../../../../../../SidebarPages/Accounting/components/utilities";

const LOG_TIME_FORMAT = "MM-DD-YYYY hh:mm A";

/**
 * @param {"edit" | "add" | "remove"} type Type of Log action
 * @param {Object} entry Entry that will create logs for
 * @param {Array} rowData List of all entries
 * @param {Object} userConfiguration User that is making the changes
 */
function createEntryLogs(type, entry, rowData, userConfiguration) {
  if (!["edit", "add", "remove"].includes(type)) {
    return;
  }

  const entryLog = {
    recordId: entry?.entryId,
    recordName: entry?.employeeFullName,
    category: "Payroll Entry Logs",
    actionType: `${type[0].toUpperCase()}${type.slice(1)}`,
    topic: "Entry",
    label: "",
    currentData: {},
    previousData: {},
    updatedAt: dayjsNY().valueOf(),
    member: userConfiguration?.nameOfUser,
    nameOfUser: userConfiguration?.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    updatedKeys: [],
  };

  const entryLogObject = {
    sow: entry?.sow,
    crewId: entry?.crewId,
    entryId: entry?.entryId,
    punchType: entry?.punchType,
    jobsiteId: entry?.jobsiteId,
    uploadName: entry?.uploadName,
    employeeId: entry?.employeeId,
    activityStatus: entry?.activityStatus,
    punchCoordinates: JSON.stringify(entry?.punchCoordinates || {}),
  };

  if (type === "edit") {
    const index = rowData.findIndex((el) => el.entryId === entry.entryId);
    const oldEntry = rowData?.[index];
    const result = getChangedData(
      {
        ...entry,
        punchTime: dayjsNY(entry?.punchTime).format(LOG_TIME_FORMAT),
        punchDate: dayjsNY(entry?.punchDate).format(LOG_TIME_FORMAT),
      },
      {
        ...oldEntry,
        punchTime: dayjsNY(oldEntry?.punchTime).format(LOG_TIME_FORMAT),
        punchDate: dayjsNY(oldEntry?.punchDate).format(LOG_TIME_FORMAT),
      }
    );
    if (result) {
      Object.assign(entryLog, {
        currentData: result.curr,
        previousData: result.prev,
        updatedKeys: Object.keys(result.curr),
      });
    } else {
      return;
    }
  }

  if (type === "add") {
    const result = getChangedData(entryLogObject, {});
    if (result) {
      Object.assign(entryLog, {
        currentData: result.curr,
        previousData: result.prev,
        updatedKeys: Object.keys(result.curr),
      });
    } else {
      return;
    }
  }

  if (type === "remove") {
    const result = getChangedData({}, entryLogObject);
    if (result) {
      Object.assign(entryLog, {
        currentData: result.curr,
        previousData: result.prev,
      });
    }
  } else {
    return;
  }

  return entryLog;
}

export default createEntryLogs;
