import React, { useState } from "react";

import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import CheckboxCard from "src/components/pages/Settings/settingsComponents/TeamManagement/components/MembersCard/CheckboxCard";

const DynamicInputSwitch = ({
  options,
  defaultChecked = false,
  canChange = true,
  mode,
  type,
  ...otherProps
}) => {
  const [toggle, setToggle] = useState(defaultChecked);

  const additionalInputKeys =
    toggle && options
      ? {
          type: "select",
          ...(mode && { mode }),
          customOptions: options,
          placeholder: "Select Topic...",
        }
      : undefined;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {options && canChange && (
        <CheckboxCard
          {...{
            identityId: 1,
            value: toggle,
            onChange: () => {
              setToggle((prev) => !prev);
            },
            style: {
              width: "fit-content",
              height: "fit-content",
            },
          }}
        />
      )}
      <InputComponent
        {...{
          ...(additionalInputKeys && additionalInputKeys),
          ...otherProps,
        }}
      />
    </div>
  );
};

export default DynamicInputSwitch;
