/**
 * @param scheduleDay {ScheduleDay}
 * */

import { nameFirstDigits } from "../../../../../../../utils/nameFirstDigits";
import { formatFullDate } from "../../../helpers/creators";
import { ScheduleDayNoteTypeLabel } from "../../../models/ScheduleDayNote";
import dayjs from "dayjs";
import "./ScheduleDayNotes.scss";
export const ScheduleDayNotesViewer = ({ scheduleDay, authenticatedUser }) => {
  const { startDate, endDate, day, notes, color } = scheduleDay;

  const titleStyle = { color };

  // console.log(notes)
  if (notes?.length > 0) {
    return (
      <div className="schedule-note">
        <div className="pli-note-info">
          <h5 style={titleStyle}>
            Day {scheduleDay?.day} - {formatFullDate(scheduleDay?.startDate)}{" "}
          </h5>
        </div>

        <div className={"notes-container"}>
          {notes?.map((note) => {
            // console.log(dayjs(note.createdDate).fromNow())
            return (
              <div className="comment_wrapper">
                <div className="comment__icon">
                  {!!note?.createdBy ? nameFirstDigits(note?.createdBy) : ""}
                </div>
                <div className="comment_data">
                  <span className="top_details">
                    <h5>{note?.createdBy}</h5>
                    <h6>{dayjs(note?.createdDate).fromNow()}</h6>
                  </span>
                  <p className="schedule-note-type">
                    Type: {ScheduleDayNoteTypeLabel(note?.type)}
                  </p>
                  <span
                    className="comment-content"
                    dangerouslySetInnerHTML={{ __html: note?.text }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return "";
  }
};
