import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);

function isLogTimeInRange(logTime, startDate, endDate) {
  return logTime.isSameOrAfter(startDate) && logTime.isSameOrBefore(endDate);
}

function sessionLogsQuickFilter(sessions, period, logTime = "") {
  const now = dayjs();
  let startDate, endDate;

  switch (period) {
    case "today":
      startDate = now.startOf("day");
      endDate = now.endOf("day");
      break;
    case "yesterday":
      startDate = now.subtract(1, "day").startOf("day");
      endDate = now.subtract(1, "day").endOf("day");
      break;
    case "thisWeek":
      startDate = now.startOf("week");
      endDate = now.endOf("week");
      break;
    case "lastWeek":
      startDate = now.subtract(1, "week").startOf("week");
      endDate = now.subtract(1, "week").endOf("week");
      break;
    default:
      throw new Error("Invalid period specified");
  }
  if (logTime) {
    return isLogTimeInRange(dayjs(logTime), startDate, endDate);
  }

  return (
    sessions.filter((session) => {
      const logTime = dayjs(session?.sessionInfo?.logTime);
      return isLogTimeInRange(logTime, startDate, endDate);
    }).length > 0
  );
}

export default sessionLogsQuickFilter;
