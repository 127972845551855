import DaysTable from "../../../DaysTable/DaysTable";
import WeekTable from "../../../WeekTable/WeekTable";
import "./DetailsBody.scss";

const DetailsBody = ({
  setSelectedWeek,
  selectedWeek,
  elevationData,
  allRow,
}) => {
  console.log("selectedWeek", selectedWeek);
  return (
    <div className="agGridContainerRentalDetails">
      {!selectedWeek ? (
        <WeekTable
          key={1}
          {...{
            elevationData,
            allRow,
            setSelectedWeek,
          }}
        />
      ) : (
        <DaysTable
          key={2}
          {...{
            elevationData,
            selectedRow: allRow,
            data: selectedWeek,
          }}
        />
      )}
    </div>
  );
};

export default DetailsBody;
