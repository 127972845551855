import { formatCurrency } from "../../utils";

const pieSeries = {
  type: "pie",
  angleKey: "value",
  legendItemKey: "category",
};

const currencyPieSeries = {
  type: "pie",
  angleKey: "value",
  legendItemKey: "category",
  tooltip: {
    renderer: function ({ datum, xKey, yKey }) {
      return {
        content: formatCurrency(datum.value),
      };
    },
  },
};

const getData = (data, chartType) => {
  return chartType === "column" || chartType === "bar" ? data.bar : data.pie;
};

const getSeries = (defaultOptions, chartType, type) => {
  const series = type === "count" ? pieSeries : currencyPieSeries;

  if (chartType === "column") {
    return defaultOptions.series;
  } else if (chartType === "bar") {
    return defaultOptions.series.map((serie) => ({
      ...serie,
      direction: "horizontal",
    }));
  } else if (chartType === "doughnut") {
    return [{ ...series, type: "donut", innerRadiusRatio: 0.7 }];
  } else {
    return [series];
  }
};

export const configureCharts = (data, chartType, defaultOptions, type) => {
  const customObj = {
    title: defaultOptions?.title,
    subtitle: defaultOptions?.subtitle,
    data: getData(data, chartType),
    legend: { enabled: true, position: "bottom" },
    navigator: { enabled: true, height: 20 },
    series: getSeries(defaultOptions, chartType, type),
  };

  return customObj;
};
