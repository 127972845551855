export const projects = (circleProgressPicker, IdHandler, updatedDynamicStates) => {
  return {
    projectStatus: () => ({
      title: "Project Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Projects",
        field: "projectStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter((a) => a?.projectStatus === circleProgressPicker)
            ?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.projectStatus === circleProgressPicker
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //projectStatus
    attorney: () => ({
      title: "Project Attorney",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Projects",
        field: "attorney",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.attorney?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.attorney?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //attorney
    expeditor: () => ({
      title: "Project Expeditor",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Projects",
        field: "expeditor",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.expeditor?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.expeditor?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //expeditor
  }
}
