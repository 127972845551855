import EstimationPicker from "./Components/EstimationPicker/EstimationPicker";
import useMountedState from "../../../../../hooks/useMountedState";
import Information from "./Components/Informaions/Information";
import ModalFooter from "./Components/ModalFooter/ModalFooter";
import { filterTables, updateDocumentTitle } from "../../../../../../../utils";
import Header from "./Components/Header/Header";
import "./new-schedule-of-value.scss";
import WarningModal from "../../../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as WarningIcon } from "../../../../../../../icons/warningTriangle.svg";
import { ReactComponent as CloseIcon } from "../../../../../DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { MondayButton } from "../../../../../../commonComponents/index";

import { useState, useEffect } from "react";
import { sortBy } from "lodash";
import { message, Modal } from "antd";
import { filterTablesPagination } from "../../../../../../../utils/paginationFetchData";
import { useSelector } from "react-redux";
import ContextProvider from "../../ScheduleOfValueView/Context/Context";
import ScheduleOfValueView from "../../ScheduleOfValueView/ScheduleOfValueView";

const getEstimations = (projectId) =>
  filterTables("estimations", "projectId", projectId);

const NewScheduleOfValue = ({
  visible,
  closeModal,
  projectId: currentProjectId,
  editSov,
  includedAlreadyServices,
  isNextStep,
  projectData,
}) => {
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const [buttonType, setButtonType] = useState("Finish");
  const [chosenWay, setChosenWay] = useState("Estimation");
  const [estimations, setEstimations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  // const [includedAlreadyServices, setIncludedAlreadyServices] = useState([]);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [isOpenForNextStep, setIsOpenForNextStep] = useState(false);

  const projectId = currentProjectId || projectData.projectId;

  const isMounted = useMountedState();

  const preClose = () => {
    setSelectedEstimations([]);
    setButtonType("Finish");
    setChosenWay("Estimation");
    updateDocumentTitle(); // reset document title to "Lead Manager";
    closeModal();
  };

  // //from "Create New" button of sidebar we need to set includedAlreadyServices
  // useEffect(() => {
  //   if (!incAlreadyServices) {
  //     const data = sortBy(scheduleOfValues, ["SOVNo"]) || [];

  //     const labels = new Set();
  //     data.forEach((obj) => {
  //       obj?.services?.forEach((service) => {
  //         if (!labels.has(service.label)) {
  //           labels.add(service.label);
  //         }
  //       });
  //     });

  //     setIncludedAlreadyServices([...labels]);
  //   } else {
  //     setIncludedAlreadyServices(incAlreadyServices);
  //   }
  // }, [incAlreadyServices]);

  // Fetch list of approved estimations on mount or whenever company or project id changes
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const estimations = await getEstimations(projectId);
        if (isMounted()) {
          let toSet = estimations?.map((el) => ({
            ...el,
            services: el?.services?.filter(
              (service) =>
                includedAlreadyServices ||
                []?.indexOf(`${service?.label}-${service?.estimationId}`) === -1
            ),
          }));
          setEstimations(
            sortBy(
              toSet?.filter(
                (estimation) => estimation.estSTATUS === "Approved"
              ),
              ["createdAt"]
            )
          );
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
        // message.destroy();
      }
    })();
  }, [projectId, isMounted, includedAlreadyServices, visible]);

  const onCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    closeModal();
    setFormChanged(false);
    setSelectedEstimations([]);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        wrapClassName={`cardLayoutModal newSovModalWrapper ${
          isDarkMode && "newSovModalWrapperDark"
        }`}
        open={visible && !!!isOpenForNextStep}
        onCancel={() => {
          formChanged ? setCancelModalVisible(true) : preClose();
        }}
        centered
        closeIcon={<CloseIcon />}
        destroyOnClose={true}
        title="New SOV"
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "New SOV" });
        }}
        footer={
          <ModalFooter
            {...{
              setSelectedEstimations,
              selectedEstimations,
              setButtonType,
              estimations,
              buttonType,
              closeModalWithWarning() {
                formChanged ? setCancelModalVisible(true) : preClose();
              },
              projectId,
              visible,
              closeModal: () => {
                if (isNextStep) return;
                updateDocumentTitle(); // reset document title to "Lead Manager";
                closeModal();
              },
              isNextStep,
              getApplicationId: (id) => {
                setIsOpenForNextStep(id);
              },
            }}
          />
        }
      >
        <div className="form-content">
          <Header {...{ setChosenWay, chosenWay }} />
          <div className="mainBodyContainer">
            <EstimationPicker
              {...{
                setSelectedEstimations,
                selectedEstimations,
                estimations,
                isLoading,
                chosenWay,
                editSov,
                includedAlreadyServices,
                setFormChanged,
              }}
            />
            <Information {...{ selectedEstimations, estimations }} />
          </div>
        </div>
      </Modal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {isOpenForNextStep && (
        <ContextProvider>
          <ScheduleOfValueView
            {...{ isNextStep, sovId: isOpenForNextStep, closeModal }}
          />
        </ContextProvider>
      )}
    </>
  );
};

export default NewScheduleOfValue;
