import "./DocMembers.scss";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Form } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { InputComponent } from "../../../../../../Fleet/components";
import {
  AvatarImages,
  MondayButton,
} from "../../../../../../../commonComponents";
import { UserIconFilled } from "../../../../../../../../assets";
import DynamicTeamModal from "../../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { formatTeamFormField } from "../../helpers";

const DocMembers = ({
  form,
  docConfig,
  docType,
  categoryName,
  thisDocNameComesFrom,
  teamsConfiguration = [],
}) => {
  const [docTeam, setDocTeam] = useState([]);
  const [open, setOpen] = useState(false);

  const selectedTeam = Form.useWatch("documentationTeam", form);

  useEffect(() => {
    API.get("teams", "/teams")
      .then((team) => {
        setDocTeam(formatTeamFormField(team));
      })
      .catch((error) => console.error(error));
  }, []);

  const categories =
    docConfig?.find((el) => el?.categoryName === categoryName)
      ?.documentationsAvailable || [];

  const settingsTeamMembers =
    categories
      ?.find((cat) => cat?.docName === docType)
      ?.teamsConfiguration?.flatMap(({ members = [] }) => members) || [];

  return (
    <>
      <div className="doc-members">
        <div className="membersButtonDiv">
          <InputComponent
            key={teamsConfiguration.length}
            {...{
              label: "Team",
              className: "doc-selectTeam",
              formItemName: "documentationTeam",
              tooltipCategory: "Documentations",
              tooltipKey: "documentationTeam",
              type: "select",
              mode: "multiple",
              placeholder: "Select team...",
              allowClear: false,
              customOptions: docTeam,
              defaultValue: formatTeamFormField(teamsConfiguration),
              onChange: (_, option) => {
                form.setFieldsValue({ documentationTeam: option });
              },
              showSearch: true,
              disabled: thisDocNameComesFrom !== "",
              // maxTagPlaceholder: (items) => (
              //   <Tooltip title={items.map(({ label }) => label).join(", ")}>
              //     <span>{`+ ${items.length} more`}</span>
              //   </Tooltip>
              // ),
              maxTagCount: "responsive",
            }}
          />

          {!!selectedTeam?.length && (
            <MondayButton
              onClick={() => {
                setOpen(true);
              }}
              Icon={<UserIconFilled />}
              tooltipCategory="Documentations"
              tooltipKey="membersModal"
              className="mondayButtonBlue docMembersButton"
            />
          )}
        </div>

        <AvatarImages
          {...{
            cognitoUserIds: (selectedTeam || teamsConfiguration || [])
              ?.flatMap((el) => el?.members)
              ?.map((e) => e?.cognitoUserId),
          }}
        />

        {open && (
          <DynamicTeamModal
            {...{
              open,
              setOpen,
              selectedTeam,
              setSelectedTeam: (team) => {
                form.setFieldsValue({
                  documentationTeam: [
                    {
                      value: team[0].value,
                      label: team[0].value,
                      members: team[0].members,
                    },
                  ],
                });
              },
              ClearOptions: () => {
                setOpen(false);
              },
            }}
          />
        )}
      </div>
      {!!settingsTeamMembers.length && (
        <div className="docConfigTable">
          <span className="docCofingTableText">
            <InfoCircleFilled />
            <p>Here are the users that are selected in settings!</p>
          </span>

          <AvatarImages
            {...{
              cognitoUserIds: settingsTeamMembers.map(
                ({ cognitoUserId }) => cognitoUserId
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default DocMembers;
