import { updateRental } from "../../../../../../../../../../../../Utils/rentalManipulation";
import { formatCurrency } from "../../../../../../../../../../../../../../../../utils";

import { message } from "antd";

export const cellValueChangedDay = ({
  setUnchangingFakeRentals,
  unchangingFakeRentals,
  selectedElevation,
  selectedService,
  selectedItem,
  selectedWeek,
  params,
}) => {
  let NewValue = undefined;
  try {
    NewValue = eval(params?.value);
  } catch (e) {
    NewValue = 0;
    message?.warning("Invalid input");
  }

  const weekData = unchangingFakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === selectedElevation?.elevationId
    )
    ?.items?.find(({ id }) => selectedItem?.id === id)
    ?.rentalDetails?.details?.find(({ week }) => week === selectedWeek?.week);

  const appliedToThisWeek = weekData?.weeklyAmount;

  const applianceAmount =
    appliedToThisWeek -
    weekData?.days?.reduce(
      (acc, curr) =>
        !!curr?.lock
          ? curr?.id === params?.data?.id
            ? acc
            : acc + curr?.dailyAmount
          : acc,
      0
    );

  const howManyUnlockedDays = weekData?.days?.filter(
    (daysData) => daysData?.id !== params?.data?.id && !daysData?.lock
  ).length;

  const dailyAmount =
    //if the value from user is less than 0 return 0
    NewValue < 0
      ? (() => {
          message?.warning(`Input is less than ${formatCurrency(0)}`);
          return 0;
        })()
      : // : //if the value from user is greater than max rent per week return the max rent value per week
        // NewValue > applianceAmount
        // ? (() => {
        //     message?.warning(`Input is less than ${formatCurrency(applianceAmount)}`)
        //     return applianceAmount
        //   })()
        //else if we dont have any of those problems divide the input form user with 7 and return it
        NewValue;

  const disturbingDaysAmount =
    (applianceAmount - dailyAmount) / howManyUnlockedDays;

  const thisElevation = selectedElevation?.elevationId;
  const thisPli = selectedItem?.id;
  const notOnlyThisDay = params?.data?.id;
  const thisWeek = selectedWeek?.week;

  // this object is the new rental object
  const newRental = updateRental({
    fakeRentals: unchangingFakeRentals,
    selectedService,
    notOnlyThisDay,
    thisElevation,
    thisWeek,
    thisPli,
    daysParams: {
      selectedDay: { lock: true, dailyAmount: +dailyAmount?.toFixed(2) },
      // otherDays: { dailyAmount: +disturbingDaysAmount?.toFixed(2) },
    },
  });

  message?.success(
    "This change has affected the distribution of amounts, please check the other days"
  );

  //this sets the newly created object to the context
  setUnchangingFakeRentals(newRental);
};
