import { Button, Tooltip } from "antd";
import { cloneElement } from "react";

const ErrorButton = ({
  children,
  disabled = false,
  Icon,
  onClick,
  className = "",
  containerClassName,
  style,
  key,
  buttonStyle = {},
  middleLineStyle = {},
  hasIcon = true,
  ...rest
}) => {
  return (
    <div
      className={`mondayButtonContainer ${containerClassName}`}
      style={style}
      key={key}
    >
      <Button
        style={buttonStyle}
        onClick={onClick}
        className={`mondayButtonStyle ${className}`.trim()}
        disabled={disabled}
        {...rest}
      >
        <div className="mondayButtonText">{children}</div>
        {hasIcon && (
          <div
            className="mondayButtonDivider"
            style={
              !!disabled
                ? { backgroundColor: "transparent !important" }
                : { middleLineStyle }
            }
          />
        )}
        {Icon && cloneElement(Icon, { className: "mondayButtonIcon" })}
      </Button>
    </div>
  );
};

export default ErrorButton;
