import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { AutoComplete, Form, Modal, Tooltip } from "antd";
import { MondayButton } from "../../../../../../commonComponents";
import {
  antdModalProps,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../utils";
import { AnimatedCheckBox } from "../../../../../Fleet/components/InputComponent/components";
import { getRandomColor } from "../../../../../utils";
import { apiRoutes, fetchData } from "../../../../../Fleet/utils";
import { formatObjectSamples } from "../../../../utils";
import { useLocation } from "react-router-dom";
import { requestAllPages } from "../../../../../../../utils/paginationFetchData";
import { LoadableComp } from "../../../../../XComponents";
import { useRecordId, useProgramFields } from "../../../../../../../hooks";
import { uuidRegex } from "../../../../../Documentation/View/documentationViewData";
import { isArray } from "chart.js/helpers";
import { camelCase, capitalize } from "lodash";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { programFields } from "../../../../../../../actions";
import { displayKey } from "./GeneralReportsFiltersModal";
import { TrashIcon, XIcon } from "../../../../../Communication/assets";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import "./Modal.scss";

const RemoveModal = ({ visible, setVisible, isDarkMode }) => {
  let location = useLocation();
  const recordId = useRecordId();
  const formattedPath = location.pathname.replace(uuidRegex, "");
  const { ["Reports Integration Config"]: reportsIntegrationConfig } =
    useProgramFields();
  const { categoryName, filters, partitionKeys } = useMemo(
    () => reportsIntegrationConfig?.[formattedPath] || {},
    [reportsIntegrationConfig, location]
  );
  const [form] = Form.useForm();
  const [keys, setKeys] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedAll, setSelectAll] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const dispatch = useDispatch();

  // console.log(keys)

  const filterFilterKeys = () => {
    const camelCasedCategoryName = camelCase(categoryName).includes("client")
      ? "accounts"
      : camelCase(categoryName).includes("tasks")
      ? "tasksManager"
      : camelCase(categoryName).includes("permit")
      ? "permitdrawings"
      : camelCase(categoryName).includes("inspection")
      ? "inspectionsView/:id"
      : camelCase(categoryName);
    if (!!filters) {
      if (!!filters[camelCasedCategoryName]) {
        return filters[camelCasedCategoryName]?.map(
          (element) => element.formItemName
        );
      } else return [];
    } else {
      return [];
    }
  };

  const handleCancel = () => {
    form.setFieldsValue({});
    setVisible(false);
    setDisabled(false);
    setSelectAll(false);
  };

  const handleDBpush = async (name, tempFilter) => {
    await API.patch(
      "programFields",
      `/programFields/82e0c5b9-ab6e-4dd8-a5ff-581d172ec8bb`,
      {
        body: {
          fieldOptions: {
            ...reportsIntegrationConfig,
            [`/${name}`]: {
              ...reportsIntegrationConfig[`/${name}`],
              filters: tempFilter,
            },
          },
        },
      }
    )
      .then(async () => {
        await API.get("programFields", "/programFields").then((res) => {
          dispatch(programFields(res));
          showSuccessMsg();
        });
        handleCancel();
      })
      .catch((e) => {
        showErrorMsg({ content: e });
      });
  };

  /**
   * * @EneaXharau -> Saving function
   * * takes the keys that are in the form
   * * and filters the tempFilter if they match
   */
  const handleOk = () => {
    const formValues = form.getFieldsValue();
    const formKeys = Object.keys(formValues);
    const category = camelCase(categoryName).includes("client")
      ? "accounts"
      : camelCase(categoryName).includes("tasks")
      ? "tasksManager"
      : camelCase(categoryName).includes("permit")
      ? "permitdrawings"
      : camelCase(categoryName).includes("inspection")
      ? "inspectionsView/:id"
      : camelCase(categoryName);
    let tempFilter = {};
    showLoadingMsg();
    if (formKeys?.some((el) => formValues[el])) {
      formKeys?.map((el) => {
        if (formValues[el]) {
          if (!!tempFilter && !!tempFilter[category]) {
            if (tempFilter[category].length > 1) {
              tempFilter = {
                [category]: tempFilter[category]?.filter((x) => {
                  return x.formItemName !== el;
                }),
              };
            } else {
              tempFilter = { [category]: [] };
            }
          } else {
            tempFilter = {
              [category]: filters[category]?.filter((x) => {
                return x.formItemName !== el;
              }),
            };
          }
        }
      });
      handleDBpush(category, tempFilter);
    } else {
      showErrorMsg({ content: "You need to select at least one" });
    }
  };

  const deleteButtonOpenHandler = useCallback(() => {
    const formValues = form.getFieldsValue();
    const formKeys = Object.keys(formValues);
    if (formKeys?.some((el) => formValues[el] === true)) {
      setDisabled(() => {
        return true;
      });
    } else {
      setDisabled(() => {
        return false;
      });
    }
  }, [disabled]);

  const selectAll = useCallback(() => {
    let formValues = form.getFieldsValue();
    const formKeys = Object.keys(formValues);
    if (selectedAll === false) {
      let selectedAllTemp = formKeys.map((el) => {
        const newObj = { [el]: true };
        return { ...newObj, [el]: true };
      });
      const result = Object.assign({}, ...selectedAllTemp);
      setSelectAll(() => {
        return true;
      });
      form.setFieldsValue(result);
    } else {
      setSelectAll(() => {
        return false;
      });
      form.setFieldsValue({});
    }
  }, [selectedAll]);

  const searchHandler = (e) => {
    let substring = e;
    let filteredData = keys
      ?.map((el) => displayKey(el))
      ?.filter?.((entry) => entry.toLowerCase().includes(substring));
    setInputValue(substring);
    setSearchTerm(filteredData);
    // setStatus(" ");
    substring !== "" ? setFilterActive(true) : setFilterActive(false);
  };

  useEffect(() => {
    setKeys(filterFilterKeys());
  }, [reportsIntegrationConfig]);

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        setVisible,
        title: "Remove Filters",
        wrapClassName: `cardLayoutModal documentationTemplatesModal ${
          isDarkMode && "documentationTemplatesModalDark"
        }`,
        onCancel: handleCancel,
        closeIcon: <XIcon />,
      }}
    >
      <Form {...{ form, preserve: false }}>
        <div className="documentationTemplatesModalContainer">
          {!!keys && keys?.length > 0 && (
            <div className="search-div">
              <AutoComplete
                className="search-comp"
                placeholder="Search filters"
                onChange={(e) => {
                  searchHandler(e);
                }}
              ></AutoComplete>
              <SearchOutlined className="search-logo" />
            </div>
          )}
          <div className="checkboxContainer-123">
            <div className="checkBoxGrid">
              {filterActive
                ? searchTerm.map((key) => {
                    const color = "#FE4C4A";
                    return (
                      <AnimatedCheckBox
                        {...{
                          key: key,
                          label: (
                            <div className="checkboxLabel">
                              <div className="templateLabel">
                                {displayKey(key)}
                              </div>
                              <Tooltip
                                {...{
                                  placement: "right",
                                  color,
                                  mouseEnterDelay: 0.5,
                                  mouseLeaveDelay: 0,
                                }}
                              ></Tooltip>
                            </div>
                          ),
                          form,
                          formItemName: key,
                          containerClassName: "templateCheckbox-123",
                          tooltipVisible: false,
                          primaryStyle: { backgroundColor: color },
                          secondaryStyle: { backgroundColor: "#FFF" },
                          onButtonReveal: deleteButtonOpenHandler,
                          selectedAllCheck: selectedAll,
                        }}
                      />
                    );
                  })
                : keys.map((key) => {
                    const color = "#FE4C4A";
                    return (
                      <AnimatedCheckBox
                        {...{
                          key: key,
                          label: (
                            <div className="checkboxLabel">
                              <div className="templateLabel">
                                {displayKey(key)}
                              </div>
                              <Tooltip
                                {...{
                                  placement: "right",
                                  color,
                                  mouseEnterDelay: 0.5,
                                  mouseLeaveDelay: 0,
                                }}
                              ></Tooltip>
                            </div>
                          ),
                          form,
                          formItemName: key,
                          containerClassName: "templateCheckbox-123",
                          tooltipVisible: false,
                          primaryStyle: { backgroundColor: color },
                          secondaryStyle: { backgroundColor: "#FFF" },
                          onButtonReveal: deleteButtonOpenHandler,
                          selectedAllCheck: selectedAll,
                        }}
                      />
                    );
                  })}
            </div>
          </div>
          <div className="templateModalActionButtons">
            <MondayButton
              {...{
                className: "rejectButton",
                Icon: <CloseOutlined />,
                onClick: handleCancel,
              }}
            >
              Cancel
            </MondayButton>
            {disabled && (
              <MondayButton
                {...{
                  className: "mondayButtonRed",
                  Icon: <TrashIcon />,
                  onClick: handleOk,
                }}
              >
                Delete
              </MondayButton>
            )}
            {!!keys && keys.length > 0 && (
              <MondayButton
                {...{
                  className: "mondayButtonBlue",
                  style: { marginLeft: 28 },
                  onClick: selectAll,
                  Icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.875"
                      height="16.243"
                      viewBox="0 0 22.875 16.243"
                    >
                      <path
                        id="checklist_FILL1_wght400_GRAD0_opsz48"
                        d="M17.9,13.819a.871.871,0,1,1,0-1.742H26.6a.871.871,0,1,1,0,1.742Zm0,9.289a.871.871,0,1,1,0-1.742H26.6a.871.871,0,1,1,0,1.742ZM7.764,15.416l-2.9-2.9a.842.842,0,0,1,0-1.219.786.786,0,0,1,.61-.247.952.952,0,0,1,.61.247l2.293,2.264L12.96,8.971a.786.786,0,0,1,.61-.247A.891.891,0,0,1,14.18,9a.842.842,0,0,1,0,1.219l-5.2,5.2a.842.842,0,0,1-1.219,0Zm0,9.289-2.9-2.9a.842.842,0,0,1,0-1.219.786.786,0,0,1,.61-.247.952.952,0,0,1,.61.247l2.293,2.264,4.587-4.587a.786.786,0,0,1,.61-.247.891.891,0,0,1,.61.276.842.842,0,0,1,0,1.219l-5.2,5.2a.842.842,0,0,1-1.219,0Z"
                        transform="translate(-4.6 -8.724)"
                        fill="#fff"
                      />
                    </svg>
                  ),
                }}
              >
                {selectedAll ? "Deselect" : "Select All"}
              </MondayButton>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default RemoveModal;
