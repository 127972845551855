export const currencyInputProps = {
  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
  controls: false,
  min: 0,
}

export const rowFields = ["units", "ppu", "price", "rental", "description"]

export const generatePricingRowFormNames = (formItemName) =>
  rowFields.map((field) => `${formItemName}-${field}`)
