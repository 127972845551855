import { forceToNumber } from "../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../../../../../../utils";
import Actions from "../../../Service/ServiceContent/Actions/Actions";
import Description from "./GridComponents/Description/Description";
import NoteEditor from "./GridComponents/NoteEditor/NoteEditor";

export const columnDefs = ({
  appendRowInGrid,
  removeRowInGrid,
  serviceLabel,
  estimationId,
  elevation,
  events,
  makeRowTaxable,
  removeRetainage,
}) => [
  {
    headerName: "Id",
    headerTooltip: "Id",
    field: "id",
    flex: 0.5,
    editable: false,
    cellRenderer: ({ value }) => value + 1,
  },
  {
    headerName: "Event",
    headerTooltip: "Event",
    field: "name",
    flex: 5,
    cellRenderer: "EventCellRenderer",
    cellRendererParams: {
      serviceLabel,
      estimationId,
      elevation,
    },
    cellStyle: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      fontSize: "1.025rem",
      fontWeight: "500",
      textOverflow: "ellipsis",
    },
    cellEditor: "EventCellEditor",
    cellEditorParams: {
      events,
    },
  },
  {
    headerName: "Charge Percentage",
    headerTooltip: "Percentage",
    field: "rate",
    flex: 2,
    cellRenderer: "RateCellRenderer",
    cellRendererParams: {
      serviceLabel,
      estimationId,
      elevation,
    },
    cellEditor: "RateCellEditor",
  },
  {
    headerName: "Charge Amount",
    headerTooltip: "Amount",
    field: "amount",
    flex: 2,
    cellRenderer: "AmountCellRenderer",
    cellEditor: "AmountCellEditor",
    getQuickFilterText: () => "",
  },
  {
    headerName: "Difference",
    headerTooltip: "Difference",
    field: "difference",
    flex: 2,
    editable: false,
    cellRenderer: "DifferenceCellRenderer",
    cellRendererParams: {
      serviceLabel,
      estimationId,
      elevation,
    },
  },
  {
    headerName: "Retainage",
    headerTooltip: "Retainage",
    field: "retainage",
    flex: 2,
    editable: false,
    valueFormatter: (params) => formatCurrency(params?.value, "USD"),
  },
  {
    headerName: "Collectable Amount",
    headerTooltip: "Collectable Amount",
    field: "collectable_amount",
    flex: 2,
    editable: false,
    valueFormatter: (params) => formatCurrency(params?.value, "USD"),
  },
  {
    headerName: "Description",
    headerTooltip: "Description",
    flex: 2,
    editable: false,
    cellRendererFramework: ({ data }) => {
      console.log("elevation", { elevation, data });
      const floors = elevation?.jumps
        ?.find((jump) => jump?.name === data?.name)
        ?.floors?.map(({ floor }) => `Floor:${floor}`)
        ?.join(", ");
      console.log("floors", floors, data);
      return (
        <Description
          {...{ value: floors, serviceLabel, elevation, eventId: data?.id }}
        />
      );
    },
  },
  {
    headerName: "Tax Amount",
    headerTooltip: "Tax Amount",
    field: "taxAmount",
    flex: 3,
    editable: false,
    valueFormatter: ({ value, data }) =>
      formatCurrency(data?.taxAmount || 0, "USD"),
    // valueGetter: ({ data }) => {
    //   data.taxAmount = forceToNumber(
    //     data?.amount / (data?.custom_tax_amount + 1)
    //   );
    //   return data?.taxAmount;
    // },
    cellStyle: {
      fontSize: "1.025rem",
      fontWeight: "700",
    },
  },
  {
    headerName: "Note",
    headerTooltip: "Note",
    field: "note",
    flex: 2,
    cellRendererFramework: ({ value, data }) => (
      <NoteEditor {...{ value, serviceLabel, elevation, eventId: data?.id }} />
    ),
  },
  {
    headerName: "Actions",
    field: "id",
    headerTooltip: "Actions",
    flex: 2.5,
    editable: false,
    cellRendererFramework: ({ value, data = {} }) => {
      return (
        <div>
          <Actions
            {...{
              rowId: value,
              data,
              appendRowInGrid,
              removeRowInGrid,
              makeRowTaxable,
              removeRetainage,
            }}
          />
        </div>
      );
    },
  },
];
