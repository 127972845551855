import { getMonthsTimestamps } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const violations = (circleProgressPicker, IdHandler, updatedDynamicStates) => {
  return {
    amount_due: () => ({
      title: "Violations Amount",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Violations",
        field: "amount_due",
        content: circleProgressPicker,
        color: "green",
        percent: (
          ((() => {
            const filteredArray = updatedDynamicStates[IdHandler]?.filter(
              (b) =>
                b.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
                b.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
            )?.map((a) => a.amount_due)

            return !!filteredArray?.length
              ? filteredArray?.reduce(
                  (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue)
                )
              : 0
          })() /
            (() => {
              const filteredArray = updatedDynamicStates[IdHandler]?.filter(
                (b) => b?.issue_date >= getMonthsTimestamps()[0]
              )?.map((a) => a?.amount_due)

              return !!filteredArray?.length
                ? filteredArray?.reduce(
                    (previousValue, currentValue) =>
                      parseInt(previousValue) + parseInt(currentValue)
                  )
                : 0
            })()) *
          100
        ).toFixed(1),
        showInfo: true,
        status: "Amount done in " + circleProgressPicker,
        data: (() => {
          const filteredArray = updatedDynamicStates[IdHandler]?.filter(
            (b) =>
              b.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
              b.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
          )?.map((a) => a.amount_due)

          return !!filteredArray?.length
            ? filteredArray?.reduce(
                (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue)
              )
            : 0
        })(),
      },
      id: IdHandler,
      width: "390px",
    }), //amount_due
    issue_date: () => ({
      title: "Violations Issue Date",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Violations",
        field: "issue_date",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
              a?.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
          ).length /
            updatedDynamicStates[IdHandler]?.filter(
              (a) => a?.issue_date >= getMonthsTimestamps()[0]
            ).length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: "Issued in " + circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.issue_date >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.issue_date <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length,
      },
      id: IdHandler,
      width: "390px",
    }), //issue_date
  } //Violations
}
