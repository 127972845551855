import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const estimations = ({ programFields, tables }) => {
  return {
    estSTATUS: () => ({
      labels: programFields
        ?.find((a) => a?.fieldName === "Estimation Status")
        ?.fieldOptions?.map((a) => a.statusName),
      data: programFields
        ?.find((a) => a?.fieldName === "Estimation Status")
        ?.fieldOptions?.map((a) => a.statusName)
        ?.map(
          (status) =>
            tables?.Estimations?.filter(
              (a) => a.estSTATUS?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "Status colors")
        ?.fieldOptions?.Estimations?.map((a) => a.statusColor),
    }), //estSTATUS
    archAssignedTo: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Estimations, "archAssignedTo")
      ),
      data: Object.values(
        repeatedElementSum(tables?.Estimations, "archAssignedTo")
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.Estimations, "archAssignedTo")
      )?.map((a) => getRandomColor()),
    }), //archAssignedTo
    engAssignedTo: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Estimations, "engAssignedTo")
      ),
      data: Object.values(
        repeatedElementSum(tables?.Estimations, "engAssignedTo")
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.Estimations, "engAssignedTo")
      )?.map((a) => getRandomColor()),
    }), //engAssignedTo
    statusByArch: () => ({
      labels: programFields
        ?.find((a) => a?.fieldName === "Status by Architecture")
        ?.fieldOptions?.map((a) => a.statusName),
      data: programFields
        ?.find((a) => a?.fieldName === "Status by Architecture")
        ?.fieldOptions?.map((a) => a.statusName)
        ?.map(
          (status) =>
            tables?.Estimations?.filter(
              (a) => a.statusByArch?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "Status by Architecture")
        ?.fieldOptions?.map((a) => getRandomColor()),
    }), //statusByArch
    statusByEng: () => ({
      labels: programFields
        ?.find((a) => a?.fieldName === "Status by Engineering")
        ?.fieldOptions?.map((a) => a.statusName),
      data: programFields
        ?.find((a) => a?.fieldName === "Status by Engineering")
        ?.fieldOptions?.map((a) => a.statusName)
        ?.map(
          (status) =>
            tables?.Estimations?.filter(
              (a) => a.statusByEng?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "Status by Engineering")
        ?.fieldOptions?.map((a) => getRandomColor()),
    }), //statusByEng

    projectExecutive: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Estimations, "projectExecutive")
      ),
      data: Object.values(
        repeatedElementSum(tables?.Estimations, "projectExecutive")
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.Estimations, "projectExecutive")
      )?.map((a) => getRandomColor()),
    }),

    projectManager: () => ({
      labels: Object.keys(
        repeatedElementSum(
          tables?.Estimations?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      ),
      data: Object.values(
        repeatedElementSum(
          tables?.Estimations?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(
          tables?.Estimations?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      )?.map((a) => getRandomColor()),
    }),
  }; //Estimations,
};
