import { useState } from "react";

import LinkExistingUserModal from "../Modals/LinkExistingUserModal";
import CreateUserModal from "../Modals/CreateUserModal";
import { MondayButton } from "../../../../../../commonComponents";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
import { ReactComponent as WarningTriangle } from "../../../../src/warningTriangle.svg";
import { ReactComponent as ConnectedWhite } from "../../../../../../../assets/icons/connectedWhite.svg";
import { useSelector } from "react-redux";

function CardNotLinked({
  rowData,
  dualRoleUser,
  setRowData,
  users,
  editable,
  linkKey,
  endpoint,
  keyId,
  elementName,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [userLinkModalOpen, setUserLinkModalOpen] = useState(false);
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);

  return (
    <>
      <div
        className={`userLinkCardNotLinkedContainer ${
          isDarkMode && "userLinkCardNotLinkedContainerDark"
        }`}
      >
        <div className="warningContainer">
          <WarningTriangle />
          <span>{`${rowData?.[elementName]} is not linked to any existing user!`}</span>
        </div>
        <div className="buttonContainer">
          <MondayButton
            onClick={() => setCreateUserModalOpen(true)}
            disabled={!editable}
            Icon={<AddIcon />}
          >
            <span>Create a new user</span>
          </MondayButton>
          <MondayButton
            Icon={<ConnectedWhite />}
            className="mondayButtonBlue"
            disabled={!editable}
            onClick={() => setUserLinkModalOpen(true)}
          >
            <span>Link an existing user</span>
          </MondayButton>
        </div>
      </div>
      <LinkExistingUserModal
        {...{
          open: userLinkModalOpen,
          onCancel() {
            setUserLinkModalOpen(false);
          },
          users,
          rowData,
          dualRoleUser,
          setRowData,
          linkKey,
          endpoint,
          keyId,
          elementName,
        }}
      />
      <CreateUserModal
        {...{
          open: createUserModalOpen,
          onCancel() {
            setCreateUserModalOpen(false);
          },
          users,
        }}
      />
    </>
  );
}

export default CardNotLinked;
