import { Button } from "antd";
import React from "react";

const SignControllerButtons = ({
  setSignatureModalVisible,
  setSign,
  setWhichSignToChange,
  sign,
  existingSignature = "",
  setEditSignIn,
  signDisabled = false,
  removeDisabled = false,
}) => (
  <div className="vehicleInspectionSignControllers">
    <Button
      onClick={() => {
        setSignatureModalVisible(true);
        !!setWhichSignToChange
          ? setWhichSignToChange({ setFunc: setSign })
          : "";
      }}
      disabled={signDisabled || !!sign}
      type="primary"
    >
      Sign
    </Button>
    {!!sign && (
      <Button
        primary
        onClick={() => {
          setWhichSignToChange({ setFunc: setSign });
          setEditSignIn(true);
          setSignatureModalVisible(true);
        }}
      >
        Edit Signature
      </Button>
    )}
    <Button
      danger
      disabled={removeDisabled || !!!sign}
      onClick={() => setSign(null)}
    >
      Remove Signature
    </Button>
    {!!existingSignature && (
      <Button
        onClick={() => setSign(existingSignature)}
        disabled={!!sign}
        type="primary"
      >
        Use existing
      </Button>
    )}
  </div>
);

export default SignControllerButtons;
