export const dispatching = ({ tables }) => {
  return {
    driverName: () => ({
      statusData: tables?.Dispatching?.map((a) => {
        return {
          label: a?.fleetId,
          value: a?.driverName,
          color: "#ECDE7D",
        }
      }),
    }), //driverName
  }
}
