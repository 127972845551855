import { message } from "antd";
import Swal from "sweetalert2";
import { forceToNumber } from "./checkers";

const formValidation = (state) => {
  console.log("state", state);
  let appliedAmount = forceToNumber(state?.appliedAmount);
  let currentlyAppliedAmount = state?.invoices?.reduce((acc, obj) => {
    return acc + forceToNumber(obj?.invoicePaidAmount);
  }, 0);
  if (appliedAmount < currentlyAppliedAmount) {
    Swal.fire({
      title: "Warning",
      text: "The transaction is not in balance. Make sure the amounts in the detail area on the form for this transaction equal the amount at the top of the form.",
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Thanks for the warning! I'll fix it.",
    });
    return false;
  } else if (!state.accountName) {
    message.error("Please enter an account name.");
    return false;
  } else if (!state.paymentDate) {
    message.error("Please select a payment date.");
    return false;
  } else if (!state.appliedAmount) {
    message.error("Please enter an applied amount.");
    return false;
  } else if (!state.paymentMethod) {
    message.error("Please select a payment method.");
    return false;
  } else if (!state.invoices || state.invoices.length === 0) {
    message.error("Please select at least one invoice.");
    return false;
  } else {
    return true;
  }
};

export default formValidation;
