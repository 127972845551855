import React, { useState } from "react";

import {
  OtherModalWrapperProvider,
  useOtherModalContext,
} from "./OtherModalsWrapperContext";
import { useRedux } from "../../../../../../hooks/useRedux";
import { useCreateChargeContext } from "../context/NewChargeItemContext";
import Report from "../../../../../../DynamicView/Pages/Overview/components/Report/Report";
import ProgressComponent from "../../../../../../../commonComponents/ProgressComponent/ProgressComponent";
import { useProgressContext } from "../../../../../../../commonComponents/ProgressComponent/context/ProgressContext";
import EmailBox from "../../../../../../Communication/components/EmailBox/EmailBox";
import { openEmailBox } from "../../../../../../Communication/functions";

const OtherModalsWrapper = ({ children }) => {
  return (
    <OtherModalWrapperProvider>
      <ModalsWrapper>{children}</ModalsWrapper>
    </OtherModalWrapperProvider>
  );
};

export default OtherModalsWrapper;

export const ModalsWrapper = ({ children }) => {
  const [objectData, setVisible] = useRedux("chargeItemsModal");
  const [attachments, setAttachments] = useState([]);
  const [emailBoxes, setEmailBoxes] = useState([]);

  const { chargeToSave, removePreviousDataOnDestroy, nextStepHandler } =
    useCreateChargeContext();
  const { visibleReport, setVisibleReport } = useOtherModalContext();

  const currentCharge = objectData?.currentCharge;

  const {
    creationProgresses,
    visibleCreationProgress,
    setVisibleCreationProgress,
  } = useProgressContext();

  return (
    <>
      {children}

      {visibleReport && (
        <Report
          {...{
            isModalVisible: visibleReport,
            onCancel: () => setVisibleReport(false),
            customCategoryName: "Charges Preview",
            customPartitionKeys: { identityId: "chargeId" },
            recordId: chargeToSave.chargeId,
            setVisibleReport,
            emailBoxes,
            setEmailBoxes,
            setAttachments,
            sendByEmail: true,
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Charges",
            actionType: currentCharge ? "Update" : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisible();
              setVisibleCreationProgress(false);
              nextStepHandler();
              removePreviousDataOnDestroy();
            },
          }}
        />
      )}

      {emailBoxes.length > 0 && (
        <div style={{ zIndex: 9994 }}>
          <EmailBox
            {...{
              rowData: {},
              onClose: () => {
                if (attachments.length > 0) setAttachments([]);
                setEmailBoxes([]);
              },
              attachments,
              openEmailBox,
            }}
          />
        </div>
      )}
    </>
  );
};
