import axios from "axios";
import { GOOGLE_API_KEY } from "../../../../helpers/constants/googleScopes";
import { useEffect, useState } from "react";

export const useAutoGenerateLatLng = (initialValueOfState, address) => {
  const [resolvedLocation, setResolvedLocation] = useState(initialValueOfState);

  const generateLatLngBasedOnAddress = async () => {
    try {
      const API_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${GOOGLE_API_KEY}`;

      const response = await axios.get(API_URL);
      const location = response?.data?.results?.[0]?.geometry?.location;

      if (!location) {
        console.log("Location not found");
      }

      return location;
    } catch (error) {
      console.error(error);
    }
  };

  const shouldGenerateLatLngBasedInProjectAddress = Object.values(
    resolvedLocation
  ).some((el) => !el);

  useEffect(() => {
    if (shouldGenerateLatLngBasedInProjectAddress) {
      generateLatLngBasedOnAddress(address).then((res) =>
        setResolvedLocation(res)
      );
    }
  }, [initialValueOfState, address]);

  return { resolvedLocation, generateLatLngBasedOnAddress };
};
