/**
 * Closes the modal and performs various actions based on the provided parameters.
 *
 * @param {Object} form - The form object.
 * @param {Object} [param={}] - Additional parameters.
 * @param {Object} driveRequest - The drive request object.
 * @param {string} [nameKey=""] - The name key.
 * @param {Object} [rowObject={}] - The row object.
 * @param {string} [objectKey=""] - The object key.
 * @param {Object} [driveFunctions={}] - The drive functions object.
 * @param {Array} [filesToBeDeleted=[]] - The files to be deleted.
 * @param {Function} [setVisible=()=>{}] - The function to set the visibility of the modal.
 * @param {string} [folderDriveKeyName=""] - The folder drive key name.
 * @param {Function} [setUploadedFiles=()=>{}] - The function to set the uploaded files.
 * @param {Function} [updateDocumentTitle=()=>{}] - The function to update the document title.
 * @param {Function} [setFilesToBeDeleted=()=>{}] - The function to set the files to be deleted.
 * @param {Function} [setCancelModalVisible=()=>{}] - The function to set the visibility of the cancel modal.
 * @param {Function} [saveAddedLogs=async()=>{}] - The function to save added logs.
 * @returns {Promise<void>} A promise that resolves when all actions are completed.
 */
async function onCloseModal({
  form,
  param = {},
  driveRequest,
  nameKey = "",
  rowObject = {},
  objectKey = "",
  driveFunctions = {},
  filesToBeDeleted = [],
  setVisible = () => {},
  folderDriveKeyName = "",
  setUploadedFiles = () => {},
  updateDocumentTitle = () => {},
  setFilesToBeDeleted = () => {},
  setCancelModalVisible = () => {},
  saveAddedLogs = async () => {},
}) {
  const {
    cancel = false,
    deleteAction = false,
    deletePostLogsBody = {},
  } = param || {};

  if (deleteAction) {
    if (driveFunctions?.parentFolderId) {
      driveFunctions
        ?.delete()
        ?.then(() => {
          if (Object.keys(deletePostLogsBody).length > 0) {
            saveAddedLogs({
              ...deletePostLogsBody,
              recordName: rowObject?.[objectKey]?.[nameKey] + "Drive Folder",
            });
          }
        })
        .catch((err) => {
          console.error("Error Deleting Drive Folder: ", err);
        });
    } else {
      driveRequest
        ?.deleteDriveItem(rowObject?.googleDriveFolderIds?.[folderDriveKeyName])
        ?.then(() => {
          if (Object.keys(deletePostLogsBody).length > 0) {
            saveAddedLogs({
              ...deletePostLogsBody,
              recordName: rowObject?.[objectKey]?.[nameKey] + "Drive Folder",
            });
          }
        });
    }
  }
  if (cancel || deleteAction) {
    Promise.allSettled(
      (filesToBeDeleted || [])?.map(
        async (id) => await driveRequest.deleteDriveItem(id)
      )
    )?.then(() => {
      console.log("DELETED FILES");

      setFilesToBeDeleted([]);
    });
  }

  driveFunctions?.reset();
  form.resetFields();
  setCancelModalVisible(false);
  updateDocumentTitle();

  setVisible(false);
  setUploadedFiles([]);
}

export default onCloseModal;
