import { QuestionCategoryCard } from "..";

const QuestionCardWHeader = ({
  type,
  serviceQuestions,
  parentId,
  accessToken,
  form,
  setVisible,
  images,
  setShownImages,
  isDraft,
  onPickerSuccess,
  inspectionType,
  style = {},
  isEditable = true,
  inspectionApplicantLicense,
  setChanges,
  isDarkMode,
  setNewImagesCount,
  setIsAnswerChanged,
  onSaveLogs,
}) => {
  const getGeneralInformationFirst = (array) => {
    let generalInformationObj = null;
    const filteredArray = [];

    for (const obj of array) {
      if (obj.categoryName.includes("General Information")) {
        generalInformationObj = obj;
      } else {
        filteredArray.push(obj);
      }
    }

    if (generalInformationObj) {
      filteredArray.unshift(generalInformationObj);
    }

    return filteredArray;
  };

  return (
    <div className="serviceCard">
      <div className="questionaryServiceName" {...{ style }}>
        {typeof type === "object" ? type?.serviceType : type}
      </div>
      {getGeneralInformationFirst(serviceQuestions)?.map(
        ({ categoryName, categoryId, questions }, i) => (
          <QuestionCategoryCard
            key={i}
            {...{
              accessToken,
              parentId,
              categoryId,
              categoryName,
              questions,
              form,
              setVisible,
              images,
              setShownImages,
              isDraft,
              onPickerSuccess,
              inspectionTypeProp: inspectionType,
              style,
              isEditable,
              type,
              inspectionApplicantLicense,
              setChanges,
              isDarkMode,
              setNewImagesCount,
              setIsAnswerChanged,
              onSaveLogs,
            }}
          />
        )
      )}
    </div>
  );
};

export default QuestionCardWHeader;
