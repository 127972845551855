import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  createContext,
} from "react";
import { message } from "antd";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";

import TableHeader from "./TableHeader";
import { useRedux } from "../../../../../hooks";
import { defaultProductRow } from "../../utils/constants";
import { columnDefs, groupColumn } from "./utils/columnDefs";
import { getRowNodeId, updateCellValue } from "./utils/helpers";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";
import { useInvoiceFormDataContext } from "../../context/InvoiceFormDataContext";
import { DescriptionModal } from "../../../../../Projects/Accounting/Charges/components";
import { frameworkComponents } from "../../../../../Projects/Accounting/Applications/ApplicationView/components/ServiceList/GridData";

import "./CreateInvoiceTable.scss";

export const GridRef = createContext({});

// DefaultColDef sets props common to all Columns
const defaultColDef = {
  resizable: true,
  enableColResize: true,
  enableRowGroup: true,
  sortable: true,
  flex: 1,
  filter: true,
  suppressSizeToFit: true,
  minWidth: 130,
};

export function CreateInvoiceTable({ servicesInRef }) {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [autoRow, setAutoRow] = useState(
    window.localStorage.getItem("autoRow") === "true" ? true : false
  );
  const [gridApi, setGridApi] = useState();

  const [isWritable] = useRedux("invoiceIsWritable");
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { formData } = useInvoiceFormDataContext();

  const {
    setProducts: setRowData,
    products: rowData,
    invoiceData,
  } = useCreateInvoiceContext();

  useEffect(() => {
    const numberOfGroups = Array?.from(
      new Set(rowData?.map(({ group }) => group))
    );
    if (numberOfGroups?.length > 1) {
      const setCol = (prev) => {
        if (!prev?.some(({ field }) => field === "group")) {
          return [
            ...prev?.slice(0, 2),
            groupColumn,
            ...prev?.slice(2, prev?.length),
          ];
        }
        return cloneDeep(prev);
      };
      gridApi?.setColumnDefs(setCol(columnDefs));
    } else {
      gridApi?.setColumnDefs([
        ...columnDefs?.filter(({ field }) => field !== "group"),
      ]);
    }
  }, [rowData]);

  //To add 1 row below
  const onLastRowClick = (e) =>
    e.rowIndex + 1 === rowData.length && addNewRow();

  // Add new row in Invoice details table
  const addNewRow = () =>
    //Add a new product row with default values at the end
    setRowData((prev) => [
      ...prev,
      { ...defaultProductRow, id: prev.length + 1 },
    ]);

  const cellEditingStarted = (params) => {
    if (!isWritable && invoiceData) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }

    if (
      (params?.data?.category === "applications" ||
        params?.data?.includedRequisitionId) &&
      params.colDef.field === "amount"
    ) {
      params.api.stopEditing(true);
      return message.error(
        "Amount of items from requisitions can not be changed."
      );
    }
  };

  //Used when the amount cell changes so it reflect the changes in tax amount
  const onCellEditingStopped = (props) => {
    if (!isWritable && invoiceData) return;
    if (
      props?.data?.category === "applications" ||
      props?.data?.includedRequisitionId
    ) {
      return;
    }
    const key = props.colDef.field;

    setRowData((prev) => updateCellValue(prev, props, key, formData));
  };

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agSelectedRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" },
      ],
    };
  }, []);

  /*↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑*/

  return (
    <div
      className={
        isDarkMode ? "productsTableContainer-dark" : "productsTableContainer"
      }
    >
      <TableHeader
        {...{
          autoRow,
          addNewRow,
          setRowData,
          setAutoRow,
          servicesInRef,
        }}
      />

      <div
        className={`${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        } documentationsTable agGridContainer createInvoiceTable`}
        id="agGridId"
        style={{
          marginTop: 0,
          marginLeft: 0,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "90%",
        }}
      >
        <GridRef.Provider value={gridRef}>
          <AgGridReact
            onGridReady={({ api }) => setGridApi(api)}
            // key={key}
            rowHeight={36}
            components={frameworkComponents}
            getRowNodeId={getRowNodeId}
            // ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows\
            enableRangeSelection={true}
            statusBar={statusBar}
            onRowClicked={autoRow ? onLastRowClick : () => {}}
            pagination={false}
            rowBuffer={5}
            suppressContextMenu={true}
            suppressCellSelection={isWritable}
            onCellEditingStopped={onCellEditingStopped}
            onCellClicked={cellEditingStarted}
            suppressDragLeaveHidesColumns={true}
          />
        </GridRef.Provider>
      </div>
      <DescriptionModal readOnly={true} />
    </div>
  );
}
