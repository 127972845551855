/**
 * @author Jon Shkreli
 * This is simple configuration for a service. Can be used for all services Sidewalk Shed, Barriers, etc
 *
 * @property label {String}
 * @property visible {boolean}
 * @property serviceId {Number} - from 1 to 20
 * @property serviceOptions {[[]]} - The first one is called "Primary Alternative" option that contains multiple "Elevations".
 * Other elements, are called "Alternative options" may have multiple "Elevations".
 * @property serviceAddons {Addon[]} - A list with some addons which belong to this service. Addons may have different columns from one service to another. They are defined in front-end app, in Settings > Pricing
 * @property documentationAddons {Addon[]} - Another list of addons
 * @property rent {Number} - some services need rent defined in service. It is a percentage number. So when writing 5, it means that it will be used to calculate 5% from price.
 * @property pricePerUnit {Number} - similar to rent, pricePerUnit or just ppu may be defined for service and it is used for calculations.
 * */
export class Service {
  label;
  visible = true;
  serviceId;
  serviceOptions = [[]];
  serviceAddons = [];
  documentationAddons = [];
  rent; //do we need it to 7% (0.07) by default
  pricePerUnit;
  isTaxable;
  isScope;
  taxRate;
  isHoist;
  columnDefinitions = [];

  constructor(
    serviceId,
    label,
    visible,
    taxRate = 0,
    isTaxable = true,
    isScope = false,
    isHoist = false,
    columnDefinitions = []
  ) {
    this.serviceId = serviceId;
    this.label = label;
    this.visible = visible;
    this.taxRate = taxRate;
    this.isTaxable = isTaxable;
    this.isScope = isScope;
    this.isHoist = isHoist;
    this.columnDefinitions = columnDefinitions;
  }

  /**
   * When services comes from database, it may be necessary to make some parsing.
   * @param servicesFromDatabase {Object[]}
   * @return Service[]
   * */
  static parseServicesForFrontend(servicesFromDatabase, currEst) {
    return servicesFromDatabase.map((s) => {
      let service = new Service(s.serviceId, s.label, s.visible, s?.taxRate);
      service = {
        ...service,
        ...s,
        estimationNumber: currEst?.estimationNumber || "",
        estimationId: currEst?.estimationId,
        isChangeOrder: currEst?.isChangeOrder || false,
        quickbooksNumber: currEst?.quickbooksNumber || "",
      };
      return service;
    });
  }
}

export const checkIfServiceIsHoist = (service) => {
  return service?.serviceId?.toString() === "3" || service?.isHoist === true;
};
