export function engineerFields(isDarkMode = false, salaryAccess = {}) {
  return [
    {
      label: "First Name",
      type: "input",
      placeholder: "First Name...",
      formItemName: "firstName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Last Name",
      type: "input",
      placeholder: "Last Name...",
      formItemName: "lastName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Email",
      type: "input",
      placeholder: "example@email.com",
      formItemName: "userName",
      className: "crewNameInput",
      required: true,
    },
    salaryAccess.write && {
      label: "Pay Rate",
      type: "number",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      className: "crewNameInput",
      prefix: "$",
      min: 0,
      required: true,
    },
    salaryAccess.write && {
      label: "Salary Type",
      type: "select",
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
  ];
}

export function architectFields(isDarkMode = false, salaryAccess = {}) {
  return [
    {
      label: "First Name",
      type: "input",
      placeholder: "First Name...",
      formItemName: "firstName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Last Name",
      type: "input",
      placeholder: "Last Name...",
      formItemName: "lastName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Email",
      type: "input",
      placeholder: "example@email.com",
      formItemName: "userName",
      className: "crewNameInput",
      required: true,
    },
    salaryAccess.write && {
      label: "Pay Rate",
      type: "number",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      className: "crewNameInput",
      prefix: "$",
      min: 0,
      required: true,
    },
    salaryAccess.write && {
      label: "Salary Type",
      type: "select",
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
  ];
}

export function driverFields({
  driverRatingOptions,
  driverStatus,
  teams,
  onTeamSelect,
}) {
  return [
    {
      label: "Full Name",
      type: "input",
      placeholder: "Full Name",
      formItemName: "driverName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Employee Id",
      type: "input",
      placeholder: "Employee Id",
      formItemName: "employeeId",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Rating",
      type: "select",
      placeholder: "Select Drivers Rating...",
      formItemName: "driverRating",
      customOptions: (driverRatingOptions || []).map(
        ({ statusName }, index) => ({
          key: index,
          label: statusName,
          value: statusName,
        })
      ),

      className: "crewNameInput",
      required: true,
    },
    {
      label: "Status",
      type: "select",
      placeholder: "Select Driver's Status...",
      formItemName: "driverStatus",
      customOptions: (driverStatus || []).map(({ statusLabel }, index) => ({
        key: index,
        label: statusLabel,
        value: statusLabel,
      })),
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Driver Type",
      type: "select",
      placeholder: "Select Driver's Type...",
      formItemName: "driverType",
      customOptions: [
        { key: 0, label: "CDL", value: "CDL" },
        { key: 1, label: "Other", value: "Other" },
      ],
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Team",
      type: "select",
      placeholder: "Select team...",
      formItemName: "driverTeam",
      customOptions: teams?.map((team) => ({
        label: team.teamName,
        value: team.teamName,
        members: team.members?.map(
          ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
            identityId,
            nameOfUser,
            cognitoUserId,
          })
        ),
      })),
      onSelect: onTeamSelect,
      className: "crewNameInput",
      required: true,
    },
  ];
}
