import { Checkbox } from "antd";
import { useSelector } from "react-redux";

import "./CheckboxCard.scss";

const CheckboxCard = (props) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      className={`CheckboxCardMember ${
        isDarkMode ? "CheckboxCardMemberDark" : ""
      }`}
    >
      <Checkbox
        id={props.identityId}
        className="CheckboxCardMember__checkbox"
        checked={props.value}
        onChange={(e) => props.onChange(e.target.checked, props.identityId)}
      >
        {props.name && props.name}
      </Checkbox>
    </div>
  );
};

export default CheckboxCard;
