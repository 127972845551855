import types from "../types";

const preferences = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_PREFERENCES,
    payload: attributes,
  });
};

const preferencesBasePageFilters = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_BASE_PAGE_FILTERS,
    payload: attributes,
  });
};

const updatePreferences = (attributes) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PREFERENCES,
    payload: attributes,
  });
};

export { preferences, preferencesBasePageFilters, updatePreferences };
