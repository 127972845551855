import { message } from "antd";
import moment from "moment";
import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../../../../../Estimations/DataEntryGrid/models/Service";

export const calculateTotalsReqs = (application) => {
  let CURRENT_PAYMENT = 0;
  let CURRENT_RETAINAGE = 0;
  let ACCUMULATED_RETAINAGE = 0;
  let THIS_PERIOD = 0;
  // TO use for AccumulatedRentals
  let ACC_RENTALS_CURRENT_PAYMENT = 0;
  console.log("new structure", application);
  try {
    let changedDateStructure = moment("11/01/2023", "MM/DD/YYYY").valueOf();
    let createdReqs = application?.createdAt || [];
    let isItBefore = false;
    // First we consider services in the Requisitions. we have 2: Hoist or Regular Service.
    // Hoist Service differs because of the structure of the SOV.
    if (isItBefore) {
      // message.warning(
      //   "This application was created before the new requisition structure. Please, update the application to the new structure."
      // );
      return {
        CURRENT_PAYMENT: application?.totalities?.currentPaymentDue || 0,
        CURRENT_RETAINAGE: application?.totalities?.thisRetainage || 0,
        ACCUMULATED_RETAINAGE: application?.totalities?.totalRetainage || 0,
        THIS_PERIOD: application?.totalities?.currentPeriod || 0,
      };
    } else {
      let SERVICES = application?.services || [];
      let CURRENT_RENTALS = application?.includedRentals || [];
      let ACCUMULATED_RENTALS = structuredClone(
        application?.accumulatedRentals || {}
      );
      let NEW_CHAINED_RENTALS = structuredClone(
        application?.chainedRentalsIds || {}
      );
      if (Array.isArray(SERVICES)) {
        SERVICES.forEach((service) => {
          // Check if it is hoist!
          if (checkIfServiceIsHoist(service)) {
            // TODO: Hoist Service
            service?.serviceOptions[0].forEach((option) => {
              let AMOUNTS = option?.amounts || {};
              if (Array.isArray(AMOUNTS)) {
                AMOUNTS.forEach((amount) => {
                  CURRENT_PAYMENT += forceToNumber(amount?.paymentDue);
                  if (!amount?.keptRetainage) {
                    CURRENT_RETAINAGE += forceToNumber(
                      amount?.thisKeptRetainage
                    );
                    ACCUMULATED_RETAINAGE += forceToNumber(
                      amount?.accRetainageBalance
                    );
                  } else {
                    CURRENT_RETAINAGE += forceToNumber(amount?.keptRetainage);
                    ACCUMULATED_RETAINAGE += forceToNumber(
                      amount?.keptRetainage
                    );
                    ACCUMULATED_RETAINAGE += forceToNumber(
                      amount?.carriedRetainage
                    );
                  }
                  THIS_PERIOD += forceToNumber(amount?.thisPeriod);
                });
              }
            });
          } else {
            let AMOUNTS = service?.amounts || {};
            if (Array.isArray(AMOUNTS)) {
              AMOUNTS.forEach((amount) => {
                CURRENT_PAYMENT += forceToNumber(amount?.paymentDue);
                // CURRENT_RETAINAGE += forceToNumber(amount?.thisKeptRetainage);
                // if (!amount?.keptRetainage) {
                CURRENT_RETAINAGE += forceToNumber(amount?.thisKeptRetainage);
                ACCUMULATED_RETAINAGE += forceToNumber(
                  amount?.accRetainageBalance
                );
                // }
                // else {
                //   message.warning("This is a hoist service" );
                //   CURRENT_RETAINAGE += forceToNumber(amount?.keptRetainage);
                //   ACCUMULATED_RETAINAGE += forceToNumber(amount?.keptRetainage);
                //   ACCUMULATED_RETAINAGE += forceToNumber(
                //     amount?.carriedRetainage
                //   );
                // }
                THIS_PERIOD += forceToNumber(amount?.thisPeriod);
              });
            }
          }
        });
      }
      if (Array.isArray(CURRENT_RENTALS)) {
        CURRENT_RENTALS.forEach((rental) => {
          let RENTAL_SERVICES = rental?.services || [];
          if (Array.isArray(RENTAL_SERVICES)) {
            RENTAL_SERVICES.forEach((service) => {
              // console.log("RENTALSERVICE", { service });
              CURRENT_PAYMENT += forceToNumber(service?.currentPayment);
              CURRENT_RETAINAGE += forceToNumber(service?.thisKeptRetainage);
              ACCUMULATED_RETAINAGE += forceToNumber(
                service?.accRetainageBalance || 0
              );
              THIS_PERIOD += forceToNumber(service?.thisPeriod || 0);
            });
          }
        });
      }
      if (Array.isArray(NEW_CHAINED_RENTALS)) {
        NEW_CHAINED_RENTALS.forEach((rental) => {
          let RENTAL_SERVICES = rental?.services || [];
          if (Array.isArray(RENTAL_SERVICES)) {
            RENTAL_SERVICES.forEach((service) => {
              // console.log("RENTALSERVICE", { service });
              CURRENT_PAYMENT += forceToNumber(service?.currentPayment);
              CURRENT_RETAINAGE += forceToNumber(service?.thisKeptRetainage);
              ACCUMULATED_RETAINAGE += forceToNumber(
                service?.accRetainageBalance || 0
              );
              THIS_PERIOD += forceToNumber(service?.thisPeriod || 0);
            });
          }
        });
      }
      if (!!ACCUMULATED_RENTALS) {
        Object.values(ACCUMULATED_RENTALS)?.forEach((app) => {
          Object.values(app)?.forEach((rental) => {
            Object.values(rental)?.forEach((service) => {
              // console.log("RENTALSERVICE", { service });
              CURRENT_PAYMENT += forceToNumber(service?.thisDistRetainage);
              ACC_RENTALS_CURRENT_PAYMENT += forceToNumber(
                service?.thisDistRetainage
              );
              ACCUMULATED_RETAINAGE += forceToNumber(
                service?.accRetainageBalance || 0
              );
              // THIS_PERIOD += forceToNumber(service?.thisPeriod || 0); //this period is zero
            });
          });
        });
      }
      return {
        CURRENT_PAYMENT,
        CURRENT_RETAINAGE,
        ACCUMULATED_RETAINAGE,
        THIS_PERIOD,
        ACC_RENTALS_CURRENT_PAYMENT,
      };
    }
  } catch (e) {
    console.log("calculateTotalsReqs", { e });
    return {
      CURRENT_PAYMENT,
      CURRENT_RETAINAGE,
      ACCUMULATED_RETAINAGE,
      THIS_PERIOD,
      ACC_RENTALS_CURRENT_PAYMENT,
    };
  }
};
