import { useMemo } from "react";
import "./MobileDashboardTable.scss";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import useTranslate from "../../../Language/useTranslate";

function MobileDashboardTable({
  tableData,
  filterKeys,
  linkKey,
  pathName,
  searchValue,
}) {
  const { t } = useTranslate();

  const navigate = useNavigate();

  const filteredData = useMemo(() => {
    if (!tableData) {
      return [];
    }
    if (!filterKeys) {
      return tableData;
    }
    if (!searchValue) {
      return tableData;
    }
    return tableData?.filter((row) => {
      return filterKeys?.some((key) => {
        return row?.[key]?.[key]
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase());
      });
    });
  }, [tableData, searchValue]);

  function navigateToLink(id, data) {
    if (!id) {
      return message.error("No ID found");
    }
    // navigate(`${pathName}/${id}`, { state: { data } });
    navigate(`${pathName}/${id}`);
  }

  return (
    <div className="mobile-table-list">
      {filteredData?.length > 0 ? (
        filteredData.map((item, index) => (
          <div
            data-testid="table-row"
            onClick={() => {
              let id;
              let data;
              Object.keys(item).map((key) => {
                if (key === "linkId") {
                  id = item?.[key]?.[linkKey];
                }
                if (key === "otherKeys") {
                  data = item?.[key];
                }
              });
              navigateToLink(id, data);
            }}
            key={index}
            className="table-row-container"
          >
            <div className="content-container">
              {Object.keys(item).map((key) => {
                if (key === "linkId" || key === "otherKeys") {
                  return null;
                }

                return (
                  <div key={key} className={item?.[key]?.className}>
                    {item?.[key]?.[key]}
                  </div>
                );
              })}
            </div>
            {Object.keys(item).map((key) => {
              if (key === "linkId") {
                return (
                  <div key={key} className={"button-text"}>
                    {t("Open")}
                  </div>
                );
              }
              return null;
            })}
          </div>
        ))
      ) : (
        <div className="no-data-container">
          <p>{t("No projects found")}</p>
        </div>
      )}
    </div>
  );
}

export default MobileDashboardTable;
