import React, { Fragment, useState } from "react";
import { ToDoIcon } from "../../../../../assets";
import "./ToDoButton.scss";
import { MondayButton } from "../../../../commonComponents";
import { Tooltip } from "antd";
import ToDoModal from "../../ToDoModal";

/**
 * This button opens the ToDoModal BasePage component for the record that is passed in as a prop
 *
 * @param {String} recordId - the id of the record we are viewing the to do's for
 * @param {String} recordName - the name of the record we are viewing the to do's for
 * @param {Boolean} iconOnly - whether the button should only show the icon
 * @param {Boolean} hasIcon - whether the button should have an icon
 * @param {String} placement - the placement of the tooltip
 * @param {String} tooltipCategory - the category of the tooltip
 * @param {String} tooltipKey - the key of the tooltip
 * @returns {JSX.Element}
 */
const ToDoButton = ({
  recordId = "",
  recordName = "",
  iconOnly = false,
  hasIcon = true,
  placement = "top",
  tooltipCategory = "",
}) => {
  const [toDoVisible, setToDoVisible] = useState(false);
  return (
    <Fragment>
      {!iconOnly ? (
        <div className="to-do-modal-button">
          <MondayButton
            className="mondayButtonBlue"
            onClick={() => setToDoVisible(true)}
            Icon={hasIcon ? <ToDoIcon /> : null}
            hasIcon={hasIcon}
            tooltipCategory={tooltipCategory}
            tooltipKey="showTodos"
            data-testid="to-do-button"
          >
            To Do
          </MondayButton>
        </div>
      ) : (
        <Tooltip title="To Do" placement={placement}>
          <div className="to-do-modal-button todo-icon-only">
            <MondayButton
              className="mondayButtonBlue"
              onClick={() => setToDoVisible(true)}
              Icon={<ToDoIcon />}
              tooltipCategory={tooltipCategory}
              tooltipKey="showTodos"
              data-testid="to-do-button"
            />
          </div>
        </Tooltip>
      )}
      {!!toDoVisible && (
        <ToDoModal
          {...{
            recordId,
            toDoVisible,
            setToDoVisible,
            recordName,
          }}
        />
      )}
    </Fragment>
  );
};

export default ToDoButton;
