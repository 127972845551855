import { message } from "antd";

/**
 * Function that configures the handlers for the
 * real time fleet live view communications
 * @param {WebSocket} ws The websocket pointer
 * @param {{
 *  pingTimeout: React.MutableRefObject,
 *  externalUpload: Function,
 *  changeDateHandler: Function,
 *  changeRefreshRate: Function,
 *  socketNotification: Function,
 *  externalBatchUpload: Function,
 *  updateProgramFields: Function,
 *  externalUpdateAudit: Function,
 *  handleDispatchChange: Function,
 *  manualRefreshHandler: Function,
 * }} [sessionHandlers]
 */
function wsSessionHandler(ws, sessionHandlers = {}) {
  const {
    pingTimeout,
    externalUpload = () => {},
    changeDateHandler = () => {},
    changeRefreshRate = () => {},
    socketNotification = () => {},
    externalBatchUpload = () => {},
    updateProgramFields = () => {},
    externalUpdateAudit = () => {},
    handleDispatchChange = () => {},
    manualRefreshHandler = () => {},
  } = sessionHandlers;

  let pingId = 0;

  ws.onmessage = ({ data: msg }) => {
    const { request = "", body = {} } = JSON.parse(msg || "{}");

    if (!request) {
      return;
    }

    if (request === "pong") {
      if (body?.pingId === pingId) {
        if (pingId < Number.MAX_SAFE_INTEGER) {
          pingId = pingId + 1;
        } else {
          pingId = 0;
        }
      }
    } else if (request === "create-session") {
      let { config } = body;
      if (config?.date) {
        changeDateHandler(config?.date);
      }
    } else if (request === "date-change") {
      let { date } = body;
      if (!date) {
        return;
      }

      changeDateHandler(date);
      socketNotification({
        message: "changed the date in the fleet live view",
      });
    } else if (request === "update-data") {
      manualRefreshHandler();
      socketNotification({ message: "updated the live data" });
    } else if (request === "plan-change") {
      const { dispatchBody = {}, action } = body;
      handleDispatchChange(dispatchBody, action);
      socketNotification({ message: "made changes to the initial plan" });
    } else if (request === "audit-change") {
      const { audit } = body;
      externalUpdateAudit(audit);
      socketNotification({ message: "made changes to the audit" });
    } else if (request === "rate-change") {
      const { rate } = body;
      changeRefreshRate(rate);
      socketNotification({ message: "changed the refresh timer" });
    } else if (request === "audit-uploaded") {
      externalUpload({ ...body });
      socketNotification({
        message: `uploaded the audit for ${body?.fleetName}}`,
      });
    } else if (request === "field-config") {
      updateProgramFields(body.field, true);
    } else if (request === "batch-upload") {
      externalBatchUpload({ ...body });
      socketNotification({
        message: `uploaded all the COMPLETED audits`,
      });
    }
  };
}

export default wsSessionHandler;
