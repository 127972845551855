export const charges = (
  programFields,
  updatedDynamicStates,
  chargesData,
  IdHandler
) => {
  const data = updatedDynamicStates[IdHandler];

  const chargeStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Charge.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    categoryFrom: () => ({
      title: "Charges - Category From",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Charges",
        field: "categoryFrom",
        labels: chargeStatus?.statusName,
        datasets: [
          {
            label: "Charges",
            data: chargeStatus?.statusName?.map(
              (status) =>
                chargesData?.filter((a) => a?.categoryFrom === status)?.length
            ),
            backgroundColor: chargeStatus?.statusColor,
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
    //totalAmount
  };
};
