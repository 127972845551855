import { cloneDeep } from "lodash";
import { getNYDate } from "../../../../../Projects/Accounting/Applications/ApplicationView/utils/dateFunctions";
import { formatCurrency } from "../../../../../utils";
import { getServices } from "./serviceHelpers";
import { stripHtmlTags } from "../../../../DataEntryGrid/tools/exporters/retrieveDOCX";
import { servicesElevationProcess } from "./servicesElevationProcess";
import moment from "moment-timezone";
import { checkIfServiceIsHoist } from "../../../../DataEntryGrid/models/Service";
export const getProcessedTakeOf = ({ estimation = {}, version = {}, type }) => {
  const processedTakeOff = takeOffProxy(cloneDeep(estimation));
  let temp = {};
  const res = processEstimationTakeOff({ estimation, version, type });
  const result = Object.entries(PROPOSAL_KEY_DICTIONARY).map(([key, value]) => {
    const myType = typeof PROPOSAL_KEY_DICTIONARY[key];
    console.log("myType", estimation);
    switch (myType.toString()) {
      case "string":
        temp[key] = processedTakeOff[value];
        return;
      case "object":
        temp[key] = "";
        return;
      case "function":
        // double checking!
        if (typeof value === "function") {
          temp[key] = value({ estimation: processedTakeOff, version });
          return;
        }
        return;
      default:
        return "";
    }
    temp[key] = processedTakeOff[value];
    console.log("typeOf", {
      t: typeof PROPOSAL_KEY_DICTIONARY[key],
      key,
    });
  });
  console.log("result", temp);
  return res;
};

export const takeOffProxy = (takeOff) =>
  new Proxy(takeOff, {
    get: function (target, prop, receiver) {
      console.log({ target, prop, receiver });
      return target[prop] || null;
    },
    set: function (target, prop, value, receiver) {
      console.log("You can't change the takeOff object");
    },
  });

const pushServicesAsParent = ({ services = {} }) => {
  let temp = {};
  Object?.entries(services)?.map(([key, value]) => {
    Object.entries(value)?.map(([key2, value2]) => {
      temp[`${key}-${key2}`] = value;
    });
  });
  return temp;
};

export const PROPOSAL_KEY_DICTIONARY = {
  // "Estimation ID": "estimationId",
  "Account Manager": "accountManager",
  "Account Name": "accountName",
  "Assigned Architect": "archAssignedTo",
  "Assigned Note": "assignNote",
  "Due Date": (estimation) => getNYDate(estimation?.dueDate).formatted || "",
  "Estimation Number": "estimationNumber",
  "Estimation Status": "estSTATUS",
  "Estimation Charged": "estCharged",
  "JobSite Address": "jobSiteAddress",
  "Is Change Order": "isChangeOrder",
  "Project Executive": "projectExecutive",
  "Estimation Priority": "estimPriority",
  "Estimation Description": "estimationDescription",
  Borough: "borough",
  "Charged Type": ({ estimation }) => {
    return estimation?.fullyCharged === true ? "Fully Charged" : "Not Charged";
  },
  "Estimation Type": ({ estimation }) => {
    return estimation?.isChangeOrder === true
      ? "Change Order"
      : "Regular Estimation";
  },
  "Sign Type": ({ estimation }) => {
    return estimation?.isSigned === true ? "Signed" : "Not Signed";
  },
  "QuickBooks Estimate Number": "quickbooksNumber",
  "Has Requisition": ({ estimation }) => {
    return estimation?.hasRequisition
      ? "It has Requisition"
      : "It doesn't have Requisition";
  },
  // Take Off Fields for Versions
  "Take Off Creation Date": ({ version }) =>
    getNYDate(version?.createdAt).formatted || "",
  "Take Off Created By": ({ version }) => version?.createdBy || "Unknown",
  "Take Off Status": ({ version }) => version?.status || "Unknown",
  "Take Off Version": ({ version }) => version?.versionName || "Unknown",
  "List of Services": ({ version }) => {
    return version?.services?.map((el) => el?.label)?.join(", ");
  },
  "Processed Services": ({ version }) => {
    let temp = {};
    version?.services?.map((el) => {
      temp[el?.label] = getServices({ service: el });
    });
    return temp;
  },

  // ...pushServicesAsParent({
  //   services: this["Processed Services"],
  // }),
};

export const processEstimationTakeOff = ({
  estimation = {},
  version = {},
  type = "process",
}) => {
  let RESULT_OBJECT = {
    "Account Manager": estimation?.accountManager || "",
    "Account Name": estimation?.accountName || "",
    "Assigned Architect": estimation?.archAssignedTo || "",
    "Assigned Note": estimation?.assignNote || "",
    "Due Date": getNYDate(estimation?.dueDate).formatted || "",
    "Estimation Number": estimation?.estimationNumber || "",
    "Estimation Status": estimation.estSTATUS || "",
    "Estimation Charged": estimation?.estCharged || "",
    "JobSite Address": estimation?.jobSiteAddress || "",
    "Is Change Order": estimation?.isChangeOrder || "",
    "Project Executive": estimation?.projectExecutive || "",
    "Estimation Priority": estimation?.estimPriority || "",
    "Estimation Description": estimation?.estimationDescription || "",
    Borough: estimation?.borough || "",
    "Charged Type":
      estimation?.fullyCharged === true ? "Fully Charged" : "Not Charged",
    "Estimation Type":
      estimation?.isChangeOrder === true
        ? "Change Order"
        : "Regular Estimation",
    "Sign Type": estimation?.isSigned === true ? "Signed" : "Not Signed",
    "QuickBooks Estimate Number": "quickbooksNumber",
    "Has Requisition": estimation?.hasRequisition
      ? "It has Requisition"
      : "It doesn't have Requisition",
    // Take Off Fields for Versions
    "Take Off Creation Date": getNYDate(version?.createdAt).formatted || "",
    "Take Off Created By": version?.createdBy || "Unknown",
    "Take Off Status": version?.status || "Unknown",
    "Take Off Version": version?.versionName || "Unknown",
    "List of Services": version?.services?.map((el) => el?.label)?.join(", "),
    "Processed Services": {},
  };

  if (type === "process") {
    let temp = version?.services?.map((el) => {
      RESULT_OBJECT["Processed Services"][el?.label] = processServices({
        service: el,
      });
      return processServices({ service: el });
    });
  } else {
    // For Preview all datas!
    let temp = {};
    version?.services?.map((el) => {
      temp = { ...temp, ...processServices({ service: el }) };
    });
    RESULT_OBJECT = { ...RESULT_OBJECT, ...temp };
  }

  console.log("RESULT OBJ", { RESULT_OBJECT });
  return RESULT_OBJECT;
};

// Grouping label for Services: ServiceName-

export const processServices = ({ service = {} }) => {
  if (checkIfServiceIsHoist(service)) {
    let sOptions = service?.serviceOptions[0]?.[0] || {};
    console.log("sOptions", sOptions);
    const temp = {
      "Is Scope": service?.isScope ? "Is Scope" : "Not Scope",
      "Is Taxable": service?.isTaxable ? "Is Taxable" : "Not Taxable",
      Label: service?.label || "Unknown",
      "Tax Rate": `${parseFloat(service?.taxRate || 0) * 100}%`,
      "Rent Percantage": `${parseFloat(service?.rentRate || 0) * 100}%`,
      "-Includes": getIncludesExcludesFormat({
        type: "includes",
        data: service?.includes ?? [],
        separator: "dash",
      }),
      "#Includes": getIncludesExcludesFormat({
        type: "includes",
        data: service?.includes ?? [],
        separator: "numbers",
      }),
      "-Excludes": getIncludesExcludesFormat({
        type: "excludes",
        data: service?.excludes ?? [],
        separator: "dash",
      }),
      "#Excludes": getIncludesExcludesFormat({
        type: "excludes",
        data: service?.excludes ?? [],
        separator: "numbers",
      }),
      // Elevations: getHoistElevations({
      //   serviceOptions: service?.serviceOptions[0],
      // }),
    };
    temp[`${service?.label}_NOTE`] = service?.note || "";

    let result = {};
    Object.entries(temp)?.map(([key, value]) => {
      result[`${service?.label}-${key}`] = value;
    });
    let elevations = getHoistElevations({
      serviceOptions: service?.serviceOptions[0],
    });
    result = { ...result, ...elevations };

    console.log("hoist loger", { result, elevations });
    return result;
  } else {
    const temp2 = {
      "Is Scope": service?.isScope ? "Is Scope" : "Not Scope",
      "Is Taxable": service?.isTaxable ? "Is Taxable" : "Not Taxable",
      Label: service?.label || "Unknown",
      "Tax Rate": `${parseFloat(service?.taxRate || 0) * 100}%`,
      "Rent Percantage": `${parseFloat(service?.rentRate || 0) * 100}%`,
      "Price Per Unit": formatCurrency(service?.pricePerUnit || 0),
      "-Includes": getIncludesExcludesFormat({
        type: "includes",
        data: service?.includes ?? [],
        separator: "dash",
      }),
      "#Includes": getIncludesExcludesFormat({
        type: "includes",
        data: service?.includes ?? [],
        separator: "numbers",
      }),
      "-Excludes": getIncludesExcludesFormat({
        type: "excludes",
        data: service?.excludes ?? [],
        separator: "dash",
      }),
      "#Excludes": getIncludesExcludesFormat({
        type: "excludes",
        data: service?.excludes ?? [],
        separator: "numbers",
      }),
      // RentalPaymentTerms: getRentalPaymentTerms(service),
    };
    temp2[`${service?.label}_NOTE`] = service?.note || "";
    getRentalPaymentTerms(service, temp2);

    let result2 = {};
    Object.entries(temp2)?.map(([key, value]) => {
      result2[`${service?.label}-${key}`] = value;
    });
    let elevations2 = getOtherServiceElevations({
      serviceOptions: service?.serviceOptions[0],
      service: service?.label,
    });
    result2 = { ...result2, ...elevations2 };
    servicesElevationProcess({
      result: result2,
      service,
      elevation: elevations2,
    });

    return result2;
  }

  ///
  console.log("this is service", service);
  switch (service?.label) {
    case checkIfServiceIsHoist(service):
      let sOptions = service?.serviceOptions[0]?.[0] || {};
      console.log("sOptions", sOptions);
      const temp = {
        "Is Scope": service?.isScope ? "Is Scope" : "Not Scope",
        "Is Taxable": service?.isTaxable ? "Is Taxable" : "Not Taxable",
        Label: service?.label || "Unknown",
        "Tax Rate": `${parseFloat(service?.taxRate || 0) * 100}%`,
        "Rent Percantage": `${parseFloat(service?.rentRate || 0) * 100}%`,
        "-Includes": getIncludesExcludesFormat({
          type: "includes",
          data: service?.includes ?? [],
          separator: "dash",
        }),
        "#Includes": getIncludesExcludesFormat({
          type: "includes",
          data: service?.includes ?? [],
          separator: "numbers",
        }),
        "-Excludes": getIncludesExcludesFormat({
          type: "excludes",
          data: service?.excludes ?? [],
          separator: "dash",
        }),
        "#Excludes": getIncludesExcludesFormat({
          type: "excludes",
          data: service?.excludes ?? [],
          separator: "numbers",
        }),
        // Elevations: getHoistElevations({
        //   serviceOptions: service?.serviceOptions[0],
        // }),
      };
      temp[`${service?.label}_NOTE`] = service?.note || "";

      let result = {};
      Object.entries(temp)?.map(([key, value]) => {
        result[`${service?.label}-${key}`] = value;
      });
      let elevations = getHoistElevations({
        serviceOptions: service?.serviceOptions[0],
      });
      result = { ...result, ...elevations };

      console.log("hoist loger", { result, elevations });
      return result;
      break;
    default:
      const temp2 = {
        "Is Scope": service?.isScope ? "Is Scope" : "Not Scope",
        "Is Taxable": service?.isTaxable ? "Is Taxable" : "Not Taxable",
        Label: service?.label || "Unknown",
        "Tax Rate": `${parseFloat(service?.taxRate || 0) * 100}%`,
        "Rent Percantage": `${parseFloat(service?.rentRate || 0) * 100}%`,
        "Price Per Unit": formatCurrency(service?.pricePerUnit || 0),
        "-Includes": getIncludesExcludesFormat({
          type: "includes",
          data: service?.includes ?? [],
          separator: "dash",
        }),
        "#Includes": getIncludesExcludesFormat({
          type: "includes",
          data: service?.includes ?? [],
          separator: "numbers",
        }),
        "-Excludes": getIncludesExcludesFormat({
          type: "excludes",
          data: service?.excludes ?? [],
          separator: "dash",
        }),
        "#Excludes": getIncludesExcludesFormat({
          type: "excludes",
          data: service?.excludes ?? [],
          separator: "numbers",
        }),
        // RentalPaymentTerms: getRentalPaymentTerms(service),
      };
      temp2[`${service?.label}_NOTE`] = service?.note || "";
      getRentalPaymentTerms(service, temp2);

      let result2 = {};
      Object.entries(temp2)?.map(([key, value]) => {
        result2[`${service?.label}-${key}`] = value;
      });
      let elevations2 = getOtherServiceElevations({
        serviceOptions: service?.serviceOptions[0],
        service: service?.label,
      });
      result2 = { ...result2, ...elevations2 };
      servicesElevationProcess({
        result: result2,
        service,
        elevation: elevations2,
      });

      return result2;
  }
};

const HOIST_SERVICES = {
  "Is Scope": ({ service }) => (service?.isScope ? "Is Scope" : "Not Scope"),
  "Is Taxable": ({ service }) =>
    service?.isTaxable ? "Is Taxable" : "Not Taxable",
  Label: ({ service }) => service?.label || "Unknown",
  "Tax Rate": ({ service }) => `${parseFloat(service?.taxRate || 0) * 100}%`,
  "Rent Rate": ({ service }) => `${parseFloat(service?.rentRate || 0) * 100}%`,
};

const getHoistElevations = ({ serviceOptions = [] }) => {
  if (Array.isArray(serviceOptions)) {
    let temp = {};
    serviceOptions?.forEach((el) => {
      const sOptions = el || {};
      let label = `Hoist_${el?.elevationLabel}`;
      temp[`${label}-Access`] = sOptions?.access || "Unknown";
      temp[`${label}-Addons Total`] = sOptions?.addonsTotal || "Unknown";
      temp[`${label}-Building Height`] = sOptions?.building_height || "Unknown";
      temp[`${label}-Elevation Label`] = sOptions?.elevationLabel || "Unknown";
      temp[`${label}-Height`] = sOptions?.height || "Unknown";
      temp[`${label}-From`] = sOptions?.from || "Unknown";
      temp[`${label}-To`] = sOptions?.to || "Unknown";
      temp[`${label}-Length`] = sOptions?.length || "Unknown";
      temp[`${label}-Lift Height`] = sOptions?.lift_height || "Unknown";
      temp[`${label}-Location`] = sOptions?.location || "Unknown";
      temp[`${label}-Mast Height`] = sOptions?.mastHeight || "Unknown";
      temp[`${label}-Number of Jumps`] = sOptions?.nrOfJumps || "Unknown";
      temp[`${label}-Price`] = sOptions?.price || "Unknown";
      temp[`${label}-Price Total`] =
        formatCurrency(sOptions?.priceTotal || 0) || "Unknown";
      temp[`${label}-Rent Price`] =
        formatCurrency(sOptions?.rentalsTotal || 0) || "Unknown";
      temp[`${label}-Rent Total Price`] =
        formatCurrency(sOptions?.totalRent || 0) || "Unknown";
      temp[`${label}-Type`] = sOptions?.type || "Unknown";
      temp[`${label}-Speed`] = sOptions?.speed || "Unknown";
      temp[`${label}-SerialNumbers`] = Array.isArray(sOptions?.serialNumbers)
        ? sOptions?.serialNumbers?.join(", ")
        : "N/A";
    });
    return temp;
  }
};

const getOtherServiceElevations = ({ serviceOptions = [], service = "" }) => {
  if (Array.isArray(serviceOptions)) {
    let temp = {};
    serviceOptions?.forEach((el) => {
      console.log("this is el", el);
      const sOptions = el || {};
      let label = `${service}_${el?.elevationLabel}`;
      temp[`${label}-Desired-Price`] =
        formatCurrency(sOptions?.desiredPrice || 0) || "Unknown";
      temp[`${label}-Total Price`] = formatCurrency(
        sOptions?.items?.reduce(
          (acc, curr) => acc + curr?.totalPrice || 0,
          0
        ) || 0
      );
      temp[`${label}-Total Rent`] = formatCurrency(
        sOptions?.items?.reduce((acc, curr) => acc + curr?.rent || 0, 0) || 0
      );
      temp[`${label}-PLIS_Description`] = generatePLIDescription({
        PLIS: sOptions?.items,
        service,
      });
      const processedPLIs = processPLI({ elevation: el, service });
      if (Object.keys(processedPLIs)?.length > 0) {
        temp = { ...temp, ...processedPLIs };
      }
    });
    // retrieving the PLIs from the service options
    return temp;
  }
};

const generatePLIDescription = ({ PLIS = [], service = "" }) => {
  let res = `<div style="display:flex; flex-direction: column;">`;
  PLIS?.forEach((el) => {
    res += `<strong>${el?.height || 0}'H x ${el?.length || 0}'L x${
      el?.width || 0
    }'W </strong> \n`;
    if (!!el?.note?.note) {
      res += `<strong>Notes: </strong> ${el?.note?.note} \n`;
    }
  });
  res += `</div>`;
  return res;
};

const processPLI = ({ elevation = {}, service = "" }) => {
  console.log("to process", { elevation, service });
  const tempPLIs = elevation?.items || [];
  let temp = {};

  if (Array.isArray(tempPLIs) && tempPLIs?.length > 0) {
    tempPLIs?.forEach((PLI) => {
      let label = `${service}-${elevation?.elevationLabel}-${PLI?.id}`;
      temp[`${label}_Price Per Unit`] = PLI?.ppu || null;
      temp[`${label}_Length`] = PLI?.length || null;
      temp[`${label}_Note`] = PLI?.note?.note || null;
      temp[`${label}_Price`] = formatCurrency(PLI?.price || 0);
      temp[`${label}_Rent`] = formatCurrency(PLI?.rent || 0);
      temp[`${label}_SQFT`] = PLI?.sqft || null;
      temp[`${label}_Tax Amount`] = formatCurrency(PLI?.taxAmount || 0);
      temp[`${label}_Tax Rate`] = `${PLI?.taxRate || 0 * 100}%` || null;
      temp[`${label}_Total Price`] = formatCurrency(PLI?.totalPrice || 0);
      temp[`${label}_Width`] = PLI?.width || null;
      temp[`${label}_Approved`] = PLI?.approved || null;
    });
    return temp;
  }
  return temp;
};

const getIncludesExcludesFormat = ({ type, data, separator }) => {
  let temp = `<div>`;
  if (type === "includes" || type === "excludes") {
    data?.forEach((d, idx) => {
      temp += `<div id=${idx} >${
        separator === "numbers" ? idx + 1 + "." : "- "
      } ${stripHtmlTags(d?.name || "")}</div>`;
    });
  }
  temp += "</div>";

  return temp.replace(/<p([^>]*)>/gi, "<div$1>").replace(/<\/p>/gi, "</div>");
};

const getRentalPaymentTerms = (service = {}, temp2) => {
  console.log("getRentalPaymentTerms", service);
  let tempTerms = service?.rentalPaymentTerms || {};
  let toReturn = {
    description: tempTerms?.description || "",
    name: tempTerms?.name || "",
  };
  if (tempTerms?.range) {
    toReturn.range = tempTerms?.range;
  }
  if (tempTerms?.startingDate) {
    toReturn.startingDate = moment(tempTerms?.startDate)
      .format("MM/DD/YYYY")
      .toString();
  }
  /// here we look & define additional rental terms
  if (Object.keys(service?.additionalRentalTerms || {})?.length > 0) {
    Object.keys(service?.additionalRentalTerms)?.forEach((el) => {
      toReturn[el] = service?.additionalRentalTerms[el];
    });
  }
  console.log("toReturn", toReturn);
  Object.keys(toReturn).forEach((key) => {
    temp2[`RentalTerms-${key}`] = toReturn[key];
    console.log("temp2", temp2);
  });
  return toReturn;
};
