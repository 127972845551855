import { Polygon } from "@react-google-maps/api";
import { findFieldOptionByNameOrId } from "../../../../../utils";

export const OnPolygonDraw = ({ pathPoints, onLoad, onUnmount, onEdit }) => {
  const configurations = findFieldOptionByNameOrId({
    fieldId: "04345rwe-rw5f65sd-fw451f5s1fwef",
  });

  const option = configurations
    ? configurations.find(({ typeOfShape }) => typeOfShape === "Polygon")
    : {};

  const dynamicOption = {
    fillOpacity: option.fillOpacity,
    fillColor: option.fillColor,
    strokeColor: option.strokeColor,
    strokeWeight: option.strokeWeight,
    draggable: true,
    editable: true,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 3,
          strokeColor: option.strokeColor,
        },
        offset: "100%",
        repeat: "20px",
      },
    ],
  };

  return (
    <Polygon
      paths={pathPoints}
      options={dynamicOption}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onMouseUp={onEdit}
    />
  );
};
