import Decimal from "decimal.js-light";

import { calculateDifference, updateFakeSOV } from "../utils";
import { getErrors } from "../../../../utils/getErrors";
import { roundToDecimals } from "../../../../../../../../../../utils";

Decimal.config({ precision: 32 });

export const cellValueChanged = (
  params,
  service,
  fakeSOV,
  setSOV,
  setErrors
) => {
  // Find service that we want to edit
  const serviceToEdit = fakeSOV?.services?.find(
    (sovService) =>
      sovService.serviceId.toString() === service.serviceId.toString() &&
      sovService.estimationId === service.estimationId
  );

  // Update rate of row thats being edited on grid
  const newAmounts = serviceToEdit?.amounts?.map(
    (amount) =>
      params.data.id === amount.id // Find the row that we are editing, and make changes
        ? params.colDef.field === "rate" // If we are editing rate
          ? {
              ...amount, // Spread out other values that dont need to change
              rate: isNaN(+params.newValue) // Check if input is valid
                ? 0 // If it is not valid make it 0
                : // : +params.newValue > 100 // Check if input is greater than 100
                  // ? 100 // If it is make it 100
                  roundToDecimals(+params.newValue, 2), // Convert new rate to number
            }
          : params.colDef.field === "amount" // If we are editing amount
          ? {
              ...amount, // Spread out other values that dont need to change
              rate: isNaN(+params.newValue) // Check if input is valid
                ? 0 // If it is not valid make it 0
                : // : +params.newValue > serviceToEdit?.totalPrice // Check if input is greater than total price
                  // ? 100 // If it is make it 100
                  new Decimal(100)
                    .dividedBy(
                      new Decimal(serviceToEdit.totalPrice).dividedBy(
                        roundToDecimals(+params.newValue, 2)
                      )
                    )
                    .toFixed(), // Calculate rate based on new amount that we provided
            }
          : { ...params.data } // If we are editing event, no changes needed
        : amount // For other rows dont make changes
  );

  // Re calculate all amounts to update other rows based on the row that we have updated
  const amounts = calculateDifference(serviceToEdit, newAmounts);
  // Find the service that we are editing and update its amounts
  const newSOV = updateFakeSOV(fakeSOV, service, amounts);

  // Get errors for each service
  const errors = getErrors(newSOV);

  // Set new SOV
  setSOV(newSOV);

  // If none of services have errors set errors to null and save to db, otherwise set errors to errors object of all services
  if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
    setErrors(null);
  } else {
    setErrors(errors);
  }
};
