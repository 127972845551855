import { openInNewTab } from "../../../utils"

export const openInStreetView = (coordinates = {}) => {
  const { lat = "", lng = "" } = coordinates
  !!lat &&
    !!lng &&
    openInNewTab(
      `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat}%2C${lng}`
    )
}
