import { boroughs, months } from "../../../../Dashboard/addWidgetsModalData";
import { formatCurrency, getMonthsTimestamps } from "../../../../utils";
import uniq from "lodash/uniq";
import {
  calculateInvoiceStatus,
  categoriesName,
  getSums,
  groupBy,
} from "../../../../Dashboard/logicDataFunctions/calculatingFunctions";
import { repeatedElementSum } from "../../../../Dashboard/NewDashboardFunctions";
//this functions return the filtered data for the specified category and subcategory (example lineChartFilter.Leads.leadStatus)
export const lineChartFilter = ({
  programFields,
  dynamicStates,
  id,
  updatedDynamicStates,
  // fleetData,
  // dispatchData,
  // archData,
  // defectsData,
  // claimsData,
  // incidentsData,
  // workOrdersData,
  // engData,
  inspectionsData,
  clear = false,
}) => {
  const dataToFilter = clear ? dynamicStates[id] : updatedDynamicStates[id];
  return {
    Leads: () => ({
      leadStatus: () =>
        programFields
          ?.find((a) => a?.fieldName === "Lead Status Types")
          ?.fieldOptions?.map((status, index) => ({
            key: index,
            title: status,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.leadStatus === status)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status} Lead`,
            contentsHandler: ` ${status} Leads`,
          })),

      leadAddress: () =>
        boroughs.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: dataToFilter?.length,
          taskDone: dataToFilter?.filter((a) => a?.borough === status)?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Lead in " + status,
          contentsHandler: " Leads in " + status,
        })),

      projectExecutive: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "projectExecutive")
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: dataToFilter?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${key} Leads`,
          contentsHandler: ` ${key} Leads`,
        })),
    }),

    Claims: () => ({
      claimStatus: () =>
        programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Claims?.map((status, index) => ({
            key: index,
            title: status?.statusName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) => a?.claimStatus === status?.statusName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status?.statusName} Claim`,
            contentsHandler: ` ${status?.statusName} Claims`,
          })),
      claimCategory: () =>
        uniq(dataToFilter?.map((a) => a?.claimCategory))?.map(
          (status, index) => ({
            key: index,
            title: status,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.claimCategory === status)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status} Claim`,
            contentsHandler: ` ${status} Claims`,
          })
        ),
    }),

    "Work Orders": () => ({
      workOrderStatus: () =>
        uniq(updatedDynamicStates?.[id]?.map((a) => a?.workOrderStatus))?.map(
          (workOrderStatus, index) => ({
            key: index,
            title: workOrderStatus,
            taskNumber: updatedDynamicStates?.[id]?.length,
            taskDone: updatedDynamicStates?.[id]?.filter(
              (a) => a.workOrderStatus === workOrderStatus
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " " + workOrderStatus,
            contentsHandler: " " + workOrderStatus,
          })
        ),

      fleetName: () =>
        uniq(dataToFilter?.map((a) => a?.fleetName))?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: dataToFilter?.length,
          taskDone: dataToFilter?.filter(
            (a) => a.fleetName?.toLowerCase() === status?.toLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + status,
          contentsHandler: " " + status,
        })),

      mechanicName: () =>
        uniq(
          dataToFilter?.map(
            (a) => a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
          )
        )?.map((mechanicName, index) => ({
          key: index,
          title: mechanicName,
          taskNumber: dataToFilter?.length,
          taskDone: dataToFilter?.filter(
            (a) =>
              (
                a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
              )?.toLowerCase() === mechanicName?.toLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + mechanicName,
          contentsHandler: " " + mechanicName,
        })),

      dueDate: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: dataToFilter?.filter(
                  (a) => a?.dueDate >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: dataToFilter?.filter(
                  (a) =>
                    a?.dueDate >= getMonthsTimestamps()[i] &&
                    a?.dueDate <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Due In " + months[i],
                contentsHandler: " Due In " + months[i],
              },
            ];
          }
          return data;
        })(),
    }),

    Defects: () => ({
      driverName: () =>
        uniq(dataToFilter?.map((a) => a?.driverName))?.map(
          (driverName, index) => ({
            key: index,
            title: driverName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.driverName === driverName)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Defect By " + driverName,
            contentsHandler: " Defects By " + driverName,
          })
        ),

      defectName: () =>
        uniq(dataToFilter?.map((a) => a?.defectName))?.map(
          (defectName, index) => ({
            key: index,
            title: defectName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.defectName === defectName)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: defectName,
            contentsHandler: defectName,
          })
        ),

      defectStatus: () =>
        uniq(dataToFilter?.map((a) => a?.defectStatus))?.map(
          (defectStatus, index) => ({
            key: index,
            title: defectStatus,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) => a?.defectStatus === defectStatus
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: defectStatus,
            contentsHandler: defectStatus,
          })
        ),

      fleetName: () =>
        uniq(dataToFilter?.map((a) => a?.fleetName))?.map(
          (fleetName, index) => ({
            key: index,
            title: fleetName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.fleetName === fleetName)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Defect By " + fleetName,
            contentsHandler: " Defects By " + fleetName,
          })
        ),

      defectPriority: () =>
        uniq(dataToFilter?.map((a) => a?.defectPriority))?.map(
          (defectPriority, index) => ({
            key: index,
            title: defectPriority,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) => a?.defectPriority === defectPriority
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: defectPriority,
            contentsHandler: defectPriority,
          })
        ),

      inspectionName: () =>
        uniq(dataToFilter?.map((a) => a?.inspectionName))?.map(
          (inspectionName, index) => ({
            key: index,
            title: inspectionName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) => a?.inspectionName === inspectionName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Defect By " + inspectionName,
            contentsHandler: " Defects By " + inspectionName,
          })
        ),
    }),

    Incidents: () => ({
      driverName: () =>
        uniq(dataToFilter?.map((a) => a?.driverName))?.map(
          (driverName, index) => ({
            key: index,
            title: driverName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.driverName === driverName)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Incidents By " + driverName,
            contentsHandler: " Incidents By " + driverName,
          })
        ),

      incidentStatus: () =>
        uniq(dataToFilter?.map((a) => a?.incidentStatus))?.map(
          (incidentStatus, index) => ({
            key: index,
            title: incidentStatus,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) => a?.incidentStatus === incidentStatus
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " " + incidentStatus,
            contentsHandler: " " + incidentStatus,
          })
        ),

      fleetName: () =>
        uniq(dataToFilter?.map((a) => a?.fleetName))?.map(
          (fleetName, index) => ({
            key: index,
            title: fleetName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.fleetName === fleetName)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Incidents By " + fleetName,
            contentsHandler: " Incidents By " + fleetName,
          })
        ),
    }),
    Applications: () => {
      const applicationStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status colors")
        ?.fieldOptions?.Requisition.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});

      return {
        applicationStatus: () =>
          applicationStatus?.statusName?.map((status, index) => ({
            key: index,
            title: status,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) => a?.applicationStatus === status
            )?.length,
            color: applicationStatus?.statusColor[index],
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status} Application`,
            contentsHandler: ` ${status} Applications`,
            custom:
              dataToFilter &&
              groupBy(
                [dataToFilter?.filter((a) => a?.applicationStatus === status)],
                "projectName"
              )?.map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Current Payment": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.applicationStatus === status
                            ),
                          ],

                          key: "totalAmount",
                        })
                      ),
                      "Total Retainage": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.applicationStatus === status
                            ),
                          ],

                          key: "totalRetainage",
                        })
                      ),
                      "Total Rental With Tax": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.applicationStatus === status
                            ),
                          ],

                          key: "totalRentalWithTax",
                        })
                      ),
                    },
                  },
                  ...Object?.entries(el)?.map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Current Payment": formatCurrency(
                          getSums({ data: value, key: "totalAmount" })
                        ),
                        "Total Retainage": formatCurrency(
                          getSums({ data: value, key: "totalRetainage" })
                        ),
                        "Total Rental With Tax": formatCurrency(
                          getSums({ data: value, key: "totalRentalWithTax" })
                        ),
                      },
                    };
                  }),
                ];
              }),
          })),
      };
    },
    Rentals: () => {
      const rentalStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status colors")
        ?.fieldOptions?.Rentals.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});

      return {
        rentalStatus: () =>
          rentalStatus?.statusName?.map((status, index) => ({
            key: index,
            title: status,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.rentalStatus === status)
              ?.length,
            color: rentalStatus?.statusColor[index],
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status} Application`,
            contentsHandler: ` ${status} Rentals`,
            custom:
              dataToFilter &&
              groupBy(
                [dataToFilter?.filter((a) => a?.rentalStatus === status)],
                "projectAddress"
              )?.map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Total Price": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.rentalStatus === status
                            ),
                          ],

                          key: "totalPrice",
                        })
                      ),
                      "Total Applied Rent": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.rentalStatus === status
                            ),
                          ],

                          key: "totalAppliedRent",
                        })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.rentalStatus === status
                            ),
                          ],

                          key: "totalTax",
                        })
                      ),
                      "Retainage Amount": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.rentalStatus === status
                            ),
                          ],
                          key: "retainageAmount",
                        })
                      ),
                    },
                  },
                  ...Object?.entries(el)?.map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Total Price": formatCurrency(
                          getSums({ data: value, key: "totalPrice" })
                        ),
                        "Total Applied Rent": formatCurrency(
                          getSums({ data: value, key: "totalAppliedRent" })
                        ),
                        "Total Tax": formatCurrency(
                          getSums({ data: value, key: "totalTax" })
                        ),
                        "Retainage Amount": formatCurrency(
                          getSums({ data: value, key: "retainageAmount" })
                        ),
                      },
                    };
                  }),
                ];
              }),
          })),
      };
    },
    Charges: () => {
      const chargeStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status colors")
        ?.fieldOptions?.Charge.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});

      return {
        categoryFrom: () =>
          chargeStatus?.statusName?.map((status, idx) => ({
            key: idx,
            title: status,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.categoryFrom === status)
              ?.length,
            color: chargeStatus?.statusColor[idx],
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status} Charge`,
            contentsHandler: ` ${status} Charges`,
            custom:
              dataToFilter &&
              groupBy(
                [dataToFilter?.filter((a) => a?.categoryFrom === status)],
                "projectAddress"
              )?.map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Charge Amount With Tax": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.categoryFrom === status
                            ),
                          ],
                          key: "chargeAmount",
                        })[index] || 0
                      ),
                      "Total Tax": formatCurrency(
                        getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.categoryFrom === status
                            ),
                          ],
                          key: "taxAmount",
                        })[index] || 0
                      ),
                      "Charge Amount Without Tax": formatCurrency(
                        (getSums({
                          data: [
                            dataToFilter?.filter(
                              (a) => a?.categoryFrom === status
                            ),
                          ],
                          key: "chargeAmount",
                        })[index] || 0) -
                          (getSums({
                            data: [
                              dataToFilter?.filter(
                                (a) => a?.categoryFrom === status
                              ),
                            ],
                            key: "taxAmount",
                          })[index] || 0) || 0
                      ),
                    },
                  },
                  ...Object?.entries(el).map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Charge Amount With Tax": formatCurrency(
                          getSums({
                            data: value,
                            key: "chargeAmount",
                          }) || 0
                        ),
                        "Total Tax": formatCurrency(
                          getSums({
                            data: value,
                            key: "taxAmount",
                          }) || 0
                        ),
                        "Charge Amount Without Tax": formatCurrency(
                          getSums({
                            data: value,
                            key: "chargeAmount",
                          }) -
                            getSums({
                              data: value,
                              key: "taxAmount",
                            })
                        ),
                      },
                    };
                  }),
                ];
              }),
          })),
      };
    },
    Invoices: () => {
      const invoiceStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status of Invoices")
        ?.fieldOptions?.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});

      return {
        invoiceStatus: () =>
          invoiceStatus?.statusName?.map((status, idx) => ({
            key: idx,
            title: status,
            taskNumber: dataToFilter?.length,
            taskDone: calculateInvoiceStatus({
              table: dataToFilter,
              status,
            })?.length,
            color: invoiceStatus?.statusColor[idx],
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status} Invoice`,
            contentsHandler: ` ${status} Invoices`,
            custom:
              dataToFilter &&
              groupBy(
                [
                  calculateInvoiceStatus({
                    table: dataToFilter,
                    status,
                  }),
                ],
                "projectName"
              )?.map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Invoice Amount With Tax": formatCurrency(
                        getSums({
                          data: [
                            calculateInvoiceStatus({
                              table: dataToFilter,
                              status,
                            }),
                          ],
                          key: "totalInvoiceAmount",
                        })[index] || 0
                      ),
                      "Total Invoice Amount Without Tax": formatCurrency(
                        getSums({
                          data: [
                            calculateInvoiceStatus({
                              table: dataToFilter,
                              status,
                            }),
                          ],
                          key: "subtotal",
                        })[index] || 0
                      ),
                      "Total Tax": formatCurrency(
                        (getSums({
                          data: [
                            calculateInvoiceStatus({
                              table: dataToFilter,
                              status,
                            }),
                          ],
                          key: "totalInvoiceAmount",
                        })[index] || 0) -
                          (getSums({
                            data: [
                              calculateInvoiceStatus({
                                table: dataToFilter,
                                status,
                              }),
                            ],
                            key: "subtotal",
                          })[index] || 0)
                      ),
                    },
                  },
                  ...Object?.entries(el).map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Invoice Amount With Tax": formatCurrency(
                          getSums({
                            data: value,
                            key: "totalInvoiceAmount",
                          }) || 0
                        ),
                        "Invoice Amount Without Tax": formatCurrency(
                          getSums({ data: value, key: "subtotal" })
                        ),
                        "Total Tax": formatCurrency(
                          getSums({ data: value, key: "totalInvoiceAmount" }) -
                            getSums({ data: value, key: "subtotal" })
                        ),
                      },
                    };
                  }),
                ];
              }),
          })),
      };
    },
    Opportunities: () => ({
      opportunityStage: () =>
        programFields
          ?.find((a) => a?.fieldName === "Opportunity Stage")
          ?.fieldOptions?.map(({ detailedStageName }, index) => ({
            key: index,
            title: detailedStageName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) => a?.opportunityStage === detailedStageName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${detailedStageName} Opportunity`,
            contentsHandler: ` ${detailedStageName} Opportunity's`,
          })),

      projectExecutive: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "projectExecutive")
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: dataToFilter?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${key} Opportunity`,
          contentsHandler: ` ${key} Opportunity's`,
        })),

      projectManager: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "label"
          )
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: dataToFilter?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${key} Opportunity`,
          contentsHandler: ` ${key} Opportunity's`,
        })),
    }), //opportunities
    Projects: () => ({
      projectStatus: () =>
        programFields
          ?.find((a) => a?.fieldName === "Project Status")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) =>
                a?.projectStatus?.toLowerCase() ===
                statusName?.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: statusName + " Project",
            contentsHandler: statusName + " Projects",
          })),

      attorney: () =>
        Object.entries(repeatedElementSum(dataToFilter || [], "attorney")).map(
          ([key, value], index) => ({
            key: index,
            title: key,
            taskNumber: (dataToFilter || [])?.length,
            taskDone: value,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: key + " Project",
            contentsHandler: key + " Projects",
          })
        ),

      expeditor: () =>
        Object.entries(repeatedElementSum(dataToFilter || [], "expeditor")).map(
          ([key, value], index) => ({
            key: index,
            title: key,
            taskNumber: (dataToFilter || [])?.length,
            taskDone: value,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: key + " Project",
            contentsHandler: key + " Projects",
          })
        ),
    }), //Projects

    Documentation: () => ({
      docType: () => {
        const data = repeatedElementSum(dataToFilter || [], "docType");

        return Object.entries(data)?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: dataToFilter?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: key + " Documentation",
          contentsHandler: key + " Documentations",
        }));
      },

      docStatus: () => {
        const data = repeatedElementSum(dataToFilter || [], "docStatus");

        return Object.entries(data)?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: dataToFilter?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: key + " Documentation",
          contentsHandler: key + " Documentations",
        }));
      },
    }), //Documentation
    Scheduling: () => ({
      typeOfWork: () =>
        programFields
          ?.find((a) => a?.fieldName === "Scheduling Types")
          ?.fieldOptions?.map(({ typeName }, index) => ({
            key: index,
            title: typeName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) =>
                a?.typeOfWork?.toLowerCase() === typeName?.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Schedule In " + typeName,
            contentsHandler: " Schedules In " + typeName,
          })),
    }), //schaduling

    Dispatching: () => ({
      driverName: () =>
        uniq(
          dataToFilter?.map((a) => a?.routes?.map((e) => e?.driverName))?.flat()
        )?.map((driverName, index) => ({
          key: index,
          title: driverName,
          taskNumber: dataToFilter
            ?.map((a) => a?.routes?.map((e) => e?.driverName))
            ?.flat()?.length,
          taskDone: dataToFilter
            ?.map((a) => a?.routes?.map((e) => e?.driverName))
            ?.flat()
            ?.filter((a) => a?.toLowerCase() === driverName?.toLowerCase())
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Drove By " + driverName,
          contentsHandler: " Drove By " + driverName,
        })),

      dispatchedBy: () =>
        uniq(dataToFilter?.map((a) => a?.dispatchedBy))?.map(
          (dispatchedBy, index) => ({
            key: index,
            title: dispatchedBy,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) =>
                a?.dispatchedBy?.toLowerCase() ===
                dispatchedBy?.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Dispatched By " + dispatchedBy,
            contentsHandler: " Dispatched By " + dispatchedBy,
          })
        ),
    }), //Dispatching

    Fleet: () => ({
      fleetYear: () =>
        uniq(dataToFilter?.map((a) => a?.fleetYear))?.map(
          (fleetYear, index) => ({
            key: index,
            title: fleetYear,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter((a) => a?.fleetYear === fleetYear)
              ?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Fleet In " + fleetYear,
            contentsHandler: " Fleets In " + fleetYear,
          })
        ),

      registrationExpDate: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: dataToFilter?.filter(
                  (b) => b?.registrationExpDate >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: dataToFilter?.filter(
                  (a) =>
                    a?.registrationExpDate >= getMonthsTimestamps()[i] &&
                    a?.registrationExpDate <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Expiration In " + months[i],
                contentsHandler: " Expiration In " + months[i],
              },
            ];
          }
          return data;
        })(),

      inspectionExpDate: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: dataToFilter?.filter(
                  (b) => b?.inspectionExpDate >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: dataToFilter?.filter(
                  (a) =>
                    a?.inspectionExpDate >= getMonthsTimestamps()[i] &&
                    a?.inspectionExpDate <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Expiration In " + months[i],
                contentsHandler: " Expiration In " + months[i],
              },
            ];
          }
          return data;
        })(),
    }), //Fleet

    "Fleet Inspections": () => ({
      inspectedBy: () =>
        Object.entries(repeatedElementSum(dataToFilter, "inspectedBy"))?.map(
          ([key, value], index) => ({
            key: index,
            title: key,
            taskNumber: dataToFilter?.length,
            taskDone: value,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Inspection By " + key,
            contentsHandler: " Inspections By " + key,
          })
        ),

      createdAt: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: dataToFilter?.filter(
                  (b) => b.createdAt >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: dataToFilter?.filter(
                  (a) =>
                    a?.createdAt >= getMonthsTimestamps()[i] &&
                    a?.createdAt <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Inspection In " + months[i],
                contentsHandler: " Inspections In " + months[i],
              },
            ];
          }
          return data;
        })(),

      fleetName: () =>
        Object?.keys(repeatedElementSum(dataToFilter || [], "fleetName"))?.map(
          ([key, value]) => ({
            title: key,
            taskNumber: dataToFilter?.length,
            taskDone: value,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` Inspection for ` + key,
            contentsHandler: ` Inspections for ` + key,
          })
        ),
    }), //Inspections
    Maintenance: () => ({
      createdAt: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: dataToFilter?.filter(
                  (b) => b.createdAt >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: dataToFilter?.filter(
                  (a) =>
                    a?.createdAt >= getMonthsTimestamps()[i] &&
                    a?.createdAt <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Maintenance In " + months[i],
                contentsHandler: " Maintenances In " + months[i],
              },
            ];
          }
          return data;
        })(),
    }), //Maintenance

    Violations: () => ({
      amount_due: () =>
        (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                title: months[i],
                taskNumber: (() => {
                  const formattedYearArray = dataToFilter?.filter(
                    (b) => b?.issue_date >= getMonthsTimestamps()[0]
                  );
                  return !!formattedYearArray?.length
                    ? formattedYearArray
                        ?.map((a) => a?.amount_due)
                        ?.reduce(
                          (previousValue, currentValue) =>
                            parseInt(previousValue) + parseInt(currentValue)
                        )
                    : 0;
                })(),

                taskDone: (() => {
                  const formattedArray = dataToFilter?.filter(
                    (a) =>
                      a?.issue_date >= getMonthsTimestamps()[i] &&
                      a?.issue_date <= getMonthsTimestamps("end")[i]
                  );

                  return !!formattedArray?.length
                    ? formattedArray
                        ?.map((b) => b?.amount_due)
                        ?.reduce(
                          (previousValue, currentValue) =>
                            parseInt(previousValue) + parseInt(currentValue)
                        )
                    : 0;
                })(),

                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: "$ In " + months[i],
                contentsHandler: "$ In " + months[i],
              },
            ];
          }
          return violations;
        })(),

      issue_date: () =>
        (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                title: months[i],
                taskNumber: dataToFilter?.filter(
                  (b) => b?.issue_date >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: dataToFilter?.filter(
                  (a) =>
                    a?.issue_date >= getMonthsTimestamps()[i] &&
                    a?.issue_date <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Violation In " + months[i],
                contentsHandler: " Violations In " + months[i],
              },
            ];
          }
          return violations;
        })(),
    }), //Violations

    Inspections: () => ({
      inspectionType: () =>
        Object.entries(repeatedElementSum(dataToFilter, "inspectionType"))?.map(
          ([key, value], index) => ({
            key: index,
            title: key,
            taskNumber: dataToFilter?.length,
            taskDone: value,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` Inspection Type ${key}`,
            contentsHandler: ` Inspections Type ${key}`,
          })
        ),

      inspectionStatus: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "inspectionStatus")
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: dataToFilter?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Inspection ${key}`,
          contentsHandler: ` Inspections ${key}`,
        })),

      inspectedBy: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((el) => el?.inspectedBy),
            "nameOfUser"
          )
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: dataToFilter?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Inspection By ${key}`,
          contentsHandler: ` Inspections By ${key}`,
        })),
    }), //Inspections

    Estimations: () => ({
      estSTATUS: () =>
        programFields
          ?.find((a) => a?.fieldName === "Estimation Status")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) =>
                a?.estSTATUS?.toLowerCase() === statusName?.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Estimation " + statusName,
            contentsHandler: " Estimations " + statusName,
          })),

      archAssignedTo: () =>
        Object.entries(
          repeatedElementSum(dataToFilter || [], "archAssignedTo")
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (dataToFilter || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Estimation Assigned `,
          contentsHandler: ` Estimations Assigned `,
        })),

      engAssignedTo: () =>
        Object.entries(
          repeatedElementSum(dataToFilter || [], "engAssignedTo")
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (dataToFilter || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Estimation Assigned `,
          contentsHandler: ` Estimations Assigned `,
        })),

      statusByArch: () =>
        programFields
          ?.find((a) => a?.fieldName === "Status by Architecture")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) =>
                a?.statusByArch?.toLowerCase() ===
                statusName?.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` Estimation ${statusName}`,
            contentsHandler: ` Estimations ${statusName}`,
          })),

      statusByEng: () =>
        programFields
          ?.find((a) => a?.fieldName === "Status by Engineering")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: dataToFilter?.length,
            taskDone: dataToFilter?.filter(
              (a) =>
                a?.statusByEng?.toLowerCase() ===
                statusName?.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` Estimation ${statusName}`,
            contentsHandler: ` Estimations ${statusName}`,
          })),
    }), //Estimations
  };
}; //LineChart
