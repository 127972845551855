import React from "react";
import parse from "html-react-parser";

const htmlParser = (html) => {
  if (typeof html !== "string") {
    return null;
  }

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };
  return parse(html || "", options);
};

export default htmlParser;
