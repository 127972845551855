import { Suspense } from "react";
import Sidebar from "./Sidebars/Sidebar";
import Header from "./Header/Header";
import { LoadableComp } from "./SidebarPages/XComponents";
import NotFound from "./SidebarPages/NotFound/NotFound";
import { useParams } from "react-router-dom";
/**
 *
 * @param {Object} params ={component:component of each route, props: props to pass in each route, ...other props}
 * @returns
 */
const AuthenticatedRoute = ({
  component: Component,
  props: componentProps,
  routeProps,
  isAccessible,
}) => {
  const { id } = useParams();

  //fix 404 flicker on first load
  return isAccessible === null ? (
    <NotFound />
  ) : (
    <Suspense fallback={<LoadableComp loading={true} className="fullWidth" />}>
      {/* Added the key id in order to trigger render when on navigate from global search  */}
      <Component key={id} {...routeProps} {...componentProps} />
    </Suspense>
  );
};
export default AuthenticatedRoute;
