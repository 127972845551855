export class OverheadProtectionPLI {
  id;
  approved = false;
  serviceId = 6;

  length;
  height;
  width;
  type;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;

  constructor(id, taxRate = 0) {
    this.id = id;
    this.taxRate = taxRate;
  }
}

export class AlternateOverheadProtectionPLI {
  id;
  approved = false;
  serviceId = 6;

  length;
  height;
  width;
  type;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;
  isAlternate = true;

  constructor(id, taxRate = 0) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
