import { getMonthsTimestamps } from "../../../../../utils";
import { months } from "../../../../addWidgetsModalData";

export const fleetInspections = ({ tables, circleProgressPicker }) => {
  return {
    inspectedBy: () => ({
      percent: (
        (tables?.["Fleet Inspections"]?.filter(
          (a) =>
            a?.inspectedBy?.toLowerCase() ===
            circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.["Fleet Inspections"]?.length) *
        100
      ).toFixed(1),

      data: tables?.["Fleet Inspections"]?.filter(
        (a) =>
          a?.inspectedBy?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //inspectedBy
    driverName: () => ({
      percent: (
        (tables?.["Fleet Inspections"]?.filter(
          (a) =>
            a?.driver?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.["Fleet Inspections"]?.length) *
        100
      ).toFixed(1),

      data: tables?.["Fleet Inspections"]?.filter(
        (a) => a?.driver?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //driverName
    createdAt: () => ({
      percent: (
        (tables?.["Fleet Inspections"]?.filter(
          (a) =>
            a?.createdAt >=
              getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.createdAt <=
              getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length /
          tables?.["Fleet Inspections"]?.filter(
            (a) => a?.createdAt >= getMonthsTimestamps()[0]
          ).length) *
        100
      ).toFixed(1),

      data: tables?.["Fleet Inspections"]?.filter(
        (a) =>
          a?.createdAt >=
            getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
          a?.createdAt <=
            getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
      ).length,
    }), //createdAt
  }; //Inspections
};
