import axios from "axios";

const endpointConfig = {
  locale: "http://localhost:8080/api/dispatchScheduleChanges",
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/api/dispatchScheduleChanges/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/api/dispatchScheduleChanges",
};

/**
 * Function that handles adding breaking schedule
 * changes to the body of the dispatch object
 *
 * @param {Object} param
 * @param {string} param.scheduleId - The schedule in question
 * @param {string} param.projectId - The id of the related project
 * @param {any[]} param.changes - The actual changes of which the dispatch should be alerted
 * @param {string} [param.scheduleDay] - ISO Date of the start of the day (not set when the schedule is deleted)
 * @param {string} [param.newStatus="Pending"] - The status of the affected activities and dispatches
 * @param {boolean} [param.keepId=false] - Whether to keep or remove the schedule id from the dispatch/activity body
 */
export async function postScheduleChanges({
  scheduleId,
  projectId,
  changes = [],
  scheduleDay = "",
  newStatus = "Pending",
  keepId = false,
}) {
  return axios
    .post(endpointConfig.online, {
      scheduleId,
      projectId,
      changes,
      scheduleDay,
      newStatus,
      keepId,
    })
    .then(({ data }) => data);
}
