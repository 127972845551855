export const compareNestedObjects = (obj1, obj2) => {
  for (let key in obj1) {
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      const isMatching = compareNestedObjects(obj1[key], obj2[key]);
      if (!isMatching) {
        return false;
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }
  return true;
};

export const compareObjects = (obj1, obj2) => {
  if (obj1 && obj2) {
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }
  return false;
};
