import { exportToXLSX } from "../../../../../../../../Applications/ApplicationView/components/exports/exportToXLSX";
import { useStateContext } from "../../../../../../Context/Context";
import { Excel } from "../../../../../../../../../../BasePage/src";
import { exportToExcelData } from "./exportToExcelFunctions";
import "./ExportToExcel.scss";

import { Tooltip } from "antd";

const ExportToExcel = () => {
  // Context state & dispatch
  const { fakeSOV } = useStateContext();

  const workSheetDataExcel = exportToExcelData({ fakeSOV });

  const handleExport = () => {
    exportToXLSX(
      `${fakeSOV?.projectName || "AIA_Application"}__SOV${
        fakeSOV?.SOVNo || ""
      }`,
      "",
      {
        sovData: { workSheetDataExcel },
      }
    );
  };

  return (
    <>
      <Tooltip title="Export to Excel">
        <button className="excelButtonSOV" onClick={handleExport}>
          <Excel />
        </button>
      </Tooltip>
    </>
  );
};

export default ExportToExcel;
