import { getNewLabelHTML } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/routeFunctions";

/**
 * Handles the change event for the address input field.
 *
 * @param {string} val - The selected address value.
 * @param {Object} option - The selected option object.
 * @param {Array} [projects=[]] - The array of projects.
 * @param {Array} [schedulesForDate=[]] - The array of schedules for the selected date.
 * @param {Function} [setAddressSelectionInfo=()=>{}] - The function to set the address selection information.
 */
function onChangeAddress({
  val,
  option,
  projects = [],
  schedulesForDate = [],
  setAddressSelectionInfo = () => {},
}) {
  let selectedAddress = val?.trim();
  let type = getNewLabelHTML({ option })?.type;

  if (type === "schedule") {
    let selectedSchedule = schedulesForDate?.find(
      (el) => el?.scheduleAddress === selectedAddress
    );

    if (!!selectedSchedule) {
      setAddressSelectionInfo({
        scheduleId: selectedSchedule?.scheduleId,
        projectId: selectedSchedule?.projectId,
        accountId: "",
      });
    }
  } else if (type === "project") {
    let selectedProject = projects?.find(
      (el) => el?.projectName === selectedAddress
    );

    if (!!selectedProject) {
      setAddressSelectionInfo({
        projectId: selectedProject?.projectId,
        accountId: selectedProject?.accountId,
        scheduleId: "",
      });
    }
  } else {
    setAddressSelectionInfo({
      projectId: "",
      scheduleId: "",
      accountId: "",
    });
  }
}

export default onChangeAddress;
