import { SimplePLI } from "./GeneralModels"

export class PlatformScaffoldPLI extends SimplePLI {
  serviceId = 20

  length
  height
  width

  constructor(id, taxRate) {
    super(id, taxRate)
  }
}
