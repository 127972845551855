import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Estimations } from "../../../../../../types/EstimationTypes";
import LoadableComp from "../../../../XComponents/LoadableComp";

type Props = {
  setVisible: (visible: boolean) => void;
  visible: boolean;
  modals?: {
    [key: string]: {
      Component: React.ElementType;
      data: any;
      message: string;
    };
  };
  step: number | string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const MultiStepModal = (props: Props) => {
  const { modals, visible, setVisible, step, setStep } = props;
  const [loading, setLoading] = useState(true);
  const ChosenStep = useMemo(
    () =>
      modals?.[Object.keys?.(modals)?.[step]] || {
        Component: () => null,
        data: {
          message: null,
        },
      },
    [step, modals, visible]
  );

  const onMultiStepCancel = (e: number) => {
    if (e === 0) {
      setVisible(false);
      setStep(e);
    } else {
      setStep((prev) => prev - 1);
    }
  };

  //This uses the data to determine if the modal should be loading or not
  useEffect(() => {
    if (!!ChosenStep) {
      if (Object.values(ChosenStep?.data)?.every(Boolean)) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [ChosenStep, visible, step, modals]);

  useEffect(() => {
    if (Number(step) >= Object.keys(modals)?.length) {
      setVisible(false);
    }
  }, [step]);

  return (
    <LoadableComp loading={loading} componentTestId={""}>
      <ChosenStep.Component
        {...{
          ...ChosenStep?.data,
          visible,
          setVisible:
            step === Object.keys(modals)?.length - 1 ? setVisible : () => null,
          onMultiStepCancel,
        }}
      />
    </LoadableComp>
  );
};

export default MultiStepModal;
