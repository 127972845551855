export function parseContent(content) {
  const htmlRegex = /<[^>]*>/;

  if (typeof content === "string" && htmlRegex.test(content)) {
    // Try parsing as HTML
    const htmlParser = new DOMParser();
    const parsedHtml = htmlParser.parseFromString(content, "text/html");

    if (parsedHtml) return parsedHtml;

    // Check if it's a valid ISO date string
    const isoDate = new Date(content);
    if (!isNaN(isoDate.getTime())) {
      return isoDate;
    }

    // If not HTML, timestamp, date string, or number, return the original string
    return content;
  } else {
    // Check if it's a timestamp (assumed to be a number representing milliseconds)
    if (!isNaN(content) && !isNaN(parseInt(content))) {
      const timestamp = parseInt(content);
      // Set a threshold to distinguish between timestamps and regular numbers
      const timestampThreshold = 1000000000000; // Adjust as needed
      if (timestamp > timestampThreshold) {
        const date = new Date(timestamp);
        if (!isNaN(date.getTime())) {
          // Return formatted date as MM/DD/YYYY
          const formattedDate = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date
            .getDate()
            .toString()
            .padStart(2, "0")}/${date.getFullYear()}`;
          return formattedDate;
        }
      }
    }

    // If not a string, return the original content
    return content;
  }
}
