import { Avatar, Tooltip } from "antd";
import { repeatelem } from "../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { defaultColors, getInitials } from "../../../utils";
import { useSelector } from "react-redux";
import { useGetImageSrc } from "../../../hooks";
import { useEffect } from "react";

/**
 * Renders a group of dynamic avatars based on the provided members data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.members - The array of members to display avatars for.
 * Array of objects with the following properties:
 *  @property {string} [props.members.nameOfUser] - The name of the member.
 *  @property {string} [props.members.identityId] - The identity ID of the member.
 * | OR | Array of strings with the member's name.
 * @param {string} [props.className="profile-photos-container"] - The CSS class name for the avatar group container.
 * @param {number} [props.maxCount=5] - The maximum number of avatars to display.
 * @param {Object} [props.groupStyle={}] - The CSS styles for the avatar group container.
 * @param {Object} [props.avatarStyle={}] - The CSS styles for each avatar.
 * @param {string|boolean} [props.nameKey=false] - The key to access the member's name in the members array.
 * @param {string|boolean} [props.idKey=false] - The key to access the member's identity ID in the members array.
 * @param {boolean} [props.fetchPhoto=true] - Determines whether to fetch the member's photo.
 * @returns {JSX.Element} The rendered DynamicAvatars component.
 */
export const Avatars = ({
  members = [],
  className = "profile-photos-container",
  maxCount = 5,
  groupStyle = {},
  avatarStyle = {},
  nameKey = false,
  idKey = false,
  fetchPhoto = true,
}) => {
  const removeDuplicates = members.reduce((acc, member) => {
    if (typeof member === "string") {
      if (!acc[member]) {
        acc[member] = member;
      }
      return acc;
    }
    const identityId = member?.[idKey] || member?.identityId;
    if (!acc[identityId]) {
      acc[identityId] = member;
    }
    return acc;
  }, []);

  const uniqueMembers = Object.values(removeDuplicates);

  const sameAuthorColor = uniqueMembers?.reduce((acc, prev, index) => {
    return {
      ...acc,
      [typeof prev === "object" ? prev?.[nameKey] || prev?.nameOfUser : prev]:
        repeatelem(defaultColors, members?.length)[index],
    };
  }, {});

  return (
    <Avatar.Group
      className={className}
      maxCount={maxCount}
      maxStyle={{
        color: "#fff",
        backgroundColor: defaultColors[8],
        width: "35px",
        height: "35px",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid white",
        ...groupStyle,
      }}
    >
      {uniqueMembers?.map((member, i) => {
        const memberName =
          typeof member === "object"
            ? member?.[nameKey] || member?.nameOfUser
            : member;

        return (
          <Tooltip title={memberName} key={i}>
            <Avatar
              shape="circle"
              src={fetchPhoto && <ImageCustom name={memberName} />}
              style={{
                backgroundColor: `${sameAuthorColor?.[memberName]}`,
                width: "35px",
                height: "35px",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid white",
                gap: "5px",
                ...avatarStyle,
              }}
            >
              {getInitials(memberName)}
            </Avatar>
          </Tooltip>
        );
      })}
    </Avatar.Group>
  );
};

const ImageCustom = ({ src = "", style = {}, className = "", name }) => {
  const { imageSrc, handleGetImageSrc, houseKeeping } = useGetImageSrc();
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);

  const isValidImage = (imgSrc) => {
    if (!imgSrc) return false;
    const image = new Image();
    image.src = imgSrc;
    return image.complete && image.naturalWidth > 0;
  };

  useEffect(() => {
    const user = userConfiguration?.allUsers?.Items?.find?.(
      (el) => el?.nameOfUser === name
    );

    if (
      user &&
      !!user?.googleDriveFileId &&
      !!user?.googleDriveFileId !== "" &&
      accessToken
    ) {
      handleGetImageSrc(user?.googleDriveFileId);
    }

    return () => houseKeeping();
  }, [src, userConfiguration, accessToken]);

  return !!imageSrc && isValidImage(imageSrc) ? (
    <img src={imageSrc} alt={""} style={style} className={className} />
  ) : (
    <div>{getInitials(name)}</div>
  );
};
