function getReportCardOptionsWithRemove({
  chartType,
  updateChartType,
  onRemove,
  onHide,
  cardKey,
  isDarkMode,
  datasets,
  filter,
  clearFilter,
}) {
  function children(dataset) {
    return [
      {
        label: "Line Chart",
        key: `CHART_TYPE_LINE${dataset?.label || ""}`,
        onClick() {
          if (chartType !== "Line") {
            updateChartType("Line");
          }
          if (dataset?.type) {
            updateChartType("line", dataset);
          }
        },
      },
      {
        label: "Vertical Bar",
        key: `CHART_TYPE_VERTICAL${dataset?.label || ""}`,
        onClick() {
          if (chartType !== "Vertical") {
            updateChartType("Vertical");
          }
          if (dataset?.type) {
            updateChartType("bar", dataset);
          }
        },
      },
      {
        label: "Horizontal Bar",
        key: `CHART_TYPE_HORIZONTAL${dataset?.label || ""}`,
        onClick() {
          if (chartType !== "Horizontal") {
            updateChartType("Horizontal");
          }
          if (dataset?.type) {
            updateChartType("bar", dataset);
          }
        },
      },
      {
        label: "Pie Chart",
        key: `CHART_TYPE_PIE${dataset?.label || ""}`,
        onClick() {
          if (chartType !== "Pie") {
            updateChartType("Pie");
          }
          if (dataset?.type) {
            updateChartType("pie", dataset);
          }
        },
      },
      {
        label: "Doughnut Chart",
        key: `CHART_TYPE_DOUGHNUT${dataset?.label || ""}`,
        onClick() {
          if (chartType !== "Doughnut") {
            updateChartType("Doughnut");
          }
          if (dataset?.type) {
            updateChartType("doughnut", dataset);
          }
        },
      },
    ];
  }

  if (datasets?.length) {
    const filterDropdown = clearFilter
      ? {
          label: "Clear Filter",
          title: "clearFilter",
          key: "CLEAR_FILTER",
          onClick: () => clearFilter(cardKey),
        }
      : {
          label: "Filter",
          title: "filter",
          key: "FILTER",
          onClick: () => filter(cardKey),
        };

    return datasets
      .map((dataset) => {
        return {
          label: dataset?.label,
          key: dataset?.label,
          popupClassName: `layout-card-dropdown-sub ${
            isDarkMode ? " layout-dropdown-sub-dark" : ""
          }`,
          children: children(dataset),
        };
      })
      .concat(
        [
          filter && filterDropdown,
          {
            label: "Hide",
            title: "hide",
            key: "HIDE",
            onClick() {
              onHide(cardKey);
            },
          },
          {
            label: "Remove",
            title: "remove",
            key: "REMOVE",
            onClick() {
              onRemove(cardKey);
            },
          },
        ].filter(Boolean)
      );
  } else {
    const filterDropdown = clearFilter
      ? {
          label: "Clear Filter",
          title: "clearFilter",
          key: "CLEAR_FILTER",
          onClick: () => clearFilter(cardKey),
        }
      : {
          label: "Filter",
          title: "filter",
          key: "FILTER",
          onClick: () => filter(cardKey),
        };

    return [
      {
        label: "Chart Type",
        key: "CART_TYPE",
        popupClassName: `layout-card-dropdown-sub ${
          isDarkMode ? " layout-dropdown-sub-dark" : ""
        }`,
        children: children(),
      },
      filter && filterDropdown,
      {
        label: "Hide",
        title: "hide",
        key: "HIDE",
        onClick() {
          onHide(cardKey);
        },
      },
      {
        label: "Remove",
        title: "remove",
        key: "REMOVE",
        onClick() {
          onRemove(cardKey);
        },
      },
    ];
  }
}

export default getReportCardOptionsWithRemove;
