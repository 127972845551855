import React from "react";
import "./dataEntryGrid.scss";
import "react-quill/dist/quill.snow.css";
import { Alert, Button, Col, Collapse, Empty, message, Row } from "antd";
// import Loader from "react-loader-spinner";
import isEqual from "lodash/isEqual";
import _ from "lodash";
import {
  AddonEditor,
  CheckboxRenderer,
  Header,
  MultipleChoiceRenderer,
  rtfEditor,
  ScopeSelectorModal,
  ServiceMenuContainer,
  SidewalkShedAddonsDetail,
  SidewalkShedPPUAdvisor,
  SidewalkShedRentAdvisor,
  StatusPanel,
  rtfEditorAddon,
} from "./subcomponents";
import { calculatePrice, getMaxNoFeet } from "./tools/formatters";
import {
  appendPLIRow,
  duplicatePLIRow,
  removePLIRow,
} from "./tools/controllers";
import SidewalkShedAddonsRenderer from "./subcomponents/SidewalkShed/SidewalkShedAddonsDetail/SidewalkShedAddonsRenderer";
import { defaultColDef } from "./tools/columnDefiners/ServiceColumnDefiner";
import { calculateRent } from "./tools/formatters/pricing";
import { PriceSchemeModal } from "./tools/controllers/modals";
import { getSelectedPriceSchemesForService } from "./tools/formatters/evaluators";
import {
  saveEstimation,
  saveProject,
} from "./tools/apicalls/estimationANDproject";
import { API } from "aws-amplify";
import { getNeededDataForDataEntryGrid } from "./tools/apicalls/main";
import { Service } from "./models/Service";
// import {SidewalkShedPLI} from "./models/SidewalkShedModels";
import {
  clearServices,
  handleBreakdownsOnProjects,
} from "./tools/apicalls/validators";
import { AddonType } from "../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { checkIncomingData } from "./tools/controllers/checkIncomingData";
import GeneralAddonEditor from "./subcomponents/cellRenderers/AddonEditor/GeneralAddonEditor";
import SelectEditor from "./subcomponents/cellRenderers/SelectEditor/SelectEditor";
import {
  appendElevation,
  resetAllElevationsId,
} from "./subcomponents/elevationPanel/elevationPanel";
import {
  documentationAddons,
  serviceAddons,
} from "./subcomponents/renderingFunctions/serviceAddons";
import ServiceHeader from "./subcomponents/ServiceHeader/ServiceHeader";
import ElevationPanelHeader from "./subcomponents/elevationPanel/ElevationPanelHeader";
import ElevationPanelExtra from "./subcomponents/elevationPanel/ElevationPanelExtra";
import { ElevationContent } from "./subcomponents/elevationPanel/ElevationContent";
import OptionHeader from "./subcomponents/option/OptionHeader";
import JumpRenderer from "./subcomponents/cellRenderers/jumpRenderer";
import ApprovedRenderer from "./subcomponents/cellRenderers/approvedRenderer";
import { checkIfRowHasValidData } from "./tools/formatters/validators";
import { getRawAllServicesTotals } from "./tools/formatters/totals";
import ClassRedux from "../../utils/classRedux";
import { ServiceDocumentation } from "./subcomponents/documentation/ServiceDocumentation";
import {
  replaceArrayElement,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import { ConfirmationModal } from "../../../commonComponents";
import { DimensionEditor } from "./subcomponents/cellEditors";
import { PriceTablePPURenderer } from "./subcomponents/cellRenderers";

import { TakeOffContext } from "./context";
import { connect } from "react-redux";
import { getRandomColor } from "../../utils";
import { isNumber } from "chart.js/helpers";
import { defaultScopeColumnDefs } from "../../../pages/Settings/settingsComponents/OtherScopes";
import { onOkHandler } from "../../SubLeads/subLeadsComponents/filterModalData";
import IncludesExcludes from "./subcomponents/includeExcludes/IncludesExcludes";
import "./../../../../components/pages/Settings/settingsComponents/Pricing/Pricing.scss";
import ServiceAddon from "./ServiceAddon";
import { state } from "../../../pages/Settings/settingsComponents/Roles/RolesData";
import EstimationSecondaryHeader from "./subcomponents/Header/EstimationSecondaryHeader";
import { PlusCircleFilled } from "@ant-design/icons";
import { CircleSpinner } from "../../../commonComponents/3LoadingDots/LoadingDots";
const { Panel } = Collapse;

// LicenseManager.setLicenseKey(agGridTrialKey);

class DataEntryGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      serviceDefs: null,
      ServicesIndex: {}, //{"Sidewalk Shed": 1, ...}]
      ServiceNameByIndex: {}, //{1: "Sidewalk Shed", ...}
      gridData: null,
      pricingData: [],
      selectOptions: {},
      activeKey: null,
      activePanels: [[[]]],
      activePriceSheetModal: false, //when it has a serviceId number, it means that modal is open for that service
      selectedPriceSchemesForService: {}, // {serviceId: {priceScheme: PriceScheme, priceSchemesType: "sheet"|"list"}, }
      statusBar: {
        statusPanels: [{ statusPanel: "StatusPanel" }],
      },
      priceView: false,
      priceEdit: false,
      saveInProjectModal: false,
      currentFocusedInitialValue: "",
      serviceGridKey: 0,
      elevationTotalKey: 0,
      agSelectedCells: {},
      fullScreenService: null,
      ServiceMenuContainerShow: true,
      errorMessages: [],
      AutoSave: true,
      project_OR_opportunity: undefined, //project|opportunity
      //  agGridTheme: this.props.agGridTheme,
      UIFeaturesForEachElevation: {}, // this.UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex] = {data}
      loading: true,
      accessRights: [],
      oppOfCurrentEstimation: null,
      scopeSelectorModalVisible: false, //used for adding elevations to "Other Scope" service
      addonsRowData: [],
      indexSelected: 0,
      currentEstimationState: [],
      itemToSaveActiveState: [],
      agGridKey: 0,
    };
    this.changeStateHandler = this.changeStateHandler.bind(this);
    this.canUndo = this.canUndo.bind(this);
    this.canRedo = this.canRedo.bind(this);
  }

  frameworkComponents = {
    StatusPanel,
    CheckboxRenderer,
    JumpRenderer,
    ApprovedRenderer,
    rtfEditor,
    rtfEditorAddon,
    MultipleChoiceRenderer,
    SidewalkShedAddonsDetail,
    SidewalkShedAddonsRenderer,
    SidewalkShedPPUAdvisor,
    SidewalkShedRentAdvisor,
    AddonEditor,
    GeneralAddonEditor,
    SelectEditor,
    DimensionEditor,
    PriceTablePPURenderer,
  };

  defaultColDef = defaultColDef;
  lastAgGridInstance = undefined;
  agGridApiForEachElevation = {}; //is accessed like this.agGridApiForEachElevation[serviceId][optionIndex][elevationIndex].api.setRowData()
  addonsAgGridInstances = {};
  // UIFeaturesForEachElevation = {}  // this.UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex] = {data}

  /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ HANDLERS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/

  //CALLING THE METHODS ABOVE TO MAKE POSSIBLE ESTIMATIONS GETTING SAVED IN BOTH COMPANIES
  saveThisEstimation = async () => {
    await this.props.saveEstimation(this.state.gridData);
  };

  saveServicesInOpp = () => {
    let oppEstimation = _.cloneDeep(this.state.oppOfCurrentEstimation);
    let servicesListCopy = _.cloneDeep(this.state.gridData);
    let { proposedTypeOfWork, opportunityId } = oppEstimation;
    let serviceList = servicesListCopy.map((service) =>
      service.serviceId.toString()
    );
    API.patch("opportunities", `/opportunities/${opportunityId}`, {
      body: { proposedTypeOfWork: [...new Set(serviceList)] },
    }).then((res) =>
      this.setState({
        oppOfCurrentEstimation: {
          ...this.state.oppOfCurrentEstimation,
          proposedTypeOfWork: serviceList,
        },
      })
    );
  };

  // handles save. also used in autosave mode
  /**
   * Save services into estimation. It is used all the time when we make changes while in auto-save mode.
   * @param willNotify {boolean?} - Will there be a notification on top that data was saved?
   * @param force {boolean?} - it is only used (as true) when pressing the Quick Save button, which will perform this save no matter this.state.AutoSave is enabled or not.
   * */

  handleSave = async (willNotify, force) => {
    if (this.state.AutoSave === false && !force) return; //if auto save is OFF and it is not forced do not save anything
    try {
      await this.saveThisEstimation();
      if (this.state.estimation.accountId === "") {
        await this.props.saveServicesInOppHandler(this.saveServicesInOpp);
      }
      if (willNotify) {
        message.success("Successfully saved.");
      }
    } catch (err) {
      this.setState({ loading: false });
      message.error("Something went wrong...");
      // console.error("core services PUT error: " + err);
    }
  };

  //save these services in project services
  handleSaveProject = async (isFromModal) => {
    if (
      this.props.isWritable &&
      this.state.project_OR_opportunity === "project"
    ) {
      try {
        let servicesToSave = _.cloneDeep(this.state.gridData);
        try {
          servicesToSave = handleBreakdownsOnProjects(
            servicesToSave,
            this.props.serviceDefinitions
          ); //Add breakdown for each pli
        } catch (e) {
          console.error(e);
        }
        clearServices(servicesToSave); //service without elevations will be removed, options without elevations will be removed, elevation without any pli will be removed
        let projectServices = _.cloneDeep(this.state.project.services);
        projectServices[this.state.estimation.estimationId] = servicesToSave;
        await this.props.saveProject(projectServices, this.state.gridData);
        message.info("Data saved successfully in project.");
      } catch (e) {
        console.error(e);
        message.error("Could not save the project");
      }
    } else {
      message.error(
        "Can not save the project. Enable write mode and make sure you are working in a project not an opportunity."
      );
    }
  };

  //when we change a value in elevation inputs such as rent and price
  handleElevationFieldChange = (
    e,
    serviceIndex,
    optionIndex,
    elevationIndex,
    reference
  ) => {
    let value;
    if (reference === "elevationLabel") {
      //if text
      value = e.target.innerText;
    } else if (reference === "additionalRentalTerms") {
      value = e || {};
    } else if (reference === "rent" || reference === "price") {
      //if rent or price
      value = parseFloat(e.target.innerText).toFixed(2); //formatForPricingNumberInput(e.target.innerText);
    } else {
      message.warning(
        "Elevation edits are not being saved. Contact with support team."
      );
      return;
    }
    if (value !== this.state.currentElevationName) {
      let gridData = [...this.state.gridData];
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex][
        reference
      ] = value;
      console.log("testAr", { gridData });
      this.updateStateAndSave(gridData);
    }
  };

  /**
   * Comes from Manage Alternates > Alternate X > Duplicate/Remove, or Manage Alternates > New alternate
   * @param serviceIndex {number}
   * @param optionIndex {number|null} 0 means primary; higher than 0 means an alternate
   * @param action {'new'|'duplicate'|'remove'}
   * @param data {null|SidewalkShedPLI[]|[]} if an array of PLI is passed, it means that we have a duplication
   **/
  handleModifyOption = (serviceIndex, optionIndex, action, data) => {
    let gridData = _.cloneDeep(this.state.gridData);
    if (action === "new") {
      gridData[serviceIndex].serviceOptions.push(data);
    } else if (action === "duplicate") {
      gridData[serviceIndex].serviceOptions.push(data);
      //reset all elevation id
      resetAllElevationsId(this.state.serviceDefs, gridData, serviceIndex);
    } else if (action === "convertPrimaryToAlternate") {
      message.info("Converting primary to alternate...");
    } else {
      // 'remove'
      gridData[serviceIndex]?.serviceOptions?.splice(optionIndex, 1);
      resetAllElevationsId(this.state.serviceDefs, gridData, serviceIndex);
    }

    this.updateStateAndSave(gridData);
  };

  //a function which is passed to some child components to easily set state here of whatever we want
  stateSetter = (data) => {
    return new Promise((resolve) =>
      this.setState(data, () => {
        resolve();
      })
    );
  };
  // appends a row below another row
  appendPLIRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!this.props.isWritable)
      return message.warning("Please enable write mode!");
    const hasAccessRight = this.state.accessRights?.children?.find?.(
      (item) => item.title === "Adding/Removing pli's"
    )?.write;
    if (!hasAccessRight)
      return message.warning("You don't have access to add PLI");

    const service = this.state.gridData[serviceIndex];
    const { serviceOptions, isScope } = service;
    // console.warning("service", service);

    if (!isScope) {
      appendPLIRow(
        this.state.ServicesIndex,
        this.props.isWritable,
        this.state.gridData,
        this.stateSetter,
        gridApi,
        serviceIndex,
        optionIndex,
        elevationIndex,
        itemId,
        this.state.accessRights
      );
    } else {
      const newService = {
        ...service,
        serviceOptions: [
          serviceOptions[0].map((elevation, idx) => {
            // Returns this scopes columnDefinitions so we can get the initial values
            const { columnDefinitions } = this.props.serviceDefinitions.find(
              ({ serviceId }) => serviceId === elevation.elevationId
            );

            // Gets the max existing pli id and adds 1
            const newPliId = _.max(elevation.items.map(({ id }) => id)) + 1;

            return idx !== elevationIndex
              ? elevation
              : // Returns everything this elevation has plus the new pli
                {
                  ...elevation,
                  items: [
                    ...elevation.items,

                    // New pli
                    {
                      ..._.unionBy(
                        columnDefinitions,
                        defaultScopeColumnDefs,
                        "field"
                      ).reduce(
                        (acc, { field, initialValue }) => ({
                          ...acc,
                          [field]: initialValue,
                        }),
                        {}
                      ),
                      id: newPliId,
                    },
                  ],
                };
          }),
        ],
      };

      const newGridData = replaceArrayElement(
        this.state.gridData,
        newService,
        "serviceId"
      );

      this.updateStateAndSave(newGridData);
    }
  };

  //duplicates a PLI row below another row
  duplicatePLIRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!this.props.isWritable)
      return message.warning("Please enable write mode!");
    const hasAccessRight = this.state.accessRights.children.find(
      (item) => item.title === "Adding/Removing pli's"
    ).write;
    if (!hasAccessRight)
      return message.warning("You don't have access to duplicate PLI");

    const service = this.state.gridData[serviceIndex];
    const { serviceId, serviceOptions, isScope } = service;

    if (!isScope) {
      duplicatePLIRow(
        this.state.ServicesIndex,
        this.props.isWritable,
        this.state.gridData,
        this.stateSetter,
        gridApi,
        serviceIndex,
        optionIndex,
        elevationIndex,
        itemId,
        this.state.accessRights
      );
    } else {
      const newService = {
        ...service,
        serviceOptions: [
          serviceOptions[0].map((elevation, idx) => {
            // Gets the max existing pli id and adds 1
            const newPliId = _.max(elevation.items.map(({ id }) => id)) + 1;

            return idx !== elevationIndex
              ? elevation
              : // Returns everything this elevation has plus the new pli
                {
                  ...elevation,
                  items: [
                    ...elevation.items,
                    // new duplicated pli
                    {
                      ...elevation.items.find(({ id }) => id === itemId),
                      id: newPliId,
                    },
                  ],
                };
          }),
        ],
      };

      const newGridData = replaceArrayElement(
        this.state.gridData,
        newService,
        "serviceId"
      );

      this.updateStateAndSave(newGridData);
    }
  };

  // removes a row and creates a new one if there are no rows left
  removePLIRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    removePLIRow(
      this.state.ServicesIndex,
      this.props.isWritable,
      this.state.gridData,
      this.stateSetter,
      gridApi,
      serviceIndex,
      optionIndex,
      elevationIndex,
      itemId,
      this.state.accessRights
    );
  };

  // keeps track of active panels
  handleCollapseChange = (value, serviceIndex, optionIndex) => {
    let activePanels = [...this.state.activePanels];
    if (activePanels[serviceIndex]) {
      activePanels[serviceIndex][optionIndex] = value;
    } else {
      activePanels[serviceIndex] = [][optionIndex] = value;
    }
    this.setState({ activePanels: activePanels });
  };

  // collapses all elevations in every option
  collapseAllElevations = (serviceIndex) => {
    let newActivePanels = [...this.state.activePanels];
    newActivePanels[serviceIndex] = [[]];
    this.setState({ activePanels: newActivePanels });
  };

  // expands all elevations in every option
  expandAllElevations = (serviceIndex) => {
    let newActivePanels = [...this.state.activePanels];

    newActivePanels[serviceIndex] = this.state.gridData[
      serviceIndex
    ].serviceOptions.map((options) => {
      return options.map((elevation) => {
        return elevation.elevationId;
      });
    });

    this.setState({ activePanels: newActivePanels });
  };

  // Add addons Total amount.
  getAddonsCaption = (rowData = [], isReadOnly) => {
    let addonsTotalPrice = rowData
      .map(({ values, id }) => {
        let newValue = values.find(
          ({ name }) => name.toLowerCase() === "price"
        );
        return Number(
          isReadOnly.find((row) => row.id === id)?.value ||
            newValue?.defaultValue ||
            0
        );
      })
      .reduce((a, b) => (a += b), 0);

    return `${rowData.length} addons (Total: $${addonsTotalPrice.toFixed(2)})`;
  };

  resizeGridRows = (gridApi) => {
    gridApi.resetRowHeights();
  };

  getRowNodeId = (data) => {
    return data.id;
  };

  /**
   * Save changes of Addons in database with this function. This function calls this.handleSave() to save in database.
   * @param gridApi {{}} //ag grid instance
   * @param serviceIndex {Number} The service number example: 0 is the first service in page
   * @param optionIndex {Number}
   * @param elevationIndex {Number} what elevation are we editing (counting starts from 0)
   * @param itemId {Number} The id of PLI that we want to modify
   * @param addonsData {Object[]} An array with addons in it. The data we want to save.
   * @param addonAPI {{addonId, api}}
   * */
  saveSidewalkShedAddonsData = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    addonsData,
    addonAPI
  ) => {
    let newGridData = _.cloneDeep(this.state.gridData);
    let items;
    // if(gridData[serviceIndex].serviceId === 3) {
    //   items = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].item.test.items
    // } else {
    //   items = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
    // }
    items =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .items;

    for (const pli of items) {
      if (pli.id === itemId) {
        if (newGridData[serviceIndex].serviceId !== 1) {
          //if not in sidewalk shed, manipulate data
          for (let i = 0; i < addonsData.length; i++) {
            let generatedAddon = {};
            for (const key in addonsData[i]) {
              generatedAddon[key.toLowerCase()] = addonsData[i][key];
            }
            addonsData[i] = generatedAddon;
          }
        }

        pli.addons = addonsData;

        gridApi.applyTransaction({ update: [pli] });
        // Removed Addon PLI from grid by commenting this line
        // gridApi.refreshCells({ rowNodes: [pli], force: true });
        break;
      }
    }
    // gridApi.setRowData(items)
    // gridApi.redrawRows()
    this.updateStateAndSave(newGridData);
    // gridApi.getRowNode(itemId).setExpanded(true);
    // if (addonAPI) {
    //   const { addonId, api } = addonAPI;
    //   api.forEachNode((n) => {
    //     if (n.data.id === addonId) {
    //       n.setExpanded(true);
    //     }
    //   });
    // }
  };

  saveHoistAddonsData = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    addonsData
  ) => {
    let newGridData = _.cloneDeep(this.state.gridData);
    for (const item of newGridData[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items) {
      if (item.id === itemId) {
        item.addons = addonsData;
        break;
      }
    }

    this.setState({ gridData: newGridData }, () => {
      gridApi.setRowData(
        newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
          .items
      );
      this.handleSave(false);
    });
  };

  onAddonRichTextChange = ({
    service,
    addonType,
    addons,
    serviceIndex,
    optionIndex,
    elevationIndex,
    pliId,
  }) => {
    let gridData = _.cloneDeep(this.state.gridData);
    if (addonType === AddonType.serviceAddon || addonType === "documentation") {
      let correctService = gridData.find(
        (s) => s.serviceId === service.serviceId
      );
      correctService.serviceAddons = service.serviceAddons;
    } else if (addonType === AddonType.pli) {
      let pli;
      // if(gridData[serviceIndex].serviceId === 3) {
      //   pli = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].item.test.items.find(pli => pli.id === pliId)
      // } else {
      pli = gridData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items.find((pli) => pli.id === pliId);
      // }
      pli.addons = addons;
    }

    this.updateStateAndSave(gridData);
  };

  // handles input change
  handleInputElementChange = (
    e,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemReference
  ) => {
    let newGridData = _.cloneDeep(this.state.gridData);
    _.update(
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .item,
      itemReference,
      () => e.target.value
    );
    this.setState({ gridData: newGridData });
  };

  //when we change ppu in service level
  handlePricePerUnitChange = (e, serviceId, saveInDatabase) => {
    let hasWriteAccess = this.state.accessRights.children.find(
      (item) => item.title === "Price Edit"
    ).write;

    if (!this.props.isWritable) {
      message.error("Please enable write mode");
      return;
    }
    if (!hasWriteAccess) {
      message.error("You have no access to edit");
      return;
    }
    if (isNaN(e) || e === Infinity) e = 0;
    let value = parseFloat(e); // formatForPricingNumberInput(e.target.value.toString()) // dollarFormatter(e.target.value)
    //calculate new prices
    const newGridData = _.cloneDeep(this.state.gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems =
          /*this.state.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/ elevation.items;
        for (const pli of iterableItems) {
          if (!pli.lock) {
            //if it is not locked
            pli.ppu = value;
            pli.price = calculatePrice(serviceId, pli);
            pli.rent = calculateRent(service, pli);
            pli.totalPrice = pli.price + pli.rent;
          }
        }
        if (
          this.agGridApiForEachElevation &&
          this.agGridApiForEachElevation[serviceId] &&
          this.agGridApiForEachElevation[serviceId][optionIndex] &&
          this.agGridApiForEachElevation[serviceId][optionIndex][elevationIndex]
        ) {
          //if ag-grid api have been initialised for this elevation
          //example: if elevation has not been expanded, it has not been initialised
          this.agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.setRowData(elevation.items);
          this.agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.redrawRows();
        }
      });
    });
    //update ppu in the service
    service.pricePerUnit = value;
    if (saveInDatabase) this.updateStateAndSave(newGridData);
    else this.setState({ gridData: newGridData });
  };

  handleDefaultPricePerUnitChange = (e, serviceId, saveInDatabase, type) => {
    let hasWriteAccess = this.state.accessRights.children.find(
      (item) => item.title === "Price Edit"
    ).write;

    if (!this.props.isWritable) {
      message.error("Please enable write mode");
      return;
    }
    if (!hasWriteAccess) {
      message.error("You have no access to edit");
      return;
    }
    if (isNaN(e) || e === Infinity) e = 0;
    let value = parseFloat(e); // formatForPricingNumberInput(e.target.value.toString()) // dollarFormatter(e.target.value)
    //calculate new prices
    const newGridData = _.cloneDeep(this.state.gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems =
          /*this.state.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/ elevation.items;
        for (const pli of iterableItems) {
          if (!pli.lock && pli?.isInitial) {
            //if it is not locked
            if (type !== "rent") {
              pli.ppu = value;
              pli.price = calculatePrice(serviceId, pli);
              pli.rent = calculateRent(service, pli);
              pli.totalPrice = pli.price + pli.rent;
            } else {
              pli.rent = calculateRent({ ...service, rent: value }, pli);
              pli.price = calculatePrice(serviceId, pli);
              pli.totalPrice = pli.price + pli.rent;
            }

            if (
              this.agGridApiForEachElevation &&
              this.agGridApiForEachElevation[serviceId] &&
              this.agGridApiForEachElevation[serviceId][optionIndex] &&
              this.agGridApiForEachElevation[serviceId][optionIndex][
                elevationIndex
              ]
            ) {
              //if ag-grid api have been initialised for this elevation
              //example: if elevation has not been expanded, it has not been initialised
              this.agGridApiForEachElevation[serviceId][optionIndex][
                elevationIndex
              ].api.setRowData(elevation.items);
              this.agGridApiForEachElevation[serviceId][optionIndex][
                elevationIndex
              ].api.redrawRows();
            }
          }
        }
      });
    });
    //update ppu in the service
    if (type !== "rent") service.defaultPricePerUnit = value;
    else if (type === "rent") service.initialRent = value;
    this.setState({ gridData: newGridData });
  };
  //when we blur some inputs an autosave is performed
  onInputBlur = () => {
    this.handleSave();
  };

  //when price scheme changes
  updateSWSPPU = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    value
  ) => {
    let gridData = _.cloneDeep(this.state.gridData);
    for (let item of gridData[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items) {
      if (item.id === itemId) {
        item.ppu = value;
        // item.lock = true;
        item.price = value * getMaxNoFeet(item.length);
        item.rent = calculateRent(gridData[serviceIndex], item, optionIndex);
        item.taxAmount = item.taxRate * item.price;
        item.totalPrice = item.price + item.taxAmount;
        gridApi.applyTransaction({ update: [item] }); //add it into ag-grid table
        gridApi.refreshCells({
          force: true,
          columns: ["ppu"],
          rowNodes: [item],
        });
        break;
      }
    }
    this.updateStateAndSave(gridData);
  };

  //handles when rent in service level changes. Unlocked plis need to be updated
  handleRentPerServiceChange = (e, serviceId, saveInDatabase) => {
    const newGridData = _.cloneDeep(this.state.gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    if (isNaN(e) || e === Infinity) e = 0;
    service.rent = parseFloat(e);

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems =
          /*this.state.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/ elevation.items;
        for (const pli of iterableItems) {
          if (!pli.lock) {
            //if it is unlocked
            pli.rent = calculateRent(service, pli);
          }
        }
        if (
          this.agGridApiForEachElevation &&
          this.agGridApiForEachElevation[serviceId] &&
          this.agGridApiForEachElevation[serviceId][optionIndex] &&
          this.agGridApiForEachElevation[serviceId][optionIndex][elevationIndex]
        ) {
          //if ag-grid api have been initialised for this elevation
          //example: if elevation has not been expanded, it has not been initialised
          this.agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.setRowData(elevation.items);
          this.agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.redrawRows();
        }
      });
    });

    if (saveInDatabase) this.updateStateAndSave(newGridData);
    else this.setState({ gridData: newGridData });
  };

  //handles when rent in service level changes. Unlocked plis need to be updated
  handleAlternateRentPerServiceChange = (e, serviceId, saveInDatabase) => {
    const newGridData = _.cloneDeep(this.state.gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    if (isNaN(e) || e === Infinity) e = 0;
    service.alternateRent = parseFloat(e);

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems =
          /*this.state.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/ elevation.items;
        for (const pli of iterableItems) {
          if (!pli.lock) {
            //if it is unlocked
            pli.rent = calculateRent(service, pli);
          }
        }
        if (
          this.agGridApiForEachElevation &&
          this.agGridApiForEachElevation[serviceId] &&
          this.agGridApiForEachElevation[serviceId][optionIndex] &&
          this.agGridApiForEachElevation[serviceId][optionIndex][elevationIndex]
        ) {
          //if ag-grid api have been initialised for this elevation
          //example: if elevation has not been expanded, it has not been initialised
          this.agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.setRowData(elevation.items);
          this.agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.redrawRows();
        }
      });
    });
    if (saveInDatabase) this.updateStateAndSave(newGridData);
    else this.setState({ gridData: newGridData });
  };

  //when we are using quill editor as custom component in ag-grid for notes and description ususally
  saveDataFromRTDEditor = (
    items,
    serviceIndex,
    optionIndex,
    elevationIndex
  ) => {
    const gridDataCopy = _.cloneDeep(this.state.gridData);
    gridDataCopy[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items = items;

    this.updateStateAndSave(gridDataCopy);
  };

  /**
   * @param gridData {Service[]}
   * */
  updateStateAndSave = (newGridData) => {
    this.setState({ gridData: newGridData }, () => {
      newGridData?.forEach?.(({ serviceId, serviceAddons, serviceOptions }) => {
        serviceOptions?.forEach?.((alternate, alternateIdx) =>
          alternate?.forEach?.(({ items = [] }, elevationIndex) => {
            const api =
              this.agGridApiForEachElevation?.[serviceId]?.[alternateIdx]?.[
                elevationIndex
              ]?.api;
            api?.setRowData?.(items);
            // api?.redrawRows?.()
          })
        );

        this.addonsAgGridInstances?.[+serviceId]?.[
          "service addon"
        ]?.api?.setRowData?.(serviceAddons);
      });

      this.handleSave();
    });
  };

  handleNumbersOnly = (e) => {
    let x = e.charCode || e.keyCode;
    if (
      isNaN(String.fromCharCode(x)) &&
      (x !== 46 ||
        x === 32 ||
        x === 13 ||
        (x === 46 && e.target.innerText.includes(".")))
    ) {
      e.preventDefault();
    }
  };

  //when clicking full screen button
  fullscreenizeService = (serviceId) => {
    this.setState({
      fullScreenService: serviceId,
      ServiceMenuContainerShow: false,
    });
  };

  //when clicking exit full screen
  deFullscreenize = () => {
    this.setState({ fullScreenService: null, ServiceMenuContainerShow: true });
  };

  /**
   * Comes from HandleShowSidewalkShedPriceSheet. This will change status with the selected price scheme. If user has pressed cancel we will not do anything.
   * @param selectedPriceSchemeId {String | undefined} If an id comes, it meas that user have pressed OK button and want to use the selected price scheme.
   * If it comes as undefined, user have pressed CANCEL and it meas that he/she wants to continue with the existing price scheme.
   * */
  onPriceSchemeModalClose = (selectedPriceSchemeId) => {
    if (!this.props.isWritable && selectedPriceSchemeId !== undefined) {
      //if we are not in write mode and user attempt to change price scheme
      message.error("Please enable write mode");
      this.setState({ activePriceSheetModal: false }); //close modal without changes
    } else {
      if (selectedPriceSchemeId === undefined)
        this.setState({ activePriceSheetModal: false });
      //if nothing selected, just close the modal
      else {
        //find the correct scheme in this.state.gridData, via activePriceSheetModal (which contains the opened service) and selectedPriceSchemeId
        const selectedPriceSchemesForService = _.cloneDeep(
          this.state.selectedPriceSchemesForService
        );
        const pricingData = _.cloneDeep(this.state.pricingData);
        const correctPricingForService = pricingData.find(
          (s) =>
            s.serviceId.toString() ===
            this.state.activePriceSheetModal.toString()
        );
        const correctPricingObject = correctPricingForService.pricingObject;
        selectedPriceSchemesForService[this.state.activePriceSheetModal] = {
          priceScheme: correctPricingObject.priceSchemes.find(
            (ps) => ps.id === selectedPriceSchemeId
          ),
          priceSchemesType: correctPricingObject.priceSchemesType,
        };

        //update the selectedPriceSchemeId to estimation
        let estimation = _.cloneDeep(this.state.estimation);
        this.state.gridData.find(
          (s) => s.serviceId === this.state.activePriceSheetModal
        ).selectedPriceSchemeId = selectedPriceSchemeId;

        estimation = { ...estimation, services: this.state.gridData }; //update estimation

        if (this.lastAgGridInstance) this.lastAgGridInstance.api.redrawRows(); //handle to update grid

        //update this.state.activePriceSheetModal and set activePriceSheetModal: false to close the modal
        this.setState(
          {
            selectedPriceSchemesForService,
            pricingData,
            estimation,
            activePriceSheetModal: false,
          },
          () => {
            this.handleSave();
          }
        );
      }
    }
  };

  /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ EVENTS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
  // called after component mounts. used for asynchronous requests
  // async componentDidMount() {
  //   // await this.initGrid()
  //     let oppId = this.state.estimation?.opportunityId
  //     await API.get("opportunities", `/opportunities/${oppId}`).then(
  //       (res) => {
  //         this.setState({oppOfCurrentEstimation: res})
  //       }
  //     )
  // }

  //initialise everything just as we open the page for the first time
  initGrid = async () => {
    this.setState({ loading: true });
    try {
      const { estimation } = this.props;

      const {
        pricingData,
        proposedTypeOfWork,
        // estimation,
        priceView,
        priceEdit,
        projectData,
        units,
      } = await getNeededDataForDataEntryGrid(
        // this.props.estimationId,
        this.state.userConfiguration,
        estimation
      );

      //create a dynamic enum for services id
      let ServicesIndex = {},
        ServiceNameByIndex = {};
      for (const s of proposedTypeOfWork) {
        ServicesIndex[s.workName] = parseInt(s.workId);
        ServiceNameByIndex[s.workId] = s?.workName; //also create the reverse references, for quicker access
      }
      console.log("ServicesIndex", estimation);
      // modifyPricingServicesToHandleErrors(estimation.services, services, ServicesIndex);
      if (
        estimation.services.length > 0 &&
        typeof estimation.services[0] !== "object"
      ) {
        //if services are in type like ["3", "6", "0"]
        estimation.services = estimation.services.map((serviceId) => {
          return new Service(
            parseInt(serviceId),
            proposedTypeOfWork.find(
              (s) => s.workId === serviceId.toString()
            )?.workName
          );
        });
      }

      let selectedPriceSchemesForService = getSelectedPriceSchemesForService(
        pricingData,
        estimation
      );

      const errorMessages = checkIncomingData({
        project: projectData,
        authenticatedUser: this.state.authenticatedUser,
      });
      if (errorMessages.length > 0) this.setState({ errorMessages });

      let project_OR_opportunity;
      if (projectData?.project) project_OR_opportunity = "project";
      else if (projectData?.opportunity) project_OR_opportunity = "opportunity";

      this.setState(
        {
          serviceDefs: proposedTypeOfWork,
          ServicesIndex,
          ServiceNameByIndex,
          estimation,
          project: projectData?.project,
          opportunity: projectData?.opportunity,
          serviceOrder: [...estimation.services.keys()],
          department: this.state.authenticatedUser?.department,
          priceView,
          priceEdit,
          gridData: Service.parseServicesForFrontend(estimation.services), //this is where all services data lives
          selectedPriceSchemesForService,
          selectOptions: { units },
          project_OR_opportunity,
          pricingData,
          loading: false,
        },
        () => {
          this.setState(() => {
            return {
              itemToSaveActiveState: this.state.gridData, //This will populate itemToSaveActiveState after gridData is filled.
            };
          });
        }
      );
      document.querySelectorAll(".priceCalculation").forEach((element) => {
        element.addEventListener("keypress", this.handleNumbersOnly);
      });
    } catch (e) {
      console.log("data error", e);
      message.error("Couldn't find some data. You can't work here, sorry.");
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    document.querySelectorAll(".priceCalculation").forEach((element) => {
      element.removeEventListener("keypress", this.handleNumbersOnly);
    });
  }

  /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ UNDO REDO START ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/

  canUndo = () => this.state.indexSelected > 0;
  canRedo = () =>
    this.state.indexSelected < this.state.currentEstimationState.length - 1;

  changeStateHandler = (value) => {
    const { itemToSaveActiveState, currentEstimationState, indexSelected } =
      this.state;
    // Use lodash isEqual to check for deep equality
    // If state has not changed, return to avoid triggering a re-render
    if (isEqual(itemToSaveActiveState, value)) {
      return;
    }
    const copy = currentEstimationState.slice(0, indexSelected + 1); // This removes all future (redo) states after current index
    copy.push(value);

    this.setState(
      {
        currentEstimationState: copy,
        indexSelected: copy.length - 1,
      },
      () => {
        this.setState(() => {
          return {
            itemToSaveActiveState:
              this.state.currentEstimationState[this.state.indexSelected],
          };
        });
      }
    );
  };

  // Allows you to go back (undo) N steps
  undoClickHandler = (steps = 1) => {
    this.setState(
      {
        indexSelected: Math.max(
          0,
          Number(this.state.indexSelected) - (Number(steps) || 1)
        ),
      },
      () => {
        this.setState({
          itemToSaveActiveState: JSON.parse(
            JSON.stringify(
              this.state.currentEstimationState[this.state.indexSelected]
            )
          ),
          gridData: JSON.parse(
            JSON.stringify(
              this.state.currentEstimationState[this.state.indexSelected]
            )
          ),
        });
        this.setState({
          agGridKey: Math.max(
            0,
            Number(this.state.agGridKey) + (Number(steps) || 1)
          ),
        });
      }
    );
  };

  // Allows you to go forward (redo) N steps
  redoClickHandler = (steps = 1) => {
    this.setState(
      {
        indexSelected: Math.min(
          this.state.currentEstimationState.length - 1,
          Number(this.state.indexSelected) + (Number(steps) || 1)
        ),
      },
      () => {
        this.setState({
          itemToSaveActiveState: JSON.parse(
            JSON.stringify(
              this.state.currentEstimationState[this.state.indexSelected]
            )
          ),
          gridData: JSON.parse(
            JSON.stringify(
              this.state.currentEstimationState[this.state.indexSelected]
            )
          ),
        });
        this.setState({
          agGridKey: Math.max(
            0,
            Number(this.state.agGridKey) + (Number(steps) || 1)
          ),
        });
      }
    );
  };

  /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ UNDO REDO END ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !isEqual(prevState.gridData, this.state.gridData) &&
      this.state.gridData
    ) {
      this.changeStateHandler(JSON.parse(JSON.stringify(this.state.gridData)));
    }

    if (prevProps.agGridTheme !== this.props.agGridTheme) {
      if (this.lastAgGridInstance) {
        this.lastAgGridInstance.api.redrawRows();
      }
    }
    if (prevProps.isWritable !== this.props.isWritable) {
      if (this.lastAgGridInstance) {
        this.lastAgGridInstance.api.redrawRows(); //if ag grid is ready, re-render all table
      }

      for (const serviceAddonContainer of Object.values(
        this.addonsAgGridInstances
      )) {
        for (const agGridInstance of Object.values(serviceAddonContainer)) {
          agGridInstance.api.refreshCells({
            columns: ["name"],
            force: true,
            suppressFlash: true,
          });
        }
      }
      // this.setState({serviceGridKey: this.state.serviceGridKey + 1,});
    }

    if (this.state.userConfiguration !== prevState.userConfiguration) {
      const accessRights = this.state.userConfiguration?.routeConfig
        ?.find(({ title }) => title === "Estimations/View")
        ?.children.find(({ title }) => title === "Type Of Work");
      this.initGrid();
      this.setState({ accessRights });
    }

    if (this.state.estimation !== prevState.estimation) {
      let oppId = this.state.estimation?.opportunityId;
      API.get("opportunities", `/opportunities/${oppId}`).then((res) => {
        this.setState({ oppOfCurrentEstimation: res });
      });
    }
  }

  // called before grid renders. used for data set and event listeners
  onGridReady = (params, data, serviceId, optionIndex, elevationIndex) => {
    //define columns event listeners

    const columnsAPI = params.columnApi.getAllColumns();

    const UIFeaturesForEachElevation = _.cloneDeep(
      this.state.UIFeaturesForEachElevation
    );

    if (!UIFeaturesForEachElevation[serviceId])
      UIFeaturesForEachElevation[serviceId] = {};
    if (!UIFeaturesForEachElevation[serviceId][optionIndex])
      UIFeaturesForEachElevation[serviceId][optionIndex] = {};
    if (!UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex])
      UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex] = {};

    for (const colAPI of columnsAPI) {
      if (
        !UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex]
          .columns
      )
        UIFeaturesForEachElevation[serviceId][optionIndex][
          elevationIndex
        ].columns = {};
      colAPI.addEventListener("visibleChanged", (event) => {
        UIFeaturesForEachElevation[serviceId][optionIndex][
          elevationIndex
        ].columns[event.column.colId] = {
          ...UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex]
            .columns[event.column.colId],
          visible: event.column.visible,
        };
        this.setState({ UIFeaturesForEachElevation });
      });
    }

    //define ag grid instances for elevation
    this.lastAgGridInstance = params;
    if (!this.agGridApiForEachElevation) this.agGridApiForEachElevation = {};
    if (!this.agGridApiForEachElevation[serviceId])
      this.agGridApiForEachElevation[serviceId] = {};
    if (!this.agGridApiForEachElevation[serviceId][optionIndex])
      this.agGridApiForEachElevation[serviceId][optionIndex] = {};
    if (!this.agGridApiForEachElevation[serviceId][optionIndex][elevationIndex])
      this.agGridApiForEachElevation[serviceId][optionIndex][elevationIndex] =
        {};

    this.agGridApiForEachElevation[serviceId][optionIndex][elevationIndex] =
      params;

    params.api.addEventListener("toolPanelVisibleChanged", (params) => {
      UIFeaturesForEachElevation[serviceId][optionIndex][
        elevationIndex
      ].toolPanelVisible = params.api.isToolPanelShowing();
      this.setState({ UIFeaturesForEachElevation });
    });

    params.api.setDomLayout("autoHeight");

    params.api.setRowData(data);

    // params.api.sizeColumnsToFit();
    params.api.closeToolPanel();
    // window.addEventListener('resize', () => {params.api.sizeColumnsToFit();});
    // this.setState({UIFeaturesForEachElevation})
  };

  handleAddonsGridReady = (params, service, serviceType) => {
    if (serviceType === AddonType.serviceAddon) {
      params.api.setRowData(service.serviceAddons);
      if (!this.addonsAgGridInstances[service.serviceId])
        this.addonsAgGridInstances[service.serviceId] = {};
      this.addonsAgGridInstances[service.serviceId][serviceType] = params;
    } else if (serviceType === "documentation") {
      params.api.setRowData(service.documentationAddons);
      if (!this.addonsAgGridInstances[service.serviceId])
        this.addonsAgGridInstances[service.serviceId] = {};
      this.addonsAgGridInstances[service.serviceId][serviceType] = params;
    }

    // params.api.sizeColumnsToFit();
    // window.addEventListener('resize', () => {params.api.sizeColumnsToFit();});
  };

  // called when a cell editing has stopped. used to resize rows when not in focus
  cellEditingStopped = (params) => {
    this.resizeGridRows(params.api);
    // this.setState({elevationTotalKey: this.state.elevationTotalKey + 1,});
  };

  // called when a cell editing has started. used for auto-new-row
  cellEditingStarted = (params) => {
    let hasAccessRight = this.state.accessRights.children.find(
      (item) => item.title === "Pli Price Edit"
    )?.write;
    if (!this.props.isWritable) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
    if (["ppu", "rent", "price"].includes(params.colDef.field)) {
      if (!!!hasAccessRight) {
        params.api.stopEditing(true);
        message.error("You have no access to edit");
      }
    }
  };

  addonsCellEditingStarted = (params) => {
    let hasWriteAccess = this.state.accessRights.children.find(
      (item) => item.title === "Addon Price Edit"
    ).write;
    if (!this.props.isWritable) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
    if (["ppu", "rent", "price"].includes(params.colDef.field)) {
      if (!!!hasWriteAccess) {
        params.api.stopEditing(true);
        message.error("You have no access to edit");
      }
    }
  };

  //when changing service addons data
  onBottomAddonChange = (serviceId, id, api, addonData, addonType) => {
    let gridData = _.cloneDeep(this.state.gridData);
    const service = gridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    let addons;
    if (addonType === AddonType.serviceAddon) addons = service.serviceAddons;
    else if (addonType === "documentation")
      addons = service.documentationAddons;

    for (let i = 0; i < addons.length; i++) {
      const addon = addons[i];
      const idOfAddonToModify = addons[i].id;
      if (id === idOfAddonToModify) {
        for (const key of addonData.values) {
          if (key.name.toLowerCase() === "name") {
            addon["name"] = addonData;
          } else addon[key.name.toLowerCase()] = key.value;
        }
        api.applyTransaction({ update: [addon] });
        break;
      }
    }
    this.updateStateAndSave(gridData);
    this.onIsTaxableChange(
      service,
      !!service?.isTaxable ? service.isTaxable : false
    );
  };

  onServiceDocumentationChange = ({ serviceId, documentationAddons }) => {
    let gridData = _.cloneDeep(this.state.gridData);
    const service = gridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );
    service.documentationAddons = documentationAddons;
    this.updateStateAndSave(gridData);
  };

  saveEstimationDescription = async (note) => {
    showLoadingMsg({ content: "Loading" });
    await API.patch(
      "estimations",
      `/estimations/${this.props.estimation?.estimationId}`,
      {
        body: { estimationDescription: note || "" },
      }
    )
      .then((res) => showSuccessMsg({ content: "Note added" }))
      .catch((err) => {
        console.log(err);
      });
  };

  addonsCellValueChanged = (params, serviceIndex, addonType) => {
    let addons,
      gridData = _.cloneDeep(this.state.gridData);
    if (addonType === AddonType.serviceAddon)
      addons = gridData[serviceIndex].serviceAddons;
    else if (addonType === "documentation")
      addons = gridData[serviceIndex].documentationAddons;

    //TODO if field that has changed is a number, parse it to 2 decimal after decimal point

    for (let i = 0; i < addons.length; i++) {
      if (addons[i].id === params.node.id) {
        addons[i] = params.data;
        break;
      }
    }

    this.onIsTaxableChange(gridData[serviceIndex], true, params.node.id);

    // this.updateStateAndSave(gridData);
  };

  onIsTaxableChange = (service, isTaxable, addOnId) => {
    const { serviceOptions, serviceAddons, taxRate } = service;
    let gridData = this.state.gridData;
    const pliTaxUpdater = (pli) => {
      const taxAmount = isTaxable
        ? (!isNaN(+pli?.price) ? +pli?.price : 0) * +taxRate
        : 0;

      const totalPrice = +pli.price + +taxAmount;
      return {
        ...pli,
        taxRate: isTaxable ? +taxRate : 0,
        price: !isNaN(+pli?.price) ? +pli?.price : 0,
        // taxRate: pli?.taxRate * 100,
        taxAmount,
        totalPrice,
      };
    };

    const updatedService = {
      ...service,
      isTaxable,
      serviceOptions: serviceOptions.map((alternate) =>
        alternate?.map?.((elevation) => ({
          ...elevation,
          items: elevation?.items?.map?.(pliTaxUpdater),
        }))
      ),
      serviceAddons: serviceAddons.map(pliTaxUpdater),
    };

    const newGridData = replaceArrayElement(
      gridData,
      updatedService,
      "serviceId"
    );

    this.updateStateAndSave(newGridData);
  };

  // called when a key is pressed inside of the grid. used for enter -> tab
  onCellKeyPress = (e) => {
    if (e.event.keyCode === 13) {
      // 13 is the ascii code for ENTER
      if (e.event.shiftKey) {
        e.event.preventDefault();
      } else {
        e.api.tabToNextCell();
      }
    }
  };

  handleExcelReport() {}

  handleGridCellFocused = (params) => {
    const focusedCell = params.api.getFocusedCell();
    let currentCell = {
      rowNode: params.api.getDisplayedRowAtIndex(focusedCell?.rowIndex),
      column: focusedCell?.column["colDef"].field,
    };
    if (this.state.agSelectedCells.hasOwnProperty("current")) {
      let newAgSelectedCells = {
        previous: this.state.agSelectedCells.current,
        current: currentCell,
      };
      try {
        params.api.flashCells({
          rowNodes: [this.state.agSelectedCells.current.rowNode],
          columns: [this.state.agSelectedCells.current.column],
        });
      } catch (e) {
        // do not flash
      }
      this.setState({ agSelectedCells: newAgSelectedCells });
    } else {
      let newAgSelectedCells = { current: currentCell };
      this.setState({ agSelectedCells: newAgSelectedCells });
    }
  };

  /**
   * Comes from ApprovedRenderer. Will approve or disapprove all PLI
   * */
  toggleApproveAll = (checked, api, serviceId, optionIndex, elevationIndex) => {
    let gridData = _.cloneDeep(this.state.gridData);
    const elevation = gridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    ).serviceOptions[optionIndex][elevationIndex];
    let anyRowWithInvalidData = false;
    for (let item of elevation.items) {
      if (item?.addons?.length > 0) {
        for (let addon of item.addons) {
          addon.approved = checked;
        }
      }
      if (checkIfRowHasValidData(item)) {
        item.approved = checked;
      } else if (checked === true) anyRowWithInvalidData = true;
    }
    this.updateStateAndSave(gridData);
    api.setRowData(elevation.items);
    api.redrawRows();

    if (anyRowWithInvalidData === true) {
      //set a warning oif we have rows without valid data
      message.warning(
        "Some rows don't have valid data and they were not marked as approved"
      );
    }
  };

  handleChange = (item) => {
    item = item === null ? [] : item;
    const arr = [];
    for (let i = 0; i < item.length; i++) {
      const element = test?.find((row) => row.name === item[i].value);
      arr.push(element);
    }
    this.setState({ addonsRowData: arr });
  };

  // handleChangePricingDataa = (serviceId, newObj) => {
  //   const pricingData = this.state.pricingData.slice();
  //   const pricingDataIndex = pricingData.findIndex(
  //     (item) => item.serviceId === serviceId
  //   );
  //   if (pricingDataIndex === -1) return;
  //   const addonsList = [
  //     ...pricingData[pricingDataIndex].pricingObject.addonsList,
  //   ];
  //   const addonsListIndex = addonsList.findIndex(
  //     (item) => item.id === newObj.id
  //   );
  //   if (addonsListIndex === -1) return;
  //   addonsList[addonsListIndex] = newObj;
  //   pricingData[pricingDataIndex].pricingObject.addonsList = addonsList;
  //   this.setState({ pricingData });
  // };

  handleChangePricingData = (serviceId, newObj) => {
    let shallowPricingData = this.state.pricingData.slice();
    shallowPricingData = shallowPricingData.map((item) =>
      item.serviceId == serviceId
        ? {
            ...item,
            pricingObject: {
              ...item.pricingObject,
              addonsList: item.pricingObject.addonsList.map((item) =>
                item.id === newObj.id ? newObj : item
              ),
            },
          }
        : item
    );
    this.setState({ pricingData: shallowPricingData });
  };
  // const service = shallowPricingData?.find(
  //   (item) => item.serviceId === serviceId
  // );
  // if (service) {
  //   const addonList = service.pricingObject.addonsList.find(
  //     (item) => item.id === addonListId
  //   );
  //   if (addonList) {
  //     const addonValue = addonList.values.find((item) => item.id === valueId);
  //     if (addonValue) {
  //       console.log(value);
  //       Object.assign(addonValue, { defaultValue: value });
  //     }
  //   }
  // }
  //   this.setState({ pricingData: shallowPricingData });
  // };

  /**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MAIN COMPONENT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~**/
  render() {
    const { serviceDefinitions } = this.props;

    const {
      agGridApiForEachElevation,
      stateSetter,
      handleElevationFieldChange,
      updateStateAndSave,
      handleSave,
      onServiceDocumentationChange,
      getAddonsCaption,
      handleChangePricingData,
    } = this;
    const {
      gridData,
      pricingData,
      selectedPriceSchemesForService,
      serviceDefs,
      activePriceSheetModal,
      priceView,
      priceEdit,
      ServicesIndex,
      accessRights,
      project,
      opportunity,
      agGridKey,
    } = this.state;
    const record = project || opportunity;

    const isWritable = this.props.isWritable;

    let activePriceSchemes = [],
      selectedPriceScheme = undefined,
      priceSchemesType = "";

    if (activePriceSheetModal !== false) {
      activePriceSchemes =
        pricingData.find(
          (s) => s.serviceId.toString() === activePriceSheetModal.toString()
        ).pricingObject?.priceSchemes || []; //if no pricingObject available, return []

      selectedPriceScheme =
        selectedPriceSchemesForService[activePriceSheetModal]?.priceScheme;

      priceSchemesType =
        selectedPriceSchemesForService[activePriceSheetModal]?.priceSchemesType;
    }

    const price_sheet_modal = (
      <PriceSchemeModal
        priceSchemes={activePriceSchemes}
        activePriceSheetModal={this?.state?.activePriceSheetModal}
        priceSchemesType={priceSchemesType}
        selectedPriceScheme={selectedPriceScheme}
        onPriceSchemeModalClose={this.onPriceSchemeModalClose}
        serviceLabel={
          this.state.ServiceNameByIndex[this.state.activePriceSheetModal]
        }
      />
    );

    let hasAccessRightsElevAdding = accessRights?.children?.find(
      (item) => item.title === "Adding/Removing Service"
    );

    let FullServiceContainerShow = false;
    if (
      this.state.fullScreenService !== null ||
      this.state.ServiceMenuContainerShow === false
    )
      FullServiceContainerShow = true;

    let ServiceMenuContainerShow = false;
    if (
      this.state.ServiceMenuContainerShow === true &&
      this.state.fullScreenService === null
    )
      ServiceMenuContainerShow = true;

    //  let exportAccess = accessRights.children?.find(access => access.title === "Export")?.write

    return (
      <TakeOffContext.Provider
        value={{
          gridData,
          isWritable,
          pricingData,
          selectedPriceSchemesForService,
          setState: this.setState.bind(this),
          updateStateAndSave: this.updateStateAndSave.bind(this),
        }}
      >
        <div>
          <ClassRedux
            {...{
              varName: "authenticatedUser",
              stateName: "authenticatedUser",
              setState: (authenticatedUser) =>
                this.setState({ authenticatedUser }),
            }}
          />
          <ClassRedux
            {...{
              varName: "userConfiguration",
              stateName: "userConfig",
              setState: (userConfiguration) =>
                this.setState({ userConfiguration }),
            }}
          />
          {this.state.errorMessages.map((e, i) => (
            <Alert key={i} message={e} banner />
          ))}
          {/* <Header
            canUndo={this.canUndo}
            canRedo={this.canRedo}
            undoClickHandler={this.undoClickHandler}
            redoClickHandler={this.redoClickHandler}
            editLogs={
              this.state.estimation?.estimationEditLogs
                ? this.state.estimation.estimationEditLogs
                : []
            }
            createdBy={this.state.estimation?.createdBy}
            projectAddress={this.state.estimation?.jobSiteAddress}
            project_OR_opportunity={this.state.project_OR_opportunity}
            accountName={
              this.state.project?.accountName ||
              this.state.opportunity?.accountName
            }
            initGrid={this.initGrid}
            AutoSave={this.state.AutoSave}
            ServiceMenuContainerShow={this.state.ServiceMenuContainerShow}
            loading={this.state.loading}
            handleSaveProject={() => {
              if (this.props.estimationStatus !== "Approved") {
                this.setState({ saveInProjectModal: true });
              } else {
                this.handleSaveProject();
              }
            }}
            agGridTheme={this.props.agGridTheme}
            setTheme={this.props.setTheme}
            estDescription={this.props.estimation?.estimationDescription}
            saveEstimationDescription={this.saveEstimationDescription}
            {...{
              serviceDefs,
              isWritable,
              gridData,
              stateSetter,
              handleSave,
              accessRights,
              estimation: this.props.estimation,
              versionId: this.props.versionId,
            }}
          /> */}
          <EstimationSecondaryHeader
            canUndo={this.canUndo}
            canRedo={this.canRedo}
            undoClickHandler={this.undoClickHandler}
            redoClickHandler={this.redoClickHandler}
            editLogs={
              this.state.estimation?.estimationEditLogs
                ? this.state.estimation.estimationEditLogs
                : []
            }
            createdBy={this.state.estimation?.createdBy}
            projectAddress={this.state.estimation?.jobSiteAddress}
            project_OR_opportunity={this.state.project_OR_opportunity}
            accountName={
              this.state.project?.accountName ||
              this.state.opportunity?.accountName
            }
            initGrid={this.initGrid}
            AutoSave={this.state.AutoSave}
            ServiceMenuContainerShow={this.state.ServiceMenuContainerShow}
            loading={this.state.loading}
            handleSaveProject={() => {
              if (this.props.estimationStatus !== "Approved") {
                this.setState({ saveInProjectModal: true });
              } else {
                this.handleSaveProject();
              }
            }}
            agGridTheme={this.props.agGridTheme}
            setTheme={this.props.setTheme}
            estDescription={this.props.estimation?.estimationDescription}
            saveEstimationDescription={this.saveEstimationDescription}
            {...{
              serviceDefs,
              isWritable,
              gridData,
              stateSetter,
              handleSave,
              accessRights,
              estimation: this.props.estimation,
              versionId: this.props.versionId,
            }}
          />
          {!gridData ? (
            <div style={{ height: "calc(100vh - 80px)", position: "relative" }}>
              <CircleSpinner
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginLeft: "-100px",
                  marginTop: "-100px",
                }}
              />
            </div>
          ) : (
            <Row>
              <Col
                {...(ServiceMenuContainerShow
                  ? { xs: 10, sm: 8, md: 5, lg: 4, xl: 3 }
                  : { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 })}
              >
                <ServiceMenuContainer
                  {...{
                    isWritable,
                    serviceDefs,
                    gridData,
                    handleSave,
                    ServicesIndex,
                    agGridApiForEachElevation,
                    accessRights,
                    record,
                    serviceDefinitions,
                  }}
                  activePanels={this.state.activePanels}
                  setState={stateSetter}
                  serviceGridKey={this.state.serviceGridKey}
                />
              </Col>
              <Col
                {...(FullServiceContainerShow
                  ? { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                  : { xs: 14, sm: 16, md: 19, lg: 20, xl: 21 })}
              >
                {(() => {
                  let allServicesInvisible = true,
                    allServicesInvisibleHTML = "";
                  for (const service of gridData) {
                    if (service.visible) {
                      allServicesInvisible = false;
                      break;
                    }
                  }
                  if (allServicesInvisible) {
                    allServicesInvisibleHTML = (
                      <div className="emptyPlaceholder">
                        <Empty
                          image="https://img.icons8.com/ios/128/5b5b5b/file.png"
                          imageStyle={{ height: 128 }}
                          description={
                            <span>
                              No services to show. Get started by selecting one
                              of the services on the left.
                            </span>
                          }
                        />
                      </div>
                    );
                  }
                  return (
                    <div
                      className="expandedServicesContainer"
                      {...(this.state.fullScreenService && {
                        style: { paddingLeft: 15 },
                      })}
                    >
                      {gridData.map((service, serviceIndex) => {
                        if (!service.visible) {
                          return <div key={service.serviceId} />;
                        } else {
                          if (
                            this.state.fullScreenService !== null &&
                            this.state.fullScreenService !== service.serviceId
                          ) {
                            return <div key={service.serviceId} />;
                          } else {
                            const borderColor = this.state.serviceDefs.find(
                              (sd) => sd.workId === service.serviceId.toString()
                            )?.colorCode;

                            return (
                              <div
                                key={service.serviceId}
                                className="serviceContainer"
                                style={{
                                  boxShadow: `0px 0px 6px 3px ${
                                    borderColor || getRandomColor()
                                  }77`,
                                }}
                              >
                                <ServiceHeader
                                  {...{
                                    record,
                                    service,
                                    isWritable,
                                    stateSetter,
                                    pricingData,
                                    priceEdit,
                                    priceView,
                                    selectedPriceSchemesForService,
                                    borderColor,
                                    serviceIndex,
                                    gridData,
                                    updateStateAndSave,
                                  }}
                                  fullscreenizeService={
                                    this.fullscreenizeService
                                  }
                                  fullScreenService={
                                    this.state.fullScreenService
                                  }
                                  agGridTheme={this.props.agGridTheme}
                                  deFullscreenize={this.deFullscreenize}
                                  handleModifyOption={this.handleModifyOption}
                                  collapseAllElevations={
                                    this.collapseAllElevations
                                  }
                                  expandAllElevations={this.expandAllElevations}
                                  handleRentPerServiceChange={
                                    this.handleRentPerServiceChange
                                  }
                                  handleAlternateRentPerServiceChange={
                                    this.handleAlternateRentPerServiceChange
                                  }
                                  handlePricePerUnitChange={
                                    this.handlePricePerUnitChange
                                  }
                                  handleDefaultPricePerUnitChange={
                                    this.handleDefaultPricePerUnitChange
                                  }
                                  onInputBlur={this.onInputBlur}
                                />

                                {service.serviceOptions.map(
                                  (option, optionIndex) => {
                                    return (
                                      <div key={optionIndex}>
                                        {
                                          <div className="optionText">
                                            {optionIndex === 0
                                              ? "Primary"
                                              : "Alternate"}
                                            <span
                                              style={{
                                                marginLeft: 10,
                                                paddingBottom: 10,
                                                fontSize: 14,
                                              }}
                                            >
                                              <OptionHeader
                                                serviceId={service.serviceId}
                                                {...{
                                                  priceView,
                                                  priceEdit,
                                                  option,
                                                  service,
                                                  optionIndex,
                                                }}
                                              />
                                            </span>
                                          </div>
                                        }
                                        {option.length === 0 ? (
                                          <div className="text-center">
                                            No elevations to show. Get started
                                            by clicking the "New Elevation"
                                            button below.
                                          </div>
                                        ) : (
                                          <Collapse
                                            activeKey={
                                              this.state.activePanels?.[
                                                serviceIndex
                                              ]?.[optionIndex] ?? []
                                            }
                                            onChange={(value) => {
                                              this.handleCollapseChange(
                                                value,
                                                serviceIndex,
                                                optionIndex
                                              );
                                            }}
                                          >
                                            {option.map(
                                              (elevation, elevationIndex) => {
                                                return (
                                                  <Panel
                                                    key={elevation.elevationId}
                                                    header={
                                                      <div
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                      >
                                                        <ElevationPanelHeader
                                                          {...{
                                                            serviceIndex,
                                                            optionIndex,
                                                            elevationIndex,
                                                            handleElevationFieldChange,
                                                            elevation,
                                                            isWritable,
                                                            gridData,
                                                            stateSetter,
                                                            updateStateAndSave,
                                                            agGridApiForEachElevation,
                                                            selectedPriceSchemesForService,
                                                            priceView,
                                                            priceEdit,
                                                            accessRights,
                                                          }}
                                                        />
                                                      </div>
                                                    } //end elevation panel header
                                                    extra={
                                                      <ElevationPanelExtra
                                                        {...{
                                                          service,
                                                          isWritable,
                                                          gridData,
                                                          updateStateAndSave,
                                                          selectedPriceSchemesForService,
                                                          serviceIndex,
                                                          optionIndex,
                                                          elevationIndex,
                                                          agGridApiForEachElevation,
                                                          serviceDefs,
                                                          stateSetter,
                                                          handleSave,
                                                          priceView,
                                                          priceEdit,
                                                          accessRights,
                                                        }}
                                                      />
                                                    }
                                                  >
                                                    <ElevationContent
                                                      {...{
                                                        service,
                                                        serviceIndex,
                                                        optionIndex,
                                                        elevationIndex,
                                                        elevation,
                                                        ServicesIndex,
                                                        accessRights,
                                                      }}
                                                      dataEntryGrid={this}
                                                      agGridTheme={
                                                        this.props.agGridTheme
                                                      }
                                                    />
                                                  </Panel>
                                                );
                                              }
                                            )}
                                          </Collapse>
                                        )}

                                        {!service.isScope && (
                                          <div className="text-center">
                                            <Button
                                              disabled={
                                                !isWritable ||
                                                !hasAccessRightsElevAdding?.write ||
                                                !!!hasAccessRightsElevAdding
                                              }
                                              className="appendElevation"
                                              onClick={() => {
                                                // service.serviceId != 9999 ?

                                                appendElevation(
                                                  serviceDefs,
                                                  gridData,
                                                  updateStateAndSave,
                                                  serviceIndex,
                                                  optionIndex
                                                );
                                                // : this.setState({
                                                //     scopeSelectorModalVisible: true,
                                                //   })
                                              }}
                                            >
                                              <span>
                                                New Elevation{" "}
                                                <PlusCircleFilled />{" "}
                                              </span>
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                )}

                                <>
                                  <>
                                    {this.props.serviceDefinitions?.find(
                                      (sE) =>
                                        Number(sE?.serviceId) ===
                                        Number(service?.serviceId)
                                    )?.hasServiceAddons === true && (
                                      <ServiceAddon
                                        {...{
                                          service,
                                          pricingData,
                                          getAddonsCaption,
                                          handleChangePricingData,
                                          gridData,
                                          updateStateAndSave,
                                        }}
                                        agGridTheme={this.props.agGridTheme}
                                      />
                                    )}{" "}
                                  </>
                                  <>
                                    {this.props.serviceDefinitions?.find(
                                      (sE) =>
                                        Number(sE?.serviceId) ===
                                        Number(service?.serviceId)
                                    )?.hasDocumentation === true && (
                                      <ServiceDocumentation
                                        {...{
                                          service,
                                          onServiceDocumentationChange,
                                          agGridTheme: this.props.agGridTheme,
                                        }}
                                      />
                                    )}
                                  </>
                                </>

                                {
                                  <IncludesExcludes
                                    {...{
                                      service,
                                      serviceIndex,
                                      gridData,
                                      updateStateAndSave,
                                    }}
                                  />
                                }
                              </div>
                            );
                          }
                        } //end of else form if (!service.visible)
                        /*end of this.state.gridData.map((service, serviceIndex)*/
                      })}
                      {allServicesInvisibleHTML}
                    </div>
                  );
                })()}
              </Col>
            </Row>
          )}
          {price_sheet_modal}
          <ConfirmationModal
            visible={this.state.saveInProjectModal}
            title="Confirmation Modal"
            onConfirm={() => this.handleSaveProject(true)}
            onCancel={() => this.setState({ saveInProjectModal: false })}
            text={
              <span className="ModalConfirmation">
                Please be cautious. This will overwrite the information of
                services in the project.
                {this.state?.estimation?.estSTATUS !== "Approved" && (
                  <>
                    <br />
                    It will also change the estimation status to approved.
                    <br />
                  </>
                )}
                Do you want to continue?
              </span>
            }
            setVisible={() => this.setState({ saveInProjectModal: false })}
          ></ConfirmationModal>
        </div>

        <ScopeSelectorModal
          visible={this.state.scopeSelectorModalVisible}
          setVisible={(val) =>
            this.setState({ scopeSelectorModalVisible: val })
          }
        />
      </TakeOffContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceDefinitions: state.serviceDefinitions,
});

export default connect(mapStateToProps)(DataEntryGrid);
