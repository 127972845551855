let date = new Date().toLocaleDateString();

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "");
}
export const pdfBorderedSection = ({
  title,
  body = [],
  noColor = false,
  type,
}) => {
  /* formattedBody is the body of the pdf  File*/
  /** @param {array of objects} body needs to be an array of objects that can be mapped */
  /** formatted body can have 3 columns
   * column 1 is the name of the item
   * column 2 is the description of the item if there is one
   * column 3 is the color or formula or type of the item
   */

  const formattedBody =
    type !== "projectTaskListModal"
      ? body?.map((item) => {
          if (!type) {
            return {
              columns: [
                {
                  width: "40%",
                  text: item?.label,
                  alignment: "left",
                  style: ["lastColumn", "cellItem"],
                },
                item?.image?.includes("data:image")
                  ? {
                      image: item?.image,
                      link: item?.link || "",
                      height: item?.height || 100,
                      width: item?.width || 100,
                      alignment: "center",
                    }
                  : {
                      width: "60%",
                      text: item?.value,
                      alignment: "left",
                      style: ["lastColumn", "cellItem"],
                    },
              ],
            };
          }
          return {
            columns: [
              {
                width: !type
                  ? "40%"
                  : type === "projectTaskListModal"
                  ? "100%"
                  : type === "approvalsRequests"
                  ? "25%"
                  : type === "approvalsLogs"
                  ? "15%"
                  : type === "notifications"
                  ? "75%"
                  : type === "EventPdf"
                  ? "20%"
                  : type === "activeAutomations"
                  ? "25%"
                  : type === "activeAutomationsLogs"
                  ? "10%"
                  : "50%",
                text:
                  item?.labelName ||
                  item?.docName ||
                  item?.typeName ||
                  item?.statusName ||
                  item?.statusColor ||
                  item?.reasonName ||
                  item?.categoryName ||
                  item?.name ||
                  item?.label ||
                  item?.rate ||
                  item?.unitName ||
                  item,
                alignment: "left",
                style: ["lastColumn", "cellItem"],
              },
              {
                width:
                  title === "approvalRequests"
                    ? "25%"
                    : type === "EventPdf"
                    ? "25%"
                    : type === "activeAutomations"
                    ? "20%"
                    : type === "activeAutomationsLogs"
                    ? "30%"
                    : "20%",
                text: item?.detail || item?.details || item,
                alignment: "left",
                style: ["lastColumn", "cellItem"],
              },
              {
                width: item?.description
                  ? !type
                    ? "60%"
                    : type === "approvalsRequests"
                    ? "25%"
                    : type === "approvalsLogs"
                    ? "15%"
                    : type === "notifications"
                    ? "25%"
                    : type === "EventPdf"
                    ? "20%"
                    : type === "activeAutomations"
                    ? "15%"
                    : type === "activeAutomationsLogs"
                    ? "30%"
                    : "30%"
                  : type
                  ? "50%"
                  : "10%",
                text: item?.description ? removeTags(item?.description) : "",
                alignment: "left",
                style: ["lastColumn", "cellItem"],
              },
              {
                width: type === "approvalsLogs" ? "35%" : "10%",
                text: item?.recordName,
                alignment: "left",
                style: ["lastColumn", "cellItem"],
              },
              {
                width:
                  type === "approvalsRequests"
                    ? "25%"
                    : type === "EventPdf"
                    ? "25%"
                    : type === "activeAutomations"
                    ? "30%"
                    : type === "activeAutomationsLogs"
                    ? "30%"
                    : item?.info
                    ? "20%"
                    : "0%",
                text: item?.info,
                alignment: "left",
                style: ["lastColumn", "cellItem"],
              },
              {
                ...(item?.image
                  ? {
                      image: item?.image,
                      link: item?.link || "",
                      height: item?.height || 100,
                      width: item?.width || 100,
                    }
                  : item?.svg
                  ? {
                      svg: item?.svg,
                      height: item?.height || 100,
                      width: item?.width || 100,
                    }
                  : {
                      width: type === "leadLogs" ? "25%" : "*",
                      text:
                        item?.color || item.statusColor
                          ? "          "
                          : item?.formula ||
                            item?.type ||
                            item?.value ||
                            item?.rate ||
                            item?.value,
                      background: item?.color
                        ? item?.color
                        : item?.statusColor
                        ? item?.statusColor
                        : "",
                    }),
                style: ["lastColumn", "cellItem"],
                // columnGap: 5,
              },
            ],
          };
        })
      : [];

  const tasksList =
    type === "projectTaskListModal"
      ? Object.keys(body).map((key) => {
          const task = Object.keys(body?.[key]);
          return [
            {
              width: "100%",
              text: `${key} (0%)`,
              alignment: "left",
              style: ["lastColumn"],
            },
            ...task.map((taskKey) => ({
              width: "100%",
              alignment: "left",
              text: taskKey,
              style: ["lastColumn", "subTask"],
            })),
          ];
        })
      : [];

  /** @param {array} tableBody  contains the formattedBody and the header of the table on the pdf*/
  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    type === "serviceAddon" && {
      columns: [
        {
          text: "Name",
          style: ["strong", "cellItem"],
          width: "50%",
        },
        {
          text: "Description",
          style: ["strong", "cellItem"],
          width: "30%",
        },
        {
          text: "Formula or Type",
          style: ["strong", "cellItem"],
          width: "20%",
        },
      ],
    },
    !type && {
      columns: [
        {
          text: "Field",
          style: ["strong", "cellItem"],
          width: "40%",
        },
        {
          text: "Value",
          style: ["strong", "cellItem"],
          width: "60%",
        },
      ],
    },
    type === true && {
      columns: [
        {
          text: "Name",
          style: ["strong", "cellItem"],
          width: "60%",
        },
        {
          text: type === "approvalsRequests" ? "Details" : "Last seen",
          style: ["strong", "cellItem"],
          width: "40%",
        },
      ],
    },
    type === "approvalsRequests" && {
      columns: [
        { text: "Type", style: ["strong", "cellItem"], width: "25%" },
        { text: "Requested by", style: ["strong", "cellItem"], width: "25%" },
        { text: "Assigned To", style: ["strong", "cellItem"], width: "25%" },
        { text: "Created At", style: ["strong", "cellItem"], width: "25%" },
      ],
    },
    type === "approvalsLogs" && {
      columns: [
        { text: "Member", style: ["strong", "cellItem"], width: "15%" },
        { text: "Response", style: ["strong", "cellItem"], width: "15%" },
        { text: "Topic", style: ["strong", "cellItem"], width: "15%" },
        { text: "Record Name", style: ["strong", "cellItem"], width: "35%" },
        { text: "Requested By", style: ["strong", "cellItem"], width: "20%" },
      ],
    },
    type === "notifications" && {
      columns: [
        { text: "Label", style: ["strong", "cellItem"], width: "75%" },
        { text: "Date & Time", style: ["strong", "cellItem"], width: "25%" },
      ],
    },
    type === "EventPdf" && {
      columns: [
        { text: "Summary", style: ["strong", "cellItem"], width: "20%" },
        { text: "Organizer", style: ["strong", "cellItem"], width: "25%" },
        { text: "Start Date", style: ["strong", "cellItem"], width: "30%" },
        { text: "End Date", style: ["strong", "cellItem"], width: "30%" },
      ],
    },
    type === "activeAutomations" && {
      columns: [
        {
          text: "Automation Name",
          style: ["strong", "cellItem"],
          width: "25%",
        },
        { text: "Created By", style: ["strong", "cellItem"], width: "20%" },
        { text: "Category", style: ["strong", "cellItem"], width: "15%" },
        { text: "Status", style: ["strong", "cellItem"], width: "10%" },
        { text: "Frequency", style: ["strong", "cellItem"], width: "30%" },
      ],
    },
    type === "activeAutomationsLogs" && {
      columns: [
        { text: "Error", style: ["strong", "cellItem"], width: "10%" },
        { text: "Response", style: ["strong", "cellItem"], width: "30%" },
        { text: "Description", style: ["strong", "cellItem"], width: "40%" },
        { text: "Date", style: ["strong", "cellItem"], width: "20%" },
      ],
    },
    type === "incident" && {
      columns: [
        {
          text: "Field",
          style: ["strong", "cellItem"],
          width: "40%%",
        },
        {
          text: "Value",
          style: ["strong", "cellItem"],
          width: "60%",
        },
      ],
    },
    ...formattedBody,
    ...tasksList,
  ].filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      // headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

const fillColor = (rowIndex) => {
  return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
};

/**@param {array} dynamicPdf is a const whose value is given by @param {function} pdfBorderedSection*/
export const docDefinition = (title, dynamicPdf, companyLogo) => {
  return {
    // pageOrientation: orientation ? orientation : "portrait",
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo, //?.current?.base64,
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
          {
            text: `Date : ${date}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      ...dynamicPdf?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo, //?.current?.base64,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      subTask: { margin: [30, 0, 0, 0] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
    },
  };
};
