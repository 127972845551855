import axios from "axios";

export const getActiveAutomations = async () => {
  try {
    const res = await axios.get(
      // "http://localhost:8080/automation/activeCronJobs"
      "https://leadmanager-express-backend.ue.r.appspot.com/automation/activeCronJobs"
    );
    return res.data;
  } catch (err) {
    console.log({ err });
    throw err;
  }
};
