import { useState, useEffect, useCallback, useContext } from "react";
import { API } from "aws-amplify";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { message } from "antd";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

import { SchedulingContext } from "../../../../context";
import { dispatchColumns, dispatchesDetails } from "./HelpingComponents/utils";
import { filterTables } from "../../../../../../../../utils";
import { toOrdinal } from "../../../../helpers/creators";
import { CREW_FLEET_POPOVER_TYPE } from "../../CrewTrucksSelectPopover";
import { NewDispatchModal } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals";
import DriverSelectionModal from "./DriverSelectionModal";
import RouteShower from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/components/RouteShower";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { InfoIconBlue } from "./HelpingComponents/icons";

import "./ScheduleDispatchModal.scss";

const DATETIME_FORMAT = "MM/DD/YYYY HH:mm";

function ScheduleDispatchModal({
  onPopoverVisibilityChanges = () => {},
  scheduleAddress,
  scheduleDay,
  isDarkMode,
  dayIndex,
  scheduleId,
  typeOfWork,
  scheduleDays,
  view,
  onSave,
  setDay,
  day,
}) {
  /**
   * Keeps track of all the created dispatches with information about the
   * selected truck, the driver and the dispatch id
   * [{fleetId, fleetName, driverId, driverName, dispatchId}]
   */
  const [routeSelections, setRouteSelections] = useState(
    scheduleDay?.fleet || []
  );
  /**
   * Keeps track of all the selected drivers and the fleets
   * [{fleetId, driverId, driverName}]
   */
  const [driverSelections, setDriverSelections] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [dispatches, setDispatches] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [driverSelectionVisible, setDriverSelectionVisible] = useState(false);
  const [newDispatchData, setNewDispatchData] = useState();
  const [showRoutes, setShowRoutes] = useState({
    fleetName: "",
    driverName: "",
    routes: [],
  });

  const { updateDispatchesForSchedule } = useContext(SchedulingContext);

  useEffect(() => {
    Promise.allSettled([
      filterTables("fleet", "fleetStatus", "Active"),
      filterTables("drivers", "driverStatus", "Ready to Work"),
      filterTables(
        "fleetDispatching",
        "dispatchDate",
        dayjsNY(scheduleDay?.startDate).startOf("day").format()
      ),
    ])
      .then(([{ value: fl }, { value: dr }, { value: dis }]) => {
        fl?.sort((a, b) => a?.fleetName?.localeCompare(b?.fleetName));
        dr?.sort((a, b) => a?.driverName?.localeCompare(b?.driverName));
        setTrucks(fl);
        setDrivers(dr);
        setDispatches(dis);
      })
      .catch((err) => {
        console.log("Could not get data: ", err);
      });
  }, []);

  const onGridReady = useCallback((param) => {
    setGridApi(param);
  }, []);

  const onFirstDataRendered = useCallback(() => {
    if (gridApi && dispatches) {
      gridApi.columnApi.columnModel.autoSizeColumn(["fleetName"]);
    }
  }, [dispatches]);

  function getRowStyle(param) {
    if (
      routeSelections?.find(
        ({ fleetId }) => param?.node?.data?.fleetId === fleetId
      )
    ) {
      return { background: "#CDE5F8" };
    }
  }

  function onSearch(e) {
    if (gridApi) {
      gridApi.api.setFilterModel({
        fleetName: {
          filterType: "text",
          type: "contains",
          filter: e,
        },
      });
    }
  }

  function getRouteInfo({ fleetId, driverId, dispatch = undefined }) {
    if (dispatch) {
      setShowRoutes({
        ...dispatch,
        routes: dispatch?.routes?.map((route) => ({
          ...route,
          fleetName: dispatch?.fleetName,
        })),
      });
      return;
    }
    let asset = null;
    if (fleetId) {
      asset = trucks.find((truck) => truck.fleetId === fleetId);
    } else {
      asset = drivers.find((driver) => driver.driverId === driverId);
    }

    let allRoutesForDay = [];
    for (let i = 0; i < dispatches?.length; i++) {
      if (fleetId) {
        if (dispatches[i].fleetId === fleetId) {
          allRoutesForDay = allRoutesForDay.concat(
            dispatches[i].routes.map((route) => ({
              ...route,
              fleetName: dispatches[i].fleetName,
            }))
          );
        }
      } else {
        let routesForDriver = dispatches[i].routes.filter(
          (route) => route.driverId === driverId
        );
        if (routesForDriver.length) {
          allRoutesForDay = allRoutesForDay.concat(
            routesForDriver.map((route) => ({
              ...route,
              fleetName: dispatches[i].fleetName,
            }))
          );
        }
      }
    }

    if (!allRoutesForDay.length) {
      message.warning("There are no routes to show");
      return;
    }

    allRoutesForDay.sort(
      (a, b) => dayjsNY(a.departAt).valueOf() - dayjsNY(b.departAt).valueOf()
    );

    setShowRoutes({
      fleetName: asset?.fleetName || "",
      driverName: asset?.driverName || "",
      routes: allRoutesForDay,
    });
  }

  function onDriverSelect(driver, fleet) {
    let tmp = structuredClone(driverSelections);
    let truckSelectionIndex = tmp.findIndex(({ fleetId }) => fleetId === fleet);
    if (truckSelectionIndex > -1) {
      tmp[truckSelectionIndex] = {
        fleetId: fleet,
        driverId: driver?.driverId,
        driverName: driver?.driverName,
      };
    } else {
      tmp.push({
        fleetId: fleet,
        driverId: driver?.driverId,
        driverName: driver?.driverName,
      });
    }
    setDriverSelections(tmp);
  }

  function openDispatchModal(param) {
    let selection = driverSelections?.find(
      ({ fleetId }) => fleetId === param?.fleetId
    );
    setNewDispatchData({
      fleetId: param?.fleetId,
      driverId: selection?.driverId || "",
      schedule: {
        scheduleAddress,
        scheduleId,
        typeOfWork,
        scheduleDays: [{ startDate: scheduleDay?.startDate }],
      },
    });
  }

  return (
    <div className="schedule-dispatch-container">
      <div className="schedule-dispatch-header">
        <div className="header-info">
          <InfoIconBlue />
          <p>
            <strong>{`${scheduleAddress} - ${toOrdinal(
              scheduleDay?.day
            )} Day - ${dayjsNY(scheduleDay?.startDate).format(
              DATETIME_FORMAT
            )}`}</strong>
          </p>
        </div>
        <div className="header-search">
          <InputComponent
            {...{
              type: "autocomplete",
              formItemName: "inputSearch",
              placeholder: "Search truck #...",
              onSearch,
              style: { width: 300, maxWidth: "80dvw" },
            }}
          />
        </div>
      </div>
      <div
        className={`schedule-dispatch-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData: trucks,
            columnDefs: dispatchColumns({
              dispatches,
              setDriverSelectionVisible,
              openDispatchModal,
              driverSelections,
            }),
            getRowStyle,
            onGridReady,
            onFirstDataRendered,
            detailCellRendererParams: dispatchesDetails({
              dispatches,
              getRouteInfo,
              grid: "truck",
            }),
            masterDetail: true,
            rowSelection: "multiple",
            pagination: false,
            gridOptions: {
              alwaysShowHorizontalScroll: true,
              alwaysShowVerticalScroll: true,
            },
            suppressRowClickSelection: true,
            animateRows: true,
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
      {(showRoutes.driverName || showRoutes.fleetName) && (
        <RouteShower
          {...{
            open: showRoutes.driverName || showRoutes.fleetName,
            onCancel() {
              setShowRoutes({
                fleetName: "",
                driverName: "",
                routes: [],
              });
            },
            title: `Route details for ${
              showRoutes.driverName || showRoutes.fleetName
            }`,
            routes: showRoutes.routes,
            showCargoInfo: true,
            selectedDate: scheduleDay?.startDate,
          }}
        />
      )}
      {driverSelectionVisible && (
        <DriverSelectionModal
          {...{
            open: driverSelectionVisible,
            onCancel() {
              setDriverSelectionVisible(false);
            },
            drivers,
            onDriverSelect,
            getRouteInfo,
            dispatches,
            routeSelections,
            selectedFleet: driverSelectionVisible,
            driverSelections,
            isDarkMode,
          }}
        />
      )}
      {newDispatchData && (
        <NewDispatchModal
          {...{
            visible: !!newDispatchData,
            setVisible: setNewDispatchData,
            refreshTable(res) {
              if (view === "calendar-view") {
                onSave(
                  routeSelections?.concat({
                    fleetId: res?.fleetId,
                    fleetName: res?.fleetName,
                    driverId: res?.routes[0]?.driverId,
                    driverName: res?.routes[0]?.driverName,
                    dispatchId: res?.dispatchId,
                  })
                );
                setDay({
                  ...day,
                  fleet: [
                    ...day?.fleet,
                    {
                      fleetId: res?.fleetId,
                      fleetName: res?.fleetName,
                      driverId: res?.routes[0]?.driverId,
                      driverName: res?.routes[0]?.driverName,
                      dispatchId: res?.dispatchId,
                    },
                  ],
                });
              }
              setDispatches(dispatches.concat(res));
              updateDispatchesForSchedule((prev) => [res, ...prev]);
              let newSelection = routeSelections.concat({
                fleetId: res?.fleetId,
                fleetName: res?.fleetName,
                driverId: res?.routes[0]?.driverId,
                driverName: res?.routes[0]?.driverName,
                dispatchId: res?.dispatchId,
              });
              setRouteSelections(newSelection);
              setDriverSelections(
                driverSelections?.filter(
                  ({ fleetId, driverId }) =>
                    fleetId !== newDispatchData.fleetId &&
                    driverId !== newDispatchData?.driverId
                )
              );
              scheduleDay.fleet = newSelection;
              setTimeout(() => {
                gridApi.api.redrawRows();
              }, 150);
              onPopoverVisibilityChanges(
                true,
                true,
                scheduleDay,
                dayIndex,
                CREW_FLEET_POPOVER_TYPE.FLEET
              );
              setRouteSelections(newSelection);

              API.put("scheduling", `/scheduling/${scheduleId}`, {
                body: {
                  scheduleDays,
                },
              });
            },
            defaultData: newDispatchData,
          }}
        />
      )}
    </div>
  );
}

export default ScheduleDispatchModal;
