import { parseComparedHtml } from "../../utils";

export const defaultColumnDef = (compareRowData) => ({
  editable: false,
  sortable: false,
  resizable: true,
  filter: false,
  flex: 1,
  minWidth: 100,
  cellStyle: (params) => {
    let objectValue = compareRowData?.[params.rowIndex]?.[params.colDef.field];

    if (Array.isArray(params.value) && Array.isArray(objectValue)) {
      return params.value.length !== objectValue.length
        ? { backgroundColor: "#b0e090" }
        : null;
    }
    return parseComparedHtml(params.value, objectValue);
  },
});
