import React from "react";

/**
 * @typedef DataSet
 * @property {string} dataSetLabel
 * @property {any} dataSetValue
 * @property {undefined | () => void} dataSetClick
 */

/**
 * @param {{label: string, value: any, dataSet: Array<DataSet>, onClick?: () => void}} param
 */
function InfoData({ label, value, dataSet, onClick }) {
  return (
    <div className="info-data">
      {dataSet?.length ? (
        dataSet.map(({ dataSetLabel, dataSetValue, dataSetClick }) => {
          return (
            <>
              <label>{dataSetLabel}: </label>
              <div
                onClick={onClick}
                className={dataSetClick ? "clickable-data" : ""}
              >
                {dataSetValue}
              </div>
            </>
          );
        })
      ) : (
        <>
          <label>{label}: </label>
          <div className={onClick ? "clickable-data" : ""} onClick={onClick}>
            {value}
          </div>
        </>
      )}
    </div>
  );
}

export default InfoData;
