import { Avatar, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import "./UserDetailsModal.scss";
import { useEffect, useState } from "react";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { useSelector } from "react-redux";
import { getInitials } from "../../../../../TeamManagement/components/TeamPreview/PreviewAvatar";
import { RedWarningModal } from "../../../../../../../../commonComponents";

const avatarStyle = {
  width: "60px",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  fontWeight: 600,
  fontSize: "24px",
};

const UsersDetails = ({
  openUsersDetails,
  setOpenUsersDetails,
  selectedRoleName,
  filteredUsers = [],
  isDarkMode,
}) => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.accessToken);
  const { getImageSrc } = driveApi({ accessToken });
  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    filteredUsers.forEach((user) => {
      const { googleDriveFileId } = user;
      if (googleDriveFileId && accessToken) {
        getImageSrc(googleDriveFileId).then((image) => {
          user.imageSrc = image;
        });
      }
    });
  }, [accessToken]);

  return (
    <Modal
      centered
      open={openUsersDetails}
      onCancel={setOpenUsersDetails}
      title={`Users Details for ${selectedRoleName} Department`}
      closeIcon={<XIcon />}
      className={`users-details-modal ${
        isDarkMode ? "users-details-modal-dark" : ""
      }`}
      footer={null}
    >
      {filteredUsers.map((user, index) => {
        const { nameOfUser, groupName, userName, departmentName, identityId } =
          user;
        return (
          <div
            className="user-details-item"
            key={index}
            onClick={() => {
              setOpenWarning(user);
              // navigate(
              //   `/settings/userAccess/${departmentName}/${groupName}/${identityId}`
              // );
            }}
          >
            <Avatar
              shape="circle"
              src={user?.imageSrc?.src}
              gap={4}
              style={{
                backgroundColor: `${isDarkMode ? "#2a2b3a" : "#F8F8FA"}`,
                color: isDarkMode ? "#fff" : "#323338",
                ...avatarStyle,
              }}
            >
              {getInitials(nameOfUser)}
            </Avatar>
            <div className="user-labels">
              <span className="user-userName">{nameOfUser}</span>
              <span className="user-data">{groupName}</span>
              <span className="user-data">{userName}</span>
            </div>
          </div>
        );
      })}
      {!!openWarning && (
        <RedWarningModal
          {...{
            visible: !!openWarning,
            onCancel: () => {
              setOpenWarning(false);
            },
            titleText: "Caution!",
            footerProps: {
              onConfirm: () => {
                navigate(
                  `/settings/userAccess/${openWarning?.departmentName}/${openWarning?.groupName}/${openWarning?.identityId}`
                );
              },
            },
          }}
        >
          <p>
            Are you sure you want to go to <b>{openWarning?.nameOfUser}</b>{" "}
            access tab? The changes you made here will be lost.
          </p>
        </RedWarningModal>
      )}
    </Modal>
  );
};

export default UsersDetails;
