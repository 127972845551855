import { defaultSafetyCategories } from "../constants";

/**
 * Handles the selection of a safety category.
 *
 * @param {Array} safety - The array of safety objects.
 * @param {string} category - The selected category.
 * @param {boolean} clear - Indicates whether to clear the fields.
 * @param {Function} setFieldsValue - The function to set field values.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 */
const selectSafetyCategory = ({
  safety = [],
  category = "",
  clear = false,
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (defaultSafetyCategories.includes(category) && !clear) {
    setFieldsValue({ safetyReport: "" });

    let res = safety?.filter(
      (el) =>
        !!el?.safetyName &&
        el?.safetyApplicationCategory === category &&
        el?.safetyApplicationStatus !== "Draft"
    );

    console.log("res", res);

    dispatchRequestData({
      type: "SAFETY_OPTIONS",
      payload: res,
    });
  } else {
    setFieldsValue({ safetyReport: "" });

    dispatchRequestData({
      type: "SAFETY_OPTIONS",
      payload: [],
    });
  }
};

export default selectSafetyCategory;
