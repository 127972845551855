import { forceToNumber } from "./checkers";
import onInputChangeHandler from "./onInputChangeHandler";

const updatePaymentAmount = (
  value,
  clientBalance,
  state,
  fakePaymentChanger
) => {
  // Loop through each row in the table
  // let remainingAmount = value + Math.abs(clientBalance);

  // Extra Validate Number coming from Input Payment Amount
  let tempPaymentAmount = forceToNumber(value);
  let invoiceWithSameAmount = state.invoices.find(
    (invoice) => invoice.invoiceAmountDue === tempPaymentAmount
  );

  if (!!invoiceWithSameAmount) {
    // tempPaymentAmount = 0;
    invoiceWithSameAmount.invoiceChecked = true;
    invoiceWithSameAmount.invoicePaidAmount = tempPaymentAmount;

    const newTemp = structuredClone(state);
    newTemp.invoices = state.invoices.map((row) => {
      if (row.invoiceAmountDue !== tempPaymentAmount) {
        return { ...row, invoiceChecked: false, invoicePaidAmount: 0 };
      } else
        return {
          ...row,
          invoiceChecked: true,
          invoicePaidAmount: tempPaymentAmount,
        };
    });
    newTemp.appliedAmount = value;
    console.log("newTemp  LEJDO", newTemp);
    fakePaymentChanger(newTemp);
    return;
  } else {
    // New Rows updated accordingly
    const updatedRowData = state.invoices
      // ?.filter(({ invoiceChecked = false }) => invoiceChecked)
      .map((row) => {
        let tempInvoicePaidAmount = 0;

        // Distribute the Payment amount from header to invoices
        if (tempPaymentAmount > row.invoiceAmountDue) {
          tempInvoicePaidAmount = row.invoiceAmountDue;
          tempPaymentAmount -= row.invoiceAmountDue;
        } else {
          tempInvoicePaidAmount = tempPaymentAmount;
          tempPaymentAmount = 0;
        }

        const toReturn = {
          ...row,
          invoiceChecked: tempInvoicePaidAmount > 0 ? true : false,
        };
        // if (!!tempInvoicePaidAmount) {
        toReturn.invoicePaidAmount = tempInvoicePaidAmount;
        // }
        return toReturn;
      });
    const newTemp = structuredClone(state);
    newTemp.invoices = updatedRowData;
    newTemp.appliedAmount = value;

    fakePaymentChanger(newTemp);
    return;
  }

  // const updatedRowData = state.invoices.map((row) => {
  //   let invoiceAmountDue = row.invoiceAmountDue + row.invoicePaidAmount;
  //   let paymentAmount = row.invoicePaidAmount;

  //   // Only add the payment amount to the invoice if it is checked
  //   // if (row.invoiceChecked) {
  //   // If invoice amount is greater than input value, fill payment amount with input value
  //   if (invoiceAmountDue >= remainingAmount) {
  //     paymentAmount = remainingAmount;
  //     invoiceAmountDue -= remainingAmount;
  //     remainingAmount = 0;
  //   }

  //   // If invoice amount is less than input value, fill payment amount with invoice amount
  //   if (invoiceAmountDue < remainingAmount) {
  //     paymentAmount = invoiceAmountDue;
  //     remainingAmount -= invoiceAmountDue;
  //     invoiceAmountDue = 0;
  //   }
  //   // }

  //   console.log("updatePaymentAmount", value, remainingAmount, clientBalance);

  //   return {
  //     ...row,
  //     invoicePaidAmount: forceToNumber(paymentAmount),
  //     // invoiceAmountDue: forceToNumber(invoiceAmountDue),
  //     invoiceChecked: forceToNumber(paymentAmount) > 0 ? true : false,
  //   };
  // });

  // onInputChangeHandler(forceToNumber(value), state, setState, "appliedAmount");
  // onInputChangeHandler(updatedRowData, state, setState, "invoices");
  // onInputChangeHandler(-remainingAmount, setState, "clientBalance");
};

export default updatePaymentAmount;
