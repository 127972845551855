import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { formatCurrency } from "../../../../../../../SidebarPages/utils";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";

export const detailsFields = ({
  listOfEmployees = [],
  listOfJobsites = [],
  listOfRoles = [],
}) => [
  {
    label: "Employee",
    type: "select",
    mode: "multiple",
    formItemName: "employees",
    className: "employeeField",
    disabled: !listOfEmployees?.length,
    placeholder: "Select Employee...",
    customOptions: listOfEmployees.map(({ employeeId, employeeFullName }) => ({
      key: employeeId,
      value: employeeId,
      label: employeeFullName,
    })),
  },
  {
    label: "Role",
    type: "select",
    mode: "multiple",
    formItemName: "employeeRole",
    className: "roleField",
    disabled: !listOfRoles?.length,
    placeholder: "Select role...",
    customOptions: listOfRoles,
  },
  {
    label: "Jobsite",
    type: "select",
    mode: "multiple",
    formItemName: "jobsites",
    className: "jobsiteAddressField",
    disabled: !listOfJobsites.length,
    placeholder: "Select Jobsite...",
    customOptions: listOfJobsites.map(({ jobsiteId, jobName }) => ({
      key: jobsiteId,
      value: jobsiteId,
      label: jobName,
    })),
  },
  {
    label: "Time Frame",
    type: "rangepicker",
    formItemName: "timeFrame",
    className: "timeFrameField",
    placeholder: "Select time frame...",
  },
];

export const detailsColumnDefs = () => [
  {
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    maxWidth: 50,
  },
  {
    headerName: "Employee Id",
    field: "employeeId",
    maxWidth: 150,
  },
  {
    headerName: "Employee Full Name",
    field: "employeeFullName",
    cellRenderer: ({ data }) => {
      if (!!data?.crewName?.length) {
        return <div>{data?.crewName}</div>;
      } else {
        return <div>{data?.employeeFullName}</div>;
      }
    },
    // pdfExportOptions: {
    //   modifyCellValue: (data) => {
    //     if (!!data?.crewName?.length) {
    //       return data?.crewName;
    //     } else {
    //       return data?.employeeFullName;
    //     }
    //   },
    // },
  },
  {
    headerName: "Role",
    field: "employeeRole",
    maxWidth: 100,
  },
  {
    headerName: "Rate",
    field: "employeeRate",
    maxWidth: 100,
    cellRenderer({ value }) {
      return formatCurrency(value || 0);
    },
    pdfExportOptions: {
      modifyCellValue: (data) => {
        return formatCurrency(data);
      },
    },
  },
  {
    headerName: "Jobsite Match",
    field: "jobsiteMatch",
    getQuickFilterText: ({ value }) => {
      return typeof value === "string" ? value : value.jobName;
    },
    cellRenderer: ({ value }) => {
      if (typeof value === "string") {
        return <div>{value}</div>;
      } else {
        return <div>{value?.jobName}</div>;
      }
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        if (typeof value === "string") {
          return value;
        } else {
          return value?.jobName;
        }
      },
    },
    filterParams: {
      valueGetter: ({ data }) => {
        return data?.jobsiteMatch?.jobName ||  "";
      },
    },
  },
  {
    headerName: "Date",
    field: "firstClockIn",
    maxWidth: 150,
    filter: "agDateColumnFilter",
    cellRenderer: ({ value }) => {
      if (!value) {
        return <div>-||-</div>;
      }
      const punchDate = dayjsNY(value).format(DEG_DATE_FORMAT);
      return <div>{punchDate === "Invalid Date" ? "-||-" : punchDate}</div>;
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        if (!value) {
          return "-||-";
        }
        const punchDate = dayjsNY(value).format(DEG_DATE_FORMAT);
        return punchDate === "Invalid Date" ? "-||-" : punchDate;
      },
    },
  },
  {
    headerName: "Jobsite Address",
    field: "jobsiteAddress",
  },
  {
    headerName: "Clock In",
    field: "firstClockIn",
    maxWidth: 150,
    cellRenderer: ({ data }) => {
      if (!data?.firstClockIn) {
        return <div>-||-</div>;
      }
      const punchTime = dayjsNY(data.firstClockIn).format(DEG_TIME_FORMAT);
      return <div>{punchTime === "Invalid Date" ? "-||-" : punchTime}</div>;
    },
    pdfExportOptions: {
      modifyCellValue: (data) => {
        if (!data) {
          return "-||-";
        }
        const punchTime = dayjsNY(data).format(DEG_TIME_FORMAT);
        return punchTime === "Invalid Date" ? "-||-" : punchTime;
      },
    },
  },
  {
    headerName: "Clock Out",
    field: "clockOut",
    maxWidth: 150,
    cellRenderer: ({ value }) => {
      if (!value) {
        return <div>-||-</div>;
      }
      const punchTime = dayjsNY(value).format(DEG_TIME_FORMAT);
      return <div>{punchTime === "Invalid Date" ? "-||-" : punchTime}</div>;
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        if (!value) {
          return "-||-";
        }
        const punchTime = dayjsNY(value).format(DEG_TIME_FORMAT);
        return punchTime === "Invalid Date" ? "-||-" : punchTime;
      },
    },
  },
];

export const detailsFilterData = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Employee",
    type: "Dropdown",
    key: "employeeId",
  },
  {
    globalKeyId: "7249e213-4aaf-4f15-880d-42f6e8ca9745",
    label: "Role",
    type: "Dropdown",
    key: "employeeRole",
  },
  {
    globalKeyId: "ac810ced-788d-4ce3-a6f9-123081326d38",
    label: "Jobsite",
    type: "Dropdown",
    key: "jobsiteMatch",
  },
  {
    globalKeyId: "27460cca-0b8e-4353-9389-e6ade02fd9fe",
    label: "Time Frame",
    type: "DateRange",
    key: "firstClockIn",
  },
];
