import { forwardRef, useImperativeHandle, useState } from "react";
import { message } from "antd";

import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const DateEditor = forwardRef(
  ({ stopEditing, data, column, context, api }, ref) => {
    const [date, setDate] = useState(data[column["colId"]]);

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue() {
            return date;
          },
          isCancelAfterEnd() {
            let cancel = false;
            let collisionId = "";

            const thisStart = column["colId"] === "start" ? date : data?.start;
            const thisEnd = column["colId"] === "end" ? date : data?.end;

            if (!thisStart || !thisEnd) {
              return false;
            }

            api.forEachNode((node) => {
              if (cancel) {
                return;
              } else if (node.data.id === data.id) {
                return;
              }

              const {
                data: { start, end, id },
              } = node;

              let endToCompare =
                id === context?.currentTimeline ? Date.now() : end;

              if (start < thisEnd && endToCompare > thisStart) {
                collisionId = id;
                cancel = true;
              }
            });

            if (cancel) {
              message.warning({
                content: "Cannot have two overlapping timelines",
                key: "overlappingTimelines",
              });

              api.flashCells({
                rowNodes: [api.getRowNode(collisionId)],
              });
            }

            return cancel;
          },
        };
      },
      [date]
    );

    return (
      <InputComponent
        {...{
          label: null,
          type: "datePicker",
          className: "form-editor date-picker-editor",
          allowClear: false,
          autoFocus: true,
          defaultOpen: true,
          onChange(e) {
            setDate(parseInTz(e).valueOf());
            setTimeout(() => {
              stopEditing();
            }, 0);
          },
          onOpenChange(open) {
            if (!open) {
              setTimeout(() => {
                stopEditing();
              }, 0);
            }
          },
          disabledDate(d) {
            if (column.colId === "start") {
              return (
                parseInTz(d).startOf("day").valueOf() >
                  parseInTz(data.end || undefined)
                    .startOf("day")
                    .valueOf() ||
                parseInTz(d).startOf("day").valueOf() <
                  parseInTz(context.createdAt).valueOf()
              );
            } else {
              return (
                parseInTz(d).startOf("day").valueOf() <
                  parseInTz(data.start || context.createdAt)
                    .startOf("day")
                    .valueOf() ||
                parseInTz(d).startOf("D").valueOf() > Date.now()
              );
            }
          },
          value: date ? parseInTz(date) : undefined,
        }}
      />
    );
  }
);

export default DateEditor;
