import { apiRoutes, fetchData } from "../../../../../Fleet/utils";
import { compareIncluding } from "../../../../../utils";

export const getServicesToBeInspected = (valuesArray, pliArray) => {
  const structuredValues = valuesArray
    .filter((item) => item?.name?.split("_").length === 1)
    .map((item) => {
      try {
        const elevations = valuesArray
          .filter(
            (elItem) =>
              elItem?.name?.split("_").length === 2 &&
              elItem?.name?.includes(item?.name)
          )
          .map((elItem) => {
            const plis = valuesArray
              .filter(
                (pliItem) =>
                  pliItem?.name?.split("_")?.length === 3 &&
                  pliItem?.name?.includes(elItem?.name)
              )
              ?.map((formPli) => ({
                ...formPli,
                pli: pliArray.filter(({ fieldName }) =>
                  formPli.name?.includes(fieldName)
                )[0]?.pli,
              }));
            return {
              elevation: elItem?.name,
              value: elItem?.value,
              plis,
              estimationId: elItem?.estimationId,
              estimationNumber: elItem?.estimationNumber,
            };
          });
        return {
          service: item?.name,
          value: item?.value,
          elevations,
          estimationId: item?.estimationId,
          estimationNumber: item?.estimationNumber,
        };
      } catch (e) {
        console.error(e);
        return {
          service: item?.name,
          value: item?.value,
          elevations: valuesArray,
          estimationId: item?.estimationId,
          estimationNumber: item?.estimationNumber,
        };
      }
    });

  const servicesToBeInspected = structuredValues
    .map((item) => {
      const elevationsInspected = item?.elevations
        ?.map((elItem) => {
          // no service has been checked
          if (elItem?.value === false) {
            return {
              elevationLabel: elItem?.elevation?.split("_")[1],
              isInspectedFull: "none",
              estimationId: elItem?.estimationId,
              estimationNumber: elItem?.estimationNumber,
            };
          }
          // the entire service has been checked
          if (elItem?.value === true) {
            return {
              elevationLabel: elItem?.elevation?.split("_")[1],
              plisInspected: elItem?.plis?.filter((pli) => pli?.value == true),
              isInspectedFull: true,
              estimationId: elItem?.estimationId,
              estimationNumber: elItem?.estimationNumber,
            };
          }

          // the service is in indeterminate mode
          const plisInspected = elItem?.plis
            ?.filter(({ value }) => value == true)
            ?.map((pliItem) => ({
              pliId: pliItem?.name?.split("_")[2],
              pli: {
                addons: pliItem?.pli?.addons,
                approved: pliItem?.pli?.approved,
                breakdownValue: pliItem?.pli?.breakdownValue,
                height: pliItem?.pli?.height,
                id: pliItem?.pli?.id,
                length: pliItem?.pli?.length,
                lock: pliItem?.pli?.lock,
                serviceId: pliItem?.pli?.serviceId,
                sqft: pliItem?.pli?.sqft,
                width: pliItem?.pli?.width,
              },
              isInspected: pliItem?.value,
              estimationId: pliItem?.estimationId,
              estimationNumber: pliItem?.estimationNumber,
            }));
          return {
            elevationLabel: elItem?.elevation?.split("_")[1],
            isInspectedFull: false,
            plisInspected,
            estimationId: elItem?.estimationId,
            estimationNumber: elItem?.estimationNumber,
          };
        })
        ?.filter(
          ({ isInspectedFull }) =>
            isInspectedFull == true || isInspectedFull == false
        );

      if (item?.value === true) {
        return {
          serviceType: item?.service,
          isInspectedFull: true,
          estimationId: item?.estimationId,
          estimationNumber: item?.estimationNumber,
          elevationsInspected,
        };
      }
      if (item?.value === "indeterminate") {
        return {
          serviceType: item?.service,
          isInspectedFull: false,
          elevationsInspected,
          estimationId: item?.estimationId,
          estimationNumber: item?.estimationNumber,
        };
      }
      if (
        item?.elevations?.filter((elItem) => elItem?.value === "indeterminate")
          ?.length > 0
      ) {
        return {
          serviceType: item?.service,
          isInspectedFull: false,
          elevationsInspected,
          estimationId: item?.estimationId,
          estimationNumber: item?.estimationNumber,
        };
      }
      return {
        serviceType: item?.service,
        isInspectedFull: "none",
        estimationId: item?.estimationId,
        estimationNumber: item?.estimationNumber,
      };
    })
    .filter((item) => Object.keys(item).length > 0);

  return servicesToBeInspected;
};

export const getDynamicQuestions = ({
  dynamicQuestions = [],
  serviceName,
  nameOfQuestions,
}) => {
  const questionsObj =
    dynamicQuestions.find(
      ({ nameOfQuestions: arrServiceName, serviceName: arrName }) =>
        !!serviceName
          ? arrName === serviceName
          : arrServiceName === nameOfQuestions
    )?.questions || {};

  const questionsCategories = Object.keys(questionsObj);

  return questionsCategories?.map((categoryName) => ({
    categoryName,
    questions: questionsObj[categoryName]?.questions,
  }));
};

export const getQuestions = async (
  servicesToBeInspected = [],
  getFieldValue
) => {
  try {
    const dynamicQuestions = await fetchData(apiRoutes.dynamicQuestions);
    let response = [];
    if (!!servicesToBeInspected?.length) {
      const serviceTypesToBeInspected = servicesToBeInspected?.filter(
        ({ isInspectedFull }) => isInspectedFull !== "none"
      );
      serviceTypesToBeInspected.forEach((service) => {
        response = [
          ...response,
          {
            estimationId: service?.estimationId,
            estimationNumber: service?.estimationNumber,
            type: service?.serviceType,
            serviceQuestions: getDynamicQuestions({
              dynamicQuestions,
              serviceName: service?.serviceType,
            }),
            elevationsInspected: service?.elevationsInspected,
          },
        ];
      });
    } else if (
      compareIncluding(
        getFieldValue("newInspectionInspType"),
        "HOIST MAINTENANCE"
      )
    ) {
      response = [
        {
          type: getFieldValue("newInspectionInspType"),
          serviceQuestions: getDynamicQuestions({
            dynamicQuestions,
            serviceName: "Hoist Maintenance",
          }),
        },
      ];
    } else {
      response = [
        {
          type: getFieldValue("newInspectionInspType"),
          serviceQuestions: getDynamicQuestions({
            dynamicQuestions,
            nameOfQuestions: "Pre-Installation Questions",
          }),
        },
      ];
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};
