import columnDefinitions from "./index";
import { GridRowModifier } from "../../subcomponents";
import React from "react";
import _ from "lodash";
import {
  DimensionClassRules,
  priceCellClassRules,
} from "../formatters/GridStyles";
import { priceFormatter } from "../formatters/pricing";
import {
  dimensionFormatter,
  evaluateMathExpression2,
  evaluateMonth,
  pliTotalPrice,
  valueGetter,
} from "../formatters/evaluators";
import { toFixedFormatter } from "../formatters";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { applyFormulaFromArray } from "../../ServiceAddon";
import { MondayButton } from "../../../../../commonComponents";
import { RentalsIcon } from "../../../../../../icons";
import { currencyFormater } from "../../../../../../utils/currencyFormater";

/**
 * @author Jon Shkreli
 * Function that defines ag grid columns for all services
 *
 * @param service {Service} - A service, example a service for Fence
 * @param serviceIndex {number} index where is this service located in data entry grid list (from 0 to 19)
 * @param optionIndex {number}
 * @param elevationIndex {number}
 * @param dataEntryGrid {DataEntryGrid}
 *
 * @return [Object]
 *
 * An return example:
 * [
 *   {
    headerName: 'ID',
    headerTooltip: 'ID',
    field: 'id',
    minWidth: 35,
    width: 35,
    editable: false,
  },
 {
    headerName: 'Approved',
    headerTooltip: 'Approved',
    field: 'approved',
    minWidth: 40,
    width: 40,
    editable: false,
    cellRenderer: "CheckboxRenderer",
  },
 ... more column definitions
 * ]
 * */

const GENERAL_COLUMNS = [];

export default function ServiceColumnDefiner(
  service,
  serviceIndex,
  optionIndex,
  elevationIndex,
  dataEntryGrid,
  agGridTheme,
  accessRights,
  scopesColumnDefs,
  handleSave
) {
  let colDefinition = [];
  colDefinition.push({
    headerName: "Modify",
    headerTooltip: "Modify",
    minWidth: 100,
    width: 100,
    editable: false,
    resizable: false,
    cellRendererFramework: (params) => {
      return (
        <GridRowModifier
          type="pli"
          gridParams={params}
          appendRow={dataEntryGrid.appendPLIRow}
          duplicateRow={dataEntryGrid.duplicatePLIRow}
          removeRow={dataEntryGrid.removePLIRow}
          isWritable={dataEntryGrid.props.isWritable}
          indexes={{ serviceIndex, optionIndex, elevationIndex }}
          accessRights={accessRights}
        />
      );
    },
  });
  if (!service?.isScope && !!columnDefinitions[service.serviceId]) {
    colDefinition = [
      ...colDefinition,
      ...(_.cloneDeep(columnDefinitions[service.serviceId]) || []),
    ];
    let hasPliPriceWriteAccess = accessRights.children.find(
      (item) => item.title === "Pli Price Edit"
    )?.write;

    let hasPliPriceReadAccess = accessRights.children.find(
      (item) => item.title === "Show Price"
    );
    const ppu = colDefinition?.find?.((cd) => cd.field === "ppu");
    if (!!ppu) {
      ppu.cellRendererParams = {
        ...(ppu.cellRendererParams || {}),
        indexes: { serviceIndex, optionIndex, elevationIndex },
      };
    }
    console.log("accessRights.children", accessRights);
    if (
      service.serviceId === dataEntryGrid.state.ServicesIndex["Sidewalk Shed"]
    ) {
      //sidewalk shed

      if (ppu)
        ppu.cellRendererParams = {
          priceSheetData:
            dataEntryGrid.state.selectedPriceSchemesForService[
              service.serviceId
            ].priceScheme,
          isWritable: dataEntryGrid.props.isWritable,
          updateSWSPPU: dataEntryGrid.updateSWSPPU,
          indexes: { serviceIndex, optionIndex, elevationIndex },
          accessRights,
        };
    }

    //remove price columns if user don't have access
    if (!!!hasPliPriceReadAccess)
      colDefinition = colDefinition?.filter?.(
        (c) =>
          !(
            c.field === "ppu" ||
            c.field === "rent" ||
            c.field === "price" ||
            c.field === "totalPrice" ||
            c.field === "taxRate" ||
            c.field === "taxAmount" ||
            c.field === "additionalAddonPrice" ||
            c.field === "pliAddonTotal"
          )
      );

    const SelectEditorColumns =
      colDefinition?.filter?.((cd) => cd.cellRenderer === "SelectEditor") || [];
    for (const SelectEditorColumn of SelectEditorColumns) {
      SelectEditorColumn.cellRendererParams = {
        ...SelectEditorColumn.cellRendererParams,
        gridData: dataEntryGrid.state.gridData,
        isWritable: dataEntryGrid.props.isWritable,
        service,
        serviceIndex,
        optionIndex,
        elevationIndex,
        agGridTheme,
        updateStateAndSave: dataEntryGrid.updateStateAndSave,
        accessRights,
      };
    }

    const rtfEditorColumns = colDefinition?.filter?.(
      (cd) => cd.cellRenderer === "rtfEditor"
    );
    for (const rtfEditorColumn of rtfEditorColumns) {
      rtfEditorColumn.editable = false;
      rtfEditorColumn.cellRendererParams = {
        ...rtfEditorColumn.cellRendererParams,
        gridData: dataEntryGrid.state.gridData,
        isWritable: dataEntryGrid.props.isWritable,
        service,
        serviceIndex,
        optionIndex,
        elevationIndex,
        saveDataFromRTDEditor: dataEntryGrid.saveDataFromRTDEditor,
        from: "pli",
        agGridTheme: agGridTheme,
        accessRights,
      };
    }

    const addonsColumn = colDefinition.find((cd) => cd.field === "addons");
    if (addonsColumn) {
      //if there is an addons column
      addonsColumn.cellRendererParams = {
        //add some more parameters
        ...addonsColumn.cellRendererParams,
        isWritable: dataEntryGrid.props.isWritable, //inside addons
        service,
        serviceIndex,
        optionIndex,
        elevationIndex,
        saveSidewalkShedAddonsData: dataEntryGrid.saveSidewalkShedAddonsData,
        gridData: dataEntryGrid.state.gridData,
        pricingData: dataEntryGrid.state.pricingData,
        agGridTheme: agGridTheme,
        accessRights,
      };
    }

    if (hasPliPriceReadAccess) {
      colDefinition.push({
        headerName: "Additional Addon Price",
        headerTooltip: "Additional Addon Price",
        field: "pliAddonTotal",
        minWidth: 250,
        valueFormatter: toFixedFormatter,
        editable: false,
      });
      colDefinition.push(
        {
          headerName: "Add. Months",
          headerTooltip: "Add. Rentals Months",
          field: "addRentalsMonths",
          editable: true,
          minWidth: 180,

          // width: 80,
          // cellRenderer: "SidewalkShedRentAdvisor",
        },
        {
          headerName: "Free. Months",
          headerTooltip: "Add. Rentals Months",
          field: "freeMonths",
          editable: true,
          minWidth: 180,
          // width: 80,
          // cellRenderer: "SidewalkShedRentAdvisor",
        },
        {
          headerName: "Total Rent. Months",
          headerTooltip: "Add. Rentals Months",
          field: "totalAddMonths",
          editable: false,
          minWidth: 330,
          valueGetter: (params) => {
            let freeMonths = forceToNumber(params.data.freeMonths);
            let addRentalsMonths = forceToNumber(params.data.addRentalsMonths);
            params.data.totalAddMonths = freeMonths + addRentalsMonths + 3;
            return params.data.totalAddMonths;
          },
          cellRendererFramework: (params) => {
            return (
              <MondayButton
                className="mondayButtonPrismarine"
                Icon={<RentalsIcon />}
              >
                {params?.value} months where 3 are incl. in Price
              </MondayButton>
            );
          },
        },
        {
          headerName: "Add Rent Amount (Month)",
          headerTooltip: "Add Rentals Amount Monthly",
          field: "addRentalsPriceMonth",
          editable: false,
          minWidth: 250,
          valueFormatter: ({ value = 0 }) =>
            currencyFormater(forceToNumber(value), false),
          valueGetter: (params) => {
            if (!params?.data?.lock) {
              let rate = forceToNumber(params.data.addRentalsRecuringRate);
              let totalAddMonths = forceToNumber(params.data.addRentalsMonths);
              params.data.addRentalsPriceMonth =
                (rate / 100) * params?.data?.price;
              return params.data.addRentalsPriceMonth;
            } else {
              return params.data.addRentalsPriceMonth;
            }
          },
          // width: 80,
          // cellRenderer: "SidewalkShedRentAdvisor",
        },
        {
          headerName: "Recurring Rent Rate",
          headerTooltip: "Recurring Rent Rate",
          field: "addRentalsRecuringRate",
          editable: true,
          minWidth: 250,
          valueFormatter: ({ value = 0 }) => `${value}%`,
          // width: 80,
          // cellRenderer: "SidewalkShedRentAdvisor",
        },
        {
          headerName: "Additional Rentals Price",
          headerTooltip: "Additional Rentals Price",
          field: "addRentalsTotal",
          editable: false,
          minWidth: 250,
          valueFormatter: ({ value = 0 }) =>
            currencyFormater(forceToNumber(value), false),
          valueGetter: (params) => {
            if (!params?.data?.lock) {
              let totalAddMonths = forceToNumber(params.data.addRentalsMonths);
              let addRentalsPriceMonth = forceToNumber(
                params.data.addRentalsPriceMonth
              );
              params.data.addRentalsTotal =
                totalAddMonths * addRentalsPriceMonth;
              params.data.addRentalsTotal +=
                params.data.addRentalsTotal *
                forceToNumber(params.data.addTaxRate);
              return params.data.addRentalsTotal;
            } else {
              return params.data.addRentalsTotal;
            }
          },
          // width: 80,
          // cellRenderer: "SidewalkShedRentAdvisor",
        }
      );
    }

    colDefinition.forEach((c) => {
      if (
        c.cellRenderer === "CheckboxRenderer" ||
        c.cellRenderer === "JumpRenderer"
      ) {
        c.cellRendererParams = {
          isWritable: dataEntryGrid.props.isWritable,
          accessRights,
        };
      }

      if (
        c.field === "ppu" ||
        c.field === "rent" ||
        c.field === "price" ||
        c.field === "totalPrice"
      ) {
        c.valueFormatter = priceFormatter;
        c.valueParser = evaluateMathExpression2;
        c.cellClassRules = priceCellClassRules;
        c.valueGetter = valueGetter;
        // c.editable = !!hasPliPriceWriteAccess
      }

      if (c.field === "totalPrice") {
        c.cellStyle = (params) => {
          return {
            color:
              forceToNumber(params?.data?.totalPrice) > 0 ? "white" : "red",
            backgroundColor:
              forceToNumber(params?.data?.totalPrice) > 0 ? "#2DA365" : "pink",
          };
        };
      }

      if (c.field === "id") {
        c.cellClass = "id-column";
      }
      if (c.field === "approved") {
        c.headerComponent = "ApprovedRenderer";
        c.headerComponentParams = {
          isWritable: dataEntryGrid.props.isWritable,
          accessRights,
          toggleApproveAll: (checked, api) =>
            dataEntryGrid.toggleApproveAll(
              checked,
              api,
              service.serviceId,
              optionIndex,
              elevationIndex
            ),
        };
      }

      if (
        c.field === "width" ||
        c.field === "height" ||
        c.field === "length" ||
        c.field === "facadeHeight"
      ) {
        c.valueParser = evaluateMathExpression2;
        c.valueFormatter = dimensionFormatter;
        const priceSchemeForThisService =
          dataEntryGrid.state.selectedPriceSchemesForService[service.serviceId];
        c.cellClassRules = DimensionClassRules(
          priceSchemeForThisService,
          c.field
        );
        c.valueGetter = valueGetter;
      }

      // if(dataEntryGrid.state.UIFeaturesForEachElevation[service.serviceId]?.[optionIndex]?.[elevationIndex]?.columns?.[c.field]?.visible !== undefined)

      //check if column is hidden or visible in UIFeaturesForEachElevation and set it like that
      if (
        dataEntryGrid.state.UIFeaturesForEachElevation[service.serviceId]?.[
          optionIndex
        ]?.[elevationIndex]?.columns?.[c.field]?.visible !== undefined
      )
        c.hide =
          !dataEntryGrid.state.UIFeaturesForEachElevation[service.serviceId]?.[
            optionIndex
          ]?.[elevationIndex]?.columns?.[c.field]?.visible;
    });
  } else if (!service?.isScope && !columnDefinitions[service.serviceId]) {
    if (Array.isArray(service?.columnDefinitions)) {
      let temp = service?.columnDefinitions?.filter(
        (el) =>
          el?.field !== "approved" &&
          el?.field !== "id" &&
          el?.field !== "description"
      );
      colDefinition.push(
        {
          headerName: "ID",
          headerTooltip: "ID",
          field: "id",
          // minWidth: 65,
          // width: 65,
          editable: false,
          // rowDrag: true,
        },
        {
          headerName: "Approved",
          headerTooltip: "Approved",
          field: "approved",
          // minWidth: 150,
          // width: 150,
          editable: false,
          cellRenderer: "CheckboxRenderer",
          //   cellRendererParams: {
          //   isWritable: this.props.isWritable,
          // },
        }
      );
      colDefinition = [...colDefinition, ...temp];
      colDefinition.push(
        {
          headerName: "Addons",
          headerTooltip: "Addons",
          field: "addons",
          width: 200,
          minWidth: 140,
          cellRenderer: "agGroupCellRenderer",
          editable: false,
          suppressPadding: true,
          cellRendererParams: {
            suppressPadding: true,

            suppressCount: true,
            // checkbox: true,
            innerRenderer: "SidewalkShedAddonsRenderer",
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true,
          },
          valueGetter: (params) => {
            // if (params.data.isJump.checked) {
            //   return null;
            // }
            // else {
            return params?.data?.addons;
            // }
          },
          valueFormatter: (params) => {
            if (params?.data?.addons) {
              return `(${params?.data?.addons?.length})`;
            } else {
              return "N/A";
            }
          },
          // valueFormatter: evaluateDimensionExpression,
        },
        {
          headerName: "Description",
          headerTooltip: "Description",
          field: "description",
          // width: 150,
          cellRenderer: "rtfEditor",
        },
        {
          headerName: "Internal Note",
          headerTooltip: "Internal Note",
          field: "note",
          // width: 150,
          cellRenderer: "rtfEditor",
        },
        {
          headerName: "PPU",
          headerTooltip: "Price Per Unit",
          field: "ppu",
          cellRenderer: "SidewalkShedPPUAdvisor",
          // minWidth: 100,
          // width: 200,
        },
        {
          headerName: "Rent",
          headerTooltip: "Rent",
          field: "rent",
          // width: 80,
          cellRenderer: "SidewalkShedRentAdvisor",
        },
        {
          headerName: "Months",
          headerTooltip: "Months",
          field: "months",
          // width: 80,
          valueFormatter: evaluateMonth,
        },
        {
          headerName: "Price",
          headerTooltip: "Price",
          field: "price",
          hide: false,
          // width: 80,
          getQuickFilterText: () => "",
        },
        {
          headerName: "Lock",
          field: "lock",
          cellRenderer: "CheckboxRenderer",
          // minWidth: 70,
          // width: 70,
          editable: false,
        },
        {
          headerName: "Tax Rate",
          field: "taxRate",
          valueFormatter: ({ value = 0 }) =>
            forceToNumber(value) !== 0 ? `${forceToNumber(value) * 100}%` : "-",
          editable: false,
        },
        {
          headerName: "Tax Amount",
          field: "taxAmount",
          valueFormatter: toFixedFormatter,
          editable: false,
        },
        {
          headerName: "Total",
          field: "totalPrice",
          valueFormatter: toFixedFormatter,
          editable: false,
        }
      );
      return colDefinition;
    }
  } else if (service?.isScope) {
    colDefinition = [];
    colDefinition =
      scopesColumnDefs
        ?.find(({ serviceName }) => serviceName === service?.label)
        ?.columnDefinitions?.map((el) => {
          let temp = { ...el };
          if (Array.isArray(el.formula) && el?.formula?.length > 0) {
            temp.valueGetter = (params) => {
              //  el?.formula?.forEach((f) => {
              let val2 = getFormulaResValueGetter(el?.formula, params?.data);
              params.data[el?.field] = val2;
              if (!!params?.data?.formulas) {
                params.data.formulas[`${el?.field}`] = [
                  {
                    formula: el?.formula,
                    value: val2,
                    field: el?.field,
                  },
                ];
              } else {
                params.data.formulas = {
                  [`${el?.field}`]: [
                    {
                      formula: el?.formula,
                      value: val2,
                      field: el?.field,
                    },
                  ],
                };
              }
              return val2;
            };
          }
          if (el?.type === "text") {
            temp.cellStyle = (params) => {
              return {
                whiteSpace: "normal",
                lineHeight: "25px",
                textAlign: "center",
              };
            };
            temp.width = 550;
            temp.wrapText = true;
            temp.autoHeight = true;
            temp.flex = 6;
          }
          return temp;
        }) || [];
    // colDefinition.push({
    //   headerName: "ID",
    //   headerTooltip: "ID",
    //   field: "id",
    //   minWidth: 35,
    //   width: 35,
    //   editable: false,
    // });
    console.log("colDefinitionsScope", colDefinition);
    // colDefinition.push({
    //   headerName: "Modify",
    //   headerTooltip: "Modify",
    //   minWidth: 55,
    //   width: 55,
    //   editable: false,
    //   resizable: false,
    //   cellRendererFramework: (params) => {
    //     return (
    //       <GridRowModifier
    //         type="pli"
    //         gridParams={params}
    //         appendRow={dataEntryGrid.appendPLIRow}
    //         duplicateRow={dataEntryGrid.duplicatePLIRow}
    //         removeRow={dataEntryGrid.removePLIRow}
    //         isWritable={dataEntryGrid.props.isWritable}
    //         indexes={{ serviceIndex, optionIndex, elevationIndex }}
    //         accessRights={accessRights}
    //       />
    //     );
    //   },
    // });
  }

  console.log("colDefinition", colDefinition);
  return colDefinition;
}

export const defaultColDef = {
  minWidth: 100,
  editable: true, //is configured depending if user is on write more or not
  hide: false,
  filter: true,
  sortable: true,
  resizable: true,
  detailRowAutoHeight: true,
  // autoHeight: true,
  enablePivot: true,
  enableRowGroup: false,
  cellClass: "cell-wrap-text",
};

const getFormulaResValueGetter = (formula, obj) => {
  try {
    let res = 0;
    let tempArr = [];
    formula.forEach((f) => {
      if (f !== "+" && f !== "-" && f !== "*" && f !== "/") {
        if (obj[f] !== undefined) {
          let temp = f?.replace(/^0+/, "");
          tempArr.push(obj[temp] ?? "-");
        } else {
          tempArr.push(0);
        }
      } else {
        tempArr.push(f);
      }
    });
    console.log("formula", { formula, tempArr, obj });
    console.log("eval", tempArr?.join(""));
    return tempArr.length > 0 ? eval(tempArr?.join("")) : 0;
  } catch (e) {}
};
