import { Collapse } from "antd";
import PeriodCellEditor from "../RentalBody/AgGrid/AgGridComponents/PeriodCellEditor/PeriodCellEditor";
import "./RentalAgGrid.scss";

import { AgGridReact } from "ag-grid-react";

const RentalAgGrid = ({ darkMode, ...rest }) => {
  return (
    <div
      className={
        darkMode
          ? "agGridRentalContainer agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
          : "agGridRentalContainer agGridRentalsDetailsStyleLight ag-theme-alpine"
      }
    >
      <AgGridReact
        stopEditingWhenGridLosesFocus={true}
        suppressDragLeaveHidesColumns={true}
        allowDragFromColumnsToolPanel={true}
        enableCellChangeFlash={true}
        undoRedoCellEditing={true}
        enableRangeSelection={false}
        undoRedoCellEditingLimit={50}
        frameworkComponents={{ PeriodCellEditor }}
        domLayout="normal"
        rowSelection="single"
        rowHeight={40}
        debug={true}
        {...rest}
      />
    </div>
  );
};

export default RentalAgGrid;
