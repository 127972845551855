import {
  Fragment,
  useRef,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { message } from "antd";

import { batchUpdateItems } from "src/utils";
import { ProjectMatchContext } from "../../data";
import MatchMap from "../MatchMap/MatchMap";
import MatchScheduleInfo from "../MatchScheduleInfo/MatchScheduleInfo";
import AddressDispatchesGrid from "../AddressDispatchesGrid/AddressDispatchesGrid";

import "./MatchProjectDetails.scss";

const MatchProjectDetails = forwardRef((props, ref) => {
  const { selectedId, updateDispatchesHandler, dispatchRecords } =
    useContext(ProjectMatchContext);

  const scheduleInfoRef = useRef(null);
  const gridRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        async onConfirm() {
          const selectedRoutesData = gridRef.current?.getSelectedRoutes() || [];
          const selectedDay = scheduleInfoRef.current?.getSelectedDayInfo();

          const dispatchesUpdateData = {};
          const activitiesUpdateData = {};
          const updatedDispatches = {};

          for (const route of selectedRoutesData) {
            dispatchesUpdateData[route.dispatchId] = {
              ...(dispatchesUpdateData[route.dispatchId] || {}),
              [`routes.[${route.routeIndex}].${
                selectedDay ? "scheduleId" : "projectId"
              }`]: selectedDay?.scheduleId || selectedId,
            };

            activitiesUpdateData[route.activityId] = {
              ...(activitiesUpdateData[route.activityId] || {}),
              [selectedDay ? "scheduleId" : "projectId"]:
                selectedDay?.scheduleId || selectedId,
            };

            if (!updatedDispatches[route.dispatchId]) {
              updatedDispatches[route.dispatchId] = {
                ...dispatchRecords[route.dispatchId],
              };
            }

            updatedDispatches[route.dispatchId]["routes"][route.routeIndex][
              selectedDay ? "scheduleId" : "projectId"
            ] = selectedDay?.scheduleId || selectedId;
          }

          message.loading({
            content: "Updating dispatch routes...",
            key: "batchUpdate",
            duration: 0,
          });

          try {
            await batchUpdateItems({
              tableName: "fleetDispatching",
              conditionKey: "dispatchId",
              updateData: dispatchesUpdateData,
            });
          } catch (err) {
            message.error({
              content: "Something went wrong while trying to update routes",
              key: "batchUpdate",
            });
            console.log("Error updating routes: ", err);
            return;
          }

          message.loading({
            content: "Updating fleet activities...",
            key: "batchUpdate",
            duration: 0,
          });

          try {
            await batchUpdateItems({
              tableName: "fleetActivity",
              conditionKey: "activityId",
              updateData: activitiesUpdateData,
            });
          } catch (err) {
            message.error({
              content:
                "Something went wrong while trying to update fleet activities",
              key: "batchUpdate",
            });
            console.log("Error updating activities: ", err);
            return;
          }

          updateDispatchesHandler(Object.values(updatedDispatches));

          message.success({
            content: "Data updated successfully!",
            key: "batchUpdate",
          });
        },
      };
    },
    [selectedId, dispatchRecords]
  );

  function getSelectedDayInfo() {
    if (scheduleInfoRef.current) {
      return scheduleInfoRef.current.getSelectedDayInfo();
    }
  }

  function getSelectedRoutes() {
    if (gridRef.current) {
      return gridRef.current.getSelectedRoutes();
    }
  }

  return (
    <Fragment>
      <div className="details-map-container-card">
        <MatchMap />
      </div>
      <div className="details-schedule-info-card">
        <MatchScheduleInfo
          ref={scheduleInfoRef}
          getSelectedRoutes={getSelectedRoutes}
        />
      </div>
      <div className="related-dispatches-container">
        <AddressDispatchesGrid
          ref={gridRef}
          getSelectedDayInfo={getSelectedDayInfo}
        />
      </div>
    </Fragment>
  );
});

export default MatchProjectDetails;
