// import { FileIcon, defaultStyles } from "react-file-icon";
import { Tooltip } from "antd";
import {
  ExcelIcon,
  PdfDocIcon,
  WordDocIcon,
} from "../../../../../../../../../../../../assets";
import { useEmailBox } from "../../../../../../../providers/EmailBoxProvider";
import { useAttachments } from "../../../../../../../providers/AttachmentsProvider";

const ItemContainer = ({
  file,
  isSelected,
  files,
  setFiles,
  setShowUpload,
}) => {
  const { attachments } = useAttachments();
  const type = file?.name?.split(".")[1]?.toLowerCase();
  const isDisabled = attachments.some(
    (uploadedFile) => uploadedFile.id === file.id
  );

  const FileIcon = () => {
    switch (type) {
      case "pdf":
        return <PdfDocIcon style={{ height: "20px", width: "16px" }} />;
      case "docx":
        return <WordDocIcon style={{ height: "20px", width: "16px" }} />;
      case "xlsx":
        return <ExcelIcon style={{ height: "20px", width: "16px" }} />;
    }
  };

  return (
    <>
      <Tooltip title={file.name}>
        <div
          className={`${
            isSelected ? "FolderItemContainer-selected" : "FolderItemContainer"
          } ${isDisabled ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabled) return false;

            if (isSelected) {
              files
                .filter(({ id }) => id === file.id)
                .forEach((e) => {
                  e.selected = false;
                });
              setFiles(JSON.parse(JSON.stringify(files)));
              setShowUpload((prev) => prev - 1);
            } else {
              files
                .filter(({ id }) => id === file.id)
                .forEach((e) => {
                  e.selected = true;
                });
              setFiles(JSON.parse(JSON.stringify(files)));
              setShowUpload((prev) => prev + 1);
            }
          }}
          data-testid="item-container"
        >
          <span className="CategoryIcon">{FileIcon()}</span>
          <span className="ItemTitle">{file.name}</span>
        </div>
      </Tooltip>
    </>
  );
};
export default ItemContainer;
