import "./Comment.scss";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Avatar, Button, Tooltip } from "antd";
import moment from "moment";
import htmlParser from "../../../utils/htmlParser";
import { extractImagesSrc } from "../../../utils/extractImagesSrc";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Tick } from "../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import { DropdownIcon } from "../../../icons";
import { getInitials } from "../../../utils";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { ReplyIcon } from "src/components/SidebarPages/Communication/assets";
import NoteImageViewer from "../Notes/components/common_components/NoteImageViewer/NoteImageViewer";
import WarningModal from "../WarningModal/WarningModal";
import MondayButton from "../MondayButton/MondayButton";
import CommentChanges from "./components/CommentChanges/CommentChanges";
import CommentInput from "./components/CommentInput/CommentInput";
import AvatarImage from "../AvatarImage/AvatarImage";

const Comment = (props) => {
  const {
    isReply = false,
    showActions = true,
    defaultCommentId,
    usersWithAccess = [],
    thread,
    thread: {
      commentId,
      author,
      replies,
      privateOnlyTo,
      commentChanges,
      createdAt,
      cognitoUserId,
      commentContent,
    },
    showReplies,
    showReplyInput,
    updateComment = () => {},
    updateReply = () => {},
    setShowReplyInput = () => {},
    setShowReplies = () => {},
    deleteComment = () => {},
  } = props;
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences = {} } = useSelector((state) => state.preferences);

  const [visible, setVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [lighting, setLighting] = useState(true);
  const [showChangesHistory, setShowChangesHistory] = useState(false);
  const [commentToEdit, setCommentToEdit] = useState(false);

  const isAdmin = userConfiguration.groupName.toLowerCase() === "admin";

  // lighting selected comment
  useEffect(() => {
    if (lighting) {
      const lighting = setTimeout(() => {
        setLighting(false);
      }, [4500]);
      return () => {
        clearTimeout(lighting);
      };
    }
  }, []);

  const findedUser = userConfiguration?.allUsers?.Items?.find?.(
    (el) => el?.cognitoUserId === cognitoUserId
  );

  const handleImageModalVisibility = () => {
    setVisible((prevState) => !prevState);
  };

  const handleImgModal = (url) => {
    setImgUrl(url);
    handleImageModalVisibility();
  };

  // modal method for closing warning modal after enter is clicked
  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      setDeleteModalVisible(false);
      deleteComment(commentId);
    }
  };
  const getLatestReplyCreatedAt = (replies) => {
    let latestCreatedAt = null;
    replies?.forEach((reply) => {
      if (reply.createdAt > latestCreatedAt) {
        latestCreatedAt = reply.createdAt;
      }
      if (reply.replies && reply.replies.length > 0) {
        const latestReplyCreatedAt = getLatestReplyCreatedAt(reply.replies);
        if (latestReplyCreatedAt > latestCreatedAt) {
          latestCreatedAt = latestReplyCreatedAt;
        }
      }
    });
    return latestCreatedAt;
  };

  const getNoteTime = useMemo(() => {
    if (!showReplies && (replies || [])?.length > 0) {
      const latestReplyCreatedAt = getLatestReplyCreatedAt(replies);
      return !!latestReplyCreatedAt
        ? `Last modified: ${moment(latestReplyCreatedAt).fromNow(true)} ago`
        : `${moment(createdAt).fromNow(true)} ago`;
    } else {
      return `${moment(createdAt).fromNow(true)} ago`;
    }
  }, [showReplies, replies]);

  return (
    <div className={`comment-component ${isDarkMode ? "CommentDark" : ""}`}>
      <div
        className={`comment-container ${
          "comment_wrapper"
          // isReply ? "replies_wrapper" : "comment_wrapper"
        } ${defaultCommentId === commentId && lighting ? "animate" : ""}`}
      >
        {/* if it is a reply show line to connect vertically reply with its comment*/}
        {!!isReply && <div className="horizontal-line-L" />}

        {showReplies && !!replies?.length && (
          <div
            style={
              isReply
                ? { left: "29px", top: "54px", height: "calc(100% - 22px)" }
                : {}
            }
            className="first-line-L"
          />
        )}

        {!!findedUser?.googleDriveFileId ? (
          <AvatarImage
            {...{
              nameOfUser: findedUser?.nameOfUser,
              googleDriveFileId: findedUser?.googleDriveFileId,
              size: !isReply ? 45 : 30,
            }}
          />
        ) : (
          <Tooltip title={author}>
            <Avatar
              size="large"
              style={{
                width: !isReply ? "45px" : "30px",
                height: !isReply ? "45px" : "30px",
                display: "flex",
                alignItems: "center",
                backgroundColor:
                  findedUser?.nameOfUser === userConfiguration?.nameOfUser
                    ? preferences?.preferences?.profileColor || "#4895ef"
                    : "#fff",
                color:
                  findedUser?.nameOfUser === userConfiguration?.nameOfUser
                    ? "#fff"
                    : "#323338",
              }}
            >
              {getInitials(author)}
            </Avatar>
          </Tooltip>
        )}
        <div className="comment_data">
          <span className="top_details">
            <div className="top_details_left">
              <h5 style={!isReply ? {} : { fontSize: "0.8rem" }}>{author}</h5>
              <h6>{replies?.length || "0"} Replies</h6>
            </div>
            <h6 style={!isReply ? {} : { fontSize: "0.7rem" }}>
              {getNoteTime}
              {/* {moment(createdAt).fromNow(true)} ago */}
            </h6>
          </span>
          <span
            className="comment-content"
            style={!isReply ? {} : { fontSize: "0.8rem" }}
          >
            <div className="comment-text">
              {privateOnlyTo && <h6> (private note)</h6>}
              {commentChanges && (
                <Tooltip
                  onClick={() => {
                    isAdmin && setShowChangesHistory(true);
                  }}
                  placement="right"
                  title={isAdmin ? "Click to see changes history" : ""}
                >
                  <h6 style={isAdmin ? { cursor: "pointer" } : {}}>
                    (note edited)
                  </h6>
                </Tooltip>
              )}
              {commentId && commentToEdit?.commentId === commentId ? (
                <CommentInput
                  {...{
                    commentToEdit,
                    setCommentToEdit,
                    placeholder: "Edit thread!",
                    usersWithAccess,
                    saveComment: (props) => {
                      const updateObj = { ...props, comment: commentToEdit };
                      isReply
                        ? updateReply(updateObj)
                        : updateComment(updateObj);
                    },
                    isReply: false,
                    hasPrivate: !isReply,
                  }}
                />
              ) : (
                <>
                  {htmlParser(commentContent?.replace(/<img[^>]*>/g, ""))}
                  {extractImagesSrc(commentContent).map((url, key) => {
                    return (
                      <img
                        key={key}
                        src={url}
                        style={{ width: 50, height: 50, cursor: "pointer" }}
                        data-testid="image-testid"
                        onClick={() => handleImgModal(url)}
                      />
                    );
                  })}
                </>
              )}
            </div>
            {!commentToEdit &&
              showActions &&
              findedUser?.cognitoUserId === userConfiguration.cognitoUserId && (
                <div className="delete-edit-icons">
                  <EditOutlined
                    data-testid="edit-icon-testid"
                    onClick={() => setCommentToEdit(thread)}
                    style={{
                      color: "#1264a3",
                      fontSize: !isReply ? "20px" : "16px",
                    }}
                  />
                  <DeleteOutlined
                    onClick={() => {
                      setDeleteModalVisible(true);
                    }}
                    data-testid="delete-icon-testid"
                    style={{
                      color: "#fe4c4a",
                      fontSize: !isReply ? "20px" : "16px",
                    }}
                  />
                </div>
              )}
          </span>
        </div>
      </div>

      {showActions && (
        <div
          className="reply_btn"
          style={showReplies && replies?.length ? { paddingLeft: "42px" } : {}}
        >
          <Button
            icon={<ReplyIcon />}
            onClick={() => {
              setShowReplyInput((prev) => !prev);
            }}
          >
            {showReplyInput
              ? "Hide Replies Input"
              : `Write a Reply for ${author}...`}
          </Button>
        </div>
      )}

      {replies?.length > 0 && (
        <div
          className="showReplies"
          onClick={() => setShowReplies((prev) => !prev)}
        >
          {showReplies ? (
            <DropdownIcon style={{ transform: "rotate(180deg)" }} fill="#fff" />
          ) : (
            <DropdownIcon />
          )}
          <span style={{ userSelect: "none" }}>
            {showReplies ? "Hide replies" : "Show replies"}
          </span>
        </div>
      )}

      {/* Confirmation delete note modal */}
      <WarningModal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        title="Delete Note!"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
      >
        <div className="logout-modal-body">
          <WarningTriangle />
          <p>
            Do you really want to delete this
            {!isReply ? " note" : " reply"}?
          </p>
          <div className="buttons">
            <MondayButton
              onClick={() => setDeleteModalVisible(false)}
              Icon={<CloseOutlined />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                deleteComment(commentId);
                setDeleteModalVisible(false);
              }}
              Icon={<Tick />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>

      <NoteImageViewer {...{ imgUrl, visible, handleImageModalVisibility }} />
      <CommentChanges
        {...{ showChangesHistory, setShowChangesHistory, commentChanges }}
      />
    </div>
  );
};

export default Comment;
