export const dispatching = ({ tables, circleProgressPicker }) => {
  return {
    fleetName: () => ({
      percent: (
        (tables?.Dispatching?.filter(
          (a) =>
            a?.fleetName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Dispatching?.length) *
        100
      ).toFixed(1),
    }), //fleetName
    driverName: () => ({
      percent: (
        (tables?.Dispatching?.filter(
          (a) =>
            a?.driverName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Dispatching?.length) *
        100
      ).toFixed(1),
    }), //driverName
    dispatchedBy: () => ({
      percent: (
        (tables?.Dispatching?.filter((a) =>
          a?.dispatchedBy?.name
            ? a?.dispatchedBy?.name?.toLowerCase()
            : a?.dispatchedBy?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Dispatching?.length) *
        100
      ).toFixed(1),
    }), //dispatchedBy
  }; //Dispatching
};
