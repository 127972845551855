import { MondayButton } from "../../../../commonComponents";
import {
  ClearIcon,
  FilterIcon,
  NextIcon,
  PreviousIcon,
  RangeIcon,
} from "../../src";
import { Form, Select, TimePicker } from "antd";
import FormItem from "../../../DynamicView/components/FormItems/FormItem";
import "./Filter.scss";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import {
  dateFilterOptions,
  timeFilterOptions,
} from "../../../XComponents/ControlPanel/datas";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ClearFilterIcon } from "../../../DynamicView/src/index";
import { XIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";
import CustomTimeInput from "../../../../pages/Payroll/Tabs/DEG/components/CustomTimeInput/CustomTimeInput";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

const Filter = ({
  title = "",
  setOpen,
  filters,
  getFilters,
  rowData,
  filtersData,
  clearFilters,
}) => {
  const [form] = Form.useForm();
  const [range, setRange] = useState({});
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const handleClickOutside = (event) => {
    if (event.target.className === "FilterModalMask") {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (keys(filtersData)?.length !== 0) {
      if (
        keys(filtersData)
          ?.filter((e) => !e?.includes("range"))
          ?.map((e) => filtersData[e])
          ?.find?.((el) => el?.length === 2)
      ) {
        if (
          !filtersData?.label &&
          !filtersData?.proposedTypeOfWorkList &&
          !filtersData?.sow
          /*this condition might not be right if at filter ag grid show white page check this*/
        ) {
          if (keys(filtersData)?.length === 0) return;
          form.setFieldsValue({
            [`${keys(filtersData)[0]}week`]: Object.keys(
              dateFilterOptions
            )?.find?.(
              (key) =>
                dateFilterOptions[key] === filtersData[keys(filtersData)?.[0]]
            ),
            [`${keys?.(filtersData)?.[0]}start`]:
              filtersData[keys(filtersData)?.[0]]?.[0]?.valueOf(),
            [`${keys?.(filtersData)?.[0]}end`]:
              filtersData[keys(filtersData)?.[0]]?.[1]?.valueOf(),
          });

          form.setFieldsValue({
            [`${keys?.(filtersData)?.[0]}range1`]:
              filtersData[keys(filtersData)?.[0]]?.[0]?.valueOf(),
            [`${keys?.(filtersData)?.[0]}range2`]:
              filtersData[keys(filtersData)?.[0]]?.[1]?.valueOf(),
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue(filtersData);
  }, [filtersData]);

  useEffect(() => {
    setRange(form.getFieldsValue());
  }, [form]);

  document.addEventListener("mousedown", handleClickOutside);

  return (
    <div className="FilterModalMask" data-testid="filter-grid-modal">
      <div
        className={`FilterModalComponent ${
          isDarkMode && "FilterModalComponentDark"
        }`}
      >
        <Form form={form}>
          <div className="FilterModalComponentHeader">
            <span className="ModalTitle">{title} Filters</span>
            <span
              className="clearIcon"
              onClick={() => {
                setOpen(false);
              }}
            >
              <XIcon fill="#333238" />
            </span>
          </div>
          <div className="FilterModalComponentBody">
            <div className="FilterModalComponentBodyContainer">
              <div className="FilterModalComponentBodyContainerLeft">
                {filters
                  ?.filter(({ type }) => type === "Dropdown")
                  ?.map(
                    (
                      {
                        label,
                        type,
                        key,
                        style,
                        value,
                        optionKey = "",
                        masterDetail = false,
                        options,
                      },
                      i
                    ) => {
                      return (
                        <div
                          className="FilterModalComponentBodyContainerDropdownElement"
                          key={i}
                        >
                          <span className="FilterName">{label}</span>
                          {FormItem["MultipleAutoComplete"]({
                            value: value,
                            name: Array.isArray(key) ? key[0] : key,

                            form: form,
                            style,
                            placeholder: "Choose options...",
                            isDarkMode,
                            options: !!options?.length
                              ? options
                              : [
                                  ...new Set(
                                    rowData
                                      ?.map((e) => {
                                        if (!masterDetail) {
                                          let value = keys(e).find((el) =>
                                            Array.isArray(key)
                                              ? key.find(
                                                  (nestedKey) =>
                                                    el === nestedKey
                                                )
                                              : el === key
                                          );

                                          if (Array.isArray(e[value])) {
                                            return e[value].map((ele) => {
                                              if (typeof ele === "object") {
                                                return ele?.[optionKey];
                                              } else {
                                                return ele;
                                              }
                                            });
                                          } else if (
                                            typeof e[value] === "object"
                                          ) {
                                            return e?.[value]?.nameOfUser
                                              ? e?.[value]?.nameOfUser
                                              : e?.[value]?.accountName
                                              ? e?.[value]?.accountName
                                              : e?.[key[0]]?.[key[1]];
                                          } else {
                                            if (
                                              e?.[value] === undefined ||
                                              e?.[value] === null ||
                                              e?.[value] === "" ||
                                              e?.[value] === " "
                                            ) {
                                              return null;
                                            }

                                            if (
                                              typeof e?.[value] === "number" &&
                                              e?.[value].toString().length ===
                                                13
                                            ) {
                                              return dayjs(e?.[value]).format(
                                                "DD MMM YYYY"
                                              );
                                            }

                                            if (
                                              e?.[value].toString().length === 1
                                            ) {
                                              return null;
                                            }

                                            return e?.[value];
                                          }
                                        } else {
                                          return e?.masterDetail
                                            ?.map((element) => {
                                              let value = keys(element).find(
                                                (el) => el === key
                                              );
                                              if (
                                                Array.isArray(element[value]) &&
                                                element[value]?.length > 0
                                              ) {
                                                return element[value].map(
                                                  (ele) => ele
                                                );
                                              } else if (
                                                typeof element[value] ===
                                                "object"
                                              ) {
                                                return element[value]
                                                  ?.nameOfUser;
                                              } else {
                                                return element[value];
                                              }
                                            })
                                            .flat(1);
                                        }
                                      })
                                      .flat(1)
                                  ),
                                ]?.filter(Boolean),
                          })}
                        </div>
                      );
                    }
                  )}
              </div>
              {filters.filter(
                ({ type }) => type === "DatePicker" || type === "DateRange"
              ).length !== 0 && (
                <div className="FilterModalComponentBodyContainerRightDivider"></div>
              )}
              <div className="FilterModalComponentBodyContainerRight">
                <div className="FilterModalComponentBodyContainerRightDateRangeContainer">
                  {filters
                    ?.filter(({ type }) => type === "NumberRange")
                    .map((e) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <h1 className="FilterName">{e?.label}</h1>
                            <div className="FilterModalComponentBodyContainerRightDateRangeComponents">
                              <FormItem.Input
                                {...{
                                  name: `${e?.key}range1`,
                                  value:
                                    form.getFieldsValue()[`${e?.key}range1`],
                                  form: form,
                                  onChange: (e) =>
                                    console.log("range1 change: ", e),
                                }}
                              />
                              <span className="FilterModalComponentBodyContainerRightDateRangeComponentsIcons">
                                <RangeIcon />
                              </span>
                              <FormItem.Input
                                {...{
                                  name: `${e?.key}range2`,
                                  value:
                                    form.getFieldsValue()[`${e?.key}range2`],
                                  form: form,
                                }}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
                <div className="FilterModalComponentBodyContainerRightDateRangeContainer">
                  {filters
                    ?.filter(({ type }) => type === "TimeStampRange")
                    .map((e) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <h1 className="FilterName">{e?.label}</h1>
                            <div className="FilterModalComponentBodyContainerRightDateRangeComponents">
                              <CustomTimeInput
                                form={form}
                                formItemName={`${e?.key}timeRange1`}
                              />
                              <span className="FilterModalComponentBodyContainerRightDateRangeComponentsIcons">
                                <RangeIcon />
                              </span>
                              <CustomTimeInput
                                form={form}
                                formItemName={`${e?.key}timeRange2`}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
                <div className="FilterModalComponentBodyContainerRightDateRangeContainer">
                  {filters
                    .filter(({ type }) => type === "DateRange")
                    .map((e) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <h1 className="FilterName">{e?.label}</h1>
                            <div className="FilterModalComponentBodyContainerRightDateRangeContainerCategories">
                              <span className="previousButton">
                                <PreviousIcon
                                  onClick={() => {
                                    if (
                                      keys(dateFilterOptions).indexOf(
                                        form.getFieldsValue()[`${e?.key}week`]
                                      ) !== -1 &&
                                      keys(dateFilterOptions).indexOf(
                                        form.getFieldsValue()[`${e?.key}week`]
                                      ) > 0
                                    ) {
                                      form.setFieldsValue({
                                        [`${e?.key}week`]:
                                          keys(dateFilterOptions)[
                                            parseInt(
                                              keys(dateFilterOptions).indexOf(
                                                form.getFieldsValue()[
                                                  `${e?.key}week`
                                                ]
                                              )
                                            ) - 1
                                          ],
                                        [`${e?.key}start`]:
                                          dateFilterOptions[
                                            keys(dateFilterOptions)[
                                              parseInt(
                                                keys(dateFilterOptions).indexOf(
                                                  form.getFieldsValue()[
                                                    `${e?.key}week`
                                                  ]
                                                )
                                              ) - 1
                                            ]
                                          ][0].valueOf(),
                                        [`${e?.key}end`]:
                                          dateFilterOptions[
                                            keys(dateFilterOptions)[
                                              parseInt(
                                                keys(dateFilterOptions).indexOf(
                                                  form.getFieldsValue()[
                                                    `${e?.key}week`
                                                  ]
                                                )
                                              ) - 1
                                            ]
                                          ][1].valueOf(),
                                      });
                                      range[`${e?.key}week`] =
                                        keys(dateFilterOptions)[
                                          parseInt(
                                            keys(dateFilterOptions).indexOf(
                                              form.getFieldsValue()[
                                                `${e?.key}week`
                                              ]
                                            )
                                          ) - 1
                                        ];
                                      range[`${e?.key}start`] =
                                        dateFilterOptions[
                                          form.getFieldsValue()[`${e?.key}week`]
                                        ][0].valueOf();
                                      range[`${e?.key}end`] =
                                        dateFilterOptions[
                                          form.getFieldsValue()[`${e?.key}week`]
                                        ][1].valueOf();
                                      setRange(state(range));
                                    } else {
                                      return null;
                                    }
                                  }}
                                />
                              </span>

                              <FormItem.Dropdown
                                options={keys(dateFilterOptions)}
                                value={
                                  form.getFieldsValue()[`${e.key}week`] || null
                                }
                                name={`${e.key}week`}
                                form={form}
                                onChange={(el) => {
                                  form.setFieldsValue({
                                    [`${e?.key}week`]: el,
                                    [`${e?.key}start`]:
                                      dateFilterOptions[
                                        keys(dateFilterOptions)[
                                          parseInt(
                                            keys(dateFilterOptions).indexOf(el)
                                          )
                                        ]
                                      ][0].valueOf(),
                                    [`${e?.key}end`]:
                                      dateFilterOptions[
                                        keys(dateFilterOptions)[
                                          parseInt(
                                            keys(dateFilterOptions).indexOf(el)
                                          )
                                        ]
                                      ][1].valueOf(),
                                  });
                                  range[`${e?.key}week`] =
                                    keys(dateFilterOptions)[
                                      parseInt(
                                        keys(dateFilterOptions).indexOf(el)
                                      )
                                    ];
                                  range[`${e?.key}start`] =
                                    dateFilterOptions[el][0].valueOf();
                                  range[`${e?.key}end`] =
                                    dateFilterOptions[el][1].valueOf();
                                  setRange(state(range));
                                }}
                                placeholder="Choose one..."
                                isDarkMode={isDarkMode}
                              />
                              <span
                                className="nextButton"
                                onClick={() => {
                                  if (
                                    keys(dateFilterOptions).indexOf(
                                      form.getFieldsValue()[`${e?.key}week`]
                                    ) !== -1 &&
                                    keys(dateFilterOptions).indexOf(
                                      form.getFieldsValue()[`${e?.key}week`]
                                    ) +
                                      1 <
                                      keys(dateFilterOptions).length - 1
                                  ) {
                                    form.setFieldsValue({
                                      [`${e?.key}week`]:
                                        keys(dateFilterOptions)[
                                          parseInt(
                                            keys(dateFilterOptions).indexOf(
                                              form.getFieldsValue()[
                                                `${e?.key}week`
                                              ]
                                            )
                                          ) + 1
                                        ],
                                      [`${e?.key}start`]:
                                        dateFilterOptions[
                                          keys(dateFilterOptions)[
                                            parseInt(
                                              keys(dateFilterOptions).indexOf(
                                                form.getFieldsValue()[
                                                  `${e?.key}week`
                                                ]
                                              )
                                            ) + 1
                                          ]
                                        ][0].valueOf(),
                                      [`${e?.key}end`]:
                                        dateFilterOptions[
                                          keys(dateFilterOptions)[
                                            parseInt(
                                              keys(dateFilterOptions).indexOf(
                                                form.getFieldsValue()[
                                                  `${e?.key}week`
                                                ]
                                              )
                                            ) + 1
                                          ]
                                        ][1].valueOf(),
                                    });
                                    range[`${e?.key}week`] =
                                      keys(dateFilterOptions)[
                                        parseInt(
                                          keys(dateFilterOptions).indexOf(
                                            form.getFieldsValue()[
                                              `${e?.key}week`
                                            ]
                                          )
                                        ) + 1
                                      ];
                                    range[`${e?.key}start`] =
                                      dateFilterOptions[
                                        form.getFieldsValue()[`${e?.key}week`]
                                      ][0].valueOf();
                                    range[`${e?.key}end`] =
                                      dateFilterOptions[
                                        form.getFieldsValue()[`${e?.key}week`]
                                      ][1].valueOf();

                                    setRange(state(range));
                                  } else {
                                    return null;
                                  }
                                }}
                              >
                                <NextIcon />
                              </span>
                            </div>
                          </div>

                          <div className="FilterModalComponentBodyContainerRightDateRangeComponents">
                            <FormItem.DatePicker
                              value={
                                form.getFieldsValue()[`${e.key}start`] || ""
                              }
                              onChange={(value) => {
                                // console.log("range value start: ", parseInTz(value));
                                range[`${e.key}start`] = value;
                                setRange(state(range));
                              }}
                              disabledDate={(current) =>
                                current &&
                                current >
                                  dayjs(
                                    form.getFieldsValue()[`${e.key}end`] || ""
                                  ).startOf("day")
                              }
                              name={`${e.key}start`}
                              form={form}
                              placeholder="Choose one..."
                              isDarkMode={isDarkMode}
                            />
                            <span className="FilterModalComponentBodyContainerRightDateRangeComponentsIcons">
                              <RangeIcon />
                            </span>
                            <FormItem.DatePicker
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  current <
                                    dayjs(
                                      form.getFieldsValue()[`${e.key}start`] ||
                                        ""
                                    ).endOf("day")
                                );
                              }}
                              onChange={(value) => {
                                range[`${e.key}end`] = value;
                                setRange(state(range));
                              }}
                              value={form.getFieldsValue()[`${e.key}end`] || ""}
                              name={`${e.key}end`}
                              form={form}
                              placeholder="Choose one..."
                              isDarkMode={isDarkMode}
                            />
                          </div>
                        </>
                      );
                    })}

                  <div className="FilterModalComponentBodyContainerRightDatePickerContainer">
                    {filters
                      ?.filter(({ type }) => type === "DatePicker")
                      ?.map(({ label, type, key, value }) => {
                        return (
                          <div className="FilterModalComponentBodyContainerDropdownElement">
                            <span className="nameLables">{label}</span>
                            {FormItem.DatePicker({
                              value:
                                value || form.getFieldValue(key)?.[key] || null,
                              name: key,
                              form: form,
                              onChange: (e) => {
                                range[key] = e;
                                setRange(state(range));
                              },
                              placeholder: "Choose one...",
                              options: rowData?.map?.((e) => {
                                let value = keys(e)?.find?.((el) => el === key);
                                return e?.[value] || null;
                              }),
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div>
                  {filters?.flatMap((el) => {
                    return el?.type === "TimeRange" ? (
                      <div>
                        <span>{el?.label}</span>
                        <FormItem.Dropdown
                          options={keys(timeFilterOptions)}
                          value={form.getFieldsValue()[`${el.key}`] || null}
                          name={el?.key}
                          form={form}
                          placeholder="Choose one"
                          onChange={(e) => {
                            if (e) {
                              form.setFieldsValue({
                                [`${el?.key}`]: [e],
                              });
                              range[`${el?.key}start`] =
                                timeFilterOptions[e][0];
                              range[`${el?.key}end`] = timeFilterOptions[e][1];
                              range[`${el?.key}`] = e;
                              setRange(state(range));
                            } else {
                              return null;
                            }
                          }}
                        />
                      </div>
                    ) : (
                      []
                    );
                  })}
                </div>
              </div>
              <div>
                {filters.flatMap((el) => {
                  return el?.type === "SliderRange" ? (
                    <div>
                      <span className="FilterName">{el?.label}</span>
                      <FormItem.SliderRange
                        form={form}
                        min={el?.min}
                        name={el?.key}
                        marks={el?.marks}
                        range={el?.range}
                        max={el?.max ?? 1000}
                        isDarkMode={isDarkMode}
                        // value={form.getFieldsValue()[`${el?.key}`]}
                      />
                    </div>
                  ) : (
                    []
                  );
                })}
              </div>
            </div>
          </div>
          <div className="FilterModalComponentFooter">
            <MondayButton
              data-testid="clear-filter-btn"
              onClick={() => {
                // setRange({});
                // filters.forEach((e) => (e.value = undefined));
                // getFilters({});
                // form.resetFields();
                clearFilters();
                form.resetFields();
              }}
              className={
                keys(range).length === 0 ||
                keys(range).find((e) => range[e] !== null) === undefined ||
                keys(form.getFieldsValue())
                  .map((e) => form.getFieldsValue()[e])
                  .filter((el) => el !== undefined)?.length === 0
                  ? "clearButton"
                  : "clearButtonReverse"
              }
              Icon={<ClearFilterIcon />}
            >
              Clear Filter
            </MondayButton>
            <MondayButton
              className="filterButton"
              data-testid="filter-btn"
              onClick={async (e) => {
                if (e) {
                  if (
                    filters.findIndex((el) => el?.type === "TimeStampRange") >
                    -1
                  ) {
                    await new Promise((resolve) => setTimeout(resolve, 500));
                  }
                  const formData = form.getFieldsValue();
                  let filterKeys = keys(formData);
                  let newFormValue = filterKeys.map((e) => {
                    if (
                      form.getFieldsValue()[e]?.length === 0 ||
                      form.getFieldsValue()[e] === undefined
                    ) {
                      return {
                        [e]: null,
                      };
                    } else {
                      return { [e]: form.getFieldsValue()[e] };
                    }
                  });

                  let newObjectFormValue = newFormValue.reduce(function (
                    result,
                    item
                  ) {
                    var key = Object.keys(item)[0];
                    result[key] = key?.includes("timeRange")
                      ? dayjsNY(item[key])?.startOf?.("m")?.valueOf?.()
                      : item[key];
                    return result;
                  },
                  {});

                  getFilters(newObjectFormValue);
                  setOpen(false);
                }
              }}
              Icon={<FilterIcon />}
            >
              Filter
            </MondayButton>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Filter;
