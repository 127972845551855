import { formatCurrency } from "../../../../../utils";
import { getSums, groupBy } from "../../../calculatingFunctions";

export const rentals = ({ tables, programFields }) => {
  const rentalStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Rentals.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = rentalStatus?.statusName?.map((status) =>
    tables?.rentals?.filter((a) => a?.rentalStatus === status)
  );

  return {
    rentalStatus: () => ({
      content: rentalStatus?.statusName?.map((status, idx) => ({
        color: rentalStatus?.statusColor[idx],
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.rentals?.filter(
            (a) => a?.rentalStatus?.toLowerCase() === status?.toLowerCase()
          ).length /
            tables?.rentals?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status,
        done: tables?.rentals?.filter(
          (a) => a?.rentalStatus?.toLowerCase() === status?.toLowerCase()
        ).length,
        custom:
          tables?.rentals &&
          groupBy(statusData, "projectAddress").map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Total Price": formatCurrency(
                    getSums({ data: statusData, key: "totalPrice" })[index]
                  ),
                  "Total Applied Rent": formatCurrency(
                    getSums({ data: statusData, key: "totalAppliedRent" })[
                      index
                    ]
                  ),
                  "Total Tax": formatCurrency(
                    getSums({ data: statusData, key: "totalTax" })[index]
                  ),
                  "Retainage Amount": formatCurrency(
                    getSums({ data: statusData, key: "retainageAmount" })[index]
                  ),
                },
              },
              ...Object?.entries(el)?.map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Total Price": formatCurrency(
                      getSums({ data: value, key: "totalPrice" })
                    ),
                    "Total Applied Rent": formatCurrency(
                      getSums({ data: value, key: "totalAppliedRent" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalTax" })
                    ),
                    "Retainage Amount": formatCurrency(
                      getSums({ data: value, key: "retainageAmount" })
                    ),
                  },
                };
              }),
            ];
          })[idx],
      })),
    }),
  };
};
