import { TaskIcon } from "src/icons/NotificationIcons";
import { ExportPdfIcon } from "../../../../../../../../../assets";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { DeleteIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { HistoryOutlined } from "@ant-design/icons";

const tooltipCategory = "Fleet Maintenance";

export const footerButtons = (
  onCancel,
  onSave,
  onDelete,
  canDelete,
  onLogClick,
  exportToPdf,
  logsAccess = false,
  openToDos
) =>
  canDelete
    ? [
        canDelete && {
          text: "Cancel",
          cancel: true,
          onClick: onCancel,
          className: "mondayButtonRed",
          tooltipCategory,
          tooltipKey: "cancel",
        },
        logsAccess && {
          text: "Show Logs",
          onClick: onLogClick,
          className: "mondayButtonBlue",
          btnIcon: <HistoryOutlined />,
          tooltipCategory,
          tooltipKey: "showLogs",
        },
        {
          text: "Export to Pdf",
          onClick: exportToPdf,
          className: "mondayButtonBlue",
          btnIcon: <ExportPdfIcon />,
          tooltipCategory,
          tooltipKey: "exportPdf",
        },
        {
          text: "To Do",
          onClick: openToDos,
          className: "mondayButtonBlue",
          btnIcon: <TaskIcon />,
          tooltipCategory,
          tooltipKey: "showTodos",
        },
        canDelete && {
          text: "Delete",
          cancel: true,
          onClick: onDelete,
          className: "mondayButtonRed",
          btnIcon: <DeleteIcon />,
          tooltipCategory,
          tooltipKey: "delete",
        },
        {
          text: "Save",
          primary: true,
          onClick: onSave,
          btnIcon: <TickIcon width={18} height={18} />,
          tooltipCategory,
          tooltipKey: "save",
        },
      ].filter(Boolean)
    : [
        {
          text: "Cancel",
          cancel: true,
          onClick: onCancel,
          className: "mondayButtonRed",
          tooltipCategory,
          tooltipKey: "cancel",
        },

        {
          text: "Save",
          primary: true,
          onClick: onSave,
          btnIcon: <TickIcon width={18} height={18} />,
          className: "saveButton",
          tooltipCategory,
          tooltipKey: "save",
        },
      ];

export const fields = ({
  mechanics,
  fleet,
  fleetId,
  allTeams,
  onSelect,
  onTruckSelect,
  onDeselect,
  teamsAccess = false,
  allFleets,
  selectedFleet,
}) => [
  {
    category: "Details",
    fields: [
      {
        label: "Vehicle",
        formItemName: "vehicle",
        placeholder: "Select...",
        required: true,
        type: !!fleetId ? "input" : "select",
        showSearch: true,
        disabled: !!fleetId,
        options: fleet,
        onSelect: (e) => {
          onTruckSelect(e, allFleets);
        },
      },
      {
        label: "Maintenance Date",
        formItemName: "maintenanceDate",
        type: "datepicker",
        required: true,
        disabled: !!fleetId,
      },
      {
        label: "Mechanic",
        formItemName: "mechanic",
        placeholder: "Select...",
        required: true,
        type: "select",
        showSearch: true,
        options: mechanics,
      },
      teamsAccess && {
        label: "Team",
        formItemName: "fleetMaintenanceTeam",
        placeholder: "Select team...",
        mode: "multiple",
        type: "select",
        showSearch: true,
        required: true,
        allowClear: false,
        customOptions: allTeams?.map((team) => ({
          label: team.teamName,
          value: team.teamName,
          members: team.members?.map(
            ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
              identityId,
              nameOfUser,
              cognitoUserId,
            })
          ),
        })),
        onSelect: onSelect,
        onDeselect: onDeselect,
      },
    ].filter(Boolean),
  },
];

const fillColor = (rowIndex) => {
  return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
};

export const pdfBorderedSection = ({ form, title, body, noColor = false }) => {
  const formattedBody = body.map((item) => {
    const value = form ? form.getFieldValue(item.id) : item.value;

    return {
      columns:
        title === "Maintenance Items" || title === "Inspection Items"
          ? [
              {
                width: "*",
                text: item.label,
                alignment: "left",
                style: ["lastColumn", "strong", "cellItem"],
              },
              {
                width: "auto",
                alignment: "right",
                style: ["lastColumn", "strong", "cellItem"],
                columns: ["Acceptable", "Poor", "Defective"].map((status) => ({
                  width: 15,
                  margin: [0, 0, 10, 0],
                  svg:
                    value === status
                      ? `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 54 54" style="enable-background:new 0 0 54 54;" xml:space="preserve">
             <style type="text/css">
               .st0{fill:none;}
               .st1{fill:none;stroke:#707070;stroke-width:3;}
               .st2{fill:none;stroke:#333333;stroke-width:7;}
             </style>
             <g id="Rectangle_1196">
               <rect class="st0" width="54" height="54"/>
               <rect x="1.5" y="1.5" class="st1" width="51" height="51"/>
             </g>
             <path id="Path_6983" class="st2" d="M9,29.9L19.1,40L45,14"/>
             </svg>
             `
                      : `<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                        <g id="Rectangle_1195" data-name="Rectangle 1195" fill="none" stroke="#707070" stroke-width="3">
                          <rect width="54" height="54" stroke="none"/>
                          <rect x="1.5" y="1.5" width="51" height="51" fill="none"/>
                        </g>
                      </svg>`,
                })),
                columnGap: 30,
              },
            ]
          : [
              {
                height: 220,
                width: "auto",
                alignment: "center",
                columns: [
                  { width: "auto", text: "" },
                  {
                    width: 520,
                    fit: [400, 200],
                    image: item.src,
                    link: `https://drive.google.com/file/d/${item.id}/view`,
                  },
                  { width: "auto", text: "" },
                ],
              },
            ],
    };
  });

  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    title === "Maintenance Items" && {
      width: "auto",
      text: "Acceptable,  Poor,  Defective",
      alignment: "right",
      height: 10,
      columns: [
        {
          width: "52.5%",
          text: "Items:",
          alignment: "left",
          height: 10,
          style: ["lastColumn", "strong", "bool"],
        },
        {
          text: "Acceptable,  Poor,  Defective",
          alignment: "right",
          style: ["lastColumn", "strong"],
          margin: [0, 5],
        },
      ],
    },
    ...formattedBody,
  ].filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
              headerRows: 2,
              pageBreak: "after",
              keepWithHeaderRows: 1,
            },
            layout,
          },
        ],
      ],
    },
    style: "outterTable",
    layout: !noColor
      ? { hLineColor: () => "#707070", vLineColor: () => "#707070" }
      : {},
  };
};
