import { formatCurrency } from "../../../../../../../utils";

const feetText = ({ params }) => {
  params.data[params.colDef.field] = parseFloat(params?.value || 0);
  return `${params?.value || 0} ft`;
};
const priceText = ({ params, nonZero }) =>
  !nonZero
    ? `${formatCurrency(params?.value || 0)}`
    : params?.data?.chargeType === "Regular"
    ? "N/A"
    : `${formatCurrency(params?.value || 0)}`;

const taxPercentage = ({ taxRate, params }) => {
  params.data[params.colDef.field] = parseFloat(taxRate || 0);
  return `${new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(taxRate)}`;
};
const taxPrice = ({ params, parent, taxRate }) => {
  params.data[params.colDef.field] = parseFloat(
    (taxRate * params?.data[parent] || 0).toFixed(2)
  );
  return `${formatCurrency((taxRate * params?.data[parent] || 0).toFixed(2))}`;
};
const totalPrice = ({ params, parent, taxRate, categoryFrom }) => {
  params.data[params.colDef.field] = parseFloat(
    (
      parseFloat((taxRate * Number(params?.data[parent]) || 0).toFixed(2)) +
      parseFloat(Number(params?.data[parent] || 0).toFixed(2)) -
      parseFloat((params?.data["applyCredit"] || 0).toFixed(2))
    ).toFixed(2)
  );
  if (categoryFrom === "Rental")
    return `${formatCurrency(
      (
        parseFloat(taxRate * Number(params?.data["appliedAmount"] || 0)) +
        parseFloat((Number(params?.data["appliedAmount"]) || 0).toFixed(2)) -
        parseFloat((params?.data["applyCredit"] || 0).toFixed(2))
      ).toFixed(2)
    )}`;
  return `${formatCurrency(
    (
      parseFloat((taxRate * Number(params?.data[parent] || 0)).toFixed(2)) +
      parseFloat((Number(params?.data[parent]) || 0).toFixed(2)) -
      parseFloat((params?.data["applyCredit"] || 0).toFixed(2))
    ).toFixed(2)
  )}`;
};
const date = ({ params: { value } }) => (
  <div>
    {new Date(value).toLocaleDateString().includes("Invalid Date")
      ? "--"
      : new Date(value).toLocaleDateString()}
  </div>
);
export const cellRenders = (e) => ({
  price: (params) => priceText({ params, ...e }),
  feet: (params) => feetText({ params, ...e }),
  tax: (params) => taxPercentage({ params, ...e }),
  taxPrice: (params) => taxPrice({ params, ...e }),
  totalPrice: (params) => totalPrice({ params, ...e }),
  date: (params) => date({ params, ...e }),
});
