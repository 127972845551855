import React, { useState } from "react";
import { MondayButton } from "../../../../../../../commonComponents";
import { Button, Modal, Popover, Select } from "antd";
import { useRedux } from "../../../../../../hooks";
import { NormalSizedModal } from "../../../../../../Fleet/components";
import { CloseIconModal, PlusIcon } from "../../../../../../../../assets";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

function HoistFloorAddon({
  params,
  service,
  parentData,
  indexes,
  handleSave,
  addonsList,
  parentAPI,
}) {
  const [open, setOpen] = useState(false);
  const [addonsToShow, setAddonsToShow] = useState([]);
  const [multipleAddonsToshow, setMultipleAddonsToShow] = useState([]); // [{label: "name", value: "name", addon: {}}
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [multipleVisible, setMultipleVisible] = useState(false);
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    setAddonsToShow(
      addonsList
        ?.filter((addon) => addon?.addonType === "pli")
        ?.map((el) => {
          let tempName = el?.values?.find(
            (val) => val?.name?.toLowerCase() === "name"
          )?.value;
          console.log("tempName", { tempName, el });
          return {
            ...el,
            name: tempName,
          };
        })
    );
    console.log("addonsList", addonsList);
  };

  const attachAddon = (addon) => {
    console.log("Attach Addon", {
      addon,
      params,
      indexes,
      parentData,
    });
    let tempGridData = gridData;
    let { serviceIndex, optionIndex, elevationIndex } = indexes;
    let floors =
      tempGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .items;
    let currentFloor = floors.find((floor) => floor.id === parentData.id) || {};
    let previousFloor =
      currentFloor?.floor === 1
        ? floors.find((floor) => floor?.floor === -1)
        : floors.find((floor) => floor?.floor === currentFloor?.floor - 1) ||
          {};
    let currentAddon = currentFloor.addons?.find(
      (e) => e.id === params.data.id
    );
    let vals = addon?.values || [];
    if (Array.isArray(vals)) {
      vals.forEach((val) => {
        if (val?.name?.toLowerCase() === "name") {
          currentAddon.name = addon;
          currentAddon.addonname = val?.value;
          if (val?.value === "Common Platform" || val?.value === "Runbacks") {
            console.log("previousFloor", previousFloor);
            currentAddon.height = previousFloor?.floor_height || 0;
          }
          console.log("valvalue", val?.value);
        } else {
          if (val?.name?.toLowerCase() !== "height") {
            currentAddon[val?.name?.toLowerCase()] = val?.value;
          }
        }
      });
    }
    params.api.applyTransaction({ update: [currentAddon] });
    // currentFloor.addons = addonsData;
    // let rows = [];
    // parentAPI.forEachNode((node) => {
    //   rows.push(node);
    // });
    // console.log("attachAddon", { addon, params });
    // let vals = addon?.values || [];
    // if (Array.isArray(vals)) {
    //   vals.forEach((val) => {
    //     if (val?.name?.toLowerCase() === "name") {
    //       params.data["name"] = addon;
    //       params.data["addonName"] = val?.value;
    //     } else params.data[val?.name?.toLowerCase()] = val?.value;
    //   });
    // }
  };
  const onMultiVisib = () => {
    setMultipleAddonsToShow(
      addonsList
        ?.filter((addon) => addon?.addonType === "pli")
        ?.map((el) => {
          let tempName = el?.values?.find(
            (val) => val?.name?.toLowerCase() === "name"
          )?.value;
          console.log("tempName", { tempName, el });
          return {
            // ...el,
            label: tempName,
            value: tempName,
          };
        })
    );
    setMultipleVisible(true);
  };

  const onAddonSaveMultiple = (addonsNames) => {
    let toAdd = [];
    addonsList
      ?.filter((addon) => addon?.addonType === "pli")
      ?.forEach((el) => {
        let tempName = el?.values?.find(
          (val) => val?.name?.toLowerCase() === "name"
        )?.value;
        console.log("tempName", { tempName, el });
        if (addonsNames.includes(tempName)) {
          toAdd.push({ ...el, name: tempName });
        }
      });
    let tempGridData = gridData;
    let { serviceIndex, optionIndex, elevationIndex } = indexes;
    let floors =
      tempGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .items;
    let currentFloor = floors.find((floor) => floor.id === parentData.id) || {};
    let currentAddon = currentFloor.addons?.find(
      (e) => e.id === params.data.id
    );
    if (Array.isArray(toAdd)) {
      toAdd.forEach((addon, idx) => {
        let vals = addon?.values || [];
        if (Array.isArray(vals)) {
          if (idx === 0) {
            vals.forEach((val) => {
              if (val?.name?.toLowerCase() === "name") {
                currentAddon.name = addon;
                currentAddon.addonname = val?.value;
              } else currentAddon[val?.name?.toLowerCase()] = val?.value;
            });
          } else {
            let temp = {
              id: params.data.id + idx,
            };

            vals.forEach((val) => {
              if (val?.name?.toLowerCase() === "name") {
                temp.name = addon;
                temp.addonname = val?.value;
              } else temp[val?.name?.toLowerCase()] = val?.value;
            });
            currentFloor.addons.push(temp);
          }
        }
      });
    }

    parentAPI.applyTransaction({ update: [currentFloor] });
    console.log("onAddonSaveMultiple", {
      toAdd,
      currentFloor,
      parentAPI,
      parentData,
    });
  };
  console.log("currentParams", params);

  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
      }}
    >
      <Popover
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
            }}
          >
            {addonsToShow?.map((addon) => {
              return (
                <Button
                  onClick={() => {
                    attachAddon(addon);
                  }}
                >
                  {addon?.name}
                </Button>
              );
            })}
          </div>
        }
        title="Pick Addon"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        {" "}
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <MondayButton hasIcon={false} onClick={() => handleOpenChange(true)}>
            Pick One
          </MondayButton>
        </div>{" "}
      </Popover>{" "}
      <MondayButton
        hasIcon={false}
        className="mondayButtonYellow"
        onClick={onMultiVisib}
      >
        Pick Multiple
      </MondayButton>
      {multipleVisible && (
        <MultipleAddonsModal
          addonsList={multipleAddonsToshow}
          setMultipleVisible={setMultipleVisible}
          onAddonSave={onAddonSaveMultiple}
        />
      )}
    </div>
  );
}

export default HoistFloorAddon;

const MultipleAddonsModal = ({
  addonsList = [],
  setMultipleVisible,
  multipleVisible,
  onAddonSave,
}) => {
  const [selectedAddons, setSelectedAddons] = useState([]);
  return (
    <>
      <NormalSizedModal
        open={true}
        setVisible={setMultipleVisible}
        maskClosable={true}
        title={"Addons Multi Selection"}
        centered={true}
        customFooter={
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <MondayButton
              className="mondayButtonRed"
              Icon={<CloseIconModal />}
              onClick={() => setMultipleVisible(false)}
            >
              Cancel
            </MondayButton>
            <MondayButton
              className="mondayButtonBlue"
              Icon={<PlusIcon />}
              onClick={() => onAddonSave(selectedAddons)}
            >
              Add Addons
            </MondayButton>
          </div>
        }
      >
        <div
          style={{
            height: "100px",
          }}
        >
          <Select
            mode="tags"
            style={{ width: "100%", minWidth: "300px" }}
            placeholder="Choose multiple addons for this floor"
            onChange={(params) => {
              setSelectedAddons(params);
            }}
            options={addonsList}
          />
          <br />
        </div>
      </NormalSizedModal>
    </>
  );
};
