import { Input } from "antd";

const UserSearch = ({ usersFilterValue, setUsersFilterValue }) => {
  return (
    <Input
      placeholder="Search User..."
      value={usersFilterValue}
      onChange={(e) => setUsersFilterValue(e.target.value)}
    />
  );
};

export default UserSearch;
