import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Badge, Tooltip } from "antd";
import InspectionModal from "../../../../../components/AgGridComponents/InspectionModal";
import { TasksWhite } from "../../../../../../../icons";
import TasksBasePage from "../../../../../TaskManager/Tasks/TasksBasePage";

export const Tasks = ({ darkMode }) => {
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);
  const { application } = useSelector((state) => state.applications);
  const [visible, setVisible] = useState(false);

  const title = "Tasks";
  const filteredTasks = loggedOnTasks?.filter(
    (task) => task.taskTopic === "Applications"
  );
  const thisApplicationTasks =
    filteredTasks.filter(
      (task) => task.taskTopicId === application.applicationId
    ) || [];
  const handleModalVisibility = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <>
      <Badge count={thisApplicationTasks.length}>
        <Tooltip title="Tasks">
          <Button
            onClick={handleModalVisibility}
            className={
              darkMode ? "applic-task-btn-dark" : "applic-task-btn-white"
            }
            icon={
              <span style={{ fill: "#fff" }}>
                <TasksWhite />
              </span>
            }
          />
        </Tooltip>
      </Badge>

      <InspectionModal
        {...{
          visible,
          onCancel: () => handleModalVisibility(),
          title,
          width: 1600,
          footer: false,
          closable: true,
          className: darkMode
            ? "application-tasks-modal-dark"
            : "application-tasks-modal-light",
        }}
      >
        <TasksBasePage
          {...{
            customTaskProps: {
              rowData: application,
              taskTopic: "Applications",
              taskTopicId: application.applicationId,
              taskRelatedTo: application.projectName,
            },
          }}
        />
      </InspectionModal>
    </>
  );
};
