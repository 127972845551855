export const updateDocumentTitle = (props) => {
  const { newTitle, newSubTitle, notificationsCount } = props || {};

  const updatedTitle =
    typeof newTitle === "string"
      ? newTitle
      : typeof newTitle?.props?.title === "string"
      ? newTitle.props.title
      : "";

  const baseTitle = notificationsCount >= 0 ? `(${notificationsCount}) - ` : "";
  const subtitle = !!newSubTitle
    ? `${newSubTitle} ${updatedTitle ? " | " : ""} `
    : "";

  const docTitle = `${baseTitle}${subtitle}${updatedTitle}`;

  if (docTitle) {
    document.title = docTitle + " | Lead Manager";
  } else {
    document.title = "Lead Manager"; // Set default title if empty
  }
};
