import React, { useState, useEffect } from "react";
import "../styles/HeaderStatistics.scss";
import ProposalCoreHeader from "../HeaderStatistics/resources/ProposalCoreHeader.png";
import { CoreLogo } from "../../components/ProposalPages/FirstProposalPage/assets";
import { useNavigate } from "react-router-dom";

function HeaderStatistics() {
  const navigate = useNavigate();
  return (
    <div className="header-statistics-wrapper">
      <img
        alt="Proposal Background Image"
        className="header-statistics_backgroundImage"
        src={ProposalCoreHeader}
      />
      <div className="header-statistics_content">
        <div className="header-statistics_content__left">
          {" "}
          <h3 className="header-statistics_title">PROPOSAL BUILDER</h3>
        </div>{" "}
        <div className="header-statistics_content__right">
          <div
            className="coreLogoBlackHolder"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <CoreLogo />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderStatistics;
