import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate, useLocation } from "react-router";
import Auth from "@aws-amplify/auth";
import Login from "../containers/Login/Login";
import Signup from "../containers/Signup/SignupComp";
import ForgotPassword from "../containers/ForgotPassword/ForgotPassword";
import { LoadableComp } from "./SidebarPages/XComponents";
import LayoutConfig from "./LayoutConfig";
import AppNotificationsConfig from "./AppNotificationsConfig/AppNotificationsConfig";
import InvitationSignUp from "../containers/InvitationSignUp/InvitationSignUp";
import FieldUsersLayout from "./pages/ScheduleProgress/FieldUsersLayout";
import { useAuth } from "../hooks/useAuth";
import InternalChat from "./InternalChat/InternalChat";
import { VirtualAssistant } from "./commonComponents";

const RouterConfig = ({ childProps }) => {
  const [routesElements, setRoutesElements] = useState({});
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences: pref } = useSelector((state) => state.preferences);
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated && userConfiguration) {
      if (userConfiguration?.isForceToResetPassword) {
        navigate("/resetPassword");
      }
      setRoutesElements(
        userConfiguration.routeConfig?.reduce(
          (prev, curr) => ({ ...prev, [curr.path]: curr }),
          {}
        )
      );
    }
  }, [isAuthenticated, userConfiguration]);

  return (
    <LoadableComp loading={isAuthenticated === undefined} className="fullWidth">
      {isAuthenticated ? (
        <>
          {userConfiguration && pref && (
            <LoadableComp loading={!!!childProps?.authenticatedUser}>
              <LoadableComp
                loading={!!!isAuthenticated && !!!userConfiguration && !!!pref}
              >
                <>
                  {userConfiguration?.groupName === "Foreman" ? (
                    <FieldUsersLayout props={childProps} />
                  ) : window.location.pathname === "/internalChat" ? (
                    <InternalChat />
                  ) : (
                    <>
                      <LayoutConfig
                        props={childProps}
                        pref={pref}
                        routesElements={routesElements}
                      />
                      <AppNotificationsConfig />
                      {userConfiguration?.virtualAssistant?.enabled && (
                        <VirtualAssistant />
                      )}
                    </>
                  )}
                </>
              </LoadableComp>
            </LoadableComp>
          )}
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/forgotpassword"
            element={<ForgotPassword props={childProps} />}
          />
          <Route path="/register" element={<Signup />} />
          <Route path="/invitation/:code" element={<InvitationSignUp />} />
        </Routes>
      )}
    </LoadableComp>
  );
};

export default RouterConfig;
