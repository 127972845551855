import AccountingConfirmationModal from "../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import AccountingWriteMode from "../../../../../../../../../commonComponents/AccountingWriteMode/AccountingWriteMode";
import DarkModeToggle from "../../../../../../../../../commonComponents/DarkModeToggle/DarkModeToggle";
import { getMonthOfTimestamp } from "../../../../../../../../utils/getMonthOfTimestamp";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../Context/Context";
import { applicationExtractor } from "../../../../applicationViewFunctions";
import { Close } from "../../../../../../../../DynamicView/src";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./MainHeader.scss";
import {
  exitApplicationViewHandler,
  discardChangesHandler,
  saveChangesHandler,
  darkModeHandler,
} from "./mainHeaderFunctions";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  useBeforeUnload,
  useEditLogs,
} from "../../../../../../../../../../hooks";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip, Tour } from "antd";
import {
  PlayVideoTutorial,
  findTutorialSteps,
} from "../../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { updateDocumentTitle } from "../../../../../../../../../../utils";

const MainHeader = ({ closeModal }) => {
  const { application, applications, isLoading } = useSelector(
    (state) => state.applications
  );
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const { isWritable, darkMode, fakeApplication, appId, isNextStep } =
    useStateContext();
  const dispatchContext = useDispatchContext();
  const { applicationId: currentApplicationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const applicationId = currentApplicationId || appId;

  const { programFields } = useSelector((state) => state.programFields);

  const [tourOpen, setTourOpen] = useState(false);
  const [videoTutorialLink, setVideoTutorialLink] = useState(null);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const links = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Project Management")
    ?.subCategories[5]?.videos.filter((item, i) => i >= 5 && i <= 8)
    .map((item) => item);

  const projectName = fakeApplication?.projectName;
  const applicationNo = fakeApplication?.applicationNo;
  const month = getMonthOfTimestamp(fakeApplication?.periodTo?.start);

  const { saveAddedLogs } = useEditLogs();

  useEffect(() => {
    updateDocumentTitle({
      newTitle: `${projectName} - Requisition ${applicationNo} - ${month}`,
    }); // set Title of Tab

    return () => {
      updateDocumentTitle(); // reset document title to "Lead Manager";
    };
  }, [projectName, applicationNo, month]);

  //remove item in LocalStorage when tab is closed or page is refreshed
  useBeforeUnload(applicationId);

  const darkModeSwitcher = () => darkModeHandler({ darkMode, dispatchContext });

  //is called when the user pres x button in the right top corner
  const exitApplicationView = () => {
    exitApplicationViewHandler({
      setConfirmModalVisibility,
      fakeApplication,
      application,
      dispatch,
      navigate,
      location,
      applications,
      isNextStep,
      closeModal,
    });
  };
  //is called when user pse discard button on confirmation modal
  const discardChanged = () => {
    discardChangesHandler({
      fakeApplication,
      navigate,
      isNextStep,
      closeModal,
    });
  };

  //is called when user pres save changes on confirmation modal
  const saveCHanges = () => {
    saveChangesHandler({
      fakeApplication,
      applicationId,
      dispatch,
      navigate,
      applications,
      isNextStep,
      closeModal,
      saveAddedLogs,
    });
  };

  //handles write mode change
  const onSwitchFunction = (value) => {
    //this saves the write mode on context
    dispatchContext({ type: "SET_WRITABLE", payload: value });
  };

  const nextApplication = applicationExtractor({
    application,
    applications,
    addedIndex: 1,
  });

  function stepsMapHelper(title) {
    const stepsMap = {
      Logs: document.querySelector(".logsButtonContainer"),
      "Undo, Redo": document.querySelector(".undoRedoButtonsContainer"),
      "Previous and Next Application":
        document.querySelector("#prevNextContainer"),
      "Include  Rentals": document.querySelector("#includeRentalsButton"),
      "Application Status": document.querySelector(".exportTo"),
      "Auto Save": document.querySelector(".autoSaveToggle"),
      "Application Tasks": document.querySelector(".applic-task-btn-dark"),
      "Application Notes": document.querySelector(".notes-icon"),
      "Export to Excel": document.querySelector(".exportToButton"),
      "Quick Save": document.querySelector("#quickSaveApplicationHeader"),
    };
    return stepsMap[title] || null;
  }
  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: darkMode ? "custom-tour-dark" : "custom-tour-light",
      };
    });
    return newSteps;
  }

  const tourSteps = [
    {
      title: "Requisition Tutorials",
      description: (
        <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
          {links.map((link, i) => {
            const name = link?.videoName?.replace(/\d+/, "");
            return (
              <MondayButton
                {...{
                  key: i,
                  className: "mondayButtonBlue guideButton",
                  onClick: () => {
                    setShowVideoTutorial(true);
                    setTourOpen(false);
                    setVideoTutorialLink(link.videoLink);
                  },
                  style: {
                    display: "flex",
                    justifyContent: " center",
                  },
                  Icon: <VideoTutorialIcon />,
                }}
              >
                {name}
                {i + 1}
              </MondayButton>
            );
          })}
        </div>
      ),
      className: darkMode ? "custom-tour-dark" : "custom-tour-light",
      placement: "center",
    },
    ...mapRefs(findTutorialSteps("requisitionView", programFields)),
  ];

  return (
    <div
      className={
        darkMode
          ? "application-main-header-dark"
          : "application-main-header-light"
      }
    >
      <div className="application-main-header-options">
        <h3 className="applicationMainHeaderTitleDiv">
          {`${projectName} - Requisition ${applicationNo} - ${month}`}
        </h3>

        <AccountingWriteMode
          {...{
            isWritable,
            recordId: applicationId,
            disableSwitch: !!nextApplication,
            disabilityReason: !!nextApplication
              ? "You cant edit this application! Next application is already created."
              : "",
            onSwitchFunction,
            darkMode,
            projectId: fakeApplication?.projectId,
          }}
        />

        <div className="lightDarkModeToggleContainer">
          <DarkModeToggle
            {...{
              onClickFunction: darkModeSwitcher,
              darkMode,
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Guide">
          <QuestionCircleFilled
            style={{ fontSize: 23, color: "#ffffff", cursor: "pointer" }}
            onClick={() => setTourOpen(true)}
          />
        </Tooltip>
        {fakeApplication?.projectId && !isLoading && (
          <div className="close-btn" onClick={exitApplicationView}>
            <Close style={{ height: 18, width: 18 }} />
          </div>
        )}
      </div>

      <AccountingConfirmationModal
        {...{
          setVisible: setConfirmModalVisibility,
          visible: confirmModalVisibility,
          onDiscard: discardChanged,
          onConfirm: saveCHanges,
          darkMode,
          text: "You have unsaved changes. Do you want to save them?",
        }}
      />
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Requisition Tutorial",
          }}
        />
      )}
    </div>
  );
};

export default MainHeader;
