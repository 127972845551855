//in this function we change the status of the rental and than save it
export const changeStatusHandler = ({
  setUnchangingFakeRentals,
  setFakeRentals,
  fakeRentals,
  value,
}) => {
  // if the new value is the same as the old value this functions does nothing
  if (fakeRentals?.rentalStatus === value) {
    return;
  }
  //else it creates the new rental and save it
  else {
    //here we take the old rental and updates the status key
    const newRental = {
      ...fakeRentals,
      rentalStatus: value,
    };

    //here we save the newly created rental
    setFakeRentals(newRental);
    setUnchangingFakeRentals(newRental);
  }
};

//here we extract all the values that we want to appear to the status from redux
export const optionsExtractor = ({ programFields }) =>
  programFields
    ?.find(({ fieldName }) => fieldName === "Statuses Of Categories")
    ?.fieldOptions["Rentals"]?.filter(({ status }) => status)
    .map(({ statusName }) => ({ value: statusName, label: statusName }));
