import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  AutoComplete,
  Col,
  Flex,
  Row,
  Tooltip,
  Space,
  Divider,
  Tour,
  message,
} from "antd";
import {
  SearchOutlined,
  StarOutlined,
  StarFilled,
  CloseCircleOutlined,
  ClearOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import useOnclickOutside from "react-cool-onclickoutside";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Text from "src/components/commonComponents/Base/Text";
import { MondayButton } from "src/components/commonComponents";
import IconButton from "src/components/commonComponents/Buttons/IconButton";
import { ConfirmationModal } from "src/components/Header/components/Notifications/ConfirmationModal";
import NewFleetInventory from "src/components/SidebarPages/FleetsMaintenances/Tabs/FleetInventory/Modals/NewFleetInventory";
import NewVendorModal from "src/components/SidebarPages/Vendors/components/NewVendorModal/NewVendorModal";
import NewBillModal from "src/components/SidebarPages/Vendors/tabs/NewBillModal";

import { ShortCuts } from "./ShortCuts";
import { initialShortCut } from "./initialShortCutObject";
import { shortcuts, categories } from "./newShortcutContentData";
import NewAccountingShortcut from "./modals/Accounting/NewAccountingShortcut";
import NewInspectionShortcut from "./modals/Inspections/NewInspectionShortcut";
import {
  ClientModal,
  ContactModal,
  EstimationModal,
  LeadModal,
  OpportunityModal,
  PermitDrawingModal,
  ProjectModal,
  PreClaimsModal,
  PreHearingsModal,
} from "../../../Header/forms";
import PreTodoModal from "../../../SidebarPages/ToDos/forms/PreTodoModal";
import LabeledShortcutIcon from "../LabeledShortcutIcon/LabeledShortcutIcon";
import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";
import SafetyModal from "../../../SidebarPages/Safety/SafetyModal/SafetyModal";
import NewNoteModal from "../../../SidebarPages/Notes/NoteModals/NewNoteModal";
import { NewFleetModal } from "../../../SidebarPages/Fleet/fleet/modals/index";
import { NewDispatchModal } from "../../../SidebarPages/Fleet/Dispatch/modals";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { NewDriverModal } from "../../../SidebarPages/Fleet/driver/modals/index";
import IncidentModal from "../../../SidebarPages/Incidents/Modals/IncidentModal";
import NewSchedule from "../../../Header/forms/Scheduling/NewSchedule/NewSchedule";
import { preferences as preferencesDispatch } from "../../../../actions/preferences";
import { NewViolationModal } from "../../../SidebarPages/Fleet/violations/modals/index";
import PreSafetyModal from "../../../SidebarPages/Safety/SafetyModal/components/PreSafetyModal";
import { REQUEST_FORM_TYPES } from "../../../Header/forms/RequestFormModal/helpers";
import NewTaskModal from "../../../SidebarPages/TaskManager/Tasks/TasksModals/NewTask/NewTaskModal";
import NewSafetyInspection from "../../../SidebarPages/Safety/Inspections/Modal/NewSafetyInspection";
import PreSafetyInspectionModal from "../../../SidebarPages/Safety/Inspections/Modal/PreSafetyInspectionModal";
import { CrewsHeader } from "../../../pages/Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";
import PreIncidentsModal from "../../../SidebarPages/Projects/Tabs/Safety/components/IncidentsTab/PreIncidentModal";
import { EditActivityModal } from "../../../SidebarPages/Fleet/view/pageView/components/SingleTruckActivity/modals/index";
import { MaintenanceModal } from "../../../SidebarPages/Fleet/view/pageView/components/SingleTruckMaintenance/modals/index";
import NewDocumentationModal from "../../../SidebarPages/Documentation/View/components/NewDocumentationModal/NewDocumentationModal";
import NewGeofenceShortcut from "../../../pages/Settings/settingsComponents/GeofenceConfigurations/shortcutFenceCreation/NewGeofenceShortcut";
import { useModalOnceObject } from "../../../../hooks/useModalOnce";
import { useMultipleRefs } from "../../../../hooks/useMultipleRefs";
import { findTutorialSteps } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import NewRequest from "../../../Header/forms/RequestFormModal/NewRequest";
import DegModal from "../../../pages/Payroll/Tabs/DEG/components/DegModal";
import JobsiteModal from "../../../pages/Payroll/Tabs/Jobsite/components/JobsiteModal";
import { fetchData, lazyFetch } from "../../../../utils";
import PayrollContext from "../../../pages/Payroll/PayrollContext";

import "./NewShortcutContent.scss";
import NewWorkOrderModal from "../../../SidebarPages/FleetsMaintenances/Tabs/WorkOrder/Modals/NewWorkOrderModal";
import { ScheduleMaintenanceModal } from "../../../RouteLazyComponents";
import ComposeNewEmailShortcut from "./components/communication/ComposeNewEmailShortcut";

const NewShortcutContent = (props) => {
  const dispatch = useDispatch();

  const { setVisible: setVisibleModal, isDarkMode } = props;

  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const {
    preferences,
    preferences: {
      ShortcutButtonsData,
      ShortcutButtonsData: {
        sortedCategories,
        sortedSubCategories,
        favourites,
      } = {},
    } = {},
  } = allPreferences;

  const driveFunctions = useCreateDriveFolders("Scheduling");

  const [searchInput, setSearchInput] = useState("");

  const [newModal, setNewModal] = useState("");
  const [selectedAccounting, setSelectedAccounting] = useState(false);
  const [lazyFetchScheduling, setLazyFetchScheduling] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [degPayrolls, setDegPayrolls] = useState([]);
  const [dynamicFormObject, setDynamicFormObject] = useState({
    formType: "",
    category: "",
    data: null,
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [actionHappend, setActionHappend] = useState(false);
  const [showFavourites, setShowFavourites] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [clearPrefs, setClearPrefs] = useState(false);
  const [fleets, setFleets] = useState([]);
  const [serviceMaintenance, setServiceMaintenance] = useState([]);

  const [tourOpen, setTourOpen] = useModalOnceObject("Tour");
  const [
    favoriteRef,
    clearRef,
    searchRef,
    safetyRepRef,
    safetyInspRef,
    incidentRef,
    projRef,
    docRef,
    scheduleRef,
    inspRef,
    perDrawRef,
    estRef,
    contactRef,
    clientRef,
    subconRef,
    leadRef,
    oppRef,
    fleetRef,
    fleetDispRef,
    fleetViolationRef,
    fleetDriverRef,
    fleetActivityRef,
    fleetMaintenRef,
    fleetInvenRef,
    taskRef,
    notesRef,
    claimsRef,
    hearingsRef,
    crewRef,
    geofenceRef,
  ] = useMultipleRefs(30);

  function stepsMapHelper(title) {
    if (title?.includes("Favorites")) {
      return favoriteRef.current;
    } else if (title?.includes("Clear")) {
      return clearRef.current;
    } else if (title?.includes("Search")) {
      return searchRef.current;
    }
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const dbSteps = mapRefs(
    findTutorialSteps("New Shortcut Content", programFields)
  );

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });

  const sortedSubCategoriesPrefs = !!sortedSubCategories
    ? Object.keys(initialShortCut.sortedSubCategories).reduce(
        (acc, catName) => {
          acc[catName] = [
            ...new Set([
              ...(sortedSubCategories?.[catName] || [])?.filter((category) =>
                initialShortCut?.sortedSubCategories?.[catName]?.includes?.(
                  category
                )
              ),
              ...initialShortCut.sortedSubCategories[catName],
            ]),
          ];
          return acc;
        },
        {}
      )
    : initialShortCut.sortedSubCategories;

  const sortedCategoriesPrefs = !!sortedCategories
    ? [
        ...new Set([
          ...sortedCategories?.filter((category) =>
            initialShortCut.sortedCategories.includes(category)
          ),
          ...initialShortCut.sortedCategories,
        ]),
      ]
    : initialShortCut.sortedCategories;

  const [shortcutButtons, setShortcutButtons] = useState(
    !!ShortcutButtonsData
      ? {
          sortedSubCategories: sortedSubCategoriesPrefs,
          sortedCategories: sortedCategoriesPrefs,
          favourites,
        }
      : initialShortCut
  );

  //type for Clients & Subcontractors
  const type =
    newModal === "Client"
      ? "Client"
      : newModal === "Subcontractor"
      ? "Subcontractors"
      : "";

  const setVisible = () => {
    setVisibleModal(false);
    setSearchInput("");
  };
  // ref wich removes popover when clicked outside
  const ref = useOnclickOutside(
    () => {
      !actionHappend && !clearPrefs && setVisible(false);
      actionHappend && !clearPrefs && setOpenConfirmationModal(true);
    },
    { ignoreClass: ["add-button", "ant-popover-content"] }
  );

  // modified shortcuts with onclick buttons
  const modifiedShortcuts = shortcuts({ setNewModal, setSelectedAccounting });

  const handleRequestFormVisible = () => {
    switch (newModal) {
      case "Estimation Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Estimation Request",
          type: REQUEST_FORM_TYPES?.ESTIMATION,
        };
      }
      case "Scheduling Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Scheduling Request",
          type: REQUEST_FORM_TYPES?.SCHEDULING,
        };
      }
      case "Dispatch Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Dispatch Request",
          type: REQUEST_FORM_TYPES?.DISPATCH,
        };
      }
      case "Permit Drawing Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Permit Drawing Request",
          type: REQUEST_FORM_TYPES?.PERMIT_DRAWING,
        };
      }
      case "Schedule Change Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Schedule Change Request",
          type: REQUEST_FORM_TYPES?.SCHEDULE_CHANGE,
        };
      }
      case "Safety Inspection Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Safety Inspection Request",
          type: REQUEST_FORM_TYPES?.SAFETY_INSPECTION,
        };
      }
      case "Project Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Project Request",
          type: REQUEST_FORM_TYPES?.PROJECT,
        };
      }
      case "Claim Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Claim Request",
          type: REQUEST_FORM_TYPES?.CLAIM,
        };
      }
      case "Incident Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Incident Request",
          type: REQUEST_FORM_TYPES?.INCIDENT,
        };
      }

      case "Service Inspection Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Service Inspection Request",
          type: REQUEST_FORM_TYPES?.SERVICE_INSPECTION,
        };
      }

      case "Documentation Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Documentation Request",
          type: REQUEST_FORM_TYPES?.DOCUMENTATION,
        };
      }

      case "Change Order Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Change Order Request",
          type: REQUEST_FORM_TYPES?.CHANGE_ORDER,
        };
      }

      case "Opportunity Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Opportunity Request",
          type: REQUEST_FORM_TYPES?.OPPORTUNITY,
        };
      }

      case "Work Order Request": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Work Order Request",
          type: REQUEST_FORM_TYPES?.WORK_ORDER,
        };
      }

      case "Fleet Inventory": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Fleet Inventory Request",
          type: REQUEST_FORM_TYPES?.FLEET_INVENTORY,
        };
      }

      case "Work Orders": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Work Orders",
          type: REQUEST_FORM_TYPES.WORK_ORDER,
        };
      }

      case "Schedule Maintenance": {
        return {
          setVisible: () => setNewModal(""),
          visible: newModal === "Schedule Maintenance",
          type: REQUEST_FORM_TYPES.SCHEDULE_MAINTENANCE,
        };
      }
    }
  };

  const requestData = handleRequestFormVisible();
  // const handleRequestFormType = () => {}

  const handleCancelChanges = () => {
    setShortcutButtons(
      !!ShortcutButtonsData
        ? {
            sortedSubCategories: sortedSubCategoriesPrefs,
            sortedCategories: sortedCategoriesPrefs,
            favourites,
          }
        : initialShortCut
    );
    setActionHappend(false);
  };

  const dragEndHandler = (result) => {
    if (!result.destination) {
      setActionHappend(false);
      return;
    }
    setActionHappend(true);
    const items = Array.from(shortcutButtons.sortedCategories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setShortcutButtons((prev) => ({ ...prev, sortedCategories: items }));
  };

  const handleFavouriteClick = ({ category, subCategory }) => {
    const currentFavourites = shortcutButtons?.favourites?.[category] || [];
    const existingItem = currentFavourites.includes(subCategory);

    setShortcutButtons((prev) => ({
      ...prev,
      favourites: {
        ...(prev.favourites || {}),
        [category]: existingItem
          ? currentFavourites.filter((subCat) => subCat !== subCategory)
          : [...currentFavourites, subCategory],
      },
    }));

    // message.success(
    //   `Successfully ${existingItem ? "removed as" : "marked as"} favourite`
    // );

    setActionHappend(true);
  };

  useEffect(() => {
    if (newModal === "Scheduling" && !lazyFetchScheduling?.length) {
      fetchAllData("scheduling", "scheduling", "scheduleId").then(
        setLazyFetchScheduling
      );
    }
  }, [newModal, lazyFetchScheduling]);

  const onSaveSorting = () => {
    const updatedPreferences = {
      ...preferences,
      ShortcutButtonsData: shortcutButtons,
    };

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        message.success("Changes Saved In Your Preferences!");
        setActionHappend(false);
        dispatch(
          preferencesDispatch({
            ...allPreferences,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => console.error("Error saving to preferences", err));
  };

  const handleClearOrderPreference = () => {
    if (!preferences?.ShortcutButtonsData) {
      message.info("There is no preference saved before...");
      return;
    }

    setClearPrefs(true);
    setOpenConfirmModal(true);
  };

  const handleOnConfirmModal = () => {
    const { ShortcutButtonsData, ...rest } = preferences;

    const updatedPreferences = rest;

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        setShortcutButtons(initialShortCut);
        setClearPrefs(false);
        message.destroy();
        message.success("Changes Saved In Your Preferences!");
        dispatch(
          preferencesDispatch({
            ...allPreferences,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => console.error("Error saving to preferences", err));
  };

  useEffect(() => {
    const projectCostAccess = userConfiguration?.routeConfig?.find(
      ({ title }) => title === "Project Cost"
    );

    const scheduleMaintenanceAccess = userConfiguration.routeConfig.find(
      (route) => route.title === "Schedule Maintenance"
    );

    if (projectCostAccess?.write) {
      Promise.allSettled([
        lazyFetch({
          tableName: "projects",
          listOfKeys: [
            "projectId",
            "accountId",
            "accountName",
            "geoFenceInfo",
            "laborRequirement",
            "projectAddress",
            "projectLatitude",
            "projectLongitude",
          ],
        }),
        API.get("deg", "/deg"),
      ]).then(([{ value: projectsRes }, { value: payrollsRes }]) => {
        setAllProjects(projectsRes);
        setDegPayrolls(payrollsRes);
      });
    }

    if (scheduleMaintenanceAccess?.write) {
      fetchData("serviceMaintenance").then((res) => {
        setServiceMaintenance(res);
      });
      fetchData("fleet").then((res) => {
        setFleets(res);
      });
    }
  }, [userConfiguration]);

  return (
    <>
      <Row
        ref={ref}
        className={`shortcutWrapper ${isDarkMode && "shortcutWrapperDark"}`}
      >
        <Col span={24} style={{ marginTop: 10 }}>
          <Flex
            gap={20}
            vertical={!isDesktop}
            justify="space-between"
            className="shortcutHeader"
          >
            <Flex gap={4}>
              <Text strong whiteText>
                Hi, here you can find all of our forms and be able to access
                them quickly.
              </Text>
              {(isMobile || isTablet) && (
                <IconButton
                  shape="circle"
                  onClick={() => setVisibleModal(false)}
                  icon={
                    <CloseCircleOutlined
                      style={{ fontSize: 24, color: "#fff" }}
                    />
                  }
                />
              )}
            </Flex>
            <Flex gap={8} vertical={isMobile}>
              <Space size="small">
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<QuestionCircleFilled />}
                  onClick={() => {
                    setTourOpen(true);
                  }}
                >
                  Guide
                </MondayButton>
                <div ref={favoriteRef}>
                  <Tooltip
                    placement="bottom"
                    title={
                      showFavourites ? "Hide Favourites" : "Show Favourites"
                    }
                  >
                    <IconButton
                      shape="circle"
                      icon={
                        showFavourites ? (
                          <StarFilled style={{ fontSize: 22, color: "#fff" }} />
                        ) : (
                          <StarOutlined
                            style={{ fontSize: 22, color: "#fff" }}
                          />
                        )
                      }
                      onClick={() => setShowFavourites(!showFavourites)}
                    />
                  </Tooltip>
                </div>
                <div ref={clearRef}>
                  <Tooltip title="Clear order preference">
                    <MondayButton
                      data-testid="clear-preference"
                      className="mondayButtonBlue"
                      Icon={<ClearOutlined />}
                      onClick={handleClearOrderPreference}
                    />
                  </Tooltip>
                </div>
                <div ref={searchRef}>
                  <div className="search-Div">
                    <AutoComplete
                      value={searchInput}
                      className="search-comp"
                      placeholder="Search..."
                      onChange={(e) => setSearchInput(e)}
                    />
                    <SearchOutlined className="SearchLogoIcon" />
                  </div>
                </div>
              </Space>
              {actionHappend && (
                <Space size="small">
                  <MondayButton
                    className="mondayButtonRed"
                    hasIcon={false}
                    onClick={handleCancelChanges}
                  >
                    Cancel Changes
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonGreen"
                    onClick={() => setOpenConfirmationModal(true)}
                    Icon={<TickIcon width={18} height={18} />}
                    disabled={!actionHappend}
                  >
                    Save
                  </MondayButton>
                </Space>
              )}
            </Flex>
          </Flex>
        </Col>

        <Col span={24}>
          <Divider style={{ background: "#4d4d4d" }} />
        </Col>

        <Col span={24}>
          <div className="shortcutContent">
            <DragDropContext onDragEnd={dragEndHandler}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided?.droppableProps}
                    ref={provided?.innerRef}
                    className="categories"
                  >
                    {shortcutButtons.sortedCategories?.map((category, key) => {
                      const findedCategory = categories.find(
                        ({ title }) => title === category
                      );

                      return (
                        <Draggable
                          draggableId={`droppable-${key}`}
                          index={key}
                          key={key}
                        >
                          {(provided) => (
                            <div
                              className="category"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div className="categoryName">
                                <LabeledShortcutIcon
                                  {...{
                                    ...findedCategory,
                                    key,
                                    style: { width: "100%" },
                                  }}
                                />
                              </div>
                              <ShortCuts
                                {...{
                                  setVisible,
                                  searchInput,
                                  shortcutButtons,
                                  setShortcutButtons,
                                  category,
                                  modifiedShortcuts,
                                  setActionHappend,
                                  showFavourites,
                                  favourites:
                                    shortcutButtons.favourites?.[category],
                                  // onFavouriteClick: (shortcut) => {
                                  //   shortcutButtons?.Favourites &&
                                  //   shortcutButtons?.Favourites[category],
                                  onFavouriteClick: (shortcut) =>
                                    handleFavouriteClick({
                                      category: findedCategory.title,
                                      subCategory: shortcut,
                                    }),
                                  // },
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>

        {/*============================== SALES ==============================*/}
        <div ref={leadRef}>
          {newModal === "Lead" && (
            <LeadModal
              {...{
                ...props,
                visible: newModal === "Lead",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={oppRef}>
          {newModal === "Opportunity" && (
            <OpportunityModal
              {...{
                ...props,
                visible: newModal === "Opportunity",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={estRef}>
          {newModal === "Estimation" && (
            <EstimationModal
              {...{
                ...props,
                visible: newModal === "Estimation",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={contactRef}>
          {newModal === "Contact" && (
            <ContactModal
              {...{
                ...props,
                visible: newModal === "Contact",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={clientRef || subconRef}>
          {(newModal === "Client" || newModal === "Subcontractor") && (
            <ClientModal
              {...{
                ...props,
                type,
                visible: newModal === "Client" || newModal === "Subcontractor",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        {/*============================== SALES ENDED ==============================*/}
        {/*============================== FLEETS ==============================*/}
        <div ref={fleetRef}>
          {newModal === "Fleet" && (
            <NewFleetModal
              visible={newModal === "Fleet"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        <div ref={fleetDispRef}>
          {newModal === "Fleet Dispatch" && (
            <NewDispatchModal
              visible={newModal === "Fleet Dispatch"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        <div ref={fleetViolationRef}>
          {newModal === "Fleet Violation" && (
            <NewViolationModal
              visible={newModal === "Fleet Violation"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        <div ref={fleetDriverRef}>
          {newModal === "Fleet Driver" && (
            <NewDriverModal
              visible={newModal === "Fleet Driver"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        <div ref={fleetMaintenRef}>
          {newModal === "Fleet Maintenance" && (
            <MaintenanceModal
              visible={newModal === "Fleet Maintenance"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        {/* {newModal === "Fleet Incident" && (
        <IncidentModal
          visible={newModal === "Fleet Incident"}
          setVisible={() => setNewModal("")}
        />
      )} */}
        <div ref={fleetActivityRef}>
          {newModal === "Fleet Activity" && (
            <EditActivityModal
              visible={newModal === "Fleet Activity"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        <div ref={fleetInvenRef}>
          {newModal === "Fleet Inventory" && (
            <NewFleetInventory
              visible={newModal === "Fleet Inventory"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        {newModal === "Work Orders" && (
          <NewWorkOrderModal
            visible={newModal === "Work Orders"}
            setVisible={() => setNewModal("")}
          />
        )}
        {newModal === "Schedule Maintenance" && (
          <ScheduleMaintenanceModal
            // visible={newModal === "Work Order"}
            setVisible={() => setNewModal("")}
            trucks={fleets}
            scheduleMaintenanceList={serviceMaintenance}
          />
        )}

        {/*============================== FLEETS ENDS HERE ============================== */}
        {/*============================== Accounting tabs ==============================*/}
        {selectedAccounting && (
          <NewAccountingShortcut
            selectedAccounting={selectedAccounting}
            setSelectedAccounting={setSelectedAccounting}
          />
        )}
        {/*============================== Accounting ENDED ==============================*/}
        {/*============================== Projects ==============================*/}
        <div ref={projRef}>
          {newModal === "Project" && (
            <ProjectModal
              {...{
                ...props,
                visible: newModal === "Project",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={scheduleRef}>
          {newModal === "Scheduling" && (
            <NewSchedule
              {...{
                ...props,
                scheduleFormOpen: lazyFetchScheduling,
                isLoading: newModal === "Scheduling",
                onChange: () => setNewModal(""),
                refreshTable: () => {},
                refreshView: () => {},
                driveFunctions,

                userConfiguration,
              }}
            />
          )}
        </div>
        <div ref={perDrawRef}>
          {newModal === "Permit Drawing" && (
            <PermitDrawingModal
              {...{
                ...props,
                visible: newModal === "Permit Drawing",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={inspRef}>
          {newModal === "Inspection" && (
            <NewInspectionShortcut
              visible={newModal === "Inspection"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        <div ref={docRef}>
          {newModal === "Documentation" && (
            <NewDocumentationModal
              visible={newModal === "Documentation"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        {/*============================== Projects ENDED ==============================*/}
        {/*============================== FLEETS ENDS HERE ============================== */}
        {/*============================== Accounting tabs ==============================*/}
        {selectedAccounting && (
          <NewAccountingShortcut
            selectedAccounting={selectedAccounting}
            setSelectedAccounting={setSelectedAccounting}
          />
        )}
        {/*============================== Accounting ENDED ==============================*/}

        {/*============================== Approvals Modals ==============================*/}
        {requestData?.visible && (
          <NewRequest
            {...{
              ...props,
              visible: requestData?.visible,
              setVisible: () => setNewModal(""),
              requestType: requestData?.type,
            }}
          />
        )}
        {/*============================== Approvals ENDED ==============================*/}

        {/*============================== Vendors Modals ==============================*/}
        {newModal === "Vendor" && (
          <NewVendorModal
            {...{
              ...props,
              visible: newModal === "Vendor",
              setVisible: () => setNewModal(""),
            }}
          />
        )}
        {newModal === "Bill" && (
          <NewBillModal {...{ onClose: () => setNewModal("") }} />
        )}
        {/*============================== Vendors ENDED ==============================*/}

        {/*============================== Others Modals ==============================*/}
        {newModal === "Task" && (
          <NewTaskModal
            {...{
              newTaskModal: newModal === "Task",
              setNewTaskModal: () => setNewModal(""),
            }}
          />
        )}
        {newModal === "Claims" && (
          <PreClaimsModal
            {...{
              ...props,
              visible: newModal === "Claims",
              setVisible: () => setNewModal(""),
            }}
          />
        )}
        {newModal === "Hearings" && (
          <PreHearingsModal
            {...{
              ...props,
              visible: newModal === "Hearings",
              setVisible: () => setNewModal(""),
            }}
          />
        )}
        {newModal === "Notes" && (
          <NewNoteModal
            visible={newModal === "Notes"}
            setVisible={() => setNewModal("")}
          />
        )}
        {newModal === "Crew" && (
          <CrewsHeader
            visible={newModal === "Crew"}
            setVisible={() => setNewModal("")}
          />
        )}
        {newModal === "Todo" && (
          <PreTodoModal
            {...{
              visible: newModal === "Todo",
              setVisible: () => setNewModal(""),
            }}
          />
        )}

        {/*============================== Approvals Modals ==============================*/}
        {/* {requestData?.visible && (
          <NewRequest
            {...{
              ...props,
              visible: requestData?.visible,
              setVisible: () => setNewModal(""),
              requestType: requestData?.type,
            }}
          />
        )} */}
        {/*============================== Approvals ENDED ==============================*/}

        {/*============================== Others Modals ==============================*/}
        <div ref={taskRef}>
          {newModal === "Task" && (
            <NewTaskModal
              {...{
                newTaskModal: newModal === "Task",
                setNewTaskModal: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={claimsRef}>
          {newModal === "Claims" && (
            <PreClaimsModal
              {...{
                ...props,
                visible: newModal === "Claims",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={hearingsRef}>
          {newModal === "Hearings" && (
            <PreHearingsModal
              {...{
                ...props,
                visible: newModal === "Hearings",
                setVisible: () => setNewModal(""),
              }}
            />
          )}
        </div>
        <div ref={notesRef}>
          {newModal === "Notes" && (
            <NewNoteModal
              visible={newModal === "Notes"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        <div ref={crewRef}>
          {newModal === "Crew" && (
            <CrewsHeader
              visible={newModal === "Crew"}
              setVisible={() => setNewModal("")}
            />
          )}
        </div>
        {newModal === "Todo" && (
          <PreTodoModal
            {...{
              visible: newModal === "Todo",
              setVisible: () => setNewModal(""),
            }}
          />
        )}
        <div ref={geofenceRef}>
          {newModal === "Geofence" && (
            <NewGeofenceShortcut
              open={newModal === "Geofence"}
              setOpen={setNewModal}
            />
          )}

          {newModal === "Communication" && (
            <ComposeNewEmailShortcut
              open={newModal === "Communication"}
              setOpen={setNewModal}
            />
          )}
        </div>

        {/*============================== ENDED Others Modals ==============================*/}
        {/*{/*============================== SAFETY ==============================*/}
        <div ref={incidentRef}>
          {newModal === "Incidents" && (
            <PreIncidentsModal
              {...{
                open: newModal === "Incidents",
                onCancel: () => setNewModal(""),
                onCategorySelected(cat) {
                  setNewModal("");
                  setDynamicFormObject({
                    formType: "Incidents Modal",
                    category: cat,
                  });
                },
              }}
            />
          )}
        </div>
        {dynamicFormObject?.formType === "Incidents Modal" && (
          <IncidentModal
            {...{
              category: dynamicFormObject?.category || "Vehicle Damage",
              setEdit: () => {
                setDynamicFormObject({
                  formType: "",
                  category: "",
                  data: "",
                });
              },
            }}
          />
        )}
        {newModal === "Safety Inspection" && (
          <PreSafetyInspectionModal
            {...{
              open: newModal === "Safety Inspection",
              onCancel: () => setNewModal(""),
              onSafetySelected(safety) {
                setNewModal("");
                setDynamicFormObject({
                  formType: "Safety Inspection Modal",
                  category: safety?.inspectionCategory,
                  data: safety,
                });
              },
            }}
          />
        )}
        <div ref={safetyInspRef}>
          {dynamicFormObject?.formType === "Safety Inspection Modal" && (
            <NewSafetyInspection
              {...{
                visible: true,
                setVisible: () =>
                  setDynamicFormObject({
                    formType: "",
                    category: "",
                    data: "",
                  }),
                inspectionCategory: dynamicFormObject?.category,
                safetyData: dynamicFormObject?.data,
              }}
            />
          )}
        </div>
        {newModal === "Safety Reports" && (
          <PreSafetyModal
            {...{
              open: newModal === "Safety Reports",
              onCancel: () => setNewModal(""),
              onIncidentSelected(incident) {
                setNewModal("");
                setDynamicFormObject({
                  formType: "Safety Modal",
                  category:
                    incident?.safetyApplicationCategory || "Vehicle Damage",
                  data: incident,
                });
              },
            }}
          />
        )}
        <div ref={safetyRepRef}>
          {dynamicFormObject?.formType === "Safety Modal" && (
            <SafetyModal
              {...{
                setEdit: () =>
                  setDynamicFormObject({
                    formType: "",
                    category: "",
                    data: "",
                  }),
                category: dynamicFormObject?.category,
                safetyData: dynamicFormObject?.data,
              }}
            />
          )}
        </div>
        {openConfirmationModal && (
          <ConfirmationModal
            setShowModal={(visible) => {
              setActionHappend(false);
              setOpenConfirmationModal(visible);
            }}
            onOK={onSaveSorting}
            title={"Save changes"}
            showModal={openConfirmationModal}
          />
        )}

        {openConfirmModal && (
          <ConfirmationModal
            setShowModal={(visible) => {
              setOpenConfirmModal(visible);
              setClearPrefs(false);
            }}
            onOK={handleOnConfirmModal}
            title={"clear order preference"}
            showModal={openConfirmModal}
          />
        )}

        {/* ============================== SAFETY END ==============================*/}

        {/* ============================== PROJECT COST ==============================*/}
        <PayrollContext.Provider value={{ degPayrolls, allProjects }}>
          {newModal === "DEG" && (
            <DegModal
              open={newModal === "DEG"}
              onCancel={() => setNewModal(false)}
              refreshTable={() => {}}
            />
          )}
          {newModal === "Jobsite" && (
            <JobsiteModal
              open={newModal === "Jobsite"}
              onCancel={() => setNewModal(false)}
            />
          )}
        </PayrollContext.Provider>

        {/* ============================== PROJECT COST ==============================*/}
      </Row>
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={dbSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
    </>
  );
};

export default NewShortcutContent;
