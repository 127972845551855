import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";
import { boroughs } from "../../../addWidgetsModalData";
import {
  getBoroughSum,
  repeatedElementSum,
} from "../../../NewDashboardFunctions";

export const incidents = ({ tables = [], programFields = [] }) => {
  const incidentStatuses = programFields?.find(
    (a) => a?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Incidents;

  console.log("incidentStatuses", incidentStatuses);
  console.log("tables", tables?.Incidents);
  console.log(
    incidentStatuses?.map(({ statusName }) =>
      tables?.Safety?.filter((e) => e?.incidentStatus === statusName)
    )?.length
  );
  return {
    driverName: () => ({
      labels: uniq(tables?.Incidents?.map((a) => a?.driverName)),
      data: uniq(tables?.Incidents?.map((a) => a?.driverName))?.map(
        (status) =>
          tables?.Incidents?.filter((a) => a?.driverName === status)?.length
      ),
      backgroundColor: uniq(tables?.Incidents?.map((a) => getRandomColor())),
    }),

    fleetName: () => ({
      labels: uniq(tables?.Incidents?.map((a) => a?.fleetName)),
      data: uniq(tables?.Incidents?.map((a) => a?.fleetName))?.map(
        (status) =>
          tables?.Incidents?.filter((a) => a?.fleetName === status)?.length
      ),
      backgroundColor: uniq(tables?.Incidents?.map((a) => getRandomColor())),
    }),

    incidentStatus: () => ({
      labels: incidentStatuses?.map(({ statusName }) => statusName),
      data: incidentStatuses?.map(
        ({ statusName }) =>
          tables?.Incidents?.filter((e) => e?.incidentStatus === statusName)
            ?.length
      ),
      backgroundColor: incidentStatuses?.map((e) => e.statusColor),
    }),

    incidentCategory: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Incidents, "incidentCategory")
      ),
      data: Object?.values(
        repeatedElementSum(tables?.Incidents, "incidentCategory")
      ),
      backgroundColor: uniq(tables?.Incidents?.map((a) => getRandomColor())),
    }),

    projectName: () => ({
      labels: Object.keys(
        repeatedElementSum(
          tables?.Incidents?.map((e) => e?.incidentObject),
          "projectName"
        )
      ),
      data: Object?.values(
        repeatedElementSum(
          tables?.Incidents?.map((e) => e?.incidentObject),
          "projectName"
        )
      ),
      backgroundColor: uniq(tables?.Incidents?.map((a) => getRandomColor())),
    }),
  };
};
