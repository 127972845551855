export const transformKeys = (obj) => {
  const transformed = {};

  for (let key in obj) {
    if (key !== "programFieldsRes") {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1) + "Data";

      transformed[newKey] = obj[key];
    }
  }

  return transformed;
};
