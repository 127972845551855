import { useSelector } from "react-redux";
import { Form, message } from "antd";

import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { loadLivePreference, saveLocalPreferences } from "../../../../utils";
import {
  parseInTz,
  setHourMinute,
} from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { FilterIcon } from "src/components/SidebarPages/BasePage/src";
import { ClearFilterIcon } from "src/components/SidebarPages/DynamicView/src";

import "./RangePopover.scss";

function RangePopover({ onRangeSelect = () => {} }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [form] = Form.useForm();

  function clearFilterHandler() {
    form.setFieldsValue({
      fromTime: null,
      toTime: null,
    });

    saveLocalPreferences({
      afterHoursFromTime: null,
      afterHoursToTime: null,
    });
    onRangeSelect([]);
  }

  function filterHandler() {
    const { fromTime, toTime, fromDate, toDate } = form.getFieldsValue();
    let start = 0,
      end = Infinity;
    if (fromTime) {
      start = setHourMinute(
        parseInTz(fromDate),
        loadLivePreference("afterHoursFromTime")
          ? fromTime
          : parseInTz(fromTime)
      ).valueOf();
    }

    if (toTime) {
      end = setHourMinute(
        parseInTz(toDate),
        loadLivePreference("afterHoursToTime") ? toTime : parseInTz(toTime)
      ).valueOf();
    }

    if (end < start) {
      form.setFieldsValue({
        fromTime: loadLivePreference("afterHoursFromTime")
          ? parseInTz(loadLivePreference("afterHoursFromTime"))
          : null,
        toTime: loadLivePreference("afterHoursToTime")
          ? parseInTz(loadLivePreference("afterHoursToTime"))
          : null,
        fromDate: parseInTz(loadLivePreference("liveSelectedDate")),
        toDate: parseInTz(loadLivePreference("liveSelectedDate")),
      });

      message.warning({
        content: "End time can not be smaller than start time",
        key: "smallerEnd",
      });

      return;
    }

    saveLocalPreferences({
      afterHoursFromTime: start,
      afterHoursToTime: end,
    });
    onRangeSelect([start, end]);
  }

  return (
    <Form
      form={form}
      className={`after-hours-picker ${
        isDarkMode ? "trips-picker-form-dark" : ""
      }`}
      initialValues={{
        fromDate: dayjsNY(loadLivePreference("liveSelectedDate")),
        toDate: dayjsNY(loadLivePreference("liveSelectedDate")),
      }}
    >
      <InputComponent
        {...{
          allowClear: false,
          type: "datePicker",
          label: "From Date",
          formItemName: "fromDate",
          className: "range-date-input",
        }}
      />
      <InputComponent
        {...{
          label: "To Date",
          allowClear: false,
          type: "datePicker",
          formItemName: "toDate",
          className: "range-date-input",
        }}
      />
      <div className="time-select-section">
        <InputComponent
          {...{
            required: false,
            allowClear: true,
            label: "From time",
            type: "timePicker",
            formItemName: "fromTime",
            dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
            defaultValue: loadLivePreference("afterHoursFromTime")
              ? parseInTz(loadLivePreference("afterHoursFromTime"))
              : null,
          }}
        />
        <InputComponent
          {...{
            required: false,
            allowClear: true,
            label: "To time",
            type: "timePicker",
            formItemName: "toTime",
            dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
            defaultValue: loadLivePreference("afterHoursToTime")
              ? parseInTz(loadLivePreference("afterHoursToTime"))
              : null,
          }}
        />
      </div>
      <div className="buttons-section">
        <MondayButton
          Icon={<ClearFilterIcon />}
          className="mondayButtonRed"
          onClick={() => {
            clearFilterHandler();
          }}
        >
          Clear Range
        </MondayButton>
        <MondayButton
          Icon={<FilterIcon />}
          className="mondayButtonBlue"
          onClick={() => {
            filterHandler();
          }}
        >
          Select Range
        </MondayButton>
      </div>
    </Form>
  );
}

export default RangePopover;
