import {
  BellOutlined,
  EyeInvisibleOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import "./AppNotificationsConfig.scss";
import { FloatButton, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { preferences } from "../../actions";

const AppNotificationsConfig = () => {
  const dispatch = useDispatch();

  const { preferences: prefs } = useSelector((state) => state.preferences);

  const [outsideAppNotifications, setOutsideAppNotifications] = useState(false);
  const [allowNotifications, setAllowNotifications] = useState(true);
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    prefs &&
      setAllowNotifications(
        prefs.preferences.notifications.hasOwnProperty("allowNotifications")
          ? prefs.preferences.notifications?.allowNotifications
          : true
      );
  }, [prefs.preferences.notifications?.allowNotifications]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOutsideAppNotifications(Notification?.permission === "granted");
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const updatePushNotifications = () => {
    const updatedPreferences = {
      preferences: {
        ...prefs.preferences,
        notifications: {
          ...prefs.preferences.notifications,
          allowNotifications: !allowNotifications,
        },
      },
    };

    API.put("preferences", "/preferences", {
      body: updatedPreferences,
    }).then(() => {
      dispatch(
        preferences({ ...prefs, preferences: updatedPreferences.preferences })
      );

      !allowNotifications
        ? message.success("You will get notifications in real time!")
        : message.warning("Notifications are disabled!");
    });
  };

  const hideIconHandler = (e) => {
    e.stopPropagation();
    setShowIcon(false);
    setTimeout(() => {
      setShowIcon(true);
    }, 60000);
  };

  const requestNotificationPremission = () => {
    if (!("Notification" in window)) {
      message.info("This browser does not support desktop notification");
    } else if (Notification?.permission !== "denied") {
      try {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            setOutsideAppNotifications(true);
            const notification = new Notification(
              "You will get notifications every time!"
            );
            console.log({ notification });
          } else {
            message.warning("Permission for notifications denied.");
          }
        });
      } catch (error) {
        message.info(`error2 ${error}`);
        console.log({ error });
      }
    } else {
      message.info(`Notifications by default are ${Notification?.permission}`);
    }
  };

  return (
    <div className="in-app-notifications-config">
      {showIcon && (
        <FloatButton.Group
          data-testid="float-group"
          trigger="click"
          type="primary"
          icon={<BellOutlined />}
          style={{ opacity: 0.8, bottom: 20, right: 20 }}
        >
          {!outsideAppNotifications && (
            <FloatButton
              data-testid="allow-browser-notifications"
              tooltip="Allow Notifications"
              icon={<NotificationOutlined />}
              onClick={requestNotificationPremission}
            />
          )}
          <FloatButton
            tooltip={`Click to${
              allowNotifications ? " disable " : " enable "
            }in app notifications!`}
            data-testid="allow-notifications"
            className={!allowNotifications && "notification-icon-not-allowed"}
            icon={<BellOutlined />}
            onClick={updatePushNotifications}
          />
          <FloatButton
            data-testid="hide-icon"
            tooltip="Hide icon for 1 min!"
            icon={<EyeInvisibleOutlined />}
            onClick={hideIconHandler}
          />
        </FloatButton.Group>
      )}
    </div>
  );
};

export default AppNotificationsConfig;
