import { driveApi } from "../../../../../integrations/DriveRequest";

/**
 *
 * @param {String} accessToken - The access token
 * @param {Array} filesToBeDeleted - The list of files to be deleted
 * @returns deletes all the necessary files from the google drive when we are saving the request
 */
const saveRequestFilesHandler = ({
  accessToken = "",
  filesToBeDeleted = [],
}) => {
  if (!accessToken) return console.error("No access token provided");

  const { deleteDriveItemPromise } = driveApi({ accessToken });

  if (filesToBeDeleted?.length) {
    const toDelete = filesToBeDeleted.map((el) => deleteDriveItemPromise(el));

    return Promise.allSettled(toDelete)?.catch((err) =>
      console.error("Error deleting drive items", err)
    );
  }
};

export default saveRequestFilesHandler;
