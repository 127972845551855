export const categoriesActions = {
  SET_CATEGORIES: "SET_CATEGORIES",

  CREATE_REPORT: "CREATE_REPORT",
  UPDATE_REPORT: "UPDATE_REPORT",
  DELETE_REPORT: "DELETE_REPORT",
  SET_FAVORITE_REPORT: "SET_FAVORITE_REPORT",
  REMOVE_FAVORITE_REPORT: "REMOVE_FAVORITE_REPORT",

  CREATE_CATEGORY: "CREATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  SET_FAVORITE_CATEGORY: "SET_FAVORITE_CATEGORY",
  REMOVE_FAVORITE_CATEGORY: "REMOVE_FAVORITE_CATEGORY",
}
