import React from "react";
import { Form } from "antd";

import "./LabeledInput.scss";

const LabeledInput = ({
  label,
  formItemName,
  initialValue = "",
  noFormItem,
  className = "",
  style = {},
  required = false,
  rules = [],
  children,
  onChange = () => {},
}) => (
  <div className={`labeledInputContainer ${className}`} style={{ ...style }}>
    <div className="labeledInputLabel">{label}</div>
    {!noFormItem ? (
      <Form.Item
        initialValue={initialValue}
        name={formItemName}
        onChange={onChange}
        rules={[
          ...rules,
          required && { required: true, message: `*${label} is required!` },
        ]}
      >
        {children}
      </Form.Item>
    ) : (
      children
    )}
  </div>
);

export default LabeledInput;
