import { Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import SelectCommunicationRecord from "./SelectCommunicationRecord";

import "./compose-new-email.scss";

function ComposeNewEmailShortcut({ open = false, setOpen = () => {} }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <Modal
      open={open}
      onCancel={() => setOpen("")}
      centered
      title="Compose an Email"
      footer={null}
      closeIcon={<XIcon />}
      className={`compose-new-email-shortcut ${isDarkMode && "dark"}`}
      maskClosable={false}
    >
      <SelectCommunicationRecord setOpen={setOpen} />
    </Modal>
  );
}

export default ComposeNewEmailShortcut;
