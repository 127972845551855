import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import { driveApi } from "src/integrations/DriveRequest";
import { operations } from "../helpers/autoCreationActions";
import { removeStep, replaceCreateFromTitle } from "../helpers";
import { ERROR_STEP_MESSAGE, SUCCESS_STEP_MESSAGE } from "../helpers/constants";
import { useEditLogs } from "../../../../hooks";

export const useNextSteps = ({
  form,
  title,
  projectData,
  setCreationProgresses,
  handleSavedRecord = () => {},
}) => {
  const [selectedSteps, setSelectedSteps] = useState([]);
  const [inputData, setInputData] = useState({});

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { applications } = useSelector((state) => state.applications);
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { preferences } = useSelector((state) => state.preferences);

  const driveRequest = driveApi({ accessToken });
  const { saveAddedLogs } = useEditLogs();

  const navigate = useNavigate();

  const { state } = useLocation();

  const { savedRecord: __, isNextStepOpen: _, ...rest } = state || {};

  const onStepChangeHandler = (checked, step) => {
    const modifiedName = replaceCreateFromTitle(step.name);

    if (!checked) {
      removeStep(step.name, selectedSteps);
      setSelectedSteps((prev) =>
        prev.filter(
          (item) =>
            item.name !== step.name &&
            item.dependant.some((dep) => dep !== modifiedName)
        )
      );
      setCreationProgresses((prev = []) =>
        prev.filter((item) => item.progressName !== step.name)
      );
      return;
    }
    setSelectedSteps((prev) => [...prev, step]);
    setCreationProgresses((prev = []) => [
      ...prev,
      {
        progressKey: "updatingRecord",
        progressName: step.name,
        progressStatus: "notStarted",
      },
    ]);
  };

  const performAutoCreation = async (data) => {
    try {
      if (selectedSteps.length >= 1) {
        for (const step of selectedSteps) {
          showLoadingMsg({
            content: `Creating 
            ${replaceCreateFromTitle(step.name)}, Please wait...`,
          });

          const formValues = form.getFieldsValue();
          const dependantName =
            step.dependant.length > 0
              ? replaceCreateFromTitle(step.dependant[0])
              : "current record";

          setCreationProgresses((prev) =>
            prev?.map((progress) =>
              progress.progressName === step.name
                ? { ...progress, progressStatus: "executing" }
                : progress
            )
          );

          await step.stepAutoCreation({
            data,
            title,
            inputData,
            formValues,
            projectData,
            preferences,
            applications,
            driveRequest,
            programFields,
            dependantName,
            userConfiguration,
            saveAddedLogs,
          });

          setCreationProgresses((prev) =>
            prev?.map((progress) =>
              progress.progressName === step.name
                ? { ...progress, progressStatus: "finished" }
                : progress
            )
          );
        }
        showSuccessMsg({
          content: SUCCESS_STEP_MESSAGE,
        });
      }
    } catch (error) {
      console.error({ error });
      setCreationProgresses((prev) =>
        prev?.map((progress) =>
          progress.progressStatus === "executing"
            ? { ...progress, progressStatus: "hasError" }
            : progress
        )
      );
      showErrorMsg({
        content: ERROR_STEP_MESSAGE,
      });
    } finally {
      operations.Clear();
      navigate(_, { state: { ...rest } });
      handleSavedRecord();
    }
  };

  return {
    inputData,
    setInputData,
    selectedSteps,
    setSelectedSteps,
    performAutoCreation,
    onStepChangeHandler,
  };
};
