import { Modal } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { useState, useRef } from "react";

import {
  InitialInvoiceProvider,
  useInitialInvoiceContext,
} from "./context/InvoiceInitialContext";
import InvoiceWrapper from "./hoc/InvoiceWrapper";
import { useRedux } from "../../../hooks/useRedux";
import { LoadableComp } from "../../../XComponents";
import InvoiceHeader from "./components/InvoiceHeader";
import InvoiceFooter from "./components/InvoiceFooter";
import { updateDocumentTitle } from "../../../../../utils";
import { exitIfInEditMode } from "./utils/exitIfInEditMode";
import InvoiceForm from "./components/InvoiceForm/InvoiceForm";
import { EditableModeWrapper } from "./hoc/EditableModeWrapper";
import { ConfirmationModal } from "../../../../commonComponents";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import { InvoiceModalProvider } from "./context/InvoiceModalContext";
import { InvoiceFormDataProvider } from "./context/InvoiceFormDataContext";
import { CreateInvoiceTable } from "./components/InvoiceTable/CreateInvoiceTable";
import { ProgressProvider } from "../../../../commonComponents/ProgressComponent/context/ProgressContext";

import "./InvoiceModal.scss";

const InvoiceModal = (props) => {
  const actionType = props.invoiceData ? "Update" : "Create";
  const categoryName = "Invoices";

  return (
    <ProgressProvider {...{ categoryName, actionType }}>
      <InitialInvoiceProvider {...{ ...props }}>
        <InvoiceFormDataProvider>
          <InvoiceModalProvider>
            <NewInvoiceModal />
          </InvoiceModalProvider>
        </InvoiceFormDataProvider>
      </InitialInvoiceProvider>
    </ProgressProvider>
  );
};

const NewInvoiceModal = () => {
  const { invoiceId } = useParams();

  const { invoiceData, handleExit, isLoading } = useInitialInvoiceContext();

  const servicesInRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(true); //Check if to show the modal

  const [confirmData, setConfirmData] = useState();

  const [isWritable, setIsWritable] = useRedux("invoiceIsWritable");

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const navigate = useNavigate();
  const location = useLocation();

  const handleCancel = () => {
    if (invoiceId) {
      return exitIfInEditMode(location, invoiceData, navigate);
    }
    setConfirmData();
    setIsModalVisible(false);
    handleExit();
    updateDocumentTitle(); // reset document title to "Lead Manager";
  };

  const onCancel = () => {
    setConfirmData({
      visible: true,
      setVisible: () => {},
      onConfirm: handleCancel,
      onCancel: setConfirmData,
      text: invoiceData
        ? "Any change that you have made will be lost?"
        : "This invoice will be discarded",
      title: "Confirm Cancel",
    });
    setIsWritable(false);
    localStorage.removeItem(invoiceData?.invoiceId);
  };

  return (
    <Modal
      className={
        isDarkMode ? "createInvoiceContainer-dark" : "createInvoiceContainer"
      }
      wrapClassName="wrapperCreateInvoiceContainer"
      title={<InvoiceHeader {...{ onCancel, handleCancel }} />}
      afterOpenChange={(event) => {
        event &&
          updateDocumentTitle({
            newTitle: <InvoiceHeader {...{ onCancel, handleCancel }} />,
          });
      }}
      closable={false}
      enableRangeSelection={true}
      destroyOnClose={true}
      open={isModalVisible}
      footer={<InvoiceFooter {...{ setConfirmData, handleCancel }} />}
      onCancel={() => handleCancel()}
      okButtonProps={{
        children: "Custom OK",
        color: "red",
      }}
    >
      <LoadableComp loading={!!isLoading}>
        <InvoiceWrapper {...{ setConfirmData, handleCancel }}>
          <EditableModeWrapper {...{ isWritable, className: "editWrapper" }}>
            <ControlPanel />
            <InvoiceForm {...{ servicesInRef }} />
            <CreateInvoiceTable servicesInRef={servicesInRef} />
            {confirmData && (
              <ConfirmationModal
                {...{ ...confirmData, callBackFunc: () => setConfirmData() }}
              />
            )}
          </EditableModeWrapper>
        </InvoiceWrapper>
      </LoadableComp>
    </Modal>
  );
};
export default InvoiceModal;
