import { Alert } from "antd";
import { formatCurrency, getDate } from "../../../../../../../utils";
import "./Information.scss";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  getOtherScopePrice,
  getServiceLegacyPrice,
  getServicePriceInclAddons,
  getTotalServicesPrice,
} from "../../../../../calculations/servicePrices";

const Information = ({ selectedEstimations, estimations }) => {
  return (
    <div className="informationHolder">
      <div className="select-options-information-title">Information</div>
      {selectedEstimations?.length ? (
        selectedEstimations?.map((data) => (
          <div className="informationDataHolder">
            <div className="estimationSelected">
              {
                estimations?.find((estData) => estData?.estimationId === data)
                  ?.jobSiteAddress
              }
            </div>
            <div className="titleHolderInformation">Services:</div>
            {estimations
              ?.find((estData) => estData?.estimationId === data)
              ?.services?.map((serviceData, index) => (
                <div className="contentHolderInformation">
                  {`${index + 1}) ${serviceData?.label}`}
                  <div className="gap" />
                  <div className="priceHolder">
                    {(() => {
                      let serviceTotalPrice = 0;
                      serviceTotalPrice += forceToNumber(
                        getServiceLegacyPrice(serviceData)
                      );

                      return formatCurrency(serviceTotalPrice);
                    })()}
                  </div>
                </div>
              ))}

            <div className="titleHolderInformation">Total Price:</div>
            <div className="contentHolderInformation">
              <div className="priceHolder">
                {(() => {
                  const services = estimations?.find(
                    (estData) => estData?.estimationId === data
                  )?.services;
                  console.log("services", services);
                  const takeOffTotalPrice = getTotalServicesPrice(services);
                  console.log("takeOffTotalPrice", takeOffTotalPrice);
                  //   const takeOffTotalPrice = (services || [])
                  //     .flatMap(({ serviceOptions, serviceAddons = [] }) => [
                  //       ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                  //         items?.flatMap(({ totalPrice }) => +totalPrice)
                  //       ),
                  //       ...serviceOptions?.[0]?.flatMap?.(
                  //         ({ totalPrice }) => +totalPrice
                  //       ),
                  //       ...serviceAddons?.map(({ totalPrice }) => +totalPrice),
                  //     ])
                  //     .filter(Boolean)
                  //     .reduce((acc, curr) => (acc += curr), 0);

                  return (
                    <>
                      {formatCurrency(takeOffTotalPrice)}
                      {takeOffTotalPrice <= 0 && (
                        <Alert
                          message="Be careful. There is no configured Price."
                          type="error"
                        />
                      )}
                    </>
                  );
                })()}
              </div>
            </div>

            <div className="titleHolderInformation">Project Executive:</div>
            <div className="contentHolderInformation">
              {
                estimations?.find((estData) => estData?.estimationId === data)
                  ?.projectExecutive
              }
            </div>

            <div className="titleHolderInformation">Estimation Status:</div>
            <div className="contentHolderInformation">
              {
                estimations?.find((estData) => estData?.estimationId === data)
                  ?.estSTATUS
              }
            </div>

            <div className="titleHolderInformation">Created At:</div>
            <div className="contentHolderInformation">
              {getDate(
                estimations?.find((estData) => estData?.estimationId === data)
                  ?.createdAt
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="titleHolderInformation">No Selected Estimations</div>
      )}
    </div>
  );
};

export default Information;

// const additionalRentalServices = (services) => {
//     let additionalRentCost = 0;
//     if (Array.isArray(services)) {
//       services?.forEach((service) => {
//         additionalRentCost += forceToNumber(
//           service?.additionalRentalTerms?.newPrice
//         );
//       });
//     }
//     return additionalRentCost;
//   };
