import { Collapse, Checkbox, Flex } from "antd";

import Text from "src/components/commonComponents/Base/Text";
import { ProgressStatistics } from "src/components/SidebarPages/Scheduling/helpers/totals";

import PliTable from "./PliTable";

const ElevationCollapse = ({
  elevation,
  label,
  displayProgress,
  progressLabel,
  serviceId,
  elevationsChecked,
  plisChecked,
  onElevationChange,
  onElevationItemChange,
  setDescription,
  setDescriptionModalVisible,
}) => {
  const { elevationLabel, items } = elevation;

  const preRowData = items?.flatMap((item) => {
    const subplis = item?.subPLIs;

    return !!!subplis
      ? { ...item }
      : [...subplis]?.map((subItem, i) => ({
          id: `${item?.id}/${i + 1}`,
          ...subItem,
        }));
  });

  const rowData = preRowData?.map((item) => ({
    ...item,
    formItemName: `${label}_${elevationLabel}_${item?.id}`,
  }));

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    onElevationChange(isChecked);
  };

  const handleElevationItemChange = (e, text, itemId) => {
    onElevationItemChange(e, text, itemId);
  };

  const handleLabelClick = (e) => {
    e.stopPropagation();
  };

  const onShowModal = (description) => {
    setDescription(description);
    setDescriptionModalVisible(true);
  };

  const elevationProgress = ProgressStatistics.elevationTotal2(
    elevation,
    serviceId
  )?.progressPercentage;

  const collapseItems = [
    {
      key: "1",
      label: (
        <Flex
          justify="space-between"
          align="center"
          className="service-accordion"
          data-testid={`${elevationLabel}-data-testid`}
        >
          <div onClick={handleLabelClick}>
            <Checkbox
              indeterminate={elevationsChecked.isIndeterminate}
              checked={elevationsChecked.isChecked}
              data-testid={`${elevationLabel}-checkbox-data-testid`}
              onChange={handleCheckboxChange}
            >
              {elevationLabel}
            </Checkbox>
          </div>

          {displayProgress && (
            <div>
              <Text>{`${progressLabel}: ${elevationProgress}%`}</Text>
            </div>
          )}
        </Flex>
      ),
      children: (
        <PliTable
          rowData={rowData}
          plisChecked={plisChecked?.find(
            (elev) => elev.elevationLabel === elevationLabel
          )}
          displayProgress={displayProgress}
          progressLabel={progressLabel}
          onShowModal={onShowModal}
          onElevationItemChange={handleElevationItemChange}
        />
      ),
    },
  ];

  return <Collapse accordion items={collapseItems} expandIconPosition="end" />;
};

export default ElevationCollapse;
