import uniq from "lodash/uniq";
import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const workOrders = ({ tables, programFields }) => {
  const workOrdersStatuses = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions["Work Orders"];

  return {
    workOrderStatus: () => ({
      labels: uniq(tables["Work Orders"]?.map((a) => a?.workOrderStatus)),
      data: uniq(
        tables?.["Work Orders"]?.map?.(({ workOrderStatus }) => workOrderStatus)
      )?.map(
        (status) =>
          tables?.["Work Orders"]?.filter(
            (a) => a?.workOrderStatus?.toLowerCase() === status?.toLowerCase()
          )?.length
      ),
      backgroundColor: tables["Work Orders"]?.map?.(() => getRandomColor()),
    }),

    fleetName: () => ({
      labels: uniq(
        tables?.["Work Orders"]?.map?.(({ fleetName }) => fleetName)
      ),
      data: uniq(
        tables?.["Work Orders"]
          ?.map?.(({ fleetName }) => fleetName)
          ?.map(
            (status) =>
              tables?.["Work Orders"]?.filter(
                (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
              )?.length
          )
      ),
      backgroundColor: uniq(
        tables?.["Work Orders"]?.map?.(() => getRandomColor())
      ),
    }),

    mechanicName: () => ({
      labels: uniq(
        tables?.["Work Orders"]?.map?.(
          ({ mechanicInfo }) =>
            mechanicInfo?.mechanic || mechanicInfo?.mechanicName
        )
      ),
      data: uniq(
        tables?.["Work Orders"]
          ?.map?.(
            (a) => a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
          )
          ?.map(
            (status) =>
              tables?.["Work Orders"]?.filter?.(
                (a) =>
                  (
                    a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
                  )?.toLowerCase() === status?.toLowerCase()
              )?.length
          )
      ),
      backgroundColor: uniq(
        tables?.["Work Orders"]?.map?.(() => getRandomColor())
      ),
    }),

    dueDate: () => ({
      labels: months,
      data: (() => {
        let data = [];
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            tables?.["Work Orders"]
              ?.map((a) => a?.dueDate)
              ?.filter(
                (b) =>
                  b >= getMonthsTimestamps()[i] &&
                  b <= getMonthsTimestamps("end")[i]
              )?.length,
          ];
        }
        return data;
      })(),
      backgroundColor: months.map((a) => getRandomColor()),
    }),
  };
};
