export const prev = (geo) => {
  if (geo.actionType === "Delete") {
    return {
      id: "generalInformation",
      params: {
        id: "geofence",
        rowData: { ...geo, action: "delete" },
      },
      currentStep: "5",
    };
  }
  if (geo.actionType === "Create") {
    return {
      id: "generalInformation",
      params: {
        id: "geofence",
        rowData: [{ value: "Create", label: "Action Type" }],
      },
      currentStep: "5",
    };
  }
  if (geo.actionType === "Edit") {
    return {
      id: "generalInformation",
      params: {
        id: "geofence",
        rowData: { ...geo, action: "edit" },
      },
      currentStep: "5",
    };
  }
};

export const curr = (geo, prev) => {
  if (geo.actionType === "Delete") {
    return {
      id: "generalInformation",
      params: {
        id: "geofence",
        rowData: [{ value: "Deleted", label: "Action Type" }],
      },
      currentStep: "5",
    };
  }
  if (geo.actionType === "Create") {
    return {
      id: "generalInformation",
      params: {
        id: "geofence",
        rowData: { ...geo, action: geo.actionType },
      },
      currentStep: "5",
    };
  }
  if (geo.actionType === "Edit") {
    console.log({ geo, prev });
    return {
      id: "generalInformation",
      params: {
        id: "geofence",
        rowData: { ...geo, geoFenceInfo: prev, action: "edit" },
      },
      currentStep: "5",
    };
  }
};

export const dynamicOnAction = (action) => {
  return action === "Delete"
    ? "onDeletion"
    : action === "Create"
    ? "onCreation"
    : action === "Edit"
    ? "onEdition"
    : "";
};
