export const customProps = (savedRecord, category) => ({
  Projects: {
    taskTopic: category,
    taskTopicId: savedRecord?.projectId || "",
    taskRelatedTo: savedRecord?.projectName || "",
  },
  Invoices: {
    taskTopic: category,
    taskTopicId: savedRecord?.invoiceId,
    taskRelatedTo: savedRecord?.invoiceNumber,
  },
  Clients: {
    taskTopic: category,
    taskTopicId: savedRecord?.accountId || "",
    taskRelatedTo: savedRecord?.accountName || "",
  },
  Hearings: {
    taskTopic: category,
    taskTopicId: savedRecord?.hearingId || "",
    taskRelatedTo: savedRecord?.hearingName || "",
  },
  Claims: {
    taskTopic: category,
    taskTopicId: savedRecord?.claimId || "",
    taskRelatedTo: savedRecord?.claimNumber || "",
  },
  Contacts: {
    taskTopic: category,
    taskTopicId: savedRecord?.contactId || "",
    taskRelatedTo: savedRecord?.contactEmail || "",
  },
  Estimations: {
    taskTopic: category,
    taskTopicId: savedRecord?.estimationId || "",
    taskRelatedTo: savedRecord?.jobSiteAddress || "",
  },
  Fleet: {
    taskTopic: category,
    taskTopicId: savedRecord?.fleetId || "",
    taskRelatedTo: savedRecord?.fleetName || "",
  },
  Incidents: {
    taskTopic: category,
    taskTopicId: savedRecord?.incidentId || "",
    taskRelatedTo: savedRecord?.incidentName || "",
  },
  ["Fleets Violations"]: {
    taskTopic: category,
    taskTopicId: savedRecord?.violationId || "",
    taskRelatedTo: `${savedRecord?.summons_number} | ${savedRecord?.fleetName}`,
  },
  Inspections: {
    taskTopic: category,
    taskTopicId: savedRecord?.inspectionId || "",
    taskRelatedTo: savedRecord?.projectName?.concat(
      " ",
      savedRecord?.inspectionType || ""
    ),
  },
  Leads: {
    taskTopic: category,
    taskTopicId: savedRecord?.leadId || "",
    taskRelatedTo: savedRecord?.leadCompany || "",
  },

  Opportunities: {
    taskTopic: category,
    taskTopicId: savedRecord?.opportunityId || "",
    taskRelatedTo: savedRecord?.opportunityAddress || "",
  },
  Applications: {
    taskTopic: category,
    taskTopicId: savedRecord?.applicationId || "",
    taskRelatedTo: savedRecord?.projectName || "",
  },
  ["Schedule of values"]: {
    taskTopic: category,
    taskTopicId: savedRecord?.scheduleId || "",
    taskRelatedTo: savedRecord?.projectName || "",
  },
  Safety: {
    taskTopic: category,
    taskTopicId: savedRecord?.safetyApplicationId || "",
    taskRelatedTo: savedRecord?.inspectionName || "",
  },
  Scheduling: {
    taskTopic: category,
    taskTopicId: savedRecord?.scheduleId || "",
    taskRelatedTo: savedRecord?.scheduleAddress || "",
  },
  Vendors: {
    taskTopic: category,
    taskTopicId: savedRecord?.vendorId || "",
    taskRelatedTo: savedRecord?.vendorName || "",
  },
});

export const remindMeInitialState = {
  reminderValue: null,
  reminderType: "Days",
  reminderTime: "",
  reminderDays: null,
  notifiedBy: ["Email", "Notification"],
  reminderAssignedTo: [],
};
