import { projectAddress } from "..";

/**
 * Handles the selection of a project address.
 *
 * @param {string} val - The selected value.
 * @param {Object} opt - The selected option.
 * @param {boolean} [clear=false] - Indicates whether to clear the fields.
 * @param {Array} [projects=[]] - The list of projects.
 * @param {Array} [accounts=[]] - The list of accounts.
 * @param {Array} [opportunities=[]] - The list of opportunities.
 * @param {boolean} [isChangeOrder=false] - Indicates whether it's a change order.
 * @param {Function} [setFieldsValue=() => {}] - The function to set field values.
 * @param {Function} [dispatchRequestData=() => {}] - The function to dispatch request data.
 */
const onProjectAddressSelect = ({
  val,
  opt,
  clear = false,
  projects = [],
  accounts = [],
  opportunities = [],
  isChangeOrder = false,
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (clear == true) {
    setFieldsValue({
      projectExecutive: "",
      proposedTypeOfWork: "",
      projectManager: [],
      client: "",
      team: [],
      bin: "",
    });
    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: null,
    });

    dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: [],
    });

    dispatchRequestData({
      type: "BIN",
      payload: [],
    });
  } else {
    const {
      projectExecutive,
      proposedTypeOfWork,
      foundProject,
      projectManager,
      accountId = "",
      teamsConfiguration,
      binNumber = [],
      leadId = "",
      projectId = "",
      opportunityId = "",
    } = projectAddress({
      val,
      projects,
      opportunities,
      category: opt?.category,
    });

    if (!!teamsConfiguration?.length) {
      dispatchRequestData({
        type: "SELECTED_TEAM",
        payload: teamsConfiguration,
      });
    }

    const toSet = {
      projectExecutive,
      proposedTypeOfWork: proposedTypeOfWork || [],
      projectManager: projectManager || [],
      team: teamsConfiguration?.map((team) => ({
        value: team?.value,
      })),
      client: foundProject?.accountName || "",
    };

    const payload = {
      recordId: val,
      recordName: opt?.recordName,
      leadId,
      accountId,
      accountName: foundProject?.accountName,
      projectId,
      opportunityId,
      category: opt?.category,
    };

    // Id we are creating request with type change order, these are keys needed to create a change order
    if (isChangeOrder === true) {
      Object.assign(payload, {
        googleDriveFolderIds: foundProject?.googleDriveFolderIds || null,
        taxRate: foundProject?.taxRate || null,
        taxExempt: foundProject?.taxExempt || null,
        primaryContact: foundProject?.primaryContact || null,
        insuranceRequirement: foundProject?.insuranceRequirement || null,
        laborRequirement: foundProject?.laborRequirement || null,
        contacts: foundProject?.contacts || null,
        proposedStartDate: foundProject?.proposedStartDate || null,
        accountName: opt?.accountName,
        isChangeOrder,
      });
    }

    if ((accounts || [])?.some((el) => el?.accountId === accountId)) {
      Object.assign(toSet, {
        client: accountId,
      });

      Object.assign(payload, {
        accountId,
      });
    }

    // console.log({ ...toSet });

    setFieldsValue(toSet);

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload,
    });

    if (binNumber?.length) {
      dispatchRequestData({
        type: "BIN",
        payload: binNumber,
      });
    }
  }
};

export default onProjectAddressSelect;
