import { useStateContext } from "../../../../../Context/Context";
import { defaultColDef } from "./GridParams/defaultColumnDefs";
import { columnDefs } from "./GridParams/columnDefs";
import "./RentalBody.scss";

import { AgGridReact } from "ag-grid-react";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { message } from "antd";
import CustomFooter from "../../../../../../ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import AgGridMathful from "../../../Service/ServiceContent/AgGridSum/AgGridMathful";
import { forceToNumber } from "../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { useCallback } from "react";

const RentalBody = ({ services, isPrevious = false }) => {
  const { darkMode } = useStateContext();
  const { fakeApplication } = useStateContext();
  const [selected, setSelected] = useState(false); // Context state & dispatch

  // const initialValue = cloneDeep(services);
  // const rowData = initialValue;
  const onRangeSelectionChanged = (event) => {
    // console.log("hoist selection changed", event);
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  };

  const rows = useMemo(() => {
    return (
      cloneDeep(
        services?.map((el) => {
          console.log("servicesservices", el);
          return {
            ...el,
            paymentDue: el?.thisDistRetainage || 0,
          };
        })
      ) || []
    );
  }, [services]);
  const getRowStyle = useCallback(
    (params) => {
      if (!isPrevious) {
        return { background: "#41B06E", color: "#fff" };
      } else {
        return { background: "#481E14", color: "#fff" };
      }
    },
    [isPrevious]
  );

  console.log("rows", rows);

  return (
    // <ResizableBox
    //   className="includeRentalBodyResizable"
    //   minConstraints={[0, 0]}
    //   maxConstraints={[0, 1500]}
    //   height={270}
    //   width={1800}
    //   axis="y"
    //   handle={
    //     <div className="includeRentalBodyResizableGripContainer">
    //       <div className="leftSize" style={{ background: "#71cf48" }} />
    //       <div className="grabberContainer" style={{ background: "#71cf48" }}>
    //         <div className="gripLines" />
    //         <div className="gripLines" />
    //         <div className="gripLines" />
    //       </div>
    //       <div className="rightSize" style={{ background: "#71cf48" }} />
    //     </div>
    //   }
    // >
    <>
      {" "}
      {selected && <AgGridMathful darkMode={darkMode} event={selected} />}
      <div
        className={
          darkMode
            ? "dark-ag-theme documentationsTable ag-theme-alpine-dark rentalBodyContainerDark"
            : "grey-ag-theme documentationsTable ag-theme-alpine rentalBodyContainerLight"
          // darkMode
          // 	? "includeRentalsAgGridContainerDark agGridRentalsDetailsStyleDark"
          // 	: "includeRentalsAgGridContainerLight agGridRentalsDetailsStyleLight"
        }
      >
        {" "}
        <AgGridReact
          enableRangeSelection={true}
          suppressSizeToFit={true}
          rowSelection="multiple"
          domLayout="autoHeight"
          paginationPageSize={14}
          headerHeight={100}
          columnDefs={columnDefs}
          rowData={rows}
          rowHeight={40}
          getRowStyle={getRowStyle}
          defaultColDef={defaultColDef}
          onRangeSelectionChanged={onRangeSelectionChanged}
          suppressDragLeaveHidesColumns={true}
        />
      </div>
      <CustomFooter
        style={{
          height: "50px",
          marginTop: "0px",
          marginRight: "50px",
          position: "relative",
          width: "100%",
          justifyContent: "center",
          backgroundColor: !isPrevious ? "#41B06E" : "#481E14",
        }}
        color="#fff"
        dataSrc={rows?.map((el) => {
          let taxAmount = el?.appliedAmount * el?.taxRate || 0;
          let retainage = el?.retainageAmount || 0;
          let credit = Number(el?.serviceLevelCredit || 0);
          let tempTotal = el?.appliedAmount + taxAmount + retainage + credit;
          credit = !isNaN(credit) ? credit + credit * el?.taxRate : 0;
          console.log("", { isPrevious, el });
          let paymentDue = isPrevious
            ? el?.paymentDue
            : el?.currentPayment || 0;

          return {
            ...el,
            serviceLevelCredit: credit || 0,
            credit: credit || 0,
            totality: tempTotal,
            paymentDue: paymentDue,
            retaianageBalance: forceToNumber(getRetBalance(el)),
          };
        })}
        labelsKeys={[
          {
            key: "paymentDue",
            name: "Payment Due",
            type: "currency",
            mathAction: "sum",
          },
          {
            key: "appliedAmount",
            name: "Applied Amount",
            type: "currency",
            mathAction: "sum",
          },
          {
            key: "retaianageBalance",
            name: "Retainage Balance",
            type: "currency",
            mathAction: "sum",
          },
          {
            key: "credit",
            name: "Credit (-)",
            type: "currency",
            mathAction: "sum",
          },
          {
            key: "totality",
            name: "Total Amount",
            type: "currency",
            mathAction: "sum",
          },
        ]}
      />
    </>
    // </ResizableBox>pA
  );
};

export default RentalBody;

const getRetBalance = (data) => {
  let tax = data?.taxRate || 0;
  console.log(
    "mydata",
    (data?.retainage / 100) * (data?.appliedAmount + data?.appliedAmount * tax)
  );
  if (data?.retainage > 0) {
    return (
      (data?.retainage / 100) *
      (data?.appliedAmount + data?.appliedAmount * tax)
    );
  } else {
    return 0;
  }
};
