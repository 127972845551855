import uniq from "lodash/uniq";
import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const fleet = (updatedDynamicStates, IdHandler, fleetData) => {
  return {
    fleetYear: () => ({
      title: "Fleet",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Fleet",
        field: "fleetYear",
        title: "Fleet Year",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.fleetYear)
        )
          ?.sort()
          ?.map((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (a) => a?.fleetYear === status
              ).length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: status,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.fleetYear === status
            ).length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }), //fleetYear
    registrationExpDate: () => ({
      title: "Fleet",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Fleet",
        field: "registrationExpDate",
        title: "Registration Expiration Date",
        subtitle: "",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                percent: (
                  ((updatedDynamicStates?.[IdHandler] || [])
                    ?.filter(
                      (b) => b.registrationExpDate >= getMonthsTimestamps()[0]
                    )
                    ?.map((a) => a?.registrationExpDate)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    (updatedDynamicStates?.[IdHandler] || [])?.filter(
                      (b) => b?.registrationExpDate >= getMonthsTimestamps()[0]
                    ).length) *
                  100
                ).toFixed(1),
                showInfo: true,
                status: months[i],
                done: (updatedDynamicStates?.[IdHandler] || [])
                  ?.filter(
                    (b) => b?.registrationExpDate >= getMonthsTimestamps()[0]
                  )
                  ?.map((a) => a?.registrationExpDate)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "390px",
    }), //registrationExpDate
    inspectionExpDate: () => ({
      title: "Fleet",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Fleet",
        field: "inspectionExpDate",
        title: "Inspection Expiration Date",
        subtitle: "",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                percent: (
                  ((updatedDynamicStates?.[IdHandler] || [])
                    ?.filter(
                      (b) => b?.inspectionExpDate >= getMonthsTimestamps()[0]
                    )
                    ?.map((a) => a?.inspectionExpDate)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    (updatedDynamicStates?.[IdHandler] || [])?.filter(
                      (b) => b?.inspectionExpDate >= getMonthsTimestamps()[0]
                    ).length) *
                  100
                ).toFixed(1),
                showInfo: true,
                status: months[i],
                done: (updatedDynamicStates?.[IdHandler] || [])
                  ?.filter(
                    (b) => b?.inspectionExpDate >= getMonthsTimestamps()[0]
                  )
                  ?.map((a) => a?.inspectionExpDate)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "390px",
    }), //inspectionExpDate
  }; //Fleet
};
