import { v4 as uuid } from "uuid";
import { notification } from "antd";
import { showErrorMsg, showSuccessMsg } from "./utils";
import { onNotificationClickUpdateState } from "./utils/onNotificationClickUpdateState";
import { addNotification } from "./actions/notificationsActions";

let pingInterval = undefined;

export const GetNotifications = ({
  wsRef,
  navigate,
  dispatch,
  userConfiguration: userDetails,
  preferences,
  notificationSettings,
}) => {
  try {
    let pingId = 0;
    const allowNotifications =
      preferences?.notifications?.allowNotifications ?? true;

    // initialize notifications session
    wsRef?.send(
      JSON.stringify({
        request: "createSession",
        body: {
          userDetails,
          sessionId: uuid(),
          notificationTopics: preferences?.notifications?.topics,
          pushNotifications: preferences?.notifications?.pushNotifications, //this is configures if user want to get push notifications or just to save its notification in db
          allowNotifications,
        },
      })
    );

    clearInterval(pingInterval);
    pingInterval = setInterval(() => {
      try {
        if (wsRef.readyState === WebSocket.OPEN) {
          wsRef.send(
            JSON.stringify({
              request: "ping",
              body: { id: pingId },
            })
          );
        } else {
          console.error(
            "WebSocket with subscribe-notifications is closed state"
          );
        }
      } catch (e) {
        console.error(e);
      }
    }, 15000);

    wsRef.onmessage = (e) => {
      let msg = JSON.parse(e?.data);

      if (
        msg?.body?.originUrl ===
          `/${wsRef.url.split("/")[wsRef.url.split("/").length - 1]}` &&
        allowNotifications
      ) {
        switch (msg.request) {
          case "pong": {
            if (msg.body.id === pingId) {
              pingId++;
            }
            break;
          }
          case "mark-all-as-read": {
            break;
          }
          case "notification-preferences": {
            if (!!msg?.body?.status) {
              showSuccessMsg({
                content: "Template saved to all user preferences",
              });
            } else {
              showErrorMsg({
                content:
                  "Something happened while trying to save the template to all users",
              });
            }
            break;
          }
          case "broadcast": {
            const { body } = msg;
            const { topic, action, actionData, id, notificationObject } =
              body || {};

            const currNotificationSetting = notificationSettings.find(
              (notificationSetting) => notificationSetting?.topicId === topic
            );
            const template = currNotificationSetting?.templates?.find(
              ({ templateAction }) => templateAction === action
            );

            //check if currNotificationSetting exists and has that template
            if (!currNotificationSetting || !template) {
              console.log(
                `currNotificationSetting:${topic} in notificationSettings does not include action:${action}`
              );
              return;
            }

            onNotificationClickUpdateState(dispatch, body);

            const assignedTo =
              (!!notificationObject &&
                Object.keys(notificationObject.cognitoUserId)) ||
              [];

            if (
              topic === "19" ||
              assignedTo.includes(userDetails?.cognitoUserId)
            ) {
              //* Update the notifications in redux
              dispatch(
                addNotification({
                  category: "all",
                  notification: notificationObject,
                  notificationSettings,
                })
              );

              let generatedURL =
                template.templateContent.uri !== null &&
                template.templateContent.uri
                  ?.replace("{id}", id)
                  ?.replaceAll("$", "");

              let msgBody = template.templateContent.body?.replace(
                /\{\{(.*?)\}\}/g,
                function (i, matched) {
                  const firstReplace = matched.replace("&ccedil;", "ç");
                  if (topic === "19") {
                    return (
                      actionData[1]?.rowSelected?.[firstReplace] || matched
                    );
                  } else {
                    return actionData[0][firstReplace] || matched;
                  }
                }
              );

              if (!document.hasFocus()) {
                const notification = new Notification(
                  template.templateContent.head,
                  {
                    body: msgBody?.replace(/<\/?[^>]+(>|$)/g, ""),
                  }
                );
                notification.onclick = (event) => {
                  // event.preventDefault();                                // prevent the browser from focusing the Notification's tab
                  // window.location.href = generatedURL;    // Opens in the current tab, not opening on a new browser tab
                  id !== null && window.open(generatedURL, "_blank"); // Ope1ns on a new browser tab
                };
              }

              const notificationBody = {
                message: template.templateContent.head,
                description: (
                  <span>
                    <p>{msgBody?.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                  </span>
                ),
                onClick: () => {
                  if (!!id) {
                    const { cognitos, ...rest } = actionData[1];

                    navigate(generatedURL, { state: rest });
                  }
                  setTimeout(() => notification.destroy(), 1000);
                },
                className: "info__notification",
                placement: "bottomRight",
                key: uuid(),
                duration: template.templateContent.duration,
              };

              if (template.templateContent.priority.includes("Critical")) {
                notification.error({
                  ...notificationBody,
                  className: "delete__notification",
                  placement: "bottomRight",
                });
              } else {
                notification.info(notificationBody);
              }
              break;
            }
            break;
          }

          case "error": {
            console.error(
              "subscribe-notifications WS returned an error from backend: ",
              msg?.body?.error
            );
            break;
          }
          default:
            break;
        }
      }
    };
    wsRef.onerror = (err) => {
      console.log("subscribe-notifications connecting error: ", err);
    };
  } catch (error) {
    console.log("GetNotifications error", error);
  }
};
