import { useEffect, useState } from "react";

import { TimePicker, DatePicker } from "antd";
import "./AddEventModal.scss";
import moment from "moment";
import dayjs from "dayjs";
import {
  getDisabledHours,
  getDisabledMinutes,
  getDisabledEndHours,
  getDisabledEndMinutes,
} from "../../utils/disabledTime";
const timeFormat = "HH:mm";
const dateFormat = "MM/DD/YYYY";

function TimeDate({ processTimeDate }) {
  const customFormat = (value) => ` ${value.format(dateFormat)}`;
  // let momentTimeInst = moment();
  const [timeDate, setTimeDate] = useState({
    startDate: "",
    startTime: "",
    endTime: "",
    endDate: "",
  });
  const [initMoment] = useState({ momentNow: moment() });
  const [startDatePicked, setStartDatePicked] = useState(false);
  const [endDatePicked, setEndDatePicked] = useState(false);
  useEffect(() => {
    if (
      timeDate.startDate &&
      timeDate.startTime &&
      timeDate.endTime &&
      timeDate.endDate
    ) {
      const startFull = dayjs(timeDate.startDate)
        .set("hour", timeDate.startTime.hour())
        .set("minute", timeDate.startTime.minute())
        .toISOString();
      const endFull = dayjs(timeDate.endDate)
        .set("hour", timeDate.endTime.hour())
        .set("minute", timeDate.endTime.minute())
        .toISOString();
      processTimeDate({ startFull, endFull });
    } else {
      processTimeDate({ startFull: "", endFull: "" });
    }
  }, [timeDate]);

  const getDisabledStartDate = (curr) => {
    if (timeDate.endDate !== "") {
      if (
        moment(timeDate.startTime).unix() < moment(initMoment.momentNow).unix()
      ) {
        return (
          curr < moment(initMoment.momentNow).add(1, "days") ||
          curr > timeDate.endDate
        );
      }
      return curr < initMoment.momentNow || curr > timeDate.endDate;
    }
    if (
      moment(timeDate.startTime).unix() < moment(initMoment.momentNow).unix()
    ) {
      return curr < moment(initMoment.momentNow).add(1, "days");
    } else {
      return curr < initMoment.momentNow;
    }
  };
  const startSwitched = () => {
    setStartDatePicked(true);
  };
  const endSwitched = () => {
    setEndDatePicked(true);
  };

  function isSelectedDateGreater() {
    return (
      timeDate.startDate &&
      timeDate.startDate.startOf("day").valueOf() >=
        dayjs().endOf("day").valueOf()
    );
  }

  function disabledHours(startTime) {
    if (!startTime) return [];
    let hours = [];
    let startHour = dayjs(startTime).hour();

    Array(startHour)
      .fill()
      .map((_, idx) => hours.push(idx));

    return hours;
  }

  function disabledMinutes(selectedHour, startTime) {
    if (!startTime && !selectedHour) return [];
    let minutes = [];
    const startMinute = dayjs(startTime).minute();
    if (selectedHour.toString() === dayjs(startTime).hour().toString()) {
      Array(startMinute + 1)
        .fill()
        .map((_, idx) => minutes.push(idx));
    }
    return minutes || [];
  }

  function disabledStartHours() {
    if (isSelectedDateGreater()) {
      return [];
    }

    return disabledHours(Date.now());
  }

  function disabledStartMinutes(selectedHour) {
    if (isSelectedDateGreater()) {
      return [];
    }

    return disabledMinutes(selectedHour, Date.now());
  }

  return (
    <div className="time-date-wrapper">
      <div className="startTime">
        <div className="date-container">
          <label>
            <span className="required-icon">*</span>Start Date
          </label>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DatePicker
              format={customFormat}
              allowClear={false}
              size="medium"
              value={timeDate.startDate}
              disabledDate={(curr) => getDisabledStartDate(curr)}
              onChange={(value) => {
                setTimeDate((prev) => ({ ...prev, startDate: value }));
                startSwitched(value);
              }}
              placeholder="Select start date"
              style={{
                marginLeft: "5px",
                borderRadius: "5px",
                width: startDatePicked ? "49%" : "100%",
                background: "#f5f5f7",
              }}
              disabled={timeDate.endDate && timeDate.endTime ? true : false}
            />{" "}
            {timeDate.startDate && (
              <TimePicker
                format={timeFormat}
                allowClear={false}
                inputReadOnly={true}
                size="medium"
                value={timeDate.startTime}
                onChange={(value) =>
                  setTimeDate((prev) => ({ ...prev, startTime: value }))
                }
                placeholder="Start time"
                style={{
                  borderRadius: "5px",
                  background: "#f5f5f7",
                  width: "49%",
                  marginLeft: "7px",
                }}
                disabled={!!timeDate.endTime && !!timeDate.endDate}
                disabledTime={() => {
                  return {
                    disabledHours: disabledStartHours,
                    disabledMinutes: disabledStartMinutes,
                  };
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="endTime">
        <div className="date-container">
          <label>
            <span className="required-icon">*</span>End Date
          </label>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DatePicker
              format={customFormat}
              allowClear={false}
              size="medium"
              value={timeDate.endDate}
              disabled={
                timeDate.startDate && timeDate.startTime && !timeDate.endTime
                  ? false
                  : true
              }
              onChange={(value) => {
                setTimeDate((prev) => ({ ...prev, endDate: value }));
                endSwitched(value);
              }}
              placeholder="Select end date"
              style={{
                marginLeft: "5px",
                borderRadius: "5px",
                width: endDatePicked ? "49%" : "100%",
                background: "#f5f5f7",
              }}
              disabledDate={(curr) =>
                curr < timeDate.startDate || curr < initMoment.momentNow
              }
            />{" "}
            {timeDate.endDate && (
              <TimePicker
                format={timeFormat}
                size="medium"
                inputReadOnly={true}
                value={timeDate.endTime}
                onChange={(value) =>
                  setTimeDate((prev) => ({ ...prev, endTime: value }))
                }
                placeholder="End time"
                style={{
                  borderRadius: "5px",
                  background: "#f5f5f7",
                  width: "49%",
                  marginLeft: "7px",
                }}
                disabledTime={() => {
                  let tmp = {
                    disabledHours: () => [],
                    disabledMinutes: () => [],
                  };
                  if (
                    timeDate.startDate &&
                    timeDate.startTime &&
                    dayjs(timeDate.startDate).date() ===
                      dayjs(timeDate.endDate).date()
                  ) {
                    return {
                      disabledHours: () => disabledHours(timeDate.startTime),
                      disabledMinutes: (hour) => {
                        return disabledMinutes(hour, timeDate?.startTime);
                      },
                    };
                  } else {
                    return tmp;
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeDate;
