import { Button, Modal, Tooltip, message, Alert, Space } from "antd";
import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import "./ShortcutForPliDays.scss";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { MondayButton } from "../../../../commonComponents";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import {
  autoCompletePlis,
  columnDefsForShortcutPliDays,
  daysInBreakdownsOrSubPliShortcutLogic,
  daysInElevationShortcutLogic,
  daysInPliShortcutLogic,
  daysScheduledInServices,
  getDisabledRows,
  prevSubPliCompleted,
  preventDuplicateStrings,
  swalToast,
  totalProgressForPliBreakdowns,
  totalProgressPli,
  unablePli,
} from "../SchedulingFirstPage/helperData";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { PlusIcon } from "../../../../../assets";
import { get_ProgressDimensionsByServiceId } from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { get_new_progressByDay } from "../../../../SidebarPages/Scheduling/helpers/setters";
import { updateDocumentTitle } from "../../../../../utils";
const ShortcutForPliDays = ({
  shortcutContent = {},
  setShortcutContent = () => {},
  updateGridDataFromShortcut = () => {},
  updateDaysInElevation = () => {},
  agGridApiForEachElevation = {},
  isDarkMode = false,
}) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState();
  const [action, setAction] = useState("add");
  const [allDaysByDefault, setAllDaysByDefault] = useState(
    shortcutContent?.content?.scheduleDays
  );
  const [autoFunction, setAutoFunction] = useState(false);
  const [randomDaysSelected, setRandomDaysSelected] = useState([]);
  const selectedDays = (day) => {
    if (randomDaysSelected?.some((a) => a?.id === day?.id)) {
      setRandomDaysSelected((prev) => [
        ...prev?.filter((x) => x?.id !== day?.id),
      ]);
    } else {
      setRandomDaysSelected((prev) => [...prev, day]);
    }
    setAllDaysByDefault([]);
  };

  let daysToPlis = !allDaysByDefault?.length
    ? randomDaysSelected
    : allDaysByDefault;

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const isRowSelectable = useMemo(() => {
    let disable = false;
    return (params = {}) => {
      if (unablePli(params?.data || {})) {
        disable = true;
      } else {
        disable = false;
      }
      return !disable;
    };
  }, []);

  const updatePlis = () => {
    if (!gridApi?.getSelectedRows()?.length) {
      Swal.fire({
        title: "No pli-s selected!",
        text: "Please select at least one pli to update days!",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    const { d1, d2 } = get_ProgressDimensionsByServiceId(
      shortcutContent?.content?.service?.serviceId
    );

    agGridApiForEachElevation?.[shortcutContent?.content?.service?.serviceId]?.[
      shortcutContent?.content?.elevationIndex
    ]?.api?.forEachNode((node) => {
      const pliSelections = gridApi
        ?.getSelectedRows()
        ?.find((item) => item?.id === node?.data?.id);
      if (!!pliSelections) {
        node.data.days = daysInPliShortcutLogic(node?.data, daysToPlis, action);
        node.data.usedScheduleDays = daysScheduledInServices(
          {
            ...node?.data,
            days: preventDuplicateStrings([
              ...(node?.data?.days || []),
              ...(node?.data?.isPliCompletedInThePreviousSchedule?.prevDays ||
                []),
            ]),
          },
          shortcutContent?.content?.scheduleDays,
          shortcutContent?.content?.scheduleName,
          ""
        );
        if (node?.data?.typeOfProgress === "breakdown") {
          message.info(
            `Pli ${
              node?.data?.id
            } has progress with breakdowns, days you selected are ${
              action === "add" ? "applied" : "removed"
            } ${action === "add" ? "to" : "from"} each Breakdown!`,
            7
          );
          node.data.breakdownValue = daysInBreakdownsOrSubPliShortcutLogic(
            node?.data,
            node?.data?.breakdownValue,
            daysToPlis,
            action,
            "breakdown",
            d1,
            d2,
            shortcutContent?.content?.service?.serviceId,
            autoFunction
          );
        }
        node.data.progressByDay =
          node.data?.typeOfProgress === "breakdown"
            ? []
            : !!autoFunction
            ? autoCompletePlis(daysToPlis, node?.data, d1, d2)
            : get_new_progressByDay(
                preventDuplicateStrings([
                  ...(node?.data?.days || []),
                  ...(node?.data?.isPliCompletedInThePreviousSchedule
                    ?.prevDays || []),
                ]),
                node?.data?.progressByDay,
                {
                  d1,
                  d2,
                }
              );
        if (node?.data?.subPli?.length > 0) {
          message.info(
            `Pli ${node?.data?.id} has Sub Pli-s, days you selected are ${
              action === "add" ? "applied" : "removed"
            } ${action === "add" ? "to" : "from"} each Sub Pli!`,
            7
          );
          node.data.subPli = node?.data?.subPli?.map((subPli) => {
            if (prevSubPliCompleted(subPli)) {
              subPli = subPli;
            } else {
              subPli.days = daysInPliShortcutLogic(subPli, daysToPlis, action);
              subPli.progressByDay = !!autoFunction
                ? autoCompletePlis(daysToPlis, subPli, d1, d2)
                : get_new_progressByDay(subPli?.days, subPli?.progressByDay, {
                    d1,
                    d2,
                  });
              subPli.totalProgress = totalProgressPli(
                subPli,
                shortcutContent?.content?.serviceId
              );
            }

            return subPli;
          });
          node?.setExpanded(false);
        }
        node.data.typeOfProgress = !preventDuplicateStrings([
          ...(node?.data?.days || []),
          ...(node?.data?.isPliCompletedInThePreviousSchedule?.prevDays || []),
        ])?.length
          ? ""
          : node?.data?.progressByDay?.length > 0 && !node?.data?.subPli?.length
          ? "dimensions"
          : node?.data?.typeOfProgress;

        node.data.totalProgress =
          node?.data?.typeOfProgress === "breakdown"
            ? totalProgressForPliBreakdowns(
                node?.data,
                node?.data?.breakdownValue,
                shortcutContent?.content?.typeOfWork,
                d1,
                d2
              )
            : totalProgressPli(
                node?.data,
                shortcutContent?.content?.service?.serviceId
              );
      }
    });

    updateGridDataFromShortcut(shortcutContent?.content?.gridData);

    updateDaysInElevation(
      shortcutContent?.content?.service?.serviceId,
      shortcutContent?.content?.elevationIndex,
      daysInElevationShortcutLogic(
        shortcutContent?.content?.elevation,
        daysToPlis,
        action
      )
    );
    setTimeout(() => {
      swalToast({
        icon: "success",
        title: "Pli-s updated successfully!",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3500,
      });
    }, 500);

    updateDocumentTitle(); // reset document title to "Lead Manager";
    setShortcutContent({ ...shortcutContent, visible: false });
    setAllDaysByDefault([]);
    setRandomDaysSelected([]);
  };

  const disabledRows = getDisabledRows(gridApi?.getRenderedNodes()) || [];

  useEffect(() => {
    if (!randomDaysSelected?.length) {
      setAllDaysByDefault(shortcutContent?.content?.scheduleDays);
      setAction("add");
      setAutoFunction(false);
    }
  }, [randomDaysSelected, shortcutContent?.visible]);

  return (
    <div>
      <Modal
        className={`shortcut-pliDays-modal ${
          isDarkMode && "shortcut-pliDays-modal-dark"
        }`}
        open={shortcutContent?.visible}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => {
          updateDocumentTitle(); // reset document title to "Lead Manager";
          setShortcutContent({ ...shortcutContent, visible: false });
          setAllDaysByDefault([]);
          setRandomDaysSelected([]);
        }}
        title={`Estimation ${
          shortcutContent?.content?.service?.estimationNumber || ""
        } - ${shortcutContent?.content?.service?.label} - ${
          shortcutContent?.content?.elevation?.elevationLabel
        } - ${shortcutContent?.content?.elevation?.elevationId}`}
        afterOpenChange={(event) => {
          event &&
            updateDocumentTitle({
              newTitle: `Estimation ${
                shortcutContent?.content?.service?.estimationNumber || ""
              } - ${shortcutContent?.content?.service?.label} - ${
                shortcutContent?.content?.elevation?.elevationLabel
              } - ${shortcutContent?.content?.elevation?.elevationId}`,
            });
        }}
        footer={
          <>
            <MondayButton
              onClick={() => {
                updateDocumentTitle(); // reset document title to "Lead Manager";
                setShortcutContent({ ...shortcutContent, visible: false });
                setAllDaysByDefault([]);
                setRandomDaysSelected([]);
              }}
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={() => {
                updatePlis();
              }}
              {...{
                Icon: <TickIcon width={17} height={17} />,
              }}
            >
              Save
            </MondayButton>
          </>
        }
        closeIcon={<XIcon />}
        centered
        width={1550}
      >
        <>
          <div className="mainContentShortcutDays">
            <div className="actionsShortcutDays">
              {disabledRows?.length > 0 && (
                <div>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Alert
                      message={
                        <div style={{}}>
                          <b style={{ color: "gray" }}>
                            For this elevation there are some pli-s that are
                            unable to select!
                          </b>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  </Space>
                </div>
              )}
              <MondayButton
                onClick={() => {
                  if (action === "add") return;
                  message.info("Switched to adding days action!");
                  setAction("add");
                }}
                {...{
                  Icon: <PlusIcon />,
                  className:
                    action === "remove"
                      ? `mondayButtonGrey${isDarkMode ? "Dark" : ""}`
                      : "",
                }}
              >
                Adding days
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (action === "remove") return;
                  message.info("Switched to removing days action!");
                  setAction("remove");
                  setAutoFunction(false);
                }}
                {...{
                  Icon: <XIcon />,
                  className:
                    action === "add"
                      ? `mondayButtonGrey${isDarkMode ? "Dark" : ""}`
                      : "mondayButtonRed",
                }}
              >
                Removing days
              </MondayButton>
              {action === "add" && !!shortcutContent?.content?.editMode && (
                <MondayButton
                  onClick={() => {
                    if (!!autoFunction) {
                      setAutoFunction(!autoFunction);
                    } else {
                      swalToast({
                        icon: "info",
                        title:
                          "This will autocomplete all selected pli-s based on the days you have selected and progress for each day will be the same depending on the pli dimension(s)!",
                        position: "bottom-end",
                        showConfirmButton: false,
                        timer: 6000,
                        timerProgressBar: true,
                      });
                    }
                    setAutoFunction(!autoFunction);
                  }}
                  {...{
                    Icon: <TickIcon />,
                    className: autoFunction
                      ? ""
                      : `mondayButtonGrey${isDarkMode ? "Dark" : ""}`,
                  }}
                >
                  Autocomplete pli(s)
                </MondayButton>
              )}
            </div>
            <div className="daysContainerShortcut">
              {shortcutContent?.content?.scheduleDays?.map((el) => {
                return (
                  <Tooltip
                    // placement="top"
                    title={dayjsNY(el?.startDate)
                      .startOf("day")
                      .format("MM/DD/YYYY")}
                  >
                    <Button
                      onClick={() => selectedDays(el)}
                      style={
                        randomDaysSelected?.some((q) => q?.id === el?.id)
                          ? {
                              backgroundColor: "#0f5c97",
                              color: "#fff",
                            }
                          : {}
                      }
                      className="shortcut-pliDays-buttons"
                    >
                      {"D" + el?.day}
                    </Button>
                  </Tooltip>
                );
              })}
              <Button
                onClick={() => {
                  setAllDaysByDefault(shortcutContent?.content?.scheduleDays);
                  setRandomDaysSelected([]);
                }}
                className="allDaysShortcutPLIButton"
                style={
                  allDaysByDefault?.length
                    ? {
                        background: "#0f5c97",
                        color: "#fff",
                      }
                    : {}
                }
              >
                All days
              </Button>
            </div>
            <div
              className={`documentationsTable ${
                isDarkMode
                  ? "dark-ag-theme ag-theme-alpine-dark"
                  : "light-ag-theme ag-theme-alpine"
              }`}
              style={{ width: "100%", height: "100%" }}
            >
              <AgGridReact
                {...{
                  ref: gridRef,
                  columnDefs: columnDefsForShortcutPliDays(
                    shortcutContent?.content?.service?.serviceId
                  ),
                  rowData: shortcutContent?.content?.elevation?.items,
                  getRowStyle: (params) => {
                    if (unablePli(params?.data)) {
                      return isDarkMode
                        ? { backgroundColor: "#12131b" }
                        : { backgroundColor: "#f8f8fa" };
                    }
                  },

                  animateRows: true,
                  onGridReady,
                  suppressDragLeaveHidesColumns: true,
                  suppressRowClickSelection: true,
                  rowSelection: "multiple",
                  isRowSelectable,
                  rowHeight: 32,
                  headerHeight: 32,
                  groupHeaderHeight: 32,
                  floatingFiltersHeight: 20,
                  pivotHeaderHeight: 32,
                  pivotGroupHeaderHeight: 32,
                  defaultColDef: {
                    resizable: true,
                    enablePivot: true,
                    enableColResize: true,
                    enableRowGroup: true,
                    editable: false,
                    sortable: true,
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                  },
                }}
              />
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default ShortcutForPliDays;
