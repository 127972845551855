import { months } from "../../../addWidgetsModalData";

export const invoices = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  invoicesData
) => {
  const invoicesStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    invoiceStatus: () => ({
      title: "Invoices - Invoice Status",
      BodyComponent: "LineChartFull",
      type: "LineChartFull",
      id: IdHandler,
      BodyComponentParams: {
        table: "Invoices",
        field: "invoiceStatus",
        labels: invoicesStatus?.statusName,
        datasets: [
          {
            label: "Invoices",
            data: invoicesStatus?.statusName?.map(
              (status) =>
                invoicesData?.filter((a) => a?.invoiceStatus === status)?.length
            ),
            backgroundColor: invoicesStatus?.statusColor,
            borderColor: invoicesStatus?.statusColor,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //invoiceStatus
    totalInvoiceAmount: () => ({
      title: "Invoices - Total Invoice Amount",
      BodyComponent: "LineChartFull",
      type: "LineChartFull",
      id: IdHandler,
      BodyComponentParams: {
        table: "Invoices",
        field: "totalInvoiceAmount",
        labels: months,
        custom: 0,
        datasets: [
          {
            label: "Total Invoice Amount",
            data: 0,
            backgroundColor: "#2DA365",
            borderColor: "#2DA365",
          },
          {
            label: "Total Invoice Without Tax",
            data: 0,
            backgroundColor: "#B5D99C",
            borderColor: "#B5D99C",
          },
          {
            label: "Total Tax",
            data: 0,
            backgroundColor: "#f04f4e",
            borderColor: "#f04f4e",
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //totalInvoiceAmount
  };
};
