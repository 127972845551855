import { Fragment, useEffect, useState } from "react";
import {
  validateAndSetChargeHoistState,
  validateAndSetChargeRentalState,
  validateAndSetChargeState,
  validateAndSetChargeRentalHoistState,
} from "../../utils/index";
import { useRedux } from "../../../../../../../hooks";
import { ChargeHeader, ChargeFooter, ChargeAgGrid } from "../";
import {
  validateAndSetChargeSOVHoistState,
  validateAndSetChargeSOVState,
} from "../../utils/validateAndSetChargeState";
import { useCreateChargeContext } from "../../context/NewChargeItemContext";

const ElevationAgGrid = ({ charge }) => {
  const [isWritable] = useRedux("chargesIsWritable");
  const [objectData] = useRedux("chargeItemsModal");

  const [percentage, setPercentage] = useState(objectData?.selectedPercentage);
  const [selectedAmount, setSelectedAmount] = useState();

  const { chargeToSave, setChargeToSave, initialCharge, selectedServices } =
    useCreateChargeContext();

  const categoryFrom = objectData?.categoryFrom;
  const currentCharge = objectData?.currentCharge;
  const selectedService = charge?.label;

  const isFromScheduleOfValue = categoryFrom === "Schedule Of Value";
  const isFromEstimation = categoryFrom === "Estimation";
  const isFromRental = categoryFrom === "Rental";
  const isHoist = charge?.label === "Hoist" || charge?.isHoist;

  const isSelected = selectedServices.find(
    (estimation) => estimation.estimationId === charge.estimationId
  )?.services?.[selectedService];

  const initialService = initialCharge?.services?.find((service) => {
    if (isFromScheduleOfValue) {
      return (
        service.label === selectedService &&
        service.estimationId === charge.estimationId
      );
    } else {
      return service.label === selectedService;
    }
  });

  useEffect(() => {
    if (!initialService) return;
    if (isFromEstimation) {
      if (isHoist) {
        validateAndSetChargeHoistState(
          charge,
          percentage,
          setChargeToSave,
          initialService
        );
      } else
        validateAndSetChargeState(
          charge,
          percentage,
          setChargeToSave,
          initialService
        );
    } else if (isFromRental) {
      if (isHoist) {
        validateAndSetChargeRentalHoistState(
          charge,
          percentage,
          setChargeToSave,
          initialService,
          initialCharge.projectTaxRate
        );
      } else
        validateAndSetChargeRentalState(
          charge,
          percentage,
          setChargeToSave,
          initialService,
          initialCharge.projectTaxRate
        );
    } else if (isFromScheduleOfValue) {
      if (isHoist) {
        validateAndSetChargeSOVHoistState(
          charge,
          percentage,
          setChargeToSave,
          initialService,
          initialCharge.projectTaxRate
        );
      } else {
        validateAndSetChargeSOVState(
          charge,
          percentage,
          setChargeToSave,
          initialService,
          initialCharge.projectTaxRate
        );
      }
    }
  }, [percentage, initialService]);

  let serviceOptions = charge?.serviceOptions || [];
  if (isFromEstimation) {
    serviceOptions = charge?.serviceOptions?.[0] || [];
  }

  if (isFromScheduleOfValue) {
    if (isHoist) serviceOptions = charge?.serviceOptions?.[0] || [];
    else serviceOptions = [charge?.amounts] || [];
  }

  const generateRowData = (service) => {
    if (isHoist && isFromEstimation) return [service];

    if (isFromScheduleOfValue) {
      if (isHoist) return service.amounts;
      else return service;
    }

    return service.items;
  };

  return (
    <div
      className="ag-theme-alpine agGridCustomStyle"
      style={{ display: isSelected ? "block" : "none" }}
    >
      {charge &&
        serviceOptions?.length > 0 &&
        serviceOptions?.map((service, index) => {
          if (!service) return null;
          return (
            <Fragment key={index}>
              <div>
                <ChargeHeader
                  {...{
                    rowData: service,
                    selectedService,
                    charge,
                    percentage,
                    setPercentage,
                    currentCharge,
                    isWritable,
                    selectedAmount,
                    setSelectedAmount,
                  }}
                />
                <ChargeAgGrid
                  {...{
                    charge,
                    rowData: generateRowData(service),
                    initialService,
                    chargeToSave,
                    setChargeToSave,
                    objectData,
                    currentCharge,
                    selectedService,
                    selectedElevation: service.elevationLabel,
                    selectedElevationId: service.elevationId,
                    percentage,
                  }}
                />
                <ChargeFooter {...{ rowData: service, charge, objectData }} />
              </div>
              {service.elevationAddons &&
                service.elevationAddons.length > 0 &&
                !isFromScheduleOfValue && (
                  <>
                    <ChargeHeader
                      {...{
                        rowData: service.elevationAddons,
                        selectedService,
                        charge,
                        percentage,
                        setPercentage,
                        currentCharge,
                        type: "Elevation Addons",
                      }}
                    />
                    <ChargeAgGrid
                      {...{
                        charge,
                        rowData: service.elevationAddons,
                        initialService,
                        chargeToSave,
                        setChargeToSave,
                        objectData,
                        currentCharge,
                        selectedService,
                        percentage,
                        type: "Elevation Addons",
                      }}
                    />
                    <ChargeFooter
                      {...{
                        rowData: service.elevationAddons,
                        charge,
                        type: "Elevation Addons",
                      }}
                    />
                  </>
                )}
            </Fragment>
          );
        })}

      {charge &&
        charge?.serviceAddons?.length > 0 &&
        !isFromScheduleOfValue && (
          <>
            <ChargeHeader
              {...{
                selectedService,
                charge,
                percentage,
                setPercentage,
                currentCharge,
                type: "Service Addons",
              }}
            />
            <ChargeAgGrid
              {...{
                rowData: charge.serviceAddons,
                chargeToSave,
                setChargeToSave,
                charge,
                initialService,
                objectData,
                currentCharge,
                selectedService,
                percentage,
                type: "Service Addons",
              }}
            />
            <ChargeFooter
              {...{
                rowData: charge.serviceAddons,
                objectData,
                type: "Service Addons",
              }}
            />
          </>
        )}
      {charge?.additionalRentalTerms &&
        charge?.additionalRentalTerms?.newPrice &&
        Object.keys(charge?.additionalRentalTerms)?.length > 0 &&
        !isFromScheduleOfValue && (
          <>
            <ChargeHeader
              {...{
                selectedService,
                charge,
                percentage,
                setPercentage,
                currentCharge,
                type: "Additional Rental",
              }}
            />
            <ChargeAgGrid
              {...{
                rowData: [charge.additionalRentalTerms],
                chargeToSave,
                setChargeToSave,
                charge,
                initialService,
                objectData,
                currentCharge,
                selectedService,
                percentage,
                type: "Additional Rental",
              }}
            />
            <ChargeFooter
              {...{
                rowData: [charge.additionalRentalTerms],
                objectData,
                type: "Additional Rental",
              }}
            />
          </>
        )}
    </div>
  );
};

export default ElevationAgGrid;
