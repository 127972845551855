import moment from "moment-timezone";
import { groupBy } from "../../../../../../../../../../Accounting/components/utilities";
import { getNYDate } from "../../../../../../utils/dateFunctions";
import { checkIfServiceIsHoist } from "../../../../../../../../../../Estimations/DataEntryGrid/models/Service";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { calculateTotalsReqs } from "../../totalities";
moment.tz.setDefault("America/New_York");

/**
 *
 * @param {Object} where fakeApplication is the current unsaved Application data we are dealing with
 * and lastRequisition is the previos requisition of the application
 * @returns
 */
export const exportToExcelData = ({
  fakeApplication,
  lastRequisition,
  othersRentalData,
}) => {
  // First, we need to check whether the Retaianage has been reduced and get the current Payment amount
  // This is done by using This Amount (when all services are not all completed) - Retainage
  // In the case where all services are completed, we use the Total Amount of Retaianage for That period
  // Until Retaianage becomes 0.
  console.log("fakeApplication", fakeApplication);
  const { result, newRes, currentPaymentDue } = getCurrentPayment({
    prev: lastRequisition,
    next: fakeApplication,
    othersRentalData,
  });

  const {
    CURRENT_PAYMENT,
    CURRENT_RETAINAGE,
    ACCUMULATED_RETAINAGE,
    THIS_PERIOD,
  } = calculateTotalsReqs(fakeApplication);

  // Object we are sending in backend to process the excel data
  const workSheet1DataExcel = {
    periodTo: getNYDate(fakeApplication?.periodTo?.end).formatted,
    createdAt: fakeApplication?.applicationDateXlsx
      ? getNYDate(fakeApplication?.applicationDateXlsx).formatted
      : getNYDate(fakeApplication?.createdAt).formatted,
    applicationNo: fakeApplication?.applicationNo,
    amount: fakeApplication?.services?.reduce(
      (acc, curr) => acc + curr?.totalPrice,
      0
    ),
    dueDate: getNYDate(fakeApplication?.dueDate).formatted,
    accountNameAddress: fakeApplication?.accountNameAddress,
    isRetainageRedacted: result,
    retaiangePercentage: newRes,
    prevTotalLessRetainage: fakeApplication?.prevTotalLessRetainage || 0,
    currentPaymentDue: CURRENT_PAYMENT,
  };
  //sheet 2 original data
  const workSheet2DataExcel = fakeApplication?.services
    ?.filter(({ isChangeOrder }) => !isChangeOrder)
    ?.flatMap(
      (
        {
          amounts,
          label,
          retainage,
          isChangeOrder,
          serviceOptions,
          rentalPaymentTerms,
          totalPrice,
          rent,
          isScope = false,
        },
        idx,
        arr
      ) => {
        let isHoist = checkIfServiceIsHoist(arr[idx]);
        // console.log("rentalPaymentTerms", rentalPaymentTerms);
        return isHoist
          ? serviceOptions[0]?.map(
              ({
                elevationLabel,
                amounts: hoistAmounts,
                totalPrice: hoistTotalPrice,
                rent: hoistRent,
              }) => ({
                label: `${label} ${elevationLabel}`,
                amounts: hoistAmounts,
                isChangeOrder,
                retainage,
                isScope,
                range: rentalPaymentTerms?.range || 0,
                description:
                  rentalPaymentTerms?.description?.replace(/<[^>]+>/g, "") ||
                  "",
                rent: (hoistTotalPrice * hoistRent) / 100 || 0,
              })
            )
          : {
              isChangeOrder,
              retainage,
              amounts,
              label,
              isScope,
              range: rentalPaymentTerms?.range || 0,
              rent: (totalPrice * rent) / 100 || 0,
              description:
                rentalPaymentTerms?.description?.replace(/<[^>]+>/g, "") || "",
            };
      }
    );

  //sheet 2 change order data
  const workSheet2ChangeOrder = fakeApplication?.services
    ?.filter(({ isChangeOrder }) => isChangeOrder)
    ?.flatMap(
      (
        {
          amounts,
          label,
          retainage,
          isChangeOrder,
          serviceOptions,
          isScope = false,
        },
        idx,
        arr
      ) => {
        const isHoist = checkIfServiceIsHoist(arr[idx]);
        return isHoist
          ? serviceOptions[0]?.map(
              ({ elevationLabel, amounts: hoistAmounts }) => ({
                label: `${label} ${elevationLabel}`,
                amounts: hoistAmounts,
                isChangeOrder,
                retainage,
                isScope,
              })
            )
          : {
              isChangeOrder,
              retainage,
              amounts,
              label,
              isScope,
            };
      }
    );

  return { workSheet1DataExcel, workSheet2DataExcel, workSheet2ChangeOrder };
};

/**
 * This function divides each Rental data, grouped by Service in monthly periods taking into consideration tax Rate, Retaianage, and Credit
 * @param {Object}
 * @returns
 */

const divideInFourWeeks = ({
  startDate,
  endDate,
  allDays,
  label,
  accumulator = [],
  month = 1,
  taxRate = 0,
  retainage = 0,
  serviceLevelCredit = 0,
  serviceApplicables = {},
}) => {
  // console.log("serviceApplicables", serviceApplicables);

  if (!startDate || !endDate) return;
  const calculatedStartDate = moment(startDate)
    ?.add(month === 1 ? 0 : 28, "days")
    ?.startOf("day")
    ?.valueOf();

  const calculatedEndDate = moment(calculatedStartDate)
    ?.add(28, "days")
    .startOf("day")
    .valueOf();

  const filteredDays = allDays
    ?.map((aD) => ({ ...aD, day: moment(aD.day).add(7, "hours").valueOf() }))
    .filter(
      ({ day }) =>
        moment(day).startOf("day").valueOf() >= calculatedStartDate &&
        moment(day).startOf("day").valueOf() <= calculatedEndDate
    );
  let percentages =
    filteredDays?.reduce((acc, curr) => {
      return acc + curr?.percentage;
    }, 0) / filteredDays?.length;
  // console.log("lets test ", {
  //   calculatedStartDate: moment(calculatedStartDate).format("MM/DD/YYYY HH:mm"),
  //   calculatedEndDate: moment(calculatedEndDate).format("MM/DD/YYYY HH:mm"),
  //   filteredDays: filteredDays.map((el) => ({
  //     day: moment(el.day).format("MM/DD/YYYY || HH:mm"),
  //   })),
  //   allDays: allDays.map((el) => ({
  //     day: moment(el.day).format("MM/DD/YYYY || HH:mm"),
  //   })),
  //   percentages,
  // });
  const amountForThisMonth = +filteredDays
    ?.reduce((acc, { dailyAmount }) => acc + dailyAmount, 0)
    ?.toFixed(2);

  accumulator = [
    ...accumulator,
    {
      label,
      month,
      monthStart: calculatedStartDate,
      monthEnd: calculatedEndDate,
      days: filteredDays,
      amountForThisMonth: amountForThisMonth,
      taxRate: taxRate,
      retainage,
      serviceLevelCredit,
      serviceApplicables,
    },
  ];

  return moment(calculatedEndDate)?.startOf("day")?.valueOf() <
    moment(endDate)?.startOf("day")?.valueOf()
    ? divideInFourWeeks({
        startDate: calculatedStartDate,
        month: month + 1,
        accumulator,
        endDate,
        allDays,
        label,
        taxRate,
        retainage,
        serviceLevelCredit,
        serviceApplicables,
      })
    : accumulator;
};

//here we extract the label and the total price from rentals that we selected
export const rentalDataExtractor = ({ fakeApplication }) => {
  let assumedApp = fakeApplication;
  if (
    !!assumedApp?.chainedRentalsIds &&
    Array.isArray(assumedApp?.chainedRentalsIds)
  ) {
    assumedApp.includedRentals = [
      ...(assumedApp?.includedRentals || []),
      ...(assumedApp?.chainedRentalsIds || []),
    ];
  }
  let result = fakeApplication?.includedRentals
    ?.flatMap(({ services, rentalNumber = 0, rentalId }) => [
      ...(services?.map((el) => ({ ...el, rentalNumber, rentalId })) || []),
    ])
    ?.flatMap((serviceData) => {
      let isProrated = false;
      let serviceApplicables = { rent: 0, appliedRent: 0 };
      const allDays = serviceData?.serviceOptions
        ?.map(({ items, elevationLabel, elevationId }) => {
          return items?.map(
            ({ rentalDetails, appliedAmount = 0, rent = 0, id }) => {
              // percentages?.[serviceData.label] =
              let thisRent = isNaN(parseFloat(rent)) ? 0 : parseFloat(rent);
              serviceApplicables.rent += thisRent;
              serviceApplicables.maxDaily = serviceApplicables.rent / 27;
              serviceApplicables.appliedRent += isNaN(parseFloat(appliedAmount))
                ? 0
                : parseFloat(appliedAmount);
              let tempWeek = 0;
              return rentalDetails?.details?.map(({ days, week }) => {
                // console.log("days", days);
                let firstDay = days[0]?.dailyAmount;
                if (tempWeek < 4) {
                  tempWeek++;
                } else {
                  tempWeek = 0;
                }

                if (!isProrated) {
                  let isDailyProRated = days.some(
                    (el) => el?.dailyAmount?.toFixed(2) !== firstDay?.toFixed(2)
                  );
                  let isWeeklyProRated = rentalDetails?.details?.some(
                    (t) =>
                      t?.weeklyAmount?.toFixed(2) !==
                      rentalDetails?.details[0]?.weeklyAmount?.toFixed(2)
                  );
                  // isProrated = checkProRated({ details: rentalDetails?.details, week });
                  isProrated = isDailyProRated || isWeeklyProRated;

                  // console.log("is ProRated", {
                  //   isDailyProRated,
                  //   isWeeklyProRated,
                  //   isProrated,
                  //   firstDay,
                  //   rentalDetails,
                  // });
                }
                return days.map((dayEl) => ({
                  ...dayEl,
                  elevationLabel: `${serviceData?.label}:${elevationLabel}:${elevationId}:${id}`,
                  percentage: Number(
                    (dayEl?.dailyAmount / (thisRent / 28)) * 100
                  ),
                  serviceLevelCredit: serviceData?.serviceLevelCredit,
                  isProrated:
                    dayEl?.dailyAmount?.toFixed(2) !== firstDay?.toFixed(2),
                }));
              });
            }
          );
        })
        ?.flat(3);

      // We call another function in order to categorize all Days, grouped by Service, Elevation Label, Id, Item Id
      // In order to display it for each service in Excel
      const determined = determineMonthlyDays({
        startDate: serviceData?.startDate,
        endDate: serviceData?.endDate,
        allDays,
        label: serviceData?.label,
        taxRate: serviceData?.taxRate,
        retainage: serviceData?.retainage,
        serviceLevelCredit: serviceData?.serviceLevelCredit,
        serviceApplicables,
        isProrated,
      })?.map((el) => ({
        ...el,
        rentalNumber: serviceData?.rentalNumber || 0,
        rentalId: serviceData?.rentalId || 0,
      }));

      return determined;
    });
  // console.log("checkproooo", checkProRatedForAll({ result }));
  // After having categorized all days, we call another function to determine whether they are prorated or not
  return checkProRatedForAll({ result });
};

// Used to redirect to a new page (Rental)
export const redirectToRental = ({ rentalId }) => {
  window.open(`/rentals/${rentalId}`, "_blank");
};

// Used to categorize All days in certain groups (months)
export const determineMonthlyDays = ({
  startDate,
  endDate,
  allDays = [],
  label = "",
  taxRate = 0,
  retainage = 0,
  serviceLevelCredit = 0,
  serviceApplicables = {},
  isProrated = false,
}) => {
  let tempServiceLevelCredit = serviceLevelCredit;
  let result = [];
  let month = 1;
  const toBeFiltered = allDays?.map((aD) => ({
    ...aD,
    day: moment(aD.day).add(7, "hours").startOf("day").valueOf(),
  }));
  let leftDays = toBeFiltered;
  // console.log("to be filtered", toBeFiltered);
  // If there are no dates or no days, return an empty array
  if (!startDate || !endDate) return [];

  let howManyDays = moment(endDate).diff(moment(startDate), "days", true);
  let howManyMonths = Math.ceil(howManyDays / 28);
  let interval = {
    start: moment(startDate).startOf("day").valueOf(),
    end: moment(startDate).add(27, "days").valueOf(),
  };
  [...Array(howManyMonths)].forEach((_, index) => {
    interval.start =
      index !== 0
        ? moment(interval.end).add(1, "day").valueOf()
        : interval.start;
    interval.end =
      index !== 0
        ? moment(interval.start).add(27, "days").valueOf()
        : interval.end;

    let currentDays = toBeFiltered?.filter(
      ({ day }) =>
        day >= moment(interval.start).startOf("day").valueOf() &&
        day <= moment(interval.end).endOf("day").valueOf()
    );
    leftDays = leftDays?.filter(
      ({ day }) => currentDays.find((el) => el.day === day) === undefined
    );

    const amountForThisMonth = +currentDays?.reduce(
      (acc, { dailyAmount }) => acc + dailyAmount,
      0
    );
    // ?.toFixed(2);

    // moment iso format

    // console.log("amountForThisMonth", { amountForThisMonth, currentDays });
    result.push({
      label,
      month: month + index,
      monthStart: interval.start,
      monthEnd: interval.end,
      days: currentDays,
      amountForThisMonth: amountForThisMonth,
      taxRate: taxRate,
      retainage,
      serviceLevelCredit:
        index === howManyMonths - 1 ? tempServiceLevelCredit : 0,
      serviceApplicables,
      isProrated: currentDays?.some((el) => el?.isProrated) || false,
    });
  });

  return result;
};

export const getCurrentPaymentChanged = ({
  prev: lastRequisition,
  next: fakeApplication,
  othersRentalData,
  otherReqs,
}) => {
  let currentPaymentDue = 0;
  let currentPaymentDueIfAllServicesArePaid = 0;
  let allCompleted = [];
  let thisPeriodRetainage = 0;
  let thisRetaianageIfAllServicesArePaid = 0;
  let collectable_amount = 0;
  let retPercentages = [];
  // New Logic Formula followup
  // TotalEarnedLessRetaiange - Line 6 will give currentPaymentDue
  let totalCompletedAndStoredToDate = 0;
  let retaiangeForAll = 0;
  let previousLine6 = Number(fakeApplication?.prevTotalLessRetainage || 0);

  // For Each Service, the retainage is calculated along with the total amount of the service
  // as well as Total completed and Stored
  // console.log("lastRequisition", lastRequisition);
  fakeApplication?.services?.forEach((service) => {
    if (!checkIfServiceIsHoist(service)) {
      let isAllCompleted = service?.amounts?.every(
        (el) => el?.percentageCompleated === 100
      );
      allCompleted.push(isAllCompleted);
      service?.amounts?.forEach((amount) => {
        retPercentages.push(amount?.percentageCompleated);
        collectable_amount += Math.abs(amount?.collectable_amount);
        retaiangeForAll += Number(amount?.keptRetainage || 0);
        console.log("retaiangeForAll1", retaiangeForAll);
        totalCompletedAndStoredToDate += Number(amount?.totalCompleated || 0);
        let tempRet =
          (amount?.retainagePercentage / 100) * amount?.thisPeriod || 0;

        let tempNow = amount?.thisPeriod || 0;
        if (tempNow !== 0 && !isNaN(tempNow)) {
          currentPaymentDue += tempNow - tempRet;
          allCompleted.push(false);
          thisPeriodRetainage += tempRet;
        } else if (isAllCompleted) {
          let thisRet =
            ((amount?.retainagePercentage || 0) / 100) *
              amount?.totalCompleated || 0;
          currentPaymentDueIfAllServicesArePaid += thisRet;
          thisRetaianageIfAllServicesArePaid += thisRet;
        }
      });
    } else {
      service?.serviceOptions[0]?.forEach((option) => {
        let isAllCompleted = option?.amounts?.every(
          (el) => el?.percentageCompleated === 100
        );
        allCompleted.push(isAllCompleted);
        option?.amounts?.forEach((amount) => {
          retPercentages.push(amount?.percentageCompleated);
          totalCompletedAndStoredToDate += Number(amount?.totalCompleated || 0);
          retaiangeForAll += forceToNumber(amount?.keptRetainage || 0);
          console.log("retaiangeForAll2", retaiangeForAll);

          let tempRet =
            ((service?.retainage || 0) / 100) * amount?.thisPeriod || 0;
          let tempNow = amount?.thisPeriod || 0;
          if (tempNow !== 0 && !isNaN(tempNow)) {
            currentPaymentDue += tempNow - tempRet;
            allCompleted.push(false);
            thisPeriodRetainage += tempRet;
          } else if (isAllCompleted) {
            currentPaymentDueIfAllServicesArePaid +=
              ((service?.retainage || 0) / 100) * amount?.totalCompleated || 0;
            thisRetaianageIfAllServicesArePaid +=
              ((service?.retainage || 0) / 100) * amount?.totalCompleated;
          }
        });
      });
    }
  });

  otherReqs?.forEach((req) => {
    req?.services?.forEach((service) => {
      if (!checkIfServiceIsHoist(service)) {
        service?.amounts?.forEach((amount) => {
          retaiangeForAll +=
            Number(amount?.keptRetainage || 0) -
            forceToNumber(amount?.reducedAmount || 0);
          console.log("retaiangeForAll3", retaiangeForAll);
        });
      } else {
        service?.serviceOptions?.[0]?.forEach((option) => {
          option?.amounts?.forEach((amount) => {
            retaiangeForAll +=
              forceToNumber(amount?.keptRetainage || 0) -
              forceToNumber(amount?.reducedAmount || 0);
          });
          console.log("retaiangeForAll4", retaiangeForAll);
        });
      }
    });
  });

  // Do the same thing for Included Rentals in Previous Application
  let rentals = fakeApplication?.includedRentals || [];
  let previousRentals = othersRentalData || [];
  let retainageAmountTotal = 0;

  // console.log("previousRentals", previousRentals);

  // console.log("thisPeriodRetainage1", thisPeriodRetainage);
  // Checking Current Rentals and Calculating Amounts (Applied Amount, Retaiange, Credit and Applied Tax)

  for (const rental of rentals) {
    let rentalServices = rental?.services || [];
    for (const rentalService of rentalServices) {
      const {
        appliedAmount = 0,
        retainage = 0,
        taxRate = 0,
        serviceLevelCredit = 0,
      } = rentalService;
      // console.log("rental service", rentalService);
      const amountPlusTax = appliedAmount + appliedAmount * taxRate;
      const positiveCredit =
        Math.abs(serviceLevelCredit) + Math.abs(serviceLevelCredit * taxRate) ||
        0;
      const positiveCreditRetainage = (positiveCredit * retainage) / 100;
      const creditAndRetainage = positiveCredit;
      let retainageAmount = (amountPlusTax * retainage) / 100;
      retaiangeForAll += retainageAmount - positiveCreditRetainage || 0;
      retainageAmount = retainageAmount + (taxRate * retainageAmount) / 100;
      const temp = amountPlusTax + retainageAmount - creditAndRetainage;
      const taxAmount = appliedAmount * taxRate;
      retainageAmountTotal = 0;
      retainageAmountTotal += retainageAmount;
      retainageAmountTotal -= (positiveCredit * retainage) / 100;
      thisPeriodRetainage += retainageAmountTotal;
      // console.log("thisPeriodRetainage2", {
      //   thisPeriodRetainage,
      //   retainageAmount,
      // });
      currentPaymentDue += temp;
      totalCompletedAndStoredToDate +=
        appliedAmount + taxAmount - positiveCredit;
      thisRetaianageIfAllServicesArePaid += appliedAmount + taxAmount;
    }
  }
  const overAllCompletion =
    rentals?.length > 0 ? false : allCompleted?.every((el) => el === true);

  // Checking Previous Rentals and Calculating Amounts (Applied Amount, Retaiange, Credit and Applied Tax)
  if (previousRentals?.length > 0) {
    for (const rental of previousRentals) {
      let appliedAmount = rental?.amountForThisMonth || 0;

      let retainage =
        fakeApplication?.totalities?.prevRentalsRetainage?.[
          `Rental${rental?.rentalNumber}`
        ] ??
        (rental?.retainage || 0);
      // : rental?.retainage || 0;
      // console.log("myyy777", retainage);
      let taxRate = rental?.taxRate || 0;
      let serviceLevelCredit = rental?.serviceLevelCredit || 0;
      const amountPlusTax = Number(
        (appliedAmount + appliedAmount * taxRate)?.toFixed(2) || 0
      );
      const positiveCredit =
        Math.abs(serviceLevelCredit) + Math.abs(serviceLevelCredit * taxRate);
      const positiveCreditRetainage = (positiveCredit * retainage) / 100;
      const taxAmount = appliedAmount * taxRate;
      const creditAndRetainage = positiveCredit;
      let retainageAmount = (amountPlusTax * retainage) / 100;
      retaiangeForAll += Number(retainageAmount) - positiveCreditRetainage;
      retainageAmount =
        retainageAmount +
        (Number(taxRate * retainageAmount)?.toFixed(2) || 0) / 100;
      // const temp = amountPlusTax + retainageAmount - creditAndRetainage;
      const temp = retainageAmount;
      retainageAmountTotal = 0;
      retainageAmountTotal += retainageAmount;
      retainageAmountTotal -= (positiveCredit * retainage) / 100;
      thisRetaianageIfAllServicesArePaid += appliedAmount + taxAmount;
      totalCompletedAndStoredToDate +=
        appliedAmount + taxAmount - positiveCredit;
      if (overAllCompletion) {
        currentPaymentDueIfAllServicesArePaid += retainageAmount;
      } else {
        currentPaymentDue += temp;
      }
    }
  }
  // console.log("tempResss", {
  //   totalCompletedAndStoredToDate,
  //   retaiangeForAll,
  //   previousLine6,
  // });
  console.log("previousLine6", {
    previousLine6,
    totalCompletedAndStoredToDate,
    retaiangeForAll,
  });
  const tempRes = {
    currentPaymentDue:
      Number(totalCompletedAndStoredToDate?.toFixed(2) || 0) -
      Number(retaiangeForAll || 0) -
      Number(previousLine6.toFixed(2) || 0),
    // thisPeriodRetainage: retaiangeForAll,
    currentPaymentDue2: overAllCompletion
      ? currentPaymentDueIfAllServicesArePaid
      : currentPaymentDue,
    // thisPeriodRetainage: overAllCompletion
    //   ? thisRetaianageIfAllServicesArePaid
    //   : thisPeriodRetainage,
    thisPeriodRetainage,
    retaiangeForAll,
    lastRetainage: Number(lastRequisition?.totalities?.totalRetainage || 0),
  };
  // console.log("tempRes", {
  //   totalCompletedAndStoredToDate,
  //   retaiangeForAll,
  //   previousLine6,
  //   tempRes,
  // });

  return tempRes;
};

export const getCurrentPayment = ({
  prev: lastRequisition,
  next: fakeApplication,
  othersRentalData,
}) => {
  let currentPaymentDue = 0;
  let currentPaymentDueIfAllServicesArePaid = 0;
  let allCompleted = [];
  let thisPeriodRetainage = 0;
  let thisRetaianageIfAllServicesArePaid = 0;
  let collectable_amount = 0;
  let retPercentages = [];
  // New Logic Formula followup
  // TotalEarnedLessRetaiange - Line 6 will give currentPaymentDue
  let totalCompletedAndStoredToDate = 0;
  let retaiangeForAll = 0;
  let previousLine6 = Number(fakeApplication?.prevTotalLessRetainage || 0);

  // For Each Service, the retainage is calculated along with the total amount of the service
  // as well as Total completed and Stored
  // console.log("lastRequisition", lastRequisition);
  fakeApplication?.services?.forEach((service) => {
    if (!checkIfServiceIsHoist(service)) {
      let isAllCompleted = service?.amounts?.every(
        (el) => el?.percentageCompleated === 100
      );
      allCompleted.push(isAllCompleted);
      service?.amounts?.forEach((amount) => {
        retPercentages.push(amount?.percentageCompleated);
        collectable_amount += Math.abs(amount?.collectable_amount);
        retaiangeForAll += Number(
          (amount?.retainagePercentage / 100) *
            Number(amount?.totalCompleated || 0) || 0
        );
        totalCompletedAndStoredToDate += Number(amount?.totalCompleated || 0);
        let tempRet =
          (amount?.retainagePercentage / 100) * amount?.thisPeriod || 0;

        let tempNow = amount?.thisPeriod || 0;
        if (tempNow !== 0 && !isNaN(tempNow)) {
          currentPaymentDue += tempNow - tempRet;
          allCompleted.push(false);
          thisPeriodRetainage += tempRet;
        } else if (isAllCompleted) {
          let thisRet =
            ((amount?.retainagePercentage || 0) / 100) *
              amount?.totalCompleated || 0;
          currentPaymentDueIfAllServicesArePaid += thisRet;
          thisRetaianageIfAllServicesArePaid += thisRet;
        }
      });
    } else {
      service?.serviceOptions[0]?.forEach((option) => {
        let isAllCompleted = option?.amounts?.every(
          (el) => el?.percentageCompleated === 100
        );
        allCompleted.push(isAllCompleted);
        option?.amounts?.forEach((amount) => {
          retPercentages.push(amount?.percentageCompleated);
          totalCompletedAndStoredToDate += Number(amount?.totalCompleated || 0);
          retaiangeForAll += Number(
            (service?.retainage / 100) * Number(amount?.totalCompleated || 0) ||
              0
          );

          let tempRet =
            ((service?.retainage || 0) / 100) * amount?.thisPeriod || 0;
          let tempNow = amount?.thisPeriod || 0;
          if (tempNow !== 0 && !isNaN(tempNow)) {
            currentPaymentDue += tempNow - tempRet;
            allCompleted.push(false);
            thisPeriodRetainage += tempRet;
          } else if (isAllCompleted) {
            currentPaymentDueIfAllServicesArePaid +=
              ((service?.retainage || 0) / 100) * amount?.totalCompleated || 0;
            thisRetaianageIfAllServicesArePaid +=
              ((service?.retainage || 0) / 100) * amount?.totalCompleated;
          }
        });
      });
    }
  });

  // Do the same thing for Included Rentals in Previous Application
  let rentals = fakeApplication?.includedRentals || [];
  let previousRentals = othersRentalData || [];
  let retainageAmountTotal = 0;

  // console.log("previousRentals", previousRentals);

  // console.log("thisPeriodRetainage1", thisPeriodRetainage);
  // Checking Current Rentals and Calculating Amounts (Applied Amount, Retaiange, Credit and Applied Tax)

  for (const rental of rentals) {
    let rentalServices = rental?.services || [];
    for (const rentalService of rentalServices) {
      const {
        appliedAmount = 0,
        retainage = 0,
        taxRate = 0,
        serviceLevelCredit = 0,
      } = rentalService;
      // console.log("rental service", rentalService);
      const amountPlusTax = appliedAmount + appliedAmount * taxRate;
      const positiveCredit =
        Math.abs(serviceLevelCredit) + Math.abs(serviceLevelCredit * taxRate) ||
        0;
      const positiveCreditRetainage = (positiveCredit * retainage) / 100;
      const creditAndRetainage = positiveCredit;
      let retainageAmount = (amountPlusTax * retainage) / 100;
      retaiangeForAll += retainageAmount - positiveCreditRetainage || 0;
      retainageAmount = retainageAmount + (taxRate * retainageAmount) / 100;
      const temp = amountPlusTax + retainageAmount - creditAndRetainage;
      const taxAmount = appliedAmount * taxRate;
      retainageAmountTotal = 0;
      retainageAmountTotal += retainageAmount;
      retainageAmountTotal -= (positiveCredit * retainage) / 100;
      thisPeriodRetainage += retainageAmountTotal;
      // console.log("thisPeriodRetainage2", {
      //   thisPeriodRetainage,
      //   retainageAmount,
      // });
      currentPaymentDue += temp;
      totalCompletedAndStoredToDate +=
        appliedAmount + taxAmount - positiveCredit;
      thisRetaianageIfAllServicesArePaid += appliedAmount + taxAmount;
    }
  }
  const overAllCompletion =
    rentals?.length > 0 ? false : allCompleted?.every((el) => el === true);

  // Checking Previous Rentals and Calculating Amounts (Applied Amount, Retaiange, Credit and Applied Tax)
  if (previousRentals?.length > 0) {
    for (const rental of previousRentals) {
      let appliedAmount = rental?.amountForThisMonth || 0;

      let retainage =
        fakeApplication?.totalities?.prevRentalsRetainage?.[
          `Rental${rental?.rentalNumber}`
        ] ??
        (rental?.retainage || 0);
      // : rental?.retainage || 0;
      // console.log("myyy777", retainage);
      let taxRate = rental?.taxRate || 0;
      let serviceLevelCredit = rental?.serviceLevelCredit || 0;
      const amountPlusTax = Number(
        (appliedAmount + appliedAmount * taxRate)?.toFixed(2) || 0
      );
      const positiveCredit =
        Math.abs(serviceLevelCredit) + Math.abs(serviceLevelCredit * taxRate);
      const positiveCreditRetainage = (positiveCredit * retainage) / 100;
      const taxAmount = appliedAmount * taxRate;
      const creditAndRetainage = positiveCredit;
      let retainageAmount = (amountPlusTax * retainage) / 100;
      retaiangeForAll += Number(retainageAmount) - positiveCreditRetainage;
      retainageAmount =
        retainageAmount +
        (Number(taxRate * retainageAmount)?.toFixed(2) || 0) / 100;
      // const temp = amountPlusTax + retainageAmount - creditAndRetainage;
      const temp = retainageAmount;
      retainageAmountTotal = 0;
      retainageAmountTotal += retainageAmount;
      retainageAmountTotal -= (positiveCredit * retainage) / 100;
      thisRetaianageIfAllServicesArePaid += appliedAmount + taxAmount;
      totalCompletedAndStoredToDate +=
        appliedAmount + taxAmount - positiveCredit;
      if (overAllCompletion) {
        currentPaymentDueIfAllServicesArePaid += retainageAmount;
      } else {
        currentPaymentDue += temp;
      }
    }
  }
  // console.log("tempResss", {
  //   totalCompletedAndStoredToDate,
  //   retaiangeForAll,
  //   previousLine6,
  // });
  console.log("previousLine6", {
    previousLine6,
    totalCompletedAndStoredToDate,
    retaiangeForAll,
  });
  const tempRes = {
    currentPaymentDue:
      Number(totalCompletedAndStoredToDate?.toFixed(2) || 0) -
      Number(retaiangeForAll || 0) -
      Number(previousLine6.toFixed(2) || 0),
    // thisPeriodRetainage: retaiangeForAll,
    currentPaymentDue2: overAllCompletion
      ? currentPaymentDueIfAllServicesArePaid
      : currentPaymentDue,
    // thisPeriodRetainage: overAllCompletion
    //   ? thisRetaianageIfAllServicesArePaid
    //   : thisPeriodRetainage,
    thisPeriodRetainage,
    retaiangeForAll,
    lastRetainage: Number(lastRequisition?.totalities?.totalRetainage || 0),
  };
  // console.log("tempRes", {
  //   totalCompletedAndStoredToDate,
  //   retaiangeForAll,
  //   previousLine6,
  //   tempRes,
  // });

  return tempRes;
};

const checkProRated = ({ details, week }) => {
  const toBeFiltered = details?.map(({ days, weeklyAmount }) => {
    return days?.map((day) => {
      return {
        ...day,
        day: moment(day.day).add(7, "hours").startOf("day").valueOf(),
        weeklyAmount,
      };
    });
  });
  const months = Math.ceil(toBeFiltered.length / 4);
  let sorted = toBeFiltered?.flatMap((el) => el)?.sort((a, b) => a.day - b.day);

  for (let i = 0; i <= months; i++) {
    let startDate = moment(sorted[0]?.day)
      .add(i * 28, "days")
      .startOf("month")
      .valueOf();
    let endDate = moment(startDate).add(28, "days").endOf("month").valueOf();
    let thisMonth = sorted?.filter(
      (el) => el.day >= startDate && el.day <= endDate
    );
  }

  // If there are no dates or no days, return an empty array
  // if (!startDate || !endDate) return [];

  // let howManyDays = moment(endDate).diff(moment(startDate), "days", true);
  // let howManyMonths = Math.ceil(howManyDays / 28);
  // let interval = {
  // 	start: moment(startDate).startOf("day").valueOf(),
  // 	end: moment(startDate).add(27, "days").valueOf(),
  // };
};

const checkProRatedForAll = ({ result = [] }) => {
  return result?.map((el, idx) => {
    // For the given month, group all Days according to Elevation Label, Id, Service and Item Id
    const groupedDays = groupBy(
      el?.days?.map((el) => ({ ...el, date: getNYDate(el?.day).formatted })),
      "elevationLabel"
    );
    let isProrated = false;
    Object.entries(groupedDays).forEach(([key, value]) => {
      // if prorated true do not check other elevations
      if (!isProrated) {
        // IF daily amount changes for that month for at least one item
        // then it is prorated
        isProrated = !value?.every(
          (el) => el?.dailyAmount === value[0]?.dailyAmount
        );
      }
    });

    return {
      ...el,
      isProrated,
    };
  });
};

const getFinalCurrentPaymentDue = ({
  fakeApplication,
  othersRentalData,
  rentals,
}) => {
  let prevRet = 0;
  let services = fakeApplication?.services || [];

  for (const service of services) {
    if (service?.label === "Hoist") {
      let amounts = service?.serviceOptions[0]?.amounts || [];
      const hoistRet =
        amounts?.reduce((a, b) => a + b?.previousRetainage || 0, 0) || 0;
      prevRet += hoistRet;
    } else {
      let amounts = service?.amounts || [];
      const ret =
        amounts?.reduce((a, b) => a + b?.previousRetainage || 0, 0) || 0;
      prevRet += ret;
    }
  }

  // console.log("getFinalCurrentPaymentDue", {
  //   fakeApplication,
  //   othersRentalData,
  //   rentals,
  //   prevRet,
  // });
};
