export const forceToNumber = (param) => {
  if (typeof param === "number" && !isNaN(param)) {
    // Check if it's a valid number
    return param;
  } else if (
    typeof param === "string" &&
    param.trim() !== "" &&
    !isNaN(Number(param))
  ) {
    // Check if it's a string that can be converted to a number
    return Number(param);
  } else {
    return 0;
  }
};

export const validateThenSavePayment = (fakePayment) => {
  const payment = {
    ...fakePayment,
    currentDueAmount: fakePayment?.invoices?.reduce(
      (acc, obj) => acc + forceToNumber(obj?.invoiceAmountDue),
      0
    ),
    invoices: fakePayment?.invoices?.map((invoice) => ({
      ...invoice,
      invoicePaidPercentage: paymentPercantageMapper(invoice),
      invoiceChecked: invoice?.invoicePaidPercentage > 0 ? true : false,
    })),
  };
  payment.newDueAmount =
    payment.currentDueAmount - forceToNumber(fakePayment?.appliedAmount);
  payment.newCustomerBalance =
    forceToNumber(fakePayment?.clientBalance) -
    forceToNumber(fakePayment?.appliedAmount);

  return payment;
};

export const paymentPercantageMapper = (data) => {
  const invoiceAmountDue = forceToNumber(data?.invoiceAmountDue);
  if (invoiceAmountDue === 0) {
    return 0;
  }

  const res = Number(
    (forceToNumber(data?.invoicePaidAmount) * 100) / invoiceAmountDue
  );
  return forceToNumber(res);
};
