import { DriveFile } from "../../../SidebarPages/FleetMaintenanceView/types";

/**
 * @typedef AssistantMessageType
 * @property {string} messageId
 * @property {string} text
 * @property {string|undefined} senderId
 * @property {string} senderName
 * @property {string|undefined} roomId
 * @property {string|undefined} roomName
 * @property {boolean} botMessage
 * @property {string} parentFolderId
 * @property {DriveFile[]} attachments
 * @property {boolean} messageRetried
 * @property {number} createdAt
 * @property {"sent"|"error"|"typing"|"pending"} messageStatus
 */

/**
 * @type {AssistantMessageType[]}
 */
const tmp = [
  {
    messageId: "50745492-44ca-40a0-85b9-053833d73f52",
    attachments: [],
    botMessage: false,
    createdAt: 1724250316114,
    messageRetried: false,
    messageStatus: "sent",
    parentFolderId: "",
    roomId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    roomName: "",
    senderId: "127cdf64-bcc2-4987-9d73-268c033b03d2",
    senderName: "Ergi Asllani",
    text: "Received 1",
  },
  {
    messageId: "2330560a-3b1c-4ecd-9685-169fa415b237",
    attachments: [],
    botMessage: false,
    createdAt: 1724250331193,
    messageRetried: false,
    messageStatus: "sent",
    parentFolderId: "",
    roomId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    roomName: "",
    senderId: "127cdf64-bcc2-4987-9d73-268c033b03d2",
    senderName: "Ergi Asllani",
    text: "Received 2",
  },

  {
    messageId: "c166efe2-c24a-4c76-94aa-7259cafc2833",
    attachments: [],
    botMessage: false,
    createdAt: 1724250343359,
    messageRetried: false,
    messageStatus: "sent",
    parentFolderId: "",
    roomId: "connected-user",
    roomName: "",
    senderId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    senderName: "Klajdi Maxhaku",
    text: "Sent 1",
  },
  {
    messageId: "a7ea248a-3c98-48d2-9aaa-fa70e62999f6",
    attachments: [],
    botMessage: false,
    createdAt: 1724350361619,
    messageRetried: false,
    messageStatus: "sent",
    parentFolderId: "",
    roomId: "connected-user",
    roomName: "",
    senderId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    senderName: "Klajdi Maxhaku",
    text: "Sent 2",
  },

  {
    messageId: "7965750f-6615-4e31-b211-a21b226b2173",
    attachments: [
      {
        id: "some-id",
        kind: "file",
        mimeType: "application/pdf",
        name: "attachment 1.pdf",
        permissions: [],
        size: 1000,
        thumbnailLink: "",
        webViewLink: "",
      },
      {
        id: "some-id-1",
        kind: "file",
        mimeType: "application/vnd.ms-excel",
        name: "spreadsheet.xlsx",
        permissions: [],
        size: 1500,
        thumbnailLink: "",
        webViewLink: "",
      },
    ],
    botMessage: false,
    createdAt: 1724350385431,
    messageRetried: false,
    messageStatus: "sent",
    parentFolderId: "",
    roomId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    roomName: "",
    senderId: "connected-user",
    senderName: "Kenado Mani",
    text: "Received 3",
  },

  {
    messageId: "84610d0a-b4a5-4419-9702-d56f6962e820",
    attachments: [],
    botMessage: false,
    createdAt: 1724350398345,
    messageRetried: false,
    messageStatus: "error",
    parentFolderId: "",
    roomId: "connected-user",
    roomName: "",
    senderId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    senderName: "Klajdi Maxhaku",
    text: "Error 1",
  },

  {
    messageId: "59b01c0b-49dc-4510-8816-54112abb7a8c",
    attachments: [],
    botMessage: true,
    createdAt: Date.now(),
    messageRetried: false,
    messageStatus: "sent",
    parentFolderId: "",
    roomId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    roomName: "",
    senderId: "chatbot",
    senderName: "Core Scaffold Systems Inc.",
    text: "This is an automated message from the ai. This is an automated message from the ai.",
  },

  {
    messageId: "3bde299f-f5ef-4b73-b0c2-9496b8a64136",
    attachments: [],
    botMessage: false,
    createdAt: Date.now(),
    messageRetried: false,
    messageStatus: "pending",
    parentFolderId: "",
    roomId: "connected-user",
    roomName: "",
    senderId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    senderName: "Klajdi Maxhaku",
    text: "Pending 1",
  },

  {
    messageId: "7f47c6b1-564e-4a43-acde-036d4040c26c",
    attachments: [],
    botMessage: false,
    createdAt: Date.now(),
    messageRetried: false,
    messageStatus: "typing",
    parentFolderId: "",
    roomId: "e5a4e3ad-a91c-493b-a05c-917c58c5bb2e",
    roomName: "",
    senderId: "connected-user",
    senderName: "Kenado Mani",
    text: "",
  },
];

export default {
  defaultMessages: [
    "Lorem Ipsum is simply dummy text of the printing andsa?",
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make?",
    "Lorem Ipsum is simply dummy text of the printing andsa?",
    "Lorem Ipsum is simply dummy text of the printing andsa?",
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make?",
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make?",
    "Lorem Ipsum is simply dummy text of the printing andsa?",
  ],
  messageHistory: tmp,
};
