import "./ReactQuillRichText.scss";
import React from "react";
import { Button, Tooltip } from "antd";
import ReactQuill, { Quill } from "react-quill";
import ImageCompress from "quill-image-compress";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "react-quill/dist/quill.snow.css";
import "../modal.scss";
import moment from "moment";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "align",
  "color",
  "mention",
  "background",
  "image",
];

export default class ReactQuillRichText extends React.Component {
  constructor(props) {
    super(props);
    // this.quillRef = null;
    this.reactQuillRef = null;
    this.size =
      new TextEncoder()?.encode(JSON?.stringify(props?.value))?.length / 1024;
    Quill.register("modules/imageCompress", ImageCompress);
  }

  state = {
    key: 1,
    modules: getModules(
      this.props.defaultUsers,
      this.props.showMentionList ?? true
    ),
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.showMentionList !== prevProps.showMentionList ||
      this.props.defaultUsers?.length !== prevProps.defaultUsers?.length
    ) {
      this.setState({
        key: ++this.state.key, //update key for react quill, its needed new key when modules change
        modules: getModules(
          this.props.defaultUsers,
          this.props.showMentionList
        ),
      });
    }
  }

  insertNewValue = (newValue) => {
    const editor = this.reactQuillRef.getEditor();
    const cursorPosition = editor.getSelection(true).index;
    editor.insertText(cursorPosition, ` ${newValue} `);
  };

  render() {
    return (
      <div
        className={`rich-editor-description ${
          this.props.wrapperClassName || ""
        }`}
      >
        <div className="addDateHourBtns">
          <Tooltip title="Click to add Time!">
            <ClockCircleOutlined
              onClick={() => this.insertNewValue(moment().format("LT"))}
            />
          </Tooltip>

          <Tooltip title="Click to add Date!">
            <CalendarOutlined
              onClick={() =>
                this.insertNewValue(moment().format("MMMM/DD/YYYY"))
              }
            />
          </Tooltip>
        </div>

        <ReactQuill
          key={this.state.key} // Use a unique key based on modules
          defaultValue={this.size < 400 ? this.props.value || "" : "a"}
          value={this.props.value || ""}
          theme="snow"
          ref={(ref) => {
            this.reactQuillRef = ref;
          }}
          placeholder={this.props.placeholder || "Start description"}
          className={`react-quill-container ${this.props.className || ""}`}
          formats={formats}
          modules={this.state.modules}
          onChange={this.props.getValue}
        />
      </div>
    );
  }
}

const getModules = (users, withMentions) => {
  return {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["clean"],
    ],

    imageCompress: {
      quality: 0.3,
      maxWidth: 1000,
      maxHeight: 1000,
      // imageType: "image/jpeg",
      keepImageTypes: ["image/jpeg", "image/png"],
      debug: true,
      suppressErrorLogging: false,
    },
    ...(withMentions && {
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        spaceAfterInsert: true,
        source: (searchTerm, renderList, mentionChar) => {
          let values;

          if (mentionChar === "@") {
            values = users;
          }
          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
      },
    }),
  };
};
