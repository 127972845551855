import { API } from "aws-amplify";

/**
 *
 * @param {String} id - the id of the record being deleted
 * @param {String} endpoint - the endpoint of the record being deleted
 * @returns
 */
async function deletePromise({ id = "", endpoint = "" }) {
  return new Promise(async (resolve, reject) => {
    return await API.del(endpoint, `/${endpoint}/${id}`)
      ?.then(() => {
        resolve();
      })
      ?.catch((error) => {
        reject(error);
      });
  });
}

export default deletePromise;
