import { uniq, uniqBy } from "lodash";
import { getSelectedBaseRecords } from "../../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import { API } from "aws-amplify";

/**
 *
 * @param {Object} fetchedData - the data fetched from the server
 * @param {String} currentPageKey - the key of the current page which is a ref
 * @param {Object} userConfiguration
 * @param {String} selectedMainField - the selected main field of the request
 * @param {Function} setRecordsLoading
 * @param {Function} dispatchRequestData - the pointer to the dispatch function of the request reducer
 */
const docRequestPopulation = async ({
  recordId = null,
  requestType = "",
  fetchedData = null,
  currentPageKey = null,
  userConfiguration = null,
  selectedMainField = null,
  setRecordsLoading = () => {},
  dispatchRequestData = () => {},
}) => {
  /**
   * If there is no selectedMainField api table or if the requestType is not Documentation, we return
   */
  if (!selectedMainField?.apiData?.table || requestType !== "Documentation")
    return;

  const {
    table = "",
    rowName = "",
    primaryKey = "",
  } = selectedMainField?.apiData || {};
  const extraFetchKeys = [
    "accountName",
    "accountId",
    "leadId",
    "estimationNumber",
    "scheduleName",
    "teamsConfiguration",
  ];

  if (!table || !rowName || !primaryKey) return;

  const fetchedDataKey = table === "scheduling" ? "schedules" : table;

  /**
   * If there is already data fetched for this specific table, we return
   */
  if (!!fetchedData?.[fetchedDataKey]?.length) return;

  await getSelectedBaseRecords({
    idKey: primaryKey,
    table: table,
    nameKey: rowName,
    setLoading: setRecordsLoading,
    keysToInclude: uniq([
      ...selectedMainField?.apiData?.keysToInclude,
      ...extraFetchKeys,
    ]),
    setRecords: async (res) => {
      const data = userAccessRecords(
        userConfiguration,
        uniqBy(res, primaryKey)
      );

      if (!data?.some((el) => el[primaryKey] === recordId)) {
        await API.get(table, `/${table}/${recordId}`, {
          queryStringParameters: {
            keysToInclude: JSON.stringify(
              uniq([
                ...selectedMainField?.apiData?.keysToInclude,
                ...extraFetchKeys,
              ])
            ),
          },
        })
          .then((res) => {
            data.push(res);
            dispatchRequestData({
              type: "SEARCH_FETCH",
              payload: { [fetchedDataKey]: data },
            });
          })
          .catch((err) => console.log(err));
      } else {
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: { [fetchedDataKey]: data },
        });
      }
    },
    customSetRecords: true,
    setCurrentPageKey: (val) => {
      Object.assign(currentPageKey, {
        current: {
          ...currentPageKey?.current,
          [selectedMainField?.apiData?.table]: val,
        },
      });
    },
    userConfiguration,
  });
};

export default docRequestPopulation;
