export const generateColumnDefs = (data) => {
  if (!!!data?.length) {
    return [];
  }

  const firstElement = data[0];
  const columnDefs = [
    {
      headerName: "",
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      maxWidth: 50,
    },
  ];

  for (const key in firstElement) {
    const formattedHeader = key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());

    columnDefs.push({
      headerName: formattedHeader,
      field: key,
      editable: true,
      //   cellEditor: InputGridEditor,
    });
  }

  return columnDefs;
};
