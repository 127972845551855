import { getYards } from "../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/routeFields";

export function getDispatchLocationOptions(
  schedulesForDate,
  projects,
  needId = false
) {
  return [
    {
      label: "Yards",
      options: getYards()?.map((y) => ({
        value: y,
        label: y,
      })),
    },
    !!schedulesForDate?.length && {
      label: "Schedules",
      options:
        schedulesForDate
          ?.reduce(
            (acc, val) => [
              ...acc,
              !acc?.find((e) => e?.label === val?.scheduleAddress)
                ? {
                    key: val?.scheduleAddress + Math.random(),
                    value: needId
                      ? val?.scheduleId + " "
                      : val?.scheduleAddress + " ",
                    label:
                      `${val?.scheduleName ? `${val.scheduleName} : ` : ""}` +
                      val?.scheduleAddress,
                    id: val?.scheduleId,
                    type: "schedule",
                  }
                : null,
            ],
            []
          )
          ?.filter(Boolean)
          .sort((a, b) => a?.value?.localeCompare(b?.value)) || [],
    },
    {
      label: "Projects",
      options:
        projects
          ?.map((e) => ({
            key: e?.projectName + Math.random(),
            value: needId ? e.projectId : e?.projectName,
            label:
              e?.projectName +
              `${e?.accountName ? ` - Client: ${e?.accountName}` : ""}`,
            id: e?.projectId,
            type: "project",
          }))
          .sort((a, b) => a?.value?.localeCompare(b?.value)) || [],
    },
  ];
}

/**
 *
 * @param {Array} users - the array of users that we are filtering
 * @param {String} accessTitle - the title of the access that we are filtering for
 * @param {String} currentUser - the current user that is logged in
 * @param {Boolean} people - if true, returns the user object, otherwise returns the name of the user
 * @returns Filtered array of users based on the access title and the current user
 */
export function filterUsersByAccess(
  users,
  accessTitle,
  currentUser,
  people = false
) {
  const filteredUsers =
    (users || []).filter((e) =>
      e?.routeConfig?.find(
        (el) =>
          (el?.title === "Approvals/View" && el?.write === true) ||
          e?.groupName === "Admin"
      )
    ) || [];
  return (filteredUsers || [])
    ?.filter(
      (e) =>
        !!e?.routeConfig?.find(
          ({ title, write }) => title === accessTitle && write === true
        ) && !e?.isSuspended
    )
    ?.map((e) => {
      if (people === false) {
        return e?.nameOfUser;
      } else {
        return e;
      }
    });
}

/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string} inputString - The input string to capitalize.
 * @returns {string} The input string with the first letter of each word capitalized.
 */
export function capitalizeFirstLetters(inputString) {
  // Split the input string into words using space as the delimiter
  const words = inputString.split(" ");

  // Initialize an empty array to store the capitalized words
  const capitalizedWords = [];

  // Iterate through each word and capitalize the first letter
  for (const word of words) {
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedWords.push(capitalizedWord);
  }

  // Join the capitalized words back into a single string
  const resultString = capitalizedWords.join(" ");

  return resultString;
}

export const responseReturned = (action) => {
  return action === "Draft"
    ? "no response"
    : action === "Reviewed"
    ? "no response"
    : "declined";
};
