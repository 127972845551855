export const statusPaths = {
  Stopped: {
    path: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 14H8V8h8v8z",
    color: "#DC4045",
  },
  Idling: {
    path: "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-80 600c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V360c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v304zm224 0c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V360c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v304z",
    color: "#DFC400",
  },
  Moving: {
    path: "M12 2 4.5 20.29l.71.71L12 18l6.79 3 .71-.71L12 2z",
    color: "#0A8800",
  },
  InMotion: {
    path: "M12 2 4.5 20.29l.71.71L12 18l6.79 3 .71-.71L12 2z",
    color: "#0A8800",
  },
  NotTracking: {
    path: "M6.027,34.231H32.8A3.555,3.555,0,0,0,35.879,28.9L22.491,5.766a3.554,3.554,0,0,0-6.152,0L2.951,28.9a3.555,3.555,0,0,0,3.076,5.334ZM19.415,21.786a1.783,1.783,0,0,1-1.778-1.778V16.452a1.778,1.778,0,1,1,3.556,0v3.556A1.783,1.783,0,0,1,19.415,21.786ZM21.193,28.9H17.637V25.342h3.556Z",
    color: "#FAD753",
  },
  NeverCheckedIn: {
    path: "M6.027,34.231H32.8A3.555,3.555,0,0,0,35.879,28.9L22.491,5.766a3.554,3.554,0,0,0-6.152,0L2.951,28.9a3.555,3.555,0,0,0,3.076,5.334ZM19.415,21.786a1.783,1.783,0,0,1-1.778-1.778V16.452a1.778,1.778,0,1,1,3.556,0v3.556A1.783,1.783,0,0,1,19.415,21.786ZM21.193,28.9H17.637V25.342h3.556Z",
    color: "#FAD753",
  },
};
