import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const estimations = ({ tables, programFields }) => {
  return {
    estSTATUS: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Estimation Status")
        ?.fieldOptions?.map(({ statusName }, index) => ({
          key: index,
          title: statusName,
          taskNumber: tables?.Estimations?.length,
          taskDone: tables?.Estimations?.filter(
            (a) =>
              a?.estSTATUS?.toLowerCase() === statusName.toLocaleLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Estimation " + statusName,
          contentsHandler: " Estimations " + statusName,
        })),
    }), //estSTATUS
    archAssignedTo: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Estimations || [], "archAssignedTo")
      )?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: (tables?.Estimations || [])?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: ` Estimation Assigned `,
        contentsHandler: ` Estimations Assigned `,
      })),
    }), //archAssignedTo
    engAssignedTo: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Estimations || [], "engAssignedTo")
      )?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: (tables?.Estimations || [])?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: ` Estimation Assigned `,
        contentsHandler: ` Estimations Assigned `,
      })),
    }), //engAssignedTo
    statusByArch: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Status by Architecture")
        ?.fieldOptions?.map(({ statusName }, index) => ({
          key: index,
          title: statusName,
          taskNumber: tables?.Estimations?.length,
          taskDone: tables?.Estimations?.filter(
            (a) =>
              a?.statusByArch?.toLowerCase() === statusName.toLocaleLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Estimation ${statusName}`,
          contentsHandler: ` Estimations ${statusName}`,
        })),
    }), //statusByArch
    statusByEng: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Status by Engineering")
        ?.fieldOptions?.map(({ statusName }, index) => ({
          key: index,
          title: statusName,
          taskNumber: tables?.Estimations?.length,
          taskDone: tables?.Estimations?.filter(
            (a) =>
              a?.statusByEng?.toLowerCase() === statusName.toLocaleLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Estimation ${statusName}`,
          contentsHandler: ` Estimations ${statusName}`,
        })),
    }), //statusByEng
  };
};
