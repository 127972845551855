import { message, Alert } from "antd";
import axios from "axios";
import { Buffer } from "buffer";
import FileSaver from "file-saver";
import React from "react";
import htmlParser from "../../../../../../utils/htmlParser";
export function retrieveTakeOffDOCX(
  data,
  address,
  serviceDefs,
  accountName,
  estDescription,
  includeNotes
) {
  const htmlData = [];
  if (!!address) {
    htmlData.push(
      <div className="address_preview_takeoff">
        <p>{address}</p>
      </div>
    );
  }

  if (!!accountName) {
    htmlData.push(
      <div className="client_preview_takeoff">
        <p>{`(Client: ${accountName})`}</p>
      </div>
    );
  }

  if (Array.isArray(data) && data.length > 0) {
    data.forEach((service) => {
      const servicesChildren = [];
      let ServColor = serviceColor(serviceDefs, service.serviceId);

      servicesChildren.push(
        <div className="takeoff_preview__title">
          <div
            className="circleshape"
            style={{
              backgroundColor: ServColor,
            }}
          ></div>{" "}
          <div>{service?.label}</div>
        </div>
      );

      // servicesChildren.push(
      //   createDynamicHtmlElement(
      //     "p",
      //     {
      //       style: {
      //         ...serviceColors(serviceDefs, service.serviceId),
      //         fontSize: "16px",
      //       },
      //     },
      //     service?.label
      //   )
      // );
      service.serviceOptions.forEach((option, optionIndex) => {
        if (optionIndex === 1) {
          servicesChildren.push(
            createDynamicHtmlElement(
              "p",
              { style: { fontSize: "11px", color: "#0743a3" } },
              "- Alternate elevations"
            )
          );
        }
        option.forEach((elevation, elevationIndex) => {
          // bold

          servicesChildren.push(
            createDynamicHtmlElement(
              "p",
              {
                style: {
                  //bold
                  fontWeight: "bold",
                  fontSize: "12px",
                  textDecoration: "underline",
                },
              },
              elevation?.elevationLabel
            )
          );

          switch (service?.serviceId) {
            // case 1:
            case 6:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  let tempText = "";
                  tempText += `${formatDimensions(
                    item?.length,
                    item?.height,
                    item?.width
                  )} `;

                  // servicesChildren.push(
                  //   createDynamicHtmlElement(
                  //     "p",
                  //     {
                  //       style: {
                  //         fontSize: "11px",
                  //         //ident left 400
                  //         marginLeft: "20px",
                  //       },
                  //     },
                  //     formatDimensions(item?.length, item?.height, item?.width)
                  //   )
                  // );
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  if (item?.addons.length > 0) {
                    for (const addon of item?.addons) {
                      if (addon?.approved) {
                        //   p = doc.createP();
                        //   p.options.indentLeft = 600;
                        //   p.addText(
                        //     "- Addon " +
                        //       `(${addon.sideWalkShedtype})` +
                        //       ": " +
                        //       formatDimensions(
                        //         addon?.length,
                        //         addon?.height,
                        //         addon?.width
                        //       ),
                        //     { font_size: 11 }
                        //   );
                        servicesChildren.push(
                          createDynamicHtmlElement(
                            "p",
                            {
                              style: {
                                fontSize: "11px",
                                marginLeft: "40px",
                              },
                            },
                            "- Addon " +
                              `(${addon.sideWalkShedtype})` +
                              ": " +
                              formatDimensions(
                                addon?.length,
                                addon?.height,
                                addon?.width
                              )
                          )
                        );
                        if (!!addon?.description) {
                          servicesChildren.push(
                            createDynamicHtmlElement(
                              "p",
                              {
                                style: {
                                  fontSize: "11px",
                                  marginLeft: "40px",
                                },
                              },
                              addon?.description
                            )
                          );
                        }

                        if (!!addon?.note && addon?.note !== "<p><br></p>") {
                          servicesChildren.push(
                            createDynamicHtmlElement(
                              "p",
                              {
                                style: {
                                  fontSize: "11px",
                                  marginLeft: "40px",
                                  color: "ff0000",
                                },
                              },
                              addon?.note?.note || "",
                              true
                            )
                          );
                        }
                      }
                    }
                  }
                  attachNotePLI(item, servicesChildren);
                  // if (
                  //   // !!req.body.includeNotes &&
                  //   !!item?.note &&
                  //   item?.note !== "<p><br></p>"
                  // ) {
                  //   // pliNotes.push(item?.note)
                  //   // p.addText(` ( `, { color: "ff0000" });
                  //   // parseHTML(p, item?.note, false, true, false, doc);
                  //   // p.addText(` ) `, { color: "ff0000" });
                  //   servicesChildren.push(
                  //     createDynamicHtmlElement(
                  //       "p",
                  //       {
                  //         style: {
                  //           fontSize: "11px",
                  //           marginLeft: "20px",
                  //           color: "ff0000",
                  //         },
                  //       },
                  //       item?.note || "",
                  //       true
                  //     )
                  //   );
                  // }
                }
              });
              break;
            case 2:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  //   p = doc?.createP();
                  //   p.options.indentLeft = 400;
                  //   p.addText(
                  //     formatDimensions(item?.length, item?.height, null),
                  //     {
                  //       font_size: 11,
                  //     }
                  //   );
                  let temp = formatDimensions(item?.length, item?.height, null);

                  if (item?.facadeHeight)
                    // p.addText(` (${getMax(item?.facadeHeight)}H) `);
                    temp += ` (${getMax(item?.facadeHeight)}H) `;

                  if (item?.fromFloor)
                    temp += ` from ${toOrdinalFloor(item?.fromFloor)} ${
                      item?.fromFloorNote ?? ""
                    }`;
                  // p.addText(
                  //   ` from ${toOrdinalFloor(item?.fromFloor)} ${
                  //     item?.fromFloorNote ?? ""
                  //   }`,
                  //   { font_size: 11 }
                  // );
                  if (item?.toFloor)
                    temp += ` to ${toOrdinalFloor(item?.toFloor)} ${
                      item?.toFloorNote ?? ""
                    }`;
                  // p.addText(
                  //   ` to ${toOrdinalFloor(item?.toFloor)} ${
                  //     item?.toFloorNote ?? ""
                  //   }`,
                  //   {
                  //     font_size: 11,
                  //   }
                  // );

                  // servicesChildren.push(
                  //   createDynamicHtmlElement(
                  //     "p",
                  //     {
                  //       style: {
                  //         fontSize: "11px",
                  //         marginLeft: "20px",
                  //       },
                  //     },
                  //     temp
                  //   )
                  // );

                  attachDescriptionPLI(temp, item, servicesChildren);

                  attachNotePLI(item, servicesChildren);

                  if (item?.includedCorners) {
                    // p.addText(
                    //   ` (including ${evaluateCornerExpression({
                    //     value: item?.includedCorners,
                    //   })} corners)`,
                    //   { font_size: 11, color: "ff0000" }
                    // );
                    servicesChildren.push(
                      createDynamicHtmlElement(
                        "p",
                        {
                          style: {
                            fontSize: "11px",
                            marginLeft: "20px",
                            color: "ff0000",
                          },
                        },
                        ` (including ${evaluateCornerExpression({
                          value: item?.includedCorners,
                        })} corners)`
                      )
                    );
                  }
                  if (item?.addons.length > 0) {
                    for (const addon of item?.addons) {
                      if (addon?.approved) {
                        //   p = doc.createP();
                        //   p.options.indentLeft = 600;
                        //   p.addText(
                        //     "- Addon " +
                        //       `(${addon.sideWalkShedtype})` +
                        //       ": " +
                        //       formatDimensions(
                        //         addon?.length,
                        //         addon?.height,
                        //         addon?.width
                        //       ),
                        //     { font_size: 11 }
                        //   );
                        servicesChildren.push(
                          createDynamicHtmlElement(
                            "p",
                            {
                              style: {
                                fontSize: "11px",
                                marginLeft: "40px",
                              },
                            },
                            "- Addon " +
                              `(${addon.sideWalkShedtype})` +
                              ": " +
                              formatDimensions(
                                addon?.length,
                                addon?.height,
                                addon?.width
                              )
                          )
                        );
                        if (!!addon?.description) {
                          servicesChildren.push(
                            createDynamicHtmlElement(
                              "p",
                              {
                                style: {
                                  fontSize: "11px",
                                  marginLeft: "40px",
                                },
                              },
                              addon?.description
                            )
                          );
                        }

                        if (!!addon?.note && addon?.note !== "<p><br></p>") {
                          servicesChildren.push(
                            createDynamicHtmlElement(
                              "p",
                              {
                                style: {
                                  fontSize: "11px",
                                  marginLeft: "40px",
                                  color: "ff0000",
                                },
                              },
                              addon?.note?.note ?? ""
                            )
                          );
                        }
                      }
                    }
                  }
                  if (item?.addons.length > 0) {
                    for (const addon of item?.addons) {
                      const addonP = (
                        <p>
                          {"- Addon " +
                            `(${addon.sideWalkShedtype})` +
                            ": " +
                            formatDimensions(
                              addon?.length,
                              addon?.height,
                              addon?.width
                            ) +
                            " " +
                            addon?.description +
                            " " +
                            "(" +
                            addon?.note?.note +
                            ")"}
                        </p>
                      );

                      servicesChildren.push(addonP);

                      // p = doc.createP();
                      // p.options.indentLeft = 600;
                      // p.addText(
                      //   "- Addon " +
                      //     `(${addon.sideWalkShedtype})` +
                      //     ": " +
                      //     formatDimensions(
                      //       addon?.length,
                      //       addon?.height,
                      //       addon?.width
                      //     ),
                      //   { font_size: 11 }
                      // );
                      // p.addText(" ");
                      // parseHTML(p, addon.description, false, false, false, doc);
                      // p.addText(` ( `, { color: "ff0000" });
                      // parseHTML(p, addon?.note, false, true, false, doc);
                      // p.addText(` ) `, { color: "ff0000" });
                    }
                  }
                }
              });
              break;
            case 3:
              //   p = doc.createP();
              //   p.options.indentLeft = 400;
              //   p.addText(
              //     elevation?.type === "dual" ? "Dual Hoist" : "Single Hoist",
              //     {
              //       font_size: 11,
              //     }
              //   );
              servicesChildren.push(
                createDynamicHtmlElement(
                  "p",
                  {
                    style: {
                      fontSize: "11px",
                      marginLeft: "20px",
                    },
                  },
                  elevation?.type === "dual" ? "Dual Hoist" : "Single Hoist"
                )
              );
              //   p = doc.createP();
              //   p.options.indentLeft = 400;
              //   p.addText(
              //     `Located on the ${elevation?.location} / ${
              //       // elevation?.access === "nonDirect" &&
              //       ` Access through 1st floor (approx. ${toOrdinalFloor(
              //         elevation.items[elevation.items.length - 1]?.floor
              //       )}′)`
              //     }`,
              //     { font_size: 11 }
              //   );
              servicesChildren.push(
                createDynamicHtmlElement(
                  "p",
                  {
                    style: {
                      fontSize: "11px",
                      marginLeft: "20px",
                    },
                  },
                  `Located on the ${elevation?.location} / ${
                    // elevation?.access === "nonDirect" &&
                    ` Access through 1st floor (approx. ${toOrdinalFloor(
                      elevation.items[elevation.items.length - 1]?.floor
                    )}′)`
                  }`
                )
              );
              //   p = doc.createP();
              //   p.options.indentLeft = 400;
              let lh = elevation?.lift_height;
              //   p.addText(
              //     `Lift Height: ${lh}′H from (${toOrdinalFloor(
              //       elevation?.items[0]?.floor
              //     )}) to (${toOrdinalFloor(
              //       elevation.items[elevation.items.length - 1]?.floor
              //     )})`,
              //     // ${
              //     //   lh.additionalMast
              //     //     ? ` (additional ${lh.additionalMast}′ mast)`
              //     //     : ""
              //     // }`,
              //     { font_size: 11 }
              //   );

              servicesChildren.push(
                createDynamicHtmlElement(
                  "p",
                  {
                    style: {
                      fontSize: "11px",
                      marginLeft: "20px",
                    },
                  },
                  `Lift Height: ${lh}′H from (${toOrdinalFloor(
                    elevation?.items[0]?.floor
                  )}) to (${toOrdinalFloor(
                    elevation.items[elevation.items.length - 1]?.floor
                  )})`
                )
              );

              //   p = doc.createP();
              //   p.options.indentLeft = 400;
              let stopsCount = getStopCount(
                elevation?.items ?? [],
                elevation?.type || "single"
              );

              // elevation?.stops_including?.length +
              // elevation?.stops_excluding?.length;
              //   p.addText(
              //     `Stops: ${stopsCount} ${stopsCount === 1 ? "stop" : "stops"}${
              //       elevation?.stops_including?.length !== 0
              //         ? ` including ${elevation?.stops_including?.join(", ")}`
              //         : ""
              //     }${
              //       elevation?.stops_excluding?.length !== 0
              //         ? ` excluding ${elevation?.stops_excluding?.join(", ")}`
              //         : ""
              //     }`,
              //     { font_size: 11 }
              //   );

              servicesChildren.push(
                createDynamicHtmlElement(
                  "p",
                  {
                    style: {
                      fontSize: "11px",
                      marginLeft: "20px",
                    },
                  },
                  `Number of Stops: ${
                    stopsCount.A > 0 ? `Cabin A: ${stopsCount.A}` : ""
                  } ${stopsCount.B > 0 ? `Cabin B: ${stopsCount.B}` : ""} ${
                    elevation?.stops_including?.length !== 0
                      ? `including ${elevation?.stops_including?.join(", ")}`
                      : ""
                  }${
                    elevation?.stops_excluding?.length !== 0
                      ? ` excluding ${elevation?.stops_excluding?.join(", ")}`
                      : ""
                  }`
                )
              );
              //   if (
              //     !!req.body.includeNotes &&
              //     elevation?.note &&
              //     elevation?.note !== "<p><br></p>"
              //   ) {
              //     pliNotes.push(elevation?.note);
              //   }
              //   elevation?.items?.forEach((item) => {
              //     if (item?.approved) {
              //       if (!!item?.note && item?.note !== "<p><br></p>") {
              //         pliNotes.push(item?.note);
              //       }
              //     }
              //   });
              break;
            case 4:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  // p = doc?.createP()
                  // p.options.indentLeft = 400
                  // if (!!item?.material) {
                  //   p.addText(`(`, { font_size: 11 })
                  //   p.addText(`${item?.material}`, { font_size: 11 })
                  //   p.addText(`)`, { font_size: 11 })
                  // }
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(
                  //   `${formatDimensions(
                  //     item?.length,
                  //     item?.height ?? 8,
                  //     null
                  //   )} `,
                  //   {
                  //     font_size: 11,
                  //   }
                  // );
                  let tempText = "";
                  tempText += `${formatDimensions(
                    item?.length,
                    item?.height ?? 8,
                    null
                  )} `;
                  tempText += !!item?.material ? `(${item?.material}) ` : "";
                  // servicesChildren.push(
                  //   createDynamicHtmlElement(
                  //     "p",
                  //     {
                  //       style: {
                  //         fontSize: "11px",
                  //         marginLeft: "20px",
                  //       },
                  //     },
                  //     `${formatDimensions(
                  //       item?.length,
                  //       item?.height ?? 8,
                  //       null
                  //     )} `,
                  //     {
                  //       font_size: 11,
                  //     }
                  //   )
                  // );
                  // if (!!item?.material) {
                  //   // p.addText(` ${item?.material} `, { font_size: 11 });
                  //   servicesChildren.push(
                  //     createDynamicHtmlElement(
                  //       "p",
                  //       {
                  //         style: {
                  //           fontSize: "11px",
                  //           marginLeft: "20px",
                  //         },
                  //       },
                  //       ` ${item?.material} `
                  //     )
                  //   );
                  // }
                  // if (item?.description && item?.description !== "<p><br></p>")
                  //   parseHTML(p, item?.description, false)
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            case 5:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(
                  //   `${formatDimensions(item?.length, null, item?.width)} / `
                  // );
                  let tempText = "";
                  tempText += `${formatDimensions(
                    item?.length,
                    null,
                    item?.width
                  )} / `;
                  // servicesChildren.push(
                  //   createDynamicHtmlElement(
                  //     "p",
                  //     {
                  //       style: {
                  //         fontSize: "11px",
                  //         marginLeft: "20px",
                  //       },
                  //     },
                  //     `${formatDimensions(item?.length, null, item?.width)} / `
                  //   )
                  // );

                  if (item?.stories) {
                    // p.addText(`${item?.stories} - story building `, {
                    //   font_size: 11,
                    // });
                    tempText += `${item?.stories} - story building `;
                    // servicesChildren.push(
                    //   createDynamicHtmlElement(
                    //     "p",
                    //     {
                    //       style: {
                    //         fontSize: "11px",
                    //       },
                    //     },
                    //     `${item?.stories} - story building `
                    //   )
                    // );
                  }
                  // if (item?.details?.length > 0) {
                  //   let details = item?.details.map((detail) =>
                  //     camel2Sentence(detail)
                  //   )
                  //   p.addText(`(${details.join(", ")}) `, {
                  //     font_size: 11,
                  //     color: "ff0000",
                  //   })
                  // }
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            case 44:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(
                  //   `${formatDimensions(item?.length, null, item?.width)} / `
                  // );
                  let tempText = "";
                  tempText += `${formatDimensions(
                    item?.length,
                    null,
                    item?.width
                  )} / `;
                  // servicesChildren.push(
                  //   createDynamicHtmlElement(
                  //     "p",
                  //     {
                  //       style: {
                  //         fontSize: "11px",
                  //         marginLeft: "20px",
                  //       },
                  //     },
                  //     `${formatDimensions(item?.length, null, item?.width)} / `
                  //   )
                  // );

                  if (item?.stories) {
                    // p.addText(`${item?.stories} - story building `, {
                    //   font_size: 11,
                    // });
                    tempText += `${item?.stories} - story building `;
                    // servicesChildren.push(
                    //   createDynamicHtmlElement(
                    //     "p",
                    //     {
                    //       style: {
                    //         fontSize: "11px",
                    //       },
                    //     },
                    //     `${item?.stories} - story building `
                    //   )
                    // );
                  }
                  // if (item?.details?.length > 0) {
                  //   let details = item?.details.map((detail) =>
                  //     camel2Sentence(detail)
                  //   )
                  //   p.addText(`(${details.join(", ")}) `, {
                  //     font_size: 11,
                  //     color: "ff0000",
                  //   })
                  // }
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            case 7:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(
                  //   `${formatDimensions(item?.length, null, item?.width ?? 8)} `
                  // );
                  let tempText = "";
                  tempText += `${formatDimensions(
                    item?.length,
                    null,
                    item?.width ?? 8
                  )} `;

                  // servicesChildren.push(
                  //   createDynamicHtmlElement(
                  //     "p",
                  //     {
                  //       style: {
                  //         fontSize: "11px",
                  //         marginLeft: "20px",
                  //       },
                  //     },
                  //     `${formatDimensions(
                  //       item?.length,
                  //       null,
                  //       item?.width ?? 8
                  //     )} `
                  //   )
                  // );

                  if (item?.onFloor) {
                    // p.addText(`at ${toOrdinalFloor(item?.onFloor)} `, {
                    //   font_size: 11,
                    // });
                    tempText += `at ${toOrdinalFloor(item?.onFloor)} `;
                    // servicesChildren.push(
                    //   createDynamicHtmlElement(
                    //     "p",
                    //     {
                    //       style: {
                    //         fontSize: "11px",
                    //       },
                    //     },
                    //     `at ${toOrdinalFloor(item?.onFloor)} `
                    //   )
                    // );
                  }
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            case 8:
              elevation?.items?.forEach((item) => {
                let tempAdd = "";
                if (item?.approved) {
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  if (item?.onFloor) {
                    // p.addText(
                    //   `${toOrdinalFloor(item?.onFloor)} - ${toOrdinalFloor(
                    //     (parseInt(item.onFloor) - 1).toString()
                    //   )}   -   `,
                    //   { font_size: 11, italic: true }
                    // );
                    tempAdd += `${toOrdinalFloor(
                      item?.onFloor
                    )} - ${toOrdinalFloor(
                      (parseInt(item.onFloor) - 1).toString()
                    )}   -   `;
                  }
                  // p.addText(
                  //   `${formatDimensions(item?.length, null, item?.width)} `
                  // );
                  tempAdd += `${formatDimensions(
                    item?.length,
                    null,
                    item?.width
                  )} `;
                  if (item?.includingCorners) {
                    // p.addText(`including corners `);
                    tempAdd += `including corners `;
                  }
                  attachDescriptionPLI(tempAdd, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            case 9:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  let tempText = "";
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(`${formatDimensions(item?.length, null, null)} `);
                  tempText += `${formatDimensions(item?.length, null, null)} `;
                  if (item?.material) {
                    // p.addText(` ${item?.material} Barriers `, {
                    //   font_size: 11,
                    // });
                    tempText += ` ${item?.material} Barriers `;
                  }
                  if (!!item?.withFence) {
                    // p.addText(`with fence `, { font_size: 11 });
                    tempText += `with fence `;
                  }
                  if (!!item?.pieces) {
                    // p.addText(` ( ${item?.pieces} pieces ) `);
                    tempText += ` ( ${item?.pieces} pieces ) `;
                  }
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);

                  // if (item?.description && item?.description !== "<p><br></p>") {
                  //   p.addText(" / ", {  color: "cfcfcf" })
                  //   parseHTML(p, item?.description, false, false, true)
                  // }
                  // if (!!req.body.includeNotes && item?.note && item?.note !== "<p><br></p>") {
                  //   p.addText("( ", { color: "ff0000" })
                  //   parseHTML(p, item?.note, false, true)
                  //   p.addText(" )", {  color: "ff0000" })
                  // }
                }
              });
              break;
            case 15:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  let tempText = "";
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(`${formatDimensions(item?.length, null, null)} `);
                  tempText += `${formatDimensions(item?.length, null, null)} `;
                  if (item?.diameter) {
                    // p.addText(`x ${getMax(item?.diameter)}′′Diameter `);
                    tempText += `x ${getMax(item?.diameter)}′′Diameter `;
                  }
                  if (item?.fromFloor) {
                    // p.addText(`from ${item?.fromFloor} `);
                    tempText += ` from ${item?.fromFloor} `;
                  }
                  if (item?.toFloor) {
                    // p.addText(`to ${item?.toFloor} `);
                    tempText += `to ${item?.toFloor} `;
                  }
                  // servicesChildren.push(
                  //   createDynamicHtmlElement(
                  //     "p",
                  //     {
                  //       style: {
                  //         fontSize: "11px",
                  //         marginLeft: "20px",
                  //       },
                  //     },
                  //     tempText
                  //   )
                  // );
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            case 18:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  let tempText = "";
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(
                  //   `${formatDimensions(item?.length, item?.height, null)} `
                  // );
                  tempText += `${formatDimensions(
                    item?.length,
                    item?.height,
                    null
                  )} `;
                  if (item?.fromFloor) {
                    // p.addText(` from ${toOrdinalFloor(item?.fromFloor)} `, {
                    //   font_size: 11,
                    // });
                    tempText += ` from ${toOrdinalFloor(item?.fromFloor)} `;
                    if (item?.toFloor) {
                      // p.addText(`to ${toOrdinalFloor(item?.toFloor)} `, {
                      //   font_size: 11,
                      // });
                      tempText += `to ${toOrdinalFloor(item?.toFloor)} `;
                    }
                  }
                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            case 19:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  let tempText = "";
                  // p = doc?.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(
                  //   `${formatDimensions(item.length, item.height, null)} `
                  // );
                  tempText += `${formatDimensions()}`;
                  if (item?.count) {
                    // p.addText(`(${item?.count} pcs.) `, { font_size: 11 });
                    tempText += `(${item?.count} pcs.) `;
                  }

                  attachDescriptionPLI(tempText, item, servicesChildren);
                  attachNotePLI(item, servicesChildren);
                }
              });
              break;
            default:
              elevation?.items?.forEach((item) => {
                if (item?.approved) {
                  let tempText = "";
                  // p = doc.createP();
                  // p.options.indentLeft = 400;
                  // p.addText(
                  //   `${formatDimensions(
                  //     item?.length,
                  //     item?.height,
                  //     item?.width
                  //   )} `
                  // );
                  tempText += `${formatDimensions(
                    item?.length,
                    item?.height,
                    item?.width
                  )} `;

                  if (
                    item?.description &&
                    item?.description !== "<p><br></p>" &&
                    item?.description?.note !== "<p><br></p>"
                  ) {
                    // parseHTML(p, item?.description, false, false, false, doc);
                    tempText += stripHtmlTags(item?.description?.note || "");
                    // servicesChildren.push(
                    //   createDynamicHtmlElement(
                    //     "p",
                    //     {},
                    //     item?.description?.note ?? "",
                    //     true
                    //   )
                    // );
                  }

                  servicesChildren.push(
                    createDynamicHtmlElement(
                      "p",
                      {
                        style: {
                          fontSize: "11px",
                          marginLeft: "20px",
                        },
                      },
                      tempText
                    )
                  );
                  attachNotePLI(item, servicesChildren);

                  // if (
                  //   // !!req.body.includeNotes &&
                  //   item?.note &&
                  //   item?.note !== "<p><br></p>"
                  // ) {
                  //   // p.addText(" ( ", { color: "ff0000" });
                  //   // parseHTML(p, item?.note, false, true, false, doc);
                  //   // p.addText(" ) ", { color: "ff0000" });
                  //   servicesChildren.push(
                  //     createDynamicHtmlElement(
                  //       "strong",
                  //       {
                  //         style: {
                  //           color: "ff0000",
                  //         },
                  //       },
                  //       "Service Note: "
                  //     )
                  //   );
                  //   servicesChildren.push(
                  //     createDynamicHtmlElement(
                  //       "p",
                  //       {
                  //         style: { color: "ff0000" },
                  //       },
                  //       item?.note ?? "",
                  //       true
                  //     )
                  //   );
                  // }
                  if (item?.addons.length > 0) {
                    for (const addon of item?.addons) {
                      if (!addon?.approved) return;
                      let tempAddonText = "";
                      // p = doc.createP();
                      // p.options.indentLeft = 600;
                      tempAddonText += `- Addon (${
                        addon.sideWalkShedtype
                      }): ${formatDimensions(
                        addon?.length,
                        addon?.height,
                        addon?.width
                      )} `;
                      // p.addText(
                      //   "- Addon " +
                      //     `(${addon.sideWalkShedtype})` +
                      //     ": " +
                      //     formatDimensions(addon?.length, addon?.height, addon?.width),
                      //   { font_size: 11 }
                      // );
                      // p.addText(" ");
                      let toSend = (
                        <div className="pli_addon_takeoff_preview">
                          <p style={{ fontSize: "11px", marginLeft: "20px" }}>
                            {tempAddonText} {addon?.description}{" "}
                            {addon?.note && (
                              <span style={{ color: "ff0000" }}>
                                {typeof addon?.note === "object"
                                  ? addon?.note?.note
                                  : addon?.note}
                              </span>
                            )}
                          </p>
                        </div>
                      );
                      // servicesChildren.push(toSend);
                      // parseHTML(p, addon.description, false, false, false, doc);
                      // p.addText(` ( `, { color: "ff0000" });
                      // parseHTML(p, addon?.note, false, true, false, doc);
                      // p.addText(` ) `, { color: "ff0000" });
                    }
                  }
                }
              });
              break;
          }

          if (hasContent(elevation?.elevationDescription || "")) {
            // p = doc.createP();

            // p.addText(`		Elevation Note:`, {
            //   font_face: "Arial",
            //   font_size: 10,
            //   color: "red",
            // }); // client
            // parseHTML(
            //   p,
            //   stripHtmlTags(elevation?.elevationDescription || ""),
            //   true,
            //   false,
            //   false,
            //   doc
            // );

            servicesChildren.push(
              <>
                <Alert
                  className="elevation_note_takeoff_alert"
                  message={
                    // "Elevation Note: " +
                    // (
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <strong> Elevation Note:</strong>
                      <span
                        className="elevation_note_takeoff_preview"
                        dangerouslySetInnerHTML={{
                          __html: elevation?.elevationDescription,
                        }}
                      ></span>
                    </div>
                    // )
                    // <div
                    //   style={{}}
                    //   className="elevation_note_takeoff_preview"
                    //   dangerouslySetInnerHTML={{
                    //     __html: elevation?.elevationDescription,
                    //   }}
                    // >,
                    //   {/* {createDynamicHtmlElement(
                    //     "div",
                    //     {
                    //       style: {
                    //         color: "red",
                    //         display: "flex",
                    //         gap: "20px",
                    //         alignItems: "center",
                    //       },
                    //     },
                    //     `Elevation Note:` + elevation?.elevationDescription || "",
                    //     true
                    //   )} */}
                    // </div>
                  }
                  type="warning"
                  showIcon
                />
                <br />
              </>
            );

            // servicesChildren.push(
            //   createDynamicHtmlElement(
            //     "strong",
            //     { style: { color: "red", display: "flex", gap: "20px" } },
            //     `Elevation Note:` + elevation?.elevationDescription || "",
            //     true
            //   )
            // );
          }
        });
      });

      if (service.serviceAddons.length > 0) {
        service.serviceAddons.forEach((addon) => {
          // p = doc.createP();
          // p.options.indentLeft = 200;
          if (addon?.values?.length > 0) {
            let vals = { height: 0, length: 0, width: 0 };
            addon.values.forEach((val) => {
              if (val?.name === "Length") {
                vals.length = val?.defaultValue;
              } else if (val?.name === "Width") {
                vals.width = val?.defaultValue;
              } else if (val?.name === "Height") {
                vals.height = val?.defaultValue;
              }
            });
            if (Object.values(vals).some((v) => v > 0)) {
              servicesChildren.push(
                createDynamicHtmlElement(
                  "p",
                  {
                    style: {
                      // fontSize: "11px",
                      // marginLeft: "400px",
                      color: "ff0000",
                    },
                  },
                  `- Service Addon: ${formatDimensions(
                    vals?.length,
                    vals?.height,
                    vals?.width
                  )}`
                )
              );
            }
          }
        });
      }

      if (hasContent(service?.note)) {
        // servicesChildren.push(
        //   createDynamicHtmlElement(
        //     "div",
        //     {
        //       className: "serviceNote__takeoffPreview",
        //     },
        //     [
        //       "<strong className=`serviceNoteIndic`>Service Note: </strong>" +
        //         service?.note ?? "",
        //     ],
        //     true
        //   )
        // );
        servicesChildren.push(
          <>
            <Alert
              className="elevation_note_takeoff_alert"
              message={
                // "Elevation Note: " +
                // (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <strong> Service Note:</strong>
                  <span
                    className="elevation_note_takeoff_preview"
                    dangerouslySetInnerHTML={{
                      __html: service?.note ?? "",
                    }}
                  ></span>
                </div>
                // )
                // <div
                //   style={{}}
                //   className="elevation_note_takeoff_preview"
                //   dangerouslySetInnerHTML={{
                //     __html: elevation?.elevationDescription,
                //   }}
                // >,
                //   {/* {createDynamicHtmlElement(
                //     "div",
                //     {
                //       style: {
                //         color: "red",
                //         display: "flex",
                //         gap: "20px",
                //         alignItems: "center",
                //       },
                //     },
                //     `Elevation Note:` + elevation?.elevationDescription || "",
                //     true
                //   )} */}
                // </div>
              }
              type="info"
              showIcon
            />
            <br />
          </>
        );
      }
      // createDynamicHtmlElement(
      //   "div",
      //   {
      //     className: "serviceParent",
      //     style: {
      //       marginTop: "20px",
      //       width: "960px",
      //       height: "273px",
      //       // background: "#FFFFFF",
      //       boxShadow: `0px 0px 5px 2px ${ServColor}`,
      //       borderRadius: "10px",
      //     },
      //   }[servicesChildren]
      // );
      // serviceParent.props.children = servicesChildren;
      htmlData.push(
        createDynamicHtmlElement(
          "div",
          {
            className: "serviceParent__takeoffPreview",
            style: {
              marginTop: "20px",
              width: "960px",
              minHeight: "73px",
              // height: "273px",
              background: "#FFFFFF",
              boxShadow: `0px 0px 5px 2px ${ServColor}`,
              borderRadius: "10px",
            },
          },
          servicesChildren
        )
      );
    });
  }
  return htmlData;
}

function createDynamicHtmlElement(
  tagName,
  attributes,
  children = [],
  isHtml = false
) {
  const props = { ...attributes };

  if (isHtml) {
    props.dangerouslySetInnerHTML = { __html: children };
  } else {
    props.children = children;
  }

  const element = React.createElement(tagName, props);

  return element;
}

function serviceColor(serviceDefs, serviceId) {
  let def = serviceDefs?.filter(
    (serviceDef) => parseInt(serviceDef["workId"]) === serviceId
  )[0];
  return def?.colorCode;
  //  {
  //   backgroundColor: def?.foreGroundColor?.substring(1),
  //   color: def?.colorCode?.substring(1),
  // };
}

export function formatDimensions(length, height, width) {
  function evaluateMathExpression(fullExpression, dimension) {
    function evaluate(expression) {
      try {
        let result = eval(expression);
        if (result > 0) {
          return `${Math.round(result * 100) / 100}′${dimension}`;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }

    if (fullExpression) {
      if (fullExpression.toString().includes(">")) {
        let expressions = fullExpression.split(">");
        return `${evaluate(expressions[0])} - ${evaluate(expressions[1])}`;
      } else {
        return evaluate(fullExpression);
      }
    } else {
      return null;
    }
  }

  let formattedDimensions = [];
  for (const dimension of [
    evaluateMathExpression(length, "L"),
    evaluateMathExpression(height, "H"),
    evaluateMathExpression(width, "W"),
  ]) {
    if (dimension != null) {
      formattedDimensions.push(dimension);
    }
  }

  return formattedDimensions.join(" x ") || "";
}
function evaluateCornerExpression(params) {
  let fullExpression = params?.value;
  if (fullExpression) {
    if (fullExpression.toString().includes("x")) {
      let expressions = fullExpression?.toString().split("x");
      if (expressions.length === 2) {
        return `${expressions[0]} x ${getMax(expressions[1])}`;
      } else {
        return "Bad format.";
      }
    } else {
      return `${getMax(fullExpression)}`;
    }
  } else {
    return "-";
  }
}
function toOrdinalFloor(value) {
  if (value) {
    value = parseInt(value);
    const j = value % 10,
      k = value % 100;
    if (j === 1 && k !== 11) {
      return value + "st floor level";
    } else if (j === 2 && k !== 12) {
      return value + "nd floor level";
    } else if (j === 3 && k !== 13) {
      return value + "rd floor level";
    } else {
      return value + "th floor level";
    }
  } else {
    return "-";
  }
}

function hasContent(str) {
  try {
    if (typeof str !== "string") {
      return false;
      throw new Error("Input must be a string");
    }

    const regex = /(<([^>]+)>)/gi;
    const content = str.replace(regex, "");

    if (typeof content !== "string") {
      throw new Error("Unexpected error: content is not a string");
    }

    return Boolean(content.trim());
  } catch (error) {
    console.error(error.message);
    return false; // Return false if an error occurs
  }
}
function getMax(expression) {
  function evaluate(expression) {
    try {
      // eslint-disable-next-line
      let result = eval(expression);
      if (result > 0 && result !== Infinity) {
        return `${Math.round(result * 100) / 100}′`;
      } else if (result === Infinity) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        return "NaN";
      }
    }
  }

  if (expression.toString().includes(">")) {
    let expressions = expression?.split(">");
    return evaluate(expressions[1]);
  } else {
    return evaluate(expression);
  }
}

function attachDescriptionPLI(stringToAdd, item, servicesChildren) {
  if (
    item?.description &&
    item?.description !== "<p><br></p>" &&
    item?.description?.note !== "<p><br></p>"
  ) {
    // parseHTML(p, item?.description, false, false, false, doc);
    stringToAdd += " ";
    stringToAdd += stripHtmlTags(item?.description?.note || "");
    // servicesChildren.push(
    //   createDynamicHtmlElement(
    //     "p",
    //     {},
    //     item?.description?.note ?? "",
    //     true
    //   )
    // );
  }

  servicesChildren.push(
    createDynamicHtmlElement(
      "p",
      {
        style: {
          fontSize: "11px",
          marginLeft: "20px",
        },
      },
      stringToAdd
    )
  );
}

function attachNotePLI(item, servicesChildren) {
  if (!!item?.note?.note && hasContent(item?.note?.note))
    servicesChildren.push(
      createDynamicHtmlElement(
        "p",
        {
          style: {
            fontSize: "11px",
            marginLeft: "40px",
          },
        },
        "Note: " + stripHtmlTags(item?.note?.note || "")
      )
    );
  // let myItem = <strong>My Note!</strong>;
}

// TODO: Pending...

function attachAddonsPLI(addon, servicesChildren) {}

export function stripHtmlTags(htmlString) {
  // Replace all HTML tags with an empty string
  // check if htmlString is a string
  return typeof htmlString === "string"
    ? htmlString.replace(/(<([^>]+)>)/gi, "")?.replaceAll("&nbsp;", "")
    : "";
}

function getStopCount(items, type) {
  let stopsCount = {
    A: 0,
    B: 0,
  };
  items.forEach((item) => {
    if (item?.stop === true) {
      stopsCount.A++;
    }
    if (type !== "single" && item?.dual === false) {
      stopsCount.B++;
    }
  });
  return stopsCount;
}
