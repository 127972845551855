import { pdfOrderHelpers } from "./utils";
import _ from "lodash";
// import {
//   compareIncluding,
// } from "../../components/SidebarPages/utils";
import PackageSlip from "../../components/pages/Orders/subcomponents/PackageSlip/PackageSlip";

export default function getDocOrderDefinition({
  rowData,
  params,
  gridApi,
  gridColumnApi,
  base64,
}) {
  const { getColumnGroupsToExport, getColumnsToExport, getRowsToExport } =
    pdfOrderHelpers({ params, gridApi, gridColumnApi, rowData, base64 });

  const {
    PDF_PAGE_ORIENTATION = "portrait",
    PDF_WITH_FOOTER_PAGE_COUNT = true,
  } = params;

  return (function () {
    const columnGroupsToExport = getColumnGroupsToExport();

    const columnsToExport = getColumnsToExport();
    const rowsToExport = getRowsToExport(columnsToExport);
    const footer = PDF_WITH_FOOTER_PAGE_COUNT
      ? function (currentPage, pageCount) {
          return {
            text: currentPage.toString() + " of " + pageCount,
            margin: [20],
          };
        }
      : null;
    const body = columnGroupsToExport
      ? [columnGroupsToExport, columnsToExport, ...rowsToExport]
      : [columnsToExport, ...rowsToExport];

    const packingSlip = () => {
      const allOrders = body
        .map((e, i) => {
          if (i !== 0) {
            if (!!e[15]?.text) {
              e[26] = e[15]?.text
                ?.split(/[\s,]+/)
                .slice(1, 4)
                .join();
            } else {
              e[26] = e[5].text
                .split(/[\s,]+/)
                .slice(1, 4)
                .join();
            }
            return e;
          } else {
            return false;
          }
        })
        .filter(Boolean);

      return _.chain(
        allOrders.sort((a, b) => {
          if (parseInt(a[17]?.text) > parseInt(b[17]?.text)) {
            return 1;
          } else if (parseInt(a[17]?.text) < parseInt(b[17]?.text)) {
            return -1;
          } else {
            return 0;
          }
        })
      )
        .map((e) => e[17]?.text || "")
        .uniq()
        .value()
        .sort()
        .map((e) => {
          return allOrders
            .filter((el) => {
              return parseInt(el[17]?.text || 0) === parseInt(e);
            })
            .sort((a, b) => {
              if (
                parseInt(
                  a[5]?.text
                    .split(/[\s,]+/)
                    .slice(0)
                    .join()
                ) >
                parseInt(
                  b[5]?.text
                    .split(/[\s,]+/)
                    .slice(0)
                    .join()
                )
              ) {
                return 1;
              } else if (
                parseInt(
                  b[5]?.text
                    .split(/[\s,]+/)
                    .slice(0)
                    .join()
                ) <
                parseInt(
                  b[5]?.text
                    .split(/[\s,]+/)
                    .slice(0)
                    .join()
                )
              ) {
                return -1;
              } else {
                return 0;
              }
            });
        })
        .flat(1)
        .filter((el) => el[21]?.text === "Yes")
        .map((e, i) => {
          return PackageSlip({ data: e });
        });
    };
    const docDefintion = {
      pageOrientation: PDF_PAGE_ORIENTATION,
      footer,
      content: packingSlip(),
    };

    return docDefintion;
  })();
}
