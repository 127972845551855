import types from "../../types";

const initialState = {
  hotCredentials: undefined,
};

const hotCredintialsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_HOT_CREDINTIALS:
      return {
        ...state,
        hotCredentials: payload,
      };
    default:
      return state;
  }
};

export default hotCredintialsReducer;
