export const claims = ({ tables, programFields }) => {
  const statusesOfClaims = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Claims;
  return {
    claimStatus: () => ({
      labels: statusesOfClaims?.map(({ statusName }) => statusName),
      data: statusesOfClaims?.map(
        ({ statusName }) =>
          tables?.Claims?.filter((e) => e?.claimStatus === statusName)?.length
      ),
      backgroundColor: statusesOfClaims?.map(({ statusColor }) => statusColor),
    }),
  };
};
