import { formatCurrency } from "../../../../../utils";
import {
  calculateInvoiceStatus,
  categoriesName,
  getSums,
  groupBy,
} from "../../../calculatingFunctions";

export const invoices = ({ tables, programFields }) => {
  const invoiceStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = invoiceStatus?.statusName?.map((status) =>
    calculateInvoiceStatus({ table: tables?.invoices, status })
  );

  return {
    invoiceStatus: () => ({
      content: invoiceStatus?.statusName?.map((status, idx) => ({
        color: invoiceStatus?.statusColor[idx],
        unfilledColor: "#F5F5F5",
        percent: (
          (calculateInvoiceStatus({ table: tables?.invoices, status })?.length /
            tables?.invoices?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status,
        done: calculateInvoiceStatus({ table: tables?.invoices, status })
          ?.length,
        custom:
          tables?.invoices &&
          groupBy(statusData, "projectName")?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Invoice Amount With Tax": formatCurrency(
                    getSums({ data: statusData, key: "totalInvoiceAmount" })[
                      index
                    ] || 0
                  ),
                  "Invoice Amount Without Tax": formatCurrency(
                    getSums({ data: statusData, key: "subtotal" })[index] || 0
                  ),
                  "Total Tax": formatCurrency(
                    (getSums({ data: statusData, key: "totalInvoiceAmount" })[
                      index
                    ] || 0) -
                      (getSums({ data: statusData, key: "subtotal" })[index] ||
                        0)
                  ),
                },
              },
              ...Object.entries(el).map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" })
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "subtotal" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" }) -
                        getSums({ data: value, key: "subtotal" })
                    ),
                  },
                };
              }),
            ];
          })[idx],
      })),
    }),
  };
};
