import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../../../utils";
import { Alert, Button, message, Space, Table, Tag } from "antd";
import { useHoistPriceCalculatorContext } from "../../context";
import { columnDefsDetailedFloor } from "./columnDefs/columnDefsDetailedFloor";
import { AgGridReact } from "ag-grid-react";
import {
  FloorDetailsProvider,
  FLOOR_DETAILS_TYPES,
  useFloorDetailsContext,
} from "./context/floorDetailsContext";
import { MondayButton } from "../../../../../../../commonComponents";
import { useRedux } from "../../../../../../hooks";
import Swal from "sweetalert2";

const FloorDetails = ({ indices }, ref) => {
  const [dataSrcRef, setDataSrc] = useState([]);
  const { state = {}, dispatch } = useFloorDetailsContext();
  const [gridData, setTakeOffData] = useRedux("takeOffTableData");
  const { serviceIndex = 0, optionIndex = 0, elevationIndex = 0 } = indices;
  console.log("indices", indices);
  const elevation =
    gridData?.[serviceIndex]?.serviceOptions?.[optionIndex]?.[elevationIndex] ??
    {};
  const getDataSrc = () => {
    // let dataSrc = [];
    // let toReturn = [];

    // elevation?.items?.map((item) => {
    //   let temp = {
    //     floor: item?.floor,
    //     floor_height: item?.floor_height,
    //   };
    //   if (Array.isArray(item?.addons)) {
    //     item?.addons?.forEach((addon) => {
    //       let tempName =
    //         addon?.name?.values?.find?.(({ name = "" }) =>
    //           name?.toLowerCase()?.includes("name")
    //         )?.value ?? "";
    //       let tempPPU = tempName?.toLowerCase()?.includes("runbacks")
    //         ? 1000
    //         : undefined;
    //       console.log("tempPPU", tempPPU);
    //       toReturn.push({
    //         floor: item?.floor,
    //         floor_height: item?.floor_height,
    //         name: tempName,
    //         height: forceToNumber(addon?.height),
    //         length: forceToNumber(addon?.length),
    //         width: forceToNumber(addon?.width),
    //         description: addon?.description,
    //         unitPrice: tempPPU,
    //         price: forceToNumber(addon?.length) * forceToNumber(tempPPU),
    //       });
    //     });
    //   }
    // });
    // console.log("elevationItems422", elevation);
    dispatch({ type: FLOOR_DETAILS_TYPES.INITIALIZE, payload: elevation });
    // setDataSrc(toReturn);
  };

  const onEditableCellChange = useCallback((params) => {
    console.log("onCellValueChangedma", params);
    let editReferences = {
      addonName: params.data?.name ?? "",
      formula: params.data?.formula ?? "",
      oldValue: params?.oldValue,
      newValue: forceToNumber(params?.newValue),
      column: params?.colDef?.field,
      floor: params.data?.floor,
    };
    if (editReferences?.column === "unitPrice") {
      dispatch({
        type: FLOOR_DETAILS_TYPES.UNIT_PRICE_CHANGE,
        payload: { elevation, editReferences },
      });
    } else if (editReferences?.column === "rentRate") {
      dispatch({
        type: FLOOR_DETAILS_TYPES.RENT_CHANGE,
        payload: { elevation, editReferences },
      });
    } else if (editReferences?.column === "totalPrice") {
    }
  }, []);

  const onSaveFloorAddons = useCallback(() => {
    try {
      let currService = gridData?.[serviceIndex];
      let currOption = currService?.serviceOptions?.[optionIndex];
      let currElevation = currOption?.[elevationIndex];
      currElevation.floorAddonsPriceConfig = state;
      let addonsPrice = forceToNumber(state?.totalities?.priceWithoutTax);
      let taxAmount =
        addonsPrice *
        forceToNumber(currElevation?.autoScheme?.TOTALITIES?.TAX_RATE);
      let totalAmount = addonsPrice + taxAmount;
      console.log("onSaveFloorAddons", {
        addonsPrice,
        taxAmount,
        currElevation,
        state,
      });
      if (!currElevation.autoScheme) {
        currElevation.autoScheme = {
          TOTALITIES: {
            ADDONS_PRICE: addonsPrice,
            TAX_AMOUNT: taxAmount,
            TOTAL_PRICE: totalAmount,
          },
        };
      } else {
        currElevation.autoScheme.TOTALITIES.ADDONS_PRICE = addonsPrice;
        currElevation.autoScheme.TOTALITIES.TAX_AMOUNT += taxAmount;
        currElevation.autoScheme.TOTALITIES.TOTAL_PRICE += totalAmount;
      }
      currElevation.totalPrice = forceToNumber(
        currElevation.autoScheme.TOTALITIES.TOTAL_PRICE
      );
      // currElevation.addonsPrice = addonsPrice;
      currElevation.addonsTotal = totalAmount;
      let autoFullPrice = message.success("Configurations Saved Successfully");
      setTakeOffData(gridData);
    } catch (e) {
      console.log("error", e);
      Swal.fire({
        title: "Error",
        text: "Error Saving the Configurations, please configure main price first",
        icon: "error",
      });
    }
    console.log("onSaveFloorAddons", elevation);
  }, [gridData, serviceIndex, optionIndex, elevationIndex, state]);
  const onDelete = useCallback(() => {
    let currService = gridData?.[serviceIndex];
    let currOption = currService?.serviceOptions?.[optionIndex];
    let currElevation = currOption?.[elevationIndex];
    if (!!currElevation?.floorAddonsPriceConfig) {
      delete currElevation.floorAddonsPriceConfig;
      message.error("Configurations Deleted Successfully");
      setTakeOffData(gridData);
    } else {
      message.error("No Floor Addons Config to delete");
    }
    console.log("onSaveFloorAddons", elevation);
  }, [gridData, serviceIndex, optionIndex, elevationIndex, state]);

  useEffect(() => {
    getDataSrc();
  }, [elevation]);
  return (
    <div className="addons-preview-card">
      <div className="addons-header">Detailed Floor View Calculator</div>
      {/* <Table columns={columnDefsDetailedFloor} dataSource={dataSrcRef} /> */}
      {/* <div className="addons-content">
        <div className="addon-price-preview" key={45}>
          <div>This is Test</div>
          <div>{formatCurrency(forceToNumber(7784))}</div>
        </div>
      </div> */}
      <div
        style={{
          padding: "20px",
        }}
      >
        {Array.isArray(state?.floorDetailsRows) &&
        state?.floorDetailsRows?.length > 0 ? (
          <Alert
            message="Do you want to include the floor addons in the price?"
            type="success"
            showIcon
            action={
              <div style={{ display: "flex", gap: "20px" }}>
                <Button size="small" type="primary" onClick={onSaveFloorAddons}>
                  Yes, Save
                </Button>
                <Button size="small" type="primary" danger onClick={onDelete}>
                  No, Delete configuration
                </Button>
              </div>
            }
            closable={false}
          />
        ) : (
          <Alert
            message="No Applicable Data Here. You can start adding addons to the floor to see the applicable prices."
            type="warning"
            showIcon
            closable
          />
        )}
      </div>
      <div className="agGridContainer">
        <div className="light-ag-theme documentationsTable ag-theme-alpine">
          <AgGridReact
            headerHeight={50}
            columnDefs={columnDefsDetailedFloor}
            rowData={
              Array.isArray(state?.floorDetailsRows)
                ? state.floorDetailsRows
                : []
            }
            enableRangeSelection={true}
            suppressSizeToFit={true}
            width={"auto"}
            tooltipShowDelay={0}
            tooltipHideDelay={5000}
            suppressDragLeaveHidesColumns={true}
            // width={"100%"}
            enableCellChangeFlash={true}
            onGridReady={(params) => {
              // setScaffoldPlanksGridAPI(params.api);
              params.api.sizeColumnsToFit();
              // }
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            autoSizeAllColumns={true}
            rowHeight={40}
            defaultColDef={{
              enablePivot: true,
              editable: false,
              resizable: true,
              sortable: true,
              // tooltipComponent: CustomFormulaToolTip,
            }}
            onCellValueChanged={onEditableCellChange}
            // onCellValueChanged={(params) => {
            //   const shouldIAddEmpty = dataSrc?.every(
            //     (el) => Object.keys(el)?.length > 12
            //   );
            //   if (shouldIAddEmpty) {
            //     setTimeout(() => {
            //       setDataSrc((prev) => [
            //         ...prev,
            //         {
            //           bicType:
            //             prev?.[prev.length - 1]?.bicType > 20
            //               ? prev[prev.length - 1]?.bicType
            //               : 20,
            //         },
            //       ]);
            //     }, 200);
            //   } else {
            //     setTimeout(() => {
            //       setDataChanged((prev) => !prev);
            //     }, 200);
            //   }
            // }}
          />
          {/* <CustomFooter
                  dataSrc={dataSrc}
                  mathAction={"sum"}
                  style={footerStyle}
                  labelsKeys={[
                    {
                      key: "sqft",
                      name: "Total Sqft",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "fullDeck",
                      name: "Total Deck",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "bicLevel",
                      name: "Total Bicycle Level",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "fullDeckEveryLevel",
                      name: "Total Every Level",
                      type: "number",
                      mathAction: "sum",
                    },
                    {
                      key: "bicEveryLevel",
                      name: "Total Bicycle Every Level",
                      type: "number",
                      mathAction: "sum",
                    },
                  ]}
                /> */}
        </div>
      </div>
      <div className="addons-footer">
        {" "}
        <div>
          Rent:{" "}
          {formatCurrency(forceToNumber(state?.totalities?.rentWithoutTax))}
        </div>
        <div>
          Total:{" "}
          {formatCurrency(forceToNumber(state?.totalities?.priceWithoutTax))}
        </div>
      </div>
    </div>
  );
};

export default FloorDetails;

const data = [
  {
    key: "1",
    floor: "1",
    name: "Ramps",
    height: 32,
    length: 32,
    width: 32,
    unitPrice: 1000,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    floor: "2",
    name: "Planks",
    age: 42,
    height: 32,
    length: 32,
    width: 32,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    floor: "3",
    height: 32,
    length: 32,
    width: 32,
    name: "Additionals",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
