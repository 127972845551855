import axios from "axios";

export const uriConfig = {
  locale:
    process.env.NODE_ENV === "production"
      ? "http://localhost:8080/api/fetchByList/prod"
      : "http://localhost:8080/api/fetchByList",
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/api/fetchByList/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/api/fetchByList",
};

/**
 * Function that filter table values against a list of matches
 * @param {string} tableName
 * @param {string} keyPath
 * @param {Array<any>} matchList
 */
async function fetchByList(tableName, keyPath, matchList) {
  return axios
    .post(uriConfig.online, {
      tableName,
      keyPath,
      matchList,
    })
    .then(({ data }) => data);
}

export default fetchByList;
