export const generateInputFields = () => [
  {
    label: "Invoice date",
    type: "datepicker-moment",
    name: "invoiceDate",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Invoice Date",
    format: "MM/DD/YYYY",
  },
  {
    label: "Due date",
    name: "invoiceDueDate",
    type: "datepicker-moment",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Due date",
    format: "MM/DD/YYYY",
  },
  {
    label: "QuickBooks Number",
    onContextMenu: () => false,
    name: "quickBooksNumber",
    type: "number",
    placeholder: "QuickBooks Number",
    className: "inputForm",
  },
];
