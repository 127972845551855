import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form } from "antd";

import { useDispatchProgramFields } from "src/hooks";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { AUDIT_FIELD_NAME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { SheetsFieldType } from "../../../../../../SidebarPages/FleetMaintenanceView/types";
import { TickIcon } from "../../../Roles/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "../VehicleSheetsConfig/VehicleSheetsConfig.scss";

function LocationSheetConfig({ open, onCancel, onNewConfig = () => {} }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const [form] = Form.useForm();

  /** @type {SheetsFieldType|undefined} */
  const sheetsConfig = useMemo(() => {
    if (!programFields) {
      return undefined;
    }

    return programFields?.find(
      ({ fieldName }) => fieldName === AUDIT_FIELD_NAME
    );
  }, [programFields]);

  useEffect(() => {
    if (!sheetsConfig) {
      return;
    }

    const key = process.env.NODE_ENV === "production" ? "prod" : "dev";

    form.setFieldsValue(sheetsConfig.fieldOptions?.[key].locationsConfig);
  }, [sheetsConfig]);

  const { dispatchFields } = useDispatchProgramFields(
    sheetsConfig?.fieldId,
    sheetsConfig?.fieldName
  );

  async function onConfirm() {
    try {
      var values = await form.validateFields();
    } catch {
      return;
    }

    try {
      const key = process.env.NODE_ENV === "production" ? "prod" : "dev";

      await dispatchFields({
        ...(sheetsConfig?.fieldOptions || {}),
        [key]: {
          ...(sheetsConfig?.fieldOptions?.[key] || {}),
          locationsConfig: {
            ...values,
            listRow: +values.listRow,
          },
        },
      });

      setTimeout(() => {
        onNewConfig();
      }, 1000);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      onCancel();
    }
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        title: "Locations List Configuration",
        centered: true,
        className: `vehicle-sheets-configuration ${
          isDarkMode ? "vehicle-sheets-config-dark" : ""
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
            }}
            key="cancel"
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onConfirm,
            }}
            key="confirm"
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <Form form={form}>
        <InputComponent
          {...{
            label: "Sheet Id",
            formItemName: "sheetId",
            required: true,
          }}
        />
        <InputComponent
          {...{
            label: "Sheet Name",
            formItemName: "sheetName",
            required: true,
          }}
        />
        <InputComponent
          {...{
            label: "List Column",
            formItemName: "listColumn",
            required: true,
            rules: [
              {
                async validator(_, value) {
                  for (let i = 0; i < value.length; i++) {
                    const ch = value.charCodeAt(i);
                    if (ch < 65 || ch > 90) {
                      return Promise.reject("*Column name is not valid");
                    }
                  }

                  return Promise.resolve();
                },
              },
            ],
          }}
        />
        <InputComponent
          {...{
            label: "List Row",
            formItemName: "listRow",
            required: true,
            rules: [
              {
                async validator(_, value) {
                  if (isNaN(+value)) {
                    return Promise.reject("*Value must be a number");
                  }
                  return Promise.resolve();
                },
              },
            ],
          }}
        />
      </Form>
    </Modal>
  );
}

export default LocationSheetConfig;
