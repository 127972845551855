import { debounce } from "lodash";

export const inputFields = ({ rowData, form, editMode, onEmailInput }) => {
  return [
    {
      label: "First Name",
      formItemName: "firstName",
      placeholder: "First name...",
      dataTestid: "firstNameField",
      type: "input",
      required: true,
    },
    {
      label: "Last Name",
      formItemName: "lastName",
      placeholder: "Last name...",
      dataTestid: "lastNameField",
      type: "input",
      required: true,
    },
    {
      label: "Email",
      formItemName: "userName",
      placeholder: "Email...",
      dataTestid: "emailField",
      onChange: debounce(onEmailInput, 500),
      type: "email",
      required: true,
      rules: [
        {
          type: "email",
          message: "Please enter a valid email!",
        },
        {
          validator: (_, value) => {
            const role = form.getFieldValue("role") || "";
            const foundUser = rowData.find(
              (el) => el.userName.toLowerCase() === value?.toLowerCase()
            );
            if (foundUser) {
              return Promise.reject(
                new Error(
                  `${
                    foundUser?.engineerId ? "Engineer" : "Architect"
                  } with this email already exists!`
                )
              );
            } else if (foundUser && foundUser?.role === "engineerArchitect") {
              return Promise.reject(
                new Error(
                  "An Engineer and an Architect with this email already exists!"
                )
              );
            } else if (foundUser && foundUser?.role === role) {
              return Promise.reject(
                new Error(
                  `${
                    foundUser?.engineerId ? "Engineer" : "Architect"
                  } with this email already exists!`
                )
              );
            } else return Promise.resolve();
          },
        },
      ],
    },
    {
      label: "Role",
      formItemName: "role",
      placeholder: "Select Role...",
      dataTestid: "roleField",
      type: "select",
      disabled: true,
      required: true,
      rules: [
        {
          validator: (_, value) => {
            const email = form.getFieldValue("email") || "";
            const foundUser = rowData.find(
              (el) => el.userName.toLowerCase() === email?.toLowerCase()
            );
            if (foundUser && value && foundUser?.role === "engineerArchitect") {
              return Promise.reject(
                new Error(
                  `An Engineer and an Architect with this email already exists!`
                )
              );
            } else if (foundUser && value && foundUser?.role === value) {
              return Promise.reject(
                new Error(
                  `You can select only ${
                    foundUser?.engineerId ? "Architect" : "Engineer"
                  } role for this email!`
                )
              );
            } else return Promise.resolve();
          },
        },
      ],
      customOptions: [
        { label: "Engineer", value: "engineer" },
        { label: "Architect", value: "architect" },
        { label: "Engineer/Architect", value: "engineerArchitect" },
      ],
    },
  ];
};
