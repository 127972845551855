function checkPicker(fields, formItemName) {
  let dynamicField = fields?.find(
    ({ formItemName: fN }) => fN === formItemName
  );
  if (dynamicField) {
    return (
      dynamicField?.type?.toLowerCase() === "datepicker" ||
      dynamicField?.type?.toLowerCase() === "timepicker"
    );
  }
  return false;
}

export default checkPicker;
