import { useState, useMemo } from "react";
import { Form, Modal, Radio, Select, Tooltip, message } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import { formatCategoryName } from "../../../../../pages/Settings/settingsComponents/DocumentationConfiguration/View/utils";
import { InputComponent, LabeledInput } from "../../../../Fleet/components";
import { validateForm } from "../../../../Fleet/utils";
import { CheckOutlined } from "@ant-design/icons";
import { antdModalProps, updateDocumentTitle } from "../../../../../../utils";
import { useSelector } from "react-redux";
import { DropDownArrow } from "../../../../Fleet/components/InputComponent/assets";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

import "./DocumentationSelectorModal.scss";

const FORM_ITEM_NAME = "docType";
const SAVE_AS_TYPE = "saveAsType";
const DocumentationSelectorModal = ({
  visible,
  setVisible,
  setDocType,
  filteredDocs,
  documentation,
  categoryConfiguration,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [saveAsTypeShow, setSaveAsTypeShow] = useState(false);
  const [originalDocuments, setOriginalDocuments] = useState([]);
  const [selectedOriginalDocument, setSelectedOriginalDocument] =
    useState(false);

  const { categoryName = "", documentationsAvailable = [] } =
    categoryConfiguration;

  const [form] = Form.useForm();

  const radioValue = Form.useWatch(SAVE_AS_TYPE, form);
  const documentType = Form.useWatch(FORM_ITEM_NAME, form);

  const docStatuses = useMemo(() => {
    return (
      categoryConfiguration?.documentationsAvailable.find(
        (el) => el.docName === documentType
      )?.docStatuses || []
    );
  }, [categoryConfiguration, documentType]);

  const options = documentationsAvailable.map(({ docName }) => ({
    label: docName,
    value: docName,
  }));

  const onNext = () => {
    if (
      radioValue !== "original" &&
      !selectedOriginalDocument &&
      !!originalDocuments?.length
    ) {
      message.error({
        key: "onNextError",
        content: "Please select an original Document to connect",
        duration: 1.8,
      });
      return;
    }
    validateForm(form, () => {
      const formData = form.getFieldsValue();
      setDocType({ ...formData, docId: selectedOriginalDocument });
      closeModal();
    });
  };

  const closeModal = () => {
    setVisible(false);
    updateDocumentTitle(); // reset document title to "Lead Manager";
  };

  const onDocTypeSelect = (type) => {
    const docTypeRepeated = (documentation || []).filter((doc) => {
      return !doc?.renewalId && !doc?.revisedId && doc?.docType === type;
    });
    setSaveAsTypeShow(!!docTypeRepeated?.length);
    setOriginalDocuments(docTypeRepeated);
    // setSaveDisabled(docTypeRepeated);
  };

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        setVisible: closeModal,
        title: `New ${formatCategoryName(categoryName)} Documentation`,
        afterOpenChange: (event) => {
          event &&
            updateDocumentTitle({
              newTitle: `New ${formatCategoryName(categoryName)} Documentation`,
            });
        },
        wrapClassName: `cardLayoutModal documentationSelectorModal ${
          isDarkMode && "documentationSelectorModalDark"
        }`,
        onCancel: closeModal,
        footer: [
          <div className="docSelectorActionButtons">
            {/* <MondayButton
              {...{
                className: "cancelButton",
                Icon: <CloseOutlined />,
                onClick: () => closeModal(),
              }}
            >
              Cancel
            </MondayButton> */}
            <Tooltip
              placement="bottom"
              trigger="hover"
              title="You can open documentation modal here by clicking next"
            >
              <MondayButton
                {...{
                  className: "saveButton",
                  Icon: <CheckOutlined />,
                  onClick: onNext,
                }}
              >
                Next
              </MondayButton>
            </Tooltip>
          </div>,
        ],
      }}
    >
      <Form {...{ form, preserve: false }}>
        <div className="selectorContainer">
          <Tooltip
            placement="bottom"
            trigger="hover"
            title="Here you can select documentation type"
          >
            <LabeledInput
              {...{
                label: "Documentation Type",
                className: "docSelector",
                formItemName: FORM_ITEM_NAME,
                required: true,
              }}
            >
              <Select
                {...{
                  options: options,
                  showSearch: true,
                  allowClear: true,
                  suffixIcon: <DropDownArrow />,
                  popupClassName: isDarkMode && "darkDropDown",
                  showSearch: true,
                  onSelect: onDocTypeSelect,
                }}
              />
            </LabeledInput>
          </Tooltip>
          {saveAsTypeShow && (
            <InputComponent
              type="radio"
              label={"Create As"}
              formItemName={SAVE_AS_TYPE}
              onChange={(e) => {
                if (e.target.value === "original") {
                  setSelectedOriginalDocument(false);
                }
              }}
              required={true}
              value={radioValue}
              options={[
                <Radio value={"original"} key={0}>
                  Original
                </Radio>,
                <Radio value={"revised"} key={1}>
                  Revised
                </Radio>,
                <Radio value={"renewal"} key={2}>
                  Renewal
                </Radio>,
              ]}
            />
          )}
          {saveAsTypeShow && radioValue && radioValue !== "original" && (
            <div
              className="original-cards"
              style={{
                height: originalDocuments?.length >= 3 * 54,
                maxHeight: 162,
              }}
            >
              {(originalDocuments || []).map((el) => {
                const statusBg = docStatuses.find(
                  (status) => status.statusName === el.docStatus
                )?.statusColor;

                return (
                  <div
                    key={el?.docId}
                    className={`original-card-container ${
                      selectedOriginalDocument === el?.docId ? "selected" : ""
                    }`}
                    onClick={() => setSelectedOriginalDocument(el?.docId)}
                  >
                    <div className="card-name">
                      <div className="doc-title">{el?.docType}</div>
                      <div className="doc-created">
                        <b style={{ marginRight: 10 }}>Created At:</b>
                        {dayjsNY(el?.createdAt).format("MM/DD/YYYY, hh:mm a")}
                      </div>
                    </div>
                    <div
                      className="doc-status"
                      style={{ backgroundColor: statusBg || "#ecde7c" }}
                    >
                      {el?.docStatus}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default DocumentationSelectorModal;
