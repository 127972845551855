import React from "react";
import InfoMessage from "../../../InfoMessage/InfoMessage";
import { Alert } from "antd";

const Header = () => {
  return (
    <div className="header">
      <InfoMessage
        text={
          "Please choose the rows you'd like to address for conflict resolution."
        }
      />
      <Alert
        message="Note: Unselected rows will not be imported."
        type="warning"
        style={{ height: "30px" }}
      />
    </div>
  );
};

export default Header;
