import { Modal } from "antd";
import React from "react";

import { modalFooterStyle } from "../../../../../../commonComponents/ExportedStylesForIcons/exportedStylesForIcons";

import { MondayButton } from "../../../../../../commonComponents";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../Roles/src";

import Geofence from "../../../../../../commonComponents/Map/Geofence/Geofence";
import { useAutoGenerateLatLng } from "../../../../../../commonComponents/Map/Geofence/useAutoGenerateLatLng";

const ShowDetailsCreationModal = ({
  openModal,
  setOpenModal,
  handleSave,
  setGeoFenceInfo,
  isDarkMode,
  setUnsavedLogs,
}) => {
  const { resolvedLocation } = useAutoGenerateLatLng(
    openModal.coordinates,
    openModal.recordAddress
  );

  const dynamicCoordinates = {
    lat:
      typeof openModal?.coordinates?.lat === "object"
        ? openModal?.coordinates?.lat?.lat
        : resolvedLocation?.lat,
    lng:
      typeof openModal?.coordinates?.lng === "object"
        ? openModal?.coordinates?.lng?.lng
        : resolvedLocation?.lng,
  };

  const rowData = {
    projectAddress: openModal?.recordAddress || "",
    opportunityAddress: openModal?.recordAddress || "",
    accountName: openModal?.accountName || "",
  };

  return (
    <Modal
      width={1000}
      centered
      title={"Geofence Details" + " (" + openModal.recordAddress + ")"}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      closeIcon={<XIcon />}
      className={`geofenceDetailsModal ${
        isDarkMode && "geofenceDetailsModalDark"
      }`}
      footer={
        <div style={modalFooterStyle}>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={() => setOpenModal(false)}
          >
            Close
          </MondayButton>
          <MondayButton Icon={<TickIcon />} onClick={handleSave}>
            Save
          </MondayButton>
        </div>
      }
    >
      <Geofence
        {...{
          rowData,
          radius: openModal?.radius,
          geoFenceInfo: openModal?.geoFenceInfo,
          setGeoFenceInfo: setGeoFenceInfo,
          setUnsavedLogs,
          places: [
            {
              coordinates: dynamicCoordinates,
              address: openModal?.recordAddress,
            },
          ],
        }}
      />
    </Modal>
  );
};

export default ShowDetailsCreationModal;
