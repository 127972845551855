import { Tag } from "antd";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../../../../utils";

export const columnDefsDetailedFloor = [
  {
    headerName: "Floor/s",
    field: "floor",
    editable: false,
    sort: "desc",
    cellRendererFramework: ({ value = "" }) => (
      <Tag color={"green"} key={value}>
        Floor {value === -2 ? "sub-cellar" : value === -1 ? "cellar" : value}
      </Tag>
    ),
  },
  // {
  //   headerName: "Floor Height",
  //   field: "floor_height",
  //   editable: false,

  //   // render: (text) => (
  //   //   <Tag color={"green"} key={text}>
  //   //     {text}
  //   //   </Tag>
  //   // ),
  // },
  {
    headerName: "Name",
    field: "name",
    editable: false,

    // render: (text) => (
    //   <Tag color={"geekblue"} key={text}>
    //     {text}
    //   </Tag>
    // ),
  },
  {
    headerName: "Length",
    field: "length",
    editable: false,

    // render: (text) => (
    //   <Tag color={text > 0 ? "volcano" : "red"} key={text}>
    //     {text}
    //   </Tag>
    // ),
  },
  {
    headerName: "Width",
    field: "width",
    editable: false,

    // render: (text) => (
    //   <Tag color={text > 0 ? "volcano" : "red"} key={text}>
    //     {text}
    //   </Tag>
    // ),
  },
  {
    headerName: "Height",
    field: "height",
    editable: false,

    // render: (text) => (
    //   <Tag color={text > 0 ? "volcano" : "red"} key={text}>
    //     {text}
    //   </Tag>
    // ),
  },
  {
    headerName: "Depth",
    field: "depth",
    editable: false,

    // render: (text) => (
    //   <Tag color={text > 0 ? "volcano" : "red"} key={text}>
    //     {text}
    //   </Tag>
    // ),
  },
  {
    headerName: "Unit Price",
    field: "unitPrice",
    editable: true,
    valueFormatter: (params) => formatCurrency(forceToNumber(params.value)),
  },
  {
    headerName: "Rent % Included.",
    field: "rentRate",
    editable: true,
    // valueFormatter: (params) => formatCurrency(forceToNumber(params.value)),
    valueFormatter: (params) => `${forceToNumber(params?.value)}%`,
    valueGetter: (params) => {
      let currRentRate = forceToNumber(params.data?.rentRate);
      let rent = forceToNumber(params.data?.unitPrice) * (currRentRate / 100);
      params.data.rent = rent;
      params.data.totalRent = rent + forceToNumber(params.data?.additionalRent);
      return forceToNumber(params.data?.rentRate);
    },
    // render: (text) => formatCurrency(forceToNumber(text)),
  },
  {
    headerName: "Rent Amount",
    field: "rent",
    editable: false,
    // valueFormatter: (params) => formatCurrency(forceToNumber(params.value)),
    // valueFormatter: (params) => `${forceToNumber(params?.value)}%`,
    valueGetter: (params) => {
      return (
        forceToNumber(params.data?.unitPrice) *
        forceToNumber(params.data?.rentRate / 100)
      );
    },
    valueFormatter: (params) => formatCurrency(forceToNumber(params?.value)),

    // render: (text) => formatCurrency(forceToNumber(text)),
  },
  {
    headerName: "Add Rent %.",
    field: "addRentRate",
    editable: true,
    // valueFormatter: (params) => formatCurrency(forceToNumber(params.value)),
    valueFormatter: (params) => `${forceToNumber(params?.value)}%`,
    valueGetter: (params) => {
      let currRentRate = forceToNumber(params.data?.addRentRate);
      let rent = forceToNumber(params.data?.unitPrice) * (currRentRate / 100);
      params.data.addRentMonth = rent;
      params.data.additionalRent = rent;
      // params.data.totalRent = rent + forceToNumber(params.data?.additionalRent);
      return forceToNumber(params.data?.rentRate);
    },
    // render: (text) => formatCurrency(forceToNumber(text)),
  },
  {
    headerName: "Add Rent Amount (Monthly)",
    field: "addRentMonth",
    editable: false,
    // valueFormatter: (params) => formatCurrency(forceToNumber(params.value)),
    // valueFormatter: (params) => `${forceToNumber(params?.value)}%`,
    valueGetter: (params) => {
      return (
        forceToNumber(params.data?.unitPrice) *
        forceToNumber(params.data?.addRentRate / 100)
      );
    },
    valueFormatter: (params) => formatCurrency(forceToNumber(params?.value)),

    // render: (text) => formatCurrency(forceToNumber(text)),
  },
  {
    headerName: "No. of Additional. Rent",
    field: "noOfAdditionalRent",
    editable: true,
    // valueFormatter: (params) => formatCurrency(forceToNumber(params.value)),
    valueGetter: (params) => {
      let addRent = forceToNumber(
        params?.data?.noOfAdditionalRent *
          forceToNumber(params.data?.addRentMonth)
      );
      params.data.additionalRent = addRent;
      return forceToNumber(params.data?.noOfAdditionalRent);
    },
    valueFormatter: (params) => `${forceToNumber(params?.value)}`,
    // render: (text) => formatCurrency(forceToNumber(text)),
  },
  {
    headerName: "Additional. Rent",
    field: "additionalRent",
    editable: false,
    // valueGetter: (params) => {
    //   return (
    //     forceToNumber(params.data?.unitPrice) *
    //     forceToNumber(params.data?.noOfAdditionalRent)
    //   );
    // },
    valueFormatter: (params) => formatCurrency(forceToNumber(params?.value)),

    // render: (text) => formatCurrency(forceToNumber(text)),
  },

  {
    headerName: "Total Price",
    field: "totalPriceAndRent",
    editable: false,
    valueGetter: (params) => {
      params.data.totalPriceAndRent =
        forceToNumber(params.data?.totalPrice) +
        forceToNumber(params.data?.additionalRent);
      return (
        forceToNumber(params.data?.totalPrice) +
        forceToNumber(params.data?.additionalRent)
      );
    },
    valueFormatter: (params) => formatCurrency(forceToNumber(params?.value)),

    // render: (text) => formatCurrency(forceToNumber(text)),
  },
  {
    headerName: "Description",
    field: "description",
    editable: true,

    // render: (text) => (
    //   <Tag color={"volcano"} key={text}>
    //     {text}
    //   </Tag>
    // ),
  },
];
