import { useState } from "react";
import { useSelector } from "react-redux";
import { TreeItem } from "../TreeItem";
import { TreeGraph } from "../TreeGraph";
import styles from "./TreeCollapse.module.scss";
import { TreeTextHeader } from "../TreeTextHeader";
import GeofenceLogs from "../TreeNode/GeofenceLogs";
import { DropdownIcon } from "../../../../../icons";

const headerStyles = {
  width: "150px",
  textAlign: "center",
  marginTop: "10px",
  marginLeft: "5px",
};

const TreeCollapse = ({ log, isCostumed }) => {
  const [collapsed, setCollapsed] = useState(true);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { logs } = log;

  const hasLogs = isCostumed ? isCostumed : logs.length > 0;

  return (
    <div
      className={
        styles[
          isDarkMode
            ? "tree-collapse-container-dark"
            : "tree-collapse-container"
        ]
      }
      // style={{ overflow: "auto" }}
    >
      <div
        className={
          styles[isDarkMode ? "tree-collapse-body-dark" : "tree-collapse-body"]
        }
        data-testid="tree-collapse-body"
        onClick={() => hasLogs && setCollapsed((prev) => !prev)}
      >
        <TreeItem {...{ item: log, isParent: true }} />
        <div
          style={{
            visibility: hasLogs ? "visible" : "hidden",
          }}
          className={
            styles[
              isDarkMode ? "tree-collapse-icon-dark" : "tree-collapse-icon"
            ]
          }
        >
          <DropdownIcon
            style={
              collapsed
                ? {
                    transition: ".3s ease",
                    color: isDarkMode ? "#fff" : "#333238",
                  }
                : {
                    transform: "rotate(180deg)",
                    transition: ".3s ease",
                    color: isDarkMode ? "#fff" : "#333238",
                  }
            }
          />
        </div>
      </div>
      {!collapsed && (
        <div className={styles["tree-collapse-items"]}>
          <div
            className={styles["tree-collapse-items-header"]}
            hidden={isCostumed}
          >
            <div className={styles["vertical-line"]} hidden={isCostumed} />
            <div className={styles["horizontal-line"]} hidden={isCostumed} />
            <div hidden={isCostumed}>
              <TreeTextHeader
                {...{ value: "Updated Key", style: headerStyles }}
              />
            </div>
            <div
              className={styles["tree-collapse-items-header-container"]}
              hidden={isCostumed}
            >
              <div className={styles["tree-collapse-items-header-title"]}>
                <TreeTextHeader {...{ value: "Previous Value" }} />
                <TreeTextHeader {...{ value: "Updated Value" }} />
              </div>
            </div>
          </div>
          {isCostumed ? (
            <GeofenceLogs {...{ log }} />
          ) : (
            <TreeGraph {...{ logs, actionType: log.actionType }} />
          )}
        </div>
      )}
    </div>
  );
};

export default TreeCollapse;
