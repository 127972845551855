import React from "react";
import { Modal } from "antd";

import { Header } from "./components/Header/Header";
import { useRedux } from "../../../../../hooks/useRedux";
import { afterOpenChange } from "./utils/afterOpenChange";

import OtherModalsWrapper from "./hoc/OtherModalsWrapper";
import { LoadableComp } from "../../../../../XComponents";
import ChargeTables from "./components/ChargeTables/ChargeTables";
import { ServicesContainer } from "./components/ServicesContainer";
import { NewChargeItemProvider } from "./context/NewChargeItemContext";
import SecondaryHeader from "./components/SecondaryHeader/SecondaryHeader";
import { ProgressProvider } from "../../../../../../commonComponents/ProgressComponent/context/ProgressContext";

import "./NewChargeItems.scss";

export const NewChargeItem = (props) => {
  const [objectData] = useRedux("chargeItemsModal");
  const currentCharge = objectData?.currentCharge;
  const actionType = currentCharge ? "Update" : "Create";
  const categoryName = "Charges";

  return (
    <ProgressProvider {...{ categoryName, actionType }}>
      <NewChargeItemProvider {...{ ...props }}>
        <ChargeItem />
      </NewChargeItemProvider>
    </ProgressProvider>
  );
};

export default NewChargeItem;

export const ChargeItem = () => {
  const [objectData] = useRedux("chargeItemsModal");
  const [darkMode] = useRedux("chargesDarkMode", true, false);

  const selectedGroup = objectData?.selectedGroup;
  const whereToFind = objectData?.whereToFind;
  const currentCharge = objectData?.currentCharge;
  const isLoading = Boolean(
    whereToFind &&
      Object.keys(whereToFind).length !== 0 &&
      selectedGroup !== false &&
      selectedGroup !== undefined
  );

  return (
    <Modal
      destroyOnClose={true}
      wrapClassName="newChargeItemWrap"
      className={darkMode ? "newChargeItemModal" : "newChargeItemModal-light"}
      closable={false}
      open={true}
      // onCancel={removePreviousDataOnDestroy}
      afterOpenChange={(event) =>
        afterOpenChange({ event, objectData, currentCharge })
      }
      title={<Header />}
      footer={false}
    >
      <OtherModalsWrapper>
        <LoadableComp loading={!isLoading}>
          <SecondaryHeader />
          <div className={"newChargeItemContainer"}>
            <ServicesContainer />
            <ChargeTables />
          </div>
        </LoadableComp>
      </OtherModalsWrapper>
    </Modal>
  );
};
