import {
  applyAllHandler,
  chargeAllServiceRent,
  checkboxValue,
} from "./CheckAllFunctions";
import { useRedux } from "../../../../../../../../../../../hooks";
import "./CheckAll.scss";

// import { useDispatchContext, useStateContext } from "../../../../../../../Context/Context"

import { useParams } from "react-router-dom";
import { Checkbox, Tooltip } from "antd";
import { MondayButton } from "../../../../../../../../../../../../commonComponents";

const CheckAll = ({ elevationData }) => {
  // const { fakeRentals, isWritable, selectedService } = useStateContext()
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [isWritable] = useRedux("rentalsIsWritable");
  const [selectedService] = useRedux("rentalsSelectedService");

  // const dispatchContext = useDispatchContext()
  const { isLoading } = useParams();

  //here we extract the values used for the checkbox
  const { value, disabled, indeterminate } = checkboxValue({
    selectedService,
    elevationData,
    fakeRentals,
    isWritable,
  });
  const onChargeAllService = () => {
    return chargeAllServiceRent({
      setFakeRentals,
      selectedService,
      elevationData,
      fakeRentals,
    });
  };

  return (
    <div className="headerCheckAllContainer">
      <Tooltip title={disabled}>
        <Checkbox
          disabled={!!disabled || isLoading}
          indeterminate={indeterminate}
          checked={value}
          onChange={() =>
            applyAllHandler({
              setFakeRentals,
              selectedService,
              elevationData,
              fakeRentals,
            })
          }
        />
      </Tooltip>
      <div className="information">Charge Elevation</div>
      <MondayButton
        onClick={onChargeAllService}
        hasIcon={false}
        className={"mondayButtonBlue"}
      >
        Charge All Service
      </MondayButton>
    </div>
  );
};

export default CheckAll;
