import { PageReport } from "@grapecity/activereports/core";
import { exportDocument as PdfExport } from "@grapecity/activereports/pdfexport";
import { requestAllPages } from "../../../../utils/paginationFetchData";

export const generateReportBlob = async (report) => {
	const pageReport = new PageReport();
	await pageReport.load(report.definition);
	const doc = await pageReport.run();
	const result = await PdfExport(doc);
	return result;
};
