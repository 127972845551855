import dayjs from "dayjs";

export const fleetInspections = ({ tables, programFields }) => {
  const inspectionStatuses = programFields
    ?.find((el) => el?.fieldName === "Statuses Of Categories")
    ?.fieldOptions?.Inspections?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.statusName]: curr?.statusColor,
      }),
      {}
    );

  return {
    inspectedBy: () => ({
      statusData: tables?.["Fleet Inspections"]?.map((a) => {
        return {
          label: a?.fleetName,
          value: a?.inspectedBy,
          color: "#ECDE7D",
        };
      }),
    }), //inspectedBy
    driverName: () => ({
      statusData: tables?.["Fleet Inspections"]?.map((a) => {
        return {
          label: a?.fleetName,
          value: a?.driver,
          color: "#ECDE7D",
        };
      }),
    }), //driverName
    createdAt: () => ({
      statusData: tables?.["Fleet Inspections"]?.map((a) => {
        return {
          label: a?.fleetName,
          value: dayjs(a?.createdAt)?.format("MM/DD/YYYY"),
          color: "#ECDE7D",
        };
      }),
    }), //createdAt

    inspectionStatus: () => ({
      statusData: (tables?.["Fleet Inspections"] || [])?.map((el) => ({
        label: el?.fleetName,
        value: el?.inspectionStatus,
        color: inspectionStatuses?.[el?.inspectionStatus] || "#6D8299",
      })),
    }),

    inspectionType: () => ({
      statusData: (tables?.["Fleet Inspections"] || [])?.map((el) => ({
        label: el?.fleetName,
        value: el?.inspectionType,
        color: "#ECDE7D",
      })),
    }),

    mechanic: () => ({
      statusData: (tables?.["Fleet Inspections"] || [])?.map((el) => ({
        label: el?.fleetName,
        value: el?.mechanic,
        color: "#ECDE7D",
      })),
    }),
  }; //Inspections
};
