import { callGmailAPI } from "../../Communication/functions";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";

export const sendTaskAssignmentEmail = async (
  users,
  taskTitle,
  taskTopic,
  taskRecord,
  taskDeadline,
  taskDescription,
  taskId
) => {
  const emailPromises = users
    .filter(({ userName }) => !!userName)
    .map((user) => {
      return callGmailAPI("sendEmail", {
        to: [user.userName],
        subject: ` Task Assignment: ${taskTitle}`,
        body: `<p>Dear ${user.nameOfUser},</p>
            <br/>
            <p>I hope this email finds you well. A new task has been assigned to you through our task management system.</p>
            <p>Here are the details:</p>
            <h4>Task Details:</h4>
            <br/>
            <div style="display: flex; alignItems: flex-end; gap: 10px;" ><b>Task Name:</b><p style="margin: 0px 5px; "><a href="http://portal.corescaffold.com/tasksManager/${taskId}?tab=Tasks">${taskTitle}</a></p></div>
            <div style="display: flex; alignItems: flex-end; gap: 10px;" ><b>Topic:</b><p style="margin: 0px 5px; ">${taskTopic}</p></div>
            <div style="display: flex; alignItems: flex-end; gap: 10px;" ><b>Record:</b><p style="margin: 0px 5px; ">${taskRecord}</p></div>
            <div style="display: flex; alignItems: flex-end; gap: 10px;" ><b>Deadline:</b><p style="margin: 0px 5px; ">${dayjsNY(
              taskDeadline
            ).format("MM/DD/YYYY, HH:mm A")}</p></div>
            <div style="display: flex" ><p><b>Description:</b></p>&nbsp;${
              taskDescription || ""
            }</div>
            <br/>
            <p>Please update the task's progress within the system to keep everyone informed. Your efforts are greatly appreciated.</p>
            <p>Best regards,</p>
            <p>Core Team</p>`,
        attachments: [],
        category: "error",
        authData: false,
      });
    });

  return await Promise.all(emailPromises);
};
