import { Button, Collapse } from "antd";
import { BranchesOutlined } from "@ant-design/icons";

import "./FenceNavigator.scss";

import moment from "moment";
import { useSelector } from "react-redux";

const FenceNavigator = ({ geofences, clickedFence, close, height }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      className={`fenceNavigatorContent ${isDarkMode && "dark"}`}
      style={{ maxHeight: height, height: height }}
    >
      <div className="fenceNavigatorContentHeader">
        <Button
          style={{ backgroundColor: isDarkMode ? "#3A3C4E" : "" }}
          danger
          onClick={close}
        >
          Close
        </Button>
      </div>
      {geofences.map((geofence, idx) => {
        return (
          <Collapse
            key={geofence.shapeId}
            items={[
              {
                key: geofence.shapeId,
                label: (
                  <div className={`fenceNavigatorItem ${isDarkMode && "dark"}`}>
                    <div>#{idx + 1}</div>
                    <div>{!geofence.title ? "-||-" : geofence.title}</div>
                  </div>
                ),
                children: (
                  <div
                    className={`fenceNavigatorItemCollapsed ${
                      isDarkMode && "dark"
                    }`}
                  >
                    <div
                      className={`fenceNavigatorDisplay ${
                        isDarkMode && "dark"
                      }`}
                    >
                      <div>Created By</div>
                      <div>{geofence.createdBy}</div>
                    </div>
                    <div
                      className={`fenceNavigatorDisplay ${
                        isDarkMode && "dark"
                      }`}
                    >
                      <div>Created At</div>
                      <div>{moment(geofence.createdAt).fromNow()}</div>
                    </div>
                    <div
                      className={`fenceNavigatorDisplay ${
                        isDarkMode && "dark"
                      }`}
                    >
                      <div>Description</div>
                      {!geofence.description ? "-||-" : geofence.description}
                    </div>
                    <div
                      className={`fenceNavigatorDisplay ${
                        isDarkMode && "dark"
                      }`}
                    >
                      <div>Type of shape</div>
                      <div>
                        {geofence.type} with {geofence.geoFenceInfo.length}{" "}
                        nodes
                      </div>
                    </div>
                    <div
                      className={`fenceNavigatorDisplay ${
                        isDarkMode && "dark"
                      }`}
                    >
                      <div>Actions</div>
                      <div>
                        <Button
                          className="fenceNavigatorButton"
                          icon={<BranchesOutlined />}
                          onClick={() => clickedFence(geofence)}
                          data-testid="go-to-geofence-button"
                        >
                          Go to geofence
                        </Button>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        );
      })}
    </div>
  );
};

export default FenceNavigator;
