import { Checkbox, Tooltip, message } from "antd";

import {
  onChargeCheckBoxChange,
  onChargeSOVCheckBoxChange,
  onChargeRentalCheckBoxChange,
} from "./utils";
import { useRedux } from "../../../../../../../../../hooks/useRedux";
import { getPriceForCharge } from "../../../../utils/getPriceForCharge";

import "./ChargeCheckBox.scss";

const ChargesCheckbox = ({
  data,
  type,
  checked,
  charge,
  currentCharge,
  initialService,
  selectedService,
  setChargeToSave,
  selectedElevation,
  selectedElevationId,
}) => {
  const [chargeItemsModal] = useRedux("chargeItemsModal");
  const [isWritable] = useRedux("chargesIsWritable");

  const { categoryFrom } = chargeItemsModal;

  const isDisabled =
    data && data.priceAmount - (getPriceForCharge(data.charges) || 0) <= 0;
  const isIndeterminate = data && data.pricePercent < 100 && checked;
  const isEstimation = categoryFrom === "Estimation";
  const isRental = categoryFrom === "Rental";
  const isScheduleOfValue = categoryFrom === "Schedule Of Value";

  const handleCheckBoxChange = () => {
    if (isEstimation)
      onChargeCheckBoxChange(
        setChargeToSave,
        selectedService,
        initialService,
        selectedElevation,
        selectedElevationId,
        type,
        data
      );
    else if (isRental)
      onChargeRentalCheckBoxChange(
        setChargeToSave,
        selectedService,
        initialService,
        selectedElevation,
        selectedElevationId,
        type,
        data
      );
    else if (isScheduleOfValue)
      onChargeSOVCheckBoxChange(
        setChargeToSave,
        selectedService,
        initialService,
        selectedElevation,
        selectedElevationId,
        data,
        charge
      );
  };

  return (
    <div className="new-charge-checkbox-item">
      <Tooltip
        title={isDisabled && "This item is fully charged"}
        data-testid="tooltip-charge-checkbox"
      >
        <Checkbox
          data-testid="charge-checkbox"
          indeterminate={isIndeterminate}
          disabled={isDisabled}
          checked={checked}
          className={isDisabled ? "ant-checkbox-disabled" : ""}
          onClick={() =>
            !isWritable && currentCharge
              ? message.error("Please enable write mode")
              : handleCheckBoxChange()
          }
        />
      </Tooltip>
    </div>
  );
};

export default ChargesCheckbox;
