import React from "react";
import { Modal } from "antd";
import "./SaveScheduleModal.scss";
import { MondayButton } from "../../../../../../commonComponents/index";
import { Close } from "../../../../../../SidebarPages/DynamicView/src/index";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src/index";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
const SaveScheduleModal = ({
  openSaveModal,
  mainText,
  secondText,
  onCancel,
  onSave,
  isDarkMode,
}) => {
  return (
    <div>
      <Modal
        className={`saveScheduleModal ${isDarkMode && "saveScheduleModalDark"}`}
        centered
        title={
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.05 24.55L12.15 19.65C11.85 19.35 11.4833 19.2 11.05 19.2C10.6167 19.2 10.25 19.35 9.95 19.65C9.61667 19.9833 9.45 20.375 9.45 20.825C9.45 21.275 9.6 21.65 9.9 21.95L16 28.05C16.2667 28.3167 16.6167 28.45 17.05 28.45C17.4833 28.45 17.8333 28.3167 18.1 28.05L30.1 16.05C30.4 15.75 30.55 15.3833 30.55 14.95C30.55 14.5167 30.3833 14.1333 30.05 13.8C29.75 13.5 29.375 13.35 28.925 13.35C28.475 13.35 28.0833 13.5167 27.75 13.85L17.05 24.55ZM20 40C17.1667 40 14.5333 39.4917 12.1 38.475C9.66667 37.4583 7.55 36.05 5.75 34.25C3.95 32.45 2.54167 30.3333 1.525 27.9C0.508333 25.4667 0 22.8333 0 20C0 17.2 0.508333 14.5833 1.525 12.15C2.54167 9.71667 3.95 7.6 5.75 5.8C7.55 4 9.66667 2.58333 12.1 1.55C14.5333 0.516667 17.1667 0 20 0C22.8 0 25.4167 0.516667 27.85 1.55C30.2833 2.58333 32.4 4 34.2 5.8C36 7.6 37.4167 9.71667 38.45 12.15C39.4833 14.5833 40 17.2 40 20C40 22.8333 39.4833 25.4667 38.45 27.9C37.4167 30.3333 36 32.45 34.2 34.25C32.4 36.05 30.2833 37.4583 27.85 38.475C25.4167 39.4917 22.8 40 20 40Z"
              fill="white"
            />
          </svg>
        }
        footer={null}
        open={openSaveModal}
        closable={false}
      >
        <div className="main-container-schedule-save-modal">
          <span className="main-text-schedule-save-modal">{mainText}</span>
          <span className="second-text-schedule-save-modal">{secondText}</span>
          <div className="footer-container-schedule-save-modal">
            <MondayButton
              onClick={onCancel}
              {...{ className: "mondayButtonRed", Icon: <XIcon /> }}
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onSave}
              {...{ Icon: <TickIcon width={17} height={17} /> }}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SaveScheduleModal;
