import { Modal } from "antd";

import { Close, Tick } from "../../../../src";
import { updateDocumentTitle } from "../../../../../../../../../utils";
import {
  ExportOrEmailDropdown,
  MondayButton,
} from "../../../../../../../../commonComponents";
import { PreviewAccessCard } from "./components";
import { keys } from "../../../../RolesData";

import "./PreviewAccessModal.scss";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "../../utils/exportToPdfHandler";
import useExportHeadersModal from "../../../../../../../../../hooks/useExportHeadersModal";
import PdfHeadersModal from "../../../../../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { previewTableHeaders } from "../../constants/tableHeaders";

const PreviewAccessModal = ({
  previewAccessData,
  previewAccessVisible,
  setPreviewAccessVisible,
  additionalData,
  saveAddedLogs,
}) => {
  const closeModal = () => {
    setPreviewAccessVisible(false);
  };

  const parent = (config, element) =>
    previewAccessData[config]?.find((val) => val.title === element.title);

  const firstChild = (config, element1, element2) =>
    parent(config, element1)?.children?.find(
      (ch) => ch.title == element2.title
    );
  const secondChild = (config, element1, element2, element3) =>
    firstChild(config, element1, element2)?.children?.find(
      (chi) => chi.title == element3.title
    );

  const departmentTabs = keys(previewAccessData).filter(
    (tab) => tab !== "Notification Preferences"
  );

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData: {
        ...additionalData,
        includeNotificationPref: false,
      },
      onGeneratePDF,
      exportToExcel,
    });

  return (
    <Modal
      className="previewModal"
      title="Role access preview (Route)"
      afterOpenChange={(event) => {
        event &&
          updateDocumentTitle({ newTitle: "Role access preview (Route)" });
      }}
      open={previewAccessVisible}
      onCancel={closeModal}
      destroyOnClose={closeModal}
      closeIcon={<Close />}
      footer={
        <>
          <MondayButton
            className="mondayButtonRed"
            onClick={closeModal}
            Icon={<Close />}
          >
            Cancel
          </MondayButton>
          <ExportOrEmailDropdown
            {...{
              rowData: true,
              onGeneratePDF: (action, getDocDefinition) =>
                getDocDefinition
                  ? onGeneratePDF(
                      action,
                      additionalData,
                      getDocDefinition,
                      null,
                      saveAddedLogs,
                      false
                    )
                  : getHeadersModal(action, getDocDefinition),
              exportGridToExcel: (getDocDefinition) =>
                getDocDefinition
                  ? exportToExcel(
                      additionalData,
                      getDocDefinition,
                      null,
                      saveAddedLogs,
                      false
                    )
                  : getHeadersModal(false, getDocDefinition, "excel"),
              uploadExcelToDrive: (driveRequest, driveFolderId) =>
                uploadExcelToDrive(additionalData, driveRequest, driveFolderId),
              defaultDocDefinition: true,
              title: `RolesAccess_${additionalData.currentRole}`,
            }}
          />
          <MondayButton
            className="mondayButton"
            onClick={closeModal}
            Icon={<Tick />}
          >
            Okay
          </MondayButton>
        </>
      }
    >
      <div className="previewModalBody">
        {departmentTabs.map((tab) => (
          <div className="previewModalBodyContainer">
            <div className="categoryTitle">
              <span className="categoryTitleLabel">{tab}</span>
            </div>
            {previewAccessData[tab].map((config) => (
              <div className="elementCategoryTitleContainer">
                <PreviewAccessCard
                  {...{
                    data: config,
                    classFunction: parent(tab, config),
                    parent: true,
                    tab,
                  }}
                />
                {config?.children?.map((child) => (
                  <div className="elementCategoryTitleContainer">
                    <PreviewAccessCard
                      {...{
                        data: child,
                        style: { marginLeft: 100 },
                        classFunction: firstChild(tab, config, child),
                      }}
                    />
                    {child?.children?.map((stepChild) => (
                      <PreviewAccessCard
                        {...{
                          data: stepChild,
                          style: { marginLeft: 150 },
                          classFunction: secondChild(
                            tab,
                            config,
                            child,
                            stepChild
                          ),
                        }}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: previewTableHeaders,
            title: "Role Access",
            nested: true,
          }}
        />
      )}
    </Modal>
  );
};

export default PreviewAccessModal;
