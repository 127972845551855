class CustomLoadingOverlay {
  init(params) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = `
            <div role="presentation" class="ag-overlay-loading-center">Loading...</div>
        `;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

class CustomNoRowsOverlay {
  init(params) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = `
          <div role="presentation" class="ag-overlay-loading-center">No Data</div>
      `;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

export { CustomNoRowsOverlay, CustomLoadingOverlay };
