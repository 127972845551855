import { SimplePLI } from "./GeneralModels";

export class MeshNettingPli extends SimplePLI {
  serviceId = 72;

  length;
  height;

  fromFloor;

  toFloor;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateMeshNettingPli extends SimplePLI {
  serviceId = 72;

  length;
  height;

  fromFloor;

  toFloor;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
