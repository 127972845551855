import { CalculatorFilled } from "@ant-design/icons";
import { MondayButton } from "src/components/commonComponents";
import { useRedux } from "../../../../../../../../../../../../hooks";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../../../Context/Context";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateScheduleOfValue } from "../../../../../../../../../../../../../../actions/scheduleOfValues";
import {
  calculatePercantage,
  calculateProportionalAmount,
} from "./ElevationGridComp/rowData";
import { forceToNumber } from "../../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  formatCurrency,
  roundToDecimals,
} from "../../../../../../../../../../../../utils";
import { RestoreFromTrash } from "../../../../../../../../../../../../DynamicView/src";
import { useCallback, useMemo, useState } from "react";
import Swal from "sweetalert2";
import DragAndEvents from "./ElevationGridComp/DragAndDropEvents/DragAndEvents";

const EventsCalculatorFooter = ({ eventProps, onClose, onRestart }) => {
  const [gridApis, setGridApis] = useRedux("eventsCalculatorModalData");
  const {
    isWritable = false,
    isAutoSaving = false,
    fakeSOV = {},
    errors = {},
    darkMode = true,
    retracted = false,
  } = useStateContext();
  const dispatchContext = useDispatchContext();
  const [showDraggable, setShowDraggable] = useState(false);
  const dispatch = useDispatch();
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const [itemsToOrder, setItemsToOrder] = useState([]);
  const [distributedElevationData, setDistributedElevationData] = useRedux(
    "distributedElevationData"
  );
  const [rowDatas, setRowDatas] = useRedux("rowDatasEventsSov");
  // const [draggable]
  // const totalPriceOfSOV = totalPriceOfSOVHandler({ fakeSOV });

  const currentAchieved = useMemo(() => {
    let RESULT = 0;
    rowDatas?.forEach((rows) => {
      rows?.forEach((row) => {
        RESULT += forceToNumber(row?.amount);
      });
    });
    console.log("currentAchieved", RESULT);
    return RESULT;
  }, [rowDatas, gridApis]);

  const getRowData = (api) => {
    const rowData = [];
    api?.forEachNode((rowNode) => {
      if (!!rowNode?.data) rowData.push(rowNode.data);
    });
    return rowData;
  };

  const onConfirm = useCallback(() => {
    let CURRENT_SERVICE = eventProps?.service || false;
    if (!CURRENT_SERVICE) return;

    let allCurrentRows = structuredClone(rowDatas || []);
    let RESULT = {};
    allCurrentRows?.forEach((rows) => {
      rows?.forEach((row) => {
        initializeDataObject(RESULT, row?.name, row);
      });
    });

    console.log("onConfirm", {
      RESULT,
      allCurrentRows,
      rowDatas,
    });
    // return;
    let NEW_AMOUNTS = [];
    let tempIDX = 0;
    let differenceReference = forceToNumber(CURRENT_SERVICE?.totalPrice);
    console.log("current service", CURRENT_SERVICE);
    Object.keys(RESULT).forEach((key, idx) => {
      let particulateAmounts = RESULT[key] || [];
      let objToPush = {
        id: tempIDX,
        name: key,
        note: "",
        notes: "",
        //TODO:
        amount: 0,
        collectable_amount: 0,
        custom_tax_amount: CURRENT_SERVICE?.isTaxable
          ? forceToNumber(CURRENT_SERVICE?.taxRate)
          : 0,
        difference: 0,
        rate: 0,
        taxAmount: 0,
        retainage: 0,
        retainagePercentage: forceToNumber(CURRENT_SERVICE?.retainage),
      };
      particulateAmounts.forEach((item) => {
        objToPush.amount += item?.amount;
        objToPush.rate += calculatePercantage({
          amount: item?.amount,
          maxAmount: CURRENT_SERVICE?.totalPrice,
        });
        // objToPush.retainagePercentage += forceToNumber(
        //   CURRENT_SERVICE?.retainage
        // );
        let currRet = calculateProportionalAmount({
          amount: item?.amount,
          rate: CURRENT_SERVICE?.retainage,
        });
        objToPush.retainage += currRet;
        objToPush.collectable_amount +=
          forceToNumber(item.amount) - forceToNumber(currRet);

        objToPush.taxAmount += forceToNumber(
          CURRENT_SERVICE?.taxRate * item.amount
        );
        differenceReference -= forceToNumber(item.amount);
        console.log("differenceReference", differenceReference);
        objToPush.difference = forceToNumber(differenceReference);
        // objToPush.collectable_amount += item?.collectable_amount;
        // objToPush.custom_tax_amount += item?.custom_tax_amount;
        // objToPush.difference += item?.difference;
        // find actual rate
        // objToPush.rate += 0;
        // objToPush.retainage += 0;
        // objToPush.retainagePercentage += 0;
      });
      NEW_AMOUNTS.push(objToPush);
      tempIDX++;
    });
    console.log("NEW_AMOUNTS", NEW_AMOUNTS);
    let SOV_UPDATE = structuredClone(fakeSOV || {});
    let SERVICE_UPDATE = structuredClone(CURRENT_SERVICE || {});
    let SERVICE_UPDATE_AMOUNTS = structuredClone(NEW_AMOUNTS || []);
    SERVICE_UPDATE.amounts = SERVICE_UPDATE_AMOUNTS;
    let SERVICES = SOV_UPDATE?.services || [];
    let SERVICE_INDEX = SERVICES.findIndex(
      (item) =>
        item?.serviceId === CURRENT_SERVICE?.serviceId &&
        item?.estimationId === CURRENT_SERVICE?.estimationId
    );
    SERVICES[SERVICE_INDEX].amounts = SERVICE_UPDATE_AMOUNTS;
    SERVICES[SERVICE_INDEX].expandedAmounts = rowDatas;
    SERVICES[SERVICE_INDEX].distributedElevationData = distributedElevationData;
    SOV_UPDATE.services = SERVICES;
    Swal.fire({
      title: "Do you want to rearrange events?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No, keep the same order!",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        // dispatch(updateScheduleOfValue(fakeSOV?.scheduleId, SOV_UPDATE));
        setShowDraggable(true);
        setItemsToOrder(
          SERVICE_UPDATE_AMOUNTS?.map((e) => ({
            ...e,
            label: e?.name + " - " + formatCurrency(e?.amount),
            id: e?.id,
          }))
        );
      } else if (result.isDenied) {
        Swal.fire("Changes are saved with the same order!", "", "info");
        dispatch(updateScheduleOfValue(fakeSOV?.scheduleId, SOV_UPDATE));
        onClose();
      }
    });
  }, [rowDatas]);

  const onStartFromScratch = () => {
    console.log("onConfirm", { gridApis, fakeSOV });
    let CURRENT_SERVICE = eventProps?.service || false;
    if (!CURRENT_SERVICE) return;
    let SOV_UPDATE = structuredClone(fakeSOV || {});
    let SERVICES = SOV_UPDATE?.services || [];
    let SERVICE_INDEX = SERVICES.findIndex(
      (item) => item?.serviceId === CURRENT_SERVICE?.serviceId
    );
    SERVICES[SERVICE_INDEX].expandedAmounts = false;
    SERVICES[SERVICE_INDEX].distributedElevationData = false;
    SOV_UPDATE.services = SERVICES;
    dispatch(updateScheduleOfValue(fakeSOV?.scheduleId, SOV_UPDATE, onRestart));
  };

  console.log("EventsCalculatorFooter", { eventProps, rowDatas, gridApis });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem",
          gap: "20px",
        }}
      >
        <MondayButton
          className="mondayButtonBlue"
          hasIcon={false}
          // onClick={onStartFromScratch}
        >
          Total Price: {formatCurrency(eventProps?.service?.totalPrice)}
        </MondayButton>
        {/* <MondayButton
          className="mondayButtonYellow"
          hasIcon={false}
          // onClick={onStartFromScratch}
        >
          Balance left : {formatCurrency(currentAchieved)}
        </MondayButton> */}
      </div>{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem",
          gap: "20px",
        }}
      >
        <MondayButton
          className="mondayButtonRed"
          Icon={<RestoreFromTrash />}
          onClick={onStartFromScratch}
        >
          Start from Scratch
        </MondayButton>
        <MondayButton
          className="mondayButtonBlue"
          Icon={<CalculatorFilled />}
          onClick={onConfirm}
        >
          Perform Distribution
        </MondayButton>
        {showDraggable && (
          <DragAndEvents
            currentService={eventProps?.service}
            showDraggable={showDraggable}
            itemsToOrder={itemsToOrder}
            setItemsToOrder={setItemsToOrder}
            onClose
          />
        )}
      </div>
    </div>
  );
};

export default EventsCalculatorFooter;
function updateData(dataObject, key, value) {
  if (dataObject.hasOwnProperty(key)) {
    dataObject[key] += value;
  } else {
    dataObject[key] = {};
  }
}

function initializeDataObject(dataObject, key, value) {
  if (!dataObject.hasOwnProperty(key)) {
    dataObject[key] = [value];
  } else {
    dataObject[key].push(value);
  }
}
