import uniq from "lodash/uniq"

export const addons = ({ tables }) => {
  const uniqueStatus = uniq(
    tables?.Estimations?.map((a) => a?.services)
      ?.flat(1)
      ?.map((b) => b?.label)
  )

  let dollarUSLocale = Intl.NumberFormat("en-US")

  return {
    amount: () => ({
      statusData: uniqueStatus?.map((status) => {
        return {
          label: status,
          value:
            dollarUSLocale.format(
              uniqueStatus
                ?.map((status) =>
                  tables?.Estimations?.map((c) => c?.services)
                    ?.flat(1)
                    ?.filter((d) => d?.label === status && status)
                )
                ?.map((e) => e?.map((f) => f?.serviceOptions))
                ?.map((g) =>
                  g
                    ?.flat(2)
                    ?.map((h) => h?.items)
                    ?.flat(1)
                    ?.map((i) => parseInt(i?.price) || 0)
                    ?.reduce((c, p) => c + p, 0)
                )[uniqueStatus.indexOf(status)]
            ) + " $",
          color: "#ECDE7D",
        }
      }),
    }), //estSTATUS
  }
}
