import { debounce, uniqBy } from "lodash";
import { filterSchedulesOnDate } from "../utils/filterSchedulesOnDate";
import { userAccessRecords } from "../../../Dashboard/NewDashboardFunctions";
import { searchFetchInput } from "../../../../../utils/searchFetchForInput";
import { message } from "antd";
import dayjs from "dayjs";

const setLoading = (value) => {
  if (!!value) {
    return message.loading({
      content: "Getting Data",
      key: "loading",
      duration: 0,
    });
  }

  return message.destroy("loading");
};

/**
 * Searches for safety addresses based on the provided search value and updates the safety form data.
 *
 * @param {string} searchValue - The value to search for.
 * @param {Function} dispatchSafetyFormData - The function to dispatch the safety form data.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Array} projects - The array of projects.
 * @param {Array} scheduling - The array of scheduling data.
 * @param {Array} schedulesForDate - The array of schedules for a specific date.
 * @param {string} dateField - The date field value.
 * @param {Object} form - The form object.
 * @returns {Promise<void>} - A promise that resolves when the search and data update is complete.
 */
const searchSafetyAddress = debounce(
  async ({
    searchValue,
    dispatchSafetyFormData,
    userConfiguration,
    projects,
    scheduling,
    schedulesForDate,
    dateField,
    form,
  }) => {
    return await searchFetchInput({
      table: ["projects", "scheduling"],
      idKey: ["projectId", "scheduleId"],
      arrayKey: ["projects", "schedules"],
      searchKey: ["projectName", "scheduleAddress"],
      keysToInclude: {
        projects: [
          "projectId",
          "projectName",
          "accountId",
          "accountName",
          "createdAt",
          "teamsConfiguration",
        ],
        scheduling: [
          "scheduleId",
          "scheduleAddress",
          "scheduleName",
          "scheduleDays",
          "createdAt",
          "teamsConfiguration",
        ],
      },
      searchValue,
      setLoading,
      setRecords: (res) => {
        const resToUse = res?.reduce(
          (acc, curr) => ({
            ...acc,
            ...curr?.value,
          }),
          {}
        );

        const date = form.getFieldValue(dateField);

        const newSchedulesForDate =
          filterSchedulesOnDate(
            resToUse?.schedules || [],
            !!date ? dayjs(date, "MM/DD/YYYY") : dayjs()
          )?.schedulesForDay || [];

        const payload = {
          projects: userAccessRecords(
            userConfiguration,
            uniqBy(
              [...(projects || []), ...(resToUse?.projects || [])],
              "projectId"
            )
          ),
          scheduling: userAccessRecords(
            userConfiguration,
            uniqBy(
              [...(scheduling || []), ...(resToUse?.schedules || [])],
              "scheduleId"
            )
          ),
          schedulesForDate: userAccessRecords(
            userConfiguration,
            uniqBy([
              ...(schedulesForDate || []),
              ...(newSchedulesForDate || []),
            ])
          ),
        };

        dispatchSafetyFormData({
          type: "SEARCH_FETCH",
          payload,
        });
      },
      customSetRecords: true,
    });
  },
  500
);

export default searchSafetyAddress;
