import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "antd";

import { userLink } from "../Cards/utils/UnlinkedFunctions";
import { updateOtherUser } from "../../../../../../../actions";

import { InputComponent } from "../../../../../Fleet/components";
import { MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../Communication/assets";

import "./LinkExistingUserModal.scss";
import { useEditLogs } from "../../../../../../../hooks";

function LinkExistingUserModal({
  open,
  onCancel,
  users,
  rowData,
  dualRoleUser,
  setRowData,
  linkKey,
  endpoint,
  keyId,
  elementName,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { saveAddedLogs } = useEditLogs();

  async function linkUserHandler(selectedSub) {
    if (!!selectedSub) {
      let selectedUser = users?.find((el) => el?.identityId === selectedSub);
      await userLink({
        user: selectedUser,
        rowData: rowData,
        endpoint,
        keyId,
        linkKey,
        saveAddedLogs,
        elementName,
      });
      setRowData((prev) => ({
        ...prev,
        [linkKey]: selectedUser?.cognitoUserId,
      }));
      dispatch(
        updateOtherUser({
          identityId: selectedSub,
          linked: true,
        })
      );
      if (dualRoleUser) {
        await userLink({
          user: selectedUser,
          rowData: dualRoleUser,
          endpoint: endpoint === "engineers" ? "architects" : "engineers",
          keyId: endpoint === "engineers" ? "architectId" : "engineerId",
          linkKey,
          saveAddedLogs,
          elementName,
        });
      }
      onCancel();
    }
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        className: `linkExistingUserModalContainer ${
          isDarkMode && "linkExistingUserModalContainerDark"
        }`,
        closeIcon: <XIcon />,
        title: "Link User",
        footer: [
          <MondayButton
            Icon={<XIcon />}
            onClick={onCancel}
            className="mondayButtonRed"
          >
            Cancel
          </MondayButton>,
          <MondayButton
            Icon={<TickIcon width={17} height={17} />}
            onClick={() => {
              linkUserHandler(form.getFieldValue("selectedUser"));
            }}
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <Form form={form}>
        <InputComponent
          {...{
            form,
            type: "select",
            formItemName: "selectedUser",
            placeholder: "Click and choose...",
            dropdownClassName: isDarkMode && "darkDropDown",
            showSearch: true,
            required: true,
            customOptions: users
              ?.filter((el) =>
                rowData?.driverId
                  ? el?.groupName === "Driver" && !el?.linked
                  : el.identityId === userConfiguration?.identityId
                  ? !userConfiguration?.linked
                  : !el?.linked
              )
              .reduce(
                (acc, val) => [
                  ...acc,
                  {
                    label: val?.nameOfUser,
                    value: val?.identityId,
                  },
                ],
                []
              ),
          }}
        />
      </Form>
    </Modal>
  );
}

export default LinkExistingUserModal;
