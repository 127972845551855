import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  TABLE_DATA_COLUMN_HOIST_CALCULATOR,
  TABLE_DATA_HOIST_CALCULATOR,
} from "./tableData";

//SINGLE PRICE SCHEME
const getUnits = ({ units = 0, ppu = 0, price = 0, rent = 0, note = "" }) => {
  return { units, ppu, price, rent, note };
};

export const TABLE_HOIST_REFERENCE_DATA = {
  // First category is the hoist type
  hoistType: {
    options: ["Default"], // to lowercase for comparison
    Default: getUnits({
      units: 1,
      ppu: 70000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinLength: {
    options: [
      `Increase length from 12'9" to 14'6"`,
      `Increase length from 12'9" to 16'4"`,
      `Increase length from 16'4" to 18'1" `,
    ],
    [`Increase length from 12'9" to 14'6"`]: getUnits({
      units: 1,
      ppu: 15000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 12'9" to 16'4"`]: getUnits({
      units: 1,
      ppu: 30000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 16'4" to 18'1"`]: getUnits({
      units: 1,
      ppu: 25000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinHeight: {
    options: [
      `Increase height from 7'6" to 9'2" including taller landing gates at every floor`,
    ],
    [`Increase height from 7'6" to 9'2" including taller landing gates at every floor`]:
      getUnits({
        units: 1,
        ppu: 45000,
        price: 0,
        rent: 0,
        note: "",
      }),
  },
  hoistSpeed: {
    options: [`Increase speed from 175 FPM to 300 FPM`],
    [`Increase speed from 175 FPM to 300 FPM`]: getUnits({
      units: 1,
      ppu: 25000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  liftHeight: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 575,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  location: {
    options: [`Rear`],
    [`Rear`]: getUnits({
      units: 1,
      ppu: 10000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  jumps: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  stops: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 6,
      ppu: 7500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`100'H`]: getUnits({
      units: 6,
      ppu: 8000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`150'H`]: getUnits({
      units: 6,
      ppu: 8500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`200'H`]: getUnits({
      units: 6,
      ppu: 9000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`250'H`]: getUnits({
      units: 6,
      ppu: 9500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`300'H`]: getUnits({
      units: 6,
      ppu: 10000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`350'H`]: getUnits({
      units: 6,
      ppu: 10500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`400'H`]: getUnits({
      units: 6,
      ppu: 11000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
  },
  additionalRentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 1,
      ppu: 7500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`100'H`]: getUnits({
      units: 1,
      ppu: 8000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`150'H`]: getUnits({
      units: 1,
      ppu: 8500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`200'H`]: getUnits({
      units: 1,
      ppu: 9000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`250'H`]: getUnits({
      units: 1,
      ppu: 9500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`300'H`]: getUnits({
      units: 1,
      ppu: 10000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`350'H`]: getUnits({
      units: 1,
      ppu: 10500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`400'H`]: getUnits({
      units: 1,
      ppu: 11000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
  },

  runbacks: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  shoringUnderRunback: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  shoring: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  loadingDock: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  winterization: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 3500,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  inserts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 600,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  needleBeamRamp: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rampOnRoof: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  mtaApproval: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  callButtonSystem: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  otherCharges: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  credit: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  discounts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
};
export const TABLE_HOIST_REFERENCE_DATA_DUAL = {
  // First category is the hoist type
  hoistType: {
    options: ["Default"], // to lowercase for comparison
    Default: getUnits({
      units: 1,
      ppu: 100000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinLength: {
    options: [
      `Increase length from 12'9" to 14'6"`,
      `Increase length from 12'9" to 16'4"`,
      `Increase length from 16'4" to 18'1" `,
    ],
    [`Increase length from 12'9" to 14'6"`]: getUnits({
      units: 1,
      ppu: 30000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 12'9" to 16'4"`]: getUnits({
      units: 1,
      ppu: 60000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 16'4" to 18'1"`]: getUnits({
      units: 1,
      ppu: 50000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinHeight: {
    options: [
      `Increase height from 7'6" to 9'2" including taller landing gates at every floor`,
    ],
    [`Increase height from 7'6" to 9'2" including taller landing gates at every floor`]:
      getUnits({
        units: 1,
        ppu: 90000,
        price: 0,
        rent: 0,
        note: "",
      }),
  },
  hoistSpeed: {
    options: [`Increase speed from 175 FPM to 300 FPM`],
    [`Increase speed from 175 FPM to 300 FPM`]: getUnits({
      units: 1,
      ppu: 50000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  liftHeight: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 575,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  location: {
    options: [`Rear`],
    [`Rear`]: getUnits({
      units: 1,
      ppu: 15000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  jumps: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  stops: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 6,
      ppu: 13000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`100'H`]: getUnits({
      units: 6,
      ppu: 13500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`150'H`]: getUnits({
      units: 6,
      ppu: 14500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`200'H`]: getUnits({
      units: 6,
      ppu: 15500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`250'H`]: getUnits({
      units: 6,
      ppu: 16500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`300'H`]: getUnits({
      units: 6,
      ppu: 17500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`350'H`]: getUnits({
      units: 6,
      ppu: 18500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`400'H`]: getUnits({
      units: 6,
      ppu: 19500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
  },
  additionalRentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 1,
      ppu: 13000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`100'H`]: getUnits({
      units: 1,
      ppu: 13500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`150'H`]: getUnits({
      units: 1,
      ppu: 14500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`200'H`]: getUnits({
      units: 1,
      ppu: 15500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`250'H`]: getUnits({
      units: 1,
      ppu: 16500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`300'H`]: getUnits({
      units: 1,
      ppu: 17500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`350'H`]: getUnits({
      units: 1,
      ppu: 18500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`400'H`]: getUnits({
      units: 1,
      ppu: 19500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
  },

  runbacks: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  shoringUnderRunback: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  shoring: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  loadingDock: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  winterization: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 7000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  inserts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 600,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  needleBeamRamp: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rampOnRoof: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  mtaApproval: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  callButtonSystem: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  otherCharges: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  credit: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  discounts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
};

export const getPriceScheme = ({ isDual = false, isAlreadyPicked = false }) => {
  if (isAlreadyPicked) {
    return {};
  }
  return isDual
    ? {
        name: "Default Dual Price Scheme",
        TABLE_HOIST_REFERENCE_DATA: TABLE_HOIST_REFERENCE_DATA_DUAL,
      }
    : { TABLE_HOIST_REFERENCE_DATA, name: "Default Single Price Scheme" };
};

export const mappingOfKeysHoistTable = {
  "Hoist Base Price": "hoistType",
  "Cabin Size (L)": "cabinLength",
  "Cabin Size (H)": "cabinHeight",
  Speed: "hoistSpeed",
  "Lift Height": "liftHeight",
  Location: "location",
  Jumps: "jumps",
  Stops: "stops",
  "Rental for Hoist": "rentalForHoist",
  "Additional Rental for Hoist": "rentalForHoist",

  Runbacks: "runbacks",
  "Shoring under Runback": "shoringUnderRunback",
  Shoring: "shoring",
  "Loading Dock": "loadingDock",
  Winterization: "winterization",
  Inserts: "inserts",
  "Needle Beam Ramp": "needleBeamRamp",
  "Ramp on Roof": "rampOnRoof",
  "MTA Approval": "mtaApproval",
  "Call Button System": "callButtonSystem",
  "Other Charges": "otherCharges",
  Credit: "credit",
  Discount: "discounts",
};

// keys to keep in mind, price, totalPrice, taxRate, taxAmount
export const autoFillHoist = ({ hoistData = {} }) => {
  // we need to check multiple things
  //1. Check type of the hoist first
  let referenceScheme = null;
  let rowData = [];
  let hoistType = hoistData?.type || "";
  if (hoistType === "dual") {
    referenceScheme = TABLE_HOIST_REFERENCE_DATA_DUAL;
  } else {
    referenceScheme = TABLE_HOIST_REFERENCE_DATA;
  }

  let applicableColumns = TABLE_DATA_HOIST_CALCULATOR;

  if (applicableColumns.length > 0) {
    applicableColumns.forEach((column) => {
      let name = column?.name || "";
      switch (name) {
        case "Hoist Base Price": {
          let hoistValueObj = referenceScheme?.hoistType?.Default || {};
          pushHoistValue(rowData, column, hoistValueObj, "Default");
          break;
        }
        case "Cabin Size (L)": {
          let hoistValueObj;
          let entityValue =
            findHoistValue({
              hoistData,
              key: "length",
            })?.replace("/", "") + `"`;
          if (checkIfBetweenIntervalHeight(entityValue, [`14'6"`, `14'6"`])) {
            entityValue = `Increase length from 12'9" to 14'6"`;
          } else if (
            checkIfBetweenIntervalHeight(entityValue, [`16'4"`, `16'4"`])
          ) {
            entityValue = `Increase length from 12'9" to 16'4"`;
          } else if (
            checkIfBetweenIntervalHeight(entityValue, [`18'1"`, `18'1"`])
          ) {
            entityValue = `Increase length from 16'4" to 18'1"`;
          } else {
            pushHoistValue(rowData, column, {});
            break;
          }

          hoistValueObj = referenceScheme?.cabinLength?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        case "Cabin Size (H)": {
          let hoistValueObj;
          let entityValue =
            findHoistValue({
              hoistData,
              key: "height",
            })?.replace("/", "") + `"`;

          if (checkIfBetweenIntervalHeight(entityValue, [`9'2"`, `9'2"`])) {
            entityValue = `Increase height from 7'6" to 9'2" including taller landing gates at every floor`;
          } else {
            pushHoistValue(rowData, column, {});
            break;
          }

          hoistValueObj = referenceScheme?.cabinHeight?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        // case "Cabin Size (H)": {
        //   let hoistValueObj;
        //   let entityValue =
        //     findHoistValue({
        //       hoistData,
        //       key: "length",
        //     })?.replace("/", "") + `"`;
        //   if (checkIfBetweenIntervalHeight(entityValue, [`14'6"`, `14'6"`])) {
        //     entityValue = `Increase length from 12'9" to 14'6"`;
        //   } else if (
        //     checkIfBetweenIntervalHeight(entityValue, [`16'4"`, `16'4"`])
        //   ) {
        //     entityValue = `Increase length from 12'9" to 16'4"`;
        //   } else if (
        //     checkIfBetweenIntervalHeight(entityValue, [`18'1"`, `18'1"`])
        //   ) {
        //     entityValue = `Increase length from 16'4" to 18'1"`;
        //   } else {
        //     pushHoistValue(rowData, column, {});
        //     break;
        //   }

        //   hoistValueObj = referenceScheme?.cabinLength?.[entityValue] || {};
        //   pushHoistValue(rowData, column, hoistValueObj, entityValue);
        //   break;
        // }
        case "Speed": {
          let hoistValueObj;
          let entityValue = findHoistValue({
            hoistData,
            key: "speed",
          });
          if (checkIfBetweenInterval(entityValue, [300, 500])) {
            entityValue = `Increase speed from 175 FPM to 300 FPM`;
          } else {
            pushHoistValue(rowData, column, {});
            break;
          }

          hoistValueObj = referenceScheme?.hoistSpeed?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        case "Lift Height": {
          let hoistValueObj;
          let entityValue = "Default Price";
          console.log("referenceScheme", { hoistData, referenceScheme });
          hoistValueObj = referenceScheme?.liftHeight?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: forceToNumber(
              findHoistValue({
                hoistData,
                key: "lift_height",
              })
            ),
          });
          break;
        }
        case "Location": {
          let hoistValueObj = {};
          let entityValue = findHoistValue({
            hoistData,
            key: "location",
          });
          hoistValueObj = referenceScheme?.location?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        case "Stops": {
          let hoistValueObj = {};
          let entityValue = forceToNumber(
            hoistData?.items?.filter((r) => r?.stop === true)?.length || 0
          );
          console.log("hoist data", { hoistData });
          hoistValueObj = referenceScheme?.location?.[entityValue] || {};
          pushHoistValue(
            rowData,
            column,
            { ...hoistValueObj, units: entityValue },
            `${entityValue} Stops`
          );
          break;
        }
        case "Jumps": {
          let hoistValueObj = {};
          let entityValue = forceToNumber(hoistData?.jumps?.length || 0);
          console.log("hoist data", { hoistData });
          hoistValueObj = referenceScheme?.location?.[entityValue] || {};
          pushHoistValue(
            rowData,
            column,
            { ...hoistValueObj, units: entityValue },
            `${entityValue} Stops`
          );
          break;
        }
        case "Rental for Hoist": {
          let hoistValueObj = {};
          let entityValue =
            findHoistValue({
              hoistData,
              key: "lift_height",
            }) + `'H`;
          hoistValueObj = referenceScheme?.rentalForHoist?.[entityValue] || {};
          pushHoistValue(rowData, column, entityValue, entityValue);
          break;
        }
        case "Additional Rental for Hoist": {
          let hoistValueObj = {};
          let entityValue =
            findHoistValue({
              hoistData,
              key: "lift_height",
            }) + `'H`;
          hoistValueObj =
            referenceScheme?.additionalRentalForHoist?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        ///TODO: Formula: Unit Price x Number of Floors x Number of Monthly Rental x Unit Price
        case "Call Button System": {
          console.log("rowData", { rowData });
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistValue({
            hoistData,
            key: "noOfFloors",
          });
          hoistValueObj =
            referenceScheme?.callButtonSystem?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        default: {
          pushHoistValue(rowData, column, {});
          return { ...(column || {}) };
        }
      }
    });
  }
  return rowData?.map((e) => {
    let PRICE = forceToNumber(e?.ppu) * forceToNumber(e?.units);
    let TAX = PRICE * forceToNumber(e?.taxRate);
    let RENT = forceToNumber(e?.rent);
    let additionalRent = forceToNumber(e?.additionalRent);
    let TOTAL_PRICE =
      forceToNumber(PRICE) + forceToNumber(TAX) + forceToNumber(additionalRent);
    return {
      ...e,
      totalPrice: TOTAL_PRICE,
      tax: TAX,
      additionalRent,
      price: PRICE,
      rent: RENT,
    };
  });
};

const findHoistValue = ({ hoistData = {}, key = "" }) => {
  return hoistData?.[key] || "";
};

const pushHoistValue = (
  rowData,
  original,
  toPush,
  entityValue = "",
  otherValues = {}
) => {
  rowData.push({ ...original, ...toPush, entityValue, ...otherValues });
  return;
};

const convertHeightToInches = (height = "") => {
  try {
    let [feet, inches] = height.split("'");
    return parseInt(feet) * 12 + parseInt(inches);
  } catch (e) {
    return 0;
  }
};

const checkIfBetweenIntervalHeight = (
  height = 0,
  interval = [(start = 0), (end = 0)]
) => {
  let heightInInches = convertHeightToInches(height);
  let intervalInInches = interval.map((e) => convertHeightToInches(e));

  return (
    heightInInches >= intervalInInches[0] &&
    heightInInches <= intervalInInches[1]
  );
};

const checkIfBetweenInterval = (
  value = 0,
  interval = [(start = 0), (end = 0)]
) => {
  let tempVal = forceToNumber(value);
  return tempVal >= interval[0] && tempVal <= interval[1];
};
