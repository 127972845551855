import {
  evaluateMathExpression,
  toFixedFormatter,
} from "../../../../SidebarPages/Estimations/DataEntryGrid/tools/formatters";

export const columnDefTypes = [
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Currency",
    value: "currency",
  },
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Text Area",
    value: "textarea",
  },
];

// properties to be added to a scope column definition according to its type
export const scopeColDefPropsByType = {
  number: { valueFormatter: evaluateMathExpression },
  currency: { valueFormatter: toFixedFormatter },
  text: {},
  textarea: { cellRenderer: "rtfEditor", editable: false },
};

export const defaultScopeColumnDefs = [
  {
    headerName: "Price",
    headerTooltip: "Price",
    field: "price",
    hide: false,
    width: 80,
    getQuickFilterText: () => "",
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    valueFormatter: ({ value = 0 }) => `${value * 100}%`,
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    valueFormatter: toFixedFormatter,
  },
  {
    headerName: "Total",
    field: "totalPrice",
    valueFormatter: toFixedFormatter,
  },
];
