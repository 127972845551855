import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const defects = ({ tables, programFields }) => {
  const defectStatuses = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Defects;

  // const defectPriority = programFields?.find?.(
  //   ({ fieldName }) => fieldName === "Priority Status"
  // )?.fieldOptions;

  return {
    defectStatus: () => ({
      labels: defectStatuses?.map((a) => a?.statusName),
      data: defectStatuses
        ?.map((a) => a?.statusName)
        ?.map(
          (status) =>
            tables?.Defects?.filter(
              (a) => a.defectStatus?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: defectStatuses?.map((a) => a?.statusColor),
    }),

    // defectName: () => ({
    //   labels: uniq(tables?.Defects?.map((a) => a?.defectName)),
    //   data: uniq(tables?.Defects?.map((a) => a?.defectName))?.map(
    //     (status) =>
    //       tables?.Defects?.filter(
    //         (a) => a.defectName?.toLowerCase() === status?.toLowerCase()
    //       )?.length
    //   ),
    //   backgroundColor: uniq(tables?.Defects?.map((a) => getRandomColor())),
    // }),

    // driverName: () => ({
    //   labels: uniq(tables?.Defects?.map((a) => a?.driverName)),
    //   data: uniq(tables?.Defects?.map((a) => a?.driverName))?.map(
    //     (status) =>
    //       tables?.Defects?.filter(
    //         (a) => a?.driverName?.toLowerCase() === status?.toLowerCase()
    //       )?.length
    //   ),
    //   backgroundColor: uniq(tables?.Defects?.map((a) => getRandomColor())),
    // }),

    // fleetName: () => ({
    //   labels: uniq(tables?.Defects?.map((a) => a?.fleetName)),
    //   data: uniq(tables?.Defects?.map((a) => a?.fleetName))?.map(
    //     (status) =>
    //       tables?.Defects?.filter(
    //         (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
    //       )?.length
    //   ),
    //   backgroundColor: uniq(tables?.Defects?.map((a) => getRandomColor())),
    // }),

    // inspectionName: () => ({
    //   labels: uniq(tables?.Defects?.map((a) => a?.inspectionName)),
    //   data: uniq(tables?.Defects?.map((a) => a?.inspectionName))?.map(
    //     (status) =>
    //       tables?.Defects?.filter(
    //         (a) => a?.inspectionName?.toLowerCase() === status?.toLowerCase()
    //       )?.length
    //   ),
    //   backgroundColor: uniq(tables?.Defects?.map((a) => getRandomColor())),
    // }),

    // defectPriority: () => ({
    //   labels: uniq(tables?.Defects?.map((a) => a?.defectPriority)),
    //   data: uniq(tables?.Defects?.map((a) => a?.defectPriority))?.map(
    //     (status) =>
    //       tables?.Defects?.filter(
    //         (a) => a.defectPriority?.toLowerCase() === status?.toLowerCase()
    //       )?.length
    //   ),
    //   backgroundColor: uniq(tables?.Defects?.map((a) => getRandomColor())),
    // }),
  };
};
