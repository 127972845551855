import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";

export const dispatching = (IdHandler, dispatchData, updatedDynamicStates) => {
  const allDrivers = (updatedDynamicStates?.[IdHandler] || [])
    .map((a) => a?.routes?.map((e) => e?.driverName))
    ?.flat();
  return {
    fleetName: () => ({
      title: "Dispatching Fleet Name",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Dispatching",
        field: "fleetName",
        labels: uniq(dispatchData?.map((a) => a?.fleetName)),
        datasets: [
          {
            label: "My First Dataset",
            data: uniq(dispatchData?.map((a) => a?.fleetName))?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) => a.fleetName === status
                )?.length
            ),
            backgroundColor: uniq(dispatchData?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "400px",
    }), //fleetName
    driverName: () => ({
      title: "Dispatching Driver Name",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Dispatching",
        field: "driverName",
        labels: uniq(allDrivers),
        datasets: [
          {
            label: "My First Dataset",
            data: uniq(allDrivers)?.map(
              (status) => allDrivers?.filter((a) => a === status)?.length
            ),
            backgroundColor: uniq(allDrivers)?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //driverName
    dispatchedBy: () => ({
      title: "Dispatched By",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Dispatching",
        field: "dispatchedBy",
        labels: uniq(
          dispatchData?.map((a) =>
            a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
          )
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: uniq(
              dispatchData?.map((a) =>
                a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  a.dispatchedBya?.dispatchedBy?.name
                    ? a?.dispatchedBy?.name?.toLowerCase()
                    : a?.dispatchedBy?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(dispatchData?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //dispatchedBy
  }; //Dispatching
};
