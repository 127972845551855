import { DRIVE_API_KEY } from "src/integrations/DriveRequest/driveStatics";
import { apiRoutes } from "../../../../../Fleet/utils";
import { API } from "aws-amplify";

export function FOLDER_FETCH_URL({ folderName }) {
  return `https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.folder' and name='${encodeURIComponent(
    folderName
  )}'&fields=files(id, name)&key=${DRIVE_API_KEY}`;
}

export function clientsCases({ rowData }) {
  return {
    ["Clients"]: "Clients",
    ["Projects"]: rowData?.accountName,
    ["Contacts"]: rowData?.contactAccountName,
    ["Estimations"]: rowData?.jobSiteAddress,
    ["Permit Drawings"]: rowData?.jobSiteAddress,
    ["Scheduling"]: rowData?.scheduleAddress,
    ["Violations"]: rowData?.fleetName,
  };
}

function findCredentialKey(cat, hotCredentials) {
  let credentialKey = null;
  cat === "Clients"
    ? (credentialKey = "CORE/CLIENT PARENT FOLDER")
    : cat === "Leads"
    ? (credentialKey = "CORE/LEAD PARENT FOLDER")
    : cat === "Claims"
    ? (credentialKey = "CLAIMS")
    : cat === "Safety"
    ? (credentialKey = "SAFETY")
    : cat === "Incidents"
    ? (credentialKey = "INCIDENTS")
    : null;

  return hotCredentials?.find((el) => el?.credentialKey === credentialKey)
    ?.credentialValue;
}

let drawingKeys = {
  "Initial Drawing": "initialDrawings",
  "Amendment Drawing": "amendmentDrawings",
  Review: "reviewDrawings",
  Supersede: "supersedeDrawings",
};

export function findParentRecordIdAndFolderKey({
  parentFolder,
  rowData,
  hotCredentials,
  fleetFields,
}) {
  const fleetsId = (key) => {
    const ids = fleetFields?.find(
      (el) => el?.fieldName === "Google Drive Folder Ids"
    )?.fieldOptions;
    if (process.env.NODE_ENV === "development" && key === "fleet") {
      return ids?.find((el) => el?.["fleet-dev"])?.["fleet-dev"];
    } else if (key === "fleet") {
      return ids?.find((el) => el?.[key])?.[key];
    } else if (key === "drivers") {
      return ids?.find((el) => el?.[key])?.[key];
    }
  };
  const path = window.location.pathname;
  const objToReturn = {
    Clients: {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Clients", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    Leads: {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Leads", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    Claims: {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Claims", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    Fleets: {
      parentRecordId: null,
      parentFolderKey: fleetsId("fleet"),
      searchTable: null,
      goTo: null,
    },
    Projects: {
      parentRecordId: rowData?.accountId,
      parentFolderKey: "projectObject",
      searchTable: apiRoutes.accounts,
      goTo: `/${apiRoutes.accounts}/${rowData?.accountId}`,
    },
    Opportunities: {
      parentRecordId: !!rowData?.projectId
        ? rowData?.projectId
        : !!rowData?.accountId
        ? rowData?.accountId
        : rowData?.leadId,
      parentFolderKey: !!rowData?.projectId
        ? "changeOrderObject"
        : "opportunitiesObject",
      searchTable: !!rowData?.projectId
        ? apiRoutes.projects
        : !!rowData?.accountId
        ? apiRoutes.accounts
        : apiRoutes.leads,
      goTo: !!rowData?.projectId
        ? `/${apiRoutes.projects}/${rowData?.projectId}`
        : !!rowData?.accountId
        ? `/${apiRoutes.accounts}/${rowData?.accountId}`
        : `/${apiRoutes.leads}/${rowData?.leadId}`,
    },
    Hearings: {
      parentRecordId: rowData?.claimId,
      parentFolderKey: "hearingObject",
      searchTable: apiRoutes.claims,
      goTo: `/${apiRoutes.claims}/${rowData?.claimId}`,
    },
    Estimations: {
      parentRecordId: !!rowData?.projectId
        ? rowData?.projectId
        : rowData?.opportunityId,
      parentFolderKey: "estimationObject",
      searchTable: !!rowData?.projectId
        ? apiRoutes.projects
        : apiRoutes.opportunities,
      goTo: !!rowData?.projectId
        ? `/${apiRoutes.projects}/${rowData?.projectId}`
        : `/${apiRoutes.opportunities}/${rowData?.opportunityId}`,
    },
    Contacts: {
      parentRecordId: !!rowData?.accountId
        ? rowData?.accountId
        : rowData?.leadId,
      parentFolderKey: "contactsObject",
      searchTable: !!rowData?.accountId ? apiRoutes.accounts : apiRoutes.leads,
      goTo: !!rowData?.accountId
        ? `/${apiRoutes.accounts}/${rowData?.accountId}`
        : `/${apiRoutes.leads}/${rowData?.leadId}`,
    },
    "Permit Drawings": {
      parentRecordId: rowData?.projectId,
      parentFolderKey: drawingKeys[rowData?.drawingType],
      searchTable: apiRoutes.projects,
      goTo: `/${apiRoutes.projects}/${rowData?.projectId}`,
    },
    Scheduling: {
      parentRecordId: rowData?.projectId,
      parentFolderKey: "scheduleObject",
      searchTable: apiRoutes.projects,
      goTo: `/${apiRoutes.projects}/${rowData?.projectId}`,
    },
    Violations: {
      parentRecordId: rowData?.fleetId,
      parentFolderKey: "violationObject",
      searchTable: apiRoutes.fleet,
      goTo: `/fleets/${rowData?.fleetId}`,
    },
    Drivers: {
      parentRecordId: null,
      parentFolderKey: fleetsId("drivers"),
      searchTable: null,
      goTo: null,
    },
    "Property Damage": {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Safety", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    "Vehicle Damage": {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Safety", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    "Personal Injury": {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Safety", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    "Other Trade Incident": {
      parentRecordId: null,
      parentFolderKey: path.includes("incidents")
        ? findCredentialKey("Incidents", hotCredentials)
        : findCredentialKey("Safety", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    "Property Damage Incident": {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Incidents", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    "Personal Injury Incident": {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Incidents", hotCredentials),
      searchTable: null,
      goTo: null,
    },
    "Other Incident": {
      parentRecordId: null,
      parentFolderKey: findCredentialKey("Incidents", hotCredentials),
      searchTable: null,
      goTo: null,
    },
  };

  return {
    ...objToReturn[parentFolder],
  };
}

export async function getParentFolderId({
  parentFolder,
  rowData,
  hotCredentials,
  fleetFields,
}) {
  const { parentFolderKey, parentRecordId, searchTable, goTo } =
    findParentRecordIdAndFolderKey({
      parentFolder,
      rowData,
      hotCredentials,
      fleetFields,
    });
  let parentFolderId = null;
  if (!parentRecordId && !searchTable && !parentFolderKey) return;
  if (!parentRecordId && !searchTable && !!parentFolderKey) {
    parentFolderId = parentFolderKey;
  } else if (!!parentRecordId && !!searchTable) {
    await API.get(searchTable, `/${searchTable}/${parentRecordId}`)
      .then((res) => {
        const folderId = res?.googleDriveFolderIds?.[parentFolderKey];
        parentFolderId = folderId;
      })
      .catch((err) => {
        console.log("Error getting parent folder id: ", { err });
      });
  }
  return { parentFolderId, goTo };
}
