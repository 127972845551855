import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

export const excelColumnsConfig = [
  "nameOfUser",
  "userName",
  "phone_number",
  "departmentName",
  "groupName",
  "lastlogintime",
  "lastlogindevice",
];

export const allUsersColDefs = [
  {
    headerName: "Name",
    field: "nameOfUser",
    cellRenderer: "UsernameRenderer",
    filter: "agMultiColumnFilter",
    enableRowGroup: false,
    minWidth: 340,
  },
  { headerName: "Username", field: "userName", filter: "agMultiColumnFilter" },
  {
    headerName: "Phone #",
    field: "phone_number",
    filter: "agMultiColumnFilter",
  },
  {
    headerName: "Department",
    field: "departmentName",
    filter: "agMultiColumnFilter",
  },
  { headerName: "Role", field: "groupName", filter: "agMultiColumnFilter" },
  {
    headerName: "Two-Factor Authentication",
    field: "is2FaEnabled",
    filter: "agMultiColumnFilter",
    cellRenderer: ({ data }) => {
      return <>{data.is2FaEnabled ? "Enabled" : "Disabled"}</>;
    },
  },
  {
    headerName: "Last login time",
    field: "lastlogintime",
    filter: "agDateColumnFilter",
    valueGetter: ({ data }) => ({
      value: data?.activeSessions,
    }),
    comparator: (valueA, valueB) => {
      const aValue = valueA.value;
      const bValue = valueB.value;

      // Perform custom comparison logic for sorting
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    },
    cellRenderer: (params) => {
      const { data } = params;
      const noDataToShow =
        !data?.activeSessions || data?.activeSessions.length === 0;

      if (noDataToShow) {
        return null;
      }

      const latestDate = data.activeSessions.reduce((latest, session) => {
        return session.loggedIn > latest ? session.loggedIn : latest;
      }, data.activeSessions[0].loggedIn);

      return dayjsNY(latestDate).format("MM/DD/YYYY - hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue: ({ value }) => {
        const noDataToShow = !value || value?.length === 0;

        if (noDataToShow) {
          return null;
        }

        const latestDate = value.reduce((latest, session) => {
          return session.loggedIn > latest ? session.loggedIn : latest;
        }, value[0].loggedIn);

        return dayjsNY(latestDate).format("MM/DD/YYYY - hh:mm a");
      },
    },
  },
  {
    headerName: "Last login device",
    field: "lastlogindevice",
    filter: "agMultiColumnFilter",
    valueGetter: ({ data }) => ({
      value: data?.activeSessions,
    }),
    comparator: (valueA, valueB) => {
      const aValue = valueA.value;
      const bValue = valueB.value;

      // Perform custom comparison logic for sorting
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    },
    cellRenderer: (params) => {
      const { data } = params;

      if (!data?.activeSessions || data.activeSessions.length === 0) {
        return null;
      }

      const latestSession = data.activeSessions.reduce((latest, session) => {
        return session.loggedIn > latest.loggedIn ? session : latest;
      }, data.activeSessions[0]);

      return latestSession.deviceType;
    },
    pdfExportOptions: {
      modifyCellValue: ({ value }) => {
        if (!value || value?.length === 0) {
          return null;
        }

        const latestSession = value.reduce((latest, session) => {
          return session.loggedIn > latest.loggedIn ? session : latest;
        }, value[0]);

        return latestSession.deviceType;
      },
    },
    filter: "agTextColumnFilter",
  },
];

export const loginStatusColors = {
  loggedIn: "#00A464",
  suspended: "#F04F4E",
  loggedOut: "#787677",
  idle: "#F9DA53",
};
