import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { roundToDecimals } from "../../../../../../../../../../utils";

//this is done to prevent errors for the services without price,this have to be deleted in the future
const servicesWithoutPricePerPli = [
  "Shoring",
  "Hanging Scaffold",
  "Platform Scaffold",
  "Window Protection",
  "Turn Stiles",
];

export const primaryServiceElevationsExtractor = ({ service }) =>
  service?.serviceOptions?.[0]?.map((elevation) => ({
    ...elevation,
    panelType: "elevation",
    items: elevation?.items
      ?.filter((item) => item?.approved)
      ?.map((item) => ({
        ...item,
        amounts:
          !servicesWithoutPricePerPli.includes(service.label) &&
          service?.amounts?.map((event, eventIndex, arrayOfEvents) => {
            // Calculate amounts of each PLI for elevation
            let rate = event.rate; // Rate of event
            let amount = roundToDecimals((item.totalPrice * rate) / 100, 2); // Calculate amount based on rate
            let i = eventIndex; // Initialize index
            let total = item.totalPrice; // Initial total is equal to total price

            // Calculate total for rows other than first
            while (i > 0) {
              // If we are on row other than first one
              total = roundToDecimals(
                total -
                  roundToDecimals(
                    (item.totalPrice * arrayOfEvents[i - 1].rate) / 100,
                    2
                  ),
                2
              ); // Subtract all previous amounts from total price
              i--; // Until we reach the current row
            }

            let difference = roundToDecimals(total - amount, 2); // Calculate difference

            // Return amounts for each PLI
            return {
              event: event.name,
              rate,
              amount,
              difference,
            };
          }),
      })),
  }));

export const serviceAddonsExtractor = ({ service }) => {
  let temp = service?.serviceAddons?.map((addon) => ({
    ...addon,
    panelType: "addon",
    amounts:
      !servicesWithoutPricePerPli.includes(service.label) &&
      service?.amounts?.map((event, eventIndex, arrayOfEvents) => {
        // Calculate amounts of each Addon for service
        let rate = event.rate; // Rate of event
        let amount = roundToDecimals((addon.totalPrice * rate) / 100, 2); // Calculate amount based on rate
        let i = eventIndex; // Initialize index
        let total = addon.totalPrice; // Initial total is equal to total price

        // Calculate total for rows other than first
        while (i > 0) {
          // If we are on row other than first one
          total = roundToDecimals(
            total -
              roundToDecimals(
                (addon.totalPrice * arrayOfEvents[i - 1].rate) / 100,
                2
              ),
            2
          ); // Subtract all previous amounts from total price
          i--; // Until we reach the current row
        }

        let difference = roundToDecimals(total - amount, 2); // Calculate difference

        // Return amounts for each PLI
        return {
          event: event.name,
          rate,
          amount,
          difference,
        };
      }),
  }));
  const additionalRentalPrice = forceToNumber(
    service?.additionalRentalTerms?.newPrice
  );

  if (additionalRentalPrice > 0) {
    temp.push({
      panelType: "addon", // TODO: Change to Additional Rental
      name: "Additional Rental",
      totalPrice: additionalRentalPrice,
      amounts:
        !servicesWithoutPricePerPli.includes(service.label) &&
        service?.amounts?.map((event, eventIndex, arrayOfEvents) => {
          // Calculate amounts of each Addon for service

          let rate = event.rate; // Rate of event
          let amount = roundToDecimals((additionalRentalPrice * rate) / 100, 2); // Calculate amount based on rate
          let i = eventIndex; // Initialize index
          let total = additionalRentalPrice; // Initial total is equal to total price

          // Calculate total for rows other than first
          while (i > 0) {
            // If we are on row other than first one
            total = roundToDecimals(
              total -
                roundToDecimals(
                  (additionalRentalPrice * arrayOfEvents[i - 1].rate) / 100,
                  2
                ),
              2
            ); // Subtract all previous amounts from total price
            i--; // Until we reach the current row
          }

          let difference = roundToDecimals(total - amount, 2); // Calculate difference

          // Return amounts for each PLI
          return {
            event: event.name,
            rate,
            amount,
            difference,
          };
        }),
    });
  }
  return temp;
};

export const totalPriceOfElevationCalculator = ({ service, elevation }) => {
  return servicesWithoutPricePerPli.includes(service.label)
    ? elevation?.totalPrice
    : elevation?.items?.reduce(
        (acc, cur) => acc + forceToNumber(cur?.totalPrice),
        0
      );
};
