import { formatCurrency, formatNumber } from "../../../utils";

export const columnDefs = [
  {
    headerName: "Name",
    field: "laborName",
  },
  {
    headerName: "Description",
    field: "description",
  },
  {
    headerName: "Rate",
    field: "rate",
    cellRenderer({ data }) {
      if (!isNaN(data?.rate)) {
        if (parseInt(data.rate) === 0 && parseInt(data?.hoursWorked) === 0) {
          return "";
        } else {
          return formatCurrency(data?.rate);
        }
      } else {
        return "";
      }
    },
  },
  {
    headerName: "Hours",
    field: "hoursWorked",
    cellRenderer({ data }) {
      if (!isNaN(data?.hoursWorked)) {
        if (parseInt(data.rate) === 0 && parseInt(data?.hoursWorked) === 0) {
          return "";
        } else if (isNaN(data?.rate)) {
          return (
            <strong>{`${formatNumber(data?.hoursWorked, {
              unit: "hour",
              unitDisplay: "long",
            })}`}</strong>
          );
        } else {
          return formatNumber(data?.hoursWorked, {
            unit: "hour",
            unitDisplay: "long",
          });
        }
      } else {
        return "";
      }
    },
  },
  {
    headerName: "Total",
    field: "total",
    cellRenderer({ data }) {
      if (!data?.total && data?.total !== 0) {
        return formatCurrency(
          parseFloat(data?.rate) * parseFloat(data?.hoursWorked)
        );
      } else {
        return <strong>{formatCurrency(data?.total)}</strong>;
      }
    },
  },
];
