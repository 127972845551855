//@ts-check
import { forceToNumber } from "../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { calculateTotalPriceForService } from "../../../SidebarPages/Accounting/components/utilities";
import { checkIfServiceIsHoist } from "../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import { getPriceChargedCredited } from "../../../SidebarPages/Projects/Accounting/Charges/components/NewChargeItem/utils/getPriceForCharge";
import { getServiceLegacyTaxAmount } from "../../../SidebarPages/Projects/Accounting/Charges/components/NewChargeItem/utils/getServicesTaxAmount";
import { getServiceLegacyPriceWithoutTax } from "../../../SidebarPages/Projects/Accounting/Charges/components/NewChargeItem/utils/servicesPricesWithoutTax";

export function serviceGeneration({
  services,
  groupName,
  category,
  chargeCategoryFrom,
  categoryId,
}) {
  return services?.reduce((updatedServices, service, index) => {
    const isDuplicate = !updatedServices.some(
      (el) =>
        service.label === el.name &&
        service?.rentalId === el?.rentalId &&
        service?.rentalId !== undefined
    );

    if (isDuplicate) {
      const scopeAmount = calculateScopeAmount(category, service);

      const taxAmount = calculateTaxAmount(category, service, scopeAmount);

      const updatedService = {
        id: index + 1,
        name: service.label,
        description: "",
        isHoist: checkIfServiceIsHoist(service),
        amount: scopeAmount,
        maxSuggestedAmount: scopeAmount,
        amountPercentage: calculateAmountPercentage(scopeAmount, scopeAmount),
        scopeAmount,
        tax: taxAmount > 0,
        taxAmount,
        serviceId: service?.serviceId || service?.id,
        ...(isRentalOrCharge(category) && service?.rentalId
          ? { rentalId: service?.rentalId }
          : {}),
        ...(chargeCategoryFrom ? { chargeCategoryFrom } : {}),
        type: category,
        categoryElementIndex: index,
        group: groupName,
        categoryId,
        category,
        data: {
          serviceOptions: service?.serviceOptions,
          type: category,
          categoryElementIndex: index,
          group: groupName,
          categoryId,
          category,
        },
        total: calculateTotalAmount(scopeAmount, taxAmount),
        creditedAmount: 0,
      };

      updatedServices.push(updatedService);
    }

    return updatedServices;
  }, []);
}

export function calculateSubTotal(services) {
  return services?.reduce(
    (acc, service) => (acc += service.amount || service.price || 0),
    0
  );
}

export function calculateTotal(services) {
  return services?.reduce(
    (acc, service) => (acc += service.total || service.totalPrice || 0),
    0
  );
}

export function calculateScopeAmount(category, service) {
  return category === "charges"
    ? service?.price || 0
    : calculateTotalPriceForService(service, category).amount;
}

export function calculateTaxAmount(category, service, amount) {
  return category === "estimations"
    ? (service?.isTaxable ? service?.taxRate : 0) * amount || 0
    : service?.taxAmount || 0;
}

export function calculateAmountPercentage(amount, scopeAmount) {
  return (amount / scopeAmount) * 100;
}

export function calculateTotalAmount(amount, taxAmount) {
  return parseFloat(amount) + (taxAmount || 0);
}

export function isRentalOrCharge(category) {
  return category === "rentals" || category === "charges";
}

export const calculateDueAmount = (invoice, paymentAmount) => {
  return invoice.totalInvoiceAmount - paymentAmount > 0
    ? invoice.totalInvoiceAmount - paymentAmount
    : 0;
};

export const calculateInvoicePaidPercentage = (invoice, paymentAmount) => {
  const invoiceValue = forceToNumber(invoice.totalInvoiceAmount);
  const paymentValue = forceToNumber(paymentAmount);

  const paidPercentage = (paymentValue * 100) / invoiceValue;

  return forceToNumber(paidPercentage);
};

export const calculatePriceDetails = (oldPrice, newPercentage, serviceItem) => {
  const isChargedCredited = getPriceChargedCredited(serviceItem?.charges) || 0;

  const maxAllowedPrice = oldPrice - isChargedCredited;
  const maxPercentage = (maxAllowedPrice / oldPrice) * 100;
  const isPercentageMax = maxPercentage <= newPercentage;
  const currentPrice =
    ((typeof newPercentage === "number"
      ? isPercentageMax
        ? maxPercentage
        : newPercentage
      : forceToNumber(serviceItem.pricePercent)) /
      100) *
    oldPrice;
  const taxAmount = currentPrice * (serviceItem?.taxRate || 0);

  return {
    ...serviceItem,
    price: currentPrice,
    priceAmount: oldPrice,
    totalPrice: currentPrice + taxAmount,
    taxRate: serviceItem?.taxRate || 0,
    pricePercent:
      (typeof newPercentage === "number"
        ? isPercentageMax
          ? maxPercentage
          : newPercentage
        : forceToNumber(serviceItem.pricePercent)) || 0,
    taxAmount: taxAmount,
    isTaxable: serviceItem?.isTaxable
      ? serviceItem.isTaxable
      : taxAmount > 0
      ? true
      : false,
    selected: currentPrice > 0 ? true : false,
  };
};

export function validateChargeRentalState(charge, percentage, projectTaxRate) {
  const newPercentage =
    typeof percentage !== "undefined" ? forceToNumber(percentage) : undefined;

  const newChargeItem = {
    ...charge,
    serviceOptions: charge.serviceOptions.map((service) => ({
      ...service,
      items: service.items
        .filter((filterService) => filterService.maxWeeklyAmount)
        .map((serviceItem) => {
          const oldPrice = forceToNumber(serviceItem.appliedAmount);
          const newServiceItem = {
            ...serviceItem,
            taxRate: projectTaxRate || serviceItem?.taxRate || 0,
          };
          return {
            ...calculatePriceDetails(oldPrice, newPercentage, newServiceItem),
            completation: 100,
          };
        }),
      ...(service?.elevationAddons && {
        elevationAddons: service.elevationAddons.map((elevationAddon) => {
          const oldPrice = forceToNumber(
            elevationAddon.includedTax.priceWithoutTax
          );

          return calculatePriceDetails(oldPrice, newPercentage, elevationAddon);
        }),
      }),
    })),
  };

  return newChargeItem;
}

export const addTotalitiesToCharges = (chargeItems) => {
  return chargeItems.map((service) => ({
    ...service,
    price: getServiceLegacyPriceWithoutTax(service),
    taxAmount: getServiceLegacyTaxAmount(service),
    totalRentalAmount:
      service.serviceOptions?.reduce(
        (optionAcc, option) =>
          optionAcc +
          option.items.reduce(
            (itemAcc, item) => itemAcc + (item.appliedAmount || 0),
            0
          ),
        0
      ) || 0,
  }));
};
