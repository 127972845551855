import { Button } from "antd";
import React, { useState } from "react";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import AccountingConfirmationModal from "../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../Context/Context";
import "./DeleteService.scss";
import {
  deleteCreditHandler,
  deleteHoistCreditHandler,
  deleteHoistServiceHandler,
} from "./helpers";
import { DeleteFilled } from "@ant-design/icons";
function DeleteService({ type, service, grid: gridParams, elevation }) {
  const dispatchContext = useDispatchContext();
  const { darkMode, fakeApplication, isWritable } = useStateContext();
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const deleteService = () => {
    let name = gridParams?.value;

    if (type === "Hoist") {
      deleteHoistCreditHandler({
        fakeApplication,
        service,
        dispatchContext,
        elevation,
        eventName: name,
      });
    } else {
      deleteCreditHandler({
        fakeApplication,
        service,
        dispatchContext,
        eventName: name,
      });
    }
  };

  return (
    <div className="delete-credit-wrapper">
      <MondayButton
        className="cancelMondayButton"
        onClick={() => setConfirmModalVisibility(true)}
        Icon={<DeleteFilled />}
        disabled={
          !isWritable || gridParams?.data?.fromPrevious !== 0
          // fakeApplication?.applicationNo !== 1
        }
      >
        Credit
      </MondayButton>
      <AccountingConfirmationModal
        {...{
          setVisible: setConfirmModalVisibility,
          visible: confirmModalVisibility,
          onDiscard: () => {},
          onConfirm: deleteService,
          saveButton: true,
          title: "Delete Credit",
          confirmButtonText: "Delete Credit",
          darkMode,
          text: "Are you sure you want to delete this credit? You can not undo this action.",
        }}
      />
    </div>
  );
}

export default DeleteService;
