import "./CustomCheckBox.scss";
import { Checkbox } from "antd";
import { useSelector } from "react-redux";
import WithTooltip from "../WithTooltip/WithTooltip";

const CustomCheckBox = ({
  name,
  checked,
  indeterminate,
  disabled,
  onChange,
  style = {},
  textStyle = {},
  tooltipCategory,
  tooltipKey,
  ...props
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <WithTooltip tooltipCategory={tooltipCategory} tooltipKey={tooltipKey}>
      <div
        className={`custom-checkbox-wrapper ${
          isDarkMode && "custom-checkbox-wrapper-dark"
        }`}
        style={style}
      >
        <Checkbox
          id={name}
          key={name}
          indeterminate={indeterminate}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          {...props}
        >
          <span style={textStyle}>{name}</span>
        </Checkbox>
      </div>
    </WithTooltip>
  );
};

export default CustomCheckBox;
