import React, { cloneElement } from "react";
import { LocationIcon } from "../../SidebarPages/Communication/assets";

import "./Marker.css";

class Marker extends React.Component {
  handleClick = () => {
    if (typeof this.props?.onClick === "function") {
      this.props.onClick();
    } else {
      this.props?.address
        ? window.open(
            `https://www.google.com/maps/place/${this.props.address}/@${this.props.lat},${this.props.lng}`,
            "_blank"
          )
        : window.open(
            `https://www.google.com/maps/search/?api=1&query=${this.props.lat},${this.props.lng}`,
            "_blank"
          );
    }
  };

  render() {
    const children = cloneElement(this?.props?.children || <div></div>, {
      className: "svg-pin childrenBounce",
    });

    return (
      <div onClick={this.handleClick} data-testid="location-marker">
        {this.props?.showIcon ? (
          <LocationIcon className="svg-pin" fill={this.props.color} />
        ) : this?.props?.children ? (
          children
        ) : (
          <div
            className="pin bounce"
            style={{ backgroundColor: this.props.color, cursor: "pointer" }}
          />
        )}
        <div className="pulse" />
      </div>
    );
  }
}

export default Marker;
