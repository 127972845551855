import { SimplePLI } from "./GeneralModels";

export class VerticalNettingPLI extends SimplePLI {
  serviceId = 18;

  length;
  height;

  fromFloor;

  toFloor;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateVerticalNettingPLI extends SimplePLI {
  serviceId = 18;

  length;
  height;

  fromFloor;

  toFloor;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
