export const filterData = (newLogData, searchText) => {
  return (
    newLogData?.filter(
      (log) =>
        log?.actionType?.toLowerCase().includes(searchText.toLowerCase()) ||
        log?.nameOfUser?.toLowerCase().includes(searchText.toLowerCase()) ||
        log?.topic?.toLowerCase().includes(searchText.toLowerCase()) ||
        log?.logs?.some((item) => {
          const searchTextLowerCase = searchText.toLocaleLowerCase();

          if (
            (typeof item.previousValue === "string" &&
              item.previousValue.toLowerCase().includes(searchTextLowerCase)) ||
            (typeof item.updatedValue === "string" &&
              item.updatedValue.toLowerCase().includes(searchTextLowerCase))
          ) {
            return true;
          }

          if (
            (typeof item.previousValue === "number" &&
              item.previousValue.toString().includes(searchText)) ||
            (typeof item.updatedValue === "number" &&
              item.updatedValue.toString().includes(searchText))
          ) {
            return true;
          }

          return false;
        })
    ) || []
  );
};
