export const getContextMenuItems = (params, appendRowInGrid, removeRowInGrid, service, events) => {
  let id = undefined          // Id of row, if we have right clicked on empty space on grid
  let NOT_ON_ROWS = true      // If we have not right clicked on any row
  if (params.node) {
    id = params.node.data.id  // If we clicked on one row, get id of it
    NOT_ON_ROWS = false       // And make not on rows false
  }

  return [
    'autoSizeAll',
    'resetColumns',
    'separator',
    {
      name: 'Add Event',
      icon: '<img src="https://img.icons8.com/material-outlined/24/000000/plus--v1.png" alt="" />',
      action: () => appendRowInGrid(id),
      disabled: !!events && service?.amounts?.length === events?.length  // Disabled when we have added all available events
    },
    {
      name: 'Remove Event',
      icon: '<img src="https://img.icons8.com/material-outlined/24/000000/delete-trash.png" alt=""/>',
      action: () => removeRowInGrid(id),
      disabled: NOT_ON_ROWS
    },
    'separator',
    'copy',
    'copyWithHeaders',
    'paste',
    'separator',
    'export',
    'chartRange'
  ]
}