import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
export const options = {
  plugins: {
    title: {
      display: true,
      text: "Proposal Total Amount",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [10, 20, 30, 40, 50, 60, 70],
      //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      label: "Dataset 2",
      data: [10, 20, 30, 40, 50, 60, 70],

      //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: "rgb(75, 192, 192)",
    },
    {
      label: "Dataset 3",
      data: [10, 20, 30, 40, 50, 60, 70],

      //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: "rgb(53, 162, 235)",
    },
  ],
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BodyStatistics() {
  return (
    <div
      className="body-statistics-wrapper"
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
        // height: "100%",
      }}
    >
      <div
        style={{
          width: "619px",
          borderRadius: "10px",
          background: " #F8F8FA",
        }}
      >
        {" "}
        <Bar options={options} data={data} />{" "}
      </div>{" "}
      <div
        style={{
          width: "619px",
          borderRadius: "10px",
          background: " #F8F8FA",
        }}
      >
        {" "}
        <Line options={options} data={data} />{" "}
      </div>{" "}
      <div
        style={{
          width: "619px",
          borderRadius: "10px",
          height: "300px",
          background: " #F8F8FA",
        }}
      >
        {" "}
        <Bar options={options} data={data} />{" "}
      </div>
    </div>
  );
}

export default BodyStatistics;
