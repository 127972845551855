import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import LiveAuditContext from "../../LiveAuditContext";
import { FleetsLiveContext, loadLivePreference } from "../../../../utils";
import { LocationInfo } from "../../../LiveMapView/components";

import "./AuditSidebar.scss";

function AuditSidebar() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { locations } = useContext(FleetsLiveContext);
  const { onVehicleSelect, selectedVehicle } = useContext(LiveAuditContext);

  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (!vehicles?.length) {
      filterVehicles();
    }
  }, [locations]);

  function filterVehicles(e) {
    let text = (e?.target?.value || "")?.toLowerCase();
    if (!text) {
      setVehicles(
        locations?.map(({ fleetName, fleetId }) => ({
          fleetName,
          fleetId,
        }))
      );
    } else {
      setVehicles(
        locations?.flatMap(({ fleetName, fleetId }) => {
          if (fleetName?.toLowerCase().includes(text)) {
            return {
              fleetName,
              fleetId,
            };
          } else {
            return [];
          }
        })
      );
    }
  }

  return (
    <div
      className={`audit-sidebar audit-side-${loadLivePreference(
        "auditVehicleSide"
      )} ${isDarkMode ? "audit-side-dark" : ""}`}
      id="audit-sidebar"
    >
      <div className="sidebar-search">
        <Input
          {...{
            placeholder: "Search Vehicle...",
            allowClear: true,
            size: "medium",
            style: {
              width: "95%",
            },
            prefix: <SearchOutlined />,
            onChange: filterVehicles,
          }}
        />
      </div>
      <div className="trucks-section">
        {vehicles?.map(({ fleetName, fleetId }, index) => {
          return (
            <LocationInfo
              {...{
                id: fleetId === selectedVehicle ? "location-selected" : "",
                location: { fleetName, fleetId },
                nameTooltip: "Open vehicle trips",
                isDarkMode,
                onInfoClick() {
                  onVehicleSelect(fleetId);
                },
              }}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AuditSidebar;
