import { useState, useCallback, Fragment } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { v4 } from "uuid";
import { message } from "antd";

import { columnDefs } from "./data";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { WarningModal } from "src/components/commonComponents";
import { WarningTriangle } from "../../src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { AddIcon } from "src/components/Header/components/GoogleEvents/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./InactivityCard.scss";

function InactivityCard({ params, isDarkMode, getEditedValue, setEditMode }) {
  const {
    createdAt,
    statusKey,
    currentStatus,
    inactivityStatuses,
    inactivityTimeline,
    recordStatuses,
  } = params;

  const [gridApi, setGridApi] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState();
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [currentTimeline, setCurrentTimeline] = useState(
    inactivityTimeline.find(({ end }) => !end)?.id
  );

  const onGridReady = useCallback((event) => {
    const { api } = event;

    api.setColumnDefs(columnDefs);
    api.setRowData(inactivityTimeline);

    setGridApi(api);

    setTimeout(() => {
      api.redrawRows();
    }, 0);
  }, []);

  const onFirstDataRendered = useCallback((event) => {
    const { api } = event;
    api.sizeColumnsToFit();
  }, []);

  const getRowId = useCallback((event) => {
    return event.data.id;
  }, []);

  const onRowDoubleClicked = useCallback(
    (event) => {
      if (event.data.id === currentTimeline) {
        message.warning({
          content:
            "This row cannot be edit since this represents the current timeline",
          key: "dbl1",
          duration: 5,
        });
        message.warning({
          content: "Change the record status to terminate the current timeline",
          key: "dbl2",
          duration: 5,
        });
      }
    },
    [currentTimeline]
  );

  function onModalClose() {
    setEditMode(false);
    setCancelModalVisible(false);
  }

  function getLogData(cardData) {
    return {
      id: "inactivity",
      Card: "InactivityCard",
      title: "Suspension Timeline",
      cardParams: {
        ...cardData,
      },
    };
  }

  function onConfirm() {
    const inactivityTimeline = [];
    let error = false;

    const newRowData = {
      [statusKey]: selectedStatus,
      inactivityTimeline,
    };

    gridApi.forEachNode((node) => {
      if (error) {
        return;
      }

      if (node.data.id !== currentTimeline) {
        if (Object.keys(node.data).some((key) => !node.data[key])) {
          error = true;
          return;
        }
      }

      inactivityTimeline.push(node.data);
    });

    if (error) {
      message.warning({
        content: "Please fill all the data before saving",
        key: "missingData",
      });
      return;
    }

    getEditedValue(
      newRowData,
      {},
      {},
      getLogData({
        ...params,
        currentStatus: selectedStatus,
        inactivityTimeline: newRowData,
      }),
      getLogData(params)
    );

    onModalClose();
  }

  function onStatusSelect(newStatus) {
    setSelectedStatus(newStatus);

    const prevStatus = selectedStatus;
    if (inactivityStatuses.includes(newStatus)) {
      if (inactivityStatuses.includes(prevStatus)) {
        /**
         * In this case, the user changed the status, however the
         * record should still remain inactive
         */
        if (currentTimeline) {
          gridApi.applyTransaction({
            update: [
              {
                ...gridApi.getRowNode(currentTimeline).data,
                reason: newStatus,
              },
            ],
          });
        }
      } else {
        /**
         * In this case, the user creates a new timeline
         */
        const newId = v4();
        setCurrentTimeline(newId);

        setTimeout(() => {
          gridApi.applyTransaction({
            add: [
              {
                start: parseInTz(new Date().toISOString())
                  .startOf("day")
                  .valueOf(),
                end: null,
                reason: newStatus,
                id: newId,
              },
            ],
            addIndex: 0,
          });
        }, 0);
      }
    } else {
      if (inactivityStatuses.includes(prevStatus)) {
        /**
         * In this case the user wants to reactive the record
         */
        if (currentTimeline) {
          gridApi.applyTransaction({
            update: [
              {
                ...gridApi.getRowNode(currentTimeline).data,
                end: parseInTz(new Date().toISOString())
                  .startOf("day")
                  .valueOf(),
              },
            ],
          });
        }
      }
    }

    setTimeout(() => {
      gridApi.redrawRows();
    }, 0);
  }

  function onAddNewTimeline() {
    gridApi.applyTransaction({
      add: [{ start: null, end: null, reason: "", id: v4() }],
      addIndex: 0,
    });
  }

  function onDelete(row) {
    gridApi.applyTransaction({
      remove: [{ id: row.id }],
    });

    if (row.id === currentTimeline) {
      setCurrentTimeline(undefined);
    }
  }

  function onMakeCurrent(data) {
    setCurrentTimeline(data.id);

    setTimeout(() => {
      gridApi.applyTransaction({
        update: [{ ...data, reason: selectedStatus }],
      });

      gridApi.redrawRows({
        rowNodes: [gridApi.getRowNode(data.id)],
      });
    }, 50);
  }

  return (
    <Fragment>
      <div
        className={`inactivity-edit-card ${
          isDarkMode ? "inactivity-edit-dark" : ""
        }`}
      >
        <div className="inactivity-controller">
          <InputComponent
            {...{
              label: "Status",
              type: "select",
              noFormItem: true,
              allowClear: false,
              onSelect: onStatusSelect,
              initialValue: selectedStatus,
              customOptions: recordStatuses.map((e) => ({
                label: e,
                value: e,
              })),
            }}
          />
          <MondayButton
            {...{
              Icon: <AddIcon />,
              onClick: onAddNewTimeline,
            }}
          >
            New Timeline
          </MondayButton>
        </div>
        <div
          className={`inactivity-body ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
        >
          <AgGridReact
            {...{
              onGridReady,
              onFirstDataRendered,
              context: {
                onDelete,
                createdAt,
                onMakeCurrent,
                selectedStatus,
                currentTimeline,
                inactivityStatuses,
              },
              getRowId,
              onRowDoubleClicked,
              pagination: true,
              paginationPageSize: 6,
              suppressRowClickSelection: true,
              animateRows: true,
              defaultColDef: {
                resizable: true,
                enableColResize: true,
                enableRowGroup: false,
                sortable: true,
                filter: true,
                flex: 1,
                suppressSizeToFit: true,
                suppressDragLeaveHidesColumns: true,
              },
            }}
          />
        </div>
        <div className="confirmContainer">
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick() {
                setCancelModalVisible(true);
              },
            }}
          >
            Cancel
          </MondayButton>
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onConfirm,
            }}
          >
            Confirm
          </MondayButton>
        </div>
      </div>
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                key="decline"
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setCancelModalVisible(false)}
              >
                No
              </MondayButton>
              <MondayButton
                key="confirm"
                Icon={<TickIcon />}
                onClick={onModalClose}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
}

export default InactivityCard;
