export const countSovItems = (chargeItems, label, estimationId) => {
  const matchingItem = chargeItems?.find(
    (e) => e.label === label && e.estimationId === estimationId
  );

  if (!matchingItem) {
    return 0;
  }

  if (label === "Hoist") {
    return matchingItem.serviceOptions
      ?.flatMap(
        (serviceOption) =>
          serviceOption?.items?.flatMap(
            (item) =>
              item?.amounts?.filter((amount) => amount.price > 0)?.length || 0
          ) || []
      )
      .reduce((acc, curr) => acc + curr, 0);
  }

  return matchingItem.amounts?.filter((item) => item.price > 0)?.length || 0;
};
