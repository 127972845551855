import { MondayButton } from "../../../../../../../../../commonComponents";
import { TemplatesIcon } from "../../../../../../../../BasePage/src";
import { formatCurrency } from "../../../../../../../../utils";
import EventsModal from "./Components/EventsModal/EventsModal";
import { useStateContext } from "../../../../Context/Context";
import "./service-header.scss";
import { message } from "antd";

import { useState } from "react";
import ChangeSOVAmount from "../../Service/ServiceHeader/Components/ChangeSOVAmount/ChangeSOVAmount";
import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const ServiceHeader = ({ service, elevation, serviceEvents }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Context state
  const { isWritable, darkMode } = useStateContext();
  const retainage =
    elevation?.amounts?.reduce(
      (a, b) => a + forceToNumber(b?.retainage || 0),
      0
    ) || 0;

  return (
    <>
      <div
        className={
          darkMode
            ? "serviceHeaderHoistDarkMode"
            : "serviceHeaderHoistLightMode"
        }
      >
        <div className="details-container">
          <div className="titleContainerHoist">
            <div className="serviceHeaderServiceHoistTittle">
              {elevation?.elevationLabel}
            </div>
            <div className="buttonContainer">
              <MondayButton
                className={`changeTemplateSOVButton ${
                  isWritable ? "" : "buttonNotClickable"
                }`}
                onClick={() =>
                  isWritable
                    ? setIsModalVisible(true)
                    : message.error("Please enable Edit Mode!")
                }
                Icon={<TemplatesIcon />}
                //disabled={!isWritable}
              >
                {
                  // If it is undefined or empty array show select template it it has values show change template
                  !!elevation?.typeOfSOV
                    ? elevation?.typeOfSOV
                    : !!!service?.amounts?.length
                    ? "Select Template"
                    : "Change Template"
                }
              </MondayButton>
            </div>
          </div>

          {/* {elevation?.typeOfSOV && (
            <div className="sovHeaderInformationContainer">
              <div className="sovHeaderLabel">Template:</div>
              <div className="sovHeaderData">{elevation.typeOfSOV}</div>
            </div>
          )} */}
          <div className="sovHeaderInformationContainer">
            <div
              className="sovHeaderLabel"
              style={{
                cursor: "pointer",
              }}
            >
              Total Amount:
            </div>
            <div className="sovHeaderData">
              {formatCurrency(forceToNumber(elevation?.totalPrice, "USD"))}
            </div>
          </div>
          <div className="sovHeaderInformationContainer">
            <div className="sovHeaderLabel">Retainage:</div>
            <div className="sovHeaderData">
              {`${formatCurrency(retainage)}`}
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <ChangeSOVAmount
                {...{
                  isHoist: true,
                  service,
                  elevation,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {serviceEvents && (
        <EventsModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          service={service}
          elevation={elevation}
          serviceEvents={serviceEvents}
        />
      )}
      {/* <RetainageModal
        isDetailsModalVisible={isRetainageModalVisible}
        setIsRetainageModalVisible={setIsRetainageModalVisible}
        service={service}
        elevation={elevation}
      /> */}
    </>
  );
};

export default ServiceHeader;
