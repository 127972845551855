export const defaultColDef = {
  minWidth: 70,
  editable: true,
  hide: false,
  filter: true,
  sortable: false,
  resizable: true,
  autoHeight: true,
  enablePivot: true,
  enableRowGroup: false,
}
