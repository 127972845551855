import { useState, useEffect, createContext } from "react";
import "./EventsPreview.scss";
import { Drawer, Modal, Tooltip, Tour, message } from "antd";
import { loadClient } from "../../googleClientAPI/authenticate";
import {
  executeGetEvents,
  executeCalendarList,
} from "../../googleClientAPI/execute";
import EventsList from "./EventsList";
import PreviewHeader from "./PreviewHeader";
import FilterEvents from "./FilterEvents";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import { useRedux } from "../../../../../SidebarPages/hooks/useRedux";
import { useSelector } from "react-redux";
import { QuestionCircleFilled } from "@ant-design/icons";
import CustomModalHeader, {
  PlayVideoTutorial,
  findTutorialSteps,
} from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../ModalVideoTutorials/assets/svg";
import { MondayButton } from "../../../../../commonComponents";
import { updateDocumentTitle } from "../../../../../../utils";
import { compareIncluding } from "../../../../../SidebarPages/utils";
import {
  docDefinition,
  pdfBorderedSection,
} from "../../../../../pages/Settings/pdfExportOnComponents";
import createPDF, { PREVIEW } from "../../../../../../integrations/createPDF";
import moment from "moment";
import { companyDetails } from "../../../../../../helpers/constants";
export const EventContext = createContext({});

function EventsPreview({ calendarPreview, handleCalendarPreview }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);
  const { programFields } = useSelector((state) => state.programFields);
  const { base64 } = useSelector((state) => state.base64);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { onGoogleSignIn, onGoogleSignOut, userDetails } = useGoogleSignIn();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [events, setEvents] = useRedux("calendarEvents");
  const [calendars, setCalendars] = useState(null);
  const [selectedCalendar, setSelectedCalendar] = useState("primary");
  const [calendarColor, setCalendarColor] = useState(null);
  const [user, setUser] = useState(null);
  const [eventTimeInstance, setEventTimeInstance] = useState("This Week");

  const [tourOpen, setTourOpen] = useState(false);
  // const { companyName } = companyDetails;
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item?.categoryName === "General Tutorials")
    ?.subCategories[0]?.videos.find(
      (item) => item.videoName === "Adding events on Calendar"
    )?.videoLink;

  const showModal = () => {
    if (!userDetails) {
      setAuthenticated(true);
      setIsModalVisible(true);
    } else if (userDetails) {
      setAuthenticated(true);
      setIsModalVisible(true);
    }
  };

  //reset states after closing modal
  const closeModalAndCleanUp = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager"
    setIsModalVisible(false);
    handleCalendarPreview(false);
  };

  //when a calendar is selected
  const handleSelect = (value) => {
    if (userDetails) {
      setSelectedCalendar(value);
      setAuthenticated(true);
      extractCalendarColor(calendars, value);
    }
  };

  //When User Icon button is clicked
  const handleChangeUser = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager"
    setIsModalVisible(false);
    handleCalendarPreview(false);
    onGoogleSignIn();
    closeModalAndCleanUp();
    showModal();
  };

  //set color of events when selected calendar has changed
  const extractCalendarColor = (calendarsArr, calendarID) => {
    if (calendarID === "primary") {
      const color = calendarsArr?.find((x) => x.primary);
      setCalendarColor(color?.backgroundColor);
    } else {
      const color = calendarsArr?.find((x) => x.id === calendarID);
      setCalendarColor(color?.backgroundColor);
    }
  };

  const changeEventTimeline = (newTimeline) => {
    if (userDetails) {
      setEventTimeInstance(newTimeline);
      setAuthenticated(true);
    }
  };

  //show or hide modal when calendar preview has been changed
  useEffect(() => {
    if (calendarPreview) {
      showModal();
    } else {
      updateDocumentTitle(); // reset document title to "Lead Manager"
      setIsModalVisible(false);
    }
  }, [calendarPreview]);

  //check if is one authenticated user
  useEffect(() => {
    const timer = setTimeout(() => {
      if (userDetails) {
        loadClient().then(() => {
          setAuthenticated(true);
        });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [userDetails]);

  //get events of calendars for authenticated user or when selected calendar changed
  useEffect(() => {
    if (authenticated && userDetails) {
      const calendar = selectedCalendar ? selectedCalendar : "primary";
      try {
        executeGetEvents(userDetails, calendar, eventTimeInstance, false)
          .then((val) =>
            setEvents(val?.result?.items?.filter((ev) => ev?.summary))
          )
          .then(() => {
            setAuthenticated(false);
          });
      } catch (e) {
        console.error("There was an error connecting to Google account.", e);
      }
    } else !userDetails && setEvents();
  }, [
    authenticated,
    selectedCalendar,
    eventTimeInstance,
    userDetails,
    loggedOnTasks,
  ]);
  useEffect(() => {
    if (authenticated && userDetails) {
      executeCalendarList()?.then((val) => {
        const items = val?.result?.items || [];
        if (!!items.length) {
          const primaryCalendarId = items.find((el) => el.primary).id;
          setCalendars(items);
          setUser(primaryCalendarId);
          extractCalendarColor(items, selectedCalendar);
          if (selectedCalendar === "primary") {
            setSelectedCalendar(primaryCalendarId);
          }
        }
      });
    }
  }, [userDetails, authenticated]);

  //on click sign out button
  const handleSignOutUser = () => {
    onGoogleSignOut();
    setAuthenticated(false);
    closeModalAndCleanUp(null);
    setCalendars(null);
    setUser(null);
    setEvents(null);
  };

  function stepsMapHelper(title) {
    const stepsMap = {
      "Login to Google Account": !userDetails
        ? document.querySelector(".user-icon-style")
        : document.querySelector(".logOut"),
      "Create Event": document.querySelector(".add-icon-style"),
      "Select Calendar": document.querySelector(".calendar-select"),
      "Filter Events": document.querySelector(".filter_container"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    userDetails ? newSteps : newSteps.splice(2, 1);
    return newSteps;
  }

  const tourSteps = [
    {
      title: "Event Tutorial",
      description: (
        <div>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => {
                setShowVideoTutorial(true);
                setTourOpen(false);
              },
              style: { display: "flex", justifyContent: " center" },
              Icon: <VideoTutorialIcon />,
            }}
          >
            Watch Video
          </MondayButton>
        </div>
      ),
      className: "custom-tour-light",
      placement: "center",
      target: document.querySelector(".events-notif-modal"),
    },
    ...mapRefs(findTutorialSteps("eventNotificationModal", programFields)),
  ];

  const onGeneratePdf = () => {
    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;
    const title = "Event List";
    const dynamicPdfData = [
      pdfBorderedSection({
        type: "EventPdf",
        title: "Event List",
        body: events.map((elem) => ({
          labelName: elem.summary,
          description: moment(elem.start.dateTime)
            .tz("America/New_York")
            .format("MM/DD/YYYY HH:mm "),
          info: moment(elem.end.dateTime)
            .tz("America/New_York")
            .format("MM/DD/YYYY HH:mm "),
          detail: elem?.organizer?.displayName || elem?.organizer?.email,
        })),
      }),
    ];
    createPDF({
      action: PREVIEW,
      docDefinition: docDefinition(title, dynamicPdfData, companyLogo),
    });
  };

  const handleExport = () => {
    onGeneratePdf();
  };

  return (
    <>
      {isModalVisible && (
        <Drawer
          rootClassName={`events-notif-modal ${
            isDarkMode && "events-notif-modal-dark"
          }`}
          title={
            <CustomModalHeader
              {...{
                title: "Upcoming & Ongoing Events",
                onClick: () => {
                  setTourOpen(true);
                },
                noX: true,
              }}
            />
          }
          afterOpenChange={(event) => {
            event &&
              updateDocumentTitle({ newTitle: "Upcoming & Ongoing Events" });
          }}
          open={isModalVisible}
          closable={true}
          onClose={closeModalAndCleanUp}
          width="578px"
          closeIcon={<XIcon />}
        >
          <EventContext.Provider
            value={{
              calendars,
              setCalendars,
              handleSelect,
            }}
          >
            <PreviewHeader
              {...{
                userDetails,
                handleChangeUser,
                handleSignOutUser,
                handleExport,
                userConfiguration,
              }}
            />
            <br />
            {userDetails && (
              <FilterEvents
                {...{
                  calendars,
                  selectedCalendar,
                  handleSelect,
                  changeEventTimeline,
                }}
              />
            )}
            <EventsList
              {...{
                events,
                user,
                userDetails,
                calendarColor,
                selectedCalendar,
                handleSelect,
              }}
            />
          </EventContext.Provider>
          {tourOpen && (
            <Tour
              open={tourOpen}
              onClose={() => setTourOpen(false)}
              steps={tourSteps}
              mask={{ color: "#2a2b3a71" }}
            />
          )}
          {showVideoTutorial && (
            <PlayVideoTutorial
              {...{
                visible: showVideoTutorial,
                setVisible: setShowVideoTutorial,
                url: videoTutorialLink,
                title: "Add Event Tutorial",
              }}
            />
          )}
        </Drawer>
      )}
    </>
  );
}

export default EventsPreview;
