import { Descriptions } from "antd";
import { currencyFormatter } from "../../../../../../../utils/currencyFormatter";
export const ClientInfo = ({ creditsTotal = 0 }) => {
  return (
    <Descriptions title="Information">
      <Descriptions.Item label="Client">Zhou Maomao</Descriptions.Item>
      <Descriptions.Item label="Amount Due">$0</Descriptions.Item>
      <Descriptions.Item label="Balance Due">$1,000.00</Descriptions.Item>
      <Descriptions.Item label="Discount Used">$0</Descriptions.Item>
      <Descriptions.Item label="Credit Used">$0</Descriptions.Item>
      <Descriptions.Item label="Credits">
        {currencyFormatter(creditsTotal)}
      </Descriptions.Item>
    </Descriptions>
  );
};
