import { formatCurrency } from "../../../../../../../../../../../utils";
import { useStateContext } from "../../../../../../../Context/Context";
import "./amount-cell-renderer.scss";
import { CheckCircleFilled } from "@ant-design/icons";

const AmountCellRenderer = ({ value }) => {
  const { darkMode } = useStateContext();
  return (
    <div
      className={
        darkMode ? "amount-cell-renderer-dark" : "amount-cell-renderer"
      }
    >
      <div className="amount-value">{formatCurrency(value, "USD")}</div>
      <div
        className="amount-status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CheckCircleFilled style={{ color: "#68C142" }} />
      </div>
    </div>
  );
};

export default AmountCellRenderer;
