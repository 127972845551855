import { useSelector } from "react-redux";

const findFieldOptionByNameOrId = (props) => {
  const { fieldName, fieldId, fieldOption } = props;

  const dynamicKeyToTake = !fieldName
    ? { key: "fieldId", value: fieldId }
    : !fieldId
    ? { key: "fieldName", value: fieldName }
    : undefined;

  const { programFields } = useSelector((state) => state.programFields);

  if (fieldOption) {
    const matchedField = programFields?.find(
      ({ [dynamicKeyToTake.key]: key }) => key === dynamicKeyToTake.value
    );

    if (matchedField) {
      const result = {};

      for (const [key, value] of Object.entries(matchedField.fieldOptions)) {
        if (fieldOption.includes(key)) {
          result[key] = value;
        }
      }

      return result;
    }
  }

  return programFields?.find(
    ({ [dynamicKeyToTake.key]: key }) => key === dynamicKeyToTake.value
  )?.fieldOptions;
};
export default findFieldOptionByNameOrId;
