import uniq from "lodash/uniq";
import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const workOrders = (
  workOrdersData,
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  return {
    workOrderStatus: () => ({
      title: "Work Order Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      id: IdHandler,
      BodyComponentParams: {
        table: "Work Orders",
        field: "workOrderStatus",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (a) => a?.workOrderStatus
          )
        )?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          data: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a.workOrderStatus?.toLowerCase() === status?.toLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + status,
          contentsHandler: " " + status,
        })),
      },
      width: "1600px",
    }),

    fleetName: () => ({
      title: "Fleet Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      id: IdHandler,
      BodyComponentParams: {
        table: "Work Orders",
        field: "fleetName",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.fleetName)
        )?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          data: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a.fleetName?.toLowerCase() === status?.toLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + status,
          contentsHandler: " " + status,
        })),
      },
      width: "1600px",
    }),

    mechanicName: () => ({
      title: "Work Orders: Mechanic Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      id: IdHandler,
      BodyComponentParams: {
        table: "Work Orders",
        field: "mechanicName",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (a) => a?.mechanicInfo?.mechanic || a?.mechanicInfo?.mechanicName
          )
        )?.map((mechanicName, index) => ({
          key: index,
          title: mechanicName,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          data: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a.mechanicName?.toLowerCase() === mechanicName?.toLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + mechanicName,
          contentsHandler: " " + mechanicName,
        })),
      },
      width: "1600px",
    }),

    dueDate: () => ({
      title: "Work Orders: Due Date",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      id: IdHandler,
      BodyComponentParams: {
        table: "Work Orders",
        field: "dueDate",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) => a?.dueDate >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a?.dueDate >= getMonthsTimestamps()[i] &&
                    a?.dueDate <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Due In " + months[i],
                contentsHandler: " Due In " + months[i],
              },
            ];
          }
          return data;
        })(),
      },
      width: "1600px",
    }),
  };
};
