import { message } from "antd";
import { API } from "aws-amplify";
import { updateProgramFieldByName } from "../../../../../../actions/programFields";
import { findNestedElement, getObjectChanges } from "../../../../../../utils";

export const saveDynamicTooltips = async ({ dispatch, fieldOptions }) => {
  message.loading("Saving...");

  return await API.patch(
    "programFields",
    "/programFields/534-sdfsd-hfghf-564-fdhfgh-675675",
    { body: { fieldOptions } }
  )
    .then(() => {
      message.destroy();
      message.success("Changes Saved Successfully!");
      dispatch(
        updateProgramFieldByName({
          fieldName: "Tooltip Configuration",
          fieldOptions,
        })
      );
    })
    .catch((err) => {
      message.error("Something went wrong!");
      console.log(err);
    });
};

export const updateLogsState = (
  newTooltipChanges,
  oldTooltipChanges,
  setTooltipLogsToSave,
  selectedKeys,
  actionType
) => {
  setTooltipLogsToSave((prev) => {
    const logKey = selectedKeys.join(" | "); //get key for curr log

    const logExits = prev.some(({ topic }) => topic === logKey); //check if log already exists

    const currData = findNestedElement(newTooltipChanges, selectedKeys); //curr tooltip data
    const prevData = findNestedElement(oldTooltipChanges, selectedKeys); //prev tooltip data

    const previousData = getObjectChanges(currData, prevData); // tooltip previous data
    const currentData = getObjectChanges(prevData, currData); // tooltip current data

    return logExits
      ? prev.map((log) =>
          log.topic === logKey ? { ...log, currentData } : log
        )
      : [
          ...prev,
          {
            actionType: actionType || "Update",
            recordId: "Tooltip Categories",
            recordName: logKey,
            topic: logKey,
            label: selectedKeys[selectedKeys.length - 1],
            currentData,
            previousData,
            updatedKeys: selectedKeys,
          },
        ];
  });
};
