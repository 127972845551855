import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const fleetInspections = (
  circleProgressPicker,
  IdHandler,
  updatedDynamicStates
) => {
  return {
    inspectedBy: () => ({
      title: "Inspected By",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "inspectedBy",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.inspectedBy?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.inspectedBy?.toLowerCase() ===
            circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //inspectedBy
    driverName: () => ({
      title: "Inspection Driver Name",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "driverName",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.driver?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.driver?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //driverName
    createdAt: () => ({
      title: "Inspection Created Date",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "createdAt",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.createdAt >=
                getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
              a?.createdAt <=
                getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
          ).length /
            updatedDynamicStates[IdHandler]?.filter(
              (a) => a?.createdAt >= getMonthsTimestamps()[0]
            ).length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.createdAt >=
              getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.createdAt <=
              getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length,
      },
      id: IdHandler,
      width: "390px",
    }), //createdAt
  }; //Inspections
};
