import React, { useEffect } from "react";
import {
  Alert,
  Button,
  Flex,
  Form,
  Image,
  Modal,
  QRCode,
  Steps,
  Typography,
} from "antd";
import { useSelector } from "react-redux";

import "./TwoFactorAuthenticatorModal.scss";
import { useTwoFactorAuthentication } from "../pages/Settings/settingsComponents/UserProfile/AccountInformation/components/UserInfos/TwoFactorAuthenticationChange/useTwoFactorAuthentication";
import { MondayButton } from "../commonComponents";
import { InputComponent } from "../SidebarPages/Fleet/components";
import { useNavigate } from "react-router-dom";
import PlayStore from "./images/google-play-badge.png";
import AppStore from "./images/ios-store-badge.jpg";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { TickIcon } from "../pages/Settings/settingsComponents/Roles/src";

const IOS_LINK =
  "https://apps.apple.com/us/app/google-authenticator/id388497605";
const ANDROID_LINK =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pli=1";

export const allSteps = (setupUri) => [
  {
    id: 1,
    title: "Download Google Authenticator",
    description: "Scan the QR code with your authenticator app.",
    Component: () => (
      <Flex className="authenticator-apps-container">
        <Flex className="information-apps">
          <Typography>
            1. Open the <b>App Store</b> on your <b>iOS device</b> or the{" "}
            <b>Google Play Store</b> on your <b>Android device</b>.
          </Typography>
          <Typography>
            2. Use the search bar to search for <b>"Google Authenticator"</b>.
          </Typography>
          <Typography>
            3. Find the app named <b>Google Authenticator by Google LLC</b> and
            tap on it.
          </Typography>
          <Typography>
            4. Tap the <b>Get or Install</b> button to download and install the
            app.
          </Typography>
          <Typography>
            5. Once installed, open the app to begin the setup process.
          </Typography>
        </Flex>
        <Flex className="authenticator-apps">
          <a href={ANDROID_LINK} target="_blank" rel="noopener noreferrer">
            <Image
              src={PlayStore}
              alt="Download on Play Store"
              width={200}
              preview={false}
            />
          </a>
          <a href={IOS_LINK} target="_blank" rel="noopener noreferrer">
            <Image
              src={AppStore}
              alt="Download on App Store"
              width={200}
              preview={false}
            />
          </a>{" "}
        </Flex>
      </Flex>
    ),
  },
  {
    id: 2,
    title: "Scan QR code",
    description: "Scan the QR code with your authenticator app.",
    Component: () => (
      <>
        <Flex className="information">
          <Typography>- In the Google Authenticator app tap the +</Typography>
          <Typography>
            - Choose <b>Scan a QR code</b>
          </Typography>
        </Flex>
        {setupUri && <QRCode size={256} value={setupUri} className="qrCode" />}
      </>
    ),
  },
  {
    id: 3,
    title: "Enter verification code",
    description: "Enter the verification code from your authenticator app.",
    Component: () => (
      <Flex gap={30} vertical>
        <Flex className="information-apps">
          <Typography>
            1. Google Authenticator will generate a <b>6-digit code</b> that
            refreshes every <b>30 seconds</b>.
          </Typography>
          <Typography>
            2. Enter the generated code into the required field during your
            account setup process to complete the verification.
          </Typography>
        </Flex>
        <InputComponent
          type="otp"
          size="large"
          name="code"
          label="Enter the 6-digit code you see in the app"
          placeholder="Enter code"
          className="otpInput"
        />
      </Flex>
    ),
  },
];

const TwoFactorAuthenticatorModal = ({ open }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const {
    isEnabled,
    isLoading,
    setupUri,
    currentStep,
    verifyCode,
    handleStepChange,
    setUpTotpHandler,
    qrErrorMessage,
  } = useTwoFactorAuthentication(open);

  const steps = allSteps(setupUri);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const { Component } = steps[currentStep];

  useEffect(() => {
    if (isEnabled) {
      navigate("/");
      return;
    }
    setUpTotpHandler();
  }, [open]);

  return (
    <Flex className="two-factor-authenticator-body">
      <Flex className="title">
        <Typography.Title level={2}>
          Welcome {userConfiguration?.nameOfUser}
        </Typography.Title>
        <Typography.Title level={5}>
          To enhance your account security, we recommend using Google
          Authenticator for two-factor authentication (2FA). Follow the steps
          below to download and set up Google Authenticator on your mobile
          device:
        </Typography.Title>
      </Flex>
      <Form form={form}>
        <div className="two-factor-authenticator-information">
          <Steps current={currentStep} items={items} />
          {!qrErrorMessage ? (
            <Flex className="authenticator-container">
              {isLoading ? <div>Loading</div> : <Component />}
            </Flex>
          ) : (
            <Alert
              message={qrErrorMessage}
              type="error"
              action={
                <Button
                  size="small"
                  type="text"
                  danger
                  onClick={setUpTotpHandler}
                >
                  Try again
                </Button>
              }
            />
          )}
        </div>
      </Form>
      <Flex className="footerButtons">
        {currentStep > 0 && (
          <MondayButton
            className="mondayButtonGrey"
            onClick={() => handleStepChange(-1)}
            Icon={<ArrowLeftOutlined />}
          >
            Back
          </MondayButton>
        )}
        {currentStep < steps.length - 1 && (
          <MondayButton
            onClick={() => handleStepChange(1)}
            className="nextStep mondayButtonBlue"
            Icon={<ArrowRightOutlined />}
          >
            Next
          </MondayButton>
        )}
        {currentStep === steps.length - 1 && (
          <MondayButton
            className="mondayButtonGreen"
            Icon={<TickIcon />}
            loading={isLoading}
            disabled={isLoading}
            onClick={async () => await verifyCode(form.getFieldValue("code"))}
          >
            Verify
          </MondayButton>
        )}
      </Flex>
    </Flex>
  );
};

export default TwoFactorAuthenticatorModal;
