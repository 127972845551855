import React from "react";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import CheckboxRenderer from "../../cellRenderers/CheckboxRenderer";
import AddonEditor from "../../cellRenderers/AddonEditor";
import rtfEditor from "../../cellRenderers/rtfEditor";
import GridRowModifier from "../../cellRenderers/GridRowModifier";
import SidewalkShedSpansDetail from "./subcomponents/SidewalkShedSpansDetail";
import SpanCellRenderer from "./SpanCellRenderer";
import { Button, message } from "antd";
import { getNewId } from "../../../tools/polyfillers";
import { SidewalkShedPLIAddonSpan } from "../../../models/SidewalkShedModels";
import { getAddonsColumns } from "../../../tools/columnDefiners/addons/AddonsColumnDefiner";
import GeneralAddonEditor from "../../cellRenderers/AddonEditor/GeneralAddonEditor";
import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import {
  createPLIAddon,
  createSideWalkShedPLIAddon,
} from "../../../tools/polyfillers/AddonsCreator";
import { darkModeRowStyleRules } from "../../../tools/formatters/GridStyles";
import {
  dimensionFormatterAddons,
  evaluateMathExpressionAddons,
} from "../../../tools/formatters/evaluators";
import rtfEditorAddon from "../../cellRenderers/rtfEditorAddon/rtfEditorAddon";
import ScaffoldPlanks from "../../Scaffold/ScaffoldPLIAddon/ScaffoldPlanks";
import SelectCellEditor from "../../cellRenderers/SelectEditor/SelectCellEditor";

export default class SidewalkShedAddonsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.rowId = props.node.id;
    this.masterGridApi = props.api;
  }

  gridData = [];

  onGridReady = (params) => {
    console.log("onGridReady", this.props);
    this.gridApi = params.api;
    this.gridData = this.props.data.addons; //.filter(a => a.type === AddonType.documentation);
    this.masterGridApi.addDetailGridInfo(this.state.rowId, {
      id: this.rowId,
      api: params.api,
      columnApi: params.columnApi,
      // handleUpdateDetailState: this.handleUpdateDetailState,
    });
    console.log("ongridreadyrows", this.gridData);
    const temp = evaluateData(this.gridData);
    this.gridApi.setRowData(temp);

    // this.gridApi.refreshCells();

    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.sizeColumnsToFit();
    // window.addEventListener('resize', () => {this.gridApi.sizeColumnsToFit();});
  };

  componentWillUnmount = () => {
    this.masterGridApi.removeDetailGridInfo(this.rowId);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isWritable !== this.props.isWritable) {
      if (this.gridApi) {
        // this.lastAgGridInstance.api.redrawRows() //if ag grid is ready, re-render all table
        this.gridApi.lastAgGridInstance.api.refreshCells({ force: true });
      }
    }
  }

  handleSave = () => {
    console.log("handleSave", this.gridData);
    this.props.saveSidewalkShedAddonsData(
      this.props.api,
      this.props.indexes.serviceIndex,
      this.props.indexes.optionIndex,
      this.props.indexes.elevationIndex,
      this.props.data.id,
      this.gridData
    );
  };

  addSpan = (addonId) => {
    if (this.props.isWritable) {
      let gridData = this.gridData; //_.cloneDeep(this.gridData); //all addons from this row
      //modify addons by adding a new span in the correct addon
      for (const addon of gridData) {
        if (addon.id === addonId) {
          //find the correct addon
          //check if addon have enough data
          if (addon.sideWalkShedtype === undefined) {
            message.error(
              "Please, fill the addon with data then create a span."
            );
            return;
          }

          //add a new span
          const highestId = getNewId(addon.span); //find the highest id from spans. If there is no span the highest id will be 0
          //insert a new span into span array
          addon.span.push(new SidewalkShedPLIAddonSpan(highestId)); //use the new id which is higher than all other ids
          break;
        }
      }
      // this.gridApi.setRowData(gridData);
      this.props.saveSidewalkShedAddonsData(
        this.props.api,
        this.props.indexes.serviceIndex,
        this.props.indexes.optionIndex,
        this.props.indexes.elevationIndex,
        this.props.data.id,
        gridData,
        { addonId, api: this.gridApi }
      );
      // this.props.api.getRowNode(addonId).setExpanded(true)
    } else {
      message.error("Please enable write mode.");
    }
  };

  removeLastSpan = (addonId) => {
    if (this.props.isWritable) {
      let gridData = this.gridData; //_.cloneDeep(this.gridData); //all addons from this row
      //modify addons by removing the last span in the correct addon
      for (const addon of gridData) {
        if (addon.id === addonId) {
          //find the correct addon
          if (addon.span.pop()) {
            //if we removed the span
            // this.gridApi.setRowData(gridData); //save changed to database
            this.props.saveSidewalkShedAddonsData(
              this.props.api,
              this.props.indexes.serviceIndex,
              this.props.indexes.optionIndex,
              this.props.indexes.elevationIndex,
              this.props.data.id,
              gridData
            );
          } else {
            //if span array has been empty
            message.warning("There is no span to remove");
          }
          break;
        }
      }
    } else {
      message.error("Please enable write mode.");
    }
  };

  // getRowHeight = (params) => {
  //   // console.log("56", params);
  //   if (params.node && params.node.detail) {
  //     const offset = 140;
  //     const allDetailRowHeight =
  //       params.data.span.length *
  //       params.api.getSizesForCurrentTheme().rowHeight;
  //     const gridSizes = params.api.getSizesForCurrentTheme();
  //     return allDetailRowHeight + gridSizes.headerHeight + offset;
  //   }
  // };

  columnResized = (params) => {
    // console.log("Sd", params);
    this.resizeGridRows(params.api);
  };

  resizeGridRows = (gridApi) => {
    // console.log("123", gridApi);
    gridApi.resetRowHeights();
  };

  cellEditStarted = (params) => {
    if (!this.props.isWritable) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  cellValueChanged = (params, valueFromNameChange) => {
    console.log("cellValueChanged", {
      params,
      valueFromNameChange,
    });
    const changedObj = params.data || {};

    if (params.colDef.field === "name") {
      //if name changed, change other data too
      for (const key of valueFromNameChange.values) {
        if (key.name.toLowerCase() !== "name") {
          changedObj[key.name] = key.value;
        } else {
          changedObj[key.name] = valueFromNameChange; //assign name object
        }
      }
    }
    if (params.colDef.field === "spanPrice" && params.data.approved) {
      this.props.data.totalPrice +=
        Number(params.newValue || 0) - Number(params.oldValue || 0);
    }
    if (params.colDef.field === "approved") {
      if (params.newValue) {
        this.props.data.totalPrice += Number(params.data.spanPrice || 0);
      } else {
        this.props.data.totalPrice -= Number(params.data.spanPrice || 0);
      }
    }
    let temp = [];
    this.gridApi.forEachNode((node) => {
      temp.push(node.data);
    });
    console.log("temp", { temp, changedObj });
    this.gridData = temp;
    this.handleSave();
  };

  onRichTextChange = (modifiedAddon) => {
    if (this.props.isWritable !== true) return;

    //modify addon the correct addon
    for (let i = 0; i < this.gridData.length; i++) {
      if (this.gridData[i].id === modifiedAddon.id) {
        //find the correct addon
        this.gridData[i] = modifiedAddon;
        break;
      }
    }

    let { serviceIndex, optionIndex, elevationIndex } = this.props.indexes;
    this.props.onAddonRichTextChange({
      addons: this.gridData,
      addonType: AddonType.pli,
      serviceIndex,
      optionIndex,
      elevationIndex,
      pliId: this.props.data.id,
    });
  };

  appendAddonRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (this.props.isWritable) {
      let addons = _.cloneDeep(this.gridData);

      for (let i = 0; i < addons.length; i++) {
        const item = addons[i];
        if (item.id === itemId) {
          //when we go to the position that we want t append row
          let addon;
          if (this.props.service.serviceId.toString() === "1") {
            addon = createSideWalkShedPLIAddon(
              itemId + 1,
              item.length,
              item.height,
              item.width
            );
          } else addon = createPLIAddon(itemId + 1);

          addons.splice(i + 1, 0, addon);
          i++; //skip the nex position as it is new new added id
        }
        if (item.id > itemId) {
          //all elements with id higher than the added element must be increased by 1
          item.id++;
        }
      }

      this.gridData = addons;
      // this.gridApi.setRowData(this.gridData);
      this.handleSave();
      // this.handleSave(); //we shouldn't save empty addon in database
    } else {
      message.error("Please enable write mode3.");
    }
  };

  duplicateAddonRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (this.props.isWritable) {
      let addons = _.cloneDeep(this.gridData);

      for (let i = 0; i < addons.length; i++) {
        const item = addons[i];
        if (item.id === itemId) {
          // gridData.splice(i + 1, 0, new SidewalkShedPLIAddon(itemId+1));
          let duplicate = { ..._.cloneDeep(item), id: itemId + 1 }; //take the correct item and add it with a new id
          addons.splice(i + 1, 0, duplicate);
          // this.gridApi.applyTransactionAsync({ update: duplicate })
          i++;
        }
        if (item.id > itemId) {
          item.id++;
        }
      }

      this.gridData = addons;
      this.gridApi.setRowData(this.gridData);
      this.handleSave();
    } else {
      message.error("Please enable write mode.");
    }
  };

  removeAddonRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    message.info("Remove addon row");
    if (this.props.isWritable) {
      let addons = _.cloneDeep(this.gridData);

      for (let i = 0; i < addons.length; i++) {
        const item = addons[i];
        if (item.id === itemId) {
          addons.splice(i, 1); //remove from this index
          i--; //come once more in this index
        }
        if (item.id > itemId) {
          //all pli that have higher ID that then deleted pli must decrease their id by 1
          item.id--;
        }
      }

      this.gridData = addons;
      // this.gridApi.setRowData(this.gridData);
      this.handleSave();
    } else {
      message.error("Please enable write mode.");
    }
  };

  saveSidewalkShedSpanData = (addonId, spanData) => {
    let gridData = _.cloneDeep(this.gridData);
    for (let addon of gridData) {
      if (addon.id === addonId) {
        addon.span = spanData;
        break;
      }
    }
    this.gridData = gridData;
    this.gridApi.setRowData(this.gridData);
    this.handleSave();
  };

  // refresh(params) {
  //     // check and see if we need to get the grid to tear this
  //     // component down and update it again
  //     return true;
  // }

  render() {
    return (
      <div className="addonsContainer" style={{ height: "auto" }}>
        <div
          className="sidewalk-shed-addon-header"
          style={{ marginBottom: "10px" }}
        >
          {/* {this?.props?.service?.serviceId === 2 && <ScaffoldPlanks />} */}
        </div>
        {console.log("props col", this.props)}
        {console.log("saveSidewalkShedSpanData", this.saveSidewalkShedSpanData)}
        {console.log("state col ", this)}
        <div
          // key={this.state.key}
          className="dataEntryGrid"
        >
          <AgGridReact
            id="detailGrid"
            // autoHeight={true}
            rowHeight={40}
            animateRows={true}
            immutableData={true}
            enableFillHandle={true}
            embedFullWidthRows={true}
            enableRangeSelection={true}
            detailRowAutoHeight={true}
            enableCellChangeFlash={true}
            undoRedoCellEditingLimit={50}
            onGridReady={this.onGridReady}
            columnDefs={this.createAddonColumns()}
            // getRowHeight={this.getRowHeight}
            // onColumnResized={this.columnResized}
            getRowNodeId={(data) => data.id}
            defaultColDef={this.defaultColDef}
            onCellValueChanged={this.cellValueChanged}
            onCellEditingStarted={this.cellEditStarted}
            suppressDragLeaveHidesColumns={true}
            // onCellEditingStopped={(params) => {
            //   params.api.resetRowHeights();
            // }}
            domLayout={"normal"}
            frameworkComponents={this.frameworkComponents}
            masterDetail={true}
            detailCellRenderer="SidewalkShedSpansDetail"
            detailCellRendererParams={{
              saveSidewalkShedSpanData: this.saveSidewalkShedSpanData,
            }}
            rowClassRules={darkModeRowStyleRules}
          />
        </div>
      </div>
    );
  }

  createAddonColumns = () => {
    if (this.props.pricingData === undefined)
      console.warning("this.props.pricingData === undefined");

    if (this.props.service.serviceId.toString() === "1") {
      for (const c of this.columnDefs) {
        if (c.cellRenderer === "rtfEditor")
          c.cellRendererParams = {
            service: this.props.service,
            isWritable: this.props.isWritable,
            onAddonRichTextChange: this.onRichTextChange,
            addonType: AddonType.pli,
            type: "addon",
            agGridTheme: this.props.agGridTheme,
          };
        c.width = 160;
        c.cellStyle = {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        };
      }

      this.columnDefs = this.columnDefs.filter(
        (c) =>
          !(
            c.field === "ppu" ||
            c.field === "rent" ||
            c.field === "price" ||
            c.field === "lock"
          )
      );

      return this.columnDefs;
    } else {
      console.log("this.props", this.props);
      const { priceView, priceEdit, agGridTheme } = this.props;

      return getAddonsColumns(
        this.props.pricingData,
        this.props.service,
        this.cellValueChanged,
        AddonType.pli,
        this.props.isWritable,
        this.props.selectOptions,
        this.onRichTextChange,
        { priceView, priceEdit, agGridTheme },
        this.props?.api,
        this.props?.data,
        this.props?.indexes,
        this.props?.context?.handleSave
      );
    }
  };

  // onInsideAddonChange = (serviceId, addonId, api, addonData) => {
  //     return this.props.onInsideAddonChange(serviceId, addonId, api, addonData, this.props.indexes, this.props.data.id)
  // }

  frameworkComponents = {
    CheckboxRenderer: CheckboxRenderer,
    AddonEditor: AddonEditor,
    rtfEditor: rtfEditor,
    rtfEditorAddon: rtfEditorAddon,
    SidewalkShedSpansDetail: SidewalkShedSpansDetail,
    SpanCellRenderer: SpanCellRenderer,
    GeneralAddonEditor: GeneralAddonEditor,
    SelectEditor: SelectCellEditor,
  };

  defaultColDef = {
    minWidth: 100,
    editable: true,
    filter: true,
    sortable: true,
    resizable: true,
    // autoHeight: true,
    rowHeight: 40,
    enablePivot: true,
    enableRowGroup: true,
    detailRowAutoHeight: true,
    cellClass: "cell-wrap-text",
    valueFormatter: (params) => {
      if (params.value) {
        return params.value;
      } else {
        return "-";
      }
    },
  };

  columnDefs = [
    {
      headerName: "ID",
      headerTooltip: "ID",
      field: "id",
      minWidth: 65,
      width: 65,
      editable: false,
      cellClass: "id-column",
      // rowDrag: true,
    },
    {
      headerName: "Approved",
      headerTooltip: "Approved",
      field: "approved",
      minWidth: 150,
      width: 150,
      editable: false,
      cellRenderer: "CheckboxRenderer",
      cellRendererParams: {
        isWritable: this.props.isWritable,
        pliApproved: this.props.data.approved,
      },
    },
    {
      headerName: "Name",
      field: "sideWalkShedtype",
      cellEditor: "AddonEditor",
      cellEditorParams: {
        saveSidewalkShedSpanData: this.saveSidewalkShedSpanData,
      },
      valueFormatter: (params) => {
        if (params.value) {
          return params.value[0].toUpperCase() + params.value.substring(1);
        }
      },
    },
    {
      headerName: "L",
      field: "length",
      valueParser: evaluateMathExpressionAddons,
      valueFormatter: dimensionFormatterAddons,
      cellClassRules: {
        "invalid-price": (params) => {
          let value = parseFloat(params?.value);
          return isNaN(value) || value === Infinity || value <= 0;
        },
      },
    },
    {
      headerName: "H",
      field: "height",
      // editable: false,
      valueParser: evaluateMathExpressionAddons,
      valueFormatter: dimensionFormatterAddons,
      cellClassRules: {
        "invalid-price": (params) => {
          let value = parseFloat(params?.value);
          return isNaN(value) || value === Infinity || value <= 0;
        },
      },
      // valueGetter: params => {
      //     if (params.data.height) {
      //         return params.data.height;
      //     } else {
      //         return this.props.data.height;
      //     }
      // },
    },
    {
      headerName: "W",
      field: "width",
      // editable: false,
      valueParser: evaluateMathExpressionAddons,
      valueFormatter: dimensionFormatterAddons,
      cellClassRules: {
        "invalid-price": (params) => {
          let value = parseFloat(params?.value);
          return isNaN(value) || value === Infinity || value <= 0;
        },
      },
      // valueGetter: params => {
      //     if (params.data.width) {
      //         return params.data.width;
      //     } else {
      //         return this.props.data.width;
      //     }
      // },
    },
    {
      headerName: "Span",
      field: "span",
      width: 150,
      hide: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        // checkbox: true,
        innerRenderer: "SpanCellRenderer",
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        addSpan: this.addSpan,
        removeLastSpan: this.removeLastSpan,
        isWritable: this.props.isWritable,
      },
      editable: false,
      valueGetter: (params) => {
        if (params.data.type === "span") {
          return null;
        } else {
          return params.data.span;
        }
      },
      valueFormatter: (params) => {
        if (params.data.span) {
          return `(${params.data?.span?.length})`;
        } else {
          return "N/A";
        }
      },
    },
    {
      headerName: "Price",
      headerTooltip: "Price",
      field: "spanPrice",
    },
    {
      headerName: "Description",
      headerTooltip: "Description",
      field: "description",
      width: 300,
      cellRenderer: "rtfEditor",
      // cellRenderer: (params) => {
      //     return params.value ? `${params.value}` : '-';
      // },
      editable: false,
    },
    {
      headerName: "Note",
      headerTooltip: "Note",
      field: "note",
      width: 150,
      cellRenderer: "rtfEditor",
      // cellRenderer: (params) => {
      //     return params.value ? `${params.value}` : '-';
      // },
      editable: false,
    },
    {
      headerName: "Modify",
      headerTooltip: "Modify",
      minWidth: 55,
      width: 55,
      editable: false,
      resizable: false,
      cellRendererFramework: (params) => {
        return (
          <>
            <GridRowModifier
              type="addon"
              gridParams={params}
              appendRow={this.appendAddonRow}
              duplicateRow={this.duplicateAddonRow}
              removeRow={this.removeAddonRow}
              indexes={{ serviceIndex: 0, optionIndex: 0, elevationIndex: 0 }}
            />
          </>
        );
      },
    },
  ];
}

function evaluateData(rows) {
  let result = JSON.parse(JSON.stringify(rows));
  if (Array.isArray(result) && result.length > 0) {
    for (let row of result) {
      let formulas = [];
      console.log("row", row);
      if (
        !!row?.name &&
        Array.isArray(row?.name?.values) &&
        row?.name?.values.length > 0
      ) {
        formulas = row?.name?.values.filter(
          (v) => !!v.formula && v?.formula?.length > 0
        );
        console.log("formulas 1", formulas);
        Object.keys(row).forEach((key) => {
          let applicables = formulas.find(
            (f) => f.name?.toLowerCase() === key?.toLowerCase()
          );
          console.log("formulas 2", {
            key,
            applicables,
            formulas,
          });

          if (!!applicables) {
            // row[key] = evaluateFormula(
            //   applicables.formula?.toLowerCase(),
            //   row,
            //   applicables?.name?.toLowerCase()
            // );
          }
        });
      }
    }
  }
  console.log("evaluated formula", result);
  return result;
}

function evaluateFormula(formula, obj, key) {
  console.log("context", { obj, formula, key });
  // Extract the keys from the formula using a regular expression
  const keys = formula.match(/[a-zA-Z\s]+/g);

  // Replace each key in the formula with its corresponding value from the object
  keys.forEach((k) => {
    const regex = new RegExp(k, "g");
    formula = formula.replace(regex, obj[k.trim()]);
  });

  // Evaluate the formula using JavaScript's eval() function
  try {
    const result = eval(formula);
    obj[key] = result; // Update the value of the provided key with the result
    return result;
  } catch (error) {
    console.error("Error evaluating formula:", error);
    return null;
  }
}
