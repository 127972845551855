import {
  MandatoryFields,
  TabFieldsTakeOff,
} from "../../models/MandatoryFields";

/**
 * @param items {SimplePLI[]}
 * */
export function checkIfAllRowsHaveValidData(items = []) {
  for (let i = 0; i < items.length; i++) {
    const pli = items[i];
    for (const field of MandatoryFields?.[pli.serviceId] || []) {
      //field must exist, it must be a valid number (not infinity)
      if (checkExemptedFields(pli?.serviceId, pli, field)) continue;
      if (
        pli[field] === undefined ||
        pli[field] === Infinity ||
        isNaN(parseFloat(pli[field]))
      )
        return false;
    }
  }

  return true;
}
export function checkTABIfAllRowsHaveValidData(items = []) {
  for (let i = 0; i < items.length; i++) {
    const pli = items[i];
    for (const field of TabFieldsTakeOff?.[pli.serviceId] || []) {
      //field must exist, it must be a valid number (not infinity)
      if (checkExemptedFields(pli?.serviceId, pli, field)) continue;
      if (
        pli[field] === undefined ||
        pli[field] === Infinity ||
        isNaN(parseFloat(pli[field]))
      )
        return false;
    }
  }

  return true;
}
export function checkIfRowHasValidData(pli) {
  console.log("mandatory", MandatoryFields?.[pli.serviceId], {
    pli,
  });
  if (!!MandatoryFields?.[pli?.serviceId]){
  for (const field of MandatoryFields?.[pli.serviceId]) {
    //field must exist, it must be a valid number (not infinity)
    if (checkExemptedFields(pli.serviceId, pli, field)) continue;

    if (
      pli[field] === undefined ||
      pli[field] === Infinity ||
      isNaN(parseFloat(pli[field]))
    )
      return false;
  }
  return true;}
  else return true;
}
export function checkIfTabRowHasValidData(pli) {
  if (!TabFieldsTakeOff?.[pli.serviceId]) {
    return true;
  }
  for (const field of TabFieldsTakeOff?.[pli.serviceId]) {
    //field must exist, it must be a valid number (not infinity)
    if (checkExemptedFields(pli.serviceId, pli, field)) continue;

    if (
      pli[field] === undefined ||
      pli[field] === Infinity ||
      isNaN(parseFloat(pli[field]))
    )
      return false;
  }
  return true;
}
const checkExemptedFields = (serviceId, pli, field) => {
  console.log("checkExemptedFields", serviceId, pli, field);
  switch (serviceId?.toString()) {
    case "13":
      if (pli?.type === "Shoring Post") {
        if (field === "width" || field === "length") return true;
      }
    default:
      return false;
  }
};
