import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";

export const safety = (updatedDynamicStates, IdHandler, programFields) => {
  const getBorough = (address) => {
    if (address?.split(",")?.length > 1) {
      return address?.split(",")?.[1]?.trim();
    } else {
      return address;
    }
  };
  const safetyStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  const projectLabels = uniq(
    (updatedDynamicStates?.[IdHandler] || [])?.map((e) =>
      getBorough(e?.safetyApplicationObject?.projectName)
    )
  );

  const accountLabels = uniq(
    (updatedDynamicStates?.[IdHandler] || [])?.map((e) =>
      getBorough(e?.safetyApplicationObject?.accountName)
    )
  );

  const scheduleLabels = uniq(
    (updatedDynamicStates?.[IdHandler] || [])?.map((e) =>
      getBorough(e?.safetyApplicationObject?.scheduleAddress)
    )
  );

  return {
    safetyApplicationStatus: () => ({
      title: "Safety Application Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Safety",
        field: "safetyApplicationStatus",
        id: IdHandler,
        page: "safety",
        labels: safetyStatus?.map(({ statusName }) => statusName),
        datasets: [
          {
            label: "Safety",
            data: safetyStatus?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) => e?.safetyApplicationStatus === status?.statusName
                )?.length
            ),
            backgroundColor: programFields
              ?.find((e) => e?.fieldName === "Status colors")
              ?.fieldOptions?.Safety?.map((e) => e.statusColor),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }),

    safetyName: () => ({
      title: "Safety Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Safety",
        field: "safetyName",
        id: IdHandler,
        page: "safety",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((e) => e?.safetyName)
        ),
        datasets: [
          {
            label: "Safety",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (e) => e?.safetyName
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) => e?.safetyName === status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(() =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    accountName: () => ({
      title: "Account Name for safety",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Safety",
        field: "accountName",
        id: IdHandler,
        page: "accounts",
        labels: accountLabels,
        datasets: [
          {
            label: "Safety",
            data: accountLabels?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) => e?.safetyApplicationObject?.accountName === status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(() =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectName: () => ({
      title: "Project Name for safety",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Safety",
        field: "projectName",
        id: IdHandler,
        page: "projects",
        labels: projectLabels,
        datasets: [
          {
            label: "Safety",
            data: projectLabels?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) =>
                    getBorough(e?.safetyApplicationObject?.projectName) ===
                    status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(() =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    safetyApplicationCategory: () => ({
      title: "Safety Category",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Safety",
        field: "safetyApplicationCategory",
        id: IdHandler,
        page: "safety",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (e) => e?.safetyApplicationCategory
          )
        ),
        datasets: [
          {
            label: "Safety",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (e) => e?.safetyApplicationCategory
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) => e?.safetyApplicationCategory === status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(() =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    scheduleAddress: () => ({
      title: "Safety Schedule Address",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Safety",
        field: "scheduleAddress",
        id: IdHandler,
        page: "scheduling",
        labels: scheduleLabels,
        datasets: [
          {
            label: "Safety",
            data: scheduleLabels?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) =>
                    getBorough(e?.safetyApplicationObject?.scheduleAddress) ===
                    status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(() =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
