import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const footerDataGenerator = ({ service }) => {
  //extract all the amount that we have applied in this application and save in an array and find the sum of all the amount that we just extracted together
  const totalThisPeriod = service?.amounts
    ?.map((a) => a?.thisPeriod)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);
  //extract all the amount that we have applied in previous application and save in an array and find the sum of all the amount that we just extracted together
  const totalFromPrevious = service?.amounts
    ?.map((a) => a?.fromPrevious)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);
  //extract all the amount that we have applied in previous application and in this one and save in an array and find the sum of all the amount that we just extracted together
  const totalCompleated = service?.amounts
    ?.map((a) => a?.totalCompleated)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);
  //extract all the amount that we can collect from events and save in an array and find the sum of all the amount that we just extracted together
  const collectableAmount = service?.amounts
    ?.map((a) => a?.collectable_amount)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);
  const totalAll = service?.amounts
    ?.map((a) => a?.amount)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);
  const totalAmount = service?.amounts
    ?.map((a) => a?.amount || 0)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);
  const applicableRetainage = service?.amounts
    ?.map((a) => {
      if (a?.thisPeriod !== 0 && !isNaN(a?.thisPeriod)) {
        return a?.retainage;
      } else return 0;
    })
    .reduce((a, b) => a + b, 0);

  const paymentDue = service?.amounts
    ?.map((a) => a?.paymentDue || 0)
    .reduce((a, b) => a + b, 0);
  const retainageBalance = service?.amounts
    ?.map((a) => a?.accRetainageBalance || 0)
    .reduce((a, b) => a + b, 0);

  return {
    totalThisPeriod,
    totalFromPrevious,
    totalCompleated,
    collectableAmount,
    totalAll,
    thisPeriod: totalThisPeriod,
    thisRetainage: applicableRetainage,
    paymentDue,
    retainageBalance,
  };
};
