import { quickStatistics_Schedule_and_Project } from "../../../../Scheduling/helpers/quickStatistics";

export const scheduling = ({ tables, programFields }) => {
  return {
    typeOfWork: () => ({
      statusData: tables?.Scheduling?.map((a) => {
        return {
          label: a?.scheduleAddress,
          value: a?.typeOfWork,
          color: "#ECDE7D",
        };
      }),
    }), //typeofWork

    projectExecutive: () => ({
      statusData: tables?.Scheduling?.map((a) => {
        return {
          label: a?.scheduleAddress,
          value: a?.projectExecutive,
          color: "#ECDE7D",
          date: a?.createdAt,
          id: a?.scheduleId,
        };
      }),
    }),

    scheduleAddress: () => ({
      statusData: tables?.Scheduling?.map((status) => {
        return {
          label: status?.scheduleAddress,
          value: status?.scheduleStatus,
          color:
            programFields
              ?.find((e) => e?.fieldName === "Statuses Of Categories")
              ?.fieldOptions?.Scheduling?.find(
                (el) =>
                  el?.statusName?.toLowerCase() ===
                  status?.scheduleStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
          date: status?.createdAt,
          id: status?.scheduleId,
        };
      }),
    }),

    scheduleTotalProgressPercentage: () => ({
      statusData: tables?.Scheduling?.map((status) => {
        return {
          label: status?.scheduleAddress,
          value:
            quickStatistics_Schedule_and_Project({
              project: [],
              toBeScheduled: status?.toBeScheduled,
              schedules: [],
              typeOfWork: status?.typeOfWork,
            })?.scheduleTotalProgressPercentage + "%",
          color: "#ECDE7D",
          date: status?.createdAt,
          id: status?.scheduleId,
        };
      }),
    }),
  }; //schaduling
};
