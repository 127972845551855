import { forceToNumber } from "src/components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const getRowData = ({ service, serviceOption }) => {
  console.log("getRowData", { service, serviceOption });
  const rowData = [];
  if (!!service?.amounts) {
    // let differenceReference = forceToNumber(serviceOption?.maxPrice);
    service?.amounts?.forEach((amount, index) => {
      let toPush = {};
      toPush.id = index;
      toPush.name = amount?.name || "";
      toPush.elevationId = serviceOption?.elevationId || "";
      toPush.elevation = serviceOption?.elevationLabel || "";
      toPush.rate = amount?.rate || 0;
      toPush.amount = calculateProportionalAmount({
        amount: serviceOption?.maxPrice || 0,
        rate: amount?.rate || 0,
      });
      toPush.retainagePercentage = forceToNumber(service?.retainage);
      toPush.retainage = calculateProportionalAmount({
        amount: toPush?.amount || 0,
        rate: service?.retainage || 0,
      });
      toPush.taxRate = forceToNumber(service?.taxRate);
      // differenceReference -= toPush?.amount;
      // toPush.difference = differenceReference - toPush?.amount;
      toPush.taxAmount = forceToNumber(toPush?.amount * service?.taxRate);
      toPush.collectable_amount = toPush.amount - toPush.retainage;
      toPush.type = serviceOption?.type || "elevation";
      rowData.push(toPush);
    });
  }
  return rowData;
};

export const calculateProportionalAmount = ({ amount = 0, rate = 0 }) => {
  const calculatedAmount = (amount * rate) / 100;
  return forceToNumber(calculatedAmount);
};
export const calculatePercantage = ({ amount = 0, maxAmount }) => {
  const calculatedAmount = (amount * 100) / maxAmount;
  return forceToNumber(calculatedAmount);
};

export const afterAmountAndRateChange = (data) => {
  let amount = forceToNumber(data?.amount);
  let rate = forceToNumber(data?.rate);
  let retainagePercentage = forceToNumber(data?.retainagePercentage);
  let taxRate = forceToNumber(data?.taxRate);

  data.retainage = calculateProportionalAmount({
    amount,
    rate,
  });
  data.taxAmount = calculateProportionalAmount({
    amount,
    rate: taxRate,
  });
  data.collectable_amount = amount - data.retainage;
  return data;
};
