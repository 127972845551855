import { FleetMaintenanceType } from "../types";
import { parseInTz } from "../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { pdfBorderedSection } from "../../Fleet/view/pageView/components/SingleTruckMaintenance/modals/maintenanceModal/maintenanceModalData";
import { pdfImageSection } from "../../Safety/safetyPdf";

type MaintenanceDoc = {
  maintenance: FleetMaintenanceType;
  imageSources: string[];
  companyLogo: string;
};

function getMaintenanceDocDefinition({
  companyLogo,
  maintenance,
  imageSources,
}: MaintenanceDoc) {
  const maintenanceDate = parseInTz(maintenance.maintenanceDate).format(
    "MMM DD, YYYY"
  );
  const mechanic = maintenance.mechanicInfo.name;

  const flexImageSectionConfig = imageSources.length
    ? pdfImageSection({
        title: "Maintenance Report Pictures",
        body: imageSources.map((src, index) => ({
          name: `Image ${index + 1}`,
          src: src,
          link: undefined,
        })),
        noColor: false,
      })
    : {};

  return {
    pageOrientation: "portrait",
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return {
        text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
        alignment: "center",
        margin: 7,
      };
    },
    content: [
      {
        columns: [
          {
            image: "logo",
            width: 100,
          },
          {
            stack: [
              {
                text: `Maintenance`,
                style: ["strong", "large"],
                margin: [0, 10, 90, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
  </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      {
        text: `Vehicle: ${maintenance.fleetName || ""}`,
        style: "subheader",
        margin: [0, 5],
      },
      {
        text: `Maintenance Date: ${maintenanceDate || ""}`,
        style: "subheader",
        margin: [0, 5],
      },
      {
        text: `Mechanic: ${mechanic || ""}`,
        style: "subheader",
        margin: [0, 5],
      },
      {
        text: `Teams Configuration: ${maintenance.teamsConfiguration.flatMap(
          (el) => el.members.map((e) => e.nameOfUser)
        )}`,
        style: "subheader",
        margin: [0, 5],
      },
      [
        pdfBorderedSection({
          title: "Maintenance Items",
          body: maintenance.maintenanceReport,
          form: undefined,
          noColor: false,
        }),
      ],
      flexImageSectionConfig,
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 14 },
      innerTable: { margin: 0 },
      outterTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
    },
  };
}

export default getMaintenanceDocDefinition;
