export const getGoogleDriveId = (itemFields, title) => {
  if (title === "Fleets") {
    if (!itemFields) return undefined;

    const itemFieldsList =
      itemFields?.find((field) => field.fieldName === "Google Drive Folder Ids")
        ?.fieldOptions || {};

    const keyToFind =
      process.env.NODE_ENV === "development" ? "fleet-dev" : "fleet";

    const foundObject = itemFieldsList.find((obj) =>
      obj.hasOwnProperty(keyToFind)
    );

    return foundObject ? foundObject[keyToFind] : undefined;
  }

  return;
};
