import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const inspections = ({
  tables = {
    Inspections: [],
  },
}) => ({
  inspectionType: () => {
    const data = repeatedElementSum(
      tables?.Inspections || [],
      "inspectionType"
    );

    return {
      content: Object.entries(data)?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Inspections?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    };
  },

  inspectionStatus: () => {
    const data = repeatedElementSum(
      tables?.Inspections || [],
      "inspectionStatus"
    );

    return {
      content: Object.entries(data)?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Inspections?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    };
  },

  inspectedBy: () => {
    const data = repeatedElementSum(
      tables?.Inspections?.map((el) => el?.inspectedBy) || [],
      "nameOfUser"
    );

    return {
      content: Object.entries(data)?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Inspections?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    };
  },
});
