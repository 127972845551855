function convertToTitleCase(str) {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (s) {
    return s.toUpperCase();
  });
}

export function convertKeyToTitleCase(key) {
  if (!key) return "";
  const words = key.split(/(?=[A-Z])/);
  const formattedWords = !isNaN(Number(key))
    ? `Item (${key})`
    : words.map((word) => convertToTitleCase(word.toLowerCase())).join(" ");
  return formattedWords;
}
