import React from "react";
import {
  DepartmentIcon,
  MailIconFilled,
  PhoneIcon,
} from "../../../../../../../../../icons";

const Description = ({ title, value }) => {
  const icons = {
    Email: <MailIconFilled />,
    Phone: <PhoneIcon />,
    Department: <DepartmentIcon />,
  };
  return (
    <div className="descriptionRow">
      <div className="logo">{icons[title]}</div>
      <div className="info">
        <span className="content">{value}</span>
      </div>
    </div>
  );
};

export default Description;
