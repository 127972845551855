import React from "react";
import { Modal } from "antd";

import ServiceDescription from "./ServiceDescription";
import { formatCurrency } from "../../../../../../utils/formatCurrency";
import { groupServicesByEstimationId } from "../utils/groupServicesByEstimationId";

import "./ServicesModal.scss";

const colors = ["#30973A", "#EEB522", "#4662F2"];

const calculateTotal = (services) =>
  services.reduce((acc, service) => acc + service.total, 0);

const ServicesModal = ({ isOpen, setIsOpen, services, title }) => {
  const total = calculateTotal(services);

  const estimations = groupServicesByEstimationId(services);

  return (
    <Modal
      open={isOpen}
      wrapClassName="service-modal-container"
      onCancel={() => setIsOpen(false)}
      destroyOnClose
      footer={null}
      title={`${title} Services`}
    >
      <div className="service-modal-body">
        {estimations.map((estimation) => (
          <React.Fragment key={estimation.estimationId}>
            <h3 className="estimation-name">{estimation.name}</h3>
            <div className="estimation-services-body">
              {estimation.services?.map((item, key) => (
                <ServiceDescription
                  key={key}
                  service={item.label}
                  elevations={item.elevations}
                  isTaxable={item.isTaxable}
                  showDivider={key < estimation.services.length - 1}
                  color={colors[key % colors.length]}
                  total={item.total}
                />
              ))}
            </div>
            <div className="service-sub-total">
              Sub Total: {formatCurrency(calculateTotal(estimation.services))}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="service-modal-footer">
        Grand Total: {formatCurrency(total)}
      </div>
    </Modal>
  );
};

export default ServicesModal;
