import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { message, Modal } from "antd";
import { API } from "aws-amplify";

import { logColumnNames } from "../../data";
import { DifferenceModal } from "../../components";
import { MondayButton } from "src/components/commonComponents";
import { DocumentationLogsModal } from "src/components/SidebarPages/Documentation/View/components/DocumentationModal/components";
import { WarningTriangle, LogsIcon } from "../../src";

import "./DriverAssign.scss";
import { AvatarImage } from "../../../../commonComponents";
import { fetchAllData } from "../../../../../utils";

function DriverAssign(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);

  const [assignedDriver, setAssignedDriver] = useState(null);
  const [assignedUser, setAssignedUser] = useState({});
  const [logsData, setLogsData] = useState();
  const [logsVisible, setLogsVisible] = useState(false);
  const [logsParams, setLogsParams] = useState();

  const { assignedDriver: defaultAssignedDriver, fleetId } = props.params || "";

  useEffect(() => {
    if (defaultAssignedDriver) {
      API.get("drivers", `/drivers/${defaultAssignedDriver}`)
        .then((res) => {
          setAssignedDriver(res);
          let tmpUser = undefined;
          if (res?.driverSub) {
            tmpUser = userConfiguration?.allUsers?.Items?.find(
              ({ cognitoUserId }) => cognitoUserId === res?.driverSub
            );
          }

          if (!tmpUser) {
            tmpUser = {
              googleDriveFileId: undefined,
              isSuspended: res?.status === "Terminated",
              nameOfUser: res?.driverName,
              groupName: "Driver",
            };
          }

          setAssignedUser(tmpUser);
        })
        .catch((err) => {
          console.log("Error getting assigned driver: ", err);
        });
    }
  }, [defaultAssignedDriver]);

  async function getLogs() {
    if (logsData) {
      setLogsVisible(true);
      return;
    }

    if (!fleetId) {
      return;
    }

    message.loading({
      content: "Getting Logs",
      key: "driverAssignLogs",
      duration: 0,
    });

    await fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: fleetId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((r) => {
        message.destroy("driverAssignLogs");
        setLogsData(r.filter(({ label }) => label === "Driver Assign"));
        setTimeout(() => {
          setLogsVisible(true);
        }, 0);
      })
      .catch((err) => {
        message.error({
          content:
            "Something went wrong while trying to get the driver assign logs",
          key: "driverAssignLogs",
        });
        console.log("Error getting logs: ", err);
      });
  }

  if (!assignedDriver) {
    return (
      <div
        className={`driver-link-no-driver ${
          isDarkMode ? "driver-link-dark" : ""
        }`}
      >
        <WarningTriangle className="no-driver-warning-icon" />
        <h2>There is no assigned driver for this vehicle!</h2>
        <h3>Edit this card in order to add a driver</h3>
      </div>
    );
  }

  function getBaseLogBody(params) {
    return {
      Card: "DriverAssign",
      align: "left",
      cardParams: params,
      editable: true,
      hidden: false,
      id: "driverAssign",
      index: 2,
      isFilter: false,
      title: "Driver Assign",
    };
  }

  function onLogClick({ data }) {
    setLogsParams({
      previus: getBaseLogBody(data?.["previousData"]),
      next: getBaseLogBody(data?.["currentData"]),
    });
  }

  return (
    <Fragment>
      <div className="membersContent-container">
        <div className="content-membersContent">
          <div className="member-content">
            {assignedUser?.googleDriveFileId ? (
              <div className="ImageContainer" onClick={() => {}}>
                <AvatarImage
                  {...{
                    nameOfUser: assignedUser?.nameOfUser,
                    googleDriveFileId: assignedUser?.googleDriveFileId,
                  }}
                />
              </div>
            ) : (
              <div
                className="InitialsContainer"
                style={{
                  backgroundColor: assignedUser?.isSuspended
                    ? "#A9A9A9"
                    : assignedUser?.nameOfUser === userConfiguration.nameOfUser
                    ? preferences?.preferences?.profileColor || "#4895ef"
                    : "#F8F8FA",
                }}
                onClick={() => {}}
              >
                <span
                  className="initialsStyle"
                  style={{
                    color:
                      assignedUser?.nameOfUser === userConfiguration.nameOfUser
                        ? "#fff"
                        : "#323338",
                  }}
                >
                  {assignedUser?.nameOfUser?.charAt(0)}
                </span>
              </div>
            )}
            <div className="memberData">
              <div
                className={`nameContainer ${
                  assignedUser?.isSuspended && "suspendedName"
                }`}
                onClick={() => {}}
              >
                {assignedUser?.nameOfUser}
              </div>
              <div
                className={`groupName ${
                  assignedUser?.isSuspended && "suspendedGroupName"
                }`}
              >
                {assignedUser?.groupName}
              </div>
            </div>
          </div>
        </div>
        <div />
        <div className="content-membersContent">
          <MondayButton
            {...{
              Icon: <LogsIcon />,
              className: "mondayButtonBlue",
              onClick: getLogs,
            }}
          >
            Driver assign logs
          </MondayButton>
        </div>
      </div>
      {logsVisible && (
        <DocumentationLogsModal
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            docLogs: logsData,
            logsColumnDefs: logColumnNames({ onLogClick }),
            viewLogs: [],
            title: "Assigned Driver Logs",
            postLogs() {},
          }}
        />
      )}
      {logsParams && (
        <Modal
          {...{
            open: Boolean(logsParams),
            title: null,
            footer: null,
            centered: true,
            closable: false,
            className: "card-view-logs-modal",
          }}
        >
          <DifferenceModal
            {...{
              params: logsParams,
              setDifferenceLog: setLogsParams,
            }}
          />
        </Modal>
      )}
    </Fragment>
  );
}

export default DriverAssign;
