import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotification } from "../../../../../utils";

/**
 * Sends safety create notifications.
 *
 * @param {string} table - The table name.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Object} res - The response object.
 * @param {string} createAction - The create action.
 * @param {string} category - The category.
 * @param {Object} tmpBody - The temporary body object.
 * @returns {Promise} A promise that resolves with the result of the broadcast notification.
 */
async function sendSafetyCreateNotifs({
  table = "",
  userConfiguration,
  res,
  createAction = "",
  category,
  tmpBody = {},
}) {
  broadcastNotification(
    table === "safety" ? "42" : "43",
    table === "safety" ? "onSafetyAssignation" : "onIncidentAssignation",
    [
      {
        common: userConfiguration?.nameOfUser,
        category,
      },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: userConfiguration?.cognitoUserId,
        cognitos:
          res.safetyApplicationObject?.assignedTo
            ?.map(({ cognitoUserId }) => cognitoUserId)
            ?.filter((el) => el !== userConfiguration?.cognitoUserId) || [],
      },
    ],
    res.safetyApplicationId
  );
  return await broadcastNotification(
    table === "safety" ? "42" : "43",
    createAction,
    [
      { common: userConfiguration?.nameOfUser },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: userConfiguration?.cognitoUserId,
        cognitos: getCognitosForNotification(
          userConfiguration,
          tmpBody?.teamsConfiguration
        ),
      },
    ],
    res?.safetyApplicationId
  );
}

export default sendSafetyCreateNotifs;
