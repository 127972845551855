import {
  longLineSteper,
  longBarChart,
  pieChart,
  barChart,
  straightProgressBar,
  circleProgressBar,
  statusProgress,
  lineChartFull,
} from "./index";
{
  /*this function populates the data that comes from the db ,it have different functions for different types of cards*/
}
export const widgetPopulationFunction = ({
  tables,
  programFields,
  circleProgressPicker,
  userConfiguration,
}) => {
  return {
    Pie: () => pieChart({ tables, programFields, userConfiguration }),
    StreightProgressBar: () =>
      straightProgressBar({ tables, programFields, userConfiguration }),
    BarChart: () => barChart({ tables, programFields, userConfiguration }),
    LongLineSteper: () => longLineSteper({ tables, programFields }),
    BarChartFull: () => longBarChart({ tables }),
    Progress: () => statusProgress({ tables, programFields }),
    CircleProgresBar: () => circleProgressBar({ tables, circleProgressPicker }),
    LineChartFull: () => lineChartFull({ tables, programFields }),
  };
};
