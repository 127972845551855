import { Button } from "antd";
import { NormalSizedModal } from "../../SidebarPages/Fleet/components";
import "./ConfirmationModal.scss";
import { useSelector } from "react-redux";

const CONFIRMATION_QUESTION = "Are you sure you want to cancel?";
const TITLE = "Cancel";
const CONFIRM_BUTTON_TEXT = "Yes";
const CANCEL_BUTTON_TEXT = "No";

const ConfirmationModal = ({
  visible,
  setVisible = () => {},
  onConfirm = () => {},
  onCancel = () => {},
  callBackFunc = () => {},
  text = CONFIRMATION_QUESTION,
  title = TITLE,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  cancelButtonText = CANCEL_BUTTON_TEXT,
  zIndex = 1500,
  children,
  setHasChanges,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <NormalSizedModal
      visible={visible}
      destroyOnClose={true}
      setVisible={setVisible}
      title={title}
      className="confirmationModalContainer"
      zIndex={zIndex}
      centered
      setHasChanges={setHasChanges}
    >
      <div>
        <div
          style={{ color: isDarkMode ? "#fff" : "#000" }}
          className="question"
        >
          {text}
        </div>
        {children}
        <div className="buttonContainer">
          <Button
            type="primary"
            danger
            onClick={() => {
              onCancel();
              setVisible(false);
            }}
          >
            {cancelButtonText}
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              onConfirm(e)?.then(callBackFunc);
              setVisible(false);
            }}
            data-testid="confirm-button"
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </NormalSizedModal>
  );
};

export default ConfirmationModal;
