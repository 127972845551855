import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DepartmentsInfoCard from "./DepartmentsInfoCard/DepartmentsInfoCard";
import NewUserList from "../../../../Management/EditUser/components/UserList/NewUserList";

const RoleInfo = ({ departmentGroups, allUsers }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { departmentName, role } = useParams();
  const currentRole = role ? role : "Engineer/Architect";

  return (
    <>
      <DepartmentsInfoCard
        {...{
          isDarkMode,
          departmentName,
          departmentGroups,
          allUsers,
          currentRole,
        }}
      />
      <NewUserList
        {...{ isDarkMode, departmentName, role, fromRolesAccess: true }}
      />
    </>
  );
};

export default RoleInfo;
