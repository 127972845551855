import { getAddressComponent } from "../../../../SidebarPages/Fleet/utils/addressParseHelpers";
import { aptNumberChangeHandler } from "../../../../SidebarPages/Fleet/utils/addressParseHelpers";

export const BILLING_ADDRESS = "Billing Address";
export const SHIPPING_ADDRESS = "Shipping Address";

export const billingAddFormNames = [
  "billingAddState",
  "billingAddCity",
  "billingAddStreet",
  "billingAddPostalCode",
];

export const shippingAddFormNames = [
  "shippingAddState",
  "shippingAddCity",
  "shippingAddStreet",
  "shippingAddPostalCode",
];

export const fieldsJSON = ({
  form,
  isBillingSameAsShipping,
  setIsBillingSameAsShipping,
  setBillingAddress,
  setShippingAddress,
  shippingAddress,
  billingAddress,
  onlyOneAddress,
  proppedBillingAddressAptNr,
  proppedShippingAddressAptNr,
  disabled,
  shippingAptChanger,
  billingAptChanger,
  // setItemSelected,
  setBillingSelected,
  setShippingSelected,
  billingClearHandler,
  shippingClearHandler,
  contactModal,
}) => [
  {
    category: BILLING_ADDRESS,
    fields: [
      // {
      //   label: "Same as billing address",
      //   formItemName: "isBillingSameAsShipping",
      //   type: "checkbox",
      //   disabled,
      //   onChange: (val) => setIsBillingSameAsShipping(val.target.checked),
      //   className: "addressCheckbox",
      // },
      {
        form,
        label: "Billing Address",
        formItemName: "primaryAddress",
        placeholder: "Click and Search...",
        disabled,
        type: "placesautocomplete",
        style: { paddingRight: 40, minWidth: 305 },
        onSelect: (value) => {
          setBillingAddress(value);
          if (!!isBillingSameAsShipping) {
            setShippingAddress(value);
          }
          setBillingSelected();

          if (!!isBillingSameAsShipping) {
            setShippingAddress(value);
            form.setFieldValue("secondaryAddress", value);
            setShippingSelected();
          }
        },
        onChange: (value) => {
          if (!value) {
            billingClearHandler();

            if (!!isBillingSameAsShipping) {
              shippingClearHandler();
            }
          }
        },
        onClear() {
          billingClearHandler();
        },
        onBlur: () => {
          //prevents the input from resetting to the previous state on blur
          if (!!form.getFieldValue("primaryAddress") && !billingAddress) {
            setBillingAddress(form.getFieldValue("primaryAddress"));
            if (!!isBillingSameAsShipping) {
              setShippingAddress(
                form.setFieldValue("secondaryAddress", billingAddress)
              );
            }
          } else {
            billingAddress !== "" &&
              form.setFieldValue("primaryAddress", billingAddress);
            if (!!isBillingSameAsShipping) {
              form.setFieldValue("secondaryAddress", billingAddress);
            }
          }
        },
      },
      {
        label: "Apartment #",
        placeholder: "Apartment #",
        formItemName: "aptNumber",
        disabled: proppedBillingAddressAptNr,
        onChange: (val) => {
          billingAptChanger(val.target.value);
          form.setFieldValue("aptNumber", val.target.value);

          if (!!isBillingSameAsShipping) {
            shippingAptChanger(val.target.value);
            form.setFieldValue("shippingAddApartmentNr", val.target.value);
          }
        },
        style: { minWidth: 305 },
      },
      {
        label: "Street",
        placeholder: "Street",
        formItemName: "billingAddStreet",
        disabled: true,
        style: { minWidth: 305 },
      },
      {
        label: "City",
        placeholder: "City",
        formItemName: "billingAddCity",
        disabled: true,
        style: { minWidth: 305 },
      },
      {
        label: "State",
        placeholder: "State",
        formItemName: "billingAddState",
        disabled: true,
        style: { minWidth: 168, width: 168 },
      },
      {
        label: "Postal Code",
        placeholder: "Postal Code",
        formItemName: "billingAddPostalCode",
        disabled: true,
        style: { minWidth: 122, width: 122 },
      },
    ],
  },
  {
    category: SHIPPING_ADDRESS,
    fields: [
      {
        form,
        label: "Shipping Address",
        formItemName: "secondaryAddress",
        disabled: !!isBillingSameAsShipping || disabled,
        placeholder: "Click and Search...",
        type: "placesautocomplete",
        style: !contactModal
          ? { paddingRight: 40, minWidth: 305 }
          : { paddingRight: 40, width: "100%" },
        // className: "secondaryAddress",
        onSelect: (value) => {
          setShippingAddress(value);
          setShippingSelected();
        },
        onChange: (value) => {
          if (!value) {
            shippingClearHandler();
          }
        },
        onClear() {
          // this.onChange();
          shippingClearHandler();
        },
        onBlur: () => {
          if (!!form.getFieldValue("secondaryAddress") && !shippingAddress) {
            setShippingAddress(form.getFieldValue("secondaryAddress"));
          } else {
            shippingAddress !== "" &&
              form.setFieldValue("secondaryAddress", shippingAddress);
          }
        },
      },
      {
        label: "Apartment #",
        placeholder: "Apartment #",
        formItemName: "shippingAddApartmentNr",
        disabled: disabled || !!isBillingSameAsShipping,
        onChange(val) {
          shippingAptChanger(val.target.value);
          form.setFieldValue("shippingAddApartmentNr", val.target.value);
        },
        style: !contactModal
          ? { minWidth: 305, width: 305 }
          : { minWidth: 305, width: "100%" },
      },

      {
        label: "Street",
        placeholder: "Street",
        formItemName: "shippingAddStreet",
        disabled: true,
        style: !contactModal
          ? { minWidth: 305, width: 305 }
          : { minWidth: 305, width: "100%" },
      },
      {
        label: "City",
        placeholder: "City",
        formItemName: "shippingAddCity",
        disabled: true,
        style: !contactModal
          ? { minWidth: 305, width: 305 }
          : { minWidth: 168, width: "100%" },
      },
      {
        label: "State",
        placeholder: "State",
        formItemName: "shippingAddState",
        disabled: true,
        style: !contactModal
          ? { minWidth: 168, width: 168 }
          : { minWidth: 168, width: "100%" },
      },
      {
        label: "Postal Code",
        placeholder: "Postal Code",
        formItemName: "shippingAddPostalCode",
        disabled: true,
        style: !contactModal
          ? { minWidth: 122, width: 122 }
          : { mindWidth: 122, width: "100%" },
      },
    ],
  },
];
