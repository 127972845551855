import {
  FILTER_VALUES,
  INITIAL_FILTER_VALUES,
} from "../../DynamicView/components/FilterComponents/filtersData";
import dayjs from "dayjs";
import { toCamelCase } from "../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import { keyToTable } from "../constants";

/**
 * @param {Array || String || Timestamp || Moment} filterRange value to be filtered that needs to be converted to timestamp
 * @returns @param {Array  || Timestamp } toReturn value to timestamp
 */
function filterValue(filterRange) {
  let toReturn = null;
  if (Array.isArray(filterRange)) {
    if (filterRange?.every((el) => Array.isArray(el))) {
      toReturn = filterRange?.map((el) => {
        if (el?.every((el) => dayjs(el).isValid())) {
          return [dayjs(el[0]).valueOf(), dayjs(el[1]).valueOf()];
        }
      });
    } else if (filterRange?.every((el) => !!dayjs(el).isValid())) {
      toReturn = [
        dayjs(filterRange[0]).valueOf(),
        dayjs(filterRange[1]).valueOf(),
      ];
    }
  } else if (dayjs(filterRange).isValid()) {
    toReturn = [
      dayjs(filterRange)?.startOf("day")?.valueOf(),
      dayjs(filterRange)?.endOf("day")?.valueOf(),
    ];
  } else if (typeof filterRange === "string" && !dayjs(filterRange).isValid()) {
    toReturn = FILTER_VALUES()?.[filterRange] || [];
  }

  return toReturn;
}

/**
 *
 * @param {Array} newRange new range to be compared to old range
 * @param {Array} oldRange old range to be compared to new range
 * @returns the overlapped range between the two ranges or a new range if there is no overlap
 */
const compareNewToMultiple = (newRange, oldRange) => {
  let tmp;
  if (!!tmp) {
    let compared = compareFilterRange(newRange, tmp);
    tmp = compared;
  } else {
    tmp = compareFilterRange(newRange, oldRange);
  }

  return tmp;
};

/**
 * @param {Object} filterStates object containing the filter states for the dashboard chart
 * @param {Object} sessionData  object we are updating
 * @param {String} apiTable table name of the chart we are updating
 * @param {String} key id of the chart
 */
export function getFilterFromPref(filterStates, sessionData, apiTable, key) {
  if (
    // filterStates?.filterByTime === "Pick Range" ||
    !!filterStates?.filterByTime
  ) {
    if (!!filterStates?.filterByDay) {
      return {
        ...sessionData,
        [apiTable]: {
          ...sessionData?.[apiTable],
          [key]: compareFilterRange(filterStates?.filterByDay, "initial"),
        },
      };
    } else if (!!filterStates?.filterByRange) {
      return {
        ...sessionData,
        [apiTable]: {
          ...sessionData?.[apiTable],
          [key]: compareFilterRange(filterStates?.filterByRange, "initial"),
        },
      };
    } else {
      return {
        ...sessionData,
        [apiTable]: {
          ...sessionData?.[apiTable],
          [key]:
            compareFilterRange(
              toCamelCase(filterStates?.filterByTime),
              "initial"
            ) || "initial",
        },
      };
    }
  } else {
    return {
      ...sessionData,
      [apiTable]: {
        ...sessionData?.[apiTable],
        [key]: toCamelCase(filterStates?.filterByTime) || "initial",
      },
    };
  }
}

/**
 *
 * @param {Array} newRange new range to be compared to old range
 * @param {Array} oldRange old range to be compared to new range
 * @returns if the new range is within the old range, return false, else return the new range
 */
export const compareFilterRange = (newRange, oldRange) => {
  if (!Array.isArray(newRange) && dayjs(newRange).isValid()) {
    const [oldStart, oldEnd] = filterValue(oldRange);
    const [newStart, newEnd] = filterValue(newRange);

    if (newStart >= oldStart && newEnd <= oldEnd) {
      return false;
    } else {
      return [newStart, newEnd];
    }
  }
  //if the old range is an array of arrays, compare the new range to each array in the old range

  // if (Array.isArray(oldRange) && oldRange?.every((el) => Array.isArray(el))) {
  //   let tmp = [];
  //   oldRange?.forEach((el) => {
  //     tmp.push(compareNewToMultiple(filterValue(newRange), filterValue(el)));
  //   });

  //   if (tmp.every((el) => el === false)) {
  //     return false;
  //   }
  //   return restructureFilterRanges(tmp?.filter((el) => el !== false));
  // }
  else {
    const [newStart, newEnd] = filterValue(newRange);
    const [oldStart, oldEnd] = filterValue(oldRange);

    if (newStart >= oldEnd && newEnd <= oldStart) {
      // No overlap, no need for update
      return false;
    } else if (newStart < oldStart && newEnd <= oldEnd) {
      // Partial overlap, adjust end

      return [newStart, newEnd];
    } else if (newStart >= oldStart && newEnd > oldEnd) {
      // Partial overlap, adjust start
      return [newStart, newEnd];
    } else if (newStart < oldStart && newEnd > oldEnd) {
      return [newStart, newEnd];
    }

    // Full overlap, no need for update
    return false;
  }
};

/**
 * @param {Array} filterRanges array of arrays to be restructured
 * @returns {Array} sorted array of arrays with overlapping ranges combined into one range
 */
export function restructureFilterRanges(filterRanges) {
  const filterRangeValues = filterRanges?.map((el) => filterValue(el));

  let sorted = filterRangeValues
    ?.filter((el) => Array.isArray(el))
    ?.sort((a, b) => a[1] - b[1] || a[0] - b[0]);

  if (sorted?.length > 1) {
    for (let idx = sorted.length - 1; idx > 0; idx--) {
      if (sorted[idx - 1]) {
        let [min, max] = sorted[idx];

        let [previousMin, previousMax] = sorted[idx - 1];

        if (min <= previousMax && min >= previousMin) {
          if (max >= previousMax) {
            sorted[idx - 1][1] = max;
            sorted.splice(idx, 1);
          }
        }
      }
    }
  }

  return sorted;
}

/**
 * @param {Array} filterRange filter range to compare
 * @param {String} string string representing a filter value that is saved in FILTER_VALUES
 * @param {Array} tmp variable to hold the compared range
 * @returns
 */
function compareRangeToStrings(filterRange, string, tmp) {
  const stringRange = filterValue(string);
  if (!stringRange?.length) return filterRange;

  if (tmp?.length) {
    let compared = compareFilterRange(tmp, stringRange);
    tmp = structuredClone(compared);
  } else {
    tmp = compareFilterRange(stringRange, filterValue(filterRange));
  }

  return tmp;
}

export function removeCardFromSession(id, table) {
  const dashboardSession = getDashboardSession();

  const apiTable = keyToTable[table] || toCamelCase(table);
  const newSession = structuredClone(dashboardSession);

  delete newSession?.[apiTable]?.[id];

  setAllTablesToStorage(newSession);
}

/**
 * @param {Array} tableData the filter data that is currently saved in session storage
 * @param {String} type type of the new filter we are adding string, range, or day
 * @param {Array || String || Timestamp || Moment} filterRange the filter value to be added
 * @returns checks if the new filter value is already in the table data and if it is,
 * returns false, else returns the new filter value to be added to session storage and used
 * for api call
 */
const findFilterInData = (tableData = [], type, filterRange) => {
  if (tableData) {
    switch (type) {
      case "range":
        return tableData?.map((oldRange) =>
          compareFilterRange(filterRange, oldRange)
        );
      case "day":
        return tableData?.map((oldRange) =>
          compareFilterRange(filterRange, oldRange)
        );
      case "string":
        if (tableData === filterRange) {
          return false;
        } else if (tableData === "initial") {
          if (INITIAL_FILTER_VALUES?.[filterRange]) return false;
          return tableData?.map((oldRange) =>
            compareFilterRange(filterRange, oldRange)
          );
        }

        return tableData?.map((oldRange) =>
          compareFilterRange(filterRange, oldRange)
        );

      default:
        return false;
    }
  }
};

/**
 * @param {String} table  the table we are filtering
 * @param {Object} tmpFilterStates object that is used to filter in dashboard
 * @returns the filter value to be used for api call or false in case the filter value is already in session storage
 */
export function validateFilterRange(table, tmpFilterStates) {
  const dashboardSession = getDashboardSession();
  const tableData = Object.values(dashboardSession?.[table]) || [];
  const {
    filterByTime = null,
    filterByDay = null,
    filterByRange = null,
  } = tmpFilterStates;
  let sessionFilterData = null;

  if (!filterByTime && !filterByDay && !filterByRange) {
    return null;
  }
  if (
    filterByTime === "Pick Range" &&
    !!filterByDay &&
    dayjs(filterByDay).isValid()
  ) {
    if (!tableData) return filterValue(filterByDay);
    if (tableData?.some((el) => el === "initial")) {
      sessionFilterData = compareFilterRange(filterByDay, "initial");
    } else {
      sessionFilterData = findFilterInData(tableData, "day", filterByDay);
    }
  } else if (
    filterByTime === "Pick Range" &&
    !!Array.isArray(filterByRange) &&
    filterByRange?.every((el) => dayjs(el).isValid())
  ) {
    if (!tableData) return filterValue(filterByRange);
    if (tableData === "initial") {
      sessionFilterData = compareFilterRange(filterByRange, "initial");
    } else {
      sessionFilterData = findFilterInData(tableData, "range", filterByRange);
    }
  } else if (!!filterByTime) {
    if (!tableData) return filterValue(toCamelCase(filterByTime));

    sessionFilterData = findFilterInData(
      tableData,
      "string",
      toCamelCase(filterByTime)
    );
  }

  return sessionFilterData;
}

/**
 * @param {Array || String || Timestamp || Moment} filterRange filter value to be added to session storage
 * @param {String} table the table we are filtering
 * @returns sets the filter value to session storage
 */
export function setTablesFilterToStorage(filterRange, table, chartId) {
  const dashboardSession = getDashboardSession();
  let validatedRange = structuredClone(filterValue(filterRange));

  if (validatedRange[1] > dayjs().endOf("day")?.valueOf()) {
    validatedRange[1] = dayjs().endOf("day")?.valueOf();
  }

  const toSet = compareFilterRange(
    validatedRange,
    dashboardSession?.[table]?.chartId || "initial"
  );

  if (!!toSet) {
    sessionStorage.setItem(
      "dashboardSession",
      JSON.stringify({
        ...dashboardSession,
        [table]: { ...dashboardSession?.[table], [chartId]: toSet },
      })
    );

    return toSet;
  }
}

/**
 * @param {Array || String || Timestamp || Moment} filterRange  filter value to be added to session storage
 * @param {Object} tablesData Object that holds the table data for each table in dashboard
 * @returns sets the filter value to session storage for all tables
 */
export function setAllTablesToStorage(tablesData, filterRange = null) {
  if (!filterRange) {
    sessionStorage.setItem("dashboardSession", JSON.stringify(tablesData));
    return;
  }

  let newRange = null;

  if (Array.isArray(filterRange)) {
    if (filterRange?.every((el) => dayjs(el).isValid())) {
      newRange = [
        dayjs(filterRange[0]).valueOf(),
        dayjs(filterRange[1]).valueOf(),
      ];
    } else return;
  } else if (dayjs(filterRange).isValid()) {
    newRange = dayjs(filterRange)?.valueOf();
  } else if (
    typeof filterRange === "string" &&
    FILTER_VALUES?.[toCamelCase(filterRange)]
  ) {
    newRange = toCamelCase(filterRange);
  } else {
    return;
  }

  const rangesToSet = tablesData.reduce((acc, curr) => {
    return {
      ...acc,
      [curr?.table]: newRange,
    };
  }, {});

  sessionStorage.setItem("dashboardSession", JSON.stringify(rangesToSet));
}

export const getDashboardSession = () => {
  if (
    sessionStorage.getItem("dashboardSession") === null ||
    sessionStorage.getItem("dashboardSession") === "undefined" ||
    !sessionStorage.getItem("dashboardSession") ||
    sessionStorage.getItem("dashboardSession") === "invalidJSON"
  ) {
    return null;
  } else if (!!sessionStorage.getItem("dashboardSession")) {
    return JSON.parse(sessionStorage.getItem("dashboardSession"));
  }
};
