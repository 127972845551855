import { useEffect, useState } from "react";
import { DayStatus, DisplayColors } from "./DayStatuses";
import { message, Modal, Button, Input } from "antd";
import ScheduleDay from "../../models/ScheduleDay";
import _, { set } from "lodash";
import dayjs from "dayjs";
import { extractContent } from "../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";
import ScheduleDayNote from "../../models/ScheduleDayNote";
import { useSelector } from "react-redux";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import StatusDayButton from "./StatusDayButton";
import { MondayButton } from "../../../../../commonComponents";
import { Tick } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import "./ScheduleDayNotesModal.scss";
import { addBusinessDaysArray } from "../../../../../SidebarPages/Scheduling/ScheduleChangesModal/Helpers/helperFunctions";
import Swal from "sweetalert2";
import { useRedux } from "../../../../../SidebarPages/hooks";
import { emptyDays } from "../emptyDays";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import {
  canDayBePostponed,
  checkIfCrewIsAlreadyScheduled,
  swalTexts,
  swalToast,
} from "../../SchedulingFirstPage/helperData";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import DispatchScheduleDayModal from "../ScheduleDayViews/DispatchScheduleDayModal";
import CrewsConflicts from "./CrewsConflicts";
import { updateDocumentTitle } from "../../../../../../utils";
import { parseInTz } from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

/**
 * This is used when we want to cancel or postpone days. Postpone operation is the most complex one. After we create new
 * schedule days via postpone they are added in normal schedule days list together with their links with original days.
 * */
export const DayStatusChangeModal = ({
  scheduleDays,
  scheduleDay,
  changeScheduleDayStatus,
  toBeScheduled,
  notesToAddForScheduleDays,
  updateNotesToAddForScheduleDays,
  editMode,
  postponedOnce,
  setDisptachDays,
  dispatchForSchedule,
  scheduleId,
  calendarView,
  scheduleName,
  daysForDispatch,
  schedulesThatBelongToProjectSelected,
  fromApprovals,
  setDidPostpone,
}) => {
  const [crewConflicts, setCrewConflicts] = useState({
    visible: false,
    data: {},
    date: null,
  });
  const [{ authenticatedUser }, { isDarkMode }] = useSelector((state) => [
    state.authenticatedUser,
    state.darkMode,
  ]);
  const { programFields } = useSelector((state) => state?.programFields);
  const [open, set_open] = useState(false);
  const [scheduleDays_to_use, set_scheduleDays_to_use] = useState(
    _.cloneDeep(scheduleDays)
  );
  const [openDispatchDays, setOpenDispatchDays] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [dayStatus, set_dayStatus] = useState(
    !postponedOnce ? scheduleDay?.status : ""
  );
  const [continuePostponing, setContinuePostponing] = useState(false);
  const [noteToAdd, set_noteToAdd] = useState(
    notesToAddForScheduleDays?.[scheduleDay?.id]?.text || ""
  );
  // new postponed days
  const [newScheduleDays, set_newScheduleDays] = useState([]);

  const [postponeReason, setPostponeReason] = useState("");
  const [whichStatusDayBtnIsClicked, setWhichStatusDayBtnIsClicked] = useState(
    !postponedOnce ? scheduleDay?.status : ""
  );
  const [isWritable] = useRedux("isWritableScheduling");

  let dayWeArePostponing, newDay;

  //* Geting ids of days used in services *//
  const usedDaysInService = [
    ...new Set(
      Object.values(toBeScheduled || {})
        ?.flat()
        ?.flatMap((el) => el?.serviceOptions?.[0])
        ?.flatMap((x) => x?.items)
        ?.flatMap((i) => i?.days)
    ),
  ];

  const mainPostponedDay = findScheduleDay(
    scheduleDays_to_use,
    scheduleDay?.id
  )?.findWhereIsPostponed(newScheduleDays);

  const onConfirm = () => {
    if (noteToAdd !== "") {
      set_noteToAdd(noteToAdd);
      updateNotesToAddForScheduleDays({
        ...notesToAddForScheduleDays,
        [scheduleDay?.id]: new ScheduleDayNote(noteToAdd, dayStatus),
      });
    }
    let totalDays = scheduleDays_to_use;

    if (dayStatus === DayStatus?.Postponed && !postponedOnce) {
      if (
        dayjs(newScheduleDays?.[0]?.startDate).valueOf() ===
        dayjs(scheduleDay?.startDate).valueOf()
      ) {
        return message.error(
          `Please select a date after ${dayjs(scheduleDay?.startDate).format(
            "MM/DD/YYYY"
          )}!`
        );
      }
      const { startDate: mainPostponedStartDate } = mainPostponedDay || {};

      for (const newScheduleDay of newScheduleDays || []) {
        const originalDay = totalDays?.find(
          (el) => el?.linkedDays?.postponedTo === newScheduleDay?.id
        );
        dayWeArePostponing = originalDay;
        newDay = newScheduleDay;

        const checkForCrews = checkIfCrewIsAlreadyScheduled(
          schedulesThatBelongToProjectSelected,
          dayWeArePostponing,
          newDay
        );

        if (Object.keys(checkForCrews)?.length > 0 && !continuePostponing) {
          setCrewConflicts({
            visible: true,
            data: checkForCrews,
            date: dayjsNY(newDay?.startDate).format("MM/DD/YYYY"),
          });
          updateDocumentTitle();
          set_open(false);
          return;
        }

        setDidPostpone(true);

        const dispatchDateMatch = dispatchForSchedule?.some(
          (item) =>
            dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
            dayjsNY(originalDay?.startDate).startOf("day").valueOf()
        );

        if (dispatchDateMatch) {
          const dispatchId = dispatchForSchedule?.find(
            (item) =>
              dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
              dayjsNY(originalDay?.startDate).startOf("day").valueOf()
          )?.dispatchId;

          setDisptachDays({
            action: "Postponed",
            dayEdited: dayjs(originalDay?.startDate).format("MM/DD/YYYY"),
            status: originalDay?.status,
            prev: dayjs(originalDay?.startDate).format("MM/DD/YYYY"),
            new: dayjs(newScheduleDay?.startDate).format("MM/DD/YYYY"),
            scheduleName,
            dispatchId,
          });
        }

        totalDays = scheduleDays_to_use.concat(newScheduleDays);

        if (canDayBePostponed(dayWeArePostponing, totalDays)) {
          message.error(
            `You can't postpone a day that is before the other postponed days!`
          );
          return;
        }

        const dayWeArePostponingStartDate = dayjs(dayWeArePostponing?.startDate)
          .tz("America/New_York")
          .format("MM/DD/YYYY");

        if (mainPostponedStartDate === dayWeArePostponingStartDate) {
          Swal.fire({
            text: "You cannot postpone a day on the same day as it is!",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
          return;
        }

        const oldScheduleDays = _.cloneDeep(scheduleDays);
        const startDatesTemp = {};
        const endDatesTemp = {};

        for (const day of totalDays || []) {
          if (
            day?.day >= dayWeArePostponing?.day &&
            day?.status !== "Postponed"
          ) {
            startDatesTemp[day?.id] = dayjs(
              oldScheduleDays?.find((el) => el?.day === day?.day)?.startDate
            )
              .tz("America/New_York")
              .clone();
            endDatesTemp[day?.id] = dayjs(
              oldScheduleDays?.find((el) => el?.day === day?.day)?.endDate
            )
              .tz("America/New_York")
              .clone();
          }
        }

        const newDates = Object.fromEntries(
          addBusinessDaysArray(
            Object.entries(startDatesTemp),
            0,
            dayjs(
              totalDays?.find(
                (t) =>
                  t?.day === dayWeArePostponing?.day &&
                  t?.status !== DayStatus?.Postponed
              )?.startDate
            )
              .clone()
              .tz("America/New_York")
          )
        );

        totalDays?.forEach((day, i) => {
          if (!!newDates?.[day?.id]) {
            const diff = _.round(
              dayjs(newDates?.[day?.id])
                .tz("America/New_York")
                .diff(startDatesTemp?.[day?.id], "days", true)
            );
            day.startDate = dayjs(startDatesTemp?.[day?.id])
              .add(diff, "days")
              .toDate();
            day.endDate = dayjs(endDatesTemp?.[day?.id])
              .add(diff, "days")
              .toDate();
          }
        });
      }
    } else if (dayStatus === DayStatus?.Canceled) {
      if (
        dispatchForSchedule?.some(
          (item) =>
            dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
            dayjsNY(scheduleDay?.startDate).startOf("day").valueOf()
        )
      ) {
        setDisptachDays({
          action: "Canceled",
          dayEdited: dayjs(scheduleDay?.startDate).format("MM/DD/YYYY"),
          status: scheduleDay?.status,
          scheduleId,
          prev: dayjs(scheduleDay?.startDate).format("MM/DD/YYYY"),
          new: "Canceled",
          dispatchId: dispatchForSchedule?.find(
            (item) =>
              dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
              dayjsNY(scheduleDay?.startDate).startOf("day").valueOf()
          )?.dispatchId,
        });
      }
      const findId = usedDaysInService?.includes(scheduleDay?.id);
      if (findId) {
        return message.error(
          "You cannot cancel a day that is already used in services!"
        );
      }
    }

    if (
      scheduleDay?.status === "Canceled" &&
      dayStatus !== "Canceled" &&
      dispatchForSchedule?.some(
        (item) =>
          dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
          dayjsNY(scheduleDay?.startDate).startOf("day").valueOf()
      )
    ) {
      setDisptachDays({
        action: "Reverted back from Canceled",
        dayEdited: dayjs(scheduleDay?.startDate).format("MM/DD/YYYY"),
        status: dayStatus,
        scheduleId,
        prev: "Canceled",
        new: dayjs(scheduleDay?.startDate).format("MM/DD/YYYY"),
        dispatchId: dispatchForSchedule?.find(
          (item) =>
            dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
            dayjsNY(scheduleDay?.startDate).startOf("day").valueOf()
        )?.dispatchId,
      });
    }

    //* getting the days that are postponed
    const filterOnlyPostponeOnes = totalDays?.filter(
      (el) => el?.linkedDays?.postponedTo !== undefined
    );
    //* getting the days which were postponed and now are not postponed anymore
    const whoChanges = filterOnlyPostponeOnes?.filter(
      (el) => el?.status !== "Postponed"
    );

    const NoDubs = new Set();

    totalDays = [
      ...totalDays
        ?.sort(
          (a, b) =>
            dayjs(a?.startDate).tz("America/New_York") -
            dayjs(b?.startDate).tz("America/New_York")
        )
        ?.filter?.((data) => {
          const findDup = NoDubs?.has(
            dayjs(data?.startDate).tz("America/New_York").format("MM/DD/YYYY")
          );
          NoDubs?.add(
            dayjs(data?.startDate).tz("America/New_York").format("MM/DD/YYYY")
          );
          if (!findDup) {
            return true;
          }
          return false;
        }),
    ];
    //* if we have a day that was postponed and now is not postponed anymore we will revert back the days how they were before the postponing
    if (whoChanges?.length > 0) {
      setDidPostpone(true);
      const latestDay = filterOnlyPostponeOnes?.sort(
        (a, b) => dayjs(b?.startDate) - dayjs(a?.startDate)
      )?.[0];
      if (
        dispatchForSchedule?.some(
          (item) =>
            dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
            dayjsNY(latestDay?.startDate).startOf("day").valueOf()
        )
      ) {
        setDisptachDays({
          action: "Reverted back from Postponed",
          dayEdited: dayjs(latestDay?.startDate).format("MM/DD/YYYY"),
          status: latestDay?.status,
          scheduleId,
          prev: dayjs(
            totalDays?.find((s) => s?.id === latestDay?.linkedDays?.postponedTo)
              ?.startDate
          ).format("MM/DD/YYYY"),
          new: dayjs(latestDay?.startDate).format("MM/DD/YYYY"),
          dispatchId: dispatchForSchedule?.find(
            (item) =>
              dayjsNY(item?.dispatchDate).startOf("day").valueOf() ===
              dayjsNY(latestDay?.startDate).startOf("day").valueOf()
          )?.dispatchId,
        });
      }

      if (
        dayjs(whoChanges?.[whoChanges?.length - 1]?.startDate).valueOf() <
        dayjs(latestDay?.startDate).valueOf()
      ) {
        message.error(
          `You can't revert back a day that is before the other postponed days!`
        );
        return;
      }
      const getTheOneWhoChanged = whoChanges[whoChanges?.length - 1];
      const first = dayjsNY(getTheOneWhoChanged?.startDate);
      const last = dayjsNY(totalDays?.[totalDays?.length - 1]?.startDate);
      const returnDays = emptyDays(first, last)?.filter((el) => {
        return (
          dayjs(el)?.day() !== 0 &&
          dayjs(el)?.day() !== 6 &&
          dayjs(el).valueOf() >= dayjs(getTheOneWhoChanged?.startDate).valueOf()
        );
      });

      let counter = 0;
      totalDays?.forEach((el, i) => {
        if (
          dayjs(el?.startDate).valueOf() >
          dayjs(getTheOneWhoChanged?.startDate).valueOf()
        ) {
          el.startDate = new Date(
            dayjs(`${returnDays?.[counter]}`).set({
              hour: 7,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
          );
          el.endDate = new Date(
            dayjs(`${returnDays?.[counter]}`).set({
              hour: 16,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
          );
          counter++;
        }
      });
      totalDays?.pop();
    }
    totalDays
      ?.filter((el) => el?.status !== "Postponed")
      ?.forEach((el, i) => {
        el.day = i + 1;
      });

    changeScheduleDayStatus(scheduleDay?.id, totalDays, noteToAdd, dayStatus);
    swalToast({
      icon: "success",
      title: "Saved successfully!",
      timer: 3500,
      position: "bottom-end",
      showConfirmButton: false,
    });
    updateDocumentTitle(); // reset document title to "Lead Manager"
    set_open(false);
  };

  const onCancel = () => {
    set_scheduleDays_to_use(
      ScheduleDay.scheduleDayArrayFromArrayObject(_.cloneDeep(scheduleDays))
    );
    set_noteToAdd(notesToAddForScheduleDays[scheduleDay.id]?.text || "");
    set_newScheduleDays([]);
    set_dayStatus(!postponedOnce ? scheduleDay?.status : "");
    setWhichStatusDayBtnIsClicked(!postponedOnce ? scheduleDay?.status : "");
    updateDocumentTitle(); // reset document title to "Lead Manager"
    set_open(false);
  };

  const startDateChange = (d, mainPostponedDay) => {
    const scheduleDays_to_use_COPY = _.cloneDeep(scheduleDays_to_use);
    let newPostponedDays = _.cloneDeep(newScheduleDays);
    if (!mainPostponedDay) return;
    let newDayToEdit = findScheduleDay(newPostponedDays, mainPostponedDay?.id);
    let timeDifference = parseInTz(d)
      .startOf("day")
      .diff(parseInTz(mainPostponedDay?.startDate).startOf("day"), "days");

    newDayToEdit = Object.assign(new ScheduleDay(), {
      ...newDayToEdit,
      startDate: parseInTz(mainPostponedDay?.startDate).add(
        timeDifference,
        "days"
      ),
      endDate: parseInTz(mainPostponedDay?.endDate).add(timeDifference, "days"),
    });

    set_newScheduleDays([newDayToEdit]);
    set_scheduleDays_to_use(scheduleDays_to_use_COPY);
  };

  const shouldDisableStartDate = (date) => {
    let disable = false;
    if (dayjs(date).valueOf() < dayjs(scheduleDay?.startDate).valueOf()) {
      disable = true;
    }
    if (dayjs(date).day() === 0 || dayjs(date).day() === 6) {
      disable = true;
    }
    return disable;
  };

  const cancelingAdayReasons = programFields
    ?.find(({ fieldName }) => fieldName === "Canceling a schedule day")
    ?.fieldOptions?.map(({ reasonName }) => reasonName);

  const canChangeDate = dayStatus === DayStatus?.Postponed;

  useEffect(() => {
    if (!!open && !postponedOnce) {
      set_newScheduleDays([]);
    }
  }, [open]);

  useEffect(() => {
    set_scheduleDays_to_use(_.cloneDeep(scheduleDays));
  }, [scheduleDays]);

  useEffect(() => {
    if (!postponedOnce) {
      set_dayStatus(scheduleDay?.status);
      setWhichStatusDayBtnIsClicked(scheduleDay?.status);
    }
  }, [scheduleDay]);

  useEffect(() => {
    set_noteToAdd(notesToAddForScheduleDays?.[scheduleDay?.id]?.text || "");
  }, [notesToAddForScheduleDays]);

  useEffect(() => {
    if (!!continuePostponing) {
      onConfirm();
    }
  }, [continuePostponing]);

  const postponedDayLabel =
    dayjs(mainPostponedDay?.startDate).startOf("day").format("MM/DD/YYYY") ===
    dayjs(scheduleDay?.startDate).startOf("day").format("MM/DD/YYYY")
      ? ""
      : dayjs(mainPostponedDay?.startDate).startOf("day").format("MM/DD/YYYY");

  return (
    <>
      <div>
        <Button
          disabled={!!calendarView || !!fromApprovals ? false : !isWritable}
          onClick={() => {
            set_open(true);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 96,
            height: 25,
            borderRadius: 5,
            color: "#fff",
            background: DisplayColors?.[scheduleDay?.status],
            border: "none",
          }}
        >
          {scheduleDay?.status}
        </Button>
      </div>
      {open && (
        <Modal
          title={
            <div>{dayjsNY(scheduleDay?.startDate).format("MM/DD/YYYY")}</div>
          }
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "Change day status" });
          }}
          centered
          footer={null}
          closeIcon={<XIcon />}
          open={open}
          className={
            dayStatus === "Postponed" && !postponedOnce
              ? `postpone-view ${isDarkMode && "postpone-view-dark"}`
              : `day-status-change-modal ${
                  isDarkMode && "day-status-change-modal-dark"
                }`
          }
          onCancel={onCancel}
          width="fit-content"
          destroyOnClose={true}
        >
          <>
            <div
              className={
                dayStatus === "Postponed" && !postponedOnce
                  ? "postpone-check"
                  : "day-status-change-container-buttons"
              }
            >
              <div
                className={
                  dayStatus === "Postponed" && !postponedOnce
                    ? "postpone-view-buttons"
                    : "day-status-change-container"
                }
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    flexDirection: "column",
                  }}
                >
                  {Object?.values(DayStatus)
                    ?.filter((el) => {
                      return el !== scheduleDay?.status;
                    })
                    ?.map?.((x, index) => (
                      <StatusDayButton
                        {...{
                          x,
                          setWhichStatusDayBtnIsClicked,
                          whichStatusDayBtnIsClicked,
                          scheduleDay,
                          scheduleDays_to_use,
                          findScheduleDay,
                          set_newScheduleDays,
                          set_dayStatus,
                          set_scheduleDays_to_use,
                          newScheduleDays,
                          dayStatus,
                          setPostponeReason,
                        }}
                      />
                    ))}
                </div>
                {dayStatus !== "Postponed" && (
                  <div>
                    <Input.TextArea
                      style={{
                        height: 112,
                        background: "#F5F5F7 0% 0% no-repeat padding-box",
                        border: "none",
                        borderRadius: 5,
                      }}
                      value={noteToAdd}
                      onChange={(e) => set_noteToAdd(e.target.value)}
                      placeholder="Write note..."
                      disabled={editMode ? !isWritable : true}
                    />
                  </div>
                )}
              </div>
              <div
                className="new-day-container"
                style={{
                  display: canChangeDate && !postponedOnce ? "flex" : "none",
                  flexDirection: "column",
                  gap: "1rem",
                  marginBottom: 20,
                }}
              >
                <div className="dateInfoPostpone">
                  <span>{`Day you editing : ${dayjs(
                    scheduleDay.startDate
                  ).format("MM/DD/YYYY")}`}</span>
                  <b>{`Day postponed to : ${postponedDayLabel}`}</b>
                </div>
                <InputComponent
                  type="datepicker"
                  showTime={false}
                  format="MM/DD/YYYY"
                  value={dayjs(mainPostponedDay?.startDate)}
                  disabledDate={shouldDisableStartDate}
                  onChange={(date) => startDateChange(date, mainPostponedDay)}
                  allowClear={false}
                />
                <div>
                  <Input.TextArea
                    style={{
                      height: 112,
                      background: "#F5F5F7 0% 0% no-repeat padding-box",
                      border: "none",
                      borderRadius: 5,
                    }}
                    value={noteToAdd}
                    onChange={(e) => set_noteToAdd(e.target.value)}
                    placeholder="Write note..."
                    disabled={editMode ? !isWritable : true}
                  />
                </div>
                <div>
                  {/* <div className="optionsForPostponingCheckbox">
                  <div className="postpone-label">Reason</div>
                  {whichStatusDayBtnIsClicked === "Postponed" && (
                    <div>
                      <InputComponent
                        type="select"
                        placeholder="Select..."
                        className="postpone-select"
                        dropdownClassName={isDarkMode && "darkDropDown"}
                        onSelect={(e) => {
                          setPostponeReason(e);
                          set_noteToAdd(e === "Other" ? "" : e);
                        }}
                        options={cancelingAdayReasons?.map?.((el) => {
                          return <Option value={el}>{el}</Option>;
                        })}
                      />
                      {postponeReason === "Other" &&
                        whichStatusDayBtnIsClicked === "Postponed" && (
                          <Input.TextArea
                            className="postpone-textarea"
                            value={noteToAdd}
                            onChange={(e) => set_noteToAdd(e.target.value)}
                            placeholder="Write your reason for postponing here..."
                            // disabled={editMode ? !isWritable : true}
                          />
                        )}
                    </div>
                  )}
                </div> */}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {/* {whichStatusDayBtnIsClicked === "Canceled" && (
                <InputComponent
                  type="select"
                  placeholder="Select..."
                  style={{ width: 250 }}
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  onSelect={(e) => {
                    setCancelReason(e);
                    set_noteToAdd(e === "Other" ? "" : e);
                  }}
                  options={cancelingAdayReasons?.map?.((el) => {
                    return <Option value={el}>{el}</Option>;
                  })}
                />
              )} */}
                {/* {whichStatusDayBtnIsClicked !== "Canceled" &&
                whichStatusDayBtnIsClicked !== "Postponed" && (
                  <Input.TextArea
                    className="reason-change"
                    value={noteToAdd}
                    onChange={(e) => set_noteToAdd(e.target.value)}
                    placeholder="Reason..."
                    // disabled={editMode ? !isWritable : true}
                  />
                )} */}
                {/* {cancelReason === "Other" &&
                whichStatusDayBtnIsClicked === "Canceled" && (
                  <Input.TextArea
                    style={{
                      height: 112,
                      background: "#F5F5F7 0% 0% no-repeat padding-box",
                      border: "none",
                      borderRadius: 5,
                    }}
                    value={noteToAdd}
                    onChange={(e) => set_noteToAdd(e.target.value)}
                    placeholder="Reason..."
                    disabled={editMode ? !isWritable : true}
                  />
                )} */}
              </div>
            </div>
            <div
              className={
                dayStatus === "Postponed" && !postponedOnce
                  ? "postponed-footer-buttons"
                  : "footer-btn-day-status-changes"
              }
            >
              <MondayButton
                onClick={onCancel}
                {...{ Icon: <XIcon />, className: "mondayButtonRed" }}
              >
                Cancel
              </MondayButton>
              <MondayButton
                // disabled={editMode ? !isWritable : checkPost}
                onClick={onConfirm}
                {...{ Icon: <Tick width={17} height={17} /> }}
              >
                Save Changes
              </MondayButton>
            </div>
          </>
        </Modal>
      )}
      {openDispatchDays && (
        <DispatchScheduleDayModal
          {...{
            openDispatchDays,
            setOpenDispatchDays,
            dispatchDays: daysForDispatch,
          }}
        />
      )}
      {crewConflicts?.visible && (
        <CrewsConflicts
          {...{
            crewConflicts,
            setCrewConflicts,
            setContinuePostponing,
          }}
        />
      )}
    </>
  );
};

const findScheduleDay = (scheduleDayList, scheduleDayId) =>
  scheduleDayList?.find((sch) => sch?.id === scheduleDayId);
