import { formatCurrency } from "../../../../../utils";
import { getSums, groupBy } from "../../../calculatingFunctions";

export const applications = ({ tables, programFields }) => {
  const applicationStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = applicationStatus?.statusName?.map((status) =>
    tables?.applications?.filter((a) => a?.applicationStatus === status)
  );

  return {
    applicationStatus: () => ({
      content: applicationStatus?.statusName?.map((status, idx) => ({
        color: applicationStatus?.statusColor[idx],
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.applications?.filter(
            (a) => a?.applicationStatus?.toLowerCase() === status?.toLowerCase()
          ).length /
            tables?.applications?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status,
        done: tables?.applications?.filter(
          (a) => a?.applicationStatus?.toLowerCase() === status?.toLowerCase()
        ).length,
        custom:
          tables?.applications &&
          groupBy(statusData, "projectName")?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Current Payment": formatCurrency(
                    getSums({ data: statusData, key: "totalAmount" })[index]
                  ),
                  "Total Retainage": formatCurrency(
                    getSums({ data: statusData, key: "totalRetainage" })[index]
                  ),
                  "Total Rental With Tax": formatCurrency(
                    getSums({
                      data: statusData,
                      key: "totalRentalWithTax",
                    })[index]
                  ),
                },
              },
              ...Object?.entries(el)?.map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Current Payment": formatCurrency(
                      getSums({ data: value, key: "totalAmount" })
                    ),
                    "Total Retainage": formatCurrency(
                      getSums({ data: value, key: "totalRetainage" })
                    ),
                    "Total Rental With Tax": formatCurrency(
                      getSums({ data: value, key: "totalRentalWithTax" })
                    ),
                  },
                };
              }),
            ];
          })[idx],
      })),
    }),
  };
};
