import { MondayButton } from "../../../../../commonComponents";

const progressChangesFooter = ({
  onCancel = () => {},
  onSave = () => {},
  t = () => {},
}) => {
  return (
    <div className="progress-changes-footer">
      <MondayButton
        onClick={() => {
          onCancel();
        }}
        hasIcon={false}
        className="mondayButtonRed foreman-button"
      >
        {t("Cancel")}
      </MondayButton>
      <MondayButton
        hasIcon={false}
        className="mondayButtonGreen foreman-button"
        onClick={() => {
          onSave();
        }}
      >
        {t("Save Changes")}
      </MondayButton>
    </div>
  );
};

export default progressChangesFooter;
