import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const estimations = (
  updatedDynamicStates,
  IdHandler,
  programFields,
  archData,
  engData
) => {
  return {
    estSTATUS: () => ({
      title: "Estimations Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Estimations",
        field: "estSTATUS",
        content: programFields
          ?.find((a) => a?.fieldName === "Estimation Status")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) =>
                a?.estSTATUS?.toLowerCase() === statusName.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Estimation " + statusName,
            contentsHandler: " Estimations " + statusName,
          })),
      },

      id: IdHandler,
      width: "1600px",
    }), //estSTATUS
    archAssignedTo: () => ({
      title: "Architect Assigned To Estimation",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Estimations",
        field: "archAssignedTo",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "archAssignedTo"
          )
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Estimation Assigned `,
          contentsHandler: ` Estimations Assigned `,
        })),
      },

      id: IdHandler,
      width: "1600px",
    }), //archAssignedTo
    engAssignedTo: () => ({
      title: "Engineer Assigned To",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Estimations",
        field: "engAssignedTo",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "engAssignedTo"
          )
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Estimation Assigned `,
          contentsHandler: ` Estimations Assigned `,
        })),
      },

      id: IdHandler,
      width: "1600px",
    }), //engAssignedTo
    statusByArch: () => ({
      title: "Status By Arch",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByArch",
        content: programFields
          ?.find((a) => a?.fieldName === "Status by Architecture")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) =>
                a?.statusByArch?.toLowerCase() ===
                statusName.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` Estimation ${statusName}`,
            contentsHandler: ` Estimations ${statusName}`,
          })),
      },

      id: IdHandler,
      width: "1600px",
    }), //statusByArch
    statusByEng: () => ({
      title: "LongLineSteper",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByEng",
        content: programFields
          ?.find((a) => a?.fieldName === "Status by Engineering")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) =>
                a?.statusByEng?.toLowerCase() === statusName.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` Estimation ${statusName}`,
            contentsHandler: ` Estimations ${statusName}`,
          })),
      },

      id: IdHandler,
      width: "1600px",
    }), //statusByEng
  };
};
