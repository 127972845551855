import { elif } from "../../../RolesData";

const RoleHeaderTab = ({
  title,
  selectedTab,
  setSelectedTab,
  setSearchRouteConfigs = () => {},
}) => {
  return (
    <div
      id={title.replace(/\s/g, "")}
      onClick={(e) => {
        setSelectedTab(title);
        setSearchRouteConfigs("");
      }}
      className={elif(title === selectedTab).configSelected}
    >
      {title}
    </div>
  );
};

export default RoleHeaderTab;
