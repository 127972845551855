import {
  FieldTimeOutlined,
  LeftOutlined,
  RightOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Select, DatePicker, Result } from "antd";
import axios from "axios";
import { on } from "events";
import FileSaver from "file-saver";
import { groupBy } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  CheckIconModal,
  CloseIconModalHeader,
  ExcelIcon,
  ExcelIconWhite,
} from "../../../../../../assets";
import { MondayButton, Stepper } from "../../../../../commonComponents";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { forceToNumber } from "../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { executeCalendarList } from "../../googleClientAPI/execute";
import AdvancedExportTable from "./AdvancedExportTable";
import { getEventsForCalendars } from "./utils";
const { RangePicker } = DatePicker;

function AdvancedExportModal({ visible, onClose }) {
  const [current, setCurrent] = useState(0);
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [availableCalendars, setAvailableCalendars] = useState([]);
  const handleChange = (value) => {
    setSelectedCalendars(value);
  };

  const onIntervalChange = (value) => {
    console.log("value", value);
    setSelectedTimeInterval(value);
  };
  const extractWordAfterScope = (htmlString) => {
    let text = htmlString.replace(/<\/?[^>]+(>|$)/g, (match) => {
      // Convert <br> tags to newlines
      if (match === "<br>") return "\n";
      // Remove other tags
      return "";
    });
    let scopeRegex = /^Scope\s*:\s*[^\n]*/gim;
    let matches = [];
    let match;

    while ((match = scopeRegex.exec(text)) !== null) {
      let sentence = match[0].trim();
      matches.push(sentence);
    }
    // need all the words after with 'Scope:'
    return matches.join("\r\n");
  };
  const extractNumberBeforeCrew = (htmlString) => {
    let text = htmlString.replace(/<\/?[^>]+(>|$)/g, (match) => {
      if (match === "<br>") return "\n";
      return "";
    });

    let crewRegex = /(\d+)\s+(crew|crews)/gi;
    let crewMatches = [];
    let match;

    while ((match = crewRegex.exec(text)) !== null) {
      crewMatches.push(match[1]); // Extract the number (first capture group)
    }

    return crewMatches.join("\r\n");
  };
  const extractWordAfterDash = (input) => {
    if (input.includes(" - ")) {
      let parts = input.split(" - ");
      return parts[parts.length - 1].trim();
    }
    return "";
  };
  const removeDashAndWordAfter = (input) => {
    if (input.includes(" - ")) {
      let parts = input.split(" - ");
      return parts[0].trim();
    }
    return input.trim();
  };

  const getColorFromId = (id) => {
    const colorMap = {
      1: "7986CB",
      2: "33B679",
      3: "8E24AA",
      4: "E67C73",
      5: "F6BF26",
      6: "F4511E",
      7: "039BE5",
      8: "616161",
      9: "3F51B5",
      10: "0B8043",
      11: "D50000",
    };

    return colorMap[id] || "039BE5";
  };
  function htmlToPlainText(htmlString) {
    if (htmlString === null || htmlString === undefined || !!!htmlString)
      return "";
    let text = htmlString.replace(/<br\s*\/?>/gi, "\n");
    text = text.replace(/<\/?[^>]+(>|$)/g, "");
    text = text.trim();

    return text;
  }

  const onFinish = async (downloadExcel) => {
    console.log("onFinish", selectedCalendars, selectedTimeInterval);
    console.log("availableCalendars", availableCalendars);
    let fileName = "GoogleEventsExport" + ".xlsx";

    let allEvents = await getEventsForCalendars({
      arrOfCalendarIDs: selectedCalendars,
      startDate: dayjsNY(selectedTimeInterval?.[0]).format(),
      endDate: dayjsNY(selectedTimeInterval?.[1]).format(),
      showDeleted: false,
    });
    const priorityOrder = {
      7: 1, // Highest priority
      9: 2, // Second priority
      8: 3, // Lowest priority
    };
    const getPriority = (refNumber) => priorityOrder[refNumber] || Infinity;

    allEvents = allEvents
      .map((event) => {
        let currCalendar = availableCalendars.find(
          (item) => item?.id === event?.organizer?.email
        );
        console.log("currCalendar", { currCalendar, event });
        return {
          ...(event || {}),
          sortVal: dayjsNY(event?.start?.dateTime).valueOf(),
          eventStart: dayjsNY(event?.start?.dateTime).format(
            "MM/DD/YYYY HH:mm"
          ),
          formatedStartDate: dayjsNY(event?.start?.dateTime).format(
            "MM/DD/YYYY"
          ),
          // eventEnd: dayjsNY(event?.end?.dateTime).format("MM/DD/YYYY HH:mm"),
          eventStatus: event?.status,
          scope: extractWordAfterScope(event?.description || ""),
          crews: forceToNumber(
            extractNumberBeforeCrew(event?.description || "") || 1
          ),
          projectManager: extractWordAfterDash(event?.summary || ""),
          summary: removeDashAndWordAfter(event?.summary || ""),
          calendarName: currCalendar?.summary,
          backgroundColor: getColorFromId(event?.colorId),
          colorId: event?.colorId ?? 7,
          foregroundColor: "FFFFFF",
          fullNote: htmlToPlainText(event?.description) || "",
          // backgroundColor: currCalendar?.backgroundColor?.replace("#", ""),
          // foregroundColor: currCalendar?.foregroundColor?.replace("#", ""),
        };
      })
      .sort((a, b) => a.sortVal - b.sortVal);
    setAllEvents(allEvents);
    let toSend = groupBy(allEvents, "formatedStartDate");
    Object.keys(toSend).forEach((key) => {
      let values = toSend[key];
      toSend[key] = values.sort((a, b) => {
        const priorityA = getPriority(a.colorId || 7);
        const priorityB = getPriority(b.colorId || 7);

        // First sort by colorId priority
        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }

        // If priorities are the same, sort by name
        if (a.projectManager < b.projectManager) {
          return -1;
        } else if (a.projectManager > b.projectManager) {
          return 1;
        } else {
          return 0;
        }
      });
    });

    console.log("getAll", groupBy(allEvents, "formatedStartDate"));
    if (!downloadExcel) return;
    const url =
      "https://leadmanager-express-backend.ue.r.appspot.com/api/exportEvents/xlsx";
    // const url = "http://10.0.0.85:8080/api/exportEvents/xlsx";

    // const url =
    //   "https://leadmanager-sockets.ue.r.appspot.com/api/exportEventsXLSX";
    const hideLoading = message.loading(
      "File is being processed. Please wait...",
      0
    );
    axios({
      method: "post",
      url,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      data: JSON.stringify({
        events: toSend,
        startDate: dayjsNY(selectedTimeInterval?.[0]).format("MM/DD/YYYY"),
        endDate: dayjsNY(selectedTimeInterval?.[1]).format("MM/DD/YYYY"),
      }),
    })
      .then(async (response) => {
        hideLoading();
        console.log("response", response);
        FileSaver.saveAs(response.data, fileName);
        message.success("Export successful.");
      })
      .catch((err) => {
        hideLoading();
        console.error(err);
        message.error("Something went wrong...");
      });
  };

  const onTimeShortcut = (type) => {
    switch (type) {
      case "today": {
        setSelectedTimeInterval([dayjsNY(), dayjsNY()]);
        break;
      }
      case "thisWeek": {
        setSelectedTimeInterval([
          dayjsNY().startOf("week"),
          dayjsNY().endOf("week"),
        ]);
        break;
      }
      case "theseTwoWeeks": {
        setSelectedTimeInterval([
          dayjsNY().startOf("week"),
          dayjsNY().endOf("week").add(1, "week"),
        ]);
        break;
      }
      case "thisMonth": {
        setSelectedTimeInterval([
          dayjsNY().startOf("month"),
          dayjsNY().endOf("month"),
        ]);
        break;
      }
      case "thisHalfYear": {
        setSelectedTimeInterval([
          dayjsNY().startOf("year"),
          dayjsNY().startOf("year").add(6, "month"),
        ]);
        break;
      }
      case "thisYear": {
        setSelectedTimeInterval([
          dayjsNY().startOf("year"),
          dayjsNY().endOf("year"),
        ]);
        break;
      }
      case "lastYear": {
        setSelectedTimeInterval([
          dayjsNY().subtract(1, "year").startOf("year"),
          dayjsNY().subtract(1, "year").endOf("year"),
        ]);
        break;
      }
      default: {
        setSelectedTimeInterval([
          dayjsNY().startOf("week"),
          dayjsNY().endOf("week"),
        ]);
        break;
      }
    }
  };

  const steps = [
    {
      title: "Pick Calendar/s and Time Interval",
      content: (
        <div
          className="pick_calendar_wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <h4
              style={{
                lineHeight: "1.5",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "2px",
              }}
            >
              Select Calendar/s
            </h4>
            <Select
              mode="multiple"
              placeholder="Please select calendar/s"
              allowClear
              style={{ width: "100%" }}
              value={selectedCalendars}
              defaultValue={selectedCalendars}
              onChange={handleChange}
              options={availableCalendars.map((item) => ({
                label: item.summary,
                value: item.id,
              }))}
            />{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "1400px",
            }}
          >
            {" "}
            <h4
              style={{
                lineHeight: "1.5",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "2px",
              }}
            >
              Select time interval -
              {selectedTimeInterval?.length > 0 &&
                " You have selected " +
                  forceToNumber(
                    dayjsNY(selectedTimeInterval?.[1]).diff(
                      dayjsNY(selectedTimeInterval?.[0]),
                      "days"
                    ) + 1
                  ) +
                  " days."}{" "}
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <RangePicker
                width={300}
                onChange={onIntervalChange}
                value={selectedTimeInterval}
                format="MM/DD/YYYY"
              />{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("today")}
                Icon={<FieldTimeOutlined />}
              >
                Today
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisWeek")}
                Icon={<FieldTimeOutlined />}
              >
                This Week
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("theseTwoWeeks")}
                Icon={<FieldTimeOutlined />}
              >
                These Two Weeks
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisMonth")}
                Icon={<FieldTimeOutlined />}
              >
                This Month
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisHalfYear")}
                Icon={<FieldTimeOutlined />}
              >
                This Half Year
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisYear")}
                Icon={<FieldTimeOutlined />}
              >
                This Year
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("lastYear")}
                Icon={<FieldTimeOutlined />}
              >
                Last Year
              </MondayButton>
            </div>
          </div>{" "}
        </div>
      ),
    },
    // {
    //   title: "Review Time Interval Options",
    //   content: (
    //     <div className="pick_time_interval_holder">
    //       {" "}
    //       <RangePicker
    //         onChange={onIntervalChange}
    //         value={selectedTimeInterval}
    //         format="MM/DD/YYYY"
    //       />
    //       <div>
    //         You have selected{" "}
    //         {dayjsNY(selectedTimeInterval?.[1]).diff(
    //           dayjsNY(selectedTimeInterval?.[0]),
    //           "days"
    //         )}{" "}
    //         days
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Successfully Exported",
      content: (
        <div className="choose_source_wrapper">
          <AdvancedExportTable data={allEvents} />
        </div>
      ),
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const next = () => {
    if (current === 0) {
      console.log("selectedCalendars", selectedCalendars);
      console.log("selectedTimeInterval", selectedTimeInterval);
      onFinish(false);
      setCurrent(current + 1);
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (visible) {
      executeCalendarList().then((res) => {
        console.log("calendarList", res);
        setAvailableCalendars(res?.result?.items || []);
      });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={"Advanced Export Events"}
      width={1800}
      closeIcon={<CloseIconModalHeader />}
      footer={null}
      maskClosable={false}
    >
      <div
        style={{
          width: "1750px",
          height: "650px",
        }}
      >
        <Stepper
          current={current}
          items={items}
          stepRenderer={false}
          type="navigation"
          componentContainerClassName="proposalBuilderWrfapper"
        />{" "}
        <div className="antStepperHolder" style={contentStyle}>
          {steps[current].content}
        </div>{" "}
      </div>
      <div
        style={{
          marginTop: 24,
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {current > 0 && (
          <MondayButton
            className="mondayButtonBlue"
            noTooltip={true}
            style={{ margin: "0 8px" }}
            onClick={() => prev()}
            Icon={<LeftOutlined />}
          >
            Previous
          </MondayButton>
        )}{" "}
        {current >= 0 && current < steps.length - 1 && (
          <MondayButton
            noTooltip={true}
            className="mondayButtonBlue"
            onClick={() => next()}
            Icon={<RightOutlined />}
          >
            Next
          </MondayButton>
        )}
        {current === steps.length - 1 && (
          <MondayButton
            noTooltip={true}
            className="mondayButtonBlue"
            Icon={<ExcelIconWhite />}
            onClick={() => onFinish(true)}
          >
            Download Excel File
          </MondayButton>
        )}
      </div>
    </Modal>
  );
}

export default AdvancedExportModal;
const contentStyle = {
  lineHeight: "260px",
  textAlign: "center",
  // width: "960px",
  minWidth: "1300px",
  // color: token.colorTextTertiary,
  // backgroundColor: token.colorFillAlter,
  // borderRadius: token.borderRadiusLG,
  // border: `1px dashed ${token.colorBorder}`,
  marginTop: 16,
};
