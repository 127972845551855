import "./CustomComments.scss";
import { Avatar, Tag, Button, Tooltip } from "antd";
import { Comment } from "@ant-design/compatible";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import htmlParser from "../../../utils/htmlParser";
import { getRandomForeBackground, hasContent } from "./utils";
import { sortBy, uniqBy } from "lodash";
import { ChildrenCollapser, DateTimeRenderer, Replier } from "./components";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import QuillTextEditor from "../QuillTextEditor/QuillTextEditor";
import { getInitials } from "../../../utils";
import AvatarImage from "../AvatarImage/AvatarImage";

const CustomComments = ({
  comments = [],
  onChange,
  disabled = false,
  people,
  // status: proppedStatus = "",
  findKeysIn,
  viewMode = false,
}) => {
  const [commenting, setCommenting] = useState(false);
  const [commentContent, setCommentContent] = useState();

  const commentsContainerRef = useRef();
  const quillRef = useRef();
  const newCommentBtnRef = useRef();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const renderCommentsRecursively = (comments = []) => {
    return sortBy(comments, "createdAt").map((comment = {}) => {
      const {
        author = "",
        id,
        createdAt,
        content = "",
        status = "",
        replies,
        cognitoUserId,
      } = comment;
      const commentStyle = getRandomForeBackground();

      const findedUser = userConfiguration?.allUsers?.Items?.find?.(
        (el) => el?.cognitoUserId === cognitoUserId
      );

      return (
        <Comment
          {...{
            key: comment[findKeysIn.id] || id,
            author,
            content: htmlParser(comment[findKeysIn.content] || content),
            actions: !viewMode && [
              <Replier
                {...{
                  disabled,
                  comment,
                  comments,
                  onChange,
                  author: userConfiguration?.nameOfUser,
                  // status: proppedStatus,
                  newCommentBtnRef,
                  people,
                }}
              />,
            ],
            datetime: (
              <DateTimeRenderer {...{ status, commentStyle, createdAt }} />
            ),
            avatar: findedUser?.googleDriveFileId ? (
              <AvatarImage
                {...{
                  nameOfUser: findedUser?.nameOfUser,
                  googleDriveFileId: findedUser?.googleDriveFileId,
                }}
              />
            ) : (
              <Tooltip title={author}>
                <Avatar style={commentStyle}>{getInitials(author)}</Avatar>
              </Tooltip>
            ),
          }}
        >
          {!!replies?.length && (
            <ChildrenCollapser>
              {renderCommentsRecursively(replies)}
            </ChildrenCollapser>
          )}
        </Comment>
      );
    });
  };

  const resetComment = () => {
    setCommentContent("");
    setCommenting(false);
  };

  const saveComment = () => {
    const editor = quillRef.current.getEditor();
    const deltas = editor.getContents(commentContent);

    const mentions = uniqBy(
      deltas
        .filter((delta) => !!delta?.insert?.mention)
        .map(({ insert: { mention } }) => ({
          name: mention.value,
          cognitoId: mention.cognitoId,
        })),
      "cognitoId"
    );

    const newComment = {
      id: v4(),
      mentions,
      author: userConfiguration?.nameOfUser,
      createdAt: dayjs().valueOf(),
      status: proppedStatus,
      content: commentContent,
    };

    onChange([...comments, newComment]);
    resetComment();
  };

  const RenderedComments = renderCommentsRecursively(comments);

  useEffect(() => {
    let commentsContainer = commentsContainerRef.current;
    commentsContainer.scrollTop =
      commentsContainer.scrollHeight - commentsContainer.clientHeight;

    setCommenting(!comments.length);
  }, [comments.length]);

  return (
    <div className="customComments">
      <div
        className="commentsContainer"
        // style={!!comments.length ? { height: 175 } : { height: 50 }}
        ref={commentsContainerRef}
      >
        {!!comments.length ? (
          RenderedComments
        ) : (
          <div className="noCommentsLabel">No comments</div>
        )}
      </div>
      {!viewMode &&
        (!!commenting ? (
          <div className="newCommentContainer">
            <QuillTextEditor
              ref={(el) => !!el && (quillRef.current = el)}
              {...{
                placeholder: placeholder,
                value: commentContent,
                onChange: (val) => {
                  setCommentContent(val);
                  onChange;
                },
                people,
                disabled,
              }}
            />
            <div className="btnSection">
              <Button {...{ disabled }} onClick={resetComment}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={
                  hasContent(commentContent) ? saveComment : resetComment
                }
                disabled={disabled}
              >
                Save Comment
              </Button>
            </div>
          </div>
        ) : (
          <Button
            className="newCommentBtn"
            ref={newCommentBtnRef}
            onClick={() => setCommenting(true)}
            {...{ disabled }}
          >
            New Comment
          </Button>
        ))}
    </div>
  );
};

export default CustomComments;
