import { quickSort } from "../../../../../helpers/SortingAlgorithms";
import { filterTables } from "../../../../../utils";
import {
  filterTablesPagination,
  requestAllPages,
} from "../../../../../utils/paginationFetchData";
import { fetchData } from "../../../../SidebarPages/Fleet/utils";
import { fetchAllData } from "../../../../SidebarPages/Fleet/utils/fetchAllData";

export const fetchModalData = ({
  routes,
  // setLoading,
  setClients,
  setProjectExecutives,
  setContacts,
  setOpportunities,
  setEstimations,
  setProjects,
  setAttorneys,
  setElectricians,
  setBoomCompanies,
  setExpeditors,
  setSafetyCompanies,
  setInsuranceBrokers,
}) => {
  filterTables("accounts", "subcontractorType", "Attorney").then((res) =>
    setAttorneys(res)
  );
  filterTables("accounts", "subcontractorType", "Electrician").then((res) =>
    setElectricians(res)
  );
  filterTables("accounts", "subcontractorType", "Expeditor").then((res) =>
    setExpeditors(res)
  );
  filterTables("accounts", "subcontractorType", "Safety Company").then((res) =>
    setSafetyCompanies(res)
  );
  filterTables("accounts", "subcontractorType", "Insurance Broker").then(
    (res) => setInsuranceBrokers(res)
  );
  filterTables("accounts", "subcontractorType", "Boom Company").then((res) =>
    setBoomCompanies(res)
  );
  filterTables("accounts", "accountRecordType", "Client").then((res) =>
    setClients(res.filter?.((acc) => acc?.activeClient === true))
  );
  fetchData(routes.projectExecutives).then((res) => setProjectExecutives(res));
  fetchData("contacts").then((res) => setContacts(res));
  fetchAllData("opportunities", "opportunities", "opportunityId").then((res) =>
    setOpportunities(res)
  );
  fetchAllData("estimations", "estimations", "estimationId").then((res) =>
    setEstimations(res)
  );
  fetchAllData("projects", "projects", "projectId").then((res) =>
    setProjects(res)
  );
  // setLoading(false);
};
