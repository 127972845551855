import { Modal } from "antd";
import React from "react";
import "./ForemanNotesModal.scss";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { RichTextEditor } from "../../../../../../commonComponents";
import { InfoText } from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import useTranslate from "../../../../Language/useTranslate";
const ForemanNotesModal = ({
  openNotes,
  setOpenNotes,
  onChangeNotes,
  dayWeEditing,
  userConfiguration,
}) => {
  const { t } = useTranslate();
  return (
    <div>
      <Modal
        {...{
          open: openNotes?.visible || false,
          centered: true,
          title: t("Notes"),
          footer: null,
          closeIcon: <XIcon />,
          onCancel: () => {
            setOpenNotes({});
          },
          className: "foremanNotesModal",
        }}
      >
        <div className="foremanNotesModal-content">
          <InfoText
            {...{
              text: `${t("Hello")} ${userConfiguration?.nameOfUser}, ${t(
                "here you can add notes for the day"
              )}.`,
            }}
          />
          <RichTextEditor
            {...{
              initialValue: !openNotes?.type
                ? dayWeEditing?.foreman?.notes
                : openNotes?.type?.notes || "",
              onChange: (e) => {
                onChangeNotes(e);
              },
              placeholder: "Add description...",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ForemanNotesModal;
