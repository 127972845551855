import "./AccountingConfirmationModal.scss";

import { MondayButton } from "..";
import { Modal } from "antd";
import { Close, WarningTriangle } from "../../SidebarPages/DynamicView/src";
import { Tick } from "../../pages/Settings/settingsComponents/Roles/src";
import { TrashIcon, XIcon } from "../../SidebarPages/Communication/assets";
import { updateDocumentTitle } from "../../../utils";

const CONFIRMATION_QUESTION =
  "You have unsaved changes. Do you want to save them?";
const CONFIRM_BUTTON_TEXT = "Save Changes";
const DISCARD_BUTTON_TEXT = "Discard";
const CANCEL_BUTTON_TEXT = "Cancel";
const TITLE = "Review Changes";
const DARK_MODE = false;
const DISCARD_BUTTON = true;
const SAVE_BUTTON = true;

const AccountingConfirmationModal = ({
  visible,
  setVisible,
  scheduleProp,
  onConfirm = () => {},
  onCancel = () => {},
  onDiscard = () => {},
  text = CONFIRMATION_QUESTION,
  title = TITLE,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  cancelButtonText = CANCEL_BUTTON_TEXT,
  discardButtonText = DISCARD_BUTTON_TEXT,
  zIndex = 1500,
  children,
  darkMode = DARK_MODE,
  discardButton = DISCARD_BUTTON,
  saveButton = SAVE_BUTTON,
  modalClassName = "",
}) => {
  const changeVisible = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  return (
    <Modal
      wrapClassName={
        darkMode
          ? "confirmationModalAccountingDark"
          : "confirmationModalAccountingLight"
      }
      onCancel={changeVisible}
      setVisible={changeVisible}
      closeIcon={<XIcon />}
      open={visible}
      zIndex={zIndex}
      destroyOnClose
      title={title}
      afterOpenChange={(event) => {
        if (typeof title === "string") {
          event && updateDocumentTitle({ newTitle: title });
        }
      }}
      centered
      className={modalClassName}
    >
      <div className="accountingConfirmationModalContainer">
        <div className="iconContainer">
          <WarningTriangle />
        </div>
        <div className="question">{text}</div>
        {children}
        <div className="buttonContainer">
          <MondayButton
            className={!scheduleProp ? "cancelButton" : "mondayButtonRed"}
            onClick={() => {
              onCancel();
              changeVisible();
            }}
            Icon={<XIcon />}
          >
            <div className="textContainer">{cancelButtonText}</div>
          </MondayButton>
          {discardButton && !scheduleProp && (
            <MondayButton
              className="discardButton"
              onClick={() => {
                onDiscard();
                changeVisible();
              }}
              Icon={<TrashIcon />}
            >
              <div className="textContainer">{discardButtonText}</div>
            </MondayButton>
          )}
          {saveButton && (
            <MondayButton
              className="confirmButton"
              onClick={() => {
                onConfirm();
                changeVisible();
              }}
              Icon={<Tick width={19} height={19} />}
            >
              <div className="textContainer">{confirmButtonText}</div>
            </MondayButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AccountingConfirmationModal;
