export const userRolesStepData = [
  {
    title: "Route Access Tab",
    id: "#Route",
  },
  {
    title: "Sidebar Access Tab",
    id: "#Sidebar",
  },
  {
    title: "Notification Preferences Tab",
    id: "#NotificationPreferences",
  },
  {
    title: "View Logs",
    id: ".logsButton",
  },
  {
    title: "Export to PDF",
    id: ".pdfButton",
  },
  {
    title: "Preview all Access Rights",
    id: "#previewButton",
  },
  {
    title: "Save Changes",
    id: "#submitButton",
  },
];
