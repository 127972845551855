import "./ChildrenCollapser.scss";
import { useState } from "react";
import { CollapseArrow } from "../../../../../icons";

const ChildrenCollapser = ({ children, initialValue = false }) => {
  const [collapsed, setCollapsed] = useState(initialValue);

  const changeVisibility = () => setCollapsed((prev) => !prev);

  return (
    !!children && (
      <div className="childrenCollapser">
        {!collapsed ? (
          <div className="commentChildrenToggle" onClick={changeVisibility}>
            <div className="line" />
          </div>
        ) : (
          <div className="showRepliesLabel" onClick={changeVisibility}>
            <CollapseArrow
              style={{ height: "14px" }}
              className="collapseIcon"
            />
            <span>Show Replies</span>
          </div>
        )}

        {!collapsed && <div>{children}</div>}
      </div>
    )
  );
};

export default ChildrenCollapser;
