import { useState } from "react";
import { MondayButton } from "../../../../../../../../../../commonComponents/index";
import { TemplatesIcon } from "../../../../../../../../../Communication/assets/index";
import { DescIcon } from "../../../../../../../../../DynamicView/src/index";
import { useRedux } from "../../../../../../../../../hooks/useRedux";
import TemplateDescModal from "../../../../../../../../../Projects/Accounting/Rentals/RentalsView/components/Header/Components/ControlPanel/Components/ApplyTemplate/RentalGeneralDescription/modal/TemplateDescModal";
import { useSelector } from "react-redux";

export default function DynamicTemplateDesc() {
  const [visible, setVisible] = useState(false);
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [isWritable] = useRedux("paymentIsWritable");

  const changeDataSrc = (newData) => {
    setFakePayment(newData);
  };
  return (
    <div>
      <div
        className="template-holder-container"
        style={{ paddingRight: "20px" }}
      >
        <MondayButton
          disabled={!isWritable}
          className={!isWritable ? "mondayButtonGrey" : "mondayButtonBlue"}
          Icon={<TemplatesIcon />}
          onClick={() => setVisible(true)}
          tooltipCategory="Payments"
          tooltipKey="templates"
        >
          Templates
        </MondayButton>
        <TemplateDescModal
          {...{
            visible,
            dataSrc: fakePayment,
            changeDataSrc,
            title: "Apply Template Description",
            onCancel: () => setVisible(false),
            templateType: "paymentsGeneral",
            savedInKey: "paymentDescription",
            darkMode: isDarkMode,
            lastModified:
              fakePayment?.paymentDescription?.lastModified || Date.now(),
            lastModifiedUser:
              fakePayment?.paymentDescription?.lastModifiedUser ?? "N/A",
          }}
        />
      </div>
    </div>
  );
}
