import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Drawer } from "antd";
import React from "react";
import { useState } from "react";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { useRedux } from "../../../../../SidebarPages/hooks";

function DocumentInfoDrawer() {
  const [open, setOpen] = useState(false);
  return (
    <>
      {" "}
      <div
        className="more_options_icon"
        onClick={() => {
          setOpen(true);
        }}
      >
        <InfoCircleOutlined />{" "}
      </div>
      {open && (
        <Drawer
          {...{
            title: "Document Info",
            placement: "right",
            closable: true,
            onClose: () => {
              setOpen(false);
            },
            visible: open,
            width: "400px",
          }}
        >
          <DrawerContent />
        </Drawer>
      )}
    </>
  );
}

export default DocumentInfoDrawer;

const DrawerContent = () => {
  const [proposalData] = useRedux("proposalData");
  console.log("proposalData", { proposalData });
  const INFO_DATA = getInfoData(proposalData);
  return (
    <div>
      {Object.keys(INFO_DATA).map((key) => {
        return (
          <div>
            <label style={{ color: "gray" }}>{mappingKeys[key]}</label>
            {key !== "estimationId" ? (
              <p style={{ color: "#03AED2" }}>{INFO_DATA[key]}</p>
            ) : (
              <p>
                <a href={`/estimations/${INFO_DATA[key]}`}>Go to Estimate</a>
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

const getInfoData = (proposalData) => {
  const INFO = {
    accountManager: "",
    accountName: "",
    estimateStatus: "",
    projectExecutive: "",
    takeOffCreatedAt: "",
    takeOffUpdatedAt: "",
    takeOffUpdatedBy: "",
    takeOffStatus: "",
    takeOffVersionName: "",
    takeOffCreatedBy: "",
    estimationId: "",
    company: "CORE SCAFFOLD",
  };
  let estimation = proposalData?.estimation || {};
  let takeOff = proposalData?.takeOff || {};
  INFO.accountManager = estimation?.accountManager || "N/A";
  INFO.accountName = estimation?.accountName || "N/A";
  INFO.estimateStatus = estimation?.estSTATUS || "N/A";
  INFO.projectExecutive = estimation?.projectExecutive || "N/A";
  INFO.takeOffCreatedAt =
    dayjsNY(takeOff?.createdAt).format("MM/DD/YYYY") ?? "N/A";
  INFO.takeOffUpdatedAt =
    dayjsNY(takeOff?.lastModifiedAt).format("MM/DD/YYYY") ?? "N/A";

  INFO.takeOffUpdatedBy = takeOff?.lastModifiedBy?.name || "N/A";
  INFO.takeOffStatus = takeOff?.status || "N/A";
  INFO.takeOffVersionName = takeOff?.versionName || "N/A";
  INFO.takeOffCreatedBy = takeOff?.createdBy?.name || "N/A";
  INFO.estimationId = estimation?.estimationId || "";
  return INFO;
};

const mappingKeys = {
  accountManager: "ACCOUNT MANAGER",
  accountName: "CLIENT",
  estimateStatus: "ESTIMATE STATUS",
  projectExecutive: "PROJECT EXECUTIVE",
  takeOffCreatedAt: "TAKE OFF CREATED AT",
  takeOffCreatedBy: "TAKE OFF CREATED BY",
  takeOffUpdatedAt: "TAKE OFF UPDATED AT",
  takeOffUpdatedBy: "TAKE OFF UPDATED BY",
  takeOffStatus: "TAKE OFF STATUS",
  takeOffVersionName: "TAKE OFF VERSION NAME",
  estimationId: "ESTIMATION REFERENCE",
  company: "COMPANY",
};
