export const automationRecurrence = ({
  hour = "",
  minute = "",
  dayOfWeek,
  dayOfMonth,
  timeFormat = "",
}) => {
  let recurrence = "";

  if (!!dayOfMonth && !dayOfWeek) {
    recurrence = `Monthly on ${(dayOfMonth || [])?.join(",")} at `;
  } else if (!!dayOfWeek && !dayOfMonth) {
    recurrence = `Weekly on ${(dayOfWeek || [])?.join(" , ")} at `;
  } else {
    recurrence = "Every Day at ";
  }
  return (
    recurrence + ` ${hour}:${minute.toString().padStart(2, "0")} ${timeFormat}`
  );
};
