import {
  ImportantIcon,
  InfoIcon,
  UnreadIcon,
  WarningIcon,
  TodayIcon,
  WeekIcon,
} from "../src";
const FilterTypes = [
  { title: "Important", Icon: ImportantIcon, color: "#ECDE7D" },
  { title: "Critical", Icon: WarningIcon, color: "#FE4C4A" },
  { title: "Info", Icon: InfoIcon, color: "#5BC0DE" },
  { title: "Unread", Icon: UnreadIcon, color: "#FE8624" },
  { title: "Today", Icon: TodayIcon, color: "#FE4C4A" },
  { title: "This Week", Icon: WeekIcon, color: "#FE4C4A" },
];

export default FilterTypes;
