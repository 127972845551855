import { message } from "antd";
import Swal from "sweetalert2";
import { filterTables } from "../../../../../../utils";
import { notification } from "antd";

export const openNotificationWithIcon = (type) => {
  const api = notification.useNotification()[0];
  api[type]({
    message: "Notification Title",
    description:
      "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
  });
};

export const isApprovedEstimateUsed = async ({
  projectId,
  estimationId,
  versionId,
  estimation,
  allowanceForEdit,
}) => {
  console.log("isApprovedEstimateUsed", projectId, estimationId, versionId);
  let isUsedData = false;
  message.loading("Checking if estimate is used in other places", 2);
  let projectSchedules = await filterTables(
    "scheduling",
    "projectId",
    projectId
  ).then((res) => {
    let estimates = res?.map((e) => {
      Object.keys(e?.toBeScheduled || {}).forEach((key) => {
        if (key === estimationId) {
          message.warning("Estimate is used in project schedules");
          isUsedData = true;
        }
      });
    });

    console.log("estimates", estimates);
  });
  //   if (isUsedData) return true;
  let scheduleOfValues = await filterTables(
    "scheduleOfValues",
    "projectId",
    projectId
  ).then((res) => {
    console.log("scheduleOfValues", res);
    res.forEach((e) => {
      if (e?.estimationsSelected?.includes(estimationId)) {
        message.warning("Estimate is used in schedule of values", 2);
        isUsedData = true;
      }
    });
  });
  // if (isUsedData) return true;
  let rentals = await filterTables(
    "rentals",
    "estimationId",
    estimationId
  ).then((res) => {
    if (res.length > 0) {
      message.warning("Estimate is used in rentals");
      isUsedData = true;
    }
    console.log("rentals", res);
  });

  let hasCharges = estimation?.charges?.length > 0;
  let hasInvoices = estimation?.invoices?.length > 0;
  if (hasCharges || hasInvoices) {
    isUsedData = true;
  }
  console.log("allowanceForEdit", allowanceForEdit);
  if (
    allowanceForEdit?.["Approved Take Off Change"]?.value === true &&
    isUsedData
  ) {
    isUsedData = false;
    Swal.fire({
      title:
        "You were given permission to edit approved take off, but estimate is used in other accordance - Contact admin!",
      icon: "warning",
      width: 1200,
      confirmButtonText:
        "I understand that my changes might NOT be passed to other departments!",

      //   imageWidth: 600,
      //   imageHeight: 400,
      //   confirmButtonText: "I understand that none of my changes were saved!",
      //   imageUrl:
      //     "https://plus.unsplash.com/premium_photo-1682310096066-20c267e20605?q=80&w=2112&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    });
    return false;
  }
  if (isUsedData) {
    message.destroy();
    // message.warning("Estimate is used in charges or invoices");
    Swal.fire({
      title:
        "You cannot change ANY DATA of approved version that has been fully approved and used in other departments - Contact admin!",
      icon: "warning",
      width: 1200,
      confirmButtonText: "I understand that none of my changes were saved!",

      //   imageWidth: 600,
      //   imageHeight: 400,
      //   confirmButtonText: "I understand that none of my changes were saved!",
      //   imageUrl:
      //     "https://plus.unsplash.com/premium_photo-1682310096066-20c267e20605?q=80&w=2112&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    });
    return true;
  }
  Swal.fire({
    title: "SERVICES SAVED IN PROJECT!",
    icon: "success",
    width: 800,
    //   width: 1400,
    //   imageWidth: 600,
    //   imageHeight: 400,
    // confirmButtonText: "I understand that none of my changes were saved!",
    //   imageUrl:
    //     "https://plus.unsplash.com/premium_photo-1682310096066-20c267e20605?q=80&w=2112&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  });
  return isUsedData;
};
