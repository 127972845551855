import { validateForm } from "../../../components/SidebarPages/Fleet/utils";

export const validateFields = async (
  form,
  onSuccessFunc,
  formItemNames = []
) => {
  validateForm(
    form,
    onSuccessFunc,
    () => {
      message.error("Invalid Credentials! Recheck the form fields.");
    },
    formItemNames
  );
};
