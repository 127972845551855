import React from "react";
import {
  BankOutlined,
  CreditCardOutlined,
  DollarOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useSelector } from "react-redux";

import {
  generateInputFields,
  generateCheckInputFields,
  generateWireInputFields,
} from "./helpers";
import { getAllUserAccess } from "../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/components/PaymentTable/utils";
import { NextStepInputs } from "../NextStepInputs";

const paymentMethods = [
  { id: 1, name: "Cash", icon: <DollarOutlined /> },
  { id: 2, name: "Check", icon: <BankOutlined /> },
  { id: 3, name: "Credit Debit", icon: <CreditCardOutlined /> },
  { id: 4, name: "e-Check", icon: <LaptopOutlined /> },
  { id: 5, name: "Wire", icon: <BankOutlined /> },
];

const Payment = ({ inputData, setInputData }) => {
  const isCheck =
    inputData.paymentMethod === "Check" ||
    inputData.paymentMethod === "e-Check";
  const isWire = inputData.paymentMethod === "Wire";

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);

  const receivedBy = programFields.find(
    (field) => field.fieldName === "Payment Options"
  ).fieldOptions;

  const usersWithAccess = getAllUserAccess(userConfiguration, "Payments/View");
  const newReceivedBy = [...receivedBy, ...usersWithAccess];

  const inputFields = generateInputFields();
  const inputCheckFields = generateCheckInputFields({ newReceivedBy });
  const inputWireFields = generateWireInputFields();

  function onInputChangeHandler(value, key) {
    setInputData((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <>
      <NextStepInputs {...{ inputData, setInputData, inputFields }} />

      <div style={{ display: "flex", gap: "10px" }}>
        {paymentMethods.map((method) => (
          <Button
            key={method.id}
            icon={method.icon}
            style={
              inputData.paymentMethod === method.name && {
                background: "#1264a3",
                color: "#ffffff",
              }
            }
            onClick={() => onInputChangeHandler(method.name, "paymentMethod")}
          >
            {method.name}
          </Button>
        ))}
      </div>
      {isWire && (
        <NextStepInputs
          {...{ inputData, setInputData, inputFields: inputWireFields }}
        />
      )}
      {isCheck && (
        <NextStepInputs
          {...{ inputData, setInputData, inputFields: inputCheckFields }}
        />
      )}
    </>
  );
};

export default Payment;
