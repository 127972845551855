import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const projects = ({ tables, programFields }) => {
  return {
    projectStatus: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Project Status")
        ?.fieldOptions?.map(({ statusName }) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Projects?.filter((a) => a?.projectStatus === statusName)
              .length /
              tables?.Projects?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: statusName,
          done: tables?.Projects?.filter((a) => a?.projectStatus === statusName)
            .length,
        })),
    }), //projectStatus
    attorney: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Projects, "attorney")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Projects?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }), //attorney
    expeditor: () => ({
      content: uniq(tables?.Projects?.map((a) => a?.expeditor))?.map(
        (expeditor) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Projects?.filter((a) => a?.expeditor === expeditor)
              .length /
              tables?.Projects?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: expeditor,
          done: tables?.Projects?.filter((a) => a?.expeditor === expeditor)
            .length,
        })
      ),
    }), //expeditor

    projectExecutive: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Projects, "projectExecutive")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Projects?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    projectManager: () => ({
      content: Object.entries(
        repeatedElementSum(
          tables?.Projects?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Projects?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
  }; //Projects
};
