import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const columnDefs = [
  {
    headerName: "Start",
    field: "start",
    filter: "agDateColumnFilter",
    sort: "desc",
    cellRenderer({ value }) {
      if (!value) {
        return "";
      }

      return parseInTz(value).format("MMM DD, YYYY");
    },
  },
  {
    headerName: "End",
    field: "end",
    filter: "agDateColumnFilter",
    cellRenderer({ value, data }) {
      if (!value) {
        return "CURRENT TIMELINE";
      }

      return parseInTz(value).format("MMM DD, YYYY");
    },
  },
  {
    headerName: "Reason",
    field: "reason",
  },
];

export default columnDefs;
