import {
  ClientModal,
  ContactModal,
  EstimationModal,
  LeadModal,
  OpportunityModal,
  PermitDrawingModal,
  PreClaimsModal,
  PreHearingsModal,
  ProjectModal,
  NewRequest as RequestFormModal,
} from "src/components/Header/forms";
import NewSchedule from "src/components/Header/forms/Scheduling/NewSchedule/NewSchedule";
import { NewPayment } from "src/components/SidebarPages/Accounting/Tabs/Payments/components";
import InvoiceModal from "src/components/SidebarPages/Accounting/components/CreateInvoice/InvoiceModal";
import NewDocumentationModal from "src/components/SidebarPages/Documentation/View/components/NewDocumentationModal/NewDocumentationModal";
import { NewDispatchModal } from "src/components/SidebarPages/Fleet/Dispatch/modals";
import { NewDriverModal } from "src/components/SidebarPages/Fleet/driver/modals";
import { NewFleetModal } from "src/components/SidebarPages/Fleet/fleet/modals";
import { EditActivityModal } from "src/components/SidebarPages/Fleet/view/pageView/components/SingleTruckActivity/modals";
import { VehicleInspectionModal } from "src/components/SidebarPages/Fleet/view/pageView/components/SingleTruckInspections/modals";
import { MaintenanceModal } from "src/components/SidebarPages/Fleet/view/pageView/components/SingleTruckMaintenance/modals";
import { NewViolationModal } from "src/components/SidebarPages/Fleet/violations/modals";
import NewFleetInventory from "src/components/SidebarPages/FleetsMaintenances/Tabs/FleetInventory/Modals/NewFleetInventory";
import NewNoteModal from "src/components/SidebarPages/Notes/NoteModals/NewNoteModal";
import NewApplication from "src/components/SidebarPages/Projects/Accounting/Applications/ApplicationsTab/NewApplication/NewApplication";
import NewRentals from "src/components/SidebarPages/Projects/Accounting/Rentals/RentalsTab/NewRentals/NewRentals";
import NewScheduleOfValue from "src/components/SidebarPages/Projects/Accounting/ScheduleOfValues/ScheduleOfValueTab/NewScheduleOfValue/NewScheduleOfValue";
import PreIncidentsModal from "src/components/SidebarPages/Projects/Tabs/Safety/components/IncidentsTab/PreIncidentModal";
import NewSafetyInspection from "src/components/SidebarPages/Safety/Inspections/Modal/NewSafetyInspection";
import PreSafetyInspectionModal from "src/components/SidebarPages/Safety/Inspections/Modal/PreSafetyInspectionModal";
import PreSafetyModal from "src/components/SidebarPages/Safety/SafetyModal/components/PreSafetyModal";
import NewTaskModal from "src/components/SidebarPages/TaskManager/Tasks/TasksModals/NewTask/NewTaskModal";
import PreTodoModal from "src/components/SidebarPages/ToDos/forms/PreTodoModal";
import NewChargeModal from "src/components/Sidebars/components/NewShortcutContent/modals/Accounting/Charges/NewChargeModal";
import NewInspectionShortcut from "src/components/Sidebars/components/NewShortcutContent/modals/Inspections/NewInspectionShortcut";
import { CrewsHeader } from "src/components/pages/Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";
import NewGeofenceShortcut from "src/components/pages/Settings/settingsComponents/GeofenceConfigurations/shortcutFenceCreation/NewGeofenceShortcut";
import { IncidentModal } from "../../../SidebarPages/Fleet/view/pageView/components/SingleTruckIncidents/modals";
import NewToDo from "../../../SidebarPages/ToDos/forms/NewToDo";

export const newStepComponents = {
  InvoiceModal,
  NewRentals,
  NewPayment,
  NewScheduleOfValue,
  NewApplication,
  NewChargeModal,
  LeadModal,
  OpportunityModal,
  EstimationModal,
  ContactModal,
  ClientModal,
  NewFleetModal,
  NewDispatchModal,
  NewViolationModal,
  NewDriverModal,
  MaintenanceModal,
  EditActivityModal,
  VehicleInspectionModal,
  NewFleetInventory,
  ProjectModal,
  // NewSchedule,
  PermitDrawingModal,
  NewInspectionShortcut,
  NewDocumentationModal,
  RequestFormModal,
  NewTaskModal,
  PreClaimsModal,
  PreHearingsModal,
  NewNoteModal,
  CrewsHeader,
  PreTodoModal,
  NewGeofenceShortcut,
  PreIncidentsModal,
  IncidentModal,
  PreSafetyInspectionModal,
  NewSafetyInspection,
  PreSafetyModal,
  NewToDo,
};
