//@ts-check
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../utils";
import RentPerServiceInEstimation from "../RentPerServiceInEstimation";

export const openBalanceStatusPanel = ({
  rowData,
  totalities,
  servicesRentalsPerEstimation,
  payments = [],
}) => {
  console.log("openBalanceStatusPanel", { totalities, payments });
  const unappliedPayment = calculateUnappliedAmount(totalities, payments);

  return [
    {
      key: "Total Estimates",
      statusName: "Total Estimates",
      value: formatCurrency(totalities?.totalEstimates),
      statusColor: "#F6CB52",
    },
    {
      key: "Rentals Invoiced",
      statusName: "Rentals Invoicing",
      value: formatCurrency(forceToNumber(totalities?.totalRentals)),

      // value: (
      //   <RentPerServiceInEstimation services={servicesRentalsPerEstimation} />
      // ),
      statusColor: "#ABB12A",
    },
    {
      key: "Total Invoiced",
      statusName: "Total Invoiced",
      value: formatCurrency(
        rowData?.reduce(
          (acc, curr) => acc + forceToNumber(curr?.totalInvoiceAmount || 0),
          0
        ) || 0
      ),
      statusColor: "#00A464",
    },
    {
      key: "Unapplied Amount",
      statusName: "Unapplied Amount",
      value: formatCurrency(unappliedPayment),
      statusColor: "#E9C466",
    },
    {
      key: "Invoices Open Balance",
      statusName: "Invoices Open Balance",
      value: formatCurrency(
        rowData?.reduce(
          (acc, curr) => acc + forceToNumber(curr?.openBalance || 0),
          0
        ) || 0
      ),
      statusColor: "#FF6F61",
    },
    {
      key: "Open Balance",
      statusName: "Open Balance",
      value: formatCurrency(
        forceToNumber(
          rowData?.reduce((acc, curr) => {
            return acc + curr.openBalance;
          }, 0)
        ) -
          // forceToNumber(
          //   totalities?.appliedAmount - totalities?.totalInvoicedPaid
          // ) +
          forceToNumber(unappliedPayment) -
          forceToNumber(totalities?.unAppliedCredit)
      ),
      statusColor: "#F04F4E",
    },
    {
      key: "Unapplied Credit",
      statusName: "Available Credit",
      value: formatCurrency(forceToNumber(totalities?.unAppliedCredit)),
      statusColor: "#FFA500",
    },
    // {
    //   key: "Applied Credit",
    //   statusName: "Used Credit",
    //   value: formatCurrency(forceToNumber(totalities?.appliedCredit)),
    //   statusColor: "#4CAF50",
    // },
    // {
    //   key: "Total Credit",
    //   statusName: "Credit Sum",
    //   value: formatCurrency(forceToNumber(totalities?.totalCredit)),
    //   statusColor: "#2196F3",
    // },
  ];
};

const calculateUnappliedAmount = (totalities, payments) => {
  console.log("23323312", { payments, totalities });
  let otherUnapplied = payments
    .filter((e) => e?.payments?.length === 0)
    .reduce((acc, curr) => {
      return acc + forceToNumber(curr?.appliedAmount);
    }, 0);
  console.log("otherUnapplied", otherUnapplied);
  return (
    totalities?.appliedAmount -
    totalities?.totalInvoicedPaid +
    forceToNumber(otherUnapplied)
  );
};
