import { fetchAllData } from "../../../../../../../SidebarPages/Fleet/utils/fetchAllData";
import { getVersionService } from "../../../../PricingConfig/utils/getVersionService";

export const checkIfUsed = async (
  estimations,
  setEstimations,
  selectedPricingSchemeId,
  label,
  endProgress
) => {
  let canEdit = true;

  if (estimations.length) {
    estimations.map((estimation) => {
      const versionServices = getVersionService(estimation.versionServices);

      versionServices?.forEach((service) => {
        if (service.value && Array.isArray(service.value)) {
          service.value.map((item) => {
            if (
              item.hasOwnProperty("priceScheme") &&
              item.label === label &&
              item.priceScheme.id === selectedPricingSchemeId
            ) {
              canEdit = false;
            }
          });
        }
      });
    });
  } else {
    await fetchAllData("estimations", "estimations", "estimationId").then(
      (estimationsData) => {
        setEstimations(estimationsData);

        estimationsData.map((estimation) => {
          const versionServices = getVersionService(estimation.versionServices);

          versionServices?.forEach((service) => {
            if (service.value && Array.isArray(service.value)) {
              service.value.map((item) => {
                if (
                  item.hasOwnProperty("priceScheme") &&
                  item.label === label &&
                  item.priceScheme.id === selectedPricingSchemeId
                ) {
                  canEdit = false;
                }
              });
            }
          });
        });

        endProgress();
      }
    );
  }

  return !canEdit;
};
