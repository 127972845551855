import types from "../../types";
import { YardType } from "src/components/SidebarPages/FleetMaintenanceView/types";

/**
 * @typedef YardState
 * @property {YardType[]} yards
 */

/**
 * @typedef {YardType[]|Partial<YardType>&{yardId: string}} YardActions
 */

/**
 * @typedef {{type: keyof types, payload: YardActions}} ActionType
 */

/** @type {YardState} */
const initialState = {
  yards: [],
};

/**
 *
 * @param {YardState} state
 * @param {ActionType} action
 */
const yardsReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.SET_YARDS:
      /** @type {Extract<YardActions, Array>} */
      let p1 = payload;

      return {
        ...state,
        yards: p1,
      };

    case types.CHANGE_YARD_CONFIG:
      /** @type { Extract<YardActions, {yardId: string}> } */
      let p2 = action.payload;

      return {
        ...state,
        yards: state.yards.map((e) => {
          if (e.yardId === p2.yardId) {
            return {
              ...e,
              ...p2,
            };
          }

          return e;
        }),
      };

    default:
      return state;
  }
};

export default yardsReducer;
