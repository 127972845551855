import { compareNestedObjects } from "./compareUtils";

export const getRowData = (originalObject, objectToCompare) => {
  const rowData = [];

  originalObject.forEach((obj1) => {
    const matchingObj2 =
      objectToCompare && objectToCompare.find((obj2) => obj2.id === obj1.id);

    if (matchingObj2) {
      const isMatching = compareNestedObjects(obj1, matchingObj2);

      if (isMatching) {
        rowData.push({ ...obj1, matched: true });
      } else {
        rowData.push({ ...obj1, matched: false });
      }
    } else {
      rowData.push({ ...obj1, matched: false });
    }
  });

  // Parse HTML strings in rowData
  rowData.forEach((row) => {
    for (let key in row) {
      if (typeof row[key] === "string" && row[key].startsWith("<")) {
        const regex = /(<([^>]+)>)/gi;
        row[key] = row[key].replace(regex, "");
      }
    }
  });

  return rowData;
};

export const getColumnDefs = (originalObject, objectToCompare) => {
  const object = [...originalObject, ...objectToCompare];

  if (object.length > 0) {
    return Object.keys(object[0]).map((key) => ({
      headerName: key,
      field: key,
      cellStyle: (params) => {
        let objectToCompareValue = objectToCompare?.[params.rowIndex]?.[key];
        if (
          typeof params.value !== "object" &&
          typeof objectToCompareValue !== "object"
        ) {
          if (
            typeof objectToCompareValue === "string" &&
            objectToCompareValue.startsWith("<")
          ) {
            const regex = /(<([^>]+)>)/gi;
            objectToCompareValue = objectToCompareValue?.replace(regex, "");
          }
          return params.value !== objectToCompareValue
            ? { backgroundColor: "#4ee54e" }
            : null;
        }
      },
    }));
  }
  return [];
};
