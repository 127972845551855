import { Form, Tooltip } from "antd";

import {
  ClientsWhite,
  SubcontractorsWhite,
} from "../../../../../../../../../icons";
import {
  PdfWhiteIcon,
  PieChartWhite,
  ExcelIconWhite,
} from "../../../../../../../../../assets";
import { MondayButton } from "../../../../../../../../commonComponents";
import { FilterIcon } from "../../../../../../../../SidebarPages/BasePage/src";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";

function LastStepHeader({
  form,
  degName,
  degStatus,
  rowToEdit,
  degPayrolls,
  setFilterOpen,
  setCategoryCost,
  setTeamsCostVisible,
  exportOverviewToPdf,
  exportOverviewToExcel,
  setCostDispersionModal,
}) {
  return (
    <div className="deg-actions">
      <div className="headerFiler">
        <MondayButton
          Icon={<FilterIcon />}
          className="mondayButtonBlue"
          data-testid="overview-filter-btn"
          tooltipKey={"Overview Table Filter"}
          onClick={() => setFilterOpen((prev) => !prev)}
        >
          Open Filters
        </MondayButton>
        <MondayButton
          {...{
            Icon: <PieChartWhite height={20} width={20} fill={"white"} />,
            onClick: () => setCostDispersionModal(true),
            ["data-testid"]: "cost-distribution-btn",
            className: "mondayButtonBlue",
          }}
        >
          Cost Distribution
        </MondayButton>
        <MondayButton
          {...{
            Icon: <SubcontractorsWhite height={20} width={20} />,
            onClick: () => setCategoryCost(true),
            ["data-testid"]: "category-cost-btn",
            className: "mondayButtonBlue",
          }}
        >
          Category Cost
        </MondayButton>
        <MondayButton
          {...{
            Icon: <ClientsWhite height={20} width={20} />,
            onClick() {
              setTeamsCostVisible(true);
            },
            className: "mondayButtonBlue",
            ["data-testid"]: "team-cost-btn",
          }}
        >
          Teams Cost
        </MondayButton>
      </div>
      <div
        className="control-buttons"
        style={{
          width: "100%",
          justifyContent: "flex-end",
          padding: "0px 10px",
        }}
      >
        <Tooltip title="Export to PDF">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<PdfWhiteIcon width={15} height={15} />}
            onClick={exportOverviewToPdf}
          >
            {""}
          </MondayButton>
        </Tooltip>
        <Tooltip title="Export to Excel">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<ExcelIconWhite width={15} height={15} />}
            onClick={exportOverviewToExcel}
          >
            {""}
          </MondayButton>
        </Tooltip>
        <Form form={form}>
          <InputComponent
            formItemName={"degName"}
            type="input"
            placeholder="Add DEG name..."
            onChange={(e) => form.setFieldValue("degName", e.target.value)}
            initialValue={degName}
            disabled={degStatus === "Completed"}
            rules={[
              {
                validator: (_, value) => {
                  const existingDegName = degPayrolls.some(
                    (deg) =>
                      deg.degId !== rowToEdit?.degId && deg.degName === value
                  );
                  return existingDegName
                    ? Promise.reject("Deg with this name already exists.")
                    : Promise.resolve();
                },
                message: "Deg with this name already exists.",
              },
            ]}
          />
        </Form>
      </div>
    </div>
  );
}

export default LastStepHeader;
