import { Form, Modal, message } from "antd";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../../../commonComponents";
import { TickIcon } from "../../../../../Roles/src";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPut } from "../../../../../../../../../utils";
import "./ChangeDepartment.scss";
import { ClosedEyeIcon } from "../../../../../../../../../assets";
import { HiddenIcon } from "../../../../../../../../SidebarPages/DynamicView/src";
import { updateOtherUser } from "../../../../../../../../../actions";
import { InfoText } from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { useEditLogs } from "../../../../../../../../../hooks";
import { changePinAndIdleFn } from "./helpers";

function ChangePinAndIdleTime({ visible, setVisible, user }) {
  const [form] = Form.useForm();

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { saveAddedLogs } = useEditLogs();

  const idleOptions = useMemo(() => {
    return (
      programFields.find((prog) => prog.fieldName === "Idle Options")
        ?.fieldOptions || []
    );
  }, [programFields]);

  //get all departments and selected user from userConfiguration
  useEffect(() => {
    form.setFieldValue("pin", user?.pinNumber || "");
    form.setFieldValue("idleTime", user?.idleTimeOut || "");
  }, [user]);

  const footer = [
    {
      children: "Cancel",
      onClick: () => {
        setVisible(false);
      },
      className: "mondayButtonRed",
      Icon: <XIcon />,
      "data-testid": "close-button",
    },
    {
      children: "Save",
      onClick: () =>
        changePinAndIdleFn({
          form,
          user,
          dispatch,
          setButtonDisabled,
          saveAddedLogs,
          idleOptions,
        }),
      disabled: buttonDisabled,
      className: "mondayButtonGreen",
      Icon: <TickIcon width={18} height={18} />,
      "data-testid": "save-button",
    },
  ];

  return (
    <Modal
      centered={true}
      open={visible}
      setVisible={setVisible}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}
      closeIcon={<XIcon />}
      title={user?.nameOfUser}
      className={`changeDepartmentModal ${
        isDarkMode && "changeDepartmentModalDark"
      }`}
      footer={
        <div className="changeDepartmentFooterContainer">
          {footer.map(({ children, ...rest }, i) => (
            <MondayButton key={i} {...rest}>
              {children}
            </MondayButton>
          ))}
        </div>
      }
    >
      <Form form={form}>
        <InfoText
          {...{
            text: "Changing the PIN will require the user to use the new PIN for access verification. Adjusting the idle timeout affects how long the user can remain inactive.",
            style: { marginBottom: 20 },
            isDarkMode,
          }}
        />

        <div className="changeDepartmentInputContainer">
          <InputComponent
            {...{
              formItemName: "pin",
              label: "Pin",
              placeholder: "Edit Pin",
              typePassword: hide,
              className: "PinInput",

              onChange: (e) => {
                if (e.target.value === user.pinNumber) setButtonDisabled(true);
                else setButtonDisabled(false);
              },
              rules: [
                {
                  pattern: /^[0-9]{4}$/,
                  message: "Please enter a valid 4-digit PIN, e.g., 1234.",
                },
              ],
              suffixIcon: !hide ? (
                <ClosedEyeIcon
                  cursor={"pointer"}
                  onClick={() => {
                    {
                      setHide(!hide);
                    }
                  }}
                />
              ) : (
                <HiddenIcon
                  cursor={"pointer"}
                  onClick={() => {
                    {
                      setHide(!hide);
                    }
                  }}
                />
              ),
            }}
          />
          <InputComponent
            {...{
              type: "select",
              formItemName: "idleTime",
              label: "Idle Timeout",
              placeholder: "Change Idle Timeout",
              customOptions: idleOptions,
              allowClear: false,
              rules: [
                {
                  required: true,
                  message: "Please enter a value.",
                },
              ],
              onChange: (e) => {
                if (e === user.idleTimeOut) setButtonDisabled(true);
                else setButtonDisabled(false);
              },
            }}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default ChangePinAndIdleTime;
