import { formatCurrency } from "../../../../utils";

export const charges = ({ tables, programFields }) => {
  const data = tables?.charges?.sort(
    (a, b) =>
      a?.projectAddress?.localeCompare(b?.projectAddress) ||
      a?.chargeNumber - b?.chargeNumber
  );

  return {
    categoryFrom: () => ({
      statusData: data?.map((a) => {
        return {
          label: `${a?.projectAddress} - ChargeNo ${a?.chargeNumber}`,
          value: a?.categoryFrom,
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Charge?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.categoryFrom?.toLowerCase()
              )?.statusColor || "#6D8299",
          date: a?.createdAt,
          custom: [
            {
              "Total Amount": {
                "Charge Amount With Tax": formatCurrency(a?.chargeAmount || 0),
                "Total Tax": formatCurrency(a?.taxAmount || 0),
                "Charge Amount Without Tax": formatCurrency(
                  (a?.chargeAmount || 0) - (a?.taxAmount || 0)
                ),
              },
            },
          ],
        };
      }),
    }),
  };
};
