import { defaultExcelHeaderStyle } from "../../../../../helpers/constants/defaultExcelHeaderStyle";

export const tableHeaders = [
  "Task Title",
  "Task Topic",
  "Task Status",
  "Created By",
  "Created At",
];

export const tableHeadersExcel = [
  { v: "Task Title", s: defaultExcelHeaderStyle },
  { v: "Task Topic", s: defaultExcelHeaderStyle },
  { v: "Task Status", s: defaultExcelHeaderStyle },
  { v: "Created By", s: defaultExcelHeaderStyle },
  { v: "Created At", s: defaultExcelHeaderStyle },
];
