import React, { useEffect, useRef } from "react";
import { JoditEditorComp } from "../../../../commonComponents";
import { useEmailBoxBody } from "../EmailBox/providers/EmailBoxBodyProvider";
import { useAttachments } from "../EmailBox/providers/AttachmentsProvider";

import "./communication-rich-editor.scss";

function CommunicationRichEditor({ toolbarUp, readonly = false }) {
  const { editorContent, setEditorContent, showEditorToolbar } =
    useEmailBoxBody();
  const { attachments } = useAttachments();

  return (
    <div
      className={`communication-rich-editor-container ${
        attachments.length > 0 ? "has-attachments" : ""
      } ${toolbarUp ? "toolbar-up" : ""}`}
    >
      <JoditEditorComp
        content={editorContent}
        setContent={setEditorContent}
        placeholder="Start typing..."
        customConfig={{
          buttons: [
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "brush",
            "fontsize",
            "font",
            "|",
            "align",
            "|",
            "eraser",
            "|",
            "ol",
            "ul",
            "table",
            "|",
            "underline",
            "|",
            "print",
          ],
          disablePlugins: "add-new-line",
        }}
        whiteTheme={true}
        showToolbar={showEditorToolbar}
        readonly={readonly}
      />
    </div>
  );
}

export default CommunicationRichEditor;
