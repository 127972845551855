import uniq from "lodash/uniq";
import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const fleet = (fleetData, updatedDynamicStates, IdHandler) => {
  return {
    fleetYear: () => ({
      title: "Fleet Year",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Fleet",
        field: "fleetYear",
        labels: uniq(fleetData?.map((a) => a?.fleetYear))?.sort(),
        datasets: [
          {
            label: "Fleet",
            data: uniq(fleetData?.map((a) => a?.fleetYear))
              ?.sort()
              ?.map(
                (status) =>
                  updatedDynamicStates?.[IdHandler]?.filter(
                    (a) => a?.fleetYear?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),
            backgroundColor: uniq(fleetData?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }), //fleetYear
    registrationExpDate: () => ({
      title: "Fleet Registration Expire Date",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Fleet",
        field: "registrationExpDate",
        labels: months,
        datasets: [
          {
            label: "Fleet",
            data: (() => {
              let data = [];
              for (let i = 0; i < 12; i++) {
                data = [
                  ...data,
                  updatedDynamicStates?.[IdHandler]?.map(
                    (a) => a?.registrationExpDate
                  )?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
                ];
              }
              return data;
            })(),
            backgroundColor: months.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //registrationExpDate
    inspectionExpDate: () => ({
      title: "Inspection Expire Date",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Fleet",
        field: "inspectionExpDate",
        labels: months,
        datasets: [
          {
            label: "Fleet",
            data: (() => {
              let data = [];
              for (let i = 0; i < 12; i++) {
                data = [
                  ...data,
                  updatedDynamicStates?.[IdHandler]?.map(
                    (a) => a?.inspectionExpDate
                  )?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
                ];
              }
              return data;
            })(),
            backgroundColor: months.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //inspectionExpDate
  }; //Fleet
};
