/**
 * Function that creates the info description prefix element
 * @param {"IDLE"|"STOP"|"FENCE"} type
 * @param {string} info
 * @return {HTMLDivElement}
 */
function createInfoDesc(type, info) {
  const desc = document.createElement("div");
  desc.classList.add("info-description");

  const prefix = document.createElement("div");
  const text = document.createElement("span");
  text.innerText = info;

  if (type === "STOP") {
    prefix.classList.add("marker-stop");
    prefix.innerHTML = "Stop -&nbsp;";
  } else if (type === "IDLE") {
    prefix.classList.add("marker-idle");
    prefix.innerHTML = "Idle -&nbsp;";
  } else {
    if (info.startsWith("Arrive")) {
      prefix.classList.add("location-dot");
      prefix.classList.add("location-arrive");
    } else {
      prefix.classList.add("location-dot");
      prefix.classList.add("location-depart");
    }
  }

  desc.appendChild(prefix);
  desc.appendChild(text);

  return desc;
}

export default createInfoDesc;
