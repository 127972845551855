import { Form } from "antd";
import CustomTransfer from "./CustomTransfer";

const CustomTransferWrapper = ({
  onChange,
  dataSource,
  className,
  label,
  rules = [],
  required = false,
  disabled = false,
  disabledAction,
  ...rest
}) => {
  return (
    <Form.Item
      {...{
        ...rest,
        rules: [
          ...rules,
          required && {
            required: true,
            message: `*${!!label ? label : "This field"} is required!`,
          },
        ].filter((item) => !!item),
      }}
    >
      <CustomTransfer
        {...{
          label,
          disabled,
          onChange,
          dataSource,
          className,
          required,
          disabledAction,
        }}
      />
    </Form.Item>
  );
};

export default CustomTransferWrapper;
