import React, { useContext } from "react";
import "./calendar.css";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import TimeDateContext from "../../TimeDateContext";
import { useEffect } from "react";
import dayjs from "dayjs";
import { dayjsNYTimestamp, dayjsNYDate } from "../../contants/DayjsNY";

const Calendar = ({
  hideButtons = false,
  hideResult = false,
  showOnlyRange = false,
  type,
  startDate,
  endDate,
  allDisabledDays = [],
  setFormChanged = () => {},
}) => {
  const {
    dateTime,
    firstDate,
    isInRange,
    dateRange,
    activeDate,
    secondDate,
    setDateRange,
    selectedDate,
    setFirstDate,
    selectedDate2,
    setSecondDate,
    setDatePicker,
    handleDateRange,
    setSelectedDate,
    setSelectedDate2,
    handleCalendarResult,
    handleChangeActiveDate,
    handleChangeSelectedDate,
  } = useContext(TimeDateContext);
  useEffect(() => {
    if (type) {
      setDatePicker(type);
    }
    if (showOnlyRange) {
      setDateRange(true);
      setFirstDate(new Date());
      setSelectedDate(new Date());
      setSecondDate(dayjs(new Date()).add(1, "days").$d);
      setSelectedDate2(
        dayjs(!!firstDate ? firstDate : new Date()).add(1, "days").$d
      );
    }
  }, [showOnlyRange]);

  const getHeader = () => {
    return (
      <div className="datePickerHeader">
        <p className="currentMonth">{dayjs(activeDate).format("YYYY MMMM")}</p>
        <div className="leftRight-buttons">
          <CaretLeftOutlined
            className="navIcon"
            onClick={() =>
              handleChangeActiveDate(dayjs(activeDate).subtract(1, "month").$d)
            }
          />
          <CaretRightOutlined
            className="navIcon"
            onClick={() =>
              handleChangeActiveDate(dayjs(activeDate).add(1, "month").$d)
            }
          />
        </div>
      </div>
    );
  };

  const getWeekDaysNames = () => {
    const weekStartDate = dayjs(activeDate).startOf("week").$d;
    const weekDays = [];
    for (let day = 0; day < 7; day++) {
      weekDays.push(
        <div className="day weekNames" key={day}>
          {dayjs(dayjs(weekStartDate).add(day, "days").$d).format("ddd")}
        </div>
      );
    }
    return <div className="weekContainer">{weekDays}</div>;
  };

  const generateDatesForCurrentWeek = (date, selectedDate, activeDate) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;
      const titleDate = dayjs(currentDate).format("D");

      week.push(
        <div
          key={day}
          data-testid={
            dayjs(activeDate).isSame(currentDate, "day")
              ? `test${titleDate}`
              : ""
          }
          className={`day ${
            dayjs(activeDate).isSame(currentDate, "month") ? "" : "inactiveDay"
          } ${
            dayjs(selectedDate).isSame(currentDate, "day") ? "selectedDay" : ""
          }
          ${
            dayjs(selectedDate2).isSame(currentDate, "day") && dateRange
              ? "selectedDay"
              : ""
          }
          ${
            isInRange(selectedDate, selectedDate2, currentDate) && dateRange
              ? "betweenDay"
              : ""
          }
          ${
            startDate &&
            (dayjs(currentDate).isBefore(dayjs(startDate))
              ? "disableDates"
              : "")
          }
          ${
            endDate &&
            (dayjs(currentDate).isAfter(dayjs(endDate)) ? "disableDates" : "")
          }
          ${
            allDisabledDays?.length > 0 &&
            allDisabledDays?.find((el) => {
              return dayjs(currentDate).isSame(dayjs(el), "day");
            })
              ? "disableDates"
              : ""
          }

        ${dayjs(new Date()).isSame(currentDate, "day") ? "today" : ""}`}
          onClick={() => {
            setFormChanged(true);
            handleChangeSelectedDate(cloneDate);
          }}
        >
          {dayjs(currentDate).format("D")}
        </div>
      );
      currentDate = dayjs(currentDate);
      currentDate = currentDate.add(1, "days").$d;
    }
    return week;
  };

  const getDates = () => {
    const startOfTheSelectedMonth = dayjs(activeDate).startOf("month").$d;
    const endOfTheSelectedMonth = dayjs(activeDate).endOf("month").$d;
    const startDate = dayjs(startOfTheSelectedMonth).startOf("week").$d;
    const endDate = dayjs(endOfTheSelectedMonth).endOf("month").$d;

    let currentDate = startDate;
    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(
        generateDatesForCurrentWeek(currentDate, selectedDate, activeDate)
      );
      currentDate = dayjs(currentDate);
      currentDate = currentDate.add(7, "days").$d;
    }
    return <div className="weekContainer2">{allWeeks}</div>;
  };

  return (
    <section className="calendar-container">
      {getHeader()}
      {getWeekDaysNames()}
      {getDates()}
      {/* if user clicked to Date&Time then we give props to Date component with hiddenButtons and hideResult,
            the DateTimePicker component has its own buttons and result areas so we dont need to display them two times.
          We check also for dateRange so if user clicked for range we can show them in result dates that user clicked otherwise we show only one date. */}
      {!hideButtons ? (
        <div className="buttons">
          <div className="okCancel-btn">
            {!showOnlyRange ? (
              <button
                className="dateRange-btn"
                onClick={() => {
                  console.log("click 1");
                  setFormChanged(true);
                  handleDateRange();
                }}
              >
                Date Range
              </button>
            ) : null}

            <button className="cancel-btn">Cancel</button>
            {dateRange && (!firstDate || !secondDate) ? (
              <button className="ok-btn okBtn-disable">OK</button>
            ) : (
              <buttona
                className="ok-btn"
                onClick={() => {
                  console.log("click 2");
                  setFormChanged(true);
                  handleCalendarResult(selectedDate);
                }}
              >
                OK
              </buttona>
            )}
          </div>
        </div>
      ) : null}
      {/* {dateRange ? (
        <div className="dateRange-inputs">
          {/* Add options to show date div */}
      {/* <div className="showDate">
            {firstDate ? (
              firstDate.toDateString()
            ) : (
              <p className="addDate-paragraph">Add date</p>
            )}
          </div>
          <p>to</p>
          <div className="showDate">
            {secondDate ? (
              secondDate.toDateString()
            ) : (
              <p className="addDate-paragraph">Add date</p>
            )}
          </div>
        </div>
      ) : null} */}
      {/* } */}
      {!hideResult &&
        (dateRange ? (
          <div className="showCalendarResult">
            <h4>
              <span>First Date:</span>
              {firstDate ? (
                <p>
                  {dayjsNYDate(firstDate)
                    .format("YYYY-MMM-DD HH:mm:ss")
                    .toString()}
                  {/* You should use format in order to conver time correctly to timezone */}
                </p>
              ) : (
                <p>No date selectd</p>
              )}

              <span>Second Date:</span>
              {secondDate ? (
                <p>
                  {dayjsNYDate(secondDate)
                    .format("YYYY-MMM-DD HH:mm:ss")
                    .toString()}
                  {/* You should use format in order to conver time correctly to timezone */}
                </p>
              ) : (
                <p>No date selectd</p>
              )}
            </h4>
            <h4>
              <span>First Date Timestamp:</span>
              {firstDate ? (
                <p data-testid="result">
                  {dayjsNYTimestamp(firstDate).valueOf()}
                </p>
              ) : (
                <p>No date selectd</p>
              )}

              <span>Second Date Timestamp:</span>
              {secondDate ? (
                <p data-testid="result">
                  {dayjsNYTimestamp(secondDate).valueOf()}
                </p>
              ) : (
                <p>No date selectd</p>
              )}
            </h4>
          </div>
        ) : (
          <div className="showCalendarResult">
            <h4>
              <span>Date:</span>
              <p>
                {dayjsNYDate(dateTime)
                  .format("YYYY-MMM-DD HH:mm:ss")
                  .toString()}
                {/* You should use format in order to conver time correctly to timezone */}
              </p>
            </h4>
            <h4>
              <span>Timestamp:</span>
              <p data-testid="result">{dayjsNYTimestamp(dateTime).valueOf()}</p>
            </h4>
          </div>
        ))}
    </section>
  );
};

export default Calendar;
