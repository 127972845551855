import { Input, Select, message } from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  AdvancedFilters,
  MondayButton,
  SwitchComponent,
  WithTooltip,
} from "../../../../commonComponents";
import { ClearFilterIcon } from "../../../DynamicView/src";
import LoadAllData from "../LoadAllData/LoadAllData";
import { useSelector } from "react-redux";
import { debounceSearch } from "../../../../../utils";
import { FilterIcon } from "../../src";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets";
import { useAdvancedFilterContext } from "../../../../commonComponents/AdvancedFilters/context";
import SaveFiltersModal from "../SaveFiltersModal/SaveFiltersModal";
import { initialFilters } from "../../../../commonComponents/AdvancedFilters/utils";
import { getSearchConfig } from "../AgGridFilters/dynamicSearch";
import FiltersModal from "src/components/commonComponents/RowDataGridModal/components/FiltersModal";

function splitAndCapitalize(inputArray) {
  return inputArray
    .filter((input) => input !== "teamsConfiguration")
    .map((value) => {
      const words = value.split(/(?=[A-Z])/); // Split at uppercase letters
      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return { label: capitalizedWords.join(" "), value };
    });
}

const AgGridFilters = ({
  title,
  panelObject,
  filtersObject,
  filtersData,
  customFooterData,
  panelFilter,
  gridApi,
  setPanelFilter,
  allowFetchSearch,
  gridRef,
  setFiltersData,
  searchTableHandler,
  filterSearchData,
  clearFilterBtn,
  detailsCell,
  newColumnDefs,
  isDarkMode,
  showSearchInput = true,
  onDebounceChange = () => {},
  setRowData,
  rowData,
  columnApi,
  templateOptions,
  saveFiltersModal,
  customFilterTitle,
  setSaveFiltersModal,
  setTemplateOptions,
  isAgGridFilterActive,
  setIsAgGridFilterActive,
  hasFetchSearchFromDb,
  userConfiguration,
  tooltipCategory,
}) => {
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const [search, setSearch] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [generalFilters, setGeneralFilters] = useState();

  const newTitle = title === "Receive Payments" ? "Payments" : title;
  const selectedCategory = topicCategories?.find((category) =>
    category.categoryName === newTitle
      ? category.categoryName === newTitle
      : category.prefix === newTitle
  );

  const filterKeys = [];

  const [filterKey, setFilterKey] = useState(
    filterKeys.length > 0 ? filterKeys[0].value : selectedCategory?.rowName
  );

  const { filters, setFilters, resetFilters } = useAdvancedFilterContext();

  const clearFilterEmptyValues = (filter) => {
    return Object.keys(filter).reduce((acc, key) => {
      if (!!filter[key]) {
        acc[key] = filter[key];
      }
      return acc;
    }, {});
  };

  const onFilterTextChange = (e) => {
    if (allowFetchSearch) {
      gridRef.current.api.showLoadingOverlay();
      if (!!search) {
        const currentFilters = clearFilterEmptyValues(filtersData);
        const filters = {
          ...currentFilters,
          [filterKey]: search,
        };
        setFilters(initialFilters);
        searchTableHandler(filters, gridApi);
        setFiltersData(filters);
      } else {
        const updatedFiltersData = { ...filtersData };
        delete updatedFiltersData[filterKey];
        setFiltersData(updatedFiltersData);
        filterSearchData(updatedFiltersData, true);
      }
    } else {
      if (!!detailsCell && !!gridApi) {
        gridApi?.forEachDetailGridInfo?.((params) => {
          params.api.setQuickFilter(search);
        });
      } else {
        gridApi?.setQuickFilter(search);
      }
    }
    // autoSizeAllColumns(false);
  };

  const clearFiltersHandler = () => {
    setTimeout(() => {
      clearFilterBtn();
      setSearch("");
      setGeneralFilters(null);
      if (setIsAgGridFilterActive) setIsAgGridFilterActive(false);
      resetFilters(setRowData);
    }, 1000);
  };

  useEffect(() => {
    if (!!search || !allowFetchSearch) onFilterTextChange();
  }, [search]);

  const onChangeHandler = (e) => {
    const { value } = e.target;
    // setSearch(value);
    if (hasFetchSearchFromDb) {
      // onDebounceChange(value);
      getSearchConfig(title, value, gridApi, setRowData, userConfiguration);
    } else {
      setSearch(value);
    }
  };

  const debouncedChange = debounceSearch(onChangeHandler, 300);

  const isFilterActive =
    filters?.some((filter) =>
      filter?.conditions?.some((condition) => condition?.value !== "")
    ) ||
    keys(filtersData)?.length > 0 ||
    keys(filtersData)?.find((e) => filtersData[e] !== null) !== undefined ||
    isAgGridFilterActive;

  return (
    <>
      {filtersObject.length !== 0 && (
        <>
          <MondayButton
            className="filterButton mondayButtonBlue"
            onClick={() => {
              setIsFiltersOpen(
                selectedCategory?.hasAdvancedFilters
                  ? "advancedFilters"
                  : "generalFilters"
              );
            }}
            Icon={<FilterIcon />}
            tooltipCategory={tooltipCategory}
            tooltipKey="openFilters"
          >
            Open Filter
          </MondayButton>
          {isFilterActive && (
            <div style={{ marginLeft: !showSearchInput ? "8px" : "0px" }}>
              <MondayButton
                onClick={clearFiltersHandler}
                className={
                  !isFilterActive
                    ? "clearButton "
                    : "clearButtonReverse mondayButtonRed"
                }
                Icon={<ClearFilterIcon />}
                tooltipCategory={tooltipCategory}
                tooltipKey="clearFilters"
              >
                Clear Filter
              </MondayButton>
            </div>
          )}
          {showSearchInput && (
            <WithTooltip tooltipCategory={tooltipCategory} tooltipKey="search">
              <div className="search-Div">
                <Input
                  // value={search}
                  // defaultValue={search}
                  className="search-comp"
                  placeholder={
                    allowFetchSearch
                      ? "Search..."
                      : "Search the current table data..."
                  }
                  allowClear
                  autoFocus={true}
                  prefix={<SearchOutlined className="SearchLogoIcon" />}
                  onChange={debouncedChange}
                  addonAfter={
                    filterKeys.length && (
                      <Select
                        disabled={allowFetchSearch}
                        value={filterKey}
                        allowClear={!allowFetchSearch}
                        className="select-comp"
                        placeholder="Select field to filter"
                        popupClassName={isDarkMode && `darkDropDown`}
                        onClear={() => setFilterKey()}
                        suffixIcon={<DropDownArrow />}
                        onSelect={(e) => {
                          setFilterKey(e);
                          setFiltersData({});
                        }}
                        options={filterKeys || []}
                      />
                    )
                  }
                />
              </div>
            </WithTooltip>
          )}
          {!!customFooterData?.fetchAllData && (
            <LoadAllData
              fetchAllData={customFooterData.fetchAllData}
              isLoading={customFooterData.isLoading}
              isAllFetched={customFooterData.isAllFetched}
              tooltipCategory={tooltipCategory}
            />
          )}

          {!!panelFilter?.visible && (
            <SwitchComponent
              label={panelFilter.status}
              checked={panelFilter.checked}
              onChange={(e) => {
                // refresh cells if columndefs changed
                if (panelFilter) {
                  if (gridApi) {
                    gridApi?.setColumnDefs(newColumnDefs);

                    gridApi.refreshCells({
                      force: true,
                      columns: panelObject.columns,
                    });
                  }
                }
                setPanelFilter(e);
                clearFiltersHandler();
              }}
              tooltipCategory={tooltipCategory}
            />
          )}
          {isFiltersOpen === "advancedFilters" ? (
            <AdvancedFilters
              {...{
                title,
                isOpen: true,
                rowData,
                gridApi,
                setRowData,
                filtersObject,
                setIsFiltersOpen,
              }}
            />
          ) : isFiltersOpen === "generalFilters" ? (
            <FiltersModal
              {...{
                title,
                showFilterModal: true,
                setShowFilterModal: setIsFiltersOpen,
                appliedFilters: generalFilters,
                setAppliedFilters: (appliedFilters) => {
                  appliedFilters === "clearFilters"
                    ? clearFiltersHandler()
                    : setGeneralFilters(appliedFilters);
                },
                gridApi,
                filtersObject,
                rowData,
              }}
            />
          ) : (
            <></>
          )}
          {saveFiltersModal && (
            <SaveFiltersModal
              {...{
                gridApi,
                columnApi,
                templateOptions,
                saveFiltersModal,
                customFilterTitle,
                setSaveFiltersModal,
                setTemplateOptions,
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default AgGridFilters;
