export const rentals = (
  updatedDynamicStates,
  IdHandler,
  programFields,
  rentalsData
) => {
  return {
    rentalStatus: () => ({
      title: "Rentals - Category From",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Rentals",
        field: "rentalStatus",
        secondaryField: "projectAddress",
        // field: "applicationCompany",
        // secondaryField: "applicationStatus",
        filterField: [],
        id: IdHandler,
        statusData: rentalsData?.map((a) => {
          return {
            label: `${a?.projectAddress} - ${a?.rentalNumber}`,
            value: a?.applicationStatus,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Rentals?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.applicationStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "795px",
    }),
  };
};
