import React from "react";
import { MondayButton } from "../index";
import { ReactComponent as WarningIcon } from "../../../icons/warningTriangle.svg";
import {
  Tick,
  OpenInNewTabIcon,
} from "../../pages/Settings/settingsComponents/Roles/src";
import WarningModal from "../WarningModal/WarningModal";
import "./OkWarningModal.styles.scss";
import { useSelector } from "react-redux";

export const OkWarningModal = ({
  isOpenedModal,
  onButtonClickHandler,
  onCloseClickHandler,
  title,
}) => {
  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onButtonClickHandler();
    }
  };

  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <WarningModal
      visible={isOpenedModal}
      setVisible={onButtonClickHandler}
      title="Warning Message"
      closable={true}
      className="logout-warning-modal"
      onKeyPress={(e) => onEnterPress(e)}
      darkMode={isDarkMode}
    >
      <div className="logout-modal-body">
        <span>
          <WarningIcon />
        </span>
        <p>{title} is already opened in another tab!</p>
        <div className="ok-warning-buttons">
          <MondayButton onClick={onButtonClickHandler} Icon={<Tick />}>
            Ok
          </MondayButton>
          <MondayButton
            onClick={onCloseClickHandler}
            Icon={<OpenInNewTabIcon />}
            className="close-tab"
          >
            Close other tab
          </MondayButton>
        </div>
      </div>
    </WarningModal>
  );
};
