// It should be used in the following way:
// Formula should be multiplied with number of floors if they have the same dimensions\
export const FORMULAS_HOIST_ADDONS = {
  "Common Platform": {
    units: 1,
    unitPrice: 1000,
    formula: ["units", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    depth: 0,
    note: "",
    totalPrice: 0,
    additionalRent: 0,
  },
  "Shoring under runbacks": {
    units: 1,
    unitPrice: 1000,
    formula: ["units", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    totalPrice: 0,
    additionalRent: 0,
  },
  "Loading Dock": {
    units: 1,
    unitPrice: 1000,
    formula: [
      "(",
      "(",
      "length",
      "*",
      "width",
      ")",
      "-",
      "160",
      ")",
      "*",
      "unitPrice",
    ],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    additionalRent: 0,
    totalPrice: 0,
  },
  Runbacks: {
    units: 1,
    unitPrice: 1000,
    formula: ["units", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    additionalRent: 0,
    totalPrice: 0,
  },
  "Inclided Ramp": {
    units: 1,
    unitPrice: 1000,
    formula: ["units", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    additionalRent: 0,
    totalPrice: 0,
  },
  "Plywood Enclosure": {
    units: 1,
    unitPrice: 1000,
    formula: ["length", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    additionalRent: 0,
    totalPrice: 0,
  },
  "Overhead Protection": {
    units: 1,
    unitPrice: 1000,
    formula: ["units", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    additionalRent: 0,
    totalPrice: 0,
  },
  "Steel Angle": {
    units: 1,
    unitPrice: 1000,
    formula: ["units", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    additionalRent: 0,
    totalPrice: 0,
  },
  Shoring: {
    units: 1,
    unitPrice: 1000,
    formula: ["units", "*", "unitPrice"],
    rent: 0,
    length: 0,
    height: 0,
    width: 0,
    note: "",
    depth: 0,
    additionalRent: 0,
    totalPrice: 0,
  },
};
