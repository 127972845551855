import { useState } from "react";
import { Tooltip } from "antd";
import "./EventsPreview.scss";
import { AddIcon } from "../../assets/index";
import { LogIn } from "../../../../../../assets";
import { LogoutIcon } from "../../../../../pages/Settings/settingsComponents/AllUsers/assets";
import AddEventModal from "../addNewEvent/AddEventModal";
import { InfoCircleFilled } from "@ant-design/icons";
import {
  ExportOrEmailDropdown,
  MondayButton,
} from "../../../../../commonComponents";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "../../utils/exportPdfExcel";
import { useSelector } from "react-redux";
import { useRedux } from "../../../../../SidebarPages/hooks";
import useExportHeadersModal from "../../../../../../hooks/useExportHeadersModal";
import PdfHeadersModal from "../../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { tableHeaders } from "../../constants/tableHeaders";
import AdvancedExportModal from "../advancedExport/AdvancedExportModal";

function PreviewHeader({ userDetails, handleChangeUser, handleSignOutUser }) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { base64 } = useSelector((state) => state.base64);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);

  const [events] = useRedux("calendarEvents");

  const filteredEvents =
    events?.filter((event) => event.hasOwnProperty("summary")) || [];

  //new event modal
  const handleModalVisible = (val) => {
    setOpenEventModal(val);
  };

  const additionalData = {
    events: filteredEvents,
    base64,
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({ additionalData, onGeneratePDF, exportToExcel });

  return (
    <div className="preview-events-header">
      <div className="user-info-display">
        <div className="info-style">
          <InfoCircleFilled />
        </div>
        {userDetails ? (
          <h3
            style={{
              color: "#323338",
              fontSize: "14px",
            }}
          >
            You are logged in as: {userDetails.email}
          </h3>
        ) : (
          <h3 style={{ color: "#323338", fontSize: "14px", fontWeight: 400 }}>
            Hello {userConfiguration?.nameOfUser},{" "}
            <span style={{ fontWeight: 600 }}>please sign in </span>
            to view events.
          </h3>
        )}
      </div>
      <div className="action-btns">
        {!userDetails ? (
          <MondayButton
            {...{
              onClick: handleChangeUser,
              className: "mondayButtonBlue",
              Icon: <LogIn />,
            }}
          >
            Sign In
          </MondayButton>
        ) : (
          <>
            <ExportOrEmailDropdown
              {...{
                rowData: filteredEvents,
                customForceEnable: true,
                onGeneratePDF: (action, getDocDefinition) =>
                  getDocDefinition
                    ? onGeneratePDF(action, additionalData, getDocDefinition)
                    : getHeadersModal(action, getDocDefinition),
                exportGridToExcel: (getDocDefinition) =>
                  getDocDefinition
                    ? exportToExcel(additionalData, getDocDefinition)
                    : getHeadersModal(false, getDocDefinition, "excel"),
                uploadExcelToDrive: (driveRequest, driveFolderId) =>
                  uploadExcelToDrive(
                    filteredEvents,
                    driveRequest,
                    driveFolderId
                  ),
                defaultDocDefinition: true,
                title: "Events",
                customItems: [
                  {
                    key: "4",
                    label: "Advanced Export Excel",
                    onClick: () => {
                      setExportModalVisible(true);
                    },
                    // onClick: () =>
                    //   onGeneratePDF
                    //     ? onGeneratePDF("download")
                    //     : (setOpenDownloadModal({
                    //         open: true,
                    //         action: "download",
                    //       }),
                    //       setDownloadType("pdf")),
                  },
                ],
              }}
            />
            <Tooltip placement="bottom" trigger="hover" title="Sign Out">
              <span
                className="button user-icon-style logOut"
                onClick={handleSignOutUser}
              >
                <LogoutIcon />
              </span>
            </Tooltip>
            <Tooltip placement="bottom" trigger="hover" title="Add event">
              <span
                className="button add-icon-style"
                onClick={() => {
                  handleModalVisible(true);
                }}
              >
                <AddIcon />
              </span>
            </Tooltip>
          </>
        )}
      </div>
      {openEventModal && (
        <AddEventModal
          {...{
            handleModalVisible,
            openEventModal,
          }}
        />
      )}
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: tableHeaders,
          }}
        />
      )}
      {exportModalVisible && (
        <AdvancedExportModal
          {...{
            visible: exportModalVisible,
            onClose: () => setExportModalVisible(false),
          }}
        />
      )}
    </div>
  );
}

export default PreviewHeader;
