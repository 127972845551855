import { message } from "antd";
import axios from "axios";
import * as FileSaver from "file-saver";
export function exportPaymentsExcel({
  projectName = "",
  payments = {},
  paymentDetails,
}) {
  console.log("paymentDetails", paymentDetails);
  let fileName = "Payments Received" + ".xlsx";
  const url =
    "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // const url = "http://10.0.0.85:8080/api/export/xlsx";
  const hideLoading = message.loading(
    "File is being processed. Please wait...",
    0
  );
  axios({
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    data: JSON.stringify({
      projectName,
      apiKey: "499c87f0-ca33-11ed-afa1-0242ac120002",
      payments,
      paymentDetails,
      // ...result,
    }),
  })
    .then((response) => {
      hideLoading();
      console.log("response", response);
      FileSaver.saveAs(response.data, fileName);
      message.success("Export successful.");
    })
    .catch((err) => {
      console.log("my error", err);
      hideLoading();
      console.error(err);
      message.error("Something went wrong...");
    });
}
