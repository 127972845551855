const excelHeaders = {
  Inspections: [
    "Inspection Id",
    "Vehicle #",
    "Inspection Type",
    "Inspection Date",
    "Mechanic",
    "Inspection Status",
    "Inspection Notes",
  ],
  WorkOrders: [
    "Work Order Id",
    "Vehicle #",
    "Issue Date",
    "Due Date",
    "Status",
    "Total Hours",
    "Total Labor",
    "Total Parts",
    "WO Total",
    "Assigned To",
    "Comments",
  ],
  Defects: [
    "Defect Id",
    "Defect Name",
    "Vehicle #",
    "Driver",
    "Status",
    "Created At",
    "Severity",
    "Priority",
    "Work Order Id",
    "Inspection Id",
  ],
  Maintenances: ["Vehicle #", "Maintenance Date", "Mechanic", "Status"],
};

export default excelHeaders;
