/**
 * Handles the selection of a safety option.
 *
 * @param {string} val - The selected value.
 * @param {Object} opt - The selected option.
 * @param {Array} safety - The safety options.
 * @param {boolean} clear - Indicates if the selection should be cleared.
 * @param {Function} setFieldsValue - The function to set field values.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 */
const onSafetySelect = ({
  val = "",
  opt = null,
  safety = [],
  clear = false,
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (clear === true) {
    setFieldsValue({ safetyReport: "", team: [] });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: null,
    });

    dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: [],
    });
  } else {
    const {
      projectId = "",
      accountId = "",
      scheduleId = "",
      teamsConfiguration = [],
    } = (safety || [])?.find((el) => el?.safetyApplicationId === val) || {};

    const payload = {
      projectId,
      accountId,
      scheduleId,
      recordId: val,
      recordName: opt?.label,
    };

    // if (opt?.recordName) {
    //   Object.assign(payload, { recordName: opt?.recordName });
    // }

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload,
    });

    if (!!teamsConfiguration?.length) {
      dispatchRequestData({
        type: "SELECTED_TEAM",
        payload: teamsConfiguration?.map((el) => el?.value) || [],
      });
      setFieldsValue({
        team: teamsConfiguration?.map((el) => el?.value) || [],
      });
    }
  }
};

export default onSafetySelect;
