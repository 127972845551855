import { Form } from "antd";
import { useSelector } from "react-redux";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";

import "./AgGridPageInput.scss";

function AgGridPageInput({ api }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [form] = Form.useForm();

  function onChange(e) {
    const totalPages = api.paginationGetTotalPages();
    const value = parseInt(e.target.value) || 0;

    if (value > totalPages) {
      api.paginationGoToPage(totalPages - 1);
      form.setFieldValue("pageInput", totalPages);
    } else if (value < 0) {
      api.paginationGoToPage(0);
      form.setFieldValue("pageInput", 0);
    } else {
      api.paginationGoToPage(value - 1);
      form.setFieldValue("pageInput", value);
    }
  }

  //?adding category labels after pagination count - change gap of .ag-paging-panel to style it properly
  const categoryLabels = {
    leads: "Leads",
    // opportunities: "Opportunities",
    // estimations: "Estimations",
    // projects: "Projects",
    // scheduling: "Schedules",
    default: "",
  };
  const pathArr = window.location.pathname.split("/");
  const cat = pathArr?.length > 2 ? "default" : pathArr[1];
  const label = categoryLabels[cat] ?? categoryLabels.default;

  return (
    <>
      {label ? (
        <span
          style={{
            fontWeight: 700,
            marginRight: 10,
            fontSize: 14,
            color: isDarkMode ? "white" : "#181d1f",
          }}
        >
          {label}
        </span>
      ) : null}
      <Form
        form={form}
        className={`pageInputContainer ${
          isDarkMode && "pageInputContainerDark"
        }`}
      >
        <InputComponent
          type="input"
          typeNumber={true}
          placeholder="Page..."
          onClick={() => form.setFieldValue("pageInput", "")}
          onChange={_.debounce(onChange, 500)}
          formItemName={"pageInput"}
          className={`pageInput`}
        />
      </Form>
    </>
  );
}

export default AgGridPageInput;
