import { useState, useContext, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { GridApi } from "ag-grid-enterprise";

import columnDefs from "./projectsFilterColumn";
import { ProjectMatchContext } from "../../data";
import { MondayButton } from "src/components/commonComponents";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { GridTemplateCard } from "../../../../../../../pages/Settings/settingsComponents/FleetLiveSheets/components";

import "./FilteredProjectsModal.scss";

/**
 * @typedef FilterRow
 * @property {string} address
 * @property {"Off Project"|"Off Schedule"} type
 * @property {boolean} hidden
 */

function FilteredProjectsModal({ open, onCancel }) {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    offProjectData,
    offScheduleData,
    filteredAddresses,
    updateFilteredAddresses,
  } = useContext(ProjectMatchContext);

  const [gridApi, setGridApi] = useState(
    /** @type {GridApi<FilterRow>} */ (undefined)
  );

  const rowData = useMemo(() => {
    //#region ROW DATA

    /** @type {FilterRow[]} */
    const tmpRows = [];
    const opSet = new Set();
    const oSchSet = new Set();

    for (const add of filteredAddresses) {
      if (add.type === "Off Project") {
        opSet.add(add.address);
      } else {
        oSchSet.add(add.address);
      }
    }

    for (const address of Object.keys(offProjectData)) {
      tmpRows.push({
        address,
        hidden: opSet.has(address),
        type: "Off Project",
      });
    }

    for (const address of Object.keys(offScheduleData)) {
      tmpRows.push({
        address,
        hidden: oSchSet.has(address),
        type: "Off Schedule",
      });
    }

    tmpRows.sort((a, b) => a.address.localeCompare(b.address));

    return tmpRows;
  }, [offProjectData, offScheduleData, filteredAddresses]);

  useEffect(() => {
    //#region SELECTION EFFECT
    if (!gridApi || !rowData.length) {
      return;
    }

    gridApi.forEachNode((node) => {
      if (node.data.hidden) {
        node.setSelected(true);
      }
    });
  }, [rowData, gridApi]);

  async function onConfirm() {
    //#region ON CONFIRM
    if (!gridApi) {
      return;
    }

    /** @type {FilterRow[]} */
    const tmpNewRows = [];
    gridApi.forEachNode((node) => {
      if (node.isSelected()) {
        tmpNewRows.push({
          ...node.data,
          hidden: node.isSelected(),
        });
      }
    });

    updateFilteredAddresses(tmpNewRows);

    onCancel();
  }

  //#region JSX
  return (
    <Modal
      {...{
        open,
        onCancel,
        title: "Addresses Filter",
        centered: true,
        className: `filtered-projects-modal ${
          isDarkMode ? "filtered-projects-modal-dark" : ""
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
            }}
            key="cancel"
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onConfirm,
            }}
            key="confirm"
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <GridTemplateCard
        {...{
          rowData,
          columnDefs,
          hideHeader: true,
          gridProps: {
            pagination: false,
            onGridReady(event) {
              setGridApi(event.api);
            },
            rowSelection: "multiple",
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: false,
            rowGroupPanelShow: "onlyWhenGrouping",
          },
        }}
      />
    </Modal>
  );
}

export default FilteredProjectsModal;
