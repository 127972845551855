import { getMonthsTimestamps } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const maintenance = ({ tables }) => {
  return {
    createdAt: () => ({
      content: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              title: months[i],
              taskNumber: tables?.Maintenance?.filter(
                (b) => b.createdAt >= getMonthsTimestamps()[0]
              )?.length,
              taskDone: tables?.Maintenance?.filter(
                (a) =>
                  a.createdAt >= getMonthsTimestamps()[i] &&
                  a.createdAt <= getMonthsTimestamps("end")[i]
              )?.length,
              color: "#71CF48",
              backgroundColor: "#B2DD9B",
              contentHandler: " Maintenance In " + months[i],
              contentsHandler: " Maintenances In " + months[i],
            },
          ]
        }
        return data
      })(),
    }), //createdAt
  } //Maintenance
}
