import uniq from "lodash/uniq";

export const claims = (updatedDynamicStates, IdHandler, programFields) => {
  // console.log("Claims ", (updatedDynamicStates?.[IdHandler] || []));
  // console.log("ID", IdHandler);

  return {
    claimStatus: () => ({
      title: "Claim Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Claims",
        field: "claimStatus",
        secondaryField: "claimCategory",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.claimCategory,
            value: a?.claimStatus,
            color: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Claims?.claimStatus?.find(
                (e) => e.statusName === a?.claimStatus
              )?.statusColor,
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "795px",
    }),

    claimAddress: () => ({
      title: "Claim Address",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Claims",
        field: "claimAddress",
        secondaryField: "",
        id: IdHandler,
        statusData: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
            return {
              label: a?.claimObject?.claimAddress,
              value: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                (e) =>
                  e?.claimObject?.claimAddress === a?.claimObject?.claimAddress
              )?.length,
              color: "#f7f8fa",
              labelColor: "black",
            };
          })
        ),
      },
      id: IdHandler,
      width: "795px",
    }),
  };
};
