import types from '../types'

export const ScheduleDaysDispatch = ({scheduleDays, notesToAddForScheduleDays}) => dispatch => {
    dispatch({
        type: types.SET_SCHEDULE_DAYS,
        payload: {scheduleDays, notesToAddForScheduleDays}
    })
}

export const ScheduleServicesDispatch = toBeScheduled => dispatch => {
    dispatch({
        type: types.SET_TO_BE_SCHEDULED,
        payload: {toBeScheduled}
    })
}

export const scheduleCalendarDispatch = ({rowData, columnDefs}) => dispatch => {
    dispatch({
        type: types.SET_DATA_FOR_SCHEDULE_CALENDAR,
        payload: {rowData, columnDefs}
    })
}

export const scheduleCrewsFleetDispatch = ({fleetInfo, crewsInfo}) => dispatch => {
    dispatch({
        type: types.SET_CREWS_FLEET_INFO,
        payload: {fleetInfo, crewsInfo}
    })
}

export const SchedulesSettingsDispatch = scheduleSettings => dispatch => {
    dispatch({
        type: types.SET_SCHEDULE_SETTINGS,
        payload: {scheduleSettings}
    })
}
