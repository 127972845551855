import { message } from "antd";
import Decimal from "decimal.js-light";
import { getErrors } from "../../../../utils/getErrors";
import { roundToDecimals } from "../../../../../../../../../../utils/index";
import { calculateDifference, updateFakeSOV } from "../utils";
import { findTaxAmountGivenCustomTaxAmount } from "../../../../Service/ServiceContent/GridData/GridUtils/toggleTaxEvent";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const removeRentEventHoist = (
  eventId,
  isWritable,
  isLoading,
  service,
  elevation,
  fakeSOV,
  setSOV,
  setErrors,
  data,
  project
) => {
  if (!isWritable) {
    message.error("Please enable write mode");
  } else if (isLoading) {
    message.error("Please wait until it finishes updating");
  } else {
    console.log("service_fakeSOV", fakeSOV);
    // keep track of the original tax amount
    // Find service that we want to edit
    const unchangedService = structuredClone(
      fakeSOV?.services?.find(
        (sovService) =>
          sovService.serviceId.toString() === service.serviceId.toString() &&
          sovService.estimationId === service.estimationId
      )
    );

    const serviceToEdit = structuredClone(unchangedService);
    const elevationToEdit = serviceToEdit?.serviceOptions?.[0]?.find(
      (serviceOption) => serviceOption?.elevationId === elevation?.elevationId
    );
    console.log("elevationToEdit.amounts[eventId].rate", service);
    elevationToEdit.amounts[eventId].retainage = 0;
    elevationToEdit.amounts[eventId].retainagePercentage = 0;
    elevationToEdit.amounts[eventId].collectable_amount = forceToNumber(
      elevationToEdit.amounts[eventId].amount
    );
    const newAmounts = elevationToEdit?.amounts;

    // Find the service that we are editing and update its amounts
    const newSOV = updateFakeSOV(fakeSOV, service, elevation, newAmounts);

    // Get errors for each service
    const errors = getErrors(newSOV);

    // Set new SOV
    setSOV(newSOV);

    // If none of services have errors set errors to null and save to db, otherwise set errors to errors object of all services
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      setErrors(null);
    } else {
      setErrors(errors);
    }
  }
};
