export const camelCaseToTitleCase = (str) => {
  // Split the string into words based on capital letters
  const words = str.split(/(?=[A-Z])/);
  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  // Join the words with spaces and return
  return capitalizedWords.join(" ");
};
