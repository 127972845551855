import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef } from "react";

import { defaultColumnDef } from "./helpers";
import { autoSizeAllColumns } from "../../utils/gridUtils";
import { useUploadRecordsContext } from "../../context/UploadRecordsContext";

import "./ComparisonTable.scss";

const ComparisonTable = ({
  tableKey,
  columnDefs,
  rowData,
  compareRowData,
  gridRef,
  gridCompareRef,
}) => {
  const timeoutRef = useRef(null);

  const { setSelectedRowsRef } = useUploadRecordsContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const onGridRowSelected = (event) => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();

    selectedNodes.forEach((row) => {
      const node2 = gridCompareRef.current.api.getRowNode(row.id);

      if (row.selected) {
        node2.setSelected(false);
      }
    });

    const data = selectedNodes.map((node) => node.data);

    setSelectedRowsRef(data, tableKey);
  };

  const defaultColDef = useMemo(() => {
    return defaultColumnDef(compareRowData);
  }, []);

  const autoSizeColumns = useCallback(() => {
    autoSizeAllColumns(rowData, gridRef, false);
  }, [rowData, gridRef]);

  return (
    <div
      className={`documentationsTable comparison-table ${
        isDarkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
      style={{ minWidth: "38vw" }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onRowSelected={onGridRowSelected}
        rowSelection={"multiple"}
        rowMultiSelectWithClick={true}
        onFirstDataRendered={(e) => {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            autoSizeColumns();
          }, 300);
        }}
      />
    </div>
  );
};

export default ComparisonTable;
