import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const violations = (updatedDynamicStates, IdHandler) => {
  return {
    amount_due: () => ({
      title: "Violations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Violations",
        field: "amount_due",
        title: "Violation Amount",
        subtitle: "",
        content: (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                dateCreated: 1630965600000,

                percent: (
                  ((() => {
                    const filteredArray = (
                      updatedDynamicStates?.[IdHandler] || []
                    )
                      ?.filter((b) => b.issue_date >= getMonthsTimestamps()[0])
                      ?.filter(
                        (b) =>
                          b.issue_date >= getMonthsTimestamps()[i] &&
                          b.issue_date <= getMonthsTimestamps("end")[i]
                      )
                      ?.map((a) => a.amount_due);

                    return !!filteredArray?.length
                      ? filteredArray?.reduce(
                          (previousValue, currentValue) =>
                            parseInt(previousValue) + parseInt(currentValue)
                        )
                      : 0;
                  })() /
                    (() => {
                      const filteredArray = (
                        updatedDynamicStates?.[IdHandler] || []
                      )
                        ?.filter(
                          (b) => b?.issue_date >= getMonthsTimestamps()[0]
                        )
                        ?.map((a) => a?.amount_due);

                      return !!filteredArray?.length
                        ? filteredArray?.reduce(
                            (previousValue, currentValue) =>
                              parseInt(previousValue) + parseInt(currentValue)
                          )
                        : 0;
                    })()) *
                  100
                ).toFixed(1),
                showInfo: true,
                status: months[i],
                done: (() => {
                  const filteredArray = (
                    updatedDynamicStates?.[IdHandler] || []
                  )
                    ?.filter((b) => b.issue_date >= getMonthsTimestamps()[0])
                    ?.filter(
                      (b) =>
                        b.issue_date >= getMonthsTimestamps()[i] &&
                        b.issue_date <= getMonthsTimestamps("end")[i]
                    )
                    ?.map((a) => a.amount_due);

                  return !!filteredArray?.length
                    ? filteredArray?.reduce(
                        (previousValue, currentValue) =>
                          parseInt(previousValue) + parseInt(currentValue)
                      )
                    : 0;
                })(),
              },
            ];
          }
          return violations;
        })(),
      },
      id: IdHandler,
      width: "390px",
    }), //amount_due
    issue_date: () => ({
      title: "Violations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Violations",
        field: "issue_date",
        title: "Issue Date",
        subtitle: "",
        content: (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                dateCreated: 1630965600000,

                percent: (
                  ((updatedDynamicStates?.[IdHandler] || [])
                    ?.filter((b) => b.issue_date >= getMonthsTimestamps()[0])
                    ?.map((a) => a?.issue_date)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    (updatedDynamicStates?.[IdHandler] || [])?.filter(
                      (b) => b.issue_date >= getMonthsTimestamps()[0]
                    ).length) *
                  100
                ).toFixed(1),
                showInfo: true,
                status: months[i],
                done: (updatedDynamicStates?.[IdHandler] || [])
                  ?.filter((b) => b.issue_date >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.issue_date)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return violations;
        })(),
      },
      id: IdHandler,
      width: "390px",
    }), //issue_date
  }; //Violations
};
