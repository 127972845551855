import dayjs from "dayjs";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

//! Returns full array of days between the first and last day of the array, including missing days between them too!

export const emptyDays = (startDate, stopDate) => {
  let dateArray = [];
  let currentDate = dayjsNY(startDate);
  let endDate = dayjsNY(stopDate);
  while (currentDate <= endDate) {
    dateArray.push(dayjsNY(currentDate).format("MM/DD/YYYY"));
    currentDate = dayjsNY(currentDate).add(1, "days");
  }
  return dateArray;
};
