import { Tooltip } from "antd";
import { fileIcons } from "../../../../../documentationViewData";
import { openInNewTab } from "../../../../../../../../../utils";
import { TrashIcon } from "../../../../../../../Communication/assets";
import {
  DownloadIcon,
  SaveIcon,
  Redirect,
} from "../../../../../../../BasePage/src";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const DATE_FORMAT = "MM/DD/YYYY hh:mm a";

function DocUploaderFile({
  file = {},
  index,
  handleCurrentFile,
  setWarningModalVisible,
  disabled,
  fileType,
  isCreateDocumentEnabled = false,
  setDocumentationModalFiles,
}) {
  const {
    id,
    name = "",
    uploadedBy = "",
    uploadedAt,
    url,
    type,
    mimeType,
    downloadUrl,
    documented = {},
  } = file;
  const divRef = useRef(null);
  const location = useLocation();

  let userName = uploadedBy;
  if (location.pathname?.includes("foremanProject")) {
    userName = "";
  }

  return (
    // <Tooltip title={() => <span>{`File Name: ${name}`}</span>}>
    <div
      data-testid="file-item"
      ref={divRef}
      key={id}
      className="uploadedFilesCard"
    >
      <span className="filesIcon">{fileIcons[fileType]}</span>

      <div
        onClick={() => {
          if (
            type !== "photo" &&
            mimeType !== "application/pdf" &&
            !mimeType?.includes("image")
          )
            openInNewTab(url);
          else {
            handleCurrentFile({
              id,
              url,
              mimeType,
              index,
              name,
            });
          }
        }}
        className="uploadedFile"
      >
        <span className="fileName">{name}</span>
        <div className="fileAuthor">
          {userName && <span>{userName}</span>}
          {uploadedAt && userName && <span>-</span>}
          {uploadedAt && <span>{dayjs(uploadedAt).format(DATE_FORMAT)}</span>}
        </div>
      </div>
      <div className="actionButtons">
        {isCreateDocumentEnabled &&
          !disabled &&
          (documented.isDocumented ? (
            <span
              data-testid="redirect-icon"
              onMouseOver={() => {
                divRef.current.classList.remove("uploadedFilesCardRed");
                divRef.current.classList.add("uploadedFilesCardBlue");
              }}
              onMouseLeave={() => {
                divRef.current.classList.remove("uploadedFilesCardBlue");
              }}
              onClick={() =>
                window.open(
                  `/documentation?tab=Documentation&docId=${documented?.docId}`,
                  "_blank"
                )
              }
              className="closeIconContainer"
            >
              <Redirect />
            </span>
          ) : (
            <span
              data-testid="save-icon"
              onMouseOver={() => {
                divRef.current.classList.remove("uploadedFilesCardRed");
                divRef.current.classList.add("uploadedFilesCardBlue");
              }}
              onMouseLeave={() => {
                divRef.current.classList.remove("uploadedFilesCardBlue");
              }}
              className="closeIconContainer"
              onClick={() => setDocumentationModalFiles([file])}
            >
              <SaveIcon />
            </span>
          ))}
        {!disabled && (
          <span
            onMouseOver={() => {
              divRef.current.classList.remove("uploadedFilesCardRed");
              divRef.current.classList.add("uploadedFilesCardBlue");
            }}
            onMouseLeave={() => {
              divRef.current.classList.remove("uploadedFilesCardBlue");
            }}
            className="closeIconContainer"
            onClick={() => {
              window.open(
                `https://drive.google.com/u/1/uc?id=${id}&export=download&authuser=0`
              );
            }}
          >
            <DownloadIcon />
          </span>
        )}
        {!disabled && (
          <span
            onMouseOver={() => {
              divRef.current.classList.remove("uploadedFilesCardBlue");
              divRef.current.classList.add("uploadedFilesCardRed");
            }}
            onMouseLeave={() => {
              divRef.current.classList.remove("uploadedFilesCardRed");
            }}
            className="closeIconContainer closeIconContainerDelete"
            onClick={() => setWarningModalVisible(id)}
          >
            <TrashIcon />
          </span>
        )}
      </div>
    </div>
    // </Tooltip>
  );
}

export default DocUploaderFile;
