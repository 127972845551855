import styles from "./FunctionList.module.css";

const FunctionList = ({ title, data, onListItemClickHandler }) => {
  return (
    <ul className={styles.ul}>
      {title && <li className={styles["disabled-title"]}>{title}</li>}
      {data.map((option, index) => (
        <li
          key={index}
          className={styles.li}
          onClick={() => onListItemClickHandler(option.value)}
        >
          {option.value}
        </li>
      ))}
    </ul>
  );
};

export default FunctionList;
