import { Radio, Tree } from "antd";
import { DownArrow } from "./../../components/FoldersAccess";
const pathFinder = (permissionList, key, prev, value) => {
  let currPath = "";
  if (!permissionList[key]?.path) {
    currPath = key;
  } else {
    currPath = permissionList[key]?.path;
  }
  let pathArray = currPath.split("-|-").filter(Boolean);
  let nestedObject = prev;
  let currentObject = nestedObject;
  for (let i = 0; i < pathArray.length; i++) {
    if (!currentObject[pathArray[i]]) {
      currentObject[pathArray[i]] = {};
    }
    currentObject = currentObject[pathArray[i]];
  }
  if (currentObject[key]) {
    currentObject[key] = {
      ...currentObject[key],
      Access: value,
    };
  } else {
    currentObject[key] = {
      Access: value,
    };
  }
  return nestedObject;
};

function pushKeys(key, arr = []) {
  if (arr.length === 0) {
    arr.push(key);
  } else {
    const found = arr.find((el) => el === key);
    if (!found) {
      arr.push(key);
    }
  }
  return arr;
}

export function renderPermissionElement(
  permissionList,
  key,
  setChangedValues,
  type = "header",
  setCollectLogs
) {
  return (
    <div
      className={`elementWrapper  ${type === "header" && "headerWrapper"}`}
      style={{ paddingRight: type === "header" ? 10 : 0 }}
    >
      <p
        className={`elementText ${
          type === "header"
            ? "headerText"
            : type === "subfolder"
            ? "subfolderText"
            : type === "tree"
            ? "treeText"
            : ""
        }`}
      >
        {key}
      </p>
      <Radio.Group
        {...{
          defaultValue: permissionList[key]?.permissions,
          options: [
            {
              label: "Reader",
              value: "reader",
              id: permissionList[key]?.permissionId,
            },
            {
              label: "Writer",
              value: "writer",
              id: permissionList[key]?.permissionId,
            },
            {
              label: "None",
              value: "none",
              id: permissionList[key]?.permissionId,
            },
          ],
          onChange: ({ target: { value, id } }) => {
            setCollectLogs((prev) => {
              return {
                curr: {
                  ...pathFinder(permissionList, key, prev?.curr || {}, value),
                },
                prev: {
                  ...pathFinder(
                    permissionList,
                    key,
                    prev?.prev || {},
                    permissionList[key]?.permissions
                  ),
                },
                changedKeys: pushKeys(key, prev?.changedKeys || []),
              };
            });
            setChangedValues((prev) => ({
              ...prev,
              [permissionList[key]?.folderId]: {
                value,
                id,
                name: key,
                prev: permissionList[key]?.permissions,
              },
            }));
          },
        }}
      />
    </div>
  );
}

export function recursiveFoldersSearch(obj, searchVal) {
  const result = {};
  for (let key in obj) {
    if (key.includes(searchVal)) {
      result[key] = obj[key];
    } else {
      const subfolders = recursiveFoldersSearch(
        obj[key]?.subfolders,
        searchVal
      );
      if (Object.keys(subfolders).length > 0) {
        result[key] = {
          ...obj[key],
          subfolders: subfolders,
        };
      }
    }
  }
  return result;
}

export function renderTree(
  list,
  expandedKeys = [],
  onExpand,
  autoExpandParent
) {
  return (
    <Tree
      {...{
        onExpand,
        expandedKeys,
        autoExpandParent,
        treeData: list,
        showLine: true,
        switcherIcon: ({ expanded }) => {
          return (
            <DownArrow
              style={{ transform: `rotate(${expanded ? "180deg" : "0deg"})` }}
            />
          );
        },
      }}
    />
  );
}

//turns object into array of objects for tree
export function createTree(obj, setChangedValues, setCollectLogs) {
  return Object.keys(obj)?.map((key) => ({
    key: obj[key].folderId,
    title: renderPermissionElement(
      obj,
      key,
      setChangedValues,
      "tree",
      setCollectLogs
    ),
    children:
      Object.keys(obj[key].subfolders)?.length > 0
        ? createTree(
            obj[key]?.subfolders,
            setChangedValues,
            setCollectLogs
          ).filter((el) => Object.keys(el).length > 0)
        : [],
  }));
}

//get folder ids from object
export function getAllFids(newList) {
  const fids = [];

  function traverse(obj) {
    Object.values(obj).forEach((value) => {
      if (typeof value === "object" && value !== null) {
        fids.push(value.folderId);
        traverse(value.subfolders);
      }
    });
  }

  traverse(newList);
  return fids;
}
