import { debounce, uniqBy } from "lodash";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import IndividualLoader from "../../../../../IndividualLoader";
import {
  assignedToObject,
  teamSelect,
  projectManagerSelect,
  REQUEST_API_KEYS,
} from "..";
import sortFields from "../sortFields";

/**
 * Generates an array of fields for the schedule change request form.
 *
 * @param {Array} teams - The list of teams.
 * @param {Array} accounts - The list of accounts.
 * @param {Array} schedules - The list of schedules.
 * @param {Array} inputFields - The list of input fields.
 * @param {boolean} isDarkMode - Indicates if the form is in dark mode.
 * @param {Array} projectManagers - The list of project managers.
 * @param {string} currentPageKey - The current page key.
 * @param {boolean} globalDisabled - Indicates if all fields are disabled.
 * @param {boolean} recordsLoading - Indicates if records are loading.
 * @param {Array} projectExecutives - The list of project executives.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {string} selectedMainField - The selected main field.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} setRecordsLoading - The function to set records loading state.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The array of fields for the schedule change request form.
 */
const scheduleChangeRequestFields = ({
  teams = [],
  accounts = [],
  schedules = [],
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  currentPageKey = null,
  globalDisabled = false,
  recordsLoading = false,
  projectExecutives = [],
  userConfiguration = {},
  onTeamSelect = () => {},
  selectedMainField = null,
  allFieldsDisabled = false,
  onMainFieldSelect = () => {},
  setRecordsLoading = () => {},
  dispatchRequestData = () => {},
}) => {
  const onSearch = debounce(
    async (searchValue) =>
      await searchFetchInput({
        table: "scheduling",
        idKey: "scheduleId",
        searchKey: "scheduleAddress",
        searchValue,
        userConfiguration,
        keysToInclude: REQUEST_API_KEYS["Schedule Change"].scheduling,
        setLoading: setRecordsLoading,
        setRecords: (res) =>
          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: {
              schedules: userAccessRecords(
                userConfiguration,
                uniqBy([...(res || []), ...(schedules || [])], "scheduleId")
              ),
            },
          }),
        setCurrentPageKey: (val) => {
          currentPageKey.current = val;
        },
        customSetRecords: true,
      }),
    500
  );

  const onPopupScroll = async (e) =>
    await handlePopupScroll({
      e,
      table: "scheduling",
      idKey: "scheduleId",
      currentPageKey: currentPageKey.current.schedules,
      keysToInclude: REQUEST_API_KEYS["Schedule Change"].scheduling,
      setLoading: setRecordsLoading,
      setRecords: (res) =>
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: {
            schedules: userAccessRecords(
              userConfiguration,
              uniqBy([...(res || []), ...(schedules || [])], "scheduleId")
            ),
          },
        }),
      userConfiguration,
      setCurrentPageKey: (val) => {
        currentPageKey.current = { schedules: val };
      },
      customSetRecords: true,
    });

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "scheduleAddress") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (schedules || [])?.map((el) => ({
          value: el?.scheduleId,
          label: `${el?.scheduleName} ― ${el?.scheduleAddress}`,
          recordName: el?.scheduleName,
        })),

        style: { minWidth: 300 },
        showSearch: true,
        allowClear: true,

        onSelect: (val, opt) =>
          onMainFieldSelect({ val, recordName: opt?.recordName }),

        onClear: () => onMainFieldSelect({ clear: true }),

        onSearch,

        onPopupScroll,

        dropdownRender,
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Scheduling",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "client") {
      delete field?.options;

      Object.assign(field, {
        disabled: true,
        customOptions: (accounts || [])?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
      });
    }

    if (formItemName === "projectExecutive") {
      delete field?.options;

      Object.assign(field, {
        options: projectExecutives?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
        disabled:
          !!allFieldsDisabled ||
          globalDisabled ||
          !!selectedMainField?.projectExecutive,
        type: "select",
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default scheduleChangeRequestFields;
