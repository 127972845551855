import { Divider, Input, Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import "./LinkBtn.scss";

/**
 * LinkBtn component represents a button with a link icon and an editable input field.
 *
 * @component
 * @param {ReactNode} children - The content of the button.
 * @param {string|null} value - The initial value of the input field.
 * @param {function} onClick - The click event handler for the button container.
 * @param {function} onChange - The change event handler for the input field.
 * @param {string} id - The id attribute for the button container.
 * @returns {JSX.Element} The rendered LinkBtn component.
 */
const LinkBtn = ({
  children,
  value = null,
  onClick = () => {},
  onChange = () => {},
  id = "",
}) => (
  <div id={id} className="linkBtnContainer" data-testid="link=btn-container">
    <LinkOutlined className="linkIcon" />
    <div
      className="linkBtnChildrenContainer"
      onClick={onClick}
      data-testid="link-btn-children-container"
    >
      {children}
    </div>

    <Divider type="vertical" className="btnDivider" />
    <Tooltip
      trigger="click"
      title={
        <Input {...{ value, onChange, "data-testid": "link-btn-input" }} />
      }
      data-testid="link-btn-tooltip"
    >
      <div className="editLabel">Edit</div>
    </Tooltip>
  </div>
);

export default LinkBtn;
