import { useEffect, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { Checkbox } from "antd";
import "./MultiBox.scss";
const MultiBox = ({ params, edited, setNewValues }) => {
  const [values, setValues] = useState(params);
  useEffect(() => {
    if (edited) {
      keys(edited).map((el) => {
        values.rowData.find(({ formItemName }) => formItemName === el).value =
          edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
    }
  }, [JSON.stringify(edited)]);

  return (
    <div className="MultiBoxContainer">
      {values?.rowData?.map(({ label, value, formItemName, type }) => {
        return (
          <>
            <div style={{ pointerEvents: "none", marginBottom: 10 }}>
              <div className="configElementContainer">
                <div
                  className="bluContainer"
                  style={{ width: !!value ? 36 : null }}
                >
                  <div
                    className="navyBlueContainer"
                    style={{
                      opacity: !!value ? 1 : null,
                    }}
                  >
                    <span style={!value ? { opacity: 0 } : {}}>
                      <Checkbox checked={value} />
                    </span>
                  </div>
                </div>
                <span className="configElementTitle">{label}</span>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};
export default MultiBox;
