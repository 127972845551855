import { apiRoutes } from "../components/SidebarPages/Fleet/utils";
import types from "../types";
import {
  apiDelete,
  apiPost,
  apiPut,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../utils";

const serviceDefinitions = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_SERVICE_DEFINITION,
    payload: attributes,
  });
};

export const addScope = (newScope) => async (dispatch) => {
  const newService = {
    ...newScope,
  };

  showLoadingMsg();
  return await apiPost(apiRoutes.serviceDefinitions, newService)
    .then((payload) => {
      showSuccessMsg();
      dispatch({
        type: types.ADD_SCOPE,
        payload: payload,
      });
    })
    .catch((e) => {
      console.error(e);
      showErrorMsg();
    });
};

export const deleteScope = (scopeId) => async (dispatch) => {
  showLoadingMsg();
  return await apiDelete(apiRoutes.serviceDefinitions, scopeId)
    .then(() => {
      showSuccessMsg();
      dispatch({
        type: types.DELETE_SCOPE,
        payload: scopeId,
      });
    })
    .catch((e) => {
      console.log(e);
      showErrorMsg();
    });
};

export const editScope = (scope) => async (dispatch) => {
  const { userId, serviceId, ...rest } = scope;

  showLoadingMsg();
  return await apiPut(apiRoutes.serviceDefinitions, serviceId, rest)
    .then(() => {
      showSuccessMsg();
      dispatch({
        type: types.EDIT_SCOPE,
        payload: scope,
      });
    })
    .catch((e) => {
      console.log(e);
      showErrorMsg();
    });
};

export default serviceDefinitions;
