import { API } from "aws-amplify";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import { addMissingKeys } from "../utils";
import { getGoogleDriveId } from "../utils/getGoogleDriveId";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";

const initialConflictsObject = {
  existingRecords: [],
  newRecords: [],
};

const useUploadRecords = ({
  title,
  setIsOpen,
  updateProgressStatus,
  setVisibleCreationProgress,
}) => {
  const [uploadedRecords, setUploadedRecords] = useState([]);
  const [recordsConflicts, setRecordsConflicts] = useState(
    initialConflictsObject
  );
  const [recordsToDelete, setRecordsToDelete] = useState([]);

  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [conflictsTableVisible, setConflictsTableVisible] = useState(false);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const { sampleObjects } = useSelector((state) => state.sampleObjects);
  const { programFields } = useSelector((state) => state.programFields);
  const { fleetFields } = useSelector((state) => state.fleetFields);

  const driveFunctions = useCreateDriveFolders(title);

  const conflictsButtonRef = useRef(null);
  const selectedRowsRef = useRef(null);

  const { nameOfUser, identityId } = userConfiguration;

  const driveFolderId = getGoogleDriveId(fleetFields, title);

  const folderList =
    programFields.find((field) => field.fieldName === "List of Folders")
      ?.fieldOptions?.[title] || {};

  const sampleObject = sampleObjects.find(
    (object) => object.objectName === title
  );
  const { objectSample } = sampleObject || {};
  const categoryObject = objectSample?.[0];

  const category = topicCategories.find(
    (category) => category.categoryName === title
  );

  const onResolveConfirm = () => {
    const selectedRows = Object.values(selectedRowsRef.current || []).flat();
    setUploadedRecords((prev) => [...prev, ...selectedRows]);
    setIsWarningOpen(false);
    setConflictsTableVisible(false);
    setRecordsToDelete(selectedRows.map((row) => row[category.primaryKey]));
    setRecordsConflicts(initialConflictsObject);
  };

  const onResolve = () => setIsWarningOpen(true);

  const getTeams = (teams, teamsConfiguration) => {
    const team = teams.find((team) => team.teamName === teamsConfiguration);

    if (!team) return;

    return {
      value: team.teamName,
      members: team.members.map((member) => ({
        cognitoUserId: member.cognitoUserId,
        identityId: member.identityId,
        nameOfUser: member.nameOfUser,
      })),
    };
  };

  const onSaveRecords = async () => {
    showLoadingMsg({ content: `Saving ${title} records...` });
    setVisibleCreationProgress(true);

    updateProgressStatus({ updatingRecord: "executing" });

    const teams = await API.get("teams", "/teams");

    const saveRecordsObject = await Promise.all(
      uploadedRecords.map(async (record) => {
        const team = getTeams(teams, record.teamsConfiguration);

        const addMissingKeysObject = addMissingKeys(
          categoryObject,
          record,
          category.primaryKey
        );

        const { folders } = await driveFunctions.create({
          parentId: driveFolderId,
          parentFolderName: record?.[category.rowName],
        });

        return {
          ...addMissingKeysObject,
          googleDriveFolderIds: folders,
          lastModifiedBy: {
            name: nameOfUser,
            date: new Date().getTime(),
            id: identityId,
          },
          createdBy: {
            name: nameOfUser,
            id: identityId,
          },
          createdAt: new Date().getTime(),
          teamsConfiguration: team ? [team] : [],
        };
      })
    );

    try {
      if (recordsToDelete.length > 0) {
        await API.del(category.apiName, `/${category.apiName}/123`, {
          body: recordsToDelete,
        });
      }

      await API.post(category.apiName, `/${category.apiName}`, {
        body: saveRecordsObject,
      });

      showSuccessMsg({ content: `${title} records saved successfully...` });
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });
    } catch (error) {
      console.error({ error });
      updateProgressStatus({ updatingRecord: "hasError" });
      showErrorMsg({ content: `Error saving ${title} records...` });
    }
  };

  const setSelectedRowsRef = (data, tableKey) => {
    const currentData = selectedRowsRef.current || {};
    selectedRowsRef.current = { ...currentData, [tableKey]: data };

    const hasData = Object.values(selectedRowsRef.current || []).flat();

    const conflictsButton = conflictsButtonRef.current;
    if (conflictsButton) {
      conflictsButton.disabled = hasData.length === 0;
      if (hasData.length > 0) conflictsButton.onclick = onResolve;
    }
  };

  return {
    title,
    onResolve,
    isWarningOpen,
    onSaveRecords,
    uploadedRecords,
    recordsConflicts,
    onResolveConfirm,
    setIsWarningOpen,
    setUploadedRecords,
    setRecordsConflicts,
    conflictsTableVisible,
    setConflictsTableVisible,
    conflictsButtonRef,
    setSelectedRowsRef,
  };
};

export default useUploadRecords;
