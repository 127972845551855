import { Form, message, Modal } from "antd";
import { useState, useEffect } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { antdModalProps, updateDocumentTitle } from "../../../../../../utils";
import { MondayButton, RichTextEditor } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { ClearIcon } from "../../../../../SidebarPages/BasePage/src/index";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { getBinNumber } from "../../../../../SidebarPages/Projects/DobTab/utils";
import "./DobInfoModal.scss";
import "../../../../../SidebarPages/Reports/components/NewReportModal/NewReportModal.scss";
import { InfoIcon } from "../../../../../../assets";

const DobInfoModal = ({
  visible,
  setVisible,
  disabled = false,
  setDobInformation,
  address,
}) => {
  const [form] = Form.useForm();
  const [canSave, setCanSave] = useState(false);
  const [canCancel, setCanCancel] = useState(false);

  const onCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  const clearDobFields = () => {
    setDobInformation({
      binNumber: "",
      dobAddress: "",
      dobBlock: "",
      dobLot: "",
    });
    form.setFieldsValue({
      dobAddress: "",
      dobBlock: "",
      dobLot: "",
    });
    setCanSave(false);
  };

  const ref = useOnclickOutside(() => {
    if (!visible) return;
    onCancel();
    if (!address) {
      setCanCancel(false);
      clearDobFields();
      form.setFieldValue("binNumber", "");
    }
  });

  const onPressEnter = () => {
    fetch(`https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${form.getFieldValue(
      "binNumber"
    )}
    `).then((resp) => {
      return resp
        .json()
        .then((r) => {
          if (r.length === 0) {
            message.info("There is no information about this DOB");
          } else {
            const resp = r[0];
            let dobAddress =
              resp?.street_name && resp?.borough
                ? `${resp?.street_name} , ${resp?.borough}`
                : "";
            setDobInformation({
              binNumber: form.getFieldValue("binNumber") || "",
              dobAddress: dobAddress,
              dobBlock: resp?.block || "",
              dobLot: resp?.lot || "",
            });
            form.setFieldsValue({
              dobAddress: dobAddress,
            });
            form.setFieldsValue({ dobBlock: resp?.block || "" });
            form.setFieldsValue({ dobLot: resp?.lot || "" });
            setCanSave(true);
            setCanCancel(true);
          }
        })
        .catch((e) => {
          message.error("Could not Find Bin Information");
          console.log("Bin Error: ", e);
          clearDobFields();
        });
    });
  };

  const handleBin = () => {
    onPressEnter();
  };

  useEffect(() => {
    let controller = new AbortController();
    const fetchBin = async () => {
      const binNumber = await getBinNumber(address);
      fetch(
        `https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${binNumber}
      `,
        {
          signal: controller.signal,
        }
      ).then(async (resp) =>
        resp
          .json()
          .then((r) => {
            if (r.length === 0) {
              message.info("There is no information about this DOB");
            } else {
              const resp = r[0];
              let dobAddress =
                resp?.street_name && resp?.borough
                  ? `${resp?.street_name} , ${resp?.borough}`
                  : "";
              setDobInformation({
                binNumber: binNumber || "",
                dobAddress: dobAddress,
                dobBlock: resp?.block || "",
                dobLot: resp?.lot || "",
              });
              form.setFieldsValue({
                binNumber: binNumber,
              });
              form.setFieldsValue({
                dobAddress: dobAddress,
              });
              form.setFieldsValue({ dobBlock: resp?.block || "" });
              form.setFieldsValue({ dobLot: resp?.lot || "" });
              setCanSave(true);
              setCanCancel(true);
            }
          })
          .catch((e) => {
            console.log("Error Fetching Bin: ", e);
            clearDobFields();
          })
      );
    };

    if (!!address) {
      fetchBin();
    }

    return () => {
      controller.abort();
    };
  }, [address]);

  return (
    <Modal
      forceRender
      {...{
        ...antdModalProps,
        title: "DOB Information",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "DOB Information" });
        },
        open: visible,
        onCancel,
        closable: false,
      }}
      style={{ maxWidth: "904px" }}
    >
      <div ref={ref}>
        <Form className="dobInfoModalContent" form={form}>
          <div className="infoText">
            <InfoIcon style={{ fillColor: "#1264A3" }} />
            <p className="text">Please fill out the DOB form below.</p>
          </div>
          <div className="dobInfoModalHeader">
            <Form.Item
              name="binNumber"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* <div className="binNumber-flex"> */}
              <InputComponent
                {...{
                  label: "Bin Number",
                  formItemName: "binNumber",
                  placeholder: "Bin Number",
                  disabled,
                  onPressEnter: onPressEnter,
                  onChange(val) {
                    setCanCancel(true);
                    if (!val.target.value) {
                      clearDobFields();
                    }
                  },
                }}
              />
              <MondayButton
                {...{
                  Icon: <TickIcon />,
                }}
                onClick={handleBin}
                style={{ marginTop: "25px" }}
              >
                Submit
              </MondayButton>
              {/* </div> */}
            </Form.Item>
          </div>

          <div className="inlineInputs">
            <Form.Item name="dobAdress">
              <InputComponent
                {...{
                  label: "DOB Address",
                  formItemName: "dobAddress",
                  placeholder: "DOB Address",
                  disabled: true,
                  className: "dobAddress",
                  // style: { width: "322px" },
                }}
              />
            </Form.Item>
            <Form.Item name="dobBlock" className="dobBlock">
              <InputComponent
                {...{
                  label: "DOB Block",
                  formItemName: "dobBlock",
                  placeholder: "DOB Block",
                  disabled: true,
                  className: "dobBlock",
                }}
              />
            </Form.Item>
            <Form.Item name="dobLot" className="dobLot">
              <InputComponent
                {...{
                  label: "DOB Lot",
                  formItemName: "dobLot",
                  placeholder: "DOB Lot",
                  disabled: true,
                  className: "dobLot",
                }}
              />
            </Form.Item>
          </div>
          <div className="textEditor">
            <RichTextEditor
              {...{
                name: "dobTextArea",
                onChange: () => {
                  setCanCancel(true);
                },
                className: "ignore-onclickoutside",
              }}
            />
          </div>
          <div className="footer">
            <MondayButton
              Icon={<ClearIcon />}
              onClick={() => {
                if (!address) {
                  clearDobFields();
                  form.setFieldValue("binNumber", "");
                }
                onCancel();
              }}
              className={`mondayButtonRed ${
                canCancel && "dobFooterCancelButton"
              }`}
            >
              Cancel
            </MondayButton>
            <MondayButton
              {...{
                Icon: <TickIcon />,
                onClick: () => {
                  handleBin();
                  onCancel();
                },
                disabled: !canSave,
              }}
            >
              Save
            </MondayButton>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default DobInfoModal;
