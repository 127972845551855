import React, { createContext, useContext } from "react";
import { useAttachments } from "./AttachmentsProvider";

const InitialEmailBoxPropsContext = createContext();

export const useInitialEmailBoxProps = () =>
  useContext(InitialEmailBoxPropsContext);

/**
 *
 * @param {Function} onClose callback when we remove the email box
 * @param {Object} rowData object of selected application item record
 * @param {Object} authToken object with credentials of logged google user to send email as logged user or set it false if you want to user application email
 * @returns
 */

export const InitialEmailBoxPropsProvider = ({
  children,
  onClose,
  rowData = {},
  selectedColumns,
  authToken = null,
  selectedDraft,
  docDefinition,
  isFull = false,
  isFromCharge,
  chargePdf,
  openEmailBox,
  isRedirected = [],
  recordID = false,
  invoice = false,
  exportOrEmailDropdown = false,
  sendEmailNotification = () => {},
  proppedRecordId = null,
  proppedTemplateCat = false,
  agGridToEmail = false,
  boxKey,
  authData = null,
  attachments = [],
}) => {
  return (
    <InitialEmailBoxPropsContext.Provider
      value={{
        onClose,
        rowData,
        selectedColumns,
        authToken,
        selectedDraft,
        docDefinition,
        isFull,
        isFromCharge,
        chargePdf,
        openEmailBox,
        isRedirected,
        recordID,
        invoice,
        exportOrEmailDropdown,
        sendEmailNotification,
        proppedRecordId,
        proppedTemplateCat,
        agGridToEmail,
        boxKey,
        authData,
        attachments,
      }}
    >
      {children}
    </InitialEmailBoxPropsContext.Provider>
  );
};
