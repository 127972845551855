import "./UploadImageStep.scss";
import { useEffect, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { CloseOutlined } from "@ant-design/icons";
import { UploadIcon } from "src/components/SidebarPages/Documentation/View/assets";
import FileUploader from "../../../commonComponents/FileUploader/FileUploader";
import { MondayButton } from "../../../commonComponents";
import { Camera, UserIcon } from "../../assets/icons";
import { driveApi } from "../../../../integrations/DriveRequest";
import { UserConfig } from "../../../../actions";
import BoardingNavigationBtn from "../BoardingNavigationBtn/BoardingNavigationBtn";
import { saveStepLogs } from "../../utils";
import { useEditLogs } from "../../../../hooks";

const UploadImageStep = () => {
  const dispatch = useDispatch();

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);

  const [userImage, setUserImage] = useState("");

  const driveRequest = driveApi({ accessToken });
  const { saveAddedLogs } = useEditLogs();

  const saveImageSrc = (fileId) => {
    !!fileId
      ? driveRequest
          .getImageSrc(fileId)
          .then((image) => setUserImage(image.src))
      : setUserImage("");
  };

  useEffect(() => {
    accessToken && saveImageSrc(userConfiguration.googleDriveFileId);
  }, [userConfiguration.googleDriveFileId, accessToken]);

  const onUploadImage = (data) => {
    const file = data.docs[0];
    message.loading(`${!!file?.id ? "Saving" : "Deleting"} image...`);

    const stepConfigs = userConfiguration?.boardingConfigs || {};

    const boardingConfigs = {
      ...stepConfigs,
      uploadImageStep: { ...stepConfigs.uploadImageStep, finished: !!file.id },
    };

    API.patch("userConfiguration", "/userConfiguration", {
      body: { googleDriveFileId: file.id, boardingConfigs },
    }).then(() => {
      message.destroy();
      message.success(`Image ${!!file?.id ? "Saved" : "Deleted"} sucessfully`);
      saveImageSrc(file.id);

      dispatch(
        UserConfig({
          ...userConfiguration,
          boardingConfigs,
          googleDriveFileId: file.id,
        })
      );

      saveStepLogs(
        [
          {
            key: "finished",
            stepKey: "uploadImageStep",
            stepTitle: "Set Your Profile Photo",
          },
        ],
        saveAddedLogs,
        userConfiguration
      );
    });
  };

  return (
    <div
      className={`upload-image-step step-lines ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="camera-icon">
        <Camera style={{ width: "30px", height: "28px" }} />
      </div>
      <span style={{ textAlign: "center" }}>
        Personalize your account by{" "}
        <span style={{ fontWeight: 600 }}>adding</span> a profile photo.
        <br /> Your photo also helps others recognize you.
      </span>
      <div className="image-holder">
        {!!userImage ? (
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <img width="100%" height="100%" src={userImage} />
            <div className="remove-img-btn">
              <Tooltip
                placement="right"
                trigger="hover"
                title="Remove Profile Picture"
              >
                <Button
                  type="primary"
                  size="small"
                  shape="circle"
                  danger
                  onClick={() => {
                    onUploadImage({ docs: [{ id: "" }] });
                  }}
                  icon={<CloseOutlined />}
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="user-icon">
            <UserIcon />
          </div>
        )}
      </div>
      <FileUploader
        onlyTypes={["image/"]}
        customBtn={
          <MondayButton className="mondayButtonBlue" Icon={<UploadIcon />}>
            Upload Photo
          </MondayButton>
        }
        onPickerSuccess={onUploadImage}
      />

      <BoardingNavigationBtn {...{ autoStepConfig: false }} />
    </div>
  );
};

export default UploadImageStep;
