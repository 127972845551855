import React from "react";
import { Checkbox, message } from "antd";

import { useRedux } from "../../../hooks";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";

const TaxCheckBox = (props) => {
  const [isWritable] = useRedux("billIsWritable");
  const { form, billData, vendor } = useInitialBillContext();
  const { rowData, setRowData } = useCreateBillContext();

  const onCheckboxChange = () => {
    if (!!vendor?.vendorTaxRate) {
      const appliedAmount = rowData.reduce((acc, row) => (acc += row.total), 0);
      const totalAmount = form.getFieldValue("billAmount") || 0;
      const remainingValue = totalAmount - appliedAmount;

      setRowData((prev) =>
        prev.map((row) => {
          const isTaxable = !row.tax;
          const taxAmount = isTaxable ? row.amount * vendor.vendorTaxRate : 0;
          const isBillable = isTaxable ? true : row.billable;

          if (row.itemId === props.data.itemId) {
            if (remainingValue - taxAmount < 0 && isTaxable) {
              message.error("You can't apply more than the total amount.");

              return {
                ...row,
                tax: false,
                taxAmount: 0,
                total: row.amount,
              };
            }

            return {
              ...row,
              billable: isBillable,
              tax: isTaxable,
              taxAmount,
              total: row.amount + (isTaxable ? taxAmount : 0),
            };
          }
          return row;
        })
      );
    } else {
      message.warning("This item is tax-free.");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Checkbox
        disabled={!isWritable && billData}
        checked={props.value}
        className="checkbox"
        onChange={onCheckboxChange}
      />
    </div>
  );
};

export default TaxCheckBox;
