import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const opportunity = (IdHandler, programFields, updatedDynamicStates) => {
  console.log({ updatedDynamicStates, IdHandler });

  return {
    opportunityStatus: () => ({
      title: "Opportunity Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStatus",
        id: IdHandler,
        page: "opportunities",
        labels: programFields
          ?.find((a) => a?.fieldName == "Status of opportunities")
          ?.fieldOptions?.map((a) => a.statusName),
        datasets: [
          {
            label: "Opportunities",
            data: programFields
              ?.find((a) => a?.fieldName == "Status of opportunities")
              ?.fieldOptions?.map((a) => a.statusName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) =>
                      a.opportunityStatus?.toLowerCase() ===
                      status?.toLowerCase()
                  )?.length
              ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName == "Status of opportunities")
              ?.fieldOptions?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    opportunityStage: () => ({
      title: "Opportunity Stage",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStage",
        id: IdHandler,
        page: "opportunities",
        labels: programFields
          ?.find((a) => a?.fieldName == "Opportunity Stage")
          ?.fieldOptions?.map((a) => a.detailedStageName),
        datasets: [
          {
            label: "Opportunities",
            data: programFields
              ?.find((a) => a?.fieldName == "Opportunity Stage")
              ?.fieldOptions?.map((a) => a.detailedStageName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) =>
                      a.opportunityStage?.toLowerCase() ===
                      status?.toLowerCase()
                  )?.length
              ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Opportunities?.map((a) => a.statusColor),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectExecutive: () => ({
      title: "Opportunity Project Executive",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectExecutive",
        id: IdHandler,
        page: "opportunities",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        ),
        datasets: [
          {
            label: "Opportunities",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectExecutive"
              )
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectManager: () => ({
      title: "Opportunity Project Manager",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectManager",
        id: IdHandler,
        page: "opportunities",
        labels: Object.keys(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])
              ?.map((a) => a?.projectManager)
              ?.flat(),
            "nameOfUser"
          )
        ),
        datasets: [
          {
            label: "Opportunities",
            data: Object.values(
              repeatedElementSum(
                (updatedDynamicStates?.[IdHandler] || [])
                  ?.map((a) => a?.projectManager)
                  ?.flat(),
                "nameOfUser"
              )
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])
                ?.map((a) => a?.projectManager)
                ?.flat()
                ?.filter(Boolean)
                ?.map((a) => getRandomColor())
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    geoFenceInfo: () => ({
      title: "Geofence Quantity - Opportunities",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Opportunities",
        field: "geoFenceInfo",
        id: IdHandler,
        page: "opportunities",
        labels: [
          "Opportunities With Geofences",
          "Opportunities Without Geofences",
        ],

        datasets: [
          {
            label: "Opportunities",
            data: [
              (updatedDynamicStates?.[IdHandler] || []).reduce(
                (sum, { geoFenceInfo }) =>
                  sum + (geoFenceInfo.length > 0 ? 1 : 0),
                0
              ),
              (updatedDynamicStates?.[IdHandler] || []).reduce(
                (sum, { geoFenceInfo }) =>
                  sum + (geoFenceInfo.length === 0 ? 1 : 0),
                0
              ),
            ],
            backgroundColor: programFields
              ?.find((a) => a?.fieldName == "Status of opportunities")
              ?.fieldOptions?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  }; //return
};
