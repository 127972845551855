const replacer = (array = [], newElement, replace, ...comparators) =>
  array.map((existingElement) =>
    comparators.every((key) => existingElement?.[key] === newElement?.[key])
      ? !!replace
        ? newElement
        : { ...existingElement, ...newElement }
      : existingElement
  )

/**
 * Updates (not replaces) an object in array.
 *
 * @param {Array} array Array containing all elements
 * @param {Object} newElement Element containing new values
 * @param  {...String} comparators Keys that compare the objects
 * @returns {Array} Updated Array
 */
export const updateArrayElement = (array = [], newElement, ...comparators) =>
  replacer(array, newElement, false, ...comparators)

/**
 *  Replaces an object in array.
 *
 * @param {Array} array Array containing all elements
 * @param {Object} newElement Element containing new values
 * @param  {...String} comparators Keys that compare the objects
 * @returns {Array} Updated Array
 */
export const replaceArrayElement = (array = [], newElement, ...comparators) =>
  replacer(array, newElement, true, ...comparators)
