import { message } from "antd";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";

import { SaveIcon } from "../../../src";
import { preferencesBasePageFilters } from "../../../../../../actions";
import { MondayButton } from "../../../../../commonComponents";
import { CloseIconModalHeader } from "../../../../../../assets";

const Footer = ({
  addedItem,
  deleteItem,
  setAddedItem,
  setDeleteItem,
  newTemplateOptions,
  selectedDefault,
  customFilterTitle,
  setSaveFiltersModal,
  templateObject,
  newTemplateValue,
}) => {
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.preferences);

  const onSaveClickHandler = async () => {
    if (selectedDefault === "") {
      if (Object.keys(newTemplateOptions).length === 0) {
        setSaveFiltersModal(false);
      } else {
        message.info("Please select a default filter.");
      }
      return;
    }

    let updatedBasePages = {};
    const basePages = preferences.basepages || {};
    const customFilter = basePages[customFilterTitle] || {};
    console.log({ customFilter, basePages, customFilterTitle });
    // return;
    if (!!newTemplateOptions[selectedDefault] && !newTemplateValue?.length) {
      updatedBasePages = {
        ...basePages,
        [customFilterTitle]: {
          ...customFilter,
          ...newTemplateOptions,
          [selectedDefault]: templateObject(),
          defaultFilter: selectedDefault,
        },
      };
    } else if (
      !!newTemplateOptions[selectedDefault] &&
      !!newTemplateValue?.length &&
      selectedDefault !== newTemplateValue
    ) {
      let tmpTemplateOptions = { ...newTemplateOptions };
      let tmpCustomFilter = customFilter;
      tmpCustomFilter[newTemplateValue] = customFilter[selectedDefault];

      delete tmpTemplateOptions[selectedDefault];
      delete tmpCustomFilter[selectedDefault];

      console.log({
        ...basePages,
        [customFilterTitle]: {
          ...customFilter,
          ...tmpTemplateOptions,
          [newTemplateValue]: templateObject(),
          defaultFilter: newTemplateValue,
        },
      });
      console.log({ newTemplateValue });
      updatedBasePages = {
        ...basePages,
        [customFilterTitle]: {
          ...customFilter,
          ...tmpTemplateOptions,
          [newTemplateValue]: templateObject(),
          defaultFilter: newTemplateValue,
        },
      };
    } else {
      updatedBasePages = {
        ...basePages,
        [customFilterTitle]: {
          ...customFilter,
          ...newTemplateOptions,
          defaultFilter: selectedDefault,
        },
      };
    }
    message.loading("Saving filters, please wait...");

    await API.patch("preferences", "/preferences", {
      body: { basepages: updatedBasePages },
    })
      .then(() => {
        dispatch(preferencesBasePageFilters(updatedBasePages));

        message.success("Filters have been successfully saved.");
      })
      .catch(() =>
        message.error(
          "There was an issue saving the filters. Please try again later."
        )
      );

    setAddedItem(false);
    setSaveFiltersModal(false);
  };

  const onConfirmClickHandler = () => {
    setSaveFiltersModal(false);
    setDeleteItem(false);
  };

  return (
    (
      <MondayButton
        className="mondayButtonRed"
        buttonStyle={{ width: "140px" }}
        onClick={() => setSaveFiltersModal(false)}
        Icon={<CloseIconModalHeader fill="#fff" />}
      >
        Cancel
      </MondayButton>
    ),
    !!deleteItem && !addedItem ? (
      <MondayButton
        buttonStyle={{ width: "180px" }}
        Icon={<SaveIcon />}
        onClick={onConfirmClickHandler}
      >
        Confirm Changes
      </MondayButton>
    ) : (
      <MondayButton
        buttonStyle={{ width: "140px" }}
        Icon={<SaveIcon />}
        onClick={onSaveClickHandler}
      >
        Save
      </MondayButton>
    )
  );
};

export default Footer;
