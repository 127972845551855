import React, { useState, useEffect } from "react";
import "./ProposalSidebar.scss";
import { Tabs } from "antd";
import {
  HelpTourIcon,
  PlusIcon,
  ProposalBuilderIcon,
  SignatureIcon,
} from "../../../../assets";
import { MondayButton } from "../../../commonComponents";
import { TestIcon } from "../../../SidebarPages/Contacts/ContactFomModal/Assets";
import { useRedux } from "../../../SidebarPages/hooks";
import {
  getServicesAsHtml,
  processWordHtml,
} from "../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/process";
import { useSelector } from "react-redux";
import ServiceTemplates from "./components/ServiceTemplates/ServiceTemplates";
import GeneralTemplatesSidebar from "./components/GeneralTemplates/GeneralTemplatesSidebar";
import Commands from "./components/Commands/Commands";
import Sidebar from "./Sidebar";
import { UserIcon } from "../../../SidebarPages/Communication/assets";
import {
  ContainerFilled,
  LayoutFilled,
  SignatureFilled,
} from "@ant-design/icons";
import SignatureBuilder from "./components/SignatureBuilder/SignatureBuilder";
import { EyeIcon } from "../../../SidebarPages/DynamicView/src";
import ProposalAssistantWrapper from "../ProposalHeader/components/ProposalAssistant/ProposalAssistantWrapper";
function ProposalSidebar({ editorRef }) {
  const [sidebarButtons, setSidebarButtons] = useState([
    {
      id: 1,
      name: "Content",
      icon: "contentIcon",
      isActive: true,
    },
    {
      id: 2,
      name: "Status",
      icon: "statusIcon",
      isActive: false,
    },
    {
      id: 3,
      name: "Take off Info",
      icon: "takeOffInfoIcon",
      isActive: false,
    },
    {
      id: 4,
      name: "Templates",
      icon: "templatesIcon",
      isActive: false,
    },
  ]);
  const [proposalData] = useRedux("proposalData");
  const [chosenView, setChosenView] = useState(0);
  const options = [
    {
      label: (
        <MondayButton className="mondayButtonBlue" hasIcon={false}>
          General
        </MondayButton>
      ),
      key: "GeneralTemplate",
      children: <GeneralTemplatesSidebar editorRef={editorRef} />,
    },
    {
      label: (
        <MondayButton className="mondayButtonBlue" hasIcon={false}>
          Services Templates
        </MondayButton>
      ),
      key: "desgins",
      // disabled: i === 28,
      children: (
        <>
          <ServiceTemplates editorRef={editorRef} />
        </>
      ),
    },
    {
      label: (
        <MondayButton className="mondayButtonBlue" hasIcon={false}>
          Services
        </MondayButton>
      ),
      key: "elevations",
      // disabled: i === 28,
      children: <CustomVariableCard id={1} />,
    },

    {
      label: (
        <MondayButton className="mondayButtonBlue" hasIcon={false}>
          Commands
        </MondayButton>
      ),
      key: "tinyMCECommands",
      children: <Commands editorRef={editorRef} />,
    },
  ];

  console.log("proposalData", proposalData);

  const onSwitchView = (key) => {
    let index = proposalSidebarData.findIndex((item) => item.key === key);
    setChosenView(index);
  };
  const chosenComponentData = proposalSidebarData[chosenView];
  const { component: ChosenSidebarComponent, props } = chosenComponentData;

  return (
    <div className="proposal-builder-sidebar_wrapper">
      <Sidebar
        onSwitchView={onSwitchView}
        proposalSidebarData={proposalSidebarData}
      />
      <div className="main-content">
        <ChosenSidebarComponent
          {...{
            editorRef,
          }}
        />
      </div>
    </div>
  );
}

export default ProposalSidebar;

export const CustomVariableCard = ({
  name,
  id,
  description,
  html,
  onDragEnd,
  onDragStart,
  commandHandler,
  keyDetail,
}) => {
  return (
    <div
      className="proposal-builder_custom_variable_card"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "10px",
        fontSize: "14px",
        width: "150px",
        // height: "200px",
        // margin: "8px",
        // backgroundColor: "lightgray",
      }}
      draggable={true}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onClick={() => {
        commandHandler(keyDetail);
      }}
    >
      <div className="previewIcon">
        <MondayButton hasIcon={false}> test</MondayButton>
      </div>
      {/* Card content */}
      <span>
        <h4 style={{ color: "#fff" }}> {name}</h4>
      </span>
    </div>
  );
};

export const ServicesMapHtml = ({ editorRef }) => {
  const [proposalData] = useRedux("proposalData");
  const [htmlTemp, setHtmlTemp] = useState("");
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  const onInitialize = () => {
    let temp = {};
    proposalData?.takeOff?.takeOffItem?.services?.forEach((service) => {
      if (service?.isScope !== false) return;
      let tempHtml = [];
      getServicesAsHtml([service], serviceDefinitions, tempHtml);
      temp[service.serviceId] = {
        data: service,
        html: tempHtml,
      };
    });
    setHtmlTemp(temp);
    console.log("onInitialize", temp);
  };
  useEffect(() => {
    const htmlTemp = getHtmlDataForAllServices(
      proposalData?.takeOff?.takeOffItem,
      proposalData?.estimation,
      serviceDefinitions,
      proposalData?.takeOff?.key
    );
    setHtmlTemp(htmlTemp);
    onInitialize();
  }, [proposalData, serviceDefinitions]);

  console.log("serviceDefinitions", serviceDefinitions);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        flexDirection: "row",
      }}
    >
      {!!htmlTemp &&
        Object.values(htmlTemp).map((item) => {
          console.log("item", item);
          return (
            <CustomVariableCard
              {...{
                name: item?.data?.label,
                html: item?.html?.[0],
                id: item?.data?.serviceId,
                onDragEnd: (event) => {
                  // const droppedHTML = item?.html?.[0];
                  event.preventDefault();
                  if (editorRef.current) {
                    // const editor = tinymce.get(editorRef.current.id);
                    // editor.execCommand("mceInsertContent", false, droppedHTML);
                  }
                  // return editorRef.current?.editor?.setContent(htmlTemp);
                },
              }}
            />
          );
        })}
    </div>
  );
};

const getHtmlDataForAllServices = (
  takeOff,
  estimation,
  serviceDefs,
  versionId
) => {
  console.log("getHtmlDataForAllServices", {
    takeOff,
    estimation,
    serviceDefs,
    versionId,
  });
  let tempHTML = processWordHtml({
    gridData: takeOff?.services || [],
    projectAddress: "Test Test",
    serviceDefs,
    accountName: "Test",
    estimation,
    versionId: takeOff?.id,
  });
  console.log("tempHTML", tempHTML);

  return tempHTML;
};
export const proposalSidebarData = [
  {
    icon: <PlusIcon />,
    component: GeneralTemplatesSidebar,
    key: "GeneralTemplate",
    hoverText: "General Templates",
  },
  {
    icon: <PlusIcon />,
    component: ProposalAssistantWrapper,
    key: "ProposalAssistant",
    hoverText: "Proposal Assistant",
  },
  {
    icon: <SignatureFilled />,
    component: SignatureBuilder,
    key: "SignatureSidebar",
    hoverText: "Signature Templates",
  },
  // { icon: <UserIcon /> },
  // { icon: <ContainerFilled /> },
  // { icon: <LayoutFilled /> },
  // <RightCircleFilled />,
];

const Card = ({ title, description, imageUrl, buttonText }) => {
  return (
    <div className="card">
      <img src={imageUrl} alt={title} className="card-image" />
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
        {buttonText && <button className="card-button">{buttonText}</button>}
      </div>
    </div>
  );
};
