import { debounce, isEmpty } from "lodash";
import { RedirectIcon } from "../../../../../icons";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../utils/searchFetchForInput";
import { MondayButtonIcon } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import IndividualLoader from "../../../../IndividualLoader";
import { compareIncluding } from "../../../../SidebarPages/utils";
import { RenderDynamicComponents } from "../../Components";
import { message } from "antd";
import { useMemo } from "react";
import { isPartOfTeam } from "../../../../SidebarPages/Documentation/Tabs/helper";

function JobsiteAddressField({
  form,
  userConfiguration,
  isDarkMode,
  projectNames,
  selectedDrawingType,
  projectId,
  setSelectedProject,
  projects,
  projectPopoverVisible,
  ProjectPopoverContent,
  setLoadingProjects,
  setProjects,
  setCurrentPageKey,
  currentPageKey,
  loadingProjects,
  allTeams,
  setSelectedTeam,
  selectedProject,
}) {
  const hasProjectAccess = useMemo(() => {
    const isPM = Array.isArray(selectedProject?.projectManager)
      ? selectedProject?.projectManager?.some(
          (el) => el?.identityId === userConfiguration?.identityId
        )
      : false;
    let prCreator =
      selectedProject?.createdBy?.name || selectedProject?.createdBy;
    if (
      userConfiguration?.groupName !== "Admin" &&
      !isPartOfTeam(selectedProject?.teamsConfiguration, userConfiguration) &&
      prCreator !== userConfiguration?.nameOfUser &&
      !isPM
    ) {
      return false;
    } else {
      return true;
    }
  }, [userConfiguration, selectedProject]);
  const field = [
    {
      className: "jobsiteAddress",
      label: "Jobsite Address",
      formItemName: "jobSiteAddress",
      placeholder: "Click and Search...",
      dropDownClassName: isDarkMode && "darkDropDown",
      required: true,
      showSearch: true,
      type: "select",
      options: projectNames,
      disabled: !selectedDrawingType || !!projectId,
      onSelect: (value) => {
        function getTeamId(teamName) {
          let teamId = allTeams?.find((e) => e?.teamName === teamName)?.teamId;
          return !!teamId ? [teamId] : [];
        }
        const project = projects?.find(({ projectName }) =>
          compareIncluding(projectName, value)
        );
        setSelectedProject(project);
        const projectTeams = project.teamsConfiguration.map((team) => ({
          value: team?.value || "Team",
          members: team?.members || [],
          teamId: !!team.teamId ? team.teamId : getTeamId(team?.value),
        }));
        form.setFieldValue(
          "permitTeam",
          projectTeams?.map(({ value }) => value)
        );
        setSelectedTeam([...projectTeams]);
      },
      style: { minWidth: 305 },
      popoverVisible: projectPopoverVisible,
      popoverContent: ProjectPopoverContent,
      popoverPlacement: "top",
      style: { width: "100%" },
      onSearch: debounce(async (value) => {
        return await searchFetchInput({
          table: "projects",
          idKey: "projectId",
          searchKey: "projectName",
          searchValue: value,
          userConfiguration,
          setLoading: setLoadingProjects,
          setRecords: setProjects,
        });
      }, 500),
      onPopupScroll: async (e) => {
        return await handlePopupScroll({
          e,
          table: "projects",
          idKey: "projectId",
          currentPageKey,
          setLoading: setLoadingProjects,
          setRecords: setProjects,
          userConfiguration,
          setCurrentPageKey,
        });
      },
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 10px",
              }}
            >
              {loadingProjects ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <IndividualLoader />
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: 10,
        alignItems: "flex-end",
      }}
    >
      {RenderDynamicComponents(field, { form })}
      {selectedProject?.projectId && hasProjectAccess && (
        <MondayButtonIcon
          {...{
            Icon: <RedirectIcon />,
            name: "Go to Projects",
            style: { marginBottom: 15 },
            onClick: () => {
              window.open(`/projects/${selectedProject?.projectId}`, "_blank");
            },
          }}
        />
      )}
    </div>
  );
}

export default JobsiteAddressField;
