import { driveApi } from "../../../../../../../../../integrations/DriveRequest";

export const getGoogleDrivePermissions = async ({
  userName,
  accessToken,
  gDriveCredentials,
}) => {
  const driveRequest = driveApi({ accessToken });

  const result = await Promise.all(
    gDriveCredentials?.map(({ credentialValue }) =>
      driveRequest.permissionList(credentialValue)
    )
  );

  const response = await Promise.all(result.map((e) => e.json()));

  const allFoldersAccess = {};
  const foldersAccess = {};

  gDriveCredentials.forEach((credential, index) => {
    allFoldersAccess[credential.credentialKey] = response[
      index
    ].permissions.find(({ emailAddress }) => emailAddress === userName);

    foldersAccess[credential.credentialKey] = response[index].permissions.find(
      ({ emailAddress }) => emailAddress === userName
    )?.role;
  });

  const formFields = {};
  Object.entries(foldersAccess).forEach(([key, value]) => {
    if (value === "writer" || value === "reader") {
      formFields[key] = value;
    } else formFields[key] = "none";
  });

  return { allFoldersAccess, foldersAccess, formFields };
};
