import RangePickerContainer from "./Components/RangePickerContainer/RangePickerContainer";
import { getScheduleOfValues } from "../../../../../../../actions/scheduleOfValues";
import ScheduleOfValue from "./Components/ScheduleOfValue/ScheduleOfValue";
import Informations from "./Components/Informations/Informations";
import ModalFooter from "./Components/ModalFooter/ModalFooter";
import MonthPicker from "./Components/MonthPicker/MonthPicker";
import { Cancel } from "../../../../../DynamicView/src";
import Header from "./Components/Header/Header";
import "./new-application.scss";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { sortBy } from "lodash";
import { Modal, Tour } from "antd";
import ScheduleDetailsView from "./Components/ScheduleOfValue/ScheduleDetailsView";
import WarningModal from "../../../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as WarningIcon } from "../../../../../../../icons/warningTriangle.svg";
import { ReactComponent as CloseIcon } from "../../../../../DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { MondayButton } from "../../../../../../commonComponents/index";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { updateDocumentTitle } from "../../../../../../../utils";
import { XIcon } from "../../../../../Communication/assets";
import ToDoModal from "src/components/SidebarPages/ToDos/ToDoModal";
import ApplicationView from "../../ApplicationView/ApplicationView";
import ContextProvider from "../../ApplicationView/Context/Context";

const NewApplication = ({ visible, closeModal, projectId, isNextStep }) => {
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { isLoading: isLoadingSOVs, scheduleOfValues } = useSelector(
    (state) => state.scheduleOfValues
  );
  const {
    isLoading: isCreatingNewApplication,
    applications,
    newlyCreatedApplication,
  } = useSelector((state) => state.applications);

  const [redirectToApplication, setRedirectToApplication] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [chosenSOV, setChosenSOV] = useState(null);
  const [custom, setCustom] = useState(false);
  const [sovs, setSovs] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const [isOpenForNextStep, setIsOpenForNextStep] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Project Management")
    ?.subCategories[5]?.videos[4].videoLink;

  // In order to not press save multiple times and create many Applications, wait until it is finished creating
  // True only if is not creating new application yet, isCreatingNewApplication is initially false and it becomes true when we submit
  const canCreate = !isCreatingNewApplication && selectedMonth && !!chosenSOV;

  useEffect(() => {
    dispatch(getScheduleOfValues(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!isLoadingSOVs && scheduleOfValues) {
      const data = !isLoadingSOVs && sortBy(scheduleOfValues, ["SOVNo"]);
      if (data?.length === 1) setChosenSOV(data?.[0]?.scheduleId);

      setSovs(data);
    }
  }, [isLoadingSOVs, scheduleOfValues]);

  // Handle redirection to newly created Application
  useEffect(() => {
    // If we are redirecting to newly created Application
    // newlyCreatedApplication will be the application that we just created and it will be reseated to null, before creating another application
    if (redirectToApplication && newlyCreatedApplication && !!!isNextStep) {
      navigate(`/applications/${newlyCreatedApplication.applicationId}`);
    }
  }, [redirectToApplication, newlyCreatedApplication]);

  const lastPeriod = applications
    ?.filter(
      (application) =>
        application?.projectId === projectId &&
        application?.scheduleId === chosenSOV
    )
    ?.reduce(
      (acc, cur) => (acc?.applicationNo > cur?.applicationNo ? acc : cur),
      null
    )?.periodTo?.end;

  const onCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    closeModal();
    setSelectedMonth(null);
    setDueDate(null);
    setFormChanged(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const tourSteps = [
    {
      title: "Event Tutorial",
      description: (
        <div>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => {
                setShowVideoTutorial(true);
                setTourOpen(false);
              },
              style: { display: "flex", justifyContent: " center" },
              Icon: <VideoTutorialIcon />,
            }}
          >
            Watch Video
          </MondayButton>
        </div>
      ),
      className: "custom-tour-light",
      placement: "center",
    },
  ];
  return (
    <Modal
      centered
      wrapClassName={`new-application-modal ${
        isDarkMode && "new-application-modal-dark"
      }`}
      open={visible} //&& !!!isOpenForNextStep}
      closeIcon={<XIcon />}
      destroyOnClose={true}
      onCancel={() => {
        formChanged ? setCancelModalVisible(true) : onCancel();
      }}
      // title="New Requisition"
      title={
        <CustomModalHeader
          title={"New Requisition"}
          onClick={() => {
            setTourOpen(true);
          }}
        />
      }
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "New Requisition" });
      }}
      footer={
        <ModalFooter
          {...{
            setRedirectToApplication,
            setSelectedMonth,
            selectedMonth,
            applications,
            closeModal: () => {
              if (isNextStep) return;
              updateDocumentTitle(); // reset document title to "Lead Manager";
              closeModal();
            },
            setDueDate,
            chosenSOV,
            canCreate,
            projectId,
            dueDate,
            sovs,
            isNextStep,
            getApplicationId: (id) => {
              setIsOpenForNextStep(id);
            },
          }}
        />
      }
    >
      <Informations {...{ lastPeriod, selectedMonth, isDarkMode }} />
      <div className="container">
        <ScheduleOfValue
          {...{
            sovs,
            chosenSOV,
            setChosenSOV,
            setFormChanged,
            setSelectedMonth,
            setDueDate,
          }}
        />

        <div className="rightSideContainer">
          <Header
            {...{
              setSelectedMonth,
              selectedMonth,
              setDueDate,
              isDarkMode,
              canCreate,
              setCustom,
              dueDate,
              custom,
            }}
          />
          {custom ? (
            <RangePickerContainer
              {...{
                setSelectedMonth,
                selectedMonth,
                lastPeriod,
                custom,
                chosenSOV,
                setFormChanged,
                cancelModalVisible,
                isDarkMode,
              }}
            />
          ) : (
            <MonthPicker
              {...{
                setSelectedMonth,
                selectedMonth,
                setDueDate,
                lastPeriod,
                chosenSOV,
                dueDate,
                custom,
                setFormChanged,
                cancelModalVisible,
                isDarkMode,
              }}
            />
          )}
        </div>
      </div>
      <ScheduleDetailsView
        sovs={sovs}
        chosenSOV={chosenSOV}
        setFormChanged={setFormChanged}
      />
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          wrapClassName="warning"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<Tick />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          placement="center"
          zIndex={1051}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Requisition Tutorial",
            zIndex: 1052,
          }}
        />
      )}
      {isOpenForNextStep && (
        <ContextProvider>
          <ApplicationView
            {...{ isNextStep, appId: isOpenForNextStep, closeModal }}
          />
        </ContextProvider>
      )}
    </Modal>
  );
};

export default NewApplication;
