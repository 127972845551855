import { API } from "aws-amplify";
import { keys } from "../Roles/RolesData";
import _ from "lodash";
export const HeaderListTitles = (departmentName) => [
  {
    title: `${departmentName} Users`,
  },
  {
    title: "Last Log In",
  },
  {
    title: "Remove User",
  },
];

export const details = (phone, email, department) => [
  {
    title: "Phone",
    value: phone,
  },
  {
    title: "Email",
    value: email,
  },
  {
    title: "Department",
    value: department,
  },
];

export const ifDepartment = async (groupConfigs, department) => {
  const current = {
    Route: groupConfigs.Route,
    Search: groupConfigs.Search,
    Sidebar: groupConfigs.Sidebar,
  };
  let submitList = [];
  let returnValue = await API.get("userGroups", "/userGroups").then((res) => {
    res
      .filter(
        ({
          groupConfigs: { routeConfig, searchConfig, sidebarConfig },
          departmentName,
        }) =>
          routeConfig !== undefined &&
          sidebarConfig !== undefined &&
          searchConfig !== undefined &&
          departmentName === department
      )
      .map(
        ({
          groupConfigs: {
            routeConfig: Route,
            // searchConfig: Search,
            sidebarConfig: Sidebar,
          },
        }) => {
          const list = { Route, Sidebar };
          let listKeys = keys(list);
          let value = listKeys.map((e) => {
            let exist = list[e].map((el) =>
              current[e].find((ele) => ele.title === el.title)
            );
            if (exist.includes(undefined)) {
              return false;
            } else {
              let existList = exist.filter((res) => res !== undefined);
              let exis = [];
              existList
                .filter(({ departmentName }) => departmentName === department)
                .map((r) => {
                  if (e === "Route") {
                    if (current[e].find((ele) => ele.title === r.title)) {
                      if (
                        r.write === current[e].find((ele) => ele.title).write
                      ) {
                        exis.push(true);
                      } else exis.push(false);
                    }
                  } else {
                    exis.push(true);
                  }
                });
              if (exis.includes(false)) {
                return false;
              } else {
                return true;
              }
            }
          });
          submitList.push(value);
        }
      );
    let index = [];
    for (var i = 0; i < submitList.length; i++) {
      if (
        JSON.stringify(submitList[i]) === JSON.stringify([true, true, true])
      ) {
        index.push(i);
      }
    }
    if (index.length === 0) {
      return undefined;
    } else {
      return res.filter(({ departmentName }) => departmentName === department)[
        index[0]
      ].groupName;
    }
  });
  return returnValue;
};

export const ifEdited = (groupConfigs, template) => {
  let current = {
    Route: groupConfigs.Route,
    Search: groupConfigs.Search,
    Sidebar: groupConfigs.Sidebar,
  };
  let previous = {
    Route: template?.routeConfig,
    Search: template?.searchConfig,
    Sidebar: template?.sidebarConfig,
  };
  return !_.isEqual(current, previous);
};
