import { useSelector } from "react-redux";

import AddItem from "./AddItem";
import { useRedux } from "../../../hooks";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets";
import InputComponent from "../../../Fleet/components/InputComponent/InputComponent";

import "./ItemSelect.scss";

const ItemSelect = ({ value, data }) => {
  const [isWritable] = useRedux("billIsWritable");

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { form, services, billData, billItems } = useInitialBillContext();
  const { setRowData, setSelectedServices } = useCreateBillContext();

  const options = billItems.map((item) => ({
    ...item,
    label: item.itemName,
    value: item.itemName,
    id: item.itemId,
  }));

  const handleSelect = (_, option) => {
    const selectedItem = billItems.find(
      (item) => item.itemId === option.itemId
    );

    const currentServices = form.getFieldValue("billServices");

    if (selectedItem) {
      setRowData((prev) => {
        return prev.map((row) => {
          if (row.itemId === data.itemId) {
            return {
              ...row,
              itemName: selectedItem.itemName,
              itemCategory: selectedItem.itemCategory,
              itemDescription: selectedItem.itemDescription,
              itemService: selectedItem.itemService,
            };
          }
          return row;
        });
      });

      const selectedService = services.find(
        (service) => service.serviceName === selectedItem.itemService
      );

      if (selectedService) {
        setSelectedServices((prev) => [
          ...prev.filter(
            (service) => service.serviceName !== selectedService.serviceName
          ),
          selectedService,
        ]);

        if (currentServices) {
          form.setFieldValue("billServices", [
            ...currentServices.filter(
              (service) => service !== selectedItem.itemService
            ),
            selectedItem.itemService,
          ]);
          return;
        }

        form.setFieldValue("billServices", [selectedItem.itemService]);
      }
    }
  };

  return (
    <InputComponent
      type="select"
      suffixIcon={<DropDownArrow />}
      defaultValue={value}
      dropdownRender={(menu) => (
        <>
          {menu}
          <AddItem />
        </>
      )}
      className="bill-item-select"
      disabled={!!billData && !isWritable}
      name="itemName"
      showSearch
      placeholder="Select an item"
      optionFilterProp="children"
      popupClassName={isDarkMode && "darkDropDown"}
      onSelect={handleSelect}
      customOptions={options}
    />
  );
};

export default ItemSelect;
