import { ClockCircleOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { findFieldOptionByNameOrId } from "../../../../../../utils";
import { Avatar, Tag } from "antd";
import Execution from "../../../../../pages/Settings/settingsComponents/PostEventsAutomationsConfig/components/Execution";
import { useState } from "react";
import AutomationResponses from "../AutomationResponses/AutomationResponses";

const ActiveEventsAutomations = () => {
  const [showLogs, setShowLogs] = useState(false);

  const postEventsAutomations = findFieldOptionByNameOrId({
    fieldName: "Post Events Automations Configurations",
  }).filter(({ shouldPostExecute }) => shouldPostExecute);

  return (
    <div className="active-automations">
      {postEventsAutomations.map((automation) => {
        return (
          <div
            className="active-automation"
            key={automation.uid}
            onClick={() =>
              setShowLogs((prev) => {
                if (typeof prev === "object") {
                  return false;
                } else {
                  return automation;
                }
              })
            }
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div className="icon">
                <Avatar icon={<ThunderboltOutlined />} />
              </div>
              <div className="description">
                <div className="name">{automation.automationName}</div>

                <div className="infos">
                  <Tag>{automation.categoryName}</Tag>
                  <Tag icon={<ClockCircleOutlined />}>Executes after event</Tag>
                </div>
              </div>
            </div>
            <div className="list-item-meta-unglobal">
              <Execution data={automation} />
            </div>
          </div>
        );
      })}
      {showLogs && (
        <AutomationResponses
          {...{
            title: `Responses for ${showLogs?.automationName}`,
            responses: showLogs?.cronLogs?.responses.map((res) => ({
              ...res,
              automationName: showLogs?.automationName,
            })),
            clickedIndex: undefined,
            setClickedIndex: () => {},
          }}
        />
      )}
    </div>
  );
};

export default ActiveEventsAutomations;
