import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "./columnDefs";
import { useSelector } from "react-redux";
import "../FleetItems/FleetItems.scss";

function LaborCard(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      className={`workOrderCardItemsContainer ${
        isDarkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
    >
      {!!props?.params?.rowData?.length ? (
        <AgGridReact
          {...{
            rowData: props?.params?.rowData?.concat({
              hoursWorked: props?.params?.rowData?.reduce(
                (acc, val) => acc + parseFloat(val?.hoursWorked),
                0
              ),
              total: props?.params?.rowData?.reduce(
                (acc, val) =>
                  acc + parseFloat(val?.total) ??
                  parseFloat(val?.rate) * parseFloat(val?.hoursWorked),
                0
              ),
            }),
            columnDefs,
            defaultColDef: {
              resizable: false,
              enableColResize: false,
              enableRowGroup: false,
              sortable: false,
              filter: false,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      ) : (
        <div
          className="noItems"
          style={{ color: isDarkMode ? "#fff" : "#333238" }}
        >
          No labor information found! Edit the card to add information!
        </div>
      )}
    </div>
  );
}

export default LaborCard;
