import moment from "moment";
import {
  apiPut,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../../utils";
import { updateOtherUser } from "../../../../../../../../../../actions";

//Suspends or reactivates the user
const changeUserActivationStatus = async ({
  user,
  wsSessions,
  saveAddedLogs,
  dispatch,
}) => {
  const { identityId, isSuspended, suspendedDate, nameOfUser } = user || {};

  showLoadingMsg();
  await apiPut("userConfiguration", identityId, {
    isSuspended: !isSuspended,
    suspendedDate: moment().valueOf(),
    reactivatedDate: !!suspendedDate ? moment().valueOf() : "",
  }).then(async (r) => {
    //if used get suspended log him out form all active devices
    if (!isSuspended === true) {
      try {
        wsSessions.send(
          JSON.stringify({
            request: "force-logout",
            body: {
              userId: identityId,
              forceLogout: true,
            },
          })
        );
      } catch (e) {
        console.error("Error sending logout request", e);
      }
    }
    saveAddedLogs({
      recordId: identityId,
      recordName: nameOfUser,
      actionType: isSuspended ? "Reactivate" : "Suspend",
      category: "Users Actions",
      currentData: { [nameOfUser]: !isSuspended ? "Suspended" : "Active" },
      previousData: { [nameOfUser]: isSuspended ? "Suspended" : "Active" },
      updatedKeys: [
        {
          label: `${nameOfUser} Status`,
          previousValue: isSuspended ? "Suspended" : "Active",
          updatedValue: !isSuspended ? "Suspended" : "Active",
        },
      ],
    }).catch((err) => console.log("Error Creating Logs: ", err));
  });
  dispatch(
    updateOtherUser({
      identityId,
      isSuspended: !isSuspended,
      suspendedDate: moment().valueOf(),
      reactivatedDate: !!suspendedDate ? moment().valueOf() : "",
    })
  );
  showSuccessMsg();
};

export default changeUserActivationStatus;
