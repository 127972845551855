import React, { useState } from "react";
import DraggableSort from "../DraggableSort/DraggableSort";
import { NormalSizedModal } from "../../../../../../Fleet/components";
import { MondayButton } from "../../../../../../../commonComponents";
import { SortAscendingOutlined, UndoOutlined } from "@ant-design/icons";
import { CheckIconModal, CloseIconModal } from "../../../../../../../../assets";
import { useRedux } from "../../../../../../hooks";
import { getPhasesConfigIfAny } from "../process/process";
import { useEstVersionsContext } from "../../../../../components/EstVersionsTab/context";
import Swal from "sweetalert2";
import { message } from "antd";

function CustomReorder({ versionId }) {
  const [visible, setVisible] = useState(false);
  const [itemsToOrder, setItemsToOrder] = useState([]);
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const { estimation, updateEstimation } = useEstVersionsContext();
  const customOrder = estimation?.versionServices?.[versionId]?.customWordOrder;

  const onDataInit = (data) => {
    let filteredServices = gridData?.filter((el) => el?.isScope !== true);
    // Now We continue with each service
    let tempServices = getPhasesConfigIfAny(
      filteredServices,
      estimation,
      versionId
    );
    if (tempServices.length > 0) {
      setItemsToOrder(
        tempServices?.map((el, idx) => {
          return {
            id: idx,
            label: el?.label,
          };
        })
      );
    }
    console.log("customSort", tempServices);
  };

  const onOpen = () => {
    setVisible(true);
    onDataInit();
  };

  const onDeleteAllCustom = () => {
    let currentTakeOff = estimation?.versionServices?.[versionId];
    if (!!currentTakeOff) {
      currentTakeOff.customWordOrder = null;
      updateEstimation(
        {
          versionServices: {
            ...estimation?.versionServices,
            [versionId]: currentTakeOff,
          },
        },
        {
          action: "deleteCustom",
          versionName: currentTakeOff?.versionName,
          versionId: versionId,
          actionType: "Edit",
        }
      );
      Swal.fire("Success", "Changes Saved Successfully", "success").then(() => {
        setVisible(false);
      });
    } else {
      message.error("Something went wrong, please try again later");
    }
  };

  const onSaveChanges = () => {
    let currentTakeOff = estimation?.versionServices?.[versionId];
    if (!!currentTakeOff) {
      currentTakeOff.customWordOrder = itemsToOrder;
      updateEstimation(
        {
          versionServices: {
            ...estimation?.versionServices,
            [versionId]: currentTakeOff,
          },
        },
        {
          action: "saveChanges",
          versionName: currentTakeOff?.versionName,
          versionId: versionId,
          actionType: "Edit",
        }
      );
      Swal.fire("Success", "Changes Saved Successfully", "success").then(() => {
        setVisible(false);
      });
    } else {
      message.error("Something went wrong, please try again later");
    }
  };
  return (
    <div>
      <MondayButton
        className="mondayButtonYellow"
        Icon={<SortAscendingOutlined />}
        onClick={onOpen}
      >
        {" "}
        Custom Reorder
      </MondayButton>
      {visible && (
        <NormalSizedModal
          title="Sort Phases according to your preferences..."
          {...{
            visible,
            setVisible,
            closable: true,
            onCancel: () => setVisible(false),
            maskClosable: true,
            // closeIcon: <CloseIconModal />,
            centered: true,
          }}
        >
          <div
            style={{
              padding: "-10px 0px 0px 20px",
              minWidth: "500px",
            }}
          >
            <DraggableSort {...{ itemsToOrder, setItemsToOrder }} />{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "30px",
                paddingBottom: "20px",
              }}
            >
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseIconModal />}
                onClick={() => setVisible(false)}
              >
                Close
              </MondayButton>{" "}
              {customOrder && (
                <MondayButton
                  onClick={onDeleteAllCustom}
                  Icon={<UndoOutlined />}
                  className="mondayButtonBlue"
                >
                  Reset from scratch
                </MondayButton>
              )}
              <MondayButton Icon={<CheckIconModal />} onClick={onSaveChanges}>
                Save Changes
              </MondayButton>
            </div>
          </div>
        </NormalSizedModal>
      )}
    </div>
  );
}

export default CustomReorder;
