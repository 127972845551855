import { actions, initialState } from "./init";
export const chargeReducer = (state = initialState, action) => {
  switch (action?.type) {
    case actions.SET_ROW_DATA:
      return {
        ...state,
        rowData: action.payload,
      };
    case actions.SET_CHARGE_TO_VIEW: {
      return { ...state, chargeToView: action.payload };
    }
    case actions.SET_NEW_CHARGE_VISIBLE: {
      return {
        ...state,
        newChargeVisible: action.payload,
      };
    }
    case actions.SET_CHARGE_ITEMS_MODAL: {
      return {
        ...state,
        chargeItemsModal: action.payload,
      };
    }
    case actions.SET_CHARGE_TO_SAVE: {
      return {
        ...state,
        chargeToSave: action.payload,
      };
    }
    case actions.SET_ITEMS_GROUP: {
      return {
        ...state,
        itemsGroup: action.payload,
      };
    }
    case actions.SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.payload,
      };
    }
    case actions.SET_SELECTED_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload,
      };
    }
    case actions.SET_APPLY_CREDIT: {
      return {
        ...state,
        applyCredit: action.payload,
      };
    }
    case actions.SET_CREDIT_APPLIED: {
      return {
        ...state,
        creditApplied: action.payload,
      };
    }
    case actions.SET_UPDATED_CREDIT_APPLIED: {
      return {
        ...state,
        updatedCreditApplied: action.payload,
      };
    }
    case actions.SET_REMAINING_CREDIT: {
      return {
        ...state,
        remainingCredit: action.payload,
      };
    }
    case actions.SET_APPLIED_CREDIT: {
      return {
        ...state,
        appliedCredit: action.payload,
      };
    }
    case actions.SET_CHARGE: {
      return {
        ...state,
        charge: action.payload,
      };
    }
    case actions.SET_WHERE_TO_FIND: {
      return {
        ...state,
        whereToFind: action.payload,
      };
    }
    case actions.SET_FROM_OBJECT: {
      return {
        ...state,
        fromObject: action.payload,
      };
    }
    case actions.SET_SELECTED_GROUP: {
      return {
        ...state,
        selectedGroup: action.payload,
      };
    }
    case actions.SET_ACTIONS: {
      return {
        ...state,
        actions: action.payload,
      };
    }
    case actions.SET_INITIAL_STATES: {
      return {
        ...state,
        initialStates: action.payload,
      };
    }
    default:
      return state;
  }
};
