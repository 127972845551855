import { useEffect, useState } from "react";
import { Map } from "../../../../commonComponents";
import { state } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { NotFoundAddress } from "../../src";
import "./Address.scss";
import { useSelector } from "react-redux";
import {
  MAP_DARK,
  MAP_DEFAULT,
} from "../../../Scheduling/Tabs/SchedulingMap/schedulingMapData";

const Address = (props) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { params, edited, setNewValues } = props || {};
  const { polygonCompleted, rowData } = params || [];

  const [values, setValues] = useState(params);

  const geoFenceInfo = rowData?.geoFenceInfo || [];
  let { coordinates = [] } = values;
  // console.log({ geoFenceInfo });

  //--geoFenceInfoOpportunities--//
  const idOfOpportunitites = rowData?.opportunityId;

  useEffect(() => {
    if (edited) {
      values.coordinates = edited;
      setNewValues(state(values));
      setValues(state(values));
    }
  }, [JSON.stringify(edited)]);

  //used to change the shipping coordinates in the map component
  //used when adding a new billing address that we need to be the same as the shipping
  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(values)) {
      setNewValues(params);
      setValues(params);
    }
  }, [JSON.stringify(params)]);

  const showDrawingManager = props?.id === "address";
  return (
    <div
      className="AddressBody"
      onMouseEnter={() => params?.disableDrag(true)}
      onMouseLeave={() => params?.disableDrag(false)}
    >
      {!!coordinates[0]?.coordinates?.lat &&
      !!coordinates?.[0]?.coordinates?.lng ? (
        <Map
          props={props}
          places={[
            {
              coordinates: coordinates
                ?.filter((el) => el?.coordinates)
                ?.map(({ coordinates }) => coordinates),
            },
          ]}
          geoFenceInfo={geoFenceInfo}
          idOfOpportunitites={idOfOpportunitites}
          polygonCompleted={polygonCompleted}
          rowData={rowData}
          style={{ height: 400 }}
          showDrawingManager={showDrawingManager}
          // mapOptions={{
          //   styles: isDarkMode ? MAP_DARK : MAP_DEFAULT,
          // }}
        ></Map>
      ) : (
        <span className="noAddressFound">
          <NotFoundAddress />
        </span>
      )}
    </div>
  );
};
export default Address;
