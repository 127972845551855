import { getMonthsTimestamps } from "../../../../../utils"
import { months } from "../../../../addWidgetsModalData"

export const maintenance = ({ tables, circleProgressPicker }) => {
  return {
    createdAt: () => ({
      percent: (
        (tables?.Maintenance?.filter(
          (a) =>
            a?.createdAt >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.createdAt <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length /
          tables?.Maintenance?.filter((a) => a?.createdAt >= getMonthsTimestamps()[0]).length) *
        100
      ).toFixed(1),

      data: tables?.Maintenance?.filter(
        (a) =>
          a?.createdAt >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
          a?.createdAt <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
      ).length,
    }), //createdAt
  } //Maintenance
}
