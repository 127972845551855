/**
 *
 * @key : Documentation categories
 * @value : Route config titles
 */
const accessCategories = {
  Claims: "Claims/View",
  Clients: "Accounts/View",
  Contacts: "Contacts/View",
  Drivers: "Fleet Drivers/View",
  Estimations: "Estimations/View",
  Fleet: "Fleets/View",
  "Fleet-Drivers": "Fleet Drivers/View",
  Hearings: "Hearings/View",
  Inspections: "Inspections/View",
  Leads: "Leads/View",
  Opportunities: "Opportunities/View",
  "Permit-Drawings": "Permit Drawings/View",
  Projects: "Projects/View",
  Scheduling: "Scheduling/View",
  Services: "",
};

/**
 *
 * @param {Array} team - array of teamsConfiguration
 * @param {object} userConfiguration - user configuration object
 * @returns {boolean} - is user part of the team
 */
export function isPartOfTeam(team = [], userConfiguration) {
  const userIdentityId = userConfiguration?.identityId;
  if (!Array.isArray(team) || team?.length === 0 || !userIdentityId)
    return false;
  const allMembers = team?.flatMap((item) => item?.members);
  const isUserInTeam = allMembers?.some(
    (member) => member?.identityId === userIdentityId
  );

  return isUserInTeam;
}

/**
 *
 * @param {string} categoryName
 * @returns {Boolean} - has access to the category
 */
function findAccessRight(categoryName, userConfiguration) {
  return userConfiguration?.routeConfig?.find(
    ({ title }) => title === accessCategories[categoryName]
  );
}

/**
 *
 * @param {Array} rowData - response from the server with all documentation
 * @param {object} userConfiguration - user configuration object
 * @returns {Array} - filtered documentation
 */
export function filterDocumentationByAccess(rowData, userConfiguration) {
  const isAdmin = userConfiguration.groupName?.toLowerCase().includes("admin");

  if (isAdmin) {
    return rowData?.filter((el) => {
      const categoryAccess = findAccessRight(
        el?.categoryName,
        userConfiguration
      );
      return !accessCategories?.[el?.categoryName] || !!categoryAccess;
    });
  } else {
    return rowData?.filter((el) => {
      const categoryAccess = findAccessRight(
        el.categoryName,
        userConfiguration
      );
      const isInTeam =
        Array.isArray(el?.teamsConfiguration) &&
        el?.teamsConfiguration?.length > 0
          ? isPartOfTeam(el?.teamsConfiguration, userConfiguration)
          : false;
      const createdBy =
        typeof el?.createdBy === "string"
          ? el?.createdBy === userConfiguration?.nameOfUser
          : el?.createdBy?.nameOfUser === userConfiguration?.nameOfUser;
      return (
        (!accessCategories?.[el?.categoryName] || !!categoryAccess) &&
        (isInTeam || createdBy)
      );
    });
  }
}
