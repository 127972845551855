import { Form, Input, InputNumber } from "antd";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../../../utils";
import { useHoistPriceCalculatorContext } from "../../context";
import { currencyInputProps } from "../../data";
import "./TotalsSection.scss";

const TotalsSection = () => {
  const { getTotals } = useHoistPriceCalculatorContext();
  console.log("getTotals", getTotals);

  return (
    <div className="price-calculator-totals-section">
      {/* <Form.Item label="Addons Total" shouldUpdate>
        {({ getFieldValue }) => (
          <InputNumber
            value={getTotals(getFieldValue).addonsTotal}
            disabled
            style={{ minWidth: 200 }}
            {...currencyInputProps}
          />
        )}
      </Form.Item>

      // <div className="input-divider" /> */}

      <div className="totals-inputs-container">
        <Form.Item label="Subtotal" shouldUpdate>
          {({ getFieldValue }) => (
            <InputNumber
              value={getTotals(getFieldValue).price}
              disabled
              style={{ minWidth: 200 }}
              {...currencyInputProps}
            />
          )}
        </Form.Item>

        <div className="input-divider" />

        <Form.Item label="Tax Rate" shouldUpdate>
          {({ getFieldValue }) => (
            <Input
              value={getTotals(getFieldValue).taxRate * 100}
              suffix="%"
              style={{ width: 80 }}
              disabled
            />
          )}
        </Form.Item>

        <div className="input-divider" />

        <Form.Item label="Tax Amount" shouldUpdate>
          {({ getFieldValue }) => (
            <InputNumber
              value={+getTotals(getFieldValue).taxAmount.toFixed(2)}
              disabled
              style={{ minWidth: 200 }}
              {...currencyInputProps}
            />
          )}
        </Form.Item>

        <div className="input-divider" />

        <Form.Item label="Rentals Total" shouldUpdate>
          {({ getFieldValue }) => (
            <InputNumber
              value={+getTotals(getFieldValue).rentalsTotal.toFixed(2)}
              disabled
              style={{ minWidth: 200 }}
              {...currencyInputProps}
            />
          )}
        </Form.Item>
        <div className="testtt">
          <Form.Item label="Rentals Total" shouldUpdate>
            {({ getFieldValue }) =>
              formatCurrency(
                forceToNumber(+getTotals(getFieldValue).rentalsTotal.toFixed(2))
              )
            }
          </Form.Item>{" "}
        </div>
      </div>

      <div className="price-totals-footer">
        <Form.Item noStyle shouldUpdate>
          {() => `Total: ${formatCurrency(+getTotals().totalPrice)}`}
        </Form.Item>
      </div>
    </div>
  );
};

export default TotalsSection;
