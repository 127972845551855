import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { compareIncluding } from "src/components/SidebarPages/utils";

export const onGeneratePDFDrive = (mainObj, selectedUser, base64) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const logo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const dataPush = (title, childNum, access) => {
    return [
      {
        text: title,
        margin: [5 + 15 * childNum, 0, 0, 0],
      },
      {
        text: access,
      },
    ];
  };
  const getLength = (currPath) => {
    let path = currPath.split("-|-").filter(Boolean);
    return path.length;
  };

  function pushDataToPDF(obj) {
    let arr = [];
    Object.keys(obj).forEach((key) => {
      let subFolders = obj[key].subfolders;
      let currPath = obj[key].path;
      let currPathLength = getLength(currPath);
      let access = obj[key].permissions;
      let hyphens = "-".repeat(currPathLength);
      let title = `${hyphens} ${key}`;
      arr.push(dataPush(title, currPathLength, access));
      if (Object.keys(subFolders).length) {
        arr.push(...pushDataToPDF(subFolders));
      }
    });
    return arr;
  }

  // Define the table headers and page margins
  const tableHeaders = ["Topic", "Access"];
  const pageMargins = [20, 120, 20, 40];

  // Define the document definition
  const docDefinition = {
    pageMargins,
    header: {
      columns: [
        {
          text: `Title: ${selectedUser?.nameOfUser}, ${selectedUser?.departmentName}, ${selectedUser?.groupName}`,
          fontSize: 11,
          alignment: "left",
          margin: [20, 10],
          width: "*",
          bold: true,
        },
        {
          image: logo,
          fit: [100, 100],
          alignment: "center",
          width: "*",
        },
        {
          text: new Date().toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          fontSize: 10,
          alignment: "right",
          margin: [20, 10],
          width: "*",
        },
      ],
      margin: [0, 10, 0, 40],
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["*", "auto"],
          body: [tableHeaders, ...pushDataToPDF(mainObj)],
          margin: [0, 50, 0, 0],
          alignment: "center",
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0
              ? "#B7C4CB"
              : rowIndex % 2 === 0
              ? "#f7f7f7"
              : "#fff";
          },
        },
      },
    ],
    footer: function (currentPage, pageCount) {
      return {
        text: "Page " + currentPage.toString() + " of " + pageCount,
        alignment: "right",
        margin: [0, 0, 20, 0],
      };
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
  };

  // Generate the PDF document
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.download("myTable.pdf");
};
