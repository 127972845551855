export const onPolygonDraw = ({
  event,
  dispatch,
  selectedShape,
  historyDispatcher,
}) => {
  if (
    (selectedShape && selectedShape.value === "polygon") ||
    selectedShape === "polygon"
  ) {
    const newPoint = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    dispatch((prev) => {
      const updatedPoints = [...prev, newPoint];
      historyDispatcher({ newData: updatedPoints });
      return updatedPoints;
    });
  }
};

export const onPolygonUndo = ({
  currentStep,
  setPathPoints,
  history,
  setCurrentStep,
  isEdit = false,
}) => {
  if (currentStep > 0) {
    const previousStep = currentStep - 1;
    setPathPoints(history[previousStep]);
    setCurrentStep(previousStep);
  } else if (currentStep === 0) {
    if (isEdit) {
      setPathPoints(isEdit);
      setCurrentStep(-1);
      return;
    }
    setPathPoints([]);
    setCurrentStep(-1);
  }
};

export const onPolygonEdit = ({ polygonRef, dispatch, historyDispatcher }) => {
  if (polygonRef.current) {
    const nextPath = polygonRef.current
      .getPath()
      .getArray()
      .map((latlng) => ({
        lat: latlng.lat(),
        lng: latlng.lng(),
      }));

    dispatch(nextPath);
    historyDispatcher({ newData: nextPath });
  }
};

export const onPolygonLoad = ({ polygon, polygonRef, listenerRef }) => {
  polygonRef.current = polygon;
  const polygonPath = polygon?.getPath();
  listenerRef?.current?.push(
    polygonPath?.addListener("set_at", onPolygonEdit),
    polygonPath?.addListener("insert_at", onPolygonEdit),
    polygonPath?.addListener("remove_at", onPolygonEdit)
  );
};

export const onPolygonUnmount = ({ polygonRef, listenerRef }) => {
  listenerRef?.current?.forEach((lis) => lis.remove());
  polygonRef.current = null;
};
