import { TimerIcon } from "../../../../../Header/subcomponents";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../../../../commonComponents/modal.scss";
import { API } from "aws-amplify";
import { message } from "antd";
import { hasSettingsAccess } from "../../utils/hasSettingsAccess";

export const TaskTitleRedirect = ({ data, value, clickableRecord }) => {
  const { taskId, taskTopic, taskRelatedTo, categoryFrom, taskTimerDisabled } =
    data || {};

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);

  function findTopicAccess(categoryName) {
    let catName = categoryName;
    if (categoryName === "Schedule of values") {
      catName = "Schedule Of Values";
    }
    return userConfiguration?.routeConfig?.find(
      ({ title }) => title === catName
    );
  }
  const navigate = useNavigate();
  let validLink = ["Client", "Subcontractors"].includes(taskTopic)
    ? "Accounts"
    : taskTopic;

  const handleTopicId = (task) => {
    return task?.taskTopicId;
  };

  const goToTask = (taskTopic) => {
    switch (taskTopic) {
      case "Fleet":
        if (taskRelatedTo === "Violations") {
          return `${validLink?.toLowerCase()}sViolations/${handleTopicId(
            data,
            taskTopic?.toLowerCase()
          )}`;
        } else {
          return `${validLink?.toLowerCase()}s/${handleTopicId(
            data,
            taskTopic?.toLowerCase()
          )}`;
        }
      case "Clients":
        return `accounts/${handleTopicId(data, taskTopic?.toLowerCase())}`;
      case "Fleets Violations":
        return `fleetViolations/${handleTopicId(
          data,
          taskTopic?.toLowerCase()
        )}`;
      case "Reports":
        return `reports?category=${data?.taskSubTopic}&reportId=${handleTopicId(
          data
        )}`;
      case "Documentation":
        return `${
          categoryFrom?.documentationCategory === "Fleet"
            ? `fleets/${handleTopicId(
                data,
                categoryFrom?.documentationCategory?.toLowerCase()
              )}`
            : categoryFrom?.documentationCategory === "Clients"
            ? `accounts/${handleTopicId(
                data,
                categoryFrom?.documentationCategory?.toLowerCase()
              )}`
            : `${categoryFrom?.documentationCategory?.toLowerCase()}/${handleTopicId(
                data,
                categoryFrom?.documentationCategory?.toLowerCase()
              )}`
        }`;
      default:
        return `${validLink?.toLowerCase().replace(/\s/g, "")}/${handleTopicId(
          data,
          taskTopic?.toLowerCase()
        )}`;
    }
  };

  function isPartOfTeam(team) {
    const userIdentityId = userConfiguration?.identityId;

    const allMembers = team?.flatMap((item) => item?.members);
    const isUserInTeam = allMembers?.some(
      (member) => member?.identityId === userIdentityId
    );

    return isUserInTeam;
  }

  async function hasAccess() {
    message.loading({
      content: "Loading...",
      key: "taskAccess",
    });

    const hasTopicAccess = findTopicAccess(validLink);
    let isAdmin = userConfiguration?.groupName === "Admin";
    let value = false;
    const link = goToTask(taskTopic);
    let splitLink = link?.split("/")?.filter(Boolean);

    if (!!isAdmin) {
      !!hasTopicAccess ? (value = true) : (value = false);
    } else if (
      !!hasTopicAccess &&
      !!link &&
      !link.includes("general") &&
      splitLink?.length === 2
    ) {
      await API.get(`${splitLink[0]}`, `/${link}`)
        .then((res) => {
          value = res?.teamsConfiguration
            ? isPartOfTeam(res?.teamsConfiguration)
            : true;
        })
        .catch((err) => {
          value = true;
        });
    } else if (splitLink?.length > 2) {
      value = true;
    } else {
      value = true;
    }
    return value;
  }

  const getLink = (result, data) => {
    if (result.categoryName === "Pricing Config") {
      return `/${result.path}?service=${data.taskRelatedTo.replace(/ /g, "_")}`;
    } else if (result.categoryName === "Services Config") {
      return `/${result.path}?serviceId=${data.taskTopicId}`;
    }

    return `/${result.path}`;
  };

  const handleClick = async () => {
    if (data.hasOwnProperty("fromSettings") && !!data.fromSettings) {
      const result = programFields
        .find(({ fieldName }) => fieldName === "Settings Tasks")
        .fieldOptions.filter((item) => item.included.includes("task"))
        .find((topic) => topic.categoryName === data.taskTopic);

      const hasAccess = hasSettingsAccess(userConfiguration, result.access);

      return hasAccess
        ? navigate(getLink(result, data), {
            state: {
              tab: "Tasks",
              selectedId: taskId,
              rowSelected: data,
            },
          })
        : message.error({
            content:
              "You don't have access to this record or it doesn't exist.",
            key: "taskAccess",
            duration: 2,
          });
    }

    await hasAccess().then((res) => {
      if (!!res) {
        message.success({
          content: "Redirecting...",
          key: "taskAccess",
          duration: 2,
        });
        navigate(`/${goToTask(taskTopic)}`, {
          state: {
            data: {
              tab: "Tasks",
              selectedId: taskId,
              rowSelected: data,
            },
          },
        });
      } else {
        message.error({
          content: "You don't have access to this record or it doesn't exist.",
          key: "taskAccess",
          duration: 2,
        });
      }
    });
  };

  return (
    <span>
      {!!clickableRecord ? (
        <a className="taskTitle" onClick={handleClick}>
          {value}
        </a>
      ) : (
        value
      )}
      {taskTimerDisabled && (
        <span style={{ fill: "rgb(122, 193, 77)", marginLeft: 10 }}>
          <TimerIcon />
        </span>
      )}
    </span>
  );
};

export default TaskTitleRedirect;
