import { useMediaQuery } from "../../../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/utils/use-media-query/use-media-query";
import HoverButton, {
  HoverButtonProps,
} from "../../../HoverButton/HoverButton";
import { ReportIcon } from "../../../../../icons";
import { DownloadIcon } from "../../../../SidebarPages/BasePage/src";
import {
  PdfIconFilled,
  WordIconFilled,
  ExcelIconFilled,
} from "../../../../../assets";
import { ImageIcon } from "../../../../SidebarPages/Documentation/View/assets";

import "./FileTypeButton.scss";

/**
 * @typedef BaseFileButtonProps
 * @property {string} mimeType
 * @property {string} fileName
 *
 * @typedef {BaseFileButtonProps & HoverButtonProps} FileTypeButtonProps
 *
 * @param {FileTypeButtonProps} props
 */
function FileTypeButton(props) {
  const smallScreen = useMediaQuery("(max-width: 1024px)");

  const { fileName = null, mimeType, text = null, className, ...rest } = props;

  return (
    <HoverButton
      text={
        <div className="attachment-content">
          <div className="file-thumbnail">
            {mimeType.includes("pdf") ? (
              <PdfIconFilled height={24} width={24} />
            ) : mimeType.includes("word") ? (
              <WordIconFilled height={24} width={24} />
            ) : mimeType.includes("excel") ||
              mimeType.includes("openxmlformats") ||
              mimeType.includes("spreadsheet") ? (
              <ExcelIconFilled height={24} width={24} />
            ) : mimeType.includes("image") ? (
              <ImageIcon
                fill={isDarkMode ? "#fff" : undefined}
                height={24}
                width={24}
              />
            ) : (
              <ReportIcon
                fill={isDarkMode ? "#fff" : undefined}
                height={24}
                width={24}
              />
            )}
          </div>
          <div>{text || fileName}</div>
        </div>
      }
      type="action"
      icon={<DownloadIcon />}
      alwaysShowIcon={smallScreen}
      iconSize={{ height: 16, width: 16 }}
      className={`file-type-button ${className ?? ""}`}
      {...rest}
    />
  );
}

export default FileTypeButton;
