import { repeatedElementSum } from "../../../NewDashboardFunctions";
import { boroughs } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const leads = (programFields, updatedDynamicStates, IdHandler) => {
  return {
    leadStatus: () => ({
      title: "Lead Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Leads",
        field: "leadStatus",
        content: programFields
          ?.find((a) => a?.fieldName === "Lead Status Types")
          ?.fieldOptions?.map((status, index) => ({
            key: index,
            title: status,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.leadStatus === status
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${status} Lead`,
            contentsHandler: ` ${status} Leads`,
          })),
      },
      id: IdHandler,
      width: "1600px",
    }),
    leadAddress: () => ({
      title: "Lead Address",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Leads",
        field: "leadAddress",
        content: boroughs.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.borough === status
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Lead in " + status,
          contentsHandler: " Leads in " + status,
        })),
      },

      id: IdHandler,
      width: "1600px",
    }),
    projectExecutive: () => ({
      title: "Leads Project Executive",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Leads",
        field: "projectExecutive",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${key} Opportunity`,
          contentsHandler: ` ${key} Opportunity's`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  };
};
