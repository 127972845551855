import moment from "moment";
import { onClickFormHandler } from "../../../../utils";

export const footerButtons = ({
  onCancel,
  onSave,
  saveOnly,
  onOptionClick,
  suffix,
  saving,
  preventContinue,
}) => {
  const onClick = onClickFormHandler(saving);
  let secondOption = preventContinue ? "Finish" : "Save & Close";

  return [
    {
      text: "Cancel",
      cancel: true,
      onClick: onCancel,
      className: "mondayButtonRed",
      tooltipCategory: "Contacts",
      tooltipKey: "cancel",
    },
    {
      text: preventContinue ? "Save & Close" : "Finish",
      primary: true,
      onClick: onClick(onSave),
      disabled: !!saving,
      dropdownDisabled: !!saveOnly || !!saving,
      options: ["Save & New", secondOption],
      onOptionClick: onClick(onOptionClick),
      suffix: suffix,
      className: "mondayButtonBlue saveButton",
      tooltipCategory: "Contacts",
      tooltipKey: "finish",
    },
  ];
};

export const CONTACT_DETAILS = "Contact Details";
export const CONTACT_ADDRESS = "Contact Address";

export const fieldsJSON = ({
  jobsiteApi,
  recordTypes,
  contactRoles,
  contactDescription,
  setContactDescription,
  setSelectedAccountName,
  setContactType,
  companies,
  contactType,
  accountName,
  beveragePreferences = [],
  setSelectedEmail,
  jobsites = [],
  setSelectedJobsite,
}) => {
  return [
    [
      {
        formItemName: "contactRecordType",
        type: "radio",
        options: recordTypes,
        onChange: ({ target: { value } }) => setContactType(value),
        disabled: !!contactType,
        required: true,
        style: { height: "fit-content" },
      },
    ],
    [
      {
        formItemName: "jobsite",
        label: "Jobsite",
        placeholder: "Select...",
        type: "select",
        options: jobsites,
        // style: { width: 300 },
        showSearch: true,
        onSelect: (val) => setSelectedJobsite(val),
        disabled:
          contactType === "Opportunity Contact" ||
          contactType === "Project Contact"
            ? true
            : false,
        className: "jobsite",
      },
      {
        label: "First Name",
        formItemName: "contactFirstName",
        placeholder: "First Name",
        type: "input",
        className: "firstName",
      },
      {
        label: "Middle Name",
        formItemName: "contactMiddleName",
        placeholder: "Middle Name",
        type: "input",
        className: "middleName",
      },
      {
        label: "Last Name",
        formItemName: "contactLastName",
        placeholder: "Last Name",
        type: "input",
        className: "lastName",
      },

      {
        label: "Contact Role",
        formItemName: "contactRole",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        mode: "tags",
        className: "contactRole",
        options: contactRoles,
      },
      {
        label: "Company Name",
        formItemName: "contactAccountName",
        placeholder: "Select...",
        type: "select",
        required: true,
        showSearch: true,
        disabled: !!jobsiteApi || !!accountName,
        options: companies,
        onSelect: (value) => setSelectedAccountName(value),
        className: "contactAccountName",
      },
      {
        label: "Email",
        formItemName: "contactEmail",
        placeholder: "Email",
        required: true,
        className: "contactEmail",
        rules: [
          {
            type: "email",
            message: "Please enter a valid email",
          },
        ],
        onChange: ({ target: { value } }) => setSelectedEmail(value),
      },
      {
        label: "Phone",
        formItemName: "contactPhone",
        placeholder: "Phone",
        type: "phone",
        className: "contactPhone",
      },
      {
        label: "Phone Extension",
        formItemName: "phoneExtension",
        placeholder: "Phone Extension",
        typeNumber: true,
        className: "phoneExtension",
      },
      {
        label: "Mobile",
        formItemName: "contactMobile",
        placeholder: "Mobile",
        type: "phone",
        className: "contactMobile",
      },
      {
        label: "Beverage Preference",
        formItemName: "beveragePreference",
        placeholder: "Beverage Preference",
        type: "select",
        options: beveragePreferences.map(({ typeName }) => typeName),
        showSearch: true,
        className: "beveragePreference",
      },
    ],
    [
      {
        label: "Contact Description",
        type: "textEditor",
        value: contactDescription,
        onChange: (value) => setContactDescription(value),
        style: { width: "100%", height: "20%" },
      },
    ],
  ];
};

export const body = ({
  form,
  accountId,
  leadId,
  familyDetails,
  contactDescription,
}) => ({
  accountId,
  leadId,
  borough: form.getFieldValue("shippingAddCity"),
  shippingCoordinates: form.getFieldValue("shippingCoordinates"),
  contactRecordType: form.getFieldValue("contactRecordType"),
  contactAccountName: form.getFieldValue("contactAccountName"),
  contactFirstName: form.getFieldValue("contactFirstName"),
  contactMiddleName: form.getFieldValue("contactMiddleName"),
  contactLastName: form.getFieldValue("contactLastName"),
  contactEmail: form.getFieldValue("contactEmail"),
  contactPhone: form.getFieldValue("contactPhone"),
  contactMobile: form.getFieldValue("contactMobile"),
  contactRole: form.getFieldValue("contactRole"),
  phoneExtension: form.getFieldValue("phoneExtension"),
  contactBirthdate: 0,
  // !!form.getFieldValue("contactBirthdate") &&
  // moment(form.getFieldValue("contactBirthdate")).valueOf(),
  beveragePreference: form.getFieldValue("beveragePreference"),
  familyDetails: "",
  contactDescription,
  mailingAddress: form.getFieldValue("secondaryAddress"),
  zipCode: form.getFieldValue("shippingAddPostalCode"),
  jobsite: form.getFieldValue("jobsite"),
});
