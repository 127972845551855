export const apiRoutes = {
  fleet: "fleet",
  drivers: "drivers",
  fleetDispatching: "fleetDispatching",
  projects: "projects",
  fleetFields: "fleetFields",
  contacts: "contacts",
  accounts: "accounts",
  leads: "leads",
  opportunities: "opportunities",
  estimations: "estimations",
  documentation: "documentation",
  docConfiguration: "docConfiguration",
  fleetDocumentation: "fleetDocumentation",
  fleetIncidents: "fleetIncidents",
  fleetInspections: "fleetInspections",
  fleetMaintenance: "fleetMaintenance",
  fleetActivity: "fleetActivity",
  filterDocumentationByFleet: "filterDocumentationByFleet",
  filterAccountsByType: "filterAccountsByType",
  proposedTypeOfWork: "proposedTypeOfWork",
  proposedConstructionServices: "proposedConstructionServices",
  projectExecutives: "projectExecutives",
  permitDrawings: "permitDrawings",
  users: "userConfiguration",
  hotCredentials: "hotCredentials",
  engineers: "engineers",
  programFields: "programFields",
  scheduling: "scheduling",
  fleetViolations: "fleetViolations",
  architects: "architects",
  preferences: "preferences",
  dynamicQuestions: "dynamicQuestions",
  formRequests: "formRequests",
  orders: "orders",
  reportConfiguration: "reportConfiguration",
  sampleObjects: "sampleObjects",
  reportsAvailable: "reportsAvailable",
  inspections: "inspections",
  userConfiguration: "userConfiguration",
  serviceDefinitions: "serviceDefinitions",
  getIp: "getIp",
  pricing: "pricing",
  applications: "applications",
  claims: "claims",
  charges: "charges",
  invoices: "invoices",
  rentals: "rentals",
  editLogs: "editLogs",
  fleetIncidents: "fleetIncidents",
  fleetDefects: "fleetDefects",
  workOrders: "workOrders",
  safety: "safety",
  categories: "categories",
  vendors: "vendors",
  bills: "bills",
};
