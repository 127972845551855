import types from '../types'

const initialState = {
  editMode: false
}

const editableFormReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_EDITABLE_MODE:
      return {
        ...state,
        editMode: payload
      }
    default:
      return state
  }
}

export default editableFormReducer