function findMissingBreakdownFiles(arr, obj) {
  const allValues = Object.values(obj)?.flat();

  const arr1Ids = arr?.map(({ id = "" }) => id);

  const missingElementsSet = new Set(
    allValues?.filter((value) => !arr1Ids?.includes(value))
  );

  const missingElements = Array.from(missingElementsSet);

  const updatedObj = {};
  for (let key in obj) {
    updatedObj[key] = obj[key]?.filter(
      (value) => !missingElements?.includes(value)
    );
  }

  return {
    missingElements,
    updatedObj,
  };
}

export default findMissingBreakdownFiles;
