import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { Close } from "../../../../SidebarPages/DynamicView/src";
import MondayButton from "../../../MondayButton/MondayButton";
import "./RequestAccessNotification.scss";

import { Tooltip } from "antd";

const RequestAccessNotification = ({ user = {}, onConfirm, onDeny }) => {
  const { nameOfUser, groupName } = user;

  return (
    <div className="requestAccessNotificationLight">
      {nameOfUser}, {groupName}, is requesting edit access. Would you like to
      leave write mode now and grant them access? (Make sure you have saved your
      progress before clicking Yes, otherwise it will be lost)
      <div className="clearfix" style={{ marginTop: 14 }}>
        <span className="float-right">
          <Tooltip title={`Grant ${nameOfUser} access and leave write mode`}>
            <MondayButton onClick={onConfirm} Icon={<TickIcon />}>
              Allow
            </MondayButton>
          </Tooltip>
        </span>
        <span className="float-right" style={{ marginRight: 14 }}>
          <Tooltip title={`Deny ${nameOfUser}'s request and keep editing`}>
            <MondayButton
              className="mondayButtonRed"
              onClick={onDeny}
              Icon={<Close />}
            >
              Deny
            </MondayButton>
          </Tooltip>
        </span>
      </div>
    </div>
  );
};

export default RequestAccessNotification;
