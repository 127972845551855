import { Fragment } from "react";
import { MondayButton } from "../../../../commonComponents";
import { TrashIcon, XIcon } from "../../../Communication/assets";
import { LogsIcon } from "../../../DynamicView/src";
import { EmailIcon, ExportPdfIcon, FilledEyeIcon } from "../../../../../assets";
import { TasksIconNew } from "../../../../../icons";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import { Tooltip } from "antd";
import { DynamicAvatars } from "../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { viewClaimsModal } from "../../PersonalInjury/Modals/NewPersonalInjuryModal";
import { LinkButton } from "../../../../commonComponents";
import { WordIcon } from "../../../BasePage/src";

/**
 * Renders the footer component for the safety modal.
 *
 * @param {string} defaultId - The default ID.
 * @param {boolean} changes - Indicates if there are changes.
 * @param {string} safetyName - The safety name.
 * @param {boolean} hasReport - Indicates if there is a report.
 * @param {Function} onSave - The onSave function.
 * @param {Array} selectedTeam - The selected team.
 * @param {Function} onEmail - The onEmail function.
 * @param {Function} onDelete - The onDelete function.
 * @param {Function} onCancel - The onCancel function.
 * @param {Function} onShowLogs - The onShowLogs function.
 * @param {Array} claimsUsingSafety - The claims using safety.
 * @param {Function} onReportView - The onReportView function.
 * @param {Function} onGeneratePdf - The onGeneratePdf function.
 * @param {Function} setTasksVisible - The setTasksVisible function.
 * @param {string} safetyCategory - The safety category.
 * @param {null|Object} noteAccess - The note access.
 * @returns {Array} - The rendered footer component.
 */
function safetyModalFooter({
  defaultId = "",
  changes = false,
  safetyName = "",
  hasReport = false,
  onSave = () => {},
  selectedTeam = [],
  onEmail = () => {},
  onDelete = () => {},
  onCancel = () => {},
  onShowLogs = () => {},
  claimsUsingSafety = [],
  onReportView = () => {},
  // onGeneratePdf = () => {},
  setTasksVisible = () => {},
  safetyCategory,
  isDarkMode,
  noteAccess = null,
  popoverProps = null,
  setWordPreview = () => {},
  // setOpenSort = () => {},
}) {
  return [
    <Fragment key="safety-modal-footer">
      {!!defaultId && (
        <div className="buttonContainer">
          <MondayButton
            {...{
              Icon: <TrashIcon />,
              className: "mondayButtonRed",
              onClick: onDelete,
              tooltipCategory: ["Safety", safetyCategory],
              tooltipKey: "delete",
            }}
          >
            Delete
          </MondayButton>

          <MondayButton
            {...{
              Icon: <LogsIcon />,
              className: "mondayButtonBlue",
              onClick: onShowLogs,
              tooltipCategory: ["Safety", safetyCategory],
              tooltipKey: "showLogs",
            }}
          >
            Show Logs
          </MondayButton>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => setWordPreview(true),
              Icon: <WordIcon />,
            }}
          >
            PDF Preview
          </MondayButton>
          {hasReport && (
            <MondayButton
              {...{
                Icon: <ExportPdfIcon />,
                className: "mondayButtonBlue",
                onClick: onReportView,
                tooltipCategory: ["Safety", safetyCategory],
                tooltipKey: "showReport",
              }}
            >
              View Report
            </MondayButton>
          )}
          <MondayButton
            {...{
              Icon: <TasksIconNew />,
              className: "mondayButtonBlue",
              onClick() {
                setTasksVisible(true);
              },
              tooltipCategory: ["Safety", safetyCategory],
              tooltipKey: "showTasks",
            }}
          >
            Tasks
          </MondayButton>
          <MondayButton
            {...{
              Icon: <EmailIcon />,
              className: "mondayButtonBlue",
              onClick: onEmail,
              tooltipCategory: ["Safety", safetyCategory],
              tooltipKey: "openEmail",
            }}
          >
            Email
          </MondayButton>
          <LinkButton popoverProps={popoverProps} title={"Go to"} />
          {!!noteAccess && noteAccess?.read && (
            <div className="safety-notes-button">
              <Notes
                {...{
                  noteModalName: safetyName,
                  rowId: defaultId,
                  tooltipCategory: "Safety Damage",
                  tooltipCategory: ["Safety", safetyCategory],
                  topicCategory: safetyCategory,
                }}
              />
            </div>
          )}

          {claimsUsingSafety.length > 0 && (
            <Tooltip title={"View Claims using this Safety"}>
              <MondayButton
                {...{
                  Icon: <FilledEyeIcon style={{ height: 23, width: 23 }} />,
                  className: "mondayButtonBlue previewButton",
                  onClick: () => viewClaimsModal(claimsUsingSafety),
                }}
              />
            </Tooltip>
          )}
        </div>
      )}

      <div className="buttonContainer">
        <DynamicAvatars
          {...{
            title: "Team",
            isDarkMode: isDarkMode,
            members: selectedTeam.reduce(
              (acc, { members }) => [...acc, ...members],
              []
            ),
          }}
        />
        <MondayButton
          {...{
            Icon: <XIcon />,
            className: "mondayButtonRed",
            onClick: onCancel,
            tooltipCategory: ["Safety", safetyCategory],
            tooltipKey: "cancel",
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          {...{
            Icon: <TickIcon />,
            onClick: onSave,
            disabled: !changes || claimsUsingSafety.length > 0,
            tooltipCategory: ["Safety", safetyCategory],
            tooltipKey: "save",
          }}
        >
          Save
        </MondayButton>
      </div>
    </Fragment>,
  ];
}

export default safetyModalFooter;
