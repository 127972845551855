import _ from "lodash";
import { message } from "antd";

export default function duplicatePLIRow(
  ServiceIndex,
  isWritable,
  gridDataState,
  setState,
  gridApi,
  serviceIndex,
  optionIndex,
  elevationIndex,
  itemId,
  accessRights
) {
  if (isWritable) {
    let hasAccessRight = accessRights.children.find(
      (item) => item.title === "Adding/Removing pli's"
    ).write;
    if (hasAccessRight) {
      // let gridData = _.cloneDeep(gridDataState);
      let items =
        gridDataState[serviceIndex].serviceOptions[optionIndex][elevationIndex]
          .items;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === itemId) {
          items.splice(i + 1, 0, { ..._.cloneDeep(item), id: itemId + 1 });
          i++;
        }
        if (item.id > itemId) {
          item.id++;
        }
      }
      setState({ agSelectedCells: {} });
      setState({ gridData: gridDataState });
      gridApi.setRowData(items);
      gridApi.redrawRows();
    } else {
      message.error("You have no access to duplicate pli.");
    }
  } else {
    message.error("Please enable write mode.");
  }
}
