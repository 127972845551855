export class DebrisChutePLI {
  id;
  approved = false;
  serviceId = 15;

  length;

  diameter;
  diameterInInch = 36;
  fromFloor;
  toFloor;

  hoppers;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
export class AlternateDebrisChutePLI {
  id;
  approved = false;
  serviceId = 15;

  length;

  diameter;
  diameterInInch = 36;
  fromFloor;
  toFloor;

  hoppers;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;

  isAlternate = true;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
