import { Divider, Input, Select } from "antd";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import "./InputAndSelect.scss";

export const InputAndSelect = ({
  onFilterTextChange,
  searchText,
  columnDefs,
  setFieldSelected,
  excludedColumnsForSearch = [],
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const columnsToNotFilter = [
    "Notes",
    "Edit",
    "Delete",
    "Actions",
    "Action",
    ...excludedColumnsForSearch,
  ];

  return (
    <div className={`search-div ${isDarkMode && "darkSearch"}`}>
      <Input
        value={searchText}
        allowClear
        className="search-comp"
        placeholder="Search..."
        prefix={<SearchOutlined className="SearchLogoIcon" />}
        onChange={(e) => onFilterTextChange(e.target.value)}
      />
      <Divider type="vertical" className="searchDivider" />
      <Select
        className="searchFilter-select"
        placeholder="Select column"
        allowClear={true}
        onClear={() => setFieldSelected(null)}
        popupMatchSelectWidth={false}
        popupClassName={isDarkMode && `darkDropDown`}
        onSelect={(e) => setFieldSelected(e)}
        options={columnDefs
          ?.filter(({ headerName }) => !columnsToNotFilter.includes(headerName))
          ?.map(({ headerName, field }) => ({
            value: field,
            label: headerName,
          }))}
      />
    </div>
  );
};
