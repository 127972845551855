import RichTextModalEditor from "../../../../../../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor"
import { useStateContext } from "../../../../../../../Context/Context"
import "./Description.scss"

const Description = ({ value }) => {
  const { darkMode } = useStateContext()

  return (
    <div className="noteEditorContainerSOVHoist">
      <RichTextModalEditor
        {...{
          value,
          darkMode,
          saveButton: false,
          disabledEditing: true,
        }}
      />
    </div>
  )
}

export default Description
