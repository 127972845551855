import axios from "axios";
import { fetchData } from "../components/SidebarPages/Fleet/utils";
import { useLocation } from "react-router-dom";

const getDataSourceReports = async (body) => {
  const queryParams = new URLSearchParams(body).toString();
  return axios.get(
    // `http://localhost:8080/api/dataSourceReportsFetch?${queryParams}`
    `https://leadmanager-express-backend.ue.r.appspot.com/api/dataSourceReportsFetch?${queryParams}`
  );
};

export const dataSourceReportsFetch = async (
  { dataSourceName, categoryName },
  recordId
) => {
  try {
    if (dataSourceName === "userConfiguration") {
      return await fetchData("userConfiguration").then((r) => [r]);
    } else {
      const result = await getDataSourceReports({
        dataSourceName,
        categoryName,
        ...(recordId && { recordId }),
      });

      return result.data;
    }
  } catch (error) {
    console.error(
      "🚀 ~ file: dataSourceReportsFetch.js ~ line 40 ~ dataSourceReportsFetch ~ error",
      error
    );
  }
};
