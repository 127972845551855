import React from "react";
import { Button, message, Tooltip } from "antd";
import ArrowDown from "../../../../../../../icons/DataGrid_Down_Red.webp";
import ArrowUp from "../../../../../../../icons/DataGrid_Up_Green.webp";
import { priceFormatter } from "../../../tools/formatters/pricing";
import { getMaxNoFeetWithMinus } from "../../../tools/formatters/getMax";
import { MinusOutlined, MinusSquareFilled } from "@ant-design/icons";

class SidewalkShedPPUAdvisor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmedSuggestion: null,
    };
  }
  // getPriceSheetSuggestion = () => {
  //   for (const [xIndex, x] of this.props.priceSheetData.x.entries()) {
  //     if (x === getMaxNoFeet(this.props.data?.width)?.toString()) {
  //       for (const [yIndex, y] of this.props.priceSheetData.y.entries()) {
  //         if (y === getMaxNoFeet(this.props.data?.height)?.toString()) {
  //           return this.props.priceSheetData.z[yIndex][xIndex];
  //         }
  //       }
  //       break;
  //     }
  //   }
  // }

  getPriceSheetSuggestion = () => {
    const widthDimensions = this.props.priceSheetData.x;
    const heightDimensions = this.props.priceSheetData.y;

    let width = getMaxNoFeetWithMinus(this.props.data?.width);
    let height = getMaxNoFeetWithMinus(this.props.data?.height);

    // console.log(width, height)
    const xIndex = this.upperIndexOfValueBetween2Values(widthDimensions, width);

    if (xIndex !== false) {
      const yIndex = this.upperIndexOfValueBetween2Values(
        heightDimensions,
        height
      );

      if (yIndex !== false) {
        return this.props.priceSheetData.z[yIndex][xIndex];
      }
    }
  };

  upperIndexOfValueBetween2Values = (array, value) => {
    if (array.length <= 0) return false;

    for (let i = 0; i < array.length - 1; i++) {
      //we will not check the last element
      const thisDimension = parseInt(array[i]);
      if (value <= thisDimension) return i; //if value is smaller than array first value, return the first index
      if (thisDimension <= value && value <= parseInt(array[i + 1]))
        return i + 1;
    }
    return false;
  };

  handleApplySuggestion = () => {
    //     if(this.props.data.lock) {
    //       message.warning("This PPU is locked. Please unlock it to apply default prices")
    //     } else
    this.props.updateSWSPPU(
      this.props.api,
      this.props.indexes.serviceIndex,
      this.props.indexes.optionIndex,
      this.props.indexes.elevationIndex,
      this.props.data.id,
      this.getPriceSheetSuggestion()
    );
    //     this.setState({confirmedSuggestion: 0})
  };

  render() {
    //   console.log(this.props)
    console.log("SidewalkShedPPUAdvisor - props", this);

    let arrow,
      value = parseFloat(this.props.value),
      suggestedPrice = parseFloat(this.getPriceSheetSuggestion()),
      difference;
    if (value > suggestedPrice) {
      arrow = <img alt="..." className="data-grid-price-arrow" src={ArrowUp} />;
    } else if (value < suggestedPrice) {
      arrow = (
        <img alt="..." className="data-grid-price-arrow" src={ArrowDown} />
      );
    } else if (value === suggestedPrice) {
      arrow = <MinusOutlined />;
    } else arrow = <MinusSquareFilled style={{ visibility: "hidden" }} />;

    difference = Math.round(Math.abs(suggestedPrice - value) * 100) / 100;

    let differenceView = difference;
    if (isNaN(difference) || difference === 0) differenceView = "";

    //     console.log(differenceView)

    return (
      <div style={{ display: "flex" }} className={"ppu-advisor-cell"}>
        <span className="ppu-difference">
          testtttt
          {arrow} {differenceView}
        </span>
        <span
          style={{
            flexGrow: 1,
            marginTop: 5,
            marginLeft: 10,
            textAlign: "right",
          }}
        >
          {priceFormatter({ value: this.props.value })}
        </span>{" "}
        {difference !== 0 ? (
          <Tooltip title={`Apply suggestion: ${suggestedPrice}`}>
            <Button
              style={{ marginLeft: 15 }}
              size="small"
              type="primary"
              onClick={this.handleApplySuggestion}
              disabled={!this.props.isWritable || this.props.data.lock}
            >
              {/* <img
                src="https://img.icons8.com/material-outlined/16/f5f5f5/submit-document.png"
                alt=""
              /> */}
              {suggestedPrice}
            </Button>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default SidewalkShedPPUAdvisor;
