import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { LabeledFilter } from ".";
import { calendarIcon } from "../assets";
import { dateFilterOptionsKeys } from "./ControlPanel/datas";

const DatePickerFilter = ({
  date,
  setDate,
  label,
  form,
  otherDate,
  setCurrent,
}) => (
  <LabeledFilter label={label} noFormItem>
    <DatePicker
      format="MM/DD/YYYY"
      value={!!date ? dayjs(date?.$d) : date}
      allowClear={false}
      suffixIcon={<img src={calendarIcon} alt={calendarIcon} />}
      inputReadOnly
      onChange={(dt) => {
        setDate(dayjs(dt));
        form.setFieldsValue({ dateFilterSelect: "Custom" });
        setCurrent(dateFilterOptionsKeys.length - 1);
      }}
      disabledDate={(dt) => dt && dt < otherDate}
    />
  </LabeledFilter>
);

export default DatePickerFilter;
