export const setFalsePermission = (route) => {
  if (!route) return route;

  // Create a new object with the same properties as route
  const newRoute = structuredClone(route);
  // Modify the new object
  newRoute.read = false;
  newRoute.write = false;

  if (newRoute.children) {
    // Recursively update children without mutating the original array
    newRoute.children = newRoute.children.map((child) =>
      setFalsePermission(child)
    );
  }

  return newRoute;
};
