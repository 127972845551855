import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

const columnDefs = [
  {
    headerName: "Vehicle",
    field: "fleetName",
  },
  {
    headerName: "Vin Number",
    field: "vinNumber",
  },
  {
    headerName: "From Location",
    field: "fenceExit",
  },
  {
    headerName: "Depart Time",
    field: "exitTime",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      return dayjsNY(value).format("MMM DD, hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
        }
        return "";
      },
    },
  },
  {
    headerName: "To Location",
    field: "fenceEnter",
  },
  {
    headerName: "Arrive Time",
    field: "enterTime",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      return dayjsNY(value).format("MMM DD, hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
        }
        return "";
      },
    },
  },
];

export const columnKeys = [
  "fleetName",
  "vinNumber",
  "fenceExit",
  "exitTime",
  "fenceEnter",
  "enterTime",
];

export default columnDefs;
