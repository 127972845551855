import { compareIncluding } from "../../../../../../../SidebarPages/utils"
import {
  GreenCircleSuccess,
  RedCircleWarning,
} from "../../../../../../../../icons"
import "./CheckmarkLabel.scss"

const CheckmarkLabel = ({ violations = [], onClick }) => {
  const activeViolations = violations?.filter(
    ({ violation_category: violation = "" }) =>
      compareIncluding(violation, "active")
  )
  const src = !!activeViolations?.length ? RedCircleWarning : GreenCircleSuccess

  return (
    <div className="violationCheckmarkContainer" {...{ onClick }}>
      <img {...{ src, alt: "", className: "checkmarkIcon" }} />
      <div className="violationInfoText">
        <div className="activeViolations">
          {`${activeViolations?.length || "No"} active violations`}
        </div>
        <div className="totalViolations">
          {`${violations?.length || "No"} violations found`}
        </div>
      </div>
    </div>
  )
}

export default CheckmarkLabel
