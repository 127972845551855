import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import type { MenuProps } from "antd";
import MondayButton from "../MondayButton/MondayButton";
import { ArrowIcon } from "../../SidebarPages/Scheduling/ScheduleChangesModal/assets/index";
import "./ButtonDropdown.scss";

type Props = {
  children: React.ReactNode;
  title: string;
  placement?:
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight"
    | "topLeft"
    | "topCenter"
    | "topRight";
  trigger?: ["click"] | ["hover"] | ["contextMenu"];
  icon?:
    | React.ReactNode
    | string
    | React.ReactElement<SVGAElement | SVGElement>;
  buttonStyle?: React.CSSProperties;
  dropDownOptions: MenuProps["items"];
  onClick?: () =>
    | void
    | React.Dispatch<React.SetStateAction<any>>
    | (() => string | number);
  buttonContainerClassName?: string;
  buttonClassName?: string;
  disabled?: boolean;
  customOverlayClassName?: string;
  tooltipCategory?: string;
  tooltipKey?: string;
};

const ButtonDropdown = (props: Props) => {
  const [open, setOpen] = useState(false);
  const {
    children,
    title,
    placement,
    trigger,
    icon,
    buttonStyle,
    dropDownOptions,
    onClick,
    buttonContainerClassName,
    buttonClassName,
    disabled,
    customOverlayClassName = "button-dropdown-overlay",
    tooltipCategory,
    tooltipKey,
  } = props;

  //   const menu = <Menu items={dropdownOptions} />;

  return (
    <Dropdown
      {...{
        menu: {
          items: dropDownOptions,
        },
        placement: placement || "bottomLeft",
        trigger: trigger || ["hover"],
        onOpenChange: (e) => setOpen(e),
        overlayClassName: customOverlayClassName,
        // ? customOverlayClassName : "button-dropdown-overlay",
      }}
    >
      {/** @ts-ignore */}
      <MondayButton
        key={title}
        {...{
          Icon: icon || (
            <ArrowIcon
              style={
                open
                  ? {
                      fill: "#fffff",
                      color: "#fffff",
                      transform: "rotate(180deg)",
                    }
                  : { fill: "#fffff", color: "#fffff" }
              }
            />
          ),
          style: buttonStyle || {},
          containerClassName: buttonContainerClassName || "",
          onClick: onClick || (() => {}),
          className: `${buttonClassName || ""} mondayButtonBlue`,
          disabled,
          tooltipCategory,
          tooltipKey,
        }}
      >
        {title}
      </MondayButton>
    </Dropdown>
  );
};

export default ButtonDropdown;
