import uniq from "lodash/uniq";
export const dispatching = (dispatchData, updatedDynamicStates, IdHandler) => {
  const allDrivers = (updatedDynamicStates?.[IdHandler] || [])
    ?.map((a) => a?.routes?.map((e) => e?.driverName))
    ?.flat();
  return {
    driverName: () => ({
      title: "Dispatching DriverName",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Dispatching",
        field: "driverName",
        content: uniq(allDrivers)?.map((driverName, index) => ({
          key: index,
          title: driverName,
          taskNumber: allDrivers?.length,
          taskDone: allDrivers?.filter(
            (a) => a?.toLowerCase() === driverName?.toLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Drove By " + driverName,
          contentsHandler: " Drove By " + driverName,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }), //driverName

    dispatchedBy: () => ({
      title: "Dispatched By",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Dispatching",
        field: "dispatchedBy",
        content: uniq(
          dispatchData?.map((a) =>
            a?.dispatchedBy?.name ? a?.a?.dispatchedBy?.name : a?.dispatchedBy
          )
        )?.map((dispatchedBy, index) => ({
          key: index,
          title: dispatchedBy,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter((a) =>
            a?.dispatchedBy?.name
              ? a?.a?.dispatchedBy?.name?.toLocaleLowerCase()
              : a?.dispatchedBy?.toLocaleLowerCase() ===
                dispatchedBy.toLocaleLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Dispatched By " + dispatchedBy,
          contentsHandler: " Dispatched By " + dispatchedBy,
        })),
      },

      id: IdHandler,
      width: "1600px",
    }), //dispatchedBy
  }; //Dispatching
};
