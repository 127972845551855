import React, { useEffect, useMemo, useState } from "react";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import {
  InputComponent,
  NormalSizedModal,
} from "src/components/SidebarPages/Fleet/components";
import MondayButton from "../MondayButton/MondayButton";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import WarningModal from "../WarningModal/WarningModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { WarningIcon } from "src/icons";
import { ReactComponent as DragIcon } from "src/icons/drag.svg";
import "src/components/pages/Settings/settingsComponents/SafetyDynamicForms/SortFields/SortFields.scss";
import { useSelector } from "react-redux";
import { message } from "antd";
import { API } from "aws-amplify";
import { StopOutlined } from "@ant-design/icons";
import { LogsIcon } from "../../SidebarPages/DynamicView/src";
import MultiLevelTreeLogs from "../MultiLevelTreeLogs/MultiLevelTreeLogs";
import {
  removeEmptyKeys,
  removeNotChangedKeys,
} from "../../pages/Settings/settingsComponents/SafetyDynamicForms/SortFields/SortFields";
import { useEditLogs } from "../../../hooks";
import { fetchAllData } from "../../../utils";

function getTextFromLabel(label) {
  if (typeof label === "string") return label;
  return label.props.children[1];
}

/**
 * Renders a modal component for sorting fields.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.visible - Determines if the modal is visible.
 * @param {function} props.setVisible - Sets the visibility of the modal.
 * @param {object|object[]} props.rowData - The data to be displayed in the modal. If Object is passed, the options prop is required.
 * @param {function} [props.onSave=()=>{}] - The function to be called when saving changes.
 * @param {string} props.title - The title of the modal.
 * @param {string} [props.saveKey=""] - The key used for saving changes.
 * @param {Array} [props.options=[]] - The options for sorting. Only used when the data is an object.
 * @param {Object} [props.currentSort={}] - The current sorting configuration to keep the previous changes.
 * @returns {JSX.Element} The SortModalFields component.
 */
function SortModalFields({
  visible,
  setVisible,
  rowData,
  onSave = () => {},
  title,
  saveKey = "",
  options = [],
  currentSort = {},
}) {
  const [{ programFields }, { isDarkMode }, { userConfiguration }] =
    useSelector((state) => [
      state.programFields,
      state.darkMode,
      state.userConfig,
    ]);

  const [changes, setChanges] = useState(false);
  const [category, setCategory] = useState(options[0]?.value);
  const [warningModal, setWarningModal] = useState(null);
  const [data, setData] = useState(
    options.length ? _.cloneDeep(rowData) : [...rowData]
  );
  const [logsVisible, setLogsVisible] = useState(false);
  const [logsData, setLogsData] = useState([]);

  const [collectLogs, setCollectLogs] = useState({
    curr: {},
    prev: {},
  });
  const { saveAddedLogs } = useEditLogs();

  const recordName = `${title} Sorted Fields`;

  const newEditLog = async (collectLogs) => {
    let updatedLogs = null;
    if (!options.length) {
      updatedLogs = {
        curr: removeNotChangedKeys(collectLogs.prev, collectLogs.curr),
        prev: removeNotChangedKeys(collectLogs.curr, collectLogs.prev),
        updatedKeys: Object.keys(
          removeNotChangedKeys(collectLogs.curr, collectLogs.prev)
        ),
      };
    } else {
      let tmp = removeEmptyKeys(
        removeNotChangedKeys(collectLogs.prev, collectLogs.curr)
      );
      updatedLogs = {
        curr: removeNotChangedKeys(collectLogs.prev, collectLogs.curr),
        prev: removeNotChangedKeys(collectLogs.curr, collectLogs.prev),
        updatedKeys: Object.values(tmp).flatMap(Object.keys),
      };
    }
    const logBody =
      updatedLogs.updatedKeys.length > 0
        ? {
            recordId: recordName,
            recordName: recordName,
            actionType: "Edit",
            topic: `Sorted ${title} Fields`,
            category: "Sorted Fields",
            currentData: !options.length
              ? updatedLogs.curr
              : removeEmptyKeys(updatedLogs.curr),
            label: `Sorted ${title} Fields`,
            previousData: !options.length
              ? updatedLogs.prev
              : removeEmptyKeys(updatedLogs.prev),
            updatedKeys: updatedLogs.updatedKeys,
          }
        : null;

    if (!!logBody) {
      setLogsData([...logsData, logBody]);
      await saveAddedLogs(logBody);
    }
  };

  function reorderElement(result) {
    if (!result.destination) return;
    !changes && setChanges(true);

    const startIndex = result?.source?.index;
    const endIndex = result?.destination?.index;

    let tmp = options?.length ? data[category] : data;
    const [removed] = tmp.splice(startIndex, 1);
    tmp.splice(endIndex, 0, removed);
    tmp.forEach((item, index) => {
      item.index = index + 1;
    });
    if (!options?.length) {
      let oldIndex = rowData.findIndex(
        (el) =>
          el.formItemName === result.draggableId || el.id === result.draggableId
      );
      let name =
        saveKey !== "inventoryModal"
          ? tmp[endIndex].label
          : getTextFromLabel(tmp[endIndex].label);
      setData(tmp);
      setCollectLogs((prev) => ({
        prev: {
          ...prev.prev,
          [name]: oldIndex + 1,
        },
        curr: {
          ...prev.curr,
          [name]: endIndex + 1,
        },
      }));
    } else {
      let oldIndex = rowData[category].findIndex(
        (el) =>
          el.formItemName === result.draggableId || el.id === result.draggableId
      );
      setData((prev) => ({ ...prev, [category]: tmp }));
      let name = tmp[endIndex].label;
      setCollectLogs((prev) => ({
        prev: {
          ...prev.prev,
          [category]: {
            ...prev.prev[category],
            [name]: oldIndex + 1,
          },
        },
        curr: {
          ...prev.curr,
          [category]: {
            ...prev.curr[category],
            [name]: endIndex + 1,
          },
        },
      }));
    }
  }

  const renderedElements = useMemo(() => {
    return (
      <DragDropContext onDragEnd={reorderElement}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="droppableDiv"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {data[category]?.map((el, i) => {
                return (
                  <Draggable
                    key={el?.id || el?.formItemName}
                    draggableId={el?.id?.toString() || el?.formItemName}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        variant={snapshot.isDragging ? "elevation" : "outlined"}
                        elevation={4}
                      >
                        <div className="draggableDiv">
                          <span className="draggableSpan">{el.label}</span>
                          <DragIcon />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, [category]);

  const modalSortedFields = programFields.find(
    ({ fieldName }) => fieldName === "Modal Sorted Fields"
  );

  function transformArrayToObj(arr) {
    return arr.reduce((acc, el) => {
      acc[el.formItemName] = el.index;
      return acc;
    }, {});
  }

  useEffect(() => {
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordName",
                value: recordName,
                formula: "is",
              },
            ],
          },
        ]),
      },
    }).then((res) => {
      setLogsData(res || []);
    });
  }, []);

  async function saveHandler() {
    let transformedData = {};
    if (!options.length) {
      transformedData = transformArrayToObj(data);
    } else {
      transformedData = Object.keys(data).reduce((acc, key) => {
        if (data[key].some((el) => !!el.index)) {
          acc[key] = transformArrayToObj(data[key]);
        }
        return acc;
      }, {});
    }
    let final = {};
    if (!!options?.length) {
      final = {
        ...currentSort,
        ...transformedData,
      };
    } else {
      final = transformedData;
    }

    await API.patch(
      "programFields",
      `/programFields/${modalSortedFields?.fieldId}`,
      {
        body: {
          fieldOptions: {
            ...modalSortedFields?.fieldOptions,
            [saveKey]: final,
          },
        },
      }
    )
      .then(async (res) => {
        onSave(final);
        message.success("Changes saved successfully");
        await newEditLog(collectLogs);
        setVisible(false);
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.log("err:", { err });
      });
  }

  function cancelHandler() {
    if (!changes) {
      setVisible(false);
    } else {
      setWarningModal("cancel");
    }
  }

  function close() {
    setData(rowData);
    setVisible(false);
  }

  return (
    <NormalSizedModal
      {...{
        visible,
        setVisible,
        title: `Sort ${title} Fields`,
        onCancel: cancelHandler,
        centered: true,
        className: `sortFieldsModal ${isDarkMode && "sortFieldsModalDark"}`,
        closeIcon: <XIcon />,
        maskClosable: true,
        closable: true,
        customFooter: (
          <div className="sortFieldsModalFooter">
            <MondayButton
              {...{
                className: "mondayButtonRed",
                onClick: cancelHandler,
                Icon: <XIcon />,
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              {...{
                onClick: () => setLogsVisible(true),
                Icon: <LogsIcon />,
                className: "mondayButtonBlue",
              }}
            >
              Logs
            </MondayButton>
            <MondayButton
              {...{
                onClick: () => {
                  setWarningModal("save");
                },
                Icon: <TickIcon />,
                disabled: !changes,
              }}
            >
              Save
            </MondayButton>
          </div>
        ),
      }}
    >
      {options.length ? (
        <InputComponent
          {...{
            label: "Category",
            defaultValue: category,
            onChange: (e) => {
              setCategory(e);
            },
            type: "select",
            customOptions: options,
            dropdownClassName: isDarkMode && "darkDropDown",
          }}
        />
      ) : (
        <></>
      )}
      {!options.length ? (
        data?.length > 1 ? (
          <DragDropContext onDragEnd={reorderElement}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="droppableDiv"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {data?.map((el, i) => {
                    return (
                      <Draggable
                        key={el?.id || el?.formItemName}
                        draggableId={el?.id?.toString() || el?.formItemName}
                        index={i}
                        isDragDisabled={el?.disableDrag === true}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            variant={
                              snapshot.isDragging ? "elevation" : "outlined"
                            }
                            elevation={4}
                          >
                            <div className="draggableDiv">
                              <span className="draggableSpan">
                                {saveKey !== "inventoryModal"
                                  ? el.label
                                  : getTextFromLabel(el.label)}
                              </span>
                              {!el?.disableDrag ? (
                                <DragIcon />
                              ) : (
                                <StopOutlined />
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          data?.map((el, i) => {
            return (
              <div className="draggableDiv">
                <span className="draggableSpan">{el.label}</span>
              </div>
            );
          })
        )
      ) : (
        renderedElements
      )}
      {!!warningModal && (
        <WarningModal
          {...{
            visible: !!warningModal,
            setVisible: setWarningModal,
            onClose: () => {
              setWarningModal(false);
            },
            title: "Warning Message",
            closable: true,
            className: "logout-warning-modal",
            darkMode: isDarkMode,
          }}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            {warningModal === "cancel" ? (
              <p>Are you sure you want to cancel all changes?</p>
            ) : (
              <p>Are you sure you want to save changes</p>
            )}

            <div className="buttons">
              <MondayButton
                onClick={() => {
                  setWarningModal(false);
                }}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  warningModal === "cancel" ? close() : saveHandler();
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: logsData || [],
            title: "Modal Sorting Fields Logs",
          }}
        />
      )}
    </NormalSizedModal>
  );
}

export default SortModalFields;
