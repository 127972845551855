export const setRowDataToAllGrids = ({
  agGridApiForEachElevation = {},
  addonsAgGridInstances = {},
  gridData = [],
}) => {
  for (const [serviceId, agGridApiForService] of Object.entries(
    agGridApiForEachElevation || {}
  )) {
    const service = gridData?.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    if (!service?.isSelected) return;

    if (agGridApiForService) {
      for (const [elevationIndex, agGridApiForElevation] of Object.entries(
        agGridApiForService || {}
      )) {
        const elevation = service?.serviceOptions?.[0]?.[elevationIndex];
        agGridApiForElevation.api.setRowData(elevation?.items);
      }
    }
  }
  for (const [serviceId, agGridApiForServiceAddons] of Object.entries(
    addonsAgGridInstances || {}
  )) {
    const service = gridData.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    agGridApiForServiceAddons.api.setRowData(service.serviceAddons);
  }
};
