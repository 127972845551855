import { Modal } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

import { antdModalProps } from "../../../../../utils";
import { MondayButton } from "../../../../commonComponents";
import { Tick } from "../../../../pages/Settings/settingsComponents/Roles/src";
import RichTextEditor from "../../../../commonComponents/RichTextEditor/RichTextEditor";

import "./ReportDescriptionModal.scss";
import { XIcon } from "../../../Communication/assets";

const ReportDescriptionModal = ({
  visible,
  setVisible,
  report = {},
  disabled = false,
  onDescriptionEdited = () => {},
}) => {
  const { description = "" } = report;
  const [value, setValue] = useState(description);

  const { isDarkMode } = useSelector((state) => state?.darkMode);

  const onSave = () => {
    onDescriptionEdited({ ...report, description: value });
    setVisible(false);
  };

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        title: "Description",
        onCancel: () => {
          setValue(description);
          setVisible(false);
        },
        className: `reportDescriptionModal ${
          isDarkMode && "reportDescriptionModalDark"
        }`,
        closeIcon: <XIcon />,
        footer: [
          <div className="footerContainer">
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={() => {
                setValue(description);
                setVisible(false);
              }}
            >
              Cancel
            </MondayButton>
            {/* {value !== description && ( */}
            <MondayButton
              {...{
                Icon: <Tick />,
                onClick: onSave,
                className: "saveBtn",
              }}
            >
              Save
            </MondayButton>
            {/* )} */}
          </div>,
        ],
      }}
    >
      <div className="reportDescriptionContainer">
        <RichTextEditor
          {...{
            value,
            disabled,
            onChange: (val) => setValue(val),
          }}
        />
      </div>
    </Modal>
  );
};

export default ReportDescriptionModal;
