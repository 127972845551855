const gridFilters = [
  {
    globalKeyId: "f37743c0-8951-4653-ba22-6d1b6ce38336",
    label: "Vehicle",
    type: "Dropdown",
    key: "fleetName",
  },
  {
    globalKeyId: "de725ecd-c6c6-4d88-851b-77bd349638f6",
    label: "Driver",
    type: "Dropdown",
    key: "driverName",
  },
  {
    globalKeyId: "ef953333-c87a-49fb-92dd-754ff36d3bb1",
    label: "Cargo",
    type: "Dropdown",
    key: "cargo",
  },
  {
    globalKeyId: "ac6ead21-af84-4674-b876-07f37d7a7d95",
    label: "From Location",
    type: "Dropdown",
    key: "pickUpLocation",
  },
  {
    globalKeyId: "1c1c6879-2d23-4547-85cb-ef048920211d",
    label: "To Location",
    type: "Dropdown",
    key: "dropOffLocation",
  },
  {
    globalKeyId: "f443e8f8-847f-45dd-8ed9-4ebf38c6f57e",
    label: "Paperwork Type",
    type: "Dropdown",
    key: "paperworkType",
  },
];

export default gridFilters;
