export const headerStyles = {
  height: "60px",
  borderRadius: "10px",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "20px",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
};

export const headerTextStyle = {
  color: "#323338",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

export const infoIconStyle = {
  fontSize: "20px",
  color: "#1264A3",
};

export const topicsConfigHeader = {
  borderRadius: "10px 10px 0px 0px",
  background: "#F8F8FA",
  height: "45px",
  display: "flex",
  alignItems: "center",
  padding: "20px",
};

export const topcisConfigHeaderText = {
  color: "#323338",
  fontFamily: "Open Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
};

export const topicsConfigBody = {
  overflowY: "scroll",
  display: "grid",
  gap: "5px",
  maxHeight: "764px",
};

export const dynamicBodyStyle = {
  display: "grid",
  gap: "5px",
};

export const activeTopicStyle = {
  background: "#F1F5FD",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #DEE2EB",
};

export const topicStyle = {
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // borderBottom: "1px solid #DEE2EB",
};

export const widgetConfigBody = {
  borderRadius: "10px",
  background: "#FFF",
  boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
  width: "100%",
  height: "757px",
};

export const singleWidgetCardStyle = {
  display: "flex",
  justifyContent: "space-between",
  padding: "5px",
  borderRadius: "5px",
  background: "#F8F8FA",
  marginBottom: "3px",
};

export const widgetInputsStyle = {
  display: "grid",
  gap: "7px",
};

export const antdInputStyle = {
  background: "#F8F8FA",
  border: "none",
};
export const labelOfInputStyle = {
  marginLeft: "5px",
  // marginBottom: "3px",
  fontWeight: "700px",
};
export const tableActions = {
  display: "flex",
  alignItems: "center",
  gap: "15px",
};

export const iconsStyles = (color) => ({
  fontSize: "20px",
  color: color,
  cursor: "pointer",
  fontWeight: "600",
  fontFamily: "Open Sans",
});
