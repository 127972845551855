import React, { useEffect, useRef, useState } from "react";
import "./invoicePreview.scss";
import { Button, Input, InputNumber, Tooltip, message } from "antd";
import InvoicePaper from "./additional_modules/invoice_paper/invoicePaper";
import HeaderButtons from "./additional_modules/header_buttons/headerButtons";
import {
  MondayButton,
  TooltipMonday,
} from "../../../../../../commonComponents";
import { InputComponent } from "../../../../../Fleet/components";
import { Notes } from "../../../../../../commonComponents/Notes/Notes";
import { BackIcon } from "../../../../../Communication/assets";
import {
  FastBackwardOutlined,
  FastForwardOutlined,
  MailOutlined,
  PrinterFilled,
  SearchOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { DownloadIcon } from "../../../../../BasePage/src";
import EmailBox from "../../../../../Communication/components/EmailBox/EmailBox";
import jsPDF from "jspdf";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import { useSelector } from "react-redux";
import { filterTables } from "../../../../../../../utils";
import { useMediaQuery } from "react-responsive";

const IconButton = ({ name = "", onClick = () => {}, Icon }) => {
  return (
    <TooltipMonday text={name}>
      <div onClick={onClick} className="mondayButtonBlue iconCont">
        {Icon}
      </div>
    </TooltipMonday>
  );
};

// const projectFolderId = "1QAVTL44wAL74olk_3gvTkbZhZ66YZXrN";

const InvoicePreview = ({
  myProps,
  invoicePreviewNumber,
  setInvoicePreviewNumber,
  setPdfVisibility,
  fetchAndSetInvoices,
}) => {
  let invView;
  for (let i = 0; i < myProps?.previewInvoices?.length; i++) {
    if (
      myProps?.previewInvoices[i]?.invoiceId === invoicePreviewNumber?.invoiceId
    ) {
      invView = { inv: myProps?.previewInvoices[i], ind: i };
      break;
    }
  }
  const [invoicePreviewPdfAction, setInvoicePreviewPdfAction] = useState(false);
  const [searchInvoicePreviewText, setSearchInvoicePreviewText] =
    useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [notesName, setNotesName] = useState("");
  const [pageNr, setPageNr] = useState(null);
  const [emailBox, setEmailBox] = useState(false);
  const [pdfId, setPdfId] = useState();
  const { accessToken } = useSelector((state) => state?.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const driveRequest = driveApi({ accessToken });
  const [projectFolderId, setProjectFolderId] = useState("");

  const updateInvoicePreviewNumber = (invoiceIndex) => {
    if (invoiceIndex >= 0 && invoiceIndex < myProps?.previewInvoices?.length) {
      const invoice = myProps?.previewInvoices[invoiceIndex];
      setInvoicePreviewNumber({
        invoceNumber: invoice?.invoiceNumber,
        invoiceId: invoice?.invoiceId,
      });
    }
  };

  const firstOrLastDoc = (dirArr) => {
    dirArr === "forward"
      ? updateInvoicePreviewNumber(myProps?.previewInvoices?.length - 1)
      : updateInvoicePreviewNumber(0);
  };

  const nextOrPrevDoc = (oldVal, dirArr) => {
    if (dirArr === "forward") {
      if (oldVal < myProps?.previewInvoices?.length - 1) {
        oldVal++;
        updateInvoicePreviewNumber(oldVal);
      }
    } else {
      if (oldVal > 0) {
        oldVal--;
        updateInvoicePreviewNumber(oldVal);
      }
    }
  };

  const onInputChange = (oldVal, e) => {
    if (pageNr > myProps?.previewInvoices?.length) {
      setPageNr("");
      e = undefined;
      oldVal = undefined;
    }
    try {
      const valueInput = parseInt(e);
      valueInput && valueInput <= myProps?.previewInvoices?.length
        ? oldVal !== valueInput
          ? myProps?.previewInvoices[valueInput - 1]
            ? updateInvoicePreviewNumber(valueInput - 1)
            : setPageNr("")
          : setPageNr("")
        : setPageNr("");
    } catch (e) {
      setPageNr("");
    }
  };

  useEffect(() => {
    if (invoicePreviewNumber) {
      filterTables(
        "projects",
        "projectId",
        getNameOrId(
          invoicePreviewNumber?.invoiceId,
          myProps?.previewInvoices,
          false
        )
      ).then((res) => {
        setProjectFolderId(res[0]?.googleDriveFolderIds?.projectId);
      });

      setNotesName(
        `${getNameOrId(
          invoicePreviewNumber?.invoiceId,
          myProps?.previewInvoices
        )} | Invoice # ${invoicePreviewNumber?.invoceNumber}`
      );
    }
  }, [invoicePreviewNumber]);
  function getNameOrId(id, arr, pName = true) {
    const name = arr.find((el) => el?.invoiceId === id);
    return pName ? name?.projectName : name.projectId;
  }

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
      driveRequest.deleteDriveItem(pdfId);
      setPdfId(null);
    }
  }

  async function pdfToDrive(folderId) {
    return new Promise((resolve, reject) => {
      // const el = document.querySelector(`#pagePDF`);
      const el = document.querySelector(`.invoicePaperCont`);
      const pdf = new jsPDF("p", "pt", "letter");
      pdf.setDocumentProperties({
        title: `invoice_${invoicePreviewNumber?.invoceNumber}`,
      });
      el.style.width = "610px";
      pdf.html(el, {
        callback: function (doc) {
          el.style.width = setWidth();

          let blob = doc.output("blob");
          let data = doc.output("datauristring").split(",")[1];

          driveRequest
            .uploadFile(
              data,
              blob.type,
              {
                name: `invoice_${invoicePreviewNumber?.invoceNumber}`,
                parents: [folderId],
                mimeType: blob.type,
              },
              true
            )
            .then(async (res) => {
              let { id, name } = await res.json().then(({ id, name }) => ({
                id,
                name,
              }));
              return resolve({ id, name });
            })
            .catch((e) => console.log("ERROR: ", e));
        },
      });
    });
  }
  const width1 = useMediaQuery({ minWidth: "1281px" });
  const width2 = useMediaQuery({ minWidth: "1025px", maxWidth: "1280px" });
  const width3 = useMediaQuery({
    minWidth: "768px",
    maxWidth: "1024px",
    orientation: "landscape",
  });
  const width4 = useMediaQuery({ minWidth: "768px", maxWidth: "1024px" });
  const width5 = useMediaQuery({ minWidth: "481px", maxWidth: "767px" });

  function setWidth() {
    if (width1) {
      return "50%";
    }
    if (width2) {
      return "50%";
    }
    if (width3) {
      return "70%";
    }
    if (width4) {
      return "80%";
    }
    if (width5) {
      return "90%";
    }
  }

  async function getFromDrive() {
    message.loading({ content: "Loading...", key: "mailLoading" });
    await pdfToDrive(projectFolderId).then((res) => {
      setPdfId(res.id);
      setEmailBox(true);
      message.destroy("modalLoading");
    });
  }

  if (invoicePreviewNumber) {
    return (
      <div className={"previewInvCont"}>
        <div
          className={`optionsContainer ${isDarkMode && "optionsContainerDark"}`}
        >
          <div className="flexDiv backAndTitle">
            <IconButton
              {...{
                name: "Back to invoices",
                onClick: () => {
                  setPdfVisibility(false);
                  setInvoicePreviewNumber(false);
                  pdfId && driveRequest.deleteDriveItem(pdfId);
                  fetchAndSetInvoices();
                },
                Icon: <BackIcon style={{ fontSize: 21 }} />,
              }}
            />
            <span className="invoiceNumber">
              {" "}
              Invoice # {invoicePreviewNumber?.invoceNumber}
            </span>
          </div>
          <div className="flexDiv pageControls">
            <IconButton
              {...{
                name: "First",
                onClick: firstOrLastDoc.bind(null, "back"),
                Icon: <FastBackwardOutlined style={{ fontSize: 21 }} />,
              }}
            />
            <IconButton
              {...{
                name: "Previous",
                onClick: nextOrPrevDoc.bind(null, invView?.ind, "back"),
                Icon: <StepBackwardOutlined style={{ fontSize: 18 }} />,
              }}
            />
            <div className="pagination flexDiv">
              <InputNumber
                {...{
                  placeholder: invView?.ind + 1,
                  onChange: onInputChange.bind(null, invView?.ind + 1),
                  onBlur: (e) => {
                    e.target.value = null;
                    setPageNr(null);
                  },
                  className: "paginationInput",
                  controls: false,
                  max: myProps.previewInvoices.length,
                  value: pageNr,
                }}
              />
              <p className="unselectable">
                of {myProps?.previewInvoices?.length}
              </p>
            </div>
            <IconButton
              {...{
                name: "Next",
                onClick: nextOrPrevDoc.bind(null, invView?.ind, "forward"),
                Icon: <StepForwardOutlined style={{ fontSize: 18 }} />,
              }}
            />

            <IconButton
              {...{
                name: "Last",
                onClick: firstOrLastDoc.bind(null, "forward"),
                Icon: <FastForwardOutlined style={{ fontSize: 21 }} />,
              }}
            />
          </div>
          <div className="flexDiv optionButtons">
            <InputComponent
              {...{
                className: "searchInput",
                placeholder: "Search...",
                type: "text",
                onChange: (e) =>
                  setSearchInvoicePreviewText(e.currentTarget.value),
                suffixIcon: <SearchOutlined style={{ color: "lightgray" }} />,
              }}
            />
            <IconButton
              {...{
                name: "Print Invoice",
                onClick: () => setInvoicePreviewPdfAction("print"),
                Icon: <PrinterFilled style={{ fontSize: 21 }} />,
              }}
            />
            <IconButton
              {...{
                name: "Download Invoice",
                onClick: () => setInvoicePreviewPdfAction("download"),
                Icon: <DownloadIcon style={{ fontSize: 21 }} />,
              }}
            />
            <Notes
              {...{
                noteModalName: notesName,
                rowId: invoicePreviewNumber?.invoiceId,
                topicCategory: "Invoices",
                notesVisibility: openNotes,
                setNotesVisibility: setOpenNotes,
              }}
            />
            <IconButton
              {...{
                name: "Email Invoice",
                onClick: getFromDrive,
                Icon: <MailOutlined style={{ fontSize: 21 }} />,
              }}
            />
          </div>
        </div>
        {/* <HeaderButtons
          {...{
            myProps: {
              previewInvoices: myProps.previewInvoices,
              invoicePreviewNumber: invoicePreviewNumber,
              previewCurrentInvoce: myProps.previewInvoices.filter(
                (d) => d.invoiceNumber === invoicePreviewNumber.invoiceNumber
              ),
            },
            setInvoicePreviewPdfAction,
            setInvoicePreviewNumber,
            setSearchInvoicePreviewText,
          }}
        /> */}
        <div className="viewModeListCont">
          {
            <InvoicePaper
              {...{
                myProps: {
                  previewCurrentInvoce: myProps?.previewInvoices?.filter(
                    (d) =>
                      d?.invoiceNumber === invoicePreviewNumber?.invoceNumber
                  )[0],
                },
                setInvoicePreviewPdfAction,
                invoicePreviewPdfAction,
                searchInvoicePreviewText,
              }}
            />
          }
        </div>
        {emailBox ? (
          <div className="safetyEmailBox">
            <EmailBox
              {...{
                invoice: true,
                // isFull: true,
                onClose: () => {
                  setEmailBox(false);
                  pdfId && driveRequest.deleteDriveItem(pdfId);
                  setPdfId(null);
                },
                setEmails: () => {},
                rowData: myProps?.previewInvoices?.find(
                  (el) => el?.invoiceId === invoicePreviewNumber?.invoiceId
                ),
                onSave: (e, type, setLoading) => {
                  onEmailSave({ e, type, setLoading });
                },
                isRedirected: pdfId,
                isFromCharge: true,

                // sendEmailNotification,
              }}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return "";
  }
};

export default InvoicePreview;
