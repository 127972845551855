import { Button } from "antd";
import { useState } from "react";

import { FunctionList } from "./components";
import { functionOptions } from "../../utils";
import { useAdvancedFilterContext } from "../../context";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { SearchIcon } from "../../../../SidebarPages/Communication/assets";
import { DropDownArrow } from "../../../../SidebarPages/Fleet/components/InputComponent/assets";

import styles from "./FunctionPicker.module.css";

const FunctionPicker = ({
  value = "",
  filterIndex,
  conditionIndex,
  selectedType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const { handleInputChange } = useAdvancedFilterContext();

  const filteredOptions = functionOptions.filter(
    (option) =>
      option.value.includes(searchInput) &&
      option.allowedTypes.includes(selectedType)
  );

  const onListItemClickHandler = (value) => {
    setIsOpen((prev) => !prev);
    handleInputChange(filterIndex, conditionIndex, "formula", value);
  };

  const onButtonClickHandler = () => setIsOpen((prev) => !prev);

  return (
    <div className={styles["function-picker-container"]}>
      <Button
        className={styles["function-button"]}
        onClick={onButtonClickHandler}
      >
        {value}
        <DropDownArrow className={styles["button-icon"]} />
      </Button>
      {isOpen && (
        <div className={styles["function-picker-modal"]}>
          <InputComponent
            type="input"
            suffixIcon={<SearchIcon />}
            onChange={(e) => setSearchInput(e.target.value)}
            className={styles.searchInput}
          />
          <div className={styles.lists}>
            <FunctionList
              {...{
                data: filteredOptions,
                onListItemClickHandler,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FunctionPicker;
