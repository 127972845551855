import dayjs from "dayjs";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
import React, { useContext, useState } from "react";

import { useRedux } from "../../../../../hooks/useRedux";
import { defaultProductRow } from "../../utils/constants";
import { DropdownIcon } from "../../../../../BasePage/src";
import { DatePickerMoment } from "../../../../../../commonComponents";
import { DataNeededForInvoiceForm } from "../../../../Tabs/Invoice/Invoice";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import { setSelectedProjectsByAccountName } from "../../utils/setSelectedProjectsByAccountName";
import { useInvoiceFormDataContext } from "../../context/InvoiceFormDataContext";

const InvoiceDetails = ({
  account,
  onCategoryChange,
  setServicesGroups,
  selectedCategory,
}) => {
  const [dueDateLimit, setDueDateLimit] = useState();
  const [invoiceDateLimit, setInvoiceDateLimit] = useState();
  const [invoiceStartLimit, setInvoiceStartLimit] = useState(
    moment().valueOf()
  );
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const contextData = useContext(DataNeededForInvoiceForm);
  const accounts = contextData?.accounts || [];
  const projects = contextData?.projects || [];

  const { invoiceData, projectData, accountData } = useInitialInvoiceContext();
  const { formData, setFormData } = useInvoiceFormDataContext();

  const { setProducts: setRowData } = useCreateInvoiceContext();

  const [isWritable] = useRedux("invoiceIsWritable");
  const [_, setFormChanged] = useRedux("invoiceFormChanged");

  let editMode = invoiceData && projectData && accountData;

  function onInputChangeHandler(value, key) {
    setFormData((prev) => {
      prev[key] = value;
      return { ...prev };
    });
  }

  function onDateChangeHandler(value, key) {
    const newDate = moment(value).tz("America/New_York").valueOf();
    key === "invoiceDate" && setDueDateLimit(value);
    key === "dueDate" && setInvoiceDateLimit(value);
    setFormData((prev) => {
      //convert date into time stamp
      prev[key] = newDate;
      return { ...prev };
    });
  }

  const disabledDateInvoice = (current) => {
    /* Makes date picker so you can not select days before today
       and after the selected due date
    */
    if (current) {
      if (!!!invoiceDateLimit)
        return current < dayjs(invoiceStartLimit).startOf("day");
      if (
        current < dayjs(invoiceStartLimit).startOf("day") ||
        current > dayjs(invoiceDateLimit).startOf("day")
      )
        return true;
    }
    return false;
  };
  const disabledDateDue = (current) =>
    current && current < dayjs(dueDateLimit).endOf("day"); // Can not select days before today and todays date

  function onSelectClientName(value, removeProjectName = true) {
    if (value) {
      setFormChanged(true);
      onInputChangeHandler(value, "accountName");
      const account = accounts.find((el) => el.accountName === value); //Returns the account data OBJ
      setFormData((prev) => {
        if (prev.accountName === account.accountName) {
          onInputChangeHandler(account.accountEmail, "accountEmail");

          onInputChangeHandler(account.billingAddress, "billingAddress");
          setSelectedProjectsByAccountName(value);

          prev.accountName = account.accountName;
          prev.accountEmail = account.accountEmail;
          prev.billingAddress = account.billingAddress;
          prev.accountId = account.accountId;

          //Used when the client is selected in the drop down, and not by selecting project first
          if (removeProjectName) {
            prev.projectId = undefined;
            prev.projectName = undefined;
            setServicesGroups([]);
            setRowData([...[defaultProductRow]]);
          }
          return { ...prev };
        }
        return prev;
      });
    }
  }

  function onSelectProject(projectName) {
    const project = projects.find((el) => el.projectName === projectName);
    setFormChanged(true);
    setFormData((prev) => {
      if (prev.projectId !== project.projectId) {
        prev.projectId = project.projectId;
        prev.projectName = project.projectName;
        prev.taxRate = project.taxRate;
      }
      return { ...prev };
    });
    onSelectClientName(project.accountName, false);
    onCategoryChange(selectedCategory); //updates category that is selected with the current project
  }

  return (
    <div
      className="invoiceFormWrapper"
      style={!!!invoiceData ? { width: "-webkit-fill-available" } : {}}
    >
      <div className="header">
        <div>Invoice Details </div>
      </div>
      <div className="body">
        <form>
          <div className={"subContainer"}>
            <div style={{ width: "100%" }}>
              <label htmlFor="client">Client</label>
              <Select
                popupMatchSelectWidth={false}
                suffixIcon={<DropdownIcon />}
                className="Autocomplete"
                name="client"
                disabled={projectData || invoiceData ? true : false}
                defaultValue={invoiceData ? invoiceData.accountName : null}
                value={
                  projectData?.accountName
                    ? projectData?.accountName
                    : formData?.accountName
                }
                showSearch
                placeholder="Client Name"
                optionFilterProp="children"
                popupClassName={isDarkMode && "darkDropDown"}
                filterOption={(input, option) =>
                  option.value.toLowerCase().includes(input.toLowerCase())
                }
                onChange={onSelectClientName}
                onClear={() => {
                  onInputChangeHandler(undefined, "accountName");
                }}
                allowClear
              >
                {accounts?.map((el, index) => (
                  <Select.Option key={index} value={el.accountName}>
                    {el.accountName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ width: "100%" }}>
              <label htmlFor="Project">Project</label>
              <Select
                popupMatchSelectWidth={false}
                suffixIcon={<DropdownIcon />}
                required
                showSearch
                className="Autocomplete"
                placeholder="Project Name"
                optionFilterProp="children"
                popupClassName={isDarkMode && "darkDropDown"}
                name="Project"
                value={formData?.projectName}
                onChange={onSelectProject}
                filterOption={(input, option) =>
                  option.value.toLowerCase().includes(input.toLowerCase())
                }
                disabled={projectData || invoiceData ? true : false}
                defaultValue={projectData ? projectData.projectName : null}
              >
                {projects
                  .filter(
                    (el) =>
                      el.accountName === formData["accountName"] ||
                      !!!formData["accountName"]
                  )
                  .map((el, index) => (
                    <Select.Option key={index} value={el.projectName}>
                      {el.projectName}
                    </Select.Option>
                  ))}
              </Select>
            </div>
            <div style={{ width: "100%" }}>
              <label htmlFor="Client Email">Client email</label>
              <Input
                disabled={true}
                placeholder="Email"
                value={account?.accountEmail}
                className={"inputForm"}
                onChange={(e) => {
                  setFormChanged(true);
                  onInputChangeHandler(
                    e.target.inputMode.value,
                    "accountEmail"
                  );
                }}
                name="Client Email"
                type="email"
              />
            </div>
          </div>
          <div className="subContainer" style={{ alignItems: "flex-end" }}>
            <div style={{ width: "100%" }}>
              <label htmlFor="Invoice date">Invoice date</label>
              <DatePickerMoment
                name="Invoice date"
                disabled={invoiceData ? !isWritable : false}
                popupClassName={isDarkMode && "darkDateDropDown"}
                required
                onChange={(value) => {
                  setFormChanged(true);
                  onDateChangeHandler(value, "invoiceDate");
                  const dueValue = moment(value).add(1, "month");
                  onDateChangeHandler(dueValue, "dueDate");
                }}
                placeholder="Invoice Date"
                // disabled={disabledDateInvoice}
                format="MM/DD/YYYY"
                defaultValue={
                  invoiceData
                    ? moment(invoiceData.fromDate).tz("America/New_York")
                    : formData?.invoiceDate
                    ? moment(formData?.invoiceDate || undefined).tz(
                        "America/New_York"
                      )
                    : moment().tz("America/New_York")
                }
              />
            </div>
            <div style={{ width: "100%" }}>
              <label htmlFor="Due date">Due date</label>
              <DatePickerMoment
                name="Due date"
                disabled={invoiceData ? !isWritable : false}
                popupClassName={isDarkMode && "darkDateDropDown"}
                required
                onChange={(value) => {
                  setFormChanged(true);
                  onDateChangeHandler(value, "dueDate");
                }}
                placeholder="Due Date"
                value={moment(formData?.dueDate || undefined).tz(
                  "America/New_York"
                )}
                // disabledDate={disabledDateDue}
                format="MM/DD/YYYY"
                defaultValue={
                  invoiceData
                    ? moment(invoiceData.toDate).tz("America/New_York")
                    : formData?.dueDate
                    ? moment(formData?.dueDate || undefined).tz(
                        "America/New_York"
                      )
                    : undefined
                }
              />
            </div>
            {/* {!editMode ? ( */}
            <div style={{ width: "100%" }}>
              <label htmlFor="Invoice number">QuickBooks Number</label>
              <Input
                disabled={invoiceData ? !isWritable : false}
                onContextMenu={() => false}
                required
                onChange={(e) => {
                  setFormChanged(true);
                  onInputChangeHandler(
                    parseInt(e.target.value),
                    "quickBooksNumber"
                  );
                }}
                name="quickBooksNumber"
                type="number"
                placeholder="QuickBooks Number"
                className={"inputForm"}
                value={formData?.quickBooksNumber}
              />
            </div>
            {/* ) : ( */}
            {editMode && (
              <div style={{ width: "100%" }}>
                <label htmlFor="Invoice number">Invoice Number</label>
                <Input
                  disabled
                  defaultValue={invoiceData?.invoiceNumber}
                  type="number"
                  placeholder="Invoice Number"
                  className={"inputForm"}
                />
              </div>
            )}
            {/* )} */}
            <div style={{ width: "100%" }}>
              <label htmlFor="Tax Rate">Tax Rate</label>
              <Input
                onContextMenu={() => false}
                required
                disabled={true}
                value={
                  formData?.taxRate > 0
                    ? `${parseFloat(formData?.taxRate * 100)}%`
                    : "0%"
                }
                name="Tax Rate"
                placeholder="Tax Rate"
                className={"inputForm"}
                defaultValue={0}
              />
            </div>
          </div>
          <div className="subContainer">
            <div className="billingAddress">
              <label htmlFor="Billing address">Billing Address</label>
              <Input.TextArea
                required
                disabled={true}
                placeholder="Billing address"
                className={"inputForm"}
                value={account?.billingAddress}
                onChange={(e) => {
                  setFormChanged(true);
                  onInputChangeHandler(
                    e.target.textarea.value,
                    "billingAddress"
                  );
                }}
                name="Billing address"
                rows={3}
                style={{ resize: "none", width: "100% !important" }}
              />
            </div>
            <div className="billingAddress">
              <label htmlFor="Memo">Memo</label>
              <Input.TextArea
                required
                placeholder="Memo"
                className={"inputForm"}
                value={formData?.memos?.[0]?.memoValue}
                disabled={invoiceData ? !isWritable : false}
                onChange={(e) => {
                  setFormChanged(true);
                  onInputChangeHandler(
                    [
                      {
                        memoValue: e.target.value,
                        memoId: uuidv4(),
                      },
                    ],
                    "memos"
                  );
                }}
                name="memos"
                rows={3}
                style={{ resize: "none", width: "100% !important" }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvoiceDetails;
