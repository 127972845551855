import { Modal } from "antd";
import React from "react";
import "./SchedulingProgressFilters.scss";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import { FilterIcon } from "../../../../../SidebarPages/BasePage/src";
import { progressFilterFields } from "../../SchedulingFirstPage/helperData";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
const SchedulingProgressFilters = ({
  progressFilters = {},
  setProgressFilters = () => {},
  progressLogs = [],
  applyFilters = () => {},
  inputOptions,
}) => {
  const { visible = false, filters = {} } = progressFilters || {};

  const onFilterChange = (field, value) => {
    let updatedFilters = { ...filters };
    if (!value) {
      delete updatedFilters[field];
    } else {
      updatedFilters[field] =
        field === "startDate" || field === "progressTimeGiven"
          ? dayjsNY(value).format("MM/DD/YYYY")
          : value;
    }

    setProgressFilters({ ...progressFilters, filters: updatedFilters });
  };

  const onCancel = () => {
    setProgressFilters({});
  };

  return (
    <div>
      <Modal
        onCancel={onCancel}
        open={visible}
        centered
        title="Filters"
        closeIcon={<XIcon />}
        className="progressFiltersModal"
        closeable={false}
        keyboard={false}
        destroyOnClose={true}
        footer={[
          <MondayButton
            {...{
              className: "mondayButtonRed",
              onClick: onCancel,
              Icon: <XIcon />,
            }}
          >
            Close Modal
          </MondayButton>,
          <span>
            Select your filters and click "<b>Apply Filters</b>" to display the
            results.
          </span>,
          <MondayButton
            {...{
              className: "mondayButtonGreen",
              Icon: <FilterIcon />,
              onClick: () => applyFilters(filters),
            }}
          >
            Apply Filters
          </MondayButton>,
        ]}
        width={1570}
      >
        <div className="progressFiltersModalContent">
          {progressFilterFields().map((item) => {
            const { label = "", field = "", type = "" } = item || {};
            return (
              <InputComponent
                {...{
                  type,
                  label,
                  placeholder: label,
                  customOptions: inputOptions(progressLogs, field),
                  [type === "datepicker" ? "value" : "initialValue"]:
                    type === "datepicker" && filters?.[field]
                      ? dayjsNY(filters?.[field]) || ""
                      : filters?.[field],
                  onChange: (value) => onFilterChange(field, value),
                  style: {
                    width: 200,
                  },
                }}
              />
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default SchedulingProgressFilters;
