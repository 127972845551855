import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";

export function approvalsDocDefinition(title, dynamicPdf, companyLogo) {
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: `${title ? title : ""}`,
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
            style: ["strong", "large"],
          },
          {
            text: `Date : ${dayjsNY().format(DEG_DATE_FORMAT)}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      ...dynamicPdf?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      date: { margin: [0, 0, 0, 10] },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      category: { fontSize: 10, color: "#4a4a4a" },
      commentContent: { margin: [10, 0, 10, 10] },
      replyContent: { margin: [20, 0, 10, 10] },
      replyHeader: { fontSize: 12, color: "#4a4a4a" },
    },
  };
}

const pdfBorderedSection = ({ title, body = {}, noColor = false }) => {
  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };

  const bodyObj = body?.requestObject;

  function camelCaseToNormalText(input) {
    let normalText = input?.replace(/([a-z])([A-Z])/g, "$1 $2");

    normalText = normalText?.charAt(0)?.toUpperCase() + normalText?.slice(1);

    return normalText;
  }

  function valueParser({ value, key }) {
    return typeof value === "string"
      ? value
      : key === "projectManager"
      ? value?.join(", ")
      : key === "createdAt" || key === "inspectionDate"
      ? dayjsNY(value).format(`${DEG_DATE_FORMAT} ${DEG_TIME_FORMAT}`)
      : key === "team"
      ? value.map(({ value }) => value).join(", ")
      : key === "teamsConfiguration"
      ? value
          .map(({ members }) =>
            members.map(({ nameOfUser }) => nameOfUser).join(", ")
          )
          .join(" | ")
      : key === "safetyReport"
      ? value?.name
      : "";
  }

  const order = [
    "documentationType",
    "recordName",
    "record",
    "documentationCategory",
    "status",
    "teamsConfiguration",
    "team",
    "docObject",
    "gDriveUploads",
    "selectedMainField",
  ];

  const formattedBody = order
    ?.map((key) => {
      const deleteKeys = [
        "team",
        "docObject",
        "gDriveUploads",
        "selectedMainField",
      ];
      if (deleteKeys.includes(key)) return;

      const value = bodyObj?.[key];

      let text = `${camelCaseToNormalText(key)}: `;

      if (key === "teamsConfiguration") {
        text = "Team: ";
      }

      return {
        columns: [
          {
            text: text,
            width: "50%",
            alignment: "left",
            style: ["strong", "lastColumn"],
          },
          {
            text: valueParser({ value, key }),
            width: "50%",
            alignment: "left",
            style: ["lastColumn"],
          },
        ],
      };
    })
    .filter(Boolean);

  const schedules = Object.keys(bodyObj?.selectedSchedule || {})?.length && [
    {
      columns: [
        {
          text: `Schedule Address: `,
          width: "50%",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: bodyObj?.selectedSchedule?.scheduleAddress,
          width: "50%",
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: `Schedule Name: `,
          width: "50%",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: bodyObj?.selectedSchedule?.scheduleName,
          width: "50%",
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: `Schedule Status: `,
          width: "50%",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: bodyObj?.selectedSchedule?.scheduleStatus,
          width: "50%",
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      text: `Schedule Days: `,
      width: "20%",
      alignment: "left",
      style: ["strong", "lastColumn"],
    },
    ...bodyObj?.selectedSchedule?.scheduleDays?.map((scheduleDay) => {
      return {
        columns: [
          {
            width: "33%",
            alignment: "right",
            text: `Start Date:   ${dayjsNY(scheduleDay?.startDate).format(
              `${DEG_DATE_FORMAT}`
            )}`,
            style: ["lastColumn"],
          },
          {
            width: "33%",
            alignment: "right",
            text: `End Date:   ${dayjsNY(scheduleDay?.startDate).format(
              `${DEG_DATE_FORMAT}`
            )}`,
            style: ["lastColumn"],
          },
          {
            width: "33%",
            alignment: "right",
            text: `Status:   ${scheduleDay?.status}`,
            style: ["lastColumn"],
          },
        ],
      };
    }),
  ];

  const teamsConfiguration =
    bodyObj?.teamsConfiguration?.map((team) => {
      return {
        columns: [
          {
            width: "20%",
            text: team?.value,
            alignment: "left",
            style: ["lastColumn", "strong"],
          },
          {
            width: "80%",
            text: team?.members.map(({ nameOfUser }) => nameOfUser).join(", "),
            alignment: "left",
            style: ["lastColumn"],
          },
        ],
      };
    }) || [];

  const tableBody = [
    {
      width: "100%",
      text: `Created At:  ${dayjsNY(body?.createdAt).format(
        `${DEG_DATE_FORMAT} ${DEG_TIME_FORMAT}`
      )}`,
      alignment: "right",
      style: ["lastColumn", "date"],
    },
    {
      columns: [
        {
          width: "25%",
          text: `Status: `,
          alignment: "left",
          style: ["subheader", "strong", "lastColumn"],
        },
        {
          width: "25%",
          text: `${body?.status}`,
          alignment: "left",
          style: ["subheader", "lastColumn"],
        },
        {
          width: "25%",
          text: `Category: `,
          alignment: "left",
          style: ["subheader", "strong", "lastColumn"],
        },
        {
          width: "25%",
          text: `${
            body?.category?.[0].toUpperCase() +
            body?.category?.slice(1, body?.category?.length)
          }`,
          alignment: "left",
          style: ["subheader", "lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: `Request Form:`,
          alignment: "left",
          style: ["subheader", "lastColumn", "strong"],
        },
        {
          width: "25%",
          text: `${body?.requestType}`,
          alignment: "left",
          style: ["subheader", "lastColumn"],
        },
        {
          width: "25%",
          text: `Assigned to:`,
          alignment: "left",
          style: ["subheader", "lastColumn", "strong"],
        },
        {
          width: "25%",
          text: `${body?.assignedTo}`,
          alignment: "left",
          style: ["subheader", "lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: `Created by:`,
          alignment: "left",
          style: ["subheader", "lastColumn", "strong"],
        },
        {
          width: "25%",
          text: `${body?.createdBy}`,
          alignment: "left",
          style: ["subheader", "lastColumn"],
        },
      ],
    },
    {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
        <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
      width: 525,
      height: 2,
      style: ["subheader"],
    },
    {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    ...formattedBody,
    schedules && {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
        <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
      width: 525,
      height: 2,
      style: ["subheader"],
    },
    schedules && {
      text: "Schedules",
      style: ["lastColumn", "strong", "subheader"],
    },
    schedules,
    // teamsConfiguration?.length && {
    //   svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    //     <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
    //     </svg>`,
    //   width: 525,
    //   height: 2,
    //   style: ["subheader"],
    // },
    // teamsConfiguration?.length && {
    //   text: "Team Configurations",
    //   style: ["lastColumn", "strong", "subheader"],
    // },
    // ...teamsConfiguration,
    // Object.keys(body?.requestObject || {}).length &&
    //   pdfBorderedSection({
    //     title: "Test",
    //     body: body?.requestObject,
    //   }),
  ].filter(Boolean);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

export function configPdfApprovalsData({ title, body }) {
  return [
    pdfBorderedSection({
      title,
      body: body || {},
    }),
    // Object.keys(body?.requestForm?.requestObject?.selectedSchedule)?.length &&
    //   pdfBorderedSection({
    //     title: "Selected Schedule",
    //     body: body?.requestForm?.requestObject?.selectedSchedule,
    //   }),
  ];
}
