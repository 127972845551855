import { formatCurrency } from "../../../../../utils";
import { getSums, groupBy } from "../../../calculatingFunctions";

export const rentals = ({ tables, circleProgressPicker }) => {
  const filteredData = tables?.rentals?.filter(
    (a) => a?.rentalStatus === circleProgressPicker
  );

  return {
    rentalStatus: () => ({
      percent: (
        (filteredData?.length / tables?.rentals?.length) *
        100
      )?.toFixed(1),
      custom:
        filteredData &&
        groupBy([filteredData], "projectAddress")?.map((el, index) => {
          return [
            {
              "Total Amount": {
                "Total Price": formatCurrency(
                  getSums({ data: [filteredData], key: "totalPrice" })[index]
                ),
                "Total Applied Rent": formatCurrency(
                  getSums({ data: [filteredData], key: "totalAppliedRent" })[
                    index
                  ]
                ),
                "Total Tax": formatCurrency(
                  getSums({ data: [filteredData], key: "totalTax" })[index]
                ),
                "Retainage Amount": formatCurrency(
                  getSums({ data: [filteredData], key: "retainageAmount" })[
                    index
                  ]
                ),
              },
            },
            ...Object?.entries(el)?.map(([key, value]) => {
              return {
                [key]: {
                  projectId: value?.[0]?.projectId,
                  "Total Price": formatCurrency(
                    getSums({ data: value, key: "totalPrice" })
                  ),
                  "Total Applied Rent": formatCurrency(
                    getSums({ data: value, key: "totalAppliedRent" })
                  ),
                  "Total Tax": formatCurrency(
                    getSums({ data: value, key: "totalTax" })
                  ),
                  "Retainage Amount": formatCurrency(
                    getSums({ data: value, key: "retainageAmount" })
                  ),
                },
              };
            }),
          ];
        }),
    }), //rentalStatus
  };
};
