import { convertCategory } from "../../../../../../../../helpers/constants/chargeConstants";
import {
  calculateTaxForSov,
  calculateTotalPriceForService,
} from "../../../../../../Accounting/components/utilities";
import { totalitiesTotalTaxGen } from "../../../../Rentals/RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";

export const calculateTaxAmountForRowData = (
  data,
  rData,
  forChargeDisplay = false,
  visible
) => {
  if (visible?.categoryFrom === "Schedule Of Value")
    return calculateTaxForSov(rData);
  if (visible?.categoryFrom === "Rental")
    return (
      totalitiesTotalTaxGen({ fakeRentals: rData, forChargeDisplay })
        ?.totalTax || 0
    );
  else {
    let toReturn = data?.reduce((prev, curr) => {
      const { taxAmount } = calculateTotalPriceForService(
        curr,
        convertCategory[visible?.categoryFrom]
      );
      return prev + (taxAmount || 0);
    }, 0);
    if (visible?.categoryFrom === "Requisition") toReturn = 0;
    return toReturn;
  }
};
