import { useMemo } from "react";
import { Divider, Modal } from "antd";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import "./FieldTableFilters.scss";

import dayjs from "dayjs";
import { getRangePresets } from "../../../../../SidebarPages/Fleet/components/InputComponent/utils";
import { MondayButton } from "../../../../../commonComponents";

function FieldTableFilters({
  visible,
  setVisible,
  filters,
  setFilters,
  rowData,
  onApply,
  clearFilters,
  t,
}) {
  const allServices = useMemo(() => {
    const services = new Set();
    rowData?.forEach((row) => {
      row?.schedules?.forEach((schedule) => {
        const scheduleDays = schedule?.days || {};
        scheduleDays?.forEach((day) => {
          const servicesArr = day?.dayServices || [];
          servicesArr?.forEach((service) => {
            services.add(service);
          });
        });
      });
    });
    return Array.from(services);
  }, [rowData]);

  const translatedPresets = useMemo(() => {
    return getRangePresets().map((preset) => ({
      ...preset,
      label: t(preset.label),
    }));
  }, [t]);
  return (
    <Modal
      {...{
        open: visible,
        onCancel: () => setVisible(false),
        title: t("Filters"),
        centered: true,
        className: "field-table-filters-modal",
        closeIcon: <XIcon />,
        footer: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={clearFilters}
            >
              {t("Clear Filters")}
            </MondayButton>
            <MondayButton
              className="mondayButtonGreen"
              Icon={<TickIcon />}
              onClick={onApply}
            >
              {t("Apply")}
            </MondayButton>
          </div>
        ),
      }}
    >
      <InputComponent
        type="select"
        placeholder={t("Select Service")}
        label={t("Service")}
        // dropdownClassName={isDarkMode && "darkDateDropDown"}
        defaultValue={filters?.service}
        customOptions={allServices}
        style={{ width: 250 }}
        onChange={(e) => {
          setFilters({
            ...filters,
            service: e,
          });
        }}
      />
      <Divider />
      <InputComponent
        type="datepicker"
        format="MM/DD/YYYY"
        placeholder={t("Select Date")}
        style={{ width: 250 }}
        disabled={!!filters?.dates}
        label={t("Date")}
        // dropdownClassName={isDarkMode && "darkDateDropDown"}
        value={!filters?.date ? null : dayjs(filters?.date)}
        onChange={(e) => {
          setFilters({
            ...filters,
            date: !!e ? dayjs(e).format("MM/DD/YYYY") : undefined,
          });
        }}
      />
      {!!filters?.dates && (
        <span style={{ color: "#ACACAC", fontSize: 12, textAlign: "center" }}>
          {t("Clear the date range to select a date")}!
        </span>
      )}
      <Divider />
      <InputComponent
        type="rangepicker"
        placeholder={[t("Start Date"), t("End Date")]}
        label={t("Date Range")}
        disabled={!!filters?.date}
        // dropdownClassName={isDarkMode && "darkDateDropDown"}
        value={filters?.dates}
        style={{ width: 300 }}
        rangePresets={translatedPresets}
        onChange={(e) => {
          setFilters({
            ...filters,
            dates: !!e ? [e[0], e[1]] : undefined,
          });
        }}
      />
      {!!filters?.date && (
        <span style={{ color: "#ACACAC" }}>
          {t("Clear the date to select a date range")}!
        </span>
      )}
    </Modal>
  );
}

export default FieldTableFilters;
