import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { newUsersActivityWS, usersActivityWS } from "../sockets";
import { getSessionInfo } from "./useEditLogs";

export const useUsersActivity = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [wsConnection, setWsConnection] = useState();

  const sessionId = JSON.parse(window.localStorage.getItem("sessionId"));

  const finalWsConnection = wsConnection || usersActivityWS;

  //function to recconnect to ws after 60 seconds
  const reconnectWs = async () => {
    usersActivityWS.close();

    const newConnection = await newUsersActivityWS();

    if (!!newConnection) {
      setWsConnection(newConnection);
    } else {
      setTimeout(() => {
        reconnectWs();
      }, 60000);
    }
  };

  const sendNotification = () => {
    if (finalWsConnection.readyState === WebSocket.OPEN) {
      finalWsConnection.send(
        JSON.stringify({ request: "newMsg", body: "newMsg" })
      );
    }
  };

  const createSession = async (ws) => {
    if (finalWsConnection.readyState === WebSocket.OPEN) {
      const sessionInfo = await getSessionInfo();

      ws.send(
        JSON.stringify({
          request: "createSession",
          body: {
            sessionId,
            identityId: userConfiguration?.identityId,
            cognitoUserId: userConfiguration?.cognitoUserId,
            nameOfUser: userConfiguration?.nameOfUser,
            nodeEnv: process.env.NODE_ENV === "development" ? "dev" : "prod",
            sessionInfo,
          },
        })
      );
    }
  };

  useEffect(() => {
    try {
      if (!!sessionId && !!userConfiguration) {
        createSession(finalWsConnection);

        finalWsConnection.onmessage = (event) => {
          const msg = JSON.parse(event.data);
          if (msg?.request === "users-activity") {
            const body = msg?.body;

            if (!!msg?.hasError) {
              console.log("Websocket users-activity error: ", body);
            } else {
            }
          }
        };

        finalWsConnection.onclose = () => {
          reconnectWs();
        };
      }
    } catch (error) {
      console.log("useUsersActivity useEffect error: ", error);
    }
  }, [sessionId, userConfiguration, finalWsConnection]);

  return { sendNotification };
};
