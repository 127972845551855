import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Modal, Button } from "antd";
import DriverSwitchModal from "./DriverSwitchModal";
import { InfoIcon } from "../../../../../Inspections/assets";
import columnDefs from "./columnDefs";
import { useSelector } from "react-redux";

function RouteListingForSwitchModal({
  open,
  onCancel,
  routes: rowData,
  confirmHandler = () => {},
  prevDriver = "",
  driverToSwitch = "",
  allRoutes = false,
  requestData = false,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedRoute, setSelectedRoute] = useState();

  function onChange(checked, record) {
    if (checked) {
      setSelectedRoute(record);
    } else {
      setSelectedRoute();
    }
  }

  return (
    <>
      {rowData?.length !== 0 ? (
        <Modal
          {...{
            open,
            onCancel,
            centered: true,
            title: requestData ? "Requested Routes" : "Routes for new driver",
            "data-testid": "switch-listing-modal",
            className: `routeShowerForSwitchModal ${
              isDarkMode && "routeShowerForSwitchModalDark"
            }`,
            footer: [
              <Button
                danger
                type="primary"
                onClick={onCancel}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>,
              <Button
                type="primary"
                onClick={() => {
                  confirmHandler(selectedRoute);
                }}
                data-testid="listing-confirm-modal"
                disabled={!selectedRoute}
                style={{ borderRadius: "5px" }}
              >
                Confirm
              </Button>,
            ],
          }}
        >
          <div className="switchTableContainer">
            {!requestData && (
              <div className="infoSection">
                <InfoIcon />{" "}
                <p>
                  Select the route you want <strong>{prevDriver}</strong> to
                  take over from
                </p>
              </div>
            )}
            {!requestData && (
              <strong style={{ marginBottom: "1rem" }}>
                All the routes for both drivers will have their times changed
                according to the time difference between switch routes
              </strong>
            )}
            <div
              className={`agGridContainer ${
                isDarkMode
                  ? "dark-ag-theme ag-theme-alpine-dark"
                  : "light-ag-theme ag-theme-alpine"
              }`}
            >
              <AgGridReact
                {...{
                  columnDefs: columnDefs({
                    selectedRoute,
                    onChange,
                    allRoutes,
                    requestData,
                  }),
                  rowData,
                  onRowDoubleClicked(params) {
                    setSelectedRoute(params?.node?.data);
                  },
                  defaultColDef: {
                    resizable: true,
                    enableColResize: true,
                    enableRowGroup: false,
                    sortable: true,
                    filter: false,
                    flex: 1,
                    suppressSizeToFit: true,
                    minWidth: 130,
                  },
                  suppressDragLeaveHidesColumns: true,
                }}
              />
            </div>
          </div>
        </Modal>
      ) : (
        <DriverSwitchModal
          {...{
            open,
            onCancel,
            driverToSwitch,
            switchHandler() {
              confirmHandler(null);
            },
            prevDriver,
          }}
        />
      )}
    </>
  );
}

export default RouteListingForSwitchModal;
