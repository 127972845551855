// returns the highest dimension of a range from a data entry grid input
export function getMax(expression) {
  function evaluate(expression) {
    try {
      // eslint-disable-next-line
      let result = eval(expression);
      if (result > 0 && result !== Infinity) {
        return `${Math.round(result * 100) / 100} ft`;
      } else if (result === Infinity) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        return "NaN";
      }
    }
  }

  if (expression.toString().includes(">")) {
    let expressions = expression.split(">");
    return evaluate(expressions[1]);
  } else {
    return evaluate(expression);
  }
}

export function getMaxNoFeet(expression, serviceId) {
  function evaluate(expression) {
    try {
      // eslint-disable-next-line
      let result = eval(expression);
      if (result > 0 && result !== Infinity) {
        return Math.ceil(result);

        // return Math.round(result * 100) / 100;
      }
      // else if (result === Infinity) {
      //   return 0;
      // }
      else {
        return 0;
        return "Invalid result";
      }
    } catch (error) {
      // if (error instanceof SyntaxError) {
      return "Error";
      // }
    }
  }

  if (expression?.toString().includes(">")) {
    let expressionArray = expression.split(">");
    if (serviceId === 5) {
      //roof protection
      return (
        Math.round(
          ((evaluate(expressionArray[0]) + evaluate(expressionArray[1])) / 2) *
            100
        ) / 100
      ); //get average
    }
    return evaluate(expressionArray[1]);
  } else {
    return evaluate(expression);
  }
}

export function getMaxNoFeetWithMinus(expression, serviceId) {
  function evaluate(expression) {
    try {
      // eslint-disable-next-line
      let result = eval(expression);
      if (result > 0 && result !== Infinity) {
        return Math.ceil(result);

        // return Math.round(result * 100) / 100;
      }
      // else if (result === Infinity) {
      //   return 0;
      // }
      else {
        return "Invalid result";
      }
    } catch (error) {
      // if (error instanceof SyntaxError) {
      return "Error";
      // }
    }
  }

  if (expression?.toString().includes("-")) {
    let expressionArray = expression.toString().split("-");
    if (serviceId === 5) {
      //roof protection
      return (
        Math.round(
          ((evaluate(expressionArray[0]) + evaluate(expressionArray[1])) / 2) *
            100
        ) / 100
      ); //get average
    }
    return evaluate(expressionArray[1]);
  } else {
    return evaluate(expression);
  }
}
