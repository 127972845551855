import uniq from "lodash/uniq"
import { getMonthsTimestamps, getRandomColor } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const fleet = ({ tables }) => {
  return {
    fleetYear: () => ({
      labels: uniq(tables?.Fleet?.map((a) => a?.fleetYear))?.sort(),
      data: uniq(tables?.Fleet?.map((a) => a?.fleetYear))
        ?.sort()
        ?.map(
          (status) =>
            tables?.Fleet?.filter((a) => a.fleetYear?.toLowerCase() === status?.toLowerCase())
              ?.length
        ),
      backgroundColor: uniq(tables?.Fleet?.map((a) => getRandomColor())),
    }), //fleetYear
    registrationExpDate: () => ({
      labels: months,
      data: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            tables?.Fleet?.map((a) => a?.registrationExpDate)?.filter(
              (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
            )?.length,
          ]
        }
        return data
      })(),
      backgroundColor: months.map((a) => getRandomColor()),
    }), //registrationExpDate
    inspectionExpDate: () => ({
      labels: months,
      data: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            tables?.Fleet?.map((a) => a?.inspectionExpDate)?.filter(
              (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
            )?.length,
          ]
        }
        return data
      })(),
      backgroundColor: months.map((a) => getRandomColor()),
    }), //inspectionExpDate
  } //Fleet,
}
