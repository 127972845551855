import axesTooltip, { pieTooltip } from "../../../../../helpers/axesTooltip";

const countOnStart = {
  title: {
    text: "Total Number of Tasks",
  },
  subtitle: {
    text: "Based on Start Date",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "dynStart",
        yKey: ["taskTitle"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "dynStart",
        angleKey: ["taskTitle"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnEnd = {
  title: {
    text: "Total Number of Tasks",
  },
  subtitle: {
    text: "Based on Deadline",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "dynEnd",
        yKey: ["taskTitle"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "dynEnd",
        angleKey: ["taskTitle"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const tasksByTopic = {
  title: {
    text: "Total Number of Tasks",
  },
  subtitle: {
    text: "Based on Topic",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "taskTopic",
        yKey: ["taskId"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "taskTopic",
        angleKey: ["taskId"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnRelatedTo = {
  title: {
    text: "Total Number of Tasks",
  },
  subtitle: {
    text: "Based on Topic Related To",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "taskRelatedTo",
        yKey: ["taskId"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "taskRelatedTo",
        angleKey: ["taskId"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const tasksForSubTopic = {
  title: {
    text: "Total Number of Tasks",
  },
  subtitle: {
    text: "Based on Sub Topic",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "taskSubTopic",
        yKey: ["taskId"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "taskSubTopic",
        angleKey: ["taskId"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const chartingOptions = [
  {
    optionTitle: "Show total number of tasks based on start date",
    params: countOnStart,
  },
  {
    optionTitle: "Show total number of tasks based on deadline",
    params: countOnEnd,
  },
  {
    optionTitle: "Count total number of Tasks for each Topic",
    params: tasksByTopic,
  },
  {
    optionTitle: "Show total number of tasks for each Topic Related To",
    params: countOnRelatedTo,
  },
  {
    optionTitle: "Display Tasks for each Sub Topic",
    params: tasksForSubTopic,
  },

  {
    dynamicKeys: [
      {
        key: "dynStart",
        keyFunction: "dateGetter",
        optionalParams: [[], "taskStartDate"],
      },
      {
        key: "dynEnd",
        keyFunction: "dateGetter",
        optionalParams: [[], "taskDeadline"],
      },
    ],
  },
];

export default chartingOptions;
