import { forceToNumber } from "src/components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "src/components/SidebarPages/utils";
import { MondayButton, RichTextModal } from "src/components/commonComponents";
import {
  DeleteIcon,
  DeleteIconModal,
  PlusIcon,
} from "../../../../../../../../../../../../../../../assets";
import { TrashIcon } from "../../../../../../../../../../../../../Communication/assets";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const getColumns = ({ events = [], service, serviceOption }) => {
  return [
    {
      headerName: "Id",
      headerTooltip: "Id",
      field: "id",
      // width: 25,
      // flex: 1,
      editable: false,
      cellStyle: {
        fontSize: "1.125rem",
        fontWeight: "600",
      },
      cellRenderer: ({ value = 0 }) => value + 1,
    },
    {
      headerName: "Event",
      headerTooltip: "Event",
      field: "name",
      // rowDrag: true,
      minWidth: 200,
      // flex: 5,
      cellRenderer: "EventCellRenderer",
      // cellRendererParams: {
      //   serviceLabel,
      //   estimationId,
      // },
      cellStyle: {
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        fontSize: "1.025rem",
        fontWeight: "500",
        textOverflow: "ellipsis",
      },
      cellEditor: "EventCellEditor",
      cellEditorParams: {
        events: events?.listOfEvents || [],
      },
    },
    {
      headerName: "Charge Percentage",
      headerTooltip: "Percentage",
      field: "rate",
      // flex: 2,
      editable: true,
      cellRenderer: "RateCellRenderer",
      // cellRendererParams: {
      //   serviceLabel,
      //   estimationId,
      // },
      cellEditor: "RateCellEditor",
    },
    {
      headerName: "Charge Amount",
      headerTooltip: "Amount",
      field: "amount",
      // flex: 3,
      editable: true,
      cellRenderer: "AmountCellRenderer",
      cellEditor: "AmountCellEditor",
      getQuickFilterText: () => "",
    },
    // {
    //   headerName: "Difference",
    //   headerTooltip: "Difference",
    //   field: "difference",
    //   // flex: 3,
    //   editable: false,
    //   // cellRenderer: "AmountCellRenderer",
    //   // cellRendererParams: {
    //   //   serviceLabel,
    //   //   estimationId,
    //   // },
    // },
    {
      headerName: "Retainage",
      headerTooltip: "Retainage",
      field: "retainage",
      // flex: 2,
      editable: false,
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      cellStyle: {
        fontSize: "1.025rem",
        fontWeight: "700",
      },
    },

    {
      headerName: "Collectable Amount",
      headerTooltip: "Collectable Amount",
      field: "collectable_amount",
      // flex: 3,
      editable: false,
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      cellStyle: {
        fontSize: "1.025rem",
        fontWeight: "700",
      },
    },
    {
      headerName: "Tax Amount",
      headerTooltip: "Tax Amount",
      field: "taxAmount",
      // flex: 3,
      editable: false,
      valueFormatter: ({ value, data }) =>
        formatCurrency(data?.taxAmount || 0, "USD"),
      // valueGetter: ({ data }) => {
      //   data.taxAmount = forceToNumber(
      //     data?.amount / (data?.custom_tax_amount + 1)
      //   );
      //   return data?.taxAmount;
      // },
      cellStyle: {
        fontSize: "1.025rem",
        fontWeight: "700",
      },
    },
    {
      headerName: "Delete",
      headerTooltip: "Actions",
      // field: "taxAmount",
      // flex: 3,
      // minWidth: 200,
      width: 100,
      editable: false,
      selectable: false,
      suppressCellSelection: true,
      cellRendererFramework: (params) => {
        return (
          <Tooltip title="Remove this row">
            <MondayButton
              hasIcon={false}
              className="mondayButtonRed"
              onClick={() => {
                params.api.applyTransaction({ remove: [params.node.data] });
              }}
            >
              <DeleteFilled style={{ fontSize: "20px" }} />
            </MondayButton>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Add",
      headerTooltip: "Actions",
      // field: "taxAmount",
      // flex: 3,
      // minWidth: 200,
      editable: false,
      width: 100,
      selectable: false,
      suppressCellSelection: true,
      cellRendererFramework: (params) => {
        return (
          <Tooltip title="Add this row">
            <MondayButton
              hasIcon={false}
              className="mondayButttonGreen"
              onClick={() => onAddNew(params, serviceOption, service)}
            >
              <PlusIcon />
            </MondayButton>
          </Tooltip>
        );
      },
    },
  ];
};
const onAddNew = (params, serviceOption, service) => {
  console.log("onAddNew");
  // Add new ag-grid row
  const newItem = {
    name: "",
    rate: 0,
    amount: 0,
    taxAmount: 0,
    id: params.api.getDisplayedRowCount() + 1,
    elevationId: serviceOption?.elevationId || "",
    elevation: serviceOption?.elevationLabel || "",
    // retainagePercentage: serviceOption?.retainagePercentage || 0,
    // retainageAmount: 0,
    // collectable_amount: 0,
  };
  params.api.applyTransaction({ add: [newItem] });
};

const onDelete = (params) => {
  console.log("onDelete");
  // Delete ag-grid row
  params.api.applyTransaction({ remove: [params.node.data] });
};
