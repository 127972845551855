import React, { useContext } from "react";
import { dayjsNY, dayjsNYTimestamp } from "../../contants/DayjsNY";
import TimeDateContext from "../../TimeDateContext";
import "./time.css";
import { useResponsive } from "../../../../hooks";

{
  /* creating array for hours and minutes */
}
const hoursArray = [
  { id: 1, value: 1, text: "01" },
  { id: 2, value: 2, text: "02" },
  { id: 3, value: 3, text: "03" },
  { id: 4, value: 4, text: "04" },
  { id: 5, value: 5, text: "05" },
  { id: 6, value: 6, text: "06" },
  { id: 7, value: 7, text: "07" },
  { id: 8, value: 8, text: "08" },
  { id: 9, value: 9, text: "09" },
  { id: 10, value: 10, text: "10" },
  { id: 11, value: 11, text: "11" },
  { id: 12, value: 12, text: "12" },
];
const minutesArray = [
  { id: 1, value: 5, text: "05" },
  { id: 2, value: 10, text: "10" },
  { id: 3, value: 15, text: "15" },
  { id: 4, value: 20, text: "20" },
  { id: 5, value: 25, text: "25" },
  { id: 6, value: 30, text: "30" },
  { id: 7, value: 35, text: "35" },
  { id: 8, value: 40, text: "40" },
  { id: 9, value: 45, text: "45" },
  { id: 10, value: 50, text: "50" },
  { id: 11, value: 55, text: "55" },
  { id: 12, value: 0, text: "00" },
];

const Time = ({ hideButtons, hideResult }) => {
  const {
    hour,
    minute,
    amOrpm,
    setHour,
    showHour,
    dateTime,
    setMinute,
    setAmOrpm,
    handleTime,
    setShowHour,
    selectedHour,
    selectedMinute,
    handleHourBlur,
    setSelectedHour,
    blockInvalidChar,
    handleMinuteBlur,
    handleHourChange,
    setSelectedMinute,
    handleMinutesChange,
    ownComponent,
    ownComponent2,
  } = useContext(TimeDateContext);

  const { mobile, tablet } = useResponsive();

  return (
    <>
      <div className="timeDatePicker">
        <div
          className={`clock-container ${
            (mobile || tablet) && "clock-container-mobile"
          }`}
        >
          <div className="selectTimeContainer">
            <div className="selectTimeText">
              <p>SELECT TIME</p>
            </div>
            <div className="timeButtons">
              <input
                data-testid="hour-button"
                className={`${showHour === true && "timeButtonsActive"} hour`}
                onClick={() => setShowHour(true)}
                value={hour}
                onChange={(e) => handleHourChange(e.target.value)}
                type="number"
                min="0"
                max="12"
                onKeyDown={blockInvalidChar}
                onBlur={() => handleHourBlur()}
              />
              <div className="doubleDots">:</div>
              <input
                data-testid="minute1"
                className={`${
                  showHour === false && "timeButtonsActive"
                } minute`}
                onClick={() => setShowHour(false)}
                value={minute}
                onChange={(e) => handleMinutesChange(e.target.value)}
                type="number"
                min="0"
                max="60"
                onKeyDown={blockInvalidChar}
                onBlur={() => handleMinuteBlur()}
              />
              <div className="amORpm">
                <div
                  className={`${
                    amOrpm == "am" ? "timeButtonsActive" : "timeButtonsDeActive"
                  } am`}
                  onClick={() => setAmOrpm("am")}
                >
                  AM
                </div>
                <div
                  className={`${
                    amOrpm == "pm" ? "timeButtonsActive" : "timeButtonsDeActive"
                  } pm`}
                  onClick={() => setAmOrpm("pm")}
                >
                  PM
                </div>
              </div>
            </div>
            <div>{ownComponent && ownComponent}</div>
          </div>
          <div className="clock-block">
            {showHour ? (
              <div className="clock">
                {hoursArray.map((hour) => (
                  <div
                    data-testid={`hour${hour.id}`}
                    className={` ${
                      selectedHour == hour.value && "selectedNumber"
                    } number number${hour.id}`}
                    key={hour.id}
                    onClick={() => {
                      setHour(hour.text);
                      setSelectedHour(hour.text);
                      setShowHour(false);
                    }}
                  >
                    <div
                      className="valueOfNumber"
                      style={{
                        backgroundColor:
                          selectedHour == hour.value && "#1264A3",
                        color: selectedHour == hour.value && "#fff",
                      }}
                    >
                      {hour.text}
                    </div>
                  </div>
                ))}
                <div className="centerOfClock"></div>
              </div>
            ) : (
              <div className="clock">
                {minutesArray.map((minute) => (
                  <div
                    data-testid={`minute${minute.id}`}
                    className={` ${
                      selectedMinute <= minute.value + 2 &&
                      selectedMinute >= minute.value - 2 &&
                      "selectedNumber"
                    } number number${minute.id}`}
                    key={minute.id}
                    onClick={() => {
                      setMinute(minute.text);
                      setSelectedMinute(minute.text);
                    }}
                  >
                    <div
                      className="valueOfNumber"
                      style={{
                        backgroundColor:
                          selectedMinute <= minute.value + 2 &&
                          selectedMinute >= minute.value - 2 &&
                          "#1264A3",
                        color: selectedMinute == minute.value && "#fff",
                      }}
                    >
                      {minute.text}
                    </div>
                  </div>
                ))}
                <div className="centerOfClock"></div>
              </div>
            )}
            <div className="ownComponent2">
              {!!ownComponent2 && ownComponent2}
            </div>
          </div>
          {/* if user clicked to Date&Time then we give props to Time component with hiddenButtons and hideResult,
            the DateTimePicker component has its own buttons and result areas so we dont need to display them two times */}
          {!hideButtons ? (
            <div className="buttons">
              <div className="okCancel-btn">
                <button className="cancel-btn">Cancel</button>
                <button className="ok-btn" onClick={() => handleTime()}>
                  OK
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {!hideResult && (
        <div className="showClockResult">
          <h4>
            <span>Date: </span>
            <p data-testid="result">
              {dayjsNY(dateTime).format("YYYY-MMM-DD HH:mm:ss").toString()}
              {/* You should use format in order to conver time correctly to timezone */}
            </p>
          </h4>
          <h4>
            <span>Timestamp: </span>
            <p data-testid="result">{dayjsNYTimestamp(dateTime).valueOf()}</p>
          </h4>
        </div>
      )}
    </>
  );
};

export default Time;
