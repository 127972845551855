import { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import TableInvoice from "./table_invoice/table_invoice";
import styleModule from "./invoicePaper.module.css";
import "./invoicePaper.scss";
import { useMediaQuery } from "react-responsive";

const InvoicePaper = ({
  myProps,
  setInvoicePreviewPdfAction,
  invoicePreviewPdfAction,
  searchInvoicePreviewText,
}) => {
  const { invoiceNumber } = myProps.previewCurrentInvoce;
  const [pdfWidth, setPdfWidth] = useState(false);

  useEffect(() => {
    if (
      invoicePreviewPdfAction &&
      document.querySelector(`.${styleModule.invoicePaperContPDF}`)
    ) {
      savePrintInvoiceToPdf(
        invoiceNumber,
        setInvoicePreviewPdfAction,
        invoicePreviewPdfAction
      );
    }
  }, [invoicePreviewPdfAction]);

  //SAVE PRINT PDF =========================================

  const savePrintInvoiceToPdf = (
    invoiceNumber,
    setInvoicePreviewPdfAction,
    invoicePreviewPdfAction
  ) => {
    const userAgent = navigator.userAgent;
    const el = document.querySelector(`.invoicePaperCont`);
    // document.getElementById(`pagePDF`);
    const pdf = new jsPDF("p", "pt", "letter");
    setPdfWidth(true);
    pdf.html(el, {
      callback: () => {
        setPdfWidth(false);
        invoicePreviewPdfAction === "print"
          ? window.open(pdf.output("bloburl"), "_blank")
          : userAgent.match(/chrome|chromium|crios/i)
          ? pdf.save(`invoice_${invoiceNumber}`)
          : userAgent.match(/firefox|fxios/i)
          ? pdf.output("dataurlnewwindow")
          : userAgent.match(/safari/i)
          ? pdf.save(`invoice_${invoiceNumber}`)
          : userAgent.match(/opr\//i)
          ? pdf.save(`invoice_${invoiceNumber}`)
          : userAgent.match(/edg/i)
          ? pdf.save(`invoice_${invoiceNumber}`)
          : pdf.save(`invoice_${invoiceNumber}`);

        setInvoicePreviewPdfAction(false);
      },
    });
  };

  const width1 = useMediaQuery({ minWidth: "1281px" });
  const width2 = useMediaQuery({ minWidth: "1025px", maxWidth: "1280px" });
  const width3 = useMediaQuery({
    minWidth: "768px",
    maxWidth: "1024px",
    orientation: "landscape",
  });
  const width4 = useMediaQuery({ minWidth: "768px", maxWidth: "1024px" });
  const width5 = useMediaQuery({ minWidth: "481px", maxWidth: "767px" });

  function setWidth() {
    if (width1) {
      return "50%";
    }
    if (width2) {
      return "50%";
    }
    if (width3) {
      return "70%";
    }
    if (width4) {
      return "80%";
    }
    if (width5) {
      return "90%";
    }
  }

  //SAVE PRINT PDF =========================================

  return (
    <>
      <div
        className={styleModule.invoicePaperCont + " invoicePaperCont"}
        style={{
          width: pdfWidth ? "610px" : setWidth(),
        }}
      >
        <TableInvoice
          {...{
            myProps: {
              mode: "viewMode",
              previewCurrentInvoce: myProps.previewCurrentInvoce,
            },
            searchInvoicePreviewText,
          }}
        />
      </div>
      <div className={styleModule.invoicePaperContPDF}>
        <TableInvoice
          {...{
            myProps: {
              mode: "printMode",
              previewCurrentInvoce: myProps.previewCurrentInvoce,
            },
            searchInvoicePreviewText,
          }}
        />
      </div>
    </>
  );
};

export default InvoicePaper;
