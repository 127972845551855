import { useDispatchProgramFields } from "../../../../../../hooks";
import { realTimePostEventsAutomations } from "../utils";
import { executeOneTimeAutomation } from "./executeOneTimeAutomation";

const usePostEventsAutomation = () => {
  // get the location of executed event
  const { href } = window.location;

  // this is used to dispatch program field we are working with
  const { dispatchFields } = useDispatchProgramFields(
    "34534rger+dfgdfgdf+3464redgrehgrhrt",
    "Post Events Automations Configurations",
    false,
    false
  );

  // this executes the post event automations if all requirements are completed
  const postEventsAutomationHandler = async ({
    automationName = "",
    dataToDisplayInAutomation,
    category = "",
    action = "",
  }) => {
    const defaultFieldOptions = await realTimePostEventsAutomations();

    const findedAutomationToModify = defaultFieldOptions.find(
      (func) =>
        automationName === func.automationName && category === func.categoryName
    );

    if (!findedAutomationToModify) {
      console.error("No configuration found for the provided automation");
      return;
    }

    if (!action) {
      console.error("no action provided");
      return;
    }

    if (!findedAutomationToModify.shouldPostExecute) {
      console.log("Automation is stopped");
      return;
    }

    if (!automationName || !dataToDisplayInAutomation || !category) {
      console.error("Missing props for executing event automation");
      return;
    }

    const updatedAutomation = {
      ...findedAutomationToModify,
      automationBody: {
        ...findedAutomationToModify.automationBody,
        dataToShowInAutomationResponse: dataToDisplayInAutomation,
      },
      action,
      href,
    };

    try {
      const responses = await executeOneTimeAutomation(updatedAutomation);
      const cronLog = responses.map((response) => ({
        description: "Email sent successfully",
        toUsers: [response.data.to],
        date: parseInt(response.data.timestampDate),
        response: automationName,
        action: action || "",
        linkTo: href,
      }));

      console.log({ defaultFieldOptions });

      const proceedFieldOptionsCron = defaultFieldOptions.map((field) => {
        if (field.uid === findedAutomationToModify.uid) {
          const updatedResponses = [
            ...(field?.cronLogs?.responses || []),
            ...cronLog,
          ];
          return {
            ...field,
            cronLogs: {
              responses: updatedResponses,
            },
          };
        } else {
          return field;
        }
      });

      console.log({ proceedFieldOptionsCron });

      dispatchFields(proceedFieldOptionsCron);
    } catch (error) {
      console.log({ error });
    }
  };

  return { postEventsAutomationHandler };
};

export default usePostEventsAutomation;
