import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const projects = (programFields, updatedDynamicStates, IdHandler) => {
  return {
    projectStatus: () => ({
      title: "Project Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Projects",
        field: "projectStatus",
        content: programFields
          ?.find((a) => a?.fieldName === "Project Status")
          ?.fieldOptions?.map(({ statusName }, index) => ({
            key: index,
            title: statusName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) =>
                a?.projectStatus?.toLowerCase() ===
                statusName.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: statusName + " Project",
            contentsHandler: statusName + " Projects",
          })),
      },

      id: IdHandler,
      width: "1600px",
    }), //projectStatus

    attorney: () => ({
      title: "Project Attorney",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Projects",
        field: "attorney",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "attorney"
          )
        ).map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: key + " Project",
          contentsHandler: key + " Projects",
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),

    expeditor: () => ({
      title: "Project Expeditor",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Projects",
        field: "expeditor",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "expeditor"
          )
        ).map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: key + " Project",
          contentsHandler: key + " Projects",
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  }; //Projects
};
