import React, { createContext, useContext } from "react";
import { useCreateCharge } from "../hooks/useCreateCharge";

const NewChargeItemContext = createContext();

export const useCreateChargeContext = () => {
  return useContext(NewChargeItemContext);
};

export const NewChargeItemProvider = ({ children, ...props }) => {
  const newCharge = useCreateCharge({ ...props });
  return (
    <NewChargeItemContext.Provider value={{ ...newCharge }}>
      {children}
    </NewChargeItemContext.Provider>
  );
};
