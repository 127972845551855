import React from "react";
import { useState } from "react";
import { MondayButton } from "../../../../../commonComponents";
import ProposalAssitant from "./ProposalAssitant/ProposalAssitant";

function ProposalAssistantWrapper({ editorRef = "" }) {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {/* <MondayButton
        className="mondayButtonYellow"
        Icon={<>?</>}
        onClick={() => setVisible(true)}
      >
        Proposal Assistant
      </MondayButton> */}
      {visible && (
        <ProposalAssitant
          editorRef={editorRef}
          visible={visible}
          closeModal={() => setVisible(false)}
        />
      )}
    </>
  );
}

export default ProposalAssistantWrapper;
