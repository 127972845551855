import "./OnBoarding.scss";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { Modal, message } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { OnBoardingContext } from "./OnBoardingContext.js";
import { getBoardingSteps, saveStepLogs } from "./utils";
import { MondayButton } from "../commonComponents";
import { TickIcon } from "../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../SidebarPages/Communication/assets";
import { UserConfig, preferences as preferencesDispatch } from "../../actions";
import { useEditLogs } from "../../hooks";
import BoardingHeader from "./components/BoardingHeader/BoardingHeader";
import BoardingSteps from "./components/BoardingSteps/BoardingSteps";
import LoadableComp from "../SidebarPages/XComponents/LoadableComp.jsx";

const OnBoarding = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const boardingStepRef = useRef(null);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: userPrefs } = useSelector((state) => state.preferences);

  const [currentStep, setCurrentStep] = useState(0);
  const [boardingChanges, setBoardingChanges] = useState({});

  const { saveAddedLogs } = useEditLogs();

  const saveChangesHandler = () => {
    const {
      notifications,
      automationPreferences,
      defaultDashboardConfig,
      isDarkMode: changedDarkMode,
    } = boardingChanges;

    let newPreferences = {
      ...(userPrefs?.preferences || {}),
      darkMode: isDarkMode,
    };
    let boardingConfigs = userConfiguration?.boardingConfigs || {};
    const logsArr = [];

    const updateConfigs = (stepKey) => {
      boardingConfigs = {
        ...boardingConfigs,
        [stepKey]: { ...boardingConfigs?.[stepKey], finished: true },
      };
      logsArr.push({
        key: "finished",
        stepKey,
        stepTitle: boardingSteps.find(({ key }) => key === stepKey).title,
      });
    };

    message.loading("Updating preferences...");

    if (!!notifications) {
      newPreferences = {
        ...newPreferences,
        notifications: {
          ...(newPreferences?.notifications || {}),
          topics: notifications,
        },
      };
      updateConfigs("notificationStep");
    }

    if (!!automationPreferences) {
      newPreferences = {
        ...newPreferences,
        notifications: {
          ...(newPreferences?.notifications || {}),
          automationPreferences,
        },
      };
      updateConfigs("automationStep");
    }

    if (typeof changedDarkMode === "boolean") {
      updateConfigs("themeStep");
    }

    let newUserPrefs = { preferences: newPreferences };

    if (!!defaultDashboardConfig) {
      Object.assign(newUserPrefs, {
        defaultConfiguration: {
          ...(userPrefs?.defaultConfiguration || {}),
          dashboard: defaultDashboardConfig?.statesData,
        },
        widgetConfiguration: {
          ...(userPrefs?.widgetConfiguration || {}),
          dashboard: defaultDashboardConfig?.widgetConfig,
        },
        widgetRepetition: {
          ...(userPrefs?.widgetRepetition || {}),
          dashboard: defaultDashboardConfig?.widgetRepetition,
        },
        widgetHiddenConfiguration: {
          ...(userPrefs?.widgetHiddenConfiguration || {}),
          dashboard: [],
        },
        widgetLayout: {
          ...(userPrefs?.widgetLayout || {}),
          dashboard: defaultDashboardConfig?.layout,
        },
      });

      updateConfigs("dashboardStep");
    }

    API.patch("preferences", "/preferences", { body: newUserPrefs })
      .then(() => {
        message.destroy();
        message.success("Preferences updated successfully!");

        setBoardingChanges((prev) => {
          const {
            notifications,
            automationPreferences,
            defaultDashboardConfig,
            isDarkMode,
            ...rest
          } = prev;
          return rest;
        });

        dispatch(preferencesDispatch({ ...userPrefs, ...newUserPrefs }));

        onCloseModal();
      })
      ?.catch((err) => console.error("Error updating preferences", err));

    API.patch("userConfiguration", "/userConfiguration", {
      body: { boardingConfigs },
    }).then(() => {
      saveStepLogs(logsArr, saveAddedLogs, userConfiguration);
    });

    dispatch(UserConfig({ ...userConfiguration, boardingConfigs }));
  };

  const onCloseModal = () => {
    setVisible(false);

    // add boardingConfigs obj to save boarding steps inside & to not display it again
    if (!userConfiguration?.boardingConfigs) {
      API.patch("userConfiguration", "/userConfiguration", {
        body: { boardingConfigs: {} },
      }).then(() => {
        dispatch(UserConfig({ ...userConfiguration, boardingConfigs: {} }));
      });
    }
  };

  const boardingSteps = getBoardingSteps({ currentStep, isDarkMode });
  const SelectedStep = boardingSteps[currentStep].component;

  const completedSteps = Object.values(
    userConfiguration?.boardingConfigs || {}
  ).filter(({ finished }) => !!finished).length;

  return (
    <Modal
      title={`Welcome ${userConfiguration?.nameOfUser?.split(" ")[0] || ""}`}
      className={`on-boarding-container ${isDarkMode ? "dark-mode" : ""}`}
      width="100%"
      closeIcon={<XIcon />}
      open={visible}
      footer={
        <>
          <div className="footer-left">
            <InfoCircleFilled
              style={{
                fontSize: 20,
                color: isDarkMode ? "#F9F9F9" : "#1264A3",
              }}
            />
            <p>
              If you want, you can save changes right away and change all your
              settings later.
            </p>
          </div>

          {Object.keys(boardingChanges).length === 0 &&
          completedSteps === boardingSteps.length ? (
            <MondayButton
              className="mondayButtonBlue"
              onClick={onCloseModal}
              Icon={<TickIcon />}
            >
              Finish
            </MondayButton>
          ) : (
            <MondayButton
              data-testid="save-changes-btn"
              disabled={Object.keys(boardingChanges).length === 0}
              onClick={saveChangesHandler}
              Icon={<TickIcon />}
            >
              Save Changes
            </MondayButton>
          )}
        </>
      }
      onCancel={onCloseModal}
    >
      <LoadableComp loading={!userConfiguration}>
        <OnBoardingContext.Provider
          value={{
            currentStep,
            setCurrentStep,
            boardingSteps,
            boardingStepRef,
          }}
        >
          <div className="boarding-content">
            <BoardingHeader />
            <BoardingSteps />

            <div className="step-component-wrapper">
              <SelectedStep
                {...{ setCurrentStep, boardingChanges, setBoardingChanges }}
              />
            </div>
          </div>
        </OnBoardingContext.Provider>
      </LoadableComp>
    </Modal>
  );
};

export default OnBoarding;
