import axios from "axios";

const encryptUri = {
  locale:
    process.env.NODE_ENV === "production"
      ? "http://localhost:8080/api/encrypt/prod"
      : "http://localhost:8080/api/encrypt",
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/api/encrypt/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/api/encrypt",
};

const decryptUri = {
  locale:
    process.env.NODE_ENV === "production"
      ? "http://localhost:8080/api/decrypt/prod"
      : "http://localhost:8080/api/decrypt",
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/api/decrypt/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/api/decrypt",
};

/**
 * @param {string} key
 */
export async function encryptKey(key) {
  try {
    const res = await axios.post(encryptUri.online, { key });
    return res?.data;
  } catch (error) {
    console.log("Error encrypting key: ", error);
  }
}

/**
 * @param {{iv: string; encryptedData: string}} encryptionData
 */
export async function decryptKey({ iv, encryptedData }) {
  try {
    const res = await axios.post(decryptUri.online, { iv, encryptedData });
    return res?.data;
  } catch (error) {
    console.log("Error decrypting key: ", error);
  }
}
