//

import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

//

const getEventsForCalendar = async ({
  calendarID,
  startDate = dayjsNY().startOf("month").format(),
  endDate = dayjsNY().endOf("month").format(),
  showDeleted = false,
}) => {
  return gapi.client.calendar.events.list({
    calendarId: calendarID,
    timeMin: startDate,
    timeMax: endDate,
    timeZone: "America/New_York",
    showDeleted: showDeleted,
    singleEvents: true,
    maxResults: 2500,
  });
};

export const getEventsForCalendars = async ({
  arrOfCalendarIDs,
  startDate = dayjsNY().startOf("month").format(),
  endDate = dayjsNY().endOf("month").format(),
  showDeleted = false,
}) => {
  try {
    const promises = arrOfCalendarIDs.map((calendarID) => {
      return getEventsForCalendar({
        calendarID,
        startDate,
        endDate,
        showDeleted,
      });
    });
    const results = await Promise.all(promises);

    let toReturn = results.flatMap((result) => result.result.items);
    console.log("toReturn", toReturn);
    return toReturn;
  } catch (error) {
    console.log("Error in getEventsForCalendars", error);
    return [];
  }
};
