//this function is used to modify key values of object
//example i need to convert key that contain array in string only for edit logs
export const destructValueFromKey = ({
  obj = {},
  destructKeys = {},
  avoidKeys = [],
}) => {
  let updatedObj = {};

  for (const key in obj) {
    //Avoid given keys
    if (avoidKeys.includes(key)) {
      continue;
    }

    //if kys has a destruc value save destructed value in updatedObj
    if (key in destructKeys) {
      //destruct value based on type
      updatedObj[key] = Array.isArray(obj[key])
        ? obj[key]
            .map(({ [destructKeys[key]]: destructedKey }) => destructedKey)
            .join(", ")
        : typeof obj[key] === "object"
        ? obj[destructKeys[key]]
        : obj[key];
    } else {
      updatedObj[key] = obj[key];
    }
  }

  return updatedObj;
};
