import "./SwitchComponent.scss";
import React, { Dispatch, SetStateAction } from "react";
import { Tooltip, message } from "antd";
import WithTooltip from "../WithTooltip/WithTooltip";

type Dispatcher<S> = Dispatch<SetStateAction<S>>;
type Props = {
  label: string;
  disabledTest: string;
  checked: boolean;
  disabled: boolean;
  onChange: Dispatcher<boolean>;
  style?: React.CSSProperties;
  agGridFilterClear?: () => Promise<void>;
  tooltipCategory: string;
};

const SwitchComponent = ({
  label,
  disabledTest,
  disabled,
  checked,
  onChange,
  style,
  agGridFilterClear,
  tooltipCategory,
}: Props): JSX.Element => {
  return (
    <WithTooltip {...{ tooltipCategory, tooltipKey: "switchComponent" }}>
      <div className="switch-container" style={style}>
        <div className="switch-toggle">
          <input
            type="checkbox"
            className="switch-toggle-input"
            name={label}
            id={label}
            checked={checked}
            onChange={async (e) => {
              if (disabled) {
                message.error(disabledTest || "This field is disabled!");
                return;
              }
              const checked = e.target.checked;
              if (!!agGridFilterClear)
                agGridFilterClear?.()?.then?.(() => {
                  onChange(checked);
                });
              else onChange(checked);
            }}
          />
          <label className="switch-toggle-label" htmlFor={label}>
            <span
              className="switch-toggle-label-inner"
              style={
                {
                  "--content-dynamic": `"${label?.split?.(" ")[0]}"`,
                } as React.CSSProperties
              }
            ></span>
            <span className="switch-toggle-label-switch"></span>
          </label>
        </div>
      </div>
    </WithTooltip>
  );
};

export default SwitchComponent;
