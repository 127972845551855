import { useEffect, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import "./DateRange.scss";
import dayjs from "dayjs";
const DateRange = ({ params, edited, setNewValues }) => {
  const [values, setValues] = useState(params);

  useEffect(() => {
    if (edited) {
      keys(edited).map((el) => {
        values.rowData.find(({ formItemName }) => formItemName === el).value =
          edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
    }
  }, [JSON.stringify(edited)]);

  return (
    <div className="InformationBody">
      <div className="InforamtionBodyElementContainer">
        {values.rowData.map(({ label, value, formItemName, type }) => {
          return (
            <>
              {/* <span>{value}</span> */}
              <span className="InformationBodyElementContainerTitle">
                {label}
              </span>
              <span className="InforamtionBodyElementContainerContent">
                {dayjs(value).format("MM/DD/YYYY").toString() === "Invalid Date"
                  ? ""
                  : dayjs(value).format("MM/DD/YYYY")}
              </span>
            </>
          );
        })}
      </div>
    </div>
  );
};
export default DateRange;
