import { Modal } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { InvoiceAgGrid } from "./InvoiceAgGrid";
import { InvoiceSelector } from "./InvoiceSelector";
import { Header, SecondaryHeader } from "./components";
import { updateEditLogs, updateInvoices } from "./utils";
import { useRedux } from "../../../../../hooks/useRedux";
import { queryParams } from "../../../../../../../utils/queryParams";
import {
  filterTables,
  sendNotification,
  showErrorMsg,
  showLoadingMsg,
} from "../../../../../../../utils";
import { updateClientBalance } from "../../../../../Accounting/Tabs/Payments/utils/paymentUtils";

import "../NewChargeItem/NewChargeItems.scss";
import { useEditLogs } from "../../../../../../../hooks";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import Swal from "sweetalert2";

export const NewCreditMemo = ({ invoices = [], project, visible }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { saveAddedLogs } = useEditLogs();

  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [rowData, setRowData] = useState();
  const [, setVisibleChargeVisible] = useRedux("newChargeVisible");

  const [creditsMemo, setCreditsMemo] = useRedux("creditsMemo");
  const [objectData, setVisible] = useRedux("chargeItemsModal");

  const currentCharge = objectData?.currentCharge;
  const [chargeToSave, setChargeToSave] = useState({
    ...({ chargeItems: currentCharge?.chargeItems } || {}),
  });
  const [creditAmount, setCreditAmount] = useState(0);

  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );

  const { userConfiguration } = useSelector((state) => state.userConfig);

  // const creditAmount =
  //   chargeToSave?.chargeItems?.reduce(
  //     (prev, { creditAmount, taxAmount }) =>
  //       prev + (creditAmount || 0) + (taxAmount || 0),
  //     0
  //   ) || 0.0;

  useEffect(() => {
    setRowData(
      invoices?.map((invoice) => {
        const alreadyChargedAmount = invoice.invoiceItems?.reduce(
          (total, item) =>
            total +
            item?.credits?.reduce(
              (itemTotal, credit) => itemTotal + (credit.creditedAmount || 0),
              0
            ),
          0
        );

        const totalAmountDue =
          invoice.totalInvoiceAmount - alreadyChargedAmount;

        const totalAmountPaid =
          invoice.totalInvoiceAmount -
          (invoice.amountDue + alreadyChargedAmount);

        const percentagePaid = parseFloat(
          totalAmountPaid / invoice.totalInvoiceAmount
        );

        return {
          ...invoice,
          invoiceItems: invoice?.invoiceItems?.map((invoiceItem) => {
            const isIncludedInPrev = chargeToSave?.chargeItems?.find(
              ({ invoiceId, id }) =>
                invoiceId === invoice?.invoiceId && id === invoiceItem?.id
            );
            if (isIncludedInPrev) {
              return {
                ...isIncludedInPrev,
                paidAmount:
                  (invoiceItem?.scopeAmount || 0) * percentagePaid || 0,
                credits: invoiceItem?.credits,
              };
            }
            return {
              ...invoiceItem,
              scopeAmount: invoiceItem.scopeAmount
                ? invoiceItem.scopeAmount
                : invoiceItem.amount,
              creditAmount: 0.0,
              creditPercentage: 0.0,
              appliedAmount: 0.0,
              taxAmount: 0.0,
              total: 0,
              paidAmount: (invoiceItem?.scopeAmount || 0) * percentagePaid || 0,
              invoiceNumber: invoice?.invoiceNumber,
              invoiceId: invoice?.invoiceId,
              taxRate: project?.taxRate || 0,
            };
          }),
        };
      })
    );
    setSelectedInvoices(
      invoices?.reduce(
        (obj, { invoiceId }) => ({ ...obj, [invoiceId]: true }),
        {}
      )
    );
  }, []);

  const exitIfInEditMode = () => {
    if (location.state?.goBackTo === "accountingGrid") {
      navigate(-1);
    } else {
      navigate(
        //the first parameter is the link
        `/projects/${currentCharge.projectId}?${queryParams({
          tab: "Accounting",
          subTab: "Credit",
        })}`,
        //those parameters are used to send it directly to the rentals tab
        {
          fromAccounting: true,
          accountingTab: "Credit",
          tabPosition: "accounting",
        }
      );
    }
  };

  const chargeToSaveHandler = (dataToSave) => setChargeToSave(dataToSave);

  const onAddNewInvoice = () => {};

  const onClose = () => {
    if (currentCharge) {
      exitIfInEditMode();
    }
    setVisibleChargeVisible();
    setVisible();
  };

  const saveCharge = async () => {
    const recordsID = [
      ...new Set(chargeToSave?.chargeItems?.map(({ invoiceId }) => invoiceId)),
    ];
    const projectTaxRate = project?.taxRate || 0;
    const accountId = project?.accountId;

    if (!accountId) {
      showErrorMsg({
        content: `There was a problem ${
          currentCharge ? "updating" : "creating"
        } Credit. Please contact administrator.`,
      });
      return;
    }
    let tempCred =
      chargeToSave?.chargeItems?.reduce(
        (prev, { creditAmount, taxAmount }) =>
          prev + (creditAmount || 0) + (taxAmount || 0),
        0
      ) || 0.0;
    const newChargeObject = {
      chargeItems: chargeToSave?.chargeItems,
      creditAmount,
      chargeAmount: creditAmount,
      appliedAmount: tempCred,
      taxAmount: chargeToSave?.chargeItems?.reduce(
        (acc, { taxAmount = 0 }) => (acc += taxAmount),
        0
      ),
      remainingAmount: creditAmount - tempCred,
      ...(currentCharge
        ? {}
        : {
            chargeType: "Credit Memo",
            categoryFrom: "Invoice",
            recordsID: recordsID,
            projectId: project.projectId,
            projectAddress: project.projectName,
          }),
    };

    try {
      const result = await API[currentCharge ? "put" : "post"](
        "charges",
        `/charges${currentCharge ? `/${currentCharge?.chargeId}` : ""}`,
        {
          body: newChargeObject,
        }
      );

      setCreditsMemo([result, ...(creditsMemo || [])]);
      updateEditLogs({
        currentCharge: currentCharge ?? result,
        saveAddedLogs,
        newChargeObject,
        isEditing: currentCharge ? true : false,
      });

      /* If Credit Memo is from Invoice */
      const chargeItems = result?.chargeItems;
      showLoadingMsg({ content: "Updating Invoices. Please wait..." });

      await updateInvoices({
        accountId,
        chargeItems,
        invoices,
        newChargeObject: currentCharge
          ? { ...currentCharge, ...newChargeObject }
          : result,
        projectTaxRate,
        onClose,
      });

      const newClientBalance = currentCharge
        ? creditAmount - currentCharge.chargeAmount
        : creditAmount;

      await updateClientBalance({
        accountId: accountId,
        paymentAmount: -newClientBalance,
      });

      const account = await filterTables("accounts", "accountId", accountId);

      if (account.length > 0)
        sendNotification({
          id: "97",
          action: currentCharge ? "onChargeEdit" : "onChargeCreation",
          team: account?.[0]?.teamsConfiguration || [],
          otherKeys: {
            topicName: account?.[0]?.accountName,
            chargeType: "Credit",
            categoryFrom: currentCharge
              ? currentCharge?.categoryFrom
              : result?.categoryFrom,
            ...(currentCharge && {
              chargeNumber: currentCharge.chargeNumber,
            }),
          },
          recordId: currentCharge ? chargeToSave.chargeId : result.chargeId,
          authenticatedUser: authUser,
          userConfiguration,
        });
    } catch (error) {
      showErrorMsg({
        content:
          "There was a problem with creating credit or updating invoices/payments.",
      });
    }
  };

  const onCreditChange = (e) => {
    setCreditAmount(forceToNumber(e.target.value.replace(/[^0-9.]/g, "")) || 0);
  };

  useEffect(() => {
    let currentCharge = visible?.currentCharge?.chargeAmount || 0;
    setCreditAmount(forceToNumber(currentCharge));
  }, [visible]);

  return (
    <Modal
      wrapClassName="newChargeItemWrap"
      className="newChargeItemModal newCreditMemo"
      open={true}
      closable={false}
      onClose={onClose}
      title={
        <Header
          {...{
            currentCharge,
            saveCharge,
            onClose,
            creditAmount,
            onAddNewInvoice,
            onCreditChange,
          }}
        />
      }
      footer={false}
    >
      {currentCharge && (
        <SecondaryHeader
          {...{
            project,
            currentCharge,
            chargeToSave,
          }}
        />
      )}
      <div className="newChargeItemContainer">
        <InvoiceSelector
          {...{
            invoices: rowData,
            selectedInvoices,
            setSelectedInvoices,
          }}
        />
        <div className="invoicesContainer">
          {rowData?.map(
            (
              {
                invoiceItems,
                invoiceId,
                invoiceNumber,
                subtotal,
                totalInvoiceAmount: total,
              },
              i
            ) => {
              const setNewRowData = (operation) => {
                const newData =
                  typeof operation === "function"
                    ? operation(invoiceItems)
                    : operation;

                setRowData((prev) => {
                  prev[i] = {
                    ...prev[i],
                    invoiceItems: newData,
                  };
                  return structuredClone(prev);
                });
              };
              return (
                <InvoiceAgGrid
                  {...{
                    style: !selectedInvoices[invoiceId]
                      ? { display: "none" }
                      : {},
                    rowData: invoiceItems,
                    setRowData: setNewRowData,
                    chargeToSave,
                    invoiceNumber,
                    setChargeToSave,
                    chargeToSaveHandler,
                    invoiceId,
                    subtotal,
                    taxAmount: total - subtotal,
                    currentCharge,
                    taxRate: project?.taxRate || 0,
                    creditAmount,
                  }}
                />
              );
            }
          )}
        </div>
      </div>
      {/* <TotalAbsolute {...{ total, title: "Total Credit Amount" }} /> */}
    </Modal>
  );
};
