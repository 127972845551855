import { StarFilled, StarOutlined } from "./assets";
import "./StarCheckbox.scss";

const StarCheckbox = ({ checked, onChange }) => (
  <div
    id="favoriteToggle"
    className={"starCheckbox"}
    onClick={(e) => {
      e.preventDefault();
      onChange(!checked);
    }}
  >
    {!!checked ? <StarFilled /> : <StarOutlined />}
  </div>
);

export default StarCheckbox;
