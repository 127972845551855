import { filterRowDataByTeamConfig } from "./RowData/filterRowData";
import { API } from "aws-amplify";
import { fetchSearchRecords } from "./fetchSearchRecords";
import { preventDuplicate } from "src/components/Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { fetchData } from "../components/SidebarPages/Fleet/utils";

/**
 * @function getSelectedBaseRecords - Fetched some records to be shown in the dropdown initially
 * @param {String} idKey - The primary key of the table use for lastEvaluatedKey
 * @param {String} table - The table name
 * @param {Function} setLoading - Set loading state
 * @param {Function} setRecords - Set the records state
 * @param {Object} userConfiguration - The user configuration object
 * @param {Function} setCurrentPageKey - Set the current page key that is used for pagination
 * @param {Boolean} customSetRecords - default false, if true, only passes the result to the state callBack and then it is handled by the parent component
 * @param {Array} keysToInclude - The keys to include in the search query
 */
export const getSelectedBaseRecords = async ({
  idKey = "",
  table = "",
  setLoading = () => {},
  setRecords = () => {},
  userConfiguration = {},
  customSetRecords = false,
  setCurrentPageKey = () => {},
  keysToInclude = [],
}) => {
  setLoading(true);

  return await fetchData(table, table, {
    queryStringParameters: {
      ...(!!keysToInclude?.length && {
        keysToInclude: JSON.stringify(keysToInclude),
      }),
    },
  })
    ?.then((res) => {
      const tmpRecords = (Array?.isArray(res) ? res : res?.[table])?.sort(
        (a, b) => {
          if (a?.createdAt < b?.createdAt) {
            return -1;
          } else if (a?.createdAt > b?.createdAt) {
            return 1;
          }
          return 0;
        }
      );

      if (res?.LastEvaluatedKey) {
        setCurrentPageKey(res?.LastEvaluatedKey?.[idKey]);
      }
      if (customSetRecords) {
        setRecords(tmpRecords);
      } else {
        setRecords(filterRowDataByTeamConfig(userConfiguration, tmpRecords));
      }
      setLoading(false);
    })
    .catch((error) => {
      console.log("Error Fetching Data : ", error);

      if (API.isCancel(error)) {
        console.log("Request was cancelled");
      }
    });
};

/**
 * @function cancelCall - Cancel an API call
 * @param {function reference} apiCall is the reference of the API call function that we need to cancel
 */

function cancelCall(apiCall) {
  if (apiCall) {
    API.cancel(apiCall, "Request cancelled");
  }
}

// async function fetchDataPromise(table, table, { queryStringParameters }) {
//   return await new Promise(async (resolve, reject) => {
//     return await fetchData(table, table, {
//       queryStringParameters,
//     })
//       ?.then((res) => {
//         resolve(res);
//       })
//       ?.catch((error) => {
//         console.log("Error Fetching Data : ", error);
//         reject(error);
//       });
//   });
// }

/**
 * @function handlePopupScroll - When we reach the bottom of the popup, fetch more records with pagination
 * @param {Event} e - The event object
 * @param {String} table - The table name
 * @param {String} idKey - The primary key of the table use for lastEvaluatedKey
 * @param {String} currentPageKey - The current page key that is used for pagination
 * @param {Function} setLoading - Set loading state
 * @param {Function} setRecords - Set the records state
 * @param {Object} userConfiguration - The user configuration object
 * @param {Boolean} customSetRecords - default false, if true, only passes the result to the state callBack and then it is handled by the parent component
 * @param {Function} setCurrentPageKey - Set the current page key that is used for pagination
 * @param {Array} keysToInclude - The keys to include in the search query
 */
export const handlePopupScroll = async ({
  e = {},
  table = "",
  idKey = "",
  arrayKey = "",
  currentPageKey = null,
  setLoading = () => {},
  setRecords = () => {},
  userConfiguration = {},
  setCurrentPageKey = () => {},
  customSetRecords = false,
  keysToInclude = [],
}) => {
  const currentPageCondition = () => {
    if (typeof currentPageKey === "string") {
      return !!currentPageKey;
    } else if (
      typeof currentPageKey === "object" &&
      !Array.isArray(currentPageKey)
    ) {
      return Object.values(currentPageKey).some((el) => !!el);
    }
  };

  if (!!currentPageCondition()) {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    // Check if the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight) {
      e.preventDefault();
      setLoading(true);
      if (Array.isArray(table) && Array.isArray(idKey)) {
        let tmpPageKey = {};

        Promise.allSettled(
          table?.map(async (el) => {
            let data;
            data = await fetchData(el, el, {
              queryStringParameters: {
                ExclusiveStartKey: currentPageKey?.[el] || undefined,
                withPagination: "true",
                ...(!!keysToInclude?.[el]?.length && {
                  keysToInclude: JSON.stringify(keysToInclude?.[el]),
                }),
              },
            });

            return { ...data, table: el };
          })
        )
          .then((res) => {
            tmpPageKey = res?.reduce(
              (acc, curr) => ({
                ...acc,
                [curr?.value?.table]: curr?.value?.LastEvaluatedKey,
              }),
              {}
            );

            const toReturn = {};

            res?.forEach((el) => {
              Object.assign(toReturn, {
                [el?.value?.table]: el?.value?.[el?.value?.table],
              });
            });

            setCurrentPageKey(tmpPageKey);

            setRecords(toReturn);

            setLoading(false);
          })
          .catch((err) => {
            console.error("Error Fetching Data : ", err);
            setLoading(false);
          });
      } else {
        await fetchData(table, table, {
          queryStringParameters: {
            ExclusiveStartKey: currentPageKey || undefined,
            withPagination: "true",
            ...(!!keysToInclude.length && {
              keysToInclude: JSON.stringify(keysToInclude),
            }),
          },
        })?.then((res) => {
          const tmpRecords = (Array?.isArray(res) ? res : res?.[table])?.sort(
            (a, b) => {
              if (a?.createdAt < b?.createdAt) {
                return -1;
              } else if (a?.createdAt > b?.createdAt) {
                return 1;
              }
              return 0;
            }
          );
          if (res?.LastEvaluatedKey) {
            setCurrentPageKey(res?.LastEvaluatedKey?.[idKey]);
          }
          if (customSetRecords) {
            setRecords(tmpRecords);
          } else {
            setRecords((prev) => {
              if (!!prev?.length) {
                return preventDuplicate(
                  prev?.concat(
                    filterRowDataByTeamConfig(userConfiguration, tmpRecords)
                  ),

                  idKey
                );
              }
              return preventDuplicate(
                filterRowDataByTeamConfig(userConfiguration, tmpRecords),
                idKey
              );
            });
          }
          setLoading(false);
        });
      }
    }
  }
};

/**
 * @function searchFetchInput - Fetch records based on the search input
 * @param {table} table - The table name
 * @param {String} idKey - The primary key of the table use for lastEvaluatedKey
 * @param {String} arrayKey - The array key that needs to be set in case the records are an object
 * @param {String} searchKey - The search key
 * @param {String} searchValue - The search value
 * @param {Function} setLoading - Set loading state
 * @param {Function} setRecords - Set the records state
 * @param {Object} userConfiguration - The user configuration object
 * @param {Boolean} customSetRecords - default false, if true, only passes the result to the state callBack and then it is handled by the parent component
 * @param {Function} setCurrentPageKey - Set the current page key that is used for pagination
 * @param {Array || Object} keysToInclude - The keys to include in the search query, if we are searching multiple tables it needs to be an object with keys corresponding to each table with an array of strings as value
 */
export const searchFetchInput = async ({
  table = "",
  idKey = "",
  arrayKey = "",
  searchKey = "",
  searchValue = "",
  setLoading = () => {},
  setRecords = () => {},
  userConfiguration = {},
  customSetRecords = false,
  setCurrentPageKey = () => {},
  keysToInclude = null,
}) => {
  if (searchValue?.trim()?.length >= 3) {
    setLoading(true);

    cancelCall(getSelectedBaseRecords);

    if (
      Array.isArray(table) &&
      Array.isArray(idKey) &&
      Array.isArray(arrayKey) &&
      Array.isArray(searchKey)
    ) {
      Promise.allSettled(
        table?.map(async (el, index) => {
          let data;
          data = await fetchSearchRecords(
            el,
            searchKey[index],
            searchValue,
            keysToInclude?.[el]
          );

          return { [arrayKey[index]]: data };
        })
      )
        ?.then((res) => {
          setLoading(false);

          const toReturn = [];
          let pageKeyToSet = {};

          res?.forEach((el) => {
            if (Object.keys(el?.value) > 1) {
              Object.keys(el?.value)?.forEach((key) => {
                if (key?.toLowerCase() === "lastevalueatedkey") {
                  Object.assign(pageKeyToSet, {
                    [key]: el?.value[key],
                  });
                } else {
                  toReturn.push({
                    value: el?.value,
                  });
                }
              });
            } else {
              toReturn.push({
                value: el?.value,
              });
            }
          });

          // console.log("PAGE KEY TO SET : ", pageKeyToSet);

          // setCurrentPageKey(pageKeyToSet);
          setRecords(toReturn);
        })
        ?.catch((error) => {
          console.error("Error Fetching Data : ", error);
          setLoading(false);
        });
    } else {
      await fetchSearchRecords(table, searchKey, searchValue, keysToInclude)
        ?.then((res) => {
          // setCurrentPageKey(res?.LastEvaluatedKey?.[idKey]);
          const tmpRecords = (Array?.isArray(res) ? res : res?.[table])?.sort(
            (a, b) => {
              if (a?.createdAt < b?.createdAt) {
                return -1;
              } else if (a?.createdAt > b?.createdAt) {
                return 1;
              }
              return 0;
            }
          );
          if (customSetRecords) {
            setRecords(tmpRecords);
          } else {
            setRecords((prev) => {
              if (Array.isArray(prev)) {
                if (!!prev?.length) {
                  return preventDuplicate(
                    prev?.concat(
                      filterRowDataByTeamConfig(userConfiguration, tmpRecords)
                    ),

                    idKey
                  );
                } else {
                  return preventDuplicate(
                    filterRowDataByTeamConfig(userConfiguration, tmpRecords),
                    idKey
                  );
                }
              } else if (Object.prototype(prev).constructor === Object) {
                return {
                  ...prev,
                  [arrayKey]: preventDuplicate(
                    prev?.[arrayKey]?.concat(
                      filterRowDataByTeamConfig(userConfiguration, tmpRecords)
                    ),
                    idKey
                  ),
                };
              }
            });
          }
          setLoading(false);
        })
        ?.catch((error) => {
          console.log("Error Fetching Data : ", error);

          setLoading(false);
        });
    }
    // }
  }
};
