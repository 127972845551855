import isEqual from "lodash/isEqual";

export const hasValuesChanged = (initialData = {}, finalData) => {
  // console.log(initialData)
  // console.log(finalData)
  // const initialKeys = Object.keys(initialData);
  // const finalKeys = Object.keys(finalData);
  // if (initialKeys.length !== finalKeys.length) {
  //   return false;
  // }
  // for (const key of initialKeys) {
  //   const val1 = object1[key];
  //   const val2 = object2[key];
  //   const areObjects = isObject(val1) && isObject(val2);
  //   if (
  //     areObjects && !deepEqual(val1, val2) ||
  //     !areObjects && val1 !== val2
  //   ) {
  //     return false;
  //   }
  // }
  return isEqual(initialData, finalData);
};

// function isObject(object) {
//   return object != null && typeof object === 'object';
// }
