import { message } from "antd";
import { debounce } from "lodash";
import { searchFetchInput } from "../../../../../utils/searchFetchForInput";

const setLoading = (value) => {
  if (!!value) {
    return message.loading({
      content: "Getting Data",
      key: "loading",
      duration: 0,
    });
  }

  return message.destroy("loading");
};

const searchSafetyInspectionAddress = debounce(
  async ({
    searchValue = "",
    setProjects = () => {},
    userConfiguration = {},
  }) => {
    return await searchFetchInput({
      table: "projects",
      idKey: "projectId",
      arrayKey: "projects",
      searchKey: "projectName",
      searchValue,
      userConfiguration,
      listOfKeys: [
        "projectId",
        "projectName",
        "accountId",
        "accountName",
        "createdAt",
        "teamsConfiguration",
      ],
      setLoading,
      setRecords: setProjects,
    });
  },
  500
);

export default searchSafetyInspectionAddress;
