import RentalCredit from "../RentalCredit/RentalCredit";
import { Redirect } from "../../../../../../../../../BasePage/src";
import { useStateContext } from "../../../../../Context/Context";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import ChangeRentalRetainage from "./ChangeRentalRetainage/ChangeRentalRetainage";
import { redirectToRental } from "../../../../Header/Components/ControlPanel/ControlPanelComponents/IncludeRentals/includeRentalsFunctions";

import "./RentalHeader.scss";

const RentalHeader = ({
  rentalId,
  rentalNumber,
  rentalStatus,
  taxRate,
  rental,
  type,
}) => {
  const { darkMode } = useStateContext();

  return (
    <div
      className={
        darkMode ? "includeRentalHeaderSovDark" : "includeRentalHeaderSovLight"
      }
    >
      <div className="rentalInformationContainer">
        <div className="label">Rental:</div>
        <div className="value"> {rentalNumber}</div>
      </div>

      <div className="rentalInformationContainer">
        <div className="label">Rental status:</div>
        <div className="value"> {rentalStatus}</div>
      </div>
      {/* <div className="rentalInformationContainer">
				<div className="label">Rental tax Rate:</div>
				<div className="value"> {taxRate > 0 ? taxRate * 100 : 0}%</div>
			</div> */}

      <div className="rentalInformationContainer">
        <MondayButton
          className="mondayButtonBlue"
          onClick={() => redirectToRental({ rentalId })}
          Icon={<Redirect />}
        >
          View rental
        </MondayButton>{" "}
        {type === "this" && <RentalCredit {...{ rental }} />}
      </div>
      <div>
        {type !== "this" && (
          <ChangeRentalRetainage {...{ rentalNumber, darkMode }} />
        )}
        {/* <MondayButton
          className="mondayButtonBlue"
          Icon={<RetainageIcon />}
          onClick={changeRentalRet}
        >
          Change Rental Retainage{" "}
        </MondayButton> */}
      </div>
    </div>
  );
};

export default RentalHeader;
