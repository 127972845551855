import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Radio } from "antd";

import { ProjectMatchContext } from "../../data";
import { parseInTz } from "../../../NewDispatchModal/utils/dateFunctions";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { LeftArrow } from "src/components/SidebarPages/BasePage/src";
import { TodoGrayIcon } from "../../../../../../../../assets";
import { FilterIcon } from "../../../../../../BasePage/src";

import "./ProjectMatchController.scss";

function ProjectMatchController() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    onFilterChange,
    filters,
    textFilterHandler,
    offProjectData,
    offScheduleData,
    drivers,
    vehicles,
    updateViewType,
    viewType,
    matchedData,
    selectedViewData,
    selectedId,
    updateSelectedId,
    changeConfirmDisabled,
    changeConfirmText,
    openFilteredProjectsModal,
    filteredAddresses,
    openManualMatchModal,
  } = useContext(ProjectMatchContext);

  const customOptions = useMemo(() => {
    const tmp =
      filters["records"] === "Off Project"
        ? { ...offProjectData }
        : { ...offScheduleData };

    for (const record of filteredAddresses) {
      if (record.type !== filters["records"]) {
        continue;
      }

      delete tmp[record.address];
    }

    return Object.keys(tmp).map((e) => ({
      label: e,
      value: e,
    }));
  }, [filters["records"], offProjectData, offScheduleData, filteredAddresses]);

  const buttonConfig = useMemo(() => {
    return {
      ["Off Project"]: {
        backgroundColor:
          filters["records"] === "Off Project"
            ? !isDarkMode
              ? "#1264A3"
              : "#123754"
            : isDarkMode
            ? "#3A3C4E"
            : undefined,
        color: !isDarkMode
          ? undefined
          : filters["records"] === "Off Project"
          ? "#FFF"
          : "#ACACAC",
      },
      ["Off Schedule"]: {
        backgroundColor:
          filters["records"] === "Off Schedule"
            ? !isDarkMode
              ? "#1264A3"
              : "#123754"
            : isDarkMode
            ? "#3A3C4E"
            : undefined,
        color: !isDarkMode
          ? undefined
          : filters["records"] === "Off Schedule"
          ? "#FFF"
          : "#ACACAC",
      },
    };
  }, [filters["records"], isDarkMode]);

  return (
    <div
      className={`project-match-controller-container ${
        isDarkMode ? "match-container-dark" : ""
      }`}
    >
      {viewType === "PROJECT" && (
        <div className="controller-section">
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              Icon: <LeftArrow />,
              onClick() {
                updateViewType({
                  type: "ALL",
                  address: null,
                  id: null,
                });
                changeConfirmDisabled(true);
                changeConfirmText();
              },
            }}
          >
            Back to addresses
          </MondayButton>
        </div>
      )}
      <div className="controller-section">
        {viewType === "ALL" && (
          <InputComponent
            {...{
              label: null,
              noFormItem: true,
              type: "select",
              customOptions,
              allowClear: true,
              showSearch: true,
              className: "address-select",
              placeholder: "Search address...",
              onChange(e) {
                textFilterHandler(e);
              },
            }}
          />
        )}
        <InputComponent
          {...{
            label: null,
            noFormItem: true,
            type: "datePicker",
            allowClear: true,
            placeholder: "Select a date...",
            value: filters["date"] ? parseInTz(filters["date"]) : undefined,
            onChange(e) {
              if (e) {
                onFilterChange({
                  date: parseInTz(e).startOf("D").format(),
                  dateChangeReason: "USER",
                });
              } else {
                onFilterChange({ date: null, dateChangeReason: "USER" });
              }
            },
          }}
        />
        <InputComponent
          {...{
            label: null,
            noFormItem: true,
            type: "select",
            allowClear: true,
            showSearch: true,
            customOptions: vehicles.map((e) => ({
              value: e.fleetId,
              label: e.fleetName,
            })),
            placeholder: "Select a vehicle...",
            onSelect(e) {
              onFilterChange({ fleetId: e });
            },
            onClear() {
              onFilterChange({ fleetId: null });
            },
          }}
        />
        <InputComponent
          {...{
            label: null,
            noFormItem: true,
            allowClear: true,
            type: "select",
            placeholder: "Select a driver...",
            customOptions: drivers.map((e) => ({
              label: e.driverName,
              value: e.driverId,
            })),
            showSearch: true,
            onSelect(e) {
              onFilterChange({ driverId: e });
            },
            onClear() {
              onFilterChange({ driverId: null });
            },
          }}
        />
        {selectedViewData &&
        matchedData?.[selectedViewData["address"]] &&
        Object.keys(matchedData?.[selectedViewData["address"]]).length > 1 ? (
          <InputComponent
            {...{
              label: null,
              type: "select",
              noFormItem: true,
              placeholder: "Select a client",
              initialValue:
                matchedData?.[selectedViewData["address"]][selectedId]?.[
                  "item"
                ]?.["differentiatorName"] || "",
              customOptions: Object.keys(
                matchedData?.[selectedViewData["address"]]
              ).map((id) => {
                return {
                  label:
                    matchedData?.[selectedViewData["address"]][id]["item"][
                      "differentiatorName"
                    ],
                  value: id,
                };
              }),
              allowClear: false,
              onSelect: updateSelectedId,
            }}
          />
        ) : null}
      </div>
      {viewType === "ALL" && (
        <div className="controller-section">
          <Radio.Group
            options={["Off Project", "Off Schedule"].map((e) => ({
              label: e,
              value: e,
              style: buttonConfig[e],
            }))}
            value={filters["records"]}
            optionType="button"
            buttonStyle="solid"
            defaultValue="Off Project"
            onChange={(ev) => {
              onFilterChange({ records: ev.target.value });
            }}
          />
          <MondayButton
            className="mondayButtonBlue"
            Icon={<FilterIcon />}
            onClick={() => {
              openFilteredProjectsModal();
            }}
          >
            Address Filter
          </MondayButton>
          {filters["records"] === "Off Project" ? (
            <MondayButton
              Icon={<TodoGrayIcon height={20} width={18} />}
              className="mondayButtonBlue"
              onClick={() => {
                openManualMatchModal();
              }}
            >
              Address Matching
            </MondayButton>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default ProjectMatchController;
