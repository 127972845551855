import { API } from "aws-amplify";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";

import {
  attachDataSources,
  attachParameters,
  extractDataSources,
  extractReportParameters,
  generateReportBlob,
  mapProppedDataSources,
  populateReport,
} from "../../../utils";
import { ReportDesigner } from "../../../components";
import { LeftArrow } from "../../../../BasePage/src";
import { views } from "../../reportsConfigurationData";
import { updateReport } from "../../actions/wrapperFunctions";
import { XIcon } from "../../../../Communication/assets/index";
import { generatePdfThumbnail } from "../../../../../../utils";
import { ReportsConfigContext } from "../../ReportsConfigContext";
import { MondayButton } from "../../../../../commonComponents/index";
import { getChangedData } from "../../../../Accounting/components/utilities";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as WarningIcon } from "../../../../../../icons/warningTriangle.svg";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src/index";

import "./ReportConfig.scss";
import { updateEditLogs } from "./updateEditLogs";
import { useEditLogs } from "../../../../../../hooks";

const ReportConfig = () => {
  const {
    objectSamples,
    selectedCategory,
    selectedReport,
    setSelectedReport,
    setActiveView,
    curryDispatch,
  } = useContext(ReportsConfigContext);
  // Unsaved changes modal state
  const [confirmationModalVisible, setConfirmationModalVisible] = useState();

  const { categoryParameters = [] } = selectedCategory || {};

  const [{ userConfiguration }, { isDarkMode }] = useSelector((state) => [
    state.userConfig,
    state.darkMode,
  ]);
  const { saveAddedLogs } = useEditLogs();

  // Destructuring of the selected report
  const {
    reportId,
    datasets,
    reportParameters = [],
    reportName,
    reportObj,
  } = selectedReport || {};
  const isNew = !reportObj;
  const designerRef = useRef();
  const onBack = () => {
    setSelectedReport(undefined);
    setActiveView(views.CATEGORY_CONFIG);
  };

  // new log object that will store the changes
  let newEditLog = {
    recordId: reportId,
    recordName: reportName,
    topic: `ReportsConfiguration/${selectedCategory.department}/${reportName}`,
    actionType: "Edit",
    category: "Reports Configuration",
    currentData: {},
    label: "",
    member: userConfiguration.nameOfUser,
    nameOfUser: userConfiguration.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    previousData: {},
    updatedKeys: [],
  };

  const onSave = async (report) => {
    const formattedReportObj = extractDataSources(cloneDeep(report));
    const newReport = {
      ...selectedReport,
      reportObj: formattedReportObj,
      datasets: formattedReportObj?.definition?.DataSets,
      reportParameters: extractReportParameters(formattedReportObj),
      datasources: formattedReportObj?.definition?.DataSources || [],
    };

    const populatedReportObj = await populateReport(
      newReport,
      categoryParameters
    );

    const pdfBlob = await generateReportBlob(populatedReportObj);
    const buffer = await pdfBlob.data.arrayBuffer();
    const thumbnail = await generatePdfThumbnail(new Uint8Array(buffer));

    // update changes in DB
    curryDispatch(
      updateReport(selectedCategory, {
        ...newReport,
        thumbnail,
      })
    );
    // Get changes
    let result = getChangedData(newReport.reportObj, reportObj);

    if (!!result) {
      const { currChange, prevChange } = updateEditLogs(result);

      newEditLog.currentData = currChange;
      newEditLog.previousData = prevChange;
      newEditLog.updatedKeys.push(reportName);
    }

    // update DB with current chagnes
    saveAddedLogs(newEditLog);

    onBack();
  };

  const onBackButtonClick = async () => {
    const report = await designerRef.current.getReport();

    !!report.isDirty ? setConfirmationModalVisible(true) : onBack();
  };

  useEffect(() => {
    (async () => {
      if (!reportId) return;
      const designerComponent = designerRef.current;
      let loadedReport = cloneDeep(await designerComponent.getReport());

      loadedReport = {
        ...loadedReport,
        id: reportId,
        displayName: reportName,
        definition: reportObj?.definition || loadedReport.definition,
      };

      loadedReport.definition.DataSets = datasets;

      // Loads the report
      designerComponent.setReport(
        attachParameters(
          attachDataSources(loadedReport, objectSamples, isNew),
          reportParameters,
          categoryParameters
        )
      );
    })();
  }, [reportId]);

  return (
    <>
      <div className="reportConfigurationDesignerContainer">
        <ReportDesigner
          {...{
            ref: designerRef,
            dataSources: mapProppedDataSources(objectSamples),
            onSave,
            newEditLog,
            reportName,
            isDarkMode,
            reportsAvailable: selectedCategory.reportsAvailable,
          }}
        />
        <div className="backButton" onClick={onBackButtonClick}>
          <LeftArrow />
        </div>
      </div>

      <WarningModal
        visible={confirmationModalVisible}
        setVisible={setConfirmationModalVisible}
        title={selectedReport?.reportName}
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p style={{ textAlign: "center" }}>
            You have unsaved changes, are you sure you want to go back?
          </p>
          <div className="buttons">
            <MondayButton
              onClick={() => setConfirmationModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                onBack();
                setConfirmationModalVisible(false);
              }}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </>
  );
};

export default ReportConfig;
