import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { state } from "../../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { EyeInvisibleFilled } from "@ant-design/icons";
import { Close } from "../../../src";
import { hiddenElement } from "../../../utils/alignItems";
import "./Drawer.scss";
import { useSelector } from "react-redux";
const DrawerComponent = ({
  visible,
  setVisible,
  dataView,
  setDataView,
  setChanged,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const onClose = () => {
    setVisible(false);
  };
  const [hiddenElements, setHiddenElements] = useState([]);
  useEffect(() => {
    setHiddenElements(
      hiddenElement({
        overviewData: dataView,
        hidden: true,
      })
    );
  }, [visible, dataView]);

  return (
    <Drawer
      width={485}
      placement="right"
      rootClassName={`viewDrawer ${isDarkMode && "viewDrawerDark"}`}
      closable={true}
      destroyOnClose={true}
      onClose={onClose}
      closeIcon={<Close />}
      open={visible}
      title={
        <span className="titleLabel">
          Hidden Cards ({hiddenElements.length})
        </span>
      }
    >
      <div className="viewDrawerBody">
        <div className="informationWrapper">
          <span className="informationIcon">i</span>
          <span className="informationText">
            This are the cards that you have hidden.
          </span>
        </div>
        <div className="viewDrawerContent">
          {hiddenElements.map((e, i) => (
            <div key={i} className="hiddenElement">
              <span className="headerElementIcon">
                <EyeInvisibleFilled />
              </span>
              <span className="headerElementTitle">{e.title}</span>
              <span
                className="showElementLink"
                onClick={() => {
                  dataView.find((el) => el.id === e.id).hidden = false;
                  setChanged(true);
                  setDataView(state(dataView));
                }}
              >
                Click to show this card
              </span>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};
export default DrawerComponent;
