import React from "react";
import { InfoCircleFilled } from "@ant-design/icons";

import "./InfoMessage.scss";

const InfoMessage = ({ text }) => {
  return (
    <div className="information-container">
      <InfoCircleFilled />
      <p>{text}</p>
    </div>
  );
};

export default InfoMessage;
