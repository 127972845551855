import { getAccessRights } from "../../../../../utils";
import { hasSettingsAccess } from "./hasSettingsAccess";

export const getTopics = (
  topicCategories,
  userConfiguration,
  programFields
) => {
  const homePageTasks = topicCategories.reduce((acc, category) => {
    const { categoryName } = category;

    const title =
      categoryName === "Applications"
        ? "Approvals"
        : categoryName === "Fleets"
        ? "Fleet"
        : categoryName === "Fleet Inspections"
        ? "Fleets Inspections"
        : categoryName;

    if (title === "Approvals") {
      return acc;
    }

    // used to check if user has access to view records of each topic
    if (
      (category.hasOwnProperty("fromSettings") &&
        category.fromSettings === true &&
        hasSettingsAccess(userConfiguration, category.access)) ||
      (!category.hasOwnProperty("fromSettings") &&
        getAccessRights({ userConfiguration, title }).routeConfig.read === true)
    ) {
      acc.push({
        value: category.categoryName,
        label: category.categoryName,
        ...category,
      });
    }
    return acc;
  }, []);

  const settingsTasks = [];

  programFields
    ?.find(({ fieldName }) => fieldName === "Settings Tasks")
    ?.fieldOptions.filter((item) => item.included.includes("task"))
    .forEach((item) => {
      hasSettingsAccess(userConfiguration, item.access) &&
        settingsTasks.push({
          ...item,
          value: item.categoryName,
          label: item.categoryName,
        });
    });

  const topics = [...homePageTasks, ...settingsTasks];

  return topics;
};
