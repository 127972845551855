/**
 * Calculates the percentage based on an input value mapped from one range to another.
 * @param {number} input - The value to be mapped
 * @param {number} input_start - The start of the input range.
 * @param {number} input_end - The end of the input range.
 * @param {number} output_start - The start of the output range. Default is 0 when we want to calculate form 0% - 100%.
 * @param {number} output_end - The end of the output range. Default is 100 when we want to calculate form 0% - 100%.
 * @returns {number} The calculated percentage based on the input value.
 */

function calculatedPercentages(
  input,
  input_start,
  input_end,
  output_start = 0,
  output_end = 100
) {
  let slope = (output_end - output_start) / (input_end - input_start);

  let percentage = output_start + slope * (input - input_start);

  return percentage.toFixed(1);
}

/**
 * Takes an array of numbers and rounds their sum to 100% by adjusting the largest percentage.
 *
 * @param {number[]} numArr An array of numbers.
 * @returns {number[]} An array containing the rounded percentages.
 */
function roundPercentageTotals(numArr) {
  const total = numArr.reduce((acc, num) => acc + num, 0);

  const percentages = numArr.map((num) => (num / total) * 100);

  const roundedPercentages = percentages.map(
    (percent) => Math.round(percent * 100) / 100
  );

  // Check if the total percentage is already 100%
  const totalPercentage = roundedPercentages.reduce((acc, num) => acc + num, 0);
  if (totalPercentage === 100) {
    return roundedPercentages;
  }

  // If totalPercentage is less or more than 100%, adjust the largest percentage
  const difference = 100 - totalPercentage;

  const largestIndex = roundedPercentages.indexOf(
    Math.max(...roundedPercentages)
  );

  roundedPercentages[largestIndex] += difference;

  // Round each percentage again to ensure exactly 2 decimal places
  const finalPercentages = roundedPercentages.map(
    (percent) => Math.round(percent * 100) / 100
  );

  return finalPercentages;
}

module.exports = {
  calculatedPercentages,
  roundPercentageTotals,
};
