import { ExportPreview } from "../../../../../commonComponents";
import { safetyPdfBody } from "../../../safetyPdf";
import AddRemoveFields from "./components/AddRemoveFields";
import SortReportFields from "./components/SortReportFields";
import {
  convertSafetyReport,
  initialExportData,
  SAFETY_REPORT_STYLES,
  safetyExportApplyFilters,
} from "./helpers";

function SafetyExportPreview({
  wordPreview,
  setWordPreview,
  rowObject,
  formFields,
  selectedTeam,
  safetyCategory,
  witnessCount,
  witnessSignings,
  defaultId,
  safetyFormData,
  userConfiguration,
  accessToken,
}) {
  async function getInitialData() {
    return await initialExportData({
      modalType: "safety",
      rowObject,
      formFields,
      selectedTeam,
      safetyPdfBody,
      safetyCategory,
      witnessCount,
      witnessSignings,
      defaultId,
      safetyFormData,
      userConfiguration,
      accessToken,
    });
  }

  const defaultHeaderSection = [
    {
      text: {
        paragraphs: [
          {
            text: rowObject?.["safetyName"] + " " + safetyCategory + " Report",
            class: "safety-title",
            bold: true,
            header: true,
          },
        ],
      },
    },
    {
      text: {
        paragraphs: [
          {
            text: "Date: " + new Date().toLocaleDateString(),
            class: "safety-section-header no-margin",
            bold: true,
          },
        ],
      },
    },
  ];

  return wordPreview ? (
    <ExportPreview
      {...{
        //region PDF PREVIEW
        open: wordPreview,
        onCancel: () => setWordPreview(false),
        documentTitle: rowObject?.safetyName + " " + safetyCategory + " Report",
        getInitialData: getInitialData,
        asyncInitialData: true,
        keyName: "safety " + safetyCategory,
        applyFilters: safetyExportApplyFilters,
        convertData: convertSafetyReport,
        tabs: [
          {
            key: "add/remove",
            label: "Add/Remove",
            component: AddRemoveFields,
          },
          {
            key: "sort",
            label: "Sort",
            component: SortReportFields,
          },
        ],
        customStyles: SAFETY_REPORT_STYLES,
        defaultHeaderSection,
      }}
    />
  ) : null;
}

export default SafetyExportPreview;
