import React from "react";
import { Divider, Modal } from "antd";
import useTranslate from "../../../Language/useTranslate";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { DocUploader } from "../../../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
import "./ServiceFileViewerModal.scss";

const styles = {
  label: {
    fontSize: 14,
    color: "#323338",
  },
  subLabel: {
    paddingLeft: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "0 10px",
  },
};

function DocumentSection({ title, documents, divider = false }) {
  const { t } = useTranslate();

  return (
    <>
      <label style={{ ...styles.label, fontWeight: "bold" }}>{title}</label>
      {Object.keys(documents || {})
        ?.filter((key) => !!(documents?.[key] || [])?.length)
        ?.map((key, i) => (
          <div key={i}>
            <label style={{ ...styles.label, ...styles.subLabel }}>
              {t(key)}
            </label>
            <DocUploader
              onlyUploadedFiles
              disabled
              checkWarnings={false}
              responsiveWidth={100}
              uploadedFiles={documents?.[key] || []}
            />
          </div>
        ))}
      {divider && (
        <Divider
          style={{
            margin: "10px 0",
          }}
        />
      )}
    </>
  );
}

function ServiceFileViewerModal({
  serviceDocModal,
  setServiceDocModal,
  foremanDocuments,
}) {
  const { t } = useTranslate();

  return (
    <Modal
      open={!!serviceDocModal}
      onCancel={() => setServiceDocModal("")}
      title={t("Service Documents")}
      className="progress-changes-modal service-file-modal"
      centered
      footer={null}
      closeIcon={<XIcon />}
      destroyOnClose
    >
      <div style={styles.container}>
        <DocumentSection
          title={t(`${serviceDocModal} Documents`)}
          documents={foremanDocuments?.[serviceDocModal] || {}}
        />
        <DocumentSection
          title={t("Estimation Documents")}
          documents={foremanDocuments?.["estimations"] || {}}
          divider
        />
      </div>
    </Modal>
  );
}

export default ServiceFileViewerModal;
