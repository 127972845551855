import XLSX from "sheetjs-style";
import moment from "moment";
import createPDF from "../../../../../integrations/createPDF";
import { generateDocDefinition } from "../../../../../utils/generateDocDefinition";
import { excelCellFitToColumn } from "../../../../../utils/excelCellFitToColumn";
import { getExcelRows } from "./getExcelRows";
import { getExcelHeaders } from "./getExcelHeaders";
import { exportExcel } from "../../../../../utils/exportExcel";
import { responsesHeaders, automationHeaders } from "./tableHeaders";
import { automationRecurrence } from "../../../../pages/Settings/settingsComponents/Automation/AllAutomations/utils";

export const onGeneratePDF = (
  action,
  additionalData,
  getDocDefinition = false,
  headersData
) => {
  const { data, status, base64 } = additionalData;

  const header =
    status === "Active Automations" ? automationHeaders : responsesHeaders;

  const columnsToInclude = headersData || header.map((item, index) => index);

  const tableData = [];

  if (status === "Active Automations") {
    for (const item of data) {
      const category =
        Object.keys(item?.automationRule)
          .map((key) => key)
          .join(", ") || "";

      tableData.push([
        item.automationName,
        item?.createdBy,
        category,
        item?.automationStatus,
        automationRecurrence(item.automationTime),
      ]);
    }
  } else {
    data.forEach(({ automationName, error, description, responseDate }) => {
      tableData.push([
        automationName,
        error ? "YES" : "NO",
        description,
        moment(responseDate).fromNow(),
      ]);
    });
  }

  const docDefinition = generateDocDefinition(
    base64,
    status,
    columnsToInclude,
    header,
    tableData
  );

  if (getDocDefinition) {
    return docDefinition;
  }

  createPDF({
    action,
    docDefinition,
    title: action === "print" ? false : `${status}.pdf`,
  });
};

export const exportToExcel = (
  additionalData,
  getDocDefinition = false,
  headersData
) => {
  const { data, status } = additionalData;

  if (getDocDefinition)
    return onGeneratePDF("download", additionalData, getDocDefinition);

  const headers = getExcelHeaders(status);
  const rows = getExcelRows(data, status);

  const columnsToInclude = headersData || headers.map((item, index) => index);

  exportExcel(headers, columnsToInclude, rows, status, status);
};

export const uploadExcelToDrive = async (
  additionalData,
  driveRequest,
  driveFolderId
) => {
  const { data, status } = additionalData;

  try {
    const headers = getExcelHeaders(status);
    const rows = getExcelRows(data, status);

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    worksheet["!cols"] = excelCellFitToColumn([headers, ...rows]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to an Excel binary array
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the array buffer to a blob with the correct MIME type
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Upload the Excel file to Google Drive
    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name: `${status}.xlsx`,
        parents: [driveFolderId],
      }
    );

    // Extract and log information about the uploaded file
    const { id, newName } = await uploadResponse.json();

    // Update state or perform any necessary actions with the file ID
    return { id, name: newName };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};
