import { useMediaQuery } from "react-responsive";

import { useMemo } from "react";
import { InputComponent } from "../../Fleet/components";
import { Stepper } from "../../../commonComponents";

function IncidentStatus({
  form,
  currentStep = 0,
  isDarkMode = false,
  incidentStatus = "",
  statusesColors = [],
  setChanges = () => {},
  setCurrentStep = () => {},
  disabledAllFields: disabled = false,
}) {
  const isMobileView = useMediaQuery({
    query: "(max-width: 575px)",
  });

  const steps = useMemo(() => {
    return statusesColors
      ?.map((el) => ({ title: el?.statusName }))
      ?.filter((el) => {
        if (incidentStatus !== "Proceeded to Safety") {
          return el?.title !== "Proceeded to Safety";
        } else {
          return el;
        }
      });
  }, [statusesColors, incidentStatus]);

  if (!!isMobileView) {
    return (
      <div className="stepper-section-mobile">
        <InputComponent
          {...{
            label: "Status",
            type: "select",
            formItemName: "stepper",
            dropdownClassName: isDarkMode && "darkDropDown",
            customOptions: statusesColors?.map(({ statusId, statusName }) => ({
              value: statusId,
              label: statusName,
            })),
            onSelect(e) {
              setCurrentStep(e);
              setChanges(true);
            },
            defaultValue: currentStep,
            disabled,
          }}
        />
      </div>
    );
  }

  return (
    <div className="stepper-section">
      <Stepper
        {...{
          setCurrentStep(e) {
            form.setFieldValue("stepper", e);
            setCurrentStep(e);
            setChanges(true);
          },
          currentStep,
          steps,
          statusesColors,
          stepperClassName: "stepper-component",
          stepRenderer: false,
          disabled,
        }}
      />
    </div>
  );
}

export default IncidentStatus;
