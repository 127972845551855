import { Radio } from "antd";
import { getDispatchLocationOptions } from "../../../../Header/forms/RequestFormModal/requestFormModalData";

const claimantEmployeeOptions = ["Core Scaffold Systems Inc", "Subcontractor"];

export const generatePropertyDamageInputFields = ({
  schedules,
  projects,
  subContractorsOptions,
}) => [
  {
    label: "Claim Number",
    onContextMenu: () => false,
    name: "claimNumber",
    type: "text",
    placeholder: "Claim Number...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Claim Type",
    onContextMenu: () => false,
    name: "claimType",
    type: "select",
    placeholder: "Claim Type...",
    className: "inputForm",
    customOptions: [
      { value: "Worker Compensation", label: "Worker Compensation" },
      { value: "Type 2", label: "Type 2" },
    ],
    required: true,
  },
  {
    label: "Claimant",
    onContextMenu: () => false,
    name: "claimant",
    type: "text",
    placeholder: "Claimant...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Claimant IS An Employee Of",
    onContextMenu: () => false,
    name: "claimantEmployee",
    type: "radio",
    className: "inputForm",
    required: true,
    options: (
      <>
        {claimantEmployeeOptions.map((option) => (
          <Radio value={option}>{option}</Radio>
        ))}
      </>
    ),
  },
  {
    label: "Claim Address",
    onContextMenu: () => false,
    name: "claimAddress",
    type: "select",
    placeholder: "Claim Address...",
    className: "inputForm",
    customOptions: getDispatchLocationOptions(schedules, projects).filter(
      Boolean
    ),
    showSearch: true,
    required: true,
  },

  {
    label: "Company Name",
    onContextMenu: () => false,
    name: "companyName",
    type: "select",
    placeholder: "Company Name...",
    className: "inputForm",
    customOptions: subContractorsOptions,
    required: true,
  },
  {
    label: "Core PM At The Time",
    onContextMenu: () => false,
    name: "corePMAtTheTime",
    type: "text",
    placeholder: "Core PM At The Time...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Client At Time Of Accident",
    onContextMenu: () => false,
    name: "clientAtTimeOfAccident",
    type: "text",
    placeholder: "Client At Time Of Accident...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Claim Date",
    onContextMenu: () => false,
    name: "claimDate",
    type: "datepicker",
    placeholder: "Claim Date...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Claim Time",
    onContextMenu: () => false,
    name: "claimTime",
    type: "timepicker",
    placeholder: "Claim Time...",
    className: "inputForm",
    required: true,
  },
];

export const generateInputFields = ({ schedules, projects }) => [
  {
    label: "Claim Number",
    onContextMenu: () => false,
    name: "claimNumber",
    type: "text",
    placeholder: "Claim Number...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Claim Type",
    onContextMenu: () => false,
    name: "claimType",
    type: "select",
    placeholder: "Claim Type...",
    className: "inputForm",
    customOptions: [
      { value: "Worker Compensation", label: "Worker Compensation" },
      { value: "Type 2", label: "Type 2" },
    ],
    required: true,
  },
  {
    label: "Claim Address",
    onContextMenu: () => false,
    name: "claimAddress",
    type: "select",
    placeholder: "Claim Address...",
    className: "inputForm",
    customOptions: getDispatchLocationOptions(schedules, projects).filter(
      Boolean
    ),
    required: true,
    showSearch: true,
  },
  {
    label: "Claim Date",
    onContextMenu: () => false,
    name: "claimDate",
    type: "datepicker",
    placeholder: "Claim Date...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Claim Time",
    onContextMenu: () => false,
    name: "claimTime",
    type: "timepicker",
    placeholder: "Claim Time...",
    className: "inputForm",
    required: true,
  },
];
