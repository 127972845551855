import useStickyElement from "../../../../../../hooks/useStickyElement";
import ControlPanel from "./Components/ControlPanel/ControlPanel";
import MainHeader from "./Components/MainHeader/MainHeader";
import { useStateContext } from "../../Context/Context";
import { autoSaveHandler } from "./headerFunctions";
import "./header.scss";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Alert } from "antd";

const Header = ({
  undoClickHandler,
  redoClickHandler,
  canUndo,
  canRedo,
  closeModal,
}) => {
  // Context state & dispatch
  const { darkMode, isAutoSaving, fakeSOV, isWritable } = useStateContext();
  const [headerRef, fakeHeaderRef] = useStickyElement();
  const dispatch = useDispatch();

  //last modified by changes every time we makes a put on db, for that reason if we put fakeSOV as dependency on uef it will make a look bcs it will always be different from the first one
  const { lastModifiedBy, ...rest } = fakeSOV || {};

  //this handle auto save
  useEffect(() => {
    autoSaveHandler({ fakeSOV, isAutoSaving, isWritable, dispatch });
  }, [JSON.stringify(rest)]);

  return (
    <>
      <div
        ref={headerRef}
        className={
          darkMode
            ? "schedule-of-value-header-dark"
            : "schedule-of-value-header"
        }
      >
        <MainHeader {...{ closeModal }} />
        <ControlPanel
          canUndo={canUndo}
          canRedo={canRedo}
          undoClickHandler={undoClickHandler}
          redoClickHandler={redoClickHandler}
        />
        {/* <div
          style={{
            // position: "absolute",
            // top: "0",
            // right: "0",
            // width: "100%",
            // zIndex: "1",
            padding: "0 22px 0 20px",
          }}
        >
          <Alert
            type="error"
            showIcon
            message={`BE CAREFUL, SINCE YOU HAVE CUSTIMZED SOV DIFFERENT FROM THE CONTRACT ESTIMATE, YOU MUST MANUALLY BALANCE AMOUNTS AND NOT CHANGE OVERALL TOTAL PRICE.`}
          />
          <br />
        </div> */}
      </div>
      <div ref={fakeHeaderRef} />
    </>
  );
};

export default Header;
