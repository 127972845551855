import dayjs from "dayjs";
import { isNumeric } from "../../../../../utils";

const DOB_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm:ss";
const DATE_FORMAT = "MM/DD/YYYY";
const PARSING_FORMAT = "YYYY-MM-DD";

export const formatDobDate = (date) => {
  const parsedDate = String(date);

  return isNumeric(date)
    ? dayjs(parseInt(date)).format(DATE_FORMAT)
    : parsedDate.includes("-")
    ? dayjs(parsedDate, PARSING_FORMAT).format(DATE_FORMAT)
    : parsedDate.includes(":")
    ? dayjs(date, DOB_DATE_TIME_FORMAT).format(DATE_FORMAT)
    : date;
};

export const formatDobDate2 = (date) => dayjs(date).format(DATE_FORMAT);
