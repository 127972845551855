import moment from "moment";

export const today = () => {
  const oneWeekFromNow = moment().add(1, "days").format();
  const today = moment().format();
  const timeObj = { timeMin: today, timeMax: oneWeekFromNow };
  return timeObj;
};

export const thisWeek = () => {
  const startOfWeek = moment().startOf("week").format();
  const endOfWeek = moment().endOf("week").format();
  const timeObj = { timeMin: startOfWeek, timeMax: endOfWeek };
  return timeObj;
};

export const thisMonth = () => {
  const todayMonth = moment().format();
  const endOfMonth = moment().endOf("month").format();
  const timeObj = { timeMin: todayMonth, timeMax: endOfMonth };
  return timeObj;
};

export const nextMonth = () => {
  const nextMonthStart = moment().add(1, "months").startOf("month").format();
  const nextMonthEnd = moment(nextMonthStart).endOf("month").format();
  const timeObj = { timeMin: nextMonthStart, timeMax: nextMonthEnd };
  return timeObj;
};

export const thisYear = () => {
  const todayYear = moment().format();
  const endYear = moment().endOf("year").format();
  const timeObj = { timeMin: todayYear, timeMax: endYear };
  return timeObj;
};

export const timeFilter = (filterType) => {
  // console.log("Filter Type", filterType);
  switch (filterType) {
    case "Today":
      const todayTimeline = today();
      return todayTimeline;
    case "This Week":
      const thisWeekTimeline = thisWeek();
      return thisWeekTimeline;
    case "This Month":
      const thisMonthTimeline = thisMonth();
      return thisMonthTimeline;
    case "Next Month":
      const nextMonthTimeline = nextMonth();
      return nextMonthTimeline;
    case "This Year":
      const thisYearTimeline = thisYear();
      return thisYearTimeline;
    case "All":
      return false;
    default:
      // const defaultTimeline = thisWeek();
      // return defaultTimeline;
      return { timeMin: false, timeMax: false };
  }
};
