import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useMediaQuery } from "react-responsive";

import { transformDates } from "src/components/SidebarPages/Accounting/utilities/convertUnixTime";

export default function TableList({ rowData, showFilters }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const formattedData = rowData?.map((item) => ({
    ...transformDates(item),
  }));

  const isMobile = useMediaQuery({ maxWidth: 649 });
  const isTablet = useMediaQuery({ minWidth: 650 });
  const isDesktop = useMediaQuery({ minWidth: 960 });
  const isLargeScreen = useMediaQuery({ minWidth: 1890 });

  const defaultColDef = {
    resizable: true,
    enablePivot: true,
    enableColResize: true,
    enableRowGroup: true,
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
  };

  const columnDefs = [
    {
      headerName: "Date",
      field: "inspectionDate",
      sortable: true,
      comparator: (valueA, valueB) => {
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
        if (dateA === dateB) return 0;
        else if (dateA > dateB) return 1;
        return -1;
      },
    },
    { headerName: "Inspected By", field: "inspectedBy" },
    { headerName: "Status", field: "inspectionStatus" },
    { headerName: "Type", field: "inspectionType" },
    { headerName: "Jobsite", field: "projectName" },
    { headerName: "P. Executive", field: "projectExecutive" },
  ];

  return (
    <div
      className={`documentationsTable ${
        isDarkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
      style={{
        height: "calc(100vh - 160px)",
        ...(isMobile && showFilters && { height: "calc(100vh - 700px)" }),
        ...(isTablet && showFilters && { height: "calc(100vh - 434px)" }),
        ...(isDesktop && showFilters && { height: "calc(100vh - 346px)" }),
        ...(isLargeScreen && showFilters && { height: "calc(100vh - 258px)" }),
      }}
    >
      <AgGridReact
        rowData={formattedData}
        animateRows
        rowGroupPanelShow="always"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        suppressDragLeaveHidesColumns
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
            },
            {
              id: "filters",
              labelDefault: "Filters",
              labelKey: "filters",
              iconKey: "filter",
              toolPanel: "agFiltersToolPanel",
            },
          ],
        }}
      />
    </div>
  );
}
