import { useState } from "react";
import { replaceArrayElement } from "../../../../../../utils";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { useRedux } from "../../../../hooks/useRedux";
import { useTakeOffContext } from "../../context";
import { JumpsList, RemainingFloorList } from "./components";
import { JumpsModalContext } from "./context";
import "./JumpsModalContent.scss";

const JumpsModalContent = ({
  elevation: proppedElevation = {},
  indices,
  onCancel,
}) => {
  const { updateStateAndSave } = useTakeOffContext();
  const { serviceIndex, optionIndex, elevationIndex } = indices;
  const [gridData] = useRedux("takeOffTableData");
  const [elevation, setElevation] = useState(
    gridData?.[serviceIndex]?.serviceOptions?.[optionIndex]?.[elevationIndex]
  );
  console.log("proppedElevation", proppedElevation);

  const { items = [], jumps = [] } = elevation;

  const includedFloors = jumps.flatMap(({ floors }) => {
    return floors.map(({ floor }) => floor);
  });

  const remainingFloors = items.filter(
    ({ floor }) => !includedFloors.includes(floor)
  );

  const createJump = (selectedFloors = []) => {
    setElevation((prev) => ({
      ...prev,
      jumps: [
        ...jumps,
        {
          name: `Jump ${jumps.length + 1}`,
          floors: selectedFloors.map((floor) => ({ floor })),
        },
      ],
    }));
  };

  const saveChanges = () => {
    const { serviceIndex, optionIndex } = indices;
    console.log("indices", indices);

    const service = gridData[serviceIndex];
    const updatedElevation = { ...elevation, nrOfJumps: jumps.length };
    console.log("updatedElevation", updatedElevation);
    const updatedService = {
      ...service,
      serviceOptions: service.serviceOptions.map((option, idx) =>
        idx === optionIndex
          ? replaceArrayElement(option, updatedElevation, "elevationId")
          : option
      ),
    };

    updateStateAndSave(
      replaceArrayElement(gridData, updatedService, "serviceId")
    );
    onCancel();
  };

  return (
    <JumpsModalContext.Provider
      value={{
        elevation,
        setElevation,
        includedFloors,
        remainingFloors,
      }}
    >
      <div className="jumps-modal-content-wrapper">
        <div className="jumps-modal-content">
          <RemainingFloorList onAddFloors={createJump} />
          <JumpsList />
        </div>

        <div className="footer">
          <MondayButton Icon={<TickIcon />} onClick={saveChanges}>
            Save
          </MondayButton>
        </div>
      </div>
    </JumpsModalContext.Provider>
  );
};

export default JumpsModalContent;
