import { axesTooltipCustom } from "../../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "name",
    groupKey: "inspectionName",
    xAxisName: "Name",
  },
  {
    chartName: "category",
    groupKey: "inspectionCategory",
    xAxisName: "Category",
  },
  {
    chartName: "status",
    groupKey: "safetyApplicationStatus",
    xAxisName: "Status",
  },
  {
    chartName: "project",
    groupKey: "projectName",
    xAxisName: "Project Name",
  },
  {
    chartName: "cilent",
    groupKey: "accountName",
    xAxisName: "Client Name",
  },
  {
    chartName: "createdBy",
    groupKey: "createdBy.name",
    xAxisName: "Inspection",
  },
];

export const getChartingOptions = (data) => {
  let name = {};
  let category = {};
  let status = {};
  let project = {};
  let cilent = {};
  let createdBy = {};

  data.forEach((item) => {
    const injuredPerson = item?.inspectionName || "No Data";
    const vehicleDriver = item?.inspectionCategory || "No Data";
    const injuredStatus = item?.safetyApplicationStatus || "No Data";
    const chargetype = item?.projectName || "No Data";
    const accName = item?.accountName.replace(".", "") || "No Data";
    const user = item?.createdBy?.name || "No Data";

    name.hasOwnProperty(injuredPerson)
      ? (name[injuredPerson] += 1)
      : (name[injuredPerson] = 1);

    category.hasOwnProperty(vehicleDriver)
      ? (category[vehicleDriver] += 1)
      : (category[vehicleDriver] = 1);

    status.hasOwnProperty(injuredStatus)
      ? (status[injuredStatus] += 1)
      : (status[injuredStatus] = 1);

    project.hasOwnProperty(chargetype)
      ? (project[chargetype] += 1)
      : (project[chargetype] = 1);

    cilent.hasOwnProperty(accName)
      ? (cilent[accName] += 1)
      : (cilent[accName] = 1);

    createdBy.hasOwnProperty(user)
      ? (createdBy[user] += 1)
      : (createdBy[user] = 1);
  });

  const countOnName = {
    title: {
      text: "Total Inspection Count",
    },
    subtitle: {
      text: "Based on Name",
    },
    series:
      Object?.keys(name).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Projects"),
          },
        };
      }) || [],
  };

  const countOnCategory = {
    title: {
      text: "Total Inspection Count",
    },
    subtitle: {
      text: "Based on Category",
    },
    series:
      Object?.keys(category).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspection"),
          },
        };
      }) || [],
  };

  const countOnStatus = {
    title: {
      text: "Total Inspection Count",
    },
    subtitle: {
      text: "Based on Status",
    },
    series:
      Object?.keys(status).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspection"),
          },
        };
      }) || [],
  };

  const countOnProject = {
    title: {
      text: "Total Inspection Count",
    },
    subtitle: {
      text: "Based on Project",
    },
    series:
      Object?.keys(project).map((type) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: type,
          yName: type,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspection"),
          },
        };
      }) || [],
  };

  const countOnCilent = {
    title: {
      text: "Total Inspection Count",
    },
    subtitle: {
      text: "Based on Client Name",
    },
    series:
      Object?.keys(cilent).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item.replace(".", ""),
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspection"),
          },
        };
      }) || [],
  };

  const countOnCreatedBy = {
    title: {
      text: "Total Inspection Count",
    },
    subtitle: {
      text: "Based on Created By",
    },
    series:
      Object?.keys(createdBy).map((user) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: user,
          yName: user,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspection"),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle: "Show total Inspection count based on Name",
      params: countOnName,
      key: "name",
    },
    {
      optionTitle: "Show total Inspection count based on Category",
      params: countOnCategory,
      key: "category",
    },
    {
      optionTitle: "Show total Inspection count based on Status",
      params: countOnStatus,
      key: "status",
    },
    {
      optionTitle: "Show total Inspection count based on Project",
      params: countOnProject,
      key: "project",
    },
    {
      optionTitle: "Show total Inspection count based on Client Name",
      params: countOnCilent,
      key: "cilent",
    },
    {
      optionTitle: "Show total Inspection count based on Created By",
      params: countOnCreatedBy,
      key: "createdBy",
    },
  ];

  return chartingOptions;
};
