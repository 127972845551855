import { useEffect, useState } from "react";
import { useLocalStorageLock } from "../../../../../../../hooks/useLocalStorageLock";
import { filterTables } from "../../../../../../../utils/filterTables";
import { OkWarningModal } from "../../../../../../commonComponents/OkWaningModal/OkWarningModal";
import { useRedux } from "../../../../../../SidebarPages/hooks/useRedux";
import NewChooseChargeFromModal from "../../../../../../SidebarPages/Projects/Accounting/Charges/components/ChooseChargeFromModal/NewChooseChargeFromModal";
import { NewChargeItem } from "../../../../../../SidebarPages/Projects/Accounting/Charges/components/index";

const NewChargeModal = ({
  projectData,
  title,
  selectedCat,
  selectedType,
  closeModal = () => {},
  getBodyToSaveInAutomation, //this is a function used in Automations to get body for api, to save it later automatically
}) => {
  const [editCharge, setEditCharge] = useRedux("chargeItemsModal", false, true);
  const [rowData, setRowData] = useRedux("chargesRowData", undefined, false);
  const [selectedData, setSelectedData] = useState(false);
  const [visible, setVisible] = useRedux("newChargeVisible");
  const [charge, setCharge] = useState();
  const { isOpen, disableReopeningTab, setIsOpen, closeOpenedTab } =
    useLocalStorageLock();

  useEffect(() => {
    setVisible({ categoryFrom: selectedCat || title, type: selectedType });

    filterTables("charges", "chargeType", "Regular").then((res) =>
      setRowData(
        res.map((e) => ({
          ...e,
          itemsLength: e?.chargeItems?.length,
          createdAt: new Date(e.createdAt).toLocaleDateString(),
          linkTo: `/charges/${e?.chargeId}`,
          linkToProject: `/projects/${e.projectId}`,
        }))
      )
    );
  }, []);

  const getCreatedCharge = (e) => {
    let rowToAdd = { ...e, itemsLength: e?.chargeItems?.length };
    setRowData([
      ...rowData.filter(({ chargeId }) => chargeId !== rowToAdd.chargeId),
      rowToAdd,
    ]);
  };

  useEffect(() => {
    disableReopeningTab(charge?.chargeId);
  }, [charge?.chargeId, charge]);

  return (
    <>
      {isOpen ? (
        <OkWarningModal
          isOpenedModal={isOpen}
          onButtonClickHandler={() => {
            setIsOpen(false);
            setEditCharge(false);
          }}
          onCloseClickHandler={() => {
            closeOpenedTab(charge?.chargeId);
            setIsOpen(false);
            setEditCharge(false);
          }}
          title="Charge"
        />
      ) : (
        editCharge &&
        selectedData && (
          <NewChargeItem
            project={projectData}
            getCreatedCharge={getCreatedCharge}
            setCurrentCharge={setCharge}
            selectedData={selectedData}
            closeModal={closeModal}
            getBodyToSaveInAutomation={getBodyToSaveInAutomation}
          />
        )
      )}
      {visible && (
        <NewChooseChargeFromModal
          project={projectData}
          setSelectedCategory={setEditCharge}
          charges={rowData}
          setSelectedData={setSelectedData}
          updateWhereToFind={(e) => {
            e &&
              setEditCharge({
                ...editCharge,
                whereToFind: e,
              });
          }}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default NewChargeModal;
