import { Tag } from "antd";
import { memo } from "react";
import { ReportIcon } from "../../../../../icons";
import { StarFilled } from "../../../../commonComponents/StarCheckbox/assets";
import { formatCategoryName } from "../../../../pages/Settings/settingsComponents/DocumentationConfiguration/View/utils";
import { categoriesIcons } from "../../configuration/reportsConfigurationData";
import "./ReportLabel.scss";

const ReportLabel = ({
  report,
  categoryName,
  department,
  history = false,
  onClick,
}) => {
  const CategoryIcon = categoriesIcons[categoryName] || categoriesIcons.default;

  const dividerClassName = `verticalDivider ${
    !report ? "favoriteCategory" : ""
  }`.trim();

  return (
    <div className="favoriteReportLabel" {...{ onClick }}>
      {/* <div className="reportsLabel"> */}
      {!history ? <StarFilled /> : <ReportIcon className="reportIcon" />}
      {!!report && (
        <>
          <div className="reportName">{report.reportName}</div>
          <div className={dividerClassName} />
        </>
      )}
      {/* </div> */}
      <div className="categoryLabel">
        <CategoryIcon className="categoryIcon" />
        <div className="categoryName">{formatCategoryName(categoryName)}</div>
      </div>
      <div className={dividerClassName} />
      <Tag className="departmentTag">{formatCategoryName(department)}</Tag>
    </div>
  );
};

export default memo(ReportLabel);
