import { Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import "./DynamicTab.scss";

const DynamicTab = ({
  activeKey,
  items,
  onChange,
  className = "",
  ...props
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <Tabs
      activeKey={activeKey}
      className={`dynamic-tabs ${className} ${
        isDarkMode && "dynamic-tabs-dark"
      }`}
      onChange={onChange}
      items={items}
      {...props}
    />
  );
};

export default DynamicTab;
