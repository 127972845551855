import { appStore } from "src/store";
import {
  MAP_DEFAULT,
  MAP_DARK,
} from "../../../Scheduling/Tabs/SchedulingMap/schedulingMapData";

export const REFRESH_VALUES = {
  "5 Minutes": 300000,
  "10 Minutes": 600000,
  "15 Minutes": 900000,
};

export const MAP_THEME = {
  dark: MAP_DARK,
  light: MAP_DEFAULT,
};

export const MAP_TYPES = ["roadmap", "hybrid", "terrain"];

export const LIVE_LIBRARIES = ["drawing", "geometry", "places"];

export const LIVE_SESSION_ID =
  process.env.NODE_ENV === "production"
    ? "FLEETS_LIVE_PROD"
    : "FLEETS_LIVE_DEV";

export function YARD_LOCATIONS() {
  return (appStore.getState()?.yards?.yards || []).map((y) => y.position);
}

export { ABBREVIATIONS_RG } from "./streetAbbreviations";

export const flashFrames = {
  dark: [
    { backgroundColor: "#2a2b3a" },
    { backgroundColor: "#2d531e" },
    { backgroundColor: "#2a2b3a" },
    { backgroundColor: "#2d531e" },
    { backgroundColor: "#2a2b3a" },
  ],
  light: [
    { backgroundColor: "#fff" },
    { backgroundColor: "#dafacd" },
    { backgroundColor: "#fff" },
    { backgroundColor: "#dafacd" },
    { backgroundColor: "#fff" },
  ],
};

export const flashOptions = {
  duration: 900,
  delay: 100,
};

export const AUDIT_FIELD_NAME = "Fleet Audit Upload";

export const VENDORS_FIELD_NAME = "Vendors";

export const MARKER_ICON_URL =
  "https://maps.google.com/mapfiles/kml/paddle/red-diamond-lv.png";

export const DIRECTION_MARKER_ICON =
  "https://maps.google.com/mapfiles/kml/paddle/";

export const TIME_TOLERANCE = 600000;

export const LIVE_STATUSES = [
  "Not Departed",
  "En Route",
  "In Location",
  "Late",
  "Early",
  "On Time",
];

export const PRINT_PAGE_CONFIGS = {
  Letter: {
    height: "279mm",
    width: "216mm",
    aspectRatio: "0.8",
    landscapeRatio: "1.3",
  },
  A4: {
    height: "297mm",
    width: "210mm",
    aspectRatio: "0.7",
    landscapeRatio: "1.4",
  },
  Legal: {
    height: "356mm",
    width: "216mm",
    aspectRatio: "0.6",
    landscapeRatio: "1.6",
  },
  Tabloid: {
    height: "432mm",
    width: "279mm",
    aspectRatio: "0.6",
    landscapeRatio: "1.5",
  },
};

export const BASE_REPORT_STYLES = {
  "main-document-body": {
    width: "100%",
    overflow: "auto",
    "min-height": "100%",
  },
  headerSection: {
    width: "100%",
    height: "10vh",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
  baseLogo: {
    height: "100%",
    "aspect-ratio": "2",
  },
  chart: {
    width: "100%",
    "aspect-ratio": "4",
  },
  "table > tbody > tr > td": {
    padding: "5px 5px",
  },
  "table > tbody": {
    width: "100%",
  },
  "table > tbody > tr > td > a": {
    "text-decoration": "none",
    color: "#1065A4",
  },
  "table > tbody > tr > th": {
    padding: "5px 5px",
    "line-height": "1.2rem",
    "background-color": "#f1f1f1",
  },
};

export { default as SIDER_ITEMS } from "./siderItems";
export { default as ACTIVE_VIEW_DATA } from "./mainViews";
export { default as MARKER_ICONS } from "./markerIcons";
export { default as DEFAULT_PREFERENCES } from "./liveLocalPreferences";
export { default as getReportCardOptions } from "./reportLayoutCardOptions";
export { default as getReportCardOptionsWithRemove } from "./reportLayoutCardOptionsWithRemove";
export { default as streetAbbreviations } from "./streetAbbreviations";
