import "./ActiveAutomations.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import {
  ClockCircleOutlined,
  PauseOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Divider, Tag, Tooltip } from "antd";
import {
  automationRecurrence,
  sendAutomationNotification,
  stopAutomation,
} from "../../../../../pages/Settings/settingsComponents/Automation/AllAutomations/utils";
import { RedWarningModal } from "../../../../../commonComponents";
import AutomationResponses from "../AutomationResponses/AutomationResponses";
import ActiveEventsAutomations from "./ActiveEventsAutomations";
import { formatAutomationResponses } from "../../utils";
import { findFieldOptionByNameOrId } from "../../../../../../utils";

const ActiveAutomations = ({ responses, setAllAutomations }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const postEventsAutomations = findFieldOptionByNameOrId({
    fieldName: "Post Events Automations Configurations",
  })?.filter(({ shouldPostExecute }) => shouldPostExecute);
  const [warningModal, setWarningModal] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [clickedIndex, setClickedIndex] = useState();

  const stopAutomatedJob = (automation) => {
    stopAutomation(automation).then(() => {
      sendAutomationNotification(
        "34",
        "onAutomationStopped",
        automation.automationId,
        automation.automationName,
        userConfiguration
      );

      API.put("automations", `/automations/${automation.automationId}`, {
        body: { automationStatus: "Stopped" },
      }).then(() => {
        setAllAutomations((prev) =>
          prev.map((item) =>
            item.automationId === automation.automationId
              ? { ...item, automationStatus: "Stopped" }
              : item
          )
        );
      });
    });
    setWarningModal(false);
  };

  return (
    <div
      className={`active-automations-container${
        isDarkMode ? " dark-mode" : ""
      }`}
    >
      <div className="active-automations">
        {responses.map((automation) => {
          return (
            <div
              className="active-automation"
              key={automation.automationId}
              onClick={() => {
                setShowLogs(
                  (prev) =>
                    prev?.automationId !== automation.automationId && automation
                );
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div className="icon">
                  <Avatar icon={<ThunderboltOutlined />} />
                </div>
                <div className="description">
                  <div className="name">{automation.automationName}</div>

                  <div className="infos">
                    {automation.automationCategory &&
                      automation.automationCategory.map(({ categoryName }) => (
                        <Tag style={{ textAlign: "center" }}>
                          {categoryName}
                        </Tag>
                      ))}
                    <Tag icon={<ClockCircleOutlined />}>
                      {automationRecurrence(automation.automationTime)}
                    </Tag>
                  </div>
                </div>
              </div>
              <div className="list-item-meta-unglobal">
                <div className="stop">
                  <Tooltip title="Stop this automation">
                    <Button
                      danger
                      icon={<PauseOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setWarningModal(automation);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {postEventsAutomations.length ? (
        <>
          <Divider>Post Events Automations</Divider>
          <ActiveEventsAutomations />
        </>
      ) : (
        <></>
      )}

      {showLogs && (
        <AutomationResponses
          {...{
            title: `Responses for ${showLogs?.automationName}`,
            responses: formatAutomationResponses(showLogs),
            clickedIndex,
            setClickedIndex,
          }}
        />
      )}

      <RedWarningModal
        visible={warningModal}
        onCancel={() => setWarningModal(false)}
        footerProps={{
          onConfirm: () => {
            stopAutomatedJob(warningModal);
          },
        }}
      >
        <p>Do you want to stop this Automation?</p>
      </RedWarningModal>
    </div>
  );
};

export default ActiveAutomations;
