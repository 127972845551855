import { Badge, Button, Input, Modal, Popover, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { get_ProgressDimensionsByServiceId } from "../../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import _, { set } from "lodash";
import moment from "moment-timezone";
import "./PliProgressModal.scss";
import {
  getDayProgress,
  getItemProgress,
} from "../../../../../SidebarPages/Scheduling/helpers/calculators";
import { ProgressStatistics } from "../../../../../SidebarPages/Scheduling/helpers/totals";
import { ItemStatisticsView } from "../../../../../SidebarPages/Scheduling/subComponents/form/ItemStatisticsView";
import { isDayDisabled } from "../../helpers/PliInfoGetters";
import { MondayButton } from "../../../../../commonComponents";
import {
  Tick,
  TickIcon,
} from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { useMediaQuery } from "react-responsive";
import {
  backgroundForProgress,
  checkIfServiceIsOtherScope,
  prevPliCompleted,
  swalToast,
  totalProgressPli,
  upperCaseFirstLetter,
} from "../../SchedulingFirstPage/helperData";
import Swal from "sweetalert2";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { checkIfServiceIsHoist } from "../../../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import { useSelector } from "react-redux";
import { InfoText } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { RetweetOutlined } from "@ant-design/icons";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { DocUploader } from "../../../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
import { fetchProgressChangesForemenFiles } from "../../../../../pages/ScheduleProgress/Components/ForemanProject/ProgressChanges/fetchProgressChangesForemenFiles";
import { WorkedHereIcon } from "../../Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { CrewsIcon } from "../../../../../../assets";
import { ManIcon } from "../../../../components/Notifications/src";

export const PliProgressModal = ({
  selectedTask,
  newArr,
  GlobalCTX,
  formulas,
  data,
  visibleProgress,
  onSaveProgress,
  ganttProgressProp,
  titleProgress,
  setVisibleProgress,
  node,
  elevationIndex,
  serviceId,
  type,
  typeOfWorkObject,
  setItemAfterProgress,
  scheduleDays,
  editMode,
  label,
  elevationLabel,
  proppedType = "",
  typeOfWork,
  service,
  isWritable,
  context,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
  const isMobileView = useMediaQuery({ query: "(max-width: 1224px)" });
  const [filesOfDays, setFilesOfDays] = useState({});

  const { list } = driveApi({
    accessToken,
  });

  const [isModalVisible, set_isModalVisible] = useState(false);

  const [item_state, set_item_state] = useState(data); //set item in state first time
  const [statistics, set_statistics] = useState(
    ProgressStatistics.getItemStatistics(item_state, { d1, d2 })
  );
  useEffect(() => {
    set_item_state(data);
    set_statistics(ProgressStatistics.getItemStatistics(data, { d1, d2 }));
  }, [JSON.stringify(data)]); //when row data has changed

  const { progressByDay = [] } = item_state || {};

  const onCancel = () => {
    set_item_state(data);
    set_statistics(ProgressStatistics.getItemStatistics(data, { d1, d2 }));
    set_isModalVisible(false);
    ganttProgressProp && setVisibleProgress(false);
  };

  const onOk = () => {
    if (!isWritable) {
      message.error("Enable write mode to edit progress!");
      return;
    }
    item_state.totalProgress = totalProgressPli(item_state, serviceId);
    item_state.typeOfProgress = type === "sub_pli" ? "subPli" : "dimensions";
    node.updateData(item_state);
    setItemAfterProgress(
      item_state,
      type,
      { serviceId, elevationIndex },
      d1,
      d2
    );

    set_isModalVisible(false);

    message.success("Progress updated successfully!", 3);
  };

  const onProgressByDayChangeInput = (day, dimension, value) => {
    const item_state_copy = _.cloneDeep(item_state);
    const progressDay = find_progressDay(day, item_state_copy);
    const prevProgressDay = find_progressDay(day, data);
    value = parseInt(value) || 0;
    value = value < 0 ? 0 : value;

    if (progressDay?.["foreman"]) {
      progressDay["foreman"] = {
        ...progressDay?.["foreman"],
        approved:
          progressDay?.["foreman"]?.[dimension] === value ? true : false,
      };
    }

    progressDay["changed"] = prevProgressDay?.[dimension] !== value;

    progressDay[dimension] = value;

    progressDay.progress = getDayProgress(progressDay, {
      d1,
      d2,
    });
    item_state_copy.totalProgress = getItemProgress(item_state_copy, {
      d1,
      d2,
    });

    set_statistics(
      ProgressStatistics.getItemStatistics(item_state_copy, { d1, d2 })
    );
    set_item_state(item_state_copy);
  };

  //utils
  const find_progressDay = (day, item) =>
    item?.progressByDay?.find((pd) => pd?.day === day);
  const hasItemSubPLi = (item) => item?.subPli && item?.subPli?.length > 0;

  const hasSubPLi = hasItemSubPLi(item_state);

  let title = `${label}`;

  const onChangeScaffold = (id, d1, d2, item_state) => {
    const item_state_copy = _.cloneDeep(item_state);
    const progressDay = find_progressDay(id, item_state_copy);
    item_state_copy.progressByDay = item_state_copy?.progressByDay?.map(
      (el, i) => {
        if (el?.day === id) {
          let toReturn = {
            ...el,
            [d1]: item_state?.[d1],
            [d2]: item_state?.[d2],
            changed:
              item_state?.[d1] !== el?.[d1] || item_state?.[d2] !== el?.[d2],
          };
          if (!!el?.foreman) {
            toReturn.foreman = {
              ...el?.foreman,
              approved: true,
            };
          }
          return toReturn;
        } else {
          return {
            ...el,
            [d1]: item_state?.progressByDay?.[i]?.[d1],
            [d2]: item_state?.progressByDay?.[i]?.[d2],
          };
        }
      }
    );

    progressDay.progress = getDayProgress(progressDay, { d1, d2 });
    item_state_copy.totalProgress =
      item_state_copy?.progressByDay?.reduce((acc, el) => acc + el[d1], 0) *
      item_state_copy?.progressByDay?.reduce((acc, el) => acc + el[d2], 0);

    set_statistics(
      ProgressStatistics.getItemStatistics(item_state_copy, { d1, d2 })
    );
    set_item_state(item_state_copy);
  };

  const dimensions = !d2
    ? `${upperCaseFirstLetter(d1)}: ${item_state?.[d1] ?? data?.[d1]}`
    : `${upperCaseFirstLetter(d1)}: ${
        item_state?.[d1] ?? data?.[d1]
      } - ${upperCaseFirstLetter(d2)}: ${item_state?.[d2] ?? data?.[d2]} (S: ${
        item_state?.[d1] * item_state?.[d2] ?? data?.[d1] * data?.[d2]
      })`;

  const tProgressPercentage =
    d2 === undefined
      ? (item_state?.totalProgress / item_state?.[d1]) * 100
      : (item_state?.totalProgress / (item_state?.[d1] * item_state?.[d2])) *
        100;

  const getPrevHistoryProgress =
    node?.data?.progressByDay?.filter((el) =>
      node?.data?.isPliCompletedInThePreviousSchedule?.prevDays?.includes(
        el?.day
      )
    ) || [];

  const sortDays = (progressByDay = []) => {
    let sortedIdList = [];

    for (const scheduleDay of scheduleDays || []) {
      let id = progressByDay?.find((id) => id?.day === scheduleDay?.id);
      if (id) sortedIdList.push(id);
    }
    return sortedIdList;
  };

  const matchForemanProgress = (value, id, dimension) => {
    const item_state_copy = _.cloneDeep(item_state);
    const progressDay = find_progressDay(id, item_state_copy);
    progressDay[dimension] = value;
    progressDay["foreman"] = {
      ...progressDay["foreman"],
      approved: true,
    };
    item_state_copy.totalProgress = totalProgressPli(
      item_state_copy,
      serviceId
    );
    set_item_state(item_state_copy);
  };

  const updateHours = (e, id) => {
    const item_state_copy = _.cloneDeep(item_state);
    const progressDay = find_progressDay(id, item_state_copy);
    progressDay.workingHours = e || 0;
    set_item_state(item_state_copy);
  };

  const hasProgressFromForeman =
    progressByDay?.length > 0
      ? progressByDay
          ?.flatMap((el) => Object.keys(el || {}))
          ?.some((el) => el?.includes("foreman") || el?.includes("files"))
      : false;

  useEffect(() => {
    if (!ganttProgressProp) {
      (async () => {
        let obj = {};
        sortDays(progressByDay).map(async (progressDay) => {
          const id = progressDay?.day;
          const scheduleDay = scheduleDays?.find((d) => d?.id === id);
          const foremanFiles = progressDay?.files || {};
          const folderName = dayjsNY(scheduleDay?.endDate)?.format(
            "MM/DD/YYYY"
          );
          let uploadedFiles = [];
          const folderId = context?.driveIdsByService?.[folderName];
          if (folderId && Object.keys(foremanFiles).length > 0) {
            uploadedFiles = await fetchProgressChangesForemenFiles({
              list,
              all: true,
              folderId,
              foremanFiles,
              userConfiguration,
            });
          }
          obj[id] = uploadedFiles;
        });
        setFilesOfDays(obj);
      })();
    }
  }, []);

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="setProgressButtonWrapper"
    >
      {ganttProgressProp !== true && !node?.group ? (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Button
            className="setProgressButton"
            onClick={() => {
              if (prevPliCompleted(node?.data)) {
                message.warning(
                  "This pli is completed in the previous schedules, you cannot give progress anymore!"
                );
              } else if (
                "editedFrom" in (node?.data || {}) &&
                Object.keys(node?.data?.editedFrom || {})?.length > 0
              ) {
                Swal.fire({
                  text: `This pli is edited in  ${node?.data?.editedFrom?.scheduleName}`,
                  icon: "warning",
                  showConfirmButton: false,
                  timer: 2500,
                });
              } else if (node?.data?.typeOfProgress === "breakdown") {
                message.error("Progress is set by breakdown!", 3);
              } else if (!editMode) {
                swalToast({
                  icon: "error",
                  title: "Available only in edit mode!",
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 4000,
                });
              } else if (node?.data?.subPli?.length) {
                swalToast({
                  icon: "warning",
                  title: "Progress is set by Sub Pli!",
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 4000,
                });
              } else if (!node?.data?.days?.length) {
                swalToast({
                  icon: "error",
                  title: "Please select a day first!",
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 4000,
                });
              } else {
                if (
                  progressByDay?.filter((el) =>
                    scheduleDays?.some((a) => a?.id === el?.day)
                  )?.length === 0
                ) {
                  swalToast({
                    icon: "error",
                    title: "Please select a day first!",
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 4000,
                  });
                } else {
                  set_isModalVisible(true);
                }
              }
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "26px",
              width: "120px",
              color: "#fff",
              backgroundColor: backgroundForProgress(
                Math.round(tProgressPercentage || 0),
                typeOfWorkObject?.label
              ),
              border: "none",
            }}
          >
            {Math.round(tProgressPercentage) || 0}%
          </Button>

          {hasProgressFromForeman && <CrewsIcon />}
        </div>
      ) : (
        ""
      )}
      {(visibleProgress || isModalVisible) && (
        <Modal
          destroyOnClose={true}
          centered
          closable={false}
          footer={
            <div className="progressFooterBtn">
              <MondayButton
                onClick={onCancel}
                {...{ className: "mondayButtonRed", Icon: <XIcon /> }}
              >
                &nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;
              </MondayButton>
              <MondayButton
                onClick={() =>
                  ganttProgressProp ? onSaveProgress(item_state) : onOk()
                }
                {...{ Icon: <TickIcon height={17} width={17} /> }}
              >
                Save Changes
              </MondayButton>
            </div>
          }
          title={
            <div className="progressPliTitle">
              <div className="progressPliTitleText">
                {titleProgress ? `${label} - ${elevationLabel}` : title}
                {!checkIfServiceIsOtherScope(service) && (
                  <span style={{ fontSize: 14 }}>{dimensions}</span>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {<XIcon onClick={onCancel} style={{ cursor: "pointer" }} />}
              </div>
            </div>
          }
          open={visibleProgress ? visibleProgress : isModalVisible}
          okButtonProps={{ disabled: hasSubPLi }}
          cancelButtonProps={{ disabled: hasSubPLi }}
          maskClosable={hasSubPLi}
          className={`pli-progress-modal ${
            isDarkMode && "pli-progress-modal-dark"
          }`}
        >
          <div className="pli-progress-table-container">
            {
              <ItemStatisticsView
                {...{
                  item_state,
                  statistics,
                  d1,
                  d2,
                  onlyPercentage: serviceId == 3 ? true : false,
                  typeOfWorkObject,
                  newArr,
                  selectedTask,
                  GlobalCTX,
                  formulas,
                  ganttProgressProp,
                  typeOfWorkType: typeOfWork,
                }}
              />
            }
            <div className={"pli-progress-table"}>
              <div
                className={"breakdowns-table-body"}
                style={{
                  height: "fit-content",
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                {ganttProgressProp
                  ? progressByDay?.map((progressDay, i) => {
                      // console.log(progressDay)
                      const id = progressDay?.day;
                      const scheduleDay = scheduleDays?.find(
                        (d) => d?.id === id
                      );
                      if (!scheduleDay) return "";
                      if (id !== proppedType) return "";
                      const inactiveDay = isDayDisabled(id, scheduleDays);
                      const dayColor = scheduleDay.color;
                      return (
                        <div className="breakdownRow" key={`${i}${id}`}>
                          <span
                            style={{ color: dayColor, fontWeight: 600 }}
                            className={"day-cell"}
                          >
                            DAY{" "}
                            {scheduleDays.find((sch) => sch?.id === id)?.day}
                          </span>
                          <div className={"progress-fields-container"}>
                            {hasSubPLi ? (
                              "Progress is set by sub pli-s"
                            ) : (
                              <>
                                {[d1, d2]?.map((d, i) =>
                                  d ? (
                                    <div key={i} className="progressField">
                                      <b className="d1DimensionInputField">
                                        {d?.charAt(0)?.toUpperCase() +
                                          d?.slice(1)}
                                      </b>
                                      <Input
                                        // className="pliInputLength"
                                        type="number"
                                        disabled={inactiveDay}
                                        value={progressDay[d]}
                                        onChange={(e) =>
                                          onProgressByDayChangeInput(
                                            id,
                                            d,
                                            e.target.value
                                          )
                                        }
                                        suffix={
                                          checkIfServiceIsOtherScope(service) ||
                                          checkIfServiceIsHoist(service)
                                            ? "%"
                                            : "ft"
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              </>
                            )}
                            {d2 === undefined && !hasSubPLi ? (
                              <div className="inputFieldBtn">
                                <MondayButton
                                  style={
                                    isMobileView
                                      ? { margin: "0px" }
                                      : { margin: "0 13px 0 0" }
                                  }
                                  {...{
                                    Icon: <Tick width={17} height={17} />,
                                  }}
                                  onClick={(e) =>
                                    onProgressByDayChangeInput(
                                      id,
                                      d1,
                                      item_state[d1]
                                    )
                                  }
                                >
                                  Complete
                                </MondayButton>
                                <MondayButton
                                  {...{
                                    className: "mondayButtonRed",
                                    Icon: <XIcon />,
                                  }}
                                  onClick={(e) =>
                                    onProgressByDayChangeInput(id, d1, 0)
                                  }
                                >
                                  &nbsp;&nbsp;&nbsp;Reset&nbsp;&nbsp;&nbsp;
                                </MondayButton>
                              </div>
                            ) : null}
                          </div>
                          {d2 || hasSubPLi ? (
                            <div className="totalInputField">
                              <b>Total:</b>
                              <Input
                                className="totalProgressDisplay"
                                style={{ marginTop: "0px" }}
                                type="input"
                                value={progressDay?.progress}
                              ></Input>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                  : sortDays(progressByDay)?.map((progressDay, i) => {
                      const id = progressDay?.day;
                      const scheduleDay = scheduleDays?.find(
                        (d) => d?.id === id
                      );
                      if (!scheduleDay) return "";
                      const inactiveDay = isDayDisabled(id, scheduleDays);
                      const dayColor = scheduleDay?.color;

                      return (
                        <div
                          className="breakdownRow with-uploads"
                          key={`${id}${i}`}
                        >
                          <div className="breakdownRow-inputs">
                            <Tooltip
                              title={dayjsNY(
                                scheduleDays?.find((sch) => sch?.id === id)
                                  ?.startDate
                              ).format("MM/DD/YYYY")}
                            >
                              <span
                                style={{ color: dayColor, fontWeight: 600 }}
                                className={"day-cell"}
                              >
                                DAY{" "}
                                {
                                  scheduleDays?.find((sch) => sch?.id === id)
                                    ?.day
                                }{" "}
                              </span>
                            </Tooltip>

                            <div className={"progress-fields-container"}>
                              {hasSubPLi ? (
                                "Progress is set by sub pli-s"
                              ) : (
                                <>
                                  {[d1, d2].map((d, i) => {
                                    const foreman = progressDay["foreman"];
                                    return d ? (
                                      <div key={i} className="progressField">
                                        <b className="d1DimensionInputField">
                                          {d?.charAt(0)?.toUpperCase() +
                                            d?.slice(1)}
                                          {foreman && (
                                            <>
                                              <Popover
                                                // title="Foreman changes"
                                                trigger={"click"}
                                                content={
                                                  <div>
                                                    Progress by{" "}
                                                    {foreman?.nameOfForeman}:{" "}
                                                    <b
                                                      onClick={() =>
                                                        matchForemanProgress(
                                                          foreman?.[d],
                                                          id,
                                                          d
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {foreman?.[d]}
                                                    </b>
                                                  </div>
                                                }
                                              >
                                                <span
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {<CrewsIcon />}
                                                </span>
                                              </Popover>
                                            </>
                                          )}
                                        </b>
                                        <Input
                                          // className="pliInputLength"
                                          type="number"
                                          disabled={inactiveDay}
                                          value={progressDay[d]}
                                          onChange={(e) =>
                                            onProgressByDayChangeInput(
                                              id,
                                              d,
                                              e.target.value
                                            )
                                          }
                                          suffix={
                                            checkIfServiceIsHoist(service) ||
                                            checkIfServiceIsOtherScope(service)
                                              ? "%"
                                              : "ft"
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </>
                              )}
                              {d2 === undefined && !hasSubPLi ? (
                                <>
                                  <div key={i} className="progressField">
                                    <b className="d1DimensionInputField">
                                      Working Hours
                                    </b>
                                    <Input
                                      className="pliInputLength"
                                      type="number"
                                      value={progressDay?.workingHours}
                                      onChange={(e) =>
                                        updateHours(
                                          Number(e.target.value),
                                          progressDay?.day
                                        )
                                      }
                                      suffix={"h"}
                                    />
                                  </div>

                                  <div className="inputFieldBtn">
                                    <MondayButton
                                      style={
                                        isMobileView
                                          ? { margin: "0px" }
                                          : { margin: "0 px" }
                                      }
                                      {...{
                                        Icon: <Tick width={17} height={17} />,
                                      }}
                                      onClick={(e) =>
                                        onProgressByDayChangeInput(
                                          id,
                                          d1,
                                          item_state[d1] -
                                            item_state?.totalProgress
                                        )
                                      }
                                    >
                                      Complete
                                    </MondayButton>
                                    {/* <MondayButton
                                  {...{
                                    className: "mondayButtonRed",
                                    Icon: <XIcon />,
                                  }}
                                  onClick={(e) =>
                                    onProgressByDayChangeInput(
                                      id,
                                      d1,
                                      d2 === undefined
                                        ? item_state[d1] -
                                            item_state?.totalProgress
                                        : item_state[d1] * item_state[d2] -
                                            item_state?.totalProgress
                                    )
                                  }
                                >
                                  &nbsp;&nbsp;&nbsp;Reset&nbsp;&nbsp;&nbsp;
                                </MondayButton> */}
                                  </div>
                                </>
                              ) : null}
                            </div>

                            {d2 || hasSubPLi ? (
                              <div className="totalInputField">
                                {d2 !== undefined && (
                                  <>
                                    <div key={i} className="progressField">
                                      <b className="d1DimensionInputField">
                                        Working Hours
                                      </b>
                                      <Input
                                        className="pliInputLength"
                                        type="number"
                                        value={progressDay?.workingHours}
                                        onChange={(e) =>
                                          updateHours(
                                            Number(e.target.value),
                                            progressDay?.day
                                          )
                                        }
                                        suffix={"h"}
                                      />
                                    </div>
                                    <MondayButton
                                      onClick={() =>
                                        onChangeScaffold(id, d1, d2, item_state)
                                      }
                                      {...{
                                        className: "mondayButtonBlue",
                                        Icon: (
                                          <TickIcon width={17} height={17} />
                                        ),
                                      }}
                                    >
                                      Complete Item
                                    </MondayButton>
                                  </>
                                  // <Button

                                  // >
                                  //   Complete Item
                                  // </Button>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {filesOfDays?.[id] &&
                            filesOfDays?.[id]?.length > 0 && (
                              <div className="progress-uploader-section">
                                Images uploaded by foremen
                                <DocUploader
                                  {...{
                                    uploadedFiles: filesOfDays?.[id],
                                    onlyUploadedFiles: true,
                                    responsiveWidth: 5000,
                                    disabled: true,
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </span>
  );
};

/**
 * @typedef {string} ProgressOriginType
 **/

/**
 * @enum {ProgressOriginType}
 */
export const PROGRESS_ORIGIN = {
  PLI: "pli",
  SUB_PLI: "sub_pli",
  SERVICE_ADDON: "SERVICE_ADDON",
  PLI_ADDON: "PLI_ADDON",
  PLI_BREAKDOWN: "PLI_BREAKDOWN",
  ELEVATION_BREAKDOWN: "ELEVATION_BREAKDOWN",
};
