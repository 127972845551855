import { COST_ROLES } from "./cellFunctions";

export const expenseTypes = [
  {
    type: "Payroll",
    subCategories: ["Direct", "Overhead"],
  },
];

// This function is Temporary and will be remade for full functionality on future dynamisms
export function getExpenseType(data) {
  const category = expenseTypes[0];
  if (
    !!data?.jobsiteMatch?.jobName?.length &&
    COST_ROLES.includes(data?.role)
  ) {
    return `${category?.subCategories[0]} ${category?.type}`;
  } else if (
    // data?.payrollType === "Prevailing Wage" ||
    data?.jobsiteMatch?.payrollType === "Open Shop" ||
    data?.jobsiteMatch?.payrollType === "Certified Payroll"
  ) {
    return `${category?.subCategories[1]} ${category?.type}`;
  } else {
    return `${category?.subCategories[1]} ${category?.type}`;
  }
}
