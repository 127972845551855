import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { message, Segmented, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { GridApi, ColumnApi, PostSortRowsParams } from "ag-grid-community";
import { useState, useContext, useMemo, useCallback, useEffect } from "react";

import {
  gridFilters,
  entriesApiCrud,
  entryColumnDefs,
  shiftColumnDefs,
  shiftGridFilters,
} from "../utils";
import { useResponsive } from "src/hooks";
import EntryModal from "../modals/EntryModal";
import PayrollLiveContext from "../PayrollLiveContext";
import { EntryType, ShiftEntry } from "../payrollLiveTypes";
import PayrollLayout from "../../Payroll/Layout/PayrollLayout";
import {
  StoreType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton, WarningModal } from "src/components/commonComponents";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
// import { AddIcon } from "src/components/Header/components/GoogleEvents/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { gridCustomOverlayLoading } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils";
// import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { CrewsHeader } from "src/components/pages/Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";

import "./LiveDegPayroll.scss";
import "../../Payroll/Payroll.scss";

type NewEmpModalType = {
  employeeId?: string;
  accountName?: string;
  employeeNumber?: string;
  employeeFullName?: string;
};

const defaultColumnDefinition = {
  flex: 1,
  filter: true,
  sortable: true,
  editable: false,
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
  enableColResize: true,
  groupHeaderHeight: 300,
};

const segmentOptions = [
  { label: "Entries", value: "entryId" },
  { label: "Shifts", value: "shiftId" },
];
function LiveDegPayroll() {
  const {
    jobsites,
    crewTeams,
    degEntries,
    shiftEntries,
    setDegEntries,
    clientConfigs,
    clientCompany,
    controlPanelForm,
    // programEmployees,
    setEmployeesReport,
    setProgramEmployees,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const initialTableType =
    (sessionStorage.getItem("tableType") as "entryId" | "shiftId") || "entryId";

  const [columnApi, setColumnApi] = useState<undefined | ColumnApi>();
  const [entryModal, setEntryModal] = useState<boolean | EntryType>(false);
  const [deleteWarning, setDeleteWarning] = useState<EntryType | undefined>();
  const [tableType, setTableType] = useState<"entryId" | "shiftId">(
    initialTableType
  );
  const [gridApi, setGridApi] = useState<
    undefined | GridApi<EntryType | ShiftEntry>
  >();
  const [newEmpModal, setNewEmpModal] = useState<boolean | NewEmpModalType>(
    false
  );

  const { height } = useResponsive();
  // const punchTimeStart = Form.useWatch("punchTimeStart", controlPanelForm);
  // const punchTimeEnd = Form.useWatch("punchTimeEnd", controlPanelForm);

  const onGridReady = useCallback(
    (api: GridApi) => {
      if (api) {
        setGridApi(api);
        api.setGetRowId((param) => param?.data?.[initialTableType]);
        api.setColumnDefs(
          initialTableType === "shiftId" ? shiftsColDefs : entriesColDefs
        );
      }
    },
    [degEntries]
  );

  const getColumnApi = useCallback((colApi: ColumnApi) => {
    setColumnApi(colApi);
    colApi.autoSizeAllColumns();
  }, []);

  const postSortRows = useCallback(function (
    params: PostSortRowsParams<EntryType & { lastFetch: number }>
  ) {
    let rowNodes = params?.nodes;
    for (let i = 0; i < rowNodes.length - 1; i++) {
      for (let j = 0; j < rowNodes.length - 1 - i; j++) {
        if (
          rowNodes?.[j]?.data?.lastFetch < rowNodes?.[j + 1]?.data?.lastFetch
        ) {
          let temp = rowNodes?.[j];
          rowNodes[j] = rowNodes?.[j + 1];
          rowNodes[j + 1] = temp;
        }
      }
    }
  },
  []);

  function onEditClick(data: EntryType) {
    setEntryModal(data);
  }

  function onSaveCallback(data: EntryType) {
    const selectedJob = jobsites.find(
      (job) => job.jobsiteId === data?.jobsiteId
    );
    // const selectedEmployee = programEmployees.find(
    //   (el) => el.crewId === data?.crewId
    // );

    if (data?.entryId) {
      try {
        const { entryId, createdAt, duration, userId, lastFetch, ...body } =
          data as EntryType & {
            userId: string;
            lastFetch: number;
          };
        entriesApiCrud({ method: "put", body, id: data?.entryId });
        gridApi.applyTransaction({ update: [data] });
        setDegEntries((prev) =>
          prev.map((el) => (el?.entryId === data?.entryId ? data : el))
        );
        setEmployeesReport((prev) =>
          prev.map((el) =>
            Number(el?.employeeNumber) ===
            Number(data?.employeeId?.replace("GMNY Construction-", "").trim())
              ? { ...el, projectId: selectedJob?.projectId }
              : el
          )
        );
        message.success({
          id: "entryCrud",
          content: "Entry edited successfully",
          duration: 2,
        });
      } catch (error) {
        message.error({
          id: "entryCrud",
          content: "There was a problem editing this entry",
          duration: 2,
        });
        console.log("Error editing entry: ", error);
      }
    } else {
      try {
        Object.assign(data, { entryId: uuid() });
        entriesApiCrud({ method: "post", body: [data] });
        gridApi.applyTransaction({ add: [data], addIndex: 0 });
        setDegEntries((prev) => [data].concat(prev));
        // const reportBody = {
        //   employeeName: data?.employeeFullName,
        //   employeeNumber:
        //     selectedEmployee?.employeeNumber ||
        //     data?.employeeId?.replace("GMNY Construction-", "").trim(),
        //   entryType: "Manually Created",
        //   fingerCheckId: selectedEmployee?.fingerCheckId,
        //   lastFetch: undefined,
        //   liveStatus: data?.punchType === "ID" ? "In" : "Out",
        //   projectId: selectedJob?.projectId,
        //   punchCoordinates: data?.punchCoordinates,
        //   punchId: uuid(),
        //   punchLocation: data?.punchLocation,
        //   punchTime: data?.punchTime.toString(),
        // };
        // setEmployeesReport((prev) => prev.concat(reportBody));
        message.success({
          id: "entryCrud",
          content: "Entry created successfully",
          duration: 2,
        });
      } catch (error) {
        console.log("Error creating entry: ", error);
        message.error({
          id: "entryCrud",
          content: "There was a problem creating this entry",
          duration: 2,
        });
      }
    }
  }

  function onDelete(data: EntryType, api?: GridApi<EntryType>) {
    const tableApi = api || gridApi;
    try {
      entriesApiCrud({
        method: "delete",
        id: data?.entryId,
        body: [data?.entryId],
      });
      tableApi.applyTransaction({ remove: [data] });
      setDegEntries((prev) =>
        prev.filter((el) => el.entryId !== data?.entryId)
      );
      setEmployeesReport((prev) =>
        prev.filter(
          (el) =>
            dayjsNY(el.punchTime).valueOf() !== data?.punchTimeStamp &&
            Number(el.employeeNumber) !==
              Number(data?.employeeId.replace("GMNY Construction-", ""))
        )
      );
      message.success({
        id: "entryCrud",
        content: "Entry deleted successfully",
        duration: 2,
      });
    } catch (error) {
      console.log("Error deleting Entry: ", error);
      message.error({
        id: "entryCrud",
        content: "There was a problem deleting this entry",
        duration: 2,
      });
    }
  }

  function onFlashRows() {
    let rowNodes = [];
    gridApi.forEachNode((node) => {
      rowNodes.push(node);
    });
    gridApi.flashCells({
      rowNodes,
    });
  }

  function onRefreshTable() {
    const selectedClient = clientConfigs.find(
      (el) => el.configId === controlPanelForm.getFieldValue("clientCompany")
    );
    const appliedFilter = [
      {
        conditions: [
          {
            id: uuid(),
            formula: "is",
            operator: "AND",
            column: "company",
            dataType: "string",
            columnType: "string",
            value: selectedClient?.clientName,
          },
        ],
        id: uuid(),
        operator: "AND",
      },
    ];
    const queryStringParameters = {
      getMaxLimit: "true",
      withPagination: "true",
      ExclusiveStartKey: undefined,
      filters: JSON.stringify(appliedFilter),
    };
    entriesApiCrud({ method: "get", queryStringParameters })
      .then((res) => {
        setDegEntries(res.employeeReports);
        if (tableType === "entryId") {
          gridApi.setRowData(res.employeeReports);
        }
        onFlashRows();
      })
      .catch((err) => {
        console.log("Error refreshing table: ", err);
      });
  }

  function onNewEmployeeCreation(newEmp: EmployeeType) {
    setProgramEmployees((prev) => prev.concat(newEmp));
  }

  function toggleEntries() {
    if (tableType === "entryId") {
      gridApi.setRowData([]);
      gridApi.setGetRowId((param) => param?.data?.shiftId);
      gridApi.setRowData(shiftEntries);
      gridApi.setColumnDefs(shiftsColDefs);
      setTableType("shiftId");
      sessionStorage.setItem("tableType", "shiftId");
      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);
    } else {
      gridApi.setRowData([]);
      gridApi.setGetRowId((param) => param?.data?.entryId);
      gridApi.setRowData(degEntries);
      gridApi.setColumnDefs(entriesColDefs);
      setTableType("entryId");
      sessionStorage.setItem("tableType", "entryId");
      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);
    }
  }

  const paginationPageSize = useMemo(() => {
    return Math.floor((height - 300) / 38);
  }, [height]);

  const entriesColDefs = useMemo(() => {
    return entryColumnDefs({
      onEditClick,
      onDelete: setDeleteWarning,
      setNewEmpModal,
    });
  }, [gridApi, tableType]);

  const shiftsColDefs = useMemo(() => {
    return shiftColumnDefs();
  }, [gridApi, tableType]);

  // useEffect(() => {
  //   if (gridApi) {
  //     const filterInstance = gridApi.getFilterInstance("punchTimeStamp");
  //     filterInstance.setModel({
  //       type: "inRange",
  //       filterType: "number",
  //       filter: punchTimeStart
  //         ? parseInTz(punchTimeStart).startOf("m")?.valueOf()
  //         : 0,
  //       filterTo: punchTimeEnd
  //         ? parseInTz(punchTimeEnd).startOf("m")?.valueOf()
  //         : Infinity,
  //     });
  //     gridApi.onFilterChanged();
  //   }
  // }, [punchTimeStart, punchTimeEnd, gridApi]);

  useEffect(() => {
    if (gridApi && columnApi) {
      const storageTableType = sessionStorage.getItem("tableType");
      const selectedCompany = (clientConfigs || [])?.find(
        (el) => el?.configId === clientCompany
      );

      const rowNode1 = gridApi.getDisplayedRowAtIndex(0);
      const momentCompany = rowNode1?.data?.companyName;

      if (
        momentCompany !== selectedCompany?.clientName &&
        (selectedCompany?.clientName === degEntries?.[0]?.companyName ||
          selectedCompany?.clientName === shiftEntries?.[0]?.companyName)
      ) {
        gridApi.setRowData(
          storageTableType === "shiftId" ? shiftEntries : degEntries
        );
        setTimeout(() => {
          columnApi.autoSizeAllColumns(false);
          onFlashRows();
        }, 100);
      }
    }
  }, [clientCompany, gridApi, columnApi, degEntries, shiftEntries]);

  useEffect(() => {
    const rowLength = gridApi?.getDisplayedRowCount?.() || 0;
    if (!!gridApi && !!columnApi && degEntries?.length && !rowLength) {
      const storageTableType = sessionStorage.getItem("tableType");
      gridApi.setRowData(
        storageTableType === "shiftId" ? shiftEntries : degEntries
      );
      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);
    }
  }, [gridApi, columnApi, degEntries, shiftEntries]);

  return (
    <section
      className={`live-deg-payroll main-payroll-view ${
        darkMode ? "main-payroll-view-dark live-deg-payroll-dark" : ""
      }`}
    >
      <PayrollLayout
        hasNew={false}
        title="Live DEG"
        suppressDoubleClickEdit
        getGridApi={onGridReady}
        getColumnApi={getColumnApi}
        postSortRows={postSortRows}
        paginationPageSize={paginationPageSize}
        context={{ crewTeams, degEntries, darkMode }}
        defaultColumnDefinition={defaultColumnDefinition}
        overlayLoadingTemplate={gridCustomOverlayLoading()}
        gridFilters={tableType === "entryId" ? gridFilters : shiftGridFilters}
        onColumnRowGroupChanged={(e: any) =>
          e?.columnApi?.autoSizeAllColumns(false)
        }
        customActionButtons={[
          <Segmented
            value={tableType}
            onChange={toggleEntries}
            options={segmentOptions}
            className="table-type-segment"
            data-testid="table-type-segment"
          />,
          <Tooltip title="Refresh table">
            <MondayButton
              hasIcon={false}
              onClick={onRefreshTable}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonWhite"}
            >
              <ReloadOutlined />
            </MondayButton>
          </Tooltip>,
          // <MondayButton
          //   Icon={<AddIcon />}
          //   className="new-entry-btn"
          //   disabled={tableType !== "entryId"}
          //   onClick={() => setEntryModal(true)}
          // >
          //   Create New
          // </MondayButton>,
        ]}
      />
      {entryModal ? (
        <EntryModal
          open={!!entryModal}
          onDeleteCallback={onDelete}
          onSaveCallback={onSaveCallback}
          editData={entryModal as EntryType}
          onCancel={() => setEntryModal(false)}
        />
      ) : null}
      {!!newEmpModal ? (
        <CrewsHeader
          isNextStep={false}
          visible={!!newEmpModal}
          defaultData={newEmpModal}
          setVisible={setNewEmpModal}
          refreshTable={onNewEmployeeCreation}
        />
      ) : null}
      {!!deleteWarning ? (
        <WarningModal
          visible={!!deleteWarning}
          setVisible={setDeleteWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure to delete this report?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setDeleteWarning(undefined)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() =>
                  onDelete(deleteWarning, gridApi as GridApi<EntryType>)
                }
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      ) : null}
    </section>
  );
}

export default LiveDegPayroll;
