import {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
  useMemo,
  ReactElement,
} from "react";
import { useSelector } from "react-redux";
import { Modal, Form, message, Radio, Tooltip } from "antd";
import { Dayjs } from "dayjs";
import { GoogleMap } from "@react-google-maps/api";

import { Itinerary } from "./ItineraryClass";
import { getYards, getLabelHTML } from "../../utils/routeFields";
import { loadLivePreference } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { MAP_THEME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import { getCoordinatesAndZip } from "src/components/SidebarPages/Fleet/utils";
import { parseInTz } from "../../utils/dateFunctions";
import { VendorType } from "../../../../../../FleetMaintenanceView/types";
import { getNewLabelHTML } from "../../utils/routeFunctions";
import { MondayButton } from "src/components/commonComponents";
import RadioIconLabel from "./RadioIconLabel";
import SpecificScheduleSelectModal from "../SpecificScheduleSelectModal";
import {
  SimplePlacesInput,
  InputComponent,
} from "src/components/SidebarPages/Fleet/components";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { AddIcon } from "src/components/Header/components/GoogleEvents/assets";
import { CommuteIcon } from "src/assets";
import { FleetWhite } from "src/icons";

import "./DispatchItineraryModal.scss";

/** @typedef {{lat: number, lng: number}} LatLng */

/** @typedef {"Yard"|"Schedule"|"Off Project"|"Off Schedule"|"Vendor"} DestinationType */

/**
 * @typedef ItineraryType
 * @property {string} pickUpLocation
 * @property {string} dropOffLocation
 * @property {Dayjs|null} departAt
 * @property {Dayjs|null} arriveBy
 * @property {number|null} routeLength
 * @property {number|null} duration
 * @property {number} index
 * @property {string} formattedDistance
 * @property {string} formattedDuration
 * @property {google.maps.TravelMode} travelMode
 * @property {DestinationType} destinationType
 * @property {string|null} destinationId
 * @property {string|null} destinationName
 * @property {google.maps.DirectionsWaypoint[]} waypoints
 * @property {string|null} overview_polyline
 * @property {LatLng|null} pickUpCoordinates
 * @property {LatLng|null} dropOffCoordinates
 */

/**
 * @param {Object} props
 * @param {boolean} props.open
 * @param {() => any} props.onCancel
 * @param {ItineraryType[]} props.itinerary
 * @param {(itinerary: ItineraryType[]) => any} props.onConfirm
 * @param {string} props.startLocation
 * @param {Dayjs|null} [props.startTime]
 * @param {boolean} props.mapLoaded
 * @param {Dayjs} props.selectedDate
 * @param {boolean} [props.staticLast]
 * @param {string|null} [props.scheduleId]
 * @param {string|null} [props.projectId]
 * @param {string} [props.endLocation]
 * @param {any[]} [props.projects]
 * @param {VendorType[]} [props.vendors]
 * @param {any[]} [props.schedulesForDate]
 * @param {Record<string, string[]>} [props.overlappingSchedules]
 * @returns {JSX.Element}
 */
function DispatchItineraryModal({
  open,
  onCancel,
  itinerary = [],
  onConfirm,
  startLocation,
  startTime,
  mapLoaded,
  selectedDate,
  endLocation,
  staticLast = false,
  scheduleId = null,
  projectId = null,
  projects = [],
  vendors = [],
  schedulesForDate = [],
  overlappingSchedules = {},
}) {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedItinerary, setSelectedItinerary] = useState(
    /** @type {ItineraryType[]} */ ([])
  );
  const [overlapData, setOverlapData] = useState({
    open: false,
    rowData: [],
    index: null,
  });

  /** @type {React.MutableRefObject<google.maps.Map>} */
  const mapRef = useRef(null);

  /** @type {React.MutableRefObject<Itinerary>} */
  const itineraryRef = useRef(null);

  const locationMarker = useRef(new google.maps.Marker());

  const [form] = Form.useForm();

  const handleForm = useCallback(
    /**
     * @param {ItineraryType|Array<ItineraryType>} param
     */
    (param) => {
      //#region HANDLE FORM
      if (Array.isArray(param)) {
        for (const step of param) {
          form.setFieldsValue({
            [`pickUpLocation-${step.index}`]: step.pickUpLocation,
            [`dropOffLocation-${step.index}`]: step.dropOffLocation,
            [`departAt-${step.index}`]: step.departAt,
            [`arriveBy-${step.index}`]: step.arriveBy,
          });
        }
      } else {
        form.setFieldsValue({
          [`pickUpLocation-${param.index}`]: param.pickUpLocation,
          [`dropOffLocation-${param.index}`]: param.dropOffLocation,
          [`departAt-${param.index}`]: param.departAt,
          [`arriveBy-${param.index}`]: param.arriveBy,
        });
      }
    },
    []
  );

  const onItineraryChange = useCallback(
    /**
     * @param {ItineraryType|Array<ItineraryType>} param
     */
    (param) => {
      //#region ON ITINERARY CHANGE
      if (Array.isArray(param)) {
        handleForm(param);
        setSelectedItinerary(param);
      } else {
        handleForm(param);
        setSelectedItinerary((prev) => {
          const tmp = [...prev];
          tmp.splice(param.index, 1, param);
          return tmp;
        });
      }

      setTimeout(() => {
        itineraryRef.current.validateItinerary();
      }, 0);

      setTimeout(() => {
        form.validateFields(createFormNames("TIMES"));
      }, 0);
    },
    []
  );

  const dropOffOptions = useMemo(() => {
    //#region DROP OFF OPTIONS
    return [
      {
        label: "Yards",
        options: getYards().map((e) => ({
          label: e,
          value: e,
        })),
      },
      schedulesForDate.length
        ? {
            label: "Schedules",
            options: Object.values(
              schedulesForDate.reduce(
                (acc, val) => ({
                  ...acc,
                  ...(acc[val?.scheduleAddress]
                    ? {}
                    : {
                        [val.scheduleAddress]: {
                          label: val.scheduleAddress,
                          value: val.scheduleAddress + " ",
                          key: val.scheduleAddress + Math.random(),
                        },
                      }),
                }),
                {}
              )
            ),
          }
        : null,
      {
        label: "Vendors",
        options: vendors.map(({ vendorAddress, vendorName, vendorId }) => {
          return {
            label: (
              <div style={{ width: "100%" }} title={vendorAddress}>
                {vendorName}
              </div>
            ),
            value: vendorAddress,
            key: vendorId,
          };
        }),
      },
      {
        label: "Projects",
        options: projects.map(
          ({ projectName, projectLatitude, projectLongitude, projectId }) => ({
            label: projectName,
            position: projectLatitude &&
              projectLongitude && {
                lat: projectLatitude,
                lng: projectLongitude,
              },
            value: projectName,
            key: projectId,
          })
        ),
      },
    ].filter(Boolean);
  }, [projects, vendors, schedulesForDate]);

  useEffect(() => {
    //#region INIT EFFECT
    const itineraryObject = new Itinerary(
      itinerary?.length
        ? itinerary
        : { startAddress: startLocation, startTime, endAddress: endLocation },
      staticLast
    );

    if (endLocation && !itinerary.length) {
      //if we have an end location, we can assign the drop off data
      const data = {};
      let change = false;

      if (scheduleId) {
        const relatedSchedule = schedulesForDate.find(
          ({ scheduleId: sId }) => scheduleId === sId
        );
        if (relatedSchedule) {
          data["destinationType"] = "Schedule";
          data["destinationName"] = relatedSchedule?.scheduleName;
          data["destinationId"] = scheduleId;
          change = true;
        }
      } else if (projectId) {
        const relatedProject = projects.find(
          ({ projectId: pId }) => projectId === pId
        );
        if (relatedProject) {
          data["destinationType"] = "Project";
          data["destinationName"] = relatedProject?.projectName;
          data["destinationId"] = projectId;
          change = true;
        }
      }

      if (change) {
        itineraryObject.changeDestinationData(data, 1);
      }
    }

    const defaultItinerary = itineraryObject.getItinerary();
    itineraryObject.onChange = onItineraryChange;
    itineraryRef.current = itineraryObject;
    setSelectedItinerary(defaultItinerary);

    setTimeout(() => {
      handleForm(defaultItinerary);
    }, 0);
  }, []);

  /**
   * @param {"TIMES"|"LOCATIONS"} type
   * @returns {string[]}
   */
  function createFormNames(type) {
    //#region CREATE TIME NAMES
    const names = [];
    for (let i = 0; i < selectedItinerary.length; i++) {
      names.push(`${type === "TIMES" ? "departAt" : "pickUpLocation"}-${i}`);
      names.push(`${type === "TIMES" ? "arriveBy" : "dropOffLocation"}-${i}`);
    }

    return names;
  }

  async function onChangeTravelMode(ev, index) {
    //#region CHANGE TRAVEL MODE
    message.loading({
      content: "Loading directions...",
      key: "loadingDirections",
    });
    try {
      await itineraryRef.current.changeTravelMode(ev.target.value, index);
      message.destroy("loadingDirections");
    } catch (err) {
      message.destroy("loadingDirections");
      message.error({ content: err, key: err, duration: 3 });
    }
  }

  /**
   * @param {string} address
   * @param {{key: string, value: string, label: string|ReactElement}} option
   * @param {number} index
   */
  async function onDropOffSelect(address, option, index) {
    //#region DROP OFF SELECT
    message.loading({
      content: "Loading directions...",
      key: "loadingDirections",
    });

    const labelData = getNewLabelHTML({
      option,
    });

    switch (labelData.type) {
      case "project": {
        const pId = projects?.find(
          ({ projectName }) => projectName === address
        )?.projectId;

        itineraryRef.current.changeDestinationData(
          {
            destinationId: pId,
            destinationName: address,
            destinationType: "Off Schedule",
          },
          index
        );
        break;
      }
      case "schedule": {
        const sch = schedulesForDate?.find(
          ({ scheduleAddress }) => scheduleAddress === address.trim()
        );

        if (overlappingSchedules[sch?.scheduleAddress]) {
          setOverlapData({
            index,
            open: true,
            rowData: schedulesForDate.filter(({ scheduleId }) =>
              overlappingSchedules[sch?.scheduleAddress].includes(scheduleId)
            ),
          });
        }

        itineraryRef.current.changeDestinationData(
          {
            destinationId: sch?.scheduleId,
            destinationName: sch?.scheduleName,
            destinationType: "Schedule",
          },
          index
        );
        break;
      }
      case "vendor": {
        const vnd = vendors.find(
          ({ vendorAddress }) => vendorAddress === address
        );

        itineraryRef.current.changeDestinationData(
          {
            destinationId: vnd?.vendorId,
            destinationName: vnd?.vendorName,
            destinationType: "Vendor",
          },
          index
        );
        break;
      }
      case "google": {
        itineraryRef.current.changeDestinationData(
          {
            destinationId: null,
            destinationName: address,
            destinationType: "Off Project",
          },
          index
        );
        break;
      }
      default:
        itineraryRef.current.changeDestinationData(
          {
            destinationId: null,
            destinationName: address,
            destinationType: "Yard",
          },
          index
        );
    }

    try {
      await itineraryRef.current.changeEndAddress(address.trim(), index);
      message.destroy("loadingDirections");
    } catch (err) {
      message.destroy("loadingDirections");
      message.error({ content: err, key: err, duration: 3 });
    }
  }

  async function onChangeDepart(value, index) {
    //#region DEPART CHANGE
    message.loading({
      content: "Loading directions...",
      key: "loadingDirections",
    });
    try {
      await itineraryRef.current.changeDepartTime(parseInTz(value), index);
      message.destroy("loadingDirections");
    } catch (err) {
      message.destroy("loadingDirections");
      message.error({ content: err, key: err, duration: 3 });
    }
  }

  async function onChangeArrive(value, index) {
    //#region ARRIVE CHANGE
    message.loading({
      content: "Loading directions...",
      key: "loadingDirections",
    });
    try {
      await itineraryRef.current.changeArriveTime(parseInTz(value), index);
      message.destroy("loadingDirections");
    } catch (err) {
      message.destroy("loadingDirections");
      message.error({ content: err, key: err, duration: 3 });
    }
  }

  async function onRemoveStep(index) {
    //#region REMOVE STEP
    message.loading({
      content: "Loading directions...",
      key: "loadingDirections",
    });
    try {
      await itineraryRef.current.removeStepOn(index);
      message.destroy("loadingDirections");
    } catch (err) {
      message.destroy("loadingDirections");
      message.error({ content: err, key: err, duration: 3 });
    }
  }

  async function departValidator(input, value) {
    //#region DEPART VALIDATOR
    const index = Number(input.field.replace("departAt-", ""));
    let prevArrive = null;

    if (index) {
      prevArrive = form.getFieldValue(`arriveBy-${index - 1}`);
    }

    if (value && prevArrive) {
      if (value.valueOf() < prevArrive.valueOf()) {
        message.error({
          content: `Cannot set time conflict (step ${index + 1})`,
          key: `timesCollide-${index}`,
        });
        return Promise.reject("Times conflict with above step");
      }
    }

    return Promise.resolve();
  }

  async function arriveValidator(input, value) {
    //#region ARRIVE VALIDATOR
    const index = Number(input.field.replace("arriveBy-", ""));
    const depart = form.getFieldValue(`departAt-${index}`);
    let prevArrive = null;

    if (index) {
      prevArrive = form.getFieldValue(`arriveBy-${index - 1}`);
    }

    if (depart && prevArrive) {
      if (depart.valueOf() < prevArrive.valueOf()) {
        message.error({
          content: `Cannot set time conflict (step ${index + 1})`,
          key: `timesCollide-${index}`,
        });
        return Promise.reject("Times conflict with above step");
      }
    }

    return Promise.resolve();
  }

  async function onLocationSearch(address, option) {
    //#region ON LOCATION SEARCH
    if (!locationMarker.current || !mapRef.current) {
      return;
    }

    locationMarker.current.setVisible(false);
    locationMarker.current.setMap(null);

    let { position } = option;

    if (!position) {
      message.loading({
        content: "Looking for address position...",
        key: address,
        duration: 0,
      });

      await getCoordinatesAndZip(address)
        .then(({ coordinates }) => {
          position = coordinates;
          message.destroy(address);
        })
        .catch((err) => {
          console.log("Error getting address: ", err);
          message.error({
            content: "Could not find address position",
            key: address,
          });
        });
    }

    locationMarker.current.setOptions({
      map: mapRef.current,
      position,
      visible: true,
    });

    mapRef.current.panTo(position);
    mapRef.current.setZoom(17);
  }

  /**
   * @param {number} index
   */
  async function onAddStep(index) {
    //#region ADD STEP
    await form
      .validateFields(staticLast ? createFormNames("LOCATIONS") : undefined)
      .then(() => {
        itineraryRef.current.addStep();
        setTimeout(() => {
          form.scrollToField(`bottom-${index + 1}`, {
            behavior: "smooth",
            block: "end",
          });
        }, 100);
      })
      .catch(() => {});
  }

  //#region JSX
  return (
    <Fragment>
      <Modal
        {...{
          open,
          onCancel,
          keyboard: false,
          closeIcon: <XIcon />,
          centered: true,
          title: "Route Itinerary",
          className: `dispatch-itinerary-container ${
            isDarkMode ? "dispatch-itinerary-dark" : ""
          }`,
          footer: [
            <MondayButton
              {...{
                className: "mondayButtonRed",
                onClick: onCancel,
                key: "cancel",
                Icon: <XIcon />,
              }}
            >
              Cancel
            </MondayButton>,
            <MondayButton
              {...{
                Icon: <TickIcon />,
                key: "confirm",
                async onClick() {
                  await form
                    .validateFields()
                    .then(() => {
                      onConfirm(selectedItinerary);
                    })
                    .catch(() => {});
                },
              }}
            >
              Confirm Itinerary
            </MondayButton>,
          ],
        }}
      >
        <Form className="itinerary-list-container" form={form}>
          {selectedItinerary.map((itineraryStep) => {
            const { index } = itineraryStep;
            const isCustomLast =
              index === selectedItinerary.length - 1 && staticLast;

            const showName =
              itineraryStep.destinationType === "Schedule" ||
              itineraryStep.destinationType === "Vendor";

            return (
              <Fragment key={`itinerary-step-${index}`}>
                <div className="itinerary-step-container">
                  {index && !isCustomLast ? (
                    <Tooltip title="Remove Step" placement="bottom">
                      {/**
                     //#region REMOVE STEP BUTTON
                     */}
                      <div
                        className="remove-step"
                        onClick={() => {
                          onRemoveStep(index);
                        }}
                      >
                        <XIcon />
                      </div>
                    </Tooltip>
                  ) : null}
                  <div className="travel-modes-container">
                    {/**
                   //#region TRAVEL MODES
                   */}
                    <Radio.Group
                      value={itineraryStep.travelMode}
                      defaultValue="DRIVING"
                      onChange={(ev) => {
                        onChangeTravelMode(ev, index);
                      }}
                    >
                      <RadioIconLabel
                        icon={<FleetWhite height={18} width={18} />}
                        title="Driving"
                        value="DRIVING"
                      />
                      <RadioIconLabel
                        icon={<CommuteIcon height={18} width={18} />}
                        title="Transit"
                        value="WALKING"
                      />
                    </Radio.Group>
                  </div>
                  {/**
                 //#region STEP INPUTS
                 */}
                  <SimplePlacesInput
                    {...{
                      disabled: true,
                      required: true,
                      label: "Pick up Location",
                      placeholder: "Pick up Location...",
                      formItemName: `pickUpLocation-${index}`,
                      className: "inner-itinerary-route route-input",
                      groupedOptions: [],
                    }}
                  />
                  <SimplePlacesInput
                    {...{
                      label: getLabelHTML(
                        `(${itineraryStep.destinationType}${
                          showName ? "" : ")"
                        }`,
                        null,
                        showName
                          ? `${itineraryStep.destinationName})`
                          : undefined
                      ),
                      required: false,
                      formItemName: `dropOffLocation-${index}`,
                      isCustomValue: true,
                      popupClassName: isDarkMode ? "darkDropDown" : undefined,
                      onClear() {
                        itineraryRef.current.changeEndAddress(null, index);
                      },
                      rules: [
                        {
                          required: true,
                          message: "*Drop off Location is required",
                        },
                      ],
                      disabled: isCustomLast,
                      className: "inner-itinerary-route route-autocomplete",
                      placeholder: "Drop off Location...",
                      onSelect(val, option) {
                        onDropOffSelect(val, option, index);
                      },
                      groupedOptions: dropOffOptions,
                    }}
                  />
                  <InputComponent
                    {...{
                      formItemName: `departAt-${index}`,
                      label: (
                        <span className="custom-label">
                          <span className="required-mark">*</span>Depart At
                          {itineraryStep.departAt?.valueOf() <
                          selectedDate.valueOf() ? (
                            <span className="label-red">
                              &nbsp;(Previous Day)
                            </span>
                          ) : itineraryStep.departAt?.valueOf() >
                            selectedDate.endOf("day").valueOf() ? (
                            <span className="label-red">&nbsp;(Next Day)</span>
                          ) : null}
                        </span>
                      ),
                      selectedDate: !index
                        ? selectedDate
                        : selectedItinerary[index - 1]["arriveBy"]
                        ? selectedItinerary[index - 1]["arriveBy"]
                        : selectedItinerary[index - 1]["departAt"] ||
                          selectedDate,
                      required: false,
                      type: "customTimeInput",
                      allowClear: false,
                      dropdownClassName: isDarkMode
                        ? "darkDateDropDown"
                        : undefined,
                      open: !index,
                      onChange(val) {
                        onChangeDepart(val, index);
                      },
                      form,
                      showNow: true,
                      className: "itinerary-depart",
                      rules: [
                        {
                          validator: departValidator,
                        },
                        { required: true, message: "*Depart At is required!" },
                      ],
                    }}
                  />
                  <InputComponent
                    {...{
                      formItemName: `arriveBy-${index}`,
                      label: (
                        <span className="custom-label">
                          <span className="required-mark">*</span>Arrive By
                          {itineraryStep.arriveBy?.valueOf() <
                          selectedDate.valueOf() ? (
                            <span className="label-red">
                              &nbsp;(Previous Day)
                            </span>
                          ) : itineraryStep.arriveBy?.valueOf() >
                            selectedDate.endOf("day").valueOf() ? (
                            <span className="label-red">&nbsp;(Next Day)</span>
                          ) : null}
                        </span>
                      ),
                      selectedDate: !index
                        ? selectedDate
                        : itineraryStep["departAt"]
                        ? itineraryStep["departAt"]
                        : selectedItinerary[index - 1]["arriveBy"]
                        ? selectedItinerary[index - 1]["arriveBy"]
                        : selectedItinerary[index - 1]["departAt"] ||
                          selectedDate,
                      required: false,
                      type: "customTimeInput",
                      dropdownClassName: isDarkMode
                        ? "darkDateDropDown"
                        : undefined,
                      allowClear: false,
                      form,
                      onChange(val) {
                        onChangeArrive(val, index);
                      },
                      showNow: true,
                      className: "itinerary-arrive",
                      rules: [
                        {
                          validator: arriveValidator,
                        },
                        {
                          required: true,
                          message: "*Arrive By is required!",
                        },
                      ],
                    }}
                  />
                  {/**
                 //#region STEP FOOTER
                 */}
                  <div className="inner-step-footer-container">
                    <span className="info-text">
                      <span className="info-text-title">Duration:</span>
                      <span className="info-text-value">
                        {itineraryStep.formattedDuration ?? "N/A"}
                      </span>
                    </span>
                    <span className="info-text">
                      <span className="info-text-title">Distance:</span>
                      <span className="info-text-value">
                        {itineraryStep.formattedDistance ?? "N/A"}
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: -100,
                      left: 0,
                      visibility: "hidden",
                    }}
                  >
                    <Form.Item name={`bottom-${index}`}>
                      <div></div>
                    </Form.Item>
                  </div>
                </div>
                {index === selectedItinerary.length - (staticLast ? 2 : 1) && (
                  <div className="add-route-button-container">
                    {/**
                   //#region ADD STEP BUTTON
                   */}
                    <MondayButton
                      {...{
                        Icon: <AddIcon />,
                        onClick: onAddStep,
                      }}
                    >
                      Add Step
                    </MondayButton>
                  </div>
                )}
              </Fragment>
            );
          })}
        </Form>
        {/**
       //#region MAP
       */}
        <div className="map-container">
          <div className="map-controller">
            <SimplePlacesInput
              {...{
                placeholder: "Search Location...",
                label: null,
                noFormItem: true,
                popupClassName: isDarkMode ? "darkDropDown" : undefined,
                onSelect: onLocationSearch,
                onClear() {
                  locationMarker.current.setMap(null);
                },
                groupedOptions: dropOffOptions,
              }}
            />
          </div>
          {mapLoaded && (
            <GoogleMap
              {...{
                zoom: 12,
                mapContainerClassName: "itinerary-map",
                onLoad(map) {
                  mapRef.current = map;
                  itineraryRef.current.map = map;
                },
                center: NYC_CENTER,
                id: "itinerary-map",
                options: {
                  disableDefaultUI: true,
                  styles: MAP_THEME[loadLivePreference("mapStyle")],
                  mapTypeId: loadLivePreference("mapType"),
                  fullscreenControl: true,
                  clickableIcons: false,
                  rotateControl: true,
                  fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                  streetViewControl: true,
                  streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                },
              }}
            />
          )}
        </div>
      </Modal>
      {/**
       //#region SPECIFIC SCHEDULE SELECT
       */}
      {overlapData.open && (
        <SpecificScheduleSelectModal
          {...{
            onCancel() {
              setOverlapData({
                open: false,
                rowData: [],
                index: null,
              });
            },
            onSelect(schedule) {
              itineraryRef.current.changeDestinationData(
                {
                  destinationId: schedule.scheduleId,
                  destinationName: schedule.scheduleName,
                  destinationType: "Schedule",
                },
                overlapData.index
              );

              setOverlapData({
                open: false,
                rowData: [],
                index: null,
              });

              setSelectedItinerary(itineraryRef.current.getItinerary());
            },
            open: overlapData.open,
            projects,
            rowData: overlapData.rowData,
            selectedDate,
          }}
        />
      )}
    </Fragment>
  );
}

export default DispatchItineraryModal;
