import { DIMENSIONS } from "../../../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";

export const getAddonName = (addon) => {
  return (
    addon?.name?.values?.find((v) => v.name.toLowerCase() === "name")?.value ||
    addon?.["name"] ||
    addon?.["sideWalkShedtype"] ||
    "unknown addon"
  );
};
