import React, { useState } from "react";
import "./ProgressDaysView.scss";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { Badge } from "antd";
const ProgressDaysView = (props) => {
  const { onlyProgressDays = [] } = props;
  const [progressDays, setProgressDays] = useState(onlyProgressDays);

  return (
    <div className="progressDaysViewContent">
      <div className="progressDaysFilters">
        <div>
          <InputComponent type="rangepicker" placeholder="Select dates" />
        </div>
        <div>
          <InputComponent type="datepicker" placeholder="Select date" />
        </div>
        <div>
          <InputComponent type="select" placeholder="Select estimation" />
        </div>
        <div>
          <InputComponent type="select" placeholder="Select service" />
        </div>
        <div>
          <InputComponent type="select" placeholder="Select breakdown" />
        </div>
        <div>
          <InputComponent type="select" placeholder="Select sub pli" />
        </div>
      </div>
      <div>
        {[...new Set(progressDays?.map((el) => el?.startDate))]?.map((date) => {
          return (
            <div>
              <span>{date}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressDaysView;
