import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { message } from "antd";
import { WarningModal } from "../../../../../commonComponents";
import { apiDelete } from "../../../../../../utils";
import {
  deleteEvent,
  executeGetEvents,
} from "../../../../../Header/components/GoogleEvents/googleClientAPI/execute";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { loggedOnTasks as updateTaskRedux } from "../../../../../../actions";
import { useEditLogs } from "../../../../../../hooks";
import { deleteAllRelatedToDos } from "../../../../ToDos/forms/helpers";
import {
  CloseIcon,
  Tick,
} from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { WarningIcon } from "../../../../../../icons";

const DeleteTaskModal = ({
  updatedTask,
  setDeleteConfirmModal,
  rowData,
  updateRowData,
  deleteDriveFolder = () => {},
}) => {
  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { userDetails } = useGoogleSignIn();
  const { saveAddedLogs } = useEditLogs();

  const deleteTaskEvents = (eventsList, delay) => {
    const delayEventAdding = (fn, delay) => {
      return (event, preTime) => {
        setTimeout(() => {
          fn(event?.id, "primary"); //preferences?.calendarPreference);
        }, preTime * delay);
      };
    };
    eventsList?.forEach(delayEventAdding(deleteEvent, delay));
  };

  const deleteTask = () => {
    message.loading("Deleting task", 1);
    setDeleteConfirmModal(false);

    const {
      taskId = "",
      taskTitle = "",
      taskTopic = "",
      taskTopicId = "",
      taskRelatedTo = "",
      createdByCognito,
      createdBy = "",
      taskAssignedTo = [],
    } = updatedTask;

    apiDelete("tasksManagement", taskId)
      .then(async () => {
        //update project task List
        deleteAllRelatedToDos({ recordId: taskId });

        await deleteDriveFolder();
        if (taskTopic.toLowerCase() === "projects") {
          API.get("projects", `/projects/${taskTopicId}`)
            .then((project) => {
              const tasksList = project.projectTaskList;

              const findTask = (tasks) => {
                for (const phase in tasks) {
                  for (const task in tasks[phase]) {
                    const subTasks = tasks[phase][task];
                    if (subTasks[taskTitle] !== undefined) {
                      return {
                        phase,
                        task,
                        subTask: taskTitle,
                      };
                    }
                    for (const subTask in subTasks) {
                      if (subTasks[subTask][taskTitle] !== undefined) {
                        return {
                          phase,
                          task,
                          subTask,
                        };
                      }
                    }
                  }
                }
                return null;
              };

              const selectedTaskInfo = findTask(tasksList);

              if (selectedTaskInfo) {
                const { phase, task, subTask } = selectedTaskInfo;
                const taskToDelete = subTask
                  ? tasksList[phase][task][subTask]
                  : tasksList[phase][task];

                delete taskToDelete[taskTitle];

                API.put("projects", `/projects/${project.projectId}`, {
                  body: { projectTaskList: tasksList },
                })
                  .then((res) => console.log("res: ", res))
                  .catch((err) =>
                    console.log(
                      "Error removing task from project task list: ",
                      err
                    )
                  );
              }
            })
            .catch((error) => {
              console.error("Error fetching project data: ", error);
            });
        }

        //   if i have created task remove and event also
        if (
          !!createdByCognito
            ? createdByCognito === userConfiguration.cognitoUserId
            : createdBy === userConfiguration.nameOfUser
        ) {
          //Get all primary events & find by taskId & delete it
          executeGetEvents(
            userDetails,
            preferences?.calendarPreference,
            "all",
            false
          ).then((response) => {
            // filter the events to find the one with the taskId
            if (response) {
              let eventOfTask = response?.result?.items?.filter(
                (event) => event?.extendedProperties?.private?.taskId === taskId
              );

              if (eventOfTask.length > 0) {
                deleteTaskEvents(eventOfTask, 1000);
              }
            }
          });
        } else {
          message.info("You deleted task but not the event");
        }

        //save logs
        saveAddedLogs({
          recordId: taskId,
          recordName: taskTitle,
          category: "Tasks",
          actionType: "Delete",
          topic: taskRelatedTo,
        });

        //send notification for task deletion
        broadcastNotification("5", "onTaskDelete", [
          {
            common: userConfiguration.nameOfUser,
            commonNext: taskTitle,
          },
          {
            cognitos: Array.from(
              new Set([
                ...(taskAssignedTo?.map(({ cognitoUserId }) => cognitoUserId) ||
                  []),
                //user who created the task
                userConfiguration?.allUsers?.Items?.find(
                  ({ nameOfUser }) => nameOfUser === createdBy
                )?.cognitoUserId,
              ])
            ),
            userName: userConfiguration.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
          },
        ]);

        message.success("Task deleted successfully!");

        const updatedRowData = rowData.filter((task) => task.taskId !== taskId);
        updateRowData(updatedRowData);
        dispatch(updateTaskRedux(updatedRowData)); //update redux state
      })
      .catch((error) => console.log("Task deletion error!", error));
  };

  return (
    <WarningModal
      visible={true}
      setVisible={setDeleteConfirmModal}
      title="Warning Message"
      closable={true}
      className="logout-warning-modal"
      darkMode={isDarkMode}
      footer={[
        {
          text: "No",
          onClick: () => setDeleteConfirmModal(false),
          disabled: false,
          icon: <CloseIcon />,
          className: "mondayButtonRed",
        },
        {
          text: "Yes",
          onClick: deleteTask,
          disabled: false,
          icon: <Tick />,
          className: "",
        },
      ]}
    >
      <div className="logout-modal-body">
        <span>
          <WarningIcon />
        </span>
        <p>Are you sure you want to delete this task.</p>
      </div>
    </WarningModal>
  );
};

export default DeleteTaskModal;
