export const defaultColDef = {
  editable: false,
  hide: false,
  filter: true,
  sortable: false,
  resizable: true,
  // autoHeight: true,
  enablePivot: true,
  enableRowGroup: false,
};
