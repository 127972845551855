import { useEffect, useState } from "react";
import { Flex, Modal } from "antd";
import { useSelector } from "react-redux";

import { MondayButton } from "src/components/commonComponents";
import { LoadableComp } from "src/components/SidebarPages/XComponents";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIconWhite } from "src/components/SidebarPages/Communication/assets";
import { swalToast } from "src/components/Header/forms/Scheduling/SchedulingFirstPage/helperData";

import Filters from "./Filters";
import TableList from "./TableList";

import "./InspectionDaysOverview.scss";

export default function InspectionDaysOverview({
  title = "",
  inspections = [],
  isDaysOverviewOpen = false,
  setIsDaysOverviewOpen = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [rowData, setRowData] = useState([]);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  const handleCancel = () => {
    setIsDaysOverviewOpen(false);
  };

  const applyFilters = () => {
    const hasFilters = Object.keys(filters).length > 0;
    if (hasFilters) {
      const filteredData = inspections.filter((row) => {
        return Object.entries(filters).reduce((acc, [key, value]) => {
          // If the filter value is empty, skip this filter
          if (!value) {
            return acc && true;
          }

          if (key === "inspected" || key === "dates") {
            return (
              dayjsNY(row?.inspectionDate).valueOf() >=
                dayjsNY(value?.[0]).valueOf() &&
              dayjsNY(row?.inspectionDate).valueOf() <=
                dayjsNY(value?.[1]).valueOf()
            );
          }
          if (key === "servicesInspected") {
            return row[key]?.find((item) => item === value);
          }

          // Check if the current key exists in the row and matches the filter value
          return acc && row[key] === value;
        }, true);
      });

      setRowData(filteredData);
      showFilters &&
        swalToast({
          icon: "success",
          title: "Filters applied",
          text: `Showing ${filteredData?.length} records!`,
          showConfirmButton: false,
          position: "bottom-end",
          timer: 4500,
        });
    } else {
      setRowData(inspections || []);
      showFilters &&
        swalToast({
          icon: "info",
          title: "No filters applied!",
          text: "Showing all inspection days!",
          showConfirmButton: false,
          position: "bottom-end",
          timer: 4500,
        });
    }
  };

  useEffect(() => {
    if (!!inspections?.length) {
      applyFilters();
    }
  }, [filters]);

  useEffect(() => {
    if (!!inspections?.length) {
      setRowData(inspections);
    }
  }, []);

  return (
    <Modal
      title={title}
      open={isDaysOverviewOpen}
      onCancel={handleCancel}
      closeIcon={<XIconWhite />}
      footer={
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIconWhite />}
          onClick={handleCancel}
        >
          Close
        </MondayButton>
      }
      className={`inspection-days-overview-modal ${
        isDarkMode && "inspection-days-overview-modal-dark"
      }`}
    >
      <LoadableComp loading={!inspections?.length}>
        <Flex gap={10} vertical>
          <div>
            <Filters
              inspections={inspections}
              filters={filters}
              setFilters={setFilters}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <TableList rowData={rowData} showFilters={showFilters} />
          </div>
        </Flex>
      </LoadableComp>
    </Modal>
  );
}
