import ScheduleDay from "../../../../Scheduling/models/ScheduleDay";

/**
 *
 * @param {String} recordId - the record id
 * @param {String} requestType - the type of the request
 * @param {Object} parsedRequest - the parsed request object
 * @param {Function} dispatchRequestData - the pointer to the dispatch function of the request reducer
 */
const mainFieldPopulate = ({
  recordId = null,
  requestType = "",
  parsedRequest = null,
  dispatchRequestData = () => {},
}) => {
  if (requestType === "Schedule Change") {
    let tmpScheduleDays = parsedRequest?.selectedMainField?.scheduleDays || [];

    const scheduleDays =
      tmpScheduleDays.map((el) => Object.assign(new ScheduleDay(), el)) || [];

    return dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        ...parsedRequest?.selectedMainField,
        recordId,
        recordName: parsedRequest?.recordName,
        scheduleDays,
      },
    });
  }

  return dispatchRequestData({
    type: "SELECT_MAIN_FIELD",
    payload:
      {
        ...parsedRequest?.selectedMainField,
        recordId,
        recordName: parsedRequest?.recordName,
      } || {},
  });
};

export default mainFieldPopulate;
