import * as Icons from "../../../../../icons";
import * as AssetsIcons from "../../../../../assets/icons";

export const getIconComponent = (logoName) => {
  let allIcons = { ...Icons, ...AssetsIcons };
  const iconName = logoName;
  if (allIcons[iconName]) {
    return allIcons[iconName];
  }
  return null;
};
