import groupEntriesInShifts from "./groupEntriesInShifts";

function findShiftEntries({ analytics, crews, jobsites, rowData, entryId }) {
  const shift = groupEntriesInShifts({
    analytics,
    jobsites,
    crews,
    rowData,
  }).find(({ entries }) => entries.includes(entryId));

  const entriesOfShift = rowData.filter(({ entryId }) =>
    shift?.entries.includes(entryId)
  );

  return entriesOfShift;
}

export default findShiftEntries;
