import { Modal } from "antd";
import { antdModalProps } from "../../../../../../utils";
import "./HoistPricingInfoModal.scss";
import HoistPricingInfoModalContent from "./HoistPricingInfoModalContent";

const HoistPricingInfoModal = ({ elevation, visible, setVisible }) => {
  const onCancel = () => setVisible(false);

  console.log("elevation", elevation);

  return (
    <Modal
      className="hoist-pricing-info-modal"
      {...{
        ...antdModalProps,
        open: visible,
        onCancel,
        title: "Pricing Information",
      }}
    >
      <HoistPricingInfoModalContent elevation={elevation} onCancel={onCancel} />
    </Modal>
  );
};

export default HoistPricingInfoModal;
