import {
  ArrowsAltOutlined,
  HistoryOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { OpenInNewTabIcon } from "../../../Roles/src";
import { MondayButton } from "../../../../../../commonComponents";
import MultiplyNotes from "../components/MultiplyNotes/MultiplyNotes";

export const excelColumnsConfig = [
  "isDrawedIn",
  "recordAddress",
  "geofencesQuantity",
];

const centerStyle = {
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
};

export const getColumnDefs = ({ modalHandler, retrieveLogs }) => [
  {
    headerName: "Is Drawed In",
    field: "isDrawedIn",
    maxWidth: 250,
  },
  {
    headerName: "Record Address",
    field: "recordAddress",
    link: (data) => `/${data?.isDrawedIn}/${data?.recordId}`,
    cellRenderer: ({ data }) => {
      const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div
            onClick={() =>
              openInNewTab(
                data?.isDrawedIn === "Jobsites"
                  ? `/projectCost?tab=Jobsites&selectedJobsite=${data?.recordId}`
                  : `/${data?.isDrawedIn?.toLowerCase()}/${data?.recordId}`
              )
            }
            style={{ color: "#1065A4", fontWeight: "600" }}
          >
            {data?.recordAddress}
          </div>
        </div>
      );
    },
  },
  {
    headerName: "Geofences Quantity",
    field: "geofencesQuantity",
    maxWidth: 205,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    headerName: "Notes Quantity",
    field: "notesQuantity",
    maxWidth: 205,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cellRenderer: ({ data }) => {
      return <div>{data?.notesQuantity?.length}</div>;
    },
  },
  {
    headerName: "Actions",
    field: "actions",
    // maxWidth: 430,
    pdfExportOptions: {
      skipColumn: true,
    },
    cellRenderer: ({ data }) => {
      const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };
      const hasGeofence = data.geoFenceInfo.length !== 0;
      return (
        <div style={centerStyle} className="actionsSectionGeoConfig">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<OpenInNewTabIcon />}
            onClick={() =>
              openInNewTab(
                data?.isDrawedIn === "Jobsites"
                  ? `/projectCost?tab=Jobsites&selectedJobsite=${data?.recordId}`
                  : `/${data?.isDrawedIn?.toLowerCase()}/${data?.recordId}`
              )
            }
          >
            Go to record
          </MondayButton>
          <MondayButton
            className={hasGeofence ? "mondayButtonBlue" : "mondayButtonGreen"}
            Icon={hasGeofence ? <ArrowsAltOutlined /> : <ShareAltOutlined />}
            onClick={() => modalHandler(data)}
          >
            {hasGeofence ? "Show Details" : "Create New"}
          </MondayButton>
          <MondayButton
            className="mondayButtonBlue"
            Icon={<HistoryOutlined />}
            onClick={() => retrieveLogs(data.recordId)}
          >
            Logs
          </MondayButton>
          {hasGeofence && <MultiplyNotes {...{ notes: data }} />}
        </div>
      );
    },
  },
];
