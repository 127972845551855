import "./ProgressBar.scss";
const ProgressBar = ({
  percent,
  loading = false, // if loading is true, then the progress bar will be gradient with 3 shades of green, else solid with red yellow and green
  style = {},
  className = "",
}) => {
  // let percent = 85;
  const colors = {
    solid: {
      col1: "#F04F4E",
      col3: "#00A464",
      col2: "#F6CB52",
    },
    gradient: {
      col1: "#B3D99B",
      col2: "#7AC14D",
      col3: "#00A464",
    },
  };
  const getProgressColor = () => {
    let finalColor = "";
    const colorPalette = loading ? colors.gradient : colors.solid;
    if (percent < 20) {
      finalColor = colorPalette.col1;
    } else if (percent > 21 && percent < 80) {
      finalColor = colorPalette.col2;
    } else {
      finalColor = colorPalette.col3;
    }
    return finalColor;
  };

  return (
    <div style={style} className={`progress-bar-container ${className}`}>
      <div
        style={!loading ? { backgroundColor: getProgressColor() + 80 } : {}}
        className="progressBarEmptyContainer"
      >
        {" "}
        <div
          className="progressBarFill"
          style={{
            width: `${percent}%`,
            backgroundColor: getProgressColor(),
          }}
        ></div>
      </div>
      <span className="progressPercentageNumber">{percent}%</span>
    </div>
  );
};

export default ProgressBar;
