import React, { useEffect, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import "./DOBCard.scss";
import { InfoIcon } from "../../../Inspections/assets";
const DobCard = ({ params, edited, setNewValues }) => {
  const [values, setValues] = useState(params);
  useEffect(() => {
    if (edited) {
      keys(edited).map((el) => {
        values.content.find(({ formItemName }) => formItemName === el).value =
          edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
    }
  }, [JSON.stringify(edited)]);

  return (
    <div className="DOBCard">
      {params.content.map(({ value, type, label }, index) => {
        if (type === "Input") {
          return (
            <React.Fragment key={index}>
              <div className="InforamtionBodyElementContainer">
                <span className="InformationBodyElementContainerTitle">
                  {label}:
                </span>
                <span className="InforamtionBodyElementContainerContent">
                  {value}
                </span>
              </div>
            </React.Fragment>
          );
        } else if (type === "Multiple") {
          return (
            <React.Fragment key={index}>
              <div className="InforamtionBodyElementContainer">
                <span className="InformationBodyElementContainerTitle">
                  {label}:
                </span>
                <span className="InforamtionBodyElementContainerContent">
                  {Array.isArray(value) ? value?.join(", ") : value}
                </span>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              <span className="InformationBodyElementContainerTitle">
                {label}:
              </span>
              <div
                className="descriptionValue"
                dangerouslySetInnerHTML={{
                  __html:
                    value === '<p><br data-mce-bogus="1"></p>'
                      ? "<InfoIcon />"
                      : value === ""
                      ? "<InfoIcon />"
                      : value,
                }}
              />
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};
export default DobCard;
