import { useState, useEffect } from "react";
import {
  disabledStartDateHandler,
  periodDatesPickerHandler,
} from "../../newApplicationFunctions";
import "./RangePickerContainer.scss";

import { DatePicker } from "antd";
import importedDayjs from "dayjs";
import moment from "moment";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";

const timezone = require("dayjs/plugin/timezone");
importedDayjs.extend(timezone);
const dayjs = importedDayjs.tz;

const { RangePicker } = DatePicker;
const RangePickerContainer = ({
  setSelectedMonth,
  selectedMonth,
  lastPeriod,
  custom,
  setFormChanged = () => {},
  cancelModalVisible = false,
  isDarkMode,
}) => {
  const [closeModal, setCloseModal] = useState(cancelModalVisible);

  useEffect(() => {
    setCloseModal(cancelModalVisible);
  }, [cancelModalVisible]);

  const periodDatesPicker = (date) =>
    periodDatesPickerHandler({ setSelectedMonth, lastPeriod, custom, date });

  return (
    <div className="rangePickerContainer">
      <RangePicker
        disabledDate={(current) =>
          disabledStartDateHandler({ current, selectedMonth, lastPeriod })
        }
        // open={true}
        popupClassName={`newApplicationCalendarStyle ${
          closeModal ? "hide" : "show"
        } ${isDarkMode && "darkDateDropDown"}`}
        className={`dateInputStyle ${closeModal ? "hide" : "show"}`}
        onChange={(date) => {
          setFormChanged(true);
          periodDatesPicker(date);
        }}
        presets={getRangePresets()}
        allowClear={true}
        autoFocus={false}
        defaultValue={null}
        showToday={false}
        defaultPickerValue={[
          dayjs(lastPeriod).tz("America/New_York"),
          dayjs(lastPeriod).tz("America/New_York").add(1, "month"),
        ]}
        value={
          !!selectedMonth?.start
            ? [
                dayjs(dayjs(selectedMonth?.start).endOf("day").valueOf()).tz(
                  "America/New_York"
                ),
                dayjs(dayjs(selectedMonth?.end)?.valueOf()).tz(
                  "America/New_York"
                ),
              ]
            : [null, null]
        }
      />
    </div>
  );
};

export default RangePickerContainer;
