/**
 * Handles the selection of a fleet option.
 *
 * @param {string} val - The selected value.
 * @param {Object} opt - The selected option.
 * @param {boolean} [clear=false] - Indicates whether to clear the fields.
 * @param {Function} [setFieldsValue=() => {}] - The function to set field values.
 * @param {Function} [dispatchRequestData=() => {}] - The function to dispatch request data.
 */
const onFleetSelect = ({
  val,
  opt,
  clear = false,
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (clear === true) {
    setFieldsValue({ issueDate: null, dueDate: null });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: null,
    });
  } else {
    // setFieldsValue({ fleetName: opt?.label });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        fleetId: val,
        fleetName: opt?.label,
        recordName: opt?.label,
        recordId: val,
      },
    });
  }
};

export default onFleetSelect;
