const mouseLeave = (i, isDarkMode) => {
  document.getElementsByClassName("NotificationPopOverBodyFilterItemContainer")[
    i
  ].style.background = isDarkMode ? "#12131b" : "#f4f5f6";
  document.getElementsByClassName("NotificationPopOverBodyFilterItemContainer")[
    i
  ].style.border = isDarkMode
    ? "1px solid #12131b"
    : "1px solid rgb(226, 226, 226)";
  document.getElementsByClassName(
    "NotificationPopOverBodyFilterItemContainerIcon"
  )[i].style.fill = isDarkMode ? "#fff" : "#323338";
  document.getElementsByClassName(
    "NotificationPopOverBodyFilterItemContainerLabel"
  )[i].style.color = isDarkMode ? "#fff" : "#323338";
};
export default mouseLeave;
