import uniq from "lodash/uniq";
import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const fleetInspections = (
  inspectionsData,
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  const inspectionStatuses = programFields?.find(
    (el) => el?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Inspections;

  return {
    inspectedBy: () => ({
      title: "Inspections",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Inspections",
        field: "inspectedBy",
        title: "Inspected By",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.inspectedBy)
        )?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          dateCreated: 1630965600000,
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.inspectedBy === status
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.inspectedBy === status
          ).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //inspectedBy,
    driverName: () => ({
      title: "Inspections",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Inspections",
        field: "driverName",
        title: "Driver Name",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler]?.map((el) => el?.driverInfo),
            "driverName"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || []).length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //driverName
    createdAt: () => ({
      title: "Inspections",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Inspections",
        field: "createdAt",
        title: "Creations Date",
        subtitle: "",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                dateCreated: 1630965600000,

                percent: (
                  ((updatedDynamicStates?.[IdHandler] || [])
                    ?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                    ?.map((a) => a?.createdAt)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    (updatedDynamicStates?.[IdHandler] || [])?.filter(
                      (b) => b.createdAt >= getMonthsTimestamps()[0]
                    ).length) *
                  100
                ).toFixed(1),
                showInfo: true,
                status: months[i],
                done: (updatedDynamicStates?.[IdHandler] || [])
                  ?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.createdAt)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "390px",
    }), //createdAt

    fleetName: () => ({
      title: "Inspection Fleet",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Inspections",
        field: "driverName",
        title: "Fleet Name",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "fleetName"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || []).length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //fleetName

    inspectionStatus: () => ({
      title: "Inspection Status",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionStatus",
        title: "Inspection Status",
        subtitle: "",
        content: inspectionStatuses?.map((el) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.inspectionStatus === el?.statusName
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //inspectionStatus

    inspectionType: () => ({
      title: "Inspection Type",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionType",
        title: "Inspection Type",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "inspectionType"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || []).length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //inspectionType

    mechanic: () => ({
      title: "Inspection Mechanic",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Inspections",
        field: "mechanic",
        title: "Mechanic Name",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "mechanic"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || []).length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  }; //Inspections
};
