import { MutableRefObject } from "react";

type MappedSectionType = {
  name: string;
  statuses: { status: string; color: string }[];
};

function changeTasksColors(
  sections: string[],
  statusColors: MappedSectionType[],
  containerRef?: MutableRefObject<HTMLElement>
) {
  for (const key of sections) {
    const classKey =
      key.toLocaleLowerCase().slice(0, -1).replace(" ", "-") + "-task";

    const statusesForClass = (
      statusColors.find(({ name }) => name === key)?.statuses || []
    ).reduce(
      (acc, val) => ({
        ...acc,
        [val.status]: val.color,
      }),
      {}
    );

    for (const status in statusesForClass) {
      const c = classKey + `-${status.replaceAll(" ", "")}`;
      const eL = (containerRef.current || document).getElementsByClassName(c);

      for (let i = 0; i < eL.length; i++) {
        if (eL.item(i).classList.contains("overdue")) {
          continue;
        }

        const bar = eL.item(i).querySelector(".bar") as SVGRectElement;
        const progressBar = eL
          .item(i)
          .querySelector(".bar-progress") as SVGRectElement;

        if (bar) {
          bar.style.fill = statusesForClass[status] + "bb";
        }

        if (progressBar) {
          progressBar.style.fill = statusesForClass[status];
        }
      }
    }
  }
}

export default changeTasksColors;
