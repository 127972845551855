import "./BorderedTextCard.scss";

/**
 * Renders a bordered text card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the card.
 * @param {string} [props.className=""] - Additional CSS class for the card.
 * @param {string} [props.titleClassName=""] - Additional CSS class for the card title.
 * @param {string} [props.childrenClassName=""] - Additional CSS class for the card children.
 * @param {Object} [props.style={}] - Additional inline styles for the card.
 * @param {boolean} [props.noChildrenStyle=false] - Determines if the children container has its own style.
 * @param {boolean} [props.isDarkMode=false] - Determines if the card is in dark mode.
 * @returns {JSX.Element} The rendered BorderedTextCard component.
 */
const BorderedTextCard = ({
  title,
  className = "",
  titleClassName = "",
  childrenClassName = "",
  children,
  style = {},
  noChildrenStyle = false,
  isDarkMode = false,
}) => (
  <div
    className={`borderedTextCard ${className} ${
      isDarkMode && "borderedTextCardDark"
    }`.trim()}
    style={style}
  >
    <span className={`cardTitle ${titleClassName}`.trim()}>{title}</span>
    <div
      className={`${
        !noChildrenStyle ? "childrenContainer" : ""
      } ${childrenClassName}`.trim()}
    >
      {children}
    </div>
  </div>
);

export default BorderedTextCard;
