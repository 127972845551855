import RichTextModalEditor from "../../../../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor"
import { useStateContext, useDispatchContext } from "../../../../../Context/Context"
import { noteExtractor, onConfirmHandler } from "./descriptionModalHoistFunctions"
import "./DescriptionModalHoist.scss"

const DescriptionModalHoist = ({ elevation, service, event }) => {
  const { darkMode, fakeApplication } = useStateContext()
  const dispatchContext = useDispatchContext()
  const { label, serviceId, estimationId } = service || {}
  const { elevationLabel, elevationId } = elevation || {}
  const { name, id: eventId } = event || {}

  const initialValue = `<div contenteditable="false" user-select: "none" ><h3>Description:</h3> Service: ${label}, Elevation: ${elevationLabel}, Event: ${name}<h3 >Notes:</h3></div>`

  const note = noteExtractor({ fakeApplication, serviceId, elevationId, eventId })

  const onConfirm = ({ newValue }) =>
    onConfirmHandler({
      dispatchContext,
      fakeApplication,
      initialValue,
      estimationId,
      elevationId,
      serviceId,
      newValue,
      eventId,
    })

  return (
    <div className="descriptionModalHoistContainer">
      <RichTextModalEditor
        {...{
          darkMode,
          value: note,
          onConfirm,
        }}
      />
    </div>
  )
}

export default DescriptionModalHoist
