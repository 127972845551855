export const charges = (
  programFields,
  updatedDynamicStates,
  IdHandler,
  chargesData
) => {
  const chargeStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Charge.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    categoryFrom: () => ({
      title: "Charges - Category From",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Charges",
        field: "categoryFrom",
        content: chargeStatus?.statusName?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: chargesData?.length,
          taskDone: chargesData?.filter((a) => a?.categoryFrom === status)
            ?.length,
          color: chargeStatus?.statusColor[index],
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Charge`,
          contentsHandler: ` ${status} Charges`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  };
};
