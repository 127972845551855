import { getCoordinatesAndZip } from "../../../Fleet/utils";

export const getBinNumber = async (address, findAlternate = false) => {
  const { address_components: addressDetails = [] } =
    await getCoordinatesAndZip(address);

  const formattedAddressDetails = addressDetails.reduce(
    (acc, { long_name, types = [] }) => ({
      ...acc,
      [types[0]]: long_name,
    }),
    {}
  );
  const { street_number, route = "" } = formattedAddressDetails;

  const [fetchRes = {}] = await (
    await fetch(
      `https://data.cityofnewyork.us/resource/ipu4-2q9a.json?house__=${street_number}&street_name=${route.toUpperCase()}`
    )
  ).json();

  const { bin__: bin } = fetchRes;

  if (!bin && findAlternate) {
    const baseUrl =
      "https://data.cityofnewyork.us/resource/eabe-havv.json?$query=";

    const queryString = `SELECT bin WHERE (upper(\`house_street\`) LIKE '%${route.toUpperCase()}%') AND (\`house_number\` = '${street_number}')`;

    const apiUrl = baseUrl + encodeURIComponent(queryString);

    const response = await fetch(apiUrl);

    const data = await response.json();

    const distinctBins = Array.from(
      data
        .reduce((map, obj) => {
          map.set(obj.bin, obj);
          return map;
        }, new Map())
        .values()
    );
    return distinctBins;
  }

  return bin;
};
