import { Tooltip } from "antd";
import { Badge } from "antd/lib";
import { ReactComponent as TasksIcon } from "../../../../icons/tasksIcon.svg";
import { useEffect, useState } from "react";
import TasksDrawer from "./LoggedOnTasksDrawer/LoggedOnTasksDrawer";
import { useSelector } from "react-redux";
import { getAccessRights } from "../../../../utils";
import { useDispatch } from "react-redux";
import { loggedOnTasks as addTasks } from "../../../../actions";
import { fetchData } from "../../../SidebarPages/Fleet/utils";
import { WithTooltip } from "../../../commonComponents";

const LoggedOnTasks = () => {
  const dispatch = useDispatch();
  const { preferences: { preferences: { tasksPreferences } = {} } = {} } =
    useSelector((state) => state.preferences);
  const { loggedOnTasks = [] } = useSelector((state) => state.loggedOnTasks);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    try {
      fetchData("tasksManagement").then((res) => {
        const isAdmin =
          userConfiguration.groupName.toLowerCase() === "admin" ||
          "super admin";

        const tasksByAccess = isAdmin
          ? res
          : res?.filter((task) => {
              return (
                task.taskAssignedTo?.includes(userConfiguration?.nameOfUser) ||
                task.createdBy === userConfiguration?.nameOfUser
              );
            });

        const sortedTasks = (
          Array.isArray(tasksPreferences) && !!tasksPreferences
            ? tasksPreferences
            : []
        )
          ?.map((taskId) =>
            tasksByAccess.find((task) => task.taskId === taskId)
          )
          ?.filter(Boolean);

        // console.log({ sortedTasks });

        dispatch(
          addTasks([
            ...sortedTasks,
            ...tasksByAccess?.filter(
              ({ taskId }) =>
                !(
                  Array.isArray(tasksPreferences) ? tasksPreferences : []
                )?.includes(taskId)
            ),
          ])
        );
      });
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const accessRights = getAccessRights({
    userConfiguration,
    title: "Task Manager",
  })?.routeConfig.read;

  if (!accessRights) return null;

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="showTasks">
        <Badge
          count={
            loggedOnTasks?.filter(
              ({ taskTimerDisabled }) => !!taskTimerDisabled
            )?.length
          }
          overflowCount={10}
        >
          <TasksIcon onClick={() => setOpen(true)} className="header-icon" />
        </Badge>
      </WithTooltip>

      {open && <TasksDrawer drawerOpen={open} setDrawerOpen={setOpen} />}
    </div>
  );
};

export default LoggedOnTasks;
