import { useEffect, useState } from "react";
import { Drawer } from "antd";
import Dropdown from "./Dropdown";
import InvoiceActivity from "./InvoiceActivity";
import convertUnixTime from "../../utilities/convertUnixTime";
import "./InvoiceInfoDrawer.scss";
import Footer from "./Footer";
import { API } from "aws-amplify";
import { LoadableComp } from "../../../XComponents";
import { formatCurrency } from "../../../utils";
import { useSelector } from "react-redux";
import { fetchAllData } from "../../../../../utils";

export default function InvoiceInfoDrawer({
  selectedData,
  onClose,
  setData,
  setSelectedData,
  fetchAndSetInvoices,
  proppedIsEditable,
  setRowData,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [visible, setVisible] = useState(true);
  const [productsDetail, setProductsDetail] = useState();
  const [accountData, setAccountData] = useState(false);
  const [projectData, setProjectData] = useState();
  const [isEditable, setIsEditable] = useState(true);
  const [editLogs, setEditLogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await API.get("accounts", `/accounts/${selectedData?.accountId}`)
        .then((res) => setAccountData(res))
        .catch((err) => {
          console.log("Error Opening Invoice: ", err);
          setAccountData(false);
          setSelectedData();
        });
      await API.get("projects", `/projects/${selectedData?.projectId}`).then(
        (res) => {
          setProjectData(res);
          res?.projectStatus === "Canceled"
            ? setIsEditable(false)
            : setIsEditable(true);
        }
      );
    };
    fetchData();
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: selectedData?.invoiceId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((r) => setEditLogs(r))
      .catch((err) => console.log("Error Fetching Logs: ", err));
  }, []);

  function onCloseDrawer() {
    //On Close Drawer
    setVisible(false);
    onClose();
  }

  function formatPrice(price) {
    return parseFloat(price).toFixed(2);
  }

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  //Calculate Total Price
  let totalPrice = 0;
  selectedData?.invoiceItems?.forEach(
    (el) => (totalPrice += parseInt(el.amount))
  );
  totalPrice -= (totalPrice * selectedData?.discount) / 100;
  totalPrice = formatPrice(totalPrice);
  /* --------------- */

  //Convert unixTimeStamp into date
  let invoiceDateConverted = convertUnixTime(selectedData?.invoiceDate);
  let dueDateConverted = convertUnixTime(selectedData?.toDate);
  /* --------------- */

  let nameConverted = selectedData?.accountName?.split(":");

  //Header used in ant drawer
  const Header = (
    <div className="drawerHeader">
      <p>Invoice {selectedData?.NO}</p>
      <div>
        <button
          onClick={onClose}
          aria-label="Close"
          label=""
          data-automation-id="IconControl"
        >
          <svg
            width="16px"
            height="16px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1755 2048"
            focusable="false"
            aria-hidden="true"
            data-automation-id="idsSvgIcon"
            fill="#8D9096"
            className="sc-gzOgki bXJIwd"
            color="white"
          >
            <path d="M0 1586l709-709L0 169 169 0l709 708L1586 0l169 169-708 708 708 709-169 169-708-709-709 709z"></path>
          </svg>
        </button>
      </div>
    </div>
  );

  let ProductsAndServices = (
    <div className="productsAndServices">
      {selectedData?.invoiceItems?.map((el, index) => (
        <div key={index}>
          <div className="mainDetails">
            <div>{el.name}</div>
            <div>${formatPrice(el.amount)}</div>
          </div>
          {productsDetail && (
            <div className="details">
              <p>{el.name}</p>
              <p>
                ${el.amount}
                {el.numberOfItems && `x${formatPrice(el.price)}`}
              </p>
              <hr />
            </div>
          )}
        </div>
      ))}
      {selectedData?.discount > 0 && (
        <div className="mainDetails">
          <i>Discount</i>
          <i>-{selectedData?.discount}%</i>
        </div>
      )}
      <span
        onClick={() => {
          setProductsDetail((prev) => !prev);
        }}
      >
        {productsDetail ? "Fewer details" : "More details"}
      </span>
    </div>
  );

  return (
    <LoadableComp
      loading={
        !!!accountData
        // || !!!projectData
      }
    >
      <Drawer
        rootClassName={`invoicesInfoDrowerContainer ${
          isDarkMode && "invoicesInfoDrowerContainerDark"
        }`}
        closable={false}
        title={Header}
        footer={
          <Footer
            {...{
              selectedData,
              projectData,
              accountData,
              onClose,
              setData,
              setSelectedData,
              fetchAndSetInvoices,
              isEditable,
              editLogs,
            }}
          />
        }
        placement="right"
        onClose={onCloseDrawer}
        open={visible}
      >
        <div className="topInfoContainer">
          {selectedData?.status === "overdue" && (
            <div className="status">
              <div className="Badge-icon">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  viewBox="0 0 24 24"
                  class=""
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 13a1 1 0 100 2 1 1 0 000-2zm0-8a1 1 0 00-1 1v5l.007.117A1 1 0 0013 13V8l-.007-.117A1 1 0 0012 7z"
                  ></path>
                </svg>
              </div>
              <div className="message">Overdue (Not sent)</div>
            </div>
          )}
          <div className="totalPriceContainer">
            <div className="totalPrice">
              {formatCurrency(selectedData?.totalInvoiceAmount)}
            </div>
            <div className="keyInfo">Total due</div>
          </div>
          <div className="dateInfoContainer">
            <div>
              <div className="keyInfo">Invoice date</div>
              <div className="date">{invoiceDateConverted}</div>
            </div>
            <div>
              <div className="keyInfo">Due date</div>
              <div className="date">{dueDateConverted}</div>
            </div>
          </div>
        </div>
        <Dropdown
          title={selectedData?.accountName}
          nameConverted={nameConverted}
          isDarkMode={isDarkMode}
        >
          <div className={"main"}>
            <span>Billing address:</span>
            <div className="address">{accountData?.billingAddress}</div>
            <div className="email">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 20 16"
              >
                <path
                  id="Path_9328"
                  data-name="Path 9328"
                  d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm-.4,4.25-7.07,4.42a1.011,1.011,0,0,1-1.06,0L4.4,8.25a.849.849,0,1,1,.9-1.44L12,11l6.7-4.19a.849.849,0,1,1,.9,1.44Z"
                  transform="translate(-2 -4)"
                  fill="#1264A3"
                />
              </svg>
              <a className="email" href={"mailto:" + accountData?.accountEmail}>
                {accountData?.accountEmail?.charAt(0)?.toUpperCase() +
                  accountData?.accountEmail?.slice(1)}
              </a>
            </div>
            <div className="phone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <path
                  id="call_FILL1_wght600_GRAD0_opsz48"
                  d="M23.749,25.25a14.794,14.794,0,0,1-6.631-1.631A22.561,22.561,0,0,1,11.2,19.368a22.123,22.123,0,0,1-4.265-5.922A14.825,14.825,0,0,1,5.3,6.828,1.526,1.526,0,0,1,5.755,5.7a1.494,1.494,0,0,1,1.1-.455h3.743a1.55,1.55,0,0,1,1.056.361,1.757,1.757,0,0,1,.548,1l.722,3.128a2.156,2.156,0,0,1-.013.989,1.691,1.691,0,0,1-.441.749L9.712,14.073A20.19,20.19,0,0,0,12.8,18.044a16.965,16.965,0,0,0,3.81,2.821l2.62-2.674a1.944,1.944,0,0,1,.842-.548,1.819,1.819,0,0,1,1-.013l2.861.668a1.82,1.82,0,0,1,1,.6,1.654,1.654,0,0,1,.361,1.083v3.69a1.526,1.526,0,0,1-.455,1.123,1.494,1.494,0,0,1-1.1.455Z"
                  transform="translate(-5.3 -5.25)"
                  fill="#1264a3"
                />
              </svg>
              <a href={"tel:" + accountData?.accountPhone}>
                {formatPhoneNumber(accountData?.accountPhone)}
              </a>
            </div>
          </div>
        </Dropdown>
        <Dropdown
          title="Invoice activity"
          nameConverted={nameConverted}
          isDarkMode={isDarkMode}
        >
          <InvoiceActivity
            {...{
              selectedData,
              setData: (newDataCallback) =>
                setData((prev) => {
                  const index = prev.findIndex(
                    (item) => item.invoiceId === selectedData?.invoiceId
                  );
                  if (index) {
                    const newData = newDataCallback(prev[index]);
                    return [
                      ...prev.slice(0, index),
                      newData,
                      ...(prev.length > index + 1
                        ? prev.slice(index + 1, prev.length)
                        : []),
                    ];
                  }
                  return prev;
                }),
              setSelectedData,
              setRowData,
            }}
          />
        </Dropdown>
        <Dropdown
          title="Services"
          nameConverted={nameConverted}
          isDarkMode={isDarkMode}
        >
          {ProductsAndServices}
        </Dropdown>
      </Drawer>
    </LoadableComp>
  );
}
