import PriceTable from "../../../PriceTable/PriceTable"
import {
  CheckboxPriceConfigurator,
  SelectPriceConfigurator,
} from "./components"

export const types = [
  {
    label: "Dropdown",
    value: "select",
  },
  {
    label: "Input",
    value: "input",
  },
  {
    label: "Checkbox",
    value: "checkbox",
  },
]

export const priceConfiguratorByType = {
  checkbox: {
    Comp: CheckboxPriceConfigurator,
    valuePropName: "value",
    rules: [
      {
        validator: (_, value = []) =>
          !!value.length && !value?.[1]
            ? Promise.reject(new Error("Please configure prices correctly"))
            : Promise.resolve(),
      },
    ],
  },
  input: {
    Comp: PriceTable,
    valuePropName: "prices",
    initialValue: [{ id: "initial-value", length: 0, price: 0 }],
    rules: [
      {
        validator: (_, value = []) =>
          !!value.length && value.some(({ length }) => !length)
            ? Promise.reject(new Error("Please configure prices correctly"))
            : Promise.resolve(),
      },
    ],
  },
  select: {
    Comp: SelectPriceConfigurator,
    valuePropName: "value",
    initialValue: [{ id: "initial-value", label: "", value: 0 }],
    rules: [
      {
        validator: (_, value = []) =>
          !!value.length &&
          value.some(({ label, value }) => !label || isNaN(value))
            ? Promise.reject(new Error("Please configure prices correctly"))
            : Promise.resolve(),
      },
    ],
  },
}
