import types from "../types"

const initialState = {
  scheduleOfValue: null,
  scheduleOfValues: [],
  newlyCreatedScheduleOfValue: null,
  isLoading: false,
  loaded: false,
  error: {},
}

const scheduleOfValuesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOADING_SCHEDULE_OF_VALUE:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_SCHEDULE_OF_VALUES:
      return {
        ...state,
        scheduleOfValues: payload,
        isLoading: false,
        loaded: true,
      }
    case types.GET_SCHEDULE_OF_VALUE:
      return {
        ...state,
        scheduleOfValue: payload,
        isLoading: false,
      }
    case types.CREATE_SCHEDULE_OF_VALUE:
      return {
        ...state,
        scheduleOfValues: [payload, ...state.scheduleOfValues],
        newlyCreatedScheduleOfValue: payload,
        isLoading: false,
      }
    case types.UPDATE_SCHEDULE_OF_VALUE:
      return {
        ...state,
        scheduleOfValue: payload.scheduleOfValue,
        scheduleOfValues: state.scheduleOfValues.map((a) =>
          a.scheduleId === payload.scheduleId ? payload.scheduleOfValue : a
        ),
        isLoading: false,
      }
    case types.DELETE_SCHEDULE_OF_VALUE:
      return {
        ...state,
        scheduleOfValues: state.scheduleOfValues.filter(
          (scheduleOfValue) => scheduleOfValue.scheduleId !== payload
        ),
        isLoading: false,
      }
    case types.ERROR_SCHEDULE_OF_VALUE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case types.CLEAR_SCHEDULE_OF_VALUE:
      return {
        ...state,
        scheduleOfValue: null,
      }
    case types.CLEAR_NEWLY_CREATED_SCHEDULE_OF_VALUE:
      return {
        ...state,
        newlyCreatedScheduleOfValue: null,
      }
    case types.RESET_SCHEDULE_OF_VALUE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default scheduleOfValuesReducer
