import { Checkbox, Form } from "antd";
import { useEffect, useState } from "react";
import { MondayButton } from "../../../../../../../commonComponents";
import { Add, HandIcon } from "../../../../../../DynamicView/components/FilterView/FilterIcons";
import { Close } from "../../../../../../DynamicView/src";
import { useJumpsModalContext } from "../../context";
import "./RemainingFloorList.scss";

const RemainingFloorList = ({ onAddFloors }) => {
	const { remainingFloors = [], elevation } = useJumpsModalContext();
	const [selectedFloors, setSelectedFloors] = useState([]);

	//this is a function that finds if the number is closer to the bottom of the array or the top
	const closerToTheEnd = (array, nr) => {
		const lowerValues = array.filter((number) => number < nr);
		const higherValues = array.filter((number) => number > nr);

		return higherValues?.length < lowerValues?.length;
	};

	const changeSelection = (data) => {
		const value = data?.target?.value;
		// in this function we have 2 main cases, when the pressed floor is already selected nd when we are selecting it for th first time
		setSelectedFloors((prevData) => {
			return !!prevData?.find((selectedData) => selectedData === value)
				? //if the floor is already selected we deselect it and if the value is closer to the lower value it removes all the selected floor between that floor
				  // and the min value else if its closer to the max value delete all values between that value and the max
				  (() => {
						const selectedValueIsCloserToTheEnd = closerToTheEnd(prevData, value);

						return prevData?.filter((selectedStops) =>
							selectedValueIsCloserToTheEnd ? selectedStops < value : selectedStops > value
						);
				  })()
				: //else we adds the selected value to an initial array, find the min and the max value in that array and select all values in between
				  (() => {
						const allData = [...prevData, value];

						const lowestFloor = Math.min(...allData);
						const highestFloor = Math.max(...allData);

						var list = [];
						for (var i = lowestFloor; i <= highestFloor; i++) {
							list.push(i);
						}

						return list;
				  })();
		});
	};

	useEffect(() => {
		setSelectedFloors([]);
	}, [remainingFloors]);

	return (
		<div className="remaining-floors-container">
			<Checkbox.Group value={selectedFloors}>
				{remainingFloors
					.sort((a, b) => a.floor - b.floor)
					.map(({ floor }) => (
						<Checkbox value={floor} onChange={changeSelection}>
							<div className="label-container">
								<div>Floor {floor}</div>
								<div className="stop-icons-container">
									<div
										className="stopIcon"
										style={{
											fill: elevation.items.find(({ floor: arrFloor }) => arrFloor === floor)?.stop
												? "#71CF48"
												: "#FE4C4A",
										}}
									>
										<HandIcon />
									</div>
									{elevation?.type === "dual" && (
										<div
											className="stopIcon"
											style={{
												fill: elevation.items.find(({ floor: arrFloor }) => arrFloor === floor)
													?.dual
													? "#71CF48"
													: "#FE4C4A",
											}}
										>
											<HandIcon />
										</div>
									)}
								</div>
							</div>
						</Checkbox>
					))}
			</Checkbox.Group>

			<div className="action-buttons">
				<MondayButton
					className="mondayButtonRed"
					Icon={<Close />}
					onClick={() => {
						setSelectedFloors([]);
					}}
				>
					Clear floors
				</MondayButton>
				<MondayButton
					className="mondayButtonBlue"
					Icon={<Add />}
					disabled={!selectedFloors?.length}
					onClick={() => onAddFloors(selectedFloors)}
				>
					Add floors
				</MondayButton>
			</div>
		</div>
	);
};

export default RemainingFloorList;
