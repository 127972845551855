import { API } from "aws-amplify";
import { filterTables } from "../../../../../utils";
import {
  filterTablesPagination,
  requestAllPages,
} from "../../../../../utils/paginationFetchData";
import { fetchData } from "../../../../SidebarPages/Fleet/utils";
import { fetchAllData } from "../../../../SidebarPages/Fleet/utils/fetchAllData";

export const getNeededTables = async (editMode, projectId) => {
  if (editMode === true) {
    // const [fleet, drivers, fleetDispatching, crews] = await Promise.all([
    //   fetchData("fleet"),
    //   fetchData("drivers"),
    //   fetchData("fleetDispatching"),
    //   fetchData("crews"),
    // ]);
    // const fleetInfo = { fleet, drivers, fleetDispatching, fleetActivity: [] };
    // const crewsInfo = { crews, crewActivity: [] };
    // return { fleetInfo, crewsInfo };
    return {};
  } else {
    let projects = [];
    if (!projectId) {
      projects = await fetchAllData("projects", "projects", "projectId");
    }

    const fleetInfo = {};
    const crewsInfo = {};

    return {
      projects,
      crewsInfo,
      fleetInfo,
      // schedules,
    };
  }
};

export const GET_SCHEDULING_BY_PROJECT = (projectId) => {
  return filterTables("scheduling", "projectId", projectId);
};

const GET_fleet = () => {
  return fetchData("fleet").then((r) => r);
};

const GET_fleetDispatching = () => {
  return fetchData("fleetDispatching").then((r) => r);
};

const GET_drivers = () => {
  return fetchData("drivers").then((r) => r);
};

export const GET_crews = () => {
  return API.get("crews", "/crews");
};

export const GET_accessRights = async (userGroups) => {
  let changeCompany = false,
    projEdit = false,
    docEdit = false,
    docView = false;

  for (const gr of userGroups.routeConfig) {
    switch (gr.title) {
      case "Company":
        changeCompany = gr.write;
        break;
      case "Projects":
        projEdit = gr.write;
        break;
      case "Scheduling/View":
        if (gr.children.find((el) => el.title === "Documentation")) {
          docView = true;
          docEdit = gr.children.find(
            (el) => el.title === "Documentation"
          ).write;
        }
        break;
    }
  }
  return { changeCompany, projEdit, docEdit, docView };
};
