import { InputComponent } from "../../Fleet/components";

function IncidentAssignedTo({
  isDarkMode = false,
  userConfiguration = {},
  setAssignedTo = () => {},
  disabledAllFields: disabled = false,
}) {
  const filterUsersByAccess = userConfiguration?.allUsers?.Items.reduce(
    (acc, user) =>
      !!user?.routeConfig?.find(
        ({ title, write }) => title === "Incidents" && write === true
      ) &&
      user.cognitoUserId !== userConfiguration.cognitoUserId &&
      !user?.isSuspended
        ? [
            ...acc,
            {
              cognitoUserId: user?.cognitoUserId,
              nameOfUser: user?.nameOfUser,
            },
          ]
        : acc,
    []
  );
  return (
    <InputComponent
      {...{
        mode: "multiple",
        required: true,
        showSearch: true,
        label: "Assign To",
        dropdownClassName: isDarkMode && "darkDropDown",
        type: "select",
        formItemName: "assignedTo",
        // onChange: (value, option) => setAssignedTo(option),
        options: filterUsersByAccess?.map(
          ({ cognitoUserId, nameOfUser }, i) => (
            <Option
              key={i}
              label={nameOfUser}
              value={nameOfUser}
              cognitoUserId={cognitoUserId}
            />
          )
        ),
        disabled,
      }}
    />
  );
}

export default IncidentAssignedTo;
