import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "./columnDefs";
import { useSelector } from "react-redux";
import "./FleetItems.scss";

function FleetItems(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      className={`workOrderCardItemsContainer ${
        isDarkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
    >
      {!!props?.params?.rowData?.length ? (
        <AgGridReact
          {...{
            rowData: props?.params?.rowData?.concat({
              total: props?.params?.rowData?.reduce(
                (acc, val) =>
                  acc + parseFloat(val?.costPerUnit) * parseInt(val?.quantity),
                0
              ),
            }),
            columnDefs,
            defaultColDef: {
              resizable: false,
              enableColResize: false,
              enableRowGroup: false,
              sortable: false,
              filter: false,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      ) : (
        <div
          className="noItems"
          style={{ color: isDarkMode ? "#fff" : "#333238" }}
        >
          No inventory items selected! Edit The card to add items.
        </div>
      )}
    </div>
  );
}

export default FleetItems;
