import VideoCard from "../VideoCard/VideoCard";

const FilteredVideos = ({ videos }) => {
  return (
    <div
      className={`searchResult-container ${
        videos?.length < 1 ? "noVideo" : ""
      }`}
    >
      {videos?.length > 0
        ? videos?.map((video) => (
            <VideoCard
              key={video.id}
              data={video}
              searchVideo={true}
              subCategoryName={video.subCategoryName}
            />
          ))
        : "No videos"}
    </div>
  );
};

export default FilteredVideos;
