/**
 * @return {number} - 80 if there are no plis or row height * pli count + header height + offset
 * */
export const getRowHeight = params => {
    if (params.node && params.node.detail) {
        const subPLi = params.data.subPli
        if(subPLi) {
            const offset = 80;
            const gridSizes = params.api.getSizesForCurrentTheme();
            const allDetailRowHeight = subPLi.length * gridSizes.rowHeight;
            return allDetailRowHeight + gridSizes.headerHeight + offset;
        } else {
            return 80;
        }
    }
}

/**
 * Get div height based on ag-grid default sizes and ag grid of sub plis. If we are working with hoist additional height is required
 * */
export const rowGroupOpenedHeight = (params, {serviceId} = {}) => {
    let total_height = 250;
    const gridSizes = params.api.getSizesForCurrentTheme();
    params.api.forEachNode(n => {
        total_height += gridSizes.rowHeight;
        if(n.expanded) {
            total_height += n.data.subPli?.length * gridSizes.rowHeight + gridSizes.headerHeight
        }
    })

    if(serviceId && serviceId.toString() === "3") total_height += 200 + 200

    return total_height
}