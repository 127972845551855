import React, { createContext, useContext, useState } from "react";

const BillCreditContext = createContext();

export const useBillCreditContext = () => {
  return useContext(BillCreditContext);
};

export const BillCreditProvider = ({ children }) => {
  const [isBill, setIsBill] = useState(true);

  return (
    <BillCreditContext.Provider value={{ isBill, setIsBill }}>
      {children}
    </BillCreditContext.Provider>
  );
};
