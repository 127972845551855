import { useEffect, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { ScrollIcon } from "../../src";
import "./Transfer.scss";

const Transfer = ({ params, id, edited, setNewValues }) => {
  const [values, setValues] = useState(params);
  const [scrollable, setScrollable] = useState();
  const [bottom, setBottom] = useState(false);
  useEffect(() => {
    document.getElementById(id)?.scrollHeight &&
      setScrollable(document.getElementById(id).scrollHeight > 300);
  }, [document.getElementById(id)?.scrollHeight]);

  useEffect(() => {
    document.prevTransferList = params.content.value;
  }, []);
  useEffect(() => {
    if (edited) {
      keys(edited).map((el) => {
        values.content.value = edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
      setScrollable(document.getElementById(id).scrollHeight > 300);
    }
  }, [JSON.stringify(edited)]);

  useEffect(() => {
    setScrollable(document.getElementById(id).scrollHeight > 300);
  }, [values]);
  return (
    <div>
      <div
        className="transferContainer"
        id={id}
        onScroll={({ target: { scrollHeight, scrollTop, clientHeight } }) => {
          if (scrollHeight - Math.floor(scrollTop) === clientHeight) {
            if (bottom !== true) {
              setBottom(true);
            }
          } else {
            if (bottom !== false) {
              setBottom(false);
            }
          }
        }}
      >
        {values?.content?.value.map((e) => {
          return (
            <div
              style={{
                backgroundColor: values.content.data.find(
                  ({ id }) => parseInt(id) === parseInt(e)
                )?.color,
              }}
              className="transferItemContainer"
            >
              <span className="transferItemStyle">
                {
                  values.content.data.find(
                    ({ id }) => parseInt(id) === parseInt(e)
                  )?.title
                }
              </span>
            </div>
          );
        })}
      </div>
      {scrollable && (
        <span
          className={!bottom ? "scrollBottomContainer" : "scrollTopContainer"}
        >
          <ScrollIcon />
        </span>
      )}
    </div>
  );
};
export default Transfer;
