import RichTextEditorWrapper from "../../../../../../../../../../../../../commonComponents/RichTextEditor/RichTextEditorWrapper";
import { TickIcon } from "../../../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { MondayButton } from "../../../../../../../../../../../../../commonComponents";
import {
  Close,
  NotesIcon,
} from "../../../../../../../../../../../../DynamicView/src";
import { useRedux } from "../../../../../../../../../../../../hooks";
import { handleSave } from "./richTextModalFunction";
import "./RichTextModal.scss";

// import { useDispatchContext, useStateContext } from "../../../../../../../../Context/Context"

import { Form, Modal, Tooltip } from "antd";
import { useState } from "react";

const RichTextModal = ({
  title,
  value,
  allRow,
  buttonText,
  saveAvailable,
  elevationData,
}) => {
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [isWritable] = useRedux("rentalsIsWritable");
  const [darkMode] = useRedux("rentalsDarkMode");

  // const { darkMode, isWritable, fakeRentals, selectedService } = useStateContext()
  // const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals")
  // const [isAutoSaving ] = useRedux("rentalsIsAutoSaving")
  // const [color ] = useRedux("rentalsColor")
  // const dispatchContext = useDispatchContext()

  const [modalVisibility, setModalVisibility] = useState(false);
  const [form] = Form.useForm();

  const saveHandler = () =>
    handleSave({
      setModalVisibility,
      setFakeRentals,
      selectedService,
      elevationData,
      fakeRentals,
      allRow,
      form,
    });

  return (
    <div className="richTextModalContainer">
      <Tooltip title={isWritable ? "" : "Write mode off"}>
        <MondayButton
          className="openNotesButton"
          onClick={() => setModalVisibility(true)}
          disabled={!isWritable}
          hasIcon={false}
          // Icon={<NotesIcon />}
        >
          {buttonText}
        </MondayButton>
      </Tooltip>
      <Modal
        wrapClassName={
          darkMode ? "richTextEditorModalStyleDark" : "richTextEditorModalStyle"
        }
        title={title || "Text Editor"}
        open={modalVisibility}
        onCancel={() => setModalVisibility(false)}
        footer={
          <div className="richTextNotesButtonRentalContainer">
            <MondayButton
              className="richTextCloseRentalMondayButton"
              onClick={() => setModalVisibility(false)}
              Icon={<Close />}
            >
              Close
            </MondayButton>
            {saveAvailable && (
              <Tooltip title={isWritable ? "" : "Write mode off"}>
                <MondayButton
                  className="richTextSaveRentalMondayButton"
                  onClick={saveHandler}
                  Icon={<TickIcon />}
                  disabled={!isWritable}
                >
                  Save
                </MondayButton>
              </Tooltip>
            )}
          </div>
        }
      >
        <Form {...{ form }}>
          <RichTextEditorWrapper {...{ name: "note", initialValue: value }} />
        </Form>
      </Modal>
    </div>
  );
};

export default RichTextModal;
