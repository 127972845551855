// src/actions/emailActions.js

import types from "../types";

export const addEmails = (newEmails) => ({
  type: types.ADD_EMAILS,
  payload: { newEmails },
});

export const markAsImportant = (itemId, isDraft) => ({
  type: types.MARK_AS_IMPORTANT,
  payload: { itemId, isDraft },
});

export const markAsNotImportant = (itemId, isDraft) => ({
  type: types.MARK_AS_NOT_IMPORTANT,
  payload: { itemId, isDraft },
});

export const markAsRead = (itemId, isDraft) => ({
  type: types.MARK_AS_READ,
  payload: { itemId, isDraft },
});
export const markAsUnread = (itemId, isDraft) => ({
  type: types.MARK_AS_UNREAD,
  payload: { itemId, isDraft },
});

export const moveEmailTo = (emailId, removeFrom, moveTo) => ({
  type: types.MOVE_EMAIL_TO,
  payload: { emailId, removeFrom, moveTo },
});

export const addDrafts = (newDrafts) => ({
  type: types.ADD_DRAFTS,
  payload: { newDrafts },
});

export const updateDrafts = (newDraft) => ({
  type: types.UPDATE_DRAFTS,
  payload: { newDraft },
});

export const updateDraftById = (updatedDraft) => ({
  type: types.UPDATE_DRAFT_BY_ID,
  payload: { updatedDraft },
});

export const deleteDraftById = (draftIdToDelete) => ({
  type: types.DELETE_DRAFT_BY_ID,
  payload: { draftIdToDelete },
});

export const fetchEmailsStart = () => ({
  type: types.FETCH_EMAILS_START,
});
export const fetchEmailsFinished = () => ({
  type: types.FETCH_EMAILS_FINISHED,
});

export const fetchEmailsSuccess = ({ emails, drafts, recordData }) => ({
  type: types.FETCH_EMAILS_SUCCESS,
  payload: { emails, drafts, recordData },
});

export const fetchEmailsFailure = (error) => ({
  type: types.FETCH_EMAILS_FAILURE,
  payload: error,
});

export const resetCommunicationState = () => ({
  type: types.RESET_COMMUNICATION_STATE,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE,
  payload: page,
});

export const setSelectedCategory = (category) => ({
  type: types.SET_SELECTED_CATEGORY,
  payload: category,
});

export const setCheckedList = (list) => ({
  type: types.SET_CHECKED_LIST,
  payload: list,
});
