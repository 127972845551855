import { useMemo, useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Tooltip, Tour, message } from "antd";
import "./ContactModal.scss";
import {
  body,
  CONTACT_ADDRESS,
  CONTACT_DETAILS,
  fieldsJSON,
  footerButtons,
  newFields,
} from "./contactModalData";
import {
  BorderedTextCard,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import {
  MondayButton,
  ProgressComponent,
  SortModalFields,
} from "../../../commonComponents";
import { BillingAndShippingAdd, RenderDynamicComponents } from "../Components";
import { compareIncluding } from "../../../SidebarPages/utils";
import { v4 as uuidv4 } from "uuid";

import {
  apiRoutes,
  fetchData,
  validateForm,
} from "../../../SidebarPages/Fleet/utils";
import {
  filterTables,
  getCognitosForNotification,
  lazyFetch,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import { API } from "aws-amplify";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { filterPeopleList } from "../../../SidebarPages/utils/filterPeopleList";
import { useSelector } from "react-redux";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as CloseIcon } from "../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warningTriangle.svg";
import { useDuplicatePrevention } from "../../../SidebarPages/hooks";
// import { hasValuesChanged } from "../../../../utils/hasValuesChanged";
// import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import _ from "lodash";
import { DownCaretWhite } from "../../../../assets/icons";
// import driveApi from "../../../../integrations/DriveRequest/driveApi";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { XIcon } from "../../../SidebarPages/Communication/assets";
// import { VideoTutorialIcon } from "../../components/ModalVideoTutorials/assets/svg";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";
import { TransferIconNew } from "src/components/SidebarPages/DynamicView/src";
import { useEditLogs, useProgressComponent } from "../../../../hooks";
// import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
// import ButtonDropdown from "../../../commonComponents/MondayButtonDropDown/ButtonDropdown";

const ContactModal = ({
  visible,
  setVisible,
  contact = {},
  refreshTable = () => {},
  saveOnly,
  accountUpdate,
  propLead = {},
  propClient = {},
  preventContinue = false,
  isNextStep,
}) => {
  const [{ userConfiguration }, { programFields }, { isDarkMode }] =
    useSelector((state) => [
      state.userConfig,
      state.programFields,
      state.darkMode,
    ]);
  const {
    jobSite: proppedjobSite,
    contactType: proppedContactType,
    accountName: proppedAccountName,
    accountPhone: proppedAccountPhone,
    opportunityAddress: opportunityAddress,
    // contactToCreate: contactToCreate,
  } = contact;

  const {
    aptNumber: proppedAptNumber = null,
    leadAddress: proppedLeadAddress = null,
  } = propLead;
  const {
    shippingAddress: proppedClientAddress = null,
    aptNumber: proppedClientAptNumber = null,
  } = propClient;

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [addressesCompKey, setAddressesCompKey] = useState(uuidv4());
  const [recordTypes, setRecordTypes] = useState([]);
  const [contactRoles, setContactRoles] = useState([]);
  const [familyDetails, setFamilyDetails] = useState();
  const [contactDescription, setContactDescription] = useState();
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [selectedAccountsContacts, setSelectedAccountsContacts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [beveragePreferences, setBeveragePreferences] = useState([]);
  const [leads, setLeads] = useState([]);
  const [saving, setSaving] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [contactType, setContactType] = useState();
  const [users, setUsers] = useState([]);
  const [fetchedContacts, setFetchedContacts] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [opportunities, setOpportunities] = useState([]);
  const [projects, setProjects] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [detailsFields, setDetailsFields] = useState([]);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.contactModal || {}
  );
  const [shippingInfo, setShippingInfo] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);
  // ------------------------------------------------------------------------------------
  const url = window.location.href;
  const categorySelected = url.substring(0, url.lastIndexOf("/") + 1);
  const latestIdOfSelected = url.substring(
    url.lastIndexOf("/") + 1,
    url.length
  );
  const categorySelected2 = categorySelected.split("/");
  const categorySelected22 = categorySelected2[categorySelected2.length - 2];
  const driveFunctions = useCreateDriveFolders("Contacts");

  // +=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=
  const lastPartUrl = latestIdOfSelected; // This gets the id on Url
  const ofThePenultimateUrl = categorySelected22; // This gets category of id in Url
  // ------------------------------------------------------------------------------------

  let idOfCompany = null;
  if (ofThePenultimateUrl === "opportunities") {
    idOfCompany = opportunities.filter(
      (item) => item.opportunityId === lastPartUrl
    )[0];
  } else if (ofThePenultimateUrl === "projects") {
    idOfCompany = projects.filter((item) => item.projectId === lastPartUrl)[0];
  }

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Contacts", actionType: "Create" });
  const { saveAddedLogs } = useEditLogs();

  const [selectedJobsite, setSelectedJobsite] = useState(null);

  const jobsiteApi =
    (compareIncluding(contactType, "opportunity") && "opportunities") ||
    (compareIncluding(contactType, "project") && "projects");

  const jobsiteRecord = useMemo(
    () =>
      !!jobsiteApi &&
      (jobsiteApi === "projects"
        ? projects?.find?.(({ projectName }) => projectName === selectedJobsite)
        : opportunities?.find?.(
            ({ opportunityAddress }) => opportunityAddress === selectedJobsite
          )),
    [
      projects,
      opportunities,
      contactType,
      selectedJobsite,
      refreshTable,
      jobsiteApi,
    ]
  );
  // setSelectedJobsite("365 Bond Street, Brooklyn, NY, USA");

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Sales")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Contact"
    )?.videoLink;

  useDuplicatePrevention({
    listOfRecords:
      fetchedContacts?.filter(
        (el) => el?.contactAccountName === selectedAccountName
      ) || [],
    filter: {
      contactEmail: selectedEmail,
    },
    notEqualFilter: ["contactEmail"],
    onDuplication: () => {
      message.warning("There is an existing contact with this information!");
      form.resetFields(["contactEmail"]);
      setSelectedEmail("");
    },
  });

  let radioContactType =
    form.getFieldsValue().contactRecordType || proppedContactType;

  const { accountId, teamsConfiguration } =
    radioContactType === "Opportunity Contact" ||
    radioContactType === "Project Contact"
      ? projects?.find?.(
          (item) => item.accountName === form.getFieldValue().contactAccountName
        ) || {}
      : accounts?.find?.(
          ({ accountName }) => accountName === selectedAccountName
        ) || {};

  const { leadId, teamsConfiguration: leadTeamsConfiguration } =
    radioContactType === "Opportunity Contact" ||
    radioContactType === "Project Contact"
      ? leads?.find?.(
          (item) => item.leadCompany === form.getFieldValue().contactAccountName
        ) || {}
      : leads?.find?.(
          ({ leadCompany }) => leadCompany === selectedAccountName
        ) || {};

  const whichId = !!accountId ? { accountId } : { leadId };

  const companies = uniqWith(
    // tempCompanies,
    [
      !compareIncluding(contactType, "lead") &&
        accounts
          ?.filter?.(({ accountRecordType }) =>
            compareIncluding(contactType, "subcontractors")
              ? compareIncluding(accountRecordType, "subcontractor")
              : compareIncluding(contactType, "client")
              ? compareIncluding(accountRecordType, "client")
              : true
          )
          ?.map?.(({ accountName }) => accountName),
      !compareIncluding(contactType, "subcontractors") &&
        !compareIncluding(contactType, "client") &&
        leads
          ?.filter(({ leadStatus }) => leadStatus !== "Converted")
          ?.map?.(({ leadCompany }) => leadCompany),
    ]
      ?.filter(Boolean)
      ?.flat(1),
    isEqual
  );

  const resetForm = () => {
    form.resetFields();
    setAddressesCompKey(uuidv4());
    setContactDescription("");
    setFamilyDetails("");
    setSelectedAccountName("");
    setSaving(false);
    setSelectedJobsite("");
    setContactType("");
    setSelectedCompany("");
    driveFunctions?.reset();
  };

  const inputFields = fieldsJSON({
    jobsiteApi,
    recordTypes,
    contactRoles,
    contactDescription,
    companies,
    beveragePreferences,
    setContactDescription,
    setSelectedAccountName,
    setSelectedEmail,
    setContactType,
    setSelectedJobsite,
    jobsites: (compareIncluding(contactType, "opportunity")
      ? opportunities
      : projects
    )?.map?.(
      ({ projectName, opportunityAddress }) => projectName || opportunityAddress
    ),
    ...contact,
  });

  const bodyObj = () =>
    body({
      form,
      familyDetails,
      contactDescription,
      ...whichId,
    });

  const handleWhereToSaveDrives = () => {
    if (!!whichId?.leadId) {
      return leads?.find(({ leadId }) => leadId === whichId?.leadId)
        ?.googleDriveFolderIds?.contactsObject;
    } else if (!!whichId?.accountId) {
      return accounts?.find(({ accountId }) => accountId === whichId?.accountId)
        ?.googleDriveFolderIds?.contactsObject;
    }
  };

  // console.log(whichId);

  const saveRecord = async (action) => {
    let res;
    showLoadingMsg();
    setSaving(true);
    // const parentId = handleWhereToSaveDrives()?.find(
    //   ({ leadCompany }) => leadCompany === selectedAccountName
    // )?.googleDriveFolderIds?.contactsObject;
    let allFolders = null;

    await driveFunctions
      .create({
        parentId: handleWhereToSaveDrives(),
        parentFolderName: form.getFieldValue("contactEmail"),
      })
      .then(({ folders }) => {
        allFolders = folders;
      })
      ?.catch((err) => console.log(err));

    setVisibleCreationProgress({ action });
    updateProgressStatus({ updatingRecord: "executing" });
    await API.post(apiRoutes.contacts, `/${apiRoutes.contacts}`, {
      body: {
        ...bodyObj(),
        googleDriveFolderIds: {
          ...(allFolders || {}),
        },
        teamsConfiguration: teamsConfiguration || leadTeamsConfiguration,
        ...(!!jobsiteApi
          ? jobsiteApi === "projects"
            ? { listOfProjectsIds: [jobsiteRecord?.projectId] }
            : { listOfOpportunitiesIds: [jobsiteRecord?.opportunityId] }
          : {}),
      },
    })
      .then(async (r) => {
        setVisibleCreationProgress({ ...r, action });
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });
        const notifTeam = teamsConfiguration || leadTeamsConfiguration;
        await broadcastNotification(
          "7",
          "onCreation",
          [
            { common: userConfiguration?.nameOfUser },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
              cognitos: getCognitosForNotification(
                userConfiguration,
                notifTeam
              ),
            },
          ],
          r.contactId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
        saveAddedLogs({
          recordId: r?.accountId,
          recordName: r?.contactEmail,
          category: "Contacts",
        });

        const { listOfOpportunitiesIds = [], listOfProjectsIds = [] } = r;
        const { contacts: jobsiteContacts } = jobsiteRecord || {};
        if (!!jobsiteRecord) {
          const hideLoader = message.loading(
            "Updating contacts at opportunity/project",
            0
          );
          if (jobsiteApi !== "projects") {
            for (const oppId of listOfOpportunitiesIds) {
              await API.patch(jobsiteApi, `/${jobsiteApi}/${oppId}`, {
                body: {
                  contacts: [
                    ...jobsiteContacts,
                    {
                      id: r?.contactId,
                      name:
                        `${r?.contactEmail}` ||
                        `${r?.contactFirstName} ${r?.contactLastName}`,
                    },
                  ],
                },
              }).catch((e) => console.log("ERROR", e.response));
            }
          } else {
            for (const projId of listOfProjectsIds) {
              await API.patch(jobsiteApi, `/${jobsiteApi}/${projId}`, {
                body: {
                  contacts: [
                    ...jobsiteContacts,
                    {
                      id: r?.contactId,
                      name:
                        `${r?.contactEmail}` ||
                        `${r?.contactFirstName} ${r?.contactLastName}`,
                    },
                  ],
                },
              }).catch((e) => console.log("ERROR", e.response));
            }
          }
          hideLoader();
          message.success("Contacts updated at opportunity/project");
        }

        !!accountId &&
          !selectedAccountsContacts.length &&
          (await API.patch(
            apiRoutes.accounts,
            `/${apiRoutes.accounts}/${accountId}`,
            {
              body: {
                primaryContactId: r.contactId,
              },
            }
          ));
        res = r;
        showSuccessMsg();
        setTimeout(() => refreshTable([r]), 0);
      })
      .catch((e) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        console.error(e);
        showErrorMsg();
      });
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
    return res;
  };

  const onCancel = () => {
    setVisible(false);
    setCancelModalVisible(false);
    setFormChanged(false);
    resetForm();
  };

  const validateContactForm = (onSuccessFunc) => {
    validateForm(form, onSuccessFunc);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const onOptionClick = ({ key }) => {
    let tmpKey = preventContinue ? "Finish" : "Save & Close";
    compareIncluding(key, tmpKey)
      ? validateContactForm(() => {
          preventContinue
            ? saveRecord("onSaveAndContinue")
            : saveRecord("onFinish");
        })
      : validateContactForm(saveRecord);
  };

  useEffect(() => {
    const programFieldsObj = programFields.reduce(
      (acc, { fieldName, fieldOptions }) => ({
        ...acc,
        [fieldName]: fieldOptions,
      }),
      {}
    );

    setUsers(filterPeopleList(userConfiguration.allUsers?.Items));
    setBeveragePreferences(programFieldsObj["Beverage preference"]);
    setContactRoles(programFieldsObj["Lead Roles"]);
    setRecordTypes(programFieldsObj["Contact Types"]);
  }, [programFields, userConfiguration]);

  const contactDetailsFields = inputFields[1];
  contactDetailsFields.sort((a, b) => {
    if (Object.keys(sortFieldsObj).length === 0) {
      return 0;
    }
    const aIndex = sortFieldsObj[a.formItemName];
    const bIndex = sortFieldsObj[b.formItemName];
    return aIndex - bIndex;
  });

  useEffect(() => {
    Promise.allSettled([
      fetchData("accounts"),
      fetchData("leads"),
      fetchData("contacts"),
      fetchAllData("opportunities", "opportunities", "opportunityId"),
      fetchAllData("projects", "projects", "projectId"),
    ])
      .catch((errorPromise) => {
        console.error(errorPromise);
      })
      .then(
        (
          // [accounts, leads, contacts, opportunities, projects]
          [
            { value: accounts },
            { value: leads },
            { value: contacts },
            { value: opportunities },
            { value: projects },
          ]
        ) => {
          setAccounts(accounts);
          setLeads(leads);
          setFetchedContacts(contacts);
          setOpportunities(opportunities);
          setProjects(projects);
        }
      )
      .catch((errorThen) => {
        console.error(errorThen);
      });
  }, []);

  useEffect(() => {
    if (!!jobsiteRecord && !!jobsiteRecord?.accountName) {
      form.setFieldsValue({ contactAccountName: jobsiteRecord?.accountName });
      setSelectedAccountName(jobsiteRecord?.accountName);
    }
  }, [jobsiteRecord]);

  useEffect(() => {
    if (!!proppedContactType && !!visible) {
      form.setFieldsValue({
        contactRecordType: proppedContactType,
        contactAccountName: proppedAccountName,
        contactPhone: proppedAccountPhone,
        jobsite: !!opportunityAddress ? opportunityAddress : proppedjobSite,
      });
      setContactType(proppedContactType);
      setSelectedAccountName(proppedAccountName);
      setSelectedJobsite(proppedjobSite);
    }
  }, [JSON.stringify(contact), addressesCompKey, visible]);

  useEffect(() => {
    if (!selectedAccountName) return;
    filterTables("contacts", "contactAccountName", selectedAccountName).then(
      (r) => setSelectedAccountsContacts(r)
    );
  }, [selectedAccountName]);

  // useEffect(() => {
  //   setInitialValues(form.getFieldsValue());
  // }, []);

  useEffect(() => {
    if (!!accountUpdate) {
      setTimeout(() => {
        setAccounts(accountUpdate);
      }, 3000);
    } else return;
  }, [accountUpdate]);

  useEffect(() => {
    if (!proppedContactType) {
      if (selectedAccountName) {
        switch (contactType) {
          case "Lead Contact":
            {
              const leadSelected = leads.find(
                (el) => el.leadCompany === selectedAccountName
              );
              setShippingInfo({
                shippingAddress: leadSelected?.leadAddress || null,
                shippingAptNr: leadSelected?.shippingAddApartmentNr || null,
              });
            }
            break;
          case "Client Contact" || "Subcontractors Contact":
            {
              const accountSelected = accounts.find((el) =>
                contactType === "Subcontractors Contact"
                  ? el?.accountName === selectedAccountName &&
                    el?.accountRecordType === ""
                  : el?.accountName === selectedAccountName
              );
              setShippingInfo({
                shippingAddress:
                  accountSelected?.shippingAddress !== ""
                    ? accountSelected?.shippingAddress
                    : null,
                shippingAptNr:
                  accountSelected?.aptNumber !== ""
                    ? accountSelected?.aptNumber
                    : null,
              });
            }
            break;
          default:
            break;
        }
      }
    }
  }, [selectedAccountName, contactType]);

  useEffect(() => {
    const jobsite = _.cloneDeep(jobsiteRecord);
    setShippingInfo({
      shippingAddress:
        accounts?.find((el) => el.accountId === jobsite?.accountId)
          ?.shippingAddress || "",
      shippingAptNr:
        accounts?.find((el) => el.accountId === jobsite?.accountId)
          ?.aptNumber || "",
    });
  }, [jobsiteRecord]);

  useEffect(() => {
    if (!contact) {
      form.setFieldValue("contactAccountName", undefined);
      setSelectedAccountName("");
      setShippingInfo(null);
    }
  }, [contactType]);

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "contactModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Contact Type": document.querySelector(".bodyTypeRadio"),
      "Contact Details": document.querySelector(".contactBorderedTextCard"),
      "Contact Address": document.querySelector(".billingBorderedTextCard"),
      "Street View Address": document.querySelector(".shippingAddIcon"),
      "Create Contact": document.querySelector(".saveButton"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  return (
    <>
      <NormalSizedModal
        visible={visible}
        // title="New Contact"
        title={
          <CustomModalHeader
            title={"New Contact"}
            headerInfo={() => {
              return (
                userConfiguration.groupName === "Admin" && (
                  <Tooltip title="Sort Fields">
                    <TransferIconNew
                      {...{
                        className: "sortFieldsIcon",
                        onClick: () => {
                          setSortOpen(true);
                        },
                      }}
                    />
                  </Tooltip>
                )
              );
            }}
            onClick={() => {
              setTourOpen(true);
            }}
          />
        }
        className={`contactModalContainer ${
          isDarkMode && "contactModalContainerDark"
        }`}
        onCancel={
          () => onCancel()
          // !!hasValuesChanged(initialValues, form.getFieldsValue())
          // ? setVisible(false)
          // : setCancelModalVisible(true)
        }
        newFooterDesign={footerButtons({
          saving,
          saveOnly,
          onCancel: () => {
            if (formChanged) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          },
          onSave: () =>
            validateContactForm(() => {
              preventContinue
                ? saveRecord("onFinish")
                : saveRecord("onSaveAndContinue");
            }),
          onOptionClick,
          preventContinue,
          suffix: <DownCaretWhite />,
        })}
        centered
        closeIcon={<XIcon />}
        closable={true}
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormChanged(true);
          }}
        >
          <div className="contactModalBodyContainer">
            <div className="contactModalBodyContainer-splash bodyTypeRadio">
              <span className="radioTitle">Select a contact type</span>
              {RenderDynamicComponents(inputFields[0])}
            </div>
            <BorderedTextCard
              title={CONTACT_DETAILS}
              // style={{ padding: "20px 0px 0px 0px" }}
              className="contactBorderedTextCard"
              childrenClassName="contactsFormContainer"
            >
              {compareIncluding(contactType, "project") ||
              compareIncluding(contactType, "opportunity") ? (
                <div className={`contactDetailsRow opportunity`}>
                  {RenderDynamicComponents(contactDetailsFields)}
                </div>
              ) : (
                <div className={`contactDetailsRow default`}>
                  {RenderDynamicComponents(
                    contactDetailsFields?.filter(
                      (el) => el.formItemName !== "jobsite"
                    )
                  )}
                </div>
              )}

              <div className="editorsContainer">
                {RenderDynamicComponents(inputFields[2])}
              </div>
            </BorderedTextCard>
            <BillingAndShippingAdd
              {...{
                form,
                onlyOneAddress: true,
                key: addressesCompKey,
                title: CONTACT_ADDRESS,
                mapClassName: "map",
                shippingAddress:
                  proppedClientAddress ||
                  proppedLeadAddress ||
                  shippingInfo?.shippingAddress ||
                  "",
                shippingAddressAptNr:
                  proppedClientAptNumber ||
                  proppedAptNumber ||
                  shippingInfo?.shippingAptNr ||
                  "",
                contactModal: true,
              }}
            />
          </div>
        </Form>
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<Tick />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      </NormalSizedModal>
      {/* <div>
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            ClearOptions,
            setSelectedTeam,
            setSelectedTeam,
          }}
        />
      </div> */}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Contact Tutorial",
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Contact",
            rowData: contactDetailsFields,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "contactModal",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Contacts",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              resetForm();
              const { action, contactId } = visibleCreationProgress;
              if (!!action) {
                setVisible(false);
                action === "onSaveAndContinue" &&
                  !!!isNextStep &&
                  navigate(`/contacts/${contactId}`);
              }
            },
          }}
        />
      )}
    </>
  );
};

export default ContactModal;
