import types from "../../types"

const initialState = {
  notification: Math.random(),
}

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.NOTIFICATION:
      return {
        ...state,
        notification: payload,
      }
    default:
      return state
  }
}

export default notificationReducer
