import moment from "moment";
import { formatCurrency } from "../../../../utils";
import { removeHTMLTags } from "../../../../../../utils/removeHTMLTags";

export const documentDefinition = (title, companyLogo, billData) => {
  const formatDate = (date) => moment(date).format("MM/DD/YYYY");

  const totalBillAmount = billData.billItems.reduce(
    (acc, bill) => acc + bill.amount,
    0
  );
  const totalTaxAmount = billData.billItems.reduce(
    (acc, bill) => acc + bill.taxAmount,
    0
  );
  const total = billData.billItems.reduce((acc, bill) => acc + bill.total, 0);

  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} |  CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: "logo", // Use the "logo" image defined in images
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                alignment: "right",
                margin: [10, 10, 0, 10],
              },
              {
                text: `Date: ${formatDate(billData.billDate)}`,
                alignment: "right",
                style: "bool", // Style for the date
              },
              {
                text: `Due Date: ${formatDate(billData.dueDate)}`,
                alignment: "right",
                style: "bool", // Style for the date
              },
            ],
            width: "*",
            alignment: "center",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
              <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
            </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        text: "Bill Information:",
        style: ["strong"],
      },
      {
        // Create a columns section to display the bill details
        columns: [
          // Left side content
          {
            stack: [
              {
                text: `Vendor: ${billData.vendorName}`,
                alignment: "left",
                margin: [0, 0, 0, 10],
              },
              {
                text: `Project: ${billData.projectName}`,
                alignment: "left",
                margin: [0, 0, 0, 10],
              },
            ],
            style: "listItem",
          },
          // Right side content
          {
            stack: [
              {
                text: `Bill Amount: ${formatCurrency(billData.billAmount)}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
              {
                text: `Bill Status: ${billData.billStatus}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
            ],
            style: "listItem",
          },
        ],
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
              <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
            </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        table: {
          headerRows: 1,
          widths: ["auto", "auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              { text: "Name", bold: true },
              { text: "Category", bold: true },
              { text: "Description", bold: true },
              { text: "Amount", bold: true },
              { text: "Tax Amount", bold: true },
              { text: "Total", bold: true },
            ],
            ...billData.billItems.map((bill) => [
              bill.itemName,
              bill.itemCategory,
              removeHTMLTags(bill.itemDescription),
              formatCurrency(bill.amount),
              formatCurrency(bill.taxAmount),
              formatCurrency(bill.total),
            ]),
            [
              { text: "Totals:", bold: true },
              "",
              "",
              formatCurrency(totalBillAmount),
              formatCurrency(totalTaxAmount),
              formatCurrency(total),
            ],
          ],
        },
        style: "outerTable",
      },
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { fontSize: 12 },
      header: { margin: [0, 0, 0, 20] },
      listItem: { margin: [5, 5] },
      bool: { margin: [0, 5], fontSize: 13 },
      outerTable: { margin: [0, 0, 0, 30] },
    },
  };
};
