import { useSelector } from "react-redux";

import styles from "./TreeTextHeader.module.scss";
import { Tooltip } from "antd";

const TreeTextHeader = ({ text, value, style = {} }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <Tooltip title={typeof value === "object" ? "" : value}>
      <div
        style={style}
        className={
          styles[isDarkMode ? "tree-text-header-dark" : "tree-text-header"]
        }
        data-testid="tree-text-header"
      >
        {text && text + ": "} {typeof value === "object" ? "" : value}
      </div>
    </Tooltip>
  );
};

export default TreeTextHeader;
