import { notification, message as antMessage } from "antd";
import { receiveSessionNotification } from "../../components/Header/utils/devUsers";
import { Auth } from "aws-amplify";
import { sessionsNotificationBody } from "./utils/sessionNotificationDescription";
import {
  askToLogoutPopup,
  forceLogoutPopup,
  messagePopup,
} from "./utils/forceLogoutPopup";
import { isEmpty } from "lodash";
import { updateReduxSession } from "../../reducers/commonsStates/sessionNotificationsReducer";

export function handleSessionMessage(
  msg,
  userConfiguration,
  wsSessions,
  userSession,
  setAskToLogout,
  navigate,
  dispatch
) {
  const message = JSON.parse(msg.data);
  const { request, body } = message;
  const allUsers = userConfiguration?.allUsers.Items;
  const allUsersObject = allUsers?.reduce((acc, user) => {
    acc[user.identityId] = user;
    return acc;
  }, {});
  const sessionId = JSON.parse(window.localStorage.getItem("sessionId"));
  const env = process.env.NODE_ENV === "production" ? "prod" : "dev";

  async function logoutUser() {
    await userSession.close(userConfiguration);
    try {
      wsSessions.send(
        JSON.stringify({
          request: "logout",
          body: {
            userId: userConfiguration?.identityId,
            sessionId: sessionId,
          },
        })
      );
    } catch (e) {
      console.error("Error sending logout request", e);
    }

    await Auth.signOut().then((e) => {
      window.location.pathname = "/login";
    });
  }
  if (body?.env !== env) {
    return;
  }
  if (request === "new-action") {
    if (
      receiveSessionNotification?.includes(userConfiguration?.nameOfUser) &&
      body?.userId !== userConfiguration?.identityId
    ) {
      const notificationObject = body?.notificationObject;
      if (!isEmpty(notificationObject)) {
        const newLog = { ...notificationObject, new: true };
        // dispatch(updateReduxSession("add", newLog));
        notification.info(
          sessionsNotificationBody({
            body,
            navigate,
            notification,
            allUsersObject,
          })
        );
      }
    }
  } else if (request === "force-logout") {
    forceLogoutPopup({
      logoutUser,
      resetPassword: body?.resetPassword || false,
    });
  } else if (request === "refresh") {
    window.location.reload();
  } else if (request === "show-popup") {
    forceLogoutPopup({ resetPassword: body?.resetPassword || false });
  } else if (request === "ask-popup") {
    let time = body?.time || 2 * 60 * 1000;
    const timer = new Date().getTime() + time;
    setAskToLogout(timer);
    localStorage.setItem("askToLogoutTime", timer);
    let convertTime = time / 60 / 1000;
    askToLogoutPopup({ convertTime, logoutUser });
  } else if (request === "immediate-logout") {
    logoutUser();
  } else if (request === "new-message") {
    messagePopup({
      message: body?.message,
      ws: wsSessions,
      userId: body?.sender,
    });
  }
}

export function handleUserSessionIdle(
  presence,
  userConfiguration,
  wsSessions,
  setIsSessionIdle
) {
  const sessionId = JSON.parse(window.localStorage.getItem("sessionId"));
  try {
    if (presence?.type === "idle") {
      setIsSessionIdle({
        idle: true,
        socket: wsSessions.readyState === 1 ? true : false,
      });

      wsSessions.send(
        JSON.stringify({
          request: "isIdle",
          body: {
            userId: userConfiguration?.identityId,
            sessionId: sessionId,
            isIdle: true,
          },
        })
      );
    } else if (presence?.type === "active") {
      setIsSessionIdle({
        idle: false,
        socket: wsSessions.readyState === 1 ? true : false,
      });
      wsSessions.send(
        JSON.stringify({
          request: "isIdle",
          body: {
            userId: userConfiguration?.identityId,
            sessionId: sessionId,
            isIdle: false,
          },
        })
      );
    }
  } catch (e) {
    console.error("Error sending idle status", e);
  }
}
