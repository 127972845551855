import { Button, Tooltip } from "antd";
import "./ViewSignature.scss";
import { DrawIcon } from "../../../../pages/ScheduleProgress/assets/images";
import { MondayButton } from "../../..";

const ViewSignature = ({
  setWhichSignToChange,
  setSignatureModalVisible,
  setEditSignIn,
  signature,
  setSignature,
  existingSignature,
  signDisabled,
  removeDisabled,
  signButtonName,
  viewClassName,
}) => {
  return (
    <div className={`viewSignature-container ${viewClassName}`}>
      <div className="signatureBox">
        {signature ? <img src={signature} alt="" /> : ""}
      </div>
      <div className="signatureButtons">
        {!signature && (
          <MondayButton
            onClick={() => {
              setWhichSignToChange({ setFunc: setSignature });
              setSignatureModalVisible(true);
            }}
            disabled={signDisabled || !!signature}
            // type="primary"
            className="mondayButtonBlue"
            Icon={<DrawIcon />}
          >
            {signButtonName}
          </MondayButton>
        )}
        {!!signature && (
          <Button
            primary
            onClick={() => {
              setWhichSignToChange({ setFunc: setSignature });
              setEditSignIn(true);
              setSignatureModalVisible(true);
            }}
          >
            Edit Signature
          </Button>
        )}
        <Button
          danger
          disabled={removeDisabled || !!!signature}
          onClick={() => {
            setSignature(null);
            setEditSignIn(false);
          }}
        >
          Remove Signature
        </Button>

        {!!existingSignature && (
          <Tooltip title={!!signature ? "There is no existing signature" : ""}>
            <Button
              onClick={() => setSignature(existingSignature)}
              disabled={!!signature}
              type="primary"
            >
              Use existing
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ViewSignature;
