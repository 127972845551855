import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { driveApi } from "../../../../../../../../../../../integrations/DriveRequest";

let date = new Date().toLocaleDateString();

export const documentDefinition = (fakePayment) => {
  return {
    content: [
      {
        text: "Payment Details",
        style: "header",
      },
      {
        text: "Payment Information:",
        style: "subheader",
      },
      {
        ul: [
          `Payment ID: ${fakePayment.paymentId}`,
          `Payment Status: ${fakePayment.paymentStatus}`,
          // Add more payment-related information as needed
        ],
      },
      {
        text: "Invoices:",
        style: "subheader",
      },
      {
        ul: fakePayment.invoices.map((invoice) => {
          return `Invoice Number: ${invoice.invoiceNumber}, Amount Due: ${invoice.invoiceAmountDue}`;
        }),
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
    },
  };
};

export const savePdfToDrive = async ({
  docDefinition,
  folderId,
  accessToken,
  title,
}) => {
  return new Promise((resolve, reject) => {
    const driveRequest = driveApi({ accessToken });
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64(async (data) => {
      pdfDocGenerator.getBlob(async (blob) => {
        return await driveRequest
          .uploadFile(
            data,
            blob.type,
            {
              name: title,
              parents: [folderId],
              mimeType: blob.type,
            },
            true
          )
          .then(async (res) => {
            let { id, name } = await res.json().then(({ id, name }) => ({
              id,
              name,
            }));
            return resolve({ id, name });
          })
          .catch((e) => console.log("ERROR: ", e));
      });
    });
  });
};

export const docDefinition = (title, dynamicPdf, companyLogo) => {
  //   console.log("DYNAMIC PDF: ", dynamicPdf);

  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
          {
            text: `Date: ${date}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
  </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },

      dynamicPdf?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
    },
  };
};
