export const renderServices = ({
  categoryFrom,
  whereToFind,
  selectedGroup,
  currentCharge,
}) => {
  if (categoryFrom === "Estimation") {
    return whereToFind?.services[selectedGroup]?.filter(
      (service) =>
        service.serviceOptions?.[0]?.length > 0 ||
        service.serviceAddons?.length > 0
    );
  }

  if (categoryFrom === "Schedule Of Value") {
    return whereToFind?.services.filter((service) => {
      const isCurrentCharge = currentCharge
        ? currentCharge.chargeItems.find(
            (charge) =>
              charge.label === service.label &&
              charge.estimationId === service.estimationId
          )
        : true;

      if (service.label === "Hoist" || (service.isHoist && isCurrentCharge)) {
        return service.serviceOptions
          ?.flatMap((serviceOption) =>
            serviceOption.map((option) => option.amounts?.length > 0)
          )
          .includes(true);
      }
      return service.amounts?.length > 0 && isCurrentCharge;
    });
  }

  return whereToFind?.services;
};
