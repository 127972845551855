import { Form, Popover } from "antd";
import { useSelector } from "react-redux";
import { WithTooltip } from "../../../../commonComponents";

const LabeledInput = ({
  label,
  formItemName,
  initialValue,
  noFormItem = false,
  className = "",
  style = {},
  required = false,
  rules = [],
  children,
  popoverVisible = false,
  popoverTitle = "",
  popoverContent,
  popoverPlacement,
  popoverClassName = "",
  popoverStyle = {},
  valuePropName,
  tooltipCategory,
  tooltipKey,
  ...rest
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const inputToReturn = !noFormItem ? (
    popoverVisible ? (
      <Popover
        open={popoverVisible}
        title={popoverTitle}
        content={popoverContent}
        placement={popoverPlacement}
        overlayClassName={popoverClassName}
        overlayStyle={popoverStyle}
      >
        <Form.Item
          initialValue={initialValue}
          name={formItemName}
          valuePropName={valuePropName}
          rules={[
            ...rules,
            required && {
              required: true,
              message: `*${!!label ? label : "This field"} is required!`,
            },
          ]?.filter((item) => !!item)}
          {...rest}
          shouldUpdate={(prevValues, curValues) =>
            prevValues.billAmount !== curValues.billAmount
          }
        >
          {children}
        </Form.Item>
      </Popover>
    ) : (
      <Form.Item
        initialValue={initialValue}
        name={formItemName}
        rules={[
          ...rules,
          required && {
            required: true,
            message: `*${!!label ? label : "This field"} is required!`,
          },
        ]?.filter((item) => !!item)}
        valuePropName={valuePropName}
        {...rest}
        shouldUpdate={(prevValues, curValues) =>
          prevValues.billAmount !== curValues.billAmount
        }
      >
        {children}
      </Form.Item>
    )
  ) : (
    children
  );

  return (
    <div
      className={`labeledInputContainer ${className} ${
        isDarkMode ? "dark-mode" : ""
      }`.trim()}
      style={{ ...style }}
    >
      {label && (
        <div
          style={{ color: isDarkMode ? "#fff" : "#000" }}
          className="labeledInputLabel"
        >
          {required && !!label && <span style={{ color: "red" }}>*</span>}
          {label}
        </div>
      )}
      {!!tooltipKey ? (
        <WithTooltip {...{ tooltipCategory, tooltipKey }}>
          {inputToReturn}
        </WithTooltip>
      ) : (
        inputToReturn
      )}
    </div>
  );
};

export default LabeledInput;
