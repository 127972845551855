export const changeBodyOrderAndFilter = (data, columnsToInclude) => {
  const updatedData = [];

  data.forEach((row) => {
    const singleRow = [];

    columnsToInclude.forEach((col) => {
      singleRow.push(row[col]);
    });

    updatedData.push(singleRow);
  });

  return updatedData;
};
