import { useSelector } from "react-redux";
import ExportOrEmailDropdown from "src/components/commonComponents/ExportOrEmailDropdown/ExportOrEmailDropdown";
import XLSX from "sheetjs-style";
import { generateDocDefinition } from "../../../../utils/generateDocDefinition";
import { excelTableHeaders, tableHeaders } from "./constants/tableHeaders";
import createPDF from "../../../../integrations/createPDF";
import useExportHeadersModal from "../../../../hooks/useExportHeadersModal";
import PdfHeadersModal from "../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { defaultExcelBodyStyle } from "../../../../helpers/constants/defaultExcelBodyStyle";
import { excelCellFitToColumn } from "../../../../utils/excelCellFitToColumn";
import { exportExcel } from "../../../../utils/exportExcel";

const getExcelRows = (subcategories) => {
  const rows = subcategories.videos.map((video) => {
    return [
      { v: video.lengthVideoInMin, s: defaultExcelBodyStyle },
      {
        t: "s",
        v: video.videoLink,
        s: defaultExcelBodyStyle,
        l: { Target: video.videoLink, Tooltip: "Click to open" },
      },
      { v: video.videoName, s: defaultExcelBodyStyle },
      { v: subcategories.subCategoryName, s: defaultExcelBodyStyle },
    ];
  });

  return rows;
};

const ExportActions = ({ item: subcategories }) => {
  const { base64 } = useSelector((state) => state.base64);

  const onGeneratePDF = (action, getDocDefinition = false, headersData) => {
    const columnsToInclude =
      headersData || tableHeaders.map((item, index) => index);

    const tableData = subcategories?.videos?.map((video) => {
      return {
        lengthVideoInMin: `${video.lengthVideoInMin}`,
        videoLink: {
          text: {
            bold: true,
            color: "#1264A3",
            text: `${video.videoName} - Watch Video`,
            link: video.videoLink,
          },
          link: { url: video.videoLink, target: "_blank" },
        },

        ideoName: video.videoName,
        subcategories: subcategories.subCategoryName,
      };
    });

    const docDefinition = generateDocDefinition(
      base64,
      ` Video Tutorials / ${subcategories.subCategoryName}`,
      columnsToInclude,
      tableHeaders,
      tableData.map(Object.values)
    );

    if (getDocDefinition) return docDefinition;

    createPDF({
      action,
      docDefinition,
      title: action === "print" ? false : "VideoTutorials",
    });
  };

  const exportToExcel = (getDocDefinition = false, headersData) => {
    if (getDocDefinition) return onGeneratePDF("download", getDocDefinition);

    const columnsToInclude =
      headersData || excelTableHeaders.map((item, index) => index);

    const rows = getExcelRows(subcategories);

    exportExcel(
      excelTableHeaders,
      columnsToInclude,
      rows,
      "Sheet1",
      "VideoTutorials"
    );
  };

  const uploadExcelToDrive = async (driveRequest, driveFolderId) => {
    try {
      const headers = excelTableHeaders;

      const rows = getExcelRows(subcategories);

      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);

      worksheet["!cols"] = excelCellFitToColumn([headers, ...rows]);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Convert the workbook to an Excel binary array
      const excelArrayBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Convert the array buffer to a blob with the correct MIME type
      const excelBlob = new Blob([excelArrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Upload the Excel file to Google Drive
      const uploadResponse = await driveRequest.uploadExcelFile(
        excelBlob,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        {
          name: "VideoTutorials.xlsx",
          parents: [driveFolderId],
        }
      );

      // Extract and log information about the uploaded file
      const { id, newName } = await uploadResponse.json();

      // Update state or perform any necessary actions with the file ID
      return { id, name: newName };
    } catch (error) {
      console.error("Error uploading Excel file to Google Drive:", error);
    }
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData: false,
      onGeneratePDF,
      exportToExcel,
    });

  return (
    <div>
      <ExportOrEmailDropdown
        {...{
          rowData: subcategories,
          onGeneratePDF: (action, getDocDefinition) =>
            getDocDefinition
              ? onGeneratePDF(action, getDocDefinition)
              : getHeadersModal(action, getDocDefinition),
          exportGridToExcel: (getDocDefinition) =>
            getDocDefinition
              ? exportToExcel(getDocDefinition)
              : getHeadersModal(false, getDocDefinition, "excel"),
          uploadExcelToDrive: (driveRequest, driveFolderId) =>
            uploadExcelToDrive(driveRequest, driveFolderId),
          defaultDocDefinition: true,
          title: "VideoTutorials",
          position: "fixed",
        }}
      />
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: tableHeaders,
            title: "Video Tutorials",
          }}
        />
      )}
    </div>
  );
};

export default ExportActions;
