import dayjs from "dayjs";
import { onClickFormHandler } from "../../../../utils";
import { trim } from "lodash";
import { getAddressComponent } from "../../../SidebarPages/Fleet/utils/addressParseHelpers";
import { Tooltip } from "antd";

export const OPPORTUNITY_INFORMATION = "Opportunity Information";
export const PROJECT_INFORMATION = "Project Information";
export const JOBSITE_ADDRESS = "Jobsite Address";
const notAllowedStages = ["Canceled", "Awarded", "Awarded Change Order"];

export const footerButtons = (
  onCancel,
  onSave,
  onOptionClick,
  suffix,
  saving,
  onFinish,
  estimationChangeOrder,
  tickIcon
) => {
  const onClick = onClickFormHandler(saving);

  return [
    {
      text: "Cancel",
      cancel: true,
      onClick: onCancel,
      className: "mondayButtonRed",
      tooltipCategory: "Opportunities",
      tooltipKey: "cancel",
    },
    !estimationChangeOrder
      ? {
          text: "Finish",
          primary: true,
          onClick: onClick(onSave),
          options: ["Save & New", "Save & Close"],
          onOptionClick: onClick(onOptionClick),
          suffix: suffix,
          className: "mondayButtonBlue saveButton",
          tooltipCategory: "Opportunities",
          tooltipKey: "finish",
        }
      : {
          text: "Proceed",
          primary: true,
          onClick: onClick(onFinish),
          dropdownDisabled: true,
          className: "mondayButtonBlue saveButton",
          suffix: tickIcon,
          tooltipCategory: "Opportunities",
          tooltipKey: "proceed",
        },
  ];
};

export const fieldsJSON = ({
  form,
  onSelect,
  accountName = "",
  uploadFile,
  setUploadFile,
  requiredBidDueDate,
  setRequiredBidDueDate,
  authenticatedUser,
  users,
  projectExecutives,
  projectManagersOptions,
  clients,
  leads,
  onDeselect,
  opportunityStages = [],
  accessToken,
  onPickerSuccess,
  fileNames,
  parentId,
  typesOfWork,
  taxRatePopOverVisible,
  constructionServices,
  sources,
  oppurtunityTeam,
  fetchedOpportunities,
  onTaxExemptSelect,
  alternativeAddressesVisible,
  AlternativeAddressesTitle,
  AlternativeAddressesComp,
  typesOfWorkKeys,
  setTypesOfWorkKeys,
  constructionServicesKeys,
  setConstructionServicesKeys,
  contacts,
  isContactDisabled,
  setSelectedCompanyName,
  setWorkDetail,
  setBuildingDimensions,
  recordTypes,
  salesPerson,
  taxExemptOptions,
  setOpportunityAddress,
  workDetail,
  buildingDimensions,
  companyNameDropdown,
  laborTypes,
  selectedPrimaryContact,
  setSelectedPrimaryContact,
  contactsDropDown,
  changeOrderData,
  insuranceTypes,
  setDocumentationVisible,
  isDarkMode,
  checkAccess,
}) => {
  return {
    type: {
      formItemName: "opportunityType",
      type: "radio",
      options: recordTypes,
    },
    [OPPORTUNITY_INFORMATION]: [
      {
        label: "Opportunity Name",
        formItemName: "opportunityName",
        placeholder: "Enter opportunity name...",
        style: { width: "100%", maxWidth: 444 },
      },
      {
        className: "opportunityInformation__Company",
        label: "Company Name",
        formItemName: "accountName",
        placeholder: "Select company name...",
        type: "select",
        showSearch: true,
        required: true,
        disabled: !!accountName || !!changeOrderData,
        style: { width: "100%", maxWidth: 313 },
        rules: [
          {
            validator: (_, value) => {
              return (!!accountName || !!changeOrderData) &&
                (clients?.some(
                  (c) => c.accountId === value || c.accountName === value
                ) ||
                  leads?.some(
                    (l) => l.leadId === value || l.leadCompany === value
                  ))
                ? Promise.reject(new Error("This Client/Lead does not exist"))
                : Promise.resolve();
            },
          },
        ],
        onSelect: (value) => {
          setSelectedCompanyName(value);
          form.resetFields(["primaryContact", "contacts"]);
        },
        dropdownRender: companyNameDropdown,
        customOptions: [
          !!checkAccess.clientAccess
            ? { label: "Clients", options: clients }
            : {
                label: "Clients - No Access",
                options: [],
              },
          !!checkAccess.leadAccess
            ? { label: "Leads", options: leads }
            : {
                label: "Leads - No Access",
                options: [],
              },
        ],
      },
      {
        label: "Primary Contact",
        formItemName: "primaryContact",
        placeholder: "Select primary contact...",
        type: "select",
        showSearch: true,
        required: true,
        disabled: isContactDisabled,
        dropdownRender: contactsDropDown,
        style: { width: "100%", maxWidth: 249 },
        customOptions: contacts.map(
          ({
            contactId,
            contactFirstName = "",
            contactLastName = "",
            contactEmail = "",
            contactRole = "",
          }) => ({
            value: contactId,
            label: trim(
              `${contactFirstName} ${contactLastName} - ${contactEmail} - ${contactRole}`,
              " -"
            ),
          })
        ),
        onSelect: (contact) => setSelectedPrimaryContact(contact),
      },
      {
        label: "Contacts",
        formItemName: "contacts",
        placeholder: "Select contacts...",
        type: "select",
        showSearch: true,
        mode: "multiple",
        dropdownRender: contactsDropDown,
        disabled: isContactDisabled || !selectedPrimaryContact,
        customOptions: contacts
          .filter(({ contactId }) => contactId !== selectedPrimaryContact)
          .map(
            ({
              contactId,
              contactFirstName = "",
              contactLastName = "",
              contactEmail = "",
              contactRole = "",
            }) => ({
              value: contactId,
              label: trim(
                `${contactFirstName} ${contactLastName} - ${contactEmail} - ${contactRole}`,
                " -"
              ),
            })
          ),
        style: { width: "100%", maxWidth: 508 },
      },
      !changeOrderData && {
        label: "Opportunity Stage",
        formItemName: "opportunityStage",
        placeholder: "Select...",
        initialValue: "New - Not yet started",
        type: "select",
        showSearch: true,
        required: true,
        style: { width: "100%", maxWidth: 250 },
        options: opportunityStages.filter(
          (stage) => !notAllowedStages.includes(stage)
        ),
      },
      !changeOrderData && {
        label: "Opportunity Source",
        formItemName: "opportunitySource",
        placeholder: "Select...",
        type: "autocomplete",
        options: sources,
        style: { width: "100%", maxWidth: 250 },
      },
      {
        label: "Labor Requirement",
        formItemName: "laborRequirement",
        placeholder: "Select labor requirement...",
        type: "select",
        showSearch: true,
        initialValue: "Standard Labor",
        options: laborTypes,
        style: { width: "100%", maxWidth: 250 },
      },
      {
        label: "Insurance Requirement",
        placeholder: "Select insurance requirement...",
        formItemName: "insuranceRequirement",
        type: "select",
        showSearch: true,
        options: insuranceTypes,
        style: { width: "100%", maxWidth: 249 },
      },
      {
        label: "Tax Exempt",
        formItemName: "taxExempt",
        placeholder: "Select...",
        initialValue: "Yes",
        type: "select",
        onSelect: onTaxExemptSelect,
        showSearch: true,
        options: taxExemptOptions,
        style: { width: "100%", maxWidth: 175 },
      },
      {
        label: "Tax Rate",
        formItemName: "taxRate",
        initialValue: "0",
        addonAfter: "%",
        type: "input",
        disabled: true,
        popoverVisible: taxRatePopOverVisible,
        popoverPlacement: "bottom",
        popoverContent: "Only Rental Tax",
        style: { width: "100%", maxWidth: 326 },
      },

      {
        label: "Project Executive",
        formItemName: "projectExecutive",
        placeholder: "Select...",
        type: "select",
        required: true,
        showSearch: true,
        options: projectExecutives,
        style: { width: "100%", maxWidth: 378.5 },
      },
      {
        label: "Project Managers",
        formItemName: "projectManager",
        placeholder: "Select Project Manager...",
        type: "select",
        mode: "multiple",
        // required: true,
        showSearch: true,
        customOptions: projectManagersOptions,
        style: { width: "100%", maxWidth: 378.5 },
        onChange: (val, option) => {
          const newOption = option?.filter((o) => !!Object.keys(o).length);
          // const { label, value, ...rest } = newOption;
          form.setFieldValue("projectManager", newOption);
        },
        maxTagCount: 2,
        maxTagPlaceholder: (e) => {
          return (
            <Tooltip title={e.map(({ label }) => label).join(", ")}>
              <span>{`+ ${e.length} more`}</span>
            </Tooltip>
          );
        },
      },
      {
        label: "Sales Person",
        formItemName: "salesPerson",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        mode: "multiple",
        options: salesPerson,
        style: { width: "100%", maxWidth: 378.5 },
      },
      {
        label: "Team",
        formItemName: "oppurtunityTeam",
        placeholder: "Select team",
        type: "select",
        customOptions: oppurtunityTeam,
        mode: "multiple",
        onSelect: onSelect,
        onDeselect: onDeselect,
        style: { width: "100%", maxWidth: 378.5 },
        required: true,
        allowClear: false,
        maxTagCount: 2,
        maxTagPlaceholder: (e) => {
          return (
            <Tooltip title={e.map(({ label }) => label).join(", ")}>
              <span>{`+ ${e.length} more`}</span>
            </Tooltip>
          );
        },
      },
    ].filter(Boolean),
    [JOBSITE_ADDRESS]: {
      main: [
        {
          className: "opportunityAddress",
          label: "Jobsite Address",
          formItemName: "opportunityAddress",
          placeholder: "Enter jobsite address...",
          onSelect: async (value) => {
            setOpportunityAddress(value);
            getAddressComponent(value, false, "").then((res) => {
              form.setFieldValue("opportunityAddCity", res.city);
              form.setFieldValue("opportunityAddState", res.state);
              form.setFieldValue("opportunityAddStreet", res.street);
              form.setFieldValue("opportunityAddress", res.address);
              form.setFieldValue("opportunityAddPostalCode", res.zipCode);
            });
          },
          required: true,
          type: "placesautocomplete",
          style: { minWidth: 305 },
          disabled: !!changeOrderData,
          existingValues: fetchedOpportunities?.map(
            ({ opportunityAddress = "", accountName = "" }) => ({
              value: opportunityAddress,
              label: `${opportunityAddress} - ${accountName}`,
              disabled: true,
            })
          ),
          form,
          popoverVisible: alternativeAddressesVisible,
          popoverTitle: AlternativeAddressesTitle,
          popoverContent: AlternativeAddressesComp,
          popoverClassName: `alternativeAddressesContainer ${
            isDarkMode && "alternativeAddressesContainerDark"
          }`,
          popoverPlacement: "bottomRight",
          isCustomValue: true,
          style: { width: "100%", maxWidth: 420 },
        },
        {
          label: "Street",
          placeholder: "Enter street...",
          formItemName: "opportunityAddStreet",
          disabled: true,
          style: { minWidth: 305 },
        },
        {
          label: "City",
          placeholder: "Enter city...",
          formItemName: "opportunityAddCity",
          disabled: true,
          style: { width: "100%", maxWidth: 220 },
        },
      ],
      split: [
        {
          label: "State",
          placeholder: "Enter state...",
          formItemName: "opportunityAddState",
          disabled: true,
          style: { width: "100%", maxWidth: 220 },
        },
        {
          label: "Postal Code",
          placeholder: "Enter postal code...",
          formItemName: "opportunityAddPostalCode",
          disabled: true,
          style: { width: "100%", maxWidth: 170 },
        },
      ],
    },
    [PROJECT_INFORMATION]: {
      dates: [
        requiredBidDueDate
          ? {
              label: "Bid Due Date",
              formItemName: "bidDueDate",
              type: "datepicker",
              required: requiredBidDueDate,
              rules: [
                {
                  validator: (_, value) =>
                    !!value && value <= form.getFieldValue("proposalStartDate")
                      ? Promise.reject(
                          new Error(
                            "Cannot be earlier than Proposed Start Date"
                          )
                        )
                      : Promise.resolve(),
                },
              ],
              style: { width: "100%", maxWidth: 190 },
            }
          : {
              label: "Bid Due Date",
              formItemName: "bidDueDate",
              disabled: requiredBidDueDate,
              type: "input",
              value: "N/A",
              placeholder: "N/A",
              style: { width: "100%", maxWidth: 190 },
            },
        {
          label: "Proposed Start Date",
          formItemName: "proposalStartDate",
          type: "datepicker",
          style: { width: "100%", maxWidth: 190 },
        },
        {
          label: "Request Received Date",
          formItemName: "requestReceivedDate",
          type: "datepicker",
          required: true,
          style: { width: "100%", maxWidth: 210 },
          disabledDate: (current) =>
            current && dayjs(current).isAfter(dayjs().endOf("day")),
        },
      ],

      checkboxesAndUploads: [
        {
          label: "Scope Sheet:",
          type: "gPicker",
          accessToken,
          uploaderId: "Opportunity Scope Sheet",
          onPickerSuccess,
          fileNames,
          parentId,
          style: uploadFile ? {} : { display: "none" },
          required: uploadFile,
        },
      ],

      transfers: [
        {
          label: "Proposed Type Of Work",
          formItemName: "proposedTypeOfWork",
          type: "transfer",
          dataSource: typesOfWork,
          targetKeys: typesOfWorkKeys,
          setTargetKeys: setTypesOfWorkKeys,
          showSearch: true,
          filterOption: (inputValue, option) =>
            option?.title?.toLowerCase()?.includes(inputValue?.toLowerCase()),
          style: { minWidth: "50%" },
          className: "transferComp",
        },
      ],
      textEditors: [
        {
          label: "Work Detail",
          type: "textEditor",
          value: workDetail,
          onChange: (value) => setWorkDetail(value),
          style: { width: "50%", paddingRight: 15 },
        },
      ],
    },
  };
};

export const body = ({
  form,
  typesOfWorkKeys,
  constructionServicesKeys,
  zipCode,
  accountId,
  leadId,
  googleDriveFolderIds,
  googleDriveUploads,
  buildingDimensions,
  workDetail,
  opportunityLatitude,
  opportunityLongitude,
  opportunityPlaceId,
  accountName,
  clients,
  leads,
}) => {
  const formValues = form.getFieldsValue();
  return {
    ...formValues,
    accountName:
      clients?.find((client) => client.accountId === formValues?.accountName)
        ?.accountName ||
      leads?.find((lead) => lead.leadId === formValues?.accountName)
        ?.leadCompany,
    opportunityAddress: form
      .getFieldValue("opportunityAddress")
      .replace(", USA", ""),
    taxRate: parseFloat(form.getFieldValue("taxRate")) / 100,
    proposalStartDate: !!form.getFieldValue("proposalStartDate")
      ? dayjs(form.getFieldValue("proposalStartDate")).valueOf()
      : undefined,
    bidDueDate: !!form.getFieldValue("bidDueDate")
      ? dayjs(form.getFieldValue("bidDueDate")).valueOf()
      : undefined,
    requestReceivedDate:
      dayjs(form.getFieldValue("requestReceivedDate")).valueOf() || undefined,
    opportunityType: form.getFieldValue("opportunityType") || "",
    buildingDimensions: "",
    workDetail,
    proposedConstructionServices: [],
    opportunityStatus: "Not Converted",
    proposedTypeOfWork: typesOfWorkKeys,
    googleDriveUploads,
    projectSiteZip: zipCode,
    googleDriveFolderIds,
    accountId,
    leadId,
    opportunityLatitude,
    opportunityLongitude,
    opportunityPlaceId,
  };
};
