/**
 * Handles the selection of an incident address.
 *
 * @param {string} [val=""] - The selected value.
 * @param {string} [type=""] - The type of selection.
 * @param {boolean} [clear=false] - Indicates if the selection should be cleared.
 * @param {Array} [projects=[]] - The list of projects.
 * @param {Array} [schedules=[]] - The list of schedules.
 * @param {string} [recordName=""] - The name of the record.
 * @param {Function} [setFieldsValue=() => {}] - The function to set field values.
 * @param {Function} [dispatchRequestData=() => {}] - The function to dispatch request data.
 */
const onIncidentAddressSelect = ({
  val = "",
  type = "",
  clear = false,
  projects = [],
  schedules = [],
  recordName = "",
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (clear === true) {
    setFieldsValue({ safetyReport: "", team: [], projectManager: [] });
    dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: [],
    });
  } else {
    let projectId,
      accountId,
      scheduleId,
      teamsConfiguration,
      projectManagers,
      projectManager;

    if (type === "schedule") {
      const foundSchedule = schedules.find((el) => el.scheduleId === val) || {};

      ({
        projectId,
        accountId,
        scheduleId,
        teamsConfiguration,
        projectManagers,
      } = foundSchedule);
    } else if (type === "project") {
      const foundProject =
        (projects || [])?.find((el) => el.projectId === val) || {};

      ({
        projectId,
        accountId,
        scheduleId,
        teamsConfiguration,
        projectManager,
      } = foundProject);
    }

    const payload = {
      projectId,
      accountId,
      scheduleId,
      projectManager,
      recordId: val,
    };

    if (recordName) {
      Object.assign(payload, { recordName: recordName });
    }

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload,
    });

    if (!!teamsConfiguration?.length) {
      dispatchRequestData({
        type: "SELECTED_TEAM",
        payload: teamsConfiguration.map((el) => el.value),
      });
      setFieldsValue({
        team: teamsConfiguration.map((el) => el.value),
      });
    }

    setFieldsValue({
      projectManager: projectManagers || projectManager,
    });
  }
};

export default onIncidentAddressSelect;
