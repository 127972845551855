import React, { useCallback, useEffect, useState } from "react";
import "./SchedulingProgressTrack.scss";
import { Alert, Card, message, Modal, App } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { AgGridReact } from "ag-grid-react";
import {
  columnDefsForProgressTracking,
  convertCamelCaseToTitleCase,
} from "../../SchedulingFirstPage/helperData";
import { FilterIcon } from "../../../../../SidebarPages/BasePage/src";
import SchedulingProgressFilters from "./SchedulingProgressFilters";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { ExcelIconWhite } from "../../../../../../assets";
import ProgressCrews from "./ProgressCrews";
import TimelineProgress from "./TimelineProgress/TimelineProgress";
import { API } from "aws-amplify";

const SchedulingProgressTrack = ({
  openProgressLogs = false,
  onCancel = () => {},
  progressLogs = [],
  fetchFUNCTION = () => {},
  infoHeaderDisplayed = [],
  view = "",
}) => {
  const gridRef = React.useRef(null);
  const { notification } = App.useApp();
  const [timeline, setTimeline] = useState(false);
  const [progressFilters, setProgressFilters] = useState({});
  const [filteredData, setFilteredData] = useState({
    prev: [],
    curr: [],
    unchangedPrev: [],
  });
  const [viewCrews, setViewCrews] = useState({ visible: false, crews: {} });
  const [dataFetched, setDataFetched] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const inputOptions = (rowData, keys) => {
    if (!rowData || !rowData.length) return [];

    const keyArray = Array.isArray(keys) ? keys : [keys];

    const options = keyArray.flatMap((key) =>
      rowData.flatMap((item) => {
        const value = item?.[key];

        if (Array.isArray(value)) {
          return value.map((subValue) => ({
            label: subValue,
            value: subValue,
          }));
        }

        if (typeof value === "object") {
          return Object.values(value)
            .flat(1)
            .map((subValue) => ({
              label: subValue.crewName,
              value: subValue.crewName,
            }));
        }
        return {
          label:
            key === "startDate" ? dayjsNY(value).format("MM/DD/YYYY") : value,
          value: value,
        };
      })
    );
    const uniqueOptions = Array.from(
      new Map(options?.map((item) => [item?.value, item])).values()
    )?.filter((item) => !!item?.value);

    return uniqueOptions;
  };

  const exportGridDataToExcel = useCallback(() => {
    if (!gridApi) return;
    const params = {
      fileName: "ProgressLogs",
      sheetName: "ProgressLogs",
      allColumns: true,
      skipHeader: false,
    };
    gridApi.exportDataAsExcel({
      ...params,
      processCellCallback: ({ column, value }) => {
        if (column?.colId === "crews") {
          return Object.keys(value || {}).join(", ");
        }
        return value;
      },
    });
  }, [gridApi]);

  const applyFilters = (filters = {}) => {
    if (Object.keys(filters).length === 0) {
      setFilteredData({
        prev: filteredData?.unchangedPrev || [],
        curr: progressLogs || [],
        unchangedPrev: filteredData?.unchangedPrev || [],
      });
      setProgressFilters({ filters, visible: false });
      return;
    }
    const filterItems = (items = []) => {
      if (!items.length || !Object.keys(filters).length) return items;
      return items.filter((item) => {
        return Object.entries(filters).every(([key, value]) => {
          if (key === "startDate" || key === "progressTimeGiven") {
            return (
              dayjsNY(item?.[key]).format("MM/DD/YYYY") ===
              dayjsNY(value).format("MM/DD/YYYY")
            );
          }

          const itemValue = item?.[key];
          if (Array.isArray(itemValue)) {
            return itemValue.includes(value);
          } else if (typeof itemValue === "string") {
            return itemValue.includes(value);
          } else if (typeof itemValue === "object") {
            return Object.values(itemValue)
              .flat(1)
              .some((subValue) => subValue.crewName === value);
          } else {
            return itemValue === value;
          }
        });
      });
    };

    const { unchangedPrev = [], curr = [] } = filteredData || {};

    const filteredPrev = filterItems(unchangedPrev);
    const filteredCurr = filterItems(curr);
    setFilteredData({
      prev: filteredPrev,
      curr: filteredCurr,
      unchangedPrev: unchangedPrev,
    });

    setProgressFilters({ ...progressFilters, visible: false });

    Object.keys(filters || {}).length &&
      Object.entries(filters || {})?.forEach?.(([key, value]) => {
        const title = convertCamelCaseToTitleCase(key);
        notification.info({
          className: "info__notification",
          message: `${title}`,
          description: `Filter applied for ${title} - ${value}`,
          duration: 8,
          placement: "bottomLeft",
        });
      });
  };

  useEffect(() => {
    if (openProgressLogs && !dataFetched) {
      const fetchProgressLogs = async () => {
        try {
          message.loading("Loading progress data...", 0);
          await fetchFUNCTION().then((res) => {
            setFilteredData({
              prev: res || [],
              curr: progressLogs || [],
              unchangedPrev: res || [],
            });
          });

          setDataFetched(true);
          message.destroy();
        } catch (e) {
          message.error("Error fetching progress logs!");
          console.error(e);
        }
      };

      fetchProgressLogs();
    } else if (!openProgressLogs) {
      setFilteredData({
        prev: filteredData?.prev || [],
        curr: progressLogs || [],
        unchangedPrev: filteredData?.unchangedPrev || [],
      });
    }
  }, [openProgressLogs, progressLogs, dataFetched]);

  return (
    <div>
      <Modal
        open={openProgressLogs}
        title="Progress Process"
        centered
        footer={
          <MondayButton
            onClick={onCancel}
            {...{ className: "mondayButtonRed", Icon: <XIcon /> }}
          >
            Close
          </MondayButton>
        }
        onCancel={onCancel}
        className="progress-track-modal"
        closeIcon={<XIcon />}
      >
        <>
          <div className="progress-track-content">
            <Alert
              className="progress-track-alert"
              type="info"
              showIcon
              message={
                <div className="progress-track-alert-content">
                  {typeof infoHeaderDisplayed === "string"
                    ? infoHeaderDisplayed
                    : infoHeaderDisplayed?.map((item) => {
                        return (
                          <div className="progress-track-alert-content-item">
                            <span>{item.field}</span>
                            <span>{item.value}</span>
                          </div>
                        );
                      }) || []}
                  <div style={{ display: "flex", gap: 10 }}>
                    {view === "allSchedules" && (
                      <MondayButton
                        {...{
                          onClick: () => setTimeline(true),
                          hasIcon: false,
                          className: "mondayButtonPrismarine",
                        }}
                      >
                        Progress Timeline
                      </MondayButton>
                    )}
                    <MondayButton
                      onClick={() =>
                        setProgressFilters({
                          ...progressFilters,
                          visible: true,
                        })
                      }
                      {...{
                        className: "mondayButtonBlue",
                        Icon: <FilterIcon />,
                      }}
                    >
                      Open Filters
                    </MondayButton>
                    {!!Object.keys(progressFilters?.filters || {})?.length && (
                      <MondayButton
                        {...{
                          className: "mondayButtonRed",
                          Icon: <XIcon />,
                          onClick: () => applyFilters({}),
                        }}
                      >
                        Clear Filters
                      </MondayButton>
                    )}
                    <MondayButton
                      {...{
                        className: "mondayButtonBlue",
                        Icon: <ExcelIconWhite />,
                        onClick: exportGridDataToExcel,
                      }}
                    >
                      Export to Excel
                    </MondayButton>
                  </div>
                </div>
              }
            />
          </div>
          <div
            className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
            style={{ width: "100%", height: "93%", marginTop: 10 }}
          >
            <AgGridReact
              {...{
                ref: gridRef,
                onGridReady,
                columnDefs: columnDefsForProgressTracking(view, setViewCrews),
                rowData:
                  [
                    ...(filteredData?.prev || []),
                    ...(filteredData?.curr || []),
                  ] || [],
                animateRows: true,
                suppressDragLeaveHidesColumns: true,
                suppressRowClickSelection: true,
                rowGroupPanelShow: "always",
                pivotPanelShow: "always",
                masterDetail: true,
                groupHeaderHeight: 32,
                floatingFiltersHeight: 20,
                pivotHeaderHeight: 32,
                pivotGroupHeaderHeight: 32,
                defaultColDef: {
                  resizable: true,
                  enablePivot: true,
                  enableColResize: true,
                  enableRowGroup: true,
                  editable: false,
                  sortable: true,
                  width: "fit-content",
                  filter: true,
                  pivot: true,
                  enableRowGroup: true,
                  enableValue: true,
                  enableCellChangeFlash: true,
                },
              }}
            />
          </div>
        </>
      </Modal>
      {progressFilters?.visible && (
        <SchedulingProgressFilters
          {...{
            progressFilters,
            setProgressFilters,
            progressLogs: [
              ...(filteredData?.unchangedPrev || []),
              ...(filteredData?.curr || []),
            ],
            applyFilters,
            inputOptions,
          }}
        />
      )}
      {!!viewCrews?.visible && (
        <ProgressCrews
          {...{
            viewCrews,
            onCancel: () => setViewCrews({ visible: false, crews: {} }),
          }}
        />
      )}
      {!!timeline && (
        <TimelineProgress
          {...{
            timeline,
            onCancel: () => setTimeline(false),
            rowData:
              [...(filteredData?.prev || []), ...(filteredData?.curr || [])] ||
              [],
            inputOptions,
          }}
        />
      )}
    </div>
  );
};

export default SchedulingProgressTrack;
