import { Fragment } from "react";
import { Tooltip } from "antd";

import {
  SpeedIcon,
  OdometerIcon,
  DirectionsIcon,
  GasStationIcon,
} from "../../../../../../../../assets";
import { formatNumber } from "../../../../../../utils";
import { FleetWhite } from "../../../../../../../../icons";

import "./LocationInfo.scss";

function LocationInfo({
  location,
  footer = null,
  onNameClick = () => {},
  nameTooltip = null,
  onInfoClick = () => {},
  isDarkMode = false,
  id = undefined,
  className = "",
  closable = false,
  onClose = () => {},
}) {
  return (
    <div
      className={`location-info ${
        isDarkMode ? "location-info-dark" : ""
      } ${className}`}
      onClick={() => {
        onInfoClick(location);
      }}
      id={id}
    >
      <div className="location-header">
        {nameTooltip ? (
          <Tooltip title={nameTooltip} trigger={"hover"} align={"top"}>
            <div className="name-section" onClick={onNameClick}>
              <FleetWhite
                height={24}
                width={24}
                fill={isDarkMode ? "#fff" : "#000"}
              />
              {location?.fleetName}
            </div>
          </Tooltip>
        ) : (
          <div className="name-section" onClick={onNameClick}>
            <FleetWhite
              height={24}
              width={24}
              fill={isDarkMode ? "#fff" : "#000"}
            />
            {location?.fleetName}
          </div>
        )}
        {location?.locationStatus && (
          <div
            className={`status-section ${(
              location?.locationStatus || ""
            )?.replaceAll(" ", "")}`}
          >
            <span>{location?.locationStatus}</span>
            <span
              className={`status-circle ${location?.locationStatus}`}
            ></span>
          </div>
        )}
        {closable && (
          <div
            className="location-info-close"
            onClick={() => {
              onClose(location);
            }}
          >
            x
          </div>
        )}
      </div>
      {!nameTooltip && (
        <Fragment>
          <div className="location-vin">{location?.vinNumber}</div>
          <div className="location-body">
            <div className="info-data">
              <DirectionsIcon height={16} width={16} />
              <span className="info-title">Direction:</span>
              <span className="info">{location?.heading}</span>
            </div>
            <div className="info-data">
              <GasStationIcon height={16} width={16} />
              <span className="info-title">Fuel level:</span>
              <span className="info">{location?.fuelLevel}</span>
            </div>
            <div className="info-data">
              <SpeedIcon height={16} width={16} />
              <span className="info-title">Speed:</span>
              <span className="info">
                {formatNumber(location?.speed, {
                  unit: "mile-per-hour",
                  fallback: "--",
                })}
              </span>
            </div>
            <div className="info-data">
              <OdometerIcon height={20} width={20} />
              <span className="info-title">Odometer:</span>
              <span className="info">
                {formatNumber(location?.odometerReading, {
                  fixed: 0,
                  fallback: "--",
                })}
              </span>
            </div>
          </div>
        </Fragment>
      )}
      {footer && <div className="info-footer">{footer}</div>}
    </div>
  );
}

export default LocationInfo;
