// Function used to replace {{key}} with its generated content
const getContent = (notification) => {
  const { template, topic, notificationBody, changes } = notification;

  return template?.replace(/\{\{(.*?)\}\}/g, function (i, matched) {
    let content;
    if (matched.includes("&ccedil;")) {
      if (topic === "19") {
        content =
          notificationBody?.notificationObject?.actionData?.[1]?.rowSelected?.[
            matched
          ] || matched;
      } else {
        matched = matched.replace("&ccedil;", "ç");
        content = changes[matched] || matched;
      }
    } else {
      if (topic === "19") {
        content =
          notificationBody?.notificationObject?.actionData?.[1]?.rowSelected?.[
            matched
          ] || matched;
      } else {
        content = changes[matched] || matched;
      }
    }

    return `<span style="font-weight: 600; ">${content}</span>`;
  });
};

export default getContent;
