export const invoices = (
  programFields,
  updatedDynamicStates,
  invoicesData,
  IdHandler
) => {
  const invoiceStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    invoiceStatus: () => ({
      title: "Invoices - Invoice Status",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      id: IdHandler,
      BodyComponentParams: {
        table: "Invoices",
        field: "invoiceStatus",
        title: "Invoice Status",
        subtitle: "",
        content: invoiceStatus?.statusName?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (invoicesData?.filter((a) => a?.categoryFrom === status)?.length /
              invoicesData?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: invoicesData?.filter((a) => a?.categoryFrom === status)?.length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
