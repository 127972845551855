import React from "react";
import { useLocation } from "react-router-dom";
import lazyRetry from "../utils/LazyRetry";
const NotFound = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NotFound"*/ "./SidebarPages/NotFound/NotFound"
      ),
    "NotFound"
  )
);
const AuthenticatedRoute = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AuthenticatedRoute" */ "./AuthenticatedRoute"
      ),
    "AuthenticatedRoute"
  )
);

const RouteWrapper = ({
  pref,
  routesElements,
  component,
  path,
  exact,
  childProps,
}) => {
  //fix 404 flicker on first load
  const isAccessible = Object.keys(routesElements || {})?.length
    ? !!routesElements[path]
      ? routesElements[path]
      : null
    : undefined;
  const location = useLocation();
  return (
    <AuthenticatedRoute
      component={component}
      routeProps={{ exact }}
      isAccessible={isAccessible}
      props={{
        preferences:
          pref?.preferences?.views &&
          location.pathname
            .split("/")
            .slice(0, location.pathname.split("/").length - 1)
            .join("/") in pref?.preferences?.views
            ? pref?.preferences?.views[
                location.pathname
                  .split("/")
                  .slice(0, location.pathname.split("/").length - 1)
                  .join("/")
              ]
            : [],
        ...childProps,
        write: routesElements[path]?.write,
        delete: routesElements[path]?.delete,
        childAccess: routesElements[path]?.children || [],
      }}
    />
  );
};

export default RouteWrapper;
