import uniq from "lodash/uniq";

export const claims = ({ tables, programFields }) => {
  return {
    claimStatus: () => ({
      statusData: tables?.Claims?.map((a) => {
        return {
          label: a?.claimCategory,
          value: a?.claimStatus,
          color: programFields
            ?.find((a) => a?.fieldName === "Status colors")
            ?.fieldOptions?.Claims?.claimStatus?.find(
              (e) => e.statusName === a?.claimStatus
            )?.statusColor,
          date: a?.createdAt,
        };
      }),
    }),

    claimAddress: () => ({
      statusData: uniq(
        tables?.Claims?.map((a) => {
          return {
            label: a?.claimObject?.claimAddress,
            value: tables?.Claims?.filter(
              (e) =>
                e?.claimObject?.claimAddress === a?.claimObject?.claimAddress
            )?.length,
            color: "#f7f8fa",
            labelColor: "black",
          };
        })
      ),
    }),
  };
};
