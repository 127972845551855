import { message } from "antd";
import { formatCurrency } from "../../../../../../utils";
import { cellRenders } from "../../ChargeInformation/AgGridData/components/cellRenders";
export const columnDefs = ({ setRowData }) => {
  return [
    {
      filter: true,
      sortable: true,
      editable: false,
      resizable: true,
      unSortIcon: true,
      field: "chargeNumber",
      checkboxSelection: true,
      headerName: "Credit Memo No#",
      headerCheckboxSelection: true,
      filter: "agNumberColumnFilter",
      headerCheckboxSelectionFilteredOnly: true,
      cellRendererFramework: function (params) {
        try {
          return (
            <a
              {...{
                style: { color: "#1065A4" },
              }}
            >
              {params.value}
            </a>
          );
        } catch (e) {
          if (params.value) {
            return params.value;
          } else {
            return null;
          }
        }
      },
    },
    {
      filter: true,
      sortable: true,
      editable: false,
      resizable: true,
      unSortIcon: true,
      enableRowGroup: true,
      field: "chargeAmount",
      filter: "agNumberColumnFilter",
      headerName: "Credit Memo Amount",
      cellRenderer: cellRenders({}).price,
    },
    {
      filter: true,
      sortable: true,
      editable: false,
      resizable: true,
      unSortIcon: true,
      enableRowGroup: true,
      field: "appliedAmount",
      headerName: "Applied Amount",
      filter: "agNumberColumnFilter",
      cellRenderer: cellRenders({}).price,
    },
    {
      filter: true,
      sortable: true,
      resizable: true,
      unSortIcon: true,
      enableRowGroup: true,
      field: "applyAmount",
      headerName: "Apply Amount",
      headerClass: "applyAmount",
      filter: "agNumberColumnFilter",
      valueSetter: (params) => {
        console.log("charge memo params", params);
        if (isNaN(parseFloat(params.newValue)) || !isFinite(params.newValue)) {
          message.info("This field require only numbers");
          return false;
        } else if (
          parseFloat(params.newValue) >
          parseFloat(
            (params.data.chargeAmount || 0) - (params.data.appliedAmount || 0)
          )
        ) {
          message.info("Please enter a smaller amount");
          return false;
        }
        params.data["applyAmount"] = parseFloat(params.newValue);
        params.data["remainingAmount"] = `${formatCurrency(
          (params.data.chargeAmount || 0) -
            (parseFloat(params.newValue) || 0) -
            (params.data.applyAmount || 0) || 0
        )}`;

        return true;
      },
      cellRenderer: cellRenders({}).price,
    },
    {
      headerName: "Remaining Amount",
      field: "remainingAmount",
      filter: true,
      resizable: true,
      enableRowGroup: true,
      editable: false,
      filter: "agNumberColumnFilter",
      sortable: true,
      unSortIcon: true,
      cellRenderer: (params) => {
        return `${formatCurrency(
          (params.data.chargeAmount || 0) -
            (params.data.appliedAmount || 0) -
            (params.data.applyAmount || 0) || 0
        )}`;
      },
    },
    {
      headerName: "Category From",
      field: "categoryFrom",
      resizable: true,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      enableCellExpressions: true,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      resizable: true,
      unSortIcon: true,
      enableRowGroup: true,
      editable: false,
      filter: "agTextColumnFilter",
      sortable: true,
      sort: "desc",
      cellRenderer: (data) => {
        try {
          return data.value
            ? new Date(data.value).toLocaleDateString() !== "Invalid Date"
              ? new Date(data.value).toLocaleDateString()
              : ""
            : "";
        } catch (err) {
          return data.value;
        }
      },
      getQuickFilterText: () => "",
    },
  ];
};
