import { Spin } from "antd";
import { useSelector } from "react-redux";

import { useRedux } from "../../../hooks";
import { debounceSearch } from "../../../../../utils";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets";
import InputComponent from "../../../Fleet/components/InputComponent/InputComponent";

import "./ItemSelect.scss";

const ProjectSelect = ({ value, data }) => {
  const [isWritable] = useRedux("billIsWritable");

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { billData } = useInitialBillContext();
  const {
    rowData,
    projects,
    setRowData,
    onSearchChangeHandler,
    isProjectLoading,
  } = useCreateBillContext();

  const options = projects.map((project) => ({
    ...project,
    label: project.projectName,
    value: project.projectName,
    id: project.projectId,
  }));

  const onProjectSearch = debounceSearch(onSearchChangeHandler, 300);

  const handleSelect = (_, option) => {
    const selectedItem = projects.find(
      (project) => project.projectId === option.projectId
    );

    if (selectedItem) {
      setRowData((prev) => {
        return prev.map((row) => {
          if (row.itemId === data.itemId) {
            return {
              ...row,
              projectName: selectedItem.projectName,
              projectId: selectedItem.projectId,
            };
          }
          return row;
        });
      });
    }
  };

  const handleClear = () => {
    setRowData((prev) => {
      return prev.map((row) => {
        if (row.itemId === data.itemId) {
          return {
            ...row,
            projectName: "",
            projectId: "",
          };
        }
        return row;
      });
    });
  };

  console.log({ rowData });

  return (
    <InputComponent
      type="select"
      suffixIcon={<DropDownArrow />}
      defaultValue={value}
      onSearch={onProjectSearch}
      notFoundContent={
        isProjectLoading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
            size="small"
          />
        ) : null
      }
      className="bill-item-select"
      disabled={!!billData && !isWritable}
      name="projectNAme"
      showSearch
      placeholder="Select a project"
      optionFilterProp="children"
      popupClassName={isDarkMode && "darkDropDown"}
      onSelect={handleSelect}
      customOptions={options}
      onClear={handleClear}
    />
  );
};

export default ProjectSelect;
