import React from "react";

import { formatCurrency } from "../../../utils";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";

import "./FooterAmounts.scss";

const FooterAmounts = () => {
  const { rowData } = useCreateBillContext();
  const { billData, form } = useInitialBillContext();

  const totalAmount = rowData.reduce((sum, row) => sum + row.total, 0);
  const subTotalAmount = rowData.reduce((sum, row) => sum + row.amount, 0);

  return (
    <div className="footer-bill-group">
      <h4 data-testid="total-amount">Total: {formatCurrency(totalAmount)}</h4>
      <h4 data-testid="sub-total-amount">
        Subtotal: {formatCurrency(subTotalAmount)}
      </h4>
      <h4 data-testid="sub-total-amount">
        Credit: {formatCurrency(billData?.creditAmount || 0)}
      </h4>
    </div>
  );
};

export default FooterAmounts;
