export const scheduling = (programFields, updatedDynamicStates, IdHandler) => {
  return {
    typeOfWork: () => ({
      title: "Scheduling Type Of Work",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Scheduling",
        field: "typeOfWork",
        content: programFields
          ?.find((a) => a?.fieldName === "Scheduling Types")
          ?.fieldOptions?.map(({ typeName }, index) => ({
            key: index,
            title: typeName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) =>
                a?.typeOfWork?.toLowerCase() === typeName.toLocaleLowerCase()
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Schedule In " + typeName,
            contentsHandler: " Schedules In " + typeName,
          })),
      },

      id: IdHandler,
      width: "1600px",
    }), //typeofWork
  }; //schaduling
};
