import { Button, Tooltip } from "antd";
import { useRedux } from "../../../../../../hooks/useRedux";
import { InfoIcon } from "../../../../../../Inspections/assets/index";
import RichTextModalEditor from "../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor";

export const DescriptionModal = ({
  note = "",
  onConfirm = () => {},
  onCancel = () => {},
  editable = true,
  children,
  buttonClassName = "",
  modalContainerClassName = "",
  modalClassName = "",
  currentCharge,
}) => {
  const [isWritable] = useRedux("chargesIsWritable");
  // editable = currentCharge ? isWritable : true;
  return (
    <div className={`descriptionModalContainer ${modalContainerClassName}`}>
      <RichTextModalEditor
        {...{
          darkMode: true,
          value: note,
          onConfirm,
          onCancel,
          // disabledEditing: !isWritable,
          disabledEditing: editable && currentCharge ? !isWritable : !editable,
          // editable && currentCharge ? !isWritable : !editable,
          // saveButtonDisability: !isWritable,
          saveButtonDisability:
            editable && currentCharge ? !isWritable : !editable,
          // editable && currentCharge ? !isWritable : !editable,
          buttonClassName,
          modalClassName,
          allowedDelete: true,
          CustomButton: ({ onClick }) => (
            <Button
              {...{
                onClick,
                style: { border: 0, background: "transparent" },
              }}
              disabled={currentCharge && !isWritable}
            >
              <Tooltip title="Open Modal">
                <InfoIcon
                  {...{ fill: "white", style: { width: 27, height: 27 } }}
                />
              </Tooltip>
            </Button>
          ),
        }}
      >
        {children}
      </RichTextModalEditor>
    </div>
  );
};
