import {
  assignedToObject,
  projectManagerSelect,
  selectSafetyCategory,
  teamSelect,
} from "..";
import sortFields from "../sortFields";

/**
 * Generates an array of new fields based on the input fields and other parameters.
 *
 * @param {Array} teams - The array of teams.
 * @param {Array} safety - The array of safety options.
 * @param {Array} options.inputFields - The array of input fields.
 * @param {boolean} isDarkMode - Indicates whether the dark mode is enabled.
 * @param {Array} safetyOptions - The array of safety options.
 * @param {Array} projectManagers - The array of project managers.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {boolean} globalDisabled - Indicates whether all fields are globally disabled.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {boolean} allFieldsDisabled - Indicates whether all fields are disabled.
 * @param {Function} setFieldsValue - The function to set field values.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @param {Array} safetyInspectionCategories - The array of safety inspection categories.
 * @returns {Array} - The sorted array of new fields.
 */
const safetyInspectionRequestFields = ({
  teams = [],
  safety = [],
  inputFields = [],
  isDarkMode = false,
  safetyOptions = [],
  projectManagers = [],
  userConfiguration = {},
  globalDisabled = false,
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  setFieldsValue = () => {},
  onMainFieldSelect = () => {},
  dispatchRequestData = () => {},
  safetyInspectionCategories = [],
}) => {
  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "inspectionCategory") {
      delete field?.options;

      Object.assign(field, {
        options: Object.keys(safetyInspectionCategories) || [],
        style: { minWidth: 170, width: 200 },
        showSearch: true,
        allowClear: true,

        onSelect: (category) =>
          selectSafetyCategory({
            safety,
            category,
            setFieldsValue,
            dispatchRequestData,
          }),
      });
    }

    if (formItemName === "safetyReport") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (safetyOptions || [])?.map((el) => ({
          value: el?.safetyApplicationId,
          label: el?.safetyName,
          recordName: el?.safetyName,
        })),

        style: { minWidth: 170, width: 200 },
        onSelect: (val, opt) => onMainFieldSelect({ val, opt }),
        onClear: () => onMainFieldSelect({ clear: true }),
        showSearch: true,
        allowClear: true,
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Safety Inspections",
          users: userConfiguration?.allUsers?.Items || [],
          currentUser: userConfiguration?.cognitoUserId || "",
        }),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default safetyInspectionRequestFields;
