import { v4 as uuidv4 } from "uuid";

import { EMPTY_ROW } from "./degParser";

function createOverheadEntries({ crews, formData }) {
  const {
    selectedMembers = [],
    selectedDate = undefined,
    shiftStatus,
    punchType,
    reason = "",
    employeeRate = 0,
    totalOvh = 0,
  } = formData;

  const jobsiteMatch = {
    jobsiteId: "",
    jobAddress: "",
    jobName: "",
    reimbursement: false,
    services: [],
  };

  let newEntries = [];
  const selectedEmployees = crews.filter(({ crewId }) =>
    selectedMembers.includes(crewId)
  );

  for (const employee of selectedEmployees) {
    const {
      employeeId = "",
      crewId = "",
      crewName = "",
      crewPosition = "",
      accountName = "",
    } = employee;

    let newEntry = {
      ...EMPTY_ROW,
      crewName,
      entryId: uuidv4(),
      jobsiteId: undefined,
      jobsiteMatch,
      punchLocation: jobsiteMatch?.jobAddress,
      payrollType: "Open Shop",
      punchDate: selectedDate,
      punchTime: undefined,
      punchTimeStamp: undefined,
      punchCoordinates: {},
      employeeId,
      crewId,
      salaryType: employee?.salaryType,
      geofence: "",
      sow: [],
      activityStatus: shiftStatus || "Draft",
      employeeFullName: crewName,
      company: accountName,
      companyName: accountName,
      employeeRate: employeeRate || employee?.employeeRate,
      punchType,
      employeeRole: crewPosition,
      totalOvh,
      reason,
      duration: 0,
      distanceFromJob: 0,
    };
    newEntries.push(newEntry);
  }

  return newEntries;
}

export default createOverheadEntries;
