import { Alert, InputNumber, message } from "antd";
import React, { useEffect, useState } from "react";
import "./ChangeRetianageActions.scss";
import AnimatedNumber from "react-animated-number";
import { formatCurrency } from "../../../../../../../../../../utils";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { TickIcon } from "../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { checkIfServiceIsHoist } from "../../../../../../../../../../Estimations/DataEntryGrid/models/Service";

function ChangeRetainageActions({
  fakeApplication = {},
  writeableObject = null,
  setWriteableObject,
  isRetainageModalVisible,
  applications,
  isWritable,
  // type = "",
}) {
  // const [writeableObject, setWriteableObject] = useState(null);
  const [distributeState, setDistributeState] = useState(0);
  const updatedRet = (newVal, service) => {
    if (service in writeableObject) {
      setWriteableObject((prev) => ({ ...prev, [service]: newVal }));
    } else {
      message.error("Something went wrong with updating this Retainage");
    }
  };

  console.log("writeableObject", { writeableObject, applications });

  let previousRentals =
    applications
      ?.filter((el) => el.applicationNo !== fakeApplication.applicationNo)
      ?.map(({ includedRentals = [] }) => includedRentals) || [];

  const distributeAll = (val) => {
    const newWriteableObject = {};
    fakeApplication?.services?.forEach((service, idx) => {
      console.log("distribute All", { service });
      newWriteableObject[service?.label + idx] = val;
    });
    let rentals = fakeApplication?.includedRentals || [];
    if (rentals.length > 0) {
      fakeApplication?.includedRentals.forEach((rental) => {
        newWriteableObject[`Rental${rental.rentalNumber}`] = val;
        // rental?.services?.forEach((s) => {
        // 	s.retainage = val;
        // });
      });
    }
    // if (previousRentals.length > 0) {
    //   previousRentals.forEach((rentals) => {
    //     rentals.forEach((rental) => {
    //       newWriteableObject[`Rental${rental.rentalNumber}`] = val;
    //     });
    //   });
    // }
    console.log("distribute", {
      newWriteableObject,
      previousRentals,
      rentals,
      fakeApplication,
    });
    setWriteableObject(newWriteableObject);
  };

  useEffect(() => {
    if (writeableObject === null && !!fakeApplication?.services) {
      let temp = {};
      fakeApplication.services.forEach((service, idx) => {
        temp[service?.label + idx] = service?.retainage || 0;
      });
      let rentals = fakeApplication?.includedRentals || [];

      if (rentals.length > 0) {
        rentals.forEach((rental) => {
          temp[`Rental${rental.rentalNumber}`] =
            rental?.services?.reduce((acc, s) => acc + s.retainage, 0) /
              rental?.services?.length || 0;
        });
      }
      if (previousRentals.length > 0) {
        previousRentals.forEach((rentals) => {
          rentals.forEach((rental) => {
            temp[`Rental${rental.rentalNumber}`] =
              rental?.services?.reduce((acc, s) => acc + s.retainage, 0) /
                rental?.services?.length || 0;
          });
        });
      }

      setWriteableObject(temp);
    }
    // return () => {
    // 	setWriteableObject({});
    // };
  }, [fakeApplication, applications]);

  useEffect(() => {
    if (!isRetainageModalVisible) {
      setWriteableObject(null);
      setDistributeState(0);
    }
  }, [isRetainageModalVisible]);

  return (
    <div className="change-ret-actions">
      {" "}
      <div className="service-ret-container">
        <div className="left-side">
          <span className="label-service-ret">Distribute:</span>
          <InputNumber
            min={0}
            max={100}
            disabled={!isWritable}
            autoFocus={true}
            value={distributeState}
            onChange={(val) => setDistributeState(val)}
          />
        </div>
        <div className="right-cont">
          {/* <span className="label-service-ret"></span> */}
          {/* <InputNumber
                disabled
                value={retainageAmountGetter({
                    services: fakeApplication?.services || [],
                    service: key || null,
                    newRet: val || 0,
                })}
            /> */}
          <span
            onClick={() => {
              if (isWritable) distributeAll(distributeState);
            }}
            style={{
              width: "32px",
              height: "32px",
              background: isWritable ? "#71cf48" : "#e0e0e0",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            disabled={!isWritable}
          >
            <TickIcon />
          </span>
          {/* <MondayButton Icon={<TickIcon />}></MondayButton> */}
        </div>
      </div>
      {!!writeableObject &&
        Object.entries(writeableObject).map(([key, val]) => (
          <div className="service-ret-container" key={key + val}>
            <div className="left-side">
              <span className="label-service-ret">{key}:</span>
              <InputNumber
                autoFocus
                min={0}
                max={100}
                disabled={key.includes("Rental") || !isWritable}
                value={writeableObject[key]}
                onChange={(val) => updatedRet(val, key)}
              />
            </div>
            <div className="right-cont">
              {/* <span className="label-service-ret">Amount:</span> */}
              {/* <InputNumber
								disabled
								value={retainageAmountGetter({
									services: fakeApplication?.services || [],
									service: key || null,
									newRet: val || 0,
								})}
							/> */}
              <AnimatedNumber
                style={{
                  transition: "0.8s ease-out",
                  // color: "#71cf48",
                  // fontSize: 48,
                  transitionProperty: "background-color, color, opacity",
                }}
                // frameStyle={(perc) => (perc === 0 ? {} : { backgroundColor: "#71cf48" })}
                duration={500}
                value={retainageAmountGetter({
                  services: fakeApplication?.services || [],
                  service: key || null,
                  newRet: val || 0,
                  rentals: [
                    ...(fakeApplication?.includedRentals || []),
                    ...(previousRentals?.flatMap((r) => r) || []),
                  ].filter(Boolean),
                })}
                formatValue={(value) => formatCurrency(value)}
              />
            </div>
          </div>
        ))}
      {/* <span className="label-service-ret">Retianage (%)</span>
				<InputNumber />
				<span className="label-service-ret">Amount</span>
				<InputNumber disabled /> */}
      <div style={{ paddingRight: "20px" }}>
        <Alert
          message="When changing Retainage by distributing it for all items, it will also change the Retaiange for
				Previous Applications, for Rentals you must change the retainage to that section at the end of the page."
          type="warning"
        />
      </div>
    </div>
  );
}

export default ChangeRetainageActions;

const retainageAmountGetter = ({ services, service, newRet, rentals }) => {
  let result = 0;
  let currentService = services?.find((el) => el?.label === service);
  if (checkIfServiceIsHoist(service)) {
    currentService?.serviceOptions[0]?.forEach((elevationData) => {
      elevationData?.amounts?.forEach((eventData) => {
        const { fromPrevious, amount, retainagePercentage } = eventData || {};
        const retainage = (amount * newRet) / 100;
        result += retainage;
        // const collectable_amount = amount - fromPrevious - retainage;
        // const isReduced = retainagePercentage > retainageState ? true : false;
        // const reducedAmount = amount * (retainagePercentage / 100) - retainage;
        // return { ...eventData, retainage, collectable_amount, isReduced, reducedAmount };
      });
    });
  } else if (service.includes("Rental")) {
    // message.info("Rental retainage is not changeable");
    let reNo = Number(service.replace("Rental", ""));
    let rental = rentals?.find((el) => el?.rentalNumber === reNo);
    result += rental?.services?.reduce(
      (a, b) => a + (b?.appliedAmount * newRet) / 100,
      0
    );
    console.log("rental ret", { rental, rentals });
  } else {
    currentService?.amounts?.forEach((amountsData) => {
      const { fromPrevious, amount, charged, retainagePercentage } =
        amountsData;
      const retainage = (amount * newRet) / 100;
      result += retainage;
    });
  }
  return result.toFixed(2);
};
