import {
  MondayButton,
  RichTextEditor,
} from "../../../../../../../../../../../commonComponents";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import {
  CalendarIcon,
  SaveIcon,
  Terms,
} from "../../../../../../../../../../BasePage/src";
import { RefreshIcon } from "../../../../../../../../../../Communication/assets";
import { payingRentalsTermsHandler } from "./rentalPayingTermsFunctions";
import { Close } from "../../../../../../../../../../DynamicView/src";
import "./RentalPayingTerms.scss";

import { DatePicker, Form, Input, InputNumber, Modal } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { message } from "antd";
import dayjs from "dayjs";

const RentalPayingTerms = ({ service }) => {
  const [form] = Form.useForm();
  const { serviceId, estimationId } = service;

  const [payingTermsModalVisibility, setPayingTermsModalVisibility] =
    useState(false);
  const { isLoading } = useSelector((state) => state?.scheduleOfValues);
  const { isWritable, errors, darkMode, fakeSOV } = useStateContext();
  const dispatchContext = useDispatchContext();

  //this will be called when we pres save
  const payingRentalsTerms = () => {
    return payingRentalsTermsHandler({
      setPayingTermsModalVisibility,
      dispatchContext,
      estimationId,
      includeWeeks,
      serviceId,
      fakeSOV,
      form,
    });
  };
  const initialPayingTerms = fakeSOV?.services?.find?.(
    (serviceData) =>
      serviceData?.serviceId === serviceId &&
      serviceData?.estimationId === estimationId
  )?.rentalPaymentTerms;
  //here we generate the initial values for all 3 inputes below
  const initialValues = {
    input: initialPayingTerms?.name ? initialPayingTerms?.name : "",
    payingTerms: initialPayingTerms?.description
      ? initialPayingTerms?.description
      : "",
    dateRange: initialPayingTerms?.range ? initialPayingTerms?.range : "",
    startingDate: !!initialPayingTerms?.startingDate
      ? dayjs(initialPayingTerms?.startingDate)
      : undefined,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialPayingTerms]);

  const [includeWeeks, setIncludeWeeks] = useState(
    !initialPayingTerms?.startingDate
  );

  const warnUser = Object.values(form.getFieldsValue())?.every(
    (values) => !values
  );

  const isButtonClickable = isWritable ? "" : "buttonRentalTermsNotClickable";
  return (
    <div
      className="rentalPayingTermsContainer"
      onClick={(e) => e?.stopPropagation()}
    >
      <MondayButton
        className={
          !!initialPayingTerms
            ? `mondayButtonBlue ${isButtonClickable}`
            : `mondayButtonRed ${isButtonClickable}`
        }
        onClick={() =>
          isWritable
            ? setPayingTermsModalVisibility(true)
            : message.error("Please enable Edit Mode!")
        }
        Icon={<Terms />}
        //disabled={!isWritable || isLoading}
      >
        Rental Terms
      </MondayButton>

      <Modal
        title="Rental Payment Terms"
        wrapClassName={
          darkMode
            ? "rentalPayingTermsModalDarkMode"
            : "rentalPayingTermsModalLightMode"
        }
        open={payingTermsModalVisibility}
        onCancel={() => setPayingTermsModalVisibility(false)}
        footer={
          <>
            <MondayButton
              className="rentalPayingTermsCancelButton"
              onClick={() => {
                setPayingTermsModalVisibility(false);
                form.setFieldsValue(initialValues);
              }}
              Icon={<Close />}
            >
              Cancel
            </MondayButton>

            <MondayButton
              className="rentalPayingTermsSaveButton"
              onClick={payingRentalsTerms}
              Icon={<SaveIcon />}
              disabled={!isWritable}
            >
              Save
            </MondayButton>
          </>
        }
      >
        <div className="informationContainer">
          Create rental payment terms by filling this form!
        </div>
        <Form {...{ form, initialValues, preserve: false }}>
          {/* pick name */}
          <div className="container">
            <div className="nameInputContainer">
              <div className="nameInputText">Name</div>
              <Form.Item {...{ name: "input" }}>
                <Input className="nameInput" />
              </Form.Item>
            </div>
          </div>
          <div className="inputSwitcherContainer">
            <div className="switcherHeader">
              {includeWeeks ? "Number of weeks" : "Starting date"}
            </div>
            <div className="switcherBody">
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => setIncludeWeeks((initialValue) => !initialValue)}
                Icon={<RefreshIcon />}
              >
                Switch
              </MondayButton>

              {!!includeWeeks ? (
                <Form.Item {...{ name: "dateRange" }}>
                  <InputNumber className="dateInput" min={0} />
                </Form.Item>
              ) : (
                <Form.Item {...{ name: "startingDate" }}>
                  <DatePicker
                    className="dateInput"
                    popupClassName="filter-by-date-picker-dropdown"
                    suffixIcon={<CalendarIcon />}
                    format={"MM/DD/YYYY"}
                  />
                </Form.Item>
              )}
            </div>
          </div>

          {/* pick descriptions */}
          <div className="rentalPayingTermsDescriptions">
            <RichTextEditor
              {...{
                name: "payingTerms",
              }}
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default RentalPayingTerms;
