import { EditIcon } from "../../../../../../../../../../icons"
import { DeleteIcon } from "../../../../../../Roles/src"

function Header({ label, onEdit, onDelete }) {
  return (
    <div className="header">
      <div className="title-section">{label}</div>
      <div className="actions-section">
        <div className="action-button blue" onClick={onEdit}>
          <EditIcon />
        </div>
        <div className="action-button red" onClick={onDelete}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  )
}

export default Header
