import MoreServices from "./MoreServices";
import ServiceDescription from "./ServiceDescription";
import { calculateTotalPriceForService } from "../../../../../../Accounting/components/utilities";

const colors = ["#30973A", "#EEB522", "#4662F2"];

export const RentalDescription = ({ groups, selectedGroup }) => {
  const { data, projectTaxRate, label } = groups?.find(
    ({ id }) => id === selectedGroup
  );

  const allServices = data
    .map((service) => {
      const totalService = calculateTotalPriceForService(service, "rentals");
      const total = totalService.amount + totalService.taxAmount;

      if (total > 0)
        return {
          ...service,
          elevations:
            service.serviceOptions?.map((option) => option.elevationLabel) ||
            [],
          total: totalService.amount + totalService.taxAmount,
        };
    })
    .filter(Boolean);

  const services = allServices.slice(0, 2);
  const moreServices = allServices.length - services.length;

  return (
    <MoreServices
      hasOtherServices={moreServices}
      services={allServices}
      title={label}
    >
      <div className="GroupContainer">
        {services.map((service, key) => {
          return (
            <ServiceDescription
              key={key}
              service={service.label}
              elevations={service.elevations}
              isTaxable={projectTaxRate > 0}
              showDivider={key === 0 && services.length > 1}
              color={colors[key % colors.length]}
              total={service.total}
            />
          );
        })}
      </div>
    </MoreServices>
  );
};
