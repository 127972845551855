import { getMonthsTimestamps } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const maintenance = (circleProgressPicker, IdHandler, updatedDynamicStates) => {
  return {
    createdAt: () => ({
      title: "Maintenance Creation Date",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Maintenance",
        field: "createdAt",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.createdAt >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
              a?.createdAt <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
          ).length /
            updatedDynamicStates[IdHandler]?.filter((a) => a?.createdAt >= getMonthsTimestamps()[0])
              .length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: "Created at " + circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.createdAt >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.createdAt <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length,
      },
      id: IdHandler,
      width: "390px",
    }), //createdAt
  } //Maintenance
}
