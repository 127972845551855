import { message } from "antd";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { dateFormat } from "../../../Fleet/violations/modals/NewViolationModal/newViolationModalData";

/**
 * Filters schedules based on a given date and optional selected schedule.
 * @param {Array} schedules - The array of schedules to filter.
 * @param {string} date - The date to filter schedules on.
 * @param {string} [selectedSchedule=""] - The optional selected schedule.
 * @returns {Object} - An object containing the filtered schedules for the day and a flag indicating if the location was cleared.
 */
export function filterSchedulesOnDate(schedules, date, selectedSchedule = "") {
  let selectedDate = dayjsNY(date).format(dateFormat);
  let isCleared = false;
  let tmpSchedulesForDate = schedules?.filter(({ scheduleDays }) =>
    scheduleDays?.some(
      ({ startDate }) => dayjsNY(startDate).format(dateFormat) === selectedDate
    )
  );
  if (!!selectedSchedule) {
    if (!tmpSchedulesForDate.find((e) => e.scheduleId === selectedSchedule)) {
      message.warning(
        "Location was cleared since the schedule doesn't have schedule days"
      );
      isCleared = true;
    }
  }
  return {
    schedulesForDay: tmpSchedulesForDate,
    isCleared,
  };
}
