export const updateFakeSOV = (fakeScheduleOfValue, service, amounts) => {
  return {
    ...fakeScheduleOfValue,
    services: fakeScheduleOfValue?.services.map((sovService) =>
      sovService.serviceId.toString() === service.serviceId.toString() &&
      sovService.estimationId === service.estimationId
        ? {
            ...sovService,
            amounts,
            typeOfSOV: "Custom",
          }
        : sovService
    ),
  }
}
