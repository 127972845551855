export const formatAutomationData = (automations, programFields = []) => {
  const allAutomationActions = programFields.find(
    (prog) => prog.fieldName === "Automation Actions"
  )?.fieldOptions;

  return automations
    .map((automation) => ({
      ...automation,
      automationAction: automation?.automationAction?.map((action) => {
        const findedAction = allAutomationActions.find(({ actionId }) =>
          action?.actionId ? action.actionId === actionId : action === actionId
        );
        return (
          findedAction && {
            label: findedAction.actionName,
            value: findedAction.actionName,
            ...findedAction,
            actionRules: [
              ...(findedAction?.actionRules || []),
              ...(findedAction?.defaultRules || []),
            ],
          }
        );
      }),
    }))
    .filter(Boolean);
};
