/**
 * @param dataArray {Array<{id: Number}>}
 * @return Number. Returns a higher id compared to all other objects. Used when creating a new span,PLI, etc
 * @example items = [
 * {id: 3, ...otherData}, {id: 4, ...otherData}, {id: 6, ...otherData},
 * ]
 * It will return 7.
 * */
const getNewId = function (dataArray) {
    let highestId = 1;
    for (const addon of dataArray) {
        if(addon.id >= highestId) highestId = addon.id + 1;
    }
    return highestId;
}

export default getNewId