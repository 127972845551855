import { Button } from "antd";
import { useSelector } from "react-redux";

export default function IconButton({ icon, type, style, ...otherProps }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <Button
      data-testid="icon-button"
      icon={icon}
      type={type ? type : "text"}
      style={{
        color: isDarkMode ? "#f2f2f2" : "#333238",
        ...style,
      }}
      {...otherProps}
    />
  );
}
