import { getMonthsTimestamps, getRandomColor } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const violations = ({ tables }) => {
  return {
    amount_due: () => ({
      labels: months,
      data: (() => {
        let violations = []
        for (let i = 0; i < 12; i++) {
          violations = [
            ...violations,
            (() => {
              const amount = tables?.Violations?.filter(
                (b) =>
                  b.issue_date >= getMonthsTimestamps()[i] &&
                  b.issue_date <= getMonthsTimestamps("end")[i]
              )

              return !!amount?.length
                ? amount
                    ?.map((a) => a.amount_due)
                    ?.reduce(
                      (previousValue, currentValue) =>
                        parseInt(previousValue) + parseInt(currentValue)
                    )
                : 0
            })(),
          ]
        }
        return violations
      })(),
      backgroundColor: (() => {
        let colors = []
        for (let i = 0; i < 12; i++) {
          colors = [...colors, getRandomColor()]
        }
        return colors
      })(),
    }), //amount_due
    issue_date: () => ({
      labels: months,
      data: (() => {
        let violations = []
        for (let i = 0; i < 12; i++) {
          violations = [
            ...violations,
            tables?.Violations?.map((a) => a?.issue_date)?.filter(
              (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
            )?.length,
          ]
        }
        return violations
      })(),
      backgroundColor: (() => {
        let colors = []
        for (let i = 0; i < 12; i++) {
          colors = [...colors, getRandomColor()]
        }
        return colors
      })(),
    }), //issue_date
  } //Violations,
}
