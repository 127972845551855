export const charges = (
  programFields,
  updatedDynamicStates,
  chargesData,
  IdHandler
) => {
  const data = updatedDynamicStates[IdHandler];

  const chargeStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    categoryFrom: () => ({
      title: "Charges - Category From",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      id: IdHandler,
      BodyComponentParams: {
        table: "Charges",
        field: "categoryFrom",
        title: "Category From",
        subtitle: "",
        content: chargeStatus?.statusName?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (chargesData?.filter((a) => a?.categoryFrom === status).length /
              chargesData?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: chargesData?.filter((a) => a?.categoryFrom === status).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
    //totalAmount
  };
};
