import React from "react";
import Swal from "sweetalert2";
import { MondayButton } from "../../../../../../../../../../../../commonComponents";
import { AddIcon } from "../../../../../../../../../../../../Header/components/GoogleEvents/assets";

function CreateNewFolder({ onCreateNewFolder }) {
  const onPreCreate = () => {
    Swal.fire({
      title: "Enter the desired new folder name:",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Create Folder",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const response = await onCreateNewFolder(login);
          if (!response) {
            return Swal.showValidationMessage(`
                    Request failed: ${response}
                  `);
          }
          return true;
        } catch (error) {
          Swal.showValidationMessage(`
                  Request failed: ${error}
                `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <div>
      {" "}
      <MondayButton
        className="mondayButtonBlue"
        Icon={<AddIcon />}
        onClick={onPreCreate}
      >
        Create New Folder
      </MondayButton>
    </div>
  );
}

export default CreateNewFolder;
