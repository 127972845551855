const gridFilters = [
    {
      globalKeyId: "8fdejjjjjj8a-400f-b352-6354cb0f22ec",
      label: "Name",
      type: "Dropdown",
      key: "crewTeamName",
    },
    {
      globalKeyId: "8f454548a-400f-b352-6354cb0f22ec",
      label: "Foreman",
      type: "Dropdown",
      key: ["crewForeman", "crewName"],
    },
    // {
    //   globalKeyId: "8fdedbc3-0b8a-3433f-b352-6354cb0f22ec",
    //   label: "Role",
    //   type: "Dropdown",
    //   key: "crewPosition",
    // },
  ];
  
  export default gridFilters;
  