import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";
import { boroughs } from "../../../addWidgetsModalData";
import {
  getBoroughSum,
  repeatedElementSum,
} from "../../../NewDashboardFunctions";

export const incidents = (updatedDynamicStates, IdHandler, programFields) => {
  const incidentStatuses = programFields?.find(
    (a) => a?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Incidents;
  return {
    driverName: () => ({
      title: "Incidents Driver",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Incidents",
        field: "driverName",
        labels: uniq(
          updatedDynamicStates?.[IdHandler]?.map((a) => a?.driverName)
        ),
        datasets: [
          {
            label: "Incidents",
            data: uniq(
              updatedDynamicStates?.[IdHandler]?.map((a) => a?.driverName)
            )?.map(
              (status) =>
                updatedDynamicStates?.[IdHandler]?.filter(
                  (a) => a?.driverName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(
              updatedDynamicStates?.[IdHandler]?.map((a) => getRandomColor())
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    fleetName: () => ({
      title: "Incidents Fleet",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Incidents",
        field: "fleetName",
        labels: uniq(
          updatedDynamicStates?.[IdHandler]?.map((a) => a?.fleetName)
        ),
        datasets: [
          {
            label: "Incidents",
            data: uniq(
              updatedDynamicStates?.[IdHandler]?.map((a) => a?.fleetName)
            )?.map(
              (status) =>
                updatedDynamicStates?.[IdHandler]?.filter(
                  (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(
              updatedDynamicStates?.[IdHandler]?.map((a) => getRandomColor())
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    incidentStatus: () => ({
      title: "Incidents Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentStatus",
        labels: incidentStatuses?.map((a) => a?.statusName),
        datasets: [
          {
            label: "Incidents",
            data: incidentStatuses?.map(
              (status) =>
                updatedDynamicStates?.[IdHandler]?.filter(
                  (e) => e?.incidentStatus === status?.statusName
                )?.length
            ),
            backgroundColor: incidentStatuses?.map((a) => a?.statusColor),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    incidentCategory: () => ({
      title: "Incidents Category",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentCategory",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "incidentCategory"
          )
        ),
        datasets: [
          {
            label: "Incidents",
            data: Object?.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "incidentCategory"
              )
            ),
            backgroundColor: uniq(
              updatedDynamicStates?.[IdHandler]?.map((a) => getRandomColor())
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectName: () => ({
      title: "Incidents Project Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Incidents",
        field: "projectName",
        labels: Object.keys(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])?.map(
              (e) => e?.incidentObject
            ),
            "projectName"
          )
        ),
        datasets: [
          {
            label: "Incidents",
            data: Object?.values(
              repeatedElementSum(
                (updatedDynamicStates?.[IdHandler] || [])?.map(
                  (e) => e?.incidentObject
                ),
                "projectName"
              )
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
