import dayjs from "dayjs";
import React from "react";
import { formatCurrency } from "../../../../../../../../utils/formatCurrency";
import { calcTotalChargeAmount } from "../../../utils/calcTotalChargeAmount";
import { generateChargeItems } from "../../../utils/generateChargeItems";
import { useRedux } from "../../../../../../../../hooks/useRedux";
import { useCreateChargeContext } from "../../../context/NewChargeItemContext";

const ChargeAmounts = () => {
  const [objectData] = useRedux("chargeItemsModal");
  const categoryFrom = objectData?.categoryFrom;
  const whereToFind = objectData?.whereToFind;

  const { chargeToSave } = useCreateChargeContext();

  const chargeItems = generateChargeItems(chargeToSave);

  const creditChargeAmount = chargeItems?.reduce(
    (acc, { creditAmount }) => (acc += creditAmount || 0),
    0
  );
  const totalThisPeriod = chargeItems?.reduce(
    (acc, { totalThisPeriod }) => (acc += totalThisPeriod || 0),
    0
  );

  if (categoryFrom === "Requisition")
    return (
      <>
        <span data-testid="charge-period">
          {dayjs(whereToFind?.periodTo?.start)?.format("MM/DD/YYYY") +
            " - " +
            dayjs(whereToFind?.periodTo?.end)?.format("MM/DD/YYYY")}
        </span>
        <span data-testid="charge-amount">
          Charge Amount:{" "}
          {formatCurrency(
            calcTotalChargeAmount(chargeToSave, categoryFrom, whereToFind) || 0
          )}
        </span>
        <span data-testid="charge-this-period">
          This Period: {formatCurrency(totalThisPeriod || 0)}
        </span>
        <span data-testid="charge-credit-amount">
          Credit Amount: {formatCurrency(-1 * creditChargeAmount || 0)}
        </span>
      </>
    );

  return (
    <span data-testid="charge-amount">
      {`${objectData?.type === "Credit Memo" ? "Credit" : "Charge"} Amount: `}
      {formatCurrency(
        calcTotalChargeAmount(chargeToSave, categoryFrom, whereToFind) || 0
      )}
    </span>
  );
};

export default ChargeAmounts;
