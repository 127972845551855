import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../utils";

export const updateDriveFolders = async ({
  folderToBeUpdated,
  existingParentId,
  newParentId,
  driveRequest,
}) => {
  let ids = {};

  await driveRequest
    .updateDriveItem(folderToBeUpdated, {
      addParents: newParentId,
      removeParents: existingParentId,
    })
    .then(async (r) => {
      const response = await r.json();
      if (!r.ok) {
        ids = null;
        showErrorMsg({
          content:
            "Something went wrong, files were not moved to the correct folder.",
        });
        console.error("response", response);
      } else {
        ids = { folderToBeUpdated, newParentId };
      }
    });

  return ids;
};
