export function cellarFloors(params) {
  let value = parseInt(params.value); //put the number to to int in order to avoid decimal numbers
  if (!isNaN(value)) {
    if (value === -4) return "sub-sub-sub-cellar";
    if (value === -3) return "sub-sub-cellar";
    if (value === -2) return "sub-cellar";
    if (value === -1) return "cellar";
    if (value === 0) return "N/A";

    return value;
  } else {
    return "no number: " + params.value;
  }
}
