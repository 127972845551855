export class HoistPLI {
  serviceId = 3;

  id;
  floor;
  stop = true;
  floor_height;

  description;
  note;
  approved = false;
  serviceId;
  addons = [];

  constructor(id) {
    this.id = id;
  }
}
