import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { InfoWindow } from "@react-google-maps/api";

import { InfoIcon } from "src/assets";
import {
  StoreType,
  JobsiteType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "./JobsiteInfoWindow.scss";

interface Props {
  data: JobsiteType;
  onClick: () => void;
}

function JobsiteInfoWindow(props: Props) {
  const { data, onClick } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  return (
    <InfoWindow
      zIndex={3}
      key={data.jobsiteId}
      position={data.addressPosition}
      options={{ pixelOffset: new google.maps.Size(0, -40) }}
    >
      <section
        className={`jobsite-info-window ${
          darkMode ? "jobsite-info-window-dark" : ""
        }`}
      >
        <div className="info-header">
          <span>Jobsite</span>
          <XIcon onClick={onClick} width={12} height={12} />
        </div>
        <div className="info-body">
          <div className="location-information">
            <Tooltip title="Jobsite Address">
              <InfoIcon width={14} height={14} />
            </Tooltip>
            <span>{data?.jobAddress}</span>
          </div>
          <div className="info-data">
            <label>Payroll Type:</label>
            <span>{data?.payrollType}</span>
          </div>
        </div>
      </section>
    </InfoWindow>
  );
}

export default JobsiteInfoWindow;
