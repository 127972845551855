import { compareIncluding } from "../components/SidebarPages/utils";
import { companyDetails } from "../helpers/constants";
import { changeBodyOrderAndFilter } from "./changeBodyOrderAndFilter";
import { changeHeaderOrderAndFilter } from "./changeHeaderOrderAndFilter";
import { getPdfTableWidths } from "./getPdfTableWidths";

const layout = {
  fillColor: function (rowIndex) {
    return rowIndex === 0 ? "#B7C4CB" : rowIndex % 2 === 0 ? "#f7f7f7" : "#fff";
  },
  hLineColor: function (i, node) {
    return i === 0 || i === node.table.body.length ? "#D0D0D0" : "#D0D0D0"; // Gray color for horizontal lines, including top and bottom borders
  },
  vLineColor: function (i, node) {
    return i === 0 || i === node.table.widths.length ? "#D0D0D0" : "#D0D0D0"; // Gray color for vertical lines, including left and right borders
  },
  hLineWidth: function (i, node) {
    return i === 0 || i === node.table.body.length ? 1 : 0.5; // Thickness for outer horizontal borders
  },
  vLineWidth: function (i, node) {
    return i === 0 || i === node.table.widths.length ? 1 : 0.5; // Thickness for outer vertical borders
  },
  paddingLeft: function (i, node) {
    return 4;
  },
  paddingRight: function (i, node) {
    return 4;
  },
  paddingTop: function (i, node) {
    return 4;
  },
  paddingBottom: function (i, node) {
    return 4;
  },
};

export const generateDocDefinition = (
  base64,
  title,
  columnsToInclude,
  tableHeaders,
  tableData,
  pdfContent = false
) => {
  const { logoName } = companyDetails;

  const logo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, logoName)
  )?.base64;

  const pageMargins = [20, 120, 20, 40];

  const headerData = changeHeaderOrderAndFilter(tableHeaders, columnsToInclude);
  const bodyData = changeBodyOrderAndFilter(tableData, columnsToInclude);

  const docDefinition = {
    pageMargins,
    header: {
      columns: [
        {
          text: `Title: ${title}`,
          fontSize: 11,
          alignment: "left",
          margin: [20, 10],
          width: "*",
          bold: true,
        },
        {
          image: logo,
          fit: [100, 100],
          alignment: "center",
          width: "*",
        },
        {
          text: new Date().toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          fontSize: 10,
          alignment: "right",
          margin: [20, 10],
          width: "*",
        },
      ],
      margin: [0, 10, 0, 40],
    },
    content: pdfContent || [
      {
        table: {
          headerRows: 1,
          widths: getPdfTableWidths(columnsToInclude),
          body: [headerData, ...bodyData],
          margin: [0, 50, 0, 0],
        },
        layout,
      },
    ],
    footer: function (currentPage, pageCount) {
      return {
        text: "Page " + currentPage.toString() + " of " + pageCount,
        alignment: "right",
        margin: [0, 0, 20, 0],
      };
    },
    styles: {
      tableHeader: {
        fontSize: 14,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
  };

  return docDefinition;
};
