import { Button, Modal, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { EyeIcon } from "../../../../DynamicView/src";
import { retrieveTakeOffDOCX } from "../../tools/exporters/retrieveDOCX";
import "./PreviewEngTakeOff.scss";
import { CloseIconModalHeader } from "../../../../../../assets";
import { useRedux } from "../../../../hooks";
import "./PreviewEngTakeOffPrint.scss";
import { MondayButton, WithTooltip } from "../../../../../commonComponents";
import { PrintIcon } from "../../../../BasePage/src";
import { EyeFilled } from "@ant-design/icons";
import { exportDOCX } from "../../tools/exporters";
import { updateDocumentTitle } from "../../../../../../utils";

function PreviewEngTakeOff({
  gridData,
  projectAddress,
  serviceDefs,
  accountName,
  estimationDescription,
  isIt,
}) {
  const [isOpen, setOpen] = useState(false);
  const [htmlData, setHtmlData] = useState([]);
  const [darkMode, setDarkMode] = useRedux("estimationsDarkMode", true, false);
  const printableModalBodyRef = useRef();

  const getData = () => {
    const temp = retrieveTakeOffDOCX(
      gridData,
      projectAddress,
      serviceDefs,
      accountName,
      estimationDescription,
      isIt
    );
    setHtmlData(temp);
    console.log("myTemp", temp);
  };

  const toggleOpen = (value) => {
    try {
      updateDocumentTitle(); // reset document title to "Lead Manager";
      if (value === false) {
        // setHtmlData([]);
        setOpen(value);
      } else {
        getData();
        setOpen(value);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onPrint = () => {
    const printableArea = printableModalBodyRef.current.cloneNode(true);
    const printWindow = window.open("", "_blank");

    printWindow.document.write("<html><head><title> </title>");
    printWindow.document.write("<style>");
    // Get all stylesheets in the current document and copy their content to the print window
    Array.from(document.styleSheets).forEach((styleSheet) => {
      try {
        // console.log("styleSheet", styleSheet);
        const css = styleSheet.cssRules;
        printWindow.document.write(
          Array.from(css)
            .map((rule) => rule.cssText)
            .join("")
        );
      } catch (e) {
        console.log(`Error loading CSS for ${styleSheet.href}: ${e}`);
      }
    });
    printWindow.document.write("</style></head><body>");
    printWindow.document.write(printableArea.outerHTML);
    printWindow.document.write("</body></html>");

    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const onExport = () => {
    let desiredHTML = document.querySelector(
      ".printable-modal__body"
    ).innerHTML;
    desiredHTML = desiredHTML.replace(/&nbsp;/g, " ");
    exportDOCX(
      { html: desiredHTML, fileName: "Estimation" },
      "address",
      [],
      "",
      "",
      true
    );
  };

  return (
    <div
      style={
        {
          // marginLeft: "20px",
        }
      }
    >
      {/* <Tooltip title="Preview TakeOff"> */}
      <WithTooltip tooltipCategory="Take Off" tooltipKey="previewTakeOff">
        <button className="icon-btn-holder" onClick={() => toggleOpen(true)}>
          <EyeFilled style={{ transform: "scale(0.9)" }} />
        </button>
      </WithTooltip>

      <Modal
        title="Take Off Preview"
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Take Off Preview" });
        }}
        // title
        // closeIcon={<CloseIconModalHeader />}
        open={isOpen}
        className={`preview-modal__takeoff${darkMode ? "__dark" : ""}`}
        // width={"1200px"}

        footer={null}
        centered
        width={"auto"}
        onCancel={() => {
          toggleOpen(false);
          // setHtmlData([]);
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
            }}
          >
            <MondayButton
              className="mondayButtonBlue"
              onClick={onPrint}
              Icon={<PrintIcon />}
            >
              PRINT
            </MondayButton>
            {/* <MondayButton onClick={onExport}>WORD</MondayButton> */}
          </div>
        </div>
        {/* <Button onClick={getData}>GET DATA</Button> */}
        <div
          className="printable-modal__body"
          style={{ width: "auto", padding: "20px" }}
          ref={printableModalBodyRef}
        >
          {htmlData?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {typeof item?.props?.children === "array"
                  ? children.map((child, index) => {
                      return child;
                    })
                  : item?.props?.children !== "object"
                  ? item
                  : ""}
              </React.Fragment>
            );
          })}
        </div>
      </Modal>

      {/* <h1>{state.user.name}</h1>
    <p>{state.user.email}</p>
    <p>{state.user.bio}</p> */}
      {/* </Tooltip> */}
    </div>
  );
}

export default PreviewEngTakeOff;
