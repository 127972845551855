import {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { WarningModal } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { MondayButton } from "src/components/commonComponents";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";

const ReasonEditor = forwardRef((props, ref) => {
  const [selectedValue, setSelectedValue] = useState(props?.value);
  const [warning, setWarning] = useState("");

  const { value, context, stopEditing } = props;

  useEffect(() => {
    if (!context.isWritable) {
      stopEditing();
    }
  }, [context.isWritable]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return selectedValue;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    [selectedValue]
  );

  async function addNewHandler(option) {
    await context.onReasonSelect(option).then((res) => {
      setSelectedValue(res);

      setTimeout(() => {
        stopEditing();
      }, 50);
    });
  }

  return (
    <Fragment>
      <InputComponent
        {...{
          label: "",
          type: "predictAutocomplete",
          required: false,
          noFormItem: true,
          defaultOpen: true,
          style: { width: "100%" },
          defaultValue: value,
          disabled: !!value,
          allowClear: !value,
          onSelect(e) {
            setWarning(e);
          },
          addNewClick(e) {
            setWarning(e);
          },
          onBlur() {
            if (!warning) {
              stopEditing();
            }
          },
          autoFocus: true,
          customOptions: context.reasons.map((e) => ({
            label: e,
            value: e,
          })),
        }}
      />
      {warning && (
        <WarningModal
          visible={!!warning}
          setVisible={setWarning}
          darkMode={context.isDarkMode}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body" style={{ textAlign: "center" }}>
            <span>
              <WarningTriangle />
            </span>
            <p>
              Are you sure you want to set a reason? The reason cannot be
              changed later
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  setWarning("");
                  stopEditing();
                }}
                className="mondayButtonRed"
                Icon={<XIcon />}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (context.reasons.includes(warning)) {
                    setSelectedValue(warning);

                    setTimeout(() => {
                      stopEditing();
                    }, 50);
                  } else {
                    addNewHandler(warning);
                  }

                  setWarning("");
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
});

export default ReasonEditor;
