import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const projects = (
  programFields,
  updatedDynamicStates,
  IdHandler,
  projectsData
) => {
  return {
    projectStatus: () => ({
      title: "Project Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Projects",
        field: "projectStatus",
        labels: programFields
          ?.find((a) => a?.fieldName == "Project Status")
          ?.fieldOptions?.map((a) => a.statusName),
        datasets: [
          {
            label: "Projects",
            data: programFields
              ?.find((a) => a?.fieldName == "Project Status")
              ?.fieldOptions?.map((a) => a.statusName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) =>
                      a.projectStatus?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Projects?.map((a) => a.statusColor),
            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }), //projectStatus
    attorney: () => ({
      title: "Project Attorney",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Projects",
        field: "attorney",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "attorney"
          )
        ),
        datasets: [
          {
            label: "Projects",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "attorney"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "attorney"
              )
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }), //attorney
    expeditor: () => ({
      title: "Project Expeditor",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Projects",
        field: "expeditor",
        labels: Object.keys(
          updatedDynamicStates?.[IdHandler] || [],
          "expeditor"
        ),
        datasets: [
          {
            label: "Projects",
            data: Object.values(
              updatedDynamicStates?.[IdHandler] || [],
              "expeditor"
            ),
            backgroundColor: Object.keys(
              updatedDynamicStates?.[IdHandler] || [],
              "expeditor"
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }), //expeditor

    projectExecutive: () => ({
      title: "Project Executive",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Projects",
        field: "projectExecutive",
        labels: Object.keys(
          repeatedElementSum(projectsData, "projectExecutive")
        ),
        datasets: [
          {
            label: "Projects",
            data: Object.values(
              repeatedElementSum(projectsData, "projectExecutive")
            ),
            backgroundColor: uniq(projectsData?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectManager: () => ({
      title: "Project Manager",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Projects",
        field: "projectManager",
        labels: Object.keys(
          repeatedElementSum(
            projectsData?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ),
        datasets: [
          {
            label: "Projects",
            data: Object.values(
              projectsData?.map((a) => a?.projectManager)?.flat(),
              "nameOfUser"
            ),
            backgroundColor: uniq(projectsData?.map((a) => getRandomColor())),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    geoFenceInfo: () => ({
      title: "Geofence Quantity - Projects",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Projects",
        field: "geoFenceInfo",
        labels: ["Projects With Geofences", "Projects Without Geofences"],

        datasets: [
          {
            label: "Projects",
            data: [
              (updatedDynamicStates?.[IdHandler] || [])?.reduce(
                (sum, { geoFenceInfo }) =>
                  sum + (geoFenceInfo.length > 0 ? 1 : 0),
                0
              ),
              (updatedDynamicStates?.[IdHandler] || [])?.reduce(
                (sum, { geoFenceInfo }) =>
                  sum + (geoFenceInfo.length === 0 ? 1 : 0),
                0
              ),
            ],
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Projects?.map((a) => a.statusColor),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  }; //Projects
};
