export const updateTeamsConfiguration = (
  userConfiguration,
  selectedTeam,
  otherUsers = [],
  teamId = false
) => {
  if (!selectedTeam?.length) {
    return [];
  }

  const otherUsersToAdd = otherUsers?.filter(Boolean) || [];

  const isAdmin = userConfiguration.groupName.toLowerCase() === "admin";

  const notExistUsers = (
    !isAdmin
      ? [
          ...otherUsersToAdd,
          {
            identityId: userConfiguration.identityId,
            nameOfUser: userConfiguration.nameOfUser,
            cognitoUserId: userConfiguration?.cognitoUserId,
          },
        ]
      : otherUsersToAdd
  ).filter((user) =>
    selectedTeam.some(
      (team) =>
        !team.members.some((member) => member.identityId === user.identityId)
    )
  );

  const updatedItems = !!notExistUsers.length
    ? !!teamId
      ? [
          {
            ...selectedTeam[0],
            members: [...selectedTeam[0].members, ...notExistUsers],
            teamId: selectedTeam[0]?.teamId || [],
          },
          ...selectedTeam.slice(1),
        ]
      : [
          {
            ...selectedTeam[0],
            members: [...selectedTeam[0].members, ...notExistUsers],
          },
          ...selectedTeam.slice(1),
        ]
    : selectedTeam;

  const teamsConfiguration = updatedItems.map((item) =>
    !!teamId
      ? {
          value: item.value,
          members: item.members,
          teamId: item?.teamId || [],
        }
      : {
          value: item.value,
          members: item.members,
        }
  );

  return teamsConfiguration;
};
