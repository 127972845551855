import { Navigate, Route, Routes } from "react-router-dom";

import RouteWrapper from "./RouteWrapper";
import { routeConfigurations } from "./routePathConfig";

const AuthenticatedRouteController = ({ pref, routesElements, childProps }) => {
    return (
    <Routes>
      {routeConfigurations.map((route, index) => {
        if (route.redirectPath) {
          // If there's a redirectPath, render a Redirect component
          return (
            <Route
              key={index}
              path={route.path}
              element={<Navigate to={route.redirectPath} replace />}
            />
          );
        } else {
          // Render the regular Route component
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <RouteWrapper
                  {...{
                    pref,
                    routesElements,
                    childProps,
                    component: route.component,
                    path: route.path,
                  }}
                />
              }
            />
          );
        }
      })}
    </Routes>
  );
};

export default AuthenticatedRouteController;
