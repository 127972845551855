import React from "react";
import { Slider, Form } from "antd";

import "./Slider.scss";

function SliderRange({
  min,
  max,
  name,
  form,
  // value,
  range,
  marks,
  disabled,
  onChange,
  isDarkMode,
}) {
  const sliderValue = Form.useWatch(name, form);

  function onSlideChange(event) {
    if (!onChange) {
      form.setFieldValue(name, event);
    } else {
      onChange(event);
    }
  }
  console.log("sliderValue: ", sliderValue);
  return (
    <section className={`form-slide ${isDarkMode ? "form-slide-dark" : ""}`}>
      <Slider
        min={min ?? 0}
        max={max}
        marks={marks}
        range={range}
        value={!!range ? sliderValue || [] : sliderValue}
        disabled={disabled}
        onChange={onSlideChange}
      />
    </section>
  );
}

export default SliderRange;
