import React from "react";
import "./logContentBuilder.scss";

export const buildLogContent = (
  gridData,
  {
    estimationIndex,
    serviceId,
    elevationIndex,
    pliId,
    subPliId,
    serviceAddonId,
  },
  staticMessage
) => {
  let service, elevation, serviceAddon, pli, subPLI, pliAddon;

  let estimationText,
    serviceText,
    elevationText,
    serviceAddonText,
    pliText,
    subPLiText,
    pliAddonText;

  // console.log(staticMessage)

  if (estimationIndex !== undefined) {
    estimationText = <b>{"Estimation " + (estimationIndex + 1)}</b>;
  }
  if (serviceId !== undefined) {
    service = gridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );
    serviceText = (
      <>
        {">"} <b>{service.label}</b>
      </>
    );
  }
  if (elevationIndex !== undefined) {
    elevation = service.serviceOptions[0][elevationIndex];
    elevationText = (
      <>
        {">"} <b>{elevation.elevationLabel}</b>
      </>
    );
  }
  if (serviceAddonId !== undefined) {
    serviceAddonText = (
      <>
        {">"} <b>{serviceAddonId}</b>
      </>
    );
  }
  if (pliId !== undefined) {
    pliText = (
      <>
        {"> PLI:"} <b>{pliId}</b>
      </>
    );
  }
  if (subPliId !== undefined) {
    subPLiText = (
      <>
        {"> sub-PLI:"} <b>{subPliId}</b>
      </>
    );
  }

  return (
    <div className={"schedule-work-log-content"}>
      <p>
        {estimationText}
        {serviceText}
        {elevationText}
        {serviceAddonText}
        {pliText}
        {subPLiText}
      </p>
      <p>{staticMessage}</p>
    </div>
  );
};

export const IndexesRender = ({
  estimationId,
  estimationIndex,
  serviceId,
  label,
  elevationLabel,
  elevationIndex,
  pliId,
  pliAddonId,
  subPliId,
  serviceAddonId,
  breakdownName,
}) => {
  let estimationText,
    serviceText,
    elevationText,
    serviceAddonText,
    pliText,
    subPLiText,
    pliAddonText,
    breakdownNameText;
  console.log(estimationIndex);

  if (estimationIndex || estimationId) {
    estimationText = (
      <>
        {
          "Go to estimation"
          // ||
          // ("Estimation " + (estimationIndex + 1))
        }
      </>
    );
  }
  if (serviceId !== undefined) {
    serviceText = <>{label}</>;
  }
  if (elevationIndex !== undefined) {
    elevationText = <>{elevationLabel}</>;
  }
  if (serviceAddonId !== undefined) {
    serviceAddonText = <>{serviceAddonId}</>;
  }
  if (pliId !== undefined) {
    pliText = (
      <>
        {"PLI: "}
        {pliId}
      </>
    );
  }
  if (breakdownName !== undefined) {
    breakdownNameText = (
      <>
        {"Breakdown:"} <>{breakdownName}</>
      </>
    );
  }
  if (pliAddonId !== undefined) {
    pliAddonText = (
      <>
        {"Addon:"} <>{pliAddonId}</>
      </>
    );
  }
  if (subPliId !== undefined) {
    subPLiText = (
      <>
        {"sub-PLI:"} <>{subPliId}</>
      </>
    );
  }

  const estRedirect = () => {
    window.open(`/estimations/${estimationId}`, "_blank");
  };

  return (
    <>
      <div className="pli-note-info">
        {serviceId && <h6>{serviceText}</h6>}
        {serviceId && <h6>{elevationText}</h6>}
        {/* {elevationIndex && <h6>{elevationText}</h6>} */}
        {serviceAddonId && <h6>{serviceAddonText}</h6>}
        {pliId && <h6>{pliText}</h6>}
        {breakdownName && <h6>{breakdownNameText}</h6>}
        {pliAddonId && <h6>{pliAddonText}</h6>}
        {subPLiText}
      </div>
    </>
  );
};
