import React from "react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { Collapse, message, Modal, Tooltip, Popconfirm } from "antd";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import { API } from "aws-amplify";
import _ from "lodash";
import { useRedux } from "../../../../hooks";
import { EditIcon } from "../../../../DynamicView/src";
const { Panel } = Collapse;
function ServiceAddonsConfig(props) {
  const {
    service,
    serviceIndex,
    pricingData,
    getAddonsCaption,
    handleChangePricingData,
    agGridTheme,
    // gridData,
    updateStateAndSave,
  } = props;
  const [isWritable = false] = useRedux("estimationsIsWritable");
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");

  const rowData = useMemo(() => {
    const temp = _.cloneDeep(gridData[serviceIndex]?.serviceAddons || []);
    if (Array.isArray(temp) && temp.length > 0) {
      console.log("tempRowData", temp);
    }

    return temp;
  }, [gridData]);
  console.log("Select 6");
  return (
    <div>
      <Collapse>
        <Panel
          key={0}
          header={
            <div className="addonsHeader">
              Service Addons
              {/* {getAddonsCaption(
                rowData,
                isReadOnly?.filter((row) => row.name.toLowerCase() === "price")
              )} */}
            </div>
          }
        ></Panel>
      </Collapse>
    </div>
  );
}

export default ServiceAddonsConfig;
