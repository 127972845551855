import { useRef, useState, useCallback, useEffect } from "react";
import { useFilteredHistory } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/Hooks/useFilteredHistory";
import "./Pie.scss";
import { SmallLoadableComp } from "../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import { Doughnut } from "react-chartjs-2";
import GraphTooltip from "../BarChart/GraphTooltip";
import { useSelector } from "react-redux";
import { Result } from "antd";

const Pie = ({ params, overviewClick = () => {}, filterValue }) => {
  const chartRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPos, setTooltipPos] = useState(null);
  const { table, field, labels, custom, datasets } = params;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const history = useFilteredHistory(table);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!datasets?.[0]?.data) {
      if (datasets[0]?.data?.every((q) => q === 0)) {
        setLoading(false);
        setNoData(true);
      } else if (datasets[0]?.data?.length === 0) {
        setLoading(false);
        setNoData(true);
      } else {
        datasets[0]?.data?.every?.((e) => {
          if (e === undefined) {
            setLoading(true);
            setNoData(false);
          } else {
            setLoading(false);
            setNoData(false);
          }
        });
      }
    }
  }, [filterValue, datasets?.[0]?.data]);

  const customTooltip = useCallback((context) => {
    if (context.tooltip.opacity == 0) {
      // hide tooltip visibilty
      setTooltipVisible(false);
      return;
    }

    const chart = chartRef.current;
    const canvas = chart.canvas;
    if (canvas) {
      // enable tooltip visibilty
      setTooltipVisible(true);

      // set position of tooltip
      const left = context.tooltip.x - 150;
      const top = context.tooltip.y + 100;

      // handle tooltip multiple rerender
      if (tooltipPos?.top != top) {
        setTooltipPos({ top: top, left: left });
        setTooltipData(context.tooltip);
      }
    }
  });

  const onClick = (_, clickedBarData) => {
    overviewClick(_, clickedBarData) ||
      history({
        [field === "leadAddress" ? "borough" : field]: [
          labels[clickedBarData[0]?.index],
        ],
      });
  };
  const options = {
    plugins: {
      tooltip: !!custom
        ? {
            enabled: false,
            external: customTooltip,
          }
        : {
            enabled: true,
          },
      legend: {
        onHover: (event, chartElement) => {
          event.native.target.style.cursor = "pointer";
        },
        onLeave: (event, chartElement) => {
          event.native.target.style.cursor = "default";
        },
        labels: {
          color: isDarkMode ? "#f2f2f2" : "#333238",
        },
        display: true,
        position: "right",
      },
      datalabels: {
        display: false,
      },
    },
    animation: false,
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
    onClick,
  };

  const resultText = () => {
    if (params?.noFunction) {
      return "There are no matching pattern for this chart";
    } else if (noData && !!filterValue?.length) {
      return "No data matches the applied filter";
    } else return `No data found for ${table}!`;
  };

  const resultSubText = () => {
    if (params?.noFunction) {
      return "Try removing and recreating this chart. If this reoccurs, please contact the Software Department.";
    } else if (noData && !!filterValue?.length) {
      return "Try another filter.";
    } else return `You may not have access to any records!`;
  };

  return (
    <div className={`pieContainer ${isDarkMode && "pieContainerDark"}`}>
      <div className="PieBodyStyle">
        {!noData ? (
          <SmallLoadableComp loading={loading}>
            <Doughnut
              className="Pie"
              data={params}
              options={options}
              animation={undefined}
              ref={chartRef}
            />
            {tooltipPos && (
              <GraphTooltip
                data={tooltipData}
                position={tooltipPos}
                visibility={tooltipVisible}
                custom={custom}
              />
            )}
          </SmallLoadableComp>
        ) : (
          <Result
            status="warning"
            title={resultText()}
            subTitle={resultSubText()}
          />
        )}
      </div>
    </div>
  );
};
export default Pie;
