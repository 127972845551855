import { v4 as uuid } from "uuid"
import { updateArrayElement } from "../../../../../../../../../../../utils"
import { SelectOption } from "./components"
import "./SelectPriceConfigurator.scss"
import { useSelector } from "react-redux"

const SelectPriceConfigurator = ({ value = [], onChange }) => {
  const { isDarkMode } = useSelector(state => state.darkMode);
  // Handles the value change
  const onOptionChange = (id) => (changedValues) =>
    onChange(updateArrayElement(value, { id, ...changedValues }, "id"))

  // Deletes an option
  const onOptionDelete = (idToDelete) => () =>
    onChange(value.filter(({ id }) => id !== idToDelete))

  // Adds a new option next to the passed id
  const onOptionAddition = (previousId) => () =>
    onChange(
      value.flatMap((item) =>
        item.id === previousId
          ? [
              item,
              {
                id: uuid(),
                label: "",
                value: 0,
              },
            ]
          : item
      )
    )

  return (
    <div className={`select-price-configurator ${isDarkMode && "select-price-configurator-dark"}`}>
      {value.map(({ id, ...rest }, index) => (
        <SelectOption
          key={id}
          index={index}
          canDelete={value.length !== 1} // If we have only one option we should not be able to delete it
          onOptionChange={onOptionChange(id)}
          onOptionDelete={onOptionDelete(id)}
          onOptionAddition={onOptionAddition(id)}
          {...rest}
        />
      ))}
    </div>
  )
}

export default SelectPriceConfigurator
