import { preventDuplicate } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { formatCurrency } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
import {
  costChartData,
  getProjectCostData,
} from "../../../addWidgetsModalData";

export const projectCost = ({ tables, programFields }) => {
  const data = tables?.["Project Cost"];

  return {
    companyEmployees: () => ({
      statusData: Object.entries(
        repeatedElementSum(preventDuplicate(data, "employeeId"), "company")
      ).map(([label, value]) => ({
        label,
        value,
        color: "#6D8299",
      })),
    }),

    jobsiteAddress: () => ({
      statusData: Object.entries(
        repeatedElementSum(
          preventDuplicate(data, "employeeId"),
          "jobsiteId"
        )?.map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        }))
      ),
    }),

    punchType: () => ({
      statusData: Object.entries(
        repeatedElementSum(preventDuplicate(data, "employeeId"), "punchType")
      ).map(([label, value]) => ({
        label,
        value,
        color: "#6D8299",
      })),
    }),

    role: () => ({
      statusData: Object.entries(
        repeatedElementSum(preventDuplicate(data, "employeeId"), "role")
      ).map(([label, value]) => ({
        label,
        value,
        color: "#6D8299",
      })),
    }),

    jobsiteTotalCost: () => {
      const costDispersion = getProjectCostData({
        data,
        jobsites: tables?.Jobsites,
      });

      const { statusData = [] } = costChartData(
        costDispersion,
        "jobsiteAddress",
        "total",
        formatCurrency
      );

      return {
        statusData,
      };
    },

    jobsiteTotalHours: () => {
      const costDispersion = getProjectCostData({
        data,
        jobsites: tables?.Jobsites,
      });

      const { statusData = [] } = costChartData(
        costDispersion,
        "jobsiteAddress",
        "totalWorkHours"
      );

      return {
        statusData,
      };
    },

    jobsiteAccount: () => {
      const costDispersion = getProjectCostData({
        data,
        jobsites: tables?.Jobsites,
      });

      return costDispersion?.map((el) => ({
        label: el?.jobsiteAddress,
        value: el?.accountName?.accountName,
        color: "#6D8299",
      }));
    },

    jobsiteAccountType: () => {
      const costDispersion = getProjectCostData({
        data,
        jobsites: tables?.Jobsites,
      });

      return costDispersion?.map((el) => ({
        label: `${el.jobsiteAddress} - ${el?.accountName?.accountName || ""}`,
        value: el?.accountName?.accountRecordType,
        color: "#6D8299",
      }));
    },
  };
};
