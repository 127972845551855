import gridFilterDate from "../../../../../../../BasePage/components/utils/gridFilterDate"
import { cellRenders } from "../../../ChargeInformation/AgGridData/components/cellRenders"

const columnDefs = [
  {
    headerName: "Day No#",
    field: "id",
    filter: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    filter: "agNumberColumnFilter",
    unSortIcon: true,
    sortable: true,
    resizable: true,
    minWidth: 200,
    maxWidth: 200,
  },
  {
    headerName: "Day",
    field: "day",
    filter: true,
    sortable: true,
    // filterParams: "dateFilterParams",
    filter: "agDateColumnFilter",
    enableRowGroup: true,
    resizable: true,
    unSortIcon: true,
    cellRenderer: cellRenders({}).date,
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => 
        gridFilterDate(filterLocalDateAtMidnight, cellValue)
    },
  },
  {
    headerName: "Daily Amount",
    field: "dailyAmount",
    filter: true,
    resizable: true,
    enableRowGroup: true,
    filter: "agNumberColumnFilter",
    sortable: true,
    unSortIcon: true,
    cellRenderer: cellRenders({}).price,
  },
]
export default columnDefs
