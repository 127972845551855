import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const AgGridTableContext = createContext();

export const useTableContext = () => {
  return useContext(AgGridTableContext);
};

export const AgGridTableProvider = ({ children }) => {
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const onGridReady = (params) => {
    setGridApi(params.api);

    if (tableLoading) {
      params.api.showLoadingOverlay();
    }
  };

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;
    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedRows(selectedNodes);
  }, [gridApi, setSelectedRows]);

  useEffect(() => {
    if (gridApi) {
      if (tableLoading) {
        gridApi.showLoadingOverlay();
        return;
      }

      gridApi.hideOverlay();
    }
  }, [tableLoading, gridApi]);

  return (
    <AgGridTableContext.Provider
      value={{
        gridRef,
        gridApi,
        onGridReady,
        selectedRows,
        onSelectionChanged,
        setTableLoading,
      }}
    >
      {children}
    </AgGridTableContext.Provider>
  );
};
