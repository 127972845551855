import { API } from "aws-amplify";
import {
  apiDelete,
  apiPost,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../utils";
import { apiRoutes } from "../../../Fleet/utils";
import { datasetsComparator, datasetsFilter } from "../../utils";
import { categoriesActions } from "./categoriesActions";
import { unionWith, uniq } from "lodash";

const {
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  SET_FAVORITE_REPORT,
  REMOVE_FAVORITE_REPORT,

  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  SET_FAVORITE_CATEGORY,
  REMOVE_FAVORITE_CATEGORY,
  UPDATE_CATEGORY_DATASETS,
} = categoriesActions;

export const updateReportConfiguration = async (categoryName, body) =>
  await API.put(
    apiRoutes.reportConfiguration,
    `/${apiRoutes.reportConfiguration}/${categoryName}`,
    { body }
  );

const updateReportApi = async (id, { reportId, userId, ...body }) =>
  await API.put(
    apiRoutes.reportsAvailable,
    `/${apiRoutes.reportsAvailable}/${id}`,
    { body }
  );

export const createCategory = (categoryObj) => async (dispatch) => {
  showLoadingMsg();
  await apiPost(apiRoutes.reportConfiguration, categoryObj)
    .then(() => {
      showSuccessMsg();
      dispatch({
        type: CREATE_CATEGORY,
        payLoad: categoryObj,
      });
    })
    .catch((e) => {
      showErrorMsg();
      console.error(e);
    });
};

export const deleteCategory = (categoryName) => async (dispatch) => {
  showLoadingMsg();

  await apiDelete(apiRoutes.reportConfiguration, categoryName)
    .then(() => {
      dispatch({
        type: DELETE_CATEGORY,
        payLoad: categoryName,
      });
      showSuccessMsg();
    })
    .catch((e) => {
      showErrorMsg();
      console.error(e);
    });
};

export const createReport = (category, report) => async (dispatch) => {
  const { categoryName, reportsAvailable } = category || {};

  const reportToCreate = { ...report, categoryName };

  showLoadingMsg();
  await updateReportApi(report.reportId, reportToCreate)
    .then(() => {
      showSuccessMsg();
      dispatch({
        type: UPDATE_CATEGORY,
        payLoad: {
          categoryName,
          updatedValues: {
            reportsAvailable: [...reportsAvailable, reportToCreate],
          },
        },
      });
    })
    .catch((e) => {
      showErrorMsg();
      console.error("Error", e);
    });

  return Promise.resolve();
};

export const updateReport = (category, report) => async (dispatch) => {
  const {
    categoryName,
    categoryParameters = [],
    datasets = [],
    reportsAvailable = [],
  } = category;
  const {
    datasets: reportDatasets = [],
    reportObj: { definition = {} },
  } = report;

  const { ReportParameters = [] } = definition;

  const validDatasets = reportDatasets.filter(datasetsFilter);

  const updatedValues = {
    categoryParameters: unionWith(
      ReportParameters,
      categoryParameters,
      (x, y) => x?.Name === y?.Name
    ),
    datasets: unionWith(validDatasets, datasets, datasetsComparator),
  };

  showLoadingMsg();

  try {
    await updateReportApi(report.reportId, report)
      .then(
        async (r) =>
          await updateReportConfiguration(categoryName, updatedValues)
            .then(() => showSuccessMsg())
            .catch((e) => {
              console.error(e);
              showErrorMsg();
            })
      )
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
    dispatch({
      type: UPDATE_CATEGORY,
      payLoad: {
        categoryName,
        updatedValues: {
          ...updatedValues,
          reportsAvailable: reportsAvailable.map((arrReport) =>
            arrReport?.reportId === report?.reportId ? report : arrReport
          ),
        },
      },
    });
  } catch (e) {
    console.error(e);
    showErrorMsg();
  }
};

export const deleteReport = (category, reportToDelId) => async (dispatch) => {
  const { categoryName, reportsAvailable = [] } = category;

  const updatedValues = {
    reportsAvailable: reportsAvailable.filter(
      ({ reportId }) => reportId !== reportToDelId
    ),
  };

  showLoadingMsg();
  await apiDelete(apiRoutes.reportsAvailable, reportToDelId)
    .then(() => {
      dispatch({
        type: UPDATE_CATEGORY,
        payLoad: { categoryName, updatedValues },
      });
      showSuccessMsg();
    })
    .catch((e) => {
      console.error(e);
      showErrorMsg();
    });
};

export const setFavoriteReport =
  (category, reportId, identityId) => async (dispatch) => {
    const { categoryName, reportsAvailable = [] } = category;

    const { hasSelectedFavorite = [] } = reportsAvailable.find(
      ({ reportId: id }) => id === reportId
    );

    const updatedReportValues = {
      hasSelectedFavorite: [...hasSelectedFavorite, identityId],
    };

    const updatedValues = {
      reportsAvailable: reportsAvailable.map((report) => {
        const { reportId: id } = report;
        return id === reportId
          ? {
              ...report,
              ...updatedReportValues,
            }
          : report;
      }),
    };
    showLoadingMsg();

    await updateReportApi(reportId, updatedReportValues)
      .then(() => {
        showSuccessMsg({ content: "Marked as favorite!" });
        dispatch({
          type: UPDATE_CATEGORY,
          payLoad: { categoryName, updatedValues },
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

export const removeFavoriteReport =
  (category, reportId, identityId) => async (dispatch) => {
    const { categoryName, reportsAvailable = [] } = category;

    const { hasSelectedFavorite } = reportsAvailable.find(
      ({ reportId: id }) => id === reportId
    );

    const updatedReportValues = {
      hasSelectedFavorite: hasSelectedFavorite?.filter(
        (exIdentityId) => exIdentityId !== identityId
      ),
    };

    const updatedValues = {
      reportsAvailable: reportsAvailable.map((report) => {
        const { reportId: id } = report;
        return id === reportId
          ? {
              ...report,
              ...updatedReportValues,
            }
          : report;
      }),
    };
    showLoadingMsg();

    await updateReportApi(reportId, updatedReportValues)
      .then(() => {
        showSuccessMsg({ content: "Removed from favorites!" });
        dispatch({
          type: UPDATE_CATEGORY,
          payLoad: { categoryName, updatedValues },
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

export const setFavoriteCategory =
  (category, identityId) => async (dispatch) => {
    const { categoryName, reportsAvailable = [] } = category || {};

    const updatedValues = {
      reportsAvailable: reportsAvailable.map((report) => {
        const { hasSelectedFavorite } = report;
        return {
          ...report,
          hasSelectedFavorite: uniq([...hasSelectedFavorite, identityId]),
        };
      }),
    };
    showLoadingMsg();

    try {
      await Promise.allSettled(
        reportsAvailable.map(
          async ({ reportId }) =>
            await setFavoriteReport(category, reportId, identityId)(dispatch)
        )
      );

      showSuccessMsg({ content: "Marked as favorite!" });
      dispatch({
        type: UPDATE_CATEGORY,
        payLoad: { categoryName, updatedValues },
      });
    } catch (e) {
      console.error(e);
      showErrorMsg();
    }
  };

export const removeFavoriteCategory =
  (category, identityId) => async (dispatch) => {
    const { categoryName, reportsAvailable = [] } = category || {};

    const updatedValues = {
      reportsAvailable: reportsAvailable.map((report) => {
        const { hasSelectedFavorite } = report;

        return {
          ...report,
          hasSelectedFavorite: hasSelectedFavorite.filter(
            (id) => id !== identityId
          ),
        };
      }),
    };

    showLoadingMsg();

    try {
      await Promise.allSettled(
        reportsAvailable.map(
          async ({ reportId }) =>
            await removeFavoriteReport(category, reportId, identityId)(dispatch)
        )
      );

      showSuccessMsg({ content: "Removed from favorites!" });
      dispatch({
        type: UPDATE_CATEGORY,
        payLoad: { categoryName, updatedValues },
      });
    } catch (e) {
      console.error(e);
      showErrorMsg();
    }
  };
