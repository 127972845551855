import { useNavigate } from "react-router-dom";

const CompanyLogo = ({ src, style }) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate("/")} className="centeredItem">
      <img className="sidebarCompanyLogo" src={src} alt="" style={style} />
    </div>
  );
};

export default CompanyLogo;
