export class RoofProtectionPLI {
  id;
  approved = false;
  serviceId = 5;

  length;
  width;
  stories;

  details;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;
  lock = false;

  constructor(id, taxRate = 0) {
    this.id = id;
    this.taxRate = taxRate;
  }
}

export class AlternateRoofProtectionPLI {
  id;
  approved = false;
  serviceId = 5;

  length;
  width;
  stories;

  details;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  taxRate;
  taxAmount = 0;
  totalPrice = 0;
  lock = false;
  isAlternate = true;
  constructor(id, taxRate = 0) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
