//@ts-check
import axios from "axios";

export const getCommonQuestions = async () => {
  try {
    const apiUrl = `https://leadmanager-sockets.ue.r.appspot.com/api/commonWords`;
    // `http://localhost:8080/api/commonWords`;
    const { data } = await axios.get(apiUrl);
    return data;
  } catch (error) {
    console.error("Error creating thread:", error);
  }
};
