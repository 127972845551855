import React, { useState } from "react";
import { useSelector } from "react-redux";

import { UploadModal } from "./components";
import { useProgressComponent } from "../../../hooks";
import MondayButton from "../MondayButton/MondayButton";
import ProgressComponent from "../ProgressComponent/ProgressComponent";
import { UploadRecordsProvider } from "./context/UploadRecordsContext";
import { UploadIcon } from "../../SidebarPages/Documentation/View/assets";

const ACTION_TYPE = "Upload";

const UploadRecords = ({ title, rowData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { topicCategories } = useSelector((state) => state.topicCategories);

  const category = topicCategories.find(
    (category) => category.categoryName === title
  );

  if (!category) return <></>;

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: title, actionType: ACTION_TYPE });

  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<UploadIcon />}
        onClick={() => setIsOpen(true)}
      >
        Upload
      </MondayButton>
      <UploadRecordsProvider
        {...{
          title,
          setIsOpen,
          updateProgressStatus,
          setVisibleCreationProgress,
        }}
      >
        {isOpen && <UploadModal {...{ isOpen, setIsOpen, title, rowData }} />}
      </UploadRecordsProvider>

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: title,
            actionType: ACTION_TYPE,
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setIsOpen(false);
              setVisibleCreationProgress(false);
            },
          }}
        />
      )}
    </>
  );
};

export default UploadRecords;
