import { message } from "antd";
import { isWindows } from "react-device-detect";

export const EditableModeWrapper = ({ isWritable, children }) => (
  <div
    onClick={() => !isWritable && message.error("Please enable Edit Mode!")}
    style={{
      cursor: isWindows ? "default" : "not-allowed",
    }}
  >
    {children}
  </div>
);
