import {
  availableEstimations,
  getDaysWithProgress,
  onlyEstWithScopeFalse,
} from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { ProgressStatistics } from "./totals";

export const quickStatisticsForSchedule_days_with_progress = ({
  scheduleId,
  daysShown,
  toBeScheduled,
  typeOfWork,
}) => {
  const daysWithProgress = getDaysWithProgress(
    scheduleId,
    daysShown,
    toBeScheduled,
    typeOfWork
  );
  return { daysWithProgress };
};

export const quickStatisticsForSchedule_by_toBeScheduled = ({
  toBeScheduled,
}) => {
  const { progress = 0 } = ProgressStatistics.scheduleTotal(toBeScheduled);

  return {
    scheduleTotalProgressPercentage: progress,
  };
};

export const quickStatistics_Schedule_and_Project = ({
  project = {},
  toBeScheduled = {},
  schedules = [],
  typeOfWork = "",
}) => {
  const onlyAvaiableEstimations =
    onlyEstWithScopeFalse({ ...toBeScheduled }) || {};

  const filteredEstimations =
    Object.fromEntries(
      Object.entries(onlyAvaiableEstimations)
        ?.map(([key, value]) => [
          key,
          value?.filter((service) => !service?.isNotAppliedForProgress),
        ])
        ?.filter(([key, value]) => value && value?.length > 0)
    ) || {};

  const totalEstimations = Object.keys(filteredEstimations).length;

  let totalScheduleProgressPercentage = Object.values(
    filteredEstimations
  )?.reduce(
    (total, value) =>
      total + ProgressStatistics.estimationTotal(value).progressPercentage,
    0
  );

  const scheduleTotalProgressPercentage =
    totalEstimations > 0
      ? Number((totalScheduleProgressPercentage / totalEstimations).toFixed(2))
      : 0;

  return {
    scheduleTotalProgressPercentage: scheduleTotalProgressPercentage || 0,
  };
};
