import { message } from "antd";
import { RentalsIcon } from "../../../../../../icons";
import { currencyFormater } from "../../../../../../utils/currencyFormater";
import { MondayButton } from "../../../../../commonComponents";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { evaluateMathExpression, toFixedFormatter } from "../formatters";
import { evaluateMonth } from "../formatters/evaluators";

export const sidewalkShed = [
  {
    headerName: "ID",
    headerTooltip: "ID",
    field: "id",
    // minWidth: 65,
    // width: 65,
    editable: false,
    // rowDrag: true,
  },
  {
    headerName: "Approved",
    headerTooltip: "Approved",
    field: "approved",
    // minWidth: 150,
    // width: 150,
    editable: false,
    cellRenderer: "CheckboxRenderer",
    //   cellRendererParams: {
    //   isWritable: this.props.isWritable,
    // },
  },
  {
    headerName: "L",
    headerTooltip: "Length",
    field: "length",
    // width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "H",
    headerTooltip: "Height",
    field: "height",
    // width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "W",
    headerTooltip: "Width",
    field: "width",
    // width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "Addons",
    headerTooltip: "Addons",
    field: "addons",
    width: 200,
    minWidth: 140,
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    suppressPadding: true,
    cellRendererParams: {
      suppressPadding: true,

      suppressCount: true,
      // checkbox: true,
      innerRenderer: "SidewalkShedAddonsRenderer",
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
    },
    valueGetter: (params) => {
      // if (params.data.isJump.checked) {
      //   return null;
      // }
      // else {
      return params?.data?.addons;
      // }
    },
    valueFormatter: (params) => {
      if (params?.data?.addons) {
        return `(${params?.data?.addons?.length})`;
      } else {
        return "N/A";
      }
    },
    // valueFormatter: evaluateDimensionExpression,
  },
  {
    headerName: "Description",
    headerTooltip: "Description",
    field: "description",
    // width: 150,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "Internal Note",
    headerTooltip: "Internal Note",
    field: "note",
    // width: 150,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "Jump",
    headerTooltip: "Jump",
    field: "isJump",
    editable: false,
    cellRenderer: "JumpRenderer",
    // minWidth: 70,
    // width: 100,
    // valueGetter: params => {
    //     return {isJump: params.data.isJump, jumpWidth: params.data.jumpWidth};
    // },
  },
  {
    headerName: "PPU",
    headerTooltip: "Price Per Unit",
    field: "ppu",
    cellRenderer: "SidewalkShedPPUAdvisor",
    minWidth: 200,
    // width: 200,
  },
  {
    headerName: "Rent",
    headerTooltip: "Rent",
    field: "rent",
    // width: 80,
    cellRenderer: "SidewalkShedRentAdvisor",
  },
  {
    headerName: "Months",
    headerTooltip: "Months",
    field: "months",
    // width: 80,
    valueFormatter: evaluateMonth,
  },

  {
    headerName: "Price",
    headerTooltip: "Price",
    field: "price",
    hide: false,
    // width: 80,
    getQuickFilterText: () => "",
  },
  {
    headerName: "Lock",
    field: "lock",
    cellRenderer: "CheckboxRenderer",
    // minWidth: 70,
    // width: 70,
    editable: false,
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    valueFormatter: ({ value = 0 }) => `${value * 100}%`,
    editable: false,
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    valueFormatter: ({ value = 0 }) =>
      currencyFormater(forceToNumber(value), false),
    editable: false,
  },
  {
    headerName: "Total",
    field: "totalPrice",
    valueFormatter: ({ value = 0 }) =>
      currencyFormater(forceToNumber(value), false),
    editable: false,
  },
];
