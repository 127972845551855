import { API } from "aws-amplify";
import { fetchAllData, fetchData, lazyFetch } from "../../../../../utils";
import { useMemo } from "react";
import { REQUEST_API_KEYS } from "./constants";

const projectApiData = {
  func: async (requestType) =>
    await fetchData({
      endpoint: "projects",
      queryStringParams: {
        queryStringParameters: {
          keysToInclude: JSON.stringify(
            REQUEST_API_KEYS?.[requestType]?.projects
          ),
        },
      },
    }),
  table: "projects",
};

const schedulingApiData = {
  func: async (requestType) =>
    await fetchData({
      endpoint: "scheduling",
      queryStringParams: {
        queryStringParameters: {
          keysToInclude: JSON.stringify(
            REQUEST_API_KEYS?.[requestType]?.scheduling
          ),
        },
      },
    }),
  table: "schedules",
};

const projectExecutiveApiData = {
  func: async () => await fetchData({ endpoint: "projectExecutives" }),
  table: "projectExecutives",
};

const safetyApiData = {
  func: async () => await fetchData({ endpoint: "safety" }),
  table: "safety",
};

const accountApiData = {
  func: async () =>
    await lazyFetch({
      tableName: "accounts",
      listOfKeys: [
        "accountId",
        "accountName",
        "projectExecutive",
        "teamsConfiguration",
      ],
    }),
  table: "accounts",
};

const teamsApiData = {
  func: async () => await API.get("teams", "/teams"),
  table: "teams",
};

const driversApiData = {
  func: async () =>
    await lazyFetch({
      tableName: "drivers",
      listOfKeys: ["driverId", "driverName", "driverStatus"],
      filterKey: "driverStatus",
      filterValue: "Ready to Work",
    }),
  table: "drivers",
};

const fleetApiData = {
  func: async () =>
    await lazyFetch({
      tableName: "fleet",
      listOfKeys: ["fleetId", "fleetName", "fleetStatus"],
      filterKey: "fleetStatus",
      filterValue: "Active",
    }),
  table: "fleet",
};

/**
 *
 * @param {String} requestType - the type of the request we are creating
 * @returns  an array of promises that will be resolved with the data from the api calls
 */
const useRequestApiCalls = (requestType, currentPageKey) => {
  const allTypes = {
    Estimation: [
      projectApiData,
      // accountApiData,
      teamsApiData,
      projectExecutiveApiData,
      {
        func: async () =>
          await fetchData({
            endpoint: "opportunities",
            query: "opportunities",
            queryStringParams: {
              queryStringParameters: {
                keysToInclude: JSON.stringify(
                  REQUEST_API_KEYS.Estimation.opportunities
                ),
              },
            },
          }),
        table: "opportunities",
      },
    ],

    "Permit Drawing": [
      projectApiData,
      accountApiData,
      projectExecutiveApiData,
      teamsApiData,
    ],

    Scheduling: [
      projectApiData,
      accountApiData,
      teamsApiData,
      projectExecutiveApiData,
    ],

    "Schedule Change": [
      schedulingApiData,
      // accountApiData,
      teamsApiData,
      // projectExecutiveApiData,
    ],

    Dispatch: [
      projectApiData,
      schedulingApiData,
      driversApiData,
      fleetApiData,
      teamsApiData,
    ],

    "Safety Inspection": [projectExecutiveApiData, safetyApiData, teamsApiData],

    Project: [
      {
        func: async () =>
          await fetchAllData({
            endpoint: "projects",
            resultId: "projectId",
            resultPosition: "projects",
            otherStringParams: {
              keysToInclude: JSON.stringify(REQUEST_API_KEYS.Project.projects),
            },
          }),
        table: "projects",
      },
      {
        func: async () =>
          await fetchAllData({
            endpoint: "accounts",
            resultId: "accountId",
            resultPosition: "accounts",
            otherStringParams: {
              keysToInclude: JSON.stringify(REQUEST_API_KEYS.Project.accounts),
            },
          }),
        table: "accounts",
      },
      projectExecutiveApiData,
      teamsApiData,
    ],

    Claim: [safetyApiData, teamsApiData],

    Incident: [projectApiData, schedulingApiData, teamsApiData],

    "Service Inspection": [
      projectApiData,
      accountApiData,
      teamsApiData,
      projectExecutiveApiData,
    ],

    Documentation: [
      {
        func: async () => fetchData({ endpoint: "docConfiguration" }),
        table: "docConfiguration",
      },
      teamsApiData,
    ],

    "Change Order": [
      projectApiData,
      // accountApiData,
      teamsApiData,
      projectExecutiveApiData,
    ],

    Opportunity: [accountApiData, teamsApiData, projectExecutiveApiData],

    "Work Order": [fleetApiData, teamsApiData],

    "Fleet Inventory": [teamsApiData],
  };

  const requestApiCalls = useMemo(
    () =>
      allTypes?.[requestType]?.map(({ func, table }) => {
        return new Promise(
          async (resolve, reject) =>
            await func(requestType)
              .then((res) => {
                if (Array.isArray(res)) {
                  return resolve({ [table]: res });
                } else if (typeof res === "object") {
                  /**This removes the last evaluated key from the object and only return the data */
                  let noLastEvalKey = Object.keys(res)
                    ?.map((key) => {
                      if (key?.toLowerCase() !== "lastevaluatedkey") {
                        return { [table]: res[key] };
                      }
                      return null;
                    })
                    ?.filter(Boolean)?.[0];

                  if (res?.LastEvaluatedKey !== undefined) {
                    const { userId, ...rest } = res?.LastEvaluatedKey;

                    Object.assign(currentPageKey.current, {
                      [table]: res?.LastEvaluatedKey?.[Object.keys(rest)?.[0]],
                    });
                  }

                  return resolve(noLastEvalKey);
                }
              })
              .catch((err) => reject(err))
        );
      }),
    [requestType]
  );

  return { requestApiCalls };
};

export default useRequestApiCalls;
