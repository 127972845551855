import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const defects = (defectsData, updatedDynamicStates, IdHandler) => {
  return {
    driverName: () => ({
      title: "Defects: Driver Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Defects",
        field: "driverName",
        content: uniq(defectsData?.map((a) => a?.driverName))?.map(
          (driverName, index) => ({
            key: index,
            title: driverName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.driverName === driverName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Defect By " + driverName,
            contentsHandler: " Defects By " + driverName,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),

    defectName: () => ({
      title: "Defect Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Defects",
        field: "defectName",
        content: uniq(defectsData?.map((a) => a?.defectName))?.map(
          (defectName, index) => ({
            key: index,
            title: defectName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.defectName === defectName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: defectName,
            contentsHandler: defectName,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),

    defectStatus: () => ({
      title: "Defect Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Defects",
        field: "defectStatus",
        content: uniq(defectsData?.map((a) => a?.defectStatus))?.map(
          (defectStatus, index) => ({
            key: index,
            title: defectStatus,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.defectStatus === defectStatus
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: defectStatus,
            contentsHandler: defectStatus,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),

    fleetName: () => ({
      title: "Defects: Fleet Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Defects",
        field: "fleetName",
        content: uniq(defectsData?.map((a) => a?.fleetName))?.map(
          (fleetName, index) => ({
            key: index,
            title: fleetName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.fleetName === fleetName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Defect By " + fleetName,
            contentsHandler: " Defects By " + fleetName,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),

    defectPriority: () => ({
      title: "Defect Priority",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Defects",
        field: "defectPriority",
        content: uniq(defectsData?.map((a) => a?.defectPriority))?.map(
          (defectPriority, index) => ({
            key: index,
            title: defectPriority,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.defectPriority === defectPriority
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: defectPriority,
            contentsHandler: defectPriority,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),

    inspectionName: () => ({
      title: "Defects: Inspection Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Defects",
        field: "inspectionName",
        content: uniq(defectsData?.map((a) => a?.inspectionName))?.map(
          (inspectionName, index) => ({
            key: index,
            title: inspectionName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.inspectionName === inspectionName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Defect By " + inspectionName,
            contentsHandler: " Defects By " + inspectionName,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),
  };
};
