import React from "react";
import MondayButton from "../../../MondayButton/MondayButton";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { AddIcon } from "../../../../Header/components/GoogleEvents/assets";
import { useUploadRecordsContext } from "../../context/UploadRecordsContext";

const Footer = ({ onCancel }) => {
  const { onResolve, conflictsButtonRef } = useUploadRecordsContext();

  return (
    <>
      <MondayButton
        className="mondayButtonRed"
        Icon={<XIcon />}
        onClick={onCancel}
      >
        Close
      </MondayButton>
      {/* <MondayButton
        disabled={true}
        Icon={<AddIcon />}
        onClick={onResolve}
      >
        Resolve Conflicts
      </MondayButton> */}
    </>
  );
};

export default Footer;
