import { useState } from "react";
import { Alert, Popconfirm, Tooltip, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { showLoadingMsg } from "../../../../../../../utils";
import { endLoggedTasks } from "../../../../../../../actions/loggedOnTasks";
import { MondayButton } from "../../../../../../commonComponents";
import { TimerIcon } from "../../../../../../Header/subcomponents";
import { StopIcon } from "../../../../../../../assets";
import DetailedTimeRemaining from "../../../../../../../utils/DetailedTimeRemaining";

export const TaskTimerInEdit = ({ updatedTask, setUpdatedTask }) => {
  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [isLoggedOnTask, setIsLoggedOnTask] = useState(false);

  const startTimer = () => {
    setUpdatedTask((prev) => ({
      ...prev,
      totalTimeLogged: {
        timeStarted: moment().valueOf(),
        timeFinished: null,
        startedBy: userConfiguration?.nameOfUser,
      },
      taskTimerDisabled: true,
    }));

    setIsLoggedOnTask(true);
    message.info("You have logged on task");
  };

  //Cancels timer on task
  const onTimerStop = () => {
    showLoadingMsg();
    const now = moment()?.valueOf();

    let totalTimeLogged = {
      timeStarted: updatedTask.totalTimeLogged.timeStarted,
      timeFinished: now,
      startedBy: updatedTask.totalTimeLogged.startedBy,
      finishedBy: userConfiguration?.nameOfUser,
    };
    let body = [
      { key: "totalTimeLogged", value: totalTimeLogged },
      { key: "timerFinished", value: true },
      { key: "taskTimerDisabled", value: false },
    ];
    dispatch(endLoggedTasks(updatedTask.taskId, body, updatedTask));

    setUpdatedTask((prev) => ({
      ...prev,
      totalTimeLogged: {
        ...prev.totalTimeLogged,
        timeFinished: now,
        finishedBy: userConfiguration?.nameOfUser,
      },
      timerFinished: true,
    }));
  };

  return updatedTask.totalTimeLogged?.timeFinished === null ||
    isLoggedOnTask ? (
    <div className="loggedIn-section">
      <Popconfirm
        title="Are you sure you want to stop the timer?"
        onConfirm={onTimerStop}
        placement="right"
        okText="Yes"
        cancelText="No"
      >
        <Tooltip
          title={
            <>
              <h6>{`Time started: ${moment?.(
                updatedTask.totalTimeLogged.timeStarted
              )?.format("lll")}`}</h6>
              <h6>{`Started by: ${
                _.isEmpty(updatedTask.totalTimeLogged)
                  ? userConfiguration?.nameOfUser
                  : updatedTask.totalTimeLogged.startedBy
              }`}</h6>
            </>
          }
          overlayClassName="loggedIn-task-info"
        >
          <MondayButton
            Icon={<StopIcon />}
            className="stop-timer-from-task"
            disabled={updatedTask.totalTimeLogged.timeFinished !== null}
          >
            Stop timer
          </MondayButton>
        </Tooltip>
      </Popconfirm>
    </div>
  ) : updatedTask.timerFinished ? (
    <Alert
      message="Logged finished"
      type="success"
      showIcon
      banner
      action={
        <Tooltip
          title={
            <div>
              <h6>{`Time started: ${moment(
                updatedTask.totalTimeLogged.timeStarted
              )?.format("lll")}`}</h6>
              <h6>{`Started by: ${updatedTask.totalTimeLogged.startedBy}`}</h6>
              <h6>{`Finished by: ${updatedTask.totalTimeLogged.finishedBy}`}</h6>
              <DetailedTimeRemaining
                text="Total time logged:"
                className="task_logged_time"
                show={updatedTask.timerFinished}
                start={updatedTask.totalTimeLogged.timeStarted}
                end={updatedTask.totalTimeLogged.timeFinished}
                includeDays={true}
                includeHours={true}
                includeMinutes={true}
                includeSeconds={true}
              />
            </div>
          }
          overlayClassName="loggedIn-task-info"
        >
          <div className="more-info-square">?</div>
        </Tooltip>
      }
    />
  ) : (
    <MondayButton
      onClick={startTimer}
      className="mondayButtonBlue"
      tooltipCategory="Tasks"
      tooltipKey="startTimer"
      style={{ marginTop: "20px" }}
      Icon={
        <span style={{ fill: "#fff" }}>
          <TimerIcon />
        </span>
      }
      disabled={
        updatedTask.taskTimerDisabled ||
        updatedTask.taskStatus === "Cancelled" ||
        updatedTask.taskStatus === "Completed"
      }
    >
      Start Timer
    </MondayButton>
  );
};
