import { getMonthsTimestamps } from "../../../../../utils"
import { months } from "../../../../addWidgetsModalData"

export const fleet = ({ tables, circleProgressPicker }) => {
  return {
    fleetYear: () => ({
      percent: (
        (tables?.Fleet?.filter((a) => a?.fleetYear === circleProgressPicker)?.length /
          tables?.Fleet?.length) *
        100
      ).toFixed(1),

      data: tables?.Fleet?.filter((a) => a?.fleetYear === circleProgressPicker)?.length,
    }), //fleetYear
    registrationExpDate: () => ({
      percent: (
        (tables?.Fleet?.filter(
          (a) =>
            a?.registrationExpDate >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.registrationExpDate <=
              getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length /
          tables?.Fleet?.filter((a) => a?.registrationExpDate >= getMonthsTimestamps()[0]).length) *
        100
      ).toFixed(1),

      data: tables?.Fleet?.filter(
        (a) =>
          a?.registrationExpDate >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
          a?.registrationExpDate <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
      ).length,
    }), //registrationExpDate
    inspectionExpDate: () => ({
      percent: (
        (tables?.Fleet?.filter(
          (a) =>
            a?.inspectionExpDate >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.inspectionExpDate <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length /
          tables?.Fleet?.filter((a) => a?.inspectionExpDate >= getMonthsTimestamps()[0]).length) *
        100
      ).toFixed(1),

      data: tables?.Fleet?.filter(
        (a) =>
          a?.inspectionExpDate >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
          a?.inspectionExpDate <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
      ).length,
    }), //inspectionExpDate
  } //Fleet
}
