function checkDateInput({ fields, formItemName }) {
  let dynamicField = fields?.find(
    ({ formItemName: fN }) => fN === formItemName
  );
  if (dynamicField) {
    return dynamicField?.type?.toLowerCase() === "dateinput";
  }
  return false;
}

export default checkDateInput;
