import { useState, useEffect } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

import filterStateConverter from "../../BasePageData/filterStateConverter";
import { removeEmptyConditionsAndFilters } from "../../../../../utils";
import { useAdvancedFilterContext } from "../../../../commonComponents/AdvancedFilters/context";
import { initialFilters } from "../../../../commonComponents/AdvancedFilters/utils";
import { autoSizeAllColumns } from "../../../../commonComponents/DynamicAgGridTable/utils/autoSizeColumns";

const { Option } = Select;

const FilterSelection = ({
  templateOptions,
  columnApi,
  gridApi,
  setFiltersData,
  customFilterTitle,
  filterSearchData,
  clearFilterBtn,
  rowData,
}) => {
  const { preferences } = useSelector((state) => state.preferences);

  const selectedPreferences = preferences?.basepages?.[customFilterTitle];

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const { setFilters } = useAdvancedFilterContext();

  const onFilterChange = async (value) => {
    if (value === "DEFAULT") {
      clearFilterBtn();
      setFilters(initialFilters);
      autoSizeAllColumns(rowData, { gridApi, columnApi });
    } else {
      const advancedFilters = removeEmptyConditionsAndFilters(
        selectedPreferences?.[value]?.advancedFiltersState || []
      );
      setFilters((prev) =>
        advancedFilters?.length > 0 ? advancedFilters : prev
      );
      if (advancedFilters?.length > 0) await filterSearchData(advancedFilters);

      if (selectedPreferences[value]?.columnState?.length > 0) {
        const { columnState, groupState, filterState, masterDetailState } =
          selectedPreferences[value];

        filterStateConverter(
          { gridApi, columnApi },
          { filterState, columnState, groupState },
          setFiltersData
        );

        setTimeout(() => {
          gridApi?.forEachDetailGridInfo?.((params) => {
            filterStateConverter(
              {
                gridApi: params.api,
                columnApi: params?.columnApi,
                filterState,
              },
              { ...masterDetailState },
              setFiltersData,
              true
            );
          });
        }, 500);
      }
    }
    setSelectedTemplate(value);
  };

  useEffect(() => {
    if (gridApi) {
      onFilterChange(selectedPreferences?.defaultFilter);
    }
  }, [selectedPreferences?.defaultFilter, gridApi]);

  return (
    <Select
      value={selectedTemplate ? selectedTemplate : "DEFAULT"}
      id="dynamicSelect234"
      onChange={onFilterChange}
      className="dynamicSelect"
      popupClassName={`dynamicSelectDropdown ${isDarkMode && "darkDropDown"}`}
      suffixIcon={
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="#000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.20354 3.10762L5.17386 7.05327C5.31572 7.19487 5.48429 7.30722 5.66988 7.38388C5.85548 7.46054 6.05447 7.5 6.25543 7.5C6.45639 7.5 6.65537 7.46054 6.84097 7.38388C7.02657 7.30722 7.19513 7.19487 7.33699 7.05327L11.3073 3.1037C11.5199 2.88919 11.6641 2.61701 11.7218 2.3213C11.7795 2.0256 11.7482 1.71952 11.6317 1.44145C11.5153 1.16339 11.3189 0.925712 11.0672 0.758231C10.8155 0.59075 10.5197 0.500918 10.2169 0.5H2.27626C1.9731 0.501301 1.67714 0.591996 1.4258 0.760616C1.17445 0.929236 0.979014 1.16821 0.864195 1.44732C0.749376 1.72643 0.720332 2.03314 0.780736 2.32867C0.841139 2.6242 0.988277 2.89527 1.20354 3.10762Z"
            fill="#323338"
          />
        </svg>
      }
    >
      {Object.keys(templateOptions).map((option, idx) => {
        return (
          <Option value={option} key={idx}>
            {option}
          </Option>
        );
      })}
      <Option value={"DEFAULT"}>Default</Option>
    </Select>
  );
};

export default FilterSelection;
