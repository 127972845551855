import React from "react";

import { generateInputFields } from "./helpers";
import { NextStepInputs } from "../NextStepInputs";

const Invoice = ({ inputData, setInputData }) => {
  const inputFields = generateInputFields();

  return <NextStepInputs {...{ inputData, setInputData, inputFields }} />;
};

export default Invoice;
