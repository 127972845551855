import "./CommentChanges.scss";
import { Alert, Divider, Modal } from "antd";
import moment from "moment";
import { Fragment } from "react";
import { CloseIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

const CommentChanges = ({
  showChangesHistory,
  setShowChangesHistory,
  commentChanges = [],
}) => {
  return (
    <Modal
      className={`${"comment-changes-history"}`}
      open={showChangesHistory}
      footer={null}
      title={"Comment Changes"}
      centered={true}
      keyboard
      closeIcon={<CloseIcon />}
      onCancel={() => {
        setShowChangesHistory(false);
      }}
    >
      {commentChanges.map(({ previous, current, updatedAt }, index) => (
        <Fragment key={index}>
          <div className="comment-change">
            <span style={{ whiteSpace: "nowrap" }}>Changed from </span>
            <Alert
              message={previous?.replace(/<\/?[^>]+(>|$)/g, "")}
              type="warning"
            />
            <span> to</span>
            <Alert
              message={current?.replace(/<\/?[^>]+(>|$)/g, "")}
              type="success"
            />
            <span> at</span>
            <Alert
              style={{ whiteSpace: "nowrap" }}
              message={moment(updatedAt).format("MM/DD/YY hh:mm")}
              type="info"
            />
          </div>
          {index !== commentChanges.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Modal>
  );
};

export default CommentChanges;
