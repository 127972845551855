import groupEntriesInShifts from "./groupEntriesInShifts";
import {
  JobsiteType,
  EmployeeType,
  AnalyticsType,
} from "../../../../../../../SidebarPages/FleetMaintenanceView/types/payrollTypes";

/**
 * @typedef props
 * @property {Array<EmployeeType>} crews
 * @property {Array<JobsiteType>} jobsites
 * @property {Array<any>} entries
 * @property {AnalyticsType}
 */

/**
 * @typedef returnType
 * @property {[index: string]: {
 * otHours: number,
 * otAmount: number,
 * regHours: number,
 * ovhHours: number,
 * regAmount: number,
 * ovhAmount: number,
 * totalHours: number,
 * totalAmount: number,
 * overtimeHours: number,
 * }}
 */

/**
 * Get analytics for each team
 * @param {props} props
 * @returns {returnType} Team analytics object
 */

function getTeamCost({ crews, jobsites, entries, analytics }) {
  const shifts = groupEntriesInShifts({
    crews,
    jobsites,
    analytics,
    rowData: entries,
  });

  const groupedShifts = _.groupBy(
    shifts,
    (data) => data?.crewTeamName || "No Team"
  );

  const teamAnalytics = {};

  for (const teamName in groupedShifts) {
    const teamShifts = groupedShifts[teamName];

    let ovhHours = 0;
    let otAmount = 0;
    let regHours = 0;
    let regAmount = 0;
    let ovhAmount = 0;
    let totalHours = 0;
    let totalAmount = 0;
    let overtimeHours = 0;
    let totalOvhAmount = 0;

    for (let i = 0; i < teamShifts.length; i++) {
      const shift = teamShifts[i];
      const shiftOvhHours = shift?.total === 0 ? shift.overtimeHours + 8 : 0;

      otAmount += shift.otAmount + shift.ovhOvertime;
      regHours += shift.workHours;
      ovhAmount += shift.ovhAmount;
      regAmount += shift.regAmount;
      ovhHours += shiftOvhHours || 0;
      totalOvhAmount += shift.totalOvh;
      overtimeHours += shift.overtimeHours;
      totalAmount += shift.total + shift.totalOvh;
      totalHours += shift.workHours + shift.overtimeHours;
    }

    Object.assign(teamAnalytics, {
      [teamName]: {
        otAmount,
        regHours,
        ovhHours,
        regAmount,
        ovhAmount,
        totalHours,
        totalAmount,
        overtimeHours,
      },
    });
  }
  return teamAnalytics;
}

export default getTeamCost;
