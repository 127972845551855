import { Fragment } from "react";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { MondayButton } from "src/components/commonComponents";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets";
import { DateEditor, ReasonEditor } from "../components";
import { AddIcon } from "src/components/Header/components/GoogleEvents/assets";

const columnDefs = [
  {
    headerName: "Start",
    field: "start",
    filter: "agDateColumnFilter",
    editable({ context, data }) {
      return context.currentTimeline !== data.id;
    },
    cellEditor: DateEditor,
    sort: "desc",
    cellRenderer({ value }) {
      if (!value) {
        return "";
      }

      return parseInTz(value).format("MMM DD, YYYY");
    },
  },
  {
    headerName: "End",
    field: "end",
    filter: "agDateColumnFilter",
    editable({ context, data }) {
      return context.currentTimeline !== data.id;
    },
    cellEditor: DateEditor,
    cellRenderer({ value, data, context }) {
      if (data.id === context.currentTimeline && !value) {
        return "CURRENT TIMELINE";
      }

      if (!value) {
        return "";
      }

      return parseInTz(value).format("MMM DD, YYYY");
    },
  },
  {
    headerName: "Reason",
    field: "reason",
    editable({ context, data }) {
      return context.currentTimeline !== data.id;
    },
    cellEditor: ReasonEditor,
  },
  {
    headerName: "Action",
    cellRenderer({ data, context, api }) {
      let maxStartTime = Infinity;
      let maxEndTime = Infinity;

      const { start, end, id } = data;
      const {
        onDelete,
        selectedStatus,
        inactivityStatuses,
        currentTimeline,
        onMakeCurrent,
      } = context;

      if (id !== currentTimeline && !end) {
        maxStartTime = 0;
        maxEndTime = 0;

        api.forEachNode((node) => {
          if (node.data.id === id) {
            return;
          }

          if (node.data.start > maxStartTime) {
            maxStartTime = node.data.start;
          }

          if (node.data.id === currentTimeline) {
            maxEndTime = Date.now();
          } else if (node.data.end > maxEndTime) {
            maxEndTime = node.data.end;
          }
        });
      }

      return (
        <Fragment>
          <MondayButton
            {...{
              Icon: <TrashIcon />,
              className: "mondayButtonRed",
              onClick() {
                onDelete(data);
              },
            }}
          >
            {""}
          </MondayButton>
          {start > maxStartTime &&
            start >= maxEndTime &&
            inactivityStatuses.includes(selectedStatus) && (
              <MondayButton
                {...{
                  Icon: <AddIcon />,
                  className: "mondayButtonBlue",
                  onClick() {
                    onMakeCurrent(data);
                  },
                  style: {
                    marginLeft: "10px",
                  },
                }}
              >
                Make Current Timeline
              </MondayButton>
            )}
        </Fragment>
      );
    },
  },
];

export default columnDefs;
