import { getTotalServicesPrice } from "../Accounting/calculations/servicePrices";

export const generateProjects = ({ projects, schedules }) => {
  return (
    projects.map((row) => {
      // const docsOfProjects =
      //   documentations?.filter((est) => est.recordId === row.projectId) || [];

      const label =
        row?.services &&
        (typeof row?.services === "object"
          ? Object.keys(row.services)
              .flatMap((e) => row?.services[e])
              .filter((serv) => !serv.isScope)
              .map(({ label }) => label)
          : row.services);

      // const missingFiles =
      //   docsOfProjects
      //     .filter((doc) => doc.googleDriveUploads.length === 0)
      //     .map((el) => el.docType).length || 0;

      // const createdDocs = docsOfProjects.flatMap((el) => el.docObject).length;

      const calculateServiceTotal = (estimations) => {
        let estTotals = 0;
        estimations?.length &&
          estimations?.map((estimation) => {
            if (
              Array.isArray(estimation?.services) &&
              estimation?.services?.length > 0
            ) {
              estTotals += getTotalServicesPrice(estimation?.services);
            }
          });
        return estTotals;
      };

      const getEstimationArray = (estimation) => {
        return Object.keys(estimation)?.map((key, index) => {
          return {
            services: estimation[key],
          };
        });
      };

      const EST = getEstimationArray(row?.services || []);

      return {
        ...row,
        projectEstimations: row.services,
        services:
          row?.services &&
          Object.keys(row.services)
            .flatMap((e) => row?.services[e])
            .filter((serv) => !serv.isScope),
        label,
        linkToProject: `${window?.location?.pathname}/${row.projectId}`,
        // statusesDocObj: docsOfProjects.map((el) => el.docStatus),
        // jobSite: docsOfProjects.map((el) => el.recordName),
        // missingFiles: `${missingFiles} Missing Files `,
        // createdDocs: `${createdDocs} Created Docs `,
        // docType: docsOfProjects.map(({ docType }) => docType),
        price: calculateServiceTotal(EST),
        crews: "Crews",
        timePlanned: "Time Planned",
        potentialStartDate: "Potential Start Date",
      };
    }) || []
  );
};
