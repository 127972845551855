import { message } from "antd";

export const payingRentalsTermsHandler = ({
  setPayingTermsModalVisibility,
  dispatchContext,
  estimationId,
  includeWeeks,
  serviceId,
  fakeSOV,
  form,
}) => {
  if (Object.values(form.getFieldsValue())?.some((value) => !!value)) {
    const newSOV = {
      ...fakeSOV,
      services: fakeSOV?.services?.map((serviceData) =>
        serviceData?.serviceId === serviceId &&
        estimationId === serviceData?.estimationId
          ? {
              ...serviceData,
              rentalPaymentTerms: {
                name: form.getFieldsValue()?.input,
                ...(includeWeeks
                  ? { range: form.getFieldValue("dateRange") }
                  : { startingDate: form.getFieldValue("startingDate") }),
                description: form.getFieldsValue()?.payingTerms,
              },
            }
          : serviceData
      ),
    };

    dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });
    setPayingTermsModalVisibility(false);
  } else {
    message.error("Complete all fields!!");
  }
};
