const gridFilters = [
  {
    globalKeyId: "09299cb5-82fc-45e5-acf0-86ddfa3efaf7",
    label: "Vehicle",
    type: "Dropdown",
    key: "fleetName",
  },
  {
    globalKeyId: "446605c5-d7f9-4d63-b4ec-564c8f7f8cce",
    label: "Driver",
    type: "Dropdown",
    key: "driverName",
  },
  {
    globalKeyId: "0aee8468-acbc-4512-a14c-ce17aa40011e",
    label: "Cargo",
    type: "Dropdown",
    key: "cargo",
  },
  {
    globalKeyId: "cfb15853-1191-4243-bff4-9259b4a4f4d6",
    label: "From Location",
    type: "Dropdown",
    key: "fromLocation",
  },
  {
    globalKeyId: "fa0a57f8-912f-48cb-a679-a9e1b291c404",
    label: "To Location",
    type: "Dropdown",
    key: "toLocation",
  },
  {
    globalKeyId: "d58e6809-8db2-4768-8531-7a4a52962f24",
    label: "Start Remark",
    type: "Dropdown",
    key: "startRemark",
  },
  {
    globalKeyId: "456ee0ab-2d9c-41e1-8786-02a84230bc21",
    label: "End Remark",
    type: "Dropdown",
    key: "endRemark",
  },
];

export default gridFilters;
