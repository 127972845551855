import { message } from "antd";
import "./Header.scss";

const Header = ({ setChosenData, chosenData, schedulingData }) => {
  const { from } = chosenData;

  const initialValue = { service: [], selectedId: undefined };

  return (
    <div className="informationHeader">
      Create From:
      {/* {schedulingData?.length > 1 && (
        <button
          className={from === "schedulingAutomatically" ? "selectedButton" : "unselectedButton"}
          onClick={() => setChosenData({ ...initialValue, from: "schedulingAutomatically" })}
        >
          Scheduling Automatically
        </button>
      )} */}{" "}
      <button
        className={
          from === "estimation" ? "selectedButton" : "unselectedButton"
        }
        onClick={() => setChosenData({ ...initialValue, from: "estimation" })}
      >
        Estimation
      </button>
      <button
        className={
          from === "schedulingManually" ? "selectedButton" : "unselectedButton"
        }
        onClick={() => {
          message.info(
            "Scheduling Manually is not available at the moment. We are working to bring you the best experience."
          );
          // setChosenData({ ...initialValue, from: "schedulingManually" });
        }}
      >
        Scheduling Manually
      </button>
      {/* {!schedulingData?.length && ( */}
      {/* )} */}
    </div>
  );
};

export default Header;
