export const viewColumnNames = [
  {
    fieldName: "nameOfUser",
    fieldTitle: "Name",
  },
  {
    fieldName: "updatedAt",
    fieldTitle: "Last seen",
  },
];
