import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import { formatCurrency } from "../../../../utils";
import { generateEstimationsForOpportunities } from "../../../NewDashboardFunctions";

export const opportunities = ({ tables, programFields }) => {
  const projectEstimations = () => {
    let toReturn = {};

    tables?.Projects?.forEach((proj) => {
      Object.entries(proj?.services)?.forEach(([key, value]) => {
        toReturn[key] = value;
      });
    });

    return toReturn;
  };

  return {
    opportunityStatus: () => ({
      statusData: tables?.Opportunities?.map((a) => {
        return {
          label: a?.opportunityAddress,
          value: a?.opportunityStatus,
          color: "#ECDE7D",
          date: a?.createdAt,
          id: a?.opportunityId,
        };
      }),
    }),

    opportunityStage: () => ({
      statusData: tables?.Opportunities?.map((a) => {
        return {
          label: a?.opportunityAddress,
          value: a?.opportunityStage,
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Opportunities?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.opportunityStage?.toLowerCase()
              )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.opportunityId,
        };
      }),
    }),

    totalEstimationsAmount: () => ({
      statusData: tables?.Opportunities?.map((a) => {
        return {
          label: a?.opportunityAddress,
          value: formatCurrency(
            getTotalServicesPrice(
              generateEstimationsForOpportunities(
                a?.estimations,
                projectEstimations()
              )?.flat()
            )
          ),
          color: "#ECDE7D",
          date: a?.createdAt,
          id: a?.opportunityId,
        };
      }),
    }),

    geoFenceInfo: () => ({
      statusData: tables?.Opportunities?.map((a) => {
        return {
          label: a?.opportunityAddress,
          value: a?.geoFenceInfo?.length.toString(),
          color: "#ECDE7D",
          date: a?.createdAt,
          id: a?.opportunityId,
        };
      }),
    }),
  };
};
