import axesTooltip, { pieTooltip } from "../../../../helpers/axesTooltip";

const countOnDate = {
  title: {
    text: "Total Number of Inspections",
  },
  subtitle: {
    text: "Based on Inspection Date",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "dynamicTime",
        yKey: ["taskTitle"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "dynamicTime",
        angleKey: ["taskTitle"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnType = {
  title: {
    text: "Total Number of Inspections",
  },
  subtitle: {
    text: "Based on Inspection Type",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "inspectionType",
        yKey: ["taskTitle"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "inspectionType",
        angleKey: ["taskTitle"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const chartingOptions = [
  {
    optionTitle: "Show total number of inspections based on inspection date",
    params: countOnDate,
  },
  {
    optionTitle: "Show total number of inspections based on inspection type",
    params: countOnType,
  },
  {
    dynamicKeys: [
      {
        key: "dynamicTime",
        keyFunction: "dateGetter",
        optionalParams: [[], "inspectionDate"],
      },
    ],
  },
];

export default chartingOptions;
