import React, { useEffect, useState } from "react";
import HeaderStatistics from "./HeaderStatistics/HeaderStatistics";
import "./styles/WaitingRoomProposal.scss";
import BodyStatistics from "./BodyStatistics/BodyStatistics";
import ProposalCards from "./ProposalCards/ProposalCards";
import HeaderFilter from "./HeaderFilter/HeaderFilter";
function WaitingRoomProposal() {
  return (
    <div className="waiting-room-proposal">
      <div className="waiting-room-proposal_header-statistics">
        <HeaderStatistics />
      </div>
      <div className="waiting-room-proposal_filter-header">
        <HeaderFilter />
      </div>
      <div className="waiting-room-proposal_body-statistics">
        <BodyStatistics />
      </div>
      <div className="waiting-room-proposal_body-drafts">
        <ProposalCards />
      </div>
    </div>
  );
}

export default WaitingRoomProposal;
