export const checkForService = (item, topics) => {
  const fromSettings = window.location.pathname.split("/")[1] === "settings";
  if (fromSettings) {
    return true;
  }

  const selectedTopic = topics.find(
    (topic) => topic.categoryName === item.categoryName
  );

  return selectedTopic?.hasOwnProperty("fromSettings") &&
    selectedTopic.fromSettings === true
    ? true
    : false;
};
