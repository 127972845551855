import React, { createContext, useContext } from "react";
import { useCreateBill } from "../hooks/useCreateBill";

const BillModalContext = createContext();

export const useCreateBillContext = () => {
  return useContext(BillModalContext);
};

export const BillModalProvider = ({ children }) => {
  const newBill = useCreateBill();
  return (
    <BillModalContext.Provider value={{ ...newBill }}>
      {children}
    </BillModalContext.Provider>
  );
};
