import { assignServiceColor } from "../../../../../../../helpers/constants/forProject";
import {
  Checkbox,
  Tooltip,
  Button,
  Alert,
  Radio,
  Badge,
  Watermark,
  Card,
  Statistic,
} from "antd";
import React, { useState, useEffect } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import InspectionModal from "../../../../../../SidebarPages/components/AgGridComponents/InspectionModal";
import { AnimatedCheckBox } from "../../../../../../SidebarPages/Fleet/components/InputComponent/components";
import { ReactComponent as DragIcon } from "../../../../../../../icons/drag.svg";
import { ReactComponent as InformationIcon } from "../../../../../../../icons/information.svg";
import ServicesCheckbox from "../ServicesCheckbox/ServicesCheckbox";
import CustomProgressBar from "../../../../../../commonComponents/CustomProgress/CustomProgressBar";
import "./ServiceMenu.scss";
import {
  ChangeOrderIcon,
  TickIcon,
} from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import {
  fetchAllData,
  filterTables,
  lazyFetch,
  openInNewTab,
} from "../../../../../../../utils";
import {
  detailedComparison,
  newEstimationsAdded,
  newServicesAdded,
  onlyEstWithScopeFalse,
} from "../../../SchedulingFirstPage/helperData";
import { ArrowIcon } from "../../../../../../SidebarPages/Scheduling/ScheduleChangesModal/assets";
import {
  ArrowUpOutlined,
  ArrowsAltOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  FallOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { WarningCircleIcon } from "../../../../../../SidebarPages/Projects/DobTab/assets";
import UndoProgressSchedule from "../../UndoProgressSchedule/UndoProgressSchedule";
import CompareChanges from "../../CompareChanges/CompareChanges";
import { CompareIcon } from "../../../../../../SidebarPages/Estimations/components/EstVersionsTab/assets";
import { WarningIcon } from "../../../../../../../assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { API } from "aws-amplify";
const ReactGridLayout = WidthProvider(RGL);

export const ServiceMenu = ({
  estimationIndex,
  estimationId,
  gridData,
  progressPercentage,
  handleServiceSelected,
  handleLayoutChange,
  project,
  unChangedRowData,
  resetProgressEstimation,
  typeOfWork,
  updateChanges,
  serviceDefinitions,
  thisProjectSchedules,
  createdAt,
  editMode,
}) => {
  const [compareChanges, setCompareChanges] = useState({
    visible: false,
    contnet: {},
  });

  const [est, setEst] = useState([]);

  const onServiceSelect = (e, serviceIndex) => {
    handleServiceSelected(serviceIndex, e);
  };

  const infoIcon = (
    <div style={{ fill: "#1264A3" }}>
      <InformationIcon style={{ width: "21px" }} />
    </div>
  );

  useEffect(() => {
    const fetchEst = async () => {
      try {
        const data = API.get(
          "estimations",
          `/estimations/${estimationId}`
        ).then(setEst);
      } catch (e) {
        console.log(e);
      }
    };
    fetchEst();
  }, []);

  const currentEstimation = est || {};
  // est?.find((estimation) => estimation?.estimationId === estimationId) || {};

  const quickbooksNumber =
    currentEstimation?.quickbooksNumber ||
    currentEstimation?.estimationNumber ||
    estimationIndex + 1;

  const colorAndIconForEsti = (pr = 0) => {
    let color = "",
      icon = "";
    if (pr === 0) {
      color = "#FE4C4A";
      icon = <FallOutlined />;
    } else if (pr > 0 && pr < 50) {
      color = "#FFCB00";
      icon = <RiseOutlined />;
    } else if (pr > 50 && pr < 100) {
      color = "#7FC051";
      icon = <ArrowUpOutlined />;
    } else {
      color = "#2DA365";
      icon = <CheckOutlined />;
    }
    return { color, icon };
  };

  const projectEstimation = onlyEstWithScopeFalse(
    project?.services,
    serviceDefinitions
  )?.[estimationId];

  return (
    <>
      <div className="service-menu-wrapper">
        <Badge
          className="estimation-progress-badge"
          count={
            newEstimationsAdded(
              unChangedRowData?.unchanged?.toBeScheduled || {},
              project?.services || {},
              estimationId
            )
              ? "New"
              : null
          }
          color="#EE8A35"
        >
          <div
            className="est-progress-status-schedule"
            id={estimationIndex === 0 ? "est-progress-status-schedule" : null}
          >
            <div
              key={progressPercentage}
              className="est-progress-status-schedule-body"
              style={{
                width: 176,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <span>Estimate -</span>{" "}
                    <span
                      onClick={() =>
                        openInNewTab(
                          `/estimations/${estimationId}?tab=Take-Off`
                        )
                      }
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#1264A3",
                      }}
                    >
                      {quickbooksNumber}
                    </span>
                  </div>
                  {!!editMode && (
                    <div>
                      {detailedComparison(
                        gridData,
                        projectEstimation,
                        serviceDefinitions,
                        estimationId
                      )?.length > 0 &&
                        typeOfWork !== "Inspection" && (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setCompareChanges({
                                visible: true,
                                contnet: {
                                  scheduleEstimation: gridData || [],
                                  projectEstimation: projectEstimation || [],
                                },
                              })
                            }
                          >
                            {
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 42 37"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5868 36.8772C1.02112 36.8772 0.588539 36.628 0.289061 36.1294C-0.0104163 35.6309 -0.0104163 35.1323 0.289061 34.6338L19.6553 1.23169C19.9548 0.733156 20.3873 0.483887 20.953 0.483887C21.5187 0.483887 21.9513 0.733156 22.2507 1.23169L41.617 34.6338C41.9164 35.1323 41.9164 35.6309 41.617 36.1294C41.3175 36.628 40.8849 36.8772 40.3192 36.8772H1.5868ZM21.1527 14.3433C20.7201 14.3433 20.3624 14.4845 20.0795 14.767C19.7967 15.0495 19.6553 15.4068 19.6553 15.8389V24.0149C19.6553 24.447 19.7967 24.8043 20.0795 25.0868C20.3624 25.3693 20.7201 25.5105 21.1527 25.5105C21.5852 25.5105 21.943 25.3693 22.2258 25.0868C22.5086 24.8043 22.6501 24.447 22.6501 24.0149V15.8389C22.6501 15.4068 22.5086 15.0495 22.2258 14.767C21.943 14.4845 21.5852 14.3433 21.1527 14.3433ZM21.1527 31.0443C21.5852 31.0443 21.943 30.9031 22.2258 30.6206C22.5086 30.3381 22.6501 29.9808 22.6501 29.5487C22.6501 29.1166 22.5086 28.7593 22.2258 28.4768C21.943 28.1943 21.5852 28.0531 21.1527 28.0531C20.7201 28.0531 20.3624 28.1943 20.0795 28.4768C19.7967 28.7593 19.6553 29.1166 19.6553 29.5487C19.6553 29.9808 19.7967 30.3381 20.0795 30.6206C20.3624 30.9031 20.7201 31.0443 21.1527 31.0443Z"
                                  fill="#FE4C4A"
                                />
                              </svg>
                            }
                          </span>
                        )}

                      {currentEstimation?.isChangeOrder ? (
                        <Tooltip title="Change Order">
                          <ChangeOrderIcon fill="red" />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>

                <div style={{ display: "flex", gap: 5 }}>
                  <CustomProgressBar
                    progressDone={Number(progressPercentage) || 0}
                    breakDownProp={true}
                  />
                  {/* <UndoProgressSchedule
                    {...{
                      title: `Reset progress or all days in estimation ${quickbooksNumber}!`,
                      reset: resetProgressEstimation,
                      needed: [],
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </Badge>
        <div
          className="serviceSchedulingSiderMenu"
          id={estimationIndex === 0 ? "serviceSchedulingSiderMenu" : null}
          style={{
            width: "100%",
            padding: 10,
            height: "fit-content",
          }}
        >
          <div
            className="dragable-services-wrapper"
            style={{
              gap: 6,
            }}
          >
            {gridData?.map((service, serviceIndex) => {
              const { isNotAppliedForProgress = false } = service;
              return (
                <div
                  style={{
                    // minWidth: 180,
                    width: "fit-content",
                    border: "none",
                  }}
                  key={service?.label}
                  className="non-drag-service"
                >
                  <Badge
                    count={
                      newServicesAdded(
                        unChangedRowData?.unchanged?.toBeScheduled || {},
                        project?.services || {}
                      )?.[estimationId]?.includes(
                        service?.serviceId?.toString()
                      )
                        ? "New"
                        : null
                    }
                    color="#EE8A35"
                  >
                    <ServicesCheckbox
                      {...{
                        key: serviceIndex,
                        label: service?.label,
                        containerClassName: "dragable-services-container",
                        initialValue: gridData?.[serviceIndex]?.isSelected,
                        labelClassName: "dragable-services-label",
                        primaryStyle: {
                          display: "flex",
                          alignItems: "center",
                          backgroundColor:
                            assignServiceColor?.[service?.serviceId],
                        },
                        onChange: (e) => onServiceSelect(e, serviceIndex),
                        isNotAppliedForProgress,
                      }}
                    />
                  </Badge>
                </div>
              );
            })}
          </div>
        </div>

        {compareChanges?.visible && (
          <CompareChanges
            {...{
              compareChanges: compareChanges,
              onCancel: () =>
                setCompareChanges({ visible: false, contnet: {} }),
              quickbooksNumber,
              updateChanges,
              serviceDefinitions,
              estimationId,
              thisProjectSchedules: thisProjectSchedules
                ?.filter((schedule) => schedule?.createdAt > createdAt)
                ?.sort((a, b) => a?.createdAt - b?.createdAt),
            }}
          />
        )}
      </div>
    </>
  );
};
