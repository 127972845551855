import AccountingConfirmationModal from "../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import AccountingWriteMode from "../../../../../../../../../commonComponents/AccountingWriteMode/AccountingWriteMode";
import DarkModeToggle from "../../../../../../../../../commonComponents/DarkModeToggle/DarkModeToggle";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../Context/Context";
import { darkModeHandler, switchWriteMode } from "../../headerFunctions";
import { Close } from "../../../../../../../../DynamicView/src";
import "./MainHeader.scss";
import {
  discardChangesHandler,
  exitSovViewHandler,
  saveChangesHandler,
} from "./mainHeaderFunctions";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tooltip, Tour, message } from "antd";
import { useBeforeUnload } from "../../../../../../../../../../hooks/useBeforeUnload";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { QuestionCircleFilled } from "@ant-design/icons";
import { PlayVideoTutorial } from "../../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { updateDocumentTitle } from "../../../../../../../../../../utils";
import { EditableModeWrapper } from "../../../EditableModeWrapper";
import StatusChanger from "../ControlPanel/Components/StatusChanger/StatusChanger";

const MainHeader = ({ closeModal }) => {
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);

  const { isLoading, scheduleOfValue } = useSelector(
    (state) => state?.scheduleOfValues
  );
  const darkModeSwitcher = () => darkModeHandler({ dispatchContext, darkMode });
  const { isWritable, fakeSOV, darkMode, errors, sovId, isNextStep } =
    useStateContext();
  const dispatchContext = useDispatchContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { programFields } = useSelector((state) => state.programFields);

  const { scheduleId: currentScheduleId } = useParams();

  const scheduleId = currentScheduleId || sovId;

  const [tourOpen, setTourOpen] = useState(false);
  const [videoTutorialLink, setVideoTutorialLink] = useState(null);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const links = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Project Management")
    ?.subCategories[5]?.videos.filter((item, i) => i >= 1 && i <= 3)
    .map((item) => item);

  useEffect(() => {
    updateDocumentTitle({
      newTitle: `SOV: ${fakeSOV?.projectName} - SOV ${fakeSOV?.SOVNo}`,
    }); // set Title of Tab

    return () => {
      updateDocumentTitle(); // reset document title to "Lead Manager";
    };
  }, [fakeSOV]);

  //remove item in LocalStorage when tab is closed or page is refreshed
  useBeforeUnload(scheduleId);

  const exitSovView = () => {
    exitSovViewHandler({
      setConfirmModalVisibility,
      scheduleOfValue,
      dispatch,
      navigate,
      location,
      fakeSOV,
      isNextStep,
      closeModal,
    });
  };

  const discardChanged = () =>
    discardChangesHandler({
      navigate,
      fakeSOV,
      isNextStep,
      closeModal,
    });

  const saveChanges = () => {
    return saveChangesHandler({
      dispatch,
      scheduleId,
      navigate,
      fakeSOV,
      isNextStep,
      closeModal,
    });
  };

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(({ categoryName }) => categoryName === "sovView")
      ?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "SOV Status": document.querySelector(".statusChangerContainer"),
      "Edit SOV": document.querySelector("#addEstimationButton"),
      "Undo, Redo": document.querySelector(".undoRedoButtonsContainer"),
      "Auto Save": document.querySelector(".autoSaveSwitchContainer"),
      "SOV Tasks": document.querySelector("#tasks-btn"),
      "Edit Logs": document.querySelector("#editLogs-btn"),
      "Export to Excel": document.querySelector(".excelButtonSOV"),
      "Restart Grid": document.querySelector(".svgContainer"),
      "Quick Save": document.querySelector(".quickSaveButton"),
    };
    return stepsMap[title] || null;
  }
  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: darkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = [
    {
      title: "SOV Tutorials",
      description: (
        <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
          {links.map((link) => (
            <MondayButton
              {...{
                className: "mondayButtonBlue guideButton",
                onClick: () => {
                  setShowVideoTutorial(true);
                  setTourOpen(false);
                  setVideoTutorialLink(link.videoLink);
                },
                style: {
                  display: "flex",
                  justifyContent: " center",
                },
                Icon: <VideoTutorialIcon />,
              }}
            >
              {link.videoName}
            </MondayButton>
          ))}
        </div>
      ),
      className: darkMode ? "custom-tour-dark" : "custom-tour-light",
      placement: "center",
    },
    ...mapRefs(steps),
  ];
  return (
    <div
      className={darkMode ? "sov-main-header-dark " : "sov-main-header-light"}
    >
      <div className="sov-main-header-options">
        <div className="sov-main-header-title">{`SOV: ${fakeSOV?.projectName} - SOV ${fakeSOV?.SOVNo}`}</div>
        <AccountingWriteMode
          {...{
            isWritable,
            recordId: scheduleId,
            onSwitchFunction: (newValue) =>
              switchWriteMode({ checked: newValue, dispatchContext }),
            darkMode,
            projectId: fakeSOV?.projectId,
          }}
        />

        <div
          style={{
            marginLeft: 10,
          }}
        >
          <EditableModeWrapper {...{ isWritable }}>
            <StatusChanger />
          </EditableModeWrapper>{" "}
        </div>
      </div>{" "}
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        {" "}
        <div className="darkModeSwitchContainer">
          <DarkModeToggle
            {...{
              onClickFunction: darkModeSwitcher,
              darkMode,
            }}
          />
        </div>{" "}
        <Tooltip title="Guide">
          <QuestionCircleFilled
            style={{ fontSize: 23, color: "#ffffff", cursor: "pointer" }}
            onClick={() =>
              tourSteps.length > 0
                ? setTourOpen(true)
                : message.info("No guide available for this form")
            }
          />
        </Tooltip>
        {fakeSOV?.projectId && !isLoading && (
          <div className="close-btn" onClick={exitSovView}>
            <Close style={{ height: 18, width: 18 }} />
          </div>
        )}
      </div>
      <AccountingConfirmationModal
        {...{
          setVisible: setConfirmModalVisibility,
          visible: confirmModalVisibility,
          onDiscard: discardChanged,
          onConfirm: saveChanges,
          saveButton: !errors,
          darkMode,
          text: errors
            ? "You have to fix errors before saving!"
            : "You have unsaved changes. Do you want to save them?",
        }}
      />
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "SOV Tutorial",
          }}
        />
      )}
    </div>
  );
};

export default MainHeader;
