import {
  filterUsersByAccess,
  getDispatchLocationOptions,
} from "../../../../Header/forms/RequestFormModal/requestFormModalData";
import { searchSafetyAddress } from "../helpers";

const namesToDisable = [
  "dateOfVehicleDamageOrIncident",
  "timeOfVehicleDamageOrIncident",
  "addressOfIncident",
  "truckNumber",
  "nameOfDriver",
];

/**
 * Adds conditional fields to the given fields array based on the provided parameters.
 *
 * @param {Array} fields - The array of fields.
 * @param {number} fieldCategoryIndex - The index of the field category.
 * @param {string} otherInvolvement - The value of the other involvement.
 * @param {Object} field - The field object.
 */
const addConditionalFields = ({
  fields,
  fieldCategoryIndex,
  otherInvolvement,
  field,
}) => {
  if (
    field.formItemName === "wereTheFollowingInvolved" &&
    otherInvolvement === "Other"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherInvolvement",
      label: "Other Involvement",
      required: true,
      placeholder: "Other Involvement",
      category: field.category,
    });
  }
};

/**
 * Generates an array of fields for the vehicle damage safety form.
 *
 * @param {Object} form - The form object.
 * @param {Array} [fleet=[]] - The array of fleet data.
 * @param {Array} [drivers=[]] - The array of driver data.
 * @param {Array} [projects=[]] - The array of project data.
 * @param {string} [defaultId=""] - The default ID.
 * @param {Array} [scheduling=[]] - The array of scheduling data.
 * @param {string} [propAddress=""] - The property address.
 * @param {Array} [dynamicFields=[]] - The array of dynamic fields.
 * @param {string} [otherInvolvement=""] - The other involvement.
 * @param {Array} [schedulesForDate=[]] - The array of schedules for the date.
 * @param {Object} [userConfiguration={}] - The user configuration object.
 * @param {boolean} [mainDisabledField=false] - Whether the main disabled field is enabled or not.
 * @param {boolean} [disabledAllFields=false] - Whether all fields are disabled or not.
 * @param {Function} [onChangeAddress=()=>{}] - The function to handle address change.
 * @param {Function} [changeDateOfIncident=()=>{}] - The function to handle date of incident change.
 * @param {Function} [dispatchSafetyFormData=()=>{}] - The function to dispatch safety form data.
 * @returns {Array} - The array of fields for the vehicle damage safety form.
 */
const vehicleDamageSafetyFields = ({
  form,
  fleet = [],
  drivers = [],
  projects = [],
  defaultId = "",
  scheduling = [],
  propAddress = "",
  dynamicFields = [],
  otherInvolvement = "",
  schedulesForDate = [],
  userConfiguration = {},
  mainDisabledField = false,
  disabledAllFields = false,
  onChangeAddress = () => {},
  changeDateOfIncident = () => {},
  dispatchSafetyFormData = () => {},
}) => {
  let fields = [];

  for (let i = 0; i < dynamicFields.length; i++) {
    let field = structuredClone(dynamicFields[i]);

    delete field?.placeholder;

    if (field.type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
      };
    }

    if (field.type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    }

    if (field.type === "radio") {
      field = {
        ...field,
        style: { borderRadius: 10, backgroundColor: "#f5f5f7", padding: 10 },
      };
    }

    if (field.formItemName === "assignTo") {
      delete field["options"];

      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: filterUsersByAccess(userConfiguration, "Safety"),
        style: { width: 200 },
      };
    }

    if (field.formItemName === "licensePlate") {
      field = {
        ...field,
        disabled: true,
      };
    }

    if (
      field.formItemName === "dateOfVehicleDamageOrIncident" ||
      field.formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(field.formItemName === "dateOfVehicleDamageOrIncident"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
        // disabledDate(e) {
        //   return e > dayjsNY().add(1, "day").startOf("D").valueOf();
        // },
      };
    }

    if (field.formItemName === "timeOfVehicleDamageOrIncident") {
      field = {
        ...field,
        validTime: "past",
        dateField: "dateOfVehicleDamageOrIncident",
        form,
      };
    }

    if (field.formItemName === "nameOfDriver") {
      field = {
        ...field,
        customOptions: drivers?.map((driver) => {
          return {
            value: driver?.driverId,
            label: driver?.driverName,
          };
        }),
      };
    }

    if (field.formItemName === "reportPreparedBy") {
      delete field?.options;
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: userConfiguration?.allUsers?.Items?.map((user) => {
          return { value: user?.identityId, label: user?.nameOfUser };
        }),
        style: { width: 200 },
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    }

    if (field.formItemName === "addressOfIncident") {
      field = {
        ...field,
        disabled: mainDisabledField || disabledAllFields,
        onSelect: onChangeAddress,
        initialValue: propAddress,
        groupedOptions: getDispatchLocationOptions(schedulesForDate, projects),
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchSafetyAddress({
            searchValue,
            dispatchSafetyFormData,
            userConfiguration,
            projects,
            scheduling,
            schedulesForDate,
            dateField: "dateOfVehicleDamageOrIncident",
            form,
          }),
      };
    }

    if (field.formItemName === "truckNumber") {
      delete field?.options;
      field = {
        ...field,
        customOptions: fleet?.map((e) => {
          return { value: e.fleetId, label: e.fleetName };
        }),
        onChange: (value) => {
          form.setFieldValue(
            "licensePlate",
            fleet?.find((e) => e.fleetId === value)?.licensePlate
          );
        },
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );

    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);
      addConditionalFields({
        fields,
        fieldCategoryIndex,
        otherInvolvement,
        field,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        fieldCategoryIndex: field.length - 1,
        otherInvolvement,
        field,
      });
    }

    if (namesToDisable) {
      if (namesToDisable.includes(field.formItemName)) {
        if (!field.disabled) {
          field = {
            ...field,
            disabled: disabledAllFields,
          };
        }
      }
    }

    if (disabledAllFields) {
      field = {
        ...field,
        disabled: true,
      };
    }
  }
  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );
  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  return fields;
};

export default vehicleDamageSafetyFields;
