/**
 * @param {Object} param
 * @param {string} param.spreadsheetId
 * @param {string} param.spreadsheetName
 * @param {string} param.access_token
 * @param {string} param.locationsColumn
 * @param {number} param.locationsRow
 * @return {Promise<string[]>}
 */
async function getSheetLocations({
  spreadsheetId,
  spreadsheetName,
  access_token,
  locationsColumn,
  locationsRow,
}) {
  if (!window.gapi) {
    return Promise.reject("Google API not yet initialized");
  }

  return gapi.client.sheets.spreadsheets.values
    .get({
      spreadsheetId,
      range: `'${spreadsheetName}'!${locationsColumn}${locationsRow}:${locationsColumn}`,
      dateTimeRenderOption: "SERIAL_NUMBER",
      majorDimension: "ROWS",
      valueRenderOption: "FORMATTED_VALUE",
      access_token,
    })
    .then((r) => {
      return (r?.result?.values || []).flat();
    });
}

export default getSheetLocations;
