import { Avatar, Button } from "antd";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import { MondayButton } from "../../../../../commonComponents";
import { LogOutIcon } from "../../../../subcomponents";
import { UserIcon } from "../../../GoogleEvents/assets";

import { useSelector } from "react-redux";
import { RefreshIcon } from "../../../../../SidebarPages/Communication/assets";
import "./GoogleUserUi.scss";

function GoogleUserUi({
  onGoogleSignIn,
  onGoogleSignOut,
  getEmailsAsNotifications,
  setSelectedRecordCategory,
}) {
  const { userLoggedIn, userDetails } = useGoogleSignIn();
  const { emailsLoading } = useSelector((state) => state.notifications);

  if (emailsLoading) {
    return null;
  }

  return (
    <div className="google-user-ui-container">
      <Button
        type="default"
        onClick={getEmailsAsNotifications}
        style={{
          textAlign: "center",
          background: "#0F5C97",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disabled={emailsLoading}
        icon={<RefreshIcon style={{ fill: "#fff" }} />}
      />

      {!userLoggedIn ? (
        <MondayButton
          onClick={(e) => {
            e.stopPropagation();
            setSelectedRecordCategory("projects");
            onGoogleSignIn();
          }}
          Icon={<UserIcon />}
          className="sign-in-button"
        >
          Sign In
        </MondayButton>
      ) : (
        <div className="user-container">
          <Avatar src={userDetails?.picture} alt="user-avatar" size="large">
            {userDetails?.email}
          </Avatar>

          <Button
            className="logout-button"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRecordCategory("projects");
              onGoogleSignOut();
            }}
            type="ghost"
            icon={<LogOutIcon />}
          />
        </div>
      )}
    </div>
  );
}

export default GoogleUserUi;
