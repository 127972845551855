import { message, notification, Switch, Tooltip } from "antd";

//request editing access from the editing user
export const requestEditAccessHandler = ({
  authenticatedUser,
  userEditingData,
  sessionId,
  recordId,
  wsRef,
}) => {
  message.loading({
    content: "Request sent...",
    key: "requestAccessMsg",
  });
  wsRef.current.send(
    JSON.stringify({
      request: "requestEditAccess",
      body: {
        userId: authenticatedUser?.sub,
        recordId,
        sessionId,
        userEditingData,
      },
    })
  );
};

export const editAccessRequestResponseHandler = ({
  changeWritability,
  degSessionSocket,
  status,
}) => {
  if (!!status) {
    message.success({
      content: "Access granted.",
      key: "requestAccessMsg",
    });
    changeWritability.enable(degSessionSocket);
  } else {
    message.warning({
      content: "Request denied.",
      key: "requestAccessMsg",
    });
  }
};

export const sessionStatusHandler = ({
  estimationIsBeingEdited,
  setUserEditingData,
  msgSessionId,
  setCanUse,
  userId,
}) => {
  if (!!estimationIsBeingEdited) {
    setCanUse(false);
    setUserEditingData({
      userId,
      sessionId: msgSessionId,
    });
  } else {
    setCanUse(true);
    setUserEditingData();
  }
};

//when another user asks for editing access, this method gives the response
export const handleSwapEditAccessHandler = ({
  changeWritability,
  wsInstance,
  sessionId,
  setCanUse,
  response,
  wsRef,
}) => {
  const wsInstanceToUse = wsInstance || wsRef.current; //chooses websocket instance to use

  notification.destroy("editAccessRequest");

  wsInstanceToUse.send(
    JSON.stringify({
      request: "editAccessRequestResponse",
      body: {
        reqSessionId: sessionId,
        response: response,
      },
    })
  );

  if (!!response) {
    // setCanUse(false);
    changeWritability.disable(wsInstance);
  }
};

export const sessionStatusColorHandler = ({ canUse, isWritable }) =>
  !!canUse
    ? !!isWritable
      ? {
          backgroundColor: "#ecde7c",
          color: "#12131B",
        }
      : {
          backgroundColor: "#68C142",
        }
    : {
        backgroundColor: "#FE4C4A",
      };
