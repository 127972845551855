import { ColumnWidthOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { MondayButton } from "src/components/commonComponents";
import CheckboxCard from "src/components/pages/Settings/settingsComponents/TeamManagement/components/MembersCard/CheckboxCard";

const HeaderButtons = ({
  setIsSelectColumn,
  isUseTemplate,
  setIsUseTemplate,
  reportTemplate,
}) => {
  const isDisabledClassName = isUseTemplate
    ? "mondayButtonDisabled"
    : "mondayButtonBlue";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        marginBottom: "30px",
      }}
    >
      <MondayButton
        className={isDisabledClassName}
        Icon={<ColumnWidthOutlined />}
        onClick={() => setIsSelectColumn(true)}
        disabled={isUseTemplate}
      >
        Columns
      </MondayButton>
      <MondayButton
        className={isDisabledClassName}
        Icon={<MenuUnfoldOutlined />}
        onClick={() => setIsSelectColumn(false)}
        disabled={isUseTemplate}
      >
        Parameters
      </MondayButton>

      {reportTemplate && (
        <CheckboxCard
          {...{
            name: "Use Template",
            identityId: 1,
            value: isUseTemplate,
            onChange: () => {
              setIsUseTemplate((prev) => !prev);
              setIsSelectColumn(true);
            },
            style: { width: "fit-content" },
          }}
        />
      )}
    </div>
  );
};

export default HeaderButtons;
