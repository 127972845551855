import { Button, Modal } from "antd";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../../../commonComponents";
import "./PreventCreationOfSchedule.scss";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
const PreventCreationOfSchedule = ({
  prevent,
  redirectToProject,
  onClose,
  pr,
  objKeys,
  project,
  isDarkMode,
  id,
}) => {
  return (
    <div>
      <Modal
        className={`preventCreationOfSchedule ${
          isDarkMode && "preventCreationOfScheduleDark"
        }`}
        title={
          <svg
            width="56"
            height="48"
            viewBox="0 0 56 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.58674 42.8772C8.02106 42.8772 7.58848 42.628 7.289 42.1294C6.98952 41.6309 6.98952 41.1323 7.289 40.6338L26.6552 7.23169C26.9547 6.73316 27.3873 6.48389 27.953 6.48389C28.5186 6.48389 28.9512 6.73316 29.2507 7.23169L48.6169 40.6338C48.9164 41.1323 48.9164 41.6309 48.6169 42.1294C48.3174 42.628 47.8849 42.8772 47.3192 42.8772H8.58674ZM28.1526 20.3433C27.72 20.3433 27.3623 20.4845 27.0795 20.767C26.7966 21.0495 26.6552 21.4068 26.6552 21.8389V30.0149C26.6552 30.447 26.7966 30.8043 27.0795 31.0868C27.3623 31.3693 27.72 31.5105 28.1526 31.5105C28.5852 31.5105 28.9429 31.3693 29.2257 31.0868C29.5086 30.8043 29.65 30.447 29.65 30.0149V21.8389C29.65 21.4068 29.5086 21.0495 29.2257 20.767C28.9429 20.4845 28.5852 20.3433 28.1526 20.3433ZM28.1526 37.0443C28.5852 37.0443 28.9429 36.9031 29.2257 36.6206C29.5086 36.3381 29.65 35.9808 29.65 35.5487C29.65 35.1166 29.5086 34.7593 29.2257 34.4768C28.9429 34.1943 28.5852 34.0531 28.1526 34.0531C27.72 34.0531 27.3623 34.1943 27.0795 34.4768C26.7966 34.7593 26.6552 35.1166 26.6552 35.5487C26.6552 35.9808 26.7966 36.3381 27.0795 36.6206C27.3623 36.9031 27.72 37.0443 28.1526 37.0443V37.0443Z"
              fill="white"
            />
            <path
              d="M3.01727 41.4489L0.999937 41.4489"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.5448 39.2664L1.79295 38.2667"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.08704 37.6351L4.06527 35.8976"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.54474 43.6312L1.79289 44.6309"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.08704 45.2624L4.06527 47"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M52.9827 41.4489H55"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M52.4551 43.6312L54.207 44.6308"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M50.913 45.2625L51.9347 47"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M52.4552 39.2663L54.2071 38.2667"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M50.913 37.6353L51.9347 35.8977"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.5211 3.01501L27.5211 1.00007"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.7062 3.54187L30.707 1.79209"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M31.3392 5.08228L33.0789 4.06172"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M25.3361 3.54187L24.3353 1.79209"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.7031 5.08228L21.9634 4.06172"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
        centered
        footer={null}
        open={prevent}
        closable={false}
      >
        <div className="preventContainer">
          <span className="preventTitle">Be cautious!</span>
          <span className="preventDesc">
            You can not create a schedule for this project because it does not
            have any estimations with services approved.
          </span>
          <div className="estContnet">
            <span className="preventSchAddress">
              {project ||
                pr?.find((item) => item?.projectId === objKeys?.scheduleAddress)
                  ?.projectAddress ||
                pr?.find((item) => item?.projectId === id)?.projectAddress}
            </span>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <MondayButton
                onClick={onClose}
                {...{ Icon: <XIcon />, className: "mondayButtonRed" }}
              >
                Close
              </MondayButton>
              {!project && (
                <Button
                  onClick={redirectToProject}
                  className="preventEstButton"
                >
                  Go to Estimation
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PreventCreationOfSchedule;
