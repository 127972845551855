import React from "react";
import { CustomVariableCard } from "../../ProposalSidebar";

function Commands({ editorRef }) {
  const onDragStart = (key) => {
    const editor = tinymce.get(editorRef.current.id);
    console.log("onDragStart", key);
    switch (key) {
      case "mceInsertToc":
        editor.execCommand("mceInsertToc");
        return;
      case "mceUpdateToc":
        editor.execCommand("mceUpdateToc");
        return;
      case "mcePageBreak":
        editor.insertContent('<hr class="page-break"/>');
        return;
      case "mceHelp":
        editor.execCommand("mceHelp");
        return;
      default:
        return;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "100%",
        justifyContent: "flex-start",
        gap: "10px",
        flexWrap: "wrap",
      }}
    >
      <CustomVariableCard
        {...{
          name: "Table of Contents",
          keyDetail: "mceInsertToc",
          onDragStart,
          commandHandler: onDragStart,
        }}
      />
      <CustomVariableCard
        {...{
          name: "Update Table of Contents",
          onDragStart,
          commandHandler: onDragStart,
          keyDetail: "mceUpdateToc",
        }}
      />
      <CustomVariableCard
        {...{
          name: "Page Break",
          onDragStart,
          commandHandler: onDragStart,
          keyDetail: "mcePageBreak",
        }}
      />{" "}
      <CustomVariableCard
        {...{
          name: "Help",
          onDragStart,
          commandHandler: onDragStart,
          keyDetail: "mceHelp",
        }}
      />
      {/* <CustomVariableCard
        {...{
          name: "Table of Contents",
        }}
      /> */}
    </div>
  );
}

export default Commands;
