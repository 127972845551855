import { Alert, Collapse, Drawer } from "antd";
import {
  SearchIcon,
  XIcon,
} from "../../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import "./FoldersAccessDrawer.scss";
import { useState, useMemo, useEffect } from "react";
import IndividualLoader from "../../../../../../../../IndividualLoader";
import { RecordsToUpdateFooter } from "../../../../../TeamManagement/components/RecordsToUpdate/CollapseExtra";
import {
  MondayButton,
  MultiLevelTreeLogs,
  WarningModal,
} from "../../../../../../../../commonComponents";
import { WarningIcon } from "../../../../../../../../../icons";
import { useSelector } from "react-redux";
import { saveHandler } from "../utils/FoldersAccessHelpers/saveHandler";
import {
  createTree,
  getAllFids,
  recursiveFoldersSearch,
  renderPermissionElement,
  renderTree,
} from "../utils/FoldersAccessHelpers/renderCollapseList";
import { DownArrow } from "./FoldersAccess";
import { LogsIcon } from "src/components/SidebarPages/DynamicView/src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { PdfDocIcon } from "src/assets";
import { onGeneratePDFDrive } from "../utils/FoldersAccessHelpers/onGeneratePdfDrive";
import { useEditLogs } from "../../../../../../../../../hooks";
import { fetchAllData } from "../../../../../../../../../utils";

/**
 * Renders a drawer component for managing folder access permissions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.permissionList - The list of folder permissions.
 * @param {boolean} props.visible - Indicates whether the drawer is visible or not.
 * @param {function} props.setVisible - Callback function to control the visibility of the drawer.
 * @param {boolean} props.loading - Indicates whether the fields are loading or not.
 * @param {function} props.driveRequest - The drive request function.
 * @param {Object} props.currentUser - The current user object.
 * @param {function} props.setPermissionList - Callback function to set the permission list.
 * @returns {JSX.Element} The FoldersAccessDrawer component.
 */
function FoldersAccessDrawer({
  permissionList,
  visible,
  setVisible,
  loading,
  driveRequest,
  currentUser,
  setPermissionList,
}) {
  const [search, setSearch] = useState("");
  const mainFolderId = useMemo(() => {
    return Object.values(permissionList)[0]?.folderId;
  }, [permissionList]);

  const mainFolderName = useMemo(() => {
    return Object.keys(permissionList || {})?.[0];
  }, [permissionList]);
  const [changedValues, setChangedValues] = useState({});
  const [{ userConfiguration }, { isDarkMode }, { base64 }] = useSelector(
    (state) => [state.userConfig, state.darkMode, state.base64]
  );

  const { saveAddedLogs } = useEditLogs();

  const [expandedKeys, setExpandedKeys] = useState({});
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [collectLogs, setCollectLogs] = useState({});
  const [logsData, setLogsData] = useState([]);
  const list = permissionList;

  let nameEmail = `${currentUser?.nameOfUser} - ${currentUser?.userName}`;
  let logsTopic = mainFolderName?.includes("FOLDER")
    ? mainFolderName
    : mainFolderName + " FOLDER";

  const newEditLog = (collectLogs) => ({
    recordId: mainFolderId,
    recordName: mainFolderName,
    actionType: "Edit",
    topic: logsTopic,
    category: "Folder Access",
    currentData: { [nameEmail]: { ...collectLogs.curr } },
    label: logsTopic,
    previousData: { [nameEmail]: { ...collectLogs.prev } },
    updatedKeys: collectLogs.updatedKeys,
  });

  useEffect(() => {
    mainFolderId &&
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: mainFolderId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      }).then((res) => {
        console.log("res", res);
        setLogsData(res || []);
      });
  }, [mainFolderId]);

  const renderCollapse = (list, first = true, exKeys = []) => {
    return Object.keys(list)?.map((key, index) => {
      const subfolders = list[key]?.subfolders;
      const subFoldersArray = createTree(
        subfolders,
        setChangedValues,
        setCollectLogs
      ).filter((el) => Object.keys(el).length > 0);
      const onExpand = (newExpandedKeys) => {
        setExpandedKeys((prev) => ({
          ...prev,
          [key]: newExpandedKeys || [],
        }));
        setAutoExpandParent(false);
      };
      return (
        <div
          key={index}
          className={!!first ? "mainCollapse" : "firstLevelFolders"}
        >
          <Collapse
            {...{
              defaultActiveKey: exKeys,
              expandIcon: ({ isActive }) => (
                <DownArrow
                  style={{
                    transform: `rotate(${isActive ? "180deg" : "0deg"})`,
                    height: 11,
                    width: 11,
                  }}
                />
              ),
              bordered: false,
              collapsible: "icon",
              expandIconPosition: "end",
              items: [
                {
                  key: list[key]?.folderId,
                  label: !!first
                    ? renderPermissionElement(
                        list,
                        key,
                        setChangedValues,
                        "header",
                        setCollectLogs
                      )
                    : renderPermissionElement(
                        list,
                        key,
                        setChangedValues,
                        "subfolder",
                        setCollectLogs
                      ),

                  children: !!first
                    ? renderCollapse(subfolders, false, exKeys)
                    : renderTree(
                        subFoldersArray,
                        !autoExpandParent
                          ? expandedKeys[key] || []
                          : [
                              ...new Set([
                                ...exKeys,
                                ...(expandedKeys[key] || []),
                              ]),
                            ],
                        onExpand,
                        autoExpandParent
                      ),
                },
              ],
            }}
          />
        </div>
      );
    });
  };

  const memoisedList = useMemo(() => {
    if (search === "") {
      return renderCollapse(permissionList, true, []);
    } else {
      const newList = recursiveFoldersSearch(list, search);
      return renderCollapse(newList, true, getAllFids(newList));
    }
  }, [permissionList, list, search, expandedKeys]);

  function cancelHandler() {
    setPermissionList({});
    setVisible(false);
    setCollectLogs({});
    setCancelModalVisible(false);
  }

  async function onConfirmHandler() {
    let log = newEditLog(collectLogs);
    setLogsData([...logsData, log]);
    saveAddedLogs(log);

    await saveHandler(
      changedValues,
      driveRequest,
      cancelHandler,
      currentUser?.userName
    );
  }

  return (
    <Drawer
      {...{
        closable: true,
        title: "Folders Access",
        closeIcon: <XIcon />,
        onClose: () => {
          Object.keys(changedValues).length === 0
            ? cancelHandler()
            : setCancelModalVisible("close");
        },
        open: !!visible,
        rootClassName: `${
          isDarkMode && "foldersAccessDrawerDark"
        } foldersAccessDrawer`,
        footer: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MondayButton
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon />,
                onClick: () =>
                  Object.keys(changedValues).length === 0
                    ? cancelHandler()
                    : setCancelModalVisible("close"),
              }}
            >
              Close
            </MondayButton>
            <div style={{ display: "flex", gap: 10 }}>
              {mainFolderName && (
                <>
                  <MondayButton
                    {...{
                      onClick: () => {
                        onGeneratePDFDrive(permissionList, currentUser, base64);
                      },
                      Icon: <PdfDocIcon />,
                      className: "mondayButtonBlue",
                    }}
                  >
                    Export to PDF
                  </MondayButton>
                  <MondayButton
                    {...{
                      onClick: () => {
                        setEditLogsVisibility(true);
                      },
                      Icon: <LogsIcon />,
                      className: "mondayButtonBlue",
                    }}
                  >
                    {logsTopic} Logs
                  </MondayButton>
                </>
              )}
              {Object.keys(changedValues).length > 0 && (
                <MondayButton
                  {...{
                    onClick: () => {
                      setCancelModalVisible("save");
                    },
                    Icon: <TickIcon />,
                    disabled: Object.keys(changedValues).length === 0,
                  }}
                >
                  Confirm
                </MondayButton>
              )}
            </div>
          </div>
        ),
      }}
    >
      {loading ? (
        <Alert
          message={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>Fields are loading...</h3>
              <IndividualLoader />
            </div>
          }
          type="info"
        />
      ) : (
        <>
          <InputComponent
            {...{
              placeholder: "Search...",
              className: "searchInput",
              onChange: (e) => {
                if (e.target.value === "") {
                  setAutoExpandParent(true);
                  setExpandedKeys({});
                }
                setSearch(e.target.value);
              },
              allowClear: true,
              prefix: <SearchIcon />,
            }}
          />
          <div>{memoisedList}</div>
        </>
      )}
      {cancelModalVisible && (
        <WarningModal
          {...{
            visible: !!cancelModalVisible,
            setVisible: setCancelModalVisible,
            title: "Cancel Changes",
            darkMode: isDarkMode,
            children: (
              <div style={{ textAlign: "center" }}>
                <span>
                  <WarningIcon />
                </span>
                {cancelModalVisible === "close" ? (
                  <p>Are you sure you want to cancel changes?</p>
                ) : (
                  <p>Are you sure you want to save changes?</p>
                )}
                <RecordsToUpdateFooter
                  {...{
                    disabled: false,
                    onClose: () => setCancelModalVisible(false),
                    onSave:
                      cancelModalVisible === "close"
                        ? () => {
                            cancelHandler();
                          }
                        : async () => {
                            onConfirmHandler();
                          },
                  }}
                />
              </div>
            ),
          }}
        />
      )}
      {editLogsVisibility && (
        <MultiLevelTreeLogs
          {...{
            visible: editLogsVisibility,
            setVisible: setEditLogsVisibility,
            logsData: logsData || [],
            title: "Drive Folder Access Logs",
          }}
        />
      )}
    </Drawer>
  );
}

export default FoldersAccessDrawer;
