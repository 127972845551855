import { Form, Modal } from "antd";
import React from "react";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../../../../../../commonComponents";
import { Close, TickIcon } from "../../../../../Roles/src";

const ConfirmationCodeModal = ({ onConfirm, onCancel, isLoading }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      type="disable-2fa"
      width={300}
      height={300}
      open={true}
      centered
      title="Disable 2FA"
      onCancel={onCancel}
      onClose={onCancel}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MondayButton
            onClick={onCancel}
            className="mondayButtonRed"
            Icon={<Close />}
          >
            Cancel
          </MondayButton>
          <MondayButton
            Icon={<TickIcon />}
            loading={isLoading}
            disabled={isLoading}
            onClick={async () => {
              try {
                await onConfirm(form.getFieldValue("code"));
                onCancel();
              } catch (error) {}
            }}
          >
            Confirm Code
          </MondayButton>
        </div>
      }
    >
      <Form form={form}>
        <InputComponent
          type="otp"
          name="code"
          label="Enter the 6-digit code you see in the app"
          placeholder="Enter code"
        />
      </Form>
    </Modal>
  );
};

export default ConfirmationCodeModal;
