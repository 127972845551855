import React from "react";
import { Tooltip } from "antd";

import { formatCurrency } from "../../../../../../utils/formatCurrency";

const ServiceDescription = ({
  service,
  elevations,
  isTaxable,
  showDivider,
  color,
  total,
}) => {
  return (
    <div className="groupContainer" data-testid="service-description">
      <Tooltip
        placement="topLeft"
        title={`${service} ${
          isTaxable ? "price is with Tax" : "price is without Tax"
        }`}
      >
        <div className="InformationSelectedGroupContainer">
          <div className="serviceTitle" style={{ color }}>
            {service}
          </div>
          <div className="InformationSelectedGroupContainerElevations">
            {elevations?.map((elevation, index) => (
              <div key={index} className="elevationInformation">
                {elevation}
              </div>
            ))}
            <b>Total: {formatCurrency(total)}</b>
          </div>
        </div>
      </Tooltip>
      {showDivider && <div className="lineDivider"></div>}
    </div>
  );
};

export default ServiceDescription;
