export const generateInputFields = ({
  laborTypes,
  salesPerson,
  onTaxSelect,
  opportunities,
  insuranceTypes,
  opportunityStage,
  taxExemptOptions,
  projectExecutives,
  opportunitySources,
  requiredBidDueDate,
  projectManagersOptions,
}) => [
  {
    label: "JobSite Address",
    onContextMenu: () => false,
    name: "jobSiteAddress",
    type: "placesautocomplete",
    placeholder: "JobSite Address...",
    className: "inputForm",
    required: true,
    existingValues: opportunities?.map(
      ({ opportunityId = "", opportunityAddress = "", accountName = "" }) => ({
        id: opportunityId,
        value: opportunityAddress,
        label: `${opportunityAddress} - ${accountName}`,
        disabled: true,
      })
    ),
    popoverClassName: "alternativeAddressesContainer",
    popoverPlacement: "bottomRight",
    isCustomValue: true,
  },
  {
    label: "Opportunity Name",
    onContextMenu: () => false,
    name: "opportunityName",
    type: "text",
    placeholder: "Opportunity Name...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Request Received Date",
    onContextMenu: () => false,
    name: "requestReceivedDate",
    type: "datepicker",
    placeholder: "Request Received Date...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Bid Due Date",
    onContextMenu: () => false,
    name: "bidDueDate",
    type: !requiredBidDueDate ? "datepicker" : "text",
    className: "inputForm",
    required: !requiredBidDueDate,
    disabled: requiredBidDueDate,
    notApplicable: requiredBidDueDate,
  },
  {
    label: "Proposed Start Date",
    onContextMenu: () => false,
    name: "proposedStartDate",
    type: "datepicker",
    placeholder: "Proposed Start Date...",
    className: "inputForm",
  },
  {
    label: "Labor Requirement",
    onContextMenu: () => false,
    name: "laborRequirement",
    type: "select",
    placeholder: "Labor Requirement...",
    className: "inputForm",
    customOptions: laborTypes,
  },
  {
    label: "Insurance Requirement",
    onContextMenu: () => false,
    name: "insuranceRequirement",
    type: "select",
    placeholder: "Traditional Insurance...",
    className: "inputForm",
    customOptions: insuranceTypes,
  },
  {
    label: "Tax Exempt",
    onContextMenu: () => false,
    name: "opportunityTaxExempt",
    type: "select",
    placeholder: "Tax Exempt...",
    className: "inputForm",
    onSelect: () => onTaxSelect(),
    customOptions: taxExemptOptions,
  },
  {
    label: "Tax Rate",
    onContextMenu: () => false,
    name: "opportunityTaxRate",
    type: "text",
    placeholder: "Tax Rate...",
    className: "inputForm",
    disabled: true,
  },
  {
    label: "Opportunity Stage",
    onContextMenu: () => false,
    name: "opportunityStage",
    type: "select",
    placeholder: "Opportunity Stage...",
    className: "inputForm",
    customOptions: opportunityStage,
  },
  {
    label: "Opportunity Source",
    onContextMenu: () => false,
    name: "opportunitySource",
    type: "select",
    placeholder: "Opportunity Source...",
    className: "inputForm",
    customOptions: opportunitySources,
  },
  {
    label: "Project Executive",
    onContextMenu: () => false,
    name: "projectExecutive",
    type: "select",
    placeholder: "Project Executive...",
    className: "inputForm",
    customOptions: projectExecutives,
    required: true,
  },
  {
    label: "Sales Person",
    onContextMenu: () => false,
    name: "salesPerson",
    type: "select",
    placeholder: "Sales Person...",
    className: "inputForm",
    customOptions: salesPerson,
  },
  {
    label: "Project Managers",
    onContextMenu: () => false,
    name: "projectManagers",
    type: "select",
    placeholder: "Project Managers...",
    className: "inputForm",
    customOptions: projectManagersOptions,
  },
];
