import { useSelector } from "react-redux";
import { ScheduleDayNotesViewer } from "./ScheduleDayNotesViewer";
import _ from "lodash";

export const ScheduleDaysNotes = ({ scheduleDays, authenticatedUser }) => {
  return (
    <div className={"schedule-days-notes-container"}>
      {scheduleDays.map((scheduleDay) => (
        <ScheduleDayNotesViewer {...{ scheduleDay, authenticatedUser }} />
      ))}
    </div>
  );
};
