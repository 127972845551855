import React, { useState } from "react";
import { Input, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { createNewCalendar } from "../../googleClientAPI/execute";
import { MondayButton } from "../../../../../commonComponents";
const NewCalendar = ({ setRefreshCalendarList }) => {
  const [summary, setSummary] = useState("");
  const [spinning, setSpinning] = useState(false);

  //create new calendar func
  const handleNewCalendar = () => {
    setSpinning(true);
    createNewCalendar(summary)
      .then(() => setRefreshCalendarList(true)) //then refresh calendar list
      .then(() => {
        setSpinning(false);
        setSummary("");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
        gap: 8,
      }}
    >
      <Input
        size="small"
        style={{ height: "32px" }}
        value={summary}
        onChange={(event) => setSummary(event.target.value)}
        placeholder="New Calendar Name..."
      />
      <MondayButton
        {...{
          Icon: <PlusOutlined />,
          onClick: handleNewCalendar,
          className: "mondayButtonBlue",
          disabled: summary?.length === 0,
        }}
      >
        Create Calendar
      </MondayButton>
      <Spin spinning={spinning} style={{ color: "#f8b96a" }} />
    </div>
  );
};

export default NewCalendar;
