import ServiceActions from "./ServiceActions/ServiceActions";
import ServicePrices from "./ServicePrices/ServicePrices";
import { Typography, Tooltip, Alert } from "antd";
import { ChainScopeIcon, ScopeIcon } from "../../../../../../icons/index";
import { ChainsIcon } from "../../../../../../assets/index";
import { getRandomColor } from "../../../../../Header/forms/Scheduling/helpers/creators";
import { useEstVersionsContext } from "../../../components/EstVersionsTab/context/estVersionsTabContext";
import { MondayButton } from "../../../../../commonComponents/index";
import { LinkIcon, XIcon } from "../../../../Communication/assets/index";
import { useRedux } from "../../../../hooks/useRedux";
import { useState } from "react";
import ButtonDropdown from "../../../../../commonComponents/MondayButtonDropDown/ButtonDropdown";
import useTakeOffAccessRights from "../../tools/controllers/useTakeOffAccessRights";

const { Title } = Typography;

export default function ServiceHeader(props) {
  const {
    borderColor,
    service,
    // isWritable,
    fullscreenizeService,
    fullScreenService,
    deFullscreenize,
    handleModifyOption,
    serviceIndex,
    collapseAllElevations,
    expandAllElevations,
    stateSetter,
    handlePricePerUnitChange,
    handleDefaultPricePerUnitChange,
    onInputBlur,
    handleRentPerServiceChange,
    handleAlternateRentPerServiceChange,
    selectedPriceSchemesForService,
    agGridTheme,
    pricingData,
    priceEdit,
    priceView,
    // gridData,
    priceSchemesData,
    updateStateAndSave,
    handleSave,
    record,
  } = props;
  // const borderColor = getRandomColor();
  // console.log("borderColor", borderColor);
  const { canSeeRentals = false } = useTakeOffAccessRights("Take-Off");
  const [isWritable] = useRedux("estimationsIsWritable");
  const { rentalsAssociated } = useEstVersionsContext();
  const [gridData] = useRedux("takeOffTableData");
  const warning = getServiceWarning({ service, rentalsAssociated });
  console.log("warning", warning);
  return (
    <div className={"service-header"}>
      <>
        {Array.isArray(warning) && warning.length > 0 && canSeeRentals && (
          <Alert
            closable
            showIcon
            closeIcon={<XIcon />}
            message={
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  animation: "typing 5s",
                }}
              >
                Please be aware, there are Rentals created for this Service for
                this specific Project! Navigate to the right to see details.
              </div>
            }
            action={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  flexDirection: "row",
                }}
              >
                <RentalsAssociated />
                <ButtonDropdown
                  {...{
                    mondayClassName: "newButton",
                    title: "Rentals",
                    dropDownOptions: warning?.map((el, index) => ({
                      key: index,
                      label: (
                        <>
                          <p>{`Rental ${el?.rentalNumber}`}</p>
                        </>
                      ),
                      onClick: () => {
                        window.open(`/rentals/${el?.rentalId}`, "_blank");
                      },
                    })),
                  }}
                />
                {/* {warning?.map((el) => (
                  <MondayButton
                    className="mondayButtonBlue"
                    Icon={<LinkIcon />}
                    onClick={() => {
                      window.open(`/rentals/${el?.rentalId}`, "_blank");
                    }}
                  >
                    {`Rental ${el?.rentalNumber}`}
                  </MondayButton>
                ))} */}
              </div>
            }
            type="warning"
            className="service-header-alert"
          />
        )}
      </>
      <Title level={3} className={"service-title"}>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "5px",
            justifyContent: "space-between",
            // background: "red",
            alignItems: "center",
            height: "40px",
            // justifyContent: "space-between",
          }}
          //   alignItems: "center",
          //   gap: "2px",
          //   height: "50px",
          //   flexDirection: "row",
          // }}
        >
          <span
            style={{
              fontSize: "18px",
              fontFamily: "Open Sans",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <div
              className="circleShape"
              style={{
                background: borderColor,
                paddingLeft: "5px",
                margin: "0px",
              }}
            />
            {service.label}
            <ChainedRelationService service={service} gridData={gridData} />
          </span>
          <ServiceActions
            {...{
              service,
              isWritable,
              fullscreenizeService,
              fullScreenService,
              deFullscreenize,
              handleModifyOption,
              serviceIndex,
              collapseAllElevations,
              expandAllElevations,
              updateStateAndSave,
              gridData,
              agGridTheme,
              handleSave,
              priceSchemesData,
            }}
          />
        </div>{" "}
      </Title>

      <ServicePrices
        {...{
          service,
          stateSetter,
          handleRentPerServiceChange,
          handleAlternateRentPerServiceChange,
          selectedPriceSchemesForService,
          pricingData,
          isWritable,
          priceEdit,
          priceView,
          handlePricePerUnitChange,
          handleDefaultPricePerUnitChange,
          onInputBlur,
          serviceIndex,
          gridData,
          updateStateAndSave,
          record,
          agGridTheme,
        }}
      />
    </div>
  );
}

const ChainedRelationService = ({ service = {}, gridData = [] }) => {
  let chains = [];
  const chainedServices =
    service?.isScope !== true
      ? gridData?.filter((el) => {
          return (
            el?.isScope === true &&
            Number(el?.chainedService?.serviceId) === Number(service?.serviceId)
          );
        })
      : [];

  if (chainedServices?.length > 0) {
    chains = chainedServices?.map(({ label = "" }) => label);
  }
  return service?.isScope === true ? (
    <Tooltip title="This Service is a Scope!">
      {" "}
      <span>
        <ChainScopeIcon />
      </span>
    </Tooltip>
  ) : chainedServices?.length > 0 ? (
    <Tooltip title="This Service is connected to these other/s Scope/s">
      <span
        style={{
          fontSize: "14px",
          color: "#7ABDFF",
          fontFamily: "Open Sans",
          display: "flex",
          gap: 5,
        }}
      >
        <ChainScopeIcon />
        {chains?.join(" | ") ?? ""}
      </span>
    </Tooltip>
  ) : (
    ""
  );
};

const getServiceWarning = ({ rentalsAssociated = [], service = {} }) => {
  let rentalsTemp = [];
  console.log("rentalsAssociated222", {
    rentalsAssociated,
    service,
  });
  rentalsAssociated?.forEach((rental) => {
    const tempServices = rental?.services ?? [];

    if (tempServices.includes(service?.label)) {
      rentalsTemp.push({
        rentalId: rental?.rentalId,
        rentalNumber: rental?.rentalNumber,
      });
    }
  });
  return rentalsTemp;
  console.log("warningHTML", warningHTML);
};

const RentalsAssociated = () => {
  const [visible, setVisible] = useState(false);
  return <>{/* <MondayButton>TEST</MondayButton> */}</>;
};
