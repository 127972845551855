import {
  calculateCreditedAmountForService,
  calculateInvoicedAmountForCategoryDataObj,
  calculateTotalPriceForService,
} from "../../../../utilities";
import {
  categoriesName,
  categoriesNumberKey,
  categoriesServiceMap,
  categoriesTypeKey,
} from "../../../utils/constants";
import { firstLetterToUpperCase } from "./firstLetterToUpperCase";
import { totalitiesTotalTaxGen } from "../../../../../../Projects/Accounting/Rentals/RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";

const getNumber = (serviceGroup, selectedCategory) => {
  const categoryNumberKey = categoriesNumberKey[selectedCategory];
  return categoryNumberKey ? serviceGroup[categoryNumberKey] : null;
};

export const groupTableRowData = ({
  servicesGroups,
  selectedCategory,
  scheduleOfValues,
  invoiceData,
}) => {
  return (
    servicesGroups
      ?.sort((e, i) => getNumber(e) - getNumber(i))
      .map((serviceGroup, index) => {
        return {
          type: firstLetterToUpperCase(categoriesName?.[selectedCategory]),
          number: categoriesNumberKey?.[selectedCategory]
            ? serviceGroup?.[categoriesNumberKey?.[selectedCategory]]
            : index + 1,
          status: serviceGroup?.[categoriesTypeKey?.[selectedCategory]] || "",
          id: serviceGroup?.[categoriesName[selectedCategory]],
          SOVNo: scheduleOfValues?.find(
            (el) => serviceGroup.scheduleId === el.scheduleId
          )?.SOVNo,
          //calculateCreditedAmountForService
          creditedAmount: serviceGroup[
            categoriesServiceMap[selectedCategory]
          ]?.reduce(
            (p, service) =>
              p +
              (calculateCreditedAmountForService({
                service,
                categoryFrom: selectedCategory,
              })["creditedTotal"] || 0),
            0
          ),
          invoicedAmount: calculateInvoicedAmountForCategoryDataObj({
            categoryDataObj: serviceGroup,
            invoiceIdsException: invoiceData ? [invoiceData?.invoiceId] : [],
          }),
          totalAmount: (() => {
            let total = 0;
            //If selected category is charges then the total will be the charge amount for all months
            if (selectedCategory === "rentals") {
              total =
                totalitiesTotalTaxGen({
                  fakeRentals: serviceGroup,
                })?.totalPrice || 0;
            } else if (selectedCategory === "applications")
              return serviceGroup?.totalities?.currentPaymentDue;
            else if (selectedCategory !== "charges") {
              serviceGroup[categoriesServiceMap[selectedCategory]]
                .flat(1)
                .forEach((service) => {
                  const { amount, taxAmount } = calculateTotalPriceForService(
                    service,
                    selectedCategory
                  );
                  total += amount + taxAmount;
                });
            } else {
              total = serviceGroup.chargeAmount;
            }
            return total;
          })(),
          selectedCategory,
          ...serviceGroup,
        };
      })
      ?.filter((data) => {
        return !(
          data?.invoicedAmount + (data?.creditedAmount || 0) >
          data?.totalAmount
        );
      }) || []
  );
};
