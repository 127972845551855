export function selectedTabInfoText({ selectedTab, nameOfUser }) {
  switch (selectedTab) {
    case "Route":
      return (
        <span>
          Manage and configure the routes that <b>{nameOfUser}</b> is authorized
          to access. Ensure each route aligns with the user's role and
          responsibilities to maintain operational security and efficiency.
        </span>
      );
    case "Sidebar":
      return (
        <span>
          Customize which sidebar options are visible to <b>{nameOfUser}</b>.
          This setting helps to simplify the user interface, ensuring that users
          see only the tools and features relevant to their tasks.
        </span>
      );
    case "Drive Access Rights":
      return (
        <span>
          Control the <b>{nameOfUser}’s</b> access to different folders and
          their contents. Set permissions based on the user’s job requirements
          and security clearance to protect sensitive information.
        </span>
      );
    case "Notification Preferences":
      return (
        <span>
          Please select a topic below and <b>enable notifications as desired</b>{" "}
          by toggling the switches.
        </span>
      );
    default:
      return "You can manage the access rights of the user here.";
  }
}
