import DefaultWidgetInfo from "./DefaultWidgetInfo";

/**
 * Renders a list of default widgets.
 *
 * @component
 * @param {Array} defaultWidgets - The array of default widgets.
 * @param {Function} handleChartSelect - The function to handle chart selection.
 * @param {boolean} selected - Indicates whether the widget is selected or not.
 * @returns {JSX.Element} The rendered component.
 */
const DefaultWidgetList = ({
  defaultWidgets = [],
  handleChartSelect = () => {},
  selected = false,
}) => {
  return (
    <div
      className="default-list"
      data-testid={`default-widgets-list-${
        selected ? "selected" : "not-selected"
      }`}
    >
      {defaultWidgets?.map((el, index) => (
        <DefaultWidgetInfo
          {...{
            el,
            cardKey: index,
            handleChartSelect,
            selected,
          }}
          key={`${index}-default-widget`}
        />
      ))}
    </div>
  );
};

export default DefaultWidgetList;
