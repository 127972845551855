import {
  useRef,
  Fragment,
  useState,
  useEffect,
  useContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import {
  MarkerF,
  Polygon,
  PolylineF,
  GoogleMap,
  InfoWindowF,
  OverlayViewF,
} from "@react-google-maps/api";
import { message } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";

import {
  getFence,
  createInfoDesc,
  getStatsFromTrips,
  FleetsLiveContext,
  loadLivePreference,
  findBoundExtremities,
  findGeofenceCenterCoordinate,
} from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import LiveAuditContext from "../../../../LiveAuditContext";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { MAP_THEME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import ActualPlanCard from "../ActualPlanCard/ActualPlanCard";
import { PauseIconFilled } from "src/assets";

import "./AuditMapView.scss";

/**
 * @typedef {"IDLE"|"STOP"|"FENCE"} EventType
 */

/**
 * @typedef ConfigParam
 * @property {string} address
 * @property {EventType} type
 * @property {string} durationInfo
 */

/**
 * @typedef {string|{lat: number, lng:number}} PositionParam
 */

/**
 * @typedef {lat:number, lng:number} Coordinate
 */

/**
 * @typedef RenderInstance
 * @property {string} address
 * @property {Coordinate[]} [points]
 * @property {Coordinate} position
 */

const AuditMapView = forwardRef(
  (
    {
      trips,
      dayStats,
      setDayStats,
      timeChanges,
      createdAlerts,
      actualPlanRef,
      onReasonSelect,
      dateBoundaries,
      discardedStops,
      linkedStopsData,
      selectedReasons,
      setDateBoundaries,
      setDiscardedStops,
      setLinkedStopsData,
      checkForOtherDay = () => {},
      onChangeActualTime = () => {},
      updateTripsHandler = () => {},
      allAuditsForVehicle = () => {},
    },
    ref
  ) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const { selectedVehicle } = useContext(LiveAuditContext);
    const { geofences, getTripsCollection, allStops } =
      useContext(FleetsLiveContext);

    const [fencesInRadius, setFencesInRadius] = useState([]);
    const [marker, setMarker] = useState(
      /** @type {google.maps.Marker} */ (null)
    );
    const [polyline, setPolyline] = useState(
      /** @type {google.maps.Polyline} */ (null)
    );
    const [polygon, setPolygon] = useState(
      /** @type {google.maps.Polygon} */ (null)
    );
    const [map, setMap] = useState(/** @type {google.maps.Map} */ (null));
    const [replayData, setReplayData] = useState(
      /** @type {{allTrips: Array<any>, allLocations: Array<any>}} */ ({
        allTrips: [],
        allLocations: [],
      })
    );
    const [renderInstance, setRenderInstance] = useState(
      /** @type {RenderInstance|null} */ (null)
    );
    const [externalDateFilter, setExternalDateFilter] = useState({
      start: null,
      end: null,
    });

    const infoWindow = useRef(new google.maps.InfoWindow({}));
    const infoContainer = useRef(document.createElement("div"));
    const infoContent = useRef(document.createElement("div"));
    const circle = useRef(
      new google.maps.Circle({
        fillColor: "#F6CB51",
        fillOpacity: 0.3,
        strokeWeight: 2,
        strokeColor: "#F6CB51",
        clickable: false,
      })
    );

    const drawLine = useCallback(
      async (points) => {
        if (!map || !polyline || !polygon || !marker) {
          return;
        }

        circle.current.setVisible(false);
        polyline.setPath([]);
        polygon.setVisible(false);
        marker.setVisible(false);
        infoWindow.current.close();
        setFencesInRadius([]);

        const allLocations = points.map(({ latitude, longitude }) => ({
          lat: latitude,
          lng: longitude,
        }));

        const bounds = new google.maps.LatLngBounds();
        const extremities = findBoundExtremities(allLocations);
        for (const extremity of extremities) {
          bounds.extend(extremity);
        }

        if (!points.length) {
          map.panTo(NYC_CENTER);
        } else {
          polyline.setPath(allLocations);
          map.fitBounds(bounds);
        }
      },
      [map, polyline, polygon, marker]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          onFilter(filterValues) {
            if (!filterValues) {
              setExternalDateFilter({
                start: null,
                end: null,
              });
            } else {
              setExternalDateFilter(filterValues);
            }
          },
          onDrawNearFences(values) {
            onNearestSearch(values);
            if (map && values?.position) {
              map.panTo(values.position);
            }
          },
          renderCustomLocation(params) {
            setRenderInstance(params);
          },
          clearCustomLocation() {
            setRenderInstance(null);
          },
        };
      },
      [map]
    );

    useEffect(() => {
      const container = infoContainer.current;
      const info = infoContent.current;

      container.classList.add("ref-info-content");
      info.classList.add("info-content");

      container.appendChild(info);

      void getReplayData({
        fleetId: selectedVehicle,
        maxDate: dateBoundaries.maxDate,
        minDate: dateBoundaries.minDate,
      });
    }, []);

    useEffect(() => {
      drawLine(replayData.allLocations);
    }, [drawLine, replayData.allLocations]);

    async function getReplayData({ fleetId, minDate, maxDate }) {
      let res = await getTripsCollection({ fleetId, minDate, maxDate });
      setReplayData(res);

      const lowBound = dateBoundaries.minDate;
      const highBound = dayjsNY(dateBoundaries.maxDate).endOf("day").valueOf();

      setDayStats(
        getStatsFromTrips({
          allTrips: res?.allTrips || [],
          allStops: allStops?.[selectedVehicle]?.filter(
            ({ beginDate, endDate }) =>
              lowBound <= endDate && highBound >= beginDate
          ),
        })
      );
    }

    /**
     * Handler for the location hover callback
     * @param {PositionParam} location
     * @param {ConfigParam} config
     */
    function onLocationHover(location, config) {
      if (!marker || !polygon || !infoWindow.current) {
        return;
      }

      if (typeof location === "string") {
        const fence = geofences?.[getFence(location)];
        const points = (fence?.points || []).map(({ latitude, longitude }) => ({
          lat: latitude,
          lng: longitude,
        }));
        const center = findGeofenceCenterCoordinate(points);

        polygon.setPaths(points);
        polygon.setVisible(true);
        marker.setVisible(false);

        infoWindow.current.setContent(createInfoContent(config));
        infoWindow.current.setPosition(center);
        infoWindow.current.open({ map });

        map.panTo(center);
      } else {
        marker.setPosition(location);
        marker.setVisible(true);
        polygon.setVisible(false);

        infoWindow.current.setContent(createInfoContent(config));
        infoWindow.current.setPosition(location);
        infoWindow.current.open({ map });

        map.panTo(location);
      }
    }

    /**
     * @param {{unit: "foot"|"mile", searchRadius: string}} values
     */
    function onNearestSearch(values) {
      if (!values) {
        circle.current.setVisible(false);
        setFencesInRadius([]);
        return;
      }

      let tmp = [];
      let radInFeet = +values.searchRadius;
      if (values.unit === "mile") {
        radInFeet = +values.searchRadius * 5280;
      }

      const infoPosition = {
        lat: infoWindow.current.get("position")?.lat(),
        lng: infoWindow.current.get("position")?.lng(),
      };

      if (!infoPosition?.lat) {
        message.info({
          content:
            "You need to put a location on the map before you can search",
          key: "missingLocation",
        });
        return;
      }

      for (const fenceName in geofences) {
        const points = geofences[fenceName]?.points || [];
        if (!points?.length) {
          continue;
        }

        if (geofences[fenceName]["type"] === "circle") {
          continue;
        }

        let pointInRange = points
          .concat(findGeofenceCenterCoordinate(points))
          .find((point) => {
            return withinRadius(
              { lat: point.latitude, lng: point.longitude },
              infoPosition,
              radInFeet
            ).withinRange;
          });

        if (pointInRange) {
          tmp.push({
            ...geofences[fenceName],
          });
        }
      }

      if (!tmp.length) {
        message.warning({
          content: "No geofence was found in the search",
          key: "noFence",
        });
      }

      circle.current.setCenter(infoPosition);
      circle.current.setRadius(radInFeet / 3.281);
      circle.current.setVisible(true);
      setFencesInRadius(tmp);
    }

    /**
     * Function that creates the content elements of the info window
     * @param {ConfigParam} config
     * @returns {HTMLDivElement}
     */
    function createInfoContent(config) {
      const container = infoContainer.current;
      const info = infoContent.current;

      info.textContent = "";

      const desc = createInfoDesc(config.type, config.durationInfo);

      const add = document.createElement("span");
      add.classList.add("info-address");
      add.innerText = config.address;

      info.appendChild(desc);
      info.appendChild(add);

      return container;
    }

    const startEndMarkers = [
      replayData.allLocations?.[0],
      replayData.allLocations?.[replayData.allLocations.length - 1],
    ].filter(Boolean);

    return (
      <div className={`audit-map-view ${isDarkMode ? "audit-map-dark" : ""}`}>
        <ActualPlanCard
          trips={trips}
          ref={actualPlanRef}
          dayStats={dayStats}
          timeChanges={timeChanges}
          createdAlerts={createdAlerts}
          onReasonSelect={onReasonSelect}
          dateBoundaries={dateBoundaries}
          discardedStops={discardedStops}
          selectedReasons={selectedReasons}
          onLocationHover={onLocationHover}
          linkedStopsData={linkedStopsData}
          onBoundariesChanged={getReplayData}
          checkForOtherDay={checkForOtherDay}
          setDiscardedStops={setDiscardedStops}
          setDateBoundaries={setDateBoundaries}
          onChangeActualTime={onChangeActualTime}
          externalDateFilter={externalDateFilter}
          setLinkedStopsData={setLinkedStopsData}
          updateTripsHandler={updateTripsHandler}
          allAuditsForVehicle={allAuditsForVehicle}
        />
        <GoogleMap
          {...{
            zoom: 12,
            mapContainerClassName: "audit-map",
            onLoad(e) {
              setMap(e);
              infoWindow.current.setMap(e);
              circle.current.setMap(e);
            },
            center: NYC_CENTER,
            id: "audit-map",
            options: {
              disableDefaultUI: true,
              styles: MAP_THEME[loadLivePreference("mapStyle")],
              mapTypeId: loadLivePreference("mapType"),
              fullscreenControl: true,
              clickableIcons: false,
              rotateControl: true,
              fullscreenControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
              },
              streetViewControl: true,
              streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
              },
            },
          }}
        >
          <PolylineF
            {...{
              onLoad(e) {
                setPolyline(e);
              },
              options: {
                visible: true,
                strokeColor: "#9d9dff",
                strokeWeight: 4,
                strokeOpacity: 1,
              },
            }}
          />
          <Polygon
            {...{
              onLoad(e) {
                setPolygon(e);
              },
              options: {
                editable: false,
                draggable: false,
                strokeColor: "#d99f00",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "#d99f00",
                fillOpacity: 0.35,
              },
            }}
          />
          <MarkerF
            {...{
              onLoad(e) {
                setMarker(e);
              },
            }}
          />
          {renderInstance && (
            <Fragment>
              <InfoWindowF
                position={renderInstance.position}
                options={{
                  content: renderInstance.address,
                }}
              >
                {renderInstance?.points ? (
                  <Polygon
                    {...{
                      paths: renderInstance.points,
                      options: {
                        editable: false,
                        draggable: false,
                        strokeColor: "#d99f00",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillColor: "#d99f00",
                        fillOpacity: 0.35,
                      },
                    }}
                  />
                ) : (
                  <MarkerF position={renderInstance.position} />
                )}
              </InfoWindowF>
            </Fragment>
          )}
          {startEndMarkers.map((e, i) => {
            return (
              <OverlayViewF
                position={{
                  lat: e.latitude,
                  lng: e.longitude,
                }}
                mapPaneName="overlayMouseTarget"
                key={`overlay-${i}`}
              >
                <div className="live-marker">
                  {i ? (
                    <span className="marker-status-icon Stopped">
                      <PauseIconFilled height={24} width={24} />
                    </span>
                  ) : (
                    <span className="marker-status-icon Play">
                      <PlayCircleFilled height={24} width={24} />
                    </span>
                  )}
                </div>
              </OverlayViewF>
            );
          })}
          {fencesInRadius.map((fence) => {
            return (
              <InfoWindowF
                key={fence.geofenceUUID}
                position={findGeofenceCenterCoordinate(fence.points)}
                options={{
                  content: fence.name,
                }}
              >
                <Polygon
                  {...{
                    path: fence.points.map(({ latitude, longitude }) => ({
                      lat: latitude,
                      lng: longitude,
                    })),
                    options: {
                      editable: false,
                      draggable: false,
                      strokeColor: "#ff4d4f",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      fillColor: "#ff4d4f",
                      fillOpacity: 0.35,
                    },
                  }}
                />
              </InfoWindowF>
            );
          })}
        </GoogleMap>
      </div>
    );
  }
);

export default AuditMapView;
