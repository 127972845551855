import { actions } from "../reducers/Charge/init"

const updateChargeRedux = (dispatch) => ({
  setRowData: (value) => {
    dispatch({ type: actions.SET_ROW_DATA, payload: value })
  },
  setChargeToView: (value) => {
    dispatch({ type: actions.SET_CHARGE_TO_VIEW, payload: value })
  },
  setChargeItemsModal: (value) => {
    dispatch({ type: actions.SET_CHARGE_ITEMS_MODAL, payload: value })
  },
  setNewChargeVisible: (value) => {
    dispatch({ type: actions.SET_NEW_CHARGE_VISIBLE, payload: value })
  },
  setChargeToSave: (value) => {
    dispatch({ type: actions.SET_CHARGE_TO_SAVE, payload: value })
  },
  setItemsGroup: (value) => {
    dispatch({ type: actions.SET_ITEMS_GROUP, payload: value })
  },
  setSelectedService: (value) => {
    dispatch({ type: actions.SET_SELECTED_SERVICE, payload: value })
  },
  setSelectedEvent: (value) => {
    dispatch({ type: actions.SET_SELECTED_EVENT, payload: value })
  },
  setApplyCredit: (value) => {
    dispatch({ type: actions.SET_APPLY_CREDIT, payload: value })
  },
  setCreditApplied: (value) => {
    dispatch({ type: actions.SET_CREDIT_APPLIED, payload: value })
  },
  setUpdatedCreditApplied: (value) => {
    dispatch({ type: actions.SET_UPDATED_CREDIT_APPLIED, payload: value })
  },
  setRemainingCredit: (value) => {
    dispatch({ type: actions.SET_REMAINING_CREDIT, payload: value })
  },
  setApliedCredit: (value) => {
    dispatch({ type: actions.SET_APPLIED_CREDIT, payload: value })
  },
  setCharge: (value) => {
    dispatch({ type: actions.SET_CHARGE, payload: value })
  },
  setWhereToFind: (value) => {
    dispatch({ type: actions.SET_WHERE_TO_FIND, payload: value })
  },
  setFromObject: (value) => {
    dispatch({ type: actions.SET_FROM_OBJECT, payload: value })
  },
  setSelectedGroup: (value) => {
    dispatch({ type: actions.SET_SELECTED_GROUP, payload: value })
  },
  setInitialStates: (value) => {
    dispatch({ type: actions.SET_INITIAL_STATES, payload: value })
  },
  setActions: (value) => {
    dispatch({ type: actions.SET_ACTIONS, payload: value })
  },
})
export default updateChargeRedux
