import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isEqual } from "lodash";
import { message } from "antd";
import AccountingConfirmationModal from "../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import {
  updateRental,
  resetRental,
} from "../../../../../../../../actions/rentals";
import ControlPanel from "./Components/ControlPanel/ControlPanel";
import {
  checkAllServicesHaveRetainage,
  handleSave,
  sendToAccounting,
  checkAllNotApplied,
} from "./headerFunction";
import MainHeader from "./Components/MainHeader/MainHeader";
import { useRedux } from "../../../../../../hooks";
import { useBeforeUnload } from "../../../../../../../../hooks/useBeforeUnload";
import { getChangedData } from "../../../../../../Accounting/components/utilities";
import { useEditLogs } from "../../../../../../../../hooks";
import "./Header.scss";

const Header = ({
  setRetract,
  retract,
  canUndo,
  canRedo,
  undoClickHandler,
  redoClickHandler,
  isNextStep,
  handleCloseForm,
}) => {
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const [confirmModalVisibility2, setConfirmModalVisibility2] = useState(false);

  const { saveAddedLogs } = useEditLogs();

  // const { isAutoSaving, fakeRentals, darkMode } = useStateContext()
  const { authUser = {}, rental = {} } = useSelector((state) => {
    return {
      authUser: state.authenticatedUser.authenticatedUser,
      rental: state.rentals.rental,
    };
  });
  // const [isAutoSaving] = useRedux("rentalsIsAutoSaving");
  // const [isWritable] = useRedux("rentalsIsWritable");
  const [darkMode] = useRedux("rentalsDarkMode");
  const [fakeRentals] = useRedux("fakeRentals");

  // const { rental } = useSelector((state) => state?.rentals);
  // const dispatchContext = useDispatchContext()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const params = useParams();

  //is used to save the rental object to the db
  const saveHandler = () => {
    message.info("Saving...", 0);
    const newEditLogData = {
      recordId: fakeRentals?.rentalId,
      recordName: `Rental ${fakeRentals?.rentalNumber}`,
      actionType: "Edit",
      category: "Rentals",
      topic: fakeRentals?.projectAddress,
      currentData: {},
      previousData: {},
      updatedKeys: [],
    };
    for (let key in fakeRentals) {
      let result;
      if (!!fakeRentals[key] && !!rental[key]) {
        result = getChangedData(fakeRentals[key], rental[key]);
      } else {
        continue;
      }
      if (result !== false) {
        newEditLogData.currentData[key] = result.curr;
        newEditLogData.previousData[key] = result.prev;
        newEditLogData.updatedKeys.push(key);
      }
    }

    saveAddedLogs(newEditLogData);

    handleSave({ dispatch, updateRental, fakeRentals });
    message.destroy();
    message.success("Saved successfully", 1);
  };
  //is called when we pres discard or confirm on closing confirmation modal
  const closeRentalView = (isSaved) => {
    if (isNextStep) {
      handleCloseForm();
      return;
    }

    sendToAccounting({ fakeRentals, navigate, location, isSaved });
  };
  useBeforeUnload(params.rentalsId);

  //this handle close button function
  const closeHandler = () => {
    if (checkAllNotApplied(fakeRentals?.services)) {
      message.warning(
        "Careful, you haven't selected any items to be charged!",
        5
      );
    }
    // setConfirmModalVisibility(true);
    // if (isEqual(fakeRentals, rental)) {
    if (checkAllServicesHaveRetainage(fakeRentals?.services)) {
      if (isEqual(fakeRentals, rental)) {
        closeRentalView();
        dispatch();
      } else {
        setConfirmModalVisibility(true);
      }
    } else {
      setConfirmModalVisibility2(true);
    }
  };

  //last modified by changes every time we makes a put on db, for that reason if we put fakeRentals as dependency on uef it will make a look bcs it will always be different from the first one
  const { lastModifiedBy, ...rest } = fakeRentals || {};

  //this handle auto save
  // useEffect(() => {
  //   // dispatchContext({ type: "SET_UNCHANGING_FAKE_RENTALS", payload: fakeRentals })
  //   setUnchangingFakeRentals(fakeRentals);

  //   if (isAutoSaving && isWritable) {
  //     saveHandler();
  //   }
  // }, [JSON.stringify(rest)]);

  return (
    <div className="rentalsHeaderContainer">
      <MainHeader {...{ closeHandler }} />
      <ControlPanel
        {...{
          saveHandler,
          setRetract,
          retract,
          canUndo,
          canRedo,
          undoClickHandler,
          redoClickHandler,
        }}
      />
      {confirmModalVisibility && (
        <AccountingConfirmationModal
          {...{
            visible: confirmModalVisibility,
            setVisible: setConfirmModalVisibility,
            onDiscard: () => closeRentalView(),
            onConfirm: () => {
              saveHandler();
              dispatch(resetRental());
              closeRentalView(true);
            },
            darkMode,
          }}
        />
      )}
      {confirmModalVisibility2 && (
        <AccountingConfirmationModal
          {...{
            visible: confirmModalVisibility2,
            text: "Not All Services Have Retainage",
            title: "Are you sure you want to Save?",
            setVisible: setConfirmModalVisibility2,
            onDiscard: () => closeRentalView(),
            onConfirm: () => {
              saveHandler();
              dispatch(resetRental());
              closeRentalView(true);
            },
            darkMode,
          }}
        />
      )}
    </div>
  );
};

export default Header;
