import { fetchByDate, filterTables } from "../../../../../../../utils";

/**
 * @typedef {import("../../../../../FleetMaintenanceView/types").WorkOrderType} WorkOrder
 */

/**
 * @typedef {import("../../../../../FleetMaintenanceView/types").ScheduleMaintenanceType} ScheduleMaintenance
 */

/**
 * @typedef FindWorkORderParam
 * @property {string} fleetId
 * @property {string} serviceId
 *
 * @param {FindWorkORderParam} param
 */
async function findPreviousWorkOrder(param) {
  const { fleetId, serviceId } = param;

  /** @type {WorkOrder[]} */
  let relevantWorkOrders = [];

  /** @type {WorkOrder[]} */
  let vehicleWorkOrders = [];

  /**
   * we get all the schedule maintenances for the vehicle
   * which are not the one we opened the work order modal with.
   * We do this in order to get a time frame of the work orders
   * that happened between the two schedule maintenances
   */

  /** @type {ScheduleMaintenance[]} */
  const schedulesForVehicle = await filterTables(
    "serviceMaintenance",
    "fleetId",
    fleetId
  ).then((res) => {
    return res.filter((e) => e.serviceId !== serviceId);
  });

  if (!schedulesForVehicle.length) {
    vehicleWorkOrders = await filterTables("workOrders", "fleetId", fleetId);
  } else {
    schedulesForVehicle.sort((a, b) => b.createdAt - a.createdAt);
    const [mostRecent] = schedulesForVehicle;

    const lastScheduledWorkOrderId = mostRecent.workOrderId;

    vehicleWorkOrders = await fetchByDate(
      "workOrders",
      [mostRecent.createdAt, Date.now()],
      "issueDate",
      "fleetId",
      fleetId
    ).then((res) =>
      res.filter((e) => e.workOrderId !== lastScheduledWorkOrderId)
    );
  }

  if (vehicleWorkOrders.length) {
    vehicleWorkOrders.sort(
      (a, b) => (b.issueDate || b.createdAt) - (a.issueDate || a.createdAt)
    );

    if (!schedulesForVehicle.length) {
      // in this case we have all of the work orders
      // so we just take the most recent
      relevantWorkOrders = [vehicleWorkOrders[0]];
    } else {
      // in this case we should have all the work orders
      // between today and the last schedule maintenance
      // sso we can pick them all of them
      relevantWorkOrders = vehicleWorkOrders;
    }
  }

  const defectsSet = new Set(
    relevantWorkOrders.flatMap((e) => e.workOrderItems.map((i) => i.defectName))
  );

  return Array.from(defectsSet);
}

export default findPreviousWorkOrder;
