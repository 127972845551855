import { SettingOutlined } from "@ant-design/icons";
import { Drawer, Tooltip } from "antd";
import React, { useState } from "react";
import { MondayButton } from "../../../../../../commonComponents";
import MoreOptionsDrawer from "./MoreOptionsDrawer";

const placement = "bottom";

//TODO: Add clearance config
function MoreOptions({ customRender, open, setOpen }) {
  const onClose = () => {
    setOpen(false);
  };

  const handleShow = () => {
    setOpen(true);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <MondayButton
        hasIcon={false}
        className="mondayButtonPrismarine"
        onClick={handleShow}
        tooltipCategory="Take Off"
        tooltipKey="elevationAdvancedTools"
        //   Icon={<SettingOutlined />}
      >
        ...
      </MondayButton>
      <div>
        <MoreOptionsDrawer
          placement={placement}
          open={open}
          onClose={onClose}
          customRender={customRender}
        />
      </div>
    </div>
  );
}

export default MoreOptions;
