import "./ScheduleOfValue.scss";

import { Radio, Space } from "antd";

const ScheduleOfValue = ({
  sovs,
  chosenSOV,
  setChosenSOV,
  setFormChanged = () => {},
  setSelectedMonth,
  setDueDate,
}) => {
  return (
    <div className="sovPickerContainer">
      <div className="title">Schedule of Values</div>
      {!!sovs?.length ? (
        <Radio.Group
          value={chosenSOV}
          onChange={(e) => {
            setFormChanged(true);
            setChosenSOV(e.target.value);
            setSelectedMonth(null), setDueDate(null);
          }}
        >
          <Space direction="vertical">
            {sovs?.map((sov, index) => (
              <Radio key={sov?.scheduleId} value={sov?.scheduleId}>
                SOV {sov?.SOVNo}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      ) : (
        <p className="no-schedule-of-values">No Schedule of Values</p>
      )}
    </div>
  );
};

export default ScheduleOfValue;
