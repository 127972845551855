import { Collapse } from "antd";
import Geofence from "../../../commonComponents/Map/Geofence/Geofence";

const GeofenceTodoModal = ({
  geoCoords,
  setGeoFenceInfo,
  geoFenceInfo,
  rowData,
}) => {
  return (
    <Collapse
      ghost
      items={[
        {
          key: "1",
          label: "Geofence Info",
          children: (
            <Geofence
              places={[{ coordinates: geoCoords }]}
              setGeoFenceInfo={setGeoFenceInfo}
              geoFenceInfo={geoFenceInfo}
              rowData={rowData}
              showDrawingManager={false}
              allowEditing={false}
            />
          ),
        },
      ]}
    />
  );
};

export default GeofenceTodoModal;
