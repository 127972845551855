import CompleteAllHoist from "../Components/CompleateAllHoist/CompleteAllHoist";
import { formatCurrency } from "../../../../../../../../utils";
import { useStateContext } from "../../../../Context/Context";
import "./service-header.scss";

import AnimatedNumber from "react-animated-number";
import ChangeOrderIndicator from "../../../../../../ScheduleOfValues/ScheduleOfValueView/components/ServiceList/Service/ServiceHeader/Components/ChangeOrderIndicator/ChangeOrderIndicator";
import CreditEnabler from "../../Service/ServiceHeader/ServiceHeaderComponents/CreditEnabler/CreditEnabler";

const ServiceHeader = ({ service, elevation }) => {
  const { darkMode } = useStateContext();

  const retainage = elevation?.amounts?.reduce(
    (acc, curr) => acc + curr?.thisKeptRetainage,
    0
  );

  return (
    <div
      className={
        darkMode
          ? "serviceListServiceHeaderHoistDark"
          : "serviceListServiceHeaderHoistLight"
      }
    >
      <div className="tittle">{elevation?.elevationLabel}</div>
      <CompleteAllHoist
        {...{
          elevationId: elevation?.elevationId,
          serviceId: service?.serviceId,
          estimationId: service?.estimationId,
        }}
      />
      <div className="headerSectionContainer">
        <div className="label">Total amount:</div>
        <div
          className={
            elevation?.price === 0
              ? "amount-equal-with-0"
              : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={elevation?.price}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>
      <div className="headerSectionContainer">
        <div className="label">Retainage:</div>
        <div
          className={
            retainage === 0 ? "amount-equal-with-0" : "amount-diff-from-0"
          }
        >
          <AnimatedNumber
            value={Math.round(retainage)}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>{" "}
      <div style={{ marginLeft: "-20px" }}>
        <CreditEnabler {...{ service, elevation, color: null }} />
      </div>
      <div className="rightContainer">
        {service?.isChangeOrder && <ChangeOrderIndicator />}
      </div>
    </div>
  );
};

export default ServiceHeader;
