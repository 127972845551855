import ServiceDescription from "./ServiceDescription";
import MoreServices from "./MoreServices";

const colors = ["#30973A", "#EEB522", "#4662F2"];

export const SovDescription = ({ groups, selectedGroup }) => {
  const { services: groupServices, label } = groups.find(
    ({ id }) => id === selectedGroup
  );

  const getTotal = (service) => {
    let totalPrice = 0;
    if (service?.label === "Hoist" || service?.isHoist)
      totalPrice = service?.serviceOptions?.reduce(
        (accOption, serviceOption) =>
          accOption +
          serviceOption.reduce(
            (accService, service) =>
              accService +
              (service.amounts?.reduce(
                (accAmount, item) => accAmount + (item?.amount || 0),
                0
              ) || 0),
            0
          ),
        0
      );
    else
      totalPrice = service.amounts.reduce(
        (accAmount, item) => accAmount + (item?.amount || 0),
        0
      );

    return totalPrice;
  };

  const allServices = groupServices.map((service) => ({
    ...service,
    elevations:
      service.serviceOptions?.[0]?.map((option) => option.elevationLabel) || [],
    total: getTotal(service),
  }));

  const services = allServices.slice(0, 2);

  const moreServices = allServices.length - services.length;

  return (
    <MoreServices
      hasOtherServices={moreServices}
      services={allServices}
      title={label}
    >
      <div className="GroupContainer">
        {services?.map((service, key) => (
          <ServiceDescription
            key={key}
            service={service.label}
            elevations={service.elevations}
            isTaxable={service.isTaxable}
            showDivider={key === 0 && services.length > 1}
            color={colors[key % colors.length]}
            total={service.total}
          />
        ))}
      </div>
    </MoreServices>
  );
};
