import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const inspections = (updatedDynamicStates, IdHandler, programFields) => {
  return {
    inspectionType: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "inspectionType"
      );

      return {
        title: "Service Inspection Type",
        BodyComponent: "BarChart",
        type: "BarChart",

        BodyComponentParams: {
          table: "Inspections",
          field: "inspectionType",
          labels: Object.keys(data),
          datasets: [
            {
              label: "Inspections",
              data: Object.values(data),
              backgroundColor: Object.keys(data).map(() => getRandomColor()),
              borderWidth: 0,
            },
          ],
        },

        id: IdHandler,
        width: "790px",
      };
    },

    inspectionStatus: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "inspectionStatus"
      );

      const statuses =
        programFields
          ?.find((el) => el?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Inspection?.reduce(
            (acc, curr) => ({
              ...acc,
              [curr?.statusName]: curr?.statusColor,
            }),
            {}
          ) || [];

      return {
        title: "Service Inspection Status",
        BodyComponent: "BarChart",
        type: "BarChart",

        BodyComponentParams: {
          table: "Inspections",
          field: "inspectionStatus",
          labels: Object.keys(data),
          datasets: [
            {
              label: "Inspections",
              data: Object.values(data),
              backgroundColor: Object.keys(data).map(
                (key) => statuses?.[key] || getRandomColor()
              ),
              borderWidth: 0,
            },
          ],
        },

        id: IdHandler,
        width: "790px",
      };
    },
    inspectedBy: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler]?.map((el) => el?.inspectedBy) || [],
        "nameOfUser"
      );

      return {
        title: "Service Inspected By",
        BodyComponent: "BarChart",
        type: "BarChart",

        BodyComponentParams: {
          table: "Inspections",
          field: "inspectedBy",
          labels: Object.keys(data),
          datasets: [
            {
              label: "Inspections",
              data: Object.values(data),
              backgroundColor: Object.keys(data).map(() => getRandomColor()),
              borderWidth: 0,
            },
          ],
        },

        id: IdHandler,
        width: "790px",
      };
    },
  };
};
