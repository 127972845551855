import { Alert, Divider } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  CONTACT_MESSAGE,
  generateContactInputFields,
  generateInputFields,
  style,
} from "./helpers";
import { mapData } from "../../helpers";
import { PlusIcon } from "../../../../../assets";
import { NextStepInputs } from "../NextStepInputs";
import MondayButton from "../../../MondayButton/MondayButton";
import { CloseIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";

const Contact = ({ savedRecord, inputData, setInputData }) => {
  const [additionalContact, setAdditionalContact] = useState(false);

  const { programFields } = useSelector((state) => state.programFields);

  const options = programFields?.reduce(
    (acc, { fieldName, fieldOptions }) => ({
      ...acc,
      [fieldName]: fieldOptions,
    }),
    {}
  );

  const contactRoles = mapData(options["Lead Roles"]);

  const inputFields = generateInputFields();

  const inputContactFields = generateContactInputFields({ contactRoles });

  const isContactCreated = !!savedRecord?.leadEmail;

  const onAdditionalButtonClick = () => setAdditionalContact((prev) => !prev);

  const onAdditionalClose = () => {
    setAdditionalContact((prev) => !prev);
    setInputData((prev) => {
      const updatedData = Object.fromEntries(
        Object.entries(prev).filter(
          ([key]) => !key.includes("additionalContact")
        )
      );
      return updatedData;
    });
  };

  return (
    <>
      {!isContactCreated ? (
        <NextStepInputs {...{ inputData, setInputData, inputFields }} />
      ) : (
        <div style={style.information}>
          <Alert message={CONTACT_MESSAGE} type="info" showIcon />
        </div>
      )}
      {!isContactCreated && <Divider style={{ margin: "-20px" }} />}
      <div style={style.additionalContactContainer}>
        {!additionalContact ? (
          <MondayButton Icon={<PlusIcon />} onClick={onAdditionalButtonClick}>
            Add Additional Contact
          </MondayButton>
        ) : (
          <div style={style.additionalContactItems}>
            <CloseIcon style={style.closeIcon} onClick={onAdditionalClose} />
            <NextStepInputs
              {...{ inputData, setInputData, inputFields: inputContactFields }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Contact;
