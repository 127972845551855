import RentalBreakdown from "./RentalBreakdown/RentalBreakdown";
import { useRedux } from "../../../../../../hooks";
import "./RentalBreakdownList.scss";
import { useEffect, useMemo, useState } from "react";
import { API } from "aws-amplify";
import ServiceRentHeader from "./RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/ServiceRentHeader";
import { Col, Collapse } from "antd";
import { MondayButton } from "../../../../../../../commonComponents";
import { useCallback } from "react";

// import { useStateContext } from "../../Context/Context"

const RentalBreakdownList = ({ canRedo }) => {
  // const { fakeRentals, selectedService } = useStateContext()
  const [selectedService] = useRedux("rentalsSelectedService");
  const [fakeRentals] = useRedux("fakeRentals");
  const [elevationsCount, setElevationsCount] = useState(2);
  const [enableStatistics, setEnableStatistics] = useRedux(
    "enableStatistics",
    {
      dateGaps: false,
      dateCollisions: false,
      details: false,
    },
    true
  );
  //here we extract all the data for the selected service
  const selectedServiceData = useMemo(() => {
    return (
      fakeRentals?.services?.find(({ label }) => label === selectedService) ||
      []
    );
  }, [selectedService, fakeRentals?.services]);

  return (
    <div className="rentalBreakdownListContainer">
      {/* //here we map all the elevations of the service we just extracted*/}
      {selectedServiceData && (
        <ServiceRentHeader {...{ selectedServiceData, canRedo }} />
      )}

      <div
        className="rentalsElevationsContainer"
        // style={{
        //   display: "flex",
        //   flexDirection: "column",
        //   gap: "1rem",
        //   height: "auto",
        //   overflowY: "scroll",
        // }}
      >
        {selectedServiceData?.serviceOptions?.map((elevationData) => (
          <RentalBreakdown
            key={elevationData?.elevationId}
            {...{
              elevationData: {
                ...elevationData,
                taxRate: fakeRentals?.taxRate,
              },
              canRedo,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RentalBreakdownList;

const SETTINGS = {
  itemHeight: 200,
  amount: 2,
  tolerance: 5,
  minIndex: -9999,
  maxIndex: 200000,
  startIndex: 1,
};
