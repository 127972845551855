import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const opportunities = (
  IdHandler,
  programFields,
  updatedDynamicStates
) => {
  const data = updatedDynamicStates?.[IdHandler] || [];
  const opportunityStatus = programFields?.find(
    (a) => a?.fieldName === "Status of opportunities"
  )?.fieldOptions;
  const opportunityStage = programFields?.find(
    (a) => a?.fieldName === "Opportunity Stage"
  )?.fieldOptions;
  return {
    opportunityStatus: () => ({
      title: "Opportunity Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStatus",
        labels: opportunityStatus?.map((a) => a.statusName),
        datasets: [
          {
            label: "My First Dataset",
            data: opportunityStatus
              ?.map((a) => a.statusName)
              ?.map(
                (status) =>
                  data?.filter(
                    (a) =>
                      a.opportunityStatus?.toLowerCase() ===
                      status?.toLowerCase()
                  )?.length
              ),
            backgroundColor: opportunityStatus?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
    opportunityStage: () => ({
      title: "Opportunity Stage",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStage",
        labels: opportunityStage?.map((a) => a.detailedStageName),
        datasets: [
          {
            label: "My First Dataset",
            data: opportunityStage
              ?.map((a) => a.detailedStageName)
              ?.map(
                (status) =>
                  data?.filter(
                    (a) =>
                      a.opportunityStage?.toLowerCase() ===
                      status?.toLowerCase()
                  )?.length
              ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Opportunities?.map((a) => a.statusColor),
            borderWidth: 1,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //opportunityStage

    projectExecutive: () => ({
      title: "Opportunity Project Executive",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectExecutive",
        labels: uniq(data?.map((a) => a?.projectExecutive))?.filter(Boolean),
        datasets: [
          {
            label: "Opportunities",
            data: Object.values(repeatedElementSum(data, "projectExecutive")),
            backgroundColor: uniq(data?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),

    projectManager: () => ({
      title: "Opportunity Project Manager",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectManager",
        labels: uniq(
          data
            ?.map((a) => a?.projectManager)
            ?.flat()
            ?.map((a) => a?.label)
            ?.filter(Boolean)
        ),
        datasets: [
          {
            label: "Opportunities",
            data: Object.values(
              repeatedElementSum(
                data?.map((a) => a?.projectManager)?.flat(),
                "label"
              )
            ),
            backgroundColor: uniq(data?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),

    geoFenceInfo: () => ({
      title: "Geofence Quantity - Opportunities",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Opportunities",
        field: "geoFenceInfo",
        labels: [
          "Opportunities With Geofences",
          "Opportunities Without Geofences",
        ],
        datasets: [
          {
            label: "Opportunities",
            data: [
              (updatedDynamicStates?.[IdHandler] || [])?.reduce(
                (sum, { geoFenceInfo }) =>
                  sum + (geoFenceInfo.length > 0 ? 1 : 0),
                0
              ),
              (updatedDynamicStates?.[IdHandler] || [])?.reduce(
                (sum, { geoFenceInfo }) =>
                  sum + (geoFenceInfo.length === 0 ? 1 : 0),
                0
              ),
            ],
            backgroundColor: programFields
              ?.find((a) => a?.fieldName == "Status of opportunities")
              ?.fieldOptions?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      width: "790px",
    }),
  };
}; //Opportunities
