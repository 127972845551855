import htmlParser from "../../../../../utils/htmlParser";
import { formatCurrency } from "../../../utils";

export const columnDefs = [
  {
    headerName: "Name",
    field: "itemName",
  },
  {
    headerName: "Item no.",
    field: "itemPartNo",
  },
  {
    headerName: "Description",
    field: "itemDescription",
    cellRenderer({ data }) {
      return htmlParser(data?.itemDescription);
    },
  },
  {
    headerName: "Notes",
    field: "itemNotes",
    cellRenderer({ data }) {
      if (!data?.itemNotes) {
        return "";
      } else {
        return data?.itemNotes;
      }
    },
  },
  {
    headerName: "Price",
    field: "costPerUnit",
    cellRenderer({ data }) {
      if (!!data?.costPerUnit) {
        return formatCurrency(data?.costPerUnit);
      } else {
        return <span></span>;
      }
    },
  },
  {
    headerName: "Quantity",
    field: "quantity",
  },
  {
    headerName: "Total",
    field: "total",
    cellRenderer({ data }) {
      if (!data?.total && data?.total !== 0) {
        return formatCurrency(
          parseFloat(data?.costPerUnit) * parseInt(data?.quantity)
        );
      } else {
        return <strong>{formatCurrency(data?.total)}</strong>;
      }
    },
  },
];
