import { Button } from "antd";
import "./CompanyNameDropdown.scss";

const CompanyNameDropdown = ({
  menu,
  setLeadModalVisible,
  setClientModalVisible,
  checkAccess,
}) => (
  <div className="opportunityCompanyNameDropdown">
    {menu}
    <div className="buttonsContainer">
      <Button
        type="primary"
        shape="round"
        onClick={() => setLeadModalVisible(true)}
        disabled={!checkAccess.leadAccess}
      >
        New Lead
      </Button>
      <Button
        type="primary"
        shape="round"
        onClick={() => setClientModalVisible(true)}
        disabled={!checkAccess.clientAccess}
      >
        New Client
      </Button>
    </div>
  </div>
);

export default CompanyNameDropdown;
