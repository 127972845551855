const incidentReducer = (state, action) => {
  try {
    switch (action?.type) {
      case "GET_DATA": {
        const { data } = action.payload;
        return {
          ...state,
          ...data,
        };
      }

      case "SCHEDULES_FOR_DATE": {
        const { schedulesForDate } = action.payload;
        return {
          ...state,
          schedulesForDate,
        };
      }

      case "EDIT_LOGS": {
        const { editLogs } = action.payload;
        return {
          ...state,
          editLogs,
        };
      }

      case "EDIT_LOGS_&_TASKS": {
        const { editLogs, taskData } = action.payload;
        return {
          ...state,
          editLogs,
          taskData,
        };
      }

      case "VIEW_LOGS": {
        const { viewLogs } = action.payload;
        return {
          ...state,
          viewLogs,
        };
      }
      case "SORT_DYNAMIC_FIELDS": {
        const { dynamicFields } = action.payload;
        return {
          ...state,
          dynamicFields,
        };
      }
    }
  } catch (err) {
    console.error("Error in incidentReducer: ", err);
  }
};

export default incidentReducer;
