import dayjs from "dayjs";
import moment from "moment-timezone";

export const getDailyTotalWorkingHours = (scheduleDay) => {
  const difference = getScheduleDayTotalTime(scheduleDay);
  return getHoursAndMinutesFromMilliseconds(difference);
};

export const getScheduleDayTotalTime = (scheduleDay) => {
  const endDate = new Date(scheduleDay?.endDate),
    startDate = new Date(scheduleDay?.startDate);
  return endDate - startDate;
};

export const getHoursAndMinutesFromMilliseconds = (milliseconds) => {
  let minutes = Math.round((milliseconds % one_hour_mils) / (1000 * 60));

  let hours = Math.floor(milliseconds / one_hour_mils);

  if (minutes === 60) {
    hours++;
    minutes = 0;
  } //to correct javascript mistake

  return { hours, minutes };
};

export const getTotalWorkingHours = (scheduleDays) => {
  let totalMilliseconds = 0;

  for (let dayIndex = 0; dayIndex < scheduleDays?.length; dayIndex++) {
    totalMilliseconds += getScheduleDayTotalTime(scheduleDays?.[dayIndex]);
  }

  const breakingMilliseconds = one_hour_mils * scheduleDays?.length;

  let workingMilliseconds = totalMilliseconds - breakingMilliseconds; //subtract one hour from each day

  return {
    totalHours: getHoursAndMinutesFromMilliseconds(totalMilliseconds),
    workingHours: getHoursAndMinutesFromMilliseconds(workingMilliseconds),
    breakingHours: scheduleDays?.length,
  };
};

export const convertToDaysHoursMinutes = (date) => {
  const startDuration = dayjs.duration(date, "milliseconds", true);
  var d = startDuration.days();
  var dayHours = d * 24;
  var h =
    startDuration.hours() +
    dayHours +
    (startDuration.hours() === 1 ? " HOUR " : " HOURS ");
  var m =
    startDuration.minutes() > 0
      ? startDuration.minutes() +
        (startDuration.minutes() === 1 ? " MINUTE " : " MINUTES ")
      : "";
  const startTime = h + m;
  return startTime;
};

export const one_hour_mils = 1000 * 60 * 60;
export const twenty_four_hours_mils = 24 * one_hour_mils;

/**
 * Checks if a day is saturday, sunday or in the future if if it is a holiday. Holiday will be checked by calling another function which will check the day in calendar.
 * If day does not belong to any of these categories, nothing is returned
 * @param date {Date}
 * @return {SPECIAL_DAYS.SATURDAY | SPECIAL_DAYS.SUNDAY | SPECIAL_DAYS.HOLIDAY | undefined}
 * */
export const isOffDay = (date) => {
  switch (dayjs(date).day()) {
    case 6:
      return SPECIAL_DAYS.SATURDAY;
    case 0:
      return SPECIAL_DAYS.SUNDAY;
    default:
      return;
  }
};

const SPECIAL_DAYS = {
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
  HOLIDAY: "HOLIDAY",
};

export const addTimeToDate = (date, time) => new Date(date.getTime() + time);

export const addTimeTo_startDate_endDate = ({ startDate, endDate }, time) => [
  addTimeToDate(startDate, time),
  addTimeToDate(endDate, time),
];

export const extendTimeForSpecialDays = ({
  startDate,
  endDate,
  extendTime = twenty_four_hours_mils,
  skipSaturday,
  skipSunday,
  skipHoliday,
}) => {
  startDate = new Date(startDate);

  endDate = new Date(endDate);

  // console.log(startDate.toLocaleDateString(), isOffDay(startDate));

  if (skipSaturday && isOffDay(startDate) === SPECIAL_DAYS.SATURDAY) {
    //extend if day is saturday and we must skip saturdays
    [startDate, endDate] = addTimeTo_startDate_endDate(
      { startDate, endDate },
      extendTime
    );
    // console.log(startDate.toLocaleDateString());
  }

  if (skipSunday && isOffDay(startDate) === SPECIAL_DAYS.SUNDAY) {
    //extend
    [startDate, endDate] = addTimeTo_startDate_endDate(
      { startDate, endDate },
      extendTime
    );
    // console.log(startDate.toLocaleDateString());
  }
  if (skipHoliday) {
    while (
      isOffDay(startDate) === SPECIAL_DAYS.HOLIDAY ||
      (skipSaturday && isOffDay(startDate) === SPECIAL_DAYS.SATURDAY) ||
      (skipSunday && isOffDay(startDate) === SPECIAL_DAYS.SUNDAY)
    ) {
      [startDate, endDate] = addTimeTo_startDate_endDate(
        { startDate, endDate },
        extendTime
      );
    }
  }

  return [startDate, endDate];
};
