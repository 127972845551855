import { formatCurrency } from "../../../../utils";
import {
  calculateInvoiceStatus,
  categoriesName,
  getSums,
  groupBy,
} from "../../calculatingFunctions";

export const invoices = ({ tables, programFields }) => {
  const invoiceStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    invoiceStatus: () => ({
      content: invoiceStatus?.statusName?.map((status, idx) => ({
        key: idx,
        title: status,
        taskNumber: tables?.invoices?.length,
        taskDone: calculateInvoiceStatus({ table: tables?.invoices, status })
          ?.length,
        color: invoiceStatus?.statusColor[idx],
        backgroundColor: "#B2DD9B",
        contentHandler: ` ${status} Invoice`,
        contentsHandler: ` ${status} Invoices`,
        custom:
          tables?.invoices &&
          groupBy(
            [calculateInvoiceStatus({ table: tables?.invoices, status })],
            "projectName"
          )?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Invoice Amount With Tax": formatCurrency(
                    getSums({
                      data: [
                        calculateInvoiceStatus({
                          table: tables?.invoices,
                          status,
                        }),
                      ],
                      key: "totalInvoiceAmount",
                    })[index] || 0
                  ),
                  "Total Invoice Amount Without Tax": formatCurrency(
                    getSums({
                      data: [
                        calculateInvoiceStatus({
                          table: tables?.invoices,
                          status,
                        }),
                      ],
                      key: "subtotal",
                    })[index] || 0
                  ),
                  "Total Tax": formatCurrency(
                    (getSums({
                      data: [
                        calculateInvoiceStatus({
                          table: tables?.invoices,
                          status,
                        }),
                      ],
                      key: "totalInvoiceAmount",
                    })[index] || 0) -
                      (getSums({
                        data: [
                          calculateInvoiceStatus({
                            table: tables?.invoices,
                            status,
                          }),
                        ],
                        key: "subtotal",
                      })[index] || 0)
                  ),
                },
              },
              ...Object?.entries(el).map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({
                        data: value,
                        key: "totalInvoiceAmount",
                      }) || 0
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "subtotal" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" }) -
                        getSums({ data: value, key: "subtotal" })
                    ),
                  },
                };
              }),
            ];
          }),
      })),
    }),
  };
};
