import { Tooltip } from "antd";
import { useState } from "react";
import { fetchAllData } from "../../../utils";
import MondayButton from "../MondayButton/MondayButton";
import { LogsIcon } from "../../SidebarPages/DynamicView/src/index";
import MultiLevelTreeLogs from "../MultiLevelTreeLogs/MultiLevelTreeLogs";

import "./EditLogs.scss";

export const EditLogs = ({ editLogs, setEditLogs, title = false }) => {
  const [logsVisible, setLogsVisible] = useState(false);

  const updateLogs = () => {
    // if user is located in notes than get all logs of notes and store it in editLogs
    // if user is located in All Automation than get all logs of automation and store it in editLogs
    if (title === "Notes Logs") {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "category",
                  value: "Notes",
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => setEditLogs(res))
        .catch((err) => console.log("error: ", err));
    } else if (title === "Automation Logs") {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "category",
                  value: "Automations",
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => setEditLogs(res))
        .catch((err) => console.log("error: ", err));
    }
  };

  const openLogs = () => {
    updateLogs();
    setLogsVisible(true);
  };

  return (
    <>
      <Tooltip title="Edit Logs">
        <MondayButton
          hasIcon={false}
          className="mondayButtonBlue"
          onClick={openLogs}
          id={"editLogs-btn"}
          Icon={<LogsIcon style={{ fill: "#fff" }} />}
        />
      </Tooltip>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: (value) => setLogsVisible(value),
            logsData: editLogs || [],
            title: title ? title : "Schedule Of Values Logs",
          }}
        />
      )}
    </>
  );
};
