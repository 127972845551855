export const projects = ({ tables, circleProgressPicker }) => {
  return {
    projectStatus: () => ({
      percent: (
        (tables?.Projects?.filter((a) => a?.projectStatus === circleProgressPicker)?.length /
          tables?.Projects?.length) *
        100
      )?.toFixed(1),
      data: tables?.Projects?.filter((a) => a?.projectStatus === circleProgressPicker)?.length,
    }), //projectStatus
    attorney: () => ({
      percent: (
        (tables?.Projects?.filter(
          (a) => a?.attorney?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Projects?.length) *
        100
      )?.toFixed(1),
      data: tables?.Projects?.filter(
        (a) => a?.attorney?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //attorney
    expeditor: () => ({
      percent: (
        (tables?.Projects?.filter(
          (a) => a?.expeditor?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Projects?.length) *
        100
      )?.toFixed(1),
      data: tables?.Projects?.filter(
        (a) => a?.expeditor?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }), //expeditor
  }
}
