import { AutoComplete } from "antd";
import { useEffect, useState } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { LabeledInput } from "..";
import { compareIncluding } from "../../../utils";

const GPlacesAutocompleteInput = ({
  label,
  form,
  formItemName,
  initialValue = "",
  noFormItem = false,
  className = "",
  style = {},
  required = false,
  rules = [],
  onSelect = () => {},
  onChange = () => {},
  onBlur = () => {},
  onClear = () => {},
  onKeyDown = () => {},
  onPopupScroll = () => {},
  customOnSearch = () => {},
  placeholder,
  groupedOptions = [],
  disabled = false,
  children,
  popoverVisible = false,
  popoverTitle = "",
  popoverContent,
  popoverPlacement,
  popoverClassName,
  allowSearch = true,
  isCustomValue = false,
  filterOption = true,
  autoFocus = false,
  open = false,
  popupClassName = "",
  existingValues = [],
  keepOptions = false,
  dropdownStyle = "",
}) => {
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
  });
  const [changingValue, setChangingValue] = useState();

  const [selectedAddress, setSelectedAddress] = useState(initialValue);

  const options = () => {
    const reducedGroupedOptions = groupedOptions
      ?.map((curr) => ({
        ...curr,
        options: curr?.options?.filter((item) =>
          compareIncluding(item["value"], value)
        ),
      }))
      .filter(({ options }) => !!options?.length);

    if (keepOptions === true) {
      return [
        ...reducedGroupedOptions,
        {
          label: "Google Results",
          options: data?.map(({ place_id, description }) => {
            return { key: place_id, value: description };
          }),
        },
      ]?.filter(Boolean);
    } else {
      return !!reducedGroupedOptions?.find(({ options }) => !!options?.length)
        ? reducedGroupedOptions
        : [
            existingValues?.length &&
              changingValue?.length > 2 && {
                label: "Existing Records",
                options: existingValues?.map((children, index) =>
                  typeof children === "string"
                    ? {
                        label: children,
                        value: children,
                        disabled: true,
                        key: `custom-${index}`,
                      }
                    : { ...children, key: `custom-${index}` }
                ),
              },
            {
              label: "Google Results",
              options: data?.map(({ place_id, description }) => {
                return { key: place_id, value: description };
              }),
            },
            isCustomValue &&
              changingValue && {
                label: "Custom Results",
                options: [
                  {
                    key: "1",
                    value: changingValue,
                  },
                ],
              },
          ].filter(Boolean);
    }
  };

  const onSearch = (val) => {
    if (allowSearch) {
      customOnSearch(val);
      setValue(val);
    }
    // else {
    // 	message.error("Search is disabled for this field");
    // }
  };

  const onSelection = (val) => {
    setValue(val, false);
    setSelectedAddress(val);
    clearSuggestions();
  };

  const handleBlur = () => {
    if (!!form && !!selectedAddress && !!form.getFieldValue(formItemName)) {
      form.setFieldsValue({ [formItemName]: selectedAddress });
    }
    clearSuggestions();
  };

  const handleClear = (val) => {
    if (!val) {
      setSelectedAddress("");
      setValue("", false);
      clearSuggestions();
    }
  };

  return (
    <LabeledInput
      {...{
        label,
        formItemName,
        initialValue,
        noFormItem,
        className,
        style,
        required,
        rules,
        popoverVisible,
        popoverTitle,
        popoverContent,
        popoverPlacement,
        popoverClassName,
      }}
    >
      <AutoComplete
        {...{
          dropdownStyle,
          onSearch,
          onSelect(val, option) {
            onSelection(val);
            onSelect(val, option);
          },
          onBlur() {
            handleBlur();
            onBlur();
          },
          onPopupScroll,
          defaultValue: initialValue,
          onClear,
          autoFocus,
          popupClassName,
          popupMatchSelectWidth: false,
          disabled: disabled,
          placeholder,
          options: options(),
          filterOption,
          onChange(val) {
            handleClear(val);
            onChange(val);
            setChangingValue(val);
          },
          onKeyDown(val) {
            onKeyDown(val);
          },
          allowClear: true,
          ...(open === true ? { open: true } : {}),
        }}
      >
        {children}
      </AutoComplete>
    </LabeledInput>
  );
};

export default GPlacesAutocompleteInput;
