import { redirectToRental } from "../../../../../../../../../../Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/IncludeRentals/includeRentalsFunctions";
import {
  existingRentalsHandler,
  findingConflictingRangesHandler,
  findingGapsHandler,
} from "./dateGapsChecker";
import {
  Close,
  WarningTriangle,
} from "../../../../../../../../../../../../DynamicView/src";
import { MondayButton } from "../../../../../../../../../../../../../commonComponents";
import { RightArrow } from "../../../../../../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../../../../../../hooks";
import "./DateGaps.scss";

// import { useStateContext } from "../../../../../../../../Context/Context"

import { useSelector } from "react-redux";
import { useState } from "react";
import { Badge, Calendar, Modal } from "antd";
import { BadgeProps, CalendarProps } from "antd";

import dayjs from "dayjs";
import moment from "moment";

const DateGaps = ({ thisElevation, thisPli, type = "collide" }) => {
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [darkMode] = useRedux("rentalsDarkMode");
  // const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals")
  // const { fakeRentals, selectedService, darkMode } = useStateContext()
  // const [isAutoSaving ] = useRedux("rentalsIsAutoSaving")
  // const [isWritable ] = useRedux("rentalsIsWritable")
  // const [color ] = useRedux("rentalsColor")

  const { isLoading, rentals } = useSelector((state) => state.rentals);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { projectId, rentalId } = fakeRentals;

  //this filters applications and return only those application that have id equal to project id that is open
  const data = !isLoading
    ? rentals.filter(
        (rental) =>
          rental.projectId === projectId && rental?.rentalId !== rentalId
      )
    : [];

  //here we find the start and end date from this pli in this elevation
  const { startDate, endDate } = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.find(({ id }) => id === thisPli)?.rentalDetails;

  //here we generate all datas from all rentals
  const existingRentals = existingRentalsHandler({
    data,
    selectedService,
    thisElevation,
    thisPli,
    service: fakeRentals?.services?.find(
      ({ label }) => label === selectedService
    ),
  });

  //here we generate gaps information
  const findingConflictingRanges = findingGapsHandler({
    existingRentals,
    startDate,
    endDate,
  });

  const conflicts = !!startDate && !!endDate ? findingConflictingRanges : [];

  const getListData = (value) => {
    let listData;
    switch (value.date()) {
      case 8:
        listData = [
          { type: "warning", content: "This is warning event." },
          { type: "success", content: "This is usual event." },
        ];
        break;
      case 10:
        listData = [
          { type: "warning", content: "This is warning event." },
          { type: "success", content: "This is usual event." },
          { type: "error", content: "This is error event." },
        ];
        break;
      case 15:
        listData = [
          { type: "warning", content: "This is warning event" },
          { type: "success", content: "This is very long usual event......" },
          { type: "error", content: "This is error event 1." },
          { type: "error", content: "This is error event 2." },
          { type: "error", content: "This is error event 3." },
          { type: "error", content: "This is error event 4." },
        ];
        break;
      default:
    }
    return listData || [];
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  return (
    <div className="dateCollisionContainer">
      {!!conflicts?.length ? (
        <MondayButton
          className="mondayButtonYellow dateCollisionModalButton"
          onClick={() => setIsModalVisible(true)}
          Icon={<WarningTriangle />}
        >
          {type !== "gap" ? "Date Collision" : "Date Gap"}
        </MondayButton>
      ) : (
        "No Gaps"
      )}

      <Modal
        title="Date Gaps"
        open={isModalVisible}
        wrapClassName={
          darkMode ? "dateConflictsModalDark" : "dateConflictsModalLight"
        }
        onCancel={() => setIsModalVisible(false)}
        closeIcon={<Close />}
        footer={
          <MondayButton
            className="mondayButtonRed"
            onClick={() => setIsModalVisible(false)}
            Icon={<Close />}
          >
            Close
          </MondayButton>
        }
      >
        {`Be very careful and review the dates, our system has found a gap day between rentals for this specific PLI!`}
        <div className="dateRangeContainerGap">
          {conflicts?.map((dayDate) => {
            return (
              <>
                {/* <div className="iconCOntainer">
                <WarningTriangle />
              </div>
              <div className="messageContainer"></div> */}
                <div className="customDateCard">
                  {" "}
                  {dayjs(dayDate)?.format("MM/DD/YYYY")}
                </div>
                {/* {`${dayjs(conflictingRange[0])?.format("MM/DD/YYYY")} to
                ${dayjs(conflictingRange[1])?.format("MM/DD/YYYY")}`} */}
                {/* <div className="redirectButton">
                <MondayButton
                  className="mondayButtonBlue"
                  onClick={() => redirectToRental({ rentalId })}
                  Icon={<RightArrow />}
                >
                  Rental {rentalNumber}
                </MondayButton>
              </div> */}{" "}
              </>
            );
          })}{" "}
          {/* <Calendar cellRender={cellRender} /> */}
        </div>
      </Modal>
    </div>
  );
};

export default DateGaps;
