import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../SidebarPages/utils";
import { DynamicAvatars } from "../../../../../../../commonComponents/DynamicAvatars/DynamicAvatars";

export function crewAnalyticsColumns() {
  return [
    {
      headerName: "Team Name",
      field: "crewName",
    },
    {
      headerName: "Services Worked In",
      valueGetter({ data }) {
        return `${data?.details?.length} services`;
      },
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "Foreman",
      field: "foreman",
      cellRenderer: ({ value }) => {
        return <div>{value?.crewName}</div>;
      },
    },
    {
      headerName: "Members",
      field: "members",
      cellRenderer: ({ value }) => {
        return (
          <DynamicAvatars
            {...{
              members: value?.map(({ crewName }) => crewName),
            }}
          />
        );
      },
    },
    {
      headerName: "Total Work Hours",
      field: "totalRegHours",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
    },
    {
      headerName: "Total OT Hours",
      field: "totalOtHours",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
    },
    {
      headerName: "Overhead Hours",
      field: "totalOvhHours",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
    },
    {
      headerName: "Total Project Cost",
      field: "total",
      cellRenderer({ value }) {
        return formatCurrency(value || 0);
      },
    },
    {
      headerName: "Total Overhead",
      field: "totalOvh",
      cellRenderer({ value }) {
        return formatCurrency(value || 0);
      },
    },
  ];
}

export function crewAnalyticsDetails() {
  return {
    detailGridOptions: {
      columnDefs: [
        {
          headerName: "Service",
          field: "service",
        },
        {
          headerName: "Total Work Hours",
          field: "totalRegHours",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Total OT Hours",
          field: "totalOtHours",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Overhead Hours",
          field: "totalOvhHours",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Total Project Cost",
          field: "total",
          cellRenderer({ value }) {
            return formatCurrency(value || 0);
          },
        },
        {
          headerName: "Total Overhead",
          field: "totalOvh",
          cellRenderer({ value }) {
            return formatCurrency(value || 0);
          },
        },
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableColResize: true,
      },
      pagination: false,
    },
    getDetailRowData(params) {
      params.successCallback(params.data.details);
    },
  };
}
