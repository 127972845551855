import AmountReq from "../../../Service/ServiceContent/GridData/GridUtils/amountFactory";

export const applicationHoistHandler = ({
  fakeApplication,
  checkboxToggle,
  elevationId,
  serviceId,
  estimationId,
}) => ({
  ...fakeApplication,
  services: fakeApplication?.services?.map((serviceData) =>
    serviceData?.serviceId === serviceId &&
    serviceData?.estimationId === estimationId
      ? {
          ...serviceData,
          serviceOptions: [
            serviceData?.serviceOptions[0]?.map((elevationData) => {
              return elevationData?.elevationId === elevationId
                ? {
                    ...elevationData,
                    amounts: elevationData?.amounts?.map((eventData) => {
                      const {
                        fromPrevious,
                        amount,
                        charged,
                        reducedBalance = 0,
                      } = eventData || {};
                      const retainagePercentage = serviceData?.retainage;

                      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
                      //else if we want to uncheck all this period will be 0
                      const thisPeriod = !!checkboxToggle
                        ? amount - fromPrevious
                        : 0;
                      //if the we want to check all this period is calculated by (the hole amount for this event)
                      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
                      const totalCompleated = !!checkboxToggle
                        ? amount
                        : fromPrevious;
                      //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
                      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
                      const percentageCompleated = !!checkboxToggle
                        ? ((thisPeriod + fromPrevious) / amount) * 100
                        : (fromPrevious / amount) * 100;
                      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
                      //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
                      const balanceToFinish = !!checkboxToggle
                        ? amount - fromPrevious - thisPeriod
                        : amount - fromPrevious;
                      //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
                      const retainage =
                        (totalCompleated * retainagePercentage) / 100;

                      // kept retainage
                      const keptRetainage =
                        (thisPeriod * retainagePercentage) / 100;
                      const paymentDue =
                        thisPeriod - keptRetainage + reducedBalance;

                      let myAmount = new AmountReq(eventData ?? {});

                      myAmount.chargeThisPeriod(thisPeriod, _, serviceData);

                      return !!charged
                        ? eventData
                        : {
                            ...myAmount.getData(),
                          };
                    }),
                  }
                : elevationData;
            }),
          ],
        }
      : serviceData
  ),
});

export const compleatedAllHoistHandler = ({
  fakeApplication,
  serviceId,
  estimationId,
  elevationId,
}) => {
  const data = fakeApplication?.services
    ?.find(
      ({ serviceId: idOfService, estimationId: idOfEstimation }) =>
        idOfService === serviceId && idOfEstimation === estimationId
    )
    ?.serviceOptions[0]?.find(
      ({ elevationId: id }) => id === elevationId
    )?.amounts;

  return {
    someChecked: data?.some(
      ({ balanceToFinish }) => Math?.floor?.(balanceToFinish) === 0
    ),
    allChecked: data?.every(
      ({ balanceToFinish }) => Math?.floor?.(balanceToFinish) === 0
    ),
    charged: data?.every(({ charged }) => !!charged),
  };
};
