import React from "react";
import { Checkbox } from "antd";

import { useRedux } from "../../../hooks";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { useCreateBillContext } from "../context/BillModalProvider";

const BillableCheckBox = (props) => {
  const [isWritable] = useRedux("billIsWritable");
  const { billData, vendor } = useInitialBillContext();
  const { setRowData } = useCreateBillContext();

  const onCheckboxChange = () => {
    setRowData((prev) =>
      prev.map((row) => {
        if (row.itemId === props.data.itemId) {
          const isBillable = !row.billable;
          const isTaxable = isBillable ? row.tax : false;
          const taxAmount = isTaxable
            ? row.amount * (vendor?.vendorTaxRate || 0)
            : 0;

          return {
            ...row,
            billable: isBillable,
            tax: isTaxable,
            taxAmount,
            total: row.amount + (isTaxable ? taxAmount : 0),
          };
        }
        return row;
      })
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Checkbox
        disabled={!isWritable && billData}
        checked={props.value}
        className="checkbox"
        onChange={onCheckboxChange}
      />
    </div>
  );
};

export default BillableCheckBox;
