import React from "react";
import { Alert, Form } from "antd";

import { formatCurrency } from "../../../utils";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";

const style = {
  fontWeight: 600,
};

const WarningMessage = ({ value }) => {
  return (
    <>
      Not applied amount <span style={style}>{formatCurrency(value)}</span>
    </>
  );
};

const WarningAmount = () => {
  const { rowData } = useCreateBillContext();
  const { form } = useInitialBillContext();

  const formAmount = Form.useWatch("billAmount", form) || 0;

  const totalAmount = rowData.reduce((sum, row) => sum + row.total, 0);

  const notAppliedAmount = (formAmount || 0) - totalAmount;

  if (formAmount === 0 && notAppliedAmount <= 0.0001) return null;

  return (
    <Alert
      message={<WarningMessage value={notAppliedAmount} />}
      description="The total amount is greater than the total items amount."
      type="warning"
      showIcon
    />
  );
};

export default WarningAmount;
