import { useState, useEffect } from "react";
import { message } from "antd";
import {
  fetchVehicleMakes,
  fetchVehicleModels,
  fetchVehicleManufacturers,
} from "src/components/SidebarPages/Fleet/fleet/modals/NewFleetModal/dataAPI";
import FormItem from "../../components/FormItems/FormItem";
import { MondayButton } from "src/components/commonComponents";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "../Information/Information.scss";

function ManufacturerCard(props) {
  const [vehicleManufacturers, setVehicleManufacturers] = useState([]);
  const [makesForSelection, setMakesForSelection] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);

  const {
    params: { fleetMake, fleetManufacturer, fleetYear, fleetModel },
    form,
    getEditedValue,
    setEditMode,
  } = props;

  useEffect(() => {
    //#region INIT EFFECT
    message.loading({
      content: "Loading manufacturer data...",
      key: "init",
    });

    fetchVehicleManufacturers()
      .then((r) => {
        message.destroy("init");
        setVehicleManufacturers(r);
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while trying to get manufacturer data",
          key: "init",
        });
        console.log("ERROR GETTING MANUFACTURERS: ", { err });
      });

    if (fleetManufacturer && fleetYear) {
      fetchVehicleMakes({
        manufacturer: fleetManufacturer,
        year: fleetYear,
      })
        .then(setMakesForSelection)
        .catch((err) => {
          console.log("ERROR GETTING MAKE: ", { err });
          message.error({
            content:
              "Could not get makes for the selected manufacturer and year",
          });
        });
    }

    if (fleetMake) {
      fetchVehicleModels(fleetMake)
        .then(setVehicleModels)
        .catch((err) => {
          message.error({
            content: "Something went wrong while trying to get vehicle models",
            key: "models",
          });
          console.log("ERROR GETTING MODELS: ", { err });
        });
    }
  }, []);

  async function getMakesForSelection() {
    //#region GET MAKES
    const { fleetManufacturer: fm, fleetYear: fy } = form.getFieldsValue();

    form.setFieldValue("fleetMake", null);
    form.setFieldValue("fleetModel", null);

    if (!fm || !fy || ("" + fy).length < 4 || ("" + fy).length > 4) {
      if (makesForSelection.length) {
        setMakesForSelection([]);
      }

      if (vehicleModels.length) {
        setVehicleModels([]);
      }
      return;
    }

    message.loading({
      content: "Loading makes...",
      key: "makes",
    });

    await fetchVehicleMakes({
      manufacturer: fm,
      year: fy,
    })
      .then((r) => {
        message.destroy("makes");
        setMakesForSelection(r);
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while trying to get vehicle makes",
          key: "makes",
        });
        console.log("ERROR GETTING MAKES: ", { err });
      });
  }

  async function getModelsForSelection() {
    //#region GET MODELS
    const { fleetMake: fm } = form.getFieldsValue();
    form.setFieldValue("fleetModel", null);

    if (!fm) {
      if (vehicleModels.length) {
        setVehicleModels([]);
      }
      return;
    }

    message.loading({
      content: "Loading models...",
      key: "models",
    });

    await fetchVehicleModels(fm)
      .then((r) => {
        message.destroy("models");
        setVehicleModels(r);
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while trying to get vehicle models",
          key: "models",
        });
        console.log("ERROR GETTING MODELS: ", { err });
      });
  }

  function onCancel() {
    //#region ON CANCEL
    setEditMode(false);
  }

  function getLogData(cardData) {
    return {
      id: "manufacturer",
      Card: "ManufacturerCard",
      title: "Vehicle Manufacturer",
      params: {
        ...cardData,
      },
    };
  }

  async function onSave() {
    //#region ON SAVE
    try {
      var {
        fleetManufacturer: man,
        fleetYear: yr,
        fleetMake: mk,
        fleetModel: mdl,
      } = await form.validateFields();
    } catch {
      return;
    }

    getEditedValue(
      {
        fleetManufacturer: man,
        fleetYear: yr,
        fleetMake: mk,
        fleetModel: mdl,
      },
      {
        fleetManufacturer,
        fleetYear,
        fleetMake,
        fleetModel,
      },
      {},
      getLogData({
        ...(props?.params || {}),
      }),
      getLogData({
        ...(props?.params || {}),
        fleetManufacturer: man,
        fleetYear: yr,
        fleetMake: mk,
        fleetModel: mdl,
      })
    );

    onCancel();
  }

  //#region JSX
  return (
    <div className="InformationBodyEdit">
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">
          Fleet Manufacturer
        </span>
        {FormItem["Dropdown"]({
          options: vehicleManufacturers.map(({ Mfr_Name }) => Mfr_Name),
          form,
          onChange: getMakesForSelection,
          value: fleetManufacturer,
          name: "fleetManufacturer",
          placeholder: "Fleet manufacturer...",
          rules: [{ required: true, message: "*This field is required" }],
        })}
      </div>
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">Fleet Year</span>
        {FormItem["Number"]({
          form,
          onChange: getMakesForSelection,
          value: fleetYear,
          name: "fleetYear",
          placeholder: "Fleet year...",
          rules: [{ required: true, message: "*This field is required" }],
        })}
      </div>
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">Fleet Make</span>
        {FormItem["Dropdown"]({
          form,
          options: makesForSelection?.map?.(({ MakeName }) => MakeName),
          value: fleetMake,
          name: "fleetMake",
          onChange: getModelsForSelection,
          placeholder: "Fleet make...",
          rules: [{ required: true, message: "*This field is required" }],
        })}
      </div>
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">
          Fleet Model
        </span>
        {FormItem["Dropdown"]({
          form,
          options: vehicleModels?.map(({ Model_Name }) => Model_Name),
          value: fleetModel,
          name: "fleetModel",
          placeholder: "Fleet model...",
          rules: [{ required: true, message: "*This field is required" }],
        })}
      </div>
      <div className="confirmContainer">
        <MondayButton
          className="cancelButton mondayButtonRed"
          onClick={onCancel}
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          className="confirmButton"
          Icon={<TickIcon />}
          onClick={onSave}
        >
          Confirm
        </MondayButton>
      </div>
    </div>
  );
}

export default ManufacturerCard;
