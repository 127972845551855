import uniq from "lodash/uniq"
//provides the filtered data for the specified category (add-ons) and subcategory (amount)
export const BarFullFilterFunctions = ({ updatedDynamicStates, dynamicStates, id }) => {
  return {
    "Add-ons": () => ({
      amount: () =>
        uniq(
          dynamicStates[id]
            ?.map((a) => a?.services)
            ?.flat(1)
            ?.map((b) => b?.label)
        )
          ?.map((status) =>
            updatedDynamicStates[id]
              ?.map((c) => c?.services)
              ?.flat(1)
              ?.filter((d) => d?.label === status && status)
          )
          ?.map((e) => e?.map((f) => f?.serviceOptions))
          ?.map((g) =>
            g
              ?.flat(2)
              ?.map((h) => h?.items)
              ?.flat(1)
              ?.map((i) => parseInt(i?.price) || 0)
              ?.reduce((c, p) => c + p, 0)
          ),
    }),
  }
} // BarCharti
