import { Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import Footer from "./Footer";
import { Stepper } from "../../../Stepper";
import { TableStep, UploadStep } from "../Steps";
import { XIcon } from "../../../../SidebarPages/Communication/assets";

import "./UploadModal.scss";

const Steps = { 0: UploadStep, 1: TableStep };

const UploadModal = ({ isOpen, setIsOpen, title, rowData }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const modalSteps = [
    { title: "Upload", disabled: true },
    { title: title, disabled: true },
  ];

  const Component = Steps[`${currentStep}`];

  const onCancel = () => setIsOpen(false);

  return (
    <Modal
      title={`Upload ${title}`}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      closable={true}
      centered={true}
      closeIcon={<XIcon />}
      className={
        isDarkMode ? "upload-records-modal-dark" : "upload-records-modal"
      }
      destroyOnClose={true}
      footer={<Footer {...{ currentStep, setCurrentStep, onCancel }} />}
    >
      <Stepper
        {...{
          stepRenderer: false,
          currentStep,
          setCurrentStep,
          steps: modalSteps,
        }}
      />
      <Component {...{ title, rowData }} />
    </Modal>
  );
};

export default UploadModal;
