import { forwardRef, useState } from "react";
import { Input } from "antd";

import useCellEditor from "../../../hooks/useCellEditor";
import "./period-cell-editor.css";

const PeriodCellEditor = forwardRef(({ value, charPress, data }, ref) => {
  const [amount, setAmount] = useState(charPress || value || 0);
  const inputRef = useCellEditor(ref, amount, !charPress && "input");

  return (
    <Input
      onWheel={(e) => e.target.blur()}
      ref={inputRef}
      className="period-cell-editor"
      placeholder="Enter amount"
      value={amount}
      type={"number"}
      onChange={(e) => {
        return setAmount(e.target.value);
      }}
      onBlur={() => {
        setAmount(parseFloat(amount));
      }}
    />
  );
});

export default PeriodCellEditor;
