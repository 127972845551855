import { forwardRef, useState } from "react"
import { Input } from "antd"

import useCellEditor from "../../../hooks/useCellEditor"

const MaterialStored = forwardRef(({ value, charPress }, ref) => {
  const [amount, setAmount] = useState(charPress || value || 0)
  const inputRef = useCellEditor(ref, amount, !charPress && "input")

  return (
    <Input
      ref={inputRef}
      className="period-cell-editor"
      placeholder="Enter amount"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
    />
  )
})

export default MaterialStored
