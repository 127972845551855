import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./AddEventModal.scss";
// import moment from "moment";
// import { Button } from "antd";
function RichTextBox({ setCreatedRichText, createdRichText }) {
  //?Commented section are for the shourcuts of adding date and time on rich text
  // var quillRef = null;
  // var reactQuillRef = null;

  // const attachQuillRefs = () => {
  //   if (typeof reactQuillRef.getEditor !== 'function') return;
  //   if (quillRef != null) return;

  //   const quillRefEditor = reactQuillRef.getEditor();
  //   if (quillRefEditor != null) quillRef = quillRefEditor;
  // }

  // const insertDate = (date) => {
  //   var range = quillRef.getSelection();
  //   let position = range ? range.index : 0;
  //   quillRef.insertText(position, date)
  // }

  // useEffect(() => {
  //   attachQuillRefs()
  // })

  return (
    <div
      className="react-quill-container"
      style={{ marginLeft: "100px", borderRadius: "15px" }}
    >
      {/* <Button className='date-btn' shape={'round'} onClick={() => insertDate(moment().format('MMMM/DD/YYYY'))}>Date</Button>
      <Button className='hour-btn' shape={'round'} onClick={() => insertDate(moment().format('H:MM'))}>Hour</Button> */}
      <ReactQuill
        theme="snow"
        value={createdRichText}
        onChange={setCreatedRichText}
        // ref={(el) => { reactQuillRef = el }}
      />
    </div>
  );
}

export default RichTextBox;
