import RentalFooter from "./Components/RentalFooter/RentalFooter";
import RentalHeader from "./Components/RentalHeader/RentalHeader";
import RentalBody from "./Components/RentalBody/RentalBody";
import { useStateContext } from "../../../Context/Context";
import "./Rentals.scss";

const Rentals = ({ rental, type, accumulatedRentals = false }) => {
  const { darkMode } = useStateContext();
  const { rentalId, rentalNumber, rentalStatus, services } = rental || {};
  console.log("rental", rental);
  return (
    <div
      className={
        darkMode ? "includeRentalContainerDark" : "includeRentalContainerLight"
      }
      data-testid="includeRentalContainer"
    >
      <RentalHeader
        {...{
          rentalId,
          rentalNumber,
          rentalStatus,
          taxRate: rental?.taxRate || 0,
          rental,
          type,
        }}
      />
      <RentalBody
        {...{
          darkMode,
          services: services?.map((el) => {
            if (type === "prev") {
              let serviceRet = 0;
              let thisDistRetainage = false;
              let ACC_RENTALS = accumulatedRentals || {};
              if (Object.keys(ACC_RENTALS).length !== 0) {
                for (const applicationNo in ACC_RENTALS) {
                  if (Object.keys(ACC_RENTALS[applicationNo]).length !== 0) {
                    for (const rentalNo in ACC_RENTALS[applicationNo]) {
                      if (
                        Object.keys(ACC_RENTALS[applicationNo][rentalNo])
                          .length !== 0
                      ) {
                        if (rentalNo.toString() === rentalNumber.toString()) {
                          let SERVICES = ACC_RENTALS[applicationNo][rentalNo];
                          if (Object.keys(SERVICES).length !== 0) {
                            for (const serviceLabel in SERVICES) {
                              console.log("serviceLabel", el);
                              if (serviceLabel === el?.label)
                                serviceRet = SERVICES[serviceLabel]?.retainage;
                              thisDistRetainage =
                                SERVICES[serviceLabel]?.thisDistRetainage;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              console.log("serviceRet", serviceRet, el);
              if (thisDistRetainage !== false) {
                el.thisDistRetainage = thisDistRetainage;
              }
              return {
                ...el,
                retainage: serviceRet,
              };
            } else {
              return el;
            }
          }),
          isPrevious: type === "prev",
          taxRate: rental?.taxRate,
        }}
      />
      <RentalFooter />
    </div>
  );
};

export default Rentals;
