import { forwardRef, useState } from 'react'
import { Input } from 'antd'

import useCellEditor from '../../../../../hooks/useCellEditor'
import './rate-cell-editor.css'

const RateCellEditor = forwardRef(({ value, charPress }, ref) => {
  const [percentage, setPercentage] = useState(charPress || value || 0)
  
  const inputRef = useCellEditor(ref, percentage, !charPress && 'input')

  return (
    <Input
      ref={inputRef}
      className='rate-cell-editor'
      placeholder='Enter rate percentage'
      value={percentage}
      onChange={e => setPercentage(e.target.value)}
    />
  )
})

export default RateCellEditor