export const safety = (IdHandler, programFields, updatedDynamicStates) => {
  const safetyStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    safetyApplicationStatus: () => ({
      title: "Safety Application Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Safety",
        field: "safetyApplicationStatus",
        labels: safetyStatus?.map(({ statusName }) => statusName),
        datasets: [
          {
            label: "My First Dataset",
            data: safetyStatus?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) => a?.safetyApplicationStatus === status?.statusName
                )?.length
            ),
            backgroundColor: safetyStatus?.map(
              ({ statusColor }) => statusColor
            ),
            borderWidth: 1,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
