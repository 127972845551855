import "./SmallLoadableComp.scss";
import "../../../commonComponents/LoadingDiamonds/LoadingDiamonds.scss";
import { Tooltip } from "antd";

export const SmallLoadableComp = ({
  loading,
  children,
  darkMode = localStorage.getItem("darkmode") === "true",
  loadingTitle = "",
}) =>
  loading ? (
    <Tooltip title={loadingTitle}>
      <div
        className={`smallLoadingDiamonds ${
          darkMode && "smallLoadingDiamondsDark"
        }`}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Tooltip>
  ) : (
    children
  );
