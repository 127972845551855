import types from "../types";

export const userGroups = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_USER_GROUPS,
    payload: attributes,
  });
};

export const userGroupsConfig = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_USER_GROUPS_BY_ID,
    payload: attributes,
  });
};
