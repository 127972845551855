import _ from "lodash";
import { Link } from "react-router-dom";
import { Popover, Tooltip } from "antd";
import { Notes } from "../../../../../commonComponents/Notes/Notes";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { queryParams } from "../../../../../../utils/queryParams";
import { MondayButton } from "../../../../../commonComponents";
import { DeleteFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { sortDates } from "../../../../ToDos/AgGridData/columnDefs";
import { currencyFormater } from "../../../../../../utils/currencyFormater";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

export const calculateTotalCreditedAmount = (data) => {
  const creditedAmount =
    data?.invoiceItems?.reduce?.(
      (total, { credits }) =>
        total +
        (credits?.reduce(
          (total, { creditedAmount, creditedTaxAmount }) =>
            total + (creditedAmount || 0) + (creditedTaxAmount || 0),
          0
        ) || 0),
      0
    ) || 0;

  return creditedAmount;
};

export const excelColumnsConfig = [
  "id",
  "invoiceNumber",
  "quickBooksNumber",
  "date",
  "customer",
  "amount",
  "invoiceItems",
  "credits",
  "invoiceStatus",
  "project",
  "createdAt",
  "createdBy",
];

export const columnDefs = ({ setConfirmModalVisible = () => {} }) => [
  {
    field: "headerCheckboxSelection",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    enableRowGroup: false,
    headerName: "",
    pdfExportOptions: {
      skipColumn: true,
    },
  },
  {
    field: "id",
    headerName: "ID",
    chartDataType: "category",
  },
  {
    field: "invoiceNumber",
    headerName: "No.",
    cellRendererFramework: (params) => {
      return <div>{params?.data?.invoiceNumber}</div>;
    },
    chartDataType: "category",
  },
  {
    field: "quickBooksNumber",
    headerName: "QuickBooks No.",

    cellRendererFramework: (params) => {
      return <div>{params?.data?.quickBooksNumber}</div>;
    },
    chartDataType: "category",
  },
  {
    field: "date",
    headerName: "DATE",
    chartDataType: "excluded",
    filter: "agTextColumnFilter",
    sortable: true,
    sort: "desc",
    valueGetter: ({ data }) => {
      try {
        return dayjsNY(data?.date)?.format("MM/DD/YYYY");
      } catch (err) {
        return data?.date;
      }
    },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
  },
  {
    field: "invoiceItems",
    headerName: "Categories From",
    enableRowGroup: false,
    cellRendererFramework: ({ value: invoiceItems }) => {
      const values = _.uniqBy(
        invoiceItems?.map(({ group, categoryId, category }) => ({
          group,
          categoryId,
          category,
        })),
        "group"
      );

      const extraService = values?.[0]?.group === "Extra Service";

      return (
        <Popover
          placement="bottom"
          content={values?.map(({ group, categoryId, category }, i) => {
            const isExtraService = group === "Extra Service";
            return (
              <>
                <b>
                  {!isExtraService ? (
                    <Link to={`/${category}/${categoryId}`} target="_blank">
                      {group}
                    </Link>
                  ) : (
                    group
                  )}
                </b>
                <br />
              </>
            );
          })}
        >
          {!extraService ? (
            <a
              href={`/${values[0]?.category}/${values[0]?.categoryId}`}
              target={"_blank"}
            >
              {values[0]?.group}
            </a>
          ) : (
            <b style={{ color: "gray" }}>{values[0]?.group}</b>
          )}
        </Popover>
      );
    },
    chartDataType: "excluded",
  },
  {
    field: "customer",
    headerName: "CLIENT",
    chartDataType: "category",
  },
  {
    field: "amount",
    headerName: "AMOUNT",
    cellRendererFramework: (params) => {
      return <div>{formatCurrency(params?.data?.amount)}</div>;
    },
    valueGetter: (params) => {
      return Number(params?.data?.amount);
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return currencyFormater(value);
      },
    },
    chartDataType: "series",
    getQuickFilterText: () => "",
  },
  {
    field: "invoiceItems",
    headerName: "CREDIT",
    cellRendererFramework: ({ data }) => (
      <div>{formatCurrency(calculateTotalCreditedAmount(data))}</div>
    ),
    valueGetter: ({ data }) => {
      return Number(calculateTotalCreditedAmount(data), 0);
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return currencyFormater(value);
      },
    },
    chartDataType: "series",
  },
  {
    field: "credits",
    headerName: "OPEN BALANCE",
    cellRendererFramework: ({ data }) => {
      let res = data?.amountDue;

      return (
        <div>
          {parseFloat(res?.toFixed(2)) !== 0 ? (
            <MondayButton className="mondayButtonRed" hasIcon={false}>
              {formatCurrency(res)}
            </MondayButton>
          ) : (
            <MondayButton
              className="mondayButtonGreen"
              // disabled={true}
              hasIcon={false}
            >
              FULLY PAID
            </MondayButton>
          )}
        </div>
      );
    },
    valueGetter: ({ data }) => Number(data?.amountDue),
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return currencyFormater(value);
      },
    },
    chartDataType: "series",
  },
  {
    field: "invoiceStatus",
    headerName: "STATUS",
    chartDataType: "category",
  },
  {
    field: "project",
    headerName: "PROJECT",
    cellRendererFramework: (params) => {
      return (
        <Link
          to={{
            pathname: `/projects/${params?.data?.projectId}`,
            state: {
              tabPosition: "Accounting",
              fromAccounting: true,
              accountingTab: "Invoice",
            },
            search: queryParams({
              tab: "Accounting",
              subTab: "Invoice",
            }),
          }}
        >
          <div style={{ color: "#1264A3" }}>{params?.data?.project}</div>
        </Link>
      );
    },
    chartDataType: "excluded",
  },
  {
    headerName: "Created At",
    field: "createdAt",
    minWidth: 165,
    resizable: true,
    unSortIcon: true,
    enableRowGroup: true,
    filter: "agTextColumnFilter",
    sortable: true,
    sort: "desc",
    hide: false,
    minWidth: 165,
    valueGetter: ({ data }) => {
      return dayjs(data?.createdAt).format("MM/DD/YYYY");
    },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
  },
  {
    headerName: "Created By",
    field: "createdBy",
    minWidth: 165,
    resizable: true,
    unSortIcon: true,
    enableRowGroup: true,
    filter: "agTextColumnFilter",
    // hide: true,
    valueGetter: ({ data }) => {
      return data?.createdBy?.name;
    },
  },
  {
    headerName: "Notes",
    field: "notes",
    filter: "agMultiColumnFilter",
    enableRowGroup: false,
    suppressMenu: true,

    cellRendererFramework: (params) => {
      return Notes({
        noteModalName: `${params?.data?.projectName} | Invoice ${params?.data?.invoiceNumber}`,
        rowId: params?.data?.invoiceId,
        topicCategory: "Invoices",
      });
    },
    valueGetter: ({ data }) =>
      data?.notes?.map(({ comment }) =>
        comment?.replace(/<\/?[^>]+(>|$)/g, "")
      ),
  },
  {
    field: "actions",
    headerName: "Actions",
    enableRowGroup: false,
    pdfExportOptions: {
      skipColumn: true,
    },
    cellRendererFramework: ({ data }) => (
      // <Tooltip
      //   title={
      //     data?.charges?.length > 0 ? (
      //       <div>
      //         This invoice is credited in:{" "}
      //         {data?.charges?.map(({ chargeId, chargeNumber }) => (
      //           <a
      //             style={{ color: "white" }}
      //             href={`/charges/${chargeId}`}
      //             target="_blank"
      //           >
      //             {chargeNumber}
      //           </a>
      //         ))}
      //         !
      //       </div>
      //     ) : data?.amountDue < data?.totalInvoiceAmount ? (
      //       <div>Payment received for this invoice. </div>
      //     ) : undefined
      //   }
      // >
      <DeleteFilled
        onClick={() =>
          // data?.amountDue === data?.totalInvoiceAmount &&
          setConfirmModalVisible(data)
        }
        style={{ fill: "#d10003", cursor: "pointer" }}
      />
      // </Tooltip>
    ),
  },
];
