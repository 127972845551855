import { Tooltip, Avatar } from "antd";
import { useSelector } from "react-redux";
import { InfoWindowF } from "@react-google-maps/api";

import { InfoIcon } from "src/assets";
import { getInitials } from "src/utils";
import { ScheduleType } from "../../payrollLiveTypes";
import { getRandomColor } from "src/components/SidebarPages/utils";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { ProgressStatistics } from "src/components/SidebarPages/Scheduling/helpers/totals";
import CustomProgressBar from "src/components/commonComponents/CustomProgress/CustomProgressBar";
import { DisplayColors } from "src/components/Header/forms/Scheduling/Subcomponents/DayStatusChangeModal/DayStatuses";

import "./ScheduleInfoWindow.scss";

interface Props {
  data: ScheduleType;
  statusColor: string;
  onClick: () => void;
}

function ScheduleInfoWindow(props: Props) {
  const { data, onClick, statusColor } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const renderProfilePhotos = (projectManagers) => {
    return (
      <Avatar.Group
        className="profile-photos-container"
        max={{
          count: 6,
          style: {
            color: "#fff",
            backgroundColor: getRandomColor(),
            width: "25px",
            height: "25px",
            fontSize: "14px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid white",
          },
        }}
      >
        {projectManagers?.map((manager) => {
          return (
            <Tooltip title={manager}>
              <Avatar
                shape="circle"
                style={{
                  backgroundColor: getRandomColor(),
                  width: "25px",
                  height: "25px",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid white",
                }}
              >
                {getInitials(manager)}
              </Avatar>
            </Tooltip>
          );
        })}
      </Avatar.Group>
    );
  };

  return (
    <InfoWindowF
      zIndex={3}
      key={data.scheduleId}
      position={data.addressPosition}
      options={{ pixelOffset: new google.maps.Size(0, -40) }}
    >
      <section
        className={`schedule-info-window ${
          darkMode ? "schedule-info-window-dark" : ""
        }`}
      >
        <div className="info-header">
          <span className="info-title">Schedule</span>
          <XIcon onClick={onClick} width={12} height={12} />
        </div>
        <div className="info-body">
          <div className="location-information">
            <Tooltip title="Schedule Address">
              <InfoIcon width={14} height={14} />
            </Tooltip>
            <span>{data?.scheduleAddress}</span>
          </div>
          <div className="info-data">
            <label>{data.scheduleName}</label>
            <div className="schedule-status">
              <div
                className="status-pin"
                style={{ backgroundColor: statusColor }}
              ></div>
              <span>{data.scheduleStatus}</span>
            </div>
          </div>
          <div className="info-data">
            <span>Type of Work:</span>
            <span>{data.typeOfWork}</span>
          </div>
          <div className="info-data">
            <span>Project Executive:</span>
            <span>{data.projectExecutive}</span>
          </div>
          <div className="info-data">
            <span>Project Managers:</span>
            <div>{renderProfilePhotos(data.projectManagers)}</div>
          </div>
          <div className="info-data">
            <span>Schedule Progress:</span>
            <div>
              <CustomProgressBar
                progressDone={
                  ProgressStatistics.estimationTotal(
                    Object.values(data?.toBeScheduled || {})?.flat(1)
                  )?.progressPercentage || 0
                }
              />
            </div>
          </div>
          <div className="schedule-days">
            <span>
              <span style={{ fontWeight: 600 }}>
                ({data?.scheduleDays?.length || 0}){" "}
              </span>
              Schedule Days:
            </span>
            <div
              className={`days-container ${
                data?.scheduleDays?.length > 5 ? "scroll" : ""
              }`}
            >
              {(data?.scheduleDays || []).map((day, i) => {
                return (
                  <div className="day-row" key={day.id}>
                    <span>Day {i + 1}</span>
                    <div className="day-separator"></div>
                    <span>
                      Date: {dayjsNY(day.startDate).format("MM/DD/YYYY")}
                    </span>
                    <div className="day-separator"></div>
                    <div className="day-status">
                      <div
                        className="status-pin"
                        style={{
                          backgroundColor: DisplayColors[day?.status],
                        }}
                      ></div>
                      <span>{day.status}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </InfoWindowF>
  );
}

export default ScheduleInfoWindow;
