const gridFilters = [
  {
    globalKeyId: "07001fbc-78d9-4b0f-8be1-edb9cb090592",
    label: "Vehicle",
    type: "Dropdown",
    key: "fleetName",
  },
  {
    globalKeyId: "2acd7c68-4fc0-4ba6-a603-e84449c02bab",
    label: "Date",
    type: "DateRange",
    key: "date",
  },
  {
    globalKeyId: "560463ff-7bf0-4afc-82f7-a0c26a3e3c9d",
    label: "Coming From",
    type: "Dropdown",
    key: "fromLocation",
  },
  {
    globalKeyId: "c0046f5a-9e04-45fb-bd90-00fa1be75a30",
    label: "Going To",
    type: "Dropdown",
    key: "toLocation",
  },
];

export default gridFilters;
