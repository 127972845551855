import { Button, Input, Modal, Table } from "antd";
import { useMemo, useRef, useState } from "react";
import "./ReportsPerCategoryModal.scss";
import { MondayButton } from "../../../../commonComponents";
import columnDefs from "./columnDefs";
import { ReportsPerCategoryChart } from "./ReportsPerCategoryChart";
import { useResponsive } from "../../../../../hooks";
import { SearchOutlined } from "@ant-design/icons";
import regex from "../helpers/regex";

export const ReportsPerCategoryModal = ({ reports, handleClickedCategory }) => {
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(undefined);

  const searchInput = useRef(null);

  const { mobile, tablet, desktop } = useResponsive();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onChangeHandler = (e) => {
    const { value } = e.target;

    setSearchText(value);
  };

  const arrayWithGroupedRecords = Object.keys(reports).reduce(
    (acc, element) => {
      const newElement = {
        category: element,
        count: reports[element].length,
      };

      acc.push(newElement);

      return acc;
    },
    []
  );

  const filteredArrayWithGroupedRecords = useMemo(() => {
    let filteredArray = arrayWithGroupedRecords;
    if (searchText.length !== 0)
      filteredArray = arrayWithGroupedRecords.filter(({ category, count }) => {
        return (
          regex(category).includes(regex(searchText)) ||
          regex(String(count)).includes(regex(searchText))
        );
      });

    return filteredArray;
  }, [searchText]);

  const handleFilterByCategory = ({ category }) => {
    handleClickedCategory?.({ category });
    handleCloseModal();
  };

  return (
    <>
      <div>
        <Button
          className="mondayButtonBlue"
          onClick={handleOpenModal}
          data-testid="statistics-button"
        >
          Statistics
        </Button>
      </div>
      {openModal && (
        <Modal
          open={openModal}
          closable
          onCancel={handleCloseModal}
          title={"Reports Per Category"}
          data-testid="reports-per-category-modal"
          className={`report-modal ${
            (mobile || tablet) && "report-modal-mobile"
          }`}
          rootClassName={`root-report-modal ${
            (mobile || tablet) && "root-report-modal-mobile"
          }`}
          width={"100%"}
          wrapClassName="wraproot-report-modal-mobile"
          footer={
            <MondayButton
              className="mondayButtonRed"
              hasIcon={false}
              onClick={handleCloseModal}
              data-testid="cancel-button"
            >
              Cancel
            </MondayButton>
          }
        >
          <div
            className={`reports-category-modal-wrapper ${
              (mobile || tablet) && "reports-category-modal-wrapper-mobile"
            }`}
          >
            <div
              className={`reports-category-modal-innerwrapper ${
                (mobile || tablet) &&
                "reports-category-modal-innerwrapper-mobile"
              }`}
            >
              <Table
                columns={columnDefs}
                dataSource={filteredArrayWithGroupedRecords}
                scroll={{ y: 300 }}
                data-testid="report-table"
                className="report-table"
                pagination={{
                  pageSize: 7,
                  disabled: false,
                }}
                rowClassName={(record, index) =>
                  `reports-category-modal-table ${
                    record.category === selectedCategory && "category-selected"
                  }`
                }
                onRow={(record) => {
                  return {
                    onClick: () =>
                      handleFilterByCategory({ category: record?.category }),
                  };
                }}
                footer={() => {
                  if (mobile || tablet)
                    return (
                      <div onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                          ref={searchInput}
                          placeholder="Search..."
                          data-testid="search-input"
                          className="search-comp report-search"
                          autoFocus={true}
                          prefix={<SearchOutlined className="SearchLogoIcon" />}
                          allowClear
                          onChange={onChangeHandler}
                        />
                      </div>
                    );

                  return null;
                }}
              />
              {desktop && (
                <div
                  onKeyDown={(e) => e.stopPropagation()}
                  className="searchbar"
                >
                  <Input
                    ref={searchInput}
                    data-testid="search-input"
                    className="search-comp report-search"
                    placeholder="Search..."
                    autoFocus={true}
                    prefix={<SearchOutlined className="SearchLogoIcon" />}
                    allowClear
                    onChange={onChangeHandler}
                    width={"500px"}
                  />
                </div>
              )}
            </div>
            <div style={{ width: "100%" }}>
              <ReportsPerCategoryChart
                data={arrayWithGroupedRecords}
                onClick={handleFilterByCategory}
                data-testid="reports-ag-charts"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
