import React, { memo, useState } from "react";
import { Tooltip } from "antd";
import { GraphTooltipContent } from "./GraphTooltipContent";

// tooltip.js
const GraphTooltip = ({ custom, data, position, visibility, table }) => {
  const [onComponentHover, setOnComponentHover] = useState(false);
  const index = data?.dataPoints?.[0]?.dataIndex;
  const tooltipData = !!data ? custom?.[index] : custom;
  const label = data?.dataPoints?.[0]?.label;

  const totalCheck = (label) =>
    label?.includes("Total") ? label : `Total ${label}`;
  return (
    (visibility || onComponentHover) && (
      <Tooltip
        overlayClassName="toolTipAccGraph"
        open={true}
        placement="top"
        title={
          <div
            onMouseEnter={() => setOnComponentHover(true)}
            onMouseLeave={() => setOnComponentHover(false)}
            style={{
              bottom: 250 - position?.top,
              left: position?.left,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              position: "absolute",
              paddingTop: "0.5rem",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              gap: "0.5rem",
              transitionProperty: "all",
              transitionDuration: "300ms",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "white",
              borderRadius: 10,
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
              width: 400,
              maxHeight: 400,
              overflowY: "auto",
            }}
          >
            <GraphTooltipContent
              {...{
                title:
                  data?.chart?._metasets.length > 1
                    ? totalCheck(
                        ` ${data?.dataPoints[0]?.dataset?.label}: $${data?.dataPoints[0].formattedValue}`
                      )
                    : `${data?.dataPoints[0].label}: ${data?.dataPoints[0].formattedValue}`,
                tooltipData,
                label: table,
              }}
            />
          </div>
        }
      ></Tooltip>
    )
  );
};

export default GraphTooltip;
