import React, { Fragment } from "react";
import { WarningIcon } from "../../../../icons";
import { MondayButton, WarningModal } from "../../../commonComponents";
import { XIcon } from "../../Communication/assets";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";

const UPLOAD_MESSAGE = {
  "Property Damage":
    "If you choose to continue, you will not be able to change the property address anymore",
  "Personal Injury":
    "If you choose to continue, you will not be able to change the Name Of Injured Individual anymore",
};

function IncidentWarningSection({
  visible = false,
  defaultId = null,
  onSave = () => {},
  onClose = () => {},
  incidentCategory = "",
  setVisible = () => {},
  onDeleteRow = () => {},
  isDarkMode: darkMode = false,
  onContinueToUpload = () => {},
}) {
  const warningMessage = () => {
    if (visible === "Upload") {
      return (
        <Fragment>
          <strong style={{ textAlign: "center" }}>
            {UPLOAD_MESSAGE[incidentCategory]}
          </strong>
          <p>Are you sure you want to continue?</p>
        </Fragment>
      );
    } else if (visible === "Delete") {
      return <strong>Are you sure you want to delete?</strong>;
    } else if (defaultId && visible !== "saveDraft") {
      return <strong>Are you sure you want to cancel?</strong>;
    } else {
      if (visible !== "saveDraft") {
        return (
          <div style={{ textAlign: "center" }}>
            <strong>You are about to exit the form with unsaved data</strong>
            <p>Would you like to save the current changes as a draft?</p>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: "center" }}>
            <strong>You have made some changes</strong>
            <p>Would you like to save the current changes as a draft?</p>
          </div>
        );
      }
    }
  };

  const warningFooter = () => {
    return (
      <div className="buttons">
        <MondayButton
          onClick={() => {
            if (defaultId || visible === "Upload") {
              setVisible(false);
            } else {
              onClose({ deleteAction: true });
            }
          }}
          Icon={<XIcon />}
          className="mondayButtonRed"
        >
          No
        </MondayButton>
        <MondayButton
          onClick={() => {
            if (visible === "Upload") {
              onContinueToUpload();
            } else if (visible === "Delete") {
              onDeleteRow();
            } else {
              if (defaultId && visible !== "saveDraft") {
                onClose({
                  cancel: true,
                });
              } else {
                onSave(true);
              }
            }
          }}
          Icon={<TickIcon />}
        >
          Yes
        </MondayButton>
      </div>
    );
  };

  return (
    <WarningModal
      {...{
        visible,
        setVisible,
        title: "Warning Message",
        closable: true,
        className: "logout-warning-modal",
        darkMode,
      }}
    >
      <div className="logout-modal-body">
        <span>
          <WarningIcon />
        </span>
        {warningMessage()}
        {warningFooter()}
      </div>
    </WarningModal>
  );
}

export default IncidentWarningSection;
