import { Form, Input, InputNumber } from "antd";
import { InputComponent } from "../../../../../../Fleet/components";
import { useHoistPriceCalculatorContext } from "../../context";
import { currencyInputProps, generatePricingRowFormNames } from "../../data";
import { minBy } from "lodash";
import "./PricingRow.scss";
import { useEffect } from "react";
import { compareIncluding } from "../../../../../../utils";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const PricingRow = ({ config }) => {
  const { formItemName, type } = config;

  // Extracting createdBy and createdAt because <InputComponent/> does not need them
  const { createdBy, createdAt, ...inputCompProps } = config;

  const { form, elevation } = useHoistPriceCalculatorContext();
  const { setFieldsValue, getFieldValue } = form;
  const { pricing, items } = elevation;

  // Converts addons units into an object { id: units}
  const addonsUnitsObject = items
    .flatMap(({ addons = [] }) =>
      addons.map(({ name, units }) => ({ id: name?.id, units }))
    )
    .reduce(
      (acc, { id, units }) => ((acc[id] = (acc[id] || 0) + +units), acc),
      {}
    );

  // generates Form.Item names
  const [
    unitsFormName,
    ppuFormName,
    priceFormName,
    rentalFormName,
    descriptionFormName,
  ] = generatePricingRowFormNames(formItemName);

  console.log("addonsUnitsObject", {
    unitsFormName,
    ppuFormName,
    priceFormName,
    rentalFormName,
    descriptionFormName,
  });

  // handles units change
  const onUnitsChange = (units) => {
    const ppu = getFieldValue(ppuFormName);
    const configValue = +getFieldValue(formItemName);

    setFieldsValue({
      [priceFormName]:
        type !== "input" ? ppu * units : ppu * units * configValue,
    });
  };

  // handles ppu change
  const onPpuChange = (ppu) => {
    const units = getFieldValue(unitsFormName);
    const configValue = +getFieldValue(formItemName);

    setFieldsValue({
      [priceFormName]:
        type !== "input" ? ppu * units : ppu * units * configValue,
    });
  };

  // <InputComponent/> Props
  const propsByConfigType = {
    select: {
      onSelect: (value) => {
        const units = getFieldValue(unitsFormName);

        setFieldsValue({
          [ppuFormName]: value,
          [priceFormName]: units * value,
        });
      },
    },

    input: {
      typeNumber: true,
      onChange: (e) => {
        const value = +e.target.value;

        const price =
          minBy(
            config?.config?.filter?.(({ length }) => length >= value),
            "length"
          )?.price || config?.config?.at?.(-1)?.price;

        const units = getFieldValue(unitsFormName);

        setFieldsValue({
          [ppuFormName]: forceToNumber(price),
          [priceFormName]: forceToNumber(units * price * value),
        });
      },
    },

    checkbox: {
      onChange: (e) => {
        const value = e.target.checked;

        const price = config.config[+value];
        const units = getFieldValue(unitsFormName);

        setFieldsValue({
          [ppuFormName]: forceToNumber(price),
          [priceFormName]: forceToNumber(units * price),
        });
      },
    },
  };

  // Sets the unchecked value of checkbox when first renders
  useEffect(() => {
    if (type !== "checkbox") return;
    const uncheckedPrice = config.config[0];

    setFieldsValue({
      [ppuFormName]: uncheckedPrice,
      [priceFormName]: uncheckedPrice,
    });
  }, [type]);

  console.log("elevation", elevation);

  // Gets data from the elevation object
  useEffect(() => {
    if (
      ![
        ...Object.keys(elevation),
        ...Object.keys(addonsUnitsObject || {}),
      ].includes(formItemName) ||
      Object.keys(pricing?.values || {}).includes(formItemName)
    )
      return; // if formItemName is not a key of elevation, do nothing

    const elevationValue =
      elevation[formItemName] || addonsUnitsObject[formItemName];

    const value =
      type === "select"
        ? config?.config?.find?.(({ label }) =>
            typeof elevationValue === "string"
              ? compareIncluding(label, elevationValue)
              : label == elevationValue
          )?.value
        : elevationValue;

    const ppuByType = {
      checkbox: config.config[+value],
      select: +value,
      input:
        minBy(
          config?.config?.filter?.(({ length }) => length >= +value),
          "length"
        )?.price || config?.config?.at?.(-1)?.price,
    };

    const ppu = forceToNumber(ppuByType[type]);
    const units = getFieldValue(unitsFormName);

    setFieldsValue({
      [formItemName]: value,
      [ppuFormName]: ppu,
      [priceFormName]:
        type !== "input"
          ? forceToNumber(ppu * units)
          : forceToNumber(ppu * units * +value),
    });
  }, [formItemName]);

  console.log("config", {
    inputCompProps,
    test: propsByConfigType[type],
  });

  return (
    <div className="hoist-pricing-row">
      <InputComponent {...inputCompProps} {...propsByConfigType[type]} />

      <div className="input-divider" />

      <Form.Item label="Units" name={unitsFormName} initialValue={1}>
        <InputNumber onChange={onUnitsChange} min={0} />
      </Form.Item>

      <div className="input-divider" />

      <Form.Item label="PPU" initialValue={0} name={ppuFormName}>
        <InputNumber
          onChange={onPpuChange}
          {...currencyInputProps}
          min={-322222222222222222222}
        />
      </Form.Item>

      <div className="input-divider" />

      <Form.Item label="Price" initialValue={0} name={priceFormName}>
        <InputNumber disabled {...currencyInputProps} />
      </Form.Item>

      <div className="input-divider" />

      <Form.Item label="Rental" initialValue={0} name={rentalFormName}>
        <InputNumber {...currencyInputProps} />
      </Form.Item>

      <div className="input-divider" />

      <Form.Item label="Description" name={descriptionFormName}>
        <Input placeholder="Description" />
      </Form.Item>
    </div>
  );
};

export default PricingRow;
