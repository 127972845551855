import { getMonthsTimestamps } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
import { months } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const fleetInspections = (
  inspectionsData,
  updatedDynamicStates,
  IdHandler
) => {
  return {
    inspectedBy: () => ({
      title: "Inspected By",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "inspectedBy",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.inspectedBy)
        )?.map((inspectedBy, index) => ({
          key: index,
          title: inspectedBy,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.inspectedBy === inspectedBy
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Inspection By " + inspectedBy,
          contentsHandler: " Inspections By " + inspectedBy,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
    createdAt: () => ({
      title: "Inspection Creation Date",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "createdAt",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (b) => b.createdAt >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a.createdAt >= getMonthsTimestamps()[i] &&
                    a.createdAt <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Inspection In " + months[i],
                contentsHandler: " Inspections In " + months[i],
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "1600px",
    }), //createdAt

    fleetName: () => ({
      title: "Inspection Fleet",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "fleetName",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "fleetName"
          )
        )?.map(([key, value]) => ({
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Inspection for " + key,
          contentsHandler: " Inspections for " + key,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }), //fleetName
  }; //Inspections
};
