import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import {
  logoutAll,
  resetPassword,
  changeStatus,
  logoutSingleSession,
  unlinkUser,
} from "./utils/LinkedFunctions";
import warningMessages from "./utils/warningMessages";
import { updateOtherUser } from "../../../../../../../actions";
import { updateArrayElement } from "../../../../../../../utils";
import { parsePhone } from "../../../../../Accounting/components/utilities";
import columnDefs from "./utils/columnDefs";
import WarningModal from "../../../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../../../commonComponents";
import { LogOutIcon } from "../../../../../../Header/subcomponents";
import { CircleLockIcon } from "../../../../../../pages/Settings/settingsComponents/AllUsers/assets";
import { HummerIcon } from "../../../../../../pages/Settings/settingsComponents/AllUsers/assets";
import { ReactComponent as ConnectedWhite } from "../../../../../../../assets/icons/connectedWhite.svg";
import { ReactComponent as CloseIcon } from "../../../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../../../icons/warningTriangle.svg";
import { useEditLogs } from "../../../../../../../hooks";

function CardLinked({
  rowData,
  dualRoleUser,
  setRowData,
  users,
  editable,
  endpoint,
  keyId,
  linkKey,
  elementName,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [user, setUser] = useState();
  const [warning, setWarning] = useState();
  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  useEffect(() => {
    if (!!users) {
      let u = users?.find((el) => el?.cognitoUserId === rowData?.[linkKey]);
      !!u && setUser(u);
    }
  }, [users]);

  async function logoutAllSessions() {
    const updateActiveSessions = user?.activeSessions?.map((session) => ({
      ...session,
      logout: true,
    }));
    await logoutAll(user, updateActiveSessions, saveAddedLogs);
    dispatch(
      updateOtherUser({
        identityId: user?.identityId,
        activeSessions: updateActiveSessions,
      })
    );
  }

  async function forceResetPassword() {
    logoutAllSessions();
    await resetPassword(user, saveAddedLogs);
    dispatch(
      updateOtherUser({
        identityId: user?.identityId,
        isForceToResetPassword: true,
      })
    );
  }

  async function changeSuspensionStatus() {
    await changeStatus(user, saveAddedLogs);
    dispatch(
      updateOtherUser({
        identityId: user?.identityId,
        isSuspended: !user?.isSuspended,
        suspendedDate: Date.now(),
        reactivatedDate: !!user?.suspendedDate ? Date.now() : "",
      })
    );
  }

  async function logoutUserSession(session) {
    const updatedActiveSessions = updateArrayElement(
      user?.activeSessions,
      { sessionId: session?.sessionId, logout: true },
      "sessionId"
    );
    await logoutSingleSession(
      user,
      saveAddedLogs,
      updatedActiveSessions,
      session
    );
    dispatch(
      updateOtherUser({
        identityId: user?.identityId,
        activeSessions: updatedActiveSessions,
      })
    );
  }

  async function unlinkHandler() {
    await unlinkUser({
      user,
      rowData,
      endpoint,
      keyId,
      linkKey,
      saveAddedLogs,
      elementName,
    });
    dispatch(updateOtherUser({ identityId: user?.identityId, linked: false }));
    setRowData((prev) =>
      rowData?.driverSub
        ? { ...prev, driverSub: "" }
        : { ...prev, [linkKey]: "" }
    );
    if (dualRoleUser) {
      await unlinkUser({
        user,
        rowData: dualRoleUser,
        endpoint: endpoint === "engineers" ? "architects" : "engineers",
        keyId: endpoint === "engineers" ? "architectId" : "engineerId",
        linkKey,
        saveAddedLogs,
        elementName,
      });
    }
  }

  return (
    <>
      <div
        className={`userLinkCardLinkedContainer ${
          isDarkMode && "userLinkCardLinkedContainerDark"
        }`}
      >
        <div className="infoContainer">
          <div className="info">
            <strong>Name:&nbsp;</strong>
            <span>{user?.nameOfUser}</span>
          </div>
          <div className="info">
            <strong>Department:&nbsp;</strong>
            <span>{user?.departmentName}</span>
          </div>
          <div className="info">
            <strong>Email:&nbsp;</strong>
            <span>{user?.userName}</span>
          </div>
          <div className="info">
            <strong>Role:&nbsp;</strong>
            <span>{user?.groupName}</span>
          </div>
          <div className="info">
            <strong>Phone Nr:&nbsp;</strong>
            <span>{parsePhone(user?.phone_number)}</span>
          </div>
        </div>
        <div className="buttonsContainer">
          <MondayButton
            className="mondayButtonBlue"
            disabled={!editable}
            Icon={<LogOutIcon height={13} width={13} />}
            onClick={() => setWarning("logout")}
          >
            Logout
          </MondayButton>
          <MondayButton
            className="mondayButtonBlue"
            disabled={!editable}
            Icon={<CircleLockIcon />}
            onClick={() => setWarning("resetPass")}
          >
            Force Reset Password
          </MondayButton>
          <MondayButton
            className={!user?.isSuspended && "mondayButtonRed"}
            disabled={!editable}
            Icon={<HummerIcon />}
            onClick={() =>
              setWarning(!user?.isSuspended ? "suspend" : "reactivate")
            }
          >
            {user?.isSuspended ? "Reactivate" : "Suspend"}
          </MondayButton>
          <MondayButton
            className="mondayButtonRed"
            disabled={!editable}
            Icon={<ConnectedWhite />}
            onClick={() => setWarning("unlink")}
          >
            Unlink
          </MondayButton>
        </div>
        {!!user && (
          <div
            className={`sessionGridContainer ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
          >
            <AgGridReact
              {...{
                rowData: user?.activeSessions ?? [],
                columnDefs: columnDefs({ logoutUserSession }),
                defaultColDef: {
                  resizable: true,
                  enablePivot: false,
                  enableColResize: false,
                  enableRowGroup: false,
                  editable: false,
                  sortable: false,
                  flex: 1,
                  minWidth: 100,
                  filter: false,
                },
                suppressDragLeaveHidesColumns: true,

                rowHeight: 39,
              }}
            />
          </div>
        )}
      </div>

      <WarningModal
        visible={!!warning}
        setVisible={setWarning}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>{warningMessages[warning]}</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setWarning(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                switch (warning) {
                  case "logout":
                    logoutAllSessions();
                    break;
                  case "resetPass":
                    forceResetPassword();
                    break;
                  case "suspend":
                    changeSuspensionStatus();
                    break;
                  case "reactivate":
                    changeSuspensionStatus();
                    break;
                  case "unlink":
                    unlinkHandler();
                    break;
                }
                setWarning(false);
              }}
              Icon={<Tick width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </>
  );
}

export default CardLinked;
