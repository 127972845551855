import React, { createContext, useContext, useState } from "react";

export const DrawerContext = createContext();

export const useNotificationsDrawer = () => useContext(DrawerContext);

const NotificationsDrawerProvider = ({ children }) => {
  const [open, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <DrawerContext.Provider value={{ open, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default NotificationsDrawerProvider;
