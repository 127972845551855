import {
  Button,
  DatePicker,
  Descriptions,
  Input,
  InputNumber,
  Alert,
} from "antd";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Tabs } from "antd";
import { NormalSizedModal } from "../../../../../../../Fleet/components/index";
import "./ApplyCredit.scss";
import { AgGridReact } from "ag-grid-react";
import { filterTables } from "../../../../../../../../../utils/filterTables";
import { fetchAllData } from "../../../../../../../Fleet/utils/fetchAllData";
import { useRedux } from "../../../../../../../hooks/useRedux";
import { processMemoCredit } from "./process";
import moment from "moment";
import { EditIcon } from "../../../../../../../DynamicView/src/index";
import { currencyFormatter } from "../../../../../../../utils/currencyFormatter";
import { forceToNumber } from "../../utils/checkers";
import { MondayButton } from "../../../../../../../../commonComponents/index";
import CreditExpand from "./CreditExpand/CreditExpand";
import { ClientInfo } from "./ClientInfo";
import { useSelector } from "react-redux";
const { TextArea } = Input;

export default function ApplyCredit({
  proppedType = "Discounts and Credits",
  clientId = "",
}) {
  const [visible, setVisible] = useState(false);
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable] = useRedux("paymentIsWritable");

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [memos, setMemos] = useState([]);
  const [info, setInfo] = useState({
    creditsTotal: 0,
  });
  // in order to keep track, we will create a object with keys for Each charge to be updated and values for the amount to be updated
  const [trackOfActions, setTrackOfActions] = useRedux(
    "paymentsTrackOfActions"
  );
  const [rowData, setRowData] = useRedux("applyCreditRowData", [], false);

  const leftCredit = useMemo(() => {
    if (Array.isArray(rowData)) {
      return rowData.reduce(
        (acc, curr) =>
          acc +
          (Number(curr?.creditAmount || 0) - Number(curr?.appliedCredit || 0)),
        0
      );
    }
    return 0;
  }, [rowData]);

  const retrieveMemos = useCallback(async () => {
    await filterTables("charges", "chargeType", "Credit Memo").then((res) => {
      const {
        myProcessedData,
        creditsTotal = 0,
        trackChanges = {},
      } = processMemoCredit({
        memoCredit: res,
        paymentData: fakePayment,
        fakePayment,
      }) || [];
      setRowData(myProcessedData);
      setMemos(res);
      setTrackOfActions({ ...trackChanges, memos: res });
    });
  }, [fakePayment.credits]);

  // useMemo(() => {
  //   setInfo((prev) => ({ ...prev, creditsTotal: rowData }));
  // }, [rowData]);

  const onSaveCredit = async () => {
    let tempFake = structuredClone(fakePayment);
    let invoicesWithCredit = {};
    trackOfActions?.updateCharges?.forEach((element) => {
      element.applicables?.forEach((i, idx) => {
        const applicable = tempFake?.invoices?.find(
          (e) => e.invoiceId === i.invoiceId
        );
        if (!!applicable) {
          if (invoicesWithCredit[i.invoiceId]) {
            invoicesWithCredit[i.invoiceId] += forceToNumber(
              i?.appliedCredit || 0
            );
          } else {
            invoicesWithCredit[i.invoiceId] = forceToNumber(
              i?.appliedCredit || 0
            );
          }
          // let prev = applicable?.invoiceCredit ?? 0;
          // if (idx === 0) prev = 0;
          // applicable.invoiceCredit = prev + i.appliedCredit;
        }
      });
    });
    // setFakePayment(tempFake);
    Object.keys(invoicesWithCredit).forEach((key) => {
      const applicable = tempFake?.invoices?.find((e) => e.invoiceId === key);
      if (!!applicable) {
        applicable.invoiceCredit = invoicesWithCredit[key];
      }
    });

    // After we have saved the credit we have to update the Credit Memos with the credit applied
    const tempMemos = structuredClone(trackOfActions.memos);
    const tempTracks = structuredClone(rowData);
    tempMemos.forEach((memo) => {
      const myTrackedCharge = tempTracks.find(
        (el) => el.creditMemoNumber === memo.chargeNumber
      );

      memo?.chargeItems?.forEach((item) => {
        let appliedCredit = item?.appliedCredit || 0;
        let customCredited =
          myTrackedCharge?.applicables?.find(
            (e) =>
              e.invoiceId === item.invoiceId &&
              // e.chargeId === item.chargeId &&
              e.name === item.name
          )?.appliedCredit ?? 0;
        tempFake.credits[memo.chargeId] = {
          ...(tempFake?.credits[memo.chargeId] || {}),
          [item.name]: {
            appliedCredit: Number(customCredited),
          },
        };
        // item.appliedCredit = Number(appliedCredit) + Number(customCredited);
      });
    });
    setFakePayment(tempFake);

    // setVisible(false);
    setTrackOfActions({ ...trackOfActions, memos: tempMemos });
  };

  useEffect(() => {
    setTrackOfActions({ ...trackOfActions, updateCharges: rowData });
  }, [rowData]);

  useEffect(() => {
    retrieveMemos();
  }, []);

  return (
    <>
      {leftCredit > 0 && (
        <>
          <Alert
            message={"Unused CREDIT"}
            action={
              <Button
                disabled={!isWritable}
                onClick={() => setVisible(true)}
                size="small"
                type="primary"
              >
                Apply Credit
              </Button>
            }
            description={
              <div>
                For this Client, there is{" "}
                <strong>{currencyFormatter(leftCredit || 0)}</strong> CREDIT
                left to be utilized.
              </div>
            }
            type="info"
            showIcon
            closable={false}
          />
          <NormalSizedModal
            visible={visible}
            title={`${proppedType}`}
            maskClosable={true}
            onCancel={() => setVisible(false)}
            customFooter={[
              <MondayButton onClick={onSaveCredit}>Save Changes</MondayButton>,
            ]}
            centered
          >
            <div className="apply-credit-payment-container">
              {/* <Tabs type="card" defaultActiveKey="1" items={items} /> */}
              <ClientInfo />
              <>
                {" "}
                <div
                  className="light-ag-theme documentationsTable ag-theme-alpine"
                  style={{ height: "100%" }}
                >
                  <AgGridReact
                    pivotPanelShow="always"
                    enableRangeSelection={true}
                    animateRows={true}
                    suppressDragLeaveHidesColumns={true}
                    allowDragFromColumnsToolPanel={true}
                    rowGroupPanelShow="always"
                    pagination={true}
                    paginationPageSize={13}
                    immutableData={false}
                    masterDetail={true}
                    rowData={rowData}
                    onCellEditingStopped={(e) => {
                      const myField = e?.colDef?.field ?? "";
                      if (myField === "appliedCredit") {
                        if (e.newValue > e.data?.amountDue) {
                        }
                        e.data[myField] = forceToNumber(e.newValue);
                      }
                    }}
                    //   rowData={sch?.sort((a, b) => moment(b?.createdAt) - moment(a?.createdAt))}
                    columnDefs={[
                      {
                        headerName: "Date",
                        field: "createdAt",
                        cellRendererFramework: (params) => {
                          return (
                            <span>
                              {moment(params?.data?.createdAt).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                          );
                        },
                        getQuickFilterText: () => "",
                      },
                      {
                        headerName: "Invoice #",
                        field: "invoiceNumber",
                        valueGetter: (params) => {
                          return `Invoice ${params?.data?.invoice?.invoiceNumber}`;
                        },
                        cellRendererFramework: (params) => {
                          return (
                            <span>Invoice {params.data.invoiceNumber}</span>
                          );
                        },
                      },
                      {
                        headerName: "Client",
                        field: "client",
                      },
                      {
                        headerName: "Credit Memo #",
                        field: "creditMemoNumber",
                      },
                      {
                        headerName: "Apply Credit",
                        field: "appliedCredit",
                        cellStyle: {
                          color: "white",
                          backgroundColor: "#68C142",
                        },
                        editable: false,
                        // maxWidth: 400,
                        cellRendererFramework: ({ value = 0, data }) => {
                          return <AppliedCredit {...{ value, data }} />;
                        },
                      },
                      {
                        headerName: "Available Credit",
                        editable: false,
                        field: "creditAmount",
                        cellStyle: {
                          color: "white",
                          backgroundColor: "#EBB02D",
                          textAlign: "center",
                        },
                        valueGetter: ({ data }) => {
                          const appliedAlready = data?.appliedCredit || 0;
                          return currencyFormatter(
                            data.creditAmount - appliedAlready
                          );
                        },
                        cellRendererFramework: ({ value = 0, data, api }) => {
                          return (
                            <div
                              style={{
                                width: "140px",
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              {value}
                              <CreditExpand {...{ data, api }} />
                            </div>
                          );
                        },
                      },
                    ]}
                    rowHeight={45}
                    defaultColDef={{
                      flex: 1,
                      sortable: true,
                      resizable: true,
                      enablePivot: true,
                      filter: true,
                      enableColResize: true,
                      enableRowGroup: true,
                      // floatingFilter: true,
                    }}
                  />
                </div>
              </>
            </div>
          </NormalSizedModal>{" "}
        </>
      )}
    </>
  );
}

const calculateInvoiceMemoCreditTotal = ({
  applicables = [],
  fakePayment,
  data,
}) => {
  if (Array.isArray(applicables) && applicables.length > 0) {
    let total = 0;
    total = Object.values(
      fakePayment?.credits?.[data?.creditMemoId] || {}
    )?.reduce((acc, curr) => {
      return acc + Number(curr?.appliedCredit || 0);
    }, 0);
    return total;
  }
  return 0;
};

const AppliedCredit = ({ value, data }) => {
  const [fakePayment] = useRedux("fakePayment");
  const amount = calculateInvoiceMemoCreditTotal({
    applicables: data?.applicables || [],
    fakePayment,
    data,
  });
  return (
    <div
      // className="payment-editable-cell"
      style={{
        padding: "0 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "140px",
      }}
    >
      <div>{currencyFormatter(Number(amount || 0))}</div>
      {/* <div className="payment-editable-cell-icon">
          {<EditIcon />}
        </div> */}
    </div>
  );
};
