import {
  LogoutIcon,
  SuspendedUserIcon,
} from "../../../../../../../../../../assets";
import { CircleLockIcon } from "../../../../../assets";

const sessionHelperObject = {
  Logout: {
    title: "Confirm Force Logout",
    text: (
      <p>
        Are you sure you want to force logout this user? This action{" "}
        <b>will immediately end the user's current sessions </b>
        and they will be required to log in again to continue. Please confirm
        this action as it may disrupt any ongoing work the user is engaged in.
      </p>
    ),
    TitleIcon: LogoutIcon,
  },
  "Force Reset Password": {
    title: "Confirm Password Reset",
    text: (
      <p>
        Are you sure you want to force a password reset for this user? This
        action will invalidate the current password and{" "}
        <b>they will be required to set up a new one upon their next login</b>.
        Please confirm to ensure the user is aware and prepared for this change
        to avoid access issues.
      </p>
    ),
    TitleIcon: CircleLockIcon,
  },
  Suspend: {
    title: "Confirm User Suspension",
    text: (
      <p>
        Are you sure you want to suspend this user?{" "}
        <b>
          Suspension will restrict the user's access to all system
          functionalities immediately
        </b>
        . This action should be taken with caution as it will prevent the user
        from performing any operations within the system until the suspension is
        lifted.
      </p>
    ),
    TitleIcon: SuspendedUserIcon,
  },
  Reactivate: {
    title: "Confirm User Reactivation",
    text: <p>Are you sure you want to reactivate this user?</p>,
    TitleIcon: SuspendedUserIcon,
  },
};

export default sessionHelperObject;
