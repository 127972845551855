import { useRedux } from "../../../../../../hooks/useRedux";
import { useGroupsContext } from "../context/GroupsContext";

import classes from "./CustomSelectBox.module.scss";

export const CustomSelectBox = ({ data }) => {
  const { selectedGroup, selectedInvoices } = useGroupsContext();

  const [visible] = useRedux("newChargeVisible");

  const categoryFrom = visible?.categoryFrom;

  const isSelected = (() => {
    if (categoryFrom === "Invoice") {
      return selectedInvoices?.find(
        ({ invoiceNumber }) => invoiceNumber === data?.invoiceNumber
      );
    }
    return data.id === selectedGroup;
  })();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        className={`${classes.container} ${
          isSelected ? classes.checked : classes.unchecked
        }`}
      >
        {isSelected && (
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.6399 10.5521L1.7219 7.08406C1.62887 6.98973 1.51802 6.91483 1.39579 6.8637C1.27356 6.81257 1.14239 6.78624 1.0099 6.78624C0.877412 6.78624 0.746242 6.81257 0.624014 6.8637C0.501785 6.91483 0.390935 6.98973 0.297903 7.08406C0.203535 7.17841 0.128677 7.29042 0.0776041 7.41371C0.0265312 7.53699 0.000244141 7.66912 0.000244141 7.80257C0.000244141 7.93601 0.0265312 8.06814 0.0776041 8.19142C0.128677 8.3147 0.203535 8.42672 0.297903 8.52106L3.9179 12.6971C4.01159 12.7921 4.12322 12.8675 4.2463 12.919C4.36939 12.9705 4.50148 12.997 4.6349 12.997C4.76833 12.997 4.90042 12.9705 5.0235 12.919C5.14659 12.8675 5.25822 12.7921 5.3519 12.6971L13.7019 1.73506C13.7963 1.64072 13.8711 1.5287 13.9222 1.40542C13.9733 1.28214 13.9996 1.15001 13.9996 1.01656C13.9996 0.883123 13.9733 0.750988 13.9222 0.627707C13.8711 0.504425 13.7963 0.392412 13.7019 0.298065C13.6089 0.203731 13.498 0.128826 13.3758 0.0776992C13.2536 0.0265725 13.1224 0.000244141 12.9899 0.000244141C12.8574 0.000244141 12.7262 0.0265725 12.604 0.0776992C12.4818 0.128826 12.3709 0.203731 12.2779 0.298065L4.6399 10.5521Z"
              fill="white"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
