import { useMemo, useContext, useRef } from "react";
import { message } from "antd";

import { fetchReportData } from "./utils";
import { ONE_DAY, TWO_DAYS, FleetsLiveContext } from "../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { ReportViewController, ReportsGridLayout } from "./components";

import "./LiveReportsView.scss";

function LiveReportsView() {
  const { locations, updateAnalytics, updateTotals } =
    useContext(FleetsLiveContext);

  const reportsViewRef = useRef(null);

  const DIFF_LIMIT = useMemo(() => {
    return TWO_DAYS * 46 + ONE_DAY;
  }, []);

  async function collectReportsData({ dateRange, selectedVehicle = "" }) {
    if (!dateRange || !dateRange?.length) {
      message.error({
        content: "Please enter a valid time frame (max frame is 93 days)",
        key: "reportGetData",
      });
      return;
    }

    let start = dayjsNY(dateRange[0]).startOf("D").valueOf();
    let end = dayjsNY(dateRange[1]).endOf("D").valueOf();

    if (end - start > DIFF_LIMIT) {
      message.error({
        content: "Please enter a valid time frame (max frame is 93 days)",
        key: "reportGetData",
      });
      return;
    }

    const { analytics: tmp, totals: tmpTotals } = await fetchReportData({
      minDate: start,
      maxDate: end,
      locations,
      selectedVehicle,
    });

    updateAnalytics(tmp);
    updateTotals(tmpTotals);
  }

  function vehicleSelectHandler(vehicle) {
    if (reportsViewRef?.current) {
      reportsViewRef.current.changeVehicleFilter(vehicle);
    }
  }

  return (
    <div className="live-reports-view-container">
      <ReportViewController
        {...{
          collectReportsData,
          vehicleSelectHandler,
        }}
      />
      <ReportsGridLayout ref={reportsViewRef} />
    </div>
  );
}

export default LiveReportsView;
