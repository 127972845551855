import React, { useState } from "react";
import { MondayButton } from "../../../../../../commonComponents";
import { EditIcon } from "../../../../../../../icons";
import InspectionsForEachJump from "./InspectionsForEachJump";

export const HoistInfoHeader = (props) => {
  const { elevation } = props;
  const displayData = [
    {
      head: "Type:",
      value: elevation?.type,
    },
    {
      head: "Location:",
      value: elevation?.location,
    },
    {
      head: "Access:",
      value: elevation?.access,
    },
    {
      head: "From:",
      value: elevation?.from,
    },
    {
      head: "To:",
      value: elevation?.to,
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          paddingLeft: 10,
        }}
      >
        {displayData?.map(({ head, value }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                gap: "0.4rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#323338",
                }}
              >
                {head}
              </div>
              <div
                style={{
                  width: "100%",
                  fontSize: 16,
                  color: "#323338",
                }}
              >
                {value}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
