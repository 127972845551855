import { useState } from "react";
import { Button, Modal } from "antd";
import { useParams } from "react-router-dom";
import "./BalanceReport.scss";
import BalanceTable from "./BalanceTable/BalanceTable";
import { NormalSizedModal } from "../../../../../Fleet/components/index";
import { footerButtons } from "../../../../../Estimations/footerButtons";
import {
  CloseIconModal,
  CloseIconModalHeader,
  DownCaretWhite,
} from "../../../../../../../assets/index";
import { MondayButton } from "../../../../../../commonComponents/index";
import createPDF from "../../../../../../../integrations/createPDF/index";
import { RetainageIcon } from "../../../../../../../icons/index";
import ClientOpenBalanceTab from "../../../../../Accounts/Tabs/Accounting/ClientOpenBalanceTab";

export default function BalanceReport({ viewFrom = "" }) {
  const [visible, setVisible] = useState(false);
  const { id } = useParams();

  return (
    <div>
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => setVisible(true)}
        Icon={<RetainageIcon />}
      >
        Open Balance
      </MondayButton>
      {visible && (
        <Modal
          title={"Open Balance"}
          visible={visible}
          className="balanceReportModal"
          maskClosable={true}
          onCancel={() => setVisible(false)}
          centered={true}
          closeIcon={<CloseIconModalHeader />}
          footer={null}
          // footer={
          //   <div>
          //     <MondayButton
          //       className="mondayButtonRed"
          //       Icon={<CloseIconModal />}
          //       onClick={() => setVisible(false)}
          //     >
          //       Close Open Balance
          //     </MondayButton>
          //   </div>
          // }
        >
          <ClientOpenBalanceTab id={id} filterBy="projectId" />
          {/* <BalanceTable {...{ active: visible, projectId: id, viewFrom }} /> */}
        </Modal>
      )}
    </div>
  );
}
