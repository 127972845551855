export class HorizontalNettingPLI {
  id;
  approved = false;
  serviceId = 8;

  length;
  width;

  onFloor;
  includingCorners;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}

export class AlternateHorizontalNettingPLI {
  id;
  approved = false;
  serviceId = 8;

  length;
  width;

  onFloor;
  includingCorners;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;
  isAlternate = true;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
