import { useState } from "react";
import { hexToRgba } from "../../../../utils";
import "../../../SidebarPages/Inspections/Inspection.scss";
import { Modal } from "antd";
import { XIcon } from "../../Communication/assets";
import "./Services.scss";
import { DynamicTable } from "../../../commonComponents/DynamicTable/DynamicTable";
import {
  displayServices,
  servicesColumnDefs,
} from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { useSelector } from "react-redux";

const Services = ({ value = {}, title = "" }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [visible, setVisible] = useState(false);
  const disabledColor = hexToRgba("#FE4C4A", 0.4);
  const services = Object.values(value || {})?.flatMap(
    ({ services = [] }) => services
  )?.length;
  const disabled = services === 0;

  return (
    <>
      <div
        className="servicesInspectedCell"
        style={
          disabled
            ? {
                backgroundColor: disabledColor,
                borderColor: disabledColor,
                cursor: "not-allowed",
              }
            : {}
        }
        onClick={() => (disabled ? () => {} : setVisible(true))}
      >
        {services}
      </div>
      {visible && (
        <Modal
          className={`servicesModalEstimation ${
            isDarkMode && "servicesModalEstimationDark"
          }`}
          title={title}
          centered
          open={visible}
          footer={null}
          closeIcon={<XIcon />}
          onCancel={() => setVisible(false)}
          width={783}
          height="fit-content"
        >
          <div className="services-table">
            <DynamicTable
              {...{
                columnDefs: servicesColumnDefs,
                rowData: displayServices(value, isDarkMode),
                tableStyle: {
                  width: "100%",
                  borderCollapse: "collapse",
                },
                tableHeaderStyle: {
                  paddingLeft: 15,
                },
                tableHeaderTr: {
                  textAlign: "left",
                  paddingLeft: 15,
                },
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Services;
