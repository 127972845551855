import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";

import {
  DatePickerMoment,
  ExportOrEmailDropdown,
  MondayButton,
  ToggleSwitch,
} from "../../../../../../../../commonComponents";
import { exportGridToExcel } from "./exportToExcel";
import { InputComponent } from "../../../../../../../Fleet/components";
import { CloseIconModal, ReceiptIcon } from "../../../../../../../../../assets";

import "./FiltersBalanceTable.scss";

function FiltersBalanceTable({
  filterAsOfDate = () => {},
  activeInvoiceButtonText = "",
  onInvoiceToggle = () => {},
  gridApi,
  columnApi,
  rowData,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [dataHolder, setDataHolder] = useState({
    startDate: moment().startOf("year"),
    endDate: moment(),
  });

  const onChange = (params) => {
    const newDates = {
      startDate: params?.[0] ? params[0] : null,
      endDate: params?.[1] ? params[1] : null,
    };
    setDataHolder((prev) => ({
      ...prev,
      ...newDates,
    }));
    filterAsOfDate({ filterDateParam: newDates });
  };

  const onClear = () => {
    filterAsOfDate({
      filterDateParam: null,
    });
    setDataHolder({
      startDate: null,
      endDate: null,
    });

    if (gridApi) {
      gridApi.setQuickFilter();
    }
  };

  const onSearchFilterChanged = (event) => {
    gridApi.setQuickFilter(event.target.value);
  };

  return (
    <div
      className={`filterBalanceTableActions ${
        isDarkMode && "filterBalanceTableActions-dark"
      }`}
    >
      <div className="leftSideFilterBalance">
        {gridApi && (
          <InputComponent
            placeholder="Search..."
            prefix={<SearchOutlined />}
            className="open-balance-search"
            onChange={onSearchFilterChanged}
          />
        )}
        {dataHolder?.startDate && (
          <MondayButton
            className="mondayButtonRed"
            Icon={<CloseIconModal />}
            onClick={onClear}
          >
            Clear Filters
          </MondayButton>
        )}
      </div>
      <div className="rightSideFilterBalance">
        <DatePickerMoment.RangePicker
          placeholder={["MM/DD/YYYY", "MM/DD/YYYY"]}
          variant="filled"
          onChange={onChange}
          format="MM/DD/YYYY"
        />
        <ToggleSwitch
          variant="filled"
          icon={<ReceiptIcon />}
          label={activeInvoiceButtonText}
          onChange={onInvoiceToggle}
        />
        <ExportOrEmailDropdown
          {...{
            rowData,
            title: "Open Balance",
            gridApi,
            gridColumnApi: columnApi,
            exportToExcel: (tableColumns) =>
              exportGridToExcel(tableColumns, gridApi),
          }}
        />
      </div>
    </div>
  );
}

export default FiltersBalanceTable;
