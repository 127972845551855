import { message } from "antd";
import { useSelector } from "react-redux";
import { DeleteIcon } from "../../../../DynamicView/src";
import { cellRenders } from "../components/ChargeInformation/AgGridData/components/cellRenders";
const dayjs = require("dayjs");
import { Tooltip } from "antd";
import { useState } from "react";
import InspectionModal from "../../../../components/AgGridComponents/InspectionModal";
import { API } from "aws-amplify";
import { extractDataHandler } from "../../Rentals/RentalsTab/ListOfRentals/Componets/ListOfServices/listOfServicesFunctions";
import { ProcessPreviewData } from "../../Rentals/RentalsTab/GridList/RentalDescription/RentalDescription";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { Notes } from "../../../../../commonComponents/Notes/Notes";
import { Link } from "react-router-dom";
import { sortDates } from "../../../../ToDos/AgGridData/columnDefs";

export const getEstimationQuickDesc = (arr) => {
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );
  return arr?.map(
    (el) => proposedTypeOfWork?.find(({ workName }) => workName === el)?.idCode
  );
};

export const excelColumnsConfig = [
  "chargeNumber",
  "description",
  "chargeAmount",
  "creditChargeAmount",
  "remainingAmount",
  "categoryFrom",
  "itemsLength",
  "createdAt",
  "invoices",
  "quickBooksNumber",
  "notes",
  "chargeNumber",
];

export const columnDefs = ({
  setGetChargeId,
  setCancelModalVisible,
  isEditable,
}) => [
  {
    headerName: "Charge No#",
    field: "chargeNumber",
    filter: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    filter: "agNumberColumnFilter",
    //Removed the Sort Icon
    // unSortIcon: true,
    sortable: true,
    resizable: true,
    minWidth: 200,
    maxWidth: 200,
    link: (data) => `/charges/${data.chargeId}`,
    cellRendererFramework: function (params) {
      const value = `Charge: ${params.data?.chargeNumber}`;

      try {
        return (
          <Link
            {...{
              to: `/charges/${params.data.chargeId}`,
            }}
          >
            <button className="linkButton">
              Charge {params?.data?.chargeNumber}
            </button>
          </Link>
        );
      } catch (e) {
        if (value) {
          return value;
        } else {
          return null;
        }
      }
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return `Charge ${value}`;
      },
    },
    chartDataType: "category",
  },
  {
    headerName: "Description",
    field: "description",
    minWidth: 200,
    resizable: true,
    enableRowGroup: true,
    cellRendererFramework: (params) => {
      const { data } = params;
      const shortServicesNames = getEstimationQuickDesc(
        data?.chargeItems?.map(({ label }) => label)
      );
      const serviceInfo = shortServicesNames?.reduce(
        (str, crr, i) =>
          `${str} ${crr}${
            i === shortServicesNames?.length - 2
              ? " &"
              : i < shortServicesNames?.length - 2
              ? ","
              : ""
          }`,
        ""
      );
      const dateCharged = dayjs(data?.createdAt);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const rentalInfo =
        data?.includedRentals?.length > 0
          ? ` ${data?.includedRentals?.length} ${
              data?.includedRentals?.length === 1 ? "Rental" : "Rentals"
            } included.`
          : "";

      const getPeriodForRental = () => {
        params.data.services = params?.data?.chargeItems;
        let data = extractDataHandler({ params }) || [];
        const structuredDataArr = data
          ?.filter((el) => !!el?.startDate && !!el?.endDate)
          ?.sort((a, b) => a?.startDate - b?.startDate);
        return ProcessPreviewData({ structuredDataArr, showTooltip: false });
      };

      const info = `${serviceInfo !== "" ? serviceInfo + " - " : ""} ${
        data?.categoryFrom
      } #${data?.categoryNumber}${" - "}
            ${
              data?.categoryFrom === "Requisition"
                ? dayjs(data?.periodTo?.start)?.format("MMMM YYYY")
                : // +
                //   " - " +
                //   dayjs(data?.periodTo?.end)?.format("MM/DD/YYYY")
                data?.categoryFrom === "Rental"
                ? getPeriodForRental()
                : months[dateCharged.month()]
            } ${
        data?.categoryFrom !== "Requisition" && data?.categoryFrom !== "Rental"
          ? dateCharged.year()
          : " "
      } | ${rentalInfo}`;
      return (
        <Tooltip title={info}>
          <div style={{ display: "flex" }}>{info}</div>
        </Tooltip>
      );
    },
    chartDataType: "category",
  },
  {
    headerName: "Charge Amount",
    field: "chargeAmount",
    filter: "agNumberColumnFilter",
    filter: true,
    resizable: true,
    enableRowGroup: true,
    minWidth: 190,
    //Removed the Sort Icon
    sortable: true,
    // unSortIcon: true,
    cellRenderer: cellRenders({}).price,
    // valueGetter: cellRenders({}).totalPrice,
    chartDataType: "category",
  },
  {
    headerName: "Credit Amount",
    field: "creditChargeAmount",
    filter: "agNumberColumnFilter",
    filter: true,
    resizable: true,
    enableRowGroup: true,
    minWidth: 190,
    sortable: true,
    cellRendererFramework: ({ value: creditChargeAmount }) =>
      formatCurrency(
        creditChargeAmount * -1 === 0 ? 0 : creditChargeAmount * -1
      ),
  },
  {
    headerName: "Remaining Amount",
    field: "remainingAmount",
    filter: "agNumberColumnFilter",
    filter: true,
    resizable: true,
    enableRowGroup: true,
    minWidth: 200,
    //Removed the Sort Icon
    sortable: true,
    // unSortIcon: true,
    cellRenderer: cellRenders({ nonZero: true }).price,
    valueGetter: cellRenders({ nonZero: true }).totalPrice,
    chartDataType: "series",
    // cellRendererFramework: ({ value }) =>
    //   `${formatCurrency(parseFloat(value) || 0)}`,
  },
  {
    headerName: "Category From",
    field: "categoryFrom",
    filter: "agTextColumnFilter",
    filterParams: { apply: true, newRowsAction: "keep" },
    resizable: true,
    filter: true,
    enableRowGroup: true,
    minWidth: 160,
    //Removed the Sort Icon
    // unSortIcon: true,
    sortable: true,
    enableCellExpressions: true,
    chartDataType: "excluded",
    cellRendererFramework: ({ value, data }) => {
      const tables = {
        Requisition: "applications",
        Estimation: "estimations",
        Rental: "rentals",
      };
      return (
        <span
          className="redirectLink"
          onClick={async () => {
            try {
              await API.get(
                `${tables[value]}`,
                `/${tables[value]}/${data?.recordId}`
              );
            } catch (err) {
              console.error("Error:", err);
              message.error(
                `${value} #${data?.categoryNumber} does not exist!`
              );
              return;
            }
            window.open(
              `${window.location.origin}/${tables[value]}/${data?.recordId}`,
              "_blank"
            );
          }}
        >
          {value} #{data?.categoryNumber}
        </span>
      );
    },
  },
  {
    headerName: "Services",
    field: "itemsLength",
    filter: "agNumberColumnFilter",
    filter: true,
    resizable: true,
    enableRowGroup: true,
    minWidth: 130,
    //Removed the Sort Icon
    sortable: true,
    // unSortIcon: true,
    enableCellExpressions: true,
    chartDataType: "category",
    cellRendererFramework: ({ value, data }) => {
      const [visible, setVisible] = useState(false);
      return (
        <>
          <button
            className="servicesInspectedCell"
            onClick={() => setVisible((prev) => !prev)}
          >
            {value}
          </button>
          <InspectionModal
            {...{
              visible,
              setVisible,
              title: "Services of This Charge",
              footer: [
                {
                  text: "close",
                  onClick: () => setVisible(false),
                  primary: true,
                },
              ],
            }}
          >
            {data?.chargeItems?.length > 0
              ? data?.chargeItems?.map(({ label, serviceOptions }) => (
                  <div className="inspectedServiceName">
                    <span>{`${label} (${
                      serviceOptions?.flat()?.length
                    })`}</span>
                  </div>
                ))
              : "There are no services for this charge."}
          </InspectionModal>
        </>
      );
    },
  },
  {
    headerName: "Created At",
    field: "createdAt",
    filter: "agTextColumnFilter",
    resizable: true,
    enableRowGroup: true,
    minWidth: 150,
    //Removed the Sort Icon
    // unSortIcon: true,
    sortable: true,
    sort: "desc",
    valueGetter: ({ data }) => {
      try {
        return data?.createdAt
          ? dayjs(data?.createdAt).isValid()
            ? dayjs(data?.createdAt).format("MM/DD/YYYY")
            : ""
          : "";
      } catch (err) {
        return data?.createdAt;
      }
    },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
    chartDataType: "excluded",
    getQuickFilterText: () => "",
  },
  {
    headerName: "Invoices No#",
    field: "invoices",
    filterParams: "dateFilterParams",
    filter: "agNumberColumnFilter",
    filter: true,
    enableRowGroup: false,
    resizable: true,
    minWidth: 150,
    //Removed the Sort Icon
    sortable: true,
    // unSortIcon: true,
    cellRendererFramework: ({ value: invoices }) => (
      <>
        {invoices?.map(({ invoiceId, invoiceNumber, invoiceAmount }, i) => (
          <>
            <Link to={`/invoices/${invoiceId}`} target="_blank">
              <Tooltip title={formatCurrency(invoiceAmount)}>
                <b>{invoiceNumber}</b>
              </Tooltip>
            </Link>
            <b>{i + 1 !== invoices?.length && ", "}</b>
          </>
        ))}
      </>
    ),
  },
  {
    headerName: "Invoiced Status",
    field: "invoices",
    filterParams: "dateFilterParams",
    filter: "agNumberColumnFilter",
    filter: true,
    enableRowGroup: true,
    resizable: true,
    minWidth: 150,
    //Removed the Sort Icon
    sortable: true,
    // unSortIcon: true,
    cellRendererFramework: ({ value: invoices, data }) => {
      const total = invoices?.reduce(
        (acc, { invoiceAmount }) => acc + invoiceAmount,
        0
      );
      return parseInt(total) === parseInt(data?.chargeAmount) ? (
        <Tooltip title={formatCurrency(total)}>
          <b style={{ color: "#7AC14D" }}>Fully Invoiced</b>
        </Tooltip>
      ) : total > 0 ? (
        <Tooltip title={formatCurrency(total)} style={{ color: "#CC782F" }}>
          <b style={{ color: "#7AC14D" }}>Partly Invoiced</b>
        </Tooltip>
      ) : (
        <b style={{ color: "#FF4D4F" }}>Not Invoiced</b>
      );
    },
    enableRowGroup: false,
  },
  {
    headerName: "Quick Books No#",
    field: "quickBooksNumber",
    filter: "agNumberColumnFilter",
    filter: true,
    resizable: true,
    enableRowGroup: true,
    minWidth: 180,
    //Removed the Sort Icon
    sortable: true,
    // unSortIcon: true,
  },
  {
    headerName: "Notes",
    field: "notes",
    filter: "agMultiColumnFilter",
    minWidth: 100,
    enableRowGroup: false,
    cellRendererFramework: (params) => {
      return Notes({
        noteModalName: `${params?.data.projectAddress} | Charge ${params?.data?.chargeNumber}`,
        rowId: params?.data?.chargeId,
        topicCategory: "Charges",
      });
    },
  },
  {
    headerName: "Actions",
    field: "chargeNumber",
    minWidth: 125,
    maxWidth: 130,
    cellRendererFramework: (params) => {
      const hasInvoices = params?.data?.invoices?.length > 0;
      return (
        <div
          onClick={() => {
            if (isEditable) {
              if (hasInvoices) return;
              return (
                setCancelModalVisible(true),
                setGetChargeId(params?.data?.chargeId)
              );
            } else {
              message.error("You don't have permission to cancel this charge");
            }
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={hasInvoices ? "This charge is invoiced!" : "Delete Charge"}
          >
            <DeleteIcon
              style={{
                cursor: "pointer",
                fill: !isEditable || hasInvoices ? "#BDBDBD" : "#d10003",
              }}
            />
          </Tooltip>
        </div>
      );
    },
    chartDataType: "excluded",
  },
];
