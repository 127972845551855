import { InsertRowBelowOutlined } from "@ant-design/icons";
import { Alert, message, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { Input } from "antd";

const { TextArea } = Input;
import {
  MondayButton,
  RichTextEditor,
} from "../../../../../../commonComponents";
import { useRedux } from "../../../../../hooks";
import { createEmptyPLI } from "../../../tools/polyfillers/PLICreator";
import { checkIfRowHasValidData } from "../../../tools/formatters/validators";
import moment from "moment";
import { NormalSizedModal } from "../../../../../Fleet/components";
import Swal from "sweetalert2";

function AutoFiller({ serviceId, elevationIndex, optionIndex }) {
  console.log("autofiller", { elevationIndex, serviceId });
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <Tooltip title="Insert from Source">
        <MondayButton
          hasIcon={false}
          className="mondayButtonPrismarine"
          onClick={() => setOpen(true)}
        >
          <InsertRowBelowOutlined />
        </MondayButton>
      </Tooltip>
      {open && (
        <AutoFillerModal
          {...{ serviceId, elevationIndex, open, setOpen, optionIndex }}
        />
      )}
    </div>
  );
}

export default AutoFiller;

const AutoFillerModal = ({
  open,
  setOpen,
  serviceId,
  elevationIndex,
  optionIndex,
}) => {
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [data, setData] = useState("");
  let toAddDescription = false;

  const getDescriptions = (input) => {
    function createArrayOfObjects(text) {
      let arrayOfObjects = [];

      // First, filter and process the lines
      let lines = text
        .split("\n")
        .filter((line) => line.length > 2)
        .map((line) => {
          return line
            .replaceAll("&nbsp;", " ")
            .replaceAll("&prime;", "'")
            .replaceAll("'", "'")
            .replaceAll("`", "'")
            .replaceAll("’", "'")
            .replaceAll("′", "'")
            .replaceAll("&rsquo;", "'");
        });

      console.log("lines", lines);

      lines.forEach((line, idx) => {
        let objectToPush = {};
        let dimensionCount = 0;
        let dimensionRegex = /\b\d+'[A-Za-z]\b/g;
        let match;
        let dimensionsEndIndex = 0;
        let dimensionsHolder = {
          l: "length",
          w: "width",
          h: "height",
        };

        // Capture dimensions up to the third one
        while ((match = dimensionRegex.exec(line)) !== null) {
          if (dimensionCount < 3) {
            dimensionCount++;
            let dimensionString = match[0];
            let length = parseInt(dimensionString.match(/\d+/)[0]);
            let type =
              dimensionString[dimensionString.length - 1].toLowerCase();
            let exists = dimensionsHolder?.[type];
            type = exists;
            delete dimensionsHolder[type];

            if (!objectToPush[type] && !!exists) {
              objectToPush[type] = length;
              dimensionsEndIndex = dimensionRegex.lastIndex;
            }
          } else {
            break; // Only consider the first set of dimensions
          }
        }

        // Remove matched dimensions up to the first " x " from the line
        let dimensionPart = line
          .slice(0, dimensionsEndIndex)
          .replace(/ x /g, "")
          .trim();
        let descriptionPart = line.slice(dimensionsEndIndex).trim();

        if (descriptionPart.length > 0) {
          objectToPush.description = {
            id: idx + 1,
            note: descriptionPart,
            createdAt: moment().valueOf(),
          };
        }

        arrayOfObjects.push(objectToPush);
      });

      return arrayOfObjects;
    }

    const arrayOfObjects = createArrayOfObjects(input);
    return arrayOfObjects;
  };

  const onInserting = () => {
    try {
      let dimensionsArray = getDescriptions(data);
      let currService = gridData?.[serviceId] || {};
      let currElevation =
        currService?.serviceOptions?.[optionIndex]?.[elevationIndex] || {};
      let currItems =
        currElevation?.items?.filter((item) => checkIfRowHasValidData(item)) ||
        [];
      let newPlis = dimensionsArray.map((dim, idx) => {
        let pli = createEmptyPLI(
          gridData[serviceId].serviceId,
          currItems.length + 1 + idx,
          gridData[serviceId],
          optionIndex
        );
        // if (dim?.description) {
        //   pli.description = dim.description;

        // }

        let toReturn = {
          ...(pli || {}),
          ...(dim || {}),
        };

        return toReturn;
      });
      let newItems = [...currItems, ...newPlis];
      currElevation.items = newItems;
      console.log("newItems", currElevation);
      setTakeOffTableData(gridData);
    } catch (e) {
      console.log("error", e);
    } finally {
      setOpen(false);
      message.success("Dimensions inserted successfully");
    }
  };

  return (
    <div>
      <NormalSizedModal
        visible={open}
        setVisible={setOpen}
        darkMode={true}
        title="Insert Dimensions"
        open={open}
        onOk={onInserting}
        width={600}
        closable={true}
        centered
        maskClosable={true}
        footerModal={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <MondayButton hasIcon={false} onClick={onInserting}>
              Save Changes
            </MondayButton>
          </div>
        }
      >
        {" "}
        <Alert
          message="Format Guide"
          description="Make sure that each pli is in a new line, and that the dimensions are in the format: 10'L x 5'W x 3'H. The description is what comes after the dimensions, and is optional."
          type="success"
          showIcon={false}
        />
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "800px",
            gap: "10px",
            alignContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <TextArea
            autoSize={{
              minRows: 5,
              maxRows: 100,
            }}
            placeholder="Insert Dimensions Here | Source can be any text"
            value={data}
            onChange={(e) => setData(e.target.value)}
          />
        </div>
      </NormalSizedModal>
    </div>
  );
};
