import { useState } from "react";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../../../commonComponents";
import { TickIcon } from "../../../../../Roles/src";
import { useUserProfileContext } from "../../../../UserProfileContext";
import { nameFirstDigits } from "../../../../../../../../../utils/nameFirstDigits";

const ProfileColorModal = ({ changeColorModal, setChangeColorModal }) => {
  const { profileChanges, setProfileChanges } = useUserProfileContext();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const {
    preferences: {
      preferences: { profileColor },
    },
  } = useSelector((state) => state.preferences);

  const [userModalColor, setUserModalColor] = useState();

  const onClose = () => {
    setChangeColorModal(false);
  };

  const currColor =
    userModalColor || profileChanges?.profileColor || profileColor || "#4895ef";

  return (
    <Modal
      className="profileColorModal"
      title="Profile Picture Color"
      open={changeColorModal}
      onCancel={onClose}
      closeIcon={<XIcon />}
      footer={
        <>
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onClose,
            }}
          >
            Cancel
          </MondayButton>
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: () => {
                onClose();
                setProfileChanges((prev) => ({
                  ...prev,
                  profileColor: userModalColor,
                }));
              },
            }}
          >
            Save
          </MondayButton>
        </>
      }
    >
      <div className="profile-circle" style={{ background: currColor }}>
        {nameFirstDigits(userConfiguration.nameOfUser)}
      </div>
      <SketchPicker
        color={userModalColor}
        onChange={(updatedColor) => setUserModalColor(updatedColor.hex)}
      />
    </Modal>
  );
};

export default ProfileColorModal;
