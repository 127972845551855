import {
  progressStatuses,
  TypeOfWorkDefinitions,
} from "../../../../../SidebarPages/Scheduling/models/TypeOfWorkDefinitions";
import {
  get_ProgressDimensionsByServiceId,
  progressPercentage_valueGetter,
} from "./ProgressColumnDefinition";
import { hasItemYetToSchedule } from "../../../Scheduling/helpers/PLI-setters-getters";

export const scheduleIDCellClassRules = {
  "id-with-subPli": (params) => {
    return params?.data?.subPli && params?.data?.subPli?.length > 0;
  },
  "id-without-subPli": (params) => {
    return (
      params?.data?.subPli === undefined || params?.data?.subPli?.length === 0
    );
  },
};

export const progressStatusCellClassRules = () => {
  let possibleClasses = {};

  for (const progressStatus of progressStatuses) {
    possibleClasses[progressStatus] = (params) => {
      for (const workDef of Object.values(TypeOfWorkDefinitions)) {
        if (workDef?.progressStatuses?.[progressStatus] === params?.value)
          return true; //example "Partially installed"
      }
      return false;
    };
  }

  return possibleClasses;
};

export const editableProgressCell = ({ dayNumber }) => {
  return {
    "edit-progress-enabled": (params) => {
      const pli = params.data;
      const disabledFromPLi = pli?.subPli && pli?.subPli?.length > 0;
      let disabledFromNotInThisDay = false;
      if (dayNumber) {
        if (!pli?.days?.includes("D" + dayNumber)) {
          disabledFromNotInThisDay = true;
        }
      }
      return disabledFromPLi === false && disabledFromNotInThisDay === false;
    },
    "invalid-remaining-value": (params) => {
      const val = params?.value;
      return isNaN(val);
    },
    "edit-progress-disabled": (params) => {
      const pli = params?.data;
      const disabledFromPLi = pli?.subPli && pli?.subPli?.length > 0;
      let disabledFromNotInThisDay = false;
      if (dayNumber) {
        if (!pli?.days?.includes("D" + dayNumber)) {
          disabledFromNotInThisDay = true;
        }
      }
      return disabledFromPLi || disabledFromNotInThisDay;
    },
  };
};

export const remainingCell = {
  "negative-remaining-value": (params) => {
    const val = params?.value;
    if (isNaN(val)) return false;
    return val < 0;
  },
  "invalid-remaining-value": (params) => {
    const val = params?.value;
    return isNaN(val);
  },
};

export const totalProgressStyle = (serviceId) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  return totalProgressStyle_byDimension({ d1, d2 });
};

export const totalProgressStyle_byDimension = ({ d1, d2 }) => ({
  [progressStatuses[0]]: (params) =>
    progressPercentage_valueGetter(params, { d1, d2 }) <= 0,
  [progressStatuses[1]]: (params) => {
    let progressPercentage = progressPercentage_valueGetter(params, { d1, d2 });
    return progressPercentage > 0 && progressPercentage < 100;
  },
  [progressStatuses[2]]: (params) =>
    progressPercentage_valueGetter(params, { d1, d2 }) === 100,
  [progressStatuses[3]]: (params) =>
    progressPercentage_valueGetter(params, { d1, d2 }) > 100,
});

export const totalPercentageStyle = {
  [progressStatuses[0]]: (params) => parseInt(params.value) <= 0,
  [progressStatuses[1]]: (params) =>
    parseInt(params.value) > 0 && parseInt(params.value) < 100,
  [progressStatuses[2]]: (params) => parseInt(params.value) === 100,
  [progressStatuses[3]]: (params) => parseInt(params.value) > 100,
  "progress-percentage": () => true,
};

export const remainingStyle = (serviceId) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  return {
    [progressStatuses[3]]: (params) =>
      100 - progressPercentage_valueGetter(params, { d1, d2 }) < 0,
    [progressStatuses[2]]: (params) =>
      100 - progressPercentage_valueGetter(params, { d1, d2 }) === 0,
    [progressStatuses[1]]: (params) => {
      let progressPercentage =
        100 - progressPercentage_valueGetter(params, { d1, d2 });
      return progressPercentage > 0 && progressPercentage < 100;
    },
    [progressStatuses[0]]: (params) =>
      100 - progressPercentage_valueGetter(params, { d1, d2 }) >= 100,
  };
};

export const remainingPercentageStyle = {
  [progressStatuses[3]]: (params) => parseInt(params.value) < 0,
  [progressStatuses[2]]: (params) => parseInt(params.value) === 0,
  [progressStatuses[1]]: (params) =>
    parseInt(params.value) > 0 && parseInt(params.value) < 100,
  [progressStatuses[0]]: (params) => parseInt(params.value) >= 100,
  "remaining-percentage": () => true,
};

export const getScheduleRowClass = (params, serviceId) =>
  params.data && hasItemYetToSchedule(params.data, serviceId)
    ? "normal-row"
    : "disabled-row";
