import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const documentation = (
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  return {
    docType: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docType"
      );

      return {
        title: "Documentation",
        BodyComponent: "StreightProgressBar",
        type: "StreightProgressBar",
        BodyComponentParams: {
          table: "Documentation",
          field: "docType",
          title: "Documentation Type",
          subtitle: "",
          content: Object.entries(data)?.map(([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (value / updatedDynamicStates?.[IdHandler]?.length) *
              100
            ).toFixed(1),
            showInfo: true,
            status: key,
            done: value,
          })),
        },
        id: IdHandler,
        width: "390px",
      };
    }, //doctype

    docStatus: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docStatus"
      );

      return {
        title: "Documentation",
        BodyComponent: "StreightProgressBar",
        type: "StreightProgressBar",
        BodyComponentParams: {
          table: "Documentation",
          field: "docStatus",
          title: "Documentation Status",
          subtitle: "",
          content: Object.entries(data)?.map(([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (value / updatedDynamicStates?.[IdHandler]?.length) *
              100
            ).toFixed(1),
            showInfo: true,
            status: key,
            done: value,
          })),
        },
        id: IdHandler,
        width: "390px",
      };
    }, //docStatus
  }; //Documentation
};
