import { useState } from "react";

import { FunctionList } from "../FunctionPicker/components";

import styles from "./AddFilterButton.module.css";

const options = [{ value: "AND" }, { value: "OR" }];

const AddFilterButton = ({
  addFilter = () => {},
  text,
  globalOperator = "",
  disabled = false,
  filterIndex,
  conditionId,
}) => {
  const [isListOpen, setIsListOpen] = useState(false);

  const onListItemClickHandler = (value) => {
    addFilter(globalOperator, value, filterIndex, conditionId);
    setIsListOpen(false);
  };

  return (
    <div className={styles["add-filter-button-container"]}>
      <div className={styles["line-button-container"]}>
        <button
          className={styles["offset-button"]}
          onClick={() => setIsListOpen((prev) => !prev)}
          disabled={disabled}
        >
          {text}
        </button>
      </div>
      {isListOpen && (
        <div className={styles["button-list"]}>
          <FunctionList
            {...{
              data: options,
              onListItemClickHandler,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AddFilterButton;
