import React, { createContext, useState, useContext } from "react";

const FilePreviewModalContext = createContext();

export const FilePreviewModalProvider = ({ children }) => {
  const [selectedFile, setSelectedFile] = useState({});
  const [filePreview, setFilePreview] = useState(false);

  return (
    <FilePreviewModalContext.Provider
      value={{ selectedFile, setSelectedFile, filePreview, setFilePreview }}
    >
      {children}
    </FilePreviewModalContext.Provider>
  );
};

export const useFilePreviewModal = () => {
  return useContext(FilePreviewModalContext);
};
