import React from "react";
import { InfoWindowF, OverlayViewF } from "@react-google-maps/api";

import { Marker } from "../../../../../../../commonComponents";

/**
 *
 * @param {{
 * position: {lat: number, lng: number},
 * address: string,
 * key: string | number,
 * color: string | "#1264a3",
 * onClick: () => void,
 * entryType: string,
 * }} param0
 * @returns
 */

function EntryMarker({
  key,
  address,
  onClick,
  position,
  entryType,
  infoWindowToggle,
  color = "#1264a3",
}) {
  return position?.lat ? (
    <OverlayViewF position={position} mapPaneName={"overlayMouseTarget"}>
      {infoWindowToggle ? (
        <InfoWindowF
          visible={infoWindowToggle}
          zIndex={1000}
          position={position}
        >
          {entryType ? <b style={{ fontSize: 16 }}>{entryType}</b> : null}
        </InfoWindowF>
      ) : null}
      <div className="markerWrapper">
        <Marker
          key={key}
          address={address}
          coordinates={position}
          color={color}
          onClick={onClick}
          showIcon={true}
        />
      </div>
    </OverlayViewF>
  ) : null;
}

export default EntryMarker;
