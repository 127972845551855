import React, { useState } from "react";
import { useSelector } from "react-redux";

import { mapData } from "../../helpers";
import { useStepData } from "../../hooks";
import { generateInputFields } from "./helpers";
import { NextStepInputs } from "../NextStepInputs";
import { getTaxRate } from "./utils";

const Opportunity = ({ inputData, setInputData }) => {
  const [requiredBidDueDate, setRequiredBidDueDate] = useState(false);
  const { programFields } = useSelector((state) => state.programFields);

  const { opportunities, projectExecutives } = useStepData("Opportunities");

  const options = programFields?.reduce(
    (acc, { fieldName, fieldOptions }) => ({
      ...acc,
      [fieldName]: fieldOptions,
    }),
    {}
  );
  const laborTypes = mapData(options["Labor Types"]);
  const salesPerson = mapData(options["Sales person"]);
  const insuranceTypes = mapData(options["Insurance Types"]);
  const opportunitySources = mapData(options["Opportunity Source"]);
  const taxExemptOptions = mapData(options["Tax Exempt options"], "id", "name");

  const projectManagersOptions = mapData(
    options["Project Managers"],
    "identityId",
    "nameOfUser"
  );
  const opportunityStage = mapData(
    options["Statuses Of Categories"]["Opportunities"],
    "statusId",
    "statusName"
  );

  const onTaxSelect = async () => {
    if (!!inputData?.jobSiteAddress) {
      const taxRate = await getTaxRate(inputData.jobSiteAddress);
      setInputData((prev) => ({ ...prev, ["opportunityTaxRate"]: taxRate }));
    }
  };

  const inputFields = generateInputFields({
    inputData,
    laborTypes,
    salesPerson,
    onTaxSelect,
    opportunities,
    insuranceTypes,
    opportunityStage,
    taxExemptOptions,
    projectExecutives,
    requiredBidDueDate,
    opportunitySources,
    projectManagersOptions,
  });

  const toggleRequired = (key) => {
    setInputData((prev) => ({ ...prev, [key]: undefined }));
  };

  const checkBoxes = [
    {
      itemName: "bidDueDate",
      isChecked: requiredBidDueDate,
      checkBoxName: "Not Applicable",
      onCheckboxChange: () => {
        toggleRequired("bidDueDate");
        setRequiredBidDueDate((prev) => !prev);
      },
    },
  ];

  return (
    <NextStepInputs
      {...{
        inputData,
        setInputData,
        inputFields,
        checkBoxes,
      }}
    />
  );
};

export default Opportunity;
