import { API } from "aws-amplify";
import { saveNotification } from "../utils/save";
import { showErrorMsg, showInfoMsg } from "../../../../../utils";
import { deleteNotification } from "../../../../../actions/notificationsActions";

export const onDeleteConfirmation = async (
  setAnimatedClassName,
  id,
  cognitoUserId,
  authenticatedUser,
  notificationObject,
  apiName,
  path,
  dispatch,
  type
) => {
  try {
    setAnimatedClassName(["moveRight", id]);

    const deleteNotificationAPI = async () => {
      const response = await API.del(apiName, `/${path}/${id}`);
      if (response.status === true) {
        showInfoMsg({ content: "Notification was successfully deleted." });
        setAnimatedClassName([]);
        dispatch(deleteNotification(id));
      } else {
        showErrorMsg({ content: `Error deleting ${type}` });
      }
    };

    if (Object.keys(cognitoUserId).length > 1) {
      const { [authenticatedUser.sub]: myCognito, ...otherCognitos } =
        cognitoUserId;

      const saveResponse = await saveNotification({
        id,
        body: {
          cognitoUserId: otherCognitos,
          notificationObject,
        },
      });

      if (saveResponse.status === true) {
        await deleteNotificationAPI();
      } else {
        showErrorMsg({ content: `Error deleting ${type}` });
      }
    } else {
      await deleteNotificationAPI();
    }
  } catch (error) {
    console.error(error);
    showErrorMsg({ content: `Error deleting ${type}` });
  }
};
