import FormItem from "../../components/FormItems/FormItem";
import StyledButton from "../../components/StyleButton/StyledButton";
import _ from "lodash";
import "./DateRange.scss";
import { validateForm } from "../../../Fleet/utils";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";
import { parseInTz } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
const DateRange = (prop) => {
  const {
    params: { rowData },
    form,
    getEditedValue,
    setEditMode,
    setFormChanged = () => {},
    getFormChanged = () => {},
  } = prop;
  let formList = {};
  const { isDarkMode } = useSelector((state) => state.darkMode);
  //saved previus data
  const [prev, setPrev] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  useEffect(() => {
    setPrev(() => _.cloneDeep(prop));
  }, [prop]);
  ///  finished
  document.dateRangeValues = rowData?.reduce((ac, { formItemName, value }) => {
    if (ac.formItemName) {
      formList[ac.formItemName] = ac.value;
    }
    formList[formItemName] = value;
    return formList;
  });
  const [values, setValues] = useState(
    rowData?.reduce((ac, { formItemName, value }) => {
      if (ac.formItemName) {
        formList[ac.formItemName] = ac.value;
      }
      formList[formItemName] = value;
      return formList;
    })
  );
  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };
  function disabledStartDate(current) {
    let formItem = rowData.find(({ type }) => type === "End").formItemName;
    const endDay = values[formItem];
    return current && current > dayjs(endDay).endOf("day");
  }
  function disabledEndDate(current) {
    let formItem = rowData.find(({ type }) => type === "Start").formItemName;
    const startDay = values[formItem];
    return current && current < dayjs(startDay).startOf("day");
  }
  return (
    <div className="InformationBodyEdit">
      <div className="DateRangeBodyElementContainer">
        {rowData.map(({ label, value, formItemName, type }) => (
          <>
            <span className="InformationBodyElementContainerTitle">
              {label}
            </span>
            {type === "Start" ? (
              <DatePicker
                className="EditDatePickerComponent"
                popupClassName={isDarkMode && "darkDateDropDown"}
                disabledDate={disabledStartDate}
                defaultValue={
                  dayjs(value).toString() !== "Invalid Date"
                    ? dayjs(value)
                    : null
                }
                onChange={(e) => {
                  setFormChanged(true);
                  setValues({
                    ...values,
                    [formItemName]: e?.toString()
                      ? parseInTz(dayjs(e).format())
                      : "",
                  });
                }}
                format="MM/DD/YYYY"
              />
            ) : (
              <DatePicker
                className="EditDatePickerComponent"
                disabledDate={disabledEndDate}
                popupClassName={isDarkMode && "darkDateDropDown"}
                defaultValue={
                  dayjs(value).toString() !== "Invalid Date"
                    ? dayjs(value)
                    : null
                }
                onChange={(e) => {
                  setFormChanged(true);
                  setValues({
                    ...values,
                    [formItemName]: e?.toString()
                      ? parseInTz(dayjs(e).format())
                      : "",
                  });
                }}
                format="MM/DD/YYYY"
              />
            )}
          </>
        ))}
      </div>
      <div className="confirmContainer">
        <MondayButton
          onClick={(res) => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          onClick={(res) => {
            getEditedValue(
              values,
              undefined,
              document.dateRangeValues,
              prev,
              keys(values).map((e) => {
                prop.params.rowData.find(
                  ({ formItemName }) => formItemName === e
                ).value = values[e];
                return prop;
              })[0]
            );
            setEditMode(false);
          }}
          className="confirmButton"
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default DateRange;
