import React from "react";
import { Dropdown } from "antd";
import { useSelector } from "react-redux";

import "./custom-dropdown.scss";

const CustomDropdown = ({ items, trigger, placement, children }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const dropdownItems =
    items?.length === 0
      ? [{ key: "no-data", label: <div>No data</div> }]
      : items;

  return (
    <Dropdown
      menu={{ items: dropdownItems }}
      placement={placement}
      trigger={trigger}
      overlayClassName={`custom-dropdown-container ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      {children}
    </Dropdown>
  );
};

export default CustomDropdown;
