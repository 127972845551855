import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const documentation = (IdHandler, updatedDynamicStates) => {
  return {
    docType: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docType"
      );

      return {
        title: "Documentation Type",
        BodyComponent: "BarChart",
        type: "BarChart",
        BodyComponentParams: {
          table: "Documentation",
          field: "docType",

          labels: Object.keys(data),
          dataSets: [
            {
              label: "Documentation",
              data: Object.values(data),

              backgroundColor: Object.keys(data).map(() => getRandomColor()),

              borderWidth: 0,
            },
          ],
        },
        id: IdHandler,
        width: "790px",
      };
    }, //doctype

    docStatus: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docStatus"
      );

      return {
        title: "Documentation Status",
        BodyComponent: "BarChart",
        type: "BarChart",
        BodyComponentParams: {
          table: "Documentation",
          field: "docStatus",
          labels: Object.keys(data),
          dataSets: [
            {
              label: "Documentation",
              data: Object.values(data),

              backgroundColor: Object.keys(data).map(getRandomColor),

              borderWidth: 0,
            },
          ],
        },
        id: IdHandler,
        width: "790px",
      };
    }, //docStatus
  }; //Documentation
};
