export const defaultColors = ["#f8f8fa"];
// export const defaultColors = [
//     "#A81563", //magenta
//     "#7e3794", //purple
//     "#A62A21", //brick red
//     "#0B51C1", //blue
//     "#0f9d58", //light green
//     "#3A6024", //green
//     "#B3003C", //red
//     "#9e9d24", //lime
//     "#00acc1", //teal
//     "#5c6bc0", //indigo
//     "#F28C28", //orange
//     "#ff7b54", //coral
//     "#f4b400", //yellow
//     "#00B377", //mint
//   ];
