
export const columnNames = [

  {
    fieldName: "label",
    fieldTitle: "Label",
  },
  {
    fieldName: "previousValue",
    fieldTitle: "Previous Value",
  },
  {
    fieldName: "updatedValue",
    fieldTitle: "Updated Value",
  },
  {
    fieldName: "member",
    fieldTitle: "User",
  },
  {
    fieldName: "updatedAt",
    fieldTitle: "Date & Time",
  },
]

