import {
  getNotificationsRequest,
  getNotificationsSuccess,
  setNotifications,
} from "../../../../../actions/notificationsActions";
import { fetchData } from "../../../../../utils";
import { getAll } from "../NotificationData";

export const fetchNotifications = (
  authenticatedUser,
  notificationSettings,
  dispatch,
  filtersStructured,
  lastEvaluatedKey,
  filterBy
) => {
  dispatch(getNotificationsRequest());

  fetchData({
    endpoint: "notifications",
    query: "notifications",
    queryStringParams: {
      queryStringParameters: {
        withPagination: "true",
        filters: filtersStructured ? JSON.stringify(filtersStructured) : null,
        ExclusiveStartKey: lastEvaluatedKey?.notificationId
          ? JSON.stringify(lastEvaluatedKey)
          : null,
      },
    },
  })
    .then(({ LastEvaluatedKey, notifications }) => {
      dispatch(
        setNotifications({
          notifications: getAll({
            notifications,
            authenticatedUser,
            notificationSettings,
            filterBy,
          }),
          authenticatedUser,
          notificationSettings,
          lastEvaluatedKey:
            LastEvaluatedKey?.notificationId && LastEvaluatedKey?.createdAt
              ? {
                  notificationId: LastEvaluatedKey?.notificationId,
                  createdAt: LastEvaluatedKey?.createdAt,
                }
              : null,
        })
      );

      dispatch(getNotificationsSuccess());
    })
    .catch((err) => {
      console.error("error while fetching notifications", err);
    });
};
