import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { DEG_TIME_FORMAT } from "../utils/cellFunctions";

export const shiftsColumnDef = [
  {
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    maxWidth: 50,
  },
  {
    headerName: "Employee Id",
    field: "employeeId",
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Employee",
    field: "employeeFullName",
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Role",
    field: "employeeRole",
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Clock In",
    field: "firstClockIn",
    cellRenderer: ({ value }) => {
      return dayjsNY(value).format(DEG_TIME_FORMAT);
    },
  },
  {
    headerName: "Clock In Jobsite Address",
    field: "inJobsiteId",
    cellRenderer: ({ context, value }) => {
      const jobsite = context.jobsites.find(({jobsiteId}) => value === jobsiteId)?.jobAddress;

      return jobsite || null;
    },
  },
  {
    headerName: "Clock Out",
    field: "clockOut",
    cellRenderer: ({ value }) => {
      return dayjsNY(value).format(DEG_TIME_FORMAT);
    },
  },
  {
    headerName: "Clock Out Jobsite Address",
    field: "outJobsiteId",
    cellRenderer: ({ context, value }) => {
      const jobsite = context.jobsites.find(({jobsiteId}) => value === jobsiteId)?.jobAddress;
      
      return jobsite || null;
    },
  },
];
