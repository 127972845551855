import { useEffect, useState } from "react";
import { SmallLoadableComp } from "../../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";

interface Props {
  thumbnailLink: string;
  setActiveSlide: (index: number) => void;
  index: number;
  size: number;
  id: string;
}

export default function ThumbnailLoader(props: Props) {
  const { thumbnailLink, setActiveSlide, index, id, size } = props;

  return (
    <img
      src={thumbnailLink}
      alt={"image"}
      onClick={() => {
        setActiveSlide(index);
      }}
      key={id}
      loading="lazy"
      style={{
        transform: `scale(${size})`,
      }}
      referrerPolicy="no-referrer"
    />
  );
}
