import { LockTwoTone } from "@ant-design/icons";
import "./NoAccess.scss";

export const NoAccessPage = ({
  sizeOfIcon = "100px",
  description = "",
  title = "",
}) => {
  const dynamicHeightForCenter = `${1165 - window.innerHeight}px`;
  return (
    <div
      className="noAccessPage"
      style={{ height: `calc(100vh - ${dynamicHeightForCenter})` }}
    >
      <div className="title">{title}</div>
      <div className="description">{description}</div>
      <LockTwoTone twoToneColor="#faad14" style={{ fontSize: sizeOfIcon }} />
    </div>
  );
};
