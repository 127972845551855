import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
import { months } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const fleetInspections = ({ tables, programFields }) => {
  const inspectionStatuses = programFields?.find(
    (el) => el?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Inspections;

  return {
    inspectedBy: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"] || [],
        "inspectedBy"
      );

      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //inspectedBy
    driverName: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"]?.map((el) => el?.driverInfo) || [],
        "driver"
      );

      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //driverName
    createdAt: () => ({
      labels: months,
      data: (() => {
        let creationsDate = [];
        for (let i = 0; i < 12; i++) {
          creationsDate = [
            ...creationsDate,
            tables?.["Fleet Inspections"]
              ?.map((a) => a?.createdAt)
              ?.filter(
                (b) =>
                  b >= getMonthsTimestamps()[i] &&
                  b <= getMonthsTimestamps("end")[i]
              )?.length,
          ];
        }
        return creationsDate;
      })(),
      backgroundColor: months.map((a) => getRandomColor()),
    }), //createdAt

    fleetName: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"] || [],
        "fleetName"
      );

      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //fleetName

    inspectionStatus: () => ({
      labels: inspectionStatuses?.map((el) => el?.statusName),
      data: inspectionStatuses?.map(
        (status) =>
          (tables?.["Fleet Inspections"] || [])?.map(
            (el) => el?.inspectionStatus === status?.statusName
          )?.length
      ),
      backgroundColor: inspectionStatuses?.map(
        (el) => el?.statusName || getRandomColor()
      ),
    }), //inspectionStatus

    inspectionType: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"] || [],
        "inspectionType"
      );
      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //inspectionType

    mechanic: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"] || [],
        "mechanic"
      );

      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    },
  };
}; //Inspections
