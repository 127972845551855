import { API } from "aws-amplify";
import { apiRoutes } from "../../../../SidebarPages/Fleet/utils";
import { deleteAllRelatedToDos } from "../../../../SidebarPages/ToDos/forms/helpers";
import dayjs from "dayjs";

/**
 * Deletes a request.
 *
 * @param {WebSocket} ws - The WebSocket connection.
 * @param {string} requestId - The ID of the request to delete.
 * @param {string} recordName - The name of the record associated with the request.
 * @param {string} requestType - The type of the request.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Function} setWarningModal - The function to set the warning modal visibility.
 * @param {Function} handleCloseModal - The function to handle closing the modal.
 * @param {Function} saveAddedLogs - The function to save added logs.
 * @returns {Promise<void>} A promise that resolves when the request is deleted.
 */
async function deleteRequest({
  ws = null,
  requestId = "",
  recordName = "",
  requestType = "",
  userConfiguration = {},
  setWarningModal = () => {},
  handleCloseModal = () => {},
  saveAddedLogs,
}) {
  await API.del(
    apiRoutes.formRequests,
    `/${apiRoutes.formRequests}/${requestId}`
  )
    .then(async () => {
      await ws.send(JSON.stringify({ request: "delete", body: { requestId } }));

      await deleteAllRelatedToDos({ recordId: requestId });

      setWarningModal({ visible: false });

      await saveAddedLogs({
        recordId: requestId,
        recordName,
        category: "Approvals",
        actionType: "Delete",
        topic: requestType,
      });

      handleCloseModal();
    })
    .catch((err) => {
      handleCloseModal();
      console.error(err);
    });
}

export default deleteRequest;
