import React from "react";
import "./3LoadingDots.scss";

export const LoadingDots = ({ style }) => (
  <span className={"loading-dots"} style={style || {}}>
    <b>.</b>
    <b>.</b>
    <b>.</b>
  </span>
);

export const CircleSpinner = ({ style }) => {
  return <span class="circle-spinner" style={style || {}}></span>;
};

export const OvalSpinner = ({ style = {}, color = "#f9db55" }) => {
  let styleObj = {
    ...style,
    border: `5px solid ${color}80`,
    borderBottomColor: `${color}`,
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span class="spinner" style={styleObj}></span>
    </div>
  );
};
