//@ts-check
import React from "react";
import { useProgressContext } from "../../../commonComponents/ProgressComponent/context/ProgressContext";
import { useInitialBillContext } from "./context/InitialBillProvider";
import { ProgressComponent } from "../../../commonComponents";

const wrapperStyle = { height: "100%" };

const BillWrapper = ({ children, closeModal }) => {
  const {
    creationProgresses,
    visibleCreationProgress,
    setVisibleCreationProgress,
  } = useProgressContext();

  const { billData } = useInitialBillContext();

  return (
    <div style={wrapperStyle}>
      {children}
      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Bills",
            actionType: !!billData ? "Update" : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              closeModal();
            },
          }}
        />
      )}
    </div>
  );
};

export default BillWrapper;
