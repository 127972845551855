import "./ProfileImage.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Button, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { UploadIcon } from "../../../../../../../SidebarPages/Documentation/View/assets";
import { MondayButton } from "../../../../../../../commonComponents";
import { UserIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { useGoogleSignIn } from "../../../../../../../../hooks/useGoogleSignIn";
import { LogoutIcon } from "../../../../AllUsers/assets";
import ProfileColorModal from "./ProfileColorModal/ProfileColorModal";
import LogOutModal from "./LogOutModal/LogOutModal";
import { useUserProfileContext } from "../../../UserProfileContext";
import FileUploader from "../../../../../../../commonComponents/FileUploader/FileUploader";
import { nameFirstDigits } from "../../../../../../../../utils/nameFirstDigits";
import { HiddenIcon } from "src/components/SidebarPages/DynamicView/src";
import GoogleScopesInfoModal from "./GoogleScopesInfo/GoogleScopesInfoModal";
import { useEditLogs } from "../../../../../../../../hooks";

const ProfileImage = () => {
  const { profileChanges, setProfileChanges } = useUserProfileContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);
  const {
    preferences: { preferences },
  } = useSelector((state) => state.preferences);
  const driveRequest = driveApi({ accessToken });

  const { onGoogleSignIn, onGoogleSignOut, userDetails } = useGoogleSignIn();

  const [userImage, setUserImage] = useState("");
  const [changeColorModal, setChangeColorModal] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [googleScopesModalVisible, setGoogleScopesModalVisible] =
    useState(false);

  const getUserImage = (fileId) => {
    !!fileId
      ? driveRequest
          .getImageSrc(fileId)
          .then((image) => setUserImage(image.src))
      : setUserImage("");
  };

  useEffect(() => {
    accessToken &&
      getUserImage(
        profileChanges?.userImage ?? userConfiguration?.googleDriveFileId
      );
  }, [
    userConfiguration?.googleDriveFileId,
    accessToken,
    profileChanges?.userImage,
  ]);

  const onUploadImage = (data) => {
    const { id } = data.docs[0];

    setProfileChanges((prev) => ({ ...prev, userImage: id }));
  };

  const onSignIn = () => {
    onGoogleSignIn();
  };

  return (
    <div className={`profile-image ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="user-image">
        {!!userImage ? (
          <div className="image-holder">
            <div className="image">
              <img width="100%" height="100%" src={userImage} />
            </div>
            <div className="remove-img-btn">
              <Tooltip
                placement="right"
                trigger="hover"
                title="Remove Profile Picture"
              >
                <Button
                  type="primary"
                  size="small"
                  shape="circle"
                  danger
                  data-testid="remove-img-btn-testid"
                  onClick={() => {
                    setUserImage("");
                    onUploadImage({ docs: [{ id: "" }] });
                  }}
                  icon={<CloseOutlined />}
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          <Tooltip title="Change Color">
            <Avatar
              style={{
                backgroundColor:
                  profileChanges?.profileColor ||
                  preferences?.profileColor ||
                  "#4895ef",
              }}
              size="large"
              onClick={() => {
                setChangeColorModal(true);
              }}
            >
              {nameFirstDigits(userConfiguration.nameOfUser)}
            </Avatar>
          </Tooltip>
        )}
      </div>
      <div className="left-buttons">
        <FileUploader
          onlyTypes={["image/"]}
          customBtn={
            <MondayButton
              {...{ className: "mondayButtonBlue", Icon: <UploadIcon /> }}
            >
              Upload Photo
            </MondayButton>
          }
          onPickerSuccess={onUploadImage}
        />

        {userDetails ? (
          <div className="google-sign-out">
            <MondayButton
              {...{
                className: "mondayButtonRed",
                Icon: <LogoutIcon />,
                onClick: () => setLogOutModal(true),
              }}
            >
              Sign Out
            </MondayButton>
            <MondayButton
              {...{
                tooltipKey: "showGoogleAccess",
                className: "mondayButtonBlue",
                Icon: <HiddenIcon />,
                onClick: () => setGoogleScopesModalVisible(true),
              }}
            />
          </div>
        ) : (
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              Icon: <UserIcon />,
              onClick: onSignIn,
            }}
          >
            Sign In
          </MondayButton>
        )}
      </div>

      {changeColorModal && (
        <ProfileColorModal {...{ changeColorModal, setChangeColorModal }} />
      )}

      {logOutModal && (
        <LogOutModal {...{ logOutModal, setLogOutModal, onGoogleSignOut }} />
      )}

      {googleScopesModalVisible && (
        <GoogleScopesInfoModal
          {...{ googleScopesModalVisible, setGoogleScopesModalVisible }}
        />
      )}
    </div>
  );
};

export default ProfileImage;
