import React from "react";
import { useUploadRecords } from "../hooks";

const UploadRecordsContext = React.createContext();

export const UploadRecordsProvider = ({
  children,
  title,
  setIsOpen,
  updateProgressStatus,
  setVisibleCreationProgress,
}) => {
  const uploadRecords = useUploadRecords({
    title,
    setIsOpen,
    updateProgressStatus,
    setVisibleCreationProgress,
  });

  return (
    <UploadRecordsContext.Provider value={{ ...uploadRecords }}>
      {children}
    </UploadRecordsContext.Provider>
  );
};

export const useUploadRecordsContext = () => {
  const context = React.useContext(UploadRecordsContext);
  if (!context) {
    throw new Error(
      "useUploadRecordsContext must be used within a UploadRecordsProvider"
    );
  }
  return context;
};
