import { message } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";

import PayrollContext from "../../PayrollContext";
import { useEditLogs } from "../../../../../hooks";
import PayrollLayout from "../../Layout/PayrollLayout";
import { LoadableComp } from "../../../../SidebarPages/XComponents";
import { DownloadIcon } from "../../../../SidebarPages/BasePage/src";
import { Tick } from "../../../Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { columnDefs, chartingOptions, gridFilters } from "./AgGridData";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { WarningTriangle } from "../../../../SidebarPages/DynamicView/src";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { UpdateCrew } from "../../../Settings/settingsComponents/Crews/Components/UpdateCrew/UpdateCrew";
import DeleteEmployeeModal from "../../../../SidebarPages/HrManagement/Pages/Employee/components/DeleteEmployeeModal";
import UploadEmployeesModal from "../../../Settings/settingsComponents/Crews/Components/UploadEmployees/UploadEmployeesModal";
import UpdateEmployeeModal from "../../../../SidebarPages/HrManagement/Pages/Employee/components/UpdateEmployee/UpdateEmployeeModal";

import "../../Payroll.scss";

function EmployeesTab({ accessRight }) {
  const [{ userConfiguration }, { isDarkMode }] = useSelector((state) => [
    state.userConfig,
    state.darkMode,
  ]);

  const {
    employeesList,
    crewTeams,
    isFromHrManagement = false,
    salaryAccess = {},
  } = useContext(PayrollContext);

  const [gridApi, setGridApi] = useState();
  const [loading, setLoading] = useState(true);
  const [rowToEdit, setRowToEdit] = useState(false);
  const [rowData, setRowData] = useState(employeesList);
  const [rowToDelete, setRowToDelete] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const { saveAddedLogs } = useEditLogs();

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  const getCrews = async () => {
    return await API.get("crews", "/crews")
      .then((res) => {
        setRowData(res);
      })
      .catch((e) => {
        console.error(e);
        message.error("Error while getting crews.");
      });
  };

  function recordClicked(rowParams) {
    setRowToEdit(rowParams);
    setUpdateModalVisible(true);
  }

  function handleUpdateModalVisible() {
    setUpdateModalVisible((prevState) => !prevState);
    setRowToEdit(null);
  }

  const deleteCrew = async (id) => {
    message.loading({ content: "Deleting Employee...", duration: 0, key: "1" });
    const selectedForeman = rowData?.find(
      (res) => res?.crewId === rowToDelete?.foreman
    );

    if (!!selectedForeman) {
      selectedForeman.members = selectedForeman?.members?.filter((val) => {
        return val?.crewId !== id;
      });
    }

    return await API.del("crews", `/crews/${id}`)
      .then(async (r) => {
        setRowData(rowData?.filter((el) => el?.crewId !== id));
        if (!!selectedForeman) {
          await API.patch("crews", `/crews/${selectedForeman?.crewId}`, {
            body: {
              members: selectedForeman?.members || [],
            },
          });
        }
      })
      .then((res) => {
        broadcastNotification(
          "20",
          "onCrewDeletion",
          [
            {
              common: userConfiguration.nameOfUser,
              commonNext: rowToDelete?.crewName,
            },
            {
              userName: userConfiguration.nameOfUser,
              currentUser: userConfiguration.cognitoUserId,
            },
          ],
          ""
        );
        saveAddedLogs({
          recordId: rowToDelete?.crewId,
          recordName: rowToDelete?.crewName,
          category: "Crews",
          actionType: "Delete",
        });

        setRowToDelete(false);
        message.success({
          content: `${rowToDelete?.crewName} deleted successfully`,
          key: "1",
        });
      })
      .catch((e) => {
        console.error(e);
        message.error({ content: "Something went wrong!", key: "1" });
      });
  };

  useEffect(() => {
    if (employeesList?.length && loading) {
      setLoading(false);
      setRowData(employeesList);
    }
  }, [employeesList, loading]);

  return (
    <>
      <LoadableComp
        componentTestId={"employees-tab-loading"}
        loading={loading || !rowData?.length}
      >
        <></>
      </LoadableComp>
      <PayrollLayout
        {...{
          title: "Employees",
          rowData,
          chartingOptions,
          suppressDoubleClickEdit: true,
          getGridApi: (param) => {
            setGridApi(param);
          },
          columnDefs: columnDefs({
            columnAccess: accessRight?.children || [],
            writeAccess: accessRight?.write,
            salaryAccess,
            recordClicked,
            setRowToDelete,
            isDarkMode,
            isFromHrManagement,
          }),
          hasNew: accessRight?.write,
          paginationPageSize,
          additionalActionsButtons: accessRight.write
            ? [
                <MondayButton
                  onClick={() => setUploadVisible(true)}
                  className="mondayButtonBlue"
                  Icon={<DownloadIcon />}
                  data-testid="employees-upload-btn"
                >
                  Upload
                </MondayButton>,
              ]
            : [],
          gridFilters,
          dataIdKey: "crewId",
          context: {
            crewTeams,
          },
        }}
      />
      {rowToEdit && rowToEdit?.data?.crewStatus && (
        <UpdateCrew
          {...{
            title: "Employees",
            recordSelected: rowToEdit,
            updateModalVisible,
            handleUpdateModalVisible,
            refreshData: getCrews,
            crews: rowData,
            refreshTable: (res) => gridApi.applyTransaction({ update: [res] }),
          }}
        />
      )}
      {rowToEdit &&
        (rowToEdit?.data?.engineerId ||
          rowToEdit?.data?.architectId ||
          rowToEdit?.data?.driverId) && (
          <UpdateEmployeeModal
            {...{
              title: "Employees",
              recordData: rowToEdit,
              updateEmployeeModalVisible: updateModalVisible,
              handleUpdateModalVisible,
              refreshData: getCrews,
              employeeData: rowData,
              refreshTable: (res) =>
                gridApi.applyTransaction({ update: [res] }),
            }}
          />
        )}
      {rowToDelete && rowToDelete?.crewId && (
        <WarningModal
          visible={rowToDelete}
          setVisible={setRowToDelete}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to delete {rowToDelete?.crewName}?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setRowToDelete(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
                data-testid="close-warning-btn"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => deleteCrew(rowToDelete?.crewId)}
                Icon={<Tick width={17} height={17} />}
                data-testid="delete-employee-btn"
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {rowToDelete &&
        (rowToDelete?.engineerId ||
          rowToDelete?.architectId ||
          rowToDelete?.driverId) && (
          <DeleteEmployeeModal
            {...{
              rowToDelete,
              setRowToDelete,
              rowData,
              setRowData,
              refreshTable: () =>
                gridApi.applyTransaction({ remove: [rowToDelete] }),
            }}
          />
        )}
      {uploadVisible && accessRight.write && (
        <UploadEmployeesModal
          {...{
            open: uploadVisible,
            onCancel: () => setUploadVisible(false),
            employeesList: rowData,
            setEmployeesList: setRowData,
          }}
        />
      )}
    </>
  );
}

export default EmployeesTab;
