import types from "../types";

const initialState = {
  isDarkMode: JSON.parse(localStorage.getItem("darkmode"))
    // !!JSON.parse(localStorage.getItem("darkmode")) !== null
    //   ? !!JSON.parse(localStorage.getItem("darkmode"))
    //   : window.matchMedia("(prefers-color-scheme: dark)")?.matches,
};

const darkModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DARK_MODE:
      return {
        ...state,
        isDarkMode: action.payload,
      };
    default:
      return state;
  }
};

export default darkModeReducer;
