export const applications = (
  programFields,
  updatedDynamicStates,
  IdHandler,
  applicationsData
) => {
  const applicationStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    applicationStatus: () => ({
      title: "Requisition - Total Amount",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Applications",
        field: "applicationStatus",
        content: applicationStatus?.statusName?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: applicationsData?.length,
          taskDone: applicationsData?.filter(
            (a) => a?.applicationStatus === status
          )?.length,
          color: applicationStatus?.statusColor[index],
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Application`,
          contentsHandler: ` ${status} Applications`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  };
};
