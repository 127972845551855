import React from "react";
import { Link } from "react-router-dom";
import AccountingWriteMode from "../../../../../commonComponents/AccountingWriteMode/AccountingWriteMode";
import { XIcon } from "../../../../Communication/assets";
import { useRedux } from "../../../../hooks";
import { useInitialInvoiceContext } from "../context/InvoiceInitialContext";

const InvoiceHeader = ({ onCancel, handleCancel }) => {
  const [isWritable, setIsWritable] = useRedux("invoiceIsWritable");
  const [formChanged] = useRedux("invoiceFormChanged");

  const { invoiceData, projectData } = useInitialInvoiceContext();

  return (
    <div className="invoice-modal-header">
      {invoiceData && (
        <AccountingWriteMode
          {...{
            isWritable,
            disableSwitch: invoiceData?.charges?.length > 0,
            disabilityReason:
              invoiceData?.charges?.length > 0 ? (
                <div>
                  This invoice is included in Credit Memo:{" "}
                  {invoiceData?.charges?.map(
                    ({ chargeId, chargeNumber }, i) => (
                      <Link
                        key={chargeId}
                        style={{ color: "#1890FF" }}
                        to={`/charges/${chargeId}`}
                        target="_blank"
                      >
                        <b>{chargeNumber}</b>
                      </Link>
                    )
                  )}
                </div>
              ) : undefined,
            recordId: invoiceData?.invoiceId,
            onSwitchFunction: setIsWritable,
            projectId: projectData?.projectId,
          }}
        />
      )}
      <div className="darkModeContainer">
        <div className="invoice-modal-item-name">
          {projectData?.projectName}
        </div>
      </div>
      <div className="invoice-modal-title">
        {invoiceData ? "Edit" : "New"} Invoice
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <XIcon onClick={() => (formChanged ? onCancel() : handleCancel())} />
      </div>
    </div>
  );
};

export default InvoiceHeader;
