import { max, min } from "lodash";
import dayjs from "dayjs";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

//here we generate all datas from all rentals
export const existingRentalsHandler = ({
  data,
  selectedService,
  thisElevation,
  thisPli,
  service = {},
}) => {
  return data
    ?.flatMap(({ services, rentalNumber, ...rest }) =>
      services
        ?.find(
          ({ label, quickbooksNumber = "" }) =>
            label === selectedService &&
            quickbooksNumber === service?.quickbooksNumber
        )
        ?.serviceOptions?.filter(
          ({ elevationId }) => elevationId === thisElevation
        )
        ?.flatMap(({ items }) => items?.filter(({ id }) => id === thisPli))
        ?.flatMap(({ rentalDetails }) => ({
          rentalId: rest?.rentalId,
          rentalNumber,
          range: [rentalDetails?.startDate, rentalDetails?.endDate],
        }))
        ?.filter(({ range }) => !!range[0] && !!range[1])
    )
    ?.filter(Boolean);
};

export const findingConflictingRangesHandler = ({
  startDate,
  endDate,
  existingRentals,
}) => {
  //here we turn start and end date from timestamp to dayjs object
  const [selectedRentalStart, selectedRentalEnd] = [
    dayjs(startDate),
    dayjs(endDate),
  ];
  console.log("existingRentals", existingRentals);

  const conflictingRentals = existingRentals
    ?.filter(({ range }) => {
      const [rentalStart, rentalEnd] = range;
      //here we filter all datas that the startDate or endDate in not in between start and end of the other rentals
      return (
        selectedRentalStart.isBetween(rentalStart, rentalEnd, "day", "[]") ||
        selectedRentalEnd.isBetween(rentalStart, rentalEnd, "day", "[]")
      );
    })
    ?.map((rentalsData) => {
      const [rentalStart, rentalEnd] = rentalsData?.range;

      //now we take all dates that have conflicts and find witch range is making the conflict
      return {
        ...rentalsData,
        conflictingRange: [
          //the star date of collision
          max([selectedRentalStart?.valueOf(), rentalStart]),
          //the end date of collision
          min([selectedRentalEnd?.valueOf(), rentalEnd]),
        ],
      };
    });

  return conflictingRentals;
};
