import getDegTotals from "./getDegTotals";
import getWeeksTotal from "./getWeeksTotal";

function getDistributionForTeams({ analytics, teams }) {
  let employeeHoursPerService = {};
  let totalsPerService = {};
  let selectedTeamsData = {};
  let res = [];

  /**
   * The whole point is to create a divided copy of the employeesHoursPerDay
   * analytic, so that we can calculate the week totals per service, and per team,
   * in the end we want to differentiate the weeks totals per team, per service and
   * the service totals
   */
  for (const employeeId in analytics["employeesHoursPerDay"]) {
    let team = teams?.find(({ crewForeman, crewMembers }) => {
      if (crewForeman?.employeeId === employeeId) {
        return true;
      }
      return crewMembers?.find(({ employeeId: eId }) => eId === employeeId);
    });

    if (team) {
      let teamId = team?.crewTeamId;
      let sowSet = new Set(selectedTeamsData?.[teamId]?.["services"] || []);
      let sowArr = [];

      for (const day in analytics["employeesHoursPerDay"][employeeId]) {
        let dayShift = analytics["employeesHoursPerDay"][employeeId][day];
        for (const entry of dayShift["jobsitesInfo"]) {
          entry["sow"]?.forEach((service) => {
            let len = entry["sow"].length;
            sowSet.add(service);

            let copy = {
              ...entry,
              otAmount: entry["otAmount"] / len,
              overtimeHours: entry["overtimeHours"] / len,
              workHours: entry["workHours"] / len,
              // regAmount: entry["regAmount"] / len,
            };

            if (!employeeHoursPerService?.[service]) {
              employeeHoursPerService[service] = {};
            }

            if (!employeeHoursPerService[service]?.[teamId]) {
              employeeHoursPerService[service][teamId] = {};
            }

            if (!employeeHoursPerService[service][teamId]?.[employeeId]) {
              employeeHoursPerService[service][teamId][employeeId] = {
                [day]: {
                  jobsitesInfo: [copy],
                },
              };
            } else {
              if (
                !employeeHoursPerService[service][teamId][employeeId]?.[day]
              ) {
                employeeHoursPerService[service][teamId][employeeId] = {
                  ...employeeHoursPerService[service][teamId][employeeId],
                  [day]: {
                    jobsitesInfo: [copy],
                  },
                };
              } else {
                let jobsitesInfo =
                  employeeHoursPerService[service][teamId][employeeId][day][
                    "jobsitesInfo"
                  ];
                let jobIndex = jobsitesInfo?.find(
                  ({ inJobsiteId }) => inJobsiteId === copy?.inJobsiteId
                );

                if (jobIndex === -1) {
                  employeeHoursPerService[service][teamId][employeeId][day] = {
                    jobsitesInfo: [
                      ...employeeHoursPerService[service][teamId][employeeId][
                        day
                      ]["jobsitesInfo"],
                      copy,
                    ],
                  };
                } else {
                  let indexEntries = {
                    ...employeeHoursPerService[service][teamId][employeeId][
                      day
                    ]["jobsitesInfo"][jobIndex],
                  };

                  employeeHoursPerService[service][teamId][employeeId][day][
                    "jobsitesInfo"
                  ][jobIndex] = {
                    ...indexEntries,
                    otAmount: entry["otAmount"] + indexEntries["otAmount"],
                    overtimeHours:
                      entry["overtimeHours"] + indexEntries["overtimeHours"],
                    workHours: entry["workHours"] + indexEntries["workHours"],
                  };
                }
              }
            }
          });
        }
      }

      sowSet.forEach((service) => sowArr.push(service));

      if (sowArr.length) {
        if (
          sowArr.some(
            (el) =>
              !(selectedTeamsData?.[teamId]?.["services"] || [])?.includes(el)
          )
        ) {
          if (!selectedTeamsData?.[teamId]) {
            selectedTeamsData[teamId] = {
              foreman: team?.crewForeman?.crewName,
              companyName: team?.company,
              crewName: team?.crewTeamName,
              members: team?.crewMembers,
              services: [...sowArr],
            };
          } else {
            selectedTeamsData[teamId] = {
              ...selectedTeamsData[teamId],
              services: [...sowArr],
            };
          }
        }
      }
    }
  }

  for (const service in employeeHoursPerService) {
    totalsPerService[service] = {
      teamTotals: {},
      totals: {},
    };

    let tmpTotals = {};

    for (const teamId in employeeHoursPerService[service]) {
      const weeksTotalsForTeam = getWeeksTotal({
        workerList: Object.keys(employeeHoursPerService[service][teamId]),
        allEntries: employeeHoursPerService[service][teamId],
      });
      const teamTotals = getDegTotals({
        employeeWeekTotals: weeksTotalsForTeam,
      });
      totalsPerService[service]["teamTotals"][teamId] = {
        ...teamTotals,
      };

      let totalKeys = Object.keys(tmpTotals);
      if (!totalKeys.length) {
        tmpTotals = { ...teamTotals };
      } else {
        totalKeys.forEach((key) => {
          tmpTotals[key] = tmpTotals[key] + teamTotals[key];
        });
      }
    }

    totalsPerService[service]["totals"] = { ...tmpTotals };
  }

  for (const teamId in selectedTeamsData) {
    let tmpEntry = {
      ...selectedTeamsData[teamId],
      teamId,
      details: [],
    };

    let total = {};

    for (const service of selectedTeamsData[teamId]["services"]) {
      let teamEntry = totalsPerService[service]["teamTotals"][teamId];
      let totalKeys = Object.keys(total);
      if (totalKeys.length) {
        totalKeys.forEach((key) => {
          total[key] = total[key] + teamEntry[key];
        });
      } else {
        total = { ...teamEntry };
      }
      tmpEntry["details"] = [
        ...tmpEntry["details"],
        {
          service,
          ...teamEntry,
        },
      ];
    }

    tmpEntry = {
      ...tmpEntry,
      ...total,
    };

    res.push(tmpEntry);
  }

  return res;
}

export default getDistributionForTeams;
