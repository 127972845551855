import { defaultExcelHeaderStyle } from "../../../../../helpers/constants/defaultExcelHeaderStyle";

export const tableHeaders = [
  "Length Of Video",
  "Video Link",
  "Video Name",
  "Sub Category Name",
];

export const excelTableHeaders = [
  { v: "Length Of Video", s: defaultExcelHeaderStyle },
  { v: "Video Link", s: defaultExcelHeaderStyle },
  { v: "Video Name", s: defaultExcelHeaderStyle },
  { v: "Sub Category Name", s: defaultExcelHeaderStyle },
];
