import { formatCurrency } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import {
  calculateInvoiceStatus,
  categoriesName,
  getMonthData,
  getSums,
  groupBy,
} from "../../calculatingFunctions";

export const invoices = ({ tables, programFields }) => {
  const invoiceStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = invoiceStatus?.statusName?.map((status) => {
    return calculateInvoiceStatus({ table: tables?.invoices, status });
  });

  const data = getMonthData(tables.Invoices, "invoiceId")?.map(({ id }) => {
    return id?.map((el) => {
      return tables?.invoices?.find(({ invoiceId }) => invoiceId === el);
    });
  });

  return {
    invoiceStatus: () => [
      {
        labels: invoiceStatus?.statusName,
        data: statusData?.map((el) => el?.length),
        backgroundColor: invoiceStatus?.statusColor,
        custom:
          !!statusData[0] &&
          groupBy(statusData, "projectName")?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Invoice Amount With Tax": formatCurrency(
                    getSums({ data: statusData, key: "totalInvoiceAmount" })[
                      index
                    ] || 0
                  ),
                  "Invoice Amount Without Tax": formatCurrency(
                    getSums({ data: statusData, key: "subtotal" })[index] || 0
                  ),
                  "Total Tax": formatCurrency(
                    (getSums({ data: statusData, key: "totalInvoiceAmount" })[
                      index
                    ] || 0) -
                      (getSums({ data: statusData, key: "subtotal" })[index] ||
                        0)
                  ),
                },
              },
              ...Object.entries(el).map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" })
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "subtotal" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" }) -
                        getSums({ data: value, key: "subtotal" })
                    ),
                  },
                };
              }),
            ];
          }),
      },
    ],
    totalInvoiceAmount: () => [
      {
        labels: months,
        data: !!tables?.invoices
          ? data?.map((el) => {
              return el
                ?.reduce((acc, curr) => {
                  return acc + (parseFloat(curr?.totalInvoiceAmount) || 0);
                }, 0)
                ?.toFixed(2);
            })
          : [undefined],
        backgroundColor: "#2DA365",
        custom: groupBy(data, "projectName")?.map((el, index) => {
          return Object?.entries(el)?.map(([key, value]) => {
            return {
              [key]: {
                projectId: value?.[0]?.projectId,
                "Invoice Amount With Tax": formatCurrency(
                  getSums({ data: value, key: "totalInvoiceAmount" })
                ),
                "Invoice Amount Without Tax": formatCurrency(
                  getSums({ data: value, key: "subtotal" })
                ),
                "Total Tax": formatCurrency(
                  getSums({ data: value, key: "totalInvoiceAmount" }) -
                    getSums({ data: value, key: "subtotal" })
                ),
              },
            };
          });
        }),
      },
      {
        labels: months,
        data: !!tables?.invoices
          ? data?.map((el) => {
              return el
                ?.reduce((acc, curr) => {
                  return acc + (Number(curr?.subtotal) || 0);
                }, 0)
                ?.toFixed(2);
            })
          : [undefined],
        backgroundColor: "#B5D99C",
      },
      {
        labels: months,
        data: !!tables?.invoices
          ? data?.map((el) => {
              return el
                ?.reduce((acc, curr) => {
                  return (
                    acc +
                    ((Number(curr?.totalInvoiceAmount) || 0) -
                      (Number(curr?.subtotal) || 0))
                  );
                }, 0)
                ?.toFixed(2);
            })
          : [undefined],
        backgroundColor: "#f04f4e",
      },
    ],
  };
};
