import { rentalDataExtractor } from "../../ExportToExcel/exportToExcelFunctions";
import moment from "moment-timezone";
import { getNYDate } from "../../../../../../../utils/dateFunctions";
import { groupBy } from "../../ExportToExcel/ExportToExcel";
import { formatCurrency } from "../../../../../../../../../../../utils";

export const retrieveProcessedRental = ({
  fakeApplication = {},
  applications = [],
}) => {
  console.log("retrieveProcessedRental", fakeApplication);
  // First we get ready the data in a format that will be processed by the exportToExcelFunctions
  let otherReqs = applications?.filter(
    (el) =>
      el?.applicationNo < fakeApplication?.applicationNo &&
      el?.scheduleId === fakeApplication?.scheduleId
  );
  let othersRentalData = otherReqs
    ?.map((el) => {
      return rentalDataExtractor({
        fakeApplication: el,
      })
        ?.filter(Boolean)
        ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
        ?.map((e) => ({
          ...e,
          isPrevious: true,
          retainage: !!fakeApplication?.prevRentalsRetainage
            ? fakeApplication?.prevRentalsRetainage
            : e?.retainage,
        }));
    })
    .flatMap((e) => e);

  let workSheet2RentalsData = rentalDataExtractor({
    fakeApplication,
  })
    ?.filter(Boolean)
    ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1));
  let temp =
    groupBy(
      "label",
      othersRentalData?.length > 0
        ? [...othersRentalData, ...workSheet2RentalsData]
        : workSheet2RentalsData
    ) || {};
  console.log("temp", temp);
  Object.values(temp).forEach(
    (arr) =>
      (arr = arr.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1)))
  );

  let workSheet2RentalsDataGrouped = Object.values(temp)
    ?.flatMap((el) => el)
    .map((el) => ({
      ...el,
      monthStart: getNYDate(moment(el?.monthStart)).formatted,
      monthEnd: getNYDate(el?.monthEnd).formatted,
      totalPercentage: Math.round(
        el?.days?.reduce((a, b) => a + b?.percentage, 0) / el?.days.length
      ),
    }));

  // We proceed with the preview in the same way it would appear in Excel

  let tableData = workSheetRentalsTable({
    workSheet2RentalsData: workSheet2RentalsDataGrouped,
    fakeApplication,
  });
  console.log("Table Data", tableData);

  return tableData;
};

// Function used in Backend to process the data for Excel

const workSheetRentalsTable = ({ workSheet2RentalsData, fakeApplication }) => {
  let rowData = [];
  console.log("workSheet2RentalsData", workSheet2RentalsData);
  //========================here we generate data for included rentals==============================
  var creditIndex = 0;
  workSheet2RentalsData?.forEach(
    (
      {
        label,
        monthStart,
        monthEnd,
        amountForThisMonth,
        taxRate = 0,
        retainage = 0,
        isPrevious = false,
        serviceLevelCredit = 0,
        totalPercentage = 0,
        isProrated = false,
        month = 0,
      },
      index
    ) => {
      var rowValues = [];
      var creditValues = [];
      var totalAmountPlusTax = +(
        +amountForThisMonth +
        +amountForThisMonth * +taxRate
      );
      var totalPlusCredit = +totalAmountPlusTax;
      var isProRated2 = totalPercentage > 0 && totalPercentage !== 100;
      let tempData = {};

      let tempId = `${monthStart}-${monthEnd}-${label}-${month}`;
      tempData.groupId = tempId;
      //   rowValues[1] = index + 1 + creditIndex;
      tempData.id = index;

      tempData.fullDescription = !!fakeApplication?.rentalExcel?.[tempId]
        ? fakeApplication?.rentalExcel?.[tempId]
        : `${
            isProrated && totalPercentage !== 100 ? "Pro Rated" : ""
          } 4 Weekly Rental for ${
            isProRated2 && !isProrated ? totalPercentage.toFixed(2) + "% " : ""
          }${label}: ${moment(monthStart)?.format("MM-DD-YYYY")} to ${moment(
            monthEnd
          )?.format("MM-DD-YYYY")} for $${amountForThisMonth?.toFixed(
            2
          )} plus tax = $${totalAmountPlusTax.toFixed(2)} ${
            isProRated2 && totalPercentage !== 100
              ? "(out of " +
                formatCurrency(
                  (
                    (1 * Math.round(amountForThisMonth)) /
                    (totalPercentage / 100)
                  ).toFixed(2)
                ) +
                " plus tax)"
              : ""
          } `;
      tempData.scheduledValue = +(+totalAmountPlusTax)?.toFixed(2);
      tempData.thisPeriod = !isPrevious
        ? +(+totalAmountPlusTax)?.toFixed(2)
        : 0;
      //   rowValues[4] = isPrevious ? +(+totalPlusCredit)?.toFixed(2) : 0;
      //   rowValues[5] = !isPrevious ? +(+totalPlusCredit)?.toFixed(2) : 0;
      //   rowValues[6] = 0;
      //   rowValues[7] = {
      //     formula: `SUM(D${currentRow},E${currentRow},F${currentRow})`,
      //   };
      //   rowValues[8] = {
      //     formula: `=IFERROR(SUM(G${currentRow}/C${currentRow}),"")`,
      //   };
      //   rowValues[9] = { formula: `SUM(C${currentRow}-G${currentRow})` };
      //   rowValues[10] = { formula: `SUM(G${currentRow}* ${+retainage}%)` };
      tempData.retainage = +((+totalPlusCredit * retainage) / 100)?.toFixed(2);
      tempData.fromPrevious = isPrevious ? +(+totalPlusCredit)?.toFixed(2) : 0;

      // return
      //   G703.insertRow(currentRow, rowValues);
      //   currentRow += 1;
      //   leftColumns.push(currentRow);
      let creditWithTax = -Math.abs(
        +serviceLevelCredit + +serviceLevelCredit * +taxRate
      );

      if (serviceLevelCredit !== 0) {
        creditIndex += 1;
        creditValues[1] = index + 1 + creditIndex;
        creditValues[2] = `CREDIT FOR ${+serviceLevelCredit} plus Tax ${+creditWithTax} ${
          isProrated && totalPercentage !== 100 ? "Pro Rated" : ""
        } 4 Weekly Rental for ${
          isProRated2 && !isProrated ? totalPercentage + "% " : ""
        }${label}: ${moment(monthStart)?.format("MM-DD-YYYY")} to ${moment(
          monthEnd
        )?.format("MM-DD-YYYY")} for $${amountForThisMonth.toFixed(
          2
        )} plus tax = $${totalAmountPlusTax.toFixed(2)} ${
          isProRated2 && totalPercentage !== 100
            ? "(out of " +
              formatCurrency(
                (
                  (1 * Math.round(amountForThisMonth)) /
                  (totalPercentage / 100)
                ).toFixed(2)
              ) +
              " plus tax)"
            : ""
        } `;
        creditValues[3] = serviceLevelCredit + serviceLevelCredit * taxRate;
        creditValues[4] = isPrevious ? creditValues[3] : 0;
        creditValues[5] = !isPrevious ? creditValues[3] : 0;
        creditValues[6] = 0;
        creditValues[7] = creditValues[3];
        // creditValues[8] = {
        //   formula: `=IFERROR(SUM(G${currentRow}/C${currentRow}),"")`,
        // };
        // creditValues[9] = { formula: `SUM(C${currentRow}-G${currentRow})` };
        // creditValues[10] = { formula: `SUM(G${currentRow}* ${+retainage}%)` };
        // G703.insertRow(currentRow, creditValues);
        // currentRow += 1;
        // leftColumns.push(currentRow);
        // creditValues = [];
        creditValues[10] = (
          (creditValues[3] * retainage + retainage * taxRate) /
          100
        )?.toFixed(2);
      }
      rowData.push(tempData);
      if (creditValues?.length > 0) {
        let creditData = {};
        creditData.groupId = `credit-${monthStart}-${monthEnd}-${label}-${month}`;
        creditData.id = index + 1 + creditIndex;
        creditData.fullDescription = !!fakeApplication?.rentalExcel?.[
          creditData.groupId
        ]
          ? fakeApplication?.rentalExcel?.[creditData.groupId]
          : `CREDIT for  ${formatCurrency(
              +serviceLevelCredit || 0
            )} plus Tax ${formatCurrency(+creditWithTax || 0)}  ${
              tempData.fullDescription
            }`;
        creditData.scheduledValue = creditValues[3];
        creditData.retainage = creditValues[10];
        creditData.thisPeriod = creditValues[5];
        creditData.fromPrevious = creditValues[4];
        console.log("credit data", creditData);
        rowData.push(creditData);
      }
    }
  );
  return {
    rowData,
  };
};
