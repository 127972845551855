import { queryParams } from "../../../../../../../../utils/queryParams";

//this handle save on click
export const handleSave = ({ dispatch, updateRental, fakeRentals }) => {
  dispatch(updateRental(fakeRentals));
};

//this function is used to redirect user in the accounting/rental tab
export const sendToAccounting = ({
  location,
  navigate,
  fakeRentals,
  isSaved,
}) => {
  if (location.state?.goBackTo === "accountingGrid") {
    navigate(-1);
  } else {
    navigate(
      //the first parameter is the link
      `/projects/${fakeRentals.projectId}?${queryParams({
        tab: "Accounting",
        subTab: "Rentals",
      })}`,
      //those parameters are used to send it directly to the rentals tab
      {
        state: {
          fromAccounting: true,
          accountingTab: "Rentals",
          fromRentals: true,
          tabPosition: "Accounting",
          ...(isSaved && {
            isNextStepOpen: { visible: true, subTab: "Rentals" },
            savedRecord: fakeRentals,
          }),
        },
      }
    );
  }
};

// const adapter = { darkMode: "SET_DARK_MODE", writeMode: "SET_WRITABLE", autoSave: "SET_AUTOSAVING" }

// const adapter1 = {
//   darkMode: "SET_DARK_MODE",
//   writeMode: "SET_WRITABLE",
//   autoSave: "SET_AUTOSAVING",
// }

// //this handles all the switch functions that update context (darkMode, writeMode, autoSaving)
// export const switcherHandler = ({ dispatchContext, checkValue, witchOne }) => {
//   dispatchContext({ type: adapter[witchOne], payload: checkValue })
// }

export const checkAllServicesHaveRetainage = (services = []) => {
  return !services?.some((service) => !service.retainage);
};

/**
 * This is a function that returns true if all the items are unselected. Runs when the user wants to close.
 * The point is to display a warning to the user that they cannot create a charge for non-applied items
 * @param {Array} services The services array of fake rentals
 */
export function checkAllNotApplied(services = []) {
  let condition = true;
  for (let i = 0; i < services?.length; i++) {
    services[i]?.serviceOptions?.forEach((option) => {
      option?.items?.forEach((item) => {
        //we found an applied item so we don't need to check anymore
        if (item?.applied !== false) condition = false;
      });
    });
    if (!condition) break;
  }
  return condition;
}
