import "./Members.scss";
import _ from "lodash";
import { saveGlobal } from "../../../../../hooks";
import FormItem from "../../components/FormItems/FormItem";
import StyledButton from "../../components/StyleButton/StyledButton";
import { getRandomColor } from "../../../../Header/forms/Scheduling/helpers/creators";
import { useEffect, useState } from "react";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { XIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";

const Members = (props) => {
  const {
    params: { rowData },
    form,
    getEditedValue,
    setEditMode,
    getFormChanged = () => {},
  } = props;

  const location = useLocation();
  let path = location.pathname;
  let type = path.split("/")[1];

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  //save previus data
  const [prev, setPrev] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  useEffect(() => {
    setPrev(() => _.cloneDeep(props));
  }, [props]);

  let typesOfMemebers = programFields?.find(
    ({ fieldName }) => fieldName === "Member Keys"
  ).fieldOptions;

  const { architects, engineers, projectExecutives } = saveGlobal("members");
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const projectManagers =
    programFields
      ?.find((prog) => prog.fieldName === "Project Managers")
      ?.fieldOptions?.map(({ nameOfUser, cognitoUserId, identityId }) => ({
        label: nameOfUser,
        value: nameOfUser,
        nameOfUser,
        cognitoUserId,
        identityId,
      })) || [];

  let options = {
    archAssignedTo: architects.map(({ nameOfUser }) => nameOfUser),
    engAssignedTo: engineers.map(({ nameOfUser }) => nameOfUser),
    projectExecutive: projectExecutives?.map(
      (e) => `${e.firstName} ${e.lastName}`
    ),
    accountManager: [
      ...new Set(
        projectExecutives?.reduce(
          (prev, curr) => prev.concat(curr?.accountManager),
          []
        )
      ),
    ],
    projectAdmin: projectExecutives
      .map((e) => e.projectAdmin)
      .filter(onlyUnique),
    projectManager: projectManagers,
  };

  let members = [];
  for (const typesMem of typesOfMemebers) {
    if (rowData.hasOwnProperty([typesMem.key])) {
      if (typesMem.key === "projectAdmin" && type !== "projects") continue;

      if (
        type === "opportunities" &&
        typesMem.key !== "projectExecutive" &&
        typesMem.key !== "accountManager" &&
        typesMem.key !== "projectManager"
      ) {
        continue;
      } else if (
        type === "projects" &&
        typesMem.key !== "projectExecutive" &&
        typesMem.key !== "accountManager" &&
        typesMem.key !== "projectManager" &&
        typesMem.key !== "projectAdmin"
      ) {
        continue;
      }

      if (typesMem.key === "projectManager") {
        members.push({
          key: typesMem.key,
          label: typesMem.value,
          value: rowData[typesMem.key].map((pManager) =>
            typeof pManager === "string"
              ? { id: pManager }
              : { id: pManager?.nameOfUser }
          ),
        });
      } else
        members.push({
          key: typesMem.key,
          label: typesMem.value,
          value: rowData[typesMem.key],
        });
    }
  }

  const inputs = members.map(({ label, value, key }) => ({
    member: { value, color: getRandomColor() },
    role: label,
    key,
    [key === "projectManager" ? "customOptions" : "options"]: options[key],
  }));

  // document.prevMembers = members.reduce(
  //   (obj, item) => ((obj[item.key] = item.value), obj),
  //   {}
  // );

  const handleConfirm = () => {
    if (!!form.getFieldValue("projectExecutive")) {
      Swal.fire({
        title:
          "You need to change the team members after changing the project executive or account manager !",
        icon: "warning",
        text: "",
        showConfirmButton: false,
        padding: "10px",
        timer: 3000,
      });
    }

    const updatedProjectManagers = form
      .getFieldValue("projectManager")
      ?.map(({ nameOfUser = "", cognitoUserId = "", identityId = "" }) =>
        typeof rowData?.projectManager?.[0] === "string"
          ? nameOfUser
          : { nameOfUser, cognitoUserId, identityId }
      );

    const body = {
      ...form.getFieldsValue(),
      ...(!!updatedProjectManagers &&
        updatedProjectManagers?.length > 0 && {
          projectManager: updatedProjectManagers,
        }),
    };

    API.put(`${path.split("/")[1]}`, `${path}`, {
      body,
    });

    getEditedValue(
      Object.fromEntries(
        Object.entries(body).filter(([key, value]) => !!value)
      ),
      undefined,
      document.prevMembers,
      prev,
      props
    );

    onCancel();
  };

  return (
    <div className="editMembersContent">
      <div className="editMembersContentElementContainer">
        {inputs?.map((input) => {
          return (
            <>
              <span className="editMembersContentElementContainerTitle">
                {input.role}
              </span>
              {FormItem[
                !!Array.isArray(input.member.value) ? "Multiple" : "Dropdown"
              ]({
                name: input?.key,
                value: input?.member?.value,
                popupClassName: isDarkMode && "darkDropDown",
                dropdownClassName: isDarkMode && "darkDropDown",
                isDarkMode,
                getEditedValue,
                form,
                ...(input?.options
                  ? {
                      options:
                        input?.options.map((option) => ({
                          label: option,
                          value: option,
                        })) || [],
                    }
                  : {
                      customOptions: input?.customOptions || [],
                    }),
                label: input?.key,
              })}
            </>
          );
        })}
      </div>
      <div className="confirmContainer">
        <MondayButton
          onClick={() => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          onClick={handleConfirm}
          className="confirmButton"
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(event) => {
          event.charCode === 13 && onCancel();
        }}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default Members;
