import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import checkDateInput from "./checkDateInput";
import checkPicker from "./checkPicker";
import checkTimeInput from "./checkTimeInput";

const keyData = {
  safety: {
    categoryKey: "safetyApplicationCategory",
    objectKey: "safetyApplicationObject",
    idKey: "safetyApplicationId",
    taskKey: "Safety",
    recordName: "safetyName",
    statusKey: "safetyApplicationStatus",
    defaultStatus: "Draft",
  },
  incidents: {
    categoryKey: "incidentCategory",
    objectKey: "incidentObject",
    idKey: "incidentId",
    taskKey: "Incidents",
    statusKey: "incidentStatus",
    recordName: "incidentName",
    defaultStatus: "Draft",
  },
};

const customOptionsData = {
  foremanOfCrewThatCausedIncident: {
    key: "crewId",
    label: "crewName",
    table: "crews",
  },

  crewMembersThatCausedDamage: {
    key: "crewId",
    label: "crewName",
    table: "crews",
    mode: "multiple",
  },

  fullNameOfForeman: {
    key: "crewId",
    label: "crewName",
    table: "crews",
  },

  nameOfDriver: {
    key: "driverId",
    label: "driverName",
    table: "drivers",
  },

  foremanOfCrewThatCausedTheDamage: {
    key: "crewId",
    label: "crewName",
    table: "crews",
  },
};

/**
 * Populates the safety form with data.
 *
 * @param {Object} form - The form object.
 * @param {string} [pdfId=""] - The PDF ID.
 * @param {string} [statusKey=""] - The status key.
 * @param {Object} [rowObject={}] - The row object.
 * @param {string} [fieldsObject=""] - The fields object.
 * @param {Array} [dynamicFields=[]] - The dynamic fields.
 * @param {Function} [setSign=()=>{}] - The setSign function.
 * @param {Object} [statusesColors={}] - The statuses colors.
 * @param {Function} [setPdfId=()=>{}] - The setPdfId function.
 * @param {Function} [setAssignedTo=()=>{}] - The setAssignedTo function.
 * @param {Function} [setCurrentStep=()=>{}] - The setCurrentStep function.
 * @param {Function} [setSelectedTeam=()=>{}] - The setSelectedTeam function.
 * @param {Function} [setWitnessCount=()=>{}] - The setWitnessCount function.
 * @param {Function} [setUploadedFiles=()=>{}] - The setUploadedFiles function.
 * @param {Function} [setWitnessSignings=()=>{}] - The setWitnessSignings function.
 * @param {Object} [data={}] - The data object.
 */
function populateSafetyForm({
  form,
  pdfId = "",
  statusKey = "",
  rowObject = {},
  fieldsObject = "",
  dynamicFields = [],
  setSign = () => {},
  statusesColors = {},
  setPdfId = () => {},
  setAssignedTo = () => {},
  setCurrentStep = () => {},
  setSelectedTeam = () => {},
  setWitnessCount = () => {},
  setUploadedFiles = () => {},
  setWitnessSignings = () => {},
  data = {},
  // setWitnessStatements = () => {},
  // setDescriptionValues = () => {},
}) {
  let toReturn = {};
  if (rowObject?.teamsConfiguration?.length > 0) {
    setSelectedTeam(
      rowObject?.teamsConfiguration?.map((team) => ({
        value: team?.value,
        members: team?.members,
      }))
    );
  }
  if (!pdfId) {
    setPdfId(rowObject?.[fieldsObject]?.gDrivePdfId || "");
  }

  setTimeout(() => {
    toReturn = structuredClone(rowObject?.[fieldsObject]);
    let witnessNo = 0;
    let rg = /witnessName-*[0-9]$/;

    Object.keys(toReturn || {}).forEach((key) => {
      if (!!checkPicker(dynamicFields, key)) {
        if (rowObject?.[fieldsObject]?.[key]) {
          toReturn[key] = dayjsNY(rowObject?.[fieldsObject]?.[key]);
        } else {
          delete toReturn?.[key];
        }
      }
      if (checkDateInput({ fields: dynamicFields, formItemName: key })) {
        if (!!rowObject?.[fieldsObject]?.[key]) {
          toReturn[key] = dayjsNY(rowObject?.[fieldsObject]?.[key]).format(
            "MM/DD/YYYY"
          );
          toReturn[`datePicker${key}`] = dayjsNY(
            rowObject?.[fieldsObject]?.[key]
          );
        } else {
          delete toReturn?.[key];
        }
      }
      if (checkTimeInput({ fields: dynamicFields, formItemName: key })) {
        if (!!rowObject?.[fieldsObject]?.[key]) {
          toReturn[key] = dayjsNY(rowObject?.[fieldsObject]?.[key]).format(
            "hh:mm A"
          );
        } else {
          delete toReturn?.[key];
        }
      }

      if (
        dynamicFields?.some((el) => el?.formItemName === key) &&
        customOptionsData?.[key]
      ) {
        const { table, key: tableKey, label } = customOptionsData[key];

        if (customOptionsData?.[key]?.mode === "multiple") {
          if (Array.isArray(rowObject?.[fieldsObject]?.[key])) {
            toReturn[key] = rowObject?.[fieldsObject]?.[key]?.map(
              ({ value, id }) => {
                const found = data?.[table]?.some(
                  (el) => el?.[tableKey] === id
                );

                if (found) {
                  return id;
                } else {
                  return value;
                }
              }
            );
          }
        } else if (
          toReturn?.[key]?.hasOwnProperty("value") &&
          toReturn?.[key]?.hasOwnProperty("id")
        ) {
          const found = (data?.[table] || [])?.some(
            (el) => el?.[tableKey] === toReturn?.[key]?.id
          );

          if (!found) {
            toReturn[key] = !!toReturn?.[key]?.value
              ? toReturn?.[key]?.value
              : null;
          } else {
            toReturn[key] = toReturn?.[key]?.id;
          }
        }
      }

      if (rg.test(key)) {
        witnessNo = witnessNo + 1;
      }
    });

    let tStatements = [];
    let tSignatures = [];

    for (let i = 0; i < witnessNo; i++) {
      // tStatements.push(toReturn?.[`witnessStatement-${i}`]);
      tSignatures.push(toReturn?.[`witnessSign-${i}`]);
    }

    setWitnessCount(witnessNo || 1);
    // setWitnessStatements(tStatements);

    setWitnessSignings(tSignatures);
    setSign(toReturn?.signature);

    // let tmpDescriptions = {};

    // Object.keys(toReturn || {}).forEach((key) => {
    //   tmpDescriptions[key] = toReturn[key];
    // });

    let stepIndex = statusesColors?.findIndex(
      ({ statusName }) => statusName === rowObject?.[statusKey]
    );

    setCurrentStep(stepIndex);

    form.setFieldValue("stepper", stepIndex);
    // setDescriptionValues(tmpDescriptions);
    setAssignedTo(toReturn?.assignedTo);

    form.setFieldsValue({
      ...toReturn,
      assignedTo: toReturn?.assignedTo?.map(
        ({ identityId = "" }) => identityId
      ),
    });

    if (!Array.isArray(rowObject?.googleDriveUpload)) {
      setUploadedFiles([]);
    } else {
      setUploadedFiles(rowObject?.googleDriveUpload);
    }
  });
}

export default populateSafetyForm;
