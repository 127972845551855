import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { PrintIcon } from "../../../../../../../../../../BasePage/src";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { useRedux } from "../../../../../../../../../../hooks";
import { getRentalPreview } from "./utils";
import { useSelector } from "react-redux";
import "./RentalPreview.scss";
import "./RentalPreviewPrint.scss";
import { updateDocumentTitle } from "../../../../../../../../../../../../utils";
import { EyeFilled } from "@ant-design/icons";

function RentalPreview() {
  const [isOpen, setOpen] = useState(false);
  const [darkMode] = useRedux("rentalsDarkMode");
  const [fakeRentals] = useRedux("fakeRentals");

  const [data, setData] = useState([]);
  const [showDimensions, setShowDimensions] = useState(false);

  const printableModalBodyRef = useRef();
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  const onPrint = () => {
    const printableArea = printableModalBodyRef.current.cloneNode(true);
    const printWindow = window.open("", "_blank");

    printWindow.document.write("<html><head><title> </title>");
    printWindow.document.write("<style>");
    // Get all stylesheets in the current document and copy their content to the print window
    Array.from(document.styleSheets).forEach((styleSheet) => {
      try {
        // console.log("styleSheet", styleSheet);
        const css = styleSheet.cssRules;
        printWindow.document.write(
          Array.from(css)
            .map((rule) => rule.cssText)
            .join("")
        );
      } catch (e) {
        console.log(`Error loading CSS for ${styleSheet.href}: ${e}`);
      }
    });
    printWindow.document.write("</style></head><body>");
    printWindow.document.write(printableArea.outerHTML);
    printWindow.document.write("</body></html>");

    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const onSwitchDimensions = () => {
    setShowDimensions((prev) => !prev);
  };

  const getData = () => {
    const temp = getRentalPreview({
      rentalObj: fakeRentals,
      serviceDefinitions: proposedTypeOfWork,
      showDimensions,
      darkMode: darkMode,
    });

    setData(temp);
  };

  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [fakeRentals, proposedTypeOfWork, showDimensions, darkMode]);

  const toggleOpen = (value) => {
    setOpen(value);
  };

  return (
    <div
      style={
        {
          // marginLeft: "20px",
        }
      }
    >
      {/* <Tooltip title="Preview TakeOff"> */}
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => toggleOpen(true)}
        hasIcon={false}
      >
        <div
          className="iconHolder"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <EyeFilled style={{ transform: "scale(0.9)" }} />
        </div>
      </MondayButton>
      {isOpen && (
        <Modal
          title="Rentals Preview"
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "Rentals Preview" });
          }}
          // title
          // closeIcon={<CloseIconModalHeader />}
          open={isOpen}
          className={`preview-modal__takeoff${darkMode ? "__dark" : ""}`}
          // width={"1200px"}

          footer={null}
          centered
          width={"auto"}
          onCancel={() => {
            updateDocumentTitle(); // reset document title to "Lead Manager";

            toggleOpen(false);
            // setHtmlData([]);
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
                gap: "10px",
              }}
            >
              <MondayButton
                className="mondayButtonBlue"
                onClick={onSwitchDimensions}
                hasIcon={false}
              >
                {showDimensions ? "HIDE DIMENSIONS" : "SHOW DIMENSIONS"}
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                onClick={onPrint}
                Icon={<PrintIcon />}
              >
                PRINT
              </MondayButton>
              {/* <MondayButton onClick={onExport}>WORD</MondayButton> */}
            </div>
          </div>
          <div
            className="printable-modal__body"
            style={{ width: "auto", padding: "20px" }}
            ref={printableModalBodyRef}
          >
            {data?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {typeof item?.props?.children === "array"
                    ? children.map((child, index) => {
                        return child;
                      })
                    : item?.props?.children !== "object"
                    ? item
                    : ""}
                </React.Fragment>
              );
            })}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default RentalPreview;
