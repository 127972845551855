export const dataEntryGridInitials = {
  project: {},
  serviceDefs: null,
  ServicesIndex: {}, //{"Sidewalk Shed": 1, ...}]
  ServiceNameByIndex: {}, //{1: "Sidewalk Shed", ...}
  gridData: null,
  pricingData: [],
  selectOptions: {},
  activeKey: null,
  activePanels: [[[]]],
  activePriceSheetModal: false, //when it has a serviceId number, it means that modal is open for that service
  selectedPriceSchemesForService: {}, // {serviceId: {priceScheme: PriceScheme, priceSchemesType: "sheet"|"list"}, }
  statusBar: {
    statusPanels: [{ statusPanel: "StatusPanel" }],
  },
  priceView: false,
  priceEdit: false,
  saveInProjectModal: false,
  currentFocusedInitialValue: "",
  serviceGridKey: 0,
  elevationTotalKey: 0,
  agSelectedCells: {},
  fullScreenService: null,
  ServiceMenuContainerShow: true,
  errorMessages: [],
  AutoSave: true,
  project_OR_opportunity: undefined, //project|opportunity
  //  agGridTheme: this.props.agGridTheme,
  UIFeaturesForEachElevation: {}, // this.UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex] = {data}
  loading: true,
  accessRights: {
    children: [],
  },
  oppOfCurrentEstimation: null,
  scopeSelectorModalVisible: false, //used for adding elevations to "Other Scope" service
  addonsRowData: [],
  agGridKey: 0,
};
