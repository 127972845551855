import uniq from "lodash/uniq";

export const dispatching = (updatedDynamicStates, IdHandler, dispatchData) => {
  const allDrivers = (updatedDynamicStates?.[IdHandler] || [])
    ?.map((a) => a?.routes?.map((e) => e?.driverName))
    ?.flat();
  return {
    fleetName: () => ({
      title: "Dispatching",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Dispatching",
        field: "fleetName",
        title: "Fleet Name",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.fleetName)
        )?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          dateCreated: 1630965600000,
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.fleetName === status
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.fleetName === status
          ).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //fleetName
    driverName: () => ({
      title: "Dispatching",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Dispatching",
        field: "driverName",
        title: "Driver Name",
        subtitle: "",
        content: uniq(allDrivers)?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          dateCreated: 1630965600000,
          percent: (
            (allDrivers?.filter((a) => a === status).length /
              allDrivers?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: allDrivers?.filter((a) => a === status).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //driverName
    dispatchedBy: () => ({
      title: "Dispatching",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Dispatching",
        field: "dispatchedBy",
        title: "Dispatched By",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
            a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
          )
        )?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          dateCreated: 1630965600000,
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter((a) =>
              a?.dispatchedBy?.name
                ? a?.dispatchedBy?.name
                : a?.dispatchedBy === status
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter((a) =>
            a?.dispatchedBy?.name
              ? a?.dispatchedBy?.name
              : a?.dispatchedBy === status
          ).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //dispatchedBy
  }; //Dispatching
};
