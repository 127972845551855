import { useRef, useEffect, useImperativeHandle } from 'react'

const useCellEditor = (ref, state, type) => {
  const inputRef = useRef(null)

  useEffect(() => {
    setTimeout(() => inputRef.current.focus())
    if (type === 'input') setTimeout(() => inputRef.current.select())
  }, [type])

  useImperativeHandle(ref, () => {
    return {
      // The final value to send to the grid, on completion of editing
      getValue() {
        return state
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false
      }
    }
  })

  return inputRef
}

export default useCellEditor