import { Checkbox } from "antd";
import { useRedux } from "../../../../../../hooks";

const CreditCheckBox = ({
  data,
  taxRate,
  invoiceId,
  updateData,
  chargeItems,
  currentCharge,
}) => {
  const [isWritable] = useRedux("creditMemoIsWritable");

  const checked = chargeItems?.find(
    ({ id: ID, invoiceId: iD }) => ID === data?.id && iD === invoiceId
  );

  const isIndeterminate =
    data &&
    checked &&
    checked.creditPercentage > 0 &&
    checked.creditPercentage < 100;

  const onCheckBoxChange = () => {
    const newCreditAmount = data.creditAmount > 0 ? 0 : data.scopeAmount;
    const newTaxAmount = data?.tax ? newCreditAmount * taxRate : 0;

    const newTotal = newCreditAmount + (newTaxAmount || 0);

    updateData(
      {
        creditPercentage: newCreditAmount > 0 ? 100 : 0,
        creditAmount: newCreditAmount,
        taxAmount: newTaxAmount,
        total: newTotal,
      },
      data
    );
  };
  return (
    <div className="new-charge-checkbox-item">
      <Checkbox
        checked={checked}
        indeterminate={isIndeterminate}
        className={`checkBox ${checked ? "checkedActive" : ""}`}
        style={{
          cursor: "pointer",
        }}
        disabled={!isWritable && currentCharge}
        onChange={onCheckBoxChange}
      />
    </div>
  );
};

export default CreditCheckBox;
