import React from "react";
import { createContext, useEffect, useState } from "react";
import dayjs from "dayjs";

const TimeDateContext = createContext();

export function TimeDateProvider({ children }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [day, setDay] = useState(new Date().getDate());
  const [hour, setHour] = useState("12");
  const [minute, setMinute] = useState("00");
  const [amOrpm, setAmOrpm] = useState("am");
  const [selectedHour, setSelectedHour] = useState(12);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [showHour, setShowHour] = useState(true);
  const [datePicker, setDatePicker] = useState("Clock");
  const [firstForDateTime, setFirstForDateTime] = useState("Clock");
  const [isClockActive, setIsClockActive] = useState(true);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());
  const [result, setResult] = useState(new Date());
  const [dateTime, setDateTime] = useState(
    new Date(year, month - 1, day, 0, 0, 0)
  );
  const [calendarResult, setCalendarResult] = useState(new Date());
  const [caledarRangeResult, setCalendarRangeResult] = useState([
    new Date(),
    new Date(),
  ]);
  const [dateRange, setDateRange] = useState(false);
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");
  const [pickFirst, setPickFirst] = useState(true);
  const [firstDateTime, setFirstDateTime] = useState();
  const [secondDateTime, setSecondDateTime] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [ownComponent, setOwnComponent] = useState(false);
  const [ownComponent2, setOwnComponent2] = useState(false);

  /* when we clicke Range button we call this function. If datePicker is Date or Clock&Date 
    we give new Dates to CalendarResult, FirstDate, SecondDate and to the SelectedDates, we also set
      calendarResult to DateTime and we change DateRange.  */
  const handleDateRange = () => {
    if (datePicker === "Clock&Date") {
      setCalendarResult(new Date());
      setDateTime(calendarResult);
      setDateRange((prev) => !prev);
      setFirstDate();
      setSelectedDate();
      setSecondDate();
      setSelectedDate2();
      // setFirstDate(new Date());
      // setSelectedDate(new Date());
      // setSecondDate(dayjs(new Date()).add(1, "days").$d);
      // setSelectedDate2(dayjs(new Date()).add(1, "days").$d);
    }
    if (datePicker === "Date") {
      // const newDate = dayjs(calendarResult).format("YYYY/M/D").split("/");
      // setDateTime(new Date(newDate[0], newDate[1] - 1, newDate[2], 6, 0, 0));

      setCalendarResult(new Date());
      setDateTime(calendarResult);
      setDateRange((prev) => !prev);
      setFirstDate();
      setSelectedDate();
      setSecondDate();
      setSelectedDate2();
    }
  };

  /* Checking dates that are between first and second date so we can style them. */
  const isInRange = (firstDate, secondDate, current) => {
    return current > firstDate && current < secondDate;
  };

  /* When we click any date in Calendar we check if it dateRange is true or false.
  If it is true than we set first click to the first date and second clicke to the second date.
  We also check if users second date is before first date, if it is then we switch them so first date is always before second date.
  If dateRange is false than we have only one date and we set clicked date to that date.  */
  const handleChangeSelectedDate = (data) => {
    setIsClicked(true);
    if (dateRange) {
      if (pickFirst === true) {
        setFirstDate(data);
        setPickFirst(false);
        setSecondDate();
        setSelectedDate(data);
        setSelectedDate2(data);
      } else {
        console.log("firstDate: ", firstDate);
        console.log("data: ", data);
        if (!dayjs(firstDate).isBefore(data)) {
          setSecondDate(firstDate);
          setSelectedDate2(firstDate);
          setFirstDate(data);
          setSelectedDate(data);
          setPickFirst(true);
        } else {
          console.log("false");
          setSecondDate(data);
          setPickFirst(true);
          setSelectedDate2(data);
        }
      }
    } else {
      setSelectedDate(data);
      setSelectedDate2();
      setCalendarResult(data);
    }
  };

  const handleChangeActiveDate = (data) => {
    setActiveDate(data);
  };

  /* Set default Hour and Minute */
  useEffect(() => {
    setHour("12");
    setMinute("00");
  }, []);

  /* Calling functions based on datePicker value.
     We check also for dateRange, if dateRange is true than we do changes so we can have both selected days.*/
  const handleDateTime = () => {
    if (datePicker === "Clock") {
      handleTime();
    } else if (datePicker === "Date") {
      if (!dateRange) {
        const newDate = dayjs(calendarResult).format("YYYY/M/D").split("/");
        setDateTime(new Date(newDate[0], newDate[1] - 1, newDate[2], 6, 0, 0));
        // setDateTime(calendarResult);
      } else {
        setFirstDate((prev) => dayjs(prev).add(6, "hour").$d);
        setSecondDate((prev) => dayjs(prev).add(6, "hour").$d);
        // setFirstDateTime(dayjs(firstDate).add(6, "hour").$d);
        // setSecondDateTime(dayjs(secondDate).add(6, "hour").$d);
      }
    } else {
      const newDate = dayjs(new Date()).format("YYYY/M/D").split("/");
      handleTime(newDate[0], newDate[1], newDate[2]);
      if (dateRange) {
        setFirstDateTime(firstDate);
        setSecondDateTime(secondDate);
      }
    }
  };

  /* useEffect when result changes and when datePicker is Clock&Date, 
  calling result of time and calendar then splitting each of them so we can push it to setDateTime.
  We check also if dateRange is true, if it is true than we can send the time to both days.  */
  useEffect(() => {
    let time;
    if (datePicker === "Clock&Date" && dateRange === true) {
      time = dayjs(result).format("HH:mm").split(":");
      const first = dayjs(firstDate).format("YYYY/M/D").split("/");
      const second = dayjs(secondDate).format("YYYY/M/D").split("/");
      setFirstDate(
        new Date(first[0], first[1] - 1, first[2], time[0], time[1], 0)
      );
      setSecondDate(
        new Date(second[0], second[1] - 1, second[2], time[0], time[1], 0)
      );
    } else if (datePicker === "Clock&Date") {
      time = dayjs(result).format("HH:mm").split(":");
      const calendar = dayjs(calendarResult).format("YYYY/M/D").split("/");
      setDateTime(
        new Date(calendar[0], calendar[1] - 1, calendar[2], time[0], time[1], 0)
      );
    }
  }, [result]);

  /* Function for giving result of Time, checking if it is am or pm and then giving it to the result */
  const handleTime = (new_year, new_month, new_day) => {
    let finalDate;
    if ((new_year, new_month, new_day)) {
      setYear(new_year);
      setMonth(new_month);
      setDay(new_day);
    }

    amOrpm === "am"
      ? (finalDate = new Date(
          year,
          parseInt(month - 1),
          day,
          hour == 12 ? 0 : hour,
          minute,
          0
        ))
      : amOrpm === "pm"
      ? (finalDate = new Date(
          year,
          parseInt(month - 1),
          day,
          hour == 12 ? hour : parseInt(hour) + 12,
          minute,
          0
        ))
      : console.log("error");

    if (datePicker === "Clock") {
      setDateTime(finalDate);
    } else {
      setResult(finalDate);
    }
  };

  /* checking if hour input is given the correct value,
   hour cant be greater than 12, less than 0 adn its length cant be greater than 2 */
  const handleHourChange = (value) => {
    if (value > 12 || value < 0 || value.length > 2) {
      return;
    } else {
      setHour(value);
      setSelectedHour(value);
      return;
    }
  };

  /* checking if minute input is given the correct value,
    hour cant be greater than 59, less than 0 adn its length cant be greater than 2 */
  const handleMinutesChange = (value) => {
    if (value > 59 || value < 0 || value.length > 2) {
      return;
    } else {
      setMinute(value);
      setSelectedMinute(value);
      // handleSelectedMinute(value);
      return;
    }
  };

  /* blocking invalid caracters so user cant type them */
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  /* checking when user has given empty value or its length is 1 */
  const handleHourBlur = () => {
    if (!hour) {
      setHour("12");
      setSelectedHour(12);
      return;
    }
    if (hour == "0" || hour == "00") {
      setHour(12);
      setSelectedHour(12);
      return;
    }
    if (hour.length === 1) {
      setSelectedHour((prev) => {
        return "0" + prev;
      });
      setHour((prev) => "0" + prev);
      return;
    }
  };

  /* checking when user has given empty value or its length is 1 */
  const handleMinuteBlur = () => {
    if (!minute) {
      setMinute("00");
      return;
    }
    if (minute.length === 1) {
      setMinute((prev) => "0" + prev);
      return;
    }
  };

  const handleCalendarResult = (data) => {
    const date = dayjs(data).format("YYYY/M/D").split("/");
    setDateTime(new Date(date[0], date[1], date[2], 0, 0, 0));
  };

  /* giving default values when user clicks buttons to change from time to date and the opposite */
  const handleChangeDatePicker = () => {
    if (isClockActive === true) {
      setDateRange(false);
      setDatePicker("Date");
      setIsClockActive((prev) => !prev);
      setSelectedDate(new Date());
      setActiveDate(new Date());
      setCalendarResult(new Date(year, month - 1, day, 6, 0, 0));
    } else {
      setDateRange(false);
      setHour("12");
      setSelectedHour("12");
      setShowHour(true);
      setMinute("00");
      setSelectedMinute("00");
      setAmOrpm("am");
      setDatePicker("Clock");
      setIsClockActive((prev) => !prev);
    }
  };

  /* giving default values when DatePicker is Clock&Date */
  const handleChangeToDateTime = () => {
    setDatePicker("Clock&Date");
    resetDateTime();
    setHour("12");
    setSelectedHour(12);
    setDateRange(false);
  };

  const handleChangeFirstForDateTime = () => {
    firstForDateTime === "Clock"
      ? setFirstForDateTime("Date")
      : setFirstForDateTime("Clock");
  };

  const handleChangeSettingOpen = () => {
    setIsSettingOpen((prev) => !prev);
  };

  /* Reseting the date and time */
  const resetDateTime = () => {
    setSelectedDate(new Date());
    setActiveDate(new Date());
    setCalendarResult(new Date(year, month - 1, day, 0, 0, 0));
    setHour("12");
    setSelectedHour(12);
    setShowHour(true);
    setMinute("00");
    setSelectedMinute("00");
    setAmOrpm("am");
  };

  return (
    <TimeDateContext.Provider
      value={{
        hour,
        minute,
        amOrpm,
        result,
        showHour,
        dateTime,
        firstDate,
        dateRange,
        secondDate,
        activeDate,
        datePicker,
        selectedHour,
        selectedDate,
        firstDateTime,
        isSettingOpen,
        selectedDate2,
        selectedMinute,
        secondDateTime,
        calendarResult,
        firstForDateTime,
        caledarRangeResult,
        setHour,
        setMinute,
        isInRange,
        setResult,
        setAmOrpm,
        handleTime,
        setDateTime,
        setShowHour,
        setFirstDate,
        setDateRange,
        setSecondDate,
        resetDateTime,
        setDatePicker,
        setActiveDate,
        handleDateTime,
        handleHourBlur,
        handleDateRange,
        setSelectedDate,
        setSelectedHour,
        setSelectedDate2,
        handleMinuteBlur,
        blockInvalidChar,
        setIsSettingOpen,
        handleHourChange,
        setSelectedMinute,
        setCalendarResult,
        handleMinutesChange,
        handleCalendarResult,
        handleChangeToDateTime,
        setCalendarRangeResult,
        handleChangeDatePicker,
        handleChangeActiveDate,
        handleChangeSettingOpen,
        handleChangeSelectedDate,
        handleChangeFirstForDateTime,
        setIsClicked,
        isClicked,
        ownComponent,
        setOwnComponent,
        ownComponent2,
        setOwnComponent2,
      }}
    >
      {children}
    </TimeDateContext.Provider>
  );
}

export default TimeDateContext;
