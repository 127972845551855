import React, { useState } from "react";
import { Modal, Button } from "antd";
import "./EstimationNoteModal.scss";
import RichText from "./RichText";
import { message } from "antd";
import { MondayButton } from "../../../../../../commonComponents/index";
import {
  CloseIconModal,
  TakeOffDescIcon,
} from "../../../../../../../assets/index";
import { useTakeOffContext } from "../../../context";
import { SaveIcon } from "../../../../../BasePage/src/index";
import { useEstVersionsContext } from "../../../../components/EstVersionsTab/context";

export const EstimationNoteModal = ({
  className,
  placeholder = "Enter description here",
  estDescription,
  saveEstimationDescription,
  disabled,
  modalClassName,
}) => {
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [note, setNote] = useState("");
  const { agGridTheme } = useTakeOffContext();
  // console.log('estDescription :>> ', estDescription);
  const { updateEstimation, estimation } = useEstVersionsContext();

  const setValue = (e) => {
    setNote(e);
  };

  const handleCancel = () => {
    setIsNoteModalVisible((prevState) => !prevState);
    // setNote("");
  };

  const handleOk = () => {
    const temp = { ...estimation };
    temp.estimationDescription = note;
    updateEstimation({ estimationDescription: note });
    // saveEstimationDescription(note);
    handleCancel();
  };

  const errorMessage = () => {
    message.error("Please enable Edit Mode!");
  };

  console.log("disabled", estimation);
  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => {
          if (!disabled) setIsNoteModalVisible(true);
        }}
        // Icon={<TakeOffDescIcon />}
        hasIcon={false}
        // disabled={disabled}
        // style={{
        //   cursor: disabled ? "not-allowed" : "default",
        // }}
      >
        {/* Add description */}
        <TakeOffDescIcon />
      </MondayButton>
      <Modal
        title="Estimation description"
        className={`est-description-modal__${agGridTheme}`}
        open={isNoteModalVisible}
        width={550}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName={modalClassName}
        footer={
          <div className="est-description-modal__footer">
            <MondayButton
              Icon={<CloseIconModal />}
              onClick={handleCancel}
              className="mondayButtonRed"
            >
              Cancel
            </MondayButton>{" "}
            <MondayButton
              Icon={<SaveIcon />}
              onClick={handleOk}
              className="mondayButtonGreen"
            >
              Save
            </MondayButton>
          </div>
        }
      >
        <RichText
          {...{
            defaultValue: estDescription || "",
            // value: note,
            wrapperClassName: "est-description-rich-text",
            placeholder,
            setValue,
            disableTitleInput: () => {},
            enableTitleInput: () => {},
          }}
        />
      </Modal>
    </>
  );
};
