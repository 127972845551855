import { groupBy, uniqBy } from "lodash";

import { getBulkCoordinates } from "src/utils";
import {
  JobsiteType,
  EmployeeType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { formatDurationDeg } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";
import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type PunchType = "ID" | "OL" | "IL" | "OD" | "HR";

export type PayrollRes = {
  entryId: string;
  isEdited: boolean;
  punchDate: string;
  punchTime: string;
  employeeId: string;
  punchType: PunchType;
  punchLocation: string;
  employeeFullName: string;
  punchCoordinates: CoordinateType;
};

type UpdateReportResultsProps = {
  results: Array<PayrollRes>;
  scheduledJobsites: { [employeeId: string]: JobsiteType };
  selectedClient: { clientName: string; clientId: string };
};

export type ReportResultType = {
  Company: string;
  Distance: string;
  Duration: string;
  "Punch Date": string;
  "Punch Time": string;
  "Employee Id": string;
  "Employee Name": string;
  "Punch Type": PunchType;
  "Jobsite Match"?: string;
  "Punch Location": string;
};

export type LunchWarning = {
  Date: string;
  ["Employee Id"]: string;
  ["Employee Name"]: string;
  ["Lunch Minutes"]: string;
};

export type MissingPunchWarning = {
  Date: string;
  ["Employee Id"]: string;
  ["Employee Name"]: string;
  ["Missing Punch"]: Array<string>;
};

export type NoJobsiteMatchWarning = {
  Company: string;
  Distance: string;
  Duration: string;
  "Punch Date": string;
  "Punch Time": string;
  "Employee Id": string;
  "Employee Name": string;
  "Punch Type": PunchType;
  "Jobsite Match"?: string;
  "Punch Location": string;
};

export type ReportWarningsType = {
  longLunchTimes: Array<LunchWarning>;
  noJobsiteMatch: Array<NoJobsiteMatchWarning>;
  missingPunchActions: Array<MissingPunchWarning>;
  // uploadedFileData?: Array<any>;
};

type GetScheduleDataProps = {
  excelData: Array<any>;
  employees: Array<EmployeeType>;
};

const SHIFT_PATTERN = ["ID", "OL", "IL", "OD"];
const SHORT_SHIFT_PATTERN = ["ID", "OD"];

export function updateReportResults({
  results,
  scheduledJobsites,
  selectedClient,
}: // uploadedFileData,
UpdateReportResultsProps): Array<ReportResultType> {
  let tmpData: Array<ReportResultType> = [];
  for (let i = 0; i < results.length; i++) {
    const rep = results[i];
    if (rep.punchType === "HR") {
      continue;
    }
    let jobMatch: JobsiteType & {
      foreman?: string;
      distance?: number;
      duration?: number;
    } = scheduledJobsites?.[`GMNY Construction-${Number(rep.employeeId)}`];

    if (jobMatch?.addressPosition?.lat) {
      let range;

      if (rep.punchCoordinates?.lat) {
        const distanceFromJob = withinRadius(
          jobMatch?.addressPosition,
          rep.punchCoordinates
        );
        range = {
          inRange: distanceFromJob.withinRange,
          distanceInFeet: distanceFromJob.distanceInFeet,
        };
      }
      Object.assign(jobMatch, {
        distance: range?.distanceInFeet || "",
        duration: range?.distanceInFeet / 3.5 || 0,
      });
    }
    // console.log("rep: ", rep);
    const objToPush = {
      "Employee Name": rep?.employeeFullName,
      "Employee Id": rep?.employeeId,
      Company: selectedClient?.clientName,
      "Punch Type": rep?.punchType,
      "Punch Date": parseInTz(rep?.punchTime).startOf("d").format("MM-DD-YYYY"),
      "Punch Time": parseInTz(rep?.punchTime).format("hh:mm A"),
      "Punch Location": rep?.punchLocation,
      "Jobsite Match": jobMatch?.jobAddress,
      Foreman: jobMatch?.foreman,
      Distance: jobMatch?.distance ? `${jobMatch?.distance} ft` : "",
      Duration: jobMatch?.duration
        ? formatDurationDeg(jobMatch.duration, "seconds")?.text
        : "",
    };
    tmpData.push(objToPush);
  }
  return tmpData;
}

export function getReportWarnings(payrollReportData: Array<ReportResultType>) {
  let longLunchTimes = [];
  let noJobsiteMatch = [];
  let missingPunchActions = [];

  const dataPerDay = groupBy(payrollReportData, (el) => el?.["Punch Date"]);
  for (const date in dataPerDay) {
    const dateReports = dataPerDay[date];
    const dataPerEmployee = groupBy(dateReports, (el) => el?.["Employee Id"]);

    for (const employeeId in dataPerEmployee) {
      const empEntries = dataPerEmployee[employeeId];

      if (empEntries?.length === 4) {
        for (let i = 0; i < SHIFT_PATTERN.length; i++) {
          const punchType = empEntries[i]?.["Punch Type"];
          const patternType = SHIFT_PATTERN[i];
          if (punchType !== patternType) {
            const punchTypeWarning = {
              ["Employee Name"]: empEntries?.[0]?.["Employee Name"],
              ["Employee Id"]: employeeId,
              Foreman: empEntries?.[0]?.Foreman,
              Date: date,
              ["Missing Punch"]: SHIFT_PATTERN[i],
            };
            missingPunchActions.push(punchTypeWarning);
          }
        }
        const oLEntry = empEntries[1];
        const iLEntry = empEntries[2];
        if (
          oLEntry?.["Punch Type"] === "OL" &&
          iLEntry?.["Punch Type"] === "IL"
        ) {
          const lunchLength = parseInTz(
            iLEntry?.["Punch Time"],
            "hh:mm A"
          ).diff(parseInTz(oLEntry?.["Punch Time"], "hh:mm A"), "minute");
          if (lunchLength > 60) {
            const lunchWarning = {
              ["Employee Name"]: empEntries[0]?.["Employee Name"],
              ["Employee Id"]: employeeId,
              Foreman: empEntries?.[0]?.Foreman,
              Date: date,
              ["Lunch Minutes"]: `${lunchLength} minutes`,
            };
            longLunchTimes.push(lunchWarning);
          }
        }
      } else if (empEntries.length === 2) {
        for (let i = 0; i < SHORT_SHIFT_PATTERN.length; i++) {
          const punchType = empEntries[i]?.["Punch Type"];
          const patternType = SHORT_SHIFT_PATTERN[i];
          if (punchType !== patternType) {
            const punchTypeWarning = {
              ["Employee Name"]: empEntries[0]?.["Employee Name"],
              ["Employee Id"]: employeeId,
              Foreman: empEntries?.[0]?.Foreman,
              Date: date,
              ["Missing Punch"]: SHORT_SHIFT_PATTERN[i],
            };
            missingPunchActions.push(punchTypeWarning);
          }
        }
      } else {
        const punchTypes = empEntries.map((el) => el?.["Punch Type"]);
        const punchTypeWarning = {
          ["Employee Name"]: empEntries[0]?.["Employee Name"],
          ["Employee Id"]: employeeId,
          Foreman: empEntries?.[0]?.Foreman,
          Date: date,
          ["Missing Punch"]: SHIFT_PATTERN.filter(
            (pt) => !punchTypes.includes(pt)
          ).join(", "),
        };
        missingPunchActions.push(punchTypeWarning);
      }

      for (let i = 0; i < empEntries.length; i++) {
        const empEntry = empEntries[i];

        if (
          !empEntry?.["Distance"] ||
          Number(empEntry?.["Distance"].replaceAll(" ft", "")) > 300
        ) {
          noJobsiteMatch.push({ ...empEntry, Date: date });
        }
      }
    }
  }

  return { missingPunchActions, noJobsiteMatch, longLunchTimes };
}

export async function getScheduledData({
  excelData,
  employees,
}: GetScheduleDataProps) {
  let employeesIncluded = [];
  const jobNames = uniqBy(excelData, (el) => el?.Location).map(
    (el) => el.Location
  );

  const employeeNames = uniqBy(excelData, (el) => el.Name).map((el) => el.Name);
  for (let i = 0; i < employeeNames.length; i++) {
    const empName = employeeNames[i];
    for (let j = 0; j < employees.length; j++) {
      const emp = employees[j];

      if (
        !!empName &&
        !!emp?.crewName &&
        areNamesSimilar(empName, emp?.crewName)
      ) {
        Object.assign(employeesIncluded, { [empName]: emp });
      }
    }
  }
  const jobCoordinates: { [index: string]: CoordinateType } = {};

  for (let i = 0; i < jobNames.length; i += 10) {
    const addresses = jobNames.slice(i, i + 10);
    const coordinates = await getBulkCoordinates({ addresses });

    for (let j = 0; j < coordinates.length; j++) {
      const jobCoordinate = coordinates[j];
      Object.assign(jobCoordinates, jobCoordinate);
    }
  }

  let employeesJobs = {};

  for (let i = 0; i < excelData.length; i++) {
    const data = excelData[i];
    if (data?.Name) {
      Object.assign(employeesJobs, {
        [data?.EmployeeId || employeesIncluded[data.Name]?.employeeId]: {
          addressPosition: jobCoordinates?.[data.Location],
          jobAddress: data.Location,
          foreman: data?.Foreman,
        },
      });
    }
  }

  return employeesJobs;
}

export const payrollReportColumns = {
  noJobsiteMatch: [
    {
      hederName: "Employee Name",
      field: "Employee Name",
      minWidth: 240,
    },
    {
      hederName: "Punch Location",
      field: "Punch Location",
      minWidth: 330,
    },
    {
      hederName: "Jobsite Match",
      field: "Jobsite Match",
      minWidth: 330,
    },
    {
      hederName: "Distance",
      field: "Distance",
      minWidth: 150,
    },
    {
      hederName: "Duration",
      field: "Duration",
      minWidth: 150,
    },
    {
      hederName: "Foreman",
      field: "Foreman",
      minWidth: 160,
    },
    {
      hederName: "Punch Type",
      field: "Punch Type",
      minWidth: 160,
    },
    {
      hederName: "Punch Time",
      field: "Punch Time",
      minWidth: 150,
    },
    {
      hederName: "Punch Date",
      field: "Punch Date",
      minWidth: 150,
    },
    {
      hederName: "Employee Id",
      field: "Employee Id",
      minWidth: 160,
    },
  ],
  longLunchTimes: [
    {
      hederName: "Employee Name",
      field: "Employee Name",
      minWidth: 240,
    },
    {
      hederName: "Employee Id",
      field: "Employee Id",
      minWidth: 150,
    },
    {
      hederName: "Foreman",
      field: "Foreman",
      minWidth: 160,
    },
    {
      hederName: "Date",
      field: "Date",
      minWidth: 150,
    },
    {
      hederName: "Lunch Minutes",
      field: "Lunch Minutes",
      minWidth: 150,
    },
  ],
  missingPunchActions: [
    {
      headerName: "Employee Name",
      field: "Employee Name",
      minWidth: 240,
    },
    {
      headerName: "Employee Id",
      field: "Employee Id",
      minWidth: 150,
    },
    {
      hederName: "Foreman",
      field: "Foreman",
      minWidth: 160,
    },
    {
      headerName: "Date",
      field: "Date",
      minWidth: 150,
    },
    {
      headerName: "Missing Punch",
      field: "Missing Punch",
      minWidth: 150,
    },
  ],
};

function normalizeName(name) {
  name = name.trim().toLowerCase();
  name = name.replace(/[^\w\s]/g, "");

  let words = name.split(/\s+/);
  words.sort();
  return words;
}

function areNamesSimilar(name1, name2) {
  const normalized_name1 = normalizeName(name1);
  const normalized_name2 = normalizeName(name2);

  return JSON.stringify(normalized_name1) === JSON.stringify(normalized_name2);
}
