import "./LineChart.scss";

const LineChart = ({ params }) => {
  return (
    <div className="LineChartDashboardStyle">
      <div className="LineChartTextContent">
        <div className="LineChartTitle">{params.title}</div>
        <div className="LineChartSubtitle">{params.subtitle}</div>
      </div>
      <div className="lineChartCount">
        ${params.count.toLocaleString("en-UK")}
      </div>

      {params.config.map((a) => (
        <div className="lineChartChartAndCount">
          <div className="count">
            <div className="mode">
              <div
                className={
                  a.data[a.data.length - 2].value >
                  a.data[a.data.length - 1].value
                    ? "arrow-down"
                    : "arrow-up"
                }
              />
              <div className="income">
                {a.data[a.data.length - 2].value >
                a.data[a.data.length - 1].value
                  ? "losses"
                  : "income"}
              </div>
            </div>
            <div className="countnumber">
              $
              {a.data
                .map((b) => b.value)
                .reduce(
                  (previousValue, currentValue, index) =>
                    previousValue + currentValue,
                  0
                )
                .toLocaleString("en-UK")}
            </div>
          </div>
          <div className="line"> {/* <Line {...a} />{" "} */}</div>
        </div>
      ))}
    </div>
  );
};

export default LineChart;
