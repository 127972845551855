import { API } from "aws-amplify";
import {
  osName,
  osVersion,
  browserName,
  browserVersion,
  deviceType,
} from "react-device-detect";
import { apiRoutes, fetchData } from "../components/SidebarPages/Fleet/utils";
import { lazyFetch, updateArrayElement, useLocalStorage } from "../utils";
import { uniqBy } from "lodash";
import { v4 as uuid } from "uuid";
import { UserConfig } from "../actions";
import { useDispatch } from "react-redux";

//updates activeSessions key in userConfiguration
const updateSession = async (body) =>
  await API.patch(
    apiRoutes.userConfiguration,
    `/${apiRoutes.userConfiguration}`,
    { body: { activeSessions: body } }
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log("Error patch userConfiguration ", error);
    });

// //Returns the current session ID
// export const getCurrentSessionId = async () => {
//   const currentSession = await Auth.currentSession()
//   const currentSessionId = currentSession?.accessToken?.jwtToken
//   return currentSessionId
// }
//

//gets user's current active sessions
export const getActiveSessions = async (user) => {
  let whichUser = user;
  // if (!user)
  //   whichUser = await lazyFetch({
  //     tableName: apiRoutes.userConfiguration,
  //     listOfKeys: ["activeSessions"],
  //   });
  if (!user)
    whichUser = await fetchData(apiRoutes.userConfiguration).catch((error) => {
      console.log("Error fetching userConfiguration ", error);
    });

  const { activeSessions = [] } = whichUser || {};
  return activeSessions;
};

/**
 ** Used for closing the current session
 *! Do not use an arrow function as it does not bind "this"
 */
function sessionsFilter({ sessionId }) {
  return sessionId !== this.sessionId;
}

export const useUserSession = () => {
  const [currentSessionId, setCurrentSessionId] = useLocalStorage(
    "sessionId",
    uuid()
  );
  const dispatch = useDispatch();

  return {
    open: async (user) =>
      await updateSession(
        uniqBy(
          [
            ...(await getActiveSessions(user)),
            {
              sessionId: currentSessionId,
              loggedIn: Date.now(),
              osName,
              osVersion,
              browserName,
              browserVersion,
              deviceType,
              ipv4: (await fetchData(apiRoutes.getIp)).split(",")[0],
            },
          ],
          "sessionId"
        )
      ).then((res) => {
        const newId = uuid();
        if (!currentSessionId || currentSessionId === "null") {
          setCurrentSessionId(newId);
        } else {
          setCurrentSessionId(currentSessionId);
        }
      }),
    close: async (user) =>
      await updateSession(
        (
          await getActiveSessions(user)
        ).filter(sessionsFilter, {
          sessionId: JSON.parse(window.localStorage.getItem("sessionId")),
        })
      ).then(() => {
        setCurrentSessionId(null);
      }),

    update: async (newValues, user) =>
      await updateSession(
        uniqBy(
          [
            ...(await getActiveSessions(user)),
            {
              sessionId: currentSessionId,
              loggedIn: Date.now(),
              osName,
              osVersion,
              browserName,
              browserVersion,
              deviceType,
              ipv4: (await fetchData(apiRoutes.getIp)).split(",")[0],
              ...newValues,
            },
          ],
          "sessionId"
        )
      ).then((res) => {
        dispatch(UserConfig(res?.user));
      }),
  };
};
