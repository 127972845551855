import { Modal } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useRealTimeReport } from "./hooks";
import { Footer, TableOptions, HeaderButtons } from "./components";
import Report from "../../../DynamicView/Pages/Overview/components/Report/Report";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import "./ReportModal.scss";
import EmailBox from "../../../Communication/components/EmailBox/EmailBox";
import { openEmailBox } from "../../../Communication/functions";

const ReportModal = ({
  title,
  gridApi,
  rowData,
  isReportModalOpen,
  modalTitle,
  setIsReportModalOpen,
  actions,
  isReport = true,
  defaultOnGeneratePDF = false,
}) => {
  const [isSelectColumn, setIsSelectColumn] = useState(true);
  const [isUseTemplate, setIsUseTemplate] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [emailBoxes, setEmailBoxes] = useState([]);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const downloadExcel = title === "Download Excel" ? true : false;

  const {
    columnDefs,
    tableColumns,
    finalRowData,
    customTemplate,
    isModalVisible,
    reportTemplate,
    tableParameters,
    openReport,
    onCancelReport,
    setTableColumns,
    setTableParameters,
  } = useRealTimeReport({
    title,
    gridApi,
    rowData,
    isUseTemplate,
    isReport,
    downloadExcel,
  });

  const onCancel = () => setIsReportModalOpen(false);

  const handleGeneratePdf = () => {
    defaultOnGeneratePDF(actions, tableColumns);
    setIsReportModalOpen(false);
  };

  return (
    <>
      <Modal
        {...{
          open: isReportModalOpen,
          onCancel,
          closeIcon: <XIcon />,
          className: `pageReportGenerator ${
            isDarkMode && "pageReportGeneratorDark"
          }`,
          title: modalTitle ?? "Base Page Report Generator",
          footer: (
            <Footer
              {...{
                onCancel,
                openReport: () => {
                  isReport ? openReport() : handleGeneratePdf();
                },
                isReport,
                actions,
                downloadExcel,
              }}
            />
          ),
          transitionName: "linear",
        }}
      >
        <div style={{ minHeight: "60vh" }}>
          {isReport && (
            <HeaderButtons
              {...{
                setIsSelectColumn,
                isUseTemplate,
                setIsUseTemplate,
                reportTemplate,
              }}
            />
          )}
          {!isUseTemplate && (
            <>
              {isSelectColumn ? (
                <TableOptions
                  {...{
                    title: "Columns",
                    columnDefs,
                    isReport,
                    options: tableColumns,
                    setOptions: setTableColumns,
                    downloadExcel,
                  }}
                />
              ) : (
                <TableOptions
                  {...{
                    title: "Parameters",
                    columnDefs,
                    isReport,
                    tableColumns,
                    options: tableParameters,
                    setOptions: setTableParameters,
                    downloadExcel,
                  }}
                />
              )}
            </>
          )}
        </div>
      </Modal>

      {isModalVisible && (
        <Report
          {...{
            isModalVisible,
            onCancel: onCancelReport,
            customData: finalRowData,
            customTemplate,
            setVisibleReport: () => {
              onCancelReport();
            },
            emailBoxes,
            setEmailBoxes,
            setAttachments,
            sendByEmail: true,
          }}
        />
      )}

      {emailBoxes?.length > 0 && (
        <div style={{ zIndex: 9994 }}>
          <EmailBox
            {...{
              rowData,
              onClose: () => {
                if (attachments.length > 0) setAttachments([]);
                setEmailBoxes([]);
              },
              attachments,
              openEmailBox,
            }}
          />
        </div>
      )}
    </>
  );
};

export default ReportModal;
