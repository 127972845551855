import { Button } from "antd";
import { filterTables } from "../../../../../../../../../utils";

export const getCategory = (
  categoryToCheck,
  categoryToReturn,
  recordDetails
) => {
  if (recordDetails?.categoryType === categoryToCheck) {
    return categoryToReturn;
  } else {
    return recordDetails?.categoryType;
  }
};

export const addToCursor = (newText, clickedElement, form) => {
  const start = clickedElement.selectionStart;
  const end = clickedElement.selectionEnd;
  const text = clickedElement.value || "";
  const before = text?.substring(0, start);
  const after = text?.substring(end, text.length);
  clickedElement.value = before + newText + after;

  clickedElement.selectionStart = clickedElement.selectionEnd =
    start + newText.length;
  clickedElement.focus();
  form.setFieldsValue({ subject: clickedElement.value });
};

export const getSingleFields = (
  dynamicFields,
  category,
  clickedElement,
  rowData,
  setEditorContent,
  form
) => {
  const filteredDynamicFields = dynamicFields?.filter(
    ({ dynamicFieldCategory, dynamicFieldRenderFor }) =>
      dynamicFieldCategory.toLowerCase() === category.toLowerCase() &&
      dynamicFieldRenderFor === "single"
  );

  const mappedDynamicFieldsFiltered = filteredDynamicFields?.filter(
    ({ dynamicFieldValue }) => {
      const value = rowData[dynamicFieldValue];

      return value !== "" && typeof value !== "object" && value !== undefined;
    }
  );

  const mappedDynamicFields = mappedDynamicFieldsFiltered?.map(
    ({ dynamicFieldLabel, dynamicFieldValue }) => ({
      key: dynamicFieldLabel,
      label: (
        <Button
          className="dropdown-item-button"
          type="link"
          key={dynamicFieldValue}
          onClick={() => {
            clickedElement
              ? addToCursor(
                  rowData[dynamicFieldValue] || "",
                  clickedElement,
                  form
                )
              : setEditorContent((prevData) => {
                  const newContent =
                    prevData +
                    (prevData ? "<br />" : "") +
                    (rowData[dynamicFieldValue] || "");
                  return newContent;
                });
          }}
        >
          {dynamicFieldLabel}
        </Button>
      ),
    })
  );

  return mappedDynamicFields;
};

export const getTableFields = (
  programFields,
  category,
  setSelectedTable,
  allDynamicFields,
  setEditorContent
) => {
  const foundProgamFields = programFields?.find(
    ({ fieldName }) => fieldName === "Dynamic Fields Subcategories"
  )?.fieldOptions[category?.toLowerCase()]?.options;

  if (!foundProgamFields) {
    return [];
  }

  const mappedTableFields = foundProgamFields?.map((tableField) => ({
    key: tableField,
    label: (
      <Button
        className="dropdown-item-button"
        type="link"
        onClick={() => {
          setSelectedTable({
            options: allDynamicFields.filter(
              ({ dynamicFieldCategory }) => dynamicFieldCategory === tableField
            ),
            label: tableField,
            editor: setEditorContent,
          });
        }}
      >
        {tableField}
      </Button>
    ),
  }));

  return mappedTableFields;
};

export const showAddedTable = async (
  template,
  programFields,
  recordDetails,
  rowData,
  setEditorContent
) => {
  var regex = /<td[^>]*>(.*?)<\/td>/g;
  const matchedItems = Array.from(template?.matchAll(regex), (x) => x);
  let newDiv = document.createElement("div");
  newDiv.innerHTML = template;
  let tableDiv = newDiv?.getElementsByTagName("table");
  let tables = [];

  for await (let table of tableDiv) {
    let tableKeys = Array.from(table.innerHTML?.matchAll(regex), (x) => x[1]);
    const relationKey = programFields?.find(
      ({ fieldName }) => fieldName === "Dynamic Fields Subcategories"
    )?.fieldOptions[recordDetails?.categoryType?.toLowerCase()].relationKey;

    await filterTables(
      table.id.toLowerCase(),
      relationKey,
      rowData[relationKey]
    ).then((record) => {
      var newRow = document.createElement("tr");
      newRow.innerHTML = `${tableKeys
        .map((e) => {
          const cleanedKey = e.replace("!", "");

          if (Array.isArray(record) && record.length > 0) {
            const value = record[0][cleanedKey];

            if (Array.isArray(value)) {
              return value
                .map((item) => {
                  if (typeof item === "object") {
                    return `<td style="border: 1px solid #000">${
                      Object.entries(item)
                        .map(([key, val]) => `${key}: ${val}`)
                        .join(", ") || "N/A"
                    }</td>`;
                  } else {
                    return `<td style="border: 1px solid #000">${
                      item || "N/A"
                    }</td>`;
                  }
                })
                .join("");
            } else if (typeof value === "object") {
              return `<td style="border: 1px solid #000">${
                Object.entries(value)
                  .map(([key, val]) => `${key}: ${val}`)
                  .join(", ") || "N/A"
              }</td>`;
            } else {
              return `<td style="border: 1px solid #000">${
                value || "N/A"
              }</td>`;
            }
          } else {
            return `<td style="border: 1px solid #000">N/A</td>`;
          }
        })
        .join("")}`;
      table.getElementsByTagName("tbody")[0].appendChild(newRow);
      table.getElementsByTagName("tr")[1].remove();

      matchedItems.forEach(
        (e) =>
          (table.innerHTML = table.innerHTML.replace(
            e[0],
            rowData[e[1]] ? rowData[e[1]] : e[0]
          ))
      );
      tables.push(`<table>${table.innerHTML}</table>`);
    });
  }

  setEditorContent((prevData) => {
    if (prevData === "<p><br></p>") {
      return tables.join("");
    }

    return prevData + tables.join("");
  });
};
