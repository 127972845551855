import React from "react";
import { formatCurrency } from "../../../../../../../../utils";
import { useStateContext } from "../../../../Context/Context";
import "./ServiceCredit.scss";
import AnimatedNumber from "react-animated-number";

function ServiceCredit({ service, color }) {
	const { darkMode, fakeApplication } = useStateContext();
	console.log("kled", service);

	return (
		<div className="service-credit-container" style={{ color: "white" }}>
			<div className="total-amount-price" style={{ backgroundColor: "orange" }}>
				CREDIT AMOUNT:{" "}
				{service?.hasCredit ? (
					<AnimatedNumber
						value={(service?.totalPrice * service?.creditPercentage) / 100}
						formatValue={(value) => formatCurrency(value)}
					/>
				) : (
					"No Credit"
				)}
			</div>
		</div>
	);
}

export default ServiceCredit;
