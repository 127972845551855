import "./Informations.scss";

import importedDayjs from "dayjs";
import { Alert } from "antd";

const timezone = require("dayjs/plugin/timezone");
importedDayjs.extend(timezone);
const dayjs = importedDayjs.tz;

const Informations = ({ lastPeriod, selectedMonth, isDarkMode }) => {
  return (
    <div className="information1">
      {!!lastPeriod &&
      dayjs(lastPeriod)?.endOf("day")?.add(1, "day")?.valueOf() <=
        dayjs(selectedMonth?.start)?.startOf("day")?.valueOf() ? (
        <div className="warningMessage">
          <Alert
            message={`${dayjs(lastPeriod)?.format("MMMM DD, YYYY")} to ${dayjs(
              selectedMonth?.start
            )
              ?.subtract(1, "day")
              ?.format("MMMM DD, YYYY")} are not included!!`}
            type="warning"
            showIcon
          />
        </div>
      ) : (
        <div
          style={{ margin: "10px ", color: isDarkMode ? "#fff" : "#333238" }}
        >
          Create an requisition by firstly, selecting a schedule of values and
          then, deciding the period in which this requisition will take place.
        </div>
      )}
    </div>
  );
};

export default Informations;
