export const getPinnedBottomRowData = (sums) => {
  return [
    {
      uploadName: null,
      degId: null,
      crewTeamName: null,
      crewTeamId: null,
      companyName: null,
      // punchDate: null,
      // punchTime: null,
      payrollType: null,
      employeeRate: sums?.employeeRate || 0,
      regAmount: sums?.regAmount || 0,
      otAmount: sums?.otAmount || 0,
      total: sums?.total || 0,
      jobsiteAddress: null,
      sow: null,
    },
  ];
};
