import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../DEG/components/modalComponents/utils/cellFunctions";
import { withinRadius } from "../../components/payrollActivityModalData";

export const selectedEntryFields = (data, jobMatch) => [
  { name: "Punch Type", value: data?.punchType },
  {
    name: "Punch Date",
    value: dayjsNY(data?.punchDate).format(DEG_DATE_FORMAT),
  },
  {
    name: "Punch Time",
    value: dayjsNY(data?.punchTime).format(DEG_TIME_FORMAT),
  },
  {
    name: "Punch Location",
    value: data?.punchLocation,
  },
  { name: "Payroll Type", value: data?.payrollType },
  {
    name: "Distance From Job",
    value: `${
      data?.distance ||
      withinRadius(data?.punchCoordinates, jobMatch?.addressPosition)
        .distanceInFeet
    } ft`,
  },
];
