import {
  addNewDay,
  days,
  inputChangeData,
  onRemoveTheShiftedDay as removeShiftedDay,
  onRemoveDay as removeDay,
  changeScheduleDayStatus as changeStatus,
} from "./requestScheduleDaysHelpers";
import { ScheduleDayView } from "../../../../../Scheduling/Subcomponents/ScheduleDayViews/ScheduleDayView";
import { useSelector } from "react-redux";
import { useRequestModalContext } from "../../../../store";

/**
 * Renders a list of schedule days for a request form.
 *
 * @component
 * @param {Function} setChanges - Callback function to set changes.
 * @returns {JSX.Element} The rendered component.
 */
const ScheduleDaysList = ({ setChanges = () => {} }) => {
  const {
    requestData: {
      selectedMainField: selectedSchedule = null,
      fetchedData: { schedules = [] },
    },
    dispatchRequestData = () => {},
  } = useRequestModalContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const setScheduleDays = (scheduleDays) => {
    setChanges(true);
    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        ...selectedSchedule,
        scheduleDays,
      },
    });
  };

  function onAddDay(dayToAddIndex, suggestedStartDate, suggestedEndDate) {
    const scheduleDaysCopy = addNewDay(
      dayToAddIndex,
      suggestedStartDate,
      suggestedEndDate,
      selectedSchedule
    );

    setScheduleDays(scheduleDaysCopy);
  }

  function onRemoveDay(id) {
    setChanges(true);
    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: removeDay(id, selectedSchedule),
    });
  }

  function onRemoveTheShiftedDay(id) {
    setChanges(true);
    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: removeShiftedDay(id, selectedSchedule),
    });
  }

  function changeScheduleDayStatus(id, modifiedSchedules) {
    setChanges(true);
    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: changeStatus(id, modifiedSchedules, selectedSchedule),
    });
  }

  function handleInputChange(index, event, reference) {
    const inputFieldsCopy = inputChangeData(
      index,
      event,
      reference,
      selectedSchedule
    );

    setScheduleDays(inputFieldsCopy);
  }

  return days(selectedSchedule?.scheduleDays || [])?.map(
    (scheduleDay, dayIndex) => (
      <div id="days-container-scroll">
        <ScheduleDayView
          {...{
            scheduleDay,
            scheduleDays: selectedSchedule?.scheduleDays,
            scheduleId: selectedSchedule?.scheduleId,
            dayIndex,
            toBeScheduled: selectedSchedule?.toBeScheduled,
            schedules,
            editMode: true,
            notesToAddForScheduleDays: {},
            onAddDay,
            fromApprovals: true,
            isDarkMode,
            handleInputChange,
            setScheduleDays,
            setAbleToSave() {},
            onRemoveDay,
            onRemoveTheShiftedDay,
            changeScheduleDayStatus,
            setDidPostpone() {},
          }}
        />
      </div>
    )
  );
};

export default ScheduleDaysList;
