/**
 * @typedef AssistantMessageType
 * @property {string} messageId
 * @property {string} text
 * @property {string|undefined} senderId
 * @property {string} senderName
 * @property {string|undefined} roomId
 * @property {string|undefined} roomName
 * @property {boolean} botMessage
 * @property {string} parentFolderId
 * @property {DriveFile[]} attachments
 * @property {boolean} messageRetried
 * @property {number} createdAt
 * @property {"sent"|"error"|"typing"|"pending"} messageStatus
 */

/**
 * The "typing" statuses ALWAYS need to be last.
 * The "pending" messages come after, since they are being sent, they some before the "sent" messages.
 * The "error" messages are sorted by date like other messages. They need to stay in that place, since the user can choose to send the message later.
 * The "pending", "error" and "typing" messages hold a message id that will be used by the actual "sent" message later.
 * This is made in order to make it simple to find, identify and remove messages when necessary and also providing a
 * consistent user experience
 *
 * If a user is "typing", all the users in the room see that except from the user that is actually typing.
 * The "pending" and "error" messages are not shared in the chat. These are simply part of the UI of the user
 *
 * @param {AssistantMessageType[]} allMessages
 * @param {AssistantMessageType} message
 */
function updateAssistantMessages(allMessages, message) {
  if (message.messageStatus === "typing") {
    // the "typing" message always needs to be the last
    let isUserTyping = false;
    for (let i = allMessages.length - 1; i >= 0; i--) {
      if (allMessages[i]["messageStatus"] !== "typing") {
        break;
      }

      if (allMessages[i]["senderId"] === message.senderId) {
        isUserTyping = true;
        break;
      }
    }

    if (isUserTyping) {
      return allMessages;
    }

    return allMessages.concat(message);
  } else if (message.messageStatus === "pending") {
    let lastTypingIndex = allMessages.length;
    for (let i = allMessages.length - 1; i >= 0; i--) {
      if (allMessages[i]["messageStatus"] !== "pending") {
        lastTypingIndex = i + 1;
        break;
      }
    }

    const tmp = [...allMessages];
    tmp.splice(lastTypingIndex, 0, message);

    return tmp;
  } else if (message.messageStatus === "sent") {
    // successfully sent messages need to attach themselves to the last sent message
    let filterIndex = -1;
    let lastSentMessage = -1;

    for (let i = allMessages.length - 1; i >= 0; i--) {
      if (
        allMessages[i]["messageStatus"] === "sent" ||
        (allMessages[i]["messageStatus"] === "error" && lastSentMessage === -1)
      ) {
        // we update the index only if we haven't found it yet
        lastSentMessage = i + 1;

        if (!message["messageRetried"]) {
          // if the sent message is a new one, we don't need to check for the message wit the same id
          break;
        }
      }

      /**
       * Since the pending messages come before the sent messages, this condition is accessed
       * before the above one. If we need to only check for "pending" the index was found in
       * a previous iteration and the loop is broken in the condition above. If we need to check
       * for error messages, we continue the loop without updating the lastSentMessage. We break the
       * loop only when we find the error message with the same id
       */
      if (allMessages[i]["messageId"] === message["messageId"]) {
        filterIndex = i;

        if (lastSentMessage > -1) {
          // if we found the last match we break the loop
          // this conditions enters only when looking for messages that could not be sent
          break;
        }
      }
    }

    if (lastSentMessage === -1) {
      lastSentMessage = 0;
    }

    const tmp = [...allMessages];
    if (filterIndex > -1) {
      tmp.splice(filterIndex, 1);

      if (filterIndex < lastSentMessage) {
        --lastSentMessage;
      }
    }

    tmp.splice(lastSentMessage, 0, message);

    return tmp;
  } else if (message.messageStatus === "error") {
    let matchingIndex = -1;
    for (let i = allMessages.length - 1; i >= 0; i--) {
      if (allMessages[i]["messageId"] === message.messageId) {
        matchingIndex = i;
        break;
      }
    }

    if (matchingIndex > -1) {
      // a message that failed was pending before, so we simply find the pending message and change it's status
      const tmp = [...allMessages];
      tmp[matchingIndex]["messageStatus"] = message.messageStatus;
      return tmp;
    }

    return allMessages;
  }
}

export default updateAssistantMessages;
