import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Radio,
  Button,
  message,
  Popover,
  Tour,
  Avatar,
  Tooltip,
} from "antd";
import "./OpportunityModal.scss";
import {
  BorderedTextCard,
  InputComponent,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import {
  filterTables,
  getAccessRights,
  getCognitosForNotification,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../utils";
import {
  OPPORTUNITY_INFORMATION,
  PROJECT_INFORMATION,
  JOBSITE_ADDRESS,
  fieldsJSON,
  footerButtons,
  body,
} from "./opportunityModalData";
import {
  apiRoutes,
  fetchData,
  getCoordinatesAndZip,
  validateForm,
} from "../../../SidebarPages/Fleet/utils";
import { compareIncluding, getRandomColor } from "../../../SidebarPages/utils";
import dayjs from "dayjs";
import axios from "axios";
import {
  MondayButton,
  ProgressComponent,
  SortModalFields,
} from "../../../commonComponents";
import { API } from "aws-amplify";
import { findCommons, findUnCommons } from "../../../../utils";
import { AlternativeAddresses, RenderDynamicComponents } from "../Components";
import LeadModal from "../LeadModal/LeadModal";
import { ClientModal, ContactModal } from "..";
import { CompanyNameDropdown, DobInfoModal } from "./components";
import { useSelector } from "react-redux";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { ReactComponent as CloseIcon } from "../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warningTriangle.svg";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import { difference } from "lodash";
import { useDuplicatePrevention } from "../../../SidebarPages/hooks";
import { ContactsDropDown } from "../ProjectModal/components/OverviewStep/components";
import { filterPeopleList } from "../../../SidebarPages/utils/filterPeopleList";
import {
  TickIcon,
  DobButtonIcon,
} from "../../../pages/Settings/settingsComponents/Roles/src";
import { getAddressComponent } from "../../../SidebarPages/Fleet/utils";
import { quickSort } from "../../../../helpers/SortingAlgorithms";
import { getBinNumber } from "../../../SidebarPages/Projects/DobTab/utils";
import Swal from "sweetalert2";
import isEmpty from "lodash/isEmpty";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { AnimatedCheckBox } from "../../../SidebarPages/Fleet/components/InputComponent/components";
import { DropDownArrow } from "../../../SidebarPages/Fleet/components/InputComponent/assets";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { PlusIcon } from "../../../../assets";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../components/ModalVideoTutorials/assets/svg";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import { DynamicAvatars } from "../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { useLoadScript } from "@react-google-maps/api";
import { driveApi } from "../../../../integrations/DriveRequest";
import Geofence from "../../../commonComponents/Map/Geofence/Geofence";
import { TransferIconNew } from "../../../SidebarPages/DynamicView/src";
import { useEditLogs, useProgressComponent } from "../../../../hooks";
import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";

const OpportunityModal = ({
  visible,
  setVisible,
  rowData,
  oppObject = {},
  changeOrderObject,
  refreshTable = () => {},
  changeOrderData,
  onMultiStepCancel = () => {},
  estimationChangeOrder = false,
  isNextStep,
  requestId = "",
  afterSaveRequest = async () => {},
}) => {
  const { accountName, projectExecutive } = oppObject;

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const opportunityAddressForm = Form.useWatch("opportunityAddress", form);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [typesOfWork, setTypesOfWork] = useState([]);
  const [typesOfWorkKeys, setTypesOfWorkKeys] = useState([]);
  const [constructionServices, setConstructionServices] = useState([]);
  const [constructionServicesKeys, setConstructionServicesKeys] = useState([]);
  const [projectExecutives, setProjectExecutives] = useState([]);
  const [clients, setClients] = useState([]);
  const [leads, setLeads] = useState([]);
  const [selectOptions, setSelectOptions] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [taxRatePopOverVisible, setTaxRatePopOverVisible] = useState(false);
  const [alternativeAddressesVisible, setAlternativeAddressesVisible] =
    useState(false);
  const [opportunityAddress, setOpportunityAddress] = useState("");
  const [taxRate, setTaxRate] = useState(0);
  const [addLatLngAndZip, setAddLatLngAndZip] = useState();
  const [contacts, setContacts] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [sources, setSources] = useState([]);
  const [users, setUsers] = useState([]);
  const [altAddresses, setAltAddresses] = useState([]);
  const [altAddressesLatLng, setAltAddressesLatLng] = useState([]);
  const [folderDriveId, setFolderDriveId] = useState();
  const [subFolderDriveId, setSubFolderDriveId] = useState();
  const [workDetail, setWorkDetail] = useState();
  const [buildingDimensions, setBuildingDimensions] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [leadModalVisible, setLeadModalVisible] = useState(false);
  const [clientModalVisible, setClientModalVisible] = useState();
  const [fetchedOpportunities, setFetchedOpportunities] = useState([]);
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState();
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [dobInfoModalVisible, setDobInfoModalVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [oppurtunityTeam, setOppurtunityTeam] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [dobInformation, setDobInformation] = useState({});
  const [uploadFile, setUploadFile] = useState();
  const [requiredBidDueDate, setRequiredBidDueDate] = useState(true);
  const [documentationVisible, setDocumentationVisible] = useState(false);
  const [docConfiguration, setDocConfiguration] = useState({});
  const [isChangeOrderState, setIsChangeOrder] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [geoFenceInfo, setGeoFenceInfo] = useState(
    changeOrderData?.geoFenceInfo ? changeOrderData.geoFenceInfo : []
  );
  const [sortOpen, setSortOpen] = useState(false);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.opportunityModal || {}
  );

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Opportunities",
    actionType: "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  const driveFunctions = useCreateDriveFolders("Opportunities");

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Sales")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Opportunity"
    )?.videoLink;

  // const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const selectedClient =
    clients?.find(({ accountId }) => accountId === selectedCompanyName) || {};

  const {
    accountId,
    primaryContactId,
    googleDriveFolderIds: clientDriveId = [],
    teamsConfiguration: clientTeamsConfiguration,
    projectExecutive: clientProjectExecutive,
    leadSalePerson: clientLeadSalePerson,
  } = selectedClient;

  const selectedLead =
    leads?.find(({ leadId }) => leadId === selectedCompanyName) || {};

  const {
    googleDriveFolderIds: leadDriveId = [],
    teamsConfiguration: leadTeamsConfiguration,
    projectExecutive: leadProjectExecutive,
    leadSalePerson,
  } = selectedLead;

  const primaryContact = useMemo(
    () => contacts.find(({ contactId }) => contactId === primaryContactId),
    [primaryContactId]
  );

  const onDuplication = () => {
    message.warning("There is an existing opportunity with this information!");
    form.resetFields(["opportunityAddress", "accountName"]);
    setSelectedCompanyName("");
    setOpportunityAddress(null);
  };

  const existingOpportunity = useDuplicatePrevention({
    listOfRecords: fetchedOpportunities,
    filter: !isEmpty(selectedClient)
      ? {
          accountId: selectedClient?.accountId,
          opportunityAddress:
            opportunityAddress || form.getFieldValue("opportunityAddress"),
        }
      : {
          leadId: selectedLead?.leadId,
          opportunityAddress:
            opportunityAddress || form.getFieldValue("opportunityAddress"),
        },
    notEqualFilter: { isChangeOrder: true },
    compareIncludingKeys: !isEmpty(selectedClient)
      ? ["opportunityAddress", "accountId"]
      : ["opportunityAddress", "leadId"],
    onDuplication,
    doNotPrevent: !!changeOrderData,
    startFiltering:
      !!opportunityAddress ||
      (form.getFieldValue("opportunityAddress") && !!selectedCompanyName),
  });

  const driveParentId =
    !!selectedCompanyName && !!accountId
      ? clientDriveId?.opportunitiesObject
      : leadDriveId?.opportunitiesObject;

  const filteredContacts = useMemo(
    () =>
      contacts?.filter(({ contactAccountName }) =>
        isEmpty(selectedClient)
          ? contactAccountName === selectedLead?.leadCompany
          : contactAccountName === selectedClient?.accountName
      ),
    [selectedClient, selectedLead, contacts]
  );

  const leadId = selectedLead?.leadId || selectedClient?.leadId;

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;
  const bodyObject = () =>
    body({
      form,
      typesOfWorkKeys,
      constructionServicesKeys,
      // zipCode: addLatLngAndZip?.zipCode,
      zipCode: form.getFieldValue("opportunityAddPostalCode"),
      accountId,
      leadId,
      clients,
      leads,
      // aptNumber: form.getFieldValue("opportunityAptNumber"),
      requestReceivedDate: form.getFieldValue("requestReceivedDate"),
      //   projectId: changeOrderData?.projectId,
      googleDriveUploads: [],
      googleDriveFolderIds: {
        ...(driveFunctions?.allFolders || {}),
      },
      buildingDimensions,
      workDetail,
      opportunityLatitude: addLatLngAndZip?.coordinates?.lat,
      opportunityLongitude: addLatLngAndZip?.coordinates?.lng,
      opportunityPlaceId: addLatLngAndZip?.place_id,
    });

  const onTaxExemptSelect = (value) => {
    compareIncluding(value, "Partial")
      ? setTaxRatePopOverVisible(true)
      : setTaxRatePopOverVisible(false);

    form.setFieldsValue({
      taxRate: compareIncluding(value, "Yes") ? 0 : taxRate,
    });
  };

  //gets the names and ids of the uploaded files
  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    let uploadedFileNames = [];
    for (const item of uploadedFiles) {
      const fileNameObj = { ...item, uploadedAt: dayjs() };
      uploadedFileNames.push(fileNameObj);
    }
    setFileNames(
      !![...fileNames]?.filter((item) =>
        compareIncluding(item.uploaderId, uploaderId)
      ).length
        ? [...fileNames]?.map((item) =>
            !compareIncluding(item?.uploaderId, uploaderId)
              ? item
              : {
                  ...item,
                  files: [...item?.files, ...uploadedFileNames],
                }
          )
        : [...fileNames, { uploaderId, files: [...uploadedFileNames] }]
    );
  };

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data?.value,
        teamId: data?.teamId,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const openOppurtunityTeamModal = () => {
    setOpen(true);
  };

  const onDeselect = (_, data) => {
    setSelectedTeam((prev) =>
      prev
        ?.filter((el) => el.value !== data.value)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
    if (form.getFieldValue("oppurtunityTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const checkAccess = useMemo(() => {
    function findAccess(titleName) {
      return userConfiguration?.routeConfig?.find(
        ({ title }) => title === titleName
      );
    }
    let clientAccess = findAccess("Accounts");
    let leadAccess = findAccess("Leads");
    return {
      clientAccess,
      leadAccess,
    };
  }, [userConfiguration]);

  const ClearOptions = () => {
    setOpen(false);
    // form.resetFields(["oppurtunityTeam"]);
  };

  //region FIELDS DATA
  const inputFields = fieldsJSON({
    form,
    onSelect,
    onDeselect,
    accountName,
    uploadFile,
    setUploadFile,
    isDarkMode,
    requiredBidDueDate,
    setRequiredBidDueDate,
    setDocumentationVisible,
    fetchedOpportunities,
    // authenticatedUser:
    //   authenticatedUser?.given_name + " " + authenticatedUser?.family_name,
    typesOfWork: typesOfWork
      ?.filter(({ isScope }) => !isScope)
      ?.map(({ workName, workId }) => ({
        key: workId,
        title: workName,
      })),
    constructionServices: constructionServices?.map(
      ({ serviceName, serviceId }) => ({
        key: serviceId,
        title: serviceName,
      })
    ),
    projectExecutives: projectExecutives?.map(
      ({ firstName, lastName }) => `${firstName} ${lastName}`
    ),
    projectManagersOptions:
      programFields
        ?.find((prog) => prog.fieldName === "Project Managers")
        ?.fieldOptions?.map((manager) => ({
          ...manager,
          label: manager.nameOfUser,
          value: manager.nameOfUser,
        })) || [],
    clients: clients
      ?.map(({ accountName, accountId }) => ({
        label: accountName,
        value: accountId,
      }))
      ?.filter(Boolean),
    leads: leads
      ?.filter(({ leadStatus }) => leadStatus !== "Converted")
      ?.map(({ leadCompany, leadId }) => ({
        label: leadCompany,
        value: leadId,
      }))
      ?.filter(Boolean),
    opportunityStages: selectOptions?.opportunityStages
      ?.filter(({ status }) => status)
      .map(({ statusName }) => statusName),
    sources,
    // users: users?.map(({ nameOfUser }) => `${nameOfUser}`),
    contacts: filteredContacts,
    isContactDisabled: !selectedCompanyName,
    workDetail,
    buildingDimensions,
    accessToken,
    fileNames,
    parentId: driveFunctions?.allFolders?.["Scope Sheet/Bid Form"],
    taxRatePopOverVisible,
    changeOrderData,
    onPickerSuccess,
    onTaxExemptSelect,
    alternativeAddressesVisible,
    typesOfWorkKeys,
    setTypesOfWorkKeys,
    constructionServicesKeys,
    selectedPrimaryContact,
    setSelectedPrimaryContact,
    setConstructionServicesKeys,
    setSelectedCompanyName,
    setWorkDetail,
    setBuildingDimensions,
    setOpportunityAddress,
    oppurtunityTeam: oppurtunityTeam?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      teamId: [team.teamId],
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
    recordTypes: selectOptions?.recordTypes?.map(({ type }) => type),
    salesPerson: selectOptions?.salesPerson,
    taxExemptOptions: selectOptions?.taxExemption?.map(({ name }) => name),
    laborTypes: selectOptions.laborTypes,
    insuranceTypes: selectOptions.insuranceTypes,
    checkAccess,

    AlternativeAddressesComp: (
      <AlternativeAddresses
        form={form}
        setVisible={setAlternativeAddressesVisible}
        {...{ altAddresses, setAltAddresses }}
      />
    ),
    AlternativeAddressesTitle: (
      <span className="opportunityModalPopoverTitle">
        Alternative Addresses
      </span>
    ),
    companyNameDropdown: (menu) => (
      <CompanyNameDropdown
        {...{ menu, setClientModalVisible, setLeadModalVisible, checkAccess }}
      />
    ),
    contactsDropDown: (menu) => (
      <ContactsDropDown {...{ menu, setContactModalVisible }} />
    ),
  });

  const {
    [PROJECT_INFORMATION]: projectInfoFields,
    [OPPORTUNITY_INFORMATION]: oppInfoFields,
    [JOBSITE_ADDRESS]: jobAddres,
  } = inputFields;

  oppInfoFields?.sort((a, b) => {
    if (Object.keys(sortFieldsObj).length === 0) {
      return 0;
    }
    const aIndex = sortFieldsObj[a.formItemName];
    const bIndex = sortFieldsObj[b.formItemName];
    return aIndex - bIndex;
  });

  const getTaxRateAndCoordinates = async () => {
    const taxExempt = form.getFieldValue("taxExempt") || "";

    const locInfo = await getCoordinatesAndZip(opportunityAddress);
    !!locInfo.zipCode &&
      (await axios
        .post(
          "https://leadmanager-express-backend.ue.r.appspot.com/api/salesTax",
          {
            postalCode: locInfo.zipCode,
          }
        )
        .then((response) => {
          const resTaxRate =
            parseFloat(response.data?.results[0]?.taxSales) * 100;
          form.setFieldsValue({
            taxRate: compareIncluding(taxExempt, "yes") ? 0 : resTaxRate,
          });
          setTaxRate(resTaxRate);
        }));

    setAddLatLngAndZip(locInfo);
  };

  const resetForm = () => {
    const formItemNames = Object.keys(form.getFieldsValue());

    //if we are creating inside a record, do not reset the record values
    !!accountName
      ? form.resetFields(difference(formItemNames, Object.keys(oppObject)))
      : form.resetFields();

    setSelectedCompanyName("");
    setOpportunityAddress("");
    setAddLatLngAndZip("");
    setTaxRate(0);
    setTaxRatePopOverVisible(false);
    driveFunctions?.reset();
    setFolderDriveId("");
    setSubFolderDriveId("");
    setFileNames([]);
    setAltAddresses(0);
    setConstructionServicesKeys([]);
    setTypesOfWorkKeys([]);
    setBuildingDimensions("");
    setWorkDetail("");
    setAltAddressesLatLng([]);
    setCancelModalVisible(false);
    setSaving(false);
  };

  const onCancel = () => {
    onMultiStepCancel(0);
    driveFunctions.delete().catch((e) => {
      console.error(e);
    });
    setFormChanged(false);
    setVisible(false);
    setCancelModalVisible(true);
    resetForm();
    // onMultiStepCancel(0);
  };

  // const onCancelModal = () => {
  //   setVisible(false);
  // };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const validateOppForm = async (onSuccessFunc) => {
    const oppAddress = form.getFieldValue("opportunityAddress") || "";
    if (oppAddress?.replace(/\s/g, "").length >= 3) {
      await validateForm(form, onSuccessFunc);
    } else {
      message.error(
        "The Jobsite Address needs to be more than 3 characters long !"
      );
    }
  };

  const addBorough = () => {
    const formattedAddDetails = addLatLngAndZip?.address_components?.reduce(
      (acc, { long_name, types = [] }) => ({
        ...acc,
        [types[0]]: long_name,
      }),
      {}
    );

    return formattedAddDetails?.political || formattedAddDetails?.locality;
  };
  //region ONSAVE
  const onSave = async (action) => {
    let res;
    const {
      primaryContact,
      contacts = [],
      opportunityStage: formOpportunityStage,
    } = form.getFieldsValue();

    const contactIdObj = await filteredContacts.reduce(
      (
        acc,
        {
          contactFirstName = "",
          contactLastName = "",
          contactId,
          contactEmail = "",
          contactRole = "",
        }
      ) => ({
        ...acc,
        [contactId]: {
          id: contactId,
          name: `${contactFirstName} ${contactLastName}`.trim() || contactEmail,
          email: contactEmail,
          role: contactRole,
        },
      }),
      {}
    );

    const { oppurtunityTeam, projectExecutive, ...otherBodyValues } =
      bodyObject();

    function findAccountManager() {
      if (!projectExecutive) return "";
      let foundAccountManager = projectExecutives?.find(
        (el) =>
          el?.firstName === projectExecutive?.split(" ")[0] &&
          el?.lastName === projectExecutive?.split(" ")[1]
      ).accountManager[0];
      return foundAccountManager || "";
    }

    showLoadingMsg();
    // let changeOderObj = {
    //   opportunityStage: "Change Order",
    //   opportunityType: "changeOrder",
    //   projectId: changeOrderData?.projectId,
    // };

    setSaving(true);

    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam,
      [],
      true
    );
    setSelectedTeam([]);

    let body = {
      ...otherBodyValues,
      projectExecutive,
      geoFenceInfo,
      borough: addBorough(),
      accountManager: findAccountManager(),
      teamsConfiguration,
      // projectId: changeOrderData?.projectId,
      primaryContact: contactIdObj?.[primaryContact],
      contacts: contacts?.map?.((contact) => contactIdObj?.[contact]),
      binNumber: dobInformation?.binNumber || "",
      dobAddress: dobInformation?.dobAddress || "",
      dobBlock: dobInformation?.dobBlock || "",
      dobLot: dobInformation?.dobLot || "",
      isChangeOrder: !!changeOrderData === true,
      opportunityStage: !!changeOrderData
        ? "New - Not yet started"
        : formOpportunityStage,
      // opportunityType: !!changeOrderData,
      projectId: !!changeOrderData ? changeOrderData?.projectId : "",
      alternativeAddresses: altAddressesLatLng.map(
        ({
          formatted_address,
          coordinates = {},
          // address_components = [],
        }) => ({
          address: formatted_address,
          lat: coordinates?.lat,
          lng: coordinates?.lng,
          // aptNumber:
          //   address_components?.find((el) => el.types[0] === "subpremise")
          //     ?.long_name || "",
        })
      ),
    };
    try {
      if (Object.keys(body.googleDriveFolderIds).length === 0) {
        const parentId = !!changeOrderData
          ? changeOrderData?.googleDriveFolderIds?.changeOrderObject
          : driveParentId;

        const parentFolderName = !!changeOrderData
          ? `${opportunityAddress} ${dayjs.tz().format("DD/MM/YYYY HH:mm:ss")}`
          : opportunityAddress;

        const { folders } = await driveFunctions.create({
          parentFolderName,
          parentId,
        });
        body.googleDriveFolderIds = folders;
      }
    } catch (error) {
      console.log(error);
      body.googleDriveFolderIds = null;
    }
    setVisibleCreationProgress({ action });
    updateProgressStatus({ updatingRecord: "executing" });
    await API.post(apiRoutes.opportunities, `/${apiRoutes.opportunities}`, {
      body: { ...body },
    })
      .then(async (r) => {
        setVisibleCreationProgress({ ...r, action });
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });

        if (!!requestId) {
          await afterSaveRequest({
            newRecordId: r?.opportunityId,
            // requestId,
            path: "opportunities",
            // cognitoUserId: userConfiguration?.cognitoUserId,
          });
        }
        saveAddedLogs({
          recordId: r?.opportunityId,
          recordName: r?.jobSiteAddress,
          category: "Opportunities",
          topic: r?.isChangeOrder ? "Change Order" : "",
        });

        if (
          fileNames.flatMap(({ files }) => files).length &&
          driveFunctions?.allFolders?.["Scope Sheet/Bid Form"] &&
          !!uploadFile
        ) {
          await API.post("documentation", "/documentation", {
            body: {
              categoryName: "Opportunities",
              docLogs: [
                {
                  member: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
                  nameOfUser: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
                  updatedAt: Date.now(),
                  updatedKeys: [{ label: "Created" }],
                },
              ],
              docObject: docConfiguration.docObject,
              docStatus: "On Hold",
              docType: "Scope Sheet/Bid Form",
              expirationDate: "",
              folderId: driveFunctions?.allFolders?.["Scope Sheet/Bid Form"],
              googleDriveUploads: fileNames?.flatMap(({ files }) => files),
              recordId: r.opportunityId,
              recordName: r.opportunityAddress,
              renewalId: "",
              requestedDate: "",
            },
          })
            .then((res) => {
              saveAddedLogs({
                recordId: res?.docId,
                recordName: `${res?.docType} ${
                  !!res?.renewalId && "(Renewal)"
                }`,
                category: "Documentation",
                topic: res?.recordName,
              });
            })
            .catch((e) => console.error(e));
        } else {
          await driveFunctions.delete("Scope Sheet/Bid Form");
        }

        API.patch(apiRoutes.leads, `/${apiRoutes.leads}/${r?.leadId}`, {
          body: { leadStatus: "In Process" },
        }).catch((e) => {
          if (r?.leadId && r?.leadId !== "")
            message.error("Lead status was not updated successfully!");
        });

        showSuccessMsg();
        resetForm();
        refreshTable([r]);
        res = r;
        await broadcastNotification(
          "2",
          "onCreation",
          [
            { common: user },
            {
              userName: user,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotification(
                userConfiguration,
                teamsConfiguration
              ),
            },
          ],
          r.opportunityId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      })
      .catch((error) => {
        console.log("opp:847", { error });
        updateProgressStatus({ updatingRecord: "hasError" });
      });
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
    return res;
  };

  const onSaveAndContinue = () => {
    if (uploadFile) {
      if (fileNames[0]?.files?.length) {
        onSave("onSaveAndContinue");
      } else {
        message.error("Please upload a file");
      }
    } else {
      onSave("onSaveAndContinue");
    }
  };

  const onFinish = () => {
    if (uploadFile) {
      if (fileNames[0]?.files?.length) {
        onSave("onFinish");
      } else {
        message.error("Please upload a file");
      }
    } else {
      onSave();
    }
  };

  const onOptionClick = ({ key }) => {
    compareIncluding(key, "Save & Close")
      ? validateOppForm(onFinish)
      : validateOppForm(onSave);
  };

  const getArraysLatLng = async (arr = []) =>
    await Promise.all(
      arr.map(
        async (el) =>
          await {
            ...(await getCoordinatesAndZip(el)),
            color: getRandomColor(),
          }
      )
    );

  const fetchClients = async () => {
    filterTables("accounts", "accountRecordType", "Client").then((r) => {
      setClients(
        quickSort(r, 0, r.length - 1, "createdAt", "descending")?.filter(
          (acc) => acc?.activeClient === true
        )
      );
    });
  };

  const fetchContacts = async () => {
    await fetchData("contacts").then((res) => {
      setContacts(quickSort(res, 0, res.length - 1, "createdAt", "descending"));
    });
  };

  const onLeadCreation = async (newLead) => {
    setLeads((prev) => newLead.concat(prev));
    fetchContacts();
  };

  const fetchLeads = async () => {
    fetchData("leads").then((r) =>
      setLeads(
        quickSort(r, 0, r?.length - 1, "createdAt", "descending")?.filter(
          (lead) => lead?.leadStatus !== "Unqualified"
        ) || []
      )
    );
  };

  useEffect(() => {
    if (!!accountName) {
      form.setFieldsValue({ ...oppObject });
      setSelectedCompanyName(accountName);
    }
  }, [accountName, visible]);

  useEffect(() => {
    if (projectExecutive) {
      form.setFieldsValue(oppObject);
    }
  }, [projectExecutive]);

  useEffect(() => {
    if (!changeOrderData) return;

    const {
      projectName,
      primaryContact,
      contacts = [],
      accountName,
      taxRate,
      taxExempt,
      laborRequirement,
      insuranceRequirement,
      projectExecutive,
      projectManager,
      salesPerson,
      dobTextArea,
      dobAddress,
      dobBlock,
      dobLot,
      teamsConfiguration: changeOrderTeamsConfiguration,
      isChangeOrder: changeOrderIsChangeOrder,
      proposalStartDate,
    } = changeOrderData;

    form.setFieldsValue({
      opportunityAddress: projectName,
      opportunityName: rowData?.[0]?.opportunityName || projectName,
      alternativeAddresses: changeOrderData?.alternativeAddresses?.map(
        (el) => el?.address
      ),
      primaryContact: primaryContact?.id,
      contacts: contacts
        ?.filter?.((el) => el?.id !== primaryContact?.id)
        ?.map?.((el) => el?.id),
      taxRate: +(taxRate || 0) * 100,
      taxExempt,
      laborRequirement,
      projectExecutive,
      projectManager: projectManager?.map((manager) =>
        typeof manager === "string"
          ? manager
          : {
              ...manager,
              label: manager.nameOfUser,
              value: manager.nameOfUser,
            }
      ),
      salesPerson,
      dobTextArea,
      accountName:
        clients?.find((el) => el?.accountName === accountName)?.accountId ||
        leads?.find((el) => el?.leadCompany === accountName)?.leadId ||
        accountName,
      dobAddress,
      dobBlock,
      dobLot,
      insuranceRequirement: insuranceRequirement?.length
        ? insuranceRequirement
        : undefined,
      oppurtunityTeam: changeOrderTeamsConfiguration
        ?.map((el) => el?.value)
        ?.filter(Boolean) || ["Team"],
      proposalStartDate: !!proposalStartDate
        ? dayjs(proposalStartDate)
        : undefined,
    });

    if (changeOrderData?.proposedTypeOfWork?.length) {
      setTypesOfWorkKeys(changeOrderData?.proposedTypeOfWork);
    }

    //splits the address into smaller components
    getAddressComponent(projectName, false, "").then((res) => {
      // form.setFieldValue("opportunityAptNumber", res.aptNumber);
      form.setFieldValue("opportunityAddCity", res.city);
      form.setFieldValue("opportunityAddState", res.state);
      form.setFieldValue("opportunityAddStreet", res.street);
      form.setFieldValue("opportunityAddPostalCode", res.zipCode);
    });
    if (changeOrderTeamsConfiguration?.length) {
      setSelectedTeam(changeOrderTeamsConfiguration);
    }
    setOpportunityAddress(projectName);
    setSelectedCompanyName(
      clients?.find((el) => el?.accountName === accountName)?.accountId ||
        leads?.find((el) => el?.leadCompany === accountName)?.leadId ||
        accountName
    );
    setAltAddresses(changeOrderData?.alternativeAddresses?.map((el) => el));
    setSelectedPrimaryContact(primaryContact?.id);
    setIsChangeOrder(changeOrderIsChangeOrder);
  }, [changeOrderData, clients, leads]);

  useEffect(() => {
    if (visible) {
      fetchLeads();
      fetchClients();
      fetchData(apiRoutes.proposedTypeOfWork).then((r) => setTypesOfWork(r));
      fetchData(apiRoutes.proposedConstructionServices).then((r) =>
        setConstructionServices(r)
      );
      setUsers(filterPeopleList(userConfiguration.allUsers?.Items));
      fetchData(apiRoutes.projectExecutives).then((r) =>
        setProjectExecutives(r)
      );
      fetchContacts();
      fetchAllData("opportunities", "opportunities", "opportunityId").then(
        (res) => {
          setFetchedOpportunities(res);
        }
      );
    }
    form.setFieldsValue(projectExecutive);
    (async function fetchTeams() {
      await API.get("teams", "/teams").then((r) => setOppurtunityTeam(r));
    })();
    setInitialValues(form.getFieldsValue());

    (async () => {
      await filterTables(
        "docConfiguration",
        "categoryName",
        "Opportunities"
      ).then((r) => {
        const scopeSheetConf = r[0].documentationsAvailable.find(
          (el) => el.docName === "Scope Sheet/Bid Form"
        );
        setDocConfiguration({
          docObject: scopeSheetConf.docObject,
          docStatuses: scopeSheetConf.docStatuses,
        });
      });
    })();
  }, []);

  useEffect(() => {
    if (!!programFields) {
      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );

      setSelectOptions({
        opportunityStages: programFields.find(
          (item) => item.fieldName === "Statuses Of Categories"
        ).fieldOptions["Opportunities"],
        taxExemption: fieldObj["Tax Exempt options"],
        salesPerson: fieldObj["Sales person"],
        recordTypes: fieldObj["Opportunity Record Type"],
        laborTypes: fieldObj["Labor Types"],
        insuranceTypes: fieldObj["Insurance Types"],
        preQualifications: fieldObj["Prequalification Stages"],
      });
      setSources(fieldObj["Opportunity Source"]);
    }
  }, [programFields]);

  useEffect(() => {
    if (!!opportunityAddress) {
      getTaxRateAndCoordinates();
      form.setFieldsValue({ opportunityName: opportunityAddress });
    }
  }, [opportunityAddress]);

  useEffect(() => {
    !!altAddresses?.length
      ? getArraysLatLng(altAddresses?.map((el) => el.address)).then((r) => {
          const commonItems = findCommons(altAddressesLatLng, r, "coordinates");
          const newItems = findUnCommons(r, altAddressesLatLng, "coordinates");
          setAltAddressesLatLng([...commonItems, ...newItems]);
        })
      : setAltAddressesLatLng([]);
  }, [altAddresses]);

  useEffect(() => {
    (async () => {
      if (
        !!driveParentId &&
        !!selectedCompanyName &&
        !!opportunityAddress &&
        Object.keys(existingOpportunity || {}).length === 0
      ) {
        const parentId =
          !!changeOrderData && changeOrderData?.isChangeOrder
            ? changeOrderData?.googleDriveFolderIds?.changeOrderObject
            : driveParentId;

        const parentFolderName = !!changeOrderData
          ? `${opportunityAddress} ${dayjs.tz().format("DD/MM/YYYY HH:mm:ss")}`
          : opportunityAddress;
        if (!driveFunctions?.parentFolderId) {
          await driveFunctions.create({
            parentFolderName: parentFolderName,
            parentId: parentId,
          });
        } else {
          await driveFunctions.delete();
          await driveFunctions.create({
            parentFolderName: parentFolderName,
            parentId: parentId,
          });
        }
      }
    })();
  }, [
    opportunityAddress,
    selectedCompanyName,
    driveParentId,
    existingOpportunity,
  ]);

  useEffect(() => {
    !!primaryContact && console.log("Setting Fields Value");
    form.setFieldsValue({
      primaryContact: primaryContact,
    });
  }, [primaryContact]);

  // useEffect(() => {
  //   setConvertedUsersList(filterPeopleList(userConfiguration.allUsers?.Items));
  // }, [userConfiguration]);

  useEffect(() => {
    !!driveParentId &&
      !!selectedCompanyName &&
      !!opportunityAddress &&
      (async () => {
        const binNumber = await getBinNumber(opportunityAddress);
        fetch(`https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${binNumber}
    `)
          .then((resp) =>
            resp
              .json()
              .then((r) => {
                if (r.length) {
                  let dobAddress =
                    r[0]?.street_name && r[0]?.borough
                      ? `${r[0].street_name} , ${r[0].borough}`
                      : "";
                  setDobInformation({
                    binNumber: binNumber || "",
                    dobAddress: dobAddress,
                    dobBlock: r[0]?.block || "",
                    dobLot: r[0]?.lot || "",
                  });
                } else throw new Error("No data found");
              })
              .catch((e) => {
                message.info({
                  content: "No dob information was found!",
                  key: "dobInfo",
                  duration: 2,
                });
              })
          )
          .catch(() => {
            message.info({
              content:
                "Something went wrong with dob information, please contact the responsible department!",
              key: "dobInfo",
              duration: 3,
            });
          });
      })();
  }, [driveParentId, selectedCompanyName, opportunityAddress]);

  useEffect(() => {
    if (!!changeOrderData) return;
    const valuesToAdd = {
      oppurtunityTeam:
        Array.isArray(clientTeamsConfiguration) &&
        clientTeamsConfiguration.length
          ? clientTeamsConfiguration
          : Array.isArray(leadTeamsConfiguration) &&
            leadTeamsConfiguration.length
          ? leadTeamsConfiguration
          : undefined,
      salesPerson: clientLeadSalePerson || leadSalePerson || undefined,
      projectExecutive:
        clientProjectExecutive || leadProjectExecutive || undefined,
    };
    const oldValues = form.getFieldsValue();
    form.setFieldsValue({
      ...oldValues,
      ...valuesToAdd,
    });
    if (!!valuesToAdd?.oppurtunityTeam) {
      function getTeamId(teamName) {
        let teamId = oppurtunityTeam?.find(
          (e) => e?.teamName === teamName
        )?.teamId;
        return !!teamId ? [teamId] : [];
      }
      let newOppurtunityTeam = valuesToAdd?.oppurtunityTeam.map((el) => ({
        ...el,
        teamId: !!el.teamId ? el.teamId : getTeamId(el.value),
      }));

      form.setFieldsValue({
        oppurtunityTeam: valuesToAdd?.oppurtunityTeam?.map((el) => el?.value),
      });
      setSelectedTeam(newOppurtunityTeam || []);
    }
  }, [clientTeamsConfiguration, leadTeamsConfiguration]);

  const handleAddressDecomposition = async (fullAddress) => {
    const addressObj = await getAddressComponent(fullAddress, false, "");
    form.setFieldsValue({
      opportunityAddStreet: addressObj?.street,
      opportunityAddCity: addressObj?.city,
      opportunityAddState: addressObj?.state,
      opportunityAddPostalCode: addressObj?.zipCode,
    });
  };

  useEffect(() => {
    if (!!opportunityAddress || !!opportunityAddressForm) {
      handleAddressDecomposition(changeOrderData?.projectName);
    }
  }, [opportunityAddress, opportunityAddressForm]);

  const coordinatesObj = [
    {
      coordinates: {
        lat: addLatLngAndZip?.coordinates?.lat || null,
        lng: addLatLngAndZip?.coordinates?.lng || null,
      },
      address: addLatLngAndZip?.formatted_address,
    },
  ];

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "opportunityModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Record Type": document.querySelector(".bodyTypeRadio"),
      "Required Fields": document.querySelector(".opportunityAddress"),
      "Jobsite Address": document.querySelector(".addressCard"),
      "Alternative Address": document.querySelector(".addAddressIcon"),
      "Opportunity Information": document.querySelector(
        ".opportunityInformation"
      ),
      "Company Name": document.querySelector(
        ".opportunityInformation__Company"
      ),
      "Project Information": document.querySelector(".projectInfosContainer"),
      "Proposed Type Of Work": document.querySelector(".transferComp"),
      "Work Detail": document.querySelector(".formTextEditorContainer"),
      "Create Opportunity": document.querySelector(".saveButton"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  const { routeConfig } = getAccessRights({
    userConfiguration,
    title: "Opportunities/View",
  });

  return (
    <>
      <NormalSizedModal
        visible={visible}
        title={
          <CustomModalHeader
            title={
              !!isChangeOrderState ? "New Change Order" : "New Opportunity"
            }
            hasButton={!!isChangeOrderState ? false : true}
            onClick={() => {
              setTourOpen(true);
            }}
            headerInfo={() => {
              return (
                userConfiguration.groupName === "Admin" && (
                  <Tooltip title="Sort Fields">
                    <TransferIconNew
                      {...{
                        className: "sortFieldsIcon",
                        onClick: () => {
                          setSortOpen(true);
                        },
                      }}
                    />
                  </Tooltip>
                )
              );
            }}
          />
        }
        className={`opportunityModalContainer ${
          isDarkMode && "opportunityModalContainerDark"
        }`}
        closable={true}
        closeIcon={<XIcon fill="#323338" />}
        onCancel={onCancel}
        newFooterDesign={footerButtons(
          () => {
            if (formChanged || !!changeOrderData) {
              setCancelModalVisible(true);
            } else {
              setVisible(false);
              onMultiStepCancel(0);
            }
          },
          () => validateOppForm(onSaveAndContinue),
          onOptionClick,
          <DropDownArrow />,
          saving,
          () => validateOppForm(onFinish),
          estimationChangeOrder,
          <TickIcon />
        )}
        centered
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormChanged(true);
          }}
        >
          <div className="opportunityModalBodyContainer">
            {!changeOrderData && (
              <div className="opportunityModalBodyContainer-splash bodyTypeRadio">
                <span className="radioTitle">Select a record type:</span>
                <InputComponent
                  {...{
                    ...inputFields.type,
                    onChange() {
                      setFormChanged(true);
                    },
                    options: inputFields?.type?.options?.map((item, i) => (
                      <Radio key={i} value={item}>
                        {item}
                      </Radio>
                    )),
                  }}
                />
              </div>
            )}
            <div className="leftSideContainer">
              <BorderedTextCard
                title={JOBSITE_ADDRESS}
                className="addressCard"
                childrenClassName="addressComponent"
                noChildrenStyle
              >
                <Popover content={<span>Alternative Addresses</span>}>
                  <Button
                    onClick={() => setAlternativeAddressesVisible(true)}
                    className="addAddressIcon"
                    type="primary"
                  >
                    {altAddresses?.length || 0}
                  </Button>
                </Popover>
                <div className="mainAddress">
                  {RenderDynamicComponents([
                    ...jobAddres.main,
                    ...jobAddres.split,
                  ])}
                </div>
              </BorderedTextCard>
              <BorderedTextCard
                title={OPPORTUNITY_INFORMATION}
                className="opportunityInformation"
              >
                {RenderDynamicComponents(oppInfoFields)}
                <div className="opportunityAvatarsDiv">
                  {!!selectedTeam?.length && (
                    <MondayButton
                      style={{
                        paddingRight: 15,
                        margin: 0,
                        alignSelf: "center",
                      }}
                      {...{ Icon: <PlusIcon /> }}
                      onClick={openOppurtunityTeamModal}
                    >
                      Members
                    </MondayButton>
                  )}
                  <div className="opportunityAvatars">
                    <DynamicAvatars
                      {...{
                        title: "Opportunity Team",
                        isDarkMode: isDarkMode,
                        members: selectedTeam.reduce(
                          (acc, { members }) => [...acc, ...members],
                          []
                        ),
                      }}
                    />
                  </div>
                </div>
                <MondayButton
                  className="mondayButtonBlue dobButton"
                  onClick={() => setDobInfoModalVisible(true)}
                  Icon={<DobButtonIcon />}
                  style={{ alignSelf: "center", marginBottom: 0 }}
                >
                  DOB Information
                </MondayButton>
                <DobInfoModal
                  {...{
                    setDobInformation: setDobInformation,
                    visible: dobInfoModalVisible,
                    setVisible: setDobInfoModalVisible,
                    address: opportunityAddress,
                  }}
                />
              </BorderedTextCard>
            </div>
            <BorderedTextCard
              title={PROJECT_INFORMATION}
              className="projectInfosContainer"
              childrenClassName="projectInfos"
              noChildrenStyle
            >
              <div className="checkboxesAndUploads">
                <AnimatedCheckBox
                  form={form}
                  formItemName="scopeSheet"
                  primaryStyle={{ maxWidth: 32 }}
                  checkBoxProps={{
                    value: uploadFile,
                    setValue: setUploadFile,
                  }}
                  onChange={(e) => {
                    setUploadFile(e.target.checked);
                  }}
                  label="Scope Sheet"
                />
                {RenderDynamicComponents(
                  projectInfoFields.checkboxesAndUploads
                )}
              </div>
              <div className="datesContainer">
                <AnimatedCheckBox
                  primaryStyle={
                    requiredBidDueDate
                      ? { backgroundColor: "#71cf48", maxWidth: 32 }
                      : { maxWidth: 32 }
                  }
                  secondaryStyle={
                    requiredBidDueDate ? { backgroundColor: "#5fb13b" } : {}
                  }
                  checkBoxProps={{
                    value: !requiredBidDueDate,
                    setValue: setRequiredBidDueDate,
                  }}
                  onChange={({ target }) => {
                    setRequiredBidDueDate(!target.checked);
                    form.setFieldValue("bidDueDate", null);
                  }}
                  label="Not Applicable"
                />
                {RenderDynamicComponents(projectInfoFields.dates)}
              </div>

              <div className="transfers">
                {RenderDynamicComponents(projectInfoFields.transfers)}
              </div>

              <div className="textEditors">
                {RenderDynamicComponents(projectInfoFields.textEditors)}
              </div>
            </BorderedTextCard>
            {(!!addLatLngAndZip?.coordinates ||
              !!altAddressesLatLng?.length) && (
              <div style={{ width: "200%" }}>
                <Geofence
                  places={coordinatesObj}
                  setGeoFenceInfo={setGeoFenceInfo}
                  geoFenceInfo={geoFenceInfo}
                  rowData={{
                    opportunityAddress,
                    accountName: selectedLead?.leadCompany,
                  }}
                  showDrawingManager={routeConfig.read}
                  allowEditing={routeConfig.read}
                />
              </div>
            )}
          </div>
        </Form>
      </NormalSizedModal>
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<Tick />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            ClearOptions,
            setSelectedTeam,
            isDarkMode,
          }}
        />
      )}
      {leadModalVisible && (
        <LeadModal
          saveOnly
          {...{
            visible: leadModalVisible,
            setVisible: setLeadModalVisible,
            preventContinue: true,
            accessToken,
            refreshTable: onLeadCreation,
          }}
        />
      )}
      {clientModalVisible && (
        <ClientModal
          saveOnly
          {...{
            visible: clientModalVisible,
            setVisible: setClientModalVisible,
            preventContinue: true,
            accessToken,
            creatingOnProjectView: true,
            refreshTable: fetchClients,
          }}
        />
      )}
      {contactModalVisible && (
        <ContactModal
          saveOnly
          {...{
            visible: contactModalVisible,
            setVisible: setContactModalVisible,
            preventContinue: true,
            contact: {
              ...(!!accountId
                ? selectedClient
                : {
                    accountName: selectedLead?.leadCompany,
                    accountPhone: selectedLead?.leadPhone,
                  }),
              contactType: !!accountId ? "Client Contact" : "Lead Contact",
            },
            [!!accountId
              ? "Client Contact"
                ? "propClient"
                : "Lead Contact"
                ? "propLead"
                : "propClient"
              : "propLead"]: {
              aptNumber: selectedClient?.aptNumber || selectedLead?.aptNumber,
              [!!accountId
                ? "Client Contact"
                  ? "shippingAddress"
                  : "Lead Contact"
                  ? "leadAddress"
                  : "shippingAddress"
                : "leadAddress"]:
                selectedClient?.shippingAddress || selectedLead?.leadAddress,
            },
            refreshTable: ([newContact]) =>
              setContacts((prev) => [newContact, ...prev]),
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Opportunity Tutorial",
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Opportunity",
            rowData: oppInfoFields,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "opportunityModal",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Opportunities",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              const { action, opportunityId } = visibleCreationProgress;
              if (!!action) {
                setVisible(false);
                action === "onSaveAndContinue" &&
                  opportunityId &&
                  navigate(`/opportunities/${opportunityId}`);
              }
            },
          }}
        />
      )}
    </>
  );
};

export default OpportunityModal;
