import React from "react";
import { Modal } from "antd";
import RichTextModalEditor from "../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor";
import htmlParser from "../../../../../../../utils/htmlParser";
import { EditFilled } from "@ant-design/icons";

function InclExclRichText({
  params,
  setRowData,
  updateStateAndSave,
  gridData,
  service,
  serviceIndex,
  type,
}) {
  const [visible, setVisible] = React.useState(false);
  console.log("params.value", params?.value);
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <RichTextModalEditor
        value={params?.value || ""}
        Icon={EditFilled}
        visibilityButtonText={htmlParser(params?.value || "Insert")}
        onConfirm={({ newValue }) => {
          let tempRows;
          setRowData((prev) => {
            const newPrev = [...prev];
            newPrev[params.rowIndex] = {
              ...newPrev[params.rowIndex],
              name: newValue.toString("html"),
            };
            tempRows = newPrev;
            return newPrev;
          });
          const newGridData = JSON.parse(JSON.stringify(gridData));
          if (type === "includes")
            newGridData[serviceIndex].includes = tempRows;
          if (type === "excludes")
            newGridData[serviceIndex].excludes = tempRows;
          updateStateAndSave(newGridData);
          //   console.log("newValue", newValue);
          //   params.data.name = newValue.toString("html");
        }}
      />
    </div>
  );
}

export default InclExclRichText;
