import dayjs from "dayjs";
import React, { useState } from "react";

import {
  AvatarIcon,
  CalendarIcon,
  DatasourceIcon,
  DescriptionIcon,
} from "../../assets";
import ReportInfo from "./ReportInfo";
import ReportDescriptionModal from "../ReportDescriptionModal/ReportDescriptionModal";

const ReportMetaData = ({
  report,
  configuring,
  onDescriptionEdited,
  onPreviewReport,
}) => {
  const [descriptionModalVisible, setDescriptionModalVisible] = useState(false);

  const { datasources = [], createdAt, createdBy, description } = report;

  return (
    <div className="reportMetadata-container">
      <div className="reportMetadata">
        <ReportInfo
          icon={<DatasourceIcon />}
          description="This Report has "
          value={`${datasources?.length} data sources.`}
        />
        <ReportInfo
          icon={<AvatarIcon />}
          description="Created by "
          value={createdBy}
        />
        <ReportInfo
          icon={<CalendarIcon />}
          description="Created at "
          value={dayjs(createdAt).format("MM/DD/YYYY")}
        />
      </div>

      <div className="reportMetadata-footer">
        {(!!description || !!configuring) && (
          <ReportInfo
            icon={<DescriptionIcon />}
            description="Description"
            onClick={() => setDescriptionModalVisible(true)}
          />
        )}
        <span
          {...{
            className: "cloneBtn previewBtn",
            onClick: () => onPreviewReport(report),
            tooltipKey: "previewReport",
          }}
        >
          Preview
        </span>
      </div>
      {descriptionModalVisible && (
        <ReportDescriptionModal
          {...{
            visible: descriptionModalVisible,
            setVisible: setDescriptionModalVisible,
            report,
            onDescriptionEdited,
            disabled: !configuring,
          }}
        />
      )}
    </div>
  );
};

export default ReportMetaData;
