import RatesNumberEditor from "./RatesNumberEditor";
import { formatCurrency } from "../../../../../../../SidebarPages/utils";

function ratesGridColumns({ editable }) {
  return [
    {
      headerName: "Role",
      field: "role",
      editable: false,
    },
    {
      headerName: "Rate",
      field: "rate",
      editable,
      cellEditor: RatesNumberEditor,
      cellRenderer({ value }) {
        let rate = 0;
        if (!isNaN(parseFloat(value))) {
          rate = parseFloat(value);
        }
        return formatCurrency(rate);
      },
    },
  ];
}

export default ratesGridColumns;
