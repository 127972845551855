import React, { useEffect } from "react";
import { Modal, Button } from "antd";
import "./FullScreenModal.scss";
import { DropdownWrapper } from "..";
import { XIcon } from "../../../Communication/assets";
import { updateDocumentTitle } from "../../../../../utils";
import { WithTooltip } from "../../../../commonComponents";

const FullScreenModal = ({
  visible,
  setVisible,
  title,
  subtitle,
  children,
  onCancel,
  centered,
  className = "",
  destroyOnClose,
  footer = [],
  style = {},
  footerButtonsStyle = {},
  isDarkMode,
}) => {
  return (
    <Modal
      wrapClassName={`fullScreenModal ${
        isDarkMode && "fullScreenModalDark"
      } ${className} ${!!title ? "" : "noTitleFullScreenModal"}`}
      open={visible}
      destroyOnClose={destroyOnClose}
      footer={null}
      title={title}
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: title });
      }}
      centered={centered}
      keyboard
      maskClosable={false}
      closeIcon={<XIcon fill="#333238" />}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel ? onCancel() : setVisible(false);
      }}
      style={{ ...style }}
    >
      <div className="subtitle">{subtitle}</div>
      {children}
      <div className="footerContainer" style={footerButtonsStyle}>
        {footer?.map(
          (
            {
              text,
              onClick,
              primary,
              cancel,
              uppercase = false,
              disabled = false,
              dropdownDisabled = false,
              danger = false,
              options,
              onOptionClick,
              suffix = "",
              tooltipCategory,
              tooltipKey,
            },
            i
          ) => (
            <WithTooltip {...{ tooltipCategory: tooltipCategory, tooltipKey }}>
              {!options ? (
                <Button
                  key={i}
                  danger={danger}
                  onClick={() => {
                    onClick();
                    updateDocumentTitle(); // reset document title to "Lead Manager";
                  }}
                  disabled={disabled}
                  className={`${
                    disabled
                      ? "disabled"
                      : danger
                      ? ""
                      : primary
                      ? "primary"
                      : cancel
                      ? "cancel"
                      : "secondary"
                  }FooterButton`}
                  style={{
                    textTransform: uppercase ? "uppercase" : "capitalize",
                  }}
                >
                  {text}
                  {suffix}
                </Button>
              ) : (
                <DropdownWrapper
                  key={i}
                  dropdownOptions={options}
                  onClick={onOptionClick}
                  dropdownDisabled={dropdownDisabled}
                >
                  <Button
                    key={i}
                    danger={danger}
                    onClick={onClick}
                    disabled={disabled}
                    className={`${
                      disabled
                        ? "disabled"
                        : danger
                        ? ""
                        : primary
                        ? "primary"
                        : cancel
                        ? "cancel"
                        : "secondary"
                    }FooterButton`}
                    style={{
                      textTransform: uppercase ? "uppercase" : "capitalize",
                    }}
                  >
                    {text}
                    {suffix}
                  </Button>
                </DropdownWrapper>
              )}
            </WithTooltip>
          )
        )}
      </div>
    </Modal>
  );
};

export default FullScreenModal;
