import { useState } from "react";
import { Input, Modal, Select, message } from "antd";
import { SketchPicker } from "react-color";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import "./EditModal.scss";
import {
  CloseOutlined,
  HistoryOutlined,
  InfoCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { MondayButton, MondaySwitch } from "../../../../../../commonComponents";
import { Tick, TickIcon } from "../../../Roles/src";
import { makeFirstLetterLowerCase } from "../../utils/makeFirstLetterLowerCase";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { CloseIconModal } from "../../../../../../../assets";
import MultiLevelTreeLogs from "../../../../../../commonComponents/MultiLevelTreeLogs/MultiLevelTreeLogs";
import { useEditLogs } from "../../../../../../../hooks";

const EditModal = ({
  data,
  setData,
  createLog,
  editModal,
  fieldName,
  categories,
  manualSave,
  hasKeyColor,
  setEditModal,
  selectedData,
  getPrevOrCurr,
  setSelectedData,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const defaultValues = {
    statusId: "",
    statusName: "",
    status: true,
    description: "",
    ...(hasKeyColor ? { statusColor: "#ccc" } : {}),
    ...(categories.length > 0 ? { category: "" } : {}),
  };

  const [editData, setEditData] = useState(selectedData || defaultValues);
  const [isWarningVisible, setIsWarningVisible] = useState(false);

  const { fetchedLogs, setFetchedLogs, handleFetchLogs, loading } =
    useEditLogs();

  const newId = v4();

  const handleModalClose = () => {
    setEditData(defaultValues);

    setSelectedData(defaultValues);

    setEditModal(false);
  };

  const handleEdit = () => {
    setData((prev) =>
      prev.map((item) =>
        item.statusId !== editData.statusId
          ? item
          : {
              ...item,
              ...editData,
              key: makeFirstLetterLowerCase(editData.statusName),
            }
      )
    );

    const newLogInfo = {
      recordId: selectedData.statusId,
      actionType: "Edit",
      ...(selectedData.hasOwnProperty("category")
        ? { recordName: selectedData.category }
        : {}),
      previousData: getPrevOrCurr(selectedData, hasKeyColor, {
        status: selectedData.status ? "Enabled" : "Disabled",
      }),
      currentData: getPrevOrCurr(editData, hasKeyColor, {
        status: editData.status ? "Enabled" : "Disabled",
      }),
    };

    createLog(newLogInfo);
  };

  const handleCreate = () => {
    const newData = {
      ...editData,
      statusId: newId,
      createdBy: userConfiguration.nameOfUser,
      createdAt: Date.now(),
      key: makeFirstLetterLowerCase(editData.statusName),
    };
    setData((prev) => [...prev, newData]);

    const newLogInfo = {
      recordId: newId,
      actionType: "Create",
      ...(editData.hasOwnProperty("category")
        ? { recordName: editData.category }
        : {}),
      previousData: getPrevOrCurr("", hasKeyColor),
      currentData: getPrevOrCurr(editData, hasKeyColor, {
        status: editData.status ? "Enabled" : "Disabled",
      }),
    };

    createLog(newLogInfo);

    if (manualSave) {
      manualSave(newData);
    }
  };

  const handleModalSave = () => {
    let allNames = data
      .filter((item) => {
        if (editData.hasOwnProperty("category")) {
          return (
            item.statusId !== editData.statusId &&
            item.category === editData.category
          );
        } else {
          return item.statusId !== editData.statusId;
        }
      })
      .map((row) => row?.statusName?.toLowerCase());

    if (editData.statusName.trimStart().length === 0) {
      return message.error("Please fill the Name");
    }

    if (allNames.includes(editData.statusName.trimStart().toLowerCase())) {
      return message.error(
        `${editData.statusName.toLowerCase()} already exists!`
      );
    }

    if (editData.hasOwnProperty("category") && !editData.category) {
      return message.error("Please select a Category");
    }

    editData.statusId ? handleEdit() : handleCreate();

    handleModalClose();
  };

  const handleEditItemChange = (key, value) => {
    setEditData((prev) => ({ ...prev, [key]: value }));
  };

  const handleShowLogs = async () => {
    const hideLoading = message.loading(
      "Retrieving log data. Please wait...",
      0
    );
    const filters = [
      {
        operator: "AND",
        conditions: [
          {
            operator: "AND",
            column: "recordId",
            value: editData.statusId,
            formula: "is",
          },
        ],
      },
    ];

    await handleFetchLogs({ filters });

    !loading && hideLoading();
  };

  return (
    <Modal
      title={`${editData.statusId ? "Edit" : "Create"} Status`}
      open={editModal}
      onCancel={handleModalClose}
      closeIcon={<XIcon />}
      className={`configTable-editModal ${
        isDarkMode && "configTable-editModal-dark"
      }`}
      footer={null}
    >
      <div className="info">
        <InfoCircleFilled
          style={{
            color: "#1264A3",
            fontSize: "20px",
          }}
        />
        <p>Make the needed changes below.</p>
      </div>
      <div className="body">
        <div className="left">
          {categories.length > 0 && (
            <div className="label-input">
              <label>Category</label>
              <InputComponent
                allowClear={false}
                type="select"
                placeholder="Select category..."
                onChange={(value) => handleEditItemChange("category", value)}
                defaultValue={editData.category}
                options={categories?.map((category) => (
                  <Select.Option value={category}>{category}</Select.Option>
                ))}
                disabled={editData.statusId}
              />
            </div>
          )}
          <div className="name-status">
            <div className="label-input">
              <label>Name</label>
              <Input
                placeholder="Enter name..."
                value={editData.statusName}
                onChange={(e) =>
                  handleEditItemChange("statusName", e.target.value)
                }
              />
            </div>
            <MondaySwitch
              {...{
                checked: editData.status,
                onToggleSwitch: (value) =>
                  handleEditItemChange("status", value),
                onText: "Enabled",
                offText: "Disabled",
                removeIcon: false,
              }}
            />
          </div>
          <div className="label-input">
            <label>Description</label>
            <ReactQuill
              placeholder="Write description here..."
              value={editData.description}
              onChange={(value) => handleEditItemChange("description", value)}
            />
          </div>
        </div>
        {!!hasKeyColor && (
          <div className="right">
            <div className="sketchPicker">
              <SketchPicker
                presetColors={[]}
                color={editData.statusColor}
                onChange={(updatedColor) =>
                  handleEditItemChange("statusColor", updatedColor.hex)
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <MondayButton
          className={"mondayButtonRed"}
          onClick={() => setIsWarningVisible(true)}
          Icon={<CloseOutlined />}
        >
          Cancel
        </MondayButton>
        {editData.statusId && (
          <MondayButton
            className="mondayButtonBlue"
            onClick={handleShowLogs}
            Icon={<HistoryOutlined />}
            tooltipKey="showLogs"
          >
            Logs
          </MondayButton>
        )}
        <MondayButton onClick={handleModalSave} Icon={<Tick />}>
          {editData.statusId ? "Edit" : "Create"}
        </MondayButton>
      </div>
      <Modal
        title={
          <div
            style={{
              position: "relative",
              height: "45px",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <WarningFilled
              style={{
                position: "absolute",
                top: "-12px",
                right: "-4px",
                transform: "rotate(-22.23deg)",
                fontSize: "60px",
                color: "#acacac",
              }}
            />
            <div className="header">
              <WarningFilled style={{ paddingRight: "12px" }} />
              Warning Message
            </div>
          </div>
        }
        open={isWarningVisible}
        onCancel={() => setIsWarningVisible(false)}
        closeIcon={false}
        className={`configPageTable-warningModal ${
          isDarkMode && "configPageTable-warningModal-dark"
        }`}
        footer={
          <div className="footer">
            <MondayButton
              className="mondayButtonRed"
              Icon={<CloseIconModal />}
              onClick={() => setIsWarningVisible(false)}
              style={{ width: "150px" }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              Icon={<TickIcon />}
              style={{ width: "150px" }}
              onClick={() => {
                setIsWarningVisible(false);
                handleModalClose();
              }}
            >
              Confirm
            </MondayButton>
          </div>
        }
      >
        <div className="warning-content">
          <p>
            Are you sure you want to cancel changes? It will result in the
            <span> loss of any changes</span> you have made that have not been
            saved. Please confirm if you wish to proceed.
          </p>
        </div>
      </Modal>
      <MultiLevelTreeLogs
        visible={fetchedLogs}
        setVisible={setFetchedLogs}
        logsData={fetchedLogs}
        title={`${fieldName} Logs`}
      />
    </Modal>
  );
};

export default EditModal;
