import { compareIncluding } from "../../../../../../../../SidebarPages/utils";
import { excelCellFitToColumn } from "../../../../../../../../../utils/excelCellFitToColumn";
import XLSX from "sheetjs-style";
import { defaultExcelBodyStyle } from "../../../../../../../../../helpers/constants/defaultExcelBodyStyle";
import createPDF from "../../../../../../../../../integrations/createPDF";
import { API } from "aws-amplify";
import {
  tableHeaders,
  excelRouteHeaders,
  excelSidebarHeaders,
} from "../../../constants/tableHeaders";
import { getPdfTableWidths } from "../../../../../../../../../utils/getPdfTableWidths";
import { changeHeaderOrderAndFilter } from "../../../../../../../../../utils/changeHeaderOrderAndFilter";
import { changeBodyOrderAndFilter } from "../../../../../../../../../utils/changeBodyOrderAndFilter";

const dataPush = (title, read, write) => {
  return [
    { v: title || "", s: defaultExcelBodyStyle },
    { v: read ? "True" : "False", s: defaultExcelBodyStyle },
    { v: write ? "True" : "False", s: defaultExcelBodyStyle },
  ];
};

const getRouteDataForExcel = (selectedData, elements, rows) => {
  const category = "Route";

  const parent = (obj, element) =>
    selectedData[obj]?.find((val) => val.title === element.title);

  const firstChild = (obj, element1, element2) =>
    parent(obj, element1)?.children?.find((ch) => ch.title == element2.title);

  const secondChild = (obj, element1, element2, element3) =>
    firstChild(obj, element1, element2)?.children?.find(
      (chi) => chi.title == element3.title
    );

  elements.forEach((element) => {
    const read = parent(category, element);
    const write = parent(category, element) && parent(category, element).write;

    rows.push(dataPush(element.title, read.read, write));

    // Loop through children of element and push to tableData
    if (element.children) {
      element.children.forEach((child) => {
        const readChild = firstChild(category, element, child);
        const writeChild =
          firstChild(category, element, child) &&
          firstChild(category, element, child).write;

        rows.push(
          dataPush(
            child.title,
            readChild.hasOwnProperty("read") ? readChild.read : true,
            writeChild
          )
        );

        // Loop through children of child and push to tableData
        if (child.children) {
          child.children.forEach((secondChildren) => {
            const readSecondChild = secondChild(
              category,
              element,
              child,
              secondChildren
            );
            const writSecondeChild =
              secondChild(category, element, child, secondChildren) &&
              secondChild(category, element, child, secondChildren).write;

            rows.push(
              dataPush(
                secondChildren.title,
                readSecondChild.hasOwnProperty("read")
                  ? readSecondChild.read
                  : true,
                writSecondeChild
              )
            );
          });
        }
      });
    }
  });
};

const getSidebarDataForExcel = (elements, rows) => {
  elements.forEach((element) => {
    rows.push([
      { v: element.title || "", s: defaultExcelBodyStyle },
      { v: element.read ? "Yes" : "No", s: defaultExcelBodyStyle },
    ]);
  });
};

const convertToNumbers = (arr) => {
  const uniqueStrings = Array.from(new Set(arr));
  const stringToNumber = {};
  uniqueStrings.forEach((str, index) => {
    stringToNumber[str] = index;
  });
  return arr.map((str) => stringToNumber[str]);
};

const getAllTableHeaders = () => {
  const tableHeadersNumeric = {};
  for (const key in tableHeaders) {
    if (tableHeaders.hasOwnProperty(key)) {
      tableHeadersNumeric[key] = convertToNumbers(tableHeaders[key]);
    }
  }

  return tableHeadersNumeric;
};

export const onGeneratePDF = (
  action,
  additionalData,
  getDocDefinition = false,
  headersData,
  saveAddedLogs
) => {
  const { accessConfiguration, selectedUser, log, base64 } = additionalData;

  const logo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const { Sidebar, Route } = accessConfiguration;

  let selectedData = { Sidebar, Route };

  const columnsToInclude = headersData || getAllTableHeaders();

  const parent = (obj, element) =>
    selectedData[obj]?.find((val) => val.title === element.title);

  const firstChild = (obj, element1, element2) =>
    parent(obj, element1)?.children?.find((ch) => ch.title == element2.title);

  const secondChild = (obj, element1, element2, element3) =>
    firstChild(obj, element1, element2)?.children?.find(
      (chi) => chi.title == element3.title
    );

  const dataPush = (title, childNum, read, write) => {
    return [
      {
        text: title,
        margin: [5 + 15 * childNum, 0, 0, 0],
      },
      {
        text: read ? "True" : "False",
      },
      { text: write ? "True" : "False" },
    ];
  };

  const getRouteData = (category, elements) => {
    const tableData = [];
    elements.forEach((element) => {
      const read = parent(category, element);
      const write =
        parent(category, element) && parent(category, element).write;

      tableData.push(dataPush(element.title, 0, read.read, write));

      // Loop through children of element and push to tableData
      if (element.children) {
        element.children.forEach((child) => {
          const readChild = firstChild(category, element, child);
          const writeChild =
            firstChild(category, element, child) &&
            firstChild(category, element, child).write;

          tableData.push(
            dataPush(
              child.title,
              1,
              readChild.hasOwnProperty("read") ? readChild.read : true,
              writeChild
            )
          );

          // Loop through children of child and push to tableData
          if (child.children) {
            child.children.forEach((secondChildren) => {
              const readSecondChild = secondChild(
                category,
                element,
                child,
                secondChildren
              );
              const writSecondeChild =
                secondChild(category, element, child, secondChildren) &&
                secondChild(category, element, child, secondChildren).write;

              tableData.push(
                dataPush(
                  secondChildren.title,
                  2,
                  readSecondChild.hasOwnProperty("read")
                    ? readSecondChild.read
                    : true,
                  writSecondeChild
                )
              );
            });
          }
        });
      }
    });

    return tableData;
  };

  const getSidebarData = (elements) => {
    const data = [];

    elements.forEach((element) => {
      data.push([element.title, element.read ? "Yes" : "No"]);
    });

    return data;
  };

  const pageMargins = [20, 120, 20, 40];

  const routeData = getRouteData("Route", accessConfiguration.Route);
  const sidebarData = getSidebarData(accessConfiguration.Sidebar);

  // Define the document definition
  const docDefinition = {
    pageMargins,
    header: {
      columns: [
        {
          text: `Title: ${selectedUser.nameOfUser}, ${selectedUser.departmentName}, ${selectedUser.groupName}`,
          fontSize: 11,
          alignment: "left",
          margin: [20, 10],
          width: "*",
          bold: true,
        },
        {
          image: logo,
          fit: [100, 100],
          alignment: "center",
          width: "*",
        },
        {
          text: new Date().toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          fontSize: 10,
          alignment: "right",
          margin: [20, 10],
          width: "*",
        },
      ],
      margin: [0, 10, 0, 40],
    },
    content: [
      {
        text: "Route",
        fontSize: 12,
        bold: true,
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: getPdfTableWidths(columnsToInclude.route),
          body: [
            changeHeaderOrderAndFilter(
              tableHeaders.route,
              columnsToInclude.route
            ),
            ...changeBodyOrderAndFilter(routeData, columnsToInclude.route),
          ],
          margin: [0, 50, 0, 0],
          alignment: "center",
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0
              ? "#B7C4CB"
              : rowIndex % 2 === 0
              ? "#f7f7f7"
              : "#fff";
          },
        },
      },
      {
        text: "Sidebar",
        fontSize: 12,
        bold: true,
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: getPdfTableWidths(columnsToInclude.sidebar),
          body: [
            changeHeaderOrderAndFilter(
              tableHeaders.sidebar,
              columnsToInclude.sidebar
            ),
            ...changeBodyOrderAndFilter(sidebarData, columnsToInclude.sidebar),
          ],
          margin: [0, 50, 0, 0],
          alignment: "center",
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0
              ? "#B7C4CB"
              : rowIndex % 2 === 0
              ? "#f7f7f7"
              : "#fff";
          },
        },
      },
    ],
    footer: function (currentPage, pageCount) {
      return {
        text: "Page " + currentPage.toString() + " of " + pageCount,
        alignment: "right",
        margin: [0, 0, 20, 0],
      };
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
  };

  if (getDocDefinition) return docDefinition;

  createPDF({
    action,
    docDefinition,
    title:
      action === "print"
        ? false
        : `${selectedUser.nameOfUser}_${selectedUser.departmentName}_${selectedUser.groupName}.pdf`,
  });

  log.actionType = action;
  log.recordName = `${action} PDF`;
  saveAddedLogs(log);
};

export const exportToExcel = (
  additionalData,
  getDocDefinition = false,
  headersData,
  saveAddedLogs
) => {
  if (getDocDefinition)
    return onGeneratePDF(
      "download",
      additionalData,
      getDocDefinition,
      null,
      saveAddedLogs
    );

  const { accessConfiguration, selectedUser, log } = additionalData;

  const columnsToInclude = headersData || getAllTableHeaders();

  const workbook = XLSX.utils.book_new();

  const routeRows = [];
  const sidebarRows = [];

  getRouteDataForExcel(
    accessConfiguration,
    accessConfiguration.Route,
    routeRows
  );

  const routeFilteredHeader = changeHeaderOrderAndFilter(
    excelRouteHeaders,
    columnsToInclude.route
  );

  const routeFilteredBody = changeBodyOrderAndFilter(
    routeRows,
    columnsToInclude.route
  );

  const routeWorksheet = XLSX.utils.aoa_to_sheet(
    [routeFilteredHeader, ...routeFilteredBody],
    {
      styles: true,
    }
  );

  routeWorksheet["!cols"] = excelCellFitToColumn([
    routeFilteredHeader,
    ...routeFilteredBody,
  ]);

  XLSX.utils.book_append_sheet(workbook, routeWorksheet, "Route");

  getSidebarDataForExcel(accessConfiguration.Sidebar, sidebarRows);

  const sidebarFilteredHeader = changeHeaderOrderAndFilter(
    excelSidebarHeaders,
    columnsToInclude.sidebar
  );
  const sidebarFilteredBody = changeBodyOrderAndFilter(
    sidebarRows,
    columnsToInclude.sidebar
  );

  const sidebarWorksheet = XLSX.utils.aoa_to_sheet(
    [sidebarFilteredHeader, ...sidebarFilteredBody],
    {
      styles: true,
    }
  );

  sidebarWorksheet["!cols"] = excelCellFitToColumn([
    sidebarFilteredHeader,
    ...sidebarFilteredBody,
  ]);

  XLSX.utils.book_append_sheet(workbook, sidebarWorksheet, "Sidebar");

  XLSX.writeFile(
    workbook,
    `${selectedUser.nameOfUser}_${selectedUser.departmentName}_${selectedUser.groupName}.xlsx`
  );
  log.actionType = "download";
  log.recordName = `${action} PDF`;
  saveAddedLogs(log);
};

export const uploadExcelToDrive = async (
  accessConfiguration,
  selectedUser,
  driveRequest,
  driveFolderId
) => {
  try {
    let workbook;

    workbook = XLSX.utils.book_new();

    const routeRows = [];
    const sidebarRows = [];

    getRouteDataForExcel(
      accessConfiguration,
      accessConfiguration.Route,
      routeRows
    );
    const routeWorksheet = XLSX.utils.aoa_to_sheet(
      [excelRouteHeaders, ...routeRows],
      {
        styles: true,
      }
    );

    routeWorksheet["!cols"] = excelCellFitToColumn([
      excelRouteHeaders,
      ...routeRows,
    ]);

    XLSX.utils.book_append_sheet(workbook, routeWorksheet, "Route");

    getSidebarDataForExcel(accessConfiguration.Sidebar, sidebarRows);
    const sidebarWorksheet = XLSX.utils.aoa_to_sheet(
      [excelSidebarHeaders, ...sidebarRows],
      {
        styles: true,
      }
    );

    sidebarWorksheet["!cols"] = excelCellFitToColumn([
      excelSidebarHeaders,
      ...sidebarRows,
    ]);

    XLSX.utils.book_append_sheet(workbook, sidebarWorksheet, "Sidebar");

    // Convert the workbook to an Excel binary array
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the array buffer to a blob with the correct MIME type
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Upload the Excel file to Google Drive
    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name: `${selectedUser.nameOfUser}_${selectedUser.departmentName}_${selectedUser.groupName}.xlsx`,
        parents: [driveFolderId],
      }
    );

    // Extract and log information about the uploaded file
    const { id, newName } = await uploadResponse.json();

    // Update state or perform any necessary actions with the file ID
    return { id, name: newName };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};
