import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { Table, Form, message } from "antd";

import { validateForm } from "../../../Fleet/utils";
import { filterTables } from "../../../../../utils";
import { columns } from "./columns";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
import {
  Tick,
  TickIcon,
} from "../../../../pages/Settings/settingsComponents/Roles/src";
import { AddIcon } from "../../../../Header/components/GoogleEvents/assets";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";

import "../FleetItems/FleetItems.scss";

function LaborCard(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [dataSource, setDataSource] = useState(props.params.rowData ?? []);
  const [initialData] = useState(structuredClone(props.params.rowData ?? []));
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [fieldId, setFieldId] = useState("");
  const [namesToUse, setNamesToUse] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    filterTables("programFields", "fieldName", "Key Words For Labor")
      .then((res) => {
        if (res?.length) {
          setFieldId(res[0]?.fieldId);
          setNamesToUse(
            Array.isArray(res[0]?.fieldOptions) ? res[0]?.fieldOptions : []
          );
        }
      })
      .catch((err) => {
        message.error("Error Getting Name Options!");
        console.log("Error getting dynamic names: ", err);
      });

    let newFields = {};
    props?.params?.rowData?.forEach((e, index) => {
      newFields = {
        ...newFields,
        [`NAME:${index}`]: e?.laborName,
        [`DESCRIPTION:${index}`]: e?.description,
        [`RATE:${index}`]: e?.rate?.toFixed(2),
        [`HOURS:${index}`]: e?.hoursWorked,
        [`TOTAL:${index}`]: e?.total?.toFixed(2),
      };
    });
    form.setFieldsValue(newFields);
  }, [JSON.stringify(props.params.rowData)]);

  function onCancel() {
    props.setEditMode(false);
    setCancelModalVisible(false);
  }

  function onRemoveItem(index) {
    let newFields = {};
    for (let i = index; i < dataSource?.length; i++) {
      newFields[`NAME:${i}`] = form.getFieldValue(`NAME:${i + 1}`) || "";
      newFields[`DESCRIPTION:${i}`] =
        form.getFieldValue(`DESCRIPTION:${i + 1}`) || "";
      newFields[`RATE:${i}`] = form.getFieldValue(`RATE:${i + 1}`) || 0;
      newFields[`HOURS:${i}`] = form.getFieldValue(`HOURS:${i + 1}`) || 0;
      newFields[`TOTAL:${i}`] = form.getFieldValue(`TOTAL:${i + 1}`) || 0;
    }
    form.setFieldsValue(newFields);
    let tmp = structuredClone(dataSource);
    tmp.splice(index, 1);
    setDataSource(tmp);
  }

  function onRateChange(index, value) {
    let tmp = structuredClone(dataSource);
    tmp[index]["rate"] = parseFloat(value.target.value || 0);
    tmp[index]["total"] =
      parseFloat(value.target.value) * tmp[index]["hoursWorked"];
    form.setFieldsValue({
      [`TOTAL:${index}`]: tmp[index]["total"]?.toFixed(2),
    });
    setDataSource(tmp);
  }

  function onHoursChanged(index, value) {
    let tmp = structuredClone(dataSource);
    tmp[index]["hoursWorked"] = parseFloat(value.target.value || 0);
    tmp[index]["total"] = parseFloat(value.target.value) * tmp[index]["rate"];
    form.setFieldsValue({
      [`TOTAL:${index}`]: tmp[index]["total"]?.toFixed(2),
    });
    setDataSource(tmp);
  }

  async function addOptionHandler(value) {
    message.loading({
      content: "Saving Option",
      key: "optionSaving",
    });
    let tmpNames = structuredClone(namesToUse);
    tmpNames = tmpNames.concat(value);
    setNamesToUse(tmpNames);
    if (fieldId) {
      API.patch("programFields", `/programFields/${fieldId}`, {
        body: {
          fieldOptions: tmpNames,
        },
      })
        .then(() => {
          message.success({
            content: "Option Saved Successfully!",
            key: "optionSaving",
          });
        })
        .catch((err) => {
          message.error({
            content: "Something Went Wrong While Saving",
            key: "optionSaving",
          });
          console.log("Error Saving Option: ", err);
        });
    }
  }

  function addLineHandler() {
    form.setFieldsValue({
      [`NAME:${dataSource?.length}`]: "",
      [`DESCRIPTION:${dataSource?.length}`]: "",
      [`RATE:${dataSource?.length}`]: 0?.toFixed(2),
      [`HOURS:${dataSource?.length}`]: 0,
      [`TOTAL:${dataSource?.length}`]: 0?.toFixed(2),
    });
    setDataSource([
      ...dataSource,
      {
        laborName: "",
        description: "",
        rate: 0,
        hoursWorked: 0,
        total: 0,
      },
    ]);
  }

  function onTotalChange(index, value) {
    form.setFieldsValue({
      [`RATE:${index}`]: 0?.toFixed(2),
      [`HOURS:${index}`]: 0,
    });

    let tSource = structuredClone(dataSource);
    tSource[index] = {
      ...tSource[index],
      total: parseFloat(value.target.value || 0),
      rate: 0,
      hoursWorked: 0,
    };

    setDataSource(tSource);
  }

  function saveHandler() {
    let bodyToSend = {
      laborInformation: dataSource?.map((e, i) => ({
        laborName: form.getFieldValue(`NAME:${i}`),
        description: form.getFieldValue(`DESCRIPTION:${i}`),
        rate: parseFloat(e?.rate) || 0,
        hoursWorked: parseFloat(e?.hoursWorked) || 0,
        total: e?.total ?? parseFloat(e?.rate) * parseFloat(e?.hoursWorked),
      })),
    };

    let prevLog = {
      id: "laborInformation",
      Card: "LaborCard",
      title: "Labor Information",
      params: {
        rowData: initialData,
      },
    };

    let nextLog = {
      id: "laborInformation",
      Card: "LaborCard",
      title: "Labor Information",
      params: {
        rowData: bodyToSend?.laborInformation,
      },
    };

    props.getEditedValue(bodyToSend, {}, {}, prevLog, nextLog);
    onCancel();
  }

  return (
    <div
      className={`inventoryItemsEditCardContainer ${
        isDarkMode ? "inventory-items-dark" : ""
      }`}
    >
      <Form form={form}>
        <div className="addItemsSection">
          <MondayButton
            {...{
              Icon: <AddIcon />,
              className: "mondayButtonBlue",
              onClick() {
                addLineHandler();
              },
              style: { marginBottom: "1rem" },
            }}
          >
            Add Line
          </MondayButton>
        </div>
        <Table
          {...{
            columns: columns({
              onRemoveItem,
              onRateChange,
              onHoursChanged,
              onTotalChange,
              form,
              namesToUse,
              addOptionHandler,
            }),
            dataSource: dataSource
              ?.map((el, index) => ({ ...el, index }))
              ?.concat({
                total: dataSource?.reduce((acc, val) => acc + val?.total, 0),
              }),
            pagination: false,
            bordered: true,
            scroll: {
              x: 900,
            },
          }}
        />
        <div className="confirmContainer">
          <MondayButton
            onClick={() => {
              setCancelModalVisible(true);
            }}
            className="cancelButton mondayButtonRed"
            Icon={<XIcon />}
          >
            Cancel
          </MondayButton>
          <MondayButton
            onClick={() => {
              validateForm(form, saveHandler);
            }}
            className="confirmButton"
            Icon={<Tick width={20} height={20} />}
          >
            Confirm
          </MondayButton>
        </div>
      </Form>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<TickIcon />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
}

export default LaborCard;
