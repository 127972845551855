import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const estimations = (
  IdHandler,
  estimationsData,
  updatedDynamicStates,
  archData,
  engData,
  programFields
) => {
  return {
    engProgress: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "engProgress",
        title: "Engineer Progress",
        subtitle: "",
        content: estimationsData?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: parseInt(a.engProgress),
          showInfo: true,
          status: a.engAssignedTo,
          done: parseInt(a.engProgress),
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //engProgress
    archProgress: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "archProgress",
        title: "Architect Progress",
        subtitle: "",
        content: estimationsData?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: parseInt(a.archProgress),
          showInfo: true,
          status: a.archAssignedTo,
          done: parseInt(a.archProgress),
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //archProgress
    estSTATUS: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "estSTATUS",
        title: "Estimation Status",
        subtitle: "",
        content: programFields
          ?.find((b) => b.fieldName === "Estimation Status")
          ?.fieldOptions?.map((a) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (c) =>
                  c?.estSTATUS?.toLowerCase() === a?.statusName?.toLowerCase()
              )?.length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: a.statusName,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (c) =>
                c?.estSTATUS?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }), //estSTATUS
    archAssignedTo: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "archAssignedTo",
        title: "Architect Assigned To",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "archAssignedTo"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //archAssignedTo
    engAssignedTo: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "engAssignedTo",
        title: "Engineer Assigned To",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "engAssignedTo"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //engAssignedTo
    statusByArch: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByArch",
        title: "Status By Architect",
        subtitle: "",
        content: programFields
          ?.find((b) => b.fieldName === "Status by Architecture")
          ?.fieldOptions?.map((a) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (c) =>
                  c?.statusByArch?.toLowerCase() ===
                  a?.statusName?.toLowerCase()
              )?.length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: a.statusName,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (c) =>
                c?.statusByArch?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }), //statusByArch
    statusByEng: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByEng",
        title: "Status By Engineer",
        subtitle: "",
        content: programFields
          ?.find((b) => b.fieldName === "Status by Engineering")
          ?.fieldOptions?.map((a) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (c) =>
                  c?.statusByEng?.toLowerCase() === a?.statusName?.toLowerCase()
              )?.length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: a.statusName,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (c) =>
                c?.statusByEng?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }), //statusByEng

    projectExecutive: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "projectExecutive",
        title: "Project Executive",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    projectManager: () => ({
      title: "Estimations",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "projectManager",
        title: "Project Manager",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])
              ?.map((a) => a?.projectManager)
              ?.flat(),
            "nameOfUser"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  }; //Estimations
};
