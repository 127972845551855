import React from "react";
import { MondayButton } from "../../../../../../../../../../../../../../../commonComponents";
import { MergeIcon } from "../../../../../../../../../../../../../../../ProposalBuilder/components/ProposalPages/FirstProposalPage/assets";
import UploadToGoogleDriveSheet from "../UploadToGoogleDriveSheet/UploadToGoogleDriveSheet";
import { RetainageIcon } from "../../../../../../../../../../../../../../../../icons";

function AttachElevationLabel({
  onAttachName,
  onToggleAttachServiceAddons,
  onDistributeAdditionalRentals,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
      }}
    >
      <MondayButton onClick={onAttachName} Icon={<MergeIcon />}>
        Attach Elevation Name
      </MondayButton>{" "}
      <MondayButton onClick={onToggleAttachServiceAddons} Icon={<MergeIcon />}>
        Remove Service Addons
      </MondayButton>{" "}
      <MondayButton
        onClick={onDistributeAdditionalRentals}
        Icon={<RetainageIcon />}
      >
        Distribute Additional Rentals
      </MondayButton>
      {/* <UploadToGoogleDriveSheet /> */}
    </div>
  );
}

export default AttachElevationLabel;
