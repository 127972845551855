import React, { useEffect, useState } from "react";
import { CoreBlack } from "../../../../../../../../../../assets/logos/index";
import { API } from "aws-amplify";

import styleModule from "./invoiceHeader.module.scss";
import { styleInvoice, styleSettings } from "./header_invoice_style_settings";

export const HeaderInvoice = ({ myProps, searchInvoicePreviewText }) => {
  const [account, setAccount] = useState();
  //const [project, setProject] = useState();
  const searchText = searchInvoicePreviewText;
  const invoice = myProps?.previewCurrentInvoce;

  useEffect(() => {
    API.get("accounts", `/accounts/${invoice.accountId}`).then((account) => {
      setAccount(account);
    });
    // API.get("projects", `/projects/${invoice.projectId}`).then((project) => {
    //   console.log(project);
    //   setProject(project);
    // });
  }, []);

  useEffect(() => {
    if (document.querySelector(`.${styleModule.invoiceHeaderCont}`)) {
      highlightInvoiceText();
    }
  }, [searchText]);

  //FIND TEXT AND HIGHLIGHT =========================
  const highlightInvoiceText = () => {
    const regExVal = new RegExp(`^${searchText}`, "i");
    const partentEls = [
      ...document.getElementsByClassName(`${styleModule.invoiceHeaderCont}`),
    ];
    partentEls.forEach((el) => {
      const elementsList = [
        ...el.children[0].children[1].children,
        ...el.children[0].children[2].children,
        ...el.children[1].children[1].children,
        ...el.children[1].children[2].children,
        el.children[1].children[0],
      ];
      elementsList.forEach((element) => {
        //Changed case from reg.test(elementsList[j].textContent) to the one below
        if (
          searchText &&
          element?.textContent.toLowerCase().includes(searchText?.toLowerCase())
        ) {
          let textLine = element.textContent;
          textLine = textLine.replace(
            regExVal,
            `<b style="background-color: #00000030">${searchText}</b>`
          );
          element.innerHTML = textLine;
        } else {
          let textLine = element.textContent;
          textLine = textLine.replace(/<b>|<\/b>/g, "");
          element.innerHTML = textLine;
        }
      });
    });
  };
  //FIND TEXT AND HIGHLIGHT =========================

  let dateInvoice = new Date(myProps?.previewCurrentInvoce.invoiceDate);
  const dayDate =
    dateInvoice.getDate() < 10
      ? `0${dateInvoice.getDate()}`
      : dateInvoice.getDate();
  const monthDate =
    dateInvoice.getMonth() < 1
      ? `01`
      : dateInvoice.getMonth() < 9
      ? `0${dateInvoice.getMonth() + 1}`
      : dateInvoice.getMonth() + 1;
  const yearDate = dateInvoice.getFullYear();
  dateInvoice = `${dayDate}/${monthDate}/${yearDate}`;

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  const { mode, previewCurrentInvoce } = myProps;
  const printModeStyle = mode === "printMode" ? styleInvoice : {};
  const viewModeStyle = myProps.mode === "viewMode" ? styleModule : {};

  return (
    <div
      key={mode}
      style={printModeStyle.headerInvoiceCont}
      className={viewModeStyle.invoiceHeaderCont}
    >
      <section
        style={printModeStyle.leftPartCont}
        className={viewModeStyle.leftPartCont}
      >
        <img src={CoreBlack} alt="Core Icon" style={printModeStyle.imgLogo} />
        <div style={printModeStyle.infoPartOne}>
          <p style={printModeStyle.textLeftSide}>417 Myrtle Avenue, Suite 14</p>
          <p style={printModeStyle.textLeftSide}>Brooklyn, NY 11205</p>
          <p style={printModeStyle.textLeftSide}>212.606.2222</p>
        </div>
        <div style={printModeStyle.infoPartTwo}>
          <h3 style={printModeStyle.infoPartTwoH3}>Client Information</h3>
          <p style={printModeStyle.textLeftSide}>{account?.accountName}</p>
          {/*<p
            style={
              printModeStyle.textLeftSide
            }
          >
            David Halberstam
          </p>*/}
          <p style={printModeStyle.textLeftSide}>
            {account?.billingAddress.split(",")[0]}
          </p>
          <p style={printModeStyle.textLeftSide}>
            {account?.billingAddress.split(",").slice(1).join(", ")}
          </p>
          <p style={printModeStyle.textLeftSide}>
            {formatPhoneNumber(account?.accountPhone)}
          </p>
        </div>
      </section>
      <section
        style={printModeStyle.rightPartCont}
        className={viewModeStyle.rightPartCont}
      >
        <h1 style={printModeStyle.head1}>Invoice</h1>
        <div
          style={printModeStyle.infoPartOneR}
          className={viewModeStyle.tableDatat}
        >
          <p
            style={printModeStyle.tableTextL}
            className={viewModeStyle.tableTextright}
          >
            Date
          </p>
          <p style={printModeStyle.tableTextR}>{dateInvoice}</p>
          <p
            style={printModeStyle.tableTextL}
            className={viewModeStyle.tableTextright}
          >
            Invoice #
          </p>
          <p style={printModeStyle.tableTextR}>
            {previewCurrentInvoce?.invoiceNumber}
          </p>
          <h3
            style={printModeStyle.tableH3L}
            className={viewModeStyle.tableTextright}
          >
            Rep{" "}
          </h3>
          <h3 style={printModeStyle.tableH3R}>O-MV</h3>
        </div>
        <div style={printModeStyle.infoPartTwoR}>
          <h3 style={printModeStyle.infoPartTwoH3R}>Project Address</h3>
          <p style={printModeStyle.textRightSide}>
            {invoice?.projectName.split(",")[0]}
          </p>
          <p style={printModeStyle.textRightSide}>
            {invoice?.projectName.split(",").slice(1).join(", ")}
          </p>
        </div>
      </section>
    </div>
  );
};

export default HeaderInvoice;
