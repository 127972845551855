import { useState, useMemo } from "react";
import { filterTables } from "../../../../../../../../../../../utils/filterTables";
import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../../../../../../commonComponents/index";
import { useRedux } from "../../../../../../../../../hooks/useRedux";
import { message } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { fetchAllData } from "../../../../../../../../../../../utils";
function RentalLogs() {
  const [fakeRentals] = useRedux("fakeRentals");
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [rentalLogs, setRentalLogs] = useState([]); // [
  console.log("fakeRentals?.editLogs", fakeRentals);

  useMemo(() => {
    if (editLogsVisibility) {
      message.loading("Loading Edit Logs", 2);
      const filter = fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: fakeRentals.rentalId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => setRentalLogs(res))
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => message.destroy());
      console.log("myfilter", filter);
    } else {
      setRentalLogs([]);
    }
  }, [editLogsVisibility]);
  console.log("rentalLogs", rentalLogs);

  return (
    <div>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<HistoryOutlined />}
        onClick={() => setEditLogsVisibility(true)}
      >
        Edit History
      </MondayButton>
      {editLogsVisibility && (
        <MultiLevelTreeLogs
          {...{
            visible: editLogsVisibility,
            setVisible: setEditLogsVisibility,
            logsData: rentalLogs || [],
            title: "Edit History for Rental",
          }}
        />
      )}
    </div>
  );
}

export default RentalLogs;
