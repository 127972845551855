export const updateCharges = (item, newCharge, newChargeId, chargeType) => {
  if (!newCharge) return item;

  const { taxRate, taxAmount, price } = newCharge;
  const newCharges = item?.charges
    ? [
        ...item.charges.filter(
          (chargeItem) => chargeItem.chargeId !== newChargeId
        ),
        {
          taxRate,
          taxAmount,
          chargeAmount: price,
          chargeId: newChargeId,
          chargeType,
        },
      ]
    : [];

  return { ...item, charges: newCharges };
};

export const updateSOVCharges = (item, newCharge, newChargeId, chargeType) => {
  if (!newCharge) return item;

  const { taxRate, taxAmount, price } = newCharge;
  const newCharges = item?.charges
    ? [
        ...item.charges.filter(
          (chargeItem) => chargeItem.chargeId !== newChargeId
        ),
        {
          taxRate,
          taxAmount,
          chargeAmount: price,
          chargeId: newChargeId,
          chargeType,
        },
      ]
    : [
        {
          taxRate,
          taxAmount,
          chargeAmount: price,
          chargeId: newChargeId,
          chargeType,
        },
      ];

  return { ...item, charges: newCharges };
};

export const updateAlreadyCreatedCharges = (
  oldCharge,
  chargeItems,
  newChargeId,
  chargeType
) => {
  const chargeToUpdate = oldCharge.map((charge, chargeIndex) => {
    return {
      ...charge,
      serviceOptions: charge.serviceOptions.map(
        (serviceOption, serviceOptionIndex) =>
          Array.isArray(serviceOption)
            ? serviceOption.map((service, serviceIndex) => ({
                ...service,
                items: service.items.map((serviceItem, serviceItemIndex) => {
                  const newCharge = chargeItems.find(
                    (updatedCharge) =>
                      updatedCharge.serviceId === charge.serviceId
                  )?.serviceOptions?.[serviceOptionIndex]?.[serviceIndex]
                    ?.items?.[serviceItemIndex];

                  return updateCharges(
                    serviceItem,
                    newCharge,
                    newChargeId,
                    chargeType
                  );
                }),
              }))
            : serviceOption.map((service, serviceIndex) => ({
                ...service,
                items: service.items.map((serviceItem, serviceItemIndex) => {
                  const newCharge = chargeItems.find(
                    (updatedCharge) =>
                      updatedCharge.serviceId === charge.serviceId
                  )?.serviceOptions?.[serviceIndex]?.items?.[serviceItemIndex];

                  return updateCharges(
                    serviceItem,
                    newCharge,
                    newChargeId,
                    chargeType
                  );
                }),
              }))
      ),
      serviceAddons:
        Array.isArray(charge?.serviceAddons) &&
        charge.serviceAddons.map((addon, addonIndex) => {
          const newCharge = chargeItems[chargeIndex].serviceAddons[addonIndex];
          return updateCharges(addon, newCharge, newChargeId, chargeType);
        }),

      ...(charge?.additionalRentalTerms &&
        charge?.additionalRentalTerms?.newPrice && {
          additionalRentalTerms:
            Object.keys(charge?.additionalRentalTerms).length > 0 &&
            (() => {
              const rentalTerms = charge?.additionalRentalTerms;
              const newCharge =
                chargeItems?.[chargeIndex]?.additionalRentalTerms;

              return updateCharges(
                rentalTerms,
                newCharge,
                newChargeId,
                chargeType
              );
            })(),
        }),
    };
  });

  return chargeToUpdate;
};

export const updateAlreadySOVCreatedCharges = (
  oldCharge,
  chargeItems,
  newChargeId,
  chargeType
) => {
  const chargeToUpdate = oldCharge.map((charge, chargeIndex) => {
    if (charge.label === "Hoist" || charge.isHoist) {
      return {
        ...charge,
        serviceOptions: charge.serviceOptions.map(
          (serviceOption, serviceOptionIndex) =>
            Array.isArray(serviceOption)
              ? serviceOption.map((service, serviceIndex) => ({
                  ...service,
                  amounts: service.amounts.map(
                    (serviceItem, serviceItemIndex) => {
                      const newCharge = chargeItems.find(
                        (updatedCharge) =>
                          updatedCharge.serviceId === charge.serviceId &&
                          updatedCharge.estimationId === charge.estimationId
                      )?.serviceOptions?.[serviceOptionIndex]?.[serviceIndex]
                        ?.amounts?.[serviceItemIndex];

                      return updateSOVCharges(
                        serviceItem,
                        newCharge,
                        newChargeId,
                        chargeType
                      );
                    }
                  ),
                }))
              : serviceOption
        ),
      };
    }
    return {
      ...charge,
      amounts: charge.amounts.map((serviceItem, serviceItemIndex) => {
        const newCharge = chargeItems.find(
          (updatedCharge) =>
            updatedCharge.serviceId === charge.serviceId &&
            updatedCharge.estimationId === charge.estimationId
        )?.amounts?.[serviceItemIndex];
        return updateSOVCharges(
          serviceItem,
          newCharge,
          newChargeId,
          chargeType
        );
      }),
    };
  });

  return chargeToUpdate;
};
