import React, { useEffect, useState } from "react";
import { InputNumber, Button, Input } from "antd";
import { useRedux } from "../../../../../SidebarPages/hooks";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { useSelector } from "react-redux";
import { MondayButton } from "src/components/commonComponents";
import Swal from "sweetalert2";
import { forceToNumber } from "src/components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const PageRangePicker = ({ onConfirm, configPages, file }) => {
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const { accessToken } = useSelector((state) => state.accessToken);
  const [pagesHolder, setPagesHolder] = useState("");
  const { getFilesByFolderId, ...rest } = driveApi({ accessToken });
  const [proposalData, setProposalData] = useRedux("proposalData");

  const handleStartPageChange = (value) => {
    setStartPage(value);
  };

  const handleEndPageChange = (value) => {
    setEndPage(value);
  };

  const handlePickPages = () => {
    // Validate and handle the selected page range

    onConfirm({
      pagesArr: validateAndCreatePrintPages(pagesHolder),
      pagesInfo: pagesHolder,
    });
    // if (startPage <= endPage) {
    //   onConfirm({
    //     pagesArr: [startPage, endPage],
    //   });
    // } else {
    //   // Show an error message or handle invalid range
    //   console.error("Invalid page range");
    // }
  };

  const onTest = async () => {
    console.log("TEST", {
      proposalData,
    });
    let docId = proposalData?.estimation?.googleDriveFolderIds?.docObject || "";
    if (!!docId) {
      await getFilesByFolderId(docId).then(async (res) => {
        let files = await res?.json().then((res) => res?.files || []);
        let currentFile = files?.find((file) => file?.name === "3D")?.id || "";
        if (!!currentFile) {
          await getFilesByFolderId(currentFile).then(async (res) => {
            let files2 = await res?.json().then((res) => {
              // console.log("RES", { res });
              let file3 = res?.files[0];
              rest.getImageSrc(file3?.id).then((res) => {
                // console.log("RES", { res });
                // window.open(res?.src, "_blank");
                // var blob = b64toBlob(mergedPdf, "application/pdf");
                const blobURL = res.blob;
                window.open(blobURL, "_blank");
              });
            });
          });
        }
      });
    }
  };

  function validateAndCreatePrintPages(input) {
    const pages = [];

    // Split the input by commas to get individual parts
    const parts = input.split(",") || [];
    // Iterate through each part and validate
    for (const part of parts) {
      const trimmedPart = part.trim();
      console.log("trimmedPart", { trimmedPart });
      if (trimmedPart.includes("-")) {
        const [start, end] = trimmedPart
          .split("-")
          .map((num) => parseInt(num, 10));
        if (!isNaN(start) && !isNaN(end) && start <= end) {
          for (let i = start; i <= end; i++) {
            pages.push(i);
          }
        }
      } else if (!isNaN(trimmedPart)) {
        const pageNumber = forceToNumber(trimmedPart);
        console.log("pageNumber", { pageNumber });
        if (!isNaN(pageNumber)) {
          pages.push(pageNumber);
        }
      }
      // Check if the part is a single page number or a range
      // if (/^\d+$/.test(trimmedPart)) {
      //   // Single page number
      //   const pageNumber = parseInt(trimmedPart, 10);
      //   if (!isNaN(pageNumber)) {
      //     pages.push(pageNumber);
      //   }
      // } else if (/^\d+-\d+$/.test(trimmedPart)) {
      //   // Page range
      //   const [start, end] = trimmedPart
      //     .split("-")
      //     .map((num) => parseInt(num, 10));
      //   if (!isNaN(start) && !isNaN(end) && start <= end) {
      //     for (let i = start; i <= end; i++) {
      //       pages.push(i);
      //     }
      //   }
      // }
      // You can add more validation rules as needed
    }
    console.log("pages", { pages });
    return pages;
  }
  const onCustomChange = (e) => {
    console.log("onCustomChange", { e });
    // Update the state only if the input is valid or if it's an empty string
    const validateInputPattern = (input) => {
      let res = validateAndCreatePrintPages(input);
      return res?.length > 0;
    };
    const inputValue = e.target.value;

    // Validate the input pattern using a custom function
    const isValidInput = validateInputPattern(inputValue);
    if (isValidInput || inputValue === "") {
      setPagesHolder(inputValue);
    } else {
      Swal.fire({
        title: "Invalid input",
        text: "Please enter a valid page range",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      setPagesHolder("");
    }
  };

  useEffect(() => {
    console.log("fileTest", { file, configPages });
    setPagesHolder(configPages?.[file?.uid]?.pagesInfo || "");
  }, [file, configPages]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
      }}
    >
      {/* <Button onClick={onTest}>TEST</Button> */}
      <span>Pages (e.g. 1, 6-7):</span>
      <Input
        value={pagesHolder}
        style={{
          width: "200px",
        }}
        onChange={(e) => setPagesHolder(e.target.value)}
        onBlur={onCustomChange}
      />
      {/* <InputNumber
        min={1}
        value={startPage}
        onChange={handleStartPageChange}
        style={{ marginRight: "8px" }}
      />
      -
      <InputNumber
        min={1}
        value={endPage}
        onChange={handleEndPageChange}
        style={{ marginLeft: "8px", marginRight: "8px" }}
      /> */}
      <MondayButton
        className="mondayButtonBlue"
        hasIcon={false}
        onClick={handlePickPages}
      >
        Apply Custom Change
      </MondayButton>
    </div>
  );
};

export default PageRangePicker;
