import React, { useEffect, useState } from "react";
import { DatePicker, InputNumber, message, notification, Tooltip } from "antd";
import { useRedux } from "../../../../../../../../../../../hooks";
import {
  getDisabledTooltipContent,
  headerDateInputHandler,
  multiHeaderDateInputHandler,
} from "../HeaderDateInput/HeaderDateInputFunctions";
import { updateRental } from "../../../../../../../../../../../../../actions/rentals";
import { getWeeksDiff } from "../../../RentalBody/AgGrid/columnDefs";
// import dayjs from "dayjs";
import { CalendarIcon } from "../../../../../../../../../../../BasePage/src";
import { MondayButton } from "../../../../../../../../../../../../commonComponents";
import { firstAndLastDateOfAnElevation } from "../HeaderDateInput/HeaderDateInput";
import { ImportOutlined } from "@ant-design/icons";
import RetrieveLastInstallDay from "./RetrieveLastInstallDay";
import Swal from "sweetalert2";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import { parseInTz } from "../../../../../../../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { handleSave } from "../../../../../../Header/headerFunction";
const { RangePicker } = DatePicker;
// dayjs.tz.setDefault("America/New_York");

export default function ChangeDateUpperLevel({
  elevationData,
  selectedServiceData,
  canRedo,
}) {
  const [isWritable] = useRedux("rentalsIsWritable");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const [noWeeksIndic, setNoOfWeeksIndic] = useState({});
  const dispatch = useDispatch();
  const [rentalFromSchedule, setRentalFromSchedule] =
    useRedux("rentalFromSchedule");
  console.log("fakeRentals", fakeRentals);
  const handleDateInput = (date) => {
    setNoOfWeeksIndic((prev) => ({
      ...prev,
      [selectedService]: {
        interval: [...date],
        value: getWeeksDiff(date[1], date[0]),
      },
    }));
    fakeRentals?.services?.forEach((el) => {
      if (el?.label === selectedService) {
        message.destroy();
        message.info(
          "Be careful! You have applied a date change for all PLI's of this service and their respective items."
        );
        multiHeaderDateInputHandler({
          setUnchangingFakeRentals,
          selectedService: selectedService,
          setFakeRentals,
          elevationData: el?.serviceOptions,
          fakeRentals,
          date,
          fromSchedule: rentalFromSchedule,
        });
      }
    });
    // return headerDateInputHandler({
    // 	setUnchangingFakeRentals,
    // 	selectedService,
    // 	setFakeRentals,
    // 	elevationData,
    // 	fakeRentals,
    // 	date,
    // });
  };

  const onRetreiveElevation = async () => {
    console.log("onRetreiveElevation", { elevationData, fakeRentals });
    await API.get("scheduling", `/scheduling/${fakeRentals?.scheduleId}`)
      .then((res) => {
        let myFind = firstAndLastDateOfAnElevation(
          res?.toBeScheduled,
          elevationData?.elevationId
        );
        console.log("myFind", { myFind, res });

        if (!myFind?.firstDate || !myFind?.lastDate) {
          Swal.fire({
            title: "Error",
            text: "There are no Schedule dates for this Elevation > Project!",
            icon: "error",
          });
          return;
        }
        handleDateInput([
          parseInTz(myFind?.firstDate),
          parseInTz(myFind?.lastDate),
        ]);
      })
      .catch((err) =>
        Swal.fire({
          title: "Error",
          text: "There is no Schedule for this Project!",
          icon: "error",
        })
      )
      .finally(() => {
        message.destroy();
      });
  };
  //here we extract the reason why the input is disabled
  const disableValue = getDisabledTooltipContent({
    selectedService,
    elevationData,
    fakeRentals,
    isWritable,
  });

  const onWeekGen = (data) => {
    console.log("data", data, "float", parseFloat(data));
    let first =
      parseInTz(noWeeksIndic?.[selectedService]?.interval[0]) || parseInTz();
    let second = parseInTz(first).add(data * 7 - 1, "days");

    handleDateInput([first, second]);
  };

  const onDoubleCheck = async () => {
    let applicableEstimation = fakeRentals?.estimationId || "";
    await API.get("estimations", `/estimations/${applicableEstimation}`).then(
      (res) => {
        console.log("reestimationss", res);
        let estNumber = res?.quickbooksNumber || res?.estimationNumber || "";
        let tempRentals = {
          ...fakeRentals,
          services: fakeRentals?.services?.map(
            (el) =>
              ({
                ...el,
                quickbooksNumber: estNumber,
              } || [])
          ),
        };

        setFakeRentals(tempRentals);
        handleSave({ dispatch, updateRental, fakeRentals: tempRentals });
      }
    );
  };
  console.log("noWeeksIndic", noWeeksIndic);

  // useEffect(() => {
  //   if (canRedo)
  //     setNoOfWeeksIndic({
  //       value: 0,
  //       interval: [],
  //     });
  // }, [canRedo]);

  return (
    <>
      {selectedServiceData && (
        <div className="HeaderDateInput" style={{ marginRight: "20px" }}>
          {" "}
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={onDoubleCheck}
          >
            Qb no. #{selectedServiceData?.quickbooksNumber || "N/A"}
          </MondayButton>
          {/* <RetrieveLastInstallDay
            {...{
              selectedServiceData,
              handleDateInput,
            }}
          /> */}
          <Tooltip title={disableValue}>
            <RangePicker
              allowClear={false}
              style={{ width: "300px" }}
              value={noWeeksIndic?.[selectedService]?.interval}
              onChange={(date) => handleDateInput(date)}
              format="MM/DD/YYYY"
              // presets={getRangePresets()} //TOFIX: this DOES NOT WORK
              popupClassName="filter-by-date-picker-dropdown"
              className="datePickerInputHeaderRentals"
              suffixIcon={<CalendarIcon />}
              disabled={!!disableValue}
            />
          </Tooltip>{" "}
          <Tooltip title={disableValue}>
            <InputNumber
              style={{ width: "80px", borderRadius: "5px" }}
              value={noWeeksIndic?.[selectedService]?.value}
              onChange={(e) => {
                if (!e) return;
                return onWeekGen(parseFloat(e));
              }}
              type="number"
              min={1}
              max={52}
              disabled={!!disableValue}
            />
          </Tooltip>
          {/* <MondayButton>Apply to all But</MondayButton> */}
        </div>
      )}
    </>
  );
}
