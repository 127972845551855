import { useState } from "react";

export const useCountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [retryTime, setRetryTime] = useState(null);

  function startTimer(newTime) {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = newTime - now;

      if (timeDiff <= 0) {
        clearInterval(interval);
        localStorage.removeItem("retryTime");
        setTimeLeft(null);
        setRetryTime(null);
        return;
      }

      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = String(
        Math.floor((timeDiff % (1000 * 60)) / 1000)
      ).padStart(2, "0");

      setTimeLeft(`${minutes}:${seconds}`);
      setRetryTime(newTime);
    }, 1000);
  }

  return { timeLeft, retryTime, setRetryTime, startTimer };
};
