import "./StepHeader.scss"

const StepHeader = ({ title = "", subtitle = "", bordered = true }) => {
  return (
    <div className="stepHeaderContainer">
      <div className={`stepTitle ${!!bordered ? "borderedTitle" : ""}`.trim()}>
        {title}
      </div>
      {!!subtitle && <div className="stepSubtitle">{subtitle}</div>}
    </div>
  )
}

export default StepHeader
