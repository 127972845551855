export function createNewElement() {
  let newItem = document.createElement("span");
  newItem.setAttribute("id", "customNextButton");
  newItem.style.color = "#181d1f";
  newItem.style.cursor = "pointer";
  newItem.style.fontSize = "14px";
  newItem.style.fontWeight = "bolder";
  newItem.innerHTML = "Get Other Data";

  return newItem;
}

export function createMessageElement() {
  let messageItem = document.createElement("span");
  messageItem.setAttribute("id", "fetchedAllDataMessage");
  messageItem.style.color = "#ff0000";
  messageItem.style.fontSize = "14px";
  messageItem.style.fontWeight = "bolder";
  messageItem.innerHTML = "You fetched all data";

  return messageItem;
}

export function movePaginationInput() {
  const flagEl = document?.querySelector(".ag-paging-page-summary-panel");
  const paginationPanel = document?.querySelector(".ag-status-bar-right");
  if (flagEl && paginationPanel) {
    const offsetWidth1 = flagEl?.offsetWidth || 200;
    paginationPanel.style.right = `${offsetWidth1}px`;
  }
}
