import dayjs from "dayjs";
import { dayjsNY } from "../../../../../components/DateComponents/contants/DayjsNY";

export const testKey = (key) => {
  const ALLOWED_KEYS = [
    "Tab",
    "Backspace",
    "Shift",
    "ArrowRight",
    "ArrowLeft",
    "ArrowUp",
    "ArrowDown",
    "Control",
  ];
  const RGX = /^[0-9]*$/;

  return RGX.test(key) || ALLOWED_KEYS.includes(key);
};

export function timeValidator(form, customFormItem, required, submit = false) {
  const {
    [`mm${customFormItem}`]: mm,
    [`hh${customFormItem}`]: hh,
    [`ampm${customFormItem}`]: ampm,
    [`MM${customFormItem}`]: MM,
    [`DD${customFormItem}`]: DD,
    [`YYYY${customFormItem}`]: YYYY,
  } = form.getFieldsValue();
  let errorText = document?.getElementById(`time-error-text${customFormItem}`);

  if ((!mm || !hh) && !!required === true) {
    if (!!submit === true) {
      if (!!errorText) {
        errorText.innerText = "*Time is required";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Time is required";
    }
    return Promise.reject();
  } else {
    if (!!errorText) {
      errorText.innerText = "";
    }
  }

  if (Object.values({ MM, DD, YYYY }).some((val) => !val)) {
    if (!!submit === true) {
      if (!!errorText) {
        errorText.innerText = "*Date is required";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Date is required";
    }
    return Promise.reject();
  }

  let month = +MM < 10 ? `0${+MM}` : MM;
  let day = +DD < 10 ? `0${+DD}` : DD;

  if (!ampm) {
    if (!!submit === true) {
      if (!!errorText) {
        errorText.innerText = "*Please specify AM/PM";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText = "*Please specify AM/PM";
    }
    return Promise.reject();
  }

  if (+hh > 12 || +hh < 1) {
    form.setFieldValue("hh", "");
    if (!!submit === true) {
      if (!!errorText) {
        errorText.innerText = "*Invalid hour";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Invalid hour";
    }
    return Promise.reject();
  }
  if (+mm > 59 || +mm < 0) {
    form.setFieldValue("mm", "");
    if (!!submit === true) {
      if (!!errorText) {
        errorText.innerText = "*Invalid minute";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Invalid minute";
    }
    return Promise.reject();
  }

  let hour = +hh < 10 ? `0${+hh}` : hh;
  let minute = +mm < 10 ? `0${+mm}` : mm;
  let inputDate = dayjsNY(
    `${month}/${day}/${YYYY} ${hour}:${minute} ${ampm}`,
    `MM/DD/YYYY hh:mm A`
  ).valueOf();

  if (Date.now() > inputDate) {
    if (!!submit === true) {
      if (!!errorText) {
        errorText.innerText = "*Cannot set a time in the past";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Cannot set a time in the past";
    }
    return Promise.reject();
  }

  if (!!submit === true) {
    if (!!errorText) {
      errorText.innerText = "";
    }
    return Promise.resolve();
  }
  if (!!errorText) {
    errorText = "";
  }
  return Promise.resolve();
}

export function dateValidator(form, customFormItem, required, submit = false) {
  let errorText = document?.getElementById(`date-error-text${customFormItem}`);
  const {
    [`MM${customFormItem}`]: MM,
    [`DD${customFormItem}`]: DD,
    [`YYYY${customFormItem}`]: YYYY,
  } = form.getFieldsValue();

  let month = +MM < 10 ? `0${+MM}` : MM;
  let day = +DD < 10 ? `0${+DD}` : DD;

  const today = dayjs().startOf("D").valueOf();
  let inputDate = dayjs(`${month}/${day}/${YYYY}`, `MM/DD/YYYY`)
    .startOf("D")
    .valueOf();

  const lastDayOfMonth = new Date(
    !!YYYY ? YYYY : dayjsNY()?.year(),
    MM,
    0
  ).getDate();

  if (!MM && !DD && !YYYY && !!required === true) {
    if (!!submit) {
      if (!!errorText) {
        errorText.innerText = "*Date is Required";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Date is Required";
    }
    return Promise.reject();
  }

  if (Object.values({ MM, DD, YYYY }).some((val) => !val)) {
    if (!!submit) {
      if (!!errorText) {
        errorText.innerText = "*Please enter a valid date";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Please enter a valid date";
    }
    return Promise.reject();
  }

  if (parseInt(MM) > 12 || parseInt(MM) < 1) {
    form.setFieldValue("MM", "");
    if (!!submit) {
      if (!!errorText) {
        errorText.innerText = "*Invalid month";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Invalid month";
    }
    return Promise.reject();
  }

  if (today > inputDate) {
    form.setFieldsValue({ MM: "", DD: "", YYYY: "" });
    if (!!submit) {
      if (!!errorText) {
        errorText.innerText = "*Cannot set a date in the past";
      }
      return Promise.reject();
    }
    if (!!errorText) {
      errorText.innerText = "*Cannot set a date in the past";
    }
    return Promise.reject();
  }

  if (parseInt(DD) > lastDayOfMonth || parseInt(DD) < 1) {
    form.setFieldValue("DD", "");
    if (!!submit) {
      if (!!errorText) {
        errorText.innerText = "*Invalid day of month";
      }
      return Promise.reject();
    }
    return Promise.reject();
  }

  if (!!submit) {
    if (!!errorText) {
      errorText.innerText = "";
    }
    return Promise.resolve();
  }
  if (!!errorText) {
    errorText.innerText = "";
  }
  return Promise.resolve();
}
