export const getPriceForCharge = (charges) =>
  charges?.reduce(
    (prev, curr) => (prev += Number((curr?.chargeAmount || 0).toFixed(2))),
    0
  );

export const getPriceChargedCredited = (charges) =>
  charges?.reduce(
    (prev, curr) => (prev += Number((curr?.chargeAmount || 0).toFixed(4))),
    0
  );
