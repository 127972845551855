import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react";
import { useState, useContext } from "react";

import { getCostDispersion } from "../utils";
import DegModalContext from "../DegModalContext";
import { mainColDefs, detailColDefs } from "./costDispersionData";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";

import "./CostDispersionModal.scss";

function CostDispersionModal({ open, onCancel }) {
  const { analytics, jobsites, isDarkMode } = useContext(DegModalContext);
  const [rowData] = useState(getCostDispersion({ analytics, jobsites }));

  return (
    <Modal
      title={"Cost Distribution"}
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Cost Distribution" });
      }}
      open={open}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel();
      }}
      closable={true}
      closeIcon={<XIcon />}
      data-testid="cost-distribution-modal"
      className={`costDispersionModal ${
        isDarkMode && "costDispersionModalDark"
      }`}
      centered
      footer={
        <MondayButton
          className="mondayButtonRed"
          data-testid="close-cost-distribution-modal-btn"
          key={"1"}
          Icon={<XIcon />}
          onClick={() => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            onCancel();
          }}
        >
          Close
        </MondayButton>
      }
    >
      <div
        className={`cost-dispersion-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            columnDefs: mainColDefs(),
            detailCellRendererParams: detailColDefs(),
            masterDetail: true,
            rowSelection: "multiple",
            pagination: false,
            detailRowHeight: 500,
            gridOptions: {
              alwaysShowHorizontalScroll: true,
              alwaysShowVerticalScroll: true,
            },
            suppressRowClickSelection: true,
            animateRows: true,
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
    </Modal>
  );
}
export default CostDispersionModal;
