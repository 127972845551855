export const editUserStepData = [
  {
    title: "Route Access Tab",
    id: "#Route",
  },
  {
    title: "Sidebar Access Tab",
    id: "#Sidebar",
  },
  {
    title: "Notification Preferences Tab",
    id: "#DriveAccessRights",
  },
  {
    title: "Assign Task",
    id: "#assignTaskButton",
  },
  {
    title: "Search Users",
    id: "#searchUsers",
  },
  {
    title: "View Logs",
    id: "#logsButton",
  },
  {
    title: "Download as PDF",
    id: "#downloadButton",
  },
  {
    title: "Preview all Access Rights",
    id: "#previewButton",
  },
  {
    title: "Save Changes",
    id: "#submitButton",
  },
];
