import "./newNoteModal.scss";
import { Modal } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Tick } from "../../../../components/pages/Settings/settingsComponents/Roles/src";
import { antdModalProps } from "../../../../utils/antdModalProps";
import { FetchedOptions, MondayButton } from "../../../commonComponents/index";
import { Notes } from "../../../commonComponents/Notes/Notes";
import { InputComponent } from "../../Fleet/components/index";
import { getAccessRights } from "../../../../utils/getAccessRights";
import { CheckAccessRights } from "../../../../utils/CheckAccessRights";
import { message } from "antd";
import _ from "lodash";
import { updateDocumentTitle } from "../../../../utils";

const NewNoteModal = ({ visible, setVisible }) => {
  const { topicCategories = [] } = useSelector(
    (state) => state.topicCategories
  );
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [noteToAdd, setNoteToAdd] = useState();
  const [modalVisibility, setModalVisibility] = useState(true);

  const topics = topicCategories.reduce((acc, category) => {
    const title =
      category.categoryName === "Clients"
        ? "Accounts"
        : category.categoryName === "Fleet Inspections"
        ? "Fleets Inspections"
        : category.categoryName;

    // used to check if user has access to view records of each topic
    if (
      category.categoryName !== "Notes" &&
      getAccessRights({ userConfiguration, title }).routeConfig.read === true
    ) {
      acc.push(category);
    }
    return acc;
  }, []);

  //set selected topic & get related records of selected topic
  const setTopic = (event) => {
    setSelectedRecord();

    const topicSelected = topics.find((top) => top.categoryName === event);

    // get access rights to notes
    const notesAccess = getAccessRights({
      userConfiguration,
      title: "Notes/View",
    }).routeConfig?.children;

    // and check if it has acces to view notes for that topic
    if (CheckAccessRights(notesAccess, topicSelected.categoryName)) {
      setSelectedTopic(topicSelected);
    } else {
      message.error(
        `You don't have access to view notes of ${topicSelected.categoryName}`
      );
    }
  };

  const handleCloseModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
    setNoteToAdd(null);
  };

  return (
    <>
      <Modal
        {...{
          ...antdModalProps,
          title: "New Note Modal",
          className: `newNoteModal ${isDarkMode && "newNoteModalDark"}`,
          afterOpenChange: (event) => {
            event && updateDocumentTitle({ newTitle: "New Note Modal" });
          },
          open: modalVisibility ?? visible,
          onCancel: () => {
            handleCloseModal();
            setSelectedRecord();
          },
        }}
      >
        <div className="note-modal-content">
          <InputComponent
            required
            showSearch
            label="Topic"
            placeholder="Select one topic"
            type="select"
            dropdownClassName={isDarkMode && "darkDropDown"}
            customOptions={topics.map(({ categoryName }) => ({
              label: categoryName,
              value: categoryName,
            }))}
            onSelect={(e) => {
              setTopic(e);
            }}
            onClear={() => {
              setSelectedTopic();
              setSelectedRecord();
            }}
          />

          {selectedTopic && (
            <FetchedOptions
              {...{
                selectedTopic,
                onChange: (
                  value,
                  { recordId, recordName, teamsConfiguration }
                ) => {
                  if (value) {
                    setSelectedRecord({
                      recordId,
                      relatedTo: recordName,
                      teamsConfiguration,
                      topicCategory: selectedTopic.categoryName,
                    });
                  }
                },
                onClear: () => {
                  setSelectedRecord();
                },
              }}
            />
          )}

          <div className="modalFooter">
            <MondayButton
              {...{
                Icon: <Tick />,
                onClick: () => {
                  setModalVisibility(false);
                  setNoteToAdd(selectedRecord);
                },
                disabled: !selectedTopic || !selectedRecord,
                tooltipCategory: "Notes",
                tooltipKey: "open",
              }}
            >
              Open Notes
            </MondayButton>
          </div>
        </div>
      </Modal>
      {noteToAdd && (
        <Notes
          {...{
            noteModalName: noteToAdd.relatedTo,
            rowId: noteToAdd?.recordId,
            topicCategory: noteToAdd.topicCategory,
            notesVisibility: true,
            setNotesVisibility: handleCloseModal,
            customLinkTo:
              noteToAdd.categoryName === "Subcontractors"
                ? `accounts/${noteToAdd?.recordId}`
                : undefined,
            teamsConfiguration: noteToAdd?.teamsConfiguration,
          }}
        />
      )}
    </>
  );
};

export default NewNoteModal;
