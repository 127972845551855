import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Popover } from "antd";

import { InputComponent } from "src/components/SidebarPages/Fleet/components";

import "./NotesEditor.scss";

const NotesEditor = forwardRef((props, ref) => {
  const [selectedValue, setSelectedValue] = useState(props?.value);

  const { value, context, stopEditing, eGridCell } = props;

  useEffect(() => {
    if (!context?.isWritable) {
      stopEditing();
    }
  }, [context?.isWritable]);

  useEffect(() => {
    const popover = document.querySelector(".notes-editor-popover-overlay");
    const content = document.getElementById("notes-editor-popover-overlay");
    if (popover && eGridCell?.clientWidth) {
      popover.style.width = `${eGridCell?.clientWidth}px`;
    }

    if (content) {
      const area = content.querySelector("textarea");
      if (area && area !== document.activeElement) {
        area.selectionStart = area.selectionEnd = area.textContent.length;
        area.focus();
      }
    }
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return selectedValue;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    [selectedValue]
  );

  return (
    <Popover
      open
      placement="bottom"
      arrowContent={null}
      overlayClassName="notes-editor-popover-overlay"
      id="notes-editor-popover-overlay"
      content={
        <InputComponent
          {...{
            rows: 5,
            label: "",
            autoFocus: true,
            type: "textarea",
            onBlur: stopEditing,
            defaultValue: value,
            placeholder: "Notes...",
            onKeyDown({ key }) {
              if (key === "Enter" || key === "Escape") {
                stopEditing();
              }
            },
            onChange(e) {
              setSelectedValue(e.target.value);
            },
          }}
        />
      }
    >
      <div style={{ height: 1, width: "100%" }}></div>
    </Popover>
  );
});

export default NotesEditor;
