import React from "react";
import "../../EstimationServiceTotal/ServicesForEach/ServicesForEach.css";
import { getRawServiceTotals } from "../../../../../Estimations/DataEntryGrid/tools/formatters/totals";
import { numberWithCommas } from "../../../../../utils/numberWithCommas";
import { roundToDecimals } from "../../../../../utils/roundToDecimals";
import { otherScopeServiceTotalPriceMapper } from "../../../../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/utils";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  getServiceLegacyPrice,
  getServicePriceInclAddons,
} from "../../../../../Projects/Accounting/calculations/servicePrices";
import { checkIfServiceIsHoist } from "../../../../../Estimations/DataEntryGrid/models/Service";
import { formatCurrency } from "../../../../../utils";

export default function ServicesForEach({ services }) {
  return services.map((service) => {
    // const {priceSubtotal} = getRawServiceTotals(service)
    const total = getServiceLegacyPrice(service);
    return (
      <div className="service_price_total">
        <div className="pre_horizontal_line">
          <hr></hr>
        </div>
        <h4>{service.label} </h4>
        <div className="service_horizontal_line">
          <hr></hr>
        </div>
        <div className="service_price_wrapper">
          {isNaN(total) ? "-" : formatCurrency(total)}
        </div>
      </div>
    );
  });
}
