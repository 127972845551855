import moment from "moment";
import { useState } from "react";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";

import {
  findLastTrueStatusIndex,
  filterPaymentsByInvoiceIdAndDate,
} from "./helpers";
import {
  filterTables,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../utils";
import { Steps } from "antd";
import { XIcon } from "../../../Communication/assets";
import { WarningTriangle } from "../../../DynamicView/src";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { MondayButton, WarningModal } from "../../../../commonComponents";

import "./InvoiceActivity.scss";

export default function InvoiceActivity({
  selectedData,
  setSelectedData,
  setData,
  setRowData,
}) {
  const [activityIndex, setActivityIndex] = useState();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const invoiceActivity = selectedData?.invoiceActivity || [];

  const lastActiveIndex = findLastTrueStatusIndex(invoiceActivity);

  const updateInvoiceActivityStatus = async (index) => {
    const payments = selectedData?.accountId
      ? await filterTables("payments", "accountId", selectedData.accountId)
      : [];

    const lastPaymentDate = filterPaymentsByInvoiceIdAndDate(
      payments,
      selectedData?.invoiceId
    )?.paymentDate;

    if (!lastPaymentDate) {
      showErrorMsg({
        content:
          "This invoice does not have any payments. Status cannot be changed",
      });
      return;
    }

    const updatedSelectedData = { ...selectedData };

    const { invoiceActivity } = updatedSelectedData;

    const updateInvoiceActivity = invoiceActivity
      .slice(0, index + 1)
      .map((activity) =>
        !activity.date
          ? {
              ...activity,
              date:
                activity.title === "Paid"
                  ? lastPaymentDate
                  : moment().tz("America/New_York").valueOf(),
              status: true,
            }
          : activity
      );

    const newInvoiceActivity = invoiceActivity.slice(index + 1);

    const newActivities = [...updateInvoiceActivity, ...newInvoiceActivity];

    const lastActivityStatus = findLastTrueStatusIndex(newActivities);

    const updatedInvoice = {
      ...updatedSelectedData,
      invoiceActivity: newActivities,
    };

    showLoadingMsg({ content: "Updating invoice activity. Please wait..." });
    await API.put("invoices", `/invoices/${selectedData?.invoiceId}`, {
      body: {
        invoiceStatus: newActivities[lastActivityStatus].title,
        invoiceActivity: newActivities,
      },
    })
      .then(() => {
        setSelectedData(updatedInvoice);
        setData((prev) => ({
          ...prev,
          invoiceStatus: newActivities[lastActivityStatus].title,
          invoiceActivity: newActivities,
        }));

        setRowData((prev) => {
          const rowData = prev.filter(
            (invoice) => invoice.invoiceId !== selectedData?.invoiceId
          );
          return [...rowData, updatedInvoice];
        });
        setActivityIndex();
        showSuccessMsg({ content: "Invoice activity updated successfully." });
      })
      .catch(() =>
        showErrorMsg({ content: "Failed updating invoice activity." })
      );
  };

  const onStepClick = async (index) => setActivityIndex(index);

  const activities =
    selectedData?.invoiceActivity?.map((activity) => ({
      title: activity.title,
      description: activity.date
        ? moment(activity.date).tz("America/New_York").format("MM/DD/YYYY")
        : " ",
      disabled: activity.status,
    })) || [];

  return (
    <div
      className={`${isDarkMode ? "invoiceActivityDark" : "invoiceActivity"}`}
    >
      <div className="vertical-line" />
      <Steps
        progressDot
        current={lastActiveIndex}
        direction="vertical"
        items={activities}
        onChange={onStepClick}
      />

      <WarningModal
        visible={activityIndex}
        setVisible={() => setActivityIndex()}
        title="Confirm change"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setActivityIndex()}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => updateInvoiceActivityStatus(activityIndex)}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
}
