import { useContext } from "react";
import "./VideoCard.scss";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { useSelector } from "react-redux";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Logs from "./Logs/Logs";

const VideoCard = ({
  data,
  searchVideo = false,
  isFavourite = false,
  isNewVideo = false,
  isUnfinishedVideo = false,
  onOpen = () => {},
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const {
    setCustomeModal,
    setVideoActive,
    setFilterInput,
    setPlayingVideo,
    setHasWatched,
    setFavOrNewCategory,
  } = useContext(VideoTutorialContext);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const handleClick = () => {
    onOpen();
    searchVideo && setFilterInput("");
    setCustomeModal(false);
    setVideoActive(data?.videoLink);
    setPlayingVideo(data);
    setHasWatched(false);
    setFavOrNewCategory(
      isFavourite
        ? "fav"
        : isNewVideo
        ? "new"
        : isUnfinishedVideo
        ? "unfinished"
        : ""
    );
  };

  return (
    <div
      className={`${
        isDarkMode ? " videoCard-container dark-mode" : "videoCard-container"
      }
      ${data?.subCategoryName ? "newCategory" : ""}`}
      datavideo={data?.videoLink}
      key={data?.id}
    >
      <div className="new-video-card-category-info" onClick={handleClick}>
        <div className="play-icon-container">
          <PlayCircleOutlined className="play-icon" />
        </div>
        <div>
          <div className="card-text-info">{data?.videoName}</div>
          <div className="video-bottom">
            <span className="video-length">
              Video length: {data?.lengthVideoInMin}
              min
            </span>
          </div>
        </div>
      </div>
      {userConfiguration.groupName === "Admin" && (
        <Tooltip title="Logs">
          <Logs
            category="recordId"
            videoName={data.videoName}
            name={`VideoTutorials ${data.id}`}
            title="Video Tutorials"
            noTextButton
          />
        </Tooltip>
      )}
    </div>
  );
};

export default VideoCard;
