import { Button, Tooltip } from "antd";
import getFormattedFleetInfo from "./getFormattedFleetInfo";
import { InfoIconBlue, UserIcon, TruckIcon } from "../icons";
import { MondayButton } from "../../../../../../../../../commonComponents";

function dispatchColumns({
  dispatches = [],
  setDriverSelectionVisible = () => {},
  openDispatchModal = () => {},
  driverSelections = [],
}) {
  return [
    {
      headerName: "Truck #",
      field: "fleetName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "",
      maxWidth: 55,
      cellRenderer({ data }) {
        return (
          <Tooltip
            trigger={["hover", "click"]}
            title={getFormattedFleetInfo(data)}
            placement="right"
            overlayStyle={{
              width: "fit-content",
            }}
          >
            <InfoIconBlue />
          </Tooltip>
        );
      },
      cellStyle: {
        display: "grid",
        placeItems: "center",
      },
    },
    {
      headerName: "Existing Routes",
      valueGetter: ({ data }) => {
        let dispatchesInvolvingTruck = dispatches?.filter(
          ({ fleetId }) => fleetId === data?.fleetId
        );
        if (dispatchesInvolvingTruck?.length) {
          return `Show Dispatches (${dispatchesInvolvingTruck?.length})`;
        } else {
          return "No Dispatches To Show";
        }
      },
      cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        display: "grid",
        placeItems: "center",
      },
    },
    {
      headerName: "Driver",
      minWidth: 180,
      cellStyle: {
        display: "grid",
        placeItems: "center",
      },
      cellRenderer({ data }) {
        let selectedDriver = driverSelections?.find(
          ({ fleetId }) => data?.fleetId === fleetId
        )?.driverName;
        return (
          <MondayButton
            {...{
              Icon: <UserIcon />,
              className: selectedDriver ? "mondayButtonBlue" : "noDriverButton",
              onClick() {
                setDriverSelectionVisible(data?.fleetId);
              },
            }}
          >
            {`${selectedDriver || "Select a driver"}`}
          </MondayButton>
        );
      },
    },
    {
      headerName: "Create Dispatch",
      minWidth: 200,
      cellStyle: {
        display: "grid",
        placeItems: "center",
      },
      cellRenderer({ data }) {
        return (
          <MondayButton
            {...{
              Icon: <TruckIcon />,
              className: "mondayButtonBlue",
              onClick() {
                openDispatchModal(data);
              },
            }}
          >
            Create a dispatch
          </MondayButton>
        );
      },
    },
  ];
}

export default dispatchColumns;
