import { message } from "antd";
import { loggedOnTasks as updateTaskRedux } from "../../../../../actions";
import { createEventFromTask } from "../../../../Header/components/GoogleEvents/googleClientAPI/execute";
import { API } from "aws-amplify";

const createNewTask = async ({
  category,
  taskAssignedTo = [],
  taskRelatedTo,
  taskTopicId,
  taskDeadline = Date.now() + 604800000,
  taskStartDate = Date.now(),
  taskEndDate = Date.now() + 604800000, //add 1 week from now,
  taskSubTopic,
  taskSubTopicId = {},
  userConfiguration,
  calendarPreference,
  loggedOnTasks,
  dispatch,
}) => {
  const taskObj = {
    taskTitle: `Automated Task for ${taskRelatedTo}`,
    taskCounter: 0,
    createdBy: "Automation",
    remainingDays: 0,
    taskSubTopicId,
    taskSubTasks: [],
    taskAssignedTo,
    checklist: [],
    taskLabels: [],
    taskDependency: {
      blocking: [],
      blockedBy: [],
    },
    taskRelatedTo,
    taskTopicId,
    taskMilestones: [],
    taskActivity: [],
    totalTimeLogged: {},
    taskDeadline,
    taskCollaborators: [],
    remindMe: {},
    taskEndDate,
    timerFinished: false,
    categoryFrom: {},
    taskPriority: "",
    taskStartDate,
    taskThread: [],
    taskStatus: "Pending",
    taskTimerDisabled: false,
    taskFiles: [],
    taskTopic: category,
    taskSubTopic: taskSubTopic || "",
  };

  return await API.post("tasksManagement", "/tasksManagement", {
    body: taskObj,
  }).then((results) => {
    createEventFromTask(
      { ...taskObj, taskId: results.taskId },
      userConfiguration,
      calendarPreference
    );
    dispatch(updateTaskRedux([...loggedOnTasks, results])); //Update logged tasks state on redux

    message.info("Task created successfully!");
  });
};
export default createNewTask;
