import { useState } from "react";
import { formatCurrency } from "../../../../../utils";
import { Checkbox } from "antd";
import { DescriptionModal } from "./components/DescriptionModal";
import { RequistionAgGrid } from "./RequisitionAgGrid";
import { checkIfServiceIsHoist } from "../../../../../Estimations/DataEntryGrid/models/Service";

export const RequisitionsAgGrid = ({
  service,
  style = {},
  setChargeToSave,
  darkMode,
  isWritable,
  currentCharge,
}) => {
  const tablesToRender = checkIfServiceIsHoist(service)
    ? service?.serviceOptions?.flat(1)?.map(({ serviceOptions, ...el }) => ({
        ...el,
        serviceOptions: serviceOptions?.map((e) => ({
          ...e,
          elevationLabel: el?.elevationLabel,
          elevationId: el?.elevationId,
        })),
      }))
    : [service];

  return (
    <div style={{ marginBottom: 20, ...style }}>
      {tablesToRender?.map(({ serviceOptions: rowData, ...el }) => (
        <RequistionAgGrid
          {...{
            service,
            setChargeToSave,
            darkMode,
            isWritable,
            currentCharge,
            serviceOptions: rowData,
            setChargeToSave,
            ...el,
          }}
        />
      ))}
    </div>
  );
};
