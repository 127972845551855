import dayjs from "dayjs";

//used for RangePicker
export const getRangePresets = () => [
  {
    label: "This Week",
    key: "This Week",
    value: [dayjs().startOf("week"), dayjs().endOf("week")],
  },
  {
    label: "Next 7 Days",
    key: "Next 7 Days",
    value: [dayjs(), dayjs().add(7, "day")],
  },
  {
    label: "Previous Week",
    key: "Previous Week",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "This Month",
    key: "This Month",
    value: [dayjs().startOf("month"), dayjs().endOf("month")],
  },
  {
    label: "Previous Month",
    key: "Previous Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "This Year",
    key: "This Year",
    value: [dayjs().startOf("year"), dayjs().endOf("year")],
  },
  {
    label: "Previous Year",
    key: "Previous Year",
    value: [
      dayjs().subtract(1, "year").startOf("year"),
      dayjs().subtract(1, "year").endOf("year"),
    ],
  },
];
