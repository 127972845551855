import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Segmented } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../../../Fleet/components";
import { ReportCheck } from "../../../../../Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

function AddRemoveFields({ filters, setFilters, initialValues }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [showFields, setShowFields] = useState(false);
  const [category, setCategory] = useState("");
  const categories = useMemo(() => {
    return initialValues?.map((el) => el?.title) || [];
  }, [initialValues]);
  const fields = useMemo(() => {
    if (!category) return [];
    return initialValues?.find((el) => el?.title === category)?.body || [];
  }, [category]);

  return (
    <div className="tab-container">
      <Segmented
        style={{ width: "fit-content" }}
        options={[
          {
            label: "Categories",
            value: false,
            icon: <AppstoreOutlined />,
            "data-testid": "categories",
          },
          {
            label: "Fields",
            value: true,
            icon: <BarsOutlined />,
            "data-testid": "fields",
          },
        ]}
        onChange={(e) => {
          setShowFields(e);
          setCategory("");
        }}
      />

      {showFields ? (
        <>
          <InputComponent
            {...{
              placeholder: "Select Category...",
              label: "Category",
              initialValue: category,
              onChange: (e) => setCategory(e),
              type: "select",
              customOptions: categories
                ?.filter(
                  (el) => !(filters?.removedCategories || []).includes(el)
                )
                ?.map((el) => ({ label: el, value: el })),
              dropdownClassName: isDarkMode && "darkDropDown",
            }}
          />
          <div className="tab-container">
            {fields?.map((el, i) => {
              const { key = "", title = "" } = el;
              const label = key || title;
              return (
                <ReportCheck
                  {...{
                    dataTestId: label,
                    id: label,
                    label: label,
                    onChange: (e) => {
                      setFilters((prev) => {
                        const removedFields =
                          prev?.removedFields?.[category] || [];
                        return {
                          ...prev,
                          removedFields: {
                            ...prev.removedFields,
                            [category]: e
                              ? removedFields.filter((el) => el !== label)
                              : [...removedFields, label],
                          },
                        };
                      });
                    },
                    checked: !(
                      filters?.removedFields?.[category] || []
                    )?.includes(label),

                    // disabled: preselectedFields.includes(label),
                  }}
                  key={i}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div className="tab-container">
          {categories.map((el, i) => {
            return (
              <ReportCheck
                {...{
                  dataTestId: el,
                  id: el,
                  label: el,
                  onChange: (e) => {
                    setFilters((prev) => {
                      const removedCategories = prev?.removedCategories || [];
                      return {
                        ...prev,
                        removedCategories: e
                          ? removedCategories.filter((cat) => el !== cat)
                          : [...removedCategories, el],
                      };
                    });
                  },
                  checked: !(filters?.removedCategories || [])?.includes(el),
                }}
                key={i}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default AddRemoveFields;
