export const onCellKeyPress = e => {
  // ENTER KEY pressed
  if (e.event.keyCode === 13) {
    if (e.event.shiftKey) {
      e.event.preventDefault()
    }
    else {
      e.api.tabToNextCell()
    }
  }
}