import { get_ProgressDimensionsByServiceId } from "src/components/Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { progressInPerc } from "src/components/Header/forms/Scheduling/SchedulingFirstPage/helperData";

export const handleServiceValues = ({
  serviceValues,
  service,
  elevations,
  plis,
  estimationId,
}) => {
  const result = serviceValues?.map((serviceValue) => {
    const serviceChecked =
      estimationId === serviceValue?.estimationId &&
      service.label === serviceValue.name
        ? {
            ...serviceValue,
            value: service?.isChecked
              ? service?.isChecked
              : service?.isIndeterminate
              ? "indeterminate"
              : false,
          }
        : null;

    const correspondingElevation = elevations?.find((elev) => {
      const elevationLabel = `${elev?.label}_${elev.elevationLabel}`;
      return (
        estimationId === serviceValue?.estimationId &&
        elevationLabel === serviceValue.name
      );
    });
    const correspondingElevationLabel =
      `${correspondingElevation?.label}_${correspondingElevation?.elevationLabel}` ??
      "";
    const elevationChecked =
      estimationId === serviceValue?.estimationId &&
      correspondingElevationLabel === serviceValue.name
        ? {
            ...serviceValue,
            value: correspondingElevation?.isChecked
              ? correspondingElevation?.isChecked
              : correspondingElevation?.isIndeterminate
              ? "indeterminate"
              : false,
          }
        : null;

    const pliChecked = plis
      ?.map((elev) => {
        const plisElements = elev?.items
          ?.map((pli) => {
            const pliLabel = `${pli.service}_${elev?.elevationLabel}_${pli?.id}`;
            const correspondingPli =
              estimationId === serviceValue?.estimationId &&
              serviceValue.name === pliLabel;

            if (correspondingPli) {
              return {
                ...serviceValue,
                value: pli?.isChecked ? pli?.isChecked : false,
              };
            }
            return null;
          })
          ?.filter(Boolean);
        return plisElements[0];
      })
      ?.filter(Boolean)[0];

    return serviceChecked
      ? serviceChecked
      : elevationChecked
      ? elevationChecked
      : pliChecked
      ? pliChecked
      : serviceValue;
  });

  return result;
};

export const retrieveProgress = (
  servicesOptions,
  elevationLabel,
  id,
  serviceId
) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  const progress = servicesOptions[0]
    ?.map((service) => {
      const pli = service?.items?.find(
        (item) =>
          item?.id === Number(id) && service?.elevationLabel === elevationLabel
      );

      if (pli) {
        return progressInPerc(pli?.totalProgress, d1, d2, pli);
      }
      return null;
    })
    .filter(Boolean);

  return progress[0] ?? 0;
};
