export const APPROVALS_REQUESTS = {
  new: "new-approval",
  update: "update-approval",
  delete: "delete-approval",
};

export const approvalsMsg = (req, dispatch) => {
  const { body, request } = req;

  switch (request) {
    case APPROVALS_REQUESTS.update + "-res": {
      dispatch({ type: "UPDATE_APPROVAL", payload: body });
      dispatch({ type: "UPDATE_REQUEST", payload: body });
      break;
    }
    case APPROVALS_REQUESTS.new + "-res": {
      dispatch({ type: "ADD_APPROVAL", payload: body });
      dispatch({ type: "ADD_REQUEST", payload: body });
      break;
    }
    case APPROVALS_REQUESTS.delete + "-res": {
      const { requestId } = body;
      dispatch({ type: "DELETE_APPROVAL_BY_ID", payload: { id: requestId } });
      dispatch({ type: "DELETE_REQUEST_BY_ID", payload: { id: requestId } });
      break;
    }
    default:
      break;
  }
};
