import { message } from "antd";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { RedWarningModal } from "../../../../../../../commonComponents";
import { LogoutIcon } from "src/assets/icons";

function LogoutOptions({
  logoutOptionsModal,
  setLogoutOptionsModal,
  logoutUserSession,
  nameOfUser,
  askToLogout,
  logoutTime,
  setLogoutTime,
}) {
  return (
    <RedWarningModal
      {...{
        visible: logoutOptionsModal,
        onCancel: () => setLogoutOptionsModal(false),
        confirmModal: true,
        titleText: "Confirm Force Logout",
        TitleIcon: LogoutIcon,
        footer: [
          {
            text: "Cancel",
            onClick: () => setLogoutOptionsModal(false),
            disabled: false,
            Icon: XIcon,
            className: "mondayButtonRed",
          },
        ],
        modalClassName: "force-logout-modal",
      }}
    >
      <div className="force-logout-modal-content">
        <div className="logout-modal-section">
          <span>
            Are you sure you want to force logout <b>{nameOfUser}</b>? This
            action <b>will immediately end the user's current session</b> and
            they will be required to log in again to continue.
          </span>
          <span
            onClick={() => {
              logoutUserSession(logoutOptionsModal);
            }}
            className="force-logout-text force"
          >
            Force Log Out <LogoutIcon />
          </span>
        </div>
        {!!logoutOptionsModal?.wsStatus && (
          <div className="logout-modal-section">
            <span>
              Alternatively, you can request the user to log out within a
              specified time. Set the desired time below and notify the user to
              log out.
            </span>
            <div className="ask-logout-form">
              <InputComponent
                {...{
                  type: "select",
                  placeholder: "Select time",
                  customOptions: [
                    {
                      label: "2 minutes",
                      value: 120 * 1000,
                    },
                    {
                      label: "5 minutes",
                      value: 5 * 60 * 1000,
                    },
                    {
                      label: "10 minutes",
                      value: 10 * 60 * 1000,
                    },
                  ],
                  label: "Log Out Within",
                  noFormItem: true,
                  onChange: (e) => {
                    setLogoutTime(e);
                  },
                  showSearch: false,
                }}
              />
              <span
                onClick={() => {
                  if (!logoutTime) {
                    message.warning("Please select a time to log out");
                    return;
                  }
                  askToLogout(logoutOptionsModal);
                }}
                className="force-logout-text ask"
              >
                Ask to Log Out
              </span>
            </div>
          </div>
        )}
      </div>
    </RedWarningModal>
  );
}

export default LogoutOptions;
