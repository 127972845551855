const getOptions = ({ data, onClick }) => {
  return {
    title: {
      text: "Reports Per Category",
    },
    subtitle: {
      text: "count",
    },
    data,
    axes: [
      {
        type: "category",
        position: "bottom",
        title: {
          text: "Categories",
        },
        tick: { interval: 1 },
        nice: true,
      },
      {
        type: "number",
        position: "left",
        title: {
          text: "Number of Reports",
        },
      },
    ],
    series: [
      {
        type: "bar",
        xKey: "category",
        yKey: "count",
        tooltip: {
          renderer: function ({ datum, xKey, yKey }) {
            return {
              content: `Number of Reports: ${datum[yKey].toFixed(0)}`,
              title: `Category: ${datum[xKey]}`,
            };
          },
        },
        listeners: {
          nodeClick: (params) => onClick?.({ category: params.datum.category }),
        },
      },
    ],
  };
};

export default getOptions;
