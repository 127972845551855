import { cloneDeep } from "lodash";
import {
  otherScopeServiceTaxMapper,
  otherScopeServiceTotalPriceMapper,
} from "../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/utils";
import { checkIfServiceIsHoist } from "../../Estimations/DataEntryGrid/models/Service";
import { forceToNumber } from "../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
export const servicePriceMapper = (service) => {
  let res = 0;
  let taxes = 0;
  if (service?.isScope === true) {
    res += otherScopeServiceTotalPriceMapper(service);
    taxes += otherScopeServiceTaxMapper(service);
  } else if (!checkIfServiceIsHoist(service)) {
    res += [
      ...service?.serviceOptions?.[0]?.flatMap?.(
        ({ items = [] }) =>
          items
            ?.filter?.(({ approved }) => approved === true)
            ?.map?.(totalPriceMapper) || [0]
      ),
      ...(service?.serviceAddons?.map?.(totalPriceMapper) || [0]),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
    taxes += [
      ...service?.serviceOptions?.[0]?.flatMap?.(
        ({ items }) =>
          items
            ?.filter?.(({ approved }) => approved === true)
            ?.map?.(totalTaxMapper) || [0]
      ),
      ...(service?.serviceAddons?.map?.(totalTaxMapper) || [0]),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
  } else if (checkIfServiceIsHoist(service)) {
    res += [
      ...service?.serviceOptions?.[0]?.map(hoistTotalPriceMapper),
      ...service?.serviceAddons.map(totalPriceMapper),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
    taxes += [
      ...service?.serviceOptions?.[0]?.map((el) => el?.taxAmount),
      ...service?.serviceAddons.map(totalTaxMapper),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
  }
  service?.serviceAddons?.forEach((el) => {
    taxes += forceToNumber(el?.includedTax?.taxAmount || 0);
  });
  console.log("servicePriceMapper", res, taxes);
  return {
    price: res,
    tax: taxes,
  };
};
export const totalPriceMapper = (el) =>
  !isNaN(+el?.totalPrice) ? +el?.totalPrice : 0;
export const totalTaxMapper = (el) =>
  !isNaN(+el?.taxAmount) ? +el?.taxAmount : 0;
export const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;

export const getAdditionalRent = (service) => {
  let additionalRent = 0;
  try {
    if (service?.additionalRentalTerms?.newPrice) {
      additionalRent = service?.additionalRentalTerms?.newPrice || 0;
    }
    return additionalRent;
  } catch (err) {
    console.log("getAdditionalRent", err);
    return additionalRent;
  }
};

export const otherScopeChain = (services, service) => {
  let temp = {
    scopeAmount: 0, // amount with tax
    scopeTax: 0, // tax amount
  };
  const chainedServices = services?.filter((el) => {
    return (
      el?.isScope === true &&
      Number(el?.chainedService?.serviceId) === Number(service?.serviceId)
    );
  });
  if (chainedServices?.length > 0) {
    chainedServices?.forEach((s) => {
      if (s?.chainedService?.isAutoSum === true)
        temp["scopeAmount"] += otherScopeServiceTotalPriceMapper(s);
      temp["scopeTax"] += otherScopeServiceTaxMapper(s);
    });
  }
  return temp;
};

export const calculateTotalitiesTakeOff = (takeOffTableData) => {
  try {
    if (Array.isArray(takeOffTableData)) {
      const TO_RETURN = structuredClone(takeOffTableData);

      TO_RETURN.forEach((service) => {
        let TOTALITIES = {
          servicePrice: servicePriceMapper(service),
          totalPrice: 0,
          includedTaxes: 0,
          includedScope: otherScopeChain(takeOffTableData, service),
          additionalRent: getAdditionalRent(service),
        };

        TOTALITIES["includedTaxes"] =
          TOTALITIES["servicePrice"]["tax"] +
          TOTALITIES["includedScope"]["scopeTax"];
        TOTALITIES["totalPrice"] =
          TOTALITIES["servicePrice"]["price"] +
          // TOTALITIES["includedTaxes"] +
          TOTALITIES["additionalRent"] +
          TOTALITIES["includedScope"]["scopeAmount"];
        // + TOTALITIES["includedScope"]["scopeTax"];

        service["totalities"] = TOTALITIES;
      });

      return TO_RETURN;
    }
  } catch (err) {
    console.log("calculateTotalitiesTakeOff", err);
    return takeOffTableData;
  }
};

export const takeOffsPrices = (data) => {
  let gridData = Array.isArray(data) ? cloneDeep(data) : [];
  const RESULT = {
    TOTAL: 0,
    TAXES: 0,
  };
  RESULT.TOTAL =
    gridData?.reduce((a, b) => {
      return a + (b?.additionalRentalTerms?.newPrice || 0);
    }, 0) || 0;

  try {
    if (Array.isArray(gridData)) {
      let temp =
        gridData
          ?.flatMap((service) => {
            const isHoist = checkIfServiceIsHoist(service);

            const {
              serviceOptions = [],
              serviceAddons = [],
              label = "",
            } = service;
            if (service?.isScope) {
              return [
                ...(serviceOptions?.[0]?.flatMap?.(
                  ({ items = [] }) =>
                    items?.flatMap((el) => {
                      if (!!el?.price || !!el?.Price) {
                        let taxRate = service?.isTaxable
                          ? service?.taxRate || 0
                          : 0;
                        const res = Number(+el?.price || +el?.Price || 0);
                        const tax = Number(
                          Number(+el?.price || +el?.Price || 0) * taxRate
                        );
                        // console.log("this is res", res);
                        // Number(+el?.price || +el?.Price || 0) *
                        // (1 + taxRate / 100);
                        return Number(res + tax);
                      }
                    }) || 0
                ) ?? 0),
                ...(serviceAddons?.map(({ totalPrice = 0 }) => +totalPrice) ??
                  0),
              ];
            } else if (!isHoist) {
              return [
                ...(serviceOptions?.[0]?.flatMap?.(
                  ({ items = [] }) =>
                    items
                      ?.filter(({ approved = false }) => approved === true)
                      ?.flatMap(({ totalPrice = 0 }) => +totalPrice) || 0
                ) ?? 0),
                ...(serviceAddons?.map(({ totalPrice = 0 }) => +totalPrice) ??
                  0),
              ];
            } else {
              // console.log("this is hoist", service);
              return [
                ...(serviceOptions?.[0]?.flatMap?.(
                  ({ totalPrice = 0 }) => +totalPrice
                ) || 0),
                ...(serviceAddons?.map(({ price = 0 }) => +price) || 0),
              ];
            }
          })
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0) ?? 0;
      let tempTax =
        gridData
          ?.flatMap((service) => {
            let isHoist = checkIfServiceIsHoist(service);
            const {
              serviceOptions = [],
              serviceAddons = [],
              label = "",
            } = service;
            if (service?.isScope) {
              return [
                ...(serviceOptions?.[0]?.flatMap?.(
                  ({ items = [] }) =>
                    items?.flatMap((el) => {
                      if (!!el?.price || !!el?.Price) {
                        let taxRate = service?.isTaxable
                          ? service?.taxRate || 0
                          : 0;
                        const res =
                          (Number(+el?.price || +el?.Price || 0) * taxRate) /
                          100;
                        return res;
                      }
                    }) || 0
                ) ?? 0),
                // ,
                // ...(serviceAddons?.map(({ totalPrice = 0 }) => +totalPrice) ??
                //   0),
              ];
            } else if (!isHoist) {
              return [
                ...(serviceOptions?.[0]?.flatMap?.(
                  ({ items = [] }) =>
                    items
                      ?.filter(({ approved = false }) => approved === true)
                      ?.flatMap(({ taxAmount = 0 }) => +taxAmount) || 0
                ) ?? 0),
                ...(serviceAddons?.map(({ taxAmount = 0 }) => +taxAmount) ?? 0),
              ];
            } else {
              console.log("this is hoist", service);
              return [
                ...(serviceOptions?.[0]?.flatMap?.(
                  ({ taxAmount = 0 }) => +taxAmount
                ) || 0),
                ...(serviceAddons?.map(({ taxAmount = 0 }) => +taxAmount) || 0),
              ];
            }
          })
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0) ?? 0;
      // console.log("take off tax", tempTax);
      if (!isNaN(temp)) {
        RESULT.TOTAL += temp;
      }
      if (!isNaN(tempTax)) {
        RESULT.TAXES += tempTax;
      }
      // console.log("MY RESULT", RESULT);
      return RESULT;
    } else return RESULT;
  } catch (error) {
    console.log("error", error);
    return RESULT;
  }
};
