import { Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRedux } from "../../../../../SidebarPages/hooks";
import { compareAndHighlight } from "./compareProposalUtil";
import "./DifferencesFinder.scss";

const parseHtml = (html) => {
  const parser = new DOMParser();
  return parser.parseFromString(html, "text/html").body;
};
function DifferencesFinder({ open, setOpen }) {
  const [content, setContent] = useState({
    leftProposal: {
      html: "",
      title: "Actual Approved Proposal",
    },
    rightProposal: {
      html: "",
      title: "Actual Approved Take Off",
    },
    differences: [],
  });
  const [proposalData, setProposalData] = useRedux("proposalData");
  const oldHtml = proposalData?.savedContent;
  const newHtml = `<div>
        <p>Client Information:</p>
        <p>Brookstone Developers</p>
        <p>Joel Kahan</p>
        <p>Project Address</p>
        <p>1481 McDonald Avenue</p>
        <p>Brooklyn, NY 11230</p>
        <p>Rep OF</p>
        <p>NOTE: Customer hereby agrees to name Core Scaffold Systems Inc. as an additional insured on a primary, non-contributory basis.</p>
        <p>&nbsp;test edit<br></p>
        <p>&nbsp;</p>
        <p><span style="color: #000000; font-family: sans-serif; font-size: 13.4745px; font-variant-ligatures: common-ligatures; text-align: center; white-space: pre;">We will install Fire Retardant Roof Protection: 2" Rigid Insulation, 2x10 Plank, 3/4" Plywood. <br></span><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false">
            <div contenteditable="false">
                <p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: #000; color: #fff;">Roof Protection</span></p>
                <p style="padding-left: 80px;"><strong><u>─ Along Left-Side Façade (North) (1477 McDonald Avenue) </u></strong></p>
                <p style="padding-left: 120px;"><strong> ─ </strong> 45′L x 40′W 1 story building </p>
                <p style="padding-left: 120px;"><strong> ─ </strong> 2361′L x 40′W 6 story building <span>+ Bulkheads</span> </p>
            </div>
        </div>
        <p></p>
        <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false">
            <ul style="padding-left: 40px;"><span>EXCLUDES:</span>
                <li>
                    <p>DOB Permit Renewal for Roof Protection. Permit Renewal Fee will be $450.</p>
                    <p>-Protection of perimeter walls of the neighboring roof</p>
                    <p>-A.C. Units</p>
                    <p>-Core will not be responsible for obtaining neighbor agreements and/or notifications and/or permissions for the</p>
                    <p>5' and/or 20' extensions. The owner/contractor, not Core, must obtain all agreements prior to us starting the</p>
                    <p>work. If work has begun and the neighbors cause our work to stop, there will be charges for lost time</p>
                </li>
            </ul>
        </div>
        <p></p>
        <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false">
            <ul style="padding-left: 40px;"><span>INCLUDES:</span>
                <li>
                    <p>-DOB Permit and DOB Approved Engineered Drawings. </p>
                    <p>-A.C. Units &amp; Skylights</p>
                </li>
            </ul>
        </div>
        <p></p>
        <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false"><span>RENTAL: </span>
            <p></p>
            <p>*NO CREDIT FOR UNUSED RENT*</p>
            <p>Rental begins one hundred and four (104) weeks after initial installation of progress work completed. Rental</p>
            <p>charge as follows: $3,500.00 per each 4 Week period ($875.00 Weekly) plus applicable sales tax. Billing to be</p>
            <p>done on a 4-week period. Pro-Rated weekly per progress installation and/or progress removal.</p>
            <p></p>
        </div>
        <p></p>
        <p>&nbsp;<br></p>
        <p></p>
    </div>`;
  const findDifferences = () => {
    const oldDom = parseHtml(proposalData?.savedContent);
    const newDom = parseHtml(`<div>
        <p>Client Information:</p>
        <p>Brookstone Developers</p>
        <p>Joel Kahan</p>
        <p>Project Address</p>
        <p>1481 McDonald Avenue</p>
        <p>Brooklyn, NY 11230</p>
        <p>Rep OF</p>
        <p>NOTE: Customer hereby agrees to name Core Scaffold Systems Inc. as an additional insured on a primary, non-contributory basis.</p>
        <p>&nbsp;test edit<br></p>
        <p>&nbsp;</p>
        <p><span style="color: #000000; font-family: sans-serif; font-size: 13.4745px; font-variant-ligatures: common-ligatures; text-align: center; white-space: pre;">We will install Fire Retardant Roof Protection: 2" Rigid Insulation, 2x10 Plank, 3/4" Plywood. <br></span><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false">
            <div contenteditable="false">
                <p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: #000; color: #fff;">Roof Protection</span></p>
                <p style="padding-left: 80px;"><strong><u>─ Along Left-Side Façade (North) (1477 McDonald Avenue) </u></strong></p>
                <p style="padding-left: 120px;"><strong> ─ </strong> 45′L x 40′W 1 story building </p>
                <p style="padding-left: 120px;"><strong> ─ </strong> 2361′L x 40′W 6 story building <span>+ Bulkheads</span> </p>
            </div>
        </div>
        <p></p>
        <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false">
            <ul style="padding-left: 40px;"><span>EXCLUDES:</span>
                <li>
                    <p>DOB Permit Renewal for Roof Protection. Permit Renewal Fee will be $450.</p>
                    <p>-Protection of perimeter walls of the neighboring roof</p>
                    <p>-A.C. Units</p>
                    <p>-Core will not be responsible for obtaining neighbor agreements and/or notifications and/or permissions for the</p>
                    <p>5' and/or 20' extensions. The owner/contractor, not Core, must obtain all agreements prior to us starting the</p>
                    <p>work. If work has begun and the neighbors cause our work to stop, there will be charges for lost time</p>
                </li>
            </ul>
        </div>
        <p></p>
        <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false">
            <ul style="padding-left: 40px;"><span>INCLUDES:</span>
                <li>
                    <p>-DOB Permit and DOB Approved Engineered Drawings. </p>
                    <p>-A.C. Units &amp; Skylights</p>
                </li>
            </ul>
        </div>
        <p></p>
        <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
        <div contenteditable="false"><span>RENTAL: </span>
            <p></p>
            <p>*NO CREDIT FOR UNUSED RENT*</p>
            <p>Rental begins one hundred and four (104) weeks after initial installation of progress work completed. Rental</p>
            <p>charge as follows: $3,500.00 per each 4 Week period ($875.00 Weekly) plus applicable sales tax. Billing to be</p>
            <p>done on a 4-week period. Pro-Rated weekly per progress installation and/or progress removal.</p>
            <p></p>
        </div>
        <p></p>
        <p>&nbsp;<br></p>
        <p></p>
    </div>`);
    const diffs = compareAndHighlight(oldDom, newDom);
    let newContent = structuredClone(content);
    newContent.differences = diffs;
    setContent(newContent);

    //     console.log("findDifferences", { proposalData });
    //     const leftProposal = proposalData?.savedContent;
    //     let newContent = structuredClone(content);
    //     newContent.leftProposal.html = leftProposal;
    //     const compared = compareAndHighlight(
    //       leftProposal,
    //       `<div>
    //     <p>Client Information:</p>
    //     <p>Brookstone Developers</p>
    //     <p>Joel Kahan</p>
    //     <p>Project Address</p>
    //     <p>1481 McDonald Avenue</p>
    //     <p>Brooklyn, NY 11230</p>
    //     <p>Rep OF</p>
    //     <p>NOTE: Customer hereby agrees to name Core Scaffold Systems Inc. as an additional insured on a primary, non-contributory basis.</p>
    //     <p>&nbsp;test edit<br></p>
    //     <p>&nbsp;</p>
    //     <p><span style="color: #000000; font-family: sans-serif; font-size: 13.4745px; font-variant-ligatures: common-ligatures; text-align: center; white-space: pre;">We will install Fire Retardant Roof Protection: 2" Rigid Insulation, 2x10 Plank, 3/4" Plywood. <br></span><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
    //     <div contenteditable="false">
    //         <div contenteditable="false">
    //             <p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: #000; color: #fff;">Roof Protection</span></p>
    //             <p style="padding-left: 80px;"><strong><u>─ Along Left-Side Façade (North) (1477 McDonald Avenue) </u></strong></p>
    //             <p style="padding-left: 120px;"><strong> ─ </strong> 45′L x 40′W 1 story building </p>
    //             <p style="padding-left: 120px;"><strong> ─ </strong> 2361′L x 40′W 6 story building <span>+ Bulkheads</span> </p>
    //         </div>
    //     </div>
    //     <p></p>
    //     <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
    //     <div contenteditable="false">
    //         <ul style="padding-left: 40px;"><span>EXCLUDES:</span>
    //             <li>
    //                 <p>DOB Permit Renewal for Roof Protection. Permit Renewal Fee will be $450.</p>
    //                 <p>-Protection of perimeter walls of the neighboring roof</p>
    //                 <p>-A.C. Units</p>
    //                 <p>-Core will not be responsible for obtaining neighbor agreements and/or notifications and/or permissions for the</p>
    //                 <p>5' and/or 20' extensions. The owner/contractor, not Core, must obtain all agreements prior to us starting the</p>
    //                 <p>work. If work has begun and the neighbors cause our work to stop, there will be charges for lost time</p>
    //             </li>
    //         </ul>
    //     </div>
    //     <p></p>
    //     <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
    //     <div contenteditable="false">
    //         <ul style="padding-left: 40px;"><span>INCLUDES:</span>
    //             <li>
    //                 <p>-DOB Permit and DOB Approved Engineered Drawings. </p>
    //                 <p>-A.C. Units &amp; Skylights</p>
    //             </li>
    //         </ul>
    //     </div>
    //     <p></p>
    //     <p><span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;"></span></p>
    //     <div contenteditable="false"><span>RENTAL: </span>
    //         <p></p>
    //         <p>*NO CREDIT FOR UNUSED RENT*</p>
    //         <p>Rental begins one hundred and four (104) weeks after initial installation of progress work completed. Rental</p>
    //         <p>charge as follows: $3,500.00 per each 4 Week period ($875.00 Weekly) plus applicable sales tax. Billing to be</p>
    //         <p>done on a 4-week period. Pro-Rated weekly per progress installation and/or progress removal.</p>
    //         <p></p>
    //     </div>
    //     <p></p>
    //     <p>&nbsp;<br></p>
    //     <p></p>
    // </div>`
    //     );
    //     newContent.rightProposal.html = compared;
    //     setContent(newContent);
  };
  const highlightElement = (elem, type) => {
    switch (type) {
      case "NODE_NAME":
        return `<span class="highlight-node">${elem.outerHTML}</span>`;
      case "TEXT_CONTENT":
        return `<span class="highlight-text">${elem.outerHTML}</span>`;
      case "ATTRIBUTE":
        return `<span class="highlight-attribute">${elem.outerHTML}</span>`;
      default:
        return elem.outerHTML;
    }
  };
  const getHighlightedHtml = (html, differences) => {
    let highlightedHtml = html;
    differences.forEach((diff) => {
      const replacement = highlightElement(diff.elem1, diff.type);
      highlightedHtml = highlightedHtml.replace(
        diff.elem1.outerHTML,
        replacement
      );
    });
    return highlightedHtml;
  };
  const oldHighlighted = getHighlightedHtml(oldHtml, content.differences);
  const newHighlighted = getHighlightedHtml(newHtml, content.differences);

  useEffect(() => {
    if (open) {
      findDifferences();
      // fetch data
    }
  }, [open]);

  return (
    <div>
      {" "}
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width="100%"
        className="differences_modal_proposal"
      >
        <div className="a4-container">
          <div className="a4-page left">
            {/* <h1>{content?.leftProposal?.title}</h1> */}
            <iframe
              srcDoc={oldHighlighted}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            {/* <p>Content for the left A4 page goes here.</p> */}
          </div>
          <div className="a4-page right">
            {/* <h1>{content?.rightProposal?.title}</h1> */}
            <iframe
              srcDoc={newHighlighted}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            {/* <p>Content for the right A4 page goes here.</p> */}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DifferencesFinder;
// Basic styling for highlighting
const styles = `
  .highlight-node { background-color: #ffdddd; }
  .highlight-text { background-color: #ddffdd; }
  .highlight-attribute { background-color: #ddddff; }
  .html-container { display: flex; justify-content: space-between; }
  .html-container h3 { width: 100%; }
`;

// Add styles to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
