import React, { Component } from "react";
import { API } from "aws-amplify";
import DataEntryGrid from "../../../../SidebarPages/Estimations/DataEntryGrid/dataEntryGrid";
import { Button, Modal, Popconfirm, message, notification, Switch } from "antd";
import "../../../../SidebarPages/Estimations/DataEntryGrid/dataEntryGrid.scss";
import ClassRedux from "../../../../SidebarPages/utils/classRedux";
import { updateDocumentTitle } from "../../../../../utils";
import { RateReviewIcon } from "../../../../../icons";

export class ModalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.value,
      id: "",
      agGridTheme: "ag-theme-balham-dark",
    };
  }

  setTheme = (agGridTheme) => {
    this.setState({ agGridTheme });
  };

  showModal = () => {
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    this.setState({
      visible: true,
    });
  };

  handleWindowBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  handleCancel = (e) => {
    if (e.keyCode === 27) {
      e.preventDefault();
    } else {
      if (
        window.confirm(
          "Are you sure you want to exit data-entry mode? Changes you made may not be saved."
        )
      ) {
        window.removeEventListener(
          "beforeunload",
          this.handleWindowBeforeUnload
        );
        updateDocumentTitle(); // reset document title to "Lead Manager";
        this.setState({ visible: false });
        this.props.refresh();
      }
    }
  };

  async componentDidMount() {
    let id = this.props.data.estimationId;
    this.setState({ id: id });
    let estimationAddress = this.props.data.jobSiteAddress;
    this.setState({ jobSiteAddress: estimationAddress });
  }

  confirm = () => {
    this.setState({ writable: !this.state.writable });
    if (this.state.writable) {
      message.info("Autosave disabled.");
    } else {
      message.info("Autosave enabled.");
      if (this.state.estSTATUS === "Approved") {
        notification.open({
          message: "Already approved",
          description:
            'This estimation is already approved. Any updates will result in its status to be changed to "Changed Order".',
          duration: 0,
        });
      }
    }
  };

  getEstimation() {
    return API.get("estimations", `/estimations/${this.state.id}`);
  }

  saveEstimation(estimations) {
    return API.put("estimations", `/estimations/${this.state.id}`, {
      body: estimations,
    });
  }

  handleSave = (services) => {
    return new Promise((resolve, reject) => {
      this.getEstimation().then(async (response) => {
        await this.saveEstimation({ ...response, services: services })
          .then(() => {
            // this.props.refresh();
            this.setState({ visible: true });
            resolve();
          })
          .catch(() => {
            console.error("somethings went wrong");
            reject();
          });
      });
    });
  };

  render() {
    const { visible } = this.state;

    return (
      <>
        <ClassRedux
          {...{
            varName: "companyName",
            stateName: "companyDetails",
            setState: (companyName) => {},
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "baseline",
          }}
        >
          <h4 style={{ fontWeight: 500 }}> {this.state.address}</h4>{" "}
          <Button style={{ marginLeft: 20 }} onClick={this.showModal}>
            {" "}
            <RateReviewIcon />
          </Button>
        </div>
        <Modal
          className={"dataEntryModal " + this.state.agGridTheme + ""}
          open={visible}
          title={
            <>
              Data-entry
              <Popconfirm
                key={"switcher"}
                placement="bottomLeft"
                title={`Are you sure you want to ${
                  this.state.writable ? "disable" : "enable"
                } write mode?\nThis will also ${
                  this.state.writable ? "disable" : "enable"
                } autosave.`}
                onConfirm={this.confirm}
                okText="Yes"
                cancelText="No"
              >
                <Switch
                  style={{ marginLeft: 15 }}
                  checked={this.state.writable}
                  checkedChildren="Write enabled"
                  unCheckedChildren="Read-only mode"
                />
              </Popconfirm>
            </>
          }
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "Data-entry" });
          }}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div className="dataEntryBody">
            <DataEntryGrid
              key={this.state.degKey}
              projectAddress={this.state.jobSiteAddress}
              estimationId={this.state.id}
              isWritable={this.state.writable}
              modifyServices={this.handleSave}
              setTheme={this.setTheme}
              agGridTheme={this.state.agGridTheme}
              pTOPList={this.state.arrDoc}
              isSelected={this.state.isSelected}
              accountName={this.state.accountName}
              estimationStatus={this.state.estSTATUS}
            />
          </div>
        </Modal>
      </>
    );
  }
}
