import { InputNumber } from "antd";
import "./Number.scss";
const FormItemNumber = ({
  value,
  form,
  name,
  disabled,
  onChange: changeHandler = () => {},
}) => {
  return (
    <div className="EditInput">
      <InputNumber
        className="EditInputComponent"
        disabled={disabled}
        min={0}
        defaultValue={parseInt(value)}
        onChange={(e) => {
          changeHandler();
          form.setFieldsValue({ [name]: e });
        }}
        onStep={(e) => {
          changeHandler();
          form.setFieldsValue({ [name]: e });
        }}
      />
    </div>
  );
};
export default FormItemNumber;
