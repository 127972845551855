import { Modal } from "antd";
import React, { useEffect, useState } from "react";

import { DobWhite } from "../../../../../../icons";
import CustomCheckBox from "../../../../../commonComponents/CustomCheckBox/CustomCheckBox";
import InfoMessage from "../../../../../commonComponents/InfoMessage/InfoMessage";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";

import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";

import "./MultipleBonModal.scss";

const Footer = ({ getFilters, bins, setOpen }) => {
  const onClick = () => {
    getFilters({ bin: bins });
    setOpen(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <MondayButton onClick={onClick} Icon={<TickIcon />}>
        Submit
      </MondayButton>
    </div>
  );
};

const MultipleBinModal = ({
  open,
  setOpen,
  getFilters,
  binNumbers,
  selectedCategory,
  selectedBins,
  setSelectedBins,
}) => {
  const [bins, setBins] = useState(selectedBins);

  const onBinSelection = (newBin) => {
    if (bins.some((bin) => bin === newBin)) {
      setBins((prev) => prev.filter((bin) => bin !== newBin));
      setSelectedBins((prev) => prev.filter((bin) => bin !== newBin));
      return;
    }

    const newBins = [...bins, newBin];
    setBins(newBins);
    setSelectedBins(newBins);
  };

  useEffect(() => {
    if (selectedBins) getFilters({ bin: selectedBins });
  }, [selectedCategory]);

  const toggleMenuOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        onClick={toggleMenuOpen}
        Icon={<DobWhite />}
        data-testid="bin-monday-button"
      >
        BIN
      </MondayButton>

      {open && (
        <Modal
          open={open}
          title="Select Bin Numbers"
          closable={false}
          className="multiple-bin-modal-container"
          footer={<Footer {...{ getFilters, bins, setOpen }} />}
        >
          <InfoMessage
            text={
              "Multiple BIN numbers detected. You can select one or more BINs for which you'd like to display data."
            }
          />
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {binNumbers.map((number) => (
              <CustomCheckBox
                key={number.bin}
                name={number.bin}
                checked={bins.includes(number.bin)}
                onChange={() => onBinSelection(number.bin)}
              />
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default MultipleBinModal;
