import { getCognitosForNotification } from "./notificationHelpers";
import { updateTeamsConfiguration } from "./updateTeamsConfiguration";
import broadcastNotification from "../helpers/controllers/broadcastNotification";

export const sendNotification = async ({
  id,
  action,
  team = [],
  recordId,
  otherKeys = {},
  authenticatedUser,
  userConfiguration,
}) => {
  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const teamsConfiguration = updateTeamsConfiguration(
    userConfiguration,
    team,
    [],
    true
  );

  try {
    return await broadcastNotification(
      id,
      action,
      [
        {
          common: user,
          ...otherKeys,
        },
        {
          userName: user,
          currentUser: authenticatedUser?.sub,
          cognitos: getCognitosForNotification(
            userConfiguration,
            teamsConfiguration
          ),
        },
      ],
      recordId
    );
  } catch (error) {
    console.log({ error });
  }
};
