const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_WRITABLE":
      return {
        ...state,
        isWritable: payload,
      };
    case "SET_AUTOSAVING":
      return {
        ...state,
        isAutoSaving: payload,
      };
    case "SET_FAKE_APPLICATION":
      return {
        ...state,
        fakeApplication: payload,
      };
    case "ATTACH_RENTALS":
      return {
        ...state,
        fakeApplication: {
          ...(state?.fakeApplication || {}),
          chainedRentalsIds: payload,
        },
      };
    case "SET_UNCHANGING_FAKE_APPLICATION":
      return {
        ...state,
        unchangingFakeApplication: payload,
      };

    case "SET_DARK_MODE":
      localStorage.setItem("themePreference", payload ? "dark" : "light");
      return {
        ...state,
        darkMode: payload,
      };

    case "SET_SELECTED_RENTALS":
      return {
        ...state,
        selectedRentals: payload,
      };

    case "SET_NEXT_STEP":
      return {
        ...state,
        isNextStep: payload.isNextStep,
        appId: payload.appId,
      };

    default:
      return state;
  }
};

export default reducer;
