import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { Modal, Radio, message } from "antd";
import { useRef, useState, useMemo, useContext, useEffect } from "react";

import {
  PRINT_PAGE_CONFIGS,
  BASE_REPORT_STYLES,
} from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { MondayButton } from "../../../../../../commonComponents";
import AnalyticsChartsContext from "../../AnalyticsChartsContext";
import { PrintIcon } from "../../../../../../SidebarPages/BasePage/src";
import { compareIncluding } from "../../../../../../SidebarPages/utils";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { generateHTMLTemplate } from "../../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/utils";
import { ReportCheck } from "../../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

import "./ChartsPdfExport.scss";

function ChartsPdfExport({ open, onClose }) {
  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode, layout } = useContext(AnalyticsChartsContext);

  const [sizeConfig, setSizeConfig] = useState({});
  const [reportsDataSections, setReportsDataSections] = useState([]);

  /**
   * @type {React.MutableRefObject<HTMLIFrameElement>}
   */
  const previewRef = useRef(null);
  const inputRef = useRef(null);

  const baseConfig = useMemo(() => {
    return [
      {
        image: {
          src: base64?.find(({ fileName }) =>
            compareIncluding(fileName, "Core Logo Black")
          )?.base64,
          class: "baseLogo",
        },
        class: "headerSection",
      },
    ];
  }, [base64]);

  const reportStyles = useMemo(() => {
    return {
      ...BASE_REPORT_STYLES,
      chart: {
        width: "100%",
        "aspect-ratio": "1.5",
      },
      table: {
        width: "100%",
        "font-size": `calc(95% * ${sizeConfig?.aspectRatio})`,
      },
    };
  }, [sizeConfig]);

  function changeSizeHandler(changeEvent) {
    let key = changeEvent?.target?.value;

    let config = {};
    for (const size in PRINT_PAGE_CONFIGS) {
      if (size === key) {
        config = { ...PRINT_PAGE_CONFIGS[size] };
      }
    }

    setSizeConfig(config);
  }

  /**
   * Function that draws a collection of charts and adds them to the report
   */
  function addChartHandler(chartData) {
    let images = [];

    const data = chartData.chartData;

    let newDataSets = [];
    for (const dataset of data.datasets) {
      const sum = _.sum(dataset.data);
      let newLabels = [];
      for (let i = 0; i < dataset?.data?.length; i++) {
        const d = dataset?.data?.[i];
        const percentage = Math.round((d / sum) * 100);
        const label = `${dataset?.labels?.[i]} (${percentage}%)`;
        newLabels.push(label);
      }
      newDataSets.push({ ...dataset, labels: newLabels });
    }

    let container = document.createElement("div");
    let chartCanvas = document.createElement("canvas");
    container.appendChild(chartCanvas);

    container.style.position = "absolute";
    container.style.top = `-${sizeConfig?.width}`;
    container.style.left = `-${sizeConfig?.width}`;
    container.style.height = `${sizeConfig?.width}`;
    container.style.width = sizeConfig?.height;
    container.style.visibility = "hidden";
    chartCanvas.style.width = container.clientWidth;
    chartCanvas.style.height = "100mm";
    document.body.appendChild(container);

    let chart = new Chart(chartCanvas, {
      type:
        data.chartType === "Vertical" || data?.chartType === "Horizontal"
          ? "bar"
          : data?.chartType.toLowerCase(),
      data:
        data.chartType === "Pie" || data?.chartType === "Doughnut"
          ? { ...data, datasets: newDataSets, labels: newDataSets[0].labels }
          : data,
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxis: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position:
              data?.chartType === "Pie" || data?.chartType === "Doughnut"
                ? "bottom"
                : "top",
          },
        },
        clip: { top: 0, left: false, right: false, bottom: false },
        aspectRatio: 0,
        animation: {
          duration: 0,
        },
      },
    });

    let image = chart.toBase64Image();
    images.push({
      image: {
        src: image,
        class: "chart",
      },
      class: `chart ${data?.datasets?.[0]?.label}`,
    });

    chart.destroy();
    document.body.removeChild(container);

    // setConfigurationOptions("BLANK");
    setReportsDataSections((prev) => {
      let t = [...prev];
      t = t.concat(images);
      return t;
    });
  }

  function removeElementHandler(label) {
    let t = [...reportsDataSections];
    let index = t.findIndex((el) => el.class === label);

    if (index > -1) {
      t.splice(index, 1);
      setReportsDataSections(t);
    }
  }

  function onPdfExport() {
    if (previewRef.current) {
      let frame = previewRef.current;

      let w = frame.contentWindow;
      if (w) {
        const inputValue = inputRef?.current?.input?.value;
        if (!!inputValue) {
          const docTitle = document.title;
          document.title = inputValue;
          w?.print?.();
          document.title = docTitle;
        } else {
          message.warning("Please add a title for this Document.");
          inputRef.current.focus();
        }
      }
    }
  }

  useEffect(() => {
    setReportsDataSections([...baseConfig]);
    changeSizeHandler({ target: { value: "Letter" } });
  }, []);

  useEffect(() => {
    if (previewRef.current) {
      let frame = previewRef.current;
      let w = frame.contentWindow;
      if (w) {
        let html = w.document.querySelector("html");
        if (html) {
          html.innerHTML = generateHTMLTemplate({
            title: "Report",
            dataSections: reportsDataSections,
            styles: reportStyles,
          });
        }
      }
    }
  }, [reportsDataSections, reportStyles]);

  return (
    <Modal
      open={open}
      centered={true}
      closable={true}
      onCancel={onClose}
      className={`charts-pdf-export ${isDarkMode && "charts-pdf-export-dark"}`}
      closeIcon={<XIcon />}
      destroyOnClose={true}
      title="Charts Pdf Export"
      data-testid="charts-pdf-export-modal"
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={onClose}
          key={"close-btn"}
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>,
        <MondayButton
          className="mondayButtonBlue"
          onClick={onPdfExport}
          disabled={reportsDataSections?.length < 2}
          data-testid="print-pdf-btn"
          key={"print-btn"}
          Icon={<PrintIcon />}
        >
          Print
        </MondayButton>,
      ]}
    >
      <div className="pdf-selection">
        <section className="charts-selection">
          <section className="choose-size-section">
            <span className="preview-label">Preview for:</span>
            <Radio.Group
              data-testid="radio-buttons"
              buttonStyle="solid"
              defaultValue={"Letter"}
              onChange={changeSizeHandler}
            >
              {Object.keys(PRINT_PAGE_CONFIGS).map((e) => (
                <Radio.Button key={e} value={e}>
                  {e}
                </Radio.Button>
              ))}
            </Radio.Group>
            <InputComponent
              inputRef={inputRef}
              placeholder="Document title..."
              dataTestid={"document-title-input"}
            />
          </section>
          {layout.map((chart) => {
            return (
              <ReportCheck
                {...{
                  id: chart.title,
                  key: chart.title,
                  label: chart.title,
                  onChange: (e) => {
                    if (e) {
                      addChartHandler(chart);
                    } else {
                      removeElementHandler(
                        `chart ${chart?.chartData?.datasets?.[0]?.label}`
                      );
                    }
                  },
                }}
              />
            );
          })}
        </section>
        <section
          className="preview-section"
          style={{ aspectRatio: sizeConfig?.aspectRatio }}
        >
          <iframe
            ref={previewRef}
            className="preview-frame"
            name="preview-frame"
            sandbox="allow-modals allow-popups allow-same-origin allow-popups-to-escape-sandbox"
            style={{ backgroundColor: "#fff" }}
          />
        </section>
      </div>
    </Modal>
  );
}

export default ChartsPdfExport;
