import { uniq } from "lodash";

/**
 * Retrieves project address information based on the provided parameters.
 *
 * @param {string} val - The value to match against project or opportunity IDs.
 * @param {Array} projects - The array of projects to search in.
 * @param {Array} opportunities - The array of opportunities to search in.
 * @param {string} category - The category to determine the search scope ("projects" or "opportunities").
 * @returns {Object} - The project address information.
 */
function projectAddress({ val, projects, opportunities, category }) {
  const found =
    category === "opportunities"
      ? (opportunities || [])?.find(
          ({ opportunityId }) => opportunityId === val
        )
      : (projects || [])?.find(({ projectId }) => projectId === val);

  const proposedTypeOfWork =
    category === "projects"
      ? uniq(
          Object.values(found?.services)
            .flat()
            .map(({ serviceId }) => String(serviceId))
        )
      : found?.proposedTypeOfWork || [];

  return {
    projectExecutive: found?.projectExecutive || "",
    proposedTypeOfWork,
    binNumber: found?.binNumber || "",
    category: category || "",
    foundProject: found,
    projectManager: found?.projectManager?.map((e) => e?.nameOfUser) || "",
    accountId: found?.accountId || "",
    opportunityId: found?.opportunityId || "",
    projectId: found?.projectId || "",
    teamsConfiguration: found?.teamsConfiguration,
    leadId: category !== "projects" ? found?.leadId || "" : "",
  };
}

export default projectAddress;
