import React, { useRef, useEffect, useState } from "react";
import { MondayButton } from "../../../../../../../../commonComponents";
import { Dropdown, Slider } from "antd";
import useTranslate from "../../../../../../../../pages/ScheduleProgress/Language/useTranslate";
import {
  DrawIcon,
  RectIcon,
  UndoIcon,
} from "../../../../../../../../pages/ScheduleProgress/assets/images";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../../../Communication/assets";
import "./Canvas.scss";

const Canvas = ({
  initialImageSrc,
  width,
  height,
  markerColor,
  onChange,
  onSave,
  thickness: proppedThickness = 2,
}) => {
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [canvasLoaded, setCanvasLoaded] = useState(false);
  const [paths, setPaths] = useState([]);
  const [color, setColor] = useState(markerColor);
  const [thickness, setThickness] = useState(proppedThickness);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [currPosition, setCurrPosition] = useState({ x: 0, y: 0 });
  const [drawRect, setDrawRect] = useState(false);
  const [drawFakeRect, setDrawFakeRect] = useState(false);
  const { t } = useTranslate();

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      setCanvasLoaded(true);
    };
    img.onerror = () => {
      // Retry loading the image if it fails
      img.src = initialImageSrc;
    };
    img.src = initialImageSrc;
  }, [initialImageSrc]);

  const startDrawing = (x, y) => {
    if (!canvasLoaded) return;
    setDrawing(true);
    setPaths([
      ...paths,
      { color: color, points: [{ x, y }], thickness: thickness },
    ]);
  };

  const draw = (x, y) => {
    if (!drawing || !canvasLoaded) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.strokeStyle = color;
    context.lineWidth = thickness;
    context.lineCap = "round";

    const updatedPaths = [...paths];
    updatedPaths[updatedPaths.length - 1].points.push({ x, y });
    setPaths(updatedPaths);
    redrawCanvas(updatedPaths);
  };

  const finishDrawing = () => {
    if (!drawing || !canvasLoaded) return;
    setDrawing(false);
    if (onChange) {
      onChange();
    }
  };

  const onDrawSave = () => {
    setDrawing(false);
    setPaths([]);
    onSave(canvasRef.current.toDataURL());
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(
      document.getElementById("initial-image"),
      0,
      0,
      canvas.width,
      canvas.height
    );
    setPaths([]);
    if (onChange) {
      onChange(canvas.toDataURL());
    }
  };

  const undo = () => {
    if (paths.length > 0) {
      const filteredPaths = paths.slice(0, -1);
      setPaths(filteredPaths);
      redrawCanvas(filteredPaths);
    }
  };

  const redrawCanvas = (paths = []) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(
      document.getElementById("initial-image"),
      0,
      0,
      canvas.width,
      canvas.height
    );
    paths.forEach((path) => {
      if (path?.type && path?.type === "rectangle") {
        const { color, points, thickness } = path;
        context.strokeStyle = color;
        context.lineWidth = thickness;
        context.strokeRect(points[0].x, points[0].y, points[1].x, points[1].y);
      } else {
        const { color, points, thickness } = path;
        context.strokeStyle = color;
        context.lineWidth = thickness;
        context.beginPath();
        context.moveTo(points[0].x, points[0].y);
        points.forEach((point) => {
          context.lineTo(point.x, point.y);
          context.stroke();
        });
      }
    });
  };

  const drawRectangle = (
    startX,
    startY,
    endX,
    endY,
    borderColor,
    borderWidth,
    save = true
  ) => {
    const newRectangle = {
      type: "rectangle",
      color: borderColor,
      thickness: borderWidth,
      points: [
        { x: startX, y: startY },
        { x: endX, y: endY },
      ],
    };
    if (save) {
      setPaths([...paths, newRectangle]);
    }
    redrawCanvas([...paths, newRectangle]);
  };

  const handleMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (drawRect) {
      setDrawFakeRect(true);
      setInitialPosition({ x, y });
    } else {
      startDrawing(x, y);
    }
  };

  const handleMouseMove = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (drawRect) {
      setCurrPosition({ x, y });
      if (drawFakeRect) {
        drawRectangle(
          initialPosition.x,
          initialPosition.y,
          x - initialPosition.x,
          y - initialPosition.y,
          color,
          thickness,
          false
        );
      }
    } else {
      draw(x, y);
    }
  };

  const handleMouseUp = (e) => {
    if (drawRect) {
      const rect = {
        startX: initialPosition.x,
        startY: initialPosition.y,
        endX: currPosition.x - initialPosition.x,
        endY: currPosition.y - initialPosition.y,
      };
      drawRectangle(
        rect.startX,
        rect.startY,
        rect.endX,
        rect.endY,
        color,
        thickness
      );
      // setRect(false);
      setDrawFakeRect(false);
    }
    finishDrawing();
  };

  const handleTouchStart = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const touch = e.touches[0];
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    if (drawRect) {
      setDrawFakeRect(true);
      setInitialPosition({ x, y });
    } else startDrawing(x, y);
  };

  const handleTouchMove = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const touch = e.touches[0];
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    if (drawRect) {
      setCurrPosition({ x, y });
      if (drawFakeRect) {
        drawRectangle(
          initialPosition.x,
          initialPosition.y,
          x - initialPosition.x,
          y - initialPosition.y,
          color,
          thickness,
          false
        );
      }
    } else draw(x, y);
  };

  const handleTouchEnd = (e) => {
    if (drawRect) {
      const rect = {
        startX: initialPosition.x,
        startY: initialPosition.y,
        endX: currPosition.x - initialPosition.x,
        endY: currPosition.y - initialPosition.y,
      };
      drawRectangle(
        rect.startX,
        rect.startY,
        rect.endX,
        rect.endY,
        color,
        thickness
      );
      // setRect(false);
      setDrawFakeRect(false);
    }

    finishDrawing();
  };

  const toolbox = [
    {
      name: "",
      onClick: () => {
        setDrawRect(!drawRect);
      },
      Icon: <RectIcon {...{ height: 15, width: 15 }} />,
      className: drawRect ? "mondayButtonBlue" : "mondayButtonGrey",
    },

    {
      // name: t("Undo"),
      name: "",
      onClick: undo,
      Icon: <UndoIcon {...{ height: 15, width: 15 }} />,
      disabled: paths.length === 0,
      className: "mondayButtonBlue",
    },

    {
      // name: t("Save"),
      name: "",
      Icon: <TickIcon {...{ height: 15, width: 15 }} />,
      onClick: onDrawSave,
      disabled: paths.length === 0,
      className: "mondayButtonGreen",
    },
    {
      // name: t("Clear"),
      name: "",
      onClick: clearCanvas,
      Icon: <XIcon {...{ height: 15, width: 15 }} />,
      disabled: paths.length === 0,
      className: "mondayButtonRed",
    },
  ];

  return (
    <div className="canvas-container">
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{ border: "1px solid #ccc", cursor: "crosshair" }}
      />
      <div className="canvas-toolbox">
        <Dropdown
          style={{ display: "flex", alignItems: "center" }}
          placement="top"
          trigger={["click"]}
          open={dropDownOpen}
          onOpenChange={(open) => setDropDownOpen(open)}
          dropdownRender={(menu) => {
            return (
              <div className="canvas-dropdown-menu">
                <div className="canvas-dropdown-colors-con">
                  <label>{t("Colors")}</label>
                  <div className="canvas-dropdown-colors">
                    {[
                      "#FF5733",
                      "#000000",
                      "#33FF57",
                      "#3357FF",
                      "#ebeb34",
                      "#b434eb",
                      "#cccccc",
                      " #ff00ff",
                      " #ff1a1a",
                      "#ff9900",
                    ].map((colorMap, i) => (
                      <span
                        key={i}
                        className="canvas-dropdown-color"
                        style={{
                          border: color === colorMap ? "1px solid #000" : "",
                          backgroundColor: colorMap,
                        }}
                        onClick={() => {
                          setColor(colorMap);
                          setDropDownOpen(false);
                        }}
                      ></span>
                    ))}
                  </div>
                </div>
                <div>
                  <label>{t("Size")}</label>
                  <Slider
                    defaultValue={proppedThickness}
                    min={1}
                    max={10}
                    onChange={(value) => {
                      setThickness(value);
                    }}
                  />
                </div>
              </div>
            );
          }}
        >
          <MondayButton
            {...{
              Icon: <DrawIcon style={{ color }} fill={color} />,
              className: "mondayButtonGrey",
            }}
          ></MondayButton>
        </Dropdown>
        {toolbox.map(({ name, onClick, disabled, className, Icon }, i) => (
          <MondayButton
            {...{
              hasIcon: false,
              className,
              onClick,
              disabled,
              Icon,
              style: { height: 32, width: 32 },
            }}
            key={i}
          >
            {name}
          </MondayButton>
        ))}
      </div>
      {/* Hidden initial image for clearCanvas function */}
      <img
        id="initial-image"
        src={initialImageSrc}
        alt="Initial"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default Canvas;
