import { axesTooltipCustom } from "../../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "documentType",
    groupKey: "docType",
    xAxisName: "Type",
  },
  {
    chartName: "docStatus",
    groupKey: "docStatus",
    xAxisName: "Status",
  },
  {
    chartName: "category",
    groupKey: "categoryName",
    xAxisName: "Category",
  },
  {
    chartName: "createdBy",
    groupKey: "createdBy",
    xAxisName: "Documents",
  },
];

export const getChartingOptions = (data) => {
  let documentType = new Set();
  let docStatus = new Set();
  let category = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const dType = item.docType || "No Data";
    const oStatus = item.docStatus || "No Data";
    const cat = item.categoryName || "No Data";
    const user = item.createdBy || "No Data";

    documentType.add(dType);
    docStatus.add(oStatus);
    category.add(cat);
    createdBy.add(user);
  });

  const countOnDocumentType = {
    title: {
      text: "Total Documents Count",
    },
    subtitle: {
      text: "Based on Type",
    },
    series:
      Array.from(documentType).map((type) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: type,
          yName: type,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Documents"),
          },
        };
      }) || [],
  };

  const countOnDocumentStatus = {
    title: {
      text: "Total Documents Count",
    },
    subtitle: {
      text: "Based on Status",
    },
    series:
      Array.from(docStatus).map((status) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: status,
          yName: status,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Documents"),
          },
        };
      }) || [],
  };

  const countOnCategory = {
    title: {
      text: "Total Documents Count",
    },
    subtitle: {
      text: "Based on Category",
    },
    series:
      Array.from(category).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Documents"),
          },
        };
      }) || [],
  };

  const countOnCreatedBy = {
    title: {
      text: "Total Documents Count",
    },
    subtitle: {
      text: "Based on Created By",
    },
    series:
      Array.from(createdBy).map((user) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: user,
          yName: user,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Documents"),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle: "Show total Documents count based on Type",
      params: countOnDocumentType,
      key: "documentType",
    },
    {
      optionTitle: "Show total Documents count based on Status",
      params: countOnDocumentStatus,
      key: "docStatus",
    },
    {
      optionTitle: "Show total Documents count based on Category",
      params: countOnCategory,
      key: "category",
    },
    {
      optionTitle: "Show total Documents count based on Created By",
      params: countOnCreatedBy,
      key: "createdBy",
    },
  ];

  return chartingOptions;
};
