import { useMediaQuery } from "react-responsive";

export const useDeviceType = () => {
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const currentDevice = isDesktop
    ? "webManagement"
    : isTablet
    ? "iPadManagement"
    : isMobile
    ? "mobileManagement"
    : "webManagement";

  return currentDevice;
};
