import moment from "moment";
import { MAIN_RECORD } from "./constants";
import { getBinNumber } from "src/components/SidebarPages/Projects/DobTab/utils";

export const generateStepProps = ({ componentClickHandler = () => {} }) => ({
  handleCloseForm: componentClickHandler,
  setNewTaskModal: componentClickHandler,
  setOpen: componentClickHandler,
  setEdit: componentClickHandler,
  closeModal: componentClickHandler,
  setShowCreatePaymentModal: componentClickHandler,
  setNewTaskModal: componentClickHandler,
});

export function isAnyStepDisabled(selectedSteps) {
  if (selectedSteps.length === 0) {
    return true;
  }
  return selectedSteps.some((step) => step.disabled);
}

export function titleFormatter(inputString) {
  if (typeof inputString !== "string") {
    throw new Error("Input must be a string");
  }
  if (inputString.toLowerCase().endsWith("s")) {
    return inputString.slice(0, -1);
  }

  return inputString;
}

export function replaceCreateFromTitle(title) {
  return title.replace(/^Create\s+/i, "");
}

export function isDependencyMet(step, selectedSteps) {
  if (
    step &&
    step.dependant.some((dependantStep) =>
      selectedSteps.find(
        (item) => replaceCreateFromTitle(item.name) === dependantStep
      )
    )
  )
    return false;

  return true;
}

const removeStepAndDependencies = (name, steps) => {
  const index = steps.findIndex(
    (step) =>
      step.name !== name &&
      !step.dependant.some((dep) => dep === "Current record")
  );
  if (index !== -1) {
    const removedStep = steps.splice(index, 1)[0];
    const dependenciesToRemove = removedStep.dependant;

    dependenciesToRemove.forEach((dependency) => {
      removeStepAndDependencies(dependency, steps);
    });
  }
};

export const removeStep = (stepName, selectedSteps) => {
  removeStepAndDependencies(replaceCreateFromTitle(stepName), selectedSteps);

  return selectedSteps;
};

export const generateStepStatus = (step, selectedSteps) => {
  const isChecked = selectedSteps.some(
    (selectedStep) => selectedStep.id === step.id
  );

  const isNotCurrent =
    step.dependant.length > 0 &&
    !step.dependant.some((item) => item === MAIN_RECORD);

  const isDisabled = isNotCurrent && isDependencyMet(step, selectedSteps);

  const dependant = step.dependant.filter((item) => item !== MAIN_RECORD);

  const hideTooltip = step.dependant.some((item) => item === MAIN_RECORD);

  const otherText = `should be selected to enable ${step.name}`;

  const toolTipText =
    dependant.length > 0 && !hideTooltip
      ? dependant.length === 1
        ? `${dependant.join("")} ${otherText}`
        : `One of ${dependant.join(" or ")} ${otherText}`
      : "";

  return { isChecked, isDisabled, toolTipText };
};

export const getLatLngFromAddress = async (address) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export const getAddressInfo = async (address) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      const addressInfo = data.results[0];
      return { addressInfo };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export function removeSpacesFromString(str) {
  return str.replace(/\s/g, "");
}

export const convertDate = (date) =>
  date ? moment(date).tz("America/New_York") : undefined;

export const mapData = (data, idKey, labelKey) => {
  return data.map((item, index) => ({
    id: idKey ? item[idKey] : index + 1,
    label: labelKey ? item[labelKey] : item,
    value: labelKey ? item[labelKey] : item,
  }));
};

export const getDobInformation = async (recordAddress) => {
  const binNumber = await getBinNumber(recordAddress);
  const response =
    await fetch(`https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${binNumber}
`);

  if (!response.ok) {
    return {};
  }

  const information = await response.json();

  let dobAddress =
    information[0]?.street_name && information[0]?.borough
      ? `${information[0].street_name} , ${information[0].borough}`
      : "";
  const dobBlock = information[0]?.block || "";
  const dobLot = information[0]?.lot || "";
  const borough = information[0]?.borough;
  const address = `${information[0].house_no} ${information[0].street_name} `;

  return { binNumber, dobAddress, dobBlock, dobLot, borough, address };
};

export const getRecord = (dependantName, record, savedItems) => {
  if (dependantName.toLowerCase() === "current record") {
    return record;
  }

  const foundItem = savedItems.find(
    (item) => item.name === dependantName
  )?.item;

  return foundItem;
};
