import htmlParser from "../../../../../utils/htmlParser";
import { formatCurrency } from "../../../utils";
import { Button } from "antd";
import { TrashIcon } from "../../../Communication/assets";
import { InputComponent } from "../../../Fleet/components";

function isInt(value) {
  let rg = /^[0-9]+$/;
  return rg.test(value) ? true : false;
}

export const columns = ({
  changeItemQuantity = () => {},
  onRemoveItem = () => {},
  totalAmountsForDefault = {},
  allItems = [],
}) => [
  {
    title: "Item Name",
    dataIndex: "itemName",
    fixed: "left",
    width: 90,
  },
  {
    title: "Item no.",
    dataIndex: "itemPartNo",
    width: 90,
  },
  {
    title: "Description",
    dataIndex: "itemDescription",
    render(value) {
      return htmlParser(value);
    },
  },
  {
    title: "Item Notes",
    dataIndex: "itemNotes",
    render(value, record) {
      if (!!record?.itemId) {
        return (
          <>
            <InputComponent
              {...{
                label: "",
                required: false,
                allowClear: true,
                type: "textarea",
                initialValue: value || "",
                formItemName: `NOTES:${record?.itemId}`,
              }}
            />
          </>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    title: "Price",
    dataIndex: "costPerUnit",
    width: 100,
    render(value) {
      if (!!value) {
        return (
          <span style={{ whiteSpace: "nowrap" }}>{formatCurrency(value)}</span>
        );
      }
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    width: 112,
    render(value, record) {
      return (
        <>
          {!record?.total && isNaN(record?.total) ? (
            <InputComponent
              {...{
                type: "input",
                typeNumber: true,
                className: "gridInput",
                initialValue: parseInt(value),
                formItemName: record?.itemId,
                onChange(val) {
                  changeItemQuantity(val.target.value, record);
                },
                rules: [
                  {
                    async validator(_, value) {
                      if (!isInt(value)) {
                        return Promise.reject("Please enter a valid quantity");
                      } else {
                        let totalAvailable = null;
                        if (!!totalAmountsForDefault[record?.itemId]) {
                          totalAvailable =
                            totalAmountsForDefault[record?.itemId];
                        } else {
                          totalAvailable = parseInt(
                            allItems?.find(
                              (el) => el?.itemId === record?.itemId
                            )?.itemQuantity
                          );
                        }
                        if (parseInt(value) > totalAvailable) {
                          return Promise.reject(
                            `Max. qty is ${totalAvailable}`
                          );
                        } else if (parseInt(value) === 0) {
                          return Promise.reject("Min. allowed is 1");
                        } else {
                          return Promise.resolve();
                        }
                      }
                    },
                  },
                ],
              }}
            />
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    width: 100,
    render(value, record) {
      if (isNaN(record?.total)) {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(
              parseFloat(record?.costPerUnit) * parseInt(record?.quantity)
            )}
          </span>
        );
      } else {
        return (
          <strong style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(value)}
          </strong>
        );
      }
    },
  },
  {
    title: "",
    width: 60,
    render(_, record) {
      if (!record?.total && isNaN(record?.total)) {
        return (
          <div className="actionButtons">
            <Button
              style={{
                borderRadius: "5px",
                display: "grid",
                placeContent: "center",
              }}
              onClick={() => {
                onRemoveItem(record);
              }}
            >
              <TrashIcon />
            </Button>
          </div>
        );
      }
    },
  },
];

/**
 * Function that returns the difference of inventory item quantity values
 */
export function getValuesToEdit({ selectedItems, defaultItems, allItems }) {
  let itemsToUpdate = [];
  //All the selected items
  selectedItems?.forEach((el) => {
    //we see if the item was part of the initial values
    let initial = defaultItems?.find((i) => i?.itemId === el?.itemId);
    let item = allItems?.find((i) => i?.itemId === el?.itemId);
    let diff = 0;
    if (!!initial) {
      //we find the difference of the items
      diff = Math.abs(parseInt(el?.quantity) - parseInt(initial?.quantity));
      if (parseInt(el?.quantity) > parseInt(initial?.quantity)) {
        diff = 0 - diff;
      }
    } else {
      diff = 0 - parseInt(el?.quantity);
    }

    if (diff !== 0) {
      let newQuantity = parseInt(item?.itemQuantity) + diff;

      itemsToUpdate.push({
        id: el?.itemId,
        name: el?.itemName,
        body: {
          itemQuantity: newQuantity.toString(),
          itemStatus: !!item?.criticalQuantity
            ? newQuantity > parseInt(item?.criticalQuantity)
              ? "In Stock"
              : "Low Stock"
            : item?.itemStatus,
        },
      });
    }
  });

  //All the default items (can contain items that are deselected)
  defaultItems?.forEach((el) => {
    let isSelected = selectedItems?.find((i) => i?.itemId === el?.itemId);
    if (!isSelected) {
      let item = allItems?.find((i) => i?.itemId === el?.itemId);
      let newQuantity = parseInt(item?.itemQuantity) + parseInt(el?.quantity);
      itemsToUpdate.push({
        id: el?.itemId,
        name: el?.itemName,
        body: {
          itemQuantity: newQuantity.toString(),
          itemStatus: !!item?.criticalQuantity
            ? newQuantity > parseInt(item?.criticalQuantity)
              ? "In Stock"
              : "Low Stock"
            : item?.itemStatus,
        },
      });
    }
  });

  return itemsToUpdate;
}
