import { useSelector } from "react-redux";
import "src/components/pages/Settings/settingsComponents/UserProfile/UserProfile.scss";
import "./FieldUserProfile.scss";
import UserProfile from "../../../Settings/settingsComponents/UserProfile/UserProfile";

function FieldUserProfile() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      className={`user-profile-container field-user-profile ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <UserProfile />
    </div>
  );
}

export default FieldUserProfile;
