import { FormInstance } from "antd";

import { ConfigDataType, EntryType, ScheduleType } from "../payrollLiveTypes";
import { MondayButton } from "src/components/commonComponents";
import {
  JobsiteType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";

type FieldsPropsType = {
  position: any;
  editData: EntryType;
  sowList: Array<string>;
  form: FormInstance<any>;
  showAllServices: () => void;
  selectedJobsite: JobsiteType;
  schedules: Array<ScheduleType>;
  selectedEmployee: EmployeeType;
  clientConfigs: Array<ConfigDataType>;
  programEmployees: Array<EmployeeType>;
  onJobsiteSelect: (id: string) => void;
  onEmployeeSelect: (id: string) => void;
  onClearJobsiteChange: (e: any) => void;
  onClearPositionChange: (e: any) => void;
  getCoordinates: (address: string) => void;
  punchType: "ID" | "OL" | "IL" | "OD" | "HR";
};

const hrCategories = [
  "Sick",
  "Holyday",
  "Regular",
  "Overtime",
  "Personal",
  "Vacation",
  "Bereavement",
  "Paid Time Of",
  "Unpaid Time Off",
  "NYC Covid Leave",
];
const statusOptions = ["Draft", "Pending", "Completed"];
const punchTypeOptions = ["ID", "OD", "IL", "OL", "HR"];

export function entryModalFields({
  form,
  sowList,
  editData,
  position,
  schedules,
  punchType,
  clientConfigs,
  getCoordinates,
  showAllServices,
  onJobsiteSelect,
  selectedJobsite,
  onEmployeeSelect,
  programEmployees,
  selectedEmployee,
  onClearJobsiteChange,
  onClearPositionChange,
}: FieldsPropsType) {
  const empCoordinates = position?.coordinates?.lat
    ? position?.coordinates
    : editData.punchCoordinates;

  const jobsiteOptions: Array<
    ScheduleType & { jobAddress?: string; jobsiteId?: string }
  > = schedules.filter(
    (el: ScheduleType & { jobsiteId?: string }) => !!el.jobsiteId
  );
  const scheduleOptions = schedules.filter(
    (el: ScheduleType & { jobsiteId?: string }) => !el.jobsiteId
  );

  return [
    {
      label: "Employee Full Name",
      formItemName: "employeeFullName",
      dataTestid: "employeeFullName",
      placeholder: "Employee Name...",
      showSearch: true,
      type: "select",
      onSelect: onEmployeeSelect,
      required: true,
      // disabled: !nameEditAllow,
      popupMatchSelectWidth: false,
      // disabled: !!selectedRow?.employeeFullName?.length,
      getPopUpContainer: document.body,
      customOptions: programEmployees.map(({ crewName, crewId }, key) => ({
        value: crewId,
        label: crewName,
        key,
      })),
    },
    {
      label: "Role",
      placeholder: "Employee role...",
      formItemName: "employeeRole",
      dataTestid: "employeeRole",
      type: "input",
      disabled: true,
    },
    {
      label: "Company",
      placeholder: "Company...",
      formItemName: "company",
      dataTestid: "companyName",
      type: "select",
      popupMatchSelectWidth: false,
      getPopUpContainer: document.body,
      disabled: true,
      customOptions:
        Array.isArray(clientConfigs) &&
        clientConfigs.map(({ clientName }, index) => ({
          key: index,
          value: clientName,
          label: clientName,
        })),
    },
    {
      label:
        selectedJobsite?.payrollType === "Prevailing Wage"
          ? `Rate (Prevailing Wage) (default ${selectedEmployee?.employeeRate})`
          : "Rate (default)",
      formItemName: "employeeRate",
      dataTestid: "employeeRate",
      disabled: true,
      type: "input",
      typeNumber: true,
      placeholder: "Rate...",
    },
    {
      label: "Punch Date",
      formItemName: "punchDate",
      dataTestid: "punchDate",
      placeholder: "Punch date...",
      type: "datePicker",
      disabled: false,
      allowClear: true,
      required: true,
      getPopUpContainer: document.body,
    },
    {
      form,
      label: "Punch Time",
      formItemName: "punchTime",
      dataTestid: "punchTime",
      placeholder: "Punch time...",
      type: "customTimeInput",
      required: true,
    },
    {
      label: "Punch Type",
      formItemName: "punchType",
      dataTestid: "punchType",
      placeholder: "Punch type...",
      required: true,
      type: "select",
      customOptions: punchTypeOptions.map((opt, key) => ({
        key,
        value: opt,
        label: opt,
      })),
    },
    punchType !== "HR" && {
      label: "Punch Location",
      formItemName: "punchLocation",
      dataTestid: "punchLocation",
      type: "placesAutocomplete",
      required: true,
      onSelect: getCoordinates,
      onClear: onClearPositionChange,
      placeholder: "Punch Location...",
      getPopUpContainer: document.body,
    },
    punchType === "HR" && {
      label: "Reason",
      formItemName: "reason",
      dataTestid: "reason",
      type: "select",
      required: true,
      placeholder: "Select reason...",
      getPopUpContainer: document.body,
      customOptions: hrCategories.map((opt, key) => ({
        key,
        value: opt,
        label: opt,
      })),
    },
    punchType !== "HR" && {
      label: "Jobsite Match",
      formItemName: "jobsiteMatch",
      dataTestid: "jobsiteMatch",
      type: "select",
      placeholder: "Jobsite...",
      required: !editData,
      // initialValue: selectedJobsite?.jobsiteId,
      onSelect: onJobsiteSelect,
      onClear: onClearJobsiteChange,
      getPopUpContainer: document.body,
      filterOption: (input, option) => {
        return (option?.label?.key || "")
          .toLowerCase()
          .includes((input || "").toLowerCase());
      },
      customOptions: [
        {
          label: "Schedules",
          options: scheduleOptions
            .sort((a, b) => {
              const positionA = a?.addressPosition?.lat
                ? a?.addressPosition
                : { lat: 0, lng: 0 };
              const positionB = b?.addressPosition?.lat
                ? b?.addressPosition
                : { lat: 0, lng: 0 };

              const distanceA = withinRadius(
                positionA,
                empCoordinates
              )?.distanceInMile;
              const distanceB = withinRadius(
                positionB,
                empCoordinates
              )?.distanceInMile;

              return distanceA - distanceB;
            })
            .map(({ scheduleAddress, projectId, addressPosition }) => {
              const distance = withinRadius(
                addressPosition,
                empCoordinates
              )?.distanceInMile;

              return {
                key: projectId,
                value: projectId,
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 10,
                    }}
                    key={scheduleAddress} // key used for search options
                  >
                    <div>{scheduleAddress}</div>
                    {(position?.coordinates?.lat ||
                      editData?.punchCoordinates?.lat) && (
                      <div
                        style={{
                          color: "grey",
                        }}
                      >
                        ({distance}) Miles
                      </div>
                    )}
                  </div>
                ),
              };
            }),
        },
        {
          label: "Jobsites",
          options: jobsiteOptions
            .sort((a, b) => {
              const positionA = a?.addressPosition?.lat
                ? a?.addressPosition
                : { lat: 0, lng: 0 };
              const positionB = b?.addressPosition?.lat
                ? b?.addressPosition
                : { lat: 0, lng: 0 };

              const distanceA = withinRadius(
                positionA,
                empCoordinates
              )?.distanceInMile;
              const distanceB = withinRadius(
                positionB,
                empCoordinates
              )?.distanceInMile;

              return distanceA - distanceB;
            })
            .map(({ jobAddress, projectId, addressPosition }) => {
              const distance = withinRadius(
                addressPosition,
                empCoordinates
              )?.distanceInMile;

              return {
                key: projectId,
                value: projectId,
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 10,
                    }}
                    key={jobAddress} // key used for search options
                  >
                    <div>{jobAddress}</div>
                    {(position?.coordinates?.lat ||
                      editData?.punchCoordinates?.lat) && (
                      <div
                        style={{
                          color: "grey",
                        }}
                      >
                        ({distance}) Miles
                      </div>
                    )}
                  </div>
                ),
              };
            }),
        },
      ],
    },

    punchType !== "HR" && {
      label: "SOW",
      placeholder: "SOW...",
      type: "select",
      formItemName: "sow",
      dataTestid: "sow",
      mode: "multiple",
      customOptions: sowList?.map?.((opt, index) => ({
        key: index,
        value: opt,
        label: opt,
      })),
      dropdownRender: (menu) => {
        return (
          <main>
            {menu}
            <section
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MondayButton
                className="mondayButtonBlue"
                data-testid="show-all-services-btn"
                hasIcon={false}
                onClick={showAllServices}
              >
                Show all Services
              </MondayButton>
            </section>
          </main>
        );
      },
    },
    {
      label: "Status",
      placeholder: "Select status...",
      type: "select",
      defaultValue: "Draft",
      required: true,
      formItemName: "activityStatus",
      dataTestid: "activityStatus",
      customOptions: statusOptions.map((opt, key) => ({
        key,
        value: opt,
        label: opt,
      })),
    },
  ].filter(Boolean);
}
