export const functionOptions = [
  {
    value: "is",
    type: "autocomplete",
    allowedTypes: ["string", "number", "boolean"],
  },
  {
    value: "is not",
    type: "autocomplete",
    allowedTypes: ["string", "number", "boolean"],
  },
  {
    value: "is not one of",
    type: "autocomplete",
    allowedTypes: ["string", "number", "boolean"],
  },
  {
    value: "is one of",
    type: "autocomplete",
    allowedTypes: ["string", "number", "boolean"],
  },
  {
    value: "contains",
    type: "input",
    allowedTypes: ["string", "number", "object"],
  },
  { value: "begins with", type: "input", allowedTypes: ["string", "number"] },
  {
    value: "is between",
    type: "double input",
    allowedTypes: ["number", "date"],
  },
  { value: "is greater than", type: "input", allowedTypes: ["number"] },
  {
    value: "is greater than or equal to",
    type: "input",
    allowedTypes: ["number"],
  },
  { value: "is like", type: "input", allowedTypes: ["string", "number"] },
  {
    value: "is not between",
    type: "double input",
    allowedTypes: ["number", "date"],
  },
  { value: "is not like", type: "input", allowedTypes: ["string", "number"] },
  {
    value: "is not null",
    type: "none",
    allowedTypes: ["string", "number", "boolean", "date", "object"],
  },
  {
    value: "is null",
    type: "none",
    allowedTypes: ["string", "number", "boolean", "date", "object"],
  },
];
