import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const opportunities = ({ tables, programFields }) => {
  return {
    opportunityStage: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Opportunity Stage")
        ?.fieldOptions?.map(({ detailedStageName }, index) => ({
          key: index,
          title: detailedStageName,
          taskNumber: tables?.Opportunities?.length,
          taskDone: tables?.Opportunities?.filter(
            (a) => a?.opportunityStage === detailedStageName
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${detailedStageName} Opportunity`,
          contentsHandler: ` ${detailedStageName} Opportunity's`,
        })),
    }),

    projectExecutive: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Opportunities, "projectExecutive")
      )?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: tables?.Opportunities?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: ` ${key} Opportunity`,
        contentsHandler: ` ${key} Opportunity's`,
      })),
    }),

    projectManager: () => ({
      content: Object.entries(
        repeatedElementSum(
          tables?.Opportunities?.map((a) => a?.projectManager)?.flat(),
          "label"
        )
      )?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: tables?.Opportunities?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: ` ${key} Opportunity`,
        contentsHandler: ` ${key} Opportunity's`,
      })),
    }),
  }; //opportunities
};
