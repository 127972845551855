// import uniq from "lodash/uniq";
// import { getRandomColor } from "../../../../utils";

export const workOrders = ({ tables, programFields }) => {
  const workOrdersStatuses = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions["Work Orders"];

  return {
    workOrderStatus: () => ({
      labels: workOrdersStatuses?.map((a) => a?.statusName),
      data: workOrdersStatuses?.map(
        (statuses) =>
          tables?.["Work Orders"]?.filter?.(
            (a) =>
              a?.workOrderStatus?.toLowerCase() ===
              statuses?.statusName?.toLowerCase()
          )?.length
      ),
      backgroundColor: workOrdersStatuses?.map((a) => a?.statusColor),
    }),

    // fleetName: () => ({
    //   labels: uniq(
    //     tables?.["Work Orders"]?.map?.(({ fleetName }) => fleetName)
    //   ),
    //   data: uniq(
    //     tables?.["Work Orders"]
    //       ?.map?.(({ fleetName }) => fleetName)
    //       ?.map(
    //         (status) =>
    //           tables?.["Work Orders"]?.filter?.(
    //             (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
    //           )?.length
    //       )
    //   ),
    //   backgroundColor: uniq(
    //     tables?.["Work Orders"]?.map?.(() => getRandomColor())
    //   ),
    // }),
  };
};
