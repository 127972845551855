import React from "react";
import ReactQuill from "react-quill";
import { Alert, Button } from "antd";
import moment from "moment-timezone";
function AlreadyHasDesc({
  descriptionContent,
  lastModifiedUser = "",
  lastModified,
  onRegenerate,
}) {
  return (
    <div>
      <div className="already-has-warning">
        <Alert
          message="Already Generated Description"
          description={`This description was generated by ${lastModifiedUser} on ${moment(
            lastModified
          ).format("DD/MM/YYYY HH:mm")}`}
          type="warning"
          action={
            <Button size="small" type="primary" danger onClick={onRegenerate}>
              Regenerate
            </Button>
          }
          closable
        />
      </div>
      <br />
      <div className="preview-dynamic-desc">
        <iframe
          title="testPdf"
          allowfullscreen
          height="100%"
          width="100%"
          id="frameId"
          output="embed"
          sandbox="allow-forms allow-modals allow-scripts allow-same-origin"
          // data-alloy-tabstop="true"
          // tabindex="-1"
          srcdoc={`<!DOCTYPE html><html><head><base href="http://localhost:3000/estimations/"><link type="text/css" rel="stylesheet" href="https://cdn.tiny.cloud/1/m7mdjxl48599wmsg7n8ghucnfu4dzdnrqmmrcw647c7qn46j/tinymce/5.10.6-132/skins/ui/oxide/content.min.css" crossorigin="anonymous"><link type="text/css" rel="stylesheet" href="https://cdn.tiny.cloud/1/m7mdjxl48599wmsg7n8ghucnfu4dzdnrqmmrcw647c7qn46j/tinymce/5.10.6-132/skins/content/default/content.min.css" crossorigin="anonymous"></head><body id="tinymce" class="mce-content-body ">
${descriptionContent}
</body></html>`}
        ></iframe>
      </div>
      {/* <ReactQuill
        {...{
          //   ref,
          value: descriptionContent,
          //   modules,
          //   onChange,
          className: `note_input quillTextEditor`.trim(),
          //   placeholder,
          theme: "snow",
          readOnly: true,
          //   formats: quillFormats,
        }}
      /> */}
    </div>
  );
}

export default AlreadyHasDesc;
