import { ReactNode, Fragment } from "react";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";

import "./SmallInfoCard.scss";

type SmallInfoProps = {
  id?: string;
  colon?: boolean;
  title: ReactNode;
  className?: string;
  isDarkMode: boolean;
  data: { title: ReactNode; value: ReactNode }[];
};

function SmallInfoCard({
  id,
  data,
  title,
  className,
  isDarkMode,
  colon = true,
}: SmallInfoProps) {
  return (
    <div
      id={id}
      className={`small-info-card ${className ?? ""} ${
        isDarkMode ? "small-info-dark" : ""
      }`}
    >
      <div className="small-info-title">{title}</div>
      {!data.length && (
        <div className="info-card-no-info">
          <WarningTriangle />
          <span className="no-info-text">No Information Was Found</span>
        </div>
      )}
      <div className="small-info-data">
        {data.map((e, i) => {
          return (
            <Fragment key={`small-data${i}`}>
              <div className="data-title">
                {e.title}
                {colon ? ":" : ""}
              </div>
              <div className="data-value">{e.value}</div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default SmallInfoCard;
