export class SimpleAddon {
  id
  approved = false
  type

  constructor(id) {
    this.id = id
  }
}

/**
 * It is the base class definition for all PLi's. PLI from different services have classes which extends this one.
 * @class
 * @constructor
 * @public
 *
 * @property {number} id - Must be 1 or higher
 * @property {boolean} approved - If PLI is approved or not. If it not approved, it is not considered in some calculations, such as average Price Per Unit, not it will be saved in project
 * @property {number} serviceId - Service identifying number can be from 1-20. Check proposedTypeOfWork table in database
 *
 * @property {number | String} length - A property in almost all service types
 *
 * @property {[]} addons - A list with addons. TO BE DEFINED
 *
 * @property {String} description - Filled with rich text.
 * @property {String} note - Filled with rich text.
 *
 * */
export class SimplePLI {
  id
  approved = false
  serviceId

  addons = []

  description
  note

  taxRate
  taxAmount = 0
  totalPrice = 0

  constructor(id, taxRate = 0) {
    this.id = id
    this.taxRate = taxRate
  }
}
