import { useContext, useEffect, useState } from "react";
import { VideoTutorialIcon } from "../../assets/svg";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import NewCategories from "../NewCategories/NewCategories";

const NewCard = () => {
  const { newVideos, setActivePrimaryCat, setSubCatVideos } =
    useContext(VideoTutorialContext);

  const [openNewCategory, setOpenNewCategory] = useState(false);

  const handleClick = () => {
    setOpenNewCategory((prev) => !prev);
    setActivePrimaryCat();
    setSubCatVideos();
  };

  useEffect(() => {
    newVideos.length === 0 && setOpenNewCategory(false);
  }, [newVideos]);

  return (
    <>
      <div
        data-testid="newTutorialsCard"
        className={`newTutorialsCard ${openNewCategory ? "active" : ""}`}
        onClick={handleClick}
      >
        <span className="new-text"> NEW </span>
        <p className="text">
          Click to see our ({newVideos.length}) new tutorials
        </p>
        <VideoTutorialIcon className="videoPlayerIcon" />
      </div>
      {openNewCategory && (
        <NewCategories newVideos={newVideos} isNewVideo={true} />
      )}
    </>
  );
};

export default NewCard;
