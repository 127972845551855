import { Progress } from "antd"
import { MondayButton } from "../../../../../../../commonComponents"

const details = ({ params, setDetailsVisible }) => {
  return (
    <MondayButton
      onClick={(e) => {
        e.stopPropagation()
        setDetailsVisible(params.data)
      }}
    >
      Details
    </MondayButton>
  )
}
const credits = (e) => {
  const { params, setCredits } = e
  return (
    <MondayButton
      onClick={(e) => {
        e.stopPropagation()
        setCredits(params)
      }}
    >
      Details
    </MondayButton>
  )
}
const progress = ({ params }) => {
  return (
    <Progress
      strokeColor={{
        "0%": "#108ee9",
        "100%": "#87d068",
      }}
      percent={params.value}
    />
  )
}

export const cellRendersFrameworks = (e) => ({
  details: (params) => details({ params, ...e }),
  progress: (params) => progress({ params, ...e }),
  credits: (params) => credits({ params, ...e }),
})
