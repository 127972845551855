import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { message, Modal, notification, Switch, Tooltip } from "antd";

import {
  editAccessRequestResponseHandler,
  handleSwapEditAccessHandler,
  sessionStatusColorHandler,
  requestEditAccessHandler,
  sessionStatusHandler,
} from "./accountingWriteModeFunctions";
import MondayButton from "../MondayButton/MondayButton";
import { wsEndpointsAndBackUps } from "../../../AppData";
import { RequestAccess, Close } from "../../SidebarPages/DynamicView/src";
import RequestAccessNotification from "./Components/RequestAccessNotification/RequestAccessNotification";

import "./AccountingWriteMode.scss";

const DISABLE_SWITCH = false;
const REASON_OF_DISABILITY = "";

const AccountingWriteMode = forwardRef(
  (
    {
      onSwitchFunction,
      disableSwitch = DISABLE_SWITCH,
      disabilityReason = REASON_OF_DISABILITY,
      isWritable,
      recordId,
      darkMode,
      projectId = "",
      manualIdleTime = false,
    },
    ref
  ) => {
    const [
      { authenticatedUser },
      { userConfiguration: userConfig },
      { preferences },
    ] = useSelector((state) => [
      state.authenticatedUser,
      state.userConfig,
      state.preferences,
    ]);
    // let disableSwitch = DISABLE_SWITCH;

    // Filter the Items array within allUsers to keep only objects with a cognitoUserId property
    const filteredUsers = userConfig.allUsers.Items.filter((user) =>
      user.hasOwnProperty("cognitoUserId")
    );

    // Create a new userConfiguration object with the filtered Items array
    const userConfiguration = {
      ...userConfig,
      allUsers: {
        Items: filteredUsers,
      },
    };

    const wsRef = useRef(); //websocket reference, so we do not create a new ws instance when sending a message ;)
    const [sessionId] = useState(uuidv4());
    const [canUse, setCanUse] = useState(true); //states if we can use the DEG, if 'false' deg is being edited by another user
    const [userEditingData, setUserEditingData] = useState(); //userId and sessionId of the user that is editing the deg
    const [isEditable, setIsEditable] = useState(true);
    const [currentInstance, setCurrentInstance] = useState({
      instance: wsEndpointsAndBackUps?.backUpIPS[0],
      numberOfTries: 0,
    });
    const [customMessage, setCustomMessage] = useState(false);
    /**
     *
     *
     ** ~~~~~~~~~~~~~~~~~~ WEBSOCKET SESSION HANDLERS~~~~~~~~~~~~~~~~~~~~~~
     *
     *
     *
     */

    //called when user is inactive
    const onIdle = () => {
      if (!isWritable) return;
      changeWritability.disable();
      message.info("Session closed due to inactivity.");
    };

    //idle timer that calls "onIdle" when user is inactive on DEG
    const { start } = useIdleTimer({
      timeout:
        1000 *
        (!manualIdleTime
          ? preferences?.preferences?.dataEntryGrid?.writeModeIdleTimeout
          : manualIdleTime),
      onIdle,
      debounce: 500,
      startManually: true,
    });

    //data of the user that is currently editing the grid
    const userEditingDataObj = !!userEditingData?.userId
      ? (userConfiguration?.allUsers?.Items || [])?.find?.(
          (item) => item?.cognitoUserId === userEditingData?.userId
        )
      : {
          nameOfUser: "Unknown",
        };

    //request editing access from the editing user
    const requestEditAccess = () =>
      requestEditAccessHandler({
        authenticatedUser,
        userEditingData,
        sessionId,
        recordId,
        wsRef,
      });

    //changes the writability of deg and closes/opens deg session
    const changeWritabilityFunction = (bool) => (wsInstance) => {
      const wsInstanceToUse = wsInstance || wsRef.current; //chooses websocket instance to use

      try {
        wsInstanceToUse.send(
          JSON.stringify({
            request: !bool ? "disableEditing" : "enableEditing",
            body: {
              userId: authenticatedUser?.sub,
              recordId,
              sessionId,
            },
          })
        );
      } catch (error) {
        setCanUse(true);
      }

      onSwitchFunction(bool);

      if (!!bool) {
        start(); //Starts the user inactivity timer
      }
    };

    //object that contains changeWritability functions for better code readability
    const changeWritability = {
      enable: changeWritabilityFunction(true),
      disable: changeWritabilityFunction(false),
    };

    //when another user asks for editing access, this method gives the response
    const handleSwapEditAccess = (sessionId, response, wsInstance) =>
      handleSwapEditAccessHandler({
        changeWritability,
        wsInstance,
        sessionId,
        setCanUse,
        response,
        wsRef,
      });

    //handles websocket methods
    useEffect(() => {
      if (currentInstance?.instance && recordId) {
        const degSessionSocket = new WebSocket(
          `${currentInstance.instance}/ws/accounting-session`
        );
        try {
          wsRef.current = degSessionSocket;

          degSessionSocket.onopen = () => {
            degSessionSocket.send(
              JSON.stringify({
                request: "createSession",
                body: {
                  recordId,
                  userId: authenticatedUser?.sub,
                  sessionId,
                },
              })
            );
          };

          degSessionSocket.onmessage = (e) => {
            setCustomMessage(false);

            const { request, body } = JSON.parse(e.data);

            const {
              estimationIsBeingEdited,
              userId,
              sessionId: msgSessionId,
              status,
            } = body || {};

            switch (request) {
              case "sessionStatus": {
                sessionStatusHandler({
                  estimationIsBeingEdited,
                  setUserEditingData,
                  msgSessionId,
                  setCanUse,
                  userId,
                });

                break;
              }
              case "editAccessRequest": {
                notification.info({
                  key: "editAccessRequest",
                  message: (
                    <div className="header">
                      <div className="icon">
                        <RequestAccess />
                      </div>
                      <div className="title-indc">
                        <span>Another user is requesting editing access.</span>
                      </div>
                      {/* <label> Another user is requesting edit access</label> */}
                    </div>
                  ),
                  closeIcon: <Close />,
                  duration: 0,
                  placement: "bottomLeft",
                  className: "accessRequestNotificationDark",

                  description: (
                    <RequestAccessNotification
                      {...{
                        user: (userConfiguration?.allUsers?.Items || [])?.find(
                          ({ cognitoUserId }) => cognitoUserId === userId
                        ),
                        onConfirm: () =>
                          handleSwapEditAccess(
                            msgSessionId,
                            true,
                            degSessionSocket
                          ),
                        onDeny: () =>
                          handleSwapEditAccess(
                            msgSessionId,
                            false,
                            degSessionSocket
                          ),
                      }}
                    />
                  ),
                });
                break;
              }
              case "editAccessRequestResponse": {
                editAccessRequestResponseHandler({
                  changeWritability,
                  degSessionSocket,
                  status,
                });
                break;
              }
              default: {
                break;
              }
            }
          };
          degSessionSocket.onerror = (e) => {
            if (currentInstance.numberOfTries < 4) {
              console.error(
                `Connection to ${currentInstance.instance} failed. Trying to connect to ... Number of tries: ${currentInstance.numberOfTries}`
              );
              setCurrentInstance((prev) => ({
                instance: wsEndpointsAndBackUps.backUpIPS[prev.numberOfTries],
                numberOfTries: prev.numberOfTries + 1,
              }));
              setCanUse(true);
              // setCustomMessage(true);
            } else {
              setCanUse(true);
              // setCustomMessage(true);
              Modal.warning({
                title: "Hang on!",
                content:
                  "We are dealing with high traffic at the moment. Please try again later.",
              });
            }
          };
        } catch (error) {
          console.error({ error });
          setCanUse(true);
        }
        return () => {
          degSessionSocket.close(1000, "User moved to a different component.");
          changeWritability["disable"]();
        };
      }
    }, [currentInstance, recordId]);

    const sessionStatusColor = sessionStatusColorHandler({
      canUse,
      isWritable,
    });

    useEffect(() => {
      (async () => {
        await API.get("projects", `/projects/${projectId}`).then((res) => {
          if (res?.projectStatus === "Canceled") {
            setIsEditable(false);
          }
        });
      })();
      setIsEditable(true);
    }, [projectId]);

    useImperativeHandle(
      ref,
      () => {
        return {
          changeStatus(newStatus) {
            changeWritability[newStatus ? "enable" : "disable"]();
          },
          getEditingUser() {
            if (userEditingDataObj?.cognitoUserId) {
              return {
                nameOfUser: userEditingDataObj?.nameOfUser,
                userId: userEditingDataObj?.cognitoUserId,
              };
            } else {
              return { nameOfUser: "Editing User", userId: "" };
            }
          },
        };
      },
      [changeWritability, userEditingDataObj, userEditingData]
    );

    return (
      <div
        className={
          !!darkMode
            ? "accountingWriteModeContainerDark"
            : "accountingWriteModeContainerLight"
        }
      >
        <br />
        <Tooltip
          title={
            !isEditable
              ? !!disableSwitch
                ? disabilityReason
                : !!canUse
                ? "Turn editable mode on"
                : ` ${userEditingDataObj?.nameOfUser} is already using this record`
              : disabilityReason
          }
        >
          <Switch
            className="writeReadSwitchContainer"
            onChange={(checked) =>
              changeWritability[!checked ? "disable" : "enable"]()
            }
            checked={isWritable && !disableSwitch}
            disabled={!isEditable || !!disableSwitch || !canUse}
            unCheckedChildren="Read-only mode"
            checkedChildren="Write enabled"
          />
        </Tooltip>
        <div className="statusContainer">
          {!!canUse && !!isWritable ? (
            <>
              {/* <div className="statusLabel">Status:</div>
              <div className="statusValue" style={sessionStatusColor}>
                {!!canUse
                  ? !!isWritable
                    ? "In use"
                    : "Available"
                  : userEditingDataObj?.cognitoUserId === authenticatedUser?.sub
                  ? `In use by you in another tab`
                  : customMessage
                  ? "No Clearance"
                  : `In use by ${userEditingDataObj?.nameOfUser}`}
              </div> */}
            </>
          ) : (
            <>
              <div className="statusLabel">Status:</div>
              <div
                className="statusValue"
                id="in-use-by"
                style={sessionStatusColor}
              >
                {!!canUse
                  ? !!isWritable
                    ? "In use"
                    : "Available"
                  : userEditingDataObj?.cognitoUserId === authenticatedUser?.sub
                  ? `In use by you in another tab`
                  : customMessage
                  ? "No Clearance"
                  : userEditingDataObj?.nameOfUser !== "Unknown"
                  ? `In use by ${userEditingDataObj?.nameOfUser}`
                  : "In use by another user"}
              </div>
            </>
          )}
        </div>
        {!canUse && !customMessage && (
          <MondayButton
            className={"mondayButtonBlue"}
            onClick={requestEditAccess}
            Icon={<RequestAccess />}
          >
            Request Access
          </MondayButton>
        )}
      </div>
    );
  }
);

export default AccountingWriteMode;
