import { useSelector } from "react-redux";

import { Header, LogItem } from "./components";
import { transformLogEntry } from "../../utils/transformLogEntry";
import { viewColumnNames } from "../../utils/viewColumnNames";

import styles from "./ViewLogs.module.scss";

const keys = (object) =>
  typeof object === "object" && !!object ? Object.keys(object) : [];

const ViewLogs = ({ viewLogs }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const viewRowData = keys(viewLogs)
    .map((key) => transformLogEntry(viewLogs[key]))
    .sort((a, b) => b.date - a.date);

  return (
    <div
      className={
        styles[isDarkMode ? "view-logs-container-dark" : "view-logs-container"]
      }
    >
      <div
        className={
          styles[
            isDarkMode ? "view-logs-item-body-dark" : "view-logs-item-body"
          ]
        }
      >
        {viewColumnNames.map((column) => (
          <Header key={column.fieldName} {...{ title: column.fieldTitle }} />
        ))}
      </div>
      {viewRowData.map((data) => (
        <LogItem
          key={data.date}
          {...{
            nameOfUser: data.nameOfUser,
            updatedAt: data.updatedAt,
            styles,
          }}
        />
      ))}
    </div>
  );
};

export default ViewLogs;
