import { dataSourceMapper, mapDataSources } from ".";

export const attachDataSources = (report = {}, datasources = [], isNew) => {
  const { definition = {} } = report;
  const { DataSources } = definition;
  const datasourcesObj = datasources.reduce(
    (acc, { name, data }) => ({ ...acc, [name]: data }),
    {}
  );

  return {
    ...report,
    definition: {
      ...definition,
      DataSources: isNew
        ? mapDataSources(datasources)
        : !!DataSources
        ? DataSources.map((name) =>
            dataSourceMapper({ name, data: datasourcesObj[name] })
          )
        : undefined,
    },
  };
};
