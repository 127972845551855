import { isOffDay } from "./calculators";
import { sortDayIds } from "../PLIPerService/componentsForPli/DaysInScheduleMaster/daysInScheduleMaster";
import { getNYDate } from "../../../../SidebarPages/Projects/Accounting/Applications/ApplicationView/utils/dateFunctions";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import {
  parseInTz,
  setHourMinute,
} from "../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
moment.tz.setDefault("America/New_York");

export function getRandomColor() {
  var letters = "0123456789ABC";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}

export const getTodayModifiedDate = () => {
  const fullDate = dayjs()
    .tz("America/New_York")
    .set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
  return fullDate.format("MM/DD/YYYY HH:mm");
};

/**
 * @param todayDate {Date|undefined}
 * */
export const getDefaultStartTime = (todayDate) => {
  let today = setHourMinute(
    parseInTz(todayDate),
    dayjs().hour(7).minute(0).second(0).millisecond(0)
  );
  return today;
};

/**
 * @param todayDate {Date|undefined}
 * */
export const getDefaultEndTime = (todayDate) => {
  let today = setHourMinute(
    parseInTz(todayDate),
    dayjs().hour(16).minute(0).second(0).millisecond(0)
  );
  return today;
};

export const getEndingDate = (value) => {
  const fullDate = dayjs(value)
    .tz("America/New_York")
    .set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
  return fullDate.format("MM/DD/YYYY HH:mm");
};

export const formatDate = (string) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return dayjs(string).tz("America/New_York").format("MM/DD/YYYY HH:mm");
};

export const formatFullDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return dayjs(date).format("dddd, MMMM Do YYYY");
};

export const formatShortTime = (date) => dayjs(date).format("h:mm A");
//   new Date(date).toLocaleTimeString([], { timeStyle: "short" });

export const formatDateANDShortTime = (date) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
  };
  return dayjs(date).tz("America/New_York").format("MM/DD/YYYY h:mm A");
};

export const getWeatherForDate = (weatherArray, date) => {
  return weatherArray
    ?.filter(
      (weatherElement) =>
        new Date(weatherElement?.startTime).toLocaleDateString() ===
        new Date(date).toLocaleDateString()
    )
    ?.map((q) => {
      return {
        startTime: q?.startTime,
        temperature: q?.temperature,
        icon: q?.icon,
        shortForecast: q?.shortForecast,
        windSpeed: q?.windSpeed,
      };
    });
};

/**
 * @param gridData {Service[]}
 * @param days {Day[]}
 * */
export const clearServicesFromNonExistentDays = (gridData, days) => {
  const dayNames = days?.map((d) => d?.day);

  for (const service of gridData) {
    for (const elevation of service?.serviceOptions?.[0]) {
      for (const pli of elevation?.items) {
        pli.days = pli?.days?.filter((d) => d?.includes(dayNames));
      }
    }
  }

  return gridData;
};

export const filterNodesByDayNames = (node, dayIdList) => {
  let editedData = node?.data;
  if (editedData?.days === undefined) editedData.days = [];
  editedData.days = editedData?.days?.filter((d) => dayIdList?.includes(d));
  if (editedData.addons) {
    for (const addon of editedData?.addons) {
      if (addon.days)
        addon.days = addon?.days?.filter((d) => dayIdList?.includes(d));
    }
  }
  node.setData(editedData);
};

export const createWorkLogLink = (
  gridData,
  {
    estimationIndex,
    serviceId,
    elevationIndex,
    pliId,
    subPliId,
    serviceAddonId,
  }
) => {
  let link = "Estimation " + (estimationIndex + 1);
  let service, elevation, serviceAddon, pli, subPLI, pliAddon;

  service = gridData.find(
    (s) => s.serviceId.toString() === serviceId.toString()
  );
  if (serviceId !== undefined) {
    link += " > " + service.label;
  }
  if (elevationIndex !== undefined) {
    elevation = service.serviceOptions[0][elevationIndex];
    console.log(elevation);
    link += " > " + elevation.elevationLabel;
  }

  if (serviceAddonId !== undefined) {
    link += " > " + serviceAddonId;
  }

  // if(pliId) {
  //     link += " > " + serviceId.toString()
  // }
  // if(serviceId) {
  //     link += " > " + serviceId.toString()
  // }
  // if(serviceId) {
  //     link += " > " + serviceId.toString()
  // }
  // if(serviceId) {
  //     link += " > " + serviceId.toString()
  // }

  return link + ": ";
};

export const toOrdinal = (value) => {
  const j = value % 10,
    k = value % 100;
  if (j === 1 && k !== 11) {
    return value + "st";
  } else if (j === 2 && k !== 12) {
    return value + "nd";
  } else if (j === 3 && k !== 13) {
    return value + "rd";
  } else {
    return value + "th";
  }
};

/**
 * Find surface of an item. It is done by multiplying 2 of items dimensions (length, height, width)
 * */
export const getSurface = (item, { d1, d2 }) =>
  parseInt(item?.[d1]) * parseInt(item?.[d2]);

/**
 * Get days from elevation items and it's sub items (sub pli and addons)
 * Also if scheduleDays is passed we will have them sorted
 * */
export const getElevationDays = (elevation, scheduleDays) => {
  let days = [];

  for (const item of elevation?.items) {
    days = days.concat(item?.days);

    if (item?.subPli) {
      days = getDaysInItems(item?.subPli, days);
    }

    if (item?.addons) {
      days = getDaysInItems(item?.addons, days);
    }
  }

  days = [...new Set(days)];
  if (scheduleDays) {
    days = sortDayIds(scheduleDays, days);
  }
  return days;
};

/**
 * Get days from some item (pli, subpli, addon, whatever)
 * @return {string[]} - return an array with day ids gathered
 * */
export const getDaysInItems = (items, days) => {
  for (const item of items) {
    if (item?.days) days = days.concat(item?.days);
  }
  return days;
};
