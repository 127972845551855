import types from "../types";

export const updateOtherUser = (attributes) => (dispatch) =>
  dispatch({
    type: types.UPDATE_OTHER_USER,
    payload: attributes,
  });

export const UserConfig = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_USER_CONFIG,
    payload: attributes,
  });
};

export const updateUserConfigKey = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_USER_CONFIG_KEY,
    payload: attributes,
  });
};

export const updateUserKeysUser = (attributes) => (dispatch) =>
  dispatch({
    type: types.UPDATE_SPECIFIC_USER,
    payload: attributes,
  });
