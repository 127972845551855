import { message } from "antd";
import _ from "lodash";

export default function removePLIRow(
  ServicesIndex,
  isWritable,
  gridDataState,
  setState,
  gridApi,
  serviceIndex,
  optionIndex,
  elevationIndex,
  itemId,
  accessRights
) {
  if (isWritable) {
    console.log("removePLIRow", {
      ServicesIndex,
      isWritable,
      gridDataState,
      setState,
      gridApi,
      serviceIndex,
      optionIndex,
      elevationIndex,
      itemId,
      accessRights,
    });
    let hasAccessRight =
      accessRights.children.find(
        (item) => item.title === "Adding/Removing pli's"
      )?.write || true;
    if (hasAccessRight) {
      // setState({agSelectedCells: {}});
      // let gridData = _.cloneDeep(gridDataState);
      // let rowData = [];
      // gridApi.forEachNode((node) => rowData.push(node.data));
      // console.log("rowData", rowData);
      let items =
        gridDataState[serviceIndex].serviceOptions[optionIndex][elevationIndex]
          .items;

      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        console.log("items2", item);
        if (item.id === itemId) {
          items.splice(i, 1); //remove from this index
          i--; //come once more in this index
        }
        if (item.id > itemId) {
          //all pli that have higher ID that then deleted pli must decrease their id by 1
          item.id--;
        }
        console.log("items2.2", item);
      }
      console.log("items3", items);

      setState({ agSelectedCells: {} });
      setState({ gridData: gridDataState });
      gridApi.setRowData(items);
      gridApi.redrawRows();
    } else {
      message.error("You have no access to remove pli.");
    }
  } else {
    message.error("Please enable write mode.");
  }
}
