import React from "react";
import { useNavigate } from "react-router-dom";
import { deleteEmployee } from "../services/deleteEmployee";

import { MondayButton, WarningModal } from "../../../../../commonComponents";
import { WarningTriangle } from "../../../../DynamicView/src";
import { XIcon } from "../../../../Communication/assets";
import { Tick } from "../../../../DynamicView/components/FilterView/FilterIcons";

const DeleteEmployeeModal = ({
  rowToDelete,
  setRowToDelete,
  rowData = [],
  setRowData = () => {},
  refreshTable = () => {},
  navigateToPath = null,
}) => {
  const navigate = useNavigate();

  const id =
    rowToDelete?.engineerId ||
    rowToDelete?.architectId ||
    rowToDelete?.driverId;
  rowToDelete?.crewId;

  const employeeType = rowToDelete?.engineerId
    ? "engineers"
    : rowToDelete?.architectId
    ? "architects"
    : rowToDelete?.driverId
    ? "drivers"
    : rowToDelete?.crewStatus
    ? "crews"
    : null;

  return (
    <WarningModal
      visible={rowToDelete}
      setVisible={setRowToDelete}
      title="Warning Message"
      closable={true}
      className="logout-warning-modal"
    >
      <div className="logout-modal-body">
        <span>
          <WarningTriangle />
        </span>
        <p>
          Are you sure you want to delete{" "}
          {rowToDelete?.nameOfUser || rowToDelete?.crewName}?
        </p>
        <div className="buttons">
          <MondayButton
            onClick={() => setRowToDelete(false)}
            Icon={<XIcon />}
            className="mondayButtonRed"
          >
            No
          </MondayButton>
          <MondayButton
            onClick={async () => {
              await deleteEmployee(
                id,
                rowToDelete,
                setRowToDelete,
                employeeType,
                rowData,
                setRowData,
                refreshTable
              );
              navigateToPath && navigate(navigateToPath);
            }}
            Icon={<Tick width={17} height={17} />}
          >
            Yes
          </MondayButton>
        </div>
      </div>
    </WarningModal>
  );
};

export default DeleteEmployeeModal;
