import { message } from "antd";

export const deleteCreditHandler = ({
  fakeApplication,
  service,
  dispatchContext,
  eventName,
}) => {
  let newApplication = { ...fakeApplication };

  newApplication.services.amounts = newApplication?.services?.forEach((el) => {
    if (
      el?.label === service?.label &&
      el?.estimationId === service?.estimationId
    ) {
      el.amounts = el.amounts.filter((amount) => amount.name !== eventName);
    }
  });
  console.log("newApplication23", newApplication);
  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
  message.success(`${eventName} deleted successfully`);
};

export const deleteHoistCreditHandler = ({
  fakeApplication,
  service,
  dispatchContext,
  elevation,
  eventName,
}) => {
  let newApplication = { ...fakeApplication };
  newApplication?.services?.forEach((el) => {
    if (
      el?.label === service?.label &&
      el?.estimationId === service?.estimationId
    ) {
      el?.serviceOptions[0]?.forEach((option) => {
        if (option?.elevationLabel === elevation?.elevationLabel) {
          option.amounts = option.amounts.filter(
            (amount) => amount.name !== eventName
          );
        }
      });
    }
  });
  console.log("newApplication23", newApplication);
  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
  message.success(`${eventName} deleted successfully`);
};
