export const get_new_progressByDay = (
  selectedDays,
  progressByDay,
  { d1, d2 }
) => {
  const new_progressByDay = [];

  for (const day of selectedDays || []) {
    const existing_progressDay = progressByDay?.find((pd) => pd?.day === day);
    if (existing_progressDay === undefined) {
      const newProgressDay = { day, [d1]: 0 };
      if (d2) newProgressDay[d2] = 0;
      new_progressByDay.push(newProgressDay);
    } else {
      new_progressByDay.push(existing_progressDay);
    }
  }
  return new_progressByDay;
};
