import React from "react";
import { MondayButton } from "../../../../../../commonComponents";
import "./QuickPreview.scss";
function QuickPreview({ html = "" }) {
  return (
    <div
      className="quickPreviewHolder"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  );
}

export default QuickPreview;
