import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

import {
  InitialBillProvider,
  useInitialBillContext,
} from "./context/InitialBillProvider";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BillForm from "./components/BillForm";
import BillTable from "./components/BillTable";
import TableTitle from "./components/TableTitle";
import { LoadableComp } from "../../XComponents";
import { FormCard } from "../../Fleet/components";
import ControlPanel from "./components/ControlPanel";
import { updateDocumentTitle } from "../../../../utils";
import { BillModalProvider } from "./context/BillModalProvider";
import { BillCreditProvider } from "./context/BillCreditProvider";
import { AgGridTableProvider } from "../../../commonComponents/DynamicAgGridTable/context/AgGridTableContext";

import "./NewBillModal.scss";
import { ProgressProvider } from "../../../commonComponents/ProgressComponent/context/ProgressContext";
import BillWrapper from "./BillWrapper";

const NewBillModal = (props) => {
  const actionType = props.billData ? "Update" : "Create";
  const categoryName = "Bills";
  return (
    <ProgressProvider {...{ categoryName, actionType }}>
      <AgGridTableProvider>
        <InitialBillProvider {...props}>
          <BillCreditProvider>
            <BillModalProvider>
              <BillModal onClose={props.onClose} />
            </BillModalProvider>
          </BillCreditProvider>
        </InitialBillProvider>
      </AgGridTableProvider>
    </ProgressProvider>
  );
};

const BillModal = ({ onClose }) => {
  const { setVisible, billData, isLoading } = useInitialBillContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const closeModal = () => {
    updateDocumentTitle();
    setVisible && setVisible(false);
    onClose();
  };

  return (
    <Modal
      title={<Header {...{ closeModal }} />}
      afterOpenChange={(event) => {
        event &&
          updateDocumentTitle({
            newTitle: billData
              ? `Edit Bill # ${billData?.billNumber}`
              : "New Bill",
          });
      }}
      className={`bill-modal-container ${
        isDarkMode && "bill-modal-container-dark"
      }`}
      wrapClassName="wrapper-bill-modal-container"
      open={true}
      closable={false}
      footer={<Footer {...{ closeModal }} />}
    >
      <LoadableComp loading={isLoading}>
        <BillWrapper closeModal={closeModal}>
          <ControlPanel />
          <BillForm />
          <FormCard
            title={<TableTitle />}
            className="new-bill-table-card-container"
            childrenClassName="new-bill-table-card"
          >
            <BillTable />
          </FormCard>
        </BillWrapper>
      </LoadableComp>
    </Modal>
  );
};

export default NewBillModal;
