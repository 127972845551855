import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const todos = ({ tables, programFields }) => {
  const todoStatus = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.["To Do"];

  return {
    todoStatus: () => ({
      content: todoStatus?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.["To Do"]?.filter(
            (e) => e?.todoStatus === status?.statusName
          ).length /
            tables?.["To Do"]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status?.statusName,
        done: tables?.["To Do"]?.filter(
          (e) => e?.todoStatus === status?.statusName
        ).length,
      })),
    }),

    todoCategory: () => ({
      content: Object?.entries(
        repeatedElementSum(tables?.["To Do"], "todoCategory")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.["To Do"]?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    relatedTo: () => ({
      content: Object?.entries(
        repeatedElementSum(tables?.["To Do"], "relatedTo")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.["To Do"]?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    todoUser: () => ({
      content: Object?.entries(
        repeatedElementSum(tables?.["To Do"], "todoUser")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.["To Do"]?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
  };
};
