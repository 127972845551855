export const todos = (updatedDynamicStates, IdHandler, programFields) => {
  const todoStatus = (todoStatus) => {
    return (
      programFields
        ?.find((a) => a?.fieldName === "Status colors")
        ?.fieldOptions?.["To Do"]?.find(
          (b) => b?.statusName?.toLowerCase() === todoStatus?.toLowerCase()
        )?.statusColor || "#6D8299"
    );
  };

  return {
    todoStatus: () => ({
      title: "To Do Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "To Do",
        field: "todoStatus",
        secondaryField: "todoName",
        filterField: [],
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.todoStatus,
            value: a?.todoName,
            color: todoStatus(a?.todoStatus),
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "795px",
    }),

    todoCategory: () => ({
      title: "To Do Category",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "To Do",
        field: "todoCategory",
        secondaryField: "todoName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.todoName,
            value: a?.todoCategory,
            color: todoStatus(a?.todoStatus),
            date: a.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    relatedTo: () => ({
      title: "Related To",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "To Do",
        field: "relatedTo",
        secondaryField: "todoName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.todoName,
            value: a?.relatedTo,
            color: todoStatus(a?.todoStatus),
            date: a.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    todoUser: () => ({
      title: "To Do User",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "To Do",
        field: "todoUser",
        secondaryField: "todoName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.todoName,
            value: a?.todoUser,
            color: todoStatus(a?.todoStatus),
            date: a.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
