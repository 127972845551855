import { getRandomColor } from "../../../../utils"
import uniq from "lodash/uniq"

export const addons = ({ tables }) => {
  return {
    amount: () => ({
      labels: uniq(
        tables?.Estimations?.map((a) => a?.services)
          ?.flat(1)
          ?.map((b) => b?.label)
      ),
      data: uniq(
        tables?.Estimations?.map((a) => a?.services)
          ?.flat(1)
          ?.map((b) => b?.label)
      )
        ?.map((status) =>
          tables?.Estimations?.map((c) => c?.services)
            ?.flat(1)
            ?.filter((d) => d?.label === status && status)
        )
        ?.map((e) => e?.map((f) => f?.serviceOptions))
        ?.map((g) =>
          g
            ?.flat(2)
            ?.map((h) => h?.items)
            ?.flat(1)
            ?.map((i) => parseInt(i?.price) || 0)
            ?.reduce((c, p) => c + p, 0)
        ),
      backgroundColor: tables?.Estimations?.map((a) => a?.services)
        ?.flat(1)
        ?.map(() => getRandomColor()),
    }),
  } //"Add-ons"
}
