import React from "react";
import { Stepper } from "../../Stepper";
import { stepForms } from "../forms";
import { removeSpacesFromString, replaceCreateFromTitle } from "../helpers";

const EmptyComponent = () => <></>;

const AutomateSteps = ({
  form,
  category,
  inputData,
  savedRecord,
  projectData,
  currentStep,
  setInputData,
  selectedSteps,
  setCurrentStep,
}) => {
  const populatedSteps = selectedSteps.map((step) => ({
    title: step.name,
    category,
    Comp:
      stepForms[removeSpacesFromString(replaceCreateFromTitle(step.name))] ||
      EmptyComponent,
    disabled: true,
    form,
    inputData,
    projectData,
    savedRecord,
    setInputData,
  }));

  return (
    <div className="step-stepper">
      <Stepper
        data-testid="stepper"
        {...{
          inputData,
          currentStep,
          setCurrentStep,
          steps: populatedSteps,
        }}
      />
    </div>
  );
};

export default AutomateSteps;
