import { useState, useMemo, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import { multipleDispatchesColumns } from "../../data";
import { MondayButton } from "src/components/commonComponents";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { InfoIconBlue } from "src/components/Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

import "./MultipleDispatchAddresses.scss";

/**
 * @typedef MatchDataItem
 * @property {string} id
 * @property {string} name
 * @property {string} address
 * @property {LatLng} [position]
 * @property {GeoFenceInfo[]} [geoFenceInfo]
 * @property {boolean} [isNameSameAsAddress=true]
 */

/**
 * @param {Object} props
 * @param {boolean} props.open
 * @param {() => any} props.onCancel
 * @param {MatchDataItem[]} props.multipleOptionsData
 * @param {(project: MatchDataItem) => any} props.onConfirm
 */
function MultipleDispatchAddresses({
  open,
  onCancel,
  multipleOptionsData,
  onConfirm,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedProject, setSelectedProject] = useState(
    /** @type {MatchDataItem} */ (undefined)
  );

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const gridContainerRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableColResize: true,
      sortable: true,
      filter: true,
      flex: 1,
      enableRowGroup: true,
      suppressSizeToFit: true,
      minWidth: 130,
      suppressDragLeaveHidesColumns: false,
      enablePivot: false,
    };
  }, []);

  const onFirstDataRendered = useCallback(({ columnApi }) => {
    columnApi.sizeColumnsToFit(gridContainerRef.current.clientWidth);
  }, []);

  const onSelectionChanged = useCallback((event) => {
    const selection = event.api.getSelectedNodes();
    if (selection.length) {
      setSelectedProject(selection[0]["data"]);
    } else {
      setSelectedProject(undefined);
    }
  }, []);

  return (
    <Modal
      {...{
        open,
        onCancel,
        closable: false,
        maskClosable: false,
        keyboard: false,
        centered: true,
        className: `multiple-dispatch-addresses-modal ${
          isDarkMode ? "multiple-addresses-dark" : ""
        }`,
        title: "Project Choice",
        footer: [
          <MondayButton
            key="confirm"
            {...{
              Icon: <TickIcon />,
              disabled: !selectedProject,
              onClick() {
                onConfirm(selectedProject);
                onCancel();
              },
            }}
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <div className="info-section">
        <InfoIconBlue />
        <span className="info-text">
          There are {multipleOptionsData.length} projects found with similar
          addresses, please select one of these options
        </span>
      </div>
      <div className="multiple-dispatches-grid">
        <div
          ref={gridContainerRef}
          className={
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }
        >
          <AgGridReact
            {...{
              rowData: multipleOptionsData,
              columnDefs: multipleDispatchesColumns,
              rowSelection: "single",
              defaultColDef,
              onFirstDataRendered,
              onSelectionChanged,
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default MultipleDispatchAddresses;
