import { Modal } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useState, useEffect, useContext, useCallback } from "react";

import { columnDefs } from "./JobsiteMatchData";
import { groupEntriesInShifts } from "../utils";
import DegModalContext from "../DegModalContext";
import { MondayButton } from "../../../../../../../commonComponents";
import OverviewFilterModal from "../OverviewFilter/OverviewFilterModal";
import createPDF from "../../../../../../../../integrations/AgGridToPdf";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import { compareIncluding } from "../../../../../../../SidebarPages/utils";
import { FilterIcon } from "../../../../../../../SidebarPages/BasePage/src";
import { ExcelIconWhite, PdfWhiteIcon } from "../../../../../../../../assets";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

import "./JobsiteMatchModal.scss";

let matchExternalFilter = {
  selectedEmployees: [],
  selectedJobsites: [],
  timeFrame: undefined,
};

function JobsiteMatchDetails({ open, onCancel }) {
  const { base64 } = useSelector((state) => state.base64);

  const { analytics, crews, jobsites, degName, isDarkMode } =
    useContext(DegModalContext);

  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [filterOpen, setFilterOpen] = useState(false);
  const [rowData] = useState(
    groupEntriesInShifts({ analytics, crews, jobsites })
  );

  const location = useLocation();

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    setColumnApi(param.columnApi);
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    return (
      !!matchExternalFilter.selectedEmployees?.length ||
      !!matchExternalFilter.selectedJobsites?.length ||
      !!matchExternalFilter.timeFrame
    );
  }, [matchExternalFilter]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      let employeeCondition = true;
      let jobsiteCondition = true;
      let timeCondition = true;

      if (!!matchExternalFilter.selectedEmployees?.length) {
        employeeCondition = matchExternalFilter.selectedEmployees?.includes(
          data?.employeeId
        );
      }

      if (!!matchExternalFilter.selectedJobsites?.length) {
        jobsiteCondition = matchExternalFilter.selectedJobsites.includes(
          data?.jobsiteMatch?.jobsiteId
        );
      }

      if (!!matchExternalFilter.timeFrame) {
        let start = dayjsNY(matchExternalFilter.timeFrame[0])
          .startOf("D")
          .valueOf();
        let end = dayjsNY(matchExternalFilter.timeFrame[1])
          .startOf("D")
          .valueOf();
        timeCondition = data?.punchDate
          ? dayjsNY(data?.punchDate).startOf("D").valueOf() >= start &&
            dayjsNY(data?.punchDate).startOf("D").valueOf() <= end
          : data?.firstClockIn >= start && data?.firstClockIn <= end;
      }

      return employeeCondition && jobsiteCondition && timeCondition;
    },
    [matchExternalFilter]
  );

  function exportToExcel() {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        processCellCallback(params) {
          const headerName = params?.column?.userProvidedColDef?.headerName;
          if (headerName === "Clock In" || headerName === "Clock Out") {
            return dayjsNY(params?.value).format(
              `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
            );
          }

          return params?.value;
        },
      });
    }
  }

  function exportToPdf() {
    return createPDF({
      action: "download",
      gridApi: gridApi,
      rowData,
      history: location?.pathname?.split("/")?.[1],
      historyTab: location?.pathname,
      gridColumnApi: columnApi,
      base64,
      masterDetail: true,
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: degName || "New DEG",
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  }

  useEffect(() => {
    return () => {
      matchExternalFilter = {
        selectedEmployees: [],
        selectedJobsites: [],
        timeFrame: undefined,
      };
    };
  }, []);

  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        ["data-testid"]: "jobsite-match-modal",
        closeIcon: <XIcon />,
        title: "Jobsite Match Info",
        ["data-testid"]: "jobsite-match-modal",
        className: `jobsiteMatchDetailsModal ${
          isDarkMode && "jobsiteMatchDetailsModalDark"
        }`,
        footer: [
          <MondayButton
            Icon={<XIcon />}
            className="mondayButtonRed"
            data-testid="close-jobsite-match-modal-btn"
            onClick={onCancel}
          >
            Close
          </MondayButton>,
        ],
      }}
    >
      <div className="modalActions">
        <MondayButton
          {...{
            className: "mondayButtonBlue",
            ["data-testid"]: "jobsite-match-filter-btn",
            Icon: <FilterIcon />,
            onClick() {
              setFilterOpen(true);
            },
          }}
        >
          Filter
        </MondayButton>
        <MondayButton
          {...{
            Icon: <ExcelIconWhite height={15} width={15} />,
            className: "mondayButtonBlue",
            onClick: exportToExcel,
          }}
        >
          {""}
        </MondayButton>
        <MondayButton
          {...{
            Icon: <PdfWhiteIcon height={15} width={15} />,
            className: "mondayButtonBlue",
            onClick: exportToPdf,
          }}
        >
          {""}
        </MondayButton>
      </div>
      <div
        className={`grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            columnDefs: columnDefs,
            onGridReady,
            isExternalFilterPresent,
            doesExternalFilterPass,
            pagination: true,
            paginationPageSize: 15,
            animateRows: true,
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
      {filterOpen && (
        <OverviewFilterModal
          {...{
            open: filterOpen,
            onCancel() {
              setFilterOpen(false);
            },
            onFilter(param) {
              matchExternalFilter = { ...param };
              gridApi?.onFilterChanged?.();
            },
            rowData,
            matchDetails: true,
            filter: matchExternalFilter,
            isDarkMode,
          }}
        />
      )}
    </Modal>
  );
}

export default JobsiteMatchDetails;
