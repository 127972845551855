import { Button, Modal } from "antd";
import "../SignupComp.scss";
import { CloseOutlined } from "@ant-design/icons";

const WarningSignupModal = ({
  visible,
  setVisible,
  title,
  warningMessage,
  destroyOnClose = true,
  maskClosable = true,
  closable = false,
  isDarkMode,
}) => {
  return (
    <Modal
      wrapClassName={`WarningSignupModal ${
        isDarkMode && "WarningSignupModalDark"
      }`}
      open={visible}
      destroyOnClose={destroyOnClose}
      closable={closable}
      closeIcon={<CloseOutlined />}
      footer={null}
      maskClosable={maskClosable}
    >
      <div className="warningHeader">
        <svg
          width="42"
          height="37"
          viewBox="0 0 42 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.58674 36.8772C1.02106 36.8772 0.588478 36.628 0.289 36.1294C-0.0104773 35.6309 -0.0104773 35.1323 0.289 34.6338L19.6552 1.23169C19.9547 0.733156 20.3873 0.483887 20.953 0.483887C21.5186 0.483887 21.9512 0.733156 22.2507 1.23169L41.6169 34.6338C41.9164 35.1323 41.9164 35.6309 41.6169 36.1294C41.3174 36.628 40.8849 36.8772 40.3192 36.8772H1.58674ZM21.1526 14.3433C20.72 14.3433 20.3623 14.4845 20.0795 14.767C19.7966 15.0495 19.6552 15.4068 19.6552 15.8389V24.0149C19.6552 24.447 19.7966 24.8043 20.0795 25.0868C20.3623 25.3693 20.72 25.5105 21.1526 25.5105C21.5852 25.5105 21.9429 25.3693 22.2257 25.0868C22.5086 24.8043 22.65 24.447 22.65 24.0149V15.8389C22.65 15.4068 22.5086 15.0495 22.2257 14.767C21.9429 14.4845 21.5852 14.3433 21.1526 14.3433ZM21.1526 31.0443C21.5852 31.0443 21.9429 30.9031 22.2257 30.6206C22.5086 30.3381 22.65 29.9808 22.65 29.5487C22.65 29.1166 22.5086 28.7593 22.2257 28.4768C21.9429 28.1943 21.5852 28.0531 21.1526 28.0531C20.72 28.0531 20.3623 28.1943 20.0795 28.4768C19.7966 28.7593 19.6552 29.1166 19.6552 29.5487C19.6552 29.9808 19.7966 30.3381 20.0795 30.6206C20.3623 30.9031 20.72 31.0443 21.1526 31.0443Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="warningBody">
        <h3>{title}</h3>
        <p>{warningMessage}</p>
        <Button
          onClick={() => {
            setVisible(false);
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
export default WarningSignupModal;
