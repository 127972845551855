import { defaultExcelHeaderStyle } from "../../../../../helpers/constants/defaultExcelHeaderStyle";

export const tableHeaders = [
  "Category",
  "Description",
  "Created At",
  "Priority",
];

export const tableHeadersExcel = [
  { v: "Category", s: defaultExcelHeaderStyle },
  { v: "Description", s: defaultExcelHeaderStyle },
  { v: "Created At", s: defaultExcelHeaderStyle },
  { v: "Priority", s: defaultExcelHeaderStyle },
];
