import { createContext } from "react";

/**
 * @typedef analytics
 * @property {object} employeesHoursPerDay
 * @property {object} employeeIncorrectShifts
 * @property {object} employeeOver24HShift
 * @property {object} employeeOverheadShifts
 * @property {object} employeeWeekTotals
 * @property {Array} employeeNotFoundLocations
 * @property {Array} dateRange
 * @property {Array} shiftsWithMultipleJobsites
 * @property {Array} missingLunchEntries
 * @property {number} generalOverheadTotal
 */

/**
 * @typedef AnalyticsChartsContextType
 * @property {Array} crews
 * @property {Array} layout
 * @property {function} setLayout
 * @property {function} onHide
 * @property {object} dataSets
 * @property {Array} jobsites
 * @property {function} onRemove
 * @property {boolean} isDarkMode
 * @property {function} saveLayout
 * @property {Array} hiddenCards
 * @property {object} filtersData
 * @property {function} clearFilters
 * @property {analytics} analyticsData
 * @property {function} setFilterOpen
 * @property {function} setHiddenCards
 * @property {Array} selectedDataOptions
 */

/**@type {AnalyticsChartsContextType} */
const initialValue = {};
const AnalyticsChartsContext = createContext(initialValue);

export default AnalyticsChartsContext;
