import { useState } from "react";
import { Tooltip, Tour } from "antd";
import { useSelector } from "react-redux";
import { QuestionCircleFilled } from "@ant-design/icons";

import {
  tourTitle,
  PlayVideoTutorial,
} from "../../../components/commonComponents/CustomModalHeader/CustomModalHeader";
import { MondayButton } from "../../../components/commonComponents";

const TourGuide = () => {
  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);

  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "General Tutorials")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "User Creation"
    )?.videoLink;

  const steps = programFields
    ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
    ?.fieldOptions?.find(
      ({ categoryName }) => categoryName === "createUser"
    )?.steps;

  function stepsMapHelper(title) {
    const stepsMap = {
      "First Name": document.querySelector("#firstName"),
      "Last Name": document.querySelector("#lastName"),
      Department: document.querySelector("#department"),
      Role: document.querySelector("#role"),
      Phone: document.querySelector(".react-tel-input"),
      Email: document.querySelector("#username"),
      Password: document.querySelector("#password"),
      "Confirm Password": document.querySelector("#confirmPassword"),
      Create: document.querySelector(".formButtonElement"),
      "One Time Password": document.querySelector(".otpInput"),
      Finish: document.querySelector(".formButtonElement"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      if (step.title === "Create") {
        return {
          title: tourTitle(step?.title, () => {
            setShowVideoTutorial(true);
            setTourOpen(false);
          }),
          description: step?.description,
          target: () => stepsMapHelper(step?.title),
          className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
          nextButtonProps: { onClick: () => setTutorial(true) },
        };
      } else if (step.title === "Finish") {
        return {
          title: tourTitle(step?.title, () => {
            setShowVideoTutorial(true);
            setTourOpen(false);
          }),
          description: step?.description,
          target: () => stepsMapHelper(step?.title),
          className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
          nextButtonProps: { onClick: () => setTutorial(false) },
          onClose: () => {
            setTutorial(false), setTourOpen(false);
          },
        };
      } else if (step.title === "One Time Password") {
        return {
          title: tourTitle(step?.title, () => {
            setShowVideoTutorial(true);
            setTourOpen(false);
          }),
          description: step?.description,
          target: () => stepsMapHelper(step?.title),
          className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
          prevButtonProps: { onClick: () => setTutorial(false) },
          onClose: () => {
            setTutorial(false), setTourOpen(false);
          },
        };
      }
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  return (
    <>
      <Tooltip title="Guide">
        <MondayButton
          className="mondayButtonBlue tourButton"
          onClick={() => setTourOpen(true)}
          Icon={<QuestionCircleFilled />}
        ></MondayButton>
      </Tooltip>

      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}

      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Documentation Tutorial",
          }}
        />
      )}
    </>
  );
};

export default TourGuide;
