import { useRef, useState, useCallback } from "react";
import { Line } from "react-chartjs-2";
import { useFilteredHistory } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/Hooks/useFilteredHistory";
import "./LineChartFull.scss";
import { SmallLoadableComp } from "../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import GraphTooltip from "../BarChart/GraphTooltip";
import { Result } from "antd";

const LineChartFull = ({ params }) => {
  const chartRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPos, setTooltipPos] = useState(null);
  const { table, field, labels, custom } = params;
  const history = useFilteredHistory(table);
  let loading = true;
  params?.datasets?.forEach?.((el) => {
    el?.data?.forEach?.((el) => {
      if (el === undefined) {
        loading = true;
      } else {
        loading = false;
      }
    });
  });

  const customTooltip = useCallback((context) => {
    if (context.tooltip.opacity == 0) {
      // hide tooltip visibilty
      setTooltipVisible(false);
      return;
    }

    const chart = chartRef.current;
    const canvas = chart.canvas;
    if (canvas) {
      // enable tooltip visibilty
      setTooltipVisible(true);

      // set position of tooltip
      const left = context.tooltip.x;
      const top = context.tooltip.y;

      // handle tooltip multiple rerender
      if (tooltipPos?.top != top) {
        setTooltipPos({ top: top, left: left });
        setTooltipData(context.tooltip);
      }
    }
  });

  const options = {
    tooltips: {
      mode: "dataset",
    },
    plugins: {
      tooltip: !!custom
        ? {
            enabled: false,
            external: customTooltip,
          }
        : {
            enabled: true,
          },
      legend: {
        onHover: (event, chartElement) => {
          event.native.target.style.cursor = "pointer";
        },
        onLeave: (event, chartElement) => {
          event.native.target.style.cursor = "default";
        },
        display: params?.datasets?.length > 1 ? true : false,
      },
      datalabels: {
        display: false,
      },
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
    scales: {
      y: {
        ticks:
          params.field === "totalAmount" || params.field === "totalPrice"
            ? {
                callback: function (tick) {
                  return "$" + tick.toLocaleString();
                },
              }
            : {},
      },
    },
    animation: true,

    onClick: (_, clickedBarData) =>
      history({
        [field === "leadAddress" ? "borough" : field]: [
          labels[clickedBarData[0]?.index],
        ],
      }),
  };

  return (
    <div className="LineChartBodyStyle">
      <div>
        {!params?.noFunction ? (
          <SmallLoadableComp loading={loading}>
            <Line
              className="LineChart"
              width={300}
              data={params}
              options={options}
              ref={chartRef}
            />
            {tooltipPos && (
              <GraphTooltip
                data={tooltipData}
                position={tooltipPos}
                visibility={tooltipVisible}
                custom={custom}
                table={params?.table}
              />
            )}
          </SmallLoadableComp>
        ) : (
          <Result
            status="warning"
            title={`There are no matching pattern for this chart `}
            subTitle={`Try removing and recreating this chart. If this reoccurs, please contact the Software Department.`}
          />
        )}
      </div>
    </div>
  );
};

export default LineChartFull;
