import { DatePicker } from "antd";
import moment from "moment";
import "./DatePicker.scss";
// import dayjs from "dayjs";
import { CalendarIcon } from "../../components/FilterView/FilterIcons";
// import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { parseInTz } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const FormItemDatePicker = ({
  value = "",
  form,
  name,
  disabledDate = undefined,
  disabled,
  onChange,
  placeholder = "",
  isDarkMode,
}) => {
  const disabledComponent = () => {
    if (name === "proposalSentDate") {
      return (
        document
          ?.getElementsByClassName("ant-steps-item-active")[0]
          ?.getElementsByClassName("ant-steps-item-title")[0]?.innerHTML !==
        "Proposal Sent"
      );
    }
    //? handled in DynamicEstimationView
    //  else if (name === "sentToClientDate") {
    //   return (
    //     document
    //       ?.getElementsByClassName("ant-steps-item-active")[0]
    //       ?.getElementsByClassName("ant-steps-item-title")[0]?.innerHTML !==
    //     "Sent To Client"
    //   );
    // }
    else {
      return disabled;
    }
  };

  return (
    <div className={`EditDatePicker ${isDarkMode && "EditDatePickerDark"}`}>
      <DatePicker
        className="EditDatePickerComponent"
        popupClassName={`filter-by-date-picker-dropdown ${
          isDarkMode && "dateDropDownDark"
        }`}
        disabled={disabledComponent()}
        suffixIcon={<CalendarIcon fill="#333238" />}
        disabledDate={disabledDate}
        placeholder={placeholder}
        value={
          moment(value).format("MM/DD/YYYY").toString() !== "Invalid date"
            ? parseInTz(value)
            : null
        }
        defaultValue={
          moment(value).format("MM/DD/YYYY").toString() !== "Invalid date"
            ? parseInTz(value)
            : null
        }
        onChange={(e) => {
          if (onChange === undefined) {
            form.setFieldsValue({
              // [name]: e?.toString() ? dayjsNY(e).unix() * 1000 : "",
              [name]: e?.toString() ? parseInTz(e).valueOf() : "", // Set input date to NY time zone
            });
          } else {
            // onChange(dayjsNY(e).unix() * 1000);
            onChange(!e ? "" : parseInTz(e).valueOf()); // Set input date to NY time zone
          }
        }}
        format="MM/DD/YYYY"
      />
    </div>
  );
};
export default FormItemDatePicker;
