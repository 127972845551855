// import { Button } from "antd";
import { onClickFormHandler } from "../../../../utils";
import { nameFirstDigits } from "../../../../utils/nameFirstDigits";
import dayjs from "dayjs";
import { compareIncluding } from "../../../SidebarPages/utils";
import { AnimatedCheckBox } from "../../../SidebarPages/Fleet/components/InputComponent/components";
import { Tooltip } from "antd";

export const footerButtons = ({
  onCancel,
  onSave,
  onOptionClick,
  suffix,
  saving,
  saveOnly = false,
  DOBprop,
  preventContinue,
}) => {
  const onClick = onClickFormHandler(saving);
  let secondOption = preventContinue ? "Finish" : "Save & Close";
  return [
    {
      text: "Cancel",
      cancel: true,
      onClick: onCancel,
      className: "mondayButtonRed",
      tooltipCategory: "Leads",
      tooltipKey: "cancel",
    },

    {
      text: preventContinue ? "Save & Close" : "Finish",
      primary: true,
      onClick: onClick(onSave),
      disabled: !!saving,
      dropdownDisabled: !!saveOnly || !!saving,
      options:
        DOBprop === true ? ["Save & Close"] : ["Save & New", secondOption],
      onOptionClick: onClick(onOptionClick),
      className: "mondayButtonBlue saveButton",
      suffix: suffix,
      tooltipCategory: "Leads",
      tooltipKey: "finish",
    },
  ];
};
export const PERSONAL_INFORMATION = "Personal Information";
export const BUSINESS_ADDRESS = "Business Address";
export const BILLING_ADDRESS = "Billing Address";
export const SHIPPING_ADDRESS = "Shipping Address";

export const fieldsJSON = ({
  sources,
  salesPerson,
  recordTypes,
  statusTypes,
  industryTypes,
  roles,
  onDeselect,
  accounts,
  onChange,
  onSelect,
  leadNames,
  projectExecutives,
  leadTeamSelect,
  prequalificationStages,
  preQualificationOnChange,
  mandatoryPreQualificationStages,
  accessToken,
  preQualificationDisabled,
  onPickerSuccess,
  fileNames,
  parentId,
  checkShabbat,
  setCheckShabbat,
  form,
  existingLeadsOptions,
  setExistingLeadsOptions,
}) => [
  // {
  //   formItemName: "leadRecordType",
  //   type: "radio",
  //   options: recordTypes,
  // },
  {
    category: PERSONAL_INFORMATION,
    fields: [
      {
        className: "leadCompanyName",
        label: "Company Name",
        formItemName: "leadCompany",
        placeholder: "Company Name",
        type: "autocomplete",
        required: true,
        customOptions: [
          form?.getFieldValue("leadCompany")?.length > 2 && {
            label: "Existing Leads",
            options: existingLeadsOptions,
          },
        ].filter(Boolean),
        onSearch: (value) => {
          if (value.length > 1) {
            setExistingLeadsOptions(
              leadNames
                .filter((record) =>
                  compareIncluding(record?.leadCompany, value)
                )
                .sort((a, b) => {
                  return a?.leadCompany?.length - b?.leadCompany?.length;
                })
                .map(({ leadCompany, leadId }) => ({
                  value: leadCompany,
                  label: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {leadCompany}
                      <a href={`/leads/${leadId}`} target="_blank">
                        Go To
                      </a>
                    </div>
                  ),
                  disabled: true,
                }))
            );
          } else {
            setExistingLeadsOptions(
              leadNames?.map(({ leadCompany, leadId }) => ({
                value: leadCompany,
                disabled: true,
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {leadCompany}
                    <a href={`/leads/${leadId}`} target="_blank">
                      Go To
                    </a>
                  </div>
                ),
              }))
            );
          }
        },
        rules: [
          {
            validator: (_, value) =>
              leadNames?.some(
                (data) =>
                  data?.leadCompany?.toLowerCase()?.replace(/\s+/g, "") ===
                  value?.toLowerCase()?.replace(/\s+/g, "")
              )
                ? Promise.reject(
                    new Error(
                      "There is an existing Lead/Client with this company name!"
                    )
                  )
                : Promise.resolve(),
          },
          {
            validator: (_, value) =>
              accounts?.some(
                ({ accountName }) =>
                  accountName?.toLowerCase()?.trim() ===
                  value?.toLowerCase()?.trim()
              )
                ? Promise.reject(
                    new Error(
                      "There is an existing Lead/Client with this company name!"
                    )
                  )
                : Promise.resolve(),
          },
        ],
      },
      {
        label: "First Name",
        formItemName: "leadFirstName",
        placeholder: "First Name",
        type: "input",
      },
      {
        label: "Middle Name",
        formItemName: "leadMiddleName",
        placeholder: "Middle Name",
        type: "input",
      },
      {
        label: "Last Name",
        formItemName: "leadLastName",
        placeholder: "Last Name",
        type: "input",
      },

      {
        label: "Email",
        formItemName: "leadEmail",
        placeholder: "Email",
        type: "input",
        rules: [{ type: "email", message: "Please enter a valid email" }],
      },
      {
        label: "Role",
        formItemName: "leadRole",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        mode: "tags",
        options: roles,
        maxTagCount: 2,
        maxTagPlaceholder: (e) => {
          return (
            <Tooltip title={e.map(({ label }) => label).join(", ")}>
              <span>{`+ ${e.length} more`}</span>
            </Tooltip>
          );
        },
      },
      // {
      //   label: "Lead Status",
      //   formItemName: "leadStatus",
      //   initialValue: "New",
      //   placeholder: "Select...",
      //   type: "select",
      //   required: true,
      //   showSearch: true,
      //   options: statusTypes,
      // },
      {
        label: "Mobile",
        formItemName: "leadMobile",
        placeholder: "Mobile",
        type: "phone",
      },
      {
        label: "Phone",
        formItemName: "leadPhone",
        placeholder: "Phone",
        type: "phone",
      },
      {
        label: "Phone Extension",
        formItemName: "phoneExtension",
        placeholder: "Phone Extension",
        typeNumber: true,
      },
      {
        label: "Fax",
        formItemName: "leadFax",
        placeholder: "Fax",
        type: "phone",
      },
      {
        label: "Website",
        formItemName: "leadWebsite",
        placeholder: "Website",
        rules: [
          {
            pattern:
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/,
            message: "This is not a valid website",
          },
        ],
      },
      {
        label: "Industry",
        formItemName: "leadIndustry",
        placeholder: "Select...",
        type: "select",
        initialValue: "Constructions",
        showSearch: true,
        options: industryTypes,
      },
      {
        label: "Lead Source",
        formItemName: "leadSource",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        options: sources,
      },
      {
        label: "P. Executive",
        formItemName: "projectExecutive",
        placeholder: "Project Executive",
        type: "select",
        showSearch: true,
        options: projectExecutives,
      },
      {
        label: "Sales Person",
        formItemName: "leadSalePerson",
        placeholder: "Select...",
        mode: "tags",
        type: "select",
        showSearch: true,
        options: salesPerson,
      },
      {
        label: "Team",
        formItemName: "leadTeam",
        placeholder: "Select team...",
        mode: "multiple",
        type: "select",
        showSearch: true,
        customOptions: leadTeamSelect,
        onSelect: onSelect,
        onDeselect: onDeselect,
        required: true,
        allowClear: false,
        maxTagCount: 2,
        maxTagPlaceholder: (e) => {
          return (
            <Tooltip title={e.map(({ label }) => label).join(", ")}>
              <span>{`+ ${e.length} more`}</span>
            </Tooltip>
          );
        },
      },
      {
        label: "Pre-qualification Stages",
        formItemName: "preQualificationStages",
        placeholder: "Select stage...",
        type: "select",
        showSearch: true,
        options: prequalificationStages,
        onSelect: (e) => preQualificationOnChange(e),
        onDeselect: (e) => preQualificationOnChange(e),
        className: "lead-pre-qualification-stages",
      },
      {
        label: "Pre-qualification website",
        formItemName: "preQualificationWebsite",
        placeholder: "Pre-qualification website",
        type: "input",
        required: mandatoryPreQualificationStages,
        disabled: preQualificationDisabled,
        rules: [
          {
            pattern:
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/,
            message: "This is not a valid website",
          },
        ],
      },
      {
        label: "Pre-qualification date",
        type: "dateinput",
        formItemName: "preQualificationDate",
        required: mandatoryPreQualificationStages,
        disabled: preQualificationDisabled,
        defaultValue: null,
        form,
      },
      {
        label: "Pre-qualification upload",
        type: "gPicker",
        accessToken,
        uploaderId: "Pre-qualification upload",
        onPickerSuccess,
        fileNames,
        parentId,
        disabled: preQualificationDisabled,
        required: mandatoryPreQualificationStages,
      },
      // {
      //   label: "Shabbat Restrictions",
      //   formItemName: "jewishClient",
      //   type: "checkbox",
      // },
      {
        customComponent: AnimatedCheckBox,
        componentProps: {
          primaryStyle: checkShabbat
            ? { backgroundColor: "#5fb13b" }
            : { backgroundColor: "#0060b9" },
          label: "Shabbat Restrictions",
          initialValue: checkShabbat,
          checkBoxProps: { onChange: (e) => setCheckShabbat(e) },
        },
      },
      // {
      //   label: "Lead Note",
      //   formItemName: "leadNote",
      //   placeholder: "Note",
      //   type: "textarea",
      //   rows: [2],
      //   style: { minWidth: "100%" },
      // },
    ],
  },
];

export const body = ({ form, authenticatedUser = {}, checkShabbat }) => ({
  borough: form.getFieldValue("billingAddCity"),
  leadRecordType: form.getFieldValue("leadRecordType"),
  leadFirstName: form.getFieldValue("leadFirstName"),
  leadMiddleName: form.getFieldValue("leadMiddleName"),
  leadLastName: form.getFieldValue("leadLastName"),
  leadCompany: form.getFieldValue("leadCompany"),
  leadIndustry: form.getFieldValue("leadIndustry"),
  leadSalePerson: form.getFieldValue("leadSalePerson"),
  leadRole: form.getFieldValue("leadRole"),
  leadEmail: form.getFieldValue("leadEmail"),
  leadPhone: form.getFieldValue("leadPhone"),
  leadTeam: form.getFieldValue("leadTeam"),
  leadMobile: form.getFieldValue("leadMobile"),
  leadSource: form.getFieldValue("leadSource"),
  jewishClient: checkShabbat,
  // leadStatus: form.getFieldValue("leadStatus"),
  preQualificationStage: form.getFieldValue("preQualificationStages"),
  preQualificationWebsite: form.getFieldValue("preQualificationWebsite"),
  preQualificationDate: !!form.getFieldValue("preQualificationDate")
    ? dayjs(form.getFieldValue("preQualificationDate"), "MM/DD/YYYY").valueOf() //dayjs(form.getFieldValue("preQualificationDate")).valueOf()
    : null,
  leadAddress: form.getFieldValue("primaryAddress"),
  aptNumber: form.getFieldValue("aptNumber"),
  shippingAddApartmentNr: form.getFieldValue("shippingAddApartmentNr"),
  leadAlternativeAddress: form.getFieldValue("secondaryAddress") || "",
  billingCoordinates: form.getFieldValue("billingCoordinates"),
  shippingCoordinates: form.getFieldValue("shippingCoordinates"),
  phoneExtension: form.getFieldValue("phoneExtension"),
  projectExecutive: form.getFieldValue("projectExecutive"),
  leadFax: form.getFieldValue("leadFax"),
  leadWebsite: form.getFieldValue("leadWebsite"),
  // leadNotes: !!form.getFieldValue("leadNote") && [
  //   {
  //     id: 0,
  //     repliesVisibility: false,
  //     author:
  //       authenticatedUser?.given_name + " " + authenticatedUser?.family_name,
  //     comment: `<p>${form.getFieldValue("leadNote")}</p>`,
  //     published: Date.now(),
  //     logo: nameFirstDigits(
  //       authenticatedUser?.given_name.concat(
  //         " ",
  //         authenticatedUser?.family_name
  //       )
  //     ),
  //     replies: [],
  //     category: "General",
  //   },
  // ],
});
