import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import MondayButton from "../MondayButton/MondayButton";
import "./RichTextEditor.scss";
const RichTextEditor = ({
  value,
  onChange,
  disabled,
  initialValue,
  placeholder,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div className="richTextComp">
      <ReactQuill
        {...{
          value: value || initialValue,
          disabled,
          onChange: onChange,
          placeholder: placeholder || "",

          // skin: isDarkMode && "oxide-dark",
          // content_css: isDarkMode && "dark",
        }}
      />

      {/* <Editor
        init={{
          width: "100%",
          placeholder,
          plugins: "link image code insertdatetime",
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat insertdatetime",
        }}
        {...{
          value: value || initialValue,
          disabled,
          onEditorChange: onChange,
          skin: isDarkMode && "oxide-dark",
          content_css: isDarkMode && "dark",
        }}
      /> */}
    </div>
  );
};

export default RichTextEditor;
