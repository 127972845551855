import { camelToTitle } from "src/utils";
import { formatNumber } from "../../utils";
import { SideInfoType, MappedSectionType, FleetInspectionType } from "../types";
import { parseInTz } from "../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type InspectionKeys = Array<keyof FleetInspectionType>;

const defaultKeys: InspectionKeys = [
  "inspectionType",
  "driver",
  "inspectionStatus",
  "inspectionDetails",
  "mechanic",
];

const DATE_FORMAT = "MMM DD, YYYY";
const TIME_FORMAT = "hh:mm a";

function getSideInspectionInfo(
  inspection: FleetInspectionType,
  colors?: MappedSectionType,
  propKeys?: InspectionKeys
): SideInfoType {
  const config: SideInfoType = [];
  const keys = propKeys ?? defaultKeys;

  if (!inspection) {
    return config;
  }

  for (const property of keys) {
    const key = property as keyof FleetInspectionType;
    const label = camelToTitle(key);

    if (typeof inspection[key] === "object") {
      continue;
    }

    const data = {
      value: inspection[key] || "No Data",
    };

    if (key === "inspectionStatus") {
      if (!colors) {
        continue;
      }

      data["color"] =
        colors.statuses.find(
          ({ status }) => status === inspection.inspectionStatus
        )?.color || undefined;
    } else if (key === "createdAt" || key === "inspectionDate") {
      data["format"] = DATE_FORMAT;
      if (key === "inspectionDate") {
        data["value"] = parseInTz(data["value"], "MM/DD/YYYY").valueOf();
      }
    } else if (key === "driver") {
      data["value"] = inspection.driverInfo.driver;
      data["to"] = inspection.driverId;
    }

    config.push({
      label,
      //@ts-ignore
      data,
    });
  }

  if (keys.includes("inspectionDetails")) {
    const detailsIndex = keys.indexOf("inspectionDetails");

    for (let i = 0; i < inspection.inspectionDetails.length; i++) {
      const otherKey =
        Object.keys(inspection.inspectionDetails[i]).filter(
          (e) => e !== "label"
        )?.[0] || "";

      if (!otherKey) {
        continue;
      }

      const label = inspection.inspectionDetails[i]["label"];

      config.splice(detailsIndex + i, 0, {
        label,
        data: {
          value: label.includes("Odometer")
            ? formatNumber(inspection.inspectionDetails[i][otherKey], {
                unit: "mile",
              })
            : inspection.inspectionDetails[i][otherKey],
          format: label.includes("Date")
            ? DATE_FORMAT
            : label.includes("Time")
            ? TIME_FORMAT
            : undefined,
        },
      });
    }
  }

  return config;
}

export default getSideInspectionInfo;
