import { RedWarningModal } from "../../../../../../../commonComponents";

const RoleWarningModal = ({
  saveChanges,
  warningModalVisible,
  setWarningModalVisible,
  roles = false,
}) => {
  return (
    <RedWarningModal
      {...{
        visible: warningModalVisible,
        onCancel: () => setWarningModalVisible(false),
        titleText: "Save Access Changes?",
        footerProps: { onConfirm: saveChanges },
      }}
    >
      <p>
        Are you sure you want to save these changes to the{" "}
        {roles ? "role" : "user"}'s access settings? Please confirm to proceed
        with the updates.
      </p>
    </RedWarningModal>
  );
};

export default RoleWarningModal;
