import { getCoordinatesAndZip } from "./getCoordinatesAndZip";
import { secondaryIdentifiers } from "./secondaryIdentifiers";
import { getLatLngByAddress } from "../../utils";

//parses the address for the geocode API
//removes the non-standard # if present
const secondaryFormatter = (val = "") => {
  if (val?.includes("#")) {
    let tmp = val?.split("#");
    return tmp[0] + "apt " + tmp[1];
  } else {
    return val;
  }
};

//gets the address' exact coordinates
export const getCoordinates = async (address) => {
  if (address !== "" && address !== undefined) {
    let add = secondaryFormatter(address);
    return new Promise(async (resolve, reject) => {
      getLatLngByAddress(add)
        .then((response) => {
          resolve(response?.coordinates);
        })
        .catch((err) => {
          resolve({ lat: "", lng: "" });
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ lat: "", lng: "" });
    });
  }
};

//regulates the address when dealing with apartment numbers
export const aptNumberChangeHandler = (oldAddress = "", value) => {
  let tmpAddress = oldAddress;
  let returnAddress = "";

  if (oldAddress === "") {
    return "";
  }

  //if the apt value is empty we want to remove the identifier and the number
  if (value === "") {
    let tmp = "";
    secondaryIdentifiers.forEach((el) => {
      if (tmpAddress.includes(" " + el)) {
        returnAddress = tmpAddress.split(" " + el)[0];
        tmp = tmpAddress.split(" " + el)[1].split(",");
      }
    });
    tmp = tmp.slice(1);
    returnAddress = returnAddress.trim() + "," + tmp.join();

    return returnAddress;
  }

  let tmpIdentifier = " apt";
  let hasIdentifier = false;
  //check if the address already has an identifier
  secondaryIdentifiers.forEach((el) => {
    if (tmpAddress.includes(" " + el)) {
      hasIdentifier = true;
      tmpAddress = tmpAddress.split(el);
      tmpIdentifier = el;
    }
  });

  //we check if the identifier in typed in the aptNumber input
  let manualIdentifier = "";
  secondaryIdentifiers.forEach((el) => {
    if (value.includes(el)) {
      manualIdentifier = el;
    }
  });

  //if the address doesn't have the identifier
  !hasIdentifier && (tmpAddress = tmpAddress.split(","));

  //when we have the identifier, we have two elements in the array
  //we split the second element and get the number, we change it and we join the new array
  if (hasIdentifier) {
    let tmp = tmpAddress[1].split(",");
    tmp[0] = value;

    //if the identifier is input alongside the value, we only get the value
    if (manualIdentifier !== "") {
      tmp[0] = value.split(manualIdentifier)[1];
    }

    tmpAddress[1] = tmp.join(",");

    returnAddress = tmpAddress.join(tmpIdentifier + " ");

    //we replace the old identifier with the new one
    if (manualIdentifier !== "") {
      returnAddress = returnAddress.replace(tmpIdentifier, manualIdentifier);
    }
  } else {
    //in this case we need to push the value as the second element and then join
    tmpAddress.splice(1, 0, tmpIdentifier + " " + value);
    let tmp = tmpAddress.slice(1);
    returnAddress = tmpAddress[0] + " " + tmp.join(",");
  }

  return returnAddress;
};

//gets the address component based on address
export const getAddressComponent = async (
  address,
  firstCall = false,
  defaultApt = ""
) => {
  return new Promise(async (resolve, reject) => {
    let tmpObject = {
      address: "",
      aptNumber: "",
      city: "",
      state: "",
      street: "",
      zipCode: "",
    };
    if (address !== "" && address !== undefined) {
      const split = await getCoordinatesAndZip(address).catch((err) => {
        //handles non standard addresses
        resolve({
          address: address,
          aptNumber: defaultApt,
          city: "",
          state: "",
          street: "",
          zipCode: "",
        });
        return;
      });
      let addComponents = split?.address_components;
      let tmpStreet = "";
      let tmpCity = "";
      addComponents?.forEach((el) => {
        if (el.types.includes("subpremise")) {
          tmpObject["aptNumber"] = el.long_name;
        } else if (el.types.includes("street_number")) {
          tmpStreet = tmpStreet + el.long_name + " ";
        } else if (el.types.includes("route")) {
          //sometimes the addresses are written in their short form
          //so we need to account for that
          if (!address?.includes(el.long_name)) {
            tmpStreet = tmpStreet + el?.short_name;
          } else {
            tmpStreet = tmpStreet + el.long_name;
          }
        } else if (el.types.includes("locality")) {
          tmpCity = el.long_name;
        } else if (el.types.includes("sublocality_level_1")) {
          tmpObject["city"] = el.long_name;
        } else if (el.types.includes("administrative_area_level_1")) {
          tmpObject["state"] = el.long_name;
        }
      });
      tmpObject["address"] = address;
      tmpObject["street"] = tmpStreet;
      tmpObject["zipCode"] = split?.zipCode;

      //check wether the city was put under "locality" or "sublocality_level_1"
      if (!tmpObject["city"]) {
        tmpObject["city"] = tmpCity;
      }

      // if (!tmpObject?.aptNumber) {
      //   //if the apt number is not set in the address, we set the one ine the state
      //   tmpObject["aptNumber"] = defaultApt;
      // }
      firstCall && (tmpObject["aptNumber"] = defaultApt);
    }
    resolve(tmpObject);
  });
};

export function getWholeAddressFromComponents(addressComponents) {
  let address = "";
  for (const component of addressComponents) {
    let types = component?.types || [];

    let admLevel = types?.find((e) =>
      e?.includes("administrative_area_level_")
    );

    if (admLevel) {
      if (+admLevel?.replace("administrative_area_level_", "") > 1) {
        continue;
      }
    }

    if (types?.includes("country")) {
      address = address + " " + "USA";
      break;
    } else {
      address = address + " " + (component?.long_name || component?.short_name);
    }

    if (!(component?.types || []).includes("street_number")) {
      address = address + ",";
    }
  }

  return address.trim();
}
