import { v4 as uuidv4 } from "uuid";

export const INITIAL_FILTER_VALUES = [
  {
    id: uuidv4(),
    operator: "AND",
    conditions: [
      {
        id: uuidv4(),
        operator: "AND",
        column: "",
        value: "",
        formula: "is one of",
      },
    ],
  },
];
