import { useEffect, useState } from "react";
import { useEmailBox } from "../providers/EmailBoxProvider";
import { filterTables } from "../../../../../../utils";
import { determineCategoryType } from "../../../functions";

const useEmailTemplatesData = (
  invoice,
  proppedTemplateCat,
  exportOrEmailDropdown,
  rowData,
  recordDetails
) => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState({
    id: "",
    customBody: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryType = determineCategoryType(
        rowData,
        invoice,
        proppedTemplateCat,
        exportOrEmailDropdown,
        recordDetails
      );
      const res = await filterTables(
        "emailTemplates",
        "templateCategory",
        categoryType
      );

      setEmailTemplates(res);

      const defaultSelected = res?.find((temp) =>
        proppedTemplateCat === "Tutorial Videos"
          ? temp?.templateName.includes("Tutorial Video")
          : temp.templateName.includes("Default")
      );

      setSelectedEmailTemplate({
        id: defaultSelected?.templateId,
        customBody: null,
      });
    } catch (error) {
      console.error("Error fetching dynamic fields:", error);
    }
  };

  return {
    emailTemplates,
    setEmailTemplates,
    selectedEmailTemplate,
    setSelectedEmailTemplate,
    loading,
    setLoading,
  };
};

export default useEmailTemplatesData;
