import { Tooltip } from "antd";
import { useStateContext } from "../../../../../../../Context/Context";
import "./event-cell-renderer.scss";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

const EventCellRenderer = ({
  value,
  serviceLabel,
  estimationId,
  elevation,
}) => {
  // Context state
  const { errors, darkMode } = useStateContext();

  const hasEventError =
    errors &&
    errors[`${serviceLabel}/${estimationId}`]?.[
      `${elevation?.elevationId}/event`
    ];

  return (
    <div
      className={darkMode ? "event-cell-renderer-dark" : "event-cell-renderer"}
    >
      <div className="event-name">{value}</div>
      <div
        className="event-status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {!value ? (
          <Tooltip title={hasEventError || "Name must not be empty"}>
            <ExclamationCircleFilled style={{ color: "#FE4C4A" }} />
          </Tooltip>
        ) : (
          <CheckCircleFilled style={{ color: "#68C142" }} />
        )}
      </div>
    </div>
  );
};

export default EventCellRenderer;
