import "./SubTaskInEdit.scss";
import { useState } from "react";
import { Input, Tooltip } from "antd";
import DependencyInEdit from "./components/DependencyInEdit";
import {
  MondayButton,
  WithTooltip,
} from "../../../../../../../commonComponents";
import { TrashIcon, XIcon } from "../../../../../../Communication/assets";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";

export const SubTaskInEdit = ({
  updatedTask = {},
  setUpdatedTask,
  rowData,
}) => {
  const [subTask, setSubTask] = useState("");

  const { taskSubTasks = [] } = updatedTask;

  //Function that adds subtask
  const addSubTask = () => {
    const newSubTask = [
      ...taskSubTasks,
      {
        id: taskSubTasks.length,
        value: subTask,
        status: "To do",
        color: "#F9A52D",
        checked: false,
      },
    ];
    setUpdatedTask({ ...updatedTask, taskSubTasks: newSubTask });
    setSubTask("");
  };

  //Setting subtask as done
  const subTaskDone = (id, checked) => {
    setUpdatedTask({
      ...updatedTask,
      taskSubTasks: taskSubTasks.map((subTask) =>
        subTask.id === id
          ? {
              id: id,
              value: subTask.value,
              status: checked ? "To do" : "Done",
              color: checked ? "#F9A52D" : "#00ab66",
              checked: !checked,
            }
          : subTask
      ),
    });
  };

  const deleteSubTask = (id) => {
    const updatedSubTasks = taskSubTasks?.filter(
      (subTask) => subTask?.id !== id
    );
    setUpdatedTask({ ...updatedTask, taskSubTasks: updatedSubTasks });
  };

  return (
    <div className="subtask-depend-wrapper">
      <div className="subtasks-wrapper">
        <span className="subtasks-header-text">
          <b>* </b>
          <h3>Subtasks</h3>
        </span>
        <WithTooltip tooltipCategory="Tasks" tooltipKey="subTask">
          <Input
            className="leftSide__input"
            onChange={(e) => setSubTask(e.target.value)}
            value={subTask}
            placeholder="Create a sub task"
          />
        </WithTooltip>
        {!!subTask && (
          <div className="subtasks-buttons">
            <MondayButton onClick={() => setSubTask("")} Icon={<XIcon />}>
              Cancel
            </MondayButton>
            <MondayButton
              disabled={!subTask}
              onClick={() => addSubTask(true)}
              Icon={<TickIcon />}
            >
              Add
            </MondayButton>
          </div>
        )}
        <div className="subtasks-content-wrapper">
          {taskSubTasks?.map((subTask, index) => {
            const { id, value, checked, status } = subTask;
            return (
              <div className="modal__subAddElement" key={index}>
                <span className={!!checked ? "subtask-done" : "subtask-todo"}>
                  {value}
                </span>
                <Tooltip
                  title={!!checked ? "Remove from done" : "Mark as done"}
                >
                  <button
                    className={!!checked ? "checked" : "unChecked"}
                    onClick={() => subTaskDone(id, checked)}
                  >
                    {status}
                  </button>
                </Tooltip>
                <button className="delete" onClick={() => deleteSubTask(id)}>
                  <TrashIcon style={{ fill: "#fff" }} />
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <DependencyInEdit {...{ updatedTask, setUpdatedTask, rowData }} />
    </div>
  );
};
