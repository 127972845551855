export const leads = (updatedDynamicStates, IdHandler, programFields) => {
  return {
    leadStatus: () => ({
      title: "Lead Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Leads",
        field: "leadStatus",
        secondaryField: "leadAddress",
        // field: "leadCompany",
        // secondaryField: "leadStatus",
        filterField: [],
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.leadCompany,
            value: a?.leadStatus,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Leads?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.leadStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "795px",
    }),
    leadAddress: () => ({
      title: "Lead Address",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Leads",
        field: "leadAddress",
        secondaryField: "leadAddress",
        // field: "leadAddress",
        // secondaryField: "borough",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.leadAddress,
            value: a?.borough,
            color:
              programFields
                ?.find((a) => a?.fieldName === "NY Boroughs")
                ?.fieldOptions?.find(
                  (b) =>
                    b?.boroughName?.toLowerCase() === a?.borough?.toLowerCase()
                )?.boroughColor || "#6D8299",
            date: a.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
