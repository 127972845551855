import { Button } from "antd";
import { useState } from "react";
import { NormalSizedModal } from "../../../../Fleet/components";
import { Link } from "react-router-dom";
import "./RecordFilesRenderer.scss";
import { queryParams } from "../../../../../../utils/queryParams";
import { LoadableComp } from "../../../../XComponents";

const RecordFilesRenderer = ({ data = {}, value = [], docMapping = {} }) => {
  const [visible, setVisible] = useState();

  const totalUploads = value?.flatMap(
    ({ googleDriveUploads }) => googleDriveUploads
  );

  const docsWFiles = value?.filter(
    ({ googleDriveUploads }) => !!googleDriveUploads.length
  );

  let correctUrl = docMapping[data?.categoryName]?.endpoint?.toLowerCase();

  if (correctUrl === "fleet") {
    correctUrl = "fleets";
  } else if (correctUrl === "drivers") {
    correctUrl = "fleet/drivers";
  }

  return (
    <>
      <Button
        style={{ borderRadius: 5, backgroundColor: "#1264a3" }}
        type="primary"
        onClick={() => !!totalUploads.length && setVisible((prev) => !prev)}
      >
        {totalUploads.length}
      </Button>
      {visible && (
        <NormalSizedModal
          {...{
            visible,
            setVisible,
            title: "Documentations Files",
            className: "recordFilesRenderer",
            maskClosable: true,
          }}
        >
          <div className="documentationDetailsContainer">
            {docsWFiles?.map(({ docType, googleDriveUploads = [] }) => (
              <div className="documentationLine">
                <div className="documentationName">{docType}</div>
                <Link
                  to={{
                    pathname: `/${correctUrl}/${data?.recordId}`,
                    state: { data: data, tabPosition: "documentation" },
                    search: queryParams({ tab: "Documentation" }),
                  }}
                >
                  <Button type="primary" className="documentationUploads">
                    {googleDriveUploads.length}
                  </Button>
                </Link>
              </div>
            ))}
          </div>
        </NormalSizedModal>
      )}
    </>
  );
};

export default RecordFilesRenderer;
