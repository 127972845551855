import {
  DislikeIcon,
  ElectricalPermitIcon,
  FileIcon,
  TickedFileIcon,
  WarningCircleIcon,
} from "./assets";
import { trimEnd } from "lodash";
import { FormGridStatusRenderer } from "../../../Header/forms/Components";
import {
  ComplaintsFilterCard,
  PermitsFilterCard,
  ViolationsFilterCard,
} from "./components/FilterCards";
import {
  formatDobDate,
  formatPricing,
  isPermitSavedInPrj,
  isViolationSavedInPrj,
} from "./utils";
import { v4 as uuidv4 } from "uuid";

export const tabCategories = [
  "issued permits",
  "approved permits",
  "electrical permits",
  "violations",
  "complaints",
];

export const formatPermitKeysMethod = {
  [tabCategories[0]]: (str = "") =>
    str.replace("_s_", "'s ").replaceAll("_", " ").trim(),
  [tabCategories[1]]: (str = "") => str.replaceAll("_", " "),
};

export const docValidPermitKeys = {
  [tabCategories[0]]: {
    permitType: "work_type",
    expirationDate: "expiration_date",
  },
  [tabCategories[1]]: {
    permitType: "work_type",
    expirationDate: "expired_date",
  },
};

export const headerIcons = {
  [tabCategories[0]]: FileIcon,
  [tabCategories[1]]: TickedFileIcon,
  [tabCategories[2]]: ElectricalPermitIcon,
  [tabCategories[3]]: WarningCircleIcon,
  [tabCategories[4]]: DislikeIcon,
};

export const filterCards = {
  [tabCategories[0]]: PermitsFilterCard,
  [tabCategories[1]]: PermitsFilterCard,
  [tabCategories[2]]: PermitsFilterCard,
  [tabCategories[3]]: ViolationsFilterCard,
  [tabCategories[4]]: ComplaintsFilterCard,
};

export const statuses = [
  { status: "Resolved", color: "#1FC74C" },
  {
    status: "Active",
    color: "#f0ad4e",
  },
  {
    status: "Dismissed",
    color: "#FF6292",
  },
];

export const complaintStatuses = [
  { status: "Closed", color: "#1FC74C" },
  {
    status: "Active",
    color: "#FF6292",
  },
];

export const filingStatuses = [
  { status: "Initial", color: "#1FC74C" },
  {
    status: "Renewal",
    color: "#6bbed1",
  },
];

const electricalFilingStatuses = [
  {
    status: "Approved",
    color: "#00A464",
  },
  {
    status: "Complete",
    color: "#7AC14D",
  },
  {
    status: "Permit Issued",
    color: "#71CF48",
  },
  {
    status: "Pending Payment",
    color: "#ECDE7C",
  },
  {
    status: "Cancel",
    color: "#787677",
  },
  {
    status: "Inspections Complete",
    color: "#7AC14D",
  },
];

const electricalJobStatuses = [
  {
    status: "In Process",
    color: "#FCC94A",
  },
  {
    status: "Complete",
    color: "#7AC14D",
  },
  {
    status: "Not Complete",
    color: "#FE4C4A",
  },
];

const electricalFilingTypes = [
  {
    status: "PAA",
    color: "#6BBED1",
  },
  {
    status: "Subsequent Filing",
    color: "#EcDE7c",
  },
  {
    status: "New Job Filing",
    color: "#1FC74C",
  },
];

const approvedPermitsFilingReasons = [
  {
    status: "Initial Permit",
    color: "#7ac14d",
  },
  {
    status: "Renewal Permit With Changes",
    color: "#6BBED1",
  },
  {
    status: "Renewal Permit Without Changes",
    color: "#1FC74C",
  },
  {
    status: "No Work Permit",
    color: "#FF6292",
  },
];

const issuedPermitStatuses = [
  {
    status: "Issued",
    color: "#7ac14d",
  },
  {
    status: "In Process",
    color: "#ECDE7C",
  },
  {
    status: "Re-Issued",
    color: "#1FC74c",
  },
];

export const docStatusByPermit = {
  [tabCategories[0]]: "Issued",
  [tabCategories[1]]: "Received",
};

export const docTagStatuses = [
  {
    status: "Saved In Project",
    color: "#1FC74C",
  },
  {
    status: "Not Saved",
    color: "#FE4C4A",
  },
];

export const permitTypesMapping = {
  EQ: "Construction Equipment",
  AL: "Alternation",
  DM: "Demolition & Removal",
  CH: "Chute",
  FN: "Construction Fence",
  SH: "Sidewalk Shed",
  SF: "Supported Scaffold",
  OT: "Other",
  EW: "Equipment Work",
  BL: "Boiler",
  FA: "Fire Alarm",
  FB: "Fuel Burning",
  FP: "Fire Suppression",
  FS: "Fuel Storage",
  MH: "Mechanical/HVAC",
  SD: "Standpipe",
  SP: "Sprinkler",
  FO: "Foundation/Earthwork",
  "FO/EA": "Earthwork Only",
  NB: "New Building",
  PL: "Plumbing",
  SG: "Sign",
  AN: "Antenna",
  BE: "Boiler Equipment",
  CC: "Curb Cut",
  EL: "Electrical",
  LA: "Limited Alteration Applications (LAA)",
  MS: "Mechanical Systems",
  PA: "Place of Assembly",
  ST: "Structural",
  TA: "Temporary Place of Assembly",
  VT: "Elevator",
};

export const colDefs = ({ documentation, selectedCategory, project }) => ({
  [tabCategories[0]]: [
    {
      headerName: "Work Type",
      field: "work_type",
      colId: uuidv4(),
      checkboxSelection: true,
      cellRenderer: ({ value }) => permitTypesMapping[value],
    },
    {
      headerName: "Permit Status",
      field: "permit_status",
      colId: uuidv4(),
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses: issuedPermitStatuses,
        }),
    },
    {
      headerName: "Issuance Date",
      field: "issuance_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.issuance_date),
    },
    {
      headerName: "Filing Date",
      field: "filing_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.filing_date),
    },
    {
      headerName: "Filing Status",
      field: "filing_status",
      colId: uuidv4(),
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses: filingStatuses,
        }),
    },
    {
      headerName: "Filing Reason",
      field: "filing_reason",
      colId: uuidv4(),
    },
    {
      headerName: "Expiration Date",
      field: "expiration_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.expiration_date),
    },
    {
      headerName: "Doc Status",
      cellRenderer: ({ data }) =>
        FormGridStatusRenderer({
          value: isPermitSavedInPrj({
            selectedCategory,
            documentation,
            permit: data,
          })
            ? "Saved In Project"
            : "Not Saved",
          statuses: docTagStatuses,
        }),
      valueGetter: ({ data }) =>
        isPermitSavedInPrj({
          selectedCategory,
          documentation,
          permit: data,
        })
          ? "Saved In Project"
          : "Not Saved",
    },
    {
      headerName: "Address",
      colId: uuidv4(),
      // cellRenderer: ({ data }) =>
      //   formatDobAddress(data?.house__, data?.street_name, data?.borough),
      valueGetter: ({ data }) =>
        formatDobAddress(data?.house__, data?.street_name, data?.borough),
    },
    {
      headerName: "Bin Number",
      field: "bin",
      colId: uuidv4(),
      valueGetter: ({ data }) => data.bin__,
    },
  ],
  [tabCategories[1]]: [
    {
      headerName: "Work Type",
      field: "work_type",
      colId: uuidv4(),
      checkboxSelection: true,
      cellClass: undefined,
    },
    {
      headerName: "Work Permit",
      field: "work_permit",
      colId: uuidv4(),
    },
    {
      headerName: "Filing Reason",
      field: "filing_reason",
      colId: uuidv4(),
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses: approvedPermitsFilingReasons,
        }),
      minWidth: 285,
    },
    {
      headerName: "Job Description",
      field: "job_description",
      colId: uuidv4(),
    },
    {
      headerName: "Issued Date",
      field: "issued_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.issued_date),
    },
    {
      headerName: "Expiration Date",
      field: "expired_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.expired_date),
    },
    {
      headerName: "Doc Status",
      cellRenderer: ({ data }) =>
        FormGridStatusRenderer({
          value: isPermitSavedInPrj({
            selectedCategory,
            documentation,
            permit: data,
          })
            ? "Saved In Project"
            : "Not Saved",
          statuses: docTagStatuses,
        }),
      valueGetter: ({ data }) =>
        isPermitSavedInPrj({
          selectedCategory,
          documentation,
          permit: data,
        })
          ? "Saved In Project"
          : "Not Saved",
    },
    {
      headerName: "Address",
      colId: uuidv4(),
      // cellRenderer: ({ data }) =>
      //   formatDobAddress(data?.house_no, data?.street_name, data?.borough),
      valueGetter: ({ data }) =>
        formatDobAddress(data?.house_no, data?.street_name, data?.borough),
    },
    {
      headerName: "Bin Number",
      field: "bin",
      colId: uuidv4(),
    },
  ],
  [tabCategories[2]]: [
    {
      headerName: "Category",
      field: "category_work_list",
      colId: uuidv4(),
    },
    {
      headerName: "Permit Issued Date",
      field: "permit_issued_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.permit_issued_date),
    },
    {
      headerName: "Job Status",
      field: "job_status",
      colId: uuidv4(),
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses: electricalJobStatuses,
        }),
    },
    {
      headerName: "Job Start Date",
      field: "job_start_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.job_start_date),
    },
    {
      headerName: "Filing Type",
      field: "filing_type",
      colId: uuidv4(),
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses: electricalFilingTypes,
        }),
    },
    {
      headerName: "Filing Status",
      field: "filing_status",
      colId: uuidv4(),
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses: electricalFilingStatuses,
        }),
    },
    {
      headerName: "Filing Date",
      field: "filing_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.filing_date),
    },
    {
      headerName: "Amount Due",
      field: "amount_due",
      colId: uuidv4(),
      hide: true,
      cellRenderer: (params) => formatPricing(params?.value),
    },
    {
      headerName: "Amount Paid",
      field: "amount_paid",
      colId: uuidv4(),
      hide: true,
      cellRenderer: (params) => formatPricing(params?.value),
    },
    {
      headerName: "Address",
      colId: uuidv4(),
      // cellRenderer: ({ data }) =>
      //   formatDobAddress(
      //     data?.house_number,
      //     data?.street_name,
      //     data?.borough,
      //     data?.state
      //   ),
      valueGetter: ({ data }) =>
        formatDobAddress(
          data?.house_number,
          data?.street_name,
          data?.borough,
          data?.state
        ),
    },
    {
      headerName: "Bin Number",
      field: "bin",
      colId: uuidv4(),
    },
  ],
  [tabCategories[3]]: [
    {
      headerName: "Violation Category",
      field: "violation_category",
      colId: uuidv4(),
    },
    {
      headerName: "Status",
      field: "violation_status",
      colId: "renderedStatus",
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses,
        }),
    },
    {
      headerName: "Violation Number",
      field: "violation_number",
      colId: uuidv4(),
    },
    {
      headerName: "Violation Type",
      field: "violation_type",
      colId: uuidv4(),
    },
    {
      headerName: "Saved in Project",
      cellRenderer: ({ data }) =>
        FormGridStatusRenderer({
          value: isViolationSavedInPrj({
            project,
            violation: data,
          })
            ? "Saved In Project"
            : "Not Saved",
          statuses: docTagStatuses,
        }),
      valueGetter: ({ data }) =>
        isViolationSavedInPrj({
          project,
          violation: data,
        })
          ? "Saved In Project"
          : "Not Saved",
    },
    {
      headerName: "Address",
      colId: uuidv4(),
      // cellRenderer: ({ data }) =>
      //   formatDobAddress(data?.house_number, data?.street, data?.borough),
      valueGetter: ({ data }) =>
        formatDobAddress(data?.house_number, data?.street, data?.borough),
    },
    {
      headerName: "Bin Number",
      field: "bin",
      colId: uuidv4(),
    },
  ],
  [tabCategories[4]]: [
    {
      headerName: "Category",
      field: "complaint_category",
      colId: uuidv4(),
    },
    {
      headerName: "Status",
      field: "status",
      colId: uuidv4(),
      cellRenderer: (params) =>
        FormGridStatusRenderer({
          value: params?.value,
          statuses: complaintStatuses,
        }),
    },
    {
      headerName: "Entered Date",
      field: "date_entered",
      colId: uuidv4(),
      // cellRenderer: (params) =>
      //   formatDobDate(params?.value)
      // ,
      valueGetter: ({ data }) => formatDobDate(data?.date_entered),
    },
    {
      headerName: "Community Board",
      field: "community_board",
      colId: uuidv4(),
    },
    {
      headerName: "Disposition Date",
      field: "disposition_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.disposition_date),
    },
    {
      headerName: "Inspection Date",
      field: "inspection_date",
      colId: uuidv4(),
      // cellRenderer: (params) => formatDobDate(params?.value),
      valueGetter: ({ data }) => formatDobDate(data?.inspection_date),
    },
    {
      headerName: "Address",
      colId: uuidv4(),
      // cellRenderer: ({ data }) =>
      //   formatDobAddress(
      //     data?.house_number,
      //     data?.house_street,
      //     data?.zip_code
      //   ),
      valueGetter: ({ data }) =>
        formatDobAddress(
          data?.house_number,
          data?.house_street,
          data?.zip_code
        ),
    },
    {
      headerName: "Bin Number",
      field: "bin",
      colId: uuidv4(),
    },
  ],
});

export const columnDefsFiltersData = {
  Dropdown: "agMultiColumnFilter",
  DateRange: "agDateColumnFilter",
  DatePicker: "agDateColumnFilter",
};

export const gridFilters = {
  [tabCategories[0]]: [
    { key: "permit_status", type: "Dropdown", label: "Permit Status" },
    { key: "filing_status", type: "Dropdown", label: "Filing Status" },
    { key: "bin", type: "Dropdown", label: "Bin Number" },
    { key: "issuance_date", type: "DatePicker", label: "Issuance Date" },
    { key: "filing_date", type: "DatePicker", label: "Filing Date" },
    { key: "expiration_date", type: "DatePicker", label: "Expiration Date" },
    { key: "issuance_date", type: "DateRange", label: "Issuance Date Range" },
    { key: "filing_date", type: "DateRange", label: "Filing Date Range" },
    {
      key: "expiration_date",
      type: "DateRange",
      label: "Expiration Date Range",
    },
  ],
  [tabCategories[1]]: [
    { key: "work_type", type: "Dropdown", label: "Work Type" },
    { key: "filing_reason", type: "Dropdown", label: "Filing Reason" },
    { key: "bin", type: "Dropdown", label: "Bin Number" },
    { key: "issued_date", type: "DatePicker", label: "Issued Date" },
    { key: "expired_date", type: "DatePicker", label: "Expiration Date" },
    { key: "issued_date", type: "DateRange", label: "Issued Date Range" },
    {
      key: "expired_date",
      type: "DateRange",
      label: "Expiration Date Range",
    },
  ],
  [tabCategories[2]]: [
    { key: "filing_type", type: "Dropdown", label: "Filing Type" },
    { key: "filing_status", type: "Dropdown", label: "Filing Status" },
    { key: "bin", type: "Dropdown", label: "Bin Number" },
    {
      key: "permit_issued_date",
      type: "DatePicker",
      label: "Permit Issued Date",
    },
    { key: "job_start_date", type: "DatePicker", label: "Job Start Date" },
    { key: "filing_date", type: "DatePicker", label: "Filing Date" },
    {
      key: "permit_issued_date",
      type: "DateRange",
      label: "Permit Issued Date Range",
    },
    { key: "job_start_date", type: "DateRange", label: "Job Start Date Range" },
    {
      key: "filing_date",
      type: "DateRange",
      label: "Filing Date Range",
    },
  ],
  [tabCategories[3]]: [
    { key: "violation_status", type: "Dropdown", label: "Status" },
    { key: "bin", type: "Dropdown", label: "Bin Number" },
    { key: "violation_type", type: "Dropdown", label: "Type" },
  ],
  [tabCategories[4]]: [
    { key: "status", type: "Dropdown", label: "Status" },
    { key: "bin", type: "Dropdown", label: "Bin Number" },
    { key: "date_entered", type: "DatePicker", label: "Entered Date" },
    { key: "disposition_date", type: "DatePicker", label: "Disposition Date" },
    { key: "inspection_date", type: "DatePicker", label: "Inspection Date" },
    { key: "date_entered", type: "DateRange", label: "Entered Date Range" },
    {
      key: "disposition_date",
      type: "DateRange",
      label: "Disposition Date Range",
    },
    {
      key: "inspection_date",
      type: "DateRange",
      label: "Inspection Date Range",
    },
  ],
};

export const defaultColDef = {
  enablePivot: true,
  enableColResize: true,
  enableRowGroup: true,
  editable: false,
  sortable: true,
  resizable: true,
  flex: 1,
  minWidth: 100,
  filter: true,
  cellClass: "centeredCell",
};

const formatDobAddress = (house, street_name, borough, state) => {
  return `${typeof house === "undefined" ? "" : house} ${
    typeof street_name === "undefined" ? "" : street_name
  } ${typeof borough === "undefined" ? "" : ", " + borough} ${
    typeof state === "undefined" ? "" : ", " + state
  }`;
};

export const addressFormatterForDOB = (data, category) => {
  switch (category) {
    case "issued permits": {
      return formatDobAddress(data?.house__, data?.street_name, data?.borough);
    }
    case "approved permits": {
      return formatDobAddress(data?.house_no, data?.street_name, data?.borough);
    }
    case "electrical permits": {
      return formatDobAddress(
        data?.house_number,
        data?.street_name,
        data?.borough,
        data?.state
      );
    }
    case "violations": {
      return formatDobAddress(data?.house_number, data?.street, data?.borough);
    }
    case "complaints": {
      return formatDobAddress(
        data?.house_number,
        data?.house_street,
        data?.zip_code
      );
    }
    default: {
      return null;
    }
  }
};
