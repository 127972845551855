import React from "react";
import DeleteItem from "./delete/DeleteItem";
import MoreActions from "./more/MoreActions";
import "./NotificationItemActions.scss";

function NotificationItemActions({
  functionParams,
  setFunctionParams,
  setAnimatedClassName,
  item,
  apiName,
  path,
  text,
  animatedClassName,
  content,
  type,
}) {
  return (
    <div className="notification-item-actions-container">
      <DeleteItem
        item={item}
        setFunctionParams={setFunctionParams}
        functionParams={functionParams}
        setAnimatedClassName={setAnimatedClassName}
        apiName={apiName}
        path={path}
        text={text}
        type={type}
      />
      <MoreActions animatedClassName={animatedClassName} content={content} />
    </div>
  );
}

export default NotificationItemActions;
