import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useStateContext } from "../../../../../../Context/Context";
import {
  columnDefs,
  defaultColDef,
  getTotalities,
  valueChangerHandler,
} from "./agGridHelpers";
import { cloneDeep } from "lodash";
import AnimatedNumber from "react-animated-number";

import "./ServiceLevelCredit.scss";
import "../../RentalBody/RentalBody.scss";
import { formatCurrency } from "../../../../../../../../../../utils";
import { useRedux } from "../../../../../../../../../../hooks";
import AgGridMathful from "../../../../Service/ServiceContent/AgGridSum/AgGridMathful";
function ServiceLevelCredit({ creditedRequisition, rental, setVisible }) {
  const { fakeApplication, darkMode } = useStateContext();
  const [rowData, setRowData] = useState(cloneDeep(rental?.services || []));
  const [totalities, setTotalities] = useState({});
  const [currentRental, setCurrentRental] = useRedux(
    "currentRental",
    false,
    true
  ); //To show the Description Modal
  const [selected, setSelected] = useState(null);

  const onRangeSelectionChanged = (event) => {
    // console.log("hoist selection changed", event);
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  };

  useEffect(() => {
    setTotalities(getTotalities({ rowData }));
    if (Array.isArray(rowData)) setCurrentRental(rowData);
  }, [rowData, fakeApplication]);

  return (
    <div className="service-level-credit-wrapper">
      <div className="service-level-header">
        You are applying Credit with chained Relation to Requisition{" "}
        {creditedRequisition}
      </div>

      {/* // className={
			// 	darkMode
			// 		? "includeRentalsAgGridContainerDark agGridRentalsDetailsStyleDark"
			// 		: "includeRentalsAgGridContainerLight agGridRentalsDetailsStyleLight"
			// } */}

      <div className="includeRentalsAgGridContainerDark agGridRentalsDetailsStyleDark ag-theme-alpine-dark">
        {selected && <AgGridMathful darkMode={darkMode} event={selected} />}

        {rowData && (
          <AgGridReact
            suppressSizeToFit={true}
            enableRangeSelection={true}
            rowSelection="single"
            paginationPageSize={14}
            columnDefs={columnDefs({
              rental,
              setRowData,
              rowData,
              setServiceVis: () => setVisible(false),
              setCurrentRental,
            })}
            rowData={rowData}
            context={[rowData]}
            headerHeight={50}
            rowHeight={40}
            onCellValueChanged={(params) =>
              valueChangerHandler({
                params,
                rowData,
                setRowData,
                setTotalities,
                setCurrentRental,
              })
            }
            stopEditingWhenGridLosesFocus={true}
            enableCellChangeFlash={true}
            onRangeSelectionChanged={onRangeSelectionChanged}
            suppressDragLeaveHidesColumns={true}
            defaultColDef={{
              flex: 1,
              sortable: true,
              resizable: true,
              enablePivot: true,
              filter: true,
              enableColResize: true,
              enableRowGroup: true,
              // rowHeight: 40,
            }}
          />
        )}
      </div>
      <div className="footer-amounts">
        <div className="amount-el">
          <span className="label-amount"> Total Credit Applied:</span>
          <span className="animated-val">
            <AnimatedNumber
              value={totalities?.totalCredit}
              formatValue={(value) => formatCurrency(value)}
            />
          </span>
        </div>
        {/* <div className="amount-el">
					<span className="label-amount"> Retainage:</span>
					<span className="animated-val">
						<AnimatedNumber
							value={totalities?.totalRetainage}
							formatValue={(value) => formatCurrency(value)}
						/>
					</span>
				</div>
				<div className="amount-el">
					<span className="label-amount"> Tax :</span>
					<span className="animated-val">
						<AnimatedNumber
							value={totalities?.totalTax}
							formatValue={(value) => formatCurrency(value)}
						/>
					</span>
				</div> */}
        {/* <div className="amount-el">
					<span className="label-amount"> Total Amount:</span>
					<span className="animated-val">
						<AnimatedNumber
							value={totalities?.totalPrice}
							formatValue={(value) => formatCurrency(value)}
						/>
					</span>
				</div> */}
      </div>
    </div>
  );
}

export default ServiceLevelCredit;
