import { message } from "antd";

import { getErrors } from "../../../../utils/getErrors";
import { updateFakeSOV } from "../utils";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const appendRow = (
  eventId,
  isWritable,
  isLoading,
  service,
  fakeSOV,
  setSOV,
  setErrors
) => {
  if (!isWritable) {
    message.error("Please enable write mode");
  } else if (isLoading) {
    message.error("Please wait until it finishes updating");
  } else {
    // Find service that we want to edit
    const serviceToEdit = fakeSOV?.services?.find(
      (sovService) =>
        sovService.serviceId.toString() === service.serviceId.toString() &&
        sovService.estimationId === service.estimationId
    );
    console.log("serviceToEdit", serviceToEdit);
    // Initial amounts of event
    const amount = {
      id: 0,
      name: "",
      rate: 0,
      amount: 0,
      difference: 0,
      retainage: 0,
      collectable_amount: 0,
      retainagePercentage: service?.retainage || 0,
      custom_tax_amount: serviceToEdit?.isTaxable
        ? forceToNumber(serviceToEdit?.taxRate)
        : 0,
      note: "",
    };

    // Update amounts on grid
    const amounts =
      serviceToEdit?.amounts?.length > 0 // If there are already some events
        ? eventId === undefined || eventId === null // Check if user clicked on empty space on grid, under other events
          ? [
              // If yes, add newest event at the end of others
              ...serviceToEdit.amounts, // Copy other events, and add the newest one on the end
              {
                ...amount, // Spread event properties that dont need to change
                id: serviceToEdit.amounts.length, // Make id of event equal to size of all events
                difference:
                  serviceToEdit.amounts[serviceToEdit.amounts.length - 1]
                    .difference, // Get difference of last event, because we initially have 0% rate
              },
            ]
          : [
              // If user clicked on a row of event
              ...serviceToEdit.amounts.slice(0, eventId + 1), // Copy all events until that row, and add newest one in the next row
              {
                ...amount, // Spread event properties that dont need to change
                id: eventId + 1, // Make id of event the next one in order, if row 1 is clicked, id = 2
                difference: serviceToEdit.amounts[eventId].difference, // Get difference of previous event, because we initially have 0% rate
              },
              ...serviceToEdit.amounts
                .slice(eventId + 1)
                .map((amount) => ({ ...amount, id: amount.id + 1 })), // Copy all other events after the inserted one and increment their id by 1
            ]
        : [...serviceToEdit.amounts, amount]; // If there are not other events, just add the newest one

    // Find the service that we are editing and update its amounts
    const newSOV = updateFakeSOV(fakeSOV, service, amounts);

    // Get errors for each service
    const errors = getErrors(newSOV);

    // Set new SOV
    setSOV(newSOV);

    // If none of services have errors set errors to null, otherwise set errors to errors object of all services
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      setErrors(null);
    } else {
      setErrors(errors);
    }
  }
};
