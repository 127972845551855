import {
  Fragment,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { OverlayViewF, Polygon, PolylineF } from "@react-google-maps/api";
import { PlayCircleFilled } from "@ant-design/icons";

import {
  findBoundExtremities,
  findGeofenceCenterCoordinate,
} from "../../../../utils";
import LiveMapContext from "../../LiveMapContext";
import { PauseIconFilled } from "src/assets";

const SideOverlay = forwardRef((_, ref) => {
  const { changeMarkerVisibility } = useContext(LiveMapContext);

  const [startEndMarkers, setStartEndMarkers] = useState([]);
  const [polygon, setPolygon] = useState(
    /** @type {google.maps.Polygon} */ (null)
  );
  const [polyline, setPolyline] = useState(
    /** @type {google.maps.Polyline} */ (null)
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        drawLine(points, map) {
          if (!polyline || !map) {
            return;
          }

          polyline.setPath([]);

          const allLocations = points.map(({ latitude, longitude }) => ({
            lat: latitude,
            lng: longitude,
          }));

          if (!points.length) {
            setStartEndMarkers([]);
            changeMarkerVisibility(true);
          } else {
            changeMarkerVisibility(false);
            const bounds = new google.maps.LatLngBounds();
            const extremities = findBoundExtremities(allLocations);
            for (const extremity of extremities) {
              bounds.extend(extremity);
            }

            setStartEndMarkers([points?.[0], points?.[points.length - 1]]);
            polyline.setPath(allLocations);
            map.fitBounds(bounds);
          }
        },
        drawPolygon(fencePoints, map) {
          if (!polygon || !map) {
            return;
          }

          const points = fencePoints.map(({ latitude, longitude }) => ({
            lat: latitude,
            lng: longitude,
          }));

          polygon.setPaths(points);

          if (points.length) {
            const center = findGeofenceCenterCoordinate(points);

            polygon.setVisible(true);

            map.panTo(center);
            map.setZoom(16);
          }
        },
      };
    },
    [polygon, polyline]
  );

  return (
    <Fragment>
      <PolylineF
        {...{
          onLoad(e) {
            setPolyline(e);
          },
          options: {
            visible: true,
            strokeColor: "#9d9dff",
            strokeWeight: 4,
            strokeOpacity: 1,
          },
        }}
      />
      <Polygon
        {...{
          onLoad(e) {
            setPolygon(e);
          },
          options: {
            editable: false,
            draggable: false,
            strokeColor: "#d99f00",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#d99f00",
            fillOpacity: 0.35,
          },
        }}
      />
      {startEndMarkers.map((e, i) => {
        return (
          <OverlayViewF
            position={{
              lat: e.latitude,
              lng: e.longitude,
            }}
            mapPaneName="overlayMouseTarget"
            key={`overlay-${i}`}
          >
            <div className="live-marker">
              {i ? (
                <span className="marker-status-icon Stopped">
                  <PauseIconFilled height={24} width={24} />
                </span>
              ) : (
                <span className="marker-status-icon Play">
                  <PlayCircleFilled height={24} width={24} />
                </span>
              )}
            </div>
          </OverlayViewF>
        );
      })}
    </Fragment>
  );
});

export default SideOverlay;
