import { Button, Modal } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { MondayButton } from "../../../../../commonComponents";
import { DisplayColors } from "../DayStatusChangeModal/DayStatuses";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { InfoIconBlue } from "../SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import {
  getCognitosForNotification,
  updateDocumentTitle,
} from "../../../../../../utils";
import { postScheduleChanges } from "src/components/SidebarPages/Scheduling/ScheduleChangesModal/Helpers/postScheduleChanges";
import _ from "lodash";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { useSocket } from "src/hooks";
import { wsDispatches } from "src/AppData";
import broadcastNotification from "src/helpers/controllers/broadcastNotification";

import "./DispatchScheduleDayModal.scss";

const DispatchScheduleDayModal = ({
  openDispatchDays = false,
  setOpenDispatchDays = () => {},
  dispatchDays = [],
  notifyFleetUsers = () => {},
  openFromDispatch = false,
  timeHandler = () => {},
  blockChanges = false,
  isDarkMode,
  projectId,
  scheduleId,
  scheduleName,
  scheduleAddress,
  dispatchForSchedule = [],
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const navigate = useNavigate();

  const { socket } = useSocket({
    path: wsDispatches.online,
    onopen(ws) {
      ws.send(
        JSON.stringify({
          request: "create-session",
          body: { env: process.env.NODE_ENV },
        })
      );
    },
  });

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setOpenDispatchDays(false);
  };

  return (
    <div>
      <Modal
        centered
        data-testid="schedule-changes-modal"
        title="Dispatch Days Changes"
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Dispatch Days Changes" });
        }}
        open={openDispatchDays}
        onCancel={closeModal}
        className={`dispatchDaysChangesModal ${
          isDarkMode && "dispatchDaysChangesModalDark"
        }`}
        closable={false}
        maskClosable={false}
        keyboard={false}
        width={900}
        footer={
          openFromDispatch ? (
            <MondayButton
              {...{
                className: "mondayButtonRed",
                "data-testid": "close-schedule-changes",
                onClick() {
                  closeModal();
                },
                Icon: <XIcon />,
              }}
            >
              Close
            </MondayButton>
          ) : (
            <MondayButton
              onClick={async () => {
                notifyFleetUsers();
                closeModal();
                const groupedDispatches = _.groupBy(
                  dispatchDays.map((day) => ({
                    ...day,
                    projectId,
                    scheduleAddress,
                  })),
                  "dayEdited"
                );

                await Promise.allSettled(
                  Object.keys(groupedDispatches).map((day) =>
                    postScheduleChanges({
                      scheduleId,
                      projectId,
                      changes: groupedDispatches[day],
                      scheduleDay: parseInTz(day).startOf("day").format(),
                      newStatus:
                        groupedDispatches[day]["action"] === "Postponed"
                          ? "Postponed"
                          : "Pending",
                    })
                  )
                )
                  .then((res) => {
                    setTimeout(() => {
                      Swal.fire({
                        title: "Successfully saved!",
                        text: "Dispatch days changes have been saved and notified!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                      });
                    }, 1500);

                    let affectedDispatchIds = [];

                    for (const { value, status } of res) {
                      if (status === "rejected") {
                        continue;
                      }

                      affectedDispatchIds = affectedDispatchIds.concat(
                        value.affectedDispatches
                      );

                      try {
                        socket.send(
                          JSON.stringify({
                            request: "dispatch-schedule-changes",
                            body: {
                              ...value,
                            },
                          })
                        );
                      } catch (err) {
                        console.log("Error sending socket: ", err);
                      }
                    }

                    const teamMembers = dispatchForSchedule.flatMap(
                      ({ teamsConfiguration, dispatchId }) => {
                        if (
                          !teamsConfiguration ||
                          !affectedDispatchIds.includes(dispatchId)
                        ) {
                          return [];
                        }

                        return teamsConfiguration;
                      }
                    );

                    broadcastNotification(
                      "11",
                      "onDispatchScheduleChange",
                      [
                        {
                          common: userConfiguration?.nameOfUser,
                          scheduleName: scheduleName,
                          scheduleAddress: scheduleAddress,
                        },
                        {
                          userName: userConfiguration?.nameOfUser,
                          currentUser: authenticatedUser?.sub,
                          cognitos: getCognitosForNotification(
                            userConfiguration,
                            teamMembers,
                            "fleet administrator"
                          ),
                        },
                      ],
                      scheduleId
                    );
                  })
                  .catch((err) => {
                    console.log("Could not change all the dispatches: ", err);
                  });

                navigate("/scheduling");
              }}
              {...{
                Icon: <TickIcon width={17} height={17} />,
              }}
            >
              Save changes
            </MondayButton>
          )
        }
      >
        <div>
          <div
            className="dispatchDaysChangesInfo"
            style={{ marginBottom: "1rem" }}
          >
            <span style={{ marginRight: "1rem" }}>{<InfoIconBlue />}</span>
            <span>
              {openFromDispatch
                ? `These are all the updates for the schedule day`
                : `We noticed changes in some schedule days that are already assigned
              to dispatch. By clicking save changes, all users that have access
              to fleet will be notified for the changes you have made!`}
            </span>
          </div>{" "}
          <table className="dispatchDaysChangesTable">
            <tr className="dispatchDaysChangesTableHeader">
              <th>{!openFromDispatch ? "Day Edited" : "Address"}</th>
              <th>Day Status</th>
              <th>Action</th>
              <th>Previous</th>
              <th>Current</th>
              {openFromDispatch && <th>Apply Changes</th>}
            </tr>
            {dispatchDays?.map((item, i) => {
              return (
                <tr className="dispatchDaysChangesTableBody" key={`tr-${i}`}>
                  <td>
                    {!openFromDispatch
                      ? item?.dayEdited
                      : item?.scheduleAddress || item?.dayEdited}
                  </td>
                  <td
                    style={{
                      background: DisplayColors[item?.status],
                      color: "#fff",
                    }}
                  >
                    {item?.status}
                  </td>
                  <td>{item?.action}</td>
                  <td
                    style={{
                      background: "#f5f5f5",
                      color: "black",
                    }}
                  >
                    {item?.prev}
                  </td>
                  <td>{item?.new}</td>
                  {openFromDispatch && (
                    <td
                      style={{
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {item?.action === "Start Time Change" && !blockChanges ? (
                        <Button
                          type="primary"
                          onClick={() => {
                            timeHandler(item?.new);
                          }}
                        >
                          Apply Change
                        </Button>
                      ) : (
                        <span>Cannot Apply Changes</span>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default DispatchScheduleDayModal;
