import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider, App as AntdApp } from "antd";
import { LicenseManager } from "ag-grid-enterprise";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";

import App from "./App";
import { appStore } from "./store";
import config from "./config.js";
import ErrorBoundary from "./ErrorBoundary";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import { WebsocketProvider } from "./contexts/WebsocketProvider/WebsocketProvider.jsx";
import { EmailBoxContainerProvider } from "./components/SidebarPages/Communication/components/EmailBox/providers/EmailBoxContainerProvider.jsx";
import { AuthProvider } from "./contexts/AuthenticatorProvider.jsx";
// import "antd/dist/reset.css";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-045792 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Core Scaffold Systems Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Lead Manager )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Lead Manager )_need_to_be_licensed___( Lead Manager )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 27 July 2024 )____[v2]_MTcyMjAzNDgwMDAwMA==9d9d1a84ee679542095c15b16b4c54e3"
);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.debug = () => {};
}

const endpointNames = [
  "rentals",
  "teams",
  "base64s",
  "leads",
  "opportunities",
  "accounts",
  "contacts",
  "totalLogs",
  "projects",
  "proposedTypeOfWork",
  "projectExecutives",
  "priority",
  "sources",
  "fetchSchedulesByDay",
  // "architects",
  // "engineers",
  "tasks",
  "users",
  "groups",
  "editLogs",
  "payments",
  "estimationLogs",
  "estimations",
  "scheduling",
  "toBeScheduled",
  "dynamicTemplates",
  "documentation",
  "trucks",
  "pricing",
  "pricePerUnitInfo",
  "proposedConstructionServices",
  "teams",
  "notes",
  "charges",
  "coreservices",
  "gridLayouts",
  "preferences",
  "priceSheet",
  "crews",
  "crewActivity",
  "drivers",
  "fetchServiceLogs",
  "permitDrawings",
  "inspections",
  "inspectionStatuses",
  "statusOfInspection",
  "filterProjects",
  "formRequests",
  "serviceDefinitions",
  "updateInspectionStatus",
  "inspectionQuestions",
  "preinstallationQuestions",
  "unitMeasurement",
  "scheduleOfValues",
  "applications",
  "sampleObjects",
  "reportConfiguration",
  "reportsAvailable",
  "getIp",
  "customerUsers",
  "userSessions",
  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Getting, posting and deleting post dynamicly ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "dynamicFunc",
  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Orders page endpoints ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "orders",
  /*~~~~~~~~~~~~~~~~~~~~~~~~ Notification settings endpoints ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "globalNotificationSettings",
  "templates",
  /*~~~~~~~~~~~~~~~~~~~~~~~~ Emails endpoints ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "emailTemplates",
  "dynamicFields",
  "categories",
  /*~~~~~~~~~~~~~~~~~~~~~~~~ Filters ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "filterTables",
  "searchRecords",
  "lazyFetching",
  "syncProjects",
  "updateProjectId",
  /*~~~~~~~~~~~~~~~~~~~~~~~ Credentials ~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "hotCredentials",
  /*~~~~~~~~~~~~~~~~~~~~~~~~ Task Management ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "tasksManagement",
  "labels",
  "categories",
  "filterAccountsByType",
  "companies",
  /*~~~~~~~~~~~~~~~~~~~~~~~~~Fleet~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  "fleet",
  "fleetActivity",
  "fleetViolations",
  "fleetFields",
  "fleetDispatching",
  "drivers",
  "fleetDocumentation",
  "fleetDocumentation",
  "fleetInspections",
  "dynamicQuestions",
  "fleetMaintenance",
  "fleetIncidents",
  "fleetDefects",
  "workOrders",
  "fleetInventory",
  "serviceMaintenance",
  "servicePrograms",
  "serviceTasks",
  /*~~~~~~~~~ PROGRAM FIELDS ~~~~~~~~~~~*/
  "orderFields",
  "programFields",
  "userConfiguration",
  "userGroups",
  "docConfiguration",
  /*~~~~~~~~~ Notifications FIELDS ~~~~~~~~~~~*/
  "notifications",
  "filterNotifications",
  "charges",
  "invoices",
  "mapping",
  // Take Off Proposal Builder
  "proposalBuilds",
  "proposalTemplates",
  "accountingTemplates",
  "automations",
  //Claims
  "claims",
  "geofences",
  "hearings",
  // Safety
  "safety",
  "incidents",
  "allInspections",
  // projectCost
  "jobsites",
  "deg",
  "costAnalytics",
  "deleteRecords",
  "degEntries",
  "fleetAudits",
  //Engineers and architects
  "engineers",
  "architects",
  //To Dos
  "todos",
  //Included rentals in applications
  "includedRentals",
  "vendors",
  "bills",
  "billItems",
  "crewTeams",
  "includedRentals",
  "developerStartLogs",
  "progressItems",
  "employeeReports",
  "fetchSchedulesByDay",
];

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    endpoints: endpointNames.map((name) => {
      return {
        name: name,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      };
    }),
  },
});

root.render(
  <ErrorBoundary>
    <Provider store={appStore}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Open Sans, sans-serif",
          },
        }}
      >
        <AntdApp>
          <Router>
            <WebsocketProvider>
              <AuthProvider>
                <EmailBoxContainerProvider>
                  <App />
                </EmailBoxContainerProvider>
              </AuthProvider>
            </WebsocketProvider>
          </Router>
        </AntdApp>
      </ConfigProvider>
    </Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
