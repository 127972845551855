import { boroughs } from "../../../../addWidgetsModalData";

export const claims = ({ tables, programFields }) => {
  const statusesOfClaims = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Claims;
  return {
    claimStatus: () => ({
      content: statusesOfClaims?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Claims?.filter((a) => a?.claimStatus === status?.statusName)
            .length /
            tables?.Claims?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status?.statusName,
        done: tables?.Claims?.filter(
          (a) => a?.claimStatus === status?.statusName
        )?.length,
      })),
    }),
  };
};
