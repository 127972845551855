import { Modal, Switch } from "antd";
import { useState } from "react";
import { antdModalProps } from "../../../../../../utils";
import { MondayButton, RichTextEditor } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import "./AssigneesToggle.scss";

const AssigneesToggle = ({
  value = false,
  onChange,
  className,
  isDarkMode,
}) => {
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const onSwitchChange = (checked) => {
    !!checked ? setReasonModalVisible(true) : onChange(false);
  };

  const onSaveToggleReason = () => {
    onChange(true);
    setReasonModalVisible(false);
  };

  return (
    <>
      <Switch
        {...{
          checked: value,
          onChange: onSwitchChange,
          className,
          checkedChildren: "Assign",
          unCheckedChildren: "Assign",
        }}
      />
      <Modal
        forceRender
        {...{
          ...antdModalProps,
          title: "Note",
          open: reasonModalVisible,
          onCancel: () => setReasonModalVisible(false),
          className: `assigneesToggleModal ${
            isDarkMode && "assigneesToggleModalDark"
          }`,
        }}
      >
        <div className="assigneesToggleModalContent">
          <RichTextEditor
            name="assigneesToggleReason"
            onChange={(e) => {
              !!e ? setCanSave(true) : setCanSave(false);
            }}
          />
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onSaveToggleReason,
              disabled: !canSave,
            }}
          >
            Save
          </MondayButton>
        </div>
      </Modal>
    </>
  );
};

export default AssigneesToggle;
