import dayjs from "dayjs";

export const fleet = (updatedDynamicStates, IdHandler) => {
  return {
    fleetYear: () => ({
      title: "Fleet Year",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet",
        field: "fleetYear",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetName,
            value: a?.fleetYear,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //fleetYear
    registrationExpDate: () => ({
      title: "Fleet Registration Expiration Date",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet",
        field: "registrationExpDate",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetName,
            value: dayjs(a?.registrationExpDate)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //registrationExpDate
    inspectionExpDate: () => ({
      title: "Fleet Inspection Expiration Date",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet",
        field: "inspectionExpDate",
        secondaryField: "inspectionExpDate",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetName,
            value: dayjs(a?.inspectionExpDate)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //inspectionExpDate
  }; //Fleet
};
