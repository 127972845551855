import { MondayButton } from "../../../../../../../../commonComponents";
import { DropdownIcon } from "../../../../../../../BasePage/src";
import { Close } from "../../../../../../../DynamicView/src";
import "./Footer.scss";
import {
  // createRentalFromEstimationHandler,
  createRentalAutomaticallyHandler,
  createRentalHandler,
} from "./FooterFunctions";
import { createRentalFromEstimationHandler } from "./createRentalFromEstimation";

import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEditLogs } from "../../../../../../../../../hooks";

const Footer = ({
  handleCloseForm,
  setChosenData,
  schedulingData,
  estimations,
  chosenData,
  projectId,
  specificProject,
  setCancelModalVisible,
  fromAutomation,
  getBodyToSaveInAutomation,
  isNextStep,
  getRental,
}) => {
  const buttonTypes = ["Save & Close", "Finish", "Save & New"];
  const { programFields } = useSelector((state) => state.programFields);
  const { isLoading: isLoadingSOVs, scheduleOfValues } = useSelector(
    (state) => state.scheduleOfValues
  );
  const [buttonType, setButtonType] = useState("Finish");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saveAddedLogs } = useEditLogs();
  const { rentals } = useSelector((state) => state.rentals);

  const createRental = () => {
    return chosenData?.from === "schedulingAutomatically"
      ? createRentalAutomaticallyHandler({
          allSchedules: schedulingData,
          handleCloseForm,
          setChosenData,
          programFields,
          buttonType,
          chosenData,
          projectId,
          dispatch,
          specificProject,
          sovServices:
            scheduleOfValues?.map((sov) => sov?.services)?.flat(1) || [],
          getBodyToSaveInAutomation,
          navigate,
          isNextStep,
          getRental,
          saveAddedLogs,
        })
      : chosenData?.from === "schedulingManually"
      ? createRentalHandler({
          allSchedules: schedulingData,
          handleCloseForm,
          setChosenData,
          programFields,
          buttonType,
          chosenData,
          projectId,
          dispatch,
          specificProject,
          sovServices:
            scheduleOfValues?.map((sov) => sov?.services)?.flat(1) || [],
          getBodyToSaveInAutomation,
          navigate,
          isNextStep,
          getRental,
          saveAddedLogs,
        })
      : createRentalFromEstimationHandler({
          handleCloseForm,
          setChosenData,
          estimations,
          chosenData,
          buttonType,
          projectId,
          dispatch,
          specificProject,
          sovServices:
            scheduleOfValues?.map((sov) => sov?.services)?.flat(1) || [],
          getBodyToSaveInAutomation,
          navigate,
          isNextStep,
          getRental,
          saveAddedLogs,
          rentals,
        });
  };

  //if any of 2 values is not picked this disabled the create button
  const isDisabled = !(
    !!chosenData?.selectedId && !!chosenData?.service?.length
  );

  return (
    <div
      className="newRentalFooterButtonContainer"
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <MondayButton
        className="cancelButton"
        Icon={<Close />}
        onClick={handleCloseForm}
        tooltipCategory="Rentals"
        tooltipKey="cancel"
      >
        Cancel
      </MondayButton>
      <Tooltip
        title={
          isDisabled
            ? "You have to pick estimation and service to create rental"
            : ""
        }
      >
        <Dropdown.Button
          className="dropDownButtonNewRental"
          placement="bottomCenter"
          icon={<DropdownIcon />}
          menu={{
            onClick: (e) => setButtonType(e.key),
            items: buttonTypes
              ?.filter((type) => type !== buttonType)
              ?.map((type) => ({
                key: type,
                label: type,
              })),
          }}
          onClick={createRental}
          disabled={isDisabled}
        >
          {buttonType}
        </Dropdown.Button>
      </Tooltip>
    </div>
  );
};

export default Footer;
