export const generateInputFields = () => [
  {
    label: "Charge %",
    onContextMenu: () => false,
    name: "chargePercentage",
    type: "number",
    required: true,
    placeholder: "50",
    className: "inputForm",
    prefix: "%",
    min: 1,
    max: 100,
  },
];
