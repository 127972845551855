import { DEFAULT_PREFERENCES } from "../data";

/**
 * Loads the session storage preference
 * @param {String} preference The session storage preference key
 */
function loadLivePreference(preference) {
  if (!preference || typeof preference !== "string") {
    return null;
  }

  return sessionStorage.getItem(preference) !== null
    ? JSON.parse(sessionStorage.getItem(preference))
    : DEFAULT_PREFERENCES[preference] || null;
}

export default loadLivePreference;
