import { GDriveIcon } from "../../../../assets"
import "./CardHeader.scss"

const TITLE = "Drive Files"

const CardHeader = () => {
  return (
    <div className="docFileTreeHeader">
      {/* <span className="gDriveIcon">
        <GDriveIcon />
      </span> */}
      <span className="docFileTreeHeaderTitle">{TITLE}</span>
    </div>
  )
}

export default CardHeader
