import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const violations = (IdHandler, updatedDynamicStates) => {
  return {
    amount_due: () => ({
      title: "Violations Amount",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Violations",
        field: "amount_due",
        labels: months,

        datasets: [
          {
            label: "Violations",
            data: (() => {
              let violations = [];
              for (let i = 0; i < 12; i++) {
                violations = [
                  ...violations,

                  (() => {
                    const amount = (
                      updatedDynamicStates?.[IdHandler] || []
                    )?.filter(
                      (b) =>
                        b.issue_date >= getMonthsTimestamps()[i] &&
                        b.issue_date <= getMonthsTimestamps("end")[i]
                    );

                    return !!amount?.length
                      ? amount
                          ?.map((a) => a.amount_due)
                          ?.reduce(
                            (previousValue, currentValue) =>
                              parseInt(previousValue) + parseInt(currentValue)
                          )
                      : 0;
                  })(),
                ];
              }
              return violations;
            })(),

            backgroundColor: (() => {
              let colors = [];
              for (let i = 0; i < 12; i++) {
                colors = [...colors, getRandomColor()];
              }
              return colors;
            })(),

            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //amount_due
    issue_date: () => ({
      title: "Violations Issued Date",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Violations",
        field: "issue_date",
        labels: months,

        // getMonthsTimestamps()[0]

        datasets: [
          {
            label: "Violations",
            data: (() => {
              let violations = [];
              for (let i = 0; i < 12; i++) {
                violations = [
                  ...violations,
                  (updatedDynamicStates?.[IdHandler] || [])
                    ?.map((a) => a?.issue_date)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length,
                ];
              }
              return violations;
            })(),

            backgroundColor: (() => {
              let colors = [];
              for (let i = 0; i < 12; i++) {
                colors = [...colors, getRandomColor()];
              }
              return colors;
            })(),

            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //issue_date
  }; //Violations
};
