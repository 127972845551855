const convertToNumbers = (arr) => {
  const uniqueStrings = Array.from(new Set(arr));
  const stringToNumber = {};
  uniqueStrings.forEach((str, index) => {
    stringToNumber[str] = index;
  });
  return arr.map((str) => stringToNumber[str]);
};

export const getNestedTableHeaders = (headers) => {
  const tableHeadersNumeric = {};
  for (const key in headers) {
    if (headers.hasOwnProperty(key)) {
      tableHeadersNumeric[key] = convertToNumbers(headers[key]);
    }
  }
  return tableHeadersNumeric;
};
