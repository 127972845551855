import types from "../types";
import { updateArrayElement } from "../utils";

const initialState = {
  userConfiguration: undefined,
};

const userConfigReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { userConfiguration = {} } = state;
  const { allUsers = {} } = userConfiguration || {};
  const { Items = [] } = allUsers || {};

  switch (type) {
    case types.SET_USER_CONFIG:
      return {
        ...state,
        userConfiguration: payload,
      };
    case types.SET_USER_CONFIG_KEY: {
      return {
        ...state,
        userConfiguration: {
          ...userConfiguration,
          allUsers: {
            ...allUsers,
            Items: updateArrayElement(Items, payload, "identityId"),
          },
          ...(payload.identityId === userConfiguration.identityId && payload),
        },
      };
    }

    case types.UPDATE_OTHER_USER:
      return {
        ...state,
        userConfiguration: {
          ...userConfiguration,
          allUsers: {
            ...allUsers,
            Items: updateArrayElement(Items, payload, "identityId"),
          },
        },
      };

    case types.UPDATE_SPECIFIC_USER:
      const { identityId, ...rest } = payload;

      const updatedUsers = userConfiguration.allUsers.Items.map((user) =>
        user.identityId === identityId
          ? {
              ...user,
              ...rest,
            }
          : user
      );

      const updatedUserConfig =
        userConfiguration.identityId === identityId
          ? {
              ...userConfiguration,
              ...rest,
              allUsers: { Items: updatedUsers },
            }
          : {
              ...userConfiguration,
              allUsers: { Items: updatedUsers },
            };

      return {
        ...state,
        userConfiguration: updatedUserConfig,
      };

    default:
      return state;
  }
};

export default userConfigReducer;
