import uniq from "lodash/uniq";

export const incidents = (incidentsData, updatedDynamicStates, IdHandler) => {
  const nypd = { Yes: 0, No: 0 };
  const fdny = { Yes: 0, No: 0 };
  const paramedics = { Yes: 0, No: 0 };
  const osha = { Yes: 0, No: 0 };
  const dobBestSquad = { Yes: 0, No: 0 };
  const incidentWitnessed = { Yes: 0, No: 0 };
  const injuredPerson = { Yes: 0, No: 0 };

  (incidentsData || [])?.forEach((a) => {
    nypd[a?.incidentReport[1]?.values[0]?.value] += 1;
    fdny[a?.incidentReport[1]?.values[1]?.value] += 1;
    paramedics[a?.incidentReport[1]?.values[2]?.value] += 1;
    osha[a?.incidentReport[1]?.values[3]?.value] += 1;
    dobBestSquad[a?.incidentReport[1]?.values[4]?.value] += 1;
    incidentWitnessed[a?.incidentReport[2]?.values[0]?.value] += 1;
    injuredPerson[a?.incidentReport[2]?.values[1]?.value] += 1;
  });

  return {
    driverName: () => ({
      title: "Incidents: Driver Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "driverName",
        content: uniq((incidentsData || [])?.map((a) => a?.driverName))?.map(
          (driverName, index) => ({
            key: index,
            title: driverName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.driverName === driverName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Incidents By " + driverName,
            contentsHandler: " Incidents By " + driverName,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),

    incidentStatus: () => ({
      title: "Incident Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentStatus",
        content: uniq(
          (incidentsData || [])?.map((a) => a?.incidentStatus)
        )?.map((incidentStatus, index) => ({
          key: index,
          title: incidentStatus,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.incidentStatus === incidentStatus
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + incidentStatus,
          contentsHandler: " " + incidentStatus,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),

    fleetName: () => ({
      title: "Incidents: Fleet Name",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "fleetName",
        content: uniq((incidentsData || [])?.map((a) => a?.fleetName))?.map(
          (fleetName, index) => ({
            key: index,
            title: fleetName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.fleetName === fleetName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Incidents By " + fleetName,
            contentsHandler: " Incidents By " + fleetName,
          })
        ),
      },
      id: IdHandler,
      width: "1600px",
    }),

    NYPD: () => ({
      title: "NYPD Involved Incidents",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "NYPD",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: nypd?.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "Yes",
            contentsHandler: "Yes",
          },
          {
            key: 0,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: nypd?.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "No",
            contentsHandler: "No",
          },
        ],
      },
      id: IdHandler,
      width: "800px",
      isFilter: false,
    }),

    FDNY: () => ({
      title: "FDNY Involved Incidents",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "NYPD",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: fdny?.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "Yes",
            contentsHandler: "Yes",
          },
          {
            key: 0,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: fdny?.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "No",
            contentsHandler: "No",
          },
        ],
      },
      id: IdHandler,
      width: "800px",
      isFilter: false,
    }),

    PARAMEDICS: () => ({
      title: "Paramedics Involved Incidents",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "PARAMEDICS",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: paramedics?.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "Yes",
            contentsHandler: "Yes",
          },
          {
            key: 0,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: paramedics?.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "No",
            contentsHandler: "No",
          },
        ],
      },
      id: IdHandler,
      width: "800px",
      isFilter: false,
    }),

    OSHA: () => ({
      title: "OSHA Involved Incidents",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "OSHA",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: osha?.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "Yes",
            contentsHandler: "Yes",
          },
          {
            key: 0,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: osha?.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "No",
            contentsHandler: "No",
          },
        ],
      },
      id: IdHandler,
      width: "800px",
      isFilter: false,
    }),

    dobBestSquad: () => ({
      title: "DOB Best Squad Involved Incidents",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "dobBestSquad",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: dobBestSquad?.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "Yes",
            contentsHandler: "Yes",
          },
          {
            key: 0,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: dobBestSquad?.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "No",
            contentsHandler: "No",
          },
        ],
      },
      id: IdHandler,
      width: "800px",
      isFilter: false,
    }),

    incidentWitnessed: () => ({
      title: "Incidents Witnessed",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentWitnessed",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: incidentWitnessed?.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "Yes",
            contentsHandler: "Yes",
          },
          {
            key: 0,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: incidentWitnessed?.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "No",
            contentsHandler: "No",
          },
        ],
      },
      id: IdHandler,
      width: "800px",
      isFilter: false,
    }),

    injuredPerson: () => ({
      title: "Injured Person",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Incidents",
        field: "injuredPerson",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: injuredPerson?.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "Yes",
            contentsHandler: "Yes",
          },
          {
            key: 0,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: injuredPerson?.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: "No",
            contentsHandler: "No",
          },
        ],
      },
      id: IdHandler,
      width: "800px",
      isFilter: false,
    }),
  };
};
