export function filterPaymentsByInvoiceIdAndDate(payments, invoiceId) {
  const filteredPayments = payments.filter((payment) => {
    return payment.invoices.some((invoice) => invoice.invoiceId === invoiceId);
  });

  if (filteredPayments.length > 0) {
    // Find the payment with the highest paymentDate
    const maxPayment = filteredPayments.reduce((maxPayment, currentPayment) => {
      return currentPayment.paymentDate > maxPayment.paymentDate
        ? currentPayment
        : maxPayment;
    });

    return maxPayment;
  }

  return undefined;
}

export function findLastTrueStatusIndex(array) {
  return array.reduceRight(
    (acc, current, index) => (acc === -1 && current.status ? index : acc),
    -1
  );
}
