import { uniq } from "lodash";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const opportunities = (
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  return {
    opportunityStatus: () => ({
      title: "Opportunities",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStatus",
        title: "Status Of Opportunities",
        subtitle: "",
        content: programFields
          ?.find((a) => a?.fieldName === "Status of opportunities")
          ?.fieldOptions?.map(({ statusName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (a) => a?.opportunityStatus === statusName
              ).length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: statusName,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.opportunityStatus === statusName
            ).length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }),
    opportunityStage: () => ({
      title: "Opportunities",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStage",
        title: "Stage Of Opportunities",
        subtitle: "",
        content: programFields
          ?.find((a) => a?.fieldName === "Opportunity Stage")
          ?.fieldOptions?.map(({ detailedStageName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (a) => a?.opportunityStage === detailedStageName
              ).length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: detailedStageName,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.opportunityStage === detailedStageName
            ).length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }),

    projectExecutive: () => ({
      title: "Opportunity",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectExecutive",
        title: "Project Executive",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    projectManager: () => ({
      title: "Opportunity",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectManager",
        title: "Project Manager",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])
              ?.map((a) => a?.projectManager)
              ?.flat(),
            "nameOfUser"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  }; //opportunities
};
