/**
 *
 * @param {Array} projectManagers - The array of project managers.
 * @returns
 */
const projectManagerSelect = (projectManagers = []) => ({
  showSearch: true,
  allowClear: true,
  mode: "multiple",
  options: projectManagers,
});

export default projectManagerSelect;
