export const generateInputFields = ({ documents }) => [
  {
    label: "Document Types",
    onContextMenu: () => false,
    name: "documentTypes",
    type: "styledmultiselect",
    required: true,
    placeholder: "Choose document types...",
    className: "inputForm",
    customOptions: documents,
  },
];
