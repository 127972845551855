import { API } from "aws-amplify";

export async function getTeams(setTeams) {
  API.get("teams", "/teams")
    .then((res) => {
      setTeams(res);
    })
    .catch((err) => {
      console.log("Error fetching teams: ", err);
    });
}
