import types from "../../types";

const initialState = {
  preferences: undefined,
};

const preferencesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PREFERENCES:
      return {
        ...state,
        preferences: payload,
      };
    case types.SET_BASE_PAGE_FILTERS:
      return {
        ...state,
        preferences: { ...state.preferences, basepages: payload },
      };
    case types.UPDATE_PREFERENCES:
      return {
        ...state,
        preferences: { ...state.preferences, preferences: payload },
      };
    default:
      return state;
  }
};

export default preferencesReducer;
