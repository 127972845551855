import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const opportunities = (
  programFields,
  updatedDynamicStates,
  IdHandler
) => {
  return {
    opportunityStage: () => ({
      title: "Opportunity Stage",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStage",
        content: programFields
          ?.find((a) => a?.fieldName === "Opportunity Stage")
          ?.fieldOptions?.map(({ detailedStageName }, index) => ({
            key: index,
            title: detailedStageName,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.opportunityStage === detailedStageName
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${detailedStageName} Opportunity`,
            contentsHandler: ` ${detailedStageName} Opportunity's`,
          })),
      },

      id: IdHandler,
      width: "1600px",
    }),

    projectExecutive: () => ({
      title: "Opportunity Project Executive",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectExecutive",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${key} Opportunity`,
          contentsHandler: ` ${key} Opportunity's`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),

    projectManager: () => ({
      title: "Opportunity Project Manager",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Opportunities",
        field: "projectManager",
        content: Object.entries(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])
              ?.map((a) => a?.projectManager)
              ?.flat(),
            "label"
          )
        )?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${key} Opportunity`,
          contentsHandler: ` ${key} Opportunity's`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  }; //opportunities
};
