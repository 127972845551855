import { message } from "antd";

export const getRowNodeId = (data) => data["itemId"];

export const updateCellValue = (prev, props, vendor, remainingValue) => {
  const changedProw = prev?.find(
    ({ itemId }) => props?.data?.itemId === itemId
  );

  let taxAmount = parseFloat(props.newValue * vendor?.vendorTaxRate || 0);

  if (remainingValue - taxAmount < 0) {
    message.warning("Amount is applied without tax.");
    taxAmount = 0;
  }
  props.oldValue = parseFloat(props.oldValue).toFixed(2);
  props.newValue = parseFloat(props.newValue).toFixed(2);
  changedProw.amount = parseFloat(props.newValue);
  changedProw.taxAmount = taxAmount;
  changedProw.tax = taxAmount > 0 ? true : false;
  changedProw.billable = taxAmount > 0 ? true : false;
  changedProw.total =
    parseFloat(changedProw?.amount) +
    (changedProw?.tax ? changedProw?.taxAmount || 0 : 0);

  return structuredClone(prev);
};
