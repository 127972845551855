import React from "react";

import { WarningModal } from "../../../../../commonComponents";
import { WarningTriangle } from "../../../../DynamicView/src";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../Communication/assets";

const ForceToInspectModal = ({
  visible,
  setVisible,
  currentProject,
  onStatusChange,
  setDispatchModalVisible,
}) => {
  return (
    <WarningModal
      {...{
        visible,
        setVisible,
        title: "Force To Inspect",
        className: "force-to-inspect-modal",
        footer: [
          {
            text: "No",
            onClick: () => setVisible(false),
            icon: <XIcon />,
            className: "mondayButtonRed",
            disabled: false,
          },
          {
            text: "Yes",
            onClick: () => {
              onStatusChange({
                projectId: currentProject?.projectId,
                status: "Pending",
              });
              setVisible(false);
              setDispatchModalVisible(true);
            },
            icon: <TickIcon />,
            disabled: false,
          },
        ],
      }}
    >
      <WarningTriangle />
      <div className="forceToInspectModalTitle">
        Are you sure you want to force to inspect?
      </div>
      <div>You can't undo this action!</div>
    </WarningModal>
  );
};

export default ForceToInspectModal;
