import { useCallback, useSyncExternalStore } from "react";

/**
 * @typedef StoreInitConfig
 * @property {string} path
 * @property {number} [maxRetries=450]
 * @property {number} [retryTimeout=15000]
 * @property {(socket: WebSocket) => any} [onopen]
 * @property {(socket: WebSocket) => any} [onclose]
 */

//#region EXTERNAL STORE
class ExternalStore {
  /** @type {Record<string, Function[]>} */
  #listeners = {};

  constructor() {}

  /** @param {string} group */
  storeUpdate(group) {
    const pathListeners = this.#listeners?.[group] || [];
    for (const listener of pathListeners) {
      listener();
    }
  }

  /**
   * @param {Function} listener
   * @param {string} group
   */
  storeListen(listener, group) {
    this.#listeners = {
      ...this.#listeners,
      [group]: [...(this.#listeners?.[group] || []), listener],
    };

    return () => {
      this.#listeners = {
        ...this.#listeners,
        [group]: (this.#listeners?.[group] || []).filter((l) => l !== listener),
      };

      if (!this.#listeners?.[group]?.length) {
        delete this.#listeners[group];
        this.cleanup(group);
      }
    };
  }

  /**
   * Cleanup is called when the listener component
   * is unmounted, custom implementation is left
   * to the store that inherits this property
   * @param {string} group
   */
  cleanup(group) {}

  /**
   * The store needs to implement this function
   * in order for the listener component to get
   * state updates
   */
  getSnapshot() {}
}

//#region SOCKET STORE
class SocketStore extends ExternalStore {
  /** @type {Map<string, WebSocket>} */
  #sockets = new Map();

  /** @type {Map<string, StoreInitConfig & {retries: number}>} */
  #socketConfigs = new Map();

  /** @type {Map<string, boolean>} */
  #onlineStatuses = new Map();

  constructor() {
    super();
  }

  /**
   * @param {Function} listener
   * @param {StoreInitConfig} config
   */
  listen(listener, config) {
    const { path } = config;

    if (!this.#socketConfigs.has(path)) {
      this.#socketConfigs.set(path, { ...config, retries: 0 });
    }

    if (!this.#sockets.has(path)) {
      this.#createSocket(path);
    }

    return this.storeListen(listener, path);
  }

  /** @param {string} path */
  #createSocket(path) {
    try {
      const newSocket = new WebSocket(path);
      this.#sockets.set(path, newSocket);

      newSocket.onopen = this.#openHandler.bind(this, path);
      newSocket.onclose = this.#retryHandler.bind(this, path);
    } catch (err) {
      console.error(`Socket connection failed ${path}: `, err);
    }
  }

  #openHandler(...args) {
    /** @type {[string]} */
    const [path] = args;
    const config = this.#socketConfigs.get(path) || {};

    if (config.onopen && typeof config.onopen === "function") {
      config.onopen(this.#sockets.get(path));
    }

    this.#socketConfigs.set(path, { ...config, retries: 0 });
    this.#onlineStatuses.set(path, true);

    this.storeUpdate(path);
  }

  async #retryHandler(...args) {
    /** @type {[string, CloseEvent]} */
    const [path, closeEvent] = args;
    const config = this.#socketConfigs.get(path);

    if (closeEvent.code === 1005) {
      // this is a type of error that happens when we try
      // to connect the socket to a valid URI but it
      // doesn't have the required socket routes
      return;
    }

    if (
      config.retries >= config.maxRetries ||
      config.retries === Number.MAX_SAFE_INTEGER
    ) {
      return;
    }

    if (config.retries) {
      // we make the first retry instant in order
      // to get the offline status immediately
      await this.#sleep(config.retryTimeout);
    }

    this.#createSocket(path);

    if (this.#onlineStatuses.get(path) === true) {
      this.#onlineStatuses.set(path, false);

      this.storeUpdate(path);

      if (config?.onclose && typeof config?.onclose === "function") {
        config.onclose(this.#sockets.get(path));
      }
    }

    ++config.retries;
    this.#socketConfigs.set(path, { ...config });
  }

  /** @param {number} [ms=2000] */
  async #sleep(ms = 2000) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });
  }

  /** @param {string} path */
  getSnapshot(path) {
    return this.#sockets.get(path);
  }

  /** @param {string} path */
  cleanup(path) {
    const socket = this.#sockets.get(path);
    socket.onclose = function () {};

    socket.close(1000);

    this.#sockets.delete(path);
    this.#socketConfigs.delete(path);
    this.#onlineStatuses.delete(path);
  }
}

const socketStore = new SocketStore();

/**
 * Hook used to handle multiple socket connections
 * while providing automatic and custom retry connection handling
 *
 * Allows multiple components to listen to the same
 * socket instance at the same time without the need of
 * recreating new sockets or importing sockets
 *
 * ON RECONNECTION THE HOOK RETURNS A NEW SOCKET INSTANCE.
 * This means that all of the socket handlers that
 * are defined in the component MUST be re-applied
 *
 * @param {StoreInitConfig} param
 */
function useSocket(param) {
  //#region HOOK
  const {
    path,
    maxRetries = 450,
    onopen = () => {},
    onclose = () => {},
    retryTimeout = 15000,
  } = param;

  const listenerFunc = useCallback((listener) => {
    return socketStore.listen(listener, {
      path,
      maxRetries,
      onclose,
      onopen,
      retryTimeout,
    });
  }, []);

  const getSnapshot = useCallback(() => {
    return socketStore.getSnapshot(path);
  }, [path]);

  /** @type {WebSocket} */
  const socket = useSyncExternalStore(listenerFunc, getSnapshot);
  return { socket, online: socket?.readyState === WebSocket.OPEN };
}

export default useSocket;
