import { forceToNumber } from "../../../utils/checkers";

export const clientBalanceCalculator = (paymentVal, clientBalance) => {
  const remainingAmount =
    Number(paymentVal || 0) + Math.abs(Number(clientBalance || 0));
  if (remainingAmount !== 0) return -remainingAmount;
  else return 0;
};

export const limitMaxAmount = ({ appliedAmount = 0, invoices = [] }) => {
  let tempPaymentAmount = appliedAmount;
  if (Array.isArray(invoices)) {
    invoices.forEach((element) => {
      if (element?.invoiceChecked) {
        console.log("tempPaymentAmount", { appliedAmount, tempPaymentAmount });
        let newTemp = tempPaymentAmount - element.invoicePaidAmount;
        if (newTemp < 0) tempPaymentAmount = 0;
        else tempPaymentAmount = newTemp;
      }
    });
    return tempPaymentAmount;
  }
  return tempPaymentAmount;
};

export const appliedAmountSumCalc = ({ invoices = [] }) => {
  let res = 0;
  if (Array.isArray(invoices)) {
    res = invoices.reduce((acc, obj) => {
      return acc + forceToNumber(obj?.invoicePaidAmount);
    }, 0);
  }
  return res;
};

export const calculateInvoicePaidItem = ({
  fakePayment = {},
  invoicePaidNumber,
  inputPayment = 0,
}) => {
  const tempFake = structuredClone(fakePayment);
  let idx = tempFake.invoices?.findIndex(
    (el) => el.invoiceNumber === invoicePaidNumber
  );
  if (idx !== -1) {
    const myInvoice = tempFake.invoices[idx];

    myInvoice.invoicePaidAmount = forceToNumber(inputPayment);
    myInvoice.invoiceChecked = inputPayment > 0 ? true : false;
  }
  // tempFake.appliedAmount = appliedAmountSumCalc({
  //   invoices: tempFake.invoices || [],
  // });

  let VERIFIED_PAYMENT = tempFake;
  console.log("VERIFIED_PAYMENT", VERIFIED_PAYMENT);
  return tempFake;
};

export const currencyToNumber = (currencyString) => {
  // Remove any non-numeric characters from the string
  const numericString = currencyString.replace(/[^0-9.-]/g, "");

  // Convert the numeric string to a number and return it
  return parseFloat(numericString);
};
