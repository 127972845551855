import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const documentation = ({ tables }) => {
  return {
    docType: () => {
      const data = repeatedElementSum(tables?.Documentation, "docType");

      return {
        labels: Object.keys(data),

        data: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //doctype

    docStatus: () => {
      const data = repeatedElementSum(tables?.Documentation, "docStatus");

      return {
        labels: Object.keys(data),

        data: Object.values(data),
        backgroundColor: Object.keys(data)?.map(getRandomColor),
      };
    }, //docStatus
  }; //Documentation,
};
