import { useEffect, useState } from "react";
import { NoServiceIconLightMode } from "../../../../../../../SidebarPages/BasePage/src";
import { ConfigCard } from ".";
import { useHoistPricingContext } from "../context";
import { Responsive, WidthProvider } from "react-grid-layout";
import { MondayButton } from "../../../../../../../commonComponents";
import { HistoryOutlined } from "@ant-design/icons";
import { Add } from "../../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import { usePricingContext } from "../../../../PricingV2/context";

const ResponsiveGridLayout = WidthProvider(Responsive);

function ConfigContent({
  layout,
  setLayout,
  setModalVisible,
  selectedPriceSchemeID,
  pricingObject,
  isDarkMode,
}) {
  const { configuration = [], editable } = useHoistPricingContext();

  const { handleShowLogs } = usePricingContext();

  const [selectedScheme, setSelectedScheme] = useState();

  useEffect(() => {
    const priceScheme = pricingObject.priceSchemes.find(
      (scheme) => scheme.id === selectedPriceSchemeID
    );

    setSelectedScheme(priceScheme);

    setLayout(priceScheme?.layout || []);
  }, [selectedPriceSchemeID]);

  // Handle layout changes
  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  return (
    <div className="config-card-body">
      <>
        <div className="logbtn">
          <MondayButton
            className="mondayButtonBlue"
            id="newConfigButton"
            onClick={() =>
              handleShowLogs(`Schema ${selectedScheme.name} items`)
            }
            Icon={<HistoryOutlined width={18} />}
          >
            Logs
          </MondayButton>
          <MondayButton Icon={<Add />} onClick={() => setModalVisible(true)}>
            New Item
          </MondayButton>
        </div>
        {!!configuration.length ? (
          <ResponsiveGridLayout
            className="layout"
            allowOverlap={false}
            breakpoints={{
              lg: 2780,
              md: 2390,
              sm: 2000,
              xs: 1585,
              xxs: 1190,
              a: 780,
              b: 0,
            }}
            transformScale={1}
            cols={{ lg: 7, md: 6, sm: 5, xs: 4, xxs: 3, a: 2, b: 1 }}
            isDraggable={editable}
            isResizable={false}
            layouts={{ lg: layout }}
            onLayoutChange={handleLayoutChange}
          >
            {configuration.map((config) => (
              <div key={config.label}>
                <ConfigCard
                  config={config}
                  isDarkMode={isDarkMode}
                  selectedScheme={selectedScheme}
                />
              </div>
            ))}
          </ResponsiveGridLayout>
        ) : (
          <div className="empty-configs-section">
            <NoServiceIconLightMode />
            <div>No pricing configuration has been created</div>
          </div>
        )}
      </>
    </div>
  );
}

export default ConfigContent;
