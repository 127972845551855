import { useState } from "react";
import { PlusIcon } from "../../../../../../../../../assets/index";
import {
  AccountingConfirmationModal,
  ConfirmationModal,
  MondayButton,
  WarningModal,
} from "../../../../../../../../commonComponents/index";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src/index";
import { XIcon } from "../../../../../../../Communication/assets/index";
import { useRedux } from "../../../../../../../hooks/useRedux";
import formValidation from "../../utils/formValidation";
import "./Footer.scss";
import { WarningTriangle } from "../../../../../../../DynamicView/src";

const Footer = ({ handleSave, paymentData, handleClear, isDarkMode }) => {
  const [confirmData, setConfirmData] = useState();
  const [isWritable] = useRedux("paymentIsWritable");
  const [fakePayment] = useRedux("fakePayment");

  const onSaveClickHandler = (type) => {
    // Check if required fields are not empty
    if (!formValidation(fakePayment)) {
      return;
    }
    setConfirmData({
      visible: true,
      setVisible: () => {},
      onConfirm: () => {
        setConfirmData();
        handleSave(type);
      },
      onCancel: () => {
        setConfirmData();
      },
      text:
        "Are you sure" +
        (paymentData
          ? " you want to save changes?"
          : " you want to save payment?"),
      title: paymentData ? "Confirm Changes" : "CONFIRMATION",
    });
  };

  return (
    <div
      className="payment-footer-container"
      style={paymentData && { justifyContent: "flex-end" }}
    >
      {!paymentData && (
        <MondayButton
          className={"cancel-button"}
          Icon={<XIcon />}
          onClick={() => {
            setConfirmData({
              ...{
                visible: true,
                setVisible: () => {},
                onConfirm: () => {
                  handleClear();
                  setConfirmData();
                },
                onCancel: () => {
                  setConfirmData();
                  handleClear();
                },
                onDiscard: () => {
                  handleClear();

                  setConfirmData();
                },
                text: paymentData
                  ? "Any change that you have made will be lost?"
                  : "This payment will be discarded",
                title: "Confirm Clear",
              },
            });
          }}
          tooltipCategory="Payments"
          tooltipKey="clear"
          disabled={!isWritable}
        >
          Clear
        </MondayButton>
      )}
      <div className="payment-footer-body">
        <MondayButton
          className={"save-button"}
          Icon={<TickIcon />}
          onClick={() => onSaveClickHandler()}
          disabled={!isWritable && paymentData}
          tooltipCategory="Payments"
          tooltipKey="saveAndClose"
        >
          Save & Close
        </MondayButton>
        <MondayButton
          className={"save-button"}
          Icon={<PlusIcon />}
          onClick={() => onSaveClickHandler("saveAndNew")}
          disabled={!isWritable && paymentData}
          tooltipCategory="Payments"
          tooltipKey="saveAndNew"
        >
          Save & New
        </MondayButton>
        {confirmData && (
          <WarningModal
            visible={confirmData?.visible}
            setVisible={confirmData?.setVisible}
            title={confirmData?.title}
            closable={true}
            className="logout-warning-modal"
            onKeyPress={(e) => onEnterPress(e)}
            darkMode={isDarkMode}
          >
            <div className="logout-modal-body">
              <span>
                <WarningTriangle />
              </span>
              <p>{confirmData?.text}</p>
              <div className="buttons">
                <MondayButton
                  onClick={confirmData?.onCancel}
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={confirmData?.onConfirm}
                  Icon={<TickIcon width={17} height={17} />}
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}
      </div>
    </div>
  );
};

export default Footer;
