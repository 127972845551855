import "./styles/TaskReminderInEdit.scss";
import { useState } from "react";
import moment from "moment";
import { Alert, Select, TimePicker, Typography, message } from "antd";
import { NotAllowed } from "../../../../../../../icons";
import { generateReminderDays } from "./utils";
import { InputComponent } from "../../../../../Fleet/components";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { ClockIcon } from "../../../../../DynamicView/components/FilterView/FilterIcons";
import { TimeDateProvider } from "../../../../../../DateComponents/TimeDateContext";
import { DropDownArrow } from "../../../../../Fleet/components/InputComponent/assets";
import { WithTooltip } from "../../../../../../commonComponents";
import ReminderModal from "../../components/ReminderModal";

const determineWeekendDay = (date) => {
  const day = dayjsNY(date)?.day();
  return day === 6 || day === 0;
};

export const TaskReminderInEdit = ({
  updatedTask = {},
  setUpdatedTask,
  isDarkMode,
}) => {
  const [visible, setVisible] = useState(false);

  const {
    taskDeadline,
    taskStartDate,
    remindMe = {},
    taskAssignedTo,
  } = updatedTask;

  const {
    reminderType,
    reminderValue,
    reminderTime,
    reminderDays,
    reminderAssignedTo,
    notifiedBy,
  } = remindMe;

  //directly update task
  const handleUpdateTask = (key, value) => {
    if (!value && value?.length === 0) {
      message.error("You should select at least one option!");
      return;
    }
    setUpdatedTask((prevTask) => ({
      ...prevTask,
      remindMe: { ...prevTask.remindMe, [key]: value },
    }));
  };

  // update task keys indirectly after reminderDays are updated
  const handleUpdateReminderDays = (key, value) => {
    if (!value && value?.length === 0) {
      message.error("You should select at least one option!");
      return;
    }
    generateReminderDays(
      { ...updatedTask, remindMe: { ...(remindMe || {}), [key]: value } },
      setUpdatedTask
    );
  };

  return (
    <div className="task-reminder-edit">
      <div className="reminder-text">
        {!reminderValue ? (
          <WithTooltip tooltipCategory="Tasks" tooltipKey="reminderText">
            <Alert
              message="No reminder yet"
              type="error"
              showIcon
              banner
              icon={<NotAllowed />}
              style={{ marginBottom: "5px" }}
            />
          </WithTooltip>
        ) : (
          <>
            <span>Reminder:</span> {reminderValue}
            &nbsp;Days&nbsp; at&nbsp;
            {moment(reminderTime)?.format("h:mm A")}
          </>
        )}
      </div>
      <div className="remindMe-update">
        <div className="task-reminder">
          <div className="input-section">
            <InputComponent
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "+" || e.key === ".") {
                  e.preventDefault();
                }
              }}
              value={reminderValue || ""}
              onChange={(e) =>
                handleUpdateReminderDays("reminderValue", e.target.value)
              }
              showSearch
              placeholder="Remind me"
              typeNumber={true}
              tooltipCategory="Tasks"
              tooltipKey="remindEvery"
              addonAfter={
                <Select
                  className="selectDays"
                  value={reminderType}
                  onSelect={(e) => handleUpdateReminderDays("reminderType", e)}
                  suffixIcon={<DropDownArrow />}
                  popupClassName={isDarkMode && "darkDropDown"}
                  options={[
                    { label: "Hours", value: "Hours" },
                    { label: "Days", value: "Days" },
                    { label: "Weeks", value: "Weeks" },
                  ]}
                />
              }
              style={{ maxWidth: "270px" }}
            />
            <TimePicker
              value={dayjsNY(reminderTime ?? "")}
              onChange={(e) =>
                handleUpdateReminderDays("reminderTime", moment(e.valueOf()))
              }
              suffixIcon={<ClockIcon />}
              popupClassName={isDarkMode && "darkDateDropDown"}
              format={"hh:mm a"}
              placeholder="Select time"
              allowClear={false}
            />

            <div className="reminderDays" style={{ marginBottom: 10 }}>
              {!!reminderValue &&
                !!reminderTime &&
                reminderDays?.map((item, index) => {
                  const isWeekend = determineWeekendDay(item);
                  return (
                    <div
                      key={index}
                      className={
                        !isWeekend
                          ? "messageReminderSet"
                          : "messageReminderError"
                      }
                    >
                      <Typography.Text
                        type={!isWeekend ? "success" : "danger"}
                        style={{ cursor: "pointer" }}
                        onClick={() => setVisible(true)}
                        data-testid="reminder-day-testid"
                      >
                        {dayjsNY(item).format("dddd, MMM D, YYYY h:mm a")}
                      </Typography.Text>
                    </div>
                  );
                })}
            </div>
            <WithTooltip
              tooltipCategory="Tasks"
              tooltipKey="reminderAssignedTo"
            >
              <Select
                mode="multiple"
                placeholder="Select who to remind"
                popupClassName={isDarkMode && "darkDropDown"}
                suffixIcon={<DropDownArrow />}
                options={taskAssignedTo?.map((user) => ({
                  ...user,
                  key: user.identityId,
                  label: user.nameOfUser,
                  value: user.nameOfUser,
                }))}
                onChange={(e, option) =>
                  handleUpdateTask("reminderAssignedTo", option)
                }
                value={reminderAssignedTo}
              />
            </WithTooltip>
            <WithTooltip tooltipCategory="Tasks" tooltipKey="reminderNotifyBy">
              <Select
                mode="multiple"
                placeholder="Get notified by"
                popupClassName={isDarkMode && "darkDropDown"}
                suffixIcon={<DropDownArrow />}
                onChange={(e) => handleUpdateTask("notifiedBy", e)}
                value={notifiedBy}
                style={{ marginTop: 10 }}
                options={[
                  { label: "Email", value: "Email" },
                  { label: "Notification", value: "Notification" },
                ]}
              />
            </WithTooltip>
          </div>
          <TimeDateProvider>
            {!!visible && (
              <ReminderModal
                {...{
                  visible,
                  setVisible,
                  setReminderDays: () => {},
                  reminderDays,
                  deadline: taskDeadline,
                  startDate: taskStartDate,
                  reminderValue,
                  setRefresh: () => {},
                  handleReminderDays: (e) =>
                    handleUpdateTask("reminderDays", e),
                }}
              />
            )}
          </TimeDateProvider>
        </div>
      </div>
    </div>
  );
};
