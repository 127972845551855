import { PauseCircleFilled } from "@ant-design/icons";
import { PauseIconFilled, NavigateIcon } from "../../../../../assets";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";

const MARKER_ICONS = {
  Stopped: PauseIconFilled,
  Idling: PauseCircleFilled,
  Moving: NavigateIcon,
  InMotion: NavigateIcon,
  NotTracking: WarningIcon,
  NeverCheckedIn: WarningIcon,
};

export default MARKER_ICONS;
