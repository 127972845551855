import { formatCurrency } from "../../../../utils";
import { getSums, groupBy } from "../../calculatingFunctions";

export const rentals = ({ tables, programFields }) => {
  const rentalStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Rentals.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    rentalStatus: () => ({
      content: rentalStatus?.statusName?.map((status, index) => ({
        key: index,
        title: status,
        taskNumber: tables?.rentals?.length,
        taskDone: tables?.rentals?.filter((a) => a?.rentalStatus === status)
          ?.length,
        color: rentalStatus?.statusColor[index],
        backgroundColor: "#B2DD9B",
        contentHandler: ` ${status} Application`,
        contentsHandler: ` ${status} Rentals`,
        custom:
          tables?.rentals &&
          groupBy(
            [tables?.rentals?.filter((a) => a?.rentalStatus === status)],
            "projectAddress"
          )?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Total Price": formatCurrency(
                    getSums({
                      data: [
                        tables?.rentals?.filter(
                          (a) => a?.rentalStatus === status
                        ),
                      ],

                      key: "totalPrice",
                    })
                  ),
                  "Total Applied Rent": formatCurrency(
                    getSums({
                      data: [
                        tables?.rentals?.filter(
                          (a) => a?.rentalStatus === status
                        ),
                      ],

                      key: "totalAppliedRent",
                    })
                  ),
                  "Total Tax": formatCurrency(
                    getSums({
                      data: [
                        tables?.rentals?.filter(
                          (a) => a?.rentalStatus === status
                        ),
                      ],

                      key: "totalTax",
                    })
                  ),
                  "Retainage Amount": formatCurrency(
                    getSums({
                      data: [
                        tables?.rentals?.filter(
                          (a) => a?.rentalStatus === status
                        ),
                      ],
                      key: "retainageAmount",
                    })
                  ),
                },
              },
              ...Object?.entries(el)?.map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Total Price": formatCurrency(
                      getSums({ data: value, key: "totalPrice" })
                    ),
                    "Total Applied Rent": formatCurrency(
                      getSums({ data: value, key: "totalAppliedRent" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalTax" })
                    ),
                    "Retainage Amount": formatCurrency(
                      getSums({ data: value, key: "retainageAmount" })
                    ),
                  },
                };
              }),
            ];
          }),
      })),
    }),
  };
};
