import { Tooltip } from "antd";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

import { useStateContext } from "../../../../../../../Context/Context";
import { formatNumberToPercentage } from "../../../../../../../../../../../utils";
import "./rate-cell-renderer.scss";

const RateCellRenderer = ({ value, serviceLabel, estimationId, elevation }) => {
  // Context state
  const { errors, darkMode } = useStateContext();

  const hasSumOfRatesError =
    errors &&
    errors[`${serviceLabel}/${estimationId}`]?.[
      `${elevation?.elevationId}/sumOfRates`
    ];

  return (
    <div
      className={darkMode ? "rate-cell-renderer-dark" : "rate-cell-renderer"}
    >
      <div className="rate-value">{formatNumberToPercentage(value)}</div>
      <div
        className="rate-status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {hasSumOfRatesError ? (
          <Tooltip title={hasSumOfRatesError}>
            <ExclamationCircleFilled style={{ color: "#FE4C4A" }} />
          </Tooltip>
        ) : (
          <CheckCircleFilled style={{ color: "#68C142" }} />
        )}
      </div>
    </div>
  );
};

export default RateCellRenderer;
