import { useEffect, useState } from "react";
import { Form, Modal, Select } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../Roles/src";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { v4 as uuidv4 } from "uuid";
import { getChangedData } from "../../../../../SidebarPages/Accounting/components/utilities";
import { DropDownArrow } from "../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { OptionsFormList } from "../../DocumentationConfiguration/components/NewCustomFieldModal/components";
import "./newFieldModal.scss";
import { updateDocumentTitle } from "../../../../../../utils";
import { useSelector } from "react-redux";

export function toCamelCase(str) {
  let camel = "";
  if (!str) {
    return "";
  } else {
    str
      .toLowerCase()
      .split(" ")
      ?.forEach((e) => {
        camel = camel + e?.charAt(0).toUpperCase() + e?.slice(1);
      });
    camel = camel?.replace(/[^a-zA-Z0-9!_.*'()-]/g, "");
  }
  return camel.charAt(0).toLocaleLowerCase() + camel.slice(1);
}

/**
 * Modal component for creating or editing a field in the settings page.
 *
 * @component
 * @param {boolean} open - Indicates whether the modal is open or not.
 * @param {function} setVisible - Function to control the visibility of the modal.
 * @param {Object} editData - Data of the field being edited, null if creating a new field.
 * @param {function} setEditData - Function to set the editData state.
 * @param {function} setChanges - Function to set the changes state.
 * @param {Array} setEditLogs - Function to set the editLogs state.
 * @param {Object} newEditLog - Object representing the new edit log.
 * @param {Array} setNewLogsList - Function to set the newLogsList state.
 * @param {boolean} isDarkMode - Indicates whether the dark mode is enabled or not.
 * @param {function} setNotifications - Function to set the notifications state.
 * @param {Object} setData - Function to set the data state.
 * @param {Object} selectedCategory - The selected category object.
 * @param {function} setSelectedCategory - Function to set the selectedCategory state.
 * @returns {JSX.Element} The NewFieldModal component.
 */
const NewFieldModal = ({
  open,
  setVisible,
  editData,
  setEditData,
  setChanges,
  setEditLogs,
  newEditLog,
  setNewLogsList,
  isDarkMode,
  setNotifications,
  setData,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(null);
  const [optionError, setOptionError] = useState();

  const onClose = () => {
    setEditData(false);
    setVisible(false);
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setSelectedType(null);
    form.resetFields();
    setOptionError();
  };

  /**
   * Handles the save action for the form field.
   */
  const onSave = () => {
    const optionsList = form.getFieldValue("options");

    //Validation for field Options
    if (optionsList) {
      const emptyOptions = optionsList?.filter((el) => el === undefined);

      const duplicates = optionsList?.filter(
        (el, index) => optionsList?.indexOf(el) !== index
      );

      if (duplicates?.length === 0) {
        setOptionError();
      } else if (duplicates?.length !== 0) {
        setOptionError(
          `Remove these options as they already exist ${duplicates}`
        );
        return;
      }

      if (optionsList?.length === 0) {
        setOptionError(`Please provide the options`);
        return;
      } else {
        setOptionError();
      }

      if (emptyOptions?.length === 0) {
        setOptionError();
      } else {
        setOptionError("Please provide a value for each option !");
        return;
      }
    }

    //Logic for editing existing field
    if (editData) {
      setNotifications((prev) => [
        ...prev,
        { actionType: "onFieldEdit", category: selectedCategory?.name },
      ]);
      const oldField = selectedCategory.items.find(
        (field) => field.id === editData.id
      );

      let updatedField = {
        id: editData.id,
        label: form.getFieldValue("label"),
        formItemName: toCamelCase(form.getFieldValue("label")),
        required: form.getFieldValue("required"),
        options: form.getFieldValue("options"),
        type: form.getFieldValue("type"),
        placeholder: `${form.getFieldValue("label")}...`,
        typeNumber: form.getFieldValue("type") === "number",
        defaultField: !!editData?.defaultField,
      };
      if (editData.index) {
        updatedField.index = editData.index;
      }

      let result = getChangedData(updatedField, oldField);

      if (!!result) {
        result.prev.options = [];
        result.curr = {
          ...result.curr,
          required: result.curr.required ? "Required" : "Not Required",
        };
        result.prev = {
          ...result.prev,
          required: result.prev.required ? "Required" : "Not Required",
        };

        newEditLog.recordId = updatedField.id;
        newEditLog.recordName = updatedField.label;
        newEditLog.actionType = "Edit";
        newEditLog.currentData = {
          [selectedCategory.name]: {
            [updatedField.label]: result.curr,
            dummyKey: undefined,
          },
        };
        newEditLog.previousData = {
          [selectedCategory.name]: {
            [updatedField.label]: result.prev,
            dummyKey: undefined,
          },
        };
        newEditLog.updatedKeys.push(updatedField.label);

        setEditLogs((prev) => [...prev, newEditLog]);
        setNewLogsList((prev) => [...prev, newEditLog]);
      }

      setData((prev) => ({
        ...prev,
        [selectedCategory.name]: selectedCategory.items.map((item) =>
          item.id !== updatedField.id ? item : updatedField
        ),
      }));

      const newSelectedCategory = {
        ...selectedCategory,
        items: selectedCategory.items.map((item) =>
          item.id !== updatedField.id ? item : updatedField
        ),
      };
      setSelectedCategory(newSelectedCategory);
    }
    //Logic for creating a new field
    else {
      setNotifications((prev) => [
        ...prev,
        { actionType: "onFieldCreation", category: selectedCategory?.name },
      ]);
      let newField = {
        id: uuidv4(),
        label: form.getFieldValue("label"),
        formItemName: toCamelCase(form.getFieldValue("label")),
        required: form.getFieldValue("required"),
        options: form.getFieldValue("options"),
        type: form.getFieldValue("type"),
        placeholder: `${form.getFieldValue("label")}...`,
        typeNumber: form.getFieldValue("type") === "number",
        createdAt: Date.now(),
        createdBy: userConfiguration.nameOfUser,
        // defaultField: true,
      };
      let items = selectedCategory.items;
      if (items.length === 0) {
        newField.index = 1;
      } else {
        let lastEl = items[items.length - 1];
        if (!!lastEl.index) {
          newField.index = lastEl.index + 1;
        }
      }

      let result = getChangedData(newField, {});

      if (!!result) {
        result.prev.options = [];
        result.curr = {
          ...result.curr,
          required: result.curr.required ? "Required" : "Not Required",
        };

        newEditLog.recordId = newField.id;
        newEditLog.recordName = newField.label;
        newEditLog.actionType = "Create";
        newEditLog.currentData = {
          [selectedCategory.name]: {
            creation: result.curr,
            dummyKey: undefined,
          },
        };
        newEditLog.previousData = {
          [selectedCategory.name]: {
            creation: result.prev,
            dummyKey: undefined,
          },
        };
        newEditLog.updatedKeys.push(newField.label);
        setEditLogs((prev) => [...prev, newEditLog]);
        setNewLogsList((prev) => [...prev, newEditLog]);
      }

      setData((prev) => ({
        ...prev,
        [selectedCategory.name]: [...selectedCategory.items, newField],
      }));

      const newSelectedCategory = {
        ...selectedCategory,
        items: [...selectedCategory.items, newField],
      };

      setSelectedCategory(newSelectedCategory);
    }
    onClose();
    setChanges(true);
  };

  useEffect(() => {
    if (editData) {
      // update values of form when editing
      setSelectedType(editData.type);
      form.setFieldValue("label", editData.label);
      form.setFieldValue("required", editData.required);
      form.setFieldValue("type", editData.type);
      form.setFieldValue("options", editData.options);
    }
  }, [editData]);

  return (
    <Modal
      open={open}
      setVisible={setVisible}
      onCancel={() => {
        setEditData(false);
        onClose();
      }}
      destroyOnClose={true}
      closeIcon={<XIcon />}
      title={editData ? `Edit ${editData.label}` : "New Detail"}
      afterOpenChange={(event) => {
        event &&
          updateDocumentTitle({
            newTitle: editData ? `Edit ${editData.label}` : "New Detail",
          });
      }}
      className={`newQuestionForFleetModal ${
        isDarkMode && "newQuestionForFleetModalDark"
      }`}
      footer={
        <>
          <MondayButton
            className="mondayButtonRed"
            onClick={onClose}
            Icon={<XIcon />}
          >
            Cancel
          </MondayButton>

          <MondayButton
            onClick={() => form.validateFields().then(onSave)}
            Icon={<TickIcon width={18} height={18} />}
          >
            Save
          </MondayButton>
        </>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Name"
          name="label"
          formItemName="Label"
          rules={[
            {
              validator: (_, value) =>
                selectedCategory.items
                  ?.filter((field) =>
                    editData ? field?.label !== editData?.label : field
                  )
                  ?.some(
                    (field) =>
                      field?.label?.toLowerCase() === value?.toLowerCase()
                  )
                  ? Promise.reject(new Error("This field name already exists!"))
                  : Promise.resolve(),
            },
            {
              required: true,
              message: "Please type in the field name",
            },
          ]}
        >
          <InputComponent
            className="labelInput"
            type="input"
            placeholder="Type in the field name"
          />
        </Form.Item>
        <Form.Item
          label="Required"
          name="required"
          formItemName="required"
          placeholder="Select if this field is Required?"
          rules={[
            {
              required: true,
              message: "Please select if this field is required",
            },
          ]}
        >
          <Select
            className="requiredSelect"
            suffixIcon={<DropDownArrow />}
            popupClassName={isDarkMode && "darkDropDown"}
            options={[
              { value: true, label: "Required" },
              { value: false, label: "Not Required" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          formItemName="type"
          placeholder="Select the field type"
          rules={[{ required: true, message: "Please select the Field Type!" }]}
        >
          <Select
            className="requiredSelect"
            onSelect={(e) => setSelectedType(e)}
            suffixIcon={<DropDownArrow />}
            popupClassName={isDarkMode && "darkDropDown"}
            customOptions={[
              { value: "input", label: "Input" },
              { value: "radio", label: "Radio" },
              { value: "placesautocomplete", label: "Places auto complete" },
              { value: "datepicker", label: "Date Picker" },
              { value: "select", label: "Select" },
              { value: "number", label: "Number" },
              { value: "timepicker", label: "Time picker" },
              { value: "phone", label: "Phone" },
              { value: "dateinput", label: "Date Input" },
            ]}
          ></Select>
        </Form.Item>
        {(selectedType === "radio" || selectedType === "select") && (
          <>
            <OptionsFormList {...{ form, formItemName: "options" }} />
            <p style={{ color: "#ff4d4f" }}>{optionError}</p>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default NewFieldModal;
