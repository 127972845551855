import { message } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useRedux } from "../../../../hooks";
let loaded = false;
let rights = {
  canViewPrice: false, // Done!
  canExport: false, // Done!
  canAddOrRemoveAddons: false, // Done
  canAddOrRemovePLItems: false, // Done!
  canAddOrRemoveServices: false, //Done!
  canEditPrice: false, // Done!
  canAddOrRemoveElevations: false, //Done!
  canSeeRentals: false, // Done!
  canSaveInProject: false, // Done!
  canChangeStatus: false, // Done!
  canAccessProposalDesigner: false, // DONE!
  writeModeClearance: false, // Done!
};
const useTakeOffAccessRights = (accessRights) => {
  // const [rights, setRights] = useRedux("test", tempInital, false); // accessRights.children[0].children[0].children[0].children[0].children[0].children[0].children[0].canViewPrice
  const { userConfiguration } = useSelector((state) => state.userConfig);

  //   const tempRights = userConfiguration?.routeConfig
  //     ?.find(({ title }) => title === "Estimations/View")
  //     ?.children.find(({ title }) => title === "Take-Off") || { children: [] };

  const fetchAccessRights = () => {
    try {
      const accessRightsData = userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Estimations/View"
      );
      // console.log("accessRightsData", {
      //   accessRightsData,
      //   userConfiguration,
      //   accessRights,
      // });
      if (!!accessRightsData) {
        let children = accessRightsData?.children || [];
        let applicableRights =
          children.find((e) => e?.title === accessRights)?.children || [];
        if (Array.isArray(applicableRights) && applicableRights?.length > 0) {
          let temp = {};
          temp.writeModeClearance =
            children.find((e) => e?.title === accessRights)?.write || false;

          applicableRights?.forEach((e) => {
            // check if the key is in the mapTempValues
            if (!!mapTempKeys[e?.title?.toString()]) {
              temp[mapTempKeys[e.title]] = e?.write || false;
            } else {
              console.log("i sawww", e?.title);
            }
          });
          // console.log("temp444", temp);
          // setRights(temp);
          rights = temp;
        }
      }
    } catch (error) {
      // setRights(tempInital);
      console.error("Error fetching access rights:", error);
    } finally {
      loaded = true;
    }
  };

  if (!loaded) fetchAccessRights();
  let localStorageRights = localStorage.getItem("takeOffAccessRightsTemp");
  localStorageRights = JSON.parse(localStorageRights);
  console.log("localStorageRights", localStorageRights);
  return localStorageRights ? localStorageRights : rights;
};

export default useTakeOffAccessRights;
const mapTempKeys = {
  "Adding/Removing addons": "canAddOrRemoveAddons", //
  Export: "canExport", //
  //   "Pli Price Edit": "canEditPrice",
  //   "Addon Price Edit": "canEditPrice",
  "Adding/Removing pli's": "canAddOrRemovePLItems", //
  "Adding/Removing Service": "canAddOrRemoveServices", //
  "Price Edit": "canEditPrice", //
  "Adding/Removing Elevation": "canAddOrRemoveElevations",
  "Show Price": "canViewPrice", //
  "Show Rentals": "canSeeRentals", //
  "Save In Project": "canSaveInProject", //
  "Change Status": "canChangeStatus", //
  "Proposal Design": "canAccessProposalDesigner", //
};

const tempInital = {
  canViewPrice: false, // Done!
  canExport: false, // Done!
  canAddOrRemoveAddons: false, // Done
  canAddOrRemovePLItems: false, // Done!
  canAddOrRemoveServices: false, //Done!
  canEditPrice: false, // Done!
  canAddOrRemoveElevations: false, //Done!
  canSeeRentals: false, // Done!
  canSaveInProject: false, // Done!
  canChangeStatus: false, // Done!
  canAccessProposalDesigner: false, // DONE!
  writeModeClearance: false, // Done!
};
export const ENG_RIGHTS_TAKEOFF = {
  canViewPrice: false, // Done!
  canExport: false, // Done!
  canAddOrRemoveAddons: true, // Done
  canAddOrRemovePLItems: true, // Done!
  canAddOrRemoveServices: true, //Done!
  canEditPrice: false, // Done!
  canAddOrRemoveElevations: true, //Done!
  canSeeRentals: true, // Done!
  canSaveInProject: true, // Done!
  canChangeStatus: true, // Done!
  canAccessProposalDesigner: true, // DONE!
  writeModeClearance: true, // Done!
};
