if (!String.prototype.hasOwnProperty("capitalize")) {
  Object.defineProperty(String.prototype, "capitalize", {
    writable: false,
    enumerable: false,
    configurable: false,
    value() {
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
  });
}

if (!String.prototype.hasOwnProperty("initials")) {
  Object.defineProperty(String.prototype, "initials", {
    writable: false,
    enumerable: false,
    configurable: false,
    value() {
      return this.split(" ").reduce((acc, curr) => {
        return acc + curr.charAt(0).toUpperCase();
      }, "");
    },
  });
}

if (!String.prototype.hasOwnProperty("removeSpacesAndCommas")) {
  Object.defineProperty(String.prototype, "removeSpacesAndCommas", {
    writable: false,
    enumerable: false,
    configurable: false,
    value() {
      return this?.replace(/\s+/g, "")?.replace(/,/g, "")?.toLowerCase();
    },
  });
}
if (!Array.prototype.hasOwnProperty("insert")) {
  Object.defineProperty(Array.prototype, "insert", {
    writable: false,
    enumerable: false,
    configurable: false,
    value(index, ...items) {
      this.splice(index, 0, ...items);
    },
  });
}
