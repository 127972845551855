import { useRef, useState } from "react";

const useProgress = () => {
  const intervalRef = useRef(0);
  const [progress, setProgress] = useState(null);

  if (progress === 100) {
    clearInterval(intervalRef.current);
  }

  const endProgress = () => {
    setProgress(100);
  };

  const startProgress = (progresSpeed = 100) => {
    const startingTime = Date.now();
    clearInterval(intervalRef.current);
    setProgress(Date.now() - startingTime);

    intervalRef.current = setInterval(() => {
      const timeDifference = Date.now() - startingTime;
      const finalProgress =
        timeDifference / progresSpeed > 99
          ? 99
          : Math.trunc(timeDifference / progresSpeed);
      setProgress(finalProgress);
    }, 320);
  };

  return {
    progress,
    setProgress,
    startProgress,
    endProgress,
  };
};

export default useProgress;
