import { compareIncluding, getEndOf, getStartOf } from "../../utils";
import { sortBy, reverse, slice } from "lodash";
import { range } from "../../Fleet/utils";
import dayjs from "dayjs";
import {
  initialApprovedPermitQuery,
  initialDOBLeadQuery,
  initialElectricPermitQuery,
} from "../newSubLeadsPageData";
import { message } from "antd";
import { formatDobData } from "../utils";

var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

export const adapter = {
  "job application filing": {
    time: "filing_date",
    ownerBusinessName: "owner_s_business_name",
    permitType: "job_type",
  },
  "issued permits": {
    time: "dobrundate",
    ownerBusinessName: "owner_s_business_name",
    permitType: "permit_type",
  },
  "approved permits": {
    time: "approved_date",
    ownerBusinessName: "applicant_business_name",
  },
  "electrical permits": {
    time: "permit_issued_date",
    ownerBusinessName: "business_name",
  },
};

export const datesPlaceholder = {
  "job application filing": "By Filing Date",
  "issued permits": "By DOB Run Date",
  "approved permits": "By Approved Date",
  "electrical permits": "By Permit Issue Date",
};

//filter by time options
export const timePicker = [
  { value: "today", field: "Today" },
  { value: "yesterday", field: "Yesterday" },
  { value: "thisWeek", field: "This Week" },
  { value: "lastWeek", field: "Last Week" },
  { value: "thisMonth", field: "This Month" },
  { value: "lastMonth", field: "Last Month" },
  { value: "thisYear", field: "This Year" },
  { value: "lastYear", field: "Last Year" },
];

export const permitTypes = ({ jobApplicationStatus }) => ({
  "job application filing": jobApplicationStatus,
  "issued permits": [
    { key: "NB", value: "New Building" },
    { key: "DM", value: "Demolition & Removal" },
    { key: "SH", value: "Sidewalk Shed" },
    { key: "SF", value: "Scaffold" },
    { key: "AL", value: "Alteration" },
    { key: "EQ", value: "Construction Equipment" },
    { key: "CH", value: "Chute" },
    { key: "FN", value: "Fence" },
    { key: "OT", value: "Other-General Construction" },
    { key: "EW", value: "Equipment Work" },
    { key: "BL", value: "Boiler" },
    { key: "FA", value: "Fire Alarm" },
    { key: "FB", value: "Fuel Burning" },
    { key: "FP", value: "Fire Suppression" },
    { key: "FS", value: "Fuel Storage" },
    { key: "MH", value: "Mechanical/HVAC" },
    { key: "SD", value: "Stand pipe" },
    { key: "SP", value: "Sprinkler" },
    { key: "FO", value: "Foundation/Earthwork" },
    { key: "FO/EA", value: "Earthwork Only" },
    { key: "PL", value: "Plumbing" },
    { key: "SG", value: "Sign" },
  ],
});

//handle the x button on the top right side of the modal
export const handleCancel = (setIsModalVisible) => {
  setIsModalVisible(false);
};

//this function handle filter by time(just set the selectedValue to filter state so we can use it afterwords on okHandler)
export const timeHandler = ({ e, setFilterValues, selectedCategory }) => {
  setFilterValues((data) => ({
    ...data,
    [selectedCategory]: {
      ...data[selectedCategory],
      time: e,
      day: null,
      range: null,
    },
  }));
};

//this function handle filter by day(just set the selectedValue to filter state so we can use it afterwords on okHandler)
export const dayHandler = ({ e, setFilterValues, selectedCategory }) => {
  setFilterValues((data) => ({
    ...data,
    [selectedCategory]: {
      ...data[selectedCategory],
      time: null,
      day: e,
      range: null,
    },
  }));
};

//this function handle filter by day ranges(just set the selectedValue to filter state so we can use it afterwords on okHandler)
export const rangeHandler = ({ e, setFilterValues, selectedCategory }) => {
  setFilterValues((data) => ({
    ...data,
    [selectedCategory]: {
      ...data[selectedCategory],
      time: null,
      day: null,
      range: e,
    },
  }));
};
//ok handler makes the filter of unsliced data and sets dob data to the new filtered data
export const onOkHandler = ({
  filterValues,
  setIsModalVisible,
  setDobData,
  unslicedData,
  selectedCategory,
}) => {
  //this reduce checks if all fields are filled and if not removes unfilled ones (so they will not makes errors if filter function below)
  let filterData = Object.keys(filterValues[selectedCategory])?.reduce(
    (acc, curr) =>
      !!filterValues[selectedCategory][curr]
        ? { ...acc, [curr]: filterValues[selectedCategory][curr] }
        : acc,
    {}
  );

  //this is a multi filter function, it go to all records and check if obtains the value that we have putted to all the filter fields,
  //if the rrecords contains all the conditions, it pass and it apers to the filtered value of the user, if not it will be removed from rowData
  let filteredData = unslicedData[selectedCategory]?.filter((unslicedData) => {
    const {
      owners_full_name: ownersName,
      jobsite_full_address: jobsiteAddress,
      // permit_type: permitType,
    } = unslicedData;

    const businessName =
      unslicedData[adapter[selectedCategory]?.ownerBusinessName];
    const creationTime = unslicedData[adapter[selectedCategory]?.time];
    const permitType = unslicedData[adapter[selectedCategory]?.permitType];

    const inputFilters = {
      ownersName,
      jobsiteAddress,
      businessName,
    };

    return Object.keys(filterData)?.every((key) =>
      //here we checks if the record includes the characters that the user have putted to (owner name,jobsite and business name)
      Object.keys(inputFilters)?.includes(key)
        ? compareIncluding(inputFilters[key], filterData[key])
        : //here we checks if the record permit type is equal with one of selected types
        key === "permitType"
        ? filterData?.permitType?.some((data) => data === permitType)
        : //here we check if the record is created in the selected date
        key === "day"
        ? getStartOf.today(creationTime) === getStartOf.today(filterData?.day)
        : //here we check if the record is created between 2 selected dates
        key === "range"
        ? getStartOf.today(filterData?.range[0]) <= creationTime &&
          getStartOf.today(filterData?.range[1]) >= creationTime
        : //this handless the case when we want to filter by time (today,yesterday,this week ....)
          !filterData?.time ||
          (getStartOf[filterData?.time]() <= creationTime &&
            getEndOf[filterData?.time]() >= creationTime)
    );
  });

  //sets the new filtered spliced data
  setDobData((dobData) => ({
    ...dobData,
    [selectedCategory]:
      selectedCategory === "job application filing"
        ? filteredData
        : slice(filteredData, [0], [10000]),
  }));
  setIsModalVisible(false);
};

//===========================================fetch data==============================================================================

let fetchInitialQuery = undefined;

//this function generates query for fetch with the date that we have selected on fetch modal
export const fetchByDateHandler = ({
  e,
  setDaysFetchHandler,
  setQuery,
  selectedCategory,
}) => {
  setDaysFetchHandler((data) => ({
    ...data,
    [selectedCategory]: { range: null, day: e || null, time: null },
  }));

  fetchInitialQuery = {
    "job application filing": `https://data.cityofnewyork.us/resource/w9ak-ipjd.json?$select=*&$where=filing_date between '${dayjs(
      e
    )
      ?.startOf("day")
      ?.format("YYYY-MM-DDTH:mm:ss.SSS")}' and '${dayjs(e)
      ?.endOf("day")
      ?.format("YYYY-MM-DDTH:mm:ss.SSS")}'&$limit=50000`,
    "issued permits": `${initialDOBLeadQuery} dobrundate = '${dayjs(e)?.format(
      "MM/DD/YYYY"
    )} 00:00:00' LIMIT 20000`,
    "approved permits": `${initialApprovedPermitQuery} approved_date = '${dayjs(
      e
    )?.format("YYYY-MM-DD")}T00:00:00.000'  LIMIT 20000`,
    "electrical permits": `${initialElectricPermitQuery} permit_issued_date = '${dayjs(
      e
    )?.format("YYYY-MM-DD")}T00:00:00.000'  LIMIT 20000`,
  };
  setQuery(fetchInitialQuery[selectedCategory]);
};

//this function generates query for fetch based on the range that we selected on fetch range picker
export const fetchByRangeHandler = ({
  e,
  setDaysFetchHandler,
  setQuery,
  selectedCategory,
  isRangePicker,
}) => {
  const adapter = {
    today: [getStartOf?.today(), getEndOf?.today()],
    yesterday: [getStartOf?.yesterday(), getEndOf?.yesterday()],
    thisWeek: [getStartOf?.thisWeek(), getEndOf?.thisWeek()],
    lastWeek: [getStartOf?.lastWeek(), getEndOf?.lastWeek()],
    thisMonth: [getStartOf?.thisMonth(), getEndOf?.thisMonth()],
    lastMonth: [getStartOf?.lastMonth(), getEndOf?.lastMonth()],
    thisYear: [getStartOf?.thisYear(), getEndOf?.thisYear()],
    lastYear: [getStartOf?.lastYear(), getEndOf?.lastYear()],
  };

  // here we find how many days are between 2 dates that selected
  const diff = !!e
    ? dayjs
        ?.duration(
          dayjs(isRangePicker ? e[1] : adapter[e][1])?.valueOf() -
            dayjs(isRangePicker ? e[0] : adapter[e][0])?.valueOf()
        )
        ?.asDays()
    : 0;
  setDaysFetchHandler((data) => ({
    ...data,
    [selectedCategory]: {
      range: isRangePicker ? e || null : null,
      day: null,
      time: isRangePicker ? null : e,
    },
  }));
  {
    /*
  range function generates an array with numbers that are between 2 numbers that we provide, the first no. is 0,
  the second number is the difference between the dates that the user has chosen to fetch. With that array we can 
  subtract from the fetch date that we chose all the days that are between 2 chosen dates.

  ex.  fetch dates: 01/01/2022-01/03/2022(2 days between)  generated dates:(01/03/2022 -0days =01/03/2022  / 01/03/2022 -1day =01/02/2022  /  01/03/2022 -2days =01/01/2022 )
  */
  }

  fetchInitialQuery = {
    //query generator for job application filing
    "job application filing": `https://data.cityofnewyork.us/resource/w9ak-ipjd.json?$select=*&$where=filing_date between '${dayjs(
      isRangePicker ? e[0] : adapter[e][0]
    )
      ?.startOf("day")
      ?.format("YYYY-MM-DDTH:mm:ss.SSS")}' and '${dayjs(
      isRangePicker ? e[1] : adapter[e][1]
    )
      ?.endOf("day")
      ?.format("YYYY-MM-DDTH:mm:ss.SSS")}'&$limit=50000`,
    //query generator for issued permits
    "issued permits": range(0, diff + 1)?.reduce(
      (acc, curr, _, arr) =>
        (acc += ` dobrundate = '${dayjs(isRangePicker ? e[1] : adapter[e][1])
          ?.subtract(curr, "day")
          ?.format("MM/DD/YYYY")} 00:00:00'${
          curr !== arr.at(-1) ? " OR" : " LIMIT 20000"
        }`),
      initialDOBLeadQuery
    ),
    //query generator for approved permits
    "approved permits": range(0, diff + 1)?.reduce(
      (acc, curr, _, arr) =>
        (acc += ` approved_date = '${dayjs(isRangePicker ? e[1] : adapter[e][1])
          ?.subtract(curr, "day")
          ?.format("YYYY-MM-DD")}T00:00:00.000'${
          curr !== arr.at(-1) ? " OR" : " LIMIT 20000"
        }`),
      initialApprovedPermitQuery
    ),
    //query generator for electrical permits
    "electrical permits": range(0, diff + 1)?.reduce(
      (acc, curr, _, arr) =>
        (acc += ` permit_issued_date = '${dayjs(
          isRangePicker ? e[1] : adapter[e][1]
        )
          ?.subtract(curr, "day")
          ?.format("YYYY-MM-DD")}T00:00:00.000'${
          curr !== arr.at(-1) ? " OR" : " LIMIT 20000"
        }`),
      initialElectricPermitQuery
    ),
  };
  setQuery(fetchInitialQuery[selectedCategory]);
};

export const handleOkFetch = async ({
  setDaysFetchHandlerInfo,
  selectedCategory,
  daysFetchHandler,
  checkFetchData,
  setIsModalVisible,
  unslicedData,
  setDobData,
  setLoading,
  query,
}) => {
  // this checks if we have selected period or date to fetch, if yes generates query and fetch data else sends user to filter modal
  if (checkFetchData) {
    const [{ value: fetchedData }] = await Promise.allSettled([
      ...[
        //fetch from  issued permits
        fetch(query),
      ]?.map(async (promise) => (await promise)?.json()),
    ]).catch(() => {
      setLoading(false);
      setIsModalVisible(false);
      message.error("Unable to retrieve data. Please try again later.");
    });
    setDaysFetchHandlerInfo(daysFetchHandler);

    //fetched data mapping
    const data = formatDobData(fetchedData, unslicedData, selectedCategory);

    //here we set row data to the selected category
    setDobData((dobData) => ({
      ...dobData,
      [selectedCategory]: slice(data[selectedCategory], [0], [10000]),
    }));

    setLoading(false);
    setIsModalVisible(false);
    // setFetchFilter(false);
  }
  //else if the user has not selected fetch date and pres the button, it sends it to the filter page without fetching
  setIsModalVisible(false);

  // else setFetchFilter(false);
};
