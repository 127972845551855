import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const documentation = ({ tables, programFields }) => {
  return {
    docType: () => {
      const data = repeatedElementSum(tables?.Documentation || [], "docType");

      return {
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / tables?.Documentation?.length) * 100).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      };
    }, //doctype

    docStatus: () => {
      const data = repeatedElementSum(tables?.Documentation || [], "docStatus");

      return {
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / tables?.Documentation?.length) * 100).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      };
    }, //docStatus
  }; //Documentation
};
