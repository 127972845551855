const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_WRITABLE":
      return {
        ...state,
        isWritable: payload,
      };
    case "SET_AUTOSAVING":
      return {
        ...state,
        isAutoSaving: payload,
      };
    case "SET_PROPOSED_TYPE_OF_WORK":
      return {
        ...state,
        proposedTypeOfWork: payload,
      };
    case "SET_FAKE_SOV":
      return {
        ...state,
        fakeSOV: payload,
      };
    case "SET_ERRORS":
      return {
        ...state,
        errors: payload,
      };
    case "SET_DARK_MODE":
      localStorage.setItem("themePreference", payload ? "dark" : "light");

      return {
        ...state,
        darkMode: payload,
      };
    case "SET_RETRACTED":
      return {
        ...state,
        retracted: payload,
      };

    case "SET_NEXT_STEP":
      return {
        ...state,
        isNextStep: payload.isNextStep,
        sovId: payload.sovId,
      };
    default:
      return state;
  }
};

export default reducer;
