/**
 * Returns the record prefix based on the provided element and category.
 *
 * @param {Object} el - The element object.
 * @returns {string} The record prefix.
 */
const getRecordPrefix = (el) => {
  if (
    !!el?.hasOwnProperty("estimationNumber") &&
    el?.estimationNumber !== undefined
  ) {
    return `EST#${el?.estimationNumber} : `;
  } else if (
    !!el?.hasOwnProperty("scheduleName") &&
    el?.scheduleName !== undefined
  ) {
    return `${el?.scheduleName} : `;
  }
  return "";
};

export default getRecordPrefix;
