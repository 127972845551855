import { v4 } from "uuid";

export const getProgramFieldsOptions = (programFields, selectedTopic) => {
  const selectedField = programFields?.find(
    (item) => item.fieldId === selectedTopic.fieldId
  )?.fieldOptions;

  if (selectedTopic.categoryName === "Dob Inspections") {
    const allData = [];

    selectedField.forEach((service) => {
      if (service.inspections.length > 0) {
        service.inspections.forEach((inspection) => {
          allData.push(inspection);
        });
      }
    });

    return allData.map((item) => {
      return {
        label: item.name,
        value: item.name,
        key: item.id,
        customValue: {
          key: item.id,
          recordId: item.id,
          recordName: item.name,
          value: item.name,
          ...(selectedTopic.hasOwnProperty("topicCategory") && {
            topicCategory: selectedTopic.topicCategory,
          }),
          ...(selectedTopic.hasOwnProperty("path") && {
            path: selectedTopic.path,
          }),
        },
      };
    });
  } else if (Array.isArray(selectedField)) {
    return selectedField.map((option) => {
      const optionId = option[selectedTopic.id] || v4();
      return {
        label: option[selectedTopic.name],
        value: option[selectedTopic.name],
        key: optionId,
        customValue: {
          key: optionId,
          recordId: optionId,
          recordName: option[selectedTopic.name],
          value: option[selectedTopic.name],
          ...(selectedTopic.hasOwnProperty("topicCategory") && {
            topicCategory: selectedTopic.topicCategory,
          }),
          ...(selectedTopic.hasOwnProperty("path") && {
            path: selectedTopic.path,
          }),
        },
      };
    });
  } else if (selectedTopic.hasOwnProperty("selectedKeys")) {
    const finalArray = [];

    selectedTopic.selectedKeys.forEach((key) => {
      selectedField[key].forEach((item) => {
        const itemId = item[selectedTopic.id] || v4();

        finalArray.push({
          label: item[selectedTopic.name],
          value: item[selectedTopic.name],
          key: itemId,
          customValue: {
            key: itemId,
            recordId: itemId,
            recordName: item[selectedTopic.name],
            value: item[selectedTopic.name],
            ...(selectedTopic.hasOwnProperty("topicCategory") && {
              topicCategory: selectedTopic.topicCategory,
            }),
            ...(selectedTopic.hasOwnProperty("path") && {
              path: selectedTopic.path,
            }),
          },
        });
      });
    });

    return finalArray;
  } else {
    return Object.keys(selectedField)?.map((option) => {
      const newId = v4();

      return {
        label: option,
        value: option,
        key: newId,
        customValue: {
          key: newId,
          recordId: newId,
          recordName: option,
          value: option,
          ...(selectedTopic.hasOwnProperty("topicCategory") && {
            topicCategory: selectedTopic.topicCategory,
          }),
          ...(selectedTopic.hasOwnProperty("path") && {
            path: selectedTopic.path,
          }),
        },
      };
    });
  }
};
