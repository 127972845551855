import { API } from "aws-amplify";

// this function is used to get latest notification templates and to put them in user preferences
// each notification setting has an updatedAt value that compares with latest update in user prefernces
// when update is triggered we save lates notification configs in our preferences
export const modifyPreferences = ({ userPrefs, notificationSettings }) => {
  const notifSettings = getNotificationTopic({
    prefs: userPrefs?.preferences,
    notificationSettings,
  });

  const updatedPreferences = {
    ...userPrefs?.preferences,
    notifications: {
      ...userPrefs?.preferences?.notifications,
      updatedAt: Date.now(),
      topics: notifSettings?.myTopics,
    },
  };

  if (
    !userPrefs?.preferences?.notifications?.hasOwnProperty("updatedAt") ||
    notifSettings?.isUpdated
  ) {
    API.put("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    }).catch((err) => console.log({ err }));
  }

  return {
    ...userPrefs,
    preferences: updatedPreferences,
  };
};

//this function is used to generate all topic with included action statuses
export const getNotificationTopic = ({ prefs, notificationSettings }) => {
  const currTopics = prefs?.notifications?.topics;
  let isUpdated = false; // used to tell when to put in preferences

  const myTopics = notificationSettings.reduce((acc, notifSetting) => {
    const topicId = notifSetting?.topicId;

    //check if any template has changed
    if (notifSetting?.updatedAt > prefs?.notifications?.updatedAt) {
      isUpdated = true;
    }

    const actions = notifSetting.templates.reduce((acc, template) => {
      let checked = true;
      if (currTopics?.hasOwnProperty(topicId)) {
        if (currTopics[topicId].hasOwnProperty(template.templateAction)) {
          checked = currTopics[topicId][template.templateAction];
        } else {
          isUpdated = true;
        }
      } else {
        isUpdated = true;
      }

      return { ...acc, [template.templateAction]: checked };
    }, {});

    return {
      ...acc,
      [topicId]: actions,
    };
  }, {});

  return { myTopics, isUpdated };
};
