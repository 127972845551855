import { Button, Form } from "antd";
import Swal from "sweetalert2";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import { GreenCheckMarkIcon } from "../Signup/icons";
import { CustomOtpInput } from "../Signup/components";

import "./CodeConfirmation.scss";

const CodeConfirmation = ({ form }) => {
  const [otpValue, setOtpValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const otpChange = (otp) => setOtpValue(otp);

  const navigate = useNavigate();

  const onConfirmAccountHandler = () => {
    setIsLoading(true);
    Auth.confirmSignUp(
      form.getFieldValue("email"),
      form.getFieldValue("verifyCode")
    )
      .then(async (res) => {
        if (res === "SUCCESS") {
          Swal.fire({
            text: "New account is confirmed",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
          });
          form.resetFields();
          navigate("/login");
        }
      })
      .catch((error) => {
        if (error.code === "CodeMismatchException") {
          Swal.fire({
            text: "Invalid verification code provided, please try again.",
            icon: "error",
            showConfirmButton: false,
            timer: 10000,
          });
          return;
        }

        Swal.fire({
          text: "There was a problem confirming you account, please try again.",
          icon: "error",
          showConfirmButton: false,
          timer: 10000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="code-confirmation">
      <GreenCheckMarkIcon />

      <h2>Confirm Your Account</h2>
      <p>Please insert the pass code that was sent to your email.</p>
      <Form.Item className="pascodeInputs" name="verifyCode">
        <CustomOtpInput
          value={otpValue}
          className={"otpInput"}
          onChange={otpChange}
          length={6}
          inputStyle={"inputStyle"}
          focusStyle={"focusStyle"}
        />
      </Form.Item>
      <Button
        type="primary"
        className="signin-btn"
        disabled={isLoading}
        onClick={onConfirmAccountHandler}
      >
        {isLoading ? "Please wait..." : "Confirm Account"}
      </Button>
    </div>
  );
};

export default CodeConfirmation;
