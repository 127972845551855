import { API } from "aws-amplify";
import { Tooltip, message } from "antd";
import { useState, useEffect } from "react";

import {
  columnDefs,
  gridFilters,
  chartingOptions,
  excelColumnsConfig,
} from "./AgGridData";
import { useQuery } from "../../../../../hooks";
import PayrollLayout from "../../Layout/PayrollLayout";
import { ExcelIconWhite } from "../../../../../assets";
import { MondayButton } from "../../../../commonComponents";
import { LoadableComp } from "../../../../SidebarPages/XComponents";

function JobsiteTab({ accessRight }) {
  const queryParam = useQuery();
  const selectedJobsite = queryParam.get("selectedJobsite");

  const [rowData, setRowData] = useState([]);
  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  function onEditClick(data) {
    setEditData(data);
    setModalVisible(true);
  }

  function exportToExcel() {
    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName.includes("Client")) {
          return value.accountName;
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  }

  useEffect(() => {
    if (!rowData?.length) {
      API.get("jobsites", "/jobsites")
        .then((res) => {
          setRowData(res);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Error", err);
          setLoading(false);
          message.error("There was a problem getting the data!");
        });
    }
    if (selectedJobsite && rowData?.length) {
      onEditClick(
        rowData.find(({ jobsiteId }) => jobsiteId === selectedJobsite)
      );
    }
  }, [selectedJobsite, rowData?.length]);

  return (
    <>
      <LoadableComp loading={loading} componentTestId={"jobsites-tab-loading"}>
        <></>
      </LoadableComp>
      <PayrollLayout
        {...{
          title: "Jobsites",
          rowData,
          chartingOptions,
          columnDefs: columnDefs({
            writeAccess: accessRight?.write,
            onEditClick,
          }),
          getGridApi: (param) => {
            setGridApi(param);
          },
          additionalActionsButtons: [
            <Tooltip title="Export to Excel" placement="bottom" key={"cimi"}>
              <MondayButton
                className="mondayButtonBlue"
                Icon={<ExcelIconWhite />}
                onClick={exportToExcel}
              >
                {""}
              </MondayButton>
            </Tooltip>,
          ],
          hasNew: accessRight?.write,
          gridFilters,
          excelColumnsConfig,
          dataIdKey: "jobsiteId",
          suppressDoubleClickEdit: true,
          rowToEdit,
          setRowToEdit,
          rowToEdit: editData,
          setRowToEdit: setEditData,
          modalVisible,
          setModalVisible,
          paginationPageSize,
        }}
      />
    </>
  );
}

export default JobsiteTab;
