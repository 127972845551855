import dayjs from "dayjs";

export const generateDatePeriods = () => {
  dayjs.tz.setDefault();

  return [
    {
      label: "All Time",
      value: "All Time",
      startDate: dayjs().subtract(10, "year"),
      endDate: dayjs().endOf("year"),
    },
    {
      label: "Today",
      value: "Today",
      startDate: dayjs().startOf("day"),
      endDate: dayjs().endOf("day"),
    },
    {
      label: "Yesterday",
      value: "Yesterday",
      startDate: dayjs().subtract(1, "day").startOf("day"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    },
    {
      label: "This Week",
      value: "This Week",
      startDate: dayjs().startOf("week"),
      endDate: dayjs().endOf("week"),
    },
    {
      label: "Last Week",
      value: "Last Week",
      startDate: dayjs().subtract(1, "week").startOf("week"),
      endDate: dayjs().subtract(1, "week").endOf("week"),
    },
    {
      label: "This Month",
      value: "This Month",
      startDate: dayjs().startOf("month"),
      endDate: dayjs().endOf("month"),
    },
    {
      label: "Last Month",
      value: "Last Month",
      startDate: dayjs().subtract(1, "month").startOf("month"),
      endDate: dayjs().subtract(1, "month").endOf("month"),
    },
    {
      label: "This Year",
      value: "This Year",
      startDate: dayjs().startOf("year"),
      endDate: dayjs().endOf("year"),
    },
    {
      label: "Last Year",
      value: "Last Year",
      startDate: dayjs().subtract(1, "year").startOf("year"),
      endDate: dayjs().subtract(1, "year").endOf("year"),
    },
  ];
};
