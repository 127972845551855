import Decimal from "decimal.js-light";
import { message } from "antd";
import AmountReq from "./amountFactory";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

Decimal.config({ precision: 32 });

export const cellValueChanged = (
  fakeApplication,
  setApplication,
  service,
  params
  // applicationId
) => {
  console.log("cellValueChanged", params);
  // Find service that we want to edit
  const serviceToEdit = fakeApplication?.services?.find(
    (appService) =>
      appService.serviceId.toString() === service.serviceId.toString() &&
      appService.estimationId === service.estimationId
  );

  const thisField = params?.colDef?.field;
  const eventId = params?.data?.id;

  console.log(
    "thisField",
    thisField,
    [
      "retainagePercentage",
      "thisKeptRetainage",
      "thisDistRetainage",
      "accRetainageBalance",
      "accRetaiangeDist",
      "retainageDndInd",
    ].includes(thisField)
  );
  // return ;

  if (
    [
      "retainagePercentage",
      "thisKeptRetainage",
      "thisDistRetainage",
      "accRetainageBalance",
      "accRetaiangeDist",
      "retainageDndInd",
    ].includes(thisField)
  ) {
    const { amount, fromPrevious } =
      serviceToEdit?.amounts?.find(({ id }) => id === eventId) || {};
    // amount.retainagePercentage = forceToNumber(params.newValue);
    let toReturn = { ...fakeApplication };
    const serviceIndex = toReturn.services.findIndex(
      (serviceData) =>
        serviceData?.serviceId === service?.serviceId &&
        serviceData?.estimationId === service?.estimationId
    );

    if (serviceIndex !== -1) {
      let myAmountIdx = toReturn.services[serviceIndex]?.amounts?.findIndex(
        (el) => el.id === eventId
      );
      if (myAmountIdx !== -1) {
        let myAmount;
        // toReturn.services[serviceIndex].amounts[myAmountIdx][thisField] =
        //   forceToNumber(params.newValue);
        myAmount = new AmountReq(
          toReturn.services[serviceIndex].amounts[myAmountIdx] || {}
        );
        // myAmount.changeThisRetainage(forceToNumber(params.newValue), thisField);

        // myAmount.retainageDeducted(_, service, 5);
        // myAmount.chargeThisPeriod(0, _, toReturn?.services[serviceIndex]);
        // myAmount.retainageDeducted(_, service, 5);
        toReturn.services[serviceIndex].amounts[myAmountIdx] =
          myAmount.changeThisRetainage(
            forceToNumber(params.newValue),
            thisField,
            toReturn.services[serviceIndex]
          );
      }
      setApplication(toReturn);
    }
    return;
  } else {
    const { amount, fromPrevious } =
      serviceToEdit?.amounts?.find(({ id }) => id === eventId) || {};

    //here we calculate the max this period that we can fill
    const maxValue = (amount - fromPrevious)?.toFixed(10);
    //here we calculate the max percentage we can fill
    const maxPercentage = (maxValue / amount) * 100;
    const applicableValue = 100;
    const initialValue = (fromPrevious / amount) * 100;

    let inputNewValue = 0;

    //here we take the impute from user and check if it contains anything besides numbers,if yes we return 0 automatically
    try {
      inputNewValue = params?.newValue ? forceToNumber(params.newValue) : 0;
    } catch (e) {
      inputNewValue = 0;
      message.warning("Invalid input");
    }
    console.log("inputNewValue", inputNewValue, params.newValue, maxValue);

    //here we calculate this period from the impute, impute can be from this period column or percentage, he handle both cases
    const thisPeriod =
      //========================== from this period case==========================
      thisField === "thisPeriod"
        ? inputNewValue === 0
          ? (() => {
              message.warning("Value  0");
              return params?.data?.isCredit ? inputNewValue : 0;
            })() // If it is not valid make it 0
          : Math.abs(inputNewValue) > Math.abs(maxValue) // Check if input is greater than collectable amount
          ? (() => {
              message.warning("Input is greater than collectable amount");
              return maxValue;
            })() // If it is, make it equal to that amount
          : inputNewValue // else
        : //=====================from percentage case=======================
        Math.abs(inputNewValue) < Math.abs(initialValue)
        ? (() => {
            message.warning(`Value smaller than ${initialValue}`);
            return 0;
          })() // If it is not valid make it 0
        : Math.abs(inputNewValue) > Math.abs(applicableValue) // Check if input is greater than collectable amount
        ? (() => {
            message.warning("Input is greater than collectable percentage");
            return (amount * maxPercentage) / 100;
          })() // If it is, make it equal to that amount
        : (amount * (inputNewValue - initialValue)) / 100; // Convert new period to number
    let toReturn = { ...fakeApplication };
    const serviceIndex = toReturn.services.findIndex(
      (serviceData) =>
        serviceData?.serviceId === service?.serviceId &&
        serviceData?.estimationId === service?.estimationId
    );

    if (serviceIndex !== -1) {
      let myAmountIdx = toReturn?.services[serviceIndex]?.amounts?.findIndex(
        (el) => el.id === eventId
      );
      if (myAmountIdx !== -1) {
        let myAmount = new AmountReq(
          toReturn?.services[serviceIndex]?.amounts[myAmountIdx] || {}
        );
        myAmount.chargeThisPeriod(
          thisPeriod,
          _,
          toReturn?.services[serviceIndex]
        );
        // myAmount.retainageDeducted(_, service, 5);
        toReturn.services[serviceIndex].amounts[myAmountIdx] =
          myAmount.getData();
      }
    }

    //this function calculates the new values of application
    // const newApplicationHandler = {
    //   //returns the application keys unchanged
    //   ...fakeApplication,
    //   //expect the service key bcs thats the only key we want ot update
    //   services: fakeApplication?.services?.map((serviceData) => {
    //     //here we check if this is the service we want to update
    //     return serviceData?.serviceId === service?.serviceId &&
    //       serviceData?.estimationId === service?.estimationId
    //       ? {
    //           //returns the services keys unchanged
    //           ...serviceData,
    //           //expect amounts key bcs thats the only key we want ot update
    //           amounts: serviceData?.amounts?.map((amountsData) => {
    //             const {
    //               fromPrevious,
    //               amount,
    //               charged,
    //               retainagePercentage,
    //               reducedBalance = 0,
    //             } = amountsData || {};
    //             //total compleated is equal to  (total amount that is applied in previous applications)+(the amount that is applied in this application)
    //             const totalCompleated = fromPrevious + thisPeriod;
    //             //percentage compleated for the event is equal to (totalCompleated that we calculated before)/(the total amount for this event) times 100 to turn it to percentage
    //             const percentageCompleated = (totalCompleated / amount) * 100;
    //             //balanceToFinish for the event is equal to (the total amount for this event) - (totalCompleated that we calculated before)
    //             const balanceToFinish = amount - totalCompleated;
    //             //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    //             const retainage = (totalCompleated * retainagePercentage) / 100;
    //             const keptRetainage = thisPeriod * (retainagePercentage / 100);
    //             const paymentDue = thisPeriod - keptRetainage + reducedBalance;

    //             //here we check if this event is not charged
    //             return !charged
    //               ? //if the event is not charged we check if this is the event that we want to update
    //                 amountsData?.id === eventId
    //                 ? {
    //                     ...amountsData,
    //                     keptRetainage,
    //                     paymentDue,
    //                     percentageCompleated,
    //                     totalCompleated,
    //                     thisPeriod,
    //                     balanceToFinish,
    //                     retainage,
    //                     //we oslo have to update the addons that are inside the event
    //                     serviceAddons: amountsData?.serviceAddons?.map(
    //                       (addonsData) => {
    //                         const {
    //                           amount: addonsAmount,
    //                           fromPrevious: addonsFromPrevious,
    //                         } = addonsData || {};

    //                         //here we calculate the percentage that the addons amount owns to the amount of the event that he is
    //                         const percentageToAmount = addonsAmount / amount;
    //                         //here we calculate amount's this period by taking amount's ( thisPeriod ) * ( the percentage that we calculated before  )
    //                         const calculatedThisPeriod =
    //                           thisPeriod * percentageToAmount;
    //                         //calculated total compleated is equal to  (total addons amount that is applied in previous applications) + ( the total amount that we calculated before )
    //                         const calculatedTotalCompleated =
    //                           addonsFromPrevious + calculatedThisPeriod;
    //                         //percentage compleated for the event is equal to (calculatedTotalCompleated that we calculated before)/(the total amount for this addons) times 100 to turn it to percentage
    //                         const calculatedPercentageCompleated =
    //                           (calculatedTotalCompleated / addonsAmount) * 100;
    //                         //balanceToFinish for the event is equal to (the total amount for this event) - (totalCompleated that we calculated before)
    //                         const calculatedBalanceToFinish =
    //                           addonsAmount - calculatedTotalCompleated;
    //                         //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    //                         const calculatedRetainage =
    //                           (totalCompleated * retainagePercentage) / 100;

    //                         return {
    //                           ...addonsData,
    //                           thisPeriod: calculatedThisPeriod,
    //                           percentageCompleated:
    //                             calculatedPercentageCompleated,
    //                           totalCompleated: calculatedTotalCompleated,
    //                           balanceToFinish: calculatedBalanceToFinish,
    //                           retainage: calculatedRetainage,
    //                         };
    //                       }
    //                     ),
    //                     //we oslo have to update the service options that are inside the event
    //                     serviceOptions: amountsData?.serviceOptions?.map(
    //                       (elevationData) => ({
    //                         ...elevationData,
    //                         items: elevationData?.items?.map((pliData) => {
    //                           const {
    //                             amount: addonsAmount,
    //                             fromPrevious: addonsFromPrevious,
    //                           } = pliData || {};

    //                           //here we calculate the percentage that the addons amount owns to the amount of the event that he is
    //                           const percentageToAmount = addonsAmount / amount;
    //                           //here we calculate amount's this period by taking amount's ( thisPeriod ) * ( the percentage that we calculated before  )
    //                           const calculatedThisPeriod =
    //                             thisPeriod * percentageToAmount;
    //                           //calculated total compleated is equal to  (total addons amount that is applied in previous applications) + ( the total amount that we calculated before )
    //                           const calculatedTotalCompleated =
    //                             addonsFromPrevious + calculatedThisPeriod;
    //                           //percentage compleated for the event is equal to (calculatedTotalCompleated that we calculated before)/(the total amount for this addons) times 100 to turn it to percentage
    //                           const calculatedPercentageCompleated =
    //                             (calculatedTotalCompleated / addonsAmount) * 100;
    //                           //balanceToFinish for the event is equal to (the total amount for this event) - (totalCompleated that we calculated before)
    //                           const calculatedBalanceToFinish =
    //                             addonsAmount - calculatedTotalCompleated;
    //                           //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    //                           const calculatedRetainage =
    //                             (totalCompleated * retainagePercentage) / 100;

    //                           return {
    //                             ...pliData,
    //                             thisPeriod: calculatedThisPeriod,
    //                             percentageCompleated:
    //                               calculatedPercentageCompleated,
    //                             totalCompleated: calculatedTotalCompleated,
    //                             balanceToFinish: calculatedBalanceToFinish,
    //                             retainage: calculatedRetainage,
    //                           };
    //                         }),
    //                       })
    //                     ),
    //                   }
    //                 : //if this is not the event that we want to update return it unchanged
    //                   amountsData
    //               : //else if event is charged we return it unchanged
    //                 amountsData;
    //           }),
    //         }
    //       : //if this is not the service return it unchanged
    //         serviceData;
    //   }),
    // };

    // Set new Application
    setApplication(toReturn);
  }
};
