/**
 *
 * @param {Array} array list of sorted entries after upload
 * array is sorted from oldest to newest compared in timeStamp
 * adds jobsiteMatch for all ID entries that create a correctShift and don t have a jobsiteMatch
 * @returns
 */
export function addJobsiteOnIdEntries(array) {
  const groupedEntries = _.groupBy(array, ({ employeeId }) => employeeId);
  let sortedArray = [];
  for (const employeeId in groupedEntries) {
    sortedArray = sortedArray.concat(
      groupedEntries[employeeId].sort(
        (a, b) => a?.punchTimeStamp - b?.punchTimeStamp
      )
    );
  }

  let tmpArray = [];
  for (let i = 0; i < sortedArray?.length; i++) {
    const entry = sortedArray[i];
    const { punchType, jobsiteMatch } = entry;

    // check for missing lunch shifts and add key of missingLunch to true
    if (punchType === "ID" && sortedArray[i + 1]?.punchType === "OD") {
      Object.assign(entry, { missingLunch: true });
      Object.assign(sortedArray[i + 1], { missingLunch: true });
    }

    // Check if entry is ID and does not have jobsiteMatch
    if (punchType === "ID" && !jobsiteMatch?.jobsiteId) {
      // when shift does not have lunch entries in shift
      if (
        sortedArray[i + 1]?.punchType === "OD" &&
        sortedArray[i + 1]?.jobsiteMatch?.jobsiteId
      ) {
        const jobsiteMatchOD = sortedArray[i + 1]?.jobsiteMatch;
        const payrollType = sortedArray[i + 1]?.payrollType;

        tmpArray.push(
          Object.assign(entry, {
            missingLunch: true,
            jobsiteChanged: true,
            jobsiteId: jobsiteMatchOD?.jobsiteId,
            jobsiteMatch: sortedArray[i + 1]?.jobsiteMatch,
            payrollType,
            payrollCategory: payrollType,
            employeeRate: jobsiteMatchOD?.rates
              ? jobsiteMatchOD?.rates[entry?.employeeRole] !== 0
                ? jobsiteMatchOD?.rates[entry?.employeeRole]
                : entry?.employeeRate
              : entry?.employeeRate,
          })
        );

        // when shift have lunch entries in shift
      } else if (
        sortedArray[i + 1]?.punchType === "OL" &&
        sortedArray[i + 2]?.punchType === "IL" &&
        sortedArray[i + 3]?.punchType === "OD" &&
        sortedArray[i + 3]?.jobsiteMatch?.jobsiteId
      ) {
        const jobsiteMatchOD = sortedArray[i + 3]?.jobsiteMatch;
        const payrollType = sortedArray[i + 3]?.payrollType;

        tmpArray.push(
          Object.assign(entry, {
            jobsiteChanged: true,
            jobsiteMatch: sortedArray[i + 3]?.jobsiteMatch,
            jobsiteId: jobsiteMatchOD?.jobsiteId,
            payrollType,
            payrollCategory: payrollType,
            employeeRate: jobsiteMatchOD?.rates
              ? jobsiteMatchOD?.rates[entry?.employeeRole] !== 0
                ? jobsiteMatchOD?.rates[entry?.employeeRole]
                : entry?.employeeRate
              : entry?.employeeRate,
          })
        );
      } else {
        tmpArray.push(entry);
      }
    } else {
      tmpArray.push(entry);
    }
  }
  return tmpArray;
}
