import { MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React, { useState } from "react";
import "./MobileHeaderButtons.scss";

const MobileHeaderButtons = ({ popoverContent }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => setOpen(newOpen);
  return (
    <Popover
      content={
        <div className="mobile-header-buttons">{popoverContent(setOpen)}</div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <MoreOutlined style={{ fontSize: "24px", fontWeight: "700" }} />
    </Popover>
  );
};

export default MobileHeaderButtons;
