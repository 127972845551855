import React from "react";
import { InputComponent } from "../../../../Fleet/components";
import { MondayButton } from "../../../../../commonComponents";
import { PlusIcon } from "../../../../../../assets";

/**
 * Renders a component for selecting a safety team.
 *
 * @component
 * @param {Array} teams - The list of teams to choose from.
 * @param {Array} selectedTeam - The currently selected team.
 * @param {boolean} isDarkMode - Indicates whether the dark mode is enabled.
 * @param {Function} onTeamSelect - The callback function when a team is selected.
 * @param {boolean} disabledAllFields - Indicates whether all fields are disabled.
 * @param {Function} onTeamDeselect - The callback function when a team is deselected.
 * @param {Function} setTeamModalVisible - The callback function to set the visibility of the team modal.
 * @returns {JSX.Element} The rendered component for selecting a safety team.
 */
function SafetyTeamSelect({
  teams = [],
  selectedTeam = [],
  isDarkMode = false,
  onTeamSelect = () => {},
  disabledAllFields = false,
  onTeamDeselect = () => {},
  setTeamModalVisible = () => {},
}) {
  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <InputComponent
        mode="multiple"
        type="select"
        required={true}
        label="Team"
        formItemName="team"
        dropdownClassName={isDarkMode && "darkDropDown"}
        allowClear={false}
        customOptions={teams?.map((team) => ({
          value: team?.teamName,
          label: team?.teamName,
          members: team?.members?.map(
            ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
              identityId,
              nameOfUser,
              cognitoUserId,
            })
          ),
        }))}
        onSelect={onTeamSelect}
        onDeselect={onTeamDeselect}
        disabled={disabledAllFields}
        showSearch={true}
      />
      {!!selectedTeam?.length && (
        <MondayButton
          {...{
            onClick: () => {
              setTeamModalVisible(true);
            },
            Icon: <PlusIcon />,
            style: { alignSelf: "end" },
          }}
        >
          Team Members
        </MondayButton>
      )}
    </div>
  );
}

export default SafetyTeamSelect;
