import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const incidents = (IdHandler, updatedDynamicStates, programFields) => {
  const incidentStatuses = programFields?.find(
    (a) => a?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Incidents;
  return {
    driverName: () => ({
      title: "Incidents Driver Name",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Incidents",
        field: "driverName",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.driverName)
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (a) => a?.driverName
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) => a.driverName === status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),

    fleetName: () => ({
      title: "Incidents Fleet Name",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Incidents",
        field: "fleetName",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.fleetName)
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (a) => a?.fleetName
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) => a.fleetName === status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),

    incidentStatus: () => ({
      title: "Incidents Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentStatus",
        labels: incidentStatuses?.map((a) => a?.statusName),
        datasets: [
          {
            label: "My First Dataset",
            data: incidentStatuses?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) => a?.incidentStatus === status?.statusName
                )?.length
            ),
            backgroundColor: incidentStatuses?.map((a) => a?.statusColor),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    incidentCategory: () => ({
      title: "Incidents Category",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentCategory",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "incidentCategory"
          )
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "incidentCategory"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "incidentCategory"
              )
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    incidentName: () => ({
      title: "Incident Name",
      BodyComponent: "Pie",
      type: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentName",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "incidentName"
          )
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "incidentName"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "incidentName"
              )
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    projectName: () => ({
      title: "Incidents Project Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Incidents",
        field: "projectName",
        labels: Object.keys(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])?.map(
              (e) => e?.incidentObject
            ),
            "projectName"
          )
        ),
        datasets: [
          {
            label: "Incidents",
            data: Object?.values(
              repeatedElementSum(
                (updatedDynamicStates?.[IdHandler] || [])?.map(
                  (e) => e?.incidentObject
                ),
                "projectName"
              )
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
