import JoditEditor, { Jodit } from "jodit-react";
import React, { useState, useMemo, useRef } from "react";
import { useRedux } from "../../../SidebarPages/hooks";
// function preparePaste(jodit) {
//   jodit.e.on(
//     "paste",
//     (e) => {
//       if (confirm("Change pasted content?")) {
//         jodit.e.stopPropagation("paste");
//         jodit.s.insertHTML("<p>Changed content</p>");
//         return false;
//       }
//     },
//     { top: true }
//   );
// }

// Jodit.plugins.add("preparePaste", preparePaste);
function JoditEditorCustom({ editorRef }) {
  const [content, setContent] = useState({
    initialized: false,
    content: "",
  });

  const [proposalData, setProposalData] = useRedux("proposalData");
  const savedContent = proposalData?.savedContent ?? "";

  const onInsert = () => {
    console.log("editor", editorRef);

    if (editorRef.current) {
      // Get Jodit editor instance
      // const editor = editorRef.current;
      console.log("editor", editorRef);
      // Insert HTMfL content programmatically
      editorRef.current.component.selection.insertHTML(
        "<p>This is inserted HTML content</p>"
      );
    }
  };

  return (
    <>
      <JoditEditor
        ref={editorRef}
        theme="dark"
        value={savedContent || content.content}
        config={{
          // theme: "dark",
          // Jodit Editor configuration options
          readonly: false, // Ensure it's set to false if you want to insert content
          toolbarButtonSize: "large",
          height: 720,
          allowResizeY: false,
          allowResizeX: false,
          // add page break
          enter: "BR",

          // Other configurations as needed
        }}
      />
    </>
  );
}

export default JoditEditorCustom;
