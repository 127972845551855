import API from "@aws-amplify/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { saveGlobal } from "../../../../../hooks";
import { getRandomColor } from "../../../../Header/forms/Scheduling/helpers/creators";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { getInitials } from "../../../../pages/Settings/settingsComponents/TeamManagement/components/TeamPreview/PreviewAvatar";
import "./Members.scss";
import { GetAll } from "../../../../../utils/functions";

const Members = ({ params, edited, setNewValues, id }) => {
  const [values, setValues] = useState(params);
  const [members, setMembers] = useState([]);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);
  const { programFields } = useSelector((state) => state.programFields);

  const typesOfMemebers = programFields?.find(
    ({ fieldName }) => fieldName === "Member Keys"
  )?.fieldOptions;

  const groupedUsers = members
    .filter(({ value }) => value !== "")
    .map(({ label, value }) => ({
      member: { value, color: getRandomColor() },
      role: label,
    }));

  useEffect(() => {
    Promise.all([
      GetAll("architects"),
      GetAll("engineers"),
      GetAll("projectExecutives"),
    ]).then((res) => {
      saveGlobal("members", {
        architects: res[0],
        engineers: res[1],
        projectExecutives: res[2],
      });
    });
  }, []);

  useEffect(() => {
    let membersList = [];
    typesOfMemebers?.map((type) => {
      if (values?.rowData[type.key] !== undefined) {
        type.key === "projectManager"
          ? values?.rowData?.projectManager?.map((user) =>
              typeof user === "string"
                ? membersList?.push({
                    label: type?.value,
                    value: user,
                  })
                : membersList?.push({
                    label: type?.value,
                    value: user?.nameOfUser || user?.label,
                  })
            )
          : membersList?.push({
              label: type?.value,
              value: values?.rowData[type.key],
            });
      }
    });

    setMembers(membersList);
  }, [values]);

  useEffect(() => {
    if (edited) {
      keys(edited).map((el) => {
        values.rowData[el] = edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
    }
  }, [JSON.stringify(edited)]);

  return (
    <div className="membersContent">
      {groupedUsers?.map((group, index) => {
        const userName =
          typeof group?.member?.value === "object" ? "" : group?.member?.value;
        return (
          <div key={index} className="columnContentContainer">
            <div>
              <div
                className="InitialsContainer"
                style={{
                  backgroundColor:
                    userName === userConfiguration?.nameOfUser
                      ? preferences.preferences?.profileColor || "#4895ef"
                      : "#F8F8FA",
                }}
              >
                <span
                  className="initialsStyle"
                  style={{
                    color:
                      userName === userConfiguration?.nameOfUser
                        ? "#fff"
                        : "#323338",
                  }}
                >
                  {typeof userName === "string" ? getInitials(userName) : ""}
                </span>
              </div>
              {userConfiguration?.nameOfUser === userName && (
                <div className="currentUserStyleContainer">
                  <span className="currentUserStyleText">You</span>
                </div>
              )}
            </div>
            <div className="memberDetailsContainer">
              <span className="nameStyle">
                {Array.isArray(userName)
                  ? typeof userName[0] !== "string"
                    ? ""
                    : userName?.join(", ")
                  : userName}
              </span>
              <span className="roleStyle">{group?.role}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Members;
