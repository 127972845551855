import { isEmpty } from "lodash";
import getFilesWarnings from "./getFilesWarnings";

async function getDocWarnings(records, getParents) {
  const processedRecords = await Promise.all(
    records?.map(async (record) => {
      if (isEmpty(record)) return null;
      const { folderId, googleDriveUploads = [] } = record || {};
      if (googleDriveUploads?.length === 0) {
        return !!folderId ? null : record;
      }
      const updatedUploads = await getFilesWarnings(
        googleDriveUploads,
        folderId,
        getParents
      );
      const allOk = updatedUploads.every((upload) => upload.status === "Ok");
      if (allOk) return null;
      return {
        ...record,
        googleDriveUploads: updatedUploads.filter(
          (upload) => upload.status !== "Ok"
        ),
      };
    })
  );
  return processedRecords.filter(Boolean);
}

export default getDocWarnings;
