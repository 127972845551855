import { Form } from "antd"
import LinkBtn from "./LinkBtn"

const LinkBtnWrapper = ({ children, onClick, onChange, ...rest }) => (
  <Form.Item {...rest}>
    <LinkBtn {...{ onClick, onChange, children }} />
  </Form.Item>
)

export default LinkBtnWrapper
