import uniq from "lodash/uniq";
import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const workOrders = ({ tables, programFields }) => {
  // console.log("Work Orders: ", tables["Work Orders"]);

  return {
    workOrderStatus: () => ({
      content: uniq(tables["Work Orders"]?.map((a) => a?.workOrderStatus))?.map(
        (status, index) => ({
          key: index,
          title: status,
          taskNumber: tables?.["Work Orders"]?.length,
          taskDone: tables?.["Work Orders"]?.filter(
            (a) => a?.workOrderStatus === status
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + status,
          contentsHandler: " " + status,
        })
      ),
    }),

    fleetName: () => ({
      content: uniq(tables["Work Orders"]?.map((a) => a?.fleetName))?.map(
        (status, index) => ({
          key: index,
          title: status,
          taskNumber: tables?.["Work Orders"]?.length,
          taskDone: tables?.["Work Orders"]?.filter(
            (a) => a?.fleetName === status
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + status,
          contentsHandler: " " + status,
        })
      ),
    }),

    mechanicName: () => ({
      content: uniq(
        tables["Work Orders"]?.map((a) => a?.mechanicInfo?.mechanicName)
      )?.map((mechanicName, index) => ({
        key: index,
        title: mechanicName,
        taskNumber: tables?.["Work Orders"]?.length,
        taskDone: tables?.["Work Orders"]?.filter(
          (a) => a?.mechanicInfo?.mechanicName === mechanicName
        )?.length,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " " + mechanicName,
        contentsHandler: " " + mechanicName,
      })),
    }),

    dueDate: () => ({
      content: (() => {
        let data = [];
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              title: months[i],
              taskNumber: tables?.["Work Orders"]?.filter(
                (b) => b.dueDate >= getMonthsTimestamps()[0]
              )?.length,
              taskDone: tables?.["Work Orders"]?.filter(
                (a) =>
                  a.dueDate >= getMonthsTimestamps()[i] &&
                  a.dueDate <= getMonthsTimestamps("end")[i]
              )?.length,
              color: "#71CF48",
              backgroundColor: "#B2DD9B",
              contentHandler: " Due  In " + months[i],
              contentsHandler: " Due  In " + months[i],
            },
          ];
        }
        return data;
      })(),
    }),
  };
};
