import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
import { DeleteIcon } from "../../../../../DynamicView/src";
import { customFloorSignificance, getNumberValOnly } from "../utils";
import { Button, message } from "antd";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
export const getScaffoldPLIColumns = ({
  setDataSrc,
  scaffoldPlanksGridAPI,
  viewOnly,
}) => {
  return [
    {
      headerName: "Actions",
      editable: false,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <div
            className="actions"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}
          >
            <Button
              className="btn btn-primary btn-sm"
              style={{
                backgroundColor: "#FE4C4A",
                border: "none",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
                padding: "0px 10px",
              }}
              onClick={() => {
                setDataSrc((prev) => {
                  if (prev.length === 1) {
                    message.error("Cannot remove last row");
                    return prev;
                  }
                  return prev.filter((item) => item !== params.data);
                });
                scaffoldPlanksGridAPI?.redrawRows();
              }}
            >
              <DeleteIcon
                style={{
                  fill: "#fff",
                }}
              />
            </Button>
            <Button
              className="btn btn-primary btn-sm"
              style={{
                backgroundColor: "#1264A3",
                border: "none",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
                padding: "0px 10px",
              }}
              onClick={() => {
                setDataSrc((prev) => [
                  ...prev,
                  {
                    bicType:
                      prev?.[prev.length - 1]?.bicType > 20
                        ? prev[prev.length - 1]?.bicType
                        : 20,
                  },
                ]);
                scaffoldPlanksGridAPI?.redrawRows();
              }}
            >
              <AddIcon />
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "L",
      field: "length",
      // width: 70,
    },
    {
      headerName: "H",
      field: "height",
      // width: 70,
    },
    {
      headerName: "Sqft",
      editable: false,
      field: "sqft",
      tooltipField: "sqft",
      tooltipComponentParams: {
        formula: "Sqft = Length * Height",
        color: "#3468C0",
      },

      // width: 70,
      valueGetter: (params) => {
        try {
          const length = params?.data?.length || 0;
          const height = params?.data?.height || 0;
          const res = getNumberValOnly(length * height);
          params.data.sqft = res;
          return res;
        } catch (err) {
          return 0;
        }
      },
    },
    {
      headerName: "Bays Long",
      // width: 100,
      editable: false,

      field: "baysLong",
      valueGetter: (params) => {
        try {
          const length = params?.data?.length || 0;
          let res = customFloorSignificance(length / 8 + 0.9, 1);
          res = getNumberValOnly(res);
          params.data.baysLong = res || 0;
          return res;
        } catch (err) {
          return 0;
        }
      },
      tooltipField: "baysLong",
      tooltipComponentParams: {
        formula: "FLOOR(Length / 8 + 0.9, 1)",
        color: "#3468C0",
      },
    },
    {
      headerName: "Frames High",
      // width: 100,
      editable: false,
      field: "framesHigh",
      valueGetter: (params) => {
        try {
          const height = params?.data?.height || 0;
          let res = customFloorSignificance(height / 6.4 + 0.75, 1);
          console.log("res", res);
          res = getNumberValOnly(res);
          params.data.framesHigh = res || 0;
          return res;
        } catch (err) {
          return 0;
        }
      },
      tooltipField: "framesHigh",
      tooltipComponentParams: {
        formula: "FLOOR(Height / 6.4 + 0.75, 1)",
        color: "#3468C0",
      },
    },
    {
      headerName: "# Full Decks",
      field: "fullDecksNo",
      // width: 100,
    },
    {
      headerName: "Full Deck",
      // width: 100,
      field: "fullDeck",
      editable: false,

      valueGetter: (params) => {
        try {
          const noFullDecks = params?.data?.fullDecksNo || 0;
          const baysLong = params?.data?.baysLong || 0;
          const applicableCoefficient = getBicycleTypeCoefficient(
            getNumberValOnly(params?.data?.bicType)
          );
          const res = getNumberValOnly(
            noFullDecks * (11 + applicableCoefficient) * baysLong
          );
          params.data.fullDeck = res;
          return res;
        } catch (err) {
          return 0;
        }
      },
      tooltipField: "fullDeck",
      tooltipComponentParams: {
        formula:
          'No. Full Decks * (11 + k) * Bays Long where k = 0 for 20, 1 for 30, 2 for 40" type of bicycle',
        color: "#3468C0",
      },
    },
    {
      headerName: "Bicycle Type",
      // width: 100,
      field: "bicType",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: Object.keys(TYPES_OF_BICYCLES),
      },
      valueFormatter: (params) => {
        let res = params?.value || 20;
        return TYPES_OF_BICYCLES?.[params?.value]?.default
          ? `${res}" (k=${getBicycleTypeCoefficient(params?.value)})`
          : `${res}" (k=${getBicycleTypeCoefficient(params?.value)})`;
      },
      tooltipField: "bicType",
      tooltipComponentParams: {
        formula:
          'k (koeficient) = 0 for 20, 1 for 30, 2 for 40 " type of bicycle',
        color: "#3468C0",
      },
    },
    {
      headerName: "# Bic Levels",
      // width: 100,
      field: "bicLevelNo",
      editable: true,
      valueSetter: (params) => {
        const newValue = getNumberValOnly(params.newValue);
        if (newValue < 0) {
          message.error("Value cannot be negative");
          return false;
        }
        params.data.bicLevelNo = newValue;
        const baysLong = getNumberValOnly(params?.data?.baysLong || 0);
        const applicableCoefficient = getBicycleTypeCoefficient(
          forceToNumber(params?.data?.bicType)
        );
        const bicLevel = getNumberValOnly(
          newValue * (5 + applicableCoefficient) * baysLong
        );
        const framesHigh = params?.data?.framesHigh || 0;
        const noDecks = params?.data?.fullDecksNo || 0;
        const fullDeck = params?.data?.fullDeck || 0;
        const framesH = getNumberValOnly(framesHigh - (Number(noDecks) + 1));
        params.data.bicLevel = bicLevel;
        params.data.framesH = framesH;
        params.data.bicEveryLevel = getNumberValOnly(
          Number(bicLevel) * Number(framesH)
        );
        params.data.fullDeckEveryLevel = getNumberValOnly(
          Number(fullDeck) * Number(framesH)
        );

        return true;
      },
    },
    {
      headerName: "Bic Level",
      // width: 100,
      field: "bicLevel",
      editable: false,

      valueGetter: (params) => {
        try {
          const bicLevelNo = getNumberValOnly(params?.data?.bicLevelNo || 0);
          const baysLong = getNumberValOnly(params?.data?.baysLong || 0);
          const applicableCoefficient = getBicycleTypeCoefficient(
            getNumberValOnly(params?.data?.bicType)
          );
          const res = getNumberValOnly(
            bicLevelNo * (5 + applicableCoefficient) * baysLong
          );
          params.data.bicLevel = getNumberValOnly(res);
          return res;
        } catch (err) {
          return 0;
        }
      },
      tooltipField: "bicLevel",
      tooltipComponentParams: {
        formula:
          'BiC Level No * (5 + k) * Bays Long | where k = 0 for 20, 1 for 30, 2 for 40" type of bicycle',
        color: "#3468C0",
      },
    },
    {
      headerName: "Full Deck Every Level",
      // width: 140,
      field: "fullDeckEveryLevel",
      editable: false,

      valueGetter: (params) => {
        try {
          console.log("myparams", params);
          const fullDeck = params?.data?.fullDeck || 0;
          const framesH = params?.data?.framesH || 0;
          const res = getNumberValOnly(Number(fullDeck) * Number(framesH));
          params.data.fullDeckEveryLevel = getNumberValOnly(res);
          return res;
        } catch (err) {
          return 0;
        }
      },
      tooltipField: "fullDeckEveryLevel",
      tooltipComponentParams: {
        formula: "Full Deck * Frames H W/O Safety and FD",
        color: "#3468C0",
      },
    },
    {
      headerName: "Bic Every Level",
      // width: 140,
      field: "bicEveryLevel",
      editable: false,

      valueGetter: (params) => {
        try {
          console.log("myparams", params);
          const bicLevel = params?.data?.bicLevel || 0;
          const framesH = params?.data?.framesH || 0;
          const res = getNumberValOnly(Number(bicLevel) * Number(framesH));
          params.data.bicEveryLevel = res;
          return res;
        } catch (err) {
          return 0;
        }
      },
      tooltipField: "bicEveryLevel",
      tooltipComponentParams: {
        formula: "Bic Level * Frames H W/O Safety and FD",
        color: "#3468C0",
      },
    },
    {
      headerName: "Frames H W/O Safety and FD",
      field: "framesH",
      editable: false,

      // width: 140,
      valueGetter: (params) => {
        try {
          console.log("myparams", params);
          const framesHigh = params?.data?.framesHigh || 0;
          const noDecks = params?.data?.fullDecksNo || 0;
          const res = getNumberValOnly(framesHigh - (Number(noDecks) + 1));
          params.data.framesH = res;
          return res >= 0 ? res : 0;
        } catch (err) {
          return 0;
        }
      },
      tooltipField: "bicEveryLevel",
      tooltipComponentParams: {
        formula: "Frames High - (No. Full Decks + 1)",
        color: "#3468C0",
      },
    },
  ];
};

export const initializeRowPLISingleEvery = ({ length = 0, height = 0 }) => {
  const sqft = getNumberValOnly(length * height);
  const baysLong = customFloorSignificance(length / 8 + 0.9, 1);
  const fullDeck = 1 * 11 * baysLong;
  const framesHigh = customFloorSignificance(height / 6.4 + 0.75, 1);
  const framesH = getNumberValOnly(framesHigh - (Number(1) + 1));

  const bicLevel = getNumberValOnly(1 * 5 * baysLong);
  const fullDeckEveryLevel = getNumberValOnly(
    Number(fullDeck) * Number(framesH)
  );
  const bicEveryLevel = getNumberValOnly(Number(bicLevel) * Number(framesH));

  return {
    length,
    height,
    sqft,
    baysLong,
    framesHigh,
    fullDecksNo: 1,
    fullDeck,
    bicLevelNo: 1,
    bicLevel,
    fullDeckEveryLevel: getNumberValOnly(fullDeckEveryLevel),
    bicEveryLevel,
    framesH,
    bicType: 20,
  };
};

const TYPES_OF_BICYCLES = {
  20: {
    coefficent: 0,
    default: true,
  },
  30: { coefficent: 1, default: false },
  40: { coefficent: 2, default: false },

  // no more than 40 as of now
};

export const getBicycleTypeCoefficient = (type) => {
  console.log("type", type);
  return TYPES_OF_BICYCLES?.[type]?.coefficent || 0;
};
