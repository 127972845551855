import { message } from "antd";
import { API } from "aws-amplify";

async function saveSortedFields({
  res,
  newLog,
  setNewSortFields,
  programFields,
  category,
  saveAddedLogs,
  fieldName,
}) {
  const safetyFields = programFields?.find((el) => el?.fieldName === fieldName);
  const fieldId = safetyFields?.fieldId;
  const oldFields = safetyFields?.fieldOptions;

  await API.patch("programFields", `/programFields/${fieldId}`, {
    body: {
      fieldOptions: {
        ...oldFields,
        [category]: res,
      },
    },
  })
    .then(() => {
      message.success({
        content: "Saved Successfully",
        key: "dynamicFieldsSave",
      });
      setNewSortFields(res);
      saveAddedLogs(newLog);
    })
    .catch((err) => {
      message.error({
        content: "Error saving",
        key: "dynamicFieldsSave",
      });
      console.log("error saving", err);
    });
}

export default saveSortedFields;
