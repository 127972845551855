/**
 * Function that saves a list of key-value pairs in session storage
 * @param {Record<string, any>} preferences
 */
function saveLocalPreferences(preferences) {
  if (!!preferences) {
    if (Object.getPrototypeOf(preferences).constructor.name === "Object") {
      for (const key in preferences) {
        sessionStorage.setItem(key, JSON.stringify(preferences[key]));
      }
    }
  }
}

export default saveLocalPreferences;
