import { RequestCard } from "..";
import { toCamelCase } from "../../../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";

const RequestsCategoryCard = ({
  createdId = "",
  category = "",
  requests = [],
  onView = () => {},
  onApprove = () => {},
  onDecline = () => {},
  onAddressClick = () => {},
  onInstantDecline = () => {},
  i,
}) => {
  return (
    <div
      className={`requestCategory ${i === 0 && "firstRequestCategory"}`}
      id={`${toCamelCase(category)}Card`}
    >
      <div className="categoryLabel">{category}</div>

      <div className="requestsContainer">
        {!!requests?.length ? (
          requests?.map((formRequest) => (
            <RequestCard
              {...{
                key: formRequest?.requestId,
                formRequest,
                onAddressClick,
                onApprove,
                onDecline,
                onView,
                onInstantDecline,
                createdId,
              }}
            />
          ))
        ) : (
          <div className="noRequestLabel">There are no requests</div>
        )}
      </div>
    </div>
  );
};

export default RequestsCategoryCard;
