import React, { Component } from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { Button } from "antd";
export default class RichText extends Component {
  constructor(props) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
  }
  state = {};

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["image"],
      ["clean"],
    ],
  };

  formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "mention",
    "image",
  ];

  attachQuillRefs = () => {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
  };

  insertDate = (date) => {
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    this.quillRef.insertText(position, date);
  };

  render() {
    return (
      <div className="rich-editor-description">
        <Button
          className="date-btn"
          shape={"round"}
          onClick={() => this.insertDate(moment().format("MMMM/DD/YYYY"))}
        >
          Date
        </Button>
        <Button
          className="hour-btn"
          shape={"round"}
          onClick={() => this.insertDate(moment().format("H:MM"))}
        >
          Hour
        </Button>
        <ReactQuill
          defaultValue={this.props.defaultValue || ""}
          theme="snow"
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          placeholder={this.props?.placeholder || "Enter description here"}
          className="note_input"
          formats={this.formats}
          readOnly={this.props?.readOnly || false}
          modules={this.modules}
          onChange={(content, delta, source, editor) =>
            this.props.setValue(content, delta, source, editor)
          }
        />
      </div>
    );
  }
}
