import { message } from "antd";
import React, { useMemo, useRef } from "react";
import { darkModeRowStyleRules } from "../../../../tools/formatters/GridStyles";
import { HoistVisualsForm, StopCounts } from "../../HoistFormFunc";
import { useRedux } from "../../../../../../hooks";
import { AgGridReact } from "ag-grid-react";

export default function GridData({
  serviceId,
  key,
  gridApi,
  setGridApi,
  isWritable,
  handleSave,
  serviceIndex,
  setTempCommands,
  tempCommands,
  darkMode,
  getColumnDefs,
  cellValueChanged,

  frameworkComponents,
  //   resizeGridRows,
  prevProps,
  optionIndex,
  elevationIndex,
  elevation,
}) {
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const gridRef = useRef(null);
  const onGridReady = (params, data) => {
    setGridApi(params.api);

    // params.api.setRowData(data);
    // params.api.sizeColumnsToFit();
    const defaultSortModel = [{ colId: "id", sort: "asc" }];
    // columnDefinitions[this.props.serviceId]["test"].find(c => c.field === "stop").cellRendererParams =
    params.columnApi.applyColumnState({ state: defaultSortModel }); //sort by default by id (which is the floor number)

    params.api.closeToolPanel();
  };
  const cellEditingStarted = () => {
    if (!isWritable) {
      gridApi.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  const saveHoistAddonData = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    addonsData,
    addonAPI
  ) => {
    let tempGridData = gridData;
    let floors =
      tempGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .items;

    let currentFloor = floors.find((floor) => floor.id === itemId);
    currentFloor.addons = addonsData;

    // gridApi.applyTransaction({ update: [currentFloor] });
    // gridApi.refreshCells({ rowNodes: [currentFloor], force: true });

    gridRef.current?.api?.getRowNode(itemId)?.setData(currentFloor);
    // setTakeOffTableData(tempGridData);

    console.log("saveHoistAddonData", {
      gridApi,
      serviceIndex,
      optionIndex,
      elevationIndex,
      itemId,
      addonsData,
      addonAPI,
    });
  };

  const rowData = useMemo(() => {
    let temp =
      gridData[serviceIndex]?.serviceOptions[optionIndex][elevationIndex]
        ?.items || [];
    setTempCommands({
      ...tempCommands,
      selectSingleStop: !temp?.some((item) => item?.stop !== true),
      selectDualStops: !temp?.some((item) => item?.dual !== true),
    });
    return temp;
  }, [gridData]);
  return (
    <div
      className="hoistFormSectionParent3d"
      style={{
        height: "100%",
        display: checkAccess(serviceId, "hasFloors") ? "flex" : "none",
        flexDirection: "column",
      }}
    >
      <div
        className={`elevationContainer  ${
          darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }`}
        style={{ height: "100%" }}
      >
        <br />
        {/* <ResizableBox
  className={"dataEntryGrid"}
  minConstraints={[0, 100]}
  maxConstraints={[0, 1500]}
  width={"auto"}
  height={calculateElevationOptimalHeight(
    elevation.items,
    UIFeatureForThisElevation,
    40
  )}
  axis="y"
  handle={
    <div className="gridResizer">
      <div className="gridResizerInnerLeft" />
      <div className="gridResizerHandler">
        <div className="gridResizerHandlerGrip" />
        <div className="gridResizerHandlerGrip" />
        <div className="gridResizerHandlerGrip" />
      </div>
      <div className="gridResizerInnerRight" />
    </div>
  }
> */}
        <AgGridReact
          key={key}
          ref={gridRef}
          // immutableData={true}
          domLayout={"autoHeight"}
          sideBar="columns"
          rowSelection={"single"}
          embedFullWidthRows={true}
          context={{
            isWritable,
            handleSave: handleSave,
          }}
          rowData={rowData}
          enableCellChangeFlash={true}
          detailRowAutoHeight={true}
          detailRowHeight={200}
          undoRedoCellEditingLimit={50}
          onGridReady={(params) => {
            // params.api.sizeColumnsToFit();
            onGridReady(params, elevation.items);
          }}
          columnDefs={getColumnDefs()}
          suppressDragLeaveHidesColumns={true}
          onCellEditingStarted={cellEditingStarted}
          // onCellEditingStopped={(params) => {
          //   props.resizeGridRows(params.api);
          // }}
          //   onColumnResized={(params) => {
          //     resizeGridRows(params.api);
          //   }}
          onCellValueChanged={cellValueChanged}
          defaultColDef={{
            // minWidth: 150,
            // width: "auto",
            editable: true,
            filter: true,
            sortable: true,
            resizable: true,
            autoHeight: true,
            detailRowAutoHeight: true,
            enablePivot: true,
            enableRowGroup: true,
            cellClass: "cell-wrap-text",
          }}
          rowHeight={20}
          headerHeight={32}
          frameworkComponents={frameworkComponents}
          getRowNodeId={(data) => data.id}
          masterDetail={true}
          detailCellRenderer="SidewalkShedAddonsDetail"
          detailCellRendererParams={{
            ServicesIndex: prevProps.frameworkComponents,
            handleSave: prevProps.handleSave,
            setState: prevProps.setState,
            isWritable: prevProps.isWritable,
            indexes: prevProps.indexes,
            // saveSidewalkShedAddonsData: prevProps.saveSidewalkShedAddonsData,
            saveSidewalkShedAddonsData: (
              gridApi,
              serviceIndex,
              optionIndex,
              elevationIndex,
              itemId,
              addonsData,
              addonAPI
            ) => {
              return saveHoistAddonData(
                gridApi,
                serviceIndex,
                optionIndex,
                elevationIndex,
                itemId,
                addonsData,
                addonAPI
              );
            },
            onAddonRichTextChange: prevProps.onAddonRichTextChange,
            pricingData: prevProps.pricingData,
            service: prevProps.service,
            selectOptions: prevProps.selectOptions,
          }}
          rowClassRules={darkModeRowStyleRules}
        />
        {/* </ResizableBox> */}
      </div>

      {/*< className="dataEntryGrid" style={{margin: '15px 0 0 0'}}>*/}

      {checkAccess(serviceId, "stops") && rowData?.length > 0 && (
        <div className={"stops-count-message"}>
          <StopCounts
            {...{
              // elevation,
              indexes: prevProps.indexes,
            }}
          />
        </div>
      )}
      {/* {displayMessage} */}
    </div>
  );
}
const checkAccess = (hoistId, key) => {
  const access = !!HoistVisualsForm?.[hoistId]?.[key] ?? false;
  return access;
};
