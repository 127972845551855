import { formatCurrency } from "../../../../../../../../../../../../../../../../utils";

import { message } from "antd";

export const cellValueChanged = ({
  setUnchangingFakeRentals,
  unchangingFakeRentals,
  selectedElevation,
  selectedService,
  initialRowData,
  selectedItem,
  params,
}) => {
  let NewValue = undefined;
  try {
    NewValue = eval(params?.value);
  } catch (e) {
    message?.warning("Invalid input");
    NewValue = 0;
  }

  const appliedToPli = unchangingFakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === selectedElevation?.elevationId
    )
    ?.items?.find(({ id }) => id === selectedItem?.id)?.appliedAmount;

  //calculates the no of days of the unlocked weeks
  const howManyUnlockedDays = initialRowData?.reduce(
    (acc, curr) =>
      curr?.week === params?.data?.week
        ? acc
        : !!curr?.lock
        ? acc
        : [...acc, ...curr?.days],
    []
  )?.length;

  //here we calculate the max value that we can edit
  const applicableMaxValue =
    appliedToPli -
    initialRowData?.reduce(
      (acc, curr) =>
        !!curr?.lock
          ? curr?.week === params?.data?.week
            ? acc
            : acc + curr?.weeklyAmount
          : acc,
      0
    );

  //this const calculates the new weekly amount
  const weeklyAmount =
    //if the input is less than 0 return 0 and message the user
    NewValue < 0
      ? (() => {
          message?.warning(`Input is less than ${formatCurrency(0)}`);
          return 0;
        })()
      : //if the input is less than the calculated max value return calculated max value and alert user
        // NewValue > applicableMaxValue
        // ? (() => {
        //     message?.warning(`Input is greater than ${formatCurrency(applicableMaxValue)}`)
        //     return applicableMaxValue
        //   })()
        // : //else if there is not any problem, return it unchanged
        NewValue;

  const remainingAmountPerDay =
    (applicableMaxValue - weeklyAmount) / howManyUnlockedDays;

  const newRental = {
    ...unchangingFakeRentals,
    services: unchangingFakeRentals?.services?.map((serviceData) =>
      serviceData?.label === selectedService
        ? {
            ...serviceData,
            serviceOptions: serviceData?.serviceOptions?.map((lineItemsData) =>
              // if this is the elevation that we are working on access and modify it
              lineItemsData?.elevationId === selectedElevation?.elevationId
                ? {
                    ...lineItemsData,
                    items: lineItemsData?.items?.map((itemsData) =>
                      // if this is the pli that we are working on access and modify it
                      itemsData?.id === selectedItem?.id
                        ? {
                            ...itemsData,
                            rentalDetails: {
                              ...itemsData?.rentalDetails,
                              details: itemsData?.rentalDetails?.details?.map(
                                (weekData) =>
                                  //if this is the week that we are working on access and modify it
                                  weekData?.week === params?.data?.week
                                    ? {
                                        ...weekData,
                                        lock: true,
                                        //the new weekly value****
                                        weeklyAmount: +weeklyAmount?.toFixed(2),
                                        days: weekData?.days?.map(
                                          (daysData, _, arr) => ({
                                            ...daysData,
                                            //here we take the input value and divide it with the length of the days
                                            dailyAmount: +(
                                              weeklyAmount / arr?.length
                                            )?.toFixed(2),
                                            lock: false,
                                          })
                                        ),
                                      }
                                    : //else if this is not the week that we are working on and this week is unlocked
                                    !weekData?.lock
                                    ? {
                                        ...weekData,

                                        //here we calculate the new value for the unlocked week, based on the days that they have inside
                                        // weeklyAmount: +(
                                        // 	remainingAmountPerDay * weekData?.days?.length
                                        // )?.toFixed(2),
                                        // days: weekData?.days?.map((daysData) => ({
                                        // 	...daysData,
                                        // 	//here we take the input value and divide it with the length of the days
                                        // 	dailyAmount: +remainingAmountPerDay?.toFixed(2),
                                        // 	lock: false,
                                        // })),
                                      }
                                    : //else if this is not the week that we are working and it is locked, return it unchanged
                                      weekData
                              ),
                            },
                          }
                        : //else if this is not the pli that we are working on return it unchanged
                          itemsData
                    ),
                  }
                : //else if this is not the elevation that we are working on return it unchanged
                  lineItemsData
            ),
          }
        : serviceData
    ),
  };

  // console.log(
  //   unchangingFakeRentals,
  //   selectedElevation,
  //   selectedService,
  //   initialRowData,
  //   selectedItem,
  //   params
  // )

  //here we sett changed data to context
  setUnchangingFakeRentals(newRental);
};
