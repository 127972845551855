export const invoices = (
  circleProgressPicker,
  IdHandler,
  updatedDynamicStates,
  invoicesData
) => {
  return {
    invoiceStatus: () => ({
      title: "Invoices - Invoice Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Invoices",
        field: "invoiceStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (invoicesData?.filter(
            (a) => a?.invoiceStatus === circleProgressPicker
          )?.length /
            invoicesData?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: invoicesData?.filter(
          (a) => a?.invoiceStatus === circleProgressPicker
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //invoiceStatus
  };
};
