export const initialState = {
  rowData: [],
  chargeToView: false,
  chargeItemsModal: false,
  newChargeVisible: false,
  chargeToSave: {},
  itemsGroup: [],
  selectedService: false,
  selectedEvent: false,
  applyCredit: false,
  creditApplied: [],
  updatedCreditApplied: [],
  remainingCredit: 0,
  appliedCredit: 0,
  charge: undefined,
  whereToFind: undefined,
  fromObject: [],
  selectedGroup: false,
  initialStates: {},
  actions: {},
}

export const actions = {
  SET_ROW_DATA: "SET_ROW_DATA",
  SET_CHARGE_TO_VIEW: "SET_CHARGE_TO_VIEW",
  SET_NEW_CHARGE_VISIBLE: "SET_NEW_CHARGE_VISIBLE",
  SET_CHARGE_ITEMS_MODAL: "SET_CHARGE_ITEMS_MODAL",
  SET_CHARGE_TO_SAVE: "SET_CHARGE_TO_SAVE",
  SET_ITEMS_GROUP: "SET_ITEMS_GROUP",
  SET_SELECTED_SERVICE: "SET_SELECTED_SERVICE",
  SET_SELECTED_EVENT: "SET_SELECTED_EVENT",
  SET_APPLY_CREDIT: "SET_APPLY_CREDIT",
  SET_CREDIT_APPLIED: "SET_CREDIT_APPLIED",
  SET_UPDATED_CREDIT_APPLIED: "SET_UPDATED_CREDIT_APPLIED",
  SET_REMAINING_CREDIT: "SET_REMAINING_CREDIT",
  SET_APPLIED_CREDIT: "SET_APPLIED_CREDIT",
  SET_CHARGE: "SET_CHARGE",
  SET_WHERE_TO_FIND: "SET_WHERE_TO_FIND",
  SET_FROM_OBJECT: "SET_FROM_OBJECT",
  SET_SELECTED_GROUP: "SET_SELECTED_GROUP",
  SET_INITIAL_STATES: "SET_INITIAL_STATES",
  SET_ACTIONS: "SET_ACTIONS",
}
