import {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import {
  FleetsLiveContext,
  loadLivePreference,
  saveLocalPreferences,
} from "../../../../utils";
import LiveMapContext from "../../LiveMapContext";
import LocationInfo from "../LocationInfo/LocationInfo";

import "./MapSidebar.scss";

const MapSidebar = forwardRef((_, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { locations = [] } = useContext(FleetsLiveContext);
  const {
    mapInstance,
    onInfoClick = () => {},
    getStatusFilter = () => {},
    getVehicleFilter = () => {},
    changeLocationInfo = () => {},
  } = useContext(LiveMapContext);

  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [drawnGeofence, setDrawnGeofence] = useState(null);

  function filterLocations(vins = [], status = null, text = "") {
    setFilteredLocations(
      locations?.filter(
        ({ deviceSerialNumber, locationStatus, fleetName = "" }) => {
          if (vins?.length) {
            if (!vins?.includes(deviceSerialNumber)) {
              return false;
            }
          }

          if (status) {
            if (locationStatus !== status) {
              return false;
            }
          }

          if (text) {
            return fleetName?.toLowerCase().includes(text?.toLowerCase());
          }

          return true;
        }
      )
    );
  }

  useEffect(() => {
    let truckVins = getVehicleFilter();
    let truckStatus = getStatusFilter();

    filterLocations(truckVins, truckStatus);
  }, [locations]);

  useEffect(() => {
    let defaultExpanded = loadLivePreference("mapSidebar");
    let list = document.getElementById("map-sidebar");
    if (list) {
      list.classList.add(`map-side-${defaultExpanded}`);
    }
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        filterByVin(arr) {
          filterLocations(arr, getStatusFilter());
        },
        filterByStatus(status) {
          filterLocations(getVehicleFilter(), status);
        },
        removeSelectedFence() {
          if (!!drawnGeofence) {
            drawnGeofence.setMap(null);
          }
        },
        toggleMapSidebar() {
          let sideElement = document.getElementById("map-sidebar");
          let willCollapse = undefined;
          if (sideElement) {
            if (sideElement.classList.contains("map-side-expanded")) {
              willCollapse = true;
              sideElement.classList.remove("map-side-expanded");
              sideElement.classList.add("map-side-collapsed");
              saveLocalPreferences({ mapSidebar: "collapsed" });
            } else {
              willCollapse = false;
              sideElement.classList.remove("map-side-collapsed");
              sideElement.classList.add("map-side-expanded");
              saveLocalPreferences({ mapSidebar: "expanded" });
            }
          }

          let mapController = document.getElementById("live-map-controller");
          if (mapController) {
            let sideWidth = loadLivePreference("liveSidebar") ? 65 : 200;
            let tripWidth = loadLivePreference("sidebarTripLocation")
              ? "18vw"
              : "0px";

            if (willCollapse) {
              mapController.style.width = `calc(100dvw - ${sideWidth}px - ${tripWidth})`;
            } else {
              mapController.style.width = `calc(100dvw - 18vw - ${sideWidth}px - ${tripWidth})`;
            }
          }
        },
        drawGeofence(points) {
          handleFenceClick(points, false);
        },
      };
    },
    [locations, drawnGeofence]
  );

  function locationClickHandler(location) {
    if (mapInstance) {
      mapInstance.panTo(location?.position);
      mapInstance.setZoom(18);
    }
    onInfoClick(location);
  }

  function handleTextFilter(e) {
    let text = e.target.value;
    let truckVins = getVehicleFilter();
    let truckStatus = getStatusFilter();
    filterLocations(truckVins, truckStatus, text);
  }

  function handleFenceClick(points, renderOnlyGeofence = true) {
    if (mapInstance) {
      let polygon = undefined;
      let bounds = undefined;

      let polygonOptions = {
        editable: false,
        draggable: false,
        strokeColor: "#d99f00",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: "#d99f00",
        fillOpacity: 0.35,
        paths: points?.map(({ latitude, longitude }) => ({
          lat: latitude,
          lng: longitude,
        })),
      };

      if (renderOnlyGeofence) {
        bounds = new window.google.maps.LatLngBounds();
        for (const point of points) {
          const { latitude, longitude } = point;
          if (latitude && longitude) {
            bounds.extend({
              lat: latitude,
              lng: longitude,
            });
          }
        }
      }

      if (!drawnGeofence) {
        polygon = new window.google.maps.Polygon(polygonOptions);
      } else {
        polygon = drawnGeofence;
        polygon.setOptions(polygonOptions);
      }

      polygon.setMap(mapInstance);
      if (bounds) {
        mapInstance.fitBounds(bounds);
        mapInstance.setZoom(17);
      }
      setDrawnGeofence(polygon);
    }
  }

  return (
    <div
      className={`map-sidebar ${isDarkMode ? "map-sidebar-dark" : ""}`}
      id="map-sidebar"
    >
      <div className="sidebar-title">
        <span>All Vehicles</span>
      </div>
      <div className="sidebar-search">
        <Input
          {...{
            placeholder: "Search vehicle...",
            allowClear: true,
            size: "large",
            style: {
              width: "95%",
            },
            prefix: <SearchOutlined />,
            onChange: handleTextFilter,
          }}
        />
      </div>
      <div className="trucks-section">
        {filteredLocations?.map((location, i) => {
          return (
            <LocationInfo
              location={location}
              nameTooltip={"Go to map location"}
              key={i}
              isDarkMode={isDarkMode}
              onInfoClick={() => {
                locationClickHandler(location);
                changeLocationInfo(location);
              }}
            />
          );
        })}
      </div>
    </div>
  );
});

export default MapSidebar;
