import { ReactElement } from "react";
import { message, notification } from "antd";
import dayjs from "dayjs";

import {
  getErrorMessage,
  warningNotificationTitle,
  getDriverErrorNotification,
  getDriverNotification,
} from "./userNotifications";

import { getLabelHTML, testUUID, getYards } from "./routeFields";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { parseInTz, setDateMonth, setHourMinute } from "./dateFunctions";

/**
 * Function that nullifies all time inputs from the caller to the return
 * @param {Number|null} index
 * @param {Number} routeLength
 */
export function nullifyAllTimes(index, routeLength) {
  //#region NULLIFY TIMES
  //we need to nullify all the time fields when we have changes in times and routes
  let tmpFields = {};
  if (index === null) {
    tmpFields["ReturnArriveBy#"] = null;
  } else {
    for (let i = index; i <= routeLength; i++) {
      let c = i === routeLength;
      tmpFields[`${c ? "Return" : ""}ArriveBy#${c ? "" : i}`] = null;
      if (!c) {
        tmpFields[`TimeExit#${i}`] = null;
      }
      if (i !== index) {
        tmpFields[`${c ? "Return" : ""}DepartAt#${c ? "" : i}`] = null;
      }
      tmpFields[`Duration#${i}`] = "";
    }
  }

  return tmpFields;
}

/**
 * Deletes a selected route
 * @param {Number} index The route that needs deleting
 * @param {Number} routeLength The total length
 * @param {Object} existingFields The existing form fields
 * @param {Boolean} createGhost Used when cancelling (not deleting) a route
 * @param {Array} cancelledRoutes List of routes that can be cancelled
 * @param {Object} rowObject The row object, used on route cancellation to get the correct data
 * @returns The new values with the field names
 */
export function removeRoute(
  index,
  routeLength,
  existingFields,
  createGhost = false,
  cancelledRoutes = [],
  rowObject
) {
  //#region REMOVE ROUTE
  let newFields = {};
  let c = index === routeLength - 1;

  //if the route is not adjacent to the return we want to
  //shift all the route fields up by one index
  if (index !== routeLength - 1) {
    Object.keys(existingFields)?.forEach((key) => {
      let splitKey = key?.split("#");
      let n = parseInt(splitKey?.[1]);
      if (!isNaN(n) && n > index && !key?.includes("Ghost")) {
        newFields[`${splitKey?.[0]}#${n - 1}`] = existingFields[key];
      }
    });
    newFields[`PickupLocation#${index}`] =
      existingFields[`DropOffLocation#${index - 1}`];
  } else if (index !== 0) {
    newFields["ReturnPickupLocation#"] =
      existingFields[`DropOffLocation#${index - 1}`];
  }

  if (index !== 0) {
    newFields = {
      ...newFields,
      ...changeCargoStates(
        index - 1,
        routeLength - 1,
        existingFields[`Cargo#${index - 1}`]
      ),
      ...nullifyAllTimes(index, routeLength - 1),
    };
    newFields[`${c ? "Return" : ""}DepartAt#${c ? "" : index}`] =
      existingFields[`TimeExit#${index - 1}`];
  } else {
    newFields = {
      "DropOffLocation#0": null,
      "PickupLocation#1": null,
      "ArriveBy#0": null,
      "Duration#0": "",
      "TimeExit#0": null,
      "DepartAt#0": existingFields["DepartAt#0"],
    };
  }

  if (createGhost) {
    let ghostIndex = cancelledRoutes?.findIndex(
      (el) => el?.id === existingFields[`cancelId#${index}`]
    );
    let ghostFields = populateFields(
      {
        routes: [rowObject?.routes?.[cancelledRoutes[ghostIndex]?.cancelIndex]],
      },
      true,
      cancelledRoutes[ghostIndex]?.cancelIndex
    );
    newFields = { ...newFields, ...ghostFields };
    if (index === 0) {
      newFields["cancelId#0"] = null;
    }
  }

  if (
    newFields[`DropOffLocation#${index}`] ===
      existingFields[`PickupLocation#${index}`] &&
    index !== 0
  ) {
    newFields[`DropOffLocation#${index}`] = null;
    if (index !== routeLength - 1) {
      newFields[`PickupLocation#${index + 1}`] = null;
    } else {
      newFields["ReturnPickupLocation#"] = null;
    }
    notification.warning({
      message: warningNotificationTitle(index),
      description:
        "Careful, the drop off location has been cleared since it would make the route locations the same",
      duration: 7,
      placement: "bottomLeft",
    });
  }

  return newFields;
}

/**
 * Function to get the suggested difference between arrival/departure times
 * @returns {Promise<{duration: number, distance: number}>} The duration difference in timestamp
 */
export async function getTimeDifference({
  origins = [],
  destinations = [],
  departureTime,
  arrivalTime,
  trafficModel,
  travelMode = google.maps.TravelMode.DRIVING,
}) {
  //#region GET TIME DIFF
  let depart = setDateMonth(departureTime, dayjsNY().add(1, "day"));
  let arrive = setDateMonth(arrivalTime, dayjsNY().add(1, "day"));
  let duration,
    distance = 0;
  let service = new window.google.maps.DistanceMatrixService();

  try {
    if (!origins?.length || !destinations?.length) {
      throw new Error("No Locations Set");
    }
    await service
      .getDistanceMatrix(
        {
          origins,
          destinations,
          travelMode,
          [!!departureTime ? "drivingOptions" : "transitOptions"]:
            !!departureTime
              ? {
                  departureTime: new Date(depart),
                  trafficModel,
                }
              : {
                  arrivalTime: new Date(arrive),
                },
          unitSystem: window.google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
          avoidFerries: false,
        },
        (response, status) => {
          if (status === window.google.maps.DistanceMatrixStatus.OK) {
            duration = !!response.rows[0].elements[0]["duration_in_traffic"]
              ? response.rows[0].elements[0]?.duration_in_traffic.value
              : response.rows[0].elements[0]?.duration?.value;
            distance = response.rows[0].elements[0]?.distance?.value;
          }
        }
      )
      .catch((err) => console.log("Error Getting Duration: ", err));
    return { duration: duration * 1000, distance };
  } catch (err) {
    console.log("Error executing duration function: ", err);
  }
}

/**
 * Function that gets the time suggestion and opens the appropriate popover on the route cards
 * @param {String} model The traffic model
 * @param {String} pop The caller's popover text, used to differentiate between depart and arrive
 * @param {String} caller The caller itself, used to differentiate between route and return
 * @param {Object} form A reference to the form
 * @param {Number} index The route who needs suggestions
 */
export async function getSuggestion(model, pop, caller = "route", form, index) {
  //#region GET SUGGESTION
  //condition to get the correct field from the form
  let c = caller === "route";
  let trafficModel =
    model === "Optimistic"
      ? "optimistic"
      : model === "Best Guess"
      ? "bestguess"
      : "pessimistic";

  let returnObj = {
    arrive: pop?.includes("Arrive"),
    index,
  };

  let baseSelector = !c ? "Return" : "";
  let indexSelector = c ? index : "";

  let depart = dayjsNY();
  let arrive = dayjsNY();

  if (!!form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`)) {
    depart = form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`);
  }

  if (!!form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`)) {
    arrive = form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`);
  }

  let pickup = form.getFieldValue(
    `${baseSelector}PickupLocation#${indexSelector}`
  );
  let dropOff = form.getFieldValue(
    `${baseSelector}DropOffLocation#${indexSelector}`
  );

  if (!pickup || !dropOff) {
    return;
  } else {
    pickup = pickup.trim();
    dropOff = dropOff.trim();
  }

  /**
   * When the user wants to make a route for hoist, they have the ability to choose the
   * same location. If that happens, we want to return a difference of 1 minute
   */
  if (pickup === dropOff) {
    let time = !!form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`)
      ? dayjsNY(
          form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`) ||
            undefined
        ).valueOf()
      : dayjsNY(
          form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`) ||
            undefined
        ).valueOf();

    returnObj["time"] = pop?.includes("Arrive") ? time + 60000 : time - 60000;
  } else {
    await getTimeDifference({
      origins: [
        form.getFieldValue(`${baseSelector}PickupLocation#${indexSelector}`),
      ],
      destinations: [
        form.getFieldValue(`${baseSelector}DropOffLocation#${indexSelector}`),
      ],
      departureTime: depart,
      arrivalTime: arrive,
      trafficModel,
    })
      .then((res) => {
        let time = !!form.getFieldValue(
          `${baseSelector}DepartAt#${indexSelector}`
        )
          ? dayjsNY(
              form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`) ||
                undefined
            ).valueOf()
          : dayjsNY(
              form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`) ||
                undefined
            ).valueOf();
        returnObj["time"] = pop?.includes("Arrive")
          ? time + res.duration
          : time - res.duration;
        returnObj["distance"] = res?.distance;
      })
      .catch((err) => console.log("Error getting time: ", err));
  }

  return returnObj;
}

/**
 * Function used for truck validation.
 * Checks that check that the dispatch does not overlap with another dispatch
 * @param {String} fleetId The selected truck
 * @param {Array} allDispatches The list of dispatches
 * @param {Number} depart The depart of the first route in timestamp
 * @param {Number} arrive The arrive of the return route in timestamp
 * @param {String} dispatchId The dispatch id of the opened dispatch
 */
export function validateTruckAvailability({
  fleetId,
  allDispatches,
  depart,
  arrive,
  dispatchId = null,
}) {
  //#region VALIDATE TRUCK AVAILABILITY
  //there are cases in which a user can create overlapping
  //dispatches with no overlapping routes. We need to run this
  //validation only for the truck, since the drivers can move
  //to other dispatches
  let startTimesForTruck = allDispatches
    ?.filter(
      ({ dispatchId: dI, fleetId: fI }) =>
        dI !== dispatchId && fI === fleetId && !!dI
    )
    ?.map(({ routes }) => ({
      exit: dayjsNY(routes[0]["departAt"]).valueOf(),
      entry: dayjsNY(routes[routes.length - 1]["arriveBy"]).valueOf(),
    }));

  let condition = false;

  for (let i = 0; i < startTimesForTruck?.length; i++) {
    let exit = startTimesForTruck[i].exit;
    let entry = startTimesForTruck[i].entry;

    if (depart >= exit && depart < entry) {
      condition = true;
      break;
    } else if (arrive > exit && arrive <= entry) {
      condition = true;
      break;
    } else if (depart <= exit && arrive >= entry) {
      condition = true;
      break;
    } else if (depart >= exit && arrive <= entry) {
      condition = true;
      break;
    }
  }

  return condition;
}

/**
 * Function that checks if a driver for a selected route
 * has conflicting times with other registered routes for the day
 * @param {String} caller "route" | "return"
 * @param {Array} filteredDispatches All the dispatches for the selected date
 * @param {FormInstance} form A pointer to the form instance
 * @param {Array} drivers The driver array
 * @param {Number} index The index of the caller route
 * @param {String} selector "driver" | "passenger" | null Check the driver that needs checking
 * @param {Function} setSwitchDriver in case of a conflict, if the drivers can be switched, sets a state
 * @param {Boolean} fromManualSelection Used when selecting a driver manually
 * @param {String} prevDriver The id of the previous driver on the dropdown
 * @returns The cause of the block: "truck" | "driver" | ""
 */
export function validateOverlapping({
  caller,
  filteredDispatches,
  form,
  drivers = [],
  index,
  selector = "driver",
  setSwitchDriver = () => {},
  fromManualSelection = false,
  prevDriver,
  canBeSelected = true,
  prevEditBlock = -1,
  setEditBlock = () => {},
}) {
  //#region VALIDATE OVERLAPPING
  let c = caller === "return";
  let baseSelector = c ? "Return" : "";
  let indexSelector = c ? "" : index;
  //if one of the dates is not set
  if (
    !form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`) ||
    !form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`)
  ) {
    return "";
  }

  const depart = dayjsNY(
    form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`)
  ).valueOf();

  const arrive = dayjsNY(
    form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`)
  ).valueOf();

  if (
    !dayjsNY(depart).toString().includes("Invalid Date") &&
    !dayjsNY(arrive).toString().includes("Invalid Date") &&
    filteredDispatches?.length > 0
  ) {
    let fleet = form.getFieldValue("truckNumber") ?? "";
    let thisPickup = form.getFieldValue(
      `${baseSelector}PickupLocation#${indexSelector}`
    );
    let driverId = form.getFieldValue(`${baseSelector}Driver#${indexSelector}`);
    let passengerId = form.getFieldValue(
      `${baseSelector}Passenger#${indexSelector}`
    );
    let driversObj = {
      driver: {
        driver: driverId,
        routesForDriver: !!driverId
          ? filteredDispatches.filter(
              (route) =>
                route?.driverId === driverId || route?.passengerId === driverId
            )
          : [],
      },
    };

    //when the "Arrive by" is changed, we should see if the route duration overlaps with another route
    //we check for the truck and the driver separately since either one can conflict with the route times
    if (
      !filteredDispatches
        ?.filter((el) => el?.fleetId === fleet)
        ?.every(
          (el) =>
            (depart < el?.firstExit && arrive <= el?.firstExit) ||
            (depart >= el?.lastEntry && arrive > el?.lastEntry)
        )
    ) {
      notification.error({
        message: getErrorMessage(index),
        description: (
          <span>
            <strong>Truck&nbsp;</strong>is not available for the selected time!
            Please select another time
          </span>
        ),
        duration: 5,
        placement: "bottomLeft",
      });
      return "truck";
    } else if (!!driverId || !!passengerId) {
      let conflict = "";
      Object.keys(driversObj)?.forEach((key) => {
        let switchCondition =
          !!prevDriver &&
          driversObj[key]["routesForDriver"]?.some(
            (route) => route?.pickupLocation === thisPickup
          );

        if (
          !!driversObj[key]["routesForDriver"]?.length &&
          !driversObj[key]["routesForDriver"]?.every(
            (el) =>
              (depart < el?.firstExit && arrive <= el?.firstExit) ||
              (depart >= el?.lastEntry && arrive > el?.lastEntry)
          )
        ) {
          getDriverErrorNotification({
            index,
            switchCondition,
            drivers,
            driver: driversObj[key]["driver"],
            setSwitchDriver,
            selector: !!selector ? selector : key,
            canBeSelected,
            setEditBlock,
            prevEditBlock,
          });
          conflict = "driver";
        } else {
          if (
            !!driversObj[key]["routesForDriver"]?.length &&
            !!switchCondition &&
            fromManualSelection
          ) {
            getDriverNotification(
              index,
              drivers?.find((el) => el?.driverId === driversObj[key]["driver"])
                ?.driverName,
              driversObj[key]["driver"],
              setSwitchDriver,
              selector,
              form,
              prevDriver,
              prevEditBlock,
              setEditBlock
            );
          }
        }
      });
      return conflict;
    }
  }

  return "";
}

/**
 * Function that populates the form fields
 * @param {Object} rowObject The selected object
 * @param {Boolean} ghost Wether we want to display a "disabled" route (after cancellation)
 * @param {Number} ghostIndex The index of the disabled fields that should be created
 */
export function populateFields(rowObject, ghost = false, ghostIndex) {
  //#region POPULATE FIELDS
  let formObject = {};
  if (!ghost) {
    formObject = {
      truckNumber: rowObject?.fleetId,
      date: dayjsNY(rowObject?.dispatchDate),
      dispatchStatus: rowObject?.status
        ? rowObject?.status
        : rowObject?.dispatchStatus || "Draft",
    };
  }
  let pickupCondition = false;

  //the times need to be set to today's date, otherwise the
  //google service won't work
  rowObject?.routes?.forEach((route, i) => {
    //condition for the last route (return route)
    let c = i === rowObject?.routes?.length - 1;

    if (ghost) {
      //when we create a ghost object, we only give it one route
      //(which is never the case for normal row objects)
      c = false;
    }
    let baseSelector = c ? "Return" : ghost ? "Ghost" : "";
    let fieldIndex = c ? "" : ghost ? ghostIndex : i;
    if (!!route?.passengerId?.[0]) {
      pickupCondition = true;
    }
    formObject = {
      ...formObject,
      [`${baseSelector}PickupLocation#${fieldIndex}`]: route?.pickUpLocation,
      [`${baseSelector}DropOffLocation#${fieldIndex}`]: route?.dropOffLocation,
      [`${baseSelector}ActivityStatus#${fieldIndex}`]:
        route?.activityStatus || "Draft",
      [`${baseSelector}CargoWeight#${fieldIndex}`]: isNaN(
        parseInt(route?.cargoWeight)
      )
        ? 0
        : parseInt(route?.cargoWeight),
      [`${baseSelector}Driver#${fieldIndex}`]: route?.driverId,
      [`${baseSelector}Passenger#${fieldIndex}`]: route?.passengerId?.[0] ?? "",
      [`${baseSelector}DepartAt#${fieldIndex}`]: !!route?.departAt
        ? dayjsNY(route?.departAt)
        : null,
      [`${baseSelector}Cargo#${fieldIndex}`]: route?.cargo,
      [`${baseSelector}ArriveBy#${fieldIndex}`]: !!route?.arriveBy
        ? dayjsNY(route?.arriveBy)
        : null,
      [`${baseSelector}Variance#${fieldIndex}`]: route?.variance || false,
      [`${baseSelector}Duration#${fieldIndex}`]: route?.timeScheduled,
      [`${baseSelector}PaperworkType#${fieldIndex}`]: route?.paperworkType,
      [`${baseSelector}PaperworkCollectStatus#${fieldIndex}`]:
        route?.paperworkCollectStatus,
      [`${baseSelector}PaperworkSoftwareStatus#${fieldIndex}`]:
        route?.paperworkSoftwareStatus,
      [`${baseSelector}NotesAndDetails#${fieldIndex}`]:
        route?.dispatchNotes ?? "",
    };
    //the return route doesn't have a schedule or an exit time
    if (!c) {
      formObject[`${baseSelector}Schedule#${fieldIndex}`] = route?.scheduleId;
      formObject[`${baseSelector}TimeExit#${fieldIndex}`] = !!route?.timeExit
        ? dayjsNY(route?.timeExit)
        : null;
    }

    if (!c && !ghost) {
      formObject[`cancelId#${i}`] = route?.activityId;
    }

    if (ghost && ghostIndex === 0) {
      formObject[`cancelId#${fieldIndex}`] = null;
    }
  });

  formObject["isPickup"] = pickupCondition;

  return formObject;
}

export function populateRequestObject(
  requestObject,
  drivers = [],
  trucks = []
) {
  //#region POPULATE REQUEST OBJECT
  let selectedDriver = drivers?.find(
    (el) => el?.driverId === requestObject?.driver
  );

  let selectedTime = null;
  if (!!requestObject?.timeOnSite) {
    selectedTime = setHourMinute(
      parseInTz(requestObject?.dateOnSite),
      dayjsNY(requestObject?.timeOnSite)
    );
  }

  let dummyRowData = {
    routes: [
      {
        pickUpLocation: getYards()?.[0],
        dropOffLocation: requestObject?.jobsiteAddress?.trim(),
        arriveBy: selectedTime,
        cargo: requestObject?.cargoStatus,
        driverName: selectedDriver?.driverName,
        driverId: selectedDriver?.driverId,
        dispatchNotes: !!requestObject?.routeNotes
          ? `[${requestObject?.priority}] - ${requestObject?.routeNotes}`
          : "",
      },
      {
        pickUpLocation: requestObject?.jobsiteAddress?.trim(),
        dropOffLocation: getYards()?.[0],
        departAt: !!selectedTime
          ? dayjsNY(selectedTime).add(1, "h").format()
          : null,
        cargo: requestObject?.cargoStatus === "Empty" ? "Loaded" : "Empty",
        driverName: selectedDriver?.driverName,
        driverId: selectedDriver?.driverId,
      },
    ],
  };

  if (
    requestObject?.scheduleId ||
    requestObject?.selectedMainField?.scheduleId
  ) {
    dummyRowData.routes[0]["scheduleId"] =
      requestObject?.scheduleId || requestObject?.selectedMainField?.scheduleId;
  } else if (
    requestObject?.projectId ||
    requestObject?.selectedMainField?.projectId
  ) {
    dummyRowData.routes[0]["projectId"] =
      requestObject?.projectId || requestObject?.selectedMainField?.projectId;
  }

  return {
    ...populateFields(dummyRowData),
    ...changeCargoStates(0, 1, requestObject?.cargoStatus),
    date: dayjsNY(requestObject?.dateOnSite),
    truckNumber: requestObject?.vehicle ?? null,
  };
}

/**
 * Function that changes the cargo states of all the routes below the caller
 * @param {Number} index The caller's index
 * @param {Number} routeLength The total length of the routes
 * @param {String} newState The selected cargo state
 */
export function changeCargoStates(index, routeLength, newState) {
  //#region CHANGE CARGO STATES
  let e = newState === "Empty";
  let currCargo = newState;
  let tmpFields = {};

  for (let i = index; i <= routeLength; i++) {
    let c = i === routeLength;
    let baseSelector = c ? "Return" : "";
    let indexSelector = c ? "" : i;
    tmpFields = {
      ...tmpFields,
      [`${baseSelector}Cargo#${indexSelector}`]: currCargo,
      [`${baseSelector}PaperworkType#${indexSelector}`]: c
        ? "Inbound"
        : i === 0
        ? "Outbound"
        : "Inbound",
      [`${baseSelector}PaperworkCollectStatus#${indexSelector}`]: e
        ? "Not Required"
        : "Waiting Paperwork",
      [`${baseSelector}PaperworkSoftwareStatus#${indexSelector}`]: e
        ? "Not Required"
        : "Pending",
    };
    if (i !== index || currCargo === "Empty") {
      tmpFields[`${baseSelector}CargoWeight#${indexSelector}`] = 0;
    }
    e = !e;
    currCargo = currCargo === "Empty" ? "Loaded" : "Empty";
  }

  return tmpFields;
}

/**
 * Function that adds a route in a specific index
 * @param {Number} index The caller index
 * @param {Object} existingFields The form fields
 * @param {Number} routeLength The total route length
 * @param {Object | null} defaultObject Default data to put in a new route
 */
export function addRouteOnIndex(
  index,
  existingFields,
  routeLength,
  defaultObject = null
) {
  //#region ADD ROUTE ON INDEX
  //when inserting a new route, we want to shift all the routes below
  //the new route will have an index of "index+1", the caller index is the
  //index of the route above the "Add route" button
  let newFields = {};

  //we use a condition to see if the caller route is adjacent to the return
  let c = index === routeLength - 1;
  let newIndex = index + 1;

  let rq = null,
    address = null,
    newArrive = null;
  if (!!defaultObject) {
    rq = defaultObject.requestObject;
    address = defaultObject.address;
    if (!!rq?.timeOnSite) {
      newArrive = dayjsNY(
        setHourMinute(existingFields["date"], dayjsNY(rq?.timeOnSite))
      );
    }
  }

  //this regulates the fields of the new added route
  newFields = {
    [`DropOffLocation#${newIndex}`]: address,
    [`DropOffLocation#${newIndex + 1}`]:
      existingFields[`DropOffLocation#${newIndex}`],
    [`${c ? "Return" : ""}PickupLocation#${c ? "" : newIndex + 1}`]: address,
    [`PickupLocation#${newIndex}`]: existingFields[`DropOffLocation#${index}`],
    [`ActivityStatus#${newIndex}`]: "Draft",
    [`Schedule#${newIndex}`]: "",
    [`Schedule#${newIndex + 1}`]: existingFields[`Schedule#${newIndex}`],
    [`Driver#${newIndex}`]:
      existingFields[`${c ? "Return" : ""}Driver#${c ? "" : index + 1}`],
    [`Driver#${newIndex + 1}`]:
      existingFields[`${c ? "Return" : ""}Driver#${c ? "" : index + 1}`],
    [`cancelId#${newIndex}`]: "",
    [`NotesAndDetails#${newIndex}`]: !!rq?.routeNotes
      ? `[${rq?.priority}] - ${rq?.routeNotes}`
      : "",
    [`NotesAndDetails#${newIndex + 1}`]:
      existingFields[`NotesAndDetails#${newIndex}`],
    ...(!!defaultObject ? { [`Cargo#${newIndex}`]: "" } : {}),
    ...(!!newArrive
      ? {
          [`DepartAt#${newIndex}`]: null,
          [`ArriveBy#${newIndex}`]: newArrive,
          [`TimeExit#${newIndex}`]: dayjsNY(newArrive).add(1, "h"),
          [`${c ? "Return" : ""}DepartAt#${c ? "" : newIndex + 1}`]: dayjsNY(
            newArrive
          ).add(1, "h"),
        }
      : {
          [`DepartAt#${newIndex}`]: existingFields[`TimeExit#${index}`],
          [`DepartAt#${newIndex + 1}`]: null,
        }),
  };

  //if the new route is not above the return we want to shift all the indexes
  if (!c) {
    for (let i = newIndex + 1; i < routeLength + 1; i++) {
      for (const key in existingFields) {
        if (key?.includes(`#${i}`) && !key?.includes("Ghost")) {
          let tmp = key?.split("#")[0];
          newFields[`${tmp}#${i + 1}`] = existingFields[key];
        }
      }
    }
  }

  if (!!existingFields[`cancelId#${newIndex}`]) {
    newFields[`cancelId#${newIndex + 1}`] =
      existingFields[`cancelId#${newIndex}`];
    newFields[`cancelId#${newIndex}`] = "";
  }

  newFields = {
    ...newFields,
    [`ActivityStatus#${newIndex + 1}`]:
      existingFields[`ActivityStatus#${newIndex}`],
    ...nullifyAllTimes(
      !!defaultObject ? newIndex + 1 : newIndex,
      routeLength + 1
    ),
  };

  return newFields;
}

/**
 * Function that updates all drivers from the route that selected to the return route
 * @param {Number} index The caller route's index
 * @param {FormInstance} form A pointer to the form instance
 * @param {Number} routeLength The route length
 * @param {Array} filteredDispatches All the dispatches for the selected date
 * @param {String} selector "driver" | "passenger" Check the driver that needs checking
 * @param {Array} drivers The list of drivers
 * @param {Function} setSwitchDriver Needs to be passed to the validation function
 * @param {String} prevDriver The previously set driver, before a selection
 */
export function driverSelect(
  index,
  form,
  routeLength,
  filteredDispatches,
  selector = "driver",
  drivers = [],
  setSwitchDriver,
  prevDriver
) {
  //#region DRIVER SELECT
  let driver = form.getFieldValue(
    `${index !== null ? "" : "Return"}Driver#${index !== null ? index : ""}`
  );
  if (selector === "driver") {
    if (index === null) {
      let res = validateOverlapping({
        caller: "return",
        filteredDispatches,
        form,
        drivers,
        index,
        selector,
        setSwitchDriver,
        fromManualSelection: true,
        prevDriver,
      });
      if (res === "driver") {
        form.setFieldValue(`${c ? "Return" : ""}Driver#${c ? "" : i}`, "");
      }
    } else {
      let prevInvalid = false;
      for (let i = index; i <= routeLength; i++) {
        //condition to check if it's the return route
        let c = i === routeLength;
        if (prevInvalid) {
          form.setFieldValue(
            `${c ? "Return" : ""}DepartAt#${c ? "" : i}`,
            null
          );
          break;
        } else {
          //we skip the first route because of outside validations
          //the validation here would be duplicated
          if (i === index) {
            continue;
          }
          if (
            form.getFieldValue(`${c ? "Return" : ""}Driver#${c ? "" : i}`) ===
              prevDriver ||
            !form.getFieldValue(`${c ? "Return" : ""}Driver#${c ? "" : i}`)
          ) {
            form.setFieldValue(
              `${c ? "Return" : ""}Driver#${c ? "" : i}`,
              driver
            );
            let res = validateOverlapping({
              caller: c ? "return" : "route",
              filteredDispatches,
              form,
              drivers,
              index: i,
              selector,
              setSwitchDriver,
              prevDriver,
            });
            if (res === "driver") {
              form.setFieldValue(
                `${c ? "Return" : ""}Driver#${c ? "" : i}`,
                ""
              );
              prevInvalid = true;
            }
          }
        }
      }
    }
  } else {
    let res = validateOverlapping({
      caller: index !== null ? "route" : "return",
      filteredDispatches,
      form,
      drivers,
      index,
      selector,
      setSwitchDriver,
      fromManualSelection: true,
      prevDriver,
    });
    if (res === "passenger") {
      form.setFieldValue(
        `${index !== null ? "" : "Return"}Passenger#${
          index !== null ? index : ""
        }`,
        ""
      );
    }
  }
}

/**
 * Function that looks through the autocomplete dropdown suggestion and edits the list of labels
 * @param {Object} data
 * @param {ReactElement[]} [data.listOfLabels] The list of labels
 * @param {Object} data.option The autocomplete dropdown option object
 * @param {Number} [data.index] The caller index
 * @param {Boolean} [data.omitVendors] Choose to not include vendors in the validation
 * @returns {{type: "google"|"schedule"|"vendor"|"project"|"yard", list: ReactElement[]}} the type of the selection and the new list to put in the list of labels
 */
export function getNewLabelHTML({
  listOfLabels = [],
  option,
  index = 0,
  omitVendors = false,
}) {
  //#region GET NEW LABEL HTML
  let tmp = [...listOfLabels];
  let tmpType = "";
  if (!option?.key && !option?.value) {
    //google results
    tmp[index] = getLabelHTML("not a project (off schedule)");
    tmpType = "google";
  } else if (option?.value !== option?.label) {
    if (option?.value?.trim() === option?.label) {
      //schedule selected
      tmp[index] = getLabelHTML("schedule");
      tmpType = "schedule";
    } else {
      if (testUUID(option.key) && !omitVendors) {
        tmp[index] = getLabelHTML("vendor");
        tmpType = "vendor";
      } else {
        //google results
        tmp[index] = getLabelHTML("not a project (off schedule)");
        tmpType = "google";
      }
    }
  } else {
    if (getYards()?.includes(option?.value)) {
      //yard selected
      tmp[index] = getLabelHTML("yard");
      tmpType = "yard";
    } else {
      //project selected
      tmp[index] = getLabelHTML("project (off schedule)");
      tmpType = "project";
    }
  }
  return {
    type: tmpType,
    list: tmp,
  };
}

/**
 * Function that checks if the chain of routes from the selected route start from the yard
 * @param {Array} routesInvolvingDriver The chronologically sorted routes
 * @param {Number} startIndex The index of the selected route
 * @param {Boolean} fromYard Specifies if the function should check for the routes from/to the yard
 * @return an object containing the validity of the route, the location to which the routes are missing
 * and if there are no start/return to the routes for the driver
 */
function validateDriverLocation({
  routesInvolvingDriver = [],
  startIndex = 0,
  fromYard = true,
}) {
  //#region VALIDATE DRIVER LOCATION
  //depending on what we're looking for, if the first or last route
  //don't have the yard as a location, it shouldn't check any further
  let yardProblem = false;
  if (
    fromYard &&
    !getYards()?.includes(routesInvolvingDriver[0]?.pickupLocation)
  ) {
    yardProblem = true;
  }
  if (
    !fromYard &&
    !getYards()?.includes(
      routesInvolvingDriver[routesInvolvingDriver?.length - 1]?.dropOffLocation
    )
  ) {
    yardProblem = true;
  }

  let routes = structuredClone(routesInvolvingDriver);
  let index = startIndex;
  if (!fromYard) {
    routes = routes.reverse();
    index = routes?.length - 1 - index;
  }

  let returnCondition = true;
  let selector = fromYard ? "pickupLocation" : "dropOffLocation";

  let breakLocation = "";

  for (let i = index; i > 0; i--) {
    let opSelector =
      selector === "pickupLocation" ? "dropOffLocation" : "pickupLocation";
    if (routes?.[i]?.[selector] !== routes?.[i - 1]?.[opSelector]) {
      breakLocation = routes[i - 1][opSelector];
      returnCondition = false;
      break;
    }
  }

  return { valid: returnCondition, breakLocation, yardProblem };
}

/**
 * Helper function that parses the form fields into routes for a driver/truck
 * @param {String} driverId
 * @param {Object} existingFields
 * @param {Number} routeLength
 * @param {String | false} truckId
 * @returns
 */
export function getDriverRoutes(
  driverId,
  existingFields,
  routeLength,
  truckId = false
) {
  //#region GET DRIVER ROUTES
  let routesInvolvingDriver = [];

  for (let i = 0; i <= routeLength; i++) {
    let r = i === routeLength;
    if (
      driverId === existingFields[`${r ? "Return" : ""}Driver#${r ? "" : i}`] ||
      driverId ===
        existingFields[`${r ? "Return" : ""}Passenger#${r ? "" : i}`] ||
      truckId === existingFields["truckNumber"]
    ) {
      //we need to change the date formats to the selected date to prevent validation
      //errors in edit mode for dates different from today's date
      let exit = existingFields[`${r ? "Return" : ""}DepartAt#${r ? "" : i}`];
      let entry = existingFields[`${r ? "Return" : ""}ArriveBy#${r ? "" : i}`];
      let dropOffLocation =
        existingFields[`${r ? "Return" : ""}DropOffLocation#${r ? "" : i}`];
      if (!!dropOffLocation) {
        dropOffLocation =
          existingFields[
            `${r ? "Return" : ""}DropOffLocation#${r ? "" : i}`
          ]?.trim();
      }
      if (r && !!existingFields["PickupLocation#0"]) {
        dropOffLocation = existingFields["PickupLocation#0"];
      }
      if (!!exit) {
        exit = parseInTz(exit).valueOf();
      }
      if (!!entry) {
        entry = parseInTz(entry).valueOf();
      }
      routesInvolvingDriver.push({
        firstExit: exit,
        lastEntry: entry,
        driverId: existingFields[`${r ? "Return" : ""}Driver#${r ? "" : i}`],
        passengerId:
          existingFields[`${r ? "Return" : ""}Passenger#${r ? "" : i}`],
        pickupLocation:
          existingFields[`${r ? "Return" : ""}PickupLocation#${r ? "" : i}`] ??
          "",
        dropOffLocation,
        routeName: r ? "Return Route" : `Route ${i + 1}`,
        fleetId: existingFields["truckNumber"],
      });
    }
  }
  return routesInvolvingDriver;
}

/**
 * Function that switches routes between two drivers
 * @param {Array} filteredDispatches The filtered dispatches
 * @param {Object} selectedRoute The selected route from the modal selection
 * @param {String} prevDriver The driver id that needs to be removed from the existing routes
 * @param {String} newDriver The driver id that needs to be put in the roues
 * @param {Function} setAllDispatches State action to refresh the data
 * @param {Object} existingFields The existing form fields
 * @param {Number | null} index The switch index, when null, it's the return route
 * @param {Array} drivers The list of drivers
 * @param {Array} allDispatches The list of dispatches
 * @param {Function} setRouteSwitched State setter for the switch tracking state
 */
export function switchDrivers(
  filteredDispatches,
  selectedRoute,
  prevDriver,
  newDriver,
  setAllDispatches,
  existingFields,
  index,
  drivers,
  allDispatches,
  setRouteSwitched,
  routeSwitched,
  setSwitchDriver,
  setAffectedDispatches,
  routeLength,
  affectedDispatches
) {
  //#region SWITCH DRIVERS
  //The filtered dispatches don't include routes of the currently open dispatch
  //all the routes that have an arrive time later than the selected route's depart need to be updated
  let c = index === null;

  let switchRouteDepart = dayjsNY(
    existingFields[`${c ? "Return" : ""}DepartAt#${c ? "" : index}`]
  )?.valueOf();

  let linkedDispatch = null;

  if (!!selectedRoute) {
    linkedDispatch = allDispatches?.find(
      (el) => el?.dispatchId === selectedRoute?.dispatchId
    );
  }

  let allRoutesForPrevDriver = filteredDispatches?.filter(
    (el) => el?.driverId === prevDriver || el?.passengerId === prevDriver
  );

  let allRoutesForNewDriver = filteredDispatches?.filter(
    (el) => el?.driverId === newDriver || el?.passengerId === newDriver
  );

  //first we need the total list of routes
  let prevDriverRoutes = allRoutesForPrevDriver?.filter(
    (el) => el?.firstExit >= switchRouteDepart
  );

  prevDriverRoutes.sort((a, b) => a?.firstExit - b?.firstExit);

  let newDriverDepart = selectedRoute?.firstExit || switchRouteDepart;

  //we also want a list of routes involving the new driver
  let newDriverRoutes = allRoutesForNewDriver?.filter(
    (el) => el?.firstExit >= newDriverDepart
  );

  newDriverRoutes.sort((a, b) => a?.firstExit - b?.firstExit);

  let prevDriverObject = drivers?.find((el) => el?.driverId === prevDriver);
  let newDriverObject = drivers?.find((el) => el?.driverId === newDriver);

  let dispatches = structuredClone(allDispatches);
  let switched = structuredClone(routeSwitched);

  //at this point we have all the routes retaining the drivers and enter times larger than the route's depart
  //now all of those routes need to be updated. The drivers need to be switched
  prevDriverRoutes?.forEach((route, i) => {
    let dispatch = dispatches?.find(
      (el) => el?.dispatchId === route?.dispatchId
    );
    //we only get the first route's index since that is the one that should
    //be changed when opening a new modal
    if (i === 0) {
      //to calculate the correct new depart time we need to take the route before
      //the first element and read the arrival time
      let newDepart = switchRouteDepart;
      let tmpRouteIndex = allRoutesForPrevDriver?.findIndex(
        (r) => r?.activityId === route?.activityId
      );
      if (tmpRouteIndex > -1) {
        let tmpDepart = allRoutesForPrevDriver?.[tmpRouteIndex]?.firstExit;
        if (tmpDepart > switchRouteDepart) {
          newDepart = tmpDepart + 1;
        }
      }
      //the data for a single affected dispatch
      let t = switched?.find(
        ({ dispatchId }) => dispatchId === route?.dispatchId
      );
      if (!!t) {
        //if there was a switch on this dispatch we need to see if it happened
        //on the same route. In this case we update the depart time
        let conflict = t?.routeChangeInfo?.findIndex(
          (el) => el?.index === route?.originalRouteIndex
        );
        if (conflict > -1) {
          t.routeChangeInfo[conflict]["depart"] = newDepart;
        } else {
          t.routeChangeInfo = [
            ...t.routeChangeInfo,
            {
              index: route?.originalRouteIndex,
              depart: newDepart,
            },
          ];
        }
      }
      switched.push({
        dispatchId: route?.dispatchId,
        routeChangeInfo: [
          {
            index: route?.originalRouteIndex,
            depart: newDepart,
          },
        ],
      });
    }
    if (route?.driverId === prevDriver) {
      dispatch["routes"][route?.originalRouteIndex]["driverId"] = newDriver;
      dispatch["routes"][route?.originalRouteIndex]["driverSub"] =
        newDriverObject?.driverSub ?? "";
      dispatch["routes"][route?.originalRouteIndex]["driverName"] =
        newDriverObject?.driverName;
    } else if (route?.passengerId === prevDriver) {
      dispatch["routes"][route?.originalRouteIndex]["passengerId"] = [
        newDriver,
      ];
    }
  });

  //contains a variable used for updating external dispatches
  let newDepartForThisDispatch = false;
  //contains the time difference between the selected route and the new depart time
  let timeDifference = 0;
  let departToMoveFrom = switchRouteDepart;
  let driverToConsider = prevDriver;

  newDriverRoutes?.forEach((route, i) => {
    let dispatch = dispatches?.find(
      (el) => el?.dispatchId === route?.dispatchId
    );
    // if (route?.firstExit === newDriverDepart) {
    if (i === 0) {
      let newDepart = switchRouteDepart;
      let tmpRouteIndex = allRoutesForNewDriver?.findIndex((r) => {
        if (!!route?.activityId) {
          return r?.activityId === route?.activityId;
        } else {
          return !r?.activityId && r?.pickupLocation === route?.pickupLocation;
        }
      });
      if (tmpRouteIndex > -1) {
        let tmpDepart = allRoutesForNewDriver?.[tmpRouteIndex]?.firstExit;
        timeDifference = Math.abs(
          dayjs(switchRouteDepart).diff(dayjs(tmpDepart), "ms")
        );
        if (tmpDepart > switchRouteDepart) {
          departToMoveFrom = tmpDepart;
          newDepart = tmpDepart + 1;
          driverToConsider = newDriver;
          setSwitchDriver((prev) => {
            let t = structuredClone(prev);
            if (!!t["allRoutes"] && !!t["selectedRoute"]) {
              delete t["allRoutes"];
              delete t["selectedRoute"];
            }
            return { ...t, depart: newDepart };
          });
          newDepartForThisDispatch = true;
        } else {
          // departToMoveFrom = tmpDepart;
        }
      } else {
        setSwitchDriver((prev) => {
          let t = structuredClone(prev);
          delete t["allRoutes"];
          return t;
        });
      }
      //the data for a single affected dispatch
      let t = switched?.find(
        ({ dispatchId }) => dispatchId === route?.dispatchId
      );
      if (!!t) {
        //if there was a switch on this dispatch we need to see if it happened
        //on the same route. In this case we update the depart time
        let conflict = t?.routeChangeInfo?.findIndex(
          (el) => el?.index === route?.originalRouteIndex
        );
        if (conflict > -1) {
          t.routeChangeInfo[conflict]["depart"] = newDepart;
        } else {
          t.routeChangeInfo = [
            ...t.routeChangeInfo,
            {
              index: route?.originalRouteIndex,
              depart: newDepart,
            },
          ];
        }
      } else {
        switched.push({
          dispatchId: route?.dispatchId,
          routeChangeInfo: [
            {
              index: route?.originalRouteIndex,
              depart: newDepart,
            },
          ],
        });
      }
    }

    if (route?.driverId === newDriver) {
      dispatch["routes"][route?.originalRouteIndex]["driverId"] = prevDriver;
      dispatch["routes"][route?.originalRouteIndex]["driverSub"] =
        prevDriverObject?.driverSub ?? "";
      dispatch["routes"][route?.originalRouteIndex]["driverName"] =
        prevDriverObject?.driverName;
    } else if (route?.passengerId === newDriver) {
      dispatch["routes"][route?.originalRouteIndex]["passengerId"] = [
        prevDriver,
      ];
    }
  });

  /**
   * During a driver switch some of the routes are moved up in time
   * we need to target all the dispatches with routes after the "moved up route"
   * then change all the routes for every affected driver of every external dispatch
   * If newDepartForThisDispatch is true, it means that we need to get all the drivers
   * for the opened dispatch and move all their routes by the time difference.
   * Else we need to target the dispatch of the selected route
   */
  const { updatedDispatches, affectedDispatches: affectedList } =
    moveAllAffectedDispatches({
      allDispatches: dispatches,
      timeDifference,
      originalTime: departToMoveFrom,
      filteredDispatches,
      existingFields,
      driver: driverToConsider,
      prevDriver,
      index,
      routeLength,
      setAffectedDispatches,
      affectedDispatches,
      rootDispatch: linkedDispatch,
      routeSwitched,
      // excludeDispatchToSwitch: switched
      //   ?.reduce((acc, val) => [...acc, val?.dispatchId], [])
      //   ?.filter((el) => el !== linkedDispatch?.dispatchId),
      excludeDispatchToSwitch: [],
    });

  setAllDispatches(updatedDispatches);
  setRouteSwitched(switched);
}

function getTmpBody({ depart, duration, pickup, dropOff, driverToPut }) {
  //#region GET TMP BODY
  return {
    pickUpLocation: pickup,
    dropOffLocation: dropOff,
    driverId: driverToPut,
    passengerId: [],
    departAt: dayjsNY(depart).format(),
    arriveBy: dayjsNY(depart + duration).format(),
    timeExit: dayjsNY(depart + duration)
      .add(1, "h")
      .format(),
    timeScheduled: dayjs
      .utc(dayjs.duration(duration).asMilliseconds())
      .format("HH:mm"),
    cargo: "",
    paperworkType: "",
    paperworkCollectStatus: "",
    paperworkSoftwareStatus: "",
    dispatchNotes: "",
  };
}

/**
 * Function that adds new routes in an external dispatch
 */
export async function handleExternalNewRoutes({
  selectedRoute,
  allDispatches,
  setAllDispatches,
  existingFields,
  index,
  prevDriver,
  driverToPut,
  setSwitchDriver,
  filteredDispatches,
  routeLength,
  setAffectedDispatches,
  affectedDispatches,
  routeSwitched,
}) {
  //#region HANDLE EXTERNAL NEW ROUTES
  let baseSelector = index === null ? "Return" : "";
  let indexSelector = index === null ? "" : index;
  let originalRouteIndex = selectedRoute?.originalRouteIndex;
  let selectRouteIndex = selectedRoute?.originalRouteIndex + 2;
  //we get the depart for the new route
  let newRouteDepart = dayjsNY(selectedRoute?.lastEntry).add(1, "h").valueOf();

  //we calculate the arrival time
  await getTimeDifference({
    origins: [selectedRoute?.dropOffLocation],
    destinations: [
      existingFields[`${baseSelector}PickupLocation#${indexSelector}`],
    ],
    departureTime: newRouteDepart,
    arrivalTime: undefined,
    trafficModel: "bestguess",
  }).then(async ({ duration }) => {
    //these are the times that need to be set in the dispatches
    let tmp = structuredClone(allDispatches);
    let dispatchIndex = tmp?.findIndex(
      (el) => el?.dispatchId === selectedRoute?.dispatchId
    );
    if (dispatchIndex > -1) {
      let tmpRoutes = structuredClone(tmp[dispatchIndex]?.routes);
      tmpRoutes.splice(
        selectedRoute?.originalRouteIndex + 1,
        0,
        getTmpBody({
          depart: newRouteDepart,
          duration,
          pickup: selectedRoute?.dropOffLocation,
          dropOff:
            existingFields[`${baseSelector}PickupLocation#${indexSelector}`],
          driverToPut,
        })
      );
      //we update the exit time of the selected route to match the new route
      tmpRoutes[selectedRoute?.originalRouteIndex].timeExit =
        tmpRoutes[selectedRoute?.originalRouteIndex + 1].departAt;

      //if the route below need to be moved because of a time conflict, we do that
      let nextRouteDepart =
        tmpRoutes[selectedRoute?.originalRouteIndex + 2].departAt;
      let conflictCondition1 = false;
      let conflictCondition2 = false;
      if (
        dayjsNY(nextRouteDepart).valueOf() <
        newRouteDepart + duration + 3600000
      ) {
        conflictCondition1 = true;
      }
      tmpRoutes[selectedRoute?.originalRouteIndex + 2].departAt = dayjsNY(
        newRouteDepart + duration + 3600000
      ).format();

      /**
       * After adding the new route, we need to check the driver that comes after
       * in the dispatch. If the driver is different, it means that there previously
       * was a driver switch. If this is the case, we need to add another route below that
       * sends the truck to the switch location
       */
      if (
        tmpRoutes[selectedRoute?.originalRouteIndex + 2].driverId ===
        driverToPut
      ) {
        //if the driver stays the same, it means that we just need to change the location
        //as if we manually added a route on index
        tmpRoutes[selectedRoute?.originalRouteIndex + 2].pickUpLocation =
          existingFields[`${baseSelector}PickupLocation#${indexSelector}`];
      } else {
        await getTimeDifference({
          origins: [
            existingFields[`${baseSelector}PickupLocation#${indexSelector}`],
          ],
          destinations: [
            tmpRoutes[selectedRoute?.originalRouteIndex + 2].pickUpLocation,
          ],
          departureTime: newRouteDepart + duration + 3600000,
          arrivalTime: undefined,
          trafficModel: "bestguess",
        }).then(({ duration: secondDuration }) => {
          let newDepart = newRouteDepart + duration + 3600000;
          // ++selectRouteIndex;
          tmpRoutes.splice(
            selectedRoute?.originalRouteIndex + 2,
            0,
            getTmpBody({
              depart: newDepart,
              duration: secondDuration,
              pickup:
                existingFields[
                  `${baseSelector}PickupLocation#${indexSelector}`
                ],
              dropOff: selectedRoute?.dropOffLocation,
              driverToPut,
            })
          );

          //if the route below need to be moved because of a time conflict, we do that
          let nextRouteDepart =
            tmpRoutes[selectedRoute?.originalRouteIndex + 3].departAt;
          if (
            dayjsNY(nextRouteDepart).valueOf() <
            newDepart + secondDuration + 3600000
          ) {
            conflictCondition2 = true;
          }
          tmpRoutes[selectedRoute?.originalRouteIndex + 3].departAt = dayjsNY(
            newDepart + secondDuration + 3600000
          ).format();
        });
      }

      /**
       * If there were time conflicts, we want to move up in time
       * the routes to make sure there are no conflicts
       */
      let timeDifference = 0;
      if (conflictCondition2) {
        timeDifference =
          dayjsNY(tmpRoutes[originalRouteIndex + 2]?.departAt).valueOf() -
          dayjsNY(tmpRoutes[originalRouteIndex]?.departAt).valueOf();
      } else if (conflictCondition1) {
        timeDifference =
          dayjsNY(tmpRoutes[originalRouteIndex + 1]?.departAt).valueOf() -
          dayjsNY(tmpRoutes[originalRouteIndex]?.departAt).valueOf();
      }

      // let indexToConsider = conflictCondition2
      //   ? selectRouteIndex + 1
      //   : conflictCondition1
      //   ? selectRouteIndex
      //   : originalRouteIndex;
      let indexToConsider = originalRouteIndex + 1;

      let { updatedDispatches } = moveAllAffectedDispatches({
        allDispatches: tmp,
        timeDifference,
        originalTime: dayjsNY(
          allDispatches[dispatchIndex]?.routes[indexToConsider]?.departAt
        ).valueOf(),
        filteredDispatches,
        existingFields,
        driver: driverToPut,
        index: "",
        routeLength,
        routeSwitched,
        setAffectedDispatches,
        affectedDispatches,
        excludeDispatchToSwitch: [],
        rootDispatch: tmp[dispatchIndex],

        // excludeDispatchToSwitch: routeSwitched
        //   ?.reduce((acc, val) => [...acc, val?.dispatchId], [])
        //   .filter((el) => el !== selectedRoute?.dispatchId),
      });

      tmp = [...updatedDispatches];

      //we update the temporary dispatches clone
      tmp[dispatchIndex]["routes"] = tmpRoutes;
      setSwitchDriver((prev) => ({
        ...prev,
        selectedRoute: tmpRoutes[selectRouteIndex],
      }));

      setAllDispatches(tmp);
    }
  });
}

/**
 * Function that looks at every affected route from a driver switch
 */
function changeDispatches(
  updateHandler,
  dispatch,
  filteredDispatches,
  originalTime,
  timeDifference,
  defaultDriverArray,
  reduce
) {
  //#region CHANGE DISPATCHES
  let driverArray = null;
  if (!!defaultDriverArray) {
    driverArray = defaultDriverArray;
  } else {
    driverArray = new Set(
      dispatch?.routes?.reduce(
        (drivers, route) => [...drivers, route?.driverId],
        []
      )
    );
  }

  let data = [];

  for (const [driverId] of driverArray.entries()) {
    //we get all the routes for a driver after the driver switch
    //we don't want to consider the opened dispatch, so we filter the currently opened one
    let routesForDriver = [];
    routesForDriver = filteredDispatches?.filter(
      (route) =>
        route?.driverId === driverId &&
        route?.firstExit > originalTime &&
        route?.dispatchId !== dispatch?.dispatchId
    );

    //we get a list of ids for all the affected dispatches
    let affectedDispatches = new Set(
      routesForDriver?.reduce((acc, route) => [...acc, route?.dispatchId], [])
    );

    for (const [id] of affectedDispatches.entries()) {
      //we separate all the routes for the dispatch that are after the original time
      let tmpList = filteredDispatches?.filter(
        (el) => el?.dispatchId === id && el?.firstExit > originalTime
      );

      //set the new data for the routes
      let tmpActivities = tmpList?.map((el) => ({
        activityId: el?.activityId,
        departAt: reduce
          ? el?.firstExit - timeDifference
          : el?.firstExit + timeDifference,
        arriveBy: reduce
          ? el?.lastEntry - timeDifference
          : el?.lastEntry + timeDifference,
        timeExit: !!el?.timeExit
          ? reduce
            ? el?.timeExit - timeDifference
            : el?.timeExit + timeDifference
          : "",
        originalRouteIndex: el?.originalRouteIndex,
      }));

      if (!!tmpActivities?.length) {
        data.push({
          dispatchId: id,
          routes: tmpActivities,
        });
      }
    }

    updateHandler(data);
  }
}

/**
 * Function that changes the data of all the dispatches and activities
 * affected by a driver switch
 * @returns an object containing all the updated external dispatches
 */
export function moveAllAffectedDispatches({
  allDispatches: dList,
  timeDifference,
  originalTime,
  filteredDispatches,
  existingFields,
  driver,
  prevDriver,
  index,
  routeLength,
  setAffectedDispatches,
  excludeDispatchToSwitch,
  affectedDispatches,
  rootDispatch,
  routeSwitched,
  reduce = false,
}) {
  //#region MOVE AFFECTED DISPATCHES
  /**
   * Firstly we need to look at the time differences, if the
   * time difference is less that one hour (the standard layover)
   * then we don't need to change the dispatches
   */
  let allDispatches = structuredClone(dList);

  let dispatchList = new Set();
  let updateList = [];
  let driversForDispatch = new Set([driver, prevDriver].filter(Boolean));

  if (!isNaN(index)) {
    for (let i = 0; i <= routeLength; i++) {
      let c = i === routeLength;
      driversForDispatch.add(
        existingFields[`${c ? "Return" : ""}Driver#${c ? "" : i}`]
      );
    }
  }

  if (!!rootDispatch) {
    rootDispatch?.routes?.forEach((el) => {
      driversForDispatch.add(el?.driverId);
    });
  }

  let i = 0,
    iterations = 0;
  do {
    let firstRunCondition = i === 0 && iterations === 0;
    let list = Array.from(dispatchList);
    let dispatchToOpen = { id: "" };
    if (!firstRunCondition) {
      dispatchToOpen = allDispatches?.find(
        (dispatch) => dispatch?.dispatchId === list[i]
      );
    }
    /**
     * The "data" parameter will contain a list of affected dispatches
     * and the correspondent activities that need to be updated. If a
     * dispatch from the data is not part of the set, we need to add it
     * and update the "allDispatches" variable, in order to set the correct
     * values in the state
     */
    changeDispatches(
      (data) => {
        data?.forEach((el) => {
          if (
            !dispatchList.has(el?.dispatchId) &&
            !excludeDispatchToSwitch?.includes(el?.dispatchId)
          ) {
            let indexInGlobal = allDispatches?.findIndex(
              (e) => e?.dispatchId === el?.dispatchId
            );
            let tmpActivities = [];
            if (indexInGlobal > -1) {
              el?.routes?.forEach((route) => {
                let depart = dayjsNY(route?.departAt).format();
                let arrive = dayjsNY(route?.arriveBy).format();
                let exit = "";
                if (!!route?.timeExit) {
                  exit = dayjsNY(route?.timeExit).format();
                }
                allDispatches[indexInGlobal]["routes"][
                  route?.originalRouteIndex
                ]["departAt"] = depart;
                allDispatches[indexInGlobal]["routes"][
                  route?.originalRouteIndex
                ]["arriveBy"] = arrive;
                allDispatches[indexInGlobal]["routes"][
                  route?.originalRouteIndex
                ]["timeExit"] = exit;
                tmpActivities.push({
                  activityId: route?.activityId,
                  startingTime: depart,
                  timeAtLocation: arrive,
                  timeExitingTheLocation: exit,
                });
              });
              dispatchList.add(el?.dispatchId);
              updateList.push({
                dispatchId: el?.dispatchId,
                activities: tmpActivities,
              });
            }
          }
        });
      },
      dispatchToOpen,
      filteredDispatches,
      originalTime,
      timeDifference,
      firstRunCondition
        ? driversForDispatch
        : dispatchToOpen?.routes?.reduce(
            (acc, val) => [...acc, val?.driverId],
            []
          ),
      reduce
    );
    //we want to execute the function two times for i===0
    if (iterations === 0) {
      ++iterations;
    } else {
      ++i;
    }
  } while (i < dispatchList.size);

  let t = structuredClone(affectedDispatches);
  updateList?.forEach((el) => {
    let indexInState = t?.findIndex((e) => e?.dispatchId === el?.dispatchId);
    if (indexInState > -1) {
      t[indexInState] = el;
    } else {
      t.push(el);
    }
  });

  setAffectedDispatches((prev) => {
    let objToPut = t?.filter(
      (el) =>
        !excludeDispatchToSwitch.includes(el?.dispatchId) ||
        !routeSwitched?.find((e) => e?.dispatchId === el?.dispatchId)
    );
    let tmp = structuredClone(prev);
    objToPut?.forEach((el) => {
      let indexInPrev = tmp.findIndex((e) => e?.dispatchId === el?.dispatchId);
      if (indexInPrev > -1) {
        tmp[indexInPrev] = el;
      } else {
        tmp.push(el);
      }
    });

    return tmp;
  });

  return {
    updatedDispatches: allDispatches,
    affectedDispatches: t,
  };
}

export async function selectNewSwitchLocation({
  index,
  allDispatches,
  routeSwitched,
  cancelledRoutes,
  rowObject,
  filteredDispatches,
  location,
  form,
  setAllDispatches,
  setRouteSwitched,
}) {
  //#region SELECT NEW SWITCH LOCATION
  message.loading({
    content: "Updating Dispatch Locations",
    key: "updateDispatchLocations",
  });
  /**
   * When the user selects a new location to make a switch,
   * we need to access the unedited dispatch and see the original
   * location for the switch, we cannot rely on the form alone to check the prev location
   */
  let originalRouteIndex = rowObject?.routes?.findIndex(
    (el) => el?.activityId === form.getFieldValue(`cancelId#${index}`)
  );

  let firstRouteCancelled = index === 0 && !!cancelledRoutes[0].cancelled;

  if (firstRouteCancelled) {
    originalRouteIndex = 0;
  }

  if (originalRouteIndex > -1) {
    if (!firstRouteCancelled) {
      originalRouteIndex += 2;
    }

    let externalRoutesForDriver = filteredDispatches?.filter(
      (el) =>
        el?.driverId === form.getFieldValue(`Driver#${index}`) &&
        el?.firstExit >= dayjsNY(rowObject.routes[0].departAt).valueOf()
    );
    externalRoutesForDriver.sort((a, b) => a?.firstExit - b?.firstExit);

    if (firstRouteCancelled) {
      ++originalRouteIndex;
    }

    let actualSwitchRoute = externalRoutesForDriver?.find(
      (route) =>
        route?.pickupLocation ===
        rowObject?.routes[originalRouteIndex - 1].dropOffLocation
    );

    if (!!actualSwitchRoute) {
      if (location === actualSwitchRoute?.dropOffLocation) {
        message.error({
          content:
            "Selected location is the same as the drop off location of the switch route!",
          key: "updateDispatchLocations",
        });
        return Promise.reject();
      }
      //after having found the external route which holds the actual switch,
      //we need to change the locations in the actual object and populate the
      //routeSwitched field in a way that allows the updating of the times in a correct manner
      let switchedDispatch = allDispatches?.find(
        (el) => el?.dispatchId === actualSwitchRoute?.dispatchId
      );
      let dispatchIndex = allDispatches?.findIndex(
        (el) => el?.dispatchId === actualSwitchRoute?.dispatchId
      );
      switchedDispatch.routes[
        actualSwitchRoute?.originalRouteIndex - 1
      ].dropOffLocation = location;
      switchedDispatch.routes[
        actualSwitchRoute?.originalRouteIndex
      ].pickUpLocation = location;

      await getTimeDifference({
        origins: [form.getFieldValue(`PickupLocation#${index}`)],
        destinations: [location],
        departureTime: form.getFieldValue(`DepartAt#${index}`),
        arriveTime: null,
        trafficModel: "bestguess",
      })
        .then(async (res) => {
          let newArriveForRoute = dayjsNY(
            form.getFieldValue(`DepartAt#${index}`)
          )
            .add(res.duration, "ms")
            .valueOf();
          //we need to find the new route times for the external routes
          await Promise.allSettled([
            getTimeDifference({
              origins: [
                switchedDispatch.routes[
                  actualSwitchRoute?.originalRouteIndex - 1
                ].pickUpLocation,
              ],
              destinations: [location],
              departureTime: undefined,
              arriveTime: newArriveForRoute,
              trafficModel: "bestguess",
            }),
            getTimeDifference({
              origins: [location],
              destinations: [
                switchedDispatch.routes[actualSwitchRoute?.originalRouteIndex]
                  .dropOffLocation,
              ],
              departureTime: dayjsNY(newArriveForRoute).add(1, "h"),
              arriveTime: undefined,
              trafficModel: "bestguess",
            }),
          ])
            .then(([{ value: routeDepart }, { value: routeArrive }]) => {
              message.success({
                content: "Updated Successfully",
                key: "updateDispatchLocations",
              });
              switchedDispatch.routes[
                actualSwitchRoute?.originalRouteIndex - 1
              ].departAt = dayjsNY(
                newArriveForRoute - routeDepart.duration
              ).format();
              switchedDispatch.routes[
                actualSwitchRoute?.originalRouteIndex - 1
              ].arriveBy = dayjsNY(newArriveForRoute).format();

              switchedDispatch.routes[
                actualSwitchRoute?.originalRouteIndex
              ].departAt = dayjsNY(newArriveForRoute).add(1, "h").format();
              switchedDispatch.routes[
                actualSwitchRoute?.originalRouteIndex
              ].arriveBy = dayjsNY(
                dayjsNY(newArriveForRoute).add(1, "h").valueOf() +
                  routeArrive.duration
              ).format();

              let d = structuredClone(allDispatches);
              d[dispatchIndex] = switchedDispatch;
              setAllDispatches(d);

              let switched = structuredClone(routeSwitched);
              let t = switched.find(
                (el) => el?.dispatchId === switchedDispatch?.dispatchId
              );
              if (!!t) {
                let conflict = t?.routeChangeInfo?.findIndex(
                  (el) => el?.index === actualSwitchRoute?.originalRouteIndex
                );
                if (conflict > -1) {
                  t.routeChangeInfo[conflict]["depart"] = dayjsNY(
                    newArriveForRoute - routeDepart.duration
                  ).valueOf();
                } else {
                  t.routeChangeInfo = [
                    ...t.routeChangeInfo,
                    {
                      index: actualSwitchRoute?.originalRouteIndex - 1,
                      depart: dayjsNY(
                        newArriveForRoute - routeDepart.duration
                      ).valueOf(),
                    },
                  ];
                }
              } else {
                switched.splice(0, 0, {
                  dispatchId: switchedDispatch?.dispatchId,
                  routeChangeInfo: [
                    {
                      index: actualSwitchRoute?.originalRouteIndex - 1,
                      depart: dayjsNY(
                        newArriveForRoute - routeDepart.duration
                      ).valueOf(),
                    },
                  ],
                });
              }

              setRouteSwitched(switched);
            })
            .catch((err) => {
              console.log("Error getting locations: ", err);
            });
        })
        .catch((err) => {
          console.log("Error getting duration: ", err);
        });
    } else {
      message.error({
        content: "Switch route not found",
        key: "updateDispatchLocations",
      });
      return Promise.reject();
    }
  }
}
