import { message } from "antd";
import { API } from "aws-amplify";

import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  updateProgramFieldById,
  updateProgramFieldByName,
} from "../actions/programFields";

// this hook is reusable;
// When you use make sure to send the required props;
const useDispatchProgramFields = (
  fieldId = "",
  fieldName = "",
  byId = false,
  showMessageInfos = true
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const dispatchFields = async (updatedFieldOptions) => {
    setLoading(true);
    if (showMessageInfos) {
      message.loading("Saving...");
    }

    if (!fieldId || !fieldName) {
      message.error(`Please send field id and field name`);
      return Promise.reject("Field ID and Field Name are required");
    }

    try {
      await API.patch("programFields", `/programFields/${fieldId}`, {
        body: {
          fieldOptions: updatedFieldOptions,
        },
      });

      dispatch(
        !byId
          ? updateProgramFieldByName({
              fieldName: fieldName,
              fieldOptions: updatedFieldOptions,
            })
          : updateProgramFieldById({
              fieldId,
              fieldOptions: updatedFieldOptions,
            })
      );

      setLoading(false);
      message.destroy();
      if (showMessageInfos) {
        message.success("Saved successfully!");
      }
      return Promise.resolve(true);
    } catch (err) {
      setLoading(false);
      message.destroy();
      if (showMessageInfos) {
        message.error(`Error saving changes: ${err}`);
      }
      return Promise.reject(`Error saving changes: ${err}`);
    }
  };

  return { dispatchFields, loading };
};

export default useDispatchProgramFields;
