import { checkDateField, checkDateInput, requestCategory } from ".";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { parseInTz } from "../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { firstLevelKeys } from "./constants";

/**
 * Constructs the request body object for the request form.
 *
 * @param {Array} statuses - The array of statuses.
 * @param {number} currentStep - The current step.
 * @param {string} requestType - The request type.
 * @param {Object|null} fetchedData - The fetched data.
 * @param {Array} inputFields - The input fields.
 * @param {Array} formattedFiles - The formatted files.
 * @param {Object|null} selectedTeam - The selected team.
 * @param {Object|null} selectedMainField - The selected main field.
 * @param {Function} getFieldsValue - The function to get field values.
 * @param {Object|null} originalRequestForm - The original request form.
 * @returns {Object} The constructed request body object.
 */
const requestBody = ({
  statuses = [],
  currentStep = 0,
  requestType = "",
  fetchedData = null,
  inputFields = [],
  formattedFiles = [],
  selectedTeam = null,
  selectedMainField = null,
  // getFieldValue = () => {},
  getFieldsValue = () => {},
  originalRequestForm = null,
}) => {
  const fieldValues = getFieldsValue();

  const category = requestCategory(requestType, selectedMainField);

  /**
   * Here we match the first level keys with the field values and the selectedMainField
   * if we find a match we add teh value to the firstLevel keys
   * and delete it from the fieldValues and selectedMainField
   */
  const firstLevelData = firstLevelKeys.reduce((acc, key) => {
    if (key === "category") {
      let toSet = selectedMainField?.category
        ? selectedMainField.category
        : category;
      return Object.assign(acc, { category: toSet });
    }

    if (key === "recordId") {
      if (requestType === "Documentation") {
        return Object.assign(acc, { recordId: fieldValues?.record });
      }

      if (selectedMainField?.hasOwnProperty("recordId")) {
        let recordId = selectedMainField?.recordId;

        delete selectedMainField.recordId;

        return Object.assign(acc, { recordId });
      }
    }

    if (selectedMainField?.[key]) {
      let value = selectedMainField[key];

      delete selectedMainField[key];

      if (value !== undefined && !!value) {
        return Object.assign(acc, { [key]: value });
      }
    }

    if (!fieldValues[key]) {
      return acc;
    }
    if (fieldValues?.[key]) {
      let value = fieldValues[key];

      delete fieldValues[key];

      return Object.assign(acc, { [key]: value });
    }
  }, {});

  //If the selectedMainField has a recordName we add it to the fieldValues
  //and delete it from the selectedMainField
  if (selectedMainField?.hasOwnProperty("recordName")) {
    let recordName = selectedMainField.recordName;

    delete selectedMainField.recordName;

    Object.assign(fieldValues, { recordName });
  }

  if (requestType === "Project") {
    Object.assign(fieldValues, {
      recordName: fieldValues?.jobsiteAddress,
      accountName: fetchedData?.accounts?.find(
        (el) => el?.accountId === firstLevelData?.companyName
      )?.accountName,
    });
  } else if (requestType === "Dispatch") {
    const mainFieldId = {};
    if (Object.keys(selectedMainField || {}).length) {
      const key = Object.keys(selectedMainField)[0];
      mainFieldId["recordId"] = selectedMainField[key];
      mainFieldId[key] = selectedMainField[key];
    }

    Object.assign(fieldValues, {
      recordName: fieldValues?.jobsiteAddress,
      ...selectedMainField,
      ...mainFieldId,
    });
  }

  /**
   * Here we construct the requestObject
   */
  const requestObject = {
    ...originalRequestForm?.requestObject,
    status: statuses[currentStep]?.statusName || "Draft",
    ...fieldValues,
    gDriveUploads: formattedFiles,
    selectedMainField,
    teamsConfiguration: selectedTeam,
  };

  /**
   * Here we loop through the requestObject and delete any empty values
   * and format the date fields
   */
  Object.keys(requestObject)?.forEach((key) => {
    if (requestObject[key] === undefined) {
      delete requestObject[key];
    } else if (!requestObject[key]) {
      delete requestObject[key];
    } else if (
      dayjsNY(requestObject[key]).isValid() &&
      checkDateField(key, inputFields)
    ) {
      Object.assign(requestObject, {
        [key]: parseInTz(requestObject[key]).format(),
      });
    } else if (
      dayjsNY(requestObject[key]).isValid() &&
      checkDateInput(key, inputFields)
    ) {
      Object.assign(requestObject, {
        [key]: parseInTz(requestObject[key], "MM/DD/YYYY").format(),
      });
    }
  });

  /**
   * We add the necessary fields for the Change Order request needed to create the Change Order
   */
  if (requestType === "Change Order") {
    Object.assign(requestObject, {
      googleDriveFolderIds: selectedMainField?.googleDriveFolderIds || null,
      taxRate: selectedMainField?.taxRate || null,
      taxExempt: selectedMainField?.taxExempt || null,
      primaryContact: selectedMainField?.primaryContact || null,
      insuranceRequirement: selectedMainField?.insuranceRequirement || null,
      laborRequirement: selectedMainField?.laborRequirement || null,
      contacts: selectedMainField?.contacts || null,
      proposedStartDate: selectedMainField?.proposedStartDate || null,
      accountName: selectedMainField?.accountName || null,
      isChangeOrder: true,
    });

    delete selectedMainField.googleDriveFolderIds;
    delete selectedMainField.taxRate;
    delete selectedMainField.taxExempt;
    delete selectedMainField.primaryContact;
    delete selectedMainField.insuranceRequirement;
    delete selectedMainField.laborRequirement;
    delete selectedMainField.contacts;
    delete selectedMainField.proposedStartDate;

    Object.assign(requestObject, selectedMainField);
  }

  /**
   * If the requestType is Documentation we add the docObject to the requestObject
   */
  if (requestType === "Documentation") {
    const { apiData } = selectedMainField;

    const docObject = fetchedData?.[apiData?.table]?.find(
      (el) => el?.[apiData?.primaryKey] === fieldValues?.record
    )?.googleDriveFolderIds?.docObject;

    Object.assign(requestObject, {
      docObject,
    });
  }

  /**
   * If the selected main field has a services key we delete it
   */
  if (selectedMainField?.hasOwnProperty("services")) {
    delete selectedMainField.services;
  }

  const body = {
    ...firstLevelData,
    requestType,
    requestObject,
  };

  return body;
};

export default requestBody;
