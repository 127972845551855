import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { getChangedData } from "../../../components/SidebarPages/Accounting/components/utilities";
import broadcastNotification from "../../../helpers/controllers/broadcastNotification";

export const formSubmit = async ({
  form,
  userConfiguration,
  saveAddedLogs,
}) => {
  const data = await Auth.signUp({
    username: form.getFieldValue("username"),
    password: form.getFieldValue("password"),
    attributes: {
      phone_number:
        "+" +
        form
          .getFieldValue("phone_number")
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", ""),
      family_name: form.getFieldValue("lastName"),
      given_name: form.getFieldValue("firstName"),
      "custom:department": form.getFieldValue("department"),
      "custom:dep": form.getFieldValue("role"),
      "custom:internal": "Yes",
    },
  }).then((res) => {
    // result holds the changes that are made
    const result = getChangedData(form.getFieldsValue(), {});
    // delete the password and confirmPassword keys so it won't be shown in logs
    delete result.curr.password;
    delete result.curr.confirmPassword;
    broadcastNotification(
      "85",
      "onUserCreation",
      [
        {
          common: userConfiguration.nameOfUser,
          commonNext:
            [
              `${form.getFieldValue("firstName")} ${form.getFieldValue(
                "lastName"
              )}`,
            ] || "",
        },
        {
          userName: userConfiguration.nameOfUser,
          currentUser: userConfiguration.cognitoUserId,
        },
      ],
      null
    );
    // log Object that shows the new added User
    let newEditLog = {
      recordId: uuidv4(),
      recordName: `${form.getFieldValue("firstName")} ${form.getFieldValue(
        "lastName"
      )}`,
      category: "Users",
      topic: form.getFieldValue("department"),
      currentData: {
        [`${form.getFieldValue("firstName")} ${form.getFieldValue(
          "lastName"
        )}`]: result.curr,
      },
      previousData: {
        [`${form.getFieldValue("firstName")} ${form.getFieldValue(
          "lastName"
        )}`]: result.prev,
      },
    };
    // Post the changes in editLogs
    saveAddedLogs(newEditLog);

    return res;
  });
  return data;
};
