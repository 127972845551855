import { MondayButton } from "src/components/commonComponents";
import { GPlacesAutocompleteInput } from "src/components/SidebarPages/Fleet/components";
import { EditIcon } from "src/icons";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { AddIcon } from "src/components/Header/components/GoogleEvents/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

function StopController({
  map,
  mode,
  stopData,
  toggleView,
  isDarkMode,
  toggleMode,
  newFenceForm,
  sortedFences,
  projectToLink,
  selectedFence,
  sortedProjects,
  onFenceConfirm,
  canSavePolygon,
  onLocationSelect,
  setSelectedFence,
  linkProjectHandler,
}) {
  return (
    <div className="stop-map-controller">
      <div className="controller-left">
        <GPlacesAutocompleteInput
          {...{
            label: "",
            required: false,
            noFormItem: true,
            isCustomValue: true,
            onSelect: onLocationSelect,
            className: "locations-input",
            placeholder: "Search location...",
            popupClassName: isDarkMode ? "darkDropDown" : undefined,
            onClear() {
              if (selectedFence?.type !== "LOCATION") {
                return;
              }

              setSelectedFence(null);
              if (map) {
                map.panTo(stopData?.position);
                map.setZoom(19);
              }
            },
            groupedOptions: [
              {
                label: "Projects",
                options: sortedProjects.map((project) => {
                  return {
                    label: project.address,
                    value: project.address,
                    key: project.id,
                    ...project,
                  };
                }),
              },
              {
                label: "Geofences",
                options: sortedFences.map((fence) => {
                  return {
                    label: fence.address,
                    value: fence.address,
                    key: fence.id,
                    ...fence,
                  };
                }),
              },
            ],
          }}
        />
      </div>
      {!newFenceForm && (
        <div className="controller-right">
          {projectToLink && (
            <MondayButton
              {...{
                Icon: <XIcon />,
                className: "mondayButtonRed",
                onClick() {
                  toggleView();
                },
              }}
            >
              Cancel Link
            </MondayButton>
          )}
          {projectToLink && selectedFence && (
            <MondayButton
              {...{
                Icon: <TickIcon />,
                onClick: linkProjectHandler,
              }}
            >
              Confirm Fence Link
            </MondayButton>
          )}
          {selectedFence?.type === "PROJECT" &&
            !Boolean(selectedFence?.points?.length) &&
            !canSavePolygon &&
            !projectToLink &&
            mode !== "DRAW" && (
              <MondayButton
                {...{
                  Icon: <EditIcon />,
                  className: "mondayButtonBlue",
                  onClick() {
                    toggleView(true);
                  },
                }}
              >
                Link project
              </MondayButton>
            )}
          {(mode === "VIEW" || canSavePolygon) &&
            (selectedFence?.points?.length ? Boolean(selectedFence) : true) &&
            !projectToLink && (
              <MondayButton
                {...{
                  Icon: canSavePolygon ? (
                    <XIcon />
                  ) : selectedFence?.points?.length ? (
                    <EditIcon />
                  ) : (
                    <AddIcon />
                  ),
                  onClick: toggleMode,
                  className: canSavePolygon
                    ? "mondayButtonRed"
                    : selectedFence?.points?.length
                    ? "mondayButtonBlue"
                    : undefined,
                }}
              >
                {canSavePolygon
                  ? "Cancel geofence"
                  : selectedFence?.points?.length
                  ? "Edit Geofence"
                  : "Create geofence"}
              </MondayButton>
            )}
          {canSavePolygon && (
            <MondayButton
              {...{
                Icon: <TickIcon />,
                onClick() {
                  onFenceConfirm(false);
                },
              }}
            >
              Confirm geofence
            </MondayButton>
          )}
        </div>
      )}
    </div>
  );
}

export default StopController;
