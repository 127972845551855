import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../SidebarPages/utils";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";

export const overtimeColumns = [
  {
    headerName: "Full Name",
    field: "employeeFullName",
  },
  {
    headerName: "Employee ID",
    field: "employeeId",
  },
  {
    headerName: "Clock In",
    field: "firstClockIn",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      if (value) {
        return dayjsNY(value).format(`${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`);
      }
      return "";
    },
  },
  {
    headerName: "Clock Out",
    field: "clockOut",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      if (value) {
        return dayjsNY(value).format(`${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`);
      }
      return "";
    },
  },
  {
    headerName: "Payroll Type",
    field: "payrollType",
  },
  {
    headerName: "Overtime Hours",
    field: "overtimeHours",
    cellRenderer({ value }) {
      return formatNumber(value, { unit: "hour" });
    },
    pdfExportOptions: {
      modifyCellValue: (data) => {
        return formatNumber(data, { unit: "hour" });
      },
    },
  },
  {
    headerName: "Total Reg. Amount",
    field: "regAmount",
    cellRenderer({ value }) {
      return formatCurrency(value || 0);
    },
  },
  {
    headerName: "Total OT. Amount",
    field: "otAmount",
    cellRenderer({ data }) {
      return formatCurrency(data?.otAmount || 0);
    },
  },
];

export const otFilterData = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Full Name",
    type: "Dropdown",
    key: "employeeFullName",
  },
  {
    globalKeyId: "e761b130-6c49-4341-8dcd-272facb67951",
    label: "Employee ID",
    type: "Dropdown",
    key: "employeeId",
  },
  {
    globalKeyId: "7a4780f9-f9b9-46e9-b77c-19ccf0c334cc",
    label: "Clock In",
    type: "DateRange",
    key: "firstClockIn",
  },
  {
    globalKeyId: "9b167405-9c97-40f4-b8f7-3de57b4d53c4",
    label: "Clock Out",
    type: "DateRange",
    key: "clockOut",
  },
  {
    globalKeyId: "5bb29443-803f-47d8-98a8-731a3a4a42e2",
    label: "Payroll Type",
    type: "Dropdown",
    key: "payrollType",
  },
];
