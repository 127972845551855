import React from "react";
import { DeleteIcon, PlusIcon } from "../../../../assets";
import { TemplateIcon } from "../../../../icons";
import "./ProposalSidebar.scss";
import { MondayButton } from "../../../commonComponents";
import {
  CaretRightFilled,
  ContainerFilled,
  RightCircleFilled,
  RightOutlined,
  LayoutFilled,
} from "@ant-design/icons";
import { UserAccessIcon } from "../../../../icons/Settings";
import { UserIcon } from "../../../SidebarPages/Communication/assets";
import GeneralTemplatesSidebar from "./components/GeneralTemplates/GeneralTemplatesSidebar";
// import { proposalSidebarData } from "./ProposalSidebar";
const Sidebar = ({ onSwitchView, proposalSidebarData = [] }) => {
  return (
    <div className="proposal_sidebar">
      {/* <div className="sidebar-header">Sidebar Header</div> */}
      <div className="sidebar-content">
        {proposalSidebarData.map((view, index) => (
          <MondayButton
            className="mondayButtonBlue"
            key={index + view.key}
            hoverText={view.hoverText}
            onClick={() => onSwitchView(view.key)}
            icon={view.icon}
            noTooltip={false}
            tooltipKey={view.key}
          />
        ))}{" "}
        {/* <MondayButton
          className="mondayButtonGreen"
          key={3545477}
          icon={<RightCircleFilled />}
        /> */}
      </div>
    </div>
  );
};

export default Sidebar;
