import { Modal } from "antd";
import { antdModalProps } from "../../../../../../../../../utils";
import HoistPriceItemModalContent from "./HoistPriceItemModalContent";
import "./HoistPriceItemModal.scss";

const HoistPriceItemModal = ({
  visible,
  setVisible,
  editingItem,
  isDarkMode,
}) => {
  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        title: "Price Item",
        onCancel,
        className: `hoistPriceItemModal ${
          isDarkMode && "hoistPriceItemModalDark"
        }`,
      }}
    >
      <HoistPriceItemModalContent
        onCancel={onCancel}
        editingItem={editingItem}
        isDarkMode={isDarkMode}
      />
    </Modal>
  );
};

export default HoistPriceItemModal;
