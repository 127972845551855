import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import dayjs from "dayjs";
import { API } from "aws-amplify";

import useLogsApi from "src/hooks/useLogsApi";

import { getQuestions } from "./utils";

import { useProgressComponent } from "../../../../../../../hooks";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import { MondayButton } from "../../../../../../commonComponents";
import {
  fetchByList,
  filterTables,
  getCognitosForNotification,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../../../../utils";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";
import { VideoTutorialIcon } from "../../../../../../../icons";
import { compareIncluding } from "src/components/SidebarPages/utils";
import { getSelectedBaseRecords } from "src/utils/searchFetchForInput";

export default function useNewInspectionModal({
  form,
  currentProject,
  setCurrentProject,
  otherProps,
  requestId = "",
  afterSaveRequest = async () => {},
}) {
  const [
    { authenticatedUser },
    { userConfiguration },
    { accessToken },
    { programFields },
  ] = useSelector((state) => [
    state.authenticatedUser,
    state.userConfig,
    state.accessToken,
    state.programFields,
  ]);

  const { getFieldValue, setFieldsValue, resetFields, validateFields } = form;

  const {
    propedRowData,
    projects: proppedProjects = [],
    estimations = [],
    inspectors,
    setDescription,
    setDescriptionModalVisible,
    setProjects: setProppedProjects = () => {},
    currentPageKey: proppedCurrentPageKey = {},
    setCurrentPageKey: setProppedCurrentPageKey = () => {},
    schedules,
  } = otherProps;

  const [next, setNext] = useState(false);
  const [alert, setAlert] = useState(false);
  const [projects, setProjects] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [serviceValues, setServiceValues] = useState([]);
  const [projectExecutives, setProjectExecutives] = useState();
  const [currentPageKey, setCurrentPageKey] = useState("");
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const [displayProgress, setDisplayProgress] = useState(true);
  const [toBeScheduled, setToBeScheduled] = useState([]);
  const [selectedInspectionType, setSelectedInspectionType] =
    useState(undefined);

  const driveRequest = driveApi({ accessToken });
  const { createLogs } = useLogsApi();
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Inspections",
    actionType: "Create",
  });

  const selectedProjectName = form.getFieldsValue(
    "newInspectionProjectName"
  ).newInspectionProjectName;

  const projectSchedules = schedules?.filter(
    (schedule) =>
      schedule?.projectId === currentProject?.projectId &&
      (schedule?.typeOfWork === "Installation" ||
        schedule?.typeOfWork === "Removal")
  );

  const filteredProjectSchedules = selectedInspectionType
    ? selectedInspectionType === "Post-Removal Inspection" ||
      selectedInspectionType === "Pre-Removal Inspection"
      ? projectSchedules?.filter(
          (schedule) => schedule?.typeOfWork === "Removal"
        )
      : projectSchedules?.filter(
          (schedule) => schedule?.typeOfWork === "Installation"
        )
    : [];

  const closestSchedule = filteredProjectSchedules.sort((a, b) => {
    // Sort by totalScheduleProgress in descending order
    if (b?.totalScheduleProgress !== a?.totalScheduleProgress) {
      return b?.totalScheduleProgress - a?.totalScheduleProgress;
    }

    const today = new Date();

    // Sort by the closest startDate to today in ascending order
    const aClosestDate = new Date(
      a?.scheduleDays.sort(
        (day1, day2) => new Date(day1?.startDate) - new Date(day2?.startDate)
      )[0]?.startDate
    );
    const bClosestDate = new Date(
      b?.scheduleDays.sort(
        (day1, day2) => new Date(day1?.startDate) - new Date(day2?.startDate)
      )[0]?.startDate
    );

    return Math.abs(aClosestDate - today) - Math.abs(bClosestDate - today);
  })[0]; // The first item after sorting is the desired schedule

  const filteredToBeScheduled = closestSchedule
    ? Object.keys(currentProject?.services ?? {})
        ?.map((estimationKey) => {
          const filtered = toBeScheduled?.filter(
            (service) =>
              service?.scheduleId === closestSchedule?.scheduleId &&
              service?.estimationId === estimationKey
          );
          return filtered;
        })
        ?.flat()
    : [];

  const allInspectionTypes = programFields
    .find(({ fieldName }) => fieldName === "Inspection Types")
    ?.fieldOptions?.filter(({ status }) => status)
    .map((item) => item.statusName);

  const videoTutorialLink = programFields
    ?.find(({ fieldName }) => fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find(
      ({ categoryName }) => categoryName === "Project Management"
    )
    ?.subCategories[6].videos.find((item, i) => i === 1)?.videoLink;

  const completedSchedulesServices = filteredToBeScheduled?.map(
    (toBeScheduled) => {
      if (
        estimations.some(
          (est) =>
            est?.estimationId === toBeScheduled?.estimationId &&
            !!est.isChangeOrder
        )
      ) {
        toBeScheduled.isChangeOrder = true;
      } else {
        toBeScheduled.isChangeOrder = false;
      }
      return toBeScheduled;
    }
  );

  const serviceOptions = completedSchedulesServices
    ?.map((option) => ({
      [option?.estimationId]: {
        estimationId: option?.estimationId,
        estimationNumber: option?.estimationNumber,
        services: completedSchedulesServices?.filter(
          (service) => service?.estimationId === option?.estimationId
        ),
      },
    }))
    ?.reduce((acc, key) => ({ ...acc, ...key }), {});

  const onSelectTeam = (_, data) => {
    setSelectedTeam([
      ...selectedTeam,
      {
        value: data?.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onDeselectTeam = (_, data) => {
    setSelectedTeam(selectedTeam?.filter((el) => el.value !== data.value));
    if (form.getFieldValue("newInspectionTeams").length < 1) {
      setSelectedTeam([]);
    }
  };

  const openMemberModal = () => {
    setOpen(true);
  };

  const ClearOptions = () => {
    setOpen(false);
  };

  const pliArray = completedSchedulesServices
    ?.flatMap(
      ({
        estimationId,
        estimationNumber,
        label,
        serviceOptions,
        isChangeOrder,
      }) =>
        serviceOptions[0]?.flatMap(({ elevationLabel, items }) =>
          items?.flatMap((item) => {
            const serviceLabel = !!isChangeOrder
              ? `${label} (Change Order)`
              : `${label}`;

            return !!!item?.subPLIs
              ? {
                  estimationId,
                  estimationNumber,
                  pli: { ...item },
                  fieldName: `${serviceLabel}_${elevationLabel}_${item?.id}`,
                }
              : item.subPLIs.flatMap((subItem, i) => ({
                  estimationId,
                  estimationNumber,
                  pli: { ...subItem },
                  fieldName: `${serviceLabel}_${elevationLabel}_${item?.id}/${
                    i + 1
                  }`,
                }));
          })
        )
    )
    .filter((item) => !!item);

  const servicesList = completedSchedulesServices?.flatMap(
    ({ label, estimationId, estimationNumber, isChangeOrder }) => {
      return {
        estimationId,
        estimationNumber,
        name: !!isChangeOrder ? `${label} (Change Order)` : `${label}`,
        value: false,
      };
    }
  );
  const elevationList = completedSchedulesServices?.flatMap(
    ({
      estimationId,
      estimationNumber,
      label,
      serviceOptions,
      isChangeOrder,
    }) =>
      serviceOptions[0]?.flatMap(({ elevationLabel }) => {
        const serviceLabel = !!isChangeOrder
          ? `${label} (Change Order)`
          : `${label}`;

        return {
          estimationId,
          estimationNumber,
          name: `${serviceLabel}_${elevationLabel}`,
          value: false,
        };
      })
  );
  const pliList = pliArray.map((item) => {
    return {
      estimationId: item?.estimationId,
      name: `${item.fieldName}`,
      value: false,
    };
  });

  const totalValues = [...servicesList, ...elevationList, ...pliList];

  const onCreateInspection = (servicesToBeInspected = []) => {
    const serviceTypes = servicesToBeInspected
      .filter((obj) => obj.isInspectedFull !== "none")
      .map((obj) => ({
        serviceType: obj.serviceType,
        estimationId: obj.estimationId,
        estimationNumber: obj.estimationNumber,
        elevationsInspected: obj.elevationsInspected,
      }));
    message.loading("Saving...");
    filterTables(
      "userConfiguration",
      "cognitoUserId",
      getFieldValue("newInspectionInspectedBy")
    ).then((insp) => {
      const inspectorsName = insp[0]?.nameOfUser;
      getQuestions(serviceTypes, getFieldValue)
        .then(async (res) => {
          const teamsConfiguration = updateTeamsConfiguration(
            userConfiguration,
            selectedTeam
          );
          setVisibleCreationProgress(true);
          const inspType = getFieldValue("newInspectionInspType");
          const parentId = await driveRequest.getFolderIdOrCreate({
            name: `${inspType} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
            parents: [currentProject?.googleDriveFolderIds?.inspectionsObject],
          });

          updateProgressStatus({ updatingRecord: "executing" });
          API.post("inspections", `/inspections`, {
            body: {
              projectId: currentProject.projectId,
              projectName: currentProject.projectName,
              inspectedBy: {
                nameOfUser: inspectorsName,
                identityId: insp[0]?.identityId,
              },
              inspectionType: inspType,
              inspectionDate: parseInt(
                dayjs(getFieldValue("newInspectionInspDate")).format("x")
              ),
              servicesInspected: servicesToBeInspected,
              projectExecutive: currentProject?.projectExecutive,
              projectManager: form.getFieldValue("newInspectionProjectManager"),
              inspectionFolderId: parentId || null,
              inspectionReport: res?.map((item) => ({
                estimationId: item?.estimationId,
                estimationNumber: item?.estimationNumber,
                serviceType: item.type,
                questions: item?.serviceQuestions?.flatMap(
                  ({ categoryName, questions = [] }) =>
                    questions?.map((question) => ({
                      ...question,
                      categoryName,
                      fileIds: [],
                    }))
                ),
                elevationsInspected: item?.elevationsInspected,
              })),
              teamsConfiguration,
              requestId,
              accountId: currentProject?.accountId,
              accountName: currentProject?.accountName,
            },
          })
            .then(async (res) => {
              //set progress of creating record as 100%
              updateProgressStatus({
                updatingRecord: "finished",
                sendingNotification: "executing",
              });
              setVisibleCreationProgress(res);

              showSuccessMsg();

              broadcastNotification(
                "16",
                "onCreation",
                [
                  {
                    common: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
                  },
                  {
                    userName: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
                    currentUser: authenticatedUser?.sub,
                    cognitos: getCognitosForNotification(
                      userConfiguration,
                      teamsConfiguration
                    ),
                  },
                ],
                res?.inspectionId
              ).then((notificationSent) => {
                updateProgressStatus({
                  sendingNotification: !!notificationSent
                    ? "finished"
                    : "hasError",
                });
              });

              if (!!requestId) {
                afterSaveRequest({
                  newRecordId: res?.inspectionId,
                  requestId,
                  path: "inspectionsView",
                  cognitoUserId: userConfiguration?.cognitoUserId,
                });
              }

              const createLog = {
                recordId: res?.inspectionId ?? "",
                recordName: res?.inspectionType ?? "",
                category: "Inspection Logs",
                actionType: "Create",
                topic: "",
                currentData: {},
                label: "",
                member: userConfiguration?.nameOfUser,
                nameOfUser: userConfiguration.nameOfUser,
                cognitoUserId: userConfiguration?.cognitoUserId,
                updatedKeys: [],
                updatedAt: dayjs().valueOf(),
                previousData: {},
              };

              try {
                await createLogs(createLog);
              } catch (err) {
                message.error("Failed to create new log");
              }
            })
            .catch((error) => {
              console.log({ error });
              updateProgressStatus({ updatingRecord: "hasError" });
            });
        })
        .catch((error) => console.log(error));
    });
  };

  const projectUniqueServices = projects
    .filter((project) => project.projectId === currentProject?.projectId)
    .flatMap((project) => {
      const { services = {} } = project || {};
      const objectsFromArrays = Object.values(services).flat();
      return [...new Set(objectsFromArrays.map((item) => item.label))];
    });

  const inspectionTypes =
    projectUniqueServices.length > 0
      ? allInspectionTypes
      : allInspectionTypes.filter(
          // (type) => type === "Pre-Installation Inspection"
          (type) => type === "Pre-Installation Inspection 2"
        );

  const questions =
    projectUniqueServices.length > 0 &&
    getQuestions(projectUniqueServices, getFieldValue);

  if (questions) {
    questions.then((items) => {
      items.forEach((item) => {
        completedSchedulesServices.forEach((service) => {
          if (service.label === item.type) {
            if (item.serviceQuestions.length === 0) {
              service.disabled = true;
            } else {
              service.disabled = false;
            }
          }
        });
      });
    });
  }

  const tourSteps = [
    {
      title: "Inspection Tutorial",
      description: (
        <div>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => {
                setShowVideoTutorial(true);
                setTourOpen(false);
              },
              style: { display: "flex", justifyContent: " center" },
              Icon: <VideoTutorialIcon />,
            }}
          >
            Watch Video
          </MondayButton>
        </div>
      ),
      className: "custom-tour-light",
      placement: "center",
    },
  ];

  const projectManagers = programFields
    ?.find((prog) => prog.fieldName === "Project Managers")
    ?.fieldOptions?.map(({ nameOfUser }) => nameOfUser);

  const updatedInspectors = [
    ...inspectors,
    ...(currentProject?.projectManager?.map((manager) =>
      typeof manager === "string"
        ? {
            name: manager,
            cognitoUserId: userConfiguration?.allUsers?.Items?.find(
              ({ nameOfUser }) => nameOfUser === manager
            ).cognitoUserId,
          }
        : {
            name: manager?.nameOfUser,
            cognitoUserId: manager?.cognitoUserId,
          }
    ) || []),
  ];

  useEffect(() => {
    Promise.allSettled([
      API.get("teams", "/teams"),
      API.get("projectExecutives", "/projectExecutives"),
    ])
      .then(([{ value: teams }, { value: projectExecutivesRes }]) => {
        setTeams(
          teams?.map((team) => ({
            label: team.teamName,
            value: team.teamName,
            members: team.members?.map(
              ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
                identityId,
                nameOfUser,
                cognitoUserId,
              })
            ),
          }))
        );
        setProjectExecutives(projectExecutivesRes);
      })
      .catch((err) => console.log(err));

    if (!!propedRowData) {
      Object.keys(propedRowData).forEach((key) => {
        if (key === "projectName") {
          form.setFieldValue("newInspectionProjectName", propedRowData[key]);
          const current = [...proppedProjects]?.find(({ projectId }) =>
            compareIncluding(projectId, propedRowData?.projects)
          );
          setCurrentProject(current);
          setFieldsValue({
            newInspectionProjectExecutive: current?.projectExecutive,
          });
          const teams = current?.teamsConfiguration?.filter(
            (e) => "value" in e && e?.value !== ""
          );

          if (!!teams?.length) {
            setSelectedTeam(teams);
            form.setFieldValue("newInspectionTeams", teams);
          }
        } else if (key === "inspectionType") {
          form.setFieldValue("newInspectionInspType", propedRowData[key]);
        } else if (key === "projectExecutive") {
          form.setFieldValue(
            "newInspectionProjectExecutive",
            propedRowData[key]
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!!proppedProjects?.length && !!selectedProjectName) {
      setProjects(proppedProjects);
    } else {
      getSelectedBaseRecords({
        idKey: "projectId",
        table: "projects",
        setRecords: setProjects,
        userConfiguration,
        setCurrentPageKey,
      });
    }
  }, [proppedProjects]);

  useEffect(() => {
    if (currentProject) {
      const projectSchedules = schedules?.filter(
        ({ projectId }) => currentProject?.projectId === projectId
      );

      const scheduleIds = projectSchedules?.map(({ scheduleId }) => scheduleId);

      const retrieveToBeScheduled = async () => {
        message.loading("Retrieving schedules...", 0);

        const response = await fetchByList(
          "toBeScheduled",
          "scheduleId",
          scheduleIds
        );

        setToBeScheduled(
          () =>
            response?.filter(
              (service) =>
                service?.label === "Sidewalk Shed" ||
                service?.label === "Scaffold"
            ) ?? []
        );

        message.destroy();
        message.success("Schedules retrieved successfully");
      };

      retrieveToBeScheduled();
    }
  }, [currentProject, schedules]);

  return {
    next,
    setNext,
    alert,
    setAlert,
    projects,
    setProjects,
    formChanged,
    setFormChanged,
    open,
    setOpen,
    teams,
    selectedTeam,
    setSelectedTeam,
    serviceValues,
    setServiceValues,
    currentPageKey,
    setCurrentPageKey,
    loadingProjects,
    setLoadingProjects,
    tourOpen,
    setTourOpen,
    showVideoTutorial,
    setShowVideoTutorial,
    userConfiguration,
    setFieldsValue,
    resetFields,
    validateFields,
    propedRowData,
    proppedProjects,
    setDescription,
    setDescriptionModalVisible,
    setProppedProjects,
    proppedCurrentPageKey,
    visibleCreationProgress,
    creationProgresses,
    completedSchedulesServices,
    serviceOptions,
    onSelectTeam,
    onDeselectTeam,
    openMemberModal,
    ClearOptions,
    pliArray,
    totalValues,
    onCreateInspection,
    inspectionTypes,
    tourSteps,
    projectManagers,
    updatedInspectors,
    videoTutorialLink,
    displayProgress,
    setDisplayProgress,
    filteredToBeScheduled,
    schedules,
    setSelectedInspectionType,
  };
}
