import { useState, useEffect } from "react";
import { Input, Popover, Checkbox } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import {
  GPlacesAutocompleteInput,
  SimplePlacesInput,
} from "../../../Fleet/components/index";
import {
  getAddressComponent,
  splitKeys,
  getCoordinates,
} from "../../../Fleet/utils/index";
import _ from "lodash";
import StyledButton from "../../components/StyleButton/StyledButton";
import { validateForm } from "../../../Fleet/utils/validateForm";
import { MondayButton } from "../../../../commonComponents/index";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import { ReactComponent as CollapseArrow } from "../../../../../icons/CollapseArrow.svg";
import { AddressClass } from "../../../../../utils/AddressClass";

import "./Address.scss";
import { XIcon } from "../../../Communication/assets";
import { HiddenIcon } from "../../src";
import { Plus } from "../../components/FilterView/FilterIcons";
import { useSelector } from "react-redux";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import { getLatLngByAddress } from "../../../utils";

const initialValue = [
  {
    address: "",
    coordinates: { lat: "", lng: "" },
    addressFormItemName: "",
  },
];

const emptyAddress = {
  address: "",
  street: "",
  city: "",
  state: "",
  zipCode: "",
};

const Address = (props) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    params,
    form,
    id,
    getEditedValue, //function from the dynamic view to save the data
    setEditMode, //regulates the visibility of the modal
    setFormChanged = () => {}, //sets condition to the warning modal
    getFormChanged = () => {}, //returns the warning modal condition
  } = props;

  //==============USED IN SINGLE ADDRESSES===============//
  const {
    oppositeAddressInfo = {
      coordinates: [],
    },
  } = params;
  //holds the saved coordinates in single addresses
  const [coordinates] = useState(
    props?.params?.coordinates?.length === 0
      ? initialValue
      : props?.params?.coordinates[0]
  );

  //holds the opposite address information
  const [oppositeCoordinates] = useState(
    oppositeAddressInfo?.coordinates?.length === 0
      ? initialValue
      : oppositeAddressInfo?.coordinates[0]
  );
  const [oppositeApt] = useState(oppositeAddressInfo?.aptNumber || "");

  const [shippingSameAsBilling, setShippingSameAsBilling] = useState(
    props?.params?.coordinates[0]?.address ===
      oppositeAddressInfo?.coordinates[0]?.address
  );
  //we make a clone of the initial state of the checkbox so the apt number won't get
  //overwritten on first EditCard call
  const [checkChanged, setCheckChanged] = useState(false);
  const [apartment, setApartment] = useState(params?.aptNumber || "");
  //prevents the call from being sent on apartment change
  //on autocomplete select we want to override the apt number
  const [preventCall, setPreventCall] = useState(false);
  //===================================================//

  //will hold the individual addresses and their components
  const [addressArray, setAddressArray] = useState();
  const [activeDetails, setActiveDetails] = useState(0);
  const [cancelModalVisible, setCancelModalVisible] = useState();
  const [prev, setPrev] = useState();

  //clones the default props to be used in the validation
  useEffect(() => {
    setPrev(() => _.cloneDeep(props));
  }, [props]);

  //function that splits addressArray addresses into smaller components
  async function addressSplitter() {
    let tmp = !!addressArray ? addressArray : props?.params?.coordinates;
    if (tmp?.length === 0) {
      setAddressArray([emptyAddress]);
      return;
    }

    let promises = tmp?.map(async (el, index) => {
      form.setFieldValue(`auto${index}`, el?.address);
      return await getAddressComponent(el?.address);
    });

    setPreventCall(false);

    let shippingCondition = id === "shippingAddress" || id === "secondAddress";

    return Promise.all(promises).then((res) => {
      //this part handles the default apt numbers
      //we make conditions related to billing, shipping, and checkbox handling
      if (getFormChanged()) {
        //when it's not a first call we want to override the apt number
        if (
          (shippingCondition && !shippingSameAsBilling) ||
          !shippingCondition
        ) {
          setApartment(res[0]["aptNumber"]);
        }
      } else {
        //on checkbox change we want to prevent the apt writing
        if (shippingCondition && !shippingSameAsBilling && checkChanged) {
          setApartment(res[0]["aptNumber"]);
        }
      }
      setAddressArray(res);
    });
  }

  //splits the addresses into components
  useEffect(() => {
    if (!preventCall) {
      addressSplitter();
    }
  }, [JSON.stringify(addressArray), preventCall]);

  //adds an address to the array
  function addAddress() {
    //no need to call when adding an empty element to the end
    setPreventCall(true);
    let tmp = [...addressArray, emptyAddress];
    form.setFieldValue(`auto${tmp.length - 1}`, "");
    setAddressArray(tmp);
  }

  //removes an element from the address array
  function removeAddress(index) {
    if (addressArray?.length !== 1) {
      let tmp = [...addressArray];
      tmp.splice(index, 1);
      setAddressArray(tmp);
      setPreventCall(false);
    }
  }

  //updates addressArray elements
  function updateAddress(value, index) {
    let tmp = [...addressArray];
    tmp[index]["address"] = value;
    setAddressArray(tmp);
  }

  //handles the change of apt number
  function aptChangeHandler(value) {
    setApartment(value);
    setPreventCall(true);
    //in case the address input is empty we simply set the address state
    //useful when dealing with incorrect addresses that cannot be split correctly
    try {
      if (!!addressArray[0]["street"]) {
        //in this instance we will only have one address so we don't need an index
        let tmpAddressClass = new AddressClass();
        tmpAddressClass.setAddress(form.getFieldValue("auto0"));
        tmpAddressClass.setStreet(addressArray[0]?.street);
        //we get the formatted address with the apt number
        let newValue = tmpAddressClass.addressFormatter(value);
        form.setFieldValue("auto0", newValue);
        updateAddress(newValue, 0);
      }
    } catch {}
  }

  //clears the address fields on clear
  function clearAddress(index) {
    setPreventCall(true);
    setApartment("");
    form.setFieldValue(`auto${index}`, "");
    let tmp = [...addressArray];
    tmp[index] = emptyAddress;
    setAddressArray(tmp);
  }

  //prevents the address input from resetting
  function blurAddress(index) {
    if (!!form.getFieldValue(`auto${index}`) && !addressArray[index]?.address) {
      setPreventCall(true);
      updateAddress(form.getFieldValue(`auto${index}`), index);
    } else {
      let tmpValue = addressArray[index]?.address;
      form.setFieldValue(`auto${index}`, tmpValue);
    }
  }

  //checkbox handler
  function checkChangeHandler(check) {
    setPreventCall(false);
    //in case we are dealing with shipping, we want to change and disable the inputs
    //otherwise we make dhe difference during the form validation
    if (id === "shippingAddress" || id === "secondAddress") {
      if (check) {
        setApartment(oppositeApt);
        updateAddress(oppositeCoordinates?.address, 0);
      } else {
        clearAddress(0);
      }
    }
  }

  //opens the selected address in a new window
  async function newWindowMap() {
    //looks at the active field and opens it
    let tmpAddress = addressArray[activeDetails]["address"];
    //if the address includes the non-standard # it will not send a request
    if (tmpAddress.includes("#")) {
      tmpAddress = tmpAddress.replace("#", "apt ");
    }
    await getLatLngByAddress(tmpAddress).then((res) => {
      let { lat = -1, lng = -1 } = res?.coordinates;
      if (lat !== -1) {
        window.open(`https://www.google.com/maps/@${lat},${lng},19z`, "_blank");
      }
    });
  }

  function validateFields(onSuccessFunc, onError) {
    validateForm(form, onSuccessFunc, onError);
  }

  //returns a coordinates array for the validation
  async function coordinatesPromises() {
    let promises = addressArray
      ?.filter(({ address }) => !!address)
      ?.map(async ({ address }) => {
        return await getCoordinates(address);
      });
    return Promise.all(promises).then((res) => {
      return res;
    });
  }

  async function saveHandler() {
    validateFields(
      //success
      async () => {
        //if the address is empty and the type is not multiple
        //we return without saving
        if (params?.type !== "multiple") {
          if (
            addressArray[0]["address"] === "" ||
            addressArray[0]["address"] === undefined
          ) {
            return;
          }
        }

        //checks is the fields are the same
        if (params?.type !== "multiple") {
          if (addressArray[0].address === coordinates.address) {
            if (apartment === params?.aptNumber) {
              setEditMode(false);
              return;
            }
          }
        }

        //get the coordinates for each address in addressArray
        let coordinatesArray = await coordinatesPromises();
        let object = {},
          previousObject = {},
          alternativeObject = {};

        let filteredArray = addressArray?.filter((el) => !!el?.address);

        //we set the props to match the current values
        params.coordinates = filteredArray?.map(({ address }, index) => {
          if (address !== "" && address !== undefined) {
            return {
              address,
              addressFormItemName:
                coordinates.addressFormItemName || "alternativeAddresses",
              coordinates: coordinatesArray[index],
              ...(params?.type === "opportunity" || params?.type === "project"
                ? {
                    latFormItemName: coordinates.latFormItemName,
                    lngFormItemName: coordinates.lngFormItemName,
                  }
                : {}),
            };
          }
        });

        //condition to see if the opened card is of the billing address
        let isSameCondition =
          shippingSameAsBilling &&
          id !== "shippingAddress" &&
          id !== "secondAddress";

        if (params?.type === "multiple") {
          //the new coordinates to be saved
          object = {
            [coordinates.formItemName || "alternativeAddresses"]:
              filteredArray.map((el, index) => {
                if (el?.address !== "" && el?.address !== undefined) {
                  return {
                    address: el.address,
                    lat: coordinatesArray[index]?.lat,
                    lng: coordinatesArray[index]?.lng,
                  };
                }
              }),
          };
          //addresses to be sent to the card view
          alternativeObject = filteredArray.map((el, index) => {
            if (el?.address !== "" && el?.address !== undefined) {
              return {
                address: el.address,
                coordinates: coordinatesArray[index],
                formItemName: "alternativeAddresses",
              };
            }
          });
          getEditedValue(object, alternativeObject, {}, prev, props);
        } else {
          //the new coordinates to be saved
          object = {
            [coordinates.addressFormItemName]: addressArray[0]?.address,
          };

          if (params?.type !== "opportunity" && params?.type !== "project") {
            object = {
              ...object,
              [coordinates.coordinatesFormItemName]: coordinatesArray[0],
            };
          } else {
            object = {
              ...object,
              [coordinates?.latFormItemName]: coordinatesArray[0]?.lat,
              [coordinates?.lngFormItemName]: coordinatesArray[0]?.lng,
            };
          }
          if (isSameCondition) {
            object[oppositeCoordinates?.addressFormItemName] =
              addressArray[0]?.address;
            object[oppositeCoordinates?.coordinatesFormItemName] =
              coordinatesArray[0];
          }

          //when the type of the card is set to "split", that means
          //that the individual address components need to be saved
          let tmpFormName = coordinates.addressFormItemName;
          if (
            params?.type === "split" ||
            params?.type === "opportunity" ||
            params?.type === "project"
          ) {
            //these are the individual rowData keys used by billing or shipping
            let rowKeys =
              params?.type === "opportunity"
                ? splitKeys.opportunity
                : params?.type === "project"
                ? splitKeys.project
                : tmpFormName.includes("billing")
                ? splitKeys.billing
                : splitKeys.shipping;

            let oppositeKeys = isSameCondition && splitKeys.shipping;

            let newAddressArray = [...addressArray];
            newAddressArray[0]["aptNumber"] = apartment;

            for (const [key, value] of Object.entries(newAddressArray[0])) {
              //we skip the address key since it's been set before
              if (key !== "address" && !!rowKeys.hasOwnProperty(key)) {
                object[rowKeys[key]] = value;
              }
              //we set the same split values to the shipping
              if (oppositeKeys && key !== "address") {
                object[oppositeKeys[key]] = value || "";
              }
            }
          } else {
            //if the data is not split in the rowData we still want to save the apt numbers
            tmpFormName.includes("billing") ||
            tmpFormName.includes("mailing") ||
            tmpFormName === "leadAddress"
              ? (object["aptNumber"] = apartment || "")
              : (object["shippingAddApartmentNr"] = apartment || "");

            if (isSameCondition) {
              object["shippingAddApartmentNr"] = apartment || "";
            }
          }

          //if the card is not linked to the shipping address, we want to save the borough
          !tmpFormName.includes("shipping") &&
            (object["borough"] = addressArray[0]["city"] || "");

          //we add the zipCode key to the contacts
          tmpFormName.includes("mailing") &&
            (object["zipCode"] = addressArray[0]["zipCode"] || "");

          previousObject = {
            [coordinates.addressFormItemName]: coordinates.address,
          };

          if (params?.type !== "opportunity" && params?.type !== "project") {
            previousObject = {
              ...previousObject,
              [coordinates.coordinatesFormItemName]: coordinates?.coordinates,
            };
          } else {
            previousObject = {
              ...previousObject,
              [coordinates?.latFormItemName]: coordinates?.coordinates?.lat,
              [coordinates?.lngFormItemName]: coordinates?.coordinates?.lng,
            };
          }

          //addresses to be sent to the card view
          let tmp = {
            ...coordinates,
            address: addressArray[0]?.address,
            coordinates: coordinatesArray[0],
          };

          getEditedValue(object, [tmp], previousObject, prev, props);
        }

        setEditMode(false);
      },
      //error
      () => {}
    );
  }

  const placesInput = (index) => {
    return [
      {
        key: `auto${index}`,
        type: "placesautocomplete",
        popoverClassName: isDarkMode && "darkDropDown",
        form,
        formItemName: `auto${index}`,
        placeholder: "Click and Search...",
        disabled:
          shippingSameAsBilling &&
          (id === "shippingAddress" || id === "secondAddress"),
        onSelect: (value) => {
          setPreventCall(false);
          updateAddress(value, index);
        },
        allowSearch: true,
        onChange: (value) => {
          // setFormChanged(true);
          // if (!value) clearAddress(index);
        },
        onClear() {
          clearAddress(index);
        },
      },
    ];
  };

  function onCancel() {
    setEditMode(false);
    setCancelModalVisible(false);
  }

  function onEnterPress(event) {
    if (event.charCode === 13) {
      onCancel();
    }
  }

  let label;
  if (params?.type === "multiple") {
    label = "Alternative Address";
  } else if (id === "contactAddress") {
    label = "Contact Address";
  } else if (id === "shippingAddress" || id === "secondAddress") {
    label = "Shipping Address";
  } else if (params?.type === "project") {
    label = "Project Address";
  } else if (params?.type === "opportunity") {
    window.location.pathname.split("/")[1] === "fleetViolations"
      ? (label = "Violation Address")
      : (label = "Opportunity Address");
  } else {
    label = "Billing Address";
  }

  return (
    <div
      className={`addressCardContainer ${
        isDarkMode && "addressCardContainerDark"
      }`}
    >
      {props.params?.type !== "multiple" &&
        id !== "contactAddress" &&
        props.params?.type !== "opportunity" &&
        props?.params?.type !== "project" && (
          <div className="checkSameAsBilling">
            <span>Shipping Same as Billing</span>
            <Checkbox
              onChange={({ target }) => {
                setCheckChanged(true);
                setShippingSameAsBilling(target.checked);
                checkChangeHandler(target.checked);
                setFormChanged(true);
              }}
              defaultChecked={
                params?.coordinates[0]?.address ===
                oppositeAddressInfo?.coordinates[0]?.address
              }
            />
          </div>
        )}
      {!!addressArray &&
        addressArray
          .filter(Boolean)
          ?.map(({ city, state, street, zipCode }, index) => (
            <div className="addDetails" key={index}>
              <div
                className="address"
                key={`address${index}`}
                onClick={() => {
                  activeDetails !== index && setActiveDetails(index);
                }}
              >
                <span className="label">{label}</span>
                {params?.type === "multiple" && (
                  <>
                    <Popover content={<span>Remove Address</span>}>
                      <MinusCircleOutlined
                        key={`remove${index}`}
                        className="removeIcon"
                        onClick={() => {
                          removeAddress(index);
                        }}
                      />
                    </Popover>
                    <Popover
                      content={<span>Show/Collapse Address Details</span>}
                    >
                      <CollapseArrow
                        key={`collapse${index}`}
                        className={`addressCollapse ${
                          activeDetails === index ? "addShow" : "addHide"
                        }`}
                        onClick={() => {
                          if (activeDetails === index) {
                            setActiveDetails(null);
                          } else {
                            setActiveDetails(index);
                          }
                        }}
                      />
                    </Popover>
                  </>
                )}
                {/* {RenderDynamicComponents(placesInput(index))} */}
                <SimplePlacesInput
                  key={`auto${index}`}
                  {...{
                    formItemName: `auto${index}`,
                    onSelect: (value) => {
                      setPreventCall(false);
                      updateAddress(value, index);
                    },
                    allowSearch: true,
                    disabled:
                      shippingSameAsBilling &&
                      (id === "shippingAddress" || id === "secondAddress"),
                    placeholder: "Click and Search...",
                    form,
                    popoverClassName: isDarkMode && "darkDropDown",
                    onClear() {
                      clearAddress(index);
                    },
                  }}
                />
              </div>
              <div
                className={`detailsContainer ${
                  activeDetails === index ? "detailsShow" : "detailsHide"
                }`}
              >
                {params.type !== "multiple" &&
                  params.type !== "opportunity" &&
                  params?.type !== "project" && (
                    <div className="apartment">
                      <span className="label">Apartment #</span>
                      <Input
                        placeholder={"Apartment #"}
                        key={`apt${index}`}
                        onChange={(e) => {
                          aptChangeHandler(e.target.value);
                          setFormChanged(true);
                        }}
                        value={apartment}
                        disabled={
                          shippingSameAsBilling &&
                          (id === "shippingAddress" || id === "secondAddress")
                        }
                        style={{
                          backgroundColor: "#f5f5f5",
                        }}
                      />
                    </div>
                  )}
                <div className="street">
                  <span className="label">Street</span>
                  <Input
                    placeholder={"Street"}
                    value={street}
                    key={`street${index}`}
                    disabled={true}
                  />
                </div>
                <div className="city">
                  <span className="label">City</span>
                  <Input
                    placeholder={"City"}
                    value={city}
                    key={`city${index}`}
                    disabled={true}
                  />
                </div>
                <div className="additionalInfo">
                  <div className="state">
                    <span className="label">State</span>
                    <Input
                      placeholder={"State"}
                      value={state}
                      key={`state${index}`}
                      disabled={true}
                    />
                  </div>
                  <div className="postalCode">
                    <span className="label">Postal Code</span>
                    <Input
                      placeholder={"Postal Code"}
                      value={zipCode}
                      key={`zip${index}`}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      <div className="confirmContainer">
        <MondayButton
          key="cancelButton"
          onClick={() => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        {params?.type === "multiple" && (
          <MondayButton
            key="addButton"
            onClick={addAddress}
            className="viewButton mondayButtonBlue"
            Icon={<Plus width={20} height={20} />}
          >
            Add
          </MondayButton>
        )}
        <MondayButton
          key="viewButton"
          onClick={newWindowMap}
          className={`${
            activeDetails !== null ? "viewButton" : "buttonDisabled"
          } mondayButtonBlue`}
          disabled={activeDetails === null}
          Icon={<HiddenIcon />}
        >
          View
        </MondayButton>
        <MondayButton
          key="confirmButton"
          onClick={() => {
            saveHandler();
          }}
          className={`confirmButton`}
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
              key="decline"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />} key="confirm">
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};

export default Address;
