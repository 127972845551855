import * as Types from "../types";

export const REDUCER_INIT: Types.MaintenanceReducerType = {
  fleet: [],
  drivers: [],
  defects: [],
  locations: [],
  workOrders: [],
  inspections: [],
  maintenances: [],
  inventoryItems: [],
  servicePrograms: [],
  reports: {
    reportErrors: [],
    vehicleReports: [],
  },
};

function getIdKey(key: string): string {
  switch (key) {
    case "fleet":
      return "fleetId";
    case "drivers":
      return "driverId";
    case "defects":
      return "defectId";
    case "inspections":
      return "inspectionId";
    case "maintenances":
      return "maintenanceId";
    case "inventoryItems":
      return "itemId";
    case "servicePrograms":
      return "programId";
    default:
      return "workOrderId";
  }
}

export function DATA_REDUCER(
  state: typeof REDUCER_INIT,
  request: Types.MaintenanceRequestType
) {
  let tmpNewObj = {
    ...state,
  };

  switch (request.action) {
    case "SETUP":
      tmpNewObj = {
        ...tmpNewObj,
        ...request.payload,
      };
      break;
    case "ADD":
      for (const key in request.payload) {
        tmpNewObj[key] = [...tmpNewObj[key], ...request.payload[key]];
      }
      break;
    case "DELETE":
      for (const key in request.payload) {
        const idKey = getIdKey(key);

        tmpNewObj[key] = tmpNewObj[key].filter(
          (element) =>
            !request.payload[key].find((el) => el[idKey] === element[idKey])
        );
      }
      break;
    case "EDIT":
      for (const key in request.payload) {
        let idKey = getIdKey(key);

        for (const changeElement of request.payload[key]) {
          const index = tmpNewObj[key].findIndex(
            (e) => e[idKey] === changeElement[idKey]
          );

          if (index > -1) {
            tmpNewObj[key][index] = {
              ...tmpNewObj[key][index],
              ...changeElement,
            };
          }
        }
      }
      break;
  }

  return tmpNewObj;
}
