import { useRef } from "react";
import dayjs from "dayjs";

import FileTypeButton from "../FileTypeButton/FileTypeButton";
import { DriveFile } from "../../../../SidebarPages/FleetMaintenanceView/types";
import { WarningTriangle } from "../../../../SidebarPages/DynamicView/src";
import { RefreshIcon } from "../../../../SidebarPages/Communication/assets";

import "./MessageBubble.scss";
import MarkdownContent from "../../../MarkdownContent/MarkdownContent";

/**
 * @typedef AssistantMessageType
 * @property {string} messageId
 * @property {string} text
 * @property {string|undefined} senderId
 * @property {string} senderName
 * @property {string|undefined} roomId
 * @property {string|undefined} roomName
 * @property {boolean} botMessage
 * @property {string} parentFolderId
 * @property {DriveFile[]} attachments
 * @property {boolean} messageRetried
 * @property {number} createdAt
 * @property {"sent"|"error"|"typing"|"pending"} messageStatus
 */

/**
 * @typedef MessageBubbleProps
 * @property {AssistantMessageType} message
 * @property {boolean} isFirstMessage
 *
 * @param {MessageBubbleProps} props
 */
function MessageBubble(props) {
  /** @type {import("react").MutableRefObject<HTMLDivElement>} */
  const bubbleRef = useRef(null);

  function onResend() {
    if (props.onRetry) props.onRetry(props.message.messageId);
    const icon = bubbleRef.current?.querySelector(".resend-icon");
    if (!icon) {
      return;
    }

    if (icon.classList.contains("icon-spin")) {
      icon.classList.remove("icon-spin");
    } else {
      icon.classList.add("icon-spin");
    }
  }

  return (
    <div
      className={`message-bubble ${
        props.message.attachments.length ? "with-attachments" : ""
      } ${
        props.message.messageStatus === "pending"
          ? "message-bubble-pending"
          : props.message.messageStatus === "error"
          ? "message-bubble-error"
          : ""
      }`}
      ref={bubbleRef}
    >
      {props.message.messageStatus === "error" ? (
        <div className="message-bubble-error-title">
          <div className="message-error-description">
            <WarningTriangle height={14} width={14} />
            <span className="message-error-text">Message not sent</span>
          </div>
          <RefreshIcon className="resend-icon" onClick={onResend} />
        </div>
      ) : props.isFirstMessage ? (
        <div className="message-bubble-title">{props.message.senderName}</div>
      ) : null}
      <div className="message-bubble-body">
        {props.message.messageStatus === "typing" ? (
          <div className="typing-bubbles">
            <span></span>
            <span></span>
            <span></span>
          </div>
        ) : (
          <div className="message-content">
            {!props.message.attachments.length &&
            props.message.messageStatus !== "typing" &&
            props.message.messageStatus !== "pending" ? (
              <>
                <span className="inner-content-container">
                  <MarkdownContent>{props.message.text}</MarkdownContent>
                  <span className="space-right">
                    {/**
                     * I know it's an ugly solution, but it's the best way
                     * to simulate the whatsapp behavior for now without javascript
                     */}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <div className="inline-date">
                  {dayjs(props.message.createdAt).format("hh:mm a")}
                </div>
              </>
            ) : (
              props.message.text
            )}
            {props.message.attachments.length ? (
              <div className="message-attachments">
                {props.message.attachments.map((file) => {
                  return (
                    <FileTypeButton
                      fileName={file.name}
                      mimeType={file.mimeType}
                      key={file.id}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
        {props.message.attachments.length ? (
          <div className="message-date">
            {dayjs(props.message.createdAt).format("hh:mm a")}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MessageBubble;
