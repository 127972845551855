import { Collapse, Select } from "antd";
import { ResizableBox } from "react-resizable";
import { AgGridReact } from "ag-grid-react";
import { getAddonsColumns } from "../../tools/columnDefiners/addons/AddonsColumnDefiner";
import { AddonType } from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { getContextMenuItemsForAddons } from "../../tools/controllers/ContextMenuItems";
import { defaultScopeColumnDefs } from "../../../../../pages/Settings/settingsComponents/OtherScopes";

const { Panel } = Collapse;

export function serviceAddons(
  service,
  serviceIndex,
  dataEntryGrid,
  accessRights,
  agGridTheme,
  updateStateAndSave
) {
  let test = dataEntryGrid.state.pricingData.find(
    (item) => Number(item.serviceId) === Number(service.serviceId)
  );
  test = test?.pricingObject?.addonsList.filter(
    (item) => item.addonType === "service addon"
  );
  console.log("SELECT serviceAddon.js ");

  const addonsCount = service.documentationAddons?.length || 0;

  const { priceView, priceEdit } = dataEntryGrid.state;
  let addonsColumns = getAddonsColumns(
    dataEntryGrid.state.pricingData,
    service,
    dataEntryGrid.onBottomAddonChange,
    AddonType.serviceAddon,
    dataEntryGrid.props.isWritable,
    dataEntryGrid.state.selectOptions,
    dataEntryGrid.onAddonRichTextChange,
    { priceView, priceEdit, agGridTheme }
  );

  addonsColumns = [
    ...addonsColumns.filter(
      ({ field }) =>
        !defaultScopeColumnDefs.some(
          ({ field: defaultField }) => defaultField === field
        )
    ),
    ...defaultScopeColumnDefs,
  ];

  let options = [];

  test?.map((item) => {
    options[options.length] = {
      value: `${item.name}`,
      label: `${item.name}`,
    };
  });

  // const customStyles = {
  //   option: (base) => ({
  //     ...base,
  //     color: "black",
  //   }),
  // };

  const handleChange = (item) => {
    item = item === null ? [] : item;
    const arr = [];
    for (let i = 0; i < item.length; i++) {
      const element = test?.find((row) => row.name === item[i].value);
      arr.push(element);
    }
    dataEntryGrid.setState({ addonsRowData: arr });
  };

  return (
    <div className="addonsContainer" style={{ height: "100%" }}>
      <Collapse>
        <Panel
          key={0}
          header={
            <div className="addonsHeader">
              {dataEntryGrid.getAddonsCaption(service.serviceAddons)}
            </div>
          }
        >
          <div className="dataEntryGrid" style={{ height: "100%" }}>
            {test ? (
              <>
                <Select
                  closeMenuOnSelect={false}
                  // styles={customStyles}
                  // isMulti
                  mode="multiple"
                  options={options}
                  onChange={(item) => handleChange(item)}
                />
                <div className="body">
                  {dataEntryGrid.state.addonsRowData?.map((item) => (
                    <div className="card-component">
                      <h1 className="card-title">{item.name}</h1>
                      <div className="card-columns">
                        {item.values.map((value) => (
                          <p className="card-values">
                            <span>{value.name} : </span>
                            {""}
                            {value.defaultValue
                              ? value.defaultValue
                              : value.type === "number"
                              ? "0.00"
                              : "' '"}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <h1 className="no-data">No data to show</h1>
            )}
            {/* <ResizableBox
              className={"dataEntryGrid"}
              minConstraints={[0, 300]}
              maxConstraints={[0, 1500]}
              width={"auto"}
              height={50 * addonsCount > 350 ? 50 * addonsCount : 350}
              axis="y"
              handle={
                <div className="gridResizer">
                  <div className="gridResizerInnerLeft" />
                  <div className="gridResizerHandler">
                    <div className="gridResizerHandlerGrip" />
                    <div className="gridResizerHandlerGrip" />
                    <div className="gridResizerHandlerGrip" />
                  </div>
                  <div className="gridResizerInnerRight" />
                </div>
              }
            > */}
            {/* <div
            className="ag-theme-balham-dark"
            style={{ height: 300, width: "100%" }}
          > */}

            {/* ag grid for addons in bottom*/}
            {/* <AgGridReact
              columnDefs={addonsColumns}
              defaultColDef={dataEntryGrid.defaultColDef}
              frameworkComponents={dataEntryGrid.frameworkComponents}
              animateRows={true}
              immutableData={true}
              // enableFillHandle={true}
              enableCellChangeFlash={true}
              domLayout={"normal"}
              getRowNodeId={(data) => data.id}
              onGridReady={(params) => {
                dataEntryGrid.handleAddonsGridReady(
                  params,
                  service,
                  AddonType.serviceAddon
                );
              }}
              onCellEditingStarted={dataEntryGrid.addonsCellEditingStarted}
              onCellEditingStopped={(params) => {
                dataEntryGrid.cellEditingStopped(params);
              }}
              onCellValueChanged={(params) =>
                dataEntryGrid.addonsCellValueChanged(
                  params,
                  serviceIndex,
                  AddonType.serviceAddon
                )
              }
              getContextMenuItems={(params) =>
                getContextMenuItemsForAddons(
                  params,
                  serviceIndex,
                  dataEntryGrid.state.gridData,
                  dataEntryGrid.props.isWritable,
                  AddonType.serviceAddon,
                  dataEntryGrid.updateStateAndSave,
                  accessRights
                )
              }
            /> */}
            {/* </div> */}
            {/* </ResizableBox> */}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export function documentationAddons(service, serviceIndex, dataEntryGrid) {
  const addonsCount = service.documentationAddons?.length || 0;
  const { priceView, priceEdit, agGridTheme } = dataEntryGrid.state;

  return (
    <div className="addonsContainer">
      <Collapse>
        <Panel header={<div className="addonsHeader">Documentations</div>}>
          <div className="dataEntryGrid ag-theme-alpine">
            <ResizableBox
              className={"dataEntryGrid"}
              minConstraints={[0, 100]}
              maxConstraints={[0, 1500]}
              width={"auto"}
              height={50 * addonsCount > 150 ? 50 * addonsCount : 150}
              axis="y"
              handle={
                <div className="gridResizer">
                  <div className="gridResizerInnerLeft" />
                  <div className="gridResizerHandler">
                    <div className="gridResizerHandlerGrip" />
                    <div className="gridResizerHandlerGrip" />
                    <div className="gridResizerHandlerGrip" />
                  </div>
                  <div className="gridResizerInnerRight" />
                </div>
              }
            >
              <AgGridReact //ag grid for addons in bottom
                columnDefs={getAddonsColumns(
                  dataEntryGrid.state.pricingData,
                  service,
                  dataEntryGrid.onBottomAddonChange,
                  AddonType.documentation,
                  dataEntryGrid.props.isWritable,
                  dataEntryGrid.state.selectOptions,
                  dataEntryGrid.onAddonRichTextChange,
                  { priceView, priceEdit, agGridTheme }
                )}
                defaultColDef={dataEntryGrid.defaultColDef}
                frameworkComponents={dataEntryGrid.frameworkComponents}
                animateRows={true}
                suppressDragLeaveHidesColumns={true}
                immutableData={true}
                // enableFillHandle={true}
                enableCellChangeFlash={true}
                domLayout={"normal"}
                getRowNodeId={(data) => data.id}
                onGridReady={(params) => {
                  dataEntryGrid.handleAddonsGridReady(
                    params,
                    service,
                    "documentation"
                  );
                }}
                onCellEditingStarted={dataEntryGrid.addonsCellEditingStarted}
                onCellEditingStopped={(params) => {
                  dataEntryGrid.cellEditingStopped(params);
                }}
                onCellValueChanged={(params) =>
                  dataEntryGrid.addonsCellValueChanged(
                    params,
                    serviceIndex,
                    "documentation"
                  )
                }
                getContextMenuItems={(params) =>
                  getContextMenuItemsForAddons(
                    params,
                    serviceIndex,
                    dataEntryGrid.state.gridData,
                    dataEntryGrid.props.isWritable,
                    "documentation",
                    dataEntryGrid.updateStateAndSave
                  )
                }
              />
            </ResizableBox>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
