import { categoriesIcons } from "../../configuration/reportsConfigurationData";

const columnDefs = [
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    fixed: "center",
    align: "center",
    render: (data, record, index) => {
      const icon = data !== "Tasks" ? data : undefined;
      const CategoryIcon = categoriesIcons[icon] || categoriesIcons.default;

      return (
        <div className="sum-category" key={index}>
          {CategoryIcon && <CategoryIcon />}
          {data}
        </div>
      );
    },
  },
  {
    title: "Number of Reports",
    dataIndex: "count",
    key: "count",
    align: "center",
    render: (data, record, index) => {
      return (
        <div className="sum-count" key={index}>
          {data}
        </div>
      );
    },
  },
];

export default columnDefs;
