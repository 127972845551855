import { CalendarIcon, ClockIcon } from "../../FilterView/FilterIcons";
// import { boroughs } from "../../../../Dashboard/addWidgetsModalData";
import { Menu, Dropdown, Button, Select } from "antd";
import { DatePicker } from "antd";
// import { useEffect } from "react";
import "../FilterView.scss";

import {
  datePickerProgressHandler,
  dateRangeDateProgressHandler,
  filterByProjectManagerHandler,
  filterOptions,
  pmTables,
} from "../filtersData";
// import { LineFilterFunctions } from "./LineChartData";
import { DropdownIcon } from "../../../../BasePage/src";
import { compareObjects } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import dayjs from "dayjs";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import ProjectManagerFilter from "../ProjectManagerFilter";

const LineChartFull = ({
  params,
  id,
  dynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  // setRowData,
  // programFields,
  // projectsData,
  // dispatchData,
  // fleetData,
  // inspectionsData,
  // archData,
  // engData,
  tmpFilterStates,
  defaultFilterStates,
  filterStates,
  setFilterStates,
}) => {
  // const url = window.location.pathname.split("/")[1];

  const { RangePicker } = DatePicker;

  const table = params?.table;
  const filterValueTurnery = compareObjects(
    tmpFilterStates,
    defaultFilterStates
  );
  return (
    <>
      <div className="filterComponents">
        <div className="tittle">Filter date</div>
        <div className="filterContainer">
          <div className="leftSideContainer">
            <div className="tittleContainer">Filter date</div>
            <div className="tittleContainer">Filter range</div>
            <div className="tittleContainer">Filter time</div>
          </div>
          <div className="rightSideContainer">
            <Dropdown
              menu={{
                style: { borderRadius: "10px" },
                items: filterOptions(
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  id,
                  dynamicStates,
                  setFilterStates,
                  table
                ).map((a, index) => ({
                  key: index,
                  label: a?.value,
                  onClick: () => {
                    a?.filter();
                    a?.buttonName();
                  },
                })),
              }}
              placement="bottomCenter"
              suffixIcon={<CalendarIcon />}
              className="filter-dropdown"
              arrow
            >
              <Button>
                {filterValueTurnery
                  ? filterStates?.filterByTime
                  : tmpFilterStates?.filterByTime}
                <ClockIcon />
              </Button>
            </Dropdown>

            <RangePicker
              // value={
              //   filterValueTurnery
              //     ? filterStates?.filterByRange
              //     : tmpFilterStates?.filterByRange
              // }
              value={
                filterValueTurnery
                  ? [
                      dayjs(filterStates?.filterByRange?.[0]) || "",
                      dayjs(filterStates?.filterByRange?.[1]) || "",
                    ]
                  : [
                      dayjs(tmpFilterStates?.filterByRange?.[0]) || "",
                      dayjs(tmpFilterStates?.filterByRange?.[1]) || "",
                    ]
              }
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              presets={getRangePresets()}
              onChange={(e) =>
                dateRangeDateProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              className="filter-by-range-picker"
              popupClassName="filter-by-date-picker-dropdown"
            />

            <DatePicker
              value={
                filterValueTurnery
                  ? filterStates?.filterByDay
                  : tmpFilterStates?.filterByDay
              }
              allowClear={false}
              format="MM/DD/YYYY"
              suffixIcon={<CalendarIcon />}
              onChange={(e) =>
                datePickerProgressHandler(
                  e,
                  setUpdatedDynamicStates,
                  updatedDynamicStates,
                  dynamicStates,
                  id,
                  setFilterStates
                )
              }
              className="filter-by-date-picker"
              popupClassName="filter-by-date-picker-dropdown"
            />
          </div>
        </div>
        {pmTables?.[table] ? (
          <ProjectManagerFilter
            {...{
              id,
              table,
              filterStates,
              dynamicStates,
              tmpFilterStates,
              filterValueTurnery,
              setFilterStates,
              setUpdatedDynamicStates,
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default LineChartFull;
