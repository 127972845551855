import React, { useState } from "react";
import "./InternalChatWrapper.scss";
import { ArchiveIcon, InboxIcon, MenuIcon } from "../../assets/icons";
import HeaderInternalChat from "../HeaderInternalChat/HeaderInternalChat";
import Chats from "../Chats/Chats";
import { VirtualAssistant } from "../../../commonComponents";
const InternalChatWrapper = () => {
  const [selectedMenu, setSelectedMenu] = useState("inbox");
  const [checked, setChecked] = useState(false);
  const menu = [
    {
      label: "Inbox",
      field: "inbox",
      icon: <InboxIcon />,
    },
    {
      label: "Archive",
      field: "archive",
      icon: <ArchiveIcon />,
    },
  ];

  return (
    <div className="internalChatSideHeader">
      <div className="internalChatSideMenu">
        <div className="internalChatSideMenuHeader">
          <MenuIcon />
          <span>Internal Chat</span>
        </div>
        <div className="internalChatSideMenuItems">
          {menu.map((item, index) => (
            <div
              key={index}
              className={`internalChatSideMenuItem ${
                selectedMenu === item.field ? "selected" : ""
              }`}
              onClick={() => setSelectedMenu(item.field)}
            >
              {item.icon}
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <HeaderInternalChat {...{ checked, setChecked }} />
        <Chats />
        {/* {checked && <VirtualAssistant {...{ floatButton: !checked }} />} */}
      </div>
    </div>
  );
};

export default InternalChatWrapper;
