import RichTextEditorWrapper from "../RichTextEditor/RichTextEditorWrapper";
import { Tick } from "../../pages/Settings/settingsComponents/Roles/src";
import { Close, NotesIcon } from "../../SidebarPages/DynamicView/src";
import "./RichTextModalStyle.scss";
import { MondayButton } from "..";
import {
  useDispatchContext,
  useStateContext,
} from "../../SidebarPages/Projects/Accounting/ScheduleOfValues/ScheduleOfValueView/Context/Context";

import { Modal, Form, Tooltip } from "antd";
import { useState } from "react";
import { updateDocumentTitle } from "../../../utils";

const RichTextModal = ({ title, gridParams, service }) => {
  const { isWritable, fakeSOV, errors, darkMode } = useStateContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModalButton = () => {
    setIsModalVisible(true);
  };

  const dispatchContext = useDispatchContext();

  const handleOkButton = () => {
    let newFakeScheduleOfValues = {
      ...fakeSOV,
      services: fakeSOV?.services?.map((serviceData) =>
        serviceData?.serviceId === service?.serviceId &&
        serviceData?.estimationId === service?.estimationId
          ? {
              ...serviceData,
              amounts: serviceData?.amounts?.map((amountsData) =>
                amountsData?.id === gridParams?.data?.id
                  ? { ...amountsData, note: form?.getFieldValue("note") }
                  : amountsData
              ),
            }
          : serviceData
      ),
    };

    dispatchContext({ type: "SET_FAKE_SOV", payload: newFakeScheduleOfValues });
  };

  const handleCancelButton = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setIsModalVisible(false);
  };

  let savable = !isWritable
    ? { value: true, message: "Write mode off" }
    : !!errors
    ? { value: true, message: "Fix errors before saving" }
    : { value: false, message: "" };

  return (
    <>
      <div className="notesContainerDiv">
        <MondayButton
          className="openNotesButton"
          onClick={showModalButton}
          hasIcon={false}
          // Icon={<NotesIcon />}
        >
          Notes
        </MondayButton>

        <Modal
          wrapClassName={
            darkMode
              ? "richTextEditorModalStyleDark"
              : "richTextEditorModalStyle"
          }
          title={title || "Text Editor"}
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: title || "Text Editor" });
          }}
          open={isModalVisible}
          onCancel={handleCancelButton}
          footer={
            <div className="richTextNotesButtonContainer">
              <MondayButton
                className="richTextCloseMondayButton"
                onClick={handleCancelButton}
                Icon={<Close />}
              >
                Close
              </MondayButton>
              <Tooltip title={savable?.message}>
                <MondayButton
                  className="richTextSaveMondayButton"
                  onClick={handleOkButton}
                  Icon={<Tick />}
                  disabled={savable?.value}
                >
                  Save
                </MondayButton>
              </Tooltip>
            </div>
          }
        >
          <Form {...{ form }}>
            <RichTextEditorWrapper
              {...{ name: "note", initialValue: gridParams?.value }}
            />
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default RichTextModal;
