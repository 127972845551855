import {
  resetScheduleOfValue,
  updateScheduleOfValue,
} from "../../../../../../../../../../actions/scheduleOfValues";

import _ from "lodash";
import { queryParams } from "../../../../../../../../../../utils/queryParams";

//this function redirect user to sovView if the user have made no changes
export const exitSovViewHandler = ({
  setConfirmModalVisibility,
  scheduleOfValue,
  dispatch,
  navigate,
  location,
  fakeSOV,
  isNextStep,
  closeModal,
}) => {
  if (_.isEqual(fakeSOV, scheduleOfValue)) {
    if (isNextStep) {
      closeModal();
      dispatch(resetScheduleOfValue());

      return;
    }
    if (location.state?.goBackTo === "accountingGrid") {
      navigate(-1);
    } else {
      navigate(`/projects/${fakeSOV?.projectId}`, {
        state: { tabPosition: "Accounting" },
      });
    }
    dispatch(resetScheduleOfValue());
  } else {
    setConfirmModalVisibility(true);
  }
};

//this function discard the changes that the user have made in sov
export const discardChangesHandler = ({
  navigate,
  fakeSOV,
  isNextStep,
  closeModal,
}) => {
  if (isNextStep) {
    closeModal();

    return;
  }
  navigate(
    `/projects/${fakeSOV?.projectId}?${queryParams({
      tab: "Accounting",
      subTab: "Schedule Of Values",
    })}`,
    {
      state: { tabPosition: "Accounting" },
    }
  );
};

//this saves all the changes the user has made
export const saveChangesHandler = ({
  scheduleId,
  dispatch,
  navigate,
  fakeSOV,
  isNextStep,
  closeModal,
}) => {
  dispatch(updateScheduleOfValue(scheduleId, fakeSOV));
  dispatch(resetScheduleOfValue());
  if (isNextStep) {
    closeModal();
    return;
  }
  navigate(
    `/projects/${fakeSOV.projectId}?${queryParams({
      tab: "Accounting",
      subTab: "Schedule Of Values",
    })}`,
    {
      state: {
        tabPosition: "Accounting",
        isNextStepOpen: { visible: true, subTab: "Schedule Of Values" },
        savedRecord: fakeSOV,
      },
    }
  );
};
