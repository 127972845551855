import { InputNumber } from "antd";
import "./CheckboxPriceConfigurator.scss";

const CheckboxPriceConfigurator = ({ value = [], onChange }) => {
  const [uncheckedValue = 0, checkedValue = 0] = value;

  const onValueChange = (checked) => (inputValue) => {
    const newValue = [...value];
    newValue[+checked] = inputValue;

    onChange(newValue);
  };

  const formatterAndParser = {
    formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
    controls: false,
    min: 0,
  };

  return (
    <div className="checkbox-price-configurator">
      <div className="price-configurator-input">
        <div className="price-configurator-input-label">Unchecked Value</div>
        <InputNumber
          data-testid="unchecked"
          value={uncheckedValue}
          onChange={onValueChange(false)}
          {...formatterAndParser}
        />
      </div>

      <div className="price-configurator-input">
        <div className="price-configurator-input-label">Checked Value</div>
        <InputNumber
          data-testid="checked"
          value={checkedValue}
          onChange={onValueChange(true)}
          {...formatterAndParser}
        />
      </div>
    </div>
  );
};

export default CheckboxPriceConfigurator;
