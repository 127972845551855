import { Dayjs } from "dayjs";

import { ItineraryStep } from "./ItineraryStepClass";

/** @typedef {{lat: number, lng: number}} LatLng */

/** @typedef {"Yard"|"Schedule"|"Off Project"|"Off Schedule"|"Vendor"} DestinationType */

/**
 * @typedef StepObject
 * @property {string} pickUpLocation
 * @property {string} dropOffLocation
 * @property {Dayjs|null} departAt
 * @property {Dayjs|null} arriveBy
 * @property {number|null} routeLength
 * @property {number|null} duration
 * @property {number} index
 * @property {string} formattedDistance
 * @property {string} formattedDuration
 * @property {google.maps.TravelMode} travelMode
 * @property {DestinationType} destinationType
 * @property {string|null} destinationId
 * @property {string|null} destinationName
 * @property {google.maps.DirectionsWaypoint[]} waypoints
 * @property {string|null} overview_polyline
 * @property {LatLng|null} pickUpCoordinates
 * @property {LatLng|null} dropOffCoordinates
 */

/**
 * @typedef NewListType
 * @property {string|null} [startAddress]
 * @property {string|null} [endAddress]
 * @property {string|number|Dayjs|null} [startTime]
 * @property {string|number|Dayjs|null} [endTime]
 * @property {google.maps.Map} [map]
 */

export class Itinerary {
  /** @type {ItineraryStep[]} */
  #itinerary = [];

  /**
   * Used for when the itinerary is created with an end address.
   * In that case we want custom logic to run
   * @type {boolean}
   */
  #staticLast = false;

  /** @type {(step: StepObject|StepObject[]) => any} */
  onChange = () => {};

  /** @type {google.maps.Map} */
  map = undefined;

  /**
   * @param {Partial<StepObject>[]|NewListType} param
   * @param {boolean} [staticLast=false]
   */
  constructor(param, staticLast = false) {
    //#region CONSTRUCTOR
    if (Array.isArray(param)) {
      this.#itinerary = param.map(
        (e, index) => new ItineraryStep({ ...e, index })
      );
    } else {
      this.#itinerary = [
        new ItineraryStep({
          index: 0,
          startAddress: param.startAddress,
          startTime: param.startTime,
          map: param.map,
        }),
      ];

      if (param.endAddress) {
        this.#itinerary.push(
          new ItineraryStep({
            index: 1,
            endAddress: param.endAddress,
            map: param.map,
          })
        );
      }
    }

    this.#staticLast = staticLast;

    return new Proxy(this, {
      set(target, key, value) {
        if (key === "onChange" || key === "map") {
          for (const step of target.#itinerary) {
            step[key] = value;
          }
        }

        return Reflect.set(target, key, value);
      },
    });
  }

  /** @param {google.maps.Map} map  */
  setMap(map) {
    //#region SET MAP
    this.map = map;
  }

  addStep() {
    //#region ADD STEP
    const index = this.#itinerary.length - (this.#staticLast ? 2 : 1);
    if (!this.#itinerary[index].getDestination()) {
      return;
    }

    const prevTime = this.#itinerary[index].getArriveTime();

    const newItinerary = new ItineraryStep({
      index: index + 1,
      startAddress: this.#itinerary[index].getDestination(),
      startTime: prevTime ? prevTime.add(1, "hour") : null,
      map: this.map,
    });

    newItinerary.onChange = this.onChange;
    if (this.#staticLast) {
      this.#itinerary[index + 1].shiftIndex();
      this.#itinerary[index + 1].changeDepartAddress(null);
    }

    this.#itinerary.splice(index + 1, 0, newItinerary);
    this.onChange(this.getItinerary());
  }

  /** @param {number} index  */
  async removeStepOn(index) {
    //#region REMOVE STEP
    if (!index) {
      return;
    }

    if (this.#staticLast) {
      if (index === this.#itinerary[this.#itinerary.length - 1]) {
        return;
      }
    }

    this.#itinerary[index].disconnectMapElements();
    this.#unshiftIndexes(index);

    this.#itinerary.splice(index, 1);

    if (!this.#itinerary[index]) {
      //this the case when we removed the last step, leaving only one
      this.onChange(this.getItinerary());
      return Promise.resolve();
    }

    this.onChange(this.getItinerary());

    this.#itinerary[index].changeDepartAddress(
      this.#itinerary[index - 1].getDestination()
    );

    if (
      this.#itinerary[index].getDestination() ===
      this.#itinerary[index].getDepart()
    ) {
      await this.#itinerary[index].changeArriveTime(null, false);
      await this.#itinerary[index].changeDepartTime(
        this.#itinerary[index - 1].getArriveTime()
          ? this.#itinerary[index - 1].getArriveTime().add(1, "hour")
          : null,
        false
      );
      return await this.#itinerary[index].changeDestinationAddress(null);
    }

    return await this.#itinerary[index].changeDepartTime(
      this.#itinerary[index - 1].getArriveTime()
        ? this.#itinerary[index - 1].getArriveTime().add(1, "hour")
        : null
    );
  }

  /**
   * @param {Dayjs} time
   * @param {number} index
   */
  async changeDepartTime(time, index) {
    //#region CHANGE START TIME
    if (isNaN(index)) {
      return;
    }

    return await this.#itinerary[index].changeDepartTime(time);
  }

  /**
   * @param {Dayjs} time
   * @param {number} index
   */
  async changeArriveTime(time, index) {
    //#region CHANGE END TIME
    if (isNaN(index)) {
      return;
    }

    return await this.#itinerary[index].changeArriveTime(time);
  }

  /**
   * @param {string} address
   * @param {number} index
   */
  async changeEndAddress(address, index) {
    //#region CHANGE END ADDRESS
    if (isNaN(index)) {
      return;
    }

    if (index !== this.#itinerary.length - 1) {
      if (this.#itinerary[index + 1].getDestination() === address) {
        this.#itinerary[index].changeDestinationAddress(null);
        return Promise.reject(
          "Location can not be the same as the next drop off"
        );
      }

      this.#itinerary[index + 1].changeDepartAddress(address);
    }

    return await this.#itinerary[index].changeDestinationAddress(address);
  }

  /**
   * @param {google.maps.TravelMode} mode
   * @param {number} index
   */
  async changeTravelMode(mode, index) {
    //#region CHANGE TRAVEL MODE
    if (isNaN(index)) {
      return;
    }

    return await this.#itinerary[index].changeTravelMode(mode);
  }

  validateItinerary() {
    //#region VALIDATE ITINERARY
    let changes = false;

    for (let index = this.#itinerary.length - 1; index > 0; index--) {
      const step = this.#itinerary[index];
      const prevStep = this.#itinerary[index - 1];

      const thisDepart = step.getDepartTime();
      const prevArrive = prevStep.getArriveTime();
      if (!prevArrive || !thisDepart) {
        continue;
      }

      if (thisDepart.valueOf() < prevArrive.valueOf()) {
        step.clearTimes();
        changes = true;
      }
    }

    if (changes) {
      this.onChange(this.getItinerary());
    }
  }

  /**
   * Changes the data related to the destination address of an index
   * @param {Object} data
   * @param {DestinationType} [data.destinationType]
   * @param {string|null} [data.destinationId]
   * @param {string|null} [data.destinationName]
   * @param {number} index
   */
  changeDestinationData(data, index) {
    //#region CHANGE DESTINATION DATA
    return this.#itinerary[index].changeDestinationData(data);
  }

  /** @param {number} start  */
  #shiftIndexes(start) {
    //#region SHIFT INDEXES
    for (let i = start; i < this.#itinerary.length; i++) {
      this.#itinerary[i].shiftIndex();
    }
  }

  /** @param {number} end */
  #unshiftIndexes(end) {
    //#region UNSHIFT INDEXES
    for (let i = this.#itinerary.length - 1; i > end; i--) {
      this.#itinerary[i].unshiftIndex();
    }
  }

  getItinerary() {
    //#region GET ITINERARY
    return this.#itinerary.map((e) => e.toObject());
  }
}
