import { useState } from "react";
import { Checkbox, Input, Popover, Typography } from "antd";
import { elif } from "../../../../../RolesData";
import ActionButtons from "../../../../ActionButtons";
import { hasFalsePermission } from "../../../utils";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { TickIcon } from "../../../../../src";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

const { Text } = Typography;

const infoIconStyle = {
  transform: "translate(0px, 5px)",
  marginRight: 10,
  height: 15,
  width: 15,
};

const ChildRouteCard = ({
  child,
  parent,
  setAccessConfiguration,
  chainKeys = [],
  first = true,
}) => {
  const [isOpen, setIsOpen] = useState(parent?.read);
  const allChainKeys = [...chainKeys, parent.title];
  const [newDescription, setNewDescription] = useState(child?.description);
  const params = useParams();

  const {
    title,
    write,
    read = true,
    children,
    checked = false,
    description = "",
  } = child;

  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const hide = () => {
    setClicked(false);
    setHovered(false);
  };
  const handleHoverChange = (open) => {
    setHovered(open);
    setClicked(false);
  };
  const handleClickChange = (open) => {
    if (params?.role === "Super Admin" && !params.identityId) {
      setHovered(false);
      setClicked(open);
    }
  };

  const updateChild = (childNode, value, titleKey, parentTitle) => {
    return {
      ...childNode,
      ...((childNode.title === title || parentTitle === title) && {
        [titleKey]: value,
        ...(titleKey === "read" && value === false && { write: false }),
      }),
      ...(!!first
        ? childNode.children &&
          titleKey !== "description" && {
            children: childNode.children.map((nestedChild) => {
              return updateChild(nestedChild, value, titleKey, childNode.title);
            }),
          }
        : childNode.children && {
            children: childNode.children.map((nestedChild) => {
              return updateChild(nestedChild, value, titleKey, childNode.title);
            }),
          }),
    };
  };

  const updateRoute = (route, value, titleKey) => {
    if (route.title !== allChainKeys[0]) return route;

    return {
      ...route,
      ...(route.children && {
        children: route.children.map((child) => {
          return updateChild(child, value, titleKey);
        }),
      }),
    };
  };

  const onChildConfigChange = (value, titleKey) => {
    setAccessConfiguration((prev) => ({
      ...prev,
      Route: prev.Route.map((route) => updateRoute(route, value, titleKey)),
    }));
  };

  const onDescriptionChange = (value) => {
    setAccessConfiguration((prev) => ({
      ...prev,
      Route: prev.Route.map((route) =>
        updateRoute(route, value, "description")
      ),
    }));
  };

  const hoverContent = description ? (
    <div>
      <div style={{ textDecoration: "underline" }}>{title}</div>
      <div>{description}</div>
    </div>
  ) : undefined;
  const clickContent = (
    <>
      <p style={{ textDecoration: "underline" }}>{title}</p>
      <div style={{ display: "flex", gap: 5 }}>
        <Input.TextArea
          {...{
            defaultValue: description,
            onChange: (e) => setNewDescription(e.target.value),
          }}
        />
        <MondayButton
          {...{
            Icon: <TickIcon />,
            onClick: () => {
              onDescriptionChange(newDescription);
              hide();
            },
          }}
        />
      </div>
    </>
  );

  return (
    <div style={first ? {} : { marginLeft: 20 }} className="drawerContent">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {params?.role === "Super Admin" ? (
          <Popover
            style={{
              width: 450,
            }}
            content={hoverContent}
            trigger="hover"
            open={hovered}
            onOpenChange={handleHoverChange}
          >
            <Popover
              content={clickContent}
              trigger="click"
              open={clicked}
              onOpenChange={handleClickChange}
            >
              <InfoCircleOutlined style={infoIconStyle} />
            </Popover>
          </Popover>
        ) : !!description ? (
          <Popover
            style={{
              width: 450,
            }}
            content={hoverContent}
            trigger="hover"
            open={hovered}
            onOpenChange={handleHoverChange}
          >
            <InfoCircleOutlined style={infoIconStyle} />
          </Popover>
        ) : null}
        <div
          className="parentContainer"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className={elif(isOpen).parentContainer}>
            <div
              className={elif(read, !hasFalsePermission(child)).checkboxSQ}
              style={elif(isOpen).bluCStyle}
            >
              <div
                className="navyBlueContainer"
                style={elif(isOpen).navyBluCStyle}
              >
                <span style={elif(!isOpen).checkBoxStyle}>
                  <Checkbox checked={checked} />
                </span>
              </div>
            </div>
            <Text
              className="configElementTitle"
              style={{
                width: 450,
              }}
              ellipsis={title?.length > 15 ? true : false}
            >
              {title}
            </Text>
          </div>
          {isOpen && (
            <div className="actionPosition">
              <ActionButtons
                {...{
                  read: read,
                  write: write,
                  onReadClick: () => onChildConfigChange(!read, "read"),
                  onWriteClick: () => onChildConfigChange(!write, "write"),
                  readLabel: "Read",
                  writeLabel: "Write",
                  showWrite: write !== undefined && read,
                }}
              />
            </div>
          )}
        </div>
      </div>
      {isOpen &&
        children?.map((nestedChild) => (
          <ChildRouteCard
            key={nestedChild.title}
            child={nestedChild}
            parent={child}
            setAccessConfiguration={setAccessConfiguration}
            chainKeys={allChainKeys}
            first={false}
          />
        ))}
      <div className="dividerContainer">
        <span className="divider"></span>
      </div>
    </div>
  );
};

export default ChildRouteCard;
