import RentalPayingTerms from "../../../Header/Components/ControlPanel/Components/RentalPayingTerms/RentalPayingTerms";
import ChangeOrderIndicator from "./Components/ChangeOrderIndicator/ChangeOrderIndicator";
import ServiceDetails from "./Components/ServiceDetails/ServiceDetails";
import RetainageModal from "./Components/RetainageModal/RetainageModal";
import Information from "./Components/Informations/Information";
import EventsModal from "./Components/EventsModal/EventsModal";
import { useStateContext } from "../../../../Context/Context";
import "./service-header.scss";
import ElevationEventsCalc from "./Components/ElevationEventsCalc/ElevationEventsCalc";

const ServiceHeader = ({ service, borderColor, serviceEvents }) => {
  const { darkMode } = useStateContext();
  const { isChangeOrder } = service || {};

  return (
    <>
      <div
        className={
          darkMode
            ? "serviceListServiceCardHeaderSovDark"
            : "serviceListServiceCardHeaderSovLight"
        }
      >
        <div className="leftContainer">
          <div level={3} className="serviceHeaderServiceTitleSov">
            {service.label}
          </div>
          {serviceEvents && (
            <EventsModal
              {...{
                service,
                serviceEvents,
              }}
            />
          )}
          <RetainageModal {...{ service, whichOne: "allEvents" }} />
          <Information {...{ service }} />
        </div>

        <div className="rightContainer">
          {isChangeOrder && <ChangeOrderIndicator />}
          <ElevationEventsCalc {...{ service, borderColor, darkMode, serviceEvents }} />
          <ServiceDetails {...{ service, borderColor, darkMode }} />
          <RentalPayingTerms {...{ service }} />
        </div>
      </div>
    </>
  );
};

export default ServiceHeader;
