export const style = {
  container: {
    padding: "20px",
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gap: "20px",
  },
  input: { width: "100%", overflow: "visible", whiteSpace: "nowrap" },
  inputNumber: {
    width: "100%",
    height: " 32px",
    backgroundColor: "#f4f5f6",
    border: "2px solid transparent",
  },
  error: {
    display: "flex",
    gap: "10px",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "200%",
    height: "200%",
  },
  label: {
    fontSize: "16px",
    fontWeight: "600",
    textTransform: "capitalize",
    letterSpacing: "0",
    color: "#323338",
    marginBottom: "2px",
    marginLeft: "10px",
  },
  inputContainer: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
};

export const ERROR_MESSAGE_STEP_INPUTS =
  "Oops! Something went wrong while retrieving input data. Please contact the administrator for assistance.";

export const initialNumberOptions = {
  required: true,
  controls: false,
  placeholder: "0.00",
  stringMode: true,
  changeOnBlur: true,
};

export const dateTypes = ["datepicker", "timepicker", "datepicker-moment"];
export const inputTypes = ["phone", "select", "styledmultiselect"];

export function areRequiredFieldsPresent(inputData, inputFields) {
  const requiredFields = inputFields.filter((field) => field.required);
  return requiredFields.every((field) => inputData[field.name]);
}
