import { ColDef } from "ag-grid-enterprise";

/** @type {ColDef[]} */
const projectsFilterColumn = [
  {
    headerName: "Address",
    field: "address",
    checkboxSelection: true,
  },
  {
    headerName: "Record Type",
    field: "type",
  },
];

export default projectsFilterColumn;
