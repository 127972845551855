/**
 * Finds how many rows cam fill the height of the grid view
 * @param {string|React.MutableRefObject<HTMLDivElement>} container - if no ref can be provided, provide a ref to the grid container
 * @param {number} rowHeight - the height of a grid row
 * @returns {number}
 */
function findPaginationSize(container, rowHeight) {
  /** @type {HTMLDivElement} */
  let mainContainer;
  if (typeof container === "string") {
    mainContainer = document.getElementById(container);
  } else if (!!container?.current) {
    mainContainer = container?.current;
  }
  if (mainContainer) {
    let viewportEl = mainContainer.querySelector(".ag-center-cols-viewport");

    if (viewportEl) {
      return parseInt(viewportEl.getBoundingClientRect().height / rowHeight);
    }
  }

  return 0;
}

export default findPaginationSize;
