import { API } from "aws-amplify";
import types from "../types";

//Logged on tasks state stored in redux
const loggedOnTasks = (attributes) => (dispatch) => {
  dispatch({
    type: types.LOGGED_ON_TASKS,
    payload: attributes,
  });
};

/**
 * Function that updates logged on taksks on backend
 * @param {taskId} string - Id of the task that has been finished logged on
 * @param {values} object - Values that needs to be updated in order to finish task
 * @param {task} object - Current task object that will be updated
 */
export const endLoggedTasks =
  (values, task, setDraggableTasks) => async (dispatch) => {
    try {
      API.put("tasksManagement", `/tasksManagement/${task.taskId}`, {
        body: values,
      }).then(() => {
        dispatch({
          type: types.UPDATE_LOGGED_TASKS,
          payload: {
            taskId: task.taskId,
            task,
          },
        });
        setDraggableTasks(null);
      });
    } catch (error) {
      console.error({ error });
    }
  };

// export const updateTasks =
//   (task, valuesChanged, loggedOnTasks) => async (dispatch) => {
//     try {
//       await API.put("tasksManagement", `/tasksManagement/${task.taskId}`, {
//         body: valuesChanged,
//       });
//       valuesChanged.forEach((val) => (task[val.key] = val.value));

//       const updatedTaskIndex = loggedOnTasks.findIndex(
//         (l) => l.taskId === task.taskId
//       );
//       loggedOnTasks[updatedTaskIndex] = task;

//       dispatch({
//         type: types.LOGGED_ON_TASKS,
//         payload: loggedOnTasks,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   };

export default loggedOnTasks;
