import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ApprovalIcon } from "../../../../icons/approvalsIcon.svg";
import { useSelector } from "react-redux";
import { Badge } from "antd";
import ApprovalsDrawer from "./components/approvals-drawer/ApprovalsDrawer";
import { fetchData, filterTables, getAccessRights } from "../../../../utils";
import { apiRoutes } from "../../../SidebarPages/Fleet/utils";
import { wsFormRequests } from "../../../../AppData";
import { useDispatch } from "react-redux";
import { WithTooltip } from "../../../commonComponents";
import { useSocket } from "src/hooks";
import { useCustomerWebsocketContext } from "../../../../contexts/WebsocketProvider/useCustomerWebsocket";
import { approvalsMsg } from "./utils/customerApprovals";

const customerPortalCategories = {
  Projects: "Project",
  Estimations: "Estimation",
};

// const wsFRequests = new WebSocket(wsFormRequests.online);
function Approvals() {
  const [open, setOpen] = useState(false);
  const { approvals, requests: userRequests } = useSelector(
    (state) => state.approvals
  );
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const dispatch = useDispatch();
  const { socket: wsFRequests } = useSocket({ path: wsFormRequests.online });

  const { websocketFunctions = {} } = useCustomerWebsocketContext();

  websocketFunctions.approvals = (msg) => {
    approvalsMsg(msg, dispatch);
  };

  /**
   * Adds socket listeners for handling different types of messages.
   */
  function addSocketListeners() {
    wsFRequests.addEventListener("message", ({ data: msg }) => {
      const { request, body = {} } = JSON.parse(msg || {});
      const { requestId } = body;

      switch (request) {
        case "new": {
          dispatch({ type: "ADD_APPROVAL", payload: body });
          dispatch({ type: "ADD_REQUEST", payload: body });
          break;
        }
        case "request-response": {
          dispatch({ type: "UPDATE_APPROVAL", payload: body });
          dispatch({ type: "UPDATE_REQUEST", payload: body });
          break;
        }
        case "delete": {
          dispatch({
            type: "DELETE_APPROVAL_BY_ID",
            payload: { id: requestId },
          });
          dispatch({
            type: "DELETE_REQUEST_BY_ID",
            payload: {
              id: requestId,
            },
          });
          break;
        }
        default:
          break;
      }
    });
  }

  // web-socket
  useEffect(() => {
    if (wsFRequests) {
      addSocketListeners();
    }
  }, [wsFRequests]);

  useEffect(() => {
    !!userConfiguration &&
      filterTables(
        apiRoutes.formRequests,
        "assignedTo",
        userConfiguration?.cognitoUserId
      )
        .then((response) => {
          dispatch({
            type: "ADD_REQUESTS",
            payload: {
              requests: response,
            },
          });
        })
        .catch((err) => {
          console.error("Error getting form requests: ", err);
        });
    fetchData({ endpoint: apiRoutes.formRequests })
      .then((response) => {
        dispatch({
          type: "ADD_APPROVALS",
          payload: {
            approvals: response,
          },
        });
      })
      .catch((err) => {
        console.error("Error getting fake approvals: ", err);
      });
  }, [userConfiguration]);

  /**
   * Returns an array of form requests that have not been responded to.
   *
   * @returns {Array} The array of form requests that have not been responded to.
   */
  const notRespondedFormRequests = useMemo(() => {
    return (userRequests || [])
      ?.filter(
        ({
          assignedTo,
          requestId,
          requestObject = {},
          createdBy,
          responseFrom,
        }) => {
          const { status = "" } = requestObject;
          // added condition removes approval requests that have been approved
          if (responseFrom !== "approved") {
            if (status === "Draft" || status === "Reviewed")
              if (assignedTo === userConfiguration?.cognitoUserId)
                return !!(
                  Array.isArray(approvals)
                    ? approvals
                    : !!approvals?.formRequests
                    ? approvals?.formRequests
                    : []
                )?.find((el) => el?.requestId === requestId);
            if (status === "Needs Review")
              if (
                assignedTo === userConfiguration?.cognitoUserId &&
                createdBy === userConfiguration?.cognitoUserId
              )
                return !!(
                  Array.isArray(approvals)
                    ? approvals
                    : !!approvals?.formRequests
                    ? approvals?.formRequests
                    : []
                )?.find((el) => el?.requestId === requestId);
          }
        }
      )
      .map((approval) => ({
        ...approval,
        requestType:
          customerPortalCategories?.[approval.requestType] ||
          approval.requestType,
      }));
  }, [userRequests, userConfiguration, approvals]);

  const accessRights = getAccessRights({
    userConfiguration,
    title: "approvals",
  })?.routeConfig.read;

  if (!accessRights) return null;

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="approvals">
        <Badge
          className="ignore-onclickoutside"
          count={notRespondedFormRequests?.length}
        >
          <ApprovalIcon onClick={() => setOpen(true)} className="header-icon" />
        </Badge>
      </WithTooltip>

      {open && (
        <ApprovalsDrawer
          visible={open}
          setVisible={setOpen}
          notRespondedFormRequests={notRespondedFormRequests}
          from="header"
          wsFRequests={wsFRequests}
        />
      )}
    </div>
  );
}

export default Approvals;
