import binOptionsPopulate from "./binOptionsPopulate";
import docRequestPopulation from "./docRequestPopulation";
import mainFieldPopulate from "./mainFieldPopulate";
import safetyOptionsPopulate from "./safetyOptionsPopulate";
import serviceInspectionPopulate from "./serviceInspectionPopulate";

/**
 *
 * @param {String} recordId - the record id
 * @param {String} requestType - the type of the request
 * @param {Object} fetchedData - the fetched data from the server
 * @param {Object} parsedRequest - the parsed request object
 * @param {Array} inspectionTypes - the inspection types
 * @param {String} currentPageKey - the current page key
 * @param {Object} selectedMainField - the selected main field
 * @param {Object} userConfiguration - the user configuration
 * @param {Function} setRecordsLoading - the function to set the records loading
 * @param {Function} dispatchRequestData - the pointer to the dispatch function of the request reducer
 * All the specific form population functions are called here
 * If a new request type is added and it requires specific form population, a new function should be added to the specificFormPopulation function
 */
const specificFormPopulation = async ({
  recordId = null,
  requestType = "",
  fetchedData = null,
  parsedRequest = null,
  inspectionTypes = [],
  currentPageKey = null,
  selectedMainField = null,
  userConfiguration = null,
  setRecordsLoading = () => {},
  dispatchRequestData = () => {},
}) => {
  mainFieldPopulate({
    recordId,
    requestType,
    parsedRequest,
    dispatchRequestData,
  });

  safetyOptionsPopulate({
    safety: fetchedData?.safety,
    parsedRequest,
    dispatchRequestData,
  });

  binOptionsPopulate({
    projects: fetchedData?.projects,
    recordId,
    requestType,
    dispatchRequestData,
  });

  serviceInspectionPopulate({
    recordId,
    projects: fetchedData?.projects,
    requestType,
    inspectionTypes,
    dispatchRequestData,
  });

  await docRequestPopulation({
    recordId,
    requestType,
    fetchedData,
    currentPageKey,
    userConfiguration,
    selectedMainField,
    setRecordsLoading,
    dispatchRequestData,
  });
};

export default specificFormPopulation;
