import React, { useEffect, useMemo, useState } from "react";
import htmlParser from "../../../../../../../../../utils/htmlParser";

function ScheduleDetailsView({ chosenSOV, sovs, setFormChanged = () => {} }) {
  const [currentSOV, setCurrentSOV] = useState(null);
  const [content, setContent] = useState(null);

  const findContent = (id) => {
    setContent(currentSOV?.services?.find?.((el) => el?.serviceId === id));
  };

  const findSov = useMemo(() => {
    setCurrentSOV(sovs?.find?.(({ scheduleId }) => scheduleId === chosenSOV));
  }, [sovs, chosenSOV]);

  useEffect(() => {
    setContent(currentSOV?.services[0]);
  }, [currentSOV]);

  return (
    <>
      {!!currentSOV && (
        <div className="ScheduleDetailsViewLight">
          <div className="ScheduleDetails-container">
            <div className="item-label-contanier">
              {currentSOV?.services?.map((el) => (
                <div
                  onClick={() => {
                    setFormChanged(true);
                    findContent(el?.serviceId);
                  }}
                  className={
                    el?.serviceId === content?.serviceId
                      ? "item-label-active"
                      : "item-label"
                  }
                >
                  {el?.label}
                </div>
              ))}
            </div>
            {!!content ? (
              <div className="notes-content">
                {!!content?.rentalPaymentTerms ? (
                  <>
                    <div className="name-div">
                      Name:
                      <br />
                      <div className="name-content">
                        {content?.rentalPaymentTerms?.name}
                      </div>
                    </div>
                    <div className="name-div">
                      Number of weeks:
                      <br />
                      <div className="name-content">
                        {content?.rentalPaymentTerms?.range}
                      </div>
                    </div>
                    <div className="name-div">
                      Description:
                      <br />
                      <div className="name-content-desc">
                        {htmlParser(content?.rentalPaymentTerms?.description)}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="select-service">There is no data</div>
                )}
              </div>
            ) : (
              <div className="notes-content">
                <div className="select-service">Select one service</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ScheduleDetailsView;
