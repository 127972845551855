import {
  evaluateMathExpression,
  getMaxNoFeet,
  toFixedFormatter,
} from "../formatters";
import { evaluateMonth } from "../formatters/evaluators";
import { toOrdinalFloor } from "../polyfillers";

export const horizontalNetting = [
  {
    headerName: "ID",
    headerTooltip: "ID",
    field: "id",
    minWidth: 35,
    width: 35,
    editable: false,
  },
  {
    headerName: "Approved",
    headerTooltip: "Approved",
    field: "approved",
    minWidth: 150,
    width: 150,
    editable: false,
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "L",
    headerTooltip: "Length",
    field: "length",
    width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "W",
    headerTooltip: "Width",
    field: "width",
    width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "On floor",
    headerTooltip: "On floor",
    field: "onFloor",
    width: 210,
    valueFormatter: (params) => {
      if (params.value) {
        return `${toOrdinalFloor({
          value: params.value - 1,
        })} - ${toOrdinalFloor(params)}`;
      } else {
        return "-";
      }
    },
  },
  {
    headerName: "Incl. Corners",
    headerTooltip: "Incl. Corners",
    field: "includingCorners",
    width: 80,
    editable: false,
    cellRenderer: "CheckboxRenderer",
  },
  // {
  //   headerName: "Addons",
  //   headerTooltip: "Addons",
  //   field: "addons",
  //   width: 200,
  //   minWidth: 140,
  //   cellRenderer: "agGroupCellRenderer",
  //   editable: false,
  //   cellRendererParams: {
  //     suppressCount: true,
  //     // checkbox: true,
  //     innerRenderer: "SidewalkShedAddonsRenderer",
  //     suppressDoubleClickExpand: true,
  //     suppressEnterExpand: true,
  //   },
  //   valueFormatter: (params) => {
  //     if (params.data.addons) {
  //       return `(${params.data?.addons?.length})`;
  //     } else {
  //       return "N/A";
  //     }
  //   },
  // },
  {
    headerName: "Description",
    headerTooltip: "Description",
    field: "description",
    width: 150,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "Internal Note",
    headerTooltip: "Internal Note",
    field: "note",
    width: 150,
    cellRenderer: "rtfEditor",
  },
  // {
  //   headerName: "Sqf.",
  //   headerTooltip: "Sqf.",
  //   field: "sqft",
  //   width: 80,
  //   editable: false,
  //   valueGetter: (params) => {
  //     if (
  //       params.data.hasOwnProperty("length") &&
  //       params.data.hasOwnProperty("width")
  //     ) {
  //       return (
  //         Math.round(
  //           getMaxNoFeet(params.data.length) *
  //             getMaxNoFeet(params.data.width) *
  //             100
  //         ) / 100
  //       );
  //     } else {
  //       return "N/A";
  //     }
  //   },
  // },
  {
    headerName: "PPU",
    headerTooltip: "Price Per Unit",
    field: "ppu",
  },
  {
    headerName: "is Initial?",
    field: "isInitial",
    cellRenderer: "CheckboxRenderer",
    minWidth: 120,
    width: 150,
    editable: false,
  },
  {
    headerName: "Type",
    field: "type",
    minWidth: 120,
    width: 150,
    editable: false,
    valueGetter: (params) => {
      params.data.type = params.data.isInitial ? "Initial" : "Jump";
      return params?.data?.isInitial ? "Initial" : "Jump";
    },
  },
  {
    headerName: "Rent",
    headerTooltip: "Rent",
    field: "rent",
    cellRenderer: "SidewalkShedRentAdvisor",
  },
  {
    headerName: "Months",
    headerTooltip: "Months",
    field: "months",
    width: 80,
    valueFormatter: evaluateMonth,
  },
  {
    headerName: "Price",
    headerTooltip: "Price",
    field: "price",
    getQuickFilterText: () => "",
  },
  {
    headerName: "Lock",
    field: "lock",
    cellRenderer: "CheckboxRenderer",
    minWidth: 70,
    width: 70,
    editable: false,
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    valueFormatter: ({ value = 0 }) => `${value * 100}%`,
    editable: false,
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    valueFormatter: toFixedFormatter,
    editable: false,
  },
  {
    headerName: "Total",
    field: "totalPrice",
    valueFormatter: toFixedFormatter,
    editable: false,
  },
];
