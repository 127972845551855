import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const maintenance = (updatedDynamicStates, IdHandler) => {
  return {
    createdAt: () => ({
      title: "Maintenance Creation Date",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Maintenance",
        field: "createdAt",
        labels: months,

        datasets: [
          {
            label: "Maintenance",
            data: (() => {
              let creationsDate = [];
              for (let i = 0; i < 12; i++) {
                creationsDate = [
                  ...creationsDate,
                  (updatedDynamicStates?.[IdHandler] || [])
                    ?.map((a) => a?.createdAt)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length,
                ];
              }
              return creationsDate;
            })(),

            backgroundColor: months.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //createdAt
  };
}; //Maintenance
