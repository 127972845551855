import { ColDef } from "ag-grid-enterprise";
import { CaretRightOutlined } from "@ant-design/icons";
import { DownCaretWhite } from "../../../../../../../assets/icons";

/** @type {ColDef[]} */
const columnDefs = [
  {
    headerName: "Address",
    field: "address",
    resizable: false,
    cellRenderer({ value, node, data, context }) {
      return (
        <div className="main-address-class">
          <DownCaretWhite
            className="main-address-arrow"
            style={{
              transform: node.expanded ? "rotate(-180deg)" : "rotate(0deg)",
            }}
          />
          <span
            style={{ color: "#1264A3", fontWeight: 600 }}
            onClick={() => {
              const idSet = new Set(
                data?.details?.map(({ projectId }) => projectId).filter(Boolean)
              );

              context.updateViewTypeHandler({
                type: "PROJECT",
                address: value,
                ids: idSet.size ? Array.from(idSet) : null,
              });
            }}
          >
            {value}
          </span>
          <div className="address-class-divider"></div>
          <span className="address-route-no">{`${data.details.length} route${
            data.details.length !== 1 ? "s" : ""
          }`}</span>
        </div>
      );
    },
  },
];

export default columnDefs;
