import React from "react";

export const getNYDate = (date) => {
  return {
    formatted: new Date(date).toLocaleDateString("en-US", {
      timeZone: "America/New_York",
    }),
    valueOf: () =>
      new Date(date).getTime("en-US", {
        timeZone: "America/New_York",
      }),
  };
};
