const getColumnIds = (columnApi) => {
  return columnApi?.getColumns().map((column) => column.getId()) || [];
};

const getColumnWidths = () => {
  const rowWidth = parseInt(
    document.getElementsByClassName("ag-row")[0]?.offsetWidth
  );
  const parentWidth = parseInt(
    document.getElementsByClassName("ag-body-viewport")[0]?.offsetWidth
  );
  return { rowWidth, parentWidth };
};

export const autoSizeAllColumns = (rowData, gridRef, skipHeader) => {
  if (rowData && gridRef?.current) {
    const { columnApi, api } = gridRef.current;

    const allColumnIds = getColumnIds(columnApi);
    columnApi?.autoSizeColumns(allColumnIds, skipHeader);

    const widths = getColumnWidths();

    if (widths.rowWidth < widths.parentWidth) {
      api?.sizeColumnsToFit();
    }
  }
};
