import { useFilteredHistory } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/Hooks/useFilteredHistory";
import "./LongLineSteper.scss";
import { SmallLoadableComp } from "../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";

import { Result, Tooltip } from "antd";
import { GraphTooltipContent } from "../BarChart/GraphTooltipContent";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const LongLineSteper = ({ params = {}, filterValue }) => {
  const { content, table, field } = params;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useFilteredHistory(table);

  useEffect(() => {
    if (content?.length === 0) {
      setLoading(false);
      setNoData(true);
    } else if (content?.every((a) => !a?.taskDone && a?.taskDone !== 0)) {
      setLoading(true);
      setNoData(false);
    } else {
      setLoading(false);
      setNoData(false);
    }
  }, [params?.content, filterValue]);
  return (
    <div
      key="LongLineSteperBodyKey"
      className={`LongLineSteperBody ${isDarkMode && "LongLineSteperBodyDark"}`}
    >
      {!noData ? (
        <SmallLoadableComp loading={loading}>
          {content?.map((paramsData, index) => (
            <Tooltip
              key={index}
              overlayClassName={
                !!paramsData?.custom && "dashToolTipStyleCustom"
              }
              title={
                !paramsData?.custom ? (
                  paramsData?.title
                ) : (
                  <GraphTooltipContent
                    {...{
                      tooltipData: paramsData?.custom?.[0],
                      title: `${paramsData.title}: ${
                        paramsData?.taskDone || 0
                      }`,
                      label: paramsData?.table,
                    }}
                  />
                )
              }
            >
              <div className="steperContainer">
                <div className="steperTopContent">
                  <div className="steperTitle">{paramsData?.title}</div>
                  <div className="steperRefractive">
                    {paramsData?.taskDone || 0}/{paramsData?.taskNumber || 0}
                  </div>
                </div>

                <div
                  className="steperUnfilled"
                  onClick={() =>
                    history({
                      [field === "leadAddress" ? "borough" : field]: [
                        paramsData?.title,
                      ],
                    })
                  }
                >
                  <div className="steperNumber">{paramsData?.taskDone}</div>

                  <div
                    className="steperFilled"
                    style={{
                      width: `${
                        (paramsData?.taskDone / paramsData?.taskNumber) * 100 ||
                        0
                      }%`,
                      backgroundColor: paramsData?.color,
                    }}
                  ></div>
                </div>
                <div className="steperDescription">{`${
                  paramsData?.taskDone || 0
                }${
                  paramsData?.taskDone !== 1
                    ? paramsData?.contentsHandler
                    : paramsData?.contentHandler
                }`}</div>
              </div>
            </Tooltip>
          ))}
        </SmallLoadableComp>
      ) : (
        <Result
          status="warning"
          title={
            params?.noFunction
              ? `There are no matching pattern for this chart `
              : `No data found for ${table}!`
          }
          subTitle={
            params?.noFunction
              ? `Try removing and recreating this chart. If this reoccurs, please contact the Software Department.`
              : `You may not have access to any records!`
          }
        />
      )}
    </div>
  );
};

export default LongLineSteper;
