export const invoices = (
  updatedDynamicStates,
  IdHandler,
  programFields,
  invoicesData
) => {
  return {
    invoiceStatus: () => ({
      title: "Invoices - Invoice Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Invoices",
        field: "invoiceStatus",
        secondaryField: "projectName",
        filterField: [],
        id: IdHandler,
        statusData: invoicesData?.map((a) => {
          return {
            label: `${a?.projectName} - ${a?.invoiceNumber}`,
            value: a?.categoryFrom,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status of Invoices")
                ?.fieldOptions?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.categoryFrom?.toLowerCase()?.slice(0, -1)
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "795px",
    }),
  };
};
