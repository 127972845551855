import ChangeOrderIndicator from "../../../../../../ScheduleOfValues/ScheduleOfValueView/components/ServiceList/Service/ServiceHeader/Components/ChangeOrderIndicator/ChangeOrderIndicator";
import ServiceDetails from "../../../../../../ScheduleOfValues/ScheduleOfValueView/components/ServiceList/Service/ServiceHeader/ServiceDetails/ServiceDetails";
import ChangeRetenageModal from "./ServiceHeaderComponents/ChangeRetenageModal/ChangeRetenageModal";
import CompleteAll from "./ServiceHeaderComponents/CompleateAll/CompleateAll";
import { formatCurrency } from "../../../../../../../../utils";
import { useStateContext } from "../../../../Context/Context";
import "./service-header.scss";

import AnimatedNumber from "react-animated-number";
import CreditEnabler from "./ServiceHeaderComponents/CreditEnabler/CreditEnabler";
import { NormalSizedModal } from "../../../../../../../../Fleet/components";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { useState } from "react";
import { Input } from "antd";
import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const ServiceHeader = ({ service, color }) => {
  // console.log("service222", service);
  const retainage = service?.amounts?.reduce(
    (acc, curr) => acc + forceToNumber(curr?.thisKeptRetainage),
    0
  );
  const { isChangeOrder = false } = service || {};

  // Context state & dispatch
  const { darkMode } = useStateContext();

  return (
    <div
      className={
        darkMode
          ? "application-service-list-service-header-dark"
          : "application-service-list-service-header-light"
      }
    >
      {/* <div className="detailsContainer"> */}
      <div className="leftContainer">
        <div className="tittle">{service?.label}</div>
        <ServiceDetails
          service={service}
          borderColor={color}
          darkMode={darkMode}
        />
        <ChangeRetenageModal {...{ service }} />
        <div className="completeAllContainer">
          <CompleteAll {...{ service }} />
          <div className="compleatedLabel">Complete All</div>
        </div>

        <div className="label">Total Amount:</div>
        <div className="total-amount-price">
          <AnimatedNumber
            value={service?.totalPrice}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        <div className="label">Retainage:</div>
        <div
          className={
            !!retainage
              ? "total-amount-price"
              : "total-amount-price-dont-have-yet"
          }
        >
          <AnimatedNumber
            value={retainage}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        {/* <div className="label">Credit:</div> */}
        <CreditEnabler {...{ service, color }} />
      </div>

      <div className="rightContainer">
        {isChangeOrder && <ChangeOrderIndicator />}
      </div>
    </div>
  );
};

export default ServiceHeader;
