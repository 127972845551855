import React, { createContext } from "react";
import {
  MaintenanceTabs,
  MaintenanceRequestType,
  MaintenanceReducerType,
} from "../types";

interface MaintenanceContextType {
  data: MaintenanceReducerType;
  updateData: React.Dispatch<MaintenanceRequestType>;
  activeTab: MaintenanceTabs;
}

const initialValue = {} as MaintenanceContextType;

const MaintenanceViewContext = createContext(initialValue);

export default MaintenanceViewContext;
