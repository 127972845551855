export const rentals = (
  programFields,
  updatedDynamicStates,
  rentalsData,
  IdHandler
) => {
  const data = updatedDynamicStates?.[IdHandler];

  const rentalStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Rentals.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    rentalStatus: () => ({
      title: "Rentals - Category From",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Rentals",
        field: "rentalStatus",
        title: "Rentals Category From",
        subtitle: "",
        content: rentalStatus?.statusName?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (rentalsData?.filter((a) => a?.rentalStatus === status).length /
              rentalsData?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: rentalsData?.filter((a) => a?.rentalStatus === status).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //rentalStatus
  };
};
