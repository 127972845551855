import { API } from "aws-amplify";
import { message } from "antd";

export async function getEmployeeData(
  setEmployeesList,
  setPageLoading = (param) => {}
) {
  setPageLoading(true);
  try {
    const [crews, engineers, architects, drivers] = await Promise.all([
      API.get("crews", "/crews"),
      API.get("engineers", "/engineers"),
      API.get("architects", "/architects"),
      API.get("drivers", "/drivers"),
    ]);

    // ToDo: Once the Employee Fields are set for Engineers, Architects and Drivers remove the Overwriting and update the colsDef to use the new Employee Fields instead of the once for crew
    for (let crew of crews) {
      crew.role = `Crew - ${crew.crewPosition}`;
    }

    // ToDo: Once the Employee Fields are set for Engineers remove the Overwriting and update the colsDef to use the new Employee Fields instead of the once for crew
    for (let engineer of engineers) {
      engineer.role = "Engineer";
      engineer.crewName = engineer.nameOfUser;
      engineer.employeeId = `ENG - ${engineer.engineerId}`;
      engineer.crewId = engineer.engineerId;
    }

    // ToDo: Once the Employee Fields are set for Architects remove the Overwriting and update the colsDef to use the new Employee Fields instead of the once for crew
    for (let architect of architects) {
      architect.role = "Architect";
      architect.crewName = architect.nameOfUser;
      architect.employeeId = `ARCH - ${architect.architectId}`;
      architect.crewId = architect.architectId;
    }

    // ToDo: Once the Employee Fields are set for Drivers remove the Overwriting and update the colsDef to use the new Employee Fields instead of the once for crew
    for (let driver of drivers) {
      driver.role = "Driver";
      driver.crewName = driver.driverName;
      driver.employeeId = `DR - ${driver.driverId}`;
      driver.crewId = driver.driverId;
    }

    setEmployeesList(crews.concat(architects, drivers, engineers));
  } catch (error) {
    message.error("There was an error getting the Employees Information");
    setEmployeesList([]);
    setPageLoading(false);
  } finally {
    setPageLoading(false);
  }
}

export async function getSingleEmployeeData(
  crewId,
  employeeType,
  setEmployeeData
) {
  try {
    const res = await API.get(employeeType, `/${employeeType}/${crewId}`);

    if (res.length === 0) {
      message.info("No Employee found");
      setEmployeeData([]);
      return;
    }

    setEmployeeData(res);
  } catch (error) {
    message.error("There was an error getting the Employee's Information");
    setEmployeeData([]);
  }
}

export async function updateEmployeeData(
  crewId,
  employeeType,
  response,
  setEmployeeData,
  setPageLoading
) {
  message.info("Updating Employee ");
  const path = employeeType;

  if (["engineers", "architects"].includes(employeeType)) {
    const firstName =
      response.find((item) => item.key === "firstName")?.value || "";
    const lastName =
      response.find((item) => item.key === "lastName")?.value || "";

    response.push({
      key: "nameOfUser",
      value: `${firstName} ${lastName}`,
    });

    // Remove 'firstName' and 'lastName'
    const updatedData = response.filter(
      (item) => item.key !== "firstName" && item.key !== "lastName"
    );

    response = updatedData;
  }

  try {
    const res = await API.put(path, `/${path}/${crewId}`, {
      body: response,
    });

    message.success("Employee was updated successfully");
  } catch (error) {
    message.error("There was an error updating the Employee");
  } finally {
    return;
  }
}

export async function createEmployeeDriveFolder(
  id,
  employeeName,
  employeeType,
  driveFunctions,
  employeeCredential
) {
  const { folders: allFolders } = await driveFunctions.create({
    parentId: employeeCredential?.credentialValue,
    parentFolderName: `${employeeName} - ${id}`,
  });

  const path = employeeType;

  await API.put(path, `/${path}/${id}`, {
    body: {
      googleDriveFolderIds: {
        ...allFolders,
      },
    },
  });

  return;
}
