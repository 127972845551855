import { removeArrayIndexes } from "../../../utils";
//this function returns array of options for the circle progress bar
{
  /*attorney=>the first parameter table is the object where it will get the data
the second parameter 'functionName' is the function that will be used to generate the array
the third parameter adapt the name of subcategory that we click to the name of the key of the obj
the forth parameter 'chartOptions' is used to remove unnecessary cards */
}
export const initialFilteredData = ({
  dispatchData = [],
  fleetData = [],
  inspectionsData = [],
  maintenanceData = [],
  violationsData = [],
  estimationsData = [],
  attorney = [],
  expeditor = [],
  documentationData = [],
  applicationsData = [],
  rentalsData = [],
  claimsData = [],
  safetyData = [],
  incidentsData = [],
  defectsData = [],
  workOrdersData = [],
  todosData = [],
  costAnalyticsData = [],
  approvalsData = [],
  fleetInspectionsData = [],
}) => {
  return {
    Leads: {
      leadStatus: {
        name: "Status of leads",
        status: "leadStatus",
        functionName: "attorney",
        chartOptions: "noProgressCircle",
      },

      leadAddress: {
        functionName: "boroughHandler",
        chartOptions: "noProgress",
      },

      projectExecutive: {
        functionName: "attorney",
        status: "projectExecutive",
        chartOptions: "noProgressCircle",
        name: "Project Executive",
      },
    },

    Opportunities: {
      opportunityStage: {
        functionName: "programFieldsHandler",
        name: "Opportunity Stage",
        status: "detailedStageName",
        chartOptions: "standard",
      },
      opportunityStatus: {
        functionName: "programFieldsHandler",
        name: "Status of opportunities",
        status: "statusName",
        chartOptions: "noLongLine",
      },
      projectExecutive: {
        functionName: "attorney",
        status: "projectExecutive",
        chartOptions: "noProgressCircle",
        name: "Project Executive",
      },

      projectManager: {
        functionName: "attorney",
        status: "projectManager",
        chartOptions: "noProgressCircle",
        name: "Project Manager",
      },

      totalEstimationsAmount: {
        functionName: "attorney",
        chartOptions: "noPieBarCircleLong",
        name: "Total Estimations Amount",
        status: "estimations",
      },

      geoFenceInfo: {
        functionName: "geofenceHandler",
        name: "Status of opportunities",
        status: "geoFenceInfo",
        chartOptions: "pieBarProgress",
      },
    }, //opportunities

    Projects: {
      projectStatus: {
        functionName: "programFieldsHandler",
        name: "Project Status",
        status: "statusName",
        chartOptions: "standard",
      },
      attorney: {
        table: attorney,
        functionName: "attorney",
        attorney: "accountName",
        chartOptions: "noProgressCircle",
      }, //attorney
      expeditor: {
        table: expeditor,
        functionName: "attorney",
        expeditor: "accountName",
        chartOptions: "noProgressCircle",
      }, //expeditor
      projectExecutive: {
        functionName: "attorney",
        status: "projectExecutive",
        chartOptions: "noLine",
        name: "Project Executive",
      },

      projectManager: {
        functionName: "attorney",
        status: "projectManager",
        chartOptions: "noStatusNoLine",
        name: "Project Manager",
      },

      totalEstimationsAmount: {
        functionName: "attorney",
        chartOptions: "noPieBarCircleLong",
        name: "Total Estimations Amount",
        status: "services",
      },

      geoFenceInfo: {
        functionName: "geofenceHandler",
        name: "Status of opportunities",
        status: "geoFenceInfo",
        chartOptions: "pieBarProgress",
      },
    }, //Projects

    Applications: {
      applicationStatus: {
        table: applicationsData,
        functionName: "programFieldsHandler",
        name: "Status of Requisition",
        status: "statusName",
        chartOptions: "standardLine",
      },
      totalAmount: {
        table: applicationsData,
        functionName: "programFieldsHandler",
        name: "Total Amount",
        status: "totalAmount",
        chartOptions: "noPie",
      },
    },

    Claims: {
      claimStatus: {
        table: claimsData,
        functionName: "programFieldsHandler",
        name: "Status of Claims",
        status: "statusName",
        chartOptions: "noProgress",
      },
      claimCategory: {
        table: claimsData,
        functionName: "attorney",
        claimCategory: "claimCategory",
        chartOptions: "noPieIncidentReport",
      },
      isClaimantAnEmployeeOfCore: {
        table: claimsData,
        functionName: "attorney",
        isClaimantAnEmployeeOfCore: ["isClaimantAnEmployeeOfCore"],
        chartOptions: "noPieIncidentReport",
      },
      claimAddress: {
        table: claimsData,
        functionName: "attorney",
        claimAddress: ["claimAddress"],
        chartOptions: "noPieBarCircleLong",
      },
    },

    Safety: {
      safetyApplicationStatus: {
        table: safetyData,
        functionName: "programFieldsHandler",
        name: "Status of Safety Applications",
        status: "statusName",
        chartOptions: "noLine",
      },
      safetyName: {
        table: safetyData,
        functionName: "attorney",
        safetyName: ["safetyName"],
        chartOptions: "noLongPieLine",
      },
      accountName: {
        table: safetyData,
        functionName: "attorney",
        accountName: ["accountName"],
        chartOptions: "noLongPieLine",
      },
      projectName: {
        table: safetyData,
        functionName: "attorney",
        projectName: ["projectName"],
        chartOptions: "noLongPieLine",
      },
      safetyApplicationCategory: {
        table: safetyData,
        functionName: "attorney",
        safetyApplicationCategory: ["safetyApplicationCategory"],
        chartOptions: "noPieCircleStepper",
      },
      scheduleAddress: {
        table: safetyData,
        functionName: "attorney",
        scheduleAddress: ["scheduleAddress"],
        chartOptions: "noLongPieLine",
      },
    },

    Rentals: {
      rentalStatus: {
        table: rentalsData,
        functionName: "programFieldsHandler",
        name: "Status of Rentals",
        status: "statusName",
        chartOptions: "standardLine",
      },
      totalPrice: {
        table: rentalsData,
        functionName: "programFieldsHandler",
        name: "Total Price",
        status: "totalPrice",
        chartOptions: "noPie",
      },
    },

    Charges: {
      categoryFrom: {
        functionName: "programFieldsHandler",
        name: "Status of Charges",
        status: "statusName",
        chartOptions: "standardLine",
      },
      chargeAmount: {
        functionName: "programFieldsHandler",
        name: "Charge Amount",
        status: "chargeAmount",
        chartOptions: "noPie",
      },
    },
    Invoices: {
      invoiceStatus: {
        functionName: "programFieldsHandler",
        name: "Status of Invoices",
        status: "statusName",
        chartOptions: "standardLine",
      },
      totalInvoiceAmount: {
        functionName: "programFieldsHandler",
        name: "Invoice Amount",
        status: "invoiceAmount",
        chartOptions: "noPie",
      },
    },

    Documentation: {
      docType: {
        table: documentationData,
        functionName: "attorney",
        docType: "docType",
        chartOptions: "noProgress",
      },
      docStatus: {
        table: documentationData,
        functionName: "attorney",
        docStatus: "docStatus",
        chartOptions: "noProgressCircle",
      },
    }, //Documentation

    Scheduling: {
      typeOfWork: {
        name: "Scheduling Types",
        status: "typeName",
        functionName: "programFieldsHandler",
        chartOptions: "noProgress",
      },
      projectExecutive: {
        functionName: "attorney",
        status: "projectExecutive",
        chartOptions: "noLine",
        name: "Project Executive",
      },

      projectManager: {
        functionName: "attorney",
        status: "projectManager",
        chartOptions: "noStatusNoLine",
        name: "Project Manager",
      },

      scheduleAddress: {
        table: safetyData,
        functionName: "attorney",
        scheduleAddress: ["scheduleAddress"],
        chartOptions: "noLongPieLine",
      },
      scheduleTotalProgressPercentage: {
        functionName: "attorney",
        chartOptions: "noPieBarCircleLong",
        name: "Schedule Total Progress",
      },
    }, //schaduling

    Dispatching: {
      fleetName: {
        table: dispatchData,
        functionName: "attorney",
        fleetName: "fleetName",
        chartOptions: "noProgressLine",
      },
      driverName: {
        table: dispatchData,
        functionName: "attorney",
        driverName: ["routes"],
        chartOptions: "noProgressCircle",
      }, //driverName
      dispatchedBy: {
        table: dispatchData,
        functionName: "attorney",
        dispatchedBy: ["dispatchedBy"],
        chartOptions: "noProgressLine",
      },
    }, //Dispatching

    Fleet: {
      fleetYear: {
        table: fleetData,
        functionName: "attorney",
        fleetYear: ["fleetYear"],
        chartOptions: "noProgress",
      },
      registrationExpDate: {
        table: fleetData,
        functionName: "monthsHandler",
        registrationExpDate: "registrationExpDate",
        chartOptions: "noProgress",
      },
      inspectionExpDate: {
        table: fleetData,
        functionName: "monthsHandler",
        inspectionExpDate: "inspectionExpDate",
        chartOptions: "noProgress",
      },
    }, //Fleet

    "Fleet Inspections": {
      inspectedBy: {
        table: fleetInspectionsData,
        functionName: "attorney",
        inspectedBy: ["inspectedBy"],
        chartOptions: "noProgressCircle",
      },
      driverName: {
        table: fleetInspectionsData,
        functionName: "attorney",
        driverName: ["driver"],
        chartOptions: "noProgressLine",
      }, //driverName
      createdAt: {
        table: fleetInspectionsData,
        functionName: "monthsHandler",
        createdAt: "createdAt",
        chartOptions: "noProgress",
      },
      fleetName: {
        table: fleetInspectionsData,
        functionName: "attorney",
        fleetName: ["fleetName"],
        chartOptions: "noProgressCircle",
      },
      inspectionStatus: {
        table: fleetInspectionsData,
        functionName: "programFieldsHandler",
        name: "Status of Inspections",
        status: "statusName",
        chartOptions: "noLine",
      },
      inspectionType: {
        table: fleetInspectionsData,
        functionName: "attorney",
        inspectionType: ["inspectionType"],
        chartOptions: "noLine",
      },
      mechanic: {
        table: fleetInspectionsData,
        functionName: "attorney",
        mechanic: ["mechanic"],
        chartOptions: "noLine",
      },
    }, //Inspections

    Incidents: {
      incidentStatus: {
        table: incidentsData,
        functionName: "programFieldsHandler",
        name: "Status of Incidents",
        status: "statusName",
        chartOptions: "noLine",
      },
      incidentCategory: {
        table: incidentsData,
        functionName: "attorney",
        incidentCategory: ["incidentCategory"],
        chartOptions: "noLine",
      },
      incidentName: {
        table: incidentsData,
        functionName: "attorney",
        incidentName: ["incidentName"],
        chartOptions: "noBarNoLine",
      },
      projectName: {
        table: incidentsData,
        functionName: "attorney",
        projectName: ["projectName"],
        chartOptions: "noBarNoLine",
      },
    },

    Defects: {
      defectStatus: {
        table: defectsData,
        functionName: "attorney",
        defectStatus: ["defectStatus"],
        chartOptions: "incidentReport",
      },
      defectName: {
        table: defectsData,
        functionName: "attorney",
        defectName: ["defectName"],
        chartOptions: "noPieIncidentReport",
      },
      fleetName: {
        table: defectsData,
        functionName: "attorney",
        fleetName: ["fleetName"],
        chartOptions: "noPieIncidentReport",
      },
      driverName: {
        table: defectsData,
        functionName: "attorney",
        driverName: ["driverName"],
        chartOptions: "noPieIncidentReport",
      },
      defectPriority: {
        table: defectsData,
        functionName: "attorney",
        defectPriority: ["defectPriority"],
        chartOptions: "noPieIncidentReport",
      },
      inspectionName: {
        table: defectsData,
        functionName: "attorney",
        inspectionName: ["inspectionName"],
        chartOptions: "noPieIncidentReport",
      },
    },

    "Work Orders": {
      workOrderStatus: {
        table: workOrdersData,
        functionName: "attorney",
        workOrderStatus: ["workOrderStatus"],
        chartOptions: "incidentReport",
      },
      fleetName: {
        table: workOrdersData,
        functionName: "attorney",
        fleetName: ["fleetName"],
        chartOptions: "noPieIncidentReport",
      },
      dueDate: {
        table: workOrdersData,
        functionName: "monthsHandler",
        dueDate: "dueDate",
        chartOptions: "noPieIncidentReport",
      },
      mechanicName: {
        table: workOrdersData,
        functionName: "attorney",
        mechanicName: ["mechanicName"],
        chartOptions: "noPieIncidentReport",
      },
    },
    Maintenance: {
      createdAt: {
        table: maintenanceData,
        functionName: "monthsHandler",
        createdAt: "createdAt",
        chartOptions: "noProgress",
      },
    }, //Maintenanc

    Violations: {
      amount_due: {
        table: violationsData,
        functionName: "monthsHandler",
        chartOptions: "noProgress",
      },
      issue_date: {
        table: violationsData,
        functionName: "monthsHandler",
        chartOptions: "noProgress",
      },
    }, //Violations
    "To Do": {
      todoStatus: {
        table: todosData,
        functionName: "programFieldsHandler",
        chartOptions: "noLine",
      },
      todoDueDate: {
        table: todosData,
        functionName: "monthsHandler",
        chartOptions: "noLine",
      },
      todoCategory: {
        table: todosData,
        functionName: "attorney",
        todoCategory: "todoCategory",
        chartOptions: "noLine",
      },
      todoUser: {
        table: todosData,
        functionName: "attorney",
        todoUser: "todoUser",
        chartOptions: "noLine",
      },
      relatedTo: {
        table: todosData,
        functionName: "attorney",
        relatedTo: "relatedTo",
        chartOptions: "noLine",
      },
    }, //To Do

    "Project Cost": {
      companyEmployees: {
        table: costAnalyticsData,
        functionName: "attorney",
        companyEmployees: "companyEmployees",
        chartOptions: "pieBarProgress",
      },
      jobsiteAddress: {
        table: costAnalyticsData,
        functionName: "attorney",
        jobsiteAddress: "jobsiteAddress",
        chartOptions: "pieBarProgress",
      },
      payrollType: {
        table: costAnalyticsData,
        functionName: "attorney",
        payrollType: "payrollType",
        chartOptions: "pieBarProgress",
      },
      punchType: {
        table: costAnalyticsData,
        functionName: "attorney",
        punchType: "punchType",
        chartOptions: "pieBarProgress",
      },
      role: {
        table: costAnalyticsData,
        functionName: "attorney",
        role: "role",
        chartOptions: "pieBarProgress",
      },
      jobsiteTotalCost: {
        table: costAnalyticsData,
        functionName: "attorney",
        jobsiteTotalCost: "jobsiteTotalCost",
        chartOptions: "pieBarProgress",
      },
      jobsiteTotalHours: {
        table: costAnalyticsData,
        functionName: "attorney",
        jobsiteTotalHours: "jobsiteTotalHours",
        chartOptions: "pieBarProgress",
      },
      jobsiteAccount: {
        table: costAnalyticsData,
        functionName: "attorney",
        jobsiteAccount: "jobsiteAccount",
        chartOptions: "noPieBarCircleLong",
      },
      jobsiteAccountType: {
        table: costAnalyticsData,
        functionName: "attorney",
        jobsiteType: "jobsiteType",
        chartOptions: "noPieBarCircleLong",
      },
    }, //Cost Analytics
    Estimations: {
      engProgress: {
        table: estimationsData,
        functionName: "attorney",
        engProgress: "engAssignedTo",
        chartOptions: "noPieBarCircleLong",
      },
      archProgress: {
        table: estimationsData,
        functionName: "attorney",
        archProgress: "archAssignedTo",
        chartOptions: "noPieBarCircleLong",
      },
      estSTATUS: {
        name: "Estimation Status",
        status: "statusName",
        functionName: "programFieldsHandler",
        chartOptions: "standard",
      },
      archAssignedTo: {
        table: estimationsData,
        functionName: "attorney",
        archAssignedTo: "archAssignedTo",
        chartOptions: "standardNoCircle",
      },
      engAssignedTo: {
        table: estimationsData,
        functionName: "attorney",
        engAssignedTo: ["engAssignedTo"],
        chartOptions: "standardNoCircle",
      },
      statusByArch: {
        name: "Status by Architecture",
        status: "statusName",
        functionName: "programFieldsHandler",
        chartOptions: "standard",
      },
      statusByEng: {
        name: "Status by Engineering",
        status: "statusName",
        functionName: "programFieldsHandler",
        chartOptions: "standard",
      },
      projectExecutive: {
        functionName: "attorney",
        status: "projectExecutive",
        chartOptions: "noLine",
        name: "Project Executive",
      },

      projectManager: {
        functionName: "attorney",
        status: "projectManager",
        chartOptions: "noStatusNoLine",
        name: "Project Manager",
      },
      amount: {
        table: estimationsData,
        functionName: "attorney",
        chartOptions: "noPieBarCircleLong",
        name: "Amount",
      },

      amountForServices: {
        table: estimationsData,
        functionName: "attorney",
        chartOptions: "noPieBarCircleLong",
        name: "Total Amount per Service",
      },
    }, //Estimations

    "Add-ons": {
      amount: {
        chartOptions: "amount",
      },
    }, //Add-ons

    Approvals: {
      userRequesting: {
        table: approvalsData,
        functionName: "attorney",
        chartOptions: "noStatusNoLine",
        name: "User Requesting",
      },

      assignedTo: {
        table: approvalsData,
        functionName: "attorney",
        chartOptions: "noStatusNoLine",
        name: "Assigned To",
      },

      requestType: {
        table: approvalsData,
        functionName: "programFieldsHandler",
        name: "Request Type",
        status: "requestTypeName",
        chartOptions: "noStatusNoLine",
      },

      category: {
        table: approvalsData,
        functionName: "attorney",
        category: ["category"],
        chartOptions: "noStatusNoLine",
        name: "Category",
      },

      responseFrom: {
        table: approvalsData,
        functionName: "attorney",
        responseFrom: ["responseFrom"],
        chartOptions: "noStatusNoLine",
        name: "Response",
      },
    },

    Inspections: {
      inspectionType: {
        table: inspectionsData,
        functionName: "attorney",
        inspectionType: ["inspectionType"],
        chartOptions: "noProgressCircle",
        name: "Inspection Type",
      },

      inspectionStatus: {
        table: inspectionsData,
        functionName: "programFieldsHandler",
        name: "Inspection Status",
        status: "statusName",
        chartOptions: "noProgressCircle",
      },

      inspectedBy: {
        table: inspectionsData,
        functionName: "attorney",
        inspectedBy: ["inspectedBy"],
        chartOptions: "noProgressCircle",
        name: "Inspected by",
      },
    },
  };
};
//this handle chart types for the selected subcategory
export const widgetOptions = (programFields) => {
  const charts = programFields.find(
    (a) => a.fieldName === "Chart types"
  ).fieldOptions;
  return {
    noProgress: removeArrayIndexes(charts, [4, 6, 7]),
    noLongLine: removeArrayIndexes(charts, [5, 6, 7]),
    noProgressCircle: removeArrayIndexes(charts, [3, 4, 6, 7]),
    noProgressLine: removeArrayIndexes(charts, [3, 4, 5, 6, 7]),
    noLine: removeArrayIndexes(charts, [3, 5, 6, 7]),
    noBarNoLine: removeArrayIndexes(charts, [1, 3, 5, 6, 7]),
    standard: removeArrayIndexes(charts, [6, 7]),
    standardNoCircle: removeArrayIndexes(charts, [3, 6, 7]),
    standardLine: removeArrayIndexes(charts, 6),
    noPie: removeArrayIndexes(charts, [0, 2, 3, 4, 5, 6]),
    noPieBarCircleLong: removeArrayIndexes(charts, [0, 1, 2, 3, 5, 6, 7]),
    amount: removeArrayIndexes(charts, [1, 2, 3, 5, 7]),
    incidentReport: removeArrayIndexes(charts, [2, 3, 4, 6, 7]),
    noPieIncidentReport: removeArrayIndexes(charts, [0, 2, 3, 4, 6, 7]),
    noPieCircleStepper: removeArrayIndexes(charts, [0, 3, 4, 5, 6, 7]),
    noLongPieLine: removeArrayIndexes(charts, [0, 3, 5, 6, 7]),
    noStatusNoLine: removeArrayIndexes(charts, [3, 4, 5, 6, 7]),
    pieBarProgress: removeArrayIndexes(charts, [2, 3, 5, 6, 7, 8]),
  };
};

//returns subcategory arrays for the selected category
export const subCategoriesFields = (programFields, subCategoryName) =>
  programFields
    ?.find((a) => a?.fieldName === "Widget Categories")
    ?.fieldOptions?.find((a) => a?.categoryName == programFields)
    ?.subCategories?.find((a) => a?.subCategoryName == subCategoryName)?.fields;
//this handle the name of the widget types that we get from the db
export const widgetOptionsMapping = {
  Pie: "Pie Chart",
  Progress: "Status Progress",
  BarChart: "Bar Chart",
  CircleProgresBar: "Circle Progress Bar",
  StreightProgressBar: "Straight Progress Bar",
  BarChartFull: "Long Bar Chart",
  LongLineSteper: "Long Line Steper",
  LineChartFull: "Long Line Chart",
};
//this handle the name of the subcategories that we get from the db
export const nameHandler = {
  leadStatus: "Lead Status",
  leadAddress: "Lead Address",
  opportunityStage: "Opportunity Stage",
  opportunityStatus: "Opportunity Status",
  projectStatus: "Project Status",
  attorney: "Attorney",
  expeditor: "Expeditor",
  docType: "Documentation Type",
  docStatus: "Documentation Status",
  engProgress: "Engineer Progress",
  archProgress: "Architect Progress",
  estSTATUS: "Estimation Status",
  archAssignedTo: "Architect Assigned",
  engAssignedTo: "Engineer Assigned",
  statusByArch: "Status by Architect",
  statusByEng: "Status by Engineer",
  amount: "Amount",
  typeOfWork: "Type of Work",
  fleetYear: "Fleet Year",
  registrationExpDate: "Registration Exp. Date",
  inspectionExpDate: "Inspection Exp. Date",
  fleetName: "Fleet Name",
  dispatchedBy: "Dispatched by",
  amount_due: "Amount Due",
  issue_date: "Issue Date",
  inspectedBy: "Inspected by",
  driverName: "Driver Name",
  createdAt: "Created at",
  services: "Services",
  projectName: "Project Name",
  opportunityAddress: "Opportunity Address",
  fleetId: "fleetId",
  violationLocation: "Violation Location",
  scheduleAddress: "Schedule Address",
  driver: "Driver Name",
  borough: "Borough",
  violationLocation: "Violation Location",
  applicationStatus: "Requisition Status",
  totalAmount: "Total Amount",
  totalPrice: "Total Price",
  rentalStatus: "Rental Status",
  categoryFrom: "Category From",
  chargeAmount: "Charge Amount",
  invoiceStatus: "Invoice Status",
  totalInvoiceAmount: "Total Invoice Amount",
  claimStatus: "Claim Status",
  claimCategory: "Claim Category",
  isClaimantAnEmployeeOfCore: "Core Employee Claimants",
  claimAddress: "Claim Job Site Address",
  incidentAddress: "Incident Address",
  incidentStatus: "Incident Status",
  fleetName: "Fleet Name",
  driverName: "Driver Name",
  incidentReport: "Incident Report",
  NYPD: "NYPD",
  FDNY: "FDNY",
  PARAMEDICS: "PARAMEDICS",
  OSHA: "OSHA",
  dobBestSquad: "DOB / BEST SQUAD",
  incidentWitnessed: "Incident Witnessed",
  injuredPerson: "Injured Person",
  defectStatus: "Defect Status",
  inspectionName: "Inspection Name",
  defectPriority: "Defect Priority",
  defectName: "Defect Name",
  workOrderStatus: "Work Order Status",
  dueDate: "Due Date",
  mechanicName: "Mechanic Name",
  safetyName: "Safety Name",
  accountName: "Account Name",
  safetyApplicationCategory: "Safety Application Category",
  safetyApplicationStatus: "Safety Application Status",
  projectExecutive: "Project Executive",
  projectManager: "Project Manager",
  scheduleAddress: "Schedule Address",
  incidentCategory: "Incident Category",
  incidentName: "Incident Name",
  amountForServices: "Total Amount per Service",
  totalEstimationsAmount: "Total Estimations Amount",
  scheduleTotalProgressPercentage: "Schedule Total Progress",
  todoStatus: "Status",
  todoDueDate: "Due Date",
  todoCategory: "Category",
  todoUser: "User",
  relatedTo: "Related To",
  geoFenceInfo: "Geofence",
  companyEmployees: "Company Employees",
  jobsiteAddress: "Jobsite Address Employees",
  payrollType: "Employee Payroll Type",
  punchType: "Employee Punch Type",
  role: "Employee Role",
  jobsiteTotalCost: "Jobsite Total Cost",
  jobsiteTotalHours: "Jobsite Total Work Hours",
  jobsiteAccount: "Jobsite Client",
  jobsiteAccountType: "Jobsite Client Type",
  inspectionType: "Inspection Type",
  mechanic: "Mechanic",
  inspectionStatus: "Inspection Status",
  userRequesting: "User Requesting",
  assignedTo: "Assigned To",
  requestType: "Request Type",
  category: "Category",
  responseFrom: "Response",
};

export const pricingFields = {
  Opportunities: {
    fields: ["totalEstimationsAmount"],
    subView: "Amount",
  },
  Estimations: {
    fields: ["amount", "amountForServices"],
    subView: "Take-Off",
    pricingView: "Show Price",
  },
  Projects: { fields: ["totalEstimationsAmount"], subView: "Amount" },
};
