const SAFETY_REPORT_STYLES = {
  "safety-table > tbody > tr > td": {
    padding: "10px",
    width: "50%",
  },
  "safety-image": {
    "object-fit": "contain",
    height: "auto",
    width: "100%",
    "max-width": "300px",
    "max-height": "150px",
  },
  "safety-section-header": {
    "font-size": "16px",
    "font-weight": "bold",
    display: "block",
    "margin-top": "3rem",
  },
  "safety-section-header.no-margin": {
    "margin-top": "0",
  },
  "safety-title": {
    "font-size": "20px",
    "font-weight": "bold",
    display: "block",
    "margin-top": "3rem",
  },
};

export default SAFETY_REPORT_STYLES;
