import { v4 as uuidv4 } from "uuid";

export const getLogsFilters = (title) => [
  {
    id: uuidv4(),
    operator: "AND",
    conditions: [
      {
        id: uuidv4(),
        operator: "AND",
        column: "category",
        value: title,
        formula: "is",
      },
      {
        id: uuidv4(),
        operator: "AND",
        column: "actionType",
        value: "Upload",
        formula: "is",
      },
    ],
  },
];
