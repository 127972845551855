import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import { formatCurrency } from "../../../../utils";

export const projects = (updatedDynamicStates, IdHandler, programFields) => {
  return {
    projectStatus: () => ({
      title: "Project Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Projects",
        field: "projectStatus",
        filterType: { type: "dashboardId" },
        secondaryField: "projectName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.projectName,
            value: a?.projectStatus,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Projects?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.projectStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.projectId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //projectStatus
    attorney: () => ({
      title: "Project Attorney",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Projects",
        field: "attorney",
        secondaryField: "projectName",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.projectName,
            value: a?.attorney,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.projectId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //attorney
    expeditor: () => ({
      title: "Project Expeditor",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Projects",
        field: "expeditor",
        secondaryField: "projectName",
        // field: "projectName",
        // secondaryField: "expeditor",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.projectName,
            value: a?.expeditor,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.projectId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
    projectManager: () => ({
      title: "Project Manager",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Projects",
        field: "projectManager",
        secondaryField: "projectManager",
        // field: "projectName",
        // secondaryField: "expeditor",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.projectManager,
            value: a?.projectManager,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.projectId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    totalEstimationsAmount: () => ({
      title: "Total Estimations Amount for Projects",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Projects",
        field: "totalEstimationsAmount",
        secondaryField: "projectName",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        noTab: true,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map(
          ({ services = [], projectName = "", projectId = "" }) => {
            return {
              label: projectName,
              value: formatCurrency(
                parseFloat(
                  getTotalServicesPrice(Object?.values(services)?.flat())
                )
              ),
              color: "#ECDE7D",
              id: projectId,
            };
          }
        ),
      },
      id: IdHandler,
      width: "790px",
    }),

    geoFenceInfo: () => ({
      title: "Geofence Quantity For Projects",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Projects",
        field: "geoFenceInfo",
        secondaryField: "projectAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.projectAddress,
            value: a?.geoFenceInfo?.length.toString(),
            color: "#ECDE7D",
            id: a?.projectId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
    //expeditor
  }; //Projects
};
