import { UserAddOutlined } from "@ant-design/icons";
import { RenderDynamicComponents } from "../../../components/Header/forms/Components";

const CreateNewUser = ({ populatedFields }) => {
  return (
    <div className="CreateNewUser">
      <UserAddOutlined style={{ fontSize: "24px" }} />
      <h2>Create a New User</h2>
      <p>Please fill all the fields below.</p>
      <div className="formInputs">
        {RenderDynamicComponents(populatedFields)?.filter(
          (item) => Number(item.key) < 6
        )}
      </div>
    </div>
  );
};

export default CreateNewUser;
