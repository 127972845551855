import React, { useState, useRef, useEffect } from "react";
import FooterInvoice from "./../footer_invoice/footer_invoice";
import HeaderInvoice from "./../header_invoice/header_invoice";
import parse from "html-react-parser";
import styleModule from "./invoiceTable.module.scss";
import { styleInvoice, styleSettings } from "./table_invoice_style_settings";
import { formatCurrency } from "../../../../../../../../utils/formatCurrency";
const _ = require("lodash");

const TableInvoice = ({ myProps, searchInvoicePreviewText }) => {
  const [renderModule, setRenderModule] = useState("");
  const tableColumnLength = useRef(39);
  const tableRowsLength = useRef(52);
  const paperTotals = useRef([]);

  const searchText = searchInvoicePreviewText;
  const printStyle = myProps.mode === "printMode" ? styleInvoice : {};
  const viewStyle = myProps.mode === "viewMode" ? styleModule : {};

  useEffect(() => {
    countInvoiceRows();
  }, [myProps.previewCurrentInvoce]);

  useEffect(() => {
    if (document.querySelector(`.${styleModule.invoiceTableCont}`)) {
      highlightSearchedText();
    }
  }, [searchText]);

  //FIND TEXT AND HIGHLIGHT =========================
  const highlightSearchedText = () => {
    const regExVal = new RegExp(`^${searchText}`, "i");
    const parentEls = document.getElementsByClassName(
      `${styleModule.invoiceTable}`
    );

    for (let i = 0; i < parentEls.length; i++) {
      const items = parentEls[i].children[1].children;
      let childrenItems = [];
      for (let j = 0; j < items.length; j++) {
        childrenItems = [...childrenItems, ...items[j].children];
      }
      const elementsList = [
        ...parentEls[i].children[0].children,
        ...childrenItems,
      ];

      for (let j = 0; j < elementsList.length; j++) {
        if (elementsList[j].tagName === "SECTION") {
          const textArr = elementsList[j].children;
          for (let k = 0; k < textArr.length; k++) {
            const reg = new RegExp(`${searchText}`, "gi");
            if (
              searchText &&
              textArr[k].textContent
                ?.toLowerCase()
                .includes(searchText?.toLowerCase())
            ) {
              let textLine = textArr[k].textContent;
              textLine = textLine.replace(
                reg,
                `<b style="background-color: #00000030">${searchText}</b>`
              );
              textArr[k].innerHTML = textLine;
            } else {
              let textLine = textArr[k].textContent;
              textLine = textLine.replace(/<b>|<\/b>/g, "");
              textArr[k].innerHTML = textLine;
            }
          }
        } else {
          if (searchText && regExVal.test(elementsList[j].textContent)) {
            let textLine = elementsList[j].textContent;
            textLine = textLine.replace(
              regExVal,
              `<b style="background-color: #00000030">${searchText}</b>`
            );
            elementsList[j].innerHTML = textLine;
          } else {
            let textLine = elementsList[j].textContent;
            textLine = textLine.replace(/<b>|<\/b>/g, "");
            elementsList[j].innerHTML = textLine;
          }
        }
      }
    }
  };
  //FIND TEXT AND HIGHLIGHT =========================

  //CALCULATE TOTAL ==================
  const calculateInvoiceTotal = (tablesPdf) => {
    let amount = 0;
    let tax;
    let taxVal = 0;
    let total = 0;
    tablesPdf.forEach((table) => {
      if (table.amount) {
        amount += parseFloat(table.amount);
        tax = table.tax ? 8.875 : 0;
        taxVal += parseFloat(parseFloat(table.amount * tax) / 100);
      }
    });
    total = formatCurrency(amount + taxVal);
    amount = formatCurrency(amount);
    taxVal = formatCurrency(taxVal);

    paperTotals.current.push({
      subtotal: amount,
      tax: tax,
      taxVal: tax ? taxVal : 0,
      total: total,
    });
  };
  //CALCULATE TOTAL ==================

  //CREATE TABLE =====================
  const createInvoiceTable = (tablesPdf, ind) => {
    calculateInvoiceTotal(tablesPdf);
    return (
      <div
        key={ind}
        style={printStyle.invoiceTable}
        className={viewStyle.invoiceTable}
      >
        <div style={printStyle.headerTable} className={viewStyle.headerTable}>
          <h3 style={printStyle.headerItem}>Service</h3>
          <h3 style={printStyle.headerItem}>Description</h3>
          <h3 style={printStyle.headerItem}>Est Amt</h3>
          <h3 style={printStyle.headerItem}>Prior Amt</h3>
          <h3 style={printStyle.headerItem}>Amt.DueNow</h3>
        </div>
        <div
          style={printStyle.datatableCont}
          className={viewStyle.datatableCont}
        >
          {tablesPdf.map((d, i) => {
            return (
              <React.Fragment key={`paperPdf${i}`}>
                {d.tableItem}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };
  //CREATE TABLE =====================

  //CREATE ITEM DATA =================
  const createInvoiceItemData = (keyData, data, itemsLine, pass) => {
    return (
      <div
        key={keyData}
        style={printStyle.itemTable}
        className={viewStyle.itemTable}
      >
        <p style={printStyle.itemDataStart} className={viewStyle.itemDataStart}>
          {pass ? data.name : ""}
        </p>
        <section style={printStyle.itemData}>{itemsLine}</section>
        <p style={printStyle.itemDataEnd}>
          {pass ? formatCurrency(data.scopeAmount) : ""}
        </p>
        <p style={printStyle.itemDataEnd}>
          {pass
            ? formatCurrency(data.scopeAmount - data.maxSuggestedAmount || 0)
            : ""}
        </p>
        <p style={printStyle.itemDataEnd}>
          {pass ? formatCurrency(data.amount) : ""}
        </p>
      </div>
    );
  };
  //CREATE ITEM DATA =================

  //COUNT ROWS =======================
  const countInvoiceRows = () => {
    let tablesPdf = [];
    let itemTable = [];
    paperTotals.current = [];

    //Parse html it first with html-react-parser first
    const fetchTextFromReactComponents = (html) => {
      let stringFormat = "";
      html?.props?.children ? (html = html?.props?.children) : html;
      html?.forEach ? html : (html = [html]);
      html.forEach((el) => {
        const innerHTML = el?.props?.children;
        innerHTML?.forEach
          ? innerHTML.forEach((el, index) => {
              el?.props?.children
                ? typeof el?.props?.children === "object"
                  ? (stringFormat += fetchTextFromReactComponents(
                      el?.props?.children
                    ))
                  : typeof el === "object"
                  ? (stringFormat += fetchTextFromReactComponents(el))
                  : typeof el?.props?.children === "string" ||
                    el?.props?.children === "number"
                  ? (stringFormat += el?.props?.children + "\n")
                  : ""
                : typeof el === "object"
                ? (stringFormat += fetchTextFromReactComponents(el))
                : typeof el === "string" || typeof el === "number"
                ? (stringFormat += el + "\n")
                : "";
            })
          : typeof el === "string" || typeof el === "number"
          ? (stringFormat += el + "\n")
          : typeof innerHTML === "string" || typeof innerHTML === "number"
          ? (stringFormat += innerHTML)
          : typeof innerHTML === "object"
          ? (stringFormat += fetchTextFromReactComponents(innerHTML))
          : "";
      });
      return stringFormat;
    };
    //CREATE LINES ===================================================
    const createInvoiceTableLines = (strD) => {
      //Extracting plain text from description , which is in HTML format
      const alternativeString = parse?.(strD.description || "");
      let stringDescription = fetchTextFromReactComponents(alternativeString);

      let lines = [];
      let ind = 0;

      while (stringDescription) {
        ind++;
        const lineLength =
          stringDescription.indexOf(stringDescription.match(/\r\n|\r|\n/)) >
            0 &&
          stringDescription.indexOf(stringDescription.match(/\r\n|\r|\n/)) <
            tableColumnLength.current
            ? stringDescription.indexOf(stringDescription.match(/\r\n|\r|\n/))
            : tableColumnLength.current;

        lines.push(
          <p
            key={`${strD.invoiceNumber}${ind}`}
            style={myProps.mode === "printMode" ? styleInvoice.lineText : {}}
          >
            {stringDescription.slice(0, lineLength)}
          </p>
        );
        stringDescription = stringDescription.slice(lineLength + 1);
      }
      return lines;
    };
    //CREATE LINES ===================================================

    for (let i = 0; i < myProps.previewCurrentInvoce.invoiceItems.length; i++) {
      const itemInvoice = myProps.previewCurrentInvoce.invoiceItems[i];
      let linesText = createInvoiceTableLines(itemInvoice);
      let tables = Math.ceil(linesText.length / tableRowsLength.current);
      tables = itemTable.length > 0 ? tables + 1 : tables;

      for (let j = 0; j < tables; j++) {
        if (linesText.length > 0 || true) {
          if (itemTable.length > 0) {
            let linesCount = 0;
            let oldItems = [];
            for (let k = 0; k < itemTable.length; k++) {
              linesCount += itemTable[k].linesNumber;
              oldItems.push(itemTable[k]);
            }
            let tableLines = linesText.filter(
              (d, ind) => ind < tableRowsLength.current - linesCount
            );
            if (tableLines.length + linesCount > tableRowsLength.current - 1) {
              const newTable = {
                amount: j === 0 ? itemInvoice.amount : null,
                tax: itemInvoice.tax,
                tableItem: createInvoiceItemData(
                  `${itemInvoice.invoiceNumber}${parseInt(j * 2)}`,
                  itemInvoice,
                  tableLines,
                  j === 0 ? "pass" : null
                ),
              };
              tablesPdf.push(
                createInvoiceTable(
                  [...oldItems, newTable],
                  `${itemInvoice.invoiceNumber}${parseInt(j)}`
                )
              );
              itemTable = [];
            } else {
              itemTable.push({
                id: itemInvoice?.id,
                amount: itemInvoice?.amount,
                tax: itemInvoice.tax,
                linesNumber: tableLines.length + 2,
                tableItem: createInvoiceItemData(
                  `${itemInvoice.invoiceNumber}${parseInt(j * 3)}`,
                  itemInvoice,
                  tableLines,
                  "pass"
                ),
              });
            }
            linesText = linesText.slice(tableLines.length);
          } else {
            let tableLines = linesText.filter(
              (d, ind) => ind < tableRowsLength.current
            );
            if (tableLines.length > tableRowsLength.current - 1) {
              itemTable.push({
                id: itemInvoice?.id,
                amount: j === 0 ? itemInvoice.amount : null,
                tax: itemInvoice.tax,
                tableItem: createInvoiceItemData(
                  `${itemInvoice.invoiceNumber}${parseInt(j * 4)}`,
                  itemInvoice,
                  tableLines,
                  j === 0 ? "pass" : null
                ),
              });
              tablesPdf.push(
                createInvoiceTable(
                  itemTable,
                  `${itemInvoice.invoiceNumber}${parseInt(j * 2)}`
                )
              );
              itemTable = [];
            } else {
              itemTable.push({
                id: itemInvoice?.id,
                amount: j === 0 ? itemInvoice.amount : null,
                tax: itemInvoice.tax,
                linesNumber: tableLines.length + 2,
                tableItem: createInvoiceItemData(
                  `${itemInvoice.invoiceNumber}${parseInt(j * 5)}`,
                  itemInvoice,
                  tableLines,
                  j === 0 ? "pass" : null
                ),
              });
            }
            linesText = linesText.slice(tableLines.length);
          }
        }
      }
    }
    itemTable = _.uniqBy(itemTable, "id");
    if (itemTable.length > 0) {
      tablesPdf.push(createInvoiceTable(itemTable, "outOne1"));
      itemTable = [];
    }
    setRenderModule(
      <div
        key={myProps.mode}
        id={myProps.mode === "printMode" ? "pagePDF" : ""}
        className={`${viewStyle.invoiceTableCont} printPage`}
      >
        {tablesPdf.map((pdfInvoice, i) => {
          return (
            <div
              key={`paperPDF${i}`}
              className={viewStyle.invoicePaper}
              style={printStyle.invoicePaper}
            >
              <HeaderInvoice
                {...{
                  myProps: {
                    previewCurrentInvoce: myProps.previewCurrentInvoce,
                    mode: myProps.mode,
                  },
                  searchText,
                }}
              />
              {pdfInvoice}
              <FooterInvoice
                {...{
                  myProps: {
                    pageNumber: `${i + 1}`,
                    mode: myProps.mode,
                    previewCurrentInvoce: myProps.previewCurrentInvoce,
                    paperTotals: paperTotals.current[i],
                  },
                  searchText,
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };
  //COUNT ROWS =======================

  return renderModule;
};

export default TableInvoice;
