import React, { useState, useEffect } from "react";
import { MondayButton } from "../../../../commonComponents/index";
import InvoiceModal from "../CreateInvoice/InvoiceModal";
import { API } from "aws-amplify";
import AccountingConfirmationModal from "../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import { DollarIcon } from "../../../Estimations/components/DataEntryGridModal/assets/index";
function InvoiceCreateAdapter({ projectId, invoiceCategory }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [workflowAction, setWorkflowAction] = useState({
    invoiceData: null,
    redirect: false,
  });
  const fetchProjectData = async (projectId) => {
    await API.get("projects", `/projects/${projectId}`)
      .then((res) => {
        setProjectData(res);
      })
      .catch((err) => {
        setProjectData([]);
      });
  };

  useEffect(() => {
    if (isModalVisible) {
      fetchProjectData(projectId);
    }

    // return () => {
    //   setIsModalVisible(false);
    //   setProjectData(null);
    // };
  }, [isModalVisible, projectId]);

  console.log("projectData", projectData);

  return (
    <div>
      <MondayButton
        onClick={() => setIsModalVisible(true)}
        className="mondayButtonBlue"
        Icon={<DollarIcon />}
      >
        Create Invoice
      </MondayButton>
      {isModalVisible && !!projectData && (
        <InvoiceModal
          {...{
            projectData: { projectId: projectId, ...projectData },
            outerSelectedCategory: invoiceCategory,
            // invoiceData: iDp, // If is is given then modal is in Edit Mode
            // accountData: aDp, //It is given when when in edit mode
            setData: (params) => {
              console.log("setDataParams", params);
            }, //Reflects changes if an invoice is updated/created
            // setSelectedData = () => {}, //Reflect changes in Invoice Drawer when invoice updates, || given when invoiceData is given
            handleExit: () => {
              setIsModalVisible(false);
            },
            createdInvoiceRetriever: (params) => {
              setWorkflowAction((prev) => ({
                invoiceData: params,
                redirect: true,
              }));
              setIsModalVisible(false);

              console.log("freshly created invoice", params);
            },
            // fetchAndSetInvoices = () => {}, //It is given when the invoice is in > Projects / View / Accounting /Invoicing and auto fills some inputs from the projects
          }}
        />
      )}
      <AccountingConfirmationModal
        {...{
          title: "Invoice Created",
          darkMode: true,
          text: "Invoice has been created successfully",
          visible: workflowAction?.redirect,
          // setIsModalVisible: () => {},
          confirmButtonText: "View Invoice",
          onConfirm: () => {
            window.open(
              `/invoices/${workflowAction?.invoiceData?.invoiceId}`,
              "_blank"
            );
            setWorkflowAction((prev) => ({
              invoiceData: null,
              redirect: false,
            }));
          },
          onCancel: () => {
            setWorkflowAction((prev) => ({
              invoiceData: null,
              redirect: false,
            }));
          },
        }}
      />
    </div>
  );
}

export default InvoiceCreateAdapter;
