export const dispatching = (
  circleProgressPicker,
  IdHandler,
  updatedDynamicStates
) => {
  return {
    fleetName: () => ({
      title: "Dispatch Fleet Name",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Dispatching",
        field: "fleetName",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.fleetName?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.fleetName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //fleetName
    driverName: () => ({
      title: "Dispatch DriverName",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Dispatching",
        field: "driverName",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.driverName?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.driverName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //driverName
    dispatchedBy: () => ({
      title: "Dispatched By",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Dispatching",
        field: "dispatchedBy",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter((a) =>
            a?.dispatchedBy?.name
              ? a?.dispatchedBy?.name?.toLowerCase()
              : a?.dispatchedBy?.toLowerCase() ===
                circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter((a) =>
          a?.dispatchedBy?.name
            ? a?.dispatchedBy?.name?.toLowerCase()
            : a?.dispatchedBy?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //dispatchedBy
  }; //Dispatching
};
