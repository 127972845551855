import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
import { boroughs } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const leads = ({ programFields, tables }) => {
  return {
    leadStatus: () => ({
      labels: programFields?.find((a) => a?.fieldName == "Lead Status Types")
        ?.fieldOptions,
      data: programFields
        ?.find((a) => a?.fieldName == "Lead Status Types")
        ?.fieldOptions?.map(
          (status) =>
            tables?.Leads?.filter(
              (a) => a?.leadStatus?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "Status colors")
        ?.fieldOptions?.Leads?.map((a) => a.statusColor),
    }),
    leadAddress: () => ({
      labels: boroughs,
      data: boroughs?.map(
        (status) =>
          tables?.Leads?.filter(
            (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
          )?.length
      ),

      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "NY Boroughs")
        ?.fieldOptions?.map((a) => a.boroughColor),
    }),

    projectExecutive: () => ({
      labels: uniq(
        tables?.Leads?.map((lead) => lead?.projectExecutive)
      )?.filter(Boolean),
      data: Object.values(
        repeatedElementSum(tables?.Leads, "projectExecutive")
      ),
      backgroundColor: uniq(tables?.Leads?.map((a) => getRandomColor())),
    }),
  }; //Leads
}; //Leads
