export let usersActivityWS = new WebSocket(
  "wss://leadmanager-sockets.ue.r.appspot.com/ws/users-activity"
  // "ws://localhost:8080/ws/users-activity"
);

export const newUsersActivityWS = () => {
  return new Promise((resolve) => {
    const newConn = new WebSocket(
      "wss://leadmanager-sockets.ue.r.appspot.com/ws/users-activity"
      // "ws://localhost:8080/ws/users-activity"
    );

    newConn.onopen = () => {
      usersActivityWS = newConn;
      resolve(newConn);
    };

    newConn.onclose = () => {
      console.log("users-activity is closed unexpectedly!");
      resolve(false);
    };

    // newConn.onerror = (error) => {
    //   console.log(`newUsersActivityWS WebSocket error: `, error);
    //   resolve(false);
    // };
  });
};
