import axios from "axios";

const uriConfig = {
  locale:
    process.env.NODE_ENV === "production"
      ? "http://localhost:8080/api/batchPost/prod"
      : "http://localhost:8080/api/batchPost",
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/api/batchPost/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/api/batchPost",
};

/**
 * Function used to create multiple records at once
 * supports up to 100 new records.
 *
 * ======= IMPORTANT =======
 * You need to give the id manually in the body
 * of the new item you want to create
 *
 * @typedef BatchPostParam
 * @property {string} tableName
 * @property {Record<string, any>[]} items
 *
 * @param {BatchPostParam} param
 */
async function bathPost({ items, tableName }) {
  return axios.post(uriConfig.online, { items, tableName });
}

export default bathPost;
