import { checkIfServiceIsHoist } from "../../../../DataEntryGrid/models/Service";

export const getServices = ({ service }) => {
  if (checkIfServiceIsHoist(service)) {
    let temp = {};
    Object.entries(HOIST_SERVICES)?.map(
      ([key, value]) => (temp[key] = value(service))
    );
    return temp;
  } else {
    return {};
  }
};

const HOIST_SERVICES = {
  "Is Scope": ({ service }) => (service?.isScope ? "Is Scope" : "Not Scope"),
  "Is Taxable": ({ service }) =>
    service?.isTaxable ? "Is Taxable" : "Not Taxable",
  Label: ({ service }) => service?.label || "Unknown",
  "Tax Rate": ({ service }) => `${parseFloat(service?.taxRate || 0) * 100}%`,
  "Rent Rate": ({ service }) => `${parseFloat(service?.rentRate || 0) * 100}%`,
};
