import { formatCurrency } from "../../../../../../../../../../../../../utils";

import { message } from "antd";
import moment from "moment";
import dayjs from "dayjs";

export const handleAppliedAmountCellChanger = ({
  selectedService,
  thisElevation,
  fakeRentals,
  thisPli,
  params,
}) => {
  let changingValue = undefined;

  try {
    changingValue = eval(params.newValue);
  } catch (e) {
    changingValue = 0;
    console.error(e);
  }
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);

  const rentalDetailsData = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.find(({ id }) => id === thisPli)?.rentalDetails;

  //here we find how many days are in this pli
  const howManyDays = +(
    dayjs
      ?.duration(
        dayjs(rentalDetailsData?.endDate)?.valueOf() -
          dayjs(rentalDetailsData?.startDate)?.valueOf()
      )
      ?.asDays() + 1
  )?.toFixed(0);
  console.log("howManyDays", howManyDays);
  const howManyWeeks = howManyDays / 7;
  const maxValue =
    fakeRentals?.services
      ?.find(({ label }) => label === selectedService)
      ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
      ?.items?.find(({ id }) => id === thisPli)?.maxWeeklyAmount * howManyWeeks;
  // rentalDetailsData?.details?.reduce(
  // 	(acc, curr) => acc + curr?.days?.length,
  // 	0
  // );
  //here we find the max value we can apply in this pli
  // const maxValue = +((rentalDetailsData?.details[0].weeklyAmount / 7) * howManyDays)?.toFixed(2);

  //this const calculates the new weekly amount
  let appliedAmount =
    params?.colDef?.field !== "lock"
      ? isNaN(changingValue) //if the value from user is not a number return 0 and a message to inform the user for the problem
        ? (() => {
            message?.warning("Invalid input");
            return 0;
          })()
        : //if the value from user is less than calculated minValue return minValue and a message to inform the user for the problem
        changingValue < 0
        ? (() => {
            message?.warning(`Input is less than 0!`);
            return 0;
          })()
        : //if the value from user is greater than currentMaxValue per week return the currentMaxValue per week and a message to inform the user for the problem
        changingValue > maxValue
        ? (() => {
            message?.warning(
              `Input is greater than ${formatCurrency(maxValue)}`
            );
            return changingValue;
          })()
        : //else if we dont have any of those problems divide the input form user
          changingValue
      : undefined;

  //here we calculate how match is the difference that appears from rounding(it will be added to the last day of the last week)
  const difference = +(
    appliedAmount -
    +(appliedAmount / howManyDays)?.toFixed(2) * howManyDays
  )?.toFixed(2);

  return {
    ...fakeRentals,
    services: fakeRentals?.services?.map((serviceData) =>
      //here we check if this object is the object of the selected service so we can edit it
      serviceData?.label === selectedService
        ? {
            ...serviceData,
            serviceOptions: serviceData?.serviceOptions?.map((elevationData) =>
              //here we check if this object is the object of the elevation that we are trying to modify so we can edit it
              elevationData?.elevationId === thisElevation
                ? {
                    ...elevationData,
                    items: elevationData?.items?.map((pli) =>
                      //here we check if this object is the object of the pli that we are trying to modify so we can edit it
                      pli?.id === thisPli
                        ? {
                            ...pli,
                            //here we set the pli with the new calculated applied amount
                            appliedAmount: +appliedAmount?.toFixed(2),
                            lock: true,
                            rentalDetails: {
                              ...pli?.rentalDetails,
                              details: pli?.rentalDetails?.details?.map(
                                (week, index, arr) =>
                                  //if this is the last week adds the difference to the last day of this week
                                  arr?.length - 1 === index
                                    ? {
                                        ...week,
                                        //here we set the applied amount for this week to the daily(applied amount from user/the number of days there are) amount times how many days are on that week + the difference that is created from subtracting
                                        weeklyAmount:
                                          +(
                                            (appliedAmount / howManyDays) *
                                            week?.days?.length
                                          )?.toFixed(2) + difference,
                                        lock: false,
                                        days: week?.days?.map(
                                          (day, index, arr) =>
                                            //if this is the last day of this week adds the difference  here
                                            arr?.length - 1 === index
                                              ? {
                                                  ...day,
                                                  //here we set the applied amount for this day to the daily(applied amount from user/the number of days there are) + the difference that is created from subtracting
                                                  dailyAmount:
                                                    +(
                                                      appliedAmount /
                                                      howManyDays
                                                    )?.toFixed(2) + difference,
                                                }
                                              : //if this is not the last day return only the daily amount
                                                {
                                                  ...day,
                                                  //here we set the applied amount for this day to the daily(applied amount from user/the number of days there are)
                                                  dailyAmount: +(
                                                    appliedAmount / howManyDays
                                                  )?.toFixed(2),
                                                }
                                        ),
                                      }
                                    : //if this not the last week change only the weekly amount and the daily amount
                                      {
                                        ...week,
                                        //here we set the applied amount for this week to the daily(applied amount from user/the number of days there are) amount times how many days are on that week
                                        weeklyAmount: +(
                                          (appliedAmount / howManyDays) *
                                          week?.days?.length
                                        )?.toFixed(2),
                                        lock: false,
                                        days: week?.days?.map((day) => ({
                                          ...day,
                                          //here we set the applied amount for this day to the daily(applied amount from user/the number of days there are)
                                          dailyAmount: +(
                                            appliedAmount / howManyDays
                                          )?.toFixed(2),
                                        })),
                                      }
                              ),
                            },
                          }
                        : //if this is not the pli that we are trying to change return it unchanged
                          pli
                    ),
                  }
                : //if this is not the elevation that we are working on return it unchanged
                  elevationData
            ),
          }
        : //if this is not the selected service return it unchanged
          serviceData
    ),
  };
};

function getWeeksDiff(startDate, endDate) {
  console.log(
    "startdata",
    moment(startDate).format("DD/MM/YYYY"),
    moment(endDate).format("DD/MM/YYYY")
  );
  // const msInWeek = 1000 * 60 * 60 * 24 * 7;
  // return (Math.abs(endDate- startDate) / msInWeek).toFixed(1);
  if (startDate && endDate) {
    const msInWeek = moment(endDate).diff(moment(startDate), "days");
    return (Math.abs(msInWeek) / 7).toFixed(1);
  } else return 0;
}
