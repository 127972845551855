import { calculateTotalPriceForService } from "../../../../../../Accounting/components/utilities";
import { formatCurrency } from "../../../../../../utils";
import { calculatePriceForIncludedRentals } from "../../NewChargeItem/utils";
import { getRetainages } from "../../../../Applications/ApplicationView/components/Header/Components/ControlPanel/utils";

const colors = ["#30973A", "#EEB522", "#4662F2"];

export const RequisitionDescription = ({
  groups,
  selectedGroup,
  includedRentals,
}) => {
  const {
    information: description,
    data,
    ...requisition
  } = groups?.find(({ id }) => id === selectedGroup);
  const retainageAmountFromPrevious = getRetainages({
    fakeApplication: requisition,
    applications: groups,
  })?.PREV_RENTAL_RETAINAGE;

  return (
    <div className="GroupContainer">
      {description?.description?.map(({ label, estimationId, events }, key) => {
        return (
          <div className="groupContainer">
            <div
              key={key}
              style={{}}
              className="InformationSelectedGroupContainer"
            >
              <div
                className="serviceTitle"
                style={{ color: colors[((key + 1) % 3) - 1] }}
              >
                {label}
              </div>
              {events.map((el) => (
                <div className="InformationSelectedGroupContainerElevations">
                  <div className="elevationInformation">{el}</div>
                </div>
              ))}
              <b>
                Total:{" "}
                {formatCurrency(
                  calculateTotalPriceForService(
                    data?.find(
                      ({ label: L, estimationId: eID }) =>
                        L === label && eID === estimationId
                    ),
                    "applications"
                  ).amount
                )}
              </b>
            </div>
            {key + 1 !== description?.description?.length && (
              <div className="lineDivider"></div>
            )}
          </div>
        );
      })}
      {includedRentals?.length > 0 && (
        <div className="groupContainer">
          <div
            className="lineDivider"
            style={{ marginLeft: -5, marginRight: 10 }}
          ></div>
          <div className="InformationSelectedGroupContainer">
            <div
              className="serviceTitle"
              style={{
                color: colors[(description?.description?.length % 3) - 1],
              }}
            >
              Included Rentals
            </div>
            {includedRentals.map(({ rentalNumber, rentalStatus }) => (
              <div className="InformationSelectedGroupContainerElevations">
                <div className="elevationInformation">
                  No.{rentalNumber} | Status: {rentalStatus}
                </div>
              </div>
            ))}
            <b>
              Total:{" "}
              {formatCurrency(
                calculatePriceForIncludedRentals(includedRentals)
              )}
            </b>
          </div>
        </div>
      )}
      {retainageAmountFromPrevious > 0 && (
        <div className="groupContainer">
          <div
            className="lineDivider"
            style={{ marginLeft: -5, marginRight: 10 }}
          ></div>
          <div className="InformationSelectedGroupContainer">
            <div
              className="serviceTitle"
              style={{
                color: colors[(description?.description?.length % 3) - 1],
              }}
            >
              Previous Included Rentals
            </div>
            <b>Total: {formatCurrency(retainageAmountFromPrevious)}</b>
          </div>
        </div>
      )}
    </div>
  );
};
