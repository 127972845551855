import { formatCurrency } from "../../../../utils";
import { categoriesName } from "../../calculatingFunctions";

export const invoices = ({ tables, programFields }) => {
  const data = tables?.invoices?.sort(
    (a, b) =>
      a?.projectName?.localeCompare(b?.projectName) ||
      a?.invoiceNumber - b?.invoiceNumber
  );

  return {
    invoiceStatus: () => ({
      statusData: data?.map((a) => {
        return {
          label: `${a?.projectName} - InvoiceNr ${a?.invoiceNumber}`,
          value: [
            ...new Set(
              a?.invoiceItems?.map((item) => categoriesName[item?.category])
            ),
          ]?.toString(),
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status of Invoices")
              ?.fieldOptions?.find(
                (b) => b?.statusName === categoriesName[a?.invoiceStatus]
              )?.statusColor || "#6D8299",
          date: a?.createdAt,
          custom: [
            {
              "Total Amount": {
                "Invoice Amount With Tax": formatCurrency(
                  a?.totalInvoiceAmount || 0
                ),
                "Invoice Amount Without Tax": formatCurrency(a?.subtotal || 0),
                "Total Tax": formatCurrency(
                  (a?.totalInvoiceAmount || 0) - (a?.subtotal || 0)
                ),
              },
            },
          ],
        };
      }),
    }),
  };
};
