import { formatCurrency } from "../../../../../../../../../../../utils/formatCurrency";
import "./AmountLabel.scss";
import AnimatedNumber from "react-animated-number";

const AmountLabel = ({ text, amount }) => {
  return (
    <div className="payment-amount-label">
      <label>{text}</label>
      <label className="label-amount">
        <AnimatedNumber
          style={{
            transition: "0.8s ease-out",
            fontSize: 20,
            fontWeight: 600,
            transitionProperty: "background-color, color, opacity",
          }}
          value={amount || 0}
          formatValue={(value) => formatCurrency(value)}
        />
      </label>
    </div>
  );
};

export default AmountLabel;
