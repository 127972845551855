import "./ActionButtons.scss";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import {
  LogsButton,
  MondayButton,
} from "../../../../../../../commonComponents";
import { DeleteIcon } from "../../../../../../DynamicView/src";
import ToDoButton from "../../../../../../ToDos/components/ToDoButton/ToDoButton";
import { ExportPdfIcon } from "../../../../../../../../assets";

const ActionButtons = ({
  isEditing,
  onDelete,
  onCancel,
  onSave,
  onGeneratePdf,
  saveButtonText = "Save Document",
  writeRight = true,
  disabled = false,
  docId = "",
  docType = "",
  saveDisabled = false,
}) => {
  return (
    <div
      className={`docViewActionButtons ${
        !isEditing || !writeRight ? "notEditing" : ""
      }`.trim()}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div style={{ display: "flex", gap: 10 }}>
        <MondayButton
          {...{
            className: "mondayButtonRed",
            Icon: <CloseOutlined />,
            onClick: onCancel,
            tooltipCategory: "Documentations",
            tooltipKey: "cancel",
          }}
        >
          Cancel
        </MondayButton>
        {!!isEditing && !!writeRight && (
          <MondayButton
            {...{
              className: "deleteButton",
              Icon: <DeleteIcon />,
              onClick: onDelete,
              disabled,
              tooltipCategory: "Documentations",
              tooltipKey: "delete",
            }}
          >
            Delete Document
          </MondayButton>
        )}
        {!!isEditing && !!writeRight && (
          <LogsButton
            {...{
              logsTitle: "Documentation Logs",
              buttonLabel: "Documentation Log",
              filtersObj: { recordId: docId },
              tooltipCategory: "Documentations",
              tooltipKey: "showLogs",
            }}
          />
        )}
        {!!isEditing && !!writeRight && (
          <ToDoButton
            {...{
              recordId: docId,
              recordName: docType,
              tooltipCategory: "Documentations",
            }}
          />
        )}
        <div>
          <MondayButton
            className="mondayButtonBlue"
            Icon={<ExportPdfIcon />}
            onClick={onGeneratePdf}
          >
            Export to pdf
          </MondayButton>
        </div>
      </div>

      {!!writeRight && (
        <MondayButton
          {...{
            className: "saveButton",
            Icon: <CheckOutlined />,
            onClick: onSave,
            disabled: saveDisabled || disabled,
            tooltipCategory: "Documentations",
            tooltipKey: "save",
          }}
        >
          {saveButtonText}
        </MondayButton>
      )}
    </div>
  );
};

export default ActionButtons;
