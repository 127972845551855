import { SettingOutlined } from "@ant-design/icons";

import MondayButton from "../../MondayButton/MondayButton";
import { Close } from "../../../SidebarPages/DynamicView/src";
import { LeftArrow, RightArrow } from "../../../SidebarPages/BasePage/src";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";

import styles from "./Footer.module.scss";

const Footer = ({
  onClick,
  onAutoCreationClick,
  onCancel,
  onNextClick = () => {},
  disabled,
  showNext,
  isAutomation,
  back,
  onBackClick = () => {},
  disabledAutomation,
}) => {
  return (
    <div className={styles["flex-container"]}>
      {!back ? (
        <MondayButton
          className="mondayButtonRed"
          Icon={<Close />}
          onClick={!isAutomation ? onCancel : onAutoCreationClick}
        >
          {!isAutomation ? "No Step" : "Cancel Automation"}
        </MondayButton>
      ) : (
        <MondayButton
          className="mondayButtonBlue"
          Icon={<LeftArrow />}
          onClick={onBackClick}
        >
          Back
        </MondayButton>
      )}
      <div className={styles["flex-container"]}>
        {showNext ? (
          <MondayButton
            Icon={<RightArrow />}
            onClick={onNextClick}
            disabled={disabled}
            id="nextButton"
          >
            Next
          </MondayButton>
        ) : (
          <>
            {!isAutomation && (
              <MondayButton
                Icon={<SettingOutlined />}
                onClick={onAutoCreationClick}
                disabled={disabledAutomation}
              >
                Auto Steps
              </MondayButton>
            )}
            <MondayButton
              Icon={<TickIcon />}
              onClick={onClick}
              disabled={disabled}
              id="submitButton"
            >
              {!isAutomation ? "Submit Manually" : "Submit"}
            </MondayButton>
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
