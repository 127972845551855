import uniq from "lodash/uniq";

export const dispatching = ({ tables }) => {
  const allDrivers = tables?.Dispatching?.map((a) =>
    a?.routes?.map((e) => e?.driverName)
  )?.flat();
  return {
    fleetName: () => ({
      content: uniq(tables?.Dispatching?.map((a) => a?.fleetName))?.map(
        (status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Dispatching?.filter((a) => a?.fleetName === status)
              .length /
              tables?.Dispatching?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Dispatching?.filter((a) => a?.fleetName === status)
            .length,
        })
      ),
    }), //fleetName
    driverName: () => ({
      content: uniq(allDrivers)?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (allDrivers?.filter((a) => a === status).length /
            allDrivers?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status,
        done: allDrivers?.filter((a) => a === status).length,
      })),
    }), //driverName
    dispatchedBy: () => ({
      content: uniq(
        tables?.Dispatching?.map((a) =>
          a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
        )
      )?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Dispatching?.filter((a) =>
            a?.dispatchedBy?.name
              ? a?.dispatchedBy?.name
              : a?.dispatchedBy === status
          ).length /
            tables?.Dispatching?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status,
        done: tables?.Dispatching?.filter((a) =>
          a?.dispatchedBy?.name
            ? a?.dispatchedBy?.name
            : a?.dispatchedBy === status
        ).length,
      })),
    }), //dispatchedBy
  }; //Dispatching
};
