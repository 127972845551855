import broadcastNotification from "../../../../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotification } from "../../../../../../../../utils/notificationHelpers";

export function sendEmailNotification({
  userConfiguration,
  authenticatedUser,
  invoiceData,
  saveAddedLogs = () => {},
}) {
  const { invoiceId, invoiceNumber } = invoiceData || {};
  broadcastNotification(
    "42",
    "onInvoiceEmail",
    [
      {
        common: userConfiguration?.nameOfUser,
      },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: authenticatedUser?.sub,
        cognitos: getCognitosForNotification(userConfiguration, []),
      },
    ],
    invoiceId
  );

  saveAddedLogs({
    recordId: invoiceId,
    recordName: `Invoice #${invoiceNumber}`,
    actionType: "Email",
    category: "Invoices",
    topic: `Invoice #${invoiceNumber}`,
    currentData: { User: "Sent an email for this record" },
  });
}
