import ServiceContent from "./ServiceContent/ServiceContent";
import { useStateContext } from "../../../Context/Context";
import ServiceHeader from "./ServiceHeader/ServiceHeader";
import ServiceFooter from "./ServiceFooter/ServiceFooter";
import "./service.scss";

import { useSelector } from "react-redux";
import ServiceCredit from "./ServiceCredit/ServiceCredit";

const Service = ({ childKey, service }) => {
	const [{ proposedTypeOfWork }] = useSelector((state) => [state.proposedTypeOfWork]);

	//here we get the color of the service from proposed type of work
	const color =
		proposedTypeOfWork?.find((a) => a?.workName?.toLowerCase() === service?.label?.toLowerCase())
			?.colorCode || "gray";

	// Context state & dispatch
	const { darkMode } = useStateContext();

	return (
		<div
			style={{ boxShadow: `0px 0px 6px 3px ${color}` }}
			className={
				darkMode
					? "application-service-list-service-dark"
					: "application-service-list-service-light"
			}
			key={childKey}
		>
			<ServiceHeader {...{ service, color }} />
			<ServiceContent {...{ service, color }} />
			<ServiceFooter {...{ service }} />
		</div>
	);
};

export default Service;
