import types from "../../types";

const initialState = {
  loggedOnTasks: [],
};

const loggedOnTasksReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.LOGGED_ON_TASKS:
      return {
        ...state,
        loggedOnTasks: payload,
      };
    case types.UPDATE_LOGGED_TASKS:
      return {
        ...state,
        loggedOnTasks: state.loggedOnTasks.map((task) =>
          task.taskId === payload.taskId ? payload.task : task
        ),
      };
    case types.UPDATE_TASKS:
      return { state };
    default:
      return state;
  }
};

export default loggedOnTasksReducer;
