import { redirectToRental } from "../../../../../../../../../../Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/IncludeRentals/includeRentalsFunctions";
import {
  existingRentalsHandler,
  findingConflictingRangesHandler,
} from "./dateCollisionFunction";
import {
  Close,
  WarningTriangle,
} from "../../../../../../../../../../../../DynamicView/src";
import { MondayButton } from "../../../../../../../../../../../../../commonComponents";
import { RightArrow } from "../../../../../../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../../../../../../hooks";
import "./DateCollision.scss";

// import { useStateContext } from "../../../../../../../../Context/Context"

import { useSelector } from "react-redux";
import { useState } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import moment from "moment";

const DateCollision = ({ thisElevation, thisPli }) => {
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [darkMode] = useRedux("rentalsDarkMode");
  // const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals")
  // const { fakeRentals, selectedService, darkMode } = useStateContext()
  // const [isAutoSaving ] = useRedux("rentalsIsAutoSaving")
  // const [isWritable ] = useRedux("rentalsIsWritable")
  // const [color ] = useRedux("rentalsColor")

  const { isLoading, rentals } = useSelector((state) => state.rentals);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { projectId, rentalId } = fakeRentals;

  //this filters applications and return only those application that have id equal to project id that is open
  const data = !isLoading
    ? rentals.filter(
        (rental) =>
          rental.projectId === projectId && rental?.rentalId !== rentalId
      )
    : [];

  //here we find the start and end date from this pli in this elevation
  const { startDate, endDate } = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.find(({ id }) => id === thisPli)?.rentalDetails;

  //here we generate all datas from all rentals
  const existingRentals = existingRentalsHandler({
    data,
    selectedService,
    thisElevation,
    thisPli,
    service: fakeRentals?.services?.find(
      ({ label }) => label === selectedService
    ),
  });

  //here we generate collision information
  const findingConflictingRanges = findingConflictingRangesHandler({
    existingRentals,
    startDate,
    endDate,
  });

  const conflicts = !!startDate && !!endDate ? findingConflictingRanges : [];

  return (
    <div className="dateCollisionContainer">
      {!!conflicts?.length ? (
        <MondayButton
          className="mondayButtonRed dateCollisionModalButton"
          onClick={() => setIsModalVisible(true)}
          Icon={<WarningTriangle />}
        >
          Date Collision
        </MondayButton>
      ) : (
        "No collisions"
      )}

      {isModalVisible && (
        <Modal
          title="Date collision"
          open={isModalVisible}
          wrapClassName={
            darkMode ? "dateConflictsModalDark" : "dateConflictsModalLight"
          }
          onCancel={() => setIsModalVisible(false)}
          closeIcon={<Close />}
          footer={
            <MondayButton
              className="mondayButtonRed"
              onClick={() => setIsModalVisible(false)}
              Icon={<Close />}
            >
              Cancel
            </MondayButton>
          }
        >
          {conflicts?.map(({ conflictingRange, rentalNumber, rentalId }) => {
            return (
              <div className="conflictsContainerDiv" key={rentalId}>
                <div className="iconCOntainer">
                  <WarningTriangle />
                </div>
                <div className="messageContainer">
                  {` Be careful! You have chosen the same rental days as in the rental ${rentalNumber}.`}
                </div>
                <div className="dateRangeContainer">
                  {`${dayjs(conflictingRange[0])?.format("MM/DD/YYYY")} to
                ${dayjs(conflictingRange[1])?.format("MM/DD/YYYY")}`}
                </div>
                <div className="redirectButton">
                  <MondayButton
                    className="mondayButtonBlue"
                    onClick={() => redirectToRental({ rentalId })}
                    Icon={<RightArrow />}
                  >
                    Rental {rentalNumber}
                  </MondayButton>
                </div>
              </div>
            );
          })}
        </Modal>
      )}
    </div>
  );
};

export default DateCollision;
