import { useState, useMemo } from "react";
import { Modal, message, Alert, Spin, Button } from "antd";
import { filterTables } from "../../../../../utils";
import { MondayButton } from "../../../../commonComponents";
import { InputComponent } from "../../../Fleet/components";
import { XIcon } from "../../../Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { preSafetyInspectionTranslateFields } from "./preSafetyInspectionTranslateFields";
import SafetyModal from "../../SafetyModal/SafetyModal";
import { useSelector } from "react-redux";

const defaultSafetyCategories = [
  "Property Damage",
  "Personal Injury",
  "Vehicle Damage",
  "Other Trade Incident",
];

/**
 * PreSafetyInspectionModal component.
 *
 * @component
 * @param {boolean} open - Determines whether the modal is open or not.
 * @param {Function} [onCancel=()=>{}] - The function to be called when the modal is canceled.
 * @param {Function} [onSafetySelected=()=>{}] - The function to be called when a safety is selected.
 * @param {string} projectId - The ID of the project.
 * @param {Function} [setVisible=()=>{}] - The function to set the visibility of the modal.
 * @returns {JSX.Element} The PreSafetyInspectionModal component.
 */
function PreSafetyInspectionModal({
  open,
  onCancel = () => {},
  onSafetySelected = () => {},
  projectId,
  setVisible = () => {}, //NEEDED FOR NEXT STEPS
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state?.programFields);
  const [safetyData, setSafetyData] = useState([]);
  // const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSafety, setSelectedSafety] = useState();
  const [newSafetyModalVisible, setNewSafetyModalVisible] = useState(false);

  const categories = useMemo(
    () =>
      Object.keys(
        programFields?.find((e) => e?.fieldName === "Safety Inspections")
          ?.fieldOptions
      ),
    [programFields]
  );

  const onCancelHandler = () => {
    onCancel();
    setVisible();
  };

  // useEffect(() => {
  //   message.loading({
  //     content: "Getting categories...",
  //     key: "gettingCategoriesMessage",
  //   });
  //   filterTables("programFields", "fieldName", "Safety Inspections")
  //     .then((res) => {
  //       message.destroy("gettingCategoriesMessage");
  //       setCategories(Object.keys(res[0]?.fieldOptions));
  //     })
  //     .catch((err) => {
  //       message.error({
  //         content: "Something went wrong!",
  //         key: "gettingCategoriesMessage",
  //       });
  //       console.log("Error getting data: ", err);
  //       onCancelHandler();
  //     });
  // }, []);

  /**
   * Selects a category and filters the tables based on the selected category.
   * @param {string} category - The category to be selected.
   * @returns {Promise<void>} - A promise that resolves when the tables are filtered.
   */
  async function selectCategory(category) {
    setSafetyData();
    if (defaultSafetyCategories.includes(category)) {
      filterTables("safety", "safetyApplicationCategory", category)
        .then((saf) => {
          let res = saf?.filter((e) => !!e.safetyName);
          if (projectId) {
            setSafetyData(res?.filter((el) => el?.projectId === projectId));
          } else {
            setSafetyData(res);
          }
        })
        .catch((err) => {
          message.error("Something went wrong while getting data");
          console.log("Error getting data: ", err);
          setSafetyData([]);
        });
    } else {
      setSafetyData([]);
    }
  }

  return (
    <Modal
      {...{
        open,
        onCancel: onCancelHandler,
        closable: true,
        destroyOnClose: true,
        wrapClassName: "cardLayoutModal",
        title: "Safety Inspection",
        className: `preSafetyModal ${isDarkMode && "preSafetyModalDark"}`,
        footer: null,
        maskClosable: false,
        centered: true,
        closeIcon: <XIcon />,
      }}
    >
      <div className="note-modal-content">
        <InputComponent
          showSearch
          label="Category"
          type="select"
          placeholder="Select an inspection category"
          dropdownClassName={isDarkMode && "darkDropDown"}
          onSelect={(e) => {
            console.log("Event: ", e);
            setSelectedCategory(e);
            selectCategory(e);
          }}
          allowClear={false}
          customOptions={(categories || []).map((e) => ({ value: e }))}
        />
        <Spin spinning={!safetyData && !!selectedCategory}>
          {!safetyData && !!selectedCategory ? (
            <Alert
              {...{
                message: "Searching for safety reports...",
                type: "info",
                style: { marginBottom: "1rem" },
              }}
            />
          ) : !safetyData && !selectedCategory ? (
            <></>
          ) : defaultSafetyCategories.includes(selectedCategory) ? (
            <InputComponent
              showSearch
              label="Safety Report"
              type="select"
              allowClear={true}
              onClear={() => {
                setSelectedSafety();
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                      padding: "1rem",
                    }}
                  >
                    <Button
                      onClick={() => setNewSafetyModalVisible(true)}
                      type="primary"
                    >
                      Create Safety Report
                    </Button>
                  </div>
                </>
              )}
              customOptions={(safetyData || [])?.map(
                ({ safetyApplicationId, safetyApplicationObject }) => ({
                  value: safetyApplicationId,
                  label:
                    safetyApplicationObject?.addressOfDamagedProperty ??
                    safetyApplicationObject?.addressOfInjury ??
                    safetyApplicationObject?.addressOfIncident ??
                    safetyApplicationObject?.incidentAddress ??
                    "Not Set",
                })
              )}
              placeholder="Select an incident..."
              onSelect={(e) => setSelectedSafety(e)}
            />
          ) : (
            <Alert
              {...{
                message: "No safety reports found",
                type: "error",
                style: { marginBottom: "1rem" },
              }}
            />
          )}
        </Spin>
        <div className="modalFooter">
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: () => {
                if (!!selectedCategory && !selectedSafety) {
                  onSafetySelected({
                    inspectionCategory: selectedCategory,
                    inspectionStatus: "Draft",
                  });
                } else {
                  onSafetySelected(
                    preSafetyInspectionTranslateFields({
                      category: selectedCategory,
                      safety: safetyData?.find(
                        (e) => e?.safetyApplicationId === selectedSafety
                      ),
                    })
                  );
                }
              },
              disabled: !selectedCategory,
              tooltipCategory: "Safety Inpections",
              tooltipKey: "open",
            }}
          >
            {!!selectedSafety
              ? "Open Safety Inspection Form"
              : "New Safety Inspection"}
          </MondayButton>
        </div>
      </div>
      {newSafetyModalVisible && (
        <SafetyModal
          {...{
            refreshTable(res) {
              setSafetyData(res.concat(safetyData || []));
            },
            setEdit: setNewSafetyModalVisible,
            category: selectedCategory,
          }}
        />
      )}
    </Modal>
  );
}

export default PreSafetyInspectionModal;
