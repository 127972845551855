import { Stepper } from "../../../../commonComponents";
import "./AbsoluteStepper.scss";

const AbsoluteStepper = ({ params, id }) => {
  let { currentStep, steps } = params;
  return (
    <>
      <div className="absoluteStepperContainer">
        <Stepper
          {...{
            id: "stepperStatus",
            className: "StepperCard",
            currentStep:
              typeof currentStep === "string"
                ? parseInt(currentStep)
                : currentStep,
            steps,
            stepRenderer: false,
          }}
        />
      </div>
    </>
  );
};
export default AbsoluteStepper;
