

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Popover, Divider } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

import { mapStatusColors } from "../../utils";
import { StoreType, StatusColors } from "../../types";

import "./ColorsLegendPopover.scss";

type MappedSectionType = {
  name: string;
  statuses: { status: string; color: string }[];
};

type NestedSectionType = {
  name: string;
  subsection: string;
};

type LegendProps = {
  sections: Array<string | NestedSectionType>;
  buttonSize?: number;
  buttonColor?: string;
  buttonClassName?: string;
  overlayClassName?: string;
  contentClassName?: string;
  orientationMargin?: string | number;
  customSections?: MappedSectionType[];
  dividerOrientation?: "left" | "right" | "center";
};

function ColorsLegendPopover({
  sections,
  buttonSize = 23,
  customSections = [],
  buttonClassName = "",
  overlayClassName = "",
  contentClassName = "",
  orientationMargin = 0,
  buttonColor = "#1264A3",
  dividerOrientation = "left",
}: LegendProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const { programFields } = useSelector(
    (state: StoreType) => state.programFields
  );

  const statusColors: StatusColors = useMemo(() => {
    return (
      programFields.find(({ fieldName }) => fieldName === "Status colors")
        ?.fieldOptions || {}
    );
  }, [programFields]);

  const mappedSections = useMemo(() => {
    return mapStatusColors(sections, statusColors);
  }, [statusColors, sections]);

  return (
    <Popover
      trigger={"click"}
      placement="bottom"
      overlayClassName={`stop-modal-popover-content${
        isDarkMode ? " popover-dark" : ""
      } ${overlayClassName}`}
      content={
        <div
          className={`legend-popover-content ${
            isDarkMode ? "legend-content-dark" : ""
          } ${contentClassName}`}
        >
          {customSections.concat(mappedSections).map((section) => {
            return (
              <div
                className="legend-section"
                key={`status-section-${section.name}`}
              >
                <Divider
                  orientation={dividerOrientation}
                  orientationMargin={orientationMargin}
                >
                  {section.name}
                </Divider>
                <div className="section-statuses">
                  {section.statuses.map((status) => {
                    return (
                      <div
                        className="section-status"
                        key={`status-name-${status.status}`}
                      >
                        <div className="section-status-name">
                          {status.status}
                        </div>
                        <div
                          className="section-status-color"
                          style={{ backgroundColor: status.color }}
                        ></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      }
    >
      
     {/**@ts-ignore */}
      <QuestionCircleFilled
        style={{
          fontSize: buttonSize,
          cursor: "pointer",
          color: buttonColor,
          backgroundColor: "transparent",
        }}
        className={`legend-popover-button ${buttonClassName} ${
          isDarkMode ? "legend-popover-button-dark" : ""
        }`}
      />
    </Popover>
  );
}

export default ColorsLegendPopover;
