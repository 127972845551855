{
  /*  
user manual:
this function is used to update rentals object, fakeRentals and thisElevation are required

if we want to update only one pli we have to pass thisPli prop(pli id) and it will modify only the pli with that id
if we want to update all pli with the same value  we dont have to pass thisPli prop
pliProps is an object with the keys that we want to update and the value for that keys

if we want to update only one week we have to pass thisWeek prop(week) and it will modify only the week with that id
if we want to update all weeks with the same value we dont have to pass thisWeek prop
weekProps is an object with the keys that we want to update and the value for that keys

if we want to update only one day we have to pass thisDay prop(id) and it will modify only the day with that id
if we want to update  one day with a value and all the others with another value we have to pass notOnlyThisDay prop (id of the day) 
if we want to update all days with the same value we dont have to pass thisWeek prop
daysParams has 2 cases:  1)the case when we want to modify only one day or all days we have to pass an object with the kays that we want to change ex.{keysThatWeWantToEdit:Value} 
                        2)the case when we want to modify only one day with a specific value and all the others with another value ex.{otherDays:{keysThatWeWantToEdit:Value},selectedDay:{keysThatWeWantToEdit:Value}}                       
*/
}

export const updateRental = ({
  fakeRentals, //rentals object
  selectedService, //service that we want to edit
  //this are the id of the rows that we want to edit(not required)
  thisElevation, //id of the row that we want to edit
  thisPli, //is used to check if we want to update only 1 pli,if we want to update all we dont pass this
  thisWeek, //is used to check if we want to update only 1 week,if we want to update all we dont pass this
  notOnlyThisWeek, //is used to check if we want to update only one week with a value and all the others with another value,
  thisDay, //is used to check if we want to update only 1 day,if we want to update all we dont pass this
  notOnlyThisDay, //is used to check if we want to update only one day with a value and all the others with another value,
  //is used to overwrite lock value (not required)
  overwriteLockedPli = false,
  overwriteLockedWeek = false,
  overwriteLockedDay = false,
  //object with keys that we want to change (required for rows that we want to edit)
  pliProps = {}, //object with all of pli keys that we want to edit
  detailsParams = {}, //object with all of details keys that we want to edit
  weekParams = {}, //object with all of week keys that we want to edit
  daysParams = {}, //object with all of days keys that we want to edit
}) => {
  return {
    ...fakeRentals,

    services: fakeRentals?.services?.map((serviceData) => {
      return serviceData?.label === selectedService
        ? {
            ...serviceData,
            serviceOptions: serviceData?.serviceOptions?.map((elevation) =>
              !!thisElevation
                ? elevation?.elevationId === thisElevation
                  ? {
                      ...elevation,
                      items: elevation?.items?.map((pli) =>
                        pliHandler({
                          pli,
                          thisPli,
                          thisDay,
                          thisWeek,
                          pliProps,
                          weekParams,
                          daysParams,
                          detailsParams,
                          notOnlyThisDay,
                          notOnlyThisWeek,
                          overwriteLockedPli,
                          overwriteLockedWeek,
                          overwriteLockedDay,
                        })
                      ),
                    }
                  : elevation
                : //THIS HANDLES THE DATA FOR THE PLI ARRAY
                  {
                    ...elevation,
                    items: elevation?.items?.map((pli) =>
                      pliHandler({
                        pli,
                        thisPli,
                        thisDay,
                        thisWeek,
                        pliProps,
                        weekParams,
                        daysParams,
                        detailsParams,
                        notOnlyThisDay,
                        notOnlyThisWeek,
                        overwriteLockedPli,
                        overwriteLockedWeek,
                        overwriteLockedDay,
                      })
                    ),
                  }
            ),
          }
        : serviceData
    }),
  }
}

//===================================================================
//THIS HANDLES THE DATA FOR THE PLI ARRAY
const pliHandler = ({
  pli,
  thisPli,
  thisDay,
  thisWeek,
  pliProps,
  weekParams,
  daysParams,
  detailsParams,
  notOnlyThisDay,
  notOnlyThisWeek,
  overwriteLockedPli,
  overwriteLockedDay,
  overwriteLockedWeek,
}) =>
  // //IF WE HAVE CHARGE FOR THIS PLI RETURN IT UNCHANGED
  // !!pli?.charged
  //   ? pli
  //   : //ELSE IF WE DONT HAVE CHARGE FOR THIS PLI
  //IF WE WANT TO MODIFY A SINGLE PLI WITHOUT CHANGING THE OTHERS
  !!thisPli
    ? pli?.id === thisPli
      ? {
          ...pli,
          ...pliProps,
          rentalDetails: {
            ...pli?.rentalDetails,
            ...detailsParams,
            details: pli?.rentalDetails?.details?.map((weeks) =>
              weekHandler({
                thisWeek,
                weeks,
                weekParams,
                thisDay,
                daysParams,
                notOnlyThisDay,
                notOnlyThisWeek,
                overwriteLockedWeek,
                overwriteLockedDay,
              })
            ),
          },
        }
      : pli
    : //ELSE IF WE WANT TO MODIFY ALL PLI
    !!overwriteLockedPli
    ? {
        ...pli,
        ...pliProps,
        rentalDetails: {
          ...pli?.rentalDetails,
          ...detailsParams,
          details: pli?.rentalDetails?.details?.map((weeks) =>
            weekHandler({
              thisWeek,
              weeks,
              weekParams,
              thisDay,
              daysParams,
              notOnlyThisDay,
              notOnlyThisWeek,
              overwriteLockedWeek,
              overwriteLockedDay,
            })
          ),
        },
      }
    : //ELSE IF WE WANT TO MODIFY ONLY UNLOCKED PLI
    !pli?.lock
    ? {
        ...pli,
        ...pliProps,
        rentalDetails: {
          ...pli?.rentalDetails,
          ...detailsParams,
          details: pli?.rentalDetails?.details?.map((weeks) =>
            weekHandler({
              thisWeek,
              weeks,
              weekParams,
              thisDay,
              daysParams,
              notOnlyThisDay,
              notOnlyThisWeek,
              overwriteLockedWeek,
              overwriteLockedDay,
            })
          ),
        },
      }
    : pli

//=======================================================================================
//THIS HANDLES THE DATA FOR THE WEEK ARRAY
const weekHandler = ({
  weeks,
  thisDay,
  weekParams,
  thisWeek,
  daysParams,
  notOnlyThisDay,
  notOnlyThisWeek,
  overwriteLockedWeek,
  overwriteLockedDay,
}) =>
  //  //IF WE HAVE CHARGE FOR THIS WEEK RETURN IT UNCHANGED
  // !!weeks?.charged
  //   ? weeks
  //   : //ELSE IF WE DONT HAVE CHARGE FOR THIS WEEK
  //IF WE WANT TO MODIFY A SINGLE WEEK WITHOUT CHANGING THE OTHERS
  !!thisWeek
    ? weeks?.week === thisWeek
      ? {
          ...weeks,
          ...weekParams,
          days: weeks?.days?.map((daysData) =>
            daysHandler({ daysData, thisDay, daysParams, overwriteLockedDay, notOnlyThisDay })
          ),
        }
      : weeks
    : //ELSE IF WE WANT TO MODIFY A SINGLE WEEK WITH A SPECIFIC VALUE AND ALL UNLOCKED ROWS WITH ANOTHER VALUE
    !!notOnlyThisWeek
    ? weeks?.week === notOnlyThisWeek
      ? { ...weeks, ...weekParams?.selectedDay }
      : !weeks?.lock
      ? { ...weeks, ...weekParams?.otherDays }
      : weeks
    : //ELSE IF WE WANT TO MODIFY ALL ROWS
    !!overwriteLockedWeek
    ? {
        ...weeks,
        ...weekParams,
        days: weeks?.days?.map((daysData) =>
          daysHandler({ daysData, thisDay, daysParams, overwriteLockedDay, notOnlyThisDay })
        ),
      }
    : //ELSE IF WE WANT TO MODIFY ONLY UNLOCKED ROWS
    !weeks?.lock
    ? {
        ...weeks,
        ...weekParams,
        days: weeks?.days?.map((daysData) =>
          daysHandler({ daysData, thisDay, daysParams, overwriteLockedDay, notOnlyThisDay })
        ),
      }
    : //else
      weeks

//========================================================================
//THIS HANDLES THE DATA FOR THE DAYS ARRAY
const daysHandler = ({ daysData, thisDay, daysParams, overwriteLockedDay, notOnlyThisDay }) =>
  //IF WE HAVE CREATED CHARGE FOR THIS DAY DONT MODIFY IT
  !!daysData?.charged
    ? daysData
    : //ELSE IF WE HAVE NOT CREATED CHARGE
    //IF WE WANT TO MODIFY A SINGLE DAY WITHOUT CHANGING THE OTHERS
    !!thisDay
    ? daysData?.id === thisDay
      ? { ...daysData, ...daysParams }
      : daysData
    : //ELSE IF WE WANT TO MODIFY A SINGLE DAY WITH A SPECIFIC VALUE AND ALL UNLOCKED ROWS WITH ANOTHER VALUE
    !!notOnlyThisDay
    ? daysData?.id === notOnlyThisDay
      ? { ...daysData, ...daysParams?.selectedDay }
      : !daysData?.lock
      ? { ...daysData, ...daysParams?.otherDays }
      : daysData
    : //ELSE IF WE WANT TO MODIFY ALL ROWS
    !!overwriteLockedDay
    ? { ...daysData, ...daysParams }
    : //ELSE IF WE WANT TO MODIFY ONLY UNLOCKED ROWS
    !daysData?.lock
    ? { ...daysData, ...daysParams }
    : daysData
