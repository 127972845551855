import { useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";

import { camelToTitle } from "src/utils";
import { ReportCheck } from "..";
import { MondayButton } from "src/components/commonComponents";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./ChooseTable.scss";

/**
 * @param {Object} props
 * @param {string[]} [props.preselectedFields]
 * @param {() => any} props.onCancel
 * @param {() => any} props.onConfirm
 * @param {string} [props.className]
 * @param {"TABLE"|"CHART"} [props.type="CHART"]
 * @param {string[]} props.labels
 */
function ChooseTable({
  preselectedFields = [],
  onCancel,
  onConfirm,
  className,
  type = "CHART",
  labels = [],
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedLabels, setSelectedLabels] = useState([]);

  function onChange(isChosen, label) {
    if (isChosen) {
      let tmp = [...selectedLabels];
      tmp.push(label);
      setSelectedLabels(tmp);
    } else {
      setSelectedLabels((prev) => prev.filter((e) => e !== label));
    }
  }

  let messageText = "chart";
  if (type === "TABLE") {
    messageText = "table column";
  }

  return (
    <div
      className={`reports-table-choose report-choose ${className ?? ""} ${
        isDarkMode ? "table-choose-dark" : ""
      }`}
    >
      <div className="choose-title">
        <span>New {type.charAt(0) + type.slice(1).toLowerCase()}</span>
        <div className="button-section">
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </MondayButton>
          <MondayButton
            Icon={<TickIcon />}
            onClick={() => {
              if (!selectedLabels.length) {
                message.warning({
                  content: `Careful no ${messageText} was selected! Please select one, or cancel`,
                  key: "labelSelect",
                });
                return;
              }

              onConfirm(selectedLabels);
            }}
          >
            Confirm
          </MondayButton>
        </div>
      </div>
      <div className="choose-body">
        {labels.map((label) => {
          return (
            <ReportCheck
              {...{
                id: label,
                label: camelToTitle(label),
                onChange,
                defaultChecked: preselectedFields.includes(label),
                disabled: preselectedFields.includes(label),
              }}
              key={label}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ChooseTable;
