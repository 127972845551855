import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const incidents = (incidentsData, updatedDynamicStates, IdHandler) => {
  return {
    driverName: () => ({
      title: "Incidents",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "driverName",
        title: "Driver Name",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.driverName)
        )?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          dateCreated: 1630965600000,
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.driverName === status
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.driverName === status
          ).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    fleetName: () => ({
      title: "Incidents",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "fleetName",
        title: "Driver Name",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((a) => a?.fleetName)
        )?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          dateCreated: 1630965600000,
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.fleetName === status
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.fleetNAme === status
          ).length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    incidentStatus: () => ({
      title: "Incidents",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentStatus",
        title: "Incident Status",
        subtitle: "",
        content: Object?.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "incidentStatus"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    incidentCategory: () => ({
      title: "Incidents",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentCategory",
        title: "Incident Category",
        subtitle: "",
        content: Object?.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "incidentCategory"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    incidentName: () => ({
      title: "Incidents",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentName",
        title: "Incident Name",
        subtitle: "",
        content: Object?.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "incidentName"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    projectName: () => ({
      title: "Incidents",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "projectName",
        title: "Incident Status",
        subtitle: "",
        content: Object?.entries(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])?.map(
              (a) => a?.incidentObject
            ),
            "projectName"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
