import { ReactNode, useState, useCallback, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { List, Tooltip, Radio, Dropdown } from "antd";
import { SearchOutlined, EllipsisOutlined } from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/interface";

import { StoreType, InspectionReportItem, DefectType } from "../../types";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";

import "./DefectsListCard.scss";

type DefectsListCardProps = {
  title?: ReactNode;
  className?: string;
  headerActions?: ItemType[];
  defects: DefectType[];
  inspectionReport: InspectionReportItem[];
};

function DefectsListCard(props: DefectsListCardProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const [textFilter, setTextFilter] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("Acceptable");

  const {
    defects = [],
    className = "",
    inspectionReport,
    headerActions = [],
    title = "Inspection Report",
  } = props;

  const changeTextFilter = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setTextFilter(ev.target.value ?? "");
  }, []);

  function getCount(status: string) {
    return inspectionReport?.filter(({ value }) => value === status)?.length;
  }

  return (
    <div
      className={`inspection-grid-card inspection-report-container ${
        isDarkMode ? "grid-card-dark" : ""
      } ${className ?? ""}`}
    >
      {title ? (
        <div className="card-title">
          <div className="card-title-text">{title}</div>
          {!!headerActions?.length && (
            <Dropdown
              trigger={["click", "hover"]}
              placement="bottomRight"
              overlayClassName={`header-action-dropdown ${
                isDarkMode ? "dropdown-dark" : ""
              }`}
              menu={{
                items: headerActions,
              }}
            >
              <EllipsisOutlined
                style={{ transform: "rotate(90deg)", cursor: "pointer" }}
              />
            </Dropdown>
          )}
        </div>
      ) : null}
      <div className={`card-body ${title ? "with-title" : "no-title"}`}>
        <div className="report-controller-section">
          <Radio.Group
            options={[
              {
                label: `Acceptable (${getCount("Acceptable")})`,
                value: "Acceptable",
                style: {
                  whiteSpace: "nowrap",
                  backgroundColor:
                    selectedStatus === "Acceptable" ? "#00A464" : undefined,
                  color: selectedStatus === "Acceptable" ? "white" : undefined,
                },
              },
              {
                label: `Poor (${getCount("Poor")})`,
                value: "Poor",
                style: {
                  whiteSpace: "nowrap",
                  backgroundColor:
                    selectedStatus === "Poor" ? "#E9C466" : undefined,
                  color: selectedStatus === "Poor" ? "white" : undefined,
                },
              },
              {
                label: `Defective (${getCount("Defective")})`,
                value: "Defective",
                style: {
                  whiteSpace: "nowrap",
                  backgroundColor:
                    selectedStatus === "Defective" ? "#F04F4E" : undefined,
                  color: selectedStatus === "Defective" ? "white" : undefined,
                },
              },
            ]}
            optionType="button"
            buttonStyle="solid"
            value={selectedStatus}
            defaultValue="Acceptable"
            onChange={(ev) => {
              setSelectedStatus(ev.target.value);
            }}
          ></Radio.Group>
          {/** @ts-ignore */}
          <InputComponent
            {...{
              label: "",
              required: false,
              noFormItem: true,
              onChange: _.debounce(changeTextFilter, 0.7),
              placeholder: "Search items...",
              prefix: <SearchOutlined />,
              onClear() {
                setTextFilter("");
              },
            }}
          />
        </div>
        <List
          {...{
            dataSource: inspectionReport.flatMap((item) => {
              if (
                item.value !== selectedStatus ||
                !item.label
                  .toLocaleLowerCase()
                  .includes(textFilter.toLocaleLowerCase())
              ) {
                return [];
              }
              return item.label;
            }),
            grid: {
              column: 2,
              gutter: 10,
            },
            renderItem(item: string, index) {
              const workOrder = defects.find(
                ({ defectName }) => defectName == item
              )?.workOrderId;

              return (
                <List.Item
                  actions={
                    workOrder
                      ? [
                          <Tooltip title="Go to work order" placement="top">
                            <Link
                              to={`/workOrders/${workOrder}`}
                              style={{
                                cursor: "pointer",
                                color: "#1065a4",
                                fontWeight: "500",
                              }}
                            >
                              {workOrder}
                            </Link>
                          </Tooltip>,
                        ]
                      : []
                  }
                  key={`item-${index}`}
                >
                  {item}
                </List.Item>
              );
            },
          }}
        />
      </div>
    </div>
  );
}

export default DefectsListCard;
