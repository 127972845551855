import {
  changeStatusHandler,
  optionsExtractor,
} from "./statusChangerFunctions";
import { DropdownIcon } from "../../../../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../../../../hooks";
import "./StatusChanger.scss";
import { EditableModeWrapper } from "../../../../../../../../ScheduleOfValues/ScheduleOfValueView/components/EditableModeWrapper";

// import { useDispatchContext, useStateContext } from "../../../../../../Context/Context"

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Select } from "antd";

const StatusChanger = () => {
  const { programFields } = useSelector((state) => state.programFields);
  const [isWritable] = useRedux("rentalsIsWritable");
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");

  // const { isWritable, fakeRentals } = useStateContext()
  // const dispatchContext = useDispatchContext()

  const { rentalStatus } = fakeRentals || {};
  const { isLoading } = useParams();

  //options from redux
  const options = optionsExtractor({ programFields });

  //this function is used to change rental status
  const changeStatus = (value) =>
    changeStatusHandler({
      fakeRentals,
      value,
      setFakeRentals,
      setUnchangingFakeRentals,
    });

  return (
    <div className="statusChangerContainer">
      <EditableModeWrapper {...{ isWritable }}>
        <Select
          // className="statusChangerContainer"
          disabled={!isWritable || isLoading}
          value={rentalStatus}
          options={options}
          style={{ width: 130, opacity: isWritable ? 1 : 0.62 }}
          onChange={changeStatus}
          suffixIcon={<DropdownIcon />}
        />
      </EditableModeWrapper>
    </div>
  );
};

export default StatusChanger;
