export const getObjectDepth = (obj) => {
  if (typeof obj === "object" && obj !== null && !Array.isArray(obj)) {
    let maxDepth = 0;

    for (const key in obj) {
      if (typeof obj[key] === "object") {
        // return maxDepth;

        const depth = getObjectDepth(obj[key]);
        if (depth > maxDepth) {
          maxDepth = depth;
        }
      }
    }
    return maxDepth + 1;
  }

  return 0;
};
