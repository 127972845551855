import { Modal, Select } from "antd";

import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../Roles/src";
import { MondayButton } from "../../../../../../commonComponents";

import { categoriesWithGeofence } from "../utils/categoriesWithGeofence";

const FilterModal = ({
  showFilter,
  closeResetFiels,
  handleFilterFinish,
  setFilters,
  filters,
  updatedAccess,
}) => {
  const updatedOptions = Array.isArray(updatedAccess)
    ? updatedAccess.map((opt) => ({
        label: opt,
        value: opt,
      }))
    : categoriesWithGeofence.map(({ categoryName }) => ({
        label: categoryName,
        value: categoryName,
      }));
  return (
    <Modal
      centered
      open={showFilter}
      onCancel={() => {
        closeResetFiels();
      }}
      title="Filter Geofences"
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={closeResetFiels}
          >
            Close
          </MondayButton>

          <MondayButton
            Icon={<TickIcon />}
            onClick={() => handleFilterFinish({ copyOfFilters: filters })}
          >
            Apply
          </MondayButton>
        </div>
      }
    >
      <div style={{ display: "grid", gap: "20px" }}>
        <div style={{ display: "grid", gap: "5px" }}>
          <span>Filter By Category</span>
          <Select
            mode="multiple"
            allowClear
            placeholder="Select a category to filter records"
            value={filters.byCategory || null}
            options={updatedOptions}
            onChange={(value) => {
              // setFilters({ byCategory: value });
              setFilters({ ...filters, byCategory: value });
            }}
          />
        </div>
        <div style={{ display: "grid", gap: "5px" }}>
          <span>Filter By Geofence Quantity</span>
          <Select
            allowClear
            value={filters.byQuantity || null}
            placeholder="Select a category to filter records"
            options={[
              "Higher in fence quantity",
              "Lower in fence quantity",
            ].map((op) => ({
              label: op,
              value: op,
            }))}
            onChange={(value) => {
              // setFilters({ byQuantity: value });
              setFilters({ ...filters, byQuantity: value });
            }}
          />
        </div>
        <div style={{ display: "grid", gap: "5px" }}>
          <span>Filter By Notes Quantity</span>
          <Select
            allowClear
            value={filters.byNotes || null}
            placeholder="Select a category to filter records"
            options={[
              "Higher in Notes Quantity",
              "Lower in Notes Quantity",
            ].map((op) => ({
              label: op,
              value: op,
            }))}
            onChange={(value) => {
              // setFilters({ byQuantity: value });
              setFilters({ ...filters, byNotes: value });
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
