function formatKey(key) {
  if (!key) return;
  return key
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/ /g, "");
}

function getHeaders(keys, object) {
  return keys
    .map((key) => {
      const lastChar = key[key.length - 1];
      const secondLastChar = key[key.length - 2];

      if (lastChar === "1" && isNaN(parseInt(secondLastChar)))
        return {
          headerName: formatKey(object[key].v),
          headerKey: key.replace("1", ""),
        };
    })
    .filter((value) => value !== undefined);
}

function convertToObjectArray(headers, object) {
  const resultArray = [];

  // Extract header keys from the header object
  const headerKeys = headers.map((header) => header.headerKey);

  // Iterate through the rows in object starting from row 2
  for (let row = 2; object[`A${row}`]; row++) {
    const rowObject = {};

    // Iterate through columns
    for (let col = 0; col < headerKeys.length; col++) {
      const cellKey = `${headerKeys[col]}${row}`;
      const cellValue = object[cellKey] ? object[cellKey].v : null;

      // Assign the value to the corresponding headerName
      const headerName = headers[col].headerName;
      rowObject[headerName] = cellValue;
    }

    resultArray.push(rowObject);
  }

  return resultArray;
}

export function convertExcelObjectToCustomObject(excelObject) {
  const tableKeys = Object.keys(excelObject);
  const headers = getHeaders(tableKeys, excelObject);
  const result = convertToObjectArray(headers, excelObject);
  return result;
}

export const parseComparedHtml = (value, objectValue) => {
  const regex = /(<([^>]+)>)/gi;
  const parsedValue =
    typeof value === "string" ? value.replace(regex, "") : value;
  const parsedObjectValue =
    typeof objectValue === "string"
      ? objectValue.replace(regex, "")
      : objectValue;

  return parsedValue !== parsedObjectValue
    ? { backgroundColor: "#b0e090" }
    : null;
};

function getDefaultValue(value) {
  switch (typeof value) {
    case "string":
      return "";
    case "number":
      return null;
    case "boolean":
      return false;
    case "object":
      return value === null ? null : Array.isArray(value) ? [] : {};
    default:
      return value;
  }
}

export function addMissingKeys(object1, object2, primaryKey) {
  for (const [key, value] of Object.entries(object1)) {
    if (key === "userId" || key === primaryKey) {
      continue;
    }
    if (!(key in object2)) {
      object2[key] = getDefaultValue(value);
    }
  }

  return object2;
}
