import {
  TrashIcon,
  XIcon,
} from "../../../../../SidebarPages/Communication/assets";
import { LogsIcon } from "../../../../../SidebarPages/DynamicView/src";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import { MondayButton } from "../../../../../commonComponents";

export function footerButtons(
  onCancel = () => {},
  onSave = () => {},
  onShowLogs = () => {},
  onDelete = () => {},
  editMode,
  saveBtnDisable
) {
  return [
    editMode && (
      <MondayButton
        className="mondayButtonRed"
        Icon={<TrashIcon />}
        onClick={onDelete}
      >
        Delete
      </MondayButton>
    ),
    editMode && (
      <MondayButton
        className="mondayButtonBlue"
        Icon={<LogsIcon />}
        onClick={onShowLogs}
      >
        Show Logs
      </MondayButton>
    ),
    <MondayButton
      className="mondayButtonRed"
      Icon={<XIcon />}
      onClick={onCancel}
    >
      Cancel
    </MondayButton>,
    <MondayButton
      disabled={saveBtnDisable}
      Icon={<TickIcon width={17} height={17} />}
      onClick={onSave}
    >
      Save
    </MondayButton>,
  ];
}

export function getLocationOptions(schedulesForDate, projects) {
  return [
    {
      label: "Schedules",
      options: schedulesForDate
        ?.reduce(
          (acc, val) => [
            ...acc,
            !acc?.find((e) => e?.label === val?.scheduleAddress)
              ? {
                  key: val?.scheduleAddress + Math.random(),
                  value: val?.scheduleId + " ",
                  label: val?.scheduleAddress,
                }
              : null,
          ],
          []
        )
        ?.filter(Boolean),
    },
    {
      label: "Projects",
      options: projects
        ?.map((e) => ({
          key: e?.projectName + Math.random(),
          value: e?.projectId,
          label: e?.projectName,
        }))
        .sort((a, b) => a?.value.localeCompare(b?.value)),
    },
  ];
}

const payrollCategories = ["Open Shop", "Certified Payroll", "Prevailing Wage"];

export const GOOGLE_MAP_API_KEY = "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo";

export const jobsiteFields = (
  onAddressSelect,
  clients,
  rowToEdit,
  servicesList,
  accessRight,
  projects,
  roles
) => {
  const rateAccess = accessRight?.children?.find?.(
    ({ title }) => title === "Rate"
  );

  return [
    {
      label: "Jobsite Address",
      type: "placesautocomplete",
      // type: "select",
      formItemName: "jobAddress",
      className: "jobsiteAddressField",
      dataTestid: "jobAddress",
      // disabled: !!rowToEdit,
      onSelect: onAddressSelect,
      placeholder: "Jobsite address...",
      keepOptions: true,
      // customOptions: projects.map(({ projectAddress, projectId }, key) => ({
      //   key,
      //   label: projectAddress,
      //   value: projectId,
      // })),
      groupedOptions: [
        {
          label: "Projects",
          options: projects.map(({ projectAddress, projectId }, key) => ({
            key,
            label: projectAddress,
            value: projectAddress,
          })),
        },
      ],
      required: true,
    },
    {
      label: "Job Name",
      type: "input",
      formItemName: "jobName",
      className: "jobNameField",
      placeholder: "Job name...",
      dataTestid: "jobName",
      // disabled: true,
      required: true,
    },
    {
      label: "Client",
      type: "select",
      formItemName: "accountName",
      className: "accountName",
      dataTestid: "accountName",
      placeholder: "Select client...",
      getPopupContainer: document.body,
      customOptions:
        Array.isArray(clients) &&
        clients.map((client, index) => ({
          key: index,
          value: client?.accountId,
          label: client?.accountName,
        })),
      required: true,
    },
    {
      label: "Payroll Category",
      type: "select",
      formItemName: "payrollType",
      className: "payrollType",
      dataTestid: "payrollType",
      placeholder: "Select payroll category...",
      getPopupContainer: document.body,
      required: true,
      customOptions: payrollCategories?.map((payroll, key) => {
        return {
          key,
          value: payroll,
          label: payroll,
          disabled: payroll === "Certified Payroll" && !rateAccess?.write,
        };
      }),
    },
    !rowToEdit && {
      label: "Google Sheet Link",
      type: "input",
      formItemName: "googleSheetLink",
      className: "googleSheetLink",
      placeholder: "Add link...",
      // required: true,
    },
    {
      label: "Location Radius",
      type: "input",
      typeNumber: true,
      formItemName: "locationRadius",
      dataTestid: "locationRadius",
      className: "locationRadius",
      placeholder: "Add radius...",
      addonAfter: "ft.",
      required: true,
    },
    {
      label: "Services",
      type: "select",
      mode: "multiple",
      formItemName: "services",
      className: "services",
      dataTestid: "services",
      placeholder: "Select service...",
      getPopupContainer: document.body,
      // required: true,
      customOptions: servicesList?.map(({ serviceName, categoryId }) => {
        return {
          key: categoryId,
          value: serviceName,
          label: serviceName,
        };
      }),
    },
    // {
    //   label: "Reimbursement",
    //   type: "checkbox",
    //   formItemName: "reimbursement",
    //   className: "reimbursement",
    //   // placeholder: "Has Reimbursement...",
    //   defaultValue: false,
    //   // getPopupContainer: document.body,
    //   // customOptions: [
    //   //   { key: 0, label: "Present", value: true },
    //   //   { key: 1, label: "Absent", value: false },
    //   // ],
    // },
  ].filter(Boolean);
};
