import { useMemo } from "react";
import { useSelector } from "react-redux";

export function getAccessRights(pageName) {
  const { userConfiguration } = useSelector((state) => state?.userConfig);

  return userConfiguration?.routeConfig?.find(
    ({ title }) => title === pageName
  );
}

export function getTabAccess(pageName, tabName) {
  const pageAccessRights = getAccessRights(pageName);

  return pageAccessRights?.children?.find(({ title }) => title === tabName);
}

export function getCardAccess(pageName, tabName, cardName) {
  const tabAccessRights = getTabAccess(pageName, tabName);

  return tabAccessRights?.children?.find(({ title }) => title === cardName);
}

export default {
  getAccessRights,
  getTabAccess,
  getCardAccess,
};
