import { message } from "antd";
import {
  deleteRelatedNotifications,
  postToDoLogs,
  removeSearchParams,
} from ".";
import { API } from "aws-amplify";

/**
 *
 * @param {String} todoId - the id of the to do
 * @param {Function} setRowData - function to set the row data
 * @param {Function} setVisible - function to set the visibility of the modal
 * @param {Function} saveAddedLogs - function to save the added logs
 * @param {Object} defaultObject - the default object
 * @param {Function} setRowObject - function to set the row object
 * @param {Function} deleteDriveFolder - function to delete the drive folder
 * @param {Function} onDeleteToDo - call back function that is executed when we delete a to do
 * @param {Array} notifications - the notifications
 */
async function deleteToDo({
  todoId = "",
  setRowData = () => {},
  setVisible = () => {},
  saveAddedLogs = async () => {},
  defaultObject = null,
  setRowObject = () => {},
  deleteDriveFolder = async () => {},
  onDeleteToDo = () => {},
  notifications = [],
}) {
  message.loading({ content: "Deleting...", key: "delete" });
  await API.del("todos", `/todos/${todoId}`)
    ?.then(async () => {
      message.success({ content: "Deleted!", key: "delete" });
      onDeleteToDo(todoId);
      setRowData((prev) => prev?.filter((item) => item.todoId !== todoId));
      await postToDoLogs({
        type: "delete",
        prevRecord: defaultObject,
        recordId: todoId,
        setRowObject,
        saveAddedLogs,
      });
      setVisible(false);
      removeSearchParams();
      await deleteDriveFolder();
      await deleteRelatedNotifications({ recordId: todoId, notifications });
    })
    ?.catch((error) => {
      message.error({ content: "Something went wrong", key: "delete" });
      console.error("Error deleting To Do", error);
      setVisible(false);
      removeSearchParams();
    });
}

export default deleteToDo;
