import { FormInstance } from "antd";
import { createContext } from "react";

/**
 * @typedef CoordinateType
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef CreatedBy
 * @property {string} identityId
 * @property {string} nameOfUser
 */

/**
 * @typedef GeofenceType
 * @property {string} type
 * @property {string} title
 * @property {string} shapeId
 * @property {number} createdAt
 * @property {string} createdBy
 * @property {string} description
 * @property {Array<CoordinateType>}
 */

/**
 * @typedef DegType
 * @property {string} degId
 * @property {number} toDate - timestamp
 * @property {string} degName
 * @property {number} fromDate - timestamp
 * @property {number} createdAt - timestamp
 * @property {CreatedBy} createdBy
 * @property {"Draft" | "Pending" | "Completed"} degStatus
 */

/**
 * @typedef AnalyticType
 * @property {string} degId
 * @property {string} crewId
 * @property {string} company
 * @property {string} entryId
 * @property {Date} punchDate
 * @property {Date} punchTime
 * @property {number} duration
 * @property {number} totalOvh
 * @property {string} uploadId
 * @property {number} createdAt
 * @property {string} jobsiteId
 * @property {string} employeeId
 * @property {string} uploadName
 * @property {string} payrollType
 * @property {number} employeeRate
 * @property {string} punchLocation
 * @property {number} punchTimeStamp
 * @property {number} distanceFromJob
 * @property {string} employeeFullName
 * @property {JobsiteMatch} jobsiteMatch
 * @property {Array<string>} serviceOptions
 * @property {"Hourly" | "Salary"} salaryType
 * @property {{lat: number, lng: number}} punchCoordinates
 * @property {"ID" | "OD" | "IL" | "OL" | "HR"} punchType
 * @property {"Draft" | "Pending" | "Completed"} activityStatus
 */

/**
 * @typedef ProjectType
 * @property {string} accountId
 * @property {string} projectId
 * @property {string} accountName
 * @property {string} projectAddress
 * @property {number} projectLatitude
 * @property {string} laborRequirement
 * @property {number} projectLongitude
 * @property {Array<GeofenceType>} geoFenceInfo
 */

/**
 * @typedef EmployeeType
 * @property {string} crewId
 * @property {Array} members
 * @property {string} crewName
 * @property {number} createdAt - timestamp
 * @property {string} crewStatus
 * @property {string} employeeId
 * @property {string} accountName
 * @property {CreatedBy} createdBy
 * @property {string} crewPosition
 * @property {number} employeeRate
 * @property {number} fingerCheckId
 * @property {string} employeeNumber
 * @property {Array} teamConfiguration
 * @property {boolean | string} foreman
 * @property {"Hourly" | "Salary"} salaryType
 */

/**
 * @typedef PayrollContextType
 * @property {FormInstance} form
 * @property {() => void} setAnalytics
 * @property {() => void} fetchAnalytics
 * @property {Array<DegType>} degPayrolls
 * @property {Array<AnalyticType>} analytics
 * @property {() => void} setRemovedAnalytics
 * @property {Array<ProjectType>} allProjects
 * @property {Array<EmployeeType>} employeesList
 * @property {Array<AnalyticType>} removedAnalytics
 * @property {Boolean} isFromHrManagement
 * @property {Object} salaryAccess
 */

const initialValue = {};

const PayrollContext = createContext(initialValue);

export default PayrollContext;
