import { updateScheduleOfValue } from "../../../../../../../../actions/scheduleOfValues"
import { getErrors } from "../ServiceList/utils/getErrors"

import { isEmpty } from "lodash"

export const switchWriteMode = ({ checked, dispatchContext }) => {
  dispatchContext({ type: "SET_WRITABLE", payload: checked })
}

export const darkModeHandler = ({ dispatchContext, darkMode }) => {
  darkMode
    ? dispatchContext({ type: "SET_DARK_MODE", payload: false })
    : dispatchContext({ type: "SET_DARK_MODE", payload: true })
}

//this function is used for autoSaving
export const autoSaveHandler = ({ fakeSOV, isAutoSaving, isWritable, dispatch }) => {
  if (fakeSOV) {
    //here we check if we have any error or not
    const errors = Object.values(getErrors(fakeSOV))
      ?.map((obj) => !isEmpty(obj))
      ?.filter(Boolean)?.length

    //if autoSaving is on and also we dont have any errors we save sov on db and redux
    if (isAutoSaving && !errors && isWritable) {
      dispatch(updateScheduleOfValue(fakeSOV?.scheduleId, fakeSOV))
    }
  }
}
