// import { MondayButton } from "../../../../../../../commonComponents";
// import { AddIcon } from "../../../../../../../Header/components/GoogleEvents/assets";

function locationColumns({ locationCreate }) {
  return [
    {
      title: "Location",
      dataIndex: "location",
    },
    // {
    //   title: "Create",
    //   render(_, record) {
    //     return (
    //       <MondayButton
    //         Icon={<AddIcon />}
    //         onClick={() => {
    //           locationCreate(record.location);
    //         }}
    //       >
    //         Create Jobsite
    //       </MondayButton>
    //     );
    //   },
    // },
  ];
}

export default locationColumns;
