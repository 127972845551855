import { driveApi } from "../../../../../integrations/DriveRequest";

/**
 *
 * @param {String} requestId - The request id
 * @param {String} accessToken - The access token
 * @param {Array} uploadedFiles - The list of uploaded files that we get from the custom hook
 * @param {Array} gDriveUploads - The list of uploaded files that we get from the original request form
 * @param {Array} filesToBeDeleted - The list of files to be deleted that we get from the custom hook
 * @returns - deletes all the necessary files from the google drive when we are canceling the request
 */
const cancelRequestFilesHandler = async ({
  requestId = "",
  accessToken = "",
  uploadedFiles = [],
  gDriveUploads = [],
  filesToBeDeleted = [],
}) => {
  if (!accessToken) return console.error("No access token provided");
  const { deleteDriveItemPromise } = driveApi({ accessToken });

  // If we are creating request, delete all uploaded files and files to delete
  if (!requestId) {
    const toDelete = [];
    if (uploadedFiles?.length) {
      uploadedFiles?.forEach((el) =>
        toDelete.push(deleteDriveItemPromise(el?.id))
      );
    }

    if (filesToBeDeleted?.length) {
      filesToBeDeleted?.forEach((el) =>
        toDelete.push(deleteDriveItemPromise(el))
      );
    }

    try {
      toDelete?.length && (await Promise.allSettled(toDelete));
    } catch (err) {
      console.error("Error deleting drive items", err);
    }
  } else {
    //If we cancel a request while editing we delete only the newly uploaded files
    const toDelete = [];
    // if (uploadedFiles?.length) {
    //   uploadedFiles?.forEach((file) => {
    //     if (!gDriveUploads?.some((el) => el?.id === file)) {
    //       toDelete.push(deleteDriveItemPromise(file));
    //     }
    //   });
    // }

    if (uploadedFiles?.some((el) => !!el?.newDocFile)) {
      uploadedFiles?.forEach((file) => {
        if (file?.newDocFile) {
          toDelete.push(deleteDriveItemPromise(file?.id));
        }
      });
    }

    try {
      toDelete?.length && (await Promise.allSettled(toDelete));
    } catch (err) {
      console.error("Error deleting drive items", err);
    }
  }
};

export default cancelRequestFilesHandler;
