import { useEffect, useReducer, useRef, useState } from "react";
import { RequestModalContext, requestReducer } from "./store";
import NewRequestModal from "./NewRequestModal";
import { useProgramFields, useSocket } from "../../../../hooks";
import { handleMissingRecord, useRequestApiCalls } from "./helpers";
import { message } from "antd";
import { fetchAllData, filterRowDataByTeamConfig } from "../../../../utils";
import { useSelector } from "react-redux";
import { wsFormRequests } from "../../../../AppData";

/**
 * Renders the NewRequest component.
 *
 * @component
 * @param {string} [action=""] - The action for the request form.
 * @param {boolean} [from=false] - Indicates if the request form is from a table.
 * @param {boolean} [visible=false] - Indicates if the request form is visible.
 * @param {string} [requestType=""] - The type of the request.
 * @param {Function} [setVisible=() => {}] - The function to set the visibility of the request form.
 * @param {Object} [requestForm=null] - The original request form data.
 * @param {boolean} [globalDisabled=false] - Indicates if the request form is globally disabled.
 * @returns {JSX.Element} The NewRequest component.
 */
const NewRequest = ({
  action = "",
  from = false,
  visible = false,
  requestType = "",
  setVisible: close = () => {},
  requestForm: originalRequestForm = null,
  globalDisabled = false,
}) => {
  const [geoFenceInfo, setGeoFenceInfo] = useState(
    originalRequestForm?.requestObject?.geoFenceInfo
      ? originalRequestForm?.requestObject?.geoFenceInfo
      : []
  );
  const [geoLocation, setGeoLocation] = useState();

  const {
    ["Request Form Status"]: statuses,
    ["Approvals Dynamic Fields"]: inputFields,
  } = useProgramFields();

  const { topicCategories } = useSelector((state) => state.topicCategories);

  const [requestData, dispatchRequestData] = useReducer(requestReducer, {});

  const { socket: ws } = useSocket({ path: wsFormRequests.online });

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const currentPageKey = useRef({});

  /**
   * All the api calls that are needed for each request type
   * if a new request type is added, it's corresponding api calls should be added here
   */
  const { requestApiCalls } = useRequestApiCalls(requestType, currentPageKey);

  const setVisible = () => {
    dispatchRequestData({
      type: "RESET",
    });

    if (from === "table") {
      history.replaceState(null, "", "/approvals");
    }

    close(false);
  };

  /**
   * Fetches all the data needed for the request form.
   * If the request form is an edit form, it also fetches the edit logs.
   */
  useEffect(() => {
    if (requestApiCalls?.length) {
      Promise.all(requestApiCalls)
        .then(async (res) => {
          /**
           * We reduce the fetched data to get the data for each table
           * and filter the data based on the records's team configuration
           */
          const reducedRes = res?.reduce((acc, el, index) => {
            const table = Object.keys(el)[0];
            const data = el?.[table];

            if (table === "projectExecutives") {
              return {
                ...acc,
                [table]: data,
              };
            }
            return {
              ...acc,
              [table]: filterRowDataByTeamConfig(userConfiguration, data),
            };
          }, {});

          /**
           * If the request is in edit mode we check the fetched data if the request record is present
           */
          if (!!originalRequestForm) {
            await handleMissingRecord({
              category: originalRequestForm?.category,
              reducedRes,
              recordId: originalRequestForm?.recordId,
              requestType,
              topicCategories,
              dispatchRequestData,
            });
          } else {
            dispatchRequestData({
              type: "GET_DATA",
              payload: reducedRes,
            });
          }

          message.destroy("modalLoading");
        })
        .catch((err) => {
          console.error("Error getting requestModal data: ", err);

          message.error({
            content: "Something went wrong. Please try again later.",
            key: "modalLoading",
          });

          setVisible(false);
        });
    }

    /**
     * If the request form is an edit form, we fetch the edit logs
     * for the request record and set it in the state
     */
    if (originalRequestForm?.requestId) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: originalRequestForm.requestId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          dispatchRequestData({
            type: "GET_LOGS",
            payload: res,
          });
        })
        .catch((error) => {
          console.error("Error fetching logs: ", error);
        });
    }
  }, []);

  return (
    <RequestModalContext.Provider
      value={{
        ws,
        from,
        action,
        statuses,
        setVisible,
        inputFields,
        requestData,
        requestType,
        globalDisabled,
        dispatchRequestData,
        originalRequestForm,
        currentPageKey,
        topicCategories,
        geoFenceInfo,
        setGeoFenceInfo,
        geoLocation,
        setGeoLocation,
      }}
    >
      {!!visible && <NewRequestModal {...{ visible }} />}
    </RequestModalContext.Provider>
  );
};

export default NewRequest;
