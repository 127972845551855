import { Tooltip } from "antd";

import { useStateContext } from "../../../../../../../Context/Context";
import { formatCurrency } from "../../../../../../../../../../../utils";
import "./difference-cell-renderer.css";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  WarningFilled,
} from "@ant-design/icons";

const DifferenceCellRenderer = ({
  api,
  rowIndex,
  value,
  serviceLabel,
  estimationId,
}) => {
  // Context state
  const { errors } = useStateContext();

  const hasDifferenceWarning =
    errors && errors[`${serviceLabel}/${estimationId}`]?.differenceWarning;
  const hasDifferenceError =
    errors && errors[`${serviceLabel}/${estimationId}`]?.differenceError;

  const lastRow = api?.getModel()?.rowsToDisplay.length - 1;

  return (
    <div className="difference-cell-renderer">
      <span className="difference-value">{formatCurrency(value, "USD")}</span>
      <div
        className="difference-status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {rowIndex === lastRow &&
          (hasDifferenceWarning ? (
            <Tooltip title={hasDifferenceWarning}>
              <WarningFilled style={{ color: "#ECDE7C" }} />
            </Tooltip>
          ) : hasDifferenceError ? (
            <Tooltip title={hasDifferenceError}>
              <ExclamationCircleFilled style={{ color: "#FE4C4A" }} />
            </Tooltip>
          ) : (
            <CheckCircleFilled style={{ color: "#68C142" }} />
          ))}
      </div>
    </div>
  );
};

export default DifferenceCellRenderer;
