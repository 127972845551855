import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { calculateTotalsReqs } from "../../../../Applications/ApplicationView/components/Header/Components/ControlPanel/totalities";

export const calcTotalChargeAmount = (
  chargeToSave,
  categoryFrom,
  whereToFind
) => {
  if (!chargeToSave || !chargeToSave.chargeItems) {
    return 0;
  }

  if (categoryFrom === "Invoice")
    return chargeToSave?.chargeItems?.reduce(
      (prev, { amount, taxAmount }) => prev + (amount || 0) + (taxAmount || 0),
      0
    );

  if (categoryFrom === "Requisition") {
    const totalRequisitionAmount =
      calculateTotalsReqs(whereToFind)?.CURRENT_PAYMENT || 0;

    return totalRequisitionAmount;
  }

  let total = chargeToSave?.chargeItems?.reduce(
    (acc, { price, taxAmount }) =>
      acc + (forceToNumber(price) + forceToNumber(taxAmount || 0) || 0),
    0
  );

  return total || 0;
};
