export const invoices = (
  programFields,
  updatedDynamicStates,
  IdHandler,
  invoicesData
) => {
  const invoiceStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    invoiceStatus: () => ({
      title: "Invoices - Invoice Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Invoices",
        field: "invoiceStatus",
        content: invoiceStatus?.statusName?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: invoicesData?.length,
          taskDone: invoicesData?.filter((a) => a?.invoiceStatus === status)
            ?.length,
          color: invoiceStatus?.statusColor[index],
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Invoice`,
          contentsHandler: ` ${status} Invoices`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  };
};
