export const createConnection = () =>
  new WebSocket(
    "wss://leadmanager-sockets.ue.r.appspot.com/ws/customerportal"
    // "ws://localhost:8080/ws/customerportal"
  );

let websocket = createConnection();

export const getWs = () => websocket;

export const setWs = () => {
  return new Promise((resolve) => {
    websocket.close();

    const newConn = createConnection();

    newConn.onopen = () => {
      resolve(newConn);
      websocket = newConn;
    };

    newConn.onclose = () => {
      resolve(false);
    };

    // newConn.onerror = (error) => {
    //   console.log(`WebSocket error: `, error);
    //   resolve(false);
    // };
  });
};
