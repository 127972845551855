import axios from "axios";
import { GOOGLE_API_KEY } from "../../../../../../../../helpers/constants";

export const getLatLng = async (address) => {
  if (address)
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: address,
            key: GOOGLE_API_KEY,
          },
        }
      );

      if (response.data.status === "OK") {
        const { lat, lng } = response.data.results[0].geometry.location;
        return { lat, lng };
      } else {
        throw new Error("Geocoding API error: " + response.data.status);
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
      return null;
    }
};
