export function calculateColumnsTotalValue({
  gridApi,
  rowData,
  sumColumns,
  setColumnsTotalValue,
}) {
  if (!gridApi || rowData.length === 0) return;

  const selectedNodes = gridApi.getSelectedNodes();
  const dataToSum =
    selectedNodes.length > 0 ? selectedNodes.map((node) => node.data) : rowData;

  const newSums = sumColumns.reduce((acc, column) => {
    acc[column] = dataToSum.reduce((sum, row) => sum + (row[column] || 0), 0);
    return acc;
  }, {});

  setColumnsTotalValue(newSums);
}
