const hoistColumnDefs = [
  {
    headerName: "Job Address",
    field: "scheduleAddress",
  },
  {
    headerName: "D/S",
    field: "ds",
  },
  {
    headerName: "Device",
    field: "device",
  },
  {
    headerName: "Jumps",
    field: "jumps",
  },
  {
    headerName: "Floor",
    field: "floor",
  },
  {
    headerName: "Inspection Date",
    field: "dateOfJumpUp",
  },
  {
    headerName: "Inspection Name",
    field: "inspection",
  },
];

export default hoistColumnDefs;
