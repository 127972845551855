import { getDispatchLocationOptions } from "../../../../../Header/forms/RequestFormModal/requestFormModalData";
import { searchSafetyAddress } from "../../../../Safety/SafetyModal/helpers";
import searchIncidentAddress from "../searchIncidentAddress";

function separateCrewMembers(foremen, selectedForeman, crewMembers) {
  if (!!selectedForeman) {
    let crew = foremen.find((el) => el?.value === selectedForeman) || [];
    return {
      customOptions: [
        {
          label: "Under Foreman",
          options:
            crew?.members?.map((e) => ({
              value: e.crewId,
              label: e.crewName,
            })) || [],
        },
        {
          label: "Other Members",
          options:
            crewMembers?.filter(
              (e) => !crew.members?.find((el) => el.crewId === e.value)
            ) || [],
        },
      ],
      className: "multipleSelect",
      mode: "multiple",
    };
  } else {
    return {
      customOptions: crewMembers,
      className: "multipleSelect",
      mode: "multiple",
    };
  }
}

function addConditionalFields({
  fields,
  fieldCategoryIndex,
  typeOfEquipment,
  field,
}) {
  if (
    typeOfEquipment === "Other" &&
    field.formItemName === "typeOfEquipmentInvolved"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherEquipmentInvolved",
      label: "Other Equipment Involved",
      required: true,
      placeholder: "Other Equipment Involved",
      category: field.category,
    });
  }
}

const propertyDamageIncidentFields = ({
  form,
  crews = [],
  projects = [],
  scheduling = [],
  defaultId = null,
  propAddress = "",
  isDarkMode = false,
  dynamicFields = [],
  typeOfEquipment = "",
  schedulesForDate = [],
  userConfiguration = {},
  selectedForeman = null,
  disabledAllFields = false,
  mainDisabledField = false,
  onChangeAddress = () => {},
  changeDateOfIncident = () => {},
  dispatchIncidentFormData = () => {},
}) => {
  let fields = [];

  const foremen = crews
    ?.filter((e) => !e.foreman)
    ?.map(({ crewId, crewName, members }) => ({
      label: crewName,
      value: crewId,
      members,
    }));

  const crewMembers = crews
    ?.filter((e) => !!e.foreman)
    ?.map(({ crewId, crewName }) => ({
      label: crewName,
      value: crewId,
    }));

  for (let i = 0; i < dynamicFields?.length; i++) {
    let field = structuredClone(dynamicFields[i]);
    delete field["placeholder"];

    if (field.type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        ...(field.label.includes("Foreman")
          ? { customOptions: foremen }
          : field.label.includes("Members")
          ? separateCrewMembers(foremen, selectedForeman, crewMembers)
          : {}),
      };
      if (field.label.includes("Members")) {
        delete field["options"];
      }
    }

    if (field.type === "placesautocomplete") {
      field = {
        ...field,
        addressDisabled: mainDisabledField,
        addressSelectionInfo: {
          address: propAddress,
          setAddress: onChangeAddress,
        },
      };
    }

    if (field.type === "radio") {
      field = {
        ...field,
        style: {
          borderRadius: 10,
          backgroundColor: isDarkMode ? "#12131b" : "#f5f5f7",
          padding: 10,
        },
      };
    }

    if (
      field.formItemName === "dateOfPropertyDamage" ||
      field.formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(field.formItemName === "dateOfPropertyDamage"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
      };
    }

    if (field.formItemName === "reportPreparedBy") {
      delete field?.options;
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: userConfiguration?.allUsers?.Items?.map((user) => {
          return { value: user?.identityId, label: user?.nameOfUser };
        }),
        style: { width: 200 },
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    }

    if (field.formItemName === "timeOfPropertyDamage") {
      field = {
        ...field,
        validTime: "past",
        dateField: "dateOfPropertyDamage",
        form,
      };
    }

    if (field.formItemName === "addressOfDamagedProperty") {
      field = {
        ...field,
        onSelect: onChangeAddress,
        initialValue: propAddress || "",
        groupedOptions: getDispatchLocationOptions(schedulesForDate, projects),
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchIncidentAddress({
            searchValue,
            dispatchIncidentFormData,
            userConfiguration,
            projects,
            scheduling,
            schedulesForDate,
            dateField: "dateOfPropertyDamage",
            form,
          }),
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );

    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);

      addConditionalFields({
        fields,
        fieldCategoryIndex,
        typeOfEquipment,
        field,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        fieldCategoryIndex: field.length - 1,
        typeOfEquipment,
        field,
      });
    }

    if (disabledAllFields) {
      field.disabled = disabledAllFields;
    }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );

  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  return fields;
};

export default propertyDamageIncidentFields;
