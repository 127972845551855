/**
 *
 * @param {Array} arr The Array
 * @param {*} el The element to insert
 * @param {Number} index The Index where to insert it
 * @returns a new Array
 */
export const pushToArrayAtIndex = (arr = [], el, index) => {
  const lastIndex = arr.length - 1
  const indexToCompare = lastIndex < index ? lastIndex : index
  return arr.flatMap((item, idx) =>
    idx === indexToCompare ? [el, item] : item
  )
}
