import ErrorComponent from "../assets/ErrorComponent";

const NotAuthorized = () => {
  return (
    <div className="ErrorBoundary">
      <ErrorComponent
        {...{
          errorMessage: "Unauthorized Access",
          errorDescription:
            "You are not authorized to access this app. Please contact the system administrator if you believe this is an error.",
        }}
      />
    </div>
  );
};

export default NotAuthorized;
