import { Modal, Button } from "antd";
import "./InspectionModal.scss";
import { XIcon } from "../../Communication/assets";
import { updateDocumentTitle } from "../../../../utils";
import { useSelector } from "react-redux";

const InspectionModal = ({
  visible,
  setVisible,
  destroyOnClose = false,
  title = "",
  className = "",
  onCancel,
  maskClosable = true,
  centered = true,
  children,
  footer = [],
  basicModalFooter = false,
  closable = false,
  style = {},
  footerStyle = {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <Modal
      wrapClassName={`basePageModal ${className} ${
        !!title ? "" : "noTitleBasePageModal"
      } ${isDarkMode && "newInspectionModalDark"}`}
      open={visible}
      destroyOnClose={destroyOnClose}
      footer={basicModalFooter ? basicModalFooter : null}
      title={title}
      classNames="inspection-modal"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: title });
      }}
      centered={centered}
      keyboard
      maskClosable={maskClosable}
      closeIcon={<XIcon fill="#333238" style={{ marginRight: "10px" }} />}
      closable={closable}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel ? onCancel() : setVisible(false);
      }}
      style={style}
    >
      {children}
      {footer?.length ? (
        <div
          className="basePageModalFooter"
          style={!!footer?.length ? footerStyle : { display: "none" }}
        >
          {footer?.map(
            (
              {
                text,
                onClick,
                primary,
                uppercase = false,
                disabled = false,
                danger = false,
              },
              i
            ) => (
              <Button
                key={i}
                danger={danger}
                onClick={() => {
                  updateDocumentTitle(); // reset document title to "Lead Manager";
                  onClick();
                }}
                disabled={disabled}
                className={`${
                  disabled
                    ? "disabled"
                    : danger
                    ? ""
                    : primary
                    ? "primary"
                    : "secondary"
                }FooterButton`}
                style={{
                  textTransform: uppercase ? "uppercase" : "capitalize",
                }}
              >
                {text}
              </Button>
            )
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default InspectionModal;
