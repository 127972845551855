import { message } from "antd";
import Decimal from "decimal.js-light";
import { getErrors } from "../../../../utils/getErrors";
import { roundToDecimals } from "../../../../../../../../../../utils/index";
import { calculateDifference, updateFakeSOV } from "../utils";
import { findTaxAmountGivenCustomTaxAmount } from "../../../../Service/ServiceContent/GridData/GridUtils/toggleTaxEvent";

export const toggleTaxEventHoist = (
  eventId,
  isWritable,
  isLoading,
  service,
  elevation,
  fakeSOV,
  setSOV,
  setErrors,
  data,
  project
) => {
  if (!isWritable) {
    message.error("Please enable write mode");
  } else if (isLoading) {
    message.error("Please wait until it finishes updating");
  } else {
    // keep track of the original tax amount
    // Find service that we want to edit
    const unchangedService = structuredClone(
      fakeSOV?.services?.find(
        (sovService) =>
          sovService.serviceId.toString() === service.serviceId.toString() &&
          sovService.estimationId === service.estimationId
      )
    );

    const serviceToEdit = structuredClone(unchangedService);
    const elevationToEdit = serviceToEdit?.serviceOptions?.[0]?.find(
      (serviceOption) => serviceOption?.elevationId === elevation?.elevationId
    );

    const previousTaxAmount = elevationToEdit.amounts[eventId].taxAmount;

    elevationToEdit.amounts[eventId].custom_tax_amount =
      elevationToEdit.amounts[eventId].custom_tax_amount > 0
        ? 0
        : project?.taxRate;

    data.custom_tax_amount = elevationToEdit.amounts[eventId].custom_tax_amount;

    console.log("elevation edit", elevationToEdit.amounts[eventId], project);
    console.log("amountsss", {
      eventId,
      isWritable,
      isLoading,
      service,
      fakeSOV,
      setSOV,
      setErrors,
    });

    if (elevationToEdit.amounts[eventId].custom_tax_amount > 0) {
      elevationToEdit.amounts[eventId].amount =
        findTaxAmountGivenCustomTaxAmount(
          elevationToEdit.amounts[eventId].amount,
          elevationToEdit.amounts[eventId].custom_tax_amount
        );
      let taxAmount =
        elevationToEdit.amounts[eventId].amount *
        elevationToEdit.amounts[eventId].custom_tax_amount;
      elevationToEdit.amounts[eventId].taxAmount = taxAmount;
      data.taxAmount = taxAmount;
    } else {
      elevationToEdit.amounts[eventId].amount -= previousTaxAmount;
      elevationToEdit.amounts[eventId].taxAmount = 0;
      data.taxAmount = 0;
    }
    elevationToEdit.amounts[eventId].rate =
      +elevationToEdit.amounts[eventId].amount <= 0 ||
      isNaN(+elevationToEdit.amounts[eventId].amount) // Check if input is valid
        ? 0 // If it is not valid make it 0
        : +elevationToEdit.amounts[eventId].amount > elevationToEdit?.totalPrice // Check if input is greater than total price
        ? 100 // If it is make it 100
        : new Decimal(100)
            .dividedBy(
              new Decimal(elevationToEdit.totalPrice).dividedBy(
                roundToDecimals(+elevationToEdit.amounts[eventId].amount, 2)
              )
            )
            .toFixed();
    console.log("elevationToEdit.amounts[eventId].rate", service);
    const newAmounts = elevationToEdit?.amounts;

    // // Update amounts on grid
    // const newAmounts = !!serviceToEdit?.amounts.length && [
    //   // If there is any event
    //   ...serviceToEdit.amounts.slice(0, eventId), // Copy all events until that row, and delete the row that was right clicked
    //   ...serviceToEdit.amounts
    //     .slice(eventId + 1)
    //     .map((amount) => ({ ...amount, id: amount.id - 1 })), // Copy all other events after the deleted one and decrement their id by 1
    // ]

    // Re calculate all amounts to update other rows based on the row that we have updated
    const amounts = calculateDifference(
      serviceToEdit,
      elevationToEdit,
      newAmounts
    );

    // Find the service that we are editing and update its amounts
    const newSOV = updateFakeSOV(fakeSOV, service, elevation, amounts);
    console.log("newAmounts", amounts);

    // Get errors for each service
    const errors = getErrors(newSOV);

    // Set new SOV
    setSOV(newSOV);

    // If none of services have errors set errors to null and save to db, otherwise set errors to errors object of all services
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      setErrors(null);
    } else {
      setErrors(errors);
    }
  }
};
