import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const projects = ({ tables, programFields }) => {
  return {
    projectStatus: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Project Status")
        ?.fieldOptions?.map(({ statusName }, index) => ({
          key: index,
          title: statusName,
          taskNumber: (tables?.Projects || [])?.length,
          taskDone: (tables?.Projects || [])?.filter(
            (a) =>
              a?.projectStatus?.toLowerCase() === statusName.toLocaleLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: statusName + " Project",
          contentsHandler: statusName + " Projects",
        })),
    }), //projectStatus

    attorney: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Projects || [], "attorney")
      ).map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: (tables?.Projects || [])?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: key + " Project",
        contentsHandler: key + " Projects",
      })),
    }),

    expeditor: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Projects || [], "expeditor")
      ).map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: (tables?.Projects || [])?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: key + " Project",
        contentsHandler: key + " Projects",
      })),
    }),
  }; //Projects
};
