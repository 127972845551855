import moment from "moment";

const filterStateConverter = (
  { gridApi, columnApi },
  { filterState, columnState, groupState },
  setFiltersData,
  masterDetail = false
) => {
  let newFilterModels = {};

  Object.keys?.(filterState)?.map?.((e) => {
    if (filterState[e]?.type === "inRange" && filterState[e]?.type === "date") {
      newFilterModels[e] = [
        moment(filterState[e].dateFrom).valueOf(),
        moment(filterState[e].dateTo).valueOf(),
      ];
    } else if (
      filterState[e]?.type === "inRange" &&
      filterState[e]?.filterType === "number"
    ) {
      newFilterModels[e] = [filterState[e].filter, filterState[e].filterTo];
    } else {
      if (masterDetail) newFilterModels[e] = filterState[e]?.values || [];
      else newFilterModels[e] = filterState[e]?.filterModels?.[1]?.values || [];
    }
  });

  setFiltersData((prev) => {
    if (masterDetail) return { ...(prev || {}), ...newFilterModels };
    return newFilterModels;
  });

  if (!!columnApi) {
    columnApi?.applyColumnState?.({
      state: columnState,
      applyOrder: true,
      defaultState: { sort: null },
    });
    columnApi?.setColumnGroupState?.(groupState);
  }
  if (!!gridApi) {
    gridApi?.setFilterModel?.(filterState);
  }
};

export default filterStateConverter;
