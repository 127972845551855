import { message } from "antd";
import { calculateStatusForEstimationAndRental } from "./calculateStatusForEstimationAndRental";
import { calculateTotalForRowData } from "./calculateTotalForRowData";

export const onRowTableClicked = ({
  e,
  visible,
  dataToCompare,
  fromObject,
  requisitions,
  charges,
  setSelectedGroup,
  setSelectedCatFrom,
  setSelectedData,
  setFormChanged,
  setSelectedInvoices,
}) => {
  if (visible.categoryFrom === "Schedule Of Value") {
    const hasCharges = dataToCompare.some(
      (estimation) =>
        e?.data?.estimationsSelected?.includes(estimation.estimationId) &&
        estimation?.charges?.length > 0
    );

    if (hasCharges)
      return message?.error(
        <div>This Schedule Of Value is included in charge from Estimation.</div>
      );
  }

  if (visible.categoryFrom === "Estimation") {
    const hasCharges = dataToCompare.some(
      (scheduleOfValue) =>
        scheduleOfValue?.estimationsSelected?.includes(e?.data?.estimationId) &&
        scheduleOfValue?.charges?.length > 0
    );
    if (hasCharges)
      return message?.error(
        <div>This Estimation is included in charge from Schedule Of Value.</div>
      );
  }

  if (visible?.categoryFrom === "Invoice") {
    return setSelectedInvoices((prev) => {
      const newInvoices =
        e?.api
          ?.getSelectedRows()
          ?.map((el) =>
            fromObject?.find(
              ({ invoiceNumber }) => el?.invoiceNumber === invoiceNumber
            )
          ) || [];
      const uniqueInvoices = [
        ...new Map(
          [...prev, ...newInvoices].map((item) => [item?.invoiceId, item])
        ).values(),
      ];
      return uniqueInvoices;
    });
  }
  setFormChanged(true);
  const crrEl = e.api.getSelectedRows()[0];
  let total = calculateTotalForRowData(
    crrEl?.data,
    crrEl,
    visible?.categoryFrom,
    true
  );
  const chargeStatus = calculateStatusForEstimationAndRental(
    crrEl?.data,
    crrEl,
    visible
  );

  if (total === 0 && visible?.categoryFrom === "Rental") {
    return message?.warning(
      <span>
        This Rental has no selected chargeable items! Change the rental data if
        you want to create a charge.
      </span>,
      4
    );
  }

  /* Validation starts here */
  const invoicedAmount =
    crrEl?.invoices?.reduce((p, { invoiceAmount }) => p + invoiceAmount, 0) ||
    0;
  const creditedAmount =
    crrEl?.charges?.reduce((p, { chargeAmount }) => p + chargeAmount, 0) || 0;
  if (
    visible?.type === "Credit Memo" &&
    invoicedAmount + creditedAmount >= total
  ) {
    return message?.error(
      <div>
        This {visible?.categoryFrom} is fully invoiced and credited in:{" "}
        {crrEl?.invoices?.length === 1 ? (
          <a
            href={`/invoices/${crrEl?.invoices[0]?.invoiceId}`}
            target={"_blank"}
          >
            Invoice {crrEl?.invoices[0]?.invoiceNumber}
          </a>
        ) : (
          <>
            Invoices:
            {crrEl?.invoices?.map(({ invoiceNumber, invoiceId }, i) => (
              <a href={`/invoices/${invoiceId}`} target={"_blank"}>
                {" "}
                Invoice {invoiceNumber}
                {crrEl?.invoices?.length - 1 === i ? "" : ","}
              </a>
            ))}
          </>
        )}{" "}
        |{" "}
        {crrEl?.charges?.map(({ chargeNumber, chargeId }, i) => (
          <>
            {" "}
            <a href={`/charges/${chargeId}`} target={"_blank"}>
              Credit {chargeNumber}
            </a>
            {i !== crrEl?.charges?.length - 1 ? "," : ""}
          </>
        ))}
      </div>
    );
  }
  if (
    visible?.type === "Credit Memo" &&
    crrEl?.invoices?.reduce((p, { invoiceAmount }) => p + invoiceAmount, 0) >=
      total
  ) {
    return message?.error(
      <div>
        This {visible?.categoryFrom} is fully invoiced in{" "}
        {crrEl?.invoices?.length === 1 ? (
          <a
            href={`/invoices/${crrEl?.invoices[0]?.invoiceId}`}
            target={"_blank"}
          >
            Invoice {crrEl?.invoices[0]?.invoiceNumber}
          </a>
        ) : (
          <>
            Invoices:
            {crrEl?.invoices?.map(({ invoiceNumber, invoiceId }, i) => (
              <a href={`/invoices/${invoiceId}`} target={"_blank"}>
                {" "}
                {invoiceNumber}
                {crrEl?.invoices?.length - 1 === i ? "" : ","}
              </a>
            ))}
          </>
        )}
      </div>
    );
  }
  if (crrEl?.invoices?.length > 0 && visible?.type !== "Credit Memo") {
    return message?.error(
      <div>
        This {visible?.categoryFrom} is included in{" "}
        {crrEl?.invoices?.length === 1 ? (
          <a
            href={`/invoices/${crrEl?.invoices[0]?.invoiceId}`}
            target={"_blank"}
          >
            Invoice {crrEl?.invoices[0]?.invoiceNumber}
          </a>
        ) : (
          <>
            Invoices:
            {crrEl?.invoices?.map(({ invoiceNumber, invoiceId }, i) => (
              <a href={`/invoices/${invoiceId}`} target={"_blank"}>
                {" "}
                {invoiceNumber}
                {crrEl?.invoices?.length - 1 === i ? "" : ","}
              </a>
            ))}
          </>
        )}
      </div>
    );
  }

  if (crrEl?.requisitionsIncluded)
    return message?.error(
      <div>
        This estimation is included in{" "}
        {crrEl?.requisitionsIncluded?.length === 1 ? (
          <a
            href={`/applications/${crrEl?.requisitionsIncluded[0]?.applicationId}`}
            target={"_blank"}
          >
            Requisition {crrEl?.requisitionsIncluded[0]?.applicationNo}
          </a>
        ) : (
          <>
            Requisition :
            {crrEl?.requisitionsIncluded
              .sort((a, b) => a?.applicationNo - b?.applicationNo)
              ?.map(({ applicationNo, applicationId }, i) => (
                <>
                  <a href={`/applications/${applicationId}`} target={"_blank"}>
                    {applicationNo}
                  </a>
                  {i !== crrEl?.requisitionsIncluded?.length - 1 ? ", " : ""}
                </>
              ))}
          </>
        )}
      </div>
    );
  if (crrEl?.includedTo) {
    const { applicationId, applicationNo } = requisitions?.find(
      ({ applicationId }) => applicationId === crrEl?.includedTo
    );
    return message?.error(
      <div>
        This rental is included in:{" "}
        <a href={`/applications/${applicationId}`} target="_blank">
          Requisition {applicationNo}
        </a>
      </div>
    );
  }
  if (crrEl?.charged && visible?.categoryFrom === "Requisition") {
    const { chargeId, chargeNumber } = charges?.find(
      ({ chargeId }) => chargeId === crrEl?.charged
    );
    return message?.error(
      <div>
        This Requisition is already charged in:{" "}
        <a href={`/charges/${chargeId}`} target={"_blank"}>
          {chargeNumber}
        </a>
      </div>
    );
  }
  if (chargeStatus?.title === "Fully Charged") {
    if (crrEl?.charges?.length > 0) {
      return message?.error(
        <div>
          This {visible?.categoryFrom} is already fully charged in:
          {crrEl?.charges?.map(({ chargeNumber, chargeId }, i) => (
            <>
              {" "}
              <a href={`/charges/${chargeId}`} target={"_blank"}>
                Charge {chargeNumber}
              </a>
              {i !== crrEl?.charges?.length - 1 ? "," : ""}
            </>
          ))}
        </div>
      );
    }
  }

  setSelectedGroup(crrEl?.id);
  setSelectedCatFrom(crrEl.label);
  setSelectedData({ ...crrEl });
};
