import React from "react";
import DashboardCard from "../DashboardCard/DashboardCard";

import "./DashboardCardsPanel.scss";

const DashboardCardsPanel = ({
  cardsData = [],
  totalCount,
  gridApi,
  moving,
  yard,
  atJobsite,
  totalFleet,
  setLocations,
  onClick,
}) => {
  return (
    <div className="dashboardCardsPanel">
      {totalCount !== undefined ? (
        <DashboardCard
          status="total"
          count={totalCount}
          gridApi={gridApi}
          color="#4791FF"
          onClick={onClick}
        />
      ) : null}

      {cardsData?.map(({ status, count, color, columnName }, i) => (
        <DashboardCard
          {...{
            key: i,
            status,
            count,
            color,
            columnName,
            gridApi,
            moving,
            yard,
            atJobsite,
            totalFleet,
            setLocations,
            onClick,
          }}
        />
      ))}
    </div>
  );
};

export default DashboardCardsPanel;
