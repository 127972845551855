import React from "react"
import { LabeledInput } from "."

const LabeledFilter = ({
  label,
  formItemName,
  noFormItem,
  initialValue,
  children,
}) => (
  <LabeledInput
    {...{ label, formItemName, noFormItem, initialValue, children }}
  />
)

export default LabeledFilter
