import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const applications = (
  programFields,
  updatedDynamicStates,
  // applicationsData,
  IdHandler
) => {
  const data = updatedDynamicStates[IdHandler];

  const getPeriodMonth = ({ periodTo, start, end }) => {
    if (periodTo.start <= start && periodTo.end >= end) {
      return end - start;
    } else if (periodTo.start >= start && periodTo.start <= end) {
      if (periodTo.end < end) return periodTo.end - periodTo.start;
      else if (periodTo.end > end) return end - periodTo.start;
    } else if (periodTo.end >= start && periodTo.end <= end) {
      if (periodTo.start < start) return periodTo.end - start;
      else if (periodTo.start > start) return periodTo.end - periodTo.start;
    } else return 0;
  };

  //we have a start period and a end period find the which months are more in between
  const getMonthData = () => {
    const start = getMonthsTimestamps();
    const end = getMonthsTimestamps("end");
    const toReturn = Array(12).fill({ id: [], value: 0 });
    applicationsData?.forEach(({ periodTo, applicationId }) => {
      const temp = Array(12).fill(0);
      temp.forEach((val, index) => {
        temp[index] = getPeriodMonth({
          periodTo,
          start: start[index],
          end: end[index],
        });
      });

      const index = temp.indexOf(Math.max(...temp));

      toReturn[index] = {
        id: [...(toReturn[index].id || []), applicationId],
        value: toReturn[index].value + 1,
      };
    });
    return toReturn;
  };

  const applicationStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    applicationStatus: () => ({
      title: "Requisition - Requisition Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Applications",
        field: "applicationStatus",
        labels: applicationStatus?.statusName,
        datasets: [
          {
            label: "Requisitions",
            data: applicationStatus?.statusName?.map(
              (status) =>
                data?.filter((a) => a?.applicationStatus === status)?.length
            ),
            backgroundColor: applicationStatus?.statusColor,
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //applicationStatus
    totalAmount: () => ({
      title: "Requisition - Total Amount",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Applications",
        field: "totalAmount",
        labels: months,
        custom: 0,
        datasets: [
          {
            label: "Current Payment",
            data: 0,
            backgroundColor: "#2DA365",
            borderWidth: 0,
          },
          {
            label: "Total Retainage",
            data: 0,
            backgroundColor: "#F6CB51",
            borderWidth: 0,
          },
          {
            label: "Total Rental With Tax",
            data: 0,
            backgroundColor: "#B5D99C",
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //totalAmount
  };
};
