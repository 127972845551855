// Importing the setFalsePermission function from a specified path
import { setFalsePermission } from "../../Roles/components/UserRoles/utils";

const addMissingKeys = (source, target) => {
  // Add missing description from source to target
  return {
    ...target,
    ...(source?.description ? { description: source.description } : {}),
  };
};

// Function to add missing children from superAdmin to existing children
const addMissingChildrenFromSuperAdmin = (
  superAdminChildren,
  existingChildren
) => {
  return (
    superAdminChildren
      ?.map((superAdminChild) => {
        // Check if there is a matching child in existingChildren
        const hasMatchingChild = existingChildren.find(
          (existingChild) => existingChild.title === superAdminChild.title
        );

        if (!hasMatchingChild) {
          // If there is no matching child, include it in the result

          const newChildren = addMissingChildrenFromSuperAdmin(
            superAdminChild.children || [],
            []
          );

          return {
            ...setFalsePermission(superAdminChild),
            ...(newChildren.length > 0 ? { children: newChildren } : {}),
            ...(superAdminChild?.description
              ? { description: superAdminChild?.description }
              : {}),
          };
        }

        //If there is a matching child but it has no children
        if (!hasMatchingChild.children) {
          //If there is a matching superAdminChild but it has no children, return hasMatchingChild
          if (!superAdminChild.children) {
            return {
              ...hasMatchingChild,
              ...(superAdminChild?.description
                ? { description: superAdminChild?.description }
                : {}),
            };
          }

          const newChild = addMissingChildrenFromSuperAdmin(
            superAdminChild.children,
            []
          );

          //If there is a matching superAdminChild and it has children, add missing children
          return {
            ...hasMatchingChild,
            ...(newChild.length > 0 ? { children: newChild } : {}),
            ...(superAdminChild?.description
              ? { description: superAdminChild?.description }
              : {}),
          };
        }

        // If there is a matching child, filter its children
        const filteredChildren = addMissingChildrenFromSuperAdmin(
          superAdminChild.children || [],
          hasMatchingChild.children
        );

        const newChildren = [
          ...hasMatchingChild.children.filter(
            (child) =>
              !filteredChildren.find(
                (newChild) => newChild.title === child.title
              )
          ),
          ...filteredChildren,
        ].filter(Boolean);

        // Only return the current child if it has filtered children
        return filteredChildren.length
          ? {
              ...hasMatchingChild,
              ...(newChildren.length > 0 ? { children: newChildren } : {}),
              ...(superAdminChild?.description
                ? { description: superAdminChild?.description }
                : {}),
            }
          : null;
      })
      .filter(Boolean) || []
  ); // Filter out null values from the result
};

// Function to add missing children from superAdminRole to adminRole
const addMissingChildren = (superAdminRole, role) => {
  if (!role?.children) {
    // If role has no children, return it
    if (!superAdminRole?.children) {
      return role;
    }

    // If role has no children but superAdminRole has, add missing children
    return {
      ...role,
      children: addMissingChildrenFromSuperAdmin(superAdminRole.children, []),
    };
  }

  // If role has children, get newChildren and update existing children
  const newChildren = addMissingChildrenFromSuperAdmin(
    superAdminRole.children,
    role.children
  );

  // Combine existing children with new children, filtering out duplicates
  const updatedChildren = [
    ...role.children.filter(
      (child) => !newChildren.find((newChild) => newChild.title === child.title)
    ),
    ...newChildren,
  ];

  // Return the updated role with combined children
  return {
    ...role,
    ...(updatedChildren.length > 0 ? { children: updatedChildren } : {}),
  };
};

// Function to generate access configuration based on superAdminData and data
export const generateAccessConfiguration = (superAdminData, data) => {
  // Use Object.keys to iterate over keys in superAdminData
  const newRoleData = Object.keys(superAdminData).reduce((result, key) => {
    // Check if the key is "Sidebar" or "Route" and handle accordingly
    if (
      (Array.isArray(superAdminData[key]) && key === "Sidebar") ||
      key === "Route"
    ) {
      // Map through superAdminData[key] to process each role
      result[key] = superAdminData[key].map((superAdminRole) => {
        // Find a matching role in data[key]
        const matchingRole = data[key]?.find(
          (role) => role.title === superAdminRole.title
        );

        // Set false permission for superAdminRole
        const newRole = setFalsePermission(superAdminRole);

        // If there is a matching role, add missing children, else use newRole
        return matchingRole
          ? addMissingKeys(superAdminRole, {
              ...addMissingChildren(superAdminRole, matchingRole),
              read: matchingRole?.read ?? true,
            })
          : newRole;
      });
    } else {
      // For keys other than "Sidebar" and "Route", copy directly from data
      result[key] = data[key];
    }

    return result;
  }, {});

  // Return the final generated access configuration
  return newRoleData;
};
