import React from "react";

import {
  generateInputFields,
  generatePropertyDamageInputFields,
} from "./helpers";
import { useStepData } from "../../hooks";
import { removeSpacesFromString } from "../../helpers";
import { NextStepInputs } from "../NextStepInputs";

const generateInput = {
  PropertyDamage: generatePropertyDamageInputFields,
  OtherClaim: generateInputFields,
};

const Claim = ({ inputData, savedRecord, setInputData }) => {
  const {
    schedules,
    projects,
    accounts: subContractorsOptions,
  } = useStepData("Claims");

  const category =
    removeSpacesFromString(
      savedRecord?.incidentCategory ||
        savedRecord?.safetyApplicationCategory ||
        ""
    ) === "PropertyDamage"
      ? "PropertyDamage"
      : "OtherClaim";

  const inputFields = generateInput[category]({
    schedules,
    projects,
    subContractorsOptions,
  });

  return <NextStepInputs {...{ inputData, setInputData, inputFields }} />;
};

export default Claim;
