import { camelToTitle } from "src/utils";
import { formatNumber } from "src/components/SidebarPages/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { formatDuration } from "src/components/SidebarPages/Fleet/fleetsLive/utils";

import "./PopoverStats.scss";

function PopoverStats({ dayStats = {} }) {
  function getFormattedValue(tt, val) {
    const title = tt.toLowerCase();

    if (
      (title.includes("date") && tt !== "updatedBy") ||
      title.includes("updatedat") ||
      tt === "createdAt"
    ) {
      return dayjsNY(val).format("MMM DD, YYYY HH:mm");
    } else if (title.includes("time") || title.includes("duration")) {
      return formatDuration(val, "minutes").text;
    } else if (title.includes("mile")) {
      return formatNumber(val, { unit: "mile" });
    }

    return val;
  }

  return (
    <div className="day-stats-container">
      {Object.keys(dayStats).map((key) => {
        return (
          <div className="single-day-stat" key={key}>
            <b className="stat-label">{camelToTitle(key)}&nbsp;</b>
            <span className="stat-value">
              {getFormattedValue(key, dayStats[key])}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default PopoverStats;
