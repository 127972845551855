import { useSelector } from "react-redux";

import CardLinked from "./components/Cards/CardLinked";
import CardNotLinked from "./components/Cards/CardNotLinked";

import "./UserLink.scss";

function UserLink({
  params: {
    rowData,
    dualRoleUser,
    setRowData,
    editable = false,
    endpoint,
    keyId,
    linkKey,
    elementName,
  },
  setNewValues,
}) {
  const { Items: users } = useSelector(
    (state) => state.userConfig.userConfiguration.allUsers
  );

  return (
    <>
      {!!rowData?.[linkKey] ? (
        <CardLinked
          {...{
            rowData,
            dualRoleUser,
            setRowData,
            users,
            editable,
            endpoint,
            keyId,
            linkKey,
            elementName,
          }}
        />
      ) : (
        <CardNotLinked
          {...{
            rowData,
            dualRoleUser,
            setRowData,
            users,
            editable,
            linkKey,
            endpoint,
            keyId,
            elementName,
          }}
        />
      )}
    </>
  );
}

export default UserLink;
