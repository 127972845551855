const requestReducer = (state, action) => {
  try {
    switch (action.type) {
      case "GET_DATA": {
        return {
          ...state,
          fetchedData: action.payload,
        };
      }

      case "SEARCH_FETCH": {
        return {
          ...state,
          fetchedData: {
            ...state.fetchedData,
            ...action.payload,
          },
        };
      }

      case "EDIT_LOGS": {
        return {
          ...state,
          editLogs: action.payload,
        };
      }

      case "VIEW_LOGS": {
        return {
          ...state,
          viewLogs: action.payload,
        };
      }

      case "SELECT_MAIN_FIELD": {
        return {
          ...state,
          selectedMainField: action.payload,
        };
      }

      case "SAFETY_OPTIONS": {
        return {
          ...state,
          safetyOptions: action.payload,
          selectedMainField: null,
        };
      }

      case "SAFETY_OPTIONS_POPULATE": {
        return {
          ...state,
          safetyOptions: action.payload,
        };
      }

      case "INSPECTION_OPTIONS": {
        return {
          ...state,
          inspectionOptions: action.payload,
        };
      }

      case "SELECTED_TEAM": {
        return {
          ...state,
          selectedTeam: action.payload,
        };
      }

      case "BIN": {
        if (Array.isArray(action.payload)) {
          return {
            ...state,
            BIN: action.payload,
          };
        } else {
          return {
            ...state,
            BIN: [action.payload],
          };
        }
      }

      case "DOCUMENTATION_RECORD_SELECT": {
        return {
          ...state,
          selectedMainField: {
            ...state.selectedMainField,
            recordName: action.payload,
          },
        };
      }

      case "RESET": {
        return {};
      }

      default: {
        return state || {};
      }
    }
  } catch (error) {
    console.error("Error in requestReducer: ", error);

    return state || {};
  }
};

export default requestReducer;
