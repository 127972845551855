//Gets the id for row to remove and add when the state changes (!important)

import { message } from "antd";
import { formatCurrency } from "../../../../../../utils";

export const getRowNodeId = (data) => data["id"];

export const updateCellValue = (prev, props, key, formData) => {
  const changedProw = prev?.find(({ id }) => props?.data?.id === id);

  if (key === "amountPercentage") {
    props.oldValue = (changedProw?.scopeAmount * props.oldValue) / 100;
    props.newValue = (changedProw?.scopeAmount * props.newValue) / 100;
  }

  // if (key === "amount") {
  props.oldValue = parseFloat(props.oldValue).toFixed(2);
  props.newValue = parseFloat(props.newValue).toFixed(2);

  // if (props.oldValue !== props.newValue) {
  // changedProw.key = parseFloat(props.newValue).toFixed(2);
  const difference = props.newValue - props.data.maxSuggestedAmount;

  if (Number(difference.toFixed(3)) > 0) {
    message.warning(
      <div>
        Selected amount is {formatCurrency(difference)} greater then estimated
        value. {" \n"}
      </div>
    );
    props.newValue = props.oldValue;
  }
  changedProw.amount = parseFloat(props.newValue);
  if (props?.data?.isExtraService) {
    const calcPercentage =
      (changedProw.amount / changedProw.scopeAmount) * 100 || 100;

    changedProw.amountPercentage = calcPercentage;

    if (key !== "amountPercentage") {
      changedProw.scopeAmount = changedProw.amount;
      changedProw.maxSuggestedAmount = changedProw.amount;
    }
  } else
    changedProw.amountPercentage =
      (props.newValue / changedProw?.scopeAmount) * 100;
  changedProw.taxAmount = parseFloat(props.newValue * formData.taxRate);
  changedProw.total =
    parseFloat(changedProw?.amount) +
    (changedProw?.tax ? changedProw?.taxAmount || 0 : 0);
  return JSON.parse(JSON.stringify(prev));
};
