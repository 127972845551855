import { MondayButton } from "../../../../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../../../Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { TrashIcon } from "../../../../../../../../../Communication/assets";

export default function footerButtons({
  onCancel = () => {},
  onDiscard = () => {},
  onSubmit = () => {},
  selectedFence,
}) {
  return [
    <MondayButton
      {...{
        Icon: <TrashIcon />,
        className: "mondayButtonRed",
        onClick: onDiscard,
      }}
      key="discard"
    >
      Discard Stop
    </MondayButton>,
    <div className="inner-footer" key="inner-footer">
      <MondayButton
        {...{
          Icon: <XIcon />,
          className: "mondayButtonRed",
          onClick: onCancel,
        }}
        key="cancel"
      >
        Cancel
      </MondayButton>
      {Boolean(selectedFence?.points?.length) && (
        <MondayButton
          {...{
            Icon: <TickIcon />,
            onClick: onSubmit,
          }}
          key="ok"
        >
          Link stop with {selectedFence?.address}
        </MondayButton>
      )}
    </div>,
  ];
}
