import { useEffect, useState } from "react";
import {
  disabledStartDateHandler,
  periodDatesPickerHandler,
} from "../../newApplicationFunctions";
import "./MonthPicker.scss";

import { Calendar, DatePicker, Select, message } from "antd";
import importedDayjs from "dayjs";
import moment from "moment";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import { parseInTz } from "../../../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const timezone = require("dayjs/plugin/timezone");
importedDayjs.extend(timezone);
const dayjs = importedDayjs.tz;

function createHeader(lastPeriod, isDarkMode) {
  return function ({ value, type, onChange, onTypeChange }) {
    const year = !!value ? value?.year() : dayjsNY().year();
    if (type === "month") {
      return <span>{year}</span>;
    }
    const startYear = dayjsNY().year() - 20;
    const endYear = dayjsNY().year() + 20;
    const yearOptions = [];
    const lastYearDisabled = dayjs(lastPeriod).year();
    for (let i = startYear; i <= endYear; i += 1) {
      yearOptions.push(
        <Select.Option
          key={i}
          disabled={
            !!lastPeriod &&
            ((dayjs(lastPeriod).month() === 11 && i <= lastYearDisabled) ||
              (dayjs(lastPeriod).month() < 11 && i < lastYearDisabled))
              ? true
              : false
          }
          value={i}
          className="year-item"
        >
          {i}
        </Select.Option>
      );
    }
    return (
      <div className="headerSelect">
        <Select
          value={String(year)}
          onChange={(newYear) => {
            let nextMonth = null;

            if (
              !!lastPeriod &&
              newYear === dayjs(lastPeriod).year() &&
              value?.month() > dayjs(lastPeriod).month() === false
            ) {
              nextMonth = dayjs(lastPeriod).add(1, "month").month();
            }

            !value
              ? newYear === dayjs(lastPeriod).year()
                ? onChange(dayjsNY().year(newYear).month(11).day(1))
                : onChange(dayjsNY().year(newYear).month(0).day(1))
              : !nextMonth
              ? onChange(value.clone().year(newYear))
              : onChange(value.clone().year(newYear).month(nextMonth));
          }}
          defaultValue={String(year)}
          popupClassName={isDarkMode && "darkDropDown"}
          showSearch={true}
          // options={yearOptions}
        >
          {yearOptions}
        </Select>
      </div>
    );
  };
}
const MonthPicker = ({
  setSelectedMonth,
  selectedMonth,
  setDueDate,
  lastPeriod,
  chosenSOV,
  dueDate,
  custom,
  setFormChanged = () => {},
  cancelModalVisible = false,
  isDarkMode,
}) => {
  const [cancelModal, setCanelModal] = useState(cancelModalVisible);

  useEffect(() => {
    setCanelModal(cancelModalVisible);
  }, [cancelModalVisible]);

  const periodDatesPicker = (date) =>
    periodDatesPickerHandler({
      setSelectedMonth,
      lastPeriod,
      custom,
      date,
      setDueDate,
    });
  const headerRender = createHeader(lastPeriod, isDarkMode);

  return (
    <div className="rangePickerContainer">
      <div className="calendarContainer">
        <Calendar
          fullscreen={false}
          mode="year"
          headerRender={headerRender}
          disabled={!chosenSOV}
          disabledDate={(current) => {
            return disabledStartDateHandler({
              current: parseInTz(current),
              selectedMonth,
              lastPeriod,
            });
          }}
          onChange={(date) => {
            setFormChanged(true);
            periodDatesPicker(parseInTz(date));
          }}
          defaultValue={
            !!selectedMonth?.start ? dayjs(selectedMonth?.start) : null
          }
        />
      </div>

      <div className="rangeInformation">
        <div className="applicationInformation"> Please pick period!!</div>
        <div className="period">
          Period From:{" "}
          {selectedMonth?.start
            ? dayjs(selectedMonth?.start)
                .tz("America/New_York")
                ?.format("MMMM DD, YYYY")
            : "--"}
        </div>
        <div className="period">
          Period To:{" "}
          {selectedMonth?.end
            ? dayjs(selectedMonth?.end)
                .tz("America/New_York")
                ?.format("MMMM DD, YYYY")
            : "--"}
        </div>
        <div className="period">
          Due Date:{" "}
          {dueDate
            ? dayjs(dueDate).tz("America/New_York")?.format("MMMM DD, YYYY")
            : "--"}
        </div>
      </div>
    </div>
  );
};

export default MonthPicker;
