import React from "react";
import { Button } from 'antd';
import "./LoaderButton.css";

const LoginButton = ({
  isLoading,
  htmlType,
  text,
  loadingText,
  className = "",
}) =>
  <Button
    className={className}
    loading={isLoading}
    htmlType={htmlType}
  >
    {!isLoading ? text : loadingText}
  </Button>

export default LoginButton