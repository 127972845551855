import "./NotFound.scss";
import NotFoundImg from "./src/404.webp";
import { MondayButton } from "../../commonComponents";
import { LeftArrow } from "../BasePage/src";
import { useSelector } from "react-redux";

const NotFound = () => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      className={`NotFoundViewContainer ${
        isDarkMode && "NotFoundViewContainerDark"
      }`}
    >
      <span className="title">PAGE NOT FOUND</span>
      <span className="subtitle">
        We're sorry, the page you requested could not be found, Please go back.
      </span>
      <img style={{ width: 800 }} src={NotFoundImg} />
      <MondayButton
        className="buttonStyle mondayButtonBlue"
        Icon={<LeftArrow />}
        onClick={() => {
          window.location.replace(
            window.location.protocol +
              "//" +
              window.location.host +
              "/" +
              (window.location.pathname.split("/")[1] === "fleets"
                ? "fleets/overview"
                : window.location.pathname.split("/")[1] === "fleet"
                ? "fleets/overview"
                : window.location.pathname.split("/")[1])
          );
        }}
      >
        Go back
      </MondayButton>
    </div>
  );
};
export default NotFound;
