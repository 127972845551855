import { SimplePLI } from "./GeneralModels";

export class WinterTarpPli extends SimplePLI {
  serviceId = 69;

  length;
  height;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
