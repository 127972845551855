import { createContext, useEffect, useState } from "react";
import { useLoading, useSessionSocket, useUserSession } from "../hooks";
import { useLocalStorage } from "../utils";
import { useNavigate } from "react-router-dom";
import { useCountdownTimer } from "../hooks/useCountDownTimer";
import Swal from "sweetalert2";
import {
  showSwalErrorMessage,
  showSwalSuccessMessage,
} from "../utils/swalWindowsMessages";
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { UserConfig } from "../actions";
import { driveApi } from "../integrations/DriveRequest";
import axios from "axios";
import { useSelector } from "react-redux";
import { setAuthUser } from "../actions/authUser";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [challengeName, setChallengeName] = useState(null);
  const [session, setSession] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [is2FaActive, setIs2FaActive] = useState(false);

  const { isLoading, startLoading, stopLoading } = useLoading(true);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [sessionId] = useLocalStorage("sessionId");
  const { socket: wsSessions } = useSessionSocket();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { startTimer } = useCountdownTimer();
  const userSession = useUserSession();

  const updateCurrentUserState = async (newUser) => {
    if (newUser?.isSuspended) {
      signOut().then(() =>
        showSwalErrorMessage({
          text: "Your account has been temporarily suspended. Please contact the administrator for further assistance.",
          showConfirmButton: true,
          timer: 0,
        })
      );
      return true;
    }

    await API.get("userConfiguration", "/userConfiguration")
      .then(async (res) => {
        //! User Session Configuration - DO NOT TOUCH UNLESS AUTHORIZED!!!
        await userSession.open(res);
        //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        if (res.isForceToResetPassword) {
          navigate("/resetPassword");
        }

        if (res.groupName === "Orders Manager") {
          navigate("/orders");
        }

        dispatch(UserConfig({ ...res }));

        if (!res?.permissionId) {
          await API.get("hotCredentials", "/hotCredentials").then((hot) => {
            axios
              .post("https://oauth2.googleapis.com/token", {
                clientId: hot.find(({ credentialId }) => credentialId === "3")
                  ?.credentialValue,
                client_secret: hot.find(
                  ({ credentialId }) => credentialId === "4"
                )?.credentialValue,
                refresh_token: hot.find(
                  ({ credentialId }) => credentialId === "1"
                )?.credentialValue,
                grant_type: "refresh_token",
              })
              .then((token) => {
                const driveRequest = driveApi({
                  accessToken: token.data.access_token,
                });
                driveRequest
                  .share(
                    hot.find(({ credentialId }) => credentialId === "10")
                      ?.credentialValue,
                    "writer",
                    "user",
                    res?.userName
                  )
                  .then(async (permissionId) => {
                    await permissionId?.json().then((e) => {
                      API.patch("userConfiguration", "/userConfiguration", {
                        body: {
                          permissionId: e.id,
                        },
                      }).then(() =>
                        dispatch(UserConfig({ ...res, permissionId: e.id }))
                      );
                    });
                  });
              });
          });
        }
        if (res?.groupName === "Foreman") {
          navigate("/field");
        }
      })
      .catch(async (e) => {
        await API.get("userGroups", "/userGroups").then(async (res) => {
          let userDepartment = res.find(
            (el) =>
              el.groupName === newUser.attributes["custom:dep"] &&
              el.departmentName === newUser.attributes["custom:department"]
          ).groupConfigs;
          await API.post("userConfiguration", "/userConfiguration", {
            body: {
              nameOfUser: `${newUser.attributes["given_name"]} ${newUser.attributes["family_name"]}`,
              groupName: `${newUser.attributes["custom:dep"]}`,
              departmentName: `${newUser.attributes["custom:department"]}`,
              phone_number: `${newUser.attributes["phone_number"]}`,
              userName: `${newUser.attributes["email"]}`,
              routeConfig: userDepartment.routeConfig,
              searchConfig: userDepartment.searchConfig,
              sidebarConfig: userDepartment.sidebarConfig,
              cognitoUserId: newUser.attributes["sub"],
            },
          }).then(async (response) => {
            if (
              `${newUser.attributes["custom:department"]}` === "Orders Manager"
            ) {
              window.location.pathname = "/orders";
            }
            if (`${newUser.attributes["custom:dep"]}` === "Foreman") {
              navigate("/field");
            }
          });
        });
      });
  };

  const checkMFAStatus = async (newUser) => {
    try {
      startLoading();
      const mfaType = await Auth.getPreferredMFA(newUser);
      const activeMfa = mfaType === "SOFTWARE_TOKEN_MFA";
      setIs2FaActive(activeMfa);

      if (!activeMfa) navigate("/two-factor-authentication");
    } catch (error) {
      console.error("Error fetching MFA preference", error);
      setIs2FaActive(false);
    } finally {
      stopLoading();
    }
  };

  const checkUser = async () => {
    startLoading();
    try {
      const newUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      await checkMFAStatus(newUser);
      setUser(newUser);

      dispatch(
        setAuthUser({
          ...newUser.attributes,
          department: newUser.attributes["custom:department"],
        })
      );
      await updateCurrentUserState(newUser);

      setIsAuthenticated(true);
    } catch (error) {
      navigate("/login");
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    checkUser();
  }, [isAuthenticated]);

  const signUp = async (data, code) => {
    const {
      email,
      password,
      phone_number,
      firstName,
      lastName,
      invitationCode,
    } = data;

    startLoading();

    try {
      const result = await Auth.signUp({
        username: email,
        password,
        attributes: {
          phone_number:
            "+" +
            phone_number
              .replace("(", "")
              .replace(")", "")
              .replace(" ", "")
              .replace("-", ""),
          family_name: lastName,
          given_name: firstName,
          "custom:department": "Executive",
          "custom:dep": "Admin",
          "custom:code": invitationCode,
          "custom:invitationUrl": code,
        },
      });

      setUser(result);

      return true;
    } catch (error) {
      showSwalErrorMessage({
        text: error.message.replace("PreSignUp failed with error ", ""),
        timer: 10000,
      });

      return false;
    } finally {
      stopLoading();
    }
  };

  const confirmSignUp = async (username, confirmationCode) => {
    startLoading();
    try {
      const result = await Auth.confirmSignUp({ username, confirmationCode });
      if (result === "SUCCESS") {
        showSwalSuccessMessage({
          text: "New account is confirmed",
          timer: 1000,
        });
        navigate("/login");
      }

      return true;
    } catch (error) {
      if (error.code === "CodeMismatchException") {
        showSwalErrorMessage({
          text: "Invalid verification code provided, please try again.",
        });
        return;
      }

      showSwalErrorMessage({
        text: "There was a problem confirming you account, please try again.",
        timer: 10000,
      });

      return false;
    } finally {
      stopLoading();
    }
  };

  const getUserData = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
      await updateCurrentUserState(currentUser);

      setIsAuthenticated(true);

      if (currentUser) navigate("/");
    } catch (error) {
      throw new Error();
    }
  };

  const confirmSignIn = async (values) => {
    const { code } = values;

    startLoading();
    try {
      await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");
      await getUserData();
      setCodeSent(false);
      return true;
    } catch (error) {
      showSwalErrorMessage({
        text: error.message,
      });
    } finally {
      stopLoading();
    }
  };

  const signIn = async (username, password) => {
    startLoading();
    try {
      const result = await Auth.signIn(username, password);
      setUser(result);
      if (result.challengeName === "SOFTWARE_TOKEN_MFA") {
        setCodeSent(true);
        return;
      }

      if (result?.challengeName) {
        setChallengeName(result.challengeName);
        setSession(result.Session);
      }
      await getUserData();

      return true;
    } catch (error) {
      if (error.message.includes("Password attempts exceeded")) {
        const retryTime = new Date().getTime() + 3 * 60 * 1000;
        localStorage.setItem("retryTime", retryTime.toString());
        startTimer(retryTime);
        showSwalErrorMessage({
          text: "Password attempts exceeded. Please try again after 3 minutes.",
        });
      } else {
        showSwalErrorMessage({
          text: error.message,
        });
      }
      return false;
    } finally {
      stopLoading();
    }
  };

  const signOut = async () => {
    startLoading();
    try {
      if (wsSessions.readyState === 1) {
        await wsSessions.send(
          JSON.stringify({
            request: "logout",
            body: {
              userId: userConfiguration?.identityId,
              sessionId: sessionId,
              config: process.env.NODE_ENV === "production" ? "prod" : "dev",
            },
          })
        );
      }
      await userSession.close(userConfiguration);

      await Auth.signOut();

      setUser(null);
      navigate("/login");
      setIsAuthenticated(false);
      dispatch(UserConfig(null));
    } catch (error) {
      console.error("Error signing out:", error);
    } finally {
      stopLoading();
    }
  };

  const resetPassword = async (email) => {
    startLoading();
    try {
      await Auth.forgotPassword(email);

      showSwalSuccessMessage({
        text: "A code has been sent to your email. Please check your inbox",
        showConfirmButton: true,
      });
      setCodeSent(true);

      return true;
    } catch (error) {
      showSwalErrorMessage({ text: error.message, showConfirmButton: true });
    } finally {
      stopLoading();
    }
  };

  const confirmResetPassword = async (data) => {
    const { email, code, password } = data;
    startLoading();

    try {
      await Auth.forgotPasswordSubmit(email, code, password);

      Swal.fire({
        text: "Password changed successfully!",
        icon: "success",
        showConfirmButton: true,
      });

      setCodeSent(false);
      navigate("/login");

      return true;
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      stopLoading();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        signUp,
        confirmSignUp,
        signIn,
        signOut,
        challengeName,
        isAuthenticated,
        resetPassword,
        confirmResetPassword,
        codeSent,
        checkUser,
        confirmSignIn,
        is2FaActive,
        checkMFAStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
