import React from "react";
import {
  ExcelIcon,
  PdfDocIcon,
  WordDocIcon,
} from "../../../../../../../../../../assets";
import { Tooltip } from "antd";

import "./file-item.scss";

function FileItem({ file, onClick }) {
  const { mimeType, name } = file;

  const getIcon = (mimeType) => {
    switch (mimeType) {
      case "application/pdf":
        return <PdfDocIcon style={{ height: "20px", width: "16px" }} />;
      case "application/msword":
        return <WordDocIcon style={{ height: "20px", width: "16px" }} />;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return (
          <ExcelIcon
            style={{ height: "20px", width: "16px" }}
            data-testid="excel-icon"
          />
        );
      default:
        return (
          <img
            data-testid="file-img"
            src="https://ssl.gstatic.com/docs/doclist/images/icon_10_generic_list.png"
          />
        );
    }
  };

  return (
    <Tooltip title={`Click for file preview`} placement="left">
      <div
        className={`file-item-container`}
        onClick={onClick}
        data-testid={`file-item-container-${name}`}
      >
        {getIcon(mimeType)}
        <h5>{name}</h5>
      </div>
    </Tooltip>
  );
}

export default FileItem;
