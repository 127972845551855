import { Popover } from "antd";
import { Stepper } from "../../../../commonComponents";
import ActionComponent from "../../components/CardView/ActionComponent/ActionComponent";
import { ActionsIcon } from "../../src";
import "./AbsoluteStepper.scss";

const AbsoluteStepper = ({ params, id }) => {
  let { currentStep, steps } = params;
  return (
    <>
      <div className="absoluteStepperContainer">
        <div className="absuluteDividerStyle"></div>
        <Stepper
          {...{
            className: "StepperCard",
            currentStep,
            steps,
            stepRenderer: false,
          }}
        />
        <div className="absuluteDividerStyle"></div>
      </div>
      <Popover
        overlayClassName="AbsoluteHeaderComponentHeaderActionWindow"
        overlayStyle={{ borderRadius: 50 }}
        placement="bottom"
        content={ActionComponent}
        trigger="click"
      >
        <span className="AbsoluteHeaderComponentHeaderActions">
          <ActionsIcon />
        </span>
      </Popover>
    </>
  );
};
export default AbsoluteStepper;
