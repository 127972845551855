import { useState, useMemo, useCallback } from "react";
import { MondayButton } from "../../../../../commonComponents";
import { NormalSizedModal } from "../../../../../SidebarPages/Fleet/components";
import HTMLPreview from "./HTMLPreview";
import { useRedux } from "../../../../../SidebarPages/hooks";
import { mapTagToData } from "./mappers/dataMapper";
import ProposalDocumentMerger from "../../../ProposalPages/FirstProposalPage/ProposalDocumentMerger";
import Swal from "sweetalert2";
import axios from "axios";
import { b64toBlob } from "../../../ProposalPages/MergePdfs/MergePdfs";
import WarningsPreview from "./WarningsPreview/WarningsPreview";
import "./RealTimeTakeOffPreview.scss";
import FlashWarning from "src/components/ProposalBuilder/NewProposal/ProposalStepper/FlashWarning/FlashWarning";
import QuickPreview from "./QuickPreview/QuickPreview";
import FullPreview from "./FullPreview/FullPreview";
function RealTimeTakeOffPreview({ editorRef }) {
  const [visible, setVisible] = useState(false);
  const [proposalData, setProposalData] = useRedux("proposalData");
  const [mergedPdf, setMergedPdf] = useState(null);
  const [madeSenseData, setMadeSenseData] = useState(null);
  const [url, setUrl] = useState("");
  const [internalCommands, setInternalCommands] = useState({
    quickPreview: true,
    finalizedPreview: false,
  });
  const [refreshToggle, setRefreshToggle] = useState(false);
  const myDataPreview = useMemo(() => {
    console.log("editorRef", editorRef);
    // return;
    const myMadeSenseData = mapTagToData({
      proposalData,
      html: editorRef.current.value,
    });
    console.log("myMadeSenseData", myMadeSenseData);
    return myMadeSenseData;
  }, [proposalData, editorRef, refreshToggle]);

  const onFinalize = async ({ newTab }) => {
    Swal.fire({
      title: "<strong>Finalizing...</strong>",
      icon: "info",
      html: "Please wait! ",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      // const myMadeSenseData = myDataPreview;
      // const MY_DATA = await onPreparePreview(myMadeSenseData);
      // const blobUrl = URL.createObjectURL(MY_DATA);

      // console.log("MY_DATA", MY_DATA);
      // // window.open(blobUrl);
      // setMergedPdf(blobUrl);
      await onDownload(newTab);

      // if (newTab) {
      //   // window.open(blobUrl);
      //   await onDownload();
      // }
      setInternalCommands({
        quickPreview: false,
        finalizedPreview: true,
      });
    } catch (err) {
      console.log("err", err);
      Swal.fire({
        title: "<strong>Finalize Proposal Error</strong>",
        icon: "error",
        html: "Your proposal could not be finalized! ",
      });
    } finally {
      Swal.close();
    }
  };

  const onSuperFastPreview = () => {
    setInternalCommands({
      quickPreview: true,
      finalizedPreview: false,
    });
  };

  const onDownload = async (openNewTab) => {
    try {
      const response = await axios.post(
        `https://leadmanager-express-backend.ue.r.appspot.com/api/htmlDocusealConverter`,
        // `https://leadmanager-express-backend.ue.r.appspot.com/api/${endPoint}`,
        {
          html: myDataPreview,
        }
      );
      setUrl(response.data?.documents?.[0]?.url);
      if (openNewTab) {
        window.open(response.data?.documents?.[0]?.url);
      }

      console.log("response", response);

      //   if (!response.ok) {
      //     const errorBody = await response.text();
      //     throw new Error(
      //       `HTTP error! Status: ${response.status}, Body: ${errorBody}`
      //     );
      //   }

      //   const data = await response.json();
      //   console.log("Document conversion successful:", data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error converting HTML to document:", error.message);
    }
  };

  console.log("proposalData", proposalData);
  return (
    <div className="realtime_takeoffproposal_preview_cont">
      <div className="quick_preview_cont">
        {" "}
        <div
          className="quick_preview_buttons_cont"
          style={{
            display: "flex",
            flexDirection: "row",
            // width: "1020px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            bprderRadius: "5px",
            padding: "10px",
            // display: "flex",
            // justifyContent: "flex-end",
            alignItems: "flex-end",
            gap: "5px",
            // gap: "10px",
            // marginBottom: "10px",
          }}
        >
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={() => onFinalize({ newTab: true })}
          >
            New Tab
          </MondayButton>{" "}
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={() => onFinalize({ newTab: false })}
          >
            Full Preview
          </MondayButton>{" "}
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={() => setRefreshToggle((prev) => !prev)}
          >
            Refresh
          </MondayButton>{" "}
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={() => onSuperFastPreview()}
          >
            Fast Preview
          </MondayButton>{" "}
        </div>{" "}
      </div>
      <div className="quick-preview">
        {internalCommands?.quickPreview && (
          <QuickPreview html={myDataPreview || `No Preview Available`} />
        )}
        {internalCommands?.finalizedPreview && <FullPreview pdfUrl={url} />}
      </div>
    </div>
  );
}

export default RealTimeTakeOffPreview;

const onPreparePreview = async (htmlTakeOff) => {
  return await axios
    .post(
      "http://localhost:8080/api/mergePDFs",
      {
        // originalPdf: await blobToBase64(originalPdf.originFileObj),
        // pdfToAttach: await blobToBase64(pdfToAttach.originFileObj),
        originalPdf: [],
        // [
        //   ...(await Promise.all(FILES_TO_SEND?.map((el) => el?.fileSrc))),
        // ]?.filter((el) => typeof el === "string"),
        configuration: {
          fileList: [],
          pagesConfig: {},
          htmlTakeOff,
          // fileList: FILES_TO_SEND,
          // pagesConfig: customConfigPages,
          // ...(idxConfigs || {}),
        },
      }
      // { responseType: "blob" }
    )
    .then(async ({ data: mergedPdf }) => {
      console.log("mergedPdf", mergedPdf);
      var blob = b64toBlob(mergedPdf, "application/pdf");

      // const blobURL = URL.createObjectURL(blob);
      // const theWindow = window.open(blobURL);
      // const theDoc = theWindow.document;
      // const theScript = document.createElement("script");
      // function injectThis() {
      //   window.print();
      // }
      // theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
      // theDoc.body.appendChild(theScript);
      // setPreviewPDF(true);
      // setMergedPdf(blobURL);
      // window.open(fileURL);
      return blob;
    })
    .catch((e) => {
      console.log("error", e);
      // Swal.fire({
      //   title: "<strong>Error</strong>",
      //   icon: "error",
      //   html: "There was an error merging your PDFs! ",
      // });
    })
    .finally(() => {
      // Swal.close();
    });
};
