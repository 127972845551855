import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import "./Footer.scss";
import { Dropdown, Menu } from "antd";
import { API } from "aws-amplify";
import { message } from "antd";
import {
  ConfirmationModal,
  MultiLevelTreeLogs,
} from "../../../../commonComponents";
import InvoiceModal from "../CreateInvoice/InvoiceModal";
import { MondayButton } from "../../../../commonComponents";
import { EditIcon } from "../../../DynamicView/src";
import { useLocalStorageLock } from "../../../../../hooks/useLocalStorageLock";
import { OkWarningModal } from "../../../../commonComponents/OkWaningModal/OkWarningModal";
import { useRedux } from "../../../hooks/useRedux";
import { DataNeededForInvoiceForm } from "../../Tabs/Invoice/Invoice";
import { onDeleteClickHandler } from "../utilities";
import { useNavigate } from "react-router-dom";
import { TaskIcon } from "src/icons/NotificationIcons";
import ToDoButton from "../../../ToDos/components/ToDoButton/ToDoButton";

export default function Footer({
  selectedData,
  projectData,
  accountData,
  onClose,
  setData,
  setSelectedData,
  fetchAndSetInvoices,
  isEditable,
  editLogs = [],
  openToDo = () => {},
}) {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [isWritable, setIsWritable] = useRedux("invoiceIsWritable");
  const { setAllInvoices = () => {} } = useContext(DataNeededForInvoiceForm);
  const navigate = useNavigate();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const { isOpen, disableReopeningTab, closeOpenedTab } = useLocalStorageLock();

  const openEditInvoice = () => {
    navigate(`/invoices/${selectedData?.invoiceId}`);
  };

  const onEditButtonClickHandler = () => {
    if (isOpen) {
      setIsOpenedModal(true);
      setShowEditModal(false);
    } else {
      setIsOpenedModal(false);
      setShowEditModal(true);
    }
  };

  const onHandleExit = () => {
    setIsWritable(false);
    setShowEditModal(false);
  };

  useEffect(() => {
    disableReopeningTab(selectedData?.invoiceId, undefined, true);
  }, [selectedData?.invoiceId, selectedData]);

  console.log({ selectedData });

  return (
    <>
      <div className={"footerInvoiceDrawerWrapper"}>
        <div className={"container"}>
          <div className={"editContainer"}>
            <MondayButton
              onClick={() => {
                isEditable
                  ? // onEditButtonClickHandler()
                    openEditInvoice()
                  : message.error(
                      "You are unable to edit this invoice because the project status is Canceled!",
                      5
                    );
              }}
              buttonStyle={!isEditable ? { cursor: "not-allowed" } : {}}
              Icon={<EditIcon />}
              className={isEditable ? "editInvoice" : "mondayButtonGrey"}
            >
              Edit Invoice
            </MondayButton>
          </div>
          <ToDoButton
            {...{
              recordId: selectedData?.invoiceId,
              recordName: `${selectedData?.project} Invoice# ${selectedData?.invoiceNumber}`,
            }}
          />
          <div className={"actionsContainer"}>
            <Dropdown
              className={"hoverMeContainer"}
              menu={{
                items: [
                  {
                    key: "1",
                    onClick: () => setEditLogsVisibility(true),
                    label: "Show Logs",
                  },
                  {
                    key: "2",
                    danger: true,
                    onClick: () => setConfirmModalVisible(true),
                    label: "Delete",
                  },
                ],
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div className={"hoverMeText"}>
                  More actions
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="38px"
                    viewBox="0 0 25 25"
                    width="32px"
                    fill="#fff"
                  >
                    <path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"></path>
                  </svg>
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
        <ConfirmationModal
          {...{
            visible: confirmModalVisible,
            setVisible: setConfirmModalVisible,
            onConfirm: () =>
              onDeleteClickHandler({
                setData,
                onClose,
                setConfirmModalVisible,
                setAllInvoices,
                selectedData,
                authenticatedUser,
                projectData,
              }),
            onCancel: () => {
              setConfirmModalVisible(false);
            },
            text: "Are you sure you want to delete this invoice?",
            title: "Delete Invoice",
          }}
        />
        {isOpenedModal ? (
          <OkWarningModal
            isOpenedModal={isOpenedModal}
            onButtonClickHandler={() => setIsOpenedModal(false)}
            onCloseClickHandler={() => {
              closeOpenedTab(selectedData?.invoiceId);
              setIsOpenedModal(false);
              setSelectedData();
            }}
            title="Invoice"
          />
        ) : (
          showEditModal && (
            <InvoiceModal
              {...{
                handleExit: () => onHandleExit(),
                projectData,
                accountData,
                invoiceData: selectedData,
                onClose,
                setData,
                setSelectedData,
                fetchAndSetInvoices,
              }}
            />
          )
        )}
      </div>
      {editLogsVisibility && (
        <MultiLevelTreeLogs
          {...{
            visible: editLogsVisibility,
            setVisible: setEditLogsVisibility,
            logsData: editLogs || [],
            title: "Invoice Logs",
          }}
        />
      )}
    </>
  );
}
