import dayjs from "dayjs";
import { compareIncluding } from "../../../../../utils";
import { fleetStatus } from "../../../../datas";

export const dateFormat = "MM/DD/YYYY";
export const yearFormat = "YYYY";
export const apiRoutes = {
  fleet: "fleet",
  drivers: "drivers",
  fleetDispatching: "fleetDispatching",
  projects: "projects",
  fleetFields: "fleetFields",
  contacts: "contacts",
  accounts: "accounts",
  fleetDocumentation: "fleetDocumentation",
};

export const dataJSON = ({ params = {}, documentation }) => {
  const {
    fleetName,
    fleetYear,
    fleetMake,
    fleetModel,
    fleetTitle,
    fleetTitleIssued,
    fleetNumber,
    fleetBodyType,
    vinNumber,
    licensePlate,
    registrationDate,
    registrationExpDate,
    registrationNumber,
    inspectionDate,
    inspectionExpDate,
    insuranceDate,
    insuranceExp,
    insuranceCompany,
    brokerContact,
    fuelType,
    createdBy,
    lastModifiedBy,
    assignedDriver,
    fireExtinguisher,
    fEInspectionDate,
    fEExpirationData,
    dmvNumber,
    ezPassTag,
    companyRegistered,
    maxCapacity,
    ownership,
  } = params;
  return [
    {
      category: "Vehicle Information",
      values: [
        { label: "Vehicle Name", value: fleetName },
        { label: "Year", value: fleetYear },
        { label: "Make", value: fleetMake },
        { label: "Model", value: fleetModel },
        { label: "Body Type", value: fleetBodyType },
        { label: "VIN", value: vinNumber },
        { label: "License Plate", value: licensePlate },
        { label: "Title", value: fleetTitle },
        { label: "Title Issued", value: fleetTitleIssued },
        { label: "Number", value: fleetNumber },
        { label: "Fuel Type", value: fuelType },
        { label: "Ownership Type", value: ownership },
        { label: "Created By", value: createdBy },
        { label: "Last Modified By", value: lastModifiedBy?.name },
      ],
    },
    {
      category: "Documents",
      values: [
        [
          [
            {
              label: "Registration Date",
              value: dayjs(registrationDate).format(dateFormat),
            },
            {
              label: "Expiration Date",
              value: dayjs(registrationExpDate).format(dateFormat),
            },
            { label: "Number", value: registrationNumber },
          ],
          documentation?.find(({ category }) =>
            compareIncluding(category, "registration documents")
          )?.values,
        ],

        [
          [
            {
              label: "Inspection Date",
              value: dayjs(inspectionDate).format(dateFormat),
            },
            {
              label: "Expiration Date",
              value: dayjs(inspectionExpDate).format(dateFormat),
            },
          ],
          documentation?.find(({ category }) =>
            compareIncluding(category, "inspection documents")
          )?.values,
        ],

        [
          [
            {
              label: "Issued Date",
              value: dayjs(insuranceDate).format(dateFormat),
            },
            {
              label: "Expiration Date",
              value: dayjs(insuranceExp).format(dateFormat),
            },
            { label: "Insurance Company", value: insuranceCompany },
            { label: "Broker Contact", value: brokerContact },
            { label: "Assigned to driver", value: assignedDriver },
          ],
          documentation?.find(({ category }) =>
            compareIncluding(category, "insurance documents")
          )?.values,
        ],
      ],
    },
    {
      category: "Fire Extinguisher",
      values: [
        {
          label: "Inspection Date",
          value: dayjs(fEInspectionDate).format(dateFormat),
        },
        {
          label: "Expiration Date",
          value: dayjs(fEExpirationData).format(dateFormat),
        },
        { label: "Number", value: fireExtinguisher },
      ],
    },
    {
      category: "DMV and Tolls",
      values: [
        { label: "HUT STICK#", value: dmvNumber },
        { label: "EZPass tag#", value: ezPassTag },
        { label: "Company Registered", value: companyRegistered },
        { label: "Max Capacity", value: maxCapacity },
      ],
    },
  ];
};
