import { Tooltip } from "antd";
import React, { useState } from "react";
import { SignatureIcon } from "../../../../../../assets";

import "./signatures.scss";

function Signatures() {
  const [withSignature, setWithSignature] = useState(true);

  return (
    <Tooltip placement="top" {...{ title: "Signatures" }}>
      <div
        {...{
          style: withSignature
            ? {
                backgroundColor: "rgba(32, 33, 36, 0.122)",
                borderRadius: 5,
              }
            : {},
          className: "signatures-container",
          onClick: () => {
            setWithSignature((prev) => !prev);
          },
        }}
        data-testid="signatures-container"
      >
        <div className="signatures-icon-conatiner">
          <SignatureIcon />
        </div>
      </div>
    </Tooltip>
  );
}

export default Signatures;
