/**
 * Function that narrows down the actual vehicle
 * locations to 10 waypoints in order to be used by
 * the directions service
 * @param {Array} locations
 */
function findWaypoints(locations) {
  let waypointArr = [];

  if (locations.length) {
    /**
     * We make the length of the results divisible by 10, since requests
     * with more than 10 waypoints are billed at a higher rate
     */
    if (locations.length > 10) {
      while (locations.length % 10 !== 0) {
        let removeIndex = Math.floor(
          Math.random() * (locations.length - 2) + 1
        );
        locations.splice(removeIndex, 1);
      }
    }

    let iterator = Math.floor(locations.length / 10) || 1;
    for (let i = 0; i < locations.length; i += iterator) {
      waypointArr.push({
        date: locations[i]["date"],
        speed: locations[i]["speed"],
        location: {
          lat: locations[i]["latitude"],
          lng: locations[i]["longitude"],
        },
      });
    }
  }

  return waypointArr;
}

export default findWaypoints;
