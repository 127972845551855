import React, { createContext, useContext, useState } from "react";

const OtherModalWrapperContext = createContext();

export const useOtherModalContext = () => {
  return useContext(OtherModalWrapperContext);
};

export const OtherModalWrapperProvider = ({ children }) => {
  const [visibleReport, setVisibleReport] = useState(false);

  return (
    <OtherModalWrapperContext.Provider
      value={{ visibleReport, setVisibleReport }}
    >
      {children}
    </OtherModalWrapperContext.Provider>
  );
};
