import React from "react";
import loadScript from "load-script";
import { gapi } from "gapi-script";

// const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";

let scriptLoadingStarted = false;

export default class GoogleChooser extends React.Component {
  constructor(props) {
    super(props);
    this.disabled = props.disabled;
    this.onApiLoad = this.onApiLoad.bind(this);
    this.onChoose = this.onChoose.bind(this);
  }

  componentDidMount() {
    if (this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad();
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    } else {
      // is loading
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.disabled !== prevProps.disabled) {
      this.disabled = this.props.disabled;
    }
  }

  isGoogleReady() {
    return !!gapi;
  }

  isGooglePickerReady() {
    return !!window.google.picker;
  }

  onApiLoad() {
    gapi.load("picker");
  }

  onChoose() {
    if (!this.isGoogleReady() || !this.isGooglePickerReady() || this.disabled) {
      return null;
    }

    const token = this.props.token;

    if (token) {
      this.createPicker(token);
    }
  }

  createPicker(oauthToken) {
    this.props.onAuthenticate(oauthToken);

    if (this.props.createPicker) {
      return this.props.createPicker(window.google, oauthToken);
    }
  }

  render() {
    return (
      <div
        data-testid="gpicker-button"
        onClick={this.onChoose}
        style={{
          width: "fit-content",
          height: "fit-content",
          cursor: "pointer",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
