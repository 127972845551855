import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { Table, Button, Form } from "antd";

import { fetchData } from "../../../Fleet/utils";
import { validateForm } from "../../../Fleet/utils";
import { columns, getValuesToEdit } from "./tableColumns";
import NewFleetInventory from "../../../FleetsMaintenances/Tabs/FleetInventory/Modals/NewFleetInventory";
import { InputComponent } from "../../../Fleet/components";
import { MondayButton } from "../../../../commonComponents";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { XIcon } from "../../../Communication/assets";
import {
  Tick,
  TickIcon,
} from "../../../../pages/Settings/settingsComponents/Roles/src";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";

import "./FleetItems.scss";

function FleetItems(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [allItems, setAllItems] = useState([]);
  const [newItemsCreated, setNewItemsCreated] = useState(0);
  const [selectedItems, setSelectedITems] = useState(
    structuredClone(props?.params?.rowData ?? [])
  );
  const [defaultItems] = useState(
    structuredClone(props?.params?.rowData ?? [])
  );
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [newFleetInventory, setNewFleetInventory] = useState(false);
  //holds the total available amount for the selected items
  const [totalAmountsForDefault, setTotalAmountsForDefault] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData("fleetInventory")
      .then((res) => {
        setAllItems(res);
        let t = {};
        defaultItems?.forEach((item) => {
          let i = res?.find((el) => el?.itemId === item?.itemId);
          if (!!i) {
            t[i?.itemId] = parseInt(i?.itemQuantity) + parseInt(item?.quantity);
          }
        });
        setTotalAmountsForDefault(t);
      })
      .catch((err) => {
        console.log("Error Getting Inventory: ", err);
      });
  }, []);

  function onCancel() {
    props.setEditMode(false);
    setCancelModalVisible(false);
  }

  function onRemoveItem(item) {
    setSelectedITems(
      selectedItems?.filter((el) => el?.itemId !== item?.itemId)
    );
  }

  function changeItemQuantity(value, item) {
    if (!isNaN(parseInt(value)) && parseInt(value) > -1) {
      let tmp = structuredClone(selectedItems);
      let tmpIndex = tmp?.findIndex((el) => el?.itemId === item?.itemId);
      if (tmpIndex > -1) {
        tmp[tmpIndex] = { ...item, quantity: parseInt(value) };
        setSelectedITems(tmp);
      }
    }
  }

  function saveHandler() {
    /**
     * Before saving we need to compare the amount of items that are
     * retrieved from the inventory. We also need to update the amounts
     * and statuses of the inventory items
     */
    let itemsToUpdate = getValuesToEdit({
      selectedItems,
      defaultItems,
      allItems,
    });

    //we update the states of all the inventory items before saving
    itemsToUpdate?.forEach(({ id, body }) => {
      API.put("fleetInventory", `/fleetInventory/${id}`, {
        body: { ...body },
      });
    });

    /**
     * If the user creates a new inventory item inside the modal,
     * the main view will have no way of actually knowing it, so we
     * need to get the new data and put it in the dynamic view state
     */
    let bodyToSend = {
      inventoryItems: selectedItems?.map((item) => ({
        itemId: item?.itemId,
        quantity: item?.quantity?.toString(),
        itemNotes: form.getFieldValue(`NOTES:${item?.itemId}`),
      })),
      newItemsCreated: allItems.slice(allItems?.length - newItemsCreated - 1),
    };

    let prevLog = {
      id: "inventoryItems",
      Card: "FleetItems",
      title: "Inventory Items",
      params: {
        rowData: defaultItems?.map((item) => ({
          costPerUnit: item?.costPerUnit,
          itemDescription: item?.itemDescription,
          itemId: item?.itemId,
          itemNotes: item?.itemNotes || "",
          quantity: item?.quantity?.toString(),
          itemPartNo: item?.itemPartNo,
          itemName: item?.itemName,
        })),
      },
    };

    let nextLog = {
      id: "inventoryItems",
      Card: "FleetItems",
      title: "Inventory Items",
      params: {
        rowData: selectedItems?.map((item) => ({
          costPerUnit: item?.costPerUnit,
          itemDescription: item?.itemDescription,
          itemId: item?.itemId,
          itemNotes: form.getFieldValue(`NOTES:${item?.itemId}`),
          quantity: item?.quantity?.toString(),
          itemPartNo: item?.itemPartNo,
          itemName: item?.itemName,
        })),
      },
    };

    props.getEditedValue(bodyToSend, {}, {}, prevLog, nextLog);
    onCancel();
  }

  return (
    <div
      className={`inventoryItemsEditCardContainer ${
        isDarkMode ? "inventory-items-dark" : ""
      }`}
    >
      <Form form={form}>
        <div className="addItemsSection">
          <InputComponent
            {...{
              label: "Inventory Items",
              type: "select",
              mode: "multiple",
              formItemName: "newInventoryItems",
              filterOption(input, option) {
                return (input || "")
                  ?.toLowerCase()
                  ?.includes(option?.key?.toLowerCase());
              },
              dropdownClassName: isDarkMode ? "darkDropDown" : "",
              customOptions: allItems
                ?.map((el) => {
                  if (
                    !selectedItems?.find((e) => e?.itemId === el?.itemId) &&
                    parseInt(el?.itemQuantity) > 0
                  ) {
                    return {
                      value: el?.itemId,
                      key: el?.itemName,
                      label: (
                        <div
                          style={{
                            width: "100%",
                            columnGap: "0.5rem",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>{el?.itemName}</span>
                          <strong
                            style={{
                              color: !parseInt(el?.itemQuantity) && "#cacaca",
                            }}
                          >{`Qty Available: ${el?.itemQuantity}`}</strong>
                        </div>
                      ),
                    };
                  }
                })
                .filter(Boolean),
              dropdownRender: (menu) => (
                <>
                  {menu}
                  <div className="openInventoryLink">
                    <Button
                      type="primary"
                      style={{ borderRadius: "5px" }}
                      onClick={() => setNewFleetInventory(true)}
                    >
                      Create new Item
                    </Button>
                  </div>
                </>
              ),
              style: { width: "100%" },
            }}
          />
          <Button
            style={{
              borderRadius: "5px",
              color: "white",
              backgroundColor: "#71CF48",
            }}
            onClick={() => {
              let newValues = form.getFieldValue("newInventoryItems");
              if (newValues?.length > 0) {
                let tmp = [];
                newValues?.forEach((id) => {
                  let i = allItems?.find((el) => el?.itemId === id);
                  if (!!i) {
                    tmp.push({ ...i, quantity: 0 });
                    form.setFieldValue(i?.itemId, 0);
                  }
                });
                form.setFieldValue("newInventoryItems", []);
                setSelectedITems(selectedItems?.concat(tmp));
              }
            }}
          >
            Add
          </Button>
        </div>
        <Table
          {...{
            columns: columns({
              changeItemQuantity,
              onRemoveItem,
              totalAmountsForDefault,
              allItems,
            }),
            dataSource: selectedItems?.concat({
              total: selectedItems?.reduce(
                (acc, val) =>
                  acc + parseFloat(val?.costPerUnit) * parseInt(val?.quantity),
                0
              ),
            }),
            pagination: false,
            bordered: true,
            scroll: {
              x: 900,
            },
          }}
        />
        <div className="confirmContainer">
          <MondayButton
            onClick={() => {
              setCancelModalVisible(true);
            }}
            className="cancelButton mondayButtonRed"
            Icon={<XIcon />}
          >
            Cancel
          </MondayButton>
          <MondayButton
            onClick={() => {
              validateForm(form, saveHandler);
            }}
            className="confirmButton"
            Icon={<Tick width={20} height={20} />}
          >
            Confirm
          </MondayButton>
        </div>
      </Form>
      {newFleetInventory && (
        <NewFleetInventory
          {...{
            visible: newFleetInventory,
            setVisible: setNewFleetInventory,
            refreshTable(obj) {
              setAllItems(allItems?.concat(obj));
              setNewItemsCreated(newItemsCreated + 1);
            },
          }}
        />
      )}
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<TickIcon />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
}

export default FleetItems;
