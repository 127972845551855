import { SimplePLI } from "./GeneralModels"

export class ShoringPLI extends SimplePLI {
  serviceId = 13

  length
  height
  width

  constructor(id, taxRate) {
    super(id, taxRate)
  }
}
