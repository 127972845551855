import { filterSchedulesOnDate } from "../utils/filterSchedulesOnDate";
import dayjs from "dayjs";

/**
 * Changes the date of incident and performs related operations.
 *
 * @param {Object} form - The form object.
 * @param {string|null} date - The date of the incident. Defaults to null.
 * @param {Array} schedules - The array of schedules.
 * @param {string} addressField - The address field.
 * @param {Object} addressSelectionInfo - The address selection information object.
 * @param {Function} setSchedulesForDate - The function to set schedules for the date.
 * @param {Function} setAddressSelectionInfo - The function to set address selection information.
 */
function changeDateOfIncident({
  form,
  date = null,
  schedules = [],
  addressField = "",
  addressSelectionInfo = {},
  setSchedulesForDate = () => {},
  setAddressSelectionInfo = () => {},
}) {
  const selectedSchedule = addressSelectionInfo?.scheduleId || null;

  const res = filterSchedulesOnDate(
    schedules,
    dayjs(date, "MM/DD/YYYY"),
    selectedSchedule
  );

  setSchedulesForDate(res?.schedulesForDay);

  if (res?.isCleared) {
    form.setFieldValue(addressField, "");
    setAddressSelectionInfo({
      projectId: "",
      scheduleId: "",
      accountId: "",
    });
  }
}

export default changeDateOfIncident;
