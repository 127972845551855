import { Modal } from "antd";
import "./GeofenceLogsModal.scss";

import Geofence from "src/components/commonComponents/Map/Geofence/Geofence";
import {
  headerTextStyle,
  singleWidgetCardStyle,
} from "src/components/pages/Settings/settingsComponents/Configurations/WidgetConfigKeys/styleHelpers/styles";
import useCalculateCenterOfCoordinates from "src/hooks/useCalculateCenterOfCoordinates";
import { InfoCircleFilled } from "@ant-design/icons";
import { infoIconStyle } from "src/components/commonComponents/ExportedStylesForIcons/exportedStylesForIcons";
import GeofenceLogsModalFooter from "./GeofenceLogsModalFooter.jsx";

const GeofenceLogsModal = ({
  params,
  setDifferenceLog,
  open = false,
  fromWichPeriod,
}) => {
  const { calculateCenter } = useCalculateCenterOfCoordinates();
  const { previus, next } = params;
  const previousData =
    fromWichPeriod === "new"
      ? previus?.cardParams?.params?.rowData
      : previus?.cardParams?.params?.rowData[0];
  const nextData = next?.cardParams?.params?.rowData;

  const dynamicLog =
    fromWichPeriod === "new"
      ? !Array.isArray(nextData)
        ? nextData
        : previousData
      : Array.isArray(nextData)
      ? {
          geoFenceInfo: nextData.map(({ lat, lng, circleRadius }) => ({
            lat,
            lng,
            circleRadius,
          })),
        }
      : previousData;

  return (
    <Modal
      width={1000}
      open={open}
      centered
      onCancel={() => setDifferenceLog(false)}
      title={"Geofence Logs Modal"}
      footer={
        <GeofenceLogsModalFooter
          {...{ setDifferenceLog, dynamicLog, previousData }}
        />
      }
    >
      <div className="geofenceLogsModalWrapper">
        <div className="geofenceLogsModalContent">
          <div className="leftSide">
            <div className="leftSideContent">
              <div style={{ display: "flex" }}>
                <span>
                  <InfoCircleFilled style={infoIconStyle} />
                </span>
                <div>Previous Information Card</div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Type of shape:
                </div>
                <div>
                  {dynamicLog.action !== "Create"
                    ? dynamicLog.type || "-||-"
                    : "-||-"}
                </div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Title of shape:
                </div>
                <div>
                  {dynamicLog.action !== "Create"
                    ? dynamicLog.title || "-||-"
                    : "-||-"}
                </div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Description of shape:
                </div>
                <div>
                  {dynamicLog.action !== "Create"
                    ? dynamicLog.description || "-||-"
                    : "-||-"}
                </div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Action Type:
                </div>
                <div>{dynamicLog.action || "-||-"}</div>
              </div>
            </div>
            <div className="card">
              <div className="cardheader">
                <span style={headerTextStyle}>Previous Card</span>
              </div>
              <div className="body">
                <Geofence
                  {...{
                    show3DotActions: false,
                    showDrawingManager: false,
                    allowEditing: false,
                    places: [
                      {
                        coordinates: calculateCenter(dynamicLog.geoFenceInfo),
                      },
                    ],
                    geoFenceInfo:
                      dynamicLog.action !== "Create" &&
                      dynamicLog.action !== "edit"
                        ? [dynamicLog]
                        : dynamicLog.action === "edit"
                        ? [previousData]
                        : [],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="rightSide">
            <div className="rightSideContent">
              <div style={{ display: "flex" }}>
                <span>
                  <InfoCircleFilled style={infoIconStyle} />
                </span>
                <div>
                  Updated Information Card{" "}
                  {dynamicLog.action === "delete" ? (
                    <span style={{ ...headerTextStyle, color: "#eb4345" }}>
                      (These data doesn't exists in project)
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Type of shape:
                </div>
                <div>{dynamicLog.type || "-||-"}</div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Title of shape:
                </div>
                <div>{dynamicLog.title || "-||-"}</div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Description of shape:
                </div>
                <div>{dynamicLog.description || "-||-"}</div>
              </div>
              <div style={singleWidgetCardStyle}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  Action Type:
                </div>
                <div>{dynamicLog.action || "-||-"}</div>
              </div>
            </div>
            <div className="card">
              <div className="cardheader">
                <span style={headerTextStyle}>Updated Card</span>
              </div>
              <div className="body">
                <Geofence
                  {...{
                    show3DotActions: false,
                    showDrawingManager: false,
                    allowEditing: false,
                    places: [
                      {
                        coordinates: calculateCenter(dynamicLog.geoFenceInfo),
                      },
                    ],
                    geoFenceInfo:
                      dynamicLog.action !== "delete" ? [dynamicLog] : [],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GeofenceLogsModal;
