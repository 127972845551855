export const generateInputFields = () => [
  {
    label: "Payment date",
    type: "datepicker-moment",
    name: "paymentDate",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Payment Date",
    format: "MM/DD/YYYY",
  },
  {
    label: "Payment Amount",
    onContextMenu: () => false,
    name: "paymentAmount",
    type: "number",
    required: true,
    placeholder: "Payment Amount",
    className: "inputForm",
    prefix: "$",
  },
  {
    label: "Reference #",
    onContextMenu: () => false,
    name: "referenceNumber",
    type: "number",
    placeholder: "Reference Number",
    className: "inputForm",
  },
  {
    label: "Payment Method",
    name: "paymentMethod",
    required: true,
    hidden: true,
  },
];

export const generateCheckInputFields = ({ newReceivedBy }) => [
  {
    label: "Check Date",
    type: "datepicker-moment",
    name: "checkDate",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Check Date",
    format: "MM/DD/YYYY",
  },
  {
    label: "Received Date",
    type: "datepicker-moment",
    name: "receivedCheckDate",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Received Date",
    format: "MM/DD/YYYY",
  },
  {
    label: "Recorded Date",
    type: "datepicker-moment",
    name: "recordedCheckDate",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Recorded Date",
    format: "MM/DD/YYYY",
  },
  {
    label: "Received By",
    type: "select",
    name: "receivedByCheckDate",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Received By",
    customOptions: newReceivedBy,
  },
];

export const generateWireInputFields = () => [
  {
    label: "Posted Date",
    type: "datepicker-moment",
    name: "wirePostedDate",
    popupClassName: "darkDateDropDown",
    required: true,
    placeholder: "Posted Date",
    format: "MM/DD/YYYY",
  },
  {
    label: "Wire Number",
    onContextMenu: () => false,
    name: "wireNumber",
    type: "number",
    required: true,
    placeholder: "Wire Number",
    className: "inputForm",
  },
];
