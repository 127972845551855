import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const applications = (
  programFields,
  updatedDynamicStates,
  applicationsData,
  IdHandler
) => {
  const data = updatedDynamicStates[IdHandler];

  //we have a start period and a end period find the which months are more in between

  const applicationStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    applicationStatus: () => ({
      title: "Requisition - Requisition Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Applications",
        field: "applicationStatus",
        labels: applicationStatus?.statusName,
        datasets: [
          {
            label: "Requisitions",
            data: applicationStatus?.statusName?.map(
              (status) =>
                applicationsData?.filter((a) => a?.applicationStatus === status)
                  ?.length
            ),
            backgroundColor: applicationStatus?.statusColor,
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //applicationStatus
    //totalAmount
  };
};
