import { List, Tooltip } from "antd";
import { debounce, uniqBy } from "lodash";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import IndividualLoader from "../../../../../IndividualLoader";
import {
  REQUEST_API_KEYS,
  assignedToObject,
  projectManagerSelect,
  teamSelect,
} from "..";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import sortFields from "../sortFields";

/**
 * Generates an array of fields for a service inspection request form.
 *
 * @param {Array} teams - The list of teams.
 * @param {Array} projects - The list of projects.
 * @param {Array} accounts - The list of accounts.
 * @param {Array} inputFields - The list of input fields.
 * @param {boolean} isDarkMode - Indicates if the form is in dark mode.
 * @param {Array} inspectionTypes - The list of inspection types.
 * @param {Array} projectManagers - The list of project managers.
 * @param {string|null} currentPageKey - The current page key.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Array} inspectionOptions - The list of inspection options.
 * @param {boolean} globalDisabled - Indicates if all fields are disabled.
 * @param {boolean} recordsLoading - Indicates if records are loading.
 * @param {Array} projectExecutives - The list of project executives.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} setRecordsLoading - The function to set records loading state.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The array of generated fields.
 */
const serviceInspectionRequestFields = ({
  teams = [],
  projects = [],
  accounts = [],
  inputFields = [],
  isDarkMode = false,
  inspectionTypes = [],
  projectManagers = [],
  currentPageKey = null,
  userConfiguration = {},
  inspectionOptions = [],
  globalDisabled = false,
  recordsLoading = false,
  projectExecutives = [],
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  setRecordsLoading = () => {},
  onMainFieldSelect = () => {},
  dispatchRequestData = () => {},
}) => {
  const newFields = [];

  const projectLabel = (el) => {
    const objectsFromArrays = Object.values(el?.services || {})?.flat();

    let uniqueServices = Array.from(
      new Set(objectsFromArrays?.map((el) => el?.label))
    );

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          {`${el?.projectName}` + ` ― ` + ` Client : ${el?.accountName}`}
        </div>

        <Tooltip
          placement="right"
          zIndex={99999}
          title={
            <List
              dataSource={uniqueServices}
              style={{
                overflow: "auto",
                maxHeight: "245px",
              }}
              renderItem={(item) => (
                <List.Item style={{ color: "#fff" }}>{item}</List.Item>
              )}
            />
          }
        >
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              color: "#fff",
              borderRadius: "5px",
              backgroundColor: "#1264a3",
              marginLeft: "10px",
              height: "fit-content",
            }}
          >
            Services: {uniqueServices.length}
          </div>
        </Tooltip>
      </div>
    );
  };

  const onSelect = (val, opt) => {
    const selectedProject = projects?.find((el) => el?.projectId === val);

    onMainFieldSelect({
      val,
      opt: { category: "projects", recordName: opt?.name },
    });

    const objectsFromArrays = Object.values(
      selectedProject?.services || {}
    ).flat();

    if (!objectsFromArrays?.length) {
      return dispatchRequestData({
        type: "INSPECTION_OPTIONS",
        payload: ["Pre-Installation Inspection"],
      });
    }
    const uniqueServices = [
      ...new Set(objectsFromArrays?.map((el) => el?.label)),
    ];

    const inspectionFieldOptions = (uniqueServices || [])?.includes("Hoist")
      ? inspectionTypes?.map((el) => el?.typeName)
      : inspectionTypes
          ?.map((el) => el?.typeName)
          ?.filter((el) => el !== "Hoist");

    return dispatchRequestData({
      type: "INSPECTION_OPTIONS",
      payload: inspectionFieldOptions,
    });
  };

  const onClear = () => {
    onMainFieldSelect({ clear: true, opt: { category: "projects" } });

    dispatchRequestData({
      type: "INSPECTION_OPTIONS",
      payload: ["Pre-Installation Inspection"],
    });
  };

  const onSearch = debounce(
    async (searchValue) =>
      await searchFetchInput({
        table: "projects",
        idKey: "projectId",
        arrayKey: "projects",
        searchKey: "projectName",
        searchValue,
        userConfiguration,
        setLoading: setRecordsLoading,
        searchValue,
        keysToInclude: REQUEST_API_KEYS["Service Inspection"].projects,
        setRecords: (res) =>
          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: {
              projects: userAccessRecords(
                userConfiguration,
                uniqBy([...(res || []), ...(projects || [])])
              ),
            },
          }),
        customSetRecords: true,
        setCurrentPageKey: (val) => {
          currentPageKey.current = val;
        },
      }),
    500
  );

  const onPopupScroll = async (e) =>
    await handlePopupScroll({
      e,
      table: "projects",
      idKey: "projectId",
      currentPageKey: currentPageKey.current.projects,
      keysToInclude: REQUEST_API_KEYS["Service Inspection"].projects,
      setLoading: setRecordsLoading,
      setRecords: (res) =>
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: {
            projects: userAccessRecords(
              userConfiguration,
              uniqBy([...(res || []), ...(projects || [])])
            ),
          },
        }),
      userConfiguration,
      setCurrentPageKey: (val) => {
        currentPageKey.current = { projects: val };
      },
      customSetRecords: true,
    });

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "projectName") {
      delete field?.options;

      Object.assign(field, {
        style: { maxWidth: "fit-content", width: "100%" },
        openFilterProp: "title",
        filterOption: (input, option) =>
          option?.name?.toLowerCase()?.includes(input?.toLowerCase()),

        customOptions: (projects || [])?.map((el) => {
          return {
            name: el?.projectName,
            value: el?.projectId,
            label: projectLabel(el),
          };
        }),

        onSelect,

        onClear,

        onSearch,

        onPopupScroll,

        dropdownRender,
      });
    }

    if (formItemName === "inspectionType") {
      delete field?.options;

      Object.assign(field, {
        options: inspectionOptions,

        style: { minWidth: 300 },
        showSearch: true,
        allowClear: true,
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Inspections",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "team") {
      Object.assign(field, {
        ...teamSelect({ onTeamSelect, teams, isDarkMode }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "inspectionDate") {
      Object.assign(field, {
        disabledDate: (current) => current < dayjsNY().startOf("D").valueOf(),
      });
    }

    if (formItemName === "projectExecutive") {
      Object.assign(field, {
        options: projectExecutives?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
      });
    }

    if (formItemName === "client") {
      delete field?.options;

      Object.assign(field, {
        disabled: true,
        customOptions: (accounts || [])?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default serviceInspectionRequestFields;
