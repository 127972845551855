import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const todos = ({ tables, programFields }) => {
  const todoStatus = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.["To Do"];
  return {
    todoStatus: () => ({
      labels: todoStatus?.map?.((el) => el?.statusName),
      data: todoStatus?.map?.(
        (status) =>
          tables?.["To Do"]?.filter?.(
            (a) => a.todoStatus === status?.statusName
          )?.length
      ),
      backgroundColor: todoStatus?.map((el) => el?.statusColor),
    }),
    todoCategory: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.["To Do"], "todoCategory")
      ),
      data: Object.values(
        repeatedElementSum(tables?.["To Do"], "todoCategory")
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.["To Do"], "todoCategory")
      ).map((a) => getRandomColor()),
    }),
    todoUser: () => ({
      labels: Object.keys(repeatedElementSum(tables?.["To Do"], "todoUser")),
      data: Object.values(repeatedElementSum(tables?.["To Do"], "todoUser")),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.["To Do"], "todoUser")
      ).map((a) => getRandomColor()),
    }),
    relatedTo: () => ({
      labels: Object.keys(repeatedElementSum(tables?.["To Do"], "relatedTo")),
      data: Object.values(repeatedElementSum(tables?.["To Do"], "relatedTo")),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.["To Do"], "relatedTo")
      ).map((a) => getRandomColor()),
    }),
  };
};
