import { updateOtherUser } from "../../../../../../../../actions";
import {
  apiPut,
  showLoadingMsg,
  showSuccessMsg,
  updateArrayElement,
} from "../../../../../../../../utils";
import dayjs from "dayjs";
import { apiRoutes } from "../../../../../../../SidebarPages/Fleet/utils";
const TIME_FORMAT = "hh:mm a";
const DATE_FORMAT = "MM/DD/YYYY";
const logoutSingleUserSession = async ({
  session,
  user,
  wsSessions,
  saveAddedLogs,
  dispatch,
  setLogoutOptionsModal,
}) => {
  try {
    const { identityId, activeSessions, nameOfUser } = user;
    const removeWsStatus =
      activeSessions
        ?.map((el) => {
          const { wsStatus, isIdle, ...rest } = el;
          return rest;
        })
        ?.filter((el) => !!el?.sessionId) || [];
    const updatedActiveSessions = updateArrayElement(
      removeWsStatus,
      { sessionId: session.sessionId, logout: true },
      "sessionId"
    );

    showLoadingMsg();
    await apiPut(apiRoutes.userConfiguration, identityId, {
      activeSessions: updatedActiveSessions,
    }).then(async () => {
      try {
        wsSessions.send(
          JSON.stringify({
            request: "force-logout",
            body: {
              userId: identityId,
              sessionId: session.sessionId,
              forceLogout: true,
            },
          })
        );
      } catch (e) {
        console.error("Error sending force logout request", e);
      }
      saveAddedLogs({
        recordId: identityId,
        recordName: nameOfUser,
        actionType: "Force Logout",
        category: "Users Actions",
        currentData: { [nameOfUser]: "Session End" },
        previousData: {
          [nameOfUser]: `${nameOfUser} Session Logout`,
          previousValue: `Session Start: ${dayjs(session?.loggedIn).format(
            `${DATE_FORMAT} ${TIME_FORMAT}`
          )}`,
        },
        updatedKeys: [
          {
            label: `${nameOfUser} Session Logout`,
            previousValue: `Session Start: ${dayjs(session?.loggedIn).format(
              `${DATE_FORMAT} ${TIME_FORMAT}`
            )}`,
            updatedValue: "Session End",
          },
        ],
      });
    });

    dispatch(
      updateOtherUser({ identityId, activeSessions: updatedActiveSessions })
    );
    showSuccessMsg();
    setLogoutOptionsModal(false);
  } catch (e) {
    console.log("Error logging out user session", { e });
  }
};

export default logoutSingleUserSession;
