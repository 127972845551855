import { Button } from "antd";
import "./ContactsDropDown.scss";

const ContactsDropDown = ({ menu, setContactModalVisible }) => {
  return (
    <div className="customContactsDropDown">
      <div className="dropdownMenu">{menu}</div>
      <Button
        type="primary"
        shape="round"
        onClick={() => setContactModalVisible(true)}
      >
        New Contact
      </Button>
    </div>
  );
};

export default ContactsDropDown;
