export const dispatching = (updatedDynamicStates, IdHandler) => {
  return {
    driverName: () => ({
      title: "Dispatch DriverName",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Dispatching",
        field: "driverName",
        secondaryField: "fleetId",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetId,
            value: a?.driverName,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //driverName
  };
};
