import { Badge, Input } from "antd";
import React, { useState } from "react";
import "./DynamicTable.scss";
export const DynamicTable = ({
  tableStyle = {},
  tableHeaderStyle = {},
  columnDefs = [],
  rowData = [],
  tableHeaderTr = {},
}) => {
  // const [searchQuery, setSearchQuery] = useState("");

  // const filteredData = rowData?.filter?.((row) => {
  //   const rowValues = columnDefs
  //     ?.map(({ field = "" }) => row?.[field]?.value)
  //     ?.join(" ")
  //     ?.toLowerCase();
  //   return rowValues?.includes(searchQuery.toLowerCase());
  // });

  return (
    <div className="htmlTableContent">
      {/* <div className="htmlTableContentSearchContent">
        <Input
          placeholder="Search anything..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="htmlTableContentSearchInput"
        />
      </div> */}
      <div>
        <table style={tableStyle}>
          <thead>
            <tr style={tableHeaderTr}>
              {columnDefs.map(({ headerName }, index) => (
                <th key={index} style={tableHeaderStyle}>
                  {headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowData?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columnDefs?.map(({ field = "" }, colIndex) => (
                  <td
                    key={colIndex}
                    id={row?.[field]?.id}
                    onClick={row?.[field]?.onClick}
                    style={row?.[field]?.style}
                  >
                    {row?.[field]?.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
