import { createContext, useReducer, useContext } from "react";

import reducer from "./reducer";

export const StateContext = createContext();
const DispatchContext = createContext();

const initialState = {
  isWritable: false,
  isAutoSaving: false, // Update Application if autosave is on which will make many API requests
  fakeApplication: null, // Clone Application from store and make changes to this, only update Application on save button pressed in order to not make many API request
  unchangingFakeApplication: null, //have the same value as fakeApplication
  darkMode: !!localStorage.getItem("themePreference")
    ? localStorage.getItem("themePreference") === "dark"
      ? true
      : false
    : true,
  selectedRentals: [],
  isNextStep: false, //THIS IS USED WHEN CREATE APPLICATION IN NEXT STEP MODAL
  appId: false, //THIS IS USED WHEN CREATE APPLICATION IN NEXT STEP MODAL
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
export const useDispatchContext = () => useContext(DispatchContext);

export default ContextProvider;
