import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { formatDuration } from "src/components/SidebarPages/Fleet/fleetsLive/utils";

function StopInfo({ dataVisible, stopData }) {
  return (
    <div
      className={`default-data-container${
        dataVisible ? " data-expanded" : " data-collapsed"
      }`}
    >
      <b className="default-data-title">
        <span>Stop info</span>
      </b>
      <div className="default-data-list">
        <div className="default-data">
          <b className="data-title">Address</b>
          <span className="data-value">{stopData?.address}</span>
        </div>
        <div className="default-data">
          <b className="data-title">Vehicle #</b>
          <span className="data-value">{stopData?.fleetName}</span>
        </div>
        <div className="default-data">
          <b className="data-title">Stop Type</b>
          <span className="data-value">{stopData?.stopType}</span>
        </div>
        <div className="default-data">
          <b className="data-title">Stop Date</b>
          <span className="data-value">
            {`From ${dayjsNY(stopData?.beginDate).format(
              "MMM DD, YY - hh:mm a"
            )}`}
          </span>
          <span className="data-value">
            {`To ${dayjsNY(stopData?.endDate).format("MMM DD, YY - hh:mm a")}`}
          </span>
        </div>
        <div className="default-data">
          <b className="data-title">Duration</b>
          <span className="data-value">
            {formatDuration(stopData?.duration, "minutes")?.text}
          </span>
        </div>
      </div>
    </div>
  );
}

export default StopInfo;
