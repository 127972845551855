import { Tooltip } from "antd";
import { useRef } from "react";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import dayjs from "dayjs";
import "./SingleFile.scss";

function SingleFile({
  driveRequest,
  index,
  isDarkMode,
  setCurrentFile,
  setShowModal,
  handleDelete,
  fileType,
  file,
  editCard = false,
}) {
  const {
    name = "",
    iconUrl,
    id = "",
    mimeType = "",
    url = "",
    downloadUrl = "",
    uploadedBy = "",
    lastEditedUtc = "",
  } = file;
  const divRef = useRef(null);
  return (
    <div
      key={id}
      style={{
        borderRadius: 5,
        padding: 10,
      }}
      className={`documentContainer ${
        isDarkMode ? "documentContainerDark" : ""
      }`}
      ref={divRef}
    >
      <div
        className="documentTitle"
        onClick={() => {
          setShowModal(true);
          driveRequest
            .getImageSrc(id)
            .then(({ src }) => setCurrentFile({ src, mimeType, url }));
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            width: "100%",
          }}
        >
          {/* <img className="documentIcon" src={iconUrl} /> */}
          <span className="documentIcon">{fileIcons[fileType]}</span>
          <Tooltip title={name}>
            <span
              className="documentName"
              style={{
                color: isDarkMode ? "white" : "black",
              }}
            >
              {name}
            </span>
          </Tooltip>
        </div>
      </div>
      {!editCard && (
        <span
          style={{
            fontSize: 12,
            color: "#acacac",
            display: "flex",
            alignItems: "center",
            height: 30,
          }}
        >
          {uploadedBy && <span>Uploader: {uploadedBy}</span>}{" "}
          {uploadedBy && lastEditedUtc && <span> | </span>}
          {lastEditedUtc && (
            <span>
              Uploaded on: {dayjs(lastEditedUtc).format("DD MMM YYYY")}
            </span>
          )}
        </span>
      )}
      <div
        style={{
          display: "flex",
          gap: "20px",
          textAlign: "right",
          height: 18,
          alignSelf: "center",
        }}
      >
        <a
          onMouseOver={() => {
            divRef.current.classList.remove("documentContainerRed");
            divRef.current.classList.add("documentContainerBlue");
          }}
          onMouseLeave={() => {
            divRef.current.classList.remove("documentContainerBlue");
          }}
          href={downloadUrl}
        >
          <DownloadIcon
            fill="#1264A3"
            style={{
              cursor: "pointer",
            }}
          />
        </a>
        <span
          onClick={() => {
            handleDelete(id);
          }}
          onMouseOver={() => {
            divRef.current.classList.remove("documentContainerBlue");
            divRef.current.classList.add("documentContainerRed");
          }}
          onMouseLeave={() => {
            divRef.current.classList.remove("documentContainerRed");
          }}
        >
          <TrashIcon
            fill="#FE4C4A"
            style={{
              cursor: "pointer",
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default SingleFile;
