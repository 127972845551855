import "./Header.scss";
import { Col, Layout, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import RightActionsMobile from "../MobileComponents/RightActionsMobile";
import SidebarMobile from "../MobileComponents/SidebarMobile";
import { GlobalSearch } from "./components";
import { VideoTutorialProvider } from "./components/ModalVideoTutorials/contexts/VideoTutorialContext";
import HeaderActions from "./components/header-right-actions/HeaderActions";
import RightActionsMobileDrawerProvider from "../MobileComponents/RightActionsMobileDrawerProvider";

const Header = ({ props }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <VideoTutorialProvider>
      <Layout.Header
        className={`title-header-comp ${
          isDarkMode && "title-header-comp-dark"
        }`}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {isMobile && (
            <Col justify="center" xs={{ span: 3, offset: 1 }}>
              <SidebarMobile props={props} />
            </Col>
          )}

          <Col
            xs={{ span: 15, offset: 1 }}
            md={{ span: 8 }}
            lg={{ span: 10 }}
            xl={{ span: 14 }}
            xxl={{ span: 17 }}
          >
            <GlobalSearch />
          </Col>

          {isMobile && (
            <Col justify="center" xs={{ span: 3, offset: 1 }}>
              <RightActionsMobileDrawerProvider>
                <RightActionsMobile />
              </RightActionsMobileDrawerProvider>
            </Col>
          )}

          {!isMobile && (
            <Col md={{ span: 15 }} lg={{ span: 13 }} xl={{ span: 5 }}>
              <HeaderActions />
            </Col>
          )}
        </Row>
      </Layout.Header>
    </VideoTutorialProvider>
  );
};
export default React.memo(Header);
