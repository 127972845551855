import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? " https://leadmanager-express-backend.ue.r.appspot.com/api/notifications"
    : "http://localhost:8080/api/notifications";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const isProd = process.env.NODE_ENV === "production" ? true : false;

const apiService = {
  async modifySelection(payload) {
    try {
      const response = await axiosInstance.post("/modify-selection", {
        ...payload,
        isProd,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "An error occurred");
    }
  },

  async modifyAll(payload) {
    try {
      const response = await axiosInstance.post("/modify-all", {
        ...payload,
        isProd,
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw new Error(error.response.message || "An error occurred");
    }
  },

  async updateNotificationPinnedStatus(payload) {
    try {
      const response = await axiosInstance.post(
        "/update-notification-pinned-status",
        {
          ...payload,
          isProd,
        }
      );

      return response;
    } catch (error) {
      console.error(
        "error while updating notification isPinned status: ",
        error.response.data.error
      );
      throw new Error(error.response.data.error);
    }
  },
};

export default apiService;
