import { useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { API } from "aws-amplify";

import ItemInfoModal from "./ItemInfoModal";

import "../Information/Information.scss";

function WorkOrderItems(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [defectInfoToShow, setDefectInfoToShow] = useState(null);

  async function getDefectInfo(defectId) {
    message.loading({
      content: "Opening Defect Info...",
      key: "defectInfoMessage",
    });
    API.get("fleetDefects", `/fleetDefects/${defectId}`)
      .then((res) => {
        message.destroy("defectInfoMessage");
        setDefectInfoToShow(res);
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong!",
          key: "defectInfoMessage",
        });
        console.log("Error getting defect: ", err);
      });
  }

  return (
    <div className="InformationBody">
      {!!props?.params?.rowData?.length ? (
        props?.params?.rowData?.map((el, i) => (
          <div className="InforamtionBodyElementContainer" key={`item-${i}`}>
            <span className="InformationBodyElementContainerTitle">{`Item ${
              i + 1
            }:`}</span>
            <span
              style={{
                cursor: "pointer",
                color: "#1065A4",
                fontWeight: "500",
              }}
              onClick={() => {
                getDefectInfo(el?.defectId);
              }}
            >
              {el?.defectName}
            </span>
          </div>
        ))
      ) : (
        <span
          className="workOrderNoInfo"
          style={{ color: isDarkMode ? "#fff" : "#000" }}
        >
          There are no selected defects for this work order
        </span>
      )}
      {!!defectInfoToShow && (
        <ItemInfoModal
          {...{
            open: !!defectInfoToShow,
            defect: defectInfoToShow,
            onCancel() {
              setDefectInfoToShow(null);
            },
          }}
        />
      )}
    </div>
  );
}

export default WorkOrderItems;
