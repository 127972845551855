import generateDescription from "../../../datas/generateDescription";

const removeRentalDetailsFromRequisition = (serviceOptions) => {
  console.log({ serviceOptions });
  return serviceOptions.map((option) => ({
    ...option,
    items: option.items?.map((item) => {
      const { rentalDetails, ...rest } = item;
      return {
        ...rest,
      };
    }),
  }));
};

export const generateChargeItems = (chargeToSave, categoryFrom) => {
  const items = chargeToSave?.chargeItems
    ?.map((item) => {
      const { totalPaymentDue = 0, taxAmount = 0, serviceOptions = [] } = item;
      return {
        ...item,
        taxAmount,
        price:
          categoryFrom === "Requisition"
            ? item.rentalId
              ? item.total || 0
              : totalPaymentDue
            : item.price || 0,
        serviceOptions:
          categoryFrom === "Requisition"
            ? removeRentalDetailsFromRequisition(serviceOptions)
            : item.serviceOptions,
        serviceDescription: generateDescription(serviceOptions, categoryFrom),
      };
    })
    ?.filter((item) => item.price !== 0);
  return items;
};
