import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import { formatCurrency } from "../../../../utils";

export const projects = ({ tables, programFields }) => {
  // console.log("tables", tables?.Projects);
  return {
    projectStatus: () => ({
      statusData: tables?.Projects?.map((a) => {
        return {
          label: a?.projectName,
          value: a?.projectStatus,
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Projects?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.projectStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
          id: a?.projectId,
        };
      }),
    }), //projectStatus
    attorney: () => ({
      statusData: tables?.Projects?.map((a) => {
        return {
          label: a?.projectName,
          value: a?.attorney,
          color: "#ECDE7D",
          id: a?.projectId,
        };
      }),
    }), //attorney
    expeditor: () => ({
      statusData: tables?.Projects?.map((a) => {
        return {
          label: a?.projectName,
          value: a?.expeditor,
          color: "#ECDE7D",
          id: a?.projectId,
        };
      }),
    }), //expeditor

    projectExecutive: () => ({
      statusData: tables?.Projects?.map((a) => {
        return {
          label: a?.projectName,
          value: a?.projectExecutive,
          color: "#ECDE7D",
          date: a?.createdAt,
          id: a?.projectId,
        };
      }),
    }),

    totalEstimationsAmount: () => ({
      statusData: tables?.Projects?.map(
        ({ services = [], projectName = "", projectId = "" }) => {
          return {
            label: projectName,
            value: formatCurrency(
              parseFloat(
                getTotalServicesPrice(Object?.values(services)?.flat())
              )
            ),
            color: "#ECDE7D",
            id: projectId,
          };
        }
      ),
    }),
  }; //Projects
};
