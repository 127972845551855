import { Collapse, Divider, message, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CollapseArrow } from "../../../../../../assets";
import { API } from "aws-amplify";
import { get_ProgressDimensionsByServiceId } from "../../../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import {
  breakInspProgress,
  onlyAppliedBreakdowns,
  totalProgressPli,
} from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import TableView from "../TableView/TableView";
import { MondayButton, ProgressBar } from "../../../../../commonComponents";
import "./DayForemanView.scss";
import useTranslate from "../../../Language/useTranslate";
import { useSelector } from "react-redux";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { getCognitosForNotification } from "../../../../../../utils";
import { DocUploader } from "../../../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { keys } from "../../../../Settings/settingsComponents/Roles/RolesData";
import ServiceFileViewerModal from "./ServiceFileViewerModal";
import { useEditLogs } from "../../../../../../hooks";

const DayForemanView = (props) => {
  const { selectedDay = {}, foremanDocuments, setLogsData } = props;
  const { t, translateSentence } = useTranslate();
  const {
    dayId = "",
    details = [],
    toBeUpdate = [],
    typeOfWork = "",
    googleDriveFolderIds,
    scheduleDays,
    endDate,
    scheduleId,
    scheduleName,
    scheduleAddress,
    scheduleTeam,
    projectId,
  } = selectedDay || {};

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [data, setData] = useState([]);
  const [serviceDocModal, setServiceDocModal] = useState("");
  const { saveAddedLogs } = useEditLogs();

  const onChangeProgress = (
    value,
    detailIndex,
    serviceIndex,
    elevationIndex,
    pliIndex,
    serviceId,
    dimension,
    editingBreakdown = false,
    hoist = false
  ) => {
    const isHoist = Array.isArray(hoist) && hoist?.length > 0 && !!hoist[0];
    if (isNaN(value) && !dimension?.includes("notes")) return;

    const newData = [...data] || [];
    const dataEditing =
      newData?.[detailIndex]?.services?.[serviceIndex]?.serviceOptions?.[
        elevationIndex
      ];
    const update = isHoist
      ? dataEditing?.jumps?.find(
          (jump) => jump?.name === dataEditing?.items?.[pliIndex]?.jump
        )
      : dataEditing?.items?.[pliIndex];

    if (!update) return;

    const updateFloor = (floors) => {
      return floors?.map((floor) => {
        if (floor?.floor === hoist[1]) {
          return {
            ...floor,
            foreman: {
              ...floor?.foreman,
              [dimension]: value,
              nameOfForeman: userConfiguration?.nameOfUser,
            },
          };
        }
        return floor;
      });
    };

    const updateProgressDay = (progressDay) => ({
      ...progressDay,
      [isHoist ? "progress" : "foreman"]: isHoist
        ? updateFloor(progressDay?.progress)
        : {
            ...progressDay?.foreman,
            [!editingBreakdown ? dimension : dimension[0]]: value,
            nameOfForeman: userConfiguration?.nameOfUser,
          },
    });

    const updateBreakdownValue = (breakdown) => {
      if (breakdown?.id === editingBreakdown?.id) {
        breakdown.progressByDay = breakdown?.progressByDay?.map((progressDay) =>
          progressDay?.day === dayId
            ? updateProgressDay(progressDay)
            : progressDay
        );
      }
      return breakdown;
    };

    if (editingBreakdown) {
      update.breakdownValue = update?.breakdownValue?.map(updateBreakdownValue);
    } else {
      update.progressByDay = update?.progressByDay?.map((progressDay) =>
        progressDay?.day === dayId
          ? updateProgressDay(progressDay)
          : progressDay
      );
    }

    setData(newData);
  };

  const onSave = async (
    detail = {},
    service = {},
    elevationId,
    rowId,
    typeOfProgress,
    files = {},
    fullElevationName = "",
    isHoist = false,
    fileLogs = {}
  ) => {
    const findObjToUpdate = toBeUpdate?.find(
      (est) =>
        est?.estimationId === detail?.estimationId &&
        est?.serviceId === service?.serviceId
    );
    let notificationData = {
      serviceName: service?.serviceName,
      foremanName: userConfiguration?.nameOfUser,
      startDate: dayjsNY(selectedDay?.startDate).format("MM/DD/YYYY"),
      scheduleAddress,
      estNumber: findObjToUpdate?.estimationNumber || 0,
      elevationName: fullElevationName,
      pliId: rowId,
    };

    let logBody = {
      d1: "d1",
      d2: "d2",
      dayId: selectedDay?.dayId,
      // projectId,
      // scheduleAddress,
      startDate: dayjsNY(selectedDay?.startDate).valueOf(),
      endDate: dayjsNY(selectedDay?.endDate).valueOf(),
      typeOfProgress: "dimensions",
      dayProgress: "",
      estimationId: detail?.estimationId,
      estimation: findObjToUpdate?.estimationNumber || 0,
      serviceId: service?.serviceId,
      totalPli: "",
      length: "",
      width: "",
      height: "",
      surface: "",
      pliProgress: "",
      pliRemainingProgress: "",
      serviceName: service?.serviceName,
      elevation: fullElevationName,
      pli: rowId,
      user: userConfiguration?.nameOfUser,
      progressTimeGiven: Date.now(),
      type: "Foreman",
      files: fileLogs?.changes,
    };

    let temp = service?.options || [];
    temp = [
      service?.options?.[0]?.map((elevation, index) => {
        if (elevation?.elevationId === elevationId) {
          if (!isHoist[0]) {
            elevation.items = elevation?.items?.map((item, itemIndex) => {
              if (item?.id === rowId) {
                if (!typeOfProgress) {
                  item.progressByDay = item?.progressByDay?.map((day) => {
                    if (day?.day === dayId) {
                      return {
                        ...day,
                        files: { ...(day?.files || {}), ...files },
                      };
                    }
                  });
                } else {
                  item.breakdownValue = item?.breakdownValue?.map(
                    (breakdown) => {
                      const findBreakdown = typeOfProgress?.find(
                        (file) => file?.id === breakdown?.id
                      );
                      if (!!findBreakdown) {
                        const toReturn = {
                          ...breakdown,
                          progressByDay: breakdown?.progressByDay?.map(
                            (day) => {
                              if (day?.day === dayId) {
                                return {
                                  ...day,
                                  files: {
                                    ...(day?.files || {}),
                                    [files.userId]:
                                      files?.files?.[breakdown?.id] || [],
                                  },
                                };
                              }
                              return day;
                            }
                          ),
                        };
                        return toReturn;
                      }
                      return breakdown;
                    }
                  );
                }
              }
              return item;
            });
          } else {
            elevation.jumps = elevation?.jumps?.map((jump) => {
              if (jump.name === isHoist[1]) {
                jump.progressByDay = jump?.progressByDay?.map((day) => {
                  if (day?.day === dayId) {
                    return {
                      ...day,
                      progress: day?.progress?.map((floor) => {
                        if (floor?.floor === isHoist[2]) {
                          return {
                            ...floor,
                            files: { ...(floor?.files || {}), ...files },
                          };
                        }
                        return floor;
                      }),
                    };
                  }
                  return day;
                });
              }
              return jump;
            });
          }
        }
        return elevation;
      }),
    ];

    await API.put(
      "toBeScheduled",
      `/toBeScheduled/${findObjToUpdate?.toBeScheduledId}`,
      {
        body: {
          serviceOptions: temp || [],
        },
      }
    )
      .then((res) => {
        // if (!typeOfProgress) {
        //   setLogsData((prev) => [...prev, logBody]);
        // }
        broadcastNotification(
          "14",
          "onForemanProgressChange",
          [
            {
              ...notificationData,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
              cognitos: getCognitosForNotification(
                userConfiguration,
                scheduleTeam
              ),
            },
          ],
          scheduleId
        )
          .then((res) => console.log({ res }))
          .catch((err) => console.log({ err }));
        message.success(t("Saved successfully"));
      })
      .catch((err) => {
        message.error(t("Failed to save"));
        console.log({ err });
      });
  };

  useEffect(() => {
    setData(details);
  }, [details]);

  return (
    <div className="day-foreman-view">
      {data && data.length > 0 ? (
        data.flatMap((detail, detailIndex) => {
          return detail?.services?.map((service, serviceIndex) => {
            const {
              serviceName = "",
              serviceOptions = [],
              serviceProgress = 0,
              estimationNumber = "",
              serviceId = "",
              options = [],
              serviceToUpdate: { estimationNumber: estNr = "" },
            } = service || {};
            const docLength =
              Object.values(foremanDocuments?.[serviceName] || {})?.flat()
                ?.length || 0;
            const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
            return (
              <div key={serviceIndex + serviceId} className="service-section">
                <div className="service-section-header">
                  <div className="service-section-header-title">
                    <span
                      style={{
                        display: "flex",
                        gap: 10,
                        alignItems: "baseline",
                      }}
                      className="title"
                    >
                      <span>{serviceName}</span>
                      {docLength > 0 && (
                        <label
                          {...{
                            style: {
                              cursor: "pointer",
                              color: "#1264a3",
                              fontWeight: 600,
                              fontSize: 16,
                            },
                            onClick: () => {
                              setServiceDocModal(serviceName);
                            },
                          }}
                        >
                          {t("Documents")} ({docLength})
                        </label>
                      )}
                    </span>
                    <span className="type-of-work">{t(typeOfWork)} </span>
                  </div>
                  <ProgressBar percent={serviceProgress} />
                </div>
                {serviceOptions?.map((elevation, elevationIndex) => {
                  const {
                    elevationLabel = "",
                    items = [],
                    elevationProgress = 0,
                  } = elevation || {};
                  return (
                    <Collapse
                      key={elevationIndex}
                      className="elevation-collapse"
                      bordered={false}
                      expandIconPosition={"end"}
                      items={[
                        {
                          key: elevationIndex,
                          label: (
                            <span className="elevation-label">
                              {translateSentence(elevationLabel)}
                            </span>
                          ),
                          children: (
                            <>
                              <div className="elevation-collapse-progress">
                                <ProgressBar
                                  style={{ maxWidth: 150 }}
                                  percent={elevationProgress}
                                />
                              </div>
                              <TableView
                                {...{
                                  d1,
                                  d2,
                                  items,
                                  dayId,
                                  detailIndex,
                                  serviceIndex,
                                  elevationIndex,
                                  serviceId: service?.serviceId,
                                  serviceName,
                                  googleDriveFolderIds,
                                  endDate,
                                  estimationNumber,
                                  estNr,
                                  onChangeProgress,
                                  detail,
                                  service,
                                  onSave,
                                  scheduleId,
                                  elevationId: elevation?.elevationId,
                                  typeOfWork,
                                  fullElevationName:
                                    elevation?.elevationLabel +
                                    " " +
                                    elevation?.elevationId,
                                }}
                              />
                            </>
                          ),
                        },
                      ]}
                      expandIcon={({ isActive }) => (
                        <CollapseArrow
                          style={{
                            transform: !isActive ? "rotate(180deg)" : "",
                          }}
                          height={12}
                          width={12}
                          fill="black"
                        />
                      )}
                    />
                  );
                })}
              </div>
            );
          });
        })
      ) : (
        <div className="no-data">{t("Not Scheduled")}</div>
      )}
      {serviceDocModal && (
        <ServiceFileViewerModal
          {...{
            serviceDocModal,
            setServiceDocModal,
            foremanDocuments,
          }}
        />
      )}
    </div>
  );
};

export default DayForemanView;
