import { Typography } from "antd";
import { useSelector } from "react-redux";

export default function Text({
  type,
  disabled,
  upperCase,
  span,
  strong,
  whiteText,
  style = {},
  dangerouslySetInnerHTML,
  children,
  ...typographyProps
}) {
  const { Text: AntText } = Typography;

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const styles = {
    width: "100%",
    margin: "auto",
    color: whiteText ? "whitesmoke" : !!isDarkMode ? "#f2f2f2" : "#333238",
    textTransform: upperCase ? "uppercase" : undefined,
    ...style,
  };

  if (span) {
    return (
      <div style={{ width: "fit" }}>
        <AntText
          type={type}
          strong={strong}
          disabled={disabled}
          style={{ ...styles }}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          {...typographyProps}
        >
          {dangerouslySetInnerHTML ? (
            <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
          ) : (
            children
          )}
        </AntText>
      </div>
    );
  }

  return (
    <AntText
      type={type}
      strong={strong}
      disabled={disabled}
      style={styles}
      {...typographyProps}
    >
      {dangerouslySetInnerHTML ? (
        <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
      ) : (
        children
      )}
    </AntText>
  );
}
