import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { uniqBy } from "lodash";
import { v4 } from "uuid";
import dayjs from "dayjs";
import "./Replier.scss";
import QuillTextEditor from "../../../QuillTextEditor/QuillTextEditor";
import { hasContent } from "../../utils";

const Replier = ({
  disabled,
  comment = {},
  onChange,
  value = [],
  author,
  // status,
  newCommentBtnRef,
  people,
}) => {
  const [replying, setReplying] = useState(false);
  const [commentValue, setCommentValue] = useState();
  const quillRef = useRef();

  const resetReplier = () => {
    setReplying(false);
    setCommentValue("");
  };

  const recursivelyFindAndReplaceObj = (arr = [], newObj, key, value) =>
    arr.map((obj) =>
      obj[key] === value
        ? newObj
        : !!obj.replies
        ? {
            ...obj,
            replies: recursivelyFindAndReplaceObj(
              obj.replies,
              newObj,
              key,
              value
            ),
          }
        : obj
    );

  const saveReply = () => {
    const editor = quillRef.current.getEditor();
    const deltas = editor.getContents(commentValue);

    const mentions = uniqBy(
      deltas
        .filter((delta) => !!delta?.insert?.mention)
        .map(({ insert: { mention } }) => ({
          name: mention.value,
          cognitoId: mention.cognitoId,
        })),
      "cognitoId"
    );

    const { replies = [] } = comment;
    const newComment = {
      ...comment,
      replies: [
        ...replies,
        {
          id: v4(),
          author,
          // status,
          createdAt: dayjs().valueOf(),
          content: commentValue,
          mentions,
        },
      ],
    };

    onChange(
      recursivelyFindAndReplaceObj(value, newComment, "id", comment?.id)
    );
    resetReplier();
  };

  useEffect(() => {
    const newBtn = newCommentBtnRef?.current;
    !!replying
      ? newBtn?.classList?.add("hidden")
      : newBtn?.classList?.remove("hidden");
  }, [replying]);

  return (
    <div className="commentReplier">
      {!replying ? (
        <span
          onClick={() => setReplying(true)}
          style={!!disabled ? { display: "none" } : undefined}
          className="replyLabel"
        >
          Add a reply...
        </span>
      ) : (
        <div>
          <QuillTextEditor
            {...{
              placeholder: "Type here...",
              ref: (el) => !!el && (quillRef.current = el),
              value: commentValue,
              onChange: (val) => setCommentValue(val),
              people,
            }}
          />
          <div className="replierActionBtn">
            <Button onClick={resetReplier}>Cancel</Button>
            <Button
              onClick={() =>
                hasContent(commentValue) ? saveReply() : resetReplier()
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Replier;
