import { apiPut } from "../../../../../../../../utils";
import { API } from "aws-amplify";
import { message } from "antd";

export async function createUserLog(saveAddedLogs, res) {
  let body = {
    recordId: res?.sub,
    recordName: `${res?.given_name} ${res?.family_name}`,
    actionType: "User Creation",
    category: "Users",
    topic: "Fleet",
  };
  saveAddedLogs(body);
}

export async function userLink({
  user,
  rowData,
  endpoint,
  keyId,
  linkKey,
  saveAddedLogs,
  elementName,
}) {
  await apiPut("userConfiguration", user?.identityId, {
    linked: true,
  })
    .then(() => {
      console.log({ linkKey });
      apiPut(endpoint, rowData?.[keyId], {
        [linkKey]: user?.cognitoUserId,
      })
        .then(() => {
          saveAddedLogs({
            recordId: user?.identityId,
            recordName: user?.nameOfUser,
            actionType: "Edit",
            category: "Users Actions",
            updatedKeys: [
              {
                label: `${user?.nameOfUser} linked status`,
                previousValue: "Unlinked",
                updatedValue: `Linked to: ${rowData?.[elementName]}`,
              },
            ],
          });
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    })
    .catch((err) => {
      console.log("Error Linking User: ", err);
      message.error(
        "An error ocurred while linking the driver, please try again later."
      );
    });
}
