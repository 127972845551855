import { titleBodyHtmlTable } from "../../../../../../../Safety/SafetyModal/components/SafetyExportPreview/helpers/convertSafetyReport";

function convertTaskData(data) {
  const dataSections = [];
  if (!Array.isArray(data)) {
    return dataSections;
  }
  for (const section of data) {
    if (section.title === "Task Dependency") {
      const { title, body } = section;
      let titleClassName = `dependency-section-header`;
      const titleFormat = {
        text: {
          paragraphs: [
            {
              text: title,
              bold: true,
              fontSize: 16,
              class: titleClassName,
            },
          ],
        },
      };
      dataSections.push(titleFormat);
      body.forEach((el) => {
        const getTable = titleBodyHtmlTable(el, "dependency-body-header");
        dataSections.push(...getTable);
      });
    } else {
      const getTable = titleBodyHtmlTable(section);
      dataSections.push(...getTable);
    }
  }
  return dataSections;
}

export default convertTaskData;
