import React from "react";
import dayjs from "dayjs";
import { DatePicker, Input } from "antd";
import { useSelector } from "react-redux";

import { useRedux } from "../../../../../../../../../hooks/useRedux";
import { validateThenSavePayment } from "../../../../utils/checkers";

import "./PaymentWireForm.scss";

const PaymentWireForm = ({ paymentData }) => {
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable] = useRedux("paymentIsWritable", false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div className="payment-wire-form-container">
      <div className="payment-wire-date-details">
        <label>Posted Date</label>
        <DatePicker
          name="Posted Date"
          className="input-form"
          popupClassName={isDarkMode && "darkDateDropDown"}
          required
          allowClear={false}
          placeholder="Posted Date"
          value={
            fakePayment?.wireDetails?.postedDate
              ? dayjs(fakePayment?.wireDetails?.postedDate).tz(
                  "America/New_York"
                )
              : undefined
          }
          onChange={(value) =>
            setFakePayment(
              validateThenSavePayment({
                ...fakePayment,
                wireDetails: {
                  ...fakePayment.wireDetails,
                  postedDate: dayjs(value).valueOf(),
                },
              })
            )
          }
          format="MM/DD/YYYY"
          disabled={paymentData && !isWritable}
        />
      </div>
      <div className="payment-wire-date-details">
        <label htmlFor="Wire #">Wire Number</label>
        <Input
          className="input-form"
          name="wireNumber"
          placeholder="Wire Number"
          value={fakePayment?.wireDetails?.wireNumber}
          onChange={(e) =>
            setFakePayment(
              validateThenSavePayment({
                ...fakePayment,
                wireDetails: {
                  ...fakePayment.wireDetails,
                  wireNumber: e.target.value,
                },
              })
            )
          }
          disabled={paymentData && !isWritable}
        />
      </div>
    </div>
  );
};

export default PaymentWireForm;
