import { months } from "../../../addWidgetsModalData";

export const rentals = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  rentalsData
) => {
  const rentalStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Rentals.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    rentalStatus: () => ({
      title: "Rentals - Category From",
      BodyComponent: "LineChartFull",
      type: "LineChartFull",
      id: IdHandler,
      BodyComponentParams: {
        table: "Rentals",
        field: "rentalStatus",
        labels: rentalStatus?.statusName,
        datasets: [
          {
            label: "Rentals",
            data: 0,
            backgroundColor: rentalStatus?.statusColor,
            borderColor: rentalStatus?.statusColor,
            // borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
    totalPrice: () => ({
      title: "Rentals - Total Price",
      BodyComponent: "LineChartFull",
      type: "LineChartFull",
      id: IdHandler,
      BodyComponentParams: {
        table: "Rentals",
        field: "totalPrice",
        labels: months,
        custom: 0,
        datasets: [
          {
            label: "Total Price",
            data: 0,
            backgroundColor: "#2DA365",
            borderColor: "#2DA365",
            // borderWidth: 0,
          },
          {
            label: "Total Applied Rent",
            data: 0,
            backgroundColor: "#B5D99C",
            borderColor: "#B5D99C",
            // borderWidth: 0,
          },
          {
            label: "Total Retainage",
            data: 0,
            backgroundColor: "#F6CB51",
            borderColor: "#F6CB51",
            // borderWidth: 0,
          },
          {
            label: "Total Tax",
            data: 0,
            backgroundColor: "#f04f4e",
            borderColor: "#f04f4e",
            // borderWidth: 0,
          },
        ],
      },
      width: "790px",
      id: IdHandler,
    }),
  };
};
