export const initialStates = {
  categoryToDrawGeofence: false,
  cateogryIdToDrawGeofence: [],
  selectedCatAdd: null,
  fetchingData: false,
  savingGeofenceToDb: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "categoryToDrawGeofence":
      return { ...state, categoryToDrawGeofence: action.payload };
    case "cateogryIdToDrawGeofence":
      return { ...state, cateogryIdToDrawGeofence: action.payload };
    case "selectedCatAdd":
      return { ...state, selectedCatAdd: action.payload };
    case "fetchingData":
      return { ...state, fetchingData: action.payload };
    case "savingGeofenceToDb":
      return { ...state, savingGeofenceToDb: action.payload };
    default:
      return state;
  }
};
