export const ACTIONS_REGISTER_INIT = {
  actions: [{}],
  index: 0,
};

function ACTIONS_REGISTER_REDUCER(state, action) {
  const indexInMiddleOfActions = state?.index !== state?.actions?.length - 1;

  switch (action.type) {
    case "new":
      let newIndexToAdd = 1;
      const newAction = {
        type: "new",
        action: action.newActions,
      };

      let caseNewActions = indexInMiddleOfActions
        ? state.actions.slice(0, state.index + 1).concat([newAction])
        : state.actions.concat([newAction]);

      if (caseNewActions?.length > 21) {
        caseNewActions.splice(1, 1);
        newIndexToAdd = 0;
      }

      return {
        actions: caseNewActions,
        index: indexInMiddleOfActions
          ? caseNewActions?.length - 1
          : state.index + newIndexToAdd,
      };
    case "edit":
      let editIndexToAdd = 1;
      const editAction = {
        type: "edit",
        action: action.editActions,
      };

      let caseEditActions = indexInMiddleOfActions
        ? state.actions.slice(0, state.index + 1).concat([editAction])
        : state.actions.concat([editAction]);

      if (caseEditActions?.length > 21) {
        caseEditActions.splice(1, 1);
        editIndexToAdd = 0;
      }
      return {
        actions: caseEditActions,
        index: indexInMiddleOfActions
          ? caseEditActions?.length - 1
          : state.index + editIndexToAdd,
      };
    case "remove":
      let removeIndexToAdd = 1;
      const removeAction = {
        type: "remove",
        action: action.removeActions,
      };

      let caseRemoveActions = state.actions.concat([removeAction]);
      if (caseRemoveActions?.length > 21) {
        caseRemoveActions.splice(1, 1);
        removeIndexToAdd = 0;
      }

      return {
        actions: caseRemoveActions,
        index: state.index + removeIndexToAdd,
      };
    case "massChanges":
      let massChangeIndexToAdd = 1;
      const massChangesAction = {
        type: "massChanges",
        action: action.massChangeActions,
      };
      let caseMassChangeActions = state.actions.concat([massChangesAction]);
      if (caseMassChangeActions?.length > 21) {
        caseMassChangeActions.splice(1, 1);
        massChangeIndexToAdd = 0;
      }
      return {
        actions: caseMassChangeActions,
        index: state.index + massChangeIndexToAdd,
      };
    case "undo":
      const undoIndex = state.index - 1;
      if (undoIndex > -1) {
        return {
          ...state,
          index: undoIndex,
        };
      } else {
        return state;
      }
    case "redo":
      const redoIndex = state.index + 1;
      if (redoIndex < state?.actions?.length) {
        return {
          ...state,
          index: redoIndex,
        };
      } else {
        return state;
      }
  }
}

export default ACTIONS_REGISTER_REDUCER;
