import { Button } from "antd";
import { NormalSizedModal } from "../../../../../../components";
import "./AskForTaskModal.scss";
import { MondayButton } from "../../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../Communication/assets";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
const AskForTaskModal = ({
  title,
  askTaskModal,
  cancelTaskModal,
  span,
  createTask,
  isDarkMode,
}) => {
  return (
    <div className="task">
      <NormalSizedModal
        title={title}
        centered
        destroyOnClose
        className={`taskRequest ${isDarkMode && "taskRequestDark"}`}
        visible={askTaskModal}
        onCancel={cancelTaskModal}
      >
        <div className="question">
          {span}
          <div className="buttonContainer">
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={cancelTaskModal}
            >
              No
            </MondayButton>
            <MondayButton
              onClick={createTask}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </NormalSizedModal>
    </div>
  );
};

export default AskForTaskModal;
