import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { fetchAllData } from "../../../../../../utils";
import { getChangedData } from "../../../../../SidebarPages/Accounting/components/utilities";

/**
 *
 * @param {{endpoint: "engineers" | "architects"}} param
 */
export async function getNonUserEmployees({ endpoint }) {
  if (!endpoint) return [];
  let data = [];
  try {
    const res = await API.get(endpoint, `/${endpoint}`);

    for (const emp of res) {
      Object.assign(emp, { tableId: uuidv4() });
      if (emp?.userLink?.length) {
        data.push(emp);
      } else {
        data = [emp].concat(data);
      }
    }
  } catch (error) {
    console.log(`There was a problem getting data for ${endpoint}: `, error);
  } finally {
    return data;
  }
}

export function onSave({
  form,
  setModalVisible,
  userConfiguration,
  setEditLogs,
  setFetchData,
  saveAddedLogs,
}) {
  const { email, firstName, lastName, role } = form.getFieldsValue();
  let endpoint;
  let tmpBody = {
    userName: email,
    nameOfUser: firstName + " " + lastName,
    createdBy: {
      nameOfUser: userConfiguration?.nameOfUser,
      cognitoId: userConfiguration?.cognitoUserId,
    },
  };

  if (role === "engineer") {
    endpoint = "engineers";
  } else if (role === "architect") {
    endpoint = "architects";
  } else {
    endpoint = "";
  }
  if (!!endpoint?.length) {
    postLogs({
      record: tmpBody,
      type: "save",
      setEditLogs,
      saveAddedLogs,
    });

    API.post(endpoint, `/${endpoint}`, {
      body: {
        ...tmpBody,
      },
    })
      ?.then((res) => {
        setFetchData((prevState) => {
          return [
            ...prevState,
            {
              ...res,
              role: endpoint === "engineers" ? "engineer" : "architect",
            },
          ];
        });
        setModalVisible(false);
      })
      ?.catch((err) => {
        console.error("Error saving engineer/architect: ", err);
      });
  } else {
    Promise.allSettled([
      API.post("engineers", "/engineers", {
        body: {
          ...tmpBody,
        },
      }),

      API.post("architects", "/architects", {
        body: {
          ...tmpBody,
        },
      }),
    ])
      ?.then(([{ value: engineer }, { value: architect }]) => {
        setFetchData((prevState) => {
          return [
            ...prevState,
            {
              ...engineer,
              architectId: architect?.architectId,
              role: "engineerArchitect",
            },
          ];
        });
        setModalVisible(false);
      })
      ?.catch((err) => {
        console.error("Error saving engineer/architect: ", err);
      });
  }
}

export function onDelete({
  defaultObject,
  setEngineersData,
  setArchitectsData,
  setModalVisible,
  setEditLogs,
  saveAddedLogs,
}) {
  let endpoint;
  let idKey;

  if (!!defaultObject?.hasOwnProperty("engineerId")) {
    endpoint = "engineers";
    idKey = "engineerId";
  } else if (!!defaultObject?.hasOwnProperty("architectId")) {
    endpoint = "architects";
    idKey = "architectId";
  }

  postLogs({
    record: defaultObject,
    idKey,
    type: "delete",
    setEditLogs,
    saveAddedLogs,
  });

  return;
  API.del(`${endpoint}`, `/${endpoint}/${defaultObject[idKey]}`)
    ?.then(() => {
      setModalVisible(false);
      endpoint === "engineers"
        ? setEngineersData((prevState) => {
            return prevState?.filter((e) => e[idKey] !== defaultObject[idKey]);
          })
        : setArchitectsData((prevState) => {
            return prevState?.filter((e) => e[idKey] !== defaultObject[idKey]);
          });
    })
    ?.catch((err) => {
      console.error("Error deleting engineer/architect: ", err);
    });
}

export async function getLogs({ setEditLogs }) {
  await fetchAllData({
    endpoint: "editLogs",
    resultPosition: "editLogs",
    resultId: "logId",
    otherStringParams: {
      getMaxLimit: "true",
      filters: JSON.stringify([
        {
          conditions: [
            {
              column: "category",
              value: "Engineers and Architects",
              formula: "is",
            },
          ],
        },
      ]),
    },
  })
    ?.then((res) => {
      setEditLogs((prev) => [...prev, ...res]);
    })
    ?.catch((err) => {
      console.error("Error getting edit logs: ", err);
    });
}

export async function postLogs({
  record,
  idKey,
  type,
  setEditLogs,
  prevRecord,
  saveAddedLogs,
}) {
  let newEditLog = {
    recordId: record[idKey],
    recordName: record.userName,
    category: "Engineers and Architects",
    actionType: "",
  };

  switch (type) {
    case "save": {
      saveAddedLogs({
        ...newEditLog,
        currentData: record,
        actionType: "Create",
      })
        ?.then((res) => {
          setEditLogs((prevState) => {
            return [...prevState, ...res];
          });
        })
        ?.catch((err) => {
          console.error("Error saving edit log: ", err);
        });
      break;
    }

    case "update": {
      let res = getChangedData({ record, prevRecord });
      let tmpLog = {
        ...newEditLog,
        currentData: res?.curr,
        previousData: res?.prev,
        actionType: "Edit",
      };
      saveAddedLogs(tmpLog)
        ?.then((res) => {
          setEditLogs((prevState) => {
            return [...prevState, ...res];
          });
        })
        ?.catch((err) => {
          console.error("Error saving edit log: ", err);
        });
      break;
    }

    case "delete": {
      let tmpLog = { ...newEditLog, previousData: record };
      saveAddedLogs(tmpLog)
        .then((res) => {
          setEditLogs((prevState) => {
            return [...prevState, ...res];
          });
        })
        ?.catch((err) => {
          console.error("Error saving edit log: ", err);
        });
      break;
    }
  }
}
