import { SearchOutlined } from "@ant-design/icons";
import { Divider, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { lazyFetch } from "../../../../utils";
import { InputComponent } from "../../../SidebarPages/Fleet/components/index";
import "./GlobalSearch.scss";
import {
  addedMissingCategories,
  addedMissingTopicCategories,
  generateLabel,
  generateLinkTo,
  getIcon,
} from "./helpers";
import { debounce } from "lodash";
import { useMediaQuery } from "react-responsive";
import { WithTooltip } from "../../../commonComponents";
import { retryFetch, sleep } from "../../../../utils/helpers/helpers";

export const GlobalSearch = memo(() => {
  const { topicCategories = [] } = useSelector(
    (state) => state.topicCategories
  );
  const [lastEl, setLastEl] = useState(1);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [categories, setCategories] = useState();
  const [saveCat, setSaveCat] = useState();
  const [initialValue, setInitialValue] = useState();
  const navigate = useNavigate();
  const [categoriesData, setCategoriesDatas] = useState({});
  const [tempData, setTempData] = useState({});
  const IconSaveCat = getIcon(saveCat);
  const handleNavigate = (linkTo, props) => {
    const proceedProps = typeof props === "string" ? { props } : props;
    setSaveCat();
    setInitialValue(null);

    if (linkTo) {
      navigate(linkTo, { state: { ...proceedProps } });
    }
  };

  const hasGlobalSearch = [
    ...userConfiguration?.routeConfig.map(({ title }) => title),
    ...addedMissingCategories,
  ];

  const proceedTopicCategories = [
    ...topicCategories,
    ...addedMissingTopicCategories,
  ].reduce((acc, sample) => {
    const existingIndex = acc.findIndex(
      (item) => item.categoryName === sample.categoryName
    );

    if (existingIndex === -1) {
      acc.push(sample);
    } else {
      const existingItem = acc[existingIndex];

      if (!existingItem.newRowName && sample.newRowName) {
        acc[existingIndex] = sample;
      }
    }

    return acc;
  }, []);

  useEffect(() => {
    const categoriesWithGlobalSearch = proceedTopicCategories.filter(
      ({ categoryName, apiName, rowName, primaryKey }) =>
        hasGlobalSearch.includes(categoryName) &&
        apiName &&
        rowName &&
        primaryKey
    );

    setCategories(
      categoriesWithGlobalSearch.map(({ categoryName }) => categoryName)
    );

    Promise.allSettled(
      categoriesWithGlobalSearch
        .map(
          async (
            {
              categoryName,
              apiName,
              rowName,
              primaryKey,
              otherName,
              lazyFetchKeys,
              filterKey,
              filterValue,
            },
            index
          ) => {
            const listOfKeys = [
              ...[rowName, primaryKey, otherName],
              ...(categoryName ? ["categoryName", "recordId"] : []),
              ...(lazyFetchKeys &&
                lazyFetchKeys.filter((key) => key !== "status")),
              apiName === "scheduling" ? "scheduleName" : "",
            ].filter(Boolean);

            const listOfKeysSets = [...new Set(listOfKeys)];

            const fetchCategories = () =>
              lazyFetch({
                tableName: apiName,
                listOfKeys: listOfKeysSets,
                ...(filterKey && { filterKey }),
                ...(filterValue && { filterValue }),
              });

            await sleep(index * 50);

            return await retryFetch(fetchCategories).catch((err) => {
              console.error(
                `Error fetching API, Error status: ${err.response?.status} Too many requests LIMIT, API name: ${apiName}`
              );

              return {
                status: "rejected",
                error: err,
                categoryName,
                apiName,
                primaryKey,
              };
            });
          }
        )
        .filter(Boolean)
    )
      .then(async (res) => {
        const fulfilledRes = res.map(({ value }) =>
          Array.isArray(value) ? value : []
        );

        const structuredData = categoriesWithGlobalSearch.reduce(
          (acc, curr, i) => {
            acc[curr.categoryName] = fulfilledRes[i].map((element) => {
              const label = generateLabel(curr, element);
              const linkTo = generateLinkTo(curr, element);

              return {
                ...element,
                key: element[curr.primaryKey],
                label,
                value: element[curr.primaryKey],
                linkTo,
                categoryName: curr.categoryName,
              };
            });
            return acc;
          },
          {}
        );

        setCategoriesDatas(structuredData);
        setTempData(structuredData);
      })
      .catch((error) => {
        console.log("Error processing categories", error);
      });
  }, []);

  const handlePopupScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setLastEl((prev) => (prev += 1));
    }
  };

  const debouncedSearch = debounce((value) => {
    value ? setLastEl(100000) : 1;
    const filteredCategoriesData = Object.fromEntries(
      Object.entries(categoriesData)
        .map(([category, elements]) => {
          const filteredElements = elements.filter((element) =>
            element.label.toLowerCase().includes(value.toLowerCase())
          );

          return [category, filteredElements];
        })
        .filter(([_, filteredElements]) => filteredElements.length > 0)
    );

    setTempData(filteredCategoriesData);
  }, 300);

  const debouncedHandleKeyDown = debounce((event) => {
    if (event.keyCode === 8) {
      setLastEl(1);
    }
  }, 300);

  const isHidden = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    setSaveCat();
    setInitialValue();
  }, [isHidden]);

  return (
    <div className="global-search-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="search">
        <div
          className={`search-input-container ${isDarkMode ? "dark-mode" : ""}`}
        >
          <SearchOutlined className="search-icon" />
          <InputComponent
            {...{
              initialValue,
              selectClassName: "search-input",
              type: "select",
              customOptions: saveCat
                ? [
                    {
                      label: (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {IconSaveCat && (
                            <IconSaveCat fill={isDarkMode ? "#fff" : "#000"} />
                          )}
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {saveCat}
                          </p>
                        </div>
                      ),
                      options: categoriesData[saveCat],
                    },
                  ]
                : Object.entries(tempData)
                    .map(([category, elements], i) => {
                      const Icon = getIcon(category);
                      return {
                        label: (
                          <div
                            id={i}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {Icon && (
                              <Icon fill={isDarkMode ? "#fff" : "#000"} />
                            )}
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {category}
                            </p>
                          </div>
                        ),
                        options: elements,
                      };
                    })
                    .slice(0, lastEl),
              showSearch: true,
              placeholder: `Search for ${
                saveCat ? saveCat : "Projects, tasks etc..."
              }`,
              dropdownClassName: `search-dropDown ${
                isDarkMode && "search-dropDown-dark darkDropDown"
              }`,
              onPopupScroll: handlePopupScroll,
              onChange: (values, { linkTo, label, categoryName }) => {
                const locationProps =
                  categoryName === "Notes"
                    ? {
                        notesOpen: true,
                        commentId: values,
                        category: categoryName,
                      }
                    : categoryName === "Reports" || categoryName === "Crews"
                    ? label.replace("|", "").trim()
                    : values;

                handleNavigate(linkTo, locationProps);
              },
              onSearch: (value) => debouncedSearch(value),
            }}
            showArrow={false}
            onClear={() => {
              setLastEl(1);
              setTempData(categoriesData);
              setInitialValue(null);
            }}
            onInputKeyDown={debouncedHandleKeyDown}
          />
        </div>
      </WithTooltip>

      {!isHidden && (
        <>
          <Divider
            type="vertical"
            className={`divider ${isDarkMode ? "dark-mode" : ""}`}
          />
          <InputComponent
            allowClear
            type="select"
            dropdownClassName={isDarkMode && "darkDropDown"}
            className="searchFilter-select"
            placeholder="Select category..."
            showSearch
            onChange={(val) => {
              setSaveCat(val);
              setInitialValue(null);
            }}
            initialValue={saveCat}
            options={categories?.map((categoryName, i) => (
              <Select.Option key={i} value={categoryName}>
                {categoryName}
              </Select.Option>
            ))}
          />
        </>
      )}
    </div>
  );
});
