import { useState } from "react";
import { Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

/**
 * Represents a component for adding a new BIN.
 *
 * @component
 * @param {Array} BIN - The array of BIN values.
 * @param {Function} setBIN - The function to set the BIN values.
 * @returns {JSX.Element} The JSX element representing the NewBIN component.
 */
const NewBIN = ({ BIN = [], setBIN = () => {} }) => {
  const [summary, setSummary] = useState("");
  const handleChange = (event) => {
    setSummary(event.target.value);
  };

  const handleNewBIN = () => {
    if (summary != "") {
      const newBIN = !BIN.includes(summary) && [...BIN, summary];
      newBIN && setBIN(newBIN);
    }
    setSummary("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
      }}
    >
      <Input
        type="number"
        size="small"
        style={{ height: "30px", marginRight: "10px" }}
        value={summary}
        placeholder="Add new BIN"
        onChange={handleChange}
      />
      <PlusOutlined onClick={handleNewBIN} style={{ color: "#1264a3" }} />
    </div>
  );
};

export default NewBIN;
