import _ from "lodash";
import { Task } from "frappe-gantt-react";

import calcTaskProgress from "./calcTaskProgress";
import findInspectionRelationships from "./findInspectionRelationships";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { DefectType, FleetInspectionType, WorkOrderType } from "../types";

type Dictionary<T> = {
  [key: string]: T;
};

type RenderParam = {
  defects: DefectType[];
  workOrders: WorkOrderType[];
  inspections: FleetInspectionType[];
};

function renderInspectionTasks({
  defects,
  inspections,
  workOrders,
}: RenderParam): Task[] {
  const { workOrderDependencies, inspectionDependencies } =
    findInspectionRelationships(defects);
  const tmpTasks: Task[] = [];

  const allWorkOrders: Dictionary<WorkOrderType> = _.keyBy(
    workOrders,
    "workOrderId"
  );

  const sortedOrderKeys = Object.keys(allWorkOrders).sort(
    (a, b) =>
      ("" + allWorkOrders[a]["fleetName"]).localeCompare(
        "" + allWorkOrders[b]["fleetName"]
      ) ||
      (allWorkOrders[a]["issueDate"] || allWorkOrders[a]["createdAt"]) -
        (allWorkOrders[b]["issueDate"] || allWorkOrders[b]["createdAt"])
  );

  const allDefects: Dictionary<DefectType> = _.keyBy(defects, "defectId");

  const allInspections: Dictionary<FleetInspectionType> = _.keyBy(
    inspections,
    "inspectionId"
  );

  const sortedInspectionKeys = Object.keys(allInspections).sort(
    (a, b) =>
      ("" + allInspections[a]["fleetName"]).localeCompare(
        "" + allInspections[b]["fleetName"]
      ) ||
      new Date(allInspections[a]["inspectionDate"]).valueOf() -
        new Date(allInspections[b]["inspectionDate"]).valueOf()
  );

  for (const inspId of sortedInspectionKeys) {
    const inspection = allInspections[inspId];

    tmpTasks.push(
      new Task({
        custom_class: `task-type ${
          "inspection-task-" + inspection.inspectionStatus.replaceAll(" ", "")
        } ${inspection.fleetId}`,
        dependencies: "",
        id: inspId,
        start: dayjsNY(inspection.inspectionDate).startOf("day").toISOString(),
        end: dayjsNY(inspection.inspectionDate).startOf("day").toISOString(),
        name: `[${inspection.inspectionId}] - ${inspection.fleetName}`,
        progress: calcTaskProgress(
          dayjsNY(inspection.inspectionDate).startOf("day").valueOf(),
          dayjsNY(inspection.inspectionDate).endOf("day").valueOf()
        ),
      })
    );

    if (!inspectionDependencies[inspId]) {
      continue;
    }

    for (const defectId of inspectionDependencies[inspId]) {
      const defect = allDefects[defectId];
      if (!defect) {
        continue;
      }

      tmpTasks.push(
        new Task({
          custom_class: `task-type unmatched ${
            "defect-task-" + defect.defectStatus.replaceAll(" ", "")
          } ${inspection.fleetId}`,
          dependencies: inspId,
          id: defectId,
          start: dayjsNY(inspection.inspectionDate)
            .startOf("day")
            .toISOString(),
          end: dayjsNY(inspection.inspectionDate).startOf("day").toISOString(),
          name: `[${defectId}] - ${defect.defectName}`,
          progress: 0,
        })
      );
    }
  }

  for (const woId of sortedOrderKeys) {
    const workOrder = allWorkOrders[woId];

    const dependencies = Object.keys(workOrderDependencies?.[woId] || "").join(
      ","
    );

    const isOverdue =
      !workOrder.completionDate &&
      workOrder.workOrderStatus !== "Completed" &&
      dayjsNY(workOrder.dueDate).startOf("day").valueOf() <
        dayjsNY().startOf("day").valueOf();

    tmpTasks.push(
      new Task({
        custom_class: `task-type ${
          "work-order-task-" + workOrder.workOrderStatus.replaceAll(" ", "")
        } ${isOverdue ? "overdue" : ""} ${workOrder.fleetId}`,
        dependencies: dependencies,
        end: dayjsNY(
          isOverdue
            ? Date.now()
            : workOrder?.completionDate || workOrder.dueDate
        )
          .startOf("day")
          .toISOString(),
        start: dayjsNY(workOrder?.issueDate || workOrder.createdAt)
          .startOf("day")
          .toISOString(),
        name: woId,
        progress: calcTaskProgress(
          workOrder?.issueDate || workOrder.createdAt,
          workOrder.dueDate
        ),
        id: woId,
      })
    );

    if (!workOrderDependencies[woId]) {
      continue;
    }

    for (const inspId in workOrderDependencies[woId]) {
      for (const defectId of workOrderDependencies[woId][inspId]) {
        const defect = allDefects[defectId];
        if (!defect) {
          continue;
        }

        tmpTasks.push(
          new Task({
            custom_class: `task-type ${
              "defect-task-" + defect.defectStatus.replaceAll(" ", "")
            } ${workOrder.fleetId}`,
            dependencies: woId,
            id: defect.defectId,
            end: dayjsNY(
              isOverdue
                ? Date.now()
                : workOrder?.completionDate || workOrder.dueDate
            )
              .startOf("day")
              .toISOString(),
            start: dayjsNY(workOrder?.issueDate || workOrder.createdAt)
              .startOf("day")
              .toISOString(),
            name: `[${defect.defectId}] - ${defect.defectName}`,
            progress: calcTaskProgress(
              workOrder?.issueDate || workOrder.createdAt,
              workOrder.dueDate
            ),
          })
        );
      }
    }
  }

  if (!tmpTasks.length) {
    return [
      new Task({
        name: "No Data Available",
        custom_class: "NoRecords",
      }),
    ];
  }

  return tmpTasks;
}

export default renderInspectionTasks;
