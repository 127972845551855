import { useState, useMemo } from "react";
import { Button, message, Tooltip } from "antd";
import ArrowDown from "../../../../../../../icons/DataGrid_Down_Red.webp";
import ArrowUp from "../../../../../../../icons/DataGrid_Up_Green.webp";
import {
  calculateRent,
  calculateTotalPrice,
  priceFormatter,
} from "../../../tools/formatters/pricing";
import { getMaxNoFeetWithMinus } from "../../../tools/formatters/getMax";
import { useRedux } from "../../../../../hooks/useRedux";
import { MinusOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

function PPUSuggestor(props) {
  const [confirmedSuggestions, setConfirmedSuggestions] = useState(null);
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const getPriceSheetSuggestion = () => {
    const widthDimensions = props?.priceSheetData?.x ?? [];
    const heightDimensions = props?.priceSheetData?.y ?? [];
    var rowIndex = props.node.rowIndex;
    var currRow =
      props.api.getDisplayedRowAtIndex(rowIndex)?.data || props?.data || {};
    let width = getMaxNoFeetWithMinus(currRow?.width);
    let height = getMaxNoFeetWithMinus(currRow?.height);

    const xIndex = upperIndexOfValueBetween2Values(widthDimensions, width);
    console.log("xIndex", xIndex);
    // console.log(width, height)
    let toReturn = 0;
    if (xIndex !== false) {
      const yIndex = upperIndexOfValueBetween2Values(heightDimensions, height);
      console.log("yIndex", yIndex);
      if (yIndex !== false) {
        toReturn = props.priceSheetData.z[yIndex][xIndex];
      }
    }
    console.log("toReturn", toReturn);
    return toReturn;
  };

  const upperIndexOfValueBetween2Values = (array, value) => {
    if (array.length <= 0) return false;

    for (let i = 0; i < array.length - 1; i++) {
      //we will not check the last element
      const thisDimension = parseInt(array[i]);
      if (value <= thisDimension) return i; //if value is smaller than array first value, return the first index
      if (thisDimension <= value && value <= parseInt(array[i + 1]))
        return i + 1;
    }
    return false;
  };

  console.log("PPU Suggestor", props);

  const updateSWSPPU = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    value
  ) => {
    let tempData = _.cloneDeep(gridData);
    for (let item of tempData[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items) {
      if (item.id === itemId) {
        // item = gridApi?.data || {};
        Object.assign(item, props?.data);
        item.ppu = value;
        // item.lock = true;
        item.price = value * getMaxNoFeetWithMinus(item.length);
        item.rent = calculateRent(tempData[serviceIndex], item, optionIndex);
        item.taxAmount = item.taxRate * item.price;
        item.totalPrice = calculateTotalPrice(item);
        item.lock = true;

        console.log("editeditem", item);
        gridApi.applyTransaction({ update: [item] }); //add it into ag-grid table
        gridApi.refreshCells({
          force: true,
          columns: ["ppu"],
          rowNodes: [item],
        });
        break;
      }
    }
    console.log("after PPUUUU", tempData);
    setTakeOffTableData(tempData);
  };

  const handleApplySuggestion = () => {
    //     if(this.props.data.lock) {
    //       message.warning("This PPU is locked. Please unlock it to apply default prices")
    //     } else
    updateSWSPPU(
      props.api,
      props.indexes.serviceIndex,
      props.indexes.optionIndex,
      props.indexes.elevationIndex,
      props.data.id,
      getPriceSheetSuggestion()
    );
    //     this.setState({confirmedSuggestion: 0})
  };

  const totalities = useMemo(() => {
    const temp = {
      arrow: null,
      value: 0,
      difference: 0,
      suggestedPrice: 0,
    };
    temp.value = parseFloat(props?.value || 0);
    temp.suggestedPrice = parseFloat(getPriceSheetSuggestion());
    console.log("totalities", temp);
    if (temp.value > temp.suggestedPrice) {
      temp.arrow = (
        <img alt="..." className="data-grid-price-arrow" src={ArrowUp} />
      );
    } else if (temp.value < temp.suggestedPrice) {
      temp.arrow = (
        <img alt="..." className="data-grid-price-arrow" src={ArrowDown} />
      );
    } else if (temp.value === temp.suggestedPrice) {
      temp.arrow = <MinusOutlined />;
    } else
      temp.arrow = <MinusSquareOutlined style={{ visibility: "hidden" }} />;

    temp.difference =
      Math.round(Math.abs(temp.suggestedPrice - temp.value) * 100) / 100 ?? "";
    if (isNaN(temp.difference) || temp.difference === 0) temp.difference = "";
    return temp;
  }, [props.value, props.api, props.data, gridData]);

  const { arrow, value, difference, suggestedPrice } = totalities;

  console.log("totalities_diff", totalities);
  // //   let arrow,
  // //     value = parseFloat(props.value),
  //     suggestedPrice = parseFloat(getPriceSheetSuggestion()),
  //     difference;
  //   if (value > suggestedPrice) {
  //     arrow = <img alt="..." className="data-grid-price-arrow" src={ArrowUp} />;
  //   } else if (value < suggestedPrice) {
  //     arrow = <img alt="..." className="data-grid-price-arrow" src={ArrowDown} />;
  //   } else if (value === suggestedPrice) {
  //     arrow = <Remove />;
  //   } else arrow = <IndeterminateCheckBox style={{ visibility: "hidden" }} />;

  //   difference = Math.round(Math.abs(suggestedPrice - value) * 100) / 100;

  //   let differenceView = difference;
  //   if (isNaN(difference) || difference === 0) differenceView = "";

  return (
    <div>
      <div style={{ display: "flex" }} className={"ppu-advisor-cell"}>
        <span className="ppu-difference">
          {arrow} {difference}
        </span>
        <span
          style={{
            flexGrow: 1,
            marginTop: 5,
            marginLeft: 10,
            textAlign: "right",
          }}
        >
          {priceFormatter({ value: props.value })}
        </span>{" "}
        {difference !== 0 && suggestedPrice !== 0 ? (
          <Tooltip title={`Apply suggestion: ${suggestedPrice}`}>
            <Button
              style={{ marginLeft: 15 }}
              size="small"
              type="primary"
              onClick={handleApplySuggestion}
              disabled={!props.isWritable || props.data.lock}
            >
              {/* <img
          src="https://img.icons8.com/material-outlined/16/f5f5f5/submit-document.png"
          alt=""
        /> */}
              {suggestedPrice}
            </Button>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PPUSuggestor;
