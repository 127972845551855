import AccountingConfirmationModal from "../../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal"
import "../../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal"
import { RefreshIcon } from "../../../../../../../../../../Communication/assets"
import { useDispatchContext, useStateContext } from "../../../../../../Context/Context"
import { getDataFromDbHandler } from "./refreshGridFunctions"
import "./RefreshGrid.scss"

import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Tooltip } from "antd"
import { useState } from "react"

const RefreshGrid = () => {
  const { isLoading } = useSelector((state) => state?.scheduleOfValues)
  const [visible, setVisible] = useState(false)
  const { scheduleId } = useParams()

  const dispatchContext = useDispatchContext()
  const dispatch = useDispatch()

  const getDataFromDb = () => getDataFromDbHandler({ dispatch, dispatchContext, scheduleId })
  const { darkMode } = useStateContext()

  return (
    <>
      <Tooltip title="Restart Grid">
        <div className="refreshButtonContainer">
          <Button type="primary" disabled={isLoading} onClick={() => setVisible(true)}>
            <div className="svgContainer">
              <RefreshIcon />
            </div>
          </Button>
        </div>
      </Tooltip>

      <AccountingConfirmationModal
        {...{
          darkMode,
          visible,
          setVisible,
          onConfirm: getDataFromDb,
          discardButton: false,
          title: "Restart Grid",
          text: "All unsaved changes will be lost! Are you sure you want to continue?",
          confirmButtonText: "Restart Grid",
        }}
      />
    </>
  )
}

export default RefreshGrid
