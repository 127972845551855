import { useNavigate } from "react-router-dom";
import { queryParams } from "../../../../../utils/queryParams";
import { formatCurrency } from "../../../utils";

export const GraphTooltipContent = ({ tooltipData = [], title, label }) => {
  const navigate = useNavigate();

  const goTo = (path) => {
    const accountingTab =
      label === "Requisitions"
        ? "Applications"
        : label === "Invoices"
        ? "Invoice"
        : label;

    !!path &&
      navigate(
        `projects/${path}?${queryParams({
          tab: "Accounting",
          subTab: accountingTab,
        })}`,
        {
          tabPosition: "Accounting",
          accountingTab,
          fromAccounting: true,
        }
      );
  };

  const colors = {
    "Current Payment": "#2DA365",
    "Total Retainage": "#F6CB51",
    "Total Rental With Tax": "#B5D99C",
    "Total Price": "#2DA365",
    "Total Applied Rent": "#B5D99C",
    "Total Tax": "#f04f4e",
    "Retainage Amount": "#F6CB51",
    "Charge Amount With Tax": "#2DA365",
    "Charge Amount Without Tax": "#B5D99C",
    "Invoice Amount With Tax": "#2DA365",
    "Invoice Amount Without Tax": "#B5D99C",
  };

  return (
    <>
      <div
        className="graphTooltipContent"
        style={{
          display: "flex",
          width: "100%",
          color: "white",
          fontSize: 16,
          fontWeight: 600,
          height: "100%",
          paddingLeft: 5,
        }}
      >
        {!!title ? title : ""}
      </div>
      {tooltipData?.map((el, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 10,
            width: "100%",
          }}
        >
          {Object.entries(el).map(
            ([key, value]) =>
              key !== "undefined" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      color: "white",
                      fontSize: 16,
                      fontWeight: 600,
                      height: "100%",
                      cursor: value?.projectId ? "pointer" : "auto",
                    }}
                    onClick={() => goTo(value?.projectId)}
                  >
                    {key}
                  </div>
                  <ul
                    style={{
                      color: "white",
                      fontSize: 14,
                      height: "100%",
                    }}
                  >
                    {Object.entries(value).map(
                      ([key, value]) =>
                        key !== "projectId" &&
                        !!key && (
                          <li>
                            {key}:{" "}
                            <span
                              style={{
                                color: colors?.[key] || "white",
                              }}
                            >
                              {typeof value === "number"
                                ? formatCurrency(value)
                                : value}
                            </span>
                          </li>
                        )
                    )}
                  </ul>
                </>
              )
          )}
        </div>
      ))}
    </>
  );
};
