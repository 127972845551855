import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message, Form } from "antd";

import { lazyFetch } from "src/utils";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./DriverAssign.scss";

function DriverAssign(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [drivers, setDrivers] = useState([]);
  const [prev, setPrev] = useState();

  const [form] = Form.useForm();

  const { params, setEditMode, getEditedValue } = props;

  useEffect(() => {
    setPrev(_.cloneDeep(props));
  }, [props]);

  useEffect(() => {
    lazyFetch({
      tableName: "drivers",
      listOfKeys: ["driverName", "driverId", "driverSub"],
    })
      .then(setDrivers)
      .catch((err) => {
        message.error({
          content:
            "Something went wrong while trying to get the list of drivers",
          key: "driverFetchErr",
        });
        console.log("Error getting drivers: ", err);
      });
  }, []);

  function onCancel() {
    setEditMode(false);
  }

  function getCardData(value) {
    return {
      id: "driverAssign",
      Card: "DriverAssign",
      title: "Driver Assign",
      params: {
        ...params,
        assignedDriver: value,
      },
    };
  }

  async function saveHandler() {
    await form
      .validateFields()
      .then(({ assignedDriver }) => {
        getEditedValue(
          { assignedDriver: assignedDriver || null },
          { assignedDriver: params?.assignedDriver },
          {},
          getCardData(prev?.params?.assignedDriver),
          getCardData(assignedDriver)
        );

        onCancel();
      })
      .catch(() => {});
  }

  return (
    <Form
      form={form}
      className={`driver-assign-form ${
        isDarkMode ? "driver-assign-form-dark" : ""
      }`}
      initialValues={{
        assignedDriver: params?.assignedDriver,
      }}
    >
      <InputComponent
        {...{
          label: "Please select a driver to assign",
          required: false,
          type: "select",
          formItemName: "assignedDriver",
          showSearch: true,
          dropdownClassName: isDarkMode && "darkDropDown",
          allowClear: true,
          customOptions: drivers.map(({ driverName, driverId }) => ({
            label: driverName,
            value: driverId,
          })),
        }}
      />
      <div className="confirmContainer">
        <MondayButton
          Icon={<XIcon />}
          className="mondayButtonRed"
          onClick={onCancel}
        >
          Cancel
        </MondayButton>
        <MondayButton Icon={<TickIcon />} onClick={saveHandler}>
          Save
        </MondayButton>
      </div>
    </Form>
  );
}

export default DriverAssign;
