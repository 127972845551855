import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";

export function getSelectionFooter({
  onSave = () => {},
  onCancel = () => {},
  onClearSelection,
}) {
  return [
    <MondayButton
      {...{
        Icon: <XIcon />,
        className: "mondayButtonRed",
        ["data-testid"]: "close-entries-selection-modal-btn",
        onClick: onCancel,
      }}
    >
      Cancel
    </MondayButton>,
    <MondayButton
      {...{
        Icon: <XIcon />,
        className: "mondayButtonBlue",
        onClick: onClearSelection,
      }}
    >
      Clear
    </MondayButton>,
    <MondayButton
      {...{
        Icon: <TickIcon width={17} height={17} />,
        onClick: onSave,
      }}
    >
      Select
    </MondayButton>,
  ];
}

export function selectionFields({
  crews,
  jobsites,
  currentStep,
  handleChange,
}) {
  return [
    {
      label: currentStep === 1 ? "Entry Date Range" : "Shift Date Range",
      type: "rangePicker",
      formItemName: "dateRange",
      required: true,
      allowClear: false,
    },
    {
      label: "Jobsite",
      type: "select",
      formItemName: "selectedJobsite",
      required: false,
      allowClear: true,
      showSearch: true,
      placeholder: "Select a jobsite...",
      customOptions: jobsites?.map(({ jobsiteId, jobAddress }) => ({
        value: jobsiteId,
        label: jobAddress,
      })),
    },
    {
      label: "Employees",
      type: "styledmultiselect",
      mode: "multiselect",
      placeholder: "Select employees...",
      optionFilterProp: "label",
      required: false,
      showSearch: true,
      formItemName: "selectedEmployees",
      onChange: handleChange,
      customOptions: crews?.map(({ crewName, employeeId }, key) => ({
        key,
        value: employeeId,
        label: `${crewName} (${employeeId})`,
      })),
    },
  ];
}
