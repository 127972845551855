/**
 * Determines the access level for viewing approvals based on the user's group and route configuration.
 *
 * @param {Object} user - The user object containing group and route configuration information.
 * @returns {Object} - An object representing the access level for reading, writing, and removing approvals.
 */
const approvalsViewAccess = (user) => {
  if (user?.groupName === "Admin" || user?.groupName === "Super Admin") {
    return {
      read: true,
      write: true,
      remove: true,
    };
  }

  const {
    write = false,
    read = false,
    delete: remove = false,
  } = user?.routeConfig?.find((el) => el?.title === "Approvals/View") || {};

  return {
    read: read || false,
    write: write || false,
    remove: remove || false,
  };
};

export default approvalsViewAccess;
