import { validateForm } from "../../../Fleet/utils";

/**
 * Checks if there are changes or an incident ID present, and performs the necessary actions accordingly.
 * If there are changes or an incident ID, it checks if any required input fields are empty and prompts the user to save the draft.
 * If all required input fields have values, it validates the form and calls the saveFunction.
 * If there are no changes or incident ID, it sets the visibility to false.
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.changes - Indicates if there are changes.
 * @param {Array} options.populatedInputFields - The populated input fields.
 * @param {Object} options.form - The form object.
 * @param {Function} options.saveFunction - The save function.
 * @param {Function} options.setVisible - The function to set the visibility.
 * @param {Function} options.setCancelModalVisible - The function to set the cancel modal visibility.
 * @param {string} options.incidentId - The incident ID.
 */
function checkSaveDraft({
  changes = false,
  populatedInputFields,
  form,
  saveFunction,
  setVisible,
  setCancelModalVisible,
  incidentId,
}) {
  if (!!changes || !!incidentId) {
    let fields = populatedInputFields
      ?.filter((el) => el?.required)
      ?.map((el) => el?.formItemName);

    let fieldValues = form.getFieldsValue(fields);
    if (Object.values(fieldValues)?.some((value) => !value)) {
      setCancelModalVisible("saveDraft");
    } else {
      validateForm(form, saveFunction);
    }
  } else {
    setVisible(false);
  }
}

export default checkSaveDraft;
