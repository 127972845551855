import { axesTooltipCustom } from "../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "toDoStatus",
    groupKey: "todoStatus",
    xAxisName: "Status",
  },
  {
    chartName: "toDoCategory",
    groupKey: "todoCategory",
    xAxisName: "Category",
  },
  {
    chartName: "createdBy",
    groupKey: "todoUser",
    xAxisName: "To Do",
  },
];

export const getChartingOptions = (data) => {
  let toDoStatus = new Set();
  let toDoCategory = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const status = item?.todoStatus || "No Data";
    const category = item?.todoCategory || "No Data";
    const user = item?.todoUser || "No Data";

    toDoStatus.add(status);
    toDoCategory.add(category);
    createdBy.add(user);
  });

  const countOnStatus = {
    title: {
      text: "Total To Do count",
    },
    subtitle: {
      text: "Based on Status",
    },
    series:
      Array.from(toDoStatus).map((status) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: status,
          yName: status,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "To Do"),
          },
        };
      }) || [],
  };

  const countOnCategory = {
    title: {
      text: "Total To Do count",
    },
    subtitle: {
      text: "Based on Category",
    },
    series:
      Array.from(toDoCategory).map((cat) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: cat,
          yName: cat,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "To Do"),
          },
        };
      }) || [],
  };

  const countOnCreatedBy = {
    title: {
      text: "Total To Do count",
    },
    subtitle: {
      text: "Based on Created By",
    },
    series:
      Array.from(createdBy).map((user) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: user,
          yName: user,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "To Do"),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle: "Show total To Do count based on Opportunity Status",
      params: countOnStatus,
      key: "toDoStatus",
    },
    {
      optionTitle: "Show total To Do count based on Opportunity Stage",
      params: countOnCategory,
      key: "toDoCategory",
    },
    {
      optionTitle: "Show total To Do count based on Created By",
      params: countOnCreatedBy,
      key: "createdBy",
    },
  ];

  return chartingOptions;
};
