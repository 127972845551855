import React from "react";

const ChargeTitle = ({ type, currentCharge }) => {
  return (
    <div className="textTitle">
      {type === "Charge"
        ? currentCharge
          ? `Edit charge no. ${currentCharge.chargeNumber}`
          : "Add new charge item"
        : currentCharge
        ? `Edit credit item no. ${currentCharge.chargeNumber}`
        : "Add new credit memo item"}
    </div>
  );
};

export default ChargeTitle;
