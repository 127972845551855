import moment from "moment";
import { formatCurrency } from "../../../../../../utils";
import { removeHTMLTags } from "../../../../../../../../utils/removeHTMLTags";

export const documentDefinition = (title, companyLogo, invoiceData) => {
  const formatDate = (date) => moment(date).format("MM/DD/YYYY");

  const totalCreditedAmount = invoiceData.invoiceItems.reduce(
    (acc, invoice) => acc + invoice.creditedAmount,
    0
  );
  const totalScopeAmount = invoiceData.invoiceItems.reduce(
    (acc, invoice) => acc + invoice.scopeAmount,
    0
  );
  const totalInvoiceAmount = invoiceData.invoiceItems.reduce(
    (acc, invoice) => acc + invoice.amount,
    0
  );
  const totalTaxAmount = invoiceData.invoiceItems.reduce(
    (acc, invoice) => acc + invoice.taxAmount,
    0
  );
  const total = invoiceData.invoiceItems.reduce(
    (acc, invoice) => acc + invoice.total,
    0
  );

  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} |  CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: "logo", // Use the "logo" image defined in images
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                alignment: "right",
                margin: [10, 10, 0, 10],
              },
              {
                text: `Date: ${formatDate(invoiceData.invoiceDate)}`,
                alignment: "right",
                style: "bool", // Style for the date
              },
            ],
            width: "*",
            alignment: "center",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
            <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        text: "Invoice Information:",
        style: ["strong"],
      },
      {
        // Create a columns section to display the invoice details
        columns: [
          // Left side content
          {
            stack: [
              {
                text: `Customer: ${invoiceData.accountName}`,
                alignment: "left",
                margin: [0, 0, 0, 10],
              },
              {
                text: `Project: ${invoiceData.projectName}`,
                alignment: "left",
                margin: [0, 0, 0, 10],
              },
            ],
            style: "listItem",
          },
          // Right side content
          {
            stack: [
              {
                text: `Invoice Amount: ${formatCurrency(
                  invoiceData.totalInvoiceAmount
                )}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
              {
                text: `Amount Due: ${formatCurrency(invoiceData.amountDue)}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
              {
                text: `Invoice Status: ${invoiceData.invoiceStatus}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
            ],
            style: "listItem",
          },
        ],
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
            <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        table: {
          headerRows: 1,
          widths: [
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
          ],
          body: [
            [
              { text: "Service", bold: true },
              { text: "Description", bold: true },
              { text: "Credited Amount", bold: true },
              { text: "Scope Amount", bold: true },
              { text: "Amount Percentage", bold: true },
              { text: "Amount", bold: true },
              { text: "Tax Amount", bold: true },
              { text: "Total", bold: true },
            ],
            ...invoiceData.invoiceItems.map((invoice) => [
              invoice.name,
              removeHTMLTags(invoice.description),
              formatCurrency(invoice.creditedAmount),
              formatCurrency(invoice.scopeAmount),
              invoice.amountPercentage + "%",
              formatCurrency(invoice.amount),
              formatCurrency(invoice.taxAmount),
              formatCurrency(invoice.total),
            ]),
            [
              { text: "Totals:", bold: true },
              "",
              formatCurrency(totalCreditedAmount),
              formatCurrency(totalScopeAmount),
              "",
              formatCurrency(totalInvoiceAmount),
              formatCurrency(totalTaxAmount),
              formatCurrency(total),
            ],
          ],
        },
        style: "outerTable",
      },
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { fontSize: 12 },
      header: { margin: [0, 0, 0, 20] },
      listItem: { margin: [5, 5] },
      bool: { margin: [0, 5], fontSize: 13 },
      outerTable: { margin: [0, 0, 0, 30] },
    },
  };
};
