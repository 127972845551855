import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
import { boroughs } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const leads = (IdHandler, programFields, updatedDynamicStates) => {
  const data = updatedDynamicStates?.[IdHandler] || [];
  const leadStatus = programFields?.find(
    (a) => a?.fieldName === "Lead Status Types"
  )?.fieldOptions;
  return {
    leadStatus: () => ({
      title: "Leads Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Leads",
        field: "leadStatus",
        labels: leadStatus,
        datasets: [
          {
            label: "My First Dataset",
            data: leadStatus?.map(
              (status) => data?.filter((a) => a?.leadStatus === status)?.length
            ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Leads?.map((a) => a.statusColor),
            borderWidth: 1,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
    leadAddress: () => ({
      title: "Leads Address",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Leads",
        field: "leadAddress",
        labels: boroughs,
        datasets: [
          {
            label: "My First Dataset",
            data: boroughs?.map(
              (status) =>
                data?.filter(
                  (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "NY Boroughs")
              ?.fieldOptions?.map((a) => a.boroughColor),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),

    projectExecutive: () => ({
      title: "Leads Project Executive",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Leads",
        field: "projectExecutive",
        labels: uniq(data?.map((a) => a?.projectExecutive))?.filter(Boolean),
        datasets: [
          {
            label: "My First Dataset",
            data: Object.values(repeatedElementSum(data, "projectExecutive")),
            backgroundColor: uniq(data?.map((a) => getRandomColor())),
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
  };
}; //Leads
