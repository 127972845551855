import {
  getLabelHTML,
  getYards,
} from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/routeFields";
import { getNewLabelHTML } from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/routeFunctions";

function getJobsiteFieldOptions(label) {
  return {
    label: getLabelHTML(label, "Jobsite Address"),
    required: false,
    className: "customJobsiteField",
    rules: [
      {
        required: true,
        message: "*Jobsite Address is required!",
      },
    ],
  };
}

/**
 * Handles the change event for the location field and dispatches the appropriate actions based on the selected option.
 *
 * @param {string} val - The value of the selected option.
 * @param {Object} option - The selected option object.
 * @param {Object} field - The field object.
 * @param {Function} getFieldValue - The function to get the value of a field.
 * @param {Function} setFieldsValue - The function to set the value of fields.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 */
const onDispatchLocationChange = ({
  val = "",
  option,
  field,
  getFieldValue = () => {},
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  let type = getNewLabelHTML({ option })?.type;

  if (type === "schedule") {
    setFieldsValue({
      jobsiteAddress: val?.trim(),
      recordName: val?.trim(),
      recordId: option?.id,
    });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        scheduleId: option?.id,
        recordId: option?.id,
        recordName: option?.label,
      },
    });

    Object.assign(field, { ...getJobsiteFieldOptions("schedule") });
  } else if (type === "project") {
    setFieldsValue({
      jobsiteAddress: val?.trim(),
      recordName: val?.trim(),
      recordId: option?.id,
    });

    Object.assign(field, {
      ...getJobsiteFieldOptions("Project (off schedule)"),
    });
    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        projectId: option?.id,
        recordId: option?.id,
        recordName: option?.label,
      },
    });
  } else if (getYards()?.includes(getFieldValue("jobsiteAddress"))) {
    Object.assign(field, {
      ...getJobsiteFieldOptions("yard"),
    });
  } else {
    Object.assign(field, {
      ...getJobsiteFieldOptions("Not a project (off schedule)"),
    });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {},
    });
  }
};

export default onDispatchLocationChange;
