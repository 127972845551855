import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SidebarData } from "../Sidebars/SidebarData";
import MobileHomeMenu from "./MobileHomeMenu";

import "./SidebarMobile.scss";
import { companyDetails } from "../../helpers/constants";

function SidebarMobile() {
  const location = useLocation();
  let path = location?.pathname;
  const [visible, setVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sidebarElements, setSidebarElements] = useState([]);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { companyName } = companyDetails;

  const getSideBarData = () => {
    let sidebarData = userConfiguration;
    for (let el of sidebarData.sidebarConfig) {
      for (let element of SidebarData) {
        if (el.title === element.title) {
          el.src = element.src;
        }
      }
    }
    setSidebarElements(sidebarData.sidebarConfig);
    setIsLoaded(true);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useMemo(() => {
    if (path) {
      onClose();
    }
  }, [path]);

  useEffect(() => {
    getSideBarData();
  }, [userConfiguration]);

  return (
    <div className="mobile-menu-container">
      <MenuOutlined
        style={{ fontSize: 25, color: isDarkMode ? "#fff" : "" }}
        onClick={showDrawer}
      />

      <Drawer
        width={280}
        rootClassName="menu-drower"
        title={companyName}
        placement="left"
        destroyOnClose={true}
        closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
        onClose={onClose}
        open={visible}
        key="left"
      >
        <div>
          {sidebarElements && (
            <MobileHomeMenu pages={sidebarElements} isLoaded={isLoaded} />
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default SidebarMobile;
