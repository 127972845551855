/**
 *
 * @param {String} requestType - The type of request being made
 * @param {Object} selectedMainField  - The selected main field object
 * @returns {String} - The category of the request which is used to redirect to the record
 * this request is being based on
 */
const requestCategory = (requestType, selectedMainField) => {
  switch (requestType) {
    case "Dispatch":
      return "dispatch";
    case "Safety Inspection":
      return "safety";
    case "Project":
      return "projects";
    case "Opportunity":
      return "clients";
    case "Claim":
      return "safety";
    case "Incident": {
      if (selectedMainField?.hasOwnProperty("scheduleId")) {
        return "scheduling";
      }
      return "projects";
    }
    case "Schedule Change":
      return "scheduling";
    case "Estimation": {
      if (selectedMainField?.hasOwnProperty("opportunityId")) {
        return "opportunities";
      }
      return "projects";
    }
    case "Documentation": {
      return selectedMainField?.apiData?.table || null;
    }
    case "Scheduling":
      return "projects";
    case "Permit Drawing":
      return "projects";
    case "Service Inspection":
      return "projects";
    case "Work Order":
      return "fleets";
    case "Fleet Inventory":
      return "fleets";
    case "Change Order":
      return "projects";
  }
};

export default requestCategory;
