import React from "react";
import { Tick } from "../../pages/Settings/settingsComponents/Roles/src/index";
import { XIcon } from "../../SidebarPages/Communication/assets/index";
import { MondayButton } from "../index";
import "./CustomModal.scss";
import { useSelector } from "react-redux";

type Props = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
  customHeader?: React.ReactNode;
  customFooter?: React.ReactNode | boolean;
  buttonCancelFunction?: () => void;
  buttonSaveFunction?: () => void;
  onClose?: () => void;
};

type State = {
  darkMode: { isDarkMode: boolean };
};

const CustomModal = (props: Props): JSX.Element => {
  const {
    children,
    open,
    setOpen,
    title = "",
    customHeader = undefined,
    customFooter = undefined,
    onClose = () => {},
    buttonCancelFunction = () => {},
    buttonSaveFunction = () => {},
  } = props;

  const { isDarkMode } = useSelector((state: State) => state.darkMode);

  return (
    <>
      {open ? (
        <>
          <div
            className="custom-modal-mask"
            onClick={() => {
              onClose();
              setOpen(false);
            }}
          />
          <div className={`custom-modal ${isDarkMode && "custom-modal-dark"}`}>
            <main className="custom-modal-content">
              {customHeader || (
                <header className="custom-modal-header">
                  <label className="custom-modal-header-title">
                    {title || "Custom Modal"}
                  </label>
                  <button
                    className="custom-modal-header-close"
                    onClick={() => {
                      onClose();
                      setOpen(false);
                    }}
                  >
                    <XIcon />
                  </button>
                </header>
              )}
              <section className="custom-modal-body">{children}</section>
              {customFooter === false
                ? null
                : customFooter || (
                    <footer className="custom-modal-footer">
                      {/** @ts-ignore */}
                      <MondayButton
                        {...{
                          containerClassName: "",
                          style: {},
                          key: "cancel",
                          onClick: buttonCancelFunction,
                          disabled: false,
                          Icon: <XIcon />,
                          className: "mondayButtonRed",
                        }}
                      >
                        Cancel
                      </MondayButton>
                      {/** @ts-ignore */}
                      <MondayButton
                        {...{
                          containerClassName: "",
                          style: {},
                          key: "save",
                          onClick: buttonSaveFunction,
                          disabled: false,
                          Icon: <Tick />,
                          className: "",
                        }}
                      >
                        Save
                      </MondayButton>
                    </footer>
                  )}
            </main>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CustomModal;
