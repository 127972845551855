import { Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { MondayButton, WithTooltip } from "../../../../../../commonComponents";
import { XIcon } from "../../../../../Communication/assets";

export const TaskModalFooter = ({
  saveTask,
  handleCloseModal,
  disableButton,
}) => {
  return (
    <>
      <MondayButton
        className="mondayButtonRed"
        Icon={<XIcon />}
        onClick={() => handleCloseModal({ cancel: true })}
        tooltipCategory="Tasks"
        tooltipKey="close"
      >
        Close
      </MondayButton>
      <WithTooltip tooltipCategory="Tasks" tooltipKey="create">
        <Dropdown
          disabled={disableButton}
          menu={{
            items: [
              {
                key: "1",
                label: "Finish",
                onClick: () => {
                  saveTask({ newTask: false });
                },
              },
              {
                key: "2",
                label: "Save & New",
                onClick: () => {
                  saveTask({ newTask: true });
                },
              },
            ],
          }}
        >
          <Button
            type="primary"
            className="create_task_button"
            icon={<DownOutlined />}
          >
            Create
          </Button>
        </Dropdown>
      </WithTooltip>
    </>
  );
};
