import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DimensionPricer } from "./components";
import { v4 as uuid } from "uuid";
import "./PriceTable.scss";
import { dimensionsSorter, isDimensionPricerDuplicate } from "./utils";
import { MondayButton } from "../../../../../../commonComponents";
import { Plus } from "../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import { uniq } from "lodash";
import { updateArrayElement } from "../../../../../../../utils";
import { usePricingContext } from "../../../PricingV2/context";
import { Modal, Tooltip } from "antd";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../Roles/src";
import { EditIcon } from "../../../../../../../icons";

const PriceTable = ({ prices, disabled, onChange, showHeatMap }, ref) => {
  const { selectedScheme, setSelectedScheme, setPricingObject } =
    usePricingContext();

  const [editPricer, setEditPricer] = useState(false);
  const [pricesData, setPricesData] = useState(
    selectedScheme?.prices || prices || []
  );

  useEffect(() => {
    setPricesData(selectedScheme?.prices || prices || []);
  }, [selectedScheme]);

  //Checks if the dimensions are filled correctly
  const hasErrors = () => {
    const uniqDimensions = uniq(pricesData.map(({ length }) => length));

    return (
      pricesData.some(({ length, price }) => !length || !price) || //checks if every dimension has values
      uniqDimensions.length !== pricesData.length // checks if there is any duplicates
    );
  };

  useImperativeHandle(ref, () => ({ getValue: () => pricesData, hasErrors }));

  //Deletes a dimension
  const onDimensionDelete = (idToDelete) => () => {
    let newPricesData;

    setPricesData(
      (prev) => (
        (newPricesData = prev.filter(({ id }) => id !== idToDelete)),
        newPricesData
      ),
      () => onChange?.(newPricesData)
    );
  };

  //handles dimension value change
  const onDimensionChange = (id) => (value) => {
    const newPrices = updateArrayElement(
      pricesData,
      { id, ...value },
      "id"
    ).sort(dimensionsSorter);
    onChange?.(newPrices);
    setPricesData(newPrices);
  };

  //adds an empty dimension
  const addDimension = () => setPricesData((prev) => [...prev, { id: uuid() }]);

  const handleAddDimentionPrice = () => {
    setPricingObject((prev) => ({
      ...prev,
      priceSchemes: prev.priceSchemes.map((scheme) =>
        scheme.id !== selectedScheme.id
          ? scheme
          : { ...scheme, prices: pricesData }
      ),
    }));
    setSelectedScheme((prev) => ({
      ...prev,
      prices: pricesData,
    }));
    setEditPricer(false);
  };

  useEffect(() => {
    if (!prices) return;
    setPricesData(prices);
  }, [prices]);

  //calculate min and max
  let min = pricesData[0]?.price || 0,
    max = 0;
  if (Array.isArray(pricesData))
    for (const p of pricesData) {
      const v = parseFloat(p.price);
      if (v < min) min = v;
      if (v > max) max = v;
    }

  return (
    <div className="singleDimensionPriceTable">
      {!selectedScheme ? (
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          No Schema selected!
        </p>
      ) : (
        <>
          <Tooltip title="Edit Table">
            <EditIcon
              className="editIcon"
              onClick={() => setEditPricer(true)}
            />
          </Tooltip>
          <div className="priceTable">
            <div className="table-labels">
              <div>Unit</div>
              <div>Price</div>
            </div>
            {pricesData.map(({ id, length, price }, idx, array) => (
              <DimensionPricer
                {...{
                  showHeatMap,
                  min,
                  max,
                  key: id,
                  disabled,
                  forEditing: false,
                  value: { length, price },
                  onDelete: onDimensionDelete(id),
                  onChange: onDimensionChange(id),
                  isDuplicate: isDimensionPricerDuplicate(id, length, array),
                  isLastChild: idx === array.length - 1,
                }}
              />
            ))}
          </div>
          {editPricer && (
            <Modal
              className="dimension-modal"
              title="Dimension Pricer"
              open={editPricer}
              onCancel={() => setEditPricer(false)}
              footer={[
                <MondayButton
                  className="mondayButtonRed"
                  Icon={<XIcon />}
                  onClick={() => {
                    setEditPricer(false);
                    setPricesData(selectedScheme?.prices || prices || []);
                  }}
                >
                  Cancel
                </MondayButton>,
                <MondayButton
                  className="mondayButtonGreen"
                  Icon={<TickIcon />}
                  onClick={() => handleAddDimentionPrice()}
                >
                  Save
                </MondayButton>,
              ]}
            >
              <>
                <div className="priceTable">
                  <div className="table-labels">
                    <div>Unit</div>
                    <div>Price</div>
                  </div>
                  {pricesData.map(({ id, length, price }, idx, array) => (
                    <DimensionPricer
                      {...{
                        showHeatMap,
                        min,
                        max,
                        key: id,
                        disabled,
                        value: { length, price },
                        onDelete: onDimensionDelete(id),
                        onChange: onDimensionChange(id),
                        isDuplicate: isDimensionPricerDuplicate(
                          id,
                          length,
                          array
                        ),
                        isLastChild: idx === array.length - 1,
                      }}
                    />
                  ))}
                </div>
                {!disabled && (
                  <MondayButton Icon={<Plus />} onClick={addDimension}>
                    Add Price
                  </MondayButton>
                )}
              </>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default memo(forwardRef(PriceTable));
