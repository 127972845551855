export function getGridProjectInfo({ projects, schedules, data }) {
  let selectedProject = null,
    selectedSchedule = null;

  try {
    if (!!data?.projectId) {
      selectedProject = projects?.find((e) => e.projectId === data.projectId);
      if (!selectedProject) {
        throw new Error();
      }
      return {
        ...data,
        projectName: selectedProject?.projectName,
        accountName: selectedProject?.accountName,
        scheduleAddress: "N/A",
      };
    } else if (!!data?.scheduleId) {
      selectedSchedule = schedules?.find(
        (e) => e?.scheduleId === data?.scheduleId
      );
      selectedProject = projects?.find(
        (e) => e?.projectId === selectedSchedule?.projectId
      );

      if (!selectedProject || !selectedSchedule) {
        throw new Error();
      }

      return {
        ...data,
        projectName: selectedProject?.projectName,
        accountName: selectedProject?.accountName,
        scheduleAddress: selectedSchedule?.scheduleAddress,
      };
    } else {
      throw new Error();
    }
  } catch {
    return {
      ...data,
      projectName: "N/A",
      accountName: "N/A",
      scheduleAddress: "N/A",
    };
  }
}
