import { formatCurrency } from "../../../../utils";
import { OtherPricingLabel } from "./components";
import PricingRowInfo from "./components/PricingRowInfo/PricingRowInfo";

const HoistPricingInfoModalContent = ({ elevation }) => {
  const {
    pricing,
    addonsTotal = 0,
    totalPrice = 0,
    taxAmount = 0,
    taxRate = 0,
    rentalsTotal = 0,
    price = 0,
  } = elevation || {};
  let { overview = [], addonsOverview = [] } = pricing || {};

  addonsOverview = [
    ...addonsOverview.map(({ name, price }) => ({
      label: name,
      value: formatCurrency(price),
    })),
    {
      label: "PLI Addons",
      value: `Total: ${formatCurrency(addonsTotal)}`,
    },
  ];

  const totalsOverview = [
    {
      label: "Rentals Total",
      value: formatCurrency(rentalsTotal || 0),
    },
    {
      label: "Subtotal",
      value: formatCurrency(price || 0),
    },
    {
      label: "Tax Amount",
      value: formatCurrency(taxAmount || 0),
    },
    {
      label: "Tax Rate",
      value: `${taxRate * 100}%`,
    },
    {
      label: "Total Including Rentals",
      value: `Total: ${formatCurrency(totalPrice || 0)}`,
    },
  ];

  return (
    <div className="hoist-pricing-info-modal-content">
      <div className="pricing-items-section">
        {overview.map((pricingRow, index) => (
          <PricingRowInfo values={pricingRow} key={index} />
        ))}
      </div>

      <div className="addons-section">
        {addonsOverview.map((values, index) => (
          <OtherPricingLabel
            {...values}
            key={index}
            lastChild={index === addonsOverview.length - 1}
          />
        ))}
      </div>

      <div className="totals-section">
        {totalsOverview.map((values, index) => (
          <OtherPricingLabel
            {...values}
            key={index}
            lastChild={index === totalsOverview.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default HoistPricingInfoModalContent;
