import { parseInTz } from "../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import {
  DobStep,
  // EstimationsStep,
  OverviewStep,
  // ServicesStep,
  SubcontractorsStep,
  GeofenceStep,
} from "./components";

export const footerButtons = ({
  onCancel,
  onSaveAndContinue,
  onOptionClick,
  onNext,
  suffix,
  saving = false,
  nextDisabled = false,
}) => [
  {
    text: "cancel",
    cancel: true,
    onClick: onCancel,
  },
  {
    text: "next",
    onClick: onNext,
    disabled: nextDisabled,
  },
  {
    text: "Finish",
    primary: true,
    dropdownDisabled: !!saving,
    disabled: !!saving,
    onClick: onSaveAndContinue,
    options: ["Save & Close", "Save & New"],
    onOptionClick: onOptionClick,
    suffix: suffix,
  },
];

export const steps = [
  {
    Comp: OverviewStep,
    title: "Overview",
  },
  {
    Comp: DobStep,
    title: "DOB",
  },
  // {
  //   Comp: EstimationsStep,
  //   title: "Estimations",
  // },
  // {
  //   Comp: ServicesStep,
  //   title: "Services",
  // },
  { Comp: SubcontractorsStep, title: "Subcontractors" },
  { Comp: GeofenceStep, title: "Geofence" },
];

export const body = (form) => ({
  binNumber: form.getFieldValue("binNr"),
  dobName: form.getFieldValue("dobAddress"),
  projectName: form.getFieldValue("projectName"),
  projectAddress: form.getFieldValue("projectAddress"),
  accountName: form.getFieldValue("accountName"),
  primaryContact: form.getFieldValue("primaryContact"),
  projectExecutive: form.getFieldValue("projectExecutive"),
  taxExempt: form.getFieldValue("taxExempt"),
  taxRate: parseFloat(form.getFieldValue("taxRate")) / 100,
  salesPerson: form.getFieldValue("salesPerson"),
  attorney: form.getFieldValue("attorney"),
  expeditor: form.getFieldValue("expeditor"),
  electrician: form.getFieldValue("electrician"),
  safetyCompany: form.getFieldValue("safetyCompany"),
  boomCompany: form.getFieldValue("boomCompany"),
  insuranceBroker: form.getFieldValue("insuranceBroker"),
  laborRequirement: form.getFieldValue("laborRequirement"),
  insuranceRequirement: form.getFieldValue("insuranceRequirement"),
  projectStatus: "Awarded",
  inspectionStatus: "Pending",
  dobAddress: form.getFieldValue("dobAddress"),
  dobTextArea: form.getFieldValue("dobTextArea"),
  dobBlock: form.getFieldValue("dobBlock"),
  dobLot: form.getFieldValue("dobLot"),
  projectAddCity: form.getFieldValue("projectAddCity"),
  projectAddState: form.getFieldValue("projectAddState"),
  projectAddStreet: form.getFieldValue("projectAddStreet"),
  projectAddPostalCode: form.getFieldValue("projectAddPostalCode"),
  proposalStartDate: !!form.getFieldValue("proposalStartDate")
    ? parseInTz(form.getFieldValue("proposalStartDate"), "MM/DD/YYYY").valueOf()
    : null,
  // teamsConfiguration: form.getFieldValue("teamsConfiguration"),
});

export const stepsMapHelper = (title) => {
  const stepMap = {
    "Required Fields": document.querySelector(".mainGrid"),
    "Project Address": document.querySelector(".mainAddress .fullInput"),
    "Alternative Addresses": document.querySelector(".addAddressIcon"),
    "Go to the next step": document.querySelector(".nextButton"),
    "DOB Information": document.querySelector(".dobInfoButton"),
    Verify: document.querySelector(".verifyButton"),
    "DOB Violations": document.querySelector(".violationsPanel"),
    "Active Violations": document.querySelector(".violationCheckmarkContainer"),
    Subcontractors: document.querySelector(
      ".subcontractorsStepContainer .bodyContainer"
    ),
    Geofence: document.querySelector(".app-map-geofence"),
    "Create Project": document.querySelector(".finishButton"),
  };
  return stepMap[title] || null;
};
