import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { MondayButton } from "../../../../../../../../../../../commonComponents/index";
import { ExpandMoreIcon } from "../../../../../../../../../../../../icons/index";
import RentalsMoreContent from "./content/RentalsMoreContent";
import { useRedux } from "../../../../../../../../../../hooks/useRedux";
import "./RentalsMoreOptions.scss";
import { ControlOutlined } from "@ant-design/icons";

function RentalsMoreOptions() {
  const [open, setOpen] = useState(false);
  const [darkMode] = useRedux("rentalsDarkMode");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {" "}
      <MondayButton
        className="mondayButtonBlue moreActionsBtn"
        onClick={showDrawer}
        hasIcon={false}
        // Icon={
        //   <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     viewBox="0 0 24 24"
        //     width="24"
        //     height="24"
        //   >
        //     <path d="M6.71 6.71l-.71.71c-.39.39-.39 1.02 0 1.41l5 5c.39.39 1.02.39 1.41 0l5-5c.39-.39.39-1.02 0-1.41l-.71-.71c-.39-.39-1.02-.39-1.41 0L12 10.59l-4.29-4.29c-.39-.39-1.02-.39-1.41 0zm0 6.58l-.71.71c-.39.39-.39 1.02 0 1.41l5 5c.39.39 1.02.39 1.41 0l5-5c.39-.39.39-1.02 0-1.41l-.71-.71c-.39-.39-1.02-.39-1.41 0L12 17.18l-4.29-4.29c-.39-.39-1.02-.39-1.41 0z" />
        //   </svg>
        // }
      >
        <ExpandMoreIcon />
      </MondayButton>
      {/* <Button type="primary" onClick={showDrawer}>
        Advanced Options
      </Button> */}
      {open && (
        <Drawer
          title="Rentals Actions"
          className={`rentals-more-options-drawer__${
            darkMode ? "dark" : "light"
          }`}
          placement="top"
          onClose={onClose}
          height={130}
          open={open}
        >
          <RentalsMoreContent />
        </Drawer>
      )}
    </div>
  );
}

export default RentalsMoreOptions;
