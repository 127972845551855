import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../commonComponents";
import { analyticColumns } from "./removedAnalyticsData";
import { InfoIcon } from "../../../../../../../assets";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";

import "./RemovedAnalyticsModal.scss";

function RemovedAnalyticsModal({ open, onCancel, removedAnalytics }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [gridApi, setGridApi] = useState(false);

  function onGridReady({ api }) {
    setGridApi(api);
  }

  function onFilterTextChange(e) {
    if (!!gridApi) {
      gridApi.setQuickFilter(e);
    }
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        closeIcon: <XIcon />,
        closable: true,
        title: `Removed Analytics`,
        className: `removedAnalyticsModal ${
          isDarkMode && "removedAnalyticsModalDark"
        }`,
        centered: true,
        destroyOnClose: true,
        ["data-testid"]: "removed-analytics-modal",
        footer: [
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={onCancel}
          >
            Close
          </MondayButton>,
        ],
      }}
    >
      <section className="infoSection">
        <InfoIcon />
        <span>Table below shows all the removed analytics.</span>
      </section>
      <section className="searchSection">
        <InputComponent
          type="input"
          onChange={(e) => onFilterTextChange(e.target.value)}
          placeholder="Search analytics..."
        />
      </section>
      <section
        className={`documentationTable tableWrapper ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            gridApi,
            onGridReady,
            rowData: removedAnalytics,
            columnDefs: analyticColumns,
            pagination: true,
            paginationPageSize: 9,
            animateRows: true,
            defaultColDef: {
              resizable: true,
              enablePivot: true,
              enableColResize: true,
              enableRowGroup: true,
              editable: false,
              sortable: true,
              flex: 1,
              filter: true,
            },
            sideBar: {
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
            },
          }}
        />
      </section>
    </Modal>
  );
}

export default RemovedAnalyticsModal;
