//to handle getting an estimation in both companies--CORE/CENTRAL
import { API } from "aws-amplify";
import { CheckIfValidId } from "./validators";

export function getEstimation(estimationId) {
  if (!CheckIfValidId(estimationId)) {
    // eslint-disable-next-line
    throw "estimation could not be found! projectId: " + estimationId;
  }
  return API.get("estimations", `/estimations/${estimationId}`, {});
}

//to handle saving estimations in both companies--CORE/CENTRAL
export function saveEstimation({ estimationId, services, estSTATUS }) {
  if (!CheckIfValidId(estimationId)) {
    // eslint-disable-next-line
    throw "estimation could not be found! projectId: " + estimationId;
  }
  if (estSTATUS) {
    return API.patch("estimations", `/estimations/${estimationId}`, {
      body: { services, estSTATUS },
    });
  } else {
    return API.patch("estimations", `/estimations/${estimationId}`, {
      body: { services },
    });
  }
}

// export async function getProject(estimation) {
//   let project;
//   if (CheckIfValidId(estimation.projectId)) {
//     //if we have a projectId
//     project = await API.get(
//       "projects",
//       `/projects/${estimation.projectId}`,
//       {}
//     );
//     if (project !== null && project !== undefined) return { project: project };
//   } else if (CheckIfValidId(estimation.opportunityId)) {
//     //if we have an opportunityId
//     project = await API.get(
//       "opportunities",
//       `/opportunities/${estimation.opportunityId}`,
//       {}
//     );
//     if (project !== null && project !== undefined)
//       return { opportunity: project };
//   }
//   return null;
// }

export async function getProject(estimation) {
  let project = null;

  await Promise.allSettled([
    !!estimation?.projectId
      ? API.get("projects", `/projects/${estimation?.projectId}`)
      : undefined,
    !!estimation?.opportunityId
      ? API.get("projects", `/projects/${estimation?.projectId}`)
      : undefined,
  ]).then(([{ value: pr }, { value: op }]) => {
    if (!!pr && !!op) {
      project = { project: { ...pr, taxExempt: op?.taxExempt } };
    } else if (!!pr) {
      project = { project: { ...pr } };
    } else if (!!op) {
      project = { opportunity: { ...op } };
    }
  });

  return project;
}

export function saveProject(project, services, isFromModal) {
  if (!CheckIfValidId(project.projectId)) {
    // eslint-disable-next-line
    throw "Project could not be found! projectId: " + project.projectId;
  }

  return API.patch("projects", `/projects/${project.projectId}`, {
    body: { services },
  });
}
