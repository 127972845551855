import { driveApi } from "../../../../../integrations/DriveRequest";

/**
 * Deletes the specified files from Google Drive.
 *
 * @param {string} accessToken - The access token.
 * @param {Array} uploadedFiles - The array of uploaded files to be deleted.
 * @param {Array} gDriveUploads - The array of Google Drive uploads to be deleted.
 * @param {Array} filesToBeDeleted - The array of file IDs to be deleted.
 */
const deleteRequestFilesHandler = async ({
  accessToken = "",
  uploadedFiles = [],
  gDriveUploads = [],
  filesToBeDeleted = [],
}) => {
  if (!accessToken) return console.error("No access token provided");

  const { deleteDriveItemPromise } = driveApi({ accessToken });

  const toDelete = [];

  if (uploadedFiles?.length) {
    uploadedFiles?.forEach((el) =>
      toDelete.push(deleteDriveItemPromise(el?.id))
    );
  }

  if (filesToBeDeleted?.length) {
    filesToBeDeleted?.forEach((el) =>
      toDelete.push(deleteDriveItemPromise(el))
    );
  }

  if (gDriveUploads?.length) {
    gDriveUploads?.forEach((el) =>
      toDelete.push(deleteDriveItemPromise(el?.id))
    );
  }

  try {
    toDelete?.length && (await Promise.allSettled(toDelete));
  } catch (err) {
    console.error("Error deleting drive items", err);
  }
};

export default deleteRequestFilesHandler;
