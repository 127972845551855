import { columnDefs } from "../utils/tableColumns";
import { AgGridReact } from "ag-grid-react";

function WorkOrderTable({
  dataSource,
  setSelectedItems,
  selectedItems,
  isDarkMode,
}) {
  function addItem(defectId) {
    setSelectedItems([...selectedItems, defectId]);
  }

  function removeItem(defectId) {
    setSelectedItems(selectedItems?.filter((el) => el !== defectId));
  }

  return (
    <div
      className={`ag-grid-container ${
        isDarkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
      style={{ width: "100%" }}
    >
      <AgGridReact
        {...{
          rowData: dataSource,
          columnDefs: columnDefs({
            selectedItems,
            addItem,
            removeItem,
            isDarkMode,
          }),
          suppressDragLeaveHidesColumns: true,
          defaultColDef: {
            resizable: true,
            enableColResize: true,
            enableRowGroup: false,
            sortable: false,
            filter: false,
            flex: 1,
            suppressSizeToFit: true,
            minWidth: 130,
          },
        }}
      />
    </div>
  );
}

export default WorkOrderTable;
