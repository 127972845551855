import { getLatLngByAddress } from "../../utils";

//update geocode address in cases when it is returned wrong
const updateAddress = (geocode, address) => {
  const updatedGeocode = { ...geocode };
  const newGeoAddress = geocode.formatted_address
    .split(",")
    .slice(1)
    .join(",")
    .trim();
  const newAddress = address.split(",")[0];
  updatedGeocode.formatted_address = `${newAddress}, ${newGeoAddress}`;
  const shortAddress =
    newAddress.split(" ").length > 2
      ? newAddress.split(" ").slice(1).join(" ").trim()
      : newAddress;
  const index = updatedGeocode.address_components.findIndex((item) =>
    item.types.includes("route")
  );
  updatedGeocode.address_components[index] = {
    ...updatedGeocode.address_components[index],
    long_name: shortAddress,
    short_name: shortAddress,
  };
  return updatedGeocode;
};

//return the coordinates of the passed address
export const getCoordinatesAndZip = async (address) => {
  return await getLatLngByAddress(address).then((res) => {
    const { coordinates, zipCode, geocode } = res;
    const hasDirection = /(East|West|North|South)/i.test(address);
    const updatedGeoCode = hasDirection
      ? updateAddress(geocode, address)
      : geocode;
    return { coordinates, zipCode, ...updatedGeoCode };
  });
};
