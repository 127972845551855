import { message } from "antd";
import { useSelector } from "react-redux";

import { useAdvancedFilterContext } from "../../context";
import MondayButton from "../../../MondayButton/MondayButton";
import { Close } from "../../../../SidebarPages/DynamicView/src";
import { fetchData } from "../../../../SidebarPages/Fleet/utils";
import { FilterIcon } from "../../../../SidebarPages/BasePage/src";
import { removeEmptyConditionsAndFilters } from "../../../../../utils";

const Footer = ({ gridApi, setRowData, title, setIsFiltersOpen }) => {
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const currentCategory = topicCategories?.find(
    (category) => category.categoryName === title
  );

  const endPoint = currentCategory?.apiName;

  const { filters } = useAdvancedFilterContext();

  const applyFiltersHandler = async () => {
    const appliedFilters = removeEmptyConditionsAndFilters(filters);
    if (appliedFilters.length === 0) {
      message.info("Your filter values are currently empty.");
      return;
    }
    if (endPoint) {
      gridApi.showLoadingOverlay();
      setIsFiltersOpen(false);
      await fetchData(endPoint, endPoint, {
        queryStringParameters: {
          ExclusiveStartKey: undefined,
          withPagination: "true",
          getMaxLimit: "true",
          filters: JSON.stringify(appliedFilters),
        },
      })
        .then((result) => {
          setRowData(result[endPoint] ? result[endPoint] : result);
          gridApi.hideOverlay();
        })
        .catch((error) => console.log({ error }));
    } else
      message.error(
        "Filter application is restricted. Please contact the administrator for assistance."
      );
  };

  const onCancelFilter = () => {
    setIsFiltersOpen(false);
  };

  return (
    <div className="footer-container">
      <MondayButton
        className="clear-filter-button"
        Icon={<Close />}
        onClick={onCancelFilter}
        // disabled={loading}
      >
        Cancel
      </MondayButton>
      <MondayButton
        className="filter-button"
        Icon={<FilterIcon />}
        // disabled={loading}
        onClick={applyFiltersHandler}
      >
        Apply Filters
      </MondayButton>
    </div>
  );
};

export default Footer;
