import { useMemo } from "react";
import { useSelector } from "react-redux";

/**
 * This hook gets programFields from redux and transforms them into an object
 *
 * @param {String} key  Key of the value you want as a key
 *
 * @returns Object Formatted programFields object {[key]: fieldOptions}
 */
export const useProgramFields = (key = "fieldName") => {
  const { programFields = [] } = useSelector((state) => state.programFields);

  const programFieldsObj = useMemo(() => {
    return programFields.reduce(
      (acc, { [key]: keyToUse, fieldOptions }) => ({
        ...acc,
        [keyToUse]: fieldOptions,
      }),
      {}
    );
  }, [programFields, key]);

  return programFieldsObj;
};
