import dayjs from "dayjs";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

const gridFilterDate = (filterLocalDateAtMidnight, cellValue) => {
  const dateAsString = dayjsNY(cellValue).format("MM/DD/YYYY");
  if (dateAsString == null) {
    return 0;
  }

  //Create a Date object for comparison against the filter date
  const dateParts = dateAsString.split("/");
  const day = Number(dateParts[1]);
  const month = Number(dateParts[0]) - 1;
  const year = Number(dateParts[2]);

  const cellDate = new Date(year, month, day);

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export default gridFilterDate;
