import { uniq } from "lodash";
import { getSelectedBaseRecords } from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import { preventDuplicate } from "../../../Scheduling/SchedulingFirstPage/helperData";

/**
 * Handles the selection of a document category.
 *
 * @param {string} val - The selected value for the document category.
 * @param {boolean} [clear=false] - Indicates whether to clear the fields.
 * @param {Object} [fetchedData={}] - The fetched data.
 * @param {string|null} [currentPageKey=null] - The current page key.
 * @param {Array} [topicCategories=[]] - The topic categories.
 * @param {Array} [docConfiguration=[]] - The document configuration.
 * @param {Object} [userConfiguration={}] - The user configuration.
 * @param {Function} [setFieldsValue=()=>{}] - The function to set field values.
 * @param {Function} [setRecordsLoading=()=>{}] - The function to set records loading.
 * @param {Function} [dispatchRequestData=()=>{}] - The function to dispatch request data.
 */
const onDocCategorySelect = async ({
  val,
  clear = false,
  fetchedData = {},
  currentPageKey = null,
  topicCategories = [],
  docConfiguration = [],
  userConfiguration = {},
  setFieldsValue = () => {},
  setRecordsLoading = () => {},
  dispatchRequestData = () => {},
}) => {
  const extraFetchKeys = [
    "accountName",
    "accountId",
    "leadId",
    "estimationNumber",
    "scheduleName",
    "teamsConfiguration",
  ];

  if (clear === true) {
    setFieldsValue({ documentationType: "", record: "", team: [] });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        category: "",
        docTypes: [],
        apiData: {
          table: "",
          primaryKey: "",
          rowName: "",
        },
      },
    });

    dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: [],
    });

    return;
  } else {
    setFieldsValue({ documentationType: "", record: "", team: [] });

    const docTypes = (docConfiguration || [])
      ?.find((el) => el?.categoryName === val)
      ?.documentationsAvailable?.map((el) => el?.docName);

    const recordApiData = topicCategories?.find((el) => {
      if (val === "Fleet") {
        return el?.categoryName === "Fleets";
      }
      return el?.categoryName === val;
    });

    dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: [],
    });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        category: val,
        docTypes,
        apiData: {
          table: recordApiData?.apiName || "",
          primaryKey: recordApiData?.primaryKey || "",
          rowName: recordApiData?.rowName || "",
          keysToInclude: recordApiData?.lazyFetchKeys || [],
        },
      },
    });

    if (!fetchedData?.[recordApiData?.apiName]?.length) {
      const fetchedDataKey =
        recordApiData?.apiName === "scheduling"
          ? "schedules"
          : recordApiData?.apiName;

      await getSelectedBaseRecords({
        idKey: recordApiData?.primaryKey,
        nameKey: recordApiData?.rowName,
        table: recordApiData?.apiName,
        setLoading: setRecordsLoading,
        keysToInclude: uniq([
          ...(recordApiData?.lazyFetchKeys || []),
          ...(extraFetchKeys || []),
        ]),
        setRecords: (res) => {
          const data = userAccessRecords(
            userConfiguration,
            preventDuplicate(res, recordApiData?.primaryKey)
          );

          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: { [fetchedDataKey]: data },
          });
        },

        customSetRecords: true,
        setCurrentPageKey: (key) => {
          Object.assign(currentPageKey, {
            current: {
              ...currentPageKey?.current,
              [recordApiData.apiName]: key,
            },
          });
          // currentPageKey.current = key;
        },
        userConfiguration,
      });
    }
  }
};

export default onDocCategorySelect;
