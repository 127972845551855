import moment from "moment";
import ActionsComponent from "../components/ActionsComponent";
import { MondaySwitch } from "../../../../../commonComponents";
import { EditIcon } from "../../../../../../icons";
import { Tooltip } from "antd";
import { DeleteReportTemplate } from "../components/DeleteReportTemplate/DeleteReportTemplate";

export const excelColumnsConfig = [
  "templateName",
  "defaultTemplate",
  "pageOrientation",
  "PageFooter",
  "PageHeader",
  "createdBy",
  "createdAt",
];
export const columnDefs = ({
  setNewTemplate,
  handleTemplateIdExtracted,
  onTemplateDeleteHandler,
  sendLogToApi,
  updateState,
  handleEditClick,
  confirm,
  setConfirm,
}) => {
  const centerItem = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return [
    {
      headerName: "Template Name",
      field: "templateName",
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Default Template",
      field: "defaultTemplate",
      filter: "agMultiColumnFilter",
      cellClass: "reportTemplate-cell cell-left-aligned",
      cellRenderer: ({ value }) => (
        <div style={centerItem}>{value === true ? "Yes" : "No"}</div>
      ),
    },
    {
      headerName: "Template State",
      field: "templateState",
      filter: "agMultiColumnFilter",
      cellRendererFramework: ({ data }) => (
        <div style={centerItem}>
          <MondaySwitch
            {...{
              checked: data?.templateState,
              onToggleSwitch: () => {
                updateState(data);
              },
              onText: "Enabled",
              offText: "Disabled",
              removeIcon: false,
            }}
          />
        </div>
      ),
      getQuickFilterText: ({ value }) => (value ? "Enabled" : "Disabled"),
      pdfExportOptions: {
        modifyCellValue: (data) => {
          return data ? "Enabled" : "Disabled";
        },
      },
    },
    {
      headerName: "Page Orientation",
      field: "pageOrientation",
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Has Footer",
      field: "PageFooter",
      filter: "agMultiColumnFilter",
      cellClass: "reportTemplate-cell",
      cellRenderer: ({ value }) => {
        return (
          <div style={centerItem}>
            {Object.keys(value || {}).length > 0 ? "Yes" : "No"}
          </div>
        );
      },
    },
    {
      headerName: "Has Header",
      field: "PageHeader",
      filter: "agMultiColumnFilter",
      cellClass: "reportTemplate-cell",
      cellRenderer: ({ value }) => {
        return (
          <div style={centerItem}>
            {Object.keys(value || {}).length > 0 ? "Yes" : "No"}
          </div>
        );
      },
    },
    {
      headerName: "Create By",
      field: "createdBy",
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Created At",
      field: "createdAt",
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => {
        return moment(value).format("MM/DD/YYYY HH:mm");
      },
      pdfExportOptions: {
        modifyCellValue: (value) => moment(value).format("MM/DD/YYYY HH:mm"),
      },
    },
    {
      headerName: "Edit",
      filter: "agMultiColumnFilter",
      enableRowGroup: false,
      cellClass: "cell-center-aligned",
      pdfExportOptions: {
        skipColumn: true,
      },
      cellRenderer: ({ data }) => {
        const containerStyle = {
          ...centerItem,
          height: 30,
          width: 30,
          cursor: "pointer",
        };
        return (
          <div style={containerStyle}>
            <EditIcon
              style={{ width: 14, height: 14 }}
              fill="#1264a3"
              onClick={() => handleEditClick(data)}
            />
          </div>
        );
      },
    },
    {
      headerName: "Delete",
      filter: "agMultiColumnFilter",
      cellClass: "cell-center-aligned",
      enableRowGroup: false,
      pdfExportOptions: {
        skipColumn: true,
      },
      cellRenderer: ({ data }) => {
        const containerStyle = {
          ...centerItem,
          height: 30,
          width: 30,
          cursor: "pointer",
        };

        return (
          <Tooltip title="Delete">
            <div style={containerStyle}>
              <DeleteReportTemplate
                {...{
                  data,
                  onTemplateDeleteHandler,
                  confirm,
                  setConfirm,
                }}
              />
            </div>
          </Tooltip>
        );
      },
    },
    // {
    //   headerName: "Actions",
    //   field: "templateId",
    //   pdfExportOptions: {
    //     skipColumn: true,
    //   },
    //   cellRendererFramework: ({ data }) => {
    //     return (
    //       <ActionsComponent
    //         {...{
    //           data,
    //           setNewTemplate,
    //           handleTemplateIdExtracted,
    //           sendLogToApi,
    //         }}
    //       />
    //     );
    //   },
    // },
  ];
};
