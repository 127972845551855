import { useState } from "react";
import { Modal, Form, message, Button } from "antd";
import { Auth } from "aws-amplify";
// import OtpInput from "react-otp-input";

import { validateForm } from "../../../../../Fleet/utils";
import { createUserLog } from "../Cards/utils/UnlinkedFunctions";
import { InputComponent } from "../../../../../Fleet/components";
import { MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../Communication/assets";

import "./CreateUserModal.scss";
import { updateDocumentTitle } from "../../../../../../../utils";
import { useSelector } from "react-redux";
import { useEditLogs } from "../../../../../../../hooks";

function CreateUserModal({ open, onCancel, users }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [newUser, setNewUser] = useState(null);
  // const [otpValue, setOtpValue] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [form] = Form.useForm();
  const { saveAddedLogs } = useEditLogs();

  async function createUserHandler() {
    await Auth.signUp({
      username: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      attributes: {
        phone_number:
          "+" +
          form
            .getFieldValue("phone")
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
        family_name: form.getFieldValue("lastName"),
        given_name: form.getFieldValue("firstName"),
        "custom:department": "Fleet",
        "custom:dep": "Driver",
      },
    })
      .then((res) => {
        createUserLog(saveAddedLogs, res);
        message.success("User Created successfully!");
        setConfirmEmail(form.getFieldValue("email"));
        setNewUser(res);
      })
      .catch((err) => {
        console.log("User Creation Error: ", err);
        message.error(
          "an error ocurred while creating the user, please try again later."
        );
      });
  }

  async function userConfirm() {
    Auth.confirmSignUp(confirmEmail, form.getFieldValue("verifyCode")).then(
      async (res) => {
        if (res === "SUCCESS") {
          message.success("New account is confirmed!");
          form.resetFields();
          setConfirmEmail();
          setNewUser(null);
          closeModal();
        }
      }
    );
  }

  function onSave() {
    validateForm(form, createUserHandler);
  }

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  };

  return (
    <Modal
      {...{
        open,
        onCancel() {
          form.resetFields();
          closeModal();
        },
        className: `createUserModalContainer ${
          isDarkMode && "createUserModalContainerDark"
        }`,
        closeIcon: <XIcon />,
        title: "Create New User",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Create New User" });
        },
        footer: !newUser
          ? [
              <MondayButton
                Icon={<XIcon />}
                onClick={() => {
                  form.resetFields();
                  closeModal();
                }}
                className="mondayButtonRed"
              >
                Cancel
              </MondayButton>,
              <MondayButton
                Icon={<TickIcon width={17} height={17} />}
                onClick={onSave}
              >
                Create User
              </MondayButton>,
            ]
          : null,
      }}
    >
      <Form form={form}>
        <InputComponent
          {...{
            label: "First Name",
            formItemName: "firstName",
            required: true,
            type: "input",
            placeholder: "Type here...",
            style: { display: newUser && "none" },
          }}
        />
        <InputComponent
          {...{
            label: "Last Name",
            formItemName: "lastName",
            required: true,
            type: "input",
            placeholder: "Type here...",
            style: { display: newUser && "none" },
          }}
        />
        <InputComponent
          {...{
            label: "Phone Nr.",
            formItemName: "phone",
            required: true,
            type: "phone",
            style: { display: newUser && "none" },
          }}
        />
        <InputComponent
          {...{
            label: "Email",
            formItemName: "email",
            required: true,
            type: "input",
            placeholder: "Type here...",
            style: { display: newUser && "none" },
            rules: [
              {
                type: "email",
                message: "Please enter a valid email",
              },
              {
                validator(_, value) {
                  let t = users?.find(
                    (user) =>
                      user?.userName?.toLowerCase() === value?.toLowerCase()
                  );
                  if (!!t) {
                    return Promise.reject(
                      "There is already a user with this email"
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ],
          }}
        />
        <InputComponent
          {...{
            label: "Password",
            formItemName: "password",
            required: true,
            type: "input",
            typePassword: true,
            placeholder: "********",
            style: { marginTop: "1rem", display: newUser && "none" },
            rules: [
              {
                validator(_, value) {
                  const regex =
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?=.*[a-zA-Z0-9]).{8,}$/;
                  if (regex.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please enter a valid password");
                  }
                },
              },
            ],
          }}
        />
        <InputComponent
          {...{
            label: "Confirm Password",
            formItemName: "passwordConfirm",
            required: true,
            type: "input",
            typePassword: true,
            placeholder: "********",
            style: { marginTop: "1rem", display: newUser && "none" },
            rules: [
              {
                validator(_, value) {
                  let original = form.getFieldValue("password");
                  if (original !== value) {
                    return Promise.reject("Passwords do not match");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ],
          }}
        />
        {newUser && (
          <div className="PasswordConfirmation">
            <svg
              width="43"
              height="42"
              viewBox="0 0 43 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0244 35.7771L4.83875 34.5032C4.47293 34.4352 4.16531 34.2399 3.91589 33.9172C3.66647 33.5945 3.57502 33.2293 3.64153 32.8217L4.33991 26.707L0.399072 22.0191C0.133024 21.7473 0 21.4076 0 21C0 20.5924 0.133024 20.2527 0.399072 19.9809L4.33991 15.344L3.64153 9.2293C3.57502 8.82166 3.66647 8.45648 3.91589 8.13376C4.16531 7.81104 4.47293 7.61571 4.83875 7.54777L11.0244 6.27389L14.1172 0.821656C14.3167 0.481953 14.5994 0.244161 14.9652 0.10828C15.331 -0.0276009 15.6968 -0.0106158 16.0626 0.159236L21.5 2.75796L26.9374 0.159236C27.3032 -0.0106158 27.6773 -0.044586 28.0597 0.0573249C28.4422 0.159236 28.7166 0.397028 28.8828 0.770701L32.0255 6.27389L38.1613 7.54777C38.5271 7.61571 38.8347 7.81104 39.0841 8.13376C39.3335 8.45648 39.425 8.82166 39.3585 9.2293L38.6601 15.344L42.6009 19.9809C42.867 20.2527 43 20.5924 43 21C43 21.4076 42.867 21.7473 42.6009 22.0191L38.6601 26.707L39.3585 32.8217C39.425 33.2293 39.3335 33.5945 39.0841 33.9172C38.8347 34.2399 38.5271 34.4352 38.1613 34.5032L32.0255 35.7771L28.8828 41.2293C28.7166 41.603 28.4422 41.8408 28.0597 41.9427C27.6773 42.0446 27.3032 42.0106 26.9374 41.8408L21.5 39.242L16.0626 41.8408C15.6968 42.0106 15.331 42.0276 14.9652 41.8917C14.5994 41.7558 14.3167 41.518 14.1172 41.1783L11.0244 35.7771ZM18.3074 26.707C18.6067 27.0127 18.9559 27.1656 19.355 27.1656C19.7541 27.1656 20.1032 27.0127 20.4026 26.707L29.6311 17.3822C29.8971 17.1104 30.0219 16.7622 30.0052 16.3376C29.9886 15.913 29.8306 15.5478 29.5313 15.242C29.232 14.9363 28.8745 14.7919 28.4588 14.8089C28.0431 14.8259 27.669 14.9873 27.3364 15.293L19.355 23.3949L15.7135 19.4713C15.4142 19.1316 15.04 18.9703 14.5911 18.9873C14.1421 19.0042 13.768 19.1826 13.4687 19.5223C13.1694 19.862 13.0114 20.2527 12.9948 20.6943C12.9782 21.1359 13.1361 21.5096 13.4687 21.8153L18.3074 26.707Z"
                fill="#71CF48"
              />
            </svg>

            <h2>Confirm Your Account</h2>
            <p>
              Please insert the confirmation code that was sent to your email.
            </p>
            <Form.Item className="passCodeInputs" name="verifyCode">
              {/* <OtpInput
                className="otpInput"
                onChange={(val) => setOtpValue(val)}
                value={otpValue}
                numInputs={6}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={"inputStyle"}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              /> */}
            </Form.Item>
            <Button
              onClick={() => validateForm(form, userConfirm, ["verifyCode"])}
              className="formButtonElement"
            >
              <span className="buttonText">Confirm Account</span>
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
}

export default CreateUserModal;
