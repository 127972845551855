import { message } from "antd";

import { calculateDifference, updateFakeSOV } from "../utils";
import { getErrors } from "../../../../utils/getErrors";

export const removeRow = (
  eventId,
  isWritable,
  isLoading,
  service,
  fakeSOV,
  setSOV,
  setErrors
) => {
  if (!isWritable) {
    message.error("Please enable write mode");
  } else if (isLoading) {
    message.error("Please wait until it finishes updating");
  } else {
    // Find service that we want to edit
    const serviceToEdit = fakeSOV?.services?.find(
      (sovService) =>
        sovService.serviceId.toString() === service.serviceId.toString() &&
        sovService.estimationId === service.estimationId
    );

    // Update amounts on grid
    const newAmounts = !!serviceToEdit?.amounts.length && [
      // If there is any event
      ...serviceToEdit.amounts.slice(0, eventId), // Copy all events until that row, and delete the row that was right clicked
      ...serviceToEdit.amounts
        .slice(eventId + 1)
        .map((amount) => ({ ...amount, id: amount.id - 1 })), // Copy all other events after the deleted one and decrement their id by 1
    ];

    // Re calculate all amounts to update other rows based on the row that we have updated
    const amounts = calculateDifference(serviceToEdit, newAmounts);

    // Find the service that we are editing and update its amounts
    const newSOV = updateFakeSOV(fakeSOV, service, amounts);

    // Get errors for each service
    const errors = getErrors(newSOV);

    // Set new SOV
    setSOV(newSOV);

    // If none of services have errors set errors to null and save to db, otherwise set errors to errors object of all services
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      setErrors(null);
    } else {
      setErrors(errors);
    }
  }
};
