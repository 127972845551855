import { forwardRef, useRef, useImperativeHandle, useContext } from "react";
import { Select } from "antd";
import DegModalContext from "../DegModalContext";
import { useSelector } from "react-redux";
import { DropdownIcon } from "../../../../../../../../icons";

const StatusGridEditor = forwardRef(
  ({ value, stopEditing, api, data }, ref) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const { rowData, setRowData, emptyData, incorrectData, addEntryAction } =
      useContext(DegModalContext);
    const status = useRef(value);

    const STATUS_LIST = ["Draft", "Pending", "Completed"];

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue() {
            return status.current;
          },
          isCancelBeforeStart() {
            return false;
          },
        };
      },
      []
    );

    function onSelect(event) {
      status.current = event;
      if (data?.shiftId) {
        let editedEntries = [];
        const updatedRowData = rowData.map((activity) => {
          if (data?.entries.includes(activity?.entryId)) {
            editedEntries.push(
              Object.assign(activity, { activityStatus: event })
            );
            return Object.assign(activity, { activityStatus: event });
          } else {
            return activity;
          }
        });
        setRowData(updatedRowData);
        const currentRow = Object.assign(data, {
          shiftStatus: event,
        });
        api.applyTransaction({
          update: [currentRow],
        });
        addEntryAction({
          type: "edit",
          entry: editedEntries,
        });
      } else {
        const currentRow = Object.assign(data, {
          activityStatus: event,
        });
        api.applyTransaction({
          update: [currentRow],
        });
        addEntryAction({
          type: "edit",
          entry: [currentRow],
        });
      }
      stopEditing();
    }

    return (
      <Select
        {...{
          className: `gridDropdownSelect ${isDarkMode && "gridSelectDark"}`,
          size: "large",
          onDropdownVisibleChange: (dropdownVisible) => {
            if (!dropdownVisible) {
              stopEditing();
            }
          },
          suffixIcon: <DropdownIcon />,
          defaultOpen: true,
          popupClassName: isDarkMode && "darkDropDown",
          onSelect,
          defaultValue: value,
          autoFocus: true,
          style: {
            width: "100%",
            height: "100%",
            borderRadius: "0px",
          },
          options: STATUS_LIST.map((opt, key) => ({
            key,
            value: opt,
            label: opt,
            disabled:
              opt === "Completed" &&
              emptyData.concat(incorrectData).includes(data?.entryId),
          })),
        }}
      />
    );
  }
);

export default StatusGridEditor;
