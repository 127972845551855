import { CardView } from "../components";
export const alignItems = ({
  overviewData,
  align,
  dataView,
  setDataView,
  hidden,
  getEditedValue,
  currentStep,
  disableDrag,
  setChanged,
}) => {
  return Array.isArray(overviewData)
    ? overviewData
        ?.filter(
          (e) =>
            e?.align === align &&
            e?.Card !== "AbsoluteStepper" &&
            e?.hidden === hidden
        )
        ?.sort((i, ni) => i.index - ni.index)
        ?.map((el, id) => {
          return {
            id: el.id,
            hidden: el.hidden || false,
            index: el.index,
            component: CardView?.[el?.Card]({
              title: el.title,
              params: el.cardParams,
              id: el.id,
              headerLabel: el?.headerLabel && el.headerLabel,
              width: el?.width && el.width,
              setDataView,
              dataView,
              getEditedValue,
              currentStep,
              editable: el?.editable,
              disableDrag,
              isFilter: el?.isFilter,
              setChanged,
            }),
          };
        })
    : [];
};

export const hiddenElement = ({ overviewData, hidden, getEditedValue }) => {
  return Array.isArray(overviewData)
    ? overviewData
        ?.filter((e) => e?.Card !== "AbsoluteStepper" && e?.hidden === hidden)
        ?.sort((i, ni) => i.index - ni.index)
        ?.map((el) => el)
    : [];
};
