import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EditableModeWrapper } from "../../../../../../../../../../Accounting/components/CreateInvoice/hoc/EditableModeWrapper";
import { useRedux } from "../../../../../../../../../../hooks";
import { Select } from "antd";
import { DropdownIcon } from "../../../../../../../../../../BasePage/src";
import "./ScheduleTypesSelector.scss";
import { changeScheduleTypeRentals } from "./changeScheduleType";
function ScheduleTypesSelector() {
  const { programFields } = useSelector((state) => state.programFields);
  const [isWritable] = useRedux("rentalsIsWritable");
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const { isLoading } = useParams();

  const scheduleStatuses = programFields
    .find((item) => item.fieldName === "Scheduling Types")
    .fieldOptions.filter(({ status }) => status)
    .map(({ statusName }) => {
      return { label: statusName, value: statusName };
    });
  const { scheduleType = "Installation" } = fakeRentals || {};

  const changeStatus = (value) => {
    return changeScheduleTypeRentals({
      setUnchangingFakeRentals,
      setFakeRentals,
      fakeRentals,
      value,
    });
  };

  return (
    <EditableModeWrapper {...{ isWritable }}>
      <Select
        className="schedulesTypeChangerContainer"
        disabled={!isWritable || isLoading}
        value={scheduleType}
        options={scheduleStatuses}
        style={{ width: 130, opacity: isWritable ? 1 : 0.62 }}
        onChange={changeStatus}
        suffixIcon={<DropdownIcon />}
      />
    </EditableModeWrapper>
  );
}

export default ScheduleTypesSelector;
