import { InfoIcon } from "../../../../Inspections/assets";

const NoWidgetsList = ({ message = "", testid = "" }) => {
  return (
    <div
      className="no-widget-list-body no-widgets-fade-in"
      key="no-widgets-message"
      data-testid={testid}
    >
      <div className="no-widget-info-icon">
        <InfoIcon />
      </div>
      <div className="no-widget-info-message">{message}</div>
    </div>
  );
};

export default NoWidgetsList;
