import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import columnDefs from "./columnDefs";

import "./InactivityCard.scss";

function InactivityCard(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div className="inactivity-card-view-body">
      <div
        className={`grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            columnDefs,
            suppressRowClickSelection: true,
            rowData: props?.params?.inactivityTimeline || [],
            pagination: false,
            gridOptions: {
              alwaysShowVerticalScroll: true,
              alwaysShowHorizontalScroll: true,
            },
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
    </div>
  );
}

export default InactivityCard;
