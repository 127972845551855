// var gapi = window.gapi;
import { gapi } from "gapi-script";
import { GOOGLE_SCOPES } from "../../../../../helpers/constants/googleScopes";
var google = window.google;

//Migration
const CLIENT_ID =
  "202644885433-htcu0omqe0tnbi7pn9gnj5boabpnp711.apps.googleusercontent.com";
/**
 * Function used to authenticate the user for connecting to Google Account
 * @returns {Boolean} true when signed in or false when couln't sign in/popup closed by user
 */
export const client = google?.accounts?.oauth2?.initTokenClient({
  client_id: CLIENT_ID,
  scope: GOOGLE_SCOPES,
  callback: (tokenResponse) => {
    token = tokenResponse;
    if (tokenResponse && tokenResponse.access_token) {
      if (
        google.accounts.oauth2?.hasGrantedAnyScope(tokenResponse, GOOGLE_SCOPES)
      ) {
      }
      if (
        google.accounts.oauth2?.hasGrantedAllScopes(
          tokenResponse,
          GOOGLE_SCOPES
        )
      ) {
      }
    }
  },
});

/**
 * Function used to authenticate the user for connecting to Google Account
 * @returns {Boolean} true when signed in or false when couln't sign in/popup closed by user
 */
export function authenticate() {
  //Migration
  return new Promise((resolve, reject) => {
    const authToken = client.requestAccessToken();
    if (authToken) {
      console.log("Sign-in successful");
      resolve(true);
    } else {
      console.error("Error signing in");
      reject(false);
    }
  });
}

gapi.load("client", function () {
  gapi.client.load("drive", "v3", function () {});

  gapi.client.load("sheets", "v4", function () {});
});

/**
 * Function used to load GAPI client and setting API key for REST services
 * @returns {Boolean} true if the GAPI client was loaded successfully after Authentication
 */
export function loadClient() {
  gapi.client.setApiKey("AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo");
  //Migration
  return gapi.client
    .load("https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest")
    .then(
      function () {
        console.log("GAPI client loaded for API");
        return true;
      },
      function (err) {
        console.error("Error loading GAPI client for API", err);
      }
    );
}

// Used to load AUTH2
//Migration
// gapi.client.load("client", function () {
google?.accounts?.oauth2?.initTokenClient({
  client_id: CLIENT_ID,
  scope: GOOGLE_SCOPES,
});
// });

/**
 * Function used to tie authenticate & load gapi promises for an optimized validation
 * @returns {Boolean} true only if authentication & load client GAPI has been successful.
 */
export const authenticateAndLoad = () => {
  return authenticate()
    .then(loadClient)
    .then(() => {
      return true;
    });
};

/**
 * Function used to get loggedIn instance from AuthInstance using Google Account.
 * @returns {Boolean} true if the user has been logged in using Google!
 */
export const isLoggedIn = () => {
  return !!localStorage.getItem("googleAuthAccessToken");
};

/**
 * Function used to validate whether the GAPI client has been loaded.
 * It is not possible to call List of Events or calendars without GAPI fully loading.
 * Usually constrained in a setTimeOut function
 * @returns {Boolean} true if GAPI has been loaded, false otherwise
 */
export const isLoaded = async () => {
  loadClient().then((val) => {
    // console.log("isLoaded? ", val);
    return val;
  });
};

/**
 * Function used to retrieve the current user of authentication instance.
 * Should be used in conjuction with authentication, load GAPI and signIn auth
 * @returns {String} email of the authenticated user
 */
export const getUserInfo = () => {
  const authInstance = gapi.client.load("oauth2", "v2", function () {
    gapi.client.oauth2?.userinfo.get().execute(function (resp) {
      // Shows user email
      return resp.email;
    });
  });
  if (authInstance) return authInstance;

  return false;
};
