export const generateColumnDefs = (data) => {
  if (!!!data?.length) {
    return [];
  }

  const firstElement = data[0];
  const columnDefs = [];

  for (const key in firstElement) {
    const formattedHeader = key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());

    columnDefs.push({
      headerName: formattedHeader,
      field: key,
      editable: true,
      //   cellEditor: InputGridEditor,
    });
  }

  return columnDefs;
};

export const gridOptions = {
  defaultColDef: {
    resizable: true,
    enablePivot: true,
    enableColResize: true,
    enableRowGroup: true,
    editable: false,
    sortable: true,
    flex: 1,
    filter: true,
  },
  rowGroupPanelShow: "always",
  pagination: true,
  animateRows: true,
  enableRangeSelection: true,
  paginationPageSize: Math.floor((window.innerHeight - 300) / 45),
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  },
  overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  maintainColumnOrder: true,
};
