import types from "../types";

const initialState = {
  scheduleDays: [],
  notesToAddForScheduleDays: {},
  toBeScheduled: {},
  rowData: [],
  columnDefs: [],
  fleetInfo: {
    fleet: [],
    drivers: [],
    fleetDispatching: [],
    dispatchesChanged: false,
  }, //info from database
  crewsInfo: { crews: [], crewActivity: [], crewsChanged: false },
  scheduleSettings: {},
};

const scheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // console.log("scheduleReducer", type, payload);

  if (payload) {
    const {
      scheduleDays,
      notesToAddForScheduleDays,
      toBeScheduled,
      rowData,
      columnDefs,
      fleetInfo,
      crewsInfo,
      scheduleSettings,
    } = payload;
    switch (type) {
      case types.SET_SCHEDULE_DAYS:
        return { ...state, scheduleDays, notesToAddForScheduleDays };
      case types.SET_TO_BE_SCHEDULED:
        return { ...state, toBeScheduled };
      case types.SET_DATA_FOR_SCHEDULE_CALENDAR:
        return { ...state, rowData, columnDefs };
      case types.SET_CREWS_FLEET_INFO:
        return { ...state, fleetInfo, crewsInfo };
      case types.SET_SCHEDULE_SETTINGS:
        return { ...state, scheduleSettings };

      default:
        return state;
    }
  } else return state;
};

export default scheduleReducer;
