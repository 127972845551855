import { List } from "../../../../../../DynamicView/cardComponent";
import "./DocumentationLogsModal.scss";
import { columnNames } from "./documentationLogsModalData";
import { viewColumnNames } from "./viewLogsModalData";
import { NormalSizedModal } from "../../../../../../Fleet/components";
import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../../../commonComponents";
import { sortBy } from "lodash";
import dayjs from "dayjs";
import { Progress, Skeleton, Switch, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { keys } from "../../../../../../../pages/Settings/settingsComponents/Roles/RolesData";
import htmlParser from "../../../../../../../../utils/htmlParser";
import { XIcon } from "../../../../../../Communication/assets/index";
import { ArrowLeftOutlined, FilterOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ExportPdfIcon } from "../../../../../../../../assets";
import {
  pdfBorderedSection,
  docDefinition,
} from "../../../../../../../pages/Settings/pdfExportOnComponents";
import createPDF, {
  PREVIEW,
} from "../../../../../../../../integrations/createPDF";
import { compareIncluding } from "../../../../../../utils";
import FiltersModal from "src/components/commonComponents/RowDataGridModal/components/FiltersModal";
import { generateColumnDefs } from "src/components/commonComponents/RowDataGridModal/components/AgGridData/generateColumnDefs";
import { LogsIcon } from "../../../../../../DynamicView/src";

const TIME_FORMAT = "hh:mm a";
const DATE_FORMAT = "MM/DD/YYYY";

const DocumentationLogsModal = ({
  visible,
  setVisible,
  docLogs = [],
  viewLogs = [],
  logsColumnDefs,
  title = "Documentation Logs",
  isForIncludesExcludes = false,
  firstElRowData,
  showFirstRow = true,
  progress = 100,
  postLogs,
  treeLogs = [],
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [isModalDescription, setIsModalDescription] = useState(false);

  const { base64 } = useSelector((state) => state.base64);
  const [description, setDescription] = useState("");
  const [selectedLog, setSelectedLog] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [rowDataFilter, setRowDataFilter] = useState("");
  const [appliedFilters, setAppliedFilters] = useState();
  const [openTreeLog, setOpenTreeLog] = useState(false);

  function removeHtmlTags(inputString) {
    const htmlRegex = /<[^>]*>/g;
    return inputString.replace(htmlRegex, "");
  }

  const formatValue = (type, value) =>
    type === "datepicker"
      ? dayjs(value).format(DATE_FORMAT)
      : type === "timepicker"
      ? dayjs(value).format(TIME_FORMAT)
      : type === "list"
      ? value.join(", ")
      : type === "html"
      ? htmlParser(value)
      : value;

  const orderedLogsColumnDefs = sortBy(docLogs, "updatedAt")?.reverse();

  const propColumnDefs = [
    {
      field: "nameOfUser",
      headerName: "User",
    },
    { field: "actionType", headerName: "ACTION" },
    { field: "updatedAt", headerName: "Date & Time" },
  ];

  firstElRowData && orderedLogsColumnDefs?.unshift(firstElRowData);
  let rowData = logsColumnDefs
    ? orderedLogsColumnDefs?.flatMap((el) => {
        //differentiates between logs saved with the correct spelling
        let tmpIdentifier = "previousData";
        if (el?.hasOwnProperty("previusData")) tmpIdentifier = "previusData";

        const destructuredData = {};
        //destruct data to display other keys
        logsColumnDefs
          .map(({ fieldName }) => fieldName)
          .forEach((key) => {
            destructuredData[key] = el[key];
          });

        const {
          member,
          updatedAt,
          label,
          previousData,
          currentData,
          updatedKeys,
        } = el;

        //if is not provided updatedKeys log was not returned!!!
        return !!updatedKeys?.length
          ? (Array.isArray(updatedKeys) ? updatedKeys : [])?.map((key) => {
              const { details = "" } = key || {};
              return {
                ...destructuredData,
                member,
                updatedAt: dayjs(updatedAt).format(
                  `${DATE_FORMAT} ${TIME_FORMAT}`
                ),
                details: !Object.keys(previousData || {})?.length
                  ? "Creation"
                  : details,
                label,
                previousData,
                currentData,
              };
            })
          : {
              ...destructuredData,
              member,
              updatedAt: dayjs(updatedAt).format(
                `${DATE_FORMAT} ${TIME_FORMAT}`
              ),
              label,
              previousData,
              currentData,
              details: "Creation",
            };
      })
    : orderedLogsColumnDefs?.flatMap(({ member, updatedAt, updatedKeys }) =>
        updatedKeys?.map(
          ({ label, previousValue, updatedValue, type, topicName }) => ({
            member,
            updatedAt: dayjs(updatedAt).format(`${DATE_FORMAT} ${TIME_FORMAT}`),
            label,
            topicName,
            previousValue: !!previousValue
              ? formatValue(type, previousValue)
              : "",
            updatedValue: formatValue(type, updatedValue),
          })
        )
      );

  const columnDefs = generateColumnDefs({ propColumnDefs, rowData });
  const viewRowData = keys(viewLogs)
    .map((e) => ({
      updatedAt: dayjs(viewLogs[e].lastSeen).format(
        `${DATE_FORMAT} ${TIME_FORMAT}`
      ),
      nameOfUser: viewLogs[e].name,
      date: viewLogs[e].lastSeen,
    }))
    .sort((a, b) => {
      return b.date - a.date;
    });

  //used in the logs base page in settings
  if (!showFirstRow) {
    rowData = rowData.filter((el) => el.details !== "Creation");
  }

  function removeHTMLTags(inputString) {
    const htmlTagPattern = /<[^>]+>/g;

    if (htmlTagPattern.test(inputString)) {
      return inputString.replace(htmlTagPattern, "");
    } else {
      return inputString;
    }
  }
  const onGeneratePdf = ({ switchChecked, viewRowData }) => {
    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;

    const dynamicPdfData = [
      pdfBorderedSection({
        type: switchChecked,
        title,
        body: !switchChecked
          ? !isForIncludesExcludes
            ? rowData
                .slice(rowData.length - 25, rowData.length)
                .reverse()
                .map((item) => ({
                  labelName: item.label,
                  details:
                    item.previousValue?.params?.data?.value === undefined
                      ? "No details"
                      : `${item.previousValue?.params?.data?.value} to ${item.currentValue?.params?.data?.value}`,
                  description: item.member,
                  type:
                    typeof item.updatedAt === "number"
                      ? dayjs(item.updatedAt).format("MM/DD/YYYY hh:mm a")
                      : item.updatedAt,
                }))
            : orderedLogsColumnDefs.map((item) => ({
                labelName: item.updatedKeys[0].label,
                details: `${removeHtmlTags(
                  item.updatedKeys[0].previousValue
                )} to ${removeHtmlTags(item.updatedKeys[0].updatedValue)}`,
                description: item.member,
                type:
                  typeof item.updatedAt === "number"
                    ? dayjs(item.updatedAt).format("MM/DD/YYYY hh:mm a")
                    : item.updatedAt,
              }))
          : viewRowData.map((item) => ({
              labelName: item.nameOfUser,
              description: item.updatedAt,
            })),
      }),
    ];
    createPDF({
      action: PREVIEW,
      docDefinition: docDefinition(title, dynamicPdfData, companyLogo),
    });
    postLogs();
  };
  const handleExport = () => {
    onGeneratePdf({ switchChecked, viewRowData });
  };
  const handleSelectChange = (e) => {
    setSelectedLog(e);
  };
  const handleChange = (e) => {
    if (!!e) {
      if (selectedLog === "updatedAt") {
        setRowDataFilter(
          rowData.filter((elem) => elem.updatedAt.includes(e?.toLowerCase()))
        );
      } else {
        setRowDataFilter(
          rowData.filter((elem) =>
            elem[selectedLog]?.toLowerCase()?.includes(e?.toLowerCase())
          )
        );
      }
    } else {
      setRowDataFilter(rowData);
    }
  };

  useEffect(() => {
    if (appliedFilters) {
      for (let key in appliedFilters) {
        setRowDataFilter(
          rowData.filter((elem) => elem[key] === appliedFilters[key].filter)
        );
      }
    } else {
      setRowDataFilter(rowData);
    }
  }, [appliedFilters]);
  return (
    <NormalSizedModal
      {...{
        visible,
        setVisible,
        title,
        className: `documentationLogsModal ${
          isDarkMode ? "documentationLogsModalDark" : ""
        }`,
        destroyOnClose: true,
        closable: true,
        centered: true,
      }}
    >
      {progress < 100 ? (
        <div style={{ minHeight: "200px", width: "90vw" }}>
          <Progress percent={progress} status="active" />
          <Skeleton active />
        </div>
      ) : (
        <div className="content">
          {isModalDescription ? (
            <div
              className="descritpion"
              style={{ minHeight: "400px", minWidth: "700px" }}
            >
              <p className="description-content">
                {removeHTMLTags(description)}
              </p>
              <button
                className="back-button"
                onClick={() => setIsModalDescription(false)}
              >
                <ArrowLeftOutlined />
                Back
              </button>
            </div>
          ) : (
            <>
              {viewLogs.length !== 0 && (
                <Switch
                  checkedChildren="Last seen"
                  unCheckedChildren="Logs"
                  checked={switchChecked}
                  onChange={(e) => setSwitchChecked(e)}
                />
              )}
              {!switchChecked ? (
                <List
                  key="List1"
                  {...{
                    isForIncludesExcludes,
                    setIsModalDescription,
                    setDescription,
                    params: {
                      columnNames: logsColumnDefs || columnNames,
                      rowData: rowDataFilter ? rowDataFilter : rowData,
                    },
                  }}
                />
              ) : (
                <List
                  key="List2"
                  {...{
                    isForIncludesExcludes,
                    setIsModalDescription,
                    setDescription,
                    params: {
                      columnNames: viewColumnNames,
                      rowData: viewRowData,
                    },
                  }}
                />
              )}
              <div style={{ display: "flex", gap: "1rem" }}>
                <MondayButton
                  {...{
                    className: "mondayButtonBlue",
                    Icon: <ExportPdfIcon width={15} />,
                    onClick: () => handleExport(),
                  }}
                >
                  Export as PDF
                </MondayButton>
                <MondayButton
                  {...{
                    className: "mondayButtonRed",
                    Icon: <XIcon width={15} />,
                    onClick: () => setVisible(false),
                  }}
                >
                  Close
                </MondayButton>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Input
                    popupClassName={isDarkMode && "darkDropDown"}
                    placeholder="Search a log..."
                    style={{ border: "none", padding: "5px", width: "250px" }}
                    onChange={(e) => handleChange(e.target.value)}
                  />
                  <Select
                    defaultValue="Select a log"
                    style={{ width: 150 }}
                    onSelect={(e) => handleSelectChange(e)}
                    allowClear
                    options={[
                      { value: "label", label: "Label" },
                      { value: "previousValue", label: "Previous Value" },
                      { value: "updatedValue", label: "Updated Value" },
                      { value: "member", label: "User" },
                      { value: "updatedAt", label: "Date & Time" },
                    ]}
                  />
                  <MondayButton
                    className="mondayButtonBlue"
                    Icon={<FilterOutlined />}
                    onClick={() => setShowFilterModal(true)}
                  >
                    Open Filters
                  </MondayButton>
                  {showFilterModal ? (
                    <FiltersModal
                      {...{
                        showFilterModal,
                        setShowFilterModal,
                        appliedFilters,
                        setAppliedFilters: (filters) => {
                          setAppliedFilters(filters);
                        },
                        columnDefs,
                        rowData: rowDataFilter ? rowDataFilter : rowData,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {treeLogs?.length > 0 && (
                    <MondayButton
                      className="mondayButtonBlue"
                      Icon={<LogsIcon />}
                      onClick={() => setOpenTreeLog(true)}
                    >
                      Open Other Logs
                    </MondayButton>
                  )}
                </div>
              </div>
            </>
          )}
          {openTreeLog && (
            <MultiLevelTreeLogs
              {...{
                visible: openTreeLog,
                setVisible: setOpenTreeLog,
                logsData: treeLogs,
                title: "Other" + title,
              }}
            />
          )}
        </div>
      )}
    </NormalSizedModal>
  );
};

export default DocumentationLogsModal;
