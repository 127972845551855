import React, { useState } from "react";
import { Form, Modal, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { WarningTwoTone } from "@ant-design/icons";

import {
  titleFormatter,
  isAnyStepDisabled,
  generateStepStatus,
  generateStepProps,
} from "./helpers";
import {
  MODAL_TITLE,
  NO_STEPS_MESSAGE,
  STEPS_INFO_MESSAGE,
  AUTOMATION_INFO_MESSAGE,
  ALERT_ERROR_MESSAGE,
  FIELD_NAME,
} from "./helpers/constants";
import { Footer } from "./components";
import InfoMessage from "../InfoMessage/InfoMessage";
import AutomateSteps from "./components/AutomateSteps";
import { newStepComponents } from "./helpers/newModals";
import { operations } from "./helpers/autoCreationActions";
import CustomCheckBox from "../CustomCheckBox/CustomCheckBox";
import { useNextSteps, useActiveStep } from "./hooks";
import { useProgressComponent } from "src/hooks";

import "./NextSteps.scss";
import ProgressComponent from "../ProgressComponent/ProgressComponent";

const NextSteps = ({ isOpen, handleStepExit, title, ...props }) => {
  const [isAutomation, setIsAutomation] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();

  const { savedRecord, handleSavedRecord = () => {}, projectData } = props;

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    setCreationProgresses,
  } = useProgressComponent({
    isNextStep: true,
    categoryName: "NextSteps",
    actionType: "Create",
  });

  const {
    inputData,
    setInputData,
    selectedSteps,
    setSelectedSteps,
    performAutoCreation,
    onStepChangeHandler,
  } = useNextSteps({
    form,
    title,
    projectData,
    handleSavedRecord,
    setCreationProgresses,
  });

  const { setIsSaved, showComponent, setShowComponent } = useActiveStep({
    handleStepExit,
    selectedSteps,
  });

  const componentClickHandler = () => {
    setShowComponent(false);
    setSelectedSteps((prevSteps) => {
      return prevSteps.slice(1);
    });
  };

  const { programFields } = useSelector((state) => state.programFields);

  const fieldOptions =
    programFields.find((field) => field.fieldName === FIELD_NAME)
      ?.fieldOptions || [];

  const fieldData = fieldOptions.find((option) => option.category === title);

  const isEnabled = fieldData?.isEnabled || false;
  const allSteps = fieldData?.steps || [];

  const steps = allSteps.map((step) => ({
    ...step,
    stepAutoCreation: operations?.[step.name],
    disabled: !!!operations?.[step.name],
    stepProps: generateStepProps({ componentClickHandler }),
  }));

  const isStepModalOpen = isOpen && steps?.length > 0;

  return (
    <Modal
      {...{
        open: isStepModalOpen && isEnabled,
        title: MODAL_TITLE,
        onCancel: handleStepExit,
        className: "next-steps-container",
        footer: (
          <Footer
            {...{
              onClick: () => {
                setVisibleCreationProgress(true);
                if (isAutomation) {
                  performAutoCreation(savedRecord);
                  return;
                }

                setShowComponent(true);
                setIsSaved(true);
              },
              onAutoCreationClick: () => setIsAutomation((prev) => !prev),
              onCancel: handleStepExit,
              disabled: selectedSteps.length === 0,
              showNext: isAutomation && currentStep < selectedSteps.length - 1,
              isAutomation,
              disabledAutomation: isAnyStepDisabled(selectedSteps),
              back: isAutomation && currentStep > 0,
              onNextClick: () => setCurrentStep((prev) => prev + 1),
              onBackClick: () => setCurrentStep((prev) => prev - 1),
            }}
          />
        ),
      }}
    >
      <InfoMessage
        text={
          !isAutomation
            ? `${titleFormatter(title)} ${STEPS_INFO_MESSAGE}`
            : AUTOMATION_INFO_MESSAGE
        }
      />
      {steps.length === 0 && (
        <div className="no-steps-container">{NO_STEPS_MESSAGE} </div>
      )}
      <div className="steps-body">
        {!isAutomation ? (
          <div className="steps-checkbox-wrapper">
            {steps.map((step) => {
              const { isChecked, isDisabled, toolTipText } = generateStepStatus(
                step,
                selectedSteps
              );

              return (
                <div key={step.id} style={{ display: "flex", gap: "10px" }}>
                  <CustomCheckBox
                    key={step.id}
                    checked={isChecked}
                    disabled={isDisabled}
                    value={false}
                    name={step.name}
                    toolTipText={toolTipText}
                    onChange={(e) =>
                      onStepChangeHandler(e.target.checked, step)
                    }
                    style={{ width: "50%" }}
                  />
                  {step.disabled && isChecked && (
                    <Tooltip title={ALERT_ERROR_MESSAGE}>
                      <WarningTwoTone
                        twoToneColor="red"
                        style={{ fontSize: "25px" }}
                      />
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <Form form={form}>
            <AutomateSteps
              {...{
                form,
                category: title,
                inputData,
                savedRecord,
                projectData,
                currentStep,
                setInputData,
                selectedSteps,
                setCurrentStep,
              }}
            />
          </Form>
        )}
      </div>
      <div className="step-modal-wrapper">
        {showComponent &&
          selectedSteps?.[0]?.component &&
          (() => {
            const Component = newStepComponents[selectedSteps?.[0].component];
            return (
              <Component
                {...{
                  ...props,
                  isNextStep: true,
                  open: showComponent,
                  setOpen: componentClickHandler,
                  visible: showComponent,
                  setVisible: componentClickHandler,
                  handleExit: componentClickHandler,
                  showCreatePaymentModal: showComponent,
                  newTaskModal: showComponent,
                  projectId: props?.projectData?.projectId,
                  ...selectedSteps?.[0]?.stepProps,
                }}
              />
            );
          })()}
      </div>

      {visibleCreationProgress && creationProgresses && isAutomation && (
        <ProgressComponent
          {...{
            categoryName: "Next Steps",
            visibleCreationProgress,
            closeModal: () => {
              setVisibleCreationProgress(false);
              handleStepExit();
            },
            creationProgresses,
          }}
        />
      )}
    </Modal>
  );
};

export default NextSteps;
