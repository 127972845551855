import { dayDetail } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";

function filterProjectsByForeman(rowData, foreman) {
  const filteredProjects = [];
  const schedulesForForeman = [];

  rowData?.forEach((project) => {
    const projectSchedules = project?.schedules || [];

    projectSchedules.forEach((schedule) => {
      const scheduleCrews = schedule?.scheduleCrews || [];
      const scheduleDays = schedule?.scheduleDays || [];

      let isForemanInCrews = scheduleCrews?.find(
        (crew) => crew?.crewName === foreman
      );
      if (isForemanInCrews) {
        isForemanInCrews = {
          ...isForemanInCrews,
          days: isForemanInCrews?.days?.map((day) => {
            return {
              ...day,
              dayServices:
                Object.values(
                  dayDetail(schedule?.toBeScheduled, [day?.dayId], true) || {}
                )
                  ?.flat()
                  ?.flatMap(({ services }) => services)
                  ?.map(({ serviceName }) => serviceName) || [],
            };
          }),
        };
      }

      const daysWithForeman = scheduleDays
        ?.filter((day) =>
          day?.crews?.some((crew) => crew?.crewName === foreman)
        )
        ?.map((day) => ({
          startDate: day?.startDate,
          endDate: day?.endDate,
          dayId: day?.id,
          dayServices:
            Object.values(
              dayDetail(schedule?.toBeScheduled, [day?.id], true) || {}
            )
              ?.flat()
              ?.flatMap(({ services }) => services)
              ?.map(({ serviceName }) => serviceName) || [],
        }));

      if (isForemanInCrews) {
        filteredProjects.push(project);
        schedulesForForeman.push({
          projectId: schedule?.projectId,
          days: isForemanInCrews?.days || [],
          scheduleTotalProgressPercentage:
            schedule?.scheduleTotalProgressPercentage || 0,
          scheduleId: schedule?.scheduleId,
          typeOfWork: schedule?.typeOfWork,
          googleDriveFolderIds: schedule?.googleDriveFolderIds,
          toBeScheduled: schedule?.toBeScheduled,
          est: schedule?.est,
          teamConfiguration: schedule?.teamsConfiguration,
        });
        return;
      }

      if (daysWithForeman?.length > 0) {
        filteredProjects.push(project);
        schedulesForForeman.push({
          projectId: schedule?.projectId,
          days: daysWithForeman,
          scheduleTotalProgressPercentage:
            schedule?.scheduleTotalProgressPercentage,
          scheduleId: schedule?.scheduleId,
          typeOfWork: schedule?.typeOfWork,
          googleDriveFolderIds: schedule?.googleDriveFolderIds,
          toBeScheduled: schedule?.toBeScheduled,
          est: schedule?.est,
          teamConfiguration: schedule?.teamsConfiguration,
        });
      }
    });
  });

  const uniqueProjects = [...new Set(filteredProjects)]?.map((project) => {
    return {
      ...project,
      schedules: schedulesForForeman?.filter(
        (schedule) => schedule?.projectId === project?.projectId
      ),
    };
  });

  return uniqueProjects;
}

export default filterProjectsByForeman;
