import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";
import { filterProjectsByForeman } from "../Components/FieldDashboardTable/helpers";
import fetchToBeScheduledDataForeman from "./fetchToBeScheduledDataForeman";
import dayjs from "dayjs";

async function fetchForemanRowData({ userConfiguration }) {
  try {
    const [projectResponse, scheduleResponse] = await Promise.all([
      fetchAllData("projects", "projects", "projectId"),
      fetchAllData("scheduling", "scheduling", "scheduleId"),
    ]);

    const projectData = projectResponse;
    const scheduleData = [];
    if (!projectData?.length) {
      throw new Error("No projects found!");
    }
    const promises = scheduleResponse?.map(async (schedule) => {
      try {
        const { toBeScheduled = {}, est = [] } =
          await fetchToBeScheduledDataForeman(schedule?.scheduleId);
        return { ...schedule, toBeScheduled, est };
      } catch (error) {
        console.error("Error processing schedule:", error);
        throw new Error(`Failed to fetch`, error);
      }
    });

    try {
      await Promise.all(promises).then((res) => {
        scheduleData.push(...res);
      });
    } catch (error) {
      console.error("Error processing schedules:", error);
      throw new Error(`Failed to fetch`, error);
    }

    const projectsWithSchedules = projectData?.map?.((project) => {
      const schedulesForProject = scheduleData?.filter(
        (schedule) => schedule?.projectId === project?.projectId
      );

      return {
        ...project,
        schedules: schedulesForProject?.sort(
          (a, b) => dayjs(a?.createdAt) - dayjs(b?.createdAt)
        ),
      };
    });

    return filterProjectsByForeman(
      projectsWithSchedules,
      userConfiguration?.nameOfUser
    );
  } catch (error) {
    console.error("Error fetching data", error);
    throw new Error("Error getting data!");
  }
}

export default fetchForemanRowData;
