export const approvals = ({ tables, userConfiguration }) => {
  const reducedUsers = userConfiguration?.allUsers?.Items?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr?.cognitoUserId]: curr,
    }),
    {}
  );

  return {
    userRequesting: () => {
      const data = repeatedElementSum(
        tables?.Approvals || [],
        "userRequesting"
      );

      return {
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / tables?.Approvals?.length) * 100).toFixed(1),
          showInfo: true,
          status: reducedUsers?.[key]?.nameOfUser || key,
          done: value,
        })),
      };
    },

    assignedTo: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "assignedTo");

      return {
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / tables?.Approvals?.length) * 100).toFixed(1),
          showInfo: true,
          status: reducedUsers?.[key]?.nameOfUser || key,
          done: value,
        })),
      };
    },

    category: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "category");

      return {
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / tables?.Approvals?.length) * 100).toFixed(1),
          showInfo: true,
          status: key?.capitalize() || key,
          done: value,
        })),
      };
    },

    responseFrom: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "responseFrom");

      return {
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / tables?.Approvals?.length) * 100).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      };
    },

    requestType: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "requestType");

      return {
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / tables?.Approvals?.length) * 100).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      };
    },
  };
};
