import { roundToDecimals } from "../../../../../../../../../../utils";

import { message } from "antd";

//handles the new value that we put on input
export const inputChangerHandler = ({ e, setRetainageState }) => {
  let initialValue = 0;
  try {
    initialValue = Function("return " + e?.target?.value)();
  } catch (e) {
    initialValue = 0;
    message.warning("Invalid input");
  }

  if (initialValue < 0) {
    setRetainageState(0);
    message.warning("Input less than 0!!");
  } else if (initialValue > 100) {
    setRetainageState(100);
    message.warning("Input greater than 100!!");
  } else setRetainageState(initialValue);
};

export const changeRetainageHandler = ({
  setIsRetainageModalVisible,
  dispatchContext,
  retainageState,
  fakeSOV,
  service,
}) => {
  const newSOV = {
    ...fakeSOV,
    // Find the service that we are changing retainage
    services: fakeSOV?.services.map((sovService) =>
      sovService.serviceId.toString() === service.serviceId.toString() &&
      sovService.estimationId === service.estimationId
        ? {
            ...sovService,
            amounts: sovService?.amounts?.map((event) => {
              const rate = event.rate; // Initial rate of event
              const amount = roundToDecimals(
                (sovService.totalPrice * rate) / 100,
                2
              ); // Calculate amount based on rate
              const retainage = roundToDecimals(
                (amount * retainageState) / 100,
                2
              ); // Calculate retainage
              const collectable_amount = roundToDecimals(amount - retainage, 2); // Get collectable amount without retainage
              console.log("event", retainageState);
              const retainagePercentage = retainageState; // Calculate retainage percentage

              return {
                ...event,
                retainage,
                collectable_amount,
                retainagePercentage,
              };
            }),
            retainage: retainageState,
          }
        : sovService
    ),
  };

  console.log("new sov", newSOV);
  // Set new SOV
  dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });

  // closeRetainageModal()
};
