import { useState, useRef, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal as AntdModal, Form, Button, Upload, message } from "antd";
import { GoogleMap, Polygon, DrawingManagerF } from "@react-google-maps/api";
import _ from "lodash";
import axios from "axios";
import { Buffer } from "buffer";
import { v4 as uuidv4 } from "uuid";
import { HistoryOutlined, UploadOutlined } from "@ant-design/icons";

import { driveApi } from "src/integrations/DriveRequest";
import useProgress from "../../../../../hooks/useProgress";
import { fetchAllData, filterTables, lazyFetch } from "../../../../../utils";
import useResponsive from "../../../../../hooks/useResponsive";
import useLogManagement from "../../../../../hooks/useLogManagement";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import { MAP_THEME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { getCoordinatesAndZip } from "src/components/SidebarPages/Fleet/utils";
import broadcastNotification from "src/helpers/controllers/broadcastNotification";
import { FleetType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { linxupEndpoints } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { loadLivePreference } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import {
  InputComponent,
  SimplePlacesInput,
} from "../../../../SidebarPages/Fleet/components";
import { MondayButton, MultiLevelTreeLogs } from "../../../../commonComponents";
import { UploadedFilesRenderer } from "../../../../Header/forms/PermitDrawingModal/components";
import { EditIcon } from "src/icons";
import { TickIcon } from "../Roles/src";
import { NewIcon } from "../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { AddIcon } from "src/components/Header/components/GoogleEvents/assets";

import "./Modal.scss";
import { useDispatchProgramFields } from "../../../../../hooks";

const VENDOR_FIELD_ID = "43dgdfgd-5645--46545nvbnvb";

/**
 * @typedef {Array<{lat: number, lng: number}>} PolygonPath
 */

/**
 * @typedef GeofenceInfo
 * @property {string} shapeId
 * @property {"Polygon"|"Circle"} type
 * @property {string} title
 * @property {string} description
 * @property {number} createdAt
 * @property {string} createdBy
 * @property {PolygonPath} geoFenceInfo
 */

/**
 * @typedef OptionType
 * @property {string} [id]
 * @property {string} [key]
 * @property {string} value
 * @property {string} [label]
 * @property {PolygonPath} [points]
 */

export const Modal = ({
  type,
  data,
  fieldId,
  onCancel,
  mapLoaded,
  vendorFences,
  initialValues,
  setDefaultFields,
  updateVendorFences,
  ...rest
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { mobile } = useResponsive();

  const [editLogs, setEditLogs] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { startProgress, endProgress } = useProgress();
  const [logsVisible, setLogsVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [vehicles, setVehicles] = useState(
    /** @type {Array<Pick<FleetType, "fleetId"|"fleetName">>} */ ([])
  );
  const [overlayFence, setOverlayFence] = useState(
    /** @type {PolygonPath} */ ([])
  );
  const [map, setMap] = useState(/** @type {google.maps.Map} */ (null));
  const [allVendors] = useState(
    (
      programFields?.find(({ fieldId }) => fieldId === VENDOR_FIELD_ID)
        ?.fieldOptions || []
    ).filter(({ vendorId }) => vendorId !== initialValues?.vendorId)
  );

  const [linkId, setLinkId] = useState(
    initialValues?.geoFenceInfo?.[0]?.["shapeId"] || ""
  );
  const [vendorTypesField, setVendorTypesField] = useState(
    programFields.find(({ fieldName }) => fieldName === "Vendor Types")
  );
  const [drawingManager, setDrawingManager] = useState(
    /** @type {google.maps.drawing.DrawingManager} */ (null)
  );
  const [vendorFence, setVendorFence] = useState(
    /** @type {PolygonPath} */ (
      initialValues?.geoFenceInfo?.[0]?.["geoFenceInfo"] || []
    )
  );
  const [canSavePolygon, setCanSavePolygon] = useState(
    /** @type {google.maps.Polygon} */ (null)
  );

  const markerRef = useRef(
    new google.maps.Marker({ clickable: false, visible: false })
  );

  const driveRequest = driveApi({ accessToken });

  const [form] = Form.useForm();
  const vendorType = Form.useWatch("vendorType", form);

  const { handleGenerateNewLog } = useLogManagement();

  const { dispatchFields, error } = useDispatchProgramFields(
    fieldId,
    "Vendors"
  );
  const { dispatchFields: dispatchVendorType } = useDispatchProgramFields(
    vendorTypesField?.fieldId,
    "Vendor Types"
  );

  const fenceOptions = useMemo(() => {
    if (!vendorFences || !Array.isArray(vendorFences)) {
      return [];
    }

    const fenceGroups = _.groupBy(vendorFences, ({ fenceGroup }) => fenceGroup);
    const options = [];

    for (const group in fenceGroups) {
      options.push({
        label: group,
        options: fenceGroups[group].map((fence) => ({
          label: fence.name,
          value: fence.name,
          id: fence.geofenceUUID,
          points: fence.points.map(({ latitude: lat, longitude: lng }) => ({
            lat,
            lng,
          })),
        })),
      });
    }

    const vendorsIndex = options.findIndex(({ label }) =>
      label?.toLocaleLowerCase()?.includes("vendor")
    );
    if (vendorsIndex > -1) {
      options.splice(0, 0, options.splice(vendorsIndex, 1)[0]);
    }

    return options;
  }, [vendorFences]);

  useEffect(() => {
    lazyFetch({
      tableName: "fleet",
      listOfKeys: ["fleetName", "fleetId"],
    })
      .then(setVehicles)
      .catch((err) => {
        console.log("Error getting vehicles: ", err);
      });
  }, []);

  function handleValuesChange() {
    setHasChanges(true);
  }

  async function getLogs() {
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: initialValues?.vendorId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((res) => {
        const logsWithoutGoogleDriveFolderId = res.map((log) => {
          if (log.currentData && log.previousData) {
            return {
              ...log,
              currentData: _.omit(log.currentData, [
                "googleDriveFolderId",
                "vendorId",
              ]),
              previousData: _.omit(log.currentData, [
                "googleDriveFolderId",
                "vendorId",
              ]),
            };
          }
          return log;
        });
        setEditLogs(logsWithoutGoogleDriveFolderId);
        endProgress();
      })
      .catch((err) => console.log({ err }));
  }

  async function uploadInDrive() {
    return await Promise.all(
      uploadedFiles.map(async (file) => {
        return await driveRequest
          .uploadFile(
            file?.blob?.split(",")[1],
            file?.type,
            {
              name: file?.name,
              mimeType: file?.type,
              writersCanShare: true,
              parents: ["1XOeFwXmYIhxaDvwtrrSzrEq7N9W5VTYX"],
            },
            true
          )
          .then(async (res) => {
            let { id, name } = await res.json().then(({ id, name }) => ({
              id,
              name,
            }));
            return { id, name };
          })
          .catch((e) => console.log(e));
      })
    );
  }

  function sendNotification(type, body) {
    broadcastNotification("102", type, body, "/");
  }

  async function addVendor() {
    const fenceId = await handleFenceChange();
    if (!fenceId && process.env.NODE_ENV === "production") {
      message.error({
        content: "Something went wrong while updating the geofence",
        key: "geofenceAction",
      });
      return;
    }

    const results = form.getFieldsValue();

    let addressPosition = null;
    try {
      const { coordinates } = await getCoordinatesAndZip(results.vendorAddress);
      addressPosition = coordinates;
    } catch (err) {
      console.log("Error getting position: ", err);
      message.error({
        content: "Something went wrong while saving vendor",
        key: "positionError",
      });

      return;
    }

    const googleDriveFolderId = await uploadInDrive();
    const vendorId = uuidv4();
    const newData = {
      ...results,
      googleDriveFolderId,
      vendorId,
      addressPosition,
      geoFenceInfo: [getGeoFenceInfo(fenceId)],
      createdAt: Date.now(),
    };

    const createData = [newData, ...data];

    dispatchFields(createData).then(() => {
      setDefaultFields(createData);
    });

    sendNotification("onCreateVendor", [
      {
        common: userConfiguration.nameOfUser,
        commonNext: results.vendorName,
      },
      {
        userName: userConfiguration.nameOfUser,
        currentUser: userConfiguration.cognitoUserId,
        recordId: vendorId,
      },
    ]);

    handleGenerateNewLog?.(
      "Create",
      "Create",
      {},
      newData,
      "Vendors",
      vendorId,
      "Status Of Vendor"
    );

    if (!error) {
      onCancel();
    }
  }

  /**
   * @return {GeofenceInfo}
   */
  function getGeoFenceInfo(fenceId) {
    return {
      type: "Polygon",
      description: "",
      shapeId: fenceId,
      geoFenceInfo: vendorFence,
      createdBy: userConfiguration.nameOfUser,
      title: form.getFieldValue("vendorAddress"),
      createdAt: initialValues?.geoFenceInfo?.[0]?.createdAt || Date.now(),
    };
  }

  async function updateVendor() {
    const fenceId = await handleFenceChange();
    if (!fenceId && process.env.NODE_ENV === "production") {
      message.error({
        content: "Something went wrong while updating the geofence",
        key: "geofenceAction",
      });
      return;
    }

    const results = form.getFieldsValue();

    let addressPosition = null;
    try {
      const { coordinates } = await getCoordinatesAndZip(results.vendorAddress);
      addressPosition = coordinates;
    } catch (err) {
      console.log("Error getting position: ", err);
      message.error({
        content: "Something went wrong while saving vendor",
        key: "positionError",
      });

      return;
    }

    let googleDriveFolderId = await uploadInDrive();

    if (initialValues?.googleDriveFolderId) {
      googleDriveFolderId = googleDriveFolderId.concat(
        initialValues.googleDriveFolderId
      );
    }

    const updateData = {
      ...results,
      googleDriveFolderId,
      createdAt: initialValues.createdAt,
      vendorId: initialValues.vendorId,
      addressPosition,
      geoFenceInfo: [getGeoFenceInfo(fenceId)],
      updatedAt: Date.now(),
    };

    const updatedData = data.map((vendor) => {
      if (vendor.vendorId === initialValues.vendorId) {
        return updateData;
      }
      return vendor;
    });

    dispatchFields(updatedData).then(() => {
      setDefaultFields(updatedData);
    });

    const prevVal = data.find(
      (vendor) => vendor.vendorId === initialValues.vendorId
    );

    sendNotification("onUpdateVendor", [
      {
        common: userConfiguration.nameOfUser,
        commonNext: initialValues.vendorName,
      },
      {
        userName: userConfiguration.nameOfUser,
        currentUser: userConfiguration.cognitoUserId,
        recordId: initialValues.vendorId,
      },
    ]);

    setHasChanges(false);
    handleGenerateNewLog?.(
      "Edit",
      "Edit",
      prevVal,
      updateData,
      "Vendors",
      initialValues?.vendorId,
      "Status Of Vendor"
    );

    if (!error) {
      onCancel();
    }
  }

  async function handleOnSave() {
    if (!vendorFence.length) {
      message.warning({
        content: "Please draw the vendor geofence before saving",
        key: "noSaveBcFence",
      });
      return;
    }

    if (
      linkId &&
      allVendors?.find(
        ({ geoFenceInfo }) => geoFenceInfo?.[0]?.["shapeId"] === linkId
      )
    ) {
      message.warning({
        content:
          "Another vendor is already linked with this geofence, please select another one",
        key: "noSaveBcFence",
      });
      return;
    }

    try {
      await form.validateFields();
      type === "Create" ? addVendor() : updateVendor();
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  }

  function beforeUploadFunction(file) {
    let { uid, name, type } = file;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ({ target: { result } }) => {
      setUploadedFiles((prev) => [
        ...prev,
        {
          id: uid,
          name,
          type,
          size: Buffer.from(result.substring(result.indexOf(",") + 1)).length,
          blob: result,
          status: 400,
        },
      ]);
    };
    return false;
  }

  async function addVendorType(vendorType) {
    const newFields = [...(vendorTypesField?.fieldOptions || []), vendorType];

    await dispatchVendorType(newFields)
      .then(() => {
        setVendorTypesField({
          ...vendorTypesField,
          fieldOptions: newFields,
        });
      })
      .catch((err) => {
        console.log("Error updating field: ", err);
      });
  }

  function toggleDrawing() {
    if (!map || !drawingManager) {
      return;
    }

    if (!isDrawing) {
      if (
        !form.getFieldValue("vendorAddress") ||
        !markerRef.current.getPosition()
      ) {
        message.warning({
          content: "Please select a valid vendor address!",
          key: "noAddress",
        });

        return;
      }

      drawingManager.setMap(map);
      setOverlayFence([...vendorFence]);
    } else {
      drawingManager.setMap(null);
      setOverlayFence([]);
    }

    setIsDrawing((prev) => !prev);

    if (canSavePolygon) {
      canSavePolygon.setMap(null);
      canSavePolygon.setVisible(false);

      setCanSavePolygon(null);
    }
  }

  function onSaveFence() {
    let newPath = canSavePolygon
      .getPath()
      .getArray()
      .map((path) => ({
        lat: path.lat(),
        lng: path.lng(),
      }));

    canSavePolygon.setVisible(false);
    canSavePolygon.setMap(null);
    setHasChanges(true);
    setVendorFence(newPath);
    toggleDrawing();
  }

  /**
   * @param {string} address
   * @param {google.maps.Map} [mapInstance]
   * @param {OptionType} [option]
   */
  async function onAddressSearch(address, mapInstance = null, option) {
    if ((!map && !mapInstance) || !markerRef.current) {
      return;
    }

    if (!address) {
      return;
    }

    message.loading({
      content: "Getting location...",
      key: "locationGet",
      duration: 0,
    });

    try {
      const { coordinates } = await getCoordinatesAndZip(address);

      message.destroy("locationGet");

      markerRef.current.setPosition(coordinates);
      markerRef.current.setVisible(true);
      (mapInstance || map).panTo(coordinates);
      (mapInstance || map).setZoom(17);

      setVendorFence(option?.points || []);

      if (
        option?.id &&
        allVendors?.find(
          ({ geoFenceInfo }) => geoFenceInfo?.[0]?.["shapeId"] === option?.id
        )
      ) {
        message.warning({
          content:
            "Another vendor is already linked with this geofence, please select another one",
          key: "noSaveBcFence",
        });
      }

      setLinkId(option?.id);
    } catch (err) {
      message.error({
        content: "Something went wrong while trying to get address location",
        key: "locationGet",
      });
      console.log("Error loading location: ", err);
    }
  }

  /**
   * Function responsible for comparing the points of two fences
   * @param {PolygonPath} points1
   * @param {PolygonPath} points2
   */
  function areSameFences(points1, points2) {
    if (points1.length !== points2.length) {
      return false;
    }

    return points1.every(({ lat, lng }) =>
      points2.find(
        ({ lat: latitude, lng: longitude }) =>
          lat === latitude && lng === longitude
      )
    );
  }

  async function handleFenceChange() {
    if (process.env.NODE_ENV !== "production") {
      /**
       * ======= DO NOT CHANGE THIS CONDITION =======
       *
       * CREATING GEOFENCES EVEN FOR TESTING PURPOSES
       * CONFLICTS WITH THE WORK OF OTHER DEPARTMENTS
       */
      return Promise.resolve("");
    }

    let updatedFenceObject = null;

    let currentlyLinkedFence;

    if (linkId) {
      currentlyLinkedFence = vendorFences?.find(
        ({ geofenceUUID }) => geofenceUUID === linkId
      );
    }

    /**
     * When we have a linked fence, we will use this condition
     * to determine whether the linxup fence needs updating or not
     */
    const hasChangesInFence = !areSameFences(
      (currentlyLinkedFence?.points || [])?.map(
        ({ latitude: lat, longitude: lng }) => ({
          lat,
          lng,
        })
      ),
      vendorFence
    );

    /**
     * If we didn't link the vendor with an existing geofence,
     * then we need to create this new fence
     */
    if (!linkId) {
      message.loading({
        content: "Creating geofence...",
        key: "geofenceAction",
        duration: 0,
      });

      updatedFenceObject = await axios
        .post(linxupEndpoints.newGeofence, {
          geofenceInfo: {
            name: form.getFieldValue("vendorAddress"),
            fenceGroup: "Vendor",
            points: vendorFence?.map(({ lat, lng }) => ({
              latitude: lat,
              longitude: lng,
            })),
          },
        })
        .then(({ data }) => {
          updateVendorFences(data, "ADD");

          message.destroy("geofenceAction");
          return data?.geofenceUUID;
        })
        .catch((err) => {
          console.log("Error creating geofence: ", err);
          return null;
        });
    } else {
      if (!hasChangesInFence) {
        return linkId;
      }

      message.loading({
        content: "Updating geofence...",
        key: "geofenceAction",
        duration: 0,
      });

      updatedFenceObject = await axios
        .put(`${linxupEndpoints.geofences}/${linkId}`, {
          name: form.getFieldValue("vendorAddress"),
          type: "Polygon",
          fenceGroup: currentlyLinkedFence?.fenceGroup || "Vendor",
          status: currentlyLinkedFence?.status || "act",
          radius: undefined,
          points: vendorFence?.map(({ lat, lng }) => ({
            latitude: lat,
            longitude: lng,
          })),
        })
        .then(({ data }) => {
          updateVendorFences(data, "EDIT");

          message.destroy("geofenceAction");
          return data.geofenceUUID;
        })
        .catch((err) => {
          console.log("Error updating fence: ", err);
          return null;
        });
    }

    return updatedFenceObject;
  }

  return (
    <div>
      <AntdModal
        {...rest}
        closeIcon={<XIcon />}
        onCancel={onCancel}
        destroyOnClose={true}
        className={`usNationalEditModal ${
          isDarkMode && "usNationalEditModalDark"
        }`}
        centered
        footer={
          <>
            <MondayButton
              className="mondayButtonRed"
              onClick={onCancel}
              Icon={<XIcon />}
            >
              Cancel
            </MondayButton>
            <div className="logButtons">
              {type !== "Create" && (
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<HistoryOutlined />}
                  onClick={() => {
                    setLogsVisible(true);
                    getLogs();
                    startProgress(10);
                  }}
                >
                  Logs
                </MondayButton>
              )}
              <MondayButton
                onClick={handleOnSave}
                className="mondayButtonGreen"
                Icon={
                  type === "Edit" ? (
                    <TickIcon width={18} height={18} />
                  ) : (
                    <NewIcon />
                  )
                }
                disabled={!hasChanges || isDrawing || canSavePolygon}
              >
                {type === "Edit" ? "Save" : "Create"}
              </MondayButton>
            </div>
          </>
        }
      >
        <Form
          form={form}
          layout="vertical"
          className={`form ${isDarkMode && "formDark"}`}
          initialValues={type === "Edit" && initialValues}
          onValuesChange={handleValuesChange}
        >
          <div className="vendor-info-container">
            <div className="basic-vendor-info">
              <InputComponent
                {...{
                  label: "Vendor Name",
                  formItemName: "vendorName",
                  placeholder: "Vendor Name...",
                  required: true,
                  className: "labelInput",
                }}
              />
              <InputComponent
                {...{
                  label: "Vendor Status",
                  required: true,
                  placeholder: "Vendor Status...",
                  formItemName: "vendorStatus",
                  type: "select",
                  dropdownClassName: isDarkMode ? "darkDropDown" : undefined,
                  customOptions: [{ value: "Active" }, { value: "Inactive" }],
                }}
              />
              <InputComponent
                {...{
                  label: "Vendor Type",
                  type: "predictAutocomplete",
                  required: true,
                  allowClear: true,
                  formItemName: "vendorType",
                  addNewClick(e) {
                    addVendorType(e);
                  },
                  customOptions: (vendorTypesField?.fieldOptions || []).map(
                    (e) => ({
                      label: e,
                      value: e,
                    })
                  ),
                }}
              />
              {vendorType === "Home" && (
                <InputComponent
                  {...{
                    label: "Match Vehicle",
                    type: "select",
                    formItemName: "fleetId",
                    required: false,
                    customOptions: vehicles.map(({ fleetId, fleetName }) => ({
                      label: fleetName,
                      value: fleetId,
                    })),
                    allowClear: true,
                    showSearch: true,
                  }}
                />
              )}
            </div>
            <div className="vendor-upload-section">
              {type === "Edit" && (
                <div className="existedFileRender" style={{ marginBottom: 10 }}>
                  {UploadedFilesRenderer({
                    node: {
                      data: {
                        googleDriveUploads:
                          typeof initialValues?.googleDriveFolderId === "string"
                            ? { id: initialValues?.googleDriveFolderId }
                            : initialValues?.googleDriveFolderId,
                      },
                    },
                    editModal: true,
                    accessToken,
                  })}
                </div>
              )}
              <Form.Item label="File Uploaded" name="googleDriveFolderId">
                <Upload
                  {...{
                    multiple: true,
                    fileList: uploadedFiles,
                    onPreview: () => {},
                    onRemove: ({ id }) => {
                      setUploadedFiles(
                        uploadedFiles.filter((e) => e.id !== id)
                      );
                    },
                    beforeUpload: beforeUploadFunction,
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </div>
          </div>
          <div className="vendor-map-section">
            <div className="map-control-section">
              <SimplePlacesInput
                {...{
                  dropdownStyle: mobile ? { maxWidth: "80vw" } : "",
                  label: "Vendor address",
                  formItemName: "vendorAddress",
                  placeholder: "Type in the field address",
                  className: "mobil-vendor-address",
                  isCustomValue: true,
                  required: true,
                  popupClassName: isDarkMode ? "darkDropDown" : "",
                  groupedOptions: fenceOptions,
                  onChange(value) {
                    if (!value) {
                      setVendorFence([]);
                      setLinkId();
                      markerRef.current.setPosition();
                      if (isDrawing) {
                        toggleDrawing();
                      }
                    }
                  },
                  onSelect(e, option) {
                    onAddressSearch(e, map, option);
                  },
                }}
              />
              <div className="vendor-controller-buttons">
                {(!isDrawing || canSavePolygon) && (
                  <MondayButton
                    {...{
                      Icon: canSavePolygon ? (
                        <XIcon />
                      ) : vendorFence.length ? (
                        <EditIcon />
                      ) : (
                        <AddIcon />
                      ),
                      className: canSavePolygon
                        ? "mondayButtonRed"
                        : vendorFence.length
                        ? "mondayButtonBlue"
                        : undefined,
                      onClick: toggleDrawing,
                    }}
                  >
                    {canSavePolygon
                      ? "Cancel Fence"
                      : vendorFence.length
                      ? "Edit Fence"
                      : "Create Fence"}
                  </MondayButton>
                )}
                {canSavePolygon && (
                  <MondayButton
                    {...{
                      Icon: <TickIcon />,
                      onClick: onSaveFence,
                    }}
                  >
                    Save Fence
                  </MondayButton>
                )}
              </div>
            </div>
            {mapLoaded && (
              <GoogleMap
                {...{
                  zoom: 14,
                  id: "vendor-map",
                  mapContainerClassName: "vendor-map",
                  center: NYC_CENTER,
                  onLoad(e) {
                    setMap(e);
                    markerRef.current.setMap(e);
                    if (initialValues?.vendorAddress) {
                      onAddressSearch(initialValues?.vendorAddress, e, {
                        id: initialValues?.geoFenceInfo?.[0]?.["shapeId"],
                        points:
                          initialValues?.geoFenceInfo?.[0]?.["geoFenceInfo"] ||
                          [],
                      });
                    }
                  },
                  options: {
                    disableDefaultUI: true,
                    styles: MAP_THEME[loadLivePreference("mapStyle")],
                    mapTypeId: loadLivePreference("mapType"),
                    fullscreenControl: true,
                    clickableIcons: false,
                    rotateControl: true,
                    fullscreenControlOptions: {
                      position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    },
                    streetViewControl: true,
                    streetViewControlOptions: {
                      position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    },
                  },
                }}
              >
                <Polygon
                  {...{
                    options: {
                      map,
                      strokeWeight: 2,
                      editable: false,
                      draggable: false,
                      strokeOpacity: 1,
                      fillOpacity: 0.35,
                      fillColor: "#f6cb51",
                      strokeColor: "#f6cb51",
                    },
                    paths: vendorFence,
                    visible: !isDrawing,
                  }}
                />
                <Polygon
                  {...{
                    options: {
                      editable: false,
                      strokeWeight: 2,
                      draggable: false,
                      fillOpacity: 0.2,
                      strokeOpacity: 0.7,
                      fillColor: "#d99f00",
                      strokeColor: "#d99f00",
                    },
                    visible: !!overlayFence?.length,
                    paths: overlayFence,
                  }}
                />
                <DrawingManagerF
                  {...{
                    options: {
                      polygonOptions: {
                        strokeWeight: 2,
                        fillOpacity: 0.4,
                        fillColor: "#52c41a",
                        strokeColor: "#52c41a",
                      },
                      drawingControlOptions: {
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                      },
                      drawingMode: isDrawing
                        ? google.maps.drawing.OverlayType.POLYGON
                        : null,
                    },
                    onLoad(e) {
                      setDrawingManager(e);
                      e.setMap(null);
                    },
                    onPolygonComplete: setCanSavePolygon,
                  }}
                />
              </GoogleMap>
            )}
          </div>
        </Form>
      </AntdModal>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: editLogs || [],
            title: "Vendors",
          }}
        />
      )}
    </div>
  );
};
