import { useState } from "react";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { Topics } from "../../../../../settingsCommonComponents";
import { SearchOutlined } from "@ant-design/icons";
import { Switcher } from "../../../../../../../commonComponents";

const NotificationPermissions = ({
  accessConfiguration,
  notificationSettings,
  setAccessConfiguration,
}) => {
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [searchValue, setSearchValue] = useState({
    action: "",
    notification: "",
  });

  const selectedTitle = notificationSettings.find(
    (notification) => notification.topicId === selectedTopicId
  )?.topicName;

  const selectedTopicNotificationConfiguration =
    accessConfiguration?.["Notification Preferences"]?.notifications?.topics?.[
      selectedTopicId
    ];

  const notificationConfiguration = Object.entries(
    selectedTopicNotificationConfiguration || {}
  ).map(([key, value]) => ({
    title: key,
    value,
  }));

  const onNotificationPreferenceChange = (value, titleKey) => {
    setAccessConfiguration((prev) => ({
      ...prev,
      ["Notification Preferences"]: {
        ...prev["Notification Preferences"],
        notifications: {
          ...prev["Notification Preferences"].notifications,
          topics: {
            ...prev["Notification Preferences"].notifications.topics,
            [selectedTopicId]: {
              ...prev["Notification Preferences"].notifications.topics[
                selectedTopicId
              ],
              [titleKey]: value,
            },
          },
        },
      },
    }));
  };

  return (
    <div className="notification-preferences-tab">
      <div className="preferencesCollapseLeftSide">
        <Topics
          {...{
            topics: notificationSettings.map(({ topicName }) => topicName),
            hasCollapse: false,
            selectedTopic: notificationSettings?.find(
              ({ topicId }) => topicId === selectedTopicId
            )?.topicName,
            setSelectedTopic: (topic) =>
              setSelectedTopicId(
                notificationSettings?.find(
                  ({ topicName }) => topicName === topic
                )?.topicId
              ),
          }}
        />
      </div>
      {!!selectedTitle ? (
        <div className="preferencesCollapseRightSide">
          <div className="search-input">
            <InputComponent
              className="preferencesSearch"
              placeholder={`Search Notification...`}
              prefix={<SearchOutlined style={{ color: "#323338" }} />}
              onChange={(e) =>
                setSearchValue((prev) => ({
                  ...prev,
                  notification: e.target.value,
                }))
              }
            />
          </div>
          <div className={`selectedBody `}>
            {notificationConfiguration
              ?.filter((el) =>
                el?.title
                  ?.toLowerCase()
                  ?.includes(searchValue?.notification?.toLowerCase())
              )
              ?.map((notificationConfig) => {
                const { title, value } = notificationConfig;

                return (
                  <Switcher
                    {...{
                      label: title,
                      status: value,
                      onClick: () =>
                        onNotificationPreferenceChange(!value, title),
                    }}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <div className="preferencesCollapseRightSide empty-right-side">
          <p>Select a Topic</p>
        </div>
      )}
    </div>
  );
};

export default NotificationPermissions;
