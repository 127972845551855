import "./AutomationNotificationConfig.scss";
import { useSelector } from "react-redux";
import { CustomCheckBox } from "../../../../commonComponents";

const AutomationNotificationConfig = ({
  automationPreferences,
  updateAutomationNotifications,
}) => {
  const { programFields } = useSelector((state) => state.programFields);
  const {
    preferences: { preferences },
  } = useSelector((state) => state.preferences);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const actionFunctions = programFields?.find(
    (prog) => prog.fieldName === "Automation Configuration"
  )?.fieldOptions["Automation Functions"];

  const finalNotifSettings =
    automationPreferences ||
    preferences?.notifications?.automationPreferences ||
    {};

  return (
    <div
      className={`automation-notification-config ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      {actionFunctions.map(({ value }, i) => {
        return (
          <div key={i} className="automation-function">
            <CustomCheckBox
              checked={finalNotifSettings[value] ?? true}
              name={value}
              onChange={(e) => {
                updateAutomationNotifications({
                  ...finalNotifSettings,
                  [value]: e.target.checked,
                });
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AutomationNotificationConfig;
