import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";

export const footerButtons = ({
  onCancel,
  onSave,
  isSaving,
  tooltipCategory = "Vendors",
}) => {
  return [
    {
      text: "Cancel",
      cancel: true,
      onClick: onCancel,
      className: "mondayButtonRed",
      tooltipCategory: tooltipCategory,
      tooltipKey: "cancel",
    },
    {
      text: "Save",
      onClick: onSave,
      disabled: !!isSaving,
      suffix: <TickIcon />,
      className: "mondayButtonBlue saveButton",
      tooltipCategory: tooltipCategory,
      tooltipKey: "finish",
    },
  ];
};
