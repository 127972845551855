import moment from "moment";
import { TreeElement } from "../TreeElement";
import { TreeTextHeader } from "../TreeTextHeader";
import { convertKeyToTitleCase } from "../../utils/convertTitle";
import { parseContent } from "../../utils/parseContent";
import styles from "./TreeItem.module.scss";

const TreeItem = ({ item, actionType, isParent = false }) => {
  return (
    <div style={{ width: "100%" }}>
      {isParent ? (
        <div className={styles["tree-element-item-container"]}>
          <TreeTextHeader
            {...{ text: "Action Type", value: item.actionType }}
          />
          <div className={styles["split-line"]} />
          <TreeTextHeader {...{ text: "User", value: item.nameOfUser }} />
          {item?.recordName && (
            <>
              <div className={styles["split-line"]} />
              <TreeTextHeader
                {...{
                  text: "Record",
                  value: item.recordName,
                }}
              />
            </>
          )}
          <div className={styles["split-line"]} />
          <TreeTextHeader
            {...{
              text: "Updated At",
              value: moment(item.updatedAt)
                .tz("America/New_York")
                .format("MM/DD/YYYY h:mm:ss a"),
            }}
          />
        </div>
      ) : item.children ? (
        // If the item has children, recursively render them
        <div className={styles["tree-element-item-container"]}>
          <TreeTextHeader
            {...{
              value: convertKeyToTitleCase(item.key),
              // value: `${convertKeyToTitleCase(item.key)} ${
              //   item.children && `(${item.children.length})`
              // }`,
              style: {
                // display: "flex",
                alignItems: "center",
                // width: `calc(100% - ${
                //   convertKeyToTitleCase(item.key)?.length * 9
                // }px)`,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "200px",
              },
            }}
          />
        </div>
      ) : (
        // If the item does not have children, display previous and updated values
        <div className={styles["tree-element-child-item-container"]}>
          <TreeTextHeader
            {...{
              value: convertKeyToTitleCase(item.key),
              style: {
                // display: "flex",
                alignItems: "center",
                // width: `calc(100% - ${
                //   convertKeyToTitleCase(item.key)?.length * 9
                // }px)`,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "100%",
                maxWidth: "200px",
              },
            }}
          />
          <div
            style={{
              // width: `calc(100% - ${
              //   convertKeyToTitleCase(item.key)?.length * 9
              // }px)`,
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              // gap: "5px",
              gap: "55px",
            }}
          >
            <TreeElement
              {...{
                text: "Previous",
                itemKey: item.key,
                value:
                  actionType === "Create"
                    ? ""
                    : parseContent(item.previousValue),
              }}
            />
            <TreeElement
              {...{
                text: "Updated",
                itemKey: item.key,
                value:
                  actionType === "Delete"
                    ? ""
                    : parseContent(item.updatedValue),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TreeItem;
