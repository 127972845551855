function getTrucksTooltipTitle(scheduleDayFleet = []) {
  if (!scheduleDayFleet?.length) {
    return <span>No Trucks Selected</span>;
  }
  return (
    <p style={{ width: "fit-content" }}>
      <p
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <strong>TRUCK #</strong>
        <strong>DRIVER</strong>
      </p>
      {scheduleDayFleet?.map(({ fleetName, driverName }, key) => (
        <p
          key={key}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ marginRight: "1rem" }}>{fleetName}</span>
          <span>{driverName}</span>
        </p>
      ))}
    </p>
  );
}

export default getTrucksTooltipTitle;
