import loadLivePreference from "./loadLivePreference";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";

/**
 * Function that filters the stops for all the
 * vehicles, given a certain date
 */
export function filterStopsForDate({
  stops = {},
  date = loadLivePreference("liveSelectedDate"),
}) {
  let data = {};

  for (const fleetId in stops) {
    data[fleetId] = filterStopsForVehicle({ fleetId, stops, date });
  }

  return data;
}

/**
 * Function that filters the stops for
 * one vehicle, given the date
 */
export function filterStopsForVehicle({
  fleetId,
  date = loadLivePreference("liveSelectedDate"),
  stops = {},
}) {
  let data = {};
  let dateStart = dayjsNY(date).startOf("D").valueOf();
  let dateEnd = dayjsNY(date).endOf("D").valueOf();

  if (data?.[fleetId]) {
    for (const stop of stops[fleetId]) {
      if (stop?.beginDate <= dateStart && stop?.endDate >= dateStart) {
        data.push(stop);
        continue;
      }

      if (stop?.beginDate <= dateEnd && stop?.endDate >= dateEnd) {
        data.push(stop);
        continue;
      }

      if (stop?.beginDate >= dateStart && stop?.endDate <= dateEnd) {
        data.push(stop);
        continue;
      }
    }
  }

  return data;
}

/**
 * Function that creates the stop body to be used in the view
 * @param {object} rawStopData The stop as is from the API
 * @param {object} additionalInfo Additional information to add to the body
 * @returns
 */
export function getStopBody(rawStopData, additionalInfo = {}) {
  const {
    deviceUUID,
    beginDate,
    city,
    countryCode,
    duration,
    endDate,
    imei,
    latitude,
    longitude,
    stateCode,
    stopType,
    street,
    stopUUID,
    geofenceName,
    geofenceUUID,
    deviceSerialNumber,
  } = rawStopData;

  return {
    deviceUUID,
    beginDate,
    endDate,
    duration,
    position: { lat: latitude, lng: longitude },
    stopType,
    stopUUID,
    fenceName: geofenceName,
    geofenceUUID,
    imei,
    deviceSerialNumber,
    address: `${street}, ${city}, ${stateCode}, ${countryCode}`,
    ...additionalInfo,
  };
}

/**
 * Function that separates the stop-merging logic
 * @param {object} prev The previous state of the stops
 * @param {object} newStopsByVehicle The stops data for each vehicle
 * @param {Function | undefined} bodyModifier a modifier function
 */
export function mergeStops(prev, newStopsByVehicle, bodyModifier) {
  let newStops = { ...(prev || {}) };
  for (const fleetId in newStopsByVehicle) {
    let prevStops = prev?.[fleetId] || [];
    let tmp = [...(prev?.[fleetId] || [])];
    newStops[fleetId] = tmp;

    for (const stop of newStopsByVehicle[fleetId]) {
      let stopStart = stop?.beginDate;
      let stopEnd = stop?.endDate;
      if (
        !prevStops?.find(
          ({ beginDate, endDate }) =>
            beginDate === stopStart && endDate === stopEnd
        )
      ) {
        tmp.push(bodyModifier ? bodyModifier(stop) : stop);
      }
    }

    tmp.sort((a, b) => a?.beginDate - b?.beginDate);
  }
  return newStops;
}
