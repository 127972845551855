export const sortCheckedAndUnchecked = (updatedHeaders, selectedHeaders) => {
  const checkedHeaders = [];
  const uncheckedHeaders = [];

  updatedHeaders.forEach((header) => {
    const isInSelectedHeaders = selectedHeaders.some((selectedHeader) => {
      return (
        selectedHeader.name === header.name && selectedHeader.id === header.id
      );
    });

    if (isInSelectedHeaders) {
      checkedHeaders.push(header);
    } else {
      uncheckedHeaders.push(header);
    }
  });

  return [...checkedHeaders, ...uncheckedHeaders];
};
