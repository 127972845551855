import { rentalDataExtractor } from "../../../../../../Projects/Accounting/Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/ExportToExcel/exportToExcelFunctions";
import {
  categoriesName,
  categoriesNumberKey,
  categoriesServiceMap,
} from "../../../utils/constants";
import { firstLetterToUpperCase } from "../../ControlPanel/utils/firstLetterToUpperCase";

export const onSelectionChanged = ({
  serviceGroup,
  index,
  onServiceGroupCheckBoxChange,
  selectedCategory,
  setSelectedCategoryGroupNumber,
  categoryData,
}) => {
  let services = serviceGroup[categoriesServiceMap[selectedCategory]];

  onServiceGroupCheckBoxChange({
    isChecked: true,
    services,
    categoryId: serviceGroup.id,
    groupName: `${firstLetterToUpperCase(categoriesName[selectedCategory])} ${
      serviceGroup.number
    }${
      selectedCategory === "applications"
        ? " SOV no. " + serviceGroup?.SOVNo
        : ""
    }`,
    selectedCategory,
    keepPrev: !(index === 0),
    chargeCategoryFrom: serviceGroup?.categoryFrom,
    accumulatedRentals: serviceGroup?.accumulatedRentals,
  });
  if (serviceGroup?.type === "Application") {
    //Adding included rentals
    serviceGroup?.includedRentals?.forEach((rental) => {
      onServiceGroupCheckBoxChange({
        isChecked: true,
        services: rental?.services,
        categoryId: rental?.rentalId,
        groupName: `Rental ${rental?.rentalNumber} > ${firstLetterToUpperCase(
          categoriesName[selectedCategory]
        )} ${serviceGroup.number}${
          selectedCategory === "applications"
            ? " SOV no. " + serviceGroup?.SOVNo
            : ""
        }`,
        selectedCategory: "rentals",
        includedRequisitionId: serviceGroup.id,
        keepPrev: true,
        chargeCategoryFrom: serviceGroup?.categoryFrom,
      });
    });

    //Adding reainage from previuous requisitions
    const applications = categoryData["applications"];
    const PREV_APPLICATIONS = structuredClone(
      applications?.filter(
        (el) => el?.applicationNo < serviceGroup?.applicationNo
      )
    );
    const BEFORE_APPLICATION =
      PREV_APPLICATIONS?.find(
        (a) => a?.applicationNo === serviceGroup?.applicationNo - 1
      ) || {};

    let prevIncRentals = PREV_APPLICATIONS?.map((el) => {
      return rentalDataExtractor({
        fakeApplication: el,
      })
        ?.filter(Boolean)
        ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
        ?.map((e) => {
          const BEFORE_RENTAL_RET =
            BEFORE_APPLICATION?.totalities?.prevRentalsRetainage?.[
              `Rental${e?.rentalNumber}`
            ] ?? e?.retainage;
          const CURRENT_RENTAL_RET =
            serviceGroup?.totalities?.prevRentalsRetainage?.[
              `Rental${e?.rentalNumber}`
            ] ?? e?.retainage;
          return {
            ...e,
            isPrevious: true,
            applicableRetainage: BEFORE_RENTAL_RET - CURRENT_RENTAL_RET,
            retainage: CURRENT_RENTAL_RET,
          };
        });
    }).flatMap((e) => e);

    const sortedRentals = {};
    prevIncRentals?.forEach((el) => {
      if (sortedRentals?.[el?.rentalId])
        return sortedRentals[el?.rentalId]?.push(el);
      return (sortedRentals[el?.rentalId] = [el]);
    });

    Object.keys(sortedRentals)?.map((id) => {
      const services = sortedRentals[id];
      onServiceGroupCheckBoxChange({
        isChecked: true,
        services,
        categoryId: id,
        groupName: `Rental ${
          services[0]?.rentalNumber
        } > ${firstLetterToUpperCase(categoriesName[selectedCategory])} ${
          serviceGroup.number
        }${
          selectedCategory === "applications"
            ? " SOV no. " + serviceGroup?.SOVNo
            : ""
        }`,
        selectedCategory: "rentals",
        includedRequisitionId: serviceGroup.id,
        keepPrev: true,
      });
    });
  }
  setSelectedCategoryGroupNumber(
    serviceGroup[categoriesNumberKey[selectedCategory]]
  );
};
