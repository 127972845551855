import React from "react";
import Swal from "sweetalert2";
import { Button, message, Modal, Popconfirm, Result, Select, Spin } from "antd";
import "./scheduleForm.css";
import "../../formsModal.scss";
import "./newSchedule.scss";
import { WrappedScheduleDays } from "../Subcomponents/WrappedScheduleDays";
import { GET_SCHEDULING_BY_PROJECT, getNeededTables } from "../helpers/apiGet";
import { getTotalWorkingHours } from "../helpers/calculators";
import { getTodayModifiedDate, toOrdinal } from "../helpers/creators";
import ScheduleDay from "../models/ScheduleDay";
import moment from "moment-timezone";
import _, { groupBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  createSchedule,
  saveMultipleSchedules,
} from "../../../../SidebarPages/Scheduling/helpers/apiCalls";
import { get_documentation, get_tasks } from "../PLIPerService/tools/apiGet";

import { filterSchedulesByProject } from "../../../../SidebarPages/Scheduling/helpers/filters";
import {
  getCrewsActivityArray,
  getFleetDispatchArray,
} from "../helpers/parsers";

import ClassRedux from "../../../../SidebarPages/utils/classRedux";
import { CREW_FLEET_POPOVER_TYPE } from "../Subcomponents/SelectView/CrewTrucksSelectPopover";
import {
  get_ProgressDimensionsByServiceId,
  hoist_dimension,
  JUMP_MAXIMAL_PROGRESS,
} from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { MondayButton, Stepper } from "../../../../commonComponents/index";
import { Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/index";
import { SchedulingContext } from "../context/index";

import API from "@aws-amplify/api";
import { connect } from "react-redux";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import { createEventsFromSchedule } from "../../../components/GoogleEvents/googleClientAPI/execute";
import {
  filterTables,
  getCognitosForNotification,
  lazyFetch,
  updateDocumentTitle,
  updateTeamsConfiguration,
} from "../../../../../utils/index";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { LoadableComp } from "../../../../SidebarPages/XComponents/index";
import FormSection from "../SchedulingFirstPage/FormSection";
import PdfProjectPreview from "../../../../SidebarPages/Projects/ExportToExel/PdfProjectPreview";
import NoDataFound from "../../../../SidebarPages/Projects/ExportToExel/NoDataFound";
import { Header } from "./components";
import { WarningMessageModal } from "./components/WarningMessageModal/WarningMessageModal";

import SaveScheduleModal from "./components/CloseModal/SaveScheduleModal";
import HoistInspectionsRaport from "../../../../SidebarPages/Projects/ExportToExel/HoistInspectionsRaport";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { breakdown_dimension } from "../PLIPerService/componentsForPli/Breakdown/breakdown";
import {
  BackIcon,
  PreviousIcon,
  SwitchAutoSaveOff,
} from "../../../../SidebarPages/BasePage/src";
import { driveFolderFunction } from "../Subcomponents/ServiceDocumentation/utils/utils";
import {
  firstScheduleDay,
  mainProcessing,
  newChangesFromEstimationForSchedule,
  notificationForProgressChange,
  onlyEstWithScopeFalse,
  scheduleProgress,
  serviceOptionsForJumps,
  transformOption,
} from "../SchedulingFirstPage/helperData";
import { checkIfServiceIsHoist } from "../../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import DispatchScheduleDayModal from "../Subcomponents/ScheduleDayViews/DispatchScheduleDayModal";
import { RightOutlined, SwitcherOutlined } from "@ant-design/icons";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { LogsIcon } from "src/components/SidebarPages/DynamicView/src";
import { driveApi } from "src/integrations/DriveRequest";
import { SwitchEyeIcon } from "../../../../SidebarPages/DynamicView/src";
import SwitchSchedules from "./SwitchSchedules";
import { getLatLngByAddress } from "../../../../SidebarPages/utils";
import SchedulingProgressTrack from "../Subcomponents/SchedulingProgressTrack/SchedulingProgressTrack";
const dayjs = require("dayjs");
/**
 * This is the schedule modal to create or edit an schedule.
 * It can be open from "new schedule" button in scheduling view, in from new shortcut -> new schedule or from edit schedule inside a schedule view.
 * It have 4 steps in new mode and only the second step in edit mode (which is the WrappedScheduleDays component) where scheduling and progress happens
 * */
// disable eslint for this file
class NewSchedule extends React.Component {
  constructor(props) {
    super(props);

    let schedulesThatBelongToProjectSelected = [];
    let firstPage_InformativeVariables = {
      projectId: this?.props?.projectId ? this?.props?.projectId : "",
      typeOfWork: this?.props?.typeOfWork || "",
      scheduleStatus: "Draft",
      scheduleName: !this.props.editMode
        ? "New Schedule"
        : "Opening schedule...",
      scheduleAddress: this?.props?.projectAddress
        ? this?.props?.projectAddress
        : "",
      totalHours: 0,
      projectManagers: this?.props?.projectManagers || [],
    };

    this.state = {
      dispatchDays: [],
      scheduleCrews: this?.props?.rowData?.scheduleCrews || [],
      openDispatchDays: false,
      schedules: undefined, //will be filled with an array of schedules, taken from props or by a http call
      thisProjectSchedules: [], //schedules which belong to this project
      schedule: null, //in edit mode it will be the schedule we are editing
      serviceDefinitionsData: undefined, //pricing, units, proposedTypeOfWork, serviceDefinitions

      projects: [], //all projects, used when creating a new schedule
      project: undefined, //project that we want to create a schedule for
      missingTasks: [],
      existingTasks: [],
      openOverview: false,
      openOverviewPrev: false,
      openInspectionOverviewprev: false,
      openSaveModal: false,
      noDataFound: false,
      showLoading: false,
      typeOfRemoval: "",
      firstPage_InformativeVariables,
      scheduleDays: [],
      googleDriveFolderIds: this?.props?.rowData?.googleDriveFolderIds || {},
      googleDriveFolderQueue: [],
      totalProgressOfAllSchedule: 0,
      schedulesThatBelongToProjectSelected,
      openProgressLogs: false,
      progressLogs: [],

      //     ], //a list with schedule days. This one represents by default tomorrow
      toBeScheduled: {}, //the services object with schedule style. they have days, progressByDay, totalProgress and more

      fleetInfo: {
        fleet: [],
        drivers: [],
        fleetDispatching: [],
        fleetActivity: [],
        dispatchesChanged: false,
      }, //info from database
      crewsInfo: { crews: [], crewActivity: [], crewsChanged: false }, //from database.

      documentAssignedTo: "",
      missingDocuments: [],
      fullDocuments: [],
      scheduleNotes: [], //notes like all other entities in program

      docCategoryConfig: {},
      docsToCreate: [],

      coordinates: { lat: "", lng: "" },
      fullWeatherArray: [], //an object taken from weather.gov, which contains some weather forecast data

      current: 0,
      wantsToSave: false, //if user presses Finish button
      disabledWhenUploading: false,
      weatherNotFound: false, //if weather for whatever reason is not set when we select a project, this is set to true and we get a retry button
      confirmCloseModalVisible: false,
      confirmCreationModalVisible: false,
      afterCreationModalVisible: false,

      ableToSave: !!this?.props?.requestId || "", //schedule can not be saved if it have incorrect data or if there is not any changes

      //quick statistics
      lastProgressDay: "",

      selectedTeams: [],
    };
    this.handleOnUnload = this.handleOnUnload.bind(this);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.handleTabClose = this.handleTabClose.bind(this);
    this.changeSelectedTeamsHandler =
      this.changeSelectedTeamsHandler.bind(this);
  }
  steps = [
    { title: "Overview ", content: "First-content" },
    { title: "Schedule Days", content: "Second-content" },
    // { title: "Documentation", content: "Last-content" },
    // { title: "Details" },
  ];
  serviceDefinitionsData = {}; //pricing, units, proposedTypeOfWork, serviceDefinitions

  handleTabClose(event) {
    if (this?.props?.editMode) {
      if (
        event?.key === "tabCloseKey" &&
        event?.newValue === this?.state?.schedule?.scheduleId
      ) {
        window.open(location.origin, "_self");
      }
    }
  }

  notifyFleetUsers = () => {
    let path = `scheduling/${this?.props?.rowData?.scheduleId}`;
    broadcastNotification(
      "11",
      "onDispatchEdit",
      [
        {
          common: this?.props?.userConfiguration?.nameOfUser,
          commonNext: this?.props?.rowData?.scheduleAddress,
        },
        {
          userName: this?.props?.userConfiguration?.nameOfUser,
          currentUser: this?.props?.authenticatedUser?.sub,
          cognitos: getCognitosForNotification(
            this?.props?.userConfiguration,
            updateTeamsConfiguration(
              this?.props?.userConfiguration,
              this?.state?.selectedTeams
            )
          ),
        },
      ],
      path
    );
  };

  updateProgressLogs = (logs = []) => {
    const { progressLogs = [], schedule = {}, scheduleCrews = [] } = this.state;
    const { scheduleId, projectId, scheduleAddress, scheduleName, typeOfWork } =
      schedule || {};
    const logData = logs?.map((log) => {
      const crewsForDay = scheduleCrews?.reduce((acc, crew) => {
        if (crew?.days?.some((day) => day?.dayId === log?.dayId)) {
          acc.push({
            crewName: crew?.crewName || "",
            crewTeamName: crew?.crewTeamName || "No crew name",
          });
        }
        return acc;
      }, []);
      return {
        ...log,
        ...{
          scheduleId,
          projectId,
          scheduleAddress,
          scheduleName,
          typeOfWork,
          crews:
            crewsForDay?.length > 0
              ? _.groupBy(crewsForDay, "crewTeamName")
              : {},
        },
      };
    });

    let progressData = progressLogs?.filter((log) => {
      const exists = logData?.some(
        (l) =>
          l?.dayId === log?.dayId &&
          l?.estimationId === log?.estimationId &&
          l?.serviceId === log?.serviceId &&
          l?.elevationId === log?.elevationId &&
          l?.pli === log?.pli &&
          l?.dayProgress !== log?.dayProgress
      );

      return !exists;
    });

    progressData = [...progressData, ...logData];

    this.setState({
      progressLogs: progressData,
    });
  };

  changeSelectedTeamsHandler = (teams) => {
    this.setState({
      selectedTeams: teams,
    });
  };

  handleBeforeUnload(e) {
    if (this?.props?.editMode) {
      if (e) {
        e.preventDefault();
        e.returnValue = "Are you sure you want to leave this page?";
      }

      // Remove the item with the with tabCloseKey and  itemId from local storage
      if (
        localStorage.getItem("tabCloseKey") ===
        this?.state?.schedule?.scheduleId
      ) {
        localStorage.removeItem("tabCloseKey");
      }
    }
  }

  handleOnUnload(e) {
    if (this?.props?.editMode) {
      // Remove the item with the specified itemId from local storage
      localStorage.removeItem(this.state?.schedule?.scheduleId);
    }
  }

  async myFunc() {
    try {
      const docCategoryConfig = {};
      this.setState({ docCategoryConfig });

      if (!!this?.props?.editMode) {
        const scheduleDays = ScheduleDay.scheduleDayArrayFromArrayObject(
          this?.props?.schedule?.scheduleDays
        );

        const {
          schedule: {
            toBeScheduled,
            scheduleStatus,
            typeOfWork,
            scheduleAddress,
            projectId,
            scheduleName,
            scheduleId,
            scheduleNotes,
          },
          project,
          schedules,
        } = this.props;

        const {
          pricing,
          units,
          proposedTypeOfWork,
          serviceDefinitions,
          schedule,
        } = this?.props;

        const serviceDefinitionsData = {
          pricing,
          units,
          proposedTypeOfWork,
          serviceDefinitions,
        };

        const totalHours = getTotalWorkingHours(
          scheduleDays?.filter(
            (a) =>
              a?.status !== "Canceled" &&
              a?.status !== "Postponed" &&
              a?.status !== "Skipped"
          )
        );

        const firstPage_InformativeVariables = {
          typeOfWork,
          scheduleStatus,
          projectId,
          scheduleAddress: this?.props?.projectAddress
            ? this?.props?.projectAddress
            : scheduleAddress,
          scheduleName,
          totalHours,
          scheduleId,
        };

        const tables = await getNeededTables(
          this?.props?.editMode,
          this?.props?.projectId
        );

        const newState = {
          current: 1,
          project,
          firstPage_InformativeVariables,
          scheduleDays,
          toBeScheduled:
            typeof toBeScheduled === "object" && !!toBeScheduled
              ? toBeScheduled
              : {},
          schedules: schedules,
          scheduleNotes,
          schedule,
          ...{ ...tables },
          serviceDefinitionsData,
        };
        if (this?.props?.projectAddress) {
          this.handleCoordinates(this?.props?.projectAddress);
        }
        this.setState(newState);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async myUpdated() {
    const tables = await getNeededTables(
      this?.props?.editMode,
      this?.props?.projectId
    );

    const schDays = firstScheduleDay(
      this?.state?.schedulesThatBelongToProjectSelected,
      this?.state?.firstPage_InformativeVariables?.typeOfWork,
      []
    );

    const totalHours = getTotalWorkingHours(schDays);
    const firstPage_InformativeVariables = {
      ...this?.state?.firstPage_InformativeVariables,
      totalHours,
    };

    this.setState({
      firstPage_InformativeVariables,
      ...{ ...tables },
    });
  }

  componentDidMount() {
    this.myFunc();

    // window.addEventListener("storage", this.handleTabClose); //adds the event listener
    // // Add the unload event listener to the window object
    // window.addEventListener("unload", this.handleOnUnload);
    // // Add the beforeunload event listener to the window object
    // window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  componentWillUnmount() {
    // this.handleOnUnload();
    // this.handleBeforeUnload();
    // this.handleTabClose();
    // window.removeEventListener("storage", this.handleTabClose); //removes the event listener
    // window.removeEventListener("unload", this.handleOnUnload);
    // window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      firstPage_InformativeVariables: { projectId, typeOfWork },
    } = this.state;

    const {
      firstPage_InformativeVariables: {
        projectId: prev_projectId,
        typeOfWork: prev_typeOfWork,
      },
    } = prevState;

    if (projectId !== "" && this?.props?.editMode !== true) {
      //if we have selected project and typeOfWork we are able to use this project for schedule
      if (projectId !== prev_projectId || typeOfWork !== prev_typeOfWork) {
        this.handleProject(projectId); //generate plis and set weather
      }
    }

    if (
      JSON.stringify(this?.state?.serviceDefinitionsData) !==
      JSON.stringify(prevState?.serviceDefinitionsData)
    ) {
      if (this.props.editMode === true) {
      } else {
        this.myUpdated();
      }
    }
  }

  next = () => {
    this.setState({ current: this?.state?.current + 1 });
  }; //move stepper
  prev = () => {
    this.setState({ current: this?.state?.current - 1 });
  };

  //handleServices and sets coordinates and weather
  handleProject = async (projectId) => {
    try {
      this.setState({ showLoading: true });
      let project =
        this?.props?.projectObj ||
        this?.state?.projects?.find(
          (projectElement) => projectElement?.projectId === projectId
        );

      await this.handleSchedules(project);
      this.handleCoordinates(project?.projectName);
      this.handleServices(project);
    } catch (e) {
      console.log("ERROR", { e });
      message.error("Something went wrong while processing this project.");
    } finally {
      this.setState({ showLoading: false });
    }
  };

  undoProgressSchedule = () => {};

  handleSchedules = async (project = {}) => {
    const { projectId, projectName } = project;
    try {
      let schedulesThatBelongToProjectSelected = [];
      if (this?.props?.schedulesBelongToProject?.length > 0) {
        schedulesThatBelongToProjectSelected =
          this?.props?.schedulesBelongToProject;
      } else {
        schedulesThatBelongToProjectSelected = await GET_SCHEDULING_BY_PROJECT(
          projectId
        );
      }

      const firstPage_InformativeVariables = _.cloneDeep(
        this?.state?.firstPage_InformativeVariables
      );
      firstPage_InformativeVariables.scheduleAddress = this?.props
        ?.projectAddress
        ? this?.props?.projectAddress
        : projectName;
      firstPage_InformativeVariables.projectId = this?.props?.projectId
        ? this?.props?.projectId
        : projectId;
      firstPage_InformativeVariables.scheduleName =
        toOrdinal(schedulesThatBelongToProjectSelected?.length + 1) +
        " Schedule";

      this.setState({
        schedulesThatBelongToProjectSelected,
        firstPage_InformativeVariables,
        scheduleDays: firstScheduleDay(
          schedulesThatBelongToProjectSelected,
          firstPage_InformativeVariables?.typeOfWork,
          []
        ),
      });
    } catch (e) {
      console.error("ERRORR", { e });
      message.error(
        "Something went wrong while processing the schedules of this project."
      );
      throw e;
    }
  };

  handleCoordinates = async (address = "") => {
    try {
      const { coordinates } = !!address
        ? await getLatLngByAddress(address)
        : {};

      this.setState({ coordinates }, () => {
        this.handleWeather();
      });
    } catch (e) {
      console.error(e);
      // message.error(
      //   "There were a problem setting coordinates for this project."
      // );
    }
  };

  handleServices = async (project = {}) => {
    try {
      const {
        firstPage_InformativeVariables: { typeOfWork },
        serviceDefinitionsData: {},
      } = this.state;
      const thisProjectSchedules = filterSchedulesByProject(
        this?.props?.schedulesBelongToProject ||
          this?.state?.schedulesThatBelongToProjectSelected,
        typeOfWork
      );

      let checkForPrevSchedules =
        thisProjectSchedules?.sort(
          (a, b) => dayjs(b?.createdAt) - dayjs(a?.createdAt)
        )?.[0] || {};

      if (Object.keys(checkForPrevSchedules).length > 0) {
        let getEst = await filterTables(
          "toBeScheduled",
          "scheduleId",
          checkForPrevSchedules?.scheduleId
        );
        let groupEst = groupBy(getEst, "estimationId");
        checkForPrevSchedules.toBeScheduled = groupEst;
      }

      const services = {};

      for (const [key, value] of Object.entries(
        onlyEstWithScopeFalse(
          project?.services || {},
          this?.state?.serviceDefinitionsData?.serviceDefinitions
        ) || {}
      )) {
        services[key] =
          this?.state?.firstPage_InformativeVariables?.typeOfWork ===
          "Inspection"
            ? value?.filter((el) => checkIfServiceIsHoist(el))
            : value;
      }
      const dataForPrevServices = {};
      Object.entries(
        newChangesFromEstimationForSchedule(
          onlyEstWithScopeFalse(
            checkForPrevSchedules?.toBeScheduled || {},
            this?.state?.serviceDefinitionsData?.serviceDefinitions
          ),
          this?.state?.firstPage_InformativeVariables?.typeOfWork ===
            "Inspection"
            ? onlyEstWithScopeFalse(
                services,
                this?.state?.serviceDefinitionsData?.serviceDefinitions
              )
            : onlyEstWithScopeFalse(
                project?.services,
                this?.state?.serviceDefinitionsData?.serviceDefinitions
              ) || {}
        ) || {}
      )?.map(([key, value]) => {
        dataForPrevServices[key] = value?.map((el) => {
          if (checkIfServiceIsHoist(el)) {
            el.serviceOptions = [
              (el?.serviceOptions || [])?.[0]?.map?.((opt) =>
                serviceOptionsForJumps(opt, breakdown_dimension)
              ),
            ];
            return el;
          } else {
            const { d1, d2 } = get_ProgressDimensionsByServiceId(el?.serviceId);
            el.serviceOptions = [
              (el?.serviceOptions || [])?.[0]?.map?.((opt) =>
                transformOption(opt, breakdown_dimension, d1, d2)
              ),
            ];
          }
          return el;
        });
        return dataForPrevServices;
      });

      let servicesToWork = _.cloneDeep(
        Object.keys(checkForPrevSchedules)?.length > 0
          ? dataForPrevServices
          : services
      );

      const estimationIdList = Object.keys(servicesToWork) || [];
      if (!Object.keys(checkForPrevSchedules || {})?.length) {
        mainProcessing(
          estimationIdList,
          servicesToWork,
          this?.serviceDefinitionsData?.serviceDefinitions || []
        );
      }

      this.setState({
        toBeScheduled:
          Object.keys(checkForPrevSchedules)?.length > 0
            ? dataForPrevServices
            : servicesToWork,
        project,
        thisProjectSchedules,
      });
    } catch (e) {
      console.error("ERROR", { e });
      message.error(
        "There was a problem when proceeding services of this project."
      );
      console.log(e);
    }
  };

  handleWeather = async () => {
    let reason = "";
    try {
      reason = "Bad response from weather.gov";
      let response = await fetch(
        `https://api.weather.gov/points/${this?.state?.coordinates?.lat},${this?.state?.coordinates?.lng}`
      );

      let data = !!response ? await response.json() : {};

      let weatherObject = await fetch(data?.properties?.forecast);
      weatherObject = await weatherObject.json();

      reason = weatherObject?.detail;

      const fullWeatherArray = weatherObject?.properties?.periods;
      let scheduleDays;
      reason = "Problem with software";
      if (this?.props?.editMode === true) {
        scheduleDays = ScheduleDay.scheduleDayArrayFromArrayObject(
          this?.props?.schedule?.scheduleDays
        );

        for (let scheduleDay of scheduleDays || []) {
          scheduleDay = Object.assign(new ScheduleDay(), scheduleDay);
          scheduleDay.setWeather(fullWeatherArray);
        }
      } else {
        scheduleDays = firstScheduleDay(
          this?.state?.schedulesThatBelongToProjectSelected,
          this?.state?.firstPage_InformativeVariables?.typeOfWork,
          fullWeatherArray
        );
      }

      this.setState({
        scheduleDays,
        fullWeatherArray,
        weatherNotFound: false,
      });
    } catch (e) {
      this.setState({ weatherNotFound: true });
      // message.error(
      //   "Could not get weather for this project. Reason: " + reason
      // );
      console.error(e);
    }
  };

  /*documentation start*/
  findTasksAssigned = () => {
    let arr = get_tasks();
    let existingTasks =
      Array.isArray(arr) && arr?.length !== 0
        ? arr?.filter(
            (taskElement) =>
              taskElement?.taskTopicId === localStorage.getItem("scheduleId")
          )
        : [];
    this.setState({ existingTasks: existingTasks });
  };

  findMissingDocumentation = async () => {
    let arr = await get_documentation();
    let existingDocuments =
      Array.isArray(arr) && arr?.length !== 0
        ? arr
            ?.filter(
              (docElement) =>
                docElement?.projectId ===
                this?.state?.firstPage_InformativeVariables?.projectId
            )
            ?.map((docElement) => docElement?.docName)
        : [];
    this.setState({
      existingDocuments: Array.from(new Set(existingDocuments)),
    });
    let documentInformation =
      Array.isArray(arr) && arr?.length !== 0
        ? arr?.filter(
            (docElement) =>
              docElement?.projectId ===
              this?.state?.firstPage_InformativeVariables?.projectId
          )
        : [];
    this.setState({ documentInformation: documentInformation });
    let docTasks = [];
    try {
      for (var i = 0; i < this?.state?.documentInformation?.length; i++) {
        for (var j = 0; j < this?.state?.existingTasks?.length; j++) {
          const taskSubTopicId =
            this?.state?.existingTasks?.[j]?.taskSubTopicId;
          const docId = this?.state?.documentInformation?.[i]?.docId;
          if (
            taskSubTopicId &&
            (!!taskSubTopicId?.recordId
              ? taskSubTopicId.recordId === docId
              : taskSubTopicId === docId)
          ) {
            docTasks = docTasks.concat(documentInformation?.[i]);
          }
        }
      }
      this.setState({ docTasks: docTasks });
    } catch (e) {
      console.error(e);
    }
  };

  handleDocument(e) {
    this.setState({ documentAssignedTo: e?.value });
  }

  //set a variable that have we want to change in firstPage_InformativeVariables
  setFirstPageVariables = (key, value) => {
    let firstPage_InformativeVariables = _.cloneDeep(
      this?.state?.firstPage_InformativeVariables
    );
    if (key === "projectId") {
      firstPage_InformativeVariables["typeOfWork"] = "";
    }
    firstPage_InformativeVariables[key] = value;

    this.setState({ firstPage_InformativeVariables });
  };

  updateGoogleDriveFolders = (updatedGoogleDrive, label = "") => {
    if (!!label) {
      this.setState({
        googleDriveFolderIds: {
          ...this?.state?.googleDriveFolderIds,
          [label]: updatedGoogleDrive,
        },
      });
    } else {
      this.setState({
        googleDriveFolderIds: updatedGoogleDrive,
      });
    }
  };

  setDisptachDays = (days) => {
    this.setState({
      dispatchDays: [...(this?.state?.dispatchDays || []), days],
    });
  };

  addGoogleDriveFolderToQueue = (folderQueue) => {
    this?.state?.googleDriveFolderQueue?.push(folderQueue);
    this.setState({
      googleDriveFolderQueue: this?.state?.googleDriveFolderQueue,
    });
  };

  onCloseModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager"
    this?.props?.onChange(false);
  };

  onEnterPress = (event) => {
    if (event.charCode === 13) {
      if (this?.props?.editMode)
        driveFolderFunction({
          driveRequest: this.props.driveRequest,
          action: "delete",
          googleDriveFolderIds: this.state.googleDriveFolderIds,
          oldGoogleDriveFolderIds: this?.props?.rowData?.googleDriveFolderIds,
          updateGoogleDriveFolders: this?.updateGoogleDriveFolders,
          day: this?.state?.scheduleDays?.map(({ endDate }) =>
            dayjs(endDate)?.format("MM/DD/YYYY")
          ),
        });
      this.setState({ confirmCloseModalVisible: false });
      this.onCloseModal();
    }
  };

  onEnterCreateSch = (event) => {
    if (event.charCode === 13) {
      this.openSetTasks();
      this.setState({ confirmCreationModalVisible: false });
    }
  };

  confirmModal = () => {
    this.setState({ confirmCloseModalVisible: true });
  };
  confirmCreation = () => {
    this.setState({ confirmCreationModalVisible: true });
  };
  closeConfirmCreation = () => {
    this.setState({ confirmCreationModalVisible: false });
  };

  //tasks start
  openSetTasks = (event) => {
    this.setState({ confirmCreationModalVisible: false });
    this.setTasks(event);
  };
  setTasks = async (event) => {
    if (!this?.state?.scheduleAddress) {
      this.setState({ projectValid: true });
      window.setTimeout(() => {
        this.setState({ projectValid: false });
      }, 3000);
      return false;
    }
    if (this?.state?.scheduleAddress) {
      this.next();
    }
  };

  selectTypeOfRemoval = (e) => {
    this.setState({ typeOfRemoval: e });
  };

  handleTasks(e, task, docName) {
    let allTasksToBeCreated = [...this?.state?.missingTasks];
    if (
      e?.target?.checked === true &&
      !allTasksToBeCreated
        ?.map((taskElement) =>
          taskElement?.taskSubTopicId?.recordId
            ? taskElement.taskSubTopicId.recordId
            : taskElement?.taskSubTopicId
        )
        ?.includes(task)
    ) {
      allTasksToBeCreated = allTasksToBeCreated?.concat({
        taskSubTopicId: { recordId: task },
        docName: docName,
        taskSubject: "Missing Documentation",
        taskCategory: "Scheduling",
        taskDueDate: getTodayModifiedDate(),
        taskPriority: "High",
        taskRelatedTo: this?.state?.scheduleAddress,
        taskAssignedTo: [this?.state?.documentAssignedTo],
        taskTopicId: localStorage.getItem("scheduleId"),
        taskStatus: "Pending",
      });
    }
    if (
      e?.target?.checked === false &&
      allTasksToBeCreated
        ?.map((taskElement) =>
          taskElement?.taskSubTopicId?.recordId
            ? taskElement.taskSubTopicId.recordId
            : taskElement?.taskSubTopicId
        )
        ?.includes(task)
    ) {
      allTasksToBeCreated = allTasksToBeCreated?.filter((taskElement) =>
        taskElement?.taskSubTopicId?.recordId
          ? taskElement.taskSubTopicId.recordId
          : taskElement?.taskSubTopicId !== task
      );
    }
    this.setState({ missingTasks: allTasksToBeCreated });
  }

  updateScheduleCrews = (day = {}, crews = []) => {
    const dayId = day?.id;

    const createNewDay = (startTime, endTime) => ({
      startDate: dayjs(day?.startDate).valueOf(),
      endDate: dayjs(day?.endDate).valueOf(),
      startTime: startTime || null,
      endTime: endTime || null,
      dayId: dayId,
      day: day?.day,
      status: day?.status,
    });

    const scheduleCrews = _.cloneDeep(this?.state?.scheduleCrews) || [];

    const crewsMap = new Map(crews?.map((crew) => [crew?.crewId, crew]));

    const updatedCrews = scheduleCrews
      ?.map((crew) => {
        const newCrew = crewsMap.get(crew?.crewId);

        if (newCrew) {
          const newDay = createNewDay(newCrew?.startTime, newCrew?.endTime);

          const updatedDays = [
            ...crew?.days?.filter((dayElement) => dayElement?.dayId !== dayId),
            newDay,
          ];

          return { ...crew, days: updatedDays };
        }

        const updatedDays = crew?.days?.filter(
          (dayElement) => dayElement?.dayId !== dayId
        );

        return { ...crew, days: updatedDays };
      })
      ?.filter((crew) => crew?.days?.length > 0);

    crews?.forEach((crew) => {
      if (!scheduleCrews?.some((sc) => sc?.crewId === crew?.crewId)) {
        const newDay = createNewDay(crew?.startTime, crew?.endTime);

        updatedCrews.push({
          crewId: crew?.crewId || "",
          crewName: crew?.crewName || "",
          crewPosition: crew?.crewPosition || "",
          crewTeamName: crew?.crewTeamName || "",
          days: [newDay] || [],
        });
      }
    });

    console.log("updatedCrews", { updatedCrews });
    this.setState({ scheduleCrews: updatedCrews });
  };

  handlePreviousNext = (scheduleDays, ableToSave) => {
    this.setState({ scheduleDays, ableToSave });
  }; //comes from WrappedScheduleDays
  setAbleToSave = (ableToSave) => this.setState({ ableToSave }); //comes from WrappedScheduleDays, after every change there we set schedule as able or not able being saved

  notesChanged = (scheduleNotes) => {
    this.setState({ scheduleNotes });
  };

  //saving functions
  //comes from WrappedScheduleDays
  save_scheduleDays_and_toBeScheduled = ({ scheduleDays, toBeScheduled }) => {
    if (this?.state?.schedule) {
      //if we are editing

      this.save_CHANGES_scheduleDays_and_toBeScheduled(
        scheduleDays,
        toBeScheduled
      );
    } else {
      this.SUMBIT_new_schedule(scheduleDays, toBeScheduled); //save schedule in database
    }
    this.setState({ wantsToSave: false });
  };

  //when editing
  save_CHANGES_scheduleDays_and_toBeScheduled = async (
    scheduleDays = [],
    toBeScheduled = {}
  ) => {
    if (this?.state?.dispatchDays?.length > 0) {
      this.setState({ openDispatchDays: true });
    }

    let schedule = _.cloneDeep(this?.state?.schedule);
    let userConfiguration = this?.props?.userConfiguration;

    let allFolders = null;
    try {
      const googleDriveFolderIds = await driveFolderFunction({
        driveRequest: this.props.driveRequest,
        action: "",
        googleDriveFolderIds: this.state.googleDriveFolderIds,
        updateGoogleDriveFolders: this?.updateGoogleDriveFolders,
        googleDriveFolderQueue: this?.state?.googleDriveFolderQueue,
      });
      allFolders = googleDriveFolderIds;
    } catch (e) {
      allFolders = this?.state?.googleDriveFolderIds;
      console.error(e);
    }
    const currentSchedule = this?.props?.rowData || {};
    const { typeOfWork, createdAt, scheduleId, scheduleName } =
      currentSchedule || {};
    const allSchedules =
      this?.props?.scheduleProps?.schedules?.filter(
        (sch) =>
          sch?.typeOfWork === typeOfWork && sch?.scheduleId !== scheduleId
      ) || [];

    schedule = {
      ...schedule,
      scheduleDays,
      toBeScheduled: toBeScheduled || {},
      totalHours: this?.state?.firstPage_InformativeVariables?.totalHours,
      scheduleNotes: this?.state?.scheduleNotes,
      googleDriveFolderIds: allFolders,
      scheduleEstimates: Object.keys(toBeScheduled || {}).length,
      totalScheduleProgress: scheduleProgress(toBeScheduled),
      scheduleName: this?.state?.firstPage_InformativeVariables?.scheduleName,
      scheduleStatus:
        scheduleProgress(toBeScheduled) === 100
          ? "Completed"
          : schedule?.scheduleStatus,
    };

    if ("scheduleCrews" in (this?.props?.rowData || {})) {
      schedule.scheduleCrews = this?.state?.scheduleCrews || [];
    }

    const schedulesToUpdate = [
      ...(allSchedules || []),
      { ...schedule, requestId: this?.props?.requestId },
    ];

    const updateRowData = () => {
      this.props.setRowData(schedule);
    };

    if (this?.state?.crewsInfo?.crewsChanged)
      schedule.crews = getCrewsActivityArray(scheduleDays, schedule, "user");
    try {
      await saveMultipleSchedules(
        schedulesToUpdate,
        toBeScheduled,
        createdAt,
        scheduleId,
        scheduleName,
        this?.state?.progressLogs,
        notificationForProgressChange(
          userConfiguration,
          currentSchedule,
          toBeScheduled,
          this?.props?.authenticatedUser,
          this?.state?.serviceDefinitionsData?.serviceDefinitions
        ),
        updateRowData,
        async () =>
          await this?.props?.afterSaveRequest({
            newRecordId: schedule?.scheduleId,
            // requestId,
            path: "scheduling",
            // cognitoUserId: this?.props?.userConfiguration?.cognitoUserId,
          }),
        this?.props?.requestId
        // this?.props?.requestObject
      ); //.then(() => {});
      this.setState({ scheduleDays, toBeScheduled, schedule });
      this.setState({ ableToSave: false });
    } catch (e) {
      console.error(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong while saving the schedule.",
        timer: 5000,
      });
    }
    // finally {
    //   let id = document.getElementById(
    //     "scheduleDaysContainer-servicesContainer"
    //   );
    //   if (id) {
    //     id.animate(
    //       [
    //         { backgroundColor: "rgba(0, 255, 0, 0.2)" },
    //         { backgroundColor: "rgba(0, 255, 0, 0)" },
    //       ],
    //       {
    //         duration: 3000,
    //         iterations: 1,
    //       }
    //     );
    //   }
    // }
  };

  //when creating a new schedule
  SUMBIT_new_schedule = async (scheduleDays = [], toBeScheduled = {}) => {
    Swal.fire({
      title: "Please wait...",
      text: "We are creating the schedule for you!",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    const {
      firstPage_InformativeVariables,
      project: { services, projectExecutive },
      scheduleNotes,
      project: { googleDriveFolderIds = {} },
    } = this.state;
    this.setState({ disabledWhenUploading: true });

    const servicesName = () => {
      const array = [];
      if (!!toBeScheduled) {
        let estimationKeys = Object.keys(toBeScheduled || {});
        for (const est of estimationKeys || []) {
          for (const service of toBeScheduled?.[est] || []) {
            array.push(
              `${service?.label} - ${service?.estimationNumber || ""}`.trim()
            );
          }
        }
      }
      array.filter(Boolean);

      return Array.from(new Set(array));
    };

    const { scheduleObject: parentId = "" } = googleDriveFolderIds || {};
    const { scheduleName = "" } = firstPage_InformativeVariables || {};

    let allFolders = null;

    try {
      const { folders } = await this?.props?.driveFunctions.create({
        parentFolderName: scheduleName,
        parentId,
        extraFolders: {
          parentFolderName: servicesName(),
          parentFolder: "Documentation",
        },
      });

      allFolders = folders;
    } catch (e) {
      allFolders = null;
      console.error(e);
    }

    // await moveRequestFiles({
    //   filesToMove: this?.props?.requestFiles || [],
    //   newParentId: allFolders?.scheduleObject,
    //   requestId: this?.props?.requestId,
    //   accessToken: this?.props?.accessToken,
    // });
    /**
     *
     */
    const teamsConfiguration = updateTeamsConfiguration(
      this?.props?.userConfiguration,
      this?.state?.selectedTeams,
      [],
      true
    );
    const data = {
      ...firstPage_InformativeVariables,
      removalType:
        firstPage_InformativeVariables?.typeOfWork === "Removal"
          ? this?.state?.typeOfRemoval
          : "",
      projectExecutive,
      documentationList: this?.state?.fullDocuments,
      scheduleNotes,
      scheduleCompleted: "",
      editLogs: [],
      scheduleDays,
      toBeScheduled: {},
      scheduleEstimates: Object.keys(toBeScheduled || {}).length,
      totalScheduleProgress: scheduleProgress(toBeScheduled),
      googleDriveFolderIds: allFolders,
      crews: getCrewsActivityArray(scheduleDays, undefined, "user"),
      dispatches: getFleetDispatchArray(scheduleDays, undefined, "user"),
      teamsConfiguration,
      requestId: this?.props?.requestId,
      scheduleCrews: this?.state?.scheduleCrews || [],
    };

    const currProject = this?.state?.projects?.find(
      (projectElement) =>
        projectElement?.projectId === firstPage_InformativeVariables?.projectId
    );

    await createSchedule(data)
      .then(async (schedule) => {
        message.loading({ content: "Creating estimates...", key: "estimates" });
        await API.post("toBeScheduled", "/toBeScheduled", {
          body: Object.entries(toBeScheduled || {}).reduce(
            (acc, [key, value]) => {
              acc.push(
                ...value?.map((service) => ({
                  ...service,
                  estimationId: key,
                  scheduleId: schedule?.scheduleId,
                }))
              );
              return acc;
            },
            []
          ),
        })
          .then((res) => {
            message.destroy("estimates");
            message.success("Estimates created successfully");
          })
          .catch((err) => {
            message.error("Error creating To Be Scheduled");
            console.log("Error creating To Be Scheduled: ", err);
          });

        // add jobSite in payroll if there is no jobSite for the project

        const jobSite = await lazyFetch({
          tableName: "jobsites",
          listOfKeys: ["projectId"],
          filterKey: "projectId",
          filterValue: schedule?.projectId,
        }).then((res) => res[0]);

        if (!jobSite) {
          API.post("jobsites", "/jobsites", {
            body: {
              accountName: currProject?.accountId,
              addressPosition: {
                lat: currProject?.projectLatitude,
                lng: currProject?.projectLongitude,
              },
              geofenceInfo: currProject?.geoFenceInfo,
              googleSheetLink: "",
              jobAddress: currProject?.projectAddress,
              projectId: currProject?.projectId,
              scheduleId: schedule?.scheduleId,
              jobName: currProject?.projectName,
              locationRadius:
                currProject?.geoFenceInfo?.find(
                  (geoInfo) => !!geoInfo?.type === "Circle"
                )?.geoFenceInfo?.[0]?.circleRadius || 900,
              payrollType: "Open Shop",
              services: [],
            },
          }).catch((err) => console.log("Error creating JobSite: ", err));
        }

        await API.post("editLogs", "/editLogs", {
          body: {
            recordId: schedule?.scheduleId,
            recordName: `${schedule?.scheduleName} - ${schedule?.typeOfWork}`,
            category: "Scheduling",
            actionType: "Create",
            topic: schedule?.scheduleAddress,
            currentData: {},
            member: `${this?.props?.authenticatedUser?.given_name} ${this?.props?.authenticatedUser?.family_name}`,
            nameOfUser: `${this?.props?.authenticatedUser?.given_name} ${this?.props?.authenticatedUser?.family_name}`,
            cognitoUserId: this?.props?.authenticatedUser?.sub,
            previousData: {},
            updatedAt: dayjs().valueOf(),
            updatedKeys: [],
          },
        })
          .then((r) => console.log("rrrrr", { r }))
          .catch((e) => console.log("eeee", { e }));

        Swal.fire({
          title: "Successfully created",
          text: "Schedule Created Successfully!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

        broadcastNotification(
          "14",
          "onCreation",
          [
            {
              common: this?.props?.userConfiguration?.nameOfUser,
            },
            {
              userName: this?.props?.userConfiguration?.nameOfUser,
              currentUser: this?.props?.authenticatedUser?.sub,
              cognitos: getCognitosForNotification(
                this?.props?.userConfiguration,
                teamsConfiguration
              ),
            },
          ],
          schedule?.scheduleId
        );

        this.setState({
          scheduleDays,
          toBeScheduled,
          schedule,
          disabledWhenUploading: false,
        });

        this.onCloseModal();

        if (!!this?.props?.requestId && !!this?.props?.requestObject) {
          await this?.props?.afterSaveRequest({
            newRecordId: schedule?.scheduleId,
            // requestId,
            path: "scheduling",
            // cognitoUserId: this?.props?.userConfiguration?.cognitoUserId,
            moveFilesParams: {
              filesToMove: this?.props?.requestFiles || [],
              newParentId: schedule?.googleDriveFolderIds?.scheduleObject,
              accessToken: this?.props?.accessToken,
            },
          });
        }
        // !!docsToCreate.length &&
        //   API.post(apiRoutes.documentation, `/${apiRoutes.documentation}`, {
        //     body: {
        //       documentations: docsToCreate.map((doc) => ({
        //         ...doc,
        //         recordId: schedule?.scheduleId,
        //         recordName: schedule?.scheduleName,
        //       })),
        //     },
        //   }).catch((err) => console.log(err))
        this.props.refreshTable([schedule]);
        this.next();
        // this.refreshTable();
        createEventsFromSchedule(schedule, false);
      })
      .catch((e) => {
        console.log("error", e);
        Swal.fire({
          title: "Something went wrong!",
          text: "Item has exceeded the maximum allowed size!",
          icon: "error",
          showConfirmButton: false,
          timer: 500,
        }).then(() => {
          this.setState({
            current: 0,
          });
        });

        this.setState({ disabledWhenUploading: true });
      });
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
  };

  //schedule days functions end

  //on click buttons
  showGMap = () => {
    try {
      getLatLngByAddress(this?.state?.scheduleAddress)
        .then((response) => {
          const { coordinates } = response;
          const { lat, lng } = coordinates;
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
            "_blank"
          );
        })
        .catch((error) => {});
    } catch (e) {}
  };
  closeConfirmModal = () => {
    this.setState({ confirmCloseModalVisible: false });

    this.onCloseModal();
  };
  onchangeStepper = (e) => {
    this.setState({ current: e });
    if (e === 2) {
      this.documentsLoading();
    }
  };

  documentsLoading = async () => {
    await this.findMissingDocumentation().then(this.findTasksAssigned());
  };

  /*JSX functions start*/
  createModalFooter = () => {
    let { editMode } = this.props;
    const {
      current,
      schedule,
      ableToSave,
      disabledWhenUploading,
      firstPage_InformativeVariables: {
        scheduleAddress,
        typeOfWork,
        projectManagers,
      },
    } = this.state;
    editMode = editMode === true;

    const schOfThisProject = this?.props?.scheduleProps?.schedules?.filter(
      (a) => a?.scheduleId !== this?.props?.rowData?.scheduleId
    );

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            position: "absolute",
            left: "1%",
          }}
        >
          <MondayButton
            onClick={this.confirmModal}
            Icon={<XIcon />}
            className="dangerBtn"
            tooltipCategory="Scheduling"
            tooltipKey="cancel"
          >
            {this?.state?.schedule ? "Close" : "Cancel"}
          </MondayButton>

          {!!editMode && (
            <MondayButton
              // disabled={true}
              onClick={() => this.setState({ openProgressLogs: true })}
              {...{
                className: "mondayButtonBlue",
                Icon: <LogsIcon />,
                tooltipCategory: "Scheduling",
                tooltipKey: "cancel",
              }}
            >
              Schedule Progress Logs
            </MondayButton>
          )}

          {!!editMode && schOfThisProject?.length > 0 && (
            <div className="goToPrevSchButton">
              <SwitchSchedules
                {...{
                  schedules:
                    schOfThisProject?.sort(
                      (a, b) =>
                        dayjs(a?.createdAt).valueOf() -
                        dayjs(b?.createdAt).valueOf()
                    ) || [],
                  switchSchedules: this?.props?.switchSchedules,
                  rowData: this?.props?.rowData,
                  ableToSave,
                }}
              />
            </div>
          )}
        </div>

        <div>{ableToSave !== true ? ableToSave : ""}</div>

        {current !== 0 && !editMode ? (
          <div id="previousStepBtn" className="previousStep">
            <MondayButton
              onClick={this.prev}
              Icon={<BackIcon />}
              className="secondaryBtn"
            >
              Previous Step
            </MondayButton>
          </div>
        ) : (
          ""
        )}

        {(!!schedule || current === 1) && (
          <div
            className={
              ableToSave !== true || disabledWhenUploading
                ? "disabledSaveButton"
                : "notDisabledSaveButton"
            }
            id="createScheduleBtn"
          >
            <MondayButton
              onClick={() => this.setState({ openSaveModal: true })}
              disabled={ableToSave !== true || disabledWhenUploading}
              Icon={<Tick width={17} height={17} />}
              tooltipCategory="Scheduling"
              tooltipKey="save"
            >
              {schedule ? "Save Changes" : "Create Schedule"}
            </MondayButton>
          </div>
        )}

        {current !== 1 && !editMode ? (
          <div className="nextStep">
            <MondayButton
              buttonStyle={
                (typeOfWork === "Removal" && !this?.state?.typeOfRemoval) ||
                typeOfWork === "" ||
                scheduleAddress === ""
                  ? { cursor: "not-allowed" }
                  : {}
              }
              Icon={<RightOutlined />}
              onClick={() =>
                scheduleAddress === ""
                  ? message.error("You haven't selected a project!", 5)
                  : typeOfWork === ""
                  ? message.error("You haven't selected one Type Of Work", 5)
                  : typeOfWork === "Removal" && !this?.state?.typeOfRemoval
                  ? message.error("Please select a type of removal!", 5)
                  : this.next()
              }
              className="secondaryBtn"
            >
              Next Step
            </MondayButton>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  // renderSteps = (stepsViews, editMode) => {
  //   if (!editMode) return stepsViews;
  //   return stepsViews?.[1];
  // };
  /*JSX functions end*/

  onCloseHandler = () => {
    this.setState({ afterCreationModalVisible: false });
    this.onCloseModal();
  };

  render() {
    const {
      wantsToSave,
      scheduleDays,
      weatherNotFound,
      firstPage_InformativeVariables,
      serviceDefinitionsData,
      project,
      projects,
      schedule,
      fleetInfo,
      scheduleNotes,
      fullWeatherArray,
      crewsInfo,
      toBeScheduled,
      current,
      // schedules,
      thisProjectSchedules,
      docCategoryConfig,
      docsToCreate,
    } = this.state;

    const { editMode, notesAccessRights } = this.props;

    const {
      save_scheduleDays_and_toBeScheduled,
      handlePreviousNext,
      documentsLoading,
      setFirstPageVariables,
      handleWeather,
      setAbleToSave,
      notesChanged,
      selectTypeOfRemoval,
    } = this;

    const steps = [{ title: "Overview" }, { title: "Schedule Days" }];

    const currentTypeOfWork = editMode
      ? this?.props?.rowData?.typeOfWork
      : this?.state?.firstPage_InformativeVariables?.typeOfWork;

    const checkAllSchedules =
      this?.state?.schedulesThatBelongToProjectSelected
        ?.filter(
          (a) =>
            (editMode &&
              moment(a?.createdAt) < moment(this?.props?.rowData?.createdAt) &&
              a?.typeOfWork === currentTypeOfWork) ||
            (!editMode && a?.typeOfWork === currentTypeOfWork)
        )
        ?.sort((a, b) => moment(a?.createdAt) - moment(b?.createdAt)) || [];

    const data = [
      {
        scheduleAddress:
          this?.state?.firstPage_InformativeVariables?.scheduleAddress,
        typeOfWork: !editMode
          ? this?.state?.firstPage_InformativeVariables?.typeOfWork
          : this?.props?.rowData?.typeOfWork,
        projectExecutive: project?.projectExecutive,
        scheduleDays: this?.state?.scheduleDays,
        toBeScheduled: toBeScheduled,
      },
    ];

    const infoHeaderDisplayed = [
      {
        field: "Schedule Address:",
        value: schedule?.scheduleAddress,
      },
      {
        field: "Schedule Name:",
        value: schedule?.scheduleName,
      },
      // {
      //   field: "Schedule Status:",
      //   value: schedule?.scheduleStatus,
      // },
      {
        field: "Type of work:",
        value: schedule?.typeOfWork,
      },
      {
        field: "Days in Schedule:",
        value: schedule?.scheduleDays?.length,
      },
      {
        field: "Start Date:",
        value: dayjsNY(schedule?.scheduleDays?.[0]?.startDate).format(
          "MM/DD/YYYY"
        ),
      },
      {
        field: "End Date:",
        value: dayjsNY(
          schedule?.scheduleDays?.[schedule?.scheduleDays?.length - 1]
            ?.startDate
        ).format("MM/DD/YYYY"),
      },
    ];
    return (
      <SchedulingContext.Provider
        value={{
          project,
          docsToCreate,
          docCategoryConfig,
          thisProjectSchedules,
          serviceDefinitionsData,
          updateDispatchesForSchedule: this.props.setDispatchForSchedule,
          updateContext: (data) => {
            this.setState(data);
          },
        }}
      >
        <Modal
          open={this?.props?.isLoading}
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "New Schedule" });
          }}
          className={`scheduleModal ${
            this?.props?.isDarkMode && "scheduleModalDark"
          }`}
          width="100%"
          centered
          title={
            <div className="scheduleModalHeader">
              <Header
                scheduleName={
                  editMode
                    ? this?.props?.rowData?.scheduleName
                    : this?.state?.firstPage_InformativeVariables?.scheduleName
                }
                setAbleToSave={setAbleToSave}
                setFirstPageVariables={setFirstPageVariables}
                schedule={schedule}
                scheduleTotalProgressPercentage={
                  this?.state?.totalProgressOfAllSchedule || 0
                }
                lastProgressDay={this?.state?.lastProgressDay || ""}
                totalWorkingHours={
                  this?.state?.firstPage_InformativeVariables?.totalHours
                    ?.totalHours?.hours
                }
                projectExcutive={
                  editMode
                    ? schedule?.projectExecutive
                    : project?.projectExecutive
                }
                scheduleStatus={
                  this?.state?.firstPage_InformativeVariables?.scheduleStatus
                }
                typeOfWork={
                  !editMode
                    ? this?.state?.firstPage_InformativeVariables?.typeOfWork
                    : this?.props?.schedule?.typeOfWork
                }
                projectName={
                  !!this?.props?.projectAddress
                    ? this?.state?.firstPage_InformativeVariables
                        ?.scheduleName +
                      " - " +
                      this?.props?.projectAddress
                    : this?.state?.firstPage_InformativeVariables
                        ?.scheduleName +
                      " - " +
                      this?.state?.firstPage_InformativeVariables
                        ?.scheduleAddress
                }
                editMode={editMode}
                projectTitle={
                  editMode
                    ? this?.state?.firstPage_InformativeVariables
                        ?.scheduleAddress
                    : null
                }
                isWritable={this?.props?.isWritable}
                setIsWritable={this?.props?.setIsWritable}
                scheduleDays={scheduleDays}
                darkMode={this?.props?.darkMode}
                setConfirmCloseModal={() =>
                  this.setState({ confirmCloseModalVisible: true })
                }
              />
            </div>
          }
          closable={false}
          footer={
            <div className="scheduleModal-footer">
              {this.createModalFooter()}
            </div>
          }
        >
          <ClassRedux
            {...{
              varName: "serviceDefinitions",
              stateName: "serviceDefinitions",
              setState: (defResult) => {
                this.serviceDefinitionsData["serviceDefinitions"] = defResult;
                if (
                  this?.serviceDefinitionsData?.serviceDefinitions !==
                    undefined &&
                  this?.serviceDefinitionsData?.proposedTypeOfWork !== undefined
                ) {
                  //if both are assigned
                  this.setState({
                    serviceDefinitionsData: this?.serviceDefinitionsData,
                  });
                }
              },
              notNested: true,
            }}
          />
          <ClassRedux
            {...{
              varName: "proposedTypeOfWork",
              stateName: "proposedTypeOfWork",
              setState: (defResult) => {
                this.serviceDefinitionsData["proposedTypeOfWork"] = defResult;
                if (
                  this?.serviceDefinitionsData?.serviceDefinitions !==
                    undefined &&
                  this?.serviceDefinitionsData?.proposedTypeOfWork !== undefined
                ) {
                  //if both are assigned
                  this.setState({
                    serviceDefinitionsData: this?.serviceDefinitionsData,
                  });
                }
              },
            }}
          />
          <LoadableComp loading={false} className="fullWidth">
            {!editMode ? (
              <div className="scheduleStepperContainer">
                <Stepper
                  {...{
                    stepperClassName: "scheduleStepper",
                    currentStep: current,
                    setCurrentStep: this.onchangeStepper,
                    steps: steps?.map((step) => ({
                      ...step,
                      disabled: true,
                    })),
                    stepRenderer: false,
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <>
              <Spin
                spinning={this?.state?.showLoading}
                fullscreen
                tip={`Loading important data for the selected project ${
                  firstPage_InformativeVariables?.typeOfWork &&
                  "and type of work"
                }...`}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 10,
                  gap: "0.7rem",
                  height: "100%",
                }}
                onClick={
                  this?.props?.editMode && !this?.props?.isWritable
                    ? () =>
                        message.error({
                          content: "Please enable Edit Mode!",
                          key: "scheduleTour",
                        })
                    : ""
                }
              >
                {!editMode && (
                  <div
                    style={{
                      display: current === 0 ? "block" : "none",
                      height: "100%",
                    }}
                  >
                    <FormSection
                      {...{
                        project:
                          this?.props?.projectObj || this?.state?.project,
                        schedules:
                          this?.state?.schedulesThatBelongToProjectSelected ||
                          [],
                        setFirstPageVariables,
                        firstPage_InformativeVariables,
                        weatherNotFound,
                        toBeScheduled: this?.state?.toBeScheduled || {},
                        fullWeatherArray,
                        selectTypeOfRemoval,
                        handleWeather,
                        projectAddress: this?.props?.projectAddress,
                        projectId:
                          this?.props?.projectId ||
                          this?.state?.firstPage_InformativeVariables
                            ?.projectId,
                        projectManagers: this?.props?.projectManagers || [],
                        projectExecutive: !!this?.props?.requestId
                          ? this?.props?.projectExecutive
                          : editMode
                          ? schedule?.projectExecutive
                          : project?.projectExecutive,
                        selectedTeams: this?.state?.selectedTeams,
                        setSelectedTeams: this.changeSelectedTeamsHandler,
                        isDarkMode: this?.props?.isDarkMode,
                        allProjects: this?.state?.projects || [],
                      }}
                    />
                  </div>
                )}

                {current === 1 && (
                  <div
                    id="scheduleDaysContainer-servicesContainer"
                    style={{
                      display: current === 1 ? "block" : "none",
                      height: "100%",
                      // opacity: 0.9,
                    }}
                  >
                    <WrappedScheduleDays
                      {...{
                        updateProgressLogs: this.updateProgressLogs,
                        scheduleCrews: this?.state?.scheduleCrews || [],
                        updateScheduleCrews: this.updateScheduleCrews,
                        updateProgressOfAllSchedule: (progress = 0) => {
                          this.setState({
                            totalProgressOfAllSchedule: progress,
                          });
                        },
                        updateLastProgressDate: (date) => {
                          this.setState({
                            lastProgressDay: date,
                          });
                        },
                        schedulesThatBelongToProjectSelected:
                          this?.state?.schedulesThatBelongToProjectSelected ||
                          [],
                        dispatchForSchedule:
                          this?.props?.dispatchForSchedule || [],
                        setDisptachDays: this.setDisptachDays,
                        thisProjectSchedules:
                          this?.props?.scheduleProps?.schedules?.filter(
                            (a) =>
                              a?.scheduleId !== this?.props?.rowData?.scheduleId
                          ) || [],
                        unChangedRowData: editMode
                          ? this?.props?.unChangedRowData
                          : {
                              unchanged:
                                filterSchedulesByProject(
                                  this?.state
                                    ?.schedulesThatBelongToProjectSelected,

                                  editMode
                                    ? this?.props?.rowData?.typeOfWork
                                    : this?.state
                                        ?.firstPage_InformativeVariables
                                        ?.typeOfWork
                                )?.sort(
                                  (a, b) =>
                                    moment(a?.createdAt) - moment(b?.createdAt)
                                )[0] || {},
                            },
                        project: this?.state?.project,
                        specificRowData: this?.props?.rowData,
                        updateGoogleDriveFolders:
                          this?.updateGoogleDriveFolders,
                        googleDriveFolderIds: this?.state?.googleDriveFolderIds,
                        addGoogleDriveFolderToQueue:
                          this?.addGoogleDriveFolderToQueue,
                        fullWeatherArray,
                        typeOfRemoval: !editMode
                          ? this?.state?.typeOfRemoval
                          : this?.props?.rowData?.removalType,
                        scheduleNotes,
                        toBeScheduled,
                        crewsInfo,
                        wantsToSave,
                        scheduleDays,
                        current,
                        schedule,
                        ableToSave: this.ableToSave,
                        setAbleToSave,
                        serviceDefinitionsData,
                        editMode,
                        firstPage_InformativeVariables,
                        fleetInfo,
                        save_scheduleDays_and_toBeScheduled,
                        handlePreviousNext,
                        setFirstPageVariables,
                        documentsLoading,
                        notesChanged,
                        notesAccessRights,
                        approvalsWritable: this?.props?.isWritable,
                        isDarkMode: this?.props?.isDarkMode,
                        newCrewsUpdate: !!editMode
                          ? "scheduleCrews" in (this?.props?.rowData || {})
                          : true,
                      }}
                    />
                  </div>
                )}
                {/* <div style={{ display: current === 2 ? "block" : "none" }}>
                  <DocumentationStep {...{ current }} />
                </div>

                <div style={{ display: current === 3 ? "block" : "none" }}>
                  <ScheduleDetails
                    {...{
                      schedule: scheduleChecksState,
                      editStyle: true,
                      scheduleChecksState,
                      toggleScheduleChecks,
                    }}
                  />
                </div> */}
              </div>
            </>
            {this?.state?.openOverview && (
              <PdfProjectPreview
                {...{
                  previewVisibile: this?.state?.openOverview,
                  schedule: data,
                  projectAccountName: project?.accountName,
                  services: Object.values(toBeScheduled)?.flat() || [],
                  userConfiguration: this?.props?.userConfiguration,
                  setPreviewVisible: (openOverview) =>
                    this.setState({ openOverview }),
                  darkMode: this?.props?.isDarkMode,
                }}
              />
            )}
            {this?.state?.openOverviewPrev && (
              <PdfProjectPreview
                {...{
                  previewVisibile: this?.state?.openOverviewPrev,
                  schedule: [checkAllSchedules[checkAllSchedules?.length - 1]],
                  projectAccountName: project?.accountName,
                  userConfiguration: this?.props?.userConfiguration,
                  setPreviewVisible: (openOverviewPrev) =>
                    this.setState({ openOverviewPrev }),
                  darkMode: this?.props?.isDarkMode,
                }}
              />
            )}
            {this?.state?.noDataFound && (
              <NoDataFound
                {...{
                  noDataVisible: this?.state?.noDataFound,
                  setNoDataVisible: (noDataFound) =>
                    this.setState({ noDataFound }),
                  userConfiguration: this?.props?.userConfiguration,
                  getFirstStep: () => this.setState({ current: 0 }),
                  editMode,
                  isDarkMode: this?.props?.isDarkMode,
                }}
              />
            )}
            {this.state.openInspectionOverviewprev && (
              <HoistInspectionsRaport
                {...{
                  open: this.state.openInspectionOverviewprev,
                  setOpen: (openInspectionOverviewprev) =>
                    this.setState({ openInspectionOverviewprev }),
                  rowData: checkAllSchedules[checkAllSchedules?.length - 1],
                  projectAccountName: project?.accountName,
                  userConfiguration: this?.props?.userConfiguration,
                  isDarkMode: this?.props?.isDarkMode,
                }}
              />
            )}
          </LoadableComp>

          {this?.state?.confirmCloseModalVisible && (
            <WarningModal
              visible={this.state.confirmCloseModalVisible}
              setVisible={() =>
                this.setState({ confirmCloseModalVisible: false })
              }
              title="Warning Message"
              closable={true}
              className="logout-warning-modal"
              onKeyPress={(e) => this.onEnterPress(e)}
              darkMode={this?.props?.isDarkMode}
            >
              <div className="logout-modal-body">
                <span>
                  <WarningIcon />
                </span>
                <p>Are you sure to close this window?</p>
                <div className="buttons" style={{ gap: 8 }}>
                  <MondayButton
                    onClick={() =>
                      this.setState({ confirmCloseModalVisible: false })
                    }
                    Icon={<CloseIcon />}
                    className="mondayButtonRed"
                  >
                    No
                  </MondayButton>
                  <MondayButton
                    onClick={() => {
                      if (this?.props?.editMode)
                        driveFolderFunction({
                          driveRequest: this.props.driveRequest,
                          action: "delete",
                          googleDriveFolderIds: this.state.googleDriveFolderIds,
                          oldGoogleDriveFolderIds:
                            this?.props?.rowData?.googleDriveFolderIds,
                          updateGoogleDriveFolders:
                            this?.updateGoogleDriveFolders,
                          day: this?.state?.scheduleDays?.map(({ endDate }) =>
                            dayjs(endDate)?.format("MM/DD/YYYY")
                          ),
                        });
                      this.onCloseModal();
                    }}
                    Icon={<Tick width={19} height={19} />}
                  >
                    Yes
                  </MondayButton>
                </div>
              </div>
            </WarningModal>
          )}
          {this?.state?.confirmCreationModalVisible && (
            <WarningModal
              visible={this?.state?.confirmCreationModalVisible}
              setVisible={() =>
                this.setState({ confirmCreationModalVisible: false })
              }
              title="Warning Message"
              closable={true}
              className="logout-warning-modal"
              onKeyPress={(e) => this.onEnterCreateSch(e)}
              darkMode={this?.props?.isDarkMode}
            >
              <div className="logout-modal-body">
                <span>
                  <WarningIcon />
                </span>
                <p>Are you sure you want to create this schedule?</p>

                <div className="buttons">
                  <MondayButton
                    onClick={() =>
                      this.setState({ confirmCreationModalVisible: false })
                    }
                    Icon={<CloseIcon />}
                    className="mondayButtonRed"
                  >
                    No
                  </MondayButton>
                  <MondayButton
                    onClick={this.openSetTasks}
                    Icon={<Tick width={19} height={19} />}
                  >
                    Yes
                  </MondayButton>
                </div>
              </div>
            </WarningModal>
          )}
          {this?.state?.openSaveModal && (
            <SaveScheduleModal
              {...{
                openSaveModal: this?.state?.openSaveModal,
                mainText: "Schedule confirmation!",
                isDarkMode: this?.props?.isDarkMode,
                secondText: `Hi ${
                  this?.props?.userConfiguration?.nameOfUser
                }, please confirm that you want to ${
                  schedule ? "save" : "create"
                } this schedule.`,
                onCancel: () => this.setState({ openSaveModal: false }),
                onSave: () => {
                  this.setState({ wantsToSave: true, openSaveModal: false });
                },
              }}
            />
          )}
        </Modal>
        {this?.state?.openDispatchDays && (
          <DispatchScheduleDayModal
            {...{
              dispatchDays: this?.state?.dispatchDays,
              openDispatchDays: this?.state?.openDispatchDays,
              setOpenDispatchDays: (openDispatchDays) => {
                this.setState({ openDispatchDays });
              },
              notifyFleetUsers: this.notifyFleetUsers,
              isDarkMode: this?.props?.isDarkMode,
              projectId: this.props?.rowData?.projectId,
              scheduleId: this?.props?.rowData?.scheduleId,
              scheduleAddress: this?.props?.rowData?.scheduleAddress,
              scheduleName: this?.props?.rowData?.scheduleName,
              dispatchForSchedule: this?.props?.dispatchForSchedule || [],
            }}
          />
        )}
        {this?.state?.openProgressLogs && (
          <SchedulingProgressTrack
            {...{
              openProgressLogs: this?.state?.openProgressLogs || false,
              onCancel: () => this.setState({ openProgressLogs: false }),
              progressLogs: this?.state?.progressLogs || [],
              fetchFUNCTION: () =>
                filterTables(
                  "progressItems",
                  "scheduleId",
                  schedule?.scheduleId
                ),
              infoHeaderDisplayed,
              view: "forSchedule",
            }}
          />
        )}
      </SchedulingContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    serviceDefinitions,
    accessToken: { accessToken },
    proposedTypeOfWork: { proposedTypeOfWork },
  } = state;

  return { accessToken, serviceDefinitions, proposedTypeOfWork };
};

export default connect(mapStateToProps)(NewSchedule);
