import { useState } from "react";
import { MondayButton } from "../../../../../commonComponents";
import { OpenInNewTabIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { useRedux } from "../../../../hooks/useRedux";
import { DollarIcon } from "../../../components/DataEntryGridModal/assets";
import HoistPriceCalculatorModal from "../HoistPriceCalculatorModal/HoistPriceCalculatorModal";
import HoistPricingInfoModal from "../HoistPricingInfoModal/HoistPricingInfoModal";
import JumpsModal from "../JumpsModal/JumpsModal";
import {
  applyPriceSheetSuggestionToAll,
  duplicateElevation,
  removeElevation,
} from "./elevationPanel";
import useTakeOffAccessRights from "../../tools/controllers/useTakeOffAccessRights";
import { checkIfServiceIsHoist } from "../../models/Service";
import Phases from "./Phases/Phases";
import RentalTermsElevation from "../ServiceHeader/ServiceActions/RentalTermsElevation/RentalTermsElevation";
import { InfoIcon } from "../../../../Inspections/assets";
import { JumpsConfigModal2 } from "../../../../../Header/forms/Scheduling/PLIPerService/componentsForPli/HoistElements/JumpsConfig/JumpsConfigModal2";
import MoreOptions from "./MoreOptions/MoreOptions";
import { LockFilled, UnlockFilled } from "@ant-design/icons";
import _ from "lodash";
import Swal from "sweetalert2";
import TableHoistPriceCalculator from "../TableHoistPriceCalculator/TableHoistPriceCalculator";

/*
buttons on thr right side of elevation
 - applyPriceSheetSuggestionToAll (only for sidewalk shed)
 - duplicateElevation
 - removeElevation
*/

export default function ElevationPanelExtra({
  service,
  gridData,
  updateStateAndSave,
  selectedPriceSchemesForService,
  serviceIndex,
  optionIndex,
  elevationIndex,
  agGridApiForEachElevation,
  serviceDefs = [],
  stateSetter,
  handleSave,
  priceView,
  priceEdit,
  accessRights,
  forceLoad,
}) {
  const [jumpsModalVisible, setJumpsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [priceCalculatorModalVisible, setPriceCalculatorModalVisible] =
    useState(false);
  const [pricingInfoModalVisible, setPricingInfoModalVisible] = useState(false);
  const [isWritable] = useRedux("estimationsIsWritable");
  const {
    canViewPrice = false,
    canAddOrRemoveElevations = false,
    canSeeRentals = false,
  } = useTakeOffAccessRights("Take-Off");
  // console.log(accessRights)
  let hasAccessRights = accessRights.children.find(
    (item) => item.title === "Price Edit"
  );

  console.log("hasAccessRights", hasAccessRights);
  // console.log(hasAccessRights)

  const elevation =
    gridData?.[serviceIndex]?.serviceOptions?.[optionIndex]?.[elevationIndex];

  const mondayBtnStyle = {
    display: "inline-block",
    marginRight: 10,
  };

  const lockUnlockShortcut = (toLock) => {
    const temp = _.cloneDeep(gridData);
    const elevation =
      temp[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    elevation.items.forEach((pli) => {
      pli.lock = toLock;
    });

    updateStateAndSave(temp);
    Swal.fire({
      icon: "success",
      title: `All PLI's are ${toLock ? "locked" : "unlocked"}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div
      style={{
        // backgroundColor: "red",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        gap: 10,
      }}
    >
      {" "}
      <Phases
        {...{
          isScope: service?.isScope,
          serviceDefs,
          indexes: {
            serviceIndex,
            optionIndex,
            elevationIndex,
          },
        }}
      />
      {(service.serviceId.toString() === "3" ||
        service?.serviceId?.toString() === "38") &&
        canViewPrice && (
          <>
            <MondayButton
              className={!canViewPrice ? "mondayButtonRed" : "mondayButtonBlue"}
              // Icon={<InfoIcon />}
              disabled={!canViewPrice}
              onClick={(e) => {
                e.stopPropagation();

                setPricingInfoModalVisible(true);
              }}
              hasIcon={false}
              style={mondayBtnStyle}
            >
              Price Info
            </MondayButton>
            <TableHoistPriceCalculator
              {...{
                indexes: {
                  serviceIndex,
                  optionIndex,
                  elevationIndex,
                },
                service,
                updateStateAndSave,
              }}
            />
            <MondayButton
              className={!canViewPrice ? "mondayButtonRed" : "mondayButtonBlue"}
              Icon={<DollarIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setPriceCalculatorModalVisible(true);
              }}
              disabled={!hasAccessRights || !isWritable || !canViewPrice}
              style={mondayBtnStyle}
            >
              Price Terms
            </MondayButton>
          </>
        )}
      {/* {[
        service.serviceId === 1 && priceView === true && !!hasAccessRights
          ? applyPriceSheetSuggestionToAll(
              isWritable,
              gridData,
              updateStateAndSave,
              selectedPriceSchemesForService[service.serviceId]?.priceScheme ||
                {},
              serviceIndex,
              optionIndex,
              elevationIndex,
              agGridApiForEachElevation
            )
          : null,
        // !!canAddOrRemoveElevations &&
        //   duplicateElevation(
        //     serviceDefs,
        //     service.serviceId,
        //     isWritable,
        //     gridData,
        //     stateSetter,
        //     serviceIndex,
        //     optionIndex,
        //     elevationIndex
        //   ), //returns button
        // !!canAddOrRemoveElevations &&
        //   removeElevation(
        //     serviceDefs,
        //     isWritable,
        //     gridData,
        //     stateSetter,
        //     handleSave,
        //     serviceIndex,
        //     optionIndex,
        //     elevationIndex
        //   ),
      ]}{" "} */}
      <MoreOptions
        open={open}
        setOpen={setOpen}
        customRender={[
          () => {
            return (
              canSeeRentals && (
                <RentalTermsElevation
                  {...{
                    indexes: {
                      serviceIndex,
                      optionIndex,
                      elevationIndex,
                    },
                    elevation,
                    serviceDefs,
                  }}
                />
              )
            );
          },
          () => {
            return (
              !!canAddOrRemoveElevations &&
              removeElevation(
                serviceDefs,
                isWritable,
                gridData,
                stateSetter,
                handleSave,
                serviceIndex,
                optionIndex,
                elevationIndex,
                setOpen
              )
            );
          },
          () => {
            return (
              !!canAddOrRemoveElevations &&
              duplicateElevation(
                serviceDefs,
                service.serviceId,
                isWritable,
                gridData,
                stateSetter,
                serviceIndex,
                optionIndex,
                elevationIndex,
                setOpen,
                updateStateAndSave,
                forceLoad
              )
            );
          },
          () => {
            return service.serviceId === 1 &&
              priceView === true &&
              !!hasAccessRights
              ? applyPriceSheetSuggestionToAll(
                  isWritable,
                  gridData,
                  updateStateAndSave,
                  selectedPriceSchemesForService[service.serviceId]
                    ?.priceScheme || {},
                  serviceIndex,
                  optionIndex,
                  elevationIndex,
                  agGridApiForEachElevation
                )
              : null;
          },
          () => {
            return (
              service?.serviceId?.toString() === "3" && (
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<OpenInNewTabIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setJumpsModalVisible(true);
                  }}
                  disabled={!hasAccessRights || !isWritable}
                  style={mondayBtnStyle}
                >
                  Configure Jumps
                </MondayButton>
              )
            );
          },
          () => {
            if (checkIfServiceIsHoist(service)) return <></>;
            else
              return (
                <>
                  <MondayButton
                    className="mondayButtonGreen"
                    hasIcon={false}
                    onClick={() => lockUnlockShortcut(false)}
                  >
                    Unlock All PLI's
                  </MondayButton>

                  <MondayButton
                    className="mondayButtonRed"
                    hasIcon={false}
                    onClick={() => lockUnlockShortcut(true)}
                  >
                    Lock All PLI's
                  </MondayButton>
                </>
              );
          },
        ]}
      />
      {checkIfServiceIsHoist(service) && (
        <div onClick={(e) => e.stopPropagation()}>
          <JumpsModal
            visible={jumpsModalVisible}
            setVisible={setJumpsModalVisible}
            elevation={elevation}
            indices={{ serviceIndex, optionIndex, elevationIndex }}
          />
          {/* <JumpsConfigModal2
            {...{
              isFrom: "takeoff",
              elevationIndex,
              elevation,
              serviceId: service.serviceId,
              setHoistPLis: () => {},
              typeOfWork: "Installation",
            }}
          /> */}
          <HoistPriceCalculatorModal
            visible={priceCalculatorModalVisible}
            setVisible={setPriceCalculatorModalVisible}
            elevation={elevation}
            indices={{ serviceIndex, optionIndex, elevationIndex }}
          />
          <HoistPricingInfoModal
            visible={pricingInfoModalVisible}
            setVisible={setPricingInfoModalVisible}
            elevation={elevation}
          />
        </div>
      )}
    </div>
  );
}
