import types from '../types';

const accounts = (state = [], action) => {
    switch (action.type) {
        case types.SET_ACCOUNTS:
            return action.payload
        default: return state;

    }
}
export default accounts;