import {
  axesTooltipCustom,
  axesTooltipWithCurrency,
} from "../../../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "client",
    groupKey: "customer",
    xAxisName: "Client",
  },
  {
    chartName: "status",
    groupKey: "invoiceStatus",
    xAxisName: "Status",
  },
  {
    chartName: "project",
    groupKey: "projectName",
    xAxisName: "Project Name",
  },
  {
    chartName: "createdBy",
    groupKey: "createdBy.name",
    xAxisName: "Invoice",
  },

  {
    chartName: "clientAmount",
    groupKey: "customer",
    xAxisName: "Client",
    calcKey: "amount",
  },
  {
    chartName: "statusAmount",
    groupKey: "invoiceStatus",
    xAxisName: "Status",
    calcKey: "amount",
  },
  {
    chartName: "projectAmount",
    groupKey: "projectName",
    xAxisName: "Project Name",
    calcKey: "amount",
  },
];

export const getChartingOptions = (data) => {
  let client = new Set();
  let clientAmount = new Set();
  let status = new Set();
  let statusAmount = new Set();
  let project = new Set();
  let projectAmount = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const chargeProject = item?.customer || "No Data";
    const invoiceStatus = item?.invoiceStatus || "No Data";
    const chargetype = item?.projectName || "No Data";
    const user = item?.createdBy?.name || "No Data";

    client.add(chargeProject);
    clientAmount.add(chargeProject);
    status.add(invoiceStatus);
    statusAmount.add(invoiceStatus);
    project.add(chargetype);
    projectAmount.add(chargetype);
    createdBy.add(user);
  });

  const countOnClient = {
    title: {
      text: "Total Invoice Count",
    },
    subtitle: {
      text: "Based on Client",
    },
    series:
      Array.from(client).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item.replace(".", ""),
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Invoice"),
          },
        };
      }) || [],
  };

  const countOnStatus = {
    title: {
      text: "Total Invoice Count",
    },
    subtitle: {
      text: "Based on Status",
    },
    series:
      Array.from(status).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Invoice"),
          },
        };
      }) || [],
  };

  const countOnProject = {
    title: {
      text: "Total Invoice Count",
    },
    subtitle: {
      text: "Based on Project",
    },
    series:
      Array.from(project).map((type) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: type.replace(".", ""),
          yName: type,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Invoice"),
          },
        };
      }) || [],
  };

  const countOnCreatedBy = {
    title: {
      text: "Total Invoice Count",
    },
    subtitle: {
      text: "Based on Created By",
    },
    series:
      Array.from(createdBy).map((user) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: user,
          yName: user,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Invoice"),
          },
        };
      }) || [],
  };

  const countOnClientAmount = {
    title: {
      text: "Total Receive Payments Amount",
    },
    subtitle: {
      text: "Based on Client",
    },
    series:
      Array.from(clientAmount).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipWithCurrency(params),
          },
        };
      }) || [],
  };

  const countOnStatusAmount = {
    title: {
      text: "Total Receive Payments Amount",
    },
    subtitle: {
      text: "Based on Status",
    },
    series:
      Array.from(statusAmount).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipWithCurrency(params),
          },
        };
      }) || [],
  };

  const countOnProjectAmount = {
    title: {
      text: "Total Receive Payments Amount",
    },
    subtitle: {
      text: "Based on Project",
    },
    series:
      Array.from(projectAmount).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipWithCurrency(params),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle: "Show total Invoice count based on Client",
      params: countOnClient,
      key: "client",
    },
    {
      optionTitle: "Show total Invoice count based on Status",
      params: countOnStatus,
      key: "status",
    },
    {
      optionTitle: "Show total Invoice count based on Project",
      params: countOnProject,
      key: "project",
    },
    {
      optionTitle: "Show total Invoice count based on Created By",
      params: countOnCreatedBy,
      key: "createdBy",
    },

    {
      optionTitle: "Show total Invoice amount based on Client",
      params: countOnClientAmount,
      key: "clientAmount",
      calculate: true,
    },
    {
      optionTitle: "Show total Invoice amount based on Status",
      params: countOnStatusAmount,
      key: "statusAmount",
      calculate: true,
    },
    {
      optionTitle: "Show total Invoice amount based on Project",
      params: countOnProjectAmount,
      key: "projectAmount",
      calculate: true,
    },
  ];

  return chartingOptions;
};
