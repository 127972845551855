import { useEffect, useState } from "react";
import SignaturePad from "react-signature-canvas";
import "./SignatureModal.scss";
import RedWarningModal from "../../../RedWarningModal/RedWarningModal";
import { DrawIcon } from "../../../../pages/ScheduleProgress/assets/images";

const SignatureModal = ({
  visible,
  setVisible,
  signature,
  setSignature,
  editSignIn = false,
  setEditSignIn = () => {},
}) => {
  const [signatureRef, setSignatureRef] = useState(null);
  const [defaultSign, setDefaultSign] = useState(null);
  const [dragged, setDragged] = useState(false);

  useEffect(() => {
    if (editSignIn && signature && signatureRef) {
      signatureRef.fromDataURL(signature);
      setDefaultSign(signature);
      setDragged(true);
    } else if (!editSignIn && signatureRef) {
      signatureRef.clear();
      setDragged(false);
    }
  }, [editSignIn, signature, signatureRef]);

  const editSign = () => {
    setDragged(false);
    setSignature?.(defaultSign);
    signatureRef.fromDataURL(defaultSign);
  };

  const resetSign = (isEdit) => {
    setDragged(false);
    setSignature?.(null);
    signatureRef.clear();

    isEdit && editSign();
  };

  const handleOnSignatureSave = () => {
    setSignature?.(signatureRef?.getTrimmedCanvas()?.toDataURL("image/png"));
    setDragged(false);
    signatureRef.clear();
    !!editSignIn && setEditSignIn(false);
    setVisible(false);
  };
  return (
    <RedWarningModal
      {...{
        visible,
        onCancel: () => {
          setVisible(false);
        },
        confirmModal: true,
        titleText: "Signature",
        TitleIcon: DrawIcon,
        modalClassName: "signatureModal",
        transformIcon: { transform: "scale(3)", color: "#ACACAC" },
        footer: [
          {
            text: "Cancel",
            onClick: () => {
              setVisible(false);
              !editSignIn ? resetSign() : setEditSignIn(false);
            },
            primary: false,
            hasIcon: false,
            className: "mondayButtonGrey",
          },
          {
            text: "Reset",
            onClick: () => (!!editSignIn ? resetSign(true) : resetSign()),
            danger: true,
            disabled: !dragged,
            hasIcon: false,
            className: "mondayButtonRed",
          },
          {
            text: "Save",
            onClick: () => handleOnSignatureSave(),
            primary: true,
            disabled: !dragged,
            hasIcon: false,
            className: "mondayButtonGreen",
          },
        ],
      }}
    >
      <div style={{ boxShadow: "0px 1px 4px #00000029" }}>
        <SignaturePad
          canvasProps={{ width: 500, height: 160, className: "sigCanvas" }}
          ref={(ref) => setSignatureRef(ref)}
          onBegin={() => setDragged(true)}
        />
      </div>
    </RedWarningModal>
  );
};

export default SignatureModal;
