export const chargeCategories = [
  { title: "Rental" },
  { title: "Requisition" },
  { title: "Estimation" },
  { title: "Schedule Of Value" },
  { title: "Other" },
];

export const chargeTypes = ["Charge", "Credit Memo"];

export const convertCategory = {
  Rental: "rentals",
  Requisition: "applications",
  Estimation: "estimations",
};
