import { SimplePLI } from "./GeneralModels";

export class EngEnclosureSystemPli extends SimplePLI {
  serviceId = 81;

  length;
  height;

  fromFloor;

  toFloor;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateEngEnclosureSystemPli extends SimplePLI {
  serviceId = 81;

  length;
  height;

  fromFloor;

  toFloor;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
