import { get_ProgressDimensionsByServiceId } from "../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import _ from "lodash";

export const clearScheduleFromProgress = (toBeScheduled) => {
  //to be done in the future
  const servicesLists = Object.values(toBeScheduled);

  for (const services of servicesLists) {
    for (const service of services) {
      for (const elevation of service.serviceOptions[0]) {
        //clear elevations
        for (const pli of elevation.items) {
          if (pli.subPli && pli.subPli.length > 0) {
            for (const subPli of pli.subPli) {
              setProgressDimensionsTo0(subPli);
            }
          }
          this.setProgressDimensionsTo0(pli);
          if (pli.addons && pli.addons.length > 0) {
            for (const addon of pli.addons) {
              setProgressDimensionsTo0(addon);
            }
          }
        }
      }

      if (service.serviceAddons)
        for (const addon of service.serviceAddons) {
          //clear addons
          setProgressDimensionsTo0(addon);
        }
    }
  }
};

export const setProgressDimensionsTo0 = (pli) => {
  if (pli.progressLength) pli.progressLength = 0;
  if (pli.progressWidth) pli.progressWidth = 0;
  if (pli.progressHeight) pli.progressHeight = 0;
};

/**
 * @param schedule_toBeScheduled {{}} - services with progress
 * @param project_estimationServices {{}} - original services from project. project.services
 * A services object with be created with all remaining
 * */
export const calculateServicesRemaining = (
  schedule_toBeScheduled,
  project_estimationServices
) => {
  //to be done in the future

  const toBeScheduled = _.cloneDeep(schedule_toBeScheduled);
  const estimationServices = _.cloneDeep(project_estimationServices);

  const remainingServicesByEstimations = {};

  for (const [estimationId, services] of Object.entries(estimationServices)) {
    if (toBeScheduled[estimationId] === undefined) {
      //if the whole estimation has not been planned add it and continue with the other estimation
      remainingServicesByEstimations[estimationId] = services;
      continue;
    }

    const remainingServices = [];

    for (let serviceIndex = 0; serviceIndex < services.length; serviceIndex++) {
      const service = services[serviceIndex];
      if (
        toBeScheduled[estimationId][serviceIndex] === undefined ||
        service.serviceId === 3 /*just continue for hoist*/
      ) {
        //if service don't exist in toBeScheduled, add it into the remaining list and continue
        remainingServices[serviceIndex] = service;
        continue;
      }

      const remainingElevations = [];

      for (
        let elevationIndex = 0;
        elevationIndex < service.serviceOptions[0].length;
        elevationIndex++
      ) {
        //clear elevations
        const elevation = service.serviceOptions[0][elevationIndex];

        if (
          toBeScheduled[estimationId][serviceIndex].serviceOptions[0][
            elevationIndex
          ] === undefined
        ) {
          remainingElevations[elevationIndex] = elevation;
          continue;
        }

        const remainingItems = [];

        for (let pliIndex = 0; pliIndex < elevation.items.length; pliIndex++) {
          const pli = elevation.items[pliIndex];

          const pli_fom_toBeScheduled =
            toBeScheduled[estimationId][serviceIndex].serviceOptions[0][
              elevationIndex
            ].items[pliIndex];

          if (pli_fom_toBeScheduled === undefined) {
            remainingItems[pliIndex] = pli;
            continue;
          }

          // console.log({pli, pli_fom_toBeScheduled,})
          //subtract from pli
          setRemainingInPLI(pli, pli_fom_toBeScheduled, service.serviceId);
          pli["breakdownValue"] = pli_fom_toBeScheduled["breakdownValue"]; //also save breakdowns progress
          remainingItems[pliIndex] = pli;
        }
        const filtered_items = remove_PLI_with_0_dimension(
          remainingItems,
          service.serviceId
        );
        // console.log(remainingItems, filtered_items)
        remainingElevations[elevationIndex] = {
          ...elevation,
          items: filtered_items,
        };
      }

      remainingServices[serviceIndex] = {
        ...service,
        serviceOptions: [
          remainingElevations.filter((elevation) => elevation.items.length > 0),
        ],
      };
      // if (service.serviceAddons)
      //     for (const addon of service.serviceAddons) { //clear addons
      //         setProgressDimensionsTo0(addon)
      //     }
    }

    remainingServicesByEstimations[estimationId] = remainingServices.filter(
      (service) => service.serviceOptions[0].length > 0
    );
  }

  const filtered_remainingServicesByEstimations = {};

  for (const [estimationId, services] of Object.entries(
    remainingServicesByEstimations
  )) {
    if (services.length > 0)
      filtered_remainingServicesByEstimations[estimationId] = services;
  }

  return filtered_remainingServicesByEstimations;
};

export const setRemainingInPLI = (originalPLI, progressPLI, serviceId) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  // console.log("Funksioni i Jonuzit",{originalPLI, progressPLI, d1, d2})

  if (d2 === undefined) {
    originalPLI[d1] -= progressPLI["totalProgress"] || 0;
  } else {
    //if total surface is not defined, calculate it. Happens only in the first time remaining is saved in project
    if (isNaN(originalPLI["surface"]))
      originalPLI["surface"] =
        parseInt(originalPLI[d1]) * parseInt(originalPLI[d2]);

    originalPLI[d1] = "-";
    originalPLI[d2] = "-";
    originalPLI["surface"] -= progressPLI["totalProgress"] || 0;
  }
};

export const remove_PLI_with_0_dimension = (items, serviceId) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
  if (d2 === undefined) {
    return items.filter((pli) => parseInt(pli[d1]) > 0);
  } else {
    //for 2 dimensions do nothing at the moment
    return items.filter((pli) => parseInt(pli["surface"]) > 0);
  }
};

export const setItemWithSubPliProgressDays = (item, serviceId) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  if (item?.progressByDay && item?.subPli && item?.subPli?.length > 0) {
    let total_progress = 0;
    const new_progressByDay = [];
    let all_subPLi_progressByDays = [];

    for (const subPli of item.subPli) {
      all_subPLi_progressByDays = all_subPLi_progressByDays.concat(
        subPli.progressByDay
      );
    }

    for (const day of item?.days) {
      let total_progress_this_of_this_day = 0;

      //get dayProgress from all sub plis of this day
      const needed_progressByDays = all_subPLi_progressByDays.filter(
        (progressByDay) => progressByDay.day === day
      );

      for (const neededProgressByDay of needed_progressByDays) {
        total_progress_this_of_this_day += getDayProgress(neededProgressByDay, {
          d1,
          d2,
        });
      }

      new_progressByDay.push({
        day,
        progress: total_progress_this_of_this_day,
      });
      total_progress += total_progress_this_of_this_day;
    }

    item.progressByDay = new_progressByDay;
    item["totalProgress"] = total_progress;
    // console.log(total_progress)
  }
};

export const getItemProgress = (item, { d1, d2 }) => {
  let total_progress = 0;
  if (item?.progressByDay) {
    for (const progressByDay of item.progressByDay) {
      total_progress += getDayProgress(progressByDay, { d1, d2 });
    }
  }
  return total_progress;
};

export const getDayProgress = (progressByDay, { d1, d2 }) => {
  if (d2 === undefined) {
    return parseInt(progressByDay?.[d1]);
  } else {
    return parseInt(progressByDay?.[d1]) * parseInt(progressByDay?.[d2]);
  }
};

export const getBreakdownsForDays = (pli, dayIdList) =>
  pli?.breakdownValue?.map?.((b) => {
    let progress = {};
    for (const d of dayIdList || []) {
      progress[d] = b?.progress?.[d] || 0;
    }
    b.progress = progress;
    return b;
  });
