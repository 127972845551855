// import { v4 as uuidv4 } from 'uuid';
const { v4: uuidv4 } = require("uuid");

interface PriceScheme {
  id: String;
  name: String;
}

export class PriceSheet implements PriceScheme {
  id: String;
  name: String;
  x: Array<String>;
  y: Array<String>;
  z: Array<Array<String>>;
  type: String;

  constructor(
    name: String,
    x: Array<String> | undefined = [],
    y: Array<String> | undefined = [],
    z: Array<Array<String>> | undefined = [[]],
    type: String | undefined = "service"
  ) {
    this.id = uuidv4(); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    this.name = name;
    this.x = x;
    this.y = y;
    this.z = z;
    this.type = type;
  }
}

export class PriceList implements PriceScheme {
  id: String;
  name: String;
  prices: Array<{ name: String; value: Number }>;

  constructor(
    name: String,
    prices: Array<{ name: String; value: Number }> | undefined = []
  ) {
    this.id = uuidv4(); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    this.name = name;
    this.prices = prices;
  }
}

export class PriceTable implements PriceScheme {
  id: String;
  name: String;
  prices: Array<Object>;

  constructor(name: String, prices: Array<Object>) {
    this.id = uuidv4();
    this.name = name;
    this.prices = prices;
  }
}

export default class PricingObject {
  id: String;
  priceSchemesType: String;
  priceSchemes: Array<PriceSheet> | Array<PriceList>;
  addonsList: Array<Addon> | [];
  addonsColumnDefs:
    | Array<{
        name: String;
        type: String;
        addonType: String;
        id: String | any;
        category: String;
      }>
    | [];

  /**
   * Do not use constructor to avoid mistakes. Use WithPriceList and WithPriceSheet instead
   * */
  constructor(
    prices: any,
    priceSchemesType: String,
    addonsList: Array<Addon> = [],
    addonsColumnDefs: Array<{
      name: String;
      type: String;
      addonType: String;
      id: String;
      category: String;
    }> = []
  ) {
    this.id = uuidv4(); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    this.priceSchemes = prices;
    this.priceSchemesType = priceSchemesType;
    this.addonsList = addonsList;
    this.addonsColumnDefs = addonsColumnDefs;
  }

  static WithPriceList(
    prices: Array<PriceList> = [],
    addonsList: Array<Addon> = []
  ) {
    return new PricingObject(prices, PriceSchemesType.LIST, addonsList);
  }

  static WithPriceSheet(
    prices: Array<PriceSheet> = [],
    addonsList: Array<Addon> = []
  ) {
    return new PricingObject(prices, PriceSchemesType.SHEET, addonsList);
  }

  static withPriceTable(prices: Object, addonsList: Array<Addon> = []) {
    return new PricingObject(prices, PriceSchemesType.TABLE, addonsList);
  }
}

export const PriceSchemesType = {
  SHEET: "sheet",
  LIST: "list",
  TABLE: "table",
};

export interface Addon {
  id: String;
  type: String;
  values: Array<{ name: String; type: String; id: String }>;
}

export const AddonType = {
  pli: "pli",
  serviceAddon: "service addon" /*documentation: "documentation"*/,
};

export const ColumnCategory = { dimension: "dimension", pricing: "pricing" };
