export const ADDRESS_FIELDS = {
  "Property Damage": "addressOfDamagedProperty",
  "Personal Injury": "addressOfInjury",
  "Vehicle Damage": "addressOfIncident",
  "Other Trade Incident": "incidentAddress",
};

export const NAME_KEY = {
  "Property Damage": "addressOfDamagedProperty",
  "Personal Injury": "fullNameOfInjuredIndividual",
  "Vehicle Damage": "addressOfIncident",
  "Other Trade Incident": "fullNameOfInjuredIndividual",
};

export const EDIT_ACTION = {
  "Property Damage": "onPropertyDamageEdit",
  "Personal Injury": "onPersonalInjuryEdit",
  "Vehicle Damage": "onVehicleDamageEdit",
  "Other Trade Incident": "onOtherTradeIncidentEdit",
};

export const CREATE_ACTION = {
  "Property Damage": "onPropertyDamageCreate",
  "Personal Injury": "onPersonalInjuryCreate",
  "Vehicle Damage": "onVehicleDamageCreate",
  "Other Trade Incident": "onOtherTradeIncidentCreate",
};

export const DELETE_ACTION = {
  "Property Damage": "onPropertyDamageDelete",
  "Personal Injury": "onPersonalInjuryDelete",
  "Vehicle Damage": "onVehicleDamageDelete",
  "Other Trade Incident": "onOtherTradeIncidentDelete",
};

export const DRIVE_FOLDER_KEY = {
  "Property Damage": "addressOfDamagedProperty",
  "Personal Injury": "fullNameOfInjuredIndividual",
  "Vehicle Damage": "addressOfIncident",
  "Other Trade Incident": "fullNameOfInjuredIndividual",
};

export const DATE_KEY = {
  "Property Damage": "dateOfPropertyDamage",
  "Personal Injury": "dateOfInjury",
  "Vehicle Damage": "dateOfVehicleDamageOrIncident",
  "Other Trade Incident": "dateOfIncident",
};
