import { Slider } from "antd";
import React from "react";

const PercentageFilter = ({
  fields = "",
  filterStates = {},
  tmpFilterStates = {},
  filterValueTurnery = false,
  setFilterStates = () => {},
}) => {
  return (
    <div className="filterComponents">
      <div className="tittle">
        Filter By{" "}
        {fields === "engProgress" ? "Engineer Progress" : "Architect Progress"}
      </div>

      <div className="filterContainer">
        <div className="leftSideContainer">
          <div className="tittleContainer">Select progress range</div>
        </div>

        <div className="rightSideContainer slider-container">
          <Slider
            {...{
              defaultValue:
                parseInt(
                  (filterValueTurnery
                    ? filterStates?.filterByPercentage
                    : tmpFilterStates?.filterByPercentage
                  )?.replace("%", "")
                ) || 0,
              tooltip: {
                // open: true,
                formatter: (value) => `${value}%`,
                placement: "bottom",
              },
              step: 5,
              style: { width: "100%" },
              onChangeComplete: (value) => {
                setFilterStates((prev) => ({
                  ...prev,
                  filterByPercentage: `${value || 0}%`,
                }));

                const valueLabel = document.getElementById("progress-value");
                valueLabel.innerHTML = `${value}%`;
              },
            }}
          />
          <div className="slider-value" id="progress-value">
            {filterValueTurnery
              ? filterStates?.filterByPercentage
              : tmpFilterStates?.filterByPercentage}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PercentageFilter;
