import React from "react";
import { Select } from "antd";
import { LabeledFilter } from ".";
import { clearFilter, onFilter, compareIncluding } from "../utils";
import { DropDownArrow } from "../Fleet/components/InputComponent/assets";

const { Option } = Select;

const SelectFilter = ({
  label,
  formItemName,
  defaultValue,
  column,
  gridApi,
  form,
  filterType,
  options,
  isDarkMode,
  setFilterColor = () => {},
}) => (
  <LabeledFilter
    label={label}
    formItemName={formItemName}
    // initialValue={defaultValue}
  >
    <Select
      showSearch
      popupClassName={isDarkMode && "darkDropDown"}
      suffixIcon={<DropDownArrow />}
      onSelect={() => {
        const currentValues = {
          current: compareIncluding("status", label)
            ? compareIncluding("inspected", form.getFieldValue(formItemName))
              ? ["Passed, Failed"]
              : [form.getFieldValue(formItemName)]
            : form.getFieldValue(formItemName),
        };
        const defaultSelected = compareIncluding(
          defaultValue,
          form.getFieldValue(formItemName)
        );

        !defaultSelected
          ? onFilter({
              column,
              gridApi,
              type: filterType,
              ...currentValues,
            })
          : clearFilter({ column, gridApi });
        setFilterColor({ active: true, color: "#FE4C4A" });
      }}
    >
      {options.map((item, i) => (
        <Option key={i} value={item}>
          {item}
        </Option>
      ))}
    </Select>
  </LabeledFilter>
);

export default SelectFilter;
