import { API } from "aws-amplify";

export const fetchSearchRecords = async (
  tableName,
  searchKey,
  startWith,
  keysToInclude
) =>
  await API.get("searchRecords", "/searchRecords", {
    queryStringParameters: {
      tableName,
      searchKey,
      startWith,
      ...(keysToInclude && { keysToInclude: JSON.stringify(keysToInclude) }),
    },
  });
