import { Input } from "antd";
import "./Input.scss";

const FormItemInput = ({ value, form, name, disabled, isDarkMode }) => {
  return (
    <div className="EditInput">
      <Input
        className={`EditInputComponent ${isDarkMode && "EditInputComponentDark"}`}
        defaultValue={name === "taxRate" ? value * 100 : value}
        value={
          name === "taxRate"
            ? value.toString().split(".")[0] !== "0"
              ? value
              : value * 100
            : value
        }
        disabled={disabled}
        onChange={(e) =>
          form.setFieldsValue({
            [name]: e.target.value,
          })
        }
      />
    </div>
  );
};
export default FormItemInput;
