import {
  getApplications,
  getApplication,
} from "../../../../../../actions/applications";
import { useDispatchContext, useStateContext } from "./Context/Context";
import ServiceList from "./components/ServiceList/ServiceList";
import { getRentals } from "../../../../../../actions/rentals";
import Header from "./components/Header/Header";
import "./application-view.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Breadcrumb, Button, Layout, Menu } from "antd";
import { useUndoRedoState } from "../../../../../../hooks/useUndoRedoState";
import NotFound from "../../../../NotFound/NotFound";
import { filterTables } from "../../../../../../utils";
const { Content, Footer, Sider } = Layout;

const ApplicationView = ({ isNextStep, appId, closeModal }) => {
  const { application, applications, error } = useSelector(
    (state) => state.applications
  );
  const { rentals } = useSelector((state) => state.rentals);
  const { darkMode, fakeApplication } = useStateContext();
  const { applicationId: currentApplicationId } = useParams();
  const dispatch = useDispatch();
  const { programFields } = useSelector((state) => state.programFields);

  const applicationId = currentApplicationId || appId;
  //Undo Redo Limit for Requisitions
  const undoRedoLimit = programFields.find(
    (item) => item.fieldName === "Statuses Of Categories"
  ).fieldOptions?.["Requistion"];

  // Context dispatch
  const dispatchContext = useDispatchContext();

  const {
    itemToSaveActiveState: newFakeApplication,
    changeStateHandler,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    undoClickHandler,
    redoClickHandler,
  } = useUndoRedoState(undoRedoLimit);

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;

  const attachRentals = async () => {
    await filterTables("includedRentals", "applicationId", applicationId).then(
      (res) => {
        dispatchContext({ type: "ATTACH_RENTALS", payload: res });
      }
    );
  };

  // Fetch Application on mount or whenever company or application changes
  useEffect(() => {
    if (applicationId) {
      dispatch(getApplication(applicationId));
    }

    if (isNextStep && appId)
      dispatchContext({
        type: "SET_NEXT_STEP",
        payload: { isNextStep, appId, closeModal },
      });
  }, [JSON.stringify(applicationId)]);

  useEffect(() => {}, [applicationId]);

  // Keep Fake Application in Sync whenever Real Application updates
  useEffect(() => {
    if (application) {
      let theme = localStorage.getItem("themePreference");
      dispatchContext({ type: "SET_FAKE_APPLICATION", payload: application });
      dispatchContext({
        type: "SET_UNCHANGING_FAKE_APPLICATION",
        payload: application,
      });
      attachRentals();
      // dispatchContext({ type: "ATTACH_RENTALS", payload: [{ TEST: 3 }] });

      dispatch(getRentals(application?.projectId));
      dispatch(getApplications(application?.projectId));
    }
    window.addEventListener("beforeunload", (event) => {
      event.returnValue = `Are you sure you want to leave?`;
    });
  }, [application]);

  useEffect(() => {
    if (newFakeApplication)
      dispatchContext({
        type: "SET_FAKE_APPLICATION",
        payload: JSON.parse(JSON.stringify(newFakeApplication)),
      });
  }, [newFakeApplication]);

  useEffect(() => {
    if (fakeApplication)
      changeStateHandler(JSON.parse(JSON.stringify(fakeApplication)));
  }, [fakeApplication]);

  if (!application && error.status === 404) {
    return <NotFound />;
  }

  return (
    application && (
      <div className={darkMode ? "application-view-dark" : "application-view"}>
        <div className="application-view-body">
          <Header
            canUndo={canUndo}
            canRedo={canRedo}
            undoClickHandler={undoClickHandler}
            redoClickHandler={redoClickHandler}
            closeModal={closeModal}
          />
          <ServiceList />
        </div>
      </div>
    )
  );
};

export default ApplicationView;
