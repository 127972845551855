import { numberWithCommas } from "../../../../../utils/numberWithCommas";
import { roundToDecimals } from "../../../../../utils/roundToDecimals";
import { getServiceLegacyPrice } from "../../../../../Projects/Accounting/calculations/servicePrices";

import "./ServicesForEach.css";

export default function ServicesForEach({ estimation }) {
  return (
    estimation?.services?.length &&
    estimation?.services?.map((service) => {
      const total =
        typeof service === "object" ? getServiceLegacyPrice(service) : 0;
      return (
        <div className="service_price_total">
          <div className="pre_horizontal_line">
            <hr></hr>
          </div>
          <h4>{service.label}</h4>
          <div className="service_horizontal_line">
            <hr></hr>
          </div>
          <div className="service_price_wrapper">
            {isNaN(Number(total))
              ? "-"
              : numberWithCommas(roundToDecimals(total, 2))}
          </div>
        </div>
      );
    })
  );
}
