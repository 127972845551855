import { YARD_LOCATIONS } from "../data";

/**
 * Function that finds the bound extremities
 * @param {Array<{lat: number, lng: number}>} locationArr The array of coordinates
 */
function findBoundExtremities(locationArr) {
  if (!Array.isArray(locationArr)) {
    return [YARD_LOCATIONS()?.[0]].filter(Boolean);
  }
  if (locationArr.length < 5) {
    return locationArr;
  }

  let north = null,
    east = null,
    south = null,
    west = null;

  let latArr = locationArr?.map(({ lat = null }) => lat).filter(Boolean);
  let lngArr = locationArr?.map(({ lng = null }) => lng).filter(Boolean);

  north = Math.max(...latArr);
  south = Math.min(...latArr);
  east = Math.max(...lngArr);
  west = Math.min(...lngArr);

  return [
    { lat: north, lng: east },
    { lat: south, lng: west },
    { lat: north, lng: west },
    { lat: south, lng: east },
  ];
}

export default findBoundExtremities;
