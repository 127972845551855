import "./Live.scss";
import { useNavigate } from "react-router-dom";
import { LiveIcon } from "../../src";

const Live = ({
  content = "This is a description and always stays here.",
  path = "fleets/live",
}) => {
  const navigate = useNavigate();

  return (
    <div className="LiveCard">
      <span className="LiveContent">{content}</span>
      <div className="LiveButton" onClick={() => navigate(path)}>
        <span className="LiveButtonText">Live</span>
        <span className="LiveButtonDivider"></span>
        <span className="LiveButtonIcon">
          <LiveIcon />
        </span>
      </div>
    </div>
  );
};
export default Live;
