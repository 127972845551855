import "./CustomTransfer.scss";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { TransferIconNew } from "../../SidebarPages/DynamicView/src";
import { InputComponent } from "../../SidebarPages/Fleet/components";
import { useSelector } from "react-redux";
import CustomCheckBox from "../CustomCheckBox/CustomCheckBox";

const CustomTransfer = ({
  dataSource = [], // all element ids
  label = "",
  value: propValue = [], //nonHiddenIds
  disabled,
  className = "",
  onChange = () => {}, // its being called with nonHiddenIds
  required,
  redSectionTitle = "",
  greenSectionTitle = "",
  hasSearch = true,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [searchValues, setSearchValues] = useState({});

  const value = Array.isArray(propValue) ? propValue : [];

  const notTransferredItems = dataSource.filter(
    ({ key }) => !value.includes(key)
  );

  const transferredItems = dataSource?.filter(({ key: sourceKey }) =>
    value.some((key) => key === sourceKey)
  );
  // ?.filter(Boolean);
  const onAdd = (key) => {
    onChange([...value, key]);
  };

  const onRemove = (key) => {
    onChange(value.filter((valKey) => valKey !== key));
  };

  const onExchange = () => {
    //this makes an exchange
    onChange(
      dataSource.filter(({ key }) => !value.includes(key)).map(({ key }) => key)
    );
  };

  return (
    <div
      className={`customAntdTransfer
      ${isDarkMode ? "dark-mode" : ""} ${className} ${
        !!disabled ? "disabledTransfer" : ""
      }`.trim()}
    >
      {!!label && (
        <div className="transferLabel">
          {!!required && <span style={{ color: "red" }}>*</span>}
          {label}
        </div>
      )}
      <div className="transfersContainer">
        <div className="transferColumn">
          <div className="itemCounter">
            {!!redSectionTitle
              ? redSectionTitle
              : `${notTransferredItems.length} Item${
                  notTransferredItems.length !== 1 ? "s" : ""
                }`}
          </div>
          <div className="transferContent">
            {hasSearch && (
              <InputComponent
                prefix={<SearchOutlined />}
                inputAllowClear={true}
                placeholder="Search here..."
                onChange={(e) => {
                  setSearchValues((prev) => ({
                    ...prev,
                    leftSearch: e.target.value,
                  }));
                }}
              />
            )}
            <div className="transferItems" data-testid="source-items-list">
              {notTransferredItems
                ?.filter(({ title }) =>
                  title
                    ?.toLowerCase()
                    ?.includes((searchValues?.leftSearch || "").toLowerCase())
                )
                .map(({ key, title }) => (
                  <CustomCheckBox
                    key={key}
                    checked={false}
                    name={title}
                    disabled={disabled}
                    onChange={() => {
                      onAdd(key);
                    }}
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="transferIcon">
          <TransferIconNew onClick={onExchange} />
        </div>

        <div className="transferColumn">
          <div className="itemCounter">
            {!!greenSectionTitle
              ? greenSectionTitle
              : `${transferredItems?.length} Item${
                  transferredItems?.length !== 1 ? "s" : ""
                }`}
          </div>
          <div className="transferContent">
            {hasSearch && (
              <InputComponent
                prefix={<SearchOutlined />}
                inputAllowClear={true}
                placeholder="Search here..."
                onChange={(e) => {
                  setSearchValues((prev) => ({
                    ...prev,
                    rightSearch: e.target.value,
                  }));
                }}
              />
            )}
            <div
              className="transferItems"
              data-testid="transferredKeys-items-list"
            >
              {transferredItems
                ?.filter(({ title }) =>
                  title
                    ?.toLowerCase()
                    ?.includes((searchValues?.rightSearch || "").toLowerCase())
                )
                .map(({ key, title }) => (
                  <CustomCheckBox
                    key={key}
                    checked={true}
                    name={title}
                    disabled={disabled}
                    onChange={() => {
                      onRemove(key);
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTransfer;
