import { Tree } from "antd";
import { DownArrow } from "../UserAccessWrites/components/FoldersAccess";
import AccessCheckboxGroup from "../AccessCheckboxGroup/AccessCheckboxGroup";

function AccessTree({
  setAccessConfiguration,
  children = [],
  parent,
  disabled,
  isDarkMode,
  ////////
  onExpand,
  autoExpandParent,
  expandedKeys = [],
}) {
  return (
    <Tree
      {...{
        expandedKeys,
        switcherIcon: ({ expanded }) => {
          return (
            <DownArrow
              style={{ transform: `rotate(${expanded ? "180deg" : "0deg"})` }}
            />
          );
        },
        autoExpandParent,
        treeData: createAccessTree({
          children,
          setAccessConfiguration,
          parent,
          chainKeys: [],
          disabled,
        }),
        onExpand,
        showLine: true,
        className: `access-tree ${isDarkMode && "access-tree-dark"}`,
      }}
    />
  );
}

export default AccessTree;

function createAccessTree({
  children,
  setAccessConfiguration,
  first = true,
  parent,
  chainKeys = [],
  disabled,
}) {
  return (
    children.map((child) => {
      const allChainKeys = [...chainKeys, parent.title];
      const { read = true, write, title, children: currChildren } = child;

      const updateChild = (childNode, value, titleKey, parentTitle) => {
        return {
          ...childNode,
          ...((childNode.title === title || parentTitle === title) && {
            [titleKey]: value,
            ...(titleKey === "read" && value === false && { write: false }),
          }),
          ...(!!first
            ? childNode.children &&
              titleKey !== "description" && {
                children: childNode.children.map((nestedChild) => {
                  return updateChild(
                    nestedChild,
                    value,
                    titleKey,
                    childNode.title
                  );
                }),
              }
            : childNode.children && {
                children: childNode.children.map((nestedChild) => {
                  return updateChild(
                    nestedChild,
                    value,
                    titleKey,
                    childNode.title
                  );
                }),
              }),
        };
      };

      const updateRoute = (route, value, titleKey) => {
        if (route.title !== allChainKeys[0]) return route;

        return {
          ...route,
          ...(route.children && {
            children: route.children.map((child) => {
              return updateChild(child, value, titleKey);
            }),
          }),
        };
      };

      const onChildConfigChange = (value, titleKey) => {
        setAccessConfiguration((prev) => ({
          ...prev,
          Route: prev.Route.map((route) => updateRoute(route, value, titleKey)),
        }));
      };

      return {
        key: title + parent.title,
        title: (
          <AccessCheckboxGroup
            {...{
              optionsProps: {
                read: read,
                write: write,
                onReadClick: () => onChildConfigChange(!read, "read"),
                onWriteClick: () => onChildConfigChange(!write, "write"),
                showWrite: write !== undefined && read,
              },
              className: "access-tree-radio-group",
              selectedRoute: { ...child, children: null },
              disabled,
            }}
          />
        ),
        children:
          currChildren &&
          createAccessTree({
            children: currChildren,
            setAccessConfiguration,
            parent: child,
            chainKeys: allChainKeys,
            first: false,
            disabled,
          }),
      };
    }) || []
  );
}
