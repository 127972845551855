import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import _ from "lodash";

import {
  isGeofenceAlert,
  isEnteredAlert,
  validateActualTrips,
  getFence,
} from "./filterAlertsOnTruck";
import findGeofenceCenterCoordinate from "./findGeofenceCenterCoordinate";
import { formatNumber } from "src/components/SidebarPages/utils";
import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";

dayjs.extend(duration);

export const ONE_DAY = dayjs.duration({ days: 1 }).asMilliseconds();
export const TWO_DAYS = dayjs.duration({ days: 2 }).asMilliseconds();
export const ONE_HOUR = dayjs.duration({ hours: 1 }).asMilliseconds();

/**
 * Function that return a duration text given the milliseconds passed
 * @param {number} durationValue
 * @param {string} durationUnit defaults to "milliseconds"
 * @returns The parsed duration string and the duration object
 */
export function formatDuration(durationValue, durationUnit = "milliseconds") {
  let duration = dayjs.duration({ [durationUnit]: durationValue });

  let d = duration.asDays();
  let h = duration.asHours();
  let m = duration.asMinutes();
  let durationText = "";

  if (parseInt(d)) {
    durationText += `${formatNumber(d, { unit: "day", fixed: 0 })} `;
  }

  if (parseInt(h)) {
    durationText += `${formatNumber(h - parseInt(d) * 24, {
      unit: "hour",
      fixed: 0,
    })} `;
  }

  durationText += `${formatNumber(m - parseInt(h) * 60, {
    unit: "minute",
    fixed: 0,
  })}`;

  return { text: durationText, durationObject: duration };
}

export function getElapseInformation({ thisTrip, nextTrip, geofences }) {
  if (thisTrip && nextTrip) {
    if (
      isEnteredAlert(nextTrip?.["alertCode"]) &&
      !isEnteredAlert(thisTrip?.["alertCode"])
    ) {
      let departFence = geofences?.[getFence(thisTrip?.["fenceName"])];
      let arriveFence = geofences?.[getFence(nextTrip?.["fenceName"])];

      if (departFence && arriveFence) {
        let departCenter = findGeofenceCenterCoordinate(
          departFence?.["points"]
        );
        let arriveCenter = findGeofenceCenterCoordinate(
          arriveFence?.["points"]
        );

        let { distanceInMile } = withinRadius(departCenter, arriveCenter);
        const { text, durationObject } = formatDuration(
          nextTrip?.["alertDateTime"] - thisTrip?.["alertDateTime"]
        );

        return {
          distanceInMile,
          duration: durationObject.asMilliseconds(),
          durationText: text,
        };
      }
    }
  }

  return {};
}

/**
 * Function that renders the actual activities for the truck
 * and validates their conflicting status if needed
 * @param {Object} actualActivities The actual activities for the truck
 * @param {{ needsValidation: boolean, auditsForTruck: {} }} [config]
 */
function getTripsFromActualActivities(actualActivities = {}, config = {}) {
  const { needsValidation = true, auditsForTruck = {} } = config;

  let tmpTrips = [];

  for (const key in actualActivities) {
    for (const trip of actualActivities[key]) {
      let tmpL = trip?.length - 1;

      if (
        isGeofenceAlert(trip?.[0]?.["alertCode"]) &&
        !(auditsForTruck?.["discarded"] || [])?.includes(
          trip?.[0]?.["alertUUID"]
        )
      ) {
        let isChanged = auditsForTruck?.changed?.[trip[0]["alertUUID"]];
        tmpTrips.push({
          alertCode: trip[0]["alertCode"],
          alertDateTime:
            isChanged?.["alertDateTime"] || trip[0]["alertDateTime"],
          fenceName: trip[0]["fenceName"],
          alertUUID: trip[0]["alertUUID"],
          isConflicting: undefined,
          defaultAudited: isChanged?.["audited"] || false,
          activityId: isChanged?.["activityId"] || undefined,
          isEnter: isEnteredAlert(trip[0]["alertCode"])
            ? "enter-dot"
            : "exit-dot",
        });
      }

      if (
        isGeofenceAlert(trip?.[tmpL]?.["alertCode"]) &&
        tmpL &&
        !(auditsForTruck?.["discarded"] || [])?.includes(
          trip?.[tmpL]?.["alertUUID"]
        )
      ) {
        let isChanged = auditsForTruck?.changed?.[trip[tmpL]["alertUUID"]];
        tmpTrips.push({
          alertCode: trip[tmpL]["alertCode"],
          alertDateTime:
            isChanged?.["alertDateTime"] || trip[tmpL]["alertDateTime"],
          fenceName: trip[tmpL]["fenceName"],
          isConflicting: undefined,
          defaultAudited: isChanged?.["audited"] || false,
          activityId: isChanged?.["activityId"] || undefined,
          alertUUID: trip[tmpL]["alertUUID"],
          isEnter: isEnteredAlert(trip[tmpL]["alertCode"])
            ? "enter-dot"
            : "exit-dot",
        });
      }
    }
  }

  /**
   * Here we need to include the stops data
   */
  for (const linked of auditsForTruck?.["linkedStops"] || []) {
    let startId = linked?.beginUUID;
    let endId = linked?.endUUID;

    if (
      auditsForTruck?.["changed"]?.[startId] &&
      !(auditsForTruck?.["discarded"] || [])?.includes(startId)
    ) {
      let isChanged = auditsForTruck?.["changed"]?.[startId];
      tmpTrips?.push({
        alertCode: "GEOFENCE_ENTERED",
        alertDateTime:
          isChanged?.["alertDateTime"] || linked?.beginAlert["alertDateTime"],
        fenceName: linked?.fenceName,
        alertUUID: startId,
        isConflicting: undefined,
        defaultAudited: isChanged?.["audited"] || false,
        activityId: isChanged?.["activityId"] || undefined,
        isEnter: "enter-dot",
      });
    }

    if (
      auditsForTruck?.["changed"]?.[endId] &&
      !(auditsForTruck?.["discarded"] || [])?.includes(endId)
    ) {
      let isChanged = auditsForTruck?.["changed"]?.[endId];
      tmpTrips?.push({
        alertCode: "GEOFENCE_EXITED",
        alertDateTime:
          isChanged?.["alertDateTime"] || linked?.beginAlert["alertDateTime"],
        fenceName: linked?.fenceName,
        alertUUID: endId,
        isConflicting: undefined,
        defaultAudited: isChanged?.["audited"] || false,
        activityId: isChanged?.["activityId"] || undefined,
        isEnter: "exit-dot",
      });
    }
  }

  for (const trip of Object.values(auditsForTruck?.["createdAlerts"] || {})) {
    if (!(auditsForTruck?.["discarded"] || [])?.includes(trip?.["alertUUID"])) {
      const isChanged = auditsForTruck?.["changed"]?.[trip.alertUUID];

      tmpTrips.push({
        alertCode: trip.alertCode,
        alertDateTime: isChanged?.["alertDateTime"] || trip?.["alertDateTime"],
        fenceName: trip?.fenceName,
        alertUUID: trip?.alertUUID,
        defaultAudited: isChanged?.["audited"] || false,
        activityId: isChanged?.["activityId"] || undefined,
        isConflicting: undefined,
        isEnter: isEnteredAlert(trip?.alertCode) ? "enter-dot" : "exit-dot",
      });
    }
  }

  tmpTrips.sort((a, b) => a?.alertDateTime - b?.alertDateTime);
  if (needsValidation) {
    let validation = validateActualTrips(tmpTrips);
    for (let i = 0; i < validation?.length; i++) {
      tmpTrips[i]["isConflicting"] = validation[i]["isConflicting"];
    }
  }

  return _.uniqBy(tmpTrips, "alertUUID");
}

export default getTripsFromActualActivities;
