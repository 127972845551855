import { message } from "antd";

const numberSetters = async ({ params, key }) => {
	if (isNaN(parseFloat(params.newValue)) || !isFinite(params.newValue)) {
		message.info("This field require only numbers");
		return false; // don't set invalid numbers!
	}
	params.data[key] = parseInt(params.newValue);
	return true;
};
const applyCredit = async ({ params, key }) => {
	console.log("charge credit params", params);
	const setCreditFrom = (value, oldCreditFrom = []) => {
		let newData = params.context.updatedCreditApplied.map((e) => {
			if (oldCreditFrom?.find(({ chargeNumber }) => chargeNumber === e.chargeNumber)) {
				return {
					...e,
					applyAmount:
						e.applyAmount +
						oldCreditFrom?.find(({ chargeNumber }) => chargeNumber === e.chargeNumber).applyAmount,
				};
			} else {
				return e;
			}
		});
		let creditFrom = [];
		let indexToStop = newData.length;
		newData.forEach((element, i) => {
			if (i < indexToStop) {
				if (element.applyAmount > parseFloat(value)) {
					creditFrom.push({ ...element, applyAmount: parseFloat(value) });
					indexToStop = i;
				} else {
					creditFrom.push({ ...element });
					value = value - parseFloat(element.applyAmount);
				}
			}
		});
		params.context.setUpdatedCreditApplied(
			newData.map((e) => {
				if (creditFrom.find(({ chargeNumber }) => chargeNumber === e.chargeNumber)) {
					return {
						...e,
						applyAmount:
							e.applyAmount -
							creditFrom.find(({ chargeNumber }) => chargeNumber === e.chargeNumber).applyAmount,
					};
				} else {
					return e;
				}
			})
		);
		return creditFrom;
	};
	if (isNaN(parseFloat(params.newValue)) || !isFinite(params.newValue)) {
		message.info("This field require only numbers");
		return false;
	} else if (
		parseFloat(params.newValue) >
			parseFloat((params?.data?.appliedAmount || 0) + (params?.data?.taxAmount || 0)) &&
		params?.colDef?.field !== "applyCredit"
		// 	||
		// parseFloat(params.newValue) > params.context.remainingCredit + parseFloat(params.oldValue || 0)
	) {
		console.log("params", params);
		message.info("Please enter a smaller amount");
		return false;
	}
	if (params?.context?.charge?.categoryFrom === "Requisition") {
		params.context.setChargeToSave((prev) => {
			if (!!prev?.chargeItems) {
				return {
					...(prev || {}),
					chargeItems: [
						...prev.chargeItems.filter(
							({ label, estimationId }) =>
								label !== params.context.selectedService.service ||
								estimationId !== params.context.selectedService.estimationId
						),
						{
							...prev.chargeItems.find(
								({ label, estimationId }) =>
									label === params.context.selectedService.service &&
									estimationId === params.context.selectedService.estimationId
							),
							serviceOptions: [
								...prev.chargeItems
									.find(
										({ label, estimationId }) =>
											label === params.context.selectedService.service &&
											estimationId === params.context.selectedService.estimationId
									)
									.serviceOptions.flat(1)
									.filter(({ elevationId }) => elevationId !== params.context.e.elevationId),
								{
									...prev.chargeItems
										?.find(
											({ label, estimationId }) =>
												label === params.context.selectedService.service &&
												estimationId === params.context.selectedService.estimationId
										)
										?.serviceOptions.flat(1)
										?.find(({ elevationId }) => elevationId === params.context.e.elevationId),
									items: [
										...prev.chargeItems
											.find(
												({ label, estimationId }) =>
													label === params.context.selectedService.service &&
													estimationId === params.context.selectedService.estimationId
											)
											?.serviceOptions?.flat(1)
											?.find(({ elevationId }) => elevationId === params.context.e.elevationId)
											?.items?.filter(
												({ event }) => event !== params?.context?.charge?.selectedEvent
											),
										{
											...prev.chargeItems
												.find(
													({ label, estimationId }) =>
														label === params.context.selectedService.service &&
														estimationId === params.context.selectedService.estimationId
												)
												.serviceOptions.flat(1)
												.find(({ elevationId }) => elevationId === params.context.e.elevationId)
												?.items?.find(
													({ event }) => event === params?.context?.charge?.selectedEvent
												),
											items: [
												...prev.chargeItems
													?.find(
														({ label, estimationId }) =>
															label === params.context.selectedService.service &&
															estimationId === params.context.selectedService.estimationId
													)
													?.serviceOptions.flat(1)
													?.find(({ elevationId }) => elevationId === params.context.e.elevationId)
													?.items?.find(
														({ event }) => event === params?.context?.charge?.selectedEvent
													)
													?.items?.filter(({ id }) => id !== params.data.id)
													.filter(Boolean),
												{
													...params.data,
													creditFrom:
														setCreditFrom(
															parseFloat(params.newValue),
															prev.chargeItems
																?.find(
																	({ label, estimationId }) =>
																		label === params.context.selectedService.service &&
																		estimationId === params.context.selectedService.estimationId
																)
																?.serviceOptions.flat(1)
																?.find(
																	({ elevationId }) => elevationId === params.context.e.elevationId
																)
																?.items?.find(
																	({ event }) => event === params?.context?.charge?.selectedEvent
																)
																?.items?.find(({ id }) => id === params.data.id)?.creditFrom || []
														)?.filter(({ applyAmount }) => applyAmount !== 0) || [],
													applyCredit:
														setCreditFrom(
															parseFloat(params.newValue),
															prev.chargeItems
																?.find(
																	({ label, estimationId }) =>
																		label === params.context.selectedService.service &&
																		estimationId === params.context.selectedService.estimationId
																)
																?.serviceOptions.flat(1)
																?.find(
																	({ elevationId }) => elevationId === params.context.e.elevationId
																)
																?.items?.find(
																	({ event }) => event === params?.context?.charge?.selectedEvent
																)
																?.items?.find(({ id }) => id === params.data.id)?.creditFrom || []
														)?.reduce((acc, curr) => {
															acc = acc + curr.applyAmount;
															return acc;
														}, 0) || 0,
												},
											].filter(Boolean),
										},
									].filter(Boolean),
								},
							].filter(Boolean),
						},
					],
				};
			} else {
				params.data[key] = parseFloat(params.newValue);
				return true;
			}
		});
		params.context.setApliedCredit(
			params.context.creditApplied?.reduce((acc, curr) => {
				acc = acc + curr.applyAmount;
				return acc;
			}, 0) -
				params.context.remainingCredit +
				parseFloat(params.oldValue || 0)
		);
	} else if (
		params?.context?.charge?.categoryFrom === "Rental" ||
		params?.context?.charge?.categoryFrom === "Estimation"
	) {
		params.context.setChargeToSave((prev) => {
			if (!!prev?.chargeItems) {
				return {
					...(prev || {}),
					chargeItems: [
						...prev?.chargeItems?.filter(
							({ label, estimationId }) =>
								label !== params.context.selectedService.service ||
								estimationId !== params.context.selectedService.estimationId
						),
						{
							...prev?.chargeItems?.find(
								({ label, estimationId }) =>
									label === params.context.selectedService.service &&
									estimationId === params.context.selectedService.estimationId
							),
							serviceOptions: [
								...prev?.chargeItems
									.find(
										({ label, estimationId }) =>
											label === params.context.selectedService.service &&
											estimationId === params.context.selectedService.estimationId
									)
									?.serviceOptions?.filter(
										({ elevationId }) => elevationId !== params.context.e.elevationId
									),
								{
									...prev?.chargeItems
										?.find(
											({ label, estimationId }) =>
												label === params.context.selectedService.service &&
												estimationId === params.context.selectedService.estimationId
										)
										?.serviceOptions?.find(
											({ elevationId }) => elevationId === params.context.e.elevationId
										),
									items: [
										...prev?.chargeItems
											?.find(
												({ label, estimationId }) =>
													label === params.context.selectedService.service &&
													estimationId === params.context.selectedService.estimationId
											)
											?.serviceOptions.find(
												({ elevationId }) => elevationId === params.context.e.elevationId
											)
											?.items.filter(({ id }) => id !== params.data.id),
										{
											...params.data,
											creditFrom:
												setCreditFrom(
													parseFloat(params?.newValue),
													prev?.chargeItems
														?.find(
															({ label, estimationId }) =>
																label === params.context.selectedService.service &&
																estimationId === params.context.selectedService.estimationId
														)
														?.serviceOptions.find(
															({ elevationId }) => elevationId === params.context.e.elevationId
														)
														?.items.find(({ id }) => id === params.data.id)?.creditFrom || []
												)?.filter(({ applyAmount }) => applyAmount !== 0) || [],
											applyCredit: parseFloat(params.newValue),
											// setCreditFrom(
											// 	parseFloat(params.newValue),
											// 	prev.chargeItems
											// 		?.find(
											// 			({ label, estimationId }) =>
											// 				label === params.context.selectedService.service &&
											// 				estimationId === params.context.selectedService.estimationId
											// 		)
											// 		?.serviceOptions.find(
											// 			({ elevationId }) => elevationId === params.context.e.elevationId
											// 		)
											// 		?.items?.find(({ id }) => id === params.data.id)?.creditFrom || []
											// )?.reduce((acc, curr) => {
											// 	acc = acc + curr.applyAmount;
											// 	return acc;
											// }, 0) || 0,
										},
										,
									].filter(Boolean),
								},
							].filter(Boolean),
						},
					].filter(Boolean),
				};
			}
		});

		params.context.setApliedCredit(
			params.context.creditApplied?.reduce((acc, curr) => {
				acc = acc + curr.applyAmount;
				return acc;
			}, 0) -
				params.context.remainingCredit +
				parseFloat(params.oldValue || 0)
		);
	} else if (params?.context?.charge?.categoryFrom === "Other") {
		params.context.setChargeToSave((prev) => {
			return {
				...(prev || {}),
				chargeItems: [
					...prev.chargeItems.filter(({ label }) => label !== "Other Scopes"),
					{
						...prev.chargeItems.find(({ label }) => label === "Other Scopes"),
						serviceOptions: [
							...prev.chargeItems
								.find(({ label }) => label === "Other Scopes")
								.serviceOptions.filter(({ elevationId }) => elevationId !== "Other Scopes"),
							{
								...prev.chargeItems
									.find(({ label }) => label === "Other Scopes")
									.serviceOptions.find(({ elevationId }) => elevationId === "Other Scopes"),
								items: [
									...prev.chargeItems
										.find(({ label }) => label === "Other Scopes")
										.serviceOptions.find(({ elevationId }) => elevationId === "Other Scopes")
										.items.filter(({ serviceId }) => serviceId !== params.data.serviceId),
									{
										...params.data,
										creditFrom:
											setCreditFrom(
												parseFloat(params?.newValue),
												prev?.chargeItems
													?.find(({ label }) => label === "Other Scopes")
													?.serviceOptions.find(({ elevationId }) => elevationId === "Other Scopes")
													?.items.find(({ serviceId }) => serviceId === params.data.serviceId)
													?.creditFrom || []
											)?.filter(({ applyAmount }) => applyAmount !== 0) || [],
										applyCredit:
											setCreditFrom(
												parseFloat(params.newValue),
												prev.chargeItems
													?.find(({ label }) => label === "Other Scopes")
													?.serviceOptions.find(({ elevationId }) => elevationId === "Other Scopes")
													?.items?.find(({ serviceId }) => serviceId === params.data.serviceId)
													?.creditFrom || []
											)?.reduce((acc, curr) => {
												acc = acc + curr.applyAmount;
												return acc;
											}, 0) || 0,
									},
									,
								].filter(Boolean),
							},
						].filter(Boolean),
					},
				].filter(Boolean),
			};
		});

		params.context.setApliedCredit(
			params.context.creditApplied?.reduce((acc, curr) => {
				acc = acc + curr.applyAmount;
				return acc;
			}, 0) -
				params.context.remainingCredit +
				parseFloat(params.oldValue || 0)
		);
	}

	params.data[key] = parseFloat(params.newValue);
	return true;
};
export const valueSetters = (key, gridApi) => ({
	number: (params) =>
		numberSetters({ params, key }).then(() => {
			gridApi.refreshCells({ force: true });
		}),
	applyCredit: (params) =>
		applyCredit({ params, key }).then(() => {
			gridApi.refreshCells({ force: true });
		}),
});
