import { getRandomColor } from "../../../../utils"
import uniq from "lodash/uniq"

export const addons = (updatedDynamicStates, IdHandler) => {
  return {
    amount: () => ({
      title: "Estimations Amount",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Add-ons",
        field: "amount",
        labels: uniq(
          updatedDynamicStates[IdHandler]?.map((a) => a?.services)
            ?.flat(1)
            ?.map((b) => b?.label)
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: uniq(
              updatedDynamicStates[IdHandler]?.map((a) => a?.services)
                ?.flat(1)
                ?.map((b) => b?.label)
            )
              ?.map((status) =>
                updatedDynamicStates[IdHandler]?.map((c) => c?.services)
                  ?.flat(1)
                  ?.filter((d) => d?.label === status && status)
              )
              ?.map((e) => e?.map((f) => f?.serviceOptions))
              ?.map((g) =>
                g
                  ?.flat(2)
                  ?.map((h) => h?.items)
                  ?.flat(1)
                  ?.map((i) => parseInt(i?.price) || 0)
                  ?.reduce((c, p) => c + p, 0)
              ),
            backgroundColor: updatedDynamicStates[IdHandler]?.map((a) => a?.services)
              ?.flat(1)
              ?.map(() => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //estSTATUS
  }
}
