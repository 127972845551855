import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const projects = ({ programFields, tables }) => {
  return {
    projectStatus: () => ({
      labels: programFields
        ?.find((a) => a?.fieldName == "Project Status")
        ?.fieldOptions?.map((a) => a.statusName),
      data: programFields
        ?.find((a) => a?.fieldName == "Project Status")
        ?.fieldOptions?.map((a) => a.statusName)
        ?.map(
          (status) =>
            tables?.Projects?.filter(
              (a) => a.projectStatus?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "Status colors")
        ?.fieldOptions?.Projects?.map((a) => a.statusColor),
    }), //projectStatus
    attorney: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Projects || []),
        "attorney"
      ),
      data: Object.values(
        repeatedElementSum(tables?.Projects || []),
        "attorney"
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.Projects || []),
        "attorney"
      )?.map(() => getRandomColor()),
    }), //attorney
    expeditor: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Projects || []),
        "expeditor"
      ),
      data: Object.values(
        repeatedElementSum(tables?.Projects || []),
        "expeditor"
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.Projects || []),
        "expeditor"
      )?.map(() => getRandomColor()),
    }), //expeditor
    projectExecutive: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Projects, "projectExecutive")
      ),
      data: Object.values(
        repeatedElementSum(tables?.Projects, "projectExecutive")
      ),
      backgroundColor: uniq(tables?.Projects?.map((a) => getRandomColor())),
    }),

    projectManager: () => ({
      labels: Object.keys(
        repeatedElementSum(
          tables?.Projects?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      ),
      data: Object.values(
        repeatedElementSum(
          tables?.Projects?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      ),
      backgroundColor: uniq(tables?.Projects?.map((a) => getRandomColor())),
    }),
    geoFenceInfo: () => ({
      labels: [
        "Opportunities With Geofences",
        "Opportunities Without Geofences",
      ],

      data: [
        tables?.Projects?.reduce(
          (sum, { geoFenceInfo }) => sum + (geoFenceInfo?.length > 0 ? 1 : 0),
          0
        ),
        tables?.Projects?.reduce(
          (sum, { geoFenceInfo }) => sum + (geoFenceInfo?.length === 0 ? 1 : 0),
          0
        ),
      ],

      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "Status colors")
        ?.fieldOptions?.Projects?.map((a) => a.statusColor),
    }),
  }; //Projects
};
