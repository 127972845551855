import { PriceSchemesType } from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";

export const darkModeRowStyleRules = {
  "approved-row": (params) => {
    return params?.data?.approved === true;
  },
  lock: (params) => {
    return params?.data?.lock === true;
  },
  jump: (params) => {
    return params?.data?.jump === true;
  },
};

// export function darkModeRowStyle(params) {
//
//     let classes = []
//
//     if (params?.data?.approved) {
//         classes.push("approved-row")
//     }
//
//     if (params?.data?.lock) {
//         classes.push("lock")
//     }
//
//     if (params?.data?.jump) {
//         classes.push("jump")
//     }
//
//     return classes
// }

export function priceCellClass(params) {
  let classes = [];

  let value = parseFloat(params?.value);
  if (isNaN(value) || value === Infinity || value <= 0) {
    classes.push("invalid-price");
  }

  return classes;
}

export const priceCellClassRules = {
  "invalid-price": (params) => {
    let value = parseFloat(params?.value);
    if (params.colDef.field === "rent")
      return isNaN(value) || value === Infinity || value < 0;
    else return isNaN(value) || value === Infinity || value <= 0; //price ppu
  },
  "price-align": (params) => {
    return true;
  },
};

export const DimensionClassRules = (priceSchemeForThisService, field) => {
  return {
    "out-of-range-dimension-higher": (params) => {
      if (field !== "width" && field !== "height") return false; //only for width an height

      const dimension = params?.node?.data?.[field];
      let xy; //dimension in matrix
      if (field === "width") xy = "x";
      else if (field === "height") xy = "y";

      if (
        priceSchemeForThisService?.priceSchemesType === PriceSchemesType?.SHEET
      ) {
        return (
          dimension >
          parseInt(
            priceSchemeForThisService.priceScheme[xy][
              priceSchemeForThisService.priceScheme[xy].length - 1
            ]
          )
        );
      }
    },
    "out-of-range-dimension-lower": (params) => {
      if (field !== "width" && field !== "height") return false;

      const dimension = params?.node?.data?.[field];
      let xy;
      if (field === "width") xy = "x";
      else if (field === "height") xy = "y";

      if (
        priceSchemeForThisService?.priceSchemesType === PriceSchemesType?.SHEET
      ) {
        return (
          dimension < parseInt(priceSchemeForThisService.priceScheme[xy][0])
        );
      }
    },
    dimension: (params) => true,
    "invalid-price": (params) => {
      let value = parseFloat(params?.value);
      return isNaN(value) || value === Infinity || value <= 0;
    },
  };
};
