import React, { createContext, useContext } from "react";
import useDynamicFieldsData from "../hooks/useDynamicFieldsData";

const DynamicFieldsContext = createContext();

export const useDynamicFields = () => useContext(DynamicFieldsContext);

export const DynamicFieldsProvider = ({ children }) => {
  const {
    allDynamicFields,
    dynamicFields,
    driveItems,
    setDriveItems,
    loading,
  } = useDynamicFieldsData();

  return (
    <DynamicFieldsContext.Provider
      value={{
        allDynamicFields,
        dynamicFields,
        driveItems,
        setDriveItems,
        loading,
      }}
    >
      {children}
    </DynamicFieldsContext.Provider>
  );
};
