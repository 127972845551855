/**
 *
 * @param {Array} addresses list of addresses to get coordinates
 * @param {String} address element of addresses
 * @returns Array of objects {address: coordinates of address}
 */
export async function getBulkCoordinates({ addresses }) {
  const directionService = new window.google.maps.DirectionsService();
  const cleanAddresses = addresses.filter((adrs) => !!adrs.trim());

  if (addresses.length <= 12) {
    const locations = await directionService
      .route({
        origin: cleanAddresses[0],
        destination: cleanAddresses[cleanAddresses.length - 1],
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: cleanAddresses
          .slice(1, cleanAddresses.length)
          .flatMap((location) =>
            !!location.trim()
              ? {
                  location,
                  stopover: true,
                }
              : []
          ),
      })
      .then(({ routes }) => {
        const listOfLocations = routes[0]?.legs.map(
          ({ start_location }, index) => ({
            [cleanAddresses[index]]: {
              lat: start_location.lat(),
              lng: start_location.lng(),
            },
          })
        );
        return listOfLocations;
      })
      .catch((error) =>
        console.log("Error getting locations: ", { error, addresses })
      );
    return locations;
  } else {
    console.error("Max length of addresses should be 10!");
    console.log("Max length of addresses should be 10!");
  }
}
