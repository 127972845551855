// import { createScheduleOfValue } from "../../../../../../../../../actions/scheduleOfValues"
// import { MondayButton } from "../../../../../../../../commonComponents"
// import { handleSubmit } from "./modalFooterFunctions"
import "./ModalFooter.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Menu, Dropdown } from "antd";
import { Close } from "../../../../../../../../DynamicView/src";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { SaveIcon } from "../../../../../../../../BasePage/src";
import { TickIcon } from "../../../../../../../../../pages/Settings/settingsComponents/Roles/src";

const ModalFooter = ({ handleOk, handleCancel }) => {
  return (
    <div className="sovModalContainer">
      <MondayButton
        className="cancelButton"
        Icon={<Close />}
        onClick={handleCancel}
      >
        Cancel
      </MondayButton>
      <MondayButton
        className="saveButton"
        Icon={<TickIcon />}
        onClick={handleOk}
      >
        Save
      </MondayButton>
    </div>
  );
};

export default ModalFooter;
