import { v4 as uuidv4 } from "uuid";

export function convertToAdvancedFilters(inputObject) {
  const result = [];

  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      const id = uuidv4();
      const value = inputObject[key];

      result.push({
        id,
        operator: "AND",
        conditions: [
          {
            id: uuidv4(),
            operator: "AND",
            column: key,
            value,
            formula: typeof value === "boolean" ? "is" : "contains",
          },
        ],
      });
    }
  }

  return result;
}

export function convertKeyToAdvancedFilters(array, key) {
  return [
    {
      id: uuidv4(),
      operator: "AND",
      conditions: array?.map((item) => {
        return {
          id: uuidv4(),
          operator: "OR",
          column: key,
          value: item,
          formula: typeof item === "boolean" ? "is" : "contains",
        };
      }),
    },
  ];
}
