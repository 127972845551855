// import React, { useState } from "react";
// import GoogleMapReact from "google-map-react";
import { Marker } from "..";
import { NYC_CENTER } from "./mapData";
import { getRandomColor } from "../../SidebarPages/utils";
import { GoogleMap, OverlayView, useLoadScript } from "@react-google-maps/api";
import Geofence from "./Geofence/Geofence";

// // Return map bounds based on list of places
// const getMapBounds = (map, maps, places) => {
//   const bounds = new maps.LatLngBounds();

//   !!places.length
//     ? places.forEach(({ coordinates }) => {
//         bounds.extend(new maps.LatLng(coordinates?.lat, coordinates?.lng));
//       })
//     : bounds.extend(new maps.LatLng(NYC_CENTER.lat, NYC_CENTER.lng));
//   return bounds;
// };

// // Re-center map when resizing the window
// const bindResizeListener = (map, maps, bounds) => {
//   maps.event.addDomListenerOnce(map, "idle", () => {
//     maps.event.addDomListener(window, "resize", () => {
//       map.fitBounds(bounds);
//     });
//   });
// };

// // Fit map to its bounds after the api is loaded
// const apiIsLoaded = (map, maps, places) => {
//   map.streetViewControl = true;
//   // Get bounds by our places
//   const bounds = getMapBounds(map, maps, places);

//   // Fit map to bounds
//   map.fitBounds(bounds);

//   !places.length && map.setCenter(NYC_CENTER);

//   map.getZoom() > 15 && map.setZoom(15);
//   // Bind the resize listener
//   bindResizeListener(map, maps, bounds);
// };

const Map = ({
  places = [],
  className = "",
  geoFenceInfo = [],
  polygonCompleted,
  rowData,
  showDrawingManager,
  // idOfOpportunitites,
  // mapOptions,
}) => {
  return (
    <div className={className} style={{ width: "100%", height: 400 }}>
      <Geofence
        places={places}
        geoFenceInfo={geoFenceInfo}
        showDrawingManager={showDrawingManager}
        polygonCompleted={polygonCompleted}
        rowData={rowData}
        // idOfOpportunitites={idOfOpportunitites}
        // mapOptions={mapOptions}
      />
    </div>
  );
};

export default Map;
