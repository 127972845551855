import { RichTextModal } from "../../../../../../../../../../commonComponents";
import { forceToNumber } from "../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../../../../../../utils";
import Actions from "../Actions/Actions";

export const columnDefs = ({
  appendRowInGrid,
  removeRowInGrid,
  serviceLabel,
  estimationId,
  service,
  events,
  makeRowTaxable,
  removeRetainage,
}) => [
  {
    headerName: "Id",
    headerTooltip: "Id",
    field: "id",
    // width: 25,
    // flex: 1,
    editable: false,
    cellStyle: {
      fontSize: "1.125rem",
      fontWeight: "600",
    },
    cellRenderer: ({ value }) => value + 1,
  },
  {
    headerName: "Event",
    headerTooltip: "Event",
    field: "name",
    rowDrag: true,
    // flex: 5,
    cellRenderer: "EventCellRenderer",
    cellRendererParams: {
      serviceLabel,
      estimationId,
    },
    cellStyle: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      fontSize: "1.025rem",
      fontWeight: "500",
      textOverflow: "ellipsis",
    },
    cellEditor: "EventCellEditor",
    cellEditorParams: {
      events,
    },
  },
  {
    headerName: "Charge Percentage",
    headerTooltip: "Percentage",
    field: "rate",
    // flex: 2,
    cellRenderer: "RateCellRenderer",
    cellRendererParams: {
      serviceLabel,
      estimationId,
    },
    cellEditor: "RateCellEditor",
  },
  {
    headerName: "Charge Amount",
    headerTooltip: "Amount",
    field: "amount",
    // flex: 3,
    cellRenderer: "AmountCellRenderer",
    cellEditor: "AmountCellEditor",
    getQuickFilterText: () => "",
  },
  {
    headerName: "Difference",
    headerTooltip: "Difference",
    field: "difference",
    // flex: 3,
    editable: false,
    cellRenderer: "DifferenceCellRenderer",
    cellRendererParams: {
      serviceLabel,
      estimationId,
    },
  },
  {
    headerName: "Retainage",
    headerTooltip: "Retainage",
    field: "retainage",
    // flex: 2,
    editable: false,
    valueFormatter: (params) => formatCurrency(params?.value, "USD"),
    cellStyle: {
      fontSize: "1.025rem",
      fontWeight: "700",
    },
  },

  {
    headerName: "Collectable Amount",
    headerTooltip: "Collectable Amount",
    field: "collectable_amount",
    // flex: 3,
    editable: false,
    valueFormatter: (params) => formatCurrency(params?.value, "USD"),
    // valueGetter: ({ data }) => {},
    cellStyle: {
      fontSize: "1.025rem",
      fontWeight: "700",
    },
  },
  {
    headerName: "Tax Amount",
    headerTooltip: "Tax Amount",
    field: "taxAmount",
    // flex: 3,
    editable: false,
    valueFormatter: ({ value, data }) =>
      formatCurrency(data?.taxAmount || 0, "USD"),
    // valueGetter: ({ data }) => {
    //   data.taxAmount = forceToNumber(data?.amount * data?.custom_tax_amount);
    //   return data?.taxAmount;
    // },
    cellStyle: {
      fontSize: "1.025rem",
      fontWeight: "700",
    },
  },
  {
    headerName: "Note",
    headerTooltip: "Note",
    field: "note",
    // flex: 2.5,
    cellStyle: {
      fontSize: "1.025rem",
      fontWeight: "500",
    },
    cellRendererFramework: (gridParams) => {
      return (
        <RichTextModal
          {...{
            title: "Note",
            gridParams,
            service,
          }}
        />
      );
    },
  },
  {
    headerName: "Actions",
    field: "id",
    headerTooltip: "Actions",
    // flex: 2.5,
    editable: false,

    cellRendererFramework: ({ value, data = {} }) => {
      return (
        <div>
          <Actions
            {...{
              rowId: value,
              data,
              appendRowInGrid,
              removeRowInGrid,
              makeRowTaxable,
              removeRetainage,
            }}
          />
        </div>
      );
    },
  },
];
