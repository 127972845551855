import { ReactNode, useRef } from "react";
import { useSelector } from "react-redux";
import { CaretRightOutlined } from "@ant-design/icons";

import { StoreType } from "../../types";

import "./CollapseInfo.scss";

type CollapseProps = {
  items: any;
  title: string;
  className?: string;
  expanded?: boolean;
  defaultExpanded?: boolean;
  collapseIcon?: ReactNode;
  customHeader?: ReactNode;
  showIcon?: boolean;
  itemRenderer?: (any) => ReactNode;
  onCollapseChange?: (collapsed: boolean, title: string) => any;
};

function CollapseInfo(props: CollapseProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const defaultExpClass = useRef<string>(
    typeof props.defaultExpanded === "boolean"
      ? props.defaultExpanded
        ? "content-expanded"
        : "content-collapsed"
      : "content-collapsed"
  );

  const {
    title,
    expanded,
    items = [],
    itemRenderer,
    className = "",
    showIcon = true,
    customHeader = null,
    onCollapseChange = () => {},
    collapseIcon = <CaretRightOutlined />,
  } = props;

  function onToggleCollapse() {
    if (typeof expanded === "boolean") {
      return;
    }

    if (defaultExpClass.current) {
      defaultExpClass.current = "";
    }

    const isCollapsing: boolean =
      contentRef.current.classList.contains("content-expanded");

    if (isCollapsing) {
      contentRef.current.classList.remove("content-expanded");
      contentRef.current.classList.add("content-collapsed");

      if (iconRef.current) {
        iconRef.current.classList.remove("icon-expanded");
        iconRef.current.classList.add("icon-collapsed");
      }
    } else {
      contentRef.current.classList.remove("content-collapsed");
      contentRef.current.classList.add("content-expanded");

      if (iconRef.current) {
        iconRef.current.classList.remove("icon-collapsed");
        iconRef.current.classList.add("icon-expanded");
      }
    }

    onCollapseChange(isCollapsing, title);
  }

  return (
    <div
      className={`single-collapse-info ${
        isDarkMode ? "single-collapse-dark" : ""
      } ${className}`}
    >
      <div
        ref={headerRef}
        className="collapse-header"
        onClick={onToggleCollapse}
      >
        {showIcon && (
          <div
            ref={iconRef}
            className={`collapse-icon-container ${
              typeof expanded === "boolean"
                ? expanded
                  ? "icon-expanded"
                  : "icon-collapsed"
                : defaultExpClass.current
                ? defaultExpClass.current.includes("exp")
                  ? "icon-expanded"
                  : "icon-collapsed"
                : ""
            }`}
          >
            {collapseIcon}
          </div>
        )}
        <div className="collapse-title-container">{customHeader ?? title}</div>
      </div>
      <div
        ref={contentRef}
        className={`collapse-content ${
          typeof expanded === "boolean"
            ? expanded
              ? "content-expanded"
              : "content-collapsed"
            : defaultExpClass.current
        }`}
      >
        {items.map((e, key) => {
          return (
            <div className="single-item-container" key={`${key}`}>
              {itemRenderer ? itemRenderer(e) : e}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CollapseInfo;
