const paramsReducer = (acc, paramObj) => ({
  ...acc,
  [paramObj?.Name]: paramObj,
});

export const attachParameters = (
  report = {},
  parameters = [],
  categoryParameters = []
) => {
  const { definition = {} } = report;
  const { ReportParameters = [] } = definition;

  const categoryParamsObj = categoryParameters.reduce(paramsReducer, {});
  const reportParamsObj = ReportParameters.reduce(paramsReducer, {});

  return {
    ...report,
    definition: {
      ...definition,
      ReportParameters: parameters.map(
        (name) => categoryParamsObj[name] || reportParamsObj[name]
      ),
    },
  };
};
