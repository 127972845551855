export const sideBar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
  ],
};

export const gridOptions = {
  autoSizeStrategy: {
    type: "fitCellContents",
  },
};

export const defaultColumnDefs = {
  autoHeight: true,
  sortable: true,
  resizable: true,
  filter: true,
};
