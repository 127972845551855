import {
  BigRain,
  ChanceRain,
  Cloudy,
  Lighting,
  Rain,
  SlightCloudy,
  Snow,
  Sunny,
  WeatherDefault,
} from "../../GanttChart/assets/weatherIcons";
import { DEG_DATE_FORMAT } from "../../../../pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { toCamelCase } from "../../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";

export const GOOGLE_API_KEY = "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo";
export const DIRECTION_ICON =
  "https://maps.google.com/mapfiles/kml/paddle/red-diamond-lv.png";
export const TRUCK_MAP_ICON =
  "https://maps.google.com/mapfiles/kml/shapes/truck.png"; //

export const LOCATIONS_URL =
  "https://leadmanager-sockets.ue.r.appspot.com/api/location";
export const LOCATIONS_FOR_VEHICLE =
  "https://leadmanager-sockets.ue.r.appspot.com/api/locationsForVehicle";

export const NEW_YORK_LOCATION = { lat: 40.712, lng: -74.006 };

export const FIVE_MINUTES_MS = 300000;

export const WEATHER_ICONS = {
  Snow: <Snow />,
  Sunny: <Sunny />,
  "Mostly Clear": <Sunny />,
  Clear: <Sunny />,
  "Mostly Sunny": <Sunny />,
  "Partly Sunny": <Cloudy />,
  "Partly Sunny then Slight Chance Showers And Thunderstorms": <SlightCloudy />,
  "Chance Rain Showers": <Rain />,
  "Slight Chance Showers And Thunderstorms": <Rain />,
  "Chance Showers And Thunderstorms": <Rain />,
  "Scattered Showers And Thunderstorms then Mostly Cloudy": <Rain />,
  "Showers And Thunderstorms": <BigRain />,
  "Partly Sunny then Chance Rain Showers": <ChanceRain />,
  "Partly Sunny then Chance Showers And Thunderstorms": <ChanceRain />,
  "Showers And Thunderstorms Likely": <Lighting />,
  "Partly Cloudy": <Cloudy />,
  "Chance Rain Showers then Showers And Thunderstorms": <Lighting />,
  "Scattered Showers And Thunderstorms": <Lighting />,
  "Slight Chance Showers And Thunderstorms then Showers And Thunderstorms Likely":
    <Lighting />,
  "Mostly Cloudy then Chance Rain Showers": <ChanceRain />,
  "Chance Showers And Thunderstorms then Mostly Cloudy": <SlightCloudy />,
  "Mostly Sunny then Slight Chance Showers And Thunderstorms": <Cloudy />,
  "Slight Chance Showers And Thunderstorms then Partly Cloudy": <Cloudy />,
  "Scattered Showers And Thunderstorms then Partly Cloudy": <Lighting />,
  "": <WeatherDefault width={20} />,
};

export async function mapDocDefinition({
  companyLogo,
  mainData,
  fromProject,
  generateMapImage,
}) {
  const headerRows = fromProject
    ? [
        { text: "Project Address", style: ["headerCell"] },
        { text: "Account Name", style: ["headerCell"] },
        { text: "Project Status", style: ["headerCell"] },
        { text: "Project Executive", style: ["headerCell"] },
      ]
    : [
        { text: "Schedule Address", style: ["headerCell"] },
        { text: "Type Of Work", style: ["headerCell"] },
        { text: "Schedule Status", style: ["headerCell"] },
      ];

  const dataRows = mainData.map((el) => {
    return headerRows.map(({ text }) =>
      text?.includes("Address")
        ? {
            text: el[toCamelCase(text)],
            style: ["link"],
            target: "_blank",
            link: `https://portal.corescaffold.com/${
              fromProject ? "projects" : "scheduling"
            }/${fromProject ? el.projectId : el.scheduleId}`,
          }
        : {
            text: el[toCamelCase(text)],
            aligment: "center",
            style: ["lastColumn"],
          }
    );
  });

  const imageLink = await generateMapImage();

  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: `${fromProject ? "Projects Map" : "Scheduling Map"}`,
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
            style: ["strong", "large"],
          },
          {
            text: `Date : ${dayjsNY().format(DEG_DATE_FORMAT)}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
        <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
        width: 555,
        height: 2,
        style: "subheader",
      },
      imageLink
        ? {
            image: imageLink,
            fit: [800, 560],
            pageBreak: "after",
          }
        : {
            width: "100%",
            text: "To many job sites to generate an image",
            alignment: "center",
            style: ["lastColumn"],
            margin: [20, 20, 20, 20],
          },
      {
        table: {
          headerRows: headerRows?.length,
          widths: headerRows.map(() => `${100 / headerRows?.length}%`),
          body: [headerRows, ...dataRows],
        },
      },
    ].filter(Boolean),
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 15],
      },
      subheader: { margin: [0, 0, 0, 5], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
      headerCell: { fillColor: "#d1d1d1" },
      link: { color: "#1264a3" },
    },
  };
}

export const mapFiltersObject = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    style: "location",
    label: "Address",
    type: "Dropdown",
    key: "scheduleAddress",
  },
  {
    globalKeyId: "8fewfwbc3-0b8a-400f-b352-6354cb0f22ec",
    label: "Status",
    type: "Dropdown",
    key: "scheduleStatus",
  },
  {
    globalKeyId: "8fdedbc3-0b8aweee-b352-6354cb0f22ec",
    label: "Services",
    type: "Dropdown",
    key: "toBeScheduled",
    options: [
      "Sidewalk Shed",
      "Hoist",
      "Scaffold",
      "System Scaffold",
      "Plywood Enclosure",
    ],
  },
  {
    globalKeyId: "8fdedbc3-xxxx-400f-b352-6354cb0f22ec",
    label: "Schedule Days",
    type: "DateRange",
    key: "scheduleDays",
  },
  {
    globalKeyId: "8fdedbc3-zhzh-400f-b352-6354cb0f22ec",
    label: "Project Executive",
    type: "Dropdown",
    key: "projectExecutive",
  },
  {
    globalKeyId: "8fdedbc3-zhzh-400f-b352-6354cb0f22ec",
    label: "Project Manager",
    type: "Dropdown",
    key: "projectManagers",
  },
  {
    globalKeyId: "8asgdbc3-ytgh-400f-b52g-6354cb0f22ec",
    label: "Crews",
    type: "Dropdown",
    key: "crewNames",
  },
  {
    globalKeyId: "6agabnc3-ytgh-f450-b52g-6354cb0f22eg",
    label: "Type Of Work",
    type: "Dropdown",
    key: "typeOfWork",
  },
];

export const MAP_DEFAULT = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.rail",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.bus",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const MAP_LIGHT = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const MAP_RETRO = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#ebe3cd",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#523735",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f1e6",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9b2a6",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#dcd2be",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ae9e90",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#93817c",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a5b076",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#447530",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f1e6",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#fdfcf8",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#f8c967",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#e9bc62",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#e98d58",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#db8555",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8f7d77",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ebe3cd",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#b9d3c2",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#92998d",
      },
    ],
  },
];

export const MAP_NIGHT = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.rail",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.bus",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const MAP_DARK = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#263c3f",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#6b9a76",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#38414e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#212a37",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9ca5b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#1f2835",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#f3d19c",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#2f3948",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#515c6d",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
];
