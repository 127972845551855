import uniq from "lodash/uniq";
import { getMonthsTimestamps } from "../../../../../utils";
import { months } from "../../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const fleetInspections = ({ tables, programFields }) => {
  const inspectionStatuses = programFields?.find(
    (el) => el?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Inspections;
  return {
    inspectedBy: () => ({
      content: uniq(
        tables?.["Fleet Inspections"]?.map((a) => a?.inspectedBy)
      )?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.["Fleet Inspections"]?.filter(
            (a) => a?.inspectedBy === status
          ).length /
            tables?.["Fleet Inspections"]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status,
        done: tables?.["Fleet Inspections"]?.filter(
          (a) => a?.inspectedBy === status
        ).length,
      })),
    }), //inspectedBy,
    driverName: () => ({
      content: uniq(tables?.["Fleet Inspections"]?.map((a) => a?.driver))?.map(
        (status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.["Fleet Inspections"]?.filter((a) => a?.driver === status)
              .length /
              tables?.["Fleet Inspections"]?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.["Fleet Inspections"]?.filter(
            (a) => a?.driver === status
          ).length,
        })
      ),
    }), //driverName
    createdAt: () => ({
      content: (() => {
        let data = [];
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              color: "#71CF48",
              unfilledColor: "#F5F5F5",
              percent: (
                (tables?.["Fleet Inspections"]
                  ?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.createdAt)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length /
                  tables?.["Fleet Inspections"]?.filter(
                    (b) => b.createdAt >= getMonthsTimestamps()[0]
                  ).length) *
                100
              ).toFixed(1),
              showInfo: true,
              status: months[i],
              done: tables?.["Fleet Inspections"]
                ?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                ?.map((a) => a?.createdAt)
                ?.filter(
                  (b) =>
                    b >= getMonthsTimestamps()[i] &&
                    b <= getMonthsTimestamps("end")[i]
                )?.length,
            },
          ];
        }
        return data;
      })(),
    }), //createdAt

    fleetName: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.["Fleet Inspections"] || [], "fleetName")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (value / (tables?.["Fleet Inspections"] || [])?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }), //fleetName

    inspectionStatus: () => ({
      content: inspectionStatuses?.map((el) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          ((tables?.["Fleet Inspections"] || [])?.filter(
            (a) => a?.inspectionStatus === el
          ).length /
            (tables?.["Fleet Inspections"] || [])?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: el,
        done: (tables?.["Fleet Inspections"] || [])?.filter(
          (a) => a?.inspectionStatus === el?.statusName
        ).length,
      })),
    }), //inspectionStatus

    inspectionType: () => ({
      content: Object.entries(
        repeatedElementSum(
          tables?.["Fleet Inspections"] || [],
          "inspectionType"
        )
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (value / (tables?.["Fleet Inspections"] || []).length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    mechanic: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.["Fleet Inspections"] || [], "mechanic")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (value / (tables?.["Fleet Inspections"] || []).length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
  }; //Inspections
};
