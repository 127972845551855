import "../Information/Information.scss";

function ManufacturerCard(props) {
  const { fleetMake, fleetManufacturer, fleetYear, fleetModel } =
    props?.params || {};

  return (
    <div className="InformationBody">
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">
          Fleet Manufacturer
        </span>
        <span className="InforamtionBodyElementContainerContent">
          {fleetManufacturer}
        </span>
      </div>
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">Fleet Year</span>
        <span className="InforamtionBodyElementContainerContent">
          {fleetYear}
        </span>
      </div>
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">Fleet Make</span>
        <span className="InforamtionBodyElementContainerContent">
          {fleetMake}
        </span>
      </div>
      <div className="InforamtionBodyElementContainer">
        <span className="InformationBodyElementContainerTitle">
          Fleet Model
        </span>
        <span className="InforamtionBodyElementContainerContent">
          {fleetModel}
        </span>
      </div>
    </div>
  );
}

export default ManufacturerCard;
