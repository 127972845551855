import { useSelector } from "react-redux";
import React, { ReactElement } from "react";

import type { StoreType } from "../SidebarPages/FleetMaintenanceView/types";

import "./CardComponent.scss";

interface Props {
  title: ReactElement | string;
  className?: string;
  key?: string | number;
  children?: React.JSX.Element;
}

function CardComponent(props: Props) {
  const darkMode = useSelector((state: StoreType) => state.darkMode.isDarkMode);

  return (
    <section
      key={props?.key}
      className={`card-component ${darkMode ? "card-component-dark" : ""} ${
        props.className
      }`}
    >
      <div className="card-header">{props.title}</div>
      <div className="card-body">{props?.children || null}</div>
    </section>
  );
}

export default CardComponent;
