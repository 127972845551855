import { LOG_CATEGORIES_PROFILE } from "./LOG_CATEGORIES_PROFILE";
import { userLogsObj } from "./userLogsObj";
import { getObjectChanges } from "../../../../../../utils";

export const handleSaveLogs = ({
  userConfiguration,
  preferences,
  profileChanges,
  saveAddedLogs,
}) => {
  const newLogs = [];

  const defaultLogData = userLogsObj();

  // save log for changesuser profile picture
  if (profileChanges.hasOwnProperty("userImage")) {
    const { userImage } = profileChanges;

    newLogs.push({
      ...defaultLogData,
      actionType: `${!!userImage ? "Upload" : "Delete"} Profile Picture`,
      category: LOG_CATEGORIES_PROFILE.userImage,
      label: "Account Information",
      currentData: { googleDriveFileId: userImage },
      previousData: {
        googleDriveFileId: userConfiguration?.googleDriveFileId,
      },
    });
  }

  // save log for pinNumber
  if (profileChanges.hasOwnProperty("pinNumber")) {
    const { pinNumber } = profileChanges;
    newLogs.push({
      ...defaultLogData,
      actionType: "Update",
      category: LOG_CATEGORIES_PROFILE.pinNumber,
      label: "Account Information",
      currentData: { pinNumber },
      previousData: { pinNumber: userConfiguration?.pinNumber },
    });
  }

  // save log for idleTimeOut
  if (profileChanges.hasOwnProperty("idleTimeOut")) {
    const { idleTimeOut } = profileChanges;
    newLogs.push({
      ...defaultLogData,
      actionType: "Update",
      category: LOG_CATEGORIES_PROFILE.idleTimeOut,
      label: "Account Information",
      currentData: { idleTimeOut },
      previousData: { idleTimeOut: userConfiguration?.idleTimeOut },
    });
  }

  //save logs for topic notification changes
  if (profileChanges.hasOwnProperty("myNotifications")) {
    const { myNotifications } = profileChanges;

    const topicChanges = getObjectChanges(
      preferences.preferences.notifications.topics,
      myNotifications
    );

    Object.entries(topicChanges).forEach(([topic, actions]) => {
      Object.entries(actions).forEach(([action, value]) => {
        if (
          value !== preferences.preferences.notifications.topics[topic][action]
        ) {
          newLogs.push({
            ...defaultLogData,
            actionType: `${!!value ? "Enabled" : "Disabled"}`,
            category: LOG_CATEGORIES_PROFILE.myNotifications,
            topic,
            label: action,
            currentData: { [action]: value },
            previousData: { [action]: !value },
          });
        }
      });
    });
  }

  // save log for profileColor
  if (profileChanges.hasOwnProperty("profileColor")) {
    const { profileColor } = profileChanges;
    newLogs.push({
      ...defaultLogData,
      actionType: "Update",
      category: LOG_CATEGORIES_PROFILE.profileColor,
      label: "Account Information",
      currentData: { profileColor },
      previousData: { profileColor: preferences.preferences?.profileColor },
    });
  }

  // save logs for changes in automation preferences
  if (profileChanges.hasOwnProperty("automationPreferences")) {
    const { automationPreferences } = profileChanges;

    const automationPreferencesChanges = getObjectChanges(
      preferences.preferences.notifications.automationPreferences,
      automationPreferences
    );

    Object.entries(automationPreferencesChanges).forEach(
      ([functionName, value]) => {
        newLogs.push({
          ...defaultLogData,
          actionType: `${!!value ? "Enabled" : "Disabled"}`,
          category: "Edit Notifications Fields",
          topic: "Automation Preferences",
          label: functionName,
          currentData: { [functionName]: value },
          previousData: { [functionName]: !value },
        });
      }
    );

    console.log({ automationPreferencesChanges });
  }

  if (newLogs.length > 0) {
    saveAddedLogs(newLogs);
  }
};
