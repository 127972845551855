import route from "./src/route.webp";
import sidebar from "./src/sidebar.webp";
import search from "./src/search.webp";
import preferences from "./src/preferences.webp";
/**
 *
 * @param {Array} groups array of userGroups
 * @returns
 */
export const Groups = ({ groups }) => {
  let departments = [];
  const notification = "Notification Preferences";
  groups.map(
    ({
      groupName,
      groupConfigs: {
        routeConfig: Route = [],
        sidebarConfig: Sidebar = [],
        // searchConfig: Search = [],
        preferences,
      },
    }) =>
      departments.push({
        [groupName]: {
          Route,
          Sidebar,
          // Search,
          [notification]: preferences,
        },
      })
  );
  return departments;
};
/**
 *
 * @param {Object} object  get keys of object
 * @returns
 */
export const keys = (object) =>
  typeof object === "object" && !!object ? Object.keys(object) : [];
/**
 *
 * @param {Object} object  get values of object
 * @returns
 */
export const values = (object) =>
  typeof object === "object" && !!object ? Object.values(object) : [];
/**
 *
 * @param {Array} array state array that you made changes inside and want to set again with changes
 * @returns
 */
export const state = (array) => JSON.parse(JSON.stringify(array));
/**
 *
 * @param {Boolean} condition condition to show write checkbox
 * @param {Oject} param1 with read and write params in boolean format
 * @returns
 */
export const Checkboxes = (condition, { read, write }) => [
  {
    isRead: true,
    checked: read,
    label: "Read",
  },
  condition && {
    isRead: false,
    checked: write,
    label: "Write",
  },
];

export const Images = {
  0: route,
  1: sidebar,
  // 2: search,
  3: preferences,
};

export const headerElements = (
  divClassName,
  imgClassName,
  spanClassName,
  src,
  b
) => [
  <div className={divClassName}></div>,
  <img
    className={imgClassName}
    style={
      b === "Notification Preferences"
        ? {
            width: 20,
            height: 20,
            marginTop: 10,
            marginLeft: 0,
            marginRight: 5,
          }
        : {}
    }
    src={src}
  />,
  <span className={spanClassName}>{b}</span>,
];

export const elif = (condition, condition2) => ({
  parentContainer: condition
    ? "configElementSelectedContainer"
    : "configElementContainer",
  bluCStyle: condition ? { width: 36 } : {},
  navyBluCStyle: condition ? { opacity: 1 } : {},
  checkBoxStyle: condition ? { opacity: 0 } : {},
  departmentName: condition
    ? "entryPageDepartmentNameSelected"
    : "entryPageDepartmentName",
  configSelected: condition
    ? "configTitleContainerSelected"
    : "configTitleContainer",
  selectedConfig: condition ? "collapseIcon" : "openIcon",
  checkboxSQ: condition
    ? condition2
      ? "greenContainer"
      : "bluContainer"
    : "redContainer",
  checkboxSQLite: condition ? "greenContainer" : "redContainer",
  topicName: condition
    ? "actionLabelContainerSelected"
    : "actionLabelContainer",
  switchContainer: condition ? "TrueContainer" : "FalseContainer",
  switch: condition ? "switchTrueContainer" : "switchFalseContainer",
});

export const pdfBorderedSection = ({ title, body, noColor = false }) => {
  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };
  const formattedBody = body.map((item) => ({
    columns:
      title === "Route"
        ? [
            {
              width: "43%",
              text: item.title,
              alignment: "left",
              style: ["lastColumn", "strong", "cellItem"],
            },
            {
              width: "47%",
              text: item.path,
              alignment: "left",
              style: ["lastColumn", "strong", "cellItem"],
            },
            {
              width: "10%",
              alignment: "center",
              style: ["lastColumn", "strong", "cellItem"],
              text: item.write ? "Write" : "-",
            },
          ]
        : title === "Sidebar"
        ? [
            {
              width: "80%",
              text: item.title,
              alignment: "left",
              style: ["lastColumn", "strong", "cellItem"],
            },
            {
              width: "20%",
              text: item.to,
              alignment: "left",
              style: ["lastColumn", "strong", "cellItem"],
            },
          ]
        : title === "Search"
        ? [
            {
              width: "43%",
              text: item.title.charAt(0).toUpperCase() + item.title.slice(1),
              alignment: "left",
              style: ["lastColumn", "strong", "actionElement"],
            },
          ]
        : [
            {
              width: "18%",
              height: "auto",
              text: `${keys(item)[0]}: `,
              alignment: "left",
              style: ["lastColumn", "strong", "cellItem"],
            },
            {
              width: "*",
              stack: keys(item[keys(item)[0]]).map((notification) => {
                const accesOfItem = item[keys(item)[0]][notification];

                return {
                  width: "*",
                  alignment: "left",
                  columns: [
                    {
                      width: "50%",
                      text: notification,
                      alignment: "left",
                      style: ["lastColumn", "strong", "cellItem"],
                    },
                    {
                      width: "50%",
                      text: `${accesOfItem}`,
                      alignment: "right",
                      style: ["lastColumn", "strong", "cellItem"],
                    },
                  ],
                };
              }),
            },
          ],
  }));

  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    {
      width: "*",
      alignment: "right",
      height: 10,
      style: ["lastColumn", "strong", "bool"],
      columns:
        title === "Route"
          ? [
              {
                width: "43%",
                text: "Title:",
                alignment: "left",
                height: 10,
              },
              {
                width: "23%",
                text: "Path:",
                alignment: "left",
                style: ["lastColumn", "strong"],
              },
              {
                width: "34%",
                text: "Access To Write:",
                alignment: "right",
                style: ["lastColumn", "strong"],
              },
            ]
          : title === "Sidebar"
          ? [
              {
                width: "80%",
                text: "Title:",
                alignment: "left",
                height: 10,
              },
              {
                width: "20%",
                text: "Redirects To:",
                alignment: "left",
                style: ["lastColumn", "strong"],
              },
            ]
          : title === "Search"
          ? [
              {
                width: "*",
                text: "Access to search in:",
                alignment: "left",
                height: 10,
              },
            ]
          : [
              {
                width: "*",
                text: "Actions ",
                alignment: "left",
                height: 10,
              },
            ],
    },
    ...formattedBody,
  ].filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
            },
            layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outterTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
    unbreakable: title !== "Route",
  };
};

export const docDefinition = (inspReport, companyLogo, headerData) => {
  return {
    pageOrientation: "portrait",
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return {
        text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
        alignment: "center",
        margin: 2,
      };
    },
    content: [
      {
        columns: [
          {
            image: "logo",
            width: 100,
          },
          {
            stack: [
              {
                text: `${headerData} Access`,
                style: ["strong", "large"],
                margin: [0, 10, 90, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
  <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
</svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },

      inspReport?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outterTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
      actionElement: { fontSize: 14 },
    },
  };
};
