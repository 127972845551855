export const replyTourStyle = {
  fontWeight: "bold",
  backgroundColor: "#1264a3",
  color: "white",
  fontSize: "20px",
  padding: "5px",
  cursor: "pointer",
  borderRadius: "5px",
};

export const infoIconStyle = {
  fontSize: "20px",
  color: "#1264A3",
  paddingRight: "10px",
};

export const deleteIconStyle = {
  fontWeight: "bold",
  backgroundColor: "#FE4C4A",
  color: "white",
  fontSize: "20px",
  padding: "5px",
  cursor: "pointer",
  borderRadius: "5px",
};

export const editIconStyle = {
  backgroundColor: "#1264a3",
  fontWeight: "bold",
  color: "white",
  fontSize: "20px",
  padding: "5px",
  cursor: "pointer",
  borderRadius: "5px",
};

export const plusIconStyle = {
  backgroundColor: "#71cf48",
  fontWeight: "bold",
  color: "white",
  fontSize: "20px",
  padding: "5px",
  cursor: "pointer",
  borderRadius: "5px",
};

export const modalFooterStyle = {
  display: "flex",
  justifyContent: "space-between",
};
