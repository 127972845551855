import { getMonthsTimestamps } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const fleet = (circleProgressPicker, IdHandler, updatedDynamicStates) => {
  return {
    fleetYear: () => ({
      title: "Fleet Year",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Fleet",
        field: "fleetYear",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter((a) => a?.fleetYear === circleProgressPicker)
            ?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter((a) => a?.fleetYear === circleProgressPicker)
          ?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //fleetYear
    registrationExpDate: () => ({
      title: "Registration Expiration Date",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Fleet",
        field: "registrationExpDate",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.registrationExpDate >=
                getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
              a?.registrationExpDate <=
                getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
          ).length /
            updatedDynamicStates[IdHandler]?.filter(
              (a) => a?.registrationExpDate >= getMonthsTimestamps()[0]
            ).length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: "Registered At" + circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.registrationExpDate >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.registrationExpDate <=
              getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length,
      },
      id: IdHandler,
      width: "390px",
    }), //registrationExpDate
    inspectionExpDate: () => ({
      title: "Inspection Expiration Date",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Fleet",
        field: "inspectionExpDate",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.inspectionExpDate >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
              a?.inspectionExpDate <=
                getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
          ).length /
            updatedDynamicStates[IdHandler]?.filter(
              (a) => a?.inspectionExpDate >= getMonthsTimestamps()[0]
            ).length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: "Inspected At" + circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.inspectionExpDate >= getMonthsTimestamps()[months.indexOf(circleProgressPicker)] &&
            a?.inspectionExpDate <= getMonthsTimestamps("end")[months.indexOf(circleProgressPicker)]
        ).length,
      },
      id: IdHandler,
      width: "390px",
    }), //inspectionExpDate
  } //Fleet
}
