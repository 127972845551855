import _ from "lodash";
import { Select, message } from "antd";
import { useSelector } from "react-redux";
import React, { createContext } from "react";

import GroupsTable from "./GroupsTable";
import { useRedux } from "../../../../../hooks";
import { DropdownIcon } from "../../../../../BasePage/src";
import { onSelectionChanged } from "./utils/onSelectionChanged";
import { categories, categoriesName } from "../../utils/constants";
import { firstLetterToUpperCase } from "../ControlPanel/utils/firstLetterToUpperCase";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";
import {
  calculateCreditedAmountForService,
  calculatePreviousInvoiceTotals,
  calculateTotalPriceForService,
  onOptionClickHandler,
} from "../../../utilities";
import { checkIfServiceIsHoist } from "../../../../../Estimations/DataEntryGrid/models/Service";

export const SelectedCategoryGroupContext = createContext();

const InvoiceGroup = ({
  selectedCategory,
  onCategoryChange,
  servicesGroups,
  selectedCategoryGroupNumber,
  setSelectedCategoryGroupNumber,
  servicesInRef,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [isWritable] = useRedux("invoiceIsWritable");

  const { categoryData, invoiceData } = useInitialInvoiceContext();
  const { setProducts: setRowData, setProductsList } =
    useCreateInvoiceContext();

  function findMinMaxDates(serviceOptions, selectedCategory) {
    if (selectedCategory === "rentals") {
      let minStartDate = null;
      let maxEndDate = null;

      serviceOptions?.forEach((serviceOption) => {
        serviceOption?.items?.forEach((item) => {
          const { startDate, endDate } = item?.rentalDetails || {};

          // Check if startDate is defined and update minStartDate
          if (
            startDate !== undefined &&
            (minStartDate === null || startDate < minStartDate)
          ) {
            minStartDate = startDate;
          }

          // Check if endDate is defined and update maxEndDate
          if (
            endDate !== undefined &&
            (maxEndDate === null || endDate > maxEndDate)
          ) {
            maxEndDate = endDate;
          }
        });
      });

      return { startDate: minStartDate, endDate: maxEndDate };
    }
    return {};
  }

  //Add or Remove the checked service/product
  async function onServiceGroupCheckBoxChange({
    isChecked,
    services,
    categoryId,
    groupName,
    selectedCategory,
    includedRequisitionId,
    keepPrev = true,
    chargeCategoryFrom,
    accumulatedRentals,
  }) {
    let productsList;
    let warn = false;

    setProductsList((prev) => {
      if (isChecked) {
        //Removing
        servicesInRef.current.value = "";

        //Add new services into the productList and removes the duplicates
        if (!keepPrev) prev = [];
        services?.forEach((service, index) => {
          //Removes duplicated
          if (
            !prev.some(
              (el) =>
                service.label === el.name &&
                service?.rentalId === el?.rentalId &&
                service?.rentalId !== undefined
            )
          ) {
            let scopeAmount = includedRequisitionId
              ? (() => {
                  let taxAmount =
                    service?.appliedAmount * service?.taxRate || 0;
                  let credit =
                    (service?.serviceLevelCredit || 0) +
                    (service?.serviceLevelCredit || 0) *
                      (service?.taxRate || 0);
                  let totalRentAmount =
                    service?.appliedAmount + taxAmount + credit;
                  let retainage =
                    service?.retainage || 0 > 0
                      ? (service?.retainage / 100) * totalRentAmount
                      : 0;
                  const total = totalRentAmount - retainage;
                  return total - taxAmount;
                })()
              : selectedCategory === "charges"
              ? service?.price // - (service?.taxAmount || 0)
              : calculateTotalPriceForService(service, selectedCategory).amount;

            const { previousInvoicedAmount } = calculatePreviousInvoiceTotals({
              service,
              exceptionInvoicesId: !!invoiceData
                ? [invoiceData?.invoiceId]
                : [],
            });

            //Calculating credited amount
            const { creditAmount } =
              calculateCreditedAmountForService({
                service,
                categoryFrom: selectedCategory,
              }) || 0;

            //Getting subtracting by other invoice related to this category group
            let amount =
              scopeAmount - (previousInvoicedAmount || 0) - (creditAmount || 0);

            if (amount === 0) warn = true;

            const selectedServiceGroup = servicesGroups?.find(
              ({ id }) => id === categoryId
            );

            const dates = findMinMaxDates(
              service?.serviceOptions || [],
              selectedCategory
            );

            let taxAmount =
              selectedCategory === "estimations"
                ? (service?.isTaxable ? service?.taxRate : 0) * amount || 0
                : includedRequisitionId
                ? (() => {
                    let taxAmount =
                      service?.appliedAmount * service?.taxRate || 0;
                    return taxAmount;
                  })()
                : selectedCategory === "rentals"
                ? selectedServiceGroup?.taxRate * amount
                : service?.taxAmount;

            const tax = taxAmount > 0;

            // taxAmount =
            //   /*taxAmount ||*/ chargeCategoryFrom === "Requisitions"
            //     ? service?.appliedAmount * service?.taxRate
            //     : amount * formData?.taxRate;

            /* Calculating Charged Amount for service */
            const objToPush = {
              id: index + 2,
              name: service.label,
              description: "",
              isHoist: checkIfServiceIsHoist(service),
              amount,
              //MaxSuggestedAmount is the maximum that a service can be invoiced
              maxSuggestedAmount: amount,
              amountPercentage: (amount / scopeAmount) * 100,
              scopeAmount,
              tax,
              taxAmount,
              includedRequisitionId,
              serviceId: service?.serviceId ? service?.serviceId : service?.id,
              ...((selectedCategory === "rentals" ||
                selectedCategory === "charges") &&
              service?.rentalId
                ? { rentalId: service?.rentalId, isFromRental: true }
                : {}),
              ...(chargeCategoryFrom ? { chargeCategoryFrom } : {}),
              data: {
                type: selectedCategory,
                categoryId,
                categoryElementIndex: index,
                groupName,
                ...(selectedCategory === "rentals" && { ...dates }),
              },
              total: parseFloat(amount) + (tax ? taxAmount || 0 : 0),
              creditedAmount: creditAmount,
            };
            prev.push(objToPush);
          }
        });

        accumulatedRentals?.forEach((rental, index) => {
          //Removes duplicated
          if (
            !prev.some(
              (rent) =>
                rental.rentalNumber === rent.rentalNumber &&
                rental?.requisitionNumber === rent?.requisitionNumber &&
                rental?.label !== rent.label
            )
          ) {
            let scopeAmount =
              rental.paymentDue > 0
                ? rental.paymentDue
                : rental.thisDistRetainage;
            const { previousInvoicedAmount } = calculatePreviousInvoiceTotals({
              service: rental,
              exceptionInvoicesId: !!invoiceData
                ? [invoiceData?.invoiceId]
                : [],
            });
            //Calculating credited amount
            const { creditAmount } =
              calculateCreditedAmountForService({
                service: rental,
                categoryFrom: selectedCategory,
              }) || 0;
            //Getting subtracting by other invoice related to this category group
            let amount =
              scopeAmount - (previousInvoicedAmount || 0) - (creditAmount || 0);
            const tax = 0;
            const taxAmount = 0;
            if (amount === 0) warn = true;
            const objToPush = {
              id: index + 1,
              name: rental.label,
              description: "",
              isHoist: checkIfServiceIsHoist(rental),
              amount,
              maxSuggestedAmount: amount,
              amountPercentage: (amount / scopeAmount) * 100,
              scopeAmount,
              tax,
              taxAmount,
              includedRequisitionId,
              isAccumulatedRental: true,
              // serviceId: service?.serviceId ? service?.serviceId : service?.id,
              ...(chargeCategoryFrom ? { chargeCategoryFrom } : {}),
              data: {
                amounts: [], //service?.amounts,
                serviceOptions: [rental],
                type: selectedCategory,
                categoryId,
                categoryElementIndex: index,
                groupName,
              },
              total: parseFloat(amount) + (tax ? taxAmount || 0 : 0),
              creditedAmount: creditAmount,
            };
            prev.push(objToPush);
          }
        });

        onOptionClickHandler({
          services: prev?.filter(({ amount }) => amount !== 0),
          setRowData,
        });
      } else {
        //Remove the unchecked services/products
        prev = prev.filter(
          (el) => !services?.map((el) => el.label).includes(el.name)
        );
      }
      const filteredPrev = [];

      prev?.forEach((cel) => {
        if (!filteredPrev?.find((el) => _.isEqual(cel, el))) {
          filteredPrev?.push(cel);
        }
      });

      productsList = filteredPrev;
      return [...(filteredPrev || [])];
    });

    if (warn) {
      message.warning(
        "This item's amount has been fully distributed so it cannot be added to the table."
      );
    }
    return productsList;
  }

  return (
    <div
      className="estimationsContainer"
      style={!!!invoiceData ? { width: "-webkit-fill-available" } : {}}
    >
      <div className="header">
        <div style={{ marginLeft: 15 }}>
          {firstLetterToUpperCase(selectedCategory)}
        </div>
      </div>

      <div className="body">
        <div className={"serviceGroupsContainer"}>
          <Select
            suffixIcon={<DropdownIcon />}
            className={"autoCompleteProduct"}
            popupClassName={isDarkMode && "darkDropDown"}
            value={selectedCategory}
            style={{
              width: 200,
            }}
            onChange={onCategoryChange}
            disabled={!isWritable && invoiceData}
          >
            {categories
              ?.filter((_category) => _category !== "applications")
              .map((category, i) => (
                <Select.Option key={i} value={category}>
                  {firstLetterToUpperCase(category)}
                </Select.Option>
              ))}
          </Select>
          <SelectedCategoryGroupContext.Provider
            value={{
              selectedCategoryGroupNumber,
              isDarkMode,
              onSelectionChanged: ({ row: serviceGroup, index }) => {
                onSelectionChanged({
                  serviceGroup,
                  index,
                  onServiceGroupCheckBoxChange,
                  selectedCategory,
                  setSelectedCategoryGroupNumber,
                  categoryData,
                });
              },
              selectedCategory,
            }}
          >
            <GroupsTable
              selectedCategory={selectedCategory}
              categoriesName={categoriesName}
              servicesGroups={servicesGroups}
            />
          </SelectedCategoryGroupContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default InvoiceGroup;
