import { useEffect } from "react";
import { Form, message } from "antd";

import { SimplePlacesInput } from "../../../../../../../../../components";

function FenceSaveForm({
  stopData,
  isDarkMode,
  toggleMode,
  selectedFence,
  handleCreateOnLocation,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedFence) {
      form.setFieldValue("name", selectedFence.address);
    } else {
      form.setFieldValue("name", stopData.address);
    }
  }, [stopData, selectedFence]);

  async function submitHandler() {
    await form
      .validateFields()
      .then(({ name }) => {
        handleCreateOnLocation(name);
      })
      .catch(() => {
        message.warning({
          content: "Please select a valid fence name",
          key: "saveError",
        });
      });
  }

  return (
    <Form form={form} className="map-fence-form">
      <SimplePlacesInput
        {...{
          required: true,
          formItemName: "name",
          label: "Geofence name",
          className: "locations-input",
          placeholder: "Search location...",
          popupClassName: isDarkMode ? "darkDropDown" : undefined,
        }}
      />
      <div className="info-form-footer">
        <span
          className="info-form-button form-button-deny"
          onClick={() => {
            toggleMode();
          }}
        >
          Cancel
        </span>
        <span
          className="info-form-button form-button-confirm"
          onClick={() => {
            submitHandler();
          }}
        >
          Confirm
        </span>
      </div>
    </Form>
  );
}

export default FenceSaveForm;
