import { XIcon } from "../components/SidebarPages/Communication/assets/index";

export const antdModalProps = {
  closable: true,
  destroyOnClose: true,
  wrapClassName: "cardLayoutModal",
  footer: null,
  maskClosable: false,
  centered: true,
  closeIcon: <XIcon />,
};
