import createPDF from "../../../../../integrations/createPDF";
import { defaultExcelHeaderStyle } from "../../../../../helpers/constants/defaultExcelHeaderStyle";
import { defaultExcelBodyStyle } from "../../../../../helpers/constants/defaultExcelBodyStyle";
import { excelCellFitToColumn } from "../../../../../utils/excelCellFitToColumn";
import { tableHeaders, tableHeadersRequest } from "../constants/tableHeadrs";
import XLSX from "sheetjs-style";
import { generateDocDefinition } from "../../../../../utils/generateDocDefinition";
import { exportExcel } from "../../../../../utils/exportExcel";

const getExcelHeaders = (status) => {
  return status === "Requests"
    ? [
        { v: "Category", s: defaultExcelHeaderStyle },
        { v: "Assigned To", s: defaultExcelHeaderStyle },
        { v: "Assigned By", s: defaultExcelHeaderStyle },
        { v: "Record Name", s: defaultExcelHeaderStyle },
      ]
    : [
        { v: "Member", s: defaultExcelHeaderStyle },
        { v: "Response", s: defaultExcelHeaderStyle },
        { v: "Topic", s: defaultExcelHeaderStyle },
        { v: "Record Name", s: defaultExcelHeaderStyle },
        { v: "Requested By", s: defaultExcelHeaderStyle },
      ];
};

const getExcelRows = (data, status) => {
  let rows = [];

  if (status === "Requests") {
    const updatedData = data.filter((item) => item?.request?.length);

    for (const data of updatedData) {
      for (const item of data?.request) {
        rows.push([
          { v: data?.category || "", s: defaultExcelBodyStyle },
          { v: item?.assignedTo || "", s: defaultExcelBodyStyle },
          { v: item?.userRequesting || "", s: defaultExcelBodyStyle },
          {
            v: item?.requestObject?.jobsiteAddress || "",
            s: defaultExcelBodyStyle,
          },
        ]);
      }
    }
  } else {
    rows = data.map((item) => {
      return status === "Requests"
        ? [
            { v: item?.member || "", s: defaultExcelBodyStyle },
            { v: item?.category || "", s: defaultExcelBodyStyle },
            { v: item?.userRequesting || "", s: defaultExcelBodyStyle },
            {
              v: item?.requestObject?.jobsiteAddress || "",
              s: defaultExcelBodyStyle,
            },
          ]
        : [
            { v: item?.member || "", s: defaultExcelBodyStyle },
            { v: item?.actionType || "", s: defaultExcelBodyStyle },
            { v: item?.topic || "", s: defaultExcelBodyStyle },
            { v: item?.recordName || "", s: defaultExcelBodyStyle },
            {
              v: item?.currentData?.requestedBy || "",
              s: defaultExcelBodyStyle,
            },
          ];
    });
  }

  return rows;
};

export const onGeneratePDF = (
  action,
  additionalData,
  getDocDefinition = false,
  headersData
) => {
  const { data, status, base64 } = additionalData;

  const header = status === "Requests" ? tableHeadersRequest : tableHeaders;

  const columnsToInclude = headersData || header.map((item, index) => index);

  const tableData = [];

  if (status === "Requests") {
    const updatedData = data.filter((item) => item?.request?.length);

    for (const data of updatedData) {
      for (const item of data.request) {
        const category = data.category;
        const assignedTo = item?.assignedTo || "";
        const assignedBy = item?.userRequesting || "";
        const recordName = item?.requestObject?.jobsiteAddress || "";

        tableData.push([category, assignedTo, assignedBy, recordName]);
      }
    }
  } else {
    for (const item of data) {
      const member = item?.member || "";
      const response = item?.actionType || "";
      const topic = item?.topic || "";
      const recordName = item?.recordName || "";
      const requestedBy = item?.currentData?.requestedBy || "";

      tableData.push([member, response, topic, recordName, requestedBy]);
    }
  }

  const docDefinition = generateDocDefinition(
    base64,
    status === "Requests" ? "Requests" : "Approvals",
    columnsToInclude,
    tableHeaders,
    tableData
  );

  if (getDocDefinition) {
    return docDefinition;
  }

  createPDF({
    action,
    docDefinition,
    title: action === "print" ? false : "Approvals.pdf",
  });
};

export const exportToExcel = (
  additionalData,
  getDocDefinition = false,
  headersData
) => {
  const { data, status } = additionalData;

  if (getDocDefinition)
    return onGeneratePDF("download", additionalData, getDocDefinition);

  const headers = getExcelHeaders(status);
  const rows = getExcelRows(data, status);

  const columnsToInclude = headersData || headers.map((item, index) => index);

  exportExcel(
    headers,
    columnsToInclude,
    rows,
    status === "Requests" ? "Requests" : "Sheet1",
    status === "Requests" ? "Requests" : "Approvals"
  );
};

export const uploadExcelToDrive = async (
  additionalData,
  driveRequest,
  driveFolderId
) => {
  const { data, status } = additionalData;

  try {
    const headers = getExcelHeaders(status);
    const rows = getExcelRows(data, status);

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    worksheet["!cols"] = excelCellFitToColumn([headers, ...rows]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to an Excel binary array
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the array buffer to a blob with the correct MIME type
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Upload the Excel file to Google Drive
    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name: `${status === "Requests" ? "Requests" : "Approvals"}.xlsx`,
        parents: [driveFolderId],
      }
    );

    // Extract and log information about the uploaded file
    const { id, newName } = await uploadResponse.json();

    // Update state or perform any necessary actions with the file ID
    return { id, name: newName };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};
