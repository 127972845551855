import { message } from "antd";
import { API } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useContext, useState, useEffect, useMemo } from "react";

import PayrollLiveContext from "../PayrollLiveContext";
import { MondayButton } from "src/components/commonComponents";
import { ChartCardType, DatasetType } from "../payrollLiveTypes";
import { getRandomColor } from "src/components/SidebarPages/utils";
import { SmallLoadableComp } from "src/components/Sidebars/components";
import { preferences as setPreferences } from "src/actions/preferences";
import PayrollLiveCharts from "../components/PayrollLiveCharts/PayrollLiveCharts";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import {
  getDataSets,
  initialChartsLayout,
} from "src/components/pages/Payroll/Tabs/AnalyticsCharts/utils/chartsHelpersData";
import ChartCardModal from "src/components/pages/Payroll/Tabs/AnalyticsCharts/components/ChartCardModal/ChartCardModal";

import "./PayrollLiveAnalytics.scss";

function PayrollLiveAnalytics() {
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const preferences = useSelector(
    (store: StoreType) => store.preferences.preferences
  );

  const { analytics, degEntries, programEmployees, jobsites } =
    useContext(PayrollLiveContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [dataSets, setDataSets] = useState<DatasetType>({});
  const [layout, setLayout] =
    useState<Array<ChartCardType>>(initialChartsLayout);
  const [createCardModal, setCreateCardModal] = useState<boolean>(false);
  const [changes, setChanges] = useState(false);
  const [hiddenCharts, setHiddenCharts] = useState<Array<ChartCardType>>([]);

  const dispatch = useDispatch();

  const selectedDataOptions = useMemo(() => {
    const dataOptions = {
      Employees: {
        "Regular Hours": dataSets["employeeCardsData"]?.employeesWorkHours,
        "Overtime Total": dataSets["employeeCardsData"]?.employeesOvertime,
        "Regular Amount": dataSets["employeeCardsData"]?.employeesTotalAmount,
        "Overhead Hours": dataSets["employeeCardsData"]?.employeesOverheadHours,
        "Overtime Hours": dataSets["employeeCardsData"]?.employeesOvertimeHours,
        "Overhead Amount":
          dataSets["employeeCardsData"]?.employeesOverheadAmount,
        "Ovh + Reg Amount":
          dataSets["employeeCardsData"]?.employeesRegWithOvhAmount,
        "Ovh + Reg Hours":
          dataSets["employeeCardsData"]?.employeesRegWithOvhHours,
      },
      Jobsites: {
        "Overtime Amount": dataSets["jobsiteCardsData"]?.jobsiteOvertime,
        "Regular Amount": dataSets["jobsiteCardsData"]?.totalJobsiteAmount,
        "Regular Hours": dataSets["jobsiteCardsData"]?.totalJobsiteWorkHours,
        "Overtime Hours": dataSets["jobsiteCardsData"]?.jobsiteOvertimeHours,
        "Ovh + Reg Hours": dataSets["jobsiteCardsData"]?.jobsiteRegWithOvhHours,
        "Ovh + Reg Amount":
          dataSets["jobsiteCardsData"]?.jobsiteRegWithOvhAmount,
      },
      Teams: {
        "Regular Hours": dataSets?.["teamCardsData"]?.teamRegularHours,
        "Ovh + Reg Hours": dataSets?.["teamCardsData"]?.teamTotalHours,
        "Overhead Hours": dataSets?.["teamCardsData"]?.teamOverheadHours,
        "Regular Amount": dataSets?.["teamCardsData"]?.teamRegularAmount,
        "Overtime Hours": dataSets?.["teamCardsData"]?.teamOvertimeHours,
        "Ovh + Reg Amount": dataSets?.["teamCardsData"]?.teamTotalAmount,
        "Overhead Amount": dataSets?.["teamCardsData"]?.teamOverheadHours,
        "Overtime Amount": dataSets?.["teamCardsData"]?.teamOvertimeAmount,
      },
    };

    if (!!layout?.length) {
      let newLayout = [];
      const labels = {
        ["Teams"]: dataSets?.["teamLabels"] || [],
        ["Jobsites"]: dataSets?.["jobsiteLabels"] || [],
        ["Employees"]: dataSets?.["employeesLabels"] || [],
      };

      for (const card of layout) {
        let cardDataSets = [];
        for (const dataset of card?.chartData?.datasets) {
          const borderColor = (labels?.[dataset?.showDataFor] || []).map(() =>
            getRandomColor()
          );

          const newDataSet = {
            labels: labels?.[dataset?.showDataFor],
            borderColor,
            backgroundColor: borderColor.map((color) => color + "66"),
            data: dataOptions?.[dataset?.showDataFor]?.[
              dataset?.selectedDataOption
            ],
          };
          cardDataSets.push(Object.assign(dataset, newDataSet));
        }
        const modifiedCard = Object.assign(card, {
          chartData: {
            ...card.chartData,
            datasets: cardDataSets,
            labels: labels?.[cardDataSets?.[0]?.showDataFor] || [],
          },
        });
        newLayout.push(modifiedCard);
      }
      // const formFields = {};
      // for (const card in newLayout) {
      //   Object.assign(formFields, { [card?.cardKey]: null });
      // }

      // form.setFieldsValue(formFields);
      setLayout(newLayout);
    }

    return dataOptions;
  }, [JSON.stringify(dataSets)]);

  function saveLayout() {
    message.loading({ key: "saveLayout", duration: 0, content: "Saving..." });
    let chartsLayout = [];
    let hiddenChartCards = [];

    for (const card of layout) {
      const compressedCard = {
        ...card,
        chartData: {
          ...card.chartData,
          labels: [],
          datasets: [],
        },
      };
      let cardDatasets = [];
      for (const dataset of card?.chartData?.datasets || []) {
        const compressedDataset = {
          ...dataset,
          backgroundColor: [],
          borderColor: [],
          labels: [],
          data: [],
        };
        cardDatasets.push(compressedDataset);
      }
      Object.assign(compressedCard.chartData, { datasets: cardDatasets });
      chartsLayout.push(compressedCard);
    }

    for (const card of hiddenCharts) {
      const compressedCard = {
        ...card,
        chartData: {
          ...card.chartData,
          labels: [],
          datasets: [],
        },
      };
      let cardDatasets = [];
      for (const dataset of card?.chartData?.datasets || []) {
        const compressedDataset = {
          ...dataset,
          backgroundColor: [],
          borderColor: [],
          labels: [],
          data: [],
        };
        cardDatasets.push(compressedDataset);
      }

      Object.assign(compressedCard.chartData, { datasets: cardDatasets });
      hiddenChartCards.push(compressedCard);
    }

    const newPreferences = {
      preferences: {
        ...preferences.preferences,
        analyticsLiveCharts: {
          chartsLayout,
          hiddenCharts,
        },
      },
    };

    API.put("preferences", "/preferences", {
      body: newPreferences,
    })
      .then(() => {
        dispatch(setPreferences(newPreferences));
        message.success({
          key: "saveLayout",
          duration: 1.8,
          content: "Layout saved successfully!",
        });
      })
      .catch(() => {
        message.error({
          key: "saveLayout",
          duration: 1.8,
          content: "There was a problem saving the Layout!",
        });
      });
  }

  useEffect(() => {
    const includedEmployees = Object.keys(analytics.employeesHoursPerDay) || [];
    const filteredEmployees = programEmployees.filter((el) =>
      includedEmployees.includes(el?.employeeId)
    );

    const tmpDataSets = getDataSets({
      jobsites,
      crews: filteredEmployees,
      analytics: degEntries,
      analyticsProp: analytics,
    });

    setDataSets(tmpDataSets);
    setLoading(false);
  }, [degEntries, JSON.stringify(analytics), programEmployees, jobsites]);

  
  useEffect(() => {
    if (!layout?.length && JSON.stringify(dataSets)?.length > 2) {
      const layoutCards =
        preferences?.preferences?.analyticsLiveCharts?.chartsLayout || [];
      const hiddenLayoutCards =
        preferences?.preferences?.analyticsLiveCharts?.hiddenCharts || [];
      const labelOptions = {
        ["Employees"]: dataSets?.["employeesLabels"] || [],
        ["Jobsites"]: dataSets?.["jobsiteLabels"] || [],
        ["Teams"]: dataSets?.["teamLabels"] || [],
      };

      let populatedLayout = [];
      let populatedHiddenCards = [];

      if (!!layoutCards?.length && hiddenLayoutCards?.length) {
        return;
      }

      for (const card of layoutCards) {
        const labels = labelOptions[card?.chartData?.showDataFor];
        const borderColor = labels.map((el) => getRandomColor());
        const backgroundColor = borderColor.map((color) => color + "66");
        const newCard = {
          ...card,
          chartData: {
            ...card?.chartData,
            labels,
          },
        };

        let newDatasets = [];
        for (const dataset of card?.chartData?.datasets) {
          const newDataset = {
            ...dataset,
            backgroundColor,
            borderColor,
            data:
              selectedDataOptions?.[dataset?.showDataFor]?.[
                dataset?.selectedDataOption
              ] || [],
          };
          newDatasets.push(newDataset);
        }
        Object.assign(newCard.chartData, { datasets: newDatasets });
        populatedLayout.push(newCard);
      }

      for (const card of hiddenLayoutCards) {
        const labels = labelOptions[card?.chartData?.showDataFor];
        const borderColor = labels.map((el) => getRandomColor());
        const backgroundColor = borderColor.map((color) => color + "66");
        const newCard = {
          ...card,
          chartData: {
            ...card?.chartData,
            labels,
          },
        };

        let newDatasets = [];
        for (const dataset of card?.chartData?.datasets) {
          const newDataset = {
            ...dataset,
            backgroundColor,
            borderColor,
            data:
              selectedDataOptions?.[dataset?.showDataFor]?.[
                dataset?.selectedDataOption
              ] || [],
          };
          newDatasets.push(newDataset);
        }
        Object.assign(newCard.chartData, { datasets: newDatasets });
        populatedHiddenCards.push(newCard);
      }

      setLayout(populatedLayout);
      setHiddenCharts(populatedHiddenCards);
    }
  }, [JSON.stringify(preferences), JSON.stringify(dataSets)]);

  return (
    <section
      className={`payroll-live-analytics ${
        darkMode ? "payroll-live-analytics-dark" : ""
      }`}
    >
      <div className="analytics-control-panel">
        <MondayButton
          Icon={<PlusOutlined />}
          className="mondayButtonBlue"
          onClick={() => setCreateCardModal(true)}
        >
          Add Chart
        </MondayButton>
        <MondayButton Icon={<TickIcon />} onClick={saveLayout}>
          Save Layout
        </MondayButton>
      </div>
      <SmallLoadableComp loading={loading}>
        <PayrollLiveCharts layout={layout} setLayout={setLayout} />
      </SmallLoadableComp>
      {createCardModal && (
        <ChartCardModal
          layout={layout}
          dataSets={dataSets}
          setLayout={setLayout}
          open={createCardModal}
          setChanges={setChanges}
          selectedDataOptions={selectedDataOptions}
          onCancel={() => setCreateCardModal(false)}
        />
      )}
    </section>
  );
}

export default PayrollLiveAnalytics;
