export const hasSettingsAccess = (userConfiguration, path) => {
  const levels = path.split("/");

  const routeConfigSettings = userConfiguration?.routeConfig?.find(
    (route) => route.title === "Settings"
  ).children;

  function findLevel(routeConfig, index) {
    const found = routeConfig.find((route) => route.title === levels[index]);

    if (!found) {
      return false;
    }

    if (index === levels.length - 1) {
      return found.write || false;
    }

    return findLevel(found.children || [], index + 1);
  }

  return findLevel(routeConfigSettings, 0);
};
