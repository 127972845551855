const gridFilters = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Jobsite Address",
    type: "Dropdown",
    key: "jobAddress",
  },
  {
    globalKeyId: "8fewfwbc3-0b8a-400f-b352-6354cb0f22ec",
    label: "Job Name",
    type: "Dropdown",
    key: "jobName",
  },
  // {
  //   globalKeyId: "8fdedbc3-0b8aweee-b352-6354cb0f22ec",
  //   label: "Client",
  //   type: "Dropdown",
  //   key: "accountName",
  // },
  {
    globalKeyId: "8fdedbc3-xxxx-400f-b352-6354cb0f22ec",
    label: "Payroll Category",
    type: "Dropdown",
    key: "payrollType",
  },
];

export default gridFilters;
