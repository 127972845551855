import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import { formatCurrency } from "../../../../utils";
import { generateEstimationsForOpportunities } from "../../../NewDashboardFunctions";

export const opportunities = (
  updatedDynamicStates,
  IdHandler,
  programFields,
  projectsData
) => {
  const projectEstimations = () => {
    let toReturn = {};

    (updatedDynamicStates?.[IdHandler] || [])?.forEach((proj) => {
      if (!proj.hasOwnProperty("services")) return;

      Object.entries(proj?.services)?.forEach(([key, value]) => {
        toReturn[key] = value;
      });
    });

    return toReturn;
  };

  return {
    opportunityStatus: () => ({
      title: "Opportunity Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStatus",
        secondaryField: "opportunityStatus",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: a?.opportunityStatus,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.opportunityId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    opportunityStage: () => ({
      title: "Opportunity Stage",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStage",
        secondaryField: "opportunityStage",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: a?.opportunityStage,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Opportunities?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.opportunityStage?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    totalEstimationsAmount: () => ({
      title: "Total Estimations Amount for Opportunity",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Opportunities",
        field: "totalEstimationsAmount",
        secondaryField: "opportunityAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: formatCurrency(
              getTotalServicesPrice(
                generateEstimationsForOpportunities(
                  a?.estimations,
                  projectEstimations()
                )?.flat()
              )
            ),
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.opportunityId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    geoFenceInfo: () => ({
      title: "Geofence Quantity For Opportunitie",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Opportunities",
        field: "geoFenceInfo",
        secondaryField: "opportunityAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: a?.geoFenceInfo?.length?.toString(),
            color: "#ECDE7D",
            id: a?.opportunityId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
