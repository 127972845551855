import { Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import "./MissingFilesRenderer.scss";
import { queryParams } from "../../../../../../utils/queryParams";

const MissingFilesRenderer = ({ value = [], data = {}, docMapping = {} }) => {
  let correctUrl = docMapping[data?.categoryName]?.endpoint?.toLowerCase();

  if (correctUrl === "fleet") {
    correctUrl = "fleets";
  } else if (correctUrl === "drivers") {
    correctUrl = "fleet/drivers";
  }

  return (
    <div className="missingFilesContainer">
      {!!value.length ? (
        <Tooltip
          {...{
            title: (
              <div className="tooltipContent">
                {value.map((docName) => (
                  <div>{docName}</div>
                ))}
              </div>
            ),
            overlayClassName: "missingFilesTooltip",
          }}
        >
          <Link
            to={{
              pathname: `/${correctUrl}/${data?.recordId}`,
              state: { tabPosition: "documentation" },
              search: queryParams({ tab: "Documentation" }),
            }}
          >
            <Button
              style={{ borderRadius: 5, backgroundColor: "#fe4c4a" }}
              danger
              type="primary"
            >
              {value.length}
            </Button>
          </Link>
        </Tooltip>
      ) : (
        <Tooltip
          title={!!value.length ? "" : "there is no missing files to show"}
        >
          <Button
            style={{ borderRadius: 5, backgroundColor: "#71cf48", border: 0 }}
            type="primary"
          >
            {value.length}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default MissingFilesRenderer;
