import { Button } from "antd";
import "./ClientDropDown.scss";

const ClientDropDown = ({ menu, setClientModalVisible, checkClientAccess }) => {
  return (
    <div className="customContactsDropDown">
      <div className="dropdownMenu">{menu}</div>
      <Button
        type="primary"
        shape="round"
        disabled={!checkClientAccess}
        onClick={() => setClientModalVisible(true)}
      >
        New Client
      </Button>
    </div>
  );
};

export default ClientDropDown;
