import React from "react";

import { useStepData } from "../../hooks";
import { NextStepInputs } from "../NextStepInputs";
import { generateVehicleDamageInputFields } from "./helpers";

const Incident = ({ inputData, savedRecord, setInputData }) => {
  const { drivers } = useStepData("Incidents");

  const inputFields = generateVehicleDamageInputFields({ drivers });

  return <NextStepInputs {...{ inputData, setInputData, inputFields }} />;
};

export default Incident;
