import { Tour } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../../../../commonComponents";
import { PlayVideoTutorial } from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../../../icons";

const NotificationVideoTutorial = ({ tourOpen, setTourOpen }) => {
  const { programFields } = useSelector((state) => state.programFields);

  const [showVideoTutorial, setShowVideoTutorial] = useState(false);

  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "General Tutorials")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "Notifications"
    )?.videoLink;

  return (
    <>
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={[
            {
              title: "Notifications Tutorial",
              description: (
                <MondayButton
                  {...{
                    className: "mondayButtonBlue",
                    onClick: () => {
                      setShowVideoTutorial(true);
                      setTourOpen(false);
                    },
                    style: { display: "flex", justifyContent: " center" },
                    Icon: <VideoTutorialIcon />,
                  }}
                >
                  Watch Video
                </MondayButton>
              ),
              className: "custom-tour-light",
              placement: "center",
            },
          ]}
          mask={{ color: "#2a2b3a71" }}
        />
      )}

      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Notification Tutorial",
          }}
        />
      )}
    </>
  );
};

export default NotificationVideoTutorial;
