import { camelToTitle } from "src/utils";
import {
  SideInfo,
  SideInfoType,
  MappedSectionType,
  FleetMaintenanceType,
} from "../types";

type MaintenanceKeys = Array<keyof FleetMaintenanceType>;

const defaultKeys: MaintenanceKeys = [
  "maintenanceDate",
  "maintenanceStatus",
  "mechanicId",
  "mechanicInfo",
];

function getSideMaintenanceInfo(
  maintenance: FleetMaintenanceType,
  colors?: MappedSectionType,
  propKeys?: MaintenanceKeys
): SideInfoType {
  const config: SideInfoType = [];
  const keys = propKeys ?? defaultKeys;

  if (!maintenance) {
    return config;
  }

  for (const property of keys) {
    const key = property as keyof FleetMaintenanceType;
    let label = camelToTitle(key);

    const data = {} as SideInfo;

    if (key === "maintenanceStatus") {
      data["value"] = maintenance[key];
      data["color"] =
        colors.statuses.find(
          ({ status }) => status === maintenance.maintenanceStatus
        )?.color || undefined;
      label = "Status";
    } else if (key === "maintenanceDate") {
      data["value"] = maintenance[key];
      data["format"] = "MMM DD, YYYY";
    } else if (key === "mechanicId") {
      label = "Mechanic Name";
      data["value"] = maintenance.mechanicInfo.name || "No Data";
    } else if (key === "mechanicInfo") {
      label = "Mechanic Note";
      data["value"] = maintenance.mechanicInfo.mechanicNote || "No Data";
      data["html"] = true;
    } else {
      if (
        typeof maintenance[key] === "string" ||
        typeof maintenance[key] === "number"
      ) {
        //@ts-ignore
        data["value"] = maintenance[key] || "No Data";
      } else {
        continue;
      }
    }

    config.push({
      label,
      data,
    });
  }

  return config;
}

export default getSideMaintenanceInfo;
