import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../SidebarPages/utils";

export function mainColDefs() {
  return [
    {
      headerName: "Jobsite Name",
      field: "jobsiteName",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "Jobsite Address",
      field: "jobsiteAddress",
    },
    {
      headerName: "Payroll Category",
      field: "payrollType",
    },
    {
      headerName: "Client Name",
      field: "accountName",
      cellRenderer: ({ value }) => {
        return <div>{value?.accountName}</div>;
      },
      getQuickFilterText: ({ value }) => {
        return value?.accountName;
      },
    },
    {
      headerName: "Total",
      field: "total",
      cellRenderer: ({ value }) => {
        return formatCurrency(value);
      },
    },
  ];
}

export function detailColDefs() {
  return {
    detailGridOptions: {
      columnDefs: [
        {
          headerName: "Role",
          field: "employeeRole",
        },
        {
          headerName: "Hours Worked",
          field: "hoursWorked",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Unmatched Hours",
          field: "unmatchedHours",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Total Reg",
          field: "regAmount",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
        {
          headerName: "Total Overtime",
          field: "otAmount",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
        {
          headerName: "Total (Unmatched Shifts)",
          field: "totalUnmatched",
          cellRenderer: ({ value }) => {
            return formatCurrency(value || 0);
          },
        },
        {
          headerName: "Total Project Cost",
          field: "totalAmount",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
        {
          headerName: "Total Overhead",
          field: "totalOverhead",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableColResize: true,
      },
      pagination: false,
    },
    getDetailRowData(params) {
      params.successCallback(params.data.details);
    },
  };
}
