/**
 *
 * @param {String} formItemName - The form item name to check
 * @param {Array} inputFields - The input fields array
 * @returns {Boolean} - Whether the field is a date field or not
 */
function checkDateField(formItemName, inputFields) {
  const field = inputFields.find(
    (field) => field.formItemName === formItemName
  );

  if (field) {
    return (
      field?.type?.toLowerCase() === "datepicker" ||
      field?.type?.toLowerCase() === "timepicker"
    );
  }

  return false;
}

export default checkDateField;
