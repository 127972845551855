import { ColDef } from "ag-grid-enterprise";
import { parseInTz } from "../../NewDispatchModal/utils/dateFunctions";
import { Button } from "antd";

/** @type {ColDef[]} */
const addressDispatchesColumns = [
  {
    headerName: "From Location",
    field: "pickUp",
    cellClass: "cell-left-aligned",
    checkboxSelection({ node }) {
      return !node.group;
    },
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Planned Departure",
    field: "departAt",
    cellClass: "cell-left-aligned",
    cellRenderer({ value }) {
      if (!value) {
        return "";
      }

      return parseInTz(value).format("MMM DD, YYYY hh:mm a");
    },
  },
  {
    headerName: "Actual Departure",
    field: "actualDepart",
    cellClass: "cell-left-aligned",
    cellRenderer({ value }) {
      if (!value) {
        return "N/A";
      }

      return parseInTz(value).format("MMM DD, YYYY hh:mm a");
    },
  },
  {
    headerName: "To Location",
    field: "dropOff",
    cellClass: "cell-left-aligned",
  },
  {
    headerName: "Planned Arrival",
    field: "arriveBy",
    cellClass: "cell-left-aligned",
    cellRenderer({ value }) {
      if (!value) {
        return "";
      }

      return parseInTz(value).format("MMM DD, YYYY hh:mm a");
    },
  },
  {
    headerName: "Actual Arrival",
    field: "actualArrive",
    cellClass: "cell-left-aligned",
    cellRenderer({ value }) {
      if (!value) {
        return "N/A";
      }

      return parseInTz(value).format("MMM DD, YYYY hh:mm a");
    },
  },
  {
    headerName: "Vehicle",
    field: "fleetName",
    cellClass: "cell-left-aligned",
  },
  {
    headerName: "Driver",
    field: "driverName",
    cellClass: "cell-left-aligned",
  },
  {
    headerName: "Cargo",
    field: "cargo",
    cellClass: "cell-left-aligned",
    cellStyle({ value }) {
      return {
        color: value === "Loaded" ? "#00A464" : "#F04F4E",
        fontWeight: 600,
      };
    },
  },
  {
    headerName: "Route Name",
    field: "routeIndex",
    cellClass: "cell-left-aligned",
    valueFormatter({ value }) {
      return `Route ${value + 1}`;
    },
  },
  {
    headerName: "Show Dispatch",
    cellRenderer({ data, context }) {
      return (
        <Button
          type="primary"
          style={{ backgroundColor: "#1264a3" }}
          onClick={() => {
            if (context.onShowDispatch) {
              context.onShowDispatch(data);
            }
          }}
        >
          Show Dispatch
        </Button>
      );
    },
  },
];

export default addressDispatchesColumns;
