export const convertDataOfPDF = (data) => {
  let count = 0;
  const header = {};
  const body = [];

  data.forEach((row, index) => {
    const areText = row.find((item) => !item.hasOwnProperty("text"));
    if (areText) return (count = index + 1);
  });

  data[count].forEach((row, index) => {
    if (index < 10) {
      header[index] = row.text;
    }
  });

  data.slice(count + 1).forEach((row) => {
    const updatedRow = row.map((item, index) => ({
      [index]: item.text,
    }));
    body.push(updatedRow);
  });

  return {
    header,
    body,
  };
};
