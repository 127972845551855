import {
  getBoroughSum,
  repeatedElementSum,
} from "../../../NewDashboardFunctions";

export const scheduling = (updatedDynamicStates, IdHandler, programFields) => {
  return {
    typeOfWork: () => ({
      title: "Scheduling",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Scheduling",
        field: "typeOfWork",
        title: "Type Of Work",
        subtitle: "",
        content: programFields
          ?.find((a) => a?.fieldName === "Scheduling Types")
          ?.fieldOptions?.map(({ typeName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            dateCreated: 1630965600000,
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (a) => a?.typeOfWork === typeName
              ).length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: typeName,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.typeOfWork === typeName
            ).length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }), //typeofWork

    projectExecutive: () => ({
      title: "Schedule",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Scheduling",
        field: "projectExecutive",
        title: "Project Executive",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    projectManager: () => ({
      title: "Schedule ",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Scheduling",
        field: "projectManager",
        title: "Project Manager",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectManagers"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    scheduleAddress: () => ({
      title: "Schedule ",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Scheduling",
        field: "scheduleAddress",
        title: "Schedule Address",
        subtitle: "",
        content: Object.entries(
          getBoroughSum(
            updatedDynamicStates?.[IdHandler] || [],
            "scheduleAddress"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  }; //schaduling
};
