import React from "react";
import { ClosedEyeIcon, FilledEyeIcon } from "../../../assets";
import styles from "./EyeCheckBox.module.scss";

const iconStyle = { fill: "white", height: 13, width: 24 };

const EyeCheckBox = ({ checked }) => {
  return (
    <div
      checked={checked}
      className={`${styles.checkBox} ${checked ? styles.checkedActive : ""}`}
      data-testid="checkbox-toggle"
    >
      {checked ? (
        <FilledEyeIcon style={iconStyle} />
      ) : (
        <ClosedEyeIcon style={{ ...iconStyle, height: 14 }} />
      )}
    </div>
  );
};

export default EyeCheckBox;
