import { convertRectangleToLatLng } from "../components/commonComponents/Map/Geofence/utils";

const useCalculateCenterOfCoordinates = () => {
  const calculateCenter = (path = [], editShape) => {
    const isArray = Array.isArray(path);

    const proceedPath = isArray ? path : convertRectangleToLatLng(path);

    const numPoints = proceedPath?.length;

    if (numPoints === 0) {
      return null;
    }

    let x = 0;
    let y = 0;
    let z = 0;

    for (const point of proceedPath) {
      const latitude = (point.lat * Math.PI) / 180;
      const longitude = (point.lng * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }

    x /= numPoints;
    y /= numPoints;
    z /= numPoints;

    const centerLongitude = Math.atan2(y, x);
    const hypotenuse = Math.sqrt(x * x + y * y);

    let centerLatitude;
    if (editShape) {
      centerLatitude += 0.0004;
    } else {
      centerLatitude = Math.atan2(z, hypotenuse);
    }

    return {
      lat: (centerLatitude * 180) / Math.PI || 40.712,
      lng: (centerLongitude * 180) / Math.PI || -74.006,
    };
  };
  return { calculateCenter };
};

export default useCalculateCenterOfCoordinates;
