import React, { useState } from "react";
import { Badge, Button, Select, message } from "antd";
import { DaySelectOption } from "../SmallComponents/DaySelectOption";
import dayjs from "dayjs";
import { DropDownArrow } from "../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { sortDayIds } from "../DaysInScheduleMaster/daysInScheduleMaster";
import { isElevationCompletedInPrevSchedule } from "../../../SchedulingFirstPage/helperData";

export const SelectDaysElevation = (props) => {
  const {
    elevation = {},
    updateDaysFromElevationToRows,
    serviceId = "",
    elevationIndex,
    scheduleDays,
    editMode,
    isWritable,
    agGridApiForEachElevation = {},
    isDarkMode,
    selectedDays,
  } = props;

  let daysAvailableForSelection = scheduleDays
    ?.filter(
      (sch) =>
        !sch?.isNotAvailable() &&
        sch?.status !== "Postponed" &&
        sch?.status !== "Canceled"
    )
    ?.sort((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate));

  const days = sortDayIds(scheduleDays, selectedDays) || [];

  return (
    <>
      <Select
        disabled={
          editMode && !isElevationCompletedInPrevSchedule(elevation)
            ? !isWritable
            : isElevationCompletedInPrevSchedule(elevation)
        }
        notFoundContent={"No Data"}
        placeholder={
          isElevationCompletedInPrevSchedule(elevation)
            ? "Completed Elevation"
            : "Select Days"
        }
        className="days-in-schedule-cell-select"
        popupClassName={isDarkMode && "darkDropDown"}
        value={days}
        onSelect={(d) => {
          updateDaysFromElevationToRows(
            serviceId,
            elevationIndex,
            days,
            d,
            "add"
          );
        }}
        suffixIcon={<DropDownArrow />}
        onDeselect={(d) => {
          if (!agGridApiForEachElevation?.[serviceId]?.[elevationIndex]) {
            message.warning("Please open the panel before editing!");
            return;
          }
          updateDaysFromElevationToRows(
            serviceId,
            elevationIndex,
            days,
            d,
            "remove"
          );
        }}
        optionLabelProp="label"
        mode="multiple"
        style={{
          maxWidth: 600,
          marginRight: 10,
          overflowX: "auto",
          minWidth: 200,
        }}
        popupMatchSelectWidth={false}
        onClick={(e) => {
          if (!!agGridApiForEachElevation?.[serviceId]?.[elevationIndex]) {
            e.stopPropagation();
          }
        }}
      >
        {daysAvailableForSelection?.map?.((scheduleDay) => {
          return DaySelectOption({ scheduleDay, isDarkMode });
        })}
      </Select>
    </>
  );
};
