import API from "@aws-amplify/api";
import { requestAllPages } from "../../../../../../utils/paginationFetchData";
import { CheckIfValidId } from "../../../../../SidebarPages/Estimations/DataEntryGrid/tools/apicalls/validators";
import { fetchData } from "../../../../../SidebarPages/Fleet/utils";
import { fetchAllData } from "../../../../../SidebarPages/Fleet/utils/fetchAllData";

// export const getWorkDefinitions = async () => {
//   const [proposedTypeOfWork] = await Promise.all([
//     // get_pricing(),
//     // get_units(),
//     get_proposedTypeOfWork(),
//   ]);

//   return { proposedTypeOfWork };
// };

export const get_pricing = () => API.get("pricing", "/pricing");
export const get_units = () => API.get("unitMeasurement", "/unitMeasurement");
export const get_proposedTypeOfWork = () =>
  API.get("proposedTypeOfWork", "/proposedTypeOfWork");

export function get_documentation() {
  return fetchAllData("documentation", "documentation", "docId").then((r) => r);
}

export function get_tasks() {}

export function createDocumentation(documentation) {
  return API.post("documentation", "/documentation", { body: documentation });
}

export const stopIncorrectCompanyName = (companyName) => {
  if (!CheckIfValidId(companyName)) {
    throw "companyName is not correct";
  }
};
