import React from "react";
import { Tooltip, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";

import { useRedux } from "../../../../../hooks";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";

const RemoveItem = ({ rowIndex, value: category, data }) => {
  const [isWritable] = useRedux("invoiceIsWritable");

  const { invoiceData } = useInitialInvoiceContext();
  const { setProducts } = useCreateInvoiceContext();

  const onRemoveItem = () => {
    let index = rowIndex;
    const resetId = (prev) =>
      prev.map((el, index) => ({ ...el, id: index + 1 }));

    if (category === "applications" || data?.includedRequisitionId) {
      message.info(`All items from ${data?.group} were deleted.`);
      setProducts((prev) => {
        prev = prev.filter(({ categoryId, includedRequisitionId }) => {
          return !(
            categoryId === data.categoryId ||
            categoryId === data?.includedRequisitionId ||
            (includedRequisitionId
              ? includedRequisitionId === data.includedRequisitionId ||
                includedRequisitionId === data.categoryId
              : false)
          );
        });
        return structuredClone(resetId(prev));
      });
      return;
    }

    setProducts((prev) => {
      prev.splice(index, 1);
      return [...resetId(prev)];
    });
  };

  return (
    <Tooltip
      title={
        (category === "applications" || data?.includedRequisitionId) &&
        `All items from ${data?.group} will be deleted.`
      }
    >
      <button
        data-testid="remove-item-button"
        style={{
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          border: "0",
          background: "inherit",
        }}
        disabled={!isWritable && invoiceData}
        onClick={onRemoveItem}
      >
        <DeleteFilled />
      </button>
    </Tooltip>
  );
};

export default RemoveItem;
