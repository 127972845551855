import { Modal, Select, message } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { useState } from "react";
import {
  changeChartType,
  chartBodyType,
  typeToRepetition,
} from "./changeChartFunctions";
import {
  initialFilteredData,
  widgetOptions,
} from "../../../../Dashboard/components/Entity/EntityData";
import { API } from "aws-amplify";
import { coordinatesReplacer } from "../../../../Dashboard/addWidgetsModalData";
import { XIcon } from "../../../../Communication/assets";
import { FILTER_VALUES } from "../../FilterComponents/filtersData";
import { toCamelCase } from "src/components/pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import "./ChangeChartType.scss";
import { useSelector } from "react-redux";
import { LabeledInput } from "../../../../XComponents";

const ChangeChartType = ({
  rowData,
  id,
  setRowData,
  widgetRepetition,
  tables,
  programFields,
  setWidgetRepetition,
  type,
  preferences,
  setPreferences,
  layout,
  visible,
  setVisible,
  statesData,
  cardFilterPreferences,
  setLayout,
  allFilterStates,
  updatedDynamicStates,
  filterStates,
  userConfiguration,
}) => {
  const card = rowData?.find((a) => a?.id === id);

  const [newChart, setNewChart] = useState();
  const { BodyComponentParams } = card;
  const { field, table } = BodyComponentParams;
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const chartTypes = widgetOptions(programFields)
    [
      initialFilteredData({
        dispatchData: [],
        fleetData: [],
        inspectionsData: [],
        maintenanceData: [],
        violationsData: [],
        estimationsData: [],
        attorney: [],
        expeditor: [],
        documentationData: [],
        applicationsData: [],
        rentalsData: [],
        claimsData: [],
        safetyData: [],
        incidentsData: [],
        defectsData: [],
        workOrdersData: [],
      })[table][field]?.chartOptions
    ]?.filter(
      (el) =>
        !widgetRepetition?.[table]?.[field]?.[el] &&
        el !== "Circle Progress Bar" &&
        el !== type
    )
    ?.map((el) => {
      return {
        label: el,
        value: el?.split(" ")?.join(""),
      };
    });

  const handleChartChange = (chart) => {
    let newChartBody = card;

    if (!card.BodyComponentParams.hasOwnProperty("datasets")) {
      newChartBody.BodyComponentParams.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      newChartBody.BodyComponentParams.labels = [];
    }

    if (!card.BodyComponentParams.hasOwnProperty("content")) {
      newChartBody.BodyComponentParams.content = [];
    }

    if (!card.BodyComponentParams.hasOwnProperty("statusData")) {
      newChartBody.BodyComponentParams.statusData = [];
    }

    newChartBody.type = chartBodyType[chart]?.type;
    newChartBody.BodyComponent = chartBodyType[chart]?.type;
    newChartBody.width = chartBodyType[chart]?.width;
    setNewChart(newChartBody);
  };

  const updatedRowData = () => {
    // const rowDataObject = rowData?.reduce((acc, curr) => {
    //   return { ...acc, [curr?.id]: curr };
    // }, {});

    if (cardFilterPreferences?.length) {
      console.log({ cardFilterPreferences });

      const cardPrefsObject = cardFilterPreferences?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.id]: curr,
        }),
        {}
      );
      return rowData?.map((a) => {
        if (cardPrefsObject?.[a?.id]) {
          if (a?.id !== id) {
            return {
              ...a,
              BodyComponentParams: {
                ...a?.BodyComponentParams,
                filterStates: cardPrefsObject[a?.id]?.filterStates,
              },
            };
          } else {
            return changeChartType(
              {
                ...tables,
                [statesData?.[card?.id]]: updatedDynamicStates[card?.id],
              },
              programFields,
              {
                ...newChart,
                BodyComponentParams: {
                  ...newChart?.BodyComponentParams,
                  filterStates: cardPrefsObject[a?.id]?.filterStates,
                },
              },
              userConfiguration
            );
          }
        } else {
          if (a?.id !== id) {
            return a;
          } else {
            return changeChartType(
              {
                ...tables,
                [statesData?.[card?.id]]: updatedDynamicStates[card?.id],
              },
              programFields,
              {
                ...newChart,
              },
              userConfiguration
            );
          }
        }
      });
    } else if (allFilterStates?.[card?.id]?.filterByTime) {
      console.log({ allFilterStates });
      let filterValue = toCamelCase(allFilterStates?.[card?.id]?.filterByTime);
      const { [filterValue]: filterPeriod } = FILTER_VALUES();
      const [start, end] = filterPeriod;
      let tmpTables = {
        ...tables,
        [statesData?.[card?.id]]: updatedDynamicStates[card?.id]?.filter(
          (el) => el?.createdAt >= start && el?.createdAt <= end
        ),
      };

      // tables?.[statesData?.[card?.id]] =
      return rowData?.map((a) =>
        a?.id !== id
          ? a
          : changeChartType(
              tmpTables,
              programFields,
              newChart,
              userConfiguration
            )
      );
    } else {
      console.log({ card });

      if (filterStates?.filterByTime === "This Month") {
        console.log("THIS MONTH");
        let dateKey = "createdAt";

        if (BodyComponentParams?.table === "Project Cost") {
          dateKey = "punchTimeStamp";
        }

        const [startDate, endDate] = FILTER_VALUES().thisMonth;

        return rowData?.map((a) =>
          a?.id !== id
            ? a
            : changeChartType(
                {
                  ...tables,
                  [statesData?.[card?.id]]: updatedDynamicStates[
                    card?.id
                  ]?.filter(
                    (el) =>
                      el?.[dateKey] >= startDate && el?.[dateKey] <= endDate
                  ),
                },
                programFields,
                newChart,
                userConfiguration
              )
        );
      }
      console.log("NO FILTERS");
      return rowData?.map((a) =>
        a?.id !== id
          ? a
          : changeChartType(
              {
                ...tables,
                [statesData?.[card?.id]]: updatedDynamicStates[card?.id],
              },
              programFields,
              newChart,
              userConfiguration
            )
      );
    }
  };

  const handleChartSave = () => {
    const spread = {
      ...widgetRepetition,
      [table]: {
        ...widgetRepetition?.[table],
        [field]: {
          ...widgetRepetition?.[table]?.[field],
          [typeToRepetition[type]]: false,
          [typeToRepetition[newChart?.type]]: true,
        },
      },
    };
    setWidgetRepetition(spread);

    let tmpRowData = updatedRowData();

    setTimeout(() => {
      console.log({ tmpRowData });
      setRowData(tmpRowData);
      setLayout(
        coordinatesReplacer({
          layout,
          widgetOptions: newChart?.type,
          IdHandler: id,
        })
      );
      API.patch("preferences", "/preferences", {
        body: {
          widgetRepetition: {
            ...preferences?.widgetRepetition,
            dashboard: spread,
          },
          widgetConfiguration: {
            ...preferences?.widgetConfiguration,
            dashboard: tmpRowData,
          },
          widgetLayout: {
            ...preferences?.widgetLayout,
            dashboard: coordinatesReplacer({
              layout,
              widgetOptions: newChart?.type,
              IdHandler: id,
            }),
          },
        },
      })
        .then(() => {
          message.success("Chart type changed successfully");
        })
        .catch((err) => {
          message.error("Something went wrong while changing chart type");
          console.error("Error changing chart type", err);
        });
    }, 100);

    // setPreferences((prev) => {
    //   prev["widgetConfiguration"].dashboard = updatedRowData;
    //   prev["widgetLayout"].dashboard = coordinatesReplacer({
    //     layout,
    //     widgetOptions: newChart?.type,
    //     IdHandler: id,
    //   });
    //   return { ...prev };
    // });
  };

  return (
    <Modal
      {...{
        open: visible,
        onCancel: () => setVisible(false),
        title: "Change Chart Type",
        closable: true,
        footer: null,
        centered: true,
        closeIcon: <XIcon />,
        className: `change-chart-type ${
          isDarkMode ? "change-chart-type-dark" : ""
        }`,
        footer: [
          <MondayButton
            {...{
              onClick() {
                setVisible(false);
              },
              className: "mondayButtonRed",
              Icon: <XIcon />,
            }}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              onClick() {
                handleChartSave();
                setVisible(false);
              },
              className: !newChart && "mondayButtonGrey",
              disabled: !newChart,
              Icon: <TickIcon />,
            }}
          >
            Save
          </MondayButton>,
        ],
      }}
    >
      <LabeledInput
        {...{
          label: "Select Chart Type",
          noFormItem: true,
        }}
      >
        <Select
          {...{
            options: chartTypes,
            onSelect: (e) => {
              handleChartChange(e);
            },
            popupClassName: isDarkMode ? "change-chart-dropdown-dark" : "",
          }}
        />
      </LabeledInput>
    </Modal>
  );
};

export default ChangeChartType;
