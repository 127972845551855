export const identicalCrewMembers = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (obj1.crewId !== obj2.crewId) {
      return false;
    }
  }

  return true;
};
