import { useEffect, useState } from "react";
import "./CarouselView.scss";
import { companyDetails } from "../../../../helpers/constants";
const { companyName } = companyDetails;

export default function CarouselView() {
  const [carouselStep, setCarouselStep] = useState(0);
  const contentWidth = window.innerWidth / 2;

  const listOfComponents = [
    {
      key: 0,
      component: (
        <span
          className="textSpan key0"
          style={{
            padding: `10px 20% 20px ${(6.25 / 100) * contentWidth}px`,
          }}
        >
          IT TAKES GUTS AND SKILLS TO BUILD 471 FEET IN THE AIR
        </span>
      ),
      width: `${window.innerWidth / 2}px`,
    },
    {
      key: 1,
      component: (
        <span
          className="textSpan key1"
          style={{
            padding: `10px 20% 20px ${(6.25 / 100) * contentWidth}px`,
          }}
        >
          WE HELP THEM DO IT SAFELY
        </span>
      ),
      width: `${(78.125 / 100) * contentWidth}px`,
    },
    {
      key: 2,
      component: (
        <span
          className="textSpan key2"
          style={{
            padding: `10px 20% 20px ${(6.25 / 100) * contentWidth}px`,
          }}
        >
          HELPING BUILDERS BUILD
        </span>
      ),
      width: `${(67.71 / 100) * contentWidth}px`,
    },
  ];

  useEffect(() => {
    let step = carouselStep;
    step = (step + 1) % 3;
    const skewDiv = document.getElementsByClassName(`skewDiv`)[0];
    const behindDiv = document.getElementsByClassName(`behindDiv`)[0];
    skewDiv.style.setProperty(
      "--componentWidth",
      `${listOfComponents[carouselStep].width}`
    );
    setTimeout(() => {
      skewDiv.classList.add("fadeOut");
      if (step !== 0) {
        behindDiv.style.setProperty(
          "--afterEffecttWidth",
          `${listOfComponents[step].width}`
        );
      }
    }, 2500);
    setTimeout(() => {
      skewDiv.classList.remove("fadeOut");
      skewDiv.classList.add("fadeIn");
      setCarouselStep(step);
      if (step === 0) {
        behindDiv.style.setProperty(
          "--afterEffecttWidth",
          `${listOfComponents[step].width}`
        );
      }
    }, 3000);
  }, [carouselStep]);

  return (
    <div className={`left_carousel`}>
      <div className="skewDiv">
        <h3
          style={{
            width: listOfComponents[carouselStep].width,
            padding: `0px 15% 0px ${(6.25 / 100) * contentWidth}px`,
            marginBottom: 0,
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          Welcome to {companyName}
        </h3>
        {listOfComponents[carouselStep].component}
        <div
          className="stepperContainer"
          style={{
            padding: `10px 20% 0px ${(6.25 / 100) * contentWidth}px`,
            cursor: "pointer",
          }}
        >
          <span
            className={`stepperSquare ${
              carouselStep === 0 ? "selectedStep" : ""
            }`}
          ></span>
          <span
            className={`stepperSquare ${
              carouselStep === 1 ? "selectedStep" : ""
            }`}
          ></span>
          <span
            className={`stepperSquare ${
              carouselStep === 2 ? "selectedStep" : ""
            }`}
          ></span>
        </div>
      </div>
      <div className="behindDiv"></div>
    </div>
  );
}
