import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import uniq from "lodash/uniq";

import { getDispatchLocationOptions } from "../../../../../Header/forms/RequestFormModal/requestFormModalData";
import { MondayButtonIcon } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { NewTabIcon } from "../../../../DynamicView/src";

const ADDITIONAL_LABEL_TEXT =
  " besides the named individual above? If so, please indicate everyone's full name and a brief description. If no one else was affected, please indicate N/A.";
const ATTESTATION_TEXT = " (if yes, please attach the attestation)";

function addConditionalFields({
  fields,
  field,
  fieldCategoryIndex,
  selectedService,
  typeOfEquipment,
  immediateActions,
}) {
  if (
    field.formItemName === "serviceBeingDone" &&
    selectedService === "Other"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherService",
      label: "Other Service",
      required: true,
      placeholder: "Other Service",
      category: field.category,
    });
  } else if (
    field.formItemName === "typeOfEquipmentInvolved" &&
    typeOfEquipment === "Other"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherEquipment",
      label: "Other Equipment",
      required: true,
      placeholder: "Other Equipment",
      category: field.category,
    });
  } else if (
    field.formItemName === "immediateActionsTakenFollowingTheIncident" &&
    (immediateActions === "Escorted by..." || immediateActions === "Other...")
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherAction",
      label:
        immediateActions === "Escorted by..." ? "Escorted By" : "Other Action",
      required: true,
      placeholder:
        immediateActions === "Escorted by..." ? "Escorted By" : "Other Action",
      category: field.category,
    });
  }
}

const namesToDisable = [
  "dateOfInjury",
  "timeOfInjury",
  "addressOfInjury",
  "fullNameOfInjuredIndividual",
];

function fixFormFields({
  dynamicFields = [],
  descriptionValues,
  setDescriptionValues,
  selectedService,
  typeOfEquipment,
  immediateActions,
  nameDisabled,
  userConfiguration,
  foremen,
  disabledFieldsForIncident,
  setRequiredTextArea,
  projects,
  schedulesForDate,
  changeDateOfIncident,
  onChangeAddress,
  setChanges,
  disableAllFields,
  addressSelectionInfo,
  navigate,
  defaultId,
  form,
  propAddress,
}) {
  let fields = [];
  for (let i = 0; i < dynamicFields?.length; i++) {
    let field = structuredClone(dynamicFields[i]);

    delete field["placeholder"];

    if (field.type === "textEditor") {
      field = {
        ...field,
        ...(field.formItemName === "wasAnyoneElseAffectedByThisInjury"
          ? { label: field.label + ADDITIONAL_LABEL_TEXT }
          : {}),
        value: descriptionValues[field.formItemName],
        style: { width: "100%" },
        onChange(val) {
          setDescriptionValues((prev) => ({
            ...prev,
            [field.formItemName]: val,
          }));
          setChanges(true);
        },
      };
      if (field.required === true) {
        setRequiredTextArea((prev) => uniq(prev.concat(field.formItemName)));
      }
    } else if (field.type === "select") {
      field = { ...field, showSearch: true, allowClear: true };
    } else if (field.type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    }

    if (field.formItemName === "didInjuredIndividualReturnToWorkOnTheSameDay") {
      field = {
        ...field,
        label: field.label + ATTESTATION_TEXT,
        style: { maxWidth: "30%" },
      };
    } else if (field.formItemName === "fullNameOfInjuredIndividual") {
      field = {
        ...field,
        disabled: nameDisabled || disableAllFields,
      };
    } else if (field.formItemName === "reportPreparedBy") {
      field = {
        ...field,
        customOptions:
          userConfiguration.allUsers.Items.map((e) => ({
            value: e.identityId,
            label: e.nameOfUser,
          })) || [],
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    } else if (field.formItemName === "fullNameOfForeman") {
      field = {
        ...field,
        customOptions: foremen || [],
      };
    } else if (
      field.formItemName === "dateOfInjury" ||
      field.formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(field.formItemName === "dateOfInjury"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
        // disabledDate(e) {
        //   return e > dayjsNY().add(1, "day").startOf("D").valueOf();
        // },
      };
    } else if (field.formItemName === "timeOfInjury") {
      field = {
        ...field,
        dateField: "dateOfInjury",
        validTime: "past",
        form,
      };
    } else if (field.formItemName === "addressOfInjury") {
      field = {
        ...field,
        onSelect: onChangeAddress,
        initialValue: propAddress,
        groupedOptions:
          getDispatchLocationOptions(schedulesForDate, projects) || [],
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );
    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);

      addConditionalFields({
        fields,
        field,
        fieldCategoryIndex,
        selectedService,
        typeOfEquipment,
        immediateActions,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        field,
        fieldCategoryIndex: fields.length - 1,
        selectedService,
        typeOfEquipment,
        immediateActions,
      });
    }

    if (namesToDisable) {
      if (namesToDisable.includes(field.formItemName)) {
        if (!field.disabled) {
          field.disabled = disabledFieldsForIncident;
        }
      }
    }
    if (disableAllFields) {
      field.disabled = disableAllFields;
    }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );
  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  // if (
  //   defaultId &&
  //   (!!addressSelectionInfo?.projectId || !!addressSelectionInfo?.scheduleId)
  // ) {
  //   let generalInfoField = fields?.find(
  //     ({ title }) => title === "General Information"
  //   );

  //   let addressFieldIndex = (generalInfoField?.fields || [])?.findIndex(
  //     ({ formItemName }) => formItemName === "addressOfInjury"
  //   );
  //   if (addressFieldIndex > -1) {
  //     let tmp = [...generalInfoField?.fields];
  //     tmp.splice(addressFieldIndex + 1, 0, {
  //       customComponent: MondayButtonIcon,
  //       componentProps: {
  //         Icon: <NewTabIcon />,
  //         onClick: () => {
  //           !!addressSelectionInfo?.projectId
  //             ? navigate(`/projects/${addressSelectionInfo?.projectId}`)
  //             : !!addressSelectionInfo?.scheduleId
  //             ? navigate(`/scheduling/${addressSelectionInfo?.scheduleId}`)
  //             : null;
  //         },
  //         name: !!addressSelectionInfo?.projectId
  //           ? "Go to Project"
  //           : !!addressSelectionInfo?.scheduleId
  //           ? "Go to Schedule"
  //           : null,
  //       },
  //     });
  //     generalInfoField.fields = [...tmp];
  //   }
  // }

  return fields;
}

export default fixFormFields;
