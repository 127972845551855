import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const estimations = ({ tables, programFields }) => {
  return {
    engProgress: () => ({
      content: tables?.Estimations?.map((a) => ({
        percent: parseInt(a.engProgress),
        showInfo: true,
        status: a.engAssignedTo,
        done: parseInt(a.engProgress),
      })),
    }), //engProgress
    archProgress: () => ({
      content: tables?.Estimations?.map((a) => ({
        percent: parseInt(a.archProgress),
        showInfo: true,
        status: a.archAssignedTo,
        done: parseInt(a.archProgress),
      })),
    }), //archProgress
    estSTATUS: () => ({
      content: programFields
        ?.find((b) => b?.fieldName === "Estimation Status")
        ?.fieldOptions?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Estimations?.filter(
              (c) =>
                c?.estSTATUS?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length /
              tables?.Estimations?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: a.statusName,
          done: tables?.Estimations?.filter(
            (c) => c?.estSTATUS?.toLowerCase() === a?.statusName?.toLowerCase()
          )?.length,
        })),
    }), //estSTATUS
    archAssignedTo: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Estimations || [], "archAssignedTo")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / (tables?.Estimations || [])?.length) * 100).toFixed(
          1
        ),
        showInfo: true,
        status: key,
        done: value,
      })),
    }), //archAssignedTo
    engAssignedTo: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Estimations || [], "engAssignedTo")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / (tables?.Estimations || [])?.length) * 100).toFixed(
          1
        ),
        showInfo: true,
        status: key,
        done: value,
      })),
    }), //engAssignedTo
    statusByArch: () => ({
      content: programFields
        ?.find((b) => b.fieldName === "Status by Architecture")
        ?.fieldOptions?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Estimations?.filter(
              (c) =>
                c?.statusByArch?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length /
              tables?.Estimations?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: a.statusName,
          done: tables?.Estimations?.filter(
            (c) =>
              c?.statusByArch?.toLowerCase() === a?.statusName?.toLowerCase()
          )?.length,
        })),
    }), //statusByArch
    statusByEng: () => ({
      content: programFields
        ?.find((b) => b.fieldName === "Status by Engineering")
        ?.fieldOptions?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Estimations?.filter(
              (c) =>
                c?.statusByEng?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length /
              tables?.Estimations?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: a.statusName,
          done: tables?.Estimations?.filter(
            (c) =>
              c?.statusByEng?.toLowerCase() === a?.statusName?.toLowerCase()
          )?.length,
        })),
    }), //statusByEng

    projectExecutive: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Estimations, "projectExecutive")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Estimations?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    projectManager: () => ({
      content: Object.entries(
        repeatedElementSum(
          tables?.Estimations?.map((a) => a.projectManager)?.flat(),
          "nameOfUser"
        )
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Estimations?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
  }; //Estimations
};
