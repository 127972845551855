import { useResponsive } from "../../../../../../../../../hooks";
import { useRedux } from "../../../../../../../hooks/useRedux";
import CustomFooter from "../../../../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import { formatCurrency } from "../../../../../../../utils/formatCurrency";
import "./TableFooter.scss";

const TableFooter = () => {
  const [fakePayment] = useRedux("fakePayment");
  // const totalAmount = rowData.reduce((acc, obj) => acc + obj.invoiceAmount, 0);
  // const totalDueAmount = rowData.reduce(
  //   (acc, obj) => acc + (obj.invoiceAmount - obj.invoicePaidAmount),
  //   0
  // );
  // const totalPayments = rowData.reduce(
  //   (acc, obj) => acc + obj.invoicePaidAmount,
  //   0
  // );

  const { mobile, tablet } = useResponsive();

  const isNotDesktop = mobile || tablet;

  return (
    <>
      {/* <br /> */}
      <CustomFooter
        dataSrc={fakePayment?.invoices?.map((el) => {
          return {
            invoiceAmount: el.invoiceAmount || 0,
            invoiceAmountDue: el.invoiceAmountDue || 0,
            invoicePaidAmount: el.invoicePaidAmount || 0,
            invoiceCredit: el.invoiceCredit || 0,
          };
        })}
        mathAction={"sum"}
        fontSize={isNotDesktop ? "14px" : "16px"}
        style={{ padding: "5px" }}
        tooltipCategory="Payments"
        labelsKeys={[
          {
            key: "invoiceAmount",
            name: "Original Amount",
            type: "currency",
            mathAction: "sum",
            tooltipKey: "originalAmount",
          },
          {
            key: "invoiceAmountDue",
            name: "Amount Due",
            type: "currency",
            mathAction: "sum",
            tooltipKey: "amountDue",
          },
          {
            key: "invoicePaidAmount",
            name: "This Payment",
            type: "currency",
            mathAction: "sum",
            tooltipKey: "thisPayment",
          },
          {
            key: "invoiceCredit",
            name: "Credit Applied",
            type: "currency",
            mathAction: "sum",
            tooltipKey: "creditApplied",
          },
        ]}
      />
    </>
    // <div className="table-footer-container">
    //   <div className="table-footer-title">Totals</div>
    //   <div className="table-footer-amount">
    //     {formatCurrency(totalAmount || 0) || "$0.00"}
    //   </div>
    //   <div className="table-footer-due">
    //     {formatCurrency(totalDueAmount || 0) || "$0.00"}
    //   </div>
    //   <div className="table-footer-payment">
    //     {formatCurrency(totalPayments || 0) || "$0.00"}
    //   </div>
    // </div>
  );
};

export default TableFooter;
