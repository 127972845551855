import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type Props = {};

const punchStatusColor = {
  In: "#00a464",
  Out: "#787677",
  "No Punch": "#f04f4e",
};

export function punchTableColumns(props: Props) {
  return [
    {
      headerName: "Employee",
      field: "employeeName",
      cellClass: "cell-left-aligned",
      minWidth: 250,
    },
    {
      headerName: "Status",
      field: "liveStatus",
      minWidth: 100,
      cellClass: "cell-center-aligned",
      cellStyle: ({ value }) => ({
        backgroundColor: punchStatusColor?.[value],
        color: "#fff",
      }),
    },
    {
      headerName: "Punch Time",
      field: "punchTime",
      minWidth: 140,
      cellRenderer: ({ value }) => {
        return value ? parseInTz(value).format("hh:mm A") : "";
      },
    },
    {
      headerName: "Punch Location",
      field: "punchLocation",
      cellClass: "cell-left-aligned",
      minWidth: 250,
    },
    {
      headerName: "Punch Type",
      field: "entryType",
      cellClass: "cell-left-aligned",
      minWidth: 140,
    },
  ];
}
