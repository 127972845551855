import { message } from "antd";
import { firstLetterToUpperCase } from "./firstLetterToUpperCase";
import { categoriesName } from "../../../utils/constants";

export const onRowSelectError = ({
  node,
  data,
  selectedCategory,
  scheduleOfValues,
  categoryData,
}) => {
  if (data?.invoicedAmount >= data?.totalAmount) {
    message.error(
      `This ${firstLetterToUpperCase(categoriesName[selectedCategory])} ${
        data?.totalAmount === 0
          ? "the Client has nothing to pay for."
          : "is already paid"
      }`
    );
    node.setSelected(false);
    return;
  }
  if (data?.requisitionsIncluded) {
    message.error(
      <div>
        This estimation is included in:{" "}
        {data?.requisitionsIncluded?.map(
          ({ applicationNo, applicationId, scheduleId }, i) => (
            <a href={`/applications/${applicationId}`} target={"_blank"}>
              Requisition {applicationNo}
              {i !== data?.requisitionsIncluded?.length - 1 ? ", " : " "}
              SOV no.{" "}
              {scheduleOfValues?.find((el) => scheduleId === el.scheduleId)
                ?.SOVNo || "(Not Found)"}{" "}
            </a>
          )
        )}
      </div>
    );
    node.setSelected(false);
    return;
  }
  if (data?.includedTo) {
    message.error(
      <div>
        Rental is included in:
        <a href={`/applications/${data?.applicationId}`} target="_blank">
          Requisition {data?.applicationInfo?.applicationNo || "(Not Found)"}
        </a>
        | SOV no.
        {scheduleOfValues?.find(
          (el) => data?.applicationInfo?.scheduleId === el.scheduleId
        )?.SOVNo || "(Not Found)"}{" "}
        !
      </div>
    );
    node.setSelected(false);
  }
  const charges = data?.charges?.filter(
    ({ chargeType }) => chargeType !== "Credit Memo"
  );
  if (charges?.length > 0) {
    message.error(
      <div>
        {data?.type} {data?.number} is included in
        {charges?.map(({ chargeNumber, chargeId }, i) => (
          <a href={`/charges/${chargeId}`} target="_blank">
            {" "}
            Charge {chargeNumber} {i !== charges?.length - 1 ? "," : ""}
          </a>
        ))}
      </div>
    );
    return node.setSelected(false);
  }
  if (data?.charged) {
    const charge = categoryData["charges"]?.find(
      ({ chargeId }) => chargeId === data?.charged,
      []
    );
    message.error(
      <div>
        Requisition {data?.number} is{" "}
        <a href={`/charges/${data?.charged}`} target="_blank">
          in Charge {charge?.chargeNumber}
        </a>{" "}
        included
      </div>
    );
    node.setSelected(false);
    return;
  }
};
