import { Checkbox, message } from "antd";
import React from "react";
import { useRedux } from "../../../../../hooks";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";

const TaxCheckBox = (props) => {
  const [isWritable] = useRedux("invoiceIsWritable");
  const { invoiceData } = useInitialInvoiceContext();
  const { setProducts } = useCreateInvoiceContext();

  return (
    <div style={{ width: "100%" }}>
      <Checkbox
        disabled={!isWritable && invoiceData}
        checked={props.value}
        className={`checkbox ${
          props?.data?.category === "applications" ||
          props?.data?.includedRequisitionId
            ? "dissabled-checkbox"
            : ""
        }`}
        onChange={(e) => {
          if (props?.data?.taxAmount > 0) {
            if (
              props?.data?.category === "applications" ||
              props?.data?.includedRequisitionId
            ) {
              e.target.checked = !e.target.checked;
              return message.error(
                "Cannot change tax for items from requisitions."
              );
            }
            let index = props.rowIndex;
            setProducts((prev) => {
              prev[index]["tax"] = prev[index]["tax"] ? false : true;
              prev[index].total =
                parseFloat(prev[index]?.amount) +
                (prev[index]?.tax ? prev[index]?.taxAmount || 0 : 0);
              return JSON.parse(JSON.stringify(prev));
            });
          } else message.warning("This item is tax-free.");
        }}
      />
    </div>
  );
};

export default TaxCheckBox;
