export const updateNotesHandler = ({
  dispatchContext,
  serviceLabel,
  elevation,
  newValue,
  fakeSOV,
  eventId,
}) => {
  const newFakeScheduleOfValues = {
    ...fakeSOV,
    services: fakeSOV?.services?.map((serviceData) =>
      serviceData?.label === serviceLabel
        ? {
            ...serviceData,
            serviceOptions: [
              serviceData?.serviceOptions[0]?.map((elevationData) =>
                elevationData?.elevationId === elevation?.elevationId
                  ? {
                      ...elevationData,
                      amounts: elevationData?.amounts?.map((eventsData) =>
                        eventsData?.id === eventId ? { ...eventsData, note: newValue } : eventsData
                      ),
                    }
                  : elevationData
              ),
            ],
          }
        : serviceData
    ),
  }

  dispatchContext({ type: "SET_FAKE_SOV", payload: newFakeScheduleOfValues })
}
