type FleetServiceParam = {
  saveHandler: () => void;
  onCostChange: (val: number, type: string) => void;
};

export default function ({ saveHandler, onCostChange }: FleetServiceParam) {
  return {
    details: [
      {
        type: "input",
        label: "Title",
        required: true,
        dataTestid: "title",
        formItemName: "serviceTitle",
        placeholder: "Service title...",
        onPressEnter: saveHandler,
      },
      {
        type: "textarea",
        label: "Description",
        dataTestid: "description",
        placeholder: "Description...",
        formItemName: "serviceDescription",
      },
    ],
    cost: [
      {
        min: 0,
        prefix: "$",
        type: "number",
        label: "Labor",
        controls: false,
        typeNumber: true,
        placeholder: "$0.00",
        dataTestid: "laborCost",
        formItemName: "laborCost",
        onChange(e: number) {
          onCostChange(e, "partsCost");
        },
      },
      {
        min: 0,
        prefix: "$",
        label: "Parts",
        type: "number",
        controls: false,
        typeNumber: true,
        placeholder: "$0.00",
        dataTestid: "partsCost",
        formItemName: "partsCost",
        onChange(e: number) {
          onCostChange(e, "laborCost");
        },
      },
      {
        min: 0,
        prefix: "$",
        type: "number",
        disabled: true,
        label: "Total",
        controls: false,
        typeNumber: true,
        placeholder: "$0.00",
        dataTestid: "totalCost",
        formItemName: "totalCost",
      },
    ],
  };
}
