import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const approvals = (
  programFields,
  updatedDynamicStates,
  IdHandler,
  reducedUsers
) => ({
  userRequesting: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "userRequesting"
    );

    return {
      title: "Approvals User Requesting",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Approvals",
        field: "userRequesting",
        labels: Object.keys(data)?.map(
          (el) => reducedUsers?.[el]?.nameOfUser || el
        ),

        dataSets: [
          {
            label: "Approvals",
            data: Object.values(data),

            backgroundColor: Object.keys(data).map(() => getRandomColor()),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    };
  },

  assignedTo: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "assignedTo"
    );

    return {
      title: "Approvals Assigned To",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Approvals",
        field: "assignedTo",
        labels: Object.keys(data)?.map(
          (el) => reducedUsers?.[el]?.nameOfUser || el
        ),

        dataSets: [
          {
            label: "Approvals",
            data: Object.values(data),

            backgroundColor: Object.keys(data).map(() => getRandomColor()),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    };
  },

  category: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "category"
    );

    return {
      title: "Approvals Category",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Approvals",
        field: "category",
        labels: Object.keys(data)?.map((el) => el?.capitalize() || el),

        dataSets: [
          {
            label: "Approvals",
            data: Object.values(data),

            backgroundColor: Object.keys(data).map(() => getRandomColor()),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    };
  },

  responseFrom: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "responseFrom"
    );

    return {
      title: "Approvals Response From",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Approvals",
        field: "responseFrom",
        labels: Object.keys(data)?.map((el) => el || "No Response"),

        dataSets: [
          {
            label: "Approvals",
            data: Object.values(data),

            backgroundColor: Object.keys(data).map(() => getRandomColor()),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    };
  },

  requestType: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "requestType"
    );

    const statusColors =
      programFields?.find((el) => el?.fieldName === "Status colors")
        ?.fieldOptions?.Approvals?.requestType || [];

    return {
      title: "Approvals Request Type",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Approvals",
        field: "requestType",
        labels: Object.keys(data),

        dataSets: [
          {
            label: "Approvals",
            data: Object.values(data),

            backgroundColor: Object.keys(data).map(
              (key) =>
                statusColors?.find((el) => el?.statusName === key)?.statusColor
            ),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    };
  },
});
