export const BASE_PAGE_DEPARTMENT = "BasePages";
export const BASE_PAGE_CATEGORY = "BasePage";

export const OTHER_TITLES = [
  { name: "Tasks", currentTitle: "TasksManager" },
  { name: "Dispatches", currentTitle: "FleetDispatching" },
  { name: "Fleets Violations", currentTitle: "FleetViolations" },
  { name: "Activity", currentTitle: "FleetActivity" },
  { name: "Fleet Incident", currentTitle: "FleetIncidents" },
  { name: "Requisition", currentTitle: "Requisitions" },
  { name: "Charge", currentTitle: "Charges" },
  { name: "Invoice", currentTitle: "Invoices" },
  { name: "Receive Payments", currentTitle: "Payments" },
  { name: "Property Damage Incident", currentTitle: "IncidentsPropertyDamage" },
  { name: "Personal Injury Incident", currentTitle: "IncidentsPersonalInjury" },
  { name: "Fleet Incidents", currentTitle: "IncidentsPropertyDamage" },
  { name: "Other Incident", currentTitle: "IncidentsOtherTradeIncident" },
  { name: "Property Damage", currentTitle: "SafetyPropertyDamage" },
  { name: "Personal Injury", currentTitle: "SafetyPersonalInjury" },
  { name: "Vehicle Damage", currentTitle: "SafetyVehicleDamage" },
  { name: "Other Trade Incident", currentTitle: "SafetyOtherTradeIncident" },
  { name: "Fleets Inspections", currentTitle: "FleetInspections" },
  { name: "Fleets Maintenances", currentTitle: "FleetMaintenance" },
  { name: "Fleet Inventory", currentTitle: "FleetsInventory" },
  { name: "To Do", currentTitle: "ToDo" },
];
