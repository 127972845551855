import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OnBoarding from "../OnBoarding";
import { getBoardingSteps, googleSignInStepFinished } from "../utils";
import { useGoogleSignIn } from "src/hooks/useGoogleSignIn";
import { useEditLogs } from "../../../hooks";

const OnBoardingProgress = () => {
  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [visible, setVisible] = useState(false);

  const { userDetails } = useGoogleSignIn();
  const { saveAddedLogs } = useEditLogs();

  useEffect(() => {
    if (!!userDetails) {
      //make google signIn step finished if user is signed in
      if (!userConfiguration?.boardingConfigs?.googleSignInStep?.finished) {
        googleSignInStepFinished({
          dispatch,
          userConfiguration,
          saveAddedLogs,
        });
      }
    }
  }, [userDetails]);

  const completedSteps = Object.values(
    userConfiguration?.boardingConfigs || {}
  ).filter(({ finished }) => !!finished).length;

  const boardingStepsLength = getBoardingSteps({
    currentStep: 0,
    isDarkMode: false,
  }).length;

  return (
    <>
      <Button
        style={{
          color: completedSteps === boardingStepsLength ? "#7ac14d" : "#f6cb51",
          backgroundColor:
            completedSteps === boardingStepsLength
              ? "rgb(122, 193, 77, 0.15)"
              : "rgb(246, 203, 81, 0.15)",
          border:
            completedSteps === boardingStepsLength
              ? "1px solid #7ac14d"
              : "1px solid #f6cb51",
        }}
        onClick={() => {
          setVisible(true);
        }}
      >
        {`Profile${
          completedSteps === boardingStepsLength ? " " : " not "
        }completed ${completedSteps}/${boardingStepsLength}`}
      </Button>

      <OnBoarding {...{ visible, setVisible }} />
    </>
  );
};

export default OnBoardingProgress;
