import { debounce, uniqBy } from "lodash";
import { searchFetchInput } from "../../../../../utils/searchFetchForInput";
import { filterSchedulesOnDate } from "../../../Safety/SafetyModal/utils/filterSchedulesOnDate";
import { userAccessRecords } from "../../../Dashboard/NewDashboardFunctions";
import { message } from "antd";
import dayjs from "dayjs";

const setLoading = (value) => {
  if (!!value) {
    return message.loading({
      content: "Getting Data",
      key: "loading",
      duration: 0,
    });
  }

  return message.destroy("loading");
};

const searchIncidentAddress = debounce(
  async ({
    searchValue = "",
    dispatchSafetyFormData = () => {},
    userConfiguration = {},
    projects = [],
    scheduling = [],
    schedulesForDate = [],
    dateField = "",
    form = {},
  }) => {
    return await searchFetchInput({
      table: ["projects", "scheduling"],
      idKey: ["projectId", "scheduleId"],
      arrayKey: ["projects", "schedules"],
      searchKey: ["projectName", "scheduleAddress"],
      searchValue,
      setLoading,
      keysToInclude: {
        projects: [
          "projectId",
          "projectName",
          "accountId",
          "accountName",
          "createdAt",
          "teamsConfiguration",
        ],
        scheduling: [
          "scheduleId",
          "scheduleAddress",
          "scheduleName",
          "scheduleDays",
          "createdAt",
          "teamsConfiguration",
        ],
      },
      setRecords: (res) => {
        const resToUse = res?.reduce(
          (acc, curr) => ({
            ...acc,
            ...curr?.value,
          }),
          {}
        );

        const date = form.getFieldValue(dateField);

        const newSchedulesForDate = filterSchedulesOnDate(
          resToUse?.schedules || [],
          !!date ? dayjs(date, "MM/DD/YYYY") : dayjs()
        )?.schedulesForDay;

        const payload = {
          projects: userAccessRecords(
            userConfiguration,
            uniqBy(
              [...(projects || []), ...(resToUse?.projects || [])],
              "projectId"
            )
          ),
          scheduling: userAccessRecords(
            userConfiguration,
            uniqBy(
              [...(scheduling || []), ...(resToUse?.schedules || [])],
              "scheduleId"
            )
          ),
          schedulesForDate: userAccessRecords(
            userConfiguration,
            uniqBy([
              ...(schedulesForDate || []),
              ...(newSchedulesForDate || []),
            ])
          ),
        };

        dispatchSafetyFormData({
          type: "SEARCH_FETCH",
          payload,
        });
      },
      customSetRecords: true,
    });
  },
  500
);

export default searchIncidentAddress;
