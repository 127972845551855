import { debounce, uniqBy } from "lodash";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import IndividualLoader from "../../../../../IndividualLoader";
import {
  REQUEST_API_KEYS,
  assignedToObject,
  projectManagerSelect,
  teamSelect,
} from "..";
import sortFields from "../sortFields";

/**
 * Generates an array of fields for the scheduling request form.
 *
 * @param {Array} teams - The list of teams.
 * @param {Array} projects - The list of projects.
 * @param {Array} accounts - The list of accounts.
 * @param {string} requestId - The ID of the request.
 * @param {Array} inputFields - The list of input fields.
 * @param {boolean} isDarkMode - Indicates if the form is in dark mode.
 * @param {Array} projectManagers - The list of project managers.
 * @param {string|null} currentPageKey - The current page key.
 * @param {boolean} globalDisabled - Indicates if all fields are disabled.
 * @param {boolean} recordsLoading - Indicates if records are loading.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} setRecordsLoading - The function to set records loading state.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The array of fields for the scheduling request form.
 */
const schedulingRequestFields = ({
  teams = [],
  projects = [],
  accounts = [],
  requestId = "",
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  currentPageKey = null,
  globalDisabled = false,
  recordsLoading = false,
  userConfiguration = {},
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  setRecordsLoading = () => {},
  onMainFieldSelect = () => {},
  dispatchRequestData = () => {},
}) => {
  const onSearch = debounce(
    async (searchValue) =>
      await searchFetchInput({
        table: "projects",
        idKey: "projectId",
        arrayKey: "projects",
        searchKey: "projectName",
        searchValue,
        userConfiguration,
        keysToInclude: REQUEST_API_KEYS["Scheduling"].projects,
        setLoading: setRecordsLoading,
        setRecords: (res) =>
          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: {
              projects: userAccessRecords(
                userConfiguration,
                uniqBy([...(res || []), ...(projects || [])], "projectId")
              ),
            },
          }),
        customSetRecords: true,
        setCurrentPageKey: (val) => {
          currentPageKey.current = val;
        },
      }),
    500
  );

  const onPopupScroll = async (e) =>
    await handlePopupScroll({
      e,
      table: "projects",
      idKey: "projectId",
      currentPageKey: currentPageKey.current.projects,
      setLoading: setRecordsLoading,
      keysToInclude: REQUEST_API_KEYS["Scheduling"].projects,
      setRecords: (res) =>
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: {
            projects: userAccessRecords(
              userConfiguration,
              uniqBy([...(res || []), ...(projects || [])], "projectId")
            ),
          },
        }),
      userConfiguration,
      setCurrentPageKey: (val) => {
        currentPageKey.current = { projects: val };
      },
      customSetRecords: true,
    });

  const dropdownRender = (menu) => (
    <>
      {" "}
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "jobsiteAddress") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (projects || [])
          ?.filter((el) => !!Object.keys(el?.services || {})?.length)
          ?.map((el) => ({
            value: el?.projectId,
            label: `${el?.projectName} ― Client : ${el?.accountName || "N/A"}`,
            recordName: el?.projectName,
          })),

        disabled: !!allFieldsDisabled || globalDisabled || !!requestId,
        style: { minWidth: 300 },
        showSearch: true,
        allowClear: true,

        onSelect: (val, opt) =>
          onMainFieldSelect({
            val,
            opt: { category: "projects", recordName: opt?.recordName },
          }),

        onClear: () =>
          onMainFieldSelect({ clear: true, opt: { category: "projects" } }),

        onSearch,

        onPopupScroll,

        dropdownRender,
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Scheduling",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "team") {
      Object.assign(field, {
        ...teamSelect({ onTeamSelect, teams, isDarkMode }),
      });
    }

    if (formItemName === "client") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (accounts || [])?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),

        style: { minWidth: 170, width: 200 },
        showSearch: true,
        allowClear: true,
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default schedulingRequestFields;
