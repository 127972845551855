import "./ThemeStep.scss";
import DarkModeToggle from "../../../commonComponents/DarkModeToggle/DarkModeToggle";
import changeScrollbarStyle from "../../../../utils/changeScrollbarStyle";
import { ArrowLine } from "../../assets/icons";
import BoardingNavigationBtn from "../BoardingNavigationBtn/BoardingNavigationBtn";
import { useDispatch } from "react-redux";
import { handleDarkMode } from "../../../../reducers/darkModeAction";
import { useSelector } from "react-redux";

const ThemeStep = ({ setBoardingChanges }) => {
  const dispatch = useDispatch();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const switchDarkMode = () => {
    dispatch(handleDarkMode(!isDarkMode));
    setBoardingChanges((prev) => ({ ...prev, isDarkMode: !isDarkMode }));
    changeScrollbarStyle();
  };

  return (
    <div className="theme-step step-lines">
      <div style={{ position: "relative", marginBottom: "10px" }}>
        <ArrowLine
          style={{ position: "absolute", top: "10px", right: "-85px" }}
        />
        <DarkModeToggle
          onClickFunction={switchDarkMode}
          darkMode={isDarkMode}
        />
      </div>
      <span style={{ textAlign: "center" }}>
        You're in control of your viewing experience,{" "}
        <span style={{ fontWeight: 600 }}>choose light mode or dark mode</span>{" "}
        by clicking the icon above.
        <br /> If you want to make changes later, you’ll have to go to your
        settings menu.
      </span>

      <BoardingNavigationBtn />
    </div>
  );
};

export default ThemeStep;
