import { useState, useEffect } from "react";
import { Form, Button, Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import { ReactComponent as CollapseArrow } from "../../../../../icons/CollapseArrow.svg";
import "./AlternativeAddresses.scss";
import {
  GPlacesAutocompleteInput,
  SimplePlacesInput,
} from "../../../../SidebarPages/Fleet/components/index";
import {
  aptNumberChangeHandler,
  getAddressComponent,
} from "../../../../SidebarPages/Fleet/utils/addressParseHelpers";

const initialObject = {
  address: "",
  // aptNumber: "",
  city: "",
  state: "",
  street: "",
  zipCode: "",
};

const AlternativeAddresses = ({
  form,
  setVisible,
  altAddresses,
  setAltAddresses,
  disabled = false,
}) => {
  const [addressArray, setAddressArray] = useState(
    (altAddresses.length > 0 &&
      altAddresses?.map((add) => ({ ...initialObject, address: add }))) || [
      initialObject,
    ]
  );
  const [activeDetails, setActiveDetails] = useState(null);
  const [splitRequest, setSplitRequest] = useState(false);

  const addressSplitter = async () => {
    let promises = addressArray?.map(async (el) => {
      return await getAddressComponent(el.address, false, "");
    });
    return Promise.all(promises).then((res) => {
      res = res.filter((el) => el?.address !== undefined);
      delete res.aptNumber;
      setAddressArray(res);
      //sets the appropriate field values to the autocomplete inputs
      res.forEach(({ address }, index) => {
        form.setFieldValue(`auto${index}`, address);
      });
    });
  };

  useEffect(() => {
    addressSplitter();
  }, [splitRequest, addressArray?.length]);

  const changeInputHandler = async (val, index) => {
    let tmpValue = form.getFieldValue(`auto${index}`);
    getAddressComponent(tmpValue).then((res) => {
      setAddressArray((prev) => {
        prev[index] = res;
        delete res.aptNumber;
        return prev;
      });
      setSplitRequest((prev) => !prev);
    });
  };

  const removeFieldHandler = (index) => {
    //every autocomplete after the deleted index needs to have it's value shifted by 1
    //we save all the addresses after the deleted address;
    let tmp = [];
    for (let i = index + 1; i < addressArray?.length; i++) {
      tmp.push(form.getFieldValue(`auto${i}`));
    }

    setAddressArray((prev) => {
      prev.splice(index, 1);
      return prev;
    });

    //if the active index comes after the deleted index
    //we reduce the active index by 1
    if (activeDetails !== null) {
      if (activeDetails > index) {
        setActiveDetails((prev) => --prev);
      } else if (index === activeDetails) {
        setActiveDetails(null);
      }
    }

    //after the deletion we set the correct values to the autocomplete fields
    for (let i = index, count = 0; i < addressArray?.length; i++, count++) {
      form.setFieldValue(`auto${i}`, tmp[count]);
    }

    if (index === 0 && addressArray?.length === 0) {
      addFieldHandler();
    }

    setSplitRequest((prev) => !prev);
  };

  const addFieldHandler = () => {
    setAddressArray((prev) => [
      ...prev,
      {
        address: "",
        // aptNumber: "",
        city: "",
        state: "",
        street: "",
        zipCode: "",
      },
    ]);
    //regulates the last form autocomplete field
    form.setFieldValue(`auto${addressArray?.length}`, "");
  };

  const aptChange = (value, index) => {
    let oldAddress = form.getFieldValue(`auto${index}`);
    let newAddress = aptNumberChangeHandler(oldAddress, value);
    form.setFieldValue(`auto${index}`, newAddress);
    setAddressArray((prev) => {
      let tmp = _.cloneDeep(prev);
      tmp[index]["address"] = newAddress;
      tmp[index]["aptNumber"] = value;
      return tmp;
    });
  };

  return (
    <div className="opportunityAltAddContainer">
      {addressArray?.map(({ address, city, state, street, zipCode }, index) => (
        <div className="placesInputContainer" key={index}>
          <div
            className="addressInput"
            onClick={() => {
              activeDetails !== index && setActiveDetails(index);
            }}
          >
            <SimplePlacesInput
              {...{
                form,
                onChange(val) {
                  // changeInputHandler(val, index);
                },
                onSelect(val) {
                  changeInputHandler(val, index);
                },
                onBlur() {
                  form.setFieldValue(
                    `auto${index}`,
                    addressArray[index].address
                  );
                },
                disabled,
                initialValue: address,
                formItemName: `auto${index}`,
                className: "placesInput",
                placeholder: "Click and Search...",
              }}
            />
            <div className="actionButtons">
              <Form.Item>
                <MinusCircleOutlined
                  onClick={() => {
                    if (!disabled) {
                      removeFieldHandler(index);
                    }
                  }}
                  className="removeIcon"
                />
              </Form.Item>
              <Form.Item>
                <CollapseArrow
                  key={`collapse${index}`}
                  className={`addressCollapse ${
                    activeDetails === index ? "addShow" : "addHide"
                  }`}
                  onClick={() => {
                    if (activeDetails === index) {
                      setActiveDetails(null);
                    } else {
                      setActiveDetails(index);
                    }
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div
            className={`addressDetailsContainer ${
              activeDetails === index ? "detailsShow" : "detailsHide"
            }`}
            key={index}
          >
            {/* <div className="apartment">
                <span className="label">Apartment #</span>
                <Form.Item>
                  <Input
                    placeholder={"Apartment #"}
                    key={`apt${index}`}
                    onChange={(e) => {
                      aptChange(e.target.value, index);
                    }}
                    value={aptNumber}
                  />
                </Form.Item>
              </div> */}
            <div className="street">
              <span className="label">Street</span>
              <Input
                placeholder={"Street"}
                key={`street${index}`}
                disabled={true}
                value={street}
              />
            </div>
            <div className="city">
              <span className="label">City</span>
              <Input
                placeholder={"City"}
                key={`city${index}`}
                disabled={true}
                value={city}
              />
            </div>
            <div className="additionalInfo">
              <div className="state">
                <span className="label">State</span>
                <Input
                  placeholder={"State"}
                  key={`state${index}`}
                  disabled={true}
                  value={state}
                />
              </div>
              <div className="postalCode">
                <span className="label">Postal Code</span>
                <Input
                  placeholder={"Postal Code"}
                  key={`zip${index}`}
                  disabled={true}
                  value={zipCode}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="controllerButtons">
        <Button
          onClick={() => {
            !altAddresses.length && form.resetFields(["alternativeAddresses"]);
            setVisible(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="addButton"
          onClick={addFieldHandler}
          {...{ disabled }}
        >
          Add
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setVisible(false);
            setAltAddresses(
              addressArray
                ?.map((el) => {
                  if (el.address !== "" && el.address !== undefined) {
                    return {
                      address: el.address,
                      // aptNumber: el.aptNumber,
                      aptNumber: "",
                    };
                  }
                })
                .filter(Boolean)
            );
          }}
          {...{ disabled }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AlternativeAddresses;
