import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { useRedux } from "../../../../../../../hooks/useRedux";
import { formatCurrency } from "../../../../../../../utils/formatCurrency";
import { appliedRentPliCalculator } from "../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalFooter/RentalBreakdownFooterFunctions";
import { TooltipComp, tooltips } from "../../utils";

const ChargeFooter = ({ rowData, charge, objectData, type = "service" }) => {
  const [chargeItemsModal] = useRedux("chargeItemsModal");
  const { categoryFrom } = chargeItemsModal;
  const totalAmounts = (() => {
    let price = 0,
      taxAmount = 0;
    let items = [];

    if (type === "service") {
      items =
        categoryFrom === "Schedule Of Value"
          ? charge?.label === "Hoist"
            ? rowData?.amounts || []
            : rowData || []
          : (charge?.label === "Hoist" || charge?.isHoist) &&
            categoryFrom === "Estimation"
          ? [rowData]
          : rowData?.items || [];
    } else {
      items = rowData || [];
    }

    for (let item of items) {
      price += item.price;
      taxAmount += item.taxAmount;
    }

    return {
      price,
      taxAmount,
    };
  })();

  const Tooltips = () =>
    tooltips({ ...totalAmounts, type: objectData?.type }).map(TooltipComp);

  const totalAmountOfRent = (() => {
    let tableRowData = type === "service" ? rowData?.items : rowData;

    return parseFloat(
      tableRowData?.reduce?.(
        (acc, curr) => (acc += forceToNumber(curr?.rent) || 0),
        0
      )
    )?.toFixed?.(2);
  })();

  const rentalTotalForRetainage = (() => {
    let toReturn = 0;
    rowData?.items
      ?.flat(1)
      ?.forEach(({ rentalDetails }) =>
        rentalDetails?.details
          ?.flat()
          ?.forEach(({ days }) =>
            days?.forEach(({ dailyAmount = 0 }) => (toReturn += dailyAmount))
          )
      );
    return toReturn * (1 + (rowData?.items?.[0]?.taxRate || 0));
  })();

  const totalScopeAmount = (() => {
    let tableRowData =
      type === "service"
        ? categoryFrom === "Schedule Of Value"
          ? charge?.label === "Hoist"
            ? rowData?.amounts
            : rowData
          : charge?.label === "Hoist"
          ? [rowData]
          : rowData?.items
        : rowData;

    return (
      tableRowData?.reduce(
        (prev, { priceAmount, taxRate, rentalDetails, applied }) => {
          if (categoryFrom === "Rental") {
            const appliedAmount = !applied
              ? 0.0
              : appliedRentPliCalculator({
                  rentalDetails: rentalDetails?.details || [],
                });
            const totalPrice =
              appliedAmount +
              (chargeItemsModal?.whereToFind?.taxRate || 0) * appliedAmount;
            return prev + totalPrice;
          }
          return prev + priceAmount;
        },
        0
      ) || 0
    );
  })();

  return (
    <div className="tableHeader footerTable" data-testid="charge-footer">
      <div className="inforamtionContainer">
        <div
          className="titleHeaderLabel withTooltipTitle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            gap: 5,
            margin: 0,
          }}
        >
          <div>
            {`${
              categoryFrom === "Rental" ? "Total Rent: " : "Total Scope Amount:"
            } ${rowData && formatCurrency(totalScopeAmount)}`}
          </div>
          {categoryFrom === "Rental" && (
            <div>
              {`Retainage amount: ${formatCurrency(
                (rowData?.retainage / 100) * rentalTotalForRetainage || 0
              )}`}
            </div>
          )}
          {categoryFrom === "Estimation" && (
            <div>
              {`Rental Scope Amount: ${formatCurrency(totalAmountOfRent || 0)}`}
            </div>
          )}
          <div>
            {(categoryFrom === "Estimation" &&
              rowData?.isTaxable &&
              rowData?.taxRate > 0) ||
            (categoryFrom === "Rental" &&
              chargeItemsModal?.whereToFind?.taxRate > 0)
              ? "Tax Included"
              : "Tax Excluded"}
          </div>
        </div>
        <div
          style={{
            width: 600,
            display: "flex",
            justifyContent: "space-between",
            margin: "0 10px",
          }}
        >
          <Tooltips />
        </div>
      </div>
    </div>
  );
};

export default ChargeFooter;
