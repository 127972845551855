import {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";

import {
  stringSimilarity,
  FleetsLiveContext,
} from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";

const SelectEditor = forwardRef((props, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { sheetLocations } = useContext(FleetsLiveContext);

  const [selectedValue, setSelectedValue] = useState(props?.value);
  const [customOptions, setCustomOptions] = useState([]);

  const { value, context, stopEditing, data } = props;

  useEffect(() => {
    if (!context.isWritable) {
      stopEditing();
    }
  }, [context.isWritable]);

  useEffect(() => {
    if (!sheetLocations.length) {
      return;
    }

    if (!data?.location) {
      setCustomOptions(
        sheetLocations.map((location) => {
          return { value: location, label: location };
        })
      );
      return;
    }

    setCustomOptions(
      [...sheetLocations]
        .sort(
          (a, b) =>
            stringSimilarity(b, data?.location) -
            stringSimilarity(a, data?.location)
        )
        .map((location) => {
          return { value: location, label: location };
        })
    );
  }, [data, sheetLocations]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return selectedValue;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    [selectedValue]
  );

  return (
    <InputComponent
      {...{
        label: null,
        type: "select",
        className: "locations-select-editor",
        dropdownClassName: isDarkMode ? "darkDropDown" : "",
        allowClear: false,
        noFormItem: true,
        defaultValue: value,
        placeholder: "Location...",
        showSearch: true,
        showArrow: false,
        defaultOpen: true,
        style: { width: "100%" },
        autoFocus: true,
        customOptions,
        onSelect(val) {
          setSelectedValue(val);
          setTimeout(() => {
            stopEditing();
          }, 0);
        },
        onBlur() {
          stopEditing();
        },
      }}
    />
  );
});

export default SelectEditor;
