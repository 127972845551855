import "./CommentContent.scss";
import { useEffect, useState } from "react";
import CommentInput from "../../components/CommentInput/CommentInput";
import Comment from "../../Comment";
import { useSelector } from "react-redux";

const CommentContent = (props) => {
  const {
    isReply = false,
    findKeysIn = { commentId: "commentId" },
    usersWithAccess = [],
    thread,
    thread: { author, replies, repliesVisibility = false },
    postReply = () => {},
    deleteReply = () => {},
    customShowReplyInput, // Used in notes besause replies are seperated in component from comments
  } = props;

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showReplies, setShowReplies] = useState(repliesVisibility);

  useEffect(() => {
    setShowReplies(repliesVisibility);
  }, [repliesVisibility]);

  const handleShowReplyInput = (boolVal) => {
    customShowReplyInput
      ? customShowReplyInput(thread)
      : setShowReplyInput(boolVal);
  };

  return (
    <div
      className={`comment-content-component ${
        isDarkMode ? "CommentContentDark" : ""
      }`}
    >
      <Comment
        {...{
          ...props,
          showReplyInput,
          setShowReplyInput: handleShowReplyInput,
          showReplies,
          setShowReplies,
        }}
      />

      {!!replies?.length &&
        showReplies &&
        replies.map((reply, index) => {
          return (
            <div
              className="comment-content-replies"
              style={isReply ? { paddingLeft: "54px" } : {}}
              key={index}
            >
              <CommentContent
                {...{
                  ...props,
                  isReply: true,
                  thread: {
                    ...reply,
                    commentId: reply[findKeysIn.commentId],
                    commentContent: reply.replyContent,
                  },
                  deleteComment: deleteReply,
                }}
              />

              {/* this removes vertical line for last reply */}
              {index !== replies.length - 1 && (
                <div
                  style={isReply ? { left: "29px" } : {}}
                  className="vertical-line-L"
                />
              )}
            </div>
          );
        })}

      {showReplyInput && (
        <div className="reply__container comment_wrapper">
          <CommentInput
            {...{
              placeholder: `Add reply for ${author}!`,
              usersWithAccess,
              saveComment: ({ inputValue, mentions, isPrivateComment }) => {
                handleShowReplyInput(false);
                setShowReplies(true);
                postReply({
                  comment: thread,
                  inputValue,
                  mentions,
                  isPrivateComment,
                });
              },
              isReply: true,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CommentContent;
