import { Typography } from "antd";
import { useSelector } from "react-redux";

const { Title } = Typography;

export default function Headline({
  level = 5,
  upperCase,
  span,
  whiteText,
  style = {},
  ...typographyProps
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const styles = {
    width: "100%",
    margin: "auto",
    color: whiteText ? "whitesmoke" : !!isDarkMode ? "#f2f2f2" : "#333238",
    textTransform: upperCase ? "uppercase" : undefined,
    ...style,
  };

  if (span) {
    return (
      <div style={{ width: "fit" }}>
        <Title level={level} style={{ ...styles }} {...typographyProps} />
      </div>
    );
  }

  return <Title level={level} style={{ ...styles }} {...typographyProps} />;
}
