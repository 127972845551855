import { LeftArrow } from "../../../../../BasePage/src";
import { useTakeOffContext } from "../../../context";
import { minBy } from "lodash";
import "./PriceTablePPURenderer.scss";
import { Button } from "antd";
import { replaceArrayElement } from "../../../../../../../utils";
import { useRedux } from "../../../../../hooks/useRedux";
import { calculateRent } from "../../../tools/formatters/pricing";

const availableDimensions = ["width", "length", "height"];

const PriceTablePPURenderer = ({ data, value, indexes }) => {
  const {
    selectedPriceSchemesForService,
    // gridData,
    isWritable,
    updateStateAndSave,
  } = useTakeOffContext();

  const [gridData] = useRedux("takeOffTableData");

  const { serviceId, lock } = data;

  const selectedPriceScheme =
    selectedPriceSchemesForService?.[serviceId]?.priceScheme;

  const { prices, dimension } = selectedPriceScheme || {};

  //The length of the selected price scheme dimension destructured from the PLI
  const { [dimension]: dimensionValue } = data || {};

  //Returns the feet reference
  //e.x. if the dimensionValue === 13.2, it will give us 14
  const dimensionFeet = Math.ceil(dimensionValue);

  //Returns the PPU if it is a number or 0
  const currentPPU = !isNaN(value) ? value : 0;

  const suggestedPPU = minBy(
    prices?.filter?.(({ length }) => length >= dimensionFeet),
    "length"
  )?.price;

  const priceDifference = suggestedPPU - currentPPU;

  const isPriceHigherThanSuggested = priceDifference < 0;

  const applySuggestedPrice = () => {
    // returns an array containing the rest of the dimensions
    // e.g. if dimension = "width" the returned array will be [ "height", "length" ]
    const restOfDimensions = availableDimensions.filter((d) => d !== dimension);

    //* Gets the dimensions value from the PLI and filters undefined ones (e.g. PLI has only 2 dimensions)
    //* and multiplies them with each other (if they are more than one)
    const dimensionValueToMultiply = restOfDimensions
      .map((dimension) => data?.[dimension])
      .filter((value) => value !== undefined)
      .reduce((acc, curr) => acc * curr, 1);

    const { serviceIndex, optionIndex, elevationIndex } = indexes;

    const price = suggestedPPU * dimensionValueToMultiply;
    const taxAmount = data.taxRate * price;

    const newPli = {
      ...data,
      ppu: suggestedPPU,
      price,
      taxAmount,
      totalPrice: taxAmount + price,
    };

    newPli.rent =
      calculateRent(gridData[serviceIndex], newPli, optionIndex) || 0;
    console.log("new pli", newPli);

    const newGridData = gridData.map((service, serviceIdx) =>
      serviceIdx !== serviceIndex
        ? service
        : {
            ...service,
            serviceOptions: service.serviceOptions.map((option, optionIdx) =>
              optionIdx !== optionIndex
                ? option
                : option.map((elevation, elevationIdx) =>
                    elevationIdx !== elevationIndex
                      ? elevation
                      : {
                          ...elevation,
                          items: replaceArrayElement(
                            elevation.items,
                            newPli,
                            "id"
                          ),
                        }
                  )
            ),
          }
    );

    updateStateAndSave(newGridData);
  };

  return (
    <div className="priceTablePPURenderer">
      {!!priceDifference && (
        <div className="ppuDifferenceContainer">
          <div
            className={`differenceArrow ${
              isPriceHigherThanSuggested ? "higher" : "lower"
            }`}
          >
            <LeftArrow />
          </div>
          <div className="priceDifference">
            {Math.abs(priceDifference).toFixed(2)}
          </div>
        </div>
      )}
      <div className="ppuContainer">{+currentPPU.toFixed(2)}</div>
      {!!priceDifference && (
        <Button
          type="primary"
          className="suggestionContainer"
          disabled={!!lock || !isWritable}
          onClick={applySuggestedPrice}
        >
          {suggestedPPU}
        </Button>
      )}
    </div>
  );
};

export default PriceTablePPURenderer;
