import { message, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import ChooseDriveFolderView from "../choose-drive-folder/ChooseDriveFolderView";

import "./upload-pdf-to-drive-modal.scss";
import { InputComponent } from "../../../../Fleet/components";
import { getFileType } from "../../../utils/helpers";

function UploadPdfToDriveModal({
  isOpen,
  setIsOpen,
  driveFolders,
  reportAsFile,
  driveFolderParentName,
}) {
  const [folderId, setFolderId] = useState("");
  const [selectedFormat, setSelectedFormat] = useState(null);

  const handleCloseFolderModal = () => {
    setIsOpen(false);
    setFolderId("");
  };

  const handleFolderClick = (folderId) => {
    setFolderId((prevFolderId) => (prevFolderId === folderId ? "" : folderId));
  };

  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const handleOk = async () => {
    if (!selectedFormat) {
      message.warning({
        content: "Please select a format file before proceeding",
      });
      return;
    } else if (folderId === "") {
      message.warning({ content: "Please select a folder before proceeding" });
      return;
    } else {
      message.loading({
        content: "Report is being uploaded to the Drive...",
      });

      if (reportAsFile?.data[selectedFormat]) {
        try {
          const blobFile = new Blob([reportAsFile?.data[selectedFormat]]);
          const uploadResponse = await driveRequest.uploadExcelFile(
            blobFile,
            getFileType(selectedFormat),
            {
              name: reportAsFile?.fileName,
              parents: [folderId],
            }
          );

          if (uploadResponse.status === 200) {
            message.success({ content: "Report uploaded successfully." });
            setIsOpen(false);
            setFolderId("");
          }
        } catch (error) {
          message.error({
            content: `Failed to upload pdf to drive error: ${error}`,
          });
        }
      } else {
        console.error("Unexpected export result format");
      }
    }
  };

  const handleOnSelect = (value) => {
    setSelectedFormat(value);
  };

  return (
    <Modal
      style={{ zIndex: 9999 }}
      open={isOpen}
      onCancel={handleCloseFolderModal}
      onClose={handleCloseFolderModal}
      onOk={handleOk}
      title={driveFolderParentName}
      width="fit-content"
      centered
    >
      <div className="upload-pdf-to-drive-modal-container">
        <InputComponent
          type="select"
          customOptions={[
            {
              id: "pdf",
              label: "PDF",
              value: "pdf",
            },
            {
              id: "excel",
              label: "Excel",
              value: "excel",
            },
          ]}
          placeholder="Choose Format"
          onChange={handleOnSelect}
          initialValue={selectedFormat}
          onClear={() => setSelectedFormat("")}
        />

        <div className="choose-drive-folder-view-wrapper">
          {driveFolders.map(({ name, id }) => (
            <ChooseDriveFolderView
              key={id}
              folderName={name}
              isSelected={folderId === id ? true : false}
              onClick={() => handleFolderClick(id)}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default UploadPdfToDriveModal;
