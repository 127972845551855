import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Doughnut, Line, Bar } from "react-chartjs-2";

import "./LayoutChart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

function charts({ chartData, options }) {
  return {
    Line: <Line data={{ ...chartData }} options={options} />,
    Horizontal: <Bar data={{ ...chartData }} options={options} />,
    Vertical: <Bar data={{ ...chartData }} options={options} />,
    Pie: <Pie data={{ ...chartData }} options={options} />,
    Doughnut: <Doughnut data={{ ...chartData }} options={options} />,
  };
}
function LayoutChart({ type, chartData = {}, indexAxis = undefined }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const options = useMemo(() => {
    let t = undefined;

    if (isDarkMode) {
      t = {
        indexAxis,
        plugins: {
          legend: {
            position: type === "Pie" || type === "Doughnut" ? "right" : "top",
            labels: {
              color: "#ffffffdb",
            },
            title: {
              color: "#ffffffdb",
            },
          },
        },
      };

      if (type !== "Pie" && type !== "Doughnut") {
        t["scales"] = {
          xAxis: {
            ticks: {
              color: "#ffffffdb",
            },
            grid: {
              color: "#b9b9b97a",
            },
          },
          yAxis: {
            beginAtZero: true,
            ticks: {
              color: "#ffffffdb",
            },
            grid: {
              color: "#b9b9b97a",
            },
          },
        };

        if (type === "Line") {
          t["datasets"] = {
            line: {
              borderColor: "#6164817a",
            },
          };
        }
      } else {
        t["clip"] = { top: 0, left: false, right: false, bottom: false };
      }
    } else {
      if (type === "Pie" || type === "Doughnut") {
        t = {
          plugins: {
            legend: {
              position: type === "Pie" || type === "Doughnut" ? "right" : "top",
            },
          },
          clip: { top: 0, left: false, right: false, bottom: false },
        };
      } else {
        t = {
          indexAxis,
          scales: {
            yAxis: {
              beginAtZero: true,
            },
          },
        };
      }
    }

    return t;
  }, [isDarkMode, indexAxis, type]);

  return charts({ chartData, options })[type];
}

LayoutChart.Line = ({ chartData }) => {
  return <LayoutChart type={"Line"} chartData={chartData} />;
};

LayoutChart.Horizontal = ({ chartData }) => {
  return (
    <LayoutChart type={"Horizontal"} chartData={chartData} indexAxis="y" />
  );
};

LayoutChart.Vertical = ({ chartData }) => {
  return <LayoutChart type={"Vertical"} chartData={chartData} indexAxis="x" />;
};

LayoutChart.Pie = ({ chartData }) => {
  return <LayoutChart type={"Pie"} chartData={chartData} />;
};

LayoutChart.Doughnut = ({ chartData }) => {
  return <LayoutChart type={"Doughnut"} chartData={chartData} />;
};

export default LayoutChart;
