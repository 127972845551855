import React, { useState } from "react";
import classes from "./Dropdown.module.scss";

export default function Dropdown({ title, children, isDarkMode }) {
  const [showDetailes, setShowDetailes] = useState(false);

  return (
    <div
      className={`${classes.container} ${
        showDetailes && (isDarkMode ? classes.clickedDark : classes.clicked)
      }`}
    >
      <div
        className={classes.header}
        onClick={() => setShowDetailes((prev) => !prev)}
      >
        <h2>{title}</h2>
        <button className={showDetailes ? classes.expended : ""}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="38px"
            viewBox="0 0 25 25"
            width="38px"
            fill={showDetailes ? (isDarkMode ? "#fff" : "#000") : "#fff"}
          >
            <path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"></path>
          </svg>
        </button>
      </div>
      {showDetailes && children}
    </div>
  );
}
