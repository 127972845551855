import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const applicationHoistFooterDataProvider = ({ elevation }) => {
  // console.log("elevation Numbers", elevation);
  //sum of all this periods fields
  const totalThisPeriod =
    elevation?.amounts
      ?.map((a) => a?.thisPeriod)
      ?.reduce((acc, curr) => (acc = acc + curr), 0) || 0;
  //sum of all from previous fields
  const totalFromPrevious =
    elevation?.amounts
      ?.map((a) => a?.fromPrevious)
      ?.reduce((acc, curr) => (acc = acc + curr), 0) || 0;

  const totalAllHoist = elevation?.amounts
    ?.map((a) => a?.amount)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);

  //sum of all Compleated and finish fields
  const totalCompleatedStored =
    elevation?.amounts
      ?.map((a) => a?.totalCompleated)
      ?.reduce((acc, curr) => (acc = acc + curr), 0) || 0;

  //sum of all Compleated and finish fields
  const collectableAmount =
    elevation?.amounts
      ?.map((a) => a?.collectable_amount)
      ?.reduce((acc, curr) => (acc = acc + curr), 0) || 0;
  const balance = elevation?.amounts?.reduce(
    (a, b) => a + forceToNumber(b?.accRetainageBalance),
    0
  );

  const paymentDue = elevation?.amounts
    ?.map((a) => a?.paymentDue || 0)
    ?.reduce((acc, curr) => (acc = acc + curr), 0);

  return {
    totalCompleatedStored,
    collectableAmount,
    totalFromPrevious,
    totalThisPeriod,
    // thisRetainage,
    retainageBalance: balance,
    paymentDue,
    totalAllHoist,
  };
};
