import { Badge } from "antd";
import React, { useState } from "react";
import { EventsIcon } from "../../../../../../assets/icons";
import { useRedux } from "../../../../../SidebarPages/hooks";
import EventsDrawer from "../previewEvent/EventsDrawer";
import { WithTooltip } from "../../../../../commonComponents";

function Events() {
  const [open, setOpen] = useState(false);
  const [events] = useRedux("calendarEvents");

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="events">
        <Badge className="ignore-onclickoutside" count={events?.length}>
          <EventsIcon
            onClick={() => {
              setOpen(true);
            }}
            className="header-icon"
          />
        </Badge>
      </WithTooltip>

      <EventsDrawer
        calendarPreview={open}
        handleCalendarPreview={(boolVal) => {
          setOpen(boolVal);
        }}
      />
    </div>
  );
}

export default Events;
