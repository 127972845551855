import types from '../types';

let states = [];
const estimations = (state = [], action) => {
    switch (action.type) {
        case types.SET_ESTIMATIONS:
            return action.payload
        case types.SET_ESTIMATION_STATUS_TO_START:
            states = state;
            states.map(estimation => {
                if (estimation.estimationId === action.payload.id) {
                    estimation.status = 'started';
                    estimation.details.started.is = action.payload.started;
                    estimation.details.started.date = action.payload.startedDate;
                }
                return estimation;
            })
            return states;
        case types.SET_ESTIMATION_STATUS_TO_HOLDON:
            states = state;
            states.map(estimation => {
                if (estimation.estimationId === action.payload.id) {
                    estimation.status = 'holdon';
                    estimation.details.holdon.is = action.payload.holdon;
                    estimation.details.holdon.date = action.payload.holdonDate;
                    estimation.details.holdon.reason = action.payload.holdonReason;
                }
                return estimation;
            })
            return states;
        case types.SET_ESTIMATION_STATUS_TO_PAUSED:
            states = state;
            states.map(estimation => {
                if (estimation.estimationId === action.payload.id) {
                    estimation.status = 'paused';
                    estimation.details.paused.is = action.payload.paused;
                    estimation.details.paused.date = action.payload.pausedDate;
                    estimation.details.paused.reason = action.payload.pausedReason;
                }
                return estimation;
            })
            return states;
        case types.SET_ESTIMATION_STATUS_TO_STOPPED:
            states = state;
            states.map(estimation => {
                if (estimation.estimationId === action.payload.id) {
                    estimation.status = 'stopped';
                    estimation.details.stopped.is = action.payload.stopped;
                    estimation.details.stopped.date = action.payload.stoppedDate;
                    estimation.details.stopped.reason = action.payload.stoppedReason;
                }
                if (estimation.estimationId === action.id) estimation.status = 'stopped'
                return estimation;
            })
            return states;
        case types.SET_ESTIMATION_STATUS_TO_FINISHED:
            states = state;
            states.map(estimation => {
                if (estimation.estimationId === action.payload.id) {
                    estimation.status = 'finished';
                    estimation.details.finished.is = action.payload.finished;
                    estimation.details.finished.date = action.payload.finishedDate;
                }
                return estimation;
            })
            return states;
        default: return state;
    }
}

export default estimations;