import types from "../../types"
import { updateArrayElement } from "../../utils"

const initialState = {
  serviceDefinitions: [],
}

const serviceDefinitionsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_SERVICE_DEFINITION:
      return payload

    case types.ADD_SCOPE:
      return [...state, payload]

    case types.EDIT_SCOPE:
      return updateArrayElement(state, payload, "serviceId")

    case types.DELETE_SCOPE:
      return state.filter(({ serviceId }) => serviceId !== payload)

    default:
      return state
  }
}

export default serviceDefinitionsReducer
