import { Checkbox } from "antd"
import { formatCurrency } from "../../../../../../utils"
import "./PricingRowInfo.scss"

const PricingRowInfo = ({ values = [] }) => {
  const [configItem = {}, ...configItemValues] = values

  const { label, type, value } = configItem

  return (
    <div className="pricing-row-info">
      <div className="config-item-container">
        {type === "checkbox" ? (
          <Checkbox checked={!!value} disabled>
            {label}
          </Checkbox>
        ) : (
          <div className="pricing-row-section">
            <div className="pricing-section-label">{label}</div>
            <div className="pricing-section-value">{value}</div>
          </div>
        )}
      </div>

      <div className="input-divider" />

      {configItemValues.map(({ label, value }, idx) => (
        <>
          <div className="pricing-row-section">
            <div className="pricing-section-label">{label}</div>
            <div className="pricing-section-value">
              {!["units", "description"].includes(label)
                ? formatCurrency(value)
                : value}
            </div>
          </div>

          {idx !== configItemValues.length - 1 && (
            <div className="input-divider" />
          )}
        </>
      ))}
    </div>
  )
}

export default PricingRowInfo
