import { MondayButton } from "../../../../../../../../../../../../../../../../commonComponents";
import { formatCurrency } from "../../../../../../../../../../../../../../../utils";
import { Info } from "../../../../../../../../../../../../../../../BasePage/src";
import LockRow from "../../LockRow/LockRow";

import dayjs from "dayjs";
import { EditIcon } from "../../../../../../../../../../../../../../../DynamicView/src";
import ApplyRentalCheckBox from "../../../../ApplyRentalCheckBox/ApplyRentalCheckBox";
import ChargedIndicator from "../../ChargedIndicator/ChargedIndicator";

const formatDate = (value) => dayjs(value)?.format("MM/DD/YYYY");

export const columnDefs = ({
  selectedElevation,
  setSelectedWeek,
  selectedItem,
  rowSpan,
}) => [
  {
    headerName: "Month",
    field: "month",
    rowSpan: (params) => rowSpan(params),
    cellClassRules: {
      "cell-span": (params) => !!params?.data?.rowSpan,
    },
    cellRendererFramework: (params) => (
      <div
        className="monthContainerCellClassName"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="label"> Month {params?.value}</div>
      </div>
    ),
    editable: false,
    flex: 1,
  },
  {
    headerName: "Apply",
    field: "applied",
    cellRendererFramework: (params) => (
      <div className="applyCheckboxParams">
        <ApplyRentalCheckBox
          {...{
            pliData: selectedItem,
            elevationData: selectedElevation,
            whichOne: "week",
            params: { thisWeek: params?.data },
          }}
        />
        Apply Rental
      </div>
    ),
    editable: false,
    flex: 2,
  },
  {
    headerName: "Week",
    field: "week",
    cellRenderer: ({ value }) => `Week ${value}`,
    editable: false,
    flex: 1,
  },
  {
    headerName: "Start",
    field: "weekStart",
    cellRenderer: ({ value }) => formatDate(value),
    editable: false,
    flex: 1,
  },
  {
    headerName: "From",
    field: "weekStart",
    cellRenderer: ({ value }) => formatDate(value),
    editable: false,
    flex: 1,
  },
  {
    headerName: "To",
    field: "weekEnd",
    cellRenderer: ({ value }) => formatDate(value),
    editable: false,
    flex: 1,
  },

  {
    headerName: "Weekly Amount",
    field: "weeklyAmount",
    cellEditor: "PeriodCellEditor",
    cellRendererFramework: ({ value }) => (
      <div className="rentalEditableCell">
        {formatCurrency(value || 0)}
        <EditIcon />
      </div>
    ),
    flex: 2,
  },
  {
    headerName: "Charged",
    field: "charged",
    editable: false,
    flex: 1,
    cellRendererFramework: (params) => (
      <ChargedIndicator
        {...{
          thisElevation: selectedElevation?.elevationId,
          whichOne: "week",
          params: {
            thisPli: selectedItem?.id,
            thisWeek: params?.data?.week,
          },
        }}
      />
    ),
  },
  {
    headerName: "Lock",
    field: "lock",
    cellRendererFramework: (params) => (
      <LockRow
        {...{
          locked: !!params?.value,
          whichFunction: "week",
          propsObject: {
            params,
            selectedElevation,
            selectedItem,
          },
        }}
      />
    ),
    editable: false,
    flex: 0.5,
  },
  {
    headerName: "Days",
    cellRendererFramework: ({ data }) => (
      <div className="buttonContainer">
        <MondayButton
          className="daysInformationButton"
          onClick={() => setSelectedWeek(data)}
          Icon={<Info />}
        >
          Days
        </MondayButton>
      </div>
    ),
    flex: 1,
  },
];
