import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const todos = (IdHandler, programFields, updatedDynamicStates) => {
  const todoStatus = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.["To Do"];

  return {
    todoStatus: () => ({
      title: "To Do Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "To Do",
        field: "todoStatus",
        labels: todoStatus?.map?.((el) => el?.statusName),
        datasets: [
          {
            label: "To Do",
            data: todoStatus?.map?.(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter?.(
                  (a) => a.todoStatus === status
                )?.length
            ),
            backgroundColor: todoStatus?.map((el) => el?.statusColor),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    todoCategory: () => ({
      title: "To Do Category",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "To Do",
        field: "todoCategory",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "todoCategory"
          )
        ),
        datasets: [
          {
            label: "To Do",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoCategory"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoCategory"
              )
            ).map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    relatedTo: () => ({
      title: "To Do Type",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "To Do",
        field: "relatedTo",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "relatedTo"
          )
        ),
        datasets: [
          {
            label: "To Do",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "relatedTo"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "relatedTo"
              )
            ).map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    todoUser: () => ({
      title: "To Do for each User",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "To Do",
        field: "todoUser",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "todoUser"
          )
        ),
        datasets: [
          {
            label: "To Do",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoUser"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoUser"
              )
            ).map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),
  };
};
