import types from "../types";
import { YardType } from "src/components/SidebarPages/FleetMaintenanceView/types";

/**
 * @typedef YardState
 * @property {YardType[]} yards
 */

/**
 * @typedef {YardType[]|Partial<YardType>&{yardId: string}} YardActions
 */

/**
 * @typedef {{type: keyof types, payload: YardActions}} ActionType
 */

/**
 * @param {YardType[]} attributes
 */
const yards = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_YARDS,
    payload: attributes,
  });
};

export default yards;

/**
 * @param {Partial<YardType>&{yardId: string}} attributes
 */
export const changeYardConfig = (attributes) => (dispatch) => {
  dispatch({
    type: types.CHANGE_YARD_CONFIG,
    payload: attributes,
  });
};
