import React, { useMemo } from "react";

export default (props) => {
  // const data = useMemo(
  //   () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
  //   []
  // );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props?.color || "#FFD23F" }}
    >
      <p>
        <strong>Formula</strong>
      </p>
      <p>
        <span>{props?.formula}</span>
      </p>
    </div>
  );
};
