/**
 * Check if price is invalid. Price should be a number and higher than 0
 * @param value {any}
 * */
export const isInvalidPriceValue = (value) => {
    if(isNaN(value)) return true;
    return value <= 0;
}

/**
 * Converts a number or string, usually coming from an user input into a float number with 2 digits after comma.
 * @example 76.8324 will be converted to 76.83
 * @param number {number|String}
 * */
export const roundTo2Digits = (number) => {
    return Math.round(number * 100) / 100
}

/**
 * @param number {String}
 * */
export const formatForPricingNumberInput = (number) => {
   let formatted = roundTo2Digits(number).toString(); //if input is coming from a umber type, transform it with 2 digits after ,
    //check if their value in the end is with a .0
    if(number.slice(number.length - 2) === ".0") {
        formatted += ".0"
    }

    return formatted
}


/**
 * @param number {String}
 * */
export const formatForPricingNumberLongNumber = (number) => {
    let formatted = (Math.floor(number * 100) / 100).toString(); //if input is coming from a umber type, transform it with 2 digits after ,
    //check if their value in the end is with a .0
    if(number.slice(number.length - 2) === ".0") {
        formatted += ".0"
    }

    return formatted
}


