import { MondayButton } from "../../../../../../../../../../../../../commonComponents";
import {
  Cancel,
  Close,
} from "../../../../../../../../../../../../DynamicView/src";
import DetailsContainer from "./Components/DetailsContainer/DetailsContainer";
import { Info } from "../../../../../../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../../../../../../hooks";
import ModalFooter from "./Components/ModalFooter/ModalFooter";
import ModalHeader from "./Components/ModalHeader/ModalHeader";
import "./RentalDetails.scss";
import _ from "lodash";

// import { useDispatchContext, useStateContext } from "../../../../../../../../Context/Context"

import { useSelector } from "react-redux";
import { useState } from "react";
import { message, Modal } from "antd";

const RentalDetails = ({ elevationData, allRow }) => {
  const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux(
    "unchangingFakeRentals"
  );
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [darkMode] = useRedux("rentalsDarkMode");

  const [IsDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const { isLoading } = useSelector((state) => state.rentals);
  const [selectedWeek, setSelectedWeek] = useRedux(
    "selectedRentalWeek",
    undefined,
    false
  );

  // const { darkMode, fakeRentals, unchangingFakeRentals } = useStateContext()
  // const dispatchContext = useDispatchContext()
  // const setRentalContext = (newRentals) => {
  //   dispatchContext({ type: "SET_FAKE_RENTALS", payload: newRentals })
  // }
  // const setUnchangingRentalContext = (newRentals) => {
  //   dispatchContext({ type: "SET_UNCHANGING_FAKE_RENTALS", payload: newRentals })
  // }

  //this discard all the changes that we made on the modal
  const handleDiscard = () => {
    setIsDetailsModalVisible(false);
    setUnchangingFakeRentals(fakeRentals);
  };

  const handleOpen = () => {
    message.info("Please wait while we load the details", 2);
    setIsDetailsModalVisible(true);
  };

  //this saves all the changes that we made on the modal
  const handleOk = (difference) => {
    let temp = Object.assign(unchangingFakeRentals, {});
    console.log("unchaning fake rentals", unchangingFakeRentals);
    let percentages = [];
    temp.isProRated = difference !== 0;
    temp?.services?.forEach((service) => {
      if (Array.isArray(service?.serviceOptions)) {
        service?.serviceOptions?.forEach((option) => {
          option.items?.forEach((item) => {
            console.log("resitem", item);
            // item.appliedAmount =
            let maxWeekly = item.maxWeeklyAmount || 0;
            let totalWeeks = item?.rentalDetails?.details?.length || 0;
            let oneHundredPercent = maxWeekly * totalWeeks;
            let res =
              item.rentalDetails?.details
                ?.flatMap(({ days }) => days)
                ?.reduce((acc, curr) => _.add(acc, curr?.dailyAmount), 0) || 0;
            item?.rentalDetails?.details?.forEach((det) => {
              det.weeklyAmount = Math.round(
                det?.days?.reduce(
                  (acc, curr) => _.add(acc || 0, curr?.dailyAmount || 0),
                  0
                )
              );
            });
            console.log("res amount", res);
            item.appliedAmount = res;
            percentages.push(res / oneHundredPercent);
            item.totalProgress =
              percentages?.length > 0
                ? percentages.reduce((acc, curr) => _.add(acc, curr), 0) /
                  percentages.length
                : 0;
          });
        });
      }
    });
    console.log("fakeRentals2", temp);
    console.log("percentages", percentages);

    setFakeRentals(temp);
    setIsDetailsModalVisible(false);
  };

  // console.log("RentalDetails", { elevationData, allRow });

  return (
    <div className="rentalDetailsContainer">
      <MondayButton
        disabled={isLoading}
        className="detailsRentalButton"
        onClick={handleOpen}
        Icon={<Info />}
      >
        Details
      </MondayButton>
      <Modal
        wrapClassName={
          darkMode ? "detailsRentalModalDark" : "detailsRentalModalLight"
        }
        closeIcon={darkMode ? <Close /> : <Cancel />}
        open={IsDetailsModalVisible}
        destroyOnClose={true}
        maskClosable={false}
        onOk={handleOk}
        onCancel={handleDiscard}
        title={<ModalHeader {...{ elevationData, allRow, selectedWeek }} />}
        footer={
          <ModalFooter
            {...{
              selectedWeek,
              setSelectedWeek,
              handleDiscard,
              handleOk,
              elevationData,
              allRow,
            }}
          />
        }
      >
        <DetailsContainer
          {...{
            setSelectedWeek,
            elevationData,
            selectedWeek,
            allRow,
          }}
        />
      </Modal>
    </div>
  );
};

export default RentalDetails;
