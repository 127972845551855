//@ts-check
import React, { useState } from "react";
import { MondayButton } from "../../../../commonComponents";
import { Pdf } from "../../../BasePage/src";
import Report from "../../../DynamicView/Pages/Overview/components/Report/Report";

const ReportButton = ({
  recordId = "",
  recordName = "",
  identityId = "",
  iconOnly = false,
  hasIcon = true,
  tooltipCategory,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <MondayButton
        Icon={hasIcon ? <Pdf /> : null}
        className="mondayButtonBlue"
        onClick={() => setVisible(true)}
        tooltipCategory={tooltipCategory}
      >
        {!iconOnly && "Report"}
      </MondayButton>

      {visible && (
        <Report
          {...{
            isModalVisible: visible,
            onCancel: () => setVisible(false),
            customCategoryName: `${recordName} Report`,
            customPartitionKeys: { identityId },
            recordId,
            setVisibleReport: setVisible,
          }}
        />
      )}
    </div>
  );
};

export default ReportButton;
