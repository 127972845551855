import axios from "axios";
import { message } from "antd";
import { slice } from "lodash";
import { formatDateToFormat, generateDateRange } from "./formatDate";
import { formatDobData } from "./formatDobData";
import { formatDobDate } from "../../Projects/DobTab/utils";

const apiUrl = {
  "issued permits": "https://data.cityofnewyork.us/resource/ipu4-2q9a.json?",
  "approved permits": "https://data.cityofnewyork.us/resource/rbx6-tga4.json?",
  "electrical permits":
    "https://data.cityofnewyork.us/resource/dm9a-ab7w.json?",
  "job application filing":
    "https://data.cityofnewyork.us/resource/w9ak-ipjd.json?",
};

export const constructQueryString = (filters, category) => {
  const checkIfValueIsDate = (condition) => {
    let value = condition.value;
    // Check if the 'column' key contains a date, if so format it
    if (condition.column.includes("date")) {
      if (Array.isArray(value)) {
        value =
          category !== "issued permits" ? value : generateDateRange(...value);
      } else {
        value =
          category !== "issued permits"
            ? formatDateToFormat(value)
            : formatDobDate(value);
      }
    }

    return {
      ...condition,
      value: value,
    }; // Return updated condition object
  };

  // Helper to convert a condition to a query string part
  const conditionToString = (condition) => {
    if (category === "issued permits" && condition.columnType === "date")
      switch (condition.formula) {
        case "is between":
          return `${condition.column} IN ('${condition.value.join("','")}')`;
        case "is not between":
          return `${condition.column} NOT IN ('${condition.value.join(
            "','"
          )}')`;
        case "is null":
          return `${condition.column} IS NULL`;
        case "is not null":
          return `${condition.column} IS NOT NULL`;
      }

    switch (condition.formula) {
      case "contains":
        return `${condition.column} LIKE '%${condition.value}%'`;
      case "starts with":
        return `${condition.column} LIKE '${condition.value}%'`;
      case "is":
        return `${condition.column} = '${condition.value}'`;
      case "is not":
        return `${condition.column} <> '${condition.value}'`;
      case "is one of":
        return `${condition.column} IN ('${condition.value.join("','")}')`;
      case "is not one of":
        return `${condition.column} NOT IN ('${condition.value.join("','")}')`;
      case "is between":
        const [start, end] = condition.value;
        return `${condition.column} BETWEEN '${start}' AND '${end}'`;
      case "is greater than":
        return `${condition.column} > '${condition.value}'`;
      case "is not between":
        const [starts, ends] = condition.value;
        return `${condition.column} NOT BETWEEN '${starts}' AND '${ends}'`;
      case "is greater than or equal to":
        return `${condition.column} >= '${condition.value}'`;
      case "is like":
        return `${condition.column} LIKE '${condition.value}'`;
      case "is not like":
        return `${condition.column} NOT LIKE '${condition.value}'`;
      case "is null":
        return `${condition.column} IS NULL`;
      case "is not null":
        return `${condition.column} IS NOT NULL`;
      default:
        return ""; // handle other cases as needed
    }
  };

  // Convert filter groups to query parts
  const filterGroups = filters.map((group, index) => {
    const conditions = group.conditions.map((condition) =>
      conditionToString(checkIfValueIsDate(condition))
    );
    const joinedConditions = `(${conditions.join(` ${group.operator} `)})`;

    // Use the group's operator to join with the next group, except for the last group
    if (index < filters.length - 1) {
      return `${joinedConditions} ${filters[index + 1].operator}`;
    } else {
      return joinedConditions;
    }
  });

  return filterGroups.join(" "); // Join the filter groups based on their given operator
};

export const fetchFilteredDobData = async ({
  filters,
  setIsModalVisible = () => {},
  selectedCategory,
  setDobData,
  setLoading,
  unslicedData,
}) => {
  const queryStr = encodeURIComponent(
    `Select * Where ${constructQueryString(
      filters[selectedCategory],
      selectedCategory
    )}`
  );
  const url = `${apiUrl[selectedCategory]}$query=${queryStr}`;

  axios
    .get(url)
    .then((response) => {
      const fetchedData = response?.data || [];
      const data = formatDobData(fetchedData, unslicedData, selectedCategory);
      setDobData((dobData) => ({
        ...dobData,
        [selectedCategory]: slice(data[selectedCategory], [0], [10000]),
      }));

      setLoading(false);
      setIsModalVisible(false);
    })
    .catch((error) => {
      setLoading(false);
      message.error(
        "There was an error fetching the DOB data. Please try again later."
      );
      console.error("There was an error:", error);
      setLoading(false);
    });
};
