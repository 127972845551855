import "./CommentInput.scss";
import { Switch, Tooltip, message } from "antd";
import { useState } from "react";
import MondayButton from "../../../MondayButton/MondayButton";
import {
  CheckOutlined,
  CloseOutlined,
  LockFilled,
  SendOutlined,
  UnlockFilled,
} from "@ant-design/icons";
import ReactQuillRichText from "../../../ReactQuillRichText/ReactQuillRichText";
import { ReplyIcon } from "src/components/SidebarPages/Communication/assets";
import { uniqBy } from "lodash";
import { useSelector } from "react-redux";
import { WithTooltip } from "../../..";

const CommentInput = ({
  isReply = false,
  hasPrivate = true,
  placeholder,
  usersWithAccess,
  disablePostBtn = false,
  commentToEdit, //value of comment to edit
  setCommentToEdit = () => {}, //if we are editing comment
  saveComment = () => {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [input, setInput] = useState(commentToEdit?.commentContent);
  const [mentions, setMentions] = useState([]);
  const [isPrivateComment, setIsPrivateComment] = useState(
    !!commentToEdit?.privateOnlyTo || false
  );

  function isRichTextContentEmpty(htmlContent, tagName = "p") {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent.trim();

    const targetElement = tempElement.querySelector(tagName);

    if (targetElement) {
      const textContent = targetElement.textContent.trim();
      return textContent === "";
    }

    return true;
  }

  //Function that called on submit text from rich text
  const getMentions = (content, delta, editor) => {
    const mentions = editor.getContents(content);
    //this removes first mention from array
    if (!delta.ops[1]) {
      setMentions([]);
    }
    // this removes mentions from array but not the first one
    if (!!delta.ops[1]?.delete) {
      setMentions([]);
    }
    mentions.ops.forEach((text) => {
      let nameOfUser = text.insert?.mention?.value;
      let cognitoUserId = text.insert?.mention?.id || "";

      //it's necessary!!!
      if (usersWithAccess.some((user) => user.id === cognitoUserId)) {
        // Getting all mentions and push them into array for broadcast notification validation
        setMentions((prev) =>
          uniqBy([...prev, { nameOfUser, cognitoUserId }], "cognitoUserId")
        );
      }
    });
  };

  // handle input changes
  const getValue = (content, delta, source, editor) => {
    setInput(content);
    getMentions(content, delta, editor);
  };

  //Function to update a comment
  const handleSaveComment = () => {
    if (input && typeof input === "string" && input !== "<p><br></p>") {
      saveComment({
        inputValue: input,
        mentions,
        ...(hasPrivate && { isPrivateComment }),
      });
    } else {
      message.error("Input was empty!");
    }
  };

  return (
    <div
      className={`comment-input-container ${isReply ? "replies_wrapper" : ""} ${
        isDarkMode ? "CommentInputDark" : ""
      }`}
    >
      {/* switch note privacy to private or public */}
      {hasPrivate && !isReply && (
        <div
          className="private-note-switcher"
          style={commentToEdit ? { top: "35px", right: "0" } : {}}
        >
          <WithTooltip
            tooltipCategory="Comments"
            tooltipKey={isPrivateComment ? "privateSwitch" : "publicSwitch"}
          >
            <Switch
              style={{
                backgroundColor: isPrivateComment ? "#fe4c4a" : "#01c06a",
              }}
              checkedChildren={<LockFilled style={{ fontSize: "14px" }} />}
              unCheckedChildren={<UnlockFilled style={{ fontSize: "14px" }} />}
              checked={isPrivateComment}
              onClick={() => setIsPrivateComment((prev) => !prev)}
            />
          </WithTooltip>
        </div>
      )}
      <div
        className={`comment_input_wrapper ${
          commentToEdit ? "edit_comment_wrapper" : "new_comment_wrapper"
        }`}
      >
        <ReactQuillRichText
          wrapperClassName={isDarkMode ? "ReactQuillRichTextDark" : ""}
          value={input}
          placeholder={placeholder}
          getValue={getValue}
          defaultUsers={usersWithAccess}
          showMentionList={!isPrivateComment}
        />
        <div className="react-quill-button">
          {commentToEdit ? (
            <div className="update-close-icons">
              <WithTooltip
                tooltipCategory="Comments"
                tooltipKey={isReply ? "updateReply" : "updateComment"}
              >
                <CheckOutlined
                  onClick={() => {
                    setCommentToEdit(false);
                    handleSaveComment();
                  }}
                  style={{
                    color: "#71cf48",
                    fontSize: isReply ? "16px" : "20px",
                  }}
                />
              </WithTooltip>
              <WithTooltip tooltipCategory="Comments" tooltipKey="closeUpdate">
                <CloseOutlined
                  onClick={() => setCommentToEdit(false)}
                  style={{
                    color: "#fe4c4a",
                    fontSize: isReply ? "16px" : "20px",
                  }}
                />
              </WithTooltip>
            </div>
          ) : (
            <MondayButton
              Icon={
                isReply ? (
                  <ReplyIcon />
                ) : (
                  <SendOutlined style={{ transform: "rotate(180deg)" }} />
                )
              }
              onClick={() => {
                setInput("");
                handleSaveComment();
              }}
              className="mondayButtonBlue"
              disabled={
                disablePostBtn ||
                !input ||
                input === "<p><br></p>" ||
                isRichTextContentEmpty(input)
              }
              tooltipCategory="Comments"
              tooltipKey={isReply ? "replyPost" : "commentPost"}
            >
              {isReply ? "Reply" : "Post"}
            </MondayButton>
          )}
        </div>
      </div>
    </div>
  );
};
export default CommentInput;
