import { OverlayViewF, InfoWindow } from "@react-google-maps/api";

import { MARKER_ICONS } from "../../../../data";
import { PowerOffIcon } from "../../../../../../../../assets";

import "./LocationMarker.scss";

function LocationMarker({ position, info, onInfoClick = () => {} }) {
  const status = info?.locationStatus?.replaceAll(" ", "");

  const Icon = MARKER_ICONS?.[status] || PowerOffIcon;

  let rotation = info?.direction;

  const applyRotation =
    info?.locationStatus === "Moving" || info?.locationStatus === "In Motion";

  return (
    <OverlayViewF {...{ position, mapPaneName: "overlayMouseTarget" }}>
      <div className="live-marker">
        <InfoWindow
          position={position}
          visible
          zIndex={1000}
          className="nameInfoBox"
        >
          <span style={{ fontWeight: "550", letterSpacing: "0.18px" }}>
            {info?.fleetName}
          </span>
        </InfoWindow>
        <span
          className={`marker-status-icon ${status}`}
          onClick={() => {
            onInfoClick(info);
          }}
        >
          <Icon
            height={24}
            width={24}
            style={applyRotation ? { transform: `rotate(${rotation}deg)` } : {}}
          />
        </span>
      </div>
    </OverlayViewF>
  );
}

export default LocationMarker;
