import uniq from "lodash/uniq"
import { getMonthsTimestamps } from "../../../../../utils"
import { months } from "../../../../addWidgetsModalData"

export const fleet = ({ tables }) => {
  return {
    fleetYear: () => ({
      content: uniq(tables?.Fleet?.map((a) => a?.fleetYear))
        ?.sort()
        ?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Fleet?.filter((a) => a?.fleetYear === status).length / tables?.Fleet?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Fleet?.filter((a) => a?.fleetYear === status).length,
        })),
    }), //fleetYear
    registrationExpDate: () => ({
      content: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              color: "#71CF48",
              unfilledColor: "#F5F5F5",
              percent: (
                (tables?.Fleet?.filter((b) => b?.registrationExpDate >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.registrationExpDate)
                  ?.filter(
                    (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
                  )?.length /
                  tables?.Fleet?.filter((b) => b.registrationExpDate >= getMonthsTimestamps()[0])
                    .length) *
                100
              ).toFixed(1),
              showInfo: true,
              status: months[i],
              done: tables?.Fleet?.filter((b) => b.registrationExpDate >= getMonthsTimestamps()[0])
                ?.map((a) => a?.registrationExpDate)
                ?.filter((b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i])
                ?.length,
            },
          ]
        }
        return data
      })(),
    }), //registrationExpDate
    inspectionExpDate: () => ({
      content: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              percent: (
                (tables?.Fleet?.filter((b) => b.inspectionExpDate >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.inspectionExpDate)
                  ?.filter(
                    (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
                  )?.length /
                  tables?.Fleet?.filter((b) => b.inspectionExpDate >= getMonthsTimestamps()[0])
                    .length) *
                100
              ).toFixed(1),
              showInfo: true,
              status: months[i],
              done: tables?.Fleet?.filter((b) => b.inspectionExpDate >= getMonthsTimestamps()[0])
                ?.map((a) => a?.inspectionExpDate)
                ?.filter((b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i])
                ?.length,
            },
          ]
        }
        return data
      })(),
    }), //inspectionExpDate
  } //Fleet
}
