import { notification } from "antd";
import { WarningFilled } from "@ant-design/icons";

import {
  getClickPhrase,
  getCloseIcon,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/userNotifications";

function getWarningNotificationTitle() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <WarningFilled style={{ color: "#fad753" }} />
      <span style={{ color: "#B18E0E", fontWeight: "550" }}>Warning</span>
    </div>
  );
}

function getNotificationForRates({
  baseRate = "",
  affectedRates = [],
  acceptHandler = () => {},
}) {
  let notificationKey = `${Math.random()}`;
  notification.warning({
    message: getWarningNotificationTitle(),
    key: notificationKey,
    closeIcon: getCloseIcon(() => {
      notification.destroy(notificationKey);
    }),
    description: (
      <>
        <strong>
          Careful, there are {affectedRates?.length} role rates that are
          affected by "{baseRate}"
        </strong>
        <p>
          Would you like to make the rates of{" "}
          {affectedRates?.map((rateName) => `"${rateName}"`)?.join(", ")} be the
          same as "{baseRate}"?
        </p>
        {getClickPhrase({
          handler() {
            acceptHandler();
            notification.destroy(notificationKey);
          },
        })}
      </>
    ),
    duration: 0,
    placement: "bottomLeft",
  });
}

export default getNotificationForRates;
