import React from "react";
import "./ServiceTemplates.scss";
function Card({ template = {}, onDragEnd = () => {} }) {
  return (
    <div
      className="serviceTemplateCard"
      draggable
      onDragEnd={(event) => onDragEnd(event, template)}
      onDragStart={(event) => {
        console.log("drag start", event);
        event.dataTransfer.setData("text/html", "<span></span>");
      }}
    >
      <div className="serviceTemplateCard_title">
        {template?.serviceName || ""}
      </div>
      <div className="serviceTemplateCard_content">
        {template?.templateTitle}
      </div>
    </div>
  );
}

export default Card;
