export const firstLetterToUpperCase = (w) =>
  w.charAt(0).toUpperCase() + w.slice(1);

export const getRecordNumber = (record, category) => {
  if (category === "charges") return record.chargeNumber;

  if (category === "rentals") return record.rentalNumber;

  if (category === "estimations") return record.estimationNumber;

  return;
};

export const getCategoryId = (record, category) => {
  if (category === "charges") return record.chargeId;

  if (category === "rentals") return record.rentalId;

  if (category === "estimations") return record.estimationId;

  return;
};
