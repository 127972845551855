import React, { createContext, useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useInitialEmailBoxProps } from "./InitialEmailBoxProvider";
import { Form } from "antd";

const EmailBoxContext = createContext();

export const useEmailBox = () => {
  return useContext(EmailBoxContext);
};

export const EmailBoxProvider = ({ children, recordDetails }) => {
  const {
    invoice,
    proppedTemplateCat,
    exportOrEmailDropdown,
    rowData,
    recordID,
    proppedRecordId,
  } = useInitialEmailBoxProps();
  const [signature, setSignature] = useState("fromUserConfigSignature");
  const { programFields } = useSelector((state) => state.programFields);
  const coreStorageSignature = programFields?.find(
    (field) => field.fieldName === "Core Storage Signature"
  ).fieldOptions;
  const [
    {
      authenticatedUser: { email },
    },
    { userConfiguration },
    { hotCredentials },
  ] = useSelector(({ authenticatedUser, userConfig, hotCredentials }) => [
    authenticatedUser,
    userConfig,
    hotCredentials,
  ]);

  const [driveDocuments, setDriveDocuments] = useState([]);
  const [selectedDocTypes, setSelectedDocTypes] = useState([]);
  const [draftId, setDraftId] = useState();
  const [isFromCharge, setisFromCharge] = useState(false);
  const [clickedElement, setClickedElement] = useState();
  const [form] = Form.useForm();

  const setSignatureState = (newSignature) => {
    setSignature(newSignature);
  };

  const googleDriveFolderIds =
    recordDetails?.categoryType !== "inspections"
      ? rowData?.googleDriveFolderIds
      : { inspectionFolderId: rowData?.inspectionFolderId };

  return (
    <EmailBoxContext.Provider
      value={{
        signature,
        setSignatureState,
        coreStorageSignature,
        hotCredentials,
        driveDocuments,
        setDriveDocuments,
        selectedDocTypes,
        setSelectedDocTypes,
        email,
        draftId,
        setDraftId,
        isFromCharge,
        setisFromCharge,
        invoice,
        proppedTemplateCat,
        exportOrEmailDropdown,
        rowData,
        recordDetails,
        clickedElement,
        setClickedElement,
        form,
        googleDriveFolderIds,
      }}
    >
      {children}
    </EmailBoxContext.Provider>
  );
};
