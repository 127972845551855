import React, { useEffect, useRef, useState } from "react";
import { MondayButton } from "../../../../../../commonComponents";
import { Button, Cascader, Divider, Input, Modal, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useRedux } from "../../../../../hooks";
import { DropDownArrow } from "../../../../../Fleet/components/InputComponent/assets";
import "./Phases.scss";
function Phases({ indexes, isScope, serviceDefs }) {
  return (
    <div>
      <CustomSelectPhases {...{ indexes, isScope, serviceDefs }} />
    </div>
  );
}

export default Phases;

const CustomSelectPhases = ({ indexes, isScope, serviceDefs }) => {
  const [items, setItems] = useState(["No Phase", "Phase 1", "Phase 2"]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const [selectedPhase, setSelectedPhase] = useState();
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [loading, setLoading] = useState(false);
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  console.log(
    "CustomSelectPhases",
    serviceDefs?.filter((e) => !e?.isScope)
  );

  const addItem = (e) => {
    let index = items.length + 1;
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const initialize = () => {
    setLoading(true);
    if (!isScope) {
      let phasesToAdd = ["No Phase", "Phase 1", "Phase 2"];
      if (Array.isArray(gridData))
        gridData?.forEach((service) => {
          service.serviceOptions.forEach((option) => {
            option.forEach((elevation) => {
              if (!!elevation?.phase) {
                phasesToAdd.push(elevation.phase);
              }
            });
          });
        });
      setItems([...new Set(phasesToAdd)]);
    } else {
      let phasesToAdd = serviceDefs
        ?.filter((e) => !e?.isScope)
        ?.map((e) => e?.workName || "N/A");
      setItems([...new Set(phasesToAdd)]);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { serviceIndex, optionIndex, elevationIndex } = indexes;
    let currElevation =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    if (currElevation.phase) {
      setSelectedPhase(currElevation.phase);
    }
  }, [gridData, indexes]);

  const onSelect = (phaseSelect) => {
    const { serviceIndex, optionIndex, elevationIndex } = indexes;
    const myTempData = structuredClone(gridData);
    let myElevation =
      myTempData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    if (phaseSelect === "No Phase") {
      setSelectedPhase(null);
      delete myElevation.phase;
      setTakeOffTableData(myTempData);
    } else {
      setSelectedPhase(phaseSelect);
      myElevation.phase = phaseSelect;
      setTakeOffTableData(myTempData);
    }
    // Modal.confirm({
    //   icon: null,
    //   title: "Are you sure you want to change the phase?",
    //   centered: true,
    //   okText: "Confirm",
    //   // content: (
    //   //   <div>
    //   //     <strong> {phaseSelect} </strong>
    //   //   </div>
    //   // ),
    //   onOk: () => {
    //     if (phaseSelect === "No Phase") {
    //       setSelectedPhase(null);
    //       delete myElevation.phase;
    //       setTakeOffTableData(myTempData);
    //     } else {
    //       setSelectedPhase(phaseSelect);
    //       myElevation.phase = phaseSelect;
    //       setTakeOffTableData(myTempData);
    //     }
    //     console.log("myElevation", myElevation);
    //   },
    // });
  };
  return (
    <div className="select-phase-wrapper">
      <Select
        style={{ minWidth: 200, border: "none" }}
        className="select-phase-dropdown"
        placeholder={isScope ? "Select Scope" : "Select Phase"}
        onClick={(e) => {
          e.stopPropagation();
        }}
        value={selectedPhase}
        onDropdownVisibleChange={(e) => {
          initialize();
        }}
        onSelect={(e) => {
          onSelect(e);
        }}
        suffixIcon={<DropDownArrow />}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Input
                placeholder="Please enter phase"
                ref={inputRef}
                value={name}
                onChange={onNameChange}
              />
              <PlusOutlined onClick={addItem} />
            </Space>
          </>
        )}
        loading={loading}
        options={items.map((item) => ({ label: item, value: item }))}
      />
    </div>
  );
};
