// import { boroughs } from "../../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const safety = ({ tables, programFields }) => {
  const safetyStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    safetyApplicationStatus: () => ({
      content: safetyStatus?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Safety?.filter(
            (e) => e?.safetyApplicationStatus === status?.statusName
          ).length /
            tables?.Safety?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status?.statusName,
        done: tables?.Safety?.filter(
          (e) => e?.safetyApplicationStatus === status?.statusName
        )?.length,
      })),
    }),

    safetyName: () => ({
      content: uniq(tables?.Safety?.map((safety) => safety?.safetyName))
        ?.filter(Boolean)
        ?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Safety?.filter((e) => e?.safetyName === status)?.length /
              tables?.Safety?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Safety?.filter((e) => e?.safetyName === status)?.length,
        })),
    }),

    accountName: () => ({
      content: uniq(
        tables?.Safety?.map(
          (safety) => safety?.safetyApplicationObject?.accountName
        )
      )?.map((a) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Safety?.filter(
            (e) => e?.safetyApplicationObject?.accountName === a
          )?.length /
            tables?.Safety?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: a,
        done: tables?.Safety?.filter(
          (e) => e?.safetyApplicationObject?.accountName === a
        )?.length,
      })),
    }),

    projectName: () => ({
      content: uniq(
        tables?.Safety?.map(
          (safety) => safety?.safetyApplicationObject?.projectName
        )
      )?.map((a) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Safety?.filter(
            (e) => e?.safetyApplicationObject?.projectName === a
          )?.length /
            tables?.Safety?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: a,
        done: tables?.Safety?.filter(
          (e) => e?.safetyApplicationObject?.projectName === a
        )?.length,
      })),
    }),

    scheduleAddress: () => ({
      content: uniq(
        tables?.Safety?.map(
          (safety) => safety?.safetyApplicationObject?.scheduleAddress
        )
      )?.map((a) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Safety?.filter(
            (e) => e?.safetyApplicationObject?.scheduleAddress === a
          )?.length /
            tables?.Safety?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: a,
        done: tables?.Safety?.filter(
          (e) => e?.safetyApplicationObject?.scheduleAddress === a
        )?.length,
      })),
    }),

    safetyApplicationCategory: () => ({
      content: uniq(
        tables?.Safety?.map((safety) => safety?.safetyApplicationCategory)
      )?.map((a) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Safety?.filter((e) => e?.safetyApplicationCategory === a)
            ?.length /
            tables?.Safety?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: a,
        done: tables?.Safety?.filter((e) => e?.safetyApplicationCategory === a)
          ?.length,
      })),
    }),
  };
};
