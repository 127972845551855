import styles from "../../MultiLevelTreeLogs.module.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import TreeCollapse from "../TreeCollapse/TreeCollapse";
import { filterData } from "../../utils/filterData";

const DisplayLogs = ({ newLogData, searchText, isCostumed }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [itemsLength, setItemsLength] = useState(100);

  const filteredData = filterData(newLogData, searchText);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollHeight - scrollTop <= clientHeight + 100) {
      setItemsLength((prev) => (prev += 50));
    }
  };

  return (
    <div
      className={styles[isDarkMode ? "logs-container-dark" : "logs-container"]}
      onScroll={handleScroll}
    >
      {filteredData.length ? (
        filteredData
          ?.slice(0, itemsLength)
          .map((log) => <TreeCollapse key={log.id} {...{ log, isCostumed }} />)
      ) : (
        <div className={styles[isDarkMode ? "no-logs-dark" : "no-logs"]}>
          No logs available
        </div>
      )}
    </div>
  );
};

export default DisplayLogs;
