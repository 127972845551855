import { message } from "antd";
import { API } from "aws-amplify";
import { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import maxBy from "lodash/maxBy";

import { CardHeader, DocumentationCard } from "./components";
import { preferences as setPreferences } from "../../../../../../actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { getDriveFolderKeyName } from "../../../../utils/getDriveFolderKeyName";
// import { flatStringWithLowerCaseAndSpaces } from "../../../../../pages/Settings/settingsComponents/DocumentationConfiguration/components/NewCustomFieldModal/customFieldModalData";

import "./DocumentationListCard.scss";
import { CardTemplate } from "../../../../../pages/Settings/settingsComponents/Management/EditUser/components";

const DocumentationListCard = ({
  record,
  endpoint,
  preferences,
  accessToken,
  activeFilter,
  recordValue = "",
  recordId,
  categoryName,
  documentation,
  setDocumentation,
  categoryConfiguration,
  viewTitle,
  isEditable = true,
  docConfiguration,
  allDocumentation = [],
  uploadsWarnings = [],
  setUploadsWarnings = () => {},
}) => {
  // const [draggableMode, setDraggableMode] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [layoutChanged, setLayoutChanged] = useState(false);
  const dispatch = useDispatch();

  const filteredDocs = useMemo(() => {
    let groupedDocs = (documentation || []).filter(
      (el) => !el?.renewalId && !el?.revisedId
    );

    for (const document of documentation) {
      if (!!document?.renewalId) {
        let docIndex = groupedDocs.findIndex(
          (el) => el?.docId === document?.renewalId
        );
        const doc = groupedDocs?.[docIndex];

        if (doc) {
          if (
            doc?.expirationDate ||
            doc?.createdAt < document?.expirationDate ||
            document.createdAt
          ) {
            groupedDocs[docIndex] = {
              ...document,
              docChildren: [...(doc?.docChildren || []), doc],
            };
          } else {
            groupedDocs[docIndex] = {
              ...doc,
              docChildren: [...(doc?.docChildren || []), document],
            };
          }
        }
      }

      if (!!document?.revisedId) {
        let docIndex = groupedDocs.findIndex(
          (el) => el?.docId === document?.revisedId
        );
        const doc = groupedDocs?.[docIndex];
        if (doc) {
          if (doc?.expirationDate || 0 < document?.expirationDate || 0) {
            groupedDocs[docIndex] = {
              ...document,
              docChildren: [...(doc?.docChildren || []), doc],
            };
          } else {
            groupedDocs[docIndex] = {
              ...doc,
              docChildren: [...(doc?.docChildren || []), document],
            };
          }
        }
      }
    }

    if (!!activeFilter) {
      groupedDocs = groupedDocs.flatMap((doc) => {
        if (
          doc?.docChildren?.length &&
          doc?.docChildren.some(
            (childDoc) => childDoc.docStatus === activeFilter
          )
        ) {
          return {
            ...doc,
            docChildren: (doc?.docChildren || []).filter(
              (childDoc) => childDoc.docStatus === activeFilter
            ),
          };
        } else if (doc?.docStatus === activeFilter) {
          return {
            ...doc,
            docChildren: (doc?.docChildren || []).filter(
              (childDoc) => childDoc.docStatus === activeFilter
            ),
          };
        } else {
          return [];
        }
      });
    }

    return groupedDocs;
  }, [documentation, activeFilter]);

  // const folderDriveKeyName = getDriveFolderKeyName({
  //   programFields,
  //   name: categoryName.replace("-", " "),
  // });
  const { googleDriveFolderIds = {} } = record;
  const { docObject: recordFolderId } = googleDriveFolderIds;

  const filterDocsByType = (type) =>
    documentation.filter(({ docType: arrDocType }) => arrDocType === type);

  function saveCardsLayout() {
    message.loading({ key: "saveLayout", content: "Saving...", duration: 0 });

    const newPreferences = {
      preferences: {
        ...preferences.preferences,
        documentationCards: {
          [endpoint]: {
            [recordId]: filteredDocs.map((el) => el.docId),
          },
        },
      },
    };

    API.put("preferences", "/preferences", {
      body: newPreferences,
    })
      .then(() => {
        dispatch(setPreferences(newPreferences));
        message.success({
          key: "saveLayout",
          duration: 1.8,
          content: "Layout saved successfully!",
        });
      })
      .catch(() => {
        message.error({
          key: "saveLayout",
          duration: 1.8,
          content: "There was a problem saving the Layout!",
        });
      });
  }

  function onDragEnd(res) {
    if (!res.destination) {
      return;
    }

    if (!layoutChanged) {
      setLayoutChanged(true);
    }

    let mainDocs = [];
    let nestedDocs = [];

    for (const doc of documentation) {
      if (!doc?.renewalId && !doc?.revisedId) {
        mainDocs.push(doc);
      } else {
        nestedDocs.push(doc);
      }
    }

    const savedItems = [...mainDocs];
    const [movedItem] = savedItems.splice(res.source.index, 1);
    savedItems.splice(res.destination.index, 0, movedItem);
    setDocumentation(savedItems.concat(nestedDocs)); // setFilteredDocs(savedItems);
  }

  // const getMaxByDocumentation = (docTypeToCompare) =>
  //   maxBy(
  //     filterDocsByType(docTypeToCompare).filter(
  //       ({ renewalId, revisedId }) => !renewalId && !revisedId
  //     ),
  //     "createdAt"
  //   ) || {};

  return (
    <CardTemplate
      {...{
        title: (
          <CardHeader
            {...{
              recordValue,
              categoryConfiguration,
              // getMaxByDocumentation,
              accessToken,
              recordFolderId,
              categoryName,
              recordId,
              setDocumentation,
              documentation,
              viewTitle,
              isEditable,
              record,
              uploadsWarnings,
              setUploadsWarnings,
              filteredDocs,
              saveCardsLayout,
              layoutChanged,
            }}
          />
        ),
        className: "documentationListCard",
        childrenClassName: "documentationListContainer",
        isDarkMode,
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="cardsDroppableId">
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={style}
              >
                {filteredDocs.map((el, i) => {
                  return (
                    <Draggable
                      key={el?.docId}
                      draggableId={el?.docId}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DocumentationCard
                            key={i}
                            {...{
                              ...el,
                              accessToken,
                              renewal:
                                !!el.renewalId &&
                                filterDocsByType(el?.docType).length > 1 &&
                                el?.renewalId,
                              revised:
                                !!el.revisedId &&
                                filterDocsByType(el?.docType).length > 1 &&
                                el?.revisedId,
                              // getMaxByDocumentation(el.docType)?.docId === el.docId,
                              // createdAt: getCreatedAt(el),
                              // createdAt: handleExpirationData(el),
                              original:
                                filterDocsByType(el?.docType)?.length > 1 &&
                                !el?.renewalId,
                              recordFolderId,
                              categoryConfiguration,
                              setDocumentation,
                              viewTitle,
                              isEditable,
                              docConfiguration,
                              documentation,
                              record,
                              allDocumentation,
                              isDragging: snapshot.isDragging,
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </CardTemplate>
  );
};

export default memo(DocumentationListCard);

const style = {
  height: "100%",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "49.5% 49.5%",
  gridAutoRows: "min-content",
  gridGap: "10px 14px",
  overflowY: "auto",
  overflowX: "hidden",
  padding: 0,
};

// const getCreatedAt = (el) => {
//   switch (el.categoryName) {
//     case "Fleet":
//       let temp = el?.docObject || [];

//       if (Array.isArray(temp)) {
//         let findTe = temp?.find((el) => el?.label === "Expiration date")?.value;
//         return !!findTe ? findTe : null;
//       }
//       break;
//     case "Drivers":
//       return el?.expirationDate;
//       break;
//     default:
//       return el?.createdAt;
//   }
// };

// function handleExpirationData(obj) {
//   const findDateInOtherLevels = obj.docObject.findIndex(
//     (el) =>
//       flatStringWithLowerCaseAndSpaces(el.label) ===
//       flatStringWithLowerCaseAndSpaces("Expiration date")
//   );

//   const hasExpirationDateInOtherLevels = findDateInOtherLevels !== -1;

//   const hasExpirationDateInFirstLevel = !obj.expirationDate ? false : true;

//   const updatedExpiration = hasExpirationDateInFirstLevel
//     ? obj.expirationDate
//     : hasExpirationDateInOtherLevels
//     ? obj.docObject[findDateInOtherLevels].value
//     : "";

//   return updatedExpiration;
// }
