import { useSelector } from "react-redux";

import { useRedux } from "../../../../../../../../../hooks/useRedux";
import { validateThenSavePayment } from "../../../../utils/checkers";
import { InputComponent } from "../../../../../../../../../Fleet/components";

import "./PaymentCardForm.scss";

const PaymentCardForm = ({ paymentData }) => {
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable] = useRedux("paymentIsWritable", false);
  const { programFields } = useSelector((state) => state.programFields);

  const cardType =
    programFields.find((field) => field.fieldName === "Card Types")
      ?.fieldOptions || [];

  return (
    <div>
      <InputComponent
        type="select"
        showSearch
        allowClear
        placeholder="Card Type"
        disabled={paymentData && !isWritable}
        customOptions={cardType}
        initialValue={fakePayment?.cardInfo?.cardType ?? ""}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onSelect={(value) => {
          setFakePayment(
            validateThenSavePayment({
              ...fakePayment,
              cardInfo: {
                ...fakePayment.cardInfo,
                cardType: value,
              },
            })
          );
        }}
        onClear={() => {
          setFakePayment(
            validateThenSavePayment({
              ...fakePayment,
              cardInfo: {},
            })
          );
        }}
      />
    </div>
  );
};

export default PaymentCardForm;
