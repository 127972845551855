import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { fetchByList, batchUpdateItems } from "../../../../../utils";

/**
 * Function that updates the data of the dispatch
 * routes and the activities with the actual depart and arrive times
 */
async function auditActivities({
  auditForTruck,
  revert = false,
  newAuditActivities,
  plannedTripsForDate,
}) {
  let activities = [];
  let fleetId = Object.keys(auditForTruck || {})?.[0];

  if (fleetId) {
    for (const activityId of newAuditActivities) {
      let relatedActivity = plannedTripsForDate?.[fleetId]?.find(
        ({ activityId: id }) => activityId === id
      );

      if (relatedActivity) {
        if (revert) {
          activities.push({
            activityId,
            dispatchId: relatedActivity?.dispatchId,
            actualDepart: "",
            actualArrive: "",
          });
        } else {
          let relatedAlerts = [];
          for (const alertId in auditForTruck?.[fleetId]?.["changed"] || {}) {
            let obj = auditForTruck[fleetId]["changed"][alertId];
            if (obj["activityId"] === activityId) {
              relatedAlerts.push(obj);
            }
          }

          if (relatedAlerts?.length) {
            relatedAlerts?.sort((a, b) => a?.alertDateTime - b?.alertDateTime);
            activities.push({
              activityId,
              dispatchId: relatedActivity?.dispatchId,
              actualDepart: dayjsNY(
                relatedAlerts?.[0]?.["alertDateTime"]
              ).format(),
              actualArrive: dayjsNY(
                relatedAlerts?.[1]?.["alertDateTime"]
              ).format(),
            });
          }
        }
      }
    }
  }

  if (!activities.length) {
    return Promise.resolve();
  }

  let dispatchesToAudit = activities.reduce(
    (acc, { dispatchId, activityId, actualDepart, actualArrive }) => ({
      ...acc,
      [dispatchId]: {
        ...(acc?.[dispatchId] || []),
        [activityId]: {
          actualDepart,
          actualArrive,
          activityStatus: revert ? "Draft" : "Audited",
        },
      },
    }),
    {}
  );

  const allDispatchesToEdit = await fetchByList(
    "fleetDispatching",
    "dispatchId",
    Object.keys(dispatchesToAudit)
  ).then((res) => {
    return res.reduce(
      (acc, val) => ({
        ...acc,
        [val.dispatchId]: val,
      }),
      {}
    );
  });

  const routesUpdates = {};
  const activityUpdates = {};

  for (const dispatchId in allDispatchesToEdit) {
    const routes = allDispatchesToEdit[dispatchId]["routes"] || [];
    for (const activityId in dispatchesToAudit[dispatchId]) {
      const routeIndex = routes?.findIndex(
        ({ activityId: id }) => activityId === id
      );

      if (routeIndex === -1) {
        continue;
      }

      if (!dispatchId in routesUpdates) {
        routesUpdates[dispatchId] = {};
      }

      routesUpdates[dispatchId] = {
        ...routesUpdates[dispatchId],
        [`routes.[${routeIndex}].actualDepart`]:
          dispatchesToAudit[dispatchId][activityId]["actualDepart"],
        [`routes.[${routeIndex}].actualArrive`]:
          dispatchesToAudit[dispatchId][activityId]["actualArrive"],
      };

      activityUpdates[activityId] = {
        actualDepart: dispatchesToAudit[dispatchId][activityId]["actualDepart"],
        actualArrive: dispatchesToAudit[dispatchId][activityId]["actualArrive"],
        activityStatus:
          dispatchesToAudit[dispatchId][activityId]["activityStatus"],
      };
    }
  }

  return await Promise.all([
    batchUpdateItems({
      tableName: "fleetActivity",
      conditionKey: "activityId",
      updateData: activityUpdates,
    }),
    batchUpdateItems({
      tableName: "fleetDispatching",
      conditionKey: "dispatchId",
      updateData: routesUpdates,
    }),
  ]);
}

export default auditActivities;
