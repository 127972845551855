import { DayStatus, DisplayColors, DisplayIcons } from "./DayStatuses";
import { Button, message } from "antd";
import _ from "lodash";
import moment from "moment";
export default function StatusDayButton({
  x,
  setWhichStatusDayBtnIsClicked,
  whichStatusDayBtnIsClicked,
  scheduleDay,
  scheduleDays_to_use,
  findScheduleDay,
  set_newScheduleDays,
  set_dayStatus,
  set_scheduleDays_to_use,
  newScheduleDays,
  dayStatus,
  setPostponeReason,
}) {
  const onStatusChange = (status, scheduleDay) => {
    setPostponeReason("");
    setWhichStatusDayBtnIsClicked(status);
    const scheduleDayToEdit = findScheduleDay(
      scheduleDays_to_use,
      scheduleDay?.id
    );
    scheduleDayToEdit.status = status;

    if (status === "Postponed") {
      const newScheduleDays_copy = _.cloneDeep(newScheduleDays);
      const postponedDayForThisDay = scheduleDayToEdit.createAPostponeDay(
        scheduleDayToEdit.startDate,
        scheduleDayToEdit.endDate,
        0,
        {}
      );
      newScheduleDays_copy.push(postponedDayForThisDay);
      set_newScheduleDays(newScheduleDays_copy);
    } else {
      for (const newScheduleDay of newScheduleDays) {
        const schToRemovePostponed = scheduleDays_to_use?.find(
          (sch) => sch?.id === newScheduleDay?.id
        );
        if (schToRemovePostponed) {
          schToRemovePostponed.linkedDays.postponedTo = undefined;
        }
      }
      set_newScheduleDays([]);
    }
    set_dayStatus(status);
    set_scheduleDays_to_use(scheduleDays_to_use);
    // }
  };

  return (
    <div
      className={
        dayStatus === "Postponed-view-buttons" ? "" : "day-status-buttons"
      }
    >
      <Button
        onClick={() => onStatusChange(x, scheduleDay)}
        style={
          x === whichStatusDayBtnIsClicked
            ? {
                background: DisplayColors[x],
                color: "#fff",
              }
            : {}
        }
        className={
          dayStatus === "Postponed"
            ? "Postponed-view-buttons"
            : "btn-status-schedule"
        }
      >
        {x}
        <div style={{ position: "absolute", right: 15 }}>
          {DisplayIcons()[x]}
        </div>
      </Button>
    </div>
  );
}
