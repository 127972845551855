import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const maintenance = (updatedDynamicStates, IdHandler) => {
  return {
    createdAt: () => ({
      title: "Maintenance Creation Date",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Maintenance",
        field: "createdAt",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (b) => b.createdAt >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a.createdAt >= getMonthsTimestamps()[i] &&
                    a.createdAt <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Maintenance In " + months[i],
                contentsHandler: " Maintenances In " + months[i],
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "1600px",
    }), //createdAt
  }; //Maintenance
};
