import { Drawer, Tooltip } from "antd";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../../commonComponents";
import filtersFieldsJSON from "./filtersFieldsJSON";
import { useState } from "react";
import SessionLogFilters from "../SessionLogsDrawer/components/SessionLogFilters/SessionLogFilters";

function SessionUsersFilterDrawer({
  filtersVisible,
  setFiltersVisible,
  filters,
  setFilters,
  clearFilters,
  allDepartments,
  allRoles,
  isDarkMode,
}) {
  const [tmpFilters, setTmpFilters] = useState(filters);
  const filterFields = filtersFieldsJSON({
    isDarkMode,
    filters: tmpFilters,
    setFilters: setTmpFilters,
    allDepartments,
    allRoles,
  });
  function onCancel() {
    setFiltersVisible(false);
    setTmpFilters(filters);
  }

  function onFilterApply() {
    setFilters(tmpFilters);
    setFiltersVisible(false);
  }
  return (
    <SessionLogFilters
      {...{
        visible: filtersVisible,
        filterFields,
        onCancel,
        clearFilters,
        onFilterApply,
        title: "Session Users Filters",
      }}
    />
  );
}

export default SessionUsersFilterDrawer;
