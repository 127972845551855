import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { useInitialEmailBoxProps } from "../providers/InitialEmailBoxProvider";
import { useEmailBox } from "../providers/EmailBoxProvider";

const useDynamicFieldsData = (rowData, recordDetails) => {
  const [loading, setLoading] = useState(true);
  const [allDynamicFields, setAllDynamicFields] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [driveItems, setDriveItems] = useState([]);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const getDriveItems = async (folderId, folderName, itemsList) => {
    await driveRequest.getFilesByFolderId(folderId).then((res) => {
      return res.json().then(({ files }) =>
        files?.map(({ mimeType, id, name }) => {
          mimeType === "application/vnd.google-apps.folder"
            ? getDriveItems(id, name, itemsList).then(() =>
                itemsList.push({
                  folder: folderName,
                  folderId,
                  id,
                  name,
                  type: "Folder",
                })
              )
            : itemsList.push({
                folder: folderName,
                folderId,
                id,
                name,
                type: "Item",
              });
        })
      );
    });

    return itemsList;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await API.get("dynamicFields", "/dynamicFields");
      setAllDynamicFields(res);

      const documents = [];
      const documentsFields = filterDynamicFields(
        res,
        recordDetails?.categoryType,
        "documentation"
      );

      const singleFields = filterDynamicFields(
        res,
        recordDetails?.categoryType,
        "single"
      );

      for await (let {
        dynamicFieldValue,
        dynamicFieldLabel,
      } of documentsFields) {
        const folderId =
          recordDetails?.categoryType !== "inspections"
            ? rowData?.googleDriveFolderIds &&
              rowData?.googleDriveFolderIds[dynamicFieldValue]
            : rowData?.inspectionFolderId;
        const folderName =
          recordDetails?.categoryType !== "inspections"
            ? dynamicFieldLabel
            : rowData[dynamicFieldValue];

        if (folderId && folderName) {
          documents.push(
            await getDriveItems(folderId, folderName, documents).then((res) =>
              Promise.all(res.flat(1))
            )
          );
        }
      }
      setDriveItems(documents.flat(1));
      setDynamicFields([...documentsFields, ...singleFields]);
    } catch (error) {
      console.error("Error fetching dynamic fields:", error);
    } finally {
      setLoading(false);
    }
  };

  function filterDynamicFields(allDynamicFields, categoryType, renderFor) {
    const allDynamicFieldsFiltered = allDynamicFields.filter(
      ({ dynamicFieldCategory, dynamicFieldRenderFor }) =>
        dynamicFieldCategory.toLowerCase() === categoryType &&
        dynamicFieldRenderFor === renderFor
    );

    return allDynamicFieldsFiltered;
  }

  return {
    loading,
    allDynamicFields,
    driveItems,
    setDriveItems,
    dynamicFields,
  };
};

export default useDynamicFieldsData;
