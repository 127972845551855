//This function finds and replaces objects inside arrays, exmple: { title: "test", children: [{ title: "childtest", children: [{}] }] }
export const recursivelyFindAndReplaceObj = (
  array = [],
  keyForId,
  id,
  updatedKeys, //when updatedKeys is false this will delete that element
  keyForRecursion
) => {
  return array
    .map((element) => {
      return element?.[keyForId] === id
        ? updatedKeys && { ...element, ...updatedKeys }
        : !!element[keyForRecursion]
        ? {
            ...element,
            [keyForRecursion]: recursivelyFindAndReplaceObj(
              element[keyForRecursion],
              keyForId,
              id,
              updatedKeys,
              keyForRecursion
            ),
          }
        : element;
    })
    .filter(Boolean);
};
