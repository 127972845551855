/**
 * Returns a modified version of the proppedRequest object based on the specified action.
 *
 * @param {Object} options - The options object.
 * @param {string} options.action - The action to perform on the proppedRequest object.
 * @param {Object} options.proppedRequest - The original proppedRequest object.
 * @param {string} options.userRequesting - The user performing the action.
 * @returns {Object} - The modified proppedRequest object.
 */
const returnProppedRequest = ({
  action = "",
  proppedRequest = {},
  userRequesting = "",
}) => {
  switch (action) {
    case "Needs Review":
      return {
        ...proppedRequest,
        status: action,
        assignedTo: userRequesting,
      };
    case "Reviewed":
      return {
        ...proppedRequest,
        status: action,
        assignedTo: userRequesting,
      };
    case "Cancel":
      return {
        ...proppedRequest,
        status: "Cancelled",
        assignedTo: userRequesting,
      };
    default:
      return proppedRequest;
  }
};

export default returnProppedRequest;
