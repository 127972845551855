import React, { Component } from "react";
import { Button } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";

export default class SpanCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };

    this.props = props;
  }

  onSpanAdd = () => {
    let thisAddonId = this.props.node.data.id;
    this.props.addSpan(thisAddonId);
    // this.props.api.getRowNode(thisAddonId).setExpanded(true)
  };

  onRemoveLastSpan = () => {
    let thisAddonId = this.props.node.data.id;
    this.props.removeLastSpan(thisAddonId);
  };

  render() {
    let spanCount = this?.props?.data?.span?.length || 0;
    let spanDisplay;
    if (spanCount === 0) {
      spanDisplay = <span className="addons-count-zero">{spanCount}</span>;
    } else {
      spanDisplay = <span className="addons-count-number">{spanCount}</span>;
    }

    return (
      <div
        className="span-cell-renderer"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {spanDisplay}
        <Button
          size="small"
          shape="circle"
          className="rowModifierAdd"
          disabled={!this.props.isWritable}
          onClick={this.onSpanAdd}
        >
          <AddIcon />
        </Button>
        <Button
          size="small"
          shape="circle"
          className="rowModifierRemove"
          disabled={!this.props.isWritable}
          onClick={this.onRemoveLastSpan}
        >
          <MinusCircleOutlined />
        </Button>
      </div>
    );
  }
}
