import { LockFilled } from "@ant-design/icons";

const Header = ({ tab }) => {
  return (
    <div className="entryRolePage-header">
      <LockFilled style={{ fontSize: "30px" }} />
      <span className="entryPageDescription">
        {tab === 7
          ? "Click the user role that you want to edit. Be very careful while you edit the user departments."
          : "Do you want to see a user's access? Every user may be found in each of these Departments."}
      </span>
    </div>
  );
};

export default Header;
