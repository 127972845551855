//@ts-check
import moment from "moment";
import { Form } from "antd";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";

import { fetchData } from "../../../Fleet/utils";
import { filterTables } from "../../../../../utils";
import { convertSubContractorToVendor } from "../utils/convertSubContractorToVendor";
import { useTableContext } from "../../../../commonComponents/DynamicAgGridTable/context/AgGridTableContext";

const InitialBillContext = createContext();

export const useInitialBillContext = () => {
  return useContext(InitialBillContext);
};

export const InitialBillProvider = ({ children, ...props }) => {
  const [billData, setBillData] = useState();
  const [billItems, setBillItems] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [services, setServices] = useState([]);
  const [vendor, setVendor] = useState(
    props.vendor && { ...props.vendor, isFromVendor: true }
  );

  const [form] = Form.useForm();

  const { billId } = useParams();

  const { setTableLoading } = useTableContext();
  const fetchBillData = async () => {
    setIsLoading(true);
    if (billId) {
      const bill = await API.get("bills", `/bills/${billId}`);

      if (bill) {
        const vendorRes = await API.get("vendors", `/vendors/${bill.vendorId}`);
        setVendor(vendorRes);
      }

      setBillData(bill);

      const formBillData = {
        ...bill,
        billServices:
          bill?.billServices?.map((service) => service.serviceName) || [],
        billDate: moment(bill.billDate),
        billDueDate: moment(bill.billDueDate),
        creditDate: moment(bill.creditDate),
      };

      form.setFieldsValue(formBillData);
    }

    const items = await fetchData("billItems");
    setBillItems(items);

    if (!vendor && !billId) {
      Promise.all([
        fetchData("vendors"),
        filterTables("accounts", "subcontractorType", "Vendor"),
      ]).then(([vendorsResult, subContractorsResult]) => {
        const subContractors =
          convertSubContractorToVendor(subContractorsResult);
        const combinedVendors = [...vendorsResult, ...subContractors];
        setVendors(combinedVendors);
      });
    }

    if (vendor && !billId)
      form.setFieldsValue({ vendorName: vendor.vendorName });

    setTableLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBillData();
  }, [billId]);

  useEffect(() => {
    if (!!!services.length)
      fetchData("serviceDefinitions").then((services) =>
        setServices(
          services.map((service) => ({
            serviceName: service.serviceName,
            serviceId: Number(service.serviceId),
          }))
        )
      );
  }, []);

  return (
    <InitialBillContext.Provider
      value={{
        fetchBillData,
        form,
        billItems,
        setBillItems,
        billData,
        isLoading,
        vendors,
        setVendors,
        setVendor,
        services,
        ...props,
        vendor,
      }}
    >
      {children}
    </InitialBillContext.Provider>
  );
};
