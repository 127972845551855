import ServiceContent from "./ServiceContent/ServiceContent";
import { useStateContext } from "../../../Context/Context";
import ServiceHeader from "./ServiceHeader/ServiceHeader";
import ServiceFooter from "./ServiceFooter/ServiceFooter";
import "./service.scss";

const Service = ({ service, borderColor, serviceEvents, project }) => {
  const { darkMode } = useStateContext();

  return (
    <div
      className={
        darkMode ? "service-list-service-dark" : "service-list-service"
      }
      style={{ boxShadow: `0px 0px 6px 3px ${borderColor}` }}
    >
      <ServiceHeader {...{ service, borderColor, serviceEvents }} />
      <ServiceContent {...{ service, borderColor, serviceEvents, project }} />
      <ServiceFooter {...{ service, events: serviceEvents?.listOfEvents }} />
    </div>
  );
};

export default Service;
