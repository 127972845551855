import { assignedToObject, teamSelect, projectManagerSelect } from "..";
import sortFields from "../sortFields";

/**
 *
 * @param {Array} teams - Array of teams
 * @param {Array} accounts - Array of accounts
 * @param {Array} inputFields - Array of input fields
 * @param {Boolean} isDarkMode - Dark mode flag
 * @param {Array} projectManagers - Array of project managers
 * @param {Array} projectExecutives - Array of project executives
 * @param {Boolean} globalDisabled - Global disabled flag
 * @param {Object} userConfiguration - User configuration object
 * @param {Function} onTeamSelect - Team select function
 * @param {Object} selectedMainField - Selected main field
 * @param {Boolean} allFieldsDisabled - All fields disabled flag
 * @param {Function} onMainFieldSelect - Main field select function
 * @returns
 */
const opportunityRequest = ({
  teams = [],
  accounts = [],
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  projectExecutives = [],
  globalDisabled = false,
  userConfiguration = {},
  onTeamSelect = () => {},
  selectedMainField = null,
  allFieldsDisabled = false,
  onMainFieldSelect = () => {},
}) => {
  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";

    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Opportunities",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "projectExecutive") {
      delete field?.options;

      Object.assign(field, {
        disabled:
          !!allFieldsDisabled ||
          globalDisabled ||
          !!selectedMainField?.accountId,
        options: projectExecutives?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
      });
    }

    if (formItemName === "client") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (accounts || []).map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
        allowClear: true,
        showSearch: true,

        onSelect: (val, opt) => onMainFieldSelect({ val, opt }),

        onClear: () => onMainFieldSelect({ val: null, opt: null, clear: true }),
      });
    }

    if (field?.disabled === false) {
      field.disabled = !!allFieldsDisabled || globalDisabled;
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default opportunityRequest;
