import moment from "moment-timezone";
import { forceToNumber } from "../../../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { currencyFormatter } from "../../../../../../../SidebarPages/utils/currencyFormatter";

class Payments {
  constructor(paymentsType) {
    this.paymentsType = paymentsType;
    this.defiance = indexes[paymentsType] || {};
  }
  getApplicableKeys() {
    return Object.keys(this.defiance);
  }
}

export const GENERAL_PAYMENTS_DESC_DEFINERS = {
  accountName: (accountName) =>
    accountName ? accountName : "Unknown Account Name",
  clientBalance: (clientBalance) =>
    currencyFormatter(forceToNumber(clientBalance || 0), "$"),
  createdAt: (createdAt) =>
    moment(createdAt).format("MM/DD/YYYY") || "Unknown Created At",
  createdBy: (createdBy) => createdBy || "Unknown Created By",
  currentDueAmount: (currentDueAmount) =>
    currencyFormatter(forceToNumber(currentDueAmount || 0), "$"),
  newCustomerBalance: (newCustomerBalance) =>
    currencyFormatter(forceToNumber(newCustomerBalance || 0), "$"),
  credits: (credits) => currencyFormatter(forceToNumber(credits || 0), "$"),
  newDueAmount: (newDueAmount) =>
    currencyFormatter(forceToNumber(newDueAmount), "$"),
  paymentAmount: (paymentAmount) =>
    currencyFormatter(forceToNumber(paymentAmount || 0), "$"),
  paymentDate: (paymentDate) =>
    moment(paymentDate).format("MM/DD/YYYY") || "Unknown Payment Date",
  paymentMethod: (paymentMethod) => paymentMethod || "Unknown Payment Method",
  paymentNumber: (paymentNumber) => paymentNumber || "Unknown Payment Number",
};

const indexes = {
  paymentsGeneral: GENERAL_PAYMENTS_DESC_DEFINERS,
};

export const paymentsProcessing = ({ payments }) => {
  const paymentObject = structuredClone(payments);
  const myPayment = {
    accountName: paymentObject?.accountName,
    clientBalance: paymentObject?.clientBalance || 0,
    createdAt: paymentObject?.createdAt,
    createdBy: paymentObject?.createdBy?.name,
    currentDueAmount: paymentObject?.currentDueAmount || 0,
    newCustomerBalance: paymentObject?.newCustomerBalance || 0,
    credits: paymentObject?.credits || 0,
    newDueAmount: paymentObject?.newDueAmount || 0,
    paymentAmount: paymentObject?.appliedAmount || 0,
    paymentDate: paymentObject?.paymentDate,
    paymentMethod: paymentObject?.paymentMethod,
    paymentNumber: paymentObject?.paymentNumber,
  };
  return myPayment;
};

export const getMyPaymentReceived = ({ paymentsType }) => {
  const payment = new Payments(paymentsType);
  return payment
    .getApplicableKeys()
    ?.map((el) => mapKeysPayments[el] || el)
    .filter(Boolean);
};

// USED T0 MAKE SENSE OF THE DATA

export const mapKeysPayments = {
  accountName: "Account Name",
  clientBalance: "Client Balance",
  createdAt: "Created At",
  createdBy: "Created By",
  currentDueAmount: "Current Due Amount",
  newCustomerBalance: "New Customer Balance",
  credits: "Credits",
  newDueAmount: "New Due Amount",
  paymentAmount: "Payment Amount",
  paymentDate: "Payment Date",
  paymentMethod: "Payment Method",
  paymentNumber: "Payment Number",
  ///
};
