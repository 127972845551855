import React from "react";
import { read } from "xlsx";
import { Form, Upload } from "antd";
import { useSelector } from "react-redux";

import UploadStepMessage from "./UploadStepMessage";
import { showWarningMsg } from "../../../../../utils";
import { convertExcelObjectToCustomObject } from "../../utils";
import { useUploadRecordsContext } from "../../context/UploadRecordsContext";

import "./UploadStep.scss";

const { Dragger } = Upload;

const UploadStep = ({ rowData }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const { title, setUploadedRecords, setRecordsConflicts } =
    useUploadRecordsContext();

  const category = topicCategories.find(
    (category) => category.categoryName === title
  );

  const { rowName } = category || {};

  return (
    <div
      className={`upload-records-step ${
        isDarkMode && "upload-records-step-dark"
      }`}
    >
      <Dragger
        {...{
          name: "file",
          accept: ".xlsx",
          multiple: true,
          customRequest({ file, onSuccess, onError }) {
            let fileReader = new FileReader();

            fileReader.addEventListener("load", () => {
              let { Sheets } = read(fileReader.result, {
                sheets: "Sheet1",
              });
              if (Sheets.Sheet1) {
                const parsedData = convertExcelObjectToCustomObject(
                  Sheets.Sheet1
                );

                let newRecordsUploaded = [];
                let newRecordsConflicts = [];
                let existingRecords = [];

                for (let record of parsedData) {
                  if (!Object.keys(record)?.includes("undefined")) {
                    const conflictData = rowData.find(
                      (data) => data?.[rowName] === record?.[rowName]
                    );
                    if (!!conflictData) {
                      newRecordsConflicts.push({
                        ...record,
                        [category.primaryKey]:
                          conflictData[category.primaryKey],
                      });
                      existingRecords.push(conflictData);
                    } else {
                      newRecordsUploaded.push(record);
                    }
                  }
                }

                if (!newRecordsUploaded.length)
                  showWarningMsg({
                    content: "No records are available for upload.",
                  });

                setUploadedRecords(newRecordsUploaded);
                setRecordsConflicts({
                  existingRecords: existingRecords,
                  newRecords: newRecordsConflicts,
                });
                return onSuccess();
              } else {
                message.error("Invalid File!");
                return onError();
              }
            });

            fileReader.readAsArrayBuffer(file);
          },
        }}
      >
        <UploadStepMessage />
      </Dragger>
    </div>
  );
};

export default UploadStep;
