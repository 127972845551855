import { API } from "aws-amplify";

export const deleteNotification = async ({
  allNotifications,
  dispatch,
  recordId,
}) => {
  const filteredNotification = allNotifications.filter(
    (item) =>
      item?.notificationBody?.notificationObject?.actionData[1].recordId ===
      recordId
  );

  filteredNotification.map((notification) => {
    const { id } = notification;

    API.del("notifications", `/notifications/${id}`)
      .then(() => {
        dispatch(deleteNotification(id));
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
