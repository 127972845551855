const firstLevelKeys = [
  "companyName",
  "assignedTo",
  "requestType",
  "category",
  "createdBy",
  "responseFrom",
  "comments",
  "recordId",
  "projectManager",
];

export default firstLevelKeys;
