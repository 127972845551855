import { AgGridReact } from "ag-grid-react";
import { message, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  CloseIconModal,
  CloseIconModalHeader,
  OpenedEyeIcon,
} from "../../../../../../../../../../../../assets";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import { retrieveProcessedRental } from "./utils/processRental";
import "./Preview.scss";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { formatCurrency } from "../../../../../../../../../../utils";
import { SaveIcon } from "../../../../../../../../../../BasePage/src";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { updateApplication } from "../../../../../../../../../../../../actions/applications";

import { updateDocumentTitle } from "../../../../../../../../../../../../utils";
import { ReloadOutlined } from "@ant-design/icons";
import { useEditLogs } from "../../../../../../../../../../../../hooks";

export default function Preview() {
  const [isPreview, setIsPreview] = useState(false);
  const { fakeApplication, darkMode } = useStateContext();
  const [rowData, setRowData] = useState([]);
  const { applications } = useSelector((state) => state.applications);
  const dispatchContext = useDispatchContext();
  const dispatch = useDispatch();

  const { saveAddedLogs } = useEditLogs();

  const cellValueChanger = (e) => {
    const newApp = manualEditRentalExcel(e, fakeApplication);
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApp });
    dispatch(
      updateApplication(
        newApp?.applicationId,
        newApp,
        applications,
        saveAddedLogs
      )
    );
    // message.success("Rental Excel updated");
  };

  const resetExcelRentalCustom = () => {
    let newApplication = cloneDeep(fakeApplication);
    newApplication.rentalExcel = {};
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
    dispatch(updateApplication(newApplication?.applicationId, newApplication));
    message.success("Rental Excel reset");
  };

  useEffect(() => {
    if (fakeApplication && isPreview) {
      const processed = retrieveProcessedRental({
        fakeApplication,
        applications,
      });
      console.log("processed", processed);
      setRowData(processed);
      // return () => {
      //   setIsPreview(false);
      // };
    }
  }, [isPreview, fakeApplication, applications]);

  return (
    <>
      <Tooltip title="Preview">
        <button
          className="exportToButton"
          onClick={() => setIsPreview(true)}
          style={{ marginRight: 10 }}
          // disabled={rowData?.rowData?.length > 0}
        >
          <OpenedEyeIcon />
        </button>
      </Tooltip>
      <Modal
        title={"Previewing Amounts"}
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Previewing Amounts" });
        }}
        open={isPreview}
        centered
        wrapClassName={darkMode ? "rentalsPreviewDark" : "rentalsPreviewLight"}
        onCancel={() => {
          updateDocumentTitle(); // reset document title to "Lead Manager";
          setIsPreview(false);
        }}
        width={"100%"}
        // closeIcon={<CloseIconModalHeader />}
        footer={
          <MondayButton
            Icon={<ReloadOutlined />}
            onClick={resetExcelRentalCustom}
          >
            Regenerate
          </MondayButton>
        }
      >
        <div className="includedRentals-preview">
          <div
            className={
              darkMode
                ? "body agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
                : "body agGridRentalsDetailsStyleLight ag-theme-alpine"
            }
          >
            <AgGridReact
              suppressSizeToFit={false}
              rowSelection="multiple"
              paginationPageSize={14}
              rowHeight={50}
              columnDefs={columnDefs || {}}
              rowData={rowData?.rowData || []}
              onCellValueChanged={cellValueChanger}
              suppressDragLeaveHidesColumns={true}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

const columnDefs = [
  {
    headerName: "No.",
    field: "id",
    resizable: true,
    flex: 1,
    width: 50,
  },
  {
    headerName: "Monthly Rentals",
    field: "fullDescription",
    resizable: true,
    minWidth: 800,
    editable: (params) => {
      return params?.data?.thisPeriod !== 0;
    },
    //   cellRenderer: (params) => {
    //     if (!!fakeApplication.rentalExcel[params.data.groupId]) {
    //       return fakeApplication.rentalExcel[params.data.groupId];
    //     } else return params.value;
    //   },
    flex: 12,
  },
  {
    headerName: "Scheduled Value",
    field: "scheduledValue",
    resizable: true,
    cellRenderer: (params) => {
      return formatCurrency(Number(params.value || 0));
    },
    style: { textAlign: "right" },
    // valueGetter: (params) => {
    //   console.log("params", params);
    //   return formatCurrency(params.value);
    // },
    flex: 2,
  },
  {
    headerName: "From Previous",
    field: "fromPrevious",
    resizable: true,
    cellRenderer: (params) => {
      return formatCurrency(Number(params.value || 0));
    },
    flex: 2,
  },
  {
    headerName: "This Period",
    field: "thisPeriod",
    resizable: true,
    cellRenderer: (params) => {
      return formatCurrency(Number(params.value || 0));
    },
    flex: 2,
  },
  {
    headerName: "Retainage",
    field: "retainage",
    resizable: true,
    cellRenderer: (params) => {
      return formatCurrency(Number(params.value || 0));
    },
    flex: 2,
  },
];

const manualEditRentalExcel = (params, fakeApplication) => {
  let newApplication = cloneDeep(fakeApplication);
  let rentalExcel = fakeApplication.rentalExcel || {};
  rentalExcel[params.data.groupId] = params.newValue;
  newApplication.rentalExcel = rentalExcel;
  console.log("newApplication", newApplication);
  return newApplication;
};
