import createPDF from "../../../../../../../integrations/createPDF";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../../../../pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { GOOGLE_API_KEY } from "../../../../../Scheduling/Tabs/SchedulingMap/schedulingMapData";
import { blobToBase64, compareIncluding } from "../../../../../utils";
import { formatNumber } from "../../../../../utils";
import { Dayjs } from "dayjs";

/** @typedef {{lat: number, lng: number}} LatLng */

/** @typedef {"Yard"|"Schedule"|"Off Project"|"Off Schedule"|"Vendor"} DestinationType */

/**
 * @typedef ItineraryType
 * @property {string} pickUpLocation
 * @property {string} dropOffLocation
 * @property {Dayjs|null} departAt
 * @property {Dayjs|null} arriveBy
 * @property {number|null} routeLength
 * @property {number|null} duration
 * @property {number} index
 * @property {string} formattedDistance
 * @property {string} formattedDuration
 * @property {google.maps.TravelMode} travelMode
 * @property {DestinationType} destinationType
 * @property {string|null} destinationId
 * @property {string|null} destinationName
 * @property {google.maps.DirectionsWaypoint[]} waypoints
 * @property {string|null} overview_polyline
 * @property {LatLng|null} pickUpCoordinates
 * @property {LatLng|null} dropOffCoordinates
 */

function getDuration(durationText = "") {
  let h = durationText?.split(":")[0];
  let m = durationText?.split(":")[1];
  let text = "";
  if (!isNaN(parseInt(h))) {
    if (parseInt(h)) {
      text += formatNumber(h, { unit: "hour" });
    }
  }
  text += ` ${formatNumber(m, { unit: "minute" })}`;
  return text;
}

async function getRouteDirection({ origin, destination }) {
  const directionService = new google.maps.DirectionsService();
  const results = await directionService.route({
    origin,
    destination,
    travelMode: google.maps.TravelMode.DRIVING,
    avoidFerries: true,
    avoidTolls: true,
    avoidHighways: true,
  });

  return results;
}

export async function generateRouteImage({
  origin = { lat: 39.522580790829615, lng: -87.11400761420286 },
  destination = { lat: 39.52221664836268, lng: -87.1085573654846 },
}) {
  const apiUrl = "https://maps.googleapis.com/maps/api/staticmap";

  const googleMapLink = `https://www.google.com/maps/dir/${origin?.lat},+${origin?.lng}/${destination?.lat},+${destination?.lng}`;
  const distance = {};

  let imageLink = await getRouteDirection({ origin, destination })
    .then(async (directionsData) => {
      if (directionsData?.status === "OK") {
        const route = directionsData?.routes?.[0]?.overview_polyline;
        Object.assign(
          distance,
          directionsData?.routes?.[0]?.legs?.[0]?.distance
        );
        const params = new URLSearchParams({
          size: "600x400",
          markers: `color:red|label:A|${origin?.lat}, ${origin?.lng}`,
          path: `color:0x0000ff|weight:5|enc:${route}`,
          key: GOOGLE_API_KEY,
        });

        const markerParam = new URLSearchParams({
          markers: `color:red|label:B|${destination?.lat}, ${destination?.lng}`,
        });

        const mapImageUrl = `${apiUrl}?${params.toString()}&${markerParam.toString()}`;
        try {
          const r = await fetch(mapImageUrl);
          const data = await r.blob();
          const res = await blobToBase64(data);
          return res;
        } catch (err) {
          console.log("Error creating base 64: ", err);
        }
      }
    })
    .catch((err) => {
      console.log("There was an error getting directionsData: ", err);
    });
  return { imageLink, googleMapLink, distance };
}

export function dispatchDocDefinition({
  title,
  dynamicPdf,
  companyLogo,
  mainData,
}) {
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: `${title ? title : ""}`,
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
            style: ["strong", "large"],
          },
          {
            text: `Date : ${dayjsNY().format(DEG_DATE_FORMAT)}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
              <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
              </svg>`,
        width: 555,
        height: 2,
        style: "subheader",
      },
      {
        columns: [
          {
            text: "Dispatch By:",
            alignment: "left",
            style: ["lastColumn", "strong"],
          },
          {
            text: mainData?.dispatchedBy,
            alignment: "left",
            style: ["lastColumn"],
          },
        ],
      },
      {
        columns: [
          {
            text: "Created At:",
            alignment: "left",
            style: ["lastColumn", "strong"],
          },
          {
            text: dayjsNY(mainData?.createdAt).format(
              `${DEG_DATE_FORMAT} ${DEG_TIME_FORMAT}`
            ),
            alignment: "left",
            style: ["lastColumn"],
          },
        ],
      },
      {
        columns: [
          {
            text: "Dispatch Date:",
            alignment: "left",
            style: ["lastColumn", "strong"],
          },
          {
            text: dayjsNY(mainData?.dispatchDate).format(DEG_DATE_FORMAT),
            alignment: "left",
            style: ["lastColumn"],
          },
        ],
      },
      {
        columns: [
          {
            text: "Vehicle #:",
            alignment: "left",
            style: ["lastColumn", "strong"],
          },
          {
            text: mainData?.fleetName,
            alignment: "left",
            style: ["lastColumn"],
          },
        ],
      },
      {
        columns: [
          {
            text: "Status:",
            alignment: "left",
            style: ["lastColumn", "strong"],
          },
          {
            text: mainData?.status,
            alignment: "left",
            style: ["lastColumn"],
          },
        ],
      },
      mainData?.teamsConfiguration && {
        width: "10%",
        text: "Teams:",
        alignment: "left",
        style: ["lastColumn", "strong"],
      },
      ...(mainData?.teamsConfiguration || [])?.map((team) => {
        return {
          columns: [
            {
              width: "20%",
              text: team?.value,
              alignment: "left",
              style: ["lastColumn"],
            },
            {
              width: "80%",
              text: team.members
                ?.map(({ nameOfUser }) => nameOfUser)
                .join(team?.members?.length > 1 ? ", " : ""),
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        };
      }),
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      dynamicPdf?.flatMap((item) => item),
    ].filter(Boolean),
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 15],
      },
      subheader: { margin: [0, 0, 0, 5], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
      category: { fontSize: 10, color: "#4a4a4a" },
    },
  };
}

function getBaseColumns(body) {
  return [
    {
      text: body?.routeName,
      alignment: "left",
      style: ["strong", "lastColumn", "subheader"],
    },
    {
      columns: [
        {
          text: "Driver Name",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: body?.driverName,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Paperwork Collect Status",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: body?.paperworkCollectStatus,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Paperwork Software Status",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: body?.paperworkSoftwareStatus,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Paperwork Type",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: body?.paperworkType,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Cargo",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: body?.cargo,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Pick Up Location",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: body?.pickUpLocation,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Drop off Location",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: body?.dropOffLocation,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Depart At",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: dayjsNY(body?.timeExit).format(
            `${DEG_DATE_FORMAT} ${DEG_TIME_FORMAT}`
          ),
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Arrive By",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: dayjsNY(body?.arriveBy).format(
            `${DEG_DATE_FORMAT} ${DEG_TIME_FORMAT}`
          ),
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Distance",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: `${parseFloat(body?.routeLength * 0.000621371).toFixed(
            2
          )} miles`,
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
    {
      columns: [
        {
          text: "Duration",
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          text: getDuration(body?.timeScheduled),
          alignment: "left",
          style: ["lastColumn"],
        },
      ],
    },
  ];
}

async function getItineraryColumns(route) {
  /** @type {ItineraryType[]} */
  const itinerary = route.itinerary;

  let isInverse = false;

  if (
    itinerary[itinerary.length - 1]["dropOffLocation"] ===
    route["pickUpLocation"]
  ) {
    isInverse = true;
  }

  const steps = [];

  for (
    let i = isInverse ? itinerary.length - 1 : 0;
    isInverse ? i >= 0 : i < itinerary.length;
    isInverse ? i-- : i++
  ) {
    let step = itinerary[i];

    const params = new URLSearchParams({
      size: "200x200",
      markers: `color:red|label:${isInverse ? "B" : "A"}|${
        step.pickUpCoordinates.lat
      }, ${step.pickUpCoordinates.lng}`,
      path: `color:${
        step.travelMode === "DRIVING" ? "0x0000ff" : "orange"
      }|weight:5|enc:${step.overview_polyline}`,
      key: GOOGLE_API_KEY,
    });

    const markerParam = new URLSearchParams({
      markers: `color:red|label:${!isInverse ? "B" : "A"}|${
        step.dropOffCoordinates.lat
      }, ${step.dropOffCoordinates.lng}`,
    });

    const mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?${params.toString()}&${markerParam.toString()}`;
    try {
      const b64 = await fetch(mapImageUrl)
        .then(async (r) => await r.blob())
        .then(async (r) => await blobToBase64(r))
        .then((r) => r);

      steps.push({
        columns: [
          {
            text: `From: ${
              isInverse ? step.dropOffLocation : step.pickUpLocation
            }\n\n To: ${
              !isInverse ? step.dropOffLocation : step.pickUpLocation
            }`,
            alignment: "left",
            style: ["lastColumn", "strong"],
          },
          {
            alignment: "left",
            image: b64,
            link: mapImageUrl,
            fit: [170, 170],
          },
        ],
      });
    } catch (err) {
      console.log("Error getting base 64: ", err);
    }
  }

  return [...getBaseColumns(route), ...steps];
}

async function pdfDispatchBorderedSection({ body = [], noColor = false }) {
  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };

  async function formattedBody() {
    let columnsToReturn = await generateRouteImage({
      origin: body?.pickUpCoordinates,
      destination: body?.dropOffCoordinates,
    }).then((res) => {
      return [
        ...getBaseColumns(body),
        {
          image: res.imageLink,
          fit: [530, 400],
        },
      ];
    });
    return columnsToReturn;
  }

  // const formattedBodyData = await formattedBody();
  let formattedBodyData;
  if (!body?.itinerary?.length) {
    formattedBodyData = await formattedBody();
  } else {
    formattedBodyData = await getItineraryColumns(body);
  }

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: formattedBodyData.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
}

async function configPdfData({ title, body }) {
  return await Promise.all(
    body.routes.map((route) =>
      pdfDispatchBorderedSection({
        title,
        body: {
          ...route,
          itinerary:
            route?.itinerary && route?.itinerary?.length
              ? route?.itinerary
              : body?.routes?.find(
                  /**
                   * @param {Object} param0
                   * @param {ItineraryType[]} param0.itinerary
                   */
                  ({ itinerary = [] }) =>
                    (itinerary?.[0]?.pickUpLocation === route?.pickUpLocation &&
                      itinerary?.[itinerary.length - 1]?.dropOffLocation ===
                        route?.dropOffLocation) ||
                    (itinerary?.[itinerary.length - 1]?.dropOffLocation ===
                      route?.pickUpLocation &&
                      itinerary?.[0]?.pickUpLocation === route?.dropOffLocation)
                )?.itinerary || [],
        },
      })
    )
  ).then((res) => res);
}

export async function generateDispatchPdf({ body, base64 }) {
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  createPDF({
    action: "open",
    docDefinition: dispatchDocDefinition({
      title: "Dispatch",
      dynamicPdf: await configPdfData({ title: "Dispatch", body }),
      companyLogo: (companyLogo = companyLogo),
      mainData: body,
    }),
  });
}
