/**
 *
 * @param {Array} projects - the projects data fetched from the server
 * @param {String} recordId - the id of the record
 * @param {String} requestType - the type of the request
 * @param {Function} dispatchRequestData - the pointer to the dispatch function of the request reducer
 */
const binOptionsPopulate = ({
  projects = [],
  recordId = null,
  requestType = "",
  dispatchRequestData = () => {},
}) => {
  /**
   * If the requestType is not Estimation or if there are no projects, we return
   */
  if (requestType !== "Estimation" || !projects?.length) return;

  const BIN =
    (projects || [])?.find((el) => el?.projectId === recordId)?.binNumber ||
    null;

  if (!!BIN) {
    dispatchRequestData({
      type: "BIN",
      payload: BIN,
    });
  }
};

export default binOptionsPopulate;
