const columnDefs = (x) => {
  return [
    {
      headerName: "ESTIMATE",
      field: "estimate",
    },
    {
      headerName: "SCOPE OF WORK",
      field: "service",
    },
    {
      headerName: "ELEVATION",
      field: "elevation",
    },
    {
      headerName: "PLI",
      field: "pli",
    },
    {
      headerName: "L (ft)",
      field: "l",
    },
    {
      headerName: "H (ft)",
      field: "h",
    },
    {
      headerName: "W (ft)",
      field: "w",
    },
    {
      headerName: "SQFT (ft)",
      field: "sqf",
    },

    {
      headerName: "ITEM (LF)",
      field: "item",
    },
    {
      headerName: `REMAINING (LF)`,
      field: "remainInstall",
    },
    {
      headerName: "ITEM (%)",
      field: "itemPerc",
    },
    {
      headerName: `REMAINING (%)`,
      field: "remainInstallPerc",
    },
  ];
};

export default columnDefs;
