import AmountReq from "../../../ServiceContent/GridData/GridUtils/amountFactory";

//this finds checkbox value
export const compleatedHandler = ({ fakeApplication, service }) => {
  // here we find the object of the service that the checkbox is on
  const data = fakeApplication?.services?.find(
    (servicesData) =>
      servicesData?.serviceId === service?.serviceId &&
      servicesData?.estimationId === service?.estimationId
  );

  return {
    //now if in some of the events the balance needed to finish is 0, it means some of the checkbox are checked and it returns true
    someChecked: data?.amounts?.some(
      ({ balanceToFinish }) => Math?.floor?.(balanceToFinish) === 0
    ),
    //now if in all of the events the balance needed to finish is 0, it means some of the checkbox are checked and it returns true
    allChecked: data?.amounts?.every(
      ({ balanceToFinish }) => Math?.floor?.(balanceToFinish) === 0
    ),
    //now if in all of the events are charged, and if they are returns true
    charged: data?.amounts?.every(({ charged }) => !!charged),
  };
};

//elevationCalculator
const serviceOptionsCalculator = ({
  checkboxToggle,
  serviceOptions,
  retainagePercentage,
}) => {
  return serviceOptions?.map((elevationData) => ({
    ...elevationData,
    items: elevationData?.items?.map((pliData) => {
      const { fromPrevious, amount } = pliData || {};
      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
      //else if we want to uncheck all this period will be 0
      const thisPeriod = !!checkboxToggle ? amount - fromPrevious : 0;
      //if the we want to check all this period is calculated by (the hole amount for this event)
      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
      const totalCompleated = !!checkboxToggle ? amount : fromPrevious;
      //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
      const percentageCompleated = !!checkboxToggle
        ? ((thisPeriod + fromPrevious) / amount) * 100
        : (fromPrevious / amount) * 100;
      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
      //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
      const balanceToFinish = !!checkboxToggle
        ? amount - fromPrevious - thisPeriod
        : amount - fromPrevious;
      //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
      const retainage = (totalCompleated * retainagePercentage) / 100;

      return {
        //here we return all keys unchanged
        ...pliData,
        //expect those keys that we calculated above
        thisPeriod,
        retainage,
        totalCompleated,
        percentageCompleated,
        balanceToFinish,
      };
    }),
  }));
};

//addons calculator
const serviceAddonsCalculator = ({
  checkboxToggle,
  serviceAddons,
  retainagePercentage,
}) =>
  serviceAddons?.map((addonsData) => {
    const { fromPrevious, amount } = addonsData || {};
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
    //else if we want to uncheck all this period will be 0
    const thisPeriod = !!checkboxToggle ? amount - fromPrevious : 0;
    //if the we want to check all this period is calculated by (the hole amount for this event)
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
    const totalCompleated = !!checkboxToggle ? amount : fromPrevious;
    //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
    const percentageCompleated = !!checkboxToggle
      ? ((thisPeriod + fromPrevious) / amount) * 100
      : (fromPrevious / amount) * 100;
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
    //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
    const balanceToFinish = !!checkboxToggle
      ? amount - fromPrevious - thisPeriod
      : amount - fromPrevious;
    //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    const retainage = (totalCompleated * retainagePercentage) / 100;

    return {
      //here we return all keys unchanged
      ...addonsData,
      //expect those keys that we calculated above
      thisPeriod,
      retainage,
      totalCompleated,
      percentageCompleated,
      balanceToFinish,
    };
  });

//amount calculator
export const amountsCalculator = ({ checkboxToggle, amounts, service }) =>
  amounts?.map((amountsData) => {
    const {
      fromPrevious = 0,
      amount,
      charged,
      retainagePercentage,
      reducedBalance = 0,
    } = amountsData || {};
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
    //else if we want to uncheck all this period will be 0
    const thisPeriod = !!checkboxToggle ? amount - fromPrevious : 0;
    //if the we want to check all this period is calculated by (the hole amount for this event)
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
    const totalCompleated = !!checkboxToggle ? amount : fromPrevious;
    //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
    const percentageCompleated = !!checkboxToggle
      ? ((thisPeriod + fromPrevious) / amount) * 100
      : (fromPrevious / amount) * 100;
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
    //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
    const balanceToFinish = !!checkboxToggle
      ? amount - fromPrevious - thisPeriod
      : amount - fromPrevious;
    //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    const retainage = (totalCompleated * retainagePercentage) / 100;
    // kept retainage
    const keptRetainage = (thisPeriod * retainagePercentage) / 100;
    const paymentDue = thisPeriod - keptRetainage + reducedBalance;

    const toReturn = {
      ...amountsData,
      paymentDue,
      //here we set the new this period
      thisPeriod,
      //here we set the new total compleated
      totalCompleated,
      //here we set the new percentage compleated
      percentageCompleated,
      //here we set the new balance to finish
      balanceToFinish,
      retainage,
      keptRetainage,
      fromPrevious,
      //also we have to modify the serviceOptions key (we do that with serviceOptionsCalculator function)
      // serviceOptions: serviceOptionsCalculator({
      //   checkboxToggle,
      //   serviceOptions: amountsData?.serviceOptions,
      //   retainagePercentage,
      // }),
      // //also we have to modify the serviceAddons key (we do that with serviceAddonsCalculator function)
      // serviceAddons: serviceAddonsCalculator({
      //   checkboxToggle,
      //   serviceAddons: amountsData?.serviceAddons,
      //   retainagePercentage,
      // }),
    };
    let myAmount = new AmountReq(toReturn);
    myAmount.chargeThisPeriod(thisPeriod, _, service);

    return charged //if this pli is charged return it unchanged
      ? amountsData //unchangedData
      : //else if its not charged and checkboxToggle is true do this
        {
          ...(myAmount?.getData() || {}),
        };
  });

//this function calculates the new values of application
export const applicationHandler = ({
  fakeApplication,
  checkboxToggle,
  service,
}) => ({
  ...fakeApplication,
  //here we access only the service key bcs thats the only key that we want to change
  services: fakeApplication?.services?.map((serviceData) => {
    const { amounts } = serviceData || {};

    //if this is the service that we want to change access and modify it
    return serviceData?.serviceId === service?.serviceId &&
      serviceData?.estimationId === service?.estimationId
      ? {
          ...serviceData,
          //in the service object amount key is the only key that we want to modify, we modify it with amountsCalculator function
          amounts: amountsCalculator({
            checkboxToggle,
            amounts,
            service: serviceData,
          }),
        }
      : //else if this is not the service return it unchanged
        serviceData;
  }),
});

export const completeAllHandler = ({
  fakeApplication,
  dispatchContext,
  allChecked,
  service,
}) => {
  //this tells the function what to do (if all pli's all checked do the function for uncheck and reverse)
  const checkboxToggle = !allChecked;

  //the wen calculated application
  const newApplications = applicationHandler({
    fakeApplication,
    checkboxToggle,
    service,
  });

  //this saves the new completed events to the main state context
  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplications });
  //this saves the new completed events to the details state context
  dispatchContext({
    type: "SET_UNCHANGING_FAKE_APPLICATION",
    payload: newApplications,
  });
};
