import uniq from "lodash/uniq";
export const dispatching = ({ tables }) => {
  const allDrivers = tables?.Dispatching?.map((a) =>
    a?.routes?.map((e) => e?.driverName)
  )?.flat();
  return {
    driverName: () => ({
      content: uniq(allDrivers)?.map((driverName, index) => ({
        key: index,
        title: driverName,
        taskNumber: allDrivers?.length,
        taskDone: allDrivers?.filter(
          (a) => a?.toLowerCase() === driverName?.toLowerCase()
        )?.length,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Drove By " + driverName,
        contentsHandler: " Drove By " + driverName,
      })),
    }), //driverName

    dispatchedBy: () => ({
      content: uniq(
        tables?.Dispatching?.map((a) =>
          a?.dispatchedBy?.name ? a?.a?.dispatchedBy?.name : a?.dispatchedBy
        )
      )?.map((dispatchedBy, index) => ({
        key: index,
        title: dispatchedBy,
        taskNumber: tables?.dispatching?.length,
        taskDone: tables?.dispatching?.filter((a) =>
          a?.dispatchedBy?.name
            ? a?.a?.dispatchedBy?.name?.toLocaleLowerCase()
            : a?.dispatchedBy?.toLocaleLowerCase() ===
              dispatchedBy.toLocaleLowerCase()
        )?.length,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Dispatched By " + dispatchedBy,
        contentsHandler: " Dispatched By " + dispatchedBy,
      })),
    }), //dispatchedBy
  }; //Dispatching
};
