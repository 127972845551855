import "./RowDataGrid.scss";
import "../../../components/pages/Settings/settingsCommonComponents/styles/HeaderContentWrapper.scss";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import {
  CloseOutlined,
  InfoCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { generateColumnDefs } from "./components/AgGridData/generateColumnDefs";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { gridSidebar } from "src/utils";
import { defaultColDef } from "src/integrations/AgGridDatas";
import { camelCaseToNormalText } from "./components/utils";
import { keysForDates } from "../../../helpers/constants";
import { FilterIcon } from "../../SidebarPages/BasePage/src";
import FiltersModal from "./components/FiltersModal";
import MondayButton from "../MondayButton/MondayButton";
import AgGridPageInput from "../../SidebarPages/BasePage/components/AgGridPageInput/AgGridPageInput";
import ExportOrEmailDropdown from "../ExportOrEmailDropdown/ExportOrEmailDropdown";

const statusBar = {
  statusPanels: [
    { statusPanel: AgGridPageInput, key: "paginationInput", align: "right" },
  ],
};

const RowDataGrid = ({
  title,
  rowData,
  propColumnDefs,
  agGridProps = {},
  showHeader = true,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState();

  const columnDefs = generateColumnDefs({ propColumnDefs, rowData });

  const clearFilters = () => {
    gridApi?.setFilterModel(null);
    setAppliedFilters();
  };
  const hasFilters = appliedFilters && !!Object.keys(appliedFilters).length;

  return (
    <div className="rowData-grid">
      <div
        className={`HeaderContentWrapper${
          isDarkMode ? " HeaderContentWrapper-dark" : ""
        }`}
      >
        {showHeader && (
          <div className="HeaderContentWrapper-header">
            <div className="header-left">
              {title && (
                <div className="title-content">
                  <InfoCircleFilled
                    style={{ fontSize: 24, color: "#1264A3" }}
                  />
                  <p>{title}</p>
                </div>
              )}
              <InputComponent
                {...{
                  placeholder: "Search",
                  onChange: (event) => {
                    gridApi.setQuickFilter(event.target.value);
                  },
                  noFormItem: true,
                  addonBefore: <SearchOutlined />,
                }}
              />
            </div>
            <div className="header-right">
              <ExportOrEmailDropdown
                {...{ rowData, title, gridApi, gridColumnApi }}
              />

              {!!hasFilters && (
                <MondayButton
                  className="mondayButtonYellow"
                  Icon={<CloseOutlined />}
                  onClick={clearFilters}
                >
                  Clear Filters
                </MondayButton>
              )}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => setShowFilterModal(true)}
                Icon={<FilterIcon />}
                tooltipKey="openFilters"
              >
                Filters
              </MondayButton>
            </div>
          </div>
        )}
        <div className="HeaderContentWrapper-content">
          <div
            className={`agGridTable ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData.sort((a, b) =>
                a.hasOwnProperty("createdAt")
                  ? b?.createdAt - a?.createdAt
                  : b?.updatedAt - a?.updatedAt
              )}
              pagination={true}
              paginationPageSize={10}
              onGridReady={({ api, columnApi }) => {
                setGridApi(api);
                setGridColumnApi(columnApi);
              }}
              defaultColDef={defaultColDef}
              sideBar={gridSidebar}
              rowGroupPanelShow="always"
              groupDisplayType="groupRows" //remove values of other columns when grouping
              enableRowGroup={true}
              suppressCellFocus={true} //remove cell selection
              statusBar={statusBar}
              onGridSizeChanged={({ api }) => {
                api.sizeColumnsToFit();
              }}
              {...agGridProps}
            />
          </div>
          {showFilterModal && (
            <FiltersModal
              {...{
                title,
                showFilterModal,
                setShowFilterModal,
                appliedFilters,
                setAppliedFilters: (filters) => {
                  filters === "clearFilters"
                    ? clearFilters()
                    : setAppliedFilters(filters);
                },
                gridApi,
                filtersObject: columnDefs
                  .sort((a, b) =>
                    keysForDates.includes(a.field)
                      ? -1
                      : keysForDates.includes(b.field)
                      ? 1
                      : 0
                  )
                  .map(({ field }) => ({
                    label: camelCaseToNormalText(field),
                    type: keysForDates.includes(field)
                      ? "RangePicker"
                      : "Dropdown",
                    key: field,
                  })),
                rowData,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RowDataGrid;
