import { UserOutlined } from "@ant-design/icons";

export const googleScopesDescription = {
  "https://www.googleapis.com/auth/calendar": {
    desc: "View and manage your calendars",
    category: "Google Calendar",
  },
  "https://www.googleapis.com/auth/calendar.readonly": {
    desc: "View your calendars",
    category: "Google Calendar",
  },
  "https://www.googleapis.com/auth/calendar.events": {
    desc: "View and edit events on all your calendars",
    category: "Google Calendar",
  },
  "https://www.googleapis.com/auth/calendar.events.readonly": {
    desc: "View events on all your calendars",
    category: "Google Calendar",
  },
  "https://www.googleapis.com/auth/drive": {
    desc: "See, edit, create, and delete all of your Google Drive files",
    category: "Google Drive",
  },
  "https://www.googleapis.com/auth/drive.file": {
    desc: "See, edit, create, and delete only the specific Google Drive files you use with this app",
    category: "Google Drive",
  },
  "https://www.googleapis.com/auth/drive.metadata": {
    desc: "View and manage metadata of files in your Google Drive",
    category: "Google Drive",
  },
  "https://www.googleapis.com/auth/drive.metadata.readonly": {
    desc: "See information about your Google Drive files",
    category: "Google Drive",
  },
  "https://www.googleapis.com/auth/drive.readonly": {
    desc: "See and download all your Google Drive files",
    category: "Google Drive",
  },
  email: {
    desc: "See your primary Google Account email address",
    category: "Google Account",
  },
  "https://www.googleapis.com/auth/drive.appdata": {
    desc: "View and manage its own configuration data in your Google Drive",
    category: "Google Drive",
  },
  "https://www.googleapis.com/auth/drive.photos.readonly": {
    desc: "View the photos, videos and albums in your Google Photos",
    category: "Google Drive",
  },
  "https://www.googleapis.com/auth/drive.apps.readonly": {
    desc: "View the files in your Google Drive",
    category: "Google Drive",
  },
  "https://www.googleapis.com/auth/gmail.modify": {
    desc: "Read, compose, and send emails from your Gmail account",
    category: "Gmail",
  },
  "https://www.googleapis.com/auth/gmail.insert": {
    desc: "Add emails into your Gmail mailbox",
    category: "Gmail",
  },
  "https://www.googleapis.com/auth/gmail.compose": {
    desc: "Manage drafts and send emails",
    category: "Gmail",
  },
  "https://www.googleapis.com/auth/gmail.readonly": {
    desc: "View your emails",
    category: "Gmail",
  },
  "https://mail.google.com/": {
    desc: "View and manage your mail",
    category: "Gmail",
  },
  "https://www.googleapis.com/auth/spreadsheets": {
    desc: "View and manage your spreadsheets in Google Drive",
    category: "Google Spreadsheets",
  },
  "https://www.googleapis.com/auth/spreadsheets.readonly": {
    desc: "View your Google Spreadsheets",
    category: "Google Spreadsheets",
  },
};

export const googleScopesIcons = {
  "Google Calendar":
    "https://www.google.com/images/icons/product/calendar-32.png",
  "Google Drive":
    "https://ssl.gstatic.com/docs/doclist/images/drive_icon_32.png",
  "Google Account": <UserOutlined fill={"#3873e8"} className="accountIcon" />,
  Gmail: "https://www.google.com/images/icons/product/googlemail-32.png",
  "Google Spreadsheets":
    "https://www.google.com/images/icons/product/docs-32.png",
};
