import { SimpleAddon } from "../../models/GeneralModels";
import { AddonType } from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { SidewalkShedPLIAddon } from "../../models/SidewalkShedModels";

export function createPLIAddon(id) {
  const addon = new SimpleAddon(id);
  addon.type = AddonType.pli;
  return addon;
}

export function createSideWalkShedPLIAddon(id, length, height, width) {
  const addon = new SidewalkShedPLIAddon(id);
  addon.type = AddonType.pli;
  addon.length = length;
  addon.height = height;
  addon.width = width;
  return addon;
}

export function createServiceAddon(id) {
  const addon = new SimpleAddon(id);
  addon.type = AddonType.serviceAddon;
  return addon;
}
