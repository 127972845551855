import { Switch, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useContext, useMemo, useState } from "react";
import { InfoWindowF } from "@react-google-maps/api";

import { InfoIcon } from "src/assets";
import PayrollLiveContext from "../../PayrollLiveContext";
import {
  StoreType,
  JobsiteType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { formatNumber } from "src/components/SidebarPages/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { EmployeeReportType, ScheduleType } from "../../payrollLiveTypes";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { withinRadius } from "../../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { DEG_TIME_FORMAT } from "../../../Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./EmployeeInfo.scss";
interface Props {
  color: string;
  onClick: () => void;
  data: EmployeeReportType & { isForeman?: boolean };
}

function EmployeeInfo(props: Props) {
  const { data, color, onClick } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const { schedules, setEmployeesReport, setDegEntries, jobsites, mapRef } =
    useContext(PayrollLiveContext);

  const [distanceTime, setDistanceTime] = useState<string | null>(null);

  function onScheduleSelect(projectId: string) {
    const updatedEntryReport = {
      ...data,
      projectId,
      color: !!projectId ? undefined : "#e9c466",
    };
    const jobIndex = jobsites.findIndex((el) => el.projectId === projectId);
    setEmployeesReport((prev) =>
      prev.map((el) =>
        el?.punchId === data?.punchId ? updatedEntryReport : el
      )
    );

    setDegEntries((prev) =>
      prev.map((el) => {
        const reportEntry =
          dayjsNY(el.punchTime).format(DEG_TIME_FORMAT) ===
            dayjsNY(data?.punchTime).format(DEG_TIME_FORMAT) &&
          el.employeeId === `${data.company}-${Number(data.employeeNumber)}`;

        const job = jobsites?.[jobIndex];

        if (reportEntry) {
          return {
            ...el,
            projectId,
            jobsiteId: job?.jobsiteId,
            jobsiteMatch: {
              services: job?.services || [],
              jobName: job?.jobName,
              jobsiteId: job?.jobsiteId,
              jobAddress: job?.jobAddress,
              reimbursement: job.reimbursement,
            },
          };
        } else {
          return el;
        }
      })
    );
  }

  function onShowRoute() {
    const jobIndex = jobsites.findIndex(
      (el) => el?.projectId === data?.projectId
    );
    if (jobIndex > -1) {
      mapRef.current.getDirections({
        origin: data.punchCoordinates,
        destination: jobsites[jobIndex].addressPosition,
        travelMode: google.maps.TravelMode.WALKING,
      });
    }
  }

  async function directionModeToggle(e: boolean) {
    const jobIndex = jobsites.findIndex(
      (el) => el?.projectId === data?.projectId
    );
    const directions = await mapRef.current.getDirections({
      origin: data.punchCoordinates,
      destination: jobsites[jobIndex].addressPosition,
      travelMode: e
        ? google.maps.TravelMode.DRIVING
        : google.maps.TravelMode.WALKING,
    });
    setDistanceTime(directions?.routes?.[0]?.legs?.[0]?.duration?.text);
  }

  function getJobOptions({
    data,
    empCoordinates,
  }: {
    data: Array<JobsiteType | ScheduleType>;
    type: "jobsites" | "schedules";
    empCoordinates: CoordinateType;
  }) {
    const options = data
      .sort((a, b) => {
        const positionA = a?.addressPosition?.lat
          ? a?.addressPosition
          : { lat: 0, lng: 0 };
        const positionB = b?.addressPosition?.lat
          ? b?.addressPosition
          : { lat: 0, lng: 0 };

        const distanceA = withinRadius(
          positionA,
          empCoordinates
        )?.distanceInMile;
        const distanceB = withinRadius(
          positionB,
          empCoordinates
        )?.distanceInMile;

        return distanceA - distanceB;
      })
      .map(
        ({
          scheduleAddress,
          jobAddress,
          projectId,
          addressPosition,
        }: ScheduleType & JobsiteType) => {
          let distance = withinRadius(
            addressPosition,
            empCoordinates
          )?.distanceInFeet;

          let lengthName = "Feet";

          if (distance >= 5280) {
            distance = Math.round(distance / 5280);
            lengthName = "Miles";
          }

          return {
            key: projectId,
            value: projectId,
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 10,
                }}
                key={scheduleAddress || jobAddress} // key used for search options
              >
                <div>{scheduleAddress || jobAddress}</div>
                {empCoordinates?.lat && (
                  <div
                    style={{
                      color: "grey",
                    }}
                  >
                    ({distance}) {lengthName}
                  </div>
                )}
              </div>
            ),
          };
        }
      );
    return options;
  }

  const customOptions = useMemo(() => {
    const empCoordinates = data.punchCoordinates;
    const scheduleOptions = getJobOptions({
      data: schedules.filter(
        (el: ScheduleType & JobsiteType) => !el?.jobsiteId
      ),
      type: "schedules",
      empCoordinates,
    });
    const jobOptions = getJobOptions({
      data: schedules.filter(
        (el: ScheduleType & JobsiteType) => !!el?.jobsiteId
      ),
      type: "jobsites",
      empCoordinates,
    });

    return [
      {
        label: "Schedules",
        options: scheduleOptions,
      },
      {
        label: "Jobsites",
        options: jobOptions,
      },
    ];
  }, [schedules, data]);

  const distance = useMemo(() => {
    const jobIndex = jobsites.findIndex(
      (el) => el?.projectId === data?.projectId
    );
    let lengthName = "ft";
    let duration = 0;

    let dst = withinRadius(
      data.punchCoordinates,
      jobsites?.[jobIndex]?.addressPosition
    ).distanceInFeet;

    duration = Math.round(dst / 5.13) / 60;
    if (dst >= 5280) {
      dst = Math.round(dst / 5280);
      lengthName = "Miles";
    }

    return { dst, lengthName, duration };
  }, [data?.punchCoordinates, data?.projectId, jobsites]);

  return (
    <InfoWindowF
      zIndex={3}
      position={data.punchCoordinates}
      key={data.employeeNumber + "info"}
      options={{ pixelOffset: new google.maps.Size(0, -36) }}
    >
      <section
        className={`employee-info-window ${
          darkMode ? "employee-info-window-dark" : ""
        }`}
        style={{ boxShadow: `0px 0px 4px 0px ${color || "#e9c466"}` }}
      >
        <div className="info-header">
          <span className="info-title">Employee</span>
          <XIcon onClick={onClick} width={12} height={12} />
        </div>
        <div className="info-body">
          <div className="location-information">
            <Tooltip title="Employee's Punch location">
              <InfoIcon width={14} height={14} />
            </Tooltip>
            <span>{data?.punchLocation}</span>
          </div>
          <div className="info-data">
            <label>
              {data.employeeName} {data?.isForeman ? <span style={{color: "#acacac"}}>(Foreman)</span> : ""}
            </label>
            <div className="employee-status">
              <div
                className="status-pin"
                style={{ backgroundColor: color || "#e9c466" }}
              ></div>
              <span>{data.liveStatus}</span>
            </div>
          </div>
          <div className="info-data">
            <div>{data?.crewTeamName}</div>
            <div>{parseInTz(data.punchTime).format("MM/DD/YYYY, hh:mm A")}</div>
          </div>
          {data?.projectId && data?.punchCoordinates?.lat ? (
            <div className="info-data">
              <div className="distance-data">
                <span>
                  Distance: {distance?.dst + " " + distance?.lengthName}
                </span>
                <div className="duration">
                  (
                  {distanceTime ??
                    formatNumber(distance.duration, { unit: "minute" })}{" "}
                  )
                </div>
              </div>
              <span>
                <Switch
                  checkedChildren="Driving"
                  unCheckedChildren="Walking"
                  onChange={directionModeToggle}
                />
              </span>
              <span onClick={onShowRoute} className="show-route">
                Show Route
              </span>
            </div>
          ) : null}

          {Object.hasOwn(data, "company") ? (
            <InputComponent
              allowClear
              type="select"
              label="Schedule"
              onSelect={onScheduleSelect}
              customOptions={customOptions}
              initialValue={data?.projectId}
              placeholder="Select schedule match"
              onClear={() => onScheduleSelect("")}
              filterOption={(
                input: string,
                option: { label: React.ReactElement }
              ) => {
                return (option?.label?.key || "")
                  .toLowerCase()
                  .includes((input || "").toLowerCase());
              }}
            />
          ) : null}
        </div>
      </section>
    </InfoWindowF>
  );
}

export default EmployeeInfo;
