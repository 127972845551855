import uniq from "lodash/uniq";
import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const fleet = (IdHandler, fleetData, updatedDynamicStates) => {
  return {
    fleetYear: () => ({
      title: "Fleet Year",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Fleet",
        field: "fleetYear",
        labels: uniq(fleetData?.map((a) => a?.fleetYear))?.sort(),
        datasets: [
          {
            label: "My First Dataset",
            data: uniq(fleetData?.map((a) => a?.fleetYear))
              ?.sort()
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) => a.fleetYear === status
                  )?.length
              ),
            backgroundColor: uniq(fleetData?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //fleetYear
    registrationExpDate: () => ({
      title: "Registration Expiration Date",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Fleet",
        field: "registrationExpDate",
        labels: months,
        datasets: [
          {
            label: "My First Dataset",
            data: (() => {
              let registrationExpDate = [];
              for (let i = 0; i < 12; i++) {
                registrationExpDate = [
                  ...registrationExpDate,
                  (updatedDynamicStates?.[IdHandler] || [])
                    ?.map((a) => a?.registrationExpDate)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length,
                ];
              }
              return registrationExpDate;
            })(),
            backgroundColor: months.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "400px",
    }), //registrationExpDate
    inspectionExpDate: () => ({
      title: "Inspection Expiration Date",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Fleet",
        field: "inspectionExpDate",
        labels: months,
        datasets: [
          {
            label: "My First Dataset",
            data: (() => {
              let data = [];
              for (let i = 0; i < 12; i++) {
                data = [
                  ...data,
                  (updatedDynamicStates?.[IdHandler] || [])
                    ?.map((a) => a?.inspectionExpDate)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length,
                ];
              }
              return data;
            })(),
            backgroundColor: months.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //inspectionExpDate
  }; //Fleet
};
