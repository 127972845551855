import RemoveItem from "../RemoveItem";
import TaxCheckBox from "../TaxCheckBox";
import AutoComplete from "../../AutoComplete";
import DescriptionInvoice from "../DescriptionInvoice";
import { formatCurrency } from "../../../../../../utils";
import { EditIcon } from "../../../../../../DynamicView/src";
import { useCreateInvoiceContext } from "../../../context/InvoiceModalContext";

const cellStyle = {
  width: "100%",
  textAlign: "center",
  fontWeight: 600,
  paddingRight: 20,
};

export const groupColumn = {
  headerName: "GROUP",
  field: "group",
  maxWidth: 350,
  cellRendererFramework: (props) => <div style={cellStyle}>{props.value}</div>,
};

export const columnDefs = [
  {
    headerName: "#",
    field: "id",
    maxWidth: 60,
    cellRendererFramework: (props) => (
      <div style={cellStyle}>{props.value}</div>
    ),
  },
  {
    headerName: "SERVICE",
    field: "name",
    cellRendererFramework: (props) => <AutoComplete {...props} />,
  },
  groupColumn,
  {
    headerName: "DESCRIPTION",
    field: "description",
    cellStyle: {
      padding: 0,
      margin: 0,
    },
    maxWidth: 440,
    height: 300,
    cellRendererFramework: DescriptionInvoice,
  },
  {
    headerName: "CREDITED MEMO",
    field: "creditedAmount",
    maxWidth: 180,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value: creditedAmount }) => (
      <div className={`editableCellContainer`} style={{ padding: "0 10px" }}>
        <div>{formatCurrency(creditedAmount || 0) || "$0.00"}</div>
      </div>
    ),
  },
  {
    headerName: "SCOPE AMOUNT",
    field: "scopeAmount",
    maxWidth: 180,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    editable: ({ data }) => {
      return data?.group === "Extra Service";
    },
    cellRendererFramework: ({ value, data }) => (
      <div className={`editableCellContainer`} style={{ padding: "0 10px" }}>
        <div>{formatCurrency(value || data?.amount || 0) || "$0.00"}</div>
      </div>
    ),
  },
  {
    headerName: "AMOUNT %",
    field: "amountPercentage",
    maxWidth: 160,
    editable: true,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value, data }) => (
      <div
        className={`editableCellContainer ${
          data?.category === "applications" || data?.includedRequisitionId
            ? "disabled"
            : ""
        }`}
        style={{ padding: "0 10px" }}
      >
        <div>{parseFloat(value || 0).toFixed(2) || 0}</div>
        <div className="editableCellIcon">
          <EditIcon />
        </div>
      </div>
    ),
  },
  {
    headerName: "AMOUNT",
    field: "amount",
    maxWidth: 160,
    editable: true,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: (params) => (
      <div
        className={`editableCellContainer ${
          params?.data?.category === "applications" ||
          params?.data?.includedRequisitionId
            ? "disabled"
            : ""
        }`}
        style={{ padding: "0 10px" }}
      >
        <div>{formatCurrency(params?.value, "USD")}</div>
        <div className="editableCellIcon">
          <EditIcon />
        </div>
      </div>
    ),
  },
  {
    headerName: "TAX AMOUNT",
    field: "taxAmount",
    maxWidth: 160,
    mutable: true,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value, data }) => {
      const { products } = useCreateInvoiceContext();
      const product = products?.find(({ id }) => id === data?.id);
      return (
        <div className="editableCellContainer" style={{ padding: "0 10px" }}>
          <div>{formatCurrency(product?.tax ? value || 0 : 0)}</div>
        </div>
      );
    },
  },
  {
    headerName: "TAX",
    field: "tax",
    maxWidth: 81,
    cellRendererFramework: TaxCheckBox,
  },
  {
    headerName: "Total",
    field: "total",
    maxWidth: 160,
    mutable: true,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value }) => (
      <div className="editableCellContainer" style={{ padding: "0 10px" }}>
        <div>{formatCurrency(value)}</div>
      </div>
    ),
  },
  {
    headerName: "",
    field: "category",
    maxWidth: 60,
    cellRendererFramework: RemoveItem,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    width: 30,
  },
];
