export const chargeStatusHandler = ({
  selectedService,
  thisElevation,
  fakeRentals,
  thisWeek,
  thisPli,
  whichOne,
  thisDay,
}) => {
  //here we find all weeks on the pli that the charge indicator is on
  const data = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.find(({ id }) => id === thisPli)?.rentalDetails?.details

  //here we find all days in this pli
  const pliData = data?.flatMap(({ days }) => days)
  //here we find all days in the week with the id (thisWeek)
  const weekData = !!thisWeek && data?.find(({ week }) => week === thisWeek)?.days
  //here we find the day with the id (thisDay)
  const dayData =
    !!thisDay && data?.find(({ week }) => week === thisWeek)?.days?.find(({ id }) => id === thisDay)

  // with this function we calculate if some days and all days are charged or not
  const objectGenerator = (arr) => {
    const checked = (array, action) => array?.[action](({ charged }) => !!charged)
    return {
      allDaysCharged: arr?.length ? checked(arr, "every") : false,
      someDaysCharged: arr?.length ? checked(arr, "some") : false,
    }
  }

  const pli = () => objectGenerator(pliData)
  const week = () => objectGenerator(weekData)
  const day = () => ({
    allDaysCharged: dayData?.charged,
    someDaysCharged: dayData?.charged,
  })

  //this function will return sth diff based on whichOne key
  return eval(`${whichOne}()`)
}
