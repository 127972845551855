import { Modal } from "antd";
import React, { useRef } from "react";
import { useSelector } from "react-redux";

import Footer from "./Footer";
import Header from "./Header";

import { generateColumnDefs } from "./columnDefs";
import ComparisonTable from "../ComparisonTable/ComparisonTable";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { useUploadRecordsContext } from "../../context/UploadRecordsContext";

import "./CompareTables.scss";
import Warning from "../Warning/Warning";

const CompareTable = ({ open, onCancel }) => {
  const grid1ApiRef = useRef(null);
  const grid2ApiRef = useRef(null);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    title,
    uploadedRecords,
    recordsConflicts,
    isWarningOpen,
    setIsWarningOpen,
    onResolve,
    onResolveConfirm,
  } = useUploadRecordsContext();

  const { newRecords, existingRecords } = recordsConflicts;

  const columnDefs = generateColumnDefs(
    !!!uploadedRecords.length ? newRecords : uploadedRecords
  );

  return (
    <Modal
      title={`${title} Conflicts Modal`}
      open={open}
      onCancel={onCancel}
      className={`uploaded-conflicts-table-modal ${
        isDarkMode && "uploaded-conflicts-table-modal-dark"
      }`}
      centered={true}
      closeIcon={<XIcon />}
      closable={true}
      footer={<Footer {...{ onCancel, onSubmit: onResolve }} />}
    >
      <div>
        <Header />
        <div className="tables-wrapper">
          <ComparisonTable
            {...{
              tableKey: "currentDataTable",
              rowData: newRecords,
              columnDefs,
              compareRowData: existingRecords,
              gridRef: grid1ApiRef,
              gridCompareRef: grid2ApiRef,
            }}
          />
          <ComparisonTable
            {...{
              tableKey: "existingDataTable",
              rowData: existingRecords,
              columnDefs,
              compareRowData: newRecords,
              gridRef: grid2ApiRef,
              gridCompareRef: grid1ApiRef,
            }}
          />
        </div>
      </div>
      {isWarningOpen && (
        <Warning
          {...{
            isOpen: isWarningOpen,
            setIsOpen: setIsWarningOpen,
            onSubmit: onResolveConfirm,
          }}
        />
      )}
    </Modal>
  );
};

export default CompareTable;
