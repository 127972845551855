// import { wsFormRequests } from "../../../../../AppData";
import { batchUpdateItems } from "../../../../../utils";
import { moveRequestFiles } from ".";
import { APPROVALS_REQUESTS } from "../../../components/Approvals/utils/customerApprovals";

/**
 * Adds a new record to a request.
 *
 * @param {WebSocket} ws - The WebSocket connection.
 * @param {string} newRecordId - The ID of the new record.
 * @param {string} path - The path of the new record.
 * @param {string} requestId - The ID of the request.
 * @param {Function} setVisible - A function to set the visibility of the modal.
 * @param {Object} moveFilesParams - Parameters for moving files.
 * @param {Function} approveLog - A function to log the approval.
 * @returns {Promise<void>} - A promise that resolves when the new record is added.
 */
async function addNewRecordToRequest({
  ws = null,
  newRecordId = "",
  path = "",
  requestId = "",
  setVisible = () => {},
  moveFilesParams = null,
  approveLog = async () => {},
  sendWsMessage = () => {},
}) {
  try {
    let failedFiles = [];
    if (!!moveFilesParams) {
      if (moveFilesParams?.filesToMove?.length) {
        failedFiles = await moveRequestFiles({
          ...moveFilesParams,
        });
      }
    }

    await batchUpdateItems({
      tableName: "formRequests",
      conditionKey: "requestId",
      updateData: {
        [requestId]: {
          "requestObject.newRecordId": newRecordId,
          "requestObject.path": path,
          "requestObject.gDriveUploads": failedFiles,
          "requestObject.status": "Reviewed",
          responseFrom: "approved",
        },
      },
    }).then(() => {
      ws.send(
        JSON.stringify({
          request: "request-response",
          body: {
            responseFrom: "approved",
            status: "Reviewed",
            requestId,
            newRecordId,
            path,
            gDriveUploads: failedFiles,
            // cognitoUserId,
          },
        })
      );
      sendWsMessage({
        type: "approvals",
        request: APPROVALS_REQUESTS.update,
        body: { requestId, responseFrom: "Approved", status: "Reviewed" },
      });

      approveLog({
        status: "Reviewed",
        response: "Approved",
        newRecordId,
        path,
        gDriveUploads: failedFiles,
      }).catch((err) => {
        console.error("Error adding log: ", err);
      });
      setVisible(false);
    });
  } catch (err) {
    console.error(err);
  }
}

export default addNewRecordToRequest;
