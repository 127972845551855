const languageTable = {
  en: {
    "Project Address": "Project Address",
    Estimates: "Estimates",
    Period: "Period",
    Projects: "Projects",
    Search: "Search",
    Profile: "Profile",
    Logout: "Logout",
    Open: "Open",
    "Back to Projects": "Back to Projects",
    "Select a day to view the estimation details":
      "Select a day to view the estimation details",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    "Not Scheduled": "Not Scheduled",
    Along: "Along",
    East: "East",
    West: "West",
    North: "North",
    South: "South",
    Elevation: "Elevation",
    Street: "Street",
    Installation: "Installation",
    Removal: "Removal",
    Maintenance: "Maintenance",
    "Deliver Material": "Deliver Material",
    "Pick Up Material": "Pick Up Material",
    Repair: "Repair",
    Inspection: "Inspection",
    item: "item",
    items: "items",
    found: "found",
    "No projects found!": "No projects found!",
    "Error getting data!": "Error getting data!",
    Editing: "Editing",
    Length: "Length",
    Width: "Width",
    Surface: "Surface",
    Height: "Height",
    "Save Changes": "Save Changes",
    Cancel: "Cancel",
    "Please upload your file": "Please upload your file",
    "Choose File": "Choose File",
    "No projects found": "No projects found",
    Completed: "Completed",
    Name: "Name",
    Daily: "Daily",
    Upload: "Upload",
    Filters: "Filters",
    Service: "Service",
    Date: "Date",
    "Date Range": "Date Range",
    "Clear Filters": "Clear Filters",
    Apply: "Apply",
    "Select Date": "Select Date",
    "Select Service": "Select Service",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "This Week": "This Week",
    "Next 7 Days": "Next 7 Days",
    "Previous Week": "Previous Week",
    "This Month": "This Month",
    "Previous Month": "Previous Month",
    "This Year": "This Year",
    "Previous Year": "Previous Year",
    "Clear the date range to select a date":
      "Clear the date range to select a date",
    "Clear the date to select a date range":
      "Clear the date to select a date range",
    "Add Notes": "Add Notes",
    "Please upload at least one photo before saving progress changes.":
      "Please upload at least one photo before saving progress changes.",
    Notes: "Notes",
    Hello: "Hello",
    "here you can add notes for the day": "here you can add notes for the day",
    Download: "Download",
    "Open In Drive": "Open In Drive",
    Draw: "Draw",
    Clear: "Clear",
    Save: "Save",
    "File Preview": "File Preview",
    Undo: "Undo",
    "Delete Image Warning": "Delete Image Warning",
    "Are you sure you want to delete this file?":
      "Are you sure you want to delete this file?",
    Confirm: "Confirm",
    Actions: "Actions",
    "Saved successfully": "Saved successfully",
    "Failed to save": "Failed to save",
    "No schedules found": "No schedules found",
    Documents: "Documents",
    Sqf: "Sqf",
  },
  es: {
    "Project Address": "Dirección del proyecto",
    Estimates: "Estimaciones",
    Period: "Período",
    Projects: "Proyectos",
    Search: "Buscar",
    Profile: "Perfil",
    Logout: "Cerrar sesión",
    Open: "Abierto",
    "Back to Projects": "Volver a Proyectos",
    "Select a day to view the estimation details":
      "Seleccione un día para ver los detalles de la estimación",
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    Sunday: "Domingo",
    "Not Scheduled": "No programado",
    Along: "A lo largo del",
    East: "Este",
    West: "Oeste",
    North: "Norte",
    South: "Sur",
    Elevation: "Fachada",
    Street: "Calle",
    Installation: "Instalación",
    Removal: "Eliminación",
    Maintenance: "Mantenimiento",
    "Deliver Material": "Entregar Material",
    "Pick Up Material": "Recoger Material",
    Repair: "Reparar",
    Inspection: "Inspección",
    item: "elemento",
    items: "elementos",
    found: "encontrado",
    "No projects found!": "¡No se encontraron proyectos!",
    "Error getting data!": "¡Error al obtener datos!",
    Editing: "Edición",
    Length: "Longitud",
    Width: "Ancho",
    Surface: "Superficie",
    Height: "Altura",
    "Save Changes": "Guardar cambios",
    Cancel: "Cancelar",
    "Please upload your file": "Por favor sube tu archivo",
    "Choose File": "Elegir archivo",
    "No projects found": "No se encontraron proyectos",
    Completed: "Completado",
    Name: "Nombre",
    Daily: "Diario",
    Upload: "Subir",
    Filters: "Filtros",
    Service: "Servicio",
    Date: "Fecha",
    "Date Range": "Rango de fechas",
    "Clear Filters": "Limpiar filtros",
    Apply: "Aplicar",
    "Select Date": "Seleccionar fecha",
    "Select Service": "Seleccionar servicio",
    "Start Date": "Fecha de inicio",
    "End Date": "Fecha de fin",
    "This Week": "Esta semana",
    "Next 7 Days": "Próximos 7 días",
    "Previous Week": "Semana anterior",
    "This Month": "Este mes",
    "Previous Month": "Mes anterior",
    "This Year": "Este año",
    "Previous Year": "Año anterior",
    "Clear the date range to select a date":
      "¡Borre el rango de fechas para seleccionar una fecha!",
    "Clear the date to select a date range":
      "¡Borre la fecha para seleccionar un rango de fechas!",
    "Add Notes": "Añadir notas",
    "Please upload at least one photo before saving progress changes.":
      "Por favor suba al menos una foto antes de guardar los cambios de progreso.",
    Notes: "Notas",
    Hello: "Hola",
    "here you can add notes for the day":
      "aquí puedes agregar notas para el día",
    Download: "Descargar",
    "Open In Drive": "Abrir en Drive",
    Draw: "Dibujar",
    Clear: "Limpiar",
    Save: "Guardar",
    Undo: "Deshacer",
    "File Preview": "Vista previa del archivo",
    "Delete Image Warning": "Eliminar advertencia de imagen",
    "Are you sure you want to delete this file?":
      "¿Estás seguro de que quieres eliminar este archivo?",
    Confirm: "Confirmar",
    Actions: "Acciones",
    "Saved successfully": "Guardado con éxito",
    "Failed to save": "Error al guardar",
    "No schedules found": "No se encontraron horarios",
    Documents: "Documentos",
    Sqf: "Sqf",
  },
};
export default languageTable;
