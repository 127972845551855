import { Tooltip } from "antd";

function sessionFiltersFields({
  users,
  osOptions,
  browserOptions,
  rolesOptions,
  departmentOptions,
  setFilters,
  filters,
  isDarkMode,
  sessionId,
}) {
  function maxTagPlaceholder(e) {
    return (
      <Tooltip title={e.map(({ label }) => label).join(", ")}>
        <span>{`+ ${e.length} more`}</span>
      </Tooltip>
    );
  }

  function onChange(key, val) {
    setFilters((prev) => ({
      ...prev,
      [key]: val,
    }));
  }

  const quickFilters = [
    { label: "Today", value: "today", color: "#4a90e2" },
    { label: "Yesterday", value: "yesterday", color: "#f5a623" },
    { label: "This Week", value: "thisWeek", color: "#18c704" },
    { label: "Last Week", value: "lastWeek", color: "#eb4345" },
  ];

  const sessionStatuses = [
    {
      label: "Online",
      value: "online",
    },
    {
      label: "Offline",
      value: "offline",
    },
    {
      label: "Logged Out",
      value: "logged out",
    },
    {
      label: "Idle",
      value: "idle",
    },
    {
      label: "Active",
      value: "active",
    },
  ];

  const multiple = {
    mode: "multiple",
    maxTagCount: 3,
    maxTagPlaceholder,
    type: "select",
  };

  return !sessionId
    ? [
        {
          label: "Quick Filters",
          placeholder: "Select Quick Filter",
          type: "select",
          customOptions: quickFilters,
          initialValue: filters?.quickFilter || [],
          onChange: (e) => {
            onChange("quickFilter", e);
          },
          disabled: !!filters?.dateRange,
        },
        {
          ...multiple,
          label: "Name Of User",
          placeholder: "Select Name",
          customOptions: users.map((user) => ({
            label: user.nameOfUser,
            value: user.cognitoUserId,
          })),
          initialValue: filters?.nameOfUser || [],
          onChange: (e) => {
            onChange("nameOfUser", e);
          },
        },
        {
          ...multiple,
          placeholder: "Select Departments",
          label: "Departments",
          customOptions: departmentOptions,
          dropdownClassName: isDarkMode && "darkDateDropDown",
          onChange: (e) => {
            onChange("departments", e);
          },
          initialValue: filters?.departments || [],
        },
        {
          ...multiple,
          placeholder: "Select Roles",
          label: "Roles",
          customOptions: rolesOptions,
          dropdownClassName: isDarkMode && "darkDateDropDown",
          onChange: (e) => {
            onChange("roles", e);
          },
          initialValue: filters?.roles || [],
        },
        {
          label: "Session Status",
          placeholder: "Select Status",
          type: "select",
          customOptions: sessionStatuses,
          initialValue: filters?.sessionStatus || [],
          onChange: (e) => {
            onChange("sessionStatus", e);
          },
        },

        {
          label: "OS Name",
          placeholder: "Select OS",
          type: "select",
          customOptions: osOptions,
          initialValue: filters?.osName || [],
          onChange: (e) => {
            onChange("osName", e);
          },
        },
        {
          label: "Browser Name",
          placeholder: "Select Browser",
          type: "select",
          customOptions: browserOptions,
          initialValue: filters?.browserName || [],
          onChange: (e) => {
            onChange("browserName", e);
          },
        },

        {
          label: "Date Range",
          type: "rangepicker",
          placeholder: ["Start Date", "End Date"],
          showTime: true,
          presets: null,
          defaultValue: filters?.dateRange || [],
          onChange: (dates) => {
            onChange("dateRange", dates);
          },
          disabled: !!filters?.quickFilter,
        },
      ]
    : [
        {
          label: "Quick Filters",
          placeholder: "Select Quick Filter",
          type: "select",
          customOptions: quickFilters,
          initialValue: filters?.quickFilter || [],
          onChange: (e) => {
            onChange("quickFilter", e);
          },
          disabled: !!filters?.dateRange,
        },
        {
          label: "Session Status",
          placeholder: "Select Status",
          type: "select",
          customOptions: sessionStatuses,
          initialValue: filters?.sessionStatus || [],
          onChange: (e) => {
            onChange("sessionStatus", e);
          },
        },
        {
          label: "Date Range",
          type: "rangepicker",
          placeholder: ["Start Date", "End Date"],
          showTime: true,
          presets: null,
          defaultValue: filters?.dateRange || [],
          onChange: (dates) => {
            onChange("dateRange", dates);
          },
          disabled: !!filters?.quickFilter,
        },
      ];
}

export default sessionFiltersFields;
