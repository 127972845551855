import { NoServiceIconDarkMode, NoServiceIconLightMode } from "../../../../../../../BasePage/src"
import "./no-services.scss"

const INITIAL_TEXT = "Get started by selecting one of the services on the left"
const INITIAL_TITTLE = "No service to show"

const NoServices = ({ darkMode, text = INITIAL_TEXT, tittle = INITIAL_TITTLE }) => {
  return (
    <div className={!!darkMode ? "sovNoServicesDarkMode" : "sovNoServicesLightMode"}>
      <div className="iconContainer">
        {darkMode ? <NoServiceIconDarkMode /> : <NoServiceIconLightMode />}
        <br />
        {!!tittle && <div className="information">{tittle}</div>}
        {!!text && <div className="information">{text}</div>}
      </div>
    </div>
  )
}

export default NoServices
