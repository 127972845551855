import "./ProgressStatus.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { saveEditLogs } from "../utils";
import { useEditLogs } from "../../../../hooks";

const ProgressStatus = ({
  categoryName,
  actionType,
  progress: { progressName, progressStatus },
  increaseValue,
  creationProgresses,
  handleCloseModal = () => {},
  datas,
}) => {
  const { topicCategories = [] } = useSelector(
    (state) => state.topicCategories
  );

  const [progressPercentage, setProgressPercentage] = useState(
    progressStatus !== "finished" ? 0 : 100
  );
  const [hasError, setHasError] = useState(false); // when a progress has progressStatus === "hasError" this will be true

  const { saveAddedLogs } = useEditLogs();

  const isLastProgress = creationProgresses.every(
    ({ progressStatus }) =>
      progressStatus === "finished" || progressStatus === "hasError"
  ); // used to check if current step is last progress

  const earlyError =
    creationProgresses.some(
      ({ progressStatus }) => progressStatus !== "notStarted"
    ) &&
    creationProgresses.every(
      ({ progressStatus }) => progressStatus !== "executing"
    );

  const showSwalMsg = () => {
    const anyError = creationProgresses.some(
      ({ progressStatus }) => progressStatus === "hasError"
    );

    Swal.fire({
      icon: anyError ? "warning" : "success",
      text: anyError
        ? "Some actions failed!"
        : "All Actions Completed Successfully!",
      showConfirmButton: false,
      timer: 2500,
    }).then(() => {
      handleCloseModal();
    });
  };

  useEffect(() => {
    let progressInterval = null;

    // this tells that record is in creating process
    if (progressStatus === "executing") {
      progressInterval = setInterval(
        () => {
          setProgressPercentage((prev) => {
            const totalValue = prev + increaseValue;

            const valueToReturn =
              totalValue >= 80
                ? Math.min(prev + (prev >= 90 ? 2 : prev >= 80 ? 5 : 7), 99)
                : totalValue;

            return valueToReturn;
          });
        },
        progressPercentage === 0 ? 100 : increaseValue * 80
      );

      if (progressPercentage >= 99) {
        clearInterval(progressInterval);
      }
    }
    // this tells that record is created
    else if (progressStatus === "finished") {
      setProgressPercentage(100);
    }
    // this tells that there is an error while creating record
    else if (progressStatus === "hasError") {
      const findedCategory = topicCategories.find(
        (category) => category.categoryName === categoryName
      );
      setHasError(true);
      saveEditLogs(
        findedCategory,
        datas,
        actionType,
        progressName,
        saveAddedLogs
      );
    }

    if (isLastProgress || earlyError) {
      showSwalMsg();
    }

    // cleanup func
    return () => {
      !!progressInterval && clearInterval(progressInterval);
    };
  }, [progressPercentage, progressStatus, isLastProgress, earlyError]);

  return (
    <div className="progress-status">
      <span className="progress-name">{progressName}</span>

      <div
        className="progress-container"
        style={{ ...(hasError && { background: "#fe8e4a" }) }}
      >
        <span
          className="progress-remaining"
          style={
            progressPercentage >= 100 || hasError ? { color: "#ffffff" } : {}
          }
        >
          {hasError
            ? "This process executed with an error!"
            : `${progressPercentage}%`}
        </span>

        <div
          className="progress-filled-percentage"
          style={{ width: `${progressPercentage}%` }}
        />
        {!hasError && (
          <span className="progress-remaining-left">
            {progressPercentage < 100
              ? `${100 - progressPercentage} % left`
              : "Completed"}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProgressStatus;
