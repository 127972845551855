import { Input } from "antd";
import { useState } from "react";
import RouteCard from "./RouteCard";

const RolePermissions = ({
  selectedTab,
  accessConfiguration,
  setAccessConfiguration,
  searchRouteConfigs = "",
}) => {
  const roleData = accessConfiguration[selectedTab];

  const routeConfigs = (roleData || []).map((route, key) => ({
    title: route.title,
    key: key.toString(),
    read: route?.read,
    write: route?.write,
    delete: route?.delete,
    checked: false,
    ...(route.children && { children: route.children }),
  }));

  const filteredRouteConfigs = routeConfigs.filter((route) =>
    route.title.toLowerCase().includes(searchRouteConfigs.toLowerCase())
  );

  return (
    <div className="collapsedContainer-body">
      <div className="configBody" id="configBody">
        {filteredRouteConfigs.map((route) => (
          <RouteCard
            key={route.key}
            {...{
              route,
              selectedTab,
              accessConfiguration,
              setAccessConfiguration,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RolePermissions;
