import { createContext, useReducer, useContext } from "react";

import reducer from "./reducer";

const StateContext = createContext();
const DispatchContext = createContext();

const initialState = {
  proposedTypeOfWork: [],
  isWritable: false,
  isAutoSaving: false, // Update SOV if autosave is on which will make many API requests
  fakeSOV: null, // Clone SOV from store and make changes to this, only update SOV on save button pressed in order to not make many API request
  errors: null, // Errors will be an object where each property will be service named label/estimationId object containing their own errors if any, reset to null if no errors
  darkMode: true,
  retracted: false,
  sovId: false,
  isNextStep: false,
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
export const useDispatchContext = () => useContext(DispatchContext);

// Only needed for Class component ServiceContent.jsx because Ag Grid Context Menu has bugs with React Hooks
// Remove these lines when ServiceContent component changes to hooks - After Ag Grid fixes issues with context menu
const DispatchConsumer = DispatchContext.Consumer;
const StateConsumer = StateContext.Consumer;

export const withContext = (WrappedComponent) => (props) =>
  (
    <DispatchConsumer>
      {(dispatch) => (
        <StateConsumer>
          {(state) => (
            <WrappedComponent
              {...props}
              stateContext={state}
              dispatchContext={dispatch}
            />
          )}
        </StateConsumer>
      )}
    </DispatchConsumer>
  );

export default ContextProvider;
