import moment from "moment-timezone";
import { formatCurrency } from "../../../../../../../../utils/formatCurrency";
import { EditIcon } from "../../../../../../../../DynamicView/src/index";
import { forceToNumber } from "../../../utils/checkers";
import { PaymentCheckBox } from "../components/index";
import { Link } from "react-router-dom";
import { Tooltip, Alert } from "antd";
import {
  getQuickDescription,
  InvoiceOverview,
} from "../components/InvoiceOverview/InvoiceOverview";
import { currencyFormatter } from "../../../../../../../../utils/currencyFormatter";
import { WithTooltip } from "../../../../../../../../../commonComponents";
export const columnDefs = ({ paymentData }) => [
  {
    headerName: "Completed",
    field: "invoiceChecked",
    // maxWidth: 140,
    resizable: true,
    enableRowGroup: false,
    sortable: false,
    filter: false,
    valueGetter: ({ data }) => {
      return data.invoicePaidAmount > 0;
    },
    cellRendererFramework: ({ data, value }) => {
      return (
        <PaymentCheckBox
          data={data}
          paymentData={paymentData}
          checked={value}
          indeterminate={
            data?.invoicePaidPercentage > 0 && data?.invoicePaidPercentage < 100
          }
        />
      );
    },
  },
  {
    headerName: "Date",
    resizable: true,
    field: "invoiceDate",
    sortable: true,
    // SORT BY DEFAULT
    sort: "desc",
    // maxWidth: 400,
    cellRendererFramework: ({ value }) => {
      if (!!value) {
        return <div>{moment(value).format("MM/DD/YYYY")}</div>;
      } else {
        return <></>;
      }
    },
    cellStyle: () => {
      return {
        display: "flex",
        justifyContent: "center",
      };
    },
  },
  {
    headerName: "Invoice #",
    resizable: true,
    field: "invoiceNumber",
    // maxWidth: 200,
    cellRendererFramework: ({ value: invoiceNumber, data, context }) => (
      <Link to={`/invoices/${data?.invoiceId}`} target="_blank">
        <WithTooltip tooltipCategory="Payments" tooltipKey="goToInvoice">
          Invoice {invoiceNumber ? invoiceNumber : "N/A"}
        </WithTooltip>
      </Link>
    ),
  },
  {
    headerName: "Client",
    resizable: true,
    field: "accountName",
    // maxWidth: 200,
    // cellRendererFramework: ({ value: invoiceNumber, data, context }) => (
    //   <Link to={`/invoices/${data?.invoiceId}`} target="_blank">
    //     Invoice {invoiceNumber ? invoiceNumber : "N/A"}
    //   </Link>
    // ),
  },
  {
    headerName: "Description",
    resizable: true,
    field: "invoiceDescription",
    // maxWidth: 200,
    cellRendererFramework: ({ value: invoiceNumber, data, context }) => (
      <InvoiceOverview data={data} />
    ),
  },
  {
    resizable: true,
    headerName: "Address",
    field: "projectAddress",
    // maxWidth: 400,
    cellRendererFramework: ({ value: projectAddress, data }) => (
      <Link to={`/projects/${data?.projectId}`} target="_blank">
        <WithTooltip tooltipCategory="Payments" tooltipKey="goToProject">
          {projectAddress}
        </WithTooltip>
      </Link>
    ),
  },
  {
    headerName: "Orig.Amt",
    resizable: true,
    field: "invoiceAmount",
    // maxWidth: 400,
    cellRendererFramework: ({ value: invoiceAmount }) => (
      <div style={{ padding: "0 10px" }}>
        <div>{formatCurrency(invoiceAmount || 0) || "$0.00"}</div>
      </div>
    ),
  },
  {
    headerName: "Credited.Amt",
    resizable: true,
    field: "invoiceCreditedAmount",
    // maxWidth: 400,
    cellRendererFramework: ({ value: invoiceCreditedAmount }) => (
      <div style={{ padding: "0 10px" }}>
        <div>{formatCurrency(invoiceCreditedAmount || 0) || "$0.00"}</div>
      </div>
    ),
  },
  {
    headerName: "Amt.Due",
    resizable: true,
    field: "invoiceAmountDue",
    // maxWidth: 400,
    cellRendererFramework: ({ data }) => (
      <div style={{ padding: "0 10px", color: "red", fontWeight: 600 }}>
        <div>{formatCurrency(data.invoiceAmountDue || 0) || "$0.00"}</div>
      </div>
    ),
  },
  {
    headerName: "Payment",
    resizable: true,
    field: "invoicePaidAmount",
    // maxWidth: 400,
    editable: true,
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value: invoicePaidAmount }) => (
      <div className="payment-editable-cell" style={{ padding: "0 10px" }}>
        <div>{formatCurrency(invoicePaidAmount || 0) || "$0.00"}</div>
        <div className="payment-editable-cell-icon">
          <WithTooltip
            tooltipCategory="Payments"
            tooltipKey="invoicePaidAmount"
          >
            <EditIcon />
          </WithTooltip>
        </div>
      </div>
    ),
  },
  {
    headerName: "%",
    resizable: true,
    field: "invoicePaidPercentage",
    // maxWidth: 220,
    editable: true,
    // maxWidth: 400,
    // editable: true,
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    valueGetter: ({ data }) => {
      let res = Number(
        (forceToNumber(data?.invoicePaidAmount) * 100) /
          forceToNumber(data?.invoiceAmountDue)
      );
      res = forceToNumber(res);
      return res.toFixed(2);
    },
    cellRendererFramework: ({ value = 0 }) => {
      return (
        <div className="payment-editable-cell" style={{ padding: "0 10px" }}>
          <div>{Number(value || 0).toFixed(2)}%</div>
          <div className="payment-editable-cell-icon">
            <WithTooltip
              tooltipCategory="Payments"
              tooltipKey="invoicePaidPercentage"
            >
              <EditIcon />
            </WithTooltip>
          </div>
        </div>
      );
    },
  },
  {
    headerName: "Applied Credit",
    resizable: true,
    editable: false,

    field: "invoiceCredit",
    cellStyle: {
      color: "white",
      backgroundColor: "#EBB02D",
    },
    // maxWidth: 400,
    cellRendererFramework: ({ value = 0 }) => {
      return (
        <div className="payment-editable-cell" style={{ padding: "0 10px" }}>
          <div>{currencyFormatter(value ?? 0)}</div>
          {/* <div className="payment-editable-cell-icon">{<EditIcon />}</div> */}
        </div>
      );
    },
  },
];
