import { months } from "../../../addWidgetsModalData";

export const invoices = (
  programFields,
  updatedDynamicStates,
  invoicesData,
  IdHandler
) => {
  const data = updatedDynamicStates[IdHandler];

  const chargesStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    invoiceStatus: () => ({
      title: "Invoices - Invoice Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Invoices",
        field: "invoiceStatus",
        labels: chargesStatus?.statusName,
        datasets: [
          {
            label: "Invoices",
            data: chargesStatus?.statusName?.map(
              (status) =>
                invoicesData?.filter((a) => a?.invoiceStatus === status)?.length
            ),
            backgroundColor: chargesStatus?.statusColor,
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //invoiceStatus
    totalInvoiceAmount: () => ({
      title: "Invoices - Total Invoice Amount",
      BodyComponent: "BarChart",
      type: "BarChart",
      id: IdHandler,
      BodyComponentParams: {
        table: "Invoices",
        field: "totalInvoiceAmount",
        labels: months,
        custom: 0,
        datasets: [
          {
            label: "Total Invoice Amount",
            data: 0,
            backgroundColor: "#2DA365",
            borderWidth: 0,
          },
          {
            label: "Total Invoice Without Tax",
            data: 0,
            backgroundColor: "#2DA365",
            borderWidth: 0,
          },
          {
            label: "Total Tax",
            data: 0,
            backgroundColor: "#2DA365",
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //totalInvoiceAmount
  };
};
