import uniq from "lodash/uniq";
import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const fleetInspections = ({ tables }) => {
  return {
    inspectedBy: () => ({
      content: uniq(
        tables?.["Fleet Inspections"]?.map((a) => a?.inspectedBy)
      )?.map((inspectedBy, index) => ({
        key: index,
        title: inspectedBy,
        taskNumber: tables?.["Fleet Inspections"]?.length,
        taskDone: tables?.["Fleet Inspections"]?.filter(
          (a) => a?.inspectedBy === inspectedBy
        )?.length,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Inspection By " + inspectedBy,
        contentsHandler: " Inspections By " + inspectedBy,
      })),
    }),
    createdAt: () => ({
      content: (() => {
        let data = [];
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              title: months[i],
              taskNumber: tables?.["Fleet Inspections"]?.filter(
                (b) => b.createdAt >= getMonthsTimestamps()[0]
              )?.length,
              taskDone: tables?.["Fleet Inspections"]?.filter(
                (a) =>
                  a.createdAt >= getMonthsTimestamps()[i] &&
                  a.createdAt <= getMonthsTimestamps("end")[i]
              )?.length,
              color: "#71CF48",
              backgroundColor: "#B2DD9B",
              contentHandler: " Inspection In " + months[i],
              contentsHandler: " Inspections In " + months[i],
            },
          ];
        }
        return data;
      })(),
    }), //createdAt

    fleetName: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.["Fleet Inspections"] || [], "fleetName")
      )?.map(([key, value]) => ({
        title: key,
        taskNumber: tables?.["Fleet Inspections"]?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Inspection for " + key,
        contentsHandler: " Inspections for " + key,
      })),
    }), //fleetName
  }; //Inspections
};
