export const changeScheduleTypeRentals = ({
  setUnchangingFakeRentals,
  setFakeRentals,
  fakeRentals,
  value,
}) => {
  // if the new value is the same as the old value this functions does nothing
  if (fakeRentals?.scheduleType === value) {
    return;
  }
  //else it creates the new rental and save it
  else {
    //here we take the old rental and updates the status key
    const newRental = {
      ...fakeRentals,
      scheduleType: value,
    };

    //here we save the newly created rental
    setFakeRentals(newRental);
    setUnchangingFakeRentals(newRental);
  }
};
