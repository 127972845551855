import { checkIfServiceIsHoist } from "../../models/Service";
import service from "../controllers/handleCopyServiceToClipboard";

export function CheckIfValidId(id) {
  if (id === undefined || id === null || id.trim().length === 0) return false;
  return true;
}

/**
 * Project needs valid data, not empty elevations and empty PLI's
 * This function will remove them
 * @param services {Service[]}
 * */
export function clearServices(services) {
  console.log("clearServices", services);
  for (let serviceIndex = 0; serviceIndex < services.length; serviceIndex++) {
    let service = services[serviceIndex];
    if (!service.isScope) {
      for (
        let optionIndex = 0;
        optionIndex < service.serviceOptions.length;
        optionIndex++
      ) {
        let option = service.serviceOptions[optionIndex];

        for (
          let elevationIndex = 0;
          elevationIndex < option.length;
          elevationIndex++
        ) {
          let elevation = option[elevationIndex];
          if (checkIfServiceIsHoist(service)) {
          } else {
            elevation.items = elevation.items.filter(
              (pli) => pli.approved === true
            ); //only approved item will pass

            if (elevation.items.length === 0) {
              option.splice(elevationIndex, 1); //remove this elevation
              elevationIndex--;
            }
          }
        }

        // if (option.length === 0) {
        //   //remove this option
        //   service.serviceOptions.splice(optionIndex, 1);
        //   optionIndex--;
        // }
      }

      // if (
      //   service.serviceOptions.length === 0 &&
      //   service.serviceAddons.length === 0
      // ) {
      //   //remove this service, if it becomes empty
      //   services.splice(serviceIndex, 1);
      //   serviceIndex--;
      // }
    }
  }
}

/**
 * Function that handles breakdown object creation on project for each service
 * That object is used in scheduling to work with breakdowns
 * @param {Array} services - Estimation services
 * @param {Array} serviceDefinitions - Services default definitions
 */
export function handleBreakdownsOnProjects(services, serviceDefinitions) {
  let servicePliWithBreakdown = services?.reduce((prev, curr) => {
    let breakdownValue = serviceDefinitions
      ?.find(
        (servDef) =>
          servDef?.serviceId.toString() === curr?.serviceId.toString()
      )
      ?.breakdowns?.map((br) => ({
        ...br,
        progressByDay: [],
        days: [],
        // [servDef?.serviceId?.toString() === "3" && floorsProgress]: [],
      }));

    // let schedulingInspections = serviceDefinitions
    //   ?.find((servDef) => servDef?.serviceId === curr?.serviceId)
    //   ?.inspections?.map((br) => ({ ...br, progressByDay: [], days: [] }));

    curr.serviceOptions[0] = curr.serviceOptions[0].map((elevation) => ({
      ...elevation,
      items: elevation?.items?.map?.((item) => ({
        ...item,
        breakdownValue,
      })),
    }));

    return [...prev, curr];
  }, []);
  return servicePliWithBreakdown;
}
