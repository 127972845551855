import { forwardRef, useRef, useImperativeHandle, useContext } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

import { DropDownArrow } from "../../../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import EmployeeUploadContext from "../../EmployeeUploadContext";

import "./CellComponentsStyle.scss";

const SelectGridEditor = forwardRef(({ value, stopEditing, column }, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { companies, roles } = useContext(EmployeeUploadContext);
  const select = useRef(value);

  const OPTIONS_LIST = {
    accountName: companies.map(({ accountName }, key) => ({
      key,
      label: accountName,
      value: accountName,
    })),
    crewPosition: roles.map(({ roleName }, key) => ({
      key,
      label: roleName,
      value: roleName,
    })),
    salaryType: [
      {key: 0, label: "Hourly", value: "Hourly"},
      {key: 0, label: "Salary", value: "Salary"},
    ]
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return select.current;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    []
  );

  return (
    <Select
      {...{
        className: `gridSelect ${isDarkMode && "gridSelectDark"}`,
        size: "large",
        onDropdownVisibleChange: (dropdownVisible) => {
          if (!dropdownVisible) {
            stopEditing();
          }
        },
        defaultOpen: true,
        popupClassName: isDarkMode && "darkDropDown",
        suffixIcon: <DropDownArrow />,
        onSelect(event) {
          select.current = event;
          stopEditing();
        },
        defaultValue: value,
        autoFocus: true,
        options: OPTIONS_LIST?.[column.colId],
      }}
    />
  );
});

export default SelectGridEditor;
