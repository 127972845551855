export const parseComparedHtml = (value, objectValue) => {
  const regex = /(<([^>]+)>)/gi;
  const parsedValue =
    typeof value === "string" ? value.replace(regex, "") : value;
  const parsedObjectValue =
    typeof objectValue === "string"
      ? objectValue.replace(regex, "")
      : objectValue;

  return parsedValue !== parsedObjectValue
    ? { backgroundColor: "#4ee54e" }
    : null;
};

export const parseComparedHtmlWithKey = (value, objectValue, key) => {
  const regex = /(<([^>]+)>)/gi;
  const parsedParamsValue =
    typeof value?.[key] === "string"
      ? value?.[key].replace(regex, "")
      : value?.[key];
  const parsedObjectValue =
    typeof objectValue?.[key] === "string"
      ? objectValue?.[key].replace(regex, "")
      : objectValue?.[key];

  return parsedParamsValue !== parsedObjectValue
    ? { backgroundColor: "#4ee54e" }
    : null;
};
