import React, { useState, useRef, useEffect } from "react";
import { InputNumber } from "antd";

function CustomOtpInput({
  length,
  value,
  onChange,
  className,
  inputStyle,
  focusStyle,
  placeholder,
  disabled = false,
}) {
  const [otp, setOtp] = useState(new Array(length).fill(null));
  const [focusedIndex, setFocusedIndex] = useState(null);
  const refs = useRef([]);

  useEffect(() => {
    if (value && value?.length === length) {
      setOtp(value?.split(""));
    }
  }, [length, value]);

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onChange(newOtp.join(""));

    if (index < length - 1 && value !== null) {
      refs.current[index + 1].focus();
    }
  };

  const handleBackspace = (event, index) => {
    if (event.key === "Backspace" && otp[index] === null && index > 0) {
      event.preventDefault();
      refs.current[index - 1].focus();
      handleChange(null, index - 1);
    } else if (event.key === "ArrowLeft" && index > 0) {
      refs.current[index - 1].focus();
      setFocusedIndex(index);
    } else if (event.key === "ArrowRight" && index < length - 1) {
      refs.current[index + 1].focus();
      setFocusedIndex(index);
    }
  };

  const handleFocus = (index) => {
    setFocusedIndex(index);
    refs.current[index].select();
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("Text/plain");
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(pastedData) && pastedData.length === length) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);
      onChange(newOtp.join(""));
      refs.current[length - 1].focus();
    }
  };

  return (
    <div className={className}>
      {otp.map((value, index) => (
        <InputNumber
          className={`${
            focusedIndex === index
              ? `${inputStyle} ${focusStyle}`
              : `${inputStyle} `
          }  `}
          keyboard={false}
          disabled={disabled}
          controls={false}
          key={index}
          value={value}
          onChange={(value) => handleChange(value, index)}
          maxLength={1}
          ref={(el) => (refs.current[index] = el)}
          onKeyDown={(event) => handleBackspace(event, index)}
          onFocus={() => handleFocus(index)}
          onPaste={handlePaste}
          placeholder={placeholder}
        />
      ))}
    </div>
  );
}

export default CustomOtpInput;
