import { getMonthsTimestamps } from "../../../../../utils";
import { months } from "../../../../addWidgetsModalData";

export const violations = ({ tables }) => {
	return {
		amount_due: () => ({
			content: (() => {
				let violations = [];
				for (let i = 0; i < 12; i++) {
					violations = [
						...violations,
						{
							color: "#71CF48",
							unfilledColor: "#F5F5F5",
							percent: (
								((() => {
									const filteredArray = tables?.Violations?.filter(
										(b) => b.issue_date >= getMonthsTimestamps()[0]
									)
										?.filter(
											(b) =>
												b.issue_date >= getMonthsTimestamps()[i] &&
												b.issue_date <= getMonthsTimestamps("end")[i]
										)
										?.map((a) => a.amount_due);

									return !!filteredArray?.length
										? filteredArray?.reduce(
												(previousValue, currentValue) =>
													parseInt(previousValue) + parseInt(currentValue)
										  )
										: 0;
								})() /
									(() => {
										const filteredArray = tables?.Violations?.filter(
											(b) => b?.issue_date >= getMonthsTimestamps()[0]
										)?.map((a) => a?.amount_due);

										return !!filteredArray?.length
											? filteredArray?.reduce(
													(previousValue, currentValue) =>
														parseInt(previousValue) + parseInt(currentValue)
											  )
											: 0;
									})()) *
								100
							).toFixed(1),
							showInfo: true,
							status: months[i],
							done: (() => {
								const filteredArray = tables?.Violations?.filter(
									(b) => b.issue_date >= getMonthsTimestamps()[0]
								)
									?.filter(
										(b) =>
											b.issue_date >= getMonthsTimestamps()[i] &&
											b.issue_date <= getMonthsTimestamps("end")[i]
									)
									?.map((a) => a.amount_due);

								return !!filteredArray?.length
									? filteredArray?.reduce(
											(previousValue, currentValue) =>
												parseInt(previousValue) + parseInt(currentValue)
									  )
									: 0;
							})(),
						},
					];
				}
				return violations;
			})(),
		}), //amount_due
		issue_date: () => ({
			content: (() => {
				let violations = [];
				for (let i = 0; i < 12; i++) {
					violations = [
						...violations,
						{
							color: "#71CF48",
							unfilledColor: "#F5F5F5",
							percent: (
								(tables?.Violations?.filter((b) => b.issue_date >= getMonthsTimestamps()[0])
									?.map((a) => a?.issue_date)
									?.filter(
										(b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
									)?.length /
									tables?.Violations?.filter((b) => b.issue_date >= getMonthsTimestamps()[0])
										.length) *
								100
							).toFixed(1),
							showInfo: true,
							status: months[i],
							done: tables?.Violations?.filter((b) => b.issue_date >= getMonthsTimestamps()[0])
								?.map((a) => a?.issue_date)
								?.filter((b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i])
								?.length,
						},
					];
				}
				return violations;
			})(),
		}), //issue_date
	}; //Violations
};
