import { AccountingSideBarIcon, SafetyIcon } from "../../../../assets/index";
import {
  FleetWhite,
  SalesWhite,
  ProjectManamengentWhite,
  TimeIcon,
  ApprovalIconWhite,
} from "../../../../icons";
import { ChartIcon } from "../../../SidebarPages/BasePage/src";
import { accessRightsCategories } from "./components/communication/utils/helpers";

export const categories = [
  {
    title: "Sales",
    subtitle: "New Records",
    src: SalesWhite,
  },
  {
    title: "Projects",
    subtitle: "New Records",
    src: ProjectManamengentWhite,
  },
  {
    title: "Accountings",
    subtitle: "New Records",
    src: AccountingSideBarIcon,
  },
  {
    title: "Fleets",
    subtitle: "New Records",
    src: FleetWhite,
  },
  {
    title: "Safety",
    subtitle: "New Records",
    src: SafetyIcon,
  },
  { title: "Approvals", subtitle: "New Requests", src: ApprovalIconWhite },
  { title: "Project Cost", subtitle: "New Records", src: ChartIcon },
  { title: "Vendors", subtitle: "New Records", src: null },
  {
    title: "Others",
    subtitle: "New Records",
    src: TimeIcon,
  },
];

export const shortcuts = ({
  setNewModal = () => {},
  setSelectedAccounting = () => {},
}) => ({
  Sales: [
    {
      title: "Lead",
      subtitle: "Create new Lead",
      onClick: () => setNewModal("Lead"),
      accessRightsTitle: "Leads",
      "data-testid": "lead-shortcut",
    },
    {
      title: "Opportunity",
      subtitle: "Create new Opportunity",
      onClick: () => setNewModal("Opportunity"),
      accessRightsTitle: "Opportunities",
      "data-testid": "opportunity-shortcut",
    },
    {
      title: "Estimation",
      subtitle: "Create new Estimation",
      onClick: () => setNewModal("Estimation"),
      accessRightsTitle: "Estimations",
      "data-testid": "estimation-shortcut",
    },
    {
      title: "Contact",
      subtitle: "Create new Contact",
      onClick: () => setNewModal("Contact"),
      accessRightsTitle: "Contacts",
      "data-testid": "contact-shortcut",
    },
    {
      title: "Client",
      subtitle: "Create new Client",
      onClick: () => setNewModal("Client"),
      accessRightsTitle: "Accounts",
      "data-testid": "client-shortcut",
    },
    {
      title: "Subcontractor",
      subtitle: "Create new Subcontractor",
      onClick: () => setNewModal("Subcontractor"),
      accessRightsTitle: "Subcontractors",
      "data-testid": "subcontractor-shortcut",
    },
  ],

  Fleets: [
    {
      title: "Fleet",
      subtitle: "Create new Fleet",
      onClick: () => setNewModal("Fleet"),
      accessRightsTitle: "Fleet",
      "data-testid": "fleet-shortcut",
    },
    {
      title: "Fleet Dispatch",
      subtitle: "Create new Dispatch",
      onClick: () => setNewModal("Fleet Dispatch"),
      accessRightsTitle: "Fleets Dispatching",
      "data-testid": "fleet-dispatch-shortcut",
    },
    {
      title: "Fleet Violation",
      subtitle: "Create new Violation",
      onClick: () => setNewModal("Fleet Violation"),
      accessRightsTitle: "Fleets Violations",
      "data-testid": "fleet-violation-shortcut",
    },
    {
      title: "Fleet Driver",
      subtitle: "Create new Driver",
      onClick: () => setNewModal("Fleet Driver"),
      accessRightsTitle: "Fleets Drivers",
      "data-testid": "fleet-driver-shortcut",
    },
    {
      title: "Fleet Activity",
      subtitle: "Create new Activity",
      onClick: () => setNewModal("Fleet Activity"),
      accessRightsTitle: "Fleets Activities",
      "data-testid": "fleet-activity-shortcut",
    },
    {
      title: "Fleet Maintenance",
      subtitle: "Create new Maintenance",
      onClick: () => setNewModal("Fleet Maintenance"),
      accessRightsTitle: "Fleet Maintenance",
      "data-testid": "fleet-maintenance-shortcut",
    },
    // {
    //   title: "Fleet Incident",
    //   subtitle: "Create new Incident",
    //   onClick: () => setNewModal("Fleet Incident"),
    //   accessRightsTitle: "Fleets Incidents",
    // },

    {
      title: "Fleet Inventory",
      subtitle: "Create new Inventory",
      onClick: () => setNewModal("Fleet Inventory"),
      accessRightsTitle: "Fleets Inventory",
      "data-testid": "fleet-inventory-shortcut",
    },
    {
      title: "Work Orders",
      subtitle: "Create new Work Order",
      onClick: () => setNewModal("Work Orders"),
      accessRightsTitle: "Work Orders",
      "data-testid": "work-order-shortcut",
    },
    {
      title: "Schedule Maintenance",
      subtitle: "Create new Schedule Maintenance",
      onClick: () => setNewModal("Schedule Maintenance"),
      accessRightsTitle: "Schedule Maintenance",
      "data-testid": "schedule-maintenance-shortcut",
    },
  ],

  Accountings: [
    {
      title: "Schedule Of Value",
      subtitle: "Create new SOV",
      onClick: () => setSelectedAccounting("SOV"),
      accessRightsTitle: "Schedule Of Values",
      "data-testid": "sov-shortcut",
    },
    {
      title: "Application",
      subtitle: "Create new Application",
      onClick: () => setSelectedAccounting("Application"),
      accessRightsTitle: "Applications",
      "data-testid": "application-shortcut",
    },
    {
      title: "Rental",
      subtitle: "Create new Rental",
      onClick: () => setSelectedAccounting("Rentals"),
      accessRightsTitle: "Rentals",
      "data-testid": "rental-shortcut",
    },
    {
      title: "Charge",
      subtitle: "Create new Charge",
      onClick: () => setSelectedAccounting("Charges"),
      accessRightsTitle: "Charges",
      "data-testid": "charge-shortcut",
    },
    {
      title: "Invoice",
      subtitle: "Create new Invoice",
      onClick: () => setSelectedAccounting("Invoices"),
      accessRightsTitle: "Invoices",
      "data-testid": "invoice-shortcut",
    },
    {
      title: "Payment",
      subtitle: "Create new Payment",
      onClick: () => setSelectedAccounting("Payments"),
      accessRightsTitle: "Payments",
      "data-testid": "payment-shortcut",
    },
  ],

  Projects: [
    {
      title: "Project",
      subtitle: "Create new Project",
      onClick: () => setNewModal("Project"),
      accessRightsTitle: "Projects",
      "data-testid": "project-shortcut",
    },
    {
      title: "Scheduling",
      subtitle: "Create new Scheduling",
      onClick: () => setNewModal("Scheduling"),
      accessRightsTitle: "Scheduling",
      "data-testid": "scheduling-shortcut",
    },
    {
      title: "Permit Drawing",
      subtitle: "Create new Permit",
      onClick: () => setNewModal("Permit Drawing"),
      accessRightsTitle: "Permit Drawings",
      "data-testid": "permit-shortcut",
    },
    {
      title: "Inspection",
      subtitle: "Create new Inspection",
      onClick: () => setNewModal("Inspection"),
      accessRightsTitle: "Inspections",
      "data-testid": "inspection-shortcut",
    },
    {
      title: "Documentation",
      subtitle: "Create new Documentation",
      onClick: () => setNewModal("Documentation"),
      accessRightsTitle: "Documentations",
      "data-testid": "documentation-shortcut",
    },
  ],
  Safety: [
    {
      title: "Safety Reports",
      subtitle: "Create new Safety Report",
      onClick: () => setNewModal("Safety Reports"),
      accessRightsTitle: "Safety",
      "data-testid": "safety-report-shortcut",
    },
    {
      title: "Safety Inspection",
      subtitle: "Create new Safety Inspection",
      onClick: () => setNewModal("Safety Inspection"),
      accessRightsTitle: "Safety",
      "data-testid": "safety-inspection-shortcut",
    },
    {
      title: "Incidents",
      subtitle: "Create new Incidents",
      onClick: () => setNewModal("Incidents"),
      accessRightsTitle: "Safety",
      "data-testid": "incident-shortcut",
    },
  ],

  Approvals: [
    {
      title: "Estimation Request",
      subtitle: "Create new Estimation Request",
      onClick: () => setNewModal("Estimation Request"),
      accessRightsTitle: "Estimations",
      requestType: "Estimation",
      "data-testid": "estimation-request-shortcut",
    },
    {
      title: "Permit Drawing Request",
      subtitle: "Create new Permit Request",
      onClick: () => setNewModal("Permit Drawing Request"),
      accessRightsTitle: "Permit Drawings",
      requestType: "Permit Drawing",
      "data-testid": "permit-request-shortcut",
    },
    {
      title: "Scheduling Request",
      subtitle: "Create new Scheduling Request",
      onClick: () => setNewModal("Scheduling Request"),
      accessRightsTitle: "Scheduling",
      requestType: "Scheduling",
      "data-testid": "scheduling-request-shortcut",
    },
    {
      title: "Schedule Change Request",
      subtitle: "Create new Schedule Change Request",
      onClick: () => setNewModal("Schedule Change Request"),
      accessRightsTitle: "Scheduling",
      requestType: "Schedule Change",
      "data-testid": "schedule-change-request-shortcut",
    },
    {
      title: "Dispatch Request",
      subtitle: "Create new Dispatch Request",
      onClick: () => setNewModal("Dispatch Request"),
      accessRightsTitle: "Fleets Dispatching",
      requestType: "Dispatch",
      "data-testid": "dispatch-request-shortcut",
    },
    {
      title: "Safety Inspection Request",
      subtitle: "Create new Safety Inspection Request",
      onClick: () => setNewModal("Safety Inspection Request"),
      accessRightsTitle: "Safety",
      requestType: "Safety Inspection",
      "data-testid": "safety-inspection-request-shortcut",
    },
    {
      title: "Project Request",
      subtitle: "Create new Project Request",
      onClick: () => setNewModal("Project Request"),
      accessRightsTitle: "Projects",
      requestType: "Project",
      "data-testid": "project-request-shortcut",
    },
    {
      title: "Claim Request",
      subtitle: "Create new Claim Request",
      onClick: () => setNewModal("Claim Request"),
      accessRightsTitle: "Claims",
      requestType: "Claim",
      "data-testid": "claim-request-shortcut",
    },
    {
      title: "Incident Request",
      subtitle: "Create new Incident Request",
      onClick: () => setNewModal("Incident Request"),
      accessRightsTitle: "Incidents",
      requestType: "Incident",
      "data-testid": "incident-request-shortcut",
    },
    {
      title: "Service Inspection Request",
      subtitle: "Create new Service Inspection Request",
      onClick: () => setNewModal("Service Inspection Request"),
      accessRightsTitle: "Inspections",
      requestType: "Service Inspection",
      "data-testid": "service-inspection-request-shortcut",
    },
    {
      title: "Documentation Request",
      subtitle: "Create new Documentation Request",
      onClick: () => setNewModal("Documentation Request"),
      accessRightsTitle: "Documentations",
      requestType: "Documentation",
      "data-testid": "documentation-request-shortcut",
    },
    {
      title: "Change Order Request",
      subtitle: "Create new Safety Request",
      onClick: () => setNewModal("Change Order Request"),
      accessRightsTitle: "Change Orders",
      requestType: "Change Order",
      "data-testid": "change-order-request-shortcut",
    },
    {
      title: "Opportunity Request",
      subtitle: "Create new Opportunity Request",
      onClick: () => setNewModal("Opportunity Request"),
      accessRightsTitle: "Opportunities",
      requestType: "Opportunity",
      "data-testid": "opportunity-request-shortcut",
    },
    {
      title: "Work Order Request",
      subtitle: "Create new Work Order Request",
      onClick: () => setNewModal("Work Order Request"),
      accessRightsTitle: "Work Orders",
      requestType: "Work Order",
      "data-testid": "work-order-request-shortcut",
    },
    {
      title: "Fleet Inventory Request",
      subtitle: "Create new Fleet Inventory Request",
      onClick: () => setNewModal("Fleet Inventory Request"),
      accessRightsTitle: "Fleets Inventory",
      requestType: "Fleet Inventory",
      "data-testid": "fleet-inventory-request-shortcut",
    },
  ],
  Others: [
    {
      title: "Task",
      subtitle: "Create new Task",
      onClick: () => setNewModal("Task"),
      accessRightsTitle: "Task Manager",
      "data-testid": "task-shortcut",
    },
    {
      title: "Notes",
      subtitle: "Create new Note",
      onClick: () => setNewModal("Notes"),
      accessRightsTitle: "Notes",
      "data-testid": "note-shortcut",
    },
    {
      title: "Claims",
      subtitle: "Create new Claim",
      onClick: () => setNewModal("Claims"),
      accessRightsTitle: "Claims",
      "data-testid": "claim-shortcut",
    },
    {
      title: "Hearings",
      subtitle: "Create new Hearing",
      onClick: () => setNewModal("Hearings"),
      accessRightsTitle: "Hearings",
      "data-testid": "hearing-shortcut",
    },
    {
      title: "Crew",
      subtitle: "Create new Crew",
      onClick: () => setNewModal("Crew"),
      accessRightsTitle: "Fleets Inspections",
      "data-testid": "crew-shortcut",
    },
    {
      title: "Todo",
      subtitle: "Create New Todo",
      onClick: () => setNewModal("Todo"),
      accessRightsTitle: "Todos",
      "data-testid": "todo-shortcut",
    },
    {
      title: "Geofence",
      subtitle: "Create New Geofence",
      onClick: () => setNewModal("Geofence"),
      accessRightsTitle: ["Opportunities/View", "Projects/View"],
      "data-testid": "geofence-shortcut",
    },
    {
      title: "Communication",
      subtitle: "Compose an Email",
      onClick: () => setNewModal("Communication"),
      accessRightsTitle: accessRightsCategories.map(
        (category) => category.title
      ),
      "data-testid": "communication-shortcut",
    },
  ],
  "Project Cost": [
    {
      title: "DEG",
      subtitle: "Create New DEG",
      onClick: () => setNewModal("DEG"),
      accessRightsTitle: ["Project Cost", "DEG"],
      "data-testid": "deg-shortcut",
    },
    {
      title: "Jobsite",
      subtitle: "Create New Jobsite",
      onClick: () => setNewModal("Jobsite"),
      accessRightsTitle: ["Project Cost", "Jobsites"],
      "data-testid": "jobsite-shortcut",
    },
  ],
  Vendors: [
    {
      title: "Vendor",
      subtitle: "Create New Vendor",
      onClick: () => setNewModal("Vendor"),
      accessRightsTitle: ["Vendors", "Vendor"],
      "data-testid": "vendor-shortcut",
    },
    {
      title: "Bill",
      subtitle: "Create New Bill",
      onClick: () => setNewModal("Bill"),
      accessRightsTitle: ["Vendors", "Bill/View"],
      "data-testid": "bill-shortcut",
    },
  ],
  favourites: [],
});
