import { max, min } from "lodash";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../../../../../../../../../../DateComponents/contants/DayjsNY";
import moment from "moment";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

//here we generate all datas from all rentals
export const existingRentalsHandler = ({
  data,
  selectedService,
  thisElevation,
  thisPli,
  service = {},
}) => {
  return data
    ?.flatMap(({ services, rentalNumber, ...rest }) =>
      services
        ?.find(
          ({ label, quickbooksNumber = "" }) =>
            label === selectedService &&
            quickbooksNumber === service?.quickbooksNumber
        )
        ?.serviceOptions?.filter(
          ({ elevationId }) => elevationId === thisElevation
        )
        ?.flatMap(({ items }) => items?.filter(({ id }) => id === thisPli))
        ?.flatMap(({ rentalDetails }) => ({
          rentalId: rest?.rentalId,
          rentalNumber,
          range: [rentalDetails?.startDate, rentalDetails?.endDate],
        }))
        ?.filter(({ range }) => !!range[0] && !!range[1])
    )
    ?.filter(Boolean);
};

export const findingConflictingRangesHandler = ({
  startDate,
  endDate,
  existingRentals,
}) => {
  //here we turn start and end date from timestamp to dayjs object
  const [selectedRentalStart, selectedRentalEnd] = [
    dayjs(startDate),
    dayjs(endDate),
  ];
  console.log("existingRentals", existingRentals);

  const conflictingRentals = existingRentals
    ?.filter(({ range }) => {
      const [rentalStart, rentalEnd] = range;
      //here we filter all datas that the startDate or endDate in not in between start and end of the other rentals
      return (
        selectedRentalStart.isBetween(rentalStart, rentalEnd, "day", "[]") ||
        selectedRentalEnd.isBetween(rentalStart, rentalEnd, "day", "[]")
      );
    })
    ?.map((rentalsData) => {
      const [rentalStart, rentalEnd] = rentalsData?.range;

      //now we take all dates that have conflicts and find witch range is making the conflict
      return {
        ...rentalsData,
        conflictingRange: [
          //the star date of collision
          max([selectedRentalStart?.valueOf(), rentalStart]),
          //the end date of collision
          min([selectedRentalEnd?.valueOf(), rentalEnd]),
        ],
      };
    });

  return conflictingRentals;
};
export const findingGapsHandler = ({ startDate, endDate, existingRentals }) => {
  // Convert start and end dates to dayjs objects
  const [selectedRentalStart, selectedRentalEnd] = [
    dayjs(startDate),
    dayjs(endDate),
  ];

  // Sort existing rentals by their start date
  existingRentals.sort((a, b) => a?.createdAt - b?.createdAt);

  const gapRanges = [];
  gapRanges.push([
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
  ]);
  gapRanges.push(
    ...existingRentals.map((rental) => [
      dayjs(rental.range[0]).format("YYYY-MM-DD"),
      dayjs(rental.range[1]).format("YYYY-MM-DD"),
    ])
  );
  console.log("findMissingDates", {
    existingRentals,
    startDate,
    endDate,
    gapRanges,
    findMissingDates: findMissingDates(gapRanges),
  });
  // let previousRentalEnd = selectedRentalStart;

  // for (let i = 0; i < existingRentals.length; i++) {
  //   const currentRental = existingRentals[i];
  //   const currentRentalStart = dayjs(currentRental.range[0]);

  //   // Calculate the gap between the previous rental end and the current rental start
  //   if (currentRentalStart.isAfter(previousRentalEnd, "day")) {
  //     const gapStart = previousRentalEnd.add(1, "day");
  //     const gapEnd = currentRentalStart.subtract(1, "day");
  //     gapRanges.push({
  //       conflictingRange: [gapStart.format(), gapEnd.format()],
  //     });
  //   }

  //   // Update the previous rental end to the current rental end
  //   const currentRentalEnd = dayjs(currentRental.range[1]);
  //   previousRentalEnd = currentRentalEnd;
  // }

  // // Calculate the gap after the last rental, if any
  // if (selectedRentalEnd.isAfter(previousRentalEnd, "day")) {
  //   const gapStart = previousRentalEnd.add(1, "day");
  //   const gapEnd = selectedRentalEnd;
  //   gapRanges.push({
  //     conflictingRange: [gapStart.format(), gapEnd.format()],
  //   });
  // }

  return findMissingDates(gapRanges);
};

function findMissingDates(dateRanges) {
  // Create a Set to store all the dates within the ranges
  // const allDates = new Set();

  // // Loop through the date ranges and add all dates to the Set
  // dateRanges.forEach(([startDate, endDate]) => {
  //   let currentDate = new Date(startDate);
  //   const end = new Date(endDate);

  //   while (currentDate <= end) {
  //     allDates.add(currentDate.toISOString().split("T")[0]);
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }
  // });

  // // Convert the Set back to an array and sort it
  // const sortedDates = [...allDates].sort();

  // // Find missing dates by comparing adjacent dates
  // const missingDates = [];
  // for (let i = 0; i < sortedDates.length - 1; i++) {
  //   const currentDate = new Date(sortedDates[i]);
  //   currentDate.setDate(currentDate.getDate() + 1);

  //   while (currentDate.toISOString().split("T")[0] < sortedDates[i + 1]) {
  //     missingDates.push(currentDate.toISOString().split("T")[0]);
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }
  // }
  const dateSet = new Set();
  let minDate = null;
  let maxDate = null;

  // Step 1: Populate the Set and find min and max dates

  function getRangesArray(dateArr, toSet) {
    for (const dateRange of dateArr) {
      let startDate = moment(dateRange[0], "YYYY-MM-DD");
      let stopDate = moment(dateRange[1], "YYYY-MM-DD");
      while (startDate <= stopDate) {
        toSet.add(moment(startDate).format("YYYY-MM-DD"));
        startDate = moment(startDate).add(1, "days");
      }
    }
  }
  getRangesArray(dateRanges, dateSet);
  let sortedData = Array.from(dateSet).sort((a, b) =>
    moment(a).diff(moment(b))
  );
  minDate = sortedData[0];
  maxDate = sortedData[sortedData.length - 1];
  let allDates = new Set();
  getRangesArray([[minDate, maxDate]], allDates);
  const foundMissingDates = new Set();
  let applicableDates = Array.from(dateSet);
  let allPossibleDates = Array.from(allDates);

  for (let i = 0; i < allPossibleDates.length; i++) {
    if (!applicableDates.includes(allPossibleDates[i])) {
      foundMissingDates.add(allPossibleDates[i]);
    }
  }

  console.log("missingDates", { foundMissingDates, dateSet, allDates });

  // let al

  // // Step 2: Find the missing days
  // const missingDays = [];
  // let currentDate = moment(minDate);
  // const endDate = moment(maxDate);

  // while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
  //   const currentDateStr = currentDate.format("YYYY-MM-DD");
  //   if (!dateSet.has(currentDateStr)) {
  //     missingDays.push(currentDateStr);
  //   }
  //   currentDate = currentDate.add(1, "day");
  // }

  // console.log("Missing Days:", missingDays);
  // // return missingDates;
  console.log("Unique Dates:", Array.from(dateSet));
  console.log("Min Date:", minDate);
  console.log("Max Date:", maxDate);
  return Array.from(foundMissingDates);
}
