import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

const columnDefs = [
  {
    headerName: "Vehicle",
    field: "fleetName",
    sort: "asc",
    cellRenderer({ data, value, node }) {
      if (node?.group) {
        return <span>{value}</span>;
      }

      let className = "name-dot";
      if (!data?.actualStart) {
        className = className + " name-dot-hold";
      } else {
        if (!data?.actualEnd) {
          className = className + " name-dot-pending";
        } else {
          className = className + " name-dot-hold";
        }
      }

      return (
        <div className="fleet-name-status">
          <span>{value}</span>
          <div className={className}></div>
        </div>
      );
    },
  },
  {
    headerName: "Driver",
    field: "driverName",
  },
  {
    headerName: "Cargo Status",
    field: "cargo",
  },
  {
    headerName: "From Location",
    field: "fromLocation",
  },
  {
    headerName: "Planned Start Time",
    field: "plannedStart",
    filter: "agDateColumnFilter",
    valueGetter({ value }) {
      if (!value) {
        return null;
      }
      return dayjsNY(value).valueOf();
    },
    cellRenderer({ data }) {
      let value = data?.plannedStart;
      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MMM DD, YYYY");
        }
        return "";
      },
    },
  },
  {
    headerName: "Actual Start Time",
    field: "actualStart",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MMM DD, YYYY");
        }
        return "";
      },
    },
  },
  {
    headerName: "Remark (Start)",
    field: "startRemark",
    cellRenderer({ value }) {
      return value || "--";
    },
  },
  {
    headerName: "To Location",
    field: "toLocation",
  },
  {
    headerName: "Planned End Time",
    field: "plannedEnd",
    filter: "agDateColumnFilter",
    valueGetter({ value }) {
      if (!value) {
        return null;
      }
      return dayjsNY(value).valueOf();
    },
    cellRenderer({ data }) {
      let value = data?.plannedEnd;
      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
        }
        return "";
      },
    },
  },
  {
    headerName: "Actual End Time",
    field: "actualEnd",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
        }
        return "";
      },
    },
  },
  {
    headerName: "Remark (End)",
    field: "endRemark",
    cellRenderer({ value }) {
      return value || "--";
    },
  },
];

export const columnKeys = [
  "fleetName",
  "driverName",
  "cargo",
  "fromLocation",
  "plannedStart",
  "actualStart",
  "startRemark",
  "toLocation",
  "plannedEnd",
  "actualEnd",
  "endRemark",
];

export default columnDefs;
