/**
 * Function that updates the drive report file of the vehicle
 * @param {Object} data
 * @param {Array} data.values
 * @param {string|number} data.lastRow
 * @param {string} data.spreadsheetId
 * @param {string} data.sheetName
 */
async function updateDriveReport({
  values,
  lastRow,
  spreadsheetId,
  sheetName,
  googleAccessToken,
}) {
  if (!window?.gapi) {
    return;
  }

  return await gapi.client.sheets.spreadsheets.values.update({
    spreadsheetId,
    range: `'${sheetName}'!${+lastRow + 1}:${+lastRow + values?.length}`,
    includeValuesInResponse: false,
    responseDateTimeRenderOption: "SERIAL_NUMBER",
    responseValueRenderOption: "UNFORMATTED_VALUE",
    valueInputOption: "RAW",
    values,
    access_token: googleAccessToken,
  });
}

export default updateDriveReport;
