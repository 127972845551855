import { Form, message } from "antd";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { useContext, useMemo, useState } from "react";

import { entriesApiCrud } from "../utils";
import EntryModal from "../modals/EntryModal";
import { EntryType } from "../payrollLiveTypes";
import { GroupedReportsCard } from "../components";
import PayrollLiveContext from "../PayrollLiveContext";
import {
  StoreType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import LateClockIn from "../components/LateClockIn/LateClockIn";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { HoverButton, MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import UnMatchedEntries from "../components/UnMatchedEntries/UnMatchedEntries";
import { RenderDynamicComponents } from "src/components/Header/forms/Components";
import {
  RightArrow,
  DownloadIcon,
} from "src/components/SidebarPages/BasePage/src";
import UnregisteredEmployees from "../components/UnregisteredEmployees/UnregisteredEmployees";
import { CrewsHeader } from "src/components/pages/Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";
import GetPayrollReportModal from "../../Payroll/Tabs/DEG/components/modalComponents/GetPayrollReportModal/GetPayrollReportModal";

import "./EmployeeReports.scss";

type NewEmpModalType = {
  employeeId?: string;
  accountName?: string;
  employeeNumber?: string;
  employeeFullName?: string;
};

function EmployeeReports() {
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const { degEntries, setDegEntries, setProgramEmployees } =
    useContext(PayrollLiveContext);

  const [payrollReport, setPayrollReport] = useState<boolean>(false);
  const [newEmpModal, setNewEmpModal] = useState<boolean | NewEmpModalType>(
    false
  );
  const [entryModal, setEntryModal] = useState<
    undefined | Partial<EntryType>
  >();

  const [form] = Form.useForm();

  function clearFilters() {
    form.resetFields();
  }

  async function massUpdateEntries(entriesToUpdate: Array<EntryType>) {
    for (let i = 0; i < entriesToUpdate.length; i += 25) {
      const entriesSlice = entriesToUpdate.slice(i, i + 25);

      for (let j = 0; j < entriesSlice.length; j++) {
        const { entryId, createdAt, userId, duration, ...body } = entriesSlice[
          j
        ] as EntryType & { userId: string };

        entriesApiCrud({ method: "put", id: entryId, body });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  function onNewEmployeeCreation(newEmp: EmployeeType) {
    setProgramEmployees((prev) => prev.concat(newEmp));
    let entriesOfEmployee = degEntries.flatMap((el) =>
      el?.employeeId === newEmp?.employeeId
        ? {
            ...el,
            crewId: newEmp?.crewId,
            crewName: newEmp?.crewName,
            salaryType: newEmp?.salaryType,
            employeeRate: newEmp?.employeeRate,
            employeeRole: newEmp?.crewPosition,
            employeeFullName: newEmp?.crewName,
          }
        : []
    );
    setDegEntries((prev) =>
      prev.flatMap((el) => {
        if (el?.employeeId === newEmp?.employeeId) {
          const updatedEntryObj = {
            ...el,
            crewId: newEmp?.crewId,
            crewName: newEmp?.crewName,
            salaryType: newEmp?.salaryType,
            employeeRate: newEmp?.employeeRate,
            employeeRole: newEmp?.crewPosition,
            employeeFullName: newEmp?.crewName,
          };
          return updatedEntryObj;
        } else {
          return el;
        }
      })
    );

    massUpdateEntries(entriesOfEmployee);
  }

  function onSaveCallback(data: EntryType) {
    if (data?.entryId) {
      try {
        const { userId, entryId, lastFetch, createdAt, duration, ...body } =
          data as EntryType & {
            userId: string;
            lastFetch: number;
          };

        entriesApiCrud({
          method: "put",
          body,
          id: data?.entryId,
        });
        setDegEntries((prev) =>
          prev.map((el) => (el?.entryId === data?.entryId ? data : el))
        );
        message.success({
          id: "entryCrud",
          content: "Entry edited successfully",
          duration: 2,
        });
      } catch (error) {
        message.error({
          id: "entryCrud",
          content: "There was a problem editing this entry",
          duration: 2,
        });
        console.log("Error editing entry: ", error);
      }
    } else {
      try {
        const { duration, ...body } = data as EntryType & {
          userId: string;
          lastFetch: number;
        };

        entriesApiCrud({
          method: "post",
          body: [body],
        });
        setDegEntries((prev) => [data].concat(prev));
        message.success({
          id: "entryCrud",
          content: "Entry created successfully",
          duration: 2,
        });
      } catch (error) {
        message.error({
          id: "entryCrud",
          content: "There was a problem creating this entry",
          duration: 2,
        });
        console.log("Error editing entry: ", error);
      }
    }
  }

  function onDelete(data: EntryType) {
    try {
      entriesApiCrud({
        method: "delete",
        id: data?.entryId,
        body: [data?.entryId],
      });

      setDegEntries((prev) =>
        prev.filter((el) => el.entryId !== data?.entryId)
      );

      message.success({
        id: "entryCrud",
        content: "Entry deleted successfully",
        duration: 2,
      });
    } catch (error) {
      console.log("Error deleting Entry: ", error);
      message.error({
        id: "entryCrud",
        content: "There was a problem deleting this entry",
        duration: 2,
      });
    }
  }

  function resetTimeFilter() {
    form.setFieldValue("punchEnd", null);
    form.setFieldValue("punchStart", null);
  }

  const filterFields = useMemo(() => {
    return [
      {
        type: "input",
        placeholder: "Search...",
        prefix: <SearchOutlined />,
        dataTestid: "employeeName",
        formItemName: "searchField",
      },
    ];
  }, []);

  return (
    <section
      className={`employee-reports-page ${
        darkMode ? "employee-reports-page-dark" : ""
      }`}
    >
      <section className="employee-reports-control-panel">
        <Form form={form} className="control-panel-form">
          {RenderDynamicComponents(filterFields, form)}
          <div className="time-range">
            <InputComponent
              secondaryDarkMode
              form={form}
              type="customTimeInput"
              formItemName="punchStart"
            />
            <RightArrow
              fill={darkMode ? "#fff" : "#323338"}
              height={13}
              width={13}
            />
            <InputComponent
              secondaryDarkMode
              form={form}
              type="customTimeInput"
              formItemName="punchEnd"
            />
            <button
              type="button"
              onClick={resetTimeFilter}
              className="clear-time-btn"
            >
              <XIcon height={10} width={10} />
            </button>
          </div>
        </Form>
        <HoverButton
          hasIcon={false}
          type="decline"
          onClick={clearFilters}
          text="Clear Filters"
        />
        <MondayButton
          className="mondayButtonBlue"
          Icon={<DownloadIcon />}
          onClick={() => setPayrollReport(true)}
        >
          Get Payroll Report
        </MondayButton>
      </section>
      <div className="employee-reports-body">
        <GroupedReportsCard form={form} setEntryModal={setEntryModal} />
        <LateClockIn form={form} />
        <UnregisteredEmployees setNewEmpModal={setNewEmpModal} form={form} />
        <UnMatchedEntries form={form} setEntryModal={setEntryModal} />
      </div>
      {!!newEmpModal ? (
        <CrewsHeader
          isNextStep={false}
          visible={!!newEmpModal}
          defaultData={newEmpModal}
          setVisible={setNewEmpModal}
          refreshTable={onNewEmployeeCreation}
        />
      ) : null}
      {entryModal ? (
        <EntryModal
          open={!!entryModal}
          editDisabled={true}
          onDeleteCallback={onDelete}
          onSaveCallback={onSaveCallback}
          editData={entryModal as EntryType}
          onCancel={() => setEntryModal(undefined)}
        />
      ) : null}
      {payrollReport ? (
        <GetPayrollReportModal
          open={payrollReport}
          onCancel={() => setPayrollReport(false)}
        />
      ) : null}
    </section>
  );
}

export default EmployeeReports;
