import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import htmlParser from "src/utils/htmlParser";
import { ItemType } from "antd/lib/menu/interface";
// import { ItemType } from "antd/es/menu/hooks/useItems";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { StoreType, SideInfoType, TeamsConfigurationType } from "../../types";
import { DynamicAvatars } from "src/components/commonComponents/DynamicAvatars/DynamicAvatars";

import "./SideInfo.scss";

type SideInfoProps = {
  className?: string;
  title?: ReactNode;
  infoData: SideInfoType;
  colon?: boolean;
  teamsConfiguration?: TeamsConfigurationType[];
  headerActions?: ItemType[];
  actionsRenderer?: ReactNode;
};

function SideInfo(props: SideInfoProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const {
    infoData,
    colon = true,
    className = "",
    headerActions = [],
    actionsRenderer = null,
    teamsConfiguration = [],
    title = "General Information",
  } = props;

  return (
    <div
      className={`inspection-grid-card general-side-info-container ${
        isDarkMode ? "grid-card-dark" : ""
      } ${className}`}
    >
      {title ? (
        <div className="card-title">
          <div className="card-title-text">{title}</div>
          {actionsRenderer ? (
            actionsRenderer
          ) : headerActions?.length ? (
            <Dropdown
              trigger={["click", "hover"]}
              placement="bottomRight"
              overlayClassName={`header-action-dropdown ${
                isDarkMode ? "dropdown-dark" : ""
              }`}
              menu={{
                items: headerActions,
              }}
            >
              <EllipsisOutlined
                style={{ transform: "rotate(90deg)", cursor: "pointer" }}
              />
            </Dropdown>
          ) : null}
        </div>
      ) : null}
      <div className={`card-body ${title ? "with-title" : "no-title"}`}>
        {infoData.map((item, index) => {
          return (
            <div className="side-info-item" key={`side-info-${index}}`}>
              <div className="item-label">
                {item.label + `${colon ? ":" : ""}`}
              </div>
              {item.data?.color ? (
                <div
                  className={`item-status item-value ${
                    item.data.className ?? ""
                  }`}
                >
                  <div
                    className="status-dot"
                    style={{ backgroundColor: item.data.color }}
                  ></div>
                  <div
                    className="status-name"
                    style={{ color: item.data.color }}
                  >
                    {item.data.value}
                  </div>
                </div>
              ) : item.data.to ? (
                <Link
                  className={`item-link item-value ${
                    item.data.className ?? ""
                  }`}
                  to={item.data.to}
                >
                  {item.data.value}
                </Link>
              ) : item.data.format ? (
                <div
                  className={`item-date item-value ${
                    item.data.className ?? ""
                  }`}
                >
                  {dayjsNY(item.data.value).format(item.data.format)}
                </div>
              ) : item.data.html ? (
                htmlParser(item.data.value)
              ) : (
                <div
                  className={`item-base item-value ${
                    item.data.className ?? ""
                  }`}
                >
                  {item.data.value}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="teams-footer-info" key={`team-section`}>
        <div className="item-label">
          {"Teams Configuration" + `${colon ? ":" : ""}`}
        </div>
        <div className="item-value">
          {/**
           //@ts-ignore */}
          <DynamicAvatars
            {...{
              title: "Team",
              members: teamsConfiguration.reduce(
                (acc, val) => [...acc, ...val.members],
                []
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SideInfo;
