import { forwardRef, useRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { Input } from "antd";

import "./CellComponentsStyle.scss";

const InputGridEditor = forwardRef(({ value, column }, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const input = useRef(value);

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return input.current;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    []
  );

  return (
    <Input
      {...{
        type: column.colId === "employeeRate" ? "number" : "text",
        defaultValue: value,
        autoFocus: true,
        onChange: ({ target }) =>
          (input.current =
            column.colId === "employeeRate"
              ? Number(target?.value.replaceAll("e"))
              : target.value),
        className: `gridInput ${isDarkMode && "gridInputDark"}`,
        size: "large",
      }}
    />
  );
});

export default InputGridEditor;
