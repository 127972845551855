import { message } from "antd";

import { formatDurationDeg } from "../utils";
import StatusGridEditor from "../utils/StatusGridEditor";
import { getExpenseType } from "../utils/expenseTypeMap";
import ServiceGridSelect from "../utils/ServiceGridSelect";
import { MondayButton } from "../../../../../../../commonComponents";
import { formatCurrency } from "../../../../../../../SidebarPages/utils";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import Services from "../../../../../../../SidebarPages/components/AgGridComponents/Services";
import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const OVERHEAD_ENTRY_TYPES = ["CASH", "1099"];

export function columnDefs({
  notFound,
  isLastStep,
  openEntryLogs,
  jobsiteServices,
  setEditModalData,
  setAddEmployeeModal,
  employeesHoursPerDay,
}) {
  const columnsState = JSON.parse(sessionStorage.getItem("degStepColumns"));
  const columnWidths = {};

  if (!!columnsState?.length) {
    for (const state of columnsState) {
      const { colId, width } = state;
      Object.assign(columnWidths, { [colId]: width });
    }
  }

  return [
    {
      headerName: "Nr",
      colId: "3",
      checkboxSelection: ({ data }) => {
        return !notFound?.[data?.employeeId] || !data.employeeId;
      },
      showDisabledCheckboxes: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      minWidth: columnWidths?.["3"] || 50,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ rowIndex }) => {
        return rowIndex + 1;
      },
    },
    {
      headerName: "Upload Name",
      field: "uploadName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      minWidth: columnWidths?.["uploadName"] || 0,
      cellClass: "cell-left-aligned",
    },
    {
      headerName: "Edit",
      colId: "1",
      skipColumnGroupHeaders: true,
      skipColumnHeaders: true,
      cellClass: "cell-center-aligned",
      minWidth: columnWidths?.["1"] || 130,
      cellRenderer: ({ data, context }) => {
        return (
          <MondayButton
            hasIcon={false}
            tooltipKey={" "}
            disabled={
              isLastStep || context?.rowToEdit?.degStatus === "Completed"
              // || editModalData?.()
            }
            onClick={() => {
              // return
              notFound?.[data?.employeeId]
                ? message.error({
                    content: `You can't edit this Activity because this employee is not registered`,
                    key: "activityDisabled",
                  })
                : setEditModalData({
                    ...data,
                    payrollCategory: data?.payrollType,
                  });
              // !notFound?.[data?.employeeId] && node.setSelected(true);
            }}
            className={
              isLastStep
                ? // || editModalData?.()
                  ""
                : notFound?.[data?.employeeId]
                ? "mondayButtonGrey"
                : "mondayButtonBlue"
            }
          >
            Edit
          </MondayButton>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Logs",
      colId: "2",
      skipColumnGroupHeaders: true,
      skipColumnHeaders: true,
      cellClass: "cell-center-aligned",
      minWidth: columnWidths?.["2"] || 130,
      cellRenderer: ({ data }) => {
        return (
          <MondayButton
            hasIcon={false}
            tooltipKey={" "}
            disabled={
              isLastStep
              // || editModalData?.()
            }
            onClick={() => {
              openEntryLogs(data?.entryId);
            }}
            className={"mondayButtonBlue"}
          >
            Show Logs
          </MondayButton>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Status",
      field: "activityStatus",
      cellClass: "cell-left-aligned",
      editable: true,
      cellEditor: StatusGridEditor,
      singleClickEdit: true,
      enableRowGroup: true,
      minWidth: columnWidths?.["activityStatus"] || 0,
      cellStyle: ({ value }) => {
        return !value?.length
          ? { background: "#f54c4a99" }
          : // : value === "Completed"
            // ? { background: "#1bd60a50" }
            {};
      },
      cellRenderer({ value }) {
        return value || "";
      },
    },
    {
      headerName: "Employee Id",
      field: "employeeId",
      enableRowGroup: true,
      minWidth: columnWidths?.["employeeId"] || 0,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
    },
    {
      headerName: "Employee Full Name",
      field: "employeeFullName",
      enableRowGroup: true,
      minWidth: columnWidths?.["employeeFullName"] || 0,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
      cellRenderer: ({ data }) => {
        if (!!data?.crewName?.length) {
          return <div>{data?.crewName}</div>;
        } else {
          return <div>{data?.employeeFullName}</div>;
        }
      },
      // pdfExportOptions: {
      //   modifyCellValue: (data) => {
      //     if (!!data?.crewName?.length) {
      //       return data?.crewName;
      //     } else {
      //       return data?.employeeFullName;
      //     }
      //   },
      // },
    },
    {
      headerName: "Rate",
      field: "employeeRate",
      enableRowGroup: true,
      minWidth: columnWidths?.["employeeRate"] || 0,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return value === 0 &&
          !OVERHEAD_ENTRY_TYPES.concat("HR").includes(data?.punchType)
          ? { background: "#f54c4a99" }
          : {};
      },
      cellRenderer({ value }) {
        if (isNaN(parseFloat(value))) {
          return formatCurrency(0);
        } else {
          return formatCurrency(value);
        }
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (isNaN(parseFloat(value))) {
            return formatCurrency(0);
          } else {
            return formatCurrency(value);
          }
        },
      },
    },
    {
      headerName: "Role",
      field: "employeeRole",
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
      minWidth: columnWidths?.["employeeRole"] || 0,
    },
    {
      headerName: "Team",
      field: "crewTeamName",
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
      minWidth: columnWidths?.["crewTeamName"] || 0,
    },
    {
      headerName: "Type",
      field: "type",
      enableRowGroup: true,
      minWidth: columnWidths?.["type"] || 0,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ data }) => {
        if (data?.employeeType) {
          return getExpenseType(data?.employeeType);
        } else {
          const date = dayjsNY(data?.punchDate).format(DEG_DATE_FORMAT);
          const employeeId = data?.employeeId;
          const shift = employeesHoursPerDay?.[employeeId]?.[date];
          return shift?.type || "";
        }
      },
      pdfExportOptions: {
        modifyCellValue: (data) => {
          return getExpenseType(data?.employeeType);
        },
      },
    },
    {
      headerName: "Payroll Type",
      field: "payrollType",
      enableRowGroup: true,
      hide: true,
      minWidth: columnWidths?.["payrollType"] || 0,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
    },
    {
      headerName: "Jobsite Match",
      field: "jobsiteMatch",
      minWidth: columnWidths?.["jobsiteMatch"] || 0,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      getQuickFilterText: ({ value }) => {
        return typeof value === "string" ? value : value?.jobName;
      },
      cellStyle: ({ value, data }) => {
        const jobsiteChanged = data?.jobsiteChanged;
        if (typeof value === "string") {
          return !value?.length &&
            !OVERHEAD_ENTRY_TYPES.concat("HR").includes(data?.punchType)
            ? { background: "#f54c4a99" }
            : {};
        } else {
          return !value?.jobName?.length &&
            !OVERHEAD_ENTRY_TYPES.concat("HR").includes(data?.punchType)
            ? { background: "#f54c4a99" }
            : jobsiteChanged
            ? { color: "red" }
            : {};
        }
      },
      cellRenderer: ({ value }) => {
        if (typeof value === "string") {
          return <div>{value}</div>;
        } else {
          return <div>{value?.jobName}</div>;
        }
      },
      filterParams: {
        valueGetter: ({ data }) => {
          return data?.jobsiteMatch?.jobName;
        },
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.jobName;
          }
        },
      },
    },
    {
      headerName: "Schedule Match",
      field: "scheduleId",
      enableRowGroup: true,
      minWidth: columnWidths?.["scheduleId"] || 0,
      valueGetter: ({ data, context }) => {
        const schedule =
          context?.scheduleMatching?.[data?.jobsiteId]?.scheduleAddress;
        return schedule || "";
      },
    },
    {
      headerName: "Overhead Amount",
      field: "totalOvh",
      enableRowGroup: true,
      minWidth: columnWidths?.["totalOvh"] || 0,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ value }) => {
        return !!value ? formatCurrency(Number(value)) : formatCurrency(0);
      },
    },
    {
      headerName: "SOW",
      field: "sow",
      minWidth: columnWidths?.["sow"] || 0,
      cellClass: "cell-center-aligned",
      editable: true,
      cellEditor: ServiceGridSelect,
      // valueGetter({ data }) {
      //   return (data?.sow || []).join(", ");
      // },
      cellRendererFramework: ({ data }) => {
        return Services({
          value: {
            [jobsiteServices?.[data?.jobsiteId]?.[0]?.estimationNumber || ""]: {
              services: data?.sow || [],
            },
          },
          title: "Jobsite Services",
        });
      },
    },
    {
      headerName: "Google Sheet Link",
      field: "jobsiteMatch",
      colId: "jobsiteMatch_1",
      enableRowGroup: true,
      minWidth: columnWidths?.["jobsiteMatch_1"] || 0,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ value }) => {
        if (value?.googleSheetLink) {
          return (
            <a onClick={() => window.open(value?.googleSheetLink)}>
              Google Sheet Link
            </a>
          );
        } else {
          return "";
        }
      },
      pdfExportOptions: {
        createURL: (value) => {
          return value?.googleSheetLink;
        },
        modifyCellValue: (value) => {
          if (value?.googleSheetLink) {
            return {
              text: "Google Sheet Link",
              hyperlink: value?.googleSheetLink,
            };
          }
        },
      },
    },
    {
      headerName: "Punch Date",
      field: "punchDate",
      minWidth: columnWidths?.["punchDate"] || 0,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value ? { background: "#f54c4a99" } : {};
      },
      cellRenderer: ({ value }) => {
        if (!value) {
          return <div>-||-</div>;
        }
        const punchDate = parseInTz(value).format(DEG_DATE_FORMAT);
        return <div>{punchDate === "Invalid Date" ? "-||-" : punchDate}</div>;
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (!value) {
            return "-||-";
          }
          const punchDate = parseInTz(value).format(DEG_DATE_FORMAT);
          return punchDate === "Invalid Date" ? "-||-" : punchDate;
        },
      },
    },
    {
      headerName: "Punch Location",
      field: "punchLocation",
      minWidth: columnWidths?.["punchLocation"] || 0,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return !value?.length &&
          !OVERHEAD_ENTRY_TYPES.concat("HR").includes(data?.punchType)
          ? { background: "#f54c4a99" }
          : {};
      },
    },
    {
      headerName: "Punch Time",
      field: "punchTime",
      minWidth: columnWidths?.["punchTime"] || 0,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return !value && !OVERHEAD_ENTRY_TYPES.includes(data?.punchType)
          ? { background: "#f54c4a99" }
          : {};
      },
      cellRenderer: ({ value }) => {
        if (!value) {
          return <div>-||-</div>;
        }

        const punchTime = parseInTz(value).format(DEG_TIME_FORMAT);
        return <div>{punchTime === "Invalid Date" ? "-||-" : punchTime}</div>;
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (!value) {
            return "-||-";
          }
          const punchTime = parseInTz(value).format(DEG_TIME_FORMAT);
          return punchTime === "Invalid Date" ? "-||-" : punchTime;
        },
      },
    },
    {
      headerName: "Punch Type",
      field: "punchType",
      minWidth: columnWidths?.["punchType"] || 0,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
      enableRowGroup: true,
    },
    {
      headerName: "Reason",
      field: "reason",
      minWidth: columnWidths?.["reason"] || 0,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return !value?.length && data?.punchType === "HR"
          ? { background: "#f54c4a99" }
          : {};
      },
    },
    {
      headerName: "Punch Time Stamp",
      field: "punchTimeStamp",
      minWidth: columnWidths?.["punchTimeStamp"] || 0,
      filter: "agDateColumnFilter",
      hide: true,
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Distance",
      field: "distanceFromJob",
      enableRowGroup: true,
      minWidth: columnWidths?.["distanceFromJob"] || 0,
      filter: "agNumberColumnFilter",
      valueGetter: ({ data }) => {
        return data?.distanceFromJob >= 5280
          ? `${parseFloat(data?.distanceFromJob / 5280).toFixed(2)} miles`
          : (data?.distanceFromJob ?? "0") + ` ft`;
      },
      filterParams: {
        valueGetter: ({ data }) => {
          return data?.distanceFromJob || 0;
        },
      },
    },
    {
      headerName: "Duration",
      field: "duration",
      enableRowGroup: true,
      minWidth: columnWidths?.["duration"] || 0,
      valueGetter: ({ data }) => {
        return formatDurationDeg(data?.duration, "seconds").text;
      },
    },
    {
      headerName: "Action",
      field: "employeeId",
      colId: "employeeId_1",
      minWidth: columnWidths?.["employeeId_1"] || 0,
      skipColumnGroupHeaders: true,
      skipColumnHeaders: true,
      cellStyle: {
        display: "grid",
        placeContent: "center",
      },
      cellRenderer: ({ data }) => {
        // if (notFound?.[data?.employeeId]) {
        return (
          <MondayButton
            tooltipKey={" "}
            disabled={!notFound?.[data?.employeeId]}
            hasIcon={false}
            onClick={() =>
              setAddEmployeeModal({
                employeeFullName: data?.employeeFullName,
                employeeId: data?.employeeId,
                accountName: data?.company,
              })
            }
          >
            Add Employee
          </MondayButton>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
  ];
}

export const gridFilterData = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Full Name",
    type: "Dropdown",
    key: "employeeFullName",
  },
  {
    globalKeyId: "78af444b-a8c0-4a9c-81e6-0bd9766a731e",
    label: "Role",
    type: "Dropdown",
    key: "employeeRole",
  },
  {
    globalKeyId: "8fewfwbc3-0b8a-400f-b352-6354cb0f22ec",
    label: "Payroll Type",
    type: "Dropdown",
    key: "payrollType",
  },
  {
    globalKeyId: "6gj1edzbn-0b8a-400f-b352-6354cb0f22ec",
    label: "Punch Type",
    type: "Dropdown",
    key: "punchType",
  },
  {
    globalKeyId: "8fdedbc3-0b8aweee-b352-6354cb0f22ec",
    label: "Company",
    type: "Dropdown",
    key: "company",
  },
  {
    globalKeyId: "ac810ced-788d-4ce3-a6f9-123081326d38",
    label: "Jobsite",
    type: "Dropdown",
    key: ["jobsiteMatch", "jobName"],
  },
  {
    globalKeyId: "a4c0c008-3db3-4a18-96c7-8950fd589948",
    label: "Status",
    type: "Dropdown",
    key: "activityStatus",
  },
  {
    globalKeyId: "27460cca-0b8e-4353-9389-e6ade02fd9fe",
    label: "Time Frame",
    type: "DateRange",
    key: "punchTimeStamp",
  },
  {
    globalKeyId: "f4d3fc54-9723-426e-af6e-86455aebf3e5",
    label: "Distance Match",
    type: "SliderRange",
    key: "distanceFromJob",
    min: 100,
    max: 10000,
    range: { draggableTrack: true },
    marks: { 100: "100 ft", 10000: "10000 ft" },
  },
];

export const excelColumnsConfig = [
  "uploadName",
  "employeeId",
  "employeeFullName",
  "companyName",
  "employeeRate",
  "employeeRole",
  "payrollType",
  "jobsiteMatch",
  "punchDate",
  "punchLocation",
  "punchTime",
  "punchType",
  "reason",
];
