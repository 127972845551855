import AccountingConfirmationModal from "../../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import {
  columnDefs,
  previewColumnDefs,
} from "../../../../../ServiceList/Rentals/Components/RentalBody/GridParams/columnDefs";
import { TickIcon } from "../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { updateApplication } from "../../../../../../../../../../../../actions/applications";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { Close } from "../../../../../../../../../../DynamicView/src";
import "./IncludeRentals.scss";
import {
  confirmIncludedRentalsHandler,
  redirectToRental,
  rentalDataExtractor,
  unchainRentalsFromApp,
} from "./includeRentalsFunctions";

import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal } from "antd";
import { cloneDeep, map, uniqBy } from "lodash";
import { message } from "antd";
import { API } from "aws-amplify";
import { currencyFormatter } from "../../../../../../../../../../utils/currencyFormatter";
import { totalitiesTotalTaxGen } from "../../../../../../../../Rentals/RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";
import { useEditLogs } from "../../../../../../../../../../../../hooks";
import ChainedRentalsView from "./ChainedRentalsView/ChainedRentalsView";

const CheckboxGroup = Checkbox.Group;
const IncludeRentals = ({ disabled }) => {
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { fakeApplication, darkMode } = useStateContext();
  const [selectedRentals, setSelectedRentals] = useState(
    fakeApplication?.includedRentals?.map(({ rentalId }) => rentalId)
  );
  const { rentals } = useSelector((state) => state.rentals);
  const dispatchContext = useDispatchContext();
  const { applications } = useSelector((state) => state.applications);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRentals(
      fakeApplication?.includedRentals?.map(({ rentalId }) => rentalId)
    );
  };
  const { saveAddedLogs } = useEditLogs();

  // console.log("fakeApplication", fakeApplication);

  const options = useMemo(() => {
    if (!isModalVisible) return [];
    return rentals
      ?.sort((a, b) => b?.rentalNumber - a?.rentalNumber)
      ?.map((e) => {
        const { rentalId, rentalNumber, includedTo, services = [] } = e;
        const {
          // totalTax = 0,
          totalPrice = 0,
          // retainageAmount = 0,
        } = totalitiesTotalTaxGen({ fakeRentals: e });
        console.log("services", services);
        let quickBooks =
          uniqBy(
            services?.map(({ quickbooksNumber = "" }) => {
              return { quickbooksNumber };
            }) || [],
            "quickbooksNumber"
          )?.[0]?.quickbooksNumber || "";
        console.log("quickBooks", quickBooks);
        return (
          {
            label: `Rental ${rentalNumber} | ${currencyFormatter(
              totalPrice
            )} | Qb Ref. ${quickBooks}`,
            includedTo,
            fakeApplication: fakeApplication?.applicationId,
            value: rentalId,
            quickBooks:
              services?.map(({ quickBooksNumber = "" }) => quickBooksNumber) ||
              [],
            disabled:
              includedTo === "" || includedTo === fakeApplication?.applicationId
                ? false
                : true,
          } || {}
        );
      });
  }, [rentals, fakeApplication, isModalVisible]);

  // console.log("options", options);
  const selectedRentalsData = rentals?.filter(({ rentalId }) =>
    selectedRentals?.some((selectedRentals) => selectedRentals === rentalId)
  );

  const findUniqueBetweenArrays = (arr1, arr2) => {
    return arr2?.filter((value) => !arr1?.includes(value));
  };

  const unchainRentals = useMemo(() => {
    if (isModalVisible) {
      let tempRentals = selectedRentals || [];
      let allRentals =
        fakeApplication?.includedRentals?.map(({ rentalId }) => rentalId) || [];
      if (
        tempRentals.length > 0 &&
        allRentals.length > 0 &&
        tempRentals.length !== allRentals.length
      ) {
        return findUniqueBetweenArrays(tempRentals, allRentals);
      } else return [];
    }
  }, [fakeApplication, selectedRentals, isModalVisible]);

  const confirmIncludedRentals = async () => {
    if (unchainRentals.length > 0) {
      await unchainRentalsFromApp(unchainRentals);
    }
    return confirmIncludedRentalsHandler({
      updateApplication,
      setIsModalVisible,
      fakeApplication,
      selectedRentals,
      dispatchContext,
      rentals,
      dispatch,
      applications,
      saveAddedLogs,
    });
  };

  const filteredColumnDefs = previewColumnDefs;

  useEffect(() => {
    if (isModalVisible) {
      setSelectedRentals(
        fakeApplication?.includedRentals?.map(({ rentalId }) => rentalId)
      );
    }
  }, [JSON.stringify(fakeApplication?.includedRentals)]);

  return (
    <>
      <MondayButton
        className={`mondayButtonBlue ${!disabled ? "" : "buttonNotClickable"}`}
        //disabled={disabled}
        onClick={() =>
          !disabled
            ? setIsModalVisible(true)
            : message.error("Please enable Edit Mode!")
        }
        Icon={<Close />}
        id="includeRentalsButton"
      >
        Include Rentals
      </MondayButton>

      {isModalVisible && (
        <Modal
          title="Include Rentals"
          open={isModalVisible}
          wrapClassName={
            darkMode ? "includeRentalsModalDark" : "includeRentalsModalLight"
          }
          disabled={disabled}
          closeIcon={<Close />}
          onOk={() => {}}
          onCancel={handleCancel}
          destroyOnClose
          footer={
            <>
              <MondayButton
                className="mondayButtonRed"
                onClick={handleCancel}
                Icon={<Close />}
              >
                Cancel
              </MondayButton>

              <MondayButton
                onClick={() => setConfirmationModalVisibility(true)}
                Icon={<TickIcon />}
              >
                Save Changes
              </MondayButton>
            </>
          }
        >
          <ChainedRentalsView />
          {!!rentals?.filter(
            (rentalData) =>
              rentalData?.projectId === fakeApplication?.projectId &&
              !rentalData?.includet
          )?.length ? (
            <>
              <div className="rentalListMainDivContainer">
                <div className="rentalsListContainer">
                  <CheckboxGroup
                    options={options}
                    onChange={(selectedRentals) =>
                      setSelectedRentals(selectedRentals)
                    }
                    value={selectedRentals}
                  />
                </div>
                <div className="buttonListContainer">
                  {rentals
                    ?.filter(
                      (rentalData) =>
                        rentalData?.projectId === fakeApplication?.projectId &&
                        !rentalData?.includet
                    )
                    ?.map(({ rentalId = "" }, index) => (
                      <MondayButton
                        key={index}
                        hasIcon={false}
                        className="mondayButtonBlue"
                        onClick={() => redirectToRental({ rentalId })}
                      >
                        View rental
                      </MondayButton>
                    ))}
                </div>
              </div>
              {!!selectedRentalsData?.length && (
                <div className="selectedRentalsDetailsInformationDiv">
                  {selectedRentalsData?.map((rental) => (
                    <div className="includedRentals">
                      <div className="header">
                        Rental {rental?.rentalNumber}
                      </div>
                      <div
                        className={
                          darkMode
                            ? "body agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
                            : "body agGridRentalsDetailsStyleLight ag-theme-alpine"
                        }
                      >
                        <AgGridReact
                          suppressSizeToFit={true}
                          rowSelection="multiple"
                          paginationPageSize={14}
                          rowHeight={40}
                          columnDefs={filteredColumnDefs}
                          rowData={cloneDeep(rentalDataExtractor({ rental }))}
                          suppressDragLeaveHidesColumns={true}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="noRentalsContainer">
              <div className="textContainer">No rentals created</div>
              <div className="textContainer">
                Get started by creating a rental
              </div>
            </div>
          )}
        </Modal>
      )}
      <AccountingConfirmationModal
        {...{
          visible: confirmationModalVisibility,
          setVisible: setConfirmationModalVisibility,
          onConfirm: confirmIncludedRentals,
          text: "To include rentals we have to save application!! Are you sure that you want to save?",
          darkMode: darkMode,
          discardButton: false,
        }}
      />
    </>
  );
};

export default IncludeRentals;
