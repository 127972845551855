export const sortServices = (service, serviceToCompare) => {
  const compareFunction = (a, b) => {
    const aIndex = serviceToCompare.findIndex((item) => item.label === a.label);
    const bIndex = serviceToCompare.findIndex((item) => item.label === b.label);

    // Handle case where label is not found in serviceToCompare
    if (aIndex === -1 && bIndex === -1) {
      return 0; // Maintain the order of items in service
    } else if (aIndex === -1) {
      return 1; // a should come after b
    } else if (bIndex === -1) {
      return -1; // a should come before b
    }

    return aIndex - bIndex;
  };

  const services = [...service].sort(compareFunction);
  const servicesToCompare = [...serviceToCompare].sort(compareFunction);

  return {
    services,
    servicesToCompare,
  };
};

export const sortByKey = (array, key) => {
  return array.sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA > valueB) {
      return -1;
    }
    if (valueA < valueB) {
      return 1;
    }
    return 0;
  });
};
