import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { ShareAltOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import EventsCalculatorModal from "./components/EventsCalculatorModal/EventsCalculatorModal";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  customBalancePriceOfSOVHandler,
  totalPriceOfSOVHandler,
} from "../../../../../Header/Components/ControlPanel/controlPanelFunctions";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import { getElevationAddonsPrice } from "../../../../../../../../../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/ServicePrices";
import { getElevationPrice } from "./components/EventsCalculatorModal/EventsCalculatorBody";
import { useRedux } from "../../../../../../../../../../hooks";
import { getRowData } from "./components/EventsCalculatorModal/ElevationGridComp/rowData";

const ElevationEventsCalc = (props) => {
  const [isModalVisib, setIsModalVisib] = useState(false);
  const { fakeSOV = {} } = useStateContext();
  const [rowDatas, setRowDatas] = useRedux("rowDatasEventsSov", [], false);
  const [distributedElevationData, setDistributedElevationData] = useRedux(
    "distributedElevationData",
    [],
    false
  );

  const getDistributedElevationData = useCallback((service) => {
    const { serviceOptions = [] } = service || {};
    if (
      Array.isArray(service?.distributedElevationData) &&
      service?.distributedElevationData?.length > 0
    ) {
      return service?.distributedElevationData;
    }
    // if (!serviceOptions?.length && ) return [];
    const elevationData = serviceOptions?.[0]?.map?.((serviceOption, index) => {
      const { price = 0, taxAmount = 0 } = getElevationPrice(
        service,
        serviceOption,
        index
      );
      let { elevationAddonPrice = 0, elevationAddonTax = 0 } =
        getElevationAddonsPrice(serviceOption);
      return {
        ...serviceOption,
        price,
        taxAmount,
        elevationAddonPrice,
        elevationAddonTax,
        maxPrice: price + elevationAddonPrice,
        legacyPrice: price + elevationAddonPrice,
      };
    });
    if (
      Array.isArray(service?.serviceAddons) &&
      service?.serviceAddons?.length > 0
    ) {
      service?.serviceAddons?.forEach((item) => {
        elevationData.push({
          // ...item,
          price: item?.totalPrice,
          taxAmount: item?.includedTax?.taxAmount,
          elevationAddonPrice: 0,
          elevationAddonTax: 0,
          type: "serviceAddon",
          elevationId: "SERVICE_ADDON",
          elevationLabel: item?.name,
          maxPrice: item?.totalPrice,
          legacyPrice: item?.totalPrice,
        });
      });
    }
    // Now we have to also add service addon amounts
    console.log("srevicce", { service, elevationData });
    return elevationData;
  }, []);

  const modalSwitcher = useCallback(() => {
    setIsModalVisib(true);
    console.log("modalSwitcher", { isModalVisib, props });
    const service = fakeSOV?.services?.find(
      (e) =>
        e?.serviceId?.toString() === props?.service?.serviceId?.toString() &&
        e?.estimationId === props?.service?.estimationId
    );
    console.log("service", { fakeSOV, props });
    message.loading("We are performing calculations, hold on tight...", 2);

    let myData = getDistributedElevationData(service);
    setDistributedElevationData(myData);

    if (Array.isArray(service?.expandedAmounts)) {
      setRowDatas(service?.expandedAmounts);
    } else {
      let myRows = [];

      if (Array.isArray(myData) && myData.length > 0) {
        myData.forEach((item) => {
          myRows.push(getRowData({ service, serviceOption: item }));
        });
      }
      console.log("myRows", myRows);
      setRowDatas(myRows);
    }
  }, [props.service, fakeSOV]);

  return (
    <>
      <MondayButton
        className={"mondayButtonBlue"}
        Icon={<ShareAltOutlined />}
        onClick={modalSwitcher}
      >
        Elevation Events
      </MondayButton>

      {isModalVisib && (
        <EventsCalculatorModal
          {...{
            ...props,
            isOpen: isModalVisib,
            onClose: () => setIsModalVisib(false),
          }}
        />
      )}
    </>
  );
};

export default ElevationEventsCalc;
