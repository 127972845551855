import { useRef, useState } from "react";
import { Carousel, Image } from "antd";
import { useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { InspectionModal } from "../../../components/SidebarPages/XComponents";
import { driveApi } from "../../DriveRequest";
import { SmallLoadableComp } from "../../../components/Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { MondayButton } from "src/components/commonComponents";

import "./inspectionGallery.scss";

const InspectionGallery = ({ data, fileNames = [], title }) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const driveRequest = driveApi({ accessToken });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselRef = useRef(null);
  const [images, setImages] = useState([]);
  const [visible, setVisible] = useState(false);
  const [folderId, setFolderId] = useState(false);
  const [loading, setLoading] = useState(false);

  const findFolderId = (obj) => {
    let id = "";
    const keyOfFolderId = Object.keys(obj)?.find((key) =>
      key?.toLowerCase()?.includes("folderid")
    );
    if (!keyOfFolderId) {
      const details = Object.keys(obj)?.find((key) =>
        key?.toLowerCase()?.includes("details")
      );

      const objectOfFolderId = obj[details]?.find((detail) =>
        detail?.label?.toLowerCase()?.includes("folderid")
      );
      if (!!objectOfFolderId) {
        const key = Object.keys(objectOfFolderId)?.find((key) =>
          key?.toLowerCase()?.includes("folderid")
        );
        id = objectOfFolderId[key];
      }
    } else {
      id = obj[keyOfFolderId];
    }

    return id;
  };

  const getItemDetailsFromId = async (fileId, mimeType, type) => {
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const fileDetailsResponse = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileId}/?fields=size,createdTime,thumbnailLink`,
        {
          headers,
        }
      );
      if (fileDetailsResponse.ok) {
        const fileDetails = await fileDetailsResponse.json();
        console.log({ fileDetails });

        if (type === "photo" || mimeType === "application/pdf") {
          await driveRequest.getImageSrc(fileId).then((imageObj) => {
            fileDetails.src = imageObj.src;
            fileDetails.blob = imageObj.blob;
          });
        }
        return fileDetails;
      } else {
        console.error("Failed to retrieve file details");
      }
    } catch (error) {
      console.error("Error retrieving file details", error);
    }
  };

  const getFilesFromFolder = async (folderId) => {
    const imagesArray = [];
    await driveRequest.getFilesThumbnails(folderId).then(async (res) => {
      if (!!res) {
        await res.json().then(async ({ files }) => {
          let filterFiles = files.filter(
            (item) => item.mimeType !== "application/vnd.google-apps.folder"
          );
          if (!!filterFiles?.length) {
            await Promise.all(
              filterFiles.map(async (file) => {
                if (
                  file?.mimeType.includes("image") ||
                  file?.mimeType === "application/pdf"
                ) {
                  const imageObj = await driveRequest.getImageSrc(file.id);
                  imagesArray.push({ ...imageObj, ...file });
                } else {
                  imagesArray.push({ ...file });
                }
              })
            );
          }
        });
      }
    });
    return imagesArray;
  };

  const handleOpen = async () => {
    setLoading(true);
    if (!folderId && !fileNames?.length) {
      const folderId = findFolderId(data);
      setFolderId(folderId);
      if (!!accessToken && !!folderId && !images?.length) {
        if (data?.inspectionId && data?.projectId) {
          // get folder ID of this inspection
          driveRequest
            .getDriveItemId({ name: data.inspectionId, parents: [folderId] })
            .then((res) => {
              if (!!res) {
                // get folders inside inspection folder
                driveRequest.getFilesByFolderId(res).then(async (res) => {
                  if (!!res) {
                    await res.json().then(async ({ files }) => {
                      // find folder that have the media files
                      const inspFolder = files.find(
                        ({ name }) => name === data.inspectionId
                      );
                      if (inspFolder?.id) {
                        // get media files
                        const files = await getFilesFromFolder(inspFolder?.id);
                        setImages(files || []);
                        setLoading(false);
                      }
                    });
                  }
                });
              }
            });
        } else if (!!fileNames?.length) {
          fileNames?.filter(Boolean)?.map(async ({ id }) => {
            await driveRequest
              .getImageSrc(id)
              .then((imageObj) => {
                if (imageObj?.src.includes("data:image/")) {
                  setImages((prev) => [...prev, { ...imageObj, id }]);
                }
              })
              .then(() => setLoading(false));
          });
        } else if (!!data?.googleDriveFolderId) {
          // this condition is for defects only
          data?.googleDriveFolderId?.filter(Boolean)?.map(async ({ id }) => {
            await driveRequest
              .getImageSrc(id)
              .then((imageObj) => {
                if (imageObj?.src.includes("data:image/")) {
                  setImages((prev) => [...prev, { ...imageObj, id }]);
                }
              })
              .then(() => setLoading(false));
          });
        } else {
          const files = await getFilesFromFolder(folderId);
          setImages(files || []);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else if (!!fileNames?.length) {
      Promise.allSettled(
        fileNames.map(async (item) => {
          const fileDetails = await getItemDetailsFromId(
            item?.id,
            item.mimeType,
            item.type
          );
          return { ...fileDetails, ...item };
        })
      ).then((res) => {
        const filtered = res
          ?.filter(({ status }) => status === "fulfilled")
          ?.map(({ value }) => value);
        setLoading(false);
        setImages(filtered);
      });
    }
    setVisible(true);
  };

  function getFileSize(sizeStr) {
    let size = parseInt(sizeStr);
    if (size < 1024) return `Size: ${size} Bytes`;
    else if (size >= 1024 && size < 1024 * 1024)
      return `Size: ${(size / 1024).toFixed(2)} KB`;
    else if (size >= 1024 * 1024)
      return `Size: ${(size / (1024 * 1024)).toFixed(2)} MB`;
  }

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
    carouselRef.current.goTo(index);
  };

  const handleCancel = () => {
    setVisible(false);
    setFolderId(false);
    setImages([]);
  };

  return (
    <div className="inspectionGallery-container">
      <button className="inspectionGalleryCell" onClick={handleOpen}>
        View
      </button>

      <InspectionModal
        visible={visible}
        setVisible={setVisible}
        onCancel={handleCancel}
        closable={true}
        title={title || "Gallery"}
        className={`galleryInspection ${isDarkMode && "galleryInspectionDark"}`}
        footer={false}
      >
        <SmallLoadableComp loading={loading} darkMode={isDarkMode}>
          {!!images?.length ? (
            <>
              <Carousel
                className="galleryCarousel"
                ref={carouselRef}
                arrows
                draggable
                dots={false}
                closeIcon={null}
                prevArrow={<LeftOutlined />}
                nextArrow={<RightOutlined />}
                afterChange={(currentSlide) => {
                  setCurrentImageIndex(currentSlide);
                }}
              >
                {images.filter(Boolean).map((image, index) => (
                  <div
                    key={index}
                    style={{ width: "100%" }}
                    className="fileContainer"
                  >
                    {image?.mimeType?.includes("image") ? (
                      <Image src={image?.src} className="imageFile" />
                    ) : image.mimeType === "application/pdf" ? (
                      <iframe src={image?.src} className="iframeFile" />
                    ) : (
                      <img
                        src={image?.thumbnailLink}
                        alt={image?.name}
                        className="imageFile"
                      />
                    )}
                  </div>
                ))}
              </Carousel>
              <div className="thumbnailContainer">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`thumbnail ${
                      currentImageIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleThumbnailClick(index)}
                  >
                    <img
                      src={image?.src || image?.thumbnailLink}
                      alt={`Thumbnail ${index}`}
                      className="thumbnailImage"
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <span className="noImages">No Images to show</span>
          )}
          {!!images?.filter(Boolean)?.length && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MondayButton
                {...{
                  className: "mondayButtonBlue",
                  Icon: <DownloadIcon />,
                  onClick: () => {
                    window.open(
                      `https://drive.google.com/u/1/uc?id=${images?.[currentImageIndex]?.id}&export=download`
                    );
                  },
                }}
              >
                Download
              </MondayButton>
              <div className="fileInfo">
                <p>{getFileSize(images?.[currentImageIndex]?.size)} </p>
                <a
                  target="_blank"
                  href={`https://drive.google.com/file/d/${images?.[currentImageIndex]?.id}`}
                >
                  Go To File
                </a>
              </div>
            </div>
          )}
        </SmallLoadableComp>
      </InspectionModal>
    </div>
  );
};

export default InspectionGallery;
