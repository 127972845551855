import React from "react";
import { useAdvancedFilter } from "../hooks";

const AdvancedFilterContext = React.createContext();

export const AdvancedFilterProvider = ({
  children,
  customFilterTitle,
  fetchAllHandler,
}) => {
  const filterValues = useAdvancedFilter({
    customFilterTitle,
    fetchAllHandler,
  });

  return (
    <AdvancedFilterContext.Provider value={{ ...filterValues }}>
      {children}
    </AdvancedFilterContext.Provider>
  );
};

export const useAdvancedFilterContext = () => {
  const context = React.useContext(AdvancedFilterContext);
  if (!context) {
    throw new Error(
      "useAdvancedFilterContext must be used within a AdvancedFilterProvider"
    );
  }
  return context;
};
