import { forwardRef, useImperativeHandle, useState } from "react";

import { InputComponent } from "src/components/SidebarPages/Fleet/components";

const ReasonEditor = forwardRef(
  ({ stopEditing, data, context, column }, ref) => {
    const [option, setOption] = useState(data[column["colId"]]);

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue() {
            return option;
          },
          isCancelAfterEnd() {
            return false;
          },
        };
      },
      [option]
    );

    return (
      <InputComponent
        {...{
          label: null,
          type: "select",
          className: "form-editor select-picker-editor",
          customOptions: [],
          autoFocus: true,
          defaultOpen: true,
          initialValue: option,
          customOptions: (context?.inactivityStatuses || [])?.map((e) => ({
            label: e,
            value: e,
          })),
          onBlur() {
            stopEditing();
          },
          onSelect(val) {
            setOption(val);
            setTimeout(() => {
              stopEditing();
            }, 0);
          },
        }}
      />
    );
  }
);

export default ReasonEditor;
