import React from "react";

import { useStepData } from "../../hooks";
import { NextStepInputs } from "../NextStepInputs";
import { generateViolationsInputs } from "./helpers";

const NewViolations = ({ inputData, savedRecord, setInputData }) => {
  const { drivers, fleetFields, projects } = useStepData("Violations");

  const violationStatuses =
    fleetFields
      ?.find((field) => field.fieldName === "Violation Status")
      ?.fieldOptions?.map((option) => ({
        id: option.statusId,
        value: option.statusLabel,
        label: option.statusLabel,
      })) || [];

  const inputFields = generateViolationsInputs({
    drivers,
    projects,
    violationStatuses,
  });

  const generalInput = inputFields["General Information"];

  const inputFieldsAmount = inputFields["Violation Amount"];

  return (
    <>
      <NextStepInputs
        {...{ inputData, setInputData, inputFields: generalInput }}
      />
      <NextStepInputs
        {...{ inputData, setInputData, inputFields: inputFieldsAmount }}
      />
    </>
  );
};

export default NewViolations;
