import { InfoWindow } from "@react-google-maps/api";
import { Input, Timeline, Tooltip, Tour } from "antd";
import {
  DeleteFilled,
  EditFilled,
  InfoCircleFilled,
  QuestionCircleFilled,
  ShrinkOutlined,
} from "@ant-design/icons";
import MondayButton from "../../../MondayButton/MondayButton";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { useState } from "react";
import { useMultipleRefs } from "src/hooks/useMultipleRefs";
import useCalculateCenterOfCoordinates from "src/hooks/useCalculateCenterOfCoordinates";
import { Notes } from "../../../Notes/Notes";
import {
  curr,
  prev,
} from "../../../../pages/Settings/settingsComponents/GeofenceConfigurations/AllProgramGeofences/utils/logsFunctions";

export const inputStyle = {
  backgroundColor: "#F5F5F7",
  border: "none",
  marginTop: "5px",
};
const { TextArea } = Input;

const ActionsComponent = ({
  clickedShape,
  setClickedShape,
  geofenceStrucutre,
  setShapeInfo,
  shapeInfo,
  setEditShape,
  widowsInfoOpener,
  editShape,
  polygonCompleted,
  setWidowsInfoOpener,
  allowEditing,
  setGeoFenceInfo,
  setGeofenceStrucutre = () => {},
  closeInfoWindow = () => {},
  setUnsavedLogs,
  showNotesOption,
}) => {
  const { calculateCenter } = useCalculateCenterOfCoordinates();
  const [
    editTitleRef,
    closeWindowRef,
    deleteShapeRef,
    editShapeRef,
    setupLaterRef,
  ] = useMultipleRefs(6);
  const [open, setOpen] = useState(false);

  // delete shape function
  const handleDeleteShape = () => {
    const updatedGeoInfo = geofenceStrucutre.filter(
      ({ shapeId }) => shapeId !== clickedShape.shapeId
    );
    if (!!setGeoFenceInfo) {
      !!setUnsavedLogs &&
        setUnsavedLogs((prev) => [
          ...prev,
          { ...clickedShape, actionType: "Delete" },
        ]);
      setGeoFenceInfo(updatedGeoInfo);
      setGeofenceStrucutre(updatedGeoInfo);
    } else {
      setGeofenceStrucutre(updatedGeoInfo);
      polygonCompleted(
        updatedGeoInfo,
        prev({ ...clickedShape, actionType: "Delete" }),
        curr({ ...clickedShape, actionType: "Delete" }),
        "Geofence",
        "Delete",
        {
          onAction: "onDeletion",
          commonNext: clickedShape.title,
        }
      );
    }

    closeInfoWindow();
  };

  const handleInformationSubmit = () => {
    const strucutre = geofenceStrucutre.map((geofence) => {
      if (geofence.shapeId === clickedShape.shapeId) {
        return {
          ...geofence,
          title: shapeInfo.title || "",
          description: shapeInfo.description || "",
        };
      }
      return geofence;
    });

    if (!!setGeoFenceInfo) {
      setGeoFenceInfo(strucutre);
      setGeofenceStrucutre(strucutre);
    } else {
      setGeofenceStrucutre(strucutre);
    }

    setWidowsInfoOpener("");
    return strucutre;
  };

  function createPolygonCompleted(action) {
    polygonCompleted(
      handleInformationSubmit(),
      prev({ ...clickedShape, actionType: "Create" }),
      curr({ ...clickedShape, actionType: "Create" }),
      "Geofence",
      action || "",
      {
        onAction: "onCreation",
        commonNext: shapeInfo.title,
      }
    );
  }

  const mondayButtonType =
    widowsInfoOpener === "creation"
      ? (shapeInfo.title !== "" && {
          children: `Save ${clickedShape.type}`,
          icon: <TickIcon />,
          tooltip: "Save Polygon",
          color: "mondayButtonGreen",
          onClick: () => {
            !!setGeoFenceInfo
              ? handleInformationSubmit()
              : createPolygonCompleted("Create");
            closeInfoWindow();
          },
        }) ||
        (shapeInfo.title === "" && {
          children: "Setup Later",
          icon: <ShrinkOutlined />,
          tooltip: "Close Window and setup later",
          color: "mondayButtonBlue",
          ref: setupLaterRef,
          onClick: () => {
            !!setGeoFenceInfo
              ? handleInformationSubmit()
              : createPolygonCompleted("Create");
            closeInfoWindow();
          },
        })
      : widowsInfoOpener === ""
      ? {
          icon: <XIcon />,
          tooltip: "Close Window",
          color: "mondayButtonRed",
          onClick: closeInfoWindow,
          hasIcon: false,
          ref: closeWindowRef,
        }
      : "";

  const steps = [
    {
      title: "Edit Shape Information",
      description: "Click this Icon to enable editing for Tile and description",
      target: () => editTitleRef.current,
    },
    {
      title: "Close Window",
      description: "Click this Icon to close this Modal",
      target: () => closeWindowRef.current,
    },
    {
      title: "Delete Shape",
      description: "Click this Icon to delete shape",
      target: () => deleteShapeRef.current,
    },
    {
      title: "Edit Shape",
      description: "Click this Icon to Be able to edit shape",
      target: () => editShapeRef.current,
    },
    {
      title: "Setup Later",
      description: "This Will close and save shape",
      target: () => setupLaterRef.current,
    },
  ];

  const filteredSteps = steps?.filter((step) => step?.target());

  return (
    clickedShape && (
      <InfoWindow
        position={calculateCenter(clickedShape.geoFenceInfo)}
        onCloseClick={closeInfoWindow}
      >
        <div className="custom-shape-info">
          {allowEditing && (
            <>
              <div
                className="custom-shape-info-header"
                onClick={() => setOpen(true)}
              >
                <InfoCircleFilled className="shape-info-icon" />
                <span className="shape-info-text">
                  Take a tour how to use this modal
                </span>
              </div>
              <Tour
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                steps={filteredSteps}
                onFinish={() => {
                  setOpen(false);
                }}
              />
            </>
          )}
          <div className="custom-shape-info-head">
            {allowEditing ? (
              <>
                <label>Title</label>
                <TextArea
                  disabled={widowsInfoOpener === "" ? !editShape : false}
                  style={{ ...inputStyle, marginBottom: "5px" }}
                  placeholder="title of shape here..."
                  onChange={(e) =>
                    setShapeInfo((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  value={shapeInfo.title}
                />
                <label>Description</label>
                <TextArea
                  disabled={widowsInfoOpener === "" ? !editShape : false}
                  placeholder="description here..."
                  style={inputStyle}
                  onChange={(e) =>
                    setShapeInfo((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  value={shapeInfo.description}
                />
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "30px",
                  marginTop: "15px",
                }}
              >
                <Timeline
                  mode={"left"}
                  items={[
                    {
                      label: "Title",
                      children: clickedShape.title || "-||-",
                    },
                    {
                      label: "Description",
                      children: clickedShape.description || "-||-",
                    },
                    {
                      label: "Created By",
                      children: clickedShape?.createdBy || "-||-",
                    },
                    {
                      label: "Created At",
                      children: dayjsNY(clickedShape.createdAt).format(
                        "MM/DD/YYYY"
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>

          {allowEditing && (
            <div className="custom-shape-info-footer">
              <div style={{ display: "grid", gap: "5px" }}>
                <span>Shape actions</span>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Tooltip title="Delete Shape" color="red">
                    <div ref={deleteShapeRef}>
                      <MondayButton
                        className="mondayButtonRed"
                        Icon={<DeleteFilled />}
                        onClick={handleDeleteShape}
                      />
                    </div>
                  </Tooltip>
                  {clickedShape.type !== "Circle" &&
                    clickedShape.type !== "Square" && (
                      <Tooltip title="Edit Shape" color="blue">
                        <div ref={editShapeRef}>
                          <MondayButton
                            className="mondayButtonBlue"
                            Icon={<EditFilled />}
                            onClick={() => {
                              setEditShape(clickedShape);
                              setClickedShape(false);
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  {showNotesOption && (
                    <Notes
                      {...{
                        noteModalName: clickedShape.title,
                        rowId: clickedShape.shapeId,
                        topicCategory: "Geofence",
                        customLinkTo: window.location.pathname.substring(1),
                      }}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "grid", gap: "5px" }}>
                <span>Information Actions</span>
                <div style={{ display: "flex", gap: "5px" }}>
                  {widowsInfoOpener === "" && (
                    <>
                      <Tooltip title="Enable Edit Information(title and description)">
                        <div ref={editTitleRef}>
                          <MondayButton
                            className="mondayButtonBlue"
                            hasIcon={false}
                            Icon={<EditFilled />}
                            onClick={() => setEditShape(true)}
                          />
                        </div>
                      </Tooltip>
                      {(clickedShape?.title !== shapeInfo?.title ||
                        clickedShape?.description !==
                          shapeInfo?.description) && (
                        <Tooltip title="Save changes">
                          <MondayButton
                            className="mondayButtonGreen"
                            hasIcon={false}
                            Icon={<TickIcon />}
                            onClick={() => {
                              !!setGeoFenceInfo
                                ? handleInformationSubmit()
                                : polygonCompleted(handleInformationSubmit());
                              closeInfoWindow();
                            }}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                  <Tooltip title={mondayButtonType.tooltip}>
                    <div ref={mondayButtonType.ref}>
                      <MondayButton
                        className={mondayButtonType.color}
                        Icon={mondayButtonType.icon}
                        onClick={mondayButtonType.onClick}
                        hasIcon={mondayButtonType.hasIcon}
                      >
                        {mondayButtonType.children}
                      </MondayButton>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      </InfoWindow>
    )
  );
};

export default ActionsComponent;
