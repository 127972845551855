export function removeEmptyConditionsAndFilters(filters) {
  if (filters && filters.length === 0) return [];
  // Filter out conditions with empty values
  const filteredFilters = filters.filter((filter) => {
    if (filter.conditions && filter.conditions.length > 0) {
      filter.conditions = filter.conditions.filter((condition) => {
        return condition.value !== "";
      });
      return filter.conditions.length > 0;
    }
    return false;
  });

  return filteredFilters || [];
}
