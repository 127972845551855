import {
  isYard,
  getFence,
  isEnteredAlert,
  compareFences,
} from "./filterAlertsOnTruck";
import { TIME_TOLERANCE } from "../data";
import matchTruckActivities from "./matchTruckActivities";
import getTripsFromActualActivities from "./getTripsFromActualActivities";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";

function createLiveLocationObject(
  activityObject,
  matchObject,
  manualNextLocation = ""
) {
  let startRemark = "";
  let endRemark = "";
  if (!matchObject) {
    startRemark = "";
    if (activityObject?.startingTime) {
      if (
        Date.now() >
        dayjsNY(activityObject?.startingTime).valueOf() + TIME_TOLERANCE
      ) {
        startRemark = "Delayed";
      }
    }
  } else {
    if (matchObject?.actualDepart) {
      if (
        dayjsNY(activityObject?.startingTime).valueOf() <
        matchObject?.actualDepart - TIME_TOLERANCE
      ) {
        startRemark = "Late";
      } else if (
        dayjsNY(activityObject?.startingTime).valueOf() >
        matchObject?.actualDepart + TIME_TOLERANCE
      ) {
        startRemark = "Early";
      } else {
        startRemark = "On Time";
      }
    } else if (activityObject?.startingTime) {
      if (
        Date.now() >
        dayjsNY(activityObject?.startingTime).valueOf() + TIME_TOLERANCE
      ) {
        startRemark = "Late";
      } else {
        startRemark = "";
      }
    }

    if (matchObject?.actualArrive) {
      if (
        dayjsNY(activityObject?.timeAtLocation).valueOf() + TIME_TOLERANCE <
        matchObject?.actualArrive
      ) {
        endRemark = "Late";
      } else if (
        dayjsNY(activityObject?.timeAtLocation).valueOf() - TIME_TOLERANCE >
        matchObject?.actualArrive
      ) {
        endRemark = "Early";
      } else {
        endRemark = "On Time";
      }
    } else {
      if (
        Date.now() >
        dayjsNY(activityObject?.timeAtLocation).valueOf() + TIME_TOLERANCE
      ) {
        endRemark = "Delayed";
      }
    }
  }

  return {
    activityId: activityObject?.activityId,
    plannedStart: activityObject?.startingTime,
    plannedEnd: activityObject?.timeAtLocation,
    driverName: activityObject?.driverName,
    driverId: activityObject?.driverId,
    fleetName: activityObject?.fleetName,
    fleetId: activityObject?.fleetId,
    fromLocation: activityObject?.pickUpLocation,
    toLocation: activityObject?.dropOffLocation,
    cargo: activityObject?.cargo,
    actualStart: matchObject?.actualDepart,
    actualEnd: matchObject?.actualArrive,
    startRemark,
    endRemark,
    nextLocation: manualNextLocation
      ? manualNextLocation
      : !matchObject?.actualArrive
      ? activityObject?.dropOffLocation
      : undefined,
  };
}

/**
 * Function that calculates the current activity of the truck.
 * This can be used to get the next location and the current status of the truck
 */
function getLiveActivity({
  planForTruck = [],
  actualActivities,
  auditsForTruck = {},
  geofences = {},
}) {
  /**
   * The point is to get the location match for every vehicle.
   * The last matched plan for the truck signals the last actual actions
   * taken by the vehicle. We can use the trip alerts to determine the
   * next locations for the truck and the actual times
   */
  if (!planForTruck?.length) {
    return {};
  }

  let trips = getTripsFromActualActivities(actualActivities, {
    needsValidation: false,
    auditsForTruck,
  });

  let activitiesMatch =
    matchTruckActivities({
      trips,
      planForTruck,
      forceOrder: true,
      geofences,
    }) || [];

  /**
   * If all the planned trips for the day found a match, we
   * can just return the last match
   */
  if (activitiesMatch?.length === planForTruck?.length) {
    let l = activitiesMatch?.length - 1;
    return createLiveLocationObject(planForTruck[l], activitiesMatch[l]);
  }

  /**
   * The last activity match holds the actual depart and arrive times
   * It also holds the ids of the plan it matched and the alerts that
   * compose it
   */
  let lastMatch = activitiesMatch[activitiesMatch?.length - 1] || {};
  let lastMatchedPlanIndex = planForTruck?.findIndex(
    ({ activityId }) => activityId === lastMatch?.activityId
  );

  /**
   * If none of the actual activities match the plan, it means
   * that the vehicle is yet to depart (or it just departed). If the vehicle matched a
   * plan, we need to see if there is a depart from the location.
   * If there isn't, that means that the truck is still inside the fence
   */
  if (lastMatchedPlanIndex === -1) {
    //firstly we need to check if the truck actually left the first location
    let firstDepart = getFence(planForTruck[0]["pickUpLocation"]);
    let actualDepartFromLocationIndex = trips?.findIndex(
      ({ fenceName, alertCode }) => {
        if (isEnteredAlert(alertCode)) {
          return false;
        }

        if (isYard(firstDepart)) {
          if (!isYard(fenceName)) {
            return false;
          }
        } else {
          if (getFence(fenceName) !== firstDepart) {
            return false;
          }
        }

        return true;
      }
    );

    if (actualDepartFromLocationIndex > -1) {
      return createLiveLocationObject(planForTruck[0], {
        actualDepart: trips[actualDepartFromLocationIndex]["alertDateTime"],
      });
    } else {
      return createLiveLocationObject(planForTruck[0]);
    }
  }

  //the activity that was matched
  let lastMatchedSchedule = planForTruck[lastMatchedPlanIndex];
  //the activity coming after that
  let nextScheduledLocation = planForTruck[lastMatchedPlanIndex + 1];
  //the index of the trip
  let lastActualTripMatchIndex = trips?.findIndex(
    ({ alertUUID }) => alertUUID === lastMatch["alertIds"][1]
  );

  if (!nextScheduledLocation) {
    return {};
  }

  if (lastActualTripMatchIndex > -1) {
    //we check wether the vehicle exited from the last match location
    let lastDropOff = lastMatchedSchedule["dropOffLocation"];
    let dropOffExitTrip = null;
    for (let i = lastActualTripMatchIndex; i < trips?.length; i++) {
      if (
        compareFences(trips[i]["fenceName"], lastDropOff) &&
        !isEnteredAlert(trips[i]["alertCode"])
      ) {
        dropOffExitTrip = trips[i];
        break;
      }
    }

    if (dropOffExitTrip) {
      //in this case, the truck has left the location, so we get the time
      return createLiveLocationObject(nextScheduledLocation, {
        actualDepart: dropOffExitTrip?.alertDateTime,
      });
    } else {
      //in this case, the truck has not left the location yet
      return createLiveLocationObject(
        lastMatchedSchedule,
        lastMatch,
        nextScheduledLocation?.["dropOffLocation"]
      );
    }
  }

  return {};
}

export default getLiveActivity;
