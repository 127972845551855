import React, { useState } from "react";
import { CheckboxHandlers } from "../../NotificationData";
import {
  showErrorMsg,
  showInfoMsg,
  showLoadingMsg,
} from "../../../../../../utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../DeleteModal";
import { updateNotifications } from "../../../../../../actions/notificationsActions";
import apiService from "../../utils/notifications-api";

function CheckboxHandlersActions({
  selectedItems,
  setSelectedItems,
  dataType,
  data,
  selectAllHandler,
  tabTitle,
  allSelected,
  setAllSelected,
}) {
  const dispatch = useDispatch();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const [open, setOpen] = useState(false);

  const checkboxActions = async (action, value) => {
    let tmpRequest = action === "delete" ? "delete" : "modify";

    let tmpAction;
    action === "hide"
      ? (tmpAction = "hasBeenHidden")
      : action === "mark as unread" || action === "mark as read"
      ? (tmpAction = "hasBeenSeen")
      : (tmpAction = null);

    if (dataType === "notifications") {
      const selectedNotifications = selectedItems;

      if (tmpAction) {
        const payload = {
          id: authenticatedUser.sub,
          action: tmpRequest,
          selectedNotifications,
          modifyKey: tmpAction,
          modValue: value,
          allSelected,
        };

        if (allSelected) {
          dispatch(
            updateNotifications({
              notifications: data.map((notification) => notification.id),
              action:
                tabTitle.toLowerCase() === "hidden" ? "move to all" : action,
            })
          );
          checkboxHandlerFunctions.removeSelectionHandler();
          apiService.modifyAll(payload);
          setAllSelected(false);
        } else {
          await apiService
            .modifySelection(payload)
            .then(() => {
              dispatch(
                updateNotifications({
                  notifications: selectedNotifications,
                  action:
                    tabTitle.toLowerCase() === "hidden"
                      ? "move to all"
                      : action,
                })
              );
            })
            .catch((error) => {
              showErrorMsg({
                content:
                  "Error while modifying selected notifications, please try again later!",
              });
              throw error;
            });
        }
      } else {
        const payload = {
          id: authenticatedUser.sub,
          action,
          selectedNotifications,
          allSelected,
        };

        if (allSelected) {
          dispatch(
            updateNotifications({
              notifications: data.map((notification) => notification.id),
              action,
            })
          );
          checkboxHandlerFunctions.removeSelectionHandler();
          apiService.modifyAll(payload);
          setAllSelected(false);
        } else {
          await apiService
            .modifySelection(payload)
            .then(() => {
              dispatch(
                updateNotifications({
                  notifications: selectedNotifications,
                  action,
                })
              );
            })
            .catch((error) => {
              showErrorMsg({
                content:
                  "Error while modifying selected notifications, please try again later!",
              });
              throw error;
            });
        }
      }
    }

    setSelectedItems([]);
  };

  const findNotificationsByIds = (ids, data) => {
    return data.filter((notification) => ids.includes(notification.id));
  };

  const checkboxHandlerFunctions = {
    toggleReadHandler: async () => {
      const selectedNotificationObjects = findNotificationsByIds(
        selectedItems,
        data
      );

      showLoadingMsg({ content: "Making selected notificatons as read..." });

      const unreadItems = selectedNotificationObjects.filter(
        (item) => !item.seen
      );

      if (unreadItems.length > 0) {
        await checkboxActions("mark as read", true).then(() => {
          if (allSelected) {
            showInfoMsg({
              content: "All notificatons marked as read",
            });
          } else {
            showInfoMsg({
              content: `${
                unreadItems.length > 1
                  ? `${unreadItems.length} Notifications have`
                  : `${unreadItems.length} Notification has`
              } been marked as read`,
            });
          }
          checkboxHandlerFunctions.removeSelectionHandler();
        });
      } else {
        showInfoMsg({
          content: `All selected notifications are already marked as read`,
        });
      }
    },

    toggleUnreadHandler: async () => {
      const selectedNotificationObjects = findNotificationsByIds(
        selectedItems,
        data
      );

      showLoadingMsg({ content: "Making selected notificatons as unread..." });

      const readItems = selectedNotificationObjects.filter((item) => item.seen);

      if (readItems.length > 0) {
        await checkboxActions("mark as unread", false);
        if (allSelected) {
          showInfoMsg({ content: "All notifications marked as unread" });
        } else {
          showInfoMsg({
            content: `${
              readItems.length > 1
                ? `${readItems.length} Notifications have`
                : `${readItems.length} Notification has`
            } been marked as unread`,
          });
        }
        checkboxHandlerFunctions.removeSelectionHandler();
      } else {
        showInfoMsg({
          content: `All selected notifications are already marked as unread`,
        });
      }
    },

    deleteSelectionsHandler: () => {
      setOpen(true);
    },
    hideSelectionsHandler: async (title) => {
      showLoadingMsg({
        content: "Hiding selected notificatons...",
      });

      let tmpValue = title === "Hidden" ? false : true;
      await checkboxActions("hide", tmpValue);
      if (allSelected) {
        showInfoMsg({
          content: `
                 All notifications been moved to ${
                   tmpValue === false ? "all" : "hidden"
                 } section`,
        });
      } else {
        showInfoMsg({
          content: `
              ${
                selectedItems && selectedItems.length > 1
                  ? `${selectedItems.length} Notifications have`
                  : `${selectedItems.length} Notification has`
              }
                been moved to ${tmpValue === false ? "all" : "hidden"} section`,
        });
      }
    },
    selectAllHandler: selectAllHandler,
    removeSelectionHandler: () => setSelectedItems([]),
  };

  return (
    <>
      <div className="notifications-modify">
        {CheckboxHandlers.map(({ title, handler }, i) => (
          <button
            key={i}
            onClick={() => {
              checkboxHandlerFunctions[handler](tabTitle);
            }}
          >
            {title}
          </button>
        ))}
      </div>

      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={async () => {
          showLoadingMsg({
            content: "Deleting selected notificatons...",
          });

          checkboxActions("delete", null);
          if (allSelected) {
            showInfoMsg({
              content: "All notifications have been deleted",
            });
          } else {
            showInfoMsg({
              content: `${
                selectedItems && selectedItems.length > 1
                  ? `${selectedItems.length} Notifications have`
                  : `${selectedItems.length} Notification has`
              } been deleted`,
            });
          }
        }}
        notificationLength={selectedItems.length}
      />
    </>
  );
}

export default CheckboxHandlersActions;
