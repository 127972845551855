import React, { useEffect, useRef, useState } from "react";
import ProposalTinyEditor from "./components/ProposalTinyEditor/ProposalTinyEditor";
import ProposalHeader from "./components/ProposalHeader/ProposalHeader";
import ProposalSidebar from "./components/ProposalSidebar/ProposalSidebar";
import "./ProposalBuilder.scss";
import { useLocation } from "react-router-dom";
import { useRedux } from "../SidebarPages/hooks";
import ProposalBodyStepper from "./components/ProposalBodyStepper/ProposalBodyStepper";
import { MondayButton } from "../commonComponents";
import { fetchData } from "../SidebarPages/Fleet/utils";
import { API } from "aws-amplify";
import { Button } from "antd";
import RealTimeTakeOffPreview from "./components/ProposalHeader/components/RealTimeTakeOffPreview/RealTimeTakeOffPreview";
import JoditEditorCustom from "./components/JoditEditorCustom/JoditEditorCustom";
import { LoadableComp } from "../SidebarPages/XComponents";
// location state needs to have this structure state {estimation: {…}, takeOff: {…}, takeOffId: ...}
function ProposalBuilder() {
  const editorRef = useRef(null);
  const [proposalData, setProposalData] = useRedux("proposalData", {}, false);
  const { state = {} } = useLocation();
  const [visbCheckers, setVisbCheckers] = useState({
    realTimePreview: false,
    templateBuilder: true,
    sidebar: true,
  });

  const getDataFromQueryParams = async () => {
    // new url params
    const urlParams = new URLSearchParams(window.location.search);
    const proposalId = urlParams.get("proposalId");
    const takeOffId = urlParams.get("takeOffId");
    let result = {};
    await API.get("proposalBuilds", `/proposalBuilds/${proposalId}`).then(
      async (currentProposal) => {
        result.currentProposal = currentProposal;
        await API.get(
          "estimations",
          `/estimations/${currentProposal?.estimationId}`
        ).then((estimation) => {
          result.estimation = estimation;
        });
      }
    );
    result.proposalId = proposalId;
    result.takeOffId = takeOffId;
    console.log("urlParams", {
      proposalId,
      takeOffId,
      result,
    });
    return result;
  };

  const onViewChange = (activeView) => {
    if (visbCheckers?.realTimePreview) {
      setVisbCheckers({
        realTimePreview: false,
        templateBuilder: true,
        sidebar: true,
      });
    } else {
      setVisbCheckers({
        realTimePreview: true,
        templateBuilder: true,
        sidebar: false,
      });
    }
  };

  useEffect(() => {
    console.log("state", state);
    // if (!!state?.takeOff && !!state?.estimation) {
    //   const { takeOff = {}, estimation = {} } = state;
    //   setProposalData({
    //     takeOff,
    //     estimation,
    //     realTimePreview: false,
    //     realTimePreviewData: {},
    //     templateData: {},
    //   });
    // } else {
    getDataFromQueryParams().then((result) => {
      console.log("getDataFromQueryParams", result);

      setProposalData({
        takeOff:
          result?.estimation?.versionServices?.[`${result?.takeOffId}`] || {},
        estimation: result?.estimation || {},
        realTimePreview: false,
        realTimePreviewData: {},
        proposalId: result?.proposalId,
        takeOffId: result?.takeOffId,
        wrappedHtml: result?.currentProposal?.wrappedHtml || "",
        templateData: {
          ...(result?.currentProposal?.templateData || {}),
        },
        savedContent: result?.currentProposal?.savedContent || "",
      });
    });
    // }
  }, []);

  console.log("proposalData", proposalData);
  return (
    <LoadableComp loading={!proposalData?.estimation}>
      <div className="new_proposal_builder">
        <div className="new_proposal_builder__header-wrapper">
          <ProposalHeader
            {...{
              editorRef,
              onViewChange,
              activeView: visbCheckers?.realTimePreview
                ? "realTimePreview"
                : "default",
            }}
          />
        </div>{" "}
        <div>
          <ProposalBodyStepper />
        </div>
        <div className="new_proposal_builder__body">
          {visbCheckers?.templateBuilder && (
            <div
              className="new_proposal_builder__body__editor-wrapper"
              onDragOver={(event) => {
                event.preventDefault();
              }}
            >
              {/* <ProposalTinyEditor {...{ editorRef }} /> */}
              <JoditEditorCustom {...{ editorRef }} />
            </div>
          )}

          {visbCheckers?.realTimePreview && (
            <div className="new_proposal_builder__body__realtime-preview">
              <RealTimeTakeOffPreview {...{ editorRef }} />{" "}
            </div>
          )}

          {visbCheckers?.sidebar && (
            <div className="new_proposal_builder__body__sidebar-wrapper">
              <ProposalSidebar {...{ editorRef }} />
            </div>
          )}
        </div>
      </div>
    </LoadableComp>
  );
}

export default ProposalBuilder;
