import {
  UIEvent,
  useMemo,
  Dispatch,
  useState,
  useContext,
  SetStateAction,
} from "react";
import { Dayjs } from "dayjs";
import { Form, FormInstance, Tooltip } from "antd";

import PayrollLiveContext from "../../PayrollLiveContext";
import { EmployeeReportType, EntryType } from "../../payrollLiveTypes";
import CardComponent from "src/components/CardComponent/CardComponent";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./UnmatchedEntries.scss";

interface Props {
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
  setEntryModal: Dispatch<SetStateAction<undefined | Partial<EntryType>>>;
}

const initialIndex = {
  start: 0,
  end: 15,
};

const punchTypeOptions = [
  { label: "ID", value: "ID", key: 0 },
  { label: "OL", value: "OL", key: 1 },
  { label: "IL", value: "IL", key: 2 },
  { label: "OD", value: "OD", key: 3 },
];

function UnMatchedEntries(props: Props) {
  const { form, setEntryModal } = props;
  const { degEntries, employeesReport } = useContext(PayrollLiveContext);

  const [punchTypeFilter, setPunchTypeFilter] = useState<Array<string>>([]);
  const [sliceIndex, setSliceIndex] = useState<{ start: number; end: number }>(
    initialIndex
  );

  const punchEnd = Form.useWatch("punchEnd", form);
  const punchStart = Form.useWatch("punchStart", form);
  // const reportDate = Form.useWatch("reportDate", form);
  const searchField = Form.useWatch("searchField", form);

  function onScroll(e: UIEvent<HTMLElement>): void {
    const scrollStep = 10;
    const maxStartIndex = unmatchedEntries.length - scrollStep;
    const maxEndIndex = unmatchedEntries.length - 1;
    let indexPosition = e.currentTarget.scrollTop / 128;

    if (indexPosition >= 7.7 && sliceIndex.end === scrollStep) {
      setSliceIndex(() => ({
        start: 0,
        end: 15 + scrollStep,
      }));
    }

    if (indexPosition >= 18 && sliceIndex.end !== maxEndIndex) {
      setSliceIndex((prev) => {
        let startIndex = prev.start + scrollStep;
        let endIndex = prev.end + scrollStep;
        return {
          start: startIndex > maxStartIndex ? maxStartIndex : startIndex,
          end: endIndex > maxEndIndex ? maxEndIndex : endIndex,
        };
      });
    }

    if (indexPosition <= 0.5) {
      setSliceIndex((prev) => {
        return {
          start: prev.start >= scrollStep ? prev.start - scrollStep : 0,
          end: prev.end > scrollStep ? prev.end - scrollStep : scrollStep,
        };
      });
    }
  }

  const unmatchedEntries = useMemo(() => {
    const jobsiteMatch = employeesReport.flatMap((report: EmployeeReportType) =>
      report?.jobsiteId ? report.jobsiteId : []
    );
    let unMatched = [];
    for (let i = 0; i < degEntries.length; i++) {
      const entry = degEntries[i];
      let pass = false;
      if (!entry?.jobsiteMatch?.jobsiteId) {
        pass = true;
      } else if (jobsiteMatch.includes(entry?.jobsiteId)) {
        pass = true;
      } else {
        continue;
      }

      if (
        searchField?.length &&
        !entry?.employeeFullName
          ?.toLowerCase()
          ?.includes(searchField.toLowerCase())
      ) {
        if (sliceIndex.start) {
          setSliceIndex(initialIndex);
        }
        pass = false;
      }

      // if (reportDate?.length === 2) {
      //   const endDate = parseInTz(reportDate?.[1]).valueOf();
      //   const startDate = parseInTz(reportDate?.[0]).valueOf();
      //   // if date is inside date range
      //   if (sliceIndex.start) {
      //     setSliceIndex(initialIndex);
      //   }
      //   if (
      //     startDate > dayjsNY(entry?.punchDate).valueOf() ||
      //     endDate < dayjsNY(entry?.punchDate).valueOf()
      //   ) {
      //     pass = false;
      //   }
      // }

      if (punchTypeFilter?.length) {
        pass = punchTypeFilter.includes(entry?.punchType);
      }

      if (punchStart) {
        pass =
          parseInTz(entry?.punchTime).valueOf() >=
          punchStart?.startOf("m")?.valueOf?.();
      }

      if (punchEnd) {
        pass =
          parseInTz(entry?.punchTime).valueOf() <=
          punchEnd?.startOf("m")?.valueOf?.();
      }

      if (pass) {
        unMatched.push(entry);
      }
    }

    return unMatched.map((entry: EntryType) => {
      return (
        <div
          key={entry?.entryId}
          className={`unmatched-card ${
            entry?.jobsiteId ? "no-schedule-match" : ""
          }`}
        >
          <div className="unmatched-header">
            <Tooltip title="Open entry">
              <label onClick={() => setEntryModal(entry)}>
                {entry?.employeeFullName}
              </label>
            </Tooltip>
            {entry?.jobsiteId ? <span>No Schedule Match</span> : null}
          </div>
          <div className="unmatched-body">
            <div className="data-row">
              <span>Punch Type: </span>
              <div>{entry?.punchType}</div>
            </div>
            <div className="data-row">
              <span>Punch Time: </span>
              <div>
                {dayjsNY(entry?.punchTime).format("MM/DD/YYYY,   hh:mm A")}
              </div>
            </div>
            <div className="data-row">
              <span>Punch Location:</span>
              <div>{entry?.punchLocation}</div>
            </div>
          </div>
        </div>
      );
    });
  }, [degEntries, searchField, punchTypeFilter, punchEnd, punchStart]);

  return (
    <CardComponent
      // title="Jobsite Unmatched Entries"
      title={
        <div className="custom-header">
          <label>Jobsite Unmatched Entries</label>
          <InputComponent
            type="select"
            mode="multiple"
            formItemName="groupStatus"
            value={punchTypeFilter}
            customOptions={punchTypeOptions}
            onChange={(e) => setPunchTypeFilter(e)}
            placeholder="Filter punch type"
          />
        </div>
      }
      className="unmatched-entries-card"
    >
      <>
        <section className="card-content" onScroll={onScroll}>
          {unmatchedEntries?.length ? (
            unmatchedEntries.slice(sliceIndex.start, sliceIndex.end)
          ) : (
            <div className="empty-unmatched-entries">
              No Jobsite Unmatched Entries
            </div>
          )}
        </section>
        <div className="unmatched-count">
          {unmatchedEntries?.length || 0} Total unmatched entries
        </div>
      </>
    </CardComponent>
  );
}

export default UnMatchedEntries;
