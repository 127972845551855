import Geofence from "src/components/commonComponents/Map/Geofence/Geofence";
import useCalculateCenterOfCoordinates from "src/hooks/useCalculateCenterOfCoordinates";

export const transformToCurrObj = (input) => {
  return input.map((item) => {
    const lngObj = item.find((obj) => obj.key === "lng");
    const latObj = item.find((obj) => obj.key === "lat");
    const circleRadiusObj = item.find((obj) => obj.key === "circleRadius");
    return {
      lng: lngObj.updatedValue,
      lat: latObj.updatedValue,
      circleRadius: circleRadiusObj?.updatedValue || null,
    };
  });
};

export const transformToPrevObject = (input) => {
  return input.map((item) => {
    const lngObj = item.find((obj) => obj.key === "lng");
    const latObj = item.find((obj) => obj.key === "lat");
    const circleRadiusObj = item.find((obj) => obj.key === "circleRadius");
    return {
      lng: lngObj.previousValue,
      lat: latObj.previousValue,
      circleRadius: circleRadiusObj?.previousValue || null,
    };
  });
};

const { calculateCenter } = useCalculateCenterOfCoordinates();

const GeofenceLogs = ({ log }) => {
  const { previousData, currentData } = log;
  const prevData = previousData?.params?.rowData;
  const currData = currentData?.params?.rowData;
  const dynamicLog = !Array.isArray(currData) ? currData : prevData;

  return (
    <div style={{ width: "100%", padding: "5px 30px 0px 20px" }}>
      <div style={{ display: "flex", gap: "20px" }}>
        {/* Previous Value */}
        <div>
          <div style={{ fontWeight: "bold" }}>Previous Value</div>

          <div style={{ width: "57vh", height: "43vh" }}>
            <Geofence
              {...{
                show3DotActions: false,
                showDrawingManager: false,
                allowEditing: false,
                places: [
                  { coordinates: calculateCenter(dynamicLog.geoFenceInfo) },
                ],
                geoFenceInfo:
                  dynamicLog.action !== "Create" && dynamicLog.action !== "edit"
                    ? [dynamicLog]
                    : dynamicLog.action === "edit"
                    ? [prevData]
                    : [],
              }}
            />
          </div>
        </div>

        {/* Updated Value */}
        <div>
          <div style={{ fontWeight: "bold" }}>Updated Value</div>
          <div style={{ width: "57vh" }}>
            <Geofence
              {...{
                showDrawingManager: false,
                allowEditing: false,
                places: [
                  { coordinates: calculateCenter(dynamicLog.geoFenceInfo) },
                ],
                geoFenceInfo:
                  dynamicLog.action !== "delete" ? [dynamicLog] : [],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeofenceLogs;
