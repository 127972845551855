import { getCoordinatesAndZip } from "../../../../Fleet/utils";

export const getAddCoordinates = async ({ address }) => {
  if (!!address) {
    const addressLatLng = !!address && (await getCoordinatesAndZip(address));

    const res = [{ addType: "address", color: "#F8D210", ...addressLatLng }];
    return res;
  }
};
