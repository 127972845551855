function loadChartsPreferences(preference) {
  if (!preference || typeof preference !== "string") {
    return null;
  }

  return !!sessionStorage.getItem(preference)
    ? JSON.parse(sessionStorage.getItem(preference))
    : [];
}

export default loadChartsPreferences;
