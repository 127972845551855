import "./NotificationsStep.scss";
import "../../../pages/Settings/settingsCommonComponents/styles/CardContentWrapper.scss";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { Notifications } from "../../assets/icons";
import BoardingNavigationBtn from "../BoardingNavigationBtn/BoardingNavigationBtn";
import NotificationConfigs from "../../../pages/Settings/settingsComponents/UserProfile/NotificationFields/NotificationConfigs/NotificationConfigs";
import { ToggleSwitch } from "../../../commonComponents";
import { NotificationRoundedIcon } from "../../../../assets";

const NotificationsStep = ({
  boardingChanges: { notifications: myNotifications },
  setBoardingChanges,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);

  const allNotificationsOn = Object.values({
    ...(myNotifications || preferences.preferences.notifications.topics || {}),
  }).every((topic) => Object.values(topic).every((topic) => !!topic));

  const handleAllNotifications = (e) => {
    setBoardingChanges((prev) => ({
      ...prev,
      notifications: Object.entries(
        preferences.preferences.notifications.topics
      ).reduce(
        (acc, [topicId, actions]) => ({
          ...acc,
          [topicId]: Object.keys(actions).reduce(
            (acc, actionName) => ({ ...acc, [actionName]: e }),
            {}
          ),
        }),
        {}
      ),
    }));
  };

  return (
    <div
      className={`notifications-step step-lines ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="notification-icon">
        <Notifications style={{ width: "24px", height: "30px" }} />
      </div>
      <span style={{ textAlign: "center" }}>
        Please select a topic below and{" "}
        <span style={{ fontWeight: 600 }}>enable notifications as desired</span>{" "}
        by toggling the switches.
      </span>

      <span>
        <Tooltip
          title={`Turn ${allNotificationsOn ? "ON" : "OFF"} all notifications!`}
        >
          <ToggleSwitch
            variant="primary"
            checked={allNotificationsOn}
            label={
              <span>
                All Notifications <b>{allNotificationsOn ? "ON" : "OFF"}</b>
              </span>
            }
            icon={<NotificationRoundedIcon />}
            onChange={handleAllNotifications}
            dataTestId="switch-notifications-testid"
          />
        </Tooltip>
      </span>

      <NotificationConfigs
        {...{
          myNotifications,
          setMyNotifications: (newNotifications) => {
            setBoardingChanges((prev) => ({
              ...prev,
              notifications: newNotifications,
            }));
          },
        }}
      />

      <BoardingNavigationBtn />
    </div>
  );
};

export default NotificationsStep;
