import React, { createContext, useContext, useState } from "react";

const AttachmentsContext = createContext();

export const useAttachments = () => {
  return useContext(AttachmentsContext);
};

export const AttachmentsProvider = ({ children }) => {
  const [attachments, setAttachments] = useState([]);

  const addFile = (file) => {
    setAttachments([...attachments, file]);
  };

  const removeFile = (fileIndex) => {
    const newFiles = [...attachments];
    newFiles.splice(fileIndex, 1);
    setAttachments(newFiles);
  };

  const clearFiles = () => {
    setAttachments([]);
  };

  return (
    <AttachmentsContext.Provider
      value={{
        attachments,
        setAttachments,
        addFile,
        removeFile,
        clearFiles,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};
