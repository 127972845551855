import axesTooltip from "../../../../helpers/axesTooltip";

const pieTooltip = (params) => {
  let tmp = params["calloutLabelKey"];
  return {
    title: params.datum[tmp],
    content: `Count: ${params.datum[params.angleName.toLowerCase()].toFixed(
      0
    )}`,
  };
};

const countOnStatus = {
  title: {
    text: "Total Number of To Dos",
  },
  subTitle: {
    text: "Based on Status",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "todoStatus",
        yKey: ["todoName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "todoStatus",
        angleKey: ["todoName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnCreatedBy = {
  title: {
    text: "Total Number of To Dos",
  },
  subTitle: {
    text: "Based on User",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "todoUser",
        yKey: ["todoName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "todoUser",
        angleKey: ["todoName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnDueDate = {
  title: {
    text: "Total Number of To Dos",
  },
  subTitle: {
    text: "Based on Due Date",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "todoDueDate",
        yKey: ["todoName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "todoDueDate",
        angleKey: ["todoName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

export const chartingOptions = [
  {
    optionTitle: "Show Total Number of To Dos based on Status",
    params: countOnStatus,
  },
  {
    optionTitle: "Show Total Number of To Dos based on User",
    params: countOnCreatedBy,
  },
  // {
  //   optionTitle: "Show Total Number of To Dos based on Due Date",
  //   params: countOnDueDate,
  // },
];
