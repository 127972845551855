import { getElevationAddonsPrice } from "src/components/SidebarPages/Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/ServicePrices";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const getTotalServicesPriceWithoutTax = (services) => {
  let totalPrice = 0;
  try {
    if (Array.isArray(services) && services?.length > 0) {
      for (const service of services) {
        if (service?.isScope === true) {
          totalPrice += getOtherScopePriceWithoutTax(service);
        } else {
          totalPrice += getServicePriceInclAddonsWithoutTax(service);
        }
      }
    }
    return totalPrice;
  } catch (error) {
    console.log("there was an error calculation", error);
    return totalPrice;
  } finally {
    return totalPrice;
  }
};

export const getOtherScopePriceWithoutTax = (otherScopeService, category) => {
  let otherScopePrice = 0;

  if (category === "Schedule Of Value") {
    if (Array.isArray(otherScopeService?.amounts)) {
      const price = [
        ...otherScopeService?.amounts?.flatMap(
          ({ price }) => +forceToNumber(price)
        ),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);

      otherScopePrice += forceToNumber(price);
    }
  } else {
    if (Array.isArray(otherScopeService?.serviceOptions)) {
      const price = [
        ...otherScopeService?.serviceOptions?.[0]?.flatMap?.(({ items }) =>
          items?.flatMap(({ price }) => +forceToNumber(price))
        ),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);

      otherScopePrice += forceToNumber(price);
    }
  }
  return otherScopePrice;
};

export const getElevationAddonsPriceWithoutTax = (service) => {
  const serviceOptions =
    service?.serviceOptions?.[0].length > 0
      ? service?.serviceOptions?.[0]
      : service?.serviceOptions;

  const elevationPriceArr = serviceOptions?.map(
    (el) => getElevationAddonsPrice(el).elevationAddonPrice || 0
  );
  const elevationPrice = elevationPriceArr.reduce(
    (acc, price) => (acc += price),
    0
  );
  return elevationPrice;
};

export const getServicePriceInclAddonsWithoutTax = (service, category) => {
  const { serviceAddons = [], serviceOptions = [] } = service || {};
  if (category === "Schedule Of Value") {
    if (service?.label === "Hoist" || service?.isHoist) {
      let serviceTotalPrice;
      if (Array.isArray(serviceOptions?.[0])) {
        serviceTotalPrice = [
          ...serviceOptions?.[0]?.flatMap?.(({ amounts }) =>
            amounts.flatMap(({ price }) => +forceToNumber(price))
          ),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);

        return forceToNumber(serviceTotalPrice);
      }
    } else {
      let serviceTotalPrice;
      if (Array.isArray(service.amounts)) {
        serviceTotalPrice = [
          ...service.amounts.flatMap(({ price }) => +forceToNumber(price)),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);

        return forceToNumber(serviceTotalPrice);
      }
    }
  } else {
    let additional = getAdditionalServiceRentPriceWithoutTax(service);
    const elevationPrice = getElevationAddonsPriceWithoutTax(service);

    let serviceTotalPrice;
    if (service?.label === "Hoist" || service?.isHoist) {
      serviceTotalPrice = [
        ...serviceOptions?.[0]?.flatMap?.(({ price }) => +forceToNumber(price)),
        ...serviceAddons?.map(({ price }) => +price),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);
    } else {
      if (Array.isArray(serviceOptions?.[0])) {
        serviceTotalPrice = [
          ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
            items
              .filter((el) =>
                el.hasOwnProperty("approved") ? el.approved === true : true
              )
              .flatMap(({ price }) => +forceToNumber(price))
          ),
          ...serviceAddons?.map(({ price }) => +price),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);
      } else {
        serviceTotalPrice = [
          ...serviceOptions?.flatMap?.(({ items }) =>
            items
              ?.filter((el) =>
                el.hasOwnProperty("approved") ? el.approved === true : true
              )
              .flatMap(({ price }) => +forceToNumber(price))
          ),
          ...serviceAddons?.map(({ price }) => +price),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);
      }
    }
    return (
      forceToNumber(serviceTotalPrice) +
      forceToNumber(additional) +
      forceToNumber(elevationPrice)
    );
  }
};

export const getAdditionalServiceRentPriceWithoutTax = (service) => {
  let additionalRentCost = 0;
  const rentalTerms = service?.additionalRentalTerms;
  if (rentalTerms?.newPrice > 0) {
    additionalRentCost += forceToNumber(
      typeof rentalTerms?.price !== "undefined"
        ? rentalTerms?.price
        : rentalTerms?.newPrice / (1 + rentalTerms?.taxRate || 1)
    );
  }
  return additionalRentCost;
};

export const getServiceLegacyPriceWithoutTax = (service, category) => {
  if (service?.isScope === true) {
    return getOtherScopePriceWithoutTax(service, category);
  }
  return getServicePriceInclAddonsWithoutTax(service, category);
};
