import React, { useCallback, useState, useEffect } from "react";
import { fetchData } from "src/components/SidebarPages/Fleet/utils";
import { useRedux } from "src/components/SidebarPages/hooks";
import Card from "../ServiceTemplates/Card";
import { message } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import { useMemo } from "react";
import { mapTagToData } from "../../../ProposalHeader/components/RealTimeTakeOffPreview/mappers/dataMapper";

function addStringToDoubleBracesTags(html, keyToAdd) {
  const regex = /\{\{(\w+)\}\}/g;
  const updatedHtml = html.replace(
    regex,
    `<span class="proposalTag" contenteditable="false" style="cursor: not-allowed; color: red;">{{$1_${keyToAdd}}}</span>`
  );
  return updatedHtml;
}

export const getMyMadeSenseData = (html, proposalData) => {
  return mapTagToData({
    proposalData,
    html,
  });
};

function GeneralTemplatesSidebar({ editorRef }) {
  const [proposalTemplates, setProposalTemplates] = useState([]);
  const [proposalData, setProposalData] = useRedux("proposalData");
  const [servicesTemplates, setServicesTemplates] = useState([]);
  const [refreshToggle, setRefreshToggle] = useState(false);

  // const myDataPreview = useMemo(() => {
  //   console.log("editorRef", editorRef);
  //   // return;
  //   const myMadeSenseData = mapTagToData({
  //     proposalData,
  //     html: editorRef.current.value,
  //   });
  //   console.log("myMadeSenseData", myMadeSenseData);
  //   return myMadeSenseData;
  // }, [proposalData, editorRef, refreshToggle]);

  const onDragEnd = (event, item) => {
    console.log("onDragEnd", item);
    const droppedHTML = item?.templateContent || "";
    event.preventDefault();
    if (editorRef.current) {
      const wrappedHTML = `<div>${droppedHTML}</div>`;
      const myMadeSenseData = getMyMadeSenseData(wrappedHTML, proposalData);
      // setProposalData(myMadeSenseData);
      console.log("myMadeSenseData", {
        myMadeSenseData,
        wrappedHTML,
        proposalData,
      });
      editorRef.current.component.selection.insertHTML(myMadeSenseData);
      setProposalData({
        ...(proposalData || {}),
        templateData: {
          ...(proposalData?.templateData || {}),
        },
        wrappedHTML,
        parentTemplateContent: item?.parentTemplateContent || "",
      });
      // const editor = tinymce.get(editorRef.current.id);
      // editor.execCommand("mceInsertContent", false, droppedHTML);
    }
  };

  const getTemplateContent = (template, tempTemplates) => {
    console.log("getTemplateContent", {
      template,
      proposalData,
      servicesTemplates,
      tempTemplates,
    });
    let myServiceTemplates = tempTemplates?.filter((sTemplate) => {
      return !!proposalData?.takeOff?.services?.find(
        (service) =>
          service?.serviceId?.toString() ===
          sTemplate?.additionalDetails?.service?.workId?.toString()
      )
        ? true
        : false;
    });
    console.log("myServiceTemplates", myServiceTemplates);
    let htmlContent = template?.templateContent;
    let pattern = /\{\{([^_]+_[^}]+)\}\}/g;
    let result = htmlContent?.replace(pattern, function (match, id) {
      let templateIdService = id?.split("_")?.[1];
      let serviceName = id?.split("_")?.[0];

      let particulateService = myServiceTemplates?.find((el) => {
        console.log("el", {
          tempId: el?.templateId,
          templIdService: templateIdService,
          id,
        });
        return el?.templateId === templateIdService;
      });

      console.log("particulateService", particulateService);
      if (!!particulateService) {
        let droppedHTML = addStringToDoubleBracesTags(
          particulateService?.templateContent || "",
          particulateService?.additionalDetails?.service?.workName || ""
        );
        return droppedHTML || "";
      } else {
        return "";
      }
    });
    return result;
    console.log("matches", { matches, result });
  };
  const getTemplateContentParent = (template, tempTemplates) => {
    console.log("getTemplateContentParent", {
      template,
      proposalData,
      servicesTemplates,
      tempTemplates,
    });
    let myServiceTemplates = tempTemplates?.filter((sTemplate) => {
      return !!proposalData?.takeOff?.services?.find(
        (service) =>
          service?.serviceId?.toString() ===
          sTemplate?.additionalDetails?.service?.workId?.toString()
      )
        ? true
        : false;
    });
    console.log("myServiceTemplates", myServiceTemplates);
    let htmlContent = template?.templateContent;
    let pattern = /\{\{([^_]+_[^}]+)\}\}/g;
    let result = htmlContent?.replace(pattern, function (match, id) {
      let templateIdService = id?.split("_")?.[1];
      let serviceName = id?.split("_")?.[0];

      let particulateService = myServiceTemplates?.find((el) => {
        console.log("el", {
          tempId: el?.templateId,
          templIdService: templateIdService,
          id,
        });
        return el?.templateId === templateIdService;
      });

      console.log("particulateService", particulateService);
      if (!!particulateService) {
        let droppedHTML = addStringToDoubleBracesTags(
          particulateService?.templateContent || "",
          particulateService?.additionalDetails?.service?.workName || ""
        );
        return droppedHTML || "";
      } else {
        return "";
      }
    });
    return result;
    console.log("matches", { matches, result });
  };

  const fetchProposalTemplates = async () => {
    const data = await fetchData("proposalTemplates");
    console.log("data", data);
    if (data) {
      let tempTemplates =
        data?.filter((el) => el?.templateCategory === "service") || [];
      console.log("tempTemplates", tempTemplates);
      setServicesTemplates(tempTemplates);
      let toShow = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element?.templateCategory?.toLowerCase() === "general") {
          toShow.push({
            ...element,
            templateCategory: element.templateCategory.toLowerCase(),
            serviceName: element?.templateTitle || "",
            templateContent: getTemplateContent(element || "", tempTemplates),
            parentTemplateContent: element?.templateContent || "",
            color: "",
            templateId: element?.templateId || "",
          });
        }
      }
      console.log("toShow", toShow);
      setProposalTemplates(Array.isArray(toShow) ? toShow : []);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchProposalTemplates();
    }, 500);
  }, [proposalData?.takeOff?.services]);
  console.log("proposalTemplates", {
    proposalTemplates,
    proposalData,
    servicesTemplates,
  });
  return (
    <div>
      <div className="serviceTemplates">
        {Array.isArray(proposalTemplates) &&
          proposalTemplates?.map((template) => (
            <div className="serviceTemplateItem">
              {/* <MondayButton>Test</MondayButton> */}
              <Card template={template} onDragEnd={onDragEnd} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default GeneralTemplatesSidebar;
