import {
  formatCurrency,
  roundToDecimals,
} from "../../../../../../../../../utils";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { Expend, Retrackt } from "../../../../../../../../../BasePage/src";
import { Cancel, Close } from "../../../../../../../../../DynamicView/src";
import { defaultColDef, columnDefs, addonsColumnDefs } from "./GridData";
import { Info } from "../../../../../../../../../BasePage/src";
import "./service-details.scss";

import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { Modal, Collapse } from "antd";
import { useState } from "react";

const { Panel } = Collapse;

const servicesWithoutPricePerPli = [
  "Shoring",
  "Hanging Scaffold",
  "Platform Scaffold",
  "Window Protection",
  "Turn Stiles",
];

const ServiceDetails = ({ service, borderColor, darkMode }) => {
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [activePanel, setActivePanel] = useState([
    service?.serviceOptions?.[0]?.[0]?.elevationId,
  ]);
  const { programFields } = useSelector((state) => state.programFields);

  const primaryServiceElevations = service?.serviceOptions?.[0]?.map(
    (elevation) => ({
      ...elevation,
      panelType: "elevation",
      items: elevation?.items
        ?.filter((item) => item?.approved)
        ?.map((item) => ({
          ...item,
          amounts:
            !servicesWithoutPricePerPli.includes(service.label) &&
            service?.amounts?.map((event, eventIndex, arrayOfEvents) => {
              // Calculate amounts of each PLI for elevation
              let rate = event.rate; // Rate of event
              let amount = roundToDecimals((item.price * rate) / 100, 2); // Calculate amount based on rate
              let i = eventIndex; // Initialize index
              let total = item.price; // Initial total is equal to total price

              // Calculate total for rows other than first
              while (i > 0) {
                // If we are on row other than first one
                total = roundToDecimals(
                  total -
                    roundToDecimals(
                      (item.price * arrayOfEvents[i - 1].rate) / 100,
                      2
                    ),
                  2
                ); // Subtract all previous amounts from total price
                i--; // Until we reach the current row
              }

              let difference = roundToDecimals(total - amount, 2); // Calculate difference

              // Return amounts for each PLI
              return {
                event: event.name,
                rate,
                amount,
                difference,
              };
            }),
        })),
    })
  );

  const serviceAddons = service?.serviceAddons?.map((addon) => ({
    ...addon,
    panelType: "addon",
    amounts:
      !servicesWithoutPricePerPli.includes(service.label) &&
      service?.amounts?.map((event, eventIndex, arrayOfEvents) => {
        // Calculate amounts of each Addon for service
        let rate = event.rate; // Rate of event
        let amount = roundToDecimals((addon.price * rate) / 100, 2); // Calculate amount based on rate
        let i = eventIndex; // Initialize index
        let total = addon.price; // Initial total is equal to total price

        // Calculate total for rows other than first
        while (i > 0) {
          // If we are on row other than first one
          total = roundToDecimals(
            total -
              roundToDecimals(
                (addon.price * arrayOfEvents[i - 1].rate) / 100,
                2
              ),
            2
          ); // Subtract all previous amounts from total price
          i--; // Until we reach the current row
        }

        let difference = roundToDecimals(total - amount, 2); // Calculate difference

        // Return amounts for each PLI
        return {
          event: event.name,
          rate,
          amount,
          difference,
        };
      }),
  }));

  const panels = [
    ...(primaryServiceElevations || []),
    ...(serviceAddons || []),
  ];

  // Close Modal
  const onCancel = () => setIsDetailsModalVisible(false);

  // Collapse & expand panels
  const collapseAll = () => setActivePanel([]);
  const expandAll = () =>
    setActivePanel(
      panels?.map((panel) =>
        panel?.panelType === "elevation" ? panel?.elevationId : panel?.id
      )
    );

  // Calculate total price of elevation
  const getTotalPriceOfElevation = (elevation) =>
    servicesWithoutPricePerPli.includes(service.label)
      ? elevation?.price
      : elevation?.items?.reduce((acc, cur) => acc + cur.price, 0);

  return (
    <>
      <div className="buttonContainer">
        <MondayButton
          className="detailsApplicationButton"
          onClick={() => setIsDetailsModalVisible(true)}
          Icon={<Info />}
        >
          Details
        </MondayButton>
      </div>
      <Modal
        wrapClassName={
          darkMode
            ? "serviceHeaderDetailsModalDarkMode"
            : "serviceHeaderDetailsDetailsModalLightMode"
        }
        open={isDetailsModalVisible}
        onCancel={onCancel}
        footer={null}
        closeIcon={darkMode ? <Close /> : <Cancel />}
      >
        <div className="service-details-card-header">
          <h3 className="service-details-card-header-title">
            <span
              className="service-details-card-header-title-color"
              style={{ background: borderColor }}
            />
            {service.label}
          </h3>
          <div className="service-details-card-header-options">
            <MondayButton
              className="service-details-card-header-option"
              onClick={collapseAll}
              Icon={<Retrackt />}
            >
              Collapse All
            </MondayButton>
            <MondayButton
              className="service-details-card-header-option"
              onClick={expandAll}
              Icon={<Expend />}
            >
              Expand All
            </MondayButton>
          </div>
        </div>
        <div className="service-details-card">
          <Collapse activeKey={activePanel} onChange={(e) => setActivePanel(e)}>
            {!!panels.length &&
              panels?.map((panel) => (
                <Panel
                  style={{ boxShadow: `0px 0px 6px 3px ${borderColor}` }}
                  key={
                    panel?.panelType === "elevation"
                      ? panel.elevationId
                      : panel.id
                  }
                  header={
                    <div className="service-details-card-collapse-panel">
                      {`  ${
                        panel?.panelType === "elevation"
                          ? panel.elevationId
                          : ""
                      } ${
                        panel?.panelType === "elevation"
                          ? panel.elevationLabel
                          : panel?.name
                      }
                     - Price total: ${formatCurrency(
                       panel?.panelType === "elevation"
                         ? getTotalPriceOfElevation(panel)
                         : panel?.totalPrice,
                       "USD"
                     )}`}
                    </div>
                  }
                >
                  <div
                    className={
                      darkMode
                        ? "agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
                        : "agGridRentalsDetailsStyleLight ag-theme-alpine"
                    }
                    style={{
                      height:
                        panel?.panelType === "elevation"
                          ? `${panel?.items?.length * 50 + 150}px`
                          : "200px",
                    }}
                  >
                    <AgGridReact
                      defaultColDef={{
                        minWidth: 100,
                        editable: false,
                        hide: false,
                        filter: true,
                        sortable: false,
                        resizable: true,
                        // autoHeight: true,
                        enablePivot: true,
                        enableRowGroup: false,
                      }}
                      rowHeight={50}
                      columnDefs={
                        panel?.panelType === "elevation"
                          ? columnDefs(service, panel?.items, programFields)
                          : addonsColumnDefs(service, panel, programFields)
                      }
                      rowData={
                        panel?.panelType === "elevation"
                          ? panel?.items
                          : [panel]
                      }
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </Panel>
              ))}
          </Collapse>
        </div>
      </Modal>
    </>
  );
};

export default ServiceDetails;
