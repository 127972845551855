import { message } from "antd";

export async function saveHandler(
  changedValues,
  driveRequest,
  onClose,
  userName
) {
  try {
    const res = await Promise.all(
      Object.keys(changedValues || {}).map((key) => {
        let permissionId = changedValues[key]?.id;
        let permissionValue = changedValues[key]?.value;
        let prev = changedValues[key]?.prev;
        if (permissionValue === prev) {
          return Promise.resolve();
        }
        return permissionValue !== "none"
          ? permissionId
            ? driveRequest.updatePermission(key, permissionValue, permissionId)
            : driveRequest.share(key, permissionValue, "user", userName, true)
          : permissionId && driveRequest.deletePermission(key, permissionId);
      })
    );
    return res;
  } catch (err) {
    console.log(err);
  } finally {
    message.success({
      content: "Changes saved successfully.",
      key: "save",
      duration: 2,
    });
    onClose();
  }
}
