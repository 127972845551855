import React from "react";
import { useSelector } from "react-redux";

import { StatusSquircle } from "./components";
import { FormCard } from "../../SidebarPages/Fleet/components";

import "./StatusesPanel.scss";

const StatusesPanel = ({
  data = [],
  className = "",
  onClick = null,
  autoSizeAllColumns = () => {},
  inLineStyle = {
    childrenStyle: {},
    style: {},
  },
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <FormCard
      className={`statusesPanelContainer ${
        isDarkMode && "statusesPanelContainerDark"
      } ${className}`?.trim?.()}
      childrenClassName="statusesPanelContent"
      childrenStyle={inLineStyle.childrenStyle}
    >
      {data?.map?.(({ statusName, statusColor: color, value }, key) => (
        <StatusSquircle
          key={key}
          statusName={statusName}
          color={color}
          value={value}
          onClick={onClick}
          autoSizeAllColumns={autoSizeAllColumns}
        />
      ))}
    </FormCard>
  );
};

export default React.memo(StatusesPanel);
