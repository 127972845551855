import { useContext } from "react";
import "./timeDatePicker.css";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  SwapOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import TimeDateContext from "../../TimeDateContext";
import Clock from "../Time/Time";
import Calendar from "../Calendar/Calendar";
import { dayjsNY, dayjsNYTimestamp } from "../../contants/DayjsNY";

const TimeDatePicker = ({ hideButtons, hideResult,showOnlySwitch }) => {
  const {
    dateTime,
    firstDate,
    dateRange,
    datePicker,
    secondDate,
    isSettingOpen,
    handleDateTime,
    handleDateRange,
    firstForDateTime,
    handleChangeDatePicker,
    handleChangeToDateTime,
    handleChangeSettingOpen,
    handleChangeFirstForDateTime,
  } = useContext(TimeDateContext);

  return (
    <div className="dateTime-container">
      <div className="dateTime-body">
        {/* checking datePicker, if it is Clock&Date show Clock and Calendar components,
         if it is Date show Calendar component and else show Clock component
         hideButtons and hideResult to hide Buttons and hide Result of Clock and Calendar components */}
        {datePicker === "Clock&Date" ? (
          <>
            {firstForDateTime === "Clock" ? (
              <>
                <Clock {...{ hideButtons, hideResult }} />
                <Calendar {...{ hideButtons, hideResult }} />
              </>
            ) : (
              <>
                <Calendar {...{ hideButtons, hideResult }} />
                <Clock {...{ hideButtons, hideResult }} />
              </>
            )}
          </>
        ) : datePicker === "Date" ? (
          <Calendar {...{ hideButtons, hideResult }} />
        ) : (
          <Clock {...{ hideButtons, hideResult }} />
        )}
      </div>
      <div className="buttons">
        <label>
          <input type="checkbox" id="checkbox" />
          <SettingOutlined
            className="settingsButton square"
            onClick={handleChangeSettingOpen}
          />
        </label>

        {isSettingOpen ? (
          <div className="dateTime-btn">
            <button className="dateOrTime" onClick={handleChangeDatePicker}>
              <ClockCircleOutlined />
              <SwapOutlined />
              <CalendarOutlined />
            </button>
            {!showOnlySwitch && (
              <>
                <button
                  className="dateTime-picker"
                  onClick={handleChangeToDateTime}
                >
                  <ClockCircleOutlined /> {" + "}
                  <CalendarOutlined />
                </button>
                <button
                  className="swapButton"
                  onClick={handleChangeFirstForDateTime}
                >
                  <SwapOutlined style={{ fontSize: "12px" }} />
                </button>
                <button className="dateRange-btn" onClick={handleDateRange}>
                  Range
                </button>
              </>
            )}
          </div>
        ) : null}

        {!hideButtons && (<div className="okCancel-btn">
          <button className="cancel-btn">Cancel</button>
          {dateRange && (!firstDate || !secondDate) ? (
            <button className="ok-btn okBtn-disable">OK</button>
          ) : (
            <button className="ok-btn" onClick={() => handleDateTime()}>
              OK
            </button>
          )}
        </div>)}
      </div>

      {/* ShowResult div to show the final result in Date and Timestamp format */}
      {!hideResult && (
        <div className="showResult">
          {/* If dateRange is true show result of first and second date, else show only one date  */}
          {dateRange ? (
            <div className="dateWithRange">
              {firstDate && secondDate ? (
                <div>
                  <h4>
                    <span>First Date: </span>
                    {dayjsNY(firstDate)
                      .format("YYYY-MMM-DD HH:mm:ss")
                      .toString()}
                    {/* You should use format in order to conver time correctly to timezone */}
                    {/* {firstDate.toString()} */}
                    <br />
                    <span>Second Date: </span>
                    {dayjsNY(secondDate)
                      .format("YYYY-MMM-DD HH:mm:ss")
                      .toString()}
                    {/* You should use format in order to conver time correctly to timezone */}
                    {/* {secondDate.toString()} */}
                  </h4>
                  <h4>
                    <span>First Timestamp: </span>
                    <p data-testid="result">
                      {dayjsNYTimestamp(firstDate).valueOf()}
                      {/* {firstDate.valueOf().toString()} */}
                    </p>
                    <span>Second Timestamp: </span>
                    <p data-testid="result">
                      {dayjsNYTimestamp(secondDate).valueOf()}
                      {/* {secondDate.valueOf().toString()} */}
                    </p>
                  </h4>
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <h4>
                <span>Date: </span>
                {dayjsNY(dateTime).format("YYYY-MMM-DD HH:mm:ss").toString()}
                {/* You should use format in order to conver time correctly to timezone */}
                {/* {dateTime.toString()} */}
              </h4>
              <h4>
                <span>Timestamp: </span>
                <p data-testid="result">
                  {dayjsNYTimestamp(dateTime).valueOf()}
                  {/* {dateTime.valueOf().toString()} */}
                </p>
              </h4>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeDatePicker;
