import { Form, Modal } from "antd";
import { useSelector } from "react-redux";
import { useContext, useMemo } from "react";

import PayrollContext from "../../../../PayrollContext";
import { getDataSets } from "../../utils/chartsHelpersData";
import { MondayButton } from "../../../../../../commonComponents";
import AnalyticsChartsContext from "../../AnalyticsChartsContext";
import { getRandomColor } from "../../../../../../SidebarPages/utils";
import { FilterIcon } from "../../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { parseInTz } from "../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./CardIndividualFilter.scss";
import { getEmployeeAnalytics } from "../../../DEG/components/modalComponents/utils";

const CardIndividualFilter = ({ open, onCancel, cardData }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { analytics, form: payrollForm } = useContext(PayrollContext);
  const { crews, jobsites, analyticsData, setIndividualFilters } = useContext(
    AnalyticsChartsContext
  );

  const [form] = Form.useForm();
  const analyticsDateRange = Form.useWatch("analyticsDateRange", payrollForm);

  const selectOptions = useMemo(() => {
    if (cardData?.showDataFor === "Employees") {
      return crews.map((emp) => {
        return {
          key: emp?.crewId,
          value: emp?.crewId,
          label: emp?.crewName,
        };
      });
    } else {
      return jobsites.map((job) => {
        return {
          key: job?.jobsiteId,
          value: job?.jobsiteId,
          label: job?.jobName,
        };
      });
    }
  }, [cardData, crews, , jobsites]);

  function onFilterCard() {
    const filterData = form.getFieldsValue();

    const dateRange = !!filterData?.dateRange?.length
      ? filterData?.dateRange
      : [];
    let filteredAnalytics = [];
    let crewsList = crews;
    let jobsitesList = jobsites;
    if (cardData?.showDataFor === "Employees") {
      filteredAnalytics = analytics.filter((data) => {
        if (!!dateRange?.length && !!filterData?.crewsIncluded?.length) {
          const insideDateRange =
            data?.punchTimeStamp >= dateRange[0].valueOf() &&
            data?.punchTimeStamp <= dateRange[1].valueOf();
          return (
            insideDateRange &&
            (filterData?.crewsIncluded || []).includes(data?.crewId)
          );
        } else if (!!dateRange?.length) {
          const insideDateRange =
            data?.punchTimeStamp >= dateRange[0].valueOf() &&
            data?.punchTimeStamp <= dateRange[1].valueOf();
          return insideDateRange;
        } else if (filterData?.crewsIncluded?.length) {
          return (filterData?.crewsIncluded || []).includes(data?.crewId);
        }
      });

      if (filterData?.crewsIncluded?.length) {
        crewsList = crewsList.filter((crew) => {
          return (filterData?.crewsIncluded || []).includes(crew?.crewId);
        });
      }
    } else {
      filteredAnalytics = analytics.filter((data) => {
        if (!!dateRange?.length && filterData?.jobsitesIncluded?.length) {
          const insideDateRange =
            data?.punchTimeStamp >= dateRange[0].valueOf() &&
            data?.punchTimeStamp <= dateRange[1].valueOf();
          return (
            (filterData?.jobsitesIncluded || []).includes(data?.jobsiteId) &&
            insideDateRange
          );
        } else if (!!dateRange?.length) {
          const insideDateRange =
            data?.punchTimeStamp >= dateRange[0].valueOf() &&
            data?.punchTimeStamp <= dateRange[1].valueOf();
          return insideDateRange;
        } else if (filterData?.jobsitesIncluded?.length) {
          return (filterData?.jobsitesIncluded || []).includes(data?.jobsiteId);
        }
      });

      if (filterData?.jobsitesIncluded?.length) {
        jobsitesList = jobsitesList.filter((job) => {
          return (filterData?.jobsitesIncluded || []).includes(job?.jobsiteId);
        });
      }
    }

    let newAnalyticsData = {};
    getEmployeeAnalytics({
      degRows: filteredAnalytics,
      degGridApi: {},
      analyticsUpdate: (data) => {
        newAnalyticsData = data;
      },
    });

    const dataSets = getDataSets({
      crews: crewsList,
      jobsites: jobsitesList,
      analyticsProp: newAnalyticsData,
      analytics: filteredAnalytics,
    });

    const dataOptions = {
      ["Employees"]: {
        "Regular Hours": dataSets["employeeCardsData"]?.employeesWorkHours,
        "Overhead Hours": dataSets["employeeCardsData"]?.employeesOverheadHours,
        "Regular Amount": dataSets["employeeCardsData"]?.employeesTotalAmount,
        "Overhead Amount":
          dataSets["employeeCardsData"]?.employeesOverheadAmount,
        "Ovh + Reg Amount":
          dataSets["employeeCardsData"]?.employeesRegWithOvhAmount,
        "Ovh + Reg Hours":
          dataSets["employeeCardsData"]?.employeesRegWithOvhHours,
      },
      ["Jobsites"]: {
        "Regular Amount": dataSets["jobsiteCardsData"]?.totalJobsiteAmount,
        "Regular Hours": dataSets["jobsiteCardsData"]?.totalJobsiteWorkHours,
        "Ovh + Reg Amount":
          dataSets["jobsiteCardsData"]?.jobsiteRegWithOvhAmount,
        "Ovh + Reg Hours": dataSets["jobsiteCardsData"]?.jobsiteRegWithOvhHours,
      },
    };

    const labels =
      dataSets?.[
        cardData?.showDataFor === "Employees"
          ? "employeesLabels"
          : "jobsiteLabels"
      ] || [];

    const filteredCardData = {
      cardKey: cardData?.cardKey,
    };

    let cardDataSets = [];

    for (const dataset of cardData?.datasets) {
      const borderColor = labels.map(() => {
        const color = getRandomColor();
        return color;
      });

      const newDataSet = {
        ...dataset,
        labels,
        borderColor,
        borderWidth: 2,
        backgroundColor: borderColor.map((color) => color + "66"),
        data: (
          dataOptions?.[cardData?.showDataFor]?.[dataset?.selectedDataOption] ||
          []
        )?.filter((el) => typeof el === "number"),
      };
      cardDataSets.push(Object.assign(dataset, newDataSet));
    }
    Object.assign(filteredCardData, {
      dateRange,
      chartData: {
        datasets: cardDataSets,
        labels,
        showDataFor: cardData?.showDataFor,
      },
    });

    setIndividualFilters((prev) => {
      return {
        ...prev,
        [cardData?.cardKey]: filteredCardData,
      };
    });
    onCancel();
  }

  return (
    <Modal
      title={`${cardData?.cardKey} Filter`}
      open={open}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      closable={true}
      centered={true}
      className={`card-individual-filter-modal ${
        isDarkMode ? "card-individual-filter-modal-dark" : ""
      }`}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={onCancel}
        >
          Cancel
        </MondayButton>,
        <MondayButton
          className="mondayButtonBlue"
          Icon={<FilterIcon />}
          onClick={onFilterCard}
        >
          Filter
        </MondayButton>,
      ]}
    >
      <Form form={form}>
        {analyticsDateRange ? (
          <InputComponent
            label={"Date Range"}
            type="rangepicker"
            formItemName={"dateRange"}
            defaultValue={analyticsDateRange}
            disabledDate={(date) =>
              date?.valueOf() <= analyticsDateRange?.[0].valueOf() ||
              date?.valueOf() >= analyticsDateRange?.[1].valueOf()
            }
          />
        ) : null}
        <InputComponent
          label={cardData?.showDataFor}
          type="select"
          mode="multiple"
          customOptions={selectOptions}
          placeholder={`Select ${cardData?.showDataFor}...`}
          onChange={(e) =>
            e?.map?.((d, i) => {
              const date = parseInTz(d.format("MM/DD/YYYY"), "MM/DD/YYYY");

              if (date.valueOf()) {
                return i ? date.endOf("D").valueOf() : date.valueOf();
              } else {
                return date;
              }
            })
          }
          formItemName={
            cardData?.showDataFor === "Employees"
              ? "crewsIncluded"
              : "jobsitesIncluded"
          }
        />
      </Form>
    </Modal>
  );
};

export default CardIndividualFilter;
