import { formatCurrency } from "../../../../utils";

export const rentals = ({ tables, programFields }) => {
  const data = tables?.rentals?.sort(
    (a, b) =>
      a?.projectAddress.localeCompare(b?.projectAddress) ||
      a?.rentalNumber - b?.rentalNumber
  );

  return {
    rentalStatus: () => ({
      statusData: data?.map((a) => {
        return {
          label: `${a?.projectAddress} - ${a?.rentalNumber}`,
          value: a?.rentalStatus,
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Rentals?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.rentalStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
          date: a?.createdAt,
          custom: [
            {
              "Total Amount": {
                "Total Price": formatCurrency(a?.totalPrice),
                "Total Applied Rent": formatCurrency(a?.totalAppliedRent),
                "Total Tax": formatCurrency(a?.totalTax),
                "Retainage Amount": formatCurrency(a?.retainageAmount),
              },
            },
          ],
        };
      }),
    }),
  };
};
