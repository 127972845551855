import { useState } from "react";
import { LeftArrow, RightArrow } from "../../../../../BasePage/src";
import ServiceGroupItem from "../../../../../../commonComponents/ServiceGroupItem/ServiceGroupItem";

export function InvoiceSelector({
  invoices,
  selectedInvoices,
  setSelectedInvoices,
}) {
  const [collapsed, setCollapsed] = useState(false);

  const onServiceClick = ({ e, invoiceId }) => {
    e.preventDefault();
    setSelectedInvoices((prev) =>
      structuredClone({ ...prev, [invoiceId]: !prev[invoiceId] })
    );
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`itemsGroupContainer ${collapsed && "collapsed"}`}>
      <div class="services-collapse" onClick={toggleCollapse}>
        {collapsed ? <RightArrow /> : <LeftArrow />}
      </div>
      {invoices?.map(({ invoiceId, invoiceNumber, invoiceItems }, key) => {
        const isChecked = selectedInvoices?.[invoiceId];
        return (
          <ServiceGroupItem
            key={key}
            {...{
              index: key,
              label: `Invoice ${invoiceNumber}`,
              length: invoiceItems?.length,
              checked: isChecked,

              onClick: (e) => onServiceClick({ e, invoiceId }),
            }}
          />
        );
      })}
    </div>
  );
}
