import React from "react";
import { FolderFileIcon } from "../../../../../../assets";

import "./choose-drive-folder-view.scss";

function ChooseDriveFolderView({ folderName, onClick, isSelected }) {
  return (
    <div
      className={`choose-drive-folder-view-container ${
        isSelected ? "selected" : ""
      }`}
      onClick={onClick}
    >
      <FolderFileIcon />
      {folderName}
    </div>
  );
}

export default ChooseDriveFolderView;
