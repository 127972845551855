import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const todos = (updatedDynamicStates, IdHandler, programFields) => {
  const todoStatus = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.["To Do"];

  return {
    todoStatus: () => ({
      title: "To Do",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "To Do",
        field: "todoStatus",
        id: IdHandler,
        page: "todos",
        labels: todoStatus?.map?.((el) => el?.statusName),
        datasets: [
          {
            label: "To Do",
            data: todoStatus?.map?.(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter?.(
                  (a) => a.todoStatus === status
                )?.length
            ),
            backgroundColor: todoStatus?.map((el) => el?.statusColor),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    todoCategory: () => ({
      title: "To Do Category",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "To Do",
        field: "todoCategory",
        id: IdHandler,
        page: "todos",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "todoCategory"
          )
        ),
        datasets: [
          {
            label: "To Do",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoCategory"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoCategory"
              )
            )?.map?.(() => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    todoUser: () => ({
      title: "To Do for each User",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "To Do",
        field: "todoUser",
        id: IdHandler,
        page: "todos",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "todoUser"
          )
        ),
        datasets: [
          {
            label: "To Do",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoUser"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "todoUser"
              )
            )?.map?.(() => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    relatedTo: () => ({
      title: "To Do Related To",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "To Do",
        field: "relatedTo",
        id: IdHandler,
        page: "todos",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "relatedTo"
          )
        ),
        datasets: [
          {
            label: "To Do",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "relatedTo"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "relatedTo"
              )
            )?.map?.(() => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
