import dayjs from "dayjs";
import { getPeriodMonth } from "../../../Dashboard/logicDataFunctions/calculatingFunctions";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import {
  filterData,
  filterDynamicStates,
  setSingleCard,
} from "../FilterView/filterViewUtils";
import { boroughs } from "../../../Dashboard/addWidgetsModalData";
import { toCamelCase } from "src/components/pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
var isoWeek = require("dayjs/plugin/isoWeek");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
// import dayjs from "dayjs-timezone";
dayjs.tz.setDefault("America/New_York");
dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);

export const FILTER_VALUES = () => {
  const initial = [
    dayjs().subtract(1, "month").startOf("month").valueOf(),
    dayjs().endOf("month").valueOf(),
  ];
  const today = [
    dayjs().startOf("day").valueOf(),
    dayjs().endOf("day").valueOf(),
  ];
  const yesterday = [
    dayjs().subtract(1, "day").startOf("day").valueOf(),
    dayjs().subtract(1, "day").endOf("day").valueOf(),
  ];
  const thisWeek = [
    dayjs().startOf("isoWeek").subtract(1, "day").valueOf(),
    dayjs().endOf("isoWeek").subtract(1, "day").valueOf(),
  ];
  const previousWeek = [
    dayjs().subtract(1, "week").startOf("isoWeek").subtract(1, "day").valueOf(),
    dayjs().subtract(1, "week").endOf("isoWeek").subtract(1, "day").valueOf(),
  ];
  const thisMonth = [
    dayjs().startOf("month").valueOf(),
    dayjs().endOf("month").valueOf(),
  ];
  const previousMonth = [
    dayjs().subtract(1, "month").startOf("month").valueOf(),
    dayjs().subtract(1, "month").endOf("month").valueOf(),
  ];
  const thisYear = [
    dayjs().startOf("year").valueOf(),
    dayjs().endOf("year").valueOf(),
  ];
  const previousYear = [
    dayjs().subtract(1, "year").startOf("year").valueOf(),
    dayjs().subtract(1, "year").endOf("year").valueOf(),
  ];
  const thisQuarter = [
    dayjs().startOf("quarter").valueOf(),
    dayjs().endOf("quarter").valueOf(),
  ];
  const previousQuarter = [
    dayjs().subtract(1, "quarter").startOf("quarter").valueOf(),
    dayjs().subtract(1, "quarter").endOf("quarter").valueOf(),
  ];
  const quarter1 = [
    dayjs().quarter(1).startOf("quarter").valueOf(),
    dayjs().quarter(1).endOf("quarter").valueOf(),
  ];
  const quarter2 = [
    dayjs().quarter(2).startOf("quarter").valueOf(),
    dayjs().quarter(2).endOf("quarter").valueOf(),
  ];
  const quarter3 = [
    dayjs().quarter(3).startOf("quarter").valueOf(),
    dayjs().quarter(3).endOf("quarter").valueOf(),
  ];
  const quarter4 = [
    dayjs().quarter(4).startOf("quarter").valueOf(),
    dayjs().quarter(4).endOf("quarter").valueOf(),
  ];
  const doneAllTime = [0, dayjs().endOf("day").valueOf()];

  return {
    initial,
    today,
    yesterday,
    thisWeek,
    previousWeek,
    thisMonth,
    previousMonth,
    thisYear,
    previousYear,
    thisQuarter,
    previousQuarter,
    quarter1,
    quarter2,
    quarter3,
    quarter4,
    doneAllTime,
    allTime: doneAllTime,
  };
};

const isEndValid = (end) => {
  if (end.endOf("day").valueOf() > dayjs().endOf("day").valueOf()) {
    return dayjs().endOf("day");
  }
  return end;
};
export const getDashboardRangePresets = () => [
  {
    label: "Today",
    key: "Today",
    value: [dayjs().startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Yesterday",
    key: "Yesterday",
    value: [
      dayjs().subtract(1, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "This Week",
    key: "This Week",
    value: [
      dayjs().startOf("isoWeek").subtract(1, "day"),
      isEndValid(dayjs().endOf("isoWeek").subtract(1, "day")),
    ],
  },
  {
    label: "Previous Week",
    key: "Previous Week",
    value: [
      dayjs().subtract(1, "week").startOf("isoWeek").subtract(1, "day"),
      dayjs().subtract(1, "week").endOf("isoWeek").subtract(1, "day"),
    ],
  },
  {
    label: "This Month",
    key: "This Month",
    value: [dayjs().startOf("month"), isEndValid(dayjs().endOf("month"))],
  },
  {
    label: "Previous Month",
    key: "Previous Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "This Year",
    key: "This Year",
    value: [dayjs().startOf("year"), isEndValid(dayjs().endOf("year"))],
  },
  {
    label: "Previous Year",
    key: "Previous Year",
    value: [
      dayjs().subtract(1, "year").startOf("year"),
      dayjs().subtract(1, "year").endOf("year"),
    ],
  },
];

export const INITIAL_FILTER_VALUES = {
  today: true,
  yesterday: true,
  thisWeek: true,
  previousWeek: true,
  thisMonth: true,
  previousMonth: true,
};

const isAccounting = (dynamicStates) => {
  const url = window.location.pathname.split("/")[1];
  if (url === "accounting") {
    if (
      !!dynamicStates[id]?.[0]?.applicationId ||
      !!dynamicStates[id]?.[0]?.chargeId ||
      !!dynamicStates[id]?.[0]?.invoiceId
    ) {
      return true;
    }
    return false;
  }
  return false;
};

const filterConstants = [
  {
    value: "Today",
    filterType: "today",
  },
  {
    value: "Yesterday",
    filterType: "yesterday",
  },
  {
    value: "This Week",
    filterType: "thisWeek",
  },
  {
    value: "Previous Week",
    filterType: "previousWeek",
  },
  {
    value: "This Month",
    filterType: "thisMonth",
  },
  {
    value: "Previous Month",
    filterType: "previousMonth",
  },
  {
    value: "This Year",
    filterType: "thisYear",
  },
  {
    value: "Previous Year",
    filterType: "previousYear",
  },
  {
    value: "This Quarter",
    filterType: "thisQuarter",
  },
  {
    value: "Previous Quarter",
    filterType: "previousQuarter",
  },
  {
    value: "Quarter 1",
    filterType: "quarter1",
  },
  {
    value: "Quarter 2",
    filterType: "quarter2",
  },
  {
    value: "Quarter 3",
    filterType: "quarter3",
  },
  {
    value: "Quarter 4",
    filterType: "quarter4",
  },
  {
    value: "Done All Time",
    filterType: "doneAllTime",
  },
];

const filterFunction = (
  value,
  filterType,
  setUpdatedDynamicStates,
  updatedDynamicStates,
  dynamicStates,
  id,
  setFilterStates,
  table
) => {
  const filterValues = FILTER_VALUES();
  const [startDate, endDate] = filterValues?.[`${filterType}`];

  const filterKey = table === "Project Cost" ? "punchTimeStamp" : "createdAt";
  return {
    value,
    filter: () => {
      // setUpdatedDynamicStates(() => ({
      //   ...updatedDynamicStates,
      //   [id]: !!isAccounting(dynamicStates)
      //     ? dynamicStates[id]?.filter((b) =>
      //         getPeriodMonth({
      //           periodTo: !!b?.periodTo?.start
      //             ? b?.periodTo
      //             : { start: b?.createdAt, end: b?.createdAt + 1 },
      //           start: startDate,
      //           end: endDate,
      //         })
      //       )
      //     : dynamicStates[id]?.filter(
      //         (b) => b?.[filterKey] >= startDate && b?.[filterKey] <= endDate
      //       ),
      // }));
    },
    buttonName: () =>
      setFilterStates((prev) => ({
        ...prev,
        filterByTime: value,
      })),
  };
};

const filterAllFunction = (
  rowData,
  dynamicStates,
  setAllFilterStates,
  setRowData,
  programFields,
  value,
  filterType,
  tables,
  userConfiguration
) => {
  const [startDate, endDate] = FILTER_VALUES()?.[filterType];
  // return;
  return {
    value,
    filter: () => {
      let toFilter = {};
      rowData?.forEach((el) => {
        setAllFilterStates((prev) => ({
          ...prev,
          [el?.id]: allFiltersObject(value),
        }));
        toFilter[el?.id] = dynamicStates[el?.id]?.filter(
          (b) => b?.createdAt >= startDate && b?.createdAt <= endDate
        );
      });
      setRowData((prev) =>
        prev?.map((el) => {
          return cardToSet(
            el,
            programFields,
            toFilter,
            dynamicStates,
            boroughs,
            tables,
            userConfiguration
          );
        })
      );
    },
  };
};
//filter by time keys and functions for each key
export const filterOptions = (
  setUpdatedDynamicStates,
  updatedDynamicStates,
  id,
  dynamicStates,
  setFilterStates,
  table
) => {
  return filterConstants.map((filter) => {
    return filterFunction(
      filter.value,
      filter.filterType,
      setUpdatedDynamicStates,
      updatedDynamicStates,
      dynamicStates,
      id,
      setFilterStates,
      table
    );
  });
};
//this function turns all daa that are created on the date that the user pick
export const datePickerProgressHandler = (
  e,
  setUpdatedDynamicStates,
  updatedDynamicStates,
  dynamicStates,
  id,
  setFilterStates,
  preferenceFilter = false
) => {
  // const url = window.location.pathname.split("/")[1];

  if (preferenceFilter === false) {
    setFilterStates((prev) => ({
      ...prev,
      filterByDay: e,
      filterByRange: null,
      filterByTime: "Pick Range",
      // filterByName: null,
      // filterByStatus: null,
    }));
  }
};
//this returns data that are created on a range of 2 dates that the user pick
export const dateRangeDateProgressHandler = (
  e,
  setUpdatedDynamicStates,
  updatedDynamicStates,
  dynamicStates,
  id,
  setFilterStates,
  preferenceFilter = false
) => {
  // const url = window.location.pathname.split("/")[1];
  if (preferenceFilter === false) {
    setFilterStates((prev) => ({
      ...prev,
      filterByDay: null,
      filterByRange: e,
      filterByTime: "Pick Range",
      // filterByName: null,
      // filterByStatus: null,
      // filterByProjectManager: null,
    }));
  }
};
//those 2 functions add and remove filters for the progress card
export const handlerAddFilter = (filterByName, setfilterByName) => {
  if (filterByName === true) {
    setfilterByName(false);
    document?.getElementById("landi")?.classList?.remove("out");
    document?.getElementById("landi")?.classList?.add("active");
  }
};
export const handlerRemoveFilter = (filterByName, setfilterByName) => {
  if (filterByName === false) {
    document?.getElementById("landi")?.classList?.remove("active");
    document?.getElementById("landi")?.classList?.add("out");
    setTimeout(async () => {
      setfilterByName(true);
    }, 290);
  }
};
//filter by the first field on progress status
export const filterByAddresHandler = (
  e,
  setUpdatedDynamicStates,
  id,
  secondaryField,
  dynamicStates,
  setFilterStates
) => {
  // setUpdatedDynamicStates((prev) => ({
  //   ...prev,
  //   [id]: dynamicStates[id]?.filter((b) =>
  //     b[secondaryField]?.toLowerCase()?.includes(e?.toLowerCase())
  //   ),
  // }));

  setFilterStates((prev) => ({
    ...prev,
    // filterByDay: null,
    // filterByRange: null,
    filterByTime: "Pick Range",
    filterByName: e,
    // filterByStatus: null,
    // filterByProjectManager: null,
  }));
};

//filter by the first field on progress status
export const filterByProjectManagerHandler = (
  e,
  setUpdatedDynamicStates,
  id,
  thirdField,
  dynamicStates,
  setFilterStates,
  filterStates,
  table
) => {
  setFilterStates((prev) => ({
    ...prev,
    filterByProjectManager: e,
  }));
  // const filterByPmActive = e !== null && e !== "Pick option";

  // const filterByTimeActive =
  //   filterStates?.filterByTime !== null &&
  //   filterStates?.filterByTime !== "Pick Range";
  // const filterByDateActive = filterStates?.filterByDay !== null;

  // setUpdatedDynamicStates((prev) => {
  //   let dataToUse = [...(dynamicStates[id] || [])];

  //   const dateKey = table === "Project Cost" ? "punchTimeStamp" : "createdAt";
  //   if (filterByTimeActive) {
  //     const [startDate, endDate] =
  //       FILTER_VALUES()?.[toCamelCase(filterStates?.filterByTime)];
  //     dataToUse = [...(dynamicStates[id] || [])]?.filter(
  //       (el) => el?.[dateKey] >= startDate && el?.[dateKey] <= endDate
  //     );
  //   } else if (filterByDateActive) {
  //     dataToUse = [...(dynamicStates[id] || [])]?.filter(
  //       (el) =>
  //         el?.[dateKey] >=
  //           dayjs(filterStates?.filterByDay).startOf("day")?.valueOf() &&
  //         el?.[dateKey] <=
  //           dayjs(filterStates?.filterByDay)?.endOf("day")?.valueOf()
  //     );
  //   }

  //   const filteredData = dataToUse?.filter((rowData) => {
  //     if (Array.isArray(rowData?.[thirdField])) {
  //       return rowData?.[thirdField]?.some((manager) => {
  //         if (typeof manager === "object" && !Array.isArray(manager)) {
  //           return manager?.namOfUser?.toLowerCase() === e?.toLowerCase();
  //         }
  //         return manager?.toLowerCase() === e?.toLowerCase();
  //       });
  //     }
  //     return rowData?.[thirdField]?.toLowerCase() === e?.toLowerCase();
  //   });

  //   return {
  //     ...prev,
  //     [id]: filteredData,
  //   };
  // });
};

//filter by the secon field on progress status
export const filterByStageHandler = (
  e,
  setUpdatedDynamicStates,
  id,
  fields,
  dynamicStates,
  setFilterStates
) => {
  // setUpdatedDynamicStates((prev) => ({
  //   ...prev,
  //   [id]: dynamicStates[id]?.filter((b) => {
  //     if (typeof b[fields] === "string")
  //       return b[fields]?.toLowerCase() === String(e)?.toLowerCase();
  //     else return b[fields] === e;
  //   }),
  // }));

  setFilterStates((prev) => ({
    ...prev,
    filterByStatus: e.toString(),
  }));
};

export const handlePreferenceFilter = (toFilter, tables, statesData) => {
  const filterValues = FILTER_VALUES();
  let toReturn = {};
  //we go over all the toFilter object keys and check if they have any filter
  keys?.(toFilter)?.forEach((key) => {
    //if they don't have any filter we return the data for this month
    if (toFilter[key] === "noFilter") {
      toReturn[statesData?.[key]] = tables[statesData?.[key]]?.filter((el) => {
        return (
          getPeriodMonth({
            periodTo: !!el?.periodTo?.start
              ? el?.periodTo
              : {
                  start: el?.createdAt,
                  end: el?.createdAt + 1,
                },
            start: dayjs().startOf("month").valueOf(),
            end: dayjs().endOf("month").valueOf(),
          }) > 0
        );
      });
    } else {
      //if they have a filter we check what filter they have and return the data
      const { BodyComponentParams = {} } = toFilter[key];
      const { filterStates = {} } = BodyComponentParams;
      //if they do not have any filter we return the data for this month
      if (!filterStates) {
        toReturn[statesData?.[key]] = tables[statesData?.[key]]?.filter(
          (el) => {
            return (
              getPeriodMonth({
                periodTo: !!el?.periodTo?.start
                  ? el?.periodTo
                  : {
                      start: el?.createdAt,
                      end: el?.createdAt + 1,
                    },
                start: dayjs().startOf("month").valueOf(),
                end: dayjs().endOf("month").valueOf(),
              }) > 0
            );
          }
        );
      } else if (!!filterStates?.filterByTime) {
        //if they have a filter we check what filter they have and return the data
        if (filterStates?.filterByTime === "Pick Range") {
          if (!!filterStates?.filterByDay) {
            toReturn[statesData?.[key]] = tables[statesData?.[key]]?.filter(
              (el) =>
                el?.createdAt >= dayjs(filterStates?.filterByDay)?.valueOf() &&
                el?.createdAt <=
                  dayjs(filterStates?.filterByDay)?.endOf("day")?.valueOf()
            );
          } else if (!!filterStates?.filterByRange) {
            toReturn[statesData?.[key]] = tables[statesData?.[key]]?.filter(
              (el) =>
                el?.createdAt >=
                  dayjs(filterStates?.filterByRange[0])?.valueOf() &&
                el?.createdAt <=
                  dayjs(filterStates?.filterByRange[1])?.endOf("day")?.valueOf()
            );
          } else if (!!filterStates?.filterByName) {
            toReturn[statesData?.[key]] = tables[statesData?.[key]]?.filter(
              (el) =>
                el?.[BodyComponentParams?.secondaryField]
                  ?.toLowerCase()
                  ?.includes(filterStates?.filterByName?.toLowerCase())
            );
          } else if (!!filterStates?.filterByStatus) {
            toReturn[statesData?.[key]] = tables[statesData?.[key]]?.filter(
              (el) => {
                if (typeof el?.[BodyComponentParams?.field] === "string")
                  return (
                    el?.[BodyComponentParams?.field]?.toLowerCase() ===
                    String(filterStates?.filterByStatus)?.toLowerCase()
                  );
                else
                  return (
                    el?.[BodyComponentParams?.field] ===
                    filterStates?.filterByStatus
                  );
              }
            );
          }
        } else {
          const filterByTime = toCamelCase(filterStates?.filterByTime);

          const [startDate, endDate] = filterValues[filterByTime];

          toReturn[statesData?.[key]] = tables[statesData?.[key]]?.filter(
            (el) => el?.createdAt >= startDate && el?.createdAt <= endDate
          );
        }
      }
    }
  });

  return toReturn;
};

const allFiltersObject = (filterByTime) => {
  return {
    filterByDay: null,
    filterByRange: null,
    filterByTime,
    filterByName: null,
    filterByStatus: null,
    filterByProjectManager: null,
  };
};

const cardToSet = (
  el,
  programFields,
  toFilter,
  dynamicStates,
  boroughs,
  tables,
  userConfiguration
) => {
  const newData = filterData(el?.type, {
    tables,
    programFields,
    updatedDynamicStates: toFilter,
    id: el?.id,
    boroughs,
    dynamicStates,
    circleProgressPicker: el?.BodyComponentParams?.content || null,
    userConfiguration,
  })
    [el?.BodyComponentParams?.table]()
    [el?.BodyComponentParams?.field]();

  return setSingleCard(
    el,
    el?.type,
    el?.id,
    newData,
    "dashboard",
    toFilter,
    el?.BodyComponentParams?.content
  );
};
export const filterOptionsMain = (
  rowData,
  dynamicStates,
  setAllFilterStates,
  setRowData,
  programFields,
  tables,
  userConfiguration
) => {
  return filterConstants.map((filter) => {
    return filterAllFunction(
      rowData,
      dynamicStates,
      setAllFilterStates,
      setRowData,
      programFields,
      filter?.value,
      filter?.filterType,
      tables,
      userConfiguration
    );
  });
};

function getFilter({
  filterStates,
  addressKey,
  el,
  filterKey = "createdAt",
  statusKey,
}) {
  const filterValues = FILTER_VALUES();

  const {
    filterByTime = null,
    filterByDay = null,
    filterByRange = null,
    filterByName = null,
    filterByStatus = null,
    filterByProjectManager = null,
  } = filterStates;

  if (!!filterByTime) {
    if (!!filterByDay) {
      return (
        dayjs(filterByDay)?.startOf("day")?.valueOf() <= el?.[filterKey] &&
        el?.[filterKey] <= dayjs(filterByDay)?.endOf("day")?.valueOf()
      );
    } else if (!!filterByRange) {
      return (
        dayjs(filterByRange[0])?.startOf("day")?.valueOf() <= el?.[filterKey] &&
        dayjs(filterByRange[1])?.endOf("day")?.valueOf() >= el?.[filterKey]
      );
    } else {
      const filterString = toCamelCase(filterByTime);
      if (!filterValues[filterString]) {
        const [startDate, endDate] = filterValues.thisMonth;

        return el?.[filterKey] >= startDate && el?.[filterKey] <= endDate;
      } else {
        const [startDate, endDate] = filterValues[filterString];

        return el?.[filterKey] >= startDate && el?.[filterKey] <= endDate;
      }
    }
  }
  if (!!filterByName) {
    return el?.[addressKey]
      ?.toLowerCase()
      ?.includes(filterByName?.toLowerCase());
  }
  if (!!filterByStatus) {
    if (typeof el?.[addressKey] === "string")
      return (
        el?.[addressKey]?.toLowerCase() ===
        String(filterByStatus)?.toLowerCase()
      );
    else {
      return el?.[addressKey] === filterByStatus;
    }
  }
}

/**
 * Filters charts based on the provided parameters.
 *
 * @param {Object} tables - The tables Object.
 * @param {Array} programFields - The program fields array.
 * @param {Object} updatedDynamicStates - The updated dynamic states object.
 * @param {Object} dynamicStates - The dynamic states object.
 * @param {Object} card - The card object.
 * @param {Array} topicCategories - The topic categories array.
 * @param {Object} userConfiguration - The user configuration object.
 * @returns {Object} - The filtered charts.
 */
export function filterChartsFromPref({
  tables = {},
  programFields = [],
  updatedDynamicStates = {},
  dynamicStates = {},
  card = {},
  topicCategories = [],
  userConfiguration = {},
}) {
  let statesToSet = { ...updatedDynamicStates };
  const { table = "", field = "" } = card?.BodyComponentParams;
  const { type = "" } = card;

  const { statusKey = "" } =
    topicCategories?.find((el) => el?.categoryName === table) || {};

  const addressKey = topicCategories
    ?.find((el) => el?.categoryName === table)
    ?.keysToUse?.find(
      (el) =>
        el?.toLowerCase().includes("address") ||
        el?.toLowerCase() === "projectname"
    );

  const filterStates = card?.BodyComponentParams?.filterStates || null;

  let filterKey = "createdAt";

  if (table === "Project Cost") {
    filterKey = "punchTimeStamp";
  }

  /**
   * If there are no filter states, filter by this month.
   */
  if (!filterStates || !Object?.keys(filterStates)?.length) {
    const [startDate, endDate] = FILTER_VALUES().thisMonth;

    statesToSet = {
      ...statesToSet,
      [card?.id]: updatedDynamicStates?.[card?.id]?.filter(
        (el) => el?.[filterKey] >= startDate && el?.[filterKey] <= endDate
      ),
    };

    const newData = filterData(type, {
      tables,
      programFields,
      updatedDynamicStates: statesToSet,
      id: card?.id,
      boroughs,
      dynamicStates,
      circleProgressPicker: card?.BodyComponentParams?.content || null,
      userConfiguration,
    })
      ?.[table]()
      ?.[field]();

    return setSingleCard(
      card,
      type,
      card?.id,
      newData,
      "dashboard",
      statesToSet,
      card?.BodyComponentParams?.content
    );
  }

  /**
   * If there are filter states, filter by the provided filters.
   */
  if (!!filterStates) {
    statesToSet = {
      ...statesToSet,
      [card?.id]: filterDynamicStates({
        id: card?.id,
        table: card?.table,
        statusKey,
        addressKey,
        tmpFilterStates: filterStates,
        dynamicStates,
        secondaryField: card?.BodyComponentParams?.secondaryField,
        thirdField: card?.BodyComponentParams?.thirdField,
      }),
      // updatedDynamicStates?.[card?.id]?.filter((el) => {
      //   return getFilter(filterStates, addressKey, el, filterKey);
      // }),
    };

    const newData = filterData(type, {
      tables,
      programFields,
      updatedDynamicStates: statesToSet,
      id: card?.id,
      boroughs,
      dynamicStates,
      circleProgressPicker: card?.BodyComponentParams?.content || null,
      userConfiguration,
    })
      ?.[table]()
      ?.[field]();

    return setSingleCard(
      card,
      type,
      card?.id,
      newData,
      "dashboard",
      statesToSet,
      card?.BodyComponentParams?.content
    );
  }
  // });
}

export const pmTables = {
  Projects: true,
  Scheduling: true,
  Estimations: true,
  Opportunities: true,
  "Add-ons": true,
};
