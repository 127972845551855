import { message } from "antd";

export const changeProgress = ({
	selectedService,
	thisElevation,
	fakeRentals,
	thisPli,
	params,
}) => {
	let changingValue = undefined;

	try {
		changingValue = eval(params.newValue);
	} catch (e) {
		changingValue = 0;
		console.error(e);
	}

	//this const calculates the new weekly amount
	let totalPercentage =
		changingValue < 0
			? (() => {
					message?.warning(`Input is less than 0!`);
					return 0;
			  })()
			: //if the value from user is greater than currentMaxValue per week return the currentMaxValue per week and a message to inform the user for the problem
			changingValue > 100
			? (() => {
					message?.warning(`Input is greater than 100`);
					return 1;
			  })()
			: //else if we dont have any of those problems divide the input form user
			  changingValue / 100;

	return {
		...fakeRentals,
		services: fakeRentals?.services?.map((serviceData) =>
			//here we check if this object is the object of the selected service so we can edit it
			serviceData?.label === selectedService
				? {
						...serviceData,
						serviceOptions: serviceData?.serviceOptions?.map((elevationData) =>
							//here we check if this object is the object of the elevation that we are trying to modify so we can edit it
							elevationData?.elevationId === thisElevation
								? {
										...elevationData,
										items: elevationData?.items?.map((pli) => {
											const rentPercentage = serviceData?.rentPercentage;
											//here we find the how many days are in this pli
											const numberOfDays = pli?.rentalDetails?.details?.flatMap(
												({ days }) => days
											)?.length;
											//here we calculate the new weekly amount (monthlyAmount/4) * ( the percentage from user ) * (rent percentage that we get from dataEntryGrid)
											const weeklyAmount =
												elevationData?.elevationLabel !== "Hoist"
													? // ? (pli?.rent / 4) * totalPercentage * (rentPercentage / 100) * 10
													  (pli?.rent / 4) * totalPercentage
													: (pli?.rentalsTotal / 4) * totalPercentage;
											//   (rentPercentage || 100 / 100);
											//here we calculate the new daily amount by dividing the new calculated weekly amount with 7
											const dailyAmount = weeklyAmount / 7;

											console.log("number of days, progress", { numberOfDays, weeklyAmount });

											//here we check if this object is the object of the pli that we are trying to modify so we can edit it
											return pli?.id === thisPli
												? {
														...pli,
														//here we set the applied amount for this pli (calculated daily amount)* (the number of days in this pli)
														// appliedAmount: dailyAmount * numberOfDays,
														appliedAmount: dailyAmount * numberOfDays,

														totalProgress: totalPercentage,
														maxWeeklyAmount: weeklyAmount,
														rentalDetails: {
															...pli?.rentalDetails,
															details: pli?.rentalDetails?.details?.map((weeksData) => {
																//here we find how many days are in this week
																const noOfDaysInThisWeek = weeksData?.days?.length;

																return {
																	...weeksData,
																	//here we set the new weekly amount to the (calculated dailyAmount) * (the number of days that that week) contains
																	weeklyAmount: dailyAmount * noOfDaysInThisWeek,
																	days: weeksData?.days?.map((daysData) => ({
																		...daysData,
																		//here we set the new daily amount to the calculated dailyAmount
																		dailyAmount,
																	})),
																};
															}),
															weeklyAmount,
														},
												  }
												: //if this is not the pli that we are trying to change return it unchanged
												  pli;
										}),
								  }
								: //if this is not the elevation that we are working on return it unchanged
								  elevationData
						),
				  }
				: //if this is not the selected service return it unchanged
				  serviceData
		),
	};
};
