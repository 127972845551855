import moment from "moment";
import { formatCurrency } from "../../../../../../../utils";

function removeHtmlTags(html) {
  return html.replace(/<[^>]*>/g, "");
}

export const documentDefinition = (title, companyLogo, currentCharge) => {
  console.log({ currentCharge });
  const formatDate = (date) => moment(date).format("MM/DD/YYYY");

  // Calculate totals
  const totalServiceAmount = currentCharge.chargeItems.reduce(
    (acc, chargeItem) => acc + chargeItem?.price || 0,
    0
  );

  const totalTaxAmount = currentCharge.chargeItems.reduce(
    (acc, chargeItem) => acc + chargeItem?.taxAmount || 0,
    0
  );

  const totalAmount = currentCharge.chargeItems.reduce(
    (acc, chargeItem) =>
      acc + (chargeItem?.price || 0) + (chargeItem?.taxAmount || 0),
    0
  );

  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: "logo", // Use the "logo" image defined in images
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                alignment: "right",
                margin: [10, 10, 0, 10],
              },
              {
                text: `Date: ${formatDate(currentCharge.createdAt)}`,
                alignment: "right",
                style: "bool", // Style for the date
              },
            ],
            width: "*",
            alignment: "center",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        text: "Charge Information:", // Change "Payment Information" to "Charge Information"
        style: ["strong"],
      },
      {
        // Create a columns section to split the content into two sides
        columns: [
          // Left side content
          {
            stack: [
              {
                text: `Project Address: ${currentCharge.projectAddress}`,
                alignment: "left",
                margin: [0, 0, 0, 10],
              },
            ],
            style: "listItem",
          },
          // Right side content
          {
            stack: [
              {
                text: `Category From: ${currentCharge.categoryFrom}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
              {
                text: `Charge Amount: ${formatCurrency(
                  currentCharge.chargeAmount
                )}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
              {
                text: `Charge Status: ${currentCharge.chargeStatus}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
            ],
            style: "listItem",
            // alignment: "right",
          },
        ],
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        table: {
          headerRows: 1,
          widths: ["auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              { text: "Service", bold: true },
              { text: "Description", bold: true },
              { text: "Price", bold: true },
              { text: "Tax Amount", bold: true },
              { text: "Total", bold: true },
            ],
            ...currentCharge.chargeItems.map((chargeItem) => [
              chargeItem?.label || "",
              removeHtmlTags(
                chargeItem?.chargeDescription ||
                  chargeItem?.serviceDescription ||
                  ""
              ),
              formatCurrency(chargeItem?.price || 0),
              formatCurrency(chargeItem?.taxAmount || 0),
              formatCurrency(
                (chargeItem?.price || 0) + (chargeItem?.taxAmount || 0)
              ),
            ]),
            // Add a row to display the total charge amount
            [
              { text: "Total Charge Amount:", bold: true },
              "",
              { text: formatCurrency(totalServiceAmount), bold: true },
              { text: formatCurrency(totalTaxAmount), bold: true },
              { text: formatCurrency(totalAmount), bold: true },
            ],
          ],
        },
        style: "outerTable",
      },
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { fontSize: 12 },
      header: { margin: [0, 0, 0, 20] },
      listItem: { margin: [5, 5] },
      bool: { margin: [0, 5], fontSize: 13 },
      outerTable: { margin: [0, 0, 0, 30] },
    },
  };
};
