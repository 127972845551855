import React, { useEffect, useState } from "react";

import { getAddCoordinates } from "./utils/helpers";
import Geofence from "../../../../commonComponents/Map/Geofence/Geofence";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";

const VendorAddressMap = ({ address }) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (!!address) {
      getAddCoordinates({ address }).then((result) => {
        setLocations(result);
      });
    }
  }, [address]);

  return (
    <div>
      {locations?.filter(
        (location) =>
          location?.coordinates && !!keys(location?.coordinates)?.length
      ).length > 0 && (
        <Geofence
          places={[
            {
              coordinates: locations
                ?.filter((location) => location?.coordinates)
                .map(({ coordinates }) => coordinates),
            },
          ]}
          showDrawingManager={false}
          allowEditing={false}
        />
      )}
    </div>
  );
};

export default VendorAddressMap;
