import { deletePromise } from ".";

/**
 * Deletes related notifications based on the provided recordId and notifications array.
 *
 * @param {string} recordId - The ID of the record.
 * @param {Array} notifications - The array of notifications.
 * @returns {Promise} A promise that resolves when the notifications are deleted successfully.
 */
async function deleteRelatedNotifications({ recordId, notifications }) {
  if (!recordId || !notifications?.length) return;
  const listOfNotifications = notifications?.filter(
    (el) => el?.notificationBody?.notificationObject?.id === recordId
  );
  try {
    const notificationsToDelete = [];
    listOfNotifications.forEach((el) =>
      notificationsToDelete?.push(
        deletePromise({ id: el?.id, endpoint: "notifications" })
      )
    );
    if (!!notificationsToDelete?.length) {
      return await Promise.allSettled(notificationsToDelete)?.then((e) =>
        console.log("Notifications deleted Successfully", e)
      );
    }
  } catch (err) {
    console.error("Error deleting Notifications", err);
  }
}

export default deleteRelatedNotifications;
