import { updateRental } from "../../../../../../../../../../../../Utils/rentalManipulation"

export const lockChangedDay = ({
  setUnchangingFakeRentals,
  unchangingFakeRentals,
  selectedElevation,
  selectedService,
  selectedItem,
  selectedWeek,
  params,
}) => {
  //id of elevation that this day is in
  const thisElevation = selectedElevation?.elevationId
  //id of the pli that this day is in
  const thisPli = selectedItem?.id
  //id of the week that this day is in
  const thisWeek = selectedWeek?.week
  //id of the day that we are trying to lock
  const thisDay = params?.data?.id

  // this object is the new rental object
  const newRental = updateRental({
    fakeRentals: unchangingFakeRentals,
    thisElevation,
    selectedService,
    thisWeek,
    thisPli,
    thisDay,
    daysParams: {
      lock: !params?.value,
    },
  })

  //here we save the new object
  setUnchangingFakeRentals(newRental)
}

//this function tell the user why the check all is disabled
export const getDisabledTooltipContentDay = ({
  unchangingFakeRentals,
  selectedElevation,
  selectedService,
  selectedItem,
  selectedWeek,
  isWritable,
  params,
}) => {
  //here we find the day in witch the lock button is
  const data = unchangingFakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === selectedElevation?.elevationId)
    ?.items?.find(({ id }) => id === selectedItem?.id)
    ?.rentalDetails?.details?.find(({ weeks }) => weeks === selectedWeek?.weeks)
    ?.days?.find(({ id }) => id === params?.data?.id)

  return !!data?.charged
    ? "This day is charged you cant unlock it!!" //the case where all day are charged
    : !isWritable
    ? "Enable write mode to edit!" //the case where write mode is off
    : "" //the case where non of those above are true
}
