import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import Swal from "sweetalert2";
import { Button, Collapse, message, Modal, Spin, Tour } from "antd";
import PLIPerService from "../PLIPerService/PLIPerService";
import { getTotalWorkingHours } from "../helpers/calculators";
import ScheduleDay from "../models/ScheduleDay";
// import Loader from "react-loader-spinner";
import { LoadingDots } from "../../../../commonComponents/3LoadingDots/LoadingDots";
import { useDispatch, useSelector } from "react-redux";
import "./Redesign.scss";
import {
  ScheduleDaysDispatch,
  ScheduleServicesDispatch,
} from "../../../../../actions/schedule";
import { HelperButtons } from "./helperButtons/HelperButtons";
import { TypeOfWorkDefinitions } from "../../../../SidebarPages/Scheduling/models/TypeOfWorkDefinitions";

import { ScheduleDayView } from "./ScheduleDayViews/ScheduleDayView";
import { correctDayNumberInScheduleDays } from "../helpers/scheduleDaysFunctions";
import API from "@aws-amplify/api";
// import dayjs from "dayjs-timezone";
import { DayStatus } from "./DayStatusChangeModal/DayStatuses";
import { v4 as uuidv4 } from "uuid";
import { useRedux } from "../../../../SidebarPages/hooks/useRedux";
import dayjs from "dayjs";
import { useMultipleRefs } from "../../../../../hooks/useMultipleRefs";
import { useModalOnce } from "../../../../../hooks/useModalOnce";
import {
  availableEstimations,
  dayDetail,
  getStateSizeInKB,
  getStateSizeInMB,
  onlyEstWithScopeFalse,
  pliEditedFromNextSchedules,
  postponeDaysInServices,
} from "../SchedulingFirstPage/helperData";
import { driveApi } from "../../../../../integrations/DriveRequest";
import { driveFolderFunction } from "./ServiceDocumentation/utils/utils";
import { VideoTutorialIcon } from "../../../components/ModalVideoTutorials/assets/svg";
import { MondayButton } from "../../../../commonComponents";
import { PlayVideoTutorial } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import ScheduleDaysPreview from "./ScheduleDaysPreview";
import { quickStatistics_Schedule_and_Project } from "../../../../SidebarPages/Scheduling/helpers/quickStatistics";
import ScheduleDayNote from "../models/ScheduleDayNote";
const { Panel } = Collapse;

/**
 * The most important component of NewSchedule. This is composited by 2 parts. On the left side there are schedule days.
 * On the right side there are PLIs.
 * @property props.scheduleDays {ScheduleDay[]}
 * @property props.days {Day[]}
 * */
export const WrappedScheduleDays = (props) => {
  const {
    scheduleCrews,
    updateScheduleCrews,
    serviceDefinitionsData,
    fullWeatherArray,
    firstPage_InformativeVariables: {
      scheduleName,
      projectId,
      scheduleAddress,
      typeOfWork,
      scheduleId,
    },
    scheduleNotes,
    setDisptachDays,
    fleetInfo,
    googleDriveFolderIds,
    editMode,
    crewsInfo,
    ableToSave,
    typeOfRemoval,
    notesChanged,
    notesAccessRights,
    setAbleToSave,
    schedule,
    current,
    updateGoogleDriveFolders,
    project,
    unChangedRowData,
    addGoogleDriveFolderToQueue,
    specificRowData,
    dispatchForSchedule,
    isDarkMode,
    updateProgressOfAllSchedule = () => {},
    updateLastProgressDate = () => {},
    thisProjectSchedules,
    newCrewsUpdate,
    updateProgressLogs,
  } = props;

  const { accessToken } = useSelector((state) => state.accessToken);
  const { programFields } = useSelector((state) => state.programFields);
  const driveRequest = driveApi({ accessToken });
  const [helperButtonsRef, ScheduleDaysViewRef] = useMultipleRefs(2);
  const [tourOpen, setTourOpen] = useModalOnce("schedulingTour");
  const theme = "light";
  const [didPostpone, setDidPostpone] = useState(false);
  const [load, setLoad] = useState(false);

  const [preview, setPreview] = useState(false);
  /**
   * @type {ScheduleDay[]}
   * */
  const [{ authenticatedUser }] = useSelector((state) => [state]);
  const [steps, setSteps] = useState([]); //used for tour
  const [scheduleDays, setScheduleDays] = useState(props?.scheduleDays);
  const [notesToAddForScheduleDays, set_notesToAddForScheduleDays] = useState(
    {}
  ); //for each day we can insert only one note each time we work with schedule. When saving these notes in state are added finally in schedule days
  const [toBeScheduled, setToBeScheduled] = useState({}); //pli that have days selected inside them (structure is same with services or toBeScheduled)

  const [visibility, setVisibility] = useState(!editMode);
  const dispatch = useDispatch(); //from redux
  const [isWritable] = useRedux("isWritableScheduling");

  const [videoTutorialLink, setVideoTutorialLink] = useState(null);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const links = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Project Management")
    ?.subCategories[2]?.videos.filter((item, i) => i >= 0 && i <= 4)
    .map((item) => item);

  const onAddDay = (dayToAddIndex, suggestedStartDate, suggestedEndDate) => {
    if (!isWritable && !!editMode) {
      message.error("Enable write mode!");
      return;
    }

    // Comes from SUGGESTION Days
    const scheduleDays_copy = _.cloneDeep(scheduleDays);
    let newScheduleDay;
    if (suggestedStartDate && suggestedEndDate) {
      newScheduleDay = new ScheduleDay(
        dayToAddIndex + 2,
        dayjsNY(suggestedStartDate),
        dayjsNY(suggestedEndDate)
      );
      newScheduleDay.setWeather(fullWeatherArray);
    } else {
      const element = document.getElementById("days-container-scroll");
      if (element) {
        setTimeout(() => {
          element.scroll({
            left: 0,
            top: element.scrollHeight + 200,
            behavior: "smooth",
          });
        }, 0);
      }
      // Comes from + button at the top

      const enabledScheduleDays = scheduleDays_copy
        ?.sort((a, b) => a?.startDate - b?.startDate)
        ?.filter((sch) => sch?.status !== "Postponed");

      const createNewScheduleDay = () => {
        const lastEnabledDay =
          enabledScheduleDays?.[enabledScheduleDays?.length - 1];
        const lastDay = dayjs(lastEnabledDay?.startDate).format("MM/DD/YYYY");

        const addDay = (date = dayjs(), hour = 0) => {
          return dayjs(date).add(hour, "hour");
        };

        return ScheduleDay.NextScheduleDay(
          {
            id: uuidv4(),
            day: lastEnabledDay?.day + 1,
            startDate: addDay(lastDay, 7),
            endDate: addDay(lastDay, 16),
            crews: [],
            fleet: [],
            weather: [],
            image: "",
            color: "",
            status: DayStatus?.Confirmed,
            linkedDays: {
              comesFrom: undefined,
              postponedTo: undefined,
            },
            notes: [],
          },
          fullWeatherArray
        );
      };
      newScheduleDay = createNewScheduleDay();
    }
    scheduleDays_copy.splice(dayToAddIndex + 1, 0, newScheduleDay);
    setAbleToSave(true);
    setStatesAfterDayAddRemoval(scheduleDays_copy);
  };

  const onRemoveDay = (id) => {
    if (!isWritable && !!editMode) {
      message.error("Enable write mode!");
      return;
    }

    if (dayDetail(toBeScheduled, [id])?.[id]?.length > 0) {
      message.error("You can't remove a day that is already scheduled!");
      return;
    }

    const scheduleDays_copy = _.cloneDeep(scheduleDays)?.sort(
      (a, b) => dayjs(a.startDate) - dayjs(b.startDate)
    );

    if (scheduleDays_copy?.length === 1) {
      message.error("You can't remove the only last day of the schedule!");
      return;
    }

    const folderDay = scheduleDays_copy?.find(({ id: dayId }) => dayId === id);
    driveFolderFunction({
      googleDriveFolderIds,
      action: "delete-queue",
      updateGoogleDriveFolders,
      day: dayjs(folderDay?.endDate).format("MM/DD/YYYY"),
      driveRequest,
      addGoogleDriveFolderToQueue,
    });

    const postponedDays = scheduleDays_copy?.filter(
      (el) => el?.status === "Postponed"
    );
    let newDays = [
      ...scheduleDays_copy?.filter(
        (el, i) =>
          el?.id !== id &&
          el?.linkedDays?.postponedTo !== id &&
          el?.status !== "Postponed"
      ),
    ];

    newDays?.forEach((day, index) => {
      day.day = index + 1;
    });

    props.setFirstPageVariables(
      "totalHours",
      getTotalWorkingHours(
        newDays?.filter(
          (a) =>
            a?.status !== "Canceled" &&
            a?.status !== "Skipped" &&
            a?.status !== "Postponed"
        )
      )
    );

    setScheduleDays([...(postponedDays || []), ...newDays]);
  };

  const onRemoveTheShiftedDay = (dayToRemoveId) => {
    if (!isWritable && !!editMode) {
      message.error("Enable write mode!");
      return;
    }
    let scheduleDays_copy = _.cloneDeep(scheduleDays);
    const dayToRemove = scheduleDays_copy?.find(
      (sch) => sch?.id === dayToRemoveId
    );
    dayToRemove.removeComesFromLink(scheduleDays_copy);
    scheduleDays_copy = scheduleDays_copy?.filter(
      (sch) => sch?.id !== dayToRemove?.id
    );
    props.setFirstPageVariables(
      "totalHours",
      getTotalWorkingHours(
        scheduleDays_copy?.filter(
          (a) =>
            a?.status !== "Canceled" &&
            a?.status !== "Postponed" &&
            a?.status !== "Skipped"
        )
      )
    );
    setScheduleDays(scheduleDays_copy);
    setStatesAfterDayAddRemoval(scheduleDays_copy);
  };

  const setStatesAfterDayAddRemoval = (scheduleDays) => {
    setScheduleDays(scheduleDays);

    correctDayNumberInScheduleDays(scheduleDays);

    props.setFirstPageVariables(
      "totalHours",
      getTotalWorkingHours(
        scheduleDays?.filter(
          (a) =>
            a?.status !== "Canceled" &&
            a?.status !== "Postponed" &&
            a?.status !== "Skipped"
        )
      )
    );
  };
  const handleInputChange = (index, event, reference) => {
    if (!isWritable && !!editMode) {
      message.error("Enable write mode!");
      return;
    }
    /**
     * @type {ScheduleDay[]}
     * */
    const inputFieldsCopy = _.cloneDeep(scheduleDays);
    /**
     * @type {ScheduleDay}
     * */
    let scheduleDay = inputFieldsCopy[index];
    try {
      switch (reference) {
        case "startDate": {
          const startDateTime = dayjs(event).tz("America/New_York");
          scheduleDay.startDate = dayjs(startDateTime).tz("America/New_York");
          scheduleDay.setWeather(fullWeatherArray);
          break;
        }
        case "endDate": {
          scheduleDay.endDate = dayjs(event).tz("America/New_York");
          break;
        }
        case "bothDates": {
          scheduleDay.startDate = event[0];
          scheduleDay.endDate = event[1];
        }
        default:
          break;
      }
    } catch (e) {
      console.error(e);
    }

    props.setFirstPageVariables(
      "totalHours",
      getTotalWorkingHours(
        inputFieldsCopy?.filter(
          (a) =>
            a?.status !== "Canceled" &&
            a?.status !== "Postponed" &&
            a?.status !== "Skipped"
        )
      )
    );

    setScheduleDays(
      inputFieldsCopy
        ?.sort?.((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate))
        .forEach((sch, index) => (sch.day = index + 1))
    );
    dispatch(
      ScheduleDaysDispatch({
        scheduleDays: inputFieldsCopy,
        notesToAddForScheduleDays,
      })
    );
    props.handlePreviousNext(inputFieldsCopy, true);
    // setCrewsAvailable(valueCrews);
  };

  /**
   * Comes from DayStatusChangeModal.
   * @param {string} id - id of opened schedule day in modal
   * @param {ScheduleDay[]} modified_scheduleDays - new new status of this schedule day
   * @param {{startDate: Date, endDate: Date, noteToAdd: string}} newScheduleDayData - when we have a postponed day we pick startDate and endDate of the new day that will substitute this day
   * */
  const changeScheduleDayStatus = (
    id = "",
    modified_scheduleDays = [],
    noteToAdd = "",
    dayStatus = ""
  ) => {
    if (!isWritable && !!editMode) {
      message.error("Enable write mode!");
      return;
    }
    let scheduleDayToEdit = modified_scheduleDays?.find(
      (sch) => sch?.id === id
    );
    const newDay = modified_scheduleDays?.find(
      (el) => el?.linkedDays?.comesFrom === id
    );

    let newNote = "";
    if (!noteToAdd) {
      newNote = new ScheduleDayNote(noteToAdd, dayStatus);
      newNote.createdBy = authenticatedUser.authenticatedUser.given_name.concat(
        " ",
        authenticatedUser.authenticatedUser.family_name
      );
      newNote.createdAt = dayjs();

      scheduleDayToEdit.notes.push(newNote);
    }

    if (
      scheduleDayToEdit?.status === "Cancel" ||
      scheduleDayToEdit?.isNotAvailable()
    ) {
      driveFolderFunction({
        googleDriveFolderIds,
        action: "delete-queue",
        updateGoogleDriveFolders,
        day: dayjs(scheduleDayToEdit?.endDate).format("MM/DD/YYYY"),
        driveRequest,
        addGoogleDriveFolderToQueue,
      });
    }

    setScheduleDays(modified_scheduleDays);

    if (scheduleDayToEdit?.status === "Postponed") {
      setToBeScheduled(
        postponeDaysInServices(toBeScheduled, id, newDay, modified_scheduleDays)
      );
    }
    correctDayNumberInScheduleDays(scheduleDays);
    props.setFirstPageVariables(
      "totalHours",
      getTotalWorkingHours(
        modified_scheduleDays?.filter(
          (a) =>
            a?.status !== "Canceled" &&
            a?.status !== "Postponed" &&
            a?.status !== "Skipped"
        )
      )
    );

    props.handlePreviousNext(modified_scheduleDays, true);
  };

  const updateNotesToAddForScheduleDays = (notesToAddForScheduleDays) => {
    set_notesToAddForScheduleDays(notesToAddForScheduleDays);
    dispatch(ScheduleDaysDispatch({ scheduleDays, notesToAddForScheduleDays }));
  };

  /**
   * Comes from PLIPerService
   * @param services {Service[]} - services with days inside that comes from PLIPerService
   * @param estimationId {string}
   * */

  const handlePlis = (services, estimationId) => {
    //handle days usage
    let toBeScheduled_copy = _.cloneDeep(toBeScheduled) || {};
    toBeScheduled_copy[estimationId] = services;
    setToBeScheduled(toBeScheduled_copy);

    props.setAbleToSave(true);
    dispatch(ScheduleServicesDispatch(toBeScheduled_copy)); //send data via redux to quick statistics in top bar
  };

  //save changes or new schedule to database
  const handleSubmit = (e) => {
    //add notes to schedule Days
    for (const [scheduleId, scheduleNote] of Object.entries(
      notesToAddForScheduleDays || {}
    )) {
      scheduleDays
        ?.find((sch) => sch?.id === scheduleId)
        ?.notes?.push(scheduleNote);
    }
    props.save_scheduleDays_and_toBeScheduled({ scheduleDays, toBeScheduled });
    set_notesToAddForScheduleDays({}); //reset
    dispatch(
      ScheduleDaysDispatch({ scheduleDays, notesToAddForScheduleDays: {} })
    );
  };

  const servicesByEstimationEntries = Object.entries(toBeScheduled || {});
  let servicesContent; //what will be displayed in right side of modal
  const dayColors = scheduleDays?.map((d) => d?.color); //just colors for shortcut
  servicesContent = servicesByEstimationEntries?.map(
    ([estimationId, gridData], estimationIndex) => (
      <PLIPerService
        key={estimationId}
        typeOfWorkObject={TypeOfWorkDefinitions?.[typeOfWork?.toUpperCase()]}
        {...{
          updateProgressLogs,
          scheduleName: editMode ? specificRowData?.scheduleName : scheduleName,
          rowData: specificRowData,
          unChangedRowData,
          project,
          estimationIndex,
          visibility,
          typeOfRemoval,
          toBeScheduled,
          setAbleToSave,
          projectId,
          ...serviceDefinitionsData,
          scheduleDays,
          googleDriveFolderIds,
          updateGoogleDriveFolders,
          dayColors,
          typeOfWork: !editMode
            ? typeOfWork
            : props?.specificRowData?.typeOfWork,
          editMode,
          isWritable,
          estimationId,
          driveRequest,
          addGoogleDriveFolderToQueue,
          gridData,
          handlePlis,
          editMode,
          isDarkMode,
          thisProjectSchedules,
          scheduleId,
        }}
      />
    )
  );

  const retriveSortedScheduleDays = (scheduleDays = []) => {
    if (Array.isArray(scheduleDays)) {
      let result = scheduleDays
        ?.filter((el) => el?.status !== "Postponed")
        ?.sort?.((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate));
      let temp = [];
      scheduleDays?.forEach?.((el, i) => {
        if (el?.status === "Postponed") {
          temp.push(el);
        }
      });
      const days = temp?.length > 0 ? [...result, ...temp] : result;
      return days?.sort?.((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate));
    } else {
      return [];
    }
  };

  useEffect(() => {
    const inputFieldsCopy = props?.scheduleDays || [];
    setScheduleDays(inputFieldsCopy);
  }, [props?.scheduleDays]);

  useEffect(() => {
    if (props?.wantsToSave === true) {
      handleSubmit();
      props.documentsLoading();
    }
  }, [props.wantsToSave]);

  useEffect(() => {
    if (!!didPostpone) {
      scheduleDays?.forEach?.((el, index) => {
        if (!!el?.linkedDays?.postponedTo && el?.status !== "Postponed") {
          scheduleDays[index].linkedDays.postponedTo = undefined;
        }
        if (el?.status !== "Postponed" && !!el?.linkedDays?.comesFrom) {
          const findDay = scheduleDays?.find(
            (el) => el?.id === scheduleDays?.[index]?.linkedDays?.comesFrom
          );
          if (!findDay?.linkedDays?.postponedTo) {
            scheduleDays[index].linkedDays.comesFrom = undefined;
          }
        }
        return el;
      });
    }
    setTimeout(() => {
      setDidPostpone(false);
    }, 1000);
  }, [didPostpone]);

  useEffect(() => {
    if (!!editMode) {
      message.destroy();
    }
  }, []);

  // useEffect(() => {
  //   if (current === 1)
  //     setTimeout(() => {
  //       const stepsArr = [
  //         {
  //           title: (
  //             <div style={{ width: 280 }}>
  //               Watch Scheduling Video Tutorials or tap "Next" to continue a
  //               quick tour
  //             </div>
  //           ),
  //           description: (
  //             <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
  //               {links.map((link, i) => (
  //                 <MondayButton
  //                   {...{
  //                     key: i,
  //                     className: "mondayButtonBlue guideButton",
  //                     onClick: () => {
  //                       setShowVideoTutorial(true);
  //                       setTourOpen(false);
  //                       setVideoTutorialLink(link.videoLink);
  //                     },
  //                     style: {
  //                       display: "flex",
  //                       justifyContent: " center",
  //                     },
  //                     Icon: <VideoTutorialIcon />,
  //                   }}
  //                 >
  //                   {link.videoName}
  //                 </MondayButton>
  //               ))}
  //             </div>
  //           ),
  //           className: `custom-tour-${theme}`,
  //           placement: "center",
  //         },
  //         {
  //           title: "Hide/Show Schedule Days",
  //           description:
  //             "Here you can hide/show schedule days to make more space to work with services.",
  //           // cover: (
  //           //   <img
  //           //     alt="tour.png"
  //           //     src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png"
  //           //   />
  //           // ),
  //           target: () =>
  //             helperButtonsRef?.current?.getHelperButtonsRefs?.()?.hideRef
  //               ?.current,

  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Show Preview",
  //           description: "You can see a preview of the schedule here.",
  //           target: () =>
  //             helperButtonsRef?.current?.getHelperButtonsRefs?.()?.previewRef
  //               .current,

  //           className: `custom-tour-${theme}`,
  //         },

  //         {
  //           title: "Calendar Days",
  //           description: "You can see all days in the calendar here.",
  //           target: () =>
  //             helperButtonsRef?.current?.getHelperButtonsRefs?.()?.oldDaysRef
  //               .current,

  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Notes",
  //           description: "You can see all notes here.",
  //           target: () =>
  //             helperButtonsRef?.current?.getHelperButtonsRefs?.()?.notesRef
  //               .current,

  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Settings",
  //           description: "You can see all settings here.",
  //           target: () =>
  //             helperButtonsRef?.current?.getHelperButtonsRefs?.()?.settingsRef
  //               .current,

  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Remove Day",
  //           description: "You can remove a day here.",
  //           target: () =>
  //             helperButtonsRef?.current?.getHelperButtonsRefs?.()?.removeDaysRef
  //               .current,

  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Add Day",
  //           description: "You can add a new day here.",
  //           target: () =>
  //             helperButtonsRef?.current?.getHelperButtonsRefs?.()?.addDaysRef
  //               .current,

  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Days",
  //           description:
  //             "Select a starting day, start and end time for your schedule. View the weather for the day you selected and add notes if needed.",
  //           target: () => document.querySelector("#schedule-day"),
  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Estimation progress of the project",
  //           description:
  //             "View the total progress of the Estimation. You can also click on it to view that Estimation.",
  //           target: () =>
  //             document.querySelector("#est-progress-status-schedule"),
  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Estimation Services",
  //           description:
  //             "Here you can select a service to distribute the schedule days to the Elevation and Pli-s of that service and the progress for each day.",
  //           target: () => document.querySelector("#serviceSchedulingSiderMenu"),
  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Previous Schedule",
  //           description:
  //             "Here you can go to the previous schedule of this project.",
  //           target: () => document.querySelector("#previousScheduleBtn"),
  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Preview Previous Schedule",
  //           description:
  //             "Here you can preview the previous schedule of this project.",
  //           target: () => document.querySelector("#previousSchedulePreview"),
  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Previous Step",
  //           description:
  //             "Here you can go to the previous step of this schedule to view or make other changes.",
  //           target: () => document.querySelector("#previousStepBtn"),
  //           className: `custom-tour-${theme}`,
  //         },
  //         {
  //           title: "Create Schedule",
  //           description:
  //             "Here you can save all the changes you made to this schedule.",
  //           target: () => document.querySelector("#createScheduleBtn"),
  //           className: `custom-tour-${theme}`,
  //         },
  //       ];
  //       !editMode
  //         ? setSteps(stepsArr)
  //         : setSteps(stepsArr?.filter((a) => a.title !== "Previous Step"));
  //     }, 500);
  // }, [current]);

  useEffect(() => {
    setToBeScheduled(
      onlyEstWithScopeFalse(
        props?.toBeScheduled,
        serviceDefinitionsData?.serviceDefinitions
      )
    );
    if (props?.editMode) {
      dispatch(
        ScheduleServicesDispatch(onlyEstWithScopeFalse(props?.toBeScheduled))
      );
    }
  }, [props?.toBeScheduled]);

  // const daysWithProgress =
  //   getDaysWithProgress(scheduleDays, toBeScheduled, typeOfWork) || [];

  // const lastDate = daysWithProgress?.[daysWithProgress?.length - 1]?.startDate;

  const scheduleProgress =
    quickStatistics_Schedule_and_Project({
      project: {},
      toBeScheduled,
      schedules: [],
      typeOfWork,
    })?.scheduleTotalProgressPercentage || 0;

  useEffect(() => {
    if (
      Object.keys(availableEstimations(toBeScheduled || {}))?.length > 0 &&
      !!editMode
    ) {
      updateProgressOfAllSchedule(scheduleProgress);
      // updateLastProgressDate(lastDate);
    }
  }, [scheduleProgress]);

  useEffect(() => {
    if (!Object.keys(toBeScheduled || {})?.length && !editMode) {
      setLoad(true);
    } else {
      setLoad(false);
    }
  }, [toBeScheduled]);

  return (
    <>
      {!!load ? (
        <Spin spinning={load} tip="Loading Estimations..." fullscreen />
      ) : (
        <div
          className="scheduleDaysStep"
          style={{
            flexDirection: visibility ? "row" : "column",
            gap: visibility ? 10 : 0,
          }}
        >
          <div className="helper-buttons-innerDay-form">
            <HelperButtons
              {...{
                setAbleToSave,
                setPreview,
                scheduleAddress,
                scheduleName: editMode
                  ? specificRowData?.scheduleName
                  : scheduleName,
                onAddDay,
                onRemoveDay,
                scheduleDays,
                typeOfWork,
                scheduleId,
                scheduleNotes,
                notesChanged,
                notesAccessRights,
                visibility,
                setVisibility,
                editMode,
                setTourOpen,
                setToBeScheduled,
                toBeScheduled,
                isWritable,
                isDarkMode,
                updateProgressOfAllSchedule,
                scheduleCrews,
                newCrewsUpdate,
              }}
              ref={helperButtonsRef}
            />

            {visibility && (
              <div
                id="days-container-scroll"
                style={{
                  maxHeight: `calc(100% - ${!!editMode ? 20 : 120}px)`,
                }}
              >
                {retriveSortedScheduleDays(scheduleDays)?.map?.(
                  (scheduleDay, dayIndex) => {
                    return (
                      <div className="schedule-day-card">
                        <ScheduleDayView
                          {...{
                            scheduleCrews,
                            updateScheduleCrews,
                            scheduleId: props?.specificRowData?.scheduleId,
                            createdAt:
                              props?.specificRowData?.createdAt ||
                              dayjs().valueOf(),
                            scheduleDay,
                            scheduleDays:
                              retriveSortedScheduleDays(scheduleDays),
                            dayIndex,
                            crewsInfo,
                            toBeScheduled,
                            setAbleToSave,
                            ableToSave,
                            fleetInfo,
                            setScheduleDays,
                            projectId,
                            scheduleAddress,
                            fullWeatherArray,
                            notesToAddForScheduleDays,
                            updateNotesToAddForScheduleDays,
                            changeScheduleDayStatus,
                            handleInputChange,
                            onAddDay,
                            onRemoveDay,
                            onRemoveTheShiftedDay,
                            typeOfWork: !editMode
                              ? typeOfWork
                              : props?.specificRowData?.typeOfWork,
                            editMode,
                            schedule,
                            setToBeScheduled,
                            dispatchForSchedule,
                            unChangedRowData,
                            setDisptachDays,
                            specificRowData,
                            isDarkMode,
                            setDidPostpone,
                            setPreview,
                            thisProjectSchedules,
                            scheduleName,
                            newCrewsUpdate,
                          }}
                          ref={dayIndex === 0 ? ScheduleDaysViewRef : null}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
          {servicesByEstimationEntries?.length > 0 && (
            <div
              className={"services-schedule-container-data"}
              style={{
                maxHeight: `calc(100% - ${
                  !!editMode ? (visibility ? 20 : 40) : visibility ? 80 : 130
                }px)`,
              }}
            >
              {servicesContent}
            </div>
          )}

          {!!preview && (
            <ScheduleDaysPreview
              {...{
                scheduleDays: scheduleDays?.filter(
                  (a) =>
                    a?.status !== "Postponed" &&
                    a?.status !== "Canceled" &&
                    a?.status !== "Skipped"
                ),
                services: toBeScheduled,
                typeOfWork,
                scheduleAddress,
                setPreview,
                preview,
                isDarkMode,
                scheduleId,
              }}
            />
          )}

          {steps?.length > 0 && (
            <Tour
              open={current === 1 && tourOpen}
              onClose={() => setTourOpen(false)}
              steps={steps}
              // mask={false}
              // colorPrimary="#3387C7"
              // type="primary"
            />
          )}
          {showVideoTutorial && (
            <PlayVideoTutorial
              {...{
                visible: showVideoTutorial,
                setVisible: setShowVideoTutorial,
                url: videoTutorialLink,
                title: "Scheduling Tutorial",
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

/**
 * @typedef {string} ALERT_SEVERITY
 * */

/**
 * enum ALERT_SEVERITY
 * */
export const ALERT_SEVERITY = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
};

// import React from "react";
// import "./ScheduleDays.scss";
// export const WrappedScheduleDays = (props) => {
//   return (
//     <div className="allPageContent">
//       <div className="daysContent">
//         <div className="headerButtons">asfas</div>
//         <div>sdgsdgsd</div>
//       </div>
//       <div>eruyeruerure</div>
//     </div>
//   );
// };

// import React from "react";
// import "./ScheduleDays.scss";
// export const WrappedScheduleDays = () => {
//   return (
//     <div className="allPageContent">
//       <div className="daysContent">
//         <div>asfasf</div>
//         <div>qweqweqw</div>
//       </div>
//       <div className="scheduleServices">cxbxcb</div>
//     </div>
//   );
// };
