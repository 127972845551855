const colorsList = [
  // "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
];

export const quillComponents = {
  modules: {
    toolbar: [
      [
        { size: [false, "large"] },
        "bold",
        "italic",
        "underline",
        { color: colorsList },
        { background: colorsList },
        "clean",
      ],
    ],
  },
  formats: ["size", "bold", "italic", "underline", "color", "background"],
};

export const defaultEmptyElevation = {
  sidewalkShed: {
    elevationLabel: "Along ",
    items: [
      {
        id: 1,
        approved: true,
        addons: [
          {
            id: 1,
            approved: true,
            span: [
              {
                id: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  hoist: {
    elevationLabel: "Along ",
    stops_excluding: [],
    stops_including: ["Ground Stop", "Roof Stop"],
    type: "dual",
    location: "front",
    access: "direct",
    lift_height: 0,
    building_height: 0,
    from: 0,
    to: 0,
    length: `12'9`,
    height: `7'6`,
    width: `4'11`,
    undergroundHeight: 0,
    items: [],
  },
  default: {
    elevationLabel: "Along ",
    items: [
      {
        id: 1,
        approved: true,
      },
    ],
  },
  empty: {
    elevationLabel: "Along ",
    items: [],
  },
};

export const defaultHoistServiceStruct = {
  serviceOptions: [
    [
      {
        elevationLabel: "Along ",
        item: {
          access: "direct",
          firstFloorHeight: 0,
          liftHeight: {
            height: 0,
            additionalMast: 0,
            fromFloor: 0,
            fromNote: "",
            toFloor: 0,
            toNote: "",
          },
          location: "front",
          ramp: {
            count: 0,
            depth: 0,
            items: [
              {
                id: 1,
              },
            ],
            length: 0,
            withShoring: false,
            shoring: {
              items: [
                {
                  id: 1,
                },
              ],
              note: "",
            },
          },
          shoring: [],
          stops: {
            count: 0,
            excluding: [],
            including: ["Ground Stop", "Roof Stop"],
          },
          type: "dual",
        },
      },
    ],
  ],
};

export let dataThreshold = {
  1: 6,
  2: 4,
  4: 4,
  5: 4,
  6: 5,
  7: 4,
  8: 5,
  9: 3,
  10: 5,
  11: 4,
  12: 5,
  13: 5,
  14: 4,
  15: 5,
  16: 3,
  17: 4,
  18: 4,
  19: 4,
  20: 5,
};
