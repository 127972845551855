import { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { InputComponent } from "src/components/SidebarPages/Fleet/components";

const JobTypeEditor = forwardRef((props, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedValue, setSelectedValue] = useState(props?.value);

  const { value, context, stopEditing } = props;

  useEffect(() => {
    if (!context.isWritable) {
      stopEditing();
    }
  }, [context.isWritable]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return selectedValue;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    [selectedValue]
  );

  return (
    <InputComponent
      {...{
        type: "select",
        className: "job-type-editor-field",
        customOptions: [
          "Home",
          "Job",
          "Vendor",
          "Yard",
          "Gas Station",
          "Other Location",
        ].map((e) => ({
          value: e,
        })),
        dropdownClassName: isDarkMode ? "darkDropDown" : "",
        onSelect(e) {
          setSelectedValue(e);
          setTimeout(() => {
            stopEditing();
          }, 0);
        },
        placeholder: "Route type...",
        defaultValue: value,
        allowClear: true,
        required: false,
        noFormItem: true,
        defaultOpen: true,
        style: { width: "100%" },
        label: "",
        noFormItem: true,
        autoFocus: true,
        onClear() {
          setSelectedValue("");
        },
        onBlur() {
          stopEditing();
        },
      }}
    />
  );
});

export default JobTypeEditor;
