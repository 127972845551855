import { useState } from "react";
import { hexToRgba } from "../../../utils";
import { XIcon } from "../Communication/assets";
import { Modal } from "antd";
import "./DocShownIn.scss";

const DocShownIn = ({ value, title = "" }) => {
  const [visible, setVisible] = useState(false);
  const disabledColor = hexToRgba("#1a3a6a", 0.4);
  const disabled = !value?.length;

  return (
    <>
      <div
        className="servicesInspectedCell"
        style={
          disabled
            ? {
                backgroundColor: disabledColor,
                borderColor: disabledColor,
                cursor: "not-allowed",
              }
            : {}
        }
        onClick={() => (disabled ? () => {} : setVisible(true))}
      >
        {value?.length}
      </div>
      <Modal
        className={"docShownInModal"}
        title={title}
        open={visible}
        centered={true}
        footer={null}
        closeIcon={<XIcon />}
        onCancel={() => {
          setVisible(false);
        }}
      >
        {typeof value !== "string"
          ? value?.map((item, i) => (
              <div key={i} className="docShownName">
                {item}
              </div>
            ))
          : ""}
      </Modal>
    </>
  );
};

export default DocShownIn;
