import { calculateTotalForRowData } from "./calculateTotalForRowData";

export const calculateTotals = (data, visible) => {
  let CHARGED_TOTAL = 0;
  let CREDIT_TOTAL = 0;

  if (visible?.categoryFrom === "Invoice") {
    CREDIT_TOTAL = data.invoiceItems.reduce(
      (acc, item) => acc + (item.creditedAmount || 0),
      0
    );
  } else {
    CREDIT_TOTAL =
      data?.charges
        ?.filter(({ chargeType }) => chargeType === "Credit Memo")
        ?.reduce((p, { chargeAmount }) => p + chargeAmount, 0) || 0;
    CHARGED_TOTAL =
      data?.charges?.reduce((p, { chargeAmount }) => p + chargeAmount, 0) || 0;
  }

  const paidTotal =
    visible?.categoryFrom === "Invoice"
      ? data?.totalInvoiceAmount - (data?.amountDue + CREDIT_TOTAL)
      : 0;

  return {
    chargedTotal: CHARGED_TOTAL,
    creditTotal: CREDIT_TOTAL,
    paidTotal,
  };
};

export const calculatePercentage = (numerator, denominator) => {
  return parseFloat((numerator / denominator) * 100).toFixed(2) || 0;
};

export const determineTitle = (percentage) => {
  if (percentage >= 100) {
    return "Fully Charged";
  }
  if (percentage > 0) {
    return "Practically Charged";
  }
  return "Not Charged";
};

export const calculateStatusForEstimationAndRental = (value, data, visible) => {
  if (!value && !data) {
    return { title: "Not Charged", perc: "0.00 %" };
  }

  const visibleCategory = visible?.categoryFrom;

  const isRental = visibleCategory === "Rental";
  const isRequisition = visibleCategory === "Requisition";
  const isScheduleOfValue = visibleCategory === "Requisition";
  const isCredit = visibleCategory === "Credit Memo";
  const isInvoice = visibleCategory === "Invoice";

  const isFullyChargedFromRental = isRental && data?.chargedFromReq;
  const isFullyChargedFromRequisition = isRequisition && data?.charged;

  if (isFullyChargedFromRental || isFullyChargedFromRequisition) {
    return { title: "Fully Charged", charged: "100%" };
  }

  const { chargedTotal, creditTotal, paidTotal } = calculateTotals(
    data,
    visible
  );

  const totalAmount = calculateTotalForRowData(value, data, visibleCategory);

  const percentage = calculatePercentage(
    chargedTotal + creditTotal,
    totalAmount
  );

  const title = determineTitle(percentage);

  return {
    title,
    perc: `${percentage} %`,
    paid: `${calculatePercentage(paidTotal, totalAmount)}%`,
    charged: `${calculatePercentage(chargedTotal - creditTotal, totalAmount)}%`,
    credited: `${calculatePercentage(creditTotal, totalAmount)}%`,
  };
};
