import React, { useState, useMemo, useRef } from "react";
import { Button, Card, List, message, Select, Spin, Steps, theme } from "antd";
import { MondayButton, Stepper } from "../../../commonComponents";
import { ProjectIcon } from "../../../SidebarPages/Scheduling/GanttChart/assets/OtherIcons";
import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";
import { useRedux } from "../../../SidebarPages/hooks";
import { fetchData } from "../../../SidebarPages/Fleet/utils";
import moment from "moment";
import { fetchSearchRecords, filterTables } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import "./ProposalStepper.scss";
import debounce from "lodash/debounce";
import DebounceSelect from "../../components/DebounceSelect/DebounceSelect";
import { proposalPostHandler } from "../../../SidebarPages/Estimations/components/ProposalBuilder/utils/requests";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ProjectsInfo from "./infoViews/ProjectsInfo/ProjectsInfo";
const INITIAL_STEPPER_DATA = {
  sourceType: undefined,
  project_or_opp: undefined,
  selectedId: undefined,
  selectLabel: undefined,
  applicableEstimations: undefined,
  selectedEstimation: undefined,
  takeoff: undefined,
  versionServices: undefined,
};
function ProposalStepper() {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [stepperData, setStepperData] = useState(INITIAL_STEPPER_DATA);
  const [options, setOptions] = useState([]);
  const [typeSource, setTypeSource] = useState(null);
  const [proposalFeedData, setProposalFeedData] = useRedux(
    "proposalFeedData",
    {
      projects: [],
      opportunities: [],
      loading: false,
    },
    false
  );
  const navigate = useNavigate();

  const onSelectDataSrc = async (value, option) => {
    setStepperData((prev) => ({
      ...prev,
      selectedId: value?.key,
      selectLabel: value?.label,
    }));
    await filterTables(
      "estimations",
      `${typeSource !== "Opportunity" ? "projectId" : "opportunityId"}`,
      value.key
    ).then((estimations) => {
      setStepperData((prev) => ({
        ...prev,
        applicableEstimations: estimations || [],
      }));
    });
    next();
  };

  const onSelectEstimation = (selectedEstimation) => {
    setStepperData((prev) => ({
      ...prev,
      selectedEstimation: selectedEstimation?.estimationId,
      versionServices: selectedEstimation?.versionServices,
      estimationTakeOff: selectedEstimation?.estimationTakeOff,
    }));
    next();
  };

  // Called after leaving the view

  const onHousekeeping = () => {
    setStepperData(INITIAL_STEPPER_DATA);
    setProposalFeedData({
      projects: [],
      opportunities: [],
      loading: false,
    });
    setCurrent(0);
  };

  const createProposalTakeOff = async (item) => {
    let PROPOSAL_DATA = {
      estimationId: item?.estimation?.estimationId,
      takeOffVersion: item?.takeOffItem?.id,
      proposalStatus: "Draft",
      projectId: item?.estimation?.projectId ?? item?.estimation?.opportunityId,
      savedContent: "",
      proposalId: "4343435353s",
      otherDetails: {
        title: `${item?.takeOffItem?.versionName || ""}`,
        jobSiteAddress: `${item?.estimation?.jobSiteAddress || ""}`,
        createdAt: new Date(),
        createdBy: userConfiguration?.nameOfUser || "Unknown",
      },
      // otherDetails: {},
    };
    await proposalPostHandler({ proposalData: PROPOSAL_DATA }).then(
      async (res) => {
        Swal.fire({
          title: "Success!",
          text: "Proposal Created Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
        console.log("res", res);
        navigate(
          `/new-proposal-builder?tab=Take-Off&takeOffId=${item?.key}&proposalId=${res?.proposalId}`,
          {
            state: {
              takeOffId: item?.key,
              takeOff: item,
              estimation: item?.estimation,
              proposalId: res?.proposalId,
            },
          }
        );
      }
    );
  };

  console.log("options", options);

  const steps = [
    {
      title: stepperData?.sourceType || "Choose Source",
      content: (
        <div className="choose_source_wrapper">
          <div className="left-button">
            <MondayButton
              Icon={<ProjectIcon />}
              onClick={() => onProgressControl("sourceType", "Project")}
            >
              PROJECT
            </MondayButton>
          </div>
          <div className="right-button">
            <MondayButton
              className="mondayButtonYellow right-button"
              // disabled
              Icon={<ProjectIcon />}
              onClick={() => onProgressControl("sourceType", "Opportunity")}
            >
              OPPORTUNITY
            </MondayButton>
          </div>
        </div>
      ),
    },
    {
      title: stepperData?.selectLabel || "Select Source",
      content: (
        <div>
          <ProjectsInfo
            {...{
              onSelectDataSrc,
              typeView: typeSource,
            }}
          />
        </div>
      ),
    },
    {
      title: !!stepperData?.selectedEstimation
        ? "Pick Take Off"
        : "Pick Estimation",
      content: (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              padding: "20px",
              overflowY: "scroll",
              justifyContent: "center",
              height: "500px",
            }}
          >
            <List
              // grid={{ gutter: 16, column: 2 }}
              dataSource={stepperData?.applicableEstimations
                ?.map((el) => {
                  console.log("el", el);
                  return {
                    title: `Estimation #${el?.estimationNumber || 0}`,
                    status: el?.estSTATUS || "N/A",
                    createdAt: el?.createdAt,
                    createdBy: el?.createdBy || "N/A",
                    key: el?.estimationId,
                    isChangeOrder: el?.isChangeOrder,
                    value: el?.estimationId,
                    versionServices: el?.versionServices,
                    estimationTakeOff: el,
                  };
                })
                ?.sort((a, b) => b?.createdAt - a?.createdAt)}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    style={{
                      cursor: "pointer",
                      background:
                        item?.status === "Approved" && !item?.isChangeOrder
                          ? "#00A464"
                          : "#E9C466",
                      color: "white",
                    }}
                    onClick={() => onSelectEstimation(item)}
                    title={
                      <span style={{ color: "#fff" }}>
                        {item.title} ({item?.status}){" "}
                        {item?.isChangeOrder && "[CO]"}
                      </span>
                    }
                  >
                    <h4>
                      Created By: <strong> {item.createdBy}</strong>
                    </h4>
                    <p>
                      Created on:
                      {` ${moment(item?.createdAt).format("MM/DD/YYYY HH:mm")}`}
                    </p>
                  </Card>
                </List.Item>
              )}
            />
          </div>
        </>
      ),
    },
    {
      title: "Pick Take Off",
      content: (
        <div
          style={{
            display: "flex",
            padding: "20px",
            overflowY: "scroll",
            justifyContent: "center",
          }}
        >
          <List
            // grid={{ gutter: 16, column: 2 }}
            dataSource={Object.keys(stepperData?.versionServices ?? {})
              ?.map((el) => {
                let takeOff = stepperData?.versionServices;
                console.log("dataSRC", stepperData);
                return {
                  title: `${takeOff[el]?.versionName || ""} - ${
                    takeOff[el]?.status || ""
                  }`,
                  status: takeOff[el]?.status,
                  createdAt: takeOff[el]?.createdAt,
                  createdBy: takeOff[el]?.createdBy?.name,
                  key: el,
                  takeOffItem: takeOff[el],
                  estimation: stepperData?.estimationTakeOff,
                };
              })
              ?.sort((a, b) => {
                return a.createdAt - b.createdAt;
              })}
            renderItem={(item) => (
              <List.Item>
                <Card
                  style={{
                    cursor: "pointer",
                    background:
                      item?.status === "Approved" ? "#00A464" : "#E9C466",
                    color: "white",
                  }}
                  onClick={
                    () => createProposalTakeOff(item)
                    // navigate(
                    //   `/new-proposal-builder?tab=Take-Off&takeOffId=${item?.key}&proposalId=`,
                    //   {
                    //     state: {
                    //       takeOffId: item?.key,
                    //       takeOff: item,
                    //       estimation: item?.estimation,
                    //     },
                    //   }
                    // )
                  }
                  title={<span style={{ color: "#fff" }}>{item.title}</span>}
                >
                  <h4>
                    Created By: <strong> {item.createdBy}</strong>
                  </h4>
                  <p>
                    Created on:
                    {` ${moment(item?.createdAt).format("MM/DD/YYYY HH:mm")}`}
                  </p>
                </Card>
              </List.Item>
            )}
          />
        </div>
      ),
    },
  ];
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    // width: "960px",
    minWidth: "1400px",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const onProgressControl = async (key, value) => {
    setTypeSource(value);
    setStepperData((prev) => ({
      ...prev,
      [key]: value,
    }));
    next();
    if (key === "sourceType") {
      setProposalFeedData({
        ...(proposalFeedData || {}),
        loading: true,
      });
      if (value === "Project") {
        await fetchData("projects").then((allProjects) => {
          let temp = {
            ...(proposalFeedData || {}),
            projects: allProjects?.projects || [],
          };
          console.log("temp", temp, allProjects);
          setProposalFeedData({
            ...(proposalFeedData || {}),
            projects: allProjects?.projects || [],
          });
          setStepperData((prev) => ({
            ...(prev || {}),

            project_or_opp: allProjects?.projects?.map((a) => ({
              key: a?.projectId,
              label: a?.projectName,
              value: a?.projectId,
            })),
          }));
        });
      } else if (value === "Opportunity") {
        //TODO: fetch all opportunities AND set stepper data
        await fetchData("opportunities", "opportunities", "opportunityId").then(
          (opportunities) => {
            setProposalFeedData({
              ...(proposalFeedData || {}),
              opportunities,
            });
          }
        );
      }
    }
    setProposalFeedData({
      ...(proposalFeedData || {}),
      loading: false,
    });
  };

  console.log("proposalFeedData", proposalFeedData);
  return (
    <>
      <div className="antCustomStepper">
        <Stepper
          current={current}
          items={items}
          stepRenderer={false}
          type="navigation"
          componentContainerClassName="proposalBuilderWrapper"
        />
      </div>
      <div className="antStepperHolder" style={contentStyle}>
        {steps[current].content}
      </div>
      <div style={{ marginTop: 24 }}>
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}{" "}
        {current > 0 && current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
      </div>
    </>
  );
}

export default ProposalStepper;
