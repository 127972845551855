import { formatCurrency } from "../../../../utils";
import { getSums, groupBy } from "../../calculatingFunctions";

export const charges = ({ tables, programFields }) => {
  const chargeStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Charge.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    categoryFrom: () => ({
      content: chargeStatus?.statusName?.map((status, idx) => ({
        key: idx,
        title: status,
        taskNumber: tables?.charges?.length,
        taskDone: tables?.charges?.filter((a) => a?.categoryFrom === status)
          ?.length,
        color: chargeStatus?.statusColor[idx],
        backgroundColor: "#B2DD9B",
        contentHandler: ` ${status} Charge`,
        contentsHandler: ` ${status} Charges`,
        custom:
          tables?.charges &&
          groupBy(
            [tables?.charges?.filter((a) => a?.categoryFrom === status)],
            "projectAddress"
          )?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Charge Amount With Tax": formatCurrency(
                    getSums({
                      data: [
                        tables?.charges?.filter(
                          (a) => a?.categoryFrom === status
                        ),
                      ],
                      key: "chargeAmount",
                    })[index] || 0
                  ),
                  "Total Tax": formatCurrency(
                    getSums({
                      data: [
                        tables?.charges?.filter(
                          (a) => a?.categoryFrom === status
                        ),
                      ],
                      key: "taxAmount",
                    })[index] || 0
                  ),
                  "Charge Amount Without Tax": formatCurrency(
                    (getSums({
                      data: [
                        tables?.charges?.filter(
                          (a) => a?.categoryFrom === status
                        ),
                      ],
                      key: "chargeAmount",
                    })[index] || 0) -
                      (getSums({
                        data: [
                          tables?.charges?.filter(
                            (a) => a?.categoryFrom === status
                          ),
                        ],
                        key: "taxAmount",
                      })[index] || 0) || 0
                  ),
                },
              },
              ...Object?.entries(el).map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Charge Amount With Tax": formatCurrency(
                      getSums({
                        data: value,
                        key: "chargeAmount",
                      }) || 0
                    ),
                    "Total Tax": formatCurrency(
                      getSums({
                        data: value,
                        key: "taxAmount",
                      }) || 0
                    ),
                    "Charge Amount Without Tax": formatCurrency(
                      getSums({
                        data: value,
                        key: "chargeAmount",
                      }) -
                        getSums({
                          data: value,
                          key: "taxAmount",
                        })
                    ),
                  },
                };
              }),
            ];
          }),
      })),
    }),
  };
};
