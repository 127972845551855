import { API } from "aws-amplify";

export const getUnitData = async (fetchedData) => {
  try {
    const result = await API.get(
      "proposedTypeOfWork",
      "/proposedTypeOfWork",
      {}
    );

    const updatedData = [];

    fetchedData.forEach((item) => {
      const matchingData = result.find(
        ({ workId }) => workId === item.serviceId
      );

      if (matchingData) {
        updatedData.push({
          userId: matchingData ? matchingData.workName : item.serviceId,
          serviceId: matchingData ? matchingData.workName : item.serviceId,
        });
      }
    });

    return updatedData;
  } catch (error) {
    console.error("Error fetching proposedTypeOfWork:", error);
    return [];
  }
};
