import { useMemo } from "react";

const getRecordDetails = ({ recordID, proppedRecordId }) => {
  return useMemo(() => {
    const pathSegments = window.location.pathname.split("/");
    const isDeviceManagement = pathSegments[2] === "deviceManagement";
    const isInspections = pathSegments[1] === "inspections";

    return {
      categoryType: isDeviceManagement ? pathSegments[2] : pathSegments[1],

      recordId:
        recordID || !proppedRecordId
          ? isInspections
            ? window.location.href.split("?id=")[1]?.split("&&")[0] || ""
            : pathSegments[2]
          : proppedRecordId,
    };
  }, [recordID, proppedRecordId]);
};

export default getRecordDetails;
