/**
 * Handles missing records by fetching data from the API and updating the provided arrays.
 *
 * @param {Object} rowObject - The row object containing scheduleId and projectId.
 * @param {string} rowObject.scheduleId - The schedule ID.
 * @param {string} rowObject.projectId - The project ID.
 * @param {Array} projects - The array of projects.
 * @param {Array} scheduling - The array of scheduling.
 * @returns {Promise<void>} - A promise that resolves when the missing record is handled.
 */
export const handleMissingRecord = async ({
  rowObject = {
    scheduleId: "",
    projectId: "",
  },
  projects = [],
  scheduling = [],
}) => {
  if (
    rowObject?.scheduleId &&
    !scheduling?.some((el) => el?.scheduleId === rowObject?.scheduleId)
  ) {
    await API.get("scheduling", `/scheduling/${rowObject?.scheduleId}`, {
      queryStringParameters: {
        keysToInclude: JSON.stringify([
          "scheduleId",
          "scheduleAddress",
          "scheduleName",
          "scheduleDays",
          "createdAt",
          "teamsConfiguration",
        ]),
      },
    })
      .then((res) => {
        scheduling.push(res);
      })
      .catch((err) => {
        console.error("Error getting missing record: ", err);
      });
  } else if (
    rowObject?.projectId &&
    !projects?.some((el) => el?.projectId === rowObject?.projectId)
  ) {
    await API.get("projects", `/projects/${rowObject?.projectId}`, {
      queryStringParameters: {
        keysToInclude: JSON.stringify([
          "projectId",
          "projectName",
          "accountId",
          "accountName",
          "createdAt",
          "teamsConfiguration",
        ]),
      },
    })
      .then((res) => {
        projects.push(res);
      })
      .catch((err) => {
        console.error("Error getting missing record: ", err);
      });
  }
};
