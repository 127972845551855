import {
  CircleF,
  DirectionsRenderer,
  OverlayView,
} from "@react-google-maps/api";
import Marker from "../../../MapMarker/Marker";
import { useState, useEffect } from "react";

const OverlayViewSection = ({
  radius,
  employeePosition,
  directions,
  places,
}) => {
  const [stateDirections, setStateDirections] = useState();
  useEffect(() => {
    if (!!directions) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(directions, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setStateDirections({ directions: result });
        } else {
          console.log("error getting directions: ", result);
        }
      });
    }
  }, [directions]);

  return (
    <OverlayView
      position={employeePosition ? employeePosition : places?.[0]?.coordinates}
      mapPaneName="markerLayer"
    >
      <>
        {employeePosition && (
          <Marker position={employeePosition} color={"pink"} />
        )}

        {radius && (
          <CircleF
            visible={true}
            center={places?.[0]?.coordinates}
            defaultCenter={places?.[0]?.coordinates}
            radius={parseFloat(radius) / 3.281}
            options={{
              fillColor: "#F6CB51",
              fillOpacity: 0.3,
              strokeWeight: 2,
              strokeColor: "#F6CB51",
              clickable: false,
            }}
          />
        )}
        {!!directions && (
          <DirectionsRenderer
            directions={stateDirections?.directions}
            options={{
              suppressMarkers: true,
            }}
          />
        )}
      </>
    </OverlayView>
  );
};

export default OverlayViewSection;
