import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { message, Modal, Tooltip } from "antd";
import { useLoadScript } from "@react-google-maps/api";

import {
  RouteType,
  DispatchType,
  StoreType,
  VendorType,
  DriverType,
  FleetType,
  DispatchMatchesType,
} from "../../../../FleetMaintenanceView/types";
import { fetchData } from "../../../utils";
import { fetchAllData } from "../../../../../../utils";
import { LIVE_LIBRARIES } from "../../../fleetsLive/data";
import { fetchSearchRecords, filterTables } from "src/utils";
import { getFence, isYard } from "../../../fleetsLive/utils";
import { ProjectMatchContext, MATCHES_FIELD_NAME } from "./data";
import { useDispatchProgramFields } from "../../../../../../hooks";
import { GOOGLE_API_KEY } from "src/helpers/constants/googleScopes";
import {
  MatchGridView,
  ModalFooter,
  ProjectMatchController,
  MatchProjectDetails,
} from "./components";
import { MondayButton } from "src/components/commonComponents";
import { GoTasks } from "src/icons";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "./DispatchProjectMatchModal.scss";

const viewProviders = {
  ALL: MatchGridView,
  PROJECT: MatchProjectDetails,
};

/**
 * @typedef {lat: number, lng: number} LatLng
 */

/**
 * @template [T=any]
 *
 * @typedef MatchDataItem
 * @property {string} id
 * @property {string} name
 * @property {string} differentiatorName - For records with the same name, we want a way to differentiate between those
 * @property {string} differentiatorId
 * @property {string} address
 * @property {LatLng} [position]
 * @property {boolean} [forceMatch=false]
 * @property {GeoFenceInfo[]} [geoFenceInfo]
 * @property {boolean} [isNameSameAsAddress=true]
 * @property {T} originalItem
 */

/**
 * @typedef MatchData
 * @property {MatchDataItem} item
 * @property {any[]|null} subitems - If we don't have subitems, then we need to fetch them
 */

/**
 * @typedef FilteredProjectsType
 * @property {string} address
 * @property {"Off Project"|"Off Schedule"} type
 */

/**
 * @typedef OffProjectData
 * @property {string} dispatchDate
 * @property {string} dropOff
 * @property {string} pickUp
 * @property {LatLng} dropOffCoordinates
 * @property {LatLng} pickUpCoordinates
 * @property {string} activityId
 * @property {string} dispatchId
 * @property {number} routeIndex
 * @property {string} driverName
 * @property {string} driverId
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string} departAt
 * @property {string} arriveBy
 * @property {"Loaded"|"Empty"} cargo
 * @property {boolean} isAudited
 */

/**
 * @typedef {OffProjectData & {projectId: string}} OffScheduleData
 */

/**
 * @typedef ViewFilters
 * @property {string|null} fleetId
 * @property {string|null} driverId
 * @property {string|null} date
 * @property {"Off Project"|"Off Schedule"} records
 * @property {"USER"|"AUTOMATIC"} dateChangeReason
 */

/**
 * @param {Object} props
 * @param {boolean} props.open
 * @param {() => any} props.onCancel
 * @param {DispatchType[]} props.dispatches
 * @param {(dispatches: DispatchType[]) => any} props.updateDispatchesHandler
 * @return {JSX.Element}
 */
function DispatchProjectMatchModal({
  open,
  onCancel,
  dispatches = [],
  updateDispatchesHandler = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector(
    /** @param {StoreType} state */
    (state) => state.programFields
  );

  //#region STATES
  const [vehicles, setVehicles] = useState(/** @type {FleetType[]} */ ([]));
  const [drivers, setDrivers] = useState(/** @type {DriverType[]} */ ([]));
  const [filteredAddresses, setFilteredAddresses] = useState(
    /** @type {FilteredProjectsType[]} */ ([])
  );
  const [selectedViewData, setSelectedViewData] = useState({
    ids: null,
    address: null,
  });
  const [matchedData, setMatchedData] = useState(
    /** @type {Record<string, Record<string, MatchData>>} */ ({})
  );
  const [selectedId, setSelectedId] = useState(
    /** @type {string|null} */ (null)
  );
  const [lastSelectedIds, setLastSelectedIds] = useState(
    /** @type {Record<string, string>} */ ({})
  );
  const [viewType, setViewType] = useState(
    /** @type {"PROJECT"|"ALL"} */ ("ALL")
  );
  const [offScheduleData, setOffScheduleData] = useState(
    /** @type {Record<string, OffScheduleData[]>} */ ({})
  );
  const [offProjectData, setOffProjectData] = useState(
    /** @type {Record<string, OffProjectData[]>} */ ({})
  );
  const [filters, setFilters] = useState(
    /** @type {ViewFilters} */ ({
      date: null,
      driverId: null,
      fleetId: null,
      dateChangeReason: "AUTOMATIC",
      records: "Off Project",
    })
  );

  //#region HOOKS
  const mainGridRef = useRef(null);
  const footerRef = useRef(null);
  const mainViewRef = useRef(null);

  const { isLoaded: mapLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: LIVE_LIBRARIES,
  });

  /** @type {Record<string, DispatchType>} */
  const dispatchRecords = useMemo(() => {
    return dispatches.reduce(
      (acc, val) => ({ ...acc, [val.dispatchId]: val }),
      {}
    );
  }, [dispatches]);

  /** @type {VendorType[]} */
  const vendors = useMemo(() => {
    return (
      programFields.find(({ fieldName }) => fieldName === "Vendors")
        ?.fieldOptions || []
    );
  }, [programFields]);

  /** @type {DispatchMatchesType} */
  const dispatchMatches = useMemo(() => {
    //#region DISPATCHES MATCHES
    return programFields.find(
      ({ fieldName }) => fieldName === MATCHES_FIELD_NAME
    );
  }, [programFields]);

  const { dispatchFields } = useDispatchProgramFields(
    dispatchMatches?.fieldId,
    dispatchMatches?.fieldName
  );

  useEffect(() => {
    //#region PROCESS DATA EFFECT

    /** @type {Record<string, OffProjectData[]>} */
    const tmpOffProject = {};
    /** @type {Record<string, OffScheduleData[]>} */
    const tmpOffSchedule = {};

    /** Contains all of the addresses that are
     * checked whether they are vendors as to not repeat the loop */
    const vendorsSet = new Set();
    const addressSet = new Set();

    /**
     * @param {RouteType} route
     * @param {DispatchType} dispatch
     * @param {number} routeIndex
     * @param {string} [projectId]
     * @return {OffProjectData | OffScheduleData}
     */
    function mapRoute(route, dispatch, routeIndex, projectId) {
      return {
        routeIndex,
        activityId: route["activityId"],
        arriveBy: route["actualArrive"] || route["arriveBy"],
        dispatchId: dispatch["dispatchId"],
        dispatchDate: dispatch["dispatchDate"],
        driverId: route["driverId"],
        driverName: route["driverName"],
        dropOff: route["dropOffLocation"],
        dropOffCoordinates: route["dropOffCoordinates"],
        fleetId: dispatch["fleetId"],
        fleetName: dispatch["fleetName"],
        pickUp: route["pickUpLocation"],
        pickUpCoordinates: route["pickUpCoordinates"],
        cargo: route["cargo"],
        departAt: route["actualDepart"] || route["departAt"],
        isAudited: !!route["actualArrive"],
        ...(projectId ? { projectId } : {}),
      };
    }

    for (const dispatch of dispatches) {
      for (let i = 0; i < dispatch.routes.length; i++) {
        const route = dispatch.routes[i];
        if (isYard(route.dropOffLocation)) {
          continue;
        }

        if (!route["projectId"] || !route["scheduleId"] || !route["vendorId"]) {
          let isVendorAddress = false;
          if (!addressSet.has(route["dropOffLocation"])) {
            if (route?.vendorId) {
              isVendorAddress = true;
            } else {
              if (!vendorsSet.has(route["dropOffLocation"])) {
                for (const vendor of vendors) {
                  if (isVendorAddress) {
                    break;
                  }

                  if (route["dropOffLocation"] === vendor.vendorAddress) {
                    isVendorAddress = true;
                  } else if (
                    route?.["dropOffCoordinates"]?.["lat"] ===
                      vendor.addressPosition?.["lat"] &&
                    route?.["dropOffCoordinates"]?.["lng"] ===
                      vendor.addressPosition?.["lng"]
                  ) {
                    isVendorAddress = true;
                  } else if (
                    getFence(route["dropOffLocation"]) ===
                    getFence(vendor.vendorAddress)
                  ) {
                    isVendorAddress = true;
                  }
                }
              } else {
                isVendorAddress = true;
              }
            }
          }

          if (!isVendorAddress) {
            if (route["projectId"]) {
              tmpOffSchedule[route["dropOffLocation"]] = [
                ...(tmpOffSchedule[route["dropOffLocation"]] || []),
                mapRoute(route, dispatch, i, route["projectId"]),
              ];
            } else if (!route["scheduleId"]) {
              tmpOffProject[route["dropOffLocation"]] = [
                ...(tmpOffProject[route["dropOffLocation"]] || []),
                mapRoute(route, dispatch, i),
              ];
            }

            addressSet.add(route["dropOffLocation"]);
          } else {
            vendorsSet.add(route["dropOffLocation"]);
          }
        }
      }
    }

    setOffProjectData(tmpOffProject);
    setOffScheduleData(tmpOffSchedule);
  }, [dispatches, vendors]);

  useEffect(() => {
    //#region INIT DATA
    message.loading({
      content: "Loading data...",
      key: "dataLoad",
    });

    Promise.allSettled([fetchData("fleet"), fetchData("drivers")])
      .then((res) => {
        if (res.every((r) => r.status === "rejected")) {
          throw new Error("Every request was rejected");
        }

        if (res[0]["status"] === "rejected") {
          message.error({
            content: "Something went wrong while getting vehicles",
            key: "dataLoad",
          });
        } else if (res[1]["status"] === "rejected") {
          message.error({
            content: "Something went wrong while getting drivers",
            key: "dataLoad",
          });
        } else {
          message.destroy("dataLoad");
        }

        setVehicles(res[0]?.["value"] || []);
        setDrivers(res[1]?.["value"] || []);
      })
      .catch((err) => {
        message.error({
          content: "Could not get data related to vehicles and drivers",
          key: "dataLoad",
        });
        console.log("Error getting data: ", err);
      });

    /** @type {DispatchMatchesType} */
    const matchDefaultData = programFields.find(
      ({ fieldName }) => fieldName === MATCHES_FIELD_NAME
    );
    if (!matchDefaultData) {
      return;
    }
    const env = process.env.NODE_ENV === "production" ? "prod" : "dev";
    const { addressFilter, addressMatch } = matchDefaultData?.fieldOptions[env];

    const filters = [];
    for (const type in addressFilter) {
      for (const address of addressFilter[type]) {
        filters.push({
          type,
          address,
        });
      }
    }
    setFilteredAddresses(filters);

    const allProjectsSet = new Set();
    for (const address in addressMatch) {
      const projectIds = addressMatch[address];
      for (const id of projectIds) {
        allProjectsSet.add(id);
      }
    }

    const conditions = [];
    const ids = Array.from(allProjectsSet);

    if (!ids.length) {
      return;
    }

    for (let i = 0; i < ids.length; i += 100) {
      conditions.push({
        operator: "OR",
        column: "projectId",
        formula: "is one of",
        value: ids.slice(i, i + 100),
      });
    }

    fetchAllData({
      endpoint: "projects",
      resultId: "projectId",
      resultPosition: "projects",
      otherStringParams: {
        // filters: JSON.stringify([{ operator: "OR", conditions }]),
        keysToInclude: JSON.stringify([
          "projectName",
          "projectId",
          "accountName",
          "projectLatitude",
          "projectLongitude",
          "geoFenceInfo",
          "accountId",
          "projectExecutive",
        ]),
      },
    })
      .then((projects) => {
        const projectMap = _.keyBy(projects.map(mapProject), "id");
        const newMatchData = {};

        for (const address in addressMatch) {
          newMatchData[address] = addressMatch[address].reduce(
            (acc, val) => ({
              ...acc,
              [val]: {
                item: { ...(projectMap?.[val] || {}) },
                subItems: null,
              },
            }),
            {}
          );
        }

        setMatchedData(newMatchData);
      })
      .catch((err) => {
        console.log("Error getting project data: ", err);
      });
  }, []);

  /**
   * This is the main function that updates the view after the address click
   * This function will be responsible for looking up a specific address and
   * determining whether that should be selected or not
   *
   * @typedef DataType
   * @property {"PROJECT"|"ALL"} type
   * @property {string|null} address
   * @property {string[]|null} ids
   *
   * @param {DataType} data
   * @param {(param: {error: "NOT_FOUND"|"MULTIPLE_IDS"|null, data: Partial<MatchData>, address?:string}) => any} [callback]
   * @return {Promise<void>}
   */
  async function updateViewType(data, callback = () => {}) {
    //#region UPDATE VIEW

    //first we look up the address on the previously selected data
    let idToSelect, dataToView;
    const { type, address, ids } = data;

    if (!address && !ids) {
      /**
       * If both the address and the ids are null, it
       * means we want to switch to the main grid view
       */
      idToSelect = null;
      dataToView = { address, ids };
    } else if (matchedData[address]) {
      /**
       * if we already had a selected address, we want to take
       * that and the previously selected id and open the view immediately
       */

      /**
       * We give the fallback ids[0] in case the function is called
       * two times by an external handler
       */
      if (lastSelectedIds[address]) {
        idToSelect = lastSelectedIds[address];
      } else if (ids) {
        idToSelect = ids[0];
      } else if (Object.keys(matchedData[address]).length) {
        idToSelect = Object.keys(matchedData[address])[0];
      } else {
        idToSelect = null;
      }

      dataToView = { address, ids };
    } else {
      /**
       * If the address had not been selected, we want to perform
       * a search in our DB records in order to find a matching address.
       * Since thins is a backwards operation, it is possible that the
       * address was not written correctly. This however is not the case
       * when the user previously specified an id
       */
      message.loading({
        content: `Loading data for ${address}`,
        key: "addressInfo",
        duration: 0,
      });

      if (!ids) {
        /**
         * This is the case where we only have the address, so we need to search for it.
         * The user is supposed to handle this case appropriately
         */
        const matchRecords = await fetchSearchRecords(
          "projects",
          "projectName",
          address.slice(0, 6),
          [
            "projectName",
            "projectId",
            "accountName",
            "projectLatitude",
            "projectLongitude",
            "geoFenceInfo",
            "accountId",
            "projectExecutive",
          ]
        );

        if (!matchRecords?.length) {
          callback({ error: "NOT_FOUND", data: null });
          return;
        } else if (matchRecords?.length > 1) {
          setMatchedData({
            ...matchedData,
            [address]: matchRecords.reduce(
              (acc, val) => ({
                ...acc,
                [val.projectId]: {
                  item: mapProject(val),
                  subItems: null,
                },
              }),
              {}
            ),
          });

          message.destroy("addressInfo");

          callback({
            error: "MULTIPLE_IDS",
            data: matchRecords.map((p) => mapProject(p, false)),
            address,
          });
          return;
        } else {
          const item = mapProject(matchRecords[0]);

          message.destroy("addressInfo");

          setMatchedData({
            ...matchedData,
            [address]: matchRecords.reduce(
              (acc, val) => ({
                ...acc,
                [val.projectId]: {
                  item,
                  subitems: null,
                },
              }),
              {}
            ),
          });

          dataToView = { address, ids: [item.id] };
          idToSelect = item.id;
        }
      } else {
        const sameProjects = await filterTables(
          "projects",
          "projectName",
          address
        );

        if (!sameProjects.length) {
          callback({ error: "NOT_FOUND", data: null });
          return;
        } else if (sameProjects.length > 1) {
          setMatchedData({
            ...matchedData,
            [address]: sameProjects.reduce(
              (acc, val) => ({
                ...acc,
                [val.projectId]: {
                  item: mapProject(val),
                  subItems: null,
                },
              }),
              {}
            ),
          });

          message.destroy("addressInfo");

          callback({
            error: "MULTIPLE_IDS",
            data: sameProjects.map(mapProject),
          });
          return;
        } else {
          const item = mapProject(sameProjects[0]);

          message.destroy("addressInfo");

          setMatchedData({
            ...matchedData,
            [address]: sameProjects.reduce(
              (acc, val) => ({
                ...acc,
                [val.projectId]: {
                  item,
                  subitems: null,
                },
              }),
              {}
            ),
          });

          dataToView = { address, ids: [item.id] };
          idToSelect = item.id;
        }
      }
    }

    setViewType(type);
    setSelectedId(idToSelect);
    const matchedAddress = matchedData[dataToView.address];
    const addresses = new Set(
      Object.keys(matchedAddress || {}).map(
        (id) => matchedAddress[id]["item"]["address"]
      )
    );

    if (addresses.size > 1) {
      const keepAddress = matchedAddress[idToSelect]["item"]["address"];
      setMatchedData({
        ...matchedData,
        [dataToView.address]: Object.keys(matchedAddress || {}).reduce(
          (acc, id) => {
            if (
              matchedAddress[id]["item"]["address"] !== keepAddress &&
              !matchedAddress[id]["item"]["forceMatch"]
            ) {
              return acc;
            }

            return {
              ...acc,
              [id]: {
                ...matchedAddress[id],
              },
            };
          },
          {}
        ),
      });
    }

    if (dataToView.address && idToSelect) {
      if (lastSelectedIds[dataToView["address"]] !== idToSelect) {
        setLastSelectedIds({
          ...lastSelectedIds,
          [dataToView.address]: idToSelect,
        });
      }
    }
    setSelectedViewData(dataToView);
    callback({ error: null, data: null });
  }

  function onConfirm() {
    //#region ON CONFIRM
    if (mainViewRef.current) {
      mainViewRef.current.onConfirm();
    }
  }

  /**
   * Normalizes the project object
   * @param {Record<string, any>} project
   * @param {boolean} opened
   * @returns {MatchDataItem}
   */
  function mapProject(project, opened = true) {
    //#region MAP PROJECT
    return {
      id: project.projectId,
      name: `${project.projectName} (${project?.accountName})`,
      differentiatorName: project?.accountName,
      differentiatorId: project?.accountId,
      address: project.projectName,
      opened,
      position:
        project.projectLatitude && project?.projectLongitude
          ? {
              lat: project.projectLatitude,
              lng: project?.projectLongitude,
            }
          : null,
      geoFenceInfo: project?.geoFenceInfo || [],
      originalItem: {
        projectName: project?.projectName,
        projectId: project?.projectId,
        accountName: project?.accountName,
        projectLatitude: project?.projectLatitude,
        projectLongitude: project?.projectLongitude,
        geoFenceInfo: project?.geoFenceInfo,
        accountId: project?.accountId,
        projectExecutive: project?.projectExecutive,
      },
    };
  }

  /** @param {Partial<ViewFilters>} filter */
  function onFilterChange(newFilter) {
    //#region ON FILTER CHANGE
    setFilters({
      ...filters,
      ...newFilter,
    });
  }

  function textFilterHandler(text) {
    //#region TEXT FILTER HANDLER
    if (mainGridRef.current) {
      mainGridRef.current.textFilterHandler(text);
    }
  }

  /**
   * @param {Record<string, MatchData>} newMatches
   * @param {string} address
   */
  async function onManualMatchConfirm(newMatches, address) {
    //#region MANUAL MATCH CONFIRM
    const env = process.env.NODE_ENV === "production" ? "prod" : "dev";

    /** @type {DispatchMatchesType["fieldOptions"]} */
    const newFields = {
      ...(dispatchMatches?.fieldOptions || {}),
      [env]: {
        ...(dispatchMatches?.fieldOptions?.[env] || {}),
        addressMatch: {
          ...(dispatchMatches?.fieldOptions?.[env]?.["addressMatch"] || {}),
          [address]: Object.keys(newMatches),
        },
      },
    };

    await dispatchFields(newFields)
      .then(() => {
        setMatchedData((prev) => {
          if (!Object.keys(newMatches).length) {
            const tmp = { ...prev };
            delete tmp[address];
            return tmp;
          } else {
            return {
              ...prev,
              [address]: newMatches,
            };
          }
        });
      })
      .catch((err) => {
        console.log("Error saving fields: ", err);
      });
  }

  function openFilteredProjectsModal() {
    //#region OPEN PROJECT FILTER
    if (mainGridRef.current) {
      mainGridRef.current.openFilteredProjectsModal();
    }
  }

  /**
   * @param {string} address
   * @param {string} id
   * @param {Partial<import("./data/ProjectMatchContext").MatchData>} data
   */
  function updateMatchedData(address, id, data) {
    //#region UPDATE MATCHES DATA
    setMatchedData((prev) => {
      return {
        ...prev,
        [address]: {
          ...prev[address],
          [id]: {
            ...prev[address][id],
            ...data,
          },
        },
      };
    });
  }

  /** @param {string} newId */
  function updateSelectedId(newId) {
    //#region UPDATE SELECTED ID
    setSelectedId(newId);
    if (selectedViewData.address && newId) {
      if (lastSelectedIds[selectedViewData.address] !== newId) {
        setLastSelectedIds({
          ...lastSelectedIds,
          [selectedViewData.address]: newId,
        });
      }
    }
  }

  /**
   * @param {FilteredProjectsType[]} addresses
   */
  async function updateFilteredAddresses(addresses) {
    //#region UPDATE FILTERED ADDRESSES
    const env = process.env.NODE_ENV === "production" ? "prod" : "dev";
    const ofP = [];
    const ofSch = [];
    const grouped = {
      ["Off Project"]: ofP,
      ["Off Schedule"]: ofSch,
    };

    for (const address of addresses) {
      if (address.type === "Off Project") {
        ofP.push(address.address);
      } else {
        ofSch.push(address.address);
      }
    }

    /** @type {DispatchMatchesType["fieldOptions"]} */
    const newFields = {
      ...(dispatchMatches?.fieldOptions || {}),
      [env]: {
        ...(dispatchMatches?.fieldOptions?.[env] || {}),
        addressFilter: grouped,
      },
    };

    await dispatchFields(newFields)
      .then(() => {
        setFilteredAddresses(addresses);
      })
      .catch((err) => {
        console.log("Error saving fields: ", err);
      });
  }

  function openManualMatchModal() {
    //#region OPEN MANUAL MATCH
    if (mainGridRef.current) {
      mainGridRef.current.openManualMatchModal();
    }
  }

  /**
   * @param {boolean} state
   */
  function changeConfirmDisabled(state) {
    //#region CONFIRM DISABLED
    if (footerRef.current) {
      footerRef.current.changeConfirmDisabled(state);
    }
  }

  function changeConfirmText(text) {
    //#region CONFIRM TEXT
    if (footerRef.current) {
      footerRef.current.changeConfirmText(text);
    }
  }

  function openProjectHandler() {
    //#region OPEN PROJECT
    window.open(location.origin + `/projects/${selectedId}`, "_blank");
  }

  const View = viewProviders[viewType];

  const titleName = matchedData?.[selectedViewData?.address]?.[selectedId]?.[
    "item"
  ]?.["name"]
    ? matchedData?.[selectedViewData?.address]?.[selectedId]?.["item"]?.["name"]
    : matchedData?.[selectedViewData?.address]?.[selectedId]?.["item"]?.[
        "address"
      ] || selectedViewData?.address;

  //#region JSX
  return (
    <ProjectMatchContext.Provider
      value={{
        dispatches,
        vendors,
        dispatchRecords,
        offProjectData,
        offScheduleData,
        filters,
        onFilterChange,
        updateViewType,
        textFilterHandler,
        drivers,
        vehicles,
        viewType,
        mapLoaded,
        selectedViewData,
        matchedData,
        updateMatchedData,
        selectedId,
        mapProject,
        updateSelectedId,
        changeConfirmDisabled,
        changeConfirmText,
        updateDispatchesHandler,
        openFilteredProjectsModal,
        updateFilteredAddresses,
        filteredAddresses,
        openManualMatchModal,
        onManualMatchConfirm,
      }}
    >
      <Modal
        {...{
          open,
          onCancel,
          centered: true,
          keyboard: false,
          maskClosable: false,
          closeIcon: <XIcon />,
          wrapClassName: "dispatch-project-match-wrap",
          title: (
            <div className="inner-modal-title-container">
              {`${
                filters["records"] === "Off Project" ? "Project" : "Schedule"
              } Match${titleName ? ` / ${titleName}` : ""}`}
              {titleName ? (
                <Tooltip title="Go to Project" placement="bottom">
                  <MondayButton
                    {...{
                      Icon: <GoTasks />,
                      className: "mondayButtonBlue",
                      onClick: openProjectHandler,
                    }}
                  >
                    {null}
                  </MondayButton>
                </Tooltip>
              ) : null}
            </div>
          ),
          selectedViewData,
          footer: <ModalFooter {...{ onCancel, onConfirm }} ref={footerRef} />,
          className: `dispatch-project-match ${
            isDarkMode ? "dispatch-project-match-dark" : ""
          }`,
        }}
      >
        <div
          className={`layout-container ${viewType === "ALL" ? "cl-1" : "cl-3"}`}
        >
          <ProjectMatchController />
          <View ref={viewType === "ALL" ? mainGridRef : mainViewRef} />
        </div>
      </Modal>
    </ProjectMatchContext.Provider>
  );
}

export default DispatchProjectMatchModal;
