import moment from "moment-timezone";
import { Tooltip, Alert } from "antd";
import { formatCurrency } from "../../../../../../../../../utils/formatCurrency";
import { useRedux } from "../../../../../../../../../hooks/useRedux";
export const InvoiceOverview = ({ data }) => {
  const [invoices = {}] = useRedux("paymentInvoices");
  const [invoiceRecords] = useRedux("invoiceRecords");
  const applicableInvoice = invoiceRecords?.find(
    (invoice) => invoice?.invoiceId === data?.invoiceId
  );
  data.invoiceDescription = getQuickDescription({
    applicableInvoice,
  });

  return (
    <Tooltip
      overlayClassName="invoiceTooltip"
      title={
        <Alert
          type="info"
          message="Invoice Overview"
          showIcon
          description={
            <div
              className="invoice-overview-tooltip"
              style={{
                color: "#000",
                // display: "flex",
                // flexDirection: "column",
              }}
            >
              <div>
                <strong>Invoice No:</strong> {applicableInvoice?.invoiceNumber}{" "}
                ({applicableInvoice?.invoiceStatus || ""})
              </div>
              <div className="more-info-invoice">
                <strong> From:</strong>{" "}
                {moment(applicableInvoice?.fromDate).format("MM/DD/YYYY")}{" "}
                <strong>To: </strong>
                {moment(applicableInvoice?.toDate).format("MM/DD/YYYY")}
              </div>{" "}
              <div className="more-info-invoice">
                <strong> Invoice From:</strong>{" "}
                {applicableInvoice?.categoriesFrom?.[0] || ""}
              </div>{" "}
              <div className="more-info-invoice">
                <strong> Quickbooks No:</strong>{" "}
                {applicableInvoice?.quickBooksNumber || ""}
              </div>
              <div className="more-info-invoice">
                <strong> Memo/s:</strong>{" "}
                {applicableInvoice?.memos
                  ?.map((e) => e?.memoValue || false)
                  ?.join("\n") || ""}
              </div>
              <div className="more-info-invoice">
                <strong> Invoice Amount:</strong>{" "}
                {formatCurrency(applicableInvoice?.totalInvoiceAmount || "")}
              </div>{" "}
              <div className="more-info-invoice">
                <strong> Included Tax (%):</strong>{" "}
                {applicableInvoice?.salesTax * 100 || 0}%{" "}
              </div>
              <div className="more-info-invoice">
                <strong> Items:</strong> <br />
                {applicableInvoice?.invoiceItems
                  ?.map(
                    (item) =>
                      item?.name +
                      ` (${item?.category}) = ${formatCurrency(
                        item?.total || 0
                      )}`
                  )
                  .join(",")}
              </div>
            </div>
          }
        ></Alert>
      }
      // color="#FFD046"
      // open
    >
      {getQuickDescription({ applicableInvoice })}
    </Tooltip>
  );
};

export const getQuickDescription = ({ applicableInvoice = {} }) => {
  const myInvoice = applicableInvoice;

  const {
    invoiceNumber = "",
    invoiceStatus = "",
    categoriesFrom = [""],
    fromDate = "",
    toDate = "",
  } = myInvoice || {};
  return `(${invoiceStatus}) From ${moment(fromDate).format(
    "MM/DD/YYYY"
  )} To ${moment(toDate).format("MM/DD/YYYY")}  - ${
    categoriesFrom[0]?.toUpperCase() || "CUSTOM"
  } - Items: ${myInvoice?.invoiceItems
    ?.map(
      (item) =>
        item?.name +
        ` (${item?.category || "custom"}) = ${formatCurrency(item?.total || 0)}`
    )
    .join(",")}`;
};
