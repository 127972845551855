import React from "react";
import { Button, Tooltip } from "antd";
import ArrowDown from "../../../../../../../icons/DataGrid_Down_Red.webp";
import ArrowUp from "../../../../../../../icons/DataGrid_Up_Green.webp";
import { priceFormatter } from "../../../tools/formatters/pricing";
import { TakeOffContext } from "../../../context";
import { isNaN } from "lodash";
import { MinusSquareOutlined } from "@ant-design/icons";

class SidewalkShedRentAdvisor extends React.Component {
  static contextType = TakeOffContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const gridData = this.context;
    this.setState(this.props.data);
  }

  handleButtonClick() {
    this.render();
  }

  render() {
    const { gridData, updateStateAndSave, isWritable } = this.context;

    const updateRent = (rentPrice) => {
      let data = gridData?.find?.(
        (el) => el?.serviceId === this.props?.data?.serviceId
      );
      if (this.props?.data?.isAlternate === true) {
        let updateData = gridData?.map?.((prev) => {
          if (prev?.serviceId === data?.serviceId) {
            let test = prev;
            test?.serviceOptions?.[1]?.[0]?.items?.forEach((el) => {
              if (el?.id === this.props?.data?.id) {
                el.rent = parseFloat(rentPrice);
                this.setState({ ...el, rent: rentPrice });
              }
            });
            return test;
          } else {
            return prev;
          }
        });
        updateStateAndSave(updateData);
        this.handleButtonClick.bind(this);
      } else {
        let updateData = gridData?.map?.((prev) => {
          if (prev?.serviceId === data?.serviceId) {
            let test = prev;
            test?.serviceOptions?.[0]?.[0]?.items?.forEach((el) => {
              if (el?.id === this.props?.data?.id) {
                el.rent = parseFloat(rentPrice);
                this.setState({ ...el, rent: rentPrice });
              }
            });
            return test;
          } else {
            return prev;
          }
        });
        updateStateAndSave(updateData);
        this.handleButtonClick.bind(this);
      }
    };

    let data = gridData?.find?.(
      (el) => el?.serviceId === this.props?.data?.serviceId
    );
    let result =
      this.props?.data?.isAlternate === true
        ? data?.alternateRent / 100
        : data?.rent / 100;

    let rent = result;
    let arrow,
      value = parseFloat(this.state.rent) || 0,
      suggestedRent = this.state.price * rent || 0,
      difference;

    if (value.toFixed(2) > suggestedRent.toFixed(2)) {
      arrow = <img alt="..." className="data-grid-price-arrow" src={ArrowUp} />;
    } else if (value.toFixed(2) < suggestedRent.toFixed(2)) {
      arrow = (
        <img alt="..." className="data-grid-price-arrow" src={ArrowDown} />
      );
    } else if (value.toFixed(0) === suggestedRent.toFixed(0)) {
      arrow = <MinusSquareOutlined />;
    } else arrow = <MinusSquareOutlined style={{ visibility: "hidden" }} />;

    difference =
      parseFloat(this.state.rent).toFixed(2) - suggestedRent.toFixed(2);

    let differenceView = Math.abs(difference).toFixed(2);

    if (isNaN(difference) || difference === 0) differenceView = "";

    return (
      <div style={{ display: "flex" }} className={"ppu-advisor-cell"}>
        <span className="ppu-difference">
          {arrow} {differenceView}
        </span>

        <span
          style={{
            flexGrow: 1,
            marginTop: 5,
            marginLeft: 10,
            textAlign: "right",
          }}
        >
          {this.state.rent}
        </span>
        {isNaN(suggestedRent) ||
        parseFloat(this.state.rent).toFixed(0) == suggestedRent?.toFixed(0) ||
        suggestedRent === 0 ? (
          ""
        ) : (
          <Tooltip title={`Apply suggestion: ${suggestedRent.toFixed(2)}`}>
            <Button
              style={{ marginLeft: 15 }}
              // size="small"
              // type="primary"
              onClick={() => updateRent(suggestedRent.toFixed(2))}
              disabled={
                this.props.data.lock == true
                  ? true
                  : false && isWritable === false
                  ? true
                  : false
              }
            >
              {/*<img src="https://img.icons8.com/material-outlined/16/f5f5f5/submit-document.png" alt=""/>*/}
              {suggestedRent.toFixed(2)}
            </Button>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default SidewalkShedRentAdvisor;
