import { ElevationPrice } from "./ElevationPrice";
import { useState } from "react";
import { getRawElevationTotalUnits } from "../../tools/formatters/totals";
import { wrapWithErrorCalculating } from "../other/ErrorCalculating";
import { Tooltip } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ConvertIcon } from "../../../../../../assets/index";
import AccountingConfirmationModal from "../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import { useRedux } from "../../../../hooks/useRedux";
import ScaffoldPlanks from "../Scaffold/ScaffoldPLIAddon/ScaffoldPlanks";
import "./elevationPanel.scss";
import ElevationDescription from "./ElevationDescription";
import AutoFiller from "./AutoFiller/AutoFiller";
import { WithTooltip } from "../../../../../commonComponents";
//all inputs and labels on elevation (except buttons on the right)
export default function ElevationPanelHeader(props) {
  const {
    elevation,
    stateSetter,
    handleElevationFieldChange,
    serviceIndex,
    optionIndex,
    elevationIndex,
    // isWritable,
    gridData,
    updateStateAndSave,
    agGridApiForEachElevation,
    selectedPriceSchemesForService,
    priceView,
    accessRights,
  } = props;

  console.log("propssElevations", { props });

  const {
    serviceId,
    isScope = false,
    isHoist = false,
  } = gridData[serviceIndex];
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [isWritable] = useRedux("estimationsIsWritable");

  const onDivKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const onConvertToPrimary = (e) => {
    console.log("onConvertToPrimary", optionIndex);
    const newData = gridData?.map((el, idx) => {
      if (idx === serviceIndex) {
        return {
          ...el,
          serviceOptions: el.serviceOptions?.map((sOption, sOpId) => {
            if (sOpId === 0) {
              return [
                ...sOption,
                {
                  ...elevation,
                  items:
                    elevation?.items?.map((el) => ({
                      ...el,
                      isAlternate: false,
                    })) || [],
                },
              ];
            } else
              return sOption?.filter(
                (el) => el?.elevationId !== elevation.elevationId
              );
          }),
        };
      }
      return el;
    });
    updateStateAndSave(newData);
  };
  const onConvertToAlternate = (e) => {
    const newData = gridData?.map((el, idx) => {
      if (idx === serviceIndex) {
        const hasEnabledAlternate = !!el.serviceOptions[1];
        if (!hasEnabledAlternate) el.serviceOptions[1] = [];
        return {
          ...el,
          serviceOptions: el.serviceOptions?.map((sOption, sOpId) => {
            if (sOpId === 1) {
              return [
                ...sOption,
                {
                  ...elevation,
                  items:
                    elevation?.items?.map((el) => ({
                      ...el,
                      isAlternate: true,
                    })) || [],
                },
              ];
            } else
              return sOption?.filter(
                (el) => el?.elevationId !== elevation.elevationId
              );
          }),
        };
      }
      return el;
    });
    updateStateAndSave(newData);
  };

  let hasAccessRights = accessRights.children.find(
    (item) => item.title === "Price Edit"
  );

  const hasUnapprovedPLI =
    elevation?.items?.some?.(({ approved = false }) => !approved) || false;

  let elevationPriceAndUnits;
  if (priceView === true) {
    elevationPriceAndUnits = (
      <ElevationPrice
        {...{
          selectedPriceSchemesForService,
          serviceIndex,
          optionIndex,
          elevationIndex,
          handleElevationFieldChange,
          elevation,
          isWritable,
          gridData,
          updateStateAndSave,
          agGridApiForEachElevation,
          stateSetter,
          hasAccessRights,
        }}
      />
    );
  } else {
    const { unitTotal, suffix } = getRawElevationTotalUnits(
      gridData[serviceIndex].serviceId,
      elevation.items
    );
    elevationPriceAndUnits = (
      <span className="elevation-total" style={{ display: "flex" }}>
        {unitTotal === undefined && suffix === undefined ? (
          ""
        ) : (
          <span
            className={"elevation-total-element"}
            style={{ display: "flex", gap: "5px" }}
          >
            <b>{wrapWithErrorCalculating(unitTotal)}</b> {suffix}
          </span>
        )}
      </span>
    );
  }

  return (
    <>
      <span className="elevation-panel-header">
        {!isScope && !isHoist && (
          <AutoFiller
            {...{ serviceId: serviceIndex, optionIndex, elevationIndex }}
          />
        )}
        {hasUnapprovedPLI &&
          serviceId != 3 &&
          serviceId?.toString() !== "38" && (
            <Tooltip title="This elevation has unapproved PLI-s">
              {/* <WarningFilled
              style={{
                // marginTop: 4,
                marginRight: 10,
                color: "#FE4C4A",
              }}
            /> */}
              <WarningFilled
                style={{
                  marginRight: 10,
                }}
              />
            </Tooltip>
          )}
        <span style={{ marginRight: "15px" }}>{elevation.elevationId}</span>

        <span
          role="textbox"
          contentEditable={isWritable}
          suppressContentEditableWarning
          className="ant-input elevationLabel"
          onFocus={(e) => {
            stateSetter({ currentElevationName: e.target.innerText });
          }}
          onBlur={(e) => {
            handleElevationFieldChange(
              e,
              serviceIndex,
              optionIndex,
              elevationIndex,
              "elevationLabel"
            );
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          // onKeyDown={onDivKeyDown}
        >
          {elevation.elevationLabel}
        </span>

        {elevationPriceAndUnits}
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 10,
          alignItems: "center",
          marginRight: 10,
        }}
      >
        {!isScope && (
          <>
            {optionIndex !== 0 ? (
              <Tooltip title="Convert to primary">
                <button
                  className="iconHolder"
                  onClick={() => {
                    try {
                      if (optionIndex !== 0) {
                        onConvertToPrimary();
                      } else {
                        onConvertToAlternate();
                      }
                    } catch (err) {}
                  }}
                >
                  <ConvertIcon />
                </button>
              </Tooltip>
            ) : (
              // <MondayButton
              //   className="mondayButtonBlue"
              //   disabled={!isWritable}
              //   onClick={() => setConfirmationModalVisibility(true)}
              //   Icon={<ConvertIcon />}
              // >
              //   Convert to Primary
              // </MondayButton>
              <WithTooltip
                tooltipCategory="Take Off"
                tooltipKey="convertAlternate"
              >
                <button
                  className="iconHolder"
                  onClick={() => {
                    try {
                      if (optionIndex !== 0) {
                        onConvertToPrimary();
                      } else {
                        onConvertToAlternate();
                      }
                    } catch (err) {}
                  }}
                >
                  <ConvertIcon />
                </button>
              </WithTooltip>

              // <MondayButton
              //   className="mondayButtonBlue"
              //   disabled={!isWritable}
              //   onClick={() => setConfirmationModalVisibility(true)}
              //   Icon={<ConvertIcon />}
              // >
              //   Convert to Alternate
              // </MondayButton>
            )}
          </>
        )}{" "}
        {(serviceId === 2 || serviceId === 11) && hasAccessRights && (
          <ScaffoldPlanks
            {...{
              elevation,
              serviceIndex,
              optionIndex,
              elevationIndex,
              serviceId,
            }}
          />
        )}
        <ElevationDescription
          {...{
            elevation,
            serviceIndex,
            optionIndex,
            elevationIndex,
          }}
        />
      </div>
      <AccountingConfirmationModal
        {...{
          visible: confirmationModalVisibility,
          setVisible: setConfirmationModalVisibility,
          onConfirm: () => {
            try {
              if (optionIndex !== 0) {
                onConvertToPrimary();
              } else {
                onConvertToAlternate();
              }
            } catch (err) {
            } finally {
              setConfirmationModalVisibility(false);
            }
          },
          text: `Are you sure you want to convert this elevation to ${
            optionIndex !== 0 ? "PRIMARY" : "ALTERNATE"
          }?`,
          darkMode: true,
          discardButton: false,
        }}
      />
      <AccountingConfirmationModal
        {...{
          visible: confirmationModalVisibility,
          setVisible: setConfirmationModalVisibility,
          onConfirm: () => {
            try {
              if (optionIndex !== 0) {
                onConvertToPrimary();
              } else {
                onConvertToAlternate();
              }
            } catch (err) {
            } finally {
              setConfirmationModalVisibility(false);
            }
          },
          text: `Are you sure you want to convert this elevation to ${
            optionIndex !== 0 ? "PRIMARY" : "ALTERNATE"
          }?`,
          darkMode: true,
          discardButton: false,
        }}
      />
      {/* <RentalCalculator
        {...{
          elevation,
          stateSetter,
          handleElevationFieldChange,
          serviceIndex,
          optionIndex,
          elevationIndex,
          isWritable,
          gridData,
          updateStateAndSave,
          agGridApiForEachElevation,
          selectedPriceSchemesForService,
          priceView,
          accessRights,
        }}
      /> */}
    </>
  );
}
