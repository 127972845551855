import { message } from "antd";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import {
  deleteNotifications,
  getCognitosForNotification,
} from "../../../../../utils/notificationHelpers";
import { API } from "aws-amplify";

/**
 * Deletes a safety record.
 *
 * @param {Function} dispatch - The dispatch function for Redux.
 * @param {string} nameKey - The key for the name of the safety record.
 * @param {string} category - The category of the safety record.
 * @param {Object} rowObject - The row object representing the safety record.
 * @param {string} notifAction - The notification action.
 * @param {Array} notifications - The array of notifications.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Function} refreshTable - The function to refresh the table.
 * @param {Function} setVisibleCreationProgress - The function to set the visibility of the creation progress.
 * @param {Function} updateProgressStatus - The function to update the progress status.
 * @param {Function} saveAddedLogs - The function to save added logs.
 * @returns {Promise<void>} A promise that resolves when the safety record is deleted.
 */
async function deleteSafety({
  dispatch = () => {},
  nameKey = "",
  category = "",
  rowObject = {},
  notifAction = "",
  notifications = [],
  userConfiguration = {},
  refreshTable = () => {},
  // onCloseWarningModal = () => {},
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
  saveAddedLogs = async () => {},
}) {
  message.loading({
    content: "Deleting...",
    key: "messageDeleting",
  });

  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });
  await API.del("safety", `/safety/${rowObject?.safetyApplicationId}`)
    ?.then(async () => {
      if (rowObject?.incidentId) {
        await API.put("incidents", `/incidents/${rowObject?.incidentId}`, {
          body: {
            incidentStatus: "New",
          },
        });
      }
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      refreshTable(rowObject, "Delete");
      deleteNotifications(
        notifications,
        rowObject?.safetyApplicationId,
        dispatch
      );

      message.success({
        content: "Safety deleted successfully",
        key: "messageDeleting",
        duration: 3,
      });
      let deletePostLogsBody = {
        recordId: rowObject?.safetyApplicationId,
        recordName: rowObject?.safetyApplicationObject?.[nameKey],
        actionType: "Delete",
        category,
        currentData: {},
        member: userConfiguration?.nameOfUser,
        nameOfUser: userConfiguration.nameOfUser,
        cognitoUserId: userConfiguration?.cognitoUserId,
        previousData: rowObject?.safetyApplicationObject,
        updatedAt: Date.now(),
      };
      setVisibleCreationProgress({ deletePostLogsBody });

      saveAddedLogs(deletePostLogsBody);

      broadcastNotification("42", notifAction, [
        { common: userConfiguration?.nameOfUser },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: userConfiguration?.cognitoUserId,
          cognitos: getCognitosForNotification(
            userConfiguration,
            rowObject?.teamsConfiguration
          ),
        },
      ]).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });

      // onCloseWarningModal({
      //   deleteAction: true,
      //   deletePostLogsBody,
      // });
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Error Deleting Safety: ", err);
    });
}

export default deleteSafety;
