import { Modal, Checkbox, message, Skeleton } from "antd";
import { API } from "aws-amplify";
import { useState, useMemo, useEffect } from "react";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { Close, EditIcon } from "../../../../../../../../DynamicView/src";
import EstimationPicker from "../../../../NewScheduleOfValue/Components/EstimationPicker/EstimationPicker";
import Header from "../../../../NewScheduleOfValue/Components/Header/Header";
import Information from "../../../../NewScheduleOfValue/Components/Informaions/Information";
import "./../../../../NewScheduleOfValue/new-schedule-of-value.scss";
import { Menu, Dropdown, Alert } from "antd";
import ModalFooter from "./ModalFooter";
import { filterTables } from "../../../../../../../../../../utils";
import { getScheduleOfValues } from "../../../../../../../../../../actions/scheduleOfValues";
import { useDispatch } from "react-redux";
import { filterTablesPagination } from "../../../../../../../../../../utils/paginationFetchData";
import "./AddEstimationModal.scss";
import "./../../../../../../../../../SidebarPages/Projects/Accounting/Applications/ApplicationView/components/ServiceList/Service/ServiceHeader/ServiceHeaderComponents/ChangeRetenageModal/ChangeRetenageModal.scss";
import { getServiceLegacyPrice } from "../../../../../../calculations/servicePrices";
import { PlusIcon } from "../../../../../../../../../../assets";
import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getModifiedServiceOptions } from "../../../../NewScheduleOfValue/Components/ModalFooter/modalFooterFunctions";
const CheckboxGroup = Checkbox.Group;
const AddEstimationModal = ({
  currentSOVData,
  onAdd,
  disabled,
  darkMode = false,
}) => {
  const [visibility, setVisibility] = useState(false);
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const [chosenWay, setChosenWay] = useState("Estimation");
  const [estimations, setEstimations] = useState(null);
  const [filteredEstimations, setFilteredEstimations] = useState([]);
  const dispatch = useDispatch();

  const getEstimations = async (projectId) =>
    await filterTables("estimations", "projectId", projectId);

  useEffect(() => {
    async function fetchEstimations() {
      if (!!currentSOVData && visibility) {
        message.success("Loading estimations...", 0);
        setEstimations(
          await getEstimations(currentSOVData?.projectId)
            .then((res) => {
              message.destroy();
              return res;
            })
            .catch((err) => {
              message.destroy();
              message.error("There was an error loading estimations");
            })
        );
      }
    }
    fetchEstimations();
  }, [currentSOVData, onAdd, visibility]);

  const handleCancel = () => {
    setVisibility(false);
    setSelectedEstimations([]);
  };

  const handleOk = () => {
    if (selectedEstimations?.length > 0) {
      const { scheduleId, userId, lastModifiedBy, ...rest } = {
        ...currentSOVData,
        estimationsSelected: [
          ...currentSOVData?.estimationsSelected,
          ...selectedEstimations,
        ],
        services: [
          ...currentSOVData?.services,

          ...estimations
            ?.filter((curr) =>
              selectedEstimations?.some(
                (selectedId) => selectedId === curr?.estimationId
              )
            ) // Filter only selected estimations, from list
            ?.flatMap((estimation, estimationIndex) =>
              estimation?.services?.map((service, serviceIndex) => {
                return {
                  // Modify and return services of each estimation
                  ...service, // Spread service properties that dont need to change
                  estimationId: estimation.estimationId, // Add estimation id to each service
                  amounts: [], // Initialize and empty array for amounts of each service
                  rentalPaymentTerms: service?.rentalPaymentTerms || {}, // Initialize rental payment terms

                  visible:
                    estimationIndex === 0 && serviceIndex === 0 ? true : false, // Set visible to true only to the first service
                  totalPrice: (() => {
                    // const { serviceAddons = [], serviceOptions = [] } = service || {};
                    let serviceTotalPrice = 0;
                    console.log("service123", service);
                    serviceTotalPrice += getServiceLegacyPrice(service);
                    // if (service?.isScope) {
                    //   serviceTotalPrice = otherScopeServiceTotalPriceMapper(service);
                    //   console.log("serviceTotalPrice", serviceTotalPrice);
                    // } else {
                    //   serviceTotalPrice = [
                    //     ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                    //       items?.flatMap(({ totalPrice }) => +totalPrice)
                    //     ),
                    //     ...serviceAddons?.map(({ totalPrice }) => +totalPrice),
                    //   ]
                    //     ?.filter(Boolean)
                    //     ?.reduce((acc, curr) => (acc += +curr), 0);
                    // }
                    console.log("onCreationPrice", {
                      serviceTotalPrice,
                      service,
                    });

                    return serviceTotalPrice;
                  })(),
                  price: (() => {
                    const { serviceAddons = [], serviceOptions = [] } =
                      service || {};

                    const serviceTotalPrice = [
                      ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                        items?.flatMap(({ price }) => +price)
                      ),
                      ...serviceAddons?.map(({ totalPrice }) =>
                        forceToNumber(totalPrice)
                      ),
                    ]
                      ?.filter(Boolean)
                      ?.reduce((acc, curr) => (acc += +curr), 0);

                    return serviceTotalPrice;
                  })(),
                  // totalPrice: (() => {
                  //   const { serviceAddons = [], serviceOptions = [] } =
                  //     service || {};

                  //   const serviceTotalPrice = [
                  //     ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                  //       items?.flatMap(({ totalPrice }) => +totalPrice)
                  //     ),
                  //     ...serviceAddons?.map(({ totalPrice }) => +totalPrice),
                  //   ]
                  //     ?.filter(Boolean)
                  //     ?.reduce((acc, curr) => (acc += +curr), 0);

                  //   return serviceTotalPrice;
                  // })(),
                  // price: (() => {
                  //   const { serviceAddons = [], serviceOptions = [] } =
                  //     service || {};

                  //   const serviceTotalPrice = [
                  //     ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                  //       items?.flatMap(({ price }) => +price)
                  //     ),
                  //     ...serviceAddons?.map(({ price }) => +price),
                  //   ]
                  //     ?.filter(Boolean)
                  //     ?.reduce((acc, curr) => (acc += +curr), 0);

                  //   return serviceTotalPrice;
                  // })(),
                  typeOfSOV: "", // Type of SOV is empty initially,
                  retainage: 10, // Default retainage 10%
                  isChangeOrder: estimation?.isChangeOrder,
                  serviceOptions: getModifiedServiceOptions(service),
                  // [
                  //   service?.serviceOptions[0]?.map((elevationData) => ({
                  //     ...elevationData,
                  //     items: elevationData?.items?.map((pliData) => ({
                  //       ...pliData,
                  //       totalPrice: +pliData?.totalPrice,
                  //       price: +pliData?.price,
                  //       approved: !!service?.isScope ? true : pliData?.approved,
                  //     })),
                  //   })),
                  // ],
                };
              })
            ),
        ],
      };

      API.put("scheduleOfValues", `/scheduleOfValues/${scheduleId}`, {
        body: rest,
      })
        .then(async (response) => {
          message.success("Estimations were added successfully!");
          onAdd !== false
            ? await onAdd()
            : dispatch(getScheduleOfValues(currentSOVData?.projectId));
          setVisibility(false);
        })
        .catch((error) => {
          message.error("Something wrong occured while adding estimations!");
          console.error(error);
        });
    } else {
      message.error("You need to select at least one estimation!");
    }
  };

  useEffect(() => {
    if (!!estimations) {
      setFilteredEstimations(
        estimations
          ?.filter(
            (estimation) =>
              estimation.estSTATUS === "Approved" &&
              !currentSOVData?.estimationsSelected?.some(
                (selectedEstimationId) =>
                  selectedEstimationId === estimation.estimationId
              )
          )
          ?.map((el, index) => ({
            ...el,
            label: `Estimation ${
              currentSOVData?.estimationsSelected?.length + index + 1
            }`,
          }))
      );
    }
  }, [estimations, onAdd]);

  return (
    <>
      {/* <button onClick={() => setVisibility(true)}>Add Estimation</button> */}
      {/* <span
        onClick={() => setVisibility(true)}
        style={{
          color: "#71CF48",
          cursor: "pointer",
        }}
      >
        add estimation{" "}
      </span> */}
      <MondayButton
        className="mondayButtonGreen"
        disabled={!!disabled ? disabled : false}
        onClick={() => setVisibility(true)}
        {...{ Icon: <PlusIcon /> }}
        id={"addEstimationButton"}
      >
        Add Estimation
      </MondayButton>

      {visibility && (
        <Modal
          wrapClassName={
            darkMode
              ? "changeRetenagePercentageApplicationModalDark"
              : "changeRetenagePercentageApplicationModalLight"
          }
          title="Add Estimations"
          open={visibility}
          width={770}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<Close />}
          footer={
            <ModalFooter
              {...{
                handleCancel,
                handleOk,
              }}
            />
          }
        >
          <div className="mainBodyContainer">
            {filteredEstimations?.length ? (
              <>
                <EstimationPicker
                  {...{
                    setSelectedEstimations,
                    selectedEstimations,
                    estimations: filteredEstimations,
                    chosenWay,
                    isLoading: !visibility,
                    editSov: !visibility,
                    hasIndex: true,
                  }}
                />
                <Information {...{ selectedEstimations, estimations }} />
              </>
            ) : // <CheckboxGroup
            // 	options={filteredEstimations}
            // 	value={selectedEstimations}
            // 	onChange={setSelectedEstimations}
            // />

            !!estimations ? (
              <div
                className="no-estimations-container"
                style={{ padding: "50px" }}
              >
                {" "}
                <Alert
                  type="warning"
                  message="For the selected SOV, there are no estimations to add!"
                />
              </div>
            ) : (
              <div style={{ width: "800px", padding: "50px" }}>
                <Skeleton />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddEstimationModal;
