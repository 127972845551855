import { Modal, Tour } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector, useDispatch } from "react-redux";
import {
  antdModalProps,
  gridSidebar,
  updateDocumentTitle,
} from "../../../../../../../utils";
import { ControlPanel, LogoutOptions, SessionForceLogout } from "./components";
import { useModalOnceObject } from "../../../../../../../hooks/useModalOnce";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets/index";
import "./UserSessionModal.scss";
import CustomModalHeader from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { useEditLogs, useSessionSocket } from "../../../../../../../hooks";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import updateSessions from "../../../../../../Header/components/SessionCheck/helpers/updateSessionsArray";
import {
  logoutSingleUserSession,
  sessionColDefs,
  userSessionModalGuide,
} from "./helpers";
import { API } from "aws-amplify";
import { updateReduxSession } from "../../../../../../../reducers/commonsStates/sessionNotificationsReducer";
import SessionLogsModal from "../../../../../../Header/components/SessionCheck/SessionLogsModal/SessionLogsModal";
import { ExportOrEmailDropdown } from "../../../../../../commonComponents";
import { getExcelColumnKeys } from "../../../../../../../utils/getExcelColumnKeys";
import dayjs from "dayjs";

const UserSessionModal = ({
  visible,
  setVisible,
  user: proppedUser = {},
  ws = false,
  queryParam,
}) => {
  const navigate = useNavigate();
  const gridRef = useRef(null);

  const { socket: wsSessions } = useSessionSocket();

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields = [] } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [user, setUser] = useState(proppedUser);
  const {
    nameOfUser,
    activeSessions = [],
    isSuspended,
    identityId,
  } = user || {};
  const [tourOpen, setTourOpen] = useModalOnceObject("userSessionModalTour");
  const [delayTour, setDelayTour] = useState(false);
  const [logoutOptionsModal, setLogoutOptionsModal] = useState(false);
  const [logoutTime, setLogoutTime] = useState(null);
  const [sessionLogsVisible, setSessionLogsVisible] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();

  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const { sessionNotifications } = useSelector(
    (state) => state.sessionNotifications
  );
  const { allLogs = [], newCount = 0 } = sessionNotifications || {};

  const sessionLogsObj = useMemo(() => {
    if (!identityId) return {};
    return (allLogs || [])
      ?.filter((log) => log?.sessionInfo?.identityId === identityId)
      ?.reduce((acc, log) => {
        const sessionId = log.sessionId;
        if (!acc[sessionId]) {
          acc[sessionId] = [];
        }
        acc[sessionId].push(log);
        return acc;
      }, {});
  }, [allLogs, identityId]);

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    setColumnApi(param.columnApi);
  });

  const onlineStatusesHandler = useCallback(
    (msg, foundUser = {}) => {
      const { request, body } = JSON.parse(msg.data);
      const env = process.env.NODE_ENV === "production" ? "prod" : "dev";
      if (request === "online-statuses" && !isEmpty(foundUser) && queryParam) {
        const singleUSerStatus = body?.statuses[env]?.[queryParam];
        setUser(() => ({
          ...foundUser,
          onlineStatus: singleUSerStatus?.onlineStatus,
          idleStatus: singleUSerStatus?.idleStatus,
          activeSessions:
            updateSessions({
              allSessions: foundUser?.activeSessions?.map((el) => {
                const { wsStatus, isIdle, ...session } = el;
                return session;
              }),
              identityId: foundUser?.identityId,
              statuses: body?.statuses?.[env],
            }) || [],
        }));
      }
    },
    [queryParam]
  );

  const logoutUserSession = useCallback(
    async (session) => {
      await logoutSingleUserSession({
        session,
        user,
        wsSessions,
        saveAddedLogs,
        dispatch,
        setLogoutOptionsModal,
      });
    },
    [user, wsSessions]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayTour(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [tourOpen]);

  const onCancel = () => {
    if (queryParam) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("sessionUserId");
      const updatedUrl = `${
        window.location.pathname
      }?${queryParams.toString()}`;
      navigate(updatedUrl);
    }
    setVisible(false);
    updateDocumentTitle(); // reset document title to "Lead Manager";
  };

  useEffect(() => {
    let foundUser = {};
    if (isEmpty(proppedUser) && queryParam) {
      foundUser = userConfiguration?.allUsers?.Items?.find(
        (el) => el.identityId === queryParam
      );
      setUser(foundUser);
      setVisible(true);
    } else if (!isEmpty(proppedUser)) {
      setUser(proppedUser);
    }
    if (wsSessions.readyState !== WebSocket.OPEN) {
      return;
    }
    try {
      wsSessions.addEventListener("message", (msg) =>
        onlineStatusesHandler(msg, foundUser)
      );
      wsSessions.send(
        JSON.stringify({ request: "get-online-statuses", body: {} })
      );
    } catch (e) {
      console.log("Error sending get-online-statuses request", e);
    }

    return () => {
      wsSessions.removeEventListener("message", onlineStatusesHandler);
    };
  }, [
    onlineStatusesHandler,
    wsSessions,
    queryParam,
    JSON.stringify(proppedUser),
  ]);

  useEffect(() => {
    async function getSessionLogs(identityId) {
      if (!identityId) return;
      await API.get("userSessions", `/userSessions`)
        .then((res) => {
          const userLogs = res.filter(
            (el) => el?.sessionInfo?.identityId === identityId
          );
          dispatch(updateReduxSession("set", res));
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (isEmpty(user) && queryParam && visible) {
      const user = userConfiguration?.allUsers?.Items?.find(
        (el) => el.identityId === queryParam
      );
      getSessionLogs(user.identityId);
    } else if (!isEmpty(user) && visible) {
      getSessionLogs(user.identityId);
    }
  }, [user?.identityId, visible]);

  //Logs user out from one session

  function askToLogout(session) {
    try {
      wsSessions.send(
        JSON.stringify({
          request: "ask-to-logout",
          body: {
            userId: identityId,
            sessionId: session.sessionId,
            askToLogout: true,
            time: logoutTime,
            config: process.env.NODE_ENV === "production" ? "prod" : "dev",
          },
        })
      );
    } catch (e) {
      console.error("Error sending ask to logout request", e);
    }
    setLogoutOptionsModal(false);
  }

  const dbSteps = useMemo(
    () =>
      userSessionModalGuide(
        programFields,
        isDarkMode,
        isSuspended,
        activeSessions
      ),
    [programFields, isDarkMode, isSuspended, activeSessions]
  );

  const hasAccess = useMemo(() => {
    return (
      userConfiguration?.routeConfig
        ?.find((el) => el.path === "/settings")
        ?.children?.find((el) => el?.title === "User Management")
        ?.children?.find((el) => el?.title === "User Access")?.write || false
    );
  }, [userConfiguration]);

  const populatedColDefs = sessionColDefs({
    setLogoutOptionsModal,
    ws,
    sessionLogsObj,
    setSessionLogsVisible,
  });

  const exportGridToExcel = (tableColumns) => {
    return gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName == "Logged In") {
          return dayjs(value)?.format("MM/DD/YYYY - hh:mm a") || "";
        }

        return value === undefined ? "" : `${value}`;
      },
    });
  };

  /**
   * ?RESET SESSIONS TO ALL USERS FUNCTION
   * import { updateAllUsers } from "./helpers";
   * updateAllUsers(userConfiguration,{activeSessions:[]});
   */

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        maskClosable: true,
        onCancel,
        title: (
          <CustomModalHeader
            {...{
              title: (
                <span className="userSessionModalHeader">
                  {nameOfUser} - Sessions
                  {ws && (
                    <div
                      className={`dot ${user?.onlineStatus ? "on" : "off"}`}
                    ></div>
                  )}
                </span>
              ),
              onClick: () => {
                setTourOpen(true);
              },
              style: { width: "98%" },
            }}
          />
        ),
        // `${nameOfUser} - Sessions`,
        afterOpenChange: (event) => {
          event &&
            updateDocumentTitle({ newTitle: `${nameOfUser} - Sessions` });
        },
        closeIcon: <XIcon />,
        className: `userSessionModal ${
          isDarkMode ? "userSessionModalDark" : ""
        }`,
      }}
    >
      <div className="userSessionModalContent">
        {hasAccess && <ControlPanel {...{ user }} />}

        <div className="userSessionsPanel">
          <div className="header">
            <div className="description">
              <p>
                <b>Session Manager</b>
              </p>
              <p>List of all devices where user is logged in.</p>
            </div>
            <ExportOrEmailDropdown
              {...{
                rowData: activeSessions.filter((el) => !!el?.sessionId),
                title: `${nameOfUser} - Sessions`,
                gridApi,
                gridColumnApi: columnApi,
                exportToExcel: (tableColumns) =>
                  exportGridToExcel(tableColumns),
              }}
            />
          </div>
          <div
            className={`sessionsGrid ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
            style={{
              overflowY: "auto",
              maxHeight: 500,
              minWidth: 900,
            }}
          >
            <AgGridReact
              columnDefs={populatedColDefs}
              rowData={activeSessions.filter((el) => !!el?.sessionId)}
              rowHeight={45}
              headerHeight={45}
              suppressDragLeaveHidesColumns={true}
              frameworkComponents={{
                SessionForceLogout,
              }}
              defaultColDef={{
                enablePivot: true,
                enableColResize: true,
                enableRowGroup: true,
                editable: false,
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
              }}
              onGridReady={onGridReady}
              sideBar={gridSidebar}
              ref={gridRef}
            />
          </div>
        </div>
      </div>
      {delayTour && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={dbSteps}
        />
      )}
      {!!logoutOptionsModal && (
        <LogoutOptions
          {...{
            logoutOptionsModal,
            setLogoutOptionsModal,
            logoutUserSession,
            nameOfUser,
            askToLogout,
            logoutTime,
            setLogoutTime,
          }}
        />
      )}
      {!!sessionLogsVisible && (
        <SessionLogsModal
          {...{
            visible: !!sessionLogsVisible,
            setVisible: setSessionLogsVisible,
            sessionId: sessionLogsVisible?.sessionId,
            title: sessionLogsVisible?.device,
          }}
        />
      )}
    </Modal>
  );
};
// };
export default UserSessionModal;
