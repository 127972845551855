import classes from "./InvoiceShower.module.scss";
import InvoiceInfoDrawer from "./InvoiceInfoDrawer";
import { useMemo, useState } from "react";
import { API } from "aws-amplify";

export default function InvoicesShower({
  setSelectedData,
  selectedData,
  setData,
  fetchAndSetInvoices,
  isEditable: proppedIsEditable,
  setRowData,
}) {
  return (
    <div className={classes.container}>
      {selectedData && (
        <InvoiceInfoDrawer
          InfoDrawer
          setData={setData}
          onClose={() => {
            setSelectedData();
          }}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          fetchAndSetInvoices={fetchAndSetInvoices}
          proppedIsEditable={proppedIsEditable}
          setRowData={setRowData}
        />
      )}
    </div>
  );
}
