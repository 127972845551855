import { defaultExcelHeaderStyle } from "../../../../../helpers/constants/defaultExcelHeaderStyle";

export const tableHeaders = ["Summary", "Organizer", "Start Date", "End Date"];

export const tableHeadersExcel = [
  { v: "Summary", s: defaultExcelHeaderStyle },
  { v: "Organizer", s: defaultExcelHeaderStyle },
  { v: "Start Date", s: defaultExcelHeaderStyle },
  { v: "End Date", s: defaultExcelHeaderStyle },
];
