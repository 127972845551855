import { months } from "../../../addWidgetsModalData";

export const charges = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  chargesData
) => {
  const data = updatedDynamicStates[IdHandler];

  const chargesStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Charge.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    categoryFrom: () => ({
      title: "Charges - Category From",
      BodyComponent: "LineChartFull",
      type: "LineChartFull",
      id: IdHandler,
      BodyComponentParams: {
        table: "Charges",
        field: "categoryFrom",
        labels: chargesStatus?.statusName,
        datasets: [
          {
            label: "Charges",
            data: chargesStatus?.statusName?.map(
              (status) =>
                chargesData?.filter((a) => a?.categoryFrom === status)?.length
            ),
            backgroundColor: chargesStatus?.statusColor,
            borderColor: chargesStatus?.statusColor,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //categoryFrom
    chargeAmount: () => ({
      title: "Charges - Charge Amount",
      BodyComponent: "LineChartFull",
      type: "LineChartFull",
      id: IdHandler,
      BodyComponentParams: {
        table: "Charges",
        field: "chargeAmount",
        labels: months,
        custom: 0,
        datasets: [
          {
            label: "Charge Amount With Tax",
            data: 0,
            backgroundColor: "#2DA365",
            borderColor: "#2DA365",
          },
          {
            label: "Total Tax",
            data: 0,
            backgroundColor: "#f04f4e",
            borderColor: "#f04f4e",
          },
          {
            label: "Charge Amount Without Tax",
            data: 0,
            backgroundColor: "#F2C94C",
            borderColor: "#F2C94C",
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //chargeAmount
  };
};
