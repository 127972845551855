import { Checkbox } from "antd";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../utils";
import { updateEditLogs } from "../../../../utils";
import { updateUserKeysUser } from "../../../../../../../../../actions";
import { ReportCheck } from "../../../../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import { useEditLogs } from "../../../../../../../../../hooks";

const accessTaskKeys = {
  accessGranted: `Access to assign task granted`,
  accessRemoved: `Access to assign task removed`,
};

const TaskAssignCheckBox = ({ canAssignTask, currentUser }) => {
  const dispatch = useDispatch();

  const { identityId, role } = useParams();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const currentRole = role || "Engineer/Architect";
  const { saveAddedLogs } = useEditLogs();

  const updateAssignTask = (value) => {
    showLoadingMsg({ content: "Updating Task Assign. Please Wait..." });

    const tasksConfig = { canAssignToOthers: value };

    API.put("userConfiguration", `/userConfiguration/${identityId}`, {
      body: { tasksConfig },
    })
      .then(async () => {
        dispatch(updateUserKeysUser({ identityId, tasksConfig }));

        await updateEditLogs({
          newValue: value,
          currentUser,
          userConfiguration,
          saveAddedLogs,
        }).catch((err) => console.log({ err }));
        showSuccessMsg({ content: "Changes saved successfully." });
      })
      .catch((error) => {
        showErrorMsg({ content: "Error saving changes." });
        console.log({ error });
      });
  };

  return (
    <div className="task-assign-checkbox" id="assignTaskButton">
      <ReportCheck
        {...{
          checked: canAssignTask,
          defaultChecked: canAssignTask,
          onChange: updateAssignTask,
          label: "Can assign task",
          className: "taskAssignCheckBox",
        }}
      />
    </div>
  );
};

export default TaskAssignCheckBox;
