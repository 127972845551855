const gridFilters = [
  {
    globalKeyId: "8fdejjjjjj8a-400f-b352-6354cb0f22ec",
    label: "Name",
    type: "Dropdown",
    key: "crewName",
  },
  {
    globalKeyId: "8f454548a-400f-b352-6354cb0f22ec",
    label: "Status",
    type: "Dropdown",
    key: "crewStatus",
  },
  {
    globalKeyId: "ace23714-e23c-45fe-83ec-0d0ddcdf0b24",
    label: "Employee ID",
    type: "Dropdown",
    key: "employeeId",
  },
  {
    globalKeyId: "8fdedbc3-0b8a-3433f-b352-6354cb0f22ec",
    label: "Role",
    type: "Dropdown",
    key: "crewPosition",
  },
];

export default gridFilters;
