import { Tooltip, message } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";

import { compareIncluding } from "../../../../../../../utils";
import createPDF, {
  PREVIEW,
} from "../../../../../../../../../integrations/createPDF";
import { documentDefinition } from "./documentDefinition";
import { LogsIcon, NotesIcon } from "../../../../../../../DynamicView/src";
import { EmailIcon, Pdf } from "../../../../../../../BasePage/src";
import {
  CheckAccessRights,
  convertMultiLevelLogsToOneLevelLogs,
  fetchAllData,
} from "../../../../../../../../../utils";
import { useRedux } from "../../../../../../../hooks/useRedux";
import { savePdfToDrive } from "../../../../../../../Safety/safetyPdf";
import { MondayButton } from "../../../../../../../../commonComponents";
import { useOtherModalContext } from "../../hoc/OtherModalsWrapperContext";
import { Notes } from "../../../../../../../../commonComponents/Notes/Notes";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { HeaderTasks } from "../../../../../../../components/HeaderTasks/HeaderTasks";
import EmailBox from "../../../../../../../Communication/components/EmailBox/EmailBox";
import { UndoRedoButtons } from "../../../../../../../../commonComponents/UndoRedoButtons/UndoRedoButtons";
import { DocumentationLogsModal } from "../../../../../../../Documentation/View/components/DocumentationModal/components";

import "./SecondaryHeader.scss";

const SecondaryHeader = () => {
  const [objectData] = useRedux("chargeItemsModal");
  const [darkMode] = useRedux("chargesDarkMode");

  const whereToFind = objectData?.whereToFind;

  const currentCharge = objectData?.currentCharge;

  const [logsVisible, setLogsVisible] = useState(false);
  const [isEmailBoxOpen, setIsEmailBoxOpen] = useState(false);
  const [editLogs, setEditLogs] = useState([]);
  const [pdfId, setPdfId] = useState("");

  const { setVisibleReport } = useOtherModalContext();

  const { base64 } = useSelector((state) => state.base64);
  const { accessToken } = useSelector((state) => state.accessToken);

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const driveRequest = driveApi({ accessToken });

  const { googleDriveFolderIds } = whereToFind || {};

  const { projectId: tempGoogleDriveFolderId } = googleDriveFolderIds || {};

  const generateChargePdfHandler = async () => {
    const pdfTitle = `Charge #${currentCharge?.chargeNumber}`;

    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;

    createPDF({
      action: PREVIEW,
      docDefinition: documentDefinition(pdfTitle, companyLogo, currentCharge),
    });

    await savePdfToDrive({
      docDefinition: documentDefinition(pdfTitle, companyLogo, currentCharge),
      accessToken,
      folderId: tempGoogleDriveFolderId,
      title: `Charge #${currentCharge?.chargeNumber}`,
    }).then((res) => {
      setPdfId(res?.id);
    });
  };

  const Icons = [
    {
      title: "Email",
      Icon: EmailIcon,
      onClick: () => setIsEmailBoxOpen((prev) => !prev),
      visible: currentCharge ? true : false,
    },
    {
      title: "View Report",
      Icon: Pdf,
      onClick: () => setVisibleReport(true),
      visible: currentCharge ? true : false,
    },
    {
      title: "Export to PDF",
      Icon: Pdf,
      onClick: () => generateChargePdfHandler(),
      visible: currentCharge ? true : false,
    },
  ]?.filter(Boolean);

  const openEditLogsHandler = () => {
    const hideLoading = message.loading(
      "Retrieving log data. Please wait...",
      0
    );

    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: currentCharge?.chargeId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((result) => {
        const newLogs = convertMultiLevelLogsToOneLevelLogs(result);
        setEditLogs(newLogs);
        setLogsVisible(true);
        hideLoading();
      })
      .catch((err) => {
        hideLoading();
        message.error(
          "Sorry, we couldn't retrieve the logs at the moment. Please try again later."
        );
      });
  };

  return (
    <>
      <div
        className={
          darkMode
            ? "secondary-header-container-dark"
            : "secondary-header-container"
        }
      >
        <div className="secondary-header-left">
          {/* <UndoRedoButtons
            {...{ canUndo, canRedo, undoClickHandler, redoClickHandler }}
          /> */}
        </div>
        <div className="secondary-header-right">
          {currentCharge && (
            <>
              <Tooltip title={"Show Logs"} className="secondary-header-logs">
                <span onClick={openEditLogsHandler}>
                  <LogsIcon
                    style={{
                      fill: "#fff",
                      borderRadius: "5px",
                      margin: "auto",
                    }}
                  />
                </span>
              </Tooltip>
              {CheckAccessRights(
                userConfiguration?.routeConfig?.find(
                  ({ title }) => title === "Notes/View"
                )?.children,
                "Charges"
              ) && (
                <div className="secondary-header-item">
                  <Notes
                    noteModalName={`${currentCharge?.projectAddress} | Charge ${currentCharge?.chargeNumber}`}
                    rowId={currentCharge.chargeId}
                    topicCategory={"Charges"}
                    customIcon={<NotesIcon />}
                  />
                </div>
              )}
              <div className="secondary-header-item">
                <HeaderTasks
                  rowData={currentCharge}
                  {...{ darkMode }}
                  taskProjectName={currentCharge?.projectAddress}
                  taskItemId={currentCharge?.chargeId}
                  taskTopic={"Charges"}
                />
              </div>
            </>
          )}
          {Icons.map(({ title, onClick, Icon, visible }, index) => {
            return (
              <MondayButton
                key={index}
                Icon={<Icon />}
                className="mondayButtonBlue"
                onClick={onClick}
                style={{ display: visible ? "block" : "none" }}
              >
                {title}
              </MondayButton>
            );
          })}
        </div>
      </div>
      {logsVisible && (
        <DocumentationLogsModal
          {...{
            logsColumnDefs: [
              { fieldName: "member", fieldTitle: "User" },
              { fieldName: "details", fieldTitle: "Action" },
              { fieldName: "updatedKey", fieldTitle: "Updated Key" },
              { fieldName: "previousData", fieldTitle: "Previous Data" },
              { fieldName: "currentData", fieldTitle: "Current Data" },
              { fieldName: "updatedAt", fieldTitle: "Date & Time" },
            ].filter(Boolean),
            visible: logsVisible,
            setVisible: setLogsVisible,
            docLogs: editLogs,
            title: "Charges Logs",
          }}
        />
      )}
      {isEmailBoxOpen && (
        <div style={{ position: "absolute", bottom: 0, right: 0, zIndex: 10 }}>
          <EmailBox
            {...{
              onClose: () => {
                if (pdfId) driveRequest.deleteDriveItem(pdfId);
                setIsEmailBoxOpen(false);
              },
              setEmails: () => {},
              rowData: currentCharge,
              onSave: (e, type, setLoading) => {
                onEmailSave({ e, type, setLoading });
              },
              //   sendEmailNotification,
              isRedirected: [pdfId],
              isFromCharge: true,
              proppedTemplateCat: "Charges",
            }}
          />
        </div>
      )}
    </>
  );
};

export default SecondaryHeader;
