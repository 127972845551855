import React, { createContext, useContext } from "react";
import { useNewCharge } from "../hooks/useNewCharge";

const NewChooseChargeContext = createContext();

export const useNewChargeContext = () => {
  return useContext(NewChooseChargeContext);
};

export const NewChooseChargeProvider = ({ children, ...props }) => {
  const newChargeStates = useNewCharge({ ...props });
  return (
    <NewChooseChargeContext.Provider value={{ ...newChargeStates }}>
      {children}
    </NewChooseChargeContext.Provider>
  );
};
