import { useState } from "react";
import { useEditLogs } from "./useEditLogs";

const useExportHeadersModal = ({
  additionalData,
  onGeneratePDF = () => {},
  exportToExcel = () => {},
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [exportType, setExportType] = useState("pdf");
  const [hidePopover, setHidePopover] = useState(false);
  const [pdfData, setPdfData] = useState({
    action: "",
    getDocDefinition: "",
  });
  const { saveAddedLogs } = useEditLogs();

  const getHeadersModal = (action, getDocDefinition, type = "pdf") => {
    setPdfData({
      action,
      getDocDefinition: getDocDefinition || "",
    });
    setExportType(type);
    setOpenModal(true);
    setHidePopover(true);
  };

  const handleCloseModal = () => {
    setHidePopover(false);
    setOpenModal(false);
  };

  const handleFinishModal = (data) => {
    setOpenModal(false);
    if (exportType === "pdf") {
      !additionalData
        ? onGeneratePDF(
            pdfData.action,
            pdfData.getDocDefinition,
            data,
            null,
            saveAddedLogs
          )
        : onGeneratePDF(
            pdfData.action,
            additionalData,
            pdfData.getDocDefinition,
            data,
            saveAddedLogs
          );
    } else {
      !additionalData
        ? exportToExcel(pdfData.getDocDefinition, data, null, saveAddedLogs)
        : exportToExcel(
            additionalData,
            pdfData.getDocDefinition,
            data,
            saveAddedLogs
          );
    }
    setHidePopover(false);
  };

  return {
    openModal,
    exportType,
    hidePopover,
    getHeadersModal,
    handleCloseModal,
    handleFinishModal,
  };
};

export default useExportHeadersModal;
