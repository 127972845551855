import React, { useState, useEffect } from "react";
import { MondayButton } from "../../../../../../commonComponents/index";
import { ClockIcon } from "../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons/index";
import { message, Modal, TimePicker, Select, Tooltip, Badge } from "antd";
import dayjs from "dayjs";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src/index";
import "./ScheduledDaysTime.scss";
import { CloseIconModal } from "../../../../../../../assets/index";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components/index";
import { useSelector } from "react-redux";
import {
  prevPliCompleted,
  swalToast,
} from "../../../SchedulingFirstPage/helperData";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { RichText } from "../../../../../../SidebarPages/DynamicView/FormItemComponents";
import { EyeIcon } from "../../../../../../SidebarPages/DynamicView/src";
import DetailsForDayModal from "./DetailsForDayModal";
import { updateDocumentTitle } from "../../../../../../../utils";
const ScheduledDaysTime = (props) => {
  const { value = {} } = props;

  const sortedDatas = Object?.fromEntries?.(
    Object?.entries?.(value || {})?.sort?.(
      ([key1, value1], [key2, value2]) =>
        dayjs(value1?.startDate).diff(dayjs(value2?.startDate)) ||
        dayjs(value1?.endDate).diff(dayjs(value2?.endDate))
    )
  );

  const calculateHours = Object.values(sortedDatas || {})?.map((el) =>
    dayjs(el?.endDate).diff(dayjs(el?.startDate), "hours")
  );
  const [openDetails, setOpenDetails] = useState({
    open: false,
    details: {},
  });

  const { programFields } = useSelector((state) => state?.programFields);
  const [openTimeModal, setOpenTimeModal] = useState(false);
  const headerData = [
    "Schedule Name",
    "Date",
    "Start Time",
    "End Time",
    "Working Hours",
    "Reason",
    "Details",
  ];
  const onChange = (e, key, type) => {
    if (
      type === "startDate" &&
      dayjs(e).isAfter(dayjs(sortedDatas[key]?.endDate))
    ) {
      message.error("Start time cannot be greater than end time!");
      return;
    }
    if (
      type === "endDate" &&
      dayjs(e).isBefore(dayjs(sortedDatas[key]?.startDate))
    ) {
      message.error("End time cannot be less than start time!");
      return;
    }
    const newData = { ...sortedDatas };
    const item = newData[key];
    item[type] = e;
    item.hours =
      dayjs(item?.endDate).diff(dayjs(item?.startDate), "hours") >= 9
        ? dayjs(item?.endDate).diff(dayjs(item?.startDate), "hours") - 1
        : dayjs(item?.endDate).diff(dayjs(item?.startDate), "hours");
    props?.node?.setDataValue("usedScheduleDays", newData);
  };

  const crewReasons = programFields
    ?.find?.(
      ({ fieldId }) => fieldId === "d1cba15e-a680-4fa4-beef-53e90e9cca8f"
    )
    ?.fieldOptions?.map(({ labelName }) => labelName);

  return (
    <>
      {!props?.node?.group ? (
        <MondayButton
          onClick={(e) => {
            e.preventDefault();
            if (Object.keys(sortedDatas || {})?.length === 0) {
              swalToast({
                icon: "error",
                title: "Please select a day first!",
                position: "bottom-end",
                showConfirmButton: false,
                timer: 4000,
              });
            } else {
              setOpenTimeModal(true);
            }
          }}
          {...{
            className: "mondayButtonBlue",
            containerClassName: "scheduled-days-time-button-wrapper",
            // Icon: <ClockIcon />,
            hasIcon: false,
            buttonStyle: {
              height: 26,
            },
          }}
        >
          {Object.keys(sortedDatas || {})?.length > 0
            ? Object?.values(sortedDatas)?.reduce?.(
                (a, b, index) =>
                  a +
                  (calculateHours?.[index] >= 9
                    ? calculateHours?.[index] - 1
                    : calculateHours?.[index]),
                0
              ) + " hours" || 0 + " hours"
            : 0 + " hours"}
        </MondayButton>
      ) : (
        ""
      )}
      <div>
        <Modal
          title="Employees time breakdown"
          afterOpenChange={(event) => {
            event &&
              updateDocumentTitle({ newTitle: "Employees time breakdown" });
          }}
          className={`employees-time-pli ${
            props?.context?.isDarkMode && "employees-time-pli-dark"
          }`}
          open={openTimeModal}
          centered
          footer={null}
          onCancel={() => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            setOpenTimeModal(false);
          }}
          width={1300}
          height="fit-content"
          closeIcon={<XIcon />}
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginBottom: 14,
              }}
            >
              <table style={{ width: "100%" }}>
                <tr>
                  {headerData?.map?.((el) => (
                    <th>{el}</th>
                  ))}
                </tr>
                {Object.entries(sortedDatas || {})?.map?.(
                  ([key, value], index) => {
                    const hours =
                      calculateHours[index] >= 9
                        ? calculateHours[index] - 1
                        : calculateHours[index];
                    return (
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {value?.scheduleName || ""}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {dayjs(value?.startDate).format("MM/DD/YYYY")}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <TimePicker
                            format={"HH:mm"}
                            value={dayjsNY(value?.startDate)}
                            disabled={prevPliCompleted(props?.data)}
                            onChange={(e) => onChange(e, key, "startDate")}
                            popupClassName={
                              props?.context?.isDarkMode && "darkDateDropDown"
                            }
                            inputReadOnly={true}
                            showNow={false}
                            style={{
                              background: "#f8f8fa",
                              borderRadius: 8,
                              width: 160,
                              border: "none",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <TimePicker
                            format={"HH:mm"}
                            value={dayjsNY(value?.endDate)}
                            onChange={(e) => onChange(e, key, "endDate")}
                            popupClassName={
                              props?.context?.isDarkMode && "darkDateDropDown"
                            }
                            disabled={prevPliCompleted(props?.data)}
                            inputReadOnly={true}
                            showNow={false}
                            style={{
                              background: "#f8f8fa",
                              borderRadius: 8,
                              width: 160,
                              border: "none",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Tooltip
                            trigger="hover"
                            title={
                              hours <= 8
                                ? ""
                                : hours -
                                  8 +
                                  " " +
                                  `${hours - 8 === 1 ? "hour" : "hours"}` +
                                  " overtime "
                            }
                          >
                            {hours <= 8 ? (
                              <span>
                                {hours +
                                  " " +
                                  `${hours === 1 ? "hour" : "hours"}`}
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {hours + " " + "hours"}
                              </span>
                            )}
                          </Tooltip>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <InputComponent
                            type="select"
                            placeholder="Select reason"
                            dropdownClassName={
                              props?.context?.isDarkMode && "darkDropDown"
                            }
                            initialValue={value?.reason}
                            onSelect={(e) => onChange(e, key, "reason")}
                            disabled={prevPliCompleted(props?.data)}
                            customOptions={crewReasons.map((el, key) => ({
                              key,
                              label: el,
                              value: el,
                            }))}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Badge
                            color="green"
                            count={!!value?.description ? "+1" : null}
                          >
                            <MondayButton
                              onClick={() => {
                                setOpenDetails({
                                  open: true,
                                  details: { ...value, key },
                                });
                              }}
                              {...{
                                className: "mondayButtonBlue",
                                Icon: <EyeIcon />,
                              }}
                            >
                              Other Details
                            </MondayButton>
                          </Badge>
                        </td>
                      </tr>
                    );
                  }
                )}
              </table>
            </div>
          </>
        </Modal>
        {!!openDetails?.open && (
          <DetailsForDayModal
            {...{
              openDetails,
              setOpenDetails,
              onChange,
              isDarkMode: props?.context?.isDarkMode,
            }}
          />
        )}
      </div>
    </>
  );
};

export default ScheduledDaysTime;
