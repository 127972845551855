import { Button, Input } from "antd"
import { useState } from "react"

const PriceSchemeSelectorDropdown = ({ menu, onPriceSchemeCreation }) => {
  const [newCategoryName, setNewCategoryName] = useState("")

  const onEnter = () => {
    onPriceSchemeCreation(newCategoryName)
    setNewCategoryName("")
  }

  return (
    <>
      {menu}
      <div className="new-price-scheme-section">
        <Input
          placeholder="New price scheme..."
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
          onPressEnter={onEnter}
        />
        <Button onClick={onEnter}>Create</Button>
      </div>
    </>
  )
}

export default PriceSchemeSelectorDropdown
