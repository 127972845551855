import React from "react";
import { Button } from "antd";
import { NormalSizedModal } from "../../../../../../../../components";
import "./MaintenanceRequestModal.scss";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { TickIcon } from "../../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../../../../../Communication/assets";

const MaintenanceRequestModal = ({
  visible,
  setVisible,
  setMaintenanceModalVisible,
  span,
  isDarkMode,
}) => {
  const onConfirm = () => {
    setMaintenanceModalVisible(true);
    setVisible(false);
  };

  const onCancel = () => setVisible(false);
  return (
    <NormalSizedModal
      visible={visible}
      destroyOnClose
      title="Maintenance Request"
      className={`maintenanceRequestModalContainer ${
        isDarkMode && "maintenanceRequestModalContainerDark"
      }`}
      zIndex={1200}
      centered
    >
      <div>
        <div className="question">{span}</div>
        <div className="buttonContainer">
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={onCancel}
          >
            No
          </MondayButton>
          <MondayButton
            Icon={<TickIcon width={17} height={17} />}
            onClick={onConfirm}
          >
            Yes
          </MondayButton>
        </div>
      </div>
    </NormalSizedModal>
  );
};

export default MaintenanceRequestModal;
