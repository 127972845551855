import { dayjs } from "dayjs";

import getDateRange from "./getDateRange";
import findPolygonRange from "./findPolygonRange";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";
import { getPayrollOnDateRange } from "../../../FingerCheckConfig/fingercheckFunctions";
import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

/**
 * @typedef params
 * @property {Array} crews List of employees
 * @property {Array} jobsites List of jobsites that employees work on
 * @property {dayjs.Dayjs[]} dateRange Range of 2 dates that set the range of data call
 * @property {string} accountName Subcontractor company name
 * @property {React.JSX.Element} progressRef Reference of the progress component
 *
 * @param {params} params
 * @returns Array with employee payroll actions.
 */
async function matchDataFromFingerCheck({
  crews,
  jobsites,
  crewTeams,
  dateRange,
  selectedClient,
  progressRef,
}) {
  progressRef.setPercentage(0);
  const accountName = selectedClient?.clientName;
  let result = [];
  let stopFunction = false;
  const allDaysIncluded = getDateRange(dateRange[0], dateRange[1]);
  const estimatedTime = allDaysIncluded.length * 0.5;
  const company = `${accountName || ""}`;
  let arrayOfTimeouts = [];

  function stopFunc() {
    stopFunction = true;
  }

  cancelButton?.addEventListener("click", stopFunc);

  for (let i = 0; i < allDaysIncluded.length; i++) {
    const percentage = ((0.5 * i) / estimatedTime) * 100;
    const addProgress = setTimeout(
      () => progressRef.setPercentage(percentage),
      500 * i + 1
    );
    arrayOfTimeouts.push(addProgress);
    if (stopFunction) {
      break;
    }
  }

  const cardRawData = await getPayrollOnDateRange({
    dateRange,
    clientKey: selectedClient.clientKey,
  }).then((res) => res.data);

  let sortedCardRawData = [];
  const groupedCardRawData = _.groupBy(
    cardRawData.map((el) => ({
      ...el,
      punchTimeStamp: parseInTz(el?.punchTime).valueOf(),
    })),
    ({ EmployeeNumber }) => `${accountName}-${parseFloat(EmployeeNumber)}`
  );

  for (const employeeId in groupedCardRawData) {
    const employeeData = groupedCardRawData[employeeId].sort(
      (a, b) => a.punchTimeStamp - b.punchTimeStamp
    );
    sortedCardRawData = sortedCardRawData.concat(employeeData);
  }

  for (let i = 0; i < sortedCardRawData?.length; i++) {
    if (stopFunction) {
      break;
    }
    const entry = sortedCardRawData[i];
    const employeeId = `${accountName}-${parseFloat(entry?.employeeId)}`;

    const punchTime = entry?.punchTime && parseInTz(entry?.punchTime);
    const punchDate = entry?.punchDate && parseInTz(entry?.punchDate);

    const employeeMatch = crews.find(
      (emp) =>
        emp?.employeeId === employeeId ||
        emp?.employeeId === `${accountName}-${entry?.employeeId}`
    );

    const teamMatch = crewTeams.find((team) => {
      if (employeeMatch?.foreman === true) {
        return team?.crewForeman?.crewId === employeeMatch?.crewId;
      } else {
        return (
          (team?.crewMembers || []).findIndex(
            (member) => member?.crewId === employeeMatch?.crewId
          ) > -1
        );
      }
    });

    const jobsiteMatch = jobsites
      .flatMap((job) => {
        const {
          geoFenceInfo: newInfo = [],
          geofenceInfo: oldInfo = [],
          locationRadius,
          addressPosition,
        } = job;
        let range;
        const geoInfo = newInfo || [{ geoFenceInfo: oldInfo }];

        // Find closest jobsite from geofence
        if (entry.punchCoordinates?.lat) {
          if (!!geoInfo?.length) {
            range = findPolygonRange({
              points: geoInfo[0]?.geoFenceInfo,
              position: entry.punchCoordinates,
              radius: +locationRadius,
            });
          } else {
            // when there is no geofence get the closest jobsite from jobsite position
            const distanceFromJob = withinRadius(
              addressPosition,
              entry.punchCoordinates,
              locationRadius
            );
            range = {
              inRange: distanceFromJob.withinRange,
              distanceInFeet: distanceFromJob.distanceInFeet,
            };
          }
        }

        if (range?.inRange) {
          return { ...job, distance: range.distanceInFeet };
        } else {
          return [];
        }
      })
      .sort((a, b) => a.distance - b.distance)?.[0];

    const newEntry = {
      ...entry,
      employeeId: employeeMatch?.employeeId,
      employeeNumber: entry?.employeeId,
      payrollType: jobsiteMatch?.payrollType,
      crewId: employeeMatch?.crewId,
      companyName: company,
      company,
      punchDate,
      punchTime,
      punchTimeStamp: punchTime?.valueOf(),
      employeeRate:
        jobsiteMatch?.payrollType !== "Prevailing Wage"
          ? parseFloat(employeeMatch?.employeeRate || 0)
          : !isNaN(
              parseFloat(jobsiteMatch?.rates?.[employeeMatch?.crewPosition])
            )
          ? parseFloat(jobsiteMatch?.rates?.[employeeMatch?.crewPosition])
          : parseFloat(employeeMatch?.employeeRate || 0),
      employeeFullName: employeeMatch?.crewName || entry?.employeeFullName,
      activityStatus: "Draft",
      salaryType: employeeMatch?.salaryType || "Hourly",
      reason: entry?.reason || "",
      employeeRole: employeeMatch?.crewPosition,
      crewTeamName: teamMatch?.crewTeamName,
      crewTeamId: teamMatch?.crewTeamId,
      employeeType: {
        jobsiteMatch: {
          jobName: jobsiteMatch?.jobName || "",
          payrollType: jobsiteMatch?.payrollType || "",
        },
        role: employeeMatch?.crewPosition,
      },
      jobsiteId: jobsiteMatch?.jobsiteId || "",
      jobsiteMatch: {
        jobAddress: jobsiteMatch?.jobAddress || "",
        jobName: jobsiteMatch?.jobName || "",
        jobsiteId: jobsiteMatch?.jobsiteId || "",
        googleSheetLink: jobsiteMatch?.googleSheetLink || "",
        services: jobsiteMatch?.services || [],
        reimbursement: !!jobsiteMatch?.reimbursement,
      },
      uploadName: "Imported from FingerCheck",
    };

    result.push(newEntry);
  }
  for (const timeout of arrayOfTimeouts) {
    clearTimeout(timeout);
  }

  if (stopFunction) {
    progressRef.setPercentage(0);
    return "Stopped";
  } else {
    progressRef.setPercentage(100);
    return result;
  }
}

export default matchDataFromFingerCheck;
