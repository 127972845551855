import { useState, useCallback } from "react";

export default function useCustomTooltip() {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    handleMouseEnter,
    handleMouseLeave,
  };
}
