import { useState } from "react";
import { useSelector } from "react-redux";

import UserCard from "./UserCard";
import UserSearch from "./UserSearch";
import { LogsButton } from "../../../components/commonComponents";

const UserList = () => {
  const [usersFilterValue, setUsersFilterValue] = useState("");

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const allUsers = userConfiguration?.allUsers?.Items || [];

  const filteredUsers = allUsers
    .filter(
      (userToFilter) =>
        !userToFilter?.isSuspended &&
        userToFilter?.nameOfUser
          ?.toLowerCase()
          ?.includes(usersFilterValue?.toLowerCase())
    )
    .sort((a, b) => a?.nameOfUser?.localeCompare(b?.nameOfUser));

  return (
    <div className="LatestUserList">
      <p className="informationText">
        <span className="informationIcon">i</span> This is a list of the users
        on our portal.
      </p>
      <div className="latestUsersSearch">
        <UserSearch
          {...{
            usersFilterValue,
            setUsersFilterValue,
          }}
        />
        <LogsButton
          {...{
            logsTitle: "New User Logs",
            filtersObj: { category: "Users" },
          }}
        />
      </div>
      <div className="userList">
        {filteredUsers.map((user) => (
          <UserCard key={user.identityId} {...{ user, allUsers }} />
        ))}
      </div>
    </div>
  );
};

export default UserList;
