import { convertMinutesToSeconds } from "../../utils/convertMinutesToSeconds";
import "./Transcription.scss";

const Transcription = ({ video, handleTextClick, currentTime }) => {
  const timeDuration = convertMinutesToSeconds(video.lengthVideoInMin);
  const newText = video.transcribedText;

  const splitedText = newText
    ?.split(/[\.]/)
    .filter((item) => item.trimStart().length > 0);

  const segmentDuration = timeDuration / splitedText.length;

  return (
    <div className="video-transcription">
      {splitedText?.map((item, index) => {
        const startTime = index * segmentDuration;
        const endTime = (index + 1) * segmentDuration;
        const isActive = currentTime >= startTime && currentTime <= endTime;

        return (
          <div
            key={index}
            className="section"
            style={{
              marginBottom: "10px",
              padding: "5px",
              backgroundColor: isActive ? "#F1F5FD" : "transparent",
            }}
          >
            <span
              style={{
                padding: "4px 2px",
                borderRadius: "5px",
                color: isActive ? "#1264A3" : "#323338",
                cursor: "pointer",
              }}
              onClick={() => handleTextClick(startTime)}
            >
              {item.trimStart()}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Transcription;
