import { Badge, Button, Dropdown, Flex, Skeleton, Spin, Tooltip } from "antd";
import { useState } from "react";
import { useEmailBox } from "../../../../providers/EmailBoxProvider";
import { useSelector } from "react-redux";
import { useDynamicFields } from "../../../../providers/DynamicFieldsProvider";
import { DriveIcon } from "../../../../../../assets";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { getLength } from "../../../../../../functions";
import { blobToBase64 } from "../../../../../../../utils";
import { Buffer } from "buffer";
import { useInitialEmailBoxProps } from "../../../../providers/InitialEmailBoxProvider";
import { useEmailTemplates } from "../../../../providers/EmailTemplatesProvider";

import "./drive-items.scss";
import CustomDropdown from "../../../custom-dropdown/CustomDropdown";
import { useAttachments } from "../../../../providers/AttachmentsProvider";
import ChooseFilesModal from "./drive-items-modal/choose-files-modal/ChooseFilesModal";
import { useDriveDocuments } from "../../../../providers/DriveDocumentsProvider";
import useDynamicFieldsData from "../../../../hooks/useDynamicFieldsData";
import { useFilePreviewModal } from "../../../../providers/FilePreviewModalProvider";

function DriveItems({ rowData, recordDetails }) {
  const [chooseFilesVisible, setChooseFilesVisible] = useState(false);
  const [selectedDocumentationField, setSelectedDocumentationField] = useState({
    folderName: "",
    folderId: "",
  });

  const { setFilePreview } = useFilePreviewModal();
  const { driveDocuments, setDriveDocuments } = useDriveDocuments();
  const { setAttachments } = useAttachments();
  const { loading: temlatesLoading } = useEmailTemplates();
  const { dynamicFields, driveItems, setDriveItems, loading } =
    useDynamicFieldsData(rowData, recordDetails);

  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const items = dynamicFields
    ?.filter(
      ({ dynamicFieldRenderFor }) => dynamicFieldRenderFor === "documentation"
    )
    .map(({ dynamicFieldLabel, dynamicFieldValue }) => ({
      key: dynamicFieldLabel,
      label: (
        <Tooltip title={dynamicFieldLabel} placement="left">
          <Button
            type="link"
            className="dropdown-item-button"
            onClick={() => {
              setChooseFilesVisible(true);
              setSelectedDocumentationField({
                folderName:
                  recordDetails?.categoryType !== "inspections"
                    ? dynamicFieldLabel
                    : rowData?.inspectionId,
                folderId:
                  recordDetails?.categoryType !== "inspections"
                    ? rowData?.googleDriveFolderIds[dynamicFieldValue]
                    : rowData[dynamicFieldValue],
              });
            }}
            data-testid="dropdown-item-label"
          >
            {dynamicFieldLabel}
          </Button>
        </Tooltip>
      ),
    }));

  const toolTipData =
    driveDocuments?.length > 0
      ? driveDocuments?.map(({ name, blob, type, id }) => (
          <p
            key={id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedFile({
                src: blob,
                mimeType: type,
              });
              setFilePreview(true);
            }}
            data-testid={`drive-document-item-${id}`}
          >
            {name}
          </p>
        ))
      : "Drive";

  if (temlatesLoading)
    return (
      <div className="drive-items-loading-container">
        <Badge status="processing" className="loading-badge" />
        <DriveIcon className="drive-icon" />
      </div>
    );

  return (
    <>
      <Tooltip
        placement="leftBottom"
        title={toolTipData}
        data-testid="drive-documents-tooltip"
      >
        <Badge count={driveDocuments?.length}>
          {loading ? (
            <DriveIcon />
          ) : (
            <CustomDropdown items={items} placement="top" trigger={["click"]}>
              <a
                onClick={(e) => e.preventDefault()}
                data-testid="drive-items-dropdown-trigger"
              >
                <DriveIcon className="drive-icon" />
              </a>
            </CustomDropdown>
          )}
        </Badge>
      </Tooltip>

      {chooseFilesVisible && (
        <ChooseFilesModal
          setVisible={setChooseFilesVisible}
          files={driveItems}
          setFiles={setDriveItems}
          type="drive"
          onCancel={() =>
            setSelectedDocumentationField({ folderName: "", folderId: "" })
          }
          folderName={selectedDocumentationField?.folderName}
          folderId={selectedDocumentationField?.folderId}
          getSelected={async (e, type) => {
            let elements = e.map(({ id, name }) => {
              let src;
              return driveRequest.getDriveItem(id, { alt: "media" }).then((r) =>
                r.blob().then(async (e) => {
                  await blobToBase64(e).then((res) => (src = res));
                  return {
                    name: name,
                    blob: src,
                    size: getLength(
                      Buffer.from(src.substring(src.indexOf(",") + 1))
                    ),
                    type: e.type,
                    id: id,
                    status: 400,
                  };
                })
              );
            });

            let finalElements = await Promise.all(elements).then((res) => res);

            if (finalElements.length > 0) {
              setAttachments((prev) => {
                return [...prev, ...finalElements];
              });
            }

            setDriveDocuments((prev) => {
              const fileIds = new Set(prev.map(({ id }) => id));

              const uniqueFiles = finalElements.filter(
                ({ type, id }) => type !== "Folder" && !fileIds.has(id)
              );

              return [...prev, ...uniqueFiles];
            });

            setChooseFilesVisible(false);
            setSelectedDocumentationField({ folderName: "", folderId: "" });
          }}
        />
      )}
    </>
  );
}

export default DriveItems;
