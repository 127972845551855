import { useCallback, useEffect, useMemo, useState } from "react";
import { InputComponent } from "../../SidebarPages/Fleet/components";
import { InfoText } from "../CustomModalHeader/CustomModalHeader";
import MondayButton from "../MondayButton/MondayButton";
import { TickIcon } from "../../InternalChat/assets/icons";
import "./SaveExportPreview.scss";
import { PinIcon } from "../../../assets";
import { useSelector } from "react-redux";
import { TrashIcon } from "../../SidebarPages/Communication/assets";
import { useDispatch } from "react-redux";
import { createTemplateActionHandler } from "./helpers/handleTemplateActions";

function SaveExportPreview({
  filters = {},
  templates = [],
  setTemplates = () => {},
  keyName,
  selectedTemplate,
  setSelectedTemplate = () => {},
}) {
  const { programFields } = useSelector((state) => state.programFields);
  const dispatch = useDispatch();
  const exportTemplateField = useMemo(
    () =>
      programFields.find(
        (el) => el?.fieldName === "Pdf Export Filter Templates"
      ),
    [programFields]
  );
  const [clicked, setClicked] = useState(selectedTemplate);
  const [templateName, setTemplateName] = useState("");

  const handleTemplateAction = useCallback(
    createTemplateActionHandler({
      templates,
      exportTemplateField,
      keyName,
      dispatch,
      setTemplates,
      setSelectedTemplate,
      setTemplateName,
      selectedTemplate,
      setClicked,
    }),
    [templates, keyName, JSON.stringify(selectedTemplate)]
  );

  useEffect(() => {
    setClicked(selectedTemplate);
  }, [JSON.stringify(selectedTemplate)]);

  return (
    <div className="tab-container save-export-preview">
      <div className="content">
        <InfoText
          {...{
            text: "Save the current filters as a template, or load a previously saved template.",
          }}
        />
        <div className="add-new-container">
          <InputComponent
            {...{
              value: templateName,
              onChange: (e) => setTemplateName(e.target.value),
              placeholder: "Template name",
              noFormItem: true,
            }}
          />
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: () => {
                handleTemplateAction("create", templateName, filters);
              },
              disabled: !templateName,
            }}
          >
            Create Template
          </MondayButton>
        </div>

        <div className="templates-container">
          {templates.map((template, index) => {
            const { name, isDefault } = template;
            return (
              <div
                key={index}
                onClick={() => {
                  setClicked(template);
                }}
                className={`template ${
                  clicked?.name === name ? "selected" : ""
                } ${isDefault ? "default" : ""}`}
              >
                <div className="template-name">{name}</div>
                {isDefault && <PinIcon />}
              </div>
            );
          })}
        </div>
      </div>

      {!!clicked && (
        <div className="save-export-footer">
          <MondayButton
            {...{
              onClick: () => {
                handleTemplateAction("delete", clicked.name);
              },
              Icon: <TrashIcon />,
              className: "mondayButtonRed",
            }}
          >
            Delete
          </MondayButton>
          <MondayButton
            {...{
              onClick: () => {
                clicked?.isDefault
                  ? handleTemplateAction("makeDefault", clicked.name, {}, false)
                  : handleTemplateAction("makeDefault", clicked.name, {}, true);
              },
              Icon: <PinIcon />,
              className: "mondayButtonGrey",
            }}
          >
            {!clicked?.isDefault ? "Set as default" : "Remove as default"}
          </MondayButton>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => {
                setSelectedTemplate(clicked);
              },
              Icon: <TickIcon />,
              disabled: clicked?.name === selectedTemplate?.name,
            }}
          >
            Apply
          </MondayButton>
        </div>
      )}
    </div>
  );
}

export default SaveExportPreview;
