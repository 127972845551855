/**
 * Removes the shifted day from the selected schedule.
 *
 * @param {string} id - The ID of the shifted day to be removed.
 * @param {object} selectedSchedule - The selected schedule object.
 * @returns {object} - The updated selected schedule object with the shifted day removed.
 */
function onRemoveTheShiftedDay(id = "", selectedSchedule = {}) {
  let linkedDays = selectedSchedule?.scheduleDays?.filter(
    (day) => day?.linkedDays?.postponedTo === id
  );

  return {
    ...selectedSchedule,
    scheduleDays: selectedSchedule?.scheduleDays?.filter(
      (el) => !linkedDays?.some((day) => day?.id === el?.id)
    ),
  };
}

export default onRemoveTheShiftedDay;
