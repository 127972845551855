import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SmallLoadableComp } from "src/components/Sidebars/components";
import { formatAutomationResponses } from "../../../../../../Header/components/HeaderAutomations/utils";
import AutomationResponses from "../../../../../../Header/components/HeaderAutomations/components/AutomationResponses/AutomationResponses";
import { findFieldOptionByNameOrId } from "../../../../../../../utils";

const UserAutomationExecutions = () => {
  const {
    userConfiguration: { userName },
  } = useSelector((state) => state.userConfig);

  const postEventsAutomations = findFieldOptionByNameOrId({
    fieldName: "Post Events Automations Configurations",
  });

  const [myResponeses, setMyResponses] = useState();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    API.get("automations", "/automations")
      .then((res) => {
        const allCronLogs = [...res, ...postEventsAutomations].flatMap(
          (automation) => formatAutomationResponses(automation)
        );

        const myAutomationResponses = allCronLogs
          .filter(({ toUsers = [] }) => toUsers.includes(userName))
          .sort((a, b) => b.responseDate - a.responseDate);

        setMyResponses(myAutomationResponses);
      })
      .catch((err) => {
        console.log({ err });
        setMyResponses([]);
      });
  }, []);

  return (
    <SmallLoadableComp loading={!Array.isArray(myResponeses)}>
      <AutomationResponses
        {...{
          responses: myResponeses,
          clickedIndex: showDetails,
          setClickedIndex: setShowDetails,
        }}
      />
    </SmallLoadableComp>
  );
};

export default UserAutomationExecutions;
