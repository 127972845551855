import {
  useState,
  useEffect,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";

import { FleetsLiveContext } from "../../../../utils";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";

const DriverEditor = forwardRef((props, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { drivers } = useContext(FleetsLiveContext);

  const [selectedValue, setSelectedValue] = useState(props?.value);

  const { value, context, stopEditing, data } = props;

  useEffect(() => {
    if (!context.isWritable) {
      stopEditing();
    }
  }, [context.isWritable]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return selectedValue;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    [selectedValue]
  );

  function selectHandler(_, option) {
    if (option.type === "USER") {
      const driver = drivers.find(
        ({ driverSub }) => driverSub === option.value
      );
      setSelectedValue({
        name: option.label,
        driverId: driver?.driverId,
        userId: option.value,
      });
    } else {
      let user;
      if (option?.driverSub) {
        user = (userConfiguration?.allUsers?.Items || []).find(
          ({ cognitoUserId }) => cognitoUserId === option?.driverSub
        );
      }

      setSelectedValue({
        name: user?.nameOfUser || option.label,
        driverId: option.value,
        userId: user?.cognitoUserId,
      });
    }

    setTimeout(() => {
      stopEditing();
    }, 0);
  }

  return (
    <InputComponent
      {...{
        label: "",
        type: "autocomplete",
        className: "job-type-editor-field",
        dropdownClassName: isDarkMode ? "darkDropDown" : "",
        onSelect: selectHandler,
        onClear() {
          setSelectedValue("");
        },
        defaultValue: value?.driverName,
        customOptions: [
          data?.assignedDriver?.name && {
            label: "Assigned Driver",
            options: [
              {
                type: "DRIVER",
                value: data?.assignedDriver?.driverId,
                label: data?.assignedDriver?.name,
              },
            ],
          },
          {
            label: "Drivers",
            options: drivers.map(({ driverName, driverId, driverSub }) => ({
              driverSub,
              type: "DRIVER",
              value: driverId,
              label: driverName,
            })),
          },
          {
            label: "Users",
            options: (userConfiguration?.allUsers?.Items || []).map(
              ({ nameOfUser, cognitoUserId }) => ({
                type: "USER",
                label: nameOfUser,
                value: cognitoUserId,
              })
            ),
          },
        ].filter(Boolean),
        placeholder: "Driver...",
        allowClear: true,
        showSearch: true,
        required: false,
        noFormItem: true,
        defaultOpen: true,
        style: { width: "100%" },
        autoFocus: true,
        customAutocompleteFilterOption(input, option) {
          let condition = (option?.label || "")
            ?.toLowerCase()
            .includes(input.toLowerCase());

          return condition;
        },
        onBlur() {
          stopEditing();
        },
      }}
    />
  );
});

export default DriverEditor;
