import types from "../types";

const initialState = {
  notificationSettings: [],
};

const notificationSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: payload,
      };
    default:
      return state;
  }
};

export default notificationSettingsReducer;
