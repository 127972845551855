import Pie, { MemoizedPie } from "../../cardComponent/Pie/Pie";
import BarChart from "../../cardComponent/BarChart/BarChart";
import CircleProgresBar from "../../cardComponent/ProgressBar/CircleProgressBar";
import StreightProgressBar from "../../cardComponent/ProgressBar/StreightProgressBar";
import LongLineSteper from "../../cardComponent/LongLineSteper/LongLineSteper";
import LineChart from "../../cardComponent/LineChart/LineChart";

import {
  Information,
  Address,
  Progress,
  LongText,
  Live,
  Members,
  List,
  MultiBox,
  Step,
  Small,
  AbsoluteStepper,
  DateRange,
  Transfer,
  ProgressBar,
  TeamsCard,
  LineChartFull,
} from "../../cardComponent";

export const cardViewComps = {
  Pie,
  BarChart,
  CircleProgresBar,
  StreightProgressBar,
  LongLineSteper,
  LineChart,
  Information,
  Address,
  Progress,
  LongText,
  Live,
  Members,
  List,
  MultiBox,
  Step,
  Small,
  AbsoluteStepper,
  DateRange,
  Transfer,
  ProgressBar,
  TeamsCard,
  LineChartFull,
};

export const cardViewTypes = {
  Pie: "Pie",
  BarChart: "BarChart",
  CircleProgresBar: "CircleProgresBar",
  StreightProgressBar: "StreightProgressBar",
  LongLineSteper: "LongLineSteper",
  LineChart: "LineChart",
  Information: "Information",
  Address: "Address",
  Progress: "Progress",
  LongText: "LongText",
  Live: "Live",
  Members: "Members",
  List: "List",
  MultiBox: "MultiBox",
  Step: "Step",
  Small: "Small",
  AbsoluteStepper: "AbsoluteStepper",
  DateRange: "DateRange",
  Transfer: "Transfer",
  ProgressBar: "ProgressBar",
  TeamsCard: "TeamsCard",
  EstimationProgressOverview: "EstimationProgressOverview",
  LineChartFull: "LineChartFull",
};
