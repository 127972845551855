import { useState, useEffect } from "react";
import { Input } from "antd";
import { useRedux } from "../../../../../../../hooks/useRedux";
import { validateThenSavePayment } from "../../utils/checkers";
import { PaymentAmounts } from "./components/";
import PaymentAmountWarning from "./components/PaymentAmountWarning/PaymentAmountWarning";
import "./PaymentDetails.scss";

const { TextArea } = Input;

const PaymentDetails = ({ paymentData }) => {
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable] = useRedux("paymentIsWritable", false);
  const [memo, setMemo] = useState(fakePayment.paymentMemo || "");

  useEffect(() => {
    if (fakePayment) setMemo(fakePayment.paymentMemo);
  }, [fakePayment?.paymentMemo]);

  return (
    <div className="payment-details-container">
      <TextArea
        className="payment-input-memo"
        placeholder="Add your memo here..."
        style={{ resize: "none" }}
        value={memo}
        onChange={(e) => {
          setMemo(e.target.value);
        }}
        onBlur={(e) =>
          setFakePayment(
            validateThenSavePayment({
              ...fakePayment,
              paymentMemo: e.target.value,
            })
          )
        }
        disabled={!isWritable && paymentData}
      />
      <div className="payment-amounts-warning">
        <PaymentAmountWarning />
      </div>
      <div className="payment-amounts-container">
        <PaymentAmounts />
      </div>
    </div>
  );
};

export default PaymentDetails;
