import { formatCurrency } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import { getMonthData, getSums, groupBy } from "../../calculatingFunctions";

export const charges = ({ tables, programFields }) => {
  const chargesStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Charge.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = chargesStatus?.statusName?.map((status) =>
    tables?.charges?.filter((a) => a?.categoryFrom === status)
  );

  const data = getMonthData(tables.Charges, "chargeId")?.map(({ id }) => {
    return id?.map((el) => {
      return tables?.charges?.find(({ chargeId }) => chargeId === el);
    });
  });
  return {
    categoryFrom: () => [
      {
        labels: chargesStatus?.statusName,
        data: statusData?.map((el) => el?.length),
        backgroundColor: chargesStatus?.statusColor,
        custom:
          !!statusData[0] &&
          groupBy(statusData, "projectAddress")?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Charge Amount With Tax": formatCurrency(
                    getSums({ data: statusData, key: "chargeAmount" })[index] ||
                      0
                  ),
                  "Total Tax": formatCurrency(
                    getSums({ data: statusData, key: "taxAmount" })[index]
                  ),
                  "Charge Amount Without Tax": formatCurrency(
                    (getSums({ data: statusData, key: "chargeAmount" })[
                      index
                    ] || 0) -
                      (getSums({ data: statusData, key: "taxAmount" })[index] ||
                        0)
                  ),
                },
              },
              ...Object.entries(el).map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Charge Amount With Tax": formatCurrency(
                      getSums({ data: value, key: "chargeAmount" }) || 0
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "taxAmount" }) || 0
                    ),
                    "Charge Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "chargeAmount" }) -
                        getSums({ data: value, key: "taxAmount" })
                    ),
                  },
                };
              }),
            ];
          }),
      },
    ],
    chargeAmount: () => [
      {
        labels: months,
        data: !!tables.Charges
          ? data?.map((el) => {
              return el
                ?.reduce((acc, curr) => {
                  return acc + (Number(curr?.chargeAmount) || 0);
                }, 0)
                ?.toFixed(2);
            })
          : [undefined],
        backgroundColor: "#2DA365",
        custom: groupBy(data, "projectAddress")?.map((el, index) => {
          return Object?.entries(el)?.map(([key, value]) => {
            return {
              [key]: {
                projectId: value?.[0]?.projectId,
                "Charge Amount With Tax": formatCurrency(
                  getSums({ data: value, key: "chargeAmount" }) || 0
                ),
                "Total Tax": formatCurrency(
                  getSums({ data: value, key: "taxAmount" }) || 0
                ),
                "Charge Amount Without Tax": formatCurrency(
                  getSums({ data: value, key: "chargeAmount" }) -
                    getSums({ data: value, key: "taxAmount" })
                ),
              },
            };
          });
        }),
      },
      {
        labels: months,
        data: !!tables.Charges
          ? data?.map((el) => {
              return el
                ?.reduce((acc, curr) => {
                  return acc + (Number(curr?.taxAmount) || 0);
                }, 0)
                ?.toFixed(2);
            })
          : [undefined],
        backgroundColor: "#f04f4e",
      },
      {
        labels: months,
        data: !!tables.Charges
          ? data?.map((el) => {
              return el
                ?.reduce((acc, curr) => {
                  return (
                    acc + (curr?.chargeAmount || 0) - (curr?.taxAmount || 0)
                  );
                }, 0)
                ?.toFixed(2);
            })
          : [undefined],
        backgroundColor: "#F2C94C",
      },
    ],
  };
};
