import moment from "moment";

export const PERIOD_FILTER = () => {
  //today selection
  const convertedToday = moment().startOf("day").valueOf();
  const convertedTodayEnd = moment().endOf("day").valueOf();
  //yesterday selection
  const covertedYesterday = moment()
    .subtract(1, "days")
    .startOf("day")
    .valueOf();
  const covertedYesterdayEnd = moment()
    .subtract(1, "days")
    .endOf("day")
    .valueOf();
  //this week selection
  const covertedWeek = moment().startOf("isoWeek").valueOf();
  const covertedWeekEnd = moment().endOf("isoWeek").valueOf();
  //Previous week selection
  const covertedPrevWeek = moment()
    .subtract(1, "week")
    .startOf("isoWeek")
    .valueOf();
  const covertedPrevWeekEnd = moment()
    .subtract(1, "week")
    .endOf("isoWeek")
    .valueOf();
  //this month selection
  const covertedthisMonth = moment().startOf("month").valueOf();
  const covertedthisMonthEnd = moment().endOf("month").valueOf();
  //previousMonth  selection
  const covertedpreviousMonth = moment().startOf("month").valueOf();
  const covertedpreviousMonthEnd = moment().endOf("month").valueOf();
  //tomorrow selection
  const tomorrow = moment().add(1, "day").startOf("day").valueOf();
  const tomorrowEnd = moment().add(1, "day").endOf("day").valueOf();
  //alltime selection
  const allTime = [0, moment().valueOf()];
  const thisYear = [
    moment().startOf("year").valueOf(),
    moment().endOf("year").valueOf(),
  ];
  const previousYear = [
    moment().subtract(1, "year").startOf("year").valueOf(),
    moment().subtract(1, "year").endOf("year").valueOf(),
  ];
  const thisQuarter = [
    moment().startOf("quarter").valueOf(),
    moment().endOf("quarter").valueOf(),
  ];
  const previousQuarter = [
    moment().subtract(1, "quarter").startOf("quarter").valueOf(),
    moment().subtract(1, "quarter").endOf("quarter").valueOf(),
  ];
  const quarter1 = [
    moment().quarter(1).startOf("quarter").valueOf(),
    moment().quarter(1).endOf("quarter").valueOf(),
  ];
  const quarter2 = [
    moment().quarter(2).startOf("quarter").valueOf(),
    moment().quarter(2).endOf("quarter").valueOf(),
  ];
  const quarter3 = [
    moment().quarter(3).startOf("quarter").valueOf(),
    moment().quarter(3).endOf("quarter").valueOf(),
  ];
  const quarter4 = [
    moment().quarter(4).startOf("quarter").valueOf(),
    moment().quarter(4).endOf("quarter").valueOf(),
  ];
  return {
    allTime,
    today: [convertedToday, convertedTodayEnd],
    yesterday: [covertedYesterday, covertedYesterdayEnd],
    thisWeek: [covertedWeek, covertedWeekEnd],
    previousWeek: [covertedPrevWeek, covertedPrevWeekEnd],
    thisMonth: [covertedthisMonth, covertedthisMonthEnd],
    previousMonth: [covertedpreviousMonth, covertedpreviousMonthEnd],
    tomorrow: [tomorrow, tomorrowEnd],
    thisYear: thisYear,
    previousYear: previousYear,
    thisQuarter: thisQuarter,
    previousQuarter: previousQuarter,
    quarter1: quarter1,
    quarter2: quarter2,
    quarter3: quarter3,
    quarter4: quarter4,
    // nextWeek: [convertedNextWeek, convertedNextWeekEnd],
    // nextMonth: [convertedNextMonth, convertedNextMonthEnd],
  };
};

export const periodDates = [
  { value: "allTime", label: "All Time" },
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "tomorrow", label: "Tomorrow" },
  //   { value: "nextWeek", label: "Next Week" },
  //   { value: "nextMonth", label: "Next Month" },
  { value: "thisWeek", label: "This Week" },
  { value: "previousWeek", label: "Previous Week" },
  { value: "thisMonth", label: "This Month" },
  { value: "previousMonth", label: "Previous Month" },
  { value: "thisYear", label: "This Year" },
  { value: "previousYear", label: "Previous Year" },
  { value: "thisQuarter", label: "This Quarter" },
  { value: "previousQuarter", label: "Previous Quarter" },
  { value: "quarter1", label: "Quarter 1" },
  { value: "quarter2", label: "Quarter 2" },
  { value: "quarter3", label: "Quarter 3" },
  { value: "quarter4", label: "Quarter 4" },
];
