import trimEnd from "lodash/trimEnd";
import { FormGridStatusRenderer } from "../../../Components";
import { extractViolationStatus } from "./utils";
import moment from "moment";

export const fieldsJSON = (binOptions) => [
  {
    label: "DOB Address",
    formItemName: "dobAddress",
    placeholder: "DOB Address",
    disabled: true,
  },
  {
    label: "BIN no:",
    formItemName: "binNumber",
    placeholder: "Type here",
    type: "select",
    mode: "tags",
    options: binOptions,
    rules: [
      {
        validator: (_, value = []) =>
          !!value?.length &&
          value?.filter?.((el) => !!el)?.some((val) => isNaN(val))
            ? Promise.reject(new Error("Value must be a number"))
            : Promise.resolve(),
      },
    ],
  },
];

export const statuses = [
  { status: "Resolved", color: "#1FC74C" },
  {
    status: "Active",
    color: "#f0ad4e",
  },
  {
    status: "Dismissed",
    color: "#FF6292",
  },
];

export const violationsGridColDefs = [
  {
    headerName: "Violation Category",
    field: "violation_category",
    filter: "agTextColumnFilter",
    cellRenderer: ({ value = "" }) =>
      trimEnd(
        value
          .toUpperCase()
          .replace("ACTIVE", "")
          .replace("RESOLVED", "")
          .replace("DISMISSED", ""),
        "- "
      ),
    checkboxSelection: true,
    resizable: true,
    sortable: true,
  },

  {
    headerName: "Status",
    colId: "renderedStatus",
    filter: "agTextColumnFilter",
    cellRenderer: (params) =>
      FormGridStatusRenderer({
        value: extractViolationStatus(params),
        statuses,
      }),
    valueGetter: (params) => extractViolationStatus(params),
    resizable: true,
    sortable: true,
  },
  {
    headerName: "BIN",
    field: "bin",
  },
  {
    headerName: "Violation Date",
    field: "issue_date",
    resizable: true,
    sortable: true,
    cellRenderer: ({ value = "" }) => moment(value).format("MM/DD/YYYY"),
  },
  {
    headerName: "Violation Number",
    field: "violation_number",
    resizable: true,
    sortable: true,
  },

  {
    headerName: "Violation Type",
    field: "violation_type",
    resizable: true,
    sortable: true,
  },
];

export const dobStepFormItemNames = fieldsJSON().map(
  ({ formItemName }) => formItemName
);
