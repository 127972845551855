import "../styles/TaskReminderInput.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import { Form, Select } from "antd";
import { TimeDateProvider } from "../../../../../../../DateComponents/TimeDateContext";
import { getAssignedUsers } from "../../helpers";
import { DropdownIcon } from "../../../../../../BasePage/src";
import { WithTooltip } from "../../../../../../../commonComponents";
import ReminderSelect from "./ReminderSelect";

export const TaskReminderInput = ({ form, remindMe, setRemindMe }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const taskAssignedTo = Form.useWatch("taskAssignedTo", form) || [];
  const assignToMe = Form.useWatch("assignToMe", form);

  const taskStartDate =
    Form.useWatch("taskStartDate", form) || moment().endOf("D");
  const taskEndDate = Form.useWatch("taskEndDate", form) || moment().endOf("D");

  const updatedTaskAssignedTo = getAssignedUsers(
    assignToMe,
    taskAssignedTo,
    userConfiguration
  );

  return (
    <div className="task-reminder-section">
      <TimeDateProvider>
        <ReminderSelect
          {...{
            value: remindMe?.reminderValue,
            // taskDetails: true, // to tell that we are about creating task
            placeholder: "Remind me every",
            reminderTime: remindMe?.reminderTime,
            handleReminderTime: (e) => {
              setRemindMe((prev) => ({ ...prev, reminderTime: e }));
            },
            setValue: (e) => {
              setRemindMe((prev) => ({ ...prev, reminderValue: e }));
            },
            handleReminderDays: (e) => {
              setRemindMe((prev) => ({ ...prev, reminderDays: e }));
            },
            reminderType: remindMe?.reminderType,
            setReminderType: (e) => {
              setRemindMe((prev) => ({ ...prev, reminderType: e }));
            },
            startDate: moment(taskStartDate),
            deadline: moment(taskEndDate),
            notifiedBy: remindMe?.notifiedBy,
            setNotifiedBy: (e) => {
              setRemindMe((prev) => ({ ...prev, notifiedBy: e }));
            },
          }}
        />
      </TimeDateProvider>
      <div className="task-users">
        <span className="assignee-label">Reminder Assignee</span>
        <span className="modal__multiSelect department-select">
          <WithTooltip tooltipCategory="Tasks" tooltipKey="reminderAssignedTo">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              value={remindMe?.reminderAssignedTo}
              popupClassName={isDarkMode && "darkDropDown"}
              placeholder="Reminder Assigned to"
              onChange={(val, option) => {
                setRemindMe((prev) => ({
                  ...prev,
                  reminderAssignedTo: option,
                }));
              }}
              suffixIcon={<DropdownIcon />}
              popupMatchSelectWidth={false}
              options={updatedTaskAssignedTo.map((user) => ({
                label: user.nameOfUser,
                value: user.nameOfUser,
                ...user,
              }))}
            />
          </WithTooltip>
        </span>
      </div>
    </div>
  );
};
