/**
 * Handles the selection of a client in the form.
 *
 * @param {string} val - The selected value.
 * @param {Object} opt - The selected option.
 * @param {boolean} [clear=false] - Indicates whether to clear the fields.
 * @param {Array} [accounts=[]] - The list of accounts.
 * @param {Function} [setFieldsValue=() => {}] - The function to set field values.
 * @param {Function} [dispatchRequestData=() => {}] - The function to dispatch request data.
 */
const onClientSelect = ({
  val,
  opt,
  clear = false,
  accounts = [],
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (clear === true) {
    setFieldsValue({ projectExecutive: "" });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: null,
    });
  } else {
    const { projectExecutive = "" } =
      (accounts || []).find((el) => el.accountId === val) || {};

    setFieldsValue({ projectExecutive });

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: {
        accountId: val,
        accountName: opt?.label,
        recordName: opt?.label,
        recordId: val,
      },
    });
  }
};

export default onClientSelect;
