import { API } from "aws-amplify";
import { boroughs, coordinatesReplacer } from "./addWidgetsModalData";
import { apiRoutes, fetchData } from "../Fleet/utils";
import {
  fetchAllData,
  fetchByDate,
  fetchByList,
  filterTables,
  lazyFetch,
} from "../../../utils";
import { getViewPortDimensions } from "../utils/getViewPortDimensions";
import { heightHandlerShow, widthHandlerShow } from "./addWidgetsModalData";
import {
  keyToTable,
  // allTableData,
  lazyFetchingData,
  tableKeyMapping,
  tableMapping,
} from "./constants";
import { transformKeys } from "./utils/transformKeys";
import { debounce, uniq } from "lodash";
import {
  FILTER_VALUES,
  filterChartsFromPref,
} from "../DynamicView/components/FilterComponents/filtersData";
import dayjs from "dayjs";
import { toCamelCase } from "../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import {
  compareFilterRange,
  getDashboardSession,
  getFilterFromPref,
  restructureFilterRanges,
  setAllTablesToStorage,
  setTablesFilterToStorage,
} from "./utils/dashboardSession";
import { preventDuplicate } from "../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { camelCaseToNormalText } from "../../commonComponents/RowDataGridModal/components/utils";
import { message } from "antd";
import { removeArrayIndexes } from "../utils";
import uniqBy from "lodash/uniqBy";

/**
 * Saves the layout and related data to the database.
 * @param {Object} layout - The layout data to be saved.
 * @param {Object} rowData - The row data to be saved.
 * @param {Array} hiddenCards - The hidden cards data to be saved.
 * @param {Function} setEditable - The function to set the editable state.
 * @param {Function} setVisible - The function to set the visible state.
 * @param {Object} preferences - The preferences data to be saved.
 * @param {string} url - The URL to save the data to.
 * @param {Function|null} saveHiddenRef - The function to save the hidden reference.
 * @param {boolean} hideCardsCase - The flag indicating whether to hide cards.
 * @returns {Promise<void>} - A promise that resolves when the data is saved.
 */
export const saveLayout = async ({
  layout,
  rowData,
  hiddenCards,
  setEditable,
  setVisible,
  preferences,
  url,
  saveHiddenRef = null,
  hideCardsCase = false,
}) => {
  setEditable(false);
  setVisible(false);
  //this saves all data on db
  saveDBHandler({
    layout,
    rowData,
    hiddenCards,
    preferences,
    url,
    saveHiddenRef,
    hideCardsCase,
  });
};

//Handle toggle animation also sets editable to false
export const toggleAnimation = (setEditable, setConfirmationModalVisible) => {
  setEditable(false);
  setConfirmationModalVisible(false);
};

//it makes the update of layout only in a specific breakpoint
export const layoutChange = (e, layout, setLayout) => {
  if (getViewPortDimensions().width >= 3032) {
    setLayout({ ...layout, lg: e });
  }
  //
  else if (
    2651 <= getViewPortDimensions().width &&
    getViewPortDimensions().width < 3032
  ) {
    setLayout({ ...layout, md: e });
  }
  //
  else if (
    2261 <= getViewPortDimensions().width &&
    getViewPortDimensions().width < 2651
  ) {
    setLayout({ ...layout, sm: e });
  }
  //
  else if (
    1846 <= getViewPortDimensions().width &&
    getViewPortDimensions().width < 2261
  ) {
    setLayout({ ...layout, xs: e });
  }
  //
  else if (
    1451 <= getViewPortDimensions().width &&
    getViewPortDimensions().width < 1846
  ) {
    setLayout({ ...layout, xxs: e });
  }
  //
  else if (
    1041 <= getViewPortDimensions().width &&
    getViewPortDimensions().width < 1451
  ) {
    setLayout({ ...layout, a: e });
  }
  //
  else if (getViewPortDimensions().width < 1041) {
    setLayout({ ...layout, b: e });
  }
};

/**
 * Handles the search functionality for the dashboard.
 *
 * @param {string} e - The search input value.
 * @param {Array} rowData - The array of dashboard data.
 * @param {function} setLayout - The function to update the widget layout.
 * @param {object} filteredWidgetLayout - The filtered widget layout.
 * @param {function} setSearchValue - The function to update the search value.
 */
export const dashboardSearchHandler = debounce(
  (e, rowData, setLayout, filteredWidgetLayout, setSearchValue) => {
    if (e === "") {
      setLayout(filteredWidgetLayout);
      setSearchValue(e);
    } else {
      const filteredData = rowData.filter((el) =>
        el?.title?.toLowerCase().includes(e?.toLowerCase())
      );

      setSearchValue(e);
      let filteredIds = filteredData.map((el) => el?.id);
      const newLayout = Object.keys(filteredWidgetLayout)?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: filteredWidgetLayout[curr].filter((a) =>
            filteredIds?.includes(a?.i)
          ),
        }),
        {}
      );

      setLayout(newLayout);
    }
  },
  500
);

//removes hidden cards from hiddenCards array and adds it on rowData
export const showHandler = ({
  e,
  setHiddenCards,
  hiddenCards,
  setRowData,
  layout,
  rowData,
  setLayout,
  onSave = () => {},
  saveHiddenRef,
  preferences,
}) => {
  const length = hiddenCards?.length;
  const newRowData = [...rowData, hiddenCards?.find((a) => a.id === e)];
  const newHidden = hiddenCards.filter((a) => a.id !== e);
  const type = hiddenCards?.find((a) => a?.id === e)?.type;
  const newLayout = Object.keys(layout)?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: [
        ...layout[curr],
        {
          w: widthHandlerShow[type],
          h: heightHandlerShow[type],
          x: 0,
          y:
            Math?.max(...layout[curr]?.map((a) => a?.y)) + 1 >= 0
              ? Math?.max(...layout[curr]?.map((a) => a?.y)) + 1
              : 0,
          i: e,
          moved: false,
          static: false,
        },
      ],
    }),
    {}
  );

  setHiddenCards(newHidden);
  setRowData(newRowData);
  setLayout(newLayout);

  saveHiddenRef.current = () =>
    saveDBHandler({
      layout: newLayout,
      rowData: newRowData,
      hiddenCards: newHidden,
      preferences,
      url: "dashboard",
      saveHiddenRef,
      hideCardsCase: true,
    });

  /**
   *If the user is removing the last hidden card then the confirmation modal will be opened
   */
  if (length === 1 || length === 0) {
    onSave();
  }
};

/**
 * Saves the dashboard configuration to the user preferences.
 *
 * @param {Object} layout - The layout configuration.
 * @param {Object} rowData - The row data.
 * @param {Object} hiddenCards - The hidden cards configuration.
 * @param {Object} preferences - The user preferences.
 * @param {string} url - The URL of the dashboard.
 * @param {Function} [setCardFilterPreferences] - The function to set card filter preferences.
 * @param {Object} [saveHiddenRef] - The reference to save hidden cards.
 * @param {boolean} [hideCardsCase=false] - Indicates whether to hide cards.
 */
export const saveDBHandler = ({
  layout,
  rowData,
  hiddenCards,
  preferences,
  url,
  setCardFilterPreferences = () => {},
  saveHiddenRef = null,
  hideCardsCase = false,
}) => {
  API.patch("preferences", "/preferences", {
    body: {
      widgetConfiguration: {
        ...(preferences.widgetConfiguration || {}),
        [url]: rowData,
      },
      widgetHiddenConfiguration: {
        ...(preferences.widgetHiddenConfiguration || {}),
        [url]: hiddenCards,
      },
      widgetLayout: { ...(preferences.widgetLayout || {}), [url]: layout },
    },
  })
    .then(() => {
      if (!hideCardsCase) {
        setCardFilterPreferences([]);
      }
      if (saveHiddenRef) {
        saveHiddenRef.current = null;
      }
    })
    .catch((e) => console.error(e));
};

//remove blur from cards with id === e
export const unBlur = (e, setCardFilterPreferences, rowData) => {
  document.getElementById(e).classList.remove("blur");
  document.getElementById("blur" + e).classList.remove("blur");
  setCardFilterPreferences(
    rowData?.map((el) => {
      if (el?.id === e) {
        return { ...el, blur: false };
      } else {
        return el;
      }
    })
  );
};

/**
 * Filters the data based on user access permissions.
 *
 * @param {Object} user - The userConfiguration object.
 * @param {Array} data - The data to be filtered.
 * @returns {Array} - The filtered data based on user access permissions and teamsConfiguration.
 */
export function userAccessRecords(user, data) {
  if (user?.groupName?.toLowerCase() === "admin") {
    return data;
  } else {
    let tmpData = data?.filter((a) => {
      if (!!a?.teamsConfiguration) {
        return a?.teamsConfiguration?.some((w) =>
          w?.members?.some(
            ({ identityId = "" }) => identityId === user?.identityId
          )
        );
      } else {
        return a;
      }
    });
    return tmpData;
  }
}

/**
 * Generates estimations for opportunities based on the provided estimations and project estimations.
 * @param {Array<string>} estimations - The list of estimations.
 * @param {Object} projectEstimations - The object containing project estimations.
 * @returns {Array} - The filtered list of estimations for opportunities.
 */
export function generateEstimationsForOpportunities(
  estimations,
  projectEstimations
) {
  return estimations
    ?.map((estimation) => projectEstimations[estimation])
    .filter(Boolean);
}

/**
 * Calculates the sum of occurrences for each borough in the given array based on the specified key.
 * @param {Array} array - The array of elements to iterate over.
 * @param {string} key - The key to access the borough value in each element.
 * @returns {Object} - An object containing the sum of occurrences for each borough.
 */
export function getBoroughSum(array = [], key = "") {
  let borough;
  let toReturn = {
    Bronx: 0,
    Brooklyn: 0,
    Manhattan: 0,
    Queens: 0,
    "Staten Island": 0,
    Other: 0,
  };

  array.forEach((element = {}) => {
    borough = boroughs.find((a) =>
      element[key]?.toLowerCase()?.includes(a?.toLowerCase())
    );
    if (!borough) {
      toReturn["Other"] = toReturn["Other"] + 1 || 1;
    } else if (borough) {
      toReturn[borough] = toReturn[borough] + 1 || 1;
    } else {
      toReturn[borough] = 1;
    }
  });

  return toReturn;
}

// const fetchAllTableData = async (tableQueries, userConfiguration) => {
//   const dataResponses = await Promise.all(
//     tableQueries.map(async (query) => {
//       let data = [];
//       let jobsites = [];

//       if (query?.tableName === "degEntries") {
//         data = await API.get("degEntries", "/degEntries", {
//           queryStringParameters: {
//             ExclusiveStartKey: undefined,
//             withPagination: "true",
//             getMaxLimit: "true",
//             // keysToInclude: JSON.stringify(query.listOfKeys),
//             filters: JSON.stringify([
//               {
//                 conditions: [
//                   {
//                     column: "activityStatus",
//                     formula: "is",
//                     value: "Completed",
//                   },
//                 ],
//               },
//             ]),
//           },
//         }).then(async (res) => {
//           if (query?.tableName === "degEntries") {
//             const uniqueJobsites = uniq(
//               res?.degEntries?.flatMap((el) =>
//                 el.activityStatus === "Completed" ? el?.jobSiteId : []
//               )
//             );

//             jobsites = await Promise.allSettled(
//               splitSubArrays(uniqueJobsites, 99)?.map(async (el) => {
//                 return await fetchListPromise("jobsites", "jobsiteId", el);
//               })
//             )
//               ?.then((res) => {
//                 return res?.flatMap((el) => el?.value);
//               })
//               .catch((err) => console.error("Error getting jobsites : ", err));
//           }

//           return res;
//         });
//       } else
//         data = await fetchAllData({
//           endpoint: query?.tableName,
//           resultPosition: query?.tableName,
//           resultId: query?.idObject,
//           otherStringParams: {
//             keysToInclude: JSON.stringify(query.listOfKeys),
//           },
//         });
//       let key =
//         tableKeyMapping[query.tableName] ||
//         query.tableName.charAt(0).toUpperCase() + query.tableName.slice(1);

//       if (query?.tableName === "degEntries") {
//         return {
//           [key]: userAccessRecords(userConfiguration, data),
//           Jobsites: jobsites,
//         };
//       } else {
//         return {
//           [key]: userAccessRecords(userConfiguration, data),
//         };
//       }
//     })
//   );

//   const successfulResponses = dataResponses.filter(
//     (response) => Object.keys(response).length > 0
//   );

//   return Object.assign({}, ...successfulResponses);
// };

/**
 * Fetches table data based on the provided table queries and user configuration.
 *
 * @param {Array} tableQueries - An array of table queries.
 * @param {Object} userConfiguration - The user configuration object.
 * @returns {Object} - The fetched table data.
 */
const fetchTableData = async (tableQueries, userConfiguration) => {
  const dashboardSession = getDashboardSession();
  const dataResponses = await Promise.all(
    tableQueries.map(async (query) => {
      let data = [];
      let jobsites = [];

      const filterRange = dashboardSession?.[query?.tableName];

      let dateKey = "createdAt";

      if (query.hasOwnProperty("dateKey")) {
        dateKey = query.dateKey;
      }

      /**
       * If there is no filter range or dashboard session, then fetch data based on the initial filter values.
       */
      if (!filterRange || !dashboardSession) {
        data = await API.get(query?.tableName, `/${query?.tableName}`, {
          queryStringParameters: {
            ExclusiveStartKey: undefined,
            withPagination: "true",
            getMaxLimit: "true",
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    columnType: "date",
                    column: dateKey,
                    formula: "is between",
                    value: FILTER_VALUES()?.initial,
                  },
                ],
              },
            ]),

            ...(query?.listOfKeys && {
              keysToInclude: JSON.stringify(query.listOfKeys),
            }),
          },
        })?.then(async (res) => {
          const resToUse = Array.isArray(res) ? res : res?.[query?.tableName];

          if (query?.tableName === "degEntries") {
            const uniqueJobsites = uniq(
              resToUse?.flatMap((el) =>
                el.activityStatus === "Completed" ? el?.jobsiteId : []
              )
            );

            jobsites = await Promise.allSettled(
              splitSubArrays(uniqueJobsites, 99)?.map(async (el) => {
                return await fetchListPromise("jobsites", "jobsiteId", el);
              })
            )
              ?.then((res) => {
                return res?.flatMap((el) => el?.value);
              })
              .catch((err) => console.error("Error getting jobsites : ", err));
          }
          return resToUse;
        });

        /**
         * await fetchByDate(
         *  query?.tableName,
         *  FILTER_VALUES()?.initial,
         *  dateKey
         * );
         */
      } else {
        /**
         * If there is a filter range, then fetch data based on the filter range.
         */
        const allRanges = Object.values(filterRange);
        let uniqueRanges = restructureFilterRanges([
          ...allRanges,
          FILTER_VALUES()?.initial,
        ]);

        let otherData = [];

        if (uniqueRanges?.some((el) => el[0] === 0)) {
          const allTimeIndex = uniqueRanges?.find((el) => el[0] === 0);

          removeArrayIndexes(uniqueRanges, allTimeIndex);

          otherData = await fetchAllData({
            endpoint: query?.tableName,
            resultPosition: query?.tableName,
            resultId: query?.idObject,
            otherStringParams: {
              ...(query?.listOfKeys && {
                keysToInclude: JSON.stringify(query.listOfKeys),
              }),
            },
          })?.then(async (res) => {
            const allRes = Array.isArray(res) ? res : res?.[query?.tableName];

            if (query?.tableName === "degEntries") {
              const uniqueJobsites = uniq(
                allRes?.flatMap((el) =>
                  el.activityStatus === "Completed" ? el?.jobsiteId : []
                )
              );

              jobsites = await Promise.allSettled(
                splitSubArrays(uniqueJobsites, 99)?.map(async (el) => {
                  return await fetchListPromise("jobsites", "jobsiteId", el);
                })
              )
                ?.then((res) => {
                  return res?.flatMap((el) => el?.value);
                })
                .catch((err) =>
                  console.error("Error getting jobsites : ", err)
                );
            }

            return allRes;
          });
        }

        data = await Promise.allSettled(
          uniqueRanges?.map(
            async (range) =>
              await API.get(query?.tableName, `/${query?.tableName}`, {
                queryStringParameters: {
                  ExclusiveStartKey: undefined,
                  withPagination: "true",
                  getMaxLimit: "true",
                  filters: JSON.stringify([
                    {
                      conditions: [
                        {
                          columnType: "date",
                          column: dateKey,
                          formula: "is between",
                          value: range,
                        },
                      ],
                    },
                  ]),

                  ...(query?.listOfKeys && {
                    keysToInclude: JSON.stringify(query.listOfKeys),
                  }),
                },
              })
            // await fetchByDatePromise(query?.tableName, range, dateKey)
          )
        )?.then(async (res) => {
          const allRes = res?.flatMap((el) => el?.value);
          if (query?.tableName === "degEntries") {
            const uniqueJobsites = uniq(
              allRes?.flatMap((el) =>
                el.activityStatus === "Completed" ? el?.jobsiteId : []
              )
            );

            jobsites = await Promise.allSettled(
              splitSubArrays(uniqueJobsites, 99)?.map(async (el) => {
                return await fetchListPromise("jobsites", "jobsiteId", el);
              })
            )
              ?.then((res) => {
                return res?.flatMap((el) => el?.value);
              })
              .catch((err) => console.error("Error getting jobsites : ", err));
          }

          return allRes;
        });

        if (otherData?.length) {
          data = uniqBy([...data, ...otherData], query?.idObject);
        }
      }

      let key =
        tableKeyMapping[query.tableName] ||
        query.tableName.charAt(0).toUpperCase() + query.tableName.slice(1);

      if (query?.tableName === "degEntries") {
        return {
          [key]: userAccessRecords(userConfiguration, data),
          Jobsites: jobsites,
        };
      } else {
        return {
          [key]: userAccessRecords(userConfiguration, data),
        };
      }
    })
  );

  const successfulResponses = dataResponses.filter(
    (response) => Object.keys(response).length > 0
  );

  return Object.assign({}, ...successfulResponses);
};

/**
 * Fetches dashboard data asynchronously and updates the state with the fetched data.
 *
 * @param {Function} setStatesData - The function to set the states data.
 * @param {Function} setLoading - The function to set the loading state.
 * @param {Function} setPreferences - The function to set the preferences.
 * @param {string} url - The URL for fetching the data.
 * @param {Function} setWidgetRepetition - The function to set the widget repetition.
 * @param {Array} programFields - The program fields array.
 * @param {Function} setHiddenCards - The function to set the hidden cards.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Function} dispatchData - The function to dispatch data.
 * @param {Function} dispatchTable - The function to dispatch table.
 * @param {Object} reducerState - The reducer state object.
 * @param {Array} applications - The applications array.
 * @param {Array} rentals - The rentals array.
 * @param {Array} charges - The charges array.
 * @param {Array} invoices - The invoices array.
 * @param {Object} defaultWidgetData - The default widget data object.
 * @param {boolean} populateDefault - The flag to populate default data.
 * @param {Function} setPopulateDefault - The function to set the populate default flag.
 * @returns {Promise<void>} - A promise that resolves when the data fetching and state updates are complete.
 */
export const fetchDashboardData = async ({
  setStatesData,
  setLoading,
  setPreferences,
  url,
  setWidgetRepetition,
  programFields,
  setHiddenCards,
  userConfiguration,
  dispatchData,
  dispatchTable,
  reducerState,
  applications,
  rentals,
  charges,
  invoices,
  defaultWidgetData,
  populateDefault,
  setPopulateDefault,
}) => {
  function setStateByKey(tablesDataResponses) {
    // dispatchTable({ type: "SET_TABLE", key: key, value: value });
    // const type = `SET_${key.toUpperCase()}_DATA`;
    // dispatchData({ type, payload: value });
    const newTablesDataResponses = transformKeys(tablesDataResponses);

    dispatchTable({ type: "SET_ALL_TABLES", payload: tablesDataResponses });
    dispatchData({ type: "SET_ALL_DATA", payload: newTablesDataResponses });
  }
  const fetchDataConcurrently = async () => {
    const [preferences, programFieldsNew, attorney, expeditor] =
      await Promise.all([
        fetchData(apiRoutes.preferences),
        fetchData(apiRoutes.programFields),
        filterTables("accounts", "subcontractorType", "Attorney"),
        filterTables("accounts", "subcontractorType", "Expeditor"),
      ]);

    return { preferences, programFieldsNew, attorney, expeditor };
  };

  const { preferences, programFieldsNew, attorney, expeditor } =
    await fetchDataConcurrently();
  setPreferences(preferences);
  dispatchData("SET_ATTORNEY_DATA", attorney);
  dispatchData("SET_EXPEDITOR_DATA", expeditor);
  dispatchData("SET_ATTORNEY_DATA", attorney);

  setHiddenCards(preferences?.widgetHiddenConfiguration?.[url] || []);
  setLoading(false);

  // const widgetConfiguration =
  //   url === "dashboard"
  //     ? preferences.widgetConfiguration?.[url] !== undefined
  //       ? preferences.widgetConfiguration?.[url]
  //       : programFieldsNew?.find?.(
  //           (a) => a?.fieldName === "Widget Configuration"
  //         )?.fieldOptions
  //     : [];

  const userPrefConfig = preferences?.widgetConfiguration?.[url] || [];

  const widgetConfiguration =
    userPrefConfig?.length && !populateDefault
      ? userPrefConfig
      : defaultWidgetData?.widgetConfig || [];

  let tableNames = Array.from(
    new Set(
      widgetConfiguration?.map((el) =>
        el?.BodyComponentParams?.table?.toLowerCase()
      )
    )
  );

  if (tableNames?.includes("opportunities")) {
    !tableNames?.includes("projects") && tableNames.push("projects");
  }
  tableNames = tableNames.map((table) => tableMapping[table] || table);

  const filteredStateKeys = Object.keys(reducerState).filter(
    (key) => reducerState[key].length > 0
  );

  const filteredFetchingData = lazyFetchingData?.filter(
    (el) =>
      tableNames?.some(
        (tableName) => tableName?.toLowerCase() === el?.tableName?.toLowerCase()
      ) && !filteredStateKeys.includes(el?.dataStateKey)
  );

  const tablesDataResponses = await fetchTableData(
    filteredFetchingData,
    userConfiguration
  );

  setStateByKey(tablesDataResponses);

  if (url === "dashboard") {
    setPopulateDefault(false);

    const results = await Promise.allSettled([
      tablesDataResponses,
      programFieldsNew,
      attorney,
      expeditor,
      preferences,
    ]);

    const [
      tableData,
      programFieldsRes,
      attorneyRes,
      expeditorRes,
      preferencesRes,
    ] = results.map((r) => r.value);

    dispatchTable({
      type: "SET_DASHBOARD_TABLES",
      tableData: tableData,
      attorneyRes: attorneyRes,
      expeditorRes: expeditorRes,
      programFieldsRes: programFieldsRes,
    });

    let data = {};
    let chartRepetition = {};

    // if (userPrefConfig?.length && !populateDefault) {
    data = preferencesRes?.defaultConfiguration?.[url] || {};
    chartRepetition = preferencesRes?.widgetRepetition?.[url] || {};

    setStatesData(data);
    setWidgetRepetition({
      ...(chartRepetition || {}),
    });
  } else if (url === "accounting") {
    // Refactor for url === "accounting"
    const results = await Promise.allSettled([programFieldsNew, preferences]);

    const [programFieldsRes, preferencesRes] = results.map((r) => r.value);

    const cardData = {
      programFieldsRes,
      applications,
      rentals,
      charges,
      invoices,
    };

    setStateByKey(cardData);

    const data = preferencesRes?.defaultConfiguration?.[url] || {};

    setStatesData(data);
    setWidgetRepetition({
      ...(preferencesRes?.widgetRepetition?.[url] || {}),
    });
  }
};

/**
 * Clears the dashboard by resetting various states and configurations.
 *
 * @param {Function} setRowData - The function to set the row data.
 * @param {Function} setWidgetRepetition - The function to set the widget repetition.
 * @param {Function} setDynamicStates - The function to set the dynamic states.
 * @param {Function} setUpdatedDynamicStates - The function to set the updated dynamic states.
 * @param {Function} setHiddenCards - The function to set the hidden cards.
 * @param {Function} setPreferences - The function to set the preferences.
 * @param {Function} setStatesData - The function to set the states data.
 * @param {Object} preferences - The preferences object.
 * @param {Array} rowData - The row data array.
 * @param {Function} setAllFilterStates - The function to set all filter states.
 * @param {Function} setPopulateDefault - The function to set the populate default flag.
 * @returns {Promise<void>} A promise that resolves when the dashboard is cleared.
 */
export async function clearDashboard({
  setRowData = () => {},
  setWidgetRepetition = () => {},
  setDynamicStates = () => {},
  setUpdatedDynamicStates = () => {},
  setHiddenCards = () => {},
  setPreferences = () => {},
  setStatesData = () => {},
  preferences = {},
  rowData = [],
  setAllFilterStates = () => {},
  setPopulateDefault = () => {},
}) {
  setPopulateDefault(false);
  await API.patch("preferences", "/preferences", {
    body: {
      defaultConfiguration: {
        ...preferences?.preferences?.defaultConfiguration,
        dashboard: {},
      },
      widgetConfiguration: {
        ...preferences?.preferences?.widgetConfiguration,
        dashboard: [],
      },
      widgetRepetition: {
        ...preferences?.preferences?.widgetRepetition,
        dashboard: {},
      },
      widgetHiddenConfiguration: {
        ...preferences?.preferences?.widgetConfiguration,
        dashboard: [],
      },
    },
  })
    ?.then(() => {
      sessionStorage.removeItem("dashboardSession");

      const url = window.location.pathname.split("/")[1];

      if (url === "dashboard") {
        rowData.map((el) => {
          const chart = document.getElementById(el?.id);
          const chartBlur = document.getElementById("blur" + el?.id);
          if (chart) {
            chart?.classList.remove("active");
            chart?.classList.add("out");
          }
          if (chartBlur) {
            chartBlur?.classList.remove("blur");
            chartBlur?.classList.add("out");
          }
        });
        // setPopulateDefault(false);

        setTimeout(() => {
          setPreferences((prev) => {
            return {
              ...prev,
              defaultConfiguration: {
                dashboard: {},
                accounting: {
                  ...prev?.preferences?.defaultConfiguration?.accounting,
                },
              },
              widgetConfiguration: {
                dashboard: [],
                accounting: {
                  ...prev?.preferences?.widgetConfiguration?.accounting,
                },
              },
              widgetRepetition: {
                dashboard: {},
                accounting: {
                  ...prev?.preferences?.widgetRepetition?.accounting,
                },
              },
              widgetHiddenConfiguration: {
                dashboard: [],
                accounting: {
                  ...prev?.preferences?.widgetHiddenConfiguration?.accounting,
                },
              },
            };
          });
          setAllFilterStates({});
          setWidgetRepetition({});
          setDynamicStates({});
          setUpdatedDynamicStates({});
          setHiddenCards([]);
          setStatesData({});
          setRowData([]);
        }, 300);
      }
    })
    ?.catch(() => {
      console.error("Error resetting layout");
    });
}

/**
 * Populates the dashboard with default charts.
 *
 * @param {Object} preferences - The preferences object.
 * @param {Function} setPopulateDefault - The function to set the populate default flag.
 * @param {Object} defaultWidgetData - The default widget data object.
 * @param {Function} fetchDashboardData - The function to fetch dashboard data.
 * @param {Function} [setVisible] - The function to set the visibility flag.
 * @param {Function} [removeCurrCharts] - The function to remove current charts.
 * @param {boolean} [hasRowData=false] - Flag indicating if there is row data.
 * @returns {Promise<void>} A promise that resolves when the dashboard is populated with default charts.
 */
export async function populateWithDefaultCharts({
  preferences,
  setPopulateDefault,
  defaultWidgetData,
  fetchDashboardData,
  setVisible = () => {},
  removeCurrCharts = async () => {},
  hasRowData = false,
  setWidgetRepetition = () => {},
}) {
  const url = "dashboard";
  setPopulateDefault(true);

  message.loading({
    key: "populate",
    content: "Adding Charts...",
    duration: 0,
  });

  if (hasRowData) {
    await removeCurrCharts().catch((err) =>
      console.error("Error removing current charts : ", err)
    );
  }

  // await removeCurrCharts()
  //   .catch((err) => console.error("Error removing current charts : ", err))
  //   .finally(async () => {
  await API.patch("preferences", "/preferences", {
    body: {
      defaultConfiguration: {
        ...preferences?.preferences?.defaultConfiguration,
        [url]: defaultWidgetData?.statesData,
      },
      widgetConfiguration: {
        ...preferences?.preferences?.widgetConfiguration,
        [url]: defaultWidgetData?.widgetConfig,
      },
      widgetRepetition: {
        ...preferences?.preferences?.widgetRepetition,
        [url]: defaultWidgetData?.widgetRepetition,
      },
      widgetHiddenConfiguration: {
        ...preferences?.preferences?.widgetHiddenConfiguration,
        [url]: [],
      },
      widgetLayout: {
        ...preferences?.preferences?.widgetLayout,
        [url]: defaultWidgetData?.layout,
      },
    },
  })
    ?.then(async () => {
      setWidgetRepetition(defaultWidgetData?.widgetRepetition);
      message.destroy("populate");
      await fetchDashboardData();
      setVisible(false);
      // setPopulateDefault(false);
    })
    ?.catch((err) => {
      message.error({
        key: "populate",
        content: "Something went wrong!",
      });
      console.error("Error populating default charts : ", err);
      setVisible(false);
    });
  // });
}

/**
 * Splits an array into sub-arrays of a specified size.
 *
 * @param {Array} arr - The array to be split.
 * @param {number} size - The size of each subarray.
 * @returns {Array} - An array of sub-arrays.
 */
function splitSubArrays(arr, size) {
  const subArrays = [];
  for (let i = 0; i < arr.length; i += size) {
    subArrays.push(arr.slice(i, i + size));
  }
  return subArrays;
}

/**
 * Fetches data from an API based on a list of items.
 *
 * @param {string} apiTable - The API table to fetch data from.
 * @param {string} key - The key to use for fetching data.
 * @param {Array} list - The list of items to fetch data for.
 * @returns {Promise<Array>} A promise that resolves to an array of fetched data.
 * @throws {Error} If there is an error fetching the data.
 */
async function fetchListPromise(apiTable, key, list) {
  return new Promise(async (resolve, reject) => {
    return await fetchByList(apiTable, key, list)
      ?.then((res = []) => {
        return resolve(res);
      })
      .catch((err) => {
        console.error("Error getting data for range : ", err);
        throw reject(err);
      });
  });
}

/**
 * Fetches data by date using a promise.
 *
 * @param {string} apiTable - The API table to fetch data from.
 * @param {string} range - The date range to fetch data for.
 * @param {string} key - The key to use for fetching data.
 * @returns {Promise<Array>} A promise that resolves to an array of fetched data.
 * @throws {Error} If there is an error while fetching the data.
 */
async function fetchByDatePromise(apiTable, range, key) {
  return new Promise(async (resolve, reject) => {
    return await fetchByDate(apiTable, range, key)
      ?.then((res = []) => {
        return resolve(res);
      })
      .catch((err) => {
        console.error("Error getting data for range : ", err);
        throw reject(err);
      });
  });
}

/**
 * Fetches data based on filter criteria.
 *
 * @param {string} fetchRange - The range for fetching data.
 * @param {string} table - The table to fetch data from.
 * @param {string} apiTable - The API table to fetch data from.
 * @param {Array} [tableData=[]] - The existing table data.
 * @param {Function} dispatchTable - The dispatch function for updating the table.
 * @param {string} idKey - The key for identifying unique records.
 * @param {Object} userConfiguration - The user configuration object.
 * @returns {Promise<Array>} - A promise that resolves to the fetched data.
 * @throws {Error} - If there is an error while fetching the data.
 */
export async function fetchOnFilter({
  fetchRange,
  table,
  apiTable,
  tableData = [],
  dispatchTable,
  idKey,
  userConfiguration,
}) {
  let rangeKey = apiTable === "degEntries" ? "punchTimeStamp" : "createdAt";

  const apiData = lazyFetchingData?.find((el) => el?.tableName === apiTable);

  const [start, end] = fetchRange;

  /**
   * This handles when we filter by done all time
   */
  if (
    start === 0 &&
    dayjs(end).format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY")
  ) {
    return await fetchAllData({
      endpoint: apiTable,
      resultPosition: apiTable,
      resultId: idKey,
      otherStringParams: {
        ...(apiData?.hasOwnProperty("listOfKeys") && {
          keysToInclude: JSON.stringify(apiData?.listOfKeys),
        }),
      },
    })
      ?.then(async (res = []) => {
        const resToUse = Array.isArray(res) ? res : res?.[apiTable];

        const toSet = userAccessRecords(
          userConfiguration,
          preventDuplicate([...tableData, ...resToUse], idKey)
        );

        dispatchTable({
          type: "SET_TABLE",
          key: table,
          value: toSet,
        });

        if (apiTable === "degEntries") {
          const uniqueJobsites = uniq(toSet?.map((el) => el?.jobsiteId));

          if (uniqueJobsites?.length >= 99) {
            await Promise.allSettled(
              splitSubArrays(uniqueJobsites, 99)?.map(async (el) => {
                return await fetchListPromise("jobsites", "jobsiteId", el);
              })
            )
              ?.then((resToUse) => {
                const jobsites = resToUse?.flatMap((el) => el?.value);
                dispatchTable({
                  type: "SET_TABLE",
                  key: "Jobsites",
                  value: jobsites,
                });
              })
              .catch((err) => console.error("Error getting jobsites : ", err));
          }
        }
        return toSet;
      })
      ?.catch((err) => {
        console.error("Error getting data : ", err);
        throw err;
      });
  }

  return await API.get(apiTable, `/${apiTable}`, {
    queryStringParameters: {
      ExclusiveStartKey: undefined,
      withPagination: "true",
      getMaxLimit: "true",
      filters: JSON.stringify([
        {
          conditions: [
            {
              columnType: "date",
              column: rangeKey,
              formula: "is between",
              value: fetchRange,
            },
          ],
        },
      ]),

      ...(apiData?.hasOwnProperty("listOfKeys") && {
        keysToInclude: JSON.stringify(apiData?.listOfKeys),
      }),
    },
  })
    ?.then(async (res = []) => {
      const resToUse = Array.isArray(res) ? res : res?.[apiTable];

      const toSet = userAccessRecords(
        userConfiguration,
        preventDuplicate([...tableData, ...resToUse], idKey)
      );

      dispatchTable({
        type: "SET_TABLE",
        key: table,
        value: toSet,
      });

      if (apiTable === "degEntries") {
        const uniqueJobsites = uniq(toSet?.map((el) => el?.jobsiteId));

        if (uniqueJobsites?.length >= 99) {
          await Promise.allSettled(
            splitSubArrays(uniqueJobsites, 99)?.map(async (el) => {
              return await fetchListPromise("jobsites", "jobsiteId", el);
            })
          )
            ?.then((resToUse) => {
              const jobsites = resToUse?.flatMap((el) => el?.value);
              dispatchTable({
                type: "SET_TABLE",
                key: "Jobsites",
                value: jobsites,
              });
            })
            .catch((err) => console.error("Error getting jobsites : ", err));
        }
      }
      return toSet;
    })
    ?.catch((err) => {
      console.error("Error getting data : ", err);
      throw err;
    });

  // return await fetchByDate(
  //   apiTable,
  //   fetchRange,
  //   rangeKey,
  //   apiTable === "degEntries" ? "activityStatus" : null,
  //   apiTable === "degEntries" ? "Completed" : null
  // )
  //   ?.then(async (res = []) => {
  //     const toSet = userAccessRecords(
  //       userConfiguration,
  //       preventDuplicate([...tableData, ...res], idKey)
  //     );

  //     dispatchTable({
  //       type: "SET_TABLE",
  //       key: table,
  //       value: toSet,
  //     });

  //     if (apiTable === "degEntries") {
  //       const uniqueJobsites = uniq(toSet?.map((el) => el?.jobsiteId));

  //       if (uniqueJobsites?.length >= 99) {
  //         await Promise.allSettled(
  //           splitSubArrays(uniqueJobsites, 99)?.map(async (el) => {
  //             return await fetchListPromise("jobsites", "jobsiteId", el);
  //           })
  //         )
  //           ?.then((res) => {
  //             const jobsites = res?.flatMap((el) => el?.value);
  //             dispatchTable({
  //               type: "SET_TABLE",
  //               key: "Jobsites",
  //               value: jobsites,
  //             });
  //           })
  //           .catch((err) => console.error("Error getting jobsites : ", err));
  //       }
  //     }
  //     return toSet;
  //   })
  //   ?.catch((err) => {
  //     console.error("Error getting data : ", err);
  //     throw err;
  //   });
}

/**
 * Adds filter states to the widget configurations based on the provided parameters.
 *
 * @param {Object} allFilterStates - The object containing all filter states.
 * @param {Object} filterChartsParams - The parameters for filter charts.
 * @param {Array} filteredWidgetConfig - The array of filtered widget configurations.
 * @param {Array} cardFilterPreferences - The array of card filter preferences.
 * @param {Array} topicCategories - The array of topic categories.
 * @param {Function} setAllFilterStates - The function to set all filter states.
 * @param {Object} userConfiguration - The user configuration object.
 * @returns {Array} - The array of filter charts to be set.
 */
export function addFilterStates({
  allFilterStates = {},
  filterChartsParams = {},
  filteredWidgetConfig = [],
  cardFilterPreferences = [],
  topicCategories = [],
  setAllFilterStates = () => {},
  userConfiguration,
}) {
  // let sessionData = null;
  let toSet = [];

  const dashboardSession = getDashboardSession();

  const cardFilterObject = cardFilterPreferences?.reduce((acc, curr) => {
    return { ...acc, [curr?.id]: curr };
  }, {});

  const defaultFilterStates = {
    filterByDay: null,
    filterByRange: null,
    filterByTime: "Pick Range",
    filterByName: null,
    filterByStatus: null,
    filterByProjectManager: null,
    filterByPercentage: null,
  };

  filteredWidgetConfig?.forEach((el) => {
    const apiTable =
      tableMapping[el?.BodyComponentParams?.table?.toLowerCase()] ||
      toCamelCase(el?.BodyComponentParams?.table);

    const { filterStates = null } = el?.BodyComponentParams || {};
    const cardSessionData = dashboardSession?.[apiTable]?.[el?.id];

    /**
     * If there is  any data on session storage for the current card, then
     * set the filter states based on the data and apply the filter to the chart.
     */
    if (!!cardSessionData) {
      if (
        typeof cardSessionData === "string" &&
        !dayjs(cardSessionData).isValid
      ) {
        el.BodyComponentParams.filterStates = {
          ...defaultFilterStates,
          ...el?.BodyComponentParams?.filterStates,
          filterByTime: camelCaseToNormalText(cardSessionData),
        };
      } else if (
        Array.isArray(cardSessionData) &&
        cardSessionData?.every((el) => !!dayjs(el)?.isValid())
      ) {
        if (
          dayjs(cardSessionData[0])?.startOf("day")?.valueOf() ===
          dayjs(cardSessionData[1])?.startOf("day")?.valueOf()
        ) {
          el.BodyComponentParams.filterStates = {
            ...defaultFilterStates,
            ...el?.BodyComponentParams?.filterStates,
            filterByDay: dayjs(cardSessionData[0])?.startOf("day"),
          };
        } else {
          if (dayjs(cardSessionData[0])?.startOf("day")?.valueOf() <= 0) {
            el.BodyComponentParams.filterStates = {
              ...defaultFilterStates,
              ...el?.BodyComponentParams?.filterStates,
              filterByTime: "Done All Time",
            };
          } else {
            el.BodyComponentParams.filterStates = {
              ...defaultFilterStates,
              filterByRange: [
                dayjs(cardSessionData[0])?.startOf("day"),
                dayjs(cardSessionData[1])?.endOf("day"),
              ],
            };
          }
        }
      }
    }

    /**
     * If there is any filter states for the current card, then set the filter states
     * based on the data and apply the filter to the chart.
     */
    if (
      !!allFilterStates[el?.id] ||
      !!cardFilterObject[el?.id] ||
      !!filterStates
    ) {
      if (!!cardFilterObject[el?.id]) {
        el.BodyComponentParams.filterStates = {
          ...cardFilterObject[el?.id].BodyComponentParams.filterStates,
        };
      } else if (allFilterStates[el?.id]) {
        el.BodyComponentParams.filterStates = {
          ...allFilterStates[el?.id],
        };
      } else if (!!filterStates) {
        // const { filterByTime, ...rest } = filterStates;

        el.BodyComponentParams.filterStates = {
          ...defaultFilterStates,
          ...el?.BodyComponentParams?.filterStates,
        };
      }
    } else {
      setTablesFilterToStorage("initial", apiTable, el?.id);
    }

    toSet?.push(
      filterChartsFromPref({
        ...filterChartsParams,
        topicCategories,
        card: el,
        userConfiguration,
      })
    );
  });

  return toSet;
}

/**
 * Calculates the sum of repeated elements in an array based on a specified key.
 *
 * @param {Array} array - The input array.
 * @param {string} key - The key to identify the property in each element of the array.
 * @returns {Object} - An object containing the repeated elements as keys and their counts as values.
 */
export const repeatedElementSum = (array = [], key = "") => {
  let toReturn = {};
  array?.forEach((element = {}) => {
    if (Array.isArray(element[key])) {
      element[key]?.forEach?.((el) => {
        if (toReturn[el]) {
          toReturn[el]++;
        } else {
          toReturn[el] = 1;
        }
      });
    } else if (toReturn[element[key]]) {
      toReturn[element[key]]++;
    } else if (!element[key]) {
      delete toReturn[element[key]];
    } else {
      toReturn[element[key]] = 1;
    }
  });
  return toReturn;
};
