import dayjs from "dayjs";
import uniq from "lodash/uniq";
import { categoriesName } from "../../../../Dashboard/logicDataFunctions/calculatingFunctions";
import { formatCurrency } from "../../../../utils";
import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import {
  generateEstimationsForOpportunities,
  repeatedElementSum,
} from "../../../../Dashboard/NewDashboardFunctions";
import { quickStatistics_Schedule_and_Project } from "../../../../Scheduling/helpers/quickStatistics";
import { preventDuplicate } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import {
  costChartData,
  getAmountPerService,
  getProjectCostData,
} from "../../../../Dashboard/addWidgetsModalData";
//this functions returns the filtered data for the specified category and subcategory (example .Leads.leadAddress)
export const StatusFilterFunctions = ({
  tables,
  updatedDynamicStates,
  dynamicStates,
  programFields,
  id,
  // projectsData,
  clear = false,
}) => {
  const dataToFilter = clear ? dynamicStates[id] : updatedDynamicStates[id];
  const statuses = programFields?.find(
    (a) => a?.fieldName === "Statuses Of Categories"
  )?.fieldOptions;
  let dollarUSLocale = Intl.NumberFormat("en-US");
  const projectEstimations = () => {
    let toReturn = {};

    if (!dataToFilter) return toReturn;
    dataToFilter?.forEach((proj) => {
      if (proj.hasOwnProperty("services")) {
        Object.entries(proj?.services)?.forEach(([key, value]) => {
          toReturn[key] = value;
        });
      } else if (proj.hasOwnProperty("totalEstimatesAmount")) {
        Object.entries(proj?.totalEstimatesAmount)?.forEach(([key, value]) => {
          toReturn[key] = value;
        });
      }
    });

    return toReturn;
  };

  const uniqueStatus = uniq(
    dataToFilter
      ?.map((a) => a?.services)
      ?.flat(1)
      ?.map((b) => b?.label)
  );

  const statusesOfCategories = programFields?.find(
    (el) => el?.fieldName === "Statuses Of Categories"
  )?.fieldOptions;

  return {
    Leads: () => ({
      leadStatus: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.leadCompany,
            value: a?.leadStatus,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Leads?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.leadStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            done: dataToFilter?.filter(
              (b) =>
                b?.leadStatus?.toLowerCase() === a?.leadStatus?.toLowerCase()
            ).length,
          };
        }),

      leadAddress: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.leadAddress,
            value: a?.borough,
            color:
              programFields
                ?.find((a) => a?.fieldName === "NY Boroughs")
                ?.fieldOptions?.find(
                  (b) =>
                    b?.boroughName?.toLowerCase() === a?.borough?.toLowerCase()
                )?.boroughColor || "#6D8299",
          };
        }),
    }), //Leads

    Claims: () => ({
      claimStatus: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.claimCategory,
            value: a?.claimStatus,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Claims?.claimStatus?.find(
                  (e) => e.statusName === a?.claimStatus
                )?.statusColor || "#f7f8fa",
          };
        }),

      claimAddress: () =>
        uniq(
          dataToFilter?.map((a) => {
            return {
              label: a?.claimObject?.claimAddress,
              value: dataToFilter?.filter(
                (e) =>
                  e?.claimObject?.claimAddress === a?.claimObject?.claimAddress
              )?.length,
              color: "#f7f8fa",
              labelColor: "black",
            };
          })
        ),
    }),

    Incidents: () => ({
      incidentAddress: () =>
        uniq(
          dataToFilter?.map((a) => {
            return {
              label: a?.incidentAddress,
              value: dataToFilter?.filter(
                (e) => e?.incidentAddress === a?.incidentAddress
              )?.length,
              color: "#f7f8fa",
              labelColor: "black",
            };
          })
        ),

      incidentStatus: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.incidentName,
            value: a?.incidentStatus,
            color: statuses?.Incidents?.find(
              (e) => e.statusName === a?.incidentStatus
            )?.statusColor,
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),

      incidentCategory: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.incidentName,
            value: a?.incidentCategory,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),

      incidentName: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.incidentName,
            value: a?.incidentStatus,
            color: statuses?.Incidents?.find(
              (e) => e.statusName === a?.incidentStatus
            )?.statusColor,
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),

      projectName: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.incidentObject?.projectName,
            value: a?.incidentName,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),
    }),
    Applications: () => {
      const data = dataToFilter?.sort(
        (a, b) =>
          a?.projectName?.localeCompare(b?.projectName) ||
          a?.applicationNo - b?.applicationNo
      );
      return {
        applicationStatus: () =>
          data?.map((a) => {
            return {
              label: `${a?.projectName} - RequisitionNo ${a?.applicationNo}`,
              value: a?.applicationStatus,
              color:
                programFields
                  ?.find((a) => a?.fieldName === "Status colors")
                  ?.fieldOptions?.Requisition?.find(
                    (b) =>
                      b?.statusName?.toLowerCase() ===
                      a?.applicationStatus?.toLowerCase()
                  )?.statusColor || "#6D8299",
              date: a?.createdAt,
              custom: [
                {
                  "Total Amount": {
                    "Current Payment": formatCurrency(a?.totalAmount),
                    "Total Retainage": formatCurrency(a?.totalRetainage),
                    "Total Rental With Tax": formatCurrency(
                      a?.totalRentalWithTax
                    ),
                  },
                },
              ],
              id: a?.projectId,
            };
          }),
      };
    },
    Rentals: () => {
      const data = dataToFilter?.sort(
        (a, b) =>
          a?.projectAddress.localeCompare(b?.projectAddress) ||
          a?.rentalNumber - b?.rentalNumber
      );
      return {
        rentalStatus: () =>
          data?.map((a) => {
            return {
              label: `${a?.projectAddress} - ${a?.rentalNumber}`,
              value: a?.rentalStatus,
              color:
                programFields
                  ?.find((a) => a?.fieldName === "Status colors")
                  ?.fieldOptions?.Rentals?.find(
                    (b) =>
                      b?.statusName?.toLowerCase() ===
                      a?.rentalStatus?.toLowerCase()
                  )?.statusColor || "#6D8299",
              date: a?.createdAt,
              custom: [
                {
                  "Total Amount": {
                    "Total Price": formatCurrency(a?.totalPrice),
                    "Total Applied Rent": formatCurrency(a?.totalAppliedRent),
                    "Total Tax": formatCurrency(a?.totalTax),
                    "Retainage Amount": formatCurrency(a?.retainageAmount),
                  },
                },
              ],
            };
          }),
      };
    },
    Charges: () => {
      const data = dataToFilter?.sort(
        (a, b) =>
          a?.projectAddress?.localeCompare(b?.projectAddress) ||
          a?.chargeNumber - b?.chargeNumber
      );
      return {
        categoryFrom: () =>
          data?.map((a) => {
            return {
              label: `${a?.projectAddress} - ChargeNo ${a?.chargeNumber}`,
              value: a?.categoryFrom,
              color:
                programFields
                  ?.find((a) => a?.fieldName === "Status colors")
                  ?.fieldOptions?.Charge?.find(
                    (b) =>
                      b?.statusName?.toLowerCase() ===
                      a?.categoryFrom?.toLowerCase()
                  )?.statusColor || "#6D8299",
              date: a?.createdAt,
              custom: [
                {
                  "Total Amount": {
                    "Charge Amount With Tax": formatCurrency(
                      a?.chargeAmount || 0
                    ),
                    "Total Tax": formatCurrency(a?.taxAmount || 0),
                    "Charge Amount Without Tax": formatCurrency(
                      (a?.chargeAmount || 0) - (a?.taxAmount || 0)
                    ),
                  },
                },
              ],
            };
          }),
      };
    },
    Invoices: () => {
      const data = dataToFilter?.sort(
        (a, b) =>
          a?.projectName?.localeCompare(b?.projectName) ||
          a?.invoiceNumber - b?.invoiceNumber
      );

      return {
        invoiceStatus: () =>
          data?.map((a) => {
            return {
              label: `${a?.projectName} - InvoiceNr ${a?.invoiceNumber}`,
              value: [
                ...new Set(
                  a?.invoiceItems?.map((item) => categoriesName[item?.category])
                ),
              ]?.toString(),
              color:
                programFields
                  ?.find((a) => a?.fieldName === "Status of Invoices")
                  ?.fieldOptions?.find(
                    (b) => b?.statusName === categoriesName[a?.invoiceStatus]
                  )?.statusColor || "#6D8299",
              date: a?.createdAt,
              custom: [
                {
                  "Total Amount": {
                    "Invoice Amount With Tax": formatCurrency(
                      a?.totalInvoiceAmount || 0
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      a?.subtotal || 0
                    ),
                    "Total Tax": formatCurrency(
                      (a?.totalInvoiceAmount || 0) - (a?.subtotal || 0)
                    ),
                  },
                },
              ],
            };
          }),
      };
    },

    Opportunities: () => ({
      opportunityStatus: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: a?.opportunityStatus,
            color: "#ECDE7D",
          };
        }),

      opportunityStage: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: a?.opportunityStage,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Opportunities?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.opportunityStage?.toLowerCase()
                )?.statusColor || "#6D8299",
          };
        }),

      totalEstimationsAmount: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: formatCurrency(
              getTotalServicesPrice(
                generateEstimationsForOpportunities(
                  a?.estimations,
                  projectEstimations()
                )?.flat()
              )
            ),
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.opportunityId,
          };
        }),

      geoFenceInfo: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.opportunityAddress,
            value: a?.geoFenceInfo?.length.toString(),
            color: "#ECDE7D",
          };
        }),
    }), //Opportunities

    Projects: () => ({
      projectStatus: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.projectName,
            value: a?.projectStatus,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Projects?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.projectStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
          };
        }),

      attorney: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.projectName,
            value: a?.attorney,
            color: "#ECDE7D",
          };
        }),

      expeditor: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.projectName,
            value: a?.expeditor,
            color: "#ECDE7D",
          };
        }),

      projectExecutive: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.projectName,
            value: a?.projectExecutive,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.projectId,
          };
        }),
      totalEstimationsAmount: () =>
        dataToFilter?.map(
          ({ services = [], projectName = "", projectId = "" }) => {
            return {
              label: projectName,
              value: formatCurrency(
                parseFloat(
                  getTotalServicesPrice(Object?.values(services)?.flat())
                )
              ),
              color: "#ECDE7D",
              id: projectId,
            };
          }
        ),

      geoFenceInfo: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.projectAddress,
            value: a?.geoFenceInfo?.length.toString(),
            color: "#ECDE7D",
          };
        }),
    }), //Projects
    Scheduling: () => ({
      typeOfWork: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.scheduleAddress,
            value: a?.typeOfWork,
            color: "#ECDE7D",
          };
        }),

      projectExecutive: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.scheduleAddress,
            value: a?.projectExecutive,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.scheduleId,
          };
        }),

      scheduleAddress: () =>
        dataToFilter?.map((status) => {
          return {
            label: status?.scheduleAddress,
            value: status?.scheduleStatus,
            color:
              programFields
                ?.find((e) => e?.fieldName === "Statuses Of Categories")
                ?.fieldOptions?.Scheduling?.find(
                  (el) =>
                    el?.statusName?.toLowerCase() ===
                    status?.scheduleStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: status?.createdAt,
            id: status?.scheduleId,
          };
        }),
      scheduleTotalProgressPercentage: () =>
        dataToFilter?.map((status) => {
          return {
            label: status?.scheduleAddress,
            value:
              quickStatistics_Schedule_and_Project({
                project: [],
                toBeScheduled: status?.toBeScheduled,
                schedules: [],
                typeOfWork: status?.typeOfWork,
              })?.scheduleTotalProgressPercentage + "%",
            color: "#ECDE7D",
            date: status?.createdAt,
            id: status?.scheduleId,
          };
        }),
    }), //schaduling

    Fleet: () => ({
      fleetYear: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.fleetName,
            value: a?.fleetYear,
            color: "#ECDE7D",
          };
        }),

      registrationExpDate: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.fleetName,
            value: dayjs(a?.registrationExpDate)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
          };
        }),

      inspectionExpDate: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.fleetName,
            value: dayjs(a?.inspectionExpDate)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
          };
        }),
    }), //Fleet

    "Fleet Inspections": () => ({
      inspectedBy: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.fleetName,
            value: a?.inspectedBy?.name,
            color: "#ECDE7D",
          };
        }),

      driverName: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.fleetName,
            value: a?.driverInfo?.driver,
            color: "#ECDE7D",
          };
        }),

      createdAt: () =>
        dataToFilter?.map((a) => {
          return {
            value: dayjs(a?.createdAt)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
          };
        }),

      inspectionStatus: () =>
        (dataToFilter || [])?.map((el) => ({
          label: el?.fleetName,
          value: el?.inspectionStatus,
          color:
            statusesOfCategories?.Inspections?.find(
              (a) => a?.statusName === el?.inspectionStatus
            )?.statusColor || "#6D8299",
        })),

      inspectionType: () =>
        (dataToFilter || [])?.map((el) => ({
          label: el?.fleetName,
          value: el?.inspectionType,
          color: "#ECDE7D",
        })),

      mechanic: () =>
        (dataToFilter || []).map((el) => ({
          label: el?.fleetName,
          value: el?.mechanicInfo?.mechanic,
          color: "#ECDE7D",
        })),
    }), //Inspections

    Violations: () => ({
      amount_due: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.violationLocation,
            value: a?.fine_amount,
            color: "#ECDE7D",
          };
        }),
      issue_date: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.violationLocation,
            value: dayjs(a?.issue_date)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
          };
        }),
    }), //Violations
    Estimations: () => ({
      engProgress: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: `${a?.engProgress || 0}%`,
            color: "#ECDE7D",
          };
        }),
      archProgress: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: `${a?.archProgress || 0}%`,
            color: "#ECDE7D",
          };
        }),
      estSTATUS: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.estSTATUS,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Estimations?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() === a?.estSTATUS?.toLowerCase()
                )?.statusColor || "#6D8299",
          };
        }),
      archAssignedTo: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.archAssignedTo,
            color: "#ECDE7D",
          };
        }),
      engAssignedTo: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.engAssignedTo,
            color: "#ECDE7D",
          };
        }),
      statusByArch: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.statusByArch,
            color: "#ECDE7D",
          };
        }),
      statusByEng: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.statusByEng,
            color: "#ECDE7D",
          };
        }),

      projectExecutive: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.projectExecutive,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),

      amount: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: formatCurrency(
              parseFloat(getTotalServicesPrice(a?.services))
            ),
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),

      amountForServices: () => {
        const data = getAmountPerService(dataToFilter);

        return Object.entries(data).map(([label, value]) => ({
          label,
          value: formatCurrency(value),
          color: "#ECDE7D",
        }));
      },
    }), //Estimations

    "Add-ons": () => ({
      amount: () =>
        uniqueStatus?.map((status) => {
          return {
            label: status,
            value:
              dollarUSLocale?.format(
                uniqueStatus
                  ?.map((status) =>
                    dataToFilter
                      ?.map((c) => c?.services)
                      ?.flat(1)
                      ?.filter((d) => d?.label === status && status)
                  )
                  ?.map((e) => e?.map((f) => f?.serviceOptions))
                  ?.map((g) =>
                    g
                      ?.flat(2)
                      ?.map((h) => h?.items)
                      ?.flat(1)
                      ?.map((i) => parseInt(i?.price) || 0)
                      ?.reduce((c, p) => c + p, 0)
                  )[uniqueStatus?.indexOf(status)]
              ) + " $",
            color: "#ECDE7D",
          };
        }),
    }), //Add-ons

    Safety: () => ({
      safetyApplicationStatus: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.safetyName || "Save Draft",
            value: a?.safetyApplicationStatus,
            color:
              programFields
                ?.find((e) => e?.fieldName === "Statuses Of Categories")
                ?.fieldOptions?.Safety?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.safetyApplicationStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.safetyApplicationId,
          };
        }),
      safetyName: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.safetyName || "Save Draft",
            value: a?.safetyApplicationStatus,
            color:
              programFields
                ?.find((e) => e?.fieldName === "Statuses Of Categories")
                ?.fieldOptions?.Safety?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.safetyApplicationStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.safetyApplicationId,
          };
        }),
      projectName: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.safetyApplicationObject?.projectName,
            value: a?.safetyApplicationStatus,
            color:
              programFields
                ?.find((e) => e?.fieldName === "Statuses Of Categories")
                ?.fieldOptions?.Safety?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.safetyApplicationStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.projectId,
          };
        }),
      accountName: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.safetyApplicationObject?.accountName,
            value: a?.safetyApplicationStatus,
            color:
              programFields
                ?.find((e) => e?.fieldName === "Statuses Of Categories")
                ?.fieldOptions?.Safety?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.safetyApplicationStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.accountId,
          };
        }),

      scheduleAddress: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.safetyApplicationObject?.scheduleAddress,
            value: a?.safetyApplicationStatus,
            color:
              programFields
                ?.find((e) => e?.fieldName === "Statuses Of Categories")
                ?.fieldOptions?.Safety?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.safetyApplicationStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.scheduleId,
          };
        }),
    }),

    "To Do": () => ({
      todoStatus: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.todoStatus,
            value: a?.todoName,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.["To Do"]?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.todoStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
          };
        }),

      todoCategory: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.todoName,
            value: a?.todoCategory,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),

      relatedTo: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.todoName,
            value: a?.relatedTo,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),

      todoUser: () =>
        dataToFilter?.map((a) => {
          return {
            label: a?.todoName,
            value: a?.todoUser,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
    }),

    "Project Cost": () => ({
      companyEmployees: () =>
        Object.entries(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "company"
          )
        ).map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        })),

      jobsiteAddress: () =>
        Object.entries(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "jobsiteId"
          )
        )?.map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        })),

      punchType: () =>
        Object.entries(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "punchType"
          )
        ).map(([label, value]) => ({
          label,
          value,
          color: "#6D8299",
        })),

      role: () =>
        Object.entries(
          repeatedElementSum(
            preventDuplicate(dataToFilter, "employeeId"),
            "role"
          )
        ).map(([label, value]) => ({ label, value, color: "#6D8299" })),

      jobsiteTotalCost: () => {
        const costDispersion = getProjectCostData({
          data: dataToFilter,
          jobsites: tables?.Jobsites,
        });

        const { statusData = [] } = costChartData(
          costDispersion,
          "jobsiteAddress",
          "total",
          formatCurrency
        );

        return statusData;
      },

      jobsiteTotalHours: () => {
        const costDispersion = getProjectCostData({
          data: dataToFilter,
          jobsites: tables?.Jobsites,
        });

        const { statusData = [] } = costChartData(
          costDispersion,
          "jobsiteAddress",
          "totalHours"
        );

        return statusData;
      },

      jobsiteAccount: () => {
        const costDispersion = getProjectCostData({
          data: dataToFilter,
          jobsites: tables?.Jobsites,
        });

        return costDispersion?.map((el) => ({
          label: el?.jobsiteAddress,
          value: el?.accountName?.accountName,
          color: "#6D8299",
        }));
      },

      jobsiteAccountType: () => {
        const costDispersion = getProjectCostData({
          data: dataToFilter,
          jobsites: tables?.Jobsites,
        });

        return costDispersion?.map((el) => ({
          label: `${el.jobsiteAddress} - ${el?.accountName?.accountName || ""}`,
          value: el?.accountName?.accountRecordType,
          color: "#6D8299",
        }));
      },
    }),

    Inspections: () => {
      const statuses = (statusesOfCategories?.Inspection || [])?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.statusName]: curr?.statusColor,
        }),
        {}
      );

      return {
        inspectionType: () => {
          return dataToFilter?.map((el) => ({
            label: el?.inspectionType,
            value: el?.projectName,
            color: statuses?.[el?.inspectionType] || "#6D8299",
            id: el?.inspectionId,
          }));
        },

        inspectionStatus: () => {
          return dataToFilter?.map((el) => ({
            label: el?.inspectionStatus,
            value: el?.projectName,
            color: statuses?.[el?.inspectionStatus] || "#6D8299",
            id: el?.inspectionId,
          }));
        },

        inspectedBy: () => {
          return dataToFilter?.map((el) => ({
            label: el?.inspectedBy,
            value: el?.projectName,
            color: statuses?.[el?.inspectionStatus] || "#6D8299",
            id: el?.inspectionId,
          }));
        },
      };
    },
  }; //StatusProgress
};
