import { message } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadableComp } from "../../XComponents";
// import NewOtherIncidentModal from "./NewOtherIncident";
import { queryParams } from "../../../../utils/queryParams";
// import NewPersonalInjuryIncidentModal from "./NewPersonalInjuryIncidentModal";
// import NewPropertyDamageIncidentModal from "./NewPropertyDamageIncidentModal";
import { IncidentModal as VehicleDamageModal } from "../../Fleet/view/pageView/components/SingleTruckIncidents/modals";
import Report from "../../DynamicView/Pages/Overview/components/Report/Report";
// import ToDoModal from "../../ToDos/ToDoModal";
import NewIncidentModal from "./NewIncidentModal";
import EmailBox from "../../Communication/components/EmailBox/EmailBox";
import { openEmailBox } from "../../Communication/functions";

// const modalType = {
//   // "Personal Injury": NewPersonalInjuryIncidentModal,
//   // "Property Damage": NewPropertyDamageIncidentModal,
//   "Vehicle Damage": VehicleDamageModal,
//   // "Other Trade Incident": NewOtherIncidentModal,
//   "Personal Injury": NewIncidentModal,
//   "Property Damage": NewIncidentModal,
//   "Other Trade Incident": NewIncidentModal,
// };

function IncidentModal({
  refreshTable = () => {},
  setEdit = null,
  category = null,
  requestObject = null,
  showNextStep = true,
  requestId = "",
  propProjId = "",
  propAddress = "",
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  afterSaveRequest = async () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [attachments, setAttachments] = useState([]);
  const [emailBoxes, setEmailBoxes] = useState([]);
  const [documentationModalFiles, setDocumentationModalFiles] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [rowObject, setRowObject] = useState(
    location?.state?.rowObject || requestObject || undefined
  );
  const [visibleReport, setVisibleReport] = useState(false);

  const [cat, setCat] = useState(
    category
      ? category
      : rowObject?.incidentCategory
      ? rowObject?.incidentCategory
      : !!rowObject
      ? "Vehicle Damage"
      : undefined
  );

  // const ModalType = modalType[cat];

  function onClose() {
    if (!category && !location?.state?.rowObject) {
      let p = location.pathname === "/incidents";
      navigate(p ? "/incidents" : "/fleets/overview", {
        search: queryParams({ tab: p ? cat : "Incidents" }),
      });
    } else if (!!setEdit) {
      setEdit(false);
    } else {
      navigate(-1);
    }
  }

  async function getSingleIncidentData() {
    message.loading({
      content: "Loading Form...",
      key: "modalLoading",
    });

    let id = location.pathname.replace("/incidents/", "");

    Promise.allSettled([API.get("incidents", `/incidents/${id}`)])
      .then(([inc]) => {
        if (inc.status === "fulfilled") {
          setRowObject(inc.value);
          setCat(inc.value.incidentCategory);
          setDocumentationModalFiles(null);
          message.destroy("modalLoading");
        } else {
          throw new Error("No Data Found");
        }
      })
      .catch((err) => {
        console.error("Error Getting Data: ", err);
        navigate("/incidents");
      });
  }

  useEffect(() => {
    if (!category && !rowObject) {
      getSingleIncidentData();
    }
  }, []);

  return (
    <LoadableComp loading={!cat}>
      {/* <ModalType
        {...{
          visible: true,
          setVisible: onClose,
          deleteRow: onClose,
          showNextStep,
          refreshTable,
          rowObject,
          setVisibleReport,
          isDarkMode,
          requestId,
          propProjId,
          propAddress,
          nextStepHandler,
          handleSavedRecord,
          setInspections,
          setTasksVisible,
        }}
      /> */}

      {cat === "Vehicle Damage" ? (
        <VehicleDamageModal
          {...{
            visible: true,
            setVisible: onClose,
            deleteRow: onClose,
            showNextStep,
            refreshTable,
            rowObject,
            setVisibleReport,
            isDarkMode,
            requestId,
            propProjId,
            propAddress,
            nextStepHandler,
            handleSavedRecord,
            afterSaveRequest,
          }}
        />
      ) : (
        <NewIncidentModal
          {...{
            visible: true,
            getSingleIncidentData,
            setVisible: onClose,
            deleteRow: onClose,
            showNextStep,
            refreshTable,
            rowObject,
            setVisibleReport,
            isDarkMode,
            requestId,
            propProjId,
            propAddress,
            nextStepHandler,
            handleSavedRecord,
            incidentCategory: cat,
            afterSaveRequest,
            documentationModalFiles,
            setDocumentationModalFiles,
          }}
        />
      )}
      {visibleReport && (
        <Report
          {...{
            isModalVisible: visibleReport,
            onCancel: () => setVisibleReport(false),
            customCategoryName: `${
              rowObject?.incidentCategory || "Incident"
            } Report`,
            customPartitionKeys: { identityId: "incidentId" },
            recordId: rowObject?.incidentId || "",
            setVisibleReport: setVisibleReport,
            emailBoxes,
            setEmailBoxes,
            setAttachments,
            sendByEmail: true,
          }}
        />
      )}

      {emailBoxes.length > 0 && (
        <EmailBox
          {...{
            rowData: rowObject,
            onClose: () => {
              if (attachments.length > 0) setAttachments([]);
              setEmailBoxes([]);
            },
            attachments,
            openEmailBox,
          }}
        />
      )}

      {/* <SafetyModal */}
      {/* {toDoVisible && (
        <ToDoModal
          {...{
            toDoVisible,
            setToDoVisible,
            recordId: rowObject?.incidentId,
            recordName: rowObject?.incidentName,
            isDarkMode,
          }}
        />
      )} */}
    </LoadableComp>
  );
}

export default IncidentModal;
