export const charges = (
    updatedDynamicStates,
    IdHandler,
    programFields,
    chargesData
) => {
    return {
        categoryFrom: () => ({
            title: "Charges - Category From",
            BodyComponent: "Progress",
            type: "Progress",
            BodyComponentParams: {
                table: "Charges",
                field: "categoryFrom",
                secondaryField: "projectAddress",
                filterField: [],
                id: IdHandler,
                statusData: chargesData?.map((a) => {
                    return {
                        label: `${a?.projectAddress} - ${a?.chargeNumber}`,
                        value: a?.categoryFrom,
                        color:
                            programFields
                                ?.find((a) => a?.fieldName === "Status colors")
                                ?.fieldOptions?.Charge?.find(
                                    (b) =>
                                        b?.statusName?.toLowerCase() ===
                                        a?.categoryFrom?.toLowerCase()
                                )?.statusColor || "#6D8299",
                        date: a?.createdAt,
                    };
                }
                ),
            },
            id: IdHandler,
            width: "795px",
        }),
    };
};