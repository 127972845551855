import { SimplePLI } from "./GeneralModels"

export class HangingScaffoldPLI extends SimplePLI {
  serviceId = 17

  length
  height

  custom //bool

  constructor(id, taxRate) {
    super(id, taxRate)
  }
}
