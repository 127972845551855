import { isEmpty } from "lodash";
import { camelCaseToNormalText } from "../../../../commonComponents/RowDataGridModal/components/utils";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

function hearingPdfRowData(rowData) {
  let ObjectToArray = [];
  if (!isEmpty(rowData)) {
    const { hearingObject, ...rest } = rowData;
    const newRowData = { ...rest, ...hearingObject };
    for (const key in newRowData) {
      if (key.includes("Id")) {
        delete newRowData[key];
      }
    }
    delete newRowData["lastModifiedBy"];
    delete newRowData["googleDriveUpload"];
    delete newRowData["viewLogs"];
    delete newRowData["teamsConfiguration"];
    ObjectToArray = Object.keys(newRowData)?.map((key) => {
      if (key === "createdBy" || key === "safety") {
        return { key: camelCaseToNormalText(key), value: newRowData[key].name };
      } else if (key === "createdAt") {
        return {
          key: camelCaseToNormalText(key),
          value: dayjsNY(newRowData[key]).format("MM/DD/YYYY"),
        };
      } else if (key === "hearingDate") {
        return {
          key: camelCaseToNormalText(key),
          value: dayjsNY(newRowData[key]).format("MM/DD/YYYY"),
        };
      } else if (key === "assignedTo") {
        return {
          key: camelCaseToNormalText(key),
          value: Array.isArray(newRowData[key])
            ? newRowData[key]?.map((user) => user?.nameOfUser)?.join(", ")
            : newRowData[key],
        };
      } else if (key === "time") {
        return {
          key: camelCaseToNormalText(key),
          value: dayjsNY(newRowData[key]).format("hh:mm A"),
        };
      } else if (key === "hearingName") {
        return { key: "Representative", value: newRowData[key] };
      }
      return { key: camelCaseToNormalText(key), value: newRowData[key] };
    });
  }
  return ObjectToArray;
}

export default hearingPdfRowData;
