import { Fragment } from "react";
import { Tooltip } from "antd";
import { MondayButton } from "src/components/commonComponents";
import { OpenLinkIcon } from "src/assets";

const colors = {
  COMPLETED: "#7cca00",
  PENDING: "#e9c400",
  UPLOADED: "#0a8700",
};

const columnDefs = [
  {
    headerName: "Truck #",
    field: "fleetName",
  },
  {
    headerName: "Audit Status",
    field: "auditStatus",
    cellClass: "cell-center-aligned",
    cellRenderer({ value }) {
      return (
        <div
          className="audit-grid-status"
          style={{ backgroundColor: colors?.[value] }}
        >
          {value[0] + value.toLowerCase().slice(1)}
        </div>
      );
    },
  },
  {
    headerName: "Actions",
    cellStyle: {
      display: "grid",
      placeItems: "center",
    },
    cellRenderer({ data, context }) {
      if (data?.auditStatus === "PENDING" || data?.auditStatus === "UPLOADED") {
        return (
          <Tooltip title="Go to Audit">
            <MondayButton
              {...{
                Icon: <OpenLinkIcon />,
                className: "mondayButtonBlue",
                onClick() {
                  if (context?.goToAudit) {
                    context.goToAudit(data);
                  }
                },
              }}
            >
              {null}
            </MondayButton>
          </Tooltip>
        );
      } else if (data?.auditStatus === "COMPLETED") {
        return (
          <Fragment>
            <Tooltip title="Go to Audit">
              <MondayButton
                {...{
                  Icon: <OpenLinkIcon />,
                  className: "mondayButtonBlue",
                  onClick() {
                    if (context?.goToAudit) {
                      context.goToAudit(data);
                    }
                  },
                }}
              >
                {null}
              </MondayButton>
            </Tooltip>
          </Fragment>
        );
      } else {
        return null;
      }
    },
  },
];

export default columnDefs;
