import moment from "moment";
import { Form, InputNumber } from "antd";
import React, { useEffect } from "react";
import { WarningTwoTone } from "@ant-design/icons";

import {
  style,
  dateTypes,
  initialNumberOptions,
  ERROR_MESSAGE_STEP_INPUTS,
  areRequiredFieldsPresent,
  inputTypes,
} from "./helpers";
import { convertDate } from "../../helpers";
import { RequiredLabel } from "./RequiredLabel";
import {
  GPlacesAutocompleteInput,
  InputComponent,
  SimplePlacesInput,
} from "../../../../SidebarPages/Fleet/components";
import CustomCheckBox from "../../../CustomCheckBox/CustomCheckBox";

const checkIsDateOrTime = (type) => dateTypes.includes(type);
const checkIsNotInput = (type) => inputTypes.includes(type);

export const NextStepInputs = ({
  inputData,
  setInputData,
  inputFields,
  checkBoxes,
}) => {
  const inputFieldsData = inputFields.filter((field) => !!!field.hidden);

  function onInputChangeHandler(value, key) {
    setInputData((prev) => ({ ...prev, [key]: value }));
  }

  function onDateChangeHandler(value, key) {
    const newDate = moment(value).tz("America/New_York").valueOf();
    onInputChangeHandler(newDate, key);
  }

  useEffect(() => {
    const shouldDisable = !areRequiredFieldsPresent(inputData, inputFields);

    const submitButton = document.getElementById("submitButton");
    const nextButton = document.getElementById("nextButton");

    if (submitButton) {
      submitButton.disabled = shouldDisable;
    }

    if (nextButton) {
      nextButton.disabled = shouldDisable;
    }
  }, [inputData]);

  return (
    <Form style={style.container}>
      {inputFieldsData.length > 0 ? (
        inputFieldsData.map((input, index) => {
          const isDateOrTime = checkIsDateOrTime(input.type);
          const isNotInput = checkIsNotInput(input.type);

          const value = inputData[input.name];

          const defaultValue = isDateOrTime
            ? convertDate(value)
            : input?.notApplicable
            ? "N/A"
            : value;

          const hasCheckbox = checkBoxes?.find(
            (checkbox) => checkbox.itemName === input.name
          );

          if (input.type === "number") {
            return (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <RequiredLabel
                  {...{ required: input?.required, label: input.label }}
                />
                <InputNumber
                  {...{
                    ...input,
                    ...initialNumberOptions,
                    style: style.inputNumber,
                    value: defaultValue,
                    defaultValue,
                    onChange: (value) =>
                      onInputChangeHandler(
                        parseFloat(value).toFixed(2),
                        input.name
                      ),
                  }}
                />
              </div>
            );
          }

          if (input.type === "placesautocomplete")
            return (
              <SimplePlacesInput
                {...{
                  ...input,
                  value: defaultValue,
                  defaultValue,
                  onChange: (value) => onInputChangeHandler(value, input.name),
                }}
              />
            );

          return (
            <div style={style.inputContainer}>
              <InputComponent
                {...{
                  ...input,
                  style: style.input,
                  value: defaultValue,
                  defaultValue,
                  onChange: (e) => {
                    if (isDateOrTime) {
                      onDateChangeHandler(e, input.name);
                    } else if (isNotInput) {
                      onInputChangeHandler(e, input.name);
                    } else {
                      onInputChangeHandler(e.target.value, input.name);
                    }
                  },
                }}
              />
              {hasCheckbox && (
                <CustomCheckBox
                  checked={hasCheckbox.isChecked}
                  value={false}
                  name={hasCheckbox.checkBoxName}
                  onChange={hasCheckbox.onCheckboxChange}
                  textStyle={{ fontSize: "13px" }}
                />
              )}
            </div>
          );
        })
      ) : (
        <div style={style.error}>
          <WarningTwoTone twoToneColor="red" style={{ fontSize: "25px" }} />
          <p>{ERROR_MESSAGE_STEP_INPUTS}</p>
        </div>
      )}
    </Form>
  );
};
