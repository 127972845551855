import { useWatch } from "antd/es/form/Form";

const useIncidentsFieldsWatch = (form, incidentCategory) => {
  switch (incidentCategory) {
    case "Property Damage": {
      const [selectedForeman, typeOfEquipment] = [
        useWatch("foremanOfCrewThatCausedTheDamage", form),
        useWatch("typeOfEquipmentInvolved", form),
      ];

      return { selectedForeman, typeOfEquipment };
    }

    case "Personal Injury": {
      const [selectedService, typeOfEquipment, immediateActions] = [
        useWatch("serviceBeingDone", form),
        useWatch("typeOfEquipmentInvolved", form),
        useWatch("immediateActionsTakenFollowingTheIncident", form),
      ];

      return { selectedService, typeOfEquipment, immediateActions };
    }

    case "Other Trade Incident": {
      const [typeOfEquipment, immediateActions] = [
        useWatch("typeOfEquipmentInvolved", form),
        useWatch("describeTheImmediateActionsTakenFollowingTheIncident", form),
      ];

      return { typeOfEquipment, immediateActions };
    }
  }
};

export default useIncidentsFieldsWatch;
