import { updateRental } from "../../../../../../../../Utils/rentalManipulation";

const applyThisRowHandler = ({
  selectedService,
  setFakeRentals,
  elevationData,
  fakeRentals,
  pliData,
}) => {
  const thisElevation = elevationData?.elevationId;
  const thisPli = pliData?.id;
  //this const is used to tell the pc what to do on click
  //extract all days from the selected/elevation and check if all of the days are applied
  //if they are returns false(which makes the un apply function)
  //if they are not returns true(which makes apply function)
  const applied = !fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.find(({ id }) => id === thisPli)
    ?.rentalDetails?.details?.flatMap(({ days }) => days)
    ?.every(({ applied }) => !!applied);

  const newRental = {
    ...fakeRentals,
    //we access only service key bcs thats the only key we want to update
    services: fakeRentals?.services?.map((rentalDetailsData) =>
      // check if this is the service that we have selected so we can modify it
      rentalDetailsData?.label === selectedService
        ? {
            ...rentalDetailsData,
            serviceOptions: rentalDetailsData?.serviceOptions?.map(
              (elevationData) =>
                // check if this is the elevation that we have selected so we can modify it
                elevationData?.elevationId === thisElevation
                  ? {
                      ...elevationData,
                      items: elevationData?.items?.map((pliData) =>
                        // check if this is the pli that we have selected so we can modify it
                        pliData?.id === thisPli
                          ? //if the pli that we selected is charged we updates only the days that are not charged if have any
                            pliData?.charged
                            ? {
                                ...pliData,
                                rentalDetails: {
                                  ...pliData?.rentalDetails,
                                  details: pliData?.rentalDetails?.details?.map(
                                    (weekData) =>
                                      //if this week is charged we modify only the days that they are not (if any)
                                      weekData?.charged
                                        ? {
                                            ...weekData,
                                            days: weekData?.days?.map(
                                              (daysData) =>
                                                // if this day is charged returns it unchanged
                                                daysData?.charged
                                                  ? daysData
                                                  : //if this day is not charged we set the ne value for the checkbox for this day
                                                    { ...daysData, applied }
                                            ),
                                          }
                                        : {
                                            ...weekData,
                                            //here we set the ne value for the checkbox for this week
                                            applied,
                                            days: weekData?.days?.map(
                                              (daysData) => ({
                                                ...daysData,
                                                //here we set the ne value for the checkbox for this day
                                                applied,
                                              })
                                            ),
                                          }
                                  ),
                                },
                              }
                            : //if this pli is not charged we updates all of its days
                              {
                                ...pliData,
                                //here we set the ne value for the checkbox for this day
                                applied,
                                lock: true,
                                //here we access and modify this pli's rental details
                                rentalDetails: {
                                  ...pliData?.rentalDetails,
                                  details: pliData?.rentalDetails?.details?.map(
                                    (weekData) => ({
                                      ...weekData,
                                      //here we set the ne value for the checkbox for this week
                                      applied,
                                      days: weekData?.days?.map((daysData) => ({
                                        ...daysData,
                                        //here we set the ne value for the checkbox for this day
                                        applied,
                                      })),
                                    })
                                  ),
                                },
                              }
                          : //if this is not the PLI that the checkbox is on we return it unchanged
                            pliData
                      ),
                    }
                  : //if this is not the elevation that the checkbox is on we return it unchanged
                    elevationData
            ),
          }
        : //if this is not the selected service we return it unchanged
          rentalDetailsData
    ),
  };

  setFakeRentals(newRental);
};

const applyThisWeekHandler = ({
  selectedService,
  setUnchangingFakeRentals,
  elevationData,
  fakeRentals,
  thisWeek,
  pliData,
}) => {
  const thisElevation = elevationData?.elevationId;
  const thisPli = pliData?.id;

  //this const is used to tell the pc what to do on click
  //extract all days from the selected/elevation and check if all of the days are applied
  //if they are returns false(which makes the un apply function)
  //if they are not returns true(which makes apply function)
  const applied = !fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.find(({ id }) => id === thisPli)
    ?.rentalDetails?.details?.find(({ week }) => week === thisWeek)
    ?.days?.every(({ applied }) => !!applied);

  //here we find how many weeks are applied
  const checker = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === elevationData?.elevationId
    )
    ?.items?.find(({ id }) => id === pliData?.id)
    ?.rentalDetails?.details?.filter(({ applied }) => applied === true);

  //now if its only one week applied and we click in this pli, the pli will automatically become unapplied else it will remain applied
  const weekApplied =
    checker?.length === 1 ? !(checker[0]?.id === thisWeek) : true;

  const newRental = {
    ...fakeRentals,
    // we access only service key bcs thats the only key we want to update
    services: fakeRentals?.services?.map((serviceData) =>
      serviceData?.label === selectedService
        ? // check if this is the service that we have selected so we can modify it
          {
            ...serviceData,
            //we access only serviceOptions key bcs thats the only key we want to update inside the selected service
            serviceOptions: serviceData?.serviceOptions?.map((elevationData) =>
              // check if this is the elevation that we have selected so we can modify it
              elevationData?.elevationId === thisElevation
                ? {
                    ...elevationData,
                    items: elevationData?.items?.map((pliData) =>
                      // check if this is the pli that we have selected so we can modify it
                      pliData?.id === thisPli
                        ? //if this pli is charged we updates only the days that are not charged if have any
                          pliData?.charged
                          ? //IF PLI IS CHARGED
                            {
                              ...pliData,
                              rentalDetails: {
                                ...pliData?.rentalDetails,
                                details: pliData?.rentalDetails?.details?.map(
                                  (weekData) =>
                                    //CHECK  IF THIS IS THE WEEK THAT WE WANT TO MODIFY
                                    weekData?.week === thisWeek
                                      ? //IF THIS WEEK IS CHARGED MODIFY ONLY UNCHARGED DAYS
                                        weekData?.charged
                                        ? {
                                            ...weekData,
                                            //here we access the days details and check if we can modify any day
                                            days: weekData?.days?.map(
                                              (daysData) =>
                                                // if this day is charged returns it unchanged
                                                daysData?.charged
                                                  ? daysData
                                                  : //if this day is not charged we set the ne value for the checkbox for this day
                                                    { ...daysData, applied }
                                            ),
                                          }
                                        : //IF THIS WEEK IS NOT CHARGED MODIFY THE WEEK OBJECT AND DAYS
                                          {
                                            ...weekData,
                                            //here we set the ne value for the checkbox for this week
                                            applied,
                                            days: weekData?.days?.map(
                                              (daysData) => ({
                                                ...daysData,
                                                //here we set the ne value for the checkbox for this day
                                                applied,
                                              })
                                            ),
                                          }
                                      : //ELSE IF THIS IS NOT THE WEEK THAT WE WANT TO CHANGE RETURN IT UNCHANGED
                                        weekData
                                ),
                              },
                            }
                          : //IF PLI IS NOT CHARGED
                            {
                              ...pliData,
                              //here we set the ne value for the checkbox for this day
                              applied: weekApplied,
                              lock: true,
                              rentalDetails: {
                                ...pliData?.rentalDetails,
                                details: pliData?.rentalDetails?.details?.map(
                                  (weekData) =>
                                    //FIND THE WEEK THAT WE WANT TO EDIT
                                    weekData?.week === thisWeek
                                      ? {
                                          ...weekData,
                                          //here we set the ne value for the checkbox for this day
                                          applied,
                                          days: weekData?.days?.map(
                                            (daysData) => ({
                                              ...daysData,
                                              //here we set the ne value for the checkbox for this day
                                              applied,
                                            })
                                          ),
                                        }
                                      : //IF THIS IS NOT THE WEEK THAT WE WANT TO EDIT RETURN IT UNCHANGED
                                        weekData
                                ),
                              },
                            }
                        : //IF THIS IS NOT THE PLI THAT WE WANT
                          pliData
                    ),
                  }
                : //if this is not the elevation that the checkbox is on we return it unchanged
                  elevationData
            ),
          }
        : //if this is not the selected service we return it unchanged
          serviceData
    ),
  };

  setUnchangingFakeRentals(newRental);
};

const applyThisDayHandler = ({
  setUnchangingFakeRentals,
  selectedService,
  elevationData,
  fakeRentals,
  thisWeek,
  checked,
  pliData,
  thisDay,
}) => {
  const applied = checked?.target?.checked;
  const thisElevation = elevationData?.elevationId;
  const thisPli = pliData?.id;

  const checkerData = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === elevationData?.elevationId
    )
    ?.items?.find(({ id }) => id === pliData?.id)?.rentalDetails?.details;

  //finds all the applied days in this week
  const weekChecker = checkerData
    ?.find(({ week }) => week === thisWeek)
    ?.days?.filter(({ applied }) => applied === true);

  //finds all the applied days in this pli
  const pliChecker = checkerData
    ?.flatMap(({ days }) => days)
    ?.filter(({ applied }) => applied === true);

  //now if its only one day in this week applied and we click that day this week will automatically become unapplied else it will remain applied
  const weekApplied =
    weekChecker?.length === 1 ? !(weekChecker[0]?.id === thisDay) : true;

  //now if its only one day in this pli is applied and we click on that day the pli will automatically become unapplied else it will remain applied
  const pliApplied =
    pliChecker?.length === 1 ? !(pliChecker[0]?.id === thisDay) : true;

  //the new rental object
  const newRental = updateRental({
    weekParams: { applied: weekApplied },
    pliProps: { applied: pliApplied },
    overwriteLockedWeek: true,
    overwriteLockedDay: true,
    daysParams: { applied },
    selectedService,
    thisElevation,
    fakeRentals,
    thisWeek,
    thisPli,
    thisDay,
  });

  setUnchangingFakeRentals(newRental);
};

export const functionsAdapter = ({
  selectedService,
  setFakeRentals,
  setUnchangingFakeRentals,
  elevationData,
  fakeRentals,
  checked,
  pliData,
  params,
}) => ({
  pli: () =>
    applyThisRowHandler({
      selectedService,
      setFakeRentals,
      elevationData,
      fakeRentals,
      pliData,
    }),
  week: () =>
    applyThisWeekHandler({
      thisWeek: params?.thisWeek?.week,
      selectedService,
      setUnchangingFakeRentals,
      elevationData,
      fakeRentals,
      pliData,
    }),
  day: () =>
    applyThisDayHandler({
      thisWeek: params?.thisWeek?.week,
      thisDay: params?.thisDay?.id,
      setUnchangingFakeRentals,
      selectedService,
      elevationData,
      fakeRentals,
      checked,
      pliData,
    }),
});

//THIS FUNCTION FIND THE VALUE OF CHECKBOX
export const checkBoxValueHandler = ({
  selectedPliWeekData,
  thisWeek,
  thisDay,
}) => {
  //here we check if any of the weeks are applied and if there are the applied checkbox takes true value
  const selectedPli = selectedPliWeekData?.some(({ applied }) => !!applied);

  //here we check if this week is applied or not
  const selectedWeek =
    !!thisWeek && selectedPliWeekData?.find(({ week }) => week === thisWeek);

  //here we check if this daY is applied or not
  const selectedDay =
    !!thisDay &&
    selectedPliWeekData
      ?.find(({ week }) => week === thisWeek)
      ?.days?.find(({ id }) => id === thisDay);

  return {
    pli: selectedPli,
    week: selectedWeek?.applied,
    day: selectedDay?.applied,
  };
};

//THIS FUNCTION CHECKS IF CHECKBOX SHOULD ME INTERMINABLE OR NOT
export const indeterminateHandler = ({ selectedPliWeekData, thisWeek }) => {
  const pliChecked = (type) =>
    selectedPliWeekData?.[type](({ applied }) => applied === true);

  const weekChecked = (type) =>
    //if the function is not on the week we dont do the calculations
    !!thisWeek &&
    //else if it is we check if all weeks or some weeks are applied based on the type
    selectedPliWeekData
      ?.find(({ week }) => week === thisWeek)
      ?.days?.[type](({ applied }) => applied === true);

  return {
    //if all pli's are not applied and some of them are return true
    pli: pliChecked("some") && !pliChecked("every"),
    //if all weeks are not applied and some of them are return true
    week: weekChecked("some") && !weekChecked("every"),
    //days dont have intermediate value
    day: false,
  };
};

//this function tell the user why the date input is disabled
export const getDisabledTooltipContent = ({
  selectedPliWeekData,
  isWritable,
  thisWeek,
  thisDay,
}) => {
  //here we check the availability of the checkbox for the pli that contains the checkbox
  const pli =
    //if we dont have any week in this pli we have to disable the checkbox
    selectedPliWeekData?.length === 0
      ? "You have to pick the period before modifying it!"
      : //if all the days in this pli are charged we have to disable this pli
      selectedPliWeekData
          ?.flatMap(({ days }) => days)
          ?.every(({ charged }) => !!charged)
      ? "All days in this pli are charged!!"
      : //if the write mode is off we have to disable the checkbox
      !isWritable
      ? "Enable write mode to edit!"
      : //else if none of the conditions above are true we dont have to disable the checkbox
        "";

  //here we check the availability of the checkbox for the week that contains the checkbox
  const week =
    //if the function is not on the week we dont do the calculations
    !!thisWeek &&
    //else if it is we check if all the days are charged and if they are we disable the checkbox
    selectedPliWeekData
      ?.find(({ week }) => week === thisWeek)
      ?.days?.every(({ charged }) => !!charged)
      ? "All days in this week are charged!!"
      : //else if the write mode is off we also disable the checkbox
      !isWritable
      ? "Enable write mode to edit!"
      : //else if none of the conditions above are true we dont have to disable the checkbox
        "";

  const day =
    //if the function is not on the week we dont do the calculations
    !!thisDay &&
    //else if it is we check if this day is charged we disable the checkbox
    selectedPliWeekData
      ?.find(({ week }) => week === thisWeek)
      ?.days?.find(({ id }) => id === thisDay)?.charged
      ? "Already charged!!"
      : //else if the write mode is off we also disable the checkbox
      !isWritable
      ? "Enable write mode to edit!"
      : //else if none of the conditions above are true we dont have to disable the checkbox
        "";

  return {
    pli,
    week,
    day,
  };
};

export const selectedPliExtractor = ({
  object,
  selectedService,
  thisElevation,
  thisPli,
}) =>
  object?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.find(({ id }) => id === thisPli)?.rentalDetails?.details;
