export function getStatus({ title = "", statusName = "", record }) {
  switch (title) {
    case "Vendors":
      return statusName?.toLowerCase() === record?.vendorStatus?.toLowerCase();
    case "Bills":
      return statusName?.toLowerCase() === record?.billStatus?.toLowerCase();
    default:
      return true;
  }
}
