import { UndoOutlined, RedoOutlined } from "@ant-design/icons";

import MondayButton from "../MondayButton/MondayButton";

import "./UndoRedoButtons.scss";

export const UndoRedoButtons = ({
  canUndo,
  canRedo,
  undoClickHandler,
  redoClickHandler,
  tooltipCategory,
}) => {
  return (
    <div className="undoRedoButtonsContainer">
      <MondayButton
        // type="primary"
        disabled={!canUndo}
        className={!canUndo ? "mondayButtonGrey" : "mondayButtonBlue"}
        hasIcon={false}
        // className="buttonClass"
        onClick={undoClickHandler}
        data-testid="undo-button"
        tooltipCategory={tooltipCategory}
        tooltipKey="undo"
      >
        <UndoOutlined className="iconClass" />
      </MondayButton>

      <MondayButton
        className={!canRedo ? "mondayButtonGrey" : "mondayButtonBlue"}
        // type="primary"
        hasIcon={false}
        disabled={!canRedo}
        // className="buttonClass"
        onClick={redoClickHandler}
        data-testid="redo-button"
        tooltipCategory={tooltipCategory}
        tooltipKey="redo"
      >
        <RedoOutlined className="iconClass" />
      </MondayButton>
    </div>
  );
};
