import { findTutorialSteps } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";

/**
 * Generates an array of tour steps for the request form modal.
 *
 * @param {Array} programFields - The program fields.
 * @param {string} requestType - The request type.
 * @param {Object} originalRequestForm - The original request form.
 * @returns {Array} An array of tour steps.
 */
const requestTourSteps = ({
  programFields = [],
  requestType = "",
  originalRequestForm = {},
}) => {
  function stepsMapHelper(title) {
    const stepsMap = {
      "Request Status": document.querySelector(".formStepper"),
      "Upload Files": document.querySelector(".uploaderSection"),
      Details: document.querySelector(".detailsContainer"),
      "Proposed Type of Work": document.querySelector(
        ".typeOfWorkBorderedTextCard"
      ),
      Comments: document.querySelector(".commentBorderedTextCard"),
      Comments: document.querySelector(".commentBorderedTextCard"),
      Save: document.querySelector("#saveButton"),
      "Delete Request": document.querySelector("#deleteButton"),
      "Show Logs": document.querySelector("#logsButton"),
      "Email Reference": document.querySelector("#emailReference"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    const indx = [6, 7];
    const hasPTY =
      requestType === "Scheduling" ||
      requestType === "Permit Drawing" ||
      requestType === "Estimation" ||
      requestType === "Service Inspection"
        ? true
        : false;
    hasPTY ? newSteps : newSteps.splice(4, 1);
    return originalRequestForm
      ? newSteps
      : newSteps.filter((_, i) => !indx.includes(i));
  }
  const tourSteps = mapRefs(findTutorialSteps("requestModal", programFields));

  return tourSteps || [];
};

export default requestTourSteps;
