import {
  dispatching,
  documentation,
  estimations,
  fleet,
  fleetInspections,
  leads,
  maintenance,
  opportunities,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  defects,
  workOrders,
  safety,
  inspections,
} from "./subCategory";

export const longLineSteper = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  engData,
  archData,
  inspectionsData,
  fleetData,
  dispatchData,
  applicationsData,
  rentalsData,
  chargesData,
  invoicesData,
  incidentsData,
  defectsData,
  workOrdersData
) => {
  return {
    Leads: () => leads(programFields, updatedDynamicStates, IdHandler),
    Claims: () => claims(programFields, updatedDynamicStates, IdHandler),
    "Work Orders": () =>
      workOrders(
        workOrdersData,
        updatedDynamicStates,
        IdHandler,
        programFields
      ),
    Opportunities: () =>
      opportunities(programFields, updatedDynamicStates, IdHandler),
    Projects: () => projects(programFields, updatedDynamicStates, IdHandler),
    Documentation: () =>
      documentation(programFields, updatedDynamicStates, IdHandler),
    Scheduling: () =>
      scheduling(programFields, updatedDynamicStates, IdHandler),
    Dispatching: () =>
      dispatching(dispatchData, updatedDynamicStates, IdHandler),
    Fleet: () => fleet(fleetData, updatedDynamicStates, IdHandler),
    "Fleet Inspections": () =>
      fleetInspections(inspectionsData, updatedDynamicStates, IdHandler),
    Incidents: () => incidents(incidentsData, updatedDynamicStates, IdHandler),
    Defects: () => defects(defectsData, updatedDynamicStates, IdHandler),
    Maintenance: () => maintenance(updatedDynamicStates, IdHandler),
    Violations: () => violations(updatedDynamicStates, IdHandler),
    Estimations: () =>
      estimations(
        updatedDynamicStates,
        IdHandler,
        programFields,
        archData,
        engData
      ),
    Applications: () =>
      applications(
        programFields,
        updatedDynamicStates,
        IdHandler,
        applicationsData
      ),
    Rentals: () =>
      rentals(programFields, updatedDynamicStates, IdHandler, rentalsData),
    Charges: () =>
      charges(programFields, updatedDynamicStates, IdHandler, chargesData),
    Invoices: () =>
      invoices(programFields, updatedDynamicStates, IdHandler, invoicesData),
    Safety: () => safety(programFields, updatedDynamicStates, IdHandler),
    Inspections: () => inspections(updatedDynamicStates, IdHandler),
  }; //LineChart
};
