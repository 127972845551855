import { useWatch } from "antd/es/form/Form";

/**
 * Custom hook that watches form fields based on the safety category.
 * you can add more cases based on the safety category but be sure to add the key and the use case functionality on the respective category field manipulation function
 *
 * @param {FormInstance} form - The form instance.
 * @param {String} safetyCategory - The selected safety category.
 * @returns {Object} - The watched form fields based on the safety category.
 */
const useSafetyFieldsWatch = (form, safetyCategory) => {
  switch (safetyCategory) {
    case "Property Damage": {
      const [selectedForeman, typeOfEquipment] = [
        useWatch("foremanOfCrewThatCausedTheDamage", form),
        useWatch("typeOfEquipmentInvolved", form),
      ];

      return { selectedForeman, typeOfEquipment };
    }

    case "Personal Injury": {
      const [selectedService, typeOfEquipment, immediateActions] = [
        useWatch("serviceBeingDone", form),
        useWatch("typeOfEquipmentInvolved", form),
        useWatch("immediateActionsTakenFollowingTheIncident", form),
      ];

      return { selectedService, typeOfEquipment, immediateActions };
    }

    case "Vehicle Damage": {
      const [otherInvolvement] = [useWatch("wereTheFollowingInvolved", form)];

      return { otherInvolvement };
    }

    case "Other Trade Incident": {
      const [otherPersonnelAffected, immediateActions, otherTypeOfEquipment] = [
        useWatch("wasAnyoneElseAffectedByThisInjury", form),
        useWatch("describeTheImmediateActionsTakenFollowingTheIncident", form),
        useWatch("typeOfEquipmentInvolved", form),
      ];

      return { otherPersonnelAffected, immediateActions, otherTypeOfEquipment };
    }
  }
};

export default useSafetyFieldsWatch;
