import { useState, useContext, useEffect, useRef } from "react";
import { GoogleMap } from "@react-google-maps/api";

import {
  FleetsLiveContext,
  loadLivePreference,
  findBoundExtremities,
} from "../../utils";
import LiveMapContext from "./LiveMapContext";
import { MAP_THEME, YARD_LOCATIONS } from "../../data";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import {
  MapFilters,
  MapSidebar,
  SideOverlay,
  SidebarTrip,
  MapController,
  LocationOverlay,
  LiveMarkerGroup,
  WaypointsMarkers,
} from "./components";

import "./LiveMapView.scss";

function LiveMapView() {
  const { mapLoaded, locations = [] } = useContext(FleetsLiveContext);

  const [mapInstance, setMapInstance] = useState();
  const filterRef = useRef(null);
  const markerRef = useRef(null);
  const sidebarRef = useRef(null);
  const waypointsRef = useRef(null);
  const controllerRef = useRef(null);
  const sideOverlayRef = useRef(null);
  const tripDetailsRef = useRef(null);
  const locationOverlayRef = useRef(null);

  function panToLocation(map) {
    if (locations?.length) {
      const bounds = new window.google.maps.LatLngBounds();
      let selectedVehicle = loadLivePreference("sidebarTripLocation");
      let extremities = [];
      let vehicle;

      if (selectedVehicle) {
        vehicle = locations?.find(({ fleetId }) => fleetId === selectedVehicle);
      }

      if (vehicle) {
        setTimeout(() => {
          map.panTo(vehicle?.position);
          map.setZoom(18);
        }, 1000);
      } else {
        extremities = findBoundExtremities(locations?.map((e) => e?.position));

        for (const location of extremities) {
          bounds.extend(location);
        }
        map.fitBounds(bounds);
      }
    } else {
      map.panTo(YARD_LOCATIONS()?.[0] || NYC_CENTER);
    }
  }

  function onLoad(map) {
    const panorama = map.getStreetView();

    window.google.maps.event.addListener(panorama, "visible_changed", () => {
      let filterComponent = document.getElementById("live-map-filters");
      if (filterComponent) {
        if (panorama.getVisible()) {
          filterComponent.style.visibility = "hidden";
          panorama.setOptions({
            enableCloseButton: true,
            fullscreenControl: true,
          });
        } else {
          filterComponent.style.visibility = "visible";
        }
      }
    });
    map.setStreetView(panorama);

    setMapInstance(map);
  }

  function toggleMapSidebar() {
    if (sidebarRef?.current) {
      sidebarRef?.current?.toggleMapSidebar();
    }
  }

  function sidebarFilterByVin(arr) {
    if (sidebarRef?.current) {
      sidebarRef?.current?.filterByVin(arr);
    }

    if (markerRef.current) {
      markerRef.current.filterByVin(arr);
    }
  }

  function onGeofenceRemove(removeDirection = false) {
    if (removeDirection) {
      if (tripDetailsRef?.current) {
        tripDetailsRef.current.removeDirections();
      }
      if (locationOverlayRef?.current) {
        locationOverlayRef?.current?.clearDirections();
      }
    }
    if (sidebarRef.current) {
      sidebarRef.current.removeSelectedFence();
    }
  }

  function onFilterChange(statusFilter, vinList) {
    if (sidebarRef.current) {
      if (vinList) {
        sidebarRef.current.filterByVin(vinList);
      } else {
        sidebarRef.current.filterByStatus(statusFilter);
      }
    }

    if (markerRef.current) {
      if (vinList) {
        markerRef.current.filterByVin(vinList);
      } else {
        markerRef.current.filterByStatus(statusFilter);
      }
    }
  }

  function getStatusFilter() {
    if (filterRef.current) {
      return filterRef.current.getStatusFilter();
    }
    return [];
  }

  function getVehicleFilter() {
    if (controllerRef.current) {
      return controllerRef.current.getVehicleFilter();
    }
    return null;
  }

  function onInfoClick(info) {
    if (tripDetailsRef?.current) {
      tripDetailsRef.current.showTripDetails(info);
    }
  }

  function drawGeofence(points) {
    if (sidebarRef?.current) {
      sidebarRef?.current?.drawGeofence(points);
    }
  }

  function getSelectedVehicle() {
    if (tripDetailsRef?.current) {
      return tripDetailsRef?.current?.getSelectedVehicle();
    }
    return undefined;
  }

  function renderCustomLocation(location) {
    if (locationOverlayRef?.current) {
      locationOverlayRef?.current?.renderInstance(location);
    }
  }

  function clearCustomLocation() {
    if (locationOverlayRef?.current) {
      locationOverlayRef?.current?.clearInstance();
    }
  }

  function updateWaypoints(waypoints) {
    if (waypointsRef?.current) {
      waypointsRef.current.updateWaypoints(waypoints);
    }
  }

  function drawLine(points) {
    if (sideOverlayRef.current && mapInstance) {
      sideOverlayRef.current.drawLine(points, mapInstance);
    }
  }

  function drawPolygon(points) {
    if (sideOverlayRef.current && mapInstance) {
      sideOverlayRef.current.drawPolygon(points, mapInstance);
    }
  }

  function changeMarkerVisibility(visible) {
    if (markerRef.current) {
      markerRef.current.changeMarkerVisibility(visible);
    }
  }

  function changeLocationInfo(info) {
    if (markerRef.current) {
      markerRef.current.changeLocationInfo(info);
    }
  }

  useEffect(() => {
    if (mapInstance) {
      panToLocation(mapInstance);
    }
  }, [mapInstance, locations]);

  return (
    <LiveMapContext.Provider
      value={{
        drawLine,
        onInfoClick,
        mapInstance,
        drawPolygon,
        drawGeofence,
        onFilterChange,
        getStatusFilter,
        updateWaypoints,
        toggleMapSidebar,
        getVehicleFilter,
        onGeofenceRemove,
        getSelectedVehicle,
        sidebarFilterByVin,
        changeLocationInfo,
        clearCustomLocation,
        renderCustomLocation,
        changeMarkerVisibility,
      }}
    >
      <div
        className="live-map-view"
        id="live-map-view"
        style={{
          width: loadLivePreference("liveSidebar")
            ? "calc(100dvw - 65px)"
            : "calc(100dvw - 200px)",
        }}
      >
        <MapSidebar ref={sidebarRef} />
        <SidebarTrip ref={tripDetailsRef} />
        <div className="map-control">
          <MapFilters ref={filterRef} />
          <div className="inner-map">
            <MapController ref={controllerRef} />
            {mapLoaded && (
              <GoogleMap
                {...{
                  zoom: 12,
                  mapContainerClassName: "live-map",
                  onLoad,
                  id: "live-map",
                  options: {
                    disableDefaultUI: true,
                    styles: MAP_THEME[loadLivePreference("mapStyle")],
                    mapTypeId: loadLivePreference("mapType"),
                    fullscreenControl: true,
                    clickableIcons: false,
                    rotateControl: true,
                    fullscreenControlOptions: {
                      position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                    },
                    streetViewControl: true,
                    streetViewControlOptions: {
                      position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                    },
                  },
                }}
              >
                <LiveMarkerGroup ref={markerRef} />
                <SideOverlay ref={sideOverlayRef} />
                <WaypointsMarkers ref={waypointsRef} />
                <LocationOverlay ref={locationOverlayRef} />
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
    </LiveMapContext.Provider>
  );
}

export default LiveMapView;
