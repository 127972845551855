import { Select, message } from "antd";
import { headerTextStyle } from "../../Configurations/WidgetConfigKeys/styleHelpers/styles";
import { useSelector } from "react-redux";
import { FetchAllIcon } from "src/components/SidebarPages/BasePage/src";
import { MondayButton } from "src/components/commonComponents";
import { RetweetOutlined } from "@ant-design/icons";
import Geofence from "src/components/commonComponents/Map/Geofence/Geofence";
import { modalFooterStyle } from "src/components/commonComponents/ExportedStylesForIcons/exportedStylesForIcons";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "../../Roles/src";
import { useMemo, useState } from "react";
import { API } from "aws-amplify";
import { getAccessRights } from "src/utils";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { categoriesWithGeofence } from "../AllProgramGeofences/utils/categoriesWithGeofence";
import { curr, prev } from "../AllProgramGeofences/utils/logsFunctions";
import { useEditLogs } from "../../../../../../hooks";
import { lazyFetch } from "../../../../../../utils";

const SelectCategoryToDrawGeofence = ({
  dispatch,
  state,
  setOpen,
  isDarkMode = false,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [geoFenceInfo, setGeoFenceInfo] = useState([]);
  const [unsavedLogs, setUnsavedLogs] = useState([]);
  const { saveAddedLogs } = useEditLogs();

  const apiName =
    state?.categoryToDrawGeofence &&
    state?.categoryToDrawGeofence?.toLowerCase();

  const dynamicCategoriesWithGeofence = [
    {
      objectName: "Projects",
      hasAccess: returnAccesRight("Projects/View"),
      hasAccesForGeofence: returnAccesRight("Projects/View")?.children?.find(
        ({ title }) => title === "Geofence"
      )
        ? false
        : true,
    },
    {
      objectName: "Opportunities",
      hasAccess: returnAccesRight("Opportunities/View"),
      hasAccesForGeofence: returnAccesRight(
        "Opportunities/View"
      )?.children?.find(({ title }) => title === "Geofence")
        ? false
        : true,
    },
  ];

  function returnAccesRight(title) {
    const { routeConfig } = getAccessRights({
      userConfiguration,
      title: title,
    });
    return routeConfig;
  }

  const options = () => {
    return dynamicCategoriesWithGeofence.map(
      ({ objectName, hasAccess, hasAccesForGeofence }) => ({
        label: objectName,
        value: objectName,
        disabled: !hasAccess.read || hasAccesForGeofence,
      })
    );
  };

  const fetchData = () => {
    if (state.categoryToDrawGeofence) {
      dispatch({ type: "fetchingData", payload: true });
      categoriesWithGeofence
        .filter(
          ({ categoryName }) => categoryName === state.categoryToDrawGeofence
        )
        .map(
          async ({
            categoryEndpoint,
            primaryKey,
            keyName,
            addressKey,
            lat,
            lng,
          }) => {
            const res = await lazyFetch({
              tableName: categoryEndpoint,
              listOfKeys: [
                primaryKey,
                keyName,
                addressKey,
                lat,
                lng,
                "accountName",
              ],
            });
            const mappedData = res.map((r) => ({
              label: r?.[addressKey],
              value: r?.[addressKey],
              geoFenceInfo: r?.[keyName],
              coords: {
                lat: r?.[lat] || r?.[lat]?.lat,
                lng: r?.[lng] || r?.[lng]?.lng,
              },
              primaryKey: r[primaryKey],
              accountName: r.accountName,
            }));
            dispatch({ type: "fetchingData", payload: false });
            dispatch({
              type: "cateogryIdToDrawGeofence",
              payload: mappedData,
            });
          }
        );
    }
  };

  function resetFetchedData() {
    dispatch({ type: "cateogryIdToDrawGeofence", payload: [] });
    dispatch({ type: "categoryToDrawGeofence", payload: false });
    dispatch({ type: "selectedCatAdd", payload: null });
  }

  const handleSave = () => {
    dispatch({ type: "savingGeofenceToDb", payload: true });
    API.put(apiName, `/${apiName}/${state.selectedCatAdd.primaryKey}`, {
      body: {
        geoFenceInfo: geoFenceInfo,
      },
    })
      .then(() => {
        message.success("GeoFence saved successfully");
        dispatch({ type: "savingGeofenceToDb", payload: false });
        dispatch({
          type: "selectedCatAdd",
          payload: {
            geoFenceInfo: geoFenceInfo,
            coords: state.selectedCatAdd.coords,
            label: state.label,
            value: state.value,
          },
        });
        broadcastNotification(
          "104",
          geoFenceInfo.length <= state.selectedCatAdd.geoFenceInfo.length
            ? "onDeletion"
            : "onCreation",
          [
            {
              common: userConfiguration.nameOfUser,
              commonNext: geoFenceInfo?.[0]?.title || "",
            },
            {
              userName: userConfiguration.nameOfUser,
              currentUser: userConfiguration.cognitoUserId,
            },
          ],
          `${apiName}/${state.selectedCatAdd.primaryKey}`
        );
        if (unsavedLogs.length !== 0) {
          unsavedLogs.map((geo) => {
            return postLogs(
              prev(geo),
              curr(geo, geo.prevGeoFenceInfo),
              "Geofence",
              geo.actionType
              // {
              //   onAction: dynamicOnAction(geo.actionType),
              //   commonNext: geo?.title,
              // }
            );
          });
        }
        setOpen(false);
      })
      .catch(() => {
        message.error("There was a problem Saving Data");
        dispatch({ type: "savingGeofenceToDb", payload: false });
      });
  };

  function postLogs(prev, curr, label, actionType = "Edit") {
    let logObject = {
      recordId: state?.selectedCatAdd?.primaryKey,
      recordName: state?.selectedCatAdd?.value,
      category: state?.categoryToDrawGeofence,
      currentData: curr,
      actionType,
      label: label,
      previousData: prev,
      updatedKeys: [{ details: "See Details" }],
    };
    saveAddedLogs(logObject).then(() => setUnsavedLogs([]));
  }

  const rowData = useMemo(
    () => ({
      projectAddress: state?.selectedCatAdd?.label || "",
      opportunityAddress: state?.selectedCatAdd?.label || "",
      accountName: state?.selectedCatAdd?.accountName || "",
    }),
    [state?.selectedCatAdd]
  );

  return (
    <div className="selectCategorySecion">
      <div className="selectCategorySingleSetion">
        <div className="selectLeftSide">
          <span className="spanTitle">
            Please select a category to draw Geofence
          </span>
          <Select
            value={state.categoryToDrawGeofence}
            // disabled={state.categoryToDrawGeofence}
            onChange={(_, value) => {
              dispatch({
                type: "categoryToDrawGeofence",
                payload: value.value,
              });
              dispatch({
                type: "selectedCatAdd",
                payload: false,
              });
              dispatch({
                type: "cateogryIdToDrawGeofence",
                payload: [],
              });
            }}
            options={options()}
            placeholder="Please select a category..."
            popupClassName={`${isDarkMode && "darkDropDown"}`}
          />
        </div>
        <div>
          {state.cateogryIdToDrawGeofence.length === 0 ? (
            <MondayButton
              loading={state.fetchingData}
              disabled={!state.categoryToDrawGeofence}
              className={
                !state.categoryToDrawGeofence
                  ? "mondayButtonGray"
                  : "mondayButtonBlue"
              }
              onClick={fetchData}
              Icon={<FetchAllIcon />}
              tooltipCategory="Geofence"
              tooltipKey="fetch"
            >
              {state.fetchingData ? "Fetching..." : "Fetch"}
            </MondayButton>
          ) : (
            <MondayButton
              className="mondayButtonYellow"
              onClick={resetFetchedData}
              Icon={<RetweetOutlined />}
              tooltipCategory="Geofence"
              tooltipKey="resetFields"
            >
              Reset Fields
            </MondayButton>
          )}
        </div>
      </div>

      {state.cateogryIdToDrawGeofence.length !== 0 && (
        <div className="selectLeftSide">
          <span
            style={{ ...headerTextStyle, marginBottom: "5px" }}
            className="spanTitle"
          >
            Please select the {state.categoryToDrawGeofence} address in which
            you want to create goefence
          </span>
          <Select
            showSearch
            // disabled={state.selectedCatAdd}
            options={state.cateogryIdToDrawGeofence}
            placeholder="Please select a Address..."
            style={{ width: "100%" }}
            onChange={(_, value) => {
              dispatch({ type: "selectedCatAdd", payload: value });
              setGeoFenceInfo(value.geoFenceInfo);
            }}
            value={state.selectedCatAdd}
            popupClassName={`${isDarkMode && "darkDropDown"}`}
          />
        </div>
      )}

      {state.selectedCatAdd && (
        <div className="geofence-section">
          <span className="geofence-section-title">
            {state.selectedCatAdd.label}
          </span>
          <Geofence
            {...{
              places: [
                {
                  coordinates: state?.selectedCatAdd.coords,
                },
              ],
              geoFenceInfo: geoFenceInfo,
              setGeoFenceInfo: setGeoFenceInfo,
              radius: state?.selectedCatAdd?.radius,
              setUnsavedLogs,
              rowData,
            }}
          />
        </div>
      )}

      <div style={modalFooterStyle}>
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={() => setOpen("")}
          tooltipCategory="Geofence"
          tooltipKey="close"
        >
          Close
        </MondayButton>
        <MondayButton
          Icon={<TickIcon />}
          onClick={handleSave}
          disabled={
            !state.selectedCatAdd ||
            geoFenceInfo.length === state.selectedCatAdd.geoFenceInfo.length
          }
          loading={state.savingGeofenceToDb}
          tooltipCategory="Geofence"
          tooltipKey="save"
        >
          {state.savingGeofenceToDb ? "Saving..." : "Save"}
        </MondayButton>
      </div>
    </div>
  );
};

export default SelectCategoryToDrawGeofence;
