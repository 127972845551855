import { useState } from "react";
import { useSelector } from "react-redux";
import { message, Select } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

import { MondayButton } from "src/components/commonComponents";
import { modalFooterStyle } from "src/components/commonComponents/ExportedStylesForIcons/exportedStylesForIcons";
import { FetchAllIcon } from "src/components/SidebarPages/BasePage/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import {
  communicationsRecords,
  getEndpoint,
  getOptions,
  getRedirectPath,
} from "./utils/helpers";
import { TickIcon } from "../../../../../InternalChat/assets/icons";
import { lazyFetch } from "../../../../../../utils";
import { headerTextStyle } from "../../../../../pages/Settings/settingsComponents/Configurations/WidgetConfigKeys/styleHelpers/styles";
import { useNavigate } from "react-router-dom";
import { recordName } from "../../../../../commonComponents/NextSteps/helpers/constants";

const SelectCommunicationRecord = ({ setOpen }) => {
  const navigate = useNavigate();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [selectedRecord, setSelectedRecord] = useState("");
  const [selectedRecordDetails, setSelectedRecordDetails] = useState([]);
  const [paramsDetails, setParamsDetails] = useState({
    recordName: "",
    recordId: "",
  });
  const [loading, setLoading] = useState(false);
  const options = getOptions(userConfiguration);

  const fetchData = async () => {
    if (selectedRecord !== "") {
      setLoading(true);
      try {
        const foundRecord = communicationsRecords.find(
          (record) => record.endpoint === getEndpoint(selectedRecord)
        );

        const res = await lazyFetch({
          tableName: foundRecord.endpoint,
          listOfKeys: foundRecord.listOfKeys,
        });

        if (!res) {
          throw new Error("Couldn't find record with name", recordName);
        }

        const [valueKey, labelKey] = foundRecord.listOfKeys;

        const formattedData = res.map((row) => ({
          label: row[labelKey],
          value: row[valueKey],
        }));

        setSelectedRecordDetails(formattedData);

        message.success({ content: "Record fetched successfully" });
      } catch (error) {
        console.error("[shortcut_communication_fetching_failed]", error);
      } finally {
        setLoading(false);
      }
    }
  };

  function resetFetchedData() {
    setSelectedRecord("");
    setSelectedRecordDetails([]);
    setParamsDetails({ recordId: "", recordName: "" });
  }

  const onNavigate = () => {
    if (selectedRecord !== "" && paramsDetails.recordId !== "") {
      message.info({ content: "Redirecting to communication" });
      navigate(
        `/${getRedirectPath(selectedRecord)}/${paramsDetails.recordId}`,
        {
          state: {
            data: {
              tab: "Communication",
              openEmailBox: true,
            },
          },
        }
      );
      setOpen(false);
    }
  };

  const onChange = (value) => {
    setParamsDetails({ recordName: selectedRecord, recordId: value });
  };

  return (
    <div className="selectCategorySecion">
      <div className="selectCategorySingleSetion">
        <div className="selectLeftSide">
          <span className="spanTitle">Please select a category</span>
          <Select
            value={selectedRecord}
            onSelect={(value) => {
              setSelectedRecord(value);
              if (selectedRecordDetails.length !== 0) {
                setSelectedRecordDetails([]);
              }
            }}
            options={options}
            placeholder="Please select a record..."
            popupClassName={`${isDarkMode && "darkDropDown"}`}
          />
        </div>
        <div>
          {selectedRecordDetails.length === 0 ? (
            <MondayButton
              loading={loading}
              disabled={selectedRecord === ""}
              className={loading ? "mondayButtonGray" : "mondayButtonBlue"}
              onClick={fetchData}
              Icon={<FetchAllIcon />}
              tooltipCategory="Geofence"
              tooltipKey="fetch"
            >
              {loading ? "Fetching..." : "Fetch"}
            </MondayButton>
          ) : (
            <MondayButton
              className="mondayButtonYellow"
              onClick={resetFetchedData}
              Icon={<RetweetOutlined />}
              tooltipCategory="Geofence"
              tooltipKey="resetFields"
            >
              Reset Fields
            </MondayButton>
          )}
        </div>
      </div>

      {selectedRecordDetails.length !== 0 && (
        <div className="selectLeftSide">
          <span
            style={{ ...headerTextStyle, marginBottom: "5px" }}
            className="spanTitle"
          >
            {`Please select the name you want to compose an email`}
          </span>
          <Select
            showSearch
            options={selectedRecordDetails}
            placeholder="Please select a name..."
            style={{ width: "100%" }}
            onChange={onChange}
            value={selectedRecordDetails.label}
            popupClassName={`${isDarkMode && "darkDropDown"}`}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
      )}

      <div style={modalFooterStyle}>
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={() => setOpen("")}
          tooltipCategory="Geofence"
          tooltipKey="close"
          disabled={loading}
        >
          Close
        </MondayButton>
        <MondayButton
          Icon={<TickIcon />}
          onClick={onNavigate}
          disabled={paramsDetails.recordId === "" || selectedRecord === ""}
          loading={loading}
          tooltipCategory="Geofence"
          tooltipKey="save"
        >
          Go to selected record
        </MondayButton>
      </div>
    </div>
  );
};

export default SelectCommunicationRecord;
