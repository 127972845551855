import { SimplePLI } from "./GeneralModels";

export class CustomServicePLI extends SimplePLI {
  
    constructor(id, taxRate, options = {}) {
      super(id, taxRate);
  
      // Spread all properties of options into the instance
      Object.assign(this, options);
    }
}