import { Select, Tooltip, Dropdown, message } from "antd";
import {
  SearchOutlined,
  ScissorOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";

import {
  EditIcon,
  ClientsWhite,
  TasksIconNew,
  EstimationsWhite,
  SubcontractorsWhite,
} from "../../../../../../../../../icons";
import {
  PdfWhiteIcon,
  PieChartWhite,
  ExcelIconWhite,
} from "../../../../../../../../../assets";
import PayrollUndoRedo from "./PayrollUndoRedo";
import {
  TrashIcon,
  XIcon,
} from "../../../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../../../commonComponents";
import { FilterIcon } from "../../../../../../../../SidebarPages/BasePage/src";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { DropDownArrow } from "../../../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { AddIcon } from "../../../../../../../../Header/components/GoogleEvents/assets";

function ShiftHeader({
  onSearch,
  addShift,
  degStatus,
  isDarkMode,
  searchInput,
  detailsOpen,
  gridActions,
  serqInputRef,
  setFilterOpen,
  onGeneratePDF,
  onShiftRemove,
  shiftsGridApi,
  setDetailsOpen,
  setCategoryCost,
  openGridActions,
  shiftsColumnDefs,
  closeGridActions,
  setFieldSelected,
  exportGridToExcel,
  onFilterTextChange,
  openSelectionModal,
  openSplitShiftModal,
  setTeamsCostVisible,
  openRecommendedModal,
  setMassEntryShiftType,
  setCostDispersionModal,
  setFiltersFromSelection,
  setShiftVisibleFromShift,
  updateShiftExternalFilter,
}) {
  return (
    <div className="deg-actions">
      <div className="headerFiler">
        <MondayButton
          Icon={<FilterIcon />}
          className="mondayButtonBlue"
          data-testid="shifts-filter-btn"
          tooltipKey={"Shifts Table Filter"}
          onClick={() => setFilterOpen((prev) => !prev)}
        >
          Open Filters
        </MondayButton>
        <InputComponent
          {...{
            type: "autocopmlete",
            inputRef: serqInputRef,
            formItemName: "inputSearch",
            value: searchInput,
            prefix: <SearchOutlined />,
            onChange: (e) => onFilterTextChange(e.target.value),
            placeholder: "Filter by name...",
            onSearch,
            addonAfter: (
              <Select
                className="select-comp"
                placeholder="Select field to filter"
                allowClear={true}
                onClear={() => setFieldSelected(null)}
                popupMatchSelectWidth={false}
                popupClassName={isDarkMode && `darkDropDown`}
                suffixIcon={<DropDownArrow />}
                onSelect={(e) => {
                  serqInputRef?.current?.focus?.();
                  setFieldSelected(e);
                }}
                options={shiftsColumnDefs.map(({ headerName, field }) => ({
                  value: field,
                  label: headerName,
                }))}
              />
            ),
          }}
        />
        <div className="control-buttons">
          {detailsOpen ? (
            <div className="grid-actions">
              <Tooltip title="Close Analytics">
                <MondayButton
                  className="cancelActions"
                  data-testid="close-analytics-btns"
                  Icon={<XIcon fill="#fff" width={15} height={15} />}
                  onClick={() => {
                    setDetailsOpen(false);
                  }}
                >
                  {""}
                </MondayButton>
              </Tooltip>
              <div className="action-buttons">
                <Tooltip title="Cost Distribution" placement="right">
                  <MondayButton
                    Icon={
                      <PieChartWhite height={14} width={14} fill={"white"} />
                    }
                    data-testid="cost-distribution-btn"
                    className="mondayButtonBlue"
                    onClick={() => {
                      setDetailsOpen(false);
                      setCostDispersionModal(true);
                    }}
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
                <Tooltip title="Category Cost" placement="right">
                  <MondayButton
                    Icon={
                      <SubcontractorsWhite
                        height={14}
                        width={14}
                        fill="white"
                      />
                    }
                    data-testid="category-cost-btn"
                    className="mondayButtonBlue"
                    onClick={() => {
                      setDetailsOpen(false);
                      setCategoryCost(true);
                    }}
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
                <Tooltip title="Teams Cost" placement="right">
                  <MondayButton
                    Icon={<ClientsWhite height={14} width={14} fill="white" />}
                    data-testid="team-cost-btn"
                    className="mondayButtonBlue"
                    onClick={() => {
                      setDetailsOpen(false);
                      setTeamsCostVisible(true);
                    }}
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="actions-trigger">
              <Tooltip title="Analytics">
                <MondayButton
                  className="mondayButtonBlue"
                  data-testid="analytics-btns-toggle"
                  Icon={
                    <EstimationsWhite width={14} height={14} fill="white" />
                  }
                  onClick={() => {
                    setDetailsOpen(true);
                  }}
                >
                  {""}
                </MondayButton>
              </Tooltip>
            </div>
          )}
          <PayrollUndoRedo />
        </div>
      </div>
      <div style={{ height: 32, display: "flex", gap: 10 }}>
        <Tooltip title="Export to PDF">
          <MondayButton
            className="mondayButtonBlue"
            onClick={onGeneratePDF}
            Icon={<PdfWhiteIcon width={15} height={15} />}
          >
            {""}
          </MondayButton>
        </Tooltip>
        <Tooltip title="Export to Excel">
          <MondayButton
            className="mondayButtonBlue"
            onClick={exportGridToExcel}
            Icon={<ExcelIconWhite width={15} height={15} />}
          >
            {""}
          </MondayButton>
        </Tooltip>
        <div className="control-buttons">
          {gridActions ? (
            <div className="grid-actions">
              <Tooltip title="Close Actions">
                <MondayButton
                  className="cancelActions"
                  data-testid="close-shift-action-btns"
                  Icon={<XIcon fill="#fff" width={15} height={15} />}
                  onClick={closeGridActions}
                >
                  {""}
                </MondayButton>
              </Tooltip>
              <div className="action-buttons">
                <Tooltip title="Split Shift" placement="right">
                  <MondayButton
                    hasIcon={false}
                    data-testid="split-shift-btn"
                    onClick={() => {
                      openSplitShiftModal();
                      closeGridActions();
                    }}
                    className="mondayButtonBlue"
                  >
                    <ScissorOutlined />
                  </MondayButton>
                </Tooltip>
                <Tooltip title="Mass Entry" placement="right">
                  <MondayButton
                    hasIcon={false}
                    className="mondayButtonBlue"
                    data-testid="mass-entry-btn"
                    onClick={() => {
                      const selectedNodes = shiftsGridApi.getSelectedNodes();
                      const shiftTypes = selectedNodes.map(
                        (node) => node.data?.shiftType
                      );

                      if (selectedNodes.length === 0) {
                        message.error("Please select Shifts to edit!");
                      } else {
                        if (
                          shiftTypes.includes("Regular Shift") &&
                          shiftTypes.includes("HR Shift")
                        ) {
                          message.warning("There are 2 types of shifts");
                          setMassEntryShiftType(true);
                        } else {
                          setShiftVisibleFromShift("normal");
                        }
                      }
                      closeGridActions();
                    }}
                  >
                    <AlignLeftOutlined />
                  </MondayButton>
                </Tooltip>
                <Tooltip title="Remove Shifts" placement="right">
                  <MondayButton
                    Icon={<TrashIcon />}
                    onClick={() => onShiftRemove()}
                    className="mondayButtonRed"
                    data-testid="remove-shift-btn"
                  >
                    {""}
                  </MondayButton>
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="actions-trigger">
              <MondayButton
                className="mondayButtonBlue"
                data-testid="shift-actions-toggle"
                Icon={<EditIcon width={14} height={14} fill="#fff" />}
                onClick={openGridActions}
                disabled={degStatus === "Completed"}
              >
                {""}
              </MondayButton>
            </div>
          )}
        </div>
        <MondayButton
          className="mondayButtonRed"
          data-testid="clear-selection-btn"
          Icon={<FilterIcon />}
          onClick={() => {
            let filtersForShiftGrid = {
              role: [],
              payrollType: [],
              selectedEmployees: [],
              selectedJobsites: [],
              timeFrame: undefined,
            };
            shiftsGridApi?.deselectAll();
            setFiltersFromSelection({});
            updateShiftExternalFilter(filtersForShiftGrid);
          }}
        >
          Clear Selection
        </MondayButton>
        <MondayButton
          className="mondayButtonBlue"
          data-testid="shift-selection-btn"
          Icon={<TasksIconNew />}
          onClick={() => {
            openSelectionModal();
          }}
        >
          Shift Selection
        </MondayButton>
        <MondayButton
          className="mondayButtonBlue"
          Icon={<ScissorOutlined />}
          onClick={openRecommendedModal}
          data-testid="recommended-splits-btn"
          disabled={degStatus === "Completed"}
        >
          Recommended Splits
        </MondayButton>
        <Dropdown
          overlayClassName={`layout-card-dropdown${
            isDarkMode ? " layout-dropdown-dark" : ""
          }`}
          placement="bottom"
          overlayStyle={{ width: 180 }}
          menu={{
            items: [
              {
                label: "Add Employee Shift",
                key: "addEmployeeShift",
              },
              {
                label: "Add Team Shift",
                key: "addTeamShift",
              },
              {
                label: "Add Multiple HR shifts",
                key: "addMultipleHR",
              },
              {
                label: "Add Cash/1099 shifts",
                key: "addCashShift",
              },
            ],
            onClick: addShift,
          }}
        >
          <MondayButton
            Icon={<AddIcon />}
            disabled={degStatus === "Completed"}
            data-testid="add-shift-dropdown"
          >
            Add Shift
          </MondayButton>
        </Dropdown>
      </div>
    </div>
  );
}

export default ShiftHeader;
