import { useSelector } from "react-redux";
import { ExportPreview } from "../../../../../../../commonComponents";
import getInitialTaskPdfData from "./helpers/getInitialTaskPdfData";
import convertTaskData from "./helpers/convertTaskData";
import AddRemoveFields from "../../../../../../Safety/SafetyModal/components/SafetyExportPreview/components/AddRemoveFields";
import { safetyExportApplyFilters } from "../../../../../../Safety/SafetyModal/components/SafetyExportPreview/helpers";
import SortReportFields from "../../../../../../Safety/SafetyModal/components/SafetyExportPreview/components/SortReportFields";

function TaskExportPreview({ openPDFPreview, setOpenPDFPreview, updatedTask }) {
  const { accessToken } = useSelector((state) => state.accessToken);
  return openPDFPreview ? (
    <ExportPreview
      {...{
        onCancel: () => setOpenPDFPreview(false),
        open: openPDFPreview,
        modalTitle: "Task PDF Preview",
        documentTitle: "Task " + updatedTask?.taskTitle,
        keyName: "task",
        applyFilters: safetyExportApplyFilters,
        asyncInitialData: true,
        getInitialData: async () =>
          await getInitialTaskPdfData(updatedTask, accessToken),
        convertData: convertTaskData,
        tabs: [
          {
            key: "add/remove",
            label: "Add/Remove",
            component: AddRemoveFields,
          },
          {
            key: "sort",
            label: "Sort",
            component: SortReportFields,
          },
        ],
        customStyles: {
          "dependency-section-header": {
            "font-size": "18px",
            display: "block",
            "font-weight": "bold",
            margin: "3rem 0 1rem 0",
          },
          "dependency-body-header": {
            "font-weight": "bold",
            display: "block",
            "margin-bottom": "10px",
          },
        },
        defaultHeaderSection: [
          {
            text: {
              paragraphs: [
                {
                  text: "Title: " + updatedTask?.taskTitle,
                  class: "safety-title",
                  bold: true,
                  header: true,
                },
              ],
            },
          },
        ],
      }}
    />
  ) : null;
}

export default TaskExportPreview;
