import { DIMENSIONS } from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { getDimensionHeader } from "../../../../SidebarPages/Estimations/DataEntryGrid/tools/polyfillers/capitalizeFirstLetter";
import { formatDateANDShortTime, formatFullDate } from "./creators";

/**
 * This returns a big array of all crews activity in all days
 * */
export const getCrewsActivityArray = (scheduleDays, schedule, user) => {
  let crewsActivity = [];

  for (const scheduleDay of scheduleDays) {
    if (Array.isArray(scheduleDay?.crews)) {
      let crewsActivityForThisDay = [];

      for (const crewId of scheduleDay?.crews) {
        crewsActivityForThisDay.push({
          scheduleId: schedule?.scheduleId,
          crewId: crewId,
          startDate: scheduleDay?.startDate,
          endDate: scheduleDay?.endDate,
          notes: "",
          // createdBy: {id: "32423", name: "sdgf",},
          lastModifiedBy: "",
        });
      }

      crewsActivity = crewsActivity.concat(crewsActivityForThisDay);
    }
  }

  return crewsActivity;
};

/**
 * This returns a big array of all dispatch in all days
 * */
export const getFleetDispatchArray = (scheduleDays, schedule, user) => {
  let fleetActivity = [];

  for (const scheduleDay of scheduleDays) {
    if (schedule) {
      for (const dispatch of scheduleDay.fleet) {
        dispatch.scheduleId = schedule.scheduleId;
      }
    }

    fleetActivity = fleetActivity.concat(scheduleDay.fleet);
  }

  return fleetActivity;
};

/**
 * Gets only dimensions of an item. (width, height, length)
 * */
export const getItemDimensionsAsObject = (item) => {
  let itemDimensions = {};

  for (const itemKey of Object.keys(item)) {
    if (DIMENSIONS.includes(itemKey.toLowerCase())) {
      itemDimensions[itemKey.toLowerCase()] = item[itemKey];
    }
  }

  return itemDimensions;
};

/**
 * Parse Dimensions from object into a readable beautiful string
 * */
export const getItemDimensionsAsString = (item, shortHeaders) => {
  if (item === undefined) return "";

  const itemDimensions = getItemDimensionsAsObject(item);

  let ds = "";

  for (const d of Object.keys(itemDimensions)) {
    let dimensionName = d;
    if (shortHeaders) dimensionName = getDimensionHeader(dimensionName);
    ds += `${dimensionName}: ${itemDimensions[d]} `;
  }

  return ds;
};

export const routeAsString = ({
  dropOffLocation,
  departAt,
  arriveBy,
  dropOffCoordinates,
  timeScheduled,
  pickUpCoordinates,
  dispatchNotes,
  timeExit,
  cargo,
  pickUpLocation,
}) =>
  `Depart At: ${formatDateANDShortTime(
    departAt
  )}, arrive by: ${formatDateANDShortTime(
    arriveBy
  )}, time exit: ${formatDateANDShortTime(timeExit)}`;
