import uniq from "lodash/uniq";

export const incidents = (updatedDynamicStates, IdHandler, programFields) => {
  const incidentStatuses = programFields?.find(
    (a) => a?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Incidents;

  return {
    driverName: () => ({
      title: "Incident Driver Name",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Incidents",
        field: "driverName",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetName,
            value: a?.driverName,
            color: "#ECDE7D",
            //   date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    fleetName: () => ({
      title: "Incident Fleet Name",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Incidents",
        field: "fleetName",
        secondaryField: "driverName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.driverName,
            value: a?.fleetName,
            color: "#ECDE7D",
            //   date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    incidentStatus: () => ({
      title: "Incident Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentStatus",
        secondaryField: "incidentName",
        filterType: { type: "dashboardId" },
        id: IdHandler,
        noTab: true,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.incidentName,
            value: a?.incidentStatus,
            color: incidentStatuses?.find(
              (e) => e.statusName === a?.incidentStatus
            )?.statusColor,
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    incidentCategory: () => ({
      title: "Incident Category",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentCategory",
        secondaryField: "incidentName",
        filterType: { type: "dashboardId" },
        id: IdHandler,
        noTab: true,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.incidentName,
            value: a?.incidentCategory,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    incidentName: () => ({
      title: "Incident Category",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentName",
        secondaryField: "incidentStatus",
        filterType: { type: "dashboardId" },
        id: IdHandler,
        noTab: true,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.incidentName,
            value: a?.incidentStatus,
            color: incidentStatuses?.find(
              (e) => e.statusName === a?.incidentStatus
            )?.statusColor,
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    projectName: () => ({
      title: "Project Name of Incidents",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Incidents",
        field: "projectName",
        secondaryField: "incidentName",
        filterType: { type: "dashboardId" },
        id: IdHandler,
        noTab: true,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.incidentName,
            value: a?.incidentObject?.projectName,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.incidentId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
