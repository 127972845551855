import {
  useDispatchContext,
  useStateContext,
} from "../../../../../Context/Context";
import "./CompleatedCheckBox.scss";
import {
  applicationHoistHandler,
  compleatedHoistHandler,
  applicationHandler,
  compleatedHandler,
} from "./compleatedCheckBoxFunctions";

import { useSelector } from "react-redux";
import { Checkbox, Tooltip } from "antd";

const CompleatedCheckBox = ({ gridParams, service, type, elevation }) => {
  const { isLoading } = useSelector((state) => state.applications);
  const { isWritable, fakeApplication } = useStateContext();

  const dispatchContext = useDispatchContext();

  const eventId = gridParams?.data?.id;
  // const data = fakeApplication?.services?.find(
  //   (serviceData) => serviceData?.serviceId === service?.serviceId
  // )

  const toggleAmountValue = (e) => {
    const checkboxToggle = e?.target?.checked;

    //this is the new apllication with this period 100%,total compleated 100% and balance to finish 0
    let newFakeApplication =
      type === "hoist"
        ? applicationHoistHandler({
            fakeApplication,
            checkboxToggle,
            elevationId: elevation?.elevationId,
            serviceId: service?.serviceId,
            estimationId: service?.estimationId,
            eventId,
          })
        : applicationHandler({
            fakeApplication,
            checkboxToggle,
            service,
            eventId,
          });

    //this sets fake application with the new values
    dispatchContext({
      type: "SET_FAKE_APPLICATION",
      payload: newFakeApplication,
    });
    dispatchContext({
      type: "SET_UNCHANGING_FAKE_APPLICATION",
      payload: newFakeApplication,
    });
  };

  const { someChecked, allChecked, charged, hasPrevious } =
    type === "hoist"
      ? compleatedHoistHandler({
          fakeApplication,
          elevationId: elevation?.elevationId,
          serviceId: service?.serviceId,
          estimationId: service?.estimationId,
          eventId,
        })
      : compleatedHandler({
          fakeApplication,
          service,
          eventId,
        });
  return (
    <div className="bodyCheckboxContainer">
      <Tooltip
        title={
          charged
            ? "This event is already charged"
            : !isWritable
            ? "Please enable write mode"
            : !!isLoading
            ? "Please wait for save to finish"
            : ""
        }
      >
        <Checkbox
          className={decideCompleated({ allChecked, hasPrevious, someChecked })}
          checked={allChecked}
          indeterminate={someChecked}
          disabled={!!isLoading || !isWritable || charged}
          onChange={toggleAmountValue}
        />
      </Tooltip>
    </div>
  );
};

export default CompleatedCheckBox;

const decideCompleated = ({ allChecked, hasPrevious, someChecked }) => {
  if (allChecked) return "allChecked";
  else return "generalCheck";
};
