import { message } from "antd";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import {
  parseInTz,
  setDateMonth,
  setHourMinute,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { DEG_DATE_FORMAT } from "../utils/cellFunctions";
import { MondayButton } from "src/components/commonComponents";

export const shiftFields = ({
  jobsites,
  index,
  onJobsiteSelect,
  sowList,
  form,
  // disabledHours,
  // disabledMinutes,
  launch,
  updateFormValue,
  shifts,
  startShiftDate,
  endShiftDate,
  getTimeConflict,
  showAllServices,
}) => {
  const isShiftInSingleDay =
    shifts?.length === 1 && startShiftDate.valueOf() === endShiftDate.valueOf();

  const formList = form.getFieldValue("shifts");

  return [
    {
      label: "Jobsite",
      type: "select",
      placeholder: "Select a Jobsite...",
      formItemName: [index, `selectedJobsite`],
      showSearch: true,
      optionFilterProp: "label",
      onSelect: (e) => onJobsiteSelect(e, index),
      customOptions: jobsites.map(({ jobsiteId, jobName }, key) => ({
        key,
        value: jobsiteId,
        label: jobName,
      })),
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "SOW",
      placeholder: "Select services...",
      type: "select",
      mode: "multiple",
      getPopUpContainer: document.body,
      required: false, // for the moment
      formItemName: [index, `services${index}`],
      // disabled: !sowList?.[`services${index}`]?.length,
      customOptions: (sowList?.[`services${index}`] || [])?.map(
        (opt, index) => ({
          key: index,
          value: opt,
          label: opt,
        })
      ),
      dropdownRender: (menu) => {
        return (
          <main>
            {menu}
            <section
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MondayButton
                className="mondayButtonBlue"
                hasIcon={false}
                onClick={() => showAllServices({ index })}
              >
                Show all Services
              </MondayButton>
            </section>
          </main>
        );
      },
    },
    // {
    //   label: "Shift Date",
    //   placeholder: "Select a Date...",
    //   type: "datepicker",
    //   getPopUpContainer: document.body,
    //   required: true,
    //   formItemName: "selectedDate",
    //   allowClear: false,
    // },
    {
      form,
      label: "Shift Start Time",
      placeholder: "Select a clock in time...",
      // type: "timepicker",
      type: "customTimeInput",
      formListName: "shifts",
      selectedDate: startShiftDate,
      secondaryDarkMode: true,
      getPopUpContainer: document.body,
      required: true,
      formItemName: [index, `selectedTime`],
      allowClear: false,
      onChange(e) {
        const previousShift = formList?.[index - 1];
        if (
          previousShift?.selectedEndTime?.valueOf() > parseInTz(e).valueOf() &&
          shifts?.length === 1
        ) {
          updateFormValue(
            "selectedTime",
            index,
            // setDateMonth(parseInTz(e), endShiftDate)
            setHourMinute(endShiftDate, parseInTz(e))
          );
          getTimeConflict({
            index,
            formData: form.getFieldValue("shifts"),
          });
        } else {
          updateFormValue(
            "selectedTime",
            index,
            // setDateMonth(parseInTz(e), endShiftDate)
            setHourMinute(endShiftDate, parseInTz(e))
          );
          getTimeConflict({
            index,
            formData: form.getFieldValue("shifts"),
          });
        }
      },
      // disabledHours() {
      //   if (shifts.length > 1) {
      //     return [];
      //   } else {
      //     return isShiftInSingleDay ? disabledHours("SHIFT_START", index) : [];
      //   }
      // },
      // disabledMinutes(hour) {
      //   if (shifts.length > 1) {
      //     return [];
      //   } else {
      //     return isShiftInSingleDay
      //       ? disabledMinutes(hour, "SHIFT_START", index)
      //       : [];
      //   }
      // },
    },
    {
      form,
      label: "Shift End Time",
      placeholder: "Select a clock in time...",
      // type: "timepicker",
      type: "customTimeInput",
      formListName: "shifts",
      secondaryDarkMode: true,
      selectedDate: startShiftDate,
      getPopUpContainer: document.body,
      required: true,
      formItemName: [index, `selectedEndTime`],
      allowClear: false,
      onChange(e) {
        let time =
          shifts.length > 1
            ? setHourMinute(startShiftDate, parseInTz(e))
            : setHourMinute(startShiftDate, parseInTz(e));
        // ? setDateMonth(parseInTz(e), startShiftDate)
        // : setDateMonth(parseInTz(e), startShiftDate);

        if (formList[index]?.selectedTime > time && shifts?.length === 1) {
          // time = setDateMonth(time, endShiftDate);
          time = setHourMinute(endShiftDate, time);
        }

        updateFormValue("selectedEndTime", index, time);
        getTimeConflict({
          index,
          formData: form.getFieldValue("shifts"),
        });

        if (!!form?.getFieldValue("shifts")?.[index + 1]) {
          updateFormValue("selectedTime", index + 1, time.add(1, "minute"));
          getTimeConflict({
            index: index + 1,
            formData: form.getFieldValue("shifts"),
          });
        }
      },
      // disabledHours() {
      //   if (shifts?.length > 1) {
      //     return [];
      //   } else {
      //     return isShiftInSingleDay ? disabledHours("SHIFT_END", index) : [];
      //   }
      // },
      // disabledMinutes(hour) {
      //   if (shifts.length > 1) {
      //     return isShiftInSingleDay
      //       ? disabledMinutes(hour, "SHIFT_END", index)
      //       : [];
      //   }
      // },
    },
    !!launch?.includes(index) && {
      form,
      label: "Lunch Start Time",
      placeholder: "Select a launch in time...",
      // type: "timepicker",
      type: "customTimeInput",
      formListName: "shifts",
      secondaryDarkMode: true,
      selectedDate: startShiftDate,
      getPopUpContainer: document.body,
      required: true,
      formItemName: [index, "launchStartTime"],
      allowClear: false,
      onChange(e) {
        const launchDate = dayjsNY(
          formList?.[index]?.selectedTime,
          DEG_DATE_FORMAT
        );

        const isLaunchNextDay =
          formList?.[index]?.selectedTime.valueOf() >
          // setDateMonth(parseInTz(e), launchDate).valueOf();
          setHourMinute(launchDate, parseInTz(e)).valueOf();

        updateFormValue(
          `launchStartTime`,
          index,
          // setDateMonth(
          //   parseInTz(e),
          //   isLaunchNextDay && shifts?.length > 1 ? endShiftDate : launchDate
          // )
          setHourMinute(
            isLaunchNextDay && shifts?.length > 1 ? endShiftDate : launchDate,
            parseInTz(e)
          )
        );

        getTimeConflict({
          index,
          formData: form.getFieldValue("shifts"),
        });
      },
      // disabledHours() {
      //   if (shifts?.length)
      //     return isShiftInSingleDay ? disabledHours("LUNCH_START", index) : [];
      // },
      // disabledMinutes(hour) {
      //   return isShiftInSingleDay
      //     ? disabledMinutes(hour, "LUNCH_START", index)
      //     : [];
      // },
    },
    !!launch?.includes(index) && {
      form,
      label: "Lunch End Time",
      placeholder: "Select a launch in time...",
      // type: "timepicker",
      type: "customTimeInput",
      formListName: "shifts",
      getPopUpContainer: document.body,
      selectedDate: startShiftDate,
      required: true,
      formItemName: [index, "launchEndTime"],
      allowClear: false,
      secondaryDarkMode: true,
      onChange(e) {
        const launchDate = dayjsNY(
          formList?.[index]?.selectedTime,
          DEG_DATE_FORMAT
        );

        const isLaunchNextDay =
          formList?.[index]?.selectedTime.valueOf() >
          // setDateMonth(parseInTz(e), launchDate).valueOf();
          setHourMinute(launchDate, parseInTz(e)).valueOf();

        updateFormValue(
          `launchEndTime`,
          index,
          // setDateMonth(
          //   parseInTz(e),
          //   isLaunchNextDay ? endShiftDate : launchDate
          // )
          setHourMinute(
            isLaunchNextDay ? endShiftDate : launchDate,
            parseInTz(e)
          )
        );
        getTimeConflict({
          index,
          formData: form.getFieldValue("shifts"),
        });
      },
      // disabledHours() {
      //   return isShiftInSingleDay ? disabledHours("LUNCH_END", index) : [];
      // },
      // disabledMinutes(hour) {
      //   return isShiftInSingleDay
      //     ? disabledMinutes(hour, "LUNCH_END", index)
      //     : [];
      // },
    },
  ].filter(Boolean);
};
