//This function finds and replace objects inside objects, exmple: {key:{key:{key:{}}}}
export const recursivelyReplaceObj = ({
  key,
  value,
  keys = [],
  obj = {},
  action = "update",
}) => {
  //key founded
  if (keys.length === 0) {
    //if update modify object with updating that key
    if (action === "update") {
      return { ...obj, [key]: value };

      //if delete modify object with deleting that key
    } else if (action === "delete") {
      const { [key]: keyToRemove, ...rest } = obj;
      return rest;
    }
    return obj;
  }

  // split keys
  const [curr, ...restKeys] = keys;

  return {
    ...obj,
    [curr]: recursivelyReplaceObj({
      key,
      value,
      keys: restKeys,
      obj: obj[curr] || {},
      action,
    }),
  };
};
