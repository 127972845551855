import { useState } from "react";
import { Modal } from "antd";
import "./CustomPdfHeaderSelect.scss";
import HeadersCategory from "./HeadersCategory";
import { showWarningMsg } from "../../../utils";
import HeadersList from "./HeadersList";
import { InputComponent } from "../../SidebarPages/Fleet/components";
import { getNestedTableHeaders } from "./utils/getNestedTableHeaders";
import { useSelector } from "react-redux";

const PdfHeadersModal = ({
  isOpen,
  onClose,
  onFinish,
  headers,
  title,
  nested = false,
}) => {
  const [search, setSearch] = useState("");
  const [allHeaders, setAllHeaders] = useState(
    nested
      ? []
      : headers.map((header, index) => ({
          name: header,
          id: index,
        }))
  );
  const [selectedHeaders, setSelectedHeaders] = useState(
    nested ? getNestedTableHeaders(headers) : allHeaders
  );
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const maxLength = 9;

  const handleOk = () => {
    if (nested) {
      onFinish(selectedHeaders);
    } else {
      const sortedCheckedHeaders = [];

      allHeaders.forEach((header) => {
        const isInSelectedHeaders = selectedHeaders.some((selectedHeader) => {
          return (
            selectedHeader.name === header.name &&
            selectedHeader.id === header.id
          );
        });

        if (isInSelectedHeaders) {
          sortedCheckedHeaders.push(header);
        }
      });

      onFinish(sortedCheckedHeaders.map(({ id }) => id));
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const sortHeaders = (selectedHeaders) => {
    const checkedHeaders = [];
    const uncheckedHeaders = [];

    allHeaders.forEach((header) => {
      const isInSelectedHeaders = selectedHeaders.some((selectedHeader) => {
        return (
          selectedHeader.name === header.name && selectedHeader.id === header.id
        );
      });

      if (isInSelectedHeaders) {
        checkedHeaders.push(header);
      } else {
        uncheckedHeaders.push(header);
      }
    });

    setAllHeaders([...checkedHeaders, ...uncheckedHeaders]);
  };

  const addOptionToTable = (option) => {
    if (selectedHeaders.length > maxLength) {
      showWarningMsg({
        content: `You can select maximum ${maxLength + 1} table columns`,
      });
      return;
    }

    const updatedHeaders = [...selectedHeaders, option];
    setSelectedHeaders(updatedHeaders);

    sortHeaders(updatedHeaders);
  };

  const removeOptionFromTable = (option) => {
    if (selectedHeaders.length === 1) {
      showWarningMsg({
        content: "At least one table column should be selected",
      });
      return;
    }

    const updatedHeaders = selectedHeaders.filter(
      (tableOption) => tableOption.id !== option.id
    );

    setSelectedHeaders(updatedHeaders);
    sortHeaders(updatedHeaders);
  };

  return (
    <Modal
      title={`${title || "Table Columns"}`}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName={`pdfExportClassName ${
        isDarkMode && "pdfExportClassNameDark"
      }`}
      data-testid="pdf-Modal"
    >
      <div>
        <h3>Add/Remove Table Columns</h3>
        <InputComponent
          type="text"
          placeholder={"Search table Columns..."}
          onChange={(event) => setSearch(event.target.value)}
        />
        <div className="tableOptionBody">
          {nested ? (
            Object.entries(headers).map(([key, value]) => (
              <HeadersCategory
                {...{
                  data: value,
                  categoryName: key,
                  allData: selectedHeaders,
                  setAllData: setSelectedHeaders,
                  search,
                }}
              />
            ))
          ) : (
            <HeadersList
              {...{
                headers: allHeaders,
                setHeaders: setAllHeaders,
                selectedHeaders,
                removeOptionFromTable,
                addOptionToTable,
                search,
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PdfHeadersModal;
