import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { pliTaxAmountCalculate } from "../../../tools/controllers/CellEditingEvents";
import {
  dimensionFormatterAddons,
  evaluateMathExpressionAddons,
} from "../../../tools/formatters/evaluators";
import { calculateTotalPrice } from "../../../tools/formatters/pricing";
import GridRowModifier from "../../cellRenderers/GridRowModifier/gridRowModifier";

export const getColumnsPLIAddon = (props) => {
  let columnDefs = [
    {
      headerName: "ID",
      headerTooltip: "ID",
      field: "id",
      minWidth: 65,
      width: 65,
      editable: false,
      cellClass: "id-column",
      // rowDrag: true,
    },
    {
      headerName: "Approved",
      headerTooltip: "Approved",
      field: "approved",
      minWidth: 150,
      hide: true,
      width: 150,
      editable: false,
      cellRenderer: "CheckboxRenderer",
      cellRendererParams: {
        isWritable: props.isWritable,
        pliApproved: props.data.approved,
      },
    },
    {
      headerName: "Name",
      field: "sideWalkShedtype",
      cellEditor: "AddonEditor",
      cellEditorParams: {
        saveSidewalkShedSpanData: props.saveSidewalkShedSpanData,
      },
      valueFormatter: (params) => {
        if (params.value) {
          return params.value[0].toUpperCase() + params.value.substring(1);
        }
      },
    },
    {
      headerName: "L",
      field: "length",
      valueParser: evaluateMathExpressionAddons,
      valueFormatter: dimensionFormatterAddons,
      cellClassRules: {
        "invalid-price": (params) => {
          let value = parseFloat(params?.value);
          return isNaN(value) || value === Infinity || value <= 0;
        },
      },
    },
    {
      headerName: "H",
      field: "height",
      // editable: false,
      valueParser: evaluateMathExpressionAddons,
      valueFormatter: dimensionFormatterAddons,
      cellClassRules: {
        "invalid-price": (params) => {
          let value = parseFloat(params?.value);
          return isNaN(value) || value === Infinity || value <= 0;
        },
      },
      // valueGetter: params => {
      //     if (params.data.height) {
      //         return params.data.height;
      //     } else {
      //         return this.props.data.height;
      //     }
      // },
    },
    {
      headerName: "W",
      field: "width",
      // editable: false,
      valueParser: evaluateMathExpressionAddons,
      valueFormatter: dimensionFormatterAddons,
      cellClassRules: {
        "invalid-price": (params) => {
          let value = parseFloat(params?.value);
          return isNaN(value) || value === Infinity || value <= 0;
        },
      },
      // valueGetter: params => {
      //     if (params.data.width) {
      //         return params.data.width;
      //     } else {
      //         return this.props.data.width;
      //     }
      // },
    },
    // {
    //   headerName: "Span",
    //   field: "span",
    //   width: 150,
    //   hide: true,
    //   cellRenderer: "agGroupCellRenderer",
    //   cellRendererParams: {
    //     suppressCount: true,
    //     // checkbox: true,
    //     innerRenderer: "SpanCellRenderer",
    //     suppressDoubleClickExpand: true,
    //     suppressEnterExpand: true,
    //     addSpan: props.addSpan,
    //     removeLastSpan: props.removeLastSpan,
    //     isWritable: props.isWritable,
    //   },
    //   editable: false,
    //   valueGetter: (params) => {
    //     if (params.data.type === "span") {
    //       return null;
    //     } else {
    //       return params.data.span;
    //     }
    //   },
    //   valueFormatter: (params) => {
    //     if (params.data.span) {
    //       return `(${params.data?.span?.length})`;
    //     } else {
    //       return "N/A";
    //     }
    //   },
    // },
    {
      headerName: "Price",
      headerTooltip: "Price",
      field: "spanPrice",
      hide: !!!props?.canViewPrice,
    },
    {
      headerName: "Description",
      headerTooltip: "Description",
      field: "description",
      width: 300,
      cellRenderer: "rtfEditor",
      // cellRenderer: (params) => {
      //     return params.value ? `${params.value}` : '-';
      // },
      editable: false,
    },
    {
      headerName: "Note",
      headerTooltip: "Note",
      field: "note",
      width: 150,
      cellRenderer: "rtfEditor",
      // cellRenderer: (params) => {
      //     return params.value ? `${params.value}` : '-';
      // },
      editable: false,
    },
    {
      headerName: "Modify",
      headerTooltip: "Modify",
      minWidth: 55,
      width: 55,
      editable: false,
      resizable: false,
      cellRendererFramework: (params) => {
        return (
          <GridRowModifier
            type="addon"
            gridParams={params}
            appendRow={props.onDuplicate}
            duplicateRow={props.onDuplicate}
            removeRow={props.removeAddonRow}
            indexes={{ serviceIndex: 0, optionIndex: 0, elevationIndex: 0 }}
          />
        );
      },
    },
  ];
  return columnDefs;
};

export const defaultColDefPLIAddon = {
  minWidth: 180,
  editable: true,
  filter: true,
  sortable: true,
  resizable: true,
  // flex: 1,
  // autoHeight: true,
  rowHeight: 40,
  enablePivot: true,
  enableRowGroup: true,
  detailRowAutoHeight: true,
  cellClass: "cell-wrap-text",
  valueFormatter: (params) => {
    if (params.value) {
      return params.value;
    } else {
      return "-";
    }
  },
};

export const attachSpanPriceToPLI = ({ data = {}, addons = [], api }) => {
  console.log("attachPriceToPLI", { data, addons, api });
  let tempPrice = 0;

  if (Array.isArray(addons) && addons?.length > 0) {
    addons.forEach((item) => {
      if (item.hasOwnProperty("spanPrice")) {
        tempPrice += forceToNumber(item?.["spanPrice"] || 0);
      }
    });
  }
  data.pliAddonTotal = tempPrice;
  data.taxAmount = pliTaxAmountCalculate(data);
  data.totalPrice = calculateTotalPrice(data);
  api?.applyTransaction({
    update: [data],
  });
  api?.refreshCells({
    force: true,
  });
};
