import RichTextEditorWrapper from "../RichTextEditor/RichTextEditorWrapper";
import { Tick } from "../../pages/Settings/settingsComponents/Roles/src";
import { TrashIcon } from "../../SidebarPages/Communication/assets";
import { Close } from "../../SidebarPages/DynamicView/src";
import MondayButton from "../MondayButton/MondayButton";
import { Info } from "../../SidebarPages/BasePage/src";
import "./RichTextModalEditor.scss";

import { Form, Modal } from "antd";
import { useEffect, useState } from "react";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import { updateDocumentTitle } from "../../../utils/updateDocumentTitle";

const VISIBILITY_BUTTON_TEXT = "Description";
const CONFIRM_BUTTON_TEXT = "Save Changes";
const DISCARD_BUTTON_TEXT = "Discard";
const CANCEL_BUTTON_TEXT = "Cancel";
const DISABLED_EDITING = false;
const DISCARD_BUTTON = false;
const TITLE = "Description";
const SAVE_BUTTON = true;
const DARK_MODE = false;
const SAVE_BUTTON_DISABILITY = false;

const RichTextModalEditor = ({
  value,
  onConfirm = () => {},
  onCancel = () => {},
  onDiscard = () => {},
  title = TITLE,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  cancelButtonText = CANCEL_BUTTON_TEXT,
  discardButtonText = DISCARD_BUTTON_TEXT,
  visibilityButtonText = VISIBILITY_BUTTON_TEXT,
  zIndex = 1500,
  darkMode = DARK_MODE,
  discardButton = DISCARD_BUTTON,
  saveButton = SAVE_BUTTON,
  disabledEditing = DISABLED_EDITING,
  saveButtonDisability = SAVE_BUTTON_DISABILITY,
  CustomButton = ({ onClick }) => (
    <MondayButton
      className="mondayButtonBlue"
      onClick={onClick}
      hasIcon={false}
      // Icon={<Info />}
    >
      <div
        className="textContainer"
        style={{
          display: "flex",
        }}
      >
        {visibilityButtonText}
      </div>
    </MondayButton>
  ),
  children = "",
  includes = true,
}) => {
  const [initialValue, setInitialValue] = useState(value);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setInitialValue(value);
  }, [visible]);

  const handleCloseModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  return (
    <>
      <CustomButton {...{ onClick: () => setVisible(true) }} />
      <Modal
        wrapClassName={
          darkMode ? "RichTextModalEditorDark" : "RichTextModalEditorLight"
        }
        onCancel={handleCloseModal}
        setVisible={setVisible}
        closeIcon={<Close />}
        open={visible}
        zIndex={zIndex}
        afterOpenChange={(event) => {
          if (typeof title === "string") {
            event && updateDocumentTitle({ newTitle: title });
          }
        }}
        title={title}
        centered
        destroyOnClose={true}
        footer={
          <>
            <MondayButton
              className="mondayButtonGrey"
              onClick={() => {
                onCancel();
                handleCloseModal();
              }}
              Icon={<Close />}
            >
              <div className="textContainer">{cancelButtonText}</div>
            </MondayButton>

            {discardButton && (
              <MondayButton
                className="mondayButtonRed"
                onClick={() => {
                  onDiscard();
                  handleCloseModal();
                }}
                Icon={<TrashIcon />}
              >
                <div className="textContainer">{discardButtonText}</div>
              </MondayButton>
            )}
            {saveButton && (
              <MondayButton
                className="confirmButton"
                onClick={() => {
                  onConfirm({ newValue: initialValue });
                  handleCloseModal();
                }}
                disabled={saveButtonDisability}
                Icon={<Tick />}
              >
                <div className="textContainer">{confirmButtonText}</div>
              </MondayButton>
            )}
          </>
        }
      >
        {children}
        <div className="accountingConfirmationModalContainer">
          <RichTextEditor
            {...{
              onChange: (newValue) => {
                if (value.includes(newValue) && newValue !== "") {
                  setInitialValue(value + "" + newValue);
                } else {
                  setInitialValue(newValue);
                }
              },
              disabled: disabledEditing,
              value: initialValue,
              initialValue,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default RichTextModalEditor;
