import { findTutorialSteps } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";

function stepsMapHelper(title) {
  const stepsMap = {
    "Claim Statuses": ".formStepper",
    "Upload Files": ".docUploader",
    Details: ".detailsCard",
    Notes: ".notesCard",
    Save: ".saveButton",
    "Assign To": ".assignedToSelect",
  };
  return document.querySelector(stepsMap[title]) || null;
}

function mapRefs(dbSteps = [], isDarkMode) {
  return dbSteps?.map((step) => ({
    ...step,
    target: () => stepsMapHelper(step?.title),
    className: !isDarkMode ? `custom-tour-light` : `custom-tour-dark`,
  }));
}

function claimGuideHelper({ programFields, isDarkMode }) {
  const steps = findTutorialSteps("claimModal", programFields);
  return mapRefs(steps, isDarkMode);
}
export default claimGuideHelper;
