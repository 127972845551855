import { message } from "antd";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

class AmountReq {
  constructor(amountObj) {
    this.amounts = amountObj;
  }

  getData() {
    return this.amounts;
  }

  setAmounts(amounts) {
    this.amounts = amounts;
  }

  updateAmounts(newKey, newValue) {
    const updatedAmount = { ...this.amounts, [newKey]: newValue };
    this.amounts = updatedAmount;
  }

  chargeThisPeriod(thisPeriod, application, service) {
    let {
      fromPrevious = 0,
      amount = 0,
      charged = 0,
      retainagePercentage = 0,
      reducedBalance = 0,
      accRetainageBalance = 0,
      thisKeptRetainage = 0,
      accRetaiangeDist = 0,
      thisDistRetainage = 0,
      fromPreviousRetainageBalance = 0,
      customAmount = false,
    } = this.amounts;
    console.log("chargeThisPeriod", { service });
    const SERVICE_RETAINAGE = customAmount
      ? retainagePercentage
      : service?.retainage || 0;
    const totalCompleated = fromPrevious + thisPeriod;
    const percentageCompleated = (totalCompleated / amount) * 100;
    const balanceToFinish = amount - totalCompleated;
    const retainage = (totalCompleated * SERVICE_RETAINAGE) / 100;
    const keptRetainage = thisPeriod * (SERVICE_RETAINAGE / 100);

    /// Here we will calculate the retainage
    thisKeptRetainage = thisPeriod * (SERVICE_RETAINAGE / 100);
    accRetainageBalance = fromPreviousRetainageBalance + thisKeptRetainage;
    accRetaiangeDist += thisDistRetainage;
    // VERY IMPORTANT: CURRENT PAYMENT FORMULA
    const paymentDue = thisPeriod - thisKeptRetainage + thisDistRetainage;

    this.amounts = {
      ...this.amounts,
      keptRetainage,
      paymentDue,
      percentageCompleated,
      totalCompleated,
      thisPeriod,
      balanceToFinish,
      retainage,
      accRetainageBalance,
      accRetaiangeDist,
      thisKeptRetainage,
      thisDistRetainage,
      fromPreviousRetainageBalance,
    };

    return this.amounts;
  }

  retainageDeducted(application, service, newRetainage) {
    let {
      fromPrevious = 0,
      amount = 0,
      charged = 0,
      retainagePercentage = 0,
      reducedBalance = 0,
      accRetainageBalance = 0,
      thisKeptRetainage = 0,
      accRetaiangeDist = 0,
      thisDistRetainage = 0,
      fromPreviousRetainageBalance = 0,
      thisPeriod,
      customAmount = false,
    } = this.amounts;
    console.log("chargeThisPeriod", { service });
    // const totalCompleated = fromPrevious + thisPeriod;
    // const percentageCompleated = (totalCompleated / amount) * 100;
    // const balanceToFinish = amount - totalCompleated;
    // const retainage = (totalCompleated * retainagePercentage) / 100;
    // const keptRetainage = thisPeriod * (retainagePercentage / 100);
    const SERVICE_RETAINAGE = forceToNumber(service?.retainage || 0);
    if (newRetainage < SERVICE_RETAINAGE) {
      // message.warning("This is a hoist service");
      // ignore this kept retainage
      let NEW_RATIO_RET = newRetainage / SERVICE_RETAINAGE;
      let NEW_RETAINAGE = accRetainageBalance * NEW_RATIO_RET;
      let tempAccRetainageBalance = accRetainageBalance - thisKeptRetainage;
      if (tempAccRetainageBalance - NEW_RETAINAGE > 0) {
        this.amounts.thisDistRetainage =
          tempAccRetainageBalance - NEW_RETAINAGE;
      }
      this.amounts.accRetaiangeDist += this.amounts.thisDistRetainage;
      this.amounts.thisKeptRetainage =
        this.amounts.thisPeriod * (newRetainage / 100);
      this.amounts.accRetainageBalance = NEW_RETAINAGE;
      this.amounts.paymentDue =
        this.amounts.thisPeriod -
        this.amounts.thisKeptRetainage +
        this.amounts.thisDistRetainage;
    } else {
      this.amounts.accRetainageBalance -= this.amounts.thisKeptRetainage;
      this.amounts.thisKeptRetainage =
        this.amounts.thisPeriod * (newRetainage / 100);
      this.amounts.accRetainageBalance += this.amounts.thisKeptRetainage;
      this.amounts.paymentDue =
        this.amounts.thisPeriod -
        this.amounts.thisKeptRetainage +
        this.amounts.thisDistRetainage;
      // const paymentDue = thisPeriod - thisKeptRetainage + thisDistRetainage;

      // this.amounts = {
      //   ...this.amounts,
      //   paymentDue,
      //   // thisPeriod,
      //   // accRetainageBalance,
      //   // accRetaiangeDist,
      //   // thisKeptRetainage,
      //   // thisDistRetainage,
      //   // fromPreviousRetainageBalance,
      // };
    }

    // this.amounts = {
    //   ...this.amounts,
    //   paymentDue,
    //   // thisPeriod,
    //   // accRetainageBalance,
    //   // accRetaiangeDist,
    //   // thisKeptRetainage,
    //   // thisDistRetainage,
    //   // fromPreviousRetainageBalance,
    // };

    return this.amounts;
  }

  changeThisRetainage(value, key, service) {
    let {
      fromPrevious = 0,
      amount = 0,
      charged = 0,
      retainagePercentage = 0,
      reducedBalance = 0,
      accRetainageBalance = 0,
      thisKeptRetainage = 0,
      accRetaiangeDist = 0,
      thisDistRetainage = 0,
      fromPreviousRetainageBalance = 0,
      thisPeriod,
    } = this.amounts;

    if (key === "thisKeptRetainage") {
      this.amounts = {
        ...this.amounts,
        [key]: value,
        // accRetainageBalance: accRetainageBalance + oldVal,
        // accRetaiangeDist: accRetaiangeDist - oldVal,
      };
    } else if (key === "accRetainageBalance") {
      this.amounts = {
        ...this.amounts,
        [key]: value,
      };
    } else if (key === "thisDistRetainage") {
      this.amounts = {
        ...this.amounts,
        [key]: value,
        paymentDue: thisPeriod - thisKeptRetainage + value,
      };
    } else if (key === "thisKeptRetainage") {
      this.amounts = {
        ...this.amounts,
        [key]: value,
        paymentDue: thisPeriod - value + thisDistRetainage,
      };
    } else if (key === "accRetaiangeDist") {
      this.amounts = {
        ...this.amounts,
        [key]: value,
        // paymentDue: thisPeriod - thisKeptRetainage + value,
      };
    } else if (key === "retainageDndInd") {
      let {
        fromPrevious = 0,
        amount = 0,
        charged = 0,
        retainagePercentage = 0,
        reducedBalance = 0,
        accRetainageBalance = 0,
        thisKeptRetainage = 0,
        accRetaiangeDist = 0,
        thisDistRetainage = 0,
        fromPreviousRetainageBalance = 0,
        thisPeriod,
        customAmount = true,
      } = this.amounts;
      let newRetainage = value;
      console.log("chargeThisPeriod", { service });
      // const totalCompleated = fromPrevious + thisPeriod;
      // const percentageCompleated = (totalCompleated / amount) * 100;
      // const balanceToFinish = amount - totalCompleated;
      // const retainage = (totalCompleated * retainagePercentage) / 100;
      // const keptRetainage = thisPeriod * (retainagePercentage / 100);
      const SERVICE_RETAINAGE = customAmount
        ? forceToNumber(retainagePercentage || 0)
        : forceToNumber(service?.retainage || 0);
      if (newRetainage < SERVICE_RETAINAGE) {
        // we now have retainage deduction
        if (newRetainage > 0) {
          thisKeptRetainage = thisPeriod * (newRetainage / 100);
          let ratioRet = newRetainage / SERVICE_RETAINAGE;
          ``;
          thisDistRetainage = accRetainageBalance * ratioRet;
          accRetainageBalance -= thisDistRetainage;
          accRetaiangeDist += thisDistRetainage;
        } else {
          thisKeptRetainage = 0;
          thisDistRetainage = accRetainageBalance;
          accRetainageBalance -= thisDistRetainage;
          accRetaiangeDist += thisDistRetainage;
        }
        // VERY IMPORTANT: CURRENT PAYMENT FORMULA
      } else {
        if (SERVICE_RETAINAGE > 0) {
          thisKeptRetainage = thisPeriod * (newRetainage / 100);
          let ratioRet = newRetainage / SERVICE_RETAINAGE;
          thisDistRetainage = accRetainageBalance * ratioRet;
          accRetainageBalance -= thisDistRetainage;
          accRetaiangeDist += thisDistRetainage;
        } else {
          thisKeptRetainage = thisPeriod * (newRetainage / 100);
          thisDistRetainage = accRetainageBalance;
          accRetainageBalance -= thisDistRetainage;
          accRetaiangeDist += thisDistRetainage;
        }
      }
      const paymentDue = thisPeriod - thisKeptRetainage + thisDistRetainage;

      this.amounts = {
        ...this.amounts,
        paymentDue,
        thisPeriod,
        accRetainageBalance,
        accRetaiangeDist,
        thisKeptRetainage,
        thisDistRetainage,
        fromPreviousRetainageBalance,
        [key]: value,
      };

      return this.amounts;
    } else {
      this.amounts = {
        ...this.amounts,
        [key]: value,
      };
    }
    // console.log("chargeThisPeriod", { service });
    // // const totalCompleated = fromPrevious + thisPeriod;
    // // const percentageCompleated = (totalCompleated / amount) * 100;
    // // const balanceToFinish = amount - totalCompleated;
    // // const retainage = (totalCompleated * retainagePercentage) / 100;
    // // const keptRetainage = thisPeriod * (retainagePercentage / 100);
    // const SERVICE_RETAINAGE = forceToNumber(service?.retainage || 0);
    // if (newRetainage < SERVICE_RETAINAGE) {
    //   // we now have retainage deduction
    //   if (newRetainage > 0) {
    //     thisKeptRetainage = thisPeriod * (newRetainage / 100);
    //     let ratioRet = newRetainage / SERVICE_RETAINAGE;
    //     thisDistRetainage = accRetainageBalance * ratioRet;
    //     accRetainageBalance -= thisDistRetainage;
    //     accRetaiangeDist += thisDistRetainage;
    //   } else {
    //     thisKeptRetainage = 0;
    //     thisDistRetainage = accRetainageBalance;
    //     accRetainageBalance -= thisDistRetainage;
    //     accRetaiangeDist += thisDistRetainage;
    //   }
    //   // VERY IMPORTANT: CURRENT PAYMENT FORMULA
    // } else {
    //   if (SERVICE_RETAINAGE > 0) {
    //     thisKeptRetainage = thisPeriod * (newRetainage / 100);
    //     let ratioRet = newRetainage / SERVICE_RETAINAGE;
    //     thisDistRetainage = accRetainageBalance * ratioRet;
    //     accRetainageBalance -= thisDistRetainage;
    //     accRetaiangeDist += thisDistRetainage;
    //   } else {
    //     thisKeptRetainage = thisPeriod * (newRetainage / 100);
    //     thisDistRetainage = accRetainageBalance;
    //     accRetainageBalance -= thisDistRetainage;
    //     accRetaiangeDist += thisDistRetainage;
    //   }
    // }
    // const paymentDue = thisPeriod - thisKeptRetainage + thisDistRetainage;

    if (key !== "retainageDndInd") {
      this.amounts = {
        // paymentDue,
        // thisPeriod,
        // accRetainageBalance,
        // accRetaiangeDist,
        // thisKeptRetainage,
        // thisDistRetainage,
        // fromPreviousRetainageBalance,
        ...this.amounts,
        paymentDue:
          this.amounts.thisPeriod -
          this.amounts.thisKeptRetainage +
          this.amounts.thisDistRetainage,
      };
    }
    this.amounts.customAmount = true;
    console.log("this.amounts", this.amounts, { value, key });

    return this.amounts;
  }

  includeRental(application, service) {
    let SERVICE_TAX = forceToNumber(this.amounts?.taxRate || 0);
    let TOTAL_WITH_TAX =
      forceToNumber(this.amounts.appliedAmount) * (1 + SERVICE_TAX);
    let THIS_KEPT_RETAINAGE =
      TOTAL_WITH_TAX * (forceToNumber(this.amounts?.retainage) / 100);

    let CREDIT_WITH_TAX =
      forceToNumber(this.amounts?.serviceLevelCredit || 0) * (1 + SERVICE_TAX);
    let CURRENT_PAYMENT_DUE =
      TOTAL_WITH_TAX - THIS_KEPT_RETAINAGE - CREDIT_WITH_TAX;
    let THIS_PERIOD = TOTAL_WITH_TAX - CREDIT_WITH_TAX;

    this.amounts = {
      ...this.amounts,
      thisKeptRetainage: THIS_KEPT_RETAINAGE,
      accRetainageBalance: THIS_KEPT_RETAINAGE,
      accRetaiangeDist: 0,
      thisDistRetainage: 0,
      retainagePercentage: forceToNumber(this.amounts?.retainage),
      retainageTax: SERVICE_TAX,
      creditWithTax: CREDIT_WITH_TAX,
      currentPayment: forceToNumber(CURRENT_PAYMENT_DUE),
      thisPeriod: forceToNumber(THIS_PERIOD),
    };
    return this.amounts;
  }

  addRentalServiceCredit(dataObj) {
    console.log("addRentalServiceCredit", dataObj);
    const {
      creditWithTax = 0,
      currentPayment = 0,
      thisPeriod = 0, // not changed when adding credit
      serviceLevelCredit = 0,
      retainage = 0, // not changed when adding credit
      appliedAmount = 0, // not changed when adding credit
      thisKeptRetainage = 0, // not changed when adding credit
      thisDistRetainage = 0, // not changed
      accRetaiangeDist = 0, // not changed
      accRetainageBalance = 0,
      taxRate = 0, // not changed
      ...rest
    } = dataObj;
    let SERVICE_TAX = forceToNumber(taxRate || 0);

    let CREDIT_WITH_TAX =
      forceToNumber(serviceLevelCredit || 0) * (1 + SERVICE_TAX);
    let CREDIT_RETAIANGE = forceToNumber(CREDIT_WITH_TAX) * (retainage / 100);
    let THIS_PERIOD = forceToNumber(appliedAmount) * (1 + SERVICE_TAX);
    THIS_PERIOD += forceToNumber(CREDIT_WITH_TAX);

    let THIS_RETAINAGE = forceToNumber(THIS_PERIOD) * (retainage / 100);
    let CURRENT_PAYMENT_DUE =
      forceToNumber(THIS_PERIOD || 0) - forceToNumber(THIS_RETAINAGE);

    console.log("changes", {
      creditWithTax: CREDIT_WITH_TAX,
      currentPayment: CURRENT_PAYMENT_DUE,
      thisKeptRetainage: THIS_RETAINAGE,
      accRetainageBalance: THIS_RETAINAGE,
      thisPeriod: THIS_PERIOD,
    });

    dataObj.creditWithTax = CREDIT_WITH_TAX;
    dataObj.currentPayment = CURRENT_PAYMENT_DUE;
    dataObj.thisKeptRetainage = THIS_RETAINAGE;
    dataObj.thisPeriod = forceToNumber(THIS_PERIOD);
    dataObj.accRetainageBalance = THIS_RETAINAGE;
    return {
      ...dataObj,
      // creditWithTax: CREDIT_WITH_TAX,
      // currentPayment: CURRENT_PAYMENT_DUE,
      // thisKeptRetainage: THIS_RETAINAGE,
      // accRetainageBalance: THIS_RETAINAGE,
      // thisPeriod: THIS_PERIOD,
    };
  }

  applyRentalRetaiange(rentalRetaiange) {
    const { application, rentalNumber } = this.amounts;
    let ACC_RENTALS = application?.accumulatedRentals || {};
    console.log("ACC_RENTALS", ACC_RENTALS);
    if (Object.keys(ACC_RENTALS).length === 0) {
      return;
    }
    for (const applicationNo in ACC_RENTALS) {
      if (Object.keys(ACC_RENTALS[applicationNo]).length === 0) {
        return;
      }
      for (const rentalNo in ACC_RENTALS[applicationNo]) {
        if (Object.keys(ACC_RENTALS[applicationNo][rentalNo]).length === 0) {
          return;
        }
        console.log("rentals", {
          rentalNo,
          rentalNumber,
        });
        if (rentalNo.toString() === rentalNumber.toString()) {
          let SERVICES = ACC_RENTALS[applicationNo][rentalNo];
          if (Object.keys(SERVICES).length === 0) {
            return;
          }
          for (const serviceLabel in SERVICES) {
            console.log("serviceLabel", SERVICES[serviceLabel]);
            const SERVICE = SERVICES[serviceLabel];
            // NOW WE PERFORM RETAIANGE DEDUCTION OR ADDITION
            let NEW_RENTAL_RETAIANGE = forceToNumber(rentalRetaiange);
            let PREVIOUS_RENTAL_RETAIANGE = forceToNumber(SERVICE?.retainage);

            if (NEW_RENTAL_RETAIANGE < PREVIOUS_RENTAL_RETAIANGE) {
              if (NEW_RENTAL_RETAIANGE > 0) {
                let ratioRet = NEW_RENTAL_RETAIANGE / PREVIOUS_RENTAL_RETAIANGE;
                let accRetaiangeDist = SERVICE?.accRetaiangeDist || 0;
                SERVICE.thisDistRetainage =
                  SERVICE.accRetainageBalance * ratioRet;
                SERVICE.accRetainageBalance -= SERVICE.thisDistRetainage;
                SERVICE.accRetaiangeDist += SERVICE.thisDistRetainage;
              } else {
                SERVICE.thisDistRetainage = SERVICE.accRetainageBalance;
                SERVICE.accRetainageBalance -= SERVICE.thisDistRetainage;
                SERVICE.accRetaiangeDist += SERVICE.thisDistRetainage;
              }
            } else {
              if (PREVIOUS_RENTAL_RETAIANGE > 0) {
                let ratioRet = NEW_RENTAL_RETAIANGE / PREVIOUS_RENTAL_RETAIANGE;
                SERVICE.thisDistRetainage =
                  SERVICE.accRetainageBalance * ratioRet;
                SERVICE.accRetainageBalance -= SERVICE.thisDistRetainage;
                SERVICE.accRetaiangeDist += SERVICE.thisDistRetainage;
              } else {
                SERVICE.thisDistRetainage = SERVICE.accRetainageBalance;
                SERVICE.accRetainageBalance -= SERVICE.thisDistRetainage;
                SERVICE.accRetaiangeDist += SERVICE.thisDistRetainage;
              }
            }
            // since this period is 0 and current Retaiange kept (thisRet) is 0
            // then the payment due is the distributed Retainage
            SERVICE.retainage = NEW_RENTAL_RETAIANGE;
            SERVICE.retainagePercentage = NEW_RENTAL_RETAIANGE;
            SERVICE.thisKeptRetainage = 0;
            SERVICE.paymentDue = SERVICE.thisDistRetainage;
          }
        }
      }
    }
    console.log("ACC_RENTALS22222", ACC_RENTALS);
    return ACC_RENTALS;
  }

  rentalRetainageDeducted(application, service, newRetainage) {
    let { appliedAmount = 0, retainage = 0 } = this.amounts;
    console.log("chargeThisPeriod", { service });
    // const totalCompleated = fromPrevious + thisPeriod;
    // const percentageCompleated = (totalCompleated / amount) * 100;
    // const balanceToFinish = amount - totalCompleated;
    // const retainage = (totalCompleated * retainagePercentage) / 100;
    // const keptRetainage = thisPeriod * (retainagePercentage / 100);
    const SERVICE_RETAINAGE = forceToNumber(retainage || 0);
    if (newRetainage < SERVICE_RETAINAGE) {
      // we now have retainage deduction
      if (newRetainage > 0) {
        thisKeptRetainage = appliedAmount * (newRetainage / 100);
        let ratioRet = newRetainage / SERVICE_RETAINAGE;
        thisDistRetainage = accRetainageBalance * ratioRet;
        accRetainageBalance -= thisDistRetainage;
        accRetaiangeDist += thisDistRetainage;
      } else {
        thisKeptRetainage = 0;
        thisDistRetainage = accRetainageBalance;
        accRetainageBalance -= thisDistRetainage;
        accRetaiangeDist += thisDistRetainage;
      }
      // VERY IMPORTANT: CURRENT PAYMENT FORMULA
    } else {
      if (SERVICE_RETAINAGE > 0) {
        thisKeptRetainage = appliedAmount * (newRetainage / 100);
        let ratioRet = newRetainage / SERVICE_RETAINAGE;
        thisDistRetainage = accRetainageBalance * ratioRet;
        accRetainageBalance -= thisDistRetainage;
        accRetaiangeDist += thisDistRetainage;
      } else {
        thisKeptRetainage = appliedAmount * (newRetainage / 100);
        thisDistRetainage = accRetainageBalance;
        accRetainageBalance -= thisDistRetainage;
        accRetaiangeDist += thisDistRetainage;
      }
    }
    const paymentDue = appliedAmount - thisKeptRetainage + thisDistRetainage;

    this.amounts = {
      ...this.amounts,
      paymentDue,
      thisPeriod,
      accRetainageBalance,
      accRetaiangeDist,
      thisKeptRetainage,
      thisDistRetainage,
      fromPreviousRetainageBalance,
    };

    return this.amounts;
  }
}

export default AmountReq;
// thisKeptRetainage: 0,
// accRetainageBalance: 0,
// accRetaiangeDist: 0,
// thisDistRetainage: 0,
// retainagePercentage: 0,
