import { formatCurrency } from "../../../../../../../../../../../utils";
import "./amount-cell-renderer.css";
import { CheckCircleFilled } from "@ant-design/icons";

const AmountCellRenderer = ({ value }) => (
  <div className="amount-cell-renderer">
    <span className="amount-value">{formatCurrency(value, "USD")}</span>
    <div
      className="amount-status"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CheckCircleFilled style={{ color: "#68C142" }} />
    </div>
  </div>
);

export default AmountCellRenderer;
