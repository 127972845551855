import React, { createContext, useContext } from "react";
import useCreateInvoice from "../hooks/useCreateInvoice";

const InvoiceModalContext = createContext();

export const useCreateInvoiceContext = () => {
  return useContext(InvoiceModalContext);
};

export const InvoiceModalProvider = ({ children }) => {
  const newInvoice = useCreateInvoice();
  return (
    <InvoiceModalContext.Provider value={{ ...newInvoice }}>
      {children}
    </InvoiceModalContext.Provider>
  );
};
