import { Button } from "antd";
import "./PaymentMethods.scss";
import { validateThenSavePayment } from "../../../../utils/checkers";
import {
  BankOutlined,
  CreditCardOutlined,
  DollarOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import { WithTooltip } from "../../../../../../../../../../commonComponents";

const paymentMethods = [
  { id: 1, tooltipKey: "cashPay", name: "Cash", icon: <DollarOutlined /> },
  { id: 2, tooltipKey: "checkPay", name: "Check", icon: <BankOutlined /> },
  {
    id: 3,
    tooltipKey: "creditOrDebitPay",
    name: "Credit Debit",
    icon: <CreditCardOutlined />,
  },
  { id: 4, tooltipKey: "eCheckPay", name: "e-Check", icon: <LaptopOutlined /> },
  { id: 5, tooltipKey: "wirePay", name: "Wire", icon: <BankOutlined /> },
];

const PaymentMethods = ({
  selectedPaymentMethod,
  setState,
  state,
  disabled,
}) => {
  return (
    <div className="payment-method-elements">
      {paymentMethods.map((paymentMethod) => (
        <WithTooltip
          tooltipCategory="Payments"
          tooltipKey={paymentMethod.tooltipKey}
        >
          <Button
            key={paymentMethod.id}
            className={`btn-payment-method ${
              selectedPaymentMethod &&
              selectedPaymentMethod === paymentMethod.name
                ? "selected"
                : ""
            }`}
            onClick={() =>
              setState(
                validateThenSavePayment({
                  ...state,
                  paymentMethod: paymentMethod.name,
                  checkDates: {},
                })
              )
            }
            icon={paymentMethod.icon}
            disabled={disabled}
            role="button"
          >
            {paymentMethod.name}
          </Button>
        </WithTooltip>
      ))}
    </div>
  );
};

export default PaymentMethods;
