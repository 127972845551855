import { useState, useRef } from "react";
import { Popover } from "antd";
import { RichText } from "../../../../DynamicView/FormItemComponents/index";
import { Select, Button } from "antd";
import { getProcessedTakeOf } from "../../../../Estimations/components/ProposalBuilder/ProposalModal/dataset/takeOffProcess";
import { useSelector } from "react-redux";

export default function DescriptionButton({
  data,
  rowIndex,
  cellStyle = {},
  popoverStyle = {},
  className = "",
  disabled,
  accountingTemplates = [],
  categoryData,
  category,
  showGenerate = true,
  onDescriptionChange,
}) {
  const inputRef = useRef();
  const textareaRef = useRef();
  const [selectedTempId, setSelectedTempId] = useState();
  const [description, setDescription] = useState(data?.description);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const postProcess = ({ estimation, templateData }) => {
    const processedTakeOff = !!estimation
      ? getProcessedTakeOf({ estimation, version: {}, type: "preview" })
      : null;
    const regex = new RegExp("{(.*?)(?=})}", "g");
    const matchedItems = Array.from(templateData?.matchAll(regex), (x) => x);
    const str = templateData?.replace(/\{\{(.*?)\}\}/g, function (i, match) {
      return processedTakeOff?.[match] || match;
    });
    return str;
  };

  const handleChange = (id) => {
    const accountingTemplate = accountingTemplates?.find(
      ({ templateId: ID }) => id === ID
    );
    const selCategoryData = categoryData["estimations"]?.find(
      ({ estimationId }) => estimationId === data?.categoryId
    );
    const description = postProcess({
      estimation: selCategoryData,
      templateData: accountingTemplate?.templateContent,
    });
    // onDescriptionChange(description);
    setDescription(description);
  };

  return (
    <>
      <Popover
        //popoverMatchSelectWidth={false}
        ref={textareaRef}
        style={{ ...popoverStyle }}
        overlayClassName={className}
        content={
          <>
            {showGenerate && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Select
                  style={{
                    width: 120,
                  }}
                  onChange={setSelectedTempId}
                  popupClassName={isDarkMode && "darkDropDown"}
                  options={accountingTemplates
                    ?.filter(
                      ({ templateType }) => templateType === data?.category
                    )
                    ?.map(({ templateTitle, templateId }) => ({
                      value: templateId,
                      label: templateTitle,
                    }))}
                />
                <Button
                  type="primary"
                  disabled={!!!selectedTempId}
                  style={{
                    background: "#6c757d",
                    border: "1px solid #6c757d",
                    cursor: !!!selectedTempId ? "not-allowed" : "pointer",
                  }}
                  onClick={() => handleChange(selectedTempId)}
                >
                  Generate
                </Button>
              </div>
            )}

            <RichText
              color="white"
              formItemName={"description"}
              initialValue={description || ""}
              setValue={(value) => {
                data.description = value;
                // setDescription(value);
              }}
              disabled={disabled}
            />
          </>
        }
        onOpenChange={(e) => {
          if (!e) {
            onDescriptionChange(data?.description);
          }
        }}
        trigger="click"
        placement="bottom"
      >
        <div
          className="descriptionContainer"
          ref={inputRef}
          style={{
            display: "block",
            overflow: "hidden",
            width: "-webkit-fill-available",
            borderRadius: 5,
            height: "100%",
            cursor: "pointer",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            ...cellStyle,
          }}
        >
          {data.description
            ? data.description
                ?.split("\n")
                ?.join(" ")
                ?.replace(/(<([^>]+)>)/gi, "")
                ?.replace("&nbsp; ", "")
            : ""}
        </div>
      </Popover>
    </>
  );
}
