import { Tooltip } from "antd";
import moment from "moment";
import { devices } from "../../../../../../DeviceManagement/utils";
import { DeleteFilled } from "@ant-design/icons";
import { EditIcon } from "../../../../../../../../../../icons";

const columnDefs = ({ deleteIpAddressHandler, setToEdit }) => [
  {
    headerName: "IP Address",
    field: "ipv4",
    maxWidth: 130,
    minWidth: 130,
  },
  {
    headerName: "Added By",
    field: "addedBy",
    cellRenderer: ({ value }) => {
      return value?.name;
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return value?.name || "";
      },
    },
    maxWidth: 130,
    minWidth: 130,
  },
  {
    headerName: "Added At",
    field: "addedAt",
    cellRenderer: ({ value }) => {
      return moment(value).format("DD/MM/YYYY - hh:mm A");
    },
    pdfExportOptions: {
      modifyCellValue: (value) => moment(value).format("MM/DD/YYYY - hh:mm A"),
    },
    maxWidth: 200,
    minWidth: 180,
  },
  {
    headerName: "Devices",
    field: "devices",
    maxWidth: 270,
    minWidth: 270,

    cellRenderer: ({ value }) => {
      const proceedValues = devices.map((device) => ({
        ...device,
        isAllowed: value?.some((value) => value?.deviceId === device?.deviceId),
      }));
      return (
        <div className="ipAddressDevicesColumn">
          {proceedValues.map((el) => {
            const dimensions =
              el.deviceName === "Website"
                ? { width: "22", height: "44" }
                : el.deviceName === "IPad"
                ? { width: "18", height: "44" }
                : { width: "14", height: "44" };

            const className = el.isAllowed ? "allowed" : "notAllowed";
            const color = el.isAllowed ? "#71cf48" : "#FE4C4A";

            return (
              <Tooltip
                arrow={false}
                title={
                  el.isAllowed
                    ? `This ipv4 its allowed to login from ${el.deviceName}`
                    : `This ipv4 its not allowed to login from ${el.deviceName}`
                }
                key={el.deviceId}
              >
                <div
                  key={el.deviceId}
                  className={`ipAddressDeviceColumn ${className}`}
                >
                  {el.deviceIcon(color, dimensions)}
                  <div style={{ color: color }}>{el.deviceName}</div>
                </div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
  },
  {
    headerName: "Remove",
    field: "actions",
    cellRendererFramework: ({ data }) => {
      return (
        <div className="ipAddressDeleteString">
          <DeleteFilled
            className="deleteIcon"
            onClick={() => deleteIpAddressHandler(data.id)}
          />
          <EditIcon className="editIcon" onClick={() => setToEdit(data)} />
        </div>
      );
    },
    pdfExportOptions: {
      skipColumn: true,
    },
  },
];
export default columnDefs;
