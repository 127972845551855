import { cloneDeep } from "lodash";
import { rentalDataExtractor } from "./ControlPanelComponents/ExportToExcel/exportToExcelFunctions";
import { checkIfServiceIsHoist } from "../../../../../../../../Estimations/DataEntryGrid/models/Service";
export const thisRetainageCalculator = ({ fakeApplication, applications }) => {
  // console.log("thisRetainageCalculator", {
  //   fakeApplication,
  //   applications,
  // });
  const THIS_APPLICATION = fakeApplication;
  const PREV_APPLICATIONS = cloneDeep(
    applications?.filter(
      (el) => el?.applicationNo < THIS_APPLICATION?.applicationNo
    )
  );
  const BEFORE_APPLICATION =
    PREV_APPLICATIONS?.find(
      (a) => a?.applicationNo === THIS_APPLICATION?.applicationNo - 1
    ) || {};
  // console.log("Before Application22", BEFORE_APPLICATION);
  let CURRENT_THIS_RETAINAGE = 0;
  let CURRENT_RENTAL_RETAINAGE = 0;
  let PREV_RENTAL_RETAINAGE = 0;
  // This is needed to calcualate the retainage left for this current Application
  //First, we will calculate the retaiange for current Services (Hoist or Other) for current Application
  const THIS_SERVICES = THIS_APPLICATION?.services || [];
  for (const thisService of THIS_SERVICES) {
    if (checkIfServiceIsHoist(thisService)) {
      CURRENT_THIS_RETAINAGE += hoistAmountCalculator({
        serviceOptions: thisService?.serviceOptions || [],
        service: thisService,
      });
    } else {
    }
    CURRENT_THIS_RETAINAGE += serviceAmountCalculator({
      service: thisService,
    });
  }
  // lets continue with current included rental
  THIS_APPLICATION.includedRentals?.forEach(({ services = [] }) => {
    services.forEach((el) => {
      let taxAmount = el?.appliedAmount * el?.taxRate || 0;
      let credit =
        (el?.serviceLevelCredit || 0) +
        (el?.serviceLevelCredit || 0) * (el?.taxRate || 0);
      let totalRentAmount = el?.appliedAmount + taxAmount + credit;
      let retainage =
        el?.retainage || 0 > 0 ? (el?.retainage / 100) * totalRentAmount : 0;
      // console.log("this included rental", credit);
      // totalRentalWithTax +=
      //   Number(el?.appliedAmount) +
      //   Number(taxAmount) +
      //   Number(retainage) +
      //   Number(credit);
      // rentalThis +=
      //   Number(el?.appliedAmount) + Number(credit) + Number(taxAmount);
      // totalCreditRental += Number(credit);
      // rentalRet += Number(retainage);
      CURRENT_RENTAL_RETAINAGE += Number(retainage);
    });
  });
  let OTHER_RENTALS = PREV_APPLICATIONS?.map((el) => {
    return rentalDataExtractor({
      fakeApplication: el,
    })
      ?.filter(Boolean)
      ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
      ?.map((e) => {
        const BEFORE_RENTAL_RET =
          BEFORE_APPLICATION?.totalities?.prevRentalsRetainage?.[
            `Rental${e?.rentalNumber}`
          ] ?? e?.retainage;
        const CURRENT_RENTAL_RET =
          fakeApplication?.totalities?.prevRentalsRetainage?.[
            `Rental${e?.rentalNumber}`
          ] ?? e?.retainage;
        // console.log("BEFORE RENTALS", {
        //   THIS_APPLICATION,
        //   BEFORE_RENTAL_RET,
        //   CURRENT_RENTAL_RET,
        //   BEFORE_APPLICATION,
        // });
        return {
          ...e,
          isPrevious: true,
          applicableRetainage: BEFORE_RENTAL_RET - CURRENT_RENTAL_RET,
          retainage: CURRENT_RENTAL_RET,
        };
      });
  }).flatMap((e) => e);
  // console.log("otherRentals", OTHER_RENTALS);
  // lets proceed with previous included rentals
  for (const prevRental of OTHER_RENTALS) {
    let taxAmount = prevRental?.amountForThisMonth * prevRental?.taxRate || 0;
    let credit =
      (prevRental?.serviceLevelCredit || 0) +
      (prevRental?.serviceLevelCredit || 0) * (prevRental?.taxRate || 0);
    let totalRentAmount = prevRental?.amountForThisMonth + taxAmount + credit;
    let retainage =
      (prevRental?.applicableRetainage || 0) > 0
        ? (prevRental?.applicableRetainage / 100) * totalRentAmount
        : 0;
    PREV_RENTAL_RETAINAGE += retainage;
    // console.log("prevvvvvv", {
    //   retainage,
    //   totalRentAmount,
    //   credit,
    //   taxAmount,
    //   appl: prevRental,
    // });
  }
  console.log("utilsTotalities", {
    CURRENT_RENTAL_RETAINAGE,
    PREV_RENTAL_RETAINAGE,
    CURRENT_THIS_RETAINAGE,
  });
  // console.log("utilsTotalities", {
  //   CURRENT_RENTAL_RETAINAGE,
  //   PREV_RENTAL_RETAINAGE,
  //   CURRENT_THIS_RETAINAGE,
  // });
  return (
    CURRENT_THIS_RETAINAGE + CURRENT_RENTAL_RETAINAGE + PREV_RENTAL_RETAINAGE
  );
};

export const getRetainages = ({ fakeApplication, applications }) => {
  // console.log("thisRetainageCalculator", {
  //   fakeApplication,
  //   applications,
  // });
  const THIS_APPLICATION = fakeApplication;
  const PREV_APPLICATIONS = cloneDeep(
    applications?.filter(
      (el) => el?.applicationNo < THIS_APPLICATION?.applicationNo
    )
  );
  const BEFORE_APPLICATION =
    PREV_APPLICATIONS?.find(
      (a) => a?.applicationNo === THIS_APPLICATION?.applicationNo - 1
    ) || {};
  // console.log("Before Application22", BEFORE_APPLICATION);
  let CURRENT_THIS_RETAINAGE = 0;
  let CURRENT_RENTAL_RETAINAGE = 0;
  let PREV_RENTAL_RETAINAGE = 0;
  // This is needed to calcualate the retainage left for this current Application
  //First, we will calculate the retaiange for current Services (Hoist or Other) for current Application
  const THIS_SERVICES = THIS_APPLICATION?.services || [];
  for (const thisService of THIS_SERVICES) {
    if (checkIfServiceIsHoist(thisService)) {
      CURRENT_THIS_RETAINAGE += hoistAmountCalculator({
        serviceOptions: thisService?.serviceOptions || [],
        service: thisService,
      });
    } else {
    }
    CURRENT_THIS_RETAINAGE += serviceAmountCalculator({
      service: thisService,
    });
  }
  // lets continue with current included rental
  THIS_APPLICATION.includedRentals?.forEach(({ services = [] }) => {
    services.forEach((el) => {
      let taxAmount = el?.appliedAmount * el?.taxRate || 0;
      let credit =
        (el?.serviceLevelCredit || 0) +
        (el?.serviceLevelCredit || 0) * (el?.taxRate || 0);
      let totalRentAmount = el?.appliedAmount + taxAmount + credit;
      let retainage =
        el?.retainage || 0 > 0 ? (el?.retainage / 100) * totalRentAmount : 0;
      // console.log("this included rental", credit);
      // totalRentalWithTax +=
      //   Number(el?.appliedAmount) +
      //   Number(taxAmount) +
      //   Number(retainage) +
      //   Number(credit);
      // rentalThis +=
      //   Number(el?.appliedAmount) + Number(credit) + Number(taxAmount);
      // totalCreditRental += Number(credit);
      // rentalRet += Number(retainage);
      CURRENT_RENTAL_RETAINAGE += Number(retainage);
    });
  });
  let OTHER_RENTALS = PREV_APPLICATIONS?.map((el) => {
    return rentalDataExtractor({
      fakeApplication: el,
    })
      ?.filter(Boolean)
      ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
      ?.map((e) => {
        const BEFORE_RENTAL_RET =
          BEFORE_APPLICATION?.totalities?.prevRentalsRetainage?.[
            `Rental${e?.rentalNumber}`
          ] ?? e?.retainage;
        const CURRENT_RENTAL_RET =
          fakeApplication?.totalities?.prevRentalsRetainage?.[
            `Rental${e?.rentalNumber}`
          ] ?? e?.retainage;
        // console.log("BEFORE RENTALS", {
        //   THIS_APPLICATION,
        //   BEFORE_RENTAL_RET,
        //   CURRENT_RENTAL_RET,
        //   BEFORE_APPLICATION,
        // });
        return {
          ...e,
          isPrevious: true,
          applicableRetainage: BEFORE_RENTAL_RET - CURRENT_RENTAL_RET,
          retainage: CURRENT_RENTAL_RET,
        };
      });
  }).flatMap((e) => e);
  // console.log("otherRentals", OTHER_RENTALS);
  // lets proceed with previous included rentals
  for (const prevRental of OTHER_RENTALS) {
    let taxAmount =
      prevRental?.serviceApplicables?.appliedRent * prevRental?.taxRate || 0;
    let credit =
      (prevRental?.serviceLevelCredit || 0) +
      (prevRental?.serviceLevelCredit || 0) * (prevRental?.taxRate || 0);
    let totalRentAmount =
      prevRental?.serviceApplicables?.appliedRent + taxAmount + credit;
    let retainage =
      (prevRental?.applicableRetainage || 0) > 0
        ? (prevRental?.applicableRetainage / 100) * totalRentAmount
        : 0;
    PREV_RENTAL_RETAINAGE += retainage;
    // console.log("prevvvvvv", {
    //   retainage,
    //   totalRentAmount,
    //   credit,
    //   taxAmount,
    //   appl: prevRental,
    // });
  }
  // console.log("utilsTotalities", {
  //   CURRENT_RENTAL_RETAINAGE,
  //   PREV_RENTAL_RETAINAGE,
  //   CURRENT_THIS_RETAINAGE,
  // });
  return {
    CURRENT_THIS_RETAINAGE,
    CURRENT_RENTAL_RETAINAGE,
    PREV_RENTAL_RETAINAGE,
  };
};
const hoistAmountCalculator = ({ serviceOptions = [], service = {} }) => {
  let result = 0;
  for (const sOption of serviceOptions) {
    for (const option of sOption) {
      const amounts = option?.amounts || [];
      for (const tempAmount of amounts) {
        const {
          retainage = 0,
          carriedRetainage = 0,
          reducedAmount = 0,
          reducedBalance = 0,
          thisPeriod = 0,
          previousRetainage = 0,
        } = tempAmount || {};
        const { retainage: retainagePercentage } = service || {};
        const res =
          (thisPeriod * retainagePercentage) / 100 + reducedAmount - retainage;
        const finalRes = res;
        // result = result + (finalRes > 0 ? finalRes : 0);
        // console.log(
        //   "totalAmount Hoist",
        //   tempAmount?.applicableRetainage || 0 + reducedAmount
        // );
        result += tempAmount?.applicableRetainage || 0 + reducedBalance;
      }
    }
  }
  // console.log("tempAm12", result);
  return result || 0;
};

const serviceAmountCalculator = ({ service }) => {
  let result = 0;
  const amounts = cloneDeep(service?.amounts || 0);
  for (const tempAmount of amounts) {
    // console.log("myTempAmount", tempAmount);
    const {
      retainage = 0,
      carriedRetainage = 0,
      // reducedAmount = 0,
      // reducedBalance = 0,
      retainagePercentage = 0,
      thisPeriod = 0,
      previousRetainage = 0,
    } = tempAmount || {};
    let reducedAmount =
      tempAmount?.reducedAmount < 0 ? 0 : tempAmount?.reducedAmount;
    let reducedBalance =
      tempAmount?.reducedBalance < 0 ? 0 : tempAmount?.reducedBalance;
    const res =
      (thisPeriod * retainagePercentage) / 100 + reducedAmount - retainage;
    const finalRes = res;
    // result = result + (finalRes > 0 ? finalRes : 0);
    // console.log(
    //   "totalAmount Service",
    //   tempAmount?.applicableRetainage || 0 + reducedBalance
    // );
    result += tempAmount?.applicableRetainage || 0 + reducedBalance;
  }
  return result || 0;
};

const validateNumber = (toCheck) => {
  if (!isNaN) {
    return Number(toCheck);
  } else {
    return 0;
  }
};
