import RichTextModalEditor from "../../../../../../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor"
import { useDispatchContext, useStateContext } from "../../../../../../../Context/Context"
import { updateNotesHandler } from "./noteEditorFunctions"
import "./NoteEditor.scss"

const NoteEditor = ({ value, serviceLabel, elevation, eventId }) => {
  const { fakeSOV, darkMode, isWritable } = useStateContext()
  const dispatchContext = useDispatchContext()

  const updateNotes = ({ newValue }) => {
    updateNotesHandler({
      dispatchContext,
      serviceLabel,
      elevation,
      newValue,
      fakeSOV,
      eventId,
    })
  }

  return (
    <div className="noteEditorContainerSOVHoist">
      <RichTextModalEditor
        {...{
          value,
          visibilityButtonText: "Notes",
          darkMode,
          title: "Notes",
          onConfirm: updateNotes,
          saveButtonDisability: !isWritable,
        }}
      />
    </div>
  )
}

export default NoteEditor
