import {
  applicationHoistHandler,
  compleatedAllHoistHandler,
} from "./complateAllHoistFunctions";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../Context/Context";
import "./CompleteAllHoist.scss";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Tooltip } from "antd";
import { message } from "antd";

const CompleteAllHoist = ({ elevationId, serviceId, estimationId }) => {
  const { isWritable, fakeApplication, darkMode } = useStateContext();
  const { isLoading } = useSelector((state) => state.applications);
  const dispatchContext = useDispatchContext();

  const { someChecked, allChecked, charged } =
    compleatedAllHoistHandler({
      fakeApplication,
      serviceId,
      estimationId,
      elevationId,
    }) || {};

  const completeAllHandler = () => {
    const checkboxToggle = !allChecked;

    //the wen calculated application
    const newApplications = applicationHoistHandler({
      fakeApplication,
      checkboxToggle,
      elevationId,
      serviceId,
      estimationId,
    });

    //this saves the new completed events on state(context)
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplications });
    dispatchContext({
      type: "SET_UNCHANGING_FAKE_APPLICATION",
      payload: newApplications,
    });
  };

  return (
    <div
      className={
        darkMode
          ? "completeAllHoistContainerDark"
          : "completeAllHoistContainerLight"
      }
    >
      <Tooltip title={charged ? "All events are charged" : ""}>
        <Checkbox
          onClick={() =>
            !isWritable && message.error("Please enable Edit Mode!")
          }
          className={!isWritable ? "checkBoxNotClickable" : ""}
          checked={(!isWritable ? false : someChecked) || allChecked}
          indeterminate={someChecked && !allChecked}
          //disabled={!!isLoading || !isWritable || charged}
          onChange={(e) => isWritable && completeAllHandler(e)}
        />
      </Tooltip>
      Complete all
    </div>
  );
};

export default CompleteAllHoist;
