export const safety = ({ tables, circleProgressPicker }) => {
  return {
    safetyApplicationStatus: () => ({
      percent: (
        (tables?.Safety?.filter(
          (e) => e?.safetyApplicationStatus === circleProgressPicker
        )?.length /
          tables?.Safety?.length) *
        100
      )?.toFixed(1),
    }),
  };
};
