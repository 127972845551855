import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { AgGridSearch, LoadableComp, AgGridSelectRows, ControlPanel } from "..";
import { onDateFilter, compareIncluding } from "../../utils";

import "./BasePage.scss";
import { companyDetails } from "../../../../helpers/constants";

const BasePage = ({
  currentUser,
  controlPanelFilters,
  loading,
  // onGridReady,
  propedRowData,
  dateColumnName,
  title,
  selectedRows,
  getRowStyle,
  className,
  initialPaginationPageSize,
  tableClassName,
  form,
  authenticatedUser,
  setRowObject,
  setChildVisible,
  columnDefs,
  defaultColDef,
  agGridProps,
  arrayOfDepend,
  clear,
  controlPanel,
  statuses,
  NewModal,
  children,
  companyLogos,
  ButtonComp,
  accessToken,
  rowClass,
  paginationAutoPageSize = false,
}) => {
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.showLoadingOverlay();
    setTimeout(() => {
      onDateFilter({
        gridApi: params.api,
        column: dateColumnName,
        dateArray: [dayjs().weekday(1), dayjs().weekday(8)],
      });
      params.api.hideOverlay();
    }, 500);
    setTimeout(() => {
      params.columnApi.applyColumnState({
        state: [{ colId: "speed", sort: "desc" }],
        defaultState: { sort: null },
      });
    }, 700);
    setTimeout(() => {
      params.columnApi.applyColumnState({
        state: [{ colId: "layover", sort: "asc" }],
        defaultState: { sort: null },
      });
    }, 1000);
  };

  const setNewRow = (newRow = []) => {
    var newItems = newRow;
    gridApi.applyTransaction({
      add: newItems,
    });
  };

  useEffect(() => {
    setRowData(propedRowData);
  }, [...arrayOfDepend]);
  const onRowSelect = (params) => {
    setRowObject(params);
    setChildVisible(true);
  };
  return (
    <div className={className}>
      <LoadableComp loading={loading}>
        {controlPanel === undefined ? (
          <ControlPanel
            controlPanelFilters={controlPanelFilters}
            gridColumnApi={gridColumnApi}
            title={title}
            statuses={statuses}
            form={form}
            gridApi={gridApi}
            rowData={rowData}
            dateColumnName={dateColumnName}
            clear={clear}
            setVisible={setVisible}
            companyLogo={
              companyLogos?.find(
                ({ fileName }) =>
                  compareIncluding(fileName, "black") &&
                  compareIncluding(
                    companyDetails.companyName,
                    fileName.split(" ")[0]
                  )
              )?.base64
            }
          />
        ) : null}

        {ButtonComp !== undefined ? (
          <div>
            <ButtonComp gridApi={gridApi} />
          </div>
        ) : null}

        <div
          className={
            !tableClassName.includes("ag-theme-alpine")
              ? `ag-theme-alpine ${tableClassName}`
              : tableClassName
          }
        >
          <div>
            <AgGridReact
              onSortChanged={(e) => e.api.refreshCells()}
              onFilterChanged={(e) => e.api.refreshCells()}
              getRowClass={rowClass}
              getRowStyle={getRowStyle}
              animateRows={true}
              onRowDoubleClicked={
                setRowObject
                  ? (params) => onRowSelect(params.data)
                  : selectedRows
                  ? (e) => {
                      return selectedRows?.setSelectedRows(
                        selectedRows.selectedRows.includes(e.data)
                          ? selectedRows.selectedRows.filter(
                              (item) => item.location !== e.data.location
                            )
                          : [...selectedRows.selectedRows, e.data]
                      );
                    }
                  : null
              }
              onRowClicked={
                selectedRows?.setSingleLocation !== undefined
                  ? (e) => selectedRows?.setSingleLocation(e.data)
                  : null
              }
              onGridReady={onGridReady}
              rowData={rowData}
              rowSelection="multiple"
              pagination={true}
              paginationPageSize={15}
              pinnedTopRowData={selectedRows ? selectedRows.selectedRows : null}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              suppressDragLeaveHidesColumns={true}
              paginationAutoPageSize={paginationAutoPageSize}
              {...agGridProps}
            />
          </div>
          {/* <AgGridSearch form={form} gridApi={gridApi} />
          <AgGridSelectRows
            gridApi={gridApi}
            initialValue={initialPaginationPageSize}
          /> */}
        </div>
        {NewModal({
          visible,
          setVisible,
          accessToken,
          authenticatedUser,
          setNewRow,
          currentUser,
        })}
        {children}
      </LoadableComp>
    </div>
  );
};
export default BasePage;
