import { compareIncludingAccurate } from "../../../utils/compareIncluding";
import { docValidPermitKeys, permitTypesMapping } from "../dobTabData";

export const isPermitSavedInPrj = ({
  documentation = [],
  permit = {},
  selectedCategory,
}) =>
  !!documentation.find(({ expirationDate: docExpDate, DOBWorkName }) => {
    const validPermitKeys = docValidPermitKeys[selectedCategory];
    const {
      [validPermitKeys?.permitType]: permitType = "",
      [validPermitKeys?.expirationDate]: expirationDate,
    } = permit;

    const formattedType = permitTypesMapping[permitType] || permitType;

    return (
      !!DOBWorkName &&
      compareIncludingAccurate(formattedType, DOBWorkName) &&
      docExpDate === expirationDate
    );
  });
