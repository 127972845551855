import { useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import {
  DarkHandle,
  LayoutCard,
} from "src/components/SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportsGridLayout/components";

import "./PayrollLiveCharts.scss";
import "react-resizable/css/styles.css";
import "react-grid-layout/css/styles.css";

interface Props {
  layout: Array<any>;
  setLayout: Dispatch<SetStateAction<Array<any>>>;
}

const breakpoints = {
  lg: 1200,
  md: 996,
  sm: 768,
  xs: 480,
  xxs: 0,
};

const LayoutBody = WidthProvider(Responsive);

function PayrollLiveCharts(props: Props) {
  const { setLayout, layout } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(calcBreakpoint());

  function calcBreakpoint() {
    let result = "lg";
    let width = window.innerWidth;

    let br = Object.keys(breakpoints).sort(
      (a, b) => breakpoints[a] - breakpoints[b]
    );

    for (const b of br) {
      if (breakpoints[b] >= width) {
        result = b;
        break;
      }
    }

    return result;
  }

  function updateChartsLayout(e) {
    if (JSON.stringify(layout) !== JSON.stringify(e)) {
      let newLayout = [];
      for (const el of e) {
        let existingIndex = layout?.findIndex(({ i }) => i === el.i);
        if (existingIndex > -1) {
          newLayout.push({
            ...layout[existingIndex],
            ...el,
          });
        } else {
          newLayout.push(el);
        }
      }
      // if (!changes) {
      // const initialCardsPosition = initialLayout.cards.map((card) => {
      //   return {
      //     h: card?.h,
      //     i: card?.i,
      //     minH: card?.minH,
      //     minW: card?.minW,
      //     moved: card?.moved,
      //     static: card?.static,
      //     w: card?.w,
      //     x: card?.x,
      //     y: card?.y,
      //   };
      // });

      // const eventCardsPosition = e.map((card) => {
      //   return {
      //     h: card?.h,
      //     i: card?.i,
      //     minH: card?.minH,
      //     minW: card?.minW,
      //     moved: card?.moved,
      //     static: card?.static,
      //     w: card?.w,
      //     x: card?.x,
      //     y: card?.y,
      //   };
      // });

      // if (
      //   JSON.stringify(initialCardsPosition) !==
      //   JSON.stringify(eventCardsPosition)
      // ) {
      // setChanges(true);
      // }
      // }
      setLayout(newLayout);
    }
  }

  function updateCardInLayout(card) {
    setLayout((prev) => {
      const newLayout = prev.map((el) => {
        return el.cardKey === card.cardKey
          ? { ...el, chartData: card.chartData }
          : el;
      });

      return newLayout;
    });
    // if (changes === false) {
    //   setChanges(true);
    // }
  }

  function onRemove(cardKey) {
    setLayout((prev) => prev.filter((el) => el.i !== cardKey));
    // setChanges(true);
  }

  const cards = useMemo(() => {
    return layout?.map((cardData) => {
      const { static: s, i, chartData, ...rest } = cardData;
      // const filteredCard = individualFilters[i];

      const updatedChartData = {
        ...chartData,
        // ...(filteredCard?.chartData || {}),
      };

      return (
        <LayoutCard
          key={i}
          title={i}
          cardKey={i}
          // onHide={onHide}
          // loading={loading}
          onRemove={onRemove}
          cardRemovable={true}
          // filter={onCardFilter}
          chartData={updatedChartData}
          className={s ? "static" : ""}
          // headerComponents={headerComponents}
          updateCardInLayout={updateCardInLayout}
          // clearFilter={filteredCard ? () => clearCardFilter(i) : null}
          {...rest}
        />
      );
    });
  }, [
    layout,
    // JSON.stringify(individualFilters),
    // JSON.stringify(analyticsDateRange),
  ]);

  return (
    <section className="analytics-body">
      <LayoutBody
        {...{
          layouts: {
            [currentBreakpoint]: layout,
          },
          id: "analytics-charts-layout",
          allowOverlap: false,
          breakpoints,
          cols: { lg: 12, md: 12, sm: 8, xs: 4, xxs: 4 },
          rowHeight: 30,
          isBounded: false,
          margin: [10, 10],
          isDraggable: true,
          isResizable: true,
          currentBreakpoint,
          resizeHandles: ["sw", "se"],
          useCSSTransforms: true,
          draggableHandle: ".card-header",
          onBreakpointChange: () => {
            const newBreakpoint = calcBreakpoint();
            setCurrentBreakpoint(newBreakpoint);
          },
          onLayoutChange: updateChartsLayout,
          resizeHandle: darkMode
            ? (props, ref) => {
                return <DarkHandle {...props} innerRef={ref} />;
              }
            : undefined,
        }}
      >
        {cards}
      </LayoutBody>
    </section>
  );
}

export default PayrollLiveCharts;
