import "./NotesDrawer.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Tooltip, Tour, message } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { notesTourSteps } from "../utils";
import { NotesComponent } from "../components/NotesComponent";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { SmallLoadableComp } from "../../../Sidebars/components";
import { useQuery } from "../../../../hooks";

const NotesDrawer = ({
  visible,
  openModal,
  noteModalName,
  topicCategory,
  rowId,
  rowNotes,
  setRowNotes,
  customLinkTo,
  teamsConfiguration,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [tourOpen, setTourOpen] = useState(false);

  const commentId = useQuery().get("commentId");
  const category = useQuery().get("category");

  const noteAccesses = userConfiguration?.routeConfig?.find(
    ({ title }) => title === "Notes/View"
  )?.children;

  const tourSteps = notesTourSteps({
    programFields,
    isDarkMode,
    rowNotes,
  });

  return (
    <Drawer
      rootClassName={`notes_drawer ${isDarkMode ? "notes-modal-dark" : ""}`}
      onClose={() => openModal(false)}
      open={visible}
      closeIcon={<XIcon style={{ fill: "#fff" }} />}
      placement="right"
      title={
        <div className="headerContent">
          <Tooltip title="Guide">
            <QuestionCircleFilled
              data-testid="tour-open-testid"
              style={{ fontSize: 23, color: "#1264A3", cursor: "pointer" }}
              onClick={() => {
                Array.isArray(tourSteps) && tourSteps.length > 0
                  ? setTourOpen(true)
                  : message.info("No guide available for this form");
              }}
              id="tourButton"
            />
          </Tooltip>
          <Tooltip title={noteModalName}>
            <span
              className="notesModalName"
              style={{ color: "#FFFFFF" }}
            >{`${noteModalName}`}</span>
          </Tooltip>
        </div>
      }
    >
      <SmallLoadableComp loading={!Array.isArray(rowNotes)}>
        <NotesComponent
          {...{
            hasWriteAccess: noteAccesses?.find(
              ({ title }) => title === topicCategory
            )?.write,
            notes: rowNotes || [],
            setRowNotes,
            noteModalName,
            rowId,
            topicCategory,
            defaultCategory: category || location?.state?.category,
            defaultCommentId: commentId || location?.state?.commentId,
            customLinkTo,
            teamsConfiguration,
          }}
        />
      </SmallLoadableComp>
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
    </Drawer>
  );
};

export default NotesDrawer;
