import MondayButton from "../MondayButton/MondayButton";
import { XIcon } from "../../SidebarPages/Communication/assets";
import { WordIcon } from "../../SidebarPages/BasePage/src";
import { PdfDocIcon } from "../../../assets";

function ExportPreviewFooter({
  onCancel = () => {},
  onPrint = () => {},
  resetFilters = () => {},
  filters = {},
  exportWord = () => {},
}) {
  return (
    <>
      <MondayButton
        Icon={<XIcon />}
        onClick={onCancel}
        className="mondayButtonRed"
      >
        Cancel
      </MondayButton>
      <div className="export-container">
        {Object.keys(filters).length > 0 && (
          <MondayButton
            Icon={<XIcon />}
            onClick={resetFilters}
            className="mondayButtonRed"
          >
            Reset Filters
          </MondayButton>
        )}
        <MondayButton
          Icon={<WordIcon />}
          onClick={exportWord}
          className="mondayButtonBlue"
        >
          Export Word
        </MondayButton>

        <MondayButton
          Icon={<PdfDocIcon />}
          onClick={onPrint}
          className="mondayButtonBlue"
        >
          Export PDF
        </MondayButton>
      </div>
    </>
  );
}

export default ExportPreviewFooter;
