import {
  getDisabledTooltipContent,
  headerDateInputHandler,
} from "./HeaderDateInputFunctions";
import {
  CalendarIcon,
  DownloadIcon,
} from "../../../../../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../../../../../hooks";
import "./HeaderDateInput.scss";

// import { useDispatchContext, useStateContext } from "../../../../../../../Context/Context"

import { DatePicker, Tooltip, InputNumber, message } from "antd";
import { useEffect, useState } from "react";
import { getWeeksDiff } from "../../../RentalBody/AgGrid/columnDefs";
import moment from "moment";
import { MondayButton } from "../../../../../../../../../../../../commonComponents";
import dayjs from "dayjs";
import { forceToNumber } from "../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { ScheduleOfValuesIcon } from "../../../../../../../../../../../../../icons/Settings";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { DeleteFilled, ImportOutlined } from "@ant-design/icons";
import CustomProgressBar from "../../../../../../../../../../../../commonComponents/CustomProgress/CustomProgressBar";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import { parseInTz } from "../../../../../../../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
// dayjs.tz.setDefault("America/New_York");

const { RangePicker } = DatePicker;
const HeaderDateInput = ({ elevationData, canRedo }) => {
  // const { isWritable, fakeRentals, selectedService } = useStateContext()

  const [isWritable] = useRedux("rentalsIsWritable");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const [noWeeksIndic, setNoOfWeeksIndic] = useState({
    value: 0,
    interval: [],
  });
  const [progressShortcut, setProgressShortcut] = useState(0);

  // const dispatchContext = useDispatchContext()

  //this is called when we change the date input
  const handleDateInput = (date) => {
    setNoOfWeeksIndic((prev) => ({
      interval: [...date],
      value: getWeeksDiff(date[1], date[0]),
    }));
    return headerDateInputHandler({
      setUnchangingFakeRentals,
      selectedService,
      setFakeRentals,
      elevationData,
      fakeRentals,
      date,
    });
  };
  //here we extract the reason why the input is disabled
  const disableValue = getDisabledTooltipContent({
    selectedService,
    elevationData,
    fakeRentals,
    isWritable,
  });

  const onWeekGen = (data) => {
    // console.log("data", data, "float", parseFloat(data));
    let first = parseInTz(noWeeksIndic.interval[0]) || parseInTz();
    let second = parseInTz(first).add(data * 7 - 1, "days");

    handleDateInput([first, second]);
  };

  const onRetreiveElevation = async () => {
    console.log("onRetreiveElevation", { elevationData, fakeRentals });
    await API.get("scheduling", `/scheduling/${fakeRentals?.scheduleId}`)
      .then((res) => {
        let myFind = firstAndLastDateOfAnElevation(
          res?.toBeScheduled,
          elevationData?.elevationId
        );
        console.log("myFind", { myFind, res });

        if (!myFind?.firstDate || !myFind?.lastDate) {
          Swal.fire({
            title: "Error",
            text: "There are no Schedule dates for this Elevation > Project!",
            icon: "error",
          });

          return;
        }
        handleDateInput([
          parseInTz(myFind?.firstDate),
          parseInTz(myFind?.lastDate),
        ]);
      })
      .catch((err) =>
        Swal.fire({
          title: "Error",
          text: "There is no Schedule for this Project!",
          icon: "error",
        })
      )
      .finally(() => {
        message.destroy();
      });
  };

  const onProgressElevationShortcut = (data) => {
    setProgressShortcut(data);
    let myProgress = forceToNumber(data) / 100;
    const temp = { ...fakeRentals };
    const updatedServices = temp.services?.map((service) => {
      if (service.label === selectedService) {
        console.log("beforeService1", service);
        const updatedServiceOptions = service.serviceOptions.map((elev) => {
          if (
            elev.elevationLabel === elevationData.elevationLabel &&
            elev.elevationId === elevationData.elevationId
          ) {
            const updatedItems = elev?.items?.map((pli) => {
              if (pli?.locked) return pli;
              const numberOfDays =
                pli?.rentalDetails?.details?.flatMap(({ days }) => days)
                  ?.length ?? 0;
              let weeklyAmount =
                elevationData?.elevationLabel !== "Hoist" &&
                elevationData?.elevationLabel !== "BetaMax Hoist"
                  ? // ? (pli?.rent / 4) * totalPercentage * (rentPercentage / 100) * 10
                    (pli?.rent / 4) * myProgress
                  : (pli?.rentalsTotal / 4) * myProgress;
              weeklyAmount = forceToNumber(weeklyAmount);
              const dailyAmount = weeklyAmount / 7;
              return {
                ...pli,
                //here we set the applied amount for this pli (calculated daily amount)* (the number of days in this pli)
                // appliedAmount: dailyAmount * numberOfDays,
                appliedAmount: dailyAmount * numberOfDays,

                totalProgress: myProgress,
                maxWeeklyAmount: weeklyAmount,
                rentalDetails: {
                  ...pli?.rentalDetails,
                  details: pli?.rentalDetails?.details?.map((weeksData) => {
                    //here we find how many days are in this week
                    const noOfDaysInThisWeek = weeksData?.days?.length;

                    return {
                      ...weeksData,
                      //here we set the new weekly amount to the (calculated dailyAmount) * (the number of days that that week) contains
                      weeklyAmount: dailyAmount * noOfDaysInThisWeek,
                      days: weeksData?.days?.map((daysData) => ({
                        ...daysData,
                        //here we set the new daily amount to the calculated dailyAmount
                        dailyAmount,
                      })),
                    };
                  }),
                  weeklyAmount,
                },
              };

              // return {
              //   ...item,
              //   rentalDetails: {
              //     details: [],
              //     weeklyAmount: item?.rentalDetails?.weeklyAmount,
              //     deleted: true,
              //   },
              // };
            });
            return {
              ...elev,
              items: updatedItems,
            };
          }
          return elev;
        });
        console.log("beforeService2", service);

        return {
          ...service,
          serviceOptions: updatedServiceOptions,
        };
      }
      return service;
    });
    temp.services = updatedServices;

    setFakeRentals(temp);
    setUnchangingFakeRentals(temp);
  };

  const deleteAmountsElevation = (elevation) => {
    // setUnchangingFakeRentals,
    // selectedService,
    // setFakeRentals,
    // elevationData,
    // fakeRentals,
    const temp = { ...fakeRentals };
    const updatedServices = temp.services?.map((service) => {
      if (service.label === selectedService) {
        const updatedServiceOptions = service.serviceOptions.map((elev) => {
          if (
            elev.elevationLabel === elevationData.elevationLabel &&
            elev.elevationId === elevationData.elevationId
          ) {
            const updatedItems = elev?.items?.map((item) => {
              return {
                ...item,
                rentalDetails: {
                  details: [],
                  weeklyAmount: item?.rentalDetails?.weeklyAmount,
                  deleted: true,
                },
              };
            });
            return {
              ...elev,
              items: updatedItems,
            };
          }
          return elev;
        });
        return {
          ...service,
          serviceOptions: updatedServiceOptions,
        };
      }
      return service;
    });
    temp.services = updatedServices;

    setFakeRentals(temp);
    setUnchangingFakeRentals(temp);
  };

  useEffect(() => {
    if (canRedo)
      setNoOfWeeksIndic({
        value: 0,
        interval: [],
      });
  }, [canRedo]);

  return (
    <div className="HeaderDateInput">
      <Tooltip title={disableValue}>
        <RangePicker
          allowClear={false}
          value={noWeeksIndic.interval}
          onChange={(date) => handleDateInput(date)}
          format="MM/DD/YYYY"
          presets={getRangePresets()}
          popupClassName="filter-by-date-picker-dropdown"
          className="datePickerInputHeaderRentals"
          suffixIcon={<CalendarIcon />}
          disabled={!!disableValue}
        />
      </Tooltip>
      <Tooltip title={disableValue}>
        <InputNumber
          style={{ width: "80px", borderRadius: "5px" }}
          value={noWeeksIndic?.value}
          onChange={(e) => {
            if (!e) return;
            return onWeekGen(parseFloat(e));
          }}
          type="number"
          min={1}
          max={52}
          disabled={!!disableValue}
        />
      </Tooltip>

      <Tooltip title={disableValue}>
        <InputNumber
          addonBefore="Progress"
          style={{ width: "140px", borderRadius: "5px" }}
          defaultValue={progressShortcut}
          // onChange={(e) => {
          //   console.log("onChange", e);
          //   onProgressElevationShortcut(e);
          // }}
          type="number"
          onBlur={(el) => {
            onProgressElevationShortcut(el?.target?.value);
          }}
          disabled={!!disableValue}
        />{" "}
      </Tooltip>
      <Tooltip title={"Reset all amounts for this elevation to 0"}>
        <MondayButton
          className="mondayButtonBlue"
          hasIcon={false}
          onClick={deleteAmountsElevation}
        >
          <DeleteFilled />
        </MondayButton>
      </Tooltip>
      {/* <div
        style={{
          height: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <strong style={{ color: "white" }}>From Scheduling:</strong>
        <CustomProgressBar
          {...{
            progressDone: 33,
          }}
        />
      </div> */}
      {/* <MondayButton
        className="mondayButtonYellow"
        // hasIcon={false}
        onClick={() => {
          message.loading("Loading data from Scheduling...");
          onRetreiveElevation();
        }}
        Icon={<ImportOutlined />}
      >
        Retrieve Dates from Sched.
      </MondayButton> */}
    </div>
  );
};

export default HeaderDateInput;
export const firstAndLastDateOfAnElevation = (
  services = {},
  elevationId = ""
) => {
  let toReturn = {};
  Object.values(services)
    ?.flat()
    ?.forEach((service) => {
      const findElevation = service?.serviceOptions[0]?.find(
        (el) => el?.elevationId === elevationId
      );
      if (findElevation) {
        const plis = findElevation?.items;
        const pliDays = plis
          ?.flatMap((a) => Object.values(a?.usedScheduleDays)?.flat())
          ?.sort((a, b) => parseInTz(a?.startDate) - parseInTz(b?.startDate));
        toReturn = {
          firstDate: pliDays[0]?.startDate,
          lastDate: pliDays?.[pliDays?.length - 1]?.startDate,
        };
      }
    });
  return toReturn;
};
