import { forwardRef, useRef, useImperativeHandle, useContext } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";

import DegModalContext from "../DegModalContext";
import { DropdownIcon } from "../../../../../../../../icons";
import findShiftEntries from "./findShiftEntries";

const ServiceGridSelect = forwardRef(
  ({ value, stopEditing, api, data }, ref) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const {
      crews,
      rowData,
      jobsites,
      analytics,
      setRowData,
      addEntryAction,
      jobsiteServices,
      defaultServicesPerProject,
    } = useContext(DegModalContext);

    const services = useRef(value || []);

    let entriesOfShift = data?.entryId
      ? findShiftEntries({
          analytics,
          jobsites,
          crews,
          rowData,
          entryId: data?.entryId,
        })
      : rowData.filter(({ entryId }) => data?.entries.includes(entryId));

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue() {
            return services.current;
          },
          isCancelBeforeStart() {
            return false;
          },
        };
      },
      []
    );

    function onSelect(event) {
      services.current = services.current?.concat(event);
      if (data?.shiftId) {
        const currentRow = Object.assign(data, {
          sow: services.current,
        });
        api.applyTransaction({
          update: [currentRow],
        });
        for (let entry of entriesOfShift) {
          Object.assign(entry, {
            sow: services.current,
          });
        }
        addEntryAction({
          type: "edit",
          entry: entriesOfShift,
        });
      } else {
        for (let entry of entriesOfShift) {
          Object.assign(entry, {
            sow: services.current,
          });
        }
        api.applyTransaction({
          update: [entriesOfShift],
        });
        addEntryAction({
          type: "edit",
          entry: entriesOfShift,
        });
      }
    }

    function onDeselect(e) {
      services.current = services.current?.filter((service) => service !== e);
      if (data?.shiftId) {
        const currentRow = Object.assign(data, {
          sow: services.current,
        });
        api.applyTransaction({
          update: [currentRow],
        });
      } else {
        for (let entry of entriesOfShift) {
          Object.assign(entry, {
            sow: services.current,
          });
        }
        api.applyTransaction({
          update: [entriesOfShift],
        });
      }
    }

    const serviceOptions =
      jobsiteServices?.[data?.jobsiteId]?.find?.(
        ({ dateRange }) =>
          dateRange[0] <=
            (data?.entryId ? data?.punchTimeStamp : data?.firstClockIn) &&
          (data?.entryId ? data?.punchTimeStamp : data?.firstClockIn) <=
            dateRange[1]
      )?.scheduleServices ||
      defaultServicesPerProject?.[
        !!data?.jobsiteId ? data?.jobsiteId : data?.jobSiteId
      ] ||
      [];

    return (
      <Select
        {...{
          className: `gridDropdownSelect ${
            isDarkMode && "gridSelectDark"
          } servicesSelect`,
          size: "large",
          onDropdownVisibleChange: (dropdownVisible) => {
            if (!dropdownVisible) {
              if (data?.shiftId) {
                const updatedRowData = rowData.map((activity) => {
                  if (data?.entries.includes(activity?.entryId)) {
                    return Object.assign(activity, { sow: services.current });
                  } else {
                    return activity;
                  }
                });
                setRowData(updatedRowData);
              }
              stopEditing();
            }
          },
          suffixIcon: <DropdownIcon />,
          open: serviceOptions?.length,
          defaultOpen: true,
          mode: "multiple",
          popupClassName: isDarkMode && "darkDropDown",
          onDeselect,
          onSelect,
          getPopupContainer: () => document.body,
          defaultValue: value || [],
          style: {
            width: "100%",
            height: "100%",
            borderRadius: "0px",
          },
          options: serviceOptions.map((service, key) => ({
            key,
            label: service,
            value: service,
          })),
        }}
      />
    );
  }
);

export default ServiceGridSelect;
