import {
  resetApplication,
  updateApplication,
} from "../../../../../../../../../../actions/applications";
import { totalCalculator as totalitiesCalc } from "../ControlPanel/controlPanelFunctions";
import _ from "lodash";
import { thisRetainageCalculator } from "../ControlPanel/utils";
import { queryParams } from "../../../../../../../../../../utils/queryParams";
import { checkIfServiceIsHoist } from "../../../../../../../../Estimations/DataEntryGrid/models/Service";

//this function handles dark mode change
export const darkModeHandler = ({ darkMode, dispatchContext }) => {
  // with this we save darkMode to context
  dispatchContext({ type: "SET_DARK_MODE", payload: !darkMode });
};

//this function redirect user to sovView if the user have made no changes
export const exitApplicationViewHandler = ({
  setConfirmModalVisibility,
  fakeApplication,
  application,
  dispatch,
  navigate,
  location,
  applications,
  isNextStep,
  closeModal,
}) => {
  const totalities = totalitiesCalc(application, applications);
  const applicationWithTotalPrice = {
    ...fakeApplication,
    services: fakeApplication?.services?.map((el) => {
      if (checkIfServiceIsHoist(el)) {
        return {
          ...el,
          serviceOptions: el?.serviceOptions?.map((sOptions) => {
            return sOptions.map((sOption) => {
              return {
                ...sOption,
                amounts: sOption?.amounts?.map((amount) => {
                  const {
                    thisPeriod = 0,
                    taxAmount = 0,
                    retainage = 0,
                    reducedAmount = 0,
                    reducedBalance = 0,
                    keptRetainage = 0,
                  } = amount || {};
                  const paymentDue =
                    Number(thisPeriod) -
                    // Number(taxAmount) -
                    Number(keptRetainage) +
                    Number(reducedBalance);
                  // console.log("myPaymentHoist", {
                  //   paymentDue,
                  //   thisPeriod,
                  //   keptRetainage,
                  //   reducedBalance,
                  //   amount,
                  // });
                  return {
                    ...amount,
                    paymentDue,
                    applicableRetainage:
                      (thisPeriod * el?.retainage) / 100 || 0,
                  };
                }),
              };
            });
          }),
        };
      } else {
        return {
          ...el,
          amounts: el?.amounts?.map((amount) => {
            ///
            const {
              thisPeriod = 0,
              taxAmount = 0,
              retainage = 0,
              reducedAmount = 0,
              totalCompleated = 0,
              retainagePercentage = 0,
              percentageCompleated = 0,
              previousRetainage = 0,
              keptRetainage = 0,
              reducedBalance = 0,
            } = amount || {};
            // const res =
            //   percentageCompleated > 0
            //     ? (thisPeriod * retainagePercentage) / 100 +
            //       (reducedAmount > 0 ? previousRetainage - reducedAmount : 0)
            //     : 0;
            // const finalRes =
            //   thisPeriod > 0 || reducedAmount > 0 ? res || 0 : 0;
            // const paymentDue =
            //   Number(thisPeriod) - Number(finalRes) > 0
            //     ? Number(thisPeriod) - Number(finalRes)
            //     : Number(finalRes);
            const paymentDue =
              Number(thisPeriod) -
              // Number(taxAmount) -
              Number(keptRetainage) +
              Number(reducedBalance);
            // console.log("this is finalRes", {
            //   finalRes,
            //   res,
            //   thisPeriod,
            //   paymentDue,
            //   reducedAmount,
            // });

            // Number(totalCompleated) -
            // // Number(taxAmount) -
            // Number(retainage) +
            // Number(reducedAmount);
            // const totalCompleted = (amount * percentageCompleated) / 100;
            // const fullAm = (totalCompleted * retainagePercentage) / 100;
            return {
              ...amount,
              paymentDue,
              applicableRetainage:
                (thisPeriod * amount?.retainagePercentage) / 100 || 0,
            };
          }),
        };
      }
    }),
    totalPrice: +totalCalculator({ fakeApplication })?.toFixed(2),
    // totalities: {
    //   ...(fakeApplication?.totalities || {}),
    //   ...(totalities || {}),
    // },
  };
  applicationWithTotalPrice.totalities.thisRetainage = thisRetainageCalculator({
    fakeApplication: applicationWithTotalPrice,
    applications,
  });
  //if the application that we can update is the same as the application saved in db send the user directly to accounting
  if (_.isEqual(applicationWithTotalPrice, application)) {
    if (isNextStep) {
      closeModal();
      dispatch(resetApplication());
      return;
    }

    if (location.state?.goBackTo === "accountingGrid") {
      navigate(-1);
    } else {
      navigate(
        //the first parameter is the link
        `/projects/${fakeApplication.projectId}?${queryParams({
          tab: "Accounting",
          subTab: "Applications",
        })}`,
        //those parameters are used to send it directly to the rentals tab
        {
          fromAccounting: true,
          accountingTab: "Applications",
          tabPosition: "accounting",
        }
      );
    }
    dispatch(resetApplication());
  } else {
    setConfirmModalVisibility(true);
  }
};

//this function discard the changes that the user have made in sov
export const discardChangesHandler = ({
  fakeApplication,
  navigate,
  isNextStep,
  closeModal,
}) => {
  // the discard button send the use directly to the accounting tab without saving the changes
  if (isNextStep) {
    closeModal();
    return;
  }

  navigate(
    //the first parameter is the link
    `/projects/${fakeApplication.projectId}?${queryParams({
      tab: "Accounting",
      subTab: "Applications",
    })}`,
    //those parameters are used to send it directly to the rentals tab
    {
      fromAccounting: true,
      accountingTab: "Applications",
      tabPosition: "accounting",
    }
  );
};

//this saves all the changes the user has made
export const saveChangesHandler = ({
  fakeApplication,
  applicationId,
  dispatch,
  navigate,
  applications,
  isNextStep,
  closeModal,
  saveAddedLogs,
}) => {
  // new application with total price
  const applicationWithTotalPrice = {
    ...fakeApplication,
    totalPrice: totalCalculator({ fakeApplication }),
  };

  //this function saves changes to db and to redux
  dispatch(
    updateApplication(
      applicationId,
      applicationWithTotalPrice,
      applications,
      saveAddedLogs
    )
  );
  //than after the changes are saved it send the user to the accounting tab
  dispatch(resetApplication());
  if (isNextStep) {
    closeModal();
    return;
  }
  navigate(
    //the first parameter is the link
    `/projects/${fakeApplication.projectId}?${queryParams({
      tab: "Accounting",
      subTab: "Applications",
    })}`,
    //those parameters are used to send it directly to the rentals tab
    {
      state: {
        fromAccounting: true,
        accountingTab: "Applications",
        tabPosition: "accounting",
        isNextStepOpen: { visible: true, subTab: "Applications" },
        savedRecord: fakeApplication,
      },
    }
  );
};

//here we extract all this periods from fakeApplications and adds them together
export const totalCalculator = ({ fakeApplication }) => {
  const { services, includedRentals } = fakeApplication || {};

  const totalApplied = [
    ...services?.flatMap(({ label, serviceOptions, amounts }, idx, arr) =>
      checkIfServiceIsHoist(arr[idx])
        ? serviceOptions[0]?.flatMap(({ amounts }) =>
            amounts?.map(({ thisPeriod }) => thisPeriod)
          )
        : amounts?.map(({ thisPeriod }) => thisPeriod)
    ),
    ...includedRentals?.flatMap(({ services }) =>
      services?.map(
        ({ appliedAmount = 0, serviceLevelCredit = 0, taxRate = 0 }) =>
          (appliedAmount =
            appliedAmount + (serviceLevelCredit - serviceLevelCredit * taxRate))
      )
    ),
  ]?.reduce((acc, curr) => acc + curr, 0);

  return totalApplied;
};

// fakeApplication?.services
// ?.flatMap(({ amounts, serviceOptions, label }) =>
//   label === "Hoist"
//     ? serviceOptions?.flatMap((elevationData) =>
//         elevationData?.flatMap(({ amounts }) => amounts?.map(({ thisPeriod }) => thisPeriod))
//       )
//     : amounts?.map(({ thisPeriod }) => thisPeriod)
// )
