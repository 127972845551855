import React, { useEffect, useState } from "react";
import { Modal, Button, message, Tooltip, Badge } from "antd";
import { RichText } from "../../../../../../DynamicView/FormItemComponents";
import "./AddServiceNote.scss";
import { CloseIconModalHeader } from "../../../../../../../../assets";
import { MondayButton } from "../../../../../../../commonComponents";
import { SaveIcon } from "../../../../../../BasePage/src";
import hasContent from "../../../../functional/hasContent";
import { CloseCircleFilled } from "@ant-design/icons";
function AddServiceNote({
  service,
  addNoteForService,
  isWritable,
  themeType,
  customNoteLabel = "Note",
}) {
  const [value, setValue] = useState("");
  const [visible, setVisible] = useState(false);
  console.log("service", service);

  return (
    <>
      <Badge count={hasContent(service?.note) ? 1 : 0}>
        <Button onClick={() => setVisible(true)}>
          {service?.note ? `Edit ${customNoteLabel}` : `Add ${customNoteLabel}`}
        </Button>
      </Badge>
      {visible && (
        <Modal
          title={`Add Notes for ${service?.label}`}
          wrapClassName={
            themeType === "dark"
              ? "add-notes-for-service-modal__dark"
              : "add-notes-for-service-modal"
          }
          open={visible}
          destroyOnClose={true}
          footer={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseCircleFilled />}
                onClick={() => setVisible(false)}
              >
                Close
              </MondayButton>
              <MondayButton
                Icon={<SaveIcon />}
                disabled={!isWritable}
                onClick={() => {
                  if (isWritable) {
                    console.log("value", value);
                    addNoteForService(value);
                    setVisible(false);
                  } else {
                    message.error("You need writing permissions to add notes");
                  }
                }}
              >
                Save Changes
              </MondayButton>
            </div>
          }
          closable={false}
          // closeIcon={themeType !== "dark" ? <CloseIconModalHeader /> : null}
        >
          <RichText
            {...{ initialValue: service?.note ?? null, value, setValue }}
          />
        </Modal>
      )}
    </>
  );
}

export default AddServiceNote;
