import { ExportPreview } from "../../../../commonComponents";
import AddRemoveFields from "../../SafetyModal/components/SafetyExportPreview/components/AddRemoveFields";
import SortReportFields from "../../SafetyModal/components/SafetyExportPreview/components/SortReportFields";
import {
  convertSafetyReport,
  initialExportData,
  SAFETY_REPORT_STYLES,
  safetyExportApplyFilters,
} from "../../SafetyModal/components/SafetyExportPreview/helpers";

function SafetyInspectionExportPreview({
  showPdfPreview,
  setShowPdfPreview,
  rowObject,
  userConfiguration,
  accessToken,
  inspectionCategory,
  fieldsJSON,
  selectedTeam,
  crewMembers,
  foremen,
  fleet,
  drivers,
}) {
  return showPdfPreview ? (
    <ExportPreview
      {...{
        open: showPdfPreview,
        onCancel: () => setShowPdfPreview(false),
        modalTitle: "Incident Report",
        documentTitle: rowObject?.inspectionName,
        getInitialData: async () => {
          return await initialExportData({
            modalType: "safetyInspection",
            accessToken,
            userConfiguration,
            rowObject,
            safetyCategory: inspectionCategory,
            selectedTeam,
            safetyFormData: { crewMembers, foremen, fleet, drivers },
            formFields: fieldsJSON,
          });
        },
        keyName: "safetyInspection " + inspectionCategory,
        asyncInitialData: true,
        applyFilters: (filters, data) =>
          safetyExportApplyFilters(filters, data),
        convertData: (data) => convertSafetyReport(data),
        tabs: [
          {
            key: "add/remove",
            label: "Add/Remove",
            component: AddRemoveFields,
          },
          {
            key: "sort",
            label: "Sort",
            component: SortReportFields,
          },
        ],
        customStyles: SAFETY_REPORT_STYLES,
        defaultHeaderSection: [
          {
            text: {
              paragraphs: [
                {
                  text: rowObject?.inspectionName + " Report",
                  class: "safety-title",
                  bold: true,
                  header: true,
                },
              ],
            },
          },
          {
            text: {
              paragraphs: [
                {
                  text: "Date: " + new Date().toLocaleDateString(),
                  class: "safety-section-header no-margin",
                  bold: true,
                },
              ],
            },
          },
        ],
      }}
    />
  ) : null;
}

export default SafetyInspectionExportPreview;
