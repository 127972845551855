export const rentals = (
  programFields,
  updatedDynamicStates,
  rentalsData,
  IdHandler
) => {
  const data = updatedDynamicStates[IdHandler];
  const rentalStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Rentals.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});
  return {
    rentalStatus: () => ({
      title: "Rentals - Category From",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Rentals",
        field: "rentalStatus",
        labels: rentalStatus?.statusName,
        datasets: [
          {
            label: "Rentals",
            data: rentalStatus?.statusName?.map(
              (status) =>
                rentalsData?.filter((a) => a?.rentalStatus === status)?.length
            ),
            backgroundColor: rentalStatus?.statusColor,
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //rentalStatus
  };
};
