import { message } from "antd";

export const uploadToDrive = async (
  viewerRef,
  report,
  setReportAsFile,
  setIsOpen,
  reportAsFile
) => {
  const { reportId, reportName } = report;

  if (reportAsFile && reportAsFile?.id === reportId) {
    setIsOpen(true);
  } else {
    try {
      message.info({
        content: "Preparing PDF file...please wait!",
        duration: 5,
      });

      const viewer = viewerRef?.current;
      if (viewer && viewer.export) {
        const options = { reportName } || "Report";
        const cancelCallback = () => false;
        const pdfResult = await viewer.export("pdf", options, {
          cancel: cancelCallback,
        });
        const excelResult = await viewer.export("tabular-data", options, {
          cancel: cancelCallback,
        });

        if (pdfResult?.data && excelResult?.data) {
          const generatedResult = {
            fileName: reportName,
            id: reportId,
            data: {
              pdf: pdfResult.data,
              excel: excelResult.data,
            },
          };

          setReportAsFile(generatedResult);
          setIsOpen(true);
          message.destroy();
        }
      }
    } catch (error) {
      console.error("Failed to export PDF", error);
    }
  }
};
