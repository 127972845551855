import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const safety = ({ tables, programFields }) => {
  const getBorough = (address) => {
    if (address?.split(",")?.length > 1) {
      return address?.split(",")?.[1]?.trim();
    } else {
      return address;
    }
  };
  const safetyStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  const projectLabels = uniq(
    tables?.Safety?.map((e) =>
      getBorough(e?.safetyApplicationObject?.projectName)
    )
  );

  const accountLabels = uniq(
    tables?.Safety?.map((e) => e?.safetyApplicationObject?.accountName)
  );

  const scheduleLabels = uniq(
    tables?.Safety?.map((e) =>
      getBorough(e?.safetyApplicationObject?.scheduleAddress)
    )
  );

  return {
    safetyApplicationStatus: () => ({
      labels: safetyStatus?.map(({ statusName }) => statusName),
      data: safetyStatus?.map(
        ({ statusName }) =>
          tables?.Safety?.filter(
            (e) => e?.safetyApplicationStatus === statusName
          )?.length
      ),
      backgroundColor: programFields
        ?.find((e) => e?.fieldName === "Status colors")
        ?.fieldOptions?.Safety?.map((e) => e.statusColor),
    }),

    safetyName: () => ({
      labels: uniq(tables?.Safety?.map((e) => e?.safetyName)),
      data: tables?.Safety?.map(
        (a) =>
          tables?.Safety?.filter((e) => e?.safetyName === a?.safetyName)?.length
      ),
      backgroundColor: uniq(tables?.Safety?.map(() => getRandomColor())),
    }),

    accountName: () => ({
      labels: accountLabels,
      data: accountLabels?.map(
        (a) =>
          tables?.Safety?.filter(
            (e) => e?.safetyApplicationObject?.accountName === a
          )?.length
      ),
      backgroundColor: uniq(tables?.Safety?.map(() => getRandomColor())),
    }),

    projectName: () => ({
      labels: projectLabels,
      data: projectLabels?.map(
        (a) =>
          tables?.Safety?.filter(
            (e) => getBorough(e?.safetyApplicationObject?.projectName) === a
          )?.length
      ),
      backgroundColor: uniq(tables?.Safety?.map(() => getRandomColor())),
    }),

    safetyApplicationCategory: () => ({
      labels: uniq(tables?.Safety?.map((e) => e?.safetyApplicationCategory)),
      data: tables?.Safety?.map(
        (a) =>
          tables?.Safety?.filter(
            (e) => e?.safetyApplicationCategory === a?.safetyApplicationCategory
          )?.length
      ),
      backgroundColor: uniq(tables?.Safety?.map(() => getRandomColor())),
    }),

    scheduleAddress: () => ({
      labels: scheduleLabels,
      data: scheduleLabels?.map(
        (a) =>
          tables?.Safety?.filter(
            (e) => getBorough(e?.safetyApplicationObject?.scheduleAddress) === a
          )?.length
      ),
      backgroundColor: uniq(tables?.Safety?.map(() => getRandomColor())),
    }),
  };
};
