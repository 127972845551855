import { useState, forwardRef, useImperativeHandle, Fragment } from "react";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";

import { MARKER_ICON_URL } from "../../../../data";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { formatNumber } from "src/components/SidebarPages/utils";

const WaypointsMarkers = forwardRef(({}, ref) => {
  const [markers, setMarkers] = useState([]);
  const [hoverInfo, setHoverInfo] = useState();

  useImperativeHandle(
    ref,
    () => {
      return {
        updateWaypoints(waypoints = []) {
          setMarkers(waypoints);
        },
      };
    },
    []
  );

  return (
    <Fragment>
      {hoverInfo && (
        <InfoWindowF visible={true} zIndex={1001} position={hoverInfo.location}>
          <div className="waypoint-hover-container" style={{ padding: "5px" }}>
            <div
              className="waypoint-single-info"
              style={{ marginBottom: "5px" }}
            >
              <b>Time:&nbsp;</b>
              <span>{dayjsNY(hoverInfo.date).format("hh:mm a")}</span>
            </div>
            <div className="waypoint-single-info">
              <b>Speed:&nbsp;</b>
              <span>
                {formatNumber(hoverInfo.speed, { unit: "mile-per-hour" })}
              </span>
            </div>
          </div>
        </InfoWindowF>
      )}
      {markers?.map((e, i) => {
        return (
          <MarkerF
            key={i}
            position={e.location}
            icon={{ url: MARKER_ICON_URL }}
            onMouseOver={() => {
              setHoverInfo(e);
            }}
            onMouseOut={() => {
              setHoverInfo();
            }}
          />
        );
      })}
    </Fragment>
  );
});

export default WaypointsMarkers;
