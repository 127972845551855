import { message, Modal, Steps, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import "./TimelineProgress.scss";
import { convertCamelCaseToTitleCase } from "../../../SchedulingFirstPage/helperData";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { InfoText } from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { MondayButton } from "../../../../../../commonComponents";
import { PreviousIcon } from "../../../../../../SidebarPages/BasePage/src";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
const TimelineProgress = ({
  timeline = false,
  onCancel = () => {},
  rowData = [],
  inputOptions = [],
}) => {
  const [allData, setAllData] = useState([]);
  const [timelineSteps, setTimelineSteps] = useState({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const firstIncompleteStep = Object.keys(timelineSteps)[currentStepIndex];

  const onStepBack = () => {
    const index = currentStepIndex - 1;
    const steps = Object.keys(timelineSteps) || [];
    const updatedTimelineSteps = {
      ...timelineSteps,
      [steps[currentStepIndex]]: null,
    };

    let newFilteredData = [];

    for (const step of steps) {
      if (timelineSteps?.[step] !== null) {
        newFilteredData = rowData?.filter(
          (el) => el?.[step] === timelineSteps?.[step]
        );
      }
    }
    setAllData(newFilteredData);

    setTimelineSteps(updatedTimelineSteps);

    setCurrentStepIndex(index);
  };

  const onContinue = (e) => {
    const filterData = allData
      ?.filter((el) => el?.[firstIncompleteStep] === e)
      ?.sort((a, b) => a?.progressTimeGiven - b?.progressTimeGiven);

    if (!filterData || filterData.length === 0) return;

    let updatedTimelineSteps = {
      ...timelineSteps,
      [firstIncompleteStep]:
        firstIncompleteStep === "startDate"
          ? dayjsNY(e).format("MM/DD/YYYY")
          : e,
    };

    let index = currentStepIndex;

    const nextSteps = Object.keys(timelineSteps).slice(currentStepIndex + 1);

    for (const step of nextSteps) {
      const stepData = inputOptions(filterData, step);
      if (stepData.length === 1) {
        updatedTimelineSteps[step] = stepData[0]["label"];
        index++;
      } else {
        updatedTimelineSteps[step] = null;
        index++;
        break;
      }
    }

    setAllData(filterData);
    setTimelineSteps(updatedTimelineSteps);
    setCurrentStepIndex(index);
  };

  useEffect(() => {
    if (!!timeline) {
      setTimelineSteps({
        scheduleAddress: null,
        typeOfWork: null,
        scheduleName: null,
        estimation: null,
        serviceName: null,
        elevation: null,
        pli: null,
        startDate: null,
      });
      setAllData(rowData);
    }
  }, [timeline]);

  return (
    <div>
      <Modal
        {...{
          className: "timelineProgressModal",
          title: "Timeline Progress",
          open: timeline,
          centered: true,
          onCancel,
          closeIcon: <XIcon />,
          footer: [
            <MondayButton
              {...{
                disabled: Object.values(timelineSteps)[0] === null,
                className: "mondayButtonBlue",
                Icon: <PreviousIcon />,
                onClick: onStepBack,
              }}
            >
              Go back
            </MondayButton>,
          ],
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: 45,
          }}
        >
          {!Object.values(timelineSteps).every((el) => !!el) && (
            <InputComponent
              type="select"
              label={convertCamelCaseToTitleCase(firstIncompleteStep)}
              customOptions={inputOptions(allData, firstIncompleteStep)}
              initialValue={timelineSteps[firstIncompleteStep]}
              onChange={onContinue}
              style={{ width: 500 }}
            />
          )}

          <Steps
            progressDot
            direction="vertical"
            current={currentStepIndex}
            items={Object.entries(timelineSteps).map(([key, value]) => ({
              title: <div>{convertCamelCaseToTitleCase(key)}</div>,
              description: <div>{value}</div>,
            }))}
          />
          {Object.values(timelineSteps).every((el) => !!el) && (
            <Timeline
              className="timelineProgress"
              mode="left"
              items={allData?.map((data) => {
                return {
                  label: (
                    <div style={{ width: "100%" }}>
                      <span>{`${data?.user} at ${new Date(
                        data?.progressTimeGiven
                      ).toLocaleString()}`}</span>
                    </div>
                  ),
                  children: data?.dayProgress,
                };
              })}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TimelineProgress;
