import { useRef, useState } from "react";
import { Button, Divider, Form, Input, message, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./SelectWithCustomInputs.scss";
import { InputComponent } from "../../SidebarPages/Fleet/components";

let index = 0;

const SelectWithCustomInputs = ({
  label,
  defaultValue,
  defaultOptions,
  form,
  formItemName = "",
  getRecords,
  width = 300,
}) => {
  const updatedDefaultOptions =
    (!defaultOptions || defaultOptions.length === 0) && !!defaultValue
      ? [defaultValue]
      : (!defaultOptions || defaultOptions.length === 0) && !defaultValue
      ? []
      : defaultOptions.includes(defaultValue) || !defaultValue
      ? defaultOptions
      : [...defaultOptions, defaultValue];

  const [items, setItems] = useState(updatedDefaultOptions);
  const [selectedValue, setSelectedValue] = useState(defaultValue || undefined);
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    const exists = items.find(
      (item) => item.toLowerCase() === name.toLocaleLowerCase()
    );

    if (exists) {
      return message.error(`${name} already exists`);
    }

    const newItem = name || `New item ${index++}`;
    setItems([...items, newItem]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onChange = (value) => {
    !!formItemName && form.setFieldValue(formItemName, value);
    getRecords(value);
    setSelectedValue(value);
  };

  return (
    <div className="SelectWithCustomInputs">
      {label && <label>{label}</label>}
      {!!formItemName ? (
        <Form.Item name={formItemName}>
          <InputComponent
            form
            type="select"
            style={{
              width,
            }}
            placeholder="Select..."
            value={selectedValue}
            onChange={onChange}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                  }}
                >
                  <Input
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                    disabled={name.trimStart().length < 1}
                  >
                    Add item
                  </Button>
                </Space>
              </>
            )}
            customOptions={items.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </Form.Item>
      ) : (
        <Select
          form
          type="select"
          style={{
            width,
          }}
          placeholder="Select..."
          value={selectedValue}
          onChange={onChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  placeholder="Please enter item"
                  ref={inputRef}
                  value={name}
                  onChange={onNameChange}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addItem}
                  disabled={name.trimStart().length < 1}
                >
                  Add item
                </Button>
              </Space>
            </>
          )}
          options={items.map((item) => ({
            label: item,
            value: item,
          }))}
        />
      )}
    </div>
  );
};

export default SelectWithCustomInputs;
