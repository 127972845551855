import { useState, useEffect } from "react";
export const useModalOnce = (modalKey) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const modalShown = localStorage.getItem(modalKey);
    setShowModal(!modalShown);
    if (!modalShown) {
      localStorage.setItem(modalKey, true);
    }
  }, [modalKey]);

  return [showModal, setShowModal];
};

export const useModalOnceObject = (tourName) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const localStorageData = localStorage.getItem("tour");
    const prev =
      Object.keys(localStorageData || {})?.length > 0
        ? JSON.parse(localStorageData)
        : {};
    setShowModal(!prev?.[tourName]);
    if (!prev?.hasOwnProperty(tourName)) {
      const newName = { [tourName]: true };
      const updatedObject = { ...prev, ...newName };
      const updatedObjectString = JSON.stringify(updatedObject);
      localStorage.setItem("tour", updatedObjectString);
      setShowModal(true);
    }
  }, [tourName]);

  return [showModal, setShowModal];
};
