import types from "../types";

export const updateTopicCategories = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_TOPIC_CATEGORIES,
    payload: attributes,
  });
};

export const updateTopicCategoriesById = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_TOPIC_CATEGORIES_BY_ID,
    payload: attributes,
  });
};
