import { Select } from "antd";
import { uniqBy } from "lodash";
import React from "react";
import { DropdownIcon } from "../../../BasePage/src";
import { filterByProjectManagerHandler } from "./filtersData";

const ProjectManagerFilter = ({
  id = "",
  table = "",
  filterStates = {},
  dynamicStates = {},
  tmpFilterStates = {},
  filterValueTurnery = false,
  setFilterStates = () => {},
  setUpdatedDynamicStates = () => {},
}) => {
  const pmKey = (dynamicStates?.[id] || [])?.some((el) =>
    el?.hasOwnProperty("projectManager")
  )
    ? "projectManager"
    : "projectManagers";

  const uniqPms = () => {
    const availablePms = (dynamicStates?.[id] || [])?.map((el) => {
      if (el?.hasOwnProperty(pmKey)) {
        return el[pmKey];
      } else {
        return null;
      }
    });

    const flattenedPms = (availablePms || [])?.flat();

    if (
      flattenedPms?.every((el) => typeof el === "object" && !Array.isArray(el))
    ) {
      return uniqBy(flattenedPms, "cognitoUserId")
        ?.map((el) => {
          if (!el?.nameOfUser) {
            return null;
          }
          return { label: el?.nameOfUser, value: el?.nameOfUser };
        })
        ?.filter(Boolean);
    }
    return uniqBy(
      (flattenedPms || [])
        ?.filter(Boolean)
        ?.map((el) => ({ label: el, value: el })),
      "label"
    );
  };

  return (
    <div className="filterComponents">
      <div className="tittle">Filter by Project Managers</div>
      <div className="filterContainer">
        <div className="leftSideContainer">
          <div className="tittleContainer">Select a Project Manager</div>
        </div>
        <div className="rightSideContainer">
          <Select
            {...{
              popupClassName: "dashDropDown",
              placeholder: "Pick option",
              suffixIcon: <DropdownIcon />,
              onSelect: (e) => {
                filterByProjectManagerHandler(
                  e,
                  setUpdatedDynamicStates,
                  id,
                  pmKey,
                  dynamicStates,
                  setFilterStates,
                  filterStates,
                  table
                );
              },
              showSearch: true,
              className: "status-selector",
              value: filterValueTurnery
                ? filterStates?.filterByProjectManager
                : tmpFilterStates?.filterByProjectManager,
              options: uniqPms() || [],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectManagerFilter;
