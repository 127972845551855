import { generatePolygonImage } from "./shapesImagesGeneratorForFences";

export const calculateZoomLevel = (coordinates, circle) => {
  const lngs = coordinates.map((coord) => coord.lng);
  const lats = coordinates.map((coord) => coord.lat);

  const lngMax = Math.max(...lngs);
  const lngMin = Math.min(...lngs);
  const latMax = Math.max(...lats);
  const latMin = Math.min(...lats);

  const lngDiff = lngMax - lngMin;
  const latDiff = latMax - latMin;

  let zoomLevel = 16;

  if (lngDiff > 0 && latDiff > 0) {
    zoomLevel = Math.min(
      Math.floor(Math.log2(360 / lngDiff)),
      Math.floor(Math.log2(180 / latDiff))
    );
  }

  if (circle && circle.circleRadius) {
    const radiusInDegrees = circle.circleRadius / 111000;
    zoomLevel = Math.min(
      zoomLevel,
      Math.floor(Math.log2(360 / (2 * radiusInDegrees)))
    );
  }

  return zoomLevel - 0.4;
};

export const convertRectangleToSquare = (coords) => {
  const { north, south, east, west } = coords.bounds || {};

  const height = Math.abs(north - south);
  const width = Math.abs(east - west);

  const centerLat = (north + south) / 2;
  const centerLng = (east + west) / 2;

  const latDifferenceInMeters = height * 111320;
  const lngDifferenceInMeters =
    width * 111320 * Math.cos(centerLat * (Math.PI / 180));

  const maxDifferenceInMeters = Math.max(
    latDifferenceInMeters,
    lngDifferenceInMeters
  );
  const halfSideLengthInMeters = maxDifferenceInMeters / 2;

  const halfSideLengthLatDegrees = halfSideLengthInMeters / 111320;
  const halfSideLengthLngDegrees =
    halfSideLengthInMeters / (111320 * Math.cos(centerLat * (Math.PI / 180)));

  // Create new bounds for the square
  const newBounds = {
    north: centerLat + halfSideLengthLatDegrees,
    south: centerLat - halfSideLengthLatDegrees,
    east: centerLng + halfSideLengthLngDegrees,
    west: centerLng - halfSideLengthLngDegrees,
  };

  return newBounds;
};

export const convertRectangleToLatLng = (coords) => {
  const { north, south, east, west } = coords?.bounds || coords || {};

  return [
    { lat: north, lng: west }, // North-West corner
    { lat: north, lng: east }, // North-East corner
    { lat: south, lng: east }, // South-East corner
    { lat: south, lng: west }, // South-West corner
  ];
};

export const resolveStructureToShowInPdf = async (data) => {
  const toReturn = await Promise.all(
    data.map(
      async ({
        createdAt,
        createdBy,
        description,
        title,
        type,
        geoFenceInfo,
      }) => {
        return {
          createdAt,
          createdBy,
          description,
          title,
          type,
          image: await generatePolygonImage({
            polygonCoordinates: geoFenceInfo,
          }),
        };
      }
    )
  );
  return toReturn;
};

export const preparedExcelToExportForGeoFences = (data) => {
  const toReturn = data.map(
    ({ createdAt, createdBy, description, title, type, geoFenceInfo }) => {
      const isArray = Array.isArray(geoFenceInfo);
      const squareCoords = !isArray && convertRectangleToSquare(geoFenceInfo);

      const proceedPolygonCoordinates = isArray
        ? geoFenceInfo
        : convertRectangleToLatLng(squareCoords);
      return {
        createdAt,
        createdBy,
        description,
        title,
        type,
        coords: proceedPolygonCoordinates
          .map(({ lat, lng }) => `LAT:${lat}, LNG:${lng}`)
          .join(", "),
      };
    }
  );
  return toReturn;
};

export const preparedExcelTColumnsForGeoFences = [
  "Created At",
  "Created By",
  "Description",
  "title",
  "Type",
  "Coords",
];
