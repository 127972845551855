import Swal from "sweetalert2";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

// Used for formatting the price input field in the rental calculator (InputNumber Antd component)
export const priceInputFormatter = (value) =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// Used for parsing the price input field in the rental calculator (InputNumber Antd component)
export const priceInputParser = (value) => value.replace(/\$\s?|(,*)/g, "");

export const totalitiesRentCalculator = ({
  elevation,
  numberOfMonths,
  currPrice,
  initialRate,
  recurringRate,
  taxRate = 0,
  service,
  reset,
  keyToSave,
}) => {
  console.log("total", { elevation, numberOfMonths, currPrice, service });
  let RESULT = {
    totalPrice: 0,
    monthlyPrice: (Number(currPrice) * recurringRate) / 100,
    rentSubtotal: 0,
    newPrice: (numberOfMonths * (currPrice * recurringRate)) / 100,
    total: 0,
    taxAmount: 0,
    priceWithoutTax: 0,
  };
  RESULT.priceWithoutTax = RESULT.newPrice;
  RESULT.taxAmount = forceToNumber(RESULT.newPrice) * forceToNumber(taxRate);
  RESULT.newPrice = RESULT.newPrice + RESULT.taxAmount;
  if (!reset && !!service?.[keyToSave]) {
    Swal.fire({
      title: "LATEST CHANGES",
      text: "These are the latest changes you made to the rental calculator. If you want to reset the calculator, please click on the reset button.",
      icon: "warning",
    });
    const {
      totalPrice = 0,
      taxRate = 0,
      total = 0,
      taxAmount = 0,
      priceWithoutTax = 0,
      newPrice = 0,
      rentSubtotal = 0,
      monthlyPrice = 0,
    } = service?.[keyToSave];
    return {
      totalPrice,
      monthlyPrice,
      rentSubtotal,
      newPrice,
      total,
      taxAmount,
      priceWithoutTax,
    };
  }
  console.log("totalitiesRentCalculator", RESULT);

  return RESULT;
};

// monthly price = current price * differentRate
