import filterUsersByAccess from "./filterUsersByAccess";

/**
 * @param {Object} param
 * @param {String} param.title - The title of the object
 * @param {Array} param.users - The users array
 * @param {String} param.currentUser - The current user cognito id
 * @param {boolean} [param.selfAssign=false] - Possibility to self assign
 */
const assignedToObject = ({
  title = "",
  users = [],
  currentUser = "",
  selfAssign = false,
}) => ({
  customOptions: filterUsersByAccess({
    users,
    accessTitle: title,
    currentUser,
    selfAssign,
  }),
  showSearch: true,
  allowClear: true,
});

export default assignedToObject;
