import React, { useEffect, useState, useMemo } from "react";
import "../TeamCard/Teams_Card.scss";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getRandomColor } from "../../../../Header/forms/Scheduling/helpers/creators";
import { AvatarImage } from "../../../../commonComponents";

function DynamicUsers(props) {
  const { params, edited } = props;
  const { dynamicRowDataKey } = params;
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [mergedUsers, setMergedUsers] = useState([]);
  const allUsers = userConfiguration?.allUsers?.Items;

  const [newValues, setNewValues] = useState([]);
  const location = useLocation();
  const path = location?.pathname;

  const dynamicUsers = useMemo(
    () => newValues && (newValues[dynamicRowDataKey] || []),
    [newValues]
  );
  useEffect(() => {
    if (!!params) {
      API.get(`${path?.split("/")[1]}`, `${path}`).then((res) =>
        setNewValues(res)
      );
    }
  }, [params, edited]);

  useEffect(() => {
    if (!!dynamicUsers && !!allUsers) {
      let data = allUsers?.filter((user) =>
        dynamicUsers.find(
          (givenUser) => user.identityId === givenUser.identityId
        )
      );
      setMergedUsers(_.uniqBy(data, "identityId"));
    }
  }, [dynamicUsers, allUsers]);

  return (
    <div className="membersContent-container">
      {mergedUsers?.map((el, index) => (
        <div className="content-membersContent" key={index}>
          <div className="member-content">
            {el?.googleDriveFileId !== undefined &&
            el?.googleDriveFileId !== null ? (
              <div
                style={{
                  marginRight: 10,
                  borderRadius: "50%",
                  width: 50,
                  height: 50,
                }}
              >
                <AvatarImage
                  {...{
                    nameOfUser: el?.nameOfUser,
                    googleDriveFileId: el?.googleDriveFileId,
                  }}
                />
              </div>
            ) : (
              <div
                className="InitialsContainer"
                style={{ background: getRandomColor() }}
              >
                <span className="initialsStyle">
                  {el?.nameOfUser?.charAt(0)}
                </span>
              </div>
            )}
            <div className="memberData">
              <div className="nameContainer">{el.nameOfUser}</div>
              <div className="groupName">{el.groupName}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default DynamicUsers;
