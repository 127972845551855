// import Loader from "react-loader-spinner";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import "./PaginationButtons.scss";
import { LoadingDots } from "../3LoadingDots/LoadingDots";

const PaginationButtons = ({
  isLoading,
  pageNumbers,
  goToPageNumber,
  isBackButtonDisabled,
  isNextButtonDisabled,
  handleBackButtonClick,
  handleNextButtonClick,
}) => {
  return isLoading ? (
    <LoadingDots
      style={{
        color: "#1264a3",
        height: 40,
        width: 40,
      }}
    />
  ) : (
    <div className="pagination-buttons-cc">
      <button
        className="pagination-button"
        onClick={handleBackButtonClick}
        disabled={isBackButtonDisabled}
      >
        <CaretLeftOutlined style={{ fontSize: 32 }} />
        <span>Previous</span>
      </button>
      {pageNumbers &&
        pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={`page-number-button ${
              pageNumbers.length === Number(pageNumber) ? "active-page" : ""
            }`}
            onClick={() => goToPageNumber(Number(pageNumber))}
          >
            {pageNumber}
          </button>
        ))}
      <button
        className="pagination-button"
        onClick={handleNextButtonClick}
        disabled={isNextButtonDisabled}
      >
        <span>Next</span>
        <CaretRightOutlined style={{ fontSize: 32 }} />
      </button>
    </div>
  );
};

export default PaginationButtons;
