import React from "react";
import { EditIcon } from "../../../icons";
import "./GridEditableCell.scss";

const GridEditableCell = ({
  value,
  hasIcon = false,
  disabled = false,
  className = "",
}) => {
  return (
    <div
      className={`editable-cell-container ${className} ${
        disabled && "disabled"
      }`}
    >
      <div>{value}</div>
      {hasIcon && (
        <div className="editable-cell-icon">
          <EditIcon />
        </div>
      )}
    </div>
  );
};

export default GridEditableCell;
