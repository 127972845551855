import { SimplePLI } from "./GeneralModels";

export class ScaffoldPLI extends SimplePLI {
  serviceId = 2;

  width = 8;

  length;
  height;
  facadeHeight;

  fromFloor;
  fromFloorNote;

  toFloor;
  toFloorNote;

  includedCorners;

  custom;

  ppu;
  rent;
  price;

  lock = false;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}

export class AlternateScaffoldPLI extends SimplePLI {
  serviceId = 2;

  width = 8;

  length;
  height;
  facadeHeight;

  fromFloor;
  fromFloorNote;

  toFloor;
  toFloorNote;

  includedCorners;

  custom;

  ppu;
  rent;
  price;

  lock = false;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
