import { useEffect, useMemo, useState } from "react";
import { Folders, UploadObject } from "./helpers";
import { Carousel, Image } from "antd";
import { EmptyBox } from "../../../../../../assets";
import ImageLoader from "./ImageLoader";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import CustomCarousel from "./CustomCarousel/CustomCarousel";
import { useScrollIntoView } from "./utils/useScrollIntoView/use-scroll-into-view";
import ThumbnailLoader from "./ThumbnailLoader";

type Props = {
  selectedFolder: string;
  dayFolder: Folders | {};
  dayDriveFolders: UploadObject[] | [];
  driveRequest: any;
  deleteFile: (id: string, formattedDate: string) => void;
  className?: string;
  disabled: boolean;
};

type Slide = {
  id: string;
  title: string;
  subtitle: string;
  Component: JSX.Element;
  url?: string;
};

const CarouselImages = (props: Props) => {
  const {
    selectedFolder,
    dayFolder,
    dayDriveFolders,
    driveRequest,
    deleteFile,
    className = "",
    disabled,
  } = props;

  const [slides, setSlides] = useState<Slide[]>([] as Slide[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  //* This is a hook that scrolls the selected photo into view with animation
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<
    HTMLDivElement,
    HTMLDivElement
  >({ axis: "x", offset: 140 });

  //* created slides for the carousel with the images from that day
  //! do not remove the timeout because the carousel will not work when changing days
  useEffect(() => {
    setLoading(true);
    setActiveSlide(0);
    const newSlides = dayDriveFolders?.map(({ id, url, webViewLink }) => {
      return {
        id,
        title: "",
        subtitle: "",
        Component: <ImageLoader fileId={id} driveRequest={driveRequest} />,
        url: webViewLink || url,
      };
    });
    setSlides(newSlides);

    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [dayDriveFolders]);

  return (
    <div className={`imagesContainer ${className}`}>
      {!!dayDriveFolders?.length ? (
        <div className="carouselContainer">
          {!loading && (
            <CustomCarousel
              slides={slides}
              interactionMode="hover"
              onDeleteClicked={
                !disabled
                  ? (fileId: string) => {
                      deleteFile(fileId, selectedFolder);
                    }
                  : undefined
              }
              activeSlide={activeSlide}
              setActiveSlideIndex={(slide: number) => {
                setActiveSlide(slide);
                setTimeout(() => {
                  scrollIntoView();
                }, 300);
              }}
            />
          )}
          <div className="allImages" ref={scrollableRef}>
            {dayDriveFolders?.map(
              ({ id, thumbnailLink, name }, index: number) => {
                const size = activeSlide === index ? 1 : 0.8;
                return (
                  <div ref={activeSlide === index ? targetRef : null}>
                    <ThumbnailLoader
                      thumbnailLink={thumbnailLink}
                      setActiveSlide={(index) => setActiveSlide(index)}
                      index={index}
                      size={size}
                      id={id}
                      key={id}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            // minHeight: "400px",
          }}
        >
          <div
            className={`carouselContainer ${
              !!dayDriveFolders?.length ? "" : "empty"
            }`}
          >
            <EmptyBox />
            <span>There are no images uploaded for this day!</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselImages;
