import { Input, InputNumber } from "antd";
import { XIcon } from "../../../../../../../../../../../../SidebarPages/Communication/assets";
import { Plus } from "../../../../../../../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";

const SelectOption = ({
  label,
  value,
  index,
  onOptionChange,
  onOptionDelete,
  onOptionAddition,
  canDelete,
}) => {
  const onInputChange = (key) => (changedValue) =>
    onOptionChange({
      [key]:
        changedValue instanceof Object
          ? changedValue.target.value
          : changedValue,
    });

  const handleEnter = (event) => {
    if (event.key !== "Enter") return;
    onOptionAddition();
  };

  return (
    <div className="select-price-option" onKeyPress={handleEnter}>
      <div className="option-container">Option {index + 1}</div>

      <div className="input-container">
        <Input
          value={label}
          placeholder="Label"
          onChange={onInputChange("label")}
        />

        <InputNumber
          value={value}
          onChange={onInputChange("value")}
          min={0}
          controls={false}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        />
      </div>
      <div className="action-buttons-container">
        {!!canDelete && (
          <div className="action-button delete" onClick={onOptionDelete}>
            <XIcon />
          </div>
        )}
        <div className="action-button add" onClick={onOptionAddition}>
          <Plus />
        </div>
      </div>
    </div>
  );
};

export default SelectOption;
