import { Modal } from "antd";
import { antdModalProps } from "../../../../../../utils";
import "./HoistPriceCalculatorModal.scss";
import HoistPriceCalculatorModalContent from "./HoistPriceCalculatorModalContent";

const HoistPriceCalculatorModal = ({
  visible,
  setVisible,
  elevation,
  indices,
}) => {
  const onCancel = () => setVisible(false);

  return (
    <Modal
      {...{
        ...antdModalProps,
        title: "Price Calculator",
        wrapClassName: "cardLayoutModal hoist-price-calculator-modal-wrapper",
        open: visible,
        onCancel,
      }}
    >
      <HoistPriceCalculatorModalContent
        elevation={elevation}
        indices={indices}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default HoistPriceCalculatorModal;
