export const documentation = ({ tables, circleProgressPicker }) => {
  return {
    docType: () => ({
      percent: (
        (tables?.Documentation?.filter((a) => a?.docType === circleProgressPicker)?.length /
          tables?.Documentation?.length) *
        100
      ).toFixed(1),
      data: tables?.Documentation?.filter((a) => a?.docType === circleProgressPicker)?.length,
    }), //doctype
  } //Documentation
}
