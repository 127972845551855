import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
export const documentation = (
  programFields,
  updatedDynamicStates,
  IdHandler
) => {
  return {
    docType: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docType"
      );

      return {
        title: "Documentation Type",
        BodyComponent: "LongLineSteper",
        type: "LongLineSteper",
        BodyComponentParams: {
          table: "Documentation",
          field: "docType",
          content: Object.entries(data)?.map(([key, value], index) => ({
            key: index,
            title: key,
            taskNumber: updatedDynamicStates?.[IdHandler]?.length,
            taskDone: value,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${key} Document`,
            contentsHandler: ` ${key} Documents`,
          })),
        },
        id: IdHandler,
        width: "1600px",
      };
    }, //doctype

    docStatus: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docStatus"
      );

      return {
        title: "Documentation Status",
        BodyComponent: "LongLineSteper",
        type: "LongLineSteper",
        BodyComponentParams: {
          table: "Documentation",
          field: "docStatus",
          content: Object.entries(data)?.map(([key, value], index) => ({
            key: index,
            title: key,
            taskNumber: updatedDynamicStates?.[IdHandler]?.length,
            taskDone: value,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` ${key} Document`,
            contentsHandler: ` ${key} Documents`,
          })),
        },
        id: IdHandler,
        width: "1600px",
      };
    },
  }; //Documentation
};
