import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../SidebarPages/utils";

export function mainColDefs() {
  return [
    {
      headerName: "Role",
      field: "employeeRole",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "Hours Worked",
      field: "totalWorkHours",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
    },
    {
      headerName: "Overtime Hours",
      field: "totalOtHours",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
    },
    {
      headerName: "Overhead Hours",
      field: "totalOvhHours",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
    },
    {
      headerName: "Total Overtime",
      field: "totalOt",
      cellRenderer: ({ value }) => {
        return formatCurrency(value);
      },
    },
    {
      headerName: "Overhead Amount",
      field: "totalOvh",
      cellRenderer: ({ value }) => {
        return formatCurrency(value);
      },
    },
    {
      headerName: "Total Reimbursement",
      field: "totalReimbursement",
      cellRenderer: ({ value }) => {
        return formatCurrency(value);
      },
    },
    {
      headerName: "Total",
      field: "total",
      cellRenderer: ({ value }) => {
        return formatCurrency(value);
      },
    },
    {
      headerName: "Total With Overhead",
      field: "totalWOvh",
      cellRenderer: ({ value }) => {
        return formatCurrency(value);
      },
    },
  ];
}

export function detailColDefs() {
  return {
    detailGridOptions: {
      columnDefs: [
        {
          headerName: "Employee Name",
          field: "crewName",
        },
        {
          headerName: "Hours Worked",
          field: "hoursWorked",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Overtime Hours",
          field: "totalOtHours",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Overhead Hours",
          field: "totalOvhHours",
          cellRenderer: ({ value }) => {
            return formatNumber(value, { unit: "hour" });
          },
        },
        {
          headerName: "Overtime Amount",
          field: "otAmount",
          cellRenderer({ value }) {
            return formatCurrency(value);
          },
        },
        {
          headerName: "Reg Amount",
          field: "regAmount",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
        {
          headerName: "Total Reimbursement",
          field: "totalReimbursement",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
        {
          headerName: "Total Amount",
          field: "totalAmount",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
        {
          headerName: "Total Overhead",
          field: "totalOverhead",
          cellRenderer: ({ value }) => {
            return formatCurrency(value || 0);
          },
        },
        {
          headerName: "Total With Overhead",
          field: "totalWOvh",
          cellRenderer: ({ value }) => {
            return formatCurrency(value);
          },
        },
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableColResize: true,
      },
      pagination: false,
    },
    getDetailRowData(params) {
      params.successCallback(params.data.details);
    },
  };
}
