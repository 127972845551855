import { Modal, Select } from "antd";
import { antdModalProps } from "../../../../../../utils";
import { PriceTable } from "../../../../../pages/Settings/settingsComponents/Pricing/subcomponents";
import { useTakeOffContext } from "../../context";
import "./PriceTableSchemeModal.scss";
import { capitalizeFirstLetter } from "../../tools/polyfillers";
import PriceTableTakeOff from "../../../../../pages/Settings/settingsComponents/Pricing/subcomponents/PriceTable/PriceTableTakeOff";

const PriceTableSchemeModal = ({ visible, setVisible, serviceData }) => {
  const { serviceId } = serviceData;
  const {
    selectedPriceSchemesForService,
    pricingData = [],
    setState,
  } = useTakeOffContext();

  const currentServiceSchemeConfiguration =
    selectedPriceSchemesForService?.[serviceId] || {};

  const {
    id: selectedPriceSchemeId,
    prices = [],
    dimension = "",
  } = currentServiceSchemeConfiguration?.priceScheme || {};

  //All price schemes of this service
  const { priceSchemes = [] } =
    pricingData.find(({ serviceId: id }) => id == serviceId)?.pricingObject ||
    {};

  //Price schemes options to pass to the selector
  const priceSchemeOptions = priceSchemes.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const onCancel = () => setVisible(false);

  const onPriceSchemeSelect = (selectedId) => {
    const priceScheme = priceSchemes.find(({ id }) => id === selectedId);
    setState({
      selectedPriceSchemesForService: {
        ...selectedPriceSchemesForService,
        [serviceId]: { ...currentServiceSchemeConfiguration, priceScheme },
      },
    });
  };
  console.log("priceSchemes", {
    priceSchemes,
    selectedPriceSchemeId,
    currentServiceSchemeConfiguration,
  });

  return (
    <Modal
      open={visible}
      {...{
        ...antdModalProps,
        // open: priceTableModalVisible,
        onCancel: onCancel,
        title: "Price Scheme",
      }}
    >
      <div className="priceTableSchemeModalContent">
        <div className="core-information">
          <Select
            value={selectedPriceSchemeId}
            placeholder="Select price scheme"
            options={priceSchemeOptions}
            onSelect={onPriceSchemeSelect}
          />

          <div className="scheme-dimension">{`Dimension: ${capitalizeFirstLetter(
            dimension
          )}`}</div>
        </div>
        <PriceTableTakeOff prices={prices} />
      </div>
    </Modal>
  );
};

export default PriceTableSchemeModal;
