import "./AutomationStep.scss";
import { useSelector } from "react-redux";
import BoardingNavigationBtn from "../BoardingNavigationBtn/BoardingNavigationBtn";
import { NewAutomationIcon } from "../../../../assets";
import AutomationNotificationConfig from "./AutomationNotificationConfig/AutomationNotificationConfig";

const AutomationStep = ({
  boardingChanges: { automationPreferences: emailNotifConfigs },
  setBoardingChanges,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const updateAutomationNotifications = (automationNotifs) => {
    setBoardingChanges((prev) => ({
      ...prev,
      automationPreferences: automationNotifs,
    }));
  };

  return (
    <div
      className={`automation-step step-lines ${isDarkMode ? "dark-mode" : ""}`}
    >
      <div className="notification-icon">
        <NewAutomationIcon style={{ width: "30px", height: "30px" }} />
      </div>
      <span style={{ textAlign: "center" }}>
        Automate <span style={{ fontWeight: 600 }}>email delivery</span> of
        relevant documents, reports
        <br /> and reminders for smoother workflow management.
      </span>

      <AutomationNotificationConfig
        {...{
          automationPreferences: emailNotifConfigs,
          updateAutomationNotifications,
        }}
      />

      <BoardingNavigationBtn />
    </div>
  );
};

export default AutomationStep;
