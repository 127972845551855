import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";
import ProposalBuilder from "../ProposalBuilder";

function NewProposalBuilder() {
  const editorRef = useRef(null);

  const API_KEY = "75p21oy3r4j7ga6bmp57xk4kwnjrk1j2dy8cie6yhrnatia4";
  return <ProposalBuilder />;
}

export default NewProposalBuilder;
