import "./BoardingNavigationBtn.scss";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { useOnBoardingContext } from "../../OnBoardingContext";
import { UserConfig } from "../../../../actions";
import { saveStepLogs } from "../../utils";
import { useEditLogs } from "../../../../hooks";

const BoardingNavigationBtn = ({ autoStepConfig = true }) => {
  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { currentStep, setCurrentStep, boardingSteps, boardingStepRef } =
    useOnBoardingContext();
  const { saveAddedLogs } = useEditLogs();

  const activeStep = boardingSteps[currentStep];
  const stepConfigs = userConfiguration?.boardingConfigs || {};
  const isLastStep = currentStep === boardingSteps.length - 1;

  const uncompletedSteps = boardingSteps.filter(
    ({ key }) => !stepConfigs?.[key]?.finished
  );
  const nextStep = uncompletedSteps.find(({ index }) => index > currentStep); //prev uncompleted step
  const prevStep = uncompletedSteps.findLast(
    ({ index }) => index < currentStep
  );

  const onClickScroll = () => {
    boardingStepRef.current.scrollTo({
      left: 300 * (nextStep ? currentStep : 0),
      behavior: "smooth",
    });
  };

  const saveSkippedStep = (key) => {
    const boardingConfigs = {
      ...stepConfigs,
      [activeStep.key]: {
        ...(stepConfigs?.[activeStep.key] || {}),
        [key]: true,
      },
    };

    API.patch("userConfiguration", "/userConfiguration", {
      body: { boardingConfigs },
    }).then(() => {
      saveStepLogs(
        [{ key, stepKey: activeStep.key, stepTitle: activeStep.stepTitle }],
        saveAddedLogs,
        userConfiguration
      );
    });

    dispatch(UserConfig({ ...userConfiguration, boardingConfigs }));
  };

  return (
    <div className="boarding-navigation-buttons">
      {prevStep && currentStep > 0 && (
        <div
          className="btn btn-back"
          data-testid="btn-back"
          onClick={() => {
            setCurrentStep(prevStep?.index);

            boardingStepRef.current.scrollTo({
              left: boardingStepRef.current.scrollLeft - 300,
              behavior: "smooth",
            });
          }}
        >
          Go Back
        </div>
      )}
      {(nextStep || prevStep) && (
        <>
          {/* For steps without autoStepConfig show only "Next" btn to set is as "skipped"
              To finish step, component itself should update boardingConfigs  */}
          {autoStepConfig && (
            <div
              className="btn"
              onClick={() => {
                setCurrentStep((!!nextStep ? nextStep : prevStep)?.index);
                onClickScroll();
                if (!stepConfigs?.[activeStep.key]?.skipped) {
                  saveSkippedStep("skipped");
                }
              }}
            >
              Skip
            </div>
          )}

          <div
            className="btn btn-next"
            data-testid="btn-next"
            onClick={() => {
              setCurrentStep(
                isLastStep ? 0 : (!!nextStep ? nextStep : prevStep)?.index
              );
              onClickScroll();
              const stepKey = autoStepConfig ? "finished" : "skipped"; //if autoStepConfig=false it need to finished from step component not from here
              if (!stepConfigs?.[activeStep.key]?.[stepKey]) {
                saveSkippedStep(stepKey);
              }
            }}
          >
            {isLastStep ? "Finish" : "Move next"}
          </div>
        </>
      )}
    </div>
  );
};

export default BoardingNavigationBtn;
