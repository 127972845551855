// import dayjs from "dayjs-timezone";
import _ from "lodash";
import { DayReplacementType } from "../../../../Header/forms/Scheduling/helpers/PLI-setters-getters";
import { iterateServicesInPLILevel } from "../../../../Header/forms/Scheduling/helpers/iterators";
import axios from "axios";
import { message } from "antd";
import * as FileSaver from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en"; // or the locale of your choice
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import moment from "moment";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

// dayjs.tz.setDefault("America/New_York");

const getDateArray = (startDate, endDate) => {
  let results = {};
  let iter = dayjs(startDate);

  while (dayjs(iter).isSameOrBefore(dayjs(endDate))) {
    results[dayjs(iter).tz("America/New_York").format("MM/DD/YYYY")] = [];
    iter = dayjs(iter).tz("America/New_York").clone().add(1, "day");
  }
  return results;
};

const cachedFunc = _.memoize(getDateArray);

export const formScheduleDaysTable = (scheduleDays, activeFilters) => {
  const datesArray = cachedFunc(
    dayjs().tz("America/New_York"),
    dayjs().tz("America/New_York").add(6, "months")
  );
  _.cloneDeep(
    scheduleDays.filter((el) =>
      dayjs(el.startDate).isSameOrAfter(dayjs(), "day")
    )
  ).forEach((day) => {
    if (datesArray[dayjs(day.startDate).format("MM/DD/YYYY")])
      datesArray[dayjs(day.startDate).format("MM/DD/YYYY")].push(day);
  });
  if (Object.values(activeFilters).every((e) => !e)) {
    return Object.fromEntries(
      Object.entries(datesArray).map(([key, value]) => [
        key,
        _.sortBy(value, "day"),
      ])
    );
  } else {
    let temp = _.cloneDeep(Object.entries(datesArray)).map(([key, value]) => [
      key,
      _.sortBy(value, (obj) => dayjs(obj.startDate).valueOf()),
    ]);
    if (activeFilters.projectId)
      temp = temp.filter(([date, scheduleDays]) =>
        scheduleDays.some((day) => day.projectId === activeFilters.projectId)
      );
    if (activeFilters.scheduleId)
      temp = temp.filter(([date, scheduleDays]) =>
        scheduleDays.some((day) => day.scheduleId === activeFilters.scheduleId)
      );
    if (activeFilters.datePicker)
      temp = temp.filter(
        ([date, scheduleDays]) =>
          date ===
            dayjs(activeFilters.datePicker)
              .tz("America/New_York")
              .format("MM/DD/YYYY") ||
          dayjs(date)
            .tz("America/New_York")
            .isAfter(dayjs(activeFilters.datePicker).tz("America/New_York"))
      );
    if (activeFilters.rangePicker)
      temp = temp = temp.filter(
        ([date, scheduleDays]) =>
          date ===
            dayjs(activeFilters.rangePicker[0])
              .tz("America/New_York")
              .format("MM/DD/YYYY") ||
          dayjs(date).isBetween(
            dayjs(activeFilters.rangePicker[0])
              .tz("America/New_York")
              .format("MM/DD/YYYY"),
            dayjs(activeFilters.rangePicker[1])
              .tz("America/New_York")
              .format("MM/DD/YYYY"),
            undefined,
            "[]"
          )
      );
    if (activeFilters.typeOfWork)
      temp = temp.filter(([date, scheduleDays]) =>
        scheduleDays.some((day) => day.typeOfWork === activeFilters.typeOfWork)
      );
    if (activeFilters.scopeOfWork)
      temp = temp.filter(([date, scheduleDays]) =>
        scheduleDays.some((day) =>
          day.scopeOfWork.includes(activeFilters.scopeOfWork)
        )
      );
    if (activeFilters.emptyDates)
      temp = temp.filter(([date, scheduleDays]) => scheduleDays.length);
    return Object.fromEntries(temp);
  }
};

export function nth(n) {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
}

export const newScheduleServices = (toBeScheduled, dayId) => {
  const toBeScheduledCopy = Object.values(_.cloneDeep(toBeScheduled)).flat(1);
  const servicesForDay = [];
  if (Object.keys(toBeScheduled)?.length)
    for (const service of toBeScheduledCopy || []) {
      for (const notOption of service?.serviceOptions || []) {
        for (const option of notOption || []) {
          for (const item of option?.items || []) {
            servicesForDay.push({
              ...item,
              parentLabel: service?.label,
              parentElevation: `${option?.elevationLabel} ${option?.elevationId}`,
            });
          }
        }
      }
    }
  return [
    ...new Set(
      servicesForDay
        ?.filter?.(
          (item) => Array.isArray(item?.days) && item.days.includes(dayId)
        )
        ?.map?.((item) => `${item?.parentLabel}`)
    ),
  ];
};

// const scheduledServices = (toBeScheduled, dayId) => {
// 	let services = Object.values(toBeScheduled);
// 	let daysScheduled = [];
// 	services = services?.reduce((a, b) => {
// 		return a.concat(b);
// 	}, []);
// 	for (const service of services) {
// 		daysScheduled.push({ serviceName: service.label, days: [] });
// 		for (const addon of service.serviceAddons) {
// 			for (const day of addon.days) {
// 				daysScheduled[daysScheduled.length - 1].days.push(day);
// 			}
// 		}
// 		for (const serviceOption of service.serviceOptions) {
// 			for (const elevation of serviceOption) {
// 				for (const item of elevation.items) {
// 					for (const addon of item.addons) {
// 						if (addon.days.length) {
// 							for (const day of addon.days) {
// 								daysScheduled[daysScheduled.length - 1].days.push(day);
// 							}
// 						}
// 					}
// 					if (item.days !== undefined) {
// 						for (const day of item.days) {
// 							daysScheduled[daysScheduled.length - 1].days.push(day);
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 	const temp = [];
// 	daysScheduled
// 		.filter((service) => service["days"].includes(dayId))
// 		.map((s) => temp.push(s["serviceName"]));
// 	return temp;
// };

// export const scheduledServicesCached = _.memoize(scheduledServices);

export const changeToBeScheduledPerDay = (
  modifiedScheduleDays,
  toBeScheduled
) => {
  let modified_scheduleDays = _.cloneDeep(modifiedScheduleDays);
  modified_scheduleDays = modified_scheduleDays
    ?.filter(
      (el) =>
        el?.status !== "Postponed" &&
        el?.status !== "Canceled" &&
        el?.status !== "Skipped"
    )
    ?.forEach?.((day, index) => {
      day.day = index + 1;
    });

  return [modifiedScheduleDays, toBeScheduled];
};

export const areConsecutiveDays = (arrayOfDates) => {
  return _.cloneDeep(arrayOfDates)
    .slice(1)
    .map((n, i) => {
      return _.round(
        Math.abs(dayjs(n).diff(dayjs(arrayOfDates[i]), "days", true))
      );
    })
    .every((value) => value === 1 || value === 0);
};

export const businessDiff = (startDate, endDate) => {
  const totalDays = dayjs.tz(endDate).diff(dayjs.tz(startDate), "days");
  const dayOfWeek = dayjs.tz(startDate).day();
  let totalWorkdays = 0;
  for (let i = dayOfWeek; i < totalDays + dayOfWeek; i++) {
    if (i % 7 !== 6 && i % 7 !== 0) {
      totalWorkdays++;
    }
  }

  return totalWorkdays;
};

export const addBusinessDays = (date, days) => {
  let daysToAdd = days;
  const today = dayjs(date).clone();
  const nextWeekStart = today.clone().add(1, "week").weekday(1);
  const weekEnd = today.clone().weekday(5);
  const daysTillWeekEnd = Math.max(0, weekEnd.diff(today, "days"));
  if (daysTillWeekEnd >= daysToAdd) return today.clone().add(daysToAdd, "days");
  daysToAdd = daysToAdd - daysTillWeekEnd - 1;
  return nextWeekStart
    .add(Math.floor(daysToAdd / 5), "week")
    .add(daysToAdd % 5, "days")
    .clone();
};

const createDateArray = (startDate, end) => {
  const arr = [];
  let date = dayjs(startDate).tz("America/New_York").clone().subtract(1, "day");
  let count = 0;
  let tempDate = date.clone();
  while (count <= end) {
    tempDate = tempDate.add(1, "day");
    if (tempDate.day() !== 0 && tempDate.day() !== 6) {
      arr.push(tempDate.clone());
      count++;
    }
  }
  return arr;
};

export const addBusinessDaysArray = (dates, diff, firstDate) => {
  let datesCopy = _.sortBy(_.cloneDeep(dates), ([key, value]) =>
    dayjs(value).tz("America/New_York").valueOf()
  );

  let newDates = [];
  const newDateArray = createDateArray(
    firstDate,
    _.uniqBy(
      datesCopy.map((el) => el[1]),
      (obj) => obj.format("MM/DD/YYYY")
    ).length - 1
  );
  const groupedDates = _.sortBy(
    Object.entries(
      _.groupBy(datesCopy, ([key, value]) => dayjs(value).format("MM/DD/YYYY"))
    ),
    (arr) => dayjs(arr[0]).tz("America/New_York").valueOf()
  );
  for (const [index, newDate] of newDateArray.entries()) {
    groupedDates[index][1].forEach(([key, value]) => {
      const difference = _.round(
        dayjs(newDate)
          .tz("America/New_York")
          .diff(value, "days", true)
          .toFixed(2)
      );

      newDates.push([key, value.clone().add(difference, "day")]);
    });
  }
  return newDates;
};

export const exportToExcel = async (data) => {
  const fileName = `Scheduling Mass Changes (${dayjs()
    .tz("America/New_York")
    .format("MM-DD-YYYY")})`;
  const url =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080/api/export/xlsx"
      : "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  const dataToBeSent = [];
  const scheduleDaysSorted = _.sortBy(data?.scheduleDays, (obj) =>
    dayjs(obj?.startDate).valueOf()
  );
  for (const day of scheduleDaysSorted) {
    const newStartDate = data?.autoDates[day?.id],
      newEndDate = data?.autoEndDates[day?.id];
    dataToBeSent.push({
      "Old Date": dayjs(day?.startDate).tz("America/New_York").toISOString(),
      "Old Start time - End time": `${dayjs(day?.startDate)
        .tz("America/New_York")
        .format("h A")} - ${dayjs(day?.endDate)
        .tz("America/New_York")
        .format("h A")}`,
      "New Date": newStartDate
        ? dayjs(newStartDate).tz("America/New_York").toISOString()
        : "No new Date",
      "New Start time - End time": newStartDate
        ? `${dayjs(newStartDate)
            .tz("America/New_York")
            .format("h A")} - ${dayjs(newEndDate)
            .tz("America/New_York")
            .format("h A")}`
        : "No new date",
      Project: day.scheduleName.split(" - ")[0],
      "Schedule Name": day.scheduleName.split(" - ")[1],
      Day: `${day.day} of ${
        data.allSchedules.find((el) => el.scheduleId === day.scheduleId)
          .scheduleDays.length
      }`,
      Type: day.day === 1 ? "Adding" : "Returning",
      "Total Schedules": data.allSchedules.filter(
        (el) => el.projectId === day.projectId
      ).length,
      "Project Executive": day.projectExecutive,
      "Project Manager": data.projectExecutives
        ?.find(
          (el) => `${el.firstName} ${el.lastName}` === day.projectExecutive
        )
        ?.projectManagers.join(" , "),
      "Scope Of Work": day.scopeOfWork?.join(", "),
      "Type Of Work": day.typeOfWork,
    });
  }
  const hideLoading = message.loading(
    "File is being processed. Please wait...",
    0
  );
  await axios({
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    data: JSON.stringify({
      massChanges: dataToBeSent,
      apiKey: "7a0cd11d-28b0-42b2-8a02-b8ef2f092b08",
    }),
  })
    .then((response) => {
      hideLoading();
      FileSaver.saveAs(response.data, fileName);
      message.success("Export successful.");
    })
    .catch((err) => {
      hideLoading();
      console.error(err);
      message.error("Something went wrong...");
    });
};
