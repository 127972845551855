import { useContext, useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  Polygon,
  CircleF,
  OverlayViewF,
} from "@react-google-maps/api";

import { MAP_THEME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { loadLivePreference } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { getCoordinatesAndZip } from "src/components/SidebarPages/Fleet/utils";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import { ProjectMatchContext } from "../../data";
import { getRandomColor } from "src/components/SidebarPages/utils";
import { Marker } from "src/components/commonComponents";

function MatchMap() {
  const { mapLoaded, selectedViewData, selectedId, matchedData } =
    useContext(ProjectMatchContext);
  const [position, setPosition] = useState();
  const colorRef = useRef();
  /** @type {React.MutableRefObject<google.maps.Map>} */
  const mapRef = useRef(null);

  useEffect(() => {
    colorRef.current = getRandomColor();

    if (
      !selectedId ||
      !matchedData?.[selectedViewData?.["address"]]?.[selectedId]
    ) {
      setPosition(undefined);
      return;
    }

    const item = matchedData[selectedViewData["address"]][selectedId]["item"];
    const bounds = new google.maps.LatLngBounds();

    if (!item.position) {
      getCoordinatesAndZip(item.address)
        .then(({ coordinates }) => {
          setPosition(coordinates);
          bounds.extend(coordinates);
        })
        .catch((err) => {
          console.log("Error could not get position: ", err);
        });
    } else {
      setPosition(item.position);
      bounds.extend(item.position);
    }

    for (const fenceInfo of item.geoFenceInfo) {
      if (fenceInfo["type"] === "Polygon") {
        for (const info of fenceInfo["geoFenceInfo"]) {
          bounds.extend(info);
        }
      }
    }

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  }, [selectedId, matchedData, selectedViewData]);

  const item =
    matchedData?.[selectedViewData?.["address"]]?.[selectedId]?.["item"];

  return (
    <div className="project-match-card">
      <div className="project-match-card-header">Project Location</div>
      {mapLoaded && (
        <GoogleMap
          {...{
            zoom: 16,
            mapContainerClassName: "match-map project-match-body",
            id: "match-map",
            onLoad(e) {
              mapRef.current = e;
            },
            center: position || NYC_CENTER,
            options: {
              disableDefaultUI: true,
              styles: MAP_THEME[loadLivePreference("mapStyle")],
              mapTypeId: loadLivePreference("mapType"),
              fullscreenControl: true,
              clickableIcons: false,
              rotateControl: true,
              fullscreenControlOptions: {
                position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
              },
              streetViewControl: true,
              streetViewControlOptions: {
                position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
              },
            },
          }}
        >
          {position ? (
            <OverlayViewF mapPaneName="overlayMouseTarget" position={position}>
              <Marker
                color={colorRef.current}
                onClick={() => {}}
                lat={position.lat}
                lng={position.lng}
                address={selectedViewData.address}
              />
            </OverlayViewF>
          ) : null}
          {item?.geoFenceInfo?.length
            ? item.geoFenceInfo.map((e, index) => {
                return e.type === "Polygon" ? (
                  <Polygon
                    options={{
                      editable: false,
                      draggable: false,
                      strokeColor: "#d99f00",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      fillColor: "#d99f00",
                      fillOpacity: 0.35,
                    }}
                    key={`shape-${index}`}
                    paths={e.geoFenceInfo}
                  />
                ) : (
                  <CircleF
                    center={e?.geoFenceInfo?.[0]?.["center"]}
                    radius={e?.geoFenceInfo?.[0]?.["radius"]}
                  />
                );
              })
            : null}
        </GoogleMap>
      )}
    </div>
  );
}

export default MatchMap;
