function hasUploadedImages(
  item,
  userConfiguration,
  dayId,
  service,
  elevationIndex
) {
  const { typeOfProgress = "", progressByDay = [], breakdownValue = [] } = item;
  const userId = userConfiguration?.cognitoUserId;
  const breakdowns = breakdownValue?.map((breakdown) => {
    return {
      ...breakdown,
      files: breakdown?.progressByDay?.[0]?.["files"]?.[userId] || [],
    };
  });

  const hoistFloor =
    service?.serviceOptions?.[elevationIndex]?.jumps
      ?.find((jump) => jump?.name === item?.jump)
      ?.progressByDay?.find((el) => el?.day === dayId)
      ?.progress?.find((floor) => floor?.floor === item?.floor) || {};
  let toReturn = false;
  const dayWeEditing = progressByDay?.find(
    (progressDay) => progressDay?.day === dayId
  );
  if (typeOfProgress === "breakdown") {
    toReturn = breakdowns?.some((breakdown) => {
      return breakdown?.files?.length > 0;
    });
  } else if (service?.isHoist) {
    toReturn = hoistFloor?.files?.[userId]?.length > 0;
  } else {
    if (dayWeEditing?.files?.[userId]?.length > 0) {
      toReturn = true;
    }
  }
  return toReturn;
}

export default hasUploadedImages;
