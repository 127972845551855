/**
 *
 * @param {Array} safety - the safety data fetched from the server
 * @param {Object} parsedRequest - the parsed request object
 * @param {Function} dispatchRequestData - the pointer to the dispatch function of the request reducer
 */
const safetyOptionsPopulate = ({
  safety = [],
  parsedRequest = null,
  dispatchRequestData = () => {},
}) => {
  /**
   * If there is no safety data, we return an empty array for safety options
   */
  if (!safety?.length) {
    return dispatchRequestData({
      type: "SAFETY_OPTIONS_POPULATE_POPULATE",
      payload: [],
    });
  }

  /**
   * We check if the parsed request has a claimCategory, inspectionCategory or incidentCategory
   * and then we filter the safety data based on the category
   */
  if (parsedRequest?.hasOwnProperty("claimCategory")) {
    return dispatchRequestData({
      type: "SAFETY_OPTIONS_POPULATE",
      payload:
        safety?.filter(
          (el) => el?.safetyApplicationCategory === parsedRequest?.claimCategory
        ) || [],
    });
  } else if (parsedRequest?.hasOwnProperty("inspectionCategory")) {
    return dispatchRequestData({
      type: "SAFETY_OPTIONS_POPULATE",
      payload:
        safety?.filter(
          (el) =>
            el?.safetyApplicationCategory === parsedRequest?.inspectionCategory
        ) || [],
    });
  } else if (parsedRequest?.hasOwnProperty("incidentCategory")) {
    return dispatchRequestData({
      type: "SAFETY_OPTIONS_POPULATE",
      payload:
        safety?.filter(
          (el) =>
            el?.safetyApplicationCategory === parsedRequest?.incidentCategory
        ) || [],
    });
  }
};

export default safetyOptionsPopulate;
