import moment from "moment";
import { isWithin24hrs } from "../../../../../utils";
import { filter } from "lodash";

const getAll = ({
  notifications = [],
  authenticatedUser = {},
  notificationSettings = [],
  filterBy = null,
}) => {
  const processedNotifications = notifications
    .map((notificationBody, key) => {
      if (
        !notificationBody ||
        !notificationBody.notificationObject ||
        !notificationBody.cognitoUserId
      ) {
        return null;
      }

      const {
        notificationId,
        notificationObject: { topic, action, actionData, id } = {},
        cognitoUserId: { [authenticatedUser?.sub]: notificationStatus } = {},
        createdAt,
        isPinned,
      } = notificationBody;

      const currNotifSetting = notificationSettings.find(
        (notifSetting) => notifSetting?.topicId === topic
      );

      const currTemplate = currNotifSetting?.templates?.find(
        ({ templateAction }) => templateAction === action
      );

      return {
        notificationBody,
        id: notificationId,
        seen: notificationStatus?.hasBeenSeen,
        hidden: notificationStatus?.hasBeenHidden,
        category: currNotifSetting?.topicName,
        topic,
        template: currTemplate?.templateContent?.body,
        date: createdAt ? moment(createdAt).fromNow() : "Empty Created At!",
        url: currTemplate?.templateContent?.uri?.replace("{id}", id) || false,
        action,
        status: isWithin24hrs(createdAt)
          ? notificationStatus?.hasBeenSeen
            ? "old"
            : "new"
          : "old",
        createdAt: createdAt || 0,
        changes: actionData?.[0],
        priority: currTemplate?.templateContent?.priority,
        description: currTemplate?.templateContent?.description,
        isPinned: isPinned || false,
      };
    })
    .filter(Boolean)
    .sort((a, b) => {
      if (a.isPinned && !b.isPinned) return -1;
      if (!a.isPinned && b.isPinned) return 1;
      return b.createdAt - a.createdAt;
    })
    .filter((notification) => {
      const topics = ["important", "critical", "info"];
      if (topics.includes(filterBy)) {
        return notification.priority.toLowerCase() === filterBy;
      }
      return true;
    });

  const unreadLength = processedNotifications.filter(
    (notification) => !notification.seen
  ).length;

  return {
    notifications: processedNotifications,
    unreadLength,
  };
};

export default getAll;
