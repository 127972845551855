import { Tick } from "../src";
import { MondayButton, WarningModal } from "../../../../../commonComponents";
import { WarningTriangle } from "../../../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";

export const DeleteConfirmModal = ({
  isModalOpen,
  setIsModalOpen,
  modalType,
  onCancel,
  onConfirm,
  isDarkMode,
}) => {
  return (
    <WarningModal
      visible={isModalOpen}
      setVisible={setIsModalOpen}
      title={`Delete ${modalType}`}
      closable={true}
      className="logout-warning-modal"
      darkMode={isDarkMode}
    >
      <div className="logout-modal-body">
        <span>
          <WarningTriangle />
        </span>
        <p style={{ textAlign: "center" }}>
          {`Are you sure you want to delete this ${modalType}?`}
        </p>
        <div className="buttons">
          <MondayButton
            onClick={onCancel}
            Icon={<XIcon />}
            className="mondayButtonRed"
          >
            No
          </MondayButton>
          <MondayButton
            onClick={onConfirm}
            Icon={<Tick width={17} height={17} />}
          >
            Yes
          </MondayButton>
        </div>
      </div>
    </WarningModal>
  );
};
