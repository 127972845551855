import { callGmailAPI } from "../../../../../../../../../SidebarPages/Communication/functions";

async function sendResetPassEmail(user) {
  return await callGmailAPI("sendEmail", {
    to: [user.userName],
    subject: "Password Changed",
    body: `<p>Hello ${user.nameOfUser},</p>
      <br/>
      <p>We received a request from admin to reset the password for your account.</p>
      <p>To reset your password, click on the link below:</p>
      <p><a href="http://portal.corescaffold.com">Portal</a>.</p>
      <br/>
      <p>Best Regards,</p>
      <p>Core Team</p>`,
    attachments: [],
    category: "error",
    authData: false,
  })
    .then(() => {
      console.log("Email sent");
    })
    .catch((error) => {
      console.error("Error sending email", error);
    });
}

export default sendResetPassEmail;
