import { fetchData, lazyFetch } from "../../../../../utils";

const schedulingApiData = async () =>
  fetchData({
    endpoint: "scheduling",
    queryStringParams: {
      queryStringParameters: {
        keysToInclude: JSON.stringify([
          "scheduleId",
          "scheduleAddress",
          "scheduleName",
          "scheduleDays",
          "createdAt",
          "teamsConfiguration",
        ]),
      },
    },
  });
const projectApiData = async () =>
  fetchData({
    endpoint: "projects",
    queryStringParams: {
      queryStringParameters: {
        keysToInclude: JSON.stringify([
          "projectId",
          "projectName",
          "accountId",
          "accountName",
          "createdAt",
          "teamsConfiguration",
        ]),
      },
    },
  });

const crewsApiData = async () =>
  fetchData({
    endpoint: "crews",
    queryStringParams: {
      queryStringParameters: {
        keysToInclude: [
          JSON.stringify([
            "crewId",
            "crewName",
            "foreman",
            "members",
            "createdAt",
          ]),
        ],
        filters: JSON.stringify([
          {
            conditions: [
              {
                columnType: "string",
                column: "crewStatus",
                formula: "is",
                value: "Active",
              },
            ],
          },
        ]),
      },
    },
  });
// const crewsApiData = async () =>
//   lazyFetch({
//     tableName: "crews",
//     listOfKeys: ["crewId", "crewName", "foreman", "members"],
//     filterKey: "crewStatus",
//     filterValue: "Active",
//   });

const safetyApiData = {
  "Property Damage": {
    crews: crewsApiData,

    projects: projectApiData,

    scheduling: schedulingApiData,
  },

  "Personal Injury": {
    crews: crewsApiData,

    projects: projectApiData,

    scheduling: schedulingApiData,
  },

  "Vehicle Damage": {
    fleet: async () =>
      await lazyFetch({
        tableName: "fleet",
        listOfKeys: ["fleetName", "fleetId", "licensePlate"],
        filterKey: "fleetStatus",
        filterValue: "Active",
      }),

    drivers: async () =>
      await lazyFetch({
        tableName: "drivers",
        listOfKeys: ["driverName", "driverId"],
        filterKey: "driverStatus",
        filterValue: "Ready to Work",
      }),

    projects: projectApiData,

    scheduling: schedulingApiData,
  },

  "Other Trade Incident": {
    projects: projectApiData,

    scheduling: schedulingApiData,
  },
};

export default safetyApiData;
