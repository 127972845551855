import { forceToNumber } from "../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const getTotalServicesPrice = (services) => {
  // Case 1: Service is Other Scope
  let totalPrice = 0;
  try {
    if (Array.isArray(services) && services?.length > 0) {
      for (const service of services) {
        if (service?.isScope === true) {
          // console.log("servicePrice", service);
          totalPrice += getOtherScopePrice(service);
        } else {
          // console.log("servicePrice", service);
          totalPrice += getServicePriceInclAddons(service);
        }
      }
    }
    return totalPrice;
  } catch (error) {
    // console.log("there was an error calculation", error);
    return totalPrice;
  } finally {
    return totalPrice;
  }
};

export const getOtherScopePrice = (otherScopeService) => {
  let otherScopePrice = 0;

  if (Array.isArray(otherScopeService?.serviceOptions)) {
    const serviceOptions = otherScopeService?.serviceOptions;
    otherScopePrice += forceToNumber(
      [
        ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
          items.flatMap((el) => {
            let taxRate = otherScopeService?.isTaxable
              ? forceToNumber(otherScopeService?.taxRate)
              : 0;
            let temp = forceToNumber(el?.price || el?.Price);
            let tax = forceToNumber(taxRate) * temp;
            return temp + tax;
          })
        ),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0)
    );
    // otherScopePrice += forceToNumber(otherScopeService?.totalities?.totalPrice);
    // if (otherScopeService?.taxRate > 0) {
    //   otherScopePrice += forceToNumber(
    //     otherScopePrice * otherScopeService?.taxRate
    //   );
    // }
  }
  return otherScopePrice;
};

export const getServicePriceInclAddons = (service) => {
  const { serviceAddons = [], serviceOptions = [] } = service || {};
  let additional = getAdditionalServiceRentPrice(service);
  let elevationAddons = getElevationAddons(service);

  const newServiceOptions = Array.isArray(serviceOptions[0])
    ? serviceOptions[0]
    : serviceOptions;

  const serviceTotalPrice = [
    ...newServiceOptions?.flatMap?.(({ items }) =>
      items
        ?.filter((el) => el?.approved === true)
        .flatMap(({ totalPrice }) => +totalPrice || 0)
    ),
    ...newServiceOptions?.flatMap?.(({ totalPrice }) => +totalPrice || 0),
    ...serviceAddons?.map(({ totalPrice }) => +totalPrice || 0),
  ]
    ?.filter(Boolean)
    ?.reduce((acc, curr) => (acc += curr), 0);

  return (
    forceToNumber(serviceTotalPrice) +
    forceToNumber(additional) +
    forceToNumber(elevationAddons)
  );
};

export const getAdditionalServiceRentPrice = (service) => {
  let additionalRentCost = 0;
  if (service?.additionalRentalTerms?.newPrice > 0) {
    additionalRentCost += forceToNumber(
      service?.additionalRentalTerms?.newPrice
    );
  }
  return additionalRentCost;
};

export const getServiceLegacyPrice = (service) => {
  if (service?.isScope === true) {
    return getOtherScopePrice(service);
  }
  return getServicePriceInclAddons(service);
};

export const getElevationAddons = (service) => {
  let elevationAddons = 0;
  service?.serviceOptions?.[0]?.forEach((elevation) => {
    if (Array.isArray(elevation?.elevationAddons)) {
      elevation?.elevationAddons?.forEach((addon) => {
        elevationAddons += forceToNumber(addon?.totalPrice);
      });
    }
  });
  return forceToNumber(elevationAddons);
};

// TODO: HOIST LOGIC
// [
//     ...serviceOptions?.[0]?.flatMap?.(
//       ({ totalPrice }) => +totalPrice
//     ),
//     ...serviceAddons.map(({ totalPrice }) => +totalPrice),
//   ]
