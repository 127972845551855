import React, { useMemo } from "react";
import {
  PaginationButtons,
  WithTooltip,
} from "../../../../../../../commonComponents";
import "./CustomFooter.scss";

function CustomFooter({
  labelsKeys,
  dataSrc,
  style = {},
  color,
  fontSize,
  type,
  isLoading,
  pageNumbers,
  fetchAllData,
  goToPageNumber,
  isBackButtonDisabled,
  isNextButtonDisabled,
  handleBackButtonClick,
  handleNextButtonClick,
  suppressPaginationPanel,
  isDarkMode,
  tooltipCategory,
}) {
  const footerData = useMemo(() => {
    if (!!labelsKeys && !!dataSrc) {
      let result = [];
      labelsKeys?.forEach((label) => {
        if (dataSrc.some((el) => el.hasOwnProperty(label?.key))) {
          getMathfulAction(
            label?.mathAction,
            label,
            dataSrc,
            result,
            label?.type
          );
        }
      });
      return result;
    }
  }, [labelsKeys, dataSrc]);

  return (
    <div
      className={`${
        type === "base"
          ? "application-footer_dynamicPage"
          : "application-footer"
      } ${isDarkMode && "application-footer-dark"}`}
      style={style}
    >
      {!!footerData &&
        Array.isArray(footerData) &&
        footerData?.map((footer, index) => (
          <div className="footer-cont" key={index}>
            <span className="footer-name" style={{ color, fontSize }}>
              {footer?.name}:
            </span>
            <WithTooltip
              tooltipCategory={tooltipCategory}
              tooltipKey={footer?.tooltipKey}
            >
              <span className="footer-val" style={{ color, fontSize }}>
                {footer?.value}
              </span>
            </WithTooltip>
          </div>
        ))}
      {suppressPaginationPanel && (
        <PaginationButtons
          {...{
            isLoading,
            pageNumbers,
            fetchAllData,
            goToPageNumber,
            isBackButtonDisabled,
            isNextButtonDisabled,
            handleBackButtonClick,
            handleNextButtonClick,
          }}
        />
      )}
    </div>
  );
}

export default CustomFooter;

const getMathfulAction = (mathAction, label, dataSrc, result, type) => {
  if (mathAction === "sum") {
    let sum = dataSrc?.reduce((acc, cur) => {
      return acc + cur[label?.key] || 0;
    }, 0);
    result.push({
      key: label?.key,
      name: label?.name,
      value: type !== "currency" ? sum : formatCurrency(sum),
      tooltipKey: label?.tooltipKey,
    });
  }
};

const formatCurrency = (amount, currency = "USD") => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(
    amount
  );
};
