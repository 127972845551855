import {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useCallback,
  ForwardedRef,
} from "react";
import { Card } from "antd";

import { ChartData, ChartTypes } from "../../types";
import { LayoutChart } from "src/components/SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportsGridLayout/components";

import "./DashboardChartCard.scss";

type ChartCardProps = {
  data: ChartData;
  className?: string;
  style?: { [key: string]: string };
  onMouseUp?: () => any;
  onTouchEnd?: () => any;
  onMouseDown?: () => any;
  children?: JSX.Element;
  cardKey?: string;
  isDarkMode: boolean;
};

const DashboardChartCard = forwardRef(
  (
    {
      data,
      style,
      cardKey,
      children,
      onMouseUp,
      className,
      isDarkMode,
      onTouchEnd,
      onMouseDown,
    }: ChartCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { cardTitle, action, chartType: type, ...chartData } = data;

    const [chartType, setChartType] = useState<ChartTypes>(type || "Line");

    const containerRef = useRef<HTMLDivElement>(null);

    const getActualContainerWidth = useCallback(() => {
      const cardBody = containerRef.current;

      if (chartType === "Pie" || chartType === "Doughnut") {
        if (cardBody) {
          let width = cardBody.clientWidth;
          let chartAvailableHeight = cardBody.clientHeight - 56;

          if (width > chartAvailableHeight) {
            return chartAvailableHeight - 20;
          }
        }
      } else {
        if (cardBody) {
          const totalsHeight = 56;
          const containerWidth = cardBody.clientWidth;
          const containerHeight = cardBody.clientHeight;

          const chartAvailableHeight = containerHeight - totalsHeight;

          const maxWidth = containerHeight * 1.6923564356;

          if (containerWidth > 2 * chartAvailableHeight) {
            return maxWidth - 40;
          } else {
            return containerWidth;
          }
        }
      }

      return undefined;
    }, [chartType]);

    const chart = useMemo(() => {
      return LayoutChart[chartType]({ chartData });
    }, [chartType, JSON.stringify(chartData)]);

    return (
      <div
        {...{
          ref,
          style,
          onMouseUp,
          onTouchEnd,
          onMouseDown,
          key: cardKey,
          className: `dashboard-chart-card-container ${
            isDarkMode ? "chart-card-container-dark" : ""
          } ${className}`,
        }}
      >
        <Card
          {...{
            title: data.cardTitle,
            extra: data?.action ?? undefined,
            size: "default",
            className: "chart-card-container",
            ref: containerRef,
            bordered: false,
          }}
        >
          <div
            className="chart-container"
            style={{ width: getActualContainerWidth() }}
          >
            {chart}
          </div>
        </Card>
        {children}
      </div>
    );
  }
);

export default DashboardChartCard;
