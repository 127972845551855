import types from "../../types";

const initialState = {
  userGroups: undefined,
};

const userGroupsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_USER_GROUPS:
      return {
        ...state,
        userGroups: payload,
      };
    case types.SET_USER_GROUPS_BY_ID:
      const { groupId, groupConfigs } = payload;

      console.log({ groupId, groupConfigs });

      const updatedUserGroups = state.userGroups.map((group) => {
        if (group.groupId === groupId) {
          return {
            ...group,
            groupConfigs,
          };
        }
        return group;
      });

      console.log({ updatedUserGroups });

      return {
        ...state,
        userGroups: updatedUserGroups,
      };

    default:
      return state;
  }
};

export default userGroupsReducer;
