import MoreServices from "./MoreServices";
import ServiceDescription from "./ServiceDescription";
import { calculateTotalPriceForService } from "../../../../../../Accounting/components/utilities";

const colors = ["#30973A", "#EEB522", "#4662F2"];

export const EstimationDescription = ({ groups, selectedGroup }) => {
  const {
    information: details,
    data,
    label,
  } = groups.find(({ id }) => id === selectedGroup);

  const getTotal = (service) => {
    const { amount, taxAmount } = calculateTotalPriceForService(
      data?.find(({ label }) => label === service),
      "estimations"
    );
    return amount + taxAmount;
  };

  const allServices = details.map((service) => ({
    ...service,
    label: service.service,
    total: getTotal(service.service),
  }));

  const services = allServices.slice(0, 2);

  const moreServices = allServices.length - services.length;

  return (
    <MoreServices
      hasOtherServices={moreServices}
      services={allServices}
      title={label}
    >
      <div className="GroupContainer">
        {services?.map((item, key) => (
          <ServiceDescription
            key={key}
            service={item.service}
            elevations={item.elevations}
            isTaxable={item.isTaxable}
            showDivider={key === 0 && services.length > 1}
            color={colors[key % colors.length]}
            total={item.total}
          />
        ))}
      </div>
    </MoreServices>
  );
};
