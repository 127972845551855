export function currencyFormater(value, includeDollarSign = true) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  if (isNaN(value) || value === null) {
    return "Invalid input";
  }

  return (
    (includeDollarSign ? "$" : "") +
    value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
}
