import XLSX from "sheetjs-style";

export const generateExcelAndUploadToDrive = async (
  sheetName,
  docDefinition,
  driveRequest,
  driveFolderId
) => {
  try {
    const modifiedData = [];
    let columns = [];

    docDefinition.forEach((row, rowIndex) => {
      let temp = {};
      if (rowIndex > 0) {
        row.forEach((item, itemIndex) => {
          temp[columns[itemIndex]] = item.text || "";
        });
        modifiedData.push(temp);
      } else {
        columns = row.map(({ text }) => text);
      }
    });

    // Create a worksheet and workbook from the row data
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);

    // Style for header cells
    const headerCellStyle = {
      font: {
        bold: true,
        sz: 14,
      },
      border: {
        bottom: { style: "thin", color: { rgb: "000000" } }, // Add bottom border
        right: { style: "thin", color: { rgb: "000000" } }, // Add right border
      },
    };

    // Apply style to header cells
    columns.forEach((header, headerIndex) => {
      const headerCell = XLSX.utils.encode_cell({ r: 0, c: headerIndex });
      worksheet[headerCell].s = headerCellStyle;
      worksheet[headerCell].v = header;
    });

    // Style for cells
    const cellStyle = {
      border: {
        bottom: { style: "thin", color: { rgb: "000000" } }, // Add bottom border
        right: { style: "thin", color: { rgb: "000000" } }, // Add right border
      },
    };

    // Apply style to cells
    modifiedData.forEach((row, rowIndex) => {
      columns.forEach((column, columnIndex) => {
        const cell = XLSX.utils.encode_cell({
          r: rowIndex + 1,
          c: columnIndex,
        });
        worksheet[cell].s = cellStyle;
        worksheet[cell].v = row[column];
      });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Convert the workbook to an Excel binary array
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the array buffer to a blob with the correct MIME type
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Upload the Excel file to Google Drive
    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name: `${sheetName}.xlsx`,
        parents: [driveFolderId],
      }
    );

    // Extract and log information about the uploaded file
    const { id, name } = await uploadResponse.json();

    // Update state or perform any necessary actions with the file ID
    return { id, name };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};
