import uniq from "lodash/uniq";
import {
  allServicesTogether,
  calculateInvoiceStatus,
  categoriesName,
  getMonthData,
  getSums,
  groupBy,
} from "../../../../Dashboard/logicDataFunctions/calculatingFunctions";
// import { months } from "../../../../Dashboard/addWidgetsModalData"
import { formatCurrency, getMonthsTimestamps } from "../../../../utils";
//contains the data for each category (leads,opportunities...) and subcategory (leadStatus,leadAddress...)
export const LineFilterFunctions = ({
  programFields,
  updatedDynamicStates,
  dynamicStates,
  id,
  clear = false,
}) => {
  const url = window.location.pathname.split("/")[1];

  const dataToFilter = clear ? dynamicStates[id] : updatedDynamicStates[id];

  return {
    Applications: () => {
      const applicationData = programFields
        ?.find((a) => a?.fieldName === "Status of Requisition")
        ?.fieldOptions?.map(({ value }) =>
          dataToFilter?.filter((a) => a?.applicationStatus === value)
        );

      const applicationTotalAmountData = getMonthData(
        dataToFilter,
        "applicationId"
      )?.map(({ id: ids }) => {
        return ids?.map((el) => {
          return dataToFilter?.find((a) => {
            return a.applicationId === el;
          });
        });
      });
      return {
        applicationStatus: () => [
          {
            data: applicationData?.map((e) => e?.length),
            custom: groupBy(applicationData, "projectName")?.map(
              (el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Current Payment": formatCurrency(
                        getSums({
                          data: applicationData,
                          key: "totalAmount",
                        })[index]
                      ),
                      "Total Retainage": formatCurrency(
                        getSums({
                          data: applicationData,
                          key: "totalRetainage",
                        })[index]
                      ),
                      "Total Rental With Tax": formatCurrency(
                        getSums({
                          data: applicationData,
                          key: "totalRentalWithTax",
                        })[index]
                      ),
                    },
                  },
                  ...Object?.entries(el)?.map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Current Payment": formatCurrency(
                          getSums({ data: value, key: "totalAmount" })
                        ),
                        "Total Retainage": formatCurrency(
                          getSums({ data: value, key: "totalRetainage" })
                        ),
                        "Total Rental With Tax": formatCurrency(
                          getSums({ data: value, key: "totalRentalWithTax" })
                        ),
                      },
                    };
                  }),
                ];
              }
            ),
          },
        ],
        totalAmount: () => [
          {
            data: getSums({
              data: applicationTotalAmountData,
              key: "totalAmount",
            })?.map((el) => el?.toFixed(2)),
            custom:
              !!applicationTotalAmountData &&
              groupBy(applicationTotalAmountData, "projectName")?.map(
                (el, index) => {
                  return Object.entries(el).map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Current Payment": formatCurrency(
                          getSums({ data: value, key: "totalAmount" })
                        ),
                        "Total Retainage": formatCurrency(
                          getSums({ data: value, key: "totalRetainage" })
                        ),
                        "Total Rental With Tax": formatCurrency(
                          getSums({ data: value, key: "totalRentalWithTax" })
                        ),
                      },
                    };
                  });
                }
              ),
          },
          {
            data: getSums({
              data: applicationTotalAmountData,
              key: "totalRetainage",
            })?.map((el) => el?.toFixed(2)),
          },
          {
            data: getSums({
              data: applicationTotalAmountData,
              key: "totalRentalWithTax",
            })?.map((el) => el?.toFixed(2)),
          },
        ],
      };
    }, //applications
    Rentals: () => {
      const rentalsData = programFields
        ?.find((a) => a?.fieldName === "Status of Rentals")
        ?.fieldOptions?.map(({ statusName }) =>
          dataToFilter?.filter((a) => a?.rentalStatus === statusName)
        );

      const rentalsTotalAmountData =
        url === "accounting" &&
        !!dataToFilter?.[0]?.rentalId &&
        allServicesTogether(dataToFilter);
      return {
        rentalStatus: () => [
          {
            data: rentalsData.map((e) => e?.length),
            custom:
              !!rentalsData[0] &&
              groupBy(rentalsData, "projectAddress").map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Total Price": formatCurrency(
                        getSums({ data: rentalsData, key: "totalPrice" })[index]
                      ),
                      "Total Applied Rent": formatCurrency(
                        getSums({
                          data: rentalsData,
                          key: "totalAppliedRent",
                        })[index]
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: rentalsData, key: "totalTax" })[index]
                      ),
                      "Retainage Amount": formatCurrency(
                        getSums({
                          data: rentalsData,
                          key: "retainageAmount",
                        })[index]
                      ),
                    },
                  },
                  ...Object?.entries(el)?.map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Total Price": formatCurrency(
                          getSums({ data: value, key: "totalPrice" })
                        ),
                        "Total Applied Rent": formatCurrency(
                          getSums({ data: value, key: "totalAppliedRent" })
                        ),
                        "Total Tax": formatCurrency(
                          getSums({ data: value, key: "totalTax" })
                        ),
                        "Retainage Amount": formatCurrency(
                          getSums({ data: value, key: "retainageAmount" })
                        ),
                      },
                    };
                  }),
                ];
              }),
          },
        ],
        totalPrice: () => [
          {
            data: Object.values(rentalsTotalAmountData).map((a) =>
              a?.totalPrice?.toFixed(2)
            ),
            custom: Object?.entries(rentalsTotalAmountData)?.map(
              ([key, value]) =>
                Object.entries(value?.projects)?.map(([key, value]) => {
                  return {
                    [key]: {
                      ...(value || {}),
                    },
                  };
                })
            ),
          },
          {
            data: Object.values(rentalsTotalAmountData).map((a) =>
              a?.appliedRent?.toFixed(2)
            ),
          },
          {
            data: Object.values(rentalsTotalAmountData).map((a) =>
              a?.retainage?.toFixed(2)
            ),
          },
          {
            data: Object.values(rentalsTotalAmountData).map((a) =>
              a?.totalTax?.toFixed(2)
            ),
          },
        ],
      };
    },
    Charges: () => {
      const chargesData = programFields
        ?.find((a) => a?.fieldName === "Status of Charges")
        ?.fieldOptions?.map(({ statusName }) =>
          dataToFilter?.filter((a) => a?.categoryFrom === statusName)
        );

      const data = getMonthData(dataToFilter, "chargeId")?.map(
        ({ id: ids }) => {
          return ids?.map((el) => {
            return dataToFilter?.find(({ chargeId }) => chargeId === el);
          });
        }
      );
      return {
        categoryFrom: () => [
          {
            data: chargesData?.map((e) => e?.length),
            custom: groupBy(chargesData, "projectAddress").map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Charge Amount With Tax": formatCurrency(
                      getSums({ data: chargesData, key: "chargeAmount" })[
                        index
                      ] || 0
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: chargesData, key: "taxAmount" })[index] ||
                        0
                    ),
                    "Charge Amount Without Tax": formatCurrency(
                      (getSums({ data: chargesData, key: "chargeAmount" })[
                        index
                      ] || 0) -
                        (getSums({ data: chargesData, key: "taxAmount" })[
                          index
                        ] || 0)
                    ),
                  },
                },
                ...Object.entries(el).map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Charge Amount With Tax": formatCurrency(
                        getSums({ data: value, key: "chargeAmount" })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: value, key: "taxAmount" })
                      ),
                      "Charge Amount Without Tax": formatCurrency(
                        getSums({ data: value, key: "chargeAmount" }) -
                          getSums({ data: value, key: "taxAmount" })
                      ),
                    },
                  };
                }),
              ];
            }),
          },
        ],
        chargeAmount: () => [
          {
            data: !!dataToFilter
              ? data?.map((el) => {
                  return el
                    ?.reduce((acc, curr) => {
                      return acc + (curr?.chargeAmount || 0);
                    }, 0)
                    ?.toFixed(2);
                })
              : [undefined],
            custom: groupBy(data, "projectAddress")?.map((el, index) => {
              return Object?.entries(el)?.map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Charge Amount With Tax": formatCurrency(
                      getSums({ data: value, key: "chargeAmount" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "taxAmount" })
                    ),
                    "Charge Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "chargeAmount" }) -
                        getSums({ data: value, key: "taxAmount" })
                    ),
                  },
                };
              });
            }),
          },
          {
            data: !!dataToFilter
              ? data?.map((el) => {
                  return el
                    ?.reduce((acc, curr) => {
                      return acc + (curr?.taxAmount || 0);
                    }, 0)
                    ?.toFixed(2);
                })
              : [undefined],
          },
          {
            data: dataToFilter
              ? data?.map((el) => {
                  return el
                    ?.reduce((acc, curr) => {
                      return (
                        acc +
                        ((curr?.chargeAmount || 0) - (curr?.taxAmount || 0))
                      );
                    }, 0)
                    ?.toFixed(2);
                })
              : [undefined],
          },
        ],
      };
    },
    Invoices: () => {
      const invoicesData = programFields
        ?.find((a) => a?.fieldName === "Status of Invoices")
        ?.fieldOptions?.map(({ statusName }) =>
          calculateInvoiceStatus({
            table: dataToFilter,
            status: statusName,
          })
        );

      const data = getMonthData(dataToFilter, "invoiceId")?.map(
        ({ id: ids }) => {
          return ids?.map((el) => {
            return dataToFilter?.find(({ invoiceId }) => invoiceId === el);
          });
        }
      );
      return {
        invoiceStatus: () => [
          {
            data: invoicesData.map((e) => e?.length),
            custom: groupBy(invoicesData, "projectName")?.map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({
                        data: invoicesData,
                        key: "totalInvoiceAmount",
                      })[index] || 0
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: invoicesData, key: "subtotal" })[index] ||
                        0
                    ),
                    "Total Tax": formatCurrency(
                      (getSums({
                        data: invoicesData,
                        key: "totalInvoiceAmount",
                      })[index] || 0) -
                        (getSums({ data: invoicesData, key: "subtotal" })[
                          index
                        ] || 0)
                    ),
                  },
                },
                ...Object.entries(el).map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Invoice Amount With Tax": formatCurrency(
                        getSums({ data: value, key: "totalInvoiceAmount" })
                      ),
                      "Invoice Amount Without Tax": formatCurrency(
                        getSums({ data: value, key: "subtotal" })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: value, key: "totalInvoiceAmount" }) -
                          getSums({ data: value, key: "subtotal" })
                      ),
                    },
                  };
                }),
              ];
            }),
          },
        ],
        totalInvoiceAmount: () => [
          {
            data: !!dataToFilter
              ? data?.map((el) => {
                  return el
                    ?.reduce((acc, curr) => {
                      return acc + (parseFloat(curr?.totalInvoiceAmount) || 0);
                    }, 0)
                    ?.toFixed(2);
                })
              : [undefined],
            custom: groupBy(data, "projectName")?.map((el, index) => {
              return Object?.entries(el)?.map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" })
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "subtotal" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" }) -
                        getSums({ data: value, key: "subtotal" })
                    ),
                  },
                };
              });
            }),
          },
          {
            data: !!dataToFilter
              ? data?.map((el) => {
                  return el
                    ?.reduce((acc, curr) => {
                      return acc + (Number(curr?.subtotal) || 0);
                    }, 0)
                    ?.toFixed(2);
                })
              : [undefined],
          },
          {
            data: !!dataToFilter
              ? data?.map((el) => {
                  return el
                    ?.reduce((acc, curr) => {
                      return (
                        acc +
                        ((Number(curr?.totalInvoiceAmount) || 0) -
                          (Number(curr?.subtotal) || 0))
                      );
                    }, 0)
                    ?.toFixed(2);
                })
              : [undefined],
          },
        ],
      };
    },
  };
}; // BarCharti
