import { Modal } from "antd";
import { useReducer } from "react";
import { SelectCategoryToDrawGeofence, initialStates, reducer } from "./index";
import "./SelectCategoryToDrawGeofence.scss";
import { useSelector } from "react-redux";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";

const NewGeofenceShortcut = ({ open = false, setOpen = () => {} }) => {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <Modal
      width={state.categoryToDrawGeofence && state.selectedCatAdd ? 700 : 600}
      open={open}
      onCancel={() => setOpen("")}
      centered
      title="Create Geofence"
      footer={null}
      closeIcon={<XIcon />}
      className={`newGeofenceShortcut ${
        isDarkMode && "newGeofenceShortcut-dark"
      }`}
    >
      <SelectCategoryToDrawGeofence
        {...{ dispatch, state, setOpen, isDarkMode }}
      />
    </Modal>
  );
};

export default NewGeofenceShortcut;
