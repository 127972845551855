import { getDispatchLocationOptions } from "../../../../Header/forms/RequestFormModal/requestFormModalData";

export const generateInputFields = ({ schedules, projects }) => [
  {
    label: "Representative",
    onContextMenu: () => false,
    name: "hearingName",
    type: "text",
    placeholder: "Representative...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Hearing Address",
    onContextMenu: () => false,
    name: "hearingAddress",
    type: "select",
    placeholder: "Hearing Address...",
    className: "inputForm",
    customOptions: getDispatchLocationOptions(schedules, projects).filter(
      Boolean
    ),
    showSearch: true,
    required: true,
  },
  {
    label: "Hearing Date",
    onContextMenu: () => false,
    name: "hearingDate",
    type: "datepicker",
    placeholder: "Hearing Date...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Hearing Time",
    onContextMenu: () => false,
    name: "hearingTime",
    type: "timepicker",
    placeholder: "Hearing Time...",
    className: "inputForm",
    required: true,
  },
];
