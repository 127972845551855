import {
  useMemo,
  useState,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Popconfirm, Tooltip } from "antd";
import { MenuOutlined, ReloadOutlined } from "@ant-design/icons";

import { useResponsive } from "src/hooks";
import { FullScreenIcon } from "src/assets/icons";
import PayrollLiveContext from "../../PayrollLiveContext";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton, HoverButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { RightArrow, BackIcon } from "src/components/SidebarPages/BasePage/src";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import LastPunchOverview from "src/components/pages/PayrollLive/modals/LastPunchOverview";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./PayrollLiveControlPanel.scss";

interface Props {}

const PayrollLiveControlPanel = forwardRef((props: Props, ref) => {
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const {} = props;
  const {
    sidebarRef,
    setFilters,
    clearFilters,
    goFullScreen,
    clientConfigs,
    controlPanelForm,
    getEmployeeReports,
  } = useContext(PayrollLiveContext);

  const [fetchDisabled, setFetchDisabled] = useState<boolean>(false);
  const [lastPunchOverview, setLastPunchOverview] = useState<boolean>(false);

  const navigate = useNavigate();
  const { width } = useResponsive();

  function sidebarToggle() {
    if (sidebarRef?.current) {
      sidebarRef.current.toggleSidebar();
    }
  }

  function onTimeChange(time: Date, formItemName: string) {
    setFilters((prev) => ({
      ...prev,
      [formItemName]: !!time ? time.valueOf() : null,
    }));
  }

  function resetFields() {
    controlPanelForm.resetFields();
    resetTimeFilter();
    setFilters((prev) => ({
      ...prev,
      punchTimeEnd: null,
      punchTimeStart: null,
    }));
  }

  function resetTimeFilter() {
    controlPanelForm.setFieldValue("punchTimeEnd", null);
    controlPanelForm.setFieldValue("punchTimeStart", null);
    setFilters((prev) => ({
      ...prev,
      punchTimeEnd: null,
      punchTimeStart: null,
    }));
  }

  function updateReportData() {
    const button = document.getElementById("loading-button");
    setFetchDisabled(true);
    button.classList.add("animation");
    getEmployeeReports(true);
    setTimeout(() => {
      button.classList.remove("animation");
      setFetchDisabled(false);
    }, 15000);
  }

  function onLastPunchClose() {
    setLastPunchOverview(false);
    controlPanelForm.setFieldValue("selectedDate", dayjsNY().startOf("d"));
  }

  function disabledDates(current: Dayjs) {
    return (
      current &&
      parseInTz(current).startOf("d").valueOf() >
        dayjsNY().startOf("d").valueOf()
    );
  }

  function onDateChange(e: Dayjs) {
    if (
      parseInTz(e).startOf("d").valueOf() !== dayjsNY().startOf("d").valueOf()
    ) {
      setLastPunchOverview(true);
    }
  }

  const customOptions = useMemo(() => {
    controlPanelForm.setFieldValue(
      "clientCompany",
      clientConfigs.find((el) => el.clientName === "GMNY Construction")
        ?.configId
    );
    return clientConfigs.map((el, key) => ({
      key,
      value: el.configId,
      label: el.clientName,
    }));
  }, [clientConfigs]);

  const timeFilter = useMemo(() => {
    return (
      <Form form={controlPanelForm}>
        <div className="time-range">
          <InputComponent
            secondaryDarkMode
            form={controlPanelForm}
            type="customTimeInput"
            formItemName="punchTimeStart"
            onChange={(e: Date) => onTimeChange(e, "punchTimeStart")}
          />
          <RightArrow
            fill={darkMode ? "#fff" : "#323338"}
            height={13}
            width={13}
          />
          <InputComponent
            secondaryDarkMode
            form={controlPanelForm}
            type="customTimeInput"
            formItemName="punchTimeEnd"
            onChange={(e: Date) => onTimeChange(e, "punchTimeEnd")}
          />
          <button
            type="button"
            onClick={resetTimeFilter}
            className="clear-time-btn"
          >
            <XIcon height={10} width={10} />
          </button>
        </div>
      </Form>
    );
  }, [controlPanelForm, darkMode, setFilters]);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetFields,
      };
    },
    []
  );

  return (
    <section
      className={`payroll-live-control-panel ${
        darkMode ? "payroll-live-control-panel-dark" : ""
      }`}
    >
      <div className="title-section">
        {width > 1180 ? (
          <div className="menu-icon" onClick={sidebarToggle}>
            <MenuOutlined />
          </div>
        ) : null}
        <span className="title-text">Payroll Live</span>
      </div>
      <div className="control-section">
        <Form form={controlPanelForm} className="control-panel-form">
          <span className="today-date">
            <InputComponent
              type="datePicker"
              allowClear={false}
              formItemName="selectedDate"
              disabledDate={disabledDates}
              defaultValue={dayjsNY().startOf("d")}
              onChange={onDateChange}
            />
          </span>
          {width > 1400 ? (
            timeFilter
          ) : (
            <Popconfirm
              title={null}
              placement="bottom"
              description={timeFilter}
              rootClassName={`popup-filter ${
                darkMode ? "popup-filter-dark" : ""
              }`}
            >
              <HoverButton
                type="action"
                text="Filter"
                hasIcon={false}
                onClick={() => {}}
              />
            </Popconfirm>
          )}
          <HoverButton
            hasIcon={false}
            type="decline"
            onClick={() => {
              clearFilters();
              resetTimeFilter();
            }}
            text="Clear Filters"
          />
          <InputComponent
            type="select"
            allowClear={false}
            label={"Company: "}
            form={controlPanelForm}
            formItemName="clientCompany"
            customOptions={customOptions}
            placeholder="Chose Subcontractor..."
          />
        </Form>
        <div className="buttons-wrapper">
          {width > 1180 ? (
            <Tooltip title="Full Screen">
              <MondayButton
                className={`mondayButton${
                  darkMode ? "Blue" : "White"
                } full-screen-btn`}
                Icon={<FullScreenIcon />}
                onClick={() => {
                  goFullScreen();
                }}
              />
            </Tooltip>
          ) : null}
          <button
            className="loading-button"
            id="loading-button"
            disabled={fetchDisabled}
            onClick={updateReportData}
          >
            <div className="progress"></div>
            {width > 1180 ? <span>Get Latest Data</span> : <ReloadOutlined />}
          </button>
          <Tooltip title="Back to Payroll">
            <MondayButton
              className={`mondayButton${
                darkMode ? "Blue" : "White"
              } full-screen-btn`}
              hasIcon={false}
              onClick={() => navigate("/projectCost?tab=DEG")}
            >
              <BackIcon fill={darkMode ? "#fff" : "#231f20"} />
            </MondayButton>
          </Tooltip>
        </div>
      </div>
      {lastPunchOverview ? (
        <LastPunchOverview
          open={lastPunchOverview}
          onCancel={onLastPunchClose}
        />
      ) : null}
    </section>
  );
});

export default PayrollLiveControlPanel;
