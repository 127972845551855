import { useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { message } from "antd";

export const useUndoRedoState = (limit, updateData = () => {}) => {
  const [states, setStates] = useState([]); // Used to store history of all states
  const [index, setIndex] = useState(0); // Index of current state within `states`

  const itemToSaveActiveState = useMemo(() => states[index], [states, index]); // Current state

  const changeStateHandler = (value) => {
    // Use lodash isEqual to check for deep equality
    // If state has not changed, return to avoid triggering a re-render
    if (isEqual(itemToSaveActiveState, value)) {
      return;
    }

    const copy = states.slice(0, index + 1); // This removes all future (redo) states after current index
    copy.push(value);

    if (!!limit)
      if (copy.length > limit + 1) {
        // remove oldest state when length is over 10
        copy.shift();
        setIndex(index - 1); // adjust index accordingly
      }

    setStates(copy);
    setIndex(copy.length - 1);
  };

  // Clear all state history
  const resetStateHandler = () => {
    setIndex(0);
    setStates([]);
  };

  // Allows you to go back (undo) N steps
  const undoClickHandler = (steps = 1) => {
    const newIndex = Math.max(0, Number(index) - (Number(steps) || 1));
    setIndex(newIndex);
    updateData(states[newIndex]);

    if (states.length - 1 === limit && index === 1)
      message.info(`Maximum number of undo actions available is ${limit}.`);
  };

  // Allows you to go forward (redo) N steps
  const redoClickHandler = (steps = 1) => {
    const newIndex = Math.min(
      states.length - 1,
      Number(index) + (Number(steps) || 1)
    );
    setIndex(newIndex);
    updateData(states[newIndex]);
  };

  return {
    itemToSaveActiveState,
    changeStateHandler,
    resetStateHandler,
    index,
    lastIndex: states.length - 1,
    undoClickHandler,
    redoClickHandler,
  };
};
