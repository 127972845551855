import { isPartOfTeam } from "../../components/SidebarPages/Documentation/Tabs/helper";

/**
 * @param {Object} userConfiguration current user configuration
 * @param {Array} rowData current row data to filter
 * @returns {Array} Array with filtered row data by team config, createdBy and groupName
 */
export const filterRowDataByTeamConfig = (userConfiguration, rowData = []) => {
  if (userConfiguration?.groupName?.toLowerCase() === "admin") return rowData;
  else {
    return rowData?.filter((row) => {
      let createdBy = row?.createdBy?.name || row?.createdBy;
      let teamMember = isPartOfTeam(row?.teamsConfiguration, userConfiguration);

      return teamMember || createdBy === userConfiguration?.nameOfUser;
    });
  }
};
