import { useState, useEffect } from "react";
import { Modal, message, Alert, Spin, Button } from "antd";
import { fetchData } from "../../../Fleet/utils";

import { preSafetyIncidentTranslateFields } from "../utils/preSafetyIncidentTranslateFields";
import { filterTables } from "../../../../../utils";
import { MondayButton } from "../../../../commonComponents";
import { InputComponent } from "../../../Fleet/components";
import { XIcon } from "../../../Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import IncidentModal from "../../../Incidents/Modals/IncidentModal";
import { PlusIcon } from "../../../../../assets";
import { useSelector } from "react-redux";
import "./PreSafetyModal.scss";

/**
 * PreSafetyModal component displays a modal for selecting safety incidents and creating new safety reports.
 *
 * @component
 * @param {boolean} open - Determines whether the modal is open or not.
 * @param {Function} [onCancel=()=>{}] - Callback function to handle canceling the modal.
 * @param {string} category - The category of the safety report.
 * @param {Function} [onIncidentSelected=()=>{}] - Callback function to handle selecting an incident.
 * @param {string} projectId - The ID of the project.
 * @param {string} projectAddress - The address of the project.
 * @param {Function} [setVisible=()=>{}] - Callback function to set the visibility of the modal.
 * @returns {JSX.Element} The rendered PreSafetyModal component.
 */
function PreSafetyModal({
  open,
  onCancel = () => {},
  category,
  onIncidentSelected = () => {},
  projectId,
  projectAddress,
  setVisible = () => {}, //NEEDED FOR NEXT STEPS
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [incidents, setIncidents] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [newIncidentVisible, setNewIncidentVisible] = useState(false);

  function selectedOptionHandler(id) {
    const selectedOption = incidents.find((option) => option.incidentId === id);
    setSelectedRow(selectedOption);
  }

  /**
   * Selects a category and filters the incidents based on the selected category.
   *
   * @param {string} c - The category to select.
   * @returns {Promise<void>} - A promise that resolves when the incidents are filtered.
   */
  async function selectCategory(c) {
    setIncidents();
    if (!c) {
      setIncidents([]);
      Promise.resolve([]);
      return;
    }
    filterTables("incidents", "incidentCategory", c)
      .then((inc) => {
        let res = inc?.filter(
          (e) =>
            (e.incidentStatus === "New" || e?.incidentStatus === "Open") &&
            !!e?.incidentName
        );
        if (c === "Vehicle Damage") {
          if (projectId) {
            setIncidents(
              res?.filter((e) => e?.incidentAddress === projectAddress)
            );
          } else {
            setIncidents(res);
          }
        } else {
          if (projectId) {
            setIncidents(res?.filter((e) => e?.projectId === projectId));
          } else {
            setIncidents(res);
          }
        }
      })
      .catch((err) => {
        console.error("Error getting Incidents: ", err);
        setIncidents([]);
      });
  }

  const onCancelHandler = () => {
    onCancel();
    setVisible();
  };

  /**
   * Fetches the vehicles and sets the vehicles state.
   */
  useEffect(() => {
    if (selectedCategory === "Vehicle Damage") {
      fetchData("fleet")
        .then((res) => {
          setVehicles(res);
        })
        .catch((err) => {
          message.error("Something went wrong while opening form");
          console.error("Error: ", err);
          onCancelHandler();
        });
    }
    if (selectedCategory) {
      selectCategory(selectedCategory);
    }
  }, [selectedCategory]);

  return (
    <Modal
      {...{
        open,
        onCancel: onCancelHandler,
        closable: true,
        destroyOnClose: true,
        title: `New ${category || ""} Safety Report`,
        wrapClassName: "cardLayoutModal",
        footer: null,
        maskClosable: false,
        centered: true,
        closeIcon: <XIcon />,
        className: `preSafetyModal ${isDarkMode && "preSafetyModalDark"}`,
      }}
    >
      <div className="note-modal-content">
        <InputComponent
          showSearch
          label="Category"
          type="select"
          dropdownClassName={isDarkMode && "darkDropDown"}
          defaultValue={category}
          disabled={!!category}
          onSelect={(e) => {
            setSelectedCategory(e);
            selectCategory(e);
          }}
          allowClear={false}
          customOptions={[
            { value: "Property Damage" },
            { value: "Personal Injury" },
            { value: "Vehicle Damage" },
            { value: "Other Trade Incident" },
          ]}
        />
        <Spin spinning={!incidents && !!selectedCategory}>
          {!incidents && !!selectedCategory ? (
            <Alert
              {...{
                message: "Searching for incidents...",
                type: "info",
                style: { marginBottom: "1rem" },
              }}
            />
          ) : !incidents && !selectedCategory ? (
            <></>
          ) : !!incidents?.length ? (
            <InputComponent
              showSearch
              label="Incident"
              type="select"
              dropdownClassName={isDarkMode && "darkDropDown"}
              allowClear={true}
              onClear={() => {
                setSelectedRow(null);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                      padding: "1rem",
                    }}
                  >
                    <Button
                      onClick={() => setNewIncidentVisible(true)}
                      type="primary"
                    >
                      Create Incident
                    </Button>
                  </div>
                </>
              )}
              customOptions={(incidents || [])?.map(
                ({
                  incidentId = null,
                  incidentName = null,
                  incidentObject = {},
                }) => ({
                  value: incidentId,
                  label:
                    incidentName ??
                    incidentObject?.addressOfDamagedProperty ??
                    incidentObject?.addressOfInjury ??
                    incidentObject?.incidentAddress ??
                    "Not Set",
                })
              )}
              placeholder="Select an incident..."
              onSelect={(e) => selectedOptionHandler(e)}
            />
          ) : (
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Alert
                style={{ height: "32px", width: "100%" }}
                message={`No Incidents for this category!`}
                type="error"
                showIcon
              />
              <MondayButton
                onClick={() => setNewIncidentVisible(true)}
                className="mondayButtonBlue"
                Icon={<PlusIcon />}
              >
                Create Incident
              </MondayButton>
            </div>
          )}
        </Spin>
        <div className="modalFooter">
          <MondayButton
            {...{
              Icon: <TickIcon width={17} height={17} />,
              onClick: () => {
                onIncidentSelected(
                  preSafetyIncidentTranslateFields({
                    category: category ?? selectedCategory,
                    incident: selectedRow,
                    vehicles,
                  })
                );
              },
              disabled: !selectedCategory,
            }}
          >
            {!!selectedRow ? "Open Safety Report" : "New Safety Report"}
          </MondayButton>
        </div>
      </div>
      {newIncidentVisible && (
        <IncidentModal
          {...{
            refreshTable(res) {
              setIncidents(res.concat(incidents || []));
            },
            setEdit: setNewIncidentVisible,
            category: selectedCategory,
            showNextStep: false,
          }}
        />
      )}
    </Modal>
  );
}

export default PreSafetyModal;
