import { IndexesRender } from "../../logs/logContentBuilder";
import { extractContent } from "../../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";
import { nameFirstDigits } from "../../../../../../../utils/nameFirstDigits";
import dayjs from "dayjs";

export const PliNoteViewer = ({ indexes, note }) => {
  console.log("AGASGASGASGASG", { note });

  if (note === undefined || note === null || extractContent(note?.note) === "-")
    return "";

  // console.log(note)
  return (
    <div className="pli-note">
      {IndexesRender({ ...indexes })}
      <div className="comment_wrapper">
        <div className="comment__icon">
          {!!note?.createdBy ? nameFirstDigits(note?.createdBy) : ""}
        </div>
        <div className="comment_data">
          <span className="top_details">
            <h5>{note?.createdBy}</h5>
            <h6>{dayjs(note?.createdAt).fromNow()}</h6>
          </span>
          <span
            className="comment-content"
            dangerouslySetInnerHTML={{ __html: note?.note }}
          />
        </div>
      </div>
    </div>
  );
};
