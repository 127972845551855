import { categories } from "../../utils/constants";

export const filterAndMapEstimations = (
  result,
  responses,
  latestProjectData
) => {
  const applications = responses[categories?.indexOf("applications")];

  return result
    .filter(({ estSTATUS }) => estSTATUS === "Approved")
    ?.map((estimation) => ({
      ...estimation,
      requisitionsIncluded: ((e) => {
        const requisitions = applications?.filter(({ estimationsSelected }) =>
          estimationsSelected?.includes(estimation?.estimationId)
        );
        if (!(requisitions?.length > 0)) return false;
        return requisitions?.map((requisition) => ({
          applicationId: requisition?.applicationId,
          applicationNo: requisition?.applicationNo,
          scheduleId: requisition?.scheduleId,
        }));
      })(),
      services: latestProjectData?.services[estimation?.estimationId],
    }));
};
