import "./Small.scss";
import _ from "lodash";
import FormItem from "../../components/FormItems/FormItem";
import StyledButton from "../../components/StyleButton/StyledButton";
import { useEffect, useState } from "react";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";

const Small = (prop) => {
  const {
    params,
    form,
    getEditedValue,
    setEditMode,
    currentStep,
    getFormChanged = () => {},
    setFormChanged = () => {},
  } = prop;
  const data = params?.data;
  const values = { [data?.formItemName]: data?.value };
  const [prev, setPrev] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };
  useEffect(() => {
    setPrev(() => _.cloneDeep(prop));
  }, [prop]);

  return (
    <div className="smallEditComponent">
      <div className="smallEditContainer">
        <span className="SmallLabel">{data?.label}</span>
        {FormItem[data?.type]({
          name: data?.formItemName,
          value: data?.value,
          getEditedValue,
          form,
          options: data?.options,
          label: data?.label,
          onChange() {
            setFormChanged(true);
          },
        })}
      </div>
      <div className="confirmContainer">
        <MondayButton
          onClick={(res) => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          onClick={(res) => {
            !_.isEqual(values, form.getFieldsValue()) &&
              getEditedValue(
                form.getFieldsValue(),
                undefined,
                values,
                prev,
                keys(form.getFieldsValue()).map((e) => {
                  prop.params.data.value = form.getFieldsValue()[e];
                  return prop;
                })[0]
              );
            setEditMode(false);
          }}
          className="confirmButton"
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default Small;
