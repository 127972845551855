import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { compareIncluding } from "../../../utils";

/**
 * Translates the fields of a safety incident object based on the provided category.
 *
 * @param {string} category - The category of the safety incident.
 * @param {Object} incident - The safety incident object.
 * @param {Array} [vehicles=[]] - The array of vehicles.
 * @returns {Object} - The translated safety incident fields.
 */
export function preSafetyIncidentTranslateFields({
  category,
  incident,
  vehicles = [],
}) {
  if (!incident) {
    return { safetyApplicationCategory: category };
  }
  let safetyObjectFields = {};

  if (category === "Vehicle Damage") {
    safetyObjectFields = {
      projectId: incident?.projectId || "",
      scheduleId: incident?.scheduleId || "",
      accountId: incident?.accountId || "",
      safetyApplicationCategory: category,
      safetyApplicationObject: {
        dateOfVehicleDamageOrIncident: dayjsNY(incident?.incidentDate).format(),
        timeOfVehicleDamageOrIncident: dayjsNY(
          incident?.incidentDate + " " + incident?.incidentTime
        ).format(),
        addressOfIncident: incident?.incidentAddress,
        incidentId: incident?.incidentId,
        truckNumber: incident?.fleetId,
        nameOfDriver: incident?.driverId,
        licensePlate: vehicles?.find(({ fleetName }) =>
          compareIncluding(fleetName, incident?.fleetName)
        )?.licensePlate,
        witnessRequired:
          incident?.incidentReport
            ?.find((el) => el?.category === "Booleans")
            ?.values?.find((el) => el?.label?.includes("witness"))?.value ===
          "Yes"
            ? true
            : false,
        werePoliceInvolved: incident?.incidentReport?.find(
          (el) => el.category === "Details"
        )?.values[0]?.value,
        wereTheFireDepartmentInvolved: incident?.incidentReport?.find(
          (el) => el.category === "Details"
        )?.values[1]?.value,
        wereTheAmbulanceInvolved: incident?.incidentReport?.find(
          (el) => el.category === "Details"
        )?.values[2]?.value,
        incidentGoogleDriveUpload: incident?.googleDriveUpload || [],
      },
    };
  } else {
    safetyObjectFields = {
      safetyApplicationObject: {
        ...incident?.incidentObject,
        incidentId: incident?.incidentId,
        incidentGoogleDriveUpload: incident?.googleDriveUpload,
      },
      safetyApplicationCategory: category,
      projectId: incident?.projectId || "",
      scheduleId: incident?.scheduleId || "",
      accountId: incident?.accountId || "",
    };
  }

  return safetyObjectFields;
}
