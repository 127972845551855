import { assignedToObject, teamSelect } from "..";
import sortFields from "../sortFields";

/**
 * Generates an array of new fields based on the provided input fields.
 *
 * @param {Array} teams - The array of teams.
 * @param {Array} fleet - The array of fleet.
 * @param {Array} inputFields - The array of input fields.
 * @param {boolean} isDarkMode - Indicates if the dark mode is enabled.
 * @param {boolean} globalDisabled - Indicates if all fields are globally disabled.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @returns {Array} The sorted array of new fields.
 */
const workOrderRequestFields = ({
  teams = [],
  fleet = [],
  inputFields = [],
  isDarkMode = false,
  globalDisabled = false,
  userConfiguration = {},
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  onMainFieldSelect = () => {},
}) => {
  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;

    field.className = "defaultInputComponent";
    // delete field?.defaultField;
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "fleetName") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (fleet || []).map((el) => ({
          label: el?.fleetName,
          value: el?.fleetId,
        })),
        allowClear: true,

        onSelect: (val, opt) => onMainFieldSelect({ val, opt }),

        onClear: () => onMainFieldSelect({ clear: true }),
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Work Orders",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ onTeamSelect, teams, isDarkMode }),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default workOrderRequestFields;
