const progressBarStyles = {
  "0-25": { barBackground: "#F04F4E", wrappBackground: "#E69B9B" },
  "25-75": { barBackground: "#FCC94A", wrappBackground: "#F5E2B2" },
  "75-100": { barBackground: "#7AC14D", wrappBackground: "#B2DD9B" },
  "100-150": { barBackground: "darkRed", wrappBackground: "red" },
};

const isBetween = (item, percentage) => {
  return (
    parseInt(item[0]) <= parseInt(percentage) &&
    parseInt(percentage) <= parseInt(item[1])
  );
};

export const isBetweenPercentage = (percentage) => {
  let keys = Object.keys(progressBarStyles).map((item) => item.split("-"));
  let isBetweenNum = keys.find((item) => isBetween(item, percentage));
  let styleOfKeyFound = isBetweenNum?.join("-");
  let style = progressBarStyles[styleOfKeyFound];

  return style;
};
