import React, { useState } from "react";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import {
  CalendarIcon,
  PercentageIcon,
} from "../../../../../../../../../BasePage/src";
import { NormalSizedModal } from "../../../../../../../../../Fleet/components";
import { Card, Skeleton, Timeline } from "antd";
import "./SchedRentalTimeline.scss";
import {
  ClockCircleOutlined,
  EditOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import {
  fetchAllData,
  filterTables,
} from "../../../../../../../../../../../utils";
import moment from "moment";
import { LoadableComp } from "../../../../../../../../../XComponents";
import { TimeIcon } from "../../../../../../../../../../../icons";
import { useSelector } from "react-redux";
import { groupBy } from "lodash";
import { retrieveSchedulingProgress } from "./scheduleRentalConnection";
export const SchedRentalTimeline = ({ projectId, view = "full" }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [visible, setVisible] = useState(false);
  const [dataTimeline, setDataTimeline] = useState([]);
  const [loading, setLoading] = useState(false);

  const onOpen = async () => {
    setVisible(true);
    setDataTimeline([]);
    setLoading(true);
    await filterTables("scheduling", "projectId", projectId)
      .then(async (e) => {
        console.log("scheduling", e);
        let applicableSchedules = e ?? [];
        if (applicableSchedules.length > 0) {
          let temp = [];
          applicableSchedules.forEach(async (schedule) => {
            setDataTimeline((prev) => [
              ...(prev || {}),
              {
                timeEvent: schedule?.createdAt,
                scheduleId: schedule?.scheduleId,
                scheduleName: schedule?.scheduleName,
                title: "Created Schedule",
                description: `This schedule was created by ${
                  schedule?.createdBy
                } on ${moment(schedule?.createdAt).format(
                  "MM/DD/YYYY hh:mm A"
                )}`,
                typeOfWork: schedule?.typeOfWork,
                scheduleStatus: schedule?.scheduleStatus,
              },
            ]);
            await fetchAllData({
              endpoint: "editLogs",
              resultPosition: "editLogs",
              resultId: "logId",
              otherStringParams: {
                getMaxLimit: "true",
                filters: JSON.stringify([
                  {
                    conditions: [
                      {
                        column: "recordId",
                        value: schedule?.scheduleId,
                        formula: "is",
                      },
                    ],
                  },
                ]),
              },
            }).then((e) => {
              let mine = e?.filter(
                (item) => item?.actionType === "progressChanged"
              );
              if (mine.length > 0) {
                mine.forEach((item) => {
                  setDataTimeline((prev) => [
                    ...(prev || {}),
                    {
                      timeEvent: item?.updatedAt,
                      scheduleId: schedule?.scheduleId,
                      title: "Changed Progress",
                      description: `${item?.topic}`,
                      scheduleName: schedule?.scheduleName,
                      scheduleStatus: schedule?.scheduleStatus,
                      typeOfWork: schedule?.typeOfWork,
                    },
                  ]);
                });
              }
              console.log("mine", mine);
            });
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
  };

  const cleanUp = () => {
    setDataTimeline([]);
    setLoading(false);
    setVisible(false);
  };

  const onTest = async () => {
    retrieveSchedulingProgress({ projectId });
  };

  console.log("dataTimeline", dataTimeline);
  return (
    <div>
      <MondayButton
        className="mondayButtonYellow"
        Icon={view === "icon" ? null : <CalendarIcon />}
        onClick={onOpen}
        hasIcon={view === "icon" ? false : true}
      >
        {view === "icon" ? "Timeline" : "Schedule Timeline"}
      </MondayButton>
      {visible && (
        <NormalSizedModal
          {...{
            visible,
            setVisible: () => {
              cleanUp();
            },
            centered: true,
            maskClosable: true,
            closable: true,
            title: "Schedule Timeline",
          }}
        >
          <div
            className={`schedRentalTimeline ${
              isDarkMode && "schedRentalTimelineDark"
            }`}
            style={{
              padding: "0 20px",
              minWidth: "800px",
              marginTop: "20px",
              // minHeight: "500px",
              height: "800px",
              overflowY: "scroll",
            }}
          >
            {/* <MondayButton onClick={onTest}>Test</MondayButton> */}
            {loading ? (
              <div>
                <Skeleton active /> <Skeleton active />
                <Skeleton active />
              </div>
            ) : (
              <div>
                {Array.isArray(dataTimeline) && dataTimeline?.length > 0 ? (
                  <Timeline
                    mode={"alternate"}
                    items={dataTimeline?.map((item) => {
                      return {
                        // label: "2023-09-01",
                        label: (
                          <strong>
                            {moment(item?.timeEvent).format(
                              "MM/DD/YYYY hh:mm A"
                            )}
                          </strong>
                        ),
                        // children: "Created Schedule",
                        children: (
                          <CustomCard
                            title={`${item?.title} - ${item?.scheduleName} (${item?.typeOfWork})`}
                            type={
                              item?.title === "Created Schedule"
                                ? "create"
                                : "change"
                            }
                          >
                            {item?.description}
                          </CustomCard>
                        ),
                        color: "red",
                        dot: (
                          <ClockCircleOutlined className="timeline-clock-icon" />
                        ),
                      };
                    })}
                  />
                ) : (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    No Timeline Data
                  </div>
                )}
              </div>
            )}
          </div>
        </NormalSizedModal>
      )}
    </div>
  );
};

const CustomCard = ({ title = "", children, type = "" }) => {
  const mainColor = type === "create" ? "#1264A3" : "#F79F4C";
  console.log("mainColor", title);
  return (
    <Card
      title={!!title && title?.length > 0 ? title : null}
      bordered={true}
      bodyStyle={{
        background: "#F8F8FA",
        boxShadow: "box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.54)",
        padding: "10px",
      }}
      headStyle={{
        // different background
        background: mainColor,
        color: "white",
      }}
    >
      {children && children}
    </Card>
  );
};
