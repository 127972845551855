import React, { useState, useEffect } from "react";
import ProposalCard from "../ProposalCard";
import "./ProposalCardsCarousel.css";
function ProposalCardsCarousel({ dataToRender }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCards = 5;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === dataToRender.length - visibleCards ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? dataToRender.length - visibleCards : prevIndex - 1
    );
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     nextSlide();
  //   }, 3000); // Autoplay interval of 3 seconds
  //   return () => clearInterval(interval);
  // }, [currentIndex]);

  return (
    <div className="carousel-container">
      <button className="left-arrow" onClick={prevSlide}>
        &lt;
      </button>
      <div className="carousel-content">
        {Array.isArray(dataToRender) &&
          dataToRender
            .slice(currentIndex, currentIndex + visibleCards)
            .map((item, index) => {
              return (
                <ProposalCard
                  key={item.id}
                  title={item?.proposalStatus ?? item?.otherDetails?.title}
                  item={item}
                />
              );
            })}
      </div>
      <button className="right-arrow" onClick={nextSlide}>
        &gt;
      </button>
    </div>
  );
}

export default ProposalCardsCarousel;
