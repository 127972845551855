import React from "react";
import { Modal, Button } from "antd";
import { updateDocumentTitle } from "../../../../utils";
import "./InspectionModal.scss";
const InspectionModal = ({
  visible,
  setVisible,
  destroyOnClose = false,
  title = "",
  className = "",
  onCancel,
  maskClosable = true,
  centered = true,
  children,
  footer = [],
  width,
  closable = false,
  closeIcon,
  isDarkMode,
  transitionName,
  "data-testid": dataTestId = "",
}) => {
  return (
    <Modal
      wrapClassName={`basePageModal ${className} ${
        !!title ? "" : "noTitleBasePageModal"
      } ${isDarkMode && "newInspectionModalDark basePageModalDark"}`}
      open={visible}
      destroyOnClose={destroyOnClose}
      footer={null}
      title={title}
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: title });
      }}
      width={width}
      centered={centered}
      keyboard
      maskClosable={maskClosable}
      closable={closable}
      closeIcon={closeIcon}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel ? onCancel() : setVisible(false);
      }}
      transitionName={transitionName}
      data-testid={dataTestId}
    >
      <div style={{ background: isDarkMode ? "black" : "#fff" }}>
        {children}
      </div>
      {footer?.length && (
        <div
          style={{ background: isDarkMode ? "#000" : "#fff" }}
          className="basePageModalFooter"
        >
          {footer?.map(
            (
              {
                text,
                onClick,
                primary,
                uppercase = false,
                disabled = false,
                danger = false,
              },
              i
            ) => (
              <Button
                key={i}
                danger={danger}
                onClick={onClick}
                disabled={disabled}
                className={`${
                  disabled
                    ? "disabled"
                    : danger
                    ? ""
                    : primary
                    ? "primary"
                    : "secondary"
                }FooterButton`}
                style={{
                  textTransform: uppercase ? "uppercase" : "capitalize",
                }}
              >
                {text}
              </Button>
            )
          )}
        </div>
      )}
    </Modal>
  );
};

export default InspectionModal;
