import { Button, Modal, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import "./PliProgressModal.scss";
import {
  getDayProgress,
  getItemProgress,
} from "../../../../../SidebarPages/Scheduling/helpers/calculators";
import { ProgressStatistics } from "../../../../../SidebarPages/Scheduling/helpers/totals";
import { ItemStatisticsView } from "../../../../../SidebarPages/Scheduling/subComponents/form/ItemStatisticsView";
import { isDayDisabled } from "../../helpers/PliInfoGetters";
import { SchedulingContext } from "../../context";
import "./AddonProgressModal.scss";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components/index";
import { MondayButton } from "../../../../../commonComponents/index";
import {
  Close,
  EditIcon,
} from "../../../../../SidebarPages/DynamicView/src/index";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src/index";
import { updateDocumentTitle } from "../../../../../../utils";
export const AddonProgressModal = ({
  data,
  node,
  elevationIndex,
  serviceId,
  type,
  typeOfWorkObject,
  setItemAfterProgress,
  scheduleDays,
  label,
  elevationLabel,
}) => {
  const { serviceDefinitionsData = {}, project } =
    useContext(SchedulingContext);
  const { serviceDefinitions = [] } = serviceDefinitionsData || {};

  const { quantity } = data;

  console.log("uuuuuu", { data });

  const d1 = "addon_progress_dimension";

  const [isModalVisible, set_isModalVisible] = useState(false);

  const [item_state, set_item_state] = useState(data); //set item in state first time
  const [statistics, set_statistics] = useState(
    ProgressStatistics.getItemStatistics(item_state, { d1 })
  );
  useEffect(() => {
    set_item_state(data);
    set_statistics(ProgressStatistics.getItemStatistics(data, { d1 }));
  }, [data]); //when row data has changed

  const { progressByDay } = item_state;

  const onCancel = () => {
    set_item_state(data);
    updateDocumentTitle(); // reset document title to "Lead Manager"
    set_isModalVisible(false);
  };

  const onOk = () => {
    item_state.totalProgress = getItemProgress(item_state, { d1 });

    // console.log(item_state, node)
    node.setDataValue("progressByDay", item_state.progressByDay);
    node.setDataValue("totalProgress", item_state.totalProgress);

    setItemAfterProgress(item_state, type, { serviceId, elevationIndex }); //this will handle item by type if pli or sub-pli
    updateDocumentTitle(); // reset document title to "Lead Manager"
    set_isModalVisible(false);
    message.success("Progress has been saved!");
  };

  const onProgressByDayChange = (day, dimension, value) => {
    const item_state_copy = _.cloneDeep(item_state);
    const progressDay = find_progressDay(day, item_state_copy);

    //make sure that value is a number and not smaller than 0
    value = parseInt(value) || 0;
    value = value < 0 ? 0 : value;

    progressDay[dimension] = value;

    progressDay.progress = getDayProgress(progressDay, { d1 });
    item_state_copy.totalProgress = getItemProgress(item_state_copy, { d1 });

    set_statistics(
      ProgressStatistics.getItemStatistics(item_state_copy, { d1 })
    );
    set_item_state(item_state_copy);
  };

  //utils
  const find_progressDay = (day, item) =>
    item.progressByDay.find((pd) => pd.day === day);
  const hasItemSubPLi = (item) => item.subPli && item.subPli.length > 0;

  // console.log(scheduleDays, progressByDay)

  console.log({
    data,
    node,
    elevationIndex,
    serviceId,
    type,
    typeOfWorkObject,
    setItemAfterProgress,
    scheduleDays,
    label,
    elevationLabel,
    project,
    serviceDefinitionsData,
  });

  const hasSubPLi = hasItemSubPLi(item_state);

  let title = "Set item progress ";

  if (type === ADDON_PROGRESS_ORIGIN.PLI_ADDON) {
    title += ` for Pli: ${node?.data?.parentId} > sub-PLi: ${node?.data.id}`;
  } else {
    title += ` for Addon: ${node?.data?.id}`;
  }

  const isCompleted = data?.history?.some(
    ({ pliTotalProgress }) => pliTotalProgress === 100
  );

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <MondayButton
        disabled={isCompleted}
        onClick={() => {
          if (progressByDay?.length === 0) {
            message.error("Please select a day!");
          } else {
            set_isModalVisible(true);
          }
        }}
        {...{ Icon: <EditIcon />, className: "mondayButtonBlue" }}
      >
        Set Progress
      </MondayButton>
      {/* <Button
        type="primary"
        disabled={isCompleted}
        onClick={() => {
          if (progressByDay?.length === 0) {
            message.error("Please select a day!");
          } else {
            set_isModalVisible(true);
          }
        }}
      >
        Set progress
      </Button> */}
      <Modal
        title={title}
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: title });
        }}
        open={isModalVisible}
        onCancel={onCancel}
        centered
        className="addon-progress-modal"
        footer={null}
      >
        <div className="container">
          <ItemStatisticsView
            {...{ statistics, d1, typeOfWorkObject }}
            onlyPercentage
          />
          <div className="tableContainer">
            <table style={{ width: "100%" }}>
              <tr style={{ width: "100%" }}>
                <th>Day</th>
                <th>Addon Progress</th>
                {/* <th>Addon Quantity</th> */}
              </tr>
              {progressByDay?.map((progressDay) => {
                const id = progressDay?.day;
                const inactiveDay = isDayDisabled(id, scheduleDays);
                const dayColor = scheduleDays?.find((d) => d?.id === id)?.color;
                return (
                  <tr>
                    <td style={{ color: dayColor }}>{`D${
                      scheduleDays?.find((sch) => sch?.id === id)?.day
                    }`}</td>
                    <td>
                      {hasSubPLi ? (
                        "Progress is set by sub pli-s"
                      ) : (
                        <InputComponent
                          className="addon-progress-input"
                          type="input"
                          disabled={inactiveDay}
                          value={progressDay[d1]}
                          onChange={(e) =>
                            onProgressByDayChange(id, d1, e?.target?.value)
                          }
                        />
                      )}
                    </td>
                    {/* <td>
                      {hasSubPLi ? (
                        "Progress is set by sub pli-s"
                      ) : (
                        <InputComponent
                          type="input"
                          disabled={inactiveDay}
                          value={(progressDay[d1] * quantity) / 100}
                          onChange={(e) =>
                            onProgressByDayChange(
                              id,
                              d1,
                              (e?.target?.value * 100) / quantity
                            )
                          }
                        />
                      )}
                    </td> */}
                  </tr>
                );
              })}
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MondayButton
              onClick={onCancel}
              {...{ className: "mondayButtonRed", Icon: <Close /> }}
            >
              Cancel
            </MondayButton>
            <MondayButton onClick={onOk} {...{ Icon: <TickIcon /> }}>
              Save Changes
            </MondayButton>
          </div>
        </div>
      </Modal>
      {/* <Modal
        title={title}
        open={isModalVisible}
        onOk={onOk}
        onCancel={onCancel}
        okButtonProps={{ disabled: hasSubPLi }}
        cancelButtonProps={{ disabled: hasSubPLi }}
        maskClosable={hasSubPLi}
        className={"pli-progress-modal"}
        width={700}
      >
        <div className={"pli-progress-table-container"}>
          <ItemStatisticsView
            {...{ statistics, d1, typeOfWorkObject }}
            onlyPercentage
          />
          <Table className={"pli-progress-table"}>
            <TableHead>
              <TableCell>Day</TableCell>
              <TableCell>Set Progress</TableCell>
            </TableHead>

            <TableBody className={"breakdowns-table-body"}>
              {progressByDay?.map((progressDay) => {
                const id = progressDay.day;
                const inactiveDay = isDayDisabled(id, scheduleDays);

                const dayColor = scheduleDays.find((d) => d.id === id)?.color;
                return (
                  <TableRow className={""} key={id}>
                    <TableCell
                      style={{ color: dayColor }}
                      className={"day-cell"}
                    >
                      {" "}
                      D{scheduleDays.find((sch) => sch.id === id).day}{" "}
                    </TableCell>
                    <TableCell className={"progress-fields-container"}>
                      {hasSubPLi ? (
                        "Progress is set by sub pli-s"
                      ) : (
                        <>
                          <TextField
                            label="Addon Progress"
                            disabled={inactiveDay}
                            value={progressDay[d1]}
                            onChange={(e) =>
                              onProgressByDayChange(id, d1, e.target.value)
                            }
                            className={"dimension-input"}
                            margin="dense"
                            type={"number"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </TableCell>
                    <TableCell className="progress-fields-container">
                      {hasSubPLi ? (
                        "Progress is set by sub pli-s"
                      ) : (
                        <TextField
                          label="Addon Quantity"
                          disabled={inactiveDay}
                          value={(+progressDay[d1] * +quantity) / 100}
                          onChange={(e) =>
                            onProgressByDayChange(
                              id,
                              d1,
                              (+e.target.value * 100) / +quantity
                            )
                          }
                          className="dimension-input"
                          margin="dense"
                          type="number"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Modal> */}
    </span>
  );
};

export const ADDON_PROGRESS_ORIGIN = {
  SERVICE_ADDON: "SERVICE_ADDON",
  PLI_ADDON: "PLI_ADDON",
};
