import { useState, useEffect } from "react";
import { Modal } from "antd";
import { GoogleMap } from "@react-google-maps/api";
import { NYC_CENTER } from "../../../../../../commonComponents/Map/mapData";
import { XIcon } from "../../../../../Communication/assets";
import {
  MAP_DARK,
  MAP_DEFAULT,
} from "../../../../../Scheduling/Tabs/SchedulingMap/schedulingMapData";
import {
  POLYLINE_OPTIONS,
  TRANSIT_OPTIONS,
} from "./DispatchItineraryModal/ItineraryStepClass";
import { MARKER_ICON_URL } from "src/components/SidebarPages/Fleet/fleetsLive/data";

/**
 * @param {Object} props
 * @param {boolean} props.open
 * @param {() => any} props.onCancel
 * @param {google.maps.DirectionsResult|google.maps.DirectionsResult[]} props.directions
 * @param {boolean} props.isDarkMode
 * @returns
 */
function DispatchMapsCard({ open, onCancel, directions, isDarkMode }) {
  const [map, setMap] = useState();

  useEffect(() => {
    if (!directions || !map) {
      return;
    }

    const mapElements = [];

    const dir = new Array(directions).flat();
    for (const direction of dir) {
      mapElements.push(
        new google.maps.DirectionsRenderer({
          directions: direction,
          draggable: false,
          map,
          markerOptions: {
            clickable: false,
            draggable: false,
            visible: true,
            icon: {
              url: MARKER_ICON_URL,
            },
          },
          polylineOptions: {
            ...POLYLINE_OPTIONS,
            ...(direction.request.travelMode !== google.maps.TravelMode.DRIVING
              ? TRANSIT_OPTIONS
              : {}),
          },
        })
      );
    }

    return () => {
      for (const el of mapElements) {
        el.setMap(null);
      }
    };
  }, [directions, map]);

  return (
    <Modal
      {...{
        open,
        onCancel,
        closeIcon: <XIcon />,
        title: "Directions Card",
        centered: true,
        footer: null,
        closable: true,
        className: `dispatchMapModal ${isDarkMode && "dispatchMapModalDark"}`,
        destroyOnClose: true,
        "data-testid": "direction-maps-modal",
      }}
    >
      <GoogleMap
        {...{
          onLoad: setMap,
          center: NYC_CENTER,
          zoom: 10,
          mapContainerClassName: "innerDispatchMap",
          options: {
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
            styles: isDarkMode ? MAP_DARK : MAP_DEFAULT,
          },
        }}
      />
    </Modal>
  );
}

export default DispatchMapsCard;
