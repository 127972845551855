import { RenderDynamicComponents } from "../../../../../forms/Components";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../../../../commonComponents";
import "./SessionLogFilters.scss";
import { Modal } from "antd";
import { antdModalProps } from "../../../../../../../utils";
import { InfoText } from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

function SessionLogFilters({
  visible,
  onFilterApply,
  filterFields = [],
  onCancel,
  clearFilters,
  title = "Filters",
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <Modal
      {...{
        ...antdModalProps,
        title,
        open: visible,
        className: "session-logs-modal logs-session-filters-modal",
        onCancel,
        wrapClassName: "",
        footer: (
          <div className="session-logs-filter-footer">
            <MondayButton
              {...{
                onClick: onCancel,
                hasIcon: false,
                className: "mondayButtonRed",
              }}
            >
              Cancel
            </MondayButton>

            <div className="logs-filter-right-footer">
              <span className="clear-button" onClick={clearFilters}>
                Clear Filters
              </span>
              <MondayButton
                {...{
                  onClick: onFilterApply,
                  hasIcon: false,
                  className: "mondayButtonBlue",
                }}
              >
                Apply Filters
              </MondayButton>
            </div>
          </div>
        ),
      }}
    >
      <InfoText
        {...{
          text: "Adjust the filters, select the specific criteria to customize the displayed data.",
          isDarkMode,
        }}
      />
      <div className="filters-container">
        {RenderDynamicComponents(filterFields)}
      </div>
    </Modal>
  );
}

export default SessionLogFilters;
