import "./ComplaintsFilterCars.scss";

const ComplaintsFilterCard = () => {
  return (
    <div className="filterCard complaintsFilterCard">
      <div className="leftSection"></div>
      <div className="rightSection"></div>
    </div>
  );
};

export default ComplaintsFilterCard;
