import { applications, rentals, charges, invoices } from "./subCategory";

export const lineChartFull = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  applicationsData,
  rentalsData,
  chargesData,
  invoicesData
) => {
  return {
    Applications: () =>
      applications(
        IdHandler,
        programFields,
        updatedDynamicStates,
        applicationsData
      ),
    Rentals: () =>
      rentals(IdHandler, programFields, updatedDynamicStates, rentalsData),
    Charges: () =>
      charges(IdHandler, programFields, updatedDynamicStates, chargesData),
    Invoices: () =>
      invoices(IdHandler, programFields, updatedDynamicStates, invoicesData),
  };
};
