import dayjs from "dayjs"

export const fleet = ({ tables }) => {
  return {
    fleetYear: () => ({
      statusData: tables?.Fleet?.map((a) => {
        return {
          label: a?.fleetName,
          value: a?.fleetYear,
          color: "#ECDE7D",
          date: a?.createdAt,
        }
      }),
    }), //fleetYear
    registrationExpDate: () => ({
      statusData: tables?.Fleet?.map((a) => {
        return {
          label: a?.fleetName,
          value: dayjs(a?.registrationExpDate)?.format("MM/DD/YYYY"),
          color: "#ECDE7D",
          date: a?.createdAt,
        }
      }),
    }), //registrationExpDate
    inspectionExpDate: () => ({
      statusData: tables?.Fleet?.map((a) => {
        return {
          label: a?.fleetName,
          value: dayjs(a?.inspectionExpDate)?.format("MM/DD/YYYY"),
          color: "#ECDE7D",
          date: a?.createdAt,
        }
      }),
    }), //inspectionExpDate
  } //Fleet
}
