import { Button, Tooltip } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

import { witnessFieldsData } from "./witnessFieldsData";
import { toCamelCase } from "../../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import { BorderedTextCard, LabeledInput } from "../../../Fleet/components";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";

import "./StatementFields.scss";
import RichTextEditorWrapper from "../../../../commonComponents/RichTextEditor/RichTextEditorWrapper";
import Signature from "../../../../commonComponents/Signature/Signature";

/**
 * Component that renders a dynamic list in the forms in a bordered text card
 * @param {FormInstance} form
 * @param {Number} fieldCount The number of fields
 * @param {Array} dynamicStatements The list of statements
 * @param {Array} dynamicSignings The list of signings
 * @param {String} labelName The field label (the formItemName is derived from this)
 * @param {String} categoryTitle The title of the bordered text card
 */
function StatementsFields({
  form,
  fieldCount = 1,
  // dynamicStatements = [],
  dynamicSignings = [],
  labelName = "Witness Name",
  categoryTitle = "Witnesses",
  // setDynamicStatements = () => {},
  setDynamicSignings = () => [],
  setFieldCount = () => {},
  disableFields = false,
  rowObject = {},
  isDarkMode,
}) {
  let formName = toCamelCase(labelName);

  // function scrollToField(index) {
  //   let modalContainers = document.getElementsByClassName("safetyDynamicForms");
  //   let modal = modalContainers.item(modalContainers.length - 1);
  //   let container = modal.getElementsByClassName("ant-form").item(0);
  //   let t = modal.getElementsByClassName("statementSection").item(index - 1);
  //   if (!!t && !!container) {
  //     let yPosition = t?.getBoundingClientRect()?.y;
  //     container.scroll(0, yPosition + 100);
  //   }
  // }

  function addWitness() {
    let newIndex = fieldCount + 1;
    form.setFieldValue(`${formName}-${newIndex}`, "");
    // setDynamicStatements([...(dynamicStatements || []), ""]);
    setDynamicSignings([...(dynamicSignings || []), ""]);
    setFieldCount(newIndex);
    // setTimeout(() => {
    //   scrollToField(newIndex);
    // }, 0);
  }

  function removeWitness(index) {
    // if (fieldCount === 1) {
    //   return;
    // }

    for (let i = index; i < fieldCount; i++) {
      form.setFieldValue(
        `${formName}-${i}`,
        form.getFieldValue(`${formName}-${i + 1}` || "")
      );
    }
    form.setFieldValue(`${formName}-${fieldCount - 1}`, null);

    // let tStatements = structuredClone(dynamicStatements);
    let tSignings = structuredClone(dynamicSignings);
    // tStatements.splice(index, 1);
    tSignings.splice(index, 1);
    // setDynamicStatements(tStatements);
    setDynamicSignings(tSignings);
    setFieldCount(fieldCount - 1);
  }

  function addSignature(index, signature) {
    let tSignings = structuredClone(dynamicSignings);
    tSignings[index] = signature;
    setDynamicSignings(tSignings);
  }

  return (
    <>
      <BorderedTextCard
        title={categoryTitle}
        className="dynamicStatementsFormSection"
        isDarkMode={isDarkMode}
      >
        <Button type="primary" onClick={addWitness} disabled={disableFields}>
          Add Witness
        </Button>
        {[...new Array(fieldCount)]?.map((_, i) => (
          <div className="statementSection" key={`witness-${i}`}>
            {!disableFields && (
              <div className="removeCardButton">
                <Tooltip trigger="hover" title="Remove Section">
                  <MinusCircleOutlined
                    onClick={() => {
                      removeWitness(i);
                    }}
                  />
                </Tooltip>
              </div>
            )}
            <div className="fieldsSection">
              {RenderDynamicComponents(
                witnessFieldsData({
                  index: i,
                  // statements: dynamicStatements,
                  // setStatements: setDynamicStatements,
                  formName,
                  labelName,
                  disableFields,
                  form,
                  isDarkMode,
                  rowObject,
                }),
                { form }
              )}
              <LabeledInput
                {...{
                  style: { width: "100%" },
                  label: "Statement",
                  formItemName: `${formName}Statement-${i}`,
                  form,
                }}
              >
                {" "}
                <RichTextEditorWrapper
                  {...{
                    form,
                    isDarkMode,
                    formItemName: `${formName}Statement-${i}`,
                    onChange: (val) =>
                      form.setFieldValue(`${formName}Statement-${i}`, val),
                  }}
                />
              </LabeledInput>
            </div>
            <div className="signings" id="sign">
              <Signature
                {...{
                  signature: dynamicSignings[i],
                  setSignature: (value) => addSignature(i, value),
                  signDisabled: disableFields,
                  removeDisabled: disableFields,
                }}
              />
            </div>
          </div>
        ))}
      </BorderedTextCard>
    </>
  );
}

export default StatementsFields;
