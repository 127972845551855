import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { Charges } from "../../../../../../../../../../BasePage/src";
import ChargePreview from "../ChargePreview/ChargePreview";

import { useEffect, useState } from "react";
import { useRedux } from "../../../../../../../../../../hooks";
import { NewChargeItem } from "../../../../../../../../Charges/components";
import { API } from "aws-amplify";

const CreateCharges = ({ disabled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [chargeItemsModal, setChargeItemsModal] = useRedux(
    "chargeItemsModal",
    false,
    true
  );
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [project, setProject] = useState(false);

  useEffect(() => {
    if (!project && !!fakeRentals)
      API.get("projects", `/projects/${fakeRentals?.projectId}`).then(
        setProject
      );
  }, [fakeRentals]);

  return (
    <div className="createChargesContainerDark">
      <MondayButton
        className="mondayButtonBlue"
        disabled={disabled}
        onClick={() =>
          setChargeItemsModal({
            categoryFrom: "Rental",
            fromObject: [fakeRentals],
            selectedGroup: fakeRentals?.rentalId,
            setWhereToFind: setFakeRentals,
            type: "Charge",
            whereToFind: fakeRentals,
          })
        }
        Icon={<Charges />}
      >
        Create Charge
      </MondayButton>

      {chargeItemsModal && project && (
        <NewChargeItem
          {...{
            project,
            selectedData: fakeRentals,
          }}
        />
      )}
      {/* <ChargePreview {...{ isModalVisible, setIsModalVisible }} /> */}
    </div>
  );
};

export default CreateCharges;
