export function excelCellFitToColumn(arrayOfArray) {
  let indexToSelect = 0;
  arrayOfArray.forEach((arr, index) => {
    if (arr.length >= arrayOfArray[indexToSelect].length) {
      indexToSelect = index;
    }
  });

  return arrayOfArray[indexToSelect]?.map((_, columnIndex) => {
    const maxLength = Math.max(
      ...arrayOfArray.map((row) =>
        row[columnIndex]?.v ? String(row[columnIndex].v).length : 0
      )
    );

    return { wch: maxLength };
  });
}
