import { useEffect } from "react";

export const useTabClose = (itemId) => {
  // useEffect hook that adds an event listener to the window object
  // to listen for the "storage" event and call the handleTabClose function
  useEffect(() => {
    window.addEventListener("storage", handleTabClose); //adds the event listener
    return () => {
      // this function will be called when the component unmounts
      window.removeEventListener("storage", handleTabClose); //removes the event listener
    };
  }, []);

  // function that will be called when the "storage" event is fired
  const handleTabClose = (event) => {
    if (event.key === "tabCloseKey" && event.newValue === itemId) {
      window.open(location.origin, "_self");
      // // window.open("", "_self");
      // window.close(); // Tries to close the window
    }
  };
};
