import "./AccountInformation.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import ProfileInputs from "./components/ProfileInputs/ProfileInputs";
import ProfileImage from "./components/ProfileImage/ProfileImage";
import UserInfos from "./components/UserInfos/UserInfos";

const AccountInformation = () => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={`account-info-container ${collapsed ? "collapsed" : ""}`}>
      <div
        className={`CardContentWrapper ${collapsed ? "collapsed" : ""} ${
          isDarkMode ? "dark-mode" : ""
        }`}
      >
        <div
          className="CardContentWrapper-header"
          onClick={() => {
            setCollapsed((prev) => !prev);
          }}
        >
          <div className="header-left">Account Information</div>
        </div>

        <div className="CardContentWrapper-content">
          <div className="profile-settings">
            <ProfileImage />
            <ProfileInputs />
          </div>

          <div className="divider" />

          <div className="account-infos">
            <UserInfos />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInformation;
