import moment from "moment";
import { formatCurrency } from "../../../../../../../utils";

export const documentDefinition = (title, companyLogo, fakePayment) => {
  const formatDate = (date) => moment(date).format("MM/DD/YYYY");

  // Calculate totals
  const totalInvoiceAmount = fakePayment.invoices.reduce(
    (acc, invoice) => acc + invoice.invoiceAmount,
    0
  );
  const totalPaidAmount = fakePayment.invoices.reduce(
    (acc, invoice) => acc + invoice.invoicePaidAmount,
    0
  );
  const totalCreditedAmount = fakePayment.invoices.reduce(
    (acc, invoice) => acc + invoice.invoiceCreditedAmount,
    0
  );

  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: "logo", // Use the "logo" image defined in images
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                alignment: "right",
                margin: [10, 10, 0, 10],
              },
              {
                text: `Date: ${formatDate(fakePayment.paymentDate)}`,
                alignment: "right",
                style: "bool", // Style for the date
              },
            ],
            width: "*",
            alignment: "center",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        text: "Payment Information:",
        style: ["strong"],
      },
      {
        // Create a columns section to split the content into two sides
        columns: [
          // Left side content
          {
            stack: [
              {
                text: `Account Name: ${fakePayment.accountName}`,
                alignment: "left",
                margin: [0, 0, 0, 10],
              },
              {
                text: `Payment Date: ${moment(fakePayment.paymentDate).format(
                  "MM/DD/YYYY"
                )}`,
                alignment: "left",
                margin: [0, 0, 0, 10],
              },
            ],
            style: "listItem",
          },
          // Right side content
          {
            stack: [
              {
                text: `Paid Amount: ${formatCurrency(
                  fakePayment.paymentAmount
                )}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
              {
                text: `Payment Method: ${fakePayment.paymentMethod}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
              {
                text: `Payment Status: ${fakePayment.paymentStatus}`,
                alignment: "left",
                margin: [50, 0, 0, 10],
              },
            ],
            style: "listItem",
            // alignment: "right",
          },
        ],
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        table: {
          headerRows: 1,
          widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              { text: "Invoice Date", bold: true },
              { text: "Account Name", bold: true },
              { text: "Project Address", bold: true },
              { text: "Description", bold: true },
              { text: "Invoice Amount", bold: true },
              { text: "Credited Amount", bold: true },
              { text: "Paid Amount", bold: true },
            ],
            ...fakePayment.invoices.map((invoice) => [
              formatDate(invoice.invoiceDate),
              invoice.accountName,
              invoice.projectAddress,
              invoice.invoiceDescription,
              formatCurrency(invoice.invoiceAmount),
              formatCurrency(invoice.invoiceCreditedAmount),
              formatCurrency(invoice.invoicePaidAmount),
            ]),
            // Add a row to display the totals
            [
              { text: "Totals:", bold: true },
              "", // Empty account name
              "", // Empty project address
              "", // Empty description
              formatCurrency(totalInvoiceAmount),
              formatCurrency(totalCreditedAmount),
              formatCurrency(totalPaidAmount),
            ],
          ],
        },
        style: "outerTable",
      },
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { fontSize: 12 },
      header: { margin: [0, 0, 0, 20] },
      listItem: { margin: [5, 5] },
      bool: { margin: [0, 5], fontSize: 13 },
      outerTable: { margin: [0, 0, 0, 30] },
    },
  };
};
