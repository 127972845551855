import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputComponent, NormalSizedModal } from "../../../components";
import { Form, Select, message } from "antd";
import "./NewDriverModal.scss";
import { footerButtons } from "./newDriverModalsData";
import { API } from "aws-amplify";
import { validateForm } from "../../../utils";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { apiRoutes } from "../../../view/pageView/components/ProjectOverviewScreen/VehicleOverviewScreenData";
import {
  MondayButton,
  ProgressComponent,
} from "../../../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  getCognitosForNotification,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../utils";
import { DownOutlined } from "@ant-design/icons";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as CloseIcon } from "../../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../../icons/warningTriangle.svg";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { fleetFields as updateFleetFields } from "../../../../../../actions";
import { useCreateDriveFolders } from "../../../../../../hooks/useCreateDriveFolders";
import { updateTeamsConfiguration } from "../../../../../../utils";
import { AddIcon } from "../../../../../Header/components/GoogleEvents/assets";
import DynamicTeamModal from "../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { useProgressComponent } from "../../../../../../hooks";

const NewDriverModal = ({
  visible,
  setVisible,
  refreshTable = () => {},
  saveButtonReversed = false,
  isNextStep,
}) => {
  const dispatch = useDispatch();

  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { fleetFields = [] } = useSelector((state) => state.fleetFields);
  const { programFields } = useSelector((state) => state.programFields);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Fleets Drivers",
    actionType: "Create",
  });

  const [accessRight] = useState(
    userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Fleets Drivers/View")
      ?.children?.find(({ title }) => title === "Overview")
  );
  const [fileNames, setFileNames] = useState([]);
  const [parentId, setParentId] = useState();
  const [driverRating, setDriverRating] = useState();
  const [driverStatus, setDriverSatus] = useState();
  const [formChanged, setFormChanged] = useState(false);

  const [form] = Form.useForm();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamsModalVisible, setTeamsModalVisible] = useState(false);
  const [allTeams, setAllTeams] = useState([]);

  const driveRequest = driveApi({ accessToken });
  const { Option } = Select;
  const driveFunctions = useCreateDriveFolders("Drivers");

  const navigate = useNavigate();

  //validates the input fields and checks if all documents have been uploaded, then calls onSave
  const validateFormFields = (redirect = true) => {
    let r = redirect;
    if (saveButtonReversed) {
      r = !redirect;
    }
    validateForm(form, () => {
      onSave(r);
      form.resetFields();
      // setVisible(false);
    });
  };

  function getAccessRights(childTitle) {
    return (accessRight?.children || [])?.find(
      ({ title }) => title === childTitle
    );
  }

  //posts the new driver to the database
  const onSave = async (redirect) => {
    showLoadingMsg();
    // licenceIssuedDate, medicalCardIssuedDate
    const { name, lastName, rating, driverStatus, employeeId, driverType } =
      form.getFieldsValue();
    const driverName = `${name} ${lastName}`;

    setVisibleCreationProgress(true);

    let allFolders = null;

    try {
      const { folders } = await driveFunctions.create({
        parentId,
        parentFolderName: driverName,
      });
      allFolders = folders;
    } catch (error) {
      console.log("Error creating folders: ", error);
      allFolders = null;
    }

    updateProgressStatus({ updatingRecord: "executing" });
    await API.post(apiRoutes.drivers, `/${apiRoutes.drivers}`, {
      body: {
        driverName,
        employeeId,
        driverType,
        driverRating: rating,
        driverStatus: driverStatus,
        googleDriveFolderIds: allFolders,
        teamsConfiguration: updateTeamsConfiguration(
          userConfiguration,
          selectedTeam
        ),
      },
    })
      .then((r) => {
        refreshTable([r]);

        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        }); //set progress of creating record as 100%
        setVisibleCreationProgress({ ...r, redirect }); //save created violation id and save datas to generate logs in case of any fail

        broadcastNotification(
          "11",
          "onDriverCreation",
          [
            {
              common: userConfiguration?.nameOfUser,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
              cognitos: getCognitosForNotification(
                userConfiguration,
                r?.teamsConfiguration
              ),
            },
          ],
          r?.driverId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
        showSuccessMsg();
      })
      .catch((e) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        showErrorMsg();
        console.error(`New Driver posting failed ${e}`);
      });
    form.resetFields();
    setFileNames([]);
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
  };

  //hides the modal, resets the input fields and deletes all the uploaded files from GDrive
  const onCancel = () => {
    setVisible(false);
    setCancelModalVisible(false);
    form.resetFields();
    driveFunctions?.reset();

    fileNames.forEach(({ files = [] }) => {
      for (const { id } of files) {
        driveRequest.deleteDriveItem(id);
      }
    });
    setFileNames([]);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  //fetches data from the API
  useEffect(() => {
    if (fleetFields.length) {
      const fieldOptionsObj = fleetFields.reduce(
        (acc, cur) => ({ ...acc, [cur.fieldName]: cur.fieldOptions }),
        {}
      );

      const googleDriveFolderIds = fieldOptionsObj[
        "Google Drive Folder Ids"
      ].reduce((acc, curr) => ({ ...acc, ...curr }), {});

      setParentId(googleDriveFolderIds?.drivers);
      setDriverRating(
        programFields
          ?.find((item) => item.fieldName === "Statuses Of Categories")
          .fieldOptions["Drivers"].filter(({ status }) => status)
      );
      setDriverSatus(fieldOptionsObj["Driver Status"]);
    }
  }, [fleetFields]);

  // new shortcut from sidebar needs fleetFields to be filled
  useEffect(() => {
    if (!fleetFields?.length > 0) {
      API.get("fleetFields", `/fleetFields`).then((res) =>
        dispatch(updateFleetFields(res))
      );
    }

    API.get("teams", "/teams")
      .then((r) => {
        setAllTeams(r);
      })
      .catch((err) => {
        console.log("Error getting teams: ", err);
      });
  }, []);

  function onTeamSelect(_, data) {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((e) => e),
      },
    ]);
  }

  function onTeamDeselect(_, data) {
    setSelectedTeam((prev) => prev?.filter((el) => el?.value !== data.value));
    if (form.getFieldValue("driverTeam")?.length < 1) {
      setSelectedTeam([]);
    }
  }

  function onClearTeamsOptions() {
    setTeamsModalVisible(false);
  }

  return (
    <>
      <NormalSizedModal
        visible={visible}
        title="Drivers"
        centered
        className={`newDriverModalContainer ${
          isDarkMode && "newDriverModalContainerDark"
        }`}
        onCancel={() =>
          formChanged ? setCancelModalVisible(true) : onCancel()
        }
        newFooterDesign={footerButtons(
          () => (formChanged ? setCancelModalVisible(true) : onCancel()),
          validateFormFields,
          () => validateFormFields(false),
          <DownOutlined />,
          saveButtonReversed
        )}
      >
        <div className="newDriverFormContainer">
          <Form
            form={form}
            onFieldsChange={() => {
              setFormChanged(true);
            }}
          >
            <InputComponent
              required
              label="Name"
              formItemName="name"
              placeholder="Name"
            />
            <InputComponent
              required
              label="Last Name"
              formItemName="lastName"
              placeholder="Last Name"
            />
            <InputComponent
              label="Employee Id"
              formItemName="employeeId"
              placeholder="Employee Id..."
            />
            <InputComponent
              required
              label="Rating"
              formItemName="rating"
              placeholder="Select..."
              dropdownClassName={isDarkMode && "darkDropDown"}
              type="select"
              options={driverRating?.map(({ statusName }, i) => (
                <Option key={i} value={statusName}>
                  {statusName}
                </Option>
              ))}
            />
            <InputComponent
              required
              label="Status"
              formItemName="driverStatus"
              placeholder="Select..."
              dropdownClassName={isDarkMode && "darkDropDown"}
              type="select"
              options={driverStatus?.map(({ statusLabel }, i) => (
                <Option key={i} value={statusLabel}>
                  {statusLabel}
                </Option>
              ))}
            />
            <InputComponent
              required
              label="Driver Type"
              formItemName="driverType"
              placeholder="Driver Type..."
              type="select"
              dropdownClassName={isDarkMode && "darkDropDown"}
              customOptions={[
                { label: "CDL", value: "CDL" },
                { label: "Sub", value: "Sub" },
                { label: "Other", value: "Other" },
              ]}
            />
            {getAccessRights("Teams")?.write ? (
              <InputComponent
                {...{
                  required: true,
                  allowClear: false,
                  label: "Team",
                  formItemName: "driverTeam",
                  placeholder: "Select team...",
                  mode: "multiple",
                  type: "select",
                  showSearch: true,
                  dropdownClassName: isDarkMode && "darkDropDown",
                  customOptions: allTeams?.map((team) => ({
                    label: team.teamName,
                    value: team.teamName,
                    members: team.members?.map(
                      ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
                        identityId,
                        nameOfUser,
                        cognitoUserId,
                      })
                    ),
                  })),
                  onSelect: onTeamSelect,
                  onDeselect: onTeamDeselect,
                }}
              />
            ) : (
              <></>
            )}
            {!!selectedTeam?.length && getAccessRights("Teams")?.write ? (
              <MondayButton
                Icon={<AddIcon />}
                onClick={() => {
                  setTeamsModalVisible(true);
                }}
              >
                Members
              </MondayButton>
            ) : (
              <></>
            )}
          </Form>
          {getAccessRights("Teams") ? (
            <DynamicAvatars
              title={"Drivers Team"}
              isDarkMode={isDarkMode}
              members={selectedTeam?.reduce(
                (acc, { members }) => [...acc, ...members],
                []
              )}
            />
          ) : (
            <></>
          )}
        </div>
      </NormalSizedModal>
      {teamsModalVisible && (
        <DynamicTeamModal
          {...{
            open: teamsModalVisible,
            setOpen: setTeamsModalVisible,
            selectedTeam,
            setSelectedTeam,
            ClearOptions: onClearTeamsOptions,
            isDarkMode,
          }}
        />
      )}
      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Fleets Drivers",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              const { redirect, driverId } = visibleCreationProgress || {};
              !!redirect &&
                !!driverId &&
                !!!isNextStep &&
                navigate(`/fleet/drivers/${driverId}`);
              setVisible(false);
            },
          }}
        />
      )}
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </>
  );
};

export default NewDriverModal;
