import { axesTooltipCustom } from "../../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "topic",
    groupKey: "taskTopic",
    xAxisName: "Topic",
  },
  {
    chartName: "subtopic",
    groupKey: "taskSubTopic",
    xAxisName: "Subtopic",
  },
  {
    chartName: "status",
    groupKey: "taskStatus",
    xAxisName: "Status",
  },
  {
    chartName: "createdBy",
    groupKey: "createdBy",
    xAxisName: "Tasks",
  },
];

export const getChartingOptions = (data) => {
  let topic = new Set();
  let subtopic = new Set();
  let status = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const tTopic = item.taskTopic || "No Data";
    const tsubTopic = item.taskSubTopic || "No Data";
    const oStage = item.taskStatus || "No Data";
    const user = item.createdBy || "No Data";

    topic.add(tTopic);
    subtopic.add(tsubTopic);
    status.add(oStage);
    createdBy.add(user);
  });

  const countOnTopic = {
    title: {
      text: "Total Tasks Count",
    },
    subtitle: {
      text: "Based on Topic",
    },
    series:
      Array.from(topic).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Tasks"),
          },
        };
      }) || [],
  };

  const countSubTopic = {
    title: {
      text: "Total Tasks Count",
    },
    subtitle: {
      text: "Based on SubTopic",
    },
    series:
      Array.from(subtopic).map((topic) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: topic,
          yName: topic,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Tasks"),
          },
        };
      }) || [],
  };

  const countOnStatus = {
    title: {
      text: "Total Tasks Count",
    },
    subtitle: {
      text: "Based on Status",
    },
    series:
      Array.from(status).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Tasks"),
          },
        };
      }) || [],
  };

  const countOnCreatedBy = {
    title: {
      text: "Total Tasks Count",
    },
    subtitle: {
      text: "Based on Created By",
    },
    series:
      Array.from(createdBy).map((user) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: user,
          yName: user,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Tasks"),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle: "Show total Tasks count based on Topic",
      params: countOnTopic,
      key: "topic",
    },
    {
      optionTitle: "Show total Tasks count based on SubTopic",
      params: countSubTopic,
      key: "subtopic",
    },
    {
      optionTitle: "Show total Tasks count based on Status",
      params: countOnStatus,
      key: "status",
    },
    {
      optionTitle: "Show total Tasks count based on Created By",
      params: countOnCreatedBy,
      key: "createdBy",
    },
  ];

  return chartingOptions;
};
