const sortHeaders = (allHeaders, options) => {
  const checkedHeaders = [];
  const uncheckedHeaders = [];

  allHeaders.forEach((header) => {
    const isInSelectedHeaders = options.includes(header.column);

    if (isInSelectedHeaders) {
      checkedHeaders.push(header);
    } else {
      uncheckedHeaders.push(header);
    }
  });

  const result = {
    sortedData: [...checkedHeaders, ...uncheckedHeaders],
    includes: checkedHeaders.map(({ column }) => column),
  };

  return result;
};

export default sortHeaders;
