import React from "react";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Select,
  Switch,
  Tooltip,
  Cascader,
} from "antd";
import {
  UndoOutlined,
  RedoOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { exportDOCX, exportPDF, exportXLSX } from "../../tools/exporters";
import { gMapsAPI } from "../../../../../../helpers/constants/forProject";
import { options } from "./options";
import "./Header.scss";
import { EstimationNoteModal } from "./EstimationNoteModal/EstimationNoteModal";
import { formatCurrency, getLatLngByAddress } from "../../../../utils";
import { LogsIcon } from "../../../../DynamicView/src";
import { fetchData } from "../../../../Fleet/utils";
import { useProgramFields } from "../../../../../../hooks";
import store from "../../../../../../store";
import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../commonComponents/index";
import { RefreshIcon } from "../../../../Communication/assets/index";
import { SaveIcon, SwitchSaveIcon } from "../../../../BasePage/src/index";
import { CheckIconModal } from "../../../../../../assets/index";
import EstimationSecondaryHeader from "./EstimationSecondaryHeader";
import { checkIfServiceIsHoist } from "../../models/Service";
const { Option } = Select;

/**
 * This component represents buttons on the top of data entry page.
 * List of buttons:
 * - Show/Hide services
 * - Refresh
 * - Auto-save switch on/off
 * - Save in estimation (Quick save)
 * - Save in project
 * - Dropdowns with extra functions
 * - Change theme
 * */
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editLogsModalVisibility: false,
      base64string: [],
      proposedTypeOfWork: [],
      takeOffExport: [],
    };
  }

  componentDidMount() {
    (async () => {
      this.state.base64string = await store().getState().base64.base64;
      this.state.proposedTypeOfWork = await store().getState()
        .proposedTypeOfWork.proposedTypeOfWork;
      this.state.takeOffExport = await store()
        .getState()
        .programFields.programFields.find(
          ({ fieldName }) => fieldName === "TakeOff Export"
        ).fieldOptions;
    })();
  }

  // opens google maps location of job site in a new window
  showGMap = () => {
    getLatLngByAddress(this.props.projectAddress)
      .then((response) => {
        let { lat, lng } = response?.coordinates;
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
          "_blank"
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setTheme = (agGridTheme) => {
    this.props.setTheme(agGridTheme);
  };

  onExportHandle = (value) => {
    const { estimation, versionId } = this.props;
    const takeOffObject = {
      ...estimation?.versionServices?.[versionId],
      jobSiteAddress: estimation?.jobSiteAddress,
      logo: this.state.base64string.find(
        (item) => item.fileName === "CORE logo"
      )?.base64,
      // estimation?.logo?.toLowerCase().includes("Central")
      //   ? this.state.base64string.find(
      //       (item) => item.fileName === "CENTRAL logo"
      //     )?.base64
      //   : this.state.base64string.find((item) => item.fileName === "CORE logo")
      //       ?.base64,
    };
    if (value.includes("excel")) {
      Modal.confirm({
        title: "Would you like to generate it with pricing or without pricing?",
        okText: "With pricing",
        cancelText: "Without pricing",
        onCancel: () => {
          exportXLSX({
            takeOffObject,
            proposedTypeOfWork: this.state.proposedTypeOfWork,
            withPricing: false,
            takeOffColumns: this.state.takeOffExport,
          });
        },
        onOk: () => {
          exportXLSX({
            takeOffObject,
            proposedTypeOfWork: this.state.proposedTypeOfWork,
            withPricing: true,
            takeOffColumns: this.state.takeOffExport,
          });
        },
      });
    } else if (value.includes("word")) {
      exportDOCX(
        this.props.gridData,
        this.props.projectAddress,
        this.props.serviceDefs,
        this.props.accountName,
        this.props.estDescription,
        value.includes("withNotes")
      );
    } else {
      exportPDF(this.props.gridData, this.props.projectAddress);
    }
  };

  render() {
    let exportAccess = this.props.accessRights?.children?.find(
      (access) => access.title === "Export"
    )?.write;

    const { gridData } = this.props;

    const takeOffTotalPrice = () => {
      let result = 0;
      console.log("takeOffTotalPrice", gridData);
      result =
        gridData?.reduce((a, b) => {
          return a + (b?.additionalRentalTerms?.newPrice || 0);
        }, 0) || 0;

      try {
        if (Array.isArray(gridData)) {
          let temp =
            gridData
              ?.flatMap(
                (
                  {
                    serviceOptions = [],
                    serviceAddons = [],
                    label = "",
                    isScope = false,
                  },
                  idx,
                  arr
                ) => {
                  let isHoist = checkIfServiceIsHoist(arr[idx]);

                  if (isScope) {
                    return 0;
                  } else if (!isHoist) {
                    return [
                      ...(serviceOptions?.[0]?.flatMap?.(
                        ({ items = [] }) =>
                          items
                            ?.filter(({ approved = false }) => !!approved)
                            ?.flatMap(({ totalPrice = 0 }) => +totalPrice) || 0
                      ) ?? 0),
                      ...(serviceAddons?.map(
                        ({ totalPrice = 0 }) => +totalPrice
                      ) ?? 0),
                    ];
                  } else {
                    return [
                      ...(serviceOptions?.[0]?.flatMap?.(
                        ({ price = 0 }) => +price
                      ) || 0),
                      ...(serviceAddons?.map(({ price = 0 }) => +price) || 0),
                    ];
                  }
                }
              )
              ?.filter(Boolean)
              ?.reduce((acc, curr) => (acc += curr), 0) ?? 0;
          if (!isNaN(temp)) {
            return result + temp;
          }
        }
        return result;
      } catch (error) {
        return result;
      }
    };

    return (
      <div className="take-off-secondary-header">
        {console.log("propsThis", this)}
        <Tooltip
          title={
            this.props.ServiceMenuContainerShow
              ? "Hide Services Manager"
              : "Show Services Manger"
          }
          className="float-left"
        >
          <button
            className="service-menu-toggle"
            shape="circle"
            onClick={() => {
              this.props.stateSetter({
                ServiceMenuContainerShow: !this.props.ServiceMenuContainerShow,
              });
            }}
          >
            {this.props.ServiceMenuContainerShow ? (
              <span style={{ paddingLeft: "10px" }}>
                <LeftOutlined />
              </span>
            ) : (
              <RightOutlined />
            )}
          </button>
        </Tooltip>
        {/* <h1 className="float-left sansFont dataEntryTitle">
          {this.props.projectAddress}
        </h1> */}
        <span
          className="dataEntryTitle"
          style={{
            // backgroundColor: "rgb(113, 207, 72,0.25)",
            backgroundColor: "#e7223a",
            textShadow: `-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)`,
            padding: "0 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            marginTop: "-3px",
            marginRight: "20px",
            gap: "5px",
            // color: "#360103",
          }}
        >
          TOTAL: <strong>{formatCurrency(takeOffTotalPrice())}</strong>
        </span>
        <div className="take-off-secondary-header-btn-cont">
          <Tooltip title={"Restart Grid"}>
            <MondayButton
              className="mondayButtonBlue"
              Icon={<RefreshIcon />}
              onClick={() => {
                this.props.stateSetter({ gridData: null });
                this.props.initGrid();
              }}
              disabled={this.props.loading}
            >
              Restart Grid
            </MondayButton>
          </Tooltip>
        </div>
        <div className="take-off-secondary-header-btn-cont">
          <Tooltip title={"Edit Logs"}>
            <button
              className="icon-btn-holder"
              onClick={() => this.setState({ editLogsModalVisibility: true })}
              disabled={this.props.loading}
            >
              <LogsIcon style={{ color: "white" }} />
            </button>
          </Tooltip>
        </div>{" "}
        <div className="take-off-secondary-header-btn-cont">
          <Tooltip title={"Undo"}>
            <button
              className="icon-btn-holder"
              disabled={!this.props.canUndo()}
              onClick={this.props.undoClickHandler}
            >
              {" "}
              <UndoOutlined style={{ fontSize: "20px" }} />
            </button>
          </Tooltip>
        </div>
        <div className="take-off-secondary-header-btn-cont">
          <Tooltip title={"Redo"}>
            <button
              className="icon-btn-holder"
              disabled={!this.props.canRedo()}
              onClick={this.props.redoClickHandler}
            >
              <RedoOutlined style={{ fontSize: "20px" }} />
            </button>
          </Tooltip>
        </div>
        <div className="float-right vDivider" />
        <Tooltip title={"Turn on/off auto save"}>
          <Switch
            className="takeoff-auto-save-switch"
            checked={this.props.AutoSave}
            onChange={(checked) =>
              this.props.stateSetter({ AutoSave: checked })
            }
          />
        </Tooltip>
        <div className="take-off-secondary-header-btn-cont">
          <Tooltip
            title={
              this.props.isWritable
                ? "Save your work in estimation"
                : "Enable WRITE mode to save in estimation"
            }
          >
            <MondayButton
              Icon={<CheckIconModal />}
              className="mondayButtonBlue"
              onClick={() => {
                this.props.handleSave(true, true);
              }}
              loading={this.props.loading}
              disabled={!this.props.isWritable}
            >
              Quick Save
            </MondayButton>
          </Tooltip>
        </div>{" "}
        {/* <div className="take-off-secondary-header-btn-cont"> */}
        {/* <Tooltip
          title={
            this.props.project_OR_opportunity !== "project"
              ? "This is an opportunity. It has not been converted yet into a project."
              : this.props.isWritable
              ? "Save approved PLI in project"
              : "Enable WRITE mode to save in project"
          }
        > */}
        <MondayButton
          className="mondayButtonBlue"
          Icon={<CheckIconModal />}
          onClick={this.props.handleSaveProject}
          loading={this.props.loading}
          disabled={
            !this.props.isWritable ||
            this.props.project_OR_opportunity !== "project"
          }
        >
          Save In Project
        </MondayButton>
        {/* </div> */}
        {/* <Button
            type="primary"
            className="float-right clearfix-primary-btn"
            style={{ marginRight: 24 }}
            onClick={this.props.handleSaveProject}
            loading={this.props.loading}
            disabled={
              !this.props.isWritable ||
              this.props.project_OR_opportunity !== "project"
            }
          >
            Save In Project
          </Button> */}
        {/* </Tooltip> */}
        <div className="float-right vDivider" />
        <Dropdown
          className="show-on-dropdown"
          menu={{
            items: [
              {
                key: "1",
                label: "Google Maps",
                onClick: this.showGMap,
              },
              {
                key: "2",
                label: "Digital Tax Map",
              },
            ],
          }}
        >
          <Button className="float-right clearfix-secondary-btn">
            Show on...
          </Button>
        </Dropdown>
        <Cascader
          options={options(exportAccess)}
          className="show-on-dropdown"
          style={{
            marginRight: 10,
            marginLeft: 20,
            height: "auto",
            background: "#1f212d",
            color: "#fff",
          }}
          popupClassName="deg-export-menu"
          // size="small"
          expandTrigger="click"
          placeholder="Export to..."
          onChange={this.onExportHandle}
        />
        <div className="float-right vDivider" />
        {/* <Select
          className="float-right"
          style={{ marginRight: 24 }}
          onChange={this.setTheme}
          defaultValue={this.props.agGridTheme}
        >
          {themes.map((th) => (
            <Option key={th.value} value={th.value}>
              {th.label}
            </Option>
          ))}
        </Select> */}
        <EstimationNoteModal
          className="float-right clearfix-primary-btn est-description-btn"
          modalClassName="warningModal-light est-note-modal"
          saveEstimationDescription={this.props.saveEstimationDescription}
          estDescription={this.props.estDescription}
          disabled={!this.props.isWritable}
        />
        {this.state.editLogsModalVisibility && (
          <MultiLevelTreeLogs
            {...{
              visible: this.state.editLogsModalVisibility,
              setVisible: (value) =>
                this.setState({ editLogsModalVisibility: value }),
              logsData: this.props.editLogs || [],
              title: "Estimation Logs",
            }}
          />
        )}
      </div>
    );
  }
}

export default Header;

const themes = [
  { value: "ag-theme-balham", label: "White Mode" },
  { value: "ag-theme-balham-dark", label: "Dark Mode" },
];
