import Invoice from "./Invoice/Invoice";
import Payment from "./Payment/Payment";
import Charge from "./Charge/Charge";
import Application from "./Application/Application";
import Claims from "./Claims/Claims";
import Hearings from "./Hearings/Hearings";
import Opportunity from "./Opportunity/Opportunity";
import Contact from "./Contact/Contact";
import NewDocumentation from "./Documentation/Documentation";
import PermitDrawing from "./PermitDrawing/PermitDrawing";
import NewTask from "./NewTask/NewTask";
import Incident from "./Incident/Incident";
import NewViolation from "./NewViolation/NewViolation";

export const stepForms = {
  Invoice,
  Payment,
  Charge,
  Application,
  Claims,
  Hearings,
  Opportunity,
  Contact,
  NewDocumentation,
  PermitDrawing,
  NewTask,
  Incident,
  NewViolation,
};
