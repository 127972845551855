import { useSelector } from "react-redux";
import { Form } from "antd";

import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { ClearFilterIcon } from "src/components/SidebarPages/DynamicView/src";

function RadiusPicker({ onConfirm }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      className="popover-unit-inputs"
      initialValues={{
        searchRadius: "300",
        unit: "foot",
      }}
    >
      <InputComponent
        {...{
          type: "input",
          typeNumber: true,
          required: true,
          label: "Search Distance",
          formItemName: "searchRadius",
        }}
      />
      <InputComponent
        {...{
          label: "Unit",
          type: "select",
          formItemName: "unit",
          dropdownClassName: isDarkMode && "darkDropDown",
          customOptions: [
            { label: "ft", value: "foot" },
            { label: "mi", value: "mile" },
          ],
          showSearch: false,
          allowClear: false,
        }}
      />
      <div className="popover-form-footer">
        <MondayButton
          Icon={<ClearFilterIcon />}
          className="mondayButtonRed"
          onClick={() => {
            onConfirm();
          }}
        >
          Clear Search
        </MondayButton>
        <MondayButton
          {...{
            onClick() {
              form.validateFields().then((values) => {
                onConfirm(values);
              });
            },
            Icon: <TickIcon />,
          }}
        >
          Confirm
        </MondayButton>
      </div>
    </Form>
  );
}

export default RadiusPicker;
