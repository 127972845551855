import dayjs from "dayjs";

const TIME_FORMAT = "hh:mm a";
const DATE_FORMAT = "MM/DD/YYYY";

export const transformLogEntry = (logEntry) => {
  const { lastSeen, name } = logEntry;
  return {
    updatedAt: dayjs(lastSeen).format(`${DATE_FORMAT} ${TIME_FORMAT}`),
    nameOfUser: name,
    date: lastSeen,
  };
};
