import React from "react";
import { useRedux } from "../../../../../../../hooks";
import { checkCheckboxStatus } from "../../utils/checkCheckboxStatus";
import ElevationAgGrid from "../ElevationAgGrid/ElevationAgGrid";
import { useCreateChargeContext } from "../../context/NewChargeItemContext";
import { groupServicesByEstimationId } from "../../../ChooseChargeFromModal/utils/groupServicesByEstimationId";
import RequisitionCharge from "../../RequisitionCharge";

const ChargeTables = () => {
  const [objectData] = useRedux("chargeItemsModal");
  const categoryFrom = objectData?.categoryFrom;

  const isRequisition = categoryFrom === "Requisition";

  const { chargeToSave, selectedServices } = useCreateChargeContext();

  const allCharges = groupServicesByEstimationId(chargeToSave?.chargeItems);

  return (
    <div className="PLISContainer">
      {!isRequisition ? (
        allCharges?.map((savedCharge, index) => {
          const isSelected = checkCheckboxStatus(
            selectedServices?.find(
              (estimation) =>
                estimation.estimationId === savedCharge.estimationId
            )
          );

          return (
            <div key={index} style={{ display: isSelected ? "block" : "none" }}>
              {allCharges.length > 1 && (
                <h3 className="estimation-name-charge">{savedCharge.name}</h3>
              )}
              {savedCharge.services.map((charge, index) => (
                <ElevationAgGrid
                  key={index}
                  charge={charge}
                  chargeIndex={index + 1}
                />
              ))}
            </div>
          );
        })
      ) : (
        <RequisitionCharge />
      )}
    </div>
  );
};

export default ChargeTables;
