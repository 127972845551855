import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { Form, Modal, message } from "antd";
import { useState, useEffect, useMemo } from "react";

import { inputFields } from "./engArchModalData";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import {
  XIcon,
  UserIcon,
} from "../../../../../SidebarPages/Communication/assets";
import WarningSection from "./WarningSection";
import { InfoIcon } from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../Roles/src";
import { Plus } from "../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";

import "./NewNonUserModal.scss";

const NewNonUserModal = ({
  open,
  setVisible,
  rowData,
  selectedRow,
  setSelectedRow = () => {},
  editMode,
  role,
  updateDataOnSave = () => {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [changes, setChanges] = useState(false);
  const [editLogs, setEditLogs] = useState([]);
  const [linkSuggestion, setLinkSuggestion] = useState(false);
  const [linked, setLinked] = useState(false);
  const [warningModal, setWarningModal] = useState({
    visible: false,
    message: "",
    type: "",
  });

  const [form] = Form.useForm();

  const title = useMemo(() => {
    return editMode
      ? `Non-User Employee - ${open?.nameOfUser}`
      : "Create a New Non-User Employee";
  }, [editMode]);

  function onEmailInput(event) {
    if (!!editMode) {
      return;
    }

    const inputValue = event.target.value;
    const existingUsers = userConfiguration.allUsers.Items;
    let foundUser = null;

    for (const user of existingUsers) {
      if (user.userName === inputValue) {
        foundUser = user;
        break;
      }
    }
    const isUserLinked = rowData.some(
      (el) => el?.userLink === foundUser?.cognitoUserId
    );

    if (foundUser && !isUserLinked) {
      setLinkSuggestion(foundUser);
    }

    if (linkSuggestion && !foundUser) {
      setLinkSuggestion(null);
      if (linked) {
        setLinked(false);
      }
    }
  }

  const fields = useMemo(() => {
    return inputFields({ rowData, form, editMode, onEmailInput });
  }, [rowData, editMode, onEmailInput]);

  function linkUser() {
    setLinked((prev) => !prev);
  }

  function onCancel() {
    if (changes) {
      setWarningModal({
        visible: true,
        message:
          "Are you sure yuo want to close this modal? All the changes that are made will be lost.",
        type: "cancel",
      });
    } else {
      setSelectedRow();
      setVisible(false);
    }
  }

  function onSave() {
    form.validateFields().then((formData) => {
      message.loading({
        key: "saveFunc",
        content: "Saving...",
        duration: 0,
      });
      const { firstName, lastName, userName } = formData;
      const postData = {
        nameOfUser: `${firstName} ${lastName}`,
        userName,
      };

      try {
        if (!!editMode) {
          const idKey = formData.role + "Id";
          delete postData[idKey];

          API.put(role, `/${role}/${open?.[idKey]}`, {
            body: postData,
          }).then(() => {
            updateDataOnSave({
              data: { ...open, ...postData },
              type: "update",
            });
            message.success({
              key: "saveFunc",
              content: "Changes have been saved successfully!",
              duration: 1.8,
            });
          });
        } else {
          API.post(role, `/${role}`, {
            body: {
              ...postData,
              userLink: linked ? linkSuggestion?.cognitoUserId : "",
              createdBy: {
                nameOfUser: userConfiguration.nameOfUser,
                identityId: userConfiguration?.identityId,
              },
            },
          }).then((res) => {
            updateDataOnSave({ data: res, type: "add" });
            message.success({
              key: "saveFunc",
              content: "Employee have been saved successfully!",
              duration: 1.8,
            });
          });
        }
        setVisible(false);
      } catch (error) {
        console.log("Error saving non-user employee: ", error);
        message.error({
          key: "saveFunc",
          content: "There was a problem saving this employee.",
          duration: 1.8,
        });
      }
    });
  }

  useEffect(() => {
    if (!!editMode) {
      const [firstName, ...lastName] = open?.nameOfUser.split(" ");
      form.setFieldsValue({
        userName: open.userName,
        firstName,
        lastName: lastName.join(" "),
        role: role.slice(0, role.length - 1),
      });
    } else if (!!selectedRow) {
      const name = selectedRow.nameOfUser.split(" ");
      const length = name.length;

      const firstName = name.slice(0, length - 1).join(" ");
      const lastName = name[length - 1];

      form.setFieldValue("firstName", firstName);
      form.setFieldValue("lastName", lastName);
      form.setFieldValue("userName", selectedRow.userName);
      form.setFieldValue("role", role.slice(0, role.length - 1));
    } else {
      form.setFieldValue("role", role.slice(0, role.length - 1));
    }
  }, [open, editMode, role]);

  return (
    <Modal
      {...{
        open,
        title,
        onCancel,
        destroyOnClose: true,
        "data-testid": "newNonUserModal",
        footer: [
          <MondayButton
            key={0}
            className="mondayButtonRed"
            data-testid="closeNewNonUserModal"
            Icon={<XIcon />}
            onClick={onCancel}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            key={1}
            Icon={editMode ? <TickIcon width={17} height={17} /> : <Plus />}
            onClick={onSave}
            disabled={!!selectedRow}
            data-testid="onCreateButton"
          >
            {editMode ? "Confirm" : "Create"}
          </MondayButton>,
        ],
        className: `non-user-modal ${isDarkMode ? "non-user-modal-dark" : ""}`,
        closeIcon: <XIcon />,
        centered: true,
      }}
    >
      <section className="info-section">
        <InfoIcon />
        {editMode
          ? "Any changes you make will update the profile, but the linked user name will remain unchanged as it is a primary identifier."
          : "After setting up this profile, you can later assign a user account or link the profile to an existing user as needed."}
      </section>
      <section className="form-container">
        <div className="form-header">
          <UserIcon width={22} height={22} />
          {title}
        </div>
        <Form
          {...{
            onFieldsChange() {
              setChanges(true);
            },
            form,
          }}
        >
          {RenderDynamicComponents(fields)}
        </Form>
        {linkSuggestion && (
          <section
            className="link-suggestion-card"
            data-testid="link-suggestion-card"
          >
            <div
              className="link-info"
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <InfoIcon />
              <span
                className="info-text"
                style={{ width: "calc(100% - 40px)" }}
              >
                The user <b>{linkSuggestion.nameOfUser}</b>, from the{" "}
                <b>{linkSuggestion?.departmentName}</b> department with the role
                of <b>{linkSuggestion?.groupName}</b>, matches this email. Would
                you like to link this employee to{" "}
                <b>{linkSuggestion.nameOfUser}?</b>
                <span
                  className={`link ${linked ? "linked" : ""}`}
                  onClick={linkUser}
                  data-testid="link-user"
                >
                  {linked ? "Unlink from this user" : "Link with this user"}
                </span>
              </span>
            </div>
          </section>
        )}
      </section>
      {!!warningModal?.visible && (
        <WarningSection
          {...{
            warningModal,
            setWarningModal,
            userConfiguration,
            form,
            setEditLogs,
            setModalVisible: setVisible,
            isDarkMode,
            // setRowData,
          }}
        />
      )}
    </Modal>
  );
};

export default NewNonUserModal;
