import React from "react";
import { style } from "./helpers";

export const RequiredLabel = ({ required = false, label }) => {
  return (
    <label style={style.label}>
      {required && <span style={{ color: "red" }}>*</span>}
      {label}
    </label>
  );
};
