import {progressStatuses} from "../models/TypeOfWorkDefinitions";

export const get_progress_status = ({scheduled, progress}) => {
    if(isNaN(progress) || progress < 1) return progressStatuses[0]
    if(progress >= 1 && progress < scheduled) return progressStatuses[1]
    if(progress === scheduled) return progressStatuses[2]
    if(progress > scheduled) return progressStatuses[3]
}

export const get_progress_status_by_progressPercentage = ({progressPercentage}) => {
    if(isNaN(progressPercentage) || progressPercentage < 1) return progressStatuses[0]
    if(progressPercentage >= 1 && progressPercentage < 100) return progressStatuses[1]
    if(progressPercentage === 100) return progressStatuses[2]
    if(progressPercentage > 100) return progressStatuses[3]

}