import {
  addons,
  dispatching,
  documentation,
  estimations,
  fleet,
  fleetInspections,
  leads,
  maintenance,
  opportunities,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  defects,
  workOrders,
  safety,
  todos,
  projectCost,
  approvals,
  inspections,
} from "./subCategory";

export const pieChart = ({ tables, programFields, userConfiguration }) => {
  return {
    Leads: () => leads({ tables, programFields }),
    Claims: () => claims({ tables, programFields }),
    Incidents: () => incidents({ tables, programFields }),
    "Work Orders": () => workOrders({ tables, programFields }), // 'Work Orders
    Defects: () => defects({ tables, programFields }),
    Opportunities: () => opportunities({ tables, programFields }),
    Projects: () => projects({ tables, programFields }),
    Documentation: () => documentation({ tables }),
    Scheduling: () => scheduling({ tables, programFields }),
    Dispatching: () => dispatching({ tables }),
    Fleet: () => fleet({ tables }),
    "Fleet Inspections": () => fleetInspections({ tables, programFields }),
    Maintenance: () => maintenance({ tables }),
    Violations: () => violations({ tables }),
    Estimations: () => estimations({ tables, programFields }),
    "Add-ons": () => addons({ tables }),
    Applications: () => applications({ tables, programFields }),
    Rentals: () => rentals({ tables, programFields }),
    Charges: () => charges({ tables, programFields }),
    Invoices: () => invoices({ tables, programFields }),
    Safety: () => safety({ tables, programFields }),
    "To Do": () => todos({ tables, programFields }),
    "Project Cost": () => projectCost({ tables, programFields }),
    Approvals: () => approvals({ tables, programFields, userConfiguration }),
    Inspections: () => inspections({ tables, programFields }),
  }; //PieChart
};
