import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

const lineTerminatorRg = /(\\n)|(\\r)|(\\t)|(\\v)|(\\f)/;
const CHAR_A = 65;
const CHAR_Z = 90;

export const keysToValidate = [
  "employeeFullName",
  "employeeRate",
  "jobsiteMatch",
  "payrollType",
  "punchDate",
  "punchLocation",
  "punchTime",
  "punchType",
  "company",
];

//a list of roles that don't create overhead
export const COST_ROLES = [
  "Hoist Supervisor",
  "Hoist Foreman",
  "Elevator Constructor",
  "Supervisor",
  "Foreman",
  "Carpenter",
  "Electrician",
  "Flagger",
  "Laborer",
  "Driver",
];

export const DEG_DATE_FORMAT = "MMM DD, YYYY";
export const DEG_TIME_FORMAT = "hh:mm a";

export const NO_JOB_NAME = "Tirana, Albania";

/**
 * Function that returns the next column name in the Excel sequence
 * @param {String} currentColumn
 */
export function getNextColumn(currentColumn = "") {
  if (!currentColumn) {
    return "A";
  }

  let indicators = [];
  for (let i = 0; i < currentColumn.length; i++) {
    indicators.push(currentColumn.charCodeAt(i));
  }
  let lastIndex = indicators?.length - 1;

  if (indicators[lastIndex] < CHAR_Z) {
    indicators[lastIndex] = indicators[lastIndex] + 1;
  } else {
    let iterationsNeeded = 0;
    let addLength = false;
    for (
      let i = 0;
      i <= iterationsNeeded && iterationsNeeded < indicators.length;
      i++
    ) {
      let indexToCheck = lastIndex - iterationsNeeded;
      if (indicators[indexToCheck] === CHAR_Z) {
        indicators[indexToCheck] = CHAR_A;
        ++iterationsNeeded;
        if (!indexToCheck) {
          addLength = true;
        }
      } else {
        indicators[indexToCheck] = indicators[indexToCheck] + 1;
      }
    }
    if (addLength) {
      indicators.splice(0, 0, CHAR_A);
    }
  }

  return indicators.map((e) => String.fromCharCode(e)).join("");
}

/**
 * Function that removes line terminators from the string content
 * @param {String} content
 */
export function parsedCell(content = "") {
  if (!content || typeof content !== "string") {
    return "";
  }
  return content.replace(lineTerminatorRg, " ");
}

/**
 * Function that returns the Excel column name
 * @param {String} col The column name
 * @param {Number|String} row The row index
 */
export function getCellName(col, row) {
  return `${col?.toUpperCase()}${row}`;
}

/**
 * Function that extracts the row index from the cell name
 * @param {String} cellName
 */
export function getIndexFromCell(cellName = "") {
  if (!cellName || typeof cellName !== "string") {
    return 0;
  }
  let index = cellName.replaceAll(/[A-Z]/g, "");
  if (isNaN(parseInt(index))) {
    return 0;
  }

  return parseInt(index);
}

/**
 * Function that returns the column from the cell name
 * @param {String} cellName
 */
export function getColumnFromCell(cellName = "") {
  if (!cellName || typeof cellName !== "string") {
    return "";
  }
  return cellName.replaceAll(/\d/g, "");
}

/**
 * Function that returns the time difference in hours
 * between two timestamps
 */
export function findTimeDiff(start, end, type = "hour") {
  if (!start || !end) {
    return 0;
  }

  let diff = dayjsNY(end)
    .add(type === "hour" ? 1 : 0, "minute")
    .diff(dayjsNY(start), type);
  if (diff > 0) {
    return diff;
  }

  return 0;
}
