/**
 * Changes the status of a schedule day.
 *
 * @param {string} id - The ID of the schedule day.
 * @param {Array} modifiedSchedules - The modified schedule days.
 * @param {Object} selectedSchedule - The selected schedule.
 * @returns {Object} - The updated selected schedule with modified schedule days.
 */
function changeScheduleDayStatus(id, modifiedSchedules, selectedSchedule) {
  return {
    ...selectedSchedule,
    scheduleDays: modifiedSchedules,
  };
}

export default changeScheduleDayStatus;
