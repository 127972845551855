import React, { useEffect, useState } from "react";
import { filterTables } from "../../../../../../../../../utils";
import { MondayButton } from "../../../../../../../../commonComponents";
import { LoadableComp } from "../../../../../../../XComponents";
import { removeDuplicates } from "../../../../../../Tabs/ScheduleView/AgGridData/utils/functions";
import Rentals from "../Rentals/Rentals";

function PreviousRentals({ fakeApplication }) {
  // we are going to extract all included rentals that are associated with the application id
  const [loading, setLoading] = useState(true);
  const [rentals, setRentals] = useState([]);

  const getPreviousRentals = async () => {
    console.log("applications", fakeApplication);
    let accumulatedRentals;
    await filterTables(
      "includedRentals",
      "projectId",
      fakeApplication?.projectId
    ).then((res) => {
      accumulatedRentals = res;
    });
    console.log("accumulatedRentals", accumulatedRentals);
    let rentals = [];
    let acc = fakeApplication?.accumulatedRentals || {};
    if (Object.keys(acc).length > 0) {
      Object.keys(acc).forEach((reqNo) => {
        Object.keys(acc[reqNo]).forEach((rentalNo) => {
          Object.keys(acc[reqNo][rentalNo]).forEach((serviceLabel) => {
            rentals.push({
              ...(accumulatedRentals?.find(
                (el) => el?.rentalNumber.toString() === rentalNo.toString()
              ) || {}),
              applicationNo: reqNo,
              rentalNo: rentalNo,
              serviceLabel: serviceLabel,
              retainage: acc[reqNo][rentalNo][serviceLabel]?.retainage,
              thisDistRetainage:
                acc[reqNo][rentalNo][serviceLabel]?.thisDistRetainage,
            });
          });
        });
      });
    }
    try {
      let notDuplicates = removeDuplicates(rentals, "rentalId");
      setRentals(notDuplicates);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPreviousRentals();
  }, []);

  return (
    <div>
      <LoadableComp loading={loading} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {rentals.map((rental) => {
          return (
            <Rentals
              {...{
                rental,
                type: "prev",
                accumulatedRentals: fakeApplication?.accumulatedRentals,
                prevRentalsRetainage:
                  fakeApplication?.totalities?.prevRentalsRetainage?.[
                    `Rental${rental?.rentalNumber}`
                  ],
              }}
            />
          );
        })}
      </div>
      <LoadableComp />
    </div>
  );
}

export default PreviousRentals;
