import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useNextStep = () => {
  const [isNextStepOpen, setIsNextStepOpen] = useState(false);
  const [savedRecord, setSavedRecord] = useState();
  const [category, setCategory] = useState();

  const haveNavigate =
    category === "Schedule Of Values" || category === "Requisitions";

  const navigate = useNavigate();

  const { state } = useLocation();

  const { savedRecord: __, isNextStepOpen: _, ...rest } = state || {};

  const nextStepHandler = (visible, category) => {
    setIsNextStepOpen(visible || true);

    console.log({ visible, category });
    category && setCategory(category);
  };

  const handleStepExit = () => {
    console.log("handleStepExit");
    setIsNextStepOpen(false);
    if (isNextStepOpen && haveNavigate) navigate(_, { state: { ...rest } });
  };

  const handleSavedRecord = (data) => setSavedRecord(data);

  return {
    category,
    savedRecord,
    setSavedRecord,
    handleStepExit,
    isNextStepOpen,
    nextStepHandler,
    handleSavedRecord,
  };
};
