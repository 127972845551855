import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";
import { boroughs } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const leads = (updatedDynamicStates, IdHandler, programFields) => {
  const leadStatus = programFields?.find(
    (a) => a?.fieldName === "Lead Status Types"
  );
  return {
    leadStatus: () => ({
      title: "Leads",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Leads",
        field: "leadStatus",
        id: IdHandler,
        page: "leads",
        labels: leadStatus?.fieldOptions,
        datasets: [
          {
            label: "Leads",
            data: leadStatus?.fieldOptions?.map(
              (status) =>
                updatedDynamicStates?.[IdHandler]?.filter(
                  (a) => a?.leadStatus === status
                )?.length
            ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Leads?.map((a) => a.statusColor),

            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
    leadAddress: () => ({
      title: "Leads Address",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Leads",
        field: "leadAddress",
        id: IdHandler,
        page: "leads",
        labels: boroughs,
        datasets: [
          {
            label: "Leads",
            data: boroughs?.map(
              (status) =>
                updatedDynamicStates?.[IdHandler]?.filter(
                  (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "NY Boroughs")
              ?.fieldOptions?.map((a) => a?.boroughColor),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectExecutive: () => ({
      title: "Leads Project Executive",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Leads",
        field: "projectExecutive",
        id: IdHandler,
        page: "leads",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        ),
        datasets: [
          {
            label: "Leads",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectExecutive"
              )
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map((a) =>
                getRandomColor()
              )
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  }; //Leads
};
