import React from "react";
import "./DashboardCard.scss";

const DashboardCard = ({
  status,
  count,
  color,
  columnName,
  gridApi,
  moving,
  yard,
  atJobsite,
  totalFleet,
  setLocations = () => {},
  onClick = () => {},
}) => {
  // console.log("moving", {
  //   moving,
  //   yard,
  //   atJobsite,
  // });
  // const yardDirections = [
  //   { lng: -74.223312, lat: 40.54908 },
  //   { lng: -74.22892, lat: 40.546581 },
  // ]

  return (
    <div
      className="statusCountCard"
      onClick={() => {
        onClick(status);
        if (status === "total") {
          if (totalFleet?.length === 0) return;
          else setLocations(totalFleet);
        } else if (status === "En route") {
          if (moving?.length === 0) return;
          else setLocations(moving);
        } else if (status === "Not departed") {
          if (yard?.length === 0) return;
          else setLocations(yard);
        } else if (status === "At destination") {
          if (atJobsite?.length === 0) return;
          else setLocations(atJobsite);
        }
      }}
      style={{ backgroundColor: color, cursor: "pointer" }}
    >
      <span className="statusCount">{count}</span>
      <span className="statusCountNr">{status}</span>
    </div>
  );
};

export default DashboardCard;
