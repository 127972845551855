import { groupBy } from "lodash";
import {
  incidentPdfBody,
  separateWitnessForPdf,
} from "../../../../../Incidents/Modals/utils/incidentsPdf";

function incidentReportWordData({
  dynamicFields,
  selectedTeam,
  rowObject,
  formDataUsers,
  userConfiguration,
  defaultId,
  witnessCount,
  witnessSignings,
}) {
  const incidentTmp = [
    ...dynamicFields,
    {
      label: "Team Members",
      value: selectedTeam?.map((el) => el?.members?.map((e) => e?.nameOfUser)),
      category: "General Information",
    },
  ];

  const prepareWitnessArr = separateWitnessForPdf({
    defaultId,
    rowObject,
    witnessCount,
    witnessSignings,
  });

  const witnessList = prepareWitnessArr?.map((el, i) => ({
    title: `Witness ${i + 1}`,
    body: el,
  }));

  const safetyDirectorSignatureSection = {
    title: "Safety Director Signature",
    body: [
      {
        key: "Safety Director",
        value:
          rowObject?.incidentObject?.safetyDirectorSignature?.nameOfUser || "",
      },
      {
        key: "Signature",
        value: rowObject?.incidentObject?.safetyDirectorSignature?.src || "N/A",
      },
    ],
  };

  const configPdfData = Object.keys(groupBy(incidentTmp, "category"))
    ?.filter((key) => key !== "undefined" && !!key)
    ?.map((key) => {
      return {
        title: key,
        body: incidentPdfBody({
          title: key,
          fieldsJSON: incidentTmp,
          rowObject,
          ...formDataUsers,
          userConfiguration,
        }),
      };
    });

  return [...configPdfData, ...witnessList, safetyDirectorSignatureSection];
}

export default incidentReportWordData;
