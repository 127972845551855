import { API } from "aws-amplify";
import { useEffect } from "react";

import { useRedux } from "../../../../../hooks";
import DescriptionButton from "../DescriptionButton";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";

const DescriptionInvoice = (props) => {
  const [isWritable] = useRedux("invoiceIsWritable");
  const [, setDescriptionModal] = useRedux("descriptionModal", false, true); //To show the Description Modal
  const [accountingTemplates, setAccountingTemplates] = useRedux(
    "accountingTemplates",
    [],
    false
  );

  const { invoiceData, categoryData } = useInitialInvoiceContext();
  const { setProducts: setRowData } = useCreateInvoiceContext();

  const onDescriptionChange = ({ description, rowIndex }) => {
    setRowData((prev) => {
      prev[rowIndex].rowDescription = description;
      prev[rowIndex].description = description;
      return structuredClone(prev);
    });
  };

  useEffect(() => {
    if (!accountingTemplates.length)
      API.get("accountingTemplates", "/accountingTemplates")?.then(
        setAccountingTemplates
      );
  }, []);

  return (
    <DescriptionButton
      setDescriptionModal={setDescriptionModal}
      {...props}
      disabled={!!invoiceData ? !isWritable : false}
      accountingTemplates={accountingTemplates}
      categoryData={categoryData}
      onDescriptionChange={(description) =>
        onDescriptionChange({ ...props, description })
      }
    />
  );
};

export default DescriptionInvoice;
