import React from "react";
import { ProgressComponent } from "../../../../../commonComponents";
import { useProgressContext } from "../../../../../commonComponents/ProgressComponent/context/ProgressContext";
import { useInitialInvoiceContext } from "../context/InvoiceInitialContext";

const wrapperStyle = { height: "100%" };

const InvoiceWrapper = ({ children, setConfirmData, handleCancel }) => {
  const {
    creationProgresses,
    visibleCreationProgress,
    setVisibleCreationProgress,
  } = useProgressContext();

  const { invoiceData, nextStepHandler } = useInitialInvoiceContext();

  return (
    <div style={wrapperStyle}>
      {children}
      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Invoices",
            actionType: !!invoiceData ? "Update" : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              setConfirmData();
              handleCancel();
              !invoiceData && nextStepHandler();
            },
          }}
        />
      )}
    </div>
  );
};

export default InvoiceWrapper;
