import { Checkbox, Form, Modal } from "antd";
import { MondayButton } from "../../../../../../commonComponents";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
import { XIcon } from "../../../../../Communication/assets";
import { RichText } from "../../../../../DynamicView/FormItemComponents";
import "./DescriptionModal.scss";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useRedux } from "../../../../../hooks";

const DescriptionModal = ({ readOnly = true }) => {
  const [price, setPrice] = useState(false);
  const [notes, setNotes] = useState(false);
  const [value, setValue] = useState("");
  const [form] = Form.useForm();
  const [charge] = useRedux("charge");
  const [visible, setVisible] = useRedux("descriptionModal");

  /**
   * set default values for checkboxes when load description modal
   */
  useEffect(() => {
    if (visible) {
      var span = document.createElement("span");
      span.innerHTML = visible?.value;
      setNotes(
        span.getElementsByTagName("span")["note"]?.style?.display ===
          "inline-block"
          ? true
          : false
      );
      setPrice(
        span.getElementsByTagName("span")["price"]?.style?.display ===
          "inline-block"
          ? true
          : false
      );
    }
  }, [visible]);

  /**
   * set description with new values after change value of checkboxes
   */
  useEffect(() => {
    if (visible) {
      var span = document.createElement("span");
      span.innerHTML = visible?.value;
      Array.from(span.getElementsByTagName("span"))?.map((elem) => {
        if (elem.id === "note") {
          elem.style.display = !notes ? "none" : "inline-block";
        } else if (elem.id === "price") {
          elem.style.display = !price ? "none" : "inline-block";
        }
      });
      Array.from(span.getElementsByTagName("div"))?.map((elem) => {
        if (elem.id === "note") {
          elem.style.display = !notes ? "none" : "inline-block";
        }
      });
      setValue(span.innerHTML);
    }
  }, [visible, price, notes]);

  /**
   * set description value in the form
   */
  useEffect(() => {
    value && form && form.setFieldsValue({ description: value });
  }, [value]);

  return (
    <Form form={form}>
      <Modal
        width={700}
        closeIcon={<XIcon />}
        footer={
          <div className="NewItemChargeFooter">
            <MondayButton
              onClick={() => {
                setPrice(false);
                setNotes(false);
                setValue("");
                setVisible(false);
              }}
              className="NewItemChargeFooterCancel"
              Icon={<XIcon />}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={() => {
                API.put("charges", `/charges/${charge?.chargeId}`, {
                  body: {
                    chargeItems: [
                      ...charge.chargeItems.filter(
                        ({ label }) => label !== visible?.service.label
                      ),
                      {
                        ...charge.chargeItems.find(
                          ({ label }) => label === visible?.service.label
                        ),
                        serviceDescription: form.getFieldsValue().description,
                      },
                    ],
                  },
                }).then(() => {
                  setVisible(false);
                  setPrice(false);
                  setNotes(false);
                  setValue("");
                });
              }}
              Icon={<AddIcon />}
            >
              Save
            </MondayButton>
          </div>
        }
        className="newChargeItemModal descriptionModal"
        open={visible}
        onCancel={() => setVisible(false)}
        title="Description"
      >
        <div style={{ padding: "15px 20px" }}>
          <div className="includesContainer">
            <Checkbox
              checked={price}
              onChange={(e) => setPrice(e.target.checked)}
            >
              Price
            </Checkbox>
            <Checkbox
              checked={notes}
              onChange={(e) => setNotes(e.target.checked)}
            >
              Notes
            </Checkbox>
          </div>
          <RichText
            color="white"
            form={form}
            formItemName={"description"}
            initialValue={`<span  contenteditable="false" user-select: "none" >${value}</span>`}
            disabled={readOnly}
          />
        </div>
      </Modal>
    </Form>
  );
};
export default DescriptionModal;
