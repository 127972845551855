import React from "react";
import { MondayButton } from "../../../../../../../../../../../../commonComponents/index";
import { Button } from "antd";
import { CreateCharges } from "../../index";
import PreviousRental from "../../../../MainHeader/PreviousRental/PreviousRental";
import "./RentalsMoreContent.scss";
import InvoiceCreateAdapter from "../../../../../../../../../../../Accounting/components/InvoiceCreateAdapter/InvoiceCreateAdapter";
import { useRedux } from "../../../../../../../../../../../hooks/useRedux";
import RentalLogs from "../../../../MainHeader/RentalLogs/RentalLogs";
import ChangeTax from "../../ChangeTax/ChangeTax";
import { HeaderTasks } from "../../../../../../../../../../../components/HeaderTasks/HeaderTasks";
import { Notes } from "../../../../../../../../../../../../commonComponents/Notes/Notes";
import useModal from "../../../../../../../../../../../../../hooks/useModal";
import RentalGeneralDescription from "../../ApplyTemplate/RentalGeneralDescription/RentalGeneralDescription";
import { useSelector } from "react-redux";
function RentalsMoreContent() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [fakeRentals] = useRedux("fakeRentals");
  const { CustomModal, open, close } = useModal();

  return (
    <div
      className={`more-options-rentals-panel__${isDarkMode ? "dark" : "light"}`}
    >
      {/* <CustomModal {...{ title: "this is a title" }} /> */}
      <div className="more-options-rentals-panel__item">
        <CreateCharges />
      </div>
      <div className="more-options-rentals-panel__item">
        <PreviousRental {...{ darkMode: isDarkMode }} />{" "}
      </div>{" "}
      <div className="more-options-rentals-panel__item">
        <InvoiceCreateAdapter
          {...{
            projectId: fakeRentals?.projectId,
            invoiceCategory: "rentals",
          }}
        />
      </div>
      <div className="more-options-rentals-panel__item">
        <RentalLogs />
      </div>
      <div className="more-options-rentals-panel__item">
        <ChangeTax />
      </div>
      <div className="more-options-rentals-panel__item">
        <HeaderTasks
          rowData={fakeRentals}
          {...{ darkMode: isDarkMode }}
          taskProjectName={fakeRentals?.projectAddress}
          taskItemId={fakeRentals?.rentalId}
          taskTopic="Rentals"
          type="Button"
        />
      </div>
      <div className="more-options-rentals-panel__item">
        <RentalGeneralDescription />
      </div>
    </div>
  );
}

export default RentalsMoreContent;
