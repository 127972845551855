import {getMaxNoFeet} from "../formatters";

const onRangeSelectionChanged = (params) => {
  const api = params.api;
  const cellRanges = api.getCellRanges();
  if (!cellRanges || cellRanges.length === 0) return;
  let statusBarComponent = api.getStatusPanel('StatusPanel');
  let componentInstance = statusBarComponent;
  if (statusBarComponent.getFrameworkComponentInstance) {
    componentInstance = statusBarComponent.getFrameworkComponentInstance();
  }
  let firstRange = cellRanges[0];
  const startRow = Math.min(
    firstRange.startRow.rowIndex,
    firstRange.endRow.rowIndex
  );
  const endRow = Math.max(
    firstRange.startRow.rowIndex,
    firstRange.endRow.rowIndex
  );
  if (startRow === endRow && firstRange.columns.length === 1) {
    // means the user has selected only one cell
    componentInstance.setVisible(false);
  } else {
    componentInstance.setVisible(true);
  }
  let count = 0, sum = 0;
  for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
    // eslint-disable-next-line
    for (const column in firstRange.columns) {
      let value = api.getValue(column, api.getModel().getRow(rowIndex));
      if (value) {
        switch (column.colId) {
          case 'length':
          case 'width':
          case 'height':
            count++;
            sum += getMaxNoFeet(value);
            break;
          default:
            break;
        }
      }
    }
  }
  componentInstance.setState({data: {count, sum}});
}

export default onRangeSelectionChanged