import "./GoogleSignInStep.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { MondayButton } from "../../../commonComponents";
import { LogIn } from "../../../../assets";
import { useGoogleSignIn } from "../../../../hooks/useGoogleSignIn";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import { preferences as preferencesDispatch } from "../../../../actions";
import { executeCalendarList } from "../../../Header/components/GoogleEvents/googleClientAPI/execute";
import { loadClient } from "../../../Header/components/GoogleEvents/googleClientAPI/authenticate";
import { Calendar, GoogleFilled, GoogleGreen } from "../../assets/icons";
import BoardingNavigationBtn from "../BoardingNavigationBtn/BoardingNavigationBtn";
import { googleSignInStepFinished } from "../../utils";
import { useEditLogs } from "../../../../hooks";

const GoogleSignInStep = () => {
  const dispatch = useDispatch();

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: userPrefs } = useSelector((state) => state.preferences);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { onGoogleSignIn, userDetails } = useGoogleSignIn();
  const { saveAddedLogs } = useEditLogs();

  const [calendarList, setCalendarList] = useState([]);

  const hasCalendarPreference = !!userPrefs?.calendarPreference;

  const onSignIn = () => {
    onGoogleSignIn(() => {
      try {
        //make google signIn step finished
        if (!userConfiguration?.boardingConfigs?.googleSignInStep?.finished) {
          googleSignInStepFinished({
            dispatch,
            userConfiguration,
            saveAddedLogs,
          });
        }

        let timeOut = null;

        loadClient().then(() => {
          timeOut = setTimeout(() => {
            executeCalendarList()?.then((res) => {
              setCalendarList(res?.result.items);
              clearTimeout(timeOut);
            });
          }, 1000);
        });
      } catch (error) {
        console.log("Load Client error ", error);
      }
    });
  };

  return (
    <div className={`google-sign-in-step ${isDarkMode ? "dark-mode" : ""}`}>
      {userDetails ? (
        hasCalendarPreference ? (
          <>
            <GoogleGreen style={{ width: "50px", height: "50px" }} />

            <div className="signed-in-text">Successfully Signed In</div>
            <span style={{ textAlign: "center" }}>You're all set!</span>
          </>
        ) : (
          <>
            <Calendar style={{ width: "50px", height: "50px" }} />

            <span style={{ textAlign: "center", margin: "10px 0" }}>
              Please select a preferred calendar! <br /> Selecting a Google
              Calendar will provide{" "}
              <span style={{ fontWeight: 600 }}> more information </span>about
              events.
            </span>
            <InputComponent
              className="calendar-select-input"
              type="select"
              label="Calendar"
              defaultValue={userPrefs?.calendarPreference || "primary"}
              customOptions={calendarList.map((calendar) => ({
                label: calendar.summary,
                value: calendar.summary,
              }))}
              onChange={(value) => {
                API.patch("preferences", "/preferences", {
                  body: { calendarPreference: value },
                }).then((res) => {
                  message.success("Calendar preference saved!");
                  dispatch(
                    preferencesDispatch({
                      ...userPrefs,
                      calendarPreference: value,
                    })
                  );
                });
              }}
            />
          </>
        )
      ) : (
        <>
          <GoogleFilled style={{ width: "50px", height: "50px" }} />

          <div style={{ fontSize: "45px" }}>Sign In</div>
          <span style={{ textAlign: "center" }}>
            Signing in with your Google account offers a smooth experience
            <br /> and{" "}
            <span style={{ fontWeight: 600 }}>
              unlocks additional features
            </span>{" "}
            powered by Google APIs.
          </span>

          <MondayButton
            {...{
              onClick: onSignIn,
              className: "mondayButtonBlue",
              Icon: <LogIn />,
            }}
          >
            Sign In
          </MondayButton>
        </>
      )}

      <BoardingNavigationBtn {...{ autoStepConfig: false }} />
    </div>
  );
};

export default GoogleSignInStep;
