import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const defects = ({ tables, programFields }) => {
  const defectStatuses = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Defects;

  const defectPriority = programFields?.find?.(
    ({ fieldName }) => fieldName === "Priority Status"
  )?.fieldOptions;

  return {
    defectStatus: () => ({
      labels: defectStatuses?.map?.(({ statusName }) => statusName),
      data: uniq(
        tables?.Defects?.map?.(({ defectStatus }) => defectStatus)
      )?.map(
        (status) =>
          tables?.Defects?.filter?.((a) => a.defectStatus === status)?.length
      ),
      backgroundColor: defectStatuses?.map((a) => a?.statusColor),
    }),

    defectName: () => ({
      labels: uniq(tables?.Defects?.map?.(({ defectName }) => defectName)),
      data: uniq(tables?.Defects?.map?.(({ defectName }) => defectName))?.map(
        (status) =>
          tables?.Defects?.filter?.(
            (a) => a?.defectName?.toLowerCase() === status?.toLowerCase()
          )?.length
      ),
      backgroundColor: uniq(tables?.Defects?.map?.(() => getRandomColor())),
    }),

    driverName: () => ({
      labels: uniq(tables?.Defects?.map?.(({ driverName }) => driverName)),
      data: uniq(tables?.Defects?.map?.(({ driverName }) => driverName))?.map(
        (status) =>
          tables?.Defects?.filter?.((a) => a?.driverName === status)?.length
      ),
      backgroundColor: uniq(tables?.Defects?.map?.(() => getRandomColor())),
    }),

    fleetName: () => ({
      labels: uniq(tables?.Defects?.map?.(({ fleetName }) => fleetName)),
      data: uniq(tables?.Defects?.map?.(({ fleetName }) => fleetName))?.map(
        (status) =>
          tables?.Defects?.filter?.((a) => a?.fleetName === status)?.length
      ),
      backgroundColor: uniq(tables?.Defects?.map?.(() => getRandomColor())),
    }),

    inspectionName: () => ({
      labels: uniq(
        tables?.Defects?.map?.(({ inspectionName }) => inspectionName)
      ),
      data: uniq(
        tables?.Defects?.map?.(({ inspectionName }) => inspectionName)
      )?.map(
        (status) =>
          tables?.Defects?.filter?.((a) => a?.inspectionName === status)?.length
      ),
      backgroundColor: uniq(tables?.Defects?.map?.(() => getRandomColor())),
    }),

    defectPriority: () => ({
      labels: defectPriority?.map?.(({ statusName }) => statusName),
      data: uniq(
        tables?.Defects?.map?.(({ defectPriority }) => defectPriority)?.map(
          (status) =>
            tables?.Defects?.filter?.((a) => a?.defectPriority === status)
              ?.length
        )
      ),
      backgroundColor: uniq(tables?.Defects?.map?.(() => getRandomColor())),
    }),
  };
};
