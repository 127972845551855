import { useCallback } from "react";
import languageTable from "./languageTable";
import translateWord from "./translateWord";
import translateSentenceFn from "./translateSentence";
import { useLanguageContext } from "./LanguageContext";

function useTranslate() {
  const languageContext = useLanguageContext();
  const { language = "en", changeLanguage = () => {} } = languageContext || {};
  const t = useCallback(
    function (word) {
      return translateWord(word, languageTable[language]);
    },
    [language]
  );

  const translateSentence = useCallback((sentence, splitter = " ") => {
    return translateSentenceFn(sentence, language, splitter);
  });
  function getMissingTranslations() {
    const missingTranslations = JSON.parse(
      localStorage.getItem("missingTranslations")
    );
    return missingTranslations || {};
  }

  return {
    language,
    changeLanguage,
    translations: languageTable[language],
    t,
    translateSentence,
    getMissingTranslations,
  };
}

export default useTranslate;
