function calcTaskProgress(start: number, end: number): number {
  const now = Date.now();
  if (now < start) {
    return 0;
  } else if (now > end) {
    return 100;
  } else {
    return ((now - start) / (end - start)) * 100;
  }
}

export default calcTaskProgress;
