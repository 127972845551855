import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { isDateBetween } from "../../../../../../../Header/components/ModalVideoTutorials/utils/isDateBetween";
import { getNestedPropertyValue } from "./getNestedPropertyValue";

export const getModifiedChartData = (
  data,
  chartConfigs,
  startDate,
  endDate
) => {
  const allData = {};

  chartConfigs?.forEach((config) => {
    let groupedData = {};
    let pieData = [];

    data
      .filter((item) => isDateBetween(item.createdAt, startDate, endDate))
      .forEach((item) => {
        const groupValue =
          getNestedPropertyValue(item, config.groupKey) || "No Data";

        // if it has the key 'calcKey' then calculate the amount
        if (config.hasOwnProperty("calcKey")) {
          groupedData.hasOwnProperty(groupValue)
            ? (groupedData[groupValue] += item[config.calcKey] || 0)
            : (groupedData[groupValue] = item[config.calcKey] || 0);
        } else {
          // check if it is array and has items of type string
          if (Array.isArray(groupValue)) {
            groupValue.forEach((value) => {
              if (typeof value === "string") {
                groupedData.hasOwnProperty(value)
                  ? (groupedData[value] += 1)
                  : (groupedData[value] = 1);
              }
            });
          } else {
            // if it is timestamp convert with dayJS
            const timeStampData = config.hasOwnProperty("timeStamp")
              ? dayjsNY(groupValue).format("MM/DD/YYYY")
              : groupValue;

            groupedData.hasOwnProperty(timeStampData)
              ? (groupedData[timeStampData] += 1)
              : (groupedData[timeStampData] = 1);
          }
        }
      });

    pieData = Object.entries(groupedData).map(([category, value]) => ({
      category,
      value,
    }));

    const barData = {
      bar: [{ ...groupedData, xAxisName: config.xAxisName }],
      pie: pieData,
    };

    allData[config.chartName] = barData;
  });

  return allData;
};
