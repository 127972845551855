export const claims = (updatedDynamicStates, IdHandler, programFields) => {
  const statusesOfClaims = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Claims;

  return {
    claimStatus: () => ({
      title: "Claims",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Claims",
        field: "claimStatus",
        title: "Claims Status ",
        subtitle: "",
        content: statusesOfClaims?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (e) => e?.claimStatus === status?.statusName
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status?.statusName,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (e) => e?.claimStatus === status?.statusName
          )?.length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
