import React, { useEffect, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { InspectionModal } from "..";
import "./SignatureModal.scss";

const SignatureModal = ({
  visible,
  setVisible,
  sign,
  setSign,
  isDarkMode,
  editSignIn = false,
  setEditSignIn = () => {},
}) => {
  const [signatureRef, setSignatureRef] = useState(null);
  const [defaultSign, setDefaultSign] = useState(null);
  const [dragged, setDragged] = useState(false);

  useEffect(() => {
    if (editSignIn && sign && signatureRef) {
      signatureRef.fromDataURL(sign);
      setDefaultSign(sign);
      setDragged(true);
    } else if (!editSignIn && signatureRef) {
      signatureRef.clear();
      setDragged(false);
    }
  }, [editSignIn, sign, signatureRef]);

  const editSign = () => {
    setDragged(false);
    setSign?.(defaultSign);
    signatureRef.fromDataURL(defaultSign);
  };

  const resetSign = (isEdit) => {
    setDragged(false);
    setSign?.(null);
    signatureRef.clear();

    isEdit && editSign();
  };

  const handleOnSignatureSave = () => {
    setSign?.(signatureRef?.getTrimmedCanvas()?.toDataURL("image/png"));
    setDragged(false);
    signatureRef.clear();
    !!editSignIn && setEditSignIn(false);
    setVisible(false);
  };

  return (
    <InspectionModal
      visible={visible}
      setVisible={setVisible}
      className={`signatureModal ${isDarkMode && "signatureModalDark"}`}
      title="Signature"
      footer={[
        {
          text: "cancel",
          onClick: () => {
            setVisible(false);
            !editSignIn ? resetSign() : setEditSignIn(false);
          },
          primary: false,
        },
        {
          text: "reset",
          onClick: () => (!!editSignIn ? resetSign(true) : resetSign()),
          danger: true,
          disabled: !dragged,
        },
        {
          text: "save",
          onClick: () => handleOnSignatureSave(),
          primary: true,
          disabled: !dragged,
        },
      ]}
    >
      <div
        className="inspectionSignContainer"
        style={{ padding: 0, marginTop: 0, height: "200px" }}
      >
        <SignaturePad
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          ref={(ref) => setSignatureRef(ref)}
          onBegin={() => setDragged(true)}
        />
      </div>
    </InspectionModal>
  );
};

export default SignatureModal;
