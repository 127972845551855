import { useEffect, useMemo, useState } from "react";
import { Dropdown, Space, message } from "antd";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import {
  generateParams,
  convertDataOfPDF,
  createDriveFolders,
  generateSelectedColumns,
  defaultExportGridToExcel,
  generateExcelAndUploadToDrive,
} from "./utils";
import { DropdownIcon } from "src/icons";
import MondayButton from "../MondayButton/MondayButton";
import createPDF from "../../../integrations/AgGridToPdf";
import { driveApi } from "../../../integrations/DriveRequest";
import { useGoogleSignIn } from "../../../hooks/useGoogleSignIn";
import { savePdfToDrive } from "../../SidebarPages/Safety/safetyPdf";
import { updateTable } from "../../SidebarPages/Communication/functions";
import getDocDefinition from "../../../integrations/AgGridToPdf/docDefinition";
import { state } from "../../pages/Settings/settingsComponents/Roles/RolesData";
import { compareIncludingAccurate } from "../../SidebarPages/utils/compareIncluding";
import EmailBox from "../../SidebarPages/Communication/components/EmailBox/EmailBox";
import getDocOrderDefinition from "../../../integrations/AgGridToPdf/docOrderDefinition";
import "./ExportOrEmailDropdown.scss";
import { useLocation } from "react-router-dom";
import { ReportModal } from "../../SidebarPages/BasePage/components";
import { useEditLogs } from "../../../hooks";

const ExportOrEmailDropdown = ({
  title,
  gridApi,
  rowData,
  gridColumnApi,
  topicCategory,
  formatCellValueHandler, // formating column that is array for pdf and email
  defaultDocDefinition = false, // docDefinition when we dont have agGrid
  uploadExcelToDrive = false, // custom function for uploading excel to drive when we dont have agGrid
  onGeneratePDF = false, // custom export to PDF function
  agGridToEmail = false,
  exportToExcel = () => {}, // custom export to Excel function for agGrid table that needs to modify cell values
  exportGridToExcel = false, // custom export to Excel function
  defaultDataRowData = false, // When adding data that its not inside gridApi
  position = false,
  returnOnlyItems = false,
  tooltipCategory,
  tooltipKey,
  noTooltip,
  customItems = [],
  customForceEnable = false,
  // isCustomButtonEnabled = true
}) => {
  const location = useLocation();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { googleAccessToken } = useGoogleSignIn();

  const { base64 } = useSelector((state) => state.base64);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const driveRequest = driveApi({ accessToken });
  const { saveAddedLogs } = useEditLogs();

  const [emails, setEmails] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [filesId, setFilesId] = useState([]);
  const [folderId, setFolderId] = useState();
  const [emailBoxes, setEmailBoxes] = useState([]);
  const [docDefinition, setDocDefinition] = useState();
  const [selectedDraft, setSelectedDraft] = useState();
  const [openDownloadModal, setOpenDownloadModal] = useState({
    open: false,
    action: "download",
  });
  const [downloadType, setDownloadType] = useState("pdf");

  const params = generateParams({ title, base64 });

  const selectedColumns = generateSelectedColumns(gridColumnApi);

  const recordDetails = useMemo(() => {
    return {
      categoryType: location?.pathname?.split("/")[1],
      recordId: v4(),
    };
  }, [location?.pathname]);

  const topic = topicCategory || title;

  const hasEmailAccess = userConfiguration?.routeConfig
    ?.find((item) => item.title === "Communication")
    ?.children.find((item) => item.title === topic)?.write;

  const generateLog = (actionType, recordName) => {
    // added pdfDownloadLogName to add custom keys and values for newLog

    saveAddedLogs({ actionType, category: title, recordName });
  };

  const agGridToPDF = async ({
    params,
    gridApi,
    rowData,
    history,
    historyTab,
    gridColumnApi,
    base64,
    isOrder,
    masterDetail = false,
    type,
  }) => {
    const docDefinition = defaultDocDefinition
      ? type === "pdf"
        ? await onGeneratePDF("download", true)
        : exportGridToExcel(true)
      : !isOrder
      ? getDocDefinition({
          params,
          rowData,
          gridApi,
          gridColumnApi,
          history,
          historyTab,
          base64,
          masterDetail,
          formatCellValueHandler,
          forExcelAndPdfAttachment: type === "pdf" ? false : true,
          dataIsRowData: defaultDataRowData,
        })
      : getDocOrderDefinition({
          params,
          gridApi,
          gridColumnApi,
          history,
          historyTab,
          base64,
        });

    await createDriveFolders({
      folderName: "tempEmail",
      driveParentId: "1PigBk7aK9xuNM4Q6IgsFIYNJQsXgJrht",
      driveRequest,
      title,
    }).then(async (result) => {
      setFolderId(result.folderId);
      const updatedFormat = !defaultDocDefinition
        ? convertDataOfPDF(docDefinition.content[0].table.body)
        : docDefinition;
      setDocDefinition(updatedFormat);

      if (type === "pdf") {
        const pdfResult = await savePdfToDrive({
          docDefinition,
          accessToken,
          folderId: result.folderId,
          title: `${title}.pdf`,
        });

        setFilesId((prev) => [...prev, pdfResult?.id]);
      } else {
        const excelResult = uploadExcelToDrive
          ? await uploadExcelToDrive(driveRequest, result.folderId)
          : await generateExcelAndUploadToDrive(
              title,
              docDefinition.content[0].table.body,
              driveRequest,
              result.folderId
            );

        setFilesId((prev) => [...prev, excelResult?.id]);
      }
    });
  };

  const defaultOnGeneratePDF = (action, tableColumn) => {
    generateLog(
      action === "download" ? "Download" : "Print",
      action === "download" ? "Download PDF" : "Print PDF"
    );

    return createPDF({
      action,
      gridApi,
      rowData,
      gridColumnApi,
      tableColumn,
      base64,
      formatCellValueHandler,
      ...(defaultDataRowData ? { dataIsRowData: true } : {}),
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: title,
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncludingAccurate(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  };

  const openEmailBox = async (type) => {
    if (emailBoxes.length === 1) {
      message.warning("You cannot open more than two mailboxes");
    } else {
      type !== "noAttach" &&
        (await agGridToPDF({ params, gridApi, rowData, gridColumnApi, type }));

      setEmailBoxes((prev) =>
        [
          ...prev,
          {
            Component: EmailBox, // Assuming EmailBox is a valid component
            key: emailBoxes.length,
          },
        ].filter((box) => {
          return emailBoxes.length > 1 ? box.id !== undefined : true;
        })
      );
    }
  };

  // on save email callback
  const onEmailSave = ({ email, key, type, setLoading }) => {
    const { draftId } = email;
    if (type.toUpperCase() === "EMAIL") {
      setEmailBoxes((prev) => prev?.filter((box) => box.key !== key)); //remove current email box
      setEmails((prev) => [...prev, email]);
      message.success("Email sent");
      setLoading(false);
    } else {
      //for drafts
      if (
        rowData.drafts?.find((draft) => draft.draftId === draftId) === undefined
      ) {
        updateTable(
          recordDetails?.categoryType === "permitdrawings"
            ? "permitDrawings"
            : recordDetails?.categoryType,
          recordDetails?.recordId,
          "drafts",
          [
            ...(rowData.drafts || []),
            {
              id: email.id,
              threadId: email.threadId,
              draftId: email.draftId,
            },
          ]
        );
        rowData.drafts = [
          ...(rowData.drafts || []),
          {
            id: email.id,
            threadId: email.threadId,
            draftId: email.draftId,
          },
        ];
        setDrafts(state(drafts));
      }
      drafts.map((draft) =>
        draft.draftId === draftId ? (draft = email) : draft
      );
      message.success("Draft saved");
    }
  };

  useEffect(() => {
    //Delete PDF from drive if it exists
    return () => {
      if (filesId) filesId.map((id) => driveRequest.deleteDriveItem(id));
    };
  }, [filesId]);

  const isButtonDisabled = customForceEnable
    ? !customForceEnable
    : !rowData || rowData?.length < 1;

  const items = [
    {
      key: "1",
      label: "Download PDF",
      onClick: () =>
        onGeneratePDF
          ? onGeneratePDF("download")
          : (setOpenDownloadModal({ open: true, action: "download" }),
            setDownloadType("pdf")),
    },
    {
      key: "2",
      label: "Print PDF",
      onClick: () =>
        onGeneratePDF
          ? onGeneratePDF("print")
          : (setOpenDownloadModal({ open: true, action: "print" }),
            setDownloadType("pdf")),
    },
    {
      key: "3",
      label: "Export To Excel",
      onClick: () =>
        exportGridToExcel
          ? exportGridToExcel()
          : exportToExcel
          ? (setOpenDownloadModal({ open: true, action: "print" }),
            setDownloadType("excel"))
          : defaultExportGridToExcel(
              defaultDataRowData,
              gridApi,
              rowData,
              title,
              generateLog
            ),
    },
    {
      key: "4",
      label: "Email",
      popupClassName: isDarkMode ? "export-email-dropdown-darkmode" : "",
      children: [
        {
          key: "4-1",
          label: "Send",
          onClick: () => openEmailBox("noAttach"),
        },
        {
          key: "4-2",
          label: "Attach PDF",
          onClick: () => openEmailBox("pdf"),
        },
        {
          key: "4-3",
          label: "Attach Excel",
          onClick: () => openEmailBox("excel"),
        },
      ],
      disabled: !hasEmailAccess,
    },
  ];

  const onEmailBoxClose = (key) => {
    if (filesId.length > 0)
      driveRequest
        .deleteDriveItem(folderId)
        .then(() => {
          message.success("Folder deleted successfully");
          setFilesId([]);
        })
        .catch(() => message.error("Folder deletion failed"));

    setEmailBoxes((prev) => prev?.filter((box) => box.key !== key));
  };

  if (returnOnlyItems) {
    return items;
  }

  return (
    <div className="pdfExcelDropdown">
      <Dropdown
        menu={{ items: [...items, ...customItems] }}
        disabled={isButtonDisabled}
        overlayClassName={isDarkMode ? "export-email-dropdown-darkmode" : ""}
      >
        <MondayButton
          data-testid="actions-button"
          className="mondayButtonBlue"
          Icon={<DropdownIcon />}
          disabled={isButtonDisabled}
          tooltipCategory={tooltipCategory}
          tooltipKey={tooltipKey || "exportActions"}
          noTooltip={noTooltip}
        >
          <Space>Actions</Space>
        </MondayButton>
      </Dropdown>

      <div className={`emailBoxContainer ${position ? "position-fixed" : ""}`}>
        {emailBoxes.map(({ Component, key }) => (
          <Component
            {...{
              rowData,
              key,
              onClose: () => onEmailBoxClose(key),
              onSave: (email, type, setLoading) =>
                onEmailSave({ email, key, type, setLoading }),
              selectedColumns,
              setEmails,
              emails,
              authToken: googleAccessToken || false,
              isRedirected: filesId.length > 0 ? filesId : false,
              selectedDraft,
              docDefinition,
              openEmailBox,
              agGridToEmail,
              recordID: recordDetails.recordId,
              exportOrEmailDropdown: true,
            }}
          />
        ))}
      </div>
      {openDownloadModal.open && (
        <ReportModal
          title={
            downloadType === "pdf"
              ? openDownloadModal.action === "download"
                ? "Download Pdf"
                : "Print pdf"
              : "Download Excel"
          }
          gridApi={gridApi}
          modalTitle={`${
            downloadType === "pdf" ? "PDF" : "EXCEL"
          } Generator based on columns`}
          rowData={rowData}
          isReportModalOpen={openDownloadModal.open}
          setIsReportModalOpen={(val) =>
            setOpenDownloadModal({ open: val, action: "download" })
          }
          actions={openDownloadModal.action}
          isReport={false}
          defaultOnGeneratePDF={(action, tableColumns) =>
            downloadType === "pdf"
              ? defaultOnGeneratePDF(action, tableColumns)
              : exportToExcel(tableColumns)
          }
        />
      )}
    </div>
  );
};

export default ExportOrEmailDropdown;
