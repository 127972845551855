import React, { useCallback, useEffect, useState } from "react";
import ProposalCard from "./ProposalCard";
import NewProposal from "../../NewProposal/NewProposal";
import { fetchData } from "../../../SidebarPages/Fleet/utils";
import { useRedux } from "../../../SidebarPages/hooks";
import { Carousel } from "antd";
import ProposalCardsCarousel from "./ProposalCardsCarousel/ProposalCardsCarousel";

function ProposalCards() {
  const [proposalBuildsData, setProposalBuildsData] = useState([]);
  const [filteredProposalData, setFilteredProposalData] = useState(null);
  const [proposalData] = useRedux("proposalData");
  const dataToRender =
    filteredProposalData !== null ? filteredProposalData : proposalBuildsData;
  // const renderProposalCards = () => {
  //   const dataToRender =
  //     filteredProposalData !== null ? filteredProposalData : proposalBuildsData;
  //   console.log("dataToRender", dataToRender);
  //   return dataToRender
  //     ?.sort((a, b) => b?.createdAt - a?.createdAt)
  //     ?.map((item) => (
  //       <ProposalCard
  //         key={item.id}
  //         title={item?.proposalStatus ?? item?.otherDetails?.title}
  //         item={item}
  //       />
  //     ));
  // };
  useEffect(() => {
    fetchData("proposalBuilds").then((data) => {
      setProposalBuildsData(data.sort((a, b) => b?.createdAt - a?.createdAt));
    });
  }, []);

  console.log("proposalBuildsData", dataToRender);

  useEffect(() => {
    if (proposalData?.filter?.filter) {
      const filteredData = proposalBuildsData
        .filter((item) => {
          return item?.projectId === proposalData?.filter?.project;
        })
        .sort((a, b) => b?.createdAt - a?.createdAt);
      setFilteredProposalData(filteredData);
    } else {
      setFilteredProposalData(null);
    }
  }, [proposalData?.filter, proposalBuildsData]);

  return (
    <div className="proposal-cards-wrapper">
      <ProposalCardsCarousel dataToRender={dataToRender} />
      <NewProposal />
    </div>
  );
}

export default ProposalCards;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
