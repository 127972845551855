//@ts-check
import { API } from "aws-amplify";
import { updateExistingPayments } from "./updateExistingPayments";
import {
  filterTables,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../utils";

export const updateInvoices = async ({
  invoices,
  newChargeObject,
  projectTaxRate,
  accountId,
  onClose,
}) => {
  const paymentData = await filterTables("payments", "accountId", accountId);

  await invoices.map(async ({ invoiceId, invoiceItems, charges }) => {
    const paymentAmount = paymentData
      .flatMap((payment) =>
        payment.invoices.filter((invoice) => invoice.invoiceId === invoiceId)
      )
      .reduce((total, invoice) => total + invoice.invoicePaidAmount, 0);

    const invoicedChargeItems = newChargeObject.chargeItems.filter(
      (charge) => charge.invoiceId === invoiceId
    );

    const newInvoiceItems = invoiceItems.map((invoiceItem) => {
      const newCreditedItems = invoicedChargeItems?.find(
        (chargeItem) => chargeItem.id === invoiceItem.id
      );

      const oldCredits = invoiceItem?.credits || [];
      const newCredits = oldCredits.filter(
        (item) => item.chargeId !== newChargeObject.chargeId
      );

      const otherChargesCreditedAmount = newCredits.reduce(
        (total, credit) => total + credit.creditedAmount,
        0
      );
      const otherChargesCreditedTaxAmount = newCredits.reduce(
        (total, credit) => total + credit.creditedTaxAmount,
        0
      );

      const newCreditedAmount = newCreditedItems?.creditAmount || 0;
      const newCreditedTaxAmount = newCreditedItems?.taxAmount || 0;

      newCredits.push({
        chargeId: newChargeObject.chargeId,
        creditedAmount: newCreditedAmount,
        creditedTaxAmount: newCreditedTaxAmount,
      });

      const totalCreditedAmount =
        (otherChargesCreditedAmount || 0) + newCreditedAmount;

      const totalCreditedTaxAmount =
        (otherChargesCreditedTaxAmount || 0) + newCreditedTaxAmount;

      const newInvoiceAmount =
        (invoiceItem.scopeAmount || invoiceItem.amount) +
        otherChargesCreditedAmount -
        totalCreditedAmount;

      const newInvoiceTaxAmount =
        (invoiceItem.scopeTaxAmount || invoiceItem.taxAmount) -
        totalCreditedTaxAmount;

      return {
        ...invoiceItem,
        amount: newInvoiceAmount,
        taxAmount: newInvoiceTaxAmount,
        credits: newCredits,
        creditedAmount: totalCreditedAmount,
        creditedTaxAmount: totalCreditedTaxAmount,
        scopeTaxAmount: invoiceItem.tax
          ? invoiceItem.scopeAmount * projectTaxRate
          : 0,
      };
    });

    const updatedCharges = [
      ...charges?.filter(
        ({ chargeId }) => chargeId !== newChargeObject?.chargeId
      ),
      {
        chargeId: newChargeObject?.chargeId,
        chargeNumber: newChargeObject?.chargeNumber,
        chargeType: newChargeObject?.chargeType,
        chargeAmount: newChargeObject?.chargeAmount,
        chargeTaxAmount: newChargeObject?.taxAmount,
      },
    ];

    const creditCharges = updatedCharges.filter(
      (charge) => charge.chargeType === "Credit Memo"
    );

    const newCreditedAmount = creditCharges.reduce(
      (total, charge) => total + charge.chargeAmount,
      0
    );

    const newAmountDue = newInvoiceItems?.reduce((total, invoiceItem) => {
      return (total +=
        (invoiceItem.scopeAmount || invoiceItem.total) +
        (invoiceItem.tax ? invoiceItem.scopeTaxAmount || 0 : 0) -
        ((invoiceItem.creditedAmount || invoiceItem.creditAmount || 0) +
          (invoiceItem.creditedTaxAmount || invoiceItem.creditTaxAmount || 0)));
    }, 0);

    const newAmountDuePayment = newInvoiceItems?.reduce(
      (total, invoiceItem) => {
        return (total +=
          (invoiceItem.scopeAmount || invoiceItem.total) +
          (invoiceItem.tax ? invoiceItem.scopeTaxAmount || 0 : 0) -
          ((invoiceItem.creditedAmount || invoiceItem.creditAmount) +
            (invoiceItem.creditedTaxAmount ||
              invoiceItem.creditTaxAmount ||
              0)));
      },
      0
    );

    await API.put("invoices", `/invoices/${invoiceId}`, {
      body: {
        amountDue: newAmountDue - (paymentAmount || 0),
        invoiceItems: newInvoiceItems,
        charges: updatedCharges,
      },
    })
      .then(async () => {
        showLoadingMsg({ content: "Updating Payments. Please wait..." });
        await updateExistingPayments({
          paymentData,
          invoiceId,
          newAmountDue: newAmountDuePayment,
          newCreditedAmount,
        });
      })
      .then(() => {
        showSuccessMsg({ content: "Credit saved successfully." });
        onClose();
      })
      .catch((error) => {
        console.log("Error updating invoice:", error);
        showErrorMsg({
          content: "Error updating invoices. Please contact the administrator.",
        });
      });
  });
};
