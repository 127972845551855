import { Tick } from "../../../../../../../../../../DynamicView/components/FilterView/FilterIcons";
import {
  useStateContext,
  useDispatchContext,
} from "../../../../../../Context/Context";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { PercentageIcon } from "../../../../../../../../../../BasePage/src";
import { Close } from "../../../../../../../../../../DynamicView/src";
import {
  handleChangeRetainage,
  handleChangeRetForAll,
} from "./retainageModalFunctions";
import "./retainage-modal.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, Input } from "antd";
import { useState } from "react";
import { EditableModeWrapper } from "../../../../../EditableModeWrapper";
import { message } from "antd";

const RetainageModal = ({ service }) => {
  const [retainageState, setRetainageState] = useState(service?.retainage || 0);
  const [isRetainageModalVisible, setIsRetainageModalVisible] = useState(false);
  const { scheduleId } = useParams();
  const dispatch = useDispatch();
  const { serviceId } = service;

  // Context state & dispatch
  const { fakeSOV, darkMode, isWritable } = useStateContext();
  const dispatchContext = useDispatchContext();

  const closeRetainageModal = () => {
    setIsRetainageModalVisible(false);
  };

  const changeRetainage = () => {
    return handleChangeRetainage({
      closeRetainageModal,
      dispatchContext,
      retainageState,
      serviceId,
      fakeSOV,
    });
  };

  const changeRetainageForAll = () => {
    return handleChangeRetForAll({
      closeRetainageModal,
      dispatchContext,
      retainageState,
      serviceId,
      fakeSOV,
    });
  };

  const onChangeRetainageClick = (e) => {
    if (isWritable) {
      e?.stopPropagation();
      setIsRetainageModalVisible(true);
    } else {
      message.error("Please enable Edit Mode!");
    }
  };

  return (
    <div
      className="retainageModalContainer"
      onClick={(e) => e?.stopPropagation()}
    >
      <div className="buttonContainer">
        <MondayButton
          className={`changeRetainageSOVButton ${
            !isWritable ? "buttonNotClickable" : ""
          }`}
          onClick={onChangeRetainageClick}
          Icon={<PercentageIcon />}
          //disabled={!isWritable}
        >
          Change Retainage
        </MondayButton>
      </div>
      <Modal
        open={isRetainageModalVisible}
        title="Change Retainage"
        destroyOnClose={true}
        wrapClassName={
          darkMode
            ? "serviceHeaderRetainageModalHoistDark"
            : "serviceHeaderRetainageModalHoist"
        }
        onCancel={closeRetainageModal}
        onOk={() => changeRetainage()}
        footer={
          <div className="serviceFooterRetainageModal">
            <MondayButton
              className="cancelMondayButton"
              Icon={<Close />}
              onClick={closeRetainageModal}
            >
              Cancel
            </MondayButton>
            <MondayButton
              className="applyMondayButton"
              Icon={<Tick />}
              onClick={() => changeRetainage()}
            >
              Apply
            </MondayButton>{" "}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<Tick />}
              onClick={() => changeRetainageForAll()}
            >
              Apply to All Services
            </MondayButton>
          </div>
        }
      >
        <Input
          placeholder="Enter new Retainage"
          value={retainageState}
          className="changeRetainageInput"
          onChange={(e) => setRetainageState(Number(e.target.value))}
        />
      </Modal>
    </div>
  );
};

export default RetainageModal;
