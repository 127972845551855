/**
 * * Quick Sort Implementation
 */
export const quickSort = (
	inputList: object[],
	low: number,
	high: number,
	key: string,
	order: string = "ascending"
): object[] => {
	if (!Array.isArray(inputList)) {
		throw new Error("THIS IS NOT AN ARRAY!");
	}
	if (low < high) {
		// get the partition index.
		const pIndex: number = partition(inputList, low, high, key, order);
		// recursively call the quickSort method again.
		quickSort(inputList, low, pIndex - 1, key, order);
		quickSort(inputList, pIndex + 1, high, key, order);
	}
	return inputList;
};

const partition = (
	partitionList: object[],
	low: number,
	high: number,
	key: string,
	order: string
): number => {
	const pivot: object = partitionList[high];
	let pIndex = low;
	for (let index = low; index <= high - 1; index++) {
		if (order === "ascending") {
			if (partitionList[index][key] < pivot[key]) {
				// swap variables using array destructuring
				[partitionList[index], partitionList[pIndex]] = [
					partitionList[pIndex],
					partitionList[index],
				];
				pIndex += 1;
			}
		} else if (order === "descending") {
			if (partitionList[index][key] > pivot[key]) {
				// swap variables using array destructuring
				[partitionList[index], partitionList[pIndex]] = [
					partitionList[pIndex],
					partitionList[index],
				];
				pIndex += 1;
			}
		}
	}
	[partitionList[pIndex], partitionList[high]] = [
		partitionList[high],
		partitionList[pIndex],
	];
	return pIndex;
};

/**
 * * Merge Sort Implementation
 */
export const mergeSort = (
	list: object[],
	key: string,
	order: string = "ascending"
): object[] => {
	if (!Array.isArray(list)) throw new Error("THIS IS NOT AN ARRAY!");

	if (list.length < 2) return list;
	const listHalf: number = Math.floor(list.length / 2);
	const subList: object[] = list.slice(0, listHalf);
	const subList2: object[] = list.slice(listHalf);
	return merge(
		mergeSort(subList, key, order),
		mergeSort(subList2, key, order),
		key,
		order
	);
};

const merge = (
	list1: object[],
	list2: object[],
	key: string,
	order: string
): object[] => {
	const results: object[] = [];
	let i: number = 0;
	let j: number = 0;
	while (i < list1.length && j < list2.length) {
		if (order === "ascending") {
			if (list1[i][key] < list2[j][key]) {
				results.push(list1[i++]);
			} else {
				results.push(list2[j++]);
			}
		} else if (order === "descending") {
			if (list1[i][key] > list2[j][key]) {
				results.push(list1[i++]);
			} else {
				results.push(list2[j++]);
			}
		}
	}
	return results.concat(list1.slice(i), list2.slice(j));
};
