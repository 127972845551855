import { useState, useEffect, useContext, useRef, Fragment } from "react";
import { message } from "antd";
import { API } from "aws-amplify";

import {
  getAllChecked,
  mergeDispatches,
  FleetsLiveContext,
  loadLivePreference,
  saveLocalPreferences,
} from "../../utils";
import { filterTables } from "src/utils";
import LiveAuditContext from "./LiveAuditContext";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { _CREATE_HEADERS } from "src/integrations/DriveRequest/driveStatics";
import {
  AuditFooter,
  AuditSidebar,
  AuditActions,
  AuditController,
} from "./components";
import { NewDispatchModal } from "../../../Dispatch/modals";

import "./LiveAuditView.scss";

function LiveAuditView() {
  //#region HOOKS
  const {
    schedules,
    dispatches,
    selectedDate,
    broadcastUpdates,
    handleDispatchChange,
  } = useContext(FleetsLiveContext);

  /**
   * The PENDING and the COMPLETED statuses can be easily calculated
   * so there's no need to save this status in the database. We only
   * need to save the UPLOADED status, since we have no clear way to
   * judge that status purely from the frontend
   */
  const [auditStatus, setAuditStatus] = useState(
    /** @type {"UPLOADED"|"COMPLETED"|"PENDING"|""} */ ("")
  );
  const [viewMode, setViewMode] = useState(
    /** @type {"MAP"|"OVERVIEW"} */ ("OVERVIEW")
  );
  const [dispatchData, setDispatchData] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [goToLocation, setGoToLocation] = useState(
    /** @type {"CLICK"|"HOVER"} */ (loadLivePreference("auditGoToLocation"))
  );
  const [reasons, setReasons] = useState([]);
  const [reasonField, setReasonField] = useState("");

  const actionsRef = useRef(null);
  const controllerRef = useRef(null);
  const dispatchModalRef = useRef(null);
  const footerRef = useRef(null);

  function onVehicleSelect(fleetId) {
    //#region ON VEHICLE SELECT
    if (fleetId === selectedVehicle) {
      return;
    }

    setSelectedVehicle(fleetId);

    if (actionsRef?.current) {
      actionsRef?.current?.changeSelectedVehicle(fleetId);
    }
    if (controllerRef?.current) {
      controllerRef?.current?.changeFleetName(fleetId);
    }
    resetChangedStatus();
    saveLocalPreferences({ auditSelectedVehicle: fleetId });
  }

  useEffect(() => {
    //#region GET PROGRAM FIELDS
    filterTables("programFields", "fieldName", "Audit Reasons")
      .then((res) => {
        setReasonField(res[0]?.fieldId);
        setReasons(
          Array.isArray(res[0]?.fieldOptions) ? res[0]?.fieldOptions : []
        );
      })
      .catch((err) => {
        console.log("Error getting dynamic fields: ", err);
      });
  }, []);

  useEffect(() => {
    //#region LOAD VEHICLE SELECT
    if (actionsRef?.current && controllerRef?.current) {
      const externalRedirect = loadLivePreference("auditExternalRedirect");

      if (externalRedirect?.fleetId) {
        setTimeout(() => {
          const nameList = document.getElementsByClassName("name-section");
          if (nameList.length) {
            let index = 0;
            while (index < nameList.length) {
              if (
                nameList.item(index).textContent === externalRedirect.fleetName
              ) {
                nameList.item(index).click();
                break;
              }
              ++index;
            }
          }
        }, 50);
      } else {
        onVehicleSelect(loadLivePreference("auditSelectedVehicle"));
      }

      saveLocalPreferences({
        auditExternalRedirect: { fleetId: undefined, fleetName: undefined },
      });
    }
  }, [actionsRef?.current, controllerRef?.current]);

  function onChangeInitialPlan() {
    //#region ON CHANGE PLAN
    if (actionsRef?.current) {
      actionsRef?.current?.changeInitialPlanMode();
    }
  }

  function getSelectedVehicle() {
    //#region GET SELECTED VEHICLE
    if (actionsRef?.current) {
      return actionsRef?.current?.getSelectedVehicle();
    }
    return undefined;
  }

  async function openDispatch(dispatchId) {
    //#region OPEN DISPATCH
    if (!dispatchId) {
      let routes = getAllChecked();
      setDispatchData({
        ...(routes?.length
          ? {
              fleetId: actionsRef?.current?.getSelectedVehicle(),
              dispatchDate: dayjsNY(selectedDate).startOf("D").valueOf(),
              routes,
            }
          : {}),
      });
    } else {
      message.loading({
        content: "Opening dispatch...",
        key: "dispatchOpen",
      });
      await API.get("fleetDispatching", `/fleetDispatching/${dispatchId}`)
        .then((res) => {
          message.destroy("dispatchOpen");
          let routes = mergeDispatches(res?.routes);
          setDispatchData({
            ...res,
            routes,
          });
        })
        .catch((err) => {
          message.error({
            content: "Something went wrong while opening dispatch",
            key: "dispatchOpen",
          });
          console.log("Error getting dispatch: ", err);
        });
    }
  }

  function onChangesSaved() {
    //#region ON SAVE
    if (actionsRef?.current) {
      actionsRef?.current?.onChangesSaved();
    }

    if (controllerRef?.current) {
      controllerRef?.current?.resetPlanMode();
    }
  }

  function configureDispatch() {
    //#region CONFIGURE DISPATCH
    if (dispatchModalRef?.current) {
      if (!dispatchData?.dispatchId) {
        dispatchModalRef?.current?.setVehicle(getSelectedVehicle());
        if (!dispatchData?.routes) {
          dispatchModalRef?.current?.setDate(selectedDate);
        } else {
          dispatchModalRef?.current?.openSuggestionAt(0);
        }
      } else {
        let suggestionRoute = dispatchData?.routes?.findIndex(
          ({ departAt }) => !departAt
        );
        if (suggestionRoute > -1) {
          dispatchModalRef?.current?.openSuggestionAt(suggestionRoute);
        }
      }
    }
  }

  function onChangesMade() {
    //#region ON CHANGE
    if (footerRef?.current) {
      footerRef?.current?.onChangesMade();
    }
  }

  function resetChangedStatus() {
    //#region RESET CHANGES
    if (footerRef?.current) {
      footerRef?.current?.resetChangesStatus();
    }
  }

  function onCancelChanges() {
    //#region CANCEL CHANGES
    if (actionsRef?.current) {
      actionsRef?.current?.onCancelChanges();
    }
  }

  function updateAuditStatus(newState) {
    //#region UPDATE AUDIT STATUS
    setAuditStatus(newState);
  }

  /**
   * @param {"CLICK"|"HOVER"} action
   */
  function updateGoToLocation(action) {
    //#region GO TO LOCATION
    setGoToLocation(action);
    saveLocalPreferences({ auditGoToLocation: action });
    localStorage.setItem("auditGoToLocation", action);
  }

  function changeAuditView(view = undefined) {
    //#region CHANGE AUDIT VIEW
    if (view) {
      setViewMode(view);
    } else {
      setViewMode((prev) => {
        if (prev === "MAP") {
          return "OVERVIEW";
        }
        return "MAP";
      });
    }
  }

  function onFiler(filterValues) {
    //#region ON FILTER
    if (actionsRef.current) {
      actionsRef.current.onFiler(filterValues);
    }
  }

  function onDrawNearFences(values) {
    //#region ON DRAW NEAR FENCES
    if (actionsRef.current) {
      actionsRef.current.onDrawNearFences(values);
    }
  }

  async function addReason(value) {
    //#region ADD REASON
    message.loading({
      content: "Saving option...",
      key: "dynFieldSave",
    });

    let tmpReasons = structuredClone(reasons);
    tmpReasons = tmpReasons.concat(value);
    if (reasonField) {
      API.patch("programFields", `/programFields/${reasonField}`, {
        body: {
          fieldOptions: tmpReasons,
        },
      })
        .then(() => {
          message.success({
            content: "Option saved successfully!",
            key: "dynFieldSave",
          });
          setReasons(tmpReasons);
        })
        .catch((err) => {
          message.error({
            content: "Something went wrong while saving",
            key: "dynFieldSave",
          });
          console.log("Error Saving Option: ", err);
        });
    }
  }

  function renderCustomLocation(params) {
    //#region RENDER CUSTOM LOCATION
    if (actionsRef.current) {
      actionsRef.current.renderCustomLocation(params);
    }
  }

  function clearCustomLocation() {
    //#region CLEAR CUSTOM LOCATION
    if (actionsRef.current) {
      actionsRef.current.clearCustomLocation();
    }
  }

  function onAuditRevert() {
    //#region AUDIT REVERT
    if (actionsRef.current) {
      actionsRef.current.onAuditRevert();
    }
  }

  function onAddActualActivity(alert) {
    //#region ADD ACTUAL
    if (actionsRef.current) {
      actionsRef.current.onAddActualActivity(alert);
    }
  }

  //#region JSX
  return (
    <Fragment>
      <LiveAuditContext.Provider
        value={{
          onFiler,
          reasons,
          viewMode,
          addReason,
          auditStatus,
          openDispatch,
          goToLocation,
          onAuditRevert,
          onChangesMade,
          onChangesSaved,
          onVehicleSelect,
          selectedVehicle,
          onCancelChanges,
          changeAuditView,
          onDrawNearFences,
          updateAuditStatus,
          updateGoToLocation,
          onAddActualActivity,
          onChangeInitialPlan,
          clearCustomLocation,
          renderCustomLocation,
        }}
      >
        <div className="live-audit-view">
          <AuditSidebar />
          <div className="audit-control">
            <AuditController ref={controllerRef} />
            <AuditActions ref={actionsRef} />
            <AuditFooter ref={footerRef} />
          </div>
        </div>
      </LiveAuditContext.Provider>
      {dispatchData && (
        <NewDispatchModal
          {...{
            visible: dispatchData,
            schedules,
            dispatches,
            setVisible() {
              setDispatchData(null);
            },
            rowObject: dispatchData,
            refreshTable(dispatchBody, action) {
              handleDispatchChange(dispatchBody, action);

              if (controllerRef?.current) {
                controllerRef?.current?.resetPlanMode();
              }

              broadcastUpdates({
                request: "plan-change",
                dispatchBody,
                action,
              });
            },
            ref: dispatchModalRef,
            onDispatchReady: configureDispatch,
          }}
        />
      )}
    </Fragment>
  );
}

export default LiveAuditView;
