import {
  GENERAL_PAYMENTS_DESC_DEFINERS,
  getMyPaymentReceived,
  mapKeysPayments,
  paymentsProcessing,
} from "../../../../../../../../../../../../../pages/Settings/settingsComponents/AccountingTemplates/components/NewTemplate/dataset/payments";
import {
  GENERAL_RENTAL_DESC_DEFINERS,
  getMyRentals,
  mapKeysRentals,
  rentalFurtherProcessing,
} from "../../../../../../../../../../../../../pages/Settings/settingsComponents/AccountingTemplates/components/NewTemplate/dataset/rentals";

export const retrieveProcessedSrc = ({ templateType, dataSrc }) => {
  if (!templateType || !dataSrc) return {};
  const myProcessed = structuredClone(dataSrc);
  const toReturn = {};
  switch (templateType) {
    case "rentalsGeneral": {
      const furtherProcessed = rentalFurtherProcessing({ rental: myProcessed });
      Object.entries(furtherProcessed).forEach(([key, val]) => {
        furtherProcessed[key] = !!GENERAL_RENTAL_DESC_DEFINERS[key]
          ? GENERAL_RENTAL_DESC_DEFINERS[key](val)
          : "";
        toReturn[!!mapKeysRentals[key] ? mapKeysRentals[key] : key] =
          furtherProcessed[key];
        // furtherProcessed[key] = furtherProcessed[key](val);
      });
    }
    case "paymentsGeneral": {
      const furtherProcessed = paymentsProcessing({ payments: myProcessed });
      Object.entries(furtherProcessed).forEach(([key, val]) => {
        furtherProcessed[key] = !!GENERAL_PAYMENTS_DESC_DEFINERS[key]
          ? GENERAL_PAYMENTS_DESC_DEFINERS[key](val)
          : "";
        toReturn[!!mapKeysPayments[key] ? mapKeysPayments[key] : key] =
          furtherProcessed[key];
        // furtherProcessed[key] = furtherProcessed[key](val);
      });
    }
  }
  return toReturn;
};

export const customMenuRetriever = ({ templateType }) => ({
  menu: {
    fields: {
      title: "Insert Dynamic Fields",
      items: "header body services footer",
      onclick: function (e) {
        console.log("e", e);
      },
    },
    fields2: {
      title: "Advanced Fields",
      items: "advanced",
    },
  },
  /**
   * single fields menu item
   */
  setup: function (editor) {
    editor.ui.registry.addNestedMenuItem("header", {
      text: "Description Fields",
      getSubmenuItems: function () {
        switch (templateType) {
          case "paymentsGeneral": {
            const payment =
              getMyPaymentReceived({
                paymentsType: templateType,
              }) || [];
            return payment?.map((el) => {
              return mapperItem(el, editor);
            });
          }
          case "rentalsGeneral": {
            const test = getMyRentals(templateType) || [];
            console.log("getMyRentals", test);
            return test.map((el) => {
              return mapperItem(el, editor);
            });
          }
          default:
            return [];
        }
      },
    });
  },
});
const mapperItem = (el, editor) => {
  return {
    type: "menuitem",
    text: el,
    // getSubmenuItems: function () {
    //   return Object.entries(val).map(([key, val]) => {
    //     return {
    //       type: "menuitem",
    //       text: key,
    //       onAction: function () {
    //         editor.insertContent(`{{${val}}}`);
    //       },
    //     };
    //   });
    // },
    onAction: function () {
      editor.insertContent(`{{${el}}}`);
    },
  };
};
