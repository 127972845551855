import React, { useState, useEffect } from "react"

import "./DigitalClock.scss"

const DigitalClock = () => {
  const getCurrentTime = () => {
    let time = new Date().toLocaleTimeString([], {
      timeZone: "America/New_York",
      hour: "2-digit",
      minute: "2-digit",
    })
    return time
  }

  const [time, setTime] = useState(getCurrentTime())

  useEffect(() => {
    setInterval(() => {
      setTime(getCurrentTime())
    }, 1000)
  })

  const [hour, afterColon] = time.split(":")
  const [minute, period] = afterColon.split(" ")

  return (
    <div className="digitalClockContainer">
      <span className="timeContainer">
        <span className="time">{hour}</span>:
        <span className="time">{minute}</span>
      </span>
      <span className="period">{period}</span>
    </div>
  )
}

export default DigitalClock
