import { useState } from "react";
import { Popover } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import NewButton from "../NewButton/NewButton";
import NewShortcutContent from "../NewShortcutContent/NewShortcutContent";

import "./NewShortcutPopover.scss";

const NewShortcutPopover = (props) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [visible, setVisible] = useState(false);

  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });

  return (
    <>
      <Popover
        arrow
        open={visible}
        color="#1f2a44"
        content={
          <NewShortcutContent
            {...{ ...props, setVisible, visible, isDarkMode }}
          />
        }
        overlayClassName={`shortcutPopover ${
          isDarkMode && "shortcutPopoverDark"
        } ${isDesktop && props.collapsed && "sidebar-collapsed"}`}
      >
        <NewButton
          collapsed={props.collapsed}
          onClick={() => {
            setVisible(!visible);
          }}
        />
      </Popover>
    </>
  );
};

export default NewShortcutPopover;
