import { useMemo } from "react";
import { useRedux } from "../../../../../../../../../hooks/useRedux";
import { Alert, Button } from "antd";
import ApplyCredit from "../../../ApplyCredit/ApplyCredit";
import { formatCurrency } from "../../../../../../../../../utils/formatCurrency";
import { forceToNumber } from "../../../../utils/checkers";
function PaymentAmountWarning() {
  const [fakePayment] = useRedux("fakePayment");

  const paymentWarning = useMemo(() => {
    let warning = false;
    let type;
    let APPLIED_AMOUNT = forceToNumber(fakePayment?.appliedAmount);
    let invoicePaid = fakePayment?.invoices?.reduce((acc, obj) => {
      return acc + forceToNumber(obj?.invoicePaidAmount);
    }, 0);
    let amount = APPLIED_AMOUNT - invoicePaid;
    if (APPLIED_AMOUNT > invoicePaid) {
      warning =
        "The payment amount is greater than the current due amount, this will result in an overpayment.";
      type = "Overpayment";
    } else if (APPLIED_AMOUNT < invoicePaid) {
      warning =
        "The payment amount is less than the current due amount, this will result in a partial payment.";
      type = "Partial Payment";
    }
    return { warning, type, amount };
  }, [fakePayment?.appliedAmount, fakePayment?.invoices]);

  return paymentWarning.warning ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      {" "}
      <Alert
        message={
          paymentWarning.type + ` ${formatCurrency(paymentWarning?.amount)}`
        }
        description={paymentWarning.warning}
        type="warning"
        showIcon
        closable={false}
      />
      {/* <ApplyCredit /> */}
    </div>
  ) : (
    <>{/* <ApplyCredit /> */}</>
  );
}

export default PaymentAmountWarning;
