export const isLocationInsideGeofences = (location, geofences) => {
  const { latitude, longitude } = location;
  for (const geofence of geofences) {
    const { geoFenceInfo } = geofence;
    if (Array.isArray(geoFenceInfo)) {
      const coordinates = geoFenceInfo.map((point) => [point.lng, point.lat]);
      let inside = false;

      for (
        let i = 0, j = coordinates.length - 1;
        i < coordinates.length;
        j = i++
      ) {
        const xi = coordinates[i][0];
        const yi = coordinates[i][1];
        const xj = coordinates[j][0];
        const yj = coordinates[j][1];
        const intersect =
          yi > latitude !== yj > latitude &&
          longitude < ((xj - xi) * (latitude - yi)) / (yj - yi) + xi;

        if (intersect) {
          inside = !inside;
        }
      }

      if (inside) {
        return true;
      }
    }
  }

  return false;
};
