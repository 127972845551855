import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { Input, message } from "antd";
import _ from "lodash";

import Transfer from "../Transfer/Transfer";
import { SearchIcon } from "../../../Communication/assets";

function DynamicUsers(props) {
  const location = useLocation();
  const { title, params, setEditMode, getEditedValue, form } = props;
  const { rowData, dynamicRowDataKey, dynamicOtherUsers } = params;

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [totalUsers, setTotalUsers] = useState([]); // here we save all users needed
  const [prevProps, setPrevProps] = useState(); // set prev props to send back after card edited
  const [currentData, setCurrentData] = useState(null); // save current data to modify
  const [filteredUsers, setFilteredUsers] = useState(false); //here we save filtered users by search
  const path = location?.pathname;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!!params) {
      API.get(`${path?.split("/")[1]}`, `${path}`).then((res) => {
        setCurrentData({
          ...res,
          [dynamicRowDataKey]:
            typeof res[dynamicRowDataKey][0] !== "string"
              ? res[dynamicRowDataKey]
              : updateDynamicKeyValues(res[dynamicRowDataKey]),
        });
      });
    }
    setPrevProps(() => _.cloneDeep(props));
  }, [params, props]);

  // if given arr with users is array with strings(with only nameOfUsers) update it with new structure
  const updateDynamicKeyValues = (dynKeyValues) =>
    userConfiguration.allUsers.Items.filter(({ nameOfUser }) =>
      dynKeyValues.includes(nameOfUser)
    ).map(({ nameOfUser, identityId }) => ({ nameOfUser, identityId }));

  useEffect(() => {
    !!dynamicOtherUsers
      ? setTotalUsers(dynamicOtherUsers)
      : setTotalUsers(
          userConfiguration.allUsers.Items?.filter?.(
            ({ isSuspended }) => !isSuspended
          )
        );
  }, []);

  //this usf is to avoid triggering frequent filter state updates while the user is typing
  useEffect(() => {
    const debounceSearch = _.debounce((value) => {
      setFilteredUsers(
        totalUsers?.filter((item) =>
          item?.nameOfUser?.toLowerCase()?.includes(value?.toLowerCase())
        )
      );
    }, 150);
    debounceSearch(searchValue);
    return () => debounceSearch.cancel();
  }, [searchValue, totalUsers]);

  const dynamicUsers = useMemo(
    () =>
      currentData &&
      (currentData?.[dynamicRowDataKey].map(({ identityId }) => identityId) ||
        []),
    [currentData]
  );

  const ClearOptions = () => setEditMode(false);

  const getEditedValues = (selectedValues) => {
    const updatedRowDataKeyValues = totalUsers
      .filter((user) =>
        selectedValues?.[dynamicRowDataKey]?.includes(user?.identityId)
      )
      .map((user) =>
        typeof rowData[dynamicRowDataKey][0] !== "string"
          ? {
              identityId: user?.identityId,
              nameOfUser: user?.nameOfUser,
              cognitoUserId: user?.cognitoUserId,
            }
          : user?.nameOfUser
      );

    API.put(`${path.split("/")[1]}`, `${path}`, {
      body: {
        [dynamicRowDataKey]: updatedRowDataKeyValues,
      },
    }).then(() => {
      ClearOptions();
      message.success(`${title} are updated successfully`, 5);
      getEditedValue(form.getFieldsValue(), undefined, {}, prevProps, props);
    });
  };

  const transferContent = (!!filteredUsers ? filteredUsers : totalUsers).map(
    (users) => ({
      title: users?.nameOfUser,
      id: users?.identityId,
      role: users?.groupName,
      email: users?.userName,
    })
  );

  return (
    <div className="teamsCardContainer">
      <div className="headerSearch">
        <div className="iconSearch">
          <SearchIcon style={{ width: 15, height: 15 }} />
        </div>
        <Input
          className="searchEditMembers"
          placeholder="Search members..."
          allowClear
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="teamsInnerContainer">
        {!!dynamicUsers && (
          <Transfer
            params={{
              content: {
                value: dynamicUsers,
                data: transferContent,
                formItemName: dynamicRowDataKey,
              },
            }}
            getEditedValue={getEditedValues}
            setEditMode={() => {}}
            setCloseTeamModalsOnCancel={() => {
              setFilteredUsers(false);
              ClearOptions();
            }}
          />
        )}
      </div>
    </div>
  );
}
export default DynamicUsers;
