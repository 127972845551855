import { InputNumber, message } from "antd";
import React, { useState } from "react";
import { RetainageIcon } from "../../../../../../../../../../../../../icons";
import { useRedux } from "../../../../../../../../../../../hooks";
import AnimatedNumber from "react-animated-number";

import "./ServiceRentHeader.scss";
import { formatCurrency } from "../../../../../../../../../../../utils";
import { totalitiesExtractor } from "./utils";
import ChangeDateUpperLevel from "./ChangeDateUpperLevel";
import { rentalServiceProcessing } from "../../../../../../../../../../../../pages/Settings/settingsComponents/AccountingTemplates/components/NewTemplate/dataset/rentals";
import { MondayButton } from "../../../../../../../../../../../../commonComponents";

function ServiceRentHeader({ selectedServiceData, type = "", canRedo }) {
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [isWritable] = useRedux("rentalsIsWritable");
  const [darkMode] = useRedux("rentalsDarkMode");
  const totalities = totalitiesExtractor(selectedServiceData, fakeRentals);

  const onRetainageChange = (value) => {
    let temp = JSON.parse(JSON.stringify(fakeRentals));
    temp.services?.forEach((service) => {
      console.log("service", service);
      if (service?.label === selectedServiceData?.label) {
        service.retainage = value;
      }
      // return service;
    });
    console.log("temp", temp);
    setFakeRentals(temp);
  };
  rentalServiceProcessing({
    rentalService: selectedServiceData,
    rental: fakeRentals,
  });

  console.log("totalities", { totalities, selectedServiceData, fakeRentals });

  return (
    <div className={`service-rent-header-${darkMode ? "dark" : "light"}`}>
      <div className="left-container">
        <div className="service-name">{selectedServiceData?.label}</div>
        <div className="information">
          Applied Rent:
          <div className="currencyPercentageContainer">
            <AnimatedNumber
              value={totalities?.appliedRent}
              formatValue={(value) => formatCurrency(value)}
            />
          </div>
        </div>{" "}
        <div className="information">
          Tax:
          <div className="currencyPercentageContainer">
            <AnimatedNumber
              value={totalities?.totalTax}
              formatValue={(value) => formatCurrency(value)}
            />
          </div>
        </div>
        <div className="information">
          Total Price:
          <div className="currencyPercentageContainer">
            <AnimatedNumber
              value={totalities?.totalPrice}
              formatValue={(value) => formatCurrency(value)}
            />
          </div>
        </div>
        <div className="information">
          Retainage:
          <div className="currencyPercentageContainer">
            <AnimatedNumber
              value={
                selectedServiceData?.retainage > 0
                  ? (selectedServiceData?.retainage / 100) *
                    totalities?.totalPrice
                  : 0
              }
              formatValue={(value) => formatCurrency(value)}
            />
          </div>
          <div className="information"></div>
        </div>
      </div>
      {type !== "viewOnly" && (
        <div className="right-container">
          {/* Retainage (%):{" "} */}
          <ChangeDateUpperLevel {...{ selectedServiceData, canRedo }} />
          <InputNumber
            disabled={!isWritable}
            addonBefore={`Retainage`}
            style={{ width: "180px" }}
            addonAfter="%"
            onChange={onRetainageChange}
            value={selectedServiceData?.retainage}
            min={0}
            max={100}
          />{" "}
        </div>
      )}
    </div>
  );
}

export default ServiceRentHeader;
