import React from "react";
import { SaveIcon } from "../../../../BasePage/src";
import { useRedux } from "../../../../hooks/useRedux";
import FooterAmounts from "./InvoiceTable/FooterAmounts";
import { XIcon } from "../../../../Communication/assets";
import { useCreateInvoiceContext } from "../context/InvoiceModalContext";
import { useInitialInvoiceContext } from "../context/InvoiceInitialContext";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";

import "./InvoiceFooter.scss";

const InvoiceFooter = ({ setConfirmData, handleCancel }) => {
  const [isWritable] = useRedux("invoiceIsWritable");
  const [formChanged] = useRedux("invoiceFormChanged");

  const { invoiceData } = useInitialInvoiceContext();

  const { handleSave } = useCreateInvoiceContext();

  let isSaveButtonClicked = false;

  return (
    <div className="invoice-footer-container">
      <FooterAmounts />
      <div className="footer-invoice-group">
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          tooltipCategory="Invoices"
          tooltipKey="cancel"
          onClick={() => {
            if (formChanged) {
              setConfirmData({
                ...{
                  visible: true,
                  setVisible: () => {},
                  onConfirm: handleCancel,
                  onCancel: () => {
                    setConfirmData();
                  },
                  text: invoiceData
                    ? "Any change that you have made will be lost?"
                    : "This invoice will be discarded",
                  title: "Confirm Cancel",
                },
              });
            } else {
              handleCancel();
            }
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          Icon={<SaveIcon />}
          tooltipCategory="Invoices"
          tooltipKey="save"
          onClick={() => {
            setConfirmData({
              visible: true,
              setVisible: () => {},
              onConfirm: () => {
                if (!isSaveButtonClicked) {
                  isSaveButtonClicked = true;
                  setConfirmData();
                  handleSave().then(() => {
                    isSaveButtonClicked = false;
                  });
                }
              },
              onCancel: () => {
                setConfirmData();
              },
              text:
                "Are you sure" +
                (invoiceData
                  ? " you want to save changes?"
                  : " you want to save Invoice?"),
              title: invoiceData ? "Confirm Changes" : "CONFIRMATION",
            });
          }}
          disabled={!isWritable && invoiceData}
        >
          Save
        </MondayButton>
      </div>
    </div>
  );
};

export default InvoiceFooter;
