import { Tick } from "../../../../../../../../../../../../../../DynamicView/components/FilterView/FilterIcons";
import { MondayButton } from "../../../../../../../../../../../../../../../commonComponents";
import { BackArrow, Close } from "../../../../../../../../../../../../../../DynamicView/src";
// import { useStateContext } from "../../../../../../../../../../Context/Context"
import { appliedAmountCalculation } from "../../rentalDetailsFunctions";
import "./ModalFooter.scss";
import { useRedux } from "../../../../../../../../../../../../../../hooks";

const ModalFooter = ({
	setSelectedWeek,
	handleDiscard,
	selectedWeek,
	elevationData,
	handleOk,
	allRow,
}) => {
	// const { isWritable, darkMode, unchangingFakeRentals, selectedService } = useStateContext()

	const [isWritable] = useRedux("rentalsIsWritable");
	const [selectedService] = useRedux("rentalsSelectedService");
	const [unchangingFakeRentals] = useRedux("unchangingFakeRentals");
	const [darkMode] = useRedux("rentalsDarkMode");
	// const [fakeRentals, setFakeRentals] = useRedux("fakeRentals")
	// const [color ] = useRedux("rentalsColor")
	// const [isAutoSaving ] = useRedux("rentalsIsAutoSaving")

	const { difference } = appliedAmountCalculation({
		unchangingFakeRentals,
		selectedService,
		elevationData,
		allRow,
	});

	return (
		<div className="footerContainerRentalDetails">
			{difference !== 0 && (
				<div
					className={
						darkMode ? "informationContainerFooterDark" : "informationContainerFooterLight"
					}
				>
					<div className="iconRentalContainerFooter">!</div>
					<strong>Be careful, your amounts are not balanced!!!!!</strong>
					{/* There is unapplied balance!! Distribute all amount to save! */}
				</div>
			)}
			{selectedWeek && (
				<MondayButton
					className="backMondayButton"
					onClick={() => setSelectedWeek(undefined)}
					Icon={<BackArrow />}
				>
					Back
				</MondayButton>
			)}
			<MondayButton className="discardMondayButton" onClick={handleDiscard} Icon={<Close />}>
				Discard
			</MondayButton>
			<MondayButton
				className="saveMondayButton"
				onClick={() => handleOk(difference)}
				Icon={<Tick />}
				disabled={
					!isWritable
					// || difference !== 0
				}
			>
				Apply
			</MondayButton>
		</div>
	);
};

export default ModalFooter;
