import { createContext, useContext } from "react";
import useEmailTemplatesData from "../hooks/useEmailTemplatesData";

const EmailTemplatesContext = createContext();

export const useEmailTemplates = () => useContext(EmailTemplatesContext);

export const EmailTemplatesProvider = ({
  children,
  invoice = false,
  proppedTemplateCat = false,
  exportOrEmailDropdown = false,
  rowData = {},
  recordDetails = {},
}) => {
  const {
    emailTemplates,
    setEmailTemplates,
    selectedEmailTemplate,
    setSelectedEmailTemplate,
    loading,
    setLoading,
  } = useEmailTemplatesData(
    invoice,
    proppedTemplateCat,
    exportOrEmailDropdown,
    rowData,
    recordDetails
  );

  return (
    <EmailTemplatesContext.Provider
      value={{
        emailTemplates,
        setEmailTemplates,
        selectedEmailTemplate,
        setSelectedEmailTemplate,
        loading,
        setLoading,
      }}
    >
      {children}
    </EmailTemplatesContext.Provider>
  );
};
