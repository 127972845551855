import moment from "moment";
import { defaultExcelBodyStyle } from "../../../../../helpers/constants/defaultExcelBodyStyle";
import { automationRecurrence } from "../../../../pages/Settings/settingsComponents/Automation/AllAutomations/utils";

export const getExcelRows = (data, status) => {
  const rows = [];

  if (status === "Active Automations") {
    for (const item of data) {
      rows.push([
        { v: item?.automationName || "", s: defaultExcelBodyStyle },
        { v: item?.createdBy?.nameOfUser || "", s: defaultExcelBodyStyle },
        {
          v:
            Object.keys(item?.automationRule)
              .map((key) => key)
              .join(", ") || "",
          s: defaultExcelBodyStyle,
        },
        {
          v: item?.automationStatus || "",
          s: defaultExcelBodyStyle,
        },
        {
          v: automationRecurrence(item.automationTime) || "",
          s: defaultExcelBodyStyle,
        },
      ]);
    }
  } else {
    data.forEach((res) => {
      rows.push([
        { v: res?.error || "", s: defaultExcelBodyStyle },
        { v: res?.responseTitle || "", s: defaultExcelBodyStyle },
        {
          v: res?.description || "",
          s: defaultExcelBodyStyle,
        },
        {
          v: moment(res?.responseDate).fromNow() || "",
          s: defaultExcelBodyStyle,
        },
      ]);
    });
  }

  return rows;
};
