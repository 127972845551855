import { useState, useRef, useEffect } from "react";
import { Radio, Modal, Input } from "antd";
import "./SpecifyTypeChargeModal.scss";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../Communication/assets";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { message } from "antd";
import { checkIfServiceIsHoist } from "../../../../../../Estimations/DataEntryGrid/models/Service";
import { getServiceLegacyPrice } from "../../../../calculations/servicePrices";
import { getPriceForCharge } from "../../NewChargeItem/utils/index";

export function SpecifyTypeChargeModal({
  isModalOpen,
  setIsModalOpen,
  handleSpecifyTypeChargeModalNext,
  whereToFind,
  selectedGroup,
}) {
  const [selectedMode, setSelectedMode] = useState(1);
  const [selectedPercentage, setSelectedPercentage] = useState();
  const inputRef = useRef();

  const handleCancel = () => setIsModalOpen(false);

  const totalPriceAlreadyCharged =
    whereToFind?.services?.[selectedGroup]?.reduce((prev, service) => {
      // Reduction for serviceOptions
      prev +=
        service?.serviceOptions?.flat(1)?.reduce((prev, curr) => {
          if (checkIfServiceIsHoist(service)) {
            prev += getPriceForCharge(curr?.charges) || 0;
          }
          return (prev += curr?.items?.reduce(
            (prev, curr) => (prev += getPriceForCharge(curr?.charges) || 0),
            0
          ));
        }, 0) || 0;

      if (service?.serviceAddons) {
        // Reduction for serviceAddons
        prev +=
          service?.serviceAddons?.reduce((prev, curr) => {
            return (prev += getPriceForCharge(curr?.charges) || 0);
          }, 0) || 0;
      }
      if (service?.additionalRentalTerms) {
        // Reduction for RentalTerms
        prev += getPriceForCharge(service?.additionalRentalTerms?.charges) || 0;
      }
      return prev;
    }, 0) || 0;

  const amountToBeCharged =
    whereToFind?.services?.[selectedGroup]?.reduce(
      (prev, service) => (prev += getServiceLegacyPrice(service)),
      0
    ) || 0;

  const totalAmountExceedsValidation = ({ perc = selectedPercentage }) => {
    if (
      amountToBeCharged <
      (amountToBeCharged * perc) / 100 + totalPriceAlreadyCharged
    ) {
      message.error("Current percentage exceeds total estimated amount!");
      //returns the maximum amount chargeable
      return (
        ((amountToBeCharged - totalPriceAlreadyCharged) / amountToBeCharged) *
        100
      );
    }
  };

  const getRemainingPercentage = () => {
    const remainingAmount = amountToBeCharged - totalPriceAlreadyCharged;

    if (remainingAmount <= 0) {
      message.error("This Estimation been fully charged!");
      return; // No percentage left to be charged
    } else {
      // Calculate percentage left to be charged
      const percentageLeft = (remainingAmount / amountToBeCharged) * 100;
      return Math.ceil(percentageLeft);
    }
  };

  const handleNext = () => {
    if (
      selectedMode === 2 &&
      (selectedPercentage <= 0 || !!!selectedPercentage)
    )
      return message.error("Please! Set a percentage greater then 0!");

    if (selectedMode !== 1 && getRemainingPercentage() <= 0)
      if (totalAmountExceedsValidation({})) return;
    setIsModalOpen(false);
    handleSpecifyTypeChargeModalNext({
      selectedMode: selectedMode,
      ...{ selectedPercentage },
    });
  };

  useEffect(() => {
    inputRef?.current?.focus();

    if (selectedMode !== 2) {
      setSelectedPercentage(selectedMode === 1 ? 100 : 0);
      if (inputRef?.current?.value) inputRef.current.value = undefined;
    }
  }, [selectedMode]);

  return (
    <Modal
      wrapClassName="newChargeModalWrapper"
      className="selectTypeNewChargeModal"
      title={
        <div className="modalHeader">
          {"Create a Charge based on Estimate"}
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setIsModalOpen(false)}
          >
            <path
              d="M16.6349 0.377576C16.5155 0.257888 16.3738 0.16293 16.2177 0.0981401C16.0617 0.0333498 15.8944 0 15.7254 0C15.5564 0 15.3891 0.0333498 15.2331 0.0981401C15.077 0.16293 14.9353 0.257888 14.8159 0.377576L8.51264 6.67667L2.20936 0.369567C1.96662 0.141013 1.64471 0.0156332 1.31143 0.0198301C0.978144 0.0240271 0.659491 0.157473 0.422576 0.392067C0.18566 0.626661 0.0489704 0.944094 0.0412922 1.27752C0.0336139 1.61094 0.155546 1.93434 0.381411 2.1796L6.68469 8.4867L0.381411 14.7938C0.138612 15.0348 0.00141819 15.3624 1.09331e-05 15.7045C-0.00139632 16.0467 0.133098 16.3754 0.373908 16.6184C0.614717 16.8613 0.942115 16.9986 1.28408 17C1.62604 17.0014 1.95456 16.8668 2.19736 16.6259L8.50064 10.3188L14.8039 16.6259C15.0473 16.8564 15.371 16.9829 15.7061 16.9783C16.0412 16.9737 16.3614 16.8385 16.5984 16.6013C16.8353 16.3642 16.9705 16.0439 16.9751 15.7086C16.9797 15.3732 16.8533 15.0493 16.6229 14.8058L10.3196 8.49872L16.6229 2.19162C16.8623 1.95127 16.9977 1.6264 17 1.28706C17.0022 0.947711 16.8711 0.621075 16.6349 0.377576Z"
              fill="#323338"
            />
          </svg>
        </div>
      }
      footer={
        <div className="NewItemChargeFooter">
          <MondayButton
            Icon={<XIcon style={{ fill: "white" }} />}
            className="NewItemChargeFooterCancel"
            onClick={handleCancel}
          >
            Cancel
          </MondayButton>
          <MondayButton
            Icon={<TickIcon />}
            className="NewItemChargeFooterSave"
            onClick={handleNext}
          >
            Next
          </MondayButton>
        </div>
      }
      open={isModalOpen}
      onOk={handleNext}
      onCancel={handleCancel}
      okText="Next"
      width={500}
      height={400}
    >
      <Radio.Group
        onChange={(value) => setSelectedMode(value.target.value)}
        value={selectedMode}
      >
        <Radio value={1}>
          Create a charge for the remaining amounts of the Estimate.
        </Radio>
        <Radio value={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            Create charge for a percentage of the entire Estimate:
            <div
              className="percentContainer"
              style={{
                color: selectedMode !== 2 ? "#D9D9D9" : "black",
              }}
              onClick={() => setSelectedMode(2)}
            >
              <span
                style={{
                  color:
                    selectedMode !== 2 || !!!selectedPercentage
                      ? "#D9D9D9"
                      : "black",
                }}
              >
                %
              </span>
              <input
                min={1}
                ref={inputRef}
                style={selectedMode !== 2 ? { cursor: "pointer" } : {}}
                onChange={(e) => {
                  const validation = totalAmountExceedsValidation({
                    perc: parseFloat(e.target.value),
                  });
                  if (validation) {
                    return (e.target.value = validation?.toFixed(2));
                  }
                  setSelectedPercentage(parseFloat(e.target.value));
                }}
                disabled={selectedMode !== 2}
                type="number"
                placeholder="of estimate"
                defaultValue={selectedMode === 2 ? selectedPercentage : null}
                className={"percentInput"}
              />
            </div>
          </div>
        </Radio>
        <Radio value={3}>
          Create charge for selected items or for different percentages of each
          item.
        </Radio>
      </Radio.Group>
    </Modal>
  );
}
