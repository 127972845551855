import axios from "axios";

const uriConfig = {
  locale:
    process.env.NODE_ENV === "production"
      ? "http://localhost:8080/api/batchUpdate/prod"
      : "http://localhost:8080/api/batchUpdate",
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/api/batchUpdate/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/api/batchUpdate",
};

/**
 * @typedef {Record<string, Record<string, any>>} UpdateData
 */

/**
 * Function that offers advanced update of nested items for multiple documents at once.
 * Removes the need to recreate the entire structure of the body to simply update a few properties.
 * Removes the need to make multiple update calls for mass updates, supports up to 100 updates at a time.
 *
 * @param {Object} param
 * @param {string} param.tableName - The table name we want to update
 * @param {string} param.conditionKey - The condition key is simply the path used to make the update valid, it should be included in the updateData
 * @param {UpdateData} param.updateData - Keys on the first level are the object ids. Keys on the second level are custom nested update paths
 *
 * @example
 * // THE UPDATE PATHS NEED TO BE SEPARATED BY A DOT.
 * // IN CASE OF AN ARRAY, THE INDEX NEEDS TO BE WRAPPED BY SQUARE BRACKETS.
 * // TO UPDATE AN ARRAY PROPERTY, THE EXACT INDEX IS NEEDED.
 *
 * {
 *    tableName: "fleetDispatching",
 *    conditionKey: "dispatchId",
 *    updateData: [dispatchId1]: {
 *      "routes.[1].dropOffLocation": "...",
 *      "dispatchDate": "...",
 *      "routes.[2].dropOffCoordinates.lat": ...,
 *      "routes.[2].dropOffCoordinates.lng": ...,
 *    },
 *    [dispatchId2]: {
 *      ...
 *    },
 * }
 */
async function batchUpdateItems({ tableName, conditionKey, updateData }) {
  return axios.post(uriConfig.online, { tableName, conditionKey, updateData });
}

export default batchUpdateItems;
