import { message } from "antd";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import AmountReq from "../../../../Service/ServiceContent/GridData/GridUtils/amountFactory";

export const cellValueChanged = ({
  fakeApplication,
  setApplication,
  estimationId,
  elevationId,
  serviceId,
  eventId,
  params,
}) => {
  // Find service that we want to edit
  const serviceToEdit = fakeApplication?.services?.find(
    (serviceData) =>
      serviceData?.serviceId === serviceId &&
      serviceData?.estimationId === estimationId
  );

  const thisField = params?.colDef?.field;

  const { amount, fromPrevious } =
    serviceToEdit?.serviceOptions[0]
      ?.find((elevationData) => elevationData?.elevationId === elevationId)
      ?.amounts?.find((eventData) => eventData?.id === eventId) || {};

  //here we calculate the max this period that we can fill
  const maxValue = +(+amount - +fromPrevious)?.toFixed(10);
  //here we calculate the max percentage we can fill
  const maxPercentage = (maxValue / amount) * 100;
  const applicableValue = 100;
  const initialValue = (+fromPrevious / +amount) * 100;

  let inputNewValue = 0;
  const toReturn = { ...fakeApplication };

  //here we take the impute from user and check if it contains anything besides numbers,if yes we return 0 automaticly
  try {
    inputNewValue = Function("return " + params.newValue)();
  } catch (e) {
    inputNewValue = 0;
    message.warning("Invalid input");
  }

  if (
    [
      "retainagePercentage",
      "thisKeptRetainage",
      "thisDistRetainage",
      "accRetainageBalance",
      "accRetaiangeDist",
      "retainageDndInd",
    ].includes(thisField)
  ) {
    const serviceIndex = toReturn.services.findIndex(
      (serviceData) =>
        serviceData?.serviceId === serviceToEdit?.serviceId &&
        serviceData?.estimationId === serviceToEdit?.estimationId
    );

    if (serviceIndex !== -1) {
      let currElevationIdx = toReturn.services[
        serviceIndex
      ].serviceOptions[0].findIndex((el) => el.elevationId === elevationId);
      if (currElevationIdx !== -1) {
        let tempElevation =
          toReturn.services[serviceIndex].serviceOptions[0]?.[
            currElevationIdx
          ] || {};
        if (!!tempElevation) {
          let myAmountIdx =
            tempElevation?.amounts?.findIndex((el) => el.id === eventId) ?? -1;
          if (myAmountIdx !== -1) {
            // we have found it.
            let myAmount;
            myAmount = new AmountReq(
              toReturn.services[serviceIndex].serviceOptions[0]?.[
                currElevationIdx
              ]?.amounts?.[myAmountIdx] ?? {}
            );
            toReturn.services[serviceIndex].serviceOptions[0][
              currElevationIdx
            ].amounts[myAmountIdx] = myAmount.changeThisRetainage(
              forceToNumber(params.newValue),
              thisField,
              toReturn.services[serviceIndex]
            );
            // let myAmount = new AmountReq(
            //   toReturn.services[serviceIndex].serviceOptions[0]?.[
            //     currElevationIdx
            //   ]?.amounts?.[myAmountIdx] ?? {}
            // );
            // myAmount.chargeThisPeriod(
            //   thisPeriod,
            //   _,
            //   toReturn.services[serviceIndex]
            // );
            // toReturn.services[serviceIndex].serviceOptions[0][
            //   currElevationIdx
            // ].amounts[myAmountIdx] = myAmount.getData();
          }
        }
      }
    }
  } else {
    //here we calculate this period from the impute, impute can be from this period column or percentage, he handle both cases
    const thisPeriod =
      //========================== from this period case==========================
      thisField === "thisPeriod"
        ? inputNewValue < 0
          ? (() => {
              message.warning("Value smaller than $0.00");
              return 0;
            })() // If it is not valid make it 0
          : inputNewValue > maxValue // Check if input is greater than collectable amount
          ? (() => {
              message.warning("Input is greater than collectable amount");
              return maxValue;
            })() // If it is, make it equal to that amount
          : inputNewValue // else
        : //=====================from percentage case=======================
        inputNewValue < initialValue
        ? (() => {
            message.warning(`Value smaller than ${initialValue}%`);
            return 0;
          })() // If it is not valid make it 0
        : inputNewValue > applicableValue // Check if input is greater than collectable amount
        ? (() => {
            message.warning("Input is greater than collectable percentage");
            return (amount * maxPercentage) / 100;
          })() // If it is, make it equal to that amount
        : (amount * (inputNewValue - initialValue)) / 100; // Convert new period to number

    const toReturn = { ...fakeApplication };
    const serviceIndex = toReturn.services.findIndex(
      (serviceData) =>
        serviceData?.serviceId === serviceToEdit?.serviceId &&
        serviceData?.estimationId === serviceToEdit?.estimationId
    );

    if (serviceIndex !== -1) {
      let currElevationIdx = toReturn.services[
        serviceIndex
      ].serviceOptions[0].findIndex((el) => el.elevationId === elevationId);
      if (currElevationIdx !== -1) {
        let tempElevation =
          toReturn.services[serviceIndex].serviceOptions[0]?.[
            currElevationIdx
          ] || {};
        if (!!tempElevation) {
          let myAmountIdx =
            tempElevation?.amounts?.findIndex((el) => el.id === eventId) ?? -1;
          if (myAmountIdx !== -1) {
            let myAmount = new AmountReq(
              toReturn.services[serviceIndex].serviceOptions[0]?.[
                currElevationIdx
              ]?.amounts?.[myAmountIdx] ?? {}
            );
            myAmount.chargeThisPeriod(
              thisPeriod,
              _,
              toReturn.services[serviceIndex]
            );
            toReturn.services[serviceIndex].serviceOptions[0][
              currElevationIdx
            ].amounts[myAmountIdx] = myAmount.getData();
          }
        }
      }
    }

    //this is the new application modal
    // const newApplicationHandler = {
    //   ...fakeApplication,
    //   services: fakeApplication?.services?.map((serviceData) =>
    //     serviceData?.serviceId === serviceId &&
    //     serviceData?.estimationId === estimationId
    //       ? {
    //           ...serviceData,
    //           serviceOptions: [
    //             serviceData?.serviceOptions[0]?.map((elevationData) =>
    //               elevationData?.elevationId === elevationId
    //                 ? {
    //                     ...elevationData,
    //                     amounts: elevationData?.amounts?.map((amountsData) => {
    //                       const {
    //                         fromPrevious,
    //                         amount,
    //                         charged,
    //                         reducedBalance = 0,
    //                       } = amountsData || {};
    //                       const retainagePercentage = serviceData?.retainage;

    //                       //total compleated is equal to  (total amount that is applied in previous applications)+(the amount that is applied in this application)
    //                       const totalCompleated = fromPrevious + thisPeriod;
    //                       //percentage compleated for the event is equal to (totalCompleated that we calculated before)/(the total amount for this event) times 100 to turn it to percentage
    //                       const percentageCompleated =
    //                         (totalCompleated / amount) * 100;
    //                       //balanceToFinish for the event is equal to (the total amount for this event) - (totalCompleated that we calculated before)
    //                       const balanceToFinish = amount - totalCompleated;
    //                       //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    //                       const retainage =
    //                         (totalCompleated * retainagePercentage) / 100;
    //                       // Kept Retaiange for event
    //                       const keptRetainage =
    //                         thisPeriod * (retainagePercentage / 100);
    //                       console.log("kept Ret", keptRetainage);
    //                       const paymentDue =
    //                         thisPeriod - keptRetainage + reducedBalance;

    //                       //here we check if this event is not charged
    //                       return !charged
    //                         ? //if the event is not charged we check if this is the event that we want to update
    //                           amountsData?.id === eventId
    //                           ? {
    //                               ...amountsData,
    //                               paymentDue,
    //                               percentageCompleated,
    //                               totalCompleated,
    //                               thisPeriod,
    //                               balanceToFinish,
    //                               retainage,
    //                               keptRetainage,
    //                             }
    //                           : //if this is not the event that we want to update return it unchanged
    //                             amountsData
    //                         : //else if event is charged we return it unchanged
    //                           amountsData;
    //                     }),
    //                   }
    //                 : elevationData
    //             ),
    //           ],
    //         }
    //       : serviceData
    //   ),
    // };
  }
  setApplication(toReturn);
};
