import dayjs from "dayjs";
import { generateDocDefinition } from "../../../../../../utils/generateDocDefinition";
import createPDF from "../../../../../../integrations/createPDF";

export function sessionLogsPDF(
  action,
  getDocDefinition = false,
  headersData,
  filteredLogs,
  base64,
  allUsersObj
) {
  console.log("pdf", { action, getDocDefinition, headersData });
  const columnsToInclude =
    headersData || sessionsTableHeaders.map((item, index) => index);

  const tableData = filteredLogs?.map((log) => {
    const {
      sessionId,
      cognitoUserId,
      sessionStatus,
      nameOfUser,
      userDepartment,
      userRole,
      sessionInfo,
    } = log || {};
    const { ip, osName, browserName, logTime, identityId } = sessionInfo || {};
    const device =
      osName && browserName ? `${osName} - ${browserName}` : "Not Found";

    const name = nameOfUser || allUsersObj?.[identityId]?.nameOfUser || "";
    return {
      nameOfUser: name || "",
      status: sessionStatus || "",
      dateTime: dayjs(logTime).format("MM/DD/YYYY HH:mm:ss") || "",
      iP: ip || "",
      device: device || "",
      role: userRole || "",
      department: userDepartment || "",
      sessionID: sessionId || "",
    };
  });

  const docDefinition = generateDocDefinition(
    base64,
    `User Sessions Logs`,
    columnsToInclude,
    sessionsTableHeaders,
    tableData.map(Object.values)
  );

  if (getDocDefinition) {
    return docDefinition;
  }

  createPDF({
    action,
    docDefinition,
    title: action.includes("download") && "User Session Logs.pdf",
  });
}

export const sessionsTableHeaders = [
  "Name Of User",
  "Status",
  "Date Time",
  "IP",
  "Device",
  "Role",
  "Department",
  "Session ID",
];
