export const groupServicesByEstimationId = (services = []) => {
  const groupedServices = {};

  services.forEach((service) => {
    if (!groupedServices[service.estimationId]) {
      groupedServices[service.estimationId] = {
        name: `Estimation ${Object.keys(groupedServices).length + 1}`,
        estimationId: service.estimationId,
        services: [],
      };
    }

    groupedServices[service.estimationId].services.push(service);
  });

  return Object.values(groupedServices);
};
