import { HistoryOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { Alert, message, Modal } from "antd";
import React, { useMemo, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { CheckIconModal, CloseIconModal } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { SaveIcon } from "../../../../../BasePage/src";
import { useRedux } from "../../../../../hooks";
import CustomFooter from "../../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import { FloorDetailsProvider } from "../../HoistPriceCalculatorModal/components/FloorDetails/context/floorDetailsContext";
import FloorDetails from "../../HoistPriceCalculatorModal/components/FloorDetails/FloorDetails";
import TableHoistFloorAddons from "../TableHoistFloorAddons/TableHoistFloorAddons";
import {
  autoFillHoist,
  getPriceScheme,
  mappingOfKeysHoistTable,
} from "./referenceData";
import {
  getTableDataColumns,
  TABLE_DATA_COLUMN_HOIST_CALCULATOR,
  TABLE_DATA_HOIST_CALCULATOR,
} from "./tableData";
import "./TableHoistStyle.scss";
function TableHoistCalcModal({
  visible,
  setVisible,
  indexes = {},
  service = {},
  updateStateAndSave,
}) {
  const [globalData, setGlobalData] = useState(TABLE_DATA_HOIST_CALCULATOR);
  const [rowData, setRowData] = useState([]);
  const [priceScheme, setPriceScheme] = useState({});
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [forceReset, setForceReset] = useState(false);
  // makes a copy of the original and merges in the new values
  const updateImmutableObject = (original, newValues) => {
    console.log("updateImmutableObject", { original, newValues });
    const newObject = {};
    Object.keys(original).forEach(function (key) {
      newObject[key] = original[key];
    });
    Object.keys(newValues).forEach(function (key) {
      newObject[key] = newValues[key];
    });
    return newObject;
  };
  let TAX_RATE = service?.isTaxable ? forceToNumber(service?.taxRate) : 0;

  const calculateTotalPrice = (item) => {
    return {
      ...item,
      totalPrice:
        forceToNumber(item?.price) +
        forceToNumber(item?.tax) +
        forceToNumber(item?.rent),
    };
  };

  const onCellValueChanged = useCallback(
    (params) => {
      const { data = {} } = params;
      const indexToUpdate = globalData.findIndex((el) => el.id === data.id);
      let itemToUpdate = params.data;
      const newValue = params.newValue;
      const colField = params.colDef.field;
      console.log("colField", colField);
      itemToUpdate[colField] = newValue;
      if (colField === "entityValue") {
        if (newValue !== "Custom") {
          let values =
            priceScheme?.[mappingOfKeysHoistTable[params?.data?.name]]?.[
              newValue
            ] || {};
          params.data = {
            ...params.data,
            ...values,
          };
          itemToUpdate = {
            ...itemToUpdate,
            ...values,
          };
          console.log("values", { values, priceScheme, itemToUpdate });
        }
      }
      const itemUpdated = updateImmutableObject(
        globalData[indexToUpdate],
        calculateTotalPrice(itemToUpdate)
      );
      /// apply transaction
      params.api.applyTransaction({ update: [itemUpdated] });
      rowData[indexToUpdate] = itemUpdated;
      setGlobalData([...rowData]);
      // setRowData([...rowData]);
    },
    [priceScheme]
  );

  const updateStateData = useCallback(
    (toSet, isDraft) => {
      message.loading("Updating Price Scheme", 2);
      const temp = gridData;
      const serviceIndex = indexes.serviceIndex;
      const optionIndex = indexes.optionIndex;
      const elevationIndex = indexes.elevationIndex;
      const elevation =
        temp[serviceIndex].serviceOptions[optionIndex][elevationIndex];
      console.log("updateStateData", { service });
      if (!isDraft) {
        elevation.totalPrice =
          toSet.TOTAL_PRICE * (forceToNumber(TAX_RATE) + 1);
        elevation.rentalsTotal = toSet.TOTAL_RENT;
        elevation.taxAmount = toSet.TOTAL_PRICE * forceToNumber(TAX_RATE);
      } else {
        elevation.totalPrice = 0;
        elevation.rentalsTotal = 0;
        elevation.taxAmount = 0;
      }

      temp[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].autoScheme = {
        // TABLE_DATA_COLUMN_HOIST_CALCULATOR: getTableDataColumns(),
        rowData: rowData,
        TOTALITIES: toSet,
        name: "Hoist Scheme",
        isDraft,
        id: 1,
      };

      console.log("updateStateData", {
        toSet,
        temp,
      });

      updateStateAndSave(structuredClone(temp));
      setVisible(false);
    },
    [globalData, gridData, indexes]
  );

  const elevationHoist = useMemo(() => {
    return service?.serviceOptions[indexes.optionIndex]?.[
      indexes.elevationIndex
    ];
  }, [indexes, service]);

  const getNewValues = useCallback(() => {
    let toSetData = autoFillHoist({ hoistData: elevationHoist });
    let priceScheme =
      getPriceScheme({
        isDual: elevationHoist?.type === "dual",
      })?.TABLE_HOIST_REFERENCE_DATA || {};
    setPriceScheme(priceScheme);
    setGlobalData(toSetData);
    setRowData(toSetData);
  }, [elevationHoist]);

  const retrieveHoistValues = useCallback(() => {
    console.log("elevationHoist", elevationHoist);
    let toSetData = autoFillHoist({ hoistData: elevationHoist });
    let priceScheme =
      getPriceScheme({
        isDual: elevationHoist?.type === "dual",
      })?.TABLE_HOIST_REFERENCE_DATA || {};
    if (elevationHoist?.autoScheme?.rowData && !forceReset) {
      toSetData = elevationHoist?.autoScheme?.rowData;
      setGlobalData(toSetData);
      setRowData(toSetData);
      setPriceScheme(priceScheme);
    } else {
      setPriceScheme(priceScheme);
      setGlobalData(toSetData);
      setRowData(toSetData);
    }
  }, [elevationHoist, forceReset]);

  const removeRow = useCallback((data) => {
    console.log("removeRow", data);
    // rowData.splice(index, 1);
    setRowData((prev) => prev?.filter((el) => el.id !== data.id));
    setGlobalData((prev) => prev?.filter((el) => el.id !== data.id));
  }, []);

  const addRow = useCallback((data) => {
    // rowData.splice(index, 1);
    console.log("addRow", data);
    setRowData((prev) => [...prev, data]);
    setGlobalData((prev) => [...prev, data]);
  }, []);

  const getRowPriceStyle = useCallback((params) => {
    console.log("getRowPriceStyle", params?.data);
    if (params?.data?.name?.includes("Rent")) {
      return { background: "#DD761C" };
    } else if (forceToNumber(params?.data?.totalPrice) > 0) {
      return { background: "#006769" };
    }
  }, []);

  const savePriceSchemeAsDraft = useCallback(
    (isTrue) => {
      let TOTALITIES = {
        TOTAL_PRICE: 0,
        TAX_AMOUNT: 0,
        TOTAL_RENT: 0,
        TAX_RATE: 0,
      };
      // let additionalRental = rowData.find(
      //   (el) => el?.name === "Additional Rental for Hoist"
      // )?.ppu;
      // if (additionalRental) {
      //   TOTALITIES.TOTAL_RENT += forceToNumber(additionalRental);
      // }
      rowData.forEach((row) => {
        TOTALITIES.TOTAL_PRICE += forceToNumber(row?.totalPrice);
        TOTALITIES.TAX_AMOUNT += forceToNumber(row?.tax);
        TOTALITIES.TOTAL_RENT += forceToNumber(row?.rentalAfter);
        TOTALITIES.TAX_RATE += forceToNumber(row?.taxRate);
      });

      updateStateData(TOTALITIES, isTrue);

      console.log("savePriceSchemeAsDraft", {
        rowData,
      });
    },
    [rowData]
  );

  useEffect(() => {
    retrieveHoistValues();
  }, []);

  return (
    <>
      {visible && (
        <Modal
          open={visible}
          title="Hoist AUTO Price Calculator"
          className="table_hoist_modal"
          // width={1500}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="footer_hoist_left"
                style={{ flex: 1, display: "flex", gap: "20px" }}
              >
                <MondayButton
                  className="mondayButtonRed"
                  onClick={() => setVisible(false)}
                  Icon={<CloseIconModal />}
                >
                  Discard
                </MondayButton>
                {/* <MondayButton
                  className="mondayButtonRed"
                  Icon={<HistoryOutlined />}
                >
                  Reset Price
                </MondayButton> */}
              </div>
              <div
                className="footer_hoist_left"
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <MondayButton
                  className="mondayButtonYellow"
                  Icon={<HistoryOutlined />}
                  onClick={() => savePriceSchemeAsDraft(true)}
                >
                  Save as Draft
                </MondayButton>{" "}
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<CheckIconModal />}
                  onClick={() => savePriceSchemeAsDraft(false)}
                >
                  Approve Price
                </MondayButton>
              </div>
            </div>
          }
          onCancel={() => setVisible(false)}
        >
          <Alert message="This is a Hoist AUTO Price Calculator. You can change the values in the table below to adjust the price scheme." />
          <div
            className="dark-ag-theme ag-theme-alpine-dark"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CustomFooter
              dataSrc={
                rowData
                  ? rowData?.map((el) => {
                      console.log("el", el);
                      el.taxRate = forceToNumber(TAX_RATE);
                      el.taxAmount =
                        forceToNumber(TAX_RATE) * forceToNumber(el?.totalPrice);
                      el.totalPriceWithTax =
                        forceToNumber(el?.totalPrice) + el?.taxAmount;

                      if (el?.name === "Additional Rental for Hoist") {
                        el.chargedMonth = forceToNumber(el?.units);
                        el.freeMonths = forceToNumber(el?.freeMonths);
                        el.totalMonths =
                          forceToNumber(el?.freeMonths) +
                          forceToNumber(el?.units);
                        return {
                          ...el,
                          additionalRentSum: forceToNumber(el?.totalPrice),
                          rentMonth: forceToNumber(el?.ppu),
                        };
                      } else if (el?.name === "Rental for Hoist") {
                        el.chargedMonth = forceToNumber(el?.units);
                        el.freeMonths = forceToNumber(el?.freeMonths);
                        el.totalMonths =
                          forceToNumber(el?.freeMonths) +
                          forceToNumber(el?.units);
                        return { ...el, additionalRentSum: 0, rentMonth: 0 };
                      } else
                        return {
                          ...el,
                          totalMonths: 0,
                          freeMonths: forceToNumber(el?.freeMonths),
                          // freeMonths: 0,
                          chargedMonth: 0,
                          additionalRentSum: 0,
                          rentMonth: 0,
                        };
                    }) || []
                  : []
              }
              mathAction={"sum"}
              type={"base"}
              style={{
                padding: "5px",
                backgroundColor: "#153448",
                color: "#fff",
              }}
              labelsKeys={[
                {
                  key: "totalMonths",
                  name: "Total Rent Months",
                  type: "number",
                  mathAction: "sum",
                },
                {
                  key: "freeMonths",
                  name: "Free Months",
                  type: "number",
                  mathAction: "sum",
                },
                {
                  key: "additionalRentSum",
                  name: "Total Add. Rent",
                  type: "currency",
                  mathAction: "sum",
                },
                {
                  key: "rentalAfter",
                  name: "Rent After (Monthly)",
                  type: "currency",
                  mathAction: "sum",
                },
                {
                  key: "price",
                  name: "Price",
                  type: "currency",
                  mathAction: "sum",
                },
                {
                  key: "taxAmount",
                  name: "Tax Amount",
                  type: "currency",
                  mathAction: "sum",
                },
                {
                  key: "totalPriceWithTax",
                  name: "Total Price",
                  type: "currency",
                  mathAction: "sum",
                },
              ]}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "20px",
                gap: "20px",
              }}
            >
              <TableHoistFloorAddons indexes={indexes} />
              <MondayButton
                hasIcon={false}
                className="mondayButtonRed"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText:
                      "Yes, I understand that I will lose all my changes!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      getNewValues();
                      Swal.fire({
                        title: "Deleted!",
                        text: "Your price calculator has been reset.",
                        icon: "success",
                      });
                    }
                  });
                }}
              >
                RECALCULATE
              </MondayButton>
            </div>
            {rowData.length > 0 && (
              <AgGridReact
                key={"3434353"}
                className="dark-ag-theme ag-theme-alpine-dark"
                rowData={rowData}
                getRowNodeId={(data) => data.id}
                embedFullWidthRows={true}
                animateRows={true}
                undoRedoCellEditing={true}
                enableRangeSelection={false}
                undoRedoCellEditingLimit={20}
                rowSelection={"single"}
                enableCellChangeFlash={true}
                context={{
                  hoistData: elevationHoist,
                }}
                gridOptions={{
                  defaultColDef: {
                    editable: true,
                    enableRowGroup: true,
                    enablePivot: true,
                    enableValue: true,
                    sortable: true,
                    resizable: true,
                    filter: true,
                    // flex: 1,
                    minWidth: 100,
                    enablePivot: false,
                  },
                }}
                sideBar={{
                  toolPanels: [
                    {
                      id: "columns",
                      labelDefault: "Columns",
                      labelKey: "columns",
                      iconKey: "columns",
                      toolPanel: "agColumnsToolPanel",
                    },
                    {
                      id: "filters",
                      labelDefault: "Filters",
                      labelKey: "filters",
                      iconKey: "filter",
                      toolPanel: "agFiltersToolPanel",
                    },
                  ],
                  // defaultToolPanel: "customStats",
                }}
                autoSizeStrategy={{
                  type: "fitCellContents",
                }}
                onFirstDataRendered={(params) => {
                  let columnApi = params.columnApi;
                  const allColumnIds = [];

                  columnApi.getColumns()?.forEach((column) => {
                    const colId = column?.getId();
                    allColumnIds.push(colId);
                  });
                  try {
                    columnApi.autoSizeColumns(allColumnIds, false);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                onComponentStateChanged={(params) => {
                  let columnApi = params.columnApi;
                  const allColumnIds = [];

                  columnApi.getColumns()?.forEach((column) => {
                    const colId = column?.getId();
                    allColumnIds.push(colId);
                  });
                  try {
                    columnApi.autoSizeColumns(allColumnIds, false);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                getRowStyle={getRowPriceStyle}
                headerHeight={50}
                onGridReady={(params) => {}}
                columnDefs={getTableDataColumns({ removeRow, addRow })}
                suppressDragLeaveHidesColumns={true}
                allowDragFromColumnsToolPanel={true}
                onCellValueChanged={onCellValueChanged}
                rowHeight={40}
                masterDetail={true}
                detailRowAutoHeight={true}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default TableHoistCalcModal;
