import { useState } from "react";
import { Button, Tooltip } from "antd";
import { useSelector } from "react-redux";

import { PlusIcon } from "../../../../../assets";
import { TasksIconNew } from "../../../../../icons";
import { XIcon } from "../../../Communication/assets";
import { InspectionModal } from "../../../XComponents";
import { MondayButton } from "../../../../commonComponents";
import TasksBasePage from "../../../TaskManager/Tasks/TasksBasePage";

import "./Tasks.scss";

export const Tasks = ({
  rowData,
  taskTopic,
  taskTopicId,
  taskRelatedTo,
  taskSubTopic,
  type = "icon",
}) => {
  const [visible, setVisible] = useState(false);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const title = "Tasks";

  const handleModalVisibility = () => {
    setVisible((prevState) => !prevState);
  };

  const reports = true;

  return (
    <>
      <Tooltip title="Tasks">
        {type === "icon" ? (
          <Button
            onClick={handleModalVisibility}
            className={`${reports && "header-task-btn-reports"} ${
              isDarkMode
                ? `header-task-btn-dark-report`
                : "header-task-btn-light-report"
            } ${reports && "header-task-btn-reports"}`}
            icon={
              <span
                style={{
                  fill: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TasksIconNew />
              </span>
            }
          />
        ) : (
          <MondayButton
            Icon={<PlusIcon />}
            onClick={handleModalVisibility}
            className="mondayButtonBlue"
          >
            Create Task
          </MondayButton>
        )}
      </Tooltip>

      <InspectionModal
        {...{
          visible,
          onCancel: () => handleModalVisibility(),
          title,
          width: 1600,
          footer: false,
          closable: true,
          closeIcon: <XIcon fill="#333238" />,
          className: isDarkMode
            ? "header-tasks-modal-dark"
            : "header-tasks-modal-light",
        }}
      >
        <TasksBasePage
          {...{
            customTaskProps: {
              rowData,
              taskTopic,
              taskTopicId,
              taskRelatedTo,
              taskSubTopic,
            },
          }}
        />
      </InspectionModal>
    </>
  );
};
