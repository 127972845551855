import { Dayjs } from "dayjs";
import { Form, FormInstance, Tooltip } from "antd";
import { UIEvent, useContext, useMemo, useState } from "react";

import { GroupedEntry } from "../../payrollLiveTypes";
import PayrollLiveContext from "../../PayrollLiveContext";
import CardComponent from "src/components/CardComponent/CardComponent";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { CoordinateType } from "src/components/SidebarPages/FleetMaintenanceView/types";
// import { getLiveEmployeeReport } from "../../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./LateClockIn.scss";

interface Props {
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
}

const initialIndex = {
  start: 0,
  end: 15,
};

function LateClockIn(props: Props) {
  const { form } = props;
  const {
    mapRef,
    // setFilters,
    onPageSelect,
    groupedEntries,
    employeesReport,
    setEmployeesInfo,
    setEmployeesReport,
  } = useContext(PayrollLiveContext);

  const [sliceIndex, setSliceIndex] = useState<{ start: number; end: number }>(
    initialIndex
  );

  const punchEnd = Form.useWatch("punchEnd", form);
  const punchStart = Form.useWatch("punchStart", form);
  const searchValue = Form.useWatch("searchField", form);
  // const reportDateRange = Form.useWatch("reportDate", form);

  async function onEmpClick(
    employeeId: string,
    punchCoordinates: CoordinateType,
    date: string
  ) {
    const index = employeesReport.findIndex(
      (el) => el.employeeId === employeeId
    );
    const employeeNumber = employeesReport?.[index]?.employeeNumber;
    // const isTodayDate =
    //   parseInTz().startOf("d").valueOf() ===
    //   parseInTz(date).startOf("d").valueOf();

    // if (!isTodayDate) {
    //   let empIndex = groupedEntries.findIndex(
    //     (el) => el.employeeId === employeeId && date === date
    //   );

    //   const report = groupedEntries?.[empIndex]?.ID?.[0];
    //   const empReport = {
    //     color: null,
    //     company: report?.company,
    //     punchId: report?.entryId,
    //     entryType: "Mobile Punch",
    //     punchTime: report?.punchTime as string,
    //     projectId: report?.jobsiteId,
    //     jobsiteId: report?.jobsiteId,
    //     liveStatus: "In",
    //     lastFetch: null,
    //     employeeId: report.employeeId,
    //     crewTeamId: null,
    //     employeeName: report?.employeeFullName,
    //     crewTeamName: report?.crewTeamName,
    //     fingerCheckId: 999111999,
    //     punchLocation: report?.punchLocation,
    //     employeeNumber: report?.employeeId,
    //     punchCoordinates: report.punchCoordinates,
    //   };
    //   setEmployeesReport((prev) => [
    //     ...prev.filter((el) => el.fingerCheckId !== 999111999),
    //     empReport,
    //   ]);
    // }
    onPageSelect("map");
    setTimeout(() => {
      if (employeeNumber) {
        setEmployeesInfo(employeeNumber);
        // setFilters((prev) => ({ ...prev, employeeNumber: [employeeNumber] }));
      }
      if (mapRef?.current?.fitBoundsToMarkers) {
        mapRef.current.fitBoundsToMarkers([punchCoordinates]);
      }
    }, 800);
  }

  function onScroll(e: UIEvent<HTMLElement>): void {
    const scrollStep = 10;
    const maxStartIndex = lateEntires.length - scrollStep;
    const maxEndIndex = lateEntires.length - 1;
    let indexPosition = e.currentTarget.scrollTop / 64;

    if (indexPosition >= 5 && sliceIndex.end === scrollStep) {
      setSliceIndex(() => ({
        start: 0,
        end: 15 + scrollStep,
      }));
    }

    if (indexPosition >= 18 && sliceIndex.end !== maxEndIndex) {
      setSliceIndex((prev) => {
        let startIndex = prev.start + scrollStep;
        let endIndex = prev.end + scrollStep;
        return {
          start: startIndex > maxStartIndex ? maxStartIndex : startIndex,
          end: endIndex > maxEndIndex ? maxEndIndex : endIndex,
        };
      });
    }

    if (indexPosition <= 0.4) {
      setSliceIndex((prev) => {
        return {
          start: prev.start >= scrollStep ? prev.start - scrollStep : 0,
          end: prev.end > scrollStep ? prev.end - scrollStep : scrollStep,
        };
      });
    }
  }

  const lateEntires = useMemo(() => {
    let filteredGroupedEntries: Array<GroupedEntry> = [];

    for (let i = 0; i < groupedEntries.length; i++) {
      let pass = false;

      const report = groupedEntries[i];
      const startTime = parseInTz(report.date, "YYYY-MM-DD")
        .set("hour", 7)
        .set("minute", 0)
        .startOf("m")
        .valueOf();

      if (
        !report.employeeName
          .toLowerCase()
          .includes((searchValue || "").toLowerCase())
      ) {
        // search matches report name
        pass = false;
      }

      if ((report?.ID?.[0]?.punchTimeStamp || 0) > startTime) {
        pass = true;
      } else {
        pass = false;
      }

      if (punchStart) {
        const entryTime = dayjsNY(report?.ID?.[0]?.punchTime).valueOf();

        if (entryTime <= punchStart?.startOf("m")?.valueOf?.()) {
          pass = false;
        }
      }

      if (punchEnd) {
        const entryTime = dayjsNY(report?.ID?.[0]?.punchTime).valueOf();
        if (entryTime >= punchEnd?.startOf("m")?.valueOf?.()) {
          pass = false;
        }
      }

      // has date range filter
      // if (reportDateRange?.length == 2) {
      //   const endDate = parseInTz(reportDateRange?.[1]).valueOf();
      //   const startDate = parseInTz(reportDateRange?.[0]).valueOf();
      //   const reportDate = parseInTz(report?.date, "YYYY-MM-DD").valueOf();
      //   // if date is inside date range
      //   if (startDate > reportDate || endDate < reportDate) {
      //     pass = false;
      //   }
      // }

      if (pass) {
        filteredGroupedEntries.push(report);
      }
    }

    return filteredGroupedEntries.map((rep, i) => {
      console.log("rep: ", rep);
      return (
        <div className={`report-card ${rep?.status}`} key={i}>
          <div className="report-header">
            <Tooltip title={`Go to location`}>
              <label
                onClick={() =>
                  onEmpClick(
                    rep.employeeId,
                    rep?.ID?.[0]?.punchCoordinates,
                    rep.date
                  )
                }
              >
                {rep?.employeeName}
              </label>
            </Tooltip>
          </div>
          <div className="report-body">
            <label>{parseInTz(rep.date).format("MM/DD/YYYY")}</label>
            <label key={rep?.ID?.[0]?.entryId}>
              {dayjsNY(rep?.ID?.[0]?.punchTimeStamp).format("hh:mm A")}
            </label>
          </div>
        </div>
      );
    });
  }, [groupedEntries, searchValue, punchEnd, punchStart]);

  return (
    <CardComponent
      title="Late Clock In"
      key="late-clock-in"
      className="late-reports-card"
    >
      <>
        <div className="cards-wrapper" onScroll={onScroll}>
          {lateEntires?.length ? (
            lateEntires.slice(sliceIndex.start, sliceIndex.end)
          ) : (
            <div className="no-data">No Late Clock In</div>
          )}
        </div>
        <span className="reports-count">
          {lateEntires?.length} Total late clock in
        </span>
      </>
    </CardComponent>
  );
}

export default LateClockIn;
