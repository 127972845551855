import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountingConfirmationModal } from "../../../../../../../../commonComponents/index";
import { useRedux } from "../../../../../../../hooks/useRedux";
import paymentInitialState from "../../utils/paymentInitialState";
import "./Header.scss";
import AccountingWriteMode from "../../../../../../../../commonComponents/AccountingWriteMode/AccountingWriteMode";
import formValidation from "../../utils/formValidation";
import { queryParams } from "../../../../../../../../../utils/queryParams";
import { XIcon } from "../../../../../../../Communication/assets";

const Header = ({
  closePaymentModal,
  paymentData,
  projectData,
  handleSave,
  fetchPayments,
  isDarkMode,
}) => {
  const [confirmData, setConfirmData] = useState();
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable, setIsWritable] = useRedux("paymentIsWritable");
  let isSaveButtonClicked = false; //Used when the user click the save button more than once and wait for the service to be saved

  const navigate = useNavigate();
  const location = useLocation();

  const handleCancel = () => {
    closePaymentModal();
    if (location.pathname !== "/accounting") {
      navigate(
        `/projects/${
          projectData
            ? projectData?.projectId
            : fakePayment?.projects?.[0]?.projectId
        }?${queryParams({
          tab: "Accounting",
          subTab: "Payments",
        })}`,
        {
          accountingTab: "Payments",
          tabPosition: "Accounting",
          fromAccounting: true,
          search: queryParams({
            tab: "Accounting",
            subTab: "Payments",
          }),
        }
      );
    }
    setFakePayment({
      ...paymentInitialState,
      accountName: projectData?.accountName || "",
      accountId: projectData?.accountId || "",
    });
    setConfirmData();
    fetchPayments();
  };

  return (
    <div className="payment-modal-header">
      <div className="payment-header-left">
        <div>
          {paymentData
            ? `Edit Payment # ${paymentData[0]?.paymentNumber}`
            : "New Payment"}
        </div>
        {paymentData && (
          <AccountingWriteMode
            {...{
              isWritable,
              recordId: paymentData[0]?.paymentId,
              onSwitchFunction: setIsWritable,
              projectId: paymentData[0]?.projectId,
            }}
          />
        )}
      </div>
      <div
        className="close-btn"
        onClick={() => {
          fakePayment.invoices.length > 0
            ? setConfirmData({
                ...{
                  visible: true,
                  setVisible: setConfirmData,
                  onConfirm: () => {
                    // Check if required fields are not empty
                    if (!formValidation(fakePayment)) {
                      return;
                    }
                    if (!isSaveButtonClicked) {
                      isSaveButtonClicked = true;
                      handleSave()
                        .then(() => {
                          isSaveButtonClicked = false;
                        })
                        .then(setConfirmData());
                    }
                  },
                  onCancel: () => {
                    setConfirmData();
                  },
                  onDiscard: () => {
                    handleCancel();
                  },
                  text: paymentData
                    ? "Any change that you have made will be lost?"
                    : "This payment will be discarded",
                  title: "Confirm Close",
                },
              })
            : handleCancel();
        }}
      >
        <XIcon />
      </div>
      {confirmData && fakePayment.invoices.length > 0 && (
        <AccountingConfirmationModal
          {...{
            ...confirmData,
            callBackFunc: () => setConfirmData(),
            darkMode: isDarkMode,
          }}
        />
      )}
    </div>
  );
};

export default Header;
