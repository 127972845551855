import { blobToBase64 } from "../../../../../../utils";

export const getImageSrcWithProgress = async (
  fileId,
  progressCallback,
  size,
  getDriveItem,
  currFile
) => {
  let src, blob;

  try {
    const response = await getDriveItem(fileId, { alt: "media" });
    const reader = response.body.getReader();
    let receivedLength = 0;
    let chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      receivedLength += value.length;
      chunks.push(value);

      if (progressCallback) {
        progressCallback(receivedLength, Number(size), currFile);
      }
    }

    const concatenatedChunks = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      concatenatedChunks.set(chunk, position);
      position += chunk.length;
    }

    const res = new Blob([concatenatedChunks], {
      type: response.headers.get("content-type"),
    });
    blob = URL.createObjectURL(res);
    src = await blobToBase64(res);
  } catch (error) {
    console.error("Error:", error);
  }

  return { src, blob };
};

export const convertObjectUrlToBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const src = await blobToBase64(blob);
  return { src, blob: url };
};
