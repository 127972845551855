import { useRedux } from "../../../../../../hooks";
import { useGroupsContext } from "../context/GroupsContext";

const NumberComponent = ({ value: information, data }) => {
  const { groups } = useGroupsContext();
  const [visible] = useRedux("newChargeVisible");

  return (
    <div className="cellContainer">
      {visible?.categoryFrom === "Estimation"
        ? groups?.find(({ id }) => id === data.id)?.information?.length
        : information?.description?.length}
    </div>
  );
};

export default NumberComponent;
