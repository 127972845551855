import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../integrations/DriveRequest";
import { showErrorMsg, showLoadingMsg, showSuccessMsg } from "../utils";
import {
  keys,
  values,
} from "../components/pages/Settings/settingsComponents/Roles/RolesData";
import { getDriveFolderKeyName } from "../components/SidebarPages/utils/getDriveFolderKeyName";

const key = "projectDriveFolders";

/**
 *
 * @param {Function} getFolderIdOrCreate - create new Drive Folder
 * @param {Function} updateDriveItem - move a Drive Folder
 * @param {Object} oldFolders
 * @param {String} parentFolderName - name of the main parent folder
 * @param {String} parentId - id of the main parent folder
 * @param {Object} listOfFolders - folder work tree comes from program fields
 * @returns all folders created and their ids
 */
const createAllFolders = async ({
  getFolderIdOrCreate = async () => {},
  updateDriveItem = async () => {},
  oldFolders = {},
  parentFolderName,
  parentId,
  listOfFolders,
}) => {
  try {
    showLoadingMsg({
      content: "Google Drive folders are being created...",
      key,
      duration: 0,
    });
    const parentFolderId = await getFolderIdOrCreate({
      name: parentFolderName,
      parents: [parentId],
    });

    //* allFolders keeps the foldersIds for every folder created
    let allFolders = {
      [listOfFolders?.folderKeyName]: parentFolderId,
    };
    /**
     *
     * @param {String} name - name of the folder that will be created
     * @param {String} parentFolderId - id of the parent folder
     * @param {String} folderKeyName - name of the folder key that will be saved on database
     * @returns the newly created or updated folder id
     */
    const createFolder = async (name, parentFolderId, folderKeyName) => {
      let temp;
      //* If folder exists in all folders move it to the new folder if not create a new one and add it to allFolders
      if (!!oldFolders?.[folderKeyName]) {
        await updateDriveItem(oldFolders?.[folderKeyName], {
          addParents: parentFolderId,
        })
          .then(async (res) => {
            allFolders[folderKeyName] = oldFolders?.[folderKeyName];
            temp = { [folderKeyName]: oldFolders?.[folderKeyName] };
          })
          .catch(() =>
            showErrorMsg({ content: "Error Moving Old Folders", key })
          );
      } else {
        await getFolderIdOrCreate({
          name,
          parents: [parentFolderId],
        })
          .then((res) => {
            allFolders[folderKeyName] = res;
            temp = { [folderKeyName]: res };
          })
          .catch(() =>
            showErrorMsg({ content: "Error Creating Folders", key })
          );
      }
      return temp;
    };

    /**
     *
     * @param {Object} value
     * @returns checks if the value is not an object or an empty object
     */
    const isObject = (value) => {
      return !!(
        value &&
        typeof value === "object" &&
        !Array.isArray(value) &&
        keys(value).length > 0
      );
    };

    /**
     *
     * @param {Object} subFolders - all subfolder of the folder we are in
     * @param {String} parentId - the parent id of the folder we are in
     * @returns {Array} an array of object with all new folderIds
     */
    const promiseAllCreate = async (subFolders, parentId) => {
      return await Promise.all(
        Object.entries(subFolders).map(([key, value]) =>
          createFolder(key, parentId, value.folderKeyName)
        )
      );
    };

    /**
     *
     * @param {Object} listOfFolders - folders tree that is defined in program field
     * @param {String} parent - main parent when creating folder
     * ! This is a recursion that created google drive folders based on the the tree that comes from program Fields
     */

    const dynamicallyCreateFolders = async (listOfFolders, parent) => {
      if (isObject(listOfFolders)) {
        const allIds = await promiseAllCreate(listOfFolders, parent);
        for (let key in listOfFolders) {
          if (typeof listOfFolders[key] === "string") continue;

          if (isObject(listOfFolders[key])) {
            let folder = listOfFolders[key];
            if (isObject(folder?.subFolders))
              await dynamicallyCreateFolders(
                folder?.subFolders,
                allIds?.find((id) => !!id?.[folder?.folderKeyName])?.[
                  folder?.folderKeyName
                ]
              );
          }
        }
      }
    };

    await dynamicallyCreateFolders(listOfFolders?.subFolders, parentFolderId);

    showSuccessMsg({
      content: "Google Drive folders created successfully!",
      key,
    });

    return allFolders;
  } catch (error) {
    console.error("google drive", error);
    showErrorMsg({
      content: "Error creating Google Drive folders!",
      key,
    });
    return Promise.reject(error);
  }
};

/**
 *
 * @param {String} name - Name of the Modal saved in programFields
 * @returns
 */

export const useCreateDriveFolders = (name) => {
  if (!name) return;
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);

  const driveRequest = driveApi({ accessToken });
  const { getFolderIdOrCreate, updateDriveItem, deleteDriveItem } =
    driveRequest;

  const listOfFolders = programFields?.find(
    ({ fieldName }) => fieldName === "List of Folders"
  )?.fieldOptions?.[name];

  const [allFolders, setAllFolders] = useState({});
  const [oldFolders, setOldFolders] = useState();
  const [oldFoldersParent, setOldFolderParent] = useState("");
  const [googleDriveUploads, setGoogleDriveUploads] = useState();

  /**
   *
   * @param {Object} oldFolders - googleDriveIds from old type
   * It keeps track of old parent folder in case the folders are moved but the modal is canceled
   */
  const getOldParentId = async (oldFolders, oldFolderParentName) => {
    setOldFolders(oldFolders);
    setOldFolderParent(
      oldFolders?.[
        getDriveFolderKeyName({ programFields, name: oldFolderParentName })
      ]
    );
  };

  useEffect(() => {
    return () => {
      setAllFolders({});
      setOldFolders();
      setOldFolderParent("");
      setGoogleDriveUploads();
    };
  }, []);

  return {
    /**
     * @param {String} parentFolderName - name of the main parent folder that will be created
     * @param {String} parentId - id of the main parent folder that will be created
     * @returns {Object} {folders, parentFolder} - new created folder object and the new parent folderKeyName
     */
    async create({
      parentFolderName,
      parentId,
      googleDriveUploads = null,
      extraFolders = null,
    }) {
      let folders = await createAllFolders({
        parentFolderName,
        parentId,
        getFolderIdOrCreate,
        listOfFolders,
      });

      if (!!extraFolders) {
        folders = await this.createExtraFolders(extraFolders, folders);
      }

      setAllFolders(folders);
      if (!!googleDriveUploads) {
        await Promise.all(
          googleDriveUploads?.map(({ id }) =>
            updateDriveItem(id, {
              addParents: folders?.[listOfFolders?.folderKeyName],
            })
          )
        );
      }
      return { folders, parentFolder: folders?.[listOfFolders?.folderKeyName] };
    },
    /**
     *
     * @param {Object} oldFolders - googleFolderDriveIds from converting object
     * @param {String} parentFolderName - name of the main parent folder that will be created
     * @param {String} parentId - id of the main parent folder that will be created
     * @param {String} updateFrom - old type that we want to convert from
     * @returns {Object} {folders, parentFolder} - new created folder object and the new parent
     * folderKeyName
     */
    update: async ({
      oldFolders,
      parentFolderName,
      parentId,
      updateFrom,
      googleDriveUploads = null,
    }) => {
      getOldParentId(oldFolders, updateFrom);
      let folders = await createAllFolders({
        oldFolders,
        parentFolderName,
        parentId,
        getFolderIdOrCreate,
        updateDriveItem,
        listOfFolders,
      });
      setAllFolders(folders);
      if (!!googleDriveUploads) {
        await Promise.all(
          googleDriveUploads?.map(({ id }) =>
            updateDriveItem(id, {
              addParents: folders?.[listOfFolders?.folderKeyName],
            })
          )
        );
        setGoogleDriveUploads(googleDriveUploads);
      }
      return { folders, parentFolder: folders?.[listOfFolders?.folderKeyName] };
    },
    /**
     * @returns {String} parentFolderId - new parent folder id
     */
    parentFolderId: allFolders?.[listOfFolders?.folderKeyName],
    /**
     * @returns {Object} allFolders - new folders object
     */
    allFolders,
    delete: async (folderName = "") => {
      if (!!oldFoldersParent && !folderName) {
        await Promise.all([
          ...(values(oldFolders).map((value) => {
            updateDriveItem(value, {
              addParents: oldFoldersParent,
            });
          }) || []),
          ...(googleDriveUploads?.map(({ id }) =>
            updateDriveItem(id, {
              addParents: oldFoldersParent,
            })
          ) || []),
        ]);
        setOldFolderParent("");
        setOldFolders();
      }
      await deleteDriveItem(
        !!folderName
          ? allFolders?.[folderName]
          : allFolders?.[listOfFolders?.folderKeyName]
      );
    },
    reset: () => {
      setAllFolders({});
      setOldFolders();
      setOldFolderParent("");
      setGoogleDriveUploads();
    },
    async createExtraFolders({ parentFolderName, parentFolder = "" }, folders) {
      if (!parentFolderName) return;
      let newParentId = !!parentFolder
        ? folders?.[listOfFolders?.[parentFolder]?.folderKeyName]
        : folders?.[listOfFolders?.folderKeyName];

      const extraFolders = {};

      if (Array.isArray(parentFolderName)) {
        await Promise.all(
          parentFolderName.map(async (name) => {
            const folder = await getFolderIdOrCreate({
              name,
              parents: [newParentId],
            });

            extraFolders[name] = {
              id: folder,
            };
          })
        );
      }
      if (typeof parentFolderName === "string") {
        const folder = await getFolderIdOrCreate({
          name: parentFolderName,
          parents: [newParentId],
        });
        extraFolders[parentFolderName] = {
          id: folder,
        };
      }

      return { ...folders, ...extraFolders };
    },
  };
};
