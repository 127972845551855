import { Skeleton, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CarouselModal } from "src/components/SidebarPages/Inspections/view/modals";
import { driveApi } from "src/integrations/DriveRequest";
import "./QuestionsImages.scss";

const QuestionsImages = ({ files }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageData, setImageData] = useState([]);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  useEffect(() => {
    const getImages = async () => {
      try {
        setLoading(true);
        const imageSrcPromises = files?.map(async ({ fileId, question }) => {
          const imageSrc = await driveRequest.getImageSrc(fileId);
          return [{ fileId, ...imageSrc, question }];
        });
        const newImageSrcArray = await Promise.all(imageSrcPromises);
        setImageData(newImageSrcArray.flat());
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getImages();
  }, [files]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          gap: "5px",
          overflowX: "auto",
          justifyContent: "end",
        }}
      >
        {files.map((_, i) => (
          <Skeleton.Avatar
            id={i}
            active
            key={i}
            shape="square"
            style={{
              height: 50,
              width: 100,
              borderRadius: "5px",
            }}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="questions-images-container">
      {imageData.map(({ src, question }, index) => {
        return (
          <Tooltip title={question}>
            <div
              key={index}
              className="image-container"
              onClick={() => {
                setVisible(true);
                setImageIndex(index);
              }}
            >
              <img
                src={src}
                alt={`Image ${index + 1}`}
                className="thumbnail-img"
              />
            </div>
          </Tooltip>
        );
      })}

      {visible && (
        <CarouselModal
          {...{
            visible,
            setVisible,
            shownImages: imageData.map(({ question, ...rest }) => rest),
            onDelete: () => {},
            isDraft: null,
            imageIndex,
          }}
        />
      )}
    </div>
  );
};

export default QuestionsImages;
