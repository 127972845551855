import { useSelector } from "react-redux";
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
  useEffect,
} from "react";

import { ReportsBlank } from "src/icons";
import { useResponsive } from "src/hooks";
import { MapsIcon, TableIcon } from "src/assets";
import PayrollLiveContext from "../../PayrollLiveContext";
import { ChartIcon } from "src/components/SidebarPages/BasePage/src";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./PayrollLiveSidebar.scss";

const PayrollLiveSidebar = forwardRef((_, ref) => {
  const { selectedPage, onPageSelect } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const { width } = useResponsive();

  const [toggleOpen, setToggleOpen] = useState<boolean>(width > 1180);

  function toggleSidebar() {
    if (width <= 1180) {
      return;
    }
    if (toggleOpen) {
      const menuTitles = document.getElementsByClassName("navigation-title");
      const animation = [
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
      ];
      const timing = {
        duration: 300,
        rangeStart: "0%",
        rangeEnd: "100%",
        transition: "ease-put",
      };
      for (const element of menuTitles) {
        element.animate(animation, timing);
      }
    }
    setToggleOpen((prev) => !prev);
  }

  useEffect(() => {
    if (width < 1180) {
      setToggleOpen(false);
    } else if (width > 1180) {
      setToggleOpen(true);
    }
  }, [width]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleOpen,
        onPageSelect,
        toggleSidebar,
      };
    },
    [toggleOpen]
  );

  return (
    <section
      className={`payroll-live-sidebar ${
        darkMode ? "payroll-live-sidebar-dark" : ""
      } ${toggleOpen ? "open-sidebar" : "closed-sidebar"}`}
    >
      <div className="sidebar-wrapper">
        <div className="sidebar-navigator">
          <div
            className={`navigation-item ${
              selectedPage === "map" ? "active" : ""
            }`}
            onClick={() => onPageSelect("map")}
          >
            <div className="navigation-icon">
              <MapsIcon height={16} width={16} />
            </div>
            {toggleOpen ? <div className="navigation-title">Map</div> : null}
          </div>

          <div
            className={`navigation-item ${
              selectedPage === "deg" ? "active" : ""
            }`}
            onClick={() => onPageSelect("deg")}
          >
            <div className="navigation-icon">
              <TableIcon />
            </div>
            {toggleOpen ? (
              <div className="navigation-title">Live DEG</div>
            ) : null}
          </div>

          <div
            onClick={() => onPageSelect("employeeReports")}
            className={`navigation-item ${
              selectedPage === "employeeReports" ? "active" : ""
            }`}
          >
            <div className="navigation-icon">
              <ReportsBlank height={16} width={16} />
            </div>
            {toggleOpen ? (
              <div className="navigation-title">Employee Reports</div>
            ) : null}
          </div>

          <div
            onClick={() => onPageSelect("analytics")}
            className={`navigation-item ${
              selectedPage === "Analytics" ? "active" : ""
            }`}
          >
            <div className="navigation-icon">
              <ChartIcon height={16} width={16} fill="323338" />
            </div>
            {toggleOpen ? (
              <div className="navigation-title">Analytics</div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
});

export default PayrollLiveSidebar;
