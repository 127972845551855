import { Modal } from "antd";
import React from "react";
import { tableHeaderForSubPliBreakdowns } from "../SchedulingFirstPage/helperData";
import { breakdown_dimension } from "../PLIPerService/componentsForPli/Breakdown/breakdown";
import { get_ProgressDimensionsByServiceId } from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import "./SubPliBreakdown.scss";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { updateDocumentTitle } from "../../../../../utils";
const SubPliBreakdown = ({
  subPliBreakdowns,
  setSubPliBreakdowns,
  isDarkMode,
}) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(
    subPliBreakdowns?.serviceId
  );
  return (
    <div>
      <Modal
        className={`subPliBreakdowns-modal ${
          isDarkMode && "subPliBreakdowns-modal-dark"
        }`}
        width={600}
        title={subPliBreakdowns?.comesFrom + " - " + subPliBreakdowns?.date}
        afterOpenChange={(event) => {
          event &&
            updateDocumentTitle({
              newTitle:
                subPliBreakdowns?.comesFrom + " - " + subPliBreakdowns?.date,
            });
        }}
        centered
        open={subPliBreakdowns?.open}
        onCancel={() => {
          updateDocumentTitle(); // reset document title to "Lead Manager";
          setSubPliBreakdowns({ ...subPliBreakdowns, open: false });
        }}
        closeIcon={<XIcon />}
        footer={null}
      >
        <div>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <tr
              style={{
                textAlign: "left",
              }}
            >
              {tableHeaderForSubPliBreakdowns(subPliBreakdowns?.comesFrom)?.map(
                (header) => {
                  return (
                    <th
                      style={{
                        border: "1px solid #e8e8e8",
                        paddingLeft: 5,
                      }}
                    >
                      {header}
                    </th>
                  );
                }
              )}
            </tr>
            {subPliBreakdowns?.data?.map((a, index) => {
              const progressByDimension = a?.progressByDay?.find(
                (b) => b?.day === subPliBreakdowns?.dayId
              );
              return (
                <tr
                  style={{
                    border: "1px solid #e8e8e8",
                    background: !progressByDimension
                      ? "transparent"
                      : "#368636",
                    color: !progressByDimension ? "" : "white",
                  }}
                >
                  <td
                    style={{
                      // textAlign: "center",
                      paddingLeft: 5,
                      fontWeight: !progressByDimension ? "" : 600,
                    }}
                  >
                    {subPliBreakdowns?.comesFrom === "Breakdowns" ||
                    subPliBreakdowns?.comesFrom === "Jump Breakdowns"
                      ? a?.name
                      : a?.id}
                  </td>
                  {(subPliBreakdowns?.comesFrom === "Breakdowns" ||
                    subPliBreakdowns?.comesFrom === "Sub Pli") && (
                    <td
                      style={{
                        fontWeight: !progressByDimension ? "" : 600,
                        // textAlign: "center",
                        paddingLeft: 5,
                      }}
                    >
                      {subPliBreakdowns?.comesFrom === "Breakdowns"
                        ? a?.rate + " " + "%"
                        : a?.totalProgress + " " + "lf"}
                    </td>
                  )}
                  <td
                    style={{
                      fontWeight: !progressByDimension ? "" : 600,
                      // textAlign: "center",
                      paddingLeft: 5,
                    }}
                  >
                    {!!a?.progressByDay?.some(
                      (a) => a?.day === subPliBreakdowns?.dayId
                    )
                      ? "Yes"
                      : "No"}
                  </td>
                  <td
                    style={{
                      fontWeight: !progressByDimension ? "" : 600,
                      // textAlign: "center",
                      paddingLeft: 5,
                    }}
                  >
                    {subPliBreakdowns?.comesFrom === "Breakdowns" ||
                    subPliBreakdowns?.comesFrom === "Jump Breakdowns"
                      ? a?.progressByDay?.find(
                          (a) => a?.day === subPliBreakdowns?.dayId
                        )?.[breakdown_dimension] || 0
                      : !a?.[d2]
                      ? (progressByDimension?.[d1] || 0) + " " + "lf"
                      : (progressByDimension?.[d1] || 0) *
                          (progressByDimension?.[d2] || 0) +
                          " " +
                          "lf" || 0}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default SubPliBreakdown;
