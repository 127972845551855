export const charges = (
  circleProgressPicker,
  IdHandler,
  updatedDynamicStates,
  chargesData
) => {
  return {
    categoryFrom: () => ({
      title: "Charges - Category From",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Charges",
        field: "categoryFrom",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (chargesData?.filter((a) => a?.categoryFrom === circleProgressPicker)
            ?.length /
            chargesData?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: chargesData?.filter(
          (a) => a?.categoryFrom === circleProgressPicker
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //categoryFrom
  };
};
