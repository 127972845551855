import { useState } from "react";
import { API } from "aws-amplify";
import { Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { RedWarningModal } from "../../../../../../../../commonComponents";
import { userGroups as userGroupsAction } from "../../../../../../../../../actions";
import { CopyIconWhite } from "../../../../../../../../SidebarPages/DynamicView/src";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";

import {
  InfoText,
  MondayButtonIcon,
} from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import "./CloneDepartmentAccess.scss";

/**
 * Renders a modal component for cloning access for a specific role.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.openCloneAccess - Flag indicating whether the clone access modal is open.
 * @param {function} props.setOpenCloneAccess - Function to set the openCloneAccess flag.
 * @param {string} props.currentRole - The current role for which access is being cloned.
 * @returns {JSX.Element} The CloneAccess component.
 */
const CloneAccess = ({ currentRole }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { programFields } = useSelector((state) => state.programFields);
  const { userGroups } = useSelector((state) => state.userGroups);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const [openWarning, setOpenWarning] = useState(false);

  const departments = programFields?.find(
    ({ fieldName }) => fieldName === "Departments"
  )?.fieldOptions;

  const currentRoleObject =
    userGroups?.find(({ groupName }) => groupName === currentRole) || {};

  const roles =
    userGroups.filter(({ groupName }) => groupName !== currentRole) || [];

  const onChangeDepartment = (e) => {
    setSelectedDepartment(e);
    form.setFieldsValue({ roles: "" });
    setSelectedRole("");
  };

  async function onSave() {
    const newGroupConfigs =
      roles?.find(({ groupId }) => groupId === selectedRole)?.groupConfigs ||
      {};
    const newObj = {
      ...currentRoleObject,
      groupConfigs: { ...newGroupConfigs },
    };
    const newUserGroups = [...roles, newObj];
    await API.put("userGroups", `/userGroups/${currentRoleObject.groupId}`, {
      body: {
        groupConfigs: {
          ...newGroupConfigs,
        },
      },
    })
      .then((res) => {
        message.success("User Updated Successfully!");
        setOpenWarning(false);
        dispatch(userGroupsAction(newUserGroups));
      })
      .catch((err) => {
        message.error("ERROR");
        console.error(err);
      });
  }

  const selectedRoleLabel =
    roles?.find(({ groupId }) => groupId === selectedRole)?.groupName || "";

  return (
    <div
      className={`cloneDepartmentAccess ${
        isDarkMode && "cloneDepartmentAccessDark"
      }`}
      style={{ padding: "0 20px", display: "flex", gap: 10 }}
    >
      <InfoText
        {...{
          isDarkMode,
          text: "Below you can select a role you want to clone access from.",
          style: { maxWidth: "unset", paddingTop: "20px" },
          className: "user-access-info-text",
        }}
      />
      <Form form={form} layout="vertical">
        <InputComponent
          {...{
            type: "select",
            formItemName: "department",
            label: "Department",
            placeholder: "Select Department",
            customOptions: departments?.map(({ departmentName }) => ({
              label: departmentName,
              value: departmentName,
            })),
            onChange: onChangeDepartment,
            initialValue: selectedDepartment,
            required: true,
            onClear: () => {
              form.setFieldsValue({ roles: "" });
              setSelectedRole("");
            },
          }}
        />
        <InputComponent
          {...{
            type: "select",
            formItemName: "roles",
            label: "Roles",
            placeholder: "Select Roles",
            dropdownClassName: isDarkMode && "darkDropDown",
            customOptions: roles
              ?.filter(
                ({ departmentName }) => departmentName === selectedDepartment
              )
              ?.map(({ groupName, groupId }) => ({
                label: groupName,
                value: groupId,
              })),
            onChange: (v) => {
              setSelectedRole(v);
            },
            initialValue: selectedRole,
            required: true,
          }}
        />
        <MondayButtonIcon
          className={
            !selectedRole || !selectedDepartment
              ? `mondayButtonGrey`
              : `mondayButtonGreen`
          }
          Icon={<CopyIconWhite />}
          onClick={() => form.validateFields().then(() => setOpenWarning(true))}
          disabled={!selectedRole || !selectedDepartment}
        />
      </Form>

      {openWarning && (
        <RedWarningModal
          {...{
            visible: openWarning,
            onCancel: () => {
              setSelectedDepartment(null);
              setSelectedRole(null);
              form.setFieldsValue({ roles: null, department: null });
              setOpenWarning(false);
            },
            titleText: "Clone Access",
            footerProps: {
              onConfirm: () => {
                onSave();
                setOpenWarning(false);
              },
            },
          }}
        >
          <p>
            Are you sure you want to clone <b>{selectedRoleLabel}</b> access to{" "}
            <b>{currentRole}</b>?
          </p>
        </RedWarningModal>
      )}
    </div>
  );
};

export default CloneAccess;
