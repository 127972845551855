import React, { useState } from "react";
import ReactQuill from "react-quill";
import { message, Popover } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import ClassRedux from "../../../../../utils/classRedux";

const colorsList = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
];
import { useSelector } from "react-redux";
import { useRedux } from "../../../../../hooks/useRedux";

function rtfEditor(props) {
  const [state, setState] = useState({
    notes: props?.value || {},
  });
  const [isWritable] = useRedux("estimationsIsWritable");

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const rowNode = props.node;
  const gridApi = props.api;
  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        { color: colorsList },
        { background: colorsList },
        "clean",
      ],
    ],
    keyboard: {
      bindings: {
        // tab: false,
        // handleEnter: {
        //     key: 13,
        //     handler: function () {
        //     }
        // },
      },
    },
  };
  const formats = ["bold", "italic", "underline", "color", "background"];

  const afterGuiAttached = () => {};

  const keyPressEventHandler = (event) => {};
  const handleChange = (content) => {
    setState({
      ...state,
      notes: {
        ...state?.notes,
        note: content,
        createdBy: props?.userConfiguration?.nameOfUser,
      },
    });
  };

  const handleQuit = (params) => {
    if (params) return;
    if (props.type === "addon") {
      let addon = _.cloneDeep(props?.data);
      addon[props?.colDef?.field] = {
        ...state?.notes,
        note: state?.notes?.note,
        createdAt: dayjs().valueOf(),
        createdBy: props?.userConfiguration?.nameOfUser,
      };

      props.api.applyTransaction({ update: [addon] });
      props.api.redrawRows();

      if (props.addonType === AddonType.serviceAddon) {
        props.onAddonRichTextChange({ service: props?.service });
      } else if (props.addonType === "documentation") {
        props.onAddonRichTextChange({ service: props?.service });
      } else if (props?.addonType === AddonType?.pli) {
        props.onAddonRichTextChange(addon);
      }
    } else {
      const { serviceIndex, optionIndex, elevationIndex } = props;
      let pli = _.cloneDeep(props.data);
      console.log("paramsssss", {
        params,
        props: props?.data,
        state,
        pli,
        field: pli?.[props?.colDef?.field],
      });
      if (pli?.[props?.colDef?.field]?.note === state?.notes?.note) return; //if there is no change
      pli.note = {
        ...state?.notes,
        note: state?.notes?.note,
        createdAt: dayjs().valueOf(),
        createdBy:
          state?.userConfiguration?.nameOfUser ||
          props?.userConfiguration?.nameOfUser,
      };

      props.api.applyTransaction({ update: [pli] });
      props.api.redrawRows();
      props.saveDataFromRTDEditor(
        serviceIndex,
        optionIndex,
        elevationIndex,
        pli
      );
    }
  };

  return (
    <>
      {" "}
      {!props?.node?.group ? (
        <Popover
          content={
            <ReactQuill
              theme="snow"
              onChange={handleChange}
              className={"dataEntryQuill darkModeQuill " + props.agGridTheme}
              modules={modules}
              formats={formats}
              value={state?.notes?.note || ""}
              selection={{ start: 0, end: 0 }}
            />
          }
          overlayClassName="no-padding-antd-popover"
          trigger="click"
          placement="bottom"
          arrowPointAtCente={false}
          onOpenChange={handleQuit}
        >
          <div style={{ width: 120 }}>
            {extractContent(state?.notes?.note || "")}
          </div>
        </Popover>
      ) : (
        ""
      )}
    </>
  );
}

export default rtfEditor;

export function extractContent(s) {
  let span = document.createElement("span");
  span.innerHTML = s;
  return span?.textContent || span?.innerText || "Add content here...";
}
