import "./ReminderModal.scss";
import { useState, useContext, useEffect } from "react";
import moment from "moment-timezone";
moment.tz.setDefault("America/New_York");
import { useSelector } from "react-redux";
import TimeDateContext from "../../../../../DateComponents/TimeDateContext";
import { dateInputValidation } from "../../../../../../utils/dateInputValidation";
import { NormalSizedModal } from "../../../../Fleet/components";
import { MondayButton } from "../../../../../commonComponents";
import { XIcon } from "../../../../Communication/assets";
import {
  DeleteIcon,
  TickIcon,
} from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { InfoIconBlue } from "../../../../../Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import DateTimePicker from "../../../../../DateComponents/components/DateTimePicker/DateTimePicker";

const determineWeekendDay = (date) => {
  const day = dayjsNY(date)?.day();
  return day === 6 || day === 0;
};

const ReminderModal = ({
  visible,
  setVisible,
  reminderDays,
  setReminderDays,
  deadline,
  startDate,
  reminderValue,
  handleReminderDays,
}) => {
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [changes, setChanges] = useState(false);
  const [reminder, setReminder] = useState(reminderDays);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { calendarResult, setActiveDate, setSelectedDate, setSelectedDate2 } =
    useContext(TimeDateContext);

  const getText = () => {
    return reminderValue === "1" ? "everyday" : `every ${reminderValue} days`;
  };

  useEffect(() => {
    setSelectedDate(reminder[selectedReminder]);
    setSelectedDate2(reminder[selectedReminder]);
  }, [selectedReminder]);

  useEffect(() => {
    calendarResult &&
      setReminder?.((prev) => {
        prev[selectedReminder] = dayjsNY(calendarResult)?.startOf()?.valueOf();
        prev?.sort();
        setSelectedReminder(
          prev?.indexOf(dayjsNY(calendarResult)?.startOf()?.valueOf())
        );
        return JSON.parse(JSON.stringify(prev));
      });
    selectedReminder !== null &&
      selectedReminder !== -1 &&
      setActiveDate(moment(dateInputValidation("input", deadline)).valueOf());
  }, [calendarResult]);

  return (
    <NormalSizedModal
      visible={visible}
      title="Reminder Overview"
      className="reminderModal"
      customFooter={
        <div className="footer">
          <MondayButton
            {...{
              className: "mondayButtonRed",
              onClick: () => {
                setVisible(false);
                setSelectedReminder(null);
              },
              Icon: <XIcon />,
            }}
          >
            Cancel
          </MondayButton>
          {!!changes && (
            <MondayButton
              {...{
                className: "mondayButtonGreen",
                onClick: () => {
                  setReminderDays(reminder);
                  handleReminderDays(reminder);
                  setVisible(false);
                  setSelectedReminder(null);
                },
                Icon: <TickIcon />,
              }}
            >
              Save Changes
            </MondayButton>
          )}
        </div>
      }
      style={{
        maxWidth:
          selectedReminder !== null && selectedReminder !== -1 ? 720 : 580,
      }}
    >
      <div
        className="body"
        style={{
          maxWidth:
            selectedReminder !== null && selectedReminder !== -1 ? 760 : 600,
        }}
      >
        <div className="information">
          <div>
            <InfoIconBlue width={20} height={20} />
          </div>
          <div>
            Hello {userConfiguration?.nameOfUser?.split(" ")?.[0]}, you’ve
            selected to be reminded {getText()} from{" "}
            {dayjsNY(startDate).format("MM/DD/YYYY")} to{" "}
            {dayjsNY(deadline).format("MM/DD/YYYY")}. If you want to change the
            reminder for any of the days below, please click on the specific day
            and then proceed to the calendar that will show on your right side.
          </div>
        </div>
        <div className="content">
          <div
            className="reminderDays"
            style={{
              maxWidth:
                selectedReminder !== null && selectedReminder !== -1 && 307,
            }}
          >
            {reminder?.map((day, index) => {
              return (
                <div
                  className="reminderDay"
                  style={{
                    color: determineWeekendDay(day) ? "#FE4C4A" : " #1264A3",
                    backgroundColor: selectedReminder === index && "#F2F2F2",
                  }}
                  key={index}
                >
                  <span
                    className="actionSpanRed"
                    onClick={() => {
                      setReminder(reminder.filter((d, idx) => idx !== index));
                      setChanges(true);
                    }}
                  >
                    <DeleteIcon fill="white" />
                  </span>
                  <div
                    className="dayName"
                    onClick={() => {
                      setSelectedReminder(index);
                      setChanges(true);
                    }}
                  >
                    {dayjsNY(day).format("dddd, MMMM D, YYYY hh:mm A")}
                  </div>
                </div>
              );
            })}
          </div>
          {selectedReminder !== null && selectedReminder !== -1 && (
            <div className="task-deadline">
              <span className="datePickerSpan">
                <DateTimePicker
                  // value={dateInputValidation("input", props.value)}
                  // onChange={props.setValue}
                  // format={props.displayFormat}
                  // suffixIcon={<CalendarIcon style={{ fill: "rgba(0, 0, 0, 0.25)" }} />}
                  // setFormChanged={props?.setFormChanged || (() => {})}
                  // index={props?.index}
                  // disabledDate={props.disableStartDate}
                  type="Date"
                  hideButtons={true}
                  hideResult={true}
                  showOnlySwitch={true}
                  startDate={startDate}
                  endDate={deadline}
                  disabledDate
                  allDisabledDays={reminder?.filter((d, idx) => {
                    return idx !== selectedReminder;
                  })}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </NormalSizedModal>
  );
};

export default ReminderModal;
