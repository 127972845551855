import { Tag, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReportFooter from "./ReportFooter";
import ReportMetaData from "./ReportMetaData";
import { useResponsive } from "../../../../../hooks";
import { DeleteIcon, NewTabIcon } from "../../../DynamicView/src";
import {
  MondayButton,
  RedWarningModal,
  StarCheckbox,
} from "../../../../commonComponents";
import "./ReportPreviewCard.scss";
import "src/components/SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportsGridLayout/components/LayoutCard/LayoutCard.scss";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import ToDoModal from "../../../ToDos/ToDoModal";
import { Tasks } from "./Tasks";

const ReportPreviewCard = ({
  report = {},
  onClick,
  isFavorite,
  onFavoriteClick,
  onDeleteReport,
  onPreviewReport,
  onDescriptionEdited,
  configuring = false,
  i,
  notes = [],
  setNotes,
}) => {
  const { reportName, thumbnail, useInAutomation, reportId, categoryName } =
    report;

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [hoverMode, setHoverMode] = useState(false);
  const [openToDo, setOpenToDo] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [reportNotesModal, setReportNotesModal] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const { mobile } = useResponsive();

  const linkTo = window.location.pathname.substring(1) + window.location.search;

  const findNotePerReportId = notes.filter(
    ({ recordId }) => recordId === report?.reportId
  );

  const onWrapperMouseEnter = () => {
    if (!configuring) return;
    setHoverMode(true);
  };

  const onWrapperMouseLeave = () => {
    if (!configuring) return;
    setHoverMode(false);
  };

  const handleFavoriteClick = ({ e, reportId }) => {
    e.stopPropagation();
    onFavoriteClick(reportId);
  };

  return (
    <>
      <div
        className={`reportPreviewCardWrapper ${
          isDarkMode && "reportPreviewCardWrapperDark"
        }  ${mobile && "reportPreviewCardWrapper-mobile"}`}
        onMouseEnter={onWrapperMouseEnter}
        onMouseLeave={onWrapperMouseLeave}
      >
        <div
          className="reportPreviewCardContainer"
          {...{
            id: i === 0 ? "firstReportPreviewCardContainer" : "",
          }}
        >
          <div className="previewHeader">
            {!!configuring && (
              <StarCheckbox
                {...{
                  checked: !!isFavorite,
                  onChange: (favorite) => onFavoriteClick(favorite, report),
                }}
              />
            )}
            <div className="reportTitle">
              <div className="reportName">{reportName}</div>
              {!!useInAutomation && <Tag color="#71CF48">Automation</Tag>}
              <Dropdown
                overlayClassName={`layout-card-dropdown${
                  isDarkMode ? " layout-dropdown-dark" : ""
                }`}
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "to-do",
                      label: "To Do",
                      onClick: () => {
                        setOpenToDo(true);
                      },
                    },
                    {
                      key: "tasks",
                      label: "Tasks",
                      onClick: () => {
                        setTaskModalVisible(true);
                      },
                    },
                    {
                      key: "notes",
                      label: "Notes",
                      onClick: () => {
                        setReportNotesModal(true);
                      },
                    },
                    {
                      key: "delete",
                      label: "Delete",
                      danger: true,
                      onClick: () => {
                        setDeleteModalVisible(true);
                      },
                    },
                  ],
                }}
                placement="bottomRight"
              >
                <EllipsisOutlined
                  height={18}
                  width={30}
                  color={!isDarkMode ? "#fff" : "#000"}
                  style={{
                    transform: "rotate(90deg)",
                    cursor: "pointer",
                  }}
                />
              </Dropdown>
            </div>
          </div>
          <div className="previewContent">
            <div
              className="reportThumbnail"
              onClick={(event) => onClick(event, report)}
            >
              <img src={thumbnail} alt="" />
            </div>
            <ReportMetaData
              report={report}
              configuring={configuring}
              onDescriptionEdited={onDescriptionEdited}
              onPreviewReport={onPreviewReport}
            />
          </div>

          {/* <ReportFooter
            report={report}
            configuring={configuring}
            handleFavoriteClick={handleFavoriteClick}
            notes={notes}
            onDeleteReport={onDeleteReport}
            setNotes={setNotes}
          /> */}
        </div>
      </div>

      {openToDo && (
        <ToDoModal
          {...{
            toDoVisible: openToDo,
            setToDoVisible: setOpenToDo,
            recordId: `?category=${categoryName}&reportId=${reportId}`,
            recordName: reportName,
            isDarkMode,
            hideCreateTaskModal: true,
          }}
        />
      )}

      {taskModalVisible && (
        <Tasks
          {...{
            visible: taskModalVisible,
            setVisible: () => {
              setTaskModalVisible(false);
            },
            rowData: report,
            taskTopic: "Reports",
            taskTopicId: reportId,
            taskRelatedTo: reportName,
            taskSubTopic: categoryName,
          }}
        />
      )}

      {reportNotesModal && (
        <Notes
          {...{
            noteModalName: `${report?.categoryName} - ${report?.reportName}`,
            rowId: report?.reportId,
            topicCategory: "Reports",
            notesVisibility: reportNotesModal,
            setNotesVisibility: () => setReportNotesModal(false),
            customLinkTo: linkTo,
            propNotes: findNotePerReportId,
            setPropNotes: setNotes,
          }}
        />
      )}

      {deleteModalVisible && (
        <RedWarningModal
          {...{
            visible: deleteModalVisible,
            setVisible: () => setDeleteModalVisible(""),
            onCancel: () => {
              setDeleteModalVisible(false);
            },
            titleText: "Confirm Deletion",
            TitleIcon: DeleteIcon,
            footerProps: {
              onConfirm: () => {
                onDeleteReport(reportId);
                setDeleteModalVisible(false);
              },
            },
          }}
        >
          <div className="delReportPreview-text">
            <p className="delReportPreview-question">
              Are you sure you want to delete <b>{reportName}</b> ?
            </p>
            <p>
              This action is irreversible and will permanently remove all
              associated data.
            </p>
          </div>
        </RedWarningModal>
      )}
    </>
  );
};

export default ReportPreviewCard;
