import { useMediaQuery } from "../../../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/utils/use-media-query/use-media-query";
import { ReloadIcon } from "../../../../../assets";

import "./AssistantConnectingView.scss";

function AssistantConnectingView({ status }) {
  const mobile = useMediaQuery("(max-width: 767px)");

  const isBotConnecting = status === "BotConnecting";

  return (
    <div className={`assistant-connecting-view`}>
      <ReloadIcon height={70} width={70} className="reload-rotate-icon" />
      <div className="connecting-message">
        <span>
          {isBotConnecting
            ? `We're connecting you to our virtual assistant. Please wait...`
            : `We've sent a request to connect with one of our employees.`}
        </span>
        {!mobile ? <br /> : <span>&nbsp;</span>}
        <span>
          {isBotConnecting
            ? `Once connected, our assistant will be live with you to chat.`
            : `Once they accept, they will be live with you to chat.`}
        </span>
      </div>
    </div>
  );
}

export default AssistantConnectingView;
