import { useContext } from "react";
import { useSelector } from "react-redux";

import { DriverType, StoreType } from "../../types";
import { MaintenanceViewContext } from "../../data";
import { formatNumber } from "src/components/SidebarPages/utils";
import SmallInfoCard from "../SmallInfoCard/SmallInfoCard";

type VehicleInformationProps = {
  fleetId: string;
};

function SmallVehicleInformation({ fleetId }: VehicleInformationProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const { data } = useContext(MaintenanceViewContext);

  const { fleet, drivers } = data;
  const vehicle = fleet.find(({ fleetId: id }) => id === fleetId);

  let assignedDriver: DriverType;
  if (vehicle?.assignedDriver) {
    assignedDriver = drivers.find(
      ({ driverId }) => driverId === vehicle.assignedDriver
    );
  }

  return (
    <SmallInfoCard
      {...{
        isDarkMode,
        title: "Vehicle Information",
        data: !vehicle
          ? []
          : [
              {
                title: "Vehicle #",
                value: vehicle.fleetName,
              },
              {
                title: "Status",
                value: vehicle.fleetStatus,
              },
              {
                title: "Odometer Reading",
                value: formatNumber(vehicle.odometerReading, { unit: "mile" }),
              },
              {
                title: "Assigned Driver",
                value: assignedDriver?.driverName || "No Assigned Driver",
              },
            ],
      }}
    />
  );
}

export default SmallVehicleInformation;
