import { message } from "antd";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { totalPriceOfSOVHandler } from "../components/SidebarPages/Projects/Accounting/ScheduleOfValues/ScheduleOfValueView/components/Header/Components/ControlPanel/controlPanelFunctions";

import types from "../types";
import { filterTables } from "../utils";
import { filterTablesPagination } from "../utils/paginationFetchData";
import { checkIfServiceIsHoist } from "../components/SidebarPages/Estimations/DataEntryGrid/models/Service";

// Get all schedule of values
export const getScheduleOfValues = (projectId) => async (dispatch) => {
  // // Dont make another Api call if we already have data
  // if (getState().scheduleOfValues.loaded) return
  try {
    dispatch({ type: types.LOADING_SCHEDULE_OF_VALUE });
    // const scheduleOfValues = await filterTables("scheduleOfValues", "projectId", projectId)
    const scheduleOfValues = await filterTables(
      "scheduleOfValues",
      "projectId",
      projectId
    );

    dispatch({
      type: types.GET_SCHEDULE_OF_VALUES,
      payload: scheduleOfValues,
    });
  } catch (err) {
    dispatch({
      type: types.ERROR_SCHEDULE_OF_VALUE,
      payload: err,
    });
  }
};

// Get schedule of value
export const getScheduleOfValue = (scheduleId) => async (dispatch) => {
  try {
    // Clear previous loaded schedule of value
    dispatch({ type: types.CLEAR_SCHEDULE_OF_VALUE });
    dispatch({ type: types.LOADING_SCHEDULE_OF_VALUE });

    // const scheduleOfValue =
    await API.get("scheduleOfValues", `/scheduleOfValues/${scheduleId}`)
      .then((res) => {
        dispatch({
          type: types.GET_SCHEDULE_OF_VALUE,
          payload: res,
        });
      })
      .catch((e) => {
        dispatch({
          type: types.ERROR_SCHEDULE_OF_VALUE,
          payload: e?.response?.data,
        });
      });

    // dispatch({
    //   type: types.GET_SCHEDULE_OF_VALUE,
    //   payload: scheduleOfValue,
    // });
  } catch (err) {
    dispatch({
      type: types.ERROR_SCHEDULE_OF_VALUE,
      payload: err,
    });
  }
};

// Create schedule of value
export const createScheduleOfValue =
  (scheduleOfValue, isNextStep, getApplicationId, saveAddedLogs) =>
  async (dispatch, getState) => {
    const authUser = getState().authenticatedUser.authenticatedUser;
    try {
      // Clear previous created schedule of value
      dispatch({ type: types.CLEAR_NEWLY_CREATED_SCHEDULE_OF_VALUE });
      dispatch({ type: types.LOADING_SCHEDULE_OF_VALUE });

      const newScheduleOfValue = await API.post(
        "scheduleOfValues",
        `/scheduleOfValues`,
        {
          body: scheduleOfValue,
        }
      ).then((res) => {
        dispatch({
          type: types.CREATE_SCHEDULE_OF_VALUE,
          payload: res,
        });

        if (isNextStep) getApplicationId(res.scheduleId);

        Swal.fire({
          title: "Successfully created",
          text: "Created new Schedule of Value",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

        saveAddedLogs({
          recordId: res?.scheduleId,
          recordName: `SOV ${res?.SOVNo}`,
          category: "Schedule of Values",
          topic: res?.projectName,
        });
      });
    } catch (err) {
      dispatch({
        type: types.ERROR_SCHEDULE_OF_VALUE,
        payload: err,
      });
      console.log("error on create", err);
      Swal.fire({
        title: "Something went wrong!",
        text: "Cannot create new Schedule of Value",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

//Update schedule of value
export const updateScheduleOfValue =
  (id, scheduleOfValue, cb = () => {}) =>
  async (dispatch) => {
    const { scheduleId, userId, lastModifiedBy, ...rest } = scheduleOfValue;
    const totalPrice = totalPriceOfSOVHandler({ fakeSOV: scheduleOfValue });
    const isIncomplete = rest?.services
      ?.filter((s) => !checkIfServiceIsHoist(s))
      .some((s) => s?.amounts?.length === 0);
    rest.totalPrice = totalPrice;
    console.log("totalSOVprice", totalPrice);
    // if (!isIncomplete) {
    console.log("rest", rest);
    try {
      message.info("Updating Schedule of Value...", 0);
      dispatch({ type: types.LOADING_SCHEDULE_OF_VALUE });

      await API.put("scheduleOfValues", `/scheduleOfValues/${id}`, {
        body: rest,
      });

      dispatch({
        type: types.UPDATE_SCHEDULE_OF_VALUE,
        payload: {
          scheduleId: id,
          scheduleOfValue,
        },
      });
      cb();
      message.destroy();
      message.success("Schedule of Value updated successfully");
    } catch (err) {
      message.destroy();
      console.log("error on update", err);
      message.error("Something went wrong");
      dispatch({
        type: types.ERROR_SCHEDULE_OF_VALUE,
        payload: err,
      });
    }
    // } else {
    //   Promise.reject("Cannot update SOV with incomplete data");
    //   message.info("Please select events for every service.");
    // }
  };

// Delete schedule of value
export const deleteScheduleOfValue =
  (scheduleToDelete, saveAddedLogs) => async (dispatch, getState) => {
    const authUser = getState().authenticatedUser.authenticatedUser;
    try {
      message.info("Deleting Schedule of Value...", 0);
      dispatch({ type: types.LOADING_SCHEDULE_OF_VALUE });

      await API.del(
        "scheduleOfValues",
        `/scheduleOfValues/${scheduleToDelete?.scheduleId}`
      );

      dispatch({
        type: types.DELETE_SCHEDULE_OF_VALUE,
        payload: scheduleToDelete?.scheduleId,
      });
      message.destroy();
      message.success("Schedule of Value deleted successfully");
    } catch (err) {
      dispatch({
        type: types.ERROR_SCHEDULE_OF_VALUE,
        payload: err,
      });
    }
    saveAddedLogs({
      recordId: scheduleToDelete?.scheduleId,
      recordName: `SOV ${scheduleToDelete?.SOVNo}`,
      actionType: "Delete",
      category: "Schedule of Values",
      topic: scheduleToDelete?.projectName,
    });
  };

// Reset schedule of value
export const resetScheduleOfValue = () => ({
  type: types.RESET_SCHEDULE_OF_VALUE,
});
