import { message } from "antd";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { compareIncluding } from "../../../../../../utils";
import { DOWNLOAD } from "../../../../../../../../integrations/createPDF";
import getDocDefinition from "../../../../../../../../integrations/AgGridToPdf/docDefinition";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePDFsForMultipleTables = (
  tableDataArray,
  action,
  title
) => {
  const tableDataArrayFiltered = tableDataArray.filter(
    (data) => data.rowData.length > 0
  );
  const pdfPromises = [];
  tableDataArrayFiltered.forEach((tableData) => {
    const pdfPromise = getDocDefinition({
      gridApi: tableData.gridApi,
      rowData: tableData.rowData,
      history: tableData.history?.location?.pathname?.split("/")?.[1],
      historyTab: tableData.window?.location?.pathname,
      gridColumnApi: tableData.gridColumnApi,
      base64: tableData.base64,
      masterDetail: !!tableData.detailsCell ? true : false,
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: title,
        PDF_WITH_CELL_FORMATTING: true,
        PDF_LOGO: tableData.base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
    pdfPromises.push({
      ...pdfPromise,
      completeServiceName: tableData.completeServiceName,
    });
  });
  Promise.all(pdfPromises)
    .then((tables) => {
      const docDefinition = { ...tables[0], content: [] };
      const printTitle = action.includes(DOWNLOAD) && `${title}.pdf`;

      tables.forEach((table) =>
        docDefinition.content.push(
          {
            text: table.completeServiceName || "",
            style: "header",
            margin: [0, 20],
          },
          { ...table.content[0] }
        )
      );
      pdfMake.createPdf(docDefinition)[action](printTitle);
    })
    .catch((error) => {
      message.error("Error generating PDF");
      console.error("Error generating PDF:", error);
    });
};
