/**
 * @typedef {[number, number]} LatLng
 */

/**
 *
 * @param {LatLng} point - [lat, lng]
 * @param {LatLng[]} points - [ [lat, lng], [lat,lng], ... ]
 * @return {boolean}
 */
export const pointsInsideAPolygon = (point = [], points = []) => {
  let x = point[0];
  let y = point[1];
  let inside = false;
  for (let i = 0, j = points?.length - 1; i < points?.length; j = i++) {
    let xi = points[i][0];
    let yi = points[i][1];
    let xj = points[j][0];
    let yj = points[j][1];
    let intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};
