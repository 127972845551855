import moment from "moment";
import { message, notification } from "antd";
import insertEvent from "./insertEvent.json";
import {
  loadClient,
  authenticate,
  getUserInfo,
  isLoggedIn,
} from "./authenticate";

import { timeFilter } from "../utils/timeFilters";
// var gapi = window.gapi;
import { gapi } from "gapi-script";

/**
 * A function that makes a call to GAPI and returns the calendars in the user's calendar list
 * @returns {Object} which contains the result Object with Calendars information
 */

export function executeCalendarList() {
  return gapi.client.calendar?.calendarList
    .list({
      showDeleted: false,
      showHidden: false,
      minAccessRole: "owner",
      singleEvents: true,
    })
    .then(
      function (response) {
        return response;
      },
      function (err) {
        console.error("Execute error", err);
      }
    );
}

/**
 * A function that inserts a new Event to Google Calendar API.
 * @param {Object} insertEvent a new Event Instance
 * @param {Boolean} sendNotif whether to send Notifications via email or Not
 * @param {String} calendarID (*required) ID of Calendar to create the event
 * @returns {Object} response if successful, else @returns {Boolean} False
 */

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function executeEventAdder(
  insertEvent,
  sendNotif,
  calendarID,
  programNotif = true,
  retries = 3
) {
  return gapi.client.calendar.events
    .insert({
      calendarId: calendarID ? calendarID : "primary",
      resource: insertEvent,
      sendNotifications: sendNotif,
    })
    .then(
      function (response) {
        if (!programNotif) return;
        const eventLink = response.result.htmlLink;
        const selectaccount =
          eventLink.slice(0, 31) +
          `/u/${response.result.creator.email}` +
          eventLink.slice(31);

        notification.open({
          message: (
            <strong style={{ color: "#0f5c97" }}>Event was created! </strong>
          ),
          onClick: () => window.open(selectaccount),
          description: [
            <strong>{insertEvent.summary}</strong>,
            ` has been created as Google Event. Start: ${moment(
              insertEvent.start.dateTime
            ).format("MM/DD/YYYY")} `,
            <p style={{ color: "#0f5c97" }}>Click to OPEN</p>,
          ],
          duration: 15,
          placement: "topRight",
          style: { borderLeft: "15px solid #0f5c97 ", borderRadius: "5px" },
        });
        // window.open(selectaccount);
      },
      async function (err) {
        // Retry mechanism
        if (retries > 0) {
          console.log(`Retrying... Attempts left: ${retries}`);
          await delay(200); // Introducing a 0.2-seconds delay before retrying
          return executeEventAdder(
            insertEvent,
            sendNotif,
            calendarID,
            programNotif,
            retries - 1
          );
        } else {
          message.error("There was an error with the creation of the event!");
          console.error("Max retry attempts reached. Event creation failed.");
        }
      }
    );
}

/**
 * Function used to tie authenticate & load gapi promises for an optimized validation
 * @returns {Boolean} true only if authentication & load client GAPI has been successful.
 */
export const authenticateAndLoad = () => {
  return authenticate()
    .then(loadClient)
    .then(() => {
      return true;
    });
};

/**
 * Returns events on the specified calendar.
 * @param {String} calendarID The Calendar to retrieve events from
 * @param {String} timeInstance for further TimeFilter Processing
 * @param {Boolean} showDel whether to show deleted/cancelled events or not
 * @returns {Object} an Object of event results for the specified Calendar ID
 * @returns {Boolean} FALSE if the events couldn't be retrieved.
 */

export const executeGetEvents = async (
  userDetails,
  calendarID,
  timeInstance,
  showDel
) => {
  if (userDetails) {
    const timeObj = timeFilter(timeInstance);
    return gapi.client.calendar.events
      .list({
        calendarId: calendarID,
        timeMin: timeObj.timeMin,
        timeMax: timeObj.timeMax,
        timeZone: "America/New_York",
        showDeleted: showDel,
      })
      .then(
        function (response) {
          return response;
        },
        function (err) {
          console.error("Couldn't retrieve events at this time.", err);
          return false;
        }
      );
  } else {
    console.log("You are not connected to Google Account.");
  }
};

/**
 * Creates a new Calendar
 * @param {String} calendName the desired name for the New Calendar
 * @returns {Boolean} true if the Calendar was created or False if error happenstance.
 *
 */
export const createNewCalendar = (calendName) => {
  return gapi.client.calendar.calendars
    .insert({
      resource: {
        summary: calendName,
      },
    })
    .then(
      function (response) {
        // console.log("Response", response);
        return true;
      },
      function (err) {
        console.error("Execute error", err);
        console.log("123");
        return false;
      }
    );
};

/**
 * Processes AddTask Object and creates a new Event
 * @param {Object} taskObj created Task Object from AddTask
 * @param {Object} userConfiguration  User Configuration
 * @param {Boolean} isCalendarValidated validates Local Storage
 */

export const createEventFromTask = async (taskObj, calendar) => {
  const {
    taskId,
    taskTitle,
    taskTopic,
    taskStatus,
    taskRelatedTo,
    taskLabels = [],
    taskThread = [],
    taskAssignedTo = [],
    taskStartDate,
    taskDeadline,
  } = taskObj;

  const isUserLoggedIn = isLoggedIn();

  if (isUserLoggedIn) {
    const preferences = checkUserEventPreferences();

    const calend = !!calendar ? calendar : "primary";
    const labels = [];
    taskLabels.map((label) => labels.push(label));
    const templ = `<p><strong>TOPIC:</strong> ${taskTopic} <strong>${taskTopic}:</strong> ${taskRelatedTo}<strong>LABELS:</strong> ${labels.join(
      ", "
    )}</p>`;

    const eventOB = {
      ...insertEvent,
      extendedProperties: {
        private: { taskId },
      },
      colorId: 1,
      summary: taskTitle + " " + `(${taskStatus || null})`,
      description: taskThread.length > 0 ? taskThread[0]?.comment + templ : "",
      attendees: taskAssignedTo.map(({ userName }) => userName),
      visibility: preferences ? preferences.visibility : "default",
      reminders: {
        ...insertEvent.reminders,
        overrides: [
          {
            method: "email",
            minutes: preferences ? preferences.notificationMinute : 10,
          },
          { method: "popup", minutes: 10 },
        ],
      },
      start: {
        dateTime: (!!taskStartDate ? moment(taskStartDate) : moment()).format(),
        timeZone: preferences ? preferences.timezone : "America/New_York",
      },
      end: {
        dateTime: (!!taskDeadline ? moment(taskDeadline) : moment()).format(),
        timeZone: preferences ? preferences.timezone : "America/New_York",
      },
    };

    return await executeEventAdder(eventOB, true, calend, true);
  } else {
    message.warning("You are not connected to your Google Account.");
    // throw new Error("You are not connected to your Google Account!");
  }
};

/**
 * Processes AddTask Object and creates multiple Events for each Schedule Day
 * @param {Object} scheduleObj the Object Created on New Schedule
 * @param {Boolean} isCalendarValidated  validates Local Storage
 */
export const createEventsFromSchedule = (scheduleObj, isCalendarValidated) => {
  if (isLoggedIn()) {
    const preferences = checkUserEventPreferences();
    const calend = "primary";
    // preferences.user === getUserInfo() && isCalendarValidated === true
    //   ? preferences.scheduleCalendar.id
    //   : "primary";

    const eventsToCreate = [];
    try {
      scheduleObj.scheduleDays.map((el) =>
        eventsToCreate.push({
          ...insertEvent,
          summary: scheduleObj.scheduleName + ` (${el.status})`,
          location: scheduleObj.scheduleAddress,
          start: {
            dateTime: el.startDate,
            timeZone: preferences ? preferences.timezone : "America/New_York",
          },
          end: {
            dateTime: el.endDate,
            timeZone: preferences ? preferences.timezone : "America/New_York",
          },
          description: el.notes.length !== 0 ? el.notes[0].text : "",
          // attendees: { email: "example@gmail.com" },
          attendees: [],
          visibility: preferences ? preferences.visibility : "default",
          reminders: {
            ...insertEvent.reminders,
            overrides: [
              {
                method: "email",
                minutes: preferences ? preferences.notificationMinute : 1440,
              },
              { method: "popup", minutes: 10 },
            ],
          },
        })
      );
      // Necessary for creating multiple events
      const delayEventAdding = (fn, delay) => {
        return (event, preTime) => {
          setTimeout(() => {
            fn(event, true, calend);
          }, preTime * delay);
        };
      };

      eventsToCreate.forEach(delayEventAdding(executeEventAdder, 1000));
    } catch (err) {
      console.log(
        "There was an error with creating event from Schedule creation",
        err
      );
    }
  } else {
    // message.error(
    //   "An error occured while trying to create the Event. You are not connected to your Google Account."
    // );
  }
};

/**
 * A function that is used for deleting an Event from the calendar.
 * It will still display on List/Get event calls with status "Cancelled"
 * @param {String} eventID the ID of event for deletion
 * @param {String} calendarID the CalendarID in which the Event is
 * @returns {Boolean} true/false accordingly to Event deletion happenstance
 */
export const deleteEvent = (eventID, calendarID) => {
  return gapi.client.calendar.events
    .delete({
      calendarId: calendarID,
      eventId: eventID,
      sendUpdates: "all",
    })
    .then(
      (response) => {
        message.success("Event was successfully deleted!");
        return true;
      },
      (err) => {
        message.error("An error occurred while trying to delete the Event.");
        return false;
      }
    );
};

/**
 * Checks local storage for saved EventPreferences
 * @returns {Object} of saved Preferences
 */
export const checkUserEventPreferences = () => {
  if (localStorage.getItem("eventPreferences") !== null) {
    const saved = JSON.parse(localStorage.getItem("eventPreferences"));
    return saved;
  }
  return null;
};

/**
 * @param {Object} task updated task object
 * @param {Object} userConfiguration userConfiguration to get allUsers
 * @param {String} eventID the ID of event for update
 * @param {String} calendarID the CalendarID in which the Event is
 * @returns {Object} response if successful, else @returns {Boolean} False
 */
export const updateEventFromTask = async (
  task,
  userConfiguration,
  eventId,
  calendarId
) => {
  try {
    let emails = task?.taskAssignedTo?.map(({ userName = "" }) => userName);

    const preferences = checkUserEventPreferences();
    const start =
      task.taskStartDate &&
      moment(task.taskStartDate).format() !== "Invalid date"
        ? moment(task.taskStartDate).format()
        : moment().format();
    const end =
      task.taskDeadline && moment(task.taskDeadline).format() !== "Invalid date"
        ? moment(task.taskDeadline).format()
        : moment().format();

    let status = task.taskStatus;
    const colorId =
      status === "Completed"
        ? "10" //Basil	#0b8043
        : status === "Cancelled"
        ? "6" //Tangerine	#f5511d
        : status === "To do"
        ? "9" //	Blueberry	#3f51b5
        : status === "Due"
        ? "1" //	Lavender	#7986cb
        : status === "In progress"
        ? "8" //	Graphite	#616161
        : "5"; //	Banana	#f6c026 for Pending status

    return await gapi.client.calendar.events.patch({
      calendarId: calendarId,
      eventId: eventId,
      resource: {
        start: {
          dateTime: start,
          timeZone: preferences ? preferences.timezone : "America/New_York",
        },
        end: {
          dateTime: end,
          timeZone: preferences ? preferences.timezone : "America/New_York",
        },
        attendees: emails,
        summary: `${task.taskTitle} (${task.taskStatus})`,
        colorId: colorId,
      },
    });
  } catch (err) {
    console.log(`An error occurred: ${err}`);
  }
};
