function checkTimeInput({ fields, formItemName }) {
  let dynamicField = fields?.find(
    ({ formItemName: fN }) => fN === formItemName
  );
  if (dynamicField) {
    return dynamicField?.type?.toLowerCase() === "timeinput";
  }
  return false;
}

export default checkTimeInput;
