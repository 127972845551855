import { useEffect, useMemo, useState } from "react";
import { fetchAllData } from "../../../../../../utils";
import { Pagination } from "antd";
import { ArrowIcon } from "../../../../../SidebarPages/Scheduling/ScheduleChangesModal/assets";
import { SmallLoadableComp } from "../../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import { useSelector } from "react-redux";
import { DrawerHiddenCardsIcon } from "../../../../../../assets";
import ActionLogRow from "./components/ActionLogRow";

/**
 * Renders a table of action logs.
 *
 * @component
 * @param {Object[]} logsRowData - The array of action log data.
 * @param {string|null} searchValue - The search value for filtering logs.
 * @param {Function} setLogsRowData - The function to update the logs row data.
 * @returns {JSX.Element} The ActionLogTable component.
 */
const ActionLogTable = ({
  logsRowData = [],
  searchValue = null,
  setLogsRowData = () => {},
}) => {
  const [page, setPage] = useState(0);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const reducedUsers = useMemo(
    () =>
      userConfiguration?.allUsers?.Items?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.cognitoUserId]: curr?.nameOfUser,
        }),
        {}
      ),
    [userConfiguration?.allUsers?.Items]
  );

  /**
   * Handles pagination for the ActionLogTable component.
   *
   * @param {number} tmpPage - The page number to navigate to.
   */
  function handlePagination(tmpPage) {
    if (tmpPage < 0) return;

    let maxPages = Math.ceil(logsRowData?.length / 8);
    if (tmpPage >= maxPages) return;

    setPage(tmpPage);
  }

  useEffect(() => {
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "category",
                value: "Approvals Action",
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      ?.then((res) => {
        setLogsRowData(res);
      })
      .catch((err) => {
        console.error("Error fetching action logs", err);
      });
  }, []);

  return (
    <SmallLoadableComp loading={!logsRowData}>
      {logsRowData.length === 0 ? (
        <div className="drawerHiddenZeroCards" data-testid="no-action-logs">
          <DrawerHiddenCardsIcon />
          <span>
            Hello <strong>{userConfiguration?.nameOfUser}</strong>, you have{" "}
            <strong>0</strong> logs.
          </span>
        </div>
      ) : (
        <div className="actionLogsTable" data-testid="action-log-table">
          <div className="action-log-rows" key="action-log-rows">
            {logsRowData
              ?.filter((e) =>
                !!searchValue?.length
                  ? e.member
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) ||
                    e.recordName
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  : e
              )
              ?.slice(page * 9, page * 9 + 9)
              ?.map((log, index) => {
                return (
                  <ActionLogRow {...{ log, index, reducedUsers }} key={index} />
                );
              })}
          </div>
          <Pagination
            {...{
              total: logsRowData?.length,
              pageSize: 9,
              current: page + 1,
              onChange: (page) => handlePagination(page - 1),
              itemRender: (_, type, originalElement) => {
                const maxPages = Math.ceil(logsRowData?.length / 9);
                if (type === "prev") {
                  return (
                    <span
                      className={`${"page-text"} ${
                        page > 0 && "page-text-active"
                      }`}
                    >
                      <ArrowIcon style={{ rotate: "90deg" }} /> Previous Page
                    </span>
                  );
                }
                if (type === "next") {
                  return (
                    <span
                      className={`${"page-text"} ${
                        page + 1 < maxPages && "page-text-active"
                      }`}
                    >
                      Next Page <ArrowIcon style={{ rotate: "-90deg" }} />
                    </span>
                  );
                }
                return originalElement;
              },
              className: "pageButtons",
            }}
          />
        </div>
      )}
    </SmallLoadableComp>
  );
};

export default ActionLogTable;
