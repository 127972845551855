import uniq from "lodash/uniq";
import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const fleetInspections = (
  IdHandler,
  updatedDynamicStates,
  inspectionsData,
  programFields
) => {
  const inspectionStatuses = programFields?.find(
    (el) => el?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Inspections;

  return {
    inspectedBy: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "inspectedBy"
      );

      return {
        title: "Inspected By",
        BodyComponent: "Pie",
        type: "Pie",
        id: IdHandler,
        BodyComponentParams: {
          table: "Fleet Inspections",
          field: "inspectedBy",
          labels: Object.keys(data),
          datasets: [
            {
              label: "Fleet Inspections",
              data: Object.values(data),
              backgroundColor: Object.keys(data)?.map((a) => getRandomColor()),
              borderWidth: 0,
            },
          ],
        },
        id: IdHandler,
        width: "390px",
      };
    }, //inspectedBy
    driverName: () => ({
      title: "Inspections Driver Name",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "driverName",
        labels: Object.keys(data),
        datasets: [
          {
            label: "Fleet Inspections",
            data: Object.values(data),
            backgroundColor: Object.values(data)?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //driverName
    createdAt: () => ({
      title: "Inspections Creation Date",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "createdAt",
        labels: months,
        datasets: [
          {
            label: "My First Dataset",
            data: (() => {
              let data = [];
              for (let i = 0; i < 12; i++) {
                data = [
                  ...data,
                  (updatedDynamicStates?.[IdHandler] || [])
                    ?.map((a) => a?.createdAt)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length,
                ];
              }
              return data;
            })(),
            backgroundColor: months?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //createdAt

    fleetName: () => ({
      title: "Inspections Fleet Name",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      width: "390px",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "fleetName",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "fleetName"
          )
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "fleetName"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "fleetName"
              )
            )?.map(() => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
    }), //fleetName

    inspectionStatus: () => ({
      title: "Inspection Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      width: "390px",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "inspectionStatus",
        labels: inspectionStatuses?.map((el) => el?.statusName),
        datasets: [
          {
            label: "My First Dataset",
            data: inspectionStatuses?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (el) => el.inspectionStatus === status?.statusName
                )?.length
            ),
            backgroundColor: inspectionStatuses?.map(
              (el) => el?.statusColor || getRandomColor()
            ),
            borderWidth: 0,
          },
        ],
      },
    }), //inspectionStatus

    inspectionType: () => ({
      title: "Inspection Type",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      width: "390px",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "inspectionType",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "inspectionType"
          )
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "inspectionType"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "inspectionType"
              )
            )?.map(() => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
    }), //inspectionType

    mechanic: () => ({
      title: "Inspection Mechanic",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      width: "390px",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "mechanic",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler]?.map(el) || [],
            "mechanic"
          )
        ),
        datasets: [
          {
            label: "My First Dataset",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "mechanic"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "mechanic"
              )
            )?.map(() => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
    }), //mechanic
  }; //Inspections
};
