import { message } from "antd";

/**
 * Validates an email reference value.
 *
 * @param {any} _ - The first parameter is ignored.
 * @param {string} [value=""] - The email reference value to be validated.
 * @returns {Promise} A promise that resolves if the value is valid, and rejects with an error if it is not.
 */
const validateEmailRef = (_, value = "") => {
  const button = document.getElementById("emailReference");

  const urlRegex = new RegExp(
    "^(https?:\\/\\/)?" + // Protocol (http/https) is optional.
      "(?:[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})" + // Domain names like "www.example.com" or "mail.google.com."
      "(?::\\d+)?" + //It accepts an optional port number, such as ":8080."
      "(?:\\/[-a-zA-Z0-9@:%._\\+~#=]+)*" + // Validates path portion of the URL, including directories and filenames.
      "(?:\\?[a-zA-Z0-9_=-]+(?:&[a-zA-Z0-9_=-]+)*)?" + // It allows for query strings, such as "?param1=value1&param2=value2."
      "(?:#[a-zA-Z0-9_=-]*)?$", // It accepts fragment identifiers, such as "#section1."
    "i"
  );

  if (!!value && urlRegex.test(value.trim())) {
    button.classList.add("hasValidLink");
    return Promise.resolve();
  } else if (!!value && !urlRegex.test(value.trim())) {
    button.classList.remove("hasValidLink");

    message.error({
      key: "emailRef",
      content: "Email Reference should be a valid link",
    });
    return Promise.reject(new Error("Should be a valid link"));
  } else {
    return Promise.resolve();
  }
};

export default validateEmailRef;
