import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";

export default function convertUnixTime(timestamp) {
  return new Date(timestamp).toLocaleDateString("en-US");
}

export function transformDates(body) {
  if (body.createdAt) {
    body.createdAt = dayjsNY(body.createdAt).format("MM/DD/YYYY");
  }
  if (body.applicationDateXlsx) {
    body.applicationDateXlsx = dayjsNY(body.applicationDateXlsx).format(
      "MM/DD/YYYY"
    );
  }
  if (body.dueDate) {
    body.dueDate = dayjsNY(body.dueDate).format("MM/DD/YYYY");
  }
  if (body.periodTo) {
    if (body.periodTo.start) {
      body.periodTo.start = dayjsNY(body.periodTo.start).format("MM/DD/YYYY");
    }
    if (body.periodTo.end) {
      body.periodTo.end = dayjsNY(body.periodTo.end).format("MM/DD/YYYY");
    }
  }
  if (body.invoiceDate) {
    body.invoiceDate = dayjsNY(body.invoiceDate).format("MM/DD/YYYY");
  }
  if (body.inspectionDate) {
    body.inspectionDate = dayjsNY(body.inspectionDate).format("MM/DD/YYYY");
  }
  if (body.todoDueDate) {
    body.todoDueDate = dayjsNY(body.todoDueDate).format("MM/DD/YYYY");
  }
  if (body.proposalSentDate) {
    body.proposalSentDate = dayjsNY(body.proposalSentDate).format("MM/DD/YYYY");
  }
  if (body.proposalStartDate) {
    body.proposalStartDate = dayjsNY(body.proposalStartDate).format(
      "MM/DD/YYYY"
    );
  }
  if (body.actualEndDate) {
    body.actualEndDate = dayjsNY(body.actualEndDate).format("MM/DD/YYYY");
  }
  if (body.actualStartDate) {
    body.actualStartDate = dayjsNY(body.actualStartDate).format("MM/DD/YYYY");
  }
  if (body.anticipStartDate) {
    body.anticipStartDate = dayjsNY(body.anticipStartDate).format("MM/DD/YYYY");
  }
  if (body.anticipEndDate) {
    body.anticipEndDate = dayjsNY(body.anticipEndDate).format("MM/DD/YYYY");
  }
  if (body.requestReceivedDate) {
    body.requestReceivedDate = dayjsNY(body.requestReceivedDate).format(
      "MM/DD/YYYY"
    );
  }
  if (body.bidDueDate) {
    body.bidDueDate = dayjsNY(body.bidDueDate).format("MM/DD/YYYY");
  }
  if (body.sentToClientDate) {
    body.sentToClientDate = dayjsNY(body.sentToClientDate).format("MM/DD/YYYY");
  }
  if (body.maintenanceDate) {
    body.maintenanceDate = dayjsNY(body.maintenanceDate).format("MM/DD/YYYY");
  }

  return body;
}
