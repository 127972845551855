import dayjs from "dayjs"

export const getMonthsTimestamps = (monthPeriod = "") => {
  let timestamps = []
  for (let i = 0; i < 12; i++) {
    timestamps = [
      ...timestamps,
      dayjs().month(i)[monthPeriod !== "end" ? "startOf" : "endOf"]("month").valueOf(),
    ]
  }
  return timestamps
}
