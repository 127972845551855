import "./DarkModeToggle.scss";

const DarkModeToggle = ({ onClickFunction, darkMode }) => {
  return (
    <>
      <div
        data-testid="darkmode-testid"
        className={
          darkMode
            ? "darkModeToggleDivContainer"
            : "lightModeToggleDivContainer"
        }
        onClick={onClickFunction}
      >
        <div className="firstSquare" />
        <div className="secondSquare" />
        <div className="innerCircle" />
        <div className="outerCircle" />
      </div>
    </>
  );
};

export default DarkModeToggle;
