import { API } from "aws-amplify";
import { getChangedData } from "../../../../../components/utilities";
import { forceToNumber } from "./checkers";
import { filterTables } from "../../../../../../../../utils";

const processArrayAsync = async (array, asyncFunction) => {
  const results = [];
  for (const item of array) {
    const result = await asyncFunction(item);
    results.push(result);
  }
  return results;
};

const updateInvoiceAmountDue = async (invoice) => {
  const { invoiceId, invoiceAmountDue = 0, invoicePaidAmount = 0 } = invoice;
  const newAmount =
    forceToNumber(invoiceAmountDue) - forceToNumber(invoicePaidAmount);

  return await API.put("invoices", `/invoices/${invoiceId}`, {
    body: {
      amountDue: newAmount,
      invoiceStatus: "Paid",
    },
  });
};

export const updateInvoicesAmountDue = async (invoices) => {
  const results = await processArrayAsync(invoices, updateInvoiceAmountDue);
  return results;
};

//used by class component
export const updateEditLogs = async (
  result,
  paymentData,
  newPayment,
  userConfiguration
) => {
  const newEditLog = {
    recordId: paymentData ? paymentData[0].paymentId : result.paymentId,
    recordName: `Payment ${
      paymentData ? paymentData[0].paymentNumber : result.paymentNumber
    }`,
    actionType: paymentData ? "Edit" : "Create",
    category: "Payments",
    topic: paymentData ? paymentData[0].accountName : result.accountName,
    label: "",
    currentData: {},
    member: userConfiguration?.nameOfUser,
    nameOfUser: userConfiguration.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    previousData: {},
    updatedAt: Date.now(),
    updatedKeys: [],
  };

  if (paymentData) {
    for (let key in newPayment) {
      let changes;
      if (!!newPayment[key] && paymentData[0][key] && key !== "editLogs") {
        changes = getChangedData(newPayment[key], paymentData[0][key]);
      } else {
        continue;
      }
      if (changes !== false) {
        newEditLog.currentData[key] = changes.curr;
        newEditLog.previousData[key] = changes.prev;
        newEditLog.updatedKeys.push(key);
      }
    }
  }
  await API.post("editLogs", "/editLogs", {
    body: { ...newEditLog },
  });
};

export const updateExistingPayments = async (fakePayment, type) => {
  const paymentData = await filterTables(
    "payments",
    "accountId",
    fakePayment.accountId
  );

  for (const invoice of fakePayment.invoices) {
    await updatePaymentsForInvoice(paymentData, fakePayment, invoice, type);
  }
};

const updatePaymentsForInvoice = async (
  paymentData,
  fakePayment,
  invoice,
  type
) => {
  for (const payment of paymentData) {
    if (
      payment.paymentId !== fakePayment?.paymentId &&
      payment.invoices.some((inv) => inv.invoiceId === invoice.invoiceId)
    ) {
      const newInvoiceItems = payment.invoices.map((invoiceItem) => {
        if (invoiceItem.invoiceId === invoice.invoiceId) {
          const newInvoiceDueAmount =
            type === "delete"
              ? invoice.invoiceAmountDue + invoiceItem.invoicePaidAmount
              : invoice.invoiceAmountDue -
                invoice.invoicePaidAmount +
                invoiceItem.invoicePaidAmount;

          invoiceItem.invoiceAmountDue = newInvoiceDueAmount;
        }
        return invoiceItem;
      });

      try {
        await API.put("payments", `/payments/${payment.paymentId}`, {
          body: {
            invoices: newInvoiceItems,
          },
        });
      } catch (error) {
        // Handle the error appropriately (log or throw)
        console.error(`Error updating payment ${payment.paymentId}:`, error);
      }
    }
  }
};
