import _ from "lodash";
import { Task } from "frappe-gantt-react";
import { utils as xl, writeFile } from "xlsx";

import { excelHeaders } from "../data";
import { formatNumber } from "../../utils";
import {
  DefectType,
  InventoryType,
  WorkOrderType,
  FleetInspectionType,
} from "../types";
import { parseInTz } from "../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type ExcelData = {
  workOrders: WorkOrderType[];
  inspections: FleetInspectionType[];
  defects: DefectType[];
  inventoryItems: InventoryType[];
  tasks: Task[];
  dateRange: [number, number];
};

function generateMaintenanceExcel(data: ExcelData) {
  const { workOrders, inventoryItems, defects, inspections, tasks, dateRange } =
    data;

  const woRecords: Record<string, WorkOrderType> = _.keyBy(
    workOrders,
    "workOrderId"
  );
  const inspRecords: Record<string, FleetInspectionType> = _.keyBy(
    inspections,
    "inspectionId"
  );
  const defectsRecords: Record<string, DefectType> = _.keyBy(
    defects,
    "defectId"
  );
  const inventoryRecords: Record<string, InventoryType> = _.keyBy(
    inventoryItems,
    "inventoryId"
  );

  const inspRows = [],
    woRows = [],
    defRows = [];

  const rg = /<\/{0,1}[\w\s='"-.]*>/g;
  const spaceRg = /\s{2,}/g;

  for (const task of tasks) {
    const { id, custom_class } = task;
    if (!custom_class || custom_class === "NoRecords") {
      continue;
    }

    if (custom_class.includes("inspection")) {
      const inspection = inspRecords?.[id];
      if (!inspection) {
        continue;
      }

      inspRows.push([
        id,
        inspection.fleetName,
        inspection.inspectionType,
        parseInTz(inspection.inspectionDate).format("MM/DD/YYYY"),
        inspection.mechanicInfo.mechanic || "",
        inspection.inspectionStatus,
        (inspection?.inspectionNotes || "")
          .replaceAll(rg, " ")
          .replaceAll(spaceRg, " ")
          .trim(),
      ]);
    } else if (custom_class.includes("defect")) {
      const defect = defectsRecords?.[id];
      if (!defect) {
        continue;
      }

      defRows.push([
        id,
        defect.defectName,
        defect.fleetName,
        defect.driverName,
        defect.defectStatus,
        parseInTz(defect.createdAt).format("MM/DD/YYYY"),
        defect.defectSeverity,
        defect.defectPriority,
        defect?.workOrderId || "",
        defect.inspectionId,
      ]);
    } else {
      const workOrder = woRecords?.[id];
      if (!workOrder) {
        continue;
      }

      let totHours = 0,
        totLabor = 0,
        totParts = 0;
      for (const info of workOrder.laborInformation) {
        totHours += Number(info.hoursWorked || 0);
        totLabor += Number(info.total);
      }

      for (const item of workOrder.inventoryItems) {
        totParts +=
          +item.quantity * +inventoryRecords?.[item.itemId]?.["costPerUnit"];
      }

      woRows.push([
        workOrder.workOrderId,
        workOrder.fleetName,
        parseInTz(workOrder?.issueDate || workOrder?.createdAt).format(
          "MM/DD/YYYY"
        ),
        parseInTz(workOrder.dueDate).format("MM/DD/YYYY"),
        workOrder.workOrderStatus,
        formatNumber(totHours, { unit: "hour" }),
        formatNumber(totLabor, { currency: "USD" }),
        formatNumber(totParts, { currency: "USD" }),
        formatNumber(totLabor + totParts, { currency: "USD" }),
        workOrder.mechanicInfo?.mechanic || "",
        (workOrder.comments || "")
          ?.replaceAll(rg, " ")
          .replaceAll(spaceRg, " ")
          .trim(),
      ]);
    }
  }

  const book = xl.book_new();

  const inspSheet = xl.json_to_sheet(inspRows);
  xl.sheet_add_aoa(inspSheet, [excelHeaders["Inspections"]], {
    origin: "A1",
  });

  const woSheet = xl.json_to_sheet(woRows);
  xl.sheet_add_aoa(woSheet, [excelHeaders["WorkOrders"]], {
    origin: "A1",
  });

  const defSheet = xl.json_to_sheet(defRows);
  xl.sheet_add_aoa(defSheet, [excelHeaders["Defects"]], {
    origin: "A1",
  });

  xl.book_append_sheet(book, inspSheet, "Inspections");
  xl.book_append_sheet(book, woSheet, "Work Orders");
  xl.book_append_sheet(book, defSheet, "Defects");

  writeFile(
    book,
    `Maintenance Report: ${parseInTz(dateRange[0]).format(
      "MM/DD/YYYY"
    )} - ${parseInTz(dateRange[1]).format("MM/DD/YYYY")}.xlsx`,
    { compression: true }
  );
}

export default generateMaintenanceExcel;
