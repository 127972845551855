export const updateFakeSOV = (fakeScheduleOfValue, service, elevation, amounts) => ({
  ...fakeScheduleOfValue,
  services: fakeScheduleOfValue?.services.map(sovService => sovService.serviceId.toString() === service.serviceId.toString() && sovService.estimationId === service.estimationId ?
    {
      ...sovService,
      serviceOptions: [sovService?.serviceOptions?.[0]?.map(serviceOption => serviceOption?.elevationId === elevation?.elevationId
        ? {
            ...serviceOption,
            amounts,
            typeOfSOV: 'Custom'
          }
        : serviceOption
      )]
    } :
    sovService
  )
})