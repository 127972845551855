import { useStateContext } from "../../../../../Context/Context"
import "./ChargedIndicator.scss"
import {
  addonsCheckerHandler,
  eventCheckerHandler,
  hoistCheckerHandler,
  pliCheckerHandler,
} from "./chargedIndicatorFunctions"
import {
  ChargedSomeDaysIcon,
  ChargedAllDaysIcon,
  NotCharged,
} from "../../../../../../../../../DynamicView/src"

import { Tooltip } from "antd"

const ChargedIndicator = ({ service, eventId, thisFunction, id, pliId }) => {
  const { fakeApplication } = useStateContext()

  const selectedEvent =
    thisFunction === "hoist"
      ? fakeApplication?.services
          ?.find(
            ({ serviceId, estimationId }) =>
              serviceId === service?.serviceId && estimationId === service?.estimationId
          )
          ?.serviceOptions[0]?.find(({ elevationId }) => elevationId === id)
          ?.amounts?.find(({ id }) => id === eventId)
      : fakeApplication?.services
          ?.find(
            ({ serviceId, estimationId }) =>
              serviceId === service?.serviceId && estimationId === service?.estimationId
          )
          ?.amounts?.find((eventData) => eventData?.id === eventId)

  const { fullyCharged, partiallyCharged } =
    thisFunction === "event"
      ? eventCheckerHandler({ selectedEvent })
      : thisFunction === "pli"
      ? pliCheckerHandler({ selectedEvent, id, pliId })
      : thisFunction === "addons"
      ? addonsCheckerHandler({ selectedEvent, id })
      : hoistCheckerHandler({ selectedEvent })

  return (
    <div className="chargedIndicatorContainerApplications">
      {/* check if this (event/pli/addons) is fully charged */}
      {fullyCharged ? (
        <Tooltip title="Fully charged!">
          <div className="allChargedIconHolder">
            <ChargedAllDaysIcon />
          </div>
        </Tooltip>
      ) : // check if this (event/pli/addons) is partially charged
      !fullyCharged && partiallyCharged ? (
        <Tooltip title="Partially charged!">
          <div className="partiallyChargedIconHolder">
            <ChargedSomeDaysIcon />
          </div>
        </Tooltip>
      ) : (
        // check if this (event/pli/addons) is not charged at all
        <Tooltip title="Not charged!">
          <div className="notChargedIconHolder">
            <NotCharged />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default ChargedIndicator
