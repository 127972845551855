import moment from "moment";
import * as XLSX from "xlsx";
import { removeTags } from "src/components/pages/Settings/settingsComponents/PricingConfig/utils/removeTags";
import { excelCellFitToColumn } from "../../../../utils/excelCellFitToColumn";

export const defaultExportGridToExcel = (
  defaultDataRowData,
  gridApi,
  rowData,
  title,
  generateLog
) => {
  generateLog("Download", "Download Excel");
  if (!defaultDataRowData) {
    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (
          headerName.toLowerCase().includes("description") ||
          headerName.toLowerCase().includes("comment")
        ) {
          return value ? removeTags(value) : "";
        } else if (headerName.toLowerCase().includes("date")) {
          return value ? moment(value).format("MM/DD/YYYY") : "";
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  } else {
    if (!rowData || rowData.length === 0) {
      // Handle the case where there is no rowData
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(rowData);

    worksheet["!cols"] = excelCellFitToColumn(rowData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, title);

    // Save the workbook to a file
    XLSX.writeFile(workbook, `${title}.xlsx`);
  }
};
