import React from "react";
import { Button, message } from "antd";
import _ from "lodash";
import { getNewId } from "../../../tools/polyfillers";
import {
  createPLIAddon,
  createSideWalkShedPLIAddon,
} from "../../../tools/polyfillers/AddonsCreator";
import { MandatoryFields } from "../../../models/MandatoryFields";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
export default class SidewalkShedAddonsRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      length: props.value,
      isWritable: props?.context?.isWritable || false,
    };
    this.props = props;
  }

  refresh(props) {
    this.setState({ value: props.value });
    return true;
  }

  componentDidMount() {}

  /**
   * This method calls parent method saveSidewalkShedAddonsData. It comes from dataEntryGrid and it is defined as prop in ServiceColumnDefiner.
   * @param addons {Object[]} Takes the modified addons array as parameter to pass them to dataEntryGrid which handles saving in database
   * Other props used in this function are defined in ServiceColumnDefiner too.
   * */
  saveNewAddonData = (addons) => {
    this.props.saveSidewalkShedAddonsData(
      this.props.api,
      this.props.serviceIndex,
      this.props.optionIndex,
      this.props.elevationIndex,
      this.props.data.id,
      addons
    );
  };

  /**
   * Insert an empty addon object inside the corresponding service
   * It is not saved into database. It will be saved in database after there is any edit in any cell of it.
   * */
  addAddon = () => {
    if (this.state.isWritable) {
      //it comes from dataEntryGrid component. It is attached to columnDefinitions by some manipulations
      let pli = _.cloneDeep(this.props.data);

      //check if pli has enough data
      let pliHasEnoughData = true;
      for (const field of MandatoryFields[pli.serviceId]) {
        //field must exist, it must be a valid number (not infinity)
        if (
          pli[field] === undefined ||
          pli[field] === Infinity ||
          isNaN(parseFloat(pli[field]))
        ) {
          pliHasEnoughData = false;
          break;
        }
      }

      if (!pliHasEnoughData) {
        message.error("Please, fill the PLI with data then create an addon.");
        return;
      }

      const highestId = getNewId(pli.addons);

      let addon;
      if (this.props.data.serviceId === 1) {
        addon = createSideWalkShedPLIAddon(
          highestId,
          pli.length,
          pli.height,
          pli.width
        );
      } else {
        addon = createPLIAddon(highestId);
      }

      pli.addons.push(addon);
      // this.props.onCellValueChanged();
      // const res = this.props.api.applyTransaction({update: [pli]});
      // this.props.api.setExpanded(true);
      // this.props.api.getRowNode(pli.id).setExpanded(false)
      this.saveNewAddonData(pli.addons);
    } else {
      message.error("Please enable write mode.");
    }
  };

  removeLastAddon = () => {
    if (this.state.isWritable) {
      //it comes from dataEntryGrid component. It is attached to columnDefinitions by some manipulations
      let pli = _.cloneDeep(this.props.data);
      if (pli.addons.pop()) {
        // const res = this.props.api.applyTransaction({update: [pli]});
        this.saveNewAddonData(pli.addons);
      } else {
        message.error("There is no addon to remove.");
      }
    } else {
      message.error("Please enable write mode.");
    }
  };
  componentDidUpdate(prevProps) {
    message.info("componentDidUpdate");
    if (prevProps.context?.isWritable !== props?.context?.isWritable) {
      this.setState({ isWritable: props?.context?.isWritable || false });
    }
  }
  render() {
    let addonCount = this?.state?.value?.length || 0;
    let addonDisplay;
    if (addonCount === 0) {
      addonDisplay = <span className="addons-count-zero">{addonCount}</span>;
    } else {
      addonDisplay = <span className="addons-count-number">{addonCount}</span>;
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {addonDisplay}
        <Button
          size="small"
          shape="circle"
          className="rowModifierAdd"
          onClick={this.addAddon}
          // disabled={!this.state?.isWritable}
        >
          <AddIcon />
        </Button>
        <Button
          size="small"
          shape="circle"
          className="rowModifierRemove"
          onClick={this.removeLastAddon}
          // disabled={!this.state?.isWritable}
        >
          <MinusCircleOutlined />{" "}
        </Button>
      </div>
    );
  }
}
