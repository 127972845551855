export const renderNestedDataWithHighlight = (data1, data2) => {
  if (Array.isArray(data1) && Array.isArray(data2)) {
    return (
      <ul>
        {data1.map((item, index) => (
          <li key={index}>
            {renderNestedDataWithHighlight(item, data2[index])}
          </li>
        ))}
      </ul>
    );
  } else if (typeof data1 === "object" && typeof data2 === "object") {
    return (
      <ul>
        {Object.entries(data1).map(([key, value], index) => {
          return (
            <li key={index}>
              <strong>{key}:</strong>
              {renderNestedDataWithHighlight(value, data2[key])}
            </li>
          );
        })}
      </ul>
    );
  } else {
    const isDifferent = data1 !== data2;
    return (
      <span style={{ background: isDifferent ? "#e3fcef" : "transparent" }}>
        {data1}
      </span>
    );
  }
};
