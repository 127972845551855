import dayjs from "dayjs";
import { message } from "antd";
import { useState } from "react";

import {
  ChargeCheckBox,
  TaxCheckbox,
} from "../components/ChargeAgGrid/components";
import { EditIcon } from "../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../utils/formatCurrency";
import { DescriptionModal } from "../../ChargeAgGrid/components/DescriptionModal";
import { cellRenders } from "../../ChargeInformation/AgGridData/components/cellRenders";
import { valueSetters } from "../../ChargeInformation/AgGridData/components/valueSetters";
import { cellRendersFrameworks } from "../../ChargeInformation/AgGridData/cellRendersFrameworks";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const calculateChargeAmountFromPLI = ({ charges = [], currentCharge }) => {
  return charges
    ?.filter(
      ({ chargeId, chargeType }) =>
        chargeId !== currentCharge?.chargeId && chargeType !== "Credit Memo"
    )
    ?.reduce((p, c) => p + (c?.chargeAmount || 0), 0);
};

const calculateCreditAmountFromPLI = ({ charges = [], currentCharge }) =>
  charges
    ?.filter(
      ({ chargeId, chargeType }) =>
        chargeId !== currentCharge?.chargeId && chargeType === "Credit Memo"
    )
    ?.reduce((p, c) => p + (c?.chargeAmount || 0), 0);

const setStringsToFunctions = (
  list,
  categoryFrom,
  gridApi,
  setDetailsVisible,
  setCredits
) =>
  list.map((e) => {
    if (e?.children)
      return {
        ...e,
        children: e?.children.map((el) => ({
          ...el,
          cellRenderer: cellRenders({
            ...e?.cellRendererParams,
          })[el?.cellRenderer],
          valueSetter: valueSetters(el?.field, gridApi)[el?.valueSetter],
          cellRendererFramework: cellRendersFrameworks({
            setDetailsVisible,
            setCredits,
          })[el.cellRendererFramework],
        })),
      };
    return {
      ...e,
      cellRenderer: cellRenders({
        ...e?.cellRendererParams,
        categoryFrom: categoryFrom,
      })[e.cellRenderer],
      valueSetter: valueSetters(e.field, gridApi)[e.valueSetter],
      cellRendererFramework: cellRendersFrameworks({
        setDetailsVisible,
        setCredits,
      })[e.cellRendererFramework],
    };
  });
const defaultPriceColumn = {
  field: "price",
  headerName: "Price",
  editable: true,

  cellRendererFramework: (props) => (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {formatCurrency(props.value)}
      <EditIcon style={{ fill: "#71CF48" }} />
    </div>
  ),
  getQuickFilterText: () => "",
};

export const columnDefsSetter = ({
  setColumnDefs,
  programFields,
  categoryFrom = "",
  gridApi,
  setDetailsVisible,
  setCredits,
  chargeItemsModal,
  objectData,
  currentCharge,
  setChargeToSave,
  chargeToSave,
  selectedService,
  selectedElevation,
  selectedElevationId,
  type,
  charge,
  initialService,
}) => {
  const defaultCheckBoxColumn = {
    headerName: "No# ",
    field: "selected",
    maxWidth: 120,
    cellRendererFramework: (params) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <ChargeCheckBox
            {...{
              data: params.data,
              checked: params.value,
              setChargeToSave,
              selectedService,
              initialService,
              type,
              charge,
              currentCharge,
              selectedElevationId,
              selectedElevation,
            }}
          />
          <span>
            {typeof params.data.id === "number"
              ? params.data.id
              : params.node.rowIndex + 1}
          </span>
        </div>
      );
    },
  };

  setColumnDefs(() => {
    try {
      Array.prototype.insert = (index, ...items) => {
        this.splice(index, 0, ...items);
      };
    } catch {}

    let toReturn = setStringsToFunctions(
      programFields.find(({ fieldName }) => fieldName === "Charges Column Defs")
        .fieldOptions[categoryFrom === "Rental" ? categoryFrom : "Estimation"],
      categoryFrom,
      gridApi,
      setDetailsVisible,
      setCredits
    );

    if (categoryFrom === "Estimation" || categoryFrom === "Schedule Of Value") {
      toReturn.find((el) => el.headerName === "No#").cellRendererFramework = ({
        data,
      }) => data?.id || 0;
      const priceC = toReturn.find((el) => el.headerName === "Price");
      priceC.field = "selectedValue";
      priceC.cellRendererFramework = ({ value }) => value || 0;
      toReturn.find((el) => el.headerName === "Tax %").cellRenderer = ({
        data,
      }) => `${data?.taxRate * 100 || 0}%`;
      toReturn.find((el) => el.headerName === "Tax Amount").cellRenderer = ({
        data,
      }) => formatCurrency(data?.taxAmount || data?.price * data?.taxRate || 0);
      if (chargeItemsModal) {
        const totalPriceColumn = toReturn.find(
          (el) => el.headerName === "Total"
        );
        totalPriceColumn.cellRenderer = (props) =>
          formatCurrency(forceToNumber(props.value));
        totalPriceColumn.editable = false;
        totalPriceColumn.field = "totalPrice";
      }
    } else if (categoryFrom === "Rental") {
      toReturn.find((el) => el.headerName === "Tax Amount").field = "taxAmount";
      toReturn.find((el) => el.headerName === "Tax Amount").cellRenderer = ({
        value,
      }) => formatCurrency(value);
      toReturn.find((el) => el.headerName === "Tax %").cellRenderer = ({
        value,
      }) => `${value * 100}%`;
      toReturn.find((el) => el.headerName === "Total").cellRenderer = ({
        data,
      }) => formatCurrency(data?.taxAmount + data?.price);

      toReturn.insert(
        4,
        {
          field: "rentalDetails",
          headerName: "Start Date",
          editable: false,
          cellRenderer: ({ value }) => (
            <div>{dayjs(value?.startDate)?.format("MM/DD/YYYY")}</div>
          ),
        },
        {
          field: "rentalDetails",
          headerName: "End Date",
          editable: false,
          cellRenderer: ({ value }) => (
            <div>{dayjs(value?.endDate)?.format("MM/DD/YYYY")}</div>
          ),
        }
      );
    }
    const indexOfPrice = toReturn.findIndex((el) => el.headerName === "Price");
    const indexOfCheckbox = toReturn.findIndex((el) => el.headerName === "No#");

    if (indexOfPrice > 0) {
      toReturn = [
        ...toReturn.slice(0, indexOfPrice),
        defaultPriceColumn,
        {
          field: "pricePercent",
          headerName:
            objectData?.type === "Credit Memo" ? "Credit %" : "Price %",
          editable: true,
          cellRendererFramework: ({ value }) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {parseFloat(value || 0).toFixed(2)}%
              <EditIcon style={{ fill: "#71CF48" }} />
            </div>
          ),
        },
        {
          field: "charges",
          headerName: "Previous Charged Amount",
          cellRendererFramework: ({ value: charges }) =>
            formatCurrency(
              calculateChargeAmountFromPLI({ charges, currentCharge })
            ),
        },
        {
          field: "charges",
          headerName: "Previous Charged %",
          cellRendererFramework: ({ value: charges, data }) =>
            `${
              (
                (calculateChargeAmountFromPLI({ charges, currentCharge }) /
                  data?.priceAmount || 0) * 100
              ).toFixed(2) || 0
            }%`,
        },
        {
          field: "charges",
          headerName: "Credited Amount",
          cellRendererFramework: ({ value: charges }) =>
            formatCurrency(
              calculateCreditAmountFromPLI({ charges, currentCharge })
            ),
        },
        {
          field: "charges",
          headerName: "Credited %",
          cellRendererFramework: ({ value: charges, data }) =>
            `${
              (
                (calculateCreditAmountFromPLI({ charges, currentCharge }) /
                  data?.priceAmount || 0) * 100
              ).toFixed(2) || 0
            }%`,
        },
        ...(objectData?.type === "Credit Memo"
          ? [
              {
                field: "invoicedPercentage",
                headerName: "Invoiced Amount",
                cellRendererFramework: ({ value: invoicedPercentage, data }) =>
                  formatCurrency(
                    ((invoicedPercentage || 0) * data?.priceAmount) / 100
                  ),
              },
            ]
          : []),
        {
          field: "priceAmount",
          headerName: "Scope Amount",
          cellRenderer: ({ value }) => formatCurrency(value || 0),
        },
        ...toReturn.slice(indexOfPrice + 1, toReturn.length),
      ];
      toReturn.splice(indexOfCheckbox, 1, { ...defaultCheckBoxColumn });
    }
    //To be changed
    if (objectData?.type !== "Credit Memo" || true)
      toReturn = toReturn.filter(({ headerName }) => {
        if (headerName === "Apply Credit") return false;
        else if (headerName === "Credits") return false;
        return true;
      });
    if (objectData?.type === "Credit Memo") {
      (
        toReturn.find(({ headerName }) => headerName === "Price") || {}
      ).headerName = "Credit Amount";
    }

    if (categoryFrom === "Schedule Of Value") {
      const indexToUpdate = toReturn.findIndex(
        ({ headerName }) => headerName === "Estimation Information"
      );

      // Check if the item was found
      if (indexToUpdate !== -1) {
        // Replace the item at the found index with the new item
        toReturn[indexToUpdate] = {
          field: "name",
          headerName: "Event",
        };
      }
    }
    toReturn?.push({
      headerName: "Description",
      headerTooltip: "Description",
      field: "note",
      minWidth: 60,
      editable: false,
      cellRendererFramework: ({ value, data }) => {
        const findLineItem = (el) => {
          const service = el?.chargeItems?.find(
            ({ label }) => label === selectedService
          );
          let chargeItem;
          if (selectedService === "Hoist") {
            chargeItem = service?.serviceOptions?.flat(1)?.find(({ items }) =>
              items?.find(({ elevationId, elevationLabel }) => {
                if (categoryFrom === "Rental") {
                  return (
                    elevationId === data?.elevationId &&
                    elevationLabel === data?.elevationLabel
                  );
                } else {
                  return (
                    elevationId === selectedElevationId &&
                    elevationLabel === selectedElevation
                  );
                }
              })
            );
          } else {
            chargeItem = service?.serviceOptions
              ?.find(
                ({ elevationId, elevationLabel }) =>
                  elevationId === selectedElevationId &&
                  elevationLabel === selectedElevation
              )
              ?.items?.find(({ id }) => id === data?.id);
          }
          return chargeItem;
        };
        if (categoryFrom !== "Requisition") {
          const chargeItem = findLineItem(chargeToSave);
          value = chargeItem?.note;
        }
        const [note, setNote] = useState(value || "");
        return (
          <DescriptionModal
            {...{
              note,
              onConfirm: ({ newValue }) => {
                setChargeToSave((el) => {
                  const chargeItem = findLineItem(el);
                  if (!!chargeItem) {
                    chargeItem.note = newValue;
                    setNote(newValue);
                  } else {
                    message.info("Select the element you want to edit");
                  }
                  return structuredClone(el);
                });
              },
              currentCharge,
            }}
          />
        );
      },
    });
    const taxCheckbox = {
      headerName: "TAX",
      field: "isTaxable",
      maxWidth: 100,
      cellRendererFramework: ({ data, value }) => {
        return (
          <TaxCheckbox
            {...{
              data,
              type,
              charge,
              checked: value,
              initialService,
              setChargeToSave,
              selectedService,
              selectedElevation,
              selectedElevationId,
            }}
          />
        );
      },
    };
    const index = toReturn.findIndex((item) => item.headerName === "Total");

    if (index !== -1) {
      toReturn.splice(index, 0, taxCheckbox);
    }

    return toReturn;
  });
};
