import "./ServicesCheckbox.scss";
import { Checkbox, Tooltip } from "antd";
import { Fragment, useEffect, useState } from "react";

const ServicesCheckbox = ({
  initialValue = false,
  containerClassName = "",
  containerStyle = {},
  primaryClassName = "",
  primaryStyle = {},
  secondaryClassName = "",
  secondaryStyle = {},
  labelClassName = "",
  label = "",
  tooltipVisible = true,
  onChange,
  key,
  isNotAppliedForProgress,
}) => {
  const [checked, setChecked] = useState(initialValue);

  const LabelWrapper = !!tooltipVisible ? Tooltip : Fragment;

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  return (
    <div
      className={`configElementContainer ${containerClassName}
        }`}
      style={containerStyle}
      key={key}
      onClick={() => {
        onChange(!checked);
        setChecked(!checked);
      }}
    >
      <div
        className={`bluContainer ${primaryClassName}`.trim()}
        style={{
          border: "none",
          width: !!checked ? 36 : null,
          ...primaryStyle,
        }}
      >
        <div
          className={`navyBlueContainer ${secondaryClassName}`.trim()}
          style={{
            ...secondaryStyle,
            // ...primaryStyle,
            opacity: !!checked ? 1 : null,
          }}
        >
          <span
            style={
              !checked ? { opacity: 0, border: "none" } : { border: "none" }
            }
          >
            <Checkbox
              {...{
                checked,
                onChange: (e) => onChange(e.target.checked),
              }}
            />
          </span>
        </div>
      </div>
      <LabelWrapper {...(!!tooltipVisible ? { title: label } : {})}>
        <span
          className={`configElementTitle ${labelClassName}`.trim()}
          style={{
            textDecoration: isNotAppliedForProgress ? "line-through" : "none",
          }}
        >
          {label}
        </span>
      </LabelWrapper>
    </div>
  );
};

export default ServicesCheckbox;
