import { API } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { Get } from "../../../utils/functions";
import { message } from "antd";
import EmailBox from "./components/EmailBox/EmailBox";

/**
 *
 * @param {Array} array current list
 * @param {Object} newElement new elemenet to add in current list
 * @returns
 */
export const addToArray = (array, newElement) => [...array, newElement];
/**
 *
 * @param {String} table db table
 * @param {String} recordId uuid of db record
 * @param {String} key key in table to update
 * @param {Array||Object} value value to update selected key
 * @returns
 */
export const updateTable = async (table, recordId, key, value) => {
  try {
    API.patch(table, `/${table}/${recordId}`, {
      body: [{ key, value }],
    });
  } catch (err) {
    console.log("error", err);
    console.error(err);
  }
};

/**
 *
 * @param {String} path path of the function in api to call
 * @param {Object} body object with params to pass
 * @returns
 */
export const callGmailAPI = async (path, body) =>
  axios.post(
    // `http://localhost:8080/api/${path}`,
    `https://leadmanager-express-backend.ue.r.appspot.com/api/${path}`,
    body
  );

/**
 *
 * @param {Array} array array to sort
 * @param {String} key string of key that you want to sort
 * @returns
 */
export const sortBy = (array, key) => array?.sort((a, b) => b[key] - a[key]);

/**
 *
 * @param {Array} array array that you want to get length
 * @returns
 */
export const getLength = (array) => array?.length;

export const getIsUnreadStatus = (checkedList) =>
  checkedList?.some((item) => item?.labels?.includes("UNREAD"));

export const getIsImportantStatus = (checkedList) =>
  checkedList?.some((item) => item?.labels?.includes("IMPORTANT"));

export const getIsUnreadAndRead = (checkedList) =>
  checkedList?.some((checkedListItem) =>
    checkedListItem?.labels?.includes("UNREAD")
  ) &&
  checkedList?.some(
    (checkedListItem) => !checkedListItem?.labels?.includes("UNREAD")
  );

export const getIsImportantAndNotImportant = (checkedList) =>
  checkedList?.some((checkedListItem) =>
    checkedListItem?.labels?.includes("IMPORTANT")
  ) &&
  checkedList?.some(
    (checkedListItem) => !checkedListItem?.labels?.includes("IMPORTANT")
  );

export const getFilteredEmails = (
  emails,
  drafts,
  category,
  searchValue,
  filterObject
) => {
  const emailsToFilter =
    category === "IMPORTANT"
      ? [...emails, ...drafts]
      : category === "DRAFT"
      ? drafts
      : emails?.filter((email) => !drafts?.includes(email));

  return (
    Array.isArray(emailsToFilter) &&
    emailsToFilter
      ?.filter((email) => email.labels?.includes(category))
      .filter((email) => {
        const createdAt = new Date(email.date);

        if (searchValue) {
          const snippetIncludesSearch = email?.snippet
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase());

          const senderIncludesSearch = email.from
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          const createdAtIncludesSearch = createdAt
            .toLocaleDateString()
            .includes(searchValue.toLowerCase());

          return (
            snippetIncludesSearch ||
            senderIncludesSearch ||
            createdAtIncludesSearch
          );
        }

        if (filterObject) {
          const fromFilter =
            !filterObject.from ||
            email.from.toLowerCase().includes(filterObject.from.toLowerCase());
          const subjectFilter =
            !filterObject.subject ||
            email.subject
              .toLowerCase()
              .includes(filterObject.subject.toLowerCase());
          const startDateFilter =
            !filterObject.date ||
            (filterObject.date[0] &&
              createdAt >= new Date(filterObject.date[0].$d));
          const endDateFilter =
            !filterObject.date ||
            (filterObject.date[1] &&
              createdAt <= new Date(filterObject.date[1].$d));

          return (
            fromFilter && subjectFilter && startDateFilter && endDateFilter
          );
        }

        return true;
      })
      .sort((a, b) => new Date(b.date) - new Date(a.date))
  );
};

export const getUnreadEmails = (emails) =>
  emails?.filter((email) => email.labels?.includes("UNREAD")) || [];

export const getReadEmails = (emails) =>
  emails?.filter((email) => !email.labels?.includes("UNREAD")) || [];

export function generateUsersEmailsOptions(userConfig, roles) {
  const userNamesSet = new Set(
    userConfig.allUsers.Items.map(({ userName }) => userName).filter(Boolean)
  );

  roles.forEach(({ email }) => {
    if (email) {
      userNamesSet.add(email);
    }
  });

  return Array.from(userNamesSet)?.map((email) => ({
    value: email.toLowerCase(),
    label: email,
  }));
}

export const getTemplateFiles = async (
  docItems,
  tableTemplateData,
  setTableTemplateData
) => {
  //TODO: fix this function not working as intended

  if (docItems?.length > 0 && tableTemplateData) {
    const templateDataWithAttachmentsIds = tableTemplateData.map((template) => {
      const updatedTemplate = { ...template };

      if (updatedTemplate.predefinedAttachments.length > 0) {
        updatedTemplate.templateAttachments =
          updatedTemplate.templateAttachments || [];

        const uniqueIds = new Set(updatedTemplate.templateAttachments);

        docItems.forEach((docItem) => {
          if (updatedTemplate.predefinedAttachments.includes(docItem.folder)) {
            if (!uniqueIds.has(docItem.id)) {
              updatedTemplate.templateAttachments.push(docItem.id);
              uniqueIds.add(docItem.id);
            }
          }
        });
      }

      return updatedTemplate;
    });

    setTableTemplateData(templateDataWithAttachmentsIds);
  }
};

// communicationFunctions.js

export const getData = async (
  recordName,
  recordId,
  dispatch,
  fetchEmailsStart,
  fetchEmailsSuccess,
  fetchEmailsFailure,
  fetchEmailsFinished,
  showErrorMsg,
  authData
) => {
  try {
    dispatch(fetchEmailsStart());
    const recordData = await Get(recordName, recordId);
    if (recordData) {
      const fetchEmails = async (emails, drafts) => {
        try {
          const [emailsResponse, draftsResponse] = await Promise.all([
            callGmailAPI("getRecordEmails", {
              list: emails || [],
              authData: authData === "bnVsbA==" ? null : authData,
            }),
            callGmailAPI("getRecordDrafts", {
              list: drafts || [],
              authData: authData === "bnVsbA==" ? null : authData,
            }),
          ]);

          dispatch(
            fetchEmailsSuccess({
              emails: emailsResponse?.data || [],
              drafts: draftsResponse?.data || [],
              recordData: {
                recordName,
                recordId,
                ...recordData,
              },
            })
          );
        } catch (error) {
          console.error("Error in try block:", error);
          dispatch(fetchEmailsFailure(error));
          showErrorMsg();
        } finally {
          setTimeout(() => {
            dispatch(fetchEmailsFinished());
          }, 500);
        }
      };
      fetchEmails(recordData.emailMessagesIds, recordData.drafts);
    }
  } catch (error) {
    dispatch(fetchEmailsFinished());
    console.error(
      "Error in fetching record information:",
      `recordName:${recordName} recordId:${recordId}`
    );
    showErrorMsg({ content: "Failed to fetch record information" });
  }
};

export const openEmailBox = (
  id,
  emailBoxes,
  setSelectedDraft,
  setEmailBoxes,
  mobile
) => {
  if (emailBoxes?.length === 2) {
    message.warning("You can not open more than two mail boxes");
    return;
  }

  if (mobile && emailBoxes?.length === 1) {
    message.warning("You can not open more than one mail box in mobile");
    return;
  }

  setSelectedDraft(id);
  setEmailBoxes((prev) =>
    [
      ...prev,
      {
        Component: EmailBox,
        key: emailBoxes?.length,
        id: id,
      },
    ].filter((box) => {
      return emailBoxes?.length > 1 ? box.id !== undefined : true;
    })
  );
};

export const determineCategoryType = (
  rowData,
  invoice,
  proppedTemplateCat,
  exportOrEmailDropdown,
  recordDetails
) => {
  if (rowData?.accountRecordType === "Subcontractors") {
    return "Subcontractors";
  } else if (rowData?.accountRecordType === "Client") {
    return "Clients";
  } else if (invoice) {
    return "Invoices";
  } else if (proppedTemplateCat) {
    return proppedTemplateCat;
  } else if (exportOrEmailDropdown) {
    return "Settings";
  } else {
    if (recordDetails?.categoryType.toLowerCase() === "inspectionsview") {
      return "Inspections";
    }

    return (
      recordDetails?.categoryType.charAt(0).toUpperCase() +
      recordDetails?.categoryType.slice(1)
    );
  }
};
