import {
  Canceled,
  Confirmed,
  Postponed,
  Reconfirmed,
  Skipped,
} from "../../../../../../assets";

export const DayStatus = {
  Canceled: "Canceled",
  Postponed: "Postponed",
  Confirmed: "Confirmed",
  Reconfirmed: "Reconfirmed",
  Skipped: "Skipped",
};

export const DayStatusWithProps = {
  Canceled: {
    label: "Canceled",
    color: "#F00",
  },
  Postponed: {
    label: "Postponed",
    color: "#7c0000",
  },
  Confirmed: {
    label: "Confirmed",
    color: "#00da00",
  },
  Reconfirmed: {
    label: "Reconfirmed",
    color: "#4bd74b",
  },
  Skipped: {
    label: "Skipped",
    color: "darkred",
  },
};

export const DisplayIcons = () => ({
  Canceled: <Canceled />,
  Skipped: <Skipped />,
  Postponed: <Postponed />,
  Confirmed: <Confirmed />,
  Reconfirmed: <Reconfirmed />,
});

export const DisplayColors = {
  Canceled: "#787677",
  Skipped: "#ECDE7C",
  Confirmed: "#71CF48",
  Reconfirmed: "#00A464",
  Postponed: "#FE4C4A",
};

//icons can be changed
export const DayStatusIcons = {
  Canceled: (style) => <Canceled style={style} />,
  Postponed: (style) => (
    <Postponed style={{ ...style, transform: "rotateX(180deg)" }} />
  ),
  ComesFrom: (style) => (
    <RiseOutlined style={{ ...style, transform: "rotateX(180deg)" }} />
  ),
  Confirmed: (style) => <Confirmed style={style} />,
  Reconfirmed: (style) => <Reconfirmed style={style} />,
  Skipped: (style) => <Skipped style={style} />,
};

export const getDayStatusIcon = (scheduleDay) => {
  const { status } = scheduleDay;

  let IconToReturn = DayStatusIcons[status]({
    color: DayStatusWithProps[status]?.color,
  });

  if (scheduleDay?.comesFromPostponed()) {
    IconToReturn = DayStatusIcons.ComesFrom({
      color: DayStatusWithProps[status]?.color,
    });
  }

  return IconToReturn || null;
};
