import "./Stepper.scss";
import { Stepper } from "../../../../commonComponents";

const Step = ({ params, id }) => {
  let { currentStep, steps } = params;
  return (
    <div className="stepperContainer">
      <div className="dividerStyle"></div>
      <Stepper
        {...{
          className: "StepperCard",
          currentStep,
          steps,
          stepRenderer: false,
        }}
      />
      <div className="dividerStyle"></div>
    </div>
  );
};
export default Step;
