import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const todos = (updatedDynamicStates, IdHandler, programFields) => {
  const todoStatus = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.["To Do"];

  return {
    todoStatus: () => ({
      title: "To Do Status",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "To Do",
        field: "todoStatus",
        title: "To Do Status",
        subtitle: "",
        content: todoStatus?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (e) => e?.todoStatus === status?.statusName
            )?.length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status?.statusName,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (e) => e?.todoStatus === status?.statusName
          )?.length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    todoCategory: () => ({
      title: "To Do Category",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "To Do",
        field: "todoCategory",
        title: "To Do Category",
        subtitle: "",
        content: Object?.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "todoCategory"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    relatedTo: () => ({
      title: "Related To",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "To Do",
        field: "relatedTo",
        title: "Related To",
        subtitle: "",
        content: Object?.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "relatedTo"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    todoUser: () => ({
      title: "To Do User",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "To Do",
        field: "todoUser",
        title: "To Do User",
        subtitle: "",
        content: Object?.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "todoUser"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
