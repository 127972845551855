import { Form, message } from "antd";
import { API } from "aws-amplify";
import { CommentsContainer } from "../../../commonComponents/Comment";

const IncidentComments = ({
  form,
  nameKey = "",
  rowObject = {},
  setChanges = () => {},
  refreshTable = () => {},
  table = "",
}) => {
  const keyData = {
    safety: {
      idKey: "safetyApplicationId",
      objectKey: "safetyApplicationObject",
    },
    incidents: {
      idKey: "incidentId",
      objectKey: "incidentObject",
    },
    allInspections: {
      idKey: "inspectionId",
      objectKey: "inspectionObject",
    },
  };

  const formComments = Form.useWatch("comments", form) || [];
  const title = Form.useWatch(nameKey, form) || [];

  const commentsChanged = (comments) => {
    const { idKey, objectKey } = keyData[table];
    // if editing
    if (!!rowObject?.[idKey]) {
      let tmpBody = structuredClone(rowObject);
      delete tmpBody?.userId;
      delete tmpBody?.[idKey];
      API.put(table, `/${table}/${rowObject?.[idKey]}`, {
        body: {
          ...tmpBody,
          [objectKey]: { ...tmpBody?.[objectKey], comments },
        },
      })
        .then(() => {
          message.success("Comments Updated Successfully");

          form.setFieldValue("comments", comments);

          refreshTable([
            {
              ...rowObject,
              [objectKey]: { ...rowObject?.[objectKey], comments },
            },
          ]);
        })
        .catch((err) => console.log({ err }));
    } else {
      form.setFieldValue("comments", comments);
      setChanges(true);
    }
  };
  return (
    <Form.Item name="comments">
      <CommentsContainer
        {...{
          category: table?.capitalize(),
          recordName: title,
          comments: formComments,
          commentsChanged,
        }}
      />
    </Form.Item>
  );
};

export default IncidentComments;
