// import { useDispatchContext, useStateContext } from "../../../../../../../../../../Context/Context"
import { getDisabledTooltipContent } from "../../../../GridUtils/functions/Disabled/Disabled";
import {
  Locked,
  Unlocked,
} from "../../../../../../../../../../../../../../DynamicView/src";
import { masterTableLockHandler } from "../../../../GridUtils/masterTableLockHandler";
import { useRedux } from "../../../../../../../../../../../../../../hooks";
import {
  getDisabledTooltipContentDay,
  lockChangedDay,
} from "../DaysTable/DaysModalAgGrid/DetailsGridUtils/lockRow";
import {
  getDisabledTooltipContentWeek,
  lockRowWeek,
} from "../WeekTable/DetailsAgGrid/DetailsGridUtils/lockRow";
import "./LockRow.scss";

import { message } from "antd";

const LockRow = ({ locked, whichFunction, propsObject }) => {
  // const { unchangingFakeRentals, selectedService, fakeRentals, isWritable } = useStateContext()

  const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux(
    "unchangingFakeRentals"
  );
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [isWritable] = useRedux("rentalsIsWritable");

  // const dispatchContext = useDispatchContext()

  // const setRentalContext = (newRentals) => {
  //   dispatchContext({ type: "SET_FAKE_RENTALS", payload: newRentals })
  // }
  // const setUnchangingRentalContext = (newRentals) => {
  //   dispatchContext({ type: "SET_UNCHANGING_FAKE_RENTALS", payload: newRentals })
  // }

  const functionHandler = {
    week: () =>
      lockRowWeek({
        selectedElevation: propsObject?.selectedElevation,
        selectedItem: propsObject?.selectedItem,
        params: propsObject?.params,
        setUnchangingFakeRentals,
        unchangingFakeRentals,
        selectedService,
      }),
    day: () =>
      lockChangedDay({
        selectedElevation: propsObject?.selectedElevation,
        selectedItem: propsObject?.selectedItem,
        selectedWeek: propsObject?.selectedWeek,
        params: propsObject?.params,
        setUnchangingFakeRentals,
        unchangingFakeRentals,
        selectedService,
      }),
    pli: () =>
      masterTableLockHandler({
        elevationData: propsObject?.elevationData,
        params: propsObject?.params,
        setFakeRentals,
        selectedService,
        fakeRentals,
      }),
  };

  const isDisabledChecked = {
    week: () =>
      getDisabledTooltipContentWeek({
        selectedElevation: propsObject?.selectedElevation,
        selectedItem: propsObject?.selectedItem,
        params: propsObject?.params,
        unchangingFakeRentals,
        selectedService,
        isWritable,
      }),
    day: () =>
      getDisabledTooltipContentDay({
        selectedElevation: propsObject?.selectedElevation,
        selectedItem: propsObject?.selectedItem,
        selectedWeek: propsObject?.selectedWeek,
        params: propsObject?.params,
        unchangingFakeRentals,
        selectedService,
        isWritable,
      }),
    pli: () =>
      getDisabledTooltipContent({
        elevationData: propsObject?.elevationData,
        params: propsObject?.params,
        selectedService,
        fakeRentals,
        isWritable,
      }),
  };

  return (
    <div className="lockedCellDetailsRentals">
      <button
        className="lockButton"
        onClick={() =>
          !!isDisabledChecked[whichFunction]()
            ? message.warning(isDisabledChecked[whichFunction]())
            : functionHandler[whichFunction]()
        }
      >
        {!!locked ? <Locked /> : <Unlocked />}
      </button>
    </div>
  );
};

export default LockRow;
