import { useState } from "react";
import { NormalSizedModal } from "../../../../../../Fleet/components";
import { Button, Table, Tag, Tooltip } from "antd";
import { MondayButton } from "../../../../../../../commonComponents";
import { CloseIconModal } from "../../../../../../../../assets";
import { formatDimensions } from "../../../../tools/exporters/exportToPDF";
import "./SelfCheck.scss";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { CarryOutFilled, CarryOutOutlined } from "@ant-design/icons";
function SelfCheck({ elevation, darkMode = true, title = "Self Check!" }) {
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const onFireUp = () => {
    const addons = elevation?.items?.flatMap((item) => {
      return item?.addons?.map((addon) => {
        return addon?.name?.values?.find((val) => val?.name === "Name")?.value;
      });
    });
    const myDataHolder = {};
    const myAddonsHolder = {};
    let tempColumns = [
      {
        title: "Floor",
        // width: 100,
        dataIndex: "floor",
        key: "floor",
        fixed: "left",
        // sorter: true,
      },
      {
        title: "Stop",
        // width: 100,
        dataIndex: "stop",
        key: "stop",
        fixed: "left",
        //   sorter: true,
        render: (stop) => {
          return stop ? <Tag color="blue">Yes</Tag> : <Tag color="red">No</Tag>;
        },
      },
      {
        title: "Dual",
        // width: 100,
        dataIndex: "dual",
        key: "dual",
        fixed: "left",
        //   sorter: true,
        render: (dual) => {
          return dual ? <Tag color="blue">Yes</Tag> : <Tag color="red">No</Tag>;
        },
      },
    ];
    console.log("onFireUp", { elevation, addons });
    let applicableAddons = [];
    const tempData = elevation?.items?.forEach((item) => {
      myDataHolder[item?.floor] = {};
      let currAddons = item?.addons;
      if (Array.isArray(currAddons)) {
        currAddons.forEach((addon) => {
          let tempName = addon?.name?.values?.find(
            (el) => el?.name === "Name"
          ).value;
          applicableAddons.push(tempName);
          if (!applicableAddons.includes(tempName)) {
            let existingColumn = tempColumns.findIndex(
              (el) => el?.title === tempName
            );
            if (existingColumn === -1 && tempName !== "undefined") {
              tempColumns.push({
                title: tempName,
                //   width: 100,
                dataIndex: tempName,
                key: tempName,
                fixed: "left",
                // sorter: true,
                render: (addons) => {
                  return <Tag color="gold">{addons}</Tag>;
                },
              });
            }
            myDataHolder[item?.floor] = {
              ...(myDataHolder[item?.floor] || {}),
              [tempName]: [],
            };
          }
          let currAddon = {
            name: tempName,
            ...addon,
          };
          myDataHolder[item?.floor] = {
            ...(myDataHolder[item?.floor] || {}),
            [tempName]: [
              ...(myDataHolder[item?.floor]?.[tempName] || []),
              currAddon,
            ],
          };
        });
      }
    });
    if (Array.isArray(applicableAddons)) {
      applicableAddons.forEach((addonName) => {
        let existingColumn = tempColumns.findIndex(
          (el) => el?.title === addonName
        );
        console.log("existingColumn", { existingColumn, addonName });
        if (existingColumn === -1 && addonName !== "undefined")
          tempColumns.push({
            title: addonName,
            //   width: 100,
            dataIndex: addonName,
            key: addonName,
            fixed: "left",
            // sorter: true,
            render: (addons) => {
              return <Tag color="gold">{addons}</Tag>;
            },
          }); // for the addons
      });
    }
    let myData = [];
    Object.keys(myDataHolder).forEach((key) => {
      let foundFloor = elevation?.items?.find(
        (item) => item?.floor.toString() === key.toString()
      );
      let initialObj = {
        floor: key,
        dual: foundFloor?.dual,
        stop: foundFloor?.stop,
      };
      if (Object.keys(myDataHolder[key]).length > 0) {
        let floorAddons = myDataHolder[key];
        if (Object.keys(floorAddons).length > 0) {
          Object.keys(floorAddons).forEach((addonKey) => {
            let currAddon = floorAddons[addonKey];
            if (Array.isArray(currAddon)) {
              if (currAddon.length > 0) {
                initialObj = {
                  ...initialObj,
                  [addonKey]: currAddon
                    .map((addon, idx) => {
                      return (
                        idx +
                        1 +
                        ". [ " +
                        formatDimensions(
                          addon?.length,
                          addon?.height,
                          addon?.width
                        ) +
                        " ]"
                      );
                    })
                    .join(", "),
                };
              }
            }
          });
        }
      }
      myData.push(initialObj);
    });
    setColumns(tempColumns?.filter((el) => el?.title !== undefined));
    setData(
      myData?.sort((a, b) => forceToNumber(a?.floor) - forceToNumber(b?.floor))
    );
    setOpen(true);
  };
  const onCleanUp = () => {
    setOpen(false);
  };
  const sharedOnCell = (_, index) => {
    if (index === 1) {
      return { colSpan: 0 };
    }
    return {};
  };
  //   const columns = [
  //     {
  //       title: "Floor",
  //       width: 100,
  //       dataIndex: "name",
  //       key: "name",
  //       fixed: "left",
  //       sorter: true,
  //     },
  //     {
  //       title: "Stop",
  //       width: 100,
  //       dataIndex: "age",
  //       key: "age",
  //       fixed: "left",
  //       //   sorter: true,
  //     },
  //     // {
  //     //   title: "Ramps",
  //     //   colSpan: 2,
  //     //   dataIndex: "tel",
  //     //   onCell: (_, index) => {
  //     //     if (index === 3) {
  //     //       return { rowSpan: 2 };
  //     //     }
  //     //     // These two are merged into above cell
  //     //     if (index === 4) {
  //     //       return { rowSpan: 0 };
  //     //     }
  //     //     if (index === 1) {
  //     //       return { colSpan: 0 };
  //     //     }
  //     //     return {};
  //     //   },
  //     // },
  //     { title: "Column 1", dataIndex: "address", key: "1" },
  //     { title: "Column 2", dataIndex: "address", key: "2" },
  //     { title: "Column 3", dataIndex: "address", key: "3" },
  //     { title: "Column 4", dataIndex: "address", key: "4" },
  //     { title: "Column 5", dataIndex: "address", key: "5" },
  //     { title: "Column 6", dataIndex: "address", key: "6" },
  //     { title: "Column 7", dataIndex: "address", key: "7" },
  //     { title: "Column 8", dataIndex: "address", key: "8" },
  //     {
  //       title: "Action",
  //       key: "operation",
  //       fixed: "right",
  //       width: 100,
  //       render: () => <a>action</a>,
  //     },
  //   ];
  //   const data = [
  //     {
  //       key: "1",
  //       name: "Floor 1",
  //       age: "Yes",
  //       address: "New York Park",
  //     },
  //     {
  //       key: "2",
  //       name: "Floor 2",
  //       age: "Yes",
  //       address: "London Park",
  //     },
  //     {
  //       key: "2",
  //       name: "Floor 3",
  //       age: "Yes",
  //       address: "London Park",
  //     },
  //   ];
  return (
    <div
      className="hoistFormSectionChild"
      style={{
        marginLeft: "10px",
      }}
    >
      <Tooltip title="Self Check">
        <MondayButton
          onClick={onFireUp}
          className="mondayButtonBlue"
          hasIcon={false}
        >
          <CarryOutFilled />
        </MondayButton>
      </Tooltip>
      {/* <Button type="primary" danger ghost onClick={onFireUp}>
        {title}
      </Button>{" "} */}
      <NormalSizedModal
        destroyOnClose={true}
        setVisible={setOpen}
        title="Self-check"
        className={`hoist_self_check_modal_${darkMode ? "dark" : "light"}`}
        visible={isOpen}
        darkMode={darkMode}
        closeIcon={<CloseIconModal />}
        customFooter={
          <div>
            <MondayButton
              className="mondayButtonRed"
              onClick={onCleanUp}
              Icon={<CloseIconModal />}
            >
              Close View
            </MondayButton>
          </div>
        }
      >
        <div className={`hoist_self_check_${darkMode ? "dark" : "light"}`}>
          {" "}
          <Table
            columns={columns}
            pagination={false}
            dataSource={data}
            bordered
            hideOnSinglePage={true}
            // scroll={{ y: 500 }}
          />
        </div>
      </NormalSizedModal>
    </div>
  );
}
export default SelfCheck;
