export const filterDuplicatedChargeItems = (chargeToSave, categoryFrom) => {
  if (categoryFrom === "Estimation") return chargeToSave;

  return {
    ...chargeToSave,
    chargeItems: chargeToSave?.chargeItems?.map((chargeItem) => ({
      ...chargeItem,
      serviceOptions:
        chargeItem?.serviceOptions?.reduce((prev, serviceOption) => {
          const items = serviceOption?.items?.filter(
            ({ elevationId }) =>
              !prev?.find(({ items }) =>
                items?.find(({ elevationId: eID }) => elevationId === eID)
              )
          );
          return items?.length > 0
            ? [...prev, { ...serviceOption, items }]
            : prev;
        }, []) || [],
    })),
  };
};
