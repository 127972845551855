import { Collapse, Divider, Table } from "antd";

import { extractContent } from "../../../Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";
import Headline from "../../../../commonComponents/Base/Headline";
import Text from "src/components/commonComponents/Base/Text";

export default function List({ estimation, activeService }) {
  const tableColumns = [
    {
      title: "Index",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const items = estimation?.services
    ?.filter(({ type }) => type === activeService?.type)
    ?.flatMap((service) =>
      service?.elevationsInspected?.map((elevation, index) => ({
        key: `${elevation?.estimationId}+${index}`,
        label: elevation?.elevationLabel,
        children: (
          <Table
            columns={tableColumns}
            pagination={false}
            dataSource={elevation?.plisInspected?.map((item, i) => ({
              id: ++i,
              height: item?.pli?.height ?? "",
              length: item?.pli?.length ?? "",
              description: !!item?.pli?.description?.note
                ? extractContent(item?.pli?.description?.note)
                : "",
            }))}
          />
        ),
      }))
    );

  return (
    <>
      <Headline>
        Estimation{" "}
        {!!estimation?.estimationNumber ? estimation?.estimationNumber : "#"}
      </Headline>
      {!!items.length ? (
        <Collapse accordion items={items} expandIconPosition="end" />
      ) : (
        <>
          <Divider />
          <Text>No records to display</Text>
        </>
      )}
    </>
  );
}
