import { getPricingPromise, getProposedTypeOfWorkPromise } from "./pricing";
import { getEstimation, getProject } from "./estimationANDproject";
import { CheckIfValidId } from "./validators";
import { getUnits } from "./units";

/**
 * Get all data that are needed for Data Entry Grid
 * @param estimationId {String}
 * @param userConfiguration {Object}
 * */
export async function getNeededDataForDataEntryGrid(
  userConfiguration,
  estimation
) {
  // if (!CheckIfValidId(estimationId)) {
  //   throw "estimationId is not correct! projectId: " + estimationId // eslint-disable-line
  // }

  //getting all needed data fro this component
  let [
    pricingData,
    proposedTypeOfWork,
    // estimation,
    { priceView, priceEdit },
    units,
  ] = await Promise.all([
    // pricePerUnitInfoPromise(estimationId),
    getPricingPromise(),
    getProposedTypeOfWorkPromise(),
    // getEstimation(estimationId),
    getAccessRights(userConfiguration),
    getUnits(),
  ]);

  const project = await getProject(estimation);
  console.log("projectTakeoff", project);

  return {
    pricingData,
    proposedTypeOfWork,
    // estimation,
    priceView,
    priceEdit,
    projectData: project,
    units,
  };
}

export const getAccessRights = async (userGroups) => {
  let priceView, priceEdit;
  let estimation = userGroups.routeConfig?.find(
    (element) => element.title === "Estimations/View"
  );
  let access = estimation?.children?.find((e) => e.title === "Type Of Work");
  if (access) {
    priceView = true;
    priceEdit = access?.children?.find((a) => a.title === "Price Edit")?.write;
  }
  return { priceView, priceEdit };
};
