import { Tag } from "antd"
import dayjs from "dayjs"

var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

const DateTimeRenderer = ({ status, commentStyle, createdAt }) => {
  return (
    <div>
      {!!status && <Tag style={{ ...commentStyle, border: 0 }}>{status}</Tag>}
      <span>{dayjs(createdAt).fromNow()}</span>
    </div>
  )
}

export default DateTimeRenderer
