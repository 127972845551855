export const getRouteAccessRights = ({ userConfiguration, title }) => {
  const routeConfig = userConfiguration.routeConfig || [];

  // check for nested route config title, example settings/services/Includes & Excludes has global notification title like this ["Settings","Services","Includes & Excludes"]
  const routeConfigObj = Array.isArray(title)
    ? findNestedRoute(routeConfig, title)
    : findRoute(routeConfig, title);

  return {
    read: !!routeConfigObj,
    ...(!!routeConfigObj && routeConfigObj),
  };
};

// Function to find a route in userConfiguration
export function findRoute(routeConfig, title) {
  return routeConfig?.find((route) => route?.title === title);
}

// Function to find a nested route in userConfiguration
function findNestedRoute(routeConfig, titles) {
  let nestedRoute = routeConfig;

  for (let index = 0; index < titles.length; index++) {
    const title = titles[index];

    const foundedRoute = nestedRoute?.find((route) => route.title === title);

    if (!foundedRoute) return null; //if not finded Route return null

    //check if is last element and breake loop else check for childrens
    if (index < titles.length - 1) {
      nestedRoute = foundedRoute.children;
    } else {
      return foundedRoute;
    }
  }

  return null;
}
