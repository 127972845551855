const safetyReducer = (state, action) => {
  try {
    switch (action.type) {
      case "GET_DATA": {
        const { data } = action.payload;

        return {
          ...state,
          ...data,
        };
      }
      case "SCHEDULES_FOR_DATE": {
        const { schedulesForDate } = action.payload;
        return {
          ...state,
          schedulesForDate,
        };
      }

      case "SEARCH_FETCH": {
        console.log("PAYLOAD : ", { ...action.payload });
        return {
          ...state,
          ...action.payload,
        };
      }

      case "EDIT_LOGS": {
        const { editLogs } = action.payload;
        return {
          ...state,
          editLogs,
        };
      }

      case "VIEW_LOGS": {
        const { viewLogs } = action.payload;
        return {
          ...state,
          viewLogs,
        };
      }

      case "RESET": {
        return {};
      }
    }
  } catch (err) {
    console.error("Error in safetyReducer: ", err);
  }
};

export default safetyReducer;
