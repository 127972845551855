import { checkIfServiceIsHoist } from "../../../../../../../../../../Estimations/DataEntryGrid/models/Service";

//export to excel data
export const exportToExcelData = ({ fakeSOV }) => {
  try {
    const workSheet2DataExcel = fakeSOV?.services
      ?.filter(({ visible = false }) => visible === true)
      ?.flatMap(({ amounts, label, retainage, serviceOptions }, idx, obj) => {
        return checkIfServiceIsHoist(obj[idx])
          ? serviceOptions[0]?.map(
              ({ elevationLabel, amounts: hoistAmounts }) => ({
                label: `${label} ${elevationLabel}`,
                amounts: hoistAmounts,
                retainage,
              })
            )
          : {
              retainage,
              amounts,
              label,
            };
      });

    return workSheet2DataExcel;
  } catch (error) {
    return [];
  }
};
