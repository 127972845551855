import React, { useState } from "react";
import { DeleteIcon } from "../../../../../../../../assets";
import { useDispatch } from "react-redux";
import { ConfirmationModal } from "../../../../ConfirmationModal";
import { useSelector } from "react-redux";
import { onDeleteConfirmation } from "../../../../helpers/notification-item-actions";

function DeleteItem({ setAnimatedClassName, apiName, path, text, type, item }) {
  const {
    id,
    notificationBody: { notificationObject, cognitoUserId },
  } = item;

  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const onOK = () => {
    onDeleteConfirmation(
      setAnimatedClassName,
      id,
      cognitoUserId,
      authenticatedUser,
      notificationObject,
      apiName,
      path,
      dispatch,
      type
    );
  };

  return (
    <div>
      <span
        className="notification-delete-icon"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon />
      </span>
      {open ? (
        <ConfirmationModal
          showModal={open}
          setShowModal={() => setOpen(false)}
          title={`delete ${text.replace(/<[^>]+>/g, "")}`}
          onOK={onOK}
        />
      ) : null}
    </div>
  );
}

export default DeleteItem;
