import { useEffect, useMemo, useState } from "react";
import {
  state,
  keys,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import _ from "lodash";
import StyledButton from "../../components/StyleButton/StyledButton";
import FormItemCheckBox from "../../FormItemComponents/CheckBox/CheckBox";
import { ScrollIcon, TransferIcon } from "../../src";
import "./Transfer.scss";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";

const Transfer = (prop) => {
  const {
    params: {
      content: { value, data, formItemName },
    },
    id,
    getEditedValue,
    firstSideHeaderText = "Users",
    secondSideHeaderText = "Selected Users",
    setEditMode,
    setCloseTeamModalsOnCancel,
    getFormChanged = () => {},
    setFormChanged = () => {},
    footerDisabled = false,
  } = prop;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [values, setValues] = useState(value);
  const [scrollable, setScrollable] = useState();
  const [bottom, setBottom] = useState(false);
  const [iconColor, setIconColor] = useState("#323338");
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [resultLengths, setResultLengths] = useState([0, 0]);
  //saved previous data
  const [prev, setPrev] = useState();

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
    setCloseTeamModalsOnCancel && setCloseTeamModalsOnCancel(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  useEffect(() => {
    setPrev(() => _.cloneDeep(JSON.parse(JSON.stringify(prop))));
  }, [prop]);

  useEffect(() => {
    if (!!data && !!values) {
      let left = 0,
        right = 0;
      data.forEach(({ id }) => {
        if (!values.includes(id.toString())) {
          ++left;
        } else {
          ++right;
        }
      });
      setResultLengths([left, right]);
    }
  }, [data, values]);

  ///  finished
  const selectedValues = { [formItemName]: values };

  useEffect(() => {
    document.getElementById(`${id}edit`)?.scrollHeight &&
      setScrollable(document.getElementById(`${id}edit`).scrollHeight > 400);
  }, [document.getElementById(`${id}edit`)?.scrollHeight]);

  const duplicatedTitles = useMemo(() => {
    const titleCounts = {};
    data.forEach((item) => {
      titleCounts[item.title] = (titleCounts[item.title] || 0) + 1;
    });

    const duplicatedTitlesArray = Object.keys(titleCounts).filter(
      (title) => titleCounts[title] > 1
    );

    return duplicatedTitlesArray;
  }, [data]);

  const tooltipTitle = (email, role) => {
    return (
      <div>
        <div>{role}</div>
        <div>{email}</div>
      </div>
    );
  };

  return (
    <div className={`transferEditBody ${isDarkMode && "transferEditBodyDark"}`}>
      <div
        className="transferEditedContainer"
        id={`${id}edit`}
        onScroll={({ target: { scrollHeight, scrollTop, clientHeight } }) => {
          if (scrollHeight - Math.floor(scrollTop) === clientHeight) {
            if (bottom !== true) {
              setBottom(true);
            }
          } else {
            if (bottom !== false) {
              setBottom(false);
            }
          }
        }}
      >
        <div className="unSelectedContainer">
          <span className="countItemsLabel">
            {resultLengths[0]} {firstSideHeaderText}
          </span>
          {data?.map(({ title, id, role, email }) => {
            if (!values.includes(id.toString())) {
              return (
                <FormItemCheckBox
                  {...{
                    className: "checkBoxStyle",
                    checkedClassName: "checkedRedStyle",
                    navyClassName: "navyRedStyle",
                    value: false,
                    tooltipTitle: duplicatedTitles.includes(title)
                      ? tooltipTitle(email, role)
                      : "",
                    callback: (e, b, cbId) => {
                      document.getElementById(id).classList.remove("out");
                      document.getElementById(id).classList.add("active");
                      setIconColor("#71CF48");
                      setTimeout(() => {
                        let index = data.find((x) => x.id === cbId).id;
                        setValues((prev) => [...prev, index.toString()]);
                        setIconColor("#323338");
                      }, 300);
                    },
                    label: title,
                    proppedId: id,
                    onChange: () => {
                      if (!prop?.params?.onChange) {
                        setFormChanged(true);
                      } else {
                        prop?.params?.onChange();
                      }
                    },
                  }}
                  key={id}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <div className="middleContainer">
          <span className="circleStyle">
            <TransferIcon fill={iconColor} />
          </span>
        </div>
        <div className="selectedContainer">
          <span className="countItemsLabel">
            {resultLengths[1]} {secondSideHeaderText}
          </span>
          {data?.map(({ title, id, role = "", email = "" }) => {
            if (values.includes(id.toString())) {
              return (
                <FormItemCheckBox
                  {...{
                    className: "checkBoxStyle",
                    checkedClassName: "checkedGreenStyle",
                    navyClassName: "navyGreenStyle",
                    tooltipTitle: duplicatedTitles.includes(title)
                      ? tooltipTitle(email, role)
                      : "",
                    value: true,
                    callback: (e, b, cbId) => {
                      document.getElementById(id).classList.remove("active");
                      document.getElementById(id).classList.add("out");
                      setIconColor("#FE4C4A");
                      setTimeout(() => {
                        let index = data?.find((x) => x?.id === cbId)?.id;
                        setValues((prev) =>
                          _.cloneDeep(prev).filter(
                            (id) => id !== index.toString()
                          )
                        );
                        setIconColor("#323338");
                      }, 300);
                    },
                    label: title,
                    proppedId: id,
                  }}
                  key={id}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      {scrollable && (
        <span
          style={{ cursor: bottom ? "pointer" : "default" }}
          onClick={() => {
            if (bottom) {
              document.getElementById(`${id}edit`).scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }}
          className={!bottom ? "scrollBottomContainer" : "scrollTopContainer"}
        >
          <ScrollIcon />
        </span>
      )}
      <div className="confirmContainer">
        <MondayButton
          className="mondayButtonRed"
          onClick={(res) => {
            let tmpHandler = prop.params.getFormChanged
              ? prop.params.getFormChanged
              : getFormChanged;
            if (typeof tmpHandler === "undefined") {
              onCancel();
            } else {
              if (tmpHandler()) {
                setCancelModalVisible(true);
              } else {
                onCancel();
              }
            }
          }}
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          disabled={footerDisabled}
          onClick={(res) => {
            prop.params.content.value = values;
            getEditedValue(
              selectedValues,
              undefined,
              {
                [formItemName]: document.prevTransferList,
              },
              prev,
              prop
            );
            setEditMode(false);
          }}
          Icon={<Tick height={19} width={19} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={() => onCancel()} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default Transfer;
