import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import "./HoistInspectionsRaport.scss";
import { DynamicTable } from "../../../commonComponents/DynamicTable/DynamicTable";
import hoistColumnDefs from "./hoistColumnDefs";
import moment from "moment";
import { MondayButton } from "../../../commonComponents/index";
import { PdfWhiteIcon } from "../../../../assets/index";
import {
  hoistRaport,
  inspectionRaport,
  repeatelem,
} from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { pdfExportFunc } from "../../../../utils/exportToPdf";
import { XIcon } from "../../Communication/assets";
const HoistInspectionsRaport = ({
  open,
  setOpen,
  rowData,
  userConfiguration,
  projectAccountName,
  isDarkMode,
}) => {
  const days =
    rowData?.scheduleDays?.reduce((prev, arr) => {
      return {
        ...prev,
        [arr?.id]: moment(arr?.startDate).format("MM/DD/YYYY"),
      };
    }, {}) || {};

  const exportToPdf = () => {
    pdfExportFunc(
      "hoist-inspection-preview",
      "",
      "Hoist Overview",
      "schedulePreviewPDF"
    );
  };

  return (
    <div>
      <Modal
        className={`hoist-inspection-raport-modal ${
          isDarkMode && "hoist-inspection-raport-modal-dark"
        }`}
        open={open}
        onCancel={() => setOpen(false)}
        closeIcon={<XIcon />}
        title="Hoist Inspections"
        footer={null}
        centered
        height="fit-content"
      >
        <>
          <div id="hoist-inspection-preview">
            <div className="header-container-inspection">
              {inspectionRaport(rowData, projectAccountName)?.map?.((el) => {
                return (
                  <div className="data-hoist-inspection">
                    <div className="elements-schedule">
                      <span className="hoist-value-elements">{el?.value}</span>
                      <span>{el?.label}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                height: "fit-content",
                maxHeight: "600px",
                overflow: "auto",
              }}
            >
              <DynamicTable
                {...{
                  columnDefs: hoistColumnDefs,
                  rowData: hoistRaport(rowData, days),
                  tableStyle: {
                    width: "100%",
                    border: "1px solid #DDE3E5",
                    borderCollapse: "collapse",
                  },
                  tableHeaderStyle: {
                    border: "1px solid #DDE3E5",
                    background: "#B7C4CB",
                    paddingLeft: 5,
                  },
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <MondayButton
              onClick={exportToPdf}
              {...{ className: "mondayButtonBlue", Icon: <PdfWhiteIcon /> }}
            >
              Export to PDF
            </MondayButton>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default HoistInspectionsRaport;
