import types from "../types";

export const setNotifications = ({
  notifications: { notifications, unreadLength },
  authenticatedUser,
  lastEvaluatedKey,
}) => ({
  type: types.SET_NOTIFICATIONS,
  payload: { notifications, authenticatedUser, lastEvaluatedKey, unreadLength },
});

export const addNotification = ({
  notification,
  category,
  notificationSettings,
}) => ({
  type: types.ADD_NOTIFICATION,
  payload: { notification, category, notificationSettings },
});

export const deleteNotification = (id) => ({
  type: types.DELETE_NOTIFICATION,
  payload: { id },
});

export const updateNotifications = ({
  notifications: notificationIds,
  action,
}) => ({
  type: types.UPDATE_NOTIFICATIONS,
  payload: { notifications: notificationIds, action },
});

export const getNotificationsRequest = () => ({
  type: types.GET_NOTIFICATIONS_REQUEST,
});

export const getNotificationsSuccess = () => ({
  type: types.GET_NOTIFICATIONS_SUCCESS,
});

export const getEmailNotificationsRequest = () => ({
  type: types.GET_EMAIL_NOTIFICATIONS_REQUEST,
});

export const getEmailNotificationsSuccess = (emails, unreadLength) => ({
  type: types.GET_EMAIL_NOTIFICATIONS_SUCCESS,
  payload: { emails, unreadLength },
});

export const getEmailNotificationsRequestFinished = () => ({
  type: types.GET_EMAIL_NOTIFICATIONS_REQUEST_FINISHED,
});

export const markEmailsAsRead = (itemId, isDraft) => ({
  type: types.MARK_EMAILS_AS_READ,
  payload: { itemId, isDraft },
});

export const getEmailNotificationsFailure = (error) => ({
  type: types.GET_EMAIL_NOTIFICATIONS_FAILURE,
  payload: { error },
});

export const setFilters = ({ tab, filterBy }) => ({
  type: types.SET_FILTERS,
  payload: { tab, filterBy },
});

export const toggleNotificationPinnedStatus = (
  notificationId,
  tab,
  filterBy
) => ({
  type: types.TOGGLE_NOTIFICATION_PINNED_STATUS,
  payload: { notificationId, tab, filterBy },
});
