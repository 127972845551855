import React, { useState } from "react";
import {
  MondayButton,
  RedWarningModal,
  WarningModal,
} from "../../../../../../../../commonComponents";
import { Alert, Button, Flex, Form, Input, QRCode, Typography } from "antd";
import CustomModal from "../../../../../../../../commonComponents/CustomModal/CustomModal";
import styles from "./TwoFactorAuthenticationChange.module.css";
import { useTwoFactorAuthentication } from "./useTwoFactorAuthentication";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { LockFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ConfirmationCodeModal from "./ConfirmationCodeModal";

const allSteps = (setupUri) => [
  {
    id: 1,
    title: "Scan QR code",
    description: "Scan the QR code with your authenticator app.",
    Component: () => (
      <>
        <Flex className={styles.information}>
          <Typography>- In the Google Authenticator app tap the +</Typography>
          <Typography>
            - Choose <b>Scan a QR code</b>
          </Typography>
        </Flex>
        {setupUri && (
          <QRCode size={256} value={setupUri} className={styles.qrCode} />
        )}
      </>
    ),
  },
  {
    id: 2,
    title: "Enter verification code",
    description: "Enter the verification code from your authenticator app.",
    Component: () => (
      <InputComponent
        type="otp"
        size="large"
        name="code"
        label="Enter the 6-digit code you see in the app"
        placeholder="Enter code"
        className={styles.otpInput}
      />
    ),
  },
];

const TwoFactorAuthenticationChange = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [disableWarning, setDisableWarning] = useState(false);
  const [confirmCodeOpen, setConfirmCodeOpen] = useState(false);

  const {
    isLoading,
    setupUri,
    currentStep,
    isEnabled,
    verifyCode,
    disableTotp,
    handleStepChange,
    setUpTotpHandler,
    qrErrorMessage,
  } = useTwoFactorAuthentication(open);

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const steps = allSteps(setupUri);

  const { Component } = steps[currentStep];

  const isLastStep = steps.length - 1 === currentStep;

  return (
    <>
      <MondayButton
        {...{
          loading: isLoading,
          disabled: isLoading,
          className: isEnabled ? "mondayButtonRed" : "mondayButtonBlue",
          Icon: <LockFilled />,
          onClick: () => {
            if (isEnabled) {
              setDisableWarning(true);
              // disableTotp();
              return;
            }
            setUpTotpHandler();
            setOpen(true);
          },
        }}
      >
        {isEnabled ? "Disable 2FA" : "Enable 2FA"}
      </MondayButton>
      {open && (
        <CustomModal
          title="Two-factor authentication (2FA)"
          open={open}
          setOpen={setOpen}
          customFooter={
            <Flex className={styles.footerButtons}>
              {currentStep !== 0 && (
                <MondayButton
                  className="mondayButtonBlue"
                  onClick={() => handleStepChange(-1)}
                >
                  Back
                </MondayButton>
              )}

              {isLastStep ? (
                <MondayButton
                  className="mondayButtonGreen"
                  loading={isLoading}
                  onClick={() =>
                    verifyCode(form.getFieldValue("code")).then(() =>
                      setOpen(false)
                    )
                  }
                >
                  Verify
                </MondayButton>
              ) : (
                <>
                  <MondayButton
                    onClick={() => setOpen(false)}
                    className="mondayButtonRed"
                  >
                    Cancel
                  </MondayButton>
                  <MondayButton onClick={() => handleStepChange(1)}>
                    Next Step
                  </MondayButton>
                </>
              )}
            </Flex>
          }
        >
          <Form form={form}>
            <Flex className={styles.information}>
              {!qrErrorMessage ? (
                <>{isLoading ? <div>Loading</div> : <Component />}</>
              ) : (
                <Alert
                  message={qrErrorMessage}
                  type="error"
                  action={
                    <Button
                      size="small"
                      type="text"
                      danger
                      onClick={setUpTotpHandler}
                    >
                      Try again
                    </Button>
                  }
                />
              )}
            </Flex>
          </Form>
        </CustomModal>
      )}

      {disableWarning && (
        <RedWarningModal
          {...{
            visible: disableWarning,
            onCancel: () => setDisableWarning(false),
            footerProps: {
              onConfirm: () => {
                setConfirmCodeOpen(true);
                setDisableWarning(false);
              },
              saveText: "Got it, disable 2FA",
              closeText: "Don't disable 2FA",
            },
            titleText: "Disable Two Factor Authentication Confirmation",
          }}
        >
          <p>
            Hi <b>{userConfiguration.nameOfUser}</b>, you're about to disable
            Two-Factor Authentication (2FA). Please ensure that your login
            credentials remain confidential and secure. Avoid sharing your
            username, password, or any security codes with anyone.
          </p>
        </RedWarningModal>
      )}

      {confirmCodeOpen && (
        <ConfirmationCodeModal
          isLoading={isLoading}
          onConfirm={disableTotp}
          onCancel={() => {
            setConfirmCodeOpen(false);
            setDisableWarning(false);
          }}
        />
      )}
    </>
  );
};

export default TwoFactorAuthenticationChange;
