import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { appliedRentPliCalculator } from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalFooter/RentalBreakdownFooterFunctions";
import {
  getPriceChargedCredited,
  getServiceLegacyPriceWithoutTax,
  getServiceLegacyTaxAmount,
} from "../../../../../utils/index";

const calculatePriceDetails = (oldPrice, serviceItem, taxChange) => {
  const isChargedCredited = getPriceChargedCredited(serviceItem?.charges) || 0;
  const maxAllowedPrice = oldPrice - isChargedCredited;
  const maxPercentage = (maxAllowedPrice / oldPrice) * 100;
  const currentPrice = !serviceItem.selected === true ? maxAllowedPrice : 0;
  if (!!taxChange) {
    const price = serviceItem.price;
    const taxAmount = serviceItem.taxAmount > 0 ? 0 : price * (taxChange || 0);

    return {
      ...serviceItem,
      taxAmount: taxAmount,
      totalPrice: price + taxAmount,
    };
  }
  return {
    ...serviceItem,
    price: currentPrice,
    totalPrice: currentPrice,
    pricePercent: !serviceItem.selected === true ? maxPercentage : 0,
    taxAmount: serviceItem.isTaxable
      ? currentPrice * (serviceItem.taxRate || 0)
      : 0,
    selected: !serviceItem.selected,
  };
};

export const onChargeCheckBoxChange = (
  setChargeToSave,
  selectedService,
  initialService,
  selectedElevation,
  selectedElevationId,
  type,
  data,
  taxChange = false
) => {
  setChargeToSave((prev) => {
    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) => {
            if (service.label === selectedService) {
              let serviceOptions = service.serviceOptions;
              let serviceAddons = service.serviceAddons;
              let additionalRentalTerms = service.additionalRentalTerms;
              switch (type) {
                case "services":
                  serviceOptions = service.serviceOptions.map(
                    (serviceOption, serviceOptionIndex) =>
                      serviceOption.map((serviceElevation, serviceIndex) => {
                        if (
                          serviceElevation.elevationLabel ===
                            selectedElevation &&
                          serviceElevation.elevationId === selectedElevationId
                        )
                          if (service.label === "Hoist" || service.isHoist) {
                            const item =
                              initialService.serviceOptions[serviceOptionIndex][
                                serviceIndex
                              ];
                            const oldPrice =
                              forceToNumber(item.price) +
                              (forceToNumber(item.pliAddonTotal) || 0);
                            return calculatePriceDetails(
                              oldPrice,
                              serviceElevation,
                              taxChange ? service.taxRate : 0
                            );
                          } else {
                            return {
                              ...serviceElevation,
                              items: serviceElevation.items.map(
                                (serviceItem, serviceItemIndex) => {
                                  if (serviceItem.id === data.id) {
                                    const item =
                                      initialService.serviceOptions[
                                        serviceOptionIndex
                                      ][serviceIndex].items[serviceItemIndex];
                                    const oldPrice =
                                      forceToNumber(item.price) +
                                        (forceToNumber(item.pliAddonTotal) ||
                                          0) || forceToNumber(item.Price);
                                    const newServiceItem =
                                      service.isScope && service.isTaxable
                                        ? {
                                            ...serviceItem,
                                            taxRate: service.taxRate,
                                          }
                                        : serviceItem;
                                    return calculatePriceDetails(
                                      oldPrice,
                                      newServiceItem,
                                      taxChange ? service.taxRate : 0
                                    );
                                  }
                                  return serviceItem;
                                }
                              ),
                            };
                          }
                        return serviceElevation;
                      })
                  );
                  break;

                case "Elevation Addons":
                  serviceOptions = service.serviceOptions.map(
                    (serviceOption, serviceOptionIndex) =>
                      serviceOption.map((serviceElevation, serviceIndex) => {
                        return {
                          ...serviceElevation,
                          ...(serviceElevation.elevationAddons && {
                            elevationAddons:
                              serviceElevation.elevationAddons.map(
                                (elevation, elevationIndex) => {
                                  if (elevation.id === data.id) {
                                    const item =
                                      initialService.serviceOptions[
                                        serviceOptionIndex
                                      ][serviceIndex].elevationAddons[
                                        elevationIndex
                                      ];
                                    const oldPrice = forceToNumber(
                                      item.includedTax.priceWithoutTax
                                    );

                                    return calculatePriceDetails(
                                      oldPrice,
                                      elevation,
                                      taxChange ? service.taxRate : 0
                                    );
                                  }
                                  return elevation;
                                }
                              ),
                          }),
                        };
                      })
                  );

                  break;
                case "Service Addons":
                  serviceAddons = service.serviceAddons.map((addon) => {
                    if (addon.id === data.id) {
                      const defaultValue =
                        addon?.includedTax?.priceWithoutTax ||
                        addon.totalPrice ||
                        0;
                      return calculatePriceDetails(
                        defaultValue,
                        addon,
                        taxChange ? service.taxRate : false
                      );
                    }
                    return addon;
                  });
                  break;
                case "Additional Rental":
                  additionalRentalTerms =
                    service?.additionalRentalTerms &&
                    Object.keys(service?.additionalRentalTerms)
                      ? (() => {
                          const rentalTerms = service?.additionalRentalTerms;
                          const priceWithoutTax =
                            rentalTerms.newPrice /
                            (1 + rentalTerms?.taxRate || 1);
                          return calculatePriceDetails(
                            priceWithoutTax,
                            rentalTerms,
                            taxChange ? service.taxRate : 0
                          );
                        })()
                      : {};

                  break;

                default:
                  break;
              }
              let price = getServiceLegacyPriceWithoutTax({
                ...service,
                serviceOptions,
                serviceAddons,
                additionalRentalTerms,
              });
              let taxAmount = getServiceLegacyTaxAmount({
                ...service,
                serviceOptions,
                serviceAddons,
                additionalRentalTerms,
              });
              return {
                ...service,
                serviceOptions,
                serviceAddons,
                additionalRentalTerms,
                price,
                taxAmount,
              };
            } else {
              return service;
            }
          })
        : [],
    };
  });
};

export const onChargeRentalCheckBoxChange = (
  setChargeToSave,
  selectedService,
  initialService,
  selectedElevation,
  selectedElevationId,
  type,
  data,
  taxChange = false
) => {
  setChargeToSave((prev) => {
    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) => {
            if (service.label === selectedService) {
              let serviceOptions = service.serviceOptions;
              let serviceAddons = service.serviceAddons;
              let additionalRentalTerms = service.additionalRentalTerms;
              switch (type) {
                case "services":
                  serviceOptions = service.serviceOptions.map(
                    (serviceElevation, serviceIndex) => {
                      if (
                        serviceElevation.elevationLabel === selectedElevation &&
                        serviceElevation.elevationId === selectedElevationId
                      )
                        return {
                          ...serviceElevation,
                          items: serviceElevation.items.map(
                            (serviceItem, serviceItemIndex) => {
                              if (serviceItem.id === data.id) {
                                const item =
                                  initialService.serviceOptions[serviceIndex]
                                    .items[serviceItemIndex];
                                const oldPrice = appliedRentPliCalculator({
                                  rentalDetails:
                                    item?.rentalDetails?.details || [],
                                });
                                return calculatePriceDetails(
                                  oldPrice,
                                  serviceItem,
                                  taxChange
                                    ? service?.taxRate || serviceItem.taxRate
                                    : 0
                                );
                              }
                              return serviceItem;
                            }
                          ),
                        };
                      return serviceElevation;
                    }
                  );

                  break;

                default:
                  break;
              }
              let price = getServiceLegacyPriceWithoutTax({
                ...service,
                serviceOptions,
                serviceAddons,
                additionalRentalTerms,
              });
              let taxAmount = getServiceLegacyTaxAmount({
                ...service,
                serviceOptions,
                serviceAddons,
                additionalRentalTerms,
              });
              return {
                ...service,
                serviceOptions,
                serviceAddons,
                additionalRentalTerms,
                price,
                taxAmount,
              };
            } else {
              return service;
            }
          })
        : [],
    };
  });
};

export const onChargeSOVCheckBoxChange = (
  setChargeToSave,
  selectedService,
  initialService,
  selectedElevation,
  selectedElevationId,
  data,
  charge,
  taxChange = false
) => {
  setChargeToSave((prev) => {
    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) => {
            if (
              service.label === selectedService &&
              service.estimationId === charge.estimationId
            ) {
              const isHoist = service.label === "Hoist" || service.isHoist;

              if (isHoist) {
                let serviceOptions = service.serviceOptions;

                serviceOptions = service.serviceOptions.map(
                  (serviceOption, serviceOptionIndex) =>
                    serviceOption.map((serviceElevation, serviceIndex) => {
                      if (
                        serviceElevation.elevationLabel === selectedElevation &&
                        serviceElevation.elevationId === selectedElevationId
                      ) {
                        return {
                          ...serviceElevation,
                          amounts: serviceElevation.amounts.map(
                            (serviceItem, serviceItemIndex) => {
                              if (serviceItem.id === data.id) {
                                const item =
                                  initialService.serviceOptions[
                                    serviceOptionIndex
                                  ][serviceIndex].amounts[serviceItemIndex];
                                const priceWithoutTax =
                                  item.amount /
                                  (1 + item.custom_tax_amount || 0);
                                const oldPrice = forceToNumber(priceWithoutTax);
                                return calculatePriceDetails(
                                  oldPrice,
                                  serviceItem,
                                  taxChange ? service.taxRate : 0
                                );
                              }
                              return serviceItem;
                            }
                          ),
                        };
                      }
                      return serviceElevation;
                    })
                );
                const category = "Schedule Of Value";

                const updatedService = { ...service, serviceOptions };

                let price = getServiceLegacyPriceWithoutTax(
                  updatedService,
                  category
                );
                let taxAmount = getServiceLegacyTaxAmount(
                  updatedService,
                  category
                );
                return {
                  ...updatedService,
                  price,
                  taxAmount,
                };
              } else {
                let amounts = service.amounts;
                amounts = service.amounts.map(
                  (serviceItem, serviceItemIndex) => {
                    if (serviceItem.id === data.id) {
                      const item = initialService.amounts[serviceItemIndex];
                      const priceWithoutTax =
                        item.amount / (1 + item.custom_tax_amount || 0);
                      const oldPrice = forceToNumber(priceWithoutTax);
                      return calculatePriceDetails(
                        oldPrice,
                        serviceItem,
                        taxChange ? service.taxRate : 0
                      );
                    }

                    return serviceItem;
                  }
                );
                const category = "Schedule Of Value";

                const updatedService = { ...service, amounts };

                let price = getServiceLegacyPriceWithoutTax(
                  updatedService,
                  category
                );
                let taxAmount = getServiceLegacyTaxAmount(
                  updatedService,
                  category
                );
                return {
                  ...updatedService,
                  price,
                  taxAmount,
                };
              }
            } else {
              return service;
            }
          })
        : [],
    };
  });
};
