import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Radio } from "antd";
import { XIcon } from "../SidebarPages/Communication/assets";
import { InfoText } from "../commonComponents/CustomModalHeader/CustomModalHeader";
import {
  BarChart,
  ColumnChart,
  DoughnutChart,
  PieChart,
} from "../SidebarPages/BasePage/src";
import { MondayButton } from "../commonComponents";
import { configureCharts } from "../SidebarPages/components/AgChart/configureCharts";
import { InputComponent } from "../SidebarPages/Fleet/components";
import { generateDatePeriods } from "../../utils";

const types = [
  {
    title: "Column Chart",
    def: "column",
    icon: <ColumnChart height={13} width={13} />,
  },
  {
    title: "Bar Chart",
    def: "bar",
    icon: <BarChart height={13} width={13} />,
  },
  {
    title: "Pie Chart",
    def: "pie",
    icon: <PieChart height={13} width={13} />,
  },
  {
    title: "Doughnut Chart",
    def: "doughnut",
    icon: <DoughnutChart height={13} width={13} />,
  },
];

const ChartModal = ({
  open,
  onCancel,
  data,
  onDateChange,
  chartingOptions = [],
  onSubmit,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedChartType, setSelectedChartType] = useState("column");
  const [selectedChartParam, setSelectedChartParam] = useState(
    chartingOptions[0]?.optionTitle || ""
  );

  const dateOptions = generateDatePeriods();

  const getRadioGroups = () => {
    let tmp = [];
    for (let i = 0; i < chartingOptions?.length; i++) {
      let el = chartingOptions[i];
      tmp.push(
        <Radio key={i} value={el?.optionTitle}>
          {el?.optionTitle}
        </Radio>
      );
    }
    return tmp;
  };

  const changeChartOption = (value) => {
    setSelectedChartParam(value);
  };

  const changeDate = (value, options) => {
    const newStartDate = options.startDate.format("YYYY-MM-DD");
    const newEndDate = options.endDate.format("YYYY-MM-DD");

    onDateChange(newStartDate, newEndDate);
  };

  const handleOk = () => {
    if (chartingOptions.length < 1) {
      return onCancel();
    }

    let tmp = chartingOptions?.find(
      ({ optionTitle }) => optionTitle === selectedChartParam
    );

    const type = tmp.hasOwnProperty("calculate") ? "calc" : "count";

    const result = configureCharts(
      data[tmp.key],
      selectedChartType,
      tmp.params,
      type
    );

    onSubmit(result);
  };

  return (
    <Modal
      title="Chart options"
      open={open}
      onCancel={onCancel}
      onOk={handleOk}
      closeIcon={<XIcon />}
      className={`chartModal ${isDarkMode ? "chartModalDark" : ""}`}
    >
      <div className="chartInfo"></div>
      {chartingOptions.length > 0 ? (
        <>
          <InfoText
            {...{
              isDarkMode,
              text: "Select a chart appearance and one of the options below them. This includes choosing the chart type and the time frame for data such as total number of users by department, role, and device type used for logging in.",
            }}
          />
          <div className="chartType">
            {types.map(({ title, def, icon }, i) => (
              <MondayButton
                key={title}
                className={
                  selectedChartType !== def ? "unactiveButton" : "activeButton"
                }
                onClick={() => setSelectedChartType(def)}
                Icon={icon}
              >
                {title}
              </MondayButton>
            ))}
          </div>
          <Radio.Group
            className="chartOption"
            onChange={(e) => changeChartOption(e.target.value)}
            defaultValue={chartingOptions[0].optionTitle}
          >
            {getRadioGroups()}
          </Radio.Group>
          <InputComponent
            style={{ textAlign: "center" }}
            allowClear={false}
            type="select"
            showSearch={true}
            dropdownClassName={isDarkMode && "darkDropDown"}
            placeholder={`Select...`}
            defaultValue={"All Time"}
            customOptions={dateOptions}
            onChange={(value, options) => changeDate(value, options)}
          />
        </>
      ) : (
        <span style={{ color: isDarkMode ? "#fff" : "#000" }}>
          No charting options available.
        </span>
      )}
    </Modal>
  );
};

export default ChartModal;
