import { InboxOutlined } from "@ant-design/icons";

const UPLOAD_MESSAGE = " Click or drag file to this area to upload ";
const UPLOAD_INFO_MESSAGE = 'Files must contain "Sheet1" sheet';

const UploadStepMessage = () => {
  return (
    <>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        {UPLOAD_MESSAGE}
        <strong>{UPLOAD_INFO_MESSAGE}</strong>
      </p>
    </>
  );
};

export default UploadStepMessage;
