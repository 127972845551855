import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { Tick } from "../../../../../Roles/src";
import {
  MondayButton,
  WarningModal,
} from "../../../../../../../../commonComponents";
import { WarningTriangle } from "../../../../../../../../SidebarPages/DynamicView/src";

const LogOutModal = ({ logOutModal, setLogOutModal, onGoogleSignOut }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const signOut = (e) => {
    e.stopPropagation();
    onGoogleSignOut();
    setLogOutModal(false);
  };

  return (
    <WarningModal
      wrapClassName="confirmModalOnRouteChange"
      title="Warning"
      visible={logOutModal}
      closable={false}
      className="logout-warning-modal"
      darkMode={isDarkMode}
    >
      <div className="logout-modal-body">
        <span>
          <WarningTriangle />
        </span>
        <p>Are you sure you want to sign out of your Google account?</p>
        <div className="buttons">
          <MondayButton
            Icon={<XIcon />}
            onClick={() => setLogOutModal(false)}
            className="mondayButtonRed"
          >
            Cancel
          </MondayButton>
          <MondayButton
            className="confirmDeletion"
            onClick={signOut}
            Icon={<Tick width={19} height={19} />}
            data-testid="sign-out-testid"
          >
            Sign Out
          </MondayButton>
        </div>
      </div>
    </WarningModal>
  );
};

export default LogOutModal;
