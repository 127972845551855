import React from "react";

const ReportInfo = ({ icon, description, value, onClick }) => {
  const onInfoClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <p>
      <span className="metadataIcon">{icon && icon}</span>
      <span
        className={`${onClick ? "metadataLink" : ""}`}
        onClick={onInfoClick}
      >
        {description}
      </span>
      <b>{value}</b>
    </p>
  );
};

export default ReportInfo;
