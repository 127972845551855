const multipleDispatchesColumns = [
  {
    headerName: "Project Address",
    field: "address",
    checkboxSelection: true,
  },
  {
    headerName: "Client Name",
    field: "differentiatorName",
  },
];

export default multipleDispatchesColumns;
