import { Leaf } from "..";

function renderTreeData(data, saveDisabled, fromDoc) {
  return data.map((el) => {
    const {
      docType = "",
      folderId = "",
      googleDriveUploads = [],
      docId = "",
      statusAfterUpdate = null,
      name = "",
      status = "",
      id = "",
      src = "",
    } = el;

    const isFromDoc = fromDoc;

    const titleProps = {
      name: isFromDoc ? docType : name,
      status: isFromDoc ? "No folder created" : status,
      color: isFromDoc
        ? "#FE4C4A"
        : status === "Not in Drive"
        ? "#FE4C4A"
        : "#F6CB51",
      hasStatus: isFromDoc ? !folderId || statusAfterUpdate === "Fixed" : true,
      isTitle: true,
      statusAfterUpdate,
      saveDisabled,
      src,
    };

    return {
      key: isFromDoc ? docId : id,
      title: <Leaf {...titleProps} />,
      children: isFromDoc
        ? googleDriveUploads.map(
            ({ name = "", status, id = "", statusAfterUpdate = "" }) => {
              return {
                key: id,
                title: (
                  <Leaf
                    {...{
                      name,
                      status,
                      color: status === "Not in Drive" ? "#FE4C4A" : "#F6CB51",
                      hasStatus: true,
                      isTitle: false,
                      statusAfterUpdate,
                      saveDisabled,
                    }}
                  />
                ),
              };
            }
          )
        : null,
    };
  });
}
export default renderTreeData;
