import React, { memo, useEffect, useState } from "react";
import "./CustomProgressBar.scss";
import { isBetweenPercentage } from "./customProgressHelpers";

/**
 * Progress bar with animation
 * @constructor
 * @param {number} progressDone - The number of percentage.
 */
export const CustomProgressBar = ({
  progressDone,
  breakDownProp,
  smallBar,
  onClick = () => {},
}) => {
  const [style, setStyle] = useState({});
  const [barStyle, setBarStyle] = useState({});

  useEffect(() => {
    setTimeout(() => {
      const progressBarStyle = {
        opacity: 1,
        width:
          progressDone <= 20
            ? "30%"
            : `${progressDone >= 100 ? 100 : progressDone}%`,
        backgroundColor:
          progressDone === 0
            ? "#fe4c4a"
            : isBetweenPercentage(progressDone)?.barBackground,
        color: "#fff",
      };
      const wrapperBackgroundStyle = {
        backgroundColor:
          progressDone === 0
            ? "#f8a09f"
            : isBetweenPercentage(progressDone)?.wrappBackground,
        cursor: onClick ? "pointer" : "default",
      };
      setBarStyle(progressBarStyle);
      setStyle(wrapperBackgroundStyle);
    }, 100);
  }, [progressDone]);

  return (
    <div
      onClick={onClick}
      className={
        !breakDownProp
          ? smallBar
            ? "custom-progress-bar-small"
            : "custom-progress-bar"
          : "breakdown-prop-progress"
      }
      style={style}
    >
      <div
        key={progressDone}
        className={!breakDownProp ? "progress-done" : "breakdown-progress-done"}
        style={barStyle}
      >
        {`${Number(progressDone)}%`}
      </div>
    </div>
  );
};

export default CustomProgressBar;
