import { useState } from "react";
import { DeleteIcon } from "../../../../../DynamicView/src";
import { RedWarningModal } from "../../../../../../commonComponents";
import "./DeleteCategory.scss";

export const DeleteCategory = ({ data, onDeleteConfiguration }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <DeleteIcon
        style={{ cursor: "pointer" }}
        fill="#FE4C4A"
        onClick={() => {
          setVisible(true);
        }}
      />

      {visible && (
        <RedWarningModal
          {...{
            visible: visible,
            onCancel: () => {
              setVisible(false);
            },
            titleText: "Confirm Deletion",
            TitleIcon: DeleteIcon,
            footerProps: {
              onConfirm: () => onDeleteConfiguration(data?.categoryName),
            },
            deleteModal: true,
          }}
        >
          <div className="deleteCategory-text">
            <p className="deleteCategory-name">
              Are you sure you want to delete <b>{data?.categoryName}</b> ?
            </p>
            <p>
              This action is irreversible and will permanently remove all
              associated data.
            </p>
          </div>
        </RedWarningModal>
      )}
    </>
  );
};
