import { Tick } from "../../../../../../../../../../DynamicView/components/FilterView/FilterIcons";
import {
  useStateContext,
  useDispatchContext,
} from "../../../../../../Context/Context";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { TemplatesIcon } from "../../../../../../../../../../BasePage/src";
import { DropdownIcon } from "../../../../../../../../../../BasePage/src";
import { roundToDecimals } from "../../../../../../../../../../utils";
import { Close } from "../../../../../../../../../../DynamicView/src";
import { getErrors } from "../../../../utils/getErrors";
import "./events-modal.scss";
import { message } from "antd";
import { Modal, Select } from "antd";
import { useState } from "react";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const { Option } = Select;

const EventsModal = ({ service, serviceEvents }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Context state & dispatch
  const { fakeSOV, darkMode, isWritable } = useStateContext();
  const dispatchContext = useDispatchContext();

  // Save and close modal with selected event
  const chooseDefaultSOVEvents = () => {
    const newSOV = {
      ...fakeSOV,
      // Find the service that we are adding amounts to
      services: fakeSOV?.services.map((sovService) =>
        sovService.serviceId.toString() === service.serviceId.toString() &&
        sovService.estimationId === service.estimationId
          ? {
              ...sovService, // Spread service properties that dont need to change
              typeOfSOV: selectedEvent ? selectedEvent : sovService.typeOfSOV, // If none is selected, get type that it already have
              amounts: selectedEvent // Find template that we have selected from dropdown
                ? serviceEvents?.scheduleOfValues[selectedEvent]?.events?.map(
                    (event, index, arrayOfEvents) => {
                      // Get events of that template
                      let rate = event.rate; // Initial rate of event
                      let amount = roundToDecimals(
                        (sovService.totalPrice * rate) / 100,
                        2
                      ); // Calculate amount based on rate
                      let i = index; // Initialize index
                      let total = sovService.totalPrice; // Initial total is equal to total price
                      let notes = event.notes; // this get notes from sov template

                      // Calculate total for rows other than first
                      while (i > 0) {
                        // If we are on row other than first one
                        total = roundToDecimals(
                          total -
                            roundToDecimals(
                              (sovService.totalPrice *
                                arrayOfEvents[i - 1].rate) /
                                100,
                              2
                            ),
                          2
                        ); // Subtract all previous amounts from total price
                        i--; // Until we reach the current row
                      }

                      let difference = roundToDecimals(total - amount, 2); // Calculate difference

                      const totalRates = arrayOfEvents.reduce(
                        (acc, cur) => roundToDecimals(acc + cur.rate, 2),
                        0
                      );
                      // If we are in the last row and we have some amount left add it to the last event
                      if (
                        index === arrayOfEvents.length - 1 &&
                        (difference > 0 || totalRates < 100)
                      ) {
                        amount = roundToDecimals(amount + difference, 2); // Add the remaining amount to amount
                        rate = rate + (100 - totalRates); // Calculate new rate based on new amount
                        difference = 0; // Make difference 0
                      }

                      const retainage = roundToDecimals(
                        (amount * service?.retainage) / 100,
                        2
                      ); // Calculate retainage
                      const collectable_amount = roundToDecimals(
                        amount - retainage,
                        2
                      ); // Get collectable amount without retainage
                      console.log("sovService", sovService, {
                        taxAmount: forceToNumber(
                          (amount / (1 + service?.taxRate)) * service?.taxRate
                        ),
                        amount,
                        txRate: service?.taxRate,
                      });
                      // Return amounts
                      return {
                        ...event,
                        rate,
                        amount,
                        difference,
                        taxAmount: forceToNumber(
                          (amount / (1 + service?.taxRate)) * service?.taxRate
                        ), //tax by default
                        custom_tax_amount: service?.isTaxable
                          ? forceToNumber(service?.taxRate)
                          : 0, //tax by default
                        retainage,
                        retainagePercentage: service?.retainage, //retenage by default
                        collectable_amount,
                        note: notes,
                      };
                    }
                  )
                : [...sovService.amounts], // If none is selected get amounts that it already have
            }
          : sovService
      ),
    };

    console.log("newSOV", newSOV);

    // Get errors for each service
    const errors = getErrors(newSOV);

    // Set new SOV
    dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });

    // If none of other services have errors set errors to null and save to db, otherwise set errors to errors object of all services
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      dispatchContext({ type: "SET_ERRORS", payload: null });
    } else {
      dispatchContext({ type: "SET_ERRORS", payload: errors });
    }

    setSelectedEvent(null);
    setIsModalVisible(false);
  };

  // Close Modal
  const onCancel = () => setIsModalVisible(false);

  // Select events
  const onChange = (value) => setSelectedEvent(value);

  const events = Object.keys(serviceEvents?.scheduleOfValues || {});

  return (
    <>
      <MondayButton
        className={`changeTemplateSOVButton ${
          isWritable ? "" : "buttonNotClickable"
        }`}
        onClick={() =>
          isWritable
            ? setIsModalVisible(true)
            : message.error("Please enable Edit Mode!")
        }
        Icon={<TemplatesIcon />}
        //disabled={!isWritable}
      >
        {
          // If it is undefined or empty array show select template it it has values show change template
          !!!service?.amounts?.length
            ? "Select Template"
            : service?.typeOfSOV || "Change Template"
        }
      </MondayButton>

      <Modal
        title="Choose which value fits better"
        wrapClassName={
          darkMode
            ? "service-header-events-modal-dark"
            : "service-header-events-modal"
        }
        open={isModalVisible}
        // onOk={chooseDefaultSOVEvents}
        onCancel={onCancel}
        footer={
          <div className="serviceFooterEventModal">
            <MondayButton
              className="cancelMondayButton"
              Icon={<Close />}
              onClick={onCancel}
            >
              Cancel
            </MondayButton>
            <MondayButton
              className="applyMondayButton"
              Icon={<Tick />}
              onClick={chooseDefaultSOVEvents}
            >
              Apply
            </MondayButton>
          </div>
        }
      >
        <Select
          showSearch
          className="service-header-events-selector"
          placeholder="Choose event"
          value={selectedEvent}
          onChange={onChange}
          suffixIcon={<DropdownIcon />}
          popupClassName="service-header-event-selector-dropdown"
        >
          {!!events.length &&
            events.map((event) => (
              <Option key={event} value={event}>
                {event}
              </Option>
            ))}
        </Select>
      </Modal>
    </>
  );
};

export default EventsModal;
