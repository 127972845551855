import { Modal } from "antd";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { MondayButton } from "../../../../../../commonComponents";
import { FloorDetailsProvider } from "../../HoistPriceCalculatorModal/components/FloorDetails/context/floorDetailsContext";
import FloorDetails from "../../HoistPriceCalculatorModal/components/FloorDetails/FloorDetails";

function TableHoistFloorAddons({ indexes }) {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <MondayButton
        hasIcon={false}
        className="mondayButtonBlue"
        onClick={() => {
          // Swal.fire({
          //   title: "Coming Very Soon",
          //   text: "This feature is being tested and will be available to you soon!",
          //   icon: "warning",
          // });
          setVisible(true);
        }}
      >
        FLOOR ADDONS
      </MondayButton>

      {visible && (
        <Modal
          open={visible}
          width={1800}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <FloorDetailsProvider>
            <FloorDetails indices={indexes} />
          </FloorDetailsProvider>
        </Modal>
      )}
    </div>
  );
}

export default TableHoistFloorAddons;
