import { Form } from "antd";
import { API } from "aws-amplify";
import { useState } from "react";
import { useSelector } from "react-redux";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../utils";
import SaveWarning from "./SaveWarning";
import { apiRoutes } from "../../../Fleet/utils";
import VendorAddressMap from "./VendorAddressMap";
import { footerButtons } from "./utils/footerData";
import { bodyObject, fieldsJSON } from "./utils/fieldsJson";
import { ProgressComponent } from "../../../../commonComponents";
import { useEditLogs, useProgressComponent } from "../../../../../hooks";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import { BorderedTextCard, NormalSizedModal } from "../../../Fleet/components";
import { getTaxRate } from "../../../../commonComponents/NextSteps/forms/Opportunity/utils";
import CustomModalHeader from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";

import "./NewVendorModal.scss";

const NewVendorModal = ({
  visible,
  setVisible,
  refreshTable,
  rowData = [],
}) => {
  const [form] = Form.useForm();

  const [isSaving, setIsSaving] = useState();
  const [formChanged, setFormChanged] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [address, setAddress] = useState("");

  const { programFields } = useSelector((state) => state.programFields);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Vendors", actionType: "Create" });

  const { saveAddedLogs } = useEditLogs();

  const statusTypes =
    programFields?.find((field) => field.fieldName === "Status colors")
      ?.fieldOptions?.["Vendors"] || [];

  const vendorTypes =
    programFields?.find((field) => field.fieldName === "Vendor Types")
      ?.fieldOptions || [];

  const vendors = rowData?.map(({ vendorName }) => vendorName);

  const onAddressSelection = async (value) => {
    setAddress(value);

    const taxRate = await getTaxRate(value);

    if (taxRate) form.setFieldValue("vendorTaxRate", taxRate / 100);
  };

  const inputFields = fieldsJSON({
    form,
    address,
    statusTypes,
    vendorTypes,
    onAddressSelection,
  });

  const onSave = async () => {
    try {
      await form.validateFields();

      if (vendors?.includes(form.getFieldValue("vendorName"))) {
        form.setFields([
          { name: "vendorName", errors: ["This vendor name already exists"] },
        ]);

        return;
      }

      showLoadingMsg();
      setIsSaving(true);

      const vendorBody = {
        ...bodyObject({ form }),
      };

      updateProgressStatus({ updatingRecord: "executing" });
      await API.post(apiRoutes.vendors, `/${apiRoutes.vendors}`, {
        body: vendorBody,
      })
        .then(async (result) => {
          refreshTable(result);
          setVisibleCreationProgress({ ...result, action: "onSave" });
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          await saveAddedLogs({
            recordId: result?.vendorId,
            recordName: result?.vendorName,
            category: "Vendors",
          }).catch((err) => console.error("Error creating logs:", err));
        })
        .catch((error) => {
          console.error({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });

      setIsSaving(false);
      setVisible(false);
      showSuccessMsg();
    } catch (error) {
      showErrorMsg({
        content: "All required fields must be filled in before submitting.",
      });
    }
  };
  const onCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <NormalSizedModal
        visible={visible}
        onCancel={() => {
          if (formChanged) {
            setCancelModalVisible(true);
          } else {
            onCancel();
          }
        }}
        title={
          <CustomModalHeader
            title={"New Vendor"}
            // onClick={() => {
            //   tourSteps.length > 0
            //     ? setTourOpen(true)
            //     : message.info("No guide available for this form");
            // }}
            noX={true}
          />
        }
        newFooterDesign={footerButtons({ onSave, onCancel, isSaving })}
        centered
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormChanged(true);
          }}
        >
          <BorderedTextCard
            childrenClassName="new-vendor-text-card"
            title="Vendor Information"
          >
            {RenderDynamicComponents(inputFields)}
          </BorderedTextCard>
        </Form>
        <VendorAddressMap address={address} />
      </NormalSizedModal>

      <SaveWarning
        open={cancelModalVisible}
        setOpen={setCancelModalVisible}
        onCancel={onCancel}
      />

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Vendors",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
            },
          }}
        />
      )}
    </>
  );
};

export default NewVendorModal;
