//this function handle check/uncheck value changer
export const applyAllHandler = ({
  setFakeRentals,
  selectedService,
  elevationData,
  fakeRentals,
}) => {
  // value that we take from the checkbox on change
  const thisElevation = elevationData?.elevationId;

  //this const is used to tell the pc what to do on click
  //extract all days from the selected/elevation and check if all of the days are applied
  //if they are returns false(which makes the un apply function)
  //if they are not returns true(which makes apply function)
  const applied = !fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.flatMap(({ rentalDetails: { details } }) => details)
    ?.flatMap(({ days }) => days)
    ?.every(({ applied }) => !!applied);

  //here we calculate the new rental object which is used to save it
  const newRental = {
    ...fakeRentals,
    //we access only service key bcs thats the only key we want to update
    services: fakeRentals?.services?.map((serviceData) =>
      // check if this is the service that we have selected so we can modify it
      serviceData?.label === selectedService
        ? {
            ...serviceData,
            //we access only serviceOptions key bcs thats the only key we want to update inside the selected service
            serviceOptions: serviceData?.serviceOptions?.map((elevationData) =>
              // check if this is the elevation that we have selected so we can modify it
              elevationData?.elevationId === thisElevation
                ? {
                    ...elevationData,
                    //we access only items key bcs thats the only key we want to update inside the elevation that contains the checkbox
                    items: elevationData?.items?.map((pliData) =>
                      //if this pli is charged we updates only the days that are not charged if have any
                      pliData?.charged
                        ? {
                            ...pliData,
                            rentalDetails: {
                              ...pliData?.rentalDetails,
                              details: pliData?.rentalDetails?.details?.map(
                                (weekData) =>
                                  //if this week is charged we modify only the days that they are not (if any)
                                  weekData?.charged
                                    ? {
                                        ...weekData,
                                        //here we access the days details and check if we can modify any day
                                        days: weekData?.days?.map((daysData) =>
                                          // if this day is charged returns it unchanged
                                          daysData?.charged
                                            ? daysData
                                            : //if this day is not charged we set the ne value for the checkbox for this day
                                              { ...daysData, applied }
                                        ),
                                      }
                                    : //if this week is not charged at all we access and modify all of his days
                                      {
                                        ...weekData,
                                        //here we set the ne value for the checkbox for this week
                                        applied,
                                        days: weekData?.days?.map(
                                          (daysData) => ({
                                            ...daysData,
                                            //here we set the ne value for the checkbox for this day
                                            applied,
                                          })
                                        ),
                                      }
                              ),
                            },
                          }
                        : //if this pli is not charged we updates all of its days
                          {
                            ...pliData,
                            //here we set the value for the apply checkbox on this pli
                            applied,
                            //here we access and modify this pli's rental details
                            rentalDetails: {
                              ...pliData?.rentalDetails,
                              details: pliData?.rentalDetails?.details?.map(
                                (weekData) => ({
                                  ...weekData,
                                  //here we set the ne value for the checkbox for this week
                                  applied,
                                  //here e access and modify all days in this week
                                  days: weekData?.days?.map((daysData) => ({
                                    ...daysData,
                                    //here we set the ne value for the checkbox for this day
                                    applied,
                                  })),
                                })
                              ),
                            },
                          }
                    ),
                  }
                : //if this is not the elevation that the checkbox is on we return it unchanged
                  elevationData
            ),
          }
        : //if this is not the selected service we return it unchanged
          serviceData
    ),
  };

  //saves values on context
  setFakeRentals(newRental);
};
export const chargeAllServiceRent = ({
  setFakeRentals,
  selectedService,
  elevationData,
  fakeRentals,
}) => {
  // value that we take from the checkbox on change
  const thisElevation = elevationData?.elevationId;

  //this const is used to tell the pc what to do on click
  //extract all days from the selected/elevation and check if all of the days are applied
  //if they are returns false(which makes the un apply function)
  //if they are not returns true(which makes apply function)
  const applied = true;

  //here we calculate the new rental object which is used to save it
  const newRental = {
    ...fakeRentals,
    //we access only service key bcs thats the only key we want to update
    services: fakeRentals?.services?.map((serviceData) =>
      // check if this is the service that we have selected so we can modify it
      serviceData?.label === selectedService
        ? {
            ...serviceData,
            //we access only serviceOptions key bcs thats the only key we want to update inside the selected service
            serviceOptions: serviceData?.serviceOptions?.map((elevationData) =>
              // check if this is the elevation that we have selected so we can modify it
              ({
                ...elevationData,
                //we access only items key bcs thats the only key we want to update inside the elevation that contains the checkbox
                items: elevationData?.items?.map((pliData) =>
                  //if this pli is charged we updates only the days that are not charged if have any
                  pliData?.charged
                    ? {
                        ...pliData,
                        rentalDetails: {
                          ...pliData?.rentalDetails,
                          details: pliData?.rentalDetails?.details?.map(
                            (weekData) =>
                              //if this week is charged we modify only the days that they are not (if any)
                              weekData?.charged
                                ? {
                                    ...weekData,
                                    //here we access the days details and check if we can modify any day
                                    days: weekData?.days?.map((daysData) =>
                                      // if this day is charged returns it unchanged
                                      daysData?.charged
                                        ? daysData
                                        : //if this day is not charged we set the ne value for the checkbox for this day
                                          { ...daysData, applied }
                                    ),
                                  }
                                : //if this week is not charged at all we access and modify all of his days
                                  {
                                    ...weekData,
                                    //here we set the ne value for the checkbox for this week
                                    applied,
                                    days: weekData?.days?.map((daysData) => ({
                                      ...daysData,
                                      //here we set the ne value for the checkbox for this day
                                      applied,
                                    })),
                                  }
                          ),
                        },
                      }
                    : //if this pli is not charged we updates all of its days
                      {
                        ...pliData,
                        //here we set the value for the apply checkbox on this pli
                        applied,
                        //here we access and modify this pli's rental details
                        rentalDetails: {
                          ...pliData?.rentalDetails,
                          details: pliData?.rentalDetails?.details?.map(
                            (weekData) => ({
                              ...weekData,
                              //here we set the ne value for the checkbox for this week
                              applied,
                              //here e access and modify all days in this week
                              days: weekData?.days?.map((daysData) => ({
                                ...daysData,
                                //here we set the ne value for the checkbox for this day
                                applied,
                              })),
                            })
                          ),
                        },
                      }
                ),
              })
            ),
          }
        : //if this is not the selected service we return it unchanged
          serviceData
    ),
  };

  //saves values on context
  setFakeRentals(newRental);
};

//here we extract all days from the selected service and save them in an array
const checker = ({ fakeRentals, thisElevation, selectedService }) =>
  fakeRentals?.services
    //here we extract the selected service object
    ?.find(({ label }) => label === selectedService)
    //here we find the elevation object in which the checkbox stands
    ?.serviceOptions?.find(({ elevationId }) => elevationId === thisElevation)
    ?.items?.flatMap(({ rentalDetails: { details } }) => details)
    // and here extract all days from that elevation that we just found
    ?.flatMap(({ days }) => days);

export const checkboxValue = ({
  selectedService,
  elevationData,
  fakeRentals,
  isWritable,
}) => {
  const thisElevation = elevationData?.elevationId;
  //hre are all days we just extracted in checked function
  const data = checker({ fakeRentals, thisElevation, selectedService });

  //check if some of the days are applied
  const someDaysApplied = data?.some(({ applied }) => applied);
  //check if all of the days are applied
  const allDaysApplied = data?.every(({ applied }) => applied);
  //check if some of the days are charged
  const allDaysCharged = data?.every(({ charged }) => charged);

  //if some days are applied returns the checkbox value to true
  const value = someDaysApplied;

  //if some days are applied but not all days are applied returns true
  const indeterminate = someDaysApplied && !allDaysApplied;
  //here we find the reason why the checkbox is disabled
  const disabled =
    //if we dont have any day automatically disable it
    data?.length === 0
      ? "You have to pick the period before modifying it!"
      : //else if all the days are charged again disable it
      allDaysCharged
      ? "All days in this elevation are charged!"
      : //else if the write mode is not on disable it
      !isWritable
      ? "Enable write mode!"
      : //else if non of the conditions is not true it means that all things are ok and we dont disable the checkbox
        "";

  return {
    value,
    disabled,
    indeterminate,
  };
};
