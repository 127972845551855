import types from "../types";

const programFields = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_PROGRAM_FIELDS,
    payload: attributes,
  });
};

export default programFields;

export const updateProgramFieldByName = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_PROGRAM_FIELD_BY_NAME,
    payload: attributes,
  });
};

export const updateProgramFieldById = (attributes) => (dispatch) => {
  dispatch({
    type: types.SET_PROGRAM_FIELD_BY_ID,
    payload: attributes,
  });
};
