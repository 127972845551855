import React from "react";
import { Calendar } from "antd";
import importedDayjs from "dayjs";

import { parseInTz } from "../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const timezone = require("dayjs/plugin/timezone");
importedDayjs.extend(timezone);
const dayjs = importedDayjs.tz;

const styleContainer = {
  content: { padding: "20px" },
  calendar: {
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    borderRadius: "5px",
  },
};

export const periodDatesPickerHandler = (date) => {
  if (!!date) {
    return {
      start: dayjs(date)?.startOf("month")?.valueOf(),
      end: dayjs(date)?.endOf("month").subtract(1, "hour")?.valueOf(),
      dueDate: dayjs(date)?.endOf("month").subtract(1, "hour")?.valueOf(),
    };
  }
  return null;
};

const Application = ({ setInputData }) => {
  function onInputChangeHandler(value, key) {
    setInputData((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <div style={styleContainer.content}>
      <div style={styleContainer.calendar}>
        <Calendar
          data-testid="calendar"
          fullscreen={false}
          mode="year"
          onChange={(date) => {
            onInputChangeHandler(
              periodDatesPickerHandler(parseInTz(date)),
              "datePeriod"
            );
          }}
        />
      </div>
    </div>
  );
};

export default Application;
