import { message } from "antd";
import Decimal from "decimal.js-light";
import { calculateDifference, updateFakeSOV } from "../utils";
import { getErrors } from "../../../../utils/getErrors";
import { roundToDecimals } from "../../../../../../../../../../utils";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const toggleTaxEvent = (
  eventId,
  isWritable,
  isLoading,
  service,
  fakeSOV,
  setSOV,
  setErrors,
  data,
  project
) => {
  console.log("this is the project", project);
  if (!isWritable) {
    message.error("Please enable write mode");
  } else if (isLoading) {
    message.error("Please wait until it finishes updating");
  } else {
    // keep track of the original tax amount
    // Find service that we want to edit
    const unchangedService = structuredClone(
      fakeSOV?.services?.find(
        (sovService) =>
          sovService.serviceId.toString() === service.serviceId.toString() &&
          sovService.estimationId === service.estimationId
      )
    );
    const serviceToEdit = structuredClone(unchangedService);
    const previousTaxAmount = serviceToEdit.amounts[eventId].taxAmount;

    serviceToEdit.amounts[eventId].custom_tax_amount =
      serviceToEdit.amounts[eventId].custom_tax_amount > 0
        ? 0
        : project?.taxRate;

    data.custom_tax_amount = serviceToEdit.amounts[eventId].custom_tax_amount;

    console.log("serviceToEdit", serviceToEdit);
    console.log("amountsss", {
      eventId,
      isWritable,
      isLoading,
      service,
      fakeSOV,
      setSOV,
      setErrors,
      projectRate: project?.taxRate,
    });

    if (serviceToEdit.amounts[eventId].custom_tax_amount > 0) {
      let taxAmount =
        serviceToEdit.amounts[eventId].amount *
        serviceToEdit.amounts[eventId].custom_tax_amount;
      serviceToEdit.amounts[eventId].amount = findTaxAmountGivenCustomTaxAmount(
        serviceToEdit.amounts[eventId].amount,
        serviceToEdit.amounts[eventId].custom_tax_amount
      );
      serviceToEdit.amounts[eventId].taxAmount = taxAmount;
      data.taxAmount = taxAmount;
    } else {
      serviceToEdit.amounts[eventId].amount -= previousTaxAmount || 0;
      serviceToEdit.amounts[eventId].taxAmount = 0;
      data.taxAmount = 0;
    }
    serviceToEdit.amounts[eventId].rate =
      +serviceToEdit.amounts[eventId].amount <= 0 ||
      isNaN(+serviceToEdit.amounts[eventId].amount) // Check if input is valid
        ? 0 // If it is not valid make it 0
        : +serviceToEdit.amounts[eventId].amount > serviceToEdit?.totalPrice // Check if input is greater than total price
        ? 100 // If it is make it 100
        : new Decimal(100)
            .dividedBy(
              new Decimal(serviceToEdit.totalPrice).dividedBy(
                roundToDecimals(+serviceToEdit.amounts[eventId].amount, 2)
              )
            )
            .toFixed();
    const newAmounts = serviceToEdit?.amounts;
    console.log("newAmounts", newAmounts);

    // // Update amounts on grid
    // const newAmounts = !!serviceToEdit?.amounts.length && [
    //   // If there is any event
    //   ...serviceToEdit.amounts.slice(0, eventId), // Copy all events until that row, and delete the row that was right clicked
    //   ...serviceToEdit.amounts
    //     .slice(eventId + 1)
    //     .map((amount) => ({ ...amount, id: amount.id - 1 })), // Copy all other events after the deleted one and decrement their id by 1
    // ]

    // Re calculate all amounts to update other rows based on the row that we have updated
    const amounts = calculateDifference(unchangedService, newAmounts);

    // Find the service that we are editing and update its amounts
    const newSOV = updateFakeSOV(fakeSOV, service, amounts);

    // Get errors for each service
    const errors = getErrors(newSOV);

    // Set new SOV
    setSOV(newSOV);

    // If none of services have errors set errors to null and save to db, otherwise set errors to errors object of all services
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      setErrors(null);
    } else {
      setErrors(errors);
    }
  }
};

export const findTaxAmountGivenCustomTaxAmount = (priceWithoutTax, taxRate) => {
  return priceWithoutTax + forceToNumber(priceWithoutTax * taxRate);
};
