import { getBaseLabelHtml } from "../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/routeFields";
import { LogsIcon } from "../../../../../DynamicView/src";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { TrashIcon, XIcon } from "../../../../../Communication/assets";
import { MondayButton } from "../../../../../../commonComponents";
import ToDoButton from "../../../../../ToDos/components/ToDoButton/ToDoButton";

function isInt(value) {
  let rg = /^[0-9]+$/;
  return rg.test(value) ? true : false;
}

export const inventoryFields = ({
  allItems,
  vendors,
  categories,
  rowObject,
  quantitiesDisabled,
  setTotalCost,
  form,
}) => [
  {
    label: "Part Name",
    placeholder: "Part name",
    formItemName: "itemName",
    required: true,
    type: "input",
    rules: [
      {
        async validator(_, value) {
          if (value === rowObject?.itemName) {
            return Promise.resolve();
          }
          if (
            !!value &&
            !!allItems?.find(
              (el) => el?.itemName?.toLowerCase() === value?.toLowerCase()
            )
          ) {
            return Promise.reject("Item with the same name already exists!");
          } else {
            return Promise.resolve();
          }
        },
      },
    ],
  },
  {
    label: "Part Number",
    placeholder: "Part number",
    formItemName: "itemPartNo",
    required: false,
    required: true,
    type: "input",
    rules: [
      {
        async validator(_, value) {
          if (value === rowObject?.itemPartNo) {
            return Promise.resolve();
          }
          if (
            !!value &&
            !!allItems?.find(
              (el) => el?.itemPartNo?.toLowerCase() === value?.toLowerCase()
            )
          ) {
            return Promise.reject("Item with the same number already exists!");
          } else {
            return Promise.resolve();
          }
        },
      },
    ],
  },
  {
    label: getBaseLabelHtml("Quantity"),
    required: false,
    placeholder: "Quantity",
    formItemName: "itemQuantity",
    type: "input",
    typeNumber: true,
    disabled: quantitiesDisabled,
    rules: [
      {
        async validator(_, value) {
          if (!isInt(value)) {
            return Promise.reject("Please enter a valid quantity");
          } else {
            return Promise.resolve();
          }
        },
      },
      { required: true, message: "" },
    ],
  },
  {
    label: "Cost per unit",
    placeholder: "Cost per unit",
    formItemName: "costPerUnit",
    type: "input",
    // typeNumber: true,
    required: true,
    disabled: quantitiesDisabled,
    rules: [{ transform: (value) => value?.replace(/[^0-9,.]/g, "") }],
    addonBefore: "$",
    onBlur() {
      const formattedValue = `${parseFloat(
        form.getFieldValue("costPerUnit")?.replace(/[^0-9,.]/g, "")
      )?.toFixed(2)}`
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        .replace(/\.(?=\d{0,2}$)/g, ".");

      if (formattedValue !== "NaN") {
        form.setFieldValue("costPerUnit", formattedValue);
      }
    },
    onChange(e) {
      const costValue = parseFloat(e.target.value?.replace?.(/[^0-9.]/g, ""));

      form.setFieldValue(
        "costPerUnit",
        e.target.value?.replace(/[^0-9,.]/g, "")
      );

      if (costValue) {
        setTotalCost(costValue);
      }
    },
  },
  {
    label: getBaseLabelHtml("Critical Quantity"),
    required: false,
    placeholder: "Critical Quantity",
    formItemName: "criticalQuantity",
    type: "input",
    typeNumber: true,
    rules: [
      {
        async validator(_, value) {
          if (!isInt(value)) {
            return Promise.reject("Please enter a valid quantity");
          } else {
            return Promise.resolve();
          }
        },
      },
      {
        required: true,
        message: "",
      },
    ],
  },
  {
    label: "Categories",
    placeholder: "Type to add or search categories",
    formItemName: "itemCategory",
    type: "select",
    customOptions: categories,
    showSearch: true,
  },
  {
    label: "Vendor",
    placeholder: "Vendor's name",
    formItemName: "vendorId",
    type: "select",
    customOptions: vendors,
  },
];

export const additionalFields = ({ index, disabled }) => [
  {
    label: getBaseLabelHtml("Quantity"),
    required: false,
    formItemName: `newQuantity#${index}`,
    type: "input",
    typeNumber: true,
    disabled,
    rules: [
      {
        async validator(_, value) {
          if (!isInt(value)) {
            return Promise.reject("Please enter a valid quantity");
          } else {
            return Promise.resolve();
          }
        },
      },
      {
        required: true,
        message: "",
      },
    ],
  },
  {
    label: "Cost per Unit",
    formItemName: `newCost#${index}`,
    type: "input",
    typeNumber: true,
    formatter: (e) => {
      return `${e}`
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        ?.replace(/\.(?=\d{0,2}$)/g, ",");
    },
    disabled,
    required: true,
    addonBefore: "$",
  },
];

const tooltipCategory = "Fleet Inventory";

export const footerButtons = ({
  setWarning = () => {},
  setLogsVisible = () => {},
  saveHandler = () => {},
  validateForm = () => {},
  form,
  editMode = false,
  onCancel = () => {},
  changes = false,
  recordId = "",
  recordName = "",
}) => [
  <MondayButton
    className="mondayButtonRed"
    Icon={<XIcon />}
    onClick={() => (changes ? setWarning(true) : onCancel())}
    tooltipCategory={tooltipCategory}
    tooltipKey="cancel"
  >
    Cancel
  </MondayButton>,
  <div className="footerButtonsContainer">
    {editMode && (
      <>
        <ToDoButton {...{ recordId, recordName, tooltipCategory }} />
        <MondayButton
          type="primary"
          className="mondayButtonBlue"
          Icon={<LogsIcon fill="#fff" />}
          onClick={() => {
            setLogsVisible(true);
          }}
          tooltipCategory={tooltipCategory}
          tooltipKey="showLogs"
        >
          Show Logs
        </MondayButton>
        <MondayButton
          className="mondayButtonRed"
          Icon={<TrashIcon fill="#fff" />}
          onClick={() => {
            setWarning("delete");
          }}
          tooltipCategory={tooltipCategory}
          tooltipKey="delete"
        >
          Delete
        </MondayButton>
      </>
    )}
    <MondayButton
      onClick={() => {
        validateForm(form, saveHandler);
      }}
      Icon={<TickIcon width={17} height={17} />}
      tooltipCategory={tooltipCategory}
      tooltipKey="save"
    >
      Confirm
    </MondayButton>
  </div>,
];
