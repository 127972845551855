import "./TutorialsStep.scss";
import {
  TutorialIconCircle,
  TutorialIconCircleDark,
  TutorialsIcons,
  TutorialsIconsDark,
} from "../../assets/icons";
import BoardingNavigationBtn from "../BoardingNavigationBtn/BoardingNavigationBtn";
import { useSelector } from "react-redux";

const circleStyle = { position: "absolute", top: "-15px", left: "-10px" };

const TutorialsStep = () => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div className="tutorials-step step-lines">
      <div style={{ position: "relative" }}>
        {isDarkMode ? <TutorialsIconsDark /> : <TutorialsIcons />}
        {isDarkMode ? (
          <TutorialIconCircleDark style={circleStyle} />
        ) : (
          <TutorialIconCircle style={circleStyle} />
        )}
      </div>
      <span style={{ textAlign: "center" }}>
        Do not forget to explore our video tutorials for{" "}
        <span style={{ fontWeight: 600 }}>step-by-step guidance </span> on
        mastering our software's features. <br /> The button can be found
        <span style={{ fontWeight: 600 }}>
          {" "}
          on top of the main dashboard.
        </span>{" "}
        If you’re done here, feel free to save changes!
      </span>

      <BoardingNavigationBtn />
    </div>
  );
};

export default TutorialsStep;
