import { ReportPreviewCard } from "../../../../components";
import { useReportOpener } from "../../../../hooks";
import { useGeneralReportsContext } from "../../context";
import { useLocation } from "react-router-dom";
import ReportView from "../../../../view/ReportView";
import { preferences as preferencesDispatch } from "../../../../../../../actions/preferences";
import "./GeneralReportsContainer.scss";
import { useSelector } from "react-redux";
import { message } from "antd";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";

const GeneralReportsContainer = ({ isDarkMode }) => {
  let location = useLocation();
  const {
    recordId,
    activeFilters,
    partitionKeys,
    reportsAvailable = [],
  } = useGeneralReportsContext();

  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const dispatch = useDispatch();
  const finalReportPrefs = allPreferences?.preferences?.reportPreferences || {};

  const locationPathname = location.pathname.replace("/", "");

  const onFavoriteClick = (ID) => {
    message.loading("Saving to favourites");
    const updatedPreferences = {
      ...(allPreferences?.preferences || {}),
      reportPreferences: { ...finalReportPrefs, [ID]: !finalReportPrefs?.[ID] },
    };

    dispatch(
      preferencesDispatch({
        ...allPreferences,
        preferences: updatedPreferences,
      })
    );

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        message.success("Changes Saved In Your Preferences!");
      })
      ?.catch((err) => {
        dispatch(
          preferencesDispatch({
            ...allPreferences,
          })
        );
        console.error("Error saving to preferences", err);
      });
  };

  const reportOpener = useReportOpener(locationPathname);

  const openReport = (e, report) => {
    if (e.defaultPrevented) return;
    reportOpener(report.categoryName, report.reportId, {
      report,
      activeFilters,
      recordId,
      partitionKeys,
      tabPosition: "Reports",
      tab: "Reports",
    });
  };

  return (
    <>
      <div className="general-reports-container">
        {reportsAvailable && reportsAvailable.length > 0 ? (
          <div className="general-reports-cards">
            {reportsAvailable
              .filter(({ useInAutomation }) => !useInAutomation)
              .map((report) => (
                <ReportPreviewCard
                  key={report.reportId}
                  report={report}
                  onClick={openReport}
                  isDarkMode={isDarkMode}
                  favouriteCard={finalReportPrefs?.[report.reportId]}
                  onFavoriteClick={onFavoriteClick}
                />
              ))}
          </div>
        ) : (
          <div className="general-no-reports">
            <h2 className="general-no-report-title">No Reports Available</h2>
          </div>
        )}
      </div>
      <ReportView />
    </>
  );
};

export default GeneralReportsContainer;
