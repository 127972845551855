import {
  ExportOrEmailDropdown,
  MondayButton,
  WithTooltip,
} from "../../../../../../commonComponents";
import { InputAndSelect } from "../../../../../../commonComponents/InputAndSelect/InputAndSelect";

import { Switch } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const Header = ({
  gridColumnApi,
  gridApi,
  searchText,
  setFieldSelected,
  onFilterTextChange,
  getColumnDefs,
  modalHandler,
  switchData,
  getNonGeofences,
  rowData,
  setSwitchData,
  setFilters,
  setShowFilter,
  getGeofences,
}) => {
  return (
    <>
      <div className="headerElements">
        <span className="recordsWithGeofencesHeaderText">All Geofences</span>
        <span>
          <InputAndSelect
            {...{
              onFilterTextChange,
              searchText,
              columnDefs: getColumnDefs({ modalHandler }),
              setFieldSelected,
            }}
          />
        </span>
        <span>
          <MondayButton
            className="mondayButtonBlue"
            Icon={<FilterOutlined />}
            onClick={() => setShowFilter(true)}
            tooltipKey="PortalOpenFilters"
          >
            Open Filter
          </MondayButton>
        </span>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div className="actions-wrapper">
          <div className="switchs-content">
            <div
              className="switch-content"
              style={{
                background: switchData ? "#fe4c4a" : "#01c06a",
              }}
            >
              <span className="switch-label">
                {switchData ? (
                  <div className="columnVisibility">
                    Switch to records that have Geofence ({getGeofences?.length}
                    )
                  </div>
                ) : (
                  <div className="columnVisibility">
                    Switch to records that dont have Geofence (
                    {getNonGeofences?.length})
                  </div>
                )}
              </span>
              <div className={`switcher switcher`}>
                <WithTooltip tooltipKey="PortalSwitcher">
                  <Switch
                    onClick={(value) => {
                      setSwitchData(value);
                      setFilters({});
                    }}
                  />
                </WithTooltip>
              </div>
            </div>
          </div>
        </div>

        <ExportOrEmailDropdown
          {...{
            rowData: rowData,
            defaultDocDefinition: !switchData && true,
            title: "Geofence",
            tooltipKey: "PortalExportActions",
            position: "absolute",
            gridApi: gridApi,
            gridColumnApi: gridColumnApi,
          }}
        />
      </div>
    </>
  );
};

export default Header;
