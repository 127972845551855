import { forwardRef, useRef, useState } from "react";
import {
  Viewer as ReportViewer,
  Designer,
} from "@grapecity/activereports-react";

import { MultiLevelTreeLogs } from "../../../../commonComponents/index";

import "./ReportDesigner.scss";
import "@grapecity/activereports/styles/ar-js-designer.css";

const ReportDesigner = forwardRef((props, ref) => {
  const { reportName, style, newEditLog, className, isDarkMode, ...rest } =
    props;

  const viewer = useRef();

  const [editLogs, setEditLogs] = useState([]);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [designerVisible, setDesignerVisible] = useState(true);

  const reportList = props.reportsAvailable?.map((report) => ({
    ...report,
    displayName: report.reportName,
    id: report.reportId,
  }));

  function updateToolbar() {
    var designButton = {
      key: "$openDesigner",
      text: "Edit in Designer",
      iconCssClass: "mdi mdi-pencil",
      enabled: true,
      action: () => {
        setDesignerVisible(true);
      },
    };
    viewer.current.toolbar.addItem(designButton);
    viewer.current.toolbar.updateLayout({
      default: [
        "$openDesigner",
        "$split",
        "$navigation",
        "$split",
        "$refresh",
        "$split",
        "$history",
        "$split",
        "$zoom",
        "$fullscreen",
        "$split",
        "$print",
        "$split",
        "$singlepagemode",
        "$continuousmode",
        "$galleymode",
      ],
    });
  }

  function onReportPreview(report) {
    setDesignerVisible(false);
    updateToolbar();
    viewer.current.open(report.definition);
    return Promise.resolve();
  }

  return (
    <div className="reportDesignerContainer" {...{ style }}>
      <div
        id="designer-host"
        style={{ display: designerVisible ? "block" : "none" }}
      >
        <Designer
          {...{
            ref,
            ...rest,
            onRender: onReportPreview,
            reportList,
          }}
        />
      </div>

      {!designerVisible && (
        <div
          id="viewer-host"
          style={{ display: designerVisible ? "none" : "block" }}
        >
          <ReportViewer ref={viewer} />
        </div>
      )}
      {editLogsVisibility && (
        <MultiLevelTreeLogs
          {...{
            visible: editLogsVisibility,
            setVisible: setEditLogsVisibility,
            logsData: editLogs || [],
            title: `${reportName} Logs`,
          }}
        />
      )}
    </div>
  );
});

export default ReportDesigner;
