import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef, useState } from "react";

import TableStepHeader from "./TableStepHeader";
import { autoSizeAllColumns } from "../../utils/gridUtils";
import CompareTables from "../CompareTables/CompareTables";
import { generateColumnDefs, gridOptions } from "./columnDefs";
import { useUploadRecordsContext } from "../../context/UploadRecordsContext";

import "./TableStep.scss";

const TableStep = () => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [gridApi, setGridApi] = useState();
  const gridRef = useRef(null);
  const timeoutRef = useRef(null);

  const {
    uploadedRecords,
    recordsConflicts,
    conflictsTableVisible,
    setConflictsTableVisible,
  } = useUploadRecordsContext();

  const columnDefs = generateColumnDefs(uploadedRecords);

  const onGridReady = useCallback(({ api }) => {
    setGridApi(api);
  }, []);

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
  }

  const autoSizeColumns = useCallback(() => {
    autoSizeAllColumns(uploadedRecords, gridRef, true);
  }, [uploadedRecords, gridRef]);

  return (
    <div
      className={`upload-table-container ${
        isDarkMode && "upload-table-container-dark"
      }`}
    >
      <TableStepHeader
        {...{ recordsConflicts, onFilterTextChange, setConflictsTableVisible }}
      />
      <div
        className={`documentationsTable ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
        style={{ width: "80%" }}
      >
        <AgGridReact
          ref={gridRef}
          {...{
            rowData: uploadedRecords,
            columnDefs,
            onGridReady,
            gridOptions,
            onComponentStateChanged: (e) => {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = setTimeout(() => {
                autoSizeColumns();
              }, 300);
            },
          }}
        />
      </div>
      {conflictsTableVisible && (
        <CompareTables
          open={conflictsTableVisible}
          onCancel={() => setConflictsTableVisible(false)}
        />
      )}
    </div>
  );
};

export default TableStep;
