import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";

export const fleet = (fleetData, updatedDynamicStates, IdHandler) => {
  return {
    fleetYear: () => ({
      title: "Fleet Year",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Fleet",
        field: "fleetYear",
        content: uniq(fleetData?.map((a) => a?.fleetYear))
          ?.sort()
          ?.map((fleetYear, index) => ({
            key: index,
            title: fleetYear,
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.fleetYear === fleetYear
            )?.length,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: " Fleet In " + fleetYear,
            contentsHandler: " Fleets In " + fleetYear,
          })),
      },
      id: IdHandler,
      width: "1600px",
    }), //fleetYear
    registrationExpDate: () => ({
      title: "Registration Expiration Date",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Fleet",
        field: "registrationExpDate",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (b) => b.registrationExpDate >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a.registrationExpDate >= getMonthsTimestamps()[i] &&
                    a.registrationExpDate <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Expiration In " + months[i],
                contentsHandler: " Expiration In " + months[i],
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "1600px",
    }), //registrationExpDate
    inspectionExpDate: () => ({
      title: "Inspection Expiration Date",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Fleet",
        field: "inspectionExpDate",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                title: months[i],
                taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (b) => b.inspectionExpDate >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a.inspectionExpDate >= getMonthsTimestamps()[i] &&
                    a.inspectionExpDate <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Expiration In " + months[i],
                contentsHandler: " Expiration In " + months[i],
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "1600px",
    }), //inspectionExpDate
  }; //Fleet
};
