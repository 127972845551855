import { Modal } from "antd";
import { useState } from "react";
import { RentalsIcon } from "../../../../../../../../../../../../icons";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { useStateContext } from "../../../../../../Context/Context";
import ChainRentalsModal from "./ChainRentalsModal/ChainRentalsModal";
import "./ChainRentals.scss";
import { CloseIconModal } from "../../../../../../../../../../../../assets";
function ChainRentals({ disabled = false }) {
  const [visible, setVisible] = useState(false);
  const { darkMode } = useStateContext();
  return (
    <div>
      <div>
        <MondayButton
          disabled={disabled}
          className="mondayButtonBlue"
          Icon={<RentalsIcon />}
          onClick={() => setVisible(true)}
        >
          Chain Rentals
        </MondayButton>
      </div>
      {visible && (
        <Modal
          title="Chain Rentals"
          className={`ChainRentalsModal${darkMode ? "_dark" : ""}`}
          footer={null}
          open={visible}
          width={1800}
          closeIcon={null}
          onCancel={() => setVisible(false)}
        >
          <ChainRentalsModal onCancel={() => setVisible(false)} />
        </Modal>
      )}
    </div>
  );
}

export default ChainRentals;
