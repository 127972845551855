export const extractImagesSrc = (str) => {
  var m,
    urls = [],
    regex = /<img[^>]+src="([^">]+)/g;

  while ((m = regex.exec(str))) {
    urls.push(m[1]);
  }

  return urls;
};
