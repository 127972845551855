export const noteExtractor = ({ fakeApplication, serviceId, eventId }) =>
  fakeApplication?.services
    ?.find((serviceData) => serviceData?.serviceId === serviceId)
    ?.amounts?.find((eventData) => eventData?.id === eventId)?.note

export const onConfirmHandler = ({
  dispatchContext,
  fakeApplication,
  initialValue,
  estimationId,
  serviceId,
  newValue,
  eventId,
}) => {
  const newApplication = {
    ...fakeApplication,
    services: fakeApplication?.services?.map((serviceData) =>
      serviceData?.serviceId === serviceId && serviceData?.estimationId === estimationId
        ? {
            ...serviceData,
            amounts: serviceData?.amounts?.map((eventData) =>
              eventData?.id === eventId
                ? { ...eventData, note: newValue || initialValue }
                : eventData
            ),
          }
        : serviceData
    ),
  }

  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication })
}
