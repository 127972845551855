import { Radio } from "antd";
import { yesNoOptions } from "../../helpers/constants";

export const generateViolationsInputs = ({
  projects,
  drivers,
  violationStatuses,
}) => ({
  "General Information": [
    {
      name: "violationNumber",
      label: "Violation#",
      required: true,
      placeholder: "Violation#",
      style: { minWidth: 215 },
      type: "input",
    },
    {
      required: true,
      label: "Violation",
      name: "violation",
      placeholder: "Violation",
      style: { width: 215 },
    },
    {
      label: "Violation Location",
      name: "violationLocation",
      placeholder: "Click and Search...",
      type: "placesautocomplete",
      groupedOptions: [
        {
          label: "Projects",
          options: projects,
        },
      ],
    },
    {
      required: true,
      label: "Violation Status",
      name: "violationStatus",
      placeholder: "Select...",
      type: "select",
      customOptions: violationStatuses,
    },
    {
      label: "Driver",
      name: "violationDriver",
      placeholder: "Click and Search...",
      type: "select",
      customOptions: drivers,
    },
    {
      label: "Driver Identified?",
      type: "radio",
      name: "violationDriverIdentified",
      options: (
        <>
          {yesNoOptions.map((option) => (
            <Radio value={option}>{option}</Radio>
          ))}
        </>
      ),
    },
    {
      required: true,
      label: "Issued Date and Time",
      name: "violationIssuedDate",
      type: "datePicker",
    },
    {
      label: "Received Date",
      name: "violationReceivedDate",
      type: "datePicker",
    },
  ],
  "Violation Amount": [
    {
      required: true,
      label: "Liability Amount",
      name: "violationLiabilityAmount",
      placeholder: "Liability Amount",
      typeNumber: true,
      addonAfter: "$",
    },
    {
      label: "Interest Amount",
      name: "violationInterestAmount",
      placeholder: "Interest Amount",
      typeNumber: true,
      addonAfter: "$",
    },
    {
      label: "Penalty Amount",
      name: "violationPenaltyAmount",
      placeholder: "Penalty Amount",
      typeNumber: true,
      addonAfter: "$",
    },
    {
      label: "Reduction Amount",
      name: "violationReductionAmount",
      placeholder: "Reduction Amount",
      typeNumber: true,
      addonAfter: "$",
    },
    {
      label: "Amount Paid",
      name: "violationAmountPaid",
      placeholder: "Amount Paid",
      typeNumber: true,
      addonAfter: "$",
    },
    {
      label: "Paid?",
      name: "violationPaymentStatus",
      type: "radio",
      options: (
        <>
          {yesNoOptions.map((option) => (
            <Radio value={option}>{option}</Radio>
          ))}
        </>
      ),
    },
  ],
});
