import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const opportunity = ({ tables, programFields }) => {
  return {
    opportunityStage: () => ({
      labels: programFields
        ?.find((a) => a?.fieldName == "Opportunity Stage")
        ?.fieldOptions?.map((a) => a.detailedStageName),

      data: programFields
        ?.find((a) => a?.fieldName == "Opportunity Stage")
        ?.fieldOptions?.map((a) => a.detailedStageName)
        ?.map(
          (status) =>
            tables?.Opportunities?.filter(
              (a) => a.opportunityStage?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),
      backgroundColor: programFields
        ?.find((a) => a?.fieldName === "Status colors")
        ?.fieldOptions?.Opportunities?.map((a) => a.statusColor),
    }),
    opportunityStatus: () => ({
      labels: programFields
        ?.find((a) => a?.fieldName == "Status of opportunities")
        ?.fieldOptions?.map((a) => a.statusName),

      data: programFields
        ?.find((a) => a?.fieldName == "Status of opportunities")
        ?.fieldOptions?.map((a) => a.statusName)
        ?.map(
          (status) =>
            tables?.Opportunities?.filter(
              (a) =>
                a.opportunityStatus?.toLowerCase() === status?.toLowerCase()
            )?.length
        ),

      backgroundColor: programFields
        ?.find((a) => a?.fieldName == "Status of opportunities")
        ?.fieldOptions?.map((a) => getRandomColor()),
    }),

    projectExecutive: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Opportunities, "projectExecutive")
      ),
      data: Object.values(
        repeatedElementSum(tables?.Opportunities, "projectExecutive")
      ),
      backgroundColor: uniq(
        tables?.Opportunities?.map((a) => getRandomColor())
      ),
    }),

    projectManager: () => ({
      labels: Object.keys(
        repeatedElementSum(
          tables?.Opportunities?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      ),
      data: Object.values(
        repeatedElementSum(
          tables?.Opportunities?.map((a) => a?.projectManager)?.flat(),
          "nameOfUser"
        )
      ),
      backgroundColor: uniq(
        tables?.Opportunities?.map((a) => getRandomColor())
      ),
    }),

    geoFenceInfo: () => ({
      labels: [
        "Opportunities With Geofences",
        "Opportunities Without Geofences",
      ],

      data: [
        tables?.Opportunities?.reduce(
          (sum, { geoFenceInfo }) => sum + (geoFenceInfo?.length > 0 ? 1 : 0),
          0
        ),
        tables?.Opportunities?.reduce(
          (sum, { geoFenceInfo }) => sum + (geoFenceInfo?.length === 0 ? 1 : 0),
          0
        ),
      ],
      backgroundColor: programFields
        ?.find((a) => a?.fieldName == "Status of opportunities")
        ?.fieldOptions?.map((a) => getRandomColor()),
    }),
  };
};
