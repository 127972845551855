import moment from "moment-timezone";

export const categories = ["estimations", "applications", "charges", "rentals"];
export const categoriesServiceMap = {
  estimations: "services",
  applications: "services",
  charges: "chargeItems",
  rentals: "services",
};
export const categoriesName = {
  estimations: "estimation",
  applications: "application",
  charges: "charge",
  rentals: "rental",
};
export const categoriesNumberKey = {
  applications: "applicationNo",
  charges: "chargeNumber",
  rentals: "rentalNumber",
  estimations: "estimationNumber",
};
export const categoriesTypeKey = {
  charges: "chargeType",
};

export const requiredInvoiceFields = [
  { field: "accountName", content: "Client" },
  { field: "projectName", content: "Project name" },
  { field: "invoiceDate", content: "Invoice date" },
  { field: "dueDate", content: "Due date field" },
];

export const defaultProductRow = {
  //Default Products on the rowData for ag-grid
  id: 1,
  name: "",
  description: "",
  amount: 0.0,
  taxAmount: 0.0,
  amountPercentage: 100,
  tax: false,
  isExtraService: true,
  group: "Extra Service",
  total: 0,
};

export const defaultServiceRow = {
  accountEmail: null,
  accountId: "",
  accountName: null,
  billingAddress: null,
  invoiceDate: moment().tz("America/New_York").valueOf(),
  dueDate: moment().tz("America/New_York").add(30, "day"),
  projectId: "",
  projectName: null,
  quickBooksNumber: null,
  taxRate: 0,
};
