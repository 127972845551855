import { useParams } from "react-router-dom";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { UsernameRenderer } from "../../../../AllUsers/components";
import "./NewUserList.scss";
import { SearchOutlined } from "@ant-design/icons";
import CardTemplate from "../CardTemplate/CardTemplate";

function NewUserList({
  isDarkMode,
  departmentName,
  role,
  fromRolesAccess = false,
}) {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [searchVal, setSearchVal] = useState("");
  const currentRole = role || "Engineer/Architect";

  const departmentUsers = useMemo(() => {
    if (fromRolesAccess) {
      return userConfiguration.allUsers.Items.filter(
        (user) => user.departmentName === departmentName
      );
    } else {
      return userConfiguration.allUsers.Items.filter(
        (user) => user.groupName === currentRole
      );
    }
  }, [fromRolesAccess, currentRole, departmentName]);

  const searchedUsers = useMemo(() => {
    return departmentUsers.filter((user) =>
      user.nameOfUser.toLowerCase().includes(searchVal.toLowerCase())
    );
  }, [searchVal, departmentUsers]);

  return (
    <CardTemplate
      {...{
        className: `user-list-container ${
          isDarkMode ? "user-list-container-dark" : ""
        }`,
        isDarkMode,
        title: fromRolesAccess
          ? `All ${departmentName} Users`
          : `Other ${currentRole} Users`,
      }}
    >
      <div className="usersSearch">
        <InputComponent
          id="searchUsers"
          placeholder="Search User..."
          value={searchVal}
          onChange={(e) => {
            setSearchVal(e.target.value);
          }}
          prefix={<SearchOutlined />}
        />
      </div>
      <div className="user-list-table">
        <div className="user-list-table-header">
          <span>User</span>
          <span>Status</span>
        </div>
        <div className="user-list-table-body">
          {searchedUsers.map((user) => (
            <UsernameRenderer
              key={user?.identityId}
              {...{
                data: user,
                value: user?.nameOfUser,
                userList: true,
                isDarkMode,
              }}
            />
          ))}
        </div>
      </div>
    </CardTemplate>
  );
}

export default NewUserList;
