import { getUnitData } from "./getUnitData";

export const getUpdatedResult = async (categoryName, result) => {
  return categoryName === "Proposal Builder Template"
    ? result.filter((item) => item.templateCategory === "service")
    : categoryName === "General Template"
    ? result.filter((item) => item.templateCategory === "general")
    : categoryName === "Pricing Config"
    ? await getUnitData(result)
    : result;
};
