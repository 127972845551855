import { trim } from "lodash";
import { getCoordinatesAndZip } from "../../../../../SidebarPages/Fleet/utils/getCoordinatesAndZip";
import {
  // aptNumberChangeHandler,
  getAddressComponent,
} from "../../../../../SidebarPages/Fleet/utils/addressParseHelpers";

const NotFound = () => {
  return (
    <p
      style={{
        textAlign: "center",
        margin: 0,
      }}
    >
      No Access to view Clients
    </p>
  );
};

export const fieldsJSON = ({
  form,
  companyNames,
  projectExecutivesNames,
  proppedAccountName,
  contacts = [],
  useExistingData = false,
  onTaxExemptSelect,
  taxExemptOptions,
  taxRatePopOverVisible,
  salesPerson,
  accountName,
  clientDropdown,
  setAccountName,
  contactsDropdown,
  setProjectAddress,
  selectedPrimaryContact,
  setSelectedPrimaryContact,
  alternativeAddressesVisible,
  AlternativeAddressesComp,
  AlternativeAddressesTitle,
  laborTypes,
  insuranceTypes,
  onSelect,
  onDeselect,
  projectTeamSelect,
  projects,
  checkClientAccess,
}) => [
  {
    category: "leftGrid",
    fields: [
      {
        label: "Project Executive",
        formItemName: "projectExecutive",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        required: true,
        options: projectExecutivesNames,
        className: "fullInput",
        disabled: !!useExistingData,
      },
      {
        label: "Sales Person",
        formItemName: "salesPerson",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        mode: "multiple",
        options: salesPerson,
        className: "fullInput",
        disabled: !!useExistingData,
      },
      {
        label: "Tax Exempt",
        formItemName: "taxExempt",
        placeholder: "Select...",
        initialValue: "Yes",
        type: "select",
        onSelect: onTaxExemptSelect,
        showSearch: true,
        required: true,
        options: taxExemptOptions,
        disabled: !!useExistingData,
      },
      {
        label: "Tax Rate",
        formItemName: "taxRate",
        initialValue: "0",
        addonAfter: "%",
        type: "input",
        disabled: true,
        popoverVisible: taxRatePopOverVisible,
        popoverPlacement: "bottom",
        popoverContent: "Only Rental Tax",
        style: { minWidth: 115 },
      },
      {
        label: "Labor Requirement",
        formItemName: "laborRequirement",
        className: "fullInput",
        type: "select",
        showSearch: true,
        initialValue: "Standard Labor - Open Shop",
        options: laborTypes,
      },
      {
        label: "Insurance Requirement",
        formItemName: "insuranceRequirement",
        className: "fullInput",
        type: "select",
        showSearch: true,
        initialValue: "Traditional Insurance",
        options: insuranceTypes,
      },
      {
        label: "Proposal Start Date",
        formItemName: "proposalStartDate",
        type: "dateinput",
        // type: "datepicker",
        className: "fullInput",
        form,
        // required: true,
      },
    ],
  },
  {
    category: "rightGrid",
    fields: [
      {
        label: "Project Name",
        placeholder: "Project Name",
        formItemName: "projectAddress",
        className: "fullInput",
        // required: true,
      },
      {
        label: "Company Name",
        formItemName: "accountName",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        required: true,
        onSelect: (value) => {
          form.resetFields(["primaryContact", "contacts"]);
          setAccountName(value);
        },
        defaultValue: accountName || "",
        options: !!checkClientAccess ? companyNames : [],
        className: "fullInput",
        style: { minWidth: 305 },
        disabled: !!useExistingData || !!proppedAccountName,
        dropdownRender: clientDropdown,
        notFoundContent: !checkClientAccess ? <NotFound /> : undefined,
      },

      {
        label: "Primary contact",
        formItemName: "primaryContact",
        placeholder: "Select...",
        type: "select",
        disabled: !accountName,
        customOptions: contacts?.map(
          ({
            contactId,
            contactFirstName = "",
            contactLastName = "",
            contactEmail = "",
            contactRole = "",
          }) => ({
            value: contactId,
            label: trim(
              `${contactFirstName} ${contactLastName} - ${contactEmail} - ${contactRole}`,
              " -"
            ),
          })
        ),
        showSearch: true,
        required: true,
        className: "fullInput",
        dropdownRender: contactsDropdown,
        onSelect: (value) => setSelectedPrimaryContact(value),
      },

      {
        label: "Contacts",
        formItemName: "contacts",
        placeholder: "Select...",
        type: "select",
        mode: "multiple",
        showSearch: true,
        // disabled: !accountName || !selectedPrimaryContact,
        customOptions: contacts
          ?.filter(({ contactId }) => contactId !== selectedPrimaryContact)
          ?.map(
            ({
              contactId,
              contactFirstName = "",
              contactLastName = "",
              contactEmail = "",
              contactRole = "",
            }) => ({
              value: contactId,
              label: trim?.(
                `${contactFirstName} ${contactLastName} - ${contactEmail} - ${contactRole}`,
                " -"
              ),
            })
          ),
        dropdownRender: contactsDropdown,

        className: "fullInput",
      },
      {
        label: "Teams",
        formItemName: "projectTeam",
        type: "select",
        className: "fullInput",
        showSearch: true,
        mode: "multiple",
        onSelect: onSelect,
        onDeselect: onDeselect,
        customOptions: projectTeamSelect,
        required: true,
        allowClear: false,
      },
    ],
  },
  {
    category: "addressGrid",
    mainFields: [
      {
        label: "Project Address",
        formItemName: "projectName",
        existingValues: projects,
        placeholder: "Click and Search...",
        onSelect: (value) => {
          setProjectAddress(value);
          getAddressComponent(value, false, "").then((res) => {
            // form.setFieldValue("projectAptNumber", res.aptNumber);
            form.setFieldValue("projectAddCity", res.city);
            form.setFieldValue("projectAddState", res.state);
            form.setFieldValue("projectAddStreet", res.street);
            form.setFieldValue("projectName", res.address);
            form.setFieldValue("projectAddPostalCode", res.zipCode);
          });
        },
        onChange: (value) => {
          if (!value) {
            form.setFieldsValue({
              projectAddCity: "",
              projectAddState: "",
              projectAddStreet: "",
              projectName: "",
              projectAddPostalCode: "",
            });
          }
        },
        required: true,
        type: "placesautocomplete",
        style: { minWidth: 305 },
        form,
        popoverVisible: alternativeAddressesVisible,
        popoverTitle: AlternativeAddressesTitle,
        popoverContent: AlternativeAddressesComp,
        popoverClassName: "alternativeAddressesContainer",
        popoverPlacement: "bottomLeft",
        className: "fullInput",
        disabled: !!useExistingData,
      },
      // {
      //   label: "Apartment #",
      //   formItemName: "projectAptNumber",
      //   placeholder: "Apartment #",
      //   onChange(val) {
      //     let oldAddress = form.getFieldValue("projectName");
      //     let newAddress = aptNumberChangeHandler(oldAddress, val.target.value);
      //     setProjectAddress(newAddress);
      //     form.setFieldValue("projectName", newAddress);
      //   },
      //   required: false,
      //   type: "input",
      //   style: { minWidth: 305 },
      //   form,
      //   isCustomValue: true,
      // },
      {
        label: "Street",
        placeholder: "Street",
        formItemName: "projectAddStreet",
        disabled: true,
        style: { minWidth: 305 },
      },
      {
        label: "City",
        placeholder: "City",
        formItemName: "projectAddCity",
        disabled: true,
        style: { minWidth: 305 },
      },
    ],
    splitFields: [
      {
        label: "State",
        placeholder: "State",
        formItemName: "projectAddState",
        disabled: true,
        style: { minWidth: 168, width: 168 },
      },
      {
        label: "Postal Code",
        placeholder: "Postal Code",
        formItemName: "projectAddPostalCode",
        disabled: true,
        style: { minWidth: 122, width: 122 },
      },
    ],
  },
];

export const overviewStepFormItemNames = fieldsJSON({})?.flatMap(
  ({ fields = [] }) => fields?.map(({ formItemName }) => formItemName)
);
