import { forwardRef } from "react";
import { Viewer } from "@grapecity/activereports-react/lib/Viewer";
import "@grapecity/activereports/styles/ar-js-viewer.css";
// import "../../styles/ar-js-viewer.css";
import "@grapecity/activereports/pdfexport";

const ReportViewer = forwardRef(({ exportsSettings }, ref) => {
  return (
    <Viewer
      {...{
        ref,
        availableExports: ["pdf", "tabular-data"],
        exportsSettings,
      }}
    />
  );
});

export default ReportViewer;
