import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 *
 * @param {String} variable varible name to create the redux state
 * @param {Object} initial  initial value of state
 * @param {Boolean} reInitialize  if you want to reinitialize when component reinitialized
 * @param {Boolean} customDispatch  if you want to use custom dispatch {type: "CUSTOM", payload: "CUSTOM", casesFn: (state, action) => ({...state, [action.payload.variable]: action.payload.value}
 * @returns
 */
export const useRedux = (
  variable,
  initial = undefined,
  reInitialize = undefined,
  customDispatch = false
) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.customReducer[variable]);
  const setState = (e) =>
    !customDispatch
      ? dispatch({ type: variable, payload: e })
      : dispatch({
          type: variable,
          payload: customDispatch.casesFn({ variable, value: e }),
        });
  useEffect(() => {
    reduxState !== initial &&
      initial !== undefined &&
      reInitialize &&
      setState(initial);
    !reduxState &&
      reduxState !== initial &&
      initial !== undefined &&
      setState(initial);
  }, []);

  return [reduxState !== undefined ? reduxState : initial, setState];
};
