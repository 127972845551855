/**
 * This function will iterate services object till to PLI level and to service addon level. (All plis and service addons of these estimations will be touched). There in pli (also in service addon)
 * we can execute whatever function we want. Also the function we execute may take care for addons and sub-plis. With variablesNeeded
 * we can store things we have done in the function we have passed.
 *
 * @param toBeScheduled {Object} - services object.
 * @param actionFunction {function} - The function we want to execute for all plis of these estimations
 * @param variablesNeeded {Object} - we can insert whatever we want here. We can pass some parameters and then during actionFunction
 * some other variables can be added/removed/modified in it. So after this function finishes execution, this is the place where we can read results
 * @param indexes {Object|undefined} - It is similar to variablesNeeded and can be also merged together such as they have are merged in the lines below. It is supposed to
 * store the origin of item. We can pass whatever we want. During the execution of this function parameters such as
 * estimationId, estimationNumber, serviceId, label, elevationId, etc are added.
 *
 * */
export const iterateServicesInPLILevel = ({
  toBeScheduled,
  actionFunction,
  variablesNeeded,
  indexes,
}) => {
  const estimationIdList =
    typeof toBeScheduled === "object" && !!toBeScheduled
      ? Object.keys(toBeScheduled)
      : [];

  for (let i = 0; i < estimationIdList?.length; i++) {
    const estimationId = estimationIdList?.[i];
    const services = toBeScheduled?.[estimationId];
    for (const service of services || []) {
      delete service.additionalRentalTerms;
      delete service.PLIAddonScheme;
      delete service.alternateRent;
      delete service.isTaxable;
      delete service.priceScheme;
      delete service.rent;
      delete service.rentalPaymentTerms;
      delete service.taxRate;
      delete service.totalities;
      delete service.serviceAddons;
      delete service.excludes;
      delete service.includes;
      delete service.priceSheetDataSrc;
      delete service.pricePerUnit;
      delete service.documentationAddons;
      const { serviceId, label } = service;

      for (let e = 0; e < service?.serviceOptions?.[0]?.length; e++) {
        const elevation = service?.serviceOptions?.[0]?.[e];
        const { elevationId, elevationLabel } = elevation;

        for (let p = 0; p < elevation?.items?.length; p++) {
          delete elevation.desiredPrice;
          delete elevation.planks;
          delete elevation.taxAmount;
          delete elevation.addonsTotal;
          delete elevation.price;
          delete elevation.taxRate;
          delete elevation.pricing;
          delete elevation.totalPrice;
          const pli = elevation?.items?.[p];

          //we add indexes inside variablesNeeded object
          variablesNeeded.indexes = {
            ...indexes,
            estimationId,
            estimationNumber: i + 1,
            serviceId,
            label,
            elevationId,
            elevationIndex: e,
            elevationLabel,
            pliId: pli?.id,
          };

          //here we execute action function
          actionFunction({ pli, variablesNeeded });
        }
      }

      // for (let a = 0; a < service?.serviceAddons?.length; a++) {
      //   const serviceAddon = service?.serviceAddons?.[a];
      //   // console.log(serviceAddon)
      //   variablesNeeded.indexes = {
      //     ...indexes,
      //     estimationId,
      //     estimationNumber: i + 1,
      //     serviceId,
      //     label,
      //     serviceAddonId: serviceAddon?.id,
      //     serviceAddonNames: serviceAddon?.name?.values?.find?.(
      //       (v) => v?.name?.toLowerCase() === "name"
      //     )?.value,
      //   };
      //   actionFunction({ serviceAddon, variablesNeeded });
      // }
    }
  }
};

/**
 * This function work with the similar logic as iterateServicesInPLILevel. This functions will iterate a list of schedules
 * and find find pliToWork inside them. Additional to that this takes another parameter (pliToWork or serviceAddonToWork)
 * which is used by actionFunction. At the time of writing this function is used from NewSchedule.handleServices to make calculations
 * from past schedules for the pli we want (pliToWork) which itself is taken from an iteration of project services. Read more
 * in NewSchedule.handleServices to understand its logic. But the main idea is that pliToWork and pli that we get here during
 * the iteration is that we logically are referring to the same PLI. Just that they belong to different schedules (or project services
 * in case of pliToWork). So basically we use this function when we want to find pliToWork sisters in all schedules.
 *
 * @param pliToWork {Object|undefined} - a PLI that we get usually from project and we want to modify it to use for the new schedule
 * @param serviceAddonToWork {Object|undefined } - same as pliToWork but used for service addons
 * @param schedules {Object[]} - a list of schedules we want to iterate (check)
 * @param actionFunction {function} - a function which will work with pli and pliToWork
 * @param variablesNeeded {Object} - same usage as iterateServicesInPLILevel
 * @param indexes {Object} - same usage as iterateServicesInPLILevel
 *
 * */
export const iteratePliInSchedules = ({
  pliToWork,
  serviceAddonToWork,
  schedules,
  actionFunction,
  variablesNeeded,
  indexes,
}) => {
  const { estimationId, serviceId, elevationIndex } = indexes;

  for (const schedule of schedules) {
    const {
      scheduleId,
      toBeScheduled,
      typeOfWork,
      scheduleName,
      scheduleDays,
      createdAt,
    } = schedule;

    const estimationIdList = Object.keys(toBeScheduled);

    if (estimationIdList.includes(estimationId)) {
      for (let i = 0; i < estimationIdList.length; i++) {
        const estimationId = estimationIdList[i];
        const services = toBeScheduled[estimationId];

        for (const service of services) {
          if (service?.serviceId === serviceId) {
            if (pliToWork)
              //its either pliToWork or serviceAddonToWork
              for (let e = 0; e < service.serviceOptions[0].length; e++) {
                const elevation = service.serviceOptions[0][e];

                if (e === elevationIndex) {
                  for (let p = 0; p < elevation?.items?.length; p++) {
                    const pli = elevation?.items?.[p];
                    if (pli?.id === pliToWork?.id) {
                      variablesNeeded = {
                        ...variablesNeeded,
                        scheduleDays,
                        iteratingScheduleData: {
                          scheduleId,
                          typeOfWork,
                          scheduleName,
                          createdAt,
                        },
                      };
                      actionFunction({
                        pliToWork,
                        pli,
                        variablesNeeded,
                        typeOfWork,
                      });
                      break;
                    }
                  }
                  break;
                }
              }

            // console.log(serviceAddonToWork)
            if (serviceAddonToWork)
              for (const serviceAddon of service?.serviceAddons) {
                if (serviceAddon?.id === serviceAddonToWork?.id) {
                  variablesNeeded = {
                    ...variablesNeeded,
                    scheduleDays,
                    iteratingScheduleData: {
                      scheduleId,
                      typeOfWork,
                      scheduleName,
                      createdAt,
                    },
                  };
                  // if(serviceAddonToWork.id === 1) console.log(serviceAddonToWork.totalProgress)
                  actionFunction({
                    serviceAddonToWork,
                    serviceAddon,
                    variablesNeeded,
                    typeOfWork,
                  });
                  // if(serviceAddonToWork.id === 1) console.log(serviceAddonToWork.totalProgress)
                }
              }
            break;
          }
        }
      }
    }
  }
};
