// here we are going to retrieve the logic between rentals and scheduling

import { groupBy } from "lodash";
import { filterTables } from "../../../../../../../../../../../utils";
import { dayjsNY } from "../../../../../../../../../../DateComponents/contants/DayjsNY";
import { forceToNumber } from "../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const retrieveSchedulingProgress = async ({
  projectId = "",
  startDate = "",
  endDate = "",
  serviceId = false,
  elevationId = false,
}) => {
  //   let startDate = dayjsNY("01/08/2024", "DD/MM/YYYY").valueOf();
  //   let endDate = dayjsNY("31/08/2024", "DD/MM/YYYY").valueOf();
  let sortEndDate = dayjsNY(endDate).endOf("day").valueOf();
  const DATA_HOLDER = {};
  await filterTables("progressItems", "projectId", projectId).then((e) => {
    //FIRST WE FILTER THE PROGRESS ITEMS ACCORDING TO THE DATE RANGE
    let filtered = e.filter(
      (item) => item?.startDate >= startDate && item?.startDate <= sortEndDate
    );
    if (!!serviceId) {
      filtered = filtered.filter(
        (item) => item?.serviceId?.toString() === serviceId?.toString()
      );
    }
    if (!!elevationId) {
      filtered = filtered.filter(
        (item) => item?.elevationId?.toString() === elevationId?.toString()
      );
    }
    console.log("filtered", filtered, e);
    // SECONDLY WE GROUP THE PROGRESS ITEMS ACCORDING TO THE PLI ID #
    const PLIGROUP = groupBy(filtered, "pli");
    // if (Object.entries(PL))
    Object.entries(PLIGROUP).forEach(([key, value]) => {
      let groupedDays = groupBy(value, "startDate");
      let progressArr = [];
      Object.entries(groupedDays).forEach(([day, items]) => {
        let days = items?.sort(
          (a, b) => a?.progressTimeGiven - b?.progressTimeGiven
        );
        let lastDay = days[days.length - 1];
        progressArr.push(forceToNumber(lastDay?.pliProgressPercentage));
      });
      DATA_HOLDER[key] = progressArr.reduce((a, b) => a + b, 0);
    });

    console.log("ontest", { PLIGROUP, DATA_HOLDER });
  });
  return DATA_HOLDER;
};
