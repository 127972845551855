/*eslint-disable*/
import { useEffect, useState } from "react";
import "./Sidebar.scss";
import { SidebarData } from "./SidebarData";
import { CompanyLogo, NewShortcutPopover, PagesMenu } from "./components";
import { useMediaQuery } from "react-responsive";
import { CoreWhiteLogo } from "../../icons";
import { useSelector } from "react-redux";
import { Layout } from "antd";

const { Sider } = Layout;

const Sidebar = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [sidebarElements, setSidebarElements] = useState([]);
  const { companyLogos = [] } = props;
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const formattedCompanyLogos = companyLogos?.reduce(
    (acc, { fileName, base64 }) => ({ ...acc, [fileName]: base64 }),
    {}
  );
  const [collapsed, setCollapsed] = useState(false);
  const isTabletView = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const getSideBarData = () => {
    let sidebarData = userConfiguration;
    for (let el of sidebarData.sidebarConfig) {
      for (let element of SidebarData) {
        if (el.title === element.title) {
          el.src = element.src;
        }
      }
    }
    setSidebarElements(sidebarData.sidebarConfig);
    setIsLoaded(true);
  };

  useEffect(() => {
    getSideBarData();
  }, [userConfiguration]);

  useEffect(() => {
    if (isTabletView && !collapsed) {
      setCollapsed(true);
    } else if (!isTabletView && collapsed) {
      setCollapsed(false);
    }
  }, [isTabletView]);

  useEffect(() => {
    const titleCompBasePage =
      document.getElementsByClassName("titleCompBasePage")[0];

    // titleComp of Tasks page
    const titleComp = document.getElementsByClassName("titleComp")[0];

    titleCompBasePage?.style?.setProperty(
      "--leftPosition",
      `${collapsed ? "70px" : "240px"}`
    );

    titleComp?.style.setProperty(
      "--leftPosition",
      `${collapsed ? "70px" : "240px"}`
    );
  }, [collapsed]);

  return (
    <Sider
      className={`sidebarContainer ${isDarkMode && "sidebarContainerDark"}`}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      width={collapsed ? "80px" : "250px"}
    >
      <CompanyLogo
        src={CoreWhiteLogo}
        style={{
          height: !collapsed ? "80px" : "40px",
          width: !collapsed ? "180px" : "80px",
        }}
      />
      <NewShortcutPopover {...props} collapsed={collapsed} />

      {sidebarElements && (
        <PagesMenu
          pages={sidebarElements}
          isLoaded={isLoaded}
          collapsed={collapsed}
          isDarkMode={isDarkMode}
        />
      )}
    </Sider>
  );
};
export default Sidebar;
