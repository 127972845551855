import { Alert, Form, Modal, message } from "antd";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../../../commonComponents";
import { TickIcon } from "../../../../../Roles/src";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Groups } from "../../../../../Roles/RolesData";
import {
  apiPost,
  apiPut,
  filterTables,
} from "../../../../../../../../../utils";
import "./ChangeDepartment.scss";
import { updateOtherUser } from "../../../../../../../../../actions";
import broadcastNotification from "../../../../../../../../../helpers/controllers/broadcastNotification";
import { InfoText } from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { useEditLogs } from "../../../../../../../../../hooks";

function ChangeDepartment({
  visible,
  setVisible,
  user,
  nameOfUser,
  userConfiguration,
}) {
  const [form] = Form.useForm();
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const dispatch = useDispatch();
  const [selectedDepartment, setSelectedDepartment] = useState(
    user.departmentName
  );
  const [selectedRole, setSelectedRole] = useState(user.groupName);
  const [roles, setRoles] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [allDepartments, setAllDepartments] = useState([]);
  const [nothingChanged, setNothingChanged] = useState(true);

  const { saveAddedLogs } = useEditLogs();

  //get all departments and selected user from userConfiguration
  useEffect(() => {
    const departments = programFields?.find(
      ({ fieldName }) => fieldName === "Departments"
    )?.fieldOptions;
    const selectedUser = userConfiguration?.allUsers.Items.find(
      ({ identityId }) => identityId === user.identityId
    );
    setSelectedUser(selectedUser);
    setAllDepartments(departments);
  }, [programFields, userConfiguration]);

  useEffect(() => {
    setNothingChanged(
      selectedUser?.departmentName === selectedDepartment &&
        selectedUser?.groupName === selectedRole
    );
  }, [selectedRole, selectedDepartment, selectedUser]);

  //get all roles for selected department
  useEffect(() => {
    !!selectedDepartment &&
      filterTables("userGroups", "departmentName", selectedDepartment).then(
        (res) => {
          res.length < 1
            ? setRoles([])
            : setRoles(Groups({ groups: res }) || []);
        }
      );
  }, [selectedDepartment]);

  function findRoleAccess(roles, selectedRole) {
    for (const obj of roles) {
      if (Object.keys(obj)[0] === selectedRole) {
        return obj[selectedRole];
      }
    }
  }

  async function onSave() {
    await apiPut("userConfiguration", selectedUser?.identityId, {
      departmentName: selectedDepartment,
      groupName: selectedRole,
      routeConfig: findRoleAccess(roles, selectedRole)["Route"],
      sidebarConfig: findRoleAccess(roles, selectedRole)["Sidebar"],
    })
      .then((res) => {
        broadcastNotification(
          "84",
          "onUserDepartmentChanged",
          [
            {
              common: userConfiguration?.nameOfUser,
              common2: `from ${user?.groupName} to ${selectedRole}`,
              commonNext: nameOfUser,
            },
            {
              userName: userConfiguration.nameOfUser,

              currentUser: userConfiguration.cognitoUserId,
            },
          ],
          null
        );
        message.success("User Updated Successfully!");
        saveAddedLogs({
          recordId: user?.identityId,
          recordName: user?.nameOfUser,
          actionType: "Change Department",
          category: "Users Actions",
          currentData: {
            [user?.nameOfUser]: `${selectedDepartment} - ${selectedRole}`,
          },
          previousData: {
            [user?.nameOfUser]: `${user?.departmentName} - ${user?.groupName}`,
          },
          updatedKeys: [
            {
              label: `${user?.nameOfUser} Role`,
              previousValue: `${user?.departmentName} - ${user?.groupName}`,
              updatedValue: `${selectedDepartment} - ${selectedRole}`,
            },
          ],
        }).catch((err) => console.log("Error Creating Logs: ", err));
      })
      .catch((err) => {
        message.error("ERROR");
        console.error(err);
      });
    dispatch(
      updateOtherUser({
        departmentName: selectedDepartment,
        groupName: selectedRole,
        routeConfig: findRoleAccess(roles, selectedRole)["Route"],
        sidebarConfig: findRoleAccess(roles, selectedRole)["Sidebar"],
      })
    );
    setVisible(false);
  }

  const onChangeDepartment = (e) => {
    setSelectedDepartment(e);
    form.setFieldsValue({ roles: "" });
    setSelectedRole("");
  };

  return (
    <Modal
      centered={true}
      open={visible}
      setVisible={setVisible}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}
      closeIcon={<XIcon />}
      title={`Change Department for ${user.nameOfUser}`}
      className={`changeDepartmentModal ${
        isDarkMode && "changeDepartmentModalDark"
      }`}
      footer={
        <div className="changeDepartmentFooterContainer">
          <MondayButton
            className="mondayButtonRed"
            onClick={() => {
              setVisible(false);
            }}
            Icon={<XIcon />}
          >
            Cancel
          </MondayButton>
          <MondayButton
            onClick={() => form.validateFields().then(onSave)}
            Icon={<TickIcon width={18} height={18} />}
            disabled={nothingChanged || !selectedRole || !selectedDepartment}
          >
            Save
          </MondayButton>
        </div>
      }
    >
      <Form form={form}>
        <InfoText
          {...{
            text: "Updating these details will alter the user's access privileges and responsibilities within the system.",
            style: { marginBottom: 20 },
            isDarkMode,
          }}
        />

        <div className="changeDepartmentInputContainer">
          <InputComponent
            {...{
              type: "select",
              formItemName: "department",
              label: "Department",
              placeholder: "Select Department",
              customOptions: allDepartments?.map(({ departmentName }) => ({
                label: departmentName,
                value: departmentName,
              })),
              onChange: onChangeDepartment,
              initialValue: selectedDepartment,
              required: true,
              onClear: () => {
                form.setFieldsValue({ roles: "" });
                setSelectedRole("");
                setRoles([]);
              },
            }}
          />
          <InputComponent
            {...{
              type: "select",
              formItemName: "roles",
              label: "Roles",
              placeholder: "Select Roles",
              customOptions: roles?.map((item) => ({
                label: Object.keys(item)[0],
                value: Object.keys(item)[0],
              })),
              onChange: (v) => {
                setSelectedRole(v);
              },
              initialValue: selectedRole,
              required: true,
            }}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default ChangeDepartment;
