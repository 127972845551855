import moment from "moment";

export const isDateBetween = (date, startDate, endDate) => {
  let finalDate = date;
  if (!moment(date).isValid()) {
    finalDate = moment(date, "MM/DD/YYYY").valueOf();
  }

  return (
    moment(finalDate).isSameOrAfter(moment(startDate), "day") &&
    moment(finalDate).isSameOrBefore(moment(endDate), "day")
  );
};
