import { useEffect, useState } from "react";
import moment from "moment";
import { Auth } from "aws-amplify";
import { Button, Input, Form } from "antd";

import { useUserSession } from "../../../hooks";
import { CompanyLogo } from "../../Sidebars/components";
import { EnterIcon } from "./Icons";
import { CoreWhiteLogo } from "../../../icons";
import idleImg from "../../../icons/IdleImg.jpg";
import idleImgCentral from "../../../icons/idleImgCentral.jpg";

import "./IdleComponent.scss";

const IdleComponent = ({
  isIdle,
  setIsIdle,
  credentialsResults,
  userConfiguration,
}) => {
  // const { userConfiguration } = useSelector((state) => state.userConfig);
  const [loginAttempts, setLoginAttempts] = useState(
    parseInt(window.localStorage.getItem("loginAttempts")) || 0
  );
  const [dateNow, setDateNow] = useState(moment());
  const sessionManager = useUserSession();
  const [form] = Form.useForm();

  useEffect(() => {
    isIdle && setInterval(() => setDateNow(moment()), 30000);
    form.resetFields();
  }, [isIdle]);

  async function signOut() {
    await sessionManager.close();
    await Auth.signOut().then((e) => {
      window.location.pathname = "/login";
      window.localStorage.setItem("loginAttempts", 0);
      window.localStorage.setItem("isIdle", false);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      isIdle &&
        document.getElementById("pinContainer").classList.remove("shake");
    }, 500);
    loginAttempts === 3 &&
      JSON.parse(window.localStorage.getItem("isIdle")) &&
      (async () => await signOut())();
    window.localStorage.setItem("loginAttempts", loginAttempts);
  }, [loginAttempts]);

  const IsCore = true;

  return (
    <div
      className="idleClassName"
      style={{
        overflow: "auto",
        display: isIdle ? "flex" : "none",
        width: "100%",
      }}
    >
      <div
        id="pinContainer"
        style={{
          flexDirection: IsCore ? "row" : "row-reverse",
          background: IsCore ? "rgba(0, 0, 0, 0.5)" : "rgba(232, 98, 43, 0.5)",
        }}
        className={
          loginAttempts === 0 ? "enterPinContainer" : "enterPinTryAgain"
        }
      >
        <div className="informationData">
          <div className="idleLogo">
            <CompanyLogo src={CoreWhiteLogo} />
          </div>
          {loginAttempts === 0 ? (
            <span className="idleText">
              Hello{" "}
              {
                <span className="idleUser">
                  {userConfiguration?.nameOfUser}
                </span>
              }
              , you've been away for some time,{<br />} please enter your PIN to
              unlock the screen.
            </span>
          ) : (
            <>
              <span className="idleText">
                I can't believe how can you forget the PIN so fast.
              </span>
              <span className="idleText">
                Please try again but be aware, we will {<br />}sign out you
                after three failing attempts.
              </span>
            </>
          )}
          <div className="inputIdleContainer">
            <Form form={form}>
              <Form.Item name={"pin"} noStyle>
                <Input.Password
                  onPressEnter={() => {
                    if (
                      form.getFieldValue("pin") === userConfiguration?.pinNumber
                    ) {
                      setIsIdle(false);
                      setLoginAttempts(0);
                      window.localStorage.setItem("loginAttempts", 0);
                    } else {
                      form.resetFields();
                      if (loginAttempts < 3) {
                        document
                          .getElementById("pinContainer")
                          .classList.add("shake");
                        setLoginAttempts((prev) => prev + 1);
                      }
                    }
                  }}
                  className="pinInput"
                  placeholder="Enter your pin"
                  autoFocus
                />
              </Form.Item>
            </Form>
            <Button
              onClick={() => {
                if (
                  form.getFieldValue("pin") === userConfiguration?.pinNumber
                ) {
                  setIsIdle(false);
                  setLoginAttempts(0);
                  window.localStorage.setItem("loginAttempts", 0);
                } else {
                  form.resetFields();
                  if (loginAttempts < 3) {
                    document
                      .getElementById("pinContainer")
                      .classList.add("shake");
                    setLoginAttempts((prev) => prev + 1);
                  }
                }
              }}
              className="pinButton"
              icon={<EnterIcon fill={IsCore ? "#4A4645" : "#E8622B"} />}
            />
          </div>
          {loginAttempts !== 0 && (
            <span
              className="idleTextAttempts"
              style={{ color: IsCore ? "#fe4c4a" : "#fff" }}
            >
              {3 - loginAttempts} attempts left
            </span>
          )}
          <div
            className="idleGoToLogin"
            onClick={async () => {
              await signOut();
            }}
          >
            Go to Login Page
          </div>
        </div>
      </div>
      <div className="timeIdleContainer">
        <span className="hourIdleText">{dateNow.format("hh:mm A")}</span>
        <span className="DateIdleText">
          {dateNow.format("LLLL").split(",").slice(0, 2).join(",")}
        </span>
      </div>
      <img
        className="idleImageClassName"
        src={IsCore ? idleImg : idleImgCentral}
      ></img>
    </div>
  );
};
export default IdleComponent;
