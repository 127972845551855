export const incidents = ({ tables, circleProgressPicker }) => {
  return {
    driverName: () => ({
      percent: (
        (tables?.Incidents?.filter(
          (a) =>
            a?.driverName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Incidents?.length) *
        100
      ).toFixed(1),
      data: tables?.Incidents?.filter(
        (a) =>
          a?.driverName?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }),

    fleetName: () => ({
      percent: (
        (tables?.Incidents?.filter(
          (a) =>
            a?.fleetName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Incidents?.length) *
        100
      ).toFixed(1),
      data: tables?.Incidents?.filter(
        (a) =>
          a?.fleetName?.toLowerCase() === circleProgressPicker?.toLowerCase()
      )?.length,
    }),

    incidentStatus: () => ({
      percent: (
        (tables?.Incidents?.filter(
          (a) =>
            a?.incidentStatus?.toLowerCase() ===
            circleProgressPicker?.toLowerCase()
        )?.length /
          tables?.Incidents?.length) *
        100
      ).toFixed(1),
      data: tables?.Incidents?.filter(
        (a) =>
          a?.incidentStatus?.toLowerCase() ===
          circleProgressPicker?.toLowerCase()
      )?.length,
    }),
  };
};
