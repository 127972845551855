import { useState, forwardRef, useImperativeHandle } from "react";
import { MondayButton } from "src/components/commonComponents";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

const ModalFooter = forwardRef(({ onCancel, onConfirm }, ref) => {
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [confirmText, setConfirmText] = useState("Confirm");

  useImperativeHandle(
    ref,
    () => {
      return {
        /** @param {boolean} state */
        changeConfirmDisabled(state) {
          if (state !== confirmDisabled) {
            setConfirmDisabled(state);
          }
        },
        /** @param {string} [text] */
        changeConfirmText(text = "Confirm") {
          if (text !== confirmText) {
            setConfirmText(text);
          }
        },
      };
    },
    [confirmDisabled, confirmText]
  );

  return [
    <MondayButton
      key="cancel"
      {...{
        className: "mondayButtonRed",
        onClick: onCancel,
        Icon: <XIcon />,
      }}
    >
      Cancel
    </MondayButton>,
    <MondayButton
      key="confirm"
      {...{
        Icon: <TickIcon />,
        onClick: onConfirm,
        disabled: confirmDisabled,
      }}
    >
      {confirmText}
    </MondayButton>,
  ];
});

export default ModalFooter;
