import { updateDocumentTitle } from "../../../../../../../../utils/updateDocumentTitle";

export const afterOpenChange = ({ event, objectData, currentCharge }) => {
  event &&
    updateDocumentTitle({
      newTitle:
        objectData?.type === "Charge"
          ? currentCharge
            ? `Edit charge no. ${currentCharge.chargeNumber}`
            : "Add new charge item"
          : currentCharge
          ? `Edit credit item no. ${currentCharge.chargeNumber}`
          : "Add new credit memo item",
    });
};
