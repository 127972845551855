import { queryParams } from "../../../../../../utils/queryParams";

export const navigateToRoute = (billData, navigate) => {
  if (billData) {
    const route = !billData.isAccount
      ? `/vendors/${billData?.vendorId}?${queryParams({ tab: "Bills" })}`
      : `/vendors?${queryParams({ tab: "Bills" })}`;
    navigate(route);
  }
};
