export const sortSessionUsers = (users) => {
  return users.sort((a, b) => {
    if (a.onlineStatus && !b.onlineStatus) {
      return -1;
    } else if (!a.onlineStatus && b.onlineStatus) {
      return 1;
    } else {
      if (a.lastSeen === "now") {
        return -1;
      } else if (b.lastSeen === "now") {
        return 1;
      } else if (a.lastSeen && b.lastSeen) {
        return new Date(b.lastSeen) - new Date(a.lastSeen);
      } else if (a.lastSeen && !b.lastSeen) {
        return -1;
      } else if (!a.lastSeen && b.lastSeen) {
        return 1;
      } else {
        return 0;
      }
    }
  });
};

export function applyFiltersSessionUsers(users, filters = {}) {
  const sorted = sortSessionUsers(users);
  return sorted.filter((user) => {
    return Object.entries(filters)?.every(([key, value]) => {
      if (value?.length > 0) {
        if (key === "status") {
          if (value === "Online") {
            return !!user?.onlineStatus;
          } else if (value === "Offline") {
            return !user?.onlineStatus && !user?.isSuspended;
          } else if (value === "Logged Out") {
            return (
              (user?.activeSessions || []).length === 0 ||
              !user?.activeSessions?.some((session) => !session?.logout)
            );
          } else {
            return false;
          }
        } else if (key === "departments") {
          return value?.includes(user?.departmentName);
        } else if (key === "roles") {
          const newVal = value.map((val) => ({
            department: val.split("*")[0],
            role: val.split("*")[1],
          }));
          return newVal?.some(({ department, role }) => {
            return (
              user?.groupName === role && user?.departmentName === department
            );
          });
        } else {
          return true;
        }
      }
      return true;
    });
  });
}
