import { formatCurrency, getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../../Dashboard/addWidgetsModalData";
import {
  calculateInvoiceStatus,
  categoriesName,
  getSums,
  groupBy,
} from "../../../../Dashboard/logicDataFunctions/calculatingFunctions";
//this function returns the filtered data for the specified category (like leads, opportunity...) and subcategory (like leadStatus,leadAddress...)
export const CircleFilterFunctions = ({
  updatedDynamicStates,
  circleProgressPicker,
  dynamicStates,
  id,
  clear = false,
}) => {
  const dataToFilter = clear ? dynamicStates[id] : updatedDynamicStates[id];
  return {
    Leads: () => ({
      leadStatus: () =>
        (
          (dataToFilter?.filter((a) => a?.leadStatus === circleProgressPicker)
            ?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
      leadAddress: () =>
        (
          (dataToFilter?.filter((a) => a?.borough === circleProgressPicker)
            ?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }), //Leads
    Applications: () => {
      const filteredData = dataToFilter?.filter(
        (a) => a?.applicationStatus === circleProgressPicker
      );
      return {
        applicationStatus: () => ({
          percent: (
            (filteredData?.length / dataToFilter?.length) *
            100
          )?.toFixed(1),
          custom:
            filteredData &&
            groupBy([filteredData], "projectName")?.map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Current Payment": formatCurrency(
                      getSums({ data: [filteredData], key: "totalAmount" })[
                        index
                      ]
                    ),
                    "Total Retainage": formatCurrency(
                      getSums({
                        data: [filteredData],
                        key: "totalRetainage",
                      })[index]
                    ),
                    "Total Rental With Tax": formatCurrency(
                      getSums({
                        data: [filteredData],
                        key: "totalRentalWithTax",
                      })[index]
                    ),
                  },
                },
                ...Object?.entries(el)?.map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Current Payment": formatCurrency(
                        getSums({ data: value, key: "totalAmount" })
                      ),
                      "Total Retainage": formatCurrency(
                        getSums({ data: value, key: "totalRetainage" })
                      ),
                      "Total Rental With Tax": formatCurrency(
                        getSums({ data: value, key: "totalRentalWithTax" })
                      ),
                    },
                  };
                }),
              ];
            }),
        }),
      };
    },
    Rentals: () => {
      const filteredData = dataToFilter?.filter(
        (a) => a?.rentalStatus === circleProgressPicker
      );

      return {
        rentalStatus: () => ({
          percent: (
            (filteredData?.length / dataToFilter?.length) *
            100
          )?.toFixed(1),
          custom:
            filteredData &&
            groupBy([filteredData], "projectAddress")?.map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Total Price": formatCurrency(
                      getSums({ data: [filteredData], key: "totalPrice" })[
                        index
                      ]
                    ),
                    "Total Applied Rent": formatCurrency(
                      getSums({
                        data: [filteredData],
                        key: "totalAppliedRent",
                      })[index]
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: [filteredData], key: "totalTax" })[index]
                    ),
                    "Retainage Amount": formatCurrency(
                      getSums({ data: [filteredData], key: "retainageAmount" })[
                        index
                      ]
                    ),
                  },
                },
                ...Object?.entries(el)?.map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Total Price": formatCurrency(
                        getSums({ data: value, key: "totalPrice" })
                      ),
                      "Total Applied Rent": formatCurrency(
                        getSums({ data: value, key: "totalAppliedRent" })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: value, key: "totalTax" })
                      ),
                      "Retainage Amount": formatCurrency(
                        getSums({ data: value, key: "retainageAmount" })
                      ),
                    },
                  };
                }),
              ];
            }),
        }), //rentalStatus
      };
    },

    Claims: () => ({
      claimStatus: () =>
        (
          (dataToFilter?.filter((a) => a?.claimStatus === circleProgressPicker)
            ?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }),

    Charges: () => {
      const filteredData = dataToFilter?.filter(
        (a) => a?.categoryFrom === circleProgressPicker
      );

      return {
        categoryFrom: () => ({
          percent: (
            (filteredData?.length / dataToFilter?.length) *
            100
          )?.toFixed(1),
          custom:
            filteredData &&
            groupBy([filteredData], "projectAddress")?.map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Charge Amount With Tax": formatCurrency(
                      getSums({ data: [filteredData], key: "chargeAmount" })[
                        index
                      ] || 0
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: [filteredData], key: "taxAmount" })[
                        index
                      ] || 0
                    ),
                    "Charge Amount Without Tax": formatCurrency(
                      (getSums({ data: [filteredData], key: "chargeAmount" })[
                        index
                      ] || 0) -
                        (getSums({ data: [filteredData], key: "taxAmount" })[
                          index
                        ] || 0) || 0
                    ),
                  },
                },
                ...Object.entries(el).map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Charge Amount With Tax": formatCurrency(
                        getSums({ data: value, key: "chargeAmount" })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: value, key: "taxAmount" })
                      ),
                      "Charge Amount Without Tax": formatCurrency(
                        getSums({ data: value, key: "chargeAmount" }) -
                          getSums({ data: value, key: "taxAmount" })
                      ),
                    },
                  };
                }),
              ];
            }),
        }),
      };
    },
    Invoices: () => {
      const filteredData = calculateInvoiceStatus({
        table: updatedDynamicStates?.[id],
        status: circleProgressPicker,
      });

      return {
        invoiceStatus: () => ({
          percent: (
            ((filteredData?.length || 0) / dataToFilter?.length) *
            100
          )?.toFixed(1),
          custom:
            filteredData &&
            groupBy([filteredData], "projectName")?.map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({
                        data: [filteredData],
                        key: "totalInvoiceAmount",
                      })[index] || 0
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: [filteredData], key: "subtotal" })[
                        index
                      ] || 0
                    ),
                    "Total Tax": formatCurrency(
                      (getSums({
                        data: [filteredData],
                        key: "totalInvoiceAmount",
                      })[index] || 0) -
                        (getSums({ data: [filteredData], key: "subtotal" })[
                          index
                        ] || 0)
                    ),
                  },
                },
                ...Object.entries(el).map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Invoice Amount With Tax": formatCurrency(
                        getSums({ data: value, key: "totalInvoiceAmount" })
                      ),
                      "Invoice Amount Without Tax": formatCurrency(
                        getSums({ data: value, key: "subtotal" })
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: value, key: "totalInvoiceAmount" }) -
                          getSums({ data: value, key: "subtotal" })
                      ),
                    },
                  };
                }),
              ];
            }),
        }),
      };
    },
    Opportunities: () => ({
      opportunityStage: () =>
        (
          (dataToFilter?.filter(
            (a) => a?.opportunityStage === circleProgressPicker
          )?.length /
            dataToFilter?.length) *
          100
        ).toFixed(1),

      opportunityStatus: () =>
        (
          (dataToFilter?.filter(
            (a) => a?.opportunityStatus === circleProgressPicker
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }), //opportunities
    Projects: () => ({
      projectStatus: () =>
        (
          (dataToFilter?.filter(
            (a) => a?.projectStatus === circleProgressPicker
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      attorney: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.attorney?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      expeditor: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.expeditor?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }), //Projects
    Documentation: () => ({
      docType: () =>
        (
          (dataToFilter?.filter((a) => a?.docType === circleProgressPicker)
            ?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }), //Documentation
    Scheduling: () => ({
      typeOfWork: () =>
        (
          (dataToFilter?.filter((a) => a?.typeOfWork === circleProgressPicker)
            ?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }), //schaduling

    Dispatching: () => ({
      fleetName: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.fleetName?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      driverName: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.driverName?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      dispatchedBy: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.dispatchedBy?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }), //Dispatching

    Fleet: () => ({
      fleetYear: () =>
        (
          (dataToFilter?.filter((a) => a?.fleetYear === circleProgressPicker)
            ?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      registrationExpDate: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.registrationExpDate >=
                getMonthsTimestamps()[months?.indexOf(circleProgressPicker)] &&
              a?.registrationExpDate <=
                getMonthsTimestamps("end")[
                  months?.indexOf(circleProgressPicker)
                ]
          )?.length /
            dataToFilter?.filter(
              (a) => a?.registrationExpDate >= getMonthsTimestamps()[0]
            )?.length) *
          100
        )?.toFixed(1),

      inspectionExpDate: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.inspectionExpDate >=
                getMonthsTimestamps()[months?.indexOf(circleProgressPicker)] &&
              a?.inspectionExpDate <=
                getMonthsTimestamps("end")[
                  months?.indexOf(circleProgressPicker)
                ]
          )?.length /
            dataToFilter?.filter(
              (a) => a?.inspectionExpDate >= getMonthsTimestamps()[0]
            )?.length) *
          100
        )?.toFixed(1),
    }), //Fleet

    "Fleet Inspections": () => ({
      inspectedBy: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.inspectedBy?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      driverName: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.driver?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      createdAt: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.createdAt >=
                getMonthsTimestamps()[months?.indexOf(circleProgressPicker)] &&
              a?.createdAt <=
                getMonthsTimestamps("end")[
                  months?.indexOf(circleProgressPicker)
                ]
          ).length /
            dataToFilter?.filter(
              (a) => a?.createdAt >= getMonthsTimestamps()[0]
            )?.length) *
          100
        )?.toFixed(1),
    }), //Inspections
    Maintenance: () => ({
      createdAt: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.createdAt >=
                getMonthsTimestamps()[months?.indexOf(circleProgressPicker)] &&
              a?.createdAt <=
                getMonthsTimestamps("end")[
                  months?.indexOf(circleProgressPicker)
                ]
          )?.length /
            dataToFilter?.filter(
              (a) => a?.createdAt >= getMonthsTimestamps()[0]
            )?.length) *
          100
        )?.toFixed(1),
    }), //Maintenance

    Violations: () => ({
      amount_due: () =>
        (
          ((() => {
            const filteredArray = dataToFilter
              ?.filter(
                (b) =>
                  b?.issue_date >=
                    getMonthsTimestamps()[
                      months?.indexOf(circleProgressPicker)
                    ] &&
                  b?.issue_date <=
                    getMonthsTimestamps("end")[
                      months?.indexOf(circleProgressPicker)
                    ]
              )
              ?.map((a) => a?.amount_due);

            return !!filteredArray?.length
              ? filteredArray?.reduce(
                  (previousValue, currentValue) =>
                    parseInt(previousValue) + parseInt(currentValue)
                )
              : 0;
          })() /
            (() => {
              const filteredArray = dataToFilter
                ?.filter(
                  (b) =>
                    b?.issue_date >=
                    getMonthsTimestamps()[months?.indexOf(circleProgressPicker)]
                )
                ?.map((a) => a?.amount_due);

              return !!filteredArray?.length
                ? filteredArray?.reduce(
                    (previousValue, currentValue) =>
                      parseInt(previousValue) + parseInt(currentValue)
                  )
                : 0;
            })()) *
          100
        ).toFixed(1),

      issue_date: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.issue_date >=
                getMonthsTimestamps()[months?.indexOf(circleProgressPicker)] &&
              a?.issue_date <=
                getMonthsTimestamps("end")[
                  months?.indexOf(circleProgressPicker)
                ]
          )?.length /
            dataToFilter?.filter(
              (a) =>
                a?.issue_date >=
                getMonthsTimestamps()[months?.indexOf(circleProgressPicker)]
            )?.length) *
          100
        )?.toFixed(1),
    }), //Violations

    Estimations: () => ({
      estSTATUS: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.estSTATUS?.toLowerCase() ===
              circleProgressPicker?.toLocaleLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      archAssignedTo: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.archAssignedTo?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      engAssignedTo: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.engAssignedTo?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      statusByArch: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.statusByArch?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),

      statusByEng: () =>
        (
          (dataToFilter?.filter(
            (a) =>
              a?.statusByEng?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            dataToFilter?.length) *
          100
        )?.toFixed(1),
    }), //Estimations
  }; //CircleProgressLine
};
