import React from "react";
import { useSelector } from "react-redux";

import { useRedux } from "../../../hooks";
import { FormCard } from "../../../Fleet/components";
import { exportBillToPdf } from "./utils/exportToPdf";
import { exportBillToExcel } from "./utils/exportBillToExcel";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import { ExportOrEmailDropdown } from "../../../../commonComponents";
import { useInitialBillContext } from "../context/InitialBillProvider";
import ToDoButton from "../../../ToDos/components/ToDoButton/ToDoButton";
import ReportButton from "../../../Reports/components/ReportButton/ReportButton";
import DynamicEditLogs from "../../../../commonComponents/DynamicEditLogs/DynamicEditLogs";

import "./ControlPanel.scss";
import { NotesIcon } from "../../../DynamicView/src";

const ControlPanel = () => {
  const { billData } = useInitialBillContext();

  const [isWritable] = useRedux("billIsWritable");

  const { base64 } = useSelector((state) => state.base64);

  if (!billData) return null;

  return (
    <FormCard className="bill-control-panel-container">
      <ReportButton
        recordId={billData?.billId}
        recordName="Bill"
        identityId="billId"
        iconOnly
      />
      <Notes
        noteModalName={`${billData.projectName} | Bill ${billData.billNumber}`}
        rowId={billData.billId}
        topicCategory="Bills"
        disableNotes={!isWritable}
        customIcon={<NotesIcon />}
      />
      <ToDoButton
        recordId={billData?.billId}
        recordName={`Bill #${billData?.billNumber}`}
        iconOnly
      />
      <DynamicEditLogs
        filterValue={billData?.billId}
        filterKey="recordId"
        title={`Bill #${billData.billNumber} Logs`}
      />
      <ExportOrEmailDropdown
        {...{
          rowData: true,
          onGeneratePDF: () => exportBillToPdf(billData, base64),
          exportGridToExcel: () => exportBillToExcel(billData),
        }}
      />
    </FormCard>
  );
};

export default ControlPanel;
