import moment from "moment";

export const expirationDateObject = {
  label: "Expiration Date",
  formItemName: "expirationDate",
  placeholder: "Expiration Date",
  type: "datepicker",
  // disabledDate: (curr) => curr < moment(),
  required: true,
};
