import { message } from "antd";

const DEFAULT_KEY = "windowMessages";
const DEFAULT_DURATION = 3;
const DEFAULT_SUCCESS_MSG = "Saved!";
const DEFAULT_LOADING_MSG = "Saving...";
const DEFAULT_ERROR_MSG = "Something went wrong.";

export const showLoadingMsg = (params) => {
  const {
    content = DEFAULT_LOADING_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
  } = params || {};
  return message.loading({ content, key, duration });
};

export const showSuccessMsg = (params) => {
  const {
    content = DEFAULT_SUCCESS_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};
  return message.success({ content, key, duration, className });
};

export const showErrorMsg = (params) => {
  const {
    content = DEFAULT_ERROR_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};
  return message.error({ content, key, duration, className });
};

export const showWarningMsg = (params) => {
  const {
    content = DEFAULT_ERROR_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};

  return message.warning({ content, key, duration, className });
};

export const showInfoMsg = (params) => {
  const {
    content = DEFAULT_ERROR_MSG,
    key = DEFAULT_KEY,
    duration = DEFAULT_DURATION,
    className = "",
  } = params || {};

  return message.info({ content, key, duration, className });
};
