import { message } from "antd"

//  Called when a cell editing has started.
export const cellEditingStarted = ({ params, isWritable, isLoading }) => {
  if (!isWritable) {
    params.api.stopEditing(true)
    message.error("Please enable write mode")
  }
  if (isLoading) {
    params.api.stopEditing(true)
    message.error("Please wait until it finishes updating")
  }
}
