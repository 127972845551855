import VideoCard from "../VideoCard/VideoCard";

const NewCategories = ({
  newVideos,
  isFavourite = false,
  isNewVideo = false,
  isUnfinishedVideo = false,
}) => {
  return (
    <div
      data-testid="newCategories-container"
      className="newCategories-container"
    >
      {newVideos.map((video) => (
        <VideoCard
          VideoCard
          key={video.id}
          data={video}
          subCategoryName={video.subCategoryName}
          isFavourite={isFavourite}
          isNewVideo={isNewVideo}
          isUnfinishedVideo={isUnfinishedVideo}
        />
      ))}
    </div>
  );
};

export default NewCategories;
