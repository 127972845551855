import { generateDescription } from "../../../../../../../../Charges/datas";
import { formatCurrency } from "../../../../../../../../../../utils";

import RichTextModalEditor from "../../../../../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor";
import moment from "moment";
import { message } from "antd";
import "./../../../../../../../../../../../../components/SidebarPages/Documentation/DocumentationPage.scss";
export const columnDefs = [
  {
    headerName: "No.",
    cellRenderer: (params) => params?.rowIndex + 1,
    // flex: 1,
    minWidth: 140,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>A</div><div class='textContainerStretched'>#</div></div>",
    },
  },
  {
    headerName: "Service",
    minWidth: 160,
    flex: 2,
    field: "label",
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>B</div><div class='textContainerStretched'>Service</div></div>",
    },
  },
  {
    headerName: "Start Date",
    field: "startDate",
    minWidth: 160,
    flex: 2,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Start Date</div></div>",
    },
    cellRenderer: ({ value }) =>
      !!value ? moment(value)?.format("MM/DD/YYYY") : "--",
  },

  {
    headerName: "End Date",
    field: "endDate",
    minWidth: 160,
    flex: 2,
    cellRenderer: ({ value }) =>
      !!value ? moment(value)?.format("MM/DD/YYYY") : "--",
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>End Date</div></div>",
    },
  },
  {
    headerName: "Weeks",
    minWidth: 160,
    field: "weeks",
    flex: 1,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Weeks</div></div>",
    },
    cellRenderer: ({ data }) => {
      if (!!data?.startDate && !!data?.endDate) {
        const msInWeek =
          moment(data.endDate).diff(moment(data.startDate), "days") + 1;
        let temp = (Math.abs(msInWeek) / 7).toFixed(1);
        return temp;
      } else {
        return "0";
      }
    },
  },
  {
    headerName: "Applied Rent",
    field: "appliedAmount",
    minWidth: 200,
    cellRenderer: ({ data }) => {
      return formatCurrency(data?.appliedAmount || 0);
    },
    flex: 1,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Applied Amount</div></div>",
    },
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    minWidth: 160,
    flex: 2,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Tax Rate</div></div>",
    },
    cellRenderer: ({ value }) => (!!value ? `${value * 100}%` : "--"),
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    minWidth: 160,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Tax Amount</div></div>",
    },
    cellRenderer: ({ data }) => {
      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount || 0;
        return formatCurrency(taxAmount);
      } else {
        return formatCurrency(0);
      }
    },
    flex: 1,
  },
  {
    headerName: "Retainage %",
    field: "retainage",
    flex: 2,
    minWidth: 160,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Retainage (%)</div></div>",
    },
    valueGetter: ({ data }) => {
      return data?.retainage;
      // let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
      // return totalities.retainageAmount || 0;
    },
    // cellRenderer: ({ data }) => {
    // 	let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
    // 	return formatCurrency(totalities.retainageAmount || 0);
    // },
  },
  {
    headerName: "Retainage Amount",
    field: "retainage",
    flex: 2,
    minWidth: 160,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>I</div><div class='textContainerStretched'>Retainage Amount</div></div>",
    },
    valueGetter: ({ data }) => {
      let tax = data?.taxRate || 0;
      console.log(
        "mydata",
        (data?.retainage / 100) *
          (data?.appliedAmount + data?.appliedAmount * tax)
      );
      if (data?.retainage > 0) {
        return (
          (data?.retainage / 100) *
          (data?.appliedAmount + data?.appliedAmount * tax)
        );
      } else {
        return 0;
      }
      // let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
      // return totalities.retainageAmount || 0;
    },
    cellRenderer: ({ value }) => {
      return formatCurrency(value || 0);
    },
  },
  {
    headerName: "Credit",
    editable: false,
    field: "serviceLevelCredit",
    minWidth: 160,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Credit</div></div>",
    },
    cellRenderer: ({ data }) => {
      return formatCurrency(
        data?.serviceLevelCredit + data?.serviceLevelCredit * data?.taxRate || 0
      );
    },
    valueSetter: (params) => {
      console.log("params", params);
      if (isNaN(params.newValue)) {
        message.error("Please insert a valid number");
        return false;
      }

      if (params.newValue > 100000) {
        message.error("input is larger than allowed");
        return false;
      } else {
        params.data.serviceLevelCredit =
          parseFloat(params.newValue) > 0
            ? -parseFloat(params.newValue)
            : parseFloat(params.newValue);
        params.api.refreshCells({ force: true });
        return true;
      }
    },
  },
  {
    headerName: "Details",
    flex: 2,
    field: "serviceOptions",
    minWidth: 180,
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Details</div></div>",
    },
    cellRendererFramework: ({ value }) => {
      const generatedDescription = generateDescription(value);
      return (
        <RichTextModalEditor
          {...{
            value: generatedDescription,
            disabledEditing: true,
            saveButton: false,
            darkMode: true,
          }}
        />
      );
    },
  },
  {
    headerName: "T-C",
    minWidth: 160,
    headerTooltip: "Tax",

    // field: "TotalCredit",
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'><div class='letterContainer'>Total - Credit</div><div class='textContainerStretched'>Total - Credit</div></div>",
    },
    cellRenderer: ({ data }) => {
      let credit =
        Math.abs(data?.serviceLevelCredit || 0) +
        (Math.abs(data?.serviceLevelCredit) * data?.taxRate || 0);

      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount;
        return formatCurrency(data?.appliedAmount + taxAmount - credit);
      } else {
        return formatCurrency(data?.appliedAmount - credit);
      }
    },
  },
  {
    headerName: "Total Amount",
    field: "totalApplied",
    minWidth: 160,
    cellRenderer: ({ data }) => {
      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount;
        return formatCurrency(data?.appliedAmount + taxAmount);
      } else {
        return formatCurrency(data?.appliedAmount);
      }
    },
    headerComponentParams: {
      template:
        "<div class='headerDivContainer'>Total Amount<div class='letterContainer'>C</div><div class='textContainerStretched'>Total Amount</div></div>",
    },
    flex: 1,
  },
];

const totalitiesTotalTaxGen = ({ fakeRentals }) => {
  console.log("to result", { fakeRentals });

  let carriedProjectTax = fakeRentals?.taxRate || 0;
  let result = {
    totalTax: 0,
    totalPrice: Math.round(appliedAmountGenerator({ fakeRentals })) || 0,
  };
  if (carriedProjectTax > 0) {
    result.totalTax = result.totalPrice * carriedProjectTax;
    result.totalPrice = result.totalPrice + result.totalTax;
  }
  result.retainageAmount = fakeRentals?.services?.reduce((acc, curr) => {
    let currentService = Math.round(
      appliedAmountGenerator({
        fakeRentals: { ...fakeRentals, services: [curr] },
      })
    );
    let tempRet = curr?.retainage || 0;
    if (tempRet > 0) {
      return acc + currentService * (tempRet / 100);
    } else return acc + 0;
  }, 0);

  return result;
};
const appliedAmountGenerator = ({ fakeRentals }) => {
  return (
    fakeRentals?.services
      // here we extract all services
      ?.flatMap(({ serviceOptions }) =>
        serviceOptions?.flatMap(({ items }) =>
          // from the extracted services we extract all weeks and take the amount from those weeks
          items?.flatMap(({ rentalDetails }) =>
            rentalDetails?.details?.map(({ weeklyAmount }) => weeklyAmount)
          )
        )
      )
      //here we add all extracted weekAmounts together
      ?.reduce((acc, curr) => acc + curr, 0)
  );
};

export const previewColumnDefs = [
  {
    headerName: "No.",
    cellRenderer: (params) => params?.rowIndex + 1,
    // flex: 1,
    // minWidth: 0,
    width: 50,
  },
  {
    headerName: "Service",
    // flex: 2,
    field: "label",
  },
  {
    headerName: "Start Date",
    field: "startDate",
    editable: false,
    // flex: 2,

    cellRenderer: ({ value }) =>
      !!value ? moment(value)?.format("MM/DD/YYYY") : "--",
  },

  {
    headerName: "End Date",
    field: "endDate",
    editable: false,
    // flex: 2,
    cellRenderer: ({ value }) =>
      !!value ? moment(value)?.format("MM/DD/YYYY") : "--",
  },
  {
    headerName: "Weeks",
    field: "weeks",
    // flex: 1,

    cellRenderer: ({ data }) => {
      if (!!data?.startDate && !!data?.endDate) {
        const msInWeek =
          moment(data.endDate).diff(moment(data.startDate), "days") + 1;
        let temp = (Math.abs(msInWeek) / 7).toFixed(1);
        return temp;
      } else {
        return "0";
      }
    },
  },
  {
    headerName: "Applied Rent",
    field: "appliedAmount",
    cellRenderer: ({ data }) => {
      return formatCurrency(data?.appliedAmount || 0);
    },
    // flex: 1,
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    cellRenderer: ({ value }) => (!!value ? `${value * 100}%` : "--"),
  },

  {
    headerName: "Retainage %",
    field: "retainage",

    valueGetter: ({ data }) => {
      return data?.retainage;
      // let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
      // return totalities.retainageAmount || 0;
    },
    // cellRenderer: ({ data }) => {
    // 	let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
    // 	return formatCurrency(totalities.retainageAmount || 0);
    // },
  },

  {
    headerName: "Total Amount",
    field: "totalApplied",

    cellRenderer: ({ data }) => {
      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount;
        return formatCurrency(data?.appliedAmount + taxAmount);
      } else {
        return formatCurrency(data?.appliedAmount);
      }
    },

    flex: 1,
  },
];
