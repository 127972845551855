import { ClockCircleOutlined } from "@ant-design/icons";
import { CommuteIcon } from "src/assets";

function InfoTripDetails({ directions = {}, setDirections = () => {} }) {
  return (
    <div className="trip-details-container">
      <div className="route-detail">
        <CommuteIcon height={16} width={16} />
        <b className="detail-title">Distance:</b>
        <span className="detail-content">
          {directions?.routes?.[0]?.legs?.[0]?.distance?.text}
        </span>
      </div>
      <div className="route-detail">
        <ClockCircleOutlined height={16} width={16} />
        <b className="detail-title">Duration:</b>
        <span className="detail-content">
          {directions?.routes?.[0]?.legs?.[0]?.duration?.text}
        </span>
      </div>
      <div className="route-detail" id="detail-button">
        <span
          className="mondayButtonRed redButton"
          onClick={() => {
            setDirections();
          }}
        >
          Close Directions
        </span>
      </div>
    </div>
  );
}

export default InfoTripDetails;
