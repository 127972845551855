import React from "react";
import "./ReplyMessages.scss";
import { XIcon } from "../../../../../../assets/icons";
const ReplyMessages = (props) => {
  return (
    <div className="replyMessages">
      <div className="replyToSender">
        <b>Reply to {props.replyTo.sender}</b>
        <XIcon cursor={"pointer"} onClick={() => props.setReplyTo(null)} />
      </div>
      <span>{props.replyTo.message}</span>
    </div>
  );
};

export default ReplyMessages;
