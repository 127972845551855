import "./Header.scss";

const Header = ({ setChosenWay, chosenWay }) => {
	return (
		<div className="chooseFromContainer">
			<div className="choose-from-title">Create from: </div>

			<button
				className={chosenWay === "Estimation" ? "estimationButtonPressed" : "estimationButton"}
				onClick={(e) => setChosenWay("Estimation")}
			>
				Estimation
			</button>
			<button
				className={chosenWay === "Other" ? "fromOtherButtonButtonPressed" : "fromOtherButtonButton"}
				onClick={(e) => setChosenWay("Other")}
			>
				Other
			</button>
		</div>
	);
};

export default Header;
