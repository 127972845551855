export const mockedUseSelector = {
  userConfiguration: {
    nameOfUser: "nameOfUser",
    userName: "userName",
    cognitoUserId: "cognitoUserId",
    allUsers: {
      Items: [{ cognitoUserId: "cognitoUserId", nameOfUser: "nameOfUser" }],
    },
  },
  accessToken: "accessToken",
};

export const formatTeamFormField = (team = []) => {
  return team.map((el) => ({
    label: el.teamName,
    value: el.teamName,
    members: el.members?.map(
      ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
        identityId,
        nameOfUser,
        cognitoUserId,
      })
    ),
  }));
};
