export const defaultColumnDefs = {
  resizable: true,
  enableColResize: true,
  enableRowGroup: true,
  sortable: true,
  filter: true,
  flex: 1,
  suppressSizeToFit: true,
  minWidth: 130,
};
