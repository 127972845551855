import { Radio, Tooltip } from "antd";
import {
  MondayButton,
  TooltipMonday,
} from "../../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { NormalSizedModal } from "../../../../../../../../SidebarPages/Fleet/components";
import { googleScopesDescription, googleScopesIcons } from "./helpers";
import { useState } from "react";
import "./GoogleScopesInfoModal.scss";

function GoogleScopesInfoModal({
  googleScopesModalVisible,
  setGoogleScopesModalVisible,
}) {
  const [selectedCategory, setSelectedCategory] = useState("Google Calendar");
  const googleAuthAccessTokenObj = localStorage.getItem(
    "googleAuthAccessToken"
  );

  const currScopes = JSON.parse(googleAuthAccessTokenObj)?.scope?.split(" ");

  const filteredList = currScopes?.filter((el) =>
    selectedCategory === "all"
      ? el
      : googleScopesDescription?.[el]?.category === selectedCategory
  );

  return (
    <NormalSizedModal
      {...{
        visible: googleScopesModalVisible,
        setVisible: setGoogleScopesModalVisible,
        title: "Access you’ve given to Core Portal",
        centered: true,
        customFooter: (
          <MondayButton
            onClick={() => {
              setGoogleScopesModalVisible(false);
            }}
            Icon={<XIcon />}
            className="mondayButtonRed"
          >
            Close
          </MondayButton>
        ),
        closable: true,
        className: "googleScopesInfoModal",
      }}
    >
      <Radio.Group
        onChange={(e) => {
          setSelectedCategory(e.target.value);
        }}
        defaultValue={selectedCategory}
        className="googleScopesInfoModal__radioGroup"
      >
        {Object.keys(googleScopesIcons).map((key) => {
          return (
            <TooltipMonday text={key} key={key}>
              <Radio.Button
                key={key}
                value={key}
                className={`radio${key.split(" ").join("")}`}
              >
                {key !== "Google Account" ? (
                  <img
                    src={googleScopesIcons[key]}
                    alt="icon"
                    style={{ width: 20, height: 20 }}
                  />
                ) : (
                  googleScopesIcons[key]
                )}
              </Radio.Button>
            </TooltipMonday>
          );
        })}
      </Radio.Group>
      <div className="listContainer">
        {filteredList?.length ? (
          filteredList.map((el, i) => {
            const description = googleScopesDescription?.[el]?.desc;
            const category = googleScopesDescription?.[el]?.category;
            if (!description) return null;

            return (
              <div key={i} className="scopeListItem">
                {category !== "Google Account" ? (
                  <img
                    src={googleScopesIcons[category]}
                    alt="icon"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                ) : (
                  googleScopesIcons[category]
                )}
                <p>{googleScopesDescription?.[el]?.desc}</p>
              </div>
            );
          })
        ) : (
          <p
            style={{ color: "red" }}
          >{`You have not given access to ${selectedCategory}!`}</p>
        )}
      </div>
    </NormalSizedModal>
  );
}

export default GoogleScopesInfoModal;
