import { Select } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { DropdownIcon } from "../../../BasePage/src";

const AddressFilter = ({
  id = "",
  table = "",
  filterStates = {},
  tmpFilterStates = {},
  dynamicStates = {},
  filterValueTurnery = false,
  setFilterStates = () => {},
}) => {
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const addressKey = useMemo(() => {
    const { keysToUse = [""] } =
      topicCategories?.find((el) => el?.categoryName === table) || {};

    const foundKey = keysToUse?.find(
      (el) =>
        el?.toLowerCase().includes("address") ||
        el?.toLowerCase() === "projectname"
    );

    if (!foundKey) {
      if ((dynamicStates?.[id]?.[0] || {})?.hasOwnProperty("projectAddress")) {
        return "projectAddress";
      } else if (
        (dynamicStates?.[id]?.[0] || {})?.hasOwnProperty("jobsiteAddress")
      ) {
        return "jobsiteAddress";
      } else if (
        (dynamicStates?.[id]?.[0] || {})?.hasOwnProperty("scheduleAddress")
      ) {
        return "scheduleAddress";
      }
    }
    return foundKey;
  }, [topicCategories, table]);

  const uniqAddresses = useMemo(() => {
    const addresses = new Set(
      dynamicStates?.[id]?.map((el) => el?.[addressKey])?.filter(Boolean)
    );

    return Array.from(addresses);
  }, [dynamicStates?.[id], addressKey]);

  return addressKey ? (
    <div className="filterComponents">
      <div className="tittle">Filter By Address</div>
      <div className="filterContainer">
        <div className="leftSideContainer">
          <div className="tittleContainer">Select the Address</div>
        </div>

        <div className="rightSideContainer">
          <Select
            {...{
              popupClassName: "dashDropDown",
              placeholder: "Select Address",
              suffixIcon: <DropdownIcon />,
              onSelect: (e) =>
                setFilterStates((prev) => ({
                  ...prev,
                  filterByName: e,
                })),
              showSearch: true,
              className: "status-selector",
              value: filterValueTurnery
                ? filterStates?.filterByName
                : tmpFilterStates?.filterByName,
              options: uniqAddresses?.map((el) => ({
                label: el,
                value: el,
              })),
            }}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default AddressFilter;
