import {
  formatCurrency,
  getMonthsTimestamps,
  getRandomColor,
} from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import { getMonthData, getSums, groupBy } from "../../calculatingFunctions";

export const applications = ({ tables, programFields, applicationsData }) => {
  const applicationStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = applicationStatus?.statusName?.map((status) =>
    tables?.applications?.filter(
      (a) => a?.applicationStatus?.toLowerCase() === status?.toLowerCase()
    )
  );

  const data = getMonthData(tables?.applications, "applicationId")?.map(
    ({ id }) => {
      return id?.map((el) => {
        return tables?.applications?.find(
          ({ applicationId }) => applicationId === el
        );
      });
    }
  );

  return {
    applicationStatus: () => [
      {
        labels: applicationStatus?.statusName,
        data: statusData?.map((e) => e?.length),
        backgroundColor: applicationStatus?.statusColor,
        custom:
          !!statusData[0] &&
          groupBy(statusData, "projectName")?.flatMap((el, index) => {
            return [
              {
                "Total Amount": {
                  "Current Payment": formatCurrency(
                    getSums({ data: statusData, key: "totalAmount" })[index]
                  ),
                  "Total Retainage": formatCurrency(
                    getSums({ data: statusData, key: "totalRetainage" })[index]
                  ),
                  "Total Rental With Tax": formatCurrency(
                    getSums({
                      data: statusData,
                      key: "totalRentalWithTax",
                    })[index]
                  ),
                },
              },
              ...Object?.entries(el)?.flatMap(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Current Payment": formatCurrency(
                      getSums({ data: value, key: "totalAmount" })
                    ),
                    "Total Retainage": formatCurrency(
                      getSums({ data: value, key: "totalRetainage" })
                    ),
                    "Total Rental With Tax": formatCurrency(
                      getSums({ data: value, key: "totalRentalWithTax" })
                    ),
                  },
                };
              }),
            ];
          }),
      },
    ],
    totalAmount: () => [
      {
        labels: months,
        data: !!tables.applications
          ? getSums({ data, key: "totalAmount" })?.map((el) => el.toFixed(2))
          : [undefined],
        backgroundColor: "#2DA365",
        custom: groupBy(data, "projectName")?.map((el) => {
          return Object.entries(el).map(([key, value]) => {
            return {
              [key]: {
                projectId: value?.[0]?.projectId,
                "Current Payment": formatCurrency(
                  getSums({ data: value, key: "totalAmount" })
                ),
                "Total Retainage": formatCurrency(
                  getSums({ data: value, key: "totalRetainage" })
                ),
                "Total Rental With Tax": formatCurrency(
                  getSums({ data: value, key: "totalRentalWithTax" })
                ),
              },
            };
          });
        }),
      },
      {
        labels: months,
        data: !!tables.applications
          ? getSums({ data, key: "totalRetainage" })?.map((el) => el.toFixed(2))
          : [undefined],
        backgroundColor: "#F6CB51",
      },
      {
        labels: months,
        data: !!tables.applications
          ? getSums({ data, key: "totalRentalWithTax" })?.map((el) =>
              el.toFixed(2)
            )
          : [undefined],
        backgroundColor: "#B5D99C",
      },
    ],
  };
};
