export function filterRoutes(routes) {
  return routes.filter((route) => {
    if (route.children && route.children.length > 0) {
      route.children = filterRoutes(route.children); // Recursively filter children
    }
    return route.read || route.write;
    // Include the route if either read is true or write is true (if write is defined)
  });
}

export function removeDescription(arr) {
  return arr?.map((item) => {
    const { description, children, ...rest } = item;
    if (children) {
      return {
        ...rest,
        ...(!!children && children.length > 0
          ? { children: removeDescription(children) }
          : {}),
      };
    }
    return rest;
  });
}
