import React, { useState } from "react";

import ModalWarning from "./ModalWarning";
import { useRedux } from "../../../../../../hooks/useRedux";
import { XIcon } from "../../../../../../Communication/assets";
import { SpecifyTypeChargeModal } from "./SpecifyTypeChargeModal";
import { NewCreditMemo } from "../../NewCreditMemo/NewCreditMemo";
import { useNewChargeContext } from "../context/NewChooseChargeContext";
import { updateDocumentTitle } from "../../../../../../../../utils/updateDocumentTitle";
import MondayButton from "../../../../../../../commonComponents/MondayButton/MondayButton";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";

const ModalFooter = ({
  formChanged,
  selectedCatFrom,
  selectedInvoices,
  selectedGroup,
  setFormChanged,
  setSelectedGroup,
}) => {
  const [showSpecifyTypeChargeModal, setShowSpecifyTypeChargeModal] =
    useState(false);
  const [
    handleSpecifyTypeChargeModalNext,
    setHandleSpecifyTypeChargeModalNext,
  ] = useState(false);

  const [showCreditMemo, setShowCreditMemo] = useState(false);

  const [visible, setVisible] = useRedux("newChargeVisible");
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const {
    project,
    groups,
    whereToFind,
    setWhereToFind,
    fromObject,
    objectData,
    setSelectedCategory,
    closeModal,
  } = useNewChargeContext();

  const onNextClickHandler = () => {
    const toSet = {
      categoryFrom: visible?.categoryFrom,
      type: visible?.type,
      selectedGroup,
      whereToFind,
      setWhereToFind,
      fromObject,
      selectedCatFrom,
    };

    if (visible?.categoryFrom === "Invoice") {
      setShowCreditMemo(true);
      setSelectedCategory(toSet);
      return;
    }

    const idKey =
      objectData?.categoryFrom === "Requisition"
        ? "applicationId"
        : objectData?.categoryFrom === "Schedule Of Value"
        ? "scheduleId"
        : `${objectData?.categoryFrom?.toLowerCase()}Id`;

    const selectedObject = groups.find(
      (el) => el[idKey] === objectData?.selectedGroup
    );

    const toSetEstimation = {
      ...toSet,
      selectedObject,
    };

    if (
      visible?.categoryFrom === "Estimation" &&
      visible?.type !== "Credit Memo"
    ) {
      setShowSpecifyTypeChargeModal(true);
      setHandleSpecifyTypeChargeModalNext(() => (props = {}) => {
        setSelectedCategory({
          ...toSetEstimation,
          ...props,
        });
      });
    } else {
      setSelectedCategory(toSet);
    }
  };

  const onWarningModalClose = () => {
    setFormChanged(false);
    setIsWarningModalOpen(false);
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
    setSelectedGroup(false);
  };

  return (
    <div className="footerContainer">
      <MondayButton
        Icon={<XIcon {...{ style: { fill: "white" } }} />}
        className="NewItemChargeFooterCancel"
        onClick={() => {
          formChanged ? setIsWarningModalOpen(true) : onWarningModalClose();
          closeModal();
        }}
      >
        Cancel
      </MondayButton>
      <MondayButton
        Icon={<TickIcon width={17} height={17} />}
        className="NewItemChargeFooterSave"
        disabled={!(selectedGroup || selectedInvoices?.length > 0)}
        onClick={onNextClickHandler}
      >
        Next
      </MondayButton>

      {showSpecifyTypeChargeModal && (
        <SpecifyTypeChargeModal
          {...{
            isModalOpen: showSpecifyTypeChargeModal,
            setIsModalOpen: setShowSpecifyTypeChargeModal,
            handleSpecifyTypeChargeModalNext,
            whereToFind,
            selectedGroup,
          }}
        />
      )}

      {showCreditMemo && (
        <NewCreditMemo
          {...{
            setVisible: setShowCreditMemo,
            invoices: selectedInvoices,
            project,
          }}
        />
      )}
      {isWarningModalOpen && (
        <ModalWarning
          isOpen={isWarningModalOpen}
          setIsOpen={setIsWarningModalOpen}
          onConfirm={onWarningModalClose}
        />
      )}
    </div>
  );
};

export default ModalFooter;
