import { useState } from "react";
import { Button, Form, Tag, Tooltip, message } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { CopyIcon } from "../../../../../../DynamicView/src";
import { InputComponent } from "../../../../../../Fleet/components";
import {
  FetchedOptions,
  MondayButton,
} from "../../../../../../../commonComponents";
import { RedirectIcon } from "../../../../../../../../icons";
import { getUsersList } from "../../helpers";
import { useSelector } from "react-redux";
import { getProgramFieldsOptions } from "../../../../utils/getProgramFieldsOptions";
import { findCategoryName } from "./findCategoryName";
import SelectWithCustomInputs from "../../../../../../../commonComponents/SelectWithCustomInputs/SelectWithCustomInputs";
import { v4 } from "uuid";

const tooltipCategory = "Tasks";

const categoriesConfig = [
  { name: "Fleet Statuses", value: "Fleet" },
  { name: "Accounting Statuses", value: "Accounting" },
  { name: "Inspection Statuses", value: "Inspection" },
  { name: "Safety Statuses", value: "Safety" },
  { name: "Safety Statuses", value: "Other" },
];

export const taskFieldsJSON = ({
  form,
  userConfiguration,
  programFields,
  customTaskProps,
  topics,
  taskSubTopic,
  getSubtopicRecords = () => {},
  userDepartments,
  assignToUsers,
  setAssignToUsers,
  getProjectTaskReferences,
  projectTaskOptions,
  subTopicRecords,
  fromSettings,
}) => {
  const selectedTopic =
    Form.useWatch("taskTopic", form) ||
    (customTaskProps &&
      topics.find(
        (topic) => topic.categoryName === customTaskProps?.taskTopic
      ));
  const selectedProjectTask = Form.useWatch("projectTask", form);
  const taskRelatedToObj =
    Form.useWatch("taskRelatedToObj", form) ||
    (customTaskProps?.taskTopicId && {
      recordId: customTaskProps?.taskTopicId,
      teamsConfiguration: customTaskProps?.rowData?.teamsConfiguration,
    });

  const taskSubTopics =
    programFields
      .find((field) => field.fieldName === "Tasks for Subtopics")
      .fieldOptions?.find(
        (sub) => sub.topicName === selectedTopic?.categoryName
      )
      ?.subTopics.map(({ subTopicName }) => subTopicName) || [];

  const getTaskTopics = () => {
    if (window.location.pathname === "/settings/allTasks") {
      return topics.filter(
        (topic) => topic.hasOwnProperty("fromSettings") && !!topic.fromSettings
      );
    }

    return topics;
  };
  const labelsOptions = programFields.find(
    (el) => el.fieldName === "Tasks labels"
  ).fieldOptions;

  return [
    {
      customComponent: TaskTitleInput,
      componentProps: {
        form,
        autoSuggestWords: programFields
          ?.find((field) => field?.fieldName === "Key Words For Task")
          ?.fieldOptions?.map(({ keyword }) => keyword),
        topics,
        getProjectTaskReferences,
        customTaskProps,
      },
    },
    !!customTaskProps
      ? {
          customComponent: CustomTopic,
          componentProps: { customTaskProps },
        }
      : {
          label: "Topic",
          formItemName: "taskTopic",
          type: "select",
          className: "task-topic",
          placeholder: "Enter Topic...",
          customOptions: getTaskTopics(),
          showSearch: true,
          tooltipCategory,
          tooltipKey: "taskTopic",
          onChange: (value, option) => {
            form.setFieldValue("taskTopic", option);
            form.resetFields([
              "taskRelatedToObj",
              "taskSubTopic",
              "projectTask",
              "projectSubTask",
              "projectSubTask",
              "taskSubTopicId",
            ]);
            value.toLowerCase() === "projects" && getProjectTaskReferences();
          },
        },

    (customTaskProps || !!selectedTopic) && {
      customComponent: TaskRelatedToInput,
      componentProps: {
        customTaskProps,
        form,
        selectedTopic,
        getProjectTaskReferences,
        userConfiguration,
        setAssignToUsers,
        programFields,
        fromSettings,
      },
    },

    selectedTopic?.categoryName === "Projects" && {
      label: "Project Task Topic",
      formItemName: "projectTask",
      placeholder: "Select...",
      type: "select",
      tooltipCategory,
      tooltipKey: "taskList",
      showSearch: true,
      onChange: (value, opt) => {
        form.setFieldValue("projectTask", opt);
      },
      customOptions: projectTaskOptions,
    },

    !!selectedProjectTask?.subTasks && {
      label: "Project Sub Task",
      formItemName: "projectSubTask",
      placeholder: "Select...",
      type: "select",
      tooltipCategory,
      tooltipKey: "projectSubTask",
      showSearch: true,
      options: selectedProjectTask.subTasks,
    },

    taskSubTopics.length > 0 &&
      !!selectedTopic &&
      !!taskRelatedToObj && {
        customComponent: SelectWithCustomInputs,
        componentProps: {
          label: "Sub Topic",
          defaultValue: taskSubTopic,
          form,
          formItemName: "taskSubTopic",
          getRecords: (value) => {
            getSubtopicRecords(
              selectedTopic,
              taskRelatedToObj?.recordId,
              value
            );
          },
          defaultOptions: taskSubTopics,
          width: "100%",
        },
      },

    taskSubTopic &&
      subTopicRecords.length > 0 && {
        customComponent: TaskSubTopicRelatedTo,
        componentProps: { form, subTopicRecords },
      },

    {
      label: "Department",
      formItemName: "department",
      className: "task-departments",
      type: "select",
      mode: "multiple",
      placeholder: "Select Department...",
      showSearch: true,
      allowClear: true,
      tooltipCategory,
      tooltipKey: "department",
      options: userDepartments,
      onChange: (selectedDepartments) => {
        setAssignToUsers(
          getUsersList(userConfiguration, selectedDepartments, taskRelatedToObj)
        );
      },
      maxTagCount: "responsive",
      maxTagPlaceholder: (e) => {
        return (
          <Tooltip title={e.map(({ label }) => label).join(", ")}>
            <span>{`+ ${e.length} more`}</span>
          </Tooltip>
        );
      },
    },

    assignToUsers?.length > 0 && {
      customComponent: TaskAssignationInput,
      componentProps: { form, assignToUsers },
    },

    {
      label: "Labels",
      formItemName: "taskLabels",
      className: "task-labels",
      type: "select",
      mode: "multiple",
      placeholder: "Select Labels heretask-labels..",
      tagRender: (props) => {
        const { label, value } = props;

        const color = labelsOptions?.find(
          ({ labelName }) => labelName === value
        )?.color;

        return <Tag color={value ? `${color}` : ""}>{label}</Tag>;
      },
      showSearch: true,
      maxTagCount: "responsive",
      tooltipCategory,
      tooltipKey: "taskLabels",
      maxTagPlaceholder: (e) => {
        return (
          <Tooltip title={e.map(({ label }) => label).join(", ")}>
            <span>{`+ ${e.length} more`}</span>
          </Tooltip>
        );
      },
      options: programFields
        .find((el) => el.fieldName === "Tasks labels")
        .fieldOptions.map(({ labelName }) => labelName),
    },
  ].filter(Boolean);
};

const TaskRelatedToInput = ({
  customTaskProps,
  form,
  selectedTopic,
  getProjectTaskReferences,
  userConfiguration,
  setAssignToUsers,
  programFields,
  fromSettings,
}) => {
  const [taskRelatedTo, setTaskRelatedTo] = useState(
    customTaskProps?.taskRelatedTo
  );

  const taskTitle = Form.useWatch("taskTitle", form);
  const selectedDepartments = Form.useWatch("department", form);

  const settingsTasks =
    programFields
      .find(({ fieldName }) => fieldName === "Settings Tasks")
      .fieldOptions.filter((item) => item.included.includes("task")) || [];

  const setClipboardData = () => {
    if (taskRelatedTo) {
      navigator.clipboard.writeText(taskRelatedTo);
      message.success({ content: "Copied to Clipboard", key: "copy" });
      if (taskTitle && taskTitle.includes(taskRelatedTo)) return;
      form.setFieldValue("taskTitle", `${taskTitle} ${taskRelatedTo}`);
    } else message.info("First select an record!");
  };

  const onChange = (value, option) => {
    if (!!option) {
      form.setFieldValue("taskRelatedToObj", option);
      setTaskRelatedTo(option.recordName);
      selectedTopic.hasOwnProperty("categoryName") &&
        selectedTopic.categoryName.toLowerCase() === "projects" &&
        getProjectTaskReferences(option.recordId);
      setAssignToUsers(
        getUsersList(userConfiguration, selectedDepartments, option)
      );
    }
  };

  const onClear = () => {
    setTaskRelatedTo(customTaskProps?.taskRelatedTo);
    form.setFieldValue("taskRelatedToObj", customTaskProps?.taskRelatedTo);
  };

  const getCustomOptions = () => {
    const categoriesItem = categoriesConfig.find(
      (item) => item.name === customTaskProps.taskTopic
    );

    if (!!categoriesItem) {
      const categories = [];

      const foundedTask = settingsTasks.find(
        (item) => item.categoryName === categoriesItem.name
      );

      const foundedFieldOptions = programFields.find(
        (item) => item.fieldName === "Categories Config"
      )?.fieldOptions[categoriesItem.value];

      foundedFieldOptions.map((option) => {
        const newId = v4();

        categories.push({
          label: option,
          value: option,
          key: newId,
          customValue: {
            key: newId,
            recordId: newId,
            recordName: option,
            value: option,
            ...(foundedTask.hasOwnProperty("topicCategory") && {
              topicCategory: foundedTask.topicCategory,
            }),
            ...(foundedTask.hasOwnProperty("path") && {
              path: foundedTask.path,
            }),
          },
        });
      });

      return categories;
    }

    const result = getProgramFieldsOptions(programFields, selectedTopic);
    return result;
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {customTaskProps?.taskRelatedTo ? (
        <div className="modal__default__props default-topicContainer">
          <div className="modal__default__props default-topic">
            <h3>
              <StopOutlined fontSize={"small"} style={{ marginRight: "3px" }} />
            </h3>
            <p>
              {` Current ${customTaskProps?.taskTopic} is `}
              <b> &nbsp;{` ${customTaskProps?.taskRelatedTo}`}</b>
            </p>
          </div>
        </div>
      ) : (fromSettings && !selectedTopic?.hasOwnProperty("apiName")) ||
        selectedTopic?.hasOwnProperty("fieldId") ? (
        <InputComponent
          {...{
            label: "SubTopic Records",
            formItemName: "taskRelatedToObj",
            placeholder: "Select an record...",
            type: "select",
            showSearch: true,
            value: "taskRelatedTo",
            tooltipCategory,
            tooltipKey: "taskRelatedTo",
            customOptions: getCustomOptions(),
            onChange: (value, custom) => {
              onChange(value, custom.customValue);
            },
          }}
        />
      ) : (
        <FetchedOptions
          {...{
            formItemName: "taskRelatedToObj",
            selectedTopic,
            onChange,
            onClear,
            tooltipCategory,
            tooltipKey: "taskRelatedTo",
          }}
        />
      )}
      {taskRelatedTo && (
        <Tooltip title="Copy to Clipboard!">
          <CopyIcon onClick={setClipboardData} style={{ cursor: "pointer" }} />
        </Tooltip>
      )}
    </div>
  );
};

const TaskSubTopicRelatedTo = ({ form, subTopicRecords }) => {
  const taskSubTopic = Form.useWatch("taskSubTopic", form);
  const taskSubTopicId = Form.useWatch("taskSubTopicId", form);

  const topicRedirect = () => {
    if (!!taskSubTopicId) {
      return window.open(
        `/${taskSubTopic.toLowerCase()}/${taskSubTopicId?.recordId}`,
        "_blank"
      );
    } else message.info("Please select an record to redirect!");
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <InputComponent
        {...{
          label: "SubTopic Records",
          formItemName: "taskSubTopicId",
          placeholder: "Select an record...",
          type: "select",
          showSearch: true,
          value: "subTopicRecords",
          tooltipCategory,
          tooltipKey: "taskSubTopicId",
          customOptions: subTopicRecords,
          onChange: (value, opt) => {
            form.setFieldValue("taskSubTopicId", opt);
          },
        }}
      />

      <Tooltip title="Redirect to">
        <MondayButton
          onClick={topicRedirect}
          Icon={<RedirectIcon />}
          className="mondayButtonBlue"
        ></MondayButton>
      </Tooltip>
    </div>
  );
};

const CustomTopic = ({ customTaskProps }) => {
  return (
    <div className="modal__default__props default-topicContainer">
      <div className="modal__default__props">
        <h3>
          <StopOutlined fontSize={"small"} style={{ marginRight: "3px" }} />
        </h3>
        <p>
          Current topic is &nbsp; <b>{` ${customTaskProps.taskTopic}`}</b>
        </p>
      </div>
      <hr style={{ margin: "0.5rem 0", opacity: "0" }} />
    </div>
  );
};

const TaskAssignationInput = ({ form, assignToUsers }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [assignToMe, setAssignToMe] = useState(true);

  return (
    <div className="modal__multiSelect">
      {userConfiguration?.tasksConfig?.canAssignToOthers && (
        <InputComponent
          {...{
            style: { width: "100%" },
            label: "Assign To",
            formItemName: "taskAssignedTo",
            placeholder: "Select Users...",
            type: "select",
            mode: "multiple",
            showSearch: true,
            allowClear: true,
            tooltipCategory,
            tooltipKey: "taskAssignedTo",
            customOptions: assignToUsers.map((user) => ({
              key: user.identityId,
              label: user.nameOfUser,
              value: user.nameOfUser,
              userName: user.userName,
              nameOfUser: user.nameOfUser,
              identityId: user.identityId,
              cognitoUserId: user.cognitoUserId,
            })),
            onChange: (val, option) => {
              form.setFieldValue("taskAssignedTo", option);
            },
            maxTagCount: "responsive",
            maxTagPlaceholder: (e) => {
              return (
                <Tooltip title={e.map(({ label }) => label).join(", ")}>
                  <span>{`+ ${e.length} more`}</span>
                </Tooltip>
              );
            },
          }}
        />
      )}
      <Form.Item name="assignToMe" initialValue={assignToMe}>
        <Tooltip
          title={`Click to ${assignToMe ? "remove" : "add"} my assignation!`}
        >
          <Button
            type={assignToMe ? "primary" : ""}
            onClick={() => {
              setAssignToMe((prev) => !prev);
              form.setFieldValue("assignToMe", !assignToMe);
            }}
            className={assignToMe ? "assigned-toMe-btn" : "assign-toMe-btn"}
          >
            {assignToMe ? "Assigned to me" : "Assign to me"}
          </Button>
        </Tooltip>
      </Form.Item>
    </div>
  );
};

const TaskTitleInput = ({
  form,
  autoSuggestWords,
  topics,
  getProjectTaskReferences,
  customTaskProps,
}) => {
  return (
    <InputComponent
      {...{
        label: (
          <span>
            <div style={{ color: "red" }}>*</div>
            Title
          </span>
        ),
        // value: taskTitle,
        value: "",
        form,
        className: "task-title",
        formItemName: "taskTitle",
        placeholder: "Enter title...",
        type: "predict",
        autoSuggestWords,
        tooltipCategory,
        tooltipKey: "taskTitle",
        onTabEnter: (event) => {
          if (!customTaskProps) {
            const foundCategory = findCategoryName(topics, event);
            if (foundCategory) {
              form.setFieldValue("taskTopic", foundCategory);
              foundCategory.categoryName.toLowerCase() === "projects" &&
                getProjectTaskReferences();
            }
          }
        },
      }}
    />
  );
};
