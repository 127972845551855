import { Avatar, Tooltip } from "antd";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../utils";
import {
  updateUserConfigKey,
  updateUserKeysUser,
} from "../../../../../../../../../actions";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { getInitials } from "../../../../../TeamManagement/components/TeamPreview/PreviewAvatar";

const ProfileImage = ({ currentUser, className }) => {
  const [userImage, setUserImage] = useState(null);

  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { preferences } = useSelector((state) => state.preferences);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const driveRequest = driveApi({ accessToken });

  const { nameOfUser, googleDriveFileId, identityId } = currentUser;

  const deletePhoto = () => {
    showLoadingMsg({ message: "Deleting image. Please wait." });
    API.put("userConfiguration", `/userConfiguration/${identityId}`, {
      body: { googleDriveFileId: "" },
    })
      .then(() => {
        dispatch(updateUserConfigKey({ identityId, googleDriveFileId: "" }));
        dispatch(updateUserKeysUser({ identityId, googleDriveFileId: "" }));
        showSuccessMsg({ content: "Image deleted successfully" });
      })
      .catch(() =>
        showErrorMsg({
          content: "Failed to delete image. Please try again later.",
        })
      );
  };

  const getImage = async () => {
    if (!!googleDriveFileId && googleDriveFileId !== "") {
      await driveRequest
        .getImageSrc(googleDriveFileId)
        .then((res) => setUserImage(res.src));
    }
  };

  useEffect(() => {
    if (googleDriveFileId) getImage();
    else setUserImage(null);
  }, [googleDriveFileId, nameOfUser]);

  const avatarStyle = {
    width: "170px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    fontWeight: 600,
    fontSize: "42px",
  };
  return (
    <Avatar
      shape="square"
      src={userImage}
      className={className || ""}
      style={{
        backgroundColor: `${
          userConfiguration?.nameOfUser === nameOfUser
            ? preferences.preferences?.profileColor || "#4895ef"
            : isDarkMode
            ? "#2a2b3a"
            : "#F8F8FA"
        }`,
        color: isDarkMode ? "#fff" : "#323338",
        ...avatarStyle,
      }}
    >
      {getInitials(nameOfUser)}
    </Avatar>
  );
};

export default ProfileImage;
