import React from "react";

import styleModule from "./invoiceFooter.module.css";
import { styleInvoice, styleSettings } from "./footer_invoice_style_settings";

const FooterInvoice = ({ myProps, searchInvoicePreviewText }) => {
  const searchText = searchInvoicePreviewText;

  React.useEffect(() => {
    if (document.querySelector(`.${styleModule.footerInvoiceCont}`)) {
      footerInvoiceText();
    }
  }, [searchText]);

  //FIND TEXT AND HIGHLIGHT =========================
  const footerInvoiceText = () => {
    if (myProps.mode === "viewMode") {
      const regExVal = new RegExp(`^${searchText}`, "i");
      const partentEls = [
        ...document.getElementsByClassName(`${styleModule.footerInvoiceCont}`),
      ];

      for (let i = 0; i < partentEls.length; i++) {
        const elementsList = [...partentEls[i].children[0].children];
        const sectionsEl = [...partentEls[i].children[1].children];
        for (let j = 0; j < sectionsEl.length; j++) {
          elementsList.push(sectionsEl[j].children[0]);
        }
        for (let j = 0; j < elementsList.length; j++) {
          let textLine = elementsList[j].textContent;
          if (
            searchText &&
            elementsList[j].textContent
              ?.toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            textLine = textLine.replace(
              regExVal,
              `<b style="background-color: #00000030">${searchText}</b>`
            );
            elementsList[j].innerHTML = textLine;
          } else {
            textLine = textLine.replace(/<b>|<\/b>/g, "");
            elementsList[j].innerHTML = textLine;
          }
        }
      }
    }
  };
  //FIND TEXT AND HIGHLIGHT =========================

  const printModeStyle = myProps.mode === "printMode" ? styleInvoice : {};
  const viewModeStyle = myProps.mode === "viewMode" ? styleModule : {};

  return (
    <React.Fragment>
      <div
        style={printModeStyle.footerInvoiceCont}
        className={viewModeStyle.footerInvoiceCont}
      >
        <div style={printModeStyle.textSide} className={viewModeStyle.textSide}>
          <p style={printModeStyle.textSideItem}>Credit Card Payment</p>
          <p style={printModeStyle.textSideItem}>
            You can now pay your bill online with a credit card by going to the
          </p>
          <p style={printModeStyle.textSideItem}>
            following website:{" "}
            <a
              href="https://secure.cardknox.com/corescaffoldsystems"
              target="_blank"
            >
              https://secure.cardknox.com/corescaffoldsystems
            </a>
          </p>
        </div>
        <div
          style={printModeStyle.itemsTable}
          className={viewModeStyle.itemsTable}
        >
          <section style={printModeStyle.itemDatas}>
            <strong style={printModeStyle.itemStrongText}>Subtotal</strong>
            <p style={printModeStyle.itemLightText}>
              {myProps.paperTotals.subtotal}
            </p>
          </section>
          <section style={printModeStyle.itemDatas}>
            <strong
              style={printModeStyle.itemStrongText}
            >{`Sales Tax ${myProps.paperTotals.tax}%`}</strong>
            <p style={printModeStyle.itemLightText}>
              {myProps.paperTotals.taxVal}
            </p>
          </section>
          <section style={printModeStyle.itemDatas}>
            <strong style={printModeStyle.itemStrongText}>Total</strong>
            <p style={printModeStyle.itemLightText}>
              {myProps.paperTotals.total}
            </p>
          </section>
          <section style={printModeStyle.itemDatas}>
            <strong style={printModeStyle.itemStrongText}>
              Payments/Credits
            </strong>
            <p style={printModeStyle.itemLightText}>
              {myProps.paymentsCredits}
            </p>
          </section>
          <section style={printModeStyle.itemDatas}>
            <strong style={printModeStyle.itemStrongText}>Balance Due</strong>
            <p style={printModeStyle.itemLightText}>{myProps.balanceDue}</p>
          </section>
        </div>
        {/* <h3 style={printModeStyle.pageNumberText}>Page {myProps.pageNumber}</h3> */}
      </div>
    </React.Fragment>
  );
};

export default FooterInvoice;
