import {
  Excel,
  Pdf,
  SaveIcon,
  WordIcon,
} from "../../../../../../../../BasePage/src";
import RedirectToSchedule from "./Components/RedirectToSchedule/RedirectToSchedule";
import { MondayButton } from "../../../../../../../../../commonComponents";
import StatusChanger from "./Components/StatusChanger/StatusChanger";
import {
  appliedAmountGenerator,
  totalitiesTotalTaxGen,
} from "./ControlPanelFunctions";
import {
  EditIcon,
  NotesIcon,
  UpIcon,
} from "../../../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../../../utils";
import { useRedux } from "../../../../../../../../hooks";
import { CreateCharges } from "./Components";
import "./ControlPanel.scss";
import { Button, Checkbox, message, Popover } from "antd";
// import { useDispatchContext, useStateContext } from "../../../../Context/Context"
// import { switcherHandler } from "../../headerFunction"

import AnimatedNumber from "react-animated-number";
import { useSelector } from "react-redux";
import { Switch, Tooltip } from "antd";
import { AnimatedCheckBox } from "../../../../../../../../Fleet/components/InputComponent/components";
import ChangeTax from "./Components/ChangeTax/ChangeTax";
import { UndoRedoButtons } from "../../../../../../../../../commonComponents/UndoRedoButtons/UndoRedoButtons";
import { Notes } from "../../../../../../../../../commonComponents/Notes/Notes";
import RentalsMoreOptions from "./Components/RentalsMoreOptions/RentalsMoreOptions";
import { CheckAccessRights } from "../../../../../../../../../../utils/CheckAccessRights";
import RentalPreview from "./Components/RentalPreview/RentalPreview";
import { CheckIconModal } from "../../../../../../../../../../assets";
import { SchedRentalTimeline } from "../MainHeader/SchedRentalTimeline/SchedRentalTimeline";
import { TaskIcon } from "src/icons/NotificationIcons";
import ToDoButton from "../../../../../../../../ToDos/components/ToDoButton/ToDoButton";
import { useMemo } from "react";
import ScheduleTypesSelector from "./Components/ScheduleTypesSelector/ScheduleTypesSelector";

const ControlPanel = ({
  saveHandler,
  setRetract,
  retract,
  canUndo,
  canRedo,
  undoClickHandler,
  redoClickHandler,
}) => {
  const [isAutoSaving, setIsAutoSaving] = useRedux("rentalsIsAutoSaving");
  const [isWritable] = useRedux("rentalsIsWritable");
  const [darkMode] = useRedux("rentalsDarkMode");
  const [fakeRentals] = useRedux("fakeRentals");
  const [rentalFromSchedule, setRentalFromSchedule] = useRedux(
    "rentalFromSchedule",
    false,
    false
  );
  // const { isLoading } = useSelector((state) => state?.rentals);
  // const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isLoading = false, userConfiguration = {} } = useSelector((state) => {
    return {
      isLoading: state?.rentals?.isLoading || false,
      userConfiguration: state?.userConfig || {},
    };
  });

  // const { isWritable, isAutoSaving, darkMode } = useStateContext()
  // const dispatchContext = useDispatchContext()
  //calculated applied amount
  // const appliedAmount = appliedAmountGenerator({ fakeRentals });
  const {
    appliedAmount = 0,
    totalTax = 0,
    totalPrice = 0,
    retainageAmount = 0,
  } = useMemo(() => {
    return totalitiesTotalTaxGen({ fakeRentals });
  }, [fakeRentals]);
  return (
    <div
      className={
        darkMode
          ? "rentalsControlPanelDarkMode"
          : "rentalsControlPanelLightMode"
      }
    >
      {
        <div className="optionsContainer">
          <div className="leftSideContainer">
            <Tooltip
              title={retract ? "Show service menu" : "Hide service menu"}
            >
              <button
                className={
                  retract
                    ? "serviceRetractButtonRetracted"
                    : "serviceRetractButton"
                }
                onClick={() => setRetract((prev) => !prev)}
              >
                <UpIcon />
              </button>
            </Tooltip>
            {/* <div className="autoSaveContainer">
              <Switch
                unCheckedChildren={
                  <div className="switchTittleContainerRentals">
                    Auto Save Off
                  </div>
                }
                checkedChildren={
                  <div className="switchTittleContainerRentals">
                    Auto Save On
                  </div>
                }
                className="rentalsAutoSaveSwitchContainer"
                onChange={
                  (checkValue) => setIsAutoSaving(checkValue)
                  // switcherHandler({ dispatchContext, checkValue, witchOne: "autoSave" })
                }
                checked={isAutoSaving}
              />
            </div> */}{" "}
            <div className="autoSaveContainer">
              <Switch
                unCheckedChildren={
                  <div className="switchTittleContainerRentals">Manual</div>
                }
                checkedChildren={
                  <div className="switchTittleContainerRentals">Schedule</div>
                }
                className="rentalsAutoSaveSwitchContainer"
                onChange={
                  (checkValue) => setRentalFromSchedule(checkValue)
                  // switcherHandler({ dispatchContext, checkValue, witchOne: "autoSave" })
                }
                checked={rentalFromSchedule}
              />
            </div>{" "}
            <div className="createChargeContainer">
              <ScheduleTypesSelector />
            </div>
            <div className="createChargeContainer">
              <StatusChanger />
            </div>
            {/* <UndoRedoButtons
              canUndo={canUndo}
              canRedo={canRedo}
              undoClickHandler={undoClickHandler}
              redoClickHandler={redoClickHandler}
            /> */}
            <div className="information">
              <strong>Applied Rent:</strong>
              <div className="currencyPercentageContainer">
                <AnimatedNumber
                  value={appliedAmount || 0}
                  formatValue={(value) => formatCurrency(value)}
                />
              </div>
            </div>
            <div className="information">
              <strong>Tax:</strong>
              <div className="currencyPercentageContainer">
                <AnimatedNumber
                  value={totalTax}
                  formatValue={(value) => formatCurrency(value)}
                />
              </div>
            </div>
            <div className="information">
              <strong>Total:</strong>
              <div className="currencyPercentageContainer">
                <AnimatedNumber
                  value={totalPrice}
                  formatValue={(value) => formatCurrency(value)}
                />
              </div>
            </div>
            <div className="information">
              {" "}
              <Popover content="Tax Rate from Project on time of Rent creation! You can remove it!">
                <strong>
                  Tax Rate:{" "}
                  {fakeRentals?.taxRate > 0
                    ? fakeRentals?.taxRate * 100 + "%"
                    : 0}
                </strong>
              </Popover>
            </div>
            {/* <div className="information">
              <ChangeTax />
            </div> */}
            <div className="information">
              <strong>Retainage:</strong>
              <div className="currencyPercentageContainer">
                <AnimatedNumber
                  value={
                    retainageAmount ||
                    0 + retainageAmount * (fakeRentals?.taxRate || 0)
                  }
                  formatValue={(value) => formatCurrency(value)}
                />
              </div>
            </div>
          </div>
          <div className="rightSideContainer">
            <ToDoButton
              {...{
                iconOnly: true,
                recordId: fakeRentals?.rentalId,
                recordName: `Rental# ${fakeRentals?.rentalNumber} ${fakeRentals?.projectAddress}`,
              }}
            />
            <div>
              <SchedRentalTimeline
                projectId={fakeRentals?.projectId}
                view={"icon"}
              />
            </div>
            <div className="createChargeContainer">
              <RedirectToSchedule />
            </div>{" "}
            <RentalPreview />
            {/* <div className="createChargeContainer">
              <CreateCharges disabled={!isWritable || isLoading} />
            </div> */}
            {/* <button className="exportButtons">
              <Excel />
            </button> */}
            {/* <HeaderTasks
              {...{ darkMode }}
              taskProjectName={fakeRentals?.projectAddress}
              taskItemId={fakeRentals?.rentalId}
              taskTopic={"Rentals"}
            /> */}
            {/* <button className="exportButtons">
              <Pdf />
            </button>
            <button className="exportButtons">
              <WordIcon />
            </button> */}{" "}
            <div style={{ marginRight: "-20px" }}>
              {CheckAccessRights(
                userConfiguration?.routeConfig?.find(
                  ({ title }) => title === "Notes/View"
                )?.children,
                "Rentals"
              ) && (
                <Notes
                  noteModalName={`${fakeRentals?.projectAddress} | Rental ${fakeRentals?.rentalNumber}`}
                  rowId={fakeRentals?.rentalId}
                  topicCategory={"Rentals"}
                  customIcon={<NotesIcon />}
                />
              )}
            </div>
            <Tooltip
              title={
                isWritable ? "Save your work" : "Enable WRITE mode to save work"
              }
            >
              <MondayButton
                className={`quickSaveApplicationHeaderRental ${
                  isWritable ? "" : "quickSaveButtonUnClickable"
                }`}
                onClick={() =>
                  isWritable
                    ? saveHandler()
                    : message.error("Please enable Edit Mode!")
                }
                Icon={<CheckIconModal />}
                disabled={!isWritable || isLoading}
                id="quickSaveButton"
              >
                Save Changes
              </MondayButton>
            </Tooltip>{" "}
            <RentalsMoreOptions />
          </div>
        </div>
      }
    </div>
  );
};

export default ControlPanel;
