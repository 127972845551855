//@ts-check
import XLSX from "sheetjs-style";
import { excelCellFitToColumn } from "../../../../../../utils/excelCellFitToColumn";
import { defaultExcelBodyStyle } from "../../../../../../helpers/constants/defaultExcelBodyStyle";
import { defaultExcelHeaderStyle } from "../../../../../../helpers/constants/defaultExcelHeaderStyle";
import { formatCurrency } from "../../../../utils";

const customTotalStyle = {
  border: {
    bottom: { style: "medium", color: { rgb: "000000" } },
    right: { style: "thin", color: { rgb: "000000" } },
    left: { style: "thin", color: { rgb: "000000" } },
  },
  font: { bold: true },
};
const customTotalStyleStart = {
  border: {
    bottom: { style: "medium", color: { rgb: "000000" } },
    right: { style: "thin", color: { rgb: "000000" } },
    left: { style: "medium", color: { rgb: "000000" } },
  },
  font: { bold: true },
};

const customTotalStyleEnd = {
  border: {
    bottom: { style: "medium", color: { rgb: "000000" } },
    right: { style: "medium", color: { rgb: "000000" } },
    left: { style: "thin", color: { rgb: "000000" } },
  },
  font: { bold: true },
};

export const exportBillToExcel = (billData) => {
  let workbook;

  workbook = XLSX.utils.book_new();

  const totalBillAmount = billData.billItems.reduce(
    (acc, bill) => acc + bill.amount,
    0
  );
  const totalTaxAmount = billData.billItems.reduce(
    (acc, bill) => acc + bill.taxAmount,
    0
  );
  const total = billData.billItems.reduce((acc, bill) => acc + bill.total, 0);

  const headers = [
    [
      {
        v: "Bill Information",
        s: defaultExcelHeaderStyle,
      },
    ],
    [
      {
        v: "Vendor:",
        s: {},
      },
      {
        v: billData.vendorName,
        s: {},
      },
      {},
      {
        v: "Bill Amount:",
        s: {},
      },
      {
        v: formatCurrency(billData.billAmount),
        s: {},
      },
    ],
    [
      {
        v: "Project",
        s: {},
      },
      {
        v: billData.projectName,
        s: {},
      },
      {},
      {
        v: "Bill Status:",
        s: {},
      },
      {
        v: billData.billStatus,
        s: {},
      },
    ],
    [{}, {}],
    [{}, {}],
  ];
  const rows = [];

  rows.push([
    {
      v: "Name",
      s: defaultExcelHeaderStyle,
    },
    {
      v: "Category",
      s: defaultExcelHeaderStyle,
    },
    {
      v: "Description",
      s: defaultExcelHeaderStyle,
    },
    {
      v: "Amount",
      s: defaultExcelHeaderStyle,
    },
    {
      v: "Tax Amount",
      s: defaultExcelHeaderStyle,
    },
    {
      v: "Total",
      s: defaultExcelHeaderStyle,
    },
  ]);

  billData.billItems.map((item) => {
    rows.push([
      {
        v: item.itemName,
        s: defaultExcelBodyStyle,
      },
      {
        v: item.itemCategory,
        s: defaultExcelBodyStyle,
      },
      {
        v: item.itemDescription,
        s: defaultExcelBodyStyle,
      },
      {
        v: item.amount,
        s: defaultExcelBodyStyle,
      },
      {
        v: item.taxAmount,
        s: defaultExcelBodyStyle,
      },
      {
        v: item.total,
        s: defaultExcelBodyStyle,
      },
    ]);
  });

  rows.push([
    {
      v: "Total",
      s: customTotalStyleStart,
    },
    {
      v: "",
      s: customTotalStyle,
    },
    {
      v: "",
      s: customTotalStyle,
    },
    {
      v: formatCurrency(totalBillAmount),
      s: customTotalStyle,
    },
    {
      v: formatCurrency(totalTaxAmount),
      s: customTotalStyle,
    },
    {
      v: formatCurrency(total),
      s: customTotalStyleEnd,
    },
  ]);

  const worksheet = XLSX.utils.aoa_to_sheet([...headers, ...rows], {
    styles: true,
  });

  worksheet["!cols"] = excelCellFitToColumn([...headers, ...rows]);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  XLSX.writeFile(workbook, `Bill-${billData.billNumber}.xlsx`);
};
