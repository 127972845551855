import { formatCurrency } from "../../../../utils";
import { getSums, groupBy } from "../../calculatingFunctions";

export const applications = ({ tables, programFields }) => {
  const applicationStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Requisition.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    applicationStatus: () => ({
      content: applicationStatus?.statusName?.map((status, index) => ({
        key: index,
        title: status,
        taskNumber: tables?.applications?.length,
        taskDone: tables?.applications?.filter(
          (a) => a?.applicationStatus === status
        )?.length,
        color: applicationStatus?.statusColor[index],
        backgroundColor: "#B2DD9B",
        contentHandler: ` ${status} Application`,
        contentsHandler: ` ${status} Applications`,
        custom:
          tables?.applications &&
          groupBy(
            [
              tables?.applications?.filter(
                (a) => a?.applicationStatus === status
              ),
            ],
            "projectName"
          )?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Current Payment": formatCurrency(
                    getSums({
                      data: [
                        tables?.applications?.filter(
                          (a) => a?.applicationStatus === status
                        ),
                      ],

                      key: "totalAmount",
                    })
                  ),
                  "Total Retainage": formatCurrency(
                    getSums({
                      data: [
                        tables?.applications?.filter(
                          (a) => a?.applicationStatus === status
                        ),
                      ],

                      key: "totalRetainage",
                    })
                  ),
                  "Total Rental With Tax": formatCurrency(
                    getSums({
                      data: [
                        tables?.applications?.filter(
                          (a) => a?.applicationStatus === status
                        ),
                      ],

                      key: "totalRentalWithTax",
                    })
                  ),
                },
              },
              ...Object?.entries(el)?.map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Current Payment": formatCurrency(
                      getSums({ data: value, key: "totalAmount" })
                    ),
                    "Total Retainage": formatCurrency(
                      getSums({ data: value, key: "totalRetainage" })
                    ),
                    "Total Rental With Tax": formatCurrency(
                      getSums({ data: value, key: "totalRentalWithTax" })
                    ),
                  },
                };
              }),
            ];
          }),
      })),
    }),
  };
};
