import { AgChartsReact } from "ag-charts-react";
import { Modal } from "antd";
import { XIcon } from "../../Communication/assets";
import { useSelector } from "react-redux";
import "./ChartStyles.scss";
import { MondayButton } from "../../../commonComponents";

const ChartComponent = ({
  open,
  onCancel,
  options,
  title = false,
  children = false,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const darkStyle = {
    theme: "ag-default-dark",
    background: { fill: "#1f212d" },
    title: { color: "#fff" },
    subtitle: { color: "#fff" },
    footnote: { stroke: "#fff" },
    legend: { stroke: "#acacca" },
  };

  return (
    <Modal
      title={title || "Chart"}
      open={open}
      centered
      onCancel={onCancel}
      className={`chartComponent ${isDarkMode && "chartComponentDark"}`}
      data-testid="progress-chart"
      closeIcon={<XIcon />}
      footer={[
        <MondayButton
          key="closeButton"
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={onCancel}
        >
          Close
        </MondayButton>,
      ]}
    >
      {children}
      <AgChartsReact
        className="ag-default-dark"
        options={isDarkMode ? Object.assign(options, darkStyle) : options}
      />
    </Modal>
  );
};

export default ChartComponent;
