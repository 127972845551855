import { InfoCircleFilled } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const hi = (nameOfUser) => (
  <span>
    Hi <span style={{ fontWeight: 600 }}>{nameOfUser}, </span>
  </span>
);

function WarningsInfoMessage({
  fromDoc,
  proppedCorrectFolderId,
  nameOfUser,
  isDarkMode,
}) {
  const navigate = useNavigate();
  const path = window.location.pathname;
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        maxWidth: 600,
        textAlign: "center",
        color: isDarkMode ? "#ffffff" : "#000000",
        placeSelf: "center",
        width: "100%",
      }}
    >
      {fromDoc && !proppedCorrectFolderId ? (
        <div style={{ width: "100%" }}>
          {hi(nameOfUser)}
          <span>
            Documentation folder is not created!{" "}
            <span
              onClick={() => {
                navigate({ pathname: path, search: `?tab=Overview` });
              }}
              style={{
                color: "#1264A3",
                cursor: "pointer",
                fontWeight: 600,
              }}
            >
              Go to Overview{" "}
            </span>
            tab to create the folder.
          </span>
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {hi(nameOfUser)}
          <span>
            "Fix All" button will fix all{" "}
            {fromDoc && (
              <>
                <span style={{ color: "#FE4C4A" }}>No Folder Created </span>
                and
              </>
            )}{" "}
            <span style={{ color: "#F6CB51" }}>Wrong Folder </span>
            warnings.{" "}
          </span>
          <br />
          {fromDoc ? (
            <span>
              <span style={{ color: "#FE4C4A" }}>Not in Drive</span> warnings
              can be fixed by going to the documentation, deleting and
              re-uploading the file.
            </span>
          ) : (
            <span>
              <span style={{ color: "#FE4C4A" }}>Not in Drive</span> warnings
              can be fixed by deleting and re-uploading the file.
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default WarningsInfoMessage;
