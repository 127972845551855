import { useEffect, useState } from "react";

export const useGetDriveFoldersAndParentNameByParentId = ({
  parentId,
  driveRequest,
}) => {
  const [driveChildrenFolders, setDriveChildrenFolders] = useState([]);
  const [parentName, setParentName] = useState("");

  const getFolderChildren = async (folderId) => {
    if (folderId) {
      try {
        return await driveRequest.getFilesByFolderId(folderId);
      } catch (error) {
        console.error("Error fetching folder children:", error);
        throw error;
      }
    }
  };

  const getFolderName = async (folderId) => {
    if (folderId) {
      try {
        return await driveRequest.getFolderName(folderId);
      } catch (error) {
        console.error("Error fetching folder name:", error);
      }
    }
  };

  useEffect(() => {
    const fetchFolderData = async (folderId) => {
      try {
        const [filesRes, nameRes] = await Promise.all([
          getFolderChildren(folderId),
          getFolderName(folderId),
        ]);

        const { files } = await filesRes.json();
        const { name } = await nameRes.json();

        setDriveChildrenFolders(
          files?.map(({ name, id }) => ({
            name,
            id,
          })) || []
        );

        setParentName(name);
      } catch (error) {}
    };

    if (parentId) {
      fetchFolderData(parentId);
    }
  }, [parentId]);

  return { driveChildrenFolders, parentName };
};
