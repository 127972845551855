import axios from "axios";
import { message } from "antd";
import { Dayjs } from "dayjs";

import {
  linxupEndpoints,
  splitTimelineCalls,
  saveLocalPreferences,
} from "../../../utils";
import { TIME_TOLERANCE } from "../../../data";
import { fetchByDate } from "src/utils";
import { fetchAllData } from "../../../../../../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

/**
 * Function that gets all the alert and stop
 * events for the selected duration
 * @param {Object} config
 * @param {Dayjs|number|string} config.minDate
 * @param {Dayjs|number|string} config.maxDate
 * @param {Array} config.locations
 * @param {string} config.selectedVehicle
 */
async function fetchReportData({
  minDate,
  maxDate,
  locations,
  selectedVehicle,
}) {
  /**
   * The idea is to make statistics related to all the stop/ide and alerts
   * while we fetch the data. As per the linxup documentation, we cannot make
   * requests to the stops and alerts endpoints that are greater than 48 hours,
   * so we need to split the calls into segments of 48h
   */
  const FORMAT = "MM/DD/YYYY";
  const BASE_OBJ = {
    totalStopDuration: 0,
    totalIdleDuration: 0,
    averageStopDuration: 0,
    averageIdleDuration: 0,
    stopEventCount: 0,
    idleEventCount: 0,
    totalTripsDuration: 0,
    averageTripsDuration: 0,
    totalTripCount: 0,
    totalEstimatedDistance: 0,
    averageTripDistance: 0,
    aheadOfScheduleDepartures: 0,
    onScheduleStarts: 0,
    lateDepartures: 0,
    aheadOfScheduleArrives: 0,
    onScheduleArrives: 0,
    lateArrives: 0,
  };

  let timelineSplit = splitTimelineCalls({ minDate, maxDate });

  let analyticsDates = timelineSplit.reduce(
    (acc, val) => ({
      ...acc,
      [dayjsNY(val?.fromDate).startOf("D").format(FORMAT)]: {
        ...BASE_OBJ,
      },
      [dayjsNY(val?.toDate - 1)
        .startOf("D")
        .format(FORMAT)]: {
        ...BASE_OBJ,
      },
    }),
    {}
  );

  let analyticsKeys = Object.keys(analyticsDates || {});
  delete analyticsDates[analyticsKeys[analyticsKeys.length - 1]];
  timelineSplit.pop();

  let totals = structuredClone(analyticsDates);
  let defaultTotals = structuredClone(analyticsDates);

  let analytics = locations.reduce(
    (acc, val) => ({
      ...acc,
      [val?.deviceSerialNumber]: structuredClone(analyticsDates),
    }),
    {}
  );
  let defaultAnalytics = structuredClone(analytics);

  let ERROR = false;
  let iterator = 5;
  let step = iterator;

  message.loading({
    content: "Please wait for the data to load...",
    key: "reportsFetch",
    duration: 0,
  });

  while (true) {
    let timelineSlice = timelineSplit.slice(step - iterator, step);
    if (!timelineSlice.length) {
      break;
    }

    await Promise.all([
      Promise.all(
        timelineSlice.map(({ fromDate, toDate }) =>
          axios.post(linxupEndpoints.stops, { fromDate, toDate })
        )
      ),
      Promise.all(
        timelineSlice.map(({ fromDate, toDate }) =>
          fetchAllData({
            endpoint: "fleetActivity",
            resultId: "activityId",
            resultPosition: "fleetActivity",
            otherStringParams: {
              filters: JSON.stringify([
                {
                  column: "activityDate",
                  formula: "is_between",
                  value: [dayjsNY(fromDate).format(), dayjsNY(toDate).format()],
                },
              ]),
            },
          })
        )
      ),
      Promise.all(
        timelineSlice.map(({ fromDate, toDate }) =>
          fetchByDate(
            "fleetAudits",
            [dayjsNY(fromDate).format(), dayjsNY(toDate).format()],
            "auditDate"
          )
        )
      ),
    ])
      .then(([stopRes = [], activityRes = [], auditRes = []]) => {
        let audits = auditRes?.flat();

        for (const dates of timelineSlice) {
          let firstDayFormat = dayjsNY(dates.fromDate).startOf("D").format();

          let secondDayFormat = dayjsNY(dates.fromDate)
            .add(1, "d")
            .startOf("D")
            .format();

          let firstDayStart = dates.fromDate;
          let secondDayStart = dayjsNY(dates.fromDate)
            .startOf("D")
            .add(1, "d")
            .valueOf();

          let secondDayEnd = dayjsNY(secondDayStart).endOf("D").valueOf();

          for (const imei in analytics) {
            let vehicle = locations?.find(
              ({ deviceSerialNumber: i }) => i === imei
            );
            const fleetId = vehicle?.fleetId;

            if (vehicle) {
              for (let iteration = 0; iteration < 2; iteration++) {
                let start = !iteration ? firstDayStart : secondDayStart;
                let end = !iteration ? secondDayStart : secondDayEnd;

                let dateFormat = !iteration ? firstDayFormat : secondDayFormat;

                let dateKey = parseInTz(dateFormat).format(FORMAT);

                let auditForDate = audits?.find(
                  ({ auditDate }) => auditDate === dateFormat
                )?.["alertChanges"]?.[fleetId];

                let stops = stopRes.flatMap(
                  (r) =>
                    r?.data?.data?.stops?.filter(
                      ({ deviceSerialNumber: i, beginDate, endDate }) =>
                        i === imei &&
                        ((beginDate <= start && endDate >= end) ||
                          (beginDate <= start && endDate >= start) ||
                          (beginDate <= end && endDate >= end) ||
                          (beginDate >= start && endDate <= end))
                    ) || []
                );

                let activities = activityRes
                  ?.flat()
                  .filter(
                    ({ activityStatus, fleetId: id, activityDate }) =>
                      activityStatus !== "Cancelled" &&
                      id === fleetId &&
                      activityDate === dateFormat
                  );

                let auditedActivities = {};
                if (auditForDate) {
                  for (const alertId in auditForDate?.["changed"]) {
                    let activityId =
                      auditForDate?.["changed"]?.[alertId]?.["activityId"];
                    if (activityId) {
                      let existing = auditedActivities?.[activityId];
                      if (!existing) {
                        auditedActivities[activityId] = {
                          start:
                            auditForDate?.["changed"]?.[alertId]?.[
                              "alertDateTime"
                            ],
                          end: undefined,
                        };
                      } else {
                        let time =
                          auditForDate?.["changed"]?.[alertId]?.[
                            "alertDateTime"
                          ];
                        let existingTime = existing?.start;
                        if (time < existingTime) {
                          auditedActivities[activityId] = {
                            start: time,
                            end: existingTime,
                          };
                        } else {
                          auditedActivities[activityId]["end"] = time;
                        }

                        let relatedActivity = activities?.find(
                          ({ activityId: id }) => activityId === id
                        );

                        if (relatedActivity) {
                          auditedActivities[activityId] = {
                            ...auditedActivities[activityId],
                            plannedStart: dayjsNY(
                              relatedActivity?.startingTime
                            ).valueOf(),
                            plannedEnd: dayjsNY(
                              relatedActivity?.timeAtLocation
                            ).valueOf(),
                            fromAddress: relatedActivity?.pickUpLocation,
                            toAddress: relatedActivity?.dropOffLocation,
                            startCoordinates:
                              relatedActivity?.pickUpCoordinates,
                            endCoordinates: relatedActivity?.dropOffCoordinates,
                            estimatedDistance: withinRadius(
                              relatedActivity?.pickUpCoordinates,
                              relatedActivity?.dropOffCoordinates
                            ).distanceInMile,
                          };
                        } else {
                          delete auditedActivities[activityId];
                        }
                      }
                    }
                  }
                }

                for (const activityId in auditedActivities) {
                  let audit = auditedActivities[activityId];

                  analytics[imei][dateKey]["totalTripCount"] =
                    analytics[imei][dateKey]["totalTripCount"] + 1;

                  if (audit.plannedStart - TIME_TOLERANCE > audit.start) {
                    analytics[imei][dateKey]["aheadOfScheduleDepartures"] =
                      analytics[imei][dateKey]["aheadOfScheduleDepartures"] + 1;
                  } else if (
                    audit.plannedStart + TIME_TOLERANCE <
                    audit.start
                  ) {
                    analytics[imei][dateKey]["lateDepartures"] =
                      analytics[imei][dateKey]["lateDepartures"] + 1;
                  } else {
                    analytics[imei][dateKey]["onScheduleStarts"] =
                      analytics[imei][dateKey]["onScheduleStarts"] + 1;
                  }

                  if (audit.plannedEnd - TIME_TOLERANCE > audit.end) {
                    analytics[imei][dateKey]["aheadOfScheduleArrives"] =
                      analytics[imei][dateKey]["aheadOfScheduleArrives"] + 1;
                  } else if (audit.plannedEnd + TIME_TOLERANCE < audit.end) {
                    analytics[imei][dateKey]["lateArrives"] =
                      analytics[imei][dateKey]["lateArrives"] + 1;
                  } else {
                    analytics[imei][dateKey]["onScheduleArrives"] =
                      analytics[imei][dateKey]["onScheduleArrives"] + 1;
                  }

                  analytics[imei][dateKey]["totalTripsDuration"] =
                    analytics[imei][dateKey]["totalTripsDuration"] +
                    (audit.end - audit.start);

                  analytics[imei][dateKey]["totalEstimatedDistance"] =
                    analytics[imei][dateKey]["totalEstimatedDistance"] +
                    audit.estimatedDistance;
                }

                if (analytics[imei][dateKey]["totalTripCount"]) {
                  analytics[imei][dateKey]["averageTripsDuration"] =
                    analytics[imei][dateKey]["totalTripsDuration"] /
                    analytics[imei][dateKey]["totalTripCount"];
                }

                if (analytics[imei][dateKey]["totalTripCount"]) {
                  analytics[imei][dateKey]["averageTripDistance"] =
                    analytics[imei][dateKey]["totalEstimatedDistance"] /
                    analytics[imei][dateKey]["totalTripCount"];
                }

                totals[dateKey]["totalTripCount"] =
                  totals[dateKey]["totalTripCount"] +
                  analytics[imei][dateKey]["totalTripCount"];

                totals[dateKey]["totalEstimatedDistance"] =
                  totals[dateKey]["totalEstimatedDistance"] +
                  analytics[imei][dateKey]["totalEstimatedDistance"];

                totals[dateKey]["totalTripsDuration"] =
                  totals[dateKey]["totalTripsDuration"] +
                  analytics[imei][dateKey]["totalTripsDuration"];

                totals[dateKey]["aheadOfScheduleDepartures"] =
                  totals[dateKey]["aheadOfScheduleDepartures"] +
                  analytics[imei][dateKey]["aheadOfScheduleDepartures"];

                totals[dateKey]["lateDepartures"] =
                  totals[dateKey]["lateDepartures"] +
                  analytics[imei][dateKey]["lateDepartures"];

                totals[dateKey]["onScheduleStarts"] =
                  totals[dateKey]["onScheduleStarts"] +
                  analytics[imei][dateKey]["onScheduleStarts"];

                totals[dateKey]["lateArrives"] =
                  totals[dateKey]["lateArrives"] +
                  analytics[imei][dateKey]["lateArrives"];

                totals[dateKey]["onScheduleArrives"] =
                  totals[dateKey]["onScheduleArrives"] +
                  analytics[imei][dateKey]["onScheduleArrives"];

                totals[dateKey]["aheadOfScheduleArrives"] =
                  totals[dateKey]["aheadOfScheduleArrives"] +
                  analytics[imei][dateKey]["aheadOfScheduleArrives"];

                let accIdle = 0,
                  accStop = 0,
                  idleCont = 0,
                  stopCount = 0;

                for (const stop of stops) {
                  let isIdle = (stop?.stopType || "")
                    ?.toLowerCase()
                    ?.includes("idling");

                  if (isIdle) {
                    idleCont++;
                    accIdle += calcDuration({
                      beginDate: stop?.beginDate,
                      endDate: stop?.endDate,
                      start,
                      end,
                    });
                  } else {
                    stopCount++;
                    accStop += calcDuration({
                      beginDate: stop?.beginDate,
                      endDate: stop?.endDate,
                      start,
                      end,
                    });
                  }
                }

                analytics[imei][dateKey]["idleEventCount"] = idleCont;
                analytics[imei][dateKey]["stopEventCount"] = stopCount;
                analytics[imei][dateKey]["totalIdleDuration"] = accIdle;
                analytics[imei][dateKey]["totalStopDuration"] = accStop;

                totals[dateKey]["idleEventCount"] =
                  totals[dateKey]["idleEventCount"] + idleCont;
                totals[dateKey]["stopEventCount"] =
                  totals[dateKey]["stopEventCount"] + stopCount;
                totals[dateKey]["totalIdleDuration"] =
                  totals[dateKey]["totalIdleDuration"] + accIdle;
                totals[dateKey]["totalStopDuration"] =
                  totals[dateKey]["totalStopDuration"] + accStop;

                if (idleCont) {
                  analytics[imei][dateKey]["averageIdleDuration"] =
                    accIdle / idleCont;
                }

                if (stopCount) {
                  analytics[imei][dateKey]["averageStopDuration"] =
                    accStop / stopCount;
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while getting analytics",
          key: "reportsFetch",
        });
        console.log("Error getting reports data: ", err);
        ERROR = true;
      });

    if (ERROR) {
      analytics = defaultAnalytics;
      totals = defaultTotals;
      break;
    }

    step += iterator;
  }

  if (!ERROR) {
    message.destroy("reportsFetch");

    for (const date in totals) {
      if (totals[date]["totalTripCount"]) {
        totals[date]["averageTripDistance"] =
          totals[date]["totalEstimatedDistance"] /
          totals[date]["totalTripCount"];
      }

      if (totals[date]["totalTripCount"]) {
        totals[date]["averageTripsDuration"] =
          totals[date]["totalTripsDuration"] / totals[date]["totalTripCount"];
      }

      if (totals[date]["stopEventCount"]) {
        totals[date]["averageStopDuration"] =
          totals[date]["totalStopDuration"] / totals[date]["stopEventCount"];
      }

      if (totals[date]["idleEventCount"]) {
        totals[date]["averageIdleDuration"] =
          totals[date]["totalIdleDuration"] / totals[date]["idleEventCount"];
      }
    }

    saveLocalPreferences({
      liveReportsTimeline: [minDate, maxDate],
      liveReportsVehicleFilter: selectedVehicle,
    });
  }

  return { analytics, totals };
}

function calcDuration({ beginDate, endDate, start, end }) {
  let duration = 0;
  if (beginDate < start && endDate > end) {
    duration = end - start;
  } else {
    if (beginDate < start) {
      duration = endDate - start;
    } else if (endDate > end) {
      duration = end - beginDate;
    } else {
      duration = endDate - beginDate;
    }
  }

  return duration;
}

export default fetchReportData;
