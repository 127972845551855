import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";

export const dispatching = ({ tables }) => {
  const allDrivers = tables?.Dispatching?.map((a) =>
    a?.routes?.map((e) => e?.driverName)
  )?.flat();
  return {
    fleetName: () => ({
      labels: uniq(tables?.Dispatching?.map((a) => a?.fleetName)),
      data: uniq(tables?.Dispatching?.map((a) => a?.fleetName))?.map(
        (status) =>
          tables?.Dispatching?.filter((a) => a.fleetName === status)?.length
      ),
      backgroundColor: uniq(tables?.Dispatching?.map((a) => getRandomColor())),
    }), //fleetName
    driverName: () => ({
      labels: uniq(allDrivers),
      data: uniq(allDrivers)?.map(
        (status) => allDrivers?.filter((a) => a === status)?.length
      ),
      backgroundColor: uniq(allDrivers)?.map((a) => getRandomColor()),
    }), //driverName
    dispatchedBy: () => ({
      labels: uniq(
        tables?.Dispatching?.map((a) =>
          a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
        )
      ),
      data: uniq(
        tables?.Dispatching?.map((a) =>
          a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
        )
      )?.map(
        (status) =>
          tables?.Dispatching?.filter((a) =>
            a.dispatchedBy?.name
              ? a?.dispatchedBy?.name?.toLowerCase()
              : a?.dispatchedBy?.toLowerCase() === status?.toLowerCase()
          )?.length
      ),
      backgroundColor: uniq(tables?.Dispatching?.map((a) => getRandomColor())),
    }), //dispatchedBy
  }; //Dispatching
};
