import React from "react";

import { useStepData } from "../../hooks";
import { generateInputFields } from "./helpers";
import { NextStepInputs } from "../NextStepInputs";

const Hearings = ({ inputData, setInputData }) => {
  const { schedules, projects } = useStepData("Hearings");

  const inputFields = generateInputFields({
    schedules,
    projects,
  });

  return <NextStepInputs {...{ inputData, setInputData, inputFields }} />;
};

export default Hearings;
