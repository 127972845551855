import { Tooltip } from "antd";
import "./StatusSquircle.scss";

const StatusSquircle = ({
  statusName,
  value,
  color = "",
  onClick,
  autoSizeAllColumns,
}) => {
  return (
    <div
      className={`statusSquircleContainer ${
        !!onClick ? "clickable" : ""
      }`?.trim?.()}
      style={{ backgroundColor: color }}
      data-testid="status-panel"
      onClick={() => {
        if (!!onClick) {
          onClick(statusName);
          autoSizeAllColumns(true);
        }
      }}
    >
      <div className="statusValue">{value}</div>
      <Tooltip
        {...{
          title: statusName,
          overlayClassName: "statusCardOverlay",
          placement: "bottom",
        }}
      >
        <div className="statusName">{statusName}</div>
      </Tooltip>
    </div>
  );
};

export default StatusSquircle;
