import Swal from "sweetalert2";
import { Form, Input } from "antd";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Link, useParams } from "react-router-dom";

import { KeyIcon } from "../../assets";
import { MailIconFilled } from "../../icons";
import CodeConfirmation from "./CodeConfirmation";
import AuthWrapper from "../AuthWrapper/AuthWrapper";
import LoginButton from "../../components/LoginButton";
import { emailRegex } from "../../components/SidebarPages/utils/validURL";
import { ReactComponent as UserIcon } from "../../icons/Auth_icons/user_icon.svg";
import { ReactComponent as LockIcon } from "../../icons/Auth_icons/lock_icon.svg";
import { ReactComponent as EyeIcon } from "../../icons/Auth_icons/eye_icon.svg";
import { ReactComponent as EyeClosedIcon } from "../../icons/Auth_icons/eye_closed_icon.svg";

import "./InvitationSignUp.scss";

const InvitationSignUp = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();

  const { code } = useParams();

  const onFinish = async () => {
    setIsLoading(true);

    await Auth.signUp({
      username: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      attributes: {
        phone_number:
          "+" +
          form
            .getFieldValue("phone_number")
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
        family_name: form.getFieldValue("lastName"),
        given_name: form.getFieldValue("firstName"),
        "custom:department": "Executive",
        "custom:dep": "Admin",
        "custom:code": form.getFieldValue("code"),
        "custom:invitationUrl": code,
      },
    })
      .then((result) => {
        setUser(result);
      })
      .catch((error) => {
        Swal.fire({
          text: error.message.replace("PreSignUp failed with error ", ""),
          icon: "error",
          showConfirmButton: false,
          timer: 10000,
        });

        console.log({ error });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthWrapper>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ remember: false }}
        className="sign-up-form"
      >
        {!user ? (
          <>
            <Form.Item name="firstName" rules={[{ required: true }]}>
              <Input
                placeholder="First Name"
                prefix={<UserIcon style={{ height: 25 }} />}
              />
            </Form.Item>
            <Form.Item name="lastName" rules={[{ required: true }]}>
              <Input
                placeholder="Last Name"
                prefix={<UserIcon style={{ height: 25 }} />}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(emailRegex),
                  message: "Invalid email",
                },
              ]}
            >
              <Input
                placeholder="Email..."
                prefix={<MailIconFilled style={{ height: 25 }} />}
              />
            </Form.Item>
            <Form.Item
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please complete the mobile number",
                },
              ]}
            >
              <PhoneInput
                disableCountryGuess
                country={"us"}
                onlyCountries={["us"]}
                disableCountryCode={true}
                placeholder="Phone number"
                specialLabel={""}
                copyNumbersOnly={false}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Password..."
                prefix={<LockIcon style={{ height: 25 }} />}
                iconRender={(visible) =>
                  visible ? <EyeIcon /> : <EyeClosedIcon />
                }
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                  validator: (_, value) =>
                    !!value
                      ? form.getFieldValue("password") === value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Passwords are not the same")
                          )
                      : Promise.resolve(),
                },
              ]}
            >
              <Input.Password
                placeholder="Confirm Password..."
                prefix={<LockIcon style={{ height: 25 }} />}
                iconRender={(visible) =>
                  visible ? <EyeIcon /> : <EyeClosedIcon />
                }
              />
            </Form.Item>
            <Form.Item name="code" rules={[{ required: true }]}>
              <Input
                placeholder="Invitation Code..."
                prefix={<KeyIcon style={{ height: 25 }} />}
              />
            </Form.Item>
            <Form.Item>
              <LoginButton
                type="primary"
                className="signin-btn"
                htmlType="submit"
                isLoading={isLoading}
                text="Sign Up"
                loadingText="Signing Up"
              />
            </Form.Item>

            <Link to="/login" className="already-have-account">
              <p>Already have account?</p>
            </Link>
          </>
        ) : (
          <CodeConfirmation form={form} />
        )}
      </Form>
    </AuthWrapper>
  );
};

export default InvitationSignUp;
