const MAINTENANCE_REPORT_STYLES = {
  "section-header": {
    "font-size": "16px",
    width: "calc(100% - 40px)",
    background:
      "linear-gradient(90deg, rgba(9,121,28,1) 0%, rgba(236,245,238,1) 30%, rgba(255,255,255,1) 75%)",
    padding: "10px 20px",
    display: "block",
    color: "#fff",
    "margin-top": "3rem",
  },
  "section-header.after-section": {
    "margin-top": "1rem",
  },
  "card-table > tbody > tr > td, .card-table > tr > th": {
    border: "none",
  },
  "card-table": {
    display: "block",
    "border-radius": "10px",
  },
  "card-table-inspection": {
    "background-color": "#9553b944",
    border: "2px solid #9553b9cc",
  },
  "card-table-work-order": {
    "background-color": "#f0477344",
    border: "2px solid #f04773cc",
  },
  "child-section": {
    "margin-top": "2rem",
  },
  "card-table > tbody": {
    display: "grid",
    "grid-template-columns": "auto",
    "grid-template-rows": "auto auto auto",
    padding: "20px",
    gap: "10px",
  },
  "card-table > tbody > tr": {
    display: "grid",
    "grid-template-columns": "50% 50%",
  },
  "card-table > tbody > td": {
    "place-self": "center",
  },
  "defect-table > tbody > tr > td": {
    padding: "10px",
  },
  "defect-table > tbody > tr > th": {
    "background-color": "#f5f5f7",
  },
  separator: {
    width: "100%",
    display: "grid",
    "place-items": "center",
    "border-top": "1px solid black",
    "border-bottom": "1px solid black",
    "font-size": "16px",
    "font-weight": "600",
    height: "3rem",
    "margin-top": "2rem",
    "border-image":
      "linear-gradient( 90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100% ) 1",
  },
};

export default MAINTENANCE_REPORT_STYLES;
