import { getScheduleOfValue } from "../../../../../../actions/scheduleOfValues";
import { useDispatchContext, useStateContext } from "./Context/Context";
import ServicesMenu from "./components/ServicesMenu/ServicesMenu";
import ServiceList from "./components/ServiceList/ServiceList";
import Header from "./components/Header/Header";
import "./schedule-of-value-view.scss";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { API } from "aws-amplify";
import { useRedux } from "../../../../hooks";
import { useTabClose } from "../../../../../../hooks/useTabClose";
import { useUndoRedoState } from "../../../../../../hooks/useUndoRedoState";
import { getErrors } from "./components/ServiceList/utils/getErrors";
import NotFound from "../../../../NotFound/NotFound";

const ScheduleOfValueView = ({ isNextStep, sovId, closeModal }) => {
  const { scheduleOfValue, error } = useSelector(
    (state) => state?.scheduleOfValues
  );
  const { scheduleId: currentScheduleId } = useParams();
  const dispatch = useDispatch();
  // Context dispatch
  const dispatchContext = useDispatchContext();

  //get dark mode from context
  const { darkMode, fakeSOV } = useStateContext();

  const scheduleId = currentScheduleId || sovId;

  const { programFields } = useSelector((state) => state.programFields);

  //Undo Redo Limit for Rentals
  const undoRedoLimit = programFields.find(
    (item) => item.fieldName === "Statuses Of Categories"
  ).fieldOptions?.["Schedule Of Values"];

  //undo redo Functions
  const {
    itemToSaveActiveState: newFakeSOV,
    changeStateHandler,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    undoClickHandler,
    redoClickHandler,
  } = useUndoRedoState(undoRedoLimit);

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;

  //Closes the tab if user tries to open same tab and clicks close
  useTabClose(scheduleId);

  // Fetch SOV on mount or whenever company or sov changes
  useEffect(() => {
    if (scheduleId) {
      dispatch(getScheduleOfValue(scheduleId));
    }

    if (isNextStep && sovId)
      dispatchContext({
        type: "SET_NEXT_STEP",
        payload: { isNextStep, sovId, closeModal },
      });
  }, [dispatch, scheduleId]);

  // Fetch types of work on mount
  useEffect(() => {
    (async () => {
      try {
        const proposedTypeOfWork = await API?.get(
          "proposedTypeOfWork",
          "/proposedTypeOfWork"
        );
        dispatchContext({
          type: "SET_PROPOSED_TYPE_OF_WORK",
          payload: proposedTypeOfWork,
        });
      } catch (err) {
        console?.error(err);
      }
    })();
  }, [dispatchContext]);

  const setErrors = (errors) => {
    dispatchContext({ type: "SET_ERRORS", payload: errors });
  };
  useEffect(() => {
    if (newFakeSOV) {
      dispatchContext({
        type: "SET_FAKE_SOV",
        payload: JSON.parse(JSON.stringify(newFakeSOV)),
      });
    }
    const errors = getErrors(newFakeSOV);
    if (Object.values(errors).every((obj) => !Object.keys(obj).length)) {
      setErrors(null);
    } else {
      setErrors(errors);
    }
  }, [newFakeSOV]);

  useEffect(() => {
    if (fakeSOV) {
      changeStateHandler(JSON.parse(JSON.stringify(fakeSOV)));
    }
  }, [fakeSOV]);

  // Keep Fake SOV in Sync whenever Real SOV updates
  useEffect(() => {
    if (scheduleOfValue) {
      changeStateHandler(JSON.parse(JSON.stringify(scheduleOfValue)));
    }
    window.addEventListener("beforeunload", (event) => {
      event.returnValue = `Are you sure you want to leave?`;
    });
  }, [dispatchContext, scheduleOfValue]);

  if (!scheduleOfValue && error.status === 404) {
    return <NotFound />;
  }

  return (
    scheduleOfValue && (
      <div
        className={
          darkMode ? "schedule-of-value-view-dark" : "schedule-of-value-view"
        }
        // style={{ position: "relative" }}
      >
        <div
          className="schedule-of-value-view-body"
          style={{ position: "relative" }}
        >
          <div className="schedule-of-value-view-body__header-container">
            <Header
              canUndo={canUndo}
              canRedo={canRedo}
              undoClickHandler={undoClickHandler}
              redoClickHandler={redoClickHandler}
              closeModal={closeModal}
            />
          </div>
          <div className="scheduleOfValuesMainBodyContainer">
            <div className="scheduleOfValuesMainBodyContainer__services-menu">
              <ServicesMenu />
            </div>
            <div className="scheduleOfValuesMainBodyContainer__service-list">
              <ServiceList />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ScheduleOfValueView;
