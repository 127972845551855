import { Select } from "antd";
import { LabelRenderer, PriceSchemeSelectorDropdown } from "./components";
import { v4 as uuid } from "uuid";
import "./PriceSchemeSelector.scss";
import { useHoistPricingContext } from "../../context";
import { DropDownArrow } from "../../../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { usePricingContext } from "../../../../../PricingV2/context";

const PriceSchemeSelector = ({
  pricingObject,
  setPricingObject,
  generateNewLog,
  value,
  onChange,
  priceSchemes,
  isDarkMode,
}) => {
  const { hoistPricingData } = useHoistPricingContext();
  const { schemeHeaderRef7 } = usePricingContext;

  // Creates a new price scheme
  const onPriceSchemeCreation = (name) => {
    if (!name?.trim?.()) return;

    const newPriceScheme = {
      name,
      id: uuid(),
      configurationByType: { single: [], dual: [] },
    };

    const newHoistPricingData = {
      ...hoistPricingData,
      priceSchemes: [...priceSchemes, newPriceScheme],
    };

    generateNewLog(
      "Create",
      `Schema ${pricingObject.priceSchemesType}`,
      {},
      {
        id: newPriceScheme.id,
        name: newPriceScheme.name,
      },
      newPriceScheme.id
    );

    setPricingObject(newHoistPricingData);
    onChange(newPriceScheme.id);
  };

  // Deletes a price scheme
  const onPriceSchemeDeletion = (idToDelete, name) => (event) => {
    event.stopPropagation();

    const newHoistPricingData = {
      ...hoistPricingData,
      priceSchemes: priceSchemes.filter(({ id }) => id !== idToDelete),
    };

    generateNewLog(
      "Delete",
      `Schema ${pricingObject.priceSchemesType}`,
      {
        id: idToDelete,
        name,
      },
      {},
      idToDelete
    );

    setPricingObject(newHoistPricingData);
  };

  return (
    <div ref={schemeHeaderRef7}>
      <Select
        value={value}
        onSelect={onChange}
        suffixIcon={<DropDownArrow fill="#fff" />}
        popupClassName={isDarkMode && "darkDropDown"}
        options={priceSchemes.map(({ id, name }) => ({
          value: id,
          label: (
            <LabelRenderer
              id={id}
              name={name}
              value={value}
              onPriceSchemeDeletion={onPriceSchemeDeletion(id, name)}
            />
          ),
        }))}
        className="price-scheme-selector"
        dropdownRender={(menu) => (
          <PriceSchemeSelectorDropdown
            menu={menu}
            onPriceSchemeCreation={onPriceSchemeCreation}
          />
        )}
        dropdownStyle={{
          borderRadius: 5,
        }}
      />
    </div>
  );
};

export default PriceSchemeSelector;
