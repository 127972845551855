export const serviceHiddenColumns = (serviceId) => {
  const commonKeys = ["id", "approved", "rent", "lock", "ppu", "addons"];
  var services = {
    1: [...commonKeys, "lnf", "length", "jump"],
    2: [
      ...commonKeys,
      "height",
      "sqft",
      "fromFloor",
      "toFloor",
      "note",
      "includedCorners",
      "generalNotes",
      "custom",
    ],
    3: [...commonKeys, "floor"],
    4: [...commonKeys, "length"],
    5: [...commonKeys, "length", "sqft"],
    6: [...commonKeys, "length"],
    7: [...commonKeys, "length"],
    8: [...commonKeys, "length"],
    9: [...commonKeys, "length"],
    10: [...commonKeys, "length"],
    11: [...commonKeys, "length", "height", "sqft"],
    12: [...commonKeys, "length", "sqft"],
    13: [...commonKeys, "length", "sqft"],
    14: [...commonKeys, "length", "height", "sqft"],
    15: [...commonKeys, "length"],
    16: [...commonKeys],
    17: [...commonKeys, "length"],
    18: [...commonKeys, "length", "height", "sqft"],
    19: [...commonKeys, "length", "height", "sqft"],
    20: [...commonKeys, "length", "width", "sqft"],
    default: [...commonKeys],
  };
  return services[serviceId] || services["default"];
};
