import "./EventsList.scss";
import moment from "moment";
import { Popconfirm, Tooltip } from "antd";
import { deleteEvent } from "../../googleClientAPI/execute";
import { PrintIcon } from "../../../../../SidebarPages/BasePage/src";
import { DeleteIcon } from "../../../../../../assets";

const noEvent = (
  <div>
    <br />{" "}
    <strong style={{ color: "red", display: "flex", justifyContent: "center" }}>
      No Events to show
    </strong>
  </div>
);

function EventsList({
  events,
  userDetails,
  calendarColor,
  selectedCalendar,
  handleSelect,
}) {
  //handles error when clicking link and having multiple accounts open in google
  const customEventLink = (link) => {
    let eventLink = link;
    return eventLink;
  };

  const printLink = (eventID, attach) => {
    let eID = eventID.slice(37).slice(0, -21);
    // const eID = eventID.slice(37);
    let pLink = `https://calendar.google.com/calendar/u/${userDetails.email}/printevent${eID}&src=${attach}`;

    return pLink;
  };

  const handleDeleteEvent = (eventInstance) => {
    deleteEvent(eventInstance.id, selectedCalendar).then(() =>
      handleSelect(selectedCalendar)
    );
  };

  return (
    <div className="events-list-container">
      <ul>
        {events?.length > 0 ? (
          <>
            <div className="list-event-item listEventHeader">
              <div className="dot-calendar-color"></div>
              <div className="event-name ">
                <h3 className="event-summary">Event Name</h3>
              </div>
              <div className="event-time ">
                <h3 className="event-summary">Start Time</h3>
              </div>
              <div className="actions-title ">
                <h3 className="event-summary ">Actions</h3>
              </div>
            </div>
            {events?.map((el, i) => (
              <div key={i} className="list-event-item">
                <div className="dot-calendar-color">
                  <span
                    className="dot-style"
                    style={{ backgroundColor: calendarColor }}
                  ></span>
                </div>
                <div className="event-name">
                  <h3
                    className="event-summary"
                    onClick={() => window.open(customEventLink(el.htmlLink))}
                  >
                    {el.summary.length > 25
                      ? el.summary.slice(0, 25) + "..."
                      : el.summary}
                  </h3>
                </div>
                <div className="event-time">
                  <h3
                    className="event-summary"
                    onClick={() =>
                      window.open(
                        customEventLink(el.htmlLink, el?.organizer?.email)
                      )
                    }
                  >
                    {moment(el.start.dateTime)
                      .tz("America/New_York")
                      .format("MM/DD/YYYY HH:mm ")}
                  </h3>
                </div>
                <div className="open-event-btn">
                  <Tooltip title={"Print"}>
                    <div
                      className="event-btn print-btn"
                      onClick={() =>
                        window.open(
                          printLink(el.htmlLink, el?.organizer?.email)
                        )
                      }
                    >
                      <PrintIcon />
                    </div>
                  </Tooltip>
                  <Popconfirm
                    title={
                      <div>
                        <strong>
                          Are you sure to delete this event? (You can not undo
                          this action){" "}
                        </strong>{" "}
                        <br /> Event Name: <strong> {el.summary}</strong> <br />
                        Timeline:{" "}
                        <strong>
                          {moment(el.start.dateTime).format("MM/DD/YYYY")} -{" "}
                          {moment(el.end.dateTime).format("MM/DD/YYYY")}
                        </strong>{" "}
                        <br />
                      </div>
                    }
                    onConfirm={() => handleDeleteEvent(el)}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <Tooltip title={"Delete Event"}>
                      <div className="event-btn delete-btn">
                        {" "}
                        <DeleteIcon />
                      </div>
                    </Tooltip>
                  </Popconfirm>
                </div>
              </div>
            ))}
          </>
        ) : (
          userDetails && noEvent
        )}
      </ul>
    </div>
  );
}

export default EventsList;
