import { getMonthsTimestamps, getRandomColor } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const maintenance = ({ tables }) => {
  return {
    createdAt: () => ({
      labels: months,
      data: (() => {
        let creationsDate = []
        for (let i = 0; i < 12; i++) {
          creationsDate = [
            ...creationsDate,
            tables?.Maintenance?.map((a) => a?.createdAt)?.filter(
              (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
            )?.length,
          ]
        }
        return creationsDate
      })(),
      backgroundColor: months.map((a) => getRandomColor()),
    }), //createdAt
  }
} //Maintenance
