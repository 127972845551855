import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  getRowNodeId,
  onCellKeyPress,
} from "../../../Applications/ApplicationView/components/ServiceList/GridData/GridUtils/index";
import { formatCurrency } from "../../../../../utils/index";
import { Checkbox } from "antd";
import { frameworkComponents } from "../../../Applications/ApplicationView/components/ServiceList/GridData/index";
import { cellEditingStarted } from "../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/AgGridComponents/RentalDetails/rentalDetailsFunctions";
import { DescriptionModal } from "./components/DescriptionModal";
import AgGridMathful from "../../../Applications/ApplicationView/components/ServiceList/Service/ServiceContent/AgGridSum/AgGridMathful";
import { checkIfServiceIsHoist } from "../../../../../Estimations/DataEntryGrid/models/Service";

export const RequistionAgGrid = ({
  service,
  darkMode,
  isWritable,
  currentCharge,
  elevationLabel,
  totalThisPeriod,
  totalPaymentDue,
  creditAmount,
  serviceOptions: rowData,
  setChargeToSave,
  el,
}) => {
  const [selected, setSelected] = useState(false); // Context state & dispatch
  const defaultColDef = {
    hide: false,
    filter: true,
    sortable: false,
    resizable: true,
    rowHeight: 40,
    enablePivot: true,
    enableRowGroup: false,
    flex: 0.7,
  };
  const isLoading = false;

  const onRangeSelectionChanged = (event) => {
    // console.log("hoist selection changed", event);
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  };

  const columnDefs = [
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>Action</div><div class='textContainerStretched'>Action</div></div>",
      },
      headerTooltip: "",
      field: "paymentDue",
      minWidth: 100,
      cellRendererFramework: ({ value: paymentDue }) => {
        const checked = paymentDue !== 0;
        return (
          <div className="completeCheckboxTextContainer">
            <Checkbox
              checked={checked}
              className={`checkBox ${checked ? "checkedActive" : ""}`}
              style={{
                cursor: paymentDue !== 0 ? "not-allowed" : "default",
              }}
            />
          </div>
        );
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>#</div><div class='textContainerStretched'>#</div></div>",
      },
      headerTooltip: "Id",
      field: "id",
      minWidth: 70,
      cellRenderer: ({ value }) => value + 1,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>B</div><div class='textContainerStretched'>Description of work</div></div>",
      },
      headerName: "Description of work",
      headerTooltip: "Events",
      field: "name",
      minWidth: 370,
      editable: true,
      cellRendererFramework: (gridParams) => {
        return (
          <>
            <div className="descriptionOfWorkCellContainer">
              {gridParams?.value}
            </div>
          </>
        );
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>C</div><div class='textContainerStretched'>Schedule of value</div></div>",
      },
      headerName: "Schedule of value",
      headerTooltip: "Amount",
      field: "amount",
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      minWidth: 160,
      editable: false,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>E</div><div class='textContainerStretched'>This period</div></div>",
      },
      headerName: "This period",
      headerTooltip: "This Period",
      field: "thisPeriod",
      minWidth: 160,
      cellEditor: "PeriodCellEditor",
      cellStyle: {
        color: "white",
        backgroundColor: "#68C142",
      },
      cellRendererFramework: (params) => (
        <div className="editableCellContainer">
          <div>{formatCurrency(params?.value, "USD")}</div>
        </div>
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>D</div><div class='textContainer'>From previous requisition</div><div class='actionsContainer'>(D+E)</div></div>",
      },
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      headerTooltip: "From Previous Requisition",
      headerName: "From previous requisition",
      field: "fromPrevious",
      minWidth: 260,
      editable: false,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>Payment Due</div></div>",
      },
      headerName: "Payment Due",
      headerTooltip: "Payment Due",
      field: "paymentDue",
      minWidth: 200,
      cellStyle: {
        // backgroundColor: "white",
        color: "#68C142",
      },
      editable: false,
      valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Action</div></div>",
      },
      headerTooltip: "Total Completed",
      field: "totalCompleated",
      editable: false,
      minWidth: 140,
      cellRendererFramework: (gridParams) => (
        <div className="completeCheckboxTextContainer">Completed</div>
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>%</div><div class='actionsContainer'>(G/C)</div></div>",
      },
      headerName: "%",
      headerTooltip: "Percentage Completed",
      cellEditor: "PeriodCellEditor",
      field: "percentageCompleated",
      minWidth: 130,
      editable: true,
      cellStyle: {
        color: "white",
        backgroundColor: "#68C142",
      },
      cellRendererFramework: ({ value }) => {
        return (
          <div className="editableCellContainer">
            <div>{!!value ? +(+value)?.toFixed(2) + "%" : "0.00%"}</div>
          </div>
        );
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Balance to finish</div><div class='actionsContainer'>(C-G)</div></div>",
      },
      headerName: "Balance to finish",
      headerTooltip: "Balance To Finish",
      field: "balanceToFinish",
      minWidth: 200,
      editable: false,
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>I</div><div class='textContainer'>Retainage</div><div class='actionsContainer'>(Balance)</div></div>",
      },
      headerName: "Retainage",
      headerTooltip: "Retainage",
      field: "retainage",
      minWidth: 140,
      editable: false,
      valueFormatter: (params) => {
        // let curr =
        //   params?.data?.thisPeriod !== 0
        //     ? formatCurrency(params?.value, "USD")
        //     : formatCurrency(0, "USD");
        return formatCurrency(params?.value || 0, "USD");
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Tax Amount</div></div>",
      },
      headerName: "Tax Amount",
      headerTooltip: "Tax Amount",
      field: "taxAmount",
      minWidth: 130,
      editable: false,
      valueFormatter: ({ value }) => formatCurrency(value, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Collectable amount</div><div class='actionsContainer'>(C-D-I)</div></div>",
      },
      headerName: "Collectable amount",
      headerTooltip: "Collectable Amount",
      field: "collectable_amount",
      minWidth: 160,
      editable: false,
      hide: true,
      valueFormatter: (params) =>
        formatCurrency(
          params?.value < 0 || !params.value ? 0 : params?.value,
          "USD"
        ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>Description</div><div class='textContainerStretched'>Description</div></div>",
      },
      headerName: "Description",
      headerTooltip: "Description",
      field: "note",
      minWidth: 100,
      editable: false,
      cellRendererFramework: ({ value, data }) => {
        console.log("value", value);
        return (
          <DescriptionModal
            {...{
              note: value?.replace('contenteditable="false"', ""),
              onConfirm: ({ newValue }) => {
                setChargeToSave((prev) => {
                  let currService = prev?.chargeItems?.find(
                    ({ label: L, estimationId: eI }) =>
                      L === service?.label && eI === service?.estimationId
                  );
                  if (checkIfServiceIsHoist(service))
                    currService = currService?.serviceOptions?.find(
                      ({ elevationId: eI, elevationLabel: eL }) =>
                        data?.elevationId === eI && data?.elevationLabel === eL
                    );

                  const lineItem = currService?.serviceOptions?.find(
                    ({ name, id }) => id === data?.id
                  );
                  console.log("dataa", { currService, lineItem, data });
                  lineItem.note = newValue;
                  return structuredClone(prev);
                });
              },
              currentCharge,
            }}
          />
        );
      },
    },
  ];
  
  return (
    <div
      className={`applicationAgGridContainer ${
        darkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      } documentationsTable reqTables`}
    >
      <div className="header">
        <div style={{ minWidth: 400 }}>
          {elevationLabel
            ? `${service?.label} → ${elevationLabel}`
            : service?.label}
        </div>
        {/* <CreditEnabler
                {...{
                  service: {
                    ...el,
                    label: service?.label,
                    estimationId: service?.estimationId,
                    elevationLabel,
                    amounts: rowData?.flat(1),
                  },
                  setChargeToSave,
                  isWritable,
                }}
              /> */}
      </div>
      {selected && <AgGridMathful darkMode={darkMode} event={selected} />}
      <AgGridReact
        headerHeight={90}
        onRangeSelectionChanged={onRangeSelectionChanged}
        enablePivot={true}
        immutableData={true}
        undoRedoCellEditing={true}
        enableRangeSelection={true}
        enableCellChangeFlash={true}
        stopEditingWhenGridLosesFocus={true}
        suppressDragLeaveHidesColumns={true}
        allowDragFromColumnsToolPanel={true}
        domLayout="autoHeight"
        rowSelection="single"
        undoRedoCellEditingLimit={50}
        defaultColDef={defaultColDef}
        rowData={rowData}
        rowHeight={40}
        columnDefs={columnDefs}
        getRowNodeId={getRowNodeId}
        onCellKeyPress={onCellKeyPress}
        onCellEditingStarted={(params) =>
          cellEditingStarted({ params, isLoading, isWritable })
        }
        frameworkComponents={frameworkComponents}
      />
      <div className="header">
        <div style={{ display: "flex", gap: 25 }}>
          <div style={{ minWidth: 300 }}>
            This Period: {formatCurrency(totalThisPeriod || 0)}
          </div>
          <div style={{ minWidth: 300 }}>
            Payment Due: {formatCurrency(totalPaymentDue || 0)}
          </div>
          <div style={{ minWidth: 300 }}>
            Credit Amount: {formatCurrency(creditAmount || 0)}
          </div>
        </div>
        <div style={{ width: 150 }}></div>
      </div>
    </div>
  );
};
