import { useState } from "react";
import ViewSignature from "./components/ViewSignature/ViewSignature";
import SignatureModal from "./components/SignatureModal/SignatureModal";

const Signature = ({
  signature,
  setSignature,
  existingSignature = "",
  signDisabled = false,
  removeDisabled = false,
  signButtonName = "Sign",
  viewClassName = "",
}) => {
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [whichSignToChange, setWhichSignToChange] = useState();
  const [editSignIn, setEditSignIn] = useState(false);

  return (
    <>
      <ViewSignature
        {...{
          setWhichSignToChange,
          setSignatureModalVisible,
          setEditSignIn,
          signature,
          setSignature,
          existingSignature,
          signDisabled,
          removeDisabled,
          signButtonName,
          viewClassName,
        }}
      />
      {signatureModalVisible && (
        <SignatureModal
          {...{
            visible: signatureModalVisible,
            setVisible: setSignatureModalVisible,
            signature,
            setSignature: whichSignToChange?.setFunc,
            editSignIn,
            setEditSignIn,
          }}
        />
      )}
    </>
  );
};

export default Signature;
