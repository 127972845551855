import { useState } from "react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, Radio, message } from "antd";

import {
  CheckIconModal,
  CloseIconModalHeader,
  GreenPin,
  InfoIcon,
  PinIcon,
} from "../../../../../assets";
import { Footer, TemplateOption } from "./components";
import { MondayButton } from "../../../../commonComponents";
import { preferencesBasePageFilters } from "../../../../../actions";
import { useAdvancedFilterContext } from "../../../../commonComponents/AdvancedFilters/context";

const removeNullKeysFromArray = (array) => {
  if (!Array.isArray(array) || array?.length === 0) return array;
  return array?.map((obj) => {
    const newObj = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value !== null) {
        newObj[key] = value;
      }
    });
    return newObj;
  });
};

const SaveFiltersModal = ({
  gridApi,
  columnApi,
  templateOptions,
  saveFiltersModal,
  customFilterTitle,
  setSaveFiltersModal,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);

  const selectedPreferences = preferences?.basepages?.[customFilterTitle];

  const [newTemplateOptions, setNewTemplateOptions] = useState(templateOptions);
  const [newTemplateValue, setNewTemplateValue] = useState("");
  const [selectedDefault, setSelectedDefault] = useState(
    selectedPreferences?.defaultFilter || ""
  );
  const [deleteItem, setDeleteItem] = useState(false);
  const [addedItem, setAddedItem] = useState(false);

  const dispatch = useDispatch();

  const onCancel = () => setSaveFiltersModal(false);

  const { filters } = useAdvancedFilterContext();

  const onCreateNewTemplateHandler = () => {
    if (newTemplateValue === "") {
      return;
    }

    setNewTemplateValue("");

    const updatedTemplateOptions = { ...newTemplateOptions };
    // const masterDetailState = {
    //   columnState: {},
    //   groupState: {},
    //   filterState: {},
    // };

    // gridApi?.forEachDetailGridInfo?.((params) => {
    //   if (keys(params.api.getFilterModel()).length > 0) {
    //     masterDetailState.columnState = params.columnApi.getColumnState();
    //     masterDetailState.groupState = params?.columnApi.getColumnGroupState();
    //     masterDetailState.filterState = params.api.getFilterModel();
    //   }
    // });

    updatedTemplateOptions[newTemplateValue] = templateObject();

    setNewTemplateOptions(updatedTemplateOptions);
    setAddedItem(true);
  };

  const onDeleteTemplateHandler = async (filter) => {
    if (selectedDefault === "" || selectedDefault === filter) {
      message.info(
        "Please choose a different default filter before deleting the current one."
      );
      return;
    }

    if (!addedItem) {
      const { [selectedDefault]: select, ...res } = selectedPreferences;

      const updatedBasePages = {
        ...(preferences?.basepages || {}),
        [customFilterTitle]: {
          ...res,
          defaultFilter:
            selectedPreferences.defaultFilter === selectedDefault
              ? "DEFAULT"
              : selectedPreferences.defaultFilter === "DEFAULT"
              ? "DEFAULT"
              : selectedPreferences.defaultFilter,
        },
      };
      delete updatedBasePages[customFilterTitle][filter];

      message.loading("Filter deletion in progress, please wait...");

      await API.patch("preferences", "/preferences", {
        body: { basepages: updatedBasePages },
      })
        .then(() => {
          setSelectedDefault("");
          setNewTemplateOptions((prev) => {
            delete prev[filter];
            return { ...prev };
          });
          dispatch(preferencesBasePageFilters(updatedBasePages));
          setDeleteItem(true);
          message.success("Filters have been successfully deleted.");
        })
        .catch(() =>
          message.error(
            "There was an issue deleting the filter. Please try again later."
          )
        );
    }
  };

  function templateObject() {
    const masterDetailState = {
      columnState: {},
      groupState: {},
      filterState: {},
    };

    gridApi?.forEachDetailGridInfo?.((params) => {
      if (keys(params.api.getFilterModel()).length > 0) {
        masterDetailState.columnState = params.columnApi.getColumnState();
        masterDetailState.groupState = params?.columnApi.getColumnGroupState();
        masterDetailState.filterState = params.api.getFilterModel();
      }
    });
    return {
      columnState: removeNullKeysFromArray(columnApi.getColumnState()),
      groupState: columnApi.getColumnGroupState(),
      filterState: gridApi.getFilterModel(),
      advancedFiltersState: filters,
      masterDetailState: masterDetailState,
    };
  }

  const updateDefaultFilterHandler = (e) => setSelectedDefault(e.target.value);

  return (
    <Modal
      title="Choose template"
      className={`chooseTemplateModal ${
        isDarkMode && "chooseTemplateModalDark"
      }`}
      wrapClassName={`chooseTemplateModal ${
        isDarkMode && "chooseTemplateModalDark"
      }`}
      open={saveFiltersModal}
      onCancel={onCancel}
      onOk={onCancel}
      closeIcon={<CloseIconModalHeader />}
      centered
      footer={
        <Footer
          {...{
            addedItem,
            deleteItem,
            setAddedItem,
            setDeleteItem,
            newTemplateOptions,
            selectedDefault,
            customFilterTitle,
            setSaveFiltersModal,
            templateObject,
            newTemplateValue,
          }}
        />
      }
    >
      <div className="info-text">
        <InfoIcon /> You can select one of the filters below to set as default
        filter for this page.
      </div>
      <div className="add-new-container"></div>
      <Radio.Group value={selectedDefault}>
        <div
          className="newFilterContainer"
          style={{ display: "flex", gap: "10px" }}
        >
          <Input
            value={newTemplateValue}
            onChange={(e) => setNewTemplateValue(e.target.value)}
            placeholder="Add a new template"
            style={{
              width: "405px",
              border: "none",
              background: "#F5F5F7",
              borderRadius: "5px",
            }}
          />

          <MondayButton
            Icon={<CheckIconModal />}
            onClick={onCreateNewTemplateHandler}
          >
            Create New Template
          </MondayButton>
        </div>
        <div className="filterTemplateModalContainer">
          <Radio
            value={"DEFAULT"}
            className="radioContainer"
            key={"default"}
            onClick={updateDefaultFilterHandler}
          >
            <div
              className="optionFilterContainer"
              style={{ width: 140, marginRight: 50 }}
            >
              <span
                className={
                  selectedDefault === "DEFAULT"
                    ? "radio-button-text__checked"
                    : "radio-button-text"
                }
              >
                Default
                {selectedDefault === "DEFAULT" ? <GreenPin /> : <PinIcon />}
              </span>
            </div>
          </Radio>
          {Object.keys(newTemplateOptions)?.map((filter, index) => (
            <TemplateOption
              key={index}
              {...{
                index,
                filter,
                onUpdate: updateDefaultFilterHandler,
                selectedDefault,
                addedItem,
                onDelete: onDeleteTemplateHandler,
              }}
            />
          ))}
        </div>
      </Radio.Group>
    </Modal>
  );
};

export default SaveFiltersModal;
