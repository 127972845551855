import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FolderFileIcon } from "../../../../../../../../../../../assets";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../../assets";
import { addToArray, getLength } from "../../../../../../../../functions";
import ItemContainer from "./item-container/ItemContainer";

import "./ChooseFilesModal.scss";
// import { UploadedFilesProvider } from "../../../../../../providers/AttachmentsProvider";

const ChooseFilesModal = ({
  setVisible,
  files = [],
  setFiles,
  folderName = "",
  folderId = "",
  getSelected,
  type,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [currentFolder, setCurrentFolder] = useState([folderName]);
  const [currentId, setCurrentId] = useState([folderId]);
  const [showUpload, setShowUpload] = useState(0);

  // let text = "";

  /**
   * @param {item} is the count of items in each folder
   
   */
  // const item = uniqBy(
  //   files
  //     ?.filter(
  //       (el) => el?.folder === currentFolder?.at(-1) && el?.type === "Folder"
  //     )
  //     ?.map(({ name, id }) =>
  //       uniqBy(
  //         files?.filter(
  //           ({ type, folder }) => type === "Item" && folder === name
  //         ),
  //         "id"
  //       )
  //     )
  // )?.filter((el) => el?.length > 0);

  // text =
  //   !item?.length && item?.type !== "Item"
  //     ? "There are no files in this folder."
  //     : "Please select the files in these folders.";

  /**
   * @param {selectedItem} is the count of selected items
   */
  let selectedItem = uniqBy(
    files?.filter(({ type, folder, folderId: parentId, selected }) => {
      return (
        type === "Item" &&
        folder === currentFolder.at(-1) &&
        (currentId.at(-1) === parentId || currentId.at(-1) === folderId) &&
        selected === true
      );
    }),
    "id"
  ).map((e) => e);

  // text = !selectedItem?.length
  //   ? text
  //   : "You have selected the following files:";

  return (
    <div className="editMask">
      <div className={`ChooseFilesModal ${isDarkMode ? "dark-mode" : ""}`}>
        <div className="ChooseFilesModalHeader">
          <span className="ChooseFilesModalHeaderTitle">Choose Files</span>
          <XIcon
            onClick={() => setVisible(false)}
            style={{ marginRight: "-12px", cursor: "pointer" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            paddingLeft: "20px",
          }}
        ></div>
        <div
          className="ChooseFilesModalBody"
          data-testid="choose-files-modal-body"
        >
          <span className="pathTitle">
            {currentFolder?.map((e, i) => (
              <>
                <span
                  key={i}
                  className="pathItem"
                  style={
                    i === getLength(currentFolder) - 1
                      ? { color: "#858586", cursor: "default" }
                      : {}
                  }
                  data-testid={`path-item-${i}`}
                  onClick={() => {
                    setCurrentFolder(
                      currentFolder.slice(
                        0,
                        currentFolder.findIndex((el) => el === e) + 1
                      )
                    );
                    setShowUpload(0);
                    setCurrentId(
                      currentId.slice(
                        0,
                        currentFolder.findIndex((el) => el === e) + 1
                      )
                    );
                  }}
                >
                  {getLength(currentFolder) === 1 ? (
                    <div style={{ margin: "-2rem" }}></div>
                  ) : (
                    e
                  )}
                </span>
                {i !== getLength(currentFolder) - 1 && <span>{" / "}</span>}
              </>
            ))}
          </span>
          <div className="ChooseFilesModalBodyFolder">
            {getLength(
              files?.filter(
                ({ type, folder }) =>
                  type === "Folder" && folder === currentFolder.at(-1)
              )
            ) !== 0 && (
              <div className="CategoryContainer">
                {/* <span className="CategoryTitle">Folders</span> */}
                <div className="CategoryItemsContainer">
                  {uniqBy(
                    files?.filter(({ type, folder, folderId: parentId }) => {
                      return (
                        type === "Folder" &&
                        folder === currentFolder.at(-1) &&
                        (currentId.at(-1) === parentId ||
                          currentId.at(-1) === folderId)
                      );
                    }),
                    "id"
                  )?.map(({ name, id }) => (
                    <div
                      className="FolderItemContainer"
                      onClick={() => {
                        setCurrentFolder(addToArray(currentFolder, name));
                        setCurrentId(addToArray(currentId, id));
                      }}
                      data-testid="folder-item-container"
                    >
                      <div className="CategoryIcon">
                        <Badge
                          count={getLength(
                            uniqBy(
                              files?.filter(
                                ({ type, folder }) =>
                                  type === "Item" && folder === name
                              ),
                              "id"
                            )
                          )}
                          showZero
                          offset={[-10, 27]}
                          color={
                            getLength(
                              uniqBy(
                                files?.filter(
                                  ({ type, folder }) =>
                                    type === "Item" && folder === name
                                ),
                                "id"
                              )
                            ) === 0
                              ? "#FE4C4A"
                              : "#1264A3"
                          }
                          // size="small"
                        >
                          <FolderFileIcon />
                        </Badge>
                      </div>
                      <span className="ItemTitle">{name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="CategoryContainer">
              {/* <span className="CategoryTitle">Files</span> */}

              <div
                className="CategoryItemsContainerFiles"
                data-testid="category-items-container-files"
              >
                {uniqBy(
                  files?.filter(({ type, folder, folderId: parentId }) => {
                    return (
                      type === "Item" &&
                      folder === currentFolder.at(-1) &&
                      (currentId.at(-1) === parentId ||
                        currentId.at(-1) === folderId)
                    );
                  }),
                  "id"
                )?.map((e) => (
                  <ItemContainer
                    isSelected={showUpload !== 0 ? e.selected : false}
                    files={files}
                    setFiles={setFiles}
                    file={e}
                    setShowUpload={setShowUpload}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="ChooseFilesModalFooter">
          <div
            className={`expandable ${
              showUpload === 0 ? "collapse" : "expanded"
            }`}
            style={showUpload === 0 ? { width: "105px" } : { width: "141px" }}
          >
            <MondayButton
              className="mondayButtonRed"
              onClick={() => {
                setVisible(false);
              }}
              Icon={<CloseOutlined />}
              data-testid="choose-files-modal-cancel-btn"
            >
              Cancel
            </MondayButton>
            {showUpload !== 0 && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<CheckOutlined />}
                onClick={() => getSelected(uniqBy(selectedItem, "id"), type)}
              >
                Attach Files
              </MondayButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChooseFilesModal;
