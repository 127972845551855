export const opportunities = (circleProgressPicker, IdHandler, updatedDynamicStates) => {
  return {
    opportunityStage: () => ({
      title: "Opportunity Stage",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStage",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.opportunityStage === circleProgressPicker
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.opportunityStage === circleProgressPicker
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }),
    opportunityStatus: () => ({
      title: "Opportunity Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Opportunities",
        field: "opportunityStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.opportunityStatus === circleProgressPicker
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.opportunityStatus === circleProgressPicker
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }),
  } //opportunities
}
