import { useSelector } from "react-redux";
import { DashboardWhite } from "../../../../icons";
import DashboardBoarding from "./components/DashboardBoarding";
import "./DashboardStep.scss";

const DashboardStep = ({ setBoardingChanges = () => {} }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      className={`dashboard-step step-lines ${
        isDarkMode ? "dashboard-step-dark" : ""
      }`}
    >
      <div className="dashboard-icon">
        <DashboardWhite style={{ width: "30px", height: "30px" }} />
      </div>

      <span style={{ textAlign: "center" }}>
        Select the default widgets you want to see on your dashboard.
      </span>

      <DashboardBoarding {...{ isDarkMode, setBoardingChanges }} />
    </div>
  );
};

export default DashboardStep;
