import { useState } from "react";
import { Modal, Button } from "antd";
import ServicePrice from "./ServicePrice/ServicePrice";
import "../EstimatesAmmount.scss";
import { MondayButton } from "../../../../../commonComponents";
import { XIcon } from "../../../../Communication/assets";
import { numberWithCommas } from "../../../../utils/numberWithCommas";
import { formatCurrency, roundToDecimals } from "../../../../utils";
import { useSelector } from "react-redux";
import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";

export default function EstAmountInsiderViewer({ data, buttonContent }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    jobSiteAddress = "",
    services = [],
    versionServices = {},
  } = data || {};

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const calculateServicesTotal = (services) => {
    let servicesTotal = getTotalServicesPrice(services);

    return servicesTotal;
  };

  const modalHeaderComp = (
    <>
      <h2>{jobSiteAddress}</h2>
      <h2>
        {numberWithCommas(roundToDecimals(calculateServicesTotal(services), 2))}
      </h2>
    </>
  );

  const noDataToCalculate = (value) => {
    // console.log(value)
    if (value === 0) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <div>
        {noDataToCalculate(calculateServicesTotal(services)) ? (
          Object.values(versionServices)?.length > 0 ? (
            <div
              onClick={showModal}
              style={{
                fontWeight: 600,
                color: "rgb(16, 101, 164)",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              View Amount Of All Versions
            </div>
          ) : (
            <div
              style={{ fontSize: "16px", fontWeight: 600, color: "#bfbfbf" }}
            >
              No Take Offs
            </div>
          )
        ) : (
          <Button
            type="primary"
            onClick={showModal}
            disabled={noDataToCalculate(calculateServicesTotal(services))}
            style={
              // !noDataToCalculate(calculateServicesTotal(services))
              // ?
              {
                borderRadius: "4px",
                margin: "0 auto",
                backgroundColor: "#1264a3",
                borderColor: "#1264a3",
                color: "#fff",
              }
              // : {}
            }
          >
            {formatCurrency(calculateServicesTotal(services))}
          </Button>
        )}
      </div>
      {isModalVisible &&
        (!noDataToCalculate(calculateServicesTotal(services)) ? (
          <Modal
            className={`est_ammount_modal ${
              isDarkMode && "est_ammount_modal_dark"
            }`}
            title={modalHeaderComp}
            open={isModalVisible}
            onCancel={handleCancel}
            centered
            footer={
              <MondayButton
                className="mondayButtonRed"
                onClick={handleCancel}
                Icon={<XIcon />}
              >
                Close
              </MondayButton>
            }
            closable={false}
          >
            <ServicePrice services={services} />
          </Modal>
        ) : (
          <Modal
            className={`est_ammount_modal ${
              isDarkMode && "est_ammount_modal_dark"
            }`}
            title={<div>{jobSiteAddress}</div>}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={
              <MondayButton
                className="mondayButtonRed"
                onClick={handleCancel}
                Icon={<XIcon />}
              >
                Close
              </MondayButton>
            }
            closable={false}
            centered
          >
            <div className="priceModalBody">
              {Object.values(versionServices)?.map((version, i) => {
                return (
                  <div className="priceVersionContainer" key={i}>
                    <div className="priceVersionHeader">
                      <h4>{`${version?.versionName} (${version?.status})`}</h4>
                      <h4 className="price">
                        {formatCurrency(
                          calculateServicesTotal(version?.services)
                        )}
                      </h4>
                    </div>
                    <ServicePrice services={version?.services} />
                  </div>
                );
              })}
            </div>
          </Modal>
        ))}
    </div>
  );
}

//returns the total service amount as a number value in order to be used by the chart
export const getServicePriceValue = ({ data }) => {
  const { jobSiteAddress = "", services = [] } = data || {};

  return getTotalServicesPrice(services);
};
