import { Button, Modal } from "antd";
import { useEffect, useMemo, useState } from "react";

import { formatCurrency } from "../../../../utils";
import { XIcon } from "../../../../Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import EstimationServiceTotal from "./EstimationServiceTotal";
import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";

function EstimationAmountNoAPIcall({ data, isOppEstimations }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [estimations, setEstimations] = useState([]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
   * Function that return estimates ammount total value
   * @param {estimations} array - Estimation list
   */
  const calculateServiceTotal = (estimations) => {
    let estTotals = 0;
    estimations?.length &&
      estimations?.map((estimation) => {
        if (
          Array.isArray(estimation?.services) &&
          estimation.services.length > 0
        ) {
          estTotals += getTotalServicesPrice(estimation?.services);
        }
      });
    return formatCurrency(estTotals);
  };

  const totalCalculated = useMemo(
    () => calculateServiceTotal(estimations),
    [estimations]
  );

  const modalHeaderComp = (
    <>
      <h2>{data?.projectName || data?.opportunityAddress}</h2>
      <h2>{totalCalculated}</h2>
    </>
  );

  const noDataToCalculate = (value) => {
    if (value === "0.00" || value === "NaN") {
      return true;
    }
    return false;
  };

  const getEstimationArray = (estimation) => {
    return Object.keys(estimation).map((key, index) => ({
      name: `Estimation ${index + 1}`,
      services: estimation[key],
    }));
  };

  function generateEstimations(estimations, projectEstimations) {
    return estimations
      .map((estimation, index) => {
        return {
          name: `Estimation ${index + 1}`,
          services: projectEstimations[estimation],
        };
      })
      .filter((item) => item.services && item.services.length);
  }

  useEffect(() => {
    if (isOppEstimations) {
      // if (
      //   (data?.estimations || []).length &&
      //   Object.keys(data?.projectEstimations || {}).length
      // ) {
      setEstimations(
        generateEstimations(
          data?.estimations || [],
          data?.projectEstimations || []
        )
      );
      // }
      // else if ((data?.estimationObjects || []).length) {
      //   setEstimations(
      //     data?.estimationObjects.map((el, i) => {
      //       return {
      //         name: `Estimation ${i + 1}`,
      //         services: el?.services,
      //         versionServices: el?.versionServices,
      //       };
      //     }) || []
      //   );
      // }
    } else setEstimations(getEstimationArray(data?.projectEstimations || []));
  }, [isOppEstimations]);

  return (
    <div
      className="cell-div"
      style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
    >
      <Button
        onClick={showModal}
        disabled={noDataToCalculate(totalCalculated)}
        style={
          !noDataToCalculate(totalCalculated)
            ? { borderRadius: "5px", background: "#1065A4", color: "#fff" }
            : { borderRadius: "5px" }
        }
      >
        {totalCalculated}
      </Button>
      {isModalVisible && (
        <Modal
          className="est_ammount_modal"
          title={modalHeaderComp}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={
            <MondayButton
              onClick={handleCancel}
              className="mondayButtonRed"
              Icon={<XIcon />}
            >
              Close
            </MondayButton>
          }
          closable={false}
        >
          <EstimationServiceTotal estimations={estimations} />
        </Modal>
      )}
    </div>
  );
}

export default EstimationAmountNoAPIcall;
