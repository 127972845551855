import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { evaluateMathExpression, toFixedFormatter } from "../formatters";
import { evaluateMonth } from "../formatters/evaluators";

export const barriers = [
  {
    headerName: "ID",
    headerTooltip: "ID",
    field: "id",
    minWidth: 35,
    width: 35,
    editable: false,
  },
  {
    headerName: "Approved",
    headerTooltip: "Approved",
    field: "approved",
    minWidth: 150,
    width: 150,
    editable: false,
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "L",
    headerTooltip: "Length",
    field: "length",
    width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "H",
    headerTooltip: "Height",
    field: "height",
    width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "P",
    headerTooltip: "Pieces",
    field: "pieces",
    width: 80,
    valueFormatter: evaluateMathExpression,
  },
  {
    headerName: "Material",
    headerTooltip: "Material",
    field: "material",
    width: 120,
    cellRenderer: "SelectEditor",
    cellRendererParams: {
      values: ["Concrete Barriers", "Plastic Barriers", "Not Specified"],
    },
  },
  {
    headerName: "With fence",
    headerTooltip: "With fence",
    field: "withFence",
    width: 80,
    editable: false,
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "Fence Type",
    headerTooltip: "Fence Type If Applicable",
    field: "fenceType",
    width: 120,
    cellRenderer: "SelectEditor",
    cellRendererParams: {
      values: [
        "With Chain Link Fence on top",
        "With Plywood Fence on top",
        // "Not Applicable",
      ],
    },
  },
  {
    headerName: "Addons",
    headerTooltip: "Addons",
    field: "addons",
    width: 200,
    minWidth: 140,
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    cellRendererParams: {
      suppressCount: true,
      // checkbox: true,
      innerRenderer: "SidewalkShedAddonsRenderer",
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
    },
    valueFormatter: (params) => {
      if (params.data.addons) {
        return `(${params.data?.addons?.length})`;
      } else {
        return "N/A";
      }
    },
  },
  {
    headerName: "Description",
    headerTooltip: "Description",
    field: "description",
    width: 150,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "Internal Note",
    headerTooltip: "Internal Note",
    field: "note",
    width: 150,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "PPU",
    headerTooltip: "Price Per Unit",
    field: "ppu",
  },
  {
    headerName: "Rent",
    headerTooltip: "Rent",
    field: "rent",
    cellRenderer: "SidewalkShedRentAdvisor",
  },
  {
    headerName: "Months",
    headerTooltip: "Months",
    field: "months",
    width: 80,
    valueFormatter: evaluateMonth,
  },
  {
    headerName: "Price",
    headerTooltip: "Price",
    field: "price",
    getQuickFilterText: () => "",
  },
  {
    headerName: "Lock",
    field: "lock",
    cellRenderer: "CheckboxRenderer",
    minWidth: 70,
    width: 70,
    editable: false,
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    valueFormatter: ({ value = 0 }) => `${value * 100}%`,
    editable: false,
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    valueFormatter: toFixedFormatter,
    editable: false,
  },
  {
    headerName: "Total",
    field: "totalPrice",
    valueFormatter: toFixedFormatter,
    // valueGetter: ({ data }) => {
    //   const { totalPrice = 0, pliAddonTotal = 0 } = data;
    //   let temp1 = forceToNumber(pliAddonTotal);
    //   let temp2 = forceToNumber(totalPrice);
    //   console.log("temp12", { temp1, temp2 });
    //   return temp1 + temp2;
    // },
    editable: false,
  },
];
