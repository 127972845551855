import { message } from "antd";
import { showErrorMsg } from "../../../../../../../../../utils";

const PercentageChangeInput = ({
  charge,
  isWritable,
  percentage,
  setPercentage,
  currentCharge,
  setSelectedAmount,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <span
        style={{ color: "white", fontSize: 17 }}
        data-testid="percent-symbol"
      >
        %{" "}
      </span>
      <input
        type="number"
        data-testid="percentage-input"
        className="input-percentage-amount"
        defaultValue={percentage}
        min={1}
        onClick={(e) => {
          if (currentCharge && !isWritable) {
            e.target.blur();
            message.info("Please enable Write Mode");
          }
        }}
        onChange={(e) => {
          if (e.target.value < 0) {
            showErrorMsg({
              content:
                "Please provide a valid percentage value. Negative percentages are not allowed.",
            });
            return;
          }
          setPercentage(e.target.value);
          setSelectedAmount(
            (charge?.totalities?.totalPrice || charge?.totalRentalAmount || 0) *
              (e.target.value / 100)
          );
        }}
        style={{
          cursor: currentCharge && !isWritable ? "not-allowed" : "auto",
        }}
      />
    </div>
  );
};

export default PercentageChangeInput;
