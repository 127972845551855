import { Flex, Tooltip } from "antd";
import { StarTwoTone, StarOutlined } from "@ant-design/icons";

import IconButton from "src/components/commonComponents/Buttons/IconButton";

import "./LabeledShortcutIcon.scss";

const LabeledShortcutIcon = ({
  src: Icon,
  title,
  subtitle,
  onClick,
  setPopoverVisible,
  style = {},
  onFavouriteClick,
  favouriteShortcut,
  propTestId = "",
}) => {
  const handleFavouriteClick = (e, shortcut) => {
    e.stopPropagation();
    if (onFavouriteClick) {
      onFavouriteClick(shortcut);
    }
  };

  return (
    <div
      className={`labeledIconContainer ${!onClick && "categoryName"}`}
      onClick={() => {
        if (onClick) {
          onClick();
          setPopoverVisible(false);
        }
      }}
      style={style}
      data-testid={propTestId}
    >
      <Flex justify="space-between" align="center" gap={4}>
        {Icon && (
          <span className="shortcutIcon">
            <Icon />
          </span>
        )}
        {/* <div className="shortcutIcon" style={{ backgroundImage: `url(${src})` }} /> */}
        <div className="shortcutLabelContainer">
          <span className="shortcutTitle">{title}</span>
          <span className="shortcutSubtitle">{subtitle}</span>
        </div>
      </Flex>
      {onFavouriteClick && (
        <Tooltip
          title={
            favouriteShortcut ? "Remove from favourites" : "Mark as favourite"
          }
        >
          <IconButton
            shape="circle"
            onClick={(e) => handleFavouriteClick(e, title)}
            data-testid={`${propTestId}-favourite`}
            icon={
              favouriteShortcut ? (
                <StarTwoTone twoToneColor="#fcba03" />
              ) : (
                <StarOutlined style={{ color: "#fff" }} />
              )
            }
          />
        </Tooltip>
      )}
    </div>
  );
};

export default LabeledShortcutIcon;
