import { useState, useMemo, useEffect } from "react";
import {
  Modal,
  InputNumber,
  Alert,
  Input,
  message,
  Button,
  Tooltip,
  Select,
} from "antd";
import "./RentalCalculator.scss";
import SwitchComponent from "../../../../../commonComponents/SwitchComponent/SwitchComponent";
import { MondayButton } from "../../../../../commonComponents/index";
import { SaveIcon } from "../../../../BasePage/src/index";
import {
  AddIcon,
  InfoIcon,
} from "../../../../../Header/components/GoogleEvents/assets/index";
import {
  priceInputFormatter,
  priceInputParser,
  totalitiesRentCalculator,
} from "./utils";
import { formatCurrency } from "../../../../utils/formatCurrency";
import {
  BlueInfoIcon,
  CheckIconModal,
  CloseIconModal,
  CloseIconModalHeader,
} from "../../../../../../assets/index";
import AnimatedNumber from "react-animated-number";
import { useTakeOffContext } from "../../context";
import { MathActionsIcon } from "../../../../../../icons";
import AdditionalRentalChart from "./rentalCalcChart/AdditionalRentalChart";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { InputComponent } from "../../../../Fleet/components";
import { AgGridReact } from "ag-grid-react";
import CustomFooter from "../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import { getApprovedRentTotal } from "../ServiceHeader/ServicePrices/ServicePrices";
import { RefreshIcon } from "../../../../Communication/assets";
import { checkIfServiceIsHoist } from "../../models/Service";
import { updateDocumentTitle } from "../../../../../../utils";
import { set } from "lodash";
import { LockFilled, UnlockFilled } from "@ant-design/icons";

function RentalCalculator({
  elevation,
  stateSetter,
  handleElevationFieldChange,
  serviceIndex,
  optionIndex,
  elevationIndex,
  // isWritable,
  // gridData,
  // updateStateAndSave,
  agGridApiForEachElevation,
  selectedPriceSchemesForService,
  priceView,
  accessRights,
  /// new props
  service,
  gridData,
  updateStateAndSave,
  title = "Additional Rental Calculator",
  collapsedTrigger = false,
  keyToSave = "additionalRentalTerms", // additionalRentalTerms for Primary or alternativeRentalTerms for Alternative
}) {
  const [calculatorOpen, setCalculatorActive] = useState(false);
  const [reset, setReset] = useState(false);
  const [inputVals, setInputVals] = useState({
    months: service?.[keyToSave]?.months || 0,
    freeMonths: service?.[keyToSave]?.freeMonths || 0,
    initialRate: service?.rent || 0,
    recurringRate: service?.[keyToSave]?.recurringRate || 7,
    taxRate: service?.[keyToSave]?.taxRate || 0,
    freeMonthsArr: service?.[keyToSave]?.freeMonthsArr || [],
    defaultMonths: 3,
  });
  const [tempHelper, setTempHelper] = useState({ desiredRent: 0 });
  const { agGridTheme, isWritable } = useTakeOffContext();
  // const elevSum = !!elevation
  //   ? +elevation?.items
  //       ?.reduce?.(
  //         (acc, curr) =>
  //           (acc += !isNaN(+curr?.["totalPrice"]) ? +curr?.["totalPrice"] : 0),
  //         0
  //       )
  //       ?.toFixed?.(2) || 0
  //   : 0;

  const { approvedRent } = getApprovedRentTotal(service, keyToSave);

  const totalPriceMapper = (el) => {
    console.log("totalPriceMapper", el);
    return (
      forceToNumber(el?.totalPrice) -
      forceToNumber(el?.taxAmount) -
      forceToNumber(el?.pliAddonTotal)
    );
  };
  const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;
  const priceTotal = useMemo(() => {
    let indexToSearch = keyToSave === "alternativeRentalTerms" ? 1 : 0;
    if (!calculatorOpen) return 0;
    try {
      return !!service
        ? !checkIfServiceIsHoist(service)
          ? [
              ...service?.serviceOptions?.[indexToSearch]?.flatMap?.(
                ({ items }) =>
                  items
                    ?.filter?.(({ approved }) => !!approved)
                    ?.map?.(totalPriceMapper) || [0]
              ),
              ...(service?.serviceAddons?.map?.(totalPriceMapper) || [0]),
            ]
              ?.filter(Boolean)
              ?.reduce((acc, curr) => (acc += curr), 0)
              ?.toFixed?.(2) || 0
          : [
              ...service?.serviceOptions?.[indexToSearch]?.map(
                hoistTotalPriceMapper
              ),
              ...service?.serviceAddons.map(totalPriceMapper),
            ]
              ?.filter(Boolean)
              ?.reduce((acc, curr) => (acc += curr), 0)
              ?.toFixed?.(2) || 0
        : 0;
    } catch (e) {
      return 0;
    }
  }, [service, JSON.stringify(gridData), calculatorOpen, keyToSave]);

  const getInitialRate = () => {
    try {
      const res = { rate: 0 };
      res.rate =
        (forceToNumber(approvedRent) / forceToNumber(priceTotal)) * 100;
      return res?.rate?.toFixed?.(2) ?? 0;
    } catch (e) {
      console.log("e", e);
      return 0;
    }
  };

  const totalities = useMemo(() => {
    if (!calculatorOpen) return;
    return totalitiesRentCalculator({
      // elevation,
      numberOfMonths: inputVals.months,
      currPrice: Number(priceTotal || 0),
      initialRate: getInitialRate(),
      recurringRate: inputVals.recurringRate,
      taxRate: inputVals.taxRate,
      service,
      reset,
      keyToSave,
    });
  }, [
    service,
    inputVals,
    // elevSum,
    calculatorOpen,
    priceTotal,
    gridData,
    calculatorOpen,
    reset,
    keyToSave,
  ]);

  const onInputChangeVal = (val, key) => {
    setInputVals((prev) => ({ ...prev, [key]: val }));
    if (key === "freeMonths") {
      setInputVals((prev) => ({ ...prev, freeMonthsArr: [] }));
    }
  };

  const onRecalculate = () => {
    setReset((prev) => !prev);
  };

  const onRentSubtotalChange = () => {
    let val = tempHelper?.desiredRent || 0;
    if (val === 0) {
      message.info("Please enter a valid number");
      return;
    }
    let newRecurringRate = 100 * (val / (inputVals.months * priceTotal));
    onInputChangeVal(newRecurringRate, "recurringRate");
  };

  const onLockEveryChange = () => {
    const submitData = {
      months: inputVals.months,
      freeMonths: inputVals.freeMonths,
      initialRate: getInitialRate(),
      // recurringRate: 7,
      // initialRate: inputVals.initialRate,
      recurringRate: inputVals.recurringRate,
      totalPrice: totalities.totalPrice,
      monthlyPrice: totalities.monthlyPrice,
      newPrice: totalities.newPrice,
      taxRate: inputVals.taxRate,
      taxAmount: totalities?.taxAmount,
      priceWithoutTax: totalities?.priceWithoutTax,
      freeMonthsArr: inputVals?.freeMonthsArr,
      isDistributedInPLIs: true,
    };

    console.log("what we send", submitData);

    let newGridData = JSON.parse(JSON.stringify(gridData));
    let myEl = newGridData.find((el) => {
      if (el.serviceId === service?.serviceId) {
        el[keyToSave] = { ...el?.[keyToSave], lockEverything: true };
      }
    });
    updateStateAndSave(newGridData);
    message.success("Data Saved Successfully.");
    // closeModal();
    console.log("my el", {
      newGridData,
      service,
      myEl,
    });
    // console.log("submitData", submitData);
    // handleElevationFieldChange(
    //   submitData,
    //   serviceIndex,
    //   optionIndex,
    //   elevationIndex,
    //   "additionalRentalTerms"
    // );  }
  };
  const onSubmit = () => {
    const submitData = {
      months: inputVals.months,
      freeMonths: inputVals.freeMonths,
      initialRate: getInitialRate(),
      // recurringRate: 7,
      // initialRate: inputVals.initialRate,
      recurringRate: inputVals.recurringRate,
      totalPrice: totalities.totalPrice,
      monthlyPrice: totalities.monthlyPrice,
      newPrice: totalities.newPrice,
      taxRate: inputVals.taxRate,
      taxAmount: totalities?.taxAmount,
      priceWithoutTax: totalities?.priceWithoutTax,
      freeMonthsArr: inputVals?.freeMonthsArr,
      isDistributedInPLIs: true,
    };

    console.log("what we send", submitData);

    let newGridData = JSON.parse(JSON.stringify(gridData));
    let myEl = newGridData.find((el) => {
      if (el.serviceId === service?.serviceId) {
        let elevations = el?.serviceOptions?.[0] || [];
        elevations.forEach((elev) => {
          let plis = elev?.items || [];
          plis.forEach((pli) => {
            if (pli?.approved === true) {
              pli.addRentalsMonths = forceToNumber(submitData?.months);
              pli.addRentalsRecuringRate = forceToNumber(
                submitData?.recurringRate
              );
              pli.freeMonths = forceToNumber(submitData?.freeMonths);
              pli.totalAddMonths =
                forceToNumber(submitData?.months) +
                forceToNumber(submitData?.freeMonths) +
                3;

              pli.addRentalsPriceMonth = forceToNumber(
                (forceToNumber(pli?.price) *
                  forceToNumber(pli?.addRentalsRecuringRate)) /
                  100
              );
              pli.addRentalsTotal =
                forceToNumber(pli?.addRentalsPriceMonth) * pli.addRentalsMonths;
              pli.addTaxRate = forceToNumber(submitData?.taxRate);
            }
          });
        });
        el[keyToSave] = submitData;
      }
    });
    updateStateAndSave(newGridData);
    message.success("Data Saved Successfully.");
    // closeModal();
    console.log("my el", {
      newGridData,
      service,
      myEl,
    });
    // console.log("submitData", submitData);
    // handleElevationFieldChange(
    //   submitData,
    //   serviceIndex,
    //   optionIndex,
    //   elevationIndex,
    //   "additionalRentalTerms"
    // );
  };
  console.log("service rental", service);

  const onMoveTo = () => {
    let newKey =
      keyToSave === "additionalRentalTerms"
        ? "alternativeRentalTerms"
        : "additionalRentalTerms";
    let newGridData = JSON.parse(JSON.stringify(gridData));
    let myEl = newGridData.find((el) => {
      if (el.serviceId === service?.serviceId) {
        el[newKey] = { ...service?.[keyToSave], initialRate: null };
        el[keyToSave] = {};
      }
    });
    updateStateAndSave(newGridData);
    message.success("Data Saved Successfully.");
  };

  useEffect(() => {
    if (!calculatorOpen) return;
    if (!!service)
      setInputVals({
        months: service?.[keyToSave]?.months || 0,
        freeMonths: service?.[keyToSave]?.freeMonths || 0,
        initialRate: service?.[keyToSave]?.initialRate || getInitialRate(),
        recurringRate: service?.[keyToSave]?.recurringRate || 7,
        taxRate: service?.[keyToSave]?.taxRate || 0,
        freeMonthsArr: service?.[keyToSave]?.freeMonthsArr ?? [],
        defaultMonths:
          service?.[keyToSave]?.defaultMonths || approvedRent === 0 ? 0 : 3,
      });
  }, [service, calculatorOpen, gridData]);

  console.log("inputVals", { inputVals, service });

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setCalculatorActive(false);
    setReset(false);
  };

  return (
    <div
      className={`rental-calculator-holder${
        agGridTheme === "ag-theme-balham-dark" ? "__dark" : ""
      }`}
    >
      {" "}
      <span
        style={
          {
            // marginRight: 20
          }
        }
      >
        {!collapsedTrigger && (
          <MondayButton
            hasIcon={false}
            className="mondayButtonBlue"
            onClick={() => setCalculatorActive(true)}
            // style={{
            //   height: 32,
            //   width: 32,
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   background: "#3a3c4e",
            //   borderRadius: 5,
            // }}
          >
            {/* Additional Rental Calculator */}
            <Tooltip title="Additional Rentals Calculator">
              <div
                style={{
                  height: 32,
                  width: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // background: "#3a3c4e",
                  borderRadius: 5,
                }}
              >
                <MathActionsIcon />
              </div>
            </Tooltip>
          </MondayButton>
        )}

        {collapsedTrigger && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <MondayButton
              className="mondayButtonBlue"
              disabled={!isWritable}
              onClick={() => setCalculatorActive(true)}
              Icon={<MathActionsIcon />}
            >
              Edit Additional Rentals
            </MondayButton>
          </div>
        )}
      </span>{" "}
      {calculatorOpen && (
        <>
          <Modal
            title={title}
            afterOpenChange={(event) => {
              event &&
                updateDocumentTitle({
                  newTitle: title,
                });
            }}
            open={calculatorOpen}
            maskClosable={false}
            style={{ top: 20, height: "100%" }}
            width={"fit-content"}
            destroyOnClose={true}
            className={`rental-calculator-modal${
              agGridTheme === "ag-theme-balham-dark" ? "__dark" : ""
            }`}
            height="100%"
            // style={{ height: "100%" }}
            // onOk={handleOk}
            footer={null}
            onCancel={closeModal}
          >
            <div className="rental-calculator-body_content">
              {" "}
              {/* <Alert
  message="Here you can provide and distribute other Rental terms. (3 months are already included by default)"
  type="info"
  showIcon
/> */}
              <div className="warning-info-rental-desc">
                <BlueInfoIcon />
                <span>
                  Here you can provide and distribute other Rental terms.
                </span>
              </div>
              <div className="rental-calculator-actions_wrapper">
                {" "}
                <Alert
                  message="Disclaimer: For a month rental, we refer to a 28 days (4 week) period. "
                  type="info"
                  showIcon
                />
                <br />
                <div className="rental-calculator-actions_header">
                  <div className="rental-calculator-input-holder">
                    <label>Additional Rent (# of months)</label>
                    <InputNumber
                      value={inputVals.months}
                      controls={false}
                      onChange={(val) => onInputChangeVal(val, "months")}
                      className="rental-calculator-input"
                    />
                  </div>{" "}
                  <div className="rental-calculator-input-holder">
                    <label>Free months (EXTRA)</label>{" "}
                    <div style={{ display: "flex", gap: "20px" }}>
                      <InputNumber
                        value={inputVals.freeMonths}
                        controls={false}
                        onChange={(val) => onInputChangeVal(val, "freeMonths")}
                        className="rental-calculator-input"
                      />
                    </div>
                  </div>
                  <div className="rental-calculator-input-holder">
                    <label>Included Rent Rate (%)</label>
                    <InputNumber
                      value={forceToNumber(inputVals.initialRate)}
                      controls={false}
                      disabled
                      className="rental-calculator-input"
                    />
                  </div>{" "}
                  <div className="rental-calculator-input-holder">
                    <label>Recurring Rent Rate % </label>
                    <InputNumber
                      value={inputVals.recurringRate}
                      onChange={(val) => onInputChangeVal(val, "recurringRate")}
                      controls={false}
                      className="rental-calculator-input"
                    />
                  </div>{" "}
                </div>
                {/* <hr /> */}
                <br />
                <div className="warning-info-rental-desc">
                  <BlueInfoIcon />
                  <span>
                    Estimates amount regarding new changes in Rental payment
                    conditions.
                  </span>
                </div>
                <div className="rental-calculator-table">
                  <div className="rental-calculator-table_first-row">
                    <div className="rental-calculator-input-holder">
                      <label>Service Name</label>
                      <Input
                        style={{ border: "none" }}
                        value={service?.label || ""}
                        className="rental-calculator-input"
                        disabled
                      />
                    </div>{" "}
                    <div className="rental-calculator-input-holder">
                      <label>
                        Current Total Price (
                        {forceToNumber(inputVals?.defaultMonths)} months free)
                      </label>
                      <InputNumber
                        className="rental-calculator-input-price"
                        value={priceTotal}
                        controls={false}
                        formatter={priceInputFormatter}
                        parser={priceInputParser}
                        onChange={() => message.info("Not editable")}
                      />
                    </div>{" "}
                    <div className="rental-calculator-input-holder">
                      <label>Rent Price (monthly)</label>
                      <InputNumber
                        className="rental-calculator-input-price"
                        value={approvedRent?.toFixed(2) || 0}
                        controls={false}
                        formatter={priceInputFormatter}
                        parser={priceInputParser}
                        onChange={() => message.info("Not editable")}
                      />
                    </div>{" "}
                    <div className="rental-calculator-input-holder">
                      <label>Additional Rent Price (monthly)</label>
                      <InputNumber
                        className="rental-calculator-input-price"
                        value={totalities?.monthlyPrice?.toFixed(2) || 0}
                        controls={false}
                        formatter={priceInputFormatter}
                        parser={priceInputParser}
                        onChange={() => message.info("Not editable")}
                      />
                    </div>{" "}
                    <div className="rental-calculator-input-holder">
                      <label>Rent Subtotal (all months)</label>
                      <InputNumber
                        className="rental-calculator-input-price"
                        value={
                          forceToNumber(totalities.newPrice || 0) > 0
                            ? forceToNumber(totalities.newPrice || 0).toFixed(2)
                            : 0
                        }
                        onChange={(val) => {
                          setTempHelper(() => ({
                            desiredRent: forceToNumber(val),
                          }));
                        }}
                        controls={false}
                        formatter={priceInputFormatter}
                        parser={priceInputParser}
                        onBlur={onRentSubtotalChange}
                      />
                    </div>
                    <div className="rental-calculator-input-holder">
                      <label> Rent Taxable</label>{" "}
                      <SwitchComponent
                        label={`Taxable ${(service?.taxRate || 0) * 100}%`}
                        checked={inputVals.taxRate > 0}
                        onChange={(paramsChange) => {
                          if (paramsChange) {
                            let serviceTaxRate = service?.taxRate || 0;
                            if (serviceTaxRate > 0) {
                              onInputChangeVal(serviceTaxRate, "taxRate");
                            } else {
                              message.info("This service is not taxable");
                            }
                          } else {
                            onInputChangeVal(0, "taxRate");
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <DecideFreeMonths
                  {...{
                    inputVals,
                    onInputChangeVal,
                    totalities,
                  }}
                />
                <div className="rental-calculator-table">
                  <AdditionalRentalChart {...{ amounts: inputVals }} />
                </div>
                <br />
                <div className="hightlight-prices-container">
                  <label>Total Rent Months</label>
                  <AnimatedNumber
                    style={{
                      transition: "0.8s ease-out",
                      fontSize: 30,
                      fontWeight: 600,
                      transitionProperty: "background-color, color, opacity",
                    }}
                    value={
                      inputVals.months +
                      inputVals.freeMonths +
                      inputVals?.defaultMonths
                    }
                    // formatValue={(value) => formatCurrency(value)}
                  />{" "}
                  <label>Total Rent Price</label>
                  <AnimatedNumber
                    style={{
                      transition: "0.8s ease-out",
                      fontSize: 30,
                      fontWeight: 600,
                      transitionProperty: "background-color, color, opacity",
                    }}
                    value={
                      forceToNumber(totalities.newPrice || 0) -
                        forceToNumber(totalities?.taxAmount || 0) || 0
                    }
                    formatValue={(value) => formatCurrency(value)}
                  />{" "}
                  {totalities?.taxAmount > 0 && (
                    <>
                      <label>Tax Amount</label>
                      <AnimatedNumber
                        style={{
                          transition: "0.8s ease-out",
                          fontSize: 30,
                          fontWeight: 600,
                          transitionProperty:
                            "background-color, color, opacity",
                        }}
                        value={totalities?.taxAmount || 0}
                        formatValue={(value) => formatCurrency(value)}
                      />
                    </>
                  )}
                  <label>Total Price</label>
                  <AnimatedNumber
                    style={{
                      transition: "0.8s ease-out",
                      fontSize: 30,
                      fontWeight: 600,
                      transitionProperty: "background-color, color, opacity",
                    }}
                    value={totalities.newPrice || 0}
                    formatValue={(value) => formatCurrency(value)}
                  />{" "}
                </div>
                <div className="rental-calculator-footer">
                  <MondayButton
                    className="mondayButtonRed"
                    Icon={<CloseIconModal />}
                    onClick={closeModal}
                  >
                    Discard & Close
                  </MondayButton>{" "}
                  <MondayButton
                    className="mondayButtonBlue"
                    Icon={<RefreshIcon />}
                    onClick={onRecalculate}
                  >
                    Recalculate
                  </MondayButton>{" "}
                  <MondayButton
                    className="mondayButtonRed"
                    Icon={
                      service?.[keyToSave]?.lockEverything ? (
                        <UnlockFilled />
                      ) : (
                        <LockFilled />
                      )
                    }
                    onClick={onLockEveryChange}
                  >
                    {service?.[keyToSave]?.lockEverything ? "Unlock" : "Lock"}{" "}
                    Every Change
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonBlue"
                    Icon={<RefreshIcon />}
                    onClick={onMoveTo}
                  >
                    Move to{" "}
                    {keyToSave === "additionalRentalTerms"
                      ? "Alternate"
                      : "Primary"}{" "}
                    Rentals
                  </MondayButton>
                  {isWritable && (
                    <MondayButton Icon={<CheckIconModal />} onClick={onSubmit}>
                      Save Changes
                    </MondayButton>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default RentalCalculator;

const DecideFreeMonths = ({ inputVals, onInputChangeVal, totalities }) => {
  console.log("inputVals", { inputVals, totalities });
  const [vals, setVals] = useState([]);

  const getRows = () => {
    console.log("getRows", inputVals);
    let freeMonthsArr = inputVals?.freeMonthsArr || [];
    // Month logic is months + 3 (default) + freeMonths (additional)
    let rows = [];
    let tempMax =
      forceToNumber(inputVals?.months) +
      forceToNumber(inputVals?.defaultMonths) +
      forceToNumber(inputVals?.freeMonths);
    for (let i = 0; i < tempMax; i++) {
      let isMonthFree = freeMonthsArr.includes(i + 1);
      rows.push({
        month: i + 1,
        free: isMonthFree,
        monthlyPrice: isMonthFree ? 0 : totalities?.monthlyPrice,
      });
    }
    return rows;
  };

  const markAsFree = (month) => {
    if (!month) return;
    let temp = structuredClone(
      Array.isArray(inputVals?.freeMonthsArr) ? inputVals?.freeMonthsArr : []
    );
    if (temp.includes(month)) {
      // case when month is already free
      temp = temp.filter((el) => el !== month);
    } else {
      let limit =
        forceToNumber(inputVals?.freeMonths) +
        forceToNumber(inputVals?.defaultMonths);
      // case when month is not free
      if (temp.length < limit) {
        temp.push(month);
      } else {
        message.info(
          `Maximum of ${limit} free months allowed as per your configuration`
        );
      }
    }
    onInputChangeVal(temp, "freeMonthsArr");
  };
  return (
    <div className="decide_free_months">
      {/* <strong
        style={{
          color: "#fff",
        }}
      >
        Free Months:
      </strong>
      <Select
        placeholder="Select free months (optional)"
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        // defaultValue={}
        value={inputVals?.freeMonthsArr || []}
        options={Array(inputVals?.months)
          .fill(inputVals.months, 0)
          ?.map((el, idx) => ({
            value: `Month ${idx + 1}`,
            label: `Month ${idx + 1}`,
          }))}
        onChange={(e) => {
          console.log("e57339", e);
          if (Array.isArray(e) && e.length > 0) {
            let temp = e?.sort(
              (a, b) => a?.replace("Month ", "") - b?.replace("Month ", "")
            );
            onInputChangeVal(temp, "freeMonthsArr");
          } else {
            setVals([]);
          }
        }}
        // onChange={handleChange}
        // options={options}
      /> */}
      <div className="agGridContainer">
        <div className="dark-ag-theme documentationsTable ag-theme-alpine-dark">
          <AgGridReact
            headerHeight={50}
            columnDefs={[
              {
                headerName: "Month",
                field: "month",
                flex: 1,
                valueGetter: (params) => {
                  return `Month ${params?.data?.month}`;
                },
              },
              {
                headerName: "Rent Type",
                field: "rentType",
                flex: 1,
                valueGetter: (params) => {
                  console.log("params", params);
                  return params?.data?.free ? "Free Month" : "Paid";
                },
              },
              {
                headerName: "Rent Price",
                field: "monthlyPrice",
                flex: 1,
                // cell style
                cellStyle: (params) => {
                  return params?.data?.free
                    ? {
                        backgroundColor: "darkgray",
                        color: "#fff",
                        display: "flex",
                        alignItems: "flex-end",
                      }
                    : {
                        backgroundColor: "#68c142",
                        display: "flex",
                        color: "#fff",
                        alignItems: "flex-end",
                      };
                },
                valueFormatter: (params) => formatCurrency(params?.value ?? 0),
              },
              {
                headerName: "Action",
                field: "id",
                flex: 1,
                cellRenderer: (params) => {
                  let isFree = params?.data?.free;
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <MondayButton
                        className={`mondayButton${isFree ? "Blue" : "Red"}`}
                        onClick={() => markAsFree(params?.data?.month)}
                        Icon={isFree ? <CheckIconModal /> : <CloseIconModal />}
                      >
                        Mark As {isFree ? "Chargable" : "Free"}
                      </MondayButton>
                    </div>
                  );
                },
              },
            ]}
            rowData={
              getRows()
              // Array(inputVals.months)
              // .fill(0)
              // ?.map((el) => {
              //   console.log("el", el);
              //   return {
              //     id: "1",
              //     month: "2",
              //     rentType: "3",
              //     rentPrice: "4",
              //   };
              // })
            }
            enableRangeSelection={true}
            suppressSizeToFit={true}
            width={"auto"}
            suppressDragLeaveHidesColumns={true}
            // width={"100%"}
            // onGridReady={(params) => {
            //   setScaffoldPlanksGridAPI(params.api);
            //   // params?.api?.setHeaderHeight(50);
            //   // setColumnApi(params?.columnApi);
            //   // const widths = {
            //   //   rowWidth: parseInt(
            //   //     document.getElementsByClassName(
            //   //       "ag-center-cols-container"
            //   //     )[0].offsetWidth
            //   //   ),
            //   //   parentWidth: parseInt(
            //   //     document.getElementsByClassName("ag-body-viewport")[0]
            //   //       .offsetWidth
            //   //   ),
            //   // };

            //   // if (widths.rowWidth > widths.parentWidth) {
            //   //   params.columnApi.autoSizeAllColumns(false);
            //   // } else if (widths.rowWidth < widths.parentWidth) {
            //   params.api.sizeColumnsToFit();
            //   // }
            // }}
            // onFirstDataRendered={(params) => {
            //   params.api.sizeColumnsToFit();
            // }}
            // autoSizeAllColumns={true}
            // rowHeight={40}
            // defaultColDef={{
            //   enablePivot: true,
            //   editable: true,
            //   resizable: true,
            // }}
            // onCellValueChanged={(params) => {
            //   const shouldIAddEmpty = dataSrc?.every(
            //     (el) => Object.keys(el).length > 11
            //   );
            //   if (shouldIAddEmpty) {
            //     setDataSrc((prev) => [...prev, {}]);
            //   } else setDataChanged((prev) => !prev);
            // }}
          />
          {/* <CustomFooter
            dataSrc={[
              {
                test: 1,
              },
              {
                test: 2,
              },
            ]}
            mathAction={"sum"}
            style={{
              backgroundColor: "#1264A3",
            }}
            labelsKeys={[
              {
                key: "test",
                name: "Total Sqft",
                type: "number",
                mathAction: "sum",
              },
            ]}
          /> */}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export const FooterAmountsRental = ({ inputVals = {}, totalities = {} }) => {
  return (
    <div className="hightlight-prices-container">
      <label>Total Rent Months</label>
      <AnimatedNumber
        style={{
          transition: "0.8s ease-out",
          fontSize: 30,
          fontWeight: 600,
          transitionProperty: "background-color, color, opacity",
        }}
        value={
          forceToNumber(inputVals?.months ?? 0) +
          forceToNumber(inputVals?.freeMonths ?? 0) +
          forceToNumber(inputVals?.defaultMonths ?? 0)
        }
        // formatValue={(value) => formatCurrency(value)}
      />{" "}
      <label>Total Rent Price</label>
      <AnimatedNumber
        style={{
          transition: "0.8s ease-out",
          fontSize: 30,
          fontWeight: 600,
          transitionProperty: "background-color, color, opacity",
        }}
        value={
          forceToNumber(totalities?.newPrice || 0) -
            forceToNumber(totalities?.taxAmount || 0) || 0
        }
        formatValue={(value) => formatCurrency(value)}
      />{" "}
      {totalities?.taxAmount > 0 && (
        <>
          <label>Tax Amount</label>
          <AnimatedNumber
            style={{
              transition: "0.8s ease-out",
              fontSize: 30,
              fontWeight: 600,
              transitionProperty: "background-color, color, opacity",
            }}
            value={totalities?.taxAmount || 0}
            formatValue={(value) => formatCurrency(value)}
          />
        </>
      )}
      <label>Total Price</label>
      <AnimatedNumber
        style={{
          transition: "0.8s ease-out",
          fontSize: 30,
          fontWeight: 600,
          transitionProperty: "background-color, color, opacity",
        }}
        value={totalities?.newPrice || 0}
        formatValue={(value) => formatCurrency(value)}
      />{" "}
      <br />
    </div>
  );
};
