import { forwardRef, useState } from "react"
import { Input } from "antd"

import useCellEditor from "../../../../../hooks/useCellEditor"
import "./amount-cell-editor.css"

const AmountCellEditor = forwardRef(({ value, charPress }, ref) => {
  const [amount, setAmount] = useState(charPress || value || 0)

  const inputRef = useCellEditor(ref, amount, !charPress && "input")

  return (
    <Input
      ref={inputRef}
      className="amount-cell-editor"
      placeholder="Enter amount"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
    />
  )
})

export default AmountCellEditor
