import { get_ProgressDimensionsByServiceId } from "../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import capitalizeFirstLetter, {
  getDimensionHeader,
} from "../../Estimations/DataEntryGrid/tools/polyfillers/capitalizeFirstLetter";
import { ArrayIncludesElementFromOtherArray } from "../../../../utils/ArrayIncludesElementFromOtherArray";

/**
 * PLI and its sub-items
 * PLI {addons, breakdownValue, subPli}
 * - addons behaves independently from PLI
 * - breakdownValue are breakdowns which have independent days and progressByDay but they will affect totalProgress
 * - subPli are created by a split of PLI in several parts and their sum will determine PLI days progressByDay and totalProgress
 * */

/**
 * Items can be an array of PLI, Addon (inside PLI or in service level), sub-PLI, breakdown or whatever includes "days" property.
 * */
export const filterSimpleItemsByDay = (items, { dayId, dayIdList, noDays }) => {
  if (dayId) return items?.filter((item) => item.days?.includes(dayId));
  else if (dayIdList)
    return items.filter((item) =>
      ArrayIncludesElementFromOtherArray(item?.days, dayIdList)
    );
  else if (noDays)
    return items?.filter(
      (item) => item?.days === undefined || item?.days?.length === 0
    );
  else return items?.filter((item) => item?.days?.length > 0); //if no day is determined, all items with some days in them pass
};

/**
 * Just like filterSimpleItemsByDay item can be whatever item type. But it will also check if its sub-items includes the mentioned day and if yes item is qualified.
 * Also cleanup all sub-items which does not include that day
 * @param {{days: Array<String>}} item
 * @param dayId {string} - the id of a ScheduleDay object
 * @param dayIdList {string[]} - the id list of a ScheduleDay objects
 * @param noDays {boolean} - if we want to select pli without days
 * @param subItems {string[]} - a list of possible sub-items that this item can include
 * */
export const filterItemByDay = (
  item,
  {
    dayId,
    dayIdList,
    noDays,
    subItems = ["addons", "subPli", "breakdownValue"],
  }
) => {
  let doesItemIncludeThisDay = item?.days?.includes(dayId);
  let aSubItemIncludeThisDay = false;

  for (const subItemKey of subItems || []) {
    if (item[subItemKey]) {
      item[subItemKey] = filterSimpleItemsByDay(item[subItemKey], {
        dayId,
        dayIdList,
        noDays,
      });
      if (item[subItemKey].length > 0) {
        aSubItemIncludeThisDay = true;
      }
    }
  }

  return doesItemIncludeThisDay || aSubItemIncludeThisDay;
};

//cut PLI which does not include this day
export const filterElevationsByDay = (
  elevations,
  { dayId, dayIdList, noDays }
) =>
  elevations?.map((elevation) => ({
    ...elevation,
    items: elevation?.items?.filter((pli) =>
      filterItemByDay(pli, { dayId, dayIdList, noDays })
    ),
  }));

export const filterPLIsInElevationAndServiceAddons = (
  toBeScheduled,
  { dayId, dayIdList, noDays }
) => {
  for (const estimationId of Object.keys(toBeScheduled)) {
    toBeScheduled[estimationId] = toBeScheduled[estimationId].map(
      (service) => ({
        ...service,
        serviceOptions: [
          filterElevationsByDay(service.serviceOptions[0], {
            dayId,
            dayIdList,
            noDays,
          }),
        ],
        serviceAddons: filterSimpleItemsByDay(service.serviceAddons, {
          dayId,
          dayIdList,
          noDays,
        }),
      })
    );
  }
};

export const filterElevationsServicesEstimations = (toBeScheduled) => {
  for (const estimationId of Object.keys(toBeScheduled)) {
    toBeScheduled[estimationId] = toBeScheduled[estimationId].map(
      (service) => ({
        ...service,
        serviceOptions: [
          service?.serviceOptions?.[0]?.filter(
            (elevation) => elevation?.items?.length > 0
          ),
        ],
      })
    );
    toBeScheduled[estimationId] = toBeScheduled?.[estimationId]?.filter(
      (service) =>
        service?.serviceOptions?.[0]?.length > 0 ||
        service?.serviceAddons?.length > 0
    );
  }

  let cleanedEstimations = {};

  for (const [estimationId, services] of Object.entries(toBeScheduled || {})) {
    if (services?.length > 0) cleanedEstimations[estimationId] = services;
  }

  return cleanedEstimations;
};

export const filterToBeScheduled = (
  toBeScheduled,
  { dayId, dayIdList, noDays }
) => {
  // console.log(toBeScheduled, dayId)
  filterPLIsInElevationAndServiceAddons(toBeScheduled, {
    dayId,
    dayIdList,
    noDays,
  });
  return filterElevationsServicesEstimations(toBeScheduled);
};

export const parseProgressInto_toBeScheduled = (toBeScheduled) => {
  actInPLIInToBeScheduled(toBeScheduled, parseProgressIntoItem);
};

export const parseProgressIntoItem = (item, { variablesNeeded }) => {
  const { d1, d2, d1Header, d2Header, d1Capitalised, d2Capitalised } =
    variablesNeeded;
  if (item?.subPli && item?.subPli?.length > 0) {
    for (const subPli of item?.subPli) {
      setProgressIntoSimpleItem(subPli, {
        d1,
        d2,
        d1Header,
        d2Header,
        d1Capitalised,
        d2Capitalised,
      });
    }

    item["progress" + d1Capitalised] = "-";
    if (d2 !== undefined) {
      item["progress" + d2Capitalised] = "-";
    }
  } else {
    setProgressIntoSimpleItem(item, {
      d1,
      d2,
      d1Header,
      d2Header,
      d1Capitalised,
      d2Capitalised,
    });
  }
};

export const setProgressIntoSimpleItem = (
  item,
  { d1, d2, d1Header, d2Header, d1Capitalised, d2Capitalised }
) => {
  item["progress" + d1Capitalised] = item?.["progress" + d1Capitalised] || 0;
  if (d2 !== undefined) {
    item["progress" + d2Capitalised] = item?.["progress" + d2Capitalised] || 0;
    item["progress"] = item?.["progress"] || 0;
  }
  item["totalProgress"] = item?.["totalProgress"] || 0;
};

export const actInPLIInToBeScheduled = (
  toBeScheduled,
  actionFunction,
  { variablesNeeded } = {}
) => {
  const servicesLists = Object.values(toBeScheduled);

  for (const services of servicesLists) {
    for (const service of services) {
      const { d1, d2 } = get_ProgressDimensionsByServiceId(service?.serviceId);
      const d1Header = getDimensionHeader(d1),
        d2Header = getDimensionHeader(d2);
      const d1Capitalised = capitalizeFirstLetter(d1),
        d2Capitalised = capitalizeFirstLetter(d2);
      variablesNeeded = {
        ...variablesNeeded,
        d1,
        d2,
        d1Header,
        d2Header,
        d1Capitalised,
        d2Capitalised,
      };
      for (const elevation of service?.serviceOptions?.[0] || []) {
        //clear elevations
        for (const pli of elevation?.items || []) {
          actionFunction(pli, { variablesNeeded });

          if (pli?.addons && pli?.addons?.length > 0) {
            for (const addon of pli?.addons) {
              actionFunction(addon, { variablesNeeded });
            }
          }
        }
      }

      if (service?.serviceAddons)
        for (const addon of service?.serviceAddons || []) {
          //clear addons
          actionFunction(addon, { variablesNeeded });
        }
    }
  }
};

export const filterSchedulesByProject = (schedules, typeOfWork) =>
  schedules?.filter((s) => s?.typeOfWork === typeOfWork) || [];

export const schNamesSchedules = (schedules, projectId) =>
  schedules?.filter((s) => s?.projectId === projectId) || [];

export const filterItemsByJump = (items, jump) =>
  items?.filter((item) =>
    jump?.floors?.map((f) => f?.floor).includes(item?.floor)
  );
