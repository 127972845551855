import { useSelector } from "react-redux";
import useExportHeadersModal from "../../../../../../hooks/useExportHeadersModal";
import {
  ExportOrEmailDropdown,
  MondayButton,
} from "../../../../../commonComponents";
import { InfoText } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import PdfHeadersModal from "../../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { FilterIcon } from "../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { sessionExportToExcel } from "../../SessionLogsDrawer/helpers/sessionLogsExcel";
import {
  sessionLogsPDF,
  sessionsTableHeaders,
} from "../../SessionLogsDrawer/helpers/sessionLogsPDF";
import SessionLogFilters from "../../SessionLogsDrawer/components/SessionLogFilters/SessionLogFilters";
import { useMemo, useState } from "react";
import sessionFiltersFields from "../../SessionLogsDrawer/components/SessionLogFilters/fieldsJSON";
import getAllRolesOptions from "../../helpers/getAllRolesOptions";

const flexStyle = {
  display: "flex",
  gap: 20,
};

function SessionLogsModalActions({
  isDarkMode,
  clearVisible,
  onFilterApply,
  filteredRowData,
  allUsersObj,
  filters,
  allLogs,
  sessionId,
  title = "",
}) {
  const { base64 } = useSelector((state) => state.base64);
  const { programFields } = useSelector((state) => state.programFields);
  const { userGroups } = useSelector((state) => state.userGroups);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [openFilters, setOpenFilters] = useState(false);
  const [tmpFilters, setTmpFilters] = useState(filters);

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData: false,
      onGeneratePDF: (action, getDocDefinition, headersData) =>
        sessionLogsPDF(
          action,
          getDocDefinition,
          headersData,
          filteredRowData,
          base64,
          allUsersObj
        ),
      exportToExcel: (getDocDef, headersData) =>
        sessionExportToExcel(
          getDocDef,
          headersData,
          filteredRowData,
          allUsersObj,
          (type, getDocDefinition) =>
            sessionLogsPDF(
              type,
              getDocDefinition,
              headersData,
              filteredRowData,
              base64
            )
        ),
    });

  const rolesOptions = useMemo(() => {
    return getAllRolesOptions({
      userGroups,
      departments: tmpFilters?.departments,
    });
  }, [tmpFilters]);

  const logsCustomOptions = useMemo(() => {
    let browserOptions = [];
    let osOptions = [];
    allLogs?.map((log) => {
      const { browserName, osName } = log?.sessionInfo || {};
      if (
        browserName &&
        !browserOptions.find((option) => option.value === browserName)
      ) {
        browserOptions.push({ label: browserName, value: browserName });
      }
      if (osName && !osOptions.find((option) => option.value === osName)) {
        osOptions.push({ label: osName, value: osName });
      }
    });
    return { browserOptions, osOptions };
  }, [allLogs]);

  const departmentOptions = useMemo(
    () =>
      programFields
        ?.find(({ fieldName }) => fieldName === "Departments")
        ?.fieldOptions.map(({ departmentName = "" }) => ({
          label: departmentName,
          value: departmentName,
        })),
    [programFields]
  );

  const filterFields = sessionFiltersFields({
    users: userConfiguration?.allUsers.Items,
    osOptions: logsCustomOptions.osOptions,
    browserOptions: logsCustomOptions.browserOptions,
    rolesOptions,
    departmentOptions,
    setFilters: setTmpFilters,
    filters: tmpFilters,
    sessionId,
  });

  return (
    <div
      style={{
        ...flexStyle,
        justifyContent: "space-between",
      }}
    >
      <InfoText
        {...{
          text: !sessionId ? (
            "Review the activity of users across various departments, including their online statuses and more."
          ) : (
            <span>
              Here you can see the exact times <b>{title}</b> was online,
              offline or idle.
            </span>
          ),
          isDarkMode,
        }}
      />
      <div style={{ ...flexStyle }}>
        <ExportOrEmailDropdown
          {...{
            onGeneratePDF: (action, getDocDefinition) => {
              getDocDefinition
                ? sessionLogsPDF(
                    action,
                    getDocDefinition,
                    sessionsTableHeaders,
                    filteredRowData,
                    base64,
                    allUsersObj
                  )
                : getHeadersModal(action, getDocDefinition);
            },
            exportGridToExcel: (getDocDefinition) => {
              getDocDefinition
                ? sessionExportToExcel(
                    false,
                    // additionalData,
                    getDocDefinition,
                    filteredRowData,
                    allUsersObj,
                    (type, getDocDefinition) =>
                      sessionLogsPDF(
                        type,
                        getDocDefinition,
                        sessionsTableHeaders,
                        filteredRowData,
                        base64
                      )
                  )
                : getHeadersModal(false, getDocDefinition, "excel");
            },
            // uploadExcelToDrive,
            rowData: filteredRowData,
            defaultDocDefinition: true,
            title: "User Sessions Logs",
          }}
        />
        <MondayButton
          {...{
            className: "mondayButtonBlue",
            onClick: () => setOpenFilters(true),
            Icon: <FilterIcon />,
          }}
        >
          Filters
        </MondayButton>
        {clearVisible && (
          <MondayButton
            {...{
              className: "mondayButtonRed",
              onClick: () => onFilterApply({}),
              Icon: <XIcon />,
            }}
          />
        )}
      </div>
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: sessionId
              ? ["Name Of User", "Status", "Date Time", "IP", "Device"]
              : sessionsTableHeaders.slice(0, -1),
            title: "User Sessions Logs",
          }}
        />
      )}
      {openFilters && (
        <SessionLogFilters
          {...{
            visible: openFilters,
            clearFilters: () => {
              setTmpFilters({});
              onFilterApply({});
              setOpenFilters(false);
            },
            onFilterApply: () => {
              onFilterApply(tmpFilters);
              setOpenFilters(false);
            },
            onCancel: () => {
              setTmpFilters(filters);
              setOpenFilters(false);
            },
            filterFields,
            title: "Session Logs Filters",
          }}
        />
      )}
    </div>
  );
}

export default SessionLogsModalActions;
