import { useState, useEffect, useContext, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import {
  MarkerF,
  CircleF,
  Polygon,
  GoogleMap,
  InfoWindowF,
  OverlayViewF,
  DrawingManagerF,
} from "@react-google-maps/api";
import axios from "axios";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Modal, message, Form, Popover } from "antd";

import {
  getFence,
  linxupEndpoints,
  FleetsLiveContext,
  loadLivePreference,
  saveLocalPreferences,
  findGeofenceCenterCoordinate,
} from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { getLatLngByAddress } from "src/components/SidebarPages/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { MAP_THEME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { pointsInsideAPolygon } from "../../../../../Map/utilities/pointsInsideAPolygon";
import { getRandomColor } from "src/components/Header/forms/Scheduling/helpers/creators";
import { getWholeAddressFromComponents } from "src/components/SidebarPages/Fleet/utils/addressParseHelpers";
import { GOOGLE_API_KEY } from "src/components/SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";
import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import {
  StopInfo,
  footerButtons,
  NearestFences,
  FenceSaveForm,
  StopController,
} from "./components";
import {
  Marker,
  MondayButton,
  WarningModal,
} from "src/components/commonComponents";
import { FleetWhite } from "src/icons";
import { XIcon } from "../../../../../../../../Communication/assets";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./StopModal.scss";

function StopModal({
  open,
  onCancel,
  stopData,
  onSubmit = () => {},
  onDiscard = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { mapLoaded, geofences, setGeofences, projects, updateProjectData } =
    useContext(FleetsLiveContext);

  const [mode, setMode] = useState("LINK_STOP");
  const [mapInstance, setMapInstance] = useState();
  const [selectedFence, setSelectedFence] = useState();
  const [tmpNewFences, setTmpNewFences] = useState([]);
  const [drawingInstance, setDrawingInstance] = useState();
  const [warningVisible, setWarningVisible] = useState("");
  const [fencesInRadius, setFencesInRadius] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [projectsPolygons, setProjectsPolygons] = useState([]);
  const [fenceFilterValue, setFenceFilterValue] = useState("");
  const [saveFencePopover, setSaveFencePopover] = useState(false);
  const [unit, setUnit] = useState(loadLivePreference("stopRadiusSearchUnit"));
  const [circle, setCircle] = useState(
    /** @type {google.maps.Circle} */ (null)
  );
  const [dataVisible, setDataVisible] = useState(
    loadLivePreference("stopModalInfo") === "expanded"
  );
  const [searchRadius, setSearchRadius] = useState(
    loadLivePreference("stopRadiusSearchValue")
  );

  const [form] = Form.useForm();
  const [fenceForm] = Form.useForm();
  const colorRef = useRef(getRandomColor());

  useEffect(() => {
    if (open) {
      let tmp = [];
      let radInFeet = searchRadius;
      if (unit === "mile") {
        radInFeet = searchRadius * 5280;
      }

      for (const fenceName in geofences) {
        if (geofences[fenceName]?.geofenceUUID !== stopData?.geofenceUUID) {
          const points = geofences[fenceName]?.points || [];
          if (!points?.length) {
            continue;
          }

          const pointInRange = points
            .concat(findGeofenceCenterCoordinate(points))
            .find((point) => {
              return withinRadius(
                { lat: point.latitude, lng: point.longitude },
                stopData?.position,
                radInFeet
              ).withinRange;
            });

          if (pointInRange) {
            let range = withinRadius(
              findGeofenceCenterCoordinate(geofences[fenceName]["points"]),
              stopData.position,
              radInFeet
            );

            tmp.push({
              ...geofences[fenceName],
              distanceInFeet: range?.distanceInFeet,
              distanceInMile: range?.distanceInMile,
            });
          }
        } else {
          tmp.push({
            ...geofences[fenceName],
            distanceInFeet: 0,
            distanceInMile: 0,
          });
        }
      }

      if (!tmp.length) {
        message.warning({
          content: "No geofence was found in the search",
          key: "noFence",
        });
      }

      tmp.sort((a, b) => a?.distanceInFeet - b?.distanceInFeet);
      setFencesInRadius(tmp);
    }
  }, [open, searchRadius, unit, geofences]);

  function onLoad(map) {
    map.panTo(stopData?.position);
    setMapInstance(map);
  }

  function handleTextFilter(e) {
    let val = e.target.value;
    if (!val) {
      setFenceFilterValue("");
      return;
    }

    setFenceFilterValue(val);
  }

  function updateSelectedFence(fenceInfo) {
    if (mapInstance) {
      mapInstance.panTo(fenceInfo?.position);
    }
    colorRef.current = getRandomColor();
    setSelectedFence(fenceInfo);
  }

  async function getAddress({ lat, lng }) {
    return await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
    )
      .then(async (r) => await r.json())
      .then(({ results }) => {
        if (!results?.length) {
          throw new Error("No results found");
        }

        let filteredResults = results?.filter(
          ({ types }) => !types.includes("plus_code")
        );

        if (!filteredResults?.length) {
          throw new Error("No results found");
        }

        return getWholeAddressFromComponents(
          filteredResults[0]?.["address_components"]
        );
      });
  }

  function calcRadius() {
    if (circle) {
      let v = +searchRadius;
      if (unit === "mile") {
        v *= 1609.34;
      } else {
        v *= 0.304;
      }

      return v;
    }
    return 0;
  }

  async function popoverConfirmHandler() {
    await form
      .validateFields()
      .then((values) => {
        setPopoverVisible(false);

        if (values?.unit) {
          saveLocalPreferences({ stopRadiusSearchUnit: values?.unit });
          setUnit(values?.unit);
        }

        if (values?.searchRadius) {
          saveLocalPreferences({ stopRadiusSearchValue: values?.searchRadius });
          setSearchRadius(Number(values?.searchRadius));
        }
      })
      .catch((err) => {
        console.log("Err validating fields: ", err);
      });
  }

  function fenceCancelHandler(resetFences = true) {
    if (drawingInstance) {
      drawingInstance.setMap(null);
    }

    setProjectsPolygons([]);

    if (resetFences) {
      for (const polygon of tmpNewFences) {
        const { instance } = polygon;
        if (instance) {
          instance.setMap(null);
        }
      }

      setTmpNewFences([]);
      setSaveFencePopover(false);
    }

    setMode("LINK_STOP");
  }

  async function saveFenceHandler() {
    try {
      const { name, project, description } = await fenceForm.validateFields();
      if (geofences?.[getFence(name)]) {
        message.error({
          content: "A geofence with this name already exists!",
          key: "geofenceCreate",
        });
        return;
      }

      let relatedProject = undefined;
      if (project) {
        const projectIndex = projects.findIndex(
          ({ projectId }) => projectId === project
        );
        if (projectIndex > -1) {
          relatedProject = {
            ...projects[projectIndex],
          };
        }
      }

      message.loading({
        content: "Saving geofence...",
        key: "geofenceCreate",
        duration: 0,
      });

      let geofenceInfo = {
        points: tmpNewFences?.[0]?.geofenceInfo?.geoFenceInfo?.map(
          ({ lat, lng }) => ({
            latitude: lat,
            longitude: lng,
          })
        ),
        name,
      };

      const newFence = await axios.post(linxupEndpoints.newGeofence, {
        geofenceInfo,
      });

      if (relatedProject) {
        message.loading({
          content: "Updating project...",
          key: "projectUpdate",
          duration: 0,
        });

        const newBody = {
          geoFenceInfo: [
            ...(relatedProject?.["geoFenceInfo"] || []),
            {
              ...(tmpNewFences?.[0]?.geofenceInfo || {}),
              description,
              title: `${name} - ${relatedProject?.accountName}`,
            },
          ],
        };

        await API.put("projects", `/projects/${relatedProject.projectId}`, {
          body: newBody,
        })
          .then(() => {
            message.destroy("projectUpdate");
            updateProjectData({ ...relatedProject, ...newBody });
          })
          .catch((err) => {
            message.error({
              content:
                "Something went wrong while trying to update the project!",
              key: "projectUpdate",
            });
            console.log("Error updating project: ", err);
          });
      }

      message.success({
        content: "Geofence created successfully!",
        key: "geofenceCreate",
      });

      updateSelectedFence({
        ...newFence.data,
        points: tmpNewFences?.[0]?.geofenceInfo?.geoFenceInfo,
        position: findGeofenceCenterCoordinate(
          tmpNewFences?.[0]?.geofenceInfo?.geoFenceInfo
        ),
      });
      fenceCancelHandler();
      setGeofences((prev) => ({
        ...prev,
        [getFence(name)]: newFence.data,
      }));
    } catch (err) {
      message.error({
        content: "Something went wrong while creating geofence",
        key: "geofenceCreate",
      });
      console.log("ERROR: ", err);
    }
  }

  function onPolygonComplete(polygonInstance) {
    const polyArray = polygonInstance.getPath().getArray();
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });

    const newGeofence = {
      shapeId: uuidv4(),
      type: "Polygon",
      title: "",
      description: "",
      createdAt: Date.now(),
      createdBy: userConfiguration.nameOfUser,
      geoFenceInfo: paths,
    };

    setTmpNewFences(() => [
      {
        instance: polygonInstance,
        geofenceInfo: newGeofence,
      },
    ]);

    fenceCancelHandler(false);
  }

  /**
   * Function that fills the info window form
   */
  async function openSavePopover() {
    if (selectedFence && !selectedFence?.points) {
      //this is the case is which we have a marker (not a fence)
      if (
        pointsInsideAPolygon(
          [selectedFence?.position?.lat, selectedFence?.position?.lng],
          tmpNewFences?.[0]?.geofenceInfo?.geoFenceInfo?.map(({ lat, lng }) => [
            lat,
            lng,
          ])
        )
      ) {
        //if the marker is inside the new created fence, we give it the marker address
        fenceForm.setFieldsValue({
          name: selectedFence?.name,
          project: "",
          description: "",
        });
        setSaveFencePopover(true);
        return;
      }
    }

    let centerPoint = findGeofenceCenterCoordinate(
      tmpNewFences?.[0]?.geofenceInfo?.geoFenceInfo
    );
    let name = "";
    try {
      message.loading({
        content: "Approximating address...",
        key: "addressApproximation",
      });

      name = await getAddress(centerPoint);
      message.destroy("addressApproximation");

      fenceForm.setFieldsValue({ name, project: "", description: "" });
    } catch (err) {
      console.log("Error geocoding: ", err);
    }

    setSaveFencePopover(true);
  }

  async function onProjectSelect(projectId) {
    if (!projectId) {
      setProjectsPolygons([]);
      return;
    }

    if (!mapInstance) {
      return;
    }

    const index = projects?.findIndex(({ projectId: id }) => id === projectId);
    if (index === -1) {
      return;
    }

    const relatedProject = projects[index];

    if (!relatedProject?.geoFenceInfo?.length) {
      message.warning({
        content: "There are no geofences set for the selected project",
        key: "projectNoGeofence",
      });

      let position = undefined;

      if (!relatedProject?.projectLatitude) {
        try {
          position = await getLatLngByAddress(relatedProject?.projectName);
        } catch (err) {
          console.log("Error getting latLng: ", err);
          return;
        }
      } else {
        position = {
          lat: relatedProject?.projectLatitude,
          lng: relatedProject?.projectLongitude,
        };
      }

      setProjectsPolygons([
        { title: relatedProject.projectName, paths: [], position },
      ]);

      const bounds = new google.maps.LatLngBounds();
      bounds.extend(position);
      bounds.extend(stopData?.position);
      mapInstance.fitBounds(bounds);
    } else {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(stopData?.position);

      const polygons = [];

      for (const fence of relatedProject?.geoFenceInfo || []) {
        const { title, geoFenceInfo = [] } = fence;
        if (!geoFenceInfo?.length) {
          continue;
        }

        if (geoFenceInfo?.length === 1) {
          /**
           * if we only have one point, it means that we have a
           * circle, since a polygon needs a minimum of 3 points
           */
          let position = {
            lat: geoFenceInfo[0]["lat"],
            lng: geoFenceInfo[0]["lng"],
          };

          polygons.push({
            position,
            title: title || relatedProject?.["projectName"],
            radius: geoFenceInfo[0]["circleRadius"],
            paths: [],
          });

          bounds.extend(position);
        } else {
          let position = findGeofenceCenterCoordinate(geoFenceInfo);
          polygons.push({
            title: title || relatedProject?.["projectName"],
            paths: geoFenceInfo.map(({ lat, lng }) => ({ lat, lng })),
            position,
          });

          for (const latLng of geoFenceInfo) {
            bounds.extend({ lat: latLng.lat, lng: latLng.lng });
          }
        }
      }

      setProjectsPolygons(polygons);
      mapInstance.fitBounds(bounds);
    }
  }

  const cancelCondition = mode === "CREATE_GEOFENCE" || tmpNewFences?.length;

  return (
    <Fragment>
      <Modal
        {...{
          open,
          onCancel() {
            setWarningVisible("CANCEL");
          },
          closeIcon: <XIcon />,
          className: `stop-modal-container${
            isDarkMode ? " stop-modal-dark" : ""
          }`,
          footer: footerButtons({
            onCancel() {
              setWarningVisible("CANCEL");
            },
            onDiscard() {
              setWarningVisible("DISCARD");
            },
            onSubmit() {
              setWarningVisible("SUBMIT");
            },
            selectedFence: selectedFence?.points ? selectedFence : undefined,
          }),
          centered: true,
          destroyOnClose: true,
          maskClosable: false,
          title: `Stop${
            stopData?.fleetName ? ` for ${stopData?.fleetName}` : ""
          }${
            stopData?.beginDate
              ? ` on ${dayjsNY(stopData?.beginDate).format("MMM DD, YYYY")}`
              : ""
          }`,
        }}
      >
        <StopInfo {...{ dataVisible, stopData }} />
        <NearestFences
          {...{
            unit,
            form,
            isDarkMode,
            dataVisible,
            searchRadius,
            fencesInRadius,
            setDataVisible,
            popoverVisible,
            fenceFilterValue,
            handleTextFilter,
            setPopoverVisible,
            updateSelectedFence,
            popoverConfirmHandler,
          }}
        />
        <div className="stop-map-section">
          <Popover
            content={
              <FenceSaveForm
                {...{
                  projects,
                  fenceForm,
                  isDarkMode,
                  onProjectSelect,
                  saveFenceHandler,
                  fenceCancelHandler,
                  position: stopData?.position,
                }}
              />
            }
            open={saveFencePopover}
            placement="bottomRight"
            overlayClassName={`popover-stop-save-fence stop-modal-popover-content ${
              isDarkMode ? " popover-dark" : ""
            }`}
          >
            <StopController
              {...{
                setMode,
                projects,
                geofences,
                isDarkMode,
                mapInstance,
                tmpNewFences,
                onProjectSelect,
                drawingInstance,
                cancelCondition,
                openSavePopover,
                saveFencePopover,
                fenceCancelHandler,
                updateSelectedFence,
              }}
            />
          </Popover>
          {mapLoaded && (
            <GoogleMap
              {...{
                zoom: 16,
                mapContainerClassName: "stop-map",
                id: "stop-map",
                onLoad,
                options: {
                  disableDefaultUI: true,
                  styles: MAP_THEME[loadLivePreference("mapStyle")],
                  mapTypeId: loadLivePreference("mapType"),
                  fullscreenControl: true,
                  clickableIcons: false,
                  rotateControl: true,
                  fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                  streetViewControl: true,
                  streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                },
              }}
            >
              <OverlayViewF
                {...{
                  position: stopData?.position,
                  mapPaneName: "overlayMouseTarget",
                }}
              >
                <Fragment>
                  {stopData?.address &&
                    !cancelCondition &&
                    !saveFencePopover && (
                      <InfoWindowF position={stopData?.position}>
                        <b>{stopData?.address}</b>
                      </InfoWindowF>
                    )}
                  <FleetWhite
                    fill={
                      (stopData?.stopType || "")
                        ?.toUpperCase()
                        ?.includes("IDLING")
                        ? "#f5a623"
                        : "#fe4c4a"
                    }
                    style={{
                      position: "absolute",
                      top: "-13px",
                      left: "-15px",
                    }}
                  />
                </Fragment>
              </OverlayViewF>
              {selectedFence?.name && !cancelCondition && !saveFencePopover && (
                <InfoWindowF position={selectedFence?.position}>
                  <b>{selectedFence?.name}</b>
                </InfoWindowF>
              )}
              {projectsPolygons.map(
                ({ title, paths, position, radius }, index) => {
                  return (
                    <InfoWindowF
                      position={position}
                      options={{ content: title }}
                      key={`shape-${index}`}
                    >
                      {paths?.length ? (
                        <Polygon
                          {...{
                            options: {
                              paths,
                              editable: false,
                              draggable: false,
                              strokeColor: "#f6cb51",
                              strokeOpacity: 1,
                              strokeWeight: 2,
                              fillColor: "#f6cb51",
                              fillOpacity: 0.35,
                            },
                          }}
                        />
                      ) : radius ? (
                        <CircleF
                          {...{
                            center: position,
                            radius: radius,
                            options: {
                              fillColor: "#f6cb51",
                              fillOpacity: 0.3,
                              strokeWeight: 2,
                              strokeColor: "#f6cb51",
                              clickable: false,
                            },
                          }}
                        />
                      ) : (
                        <MarkerF
                          position={position}
                          options={{ clickable: false, draggable: false }}
                        />
                      )}
                    </InfoWindowF>
                  );
                }
              )}
              <Polygon
                {...{
                  options: {
                    editable: false,
                    draggable: false,
                    strokeColor: "#ff4d4f",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: "#ff4d4f",
                    fillOpacity: 0.35,
                    map: mapInstance,
                  },
                  visible:
                    Boolean(selectedFence?.points) &&
                    !cancelCondition &&
                    !saveFencePopover,
                  paths: selectedFence?.points || [],
                }}
              />
              <CircleF
                {...{
                  onLoad(e) {
                    setCircle(e);
                  },
                  visible: !cancelCondition && !saveFencePopover,
                  center: stopData?.position,
                  radius: calcRadius(),
                  options: {
                    fillColor: "#F6CB51",
                    fillOpacity: 0.3,
                    strokeWeight: 2,
                    strokeColor: "#F6CB51",
                    clickable: false,
                  },
                }}
              />
              {selectedFence?.name && !selectedFence?.points && (
                <OverlayViewF
                  position={selectedFence?.position}
                  mapPaneName="overlayMouseTarget"
                >
                  <Marker
                    {...{
                      address: selectedFence?.name,
                      onClick() {},
                      color: colorRef.current,
                      lat: selectedFence?.position?.lat,
                      lng: selectedFence?.position?.lng,
                    }}
                  />
                </OverlayViewF>
              )}
              <DrawingManagerF
                {...{
                  options: {
                    polygonOptions: {
                      fillColor: "#52c41a",
                      strokeColor: "#52c41a",
                      fillOpacity: 0.4,
                      strokeWeight: 2,
                    },
                    drawingControlOptions: {
                      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                    },
                    drawingMode:
                      mode === "CREATE_GEOFENCE"
                        ? google.maps.drawing.OverlayType.POLYGON
                        : null,
                  },
                  onLoad(instance) {
                    setDrawingInstance(instance);
                    instance.setMap(null);
                  },
                  onPolygonComplete,
                }}
              />
            </GoogleMap>
          )}
        </div>
      </Modal>
      {warningVisible && (
        <WarningModal
          visible={warningVisible}
          setVisible={setWarningVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              {warningVisible === "CANCEL"
                ? "Are you sure you want to cancel?"
                : warningVisible === "SUBMIT"
                ? `Are you sure you want to link this stop with ${selectedFence?.name}?`
                : "Are you sure you want to discard this stop?"}
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setWarningVisible("")}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (warningVisible === "CANCEL") {
                    onCancel();
                  } else if (warningVisible === "SUBMIT") {
                    onSubmit(selectedFence);
                  } else {
                    onDiscard(stopData);
                  }

                  setWarningVisible("");
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
}

export default StopModal;
