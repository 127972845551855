import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, Radio, Form, message } from "antd";
import dayjs from "dayjs";
import { LineChartOutlined, TableOutlined } from "@ant-design/icons";
import { Chart as ChartJS } from "chart.js";
import { utils as xl, writeFile } from "xlsx";

import {
  FleetsLiveContext,
  loadLivePreference,
} from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import {
  PRINT_PAGE_CONFIGS,
  BASE_REPORT_STYLES,
} from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { generateHTMLTemplate } from "../../../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { getRandomColor } from "src/components/Header/forms/Scheduling/helpers/creators";
import { hexToRgba, camelToTitle } from "src/utils";
import { formatNumber } from "src/components/SidebarPages/utils";
import { compareIncluding } from "src/components/SidebarPages/utils";
import { ReportsModalFooter } from "..";
import ChooseTable from "../ChooseTable/ChooseTable";
import { MondayButton } from "src/components/commonComponents";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets";

import "./ReportsPdfExport.scss";

const configOptions = {
  TABLE: ChooseTable,
  CHART: ChooseTable,
  BLANK: () => null,
};

function ReportsPdfExport({ open, onCancel, onlyAllowTable = false }) {
  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { analytics, totals, locations } = useContext(FleetsLiveContext);
  const [reportsDataSections, setReportsDataSections] = useState([]);
  const [sizeConfig, setSizeConfig] = useState({});
  const [configurationOptions, setConfigurationOptions] = useState(
    onlyAllowTable ? "TABLE" : "BLANK"
  );

  /**
   * @type {React.MutableRefObject<HTMLIFrameElement>}
   */
  const previewRef = useRef(null);
  const [form] = Form.useForm();

  const baseConfig = useMemo(() => {
    return [
      {
        image: {
          src: base64?.find(({ fileName }) =>
            compareIncluding(fileName, "Core Logo Black")
          )?.base64,
          class: "baseLogo",
        },
        class: "headerSection",
      },
    ];
  }, [base64]);

  const reportStyles = useMemo(() => {
    return {
      ...BASE_REPORT_STYLES,
      table: {
        width: "100%",
        "font-size": `calc(95% * ${sizeConfig?.aspectRatio})`,
      },
    };
  }, [sizeConfig]);

  function changeSizeHandler(changeEvent) {
    let key = changeEvent?.target?.value;
    let config = {};
    for (const size in PRINT_PAGE_CONFIGS) {
      if (size === key) {
        config = { ...PRINT_PAGE_CONFIGS[size] };
      }
    }

    setSizeConfig(config);
  }

  function onConfirm() {
    if (previewRef.current) {
      let frame = previewRef.current;
      let w = frame.contentWindow;
      if (w) {
        w.print();
      }
    }
  }

  /**
   * Function that draws a collection of charts and adds them to the report
   */
  function addChartHandler(add, data) {
    let images = [];
    let objectsToAdd = [];
    let newChartLabels = [...data];
    let allDates = Object.keys(totals || {}).sort(
      (a, b) =>
        dayjs(a, "MM/DD/YYYY").valueOf() - dayjs(b, "MM/DD/YYYY").valueOf()
    );

    for (const label of newChartLabels) {
      let labels = [];
      let data = [];
      let backgroundColor = [];
      let borderColor = [];

      for (const date of allDates) {
        let step = date.replace(/\/\d{4,}/g, "");
        labels.push(step);
        data.push(totals[date][label]);
        let color = getRandomColor();
        backgroundColor.push(hexToRgba(color, 0.4));
        borderColor.push(color);
      }

      let title = camelToTitle(label);
      if (title?.includes("Duration")) {
        title = title + " (in hours)";
        data = data.map((e) => e / 3600000);
      } else if (title?.includes("Distance")) {
        title = title + " (in miles)";
      }

      objectsToAdd.push({
        labels,
        datasets: [
          {
            label: title,
            data,
            backgroundColor,
            borderColor,
            borderWidth: 2,
          },
        ],
      });
    }

    for (const data of objectsToAdd) {
      let container = document.createElement("div");
      let chartCanvas = document.createElement("canvas");
      container.appendChild(chartCanvas);

      container.style.position = "absolute";
      container.style.top = `-${sizeConfig?.width}`;
      container.style.left = `-${sizeConfig?.width}`;
      container.style.height = `${+sizeConfig?.width.replace("mm", "") / 4}mm`;
      container.style.width = sizeConfig?.width;
      container.style.visibility = "hidden";
      document.body.appendChild(container);

      let chart = new ChartJS(chartCanvas, {
        type: "line",
        data,
        options: {
          scales: {
            yAxis: {
              beginAtZero: true,
            },
          },
          aspectRatio: 4,
          animation: {
            duration: 0,
          },
        },
      });

      let image = chart.toBase64Image();
      images.push({
        image: {
          src: image,
          class: "chart",
        },
        class: `chart ${data?.datasets?.[0]?.label}`,
      });

      chart.destroy();
      document.body.removeChild(container);
    }

    setConfigurationOptions("BLANK");
    setReportsDataSections((prev) => {
      let t = [...prev];
      t = t.concat(images);
      return t;
    });

    setTimeout(() => {
      for (let i = 0; i < images?.length; i++) {
        add();
      }
    }, 0);
  }

  /**
   * Function that draws a new table and adds it to the report
   */
  function addTableHandler(add, data) {
    let table = {};

    let cols = ["Vehicle"];
    let rows = [];

    for (const label of data) {
      let title = camelToTitle(label);
      cols.push(title);
    }

    for (const location of locations) {
      let totalForVehicle = analytics?.[location?.["deviceSerialNumber"]];
      if (!totalForVehicle) {
        continue;
      }

      let vehicleRow = [
        {
          text: location.fleetName,
          bold: true,
          link: `${window.location.origin}/fleets/${location?.fleetId}`,
        },
      ];

      for (const label of data) {
        let totalForLabel = 0;
        for (const date in totalForVehicle) {
          totalForLabel = totalForLabel + totalForVehicle[date][label];
        }
        if (label.includes("Duration")) {
          vehicleRow.push(
            formatNumber(totalForLabel / 3600000, { unit: "hour" })
          );
        } else if (label.includes("Distance")) {
          vehicleRow.push(formatNumber(totalForLabel, { unit: "mile" }));
        } else {
          vehicleRow.push(formatNumber(totalForLabel));
        }
      }

      rows.push(vehicleRow);
    }

    rows.sort((a, b) =>
      a?.[0]?.["text"].localeCompare(b?.[0]?.["text"], undefined, {
        sensitivity: "accent",
      })
    );

    table = {
      table: {
        class: `table`,
        cols,
        rows,
      },
    };

    setConfigurationOptions("BLANK");
    setReportsDataSections((prev) => {
      let t = [...prev];
      t = t.concat(table);
      return t;
    });

    setTimeout(() => {
      add();
    }, 0);
  }

  function removeElementHandler(remove, index) {
    setReportsDataSections((prev) => {
      let t = [...prev];
      t.splice(index + 1, 1);
      return t;
    });

    setTimeout(() => {
      remove(index);
    }, 0);
  }

  function getRenderCondition() {
    if (onlyAllowTable) {
      return reportsDataSections?.length < 2;
    }

    return true;
  }

  function onExport() {
    let timePeriod = loadLivePreference("liveReportsTimeline").map((e) =>
      dayjsNY(e).format("MM/DD/YY")
    );

    let table = reportsDataSections?.[1]?.table;
    if (!table) {
      message.warning({
        content: "Please generate a table before exporting!",
        key: "tableGenerate",
      });
      return;
    }

    let worksheetData = [[...table?.cols]];
    for (const row of table?.rows) {
      let [name] = row;
      let n = name.text;
      worksheetData.push([n, ...row.slice(1)]);
    }

    let worksheet = xl.json_to_sheet(worksheetData);
    let workbook = xl.book_new();
    xl.book_append_sheet(workbook, worksheet, "report");
    writeFile(workbook, `Live Report: ${timePeriod[0]}-${timePeriod[1]}.xlsx`, {
      compression: true,
    });
  }

  useEffect(() => {
    setReportsDataSections([...baseConfig]);
    changeSizeHandler({ target: { value: "Letter" } });
  }, []);

  useEffect(() => {
    if (previewRef.current) {
      let frame = previewRef.current;
      let w = frame.contentWindow;
      if (w) {
        let html = w.document.querySelector("html");
        if (html) {
          html.innerHTML = generateHTMLTemplate({
            title: "Report",
            dataSections: reportsDataSections,
            styles: reportStyles,
          });
        }
      }
    }
  }, [reportsDataSections, reportStyles]);

  const ConfigOption = configOptions[configurationOptions];

  return (
    <Modal
      {...{
        open,
        onCancel,
        className: `reports-pdf-export-container${
          isDarkMode ? " pdf-export-container-dark" : ""
        }`,
        closeIcon: <XIcon />,
        centered: true,
        title: `Report ${onlyAllowTable ? "Excel" : "PDF"} Export`,
        footer: (
          <ReportsModalFooter
            {...{ onCancel, onConfirm, onExport, onlyAllowTable }}
          />
        ),
      }}
    >
      <div className="configuration-section">
        <div className="choose-size-section">
          <span className="preview-label">Preview for:</span>
          <Radio.Group
            buttonStyle="solid"
            defaultValue={"Letter"}
            onChange={changeSizeHandler}
          >
            {Object.keys(PRINT_PAGE_CONFIGS).map((e) => (
              <Radio.Button key={e} value={e}>
                {e}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <div className="change-fields-section">
          <Form form={form}>
            <Form.List name="config">
              {(fields, { add, remove }) => {
                return (
                  <div className="dynamic-fields-container">
                    <ul className="field-list">
                      {fields.map((e) => {
                        let comp = reportsDataSections?.[e.name + 1];
                        let labelToShow = "Table - ";
                        if (comp?.image) {
                          labelToShow = `Chart - ${comp?.class.replace(
                            "chart ",
                            ""
                          )}`;
                        } else {
                          labelToShow =
                            labelToShow +
                            `${comp?.table?.cols?.length} columns`;
                        }

                        return (
                          <li className="dynamic-field" key={`${e.key}`}>
                            <div className="inner-field">
                              <b>{labelToShow}</b>
                              <MondayButton
                                Icon={<TrashIcon />}
                                className="mondayButtonRed"
                                onClick={() => {
                                  removeElementHandler(remove, e.name);
                                }}
                              >
                                {""}
                              </MondayButton>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {getRenderCondition() && (
                      <div className="fields-button-container">
                        {!onlyAllowTable && (
                          <MondayButton
                            Icon={<LineChartOutlined />}
                            className="mondayButtonBlue"
                            onClick={() => {
                              setConfigurationOptions("CHART");
                            }}
                          >
                            Add Chart
                          </MondayButton>
                        )}
                        <MondayButton
                          Icon={<TableOutlined />}
                          className="mondayButtonBlue"
                          onClick={() => {
                            setConfigurationOptions("TABLE");
                          }}
                        >
                          Add Table
                        </MondayButton>
                      </div>
                    )}
                    <ConfigOption
                      {...{
                        onConfirm(data) {
                          if (configurationOptions === "TABLE") {
                            addTableHandler(add, data);
                          } else {
                            addChartHandler(add, data);
                          }
                        },
                        onCancel() {
                          setConfigurationOptions("BLANK");
                        },
                        type: configurationOptions,
                        labels: Object.keys(
                          totals?.[Object.keys(totals)?.[0]] || {}
                        ),
                      }}
                    />
                  </div>
                );
              }}
            </Form.List>
          </Form>
        </div>
      </div>
      <div
        className="preview-section"
        style={{ aspectRatio: sizeConfig?.aspectRatio }}
      >
        <iframe
          ref={previewRef}
          className="preview-frame"
          name="preview-frame"
          height={"100%"}
          width={"100%"}
          sandbox="allow-modals allow-popups allow-same-origin allow-popups-to-escape-sandbox"
        />
      </div>
    </Modal>
  );
}

export default ReportsPdfExport;
