import dayjs from "dayjs";

export function formatDateToFormat(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}T0:00:00.000`;
}

export const generateDateRange = (startDateString, endDateString) => {
  const startDate = dayjs(startDateString);
  const endDate = dayjs(endDateString);

  const dateRange = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    dateRange.push(currentDate.format("MM/DD/YYYY"));
    currentDate = currentDate.add(1, "day");
  }

  return dateRange;
};
