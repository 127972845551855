import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { MondayButton } from "../../../../../commonComponents";

const statusColors = {
  Completed: "#7ac14d",
  Pending: "#ecde7c",
  Draft: "#787677",
};

const statusStyle = {
  height: 30,
  width: "100%",
  maxWidth: 300,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 5,
  textTransform: "uppercase",
  color: "#fff",
};

function columnDefs({ writeAccess, onEditClick, editData }) {
  const cellWidth = (window.innerWidth - 430) / 3;
  return [
    {
      headerName: "DEG Name",
      field: "degName",
      minWidth: cellWidth,
      cellRenderer: "leftAlignRenderer",
    },
    {
      headerName: "DEG Status",
      field: "degStatus",
      cellClass: "cell-center-aligned",
      minWidth: cellWidth,
      cellRenderer: ({ value }) => {
        const backgroundColor = statusColors?.[value] || "#787677";
        return <div style={{ ...statusStyle, backgroundColor }}>{value}</div>;
      },
    },
    {
      headerName: "DEG Date Range",
      minWidth: cellWidth,
      cellRenderer: ({ data }) => {
        return (
          <div style={{ width: "100%", textAlign: "start" }}>
            From: {dayjsNY(data?.fromDate).format("MM/DD/YYYY")} To:{" "}
            {dayjsNY(data?.toDate).format("MM/DD/YYYY")}
          </div>
        );
      },
    },
    writeAccess && {
      headerName: "Edit",
      minWidth: 130,
      cellClass: "cell-center-aligned",
      cellRenderer: ({ data }) => {
        return (
          <MondayButton
            hasIcon={false}
            onClick={() => onEditClick(data)}
            className={editData ? "" : "mondayButtonBlue"}
            disabled={!!editData}
            tooltipKey={" "}
          >
            Edit
          </MondayButton>
        );
      },
    },
  ].filter(Boolean);
}

export default columnDefs;
