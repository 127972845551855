const mouseEnter = (i, color) => {
  document.getElementsByClassName('NotificationPopOverBodyFilterItemContainer')[
    i
  ].style.background = color
  document.getElementsByClassName('NotificationPopOverBodyFilterItemContainer')[
    i
  ].style.border = `1px solid ${color}`
  document.getElementsByClassName(
    'NotificationPopOverBodyFilterItemContainerIcon'
  )[i].style.fill = '#fff'
  document.getElementsByClassName(
    'NotificationPopOverBodyFilterItemContainerLabel'
  )[i].style.color = '#fff'
}
export default mouseEnter
