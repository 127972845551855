import { createRental } from "../../../../../../../../../actions/rentals";
import { generateWeeks } from "../../../../../../../../../utils";

import { v4 as uuid } from "uuid";
import { max } from "lodash";
import dayjs from "dayjs";
import _ from "lodash";
import { checkIfServiceIsHoist } from "../../../../../../../Estimations/DataEntryGrid/models/Service";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { capitalizeFirstLetter } from "../../../../../../../Estimations/DataEntryGrid/tools/polyfillers";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import { getDetailsRentDaily } from "../../../../RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/HeaderDateInput/HeaderDateInputFunctions";
import { autoFillRentals } from "../../newRentalFunctions";
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const hoistDescriptionGenerator = ({ hoistData }) =>
  [
    [
      "<b>Label</b>",
      `${capitalizeFirstLetter(hoistData?.elevationLabel)}`,
    ]?.join(": "),
    ["<b>Location</b>", `${capitalizeFirstLetter(hoistData?.location)}`]?.join(
      ": "
    ),
    ["<b>Access</b>", `${capitalizeFirstLetter(hoistData?.access)}`]?.join(
      ": "
    ),
    ["<b>Type</b>", `${capitalizeFirstLetter(hoistData?.type)}`]?.join(": "),
    [
      "<b>Jumps</b> <br/>",
      hoistData?.jumps
        ?.map(({ name, floors }) =>
          [
            `${name}: `,
            "{ ",
            floors?.flatMap(({ floor }) => `Floor: ${floor}`)?.join(", "),
            " }",
          ]?.join("")
        )
        ?.join(" <br/> "),
    ]?.join(""),
  ]?.join("<br/>");

//================================= rental from schedule manually =========================================================
//with this function we create rental from scheduling
export const createRentalHandler = ({
  handleCloseForm,
  setChosenData,
  programFields,
  buttonType,
  allSchedules,
  chosenData,
  projectId,
  dispatch,
  specificProject,
  sovServices,
  getBodyToSaveInAutomation,
  navigate,
  isNextStep,
  getRental,
  saveAddedLogs,
}) => {
  const { toBeScheduled, scheduleAddress, scheduleId } = allSchedules?.find(
    ({ scheduleName }) => parseFloat(scheduleName) === chosenData?.selectedId
  );
  // Because the structure for Schedule changed, we need to group by Servivce Id & Estimation number
  const data = chosenData?.service?.map((serviceData) => {
    return toBeScheduled?.find(({ serviceId = "", estimationNumber = "" }) => {
      return `${serviceId}-${estimationNumber}` === serviceData;
    });
  });
  const taxRate = specificProject?.taxRate || 0;

  // here we generate te new rental object that is saved in db
  const newRental = {
    projectId: projectId,
    scheduleId,
    projectAddress: scheduleAddress,
    taxRate: taxRate,
    projectTaxRate: taxRate,
    services: data?.map((serviceData) => {
      const rentPercentage = serviceData?.rent || 7;

      const progressCalculator = 1;

      return checkIfServiceIsHoist(serviceData)
        ? //here we manipulate the object when the service is hoist
          {
            label: serviceData?.label,
            serviceId: serviceData?.serviceId,
            rentPercentage,
            estimationNumber: serviceData?.estimationNumber,
            estimationId: serviceData?.estimationId,
            serviceOptions: [
              {
                elevationId: uuid(),
                elevationLabel: "Hoist",
                items: serviceData?.serviceOptions[0]?.map(
                  (hoistData, index) => {
                    return {
                      ...hoistData,
                      id: index + 1,
                      appliedAmount: 0,
                      totalProgress: progressCalculator,
                      charged: false,
                      charges: [],
                      applied: false,
                      lock: false,
                      approved: true,
                      maxWeeklyAmount: +(
                        (hoistData?.rentalsTotal / 4) *
                        progressCalculator
                      )?.toFixed(2),
                      rentalDetails: {
                        startDate: undefined,
                        endDate: undefined,
                        weeklyAmount: +(
                          (hoistData?.rentalsTotal / 4) *
                          progressCalculator
                        )?.toFixed(2),
                        details: [],
                      },
                      description: hoistDescriptionGenerator({ hoistData }),
                    };
                  }
                ),
              },
            ],
          }
        : //here we manipulate the object when the service is not hoist
          {
            label: serviceData?.label,
            serviceId: serviceData?.serviceId,
            rentPercentage,
            estimationNumber: serviceData?.estimationNumber,
            estimationId: serviceData?.estimationId,
            serviceOptions: serviceData?.serviceOptions[0]?.map(
              (elevationData) => {
                return {
                  ...elevationData,
                  items: elevationData?.items?.map(
                    ({
                      originalPli,
                      totalProgress,
                      width,
                      height,
                      length,
                      diameter,
                    }) => {
                      //dont delete width, height, length because i use them with eval
                      const { dimensions, progress } = programFields
                        ?.find(
                          ({ fieldName }) => fieldName === "Mapping of Progress"
                        )
                        ?.fieldOptions?.find(
                          ({ name }) => name === serviceData?.label
                        );

                      console.log("originalPli", originalPli);

                      //here we find the elevationData for this pli
                      const filteredSchedulingPli = dimensions?.reduce(
                        (acc, curr) =>
                          curr === "length"
                            ? {
                                ...acc,
                                schedulingLength:
                                  eval(curr) || parseInt(originalPli?.[curr]),
                              }
                            : curr === "height"
                            ? {
                                ...acc,
                                schedulingHeight:
                                  eval(curr) || parseInt(originalPli?.[curr]),
                              }
                            : curr === "width"
                            ? {
                                ...acc,
                                schedulingWidth:
                                  eval(curr) || parseInt(originalPli?.[curr]),
                              }
                            : curr === "diameter"
                            ? {
                                ...acc,
                                schedulingDiameter:
                                  eval(curr) || parseInt(originalPli?.[curr]),
                              }
                            : acc,
                        {}
                      );

                      //here we calculate the total progress
                      const progressCalculator = totalProgress / eval(progress);

                      return {
                        ...filteredSchedulingPli,
                        ...originalPli,
                        appliedAmount: 0,
                        totalProgress: progressCalculator,
                        charged: false,
                        applied: false,
                        lock: false,
                        // maxWeeklyAmount: +(
                        // 	(originalPli?.rent / 4) *
                        // 	progressCalculator *
                        // 	(rentPercentage / 100)
                        // )?.toFixed(2),
                        maxWeeklyAmount: +(
                          (originalPli?.rent / 4) *
                          10
                        ).toFixed(2),
                        rentalDetails: {
                          startDate: undefined,
                          endDate: undefined,
                          weeklyAmount: +(
                            (originalPli?.rent / 4) *
                            progressCalculator *
                            (rentPercentage / 100)
                          )?.toFixed(2),
                          details: [],
                        },
                      };
                    }
                  ),
                };
              }
            ),
          };
    }),
  };

  if (!!getBodyToSaveInAutomation) {
    getBodyToSaveInAutomation({ bodyForApi: newRental });
  } else {
    const redirectToCreatedRental = !!(buttonType === "Finish");

    dispatch(
      createRental(
        newRental,
        redirectToCreatedRental,
        navigate,
        isNextStep,
        getRental,
        saveAddedLogs
      )
    );

    setChosenData({
      estimation: undefined,
      service: undefined,
    });
    if (!!!isNextStep) {
      if (buttonType === "Finish") {
        handleCloseForm();
      } else if (buttonType === "Save & Close") {
        handleCloseForm();
      }
    }
  }
};

//================================= rental from estimation =========================================================
//here we handle creation rentals estimation
// export const createRentalFromEstimationHandler = ({
//   handleCloseForm,
//   setChosenData,
//   estimations,
//   buttonType,
//   chosenData,
//   projectId,
//   dispatch,
//   specificProject,
//   sovServices,
//   getBodyToSaveInAutomation,
//   navigate,
//   isNextStep,
//   getRental,
//   saveAddedLogs,
//   rentals,
// }) => {
//   console.log("createRentalFromEstimationHandler", sovServices);
//   const {
//     services,
//     jobSiteAddress,
//     estimationId,
//     quickbooksNumber = "",
//     estimationNumber = "",
//     ...rest
//   } = estimations?.find(
//     ({ estimationId }) => estimationId === chosenData?.selectedId
//   );
//   const taxRate = specificProject?.taxRate || 0;
//   console.log("restServices", services);
//   const data = chosenData?.service?.map((serviceData) => {
//     const splittedServiceLabel = serviceData.split("|")[0];
//     const splittedEstimationId = serviceData.split("|")[1];

//     return services?.find(
//       ({ label }) =>
//         label === splittedServiceLabel && estimationId === splittedEstimationId
//     );
//   });

//   const progressCalculator = 1; //its 100 by default

//   // here we generate te new rental object that is saved in db
//   const newRental = {
//     projectId: projectId,
//     projectAddress: jobSiteAddress,
//     taxRate: taxRate,
//     projectTaxRate: taxRate,
//     estimationId: estimationId,
//     services: data?.map((serviceData) => {
//       console.log("serviceData", serviceData);
//       const rentPercentage = serviceData?.isScope
//         ? getScopeRentPerc(serviceData)
//         : serviceData?.rent;
//       const sovServiceData = sovServices?.find(
//         (el) =>
//           el?.label === serviceData?.label && el?.estimationId === estimationId
//       );
//       console.log("sovServiceData", {
//         sovServiceData,
//         sovServices,
//         estimationId,
//       });
//       return checkIfServiceIsHoist(serviceData)
//         ? //here we manipulate the object when the service is hoist
//           {
//             label: serviceData?.label,
//             serviceId: serviceData?.serviceId,
//             rentPercentage,
//             isScope: false,
//             retainage: sovServiceData?.retainage || 0,
//             rentalPaymentTerms: serviceData?.rentalPaymentTerms || {},
//             quickbooksNumber:
//               serviceData?.quickbooksNumber ||
//               quickbooksNumber ||
//               estimationNumber,
//             serviceOptions: [
//               {
//                 elevationId: uuid(),
//                 elevationLabel: "Hoist",
//                 items: serviceData?.serviceOptions[0]?.map(
//                   (hoistData, index) => {
//                     return {
//                       ...hoistData,
//                       id: index + 1,
//                       appliedAmount: 0,
//                       totalProgress: progressCalculator,
//                       charged: false,
//                       charges: [],
//                       applied: false,
//                       lock: false,
//                       approved: true,
//                       maxWeeklyAmount: +(
//                         (hoistData?.rentalsTotal / 4) *
//                         progressCalculator
//                       )?.toFixed(2),
//                       rentalDetails: {
//                         startDate: undefined,
//                         endDate: undefined,
//                         weeklyAmount: +(
//                           (hoistData?.rentalsTotal / 4) *
//                           progressCalculator
//                         )?.toFixed(2),
//                         details: [],
//                       },
//                       description: hoistDescriptionGenerator({ hoistData }),
//                       // items: hoistData?.items?.map(
//                       //   //dont delete width, height, length because i use them with eval
//                       //   (pliData) => {
//                       //     // elevationData.rent = serviceData?.isScope
//                       //     //   ? getScopeElevationRent(elevationData)
//                       //     //   : elevationData?.rent;

//                       //     return {
//                       //       ...pliData,
//                       //       appliedAmount: 0,
//                       //       totalProgress: progressCalculator,
//                       //       charged: false,
//                       //       charges: [],
//                       //       applied: false,
//                       //       lock: false,
//                       //       maxWeeklyAmount: +(
//                       //         (hoistData?.rent / 4) *
//                       //         progressCalculator *
//                       //         10 *
//                       //         (rentPercentage / 100)
//                       //       )?.toFixed(2),
//                       //       rentalDetails: {
//                       //         startDate: undefined,
//                       //         endDate: undefined,
//                       //         weeklyAmount: +(
//                       //           (hoistData?.rent / 4) *
//                       //           progressCalculator *
//                       //           (rentPercentage / 100)
//                       //         )?.toFixed(2),
//                       //         details: [],
//                       //       },
//                       //     };
//                       //   }
//                       // ),
//                       ...(autoFillRentals({
//                         rentals: rentals,
//                         currentElevation: hoistData,
//                         currentService: serviceData,
//                         quickbooksNumber:
//                           serviceData?.quickbooksNumber ||
//                           quickbooksNumber ||
//                           estimationNumber,
//                         pliData: hoistData,
//                       }) || {}),
//                       charged: false,
//                       charges: [],
//                       applied: false,
//                     };
//                   }
//                 ),
//               },
//             ],
//           }
//         : {
//             label: serviceData?.label,
//             serviceId: serviceData?.serviceId,
//             rentPercentage,
//             retainage: sovServiceData?.retainage || 0,
//             isScope: serviceData?.isScope,
//             rentalPaymentTerms: serviceData?.rentalPaymentTerms || {},
//             serviceOptions: serviceData?.serviceOptions[0]?.map(
//               (elevationData) => {
//                 if (serviceData?.isScope) {
//                   attachStructureToElevation(elevationData, serviceData);
//                 }
//                 return {
//                   ...elevationData,
//                   items: elevationData?.items
//                     ?.filter((e) => e?.approved === true)
//                     .map(
//                       //dont delete width, height, length because i use them with eval
//                       (pliData) => {
//                         // elevationData.rent = serviceData?.isScope
//                         //   ? getScopeElevationRent(elevationData)
//                         //   : elevationData?.rent;

//                         return {
//                           ...pliData,
//                           appliedAmount: 0,
//                           totalProgress: progressCalculator,
//                           charged: false,
//                           charges: [],
//                           applied: false,
//                           lock: false,
//                           maxWeeklyAmount: +(
//                             (pliData?.rent / 4) *
//                             progressCalculator *
//                             10 *
//                             (rentPercentage / 100)
//                           )?.toFixed(2),
//                           rentalDetails: {
//                             startDate: undefined,
//                             endDate: undefined,
//                             weeklyAmount: +(
//                               (elevationData?.rent / 4) *
//                               progressCalculator *
//                               (rentPercentage / 100)
//                             )?.toFixed(2),
//                             details: [],
//                           },
//                           ...(autoFillRelatedRentals({
//                             rentals: rentals,
//                             currentElevation: elevationData,
//                             currentService: serviceData,
//                             quickbooksNumber:
//                               serviceData?.quickbooksNumber ||
//                               quickbooksNumber ||
//                               estimationNumber,
//                             pliData,
//                           }) || {}),
//                           charged: false,
//                           charges: [],
//                           applied: false,
//                         };
//                       }
//                     ),
//                 };
//               }
//             ),
//             quickbooksNumber:
//               serviceData?.quickbooksNumber ||
//               quickbooksNumber ||
//               estimationNumber,
//           };
//     }),
//   };

//   console.log("newRental", { newRental, services });
//   // return;
//   if (!!getBodyToSaveInAutomation) {
//     getBodyToSaveInAutomation({ bodyForApi: newRental });
//   } else {
//     const redirectToCreatedRental = !!(buttonType === "Finish");

//     dispatch(
//       createRental(
//         newRental,
//         redirectToCreatedRental,
//         navigate,
//         isNextStep,
//         getRental,
//         saveAddedLogs
//       )
//     );

//     setChosenData({
//       estimation: undefined,
//       service: undefined,
//     });

//     if (buttonType === "Finish") {
//       handleCloseForm();
//     } else if (buttonType === "Save & Close") {
//       handleCloseForm();
//     }
//   }
// };
//================================= free rental period =========================================================================

export const freeRentalPeriod = ({ allSchedules, offset }) => {
  const { scheduleDays } = allSchedules[0] || {};

  const startDate = dayjs(
    max(scheduleDays?.map(({ endDate }) => dayjs(endDate)?.valueOf()))
  )
    ?.add(offset, "week")
    ?.valueOf();

  return startDate;
};

//================================= rental from schedule automatically =========================================================
//with this function we create rental from scheduling
export const createRentalAutomaticallyHandler = ({
  handleCloseForm,
  setChosenData,
  programFields,
  allSchedules,
  buttonType,
  chosenData,
  projectId,
  dispatch,
  specificProject,
  sovServices,
  getBodyToSaveInAutomation,
  navigate,
  isNextStep,
  getRental,
  saveAddedLogs,
}) => {
  const offsetiQeDoBohetMeVone = 0;
  const taxRate = specificProject?.taxRate || 0;

  const { scheduleDays, toBeScheduled, scheduleAddress, scheduleId } =
    allSchedules?.find(
      ({ scheduleName }) => parseFloat(scheduleName) === chosenData?.selectedId
    ) || {};

  const endingSchedule = allSchedules?.find(
    ({ scheduleName }) =>
      parseFloat(scheduleName) === chosenData?.selectedId + 1
  );

  const endDate = max(
    endingSchedule?.scheduleDays?.map(({ endDate }) =>
      dayjs(endDate)?.valueOf()
    )
  );

  const startDate =
    chosenData?.selectedId > 1
      ? max(
          scheduleDays?.map(({ endDate }) =>
            dayjs(endDate)?.add(1, "day")?.valueOf()
          )
        )
      : max(scheduleDays?.map(({ endDate }) => dayjs(endDate)?.valueOf()));

  const data = chosenData?.service?.map((serviceData) =>
    toBeScheduled?.find(({ label }) => label === serviceData)
  );

  const freeRentalEndDate = freeRentalPeriod({
    allSchedules,
    offset: offsetiQeDoBohetMeVone,
  });

  const calculatedStartDate =
    startDate >= freeRentalEndDate && freeRentalEndDate <= endDate
      ? freeRentalEndDate
      : startDate;

  //here we calculate how many days are between the date of first schedule and the second
  const howManyDays = +(
    dayjs
      ?.duration(
        dayjs(endDate).valueOf() - dayjs(calculatedStartDate).valueOf()
      )
      ?.asDays() + 1
  )?.toFixed(0);

  // here we generate te new rental object that is saved in db
  const newRental = {
    projectId: projectId,
    projectAddress: scheduleAddress,
    scheduleId,
    taxRate: taxRate,
    projectTaxRate: taxRate,
    services: data?.map((serviceData) => {
      const rentPercentage = serviceData?.rent || 7;
      return checkIfServiceIsHoist(serviceData)
        ? //here we manipulate the object when the service is hoist
          {
            label: serviceData?.label,
            serviceId: serviceData?.serviceId,
            rentPercentage,
            serviceOptions: [
              {
                elevationId: uuid(),
                elevationLabel: "Hoist",
                items: serviceData?.serviceOptions[0]?.map(
                  (hoistData, index) => {
                    //here we calculate the total progress
                    const progressCalculator = 1;

                    //here we calculate the max weekly amount from taking rent(which is for 28 days) and dividing it with 4
                    const maxWeeklyAmount = +(
                      (hoistData?.rentalsTotal / 4) *
                      10 *
                      progressCalculator
                    )?.toFixed(2);

                    //here we calculate the daily amount which is previously calculated weekly amount  dividing it with 7
                    const dailyAmount = +_.divide(maxWeeklyAmount, 7)?.toFixed(
                      2
                    );
                    const details =
                      generateWeeks(calculatedStartDate, endDate) || [];

                    return {
                      ...hoistData,
                      id: index + 1,
                      appliedAmount: dailyAmount * howManyDays,
                      totalProgress: progressCalculator,
                      charged: false,
                      applied: false,
                      lock: false,
                      approved: true,
                      maxWeeklyAmount,
                      rentalDetails: {
                        startDate,
                        endDate,
                        weeklyAmount: maxWeeklyAmount,
                        details: details?.map((detailsData) => ({
                          ...detailsData,
                          charged: false,
                          weeklyAmount: dailyAmount * detailsData?.days?.length,
                          lock: false,
                          applied: false,
                          days: detailsData?.days?.map((daysData) => ({
                            ...daysData,
                            applied: false,
                            charged: false,
                            lock: false,
                            dailyAmount: dailyAmount,
                          })),
                        })),
                      },
                      description: hoistDescriptionGenerator({ hoistData }),
                    };
                  }
                ),
              },
            ],
          }
        : {
            label: serviceData?.label,
            serviceId: serviceData?.serviceId,
            rentPercentage,
            serviceOptions: serviceData?.serviceOptions[0]?.map(
              (elevationData) => {
                return {
                  ...elevationData,
                  items: elevationData?.items?.map(
                    ({
                      originalPli,
                      totalProgress,
                      width,
                      height,
                      length,
                      diameter,
                    }) => {
                      //dont delete width, height, length because i use them with eval
                      const { dimensions, progress } = programFields
                        ?.find(
                          ({ fieldName }) => fieldName === "Mapping of Progress"
                        )
                        ?.fieldOptions?.find(
                          ({ name }) => name === serviceData?.label
                        );

                      //here we find the elevationData for this pli
                      const filteredSchedulingPli = dimensions?.reduce(
                        (acc, curr) =>
                          curr === "length"
                            ? {
                                ...acc,
                                schedulingLength:
                                  eval(curr) || parseInt(originalPli[curr]),
                              }
                            : curr === "height"
                            ? {
                                ...acc,
                                schedulingHeight:
                                  eval(curr) || parseInt(originalPli[curr]),
                              }
                            : curr === "width"
                            ? {
                                ...acc,
                                schedulingWidth:
                                  eval(curr) || parseInt(originalPli[curr]),
                              }
                            : curr === "diameter"
                            ? {
                                ...acc,
                                schedulingDiameter:
                                  eval(curr) || parseInt(originalPli[curr]),
                              }
                            : acc,
                        {}
                      );

                      //here we calculate the total progress
                      const progressCalculator = totalProgress / eval(progress);

                      // const maxWeeklyAmount = +(
                      // 	(originalPli?.rent / 4) *
                      // 	progressCalculator *
                      // 	(rentPercentage / 100)
                      // )?.toFixed(2);

                      const maxWeeklyAmount = +(
                        (originalPli?.rent / 4) *
                        10 *
                        progressCalculator
                      )?.toFixed(2);

                      const dailyAmount = +_.divide(maxWeeklyAmount, 7);
                      const details =
                        generateWeeks(calculatedStartDate, endDate) || [];

                      return {
                        ...filteredSchedulingPli,
                        ...originalPli,
                        appliedAmount: dailyAmount * howManyDays,
                        totalProgress: progressCalculator,
                        charged: false,
                        applied: false,
                        lock: false,
                        maxWeeklyAmount,
                        rentalDetails: {
                          startDate,
                          endDate,
                          weeklyAmount: maxWeeklyAmount,
                          details: details?.map((detailsData) => ({
                            ...detailsData,
                            charged: false,
                            weeklyAmount:
                              dailyAmount * detailsData?.days?.length,
                            lock: false,
                            applied: false,
                            days: detailsData?.days?.map((daysData) => ({
                              ...daysData,
                              applied: false,
                              charged: false,
                              lock: false,
                              dailyAmount: dailyAmount,
                            })),
                          })),
                        },
                      };
                    }
                  ),
                };
              }
            ),
          };
    }),
  };

  if (!!getBodyToSaveInAutomation) {
    getBodyToSaveInAutomation({ bodyForApi: newRental });
  } else {
    const redirectToCreatedRental = !!(buttonType === "Finish");

    dispatch(
      createRental(
        newRental,
        redirectToCreatedRental,
        navigate,
        isNextStep,
        getRental,
        saveAddedLogs
      )
    );

    setChosenData({
      estimation: undefined,
      service: undefined,
    });

    {
      if (!!!isNextStep) {
        if (buttonType === "Finish") {
          handleCloseForm();
        } else if (buttonType === "Save & Close") {
          handleCloseForm();
        }
      }
    }
  }
};

//=====================================manipulate schedules==============================================================
export const calculatedScheduleHandler = ({ allSchedules }) =>
  allSchedules?.reduce((acc, curr, index) => {
    const previousSchedule = acc?.at?.(-1);
    return index === 0
      ? //if index is 0 we just turn toBeScheduled from object to array
        [
          {
            ...curr,
            toBeScheduled: Object.keys(curr?.toBeScheduled || {})?.flatMap(
              (estimationId) => curr.toBeScheduled[estimationId]
            ),
          },
        ]
      : //if index is not 0 we just turn toBeScheduled from object, and take the totalProgress from previous element in array and remove total progress
        [
          ...acc,
          {
            ...curr,
            toBeScheduled: Object.keys(curr?.toBeScheduled || {})
              ?.flatMap((estimationId) => curr.toBeScheduled[estimationId])
              ?.map((serviceData, serviceIndex) => {
                return {
                  ...serviceData,
                  serviceOptions: [
                    serviceData?.serviceOptions[0]?.map(
                      (elevationData, elevationIndex) => {
                        return {
                          ...elevationData,
                          items: elevationData?.items?.map(
                            (itemsData, itemsIndex) => {
                              const previousTotalProgress =
                                previousSchedule?.toBeScheduled[serviceIndex]
                                  ?.serviceOptions[0][elevationIndex]?.items[
                                  itemsIndex
                                ]?.totalProgress;

                              return {
                                ...itemsData,
                                totalProgress:
                                  previousTotalProgress -
                                  itemsData?.totalProgress,
                              };
                            }
                          ),
                        };
                      }
                    ),
                  ],
                };
              }),
          },
        ];
  }, []);

export const getScopeElevationRent = (elevationData) => {
  console.log("elevationData, ", elevationData);
  let items = elevationData?.items || [];
  let totalRent = 0;
  items.forEach((item) => {
    console.log("item, ", item?.rent);
    if (item?.approved === false) return;
    totalRent += forceToNumber(item?.rent || item?.Rent);
  });
  return totalRent;
};

export const getScopeRentPerc = (serviceData) => {
  let amount = 0;
  let perc = 0;
  let elevations = serviceData?.serviceOptions[0] || [];
  elevations.forEach((elevation) => {
    amount += getScopeElevationRent(elevation);
  });
  return 100;
};

export const attachStructureToElevation = (elevationData, serviceData) => {
  elevationData.approved = true;
  elevationData.charged = false;
  elevationData.charges = [];
  elevationData.price = elevationData.items?.reduce((acc, curr) => {
    return acc + forceToNumber(curr?.price || curr?.Price);
  }, 0);
  elevationData.rent = getScopeElevationRent(elevationData);
  elevationData.taxAmount = serviceData?.isTaxable
    ? (elevationData.price * serviceData?.taxRate) / 100
    : 0;
  elevationData.totalPrice = elevationData.price + elevationData.taxAmount;

  let items = elevationData?.items || [];
  items.forEach((item) => {
    item.approved = true;
    item.charged = false;
    item.charges = [];
    item.price = forceToNumber(item?.price || item?.Price);
    item.rent = forceToNumber(item?.rent || item?.Rent);
    item.taxAmount = serviceData?.isTaxable
      ? (item.price * serviceData?.taxRate) / 100
      : 0;
    item.totalPrice = item.price + item.taxAmount;
  });
};
