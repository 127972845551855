import React, { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import SignaturePad from "react-signature-canvas";
import Swal from "sweetalert2";
import { CheckIconModal, DeleteIcon } from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";
import { ClearIcon } from "../../../../../SidebarPages/BasePage/src";
import { apiRoutes, fetchData } from "../../../../../SidebarPages/Fleet/utils";

function SignatureBuilder({ editorRef }) {
  const signatureRef = useRef(null);
  const [controls, setControls] = useState({
    onS: true,
  });
  const { userConfiguration } = useSelector((state) => state.userConfig);
  console.log("userConfiguration", userConfiguration);

  const onInsertSignature = async () => {
    let ip = await fetchData(apiRoutes.getIp);
    ip = console.log("onInsertSignature", ip);
    editorRef.current.component.selection.insertHTML(
      `<img src="${signatureRef.current
        ?.getTrimmedCanvas()
        ?.toDataURL("image/png")}" alt="Signed by ${
        userConfiguration?.nameOfUser
      }, 07/09/2024, [${ip || "no_ip_found"}]" />`
    );
    Swal.fire("Signature Proposal Inserted!", "", "success");
  };

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "center",
        width: "100%",
      }}
      //   className="inspectionSignContainer"
      //   style={{ padding: 0, marginTop: 0, height: "100" }}
    >
      <div
        style={{
          display: "flex",
          gap: 10,
        }}
      >
        <MondayButton
          className="mondayButtonBlue"
          Icon={<CheckIconModal />}
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You are about to insert the official signature proposal into the editor.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Officially sign the proposal!",
            }).then((result) => {
              if (result.isConfirmed) {
                onInsertSignature();
              }
            });
          }}
        >
          Insert Signature Proposal
        </MondayButton>
        <MondayButton
          className="mondayButtonRed"
          Icon={<DeleteIcon />}
          onClick={() => {
            signatureRef.current?.clear();
          }}
        >
          Clear Signature
        </MondayButton>
      </div>
      <div
        style={{
          padding: 20,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          paddingLeft: "50px",
          width: "100%",
        }}
      >
        <SignaturePad
          canvasProps={{ width: 330, height: 200, className: "sigCanvas" }}
          ref={signatureRef}
        />
      </div>
    </div>
  );
}

export default SignatureBuilder;
