import React, { createContext, useContext, useState } from "react";

export const DrawerContext = createContext();

export const useDrawer = () => useContext(DrawerContext);

const RightActionsMobileDrawerProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default RightActionsMobileDrawerProvider;
