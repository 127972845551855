export const findInvoicesByCategoryId = ({
  categoryData,
  table,
  categoriesName,
  categoryId,
}) => {
  return (
    categoryData[table]?.find(
      (el) => el?.[`${categoriesName[table]}Id`] === categoryId
    )?.invoices || []
  );
};

export const calculateInvoiceAmount = (items) => {
  return (
    items?.reduce(
      (total, { amount = 0, tax = false, taxAmount = 0 }) =>
        total + (amount + (tax ? taxAmount : 0)),
      0
    ) || 0
  );
};

export const generateInvoices = ({
  categoryData,
  table,
  categoriesName,
  categoryId,
  invoiceData,
  res,
  convertedData,
}) => {
  if (res) {
    const existingInvoices = findInvoicesByCategoryId({
      categoryData,
      table,
      categoriesName,
      categoryId,
    });
    const newInvoiceAmount = calculateInvoiceAmount(res?.invoiceItems);

    return [
      ...existingInvoices,
      {
        invoiceId: res?.invoiceId,
        invoiceNumber: res?.invoiceNumber,
        invoiceAmount: newInvoiceAmount,
      },
    ];
  } else {
    const existingInvoices = findInvoicesByCategoryId({
      categoryData,
      table,
      categoriesName,
      categoryId,
    }).filter(({ invoiceId }) => invoiceId !== invoiceData?.invoiceId);
    const newInvoiceAmount = calculateInvoiceAmount(
      convertedData?.invoiceItems
    );

    return {
      invoices: [
        ...existingInvoices,
        {
          invoiceId: invoiceData?.invoiceId,
          invoiceNumber: invoiceData?.invoiceNumber,
          invoiceAmount: newInvoiceAmount,
        },
      ],
    };
  }
};
