function findTabFromSearch({ defaultTab, tabQuery = "tab" }) {
  let searchParams = new URLSearchParams(window.location.search);
  let tabValue = searchParams.get(tabQuery);

  if (!tabValue) {
    return defaultTab;
  }
  return decodeURIComponent(tabValue.replace("+", " "));
}

export default findTabFromSearch;
