import { Spin, Tooltip } from "antd";
import "./MondaySwitch.scss";
import { Locked } from "../../SidebarPages/DynamicView/src";
import { LoadingOutlined } from "@ant-design/icons";
import { TickIcon } from "../../pages/Settings/settingsComponents/Roles/src";

/**
 * Renders a MondaySwitch component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.checked=true] - Specifies whether the switch is checked or not.
 * @param {Function} [props.onToggleSwitch=()=>{}] - The function to be called when the switch is toggled.
 * @param {string} [props.onText="On"] - The text to be displayed when the switch is on.
 * @param {string} [props.offText="Off"] - The text to be displayed when the switch is off.
 * @param {Object} [props.colors={ on: "#00A464", off: "#F5F5F7" }] - The colors for the switch background.
 * @param {string} [props.title=""] - The tooltip title for the switch.
 * @param {ReactNode} [props.onIcon=<TickIcon />] - The icon to be displayed when the switch is on.
 * @param {ReactNode} [props.offIcon=<Locked />] - The icon to be displayed when the switch is off.
 * @param {boolean} [props.removeIcon=true] - Specifies whether to remove the icon from the switch.
 * @param {string|null} [props.width=null] - The width of the switch.
 * @param {string} [props.className=""] - The additional CSS class for the switch.
 * @param {boolean} [props.small=false] - Specifies whether to render a small switch.
 * @param {Object} [props.style={}] - The inline style for the switch.
 * @param {boolean} [props.loading=false] - Specifies whether the switch is in loading state.
 * @returns {JSX.Element} The rendered MondaySwitch component.
 */
function MondaySwitch({
  checked = true,
  onToggleSwitch = () => {},
  onText = "On",
  offText = "Off",
  colors = { on: "#00A464", off: "#F5F5F7" },
  title = "",
  onIcon = <TickIcon />,
  offIcon = <Locked />,
  removeIcon = true,
  width = null,
  className = "",
  small = false,
  style = {},
  loading = false,
}) {
  function handleSwitch() {
    onToggleSwitch(!checked);
  }

  const loadingIcon = (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: small ? 10 : 15,
          }}
          spin
        />
      }
    />
  );

  return (
    <Tooltip placement="top" trigger="hover" title={title}>
      <div
        className={`monday-switch ${className} ${
          small ? "small-monday-switch" : ""
        }`}
        style={{
          ...style,
          ...(width ? { width } : {}),
          backgroundColor: checked ? colors.on : colors.off,
        }}
        id="switchButtonContainer"
        onClick={handleSwitch}
      >
        <div
          className={`monday-switch-toggle ${checked ? "onIcon" : "offIcon"}`}
          id="toggleDiv"
        >
          {!removeIcon
            ? loading
              ? loadingIcon
              : checked
              ? onIcon
              : offIcon
            : null}
        </div>
        <div
          className={`monday-switch-text ${checked && "onText"} ${
            !checked && "offText"
          }`}
        >
          {checked ? onText : offText}
        </div>
      </div>
    </Tooltip>
  );
}

export default MondaySwitch;
