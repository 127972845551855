function inspectionBreadcrumbItems(id: string) {
  return [
    {
      title: "Maintenance",
      path: "/fleetMaintenance",
    },
    {
      title: "Inspections",
      path: "/fleetInspections",
    },
    {
      title: id,
    },
  ];
}

export default inspectionBreadcrumbItems;
