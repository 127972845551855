import { Tooltip } from "antd";
import { Link } from "react-router-dom";

import { ChargedAllDaysIcon } from "../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../utils/formatCurrency";
import { DollarIcon } from "../../../../../../Estimations/components/DataEntryGridModal/assets";

const centerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const columnDefsInitial = [
  {
    headerName: "",
    field: "id",
    maxWidth: 20,
    resizable: false,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    cellRendererFramework: () => "",
  },
  {
    headerName: "#",
    field: "number",
    cellStyle: () => centerStyle,
    cellRendererFramework: (props) => (
      <div style={{ textAlign: "center" }}>
        <Link
          to={`/${props.data.selectedCategory.toLowerCase()}/${props.data.id}`}
          target="_blank"
        >
          {/*props.data.type*/} {props.value}
        </Link>
      </div>
    ),
  },
  {
    headerName: "Invoiced Amount",
    field: "invoicedAmount",
    // cellStyle: () => centerStyle,
    cellRendererFramework: ({ value }) => formatCurrency(value),
  },
  {
    headerName: "Total",
    field: "totalAmount",
    cellStyle: () => centerStyle,
    cellRendererFramework: ({ value }) => (
      <div style={{ textAlign: "center" }}>{formatCurrency(value)}</div>
    ),
  },
  {
    headerName: "",
    field: "charges",
    cellStyle: () => centerStyle,
    cellRendererFramework: ({ value: charges, data }) => {
      const isReqIncluded = data?.requisitionsIncluded?.length > 0;
      const isCharged =
        charges?.filter(({ chargeType }) => chargeType !== "Credit Memo") ||
        data?.charged;
      const isCredited = charges?.filter(
        ({ chargeType }) => chargeType === "Credit Memo"
      );
      return (
        <div style={{ textAlign: "center", gap: 12, display: "flex" }}>
          {isReqIncluded && (
            <Tooltip
              title={data?.requisitionsIncluded?.map(
                ({ applicationNo, applicationId }, i) => (
                  <>
                    <a
                      style={{ color: "white" }}
                      href={`/applications/${applicationId}`}
                      target={"_blank"}
                    >
                      Requisition {applicationNo}
                    </a>
                    <br />
                  </>
                )
              )}
            >
              <DollarIcon style={{ fill: "#68c142", width: 20, height: 20 }} />
            </Tooltip>
          )}
          {isCharged?.length > 0 && (
            <Tooltip
              title={isCharged?.map(({ chargeNumber, chargeId }, i) => (
                <>
                  <a
                    style={{ color: "white" }}
                    href={`/charges/${chargeId}`}
                    target="_blank"
                  >
                    Charge {chargeNumber}
                  </a>
                  <br />
                </>
              ))}
            >
              <ChargedAllDaysIcon
                style={{ fill: "#68c142", width: 20, height: 20 }}
              />
            </Tooltip>
          )}
          {isCredited?.length > 0 && (
            <Tooltip
              title={isCredited?.map(({ chargeNumber, chargeId }, i) => (
                <>
                  <a
                    style={{ color: "white" }}
                    href={`/charges/${chargeId}`}
                    target="_blank"
                  >
                    Credit {chargeNumber}
                  </a>
                  <br />
                </>
              ))}
            >
              <ChargedAllDaysIcon
                style={{ fill: "#68c142", width: 20, height: 20 }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
];
