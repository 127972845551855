import React from "react";
import { Modal, message } from "antd";
import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
// import { ResizableBox } from "react-resizable";
import { AgGridReact } from "ag-grid-react";
import "../../scheduleGrid.scss";
import CheckboxForOther from "../../componentsForPli/CheckboxForOther";
import DaysInScheduleMaster from "../../componentsForPli/DaysInScheduleMaster";
import { scheduleAddonsColumns } from "../../columnDefinition/columnsGenerators";
import { AddonProgressModal } from "../AddonProgressModal";
import { PROGRESS_ORIGIN } from "../PliProgressModal";
import { PliHistoryPopover } from "../PliHistoryPopover";
import { completedItems, selectedItems } from "../../../helpers/PliInfoGetters";
import { AddonButton } from "./AddonButton";
import { RateReviewIcon } from "../../../../../../../icons";
import { autoSizeGridColumns } from "../../../../../../SidebarPages/Projects/Accounting/Applications/ApplicationView/components/ServiceList/Rentals/Components/RentalCredit/ServiceLevelCredit/agGridHelpers";

/**
 * Modal of addons inside plis
 * */
export class AddonsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addons: this.props.value,
      visible: false,
    };
  }

  frameworkComponents = {
    CheckboxForOther,
    DaysInScheduleMaster,
    AddonProgressModal,
    PliHistoryPopover,
  };

  showModal = () => {
    if (this?.props?.data?.addons?.length) {
      this.setState({ visible: true });
    } else {
      message.warning("No addons found");
    }
  };
  handleCancel = () => {
    this.setState({ visible: false });
  }; //when we close the modal. Nothing is canceled actually

  handleAddonsGridReady = (params, data) => {
    params.api.setRowData(data);
  };

  getAddonsColumns = () => {
    const {
      serviceId,
      elevationIndex,
      // origin,
      updateDays_From_Addons_to_PLI,
      scheduleDays,
      pricing,
      typeOfWorkObject,
      setItemAfterProgress,
    } = this.props;

    try {
      let columns = scheduleAddonsColumns(
        pricing,
        serviceId,
        AddonType.pli,
        typeOfWorkObject,
        scheduleDays
      );

      let DaysInScheduleMasterCol = columns.find(
        (c) => c.cellRenderer === "DaysInScheduleMaster"
      );
      if (DaysInScheduleMasterCol)
        DaysInScheduleMasterCol.cellRendererParams = {
          serviceId,
          elevationIndex,
          origin: PROGRESS_ORIGIN.PLI_ADDON,
          updateDays_From_Addons_to_PLI,
          scheduleDays,
        };

      let AddonProgressModalCol = columns.find(
        (c) => c.cellRenderer === "AddonProgressModal"
      );
      if (AddonProgressModalCol)
        AddonProgressModalCol.cellRendererParams = {
          serviceId,
          elevationIndex,
          type: PROGRESS_ORIGIN.PLI_ADDON,
          setItemAfterProgress,
          typeOfWorkObject,
          scheduleDays,
        };

      return columns;
    } catch (e) {
      console.error(
        "Could not create addons columns. schedule form -> addonsList",
        e
      );
      return [];
    }
  };

  render() {
    const { visible, addons } = this.state;
    const addonsCount = addons?.length || 0;
    const selectedAddonsCount = selectedItems(addons).length;
    const completedAddonsCount = completedItems(addons, {
      d1: "addon_progress_dimension",
    }).length;

    return (
      <>
        <div style={{ display: "flex" }}>
          {!this?.props?.node?.group ? (
            <AddonButton
              {...{
                numbers: [
                  {
                    number: completedAddonsCount,
                    description: "Completed addons",
                  },
                  {
                    number: selectedAddonsCount,
                    description: "Selected addons",
                  },
                  {
                    number: addonsCount,
                    description: "Total addons for this Line Item",
                  },
                ],
                icon: <RateReviewIcon />,
                onClick: this.showModal,
              }}
            />
          ) : (
            ""
          )}
        </div>
        <Modal
          className="servicesModal"
          centered
          open={visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div className="addonsContainer" style={{ height: "100%" }}>
            <div className="ag-theme-balham scheduleGrid ag-theme-alpine">
              <AgGridReact //ag grid for addons in bottom
                columnDefs={this.getAddonsColumns()}
                defaultColDef={scheduleAddonsDefaultColDef}
                frameworkComponents={this.frameworkComponents}
                suppressDragLeaveHidesColumns={true}
                animateRows={false}
                // immutableData={true}
                // enableFillHandle={true}
                enableCellChangeFlash={true}
                domLayout={"normal"}
                getRowNodeId={(data) => data.id}
                onGridReady={(params) => {
                  this.handleAddonsGridReady(params, this.state.addons);
                }}
                onComponentStateChanged={(e) => autoSizeGridColumns(e)}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export const scheduleAddonsDefaultColDef = {
  editable: false,
  enableCellChangeFlash: false,
  resizable: true,
  autoHeight: true,
  flex: 1,
};
