import React, { Fragment } from "react";
import { HistoryOutlined } from "@ant-design/icons";

import { useRedux } from "../../../../../hooks/useRedux";
import { IncludeRentals } from "./components/IncludeRentals";
import { useCreateChargeContext } from "./context/NewChargeItemContext";
import { RequisitionsAgGrid } from "../ChargeAgGrid/RequisitionsAgGrid";
import Rentals from "../../../Applications/ApplicationView/components/ServiceList/Rentals/Rentals";
import { StateContext } from "../../../Applications/ApplicationView/Context/Context";
import { groupServicesByEstimationId } from "../ChooseChargeFromModal/utils/groupServicesByEstimationId";

const RequisitionCharge = () => {
  const [objectData] = useRedux("chargeItemsModal");
  const [darkMode] = useRedux("chargesDarkMode", true, false);
  const [isWritable] = useRedux("chargesIsWritable");

  const categoryFrom = objectData?.categoryFrom;
  const whereToFind = objectData?.whereToFind;
  const currentCharge = objectData?.currentCharge;

  const {
    chargeToSave,
    applications,
    setChargeToSave,
    selectedServices,
    includeRentals,
  } = useCreateChargeContext();

  const filteredCharges = groupServicesByEstimationId(
    chargeToSave?.chargeItems?.filter((el) => !!!el?.rentalId)
  );

  const filteredApplications = applications
    ?.filter((el) => el?.applicationNo < whereToFind?.applicationNo)
    .sort((a, b) => b?.applicationNo - a?.applicationNo);

  return (
    <>
      {filteredCharges.map((charge) => {
        const selectedCharge = selectedServices?.find(
          (estimation) => estimation.estimationId === charge.estimationId
        );
        return charge?.services?.map((service) => {
          const isSelected = selectedCharge?.services?.[service.label];
          return (
            <RequisitionsAgGrid
              key={service.label}
              {...{
                style: {
                  display: isSelected ? "block" : "none",
                },
                charge: { ...chargeToSave, categoryFrom },
                service: service,
                isWritable: !!currentCharge ? isWritable : true,
                darkMode,
                setChargeToSave,
                currentCharge,
              }}
            />
          );
        });
      })}
      {includeRentals && whereToFind?.includedRentals?.map(IncludeRentals)}
      {filteredApplications?.map((application, index) => {
        if (application?.includedRentals?.length > 0)
          return (
            <Fragment key={index}>
              <StateContext.Provider value={{ darkMode }}>
                <div
                  className={
                    darkMode
                      ? "includedRentalsMainHeaderDark"
                      : "includedRentalsMainHeaderLight"
                  }
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <HistoryOutlined
                    style={{ fill: darkMode ? "white" : "dark" }}
                  />
                  <strong style={{ marginLeft: "10px", color: "#71CF48" }}>
                    These are the rentals included in Requisition{" "}
                    {application?.applicationNo}
                  </strong>
                </div>
                {application?.includedRentals?.map((rental) => (
                  <Rentals
                    {...{
                      rental,
                      type: "prev",
                      prevRentalsRetainage: whereToFind?.prevRentalsRetainage,
                    }}
                  />
                ))}
              </StateContext.Provider>
            </Fragment>
          );
      })}
    </>
  );
};

export default RequisitionCharge;
