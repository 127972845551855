import React, { useEffect, useState } from "react";
import { SmallLoadableComp } from "../../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import { Image } from "antd";
import { image } from "../../../../../SidebarPages/Fleet/assets";

type Props = {
  styles?: React.CSSProperties;
  fileId: string;
  driveRequest: any;
};

//* Component that loads images from google drive with a loading screen
const ImageLoader = (props: Props) => {
  const { fileId, driveRequest, styles = {} } = props;
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    driveRequest?.getImageSrc(fileId).then((res) => {
      setImageSrc(res?.src);
    });
  }, []);

  return (
    <div
      style={{
        height: !!imageSrc ? "100%" : "400px",
      }}
    >
      <SmallLoadableComp loading={!imageSrc}>
        <Image src={imageSrc} alt="image" style={styles} />
      </SmallLoadableComp>
    </div>
  );
};

export default ImageLoader;
