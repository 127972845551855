// noinspection JSUnusedAssignment

import {
  Button,
  InputNumber,
  message,
  Modal,
  Popover,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getElevationTotal } from "../../tools/formatters";
import Force from "../../../../../../icons/Data_Grid_Force 32px.webp";
import { wrapWithErrorCalculating } from "../other/ErrorCalculating";
import CurrencyInput from "react-currency-input-field";
import { formatCurrency } from "../../../../utils";
import { CloseIconModal, ForcePriceIcon } from "../../../../../../assets/index";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { MondayButton, WithTooltip } from "../../../../../commonComponents";
import { NormalSizedModal } from "../../../../Fleet/components";
import { SaveIcon } from "../../../../BasePage/src";
import { useRedux } from "../../../../hooks";
import "./elevationPanel.scss";
import { pliTaxAmountCalculate } from "../../tools/controllers/CellEditingEvents";
import { checkIfServiceIsHoist } from "../../models/Service";
import { LockFilled, UnlockFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import AutoFiller from "./AutoFiller/AutoFiller";
// noinspection JSUnusedLocalSymbols
export class ElevationPrice extends React.Component {
  flashes = {
    unitTotal: false,
    suffix: false,
    priceSubtotal: false,
    averagePPU: false,
    rentTotal: false,
  };

  state = {
    priceTotal: this?.props?.elevation?.totalPrice,
    totalRent: this.props.elevation.rentalsTotal,
    desiredPrice: this.props.elevation.desiredPrice,
    price: this.props.elevation.price,
    rent: this.props.elevation.rentalsTotal,
    // unitTotal: 0,
    // suffix: "",
    // priceSubtotal: 0,
    flash: false,
    applyPriceInElevationPopoverVisible: false,
    unitTotal: null,
    suffix: null,
    priceSubtotal: null,
    averagePPU: null,
    rentTotal: null,
    flashes: { ...this.flashes },
    serviceId: this.props.gridData[this.props.serviceIndex].serviceId,
    isScope: this.props.gridData[this.props.serviceIndex]?.isScope ?? false,
  };

  componentDidMount() {
    // const {serviceIndex, elevation, gridData,} = this.props
    // const {unitTotal, suffix, priceSubtotal} = getElevationTotal(gridData[serviceIndex].serviceId, elevation.items ?? elevation.item, gridData[serviceIndex].pricePerUnit);
    // this.setState({unitTotal, suffix, priceSubtotal})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("prevProps", {
      prevProps: prevProps,
      props: this.props,
    });

    const { serviceIndex, gridData } = this.props;
    const tempServiceId = gridData[serviceIndex].serviceId;
    if (
      prevProps.elevation.desiredPrice !== this.props.elevation.desiredPrice
    ) {
      //if there are changes in desired price
      if (
        isNaN(this.props.elevation.desiredPrice) ||
        this.props.elevation.desiredPrice === Infinity
      ) {
        //this can happen only if desired price is changed somewhere else. It can't happen from this component inputs
        //because default value is 0
        this.setState({ desiredPrice: 0 }, () => {
          this.handlePriceRentChange("desiredPrice");
        }); //if we have invalid value, set it to 0
      } else this.setState({ desiredPrice: this.props.elevation.desiredPrice });
    }
    if (prevProps.elevation.price !== this.props.elevation.price) {
      if (
        isNaN(this.props.elevation.price) ||
        this.props.elevation.price === Infinity
      ) {
        this.setState({ price: 0 }, () => {
          this.handlePriceRentChange("price");
        });
      } else this.setState({ price: this.props.elevation.price });
    }
    if (prevProps.elevation.totalPrice !== this.props.elevation.totalPrice) {
      this.setState({ priceTotal: this.props.elevation.totalPrice });
    }
    if (
      prevProps.elevation.rent !== this.props.elevation.rent ||
      prevProps.elevation.rentalsTotal !== this.props.elevation.rentalsTotal
    ) {
      if (
        (tempServiceId !== 3 && isNaN(this.props.elevation.rent)) ||
        this.props.elevation.rent === Infinity
      ) {
        this.setState({ totalRent: 0 }, () => {
          this.handlePriceRentChange("rent");
        });
      } else
        this.setState({
          totalRent:
            tempServiceId !== 3
              ? this.props.elevation.rent
              : this.props.elevation.rentalsTotal,
        });
    }

    // if (
    //   prevProps.elevation.rentalsTotal !== this.props.elevation.rentalsTotal
    // ) {
    //   this.setState({ rent: this.props.elevation.rentalsTotal || 0 });
    // }

    //check for changes in elevation price
    // const { serviceIndex, gridData } = this.props;

    const prevPrices = getElevationTotal(
      gridData[serviceIndex].serviceId,
      prevProps.elevation.items
    );
    const currPrices = getElevationTotal(
      gridData[serviceIndex].serviceId,
      this.props.elevation.items
    );

    let hasChanges = false;
    let flashes = { ...this.flashes };
    for (const priceVariable of Object.keys(currPrices)) {
      if (
        prevPrices[priceVariable].toString() !==
        currPrices[priceVariable].toString()
      ) {
        flashes[priceVariable] = true;
        hasChanges = true;
      }
    }

    // console.log(hasChanges, this.state.flash, prevProps.elevation.items[0].rent, this.props.elevation.items[0].rent)
    if (hasChanges) {
      const { unitTotal, suffix, priceSubtotal, averagePPU, rentTotal } =
        currPrices;
      this.setState(
        { unitTotal, suffix, priceSubtotal, averagePPU, rentTotal, flashes },
        () => {
          setTimeout(() => {
            let flashes = { ...this.flashes };
            this.setState({ flashes });
          }, 2000);
        }
      );
    }
  }

  setPrices = () => {
    const { serviceIndex, elevation, gridData } = this.props;
    const { unitTotal, suffix, priceSubtotal, averagePPU, rentTotal } =
      getElevationTotal(gridData[serviceIndex].serviceId, elevation.items);

    this.setState({ unitTotal, suffix, priceSubtotal, averagePPU, rentTotal });
  };

  handleChange = (value, reference) => {
    if (isNaN(value) || value === Infinity || value < 0) value = 0;
    this.setState({ [reference]: value });
  };

  handlePriceRentChange = (reference) => {
    const {
      serviceIndex,
      optionIndex,
      elevationIndex,
      gridData,
      updateStateAndSave,
    } = this.props;
    const gridDataCopy = _.cloneDeep(gridData);
    gridDataCopy[serviceIndex].serviceOptions[optionIndex][elevationIndex][
      reference
    ] = parseFloat(this.state[reference]);
    updateStateAndSave(gridDataCopy);
  };

  reCalculatePLIPrices = (
    serviceIndex,
    optionIndex,
    elevationIndex,
    isWritable,
    gridDataFromState,
    updateStateAndSave,
    selectedPriceScheme,
    preventLocked,
    type,
    affectRent = true
  ) => {
    if (isWritable) {
      const gridData = _.cloneDeep(gridDataFromState);
      const elevation =
        gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
      const serviceId = gridData[serviceIndex].serviceId;
      console.log("pli elevation", serviceId);

      let priceRatio;
      let pliAddonPrice = elevation.items.reduce((acc, pli) => {
        return acc + forceToNumber(pli?.pliAddonTotal);
      }, 0);
      if (preventLocked === true) {
        //we need to subtract from all calculations locked PLI
        let lockedPLITotalPrice = 0;
        for (const pli of elevation.items) {
          if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
          // if (pli.lock !== true) continue;
          // lockedPLITotalPrice += parseFloat(pli.price);
        }

        if (isNaN(lockedPLITotalPrice)) {
          message.error(
            "Can't do this action. Please put correct price values in all approved PLI"
          );
          this.setState({ applyPriceInElevationPopoverVisible: false });
          return;
        }

        priceRatio =
          (forceToNumber(elevation.desiredPrice) - pliAddonPrice) /
          (getElevationTotal(serviceId, elevation.items).priceSubtotal -
            lockedPLITotalPrice);
      } else {
        //simple price ratio, based on total price
        priceRatio =
          (forceToNumber(elevation.desiredPrice) - pliAddonPrice) /
          getElevationTotal(serviceId, elevation.items).priceSubtotal;
      }
      console.log("priceStyle", { serviceId, priceRatio });

      switch (serviceId) {
        case 1:
        // for (const pli of elevation.items) {
        //   for (const addon of pli.addons) {
        //     if (addon?.spanPrice && addon?.approved) {
        //       pli.totalPrice += Number(addon?.spanPrice || 0);
        //       console.log("we are here", pli);
        //     }
        //   }
        // }
        // break;

        case 4:
        case 9:

        case 12:
        case 13: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / pli.length) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            // if (pli.lock === false)
            if (affectRent) {
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
            }
          }
          break;
        }
        case 10:
        case 18:
        case 19: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / pli.length) * pli.height * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 20: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI
            console.log("case20", priceRatio);
            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / pli.length) * pli.height * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 6:
        case 7:
        case 15: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / pli.length) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent) {
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
            }
          }
          break;
        }
        case 2: {
          for (const pli of elevation.items) {
            if (type === "approved" && pli.approved !== true)
              if (preventLocked === true && pli.lock === true)
                //dont count unapproved PLI
                continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = Math.round(price * 100) / 100;
            pli.ppu =
              Math.round((price / (pli.height * pli.length)) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice = pli.price + pli.taxAmount;
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 11: {
          for (const pli of elevation.items) {
            if (type === "approved" && pli.approved !== true)
              if (preventLocked === true && pli.lock === true)
                //dont count unapproved PLI
                continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = Math.round(price * 100) / 100;
            pli.ppu =
              Math.round((price / (pli.height * pli.length)) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice = pli.price + pli.taxAmount;
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 5:
          for (const pli of elevation.items) {
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI
            //dont count unapproved PLI
            let sqft = pli.length * pli.width;
            let price = pli.price * priceRatio;
            pli.price = Math.round(price * 100) / 100;
            pli.ppu = Math.round((price / sqft) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice = pli.price + pli.taxAmount;
            console.log("pliiii", pli);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
          for (const pli of elevation.items) {
            if (type === "approved" && pli.approved !== true)
              if (preventLocked === true && pli.lock === true)
                //dont count unapproved PLI
                continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = Math.round(price * 100) / 100;
            pli.ppu = Math.round((price / sqft) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice = pli.price + pli.taxAmount;
            console.log("pliiii", pli);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        case 44:
        case 8: {
          for (const pli of elevation.items) {
            if (type === "approved" && pli.approved !== true)
              if (preventLocked === true && pli.lock === true)
                //dont count unapproved PLI
                continue; //if we are preventing locked PLI
            console.log("pliRatio", {
              pli,
              priceRatio,
            });
            let price = pli.price * priceRatio;
            pli.price = Math.round(price * 100) / 100;
            pli.ppu = Math.round((price / pli.length) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice = pli.price + pli.taxAmount;
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 69: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / pli.length) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 70: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / pli.length) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 71: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / pli.length) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 14: {
          for (const pli of elevation.items) {
            if (type === "approved" && pli.approved !== true); //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = Math.round(price * 100) / 100;
            pli.ppu =
              Math.round((price / (pli.length * pli.height)) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice = pli.price + pli.taxAmount;
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 79: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / (pli.length * pli?.width)) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        case 73: {
          for (const pli of elevation.items) {
            console.log("pli", pli);
            console.log("elevation", elevation);
            if (type === "approved" && pli.approved !== true) continue; //dont count unapproved PLI
            if (preventLocked === true && pli.lock === true) continue; //if we are preventing locked PLI

            let price = pli.price * priceRatio;
            pli.price = (price * 100) / 100;
            pli.ppu = ((price / (pli.length * pli?.height)) * 100) / 100;
            pli.taxAmount = pliTaxAmountCalculate(pli);
            pli.totalPrice =
              pli.price + pli.taxAmount + forceToNumber(pli?.pliAddonTotal);
            if (affectRent)
              pli.rent =
                Math.round(
                  parseFloat(gridData[serviceIndex].rent) * pli.price
                ) / 100;
          }
          break;
        }
        default:
          break;
      }

      try {
        this.props.agGridApiForEachElevation[serviceId][optionIndex][
          elevationIndex
        ].api.setRowData(elevation.items);
        this.props.agGridApiForEachElevation[serviceId][optionIndex][
          elevationIndex
        ].api.redrawRows();
      } catch (e) {
        // console.warning(e);
      }

      updateStateAndSave(gridData);
    } else {
      message.error("Please enable write mode to make edits");
    }
    this.setState({ applyPriceInElevationPopoverVisible: false });
  };

  rentalRateApply = (rate) => {
    const {
      serviceIndex,
      gridData,
      optionIndex,
      elevationIndex,
      updateStateAndSave,
    } = this.props;

    const temp = _.cloneDeep(gridData);
    const elevation =
      temp[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    elevation.items.forEach((pli) => {
      let temp2 = (forceToNumber(rate) / 100) * pli.rent;
      pli.rent = forceToNumber(forceToNumber(pli.rent - temp2).toFixed(2));
    });

    updateStateAndSave(temp);
  };

  rentalDesiredApply = (rentDesired) => {
    const {
      serviceIndex,
      gridData,
      optionIndex,
      elevationIndex,
      updateStateAndSave,
    } = this.props;

    const temp = _.cloneDeep(gridData);
    const elevation =
      temp[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    // Step 1 - Calculate the total price

    const { highestPricePLI = {}, elPrice = 0 } = elevation.items.reduce(
      (acc, pli) => {
        if (forceToNumber(pli?.price) > forceToNumber(acc?.highestPrice)) {
          acc.highestPricePLI = pli;
          acc.highestPrice = pli?.price;
        }
        acc.elPrice += forceToNumber(pli?.price);
        return acc;
      },
      { highestPricePLI: null, highestPrice: -Infinity, elPrice: 0 }
    );
    const desiredRent = forceToNumber(rentDesired);
    let balance = 0;
    if (elPrice === 0 || desiredRent === 0) {
      message.error("Please configure price first");
      return;
    }
    elevation.items.forEach((pli) => {
      let tempPercentage =
        (forceToNumber(pli?.price) * 100) / forceToNumber(elPrice);
      let tempRent = (tempPercentage * desiredRent) / 100;
      pli.rent = forceToNumber(tempRent?.toFixed(2));
      balance += forceToNumber(tempRent?.toFixed(2));
    });
    if (balance !== desiredRent) {
      highestPricePLI.rent = (
        forceToNumber(highestPricePLI.rent) +
        forceToNumber(desiredRent - balance)
      )?.toFixed(2);
    }
    console.log("rentalDesiredApply", {
      balance,
      highestPricePLI,
      elevation,
    });

    updateStateAndSave(temp);
  };

  lockUnlockShortcut = (toLock) => {
    const {
      serviceIndex,
      gridData,
      optionIndex,
      elevationIndex,
      updateStateAndSave,
    } = this.props;

    const temp = _.cloneDeep(gridData);
    const elevation =
      temp[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    elevation.items.forEach((pli) => {
      pli.lock = toLock;
    });

    updateStateAndSave(temp);
    Swal.fire({
      icon: "success",
      title: `All PLI's are ${toLock ? "locked" : "unlocked"}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  rent_and_price = ({ elevation, isWritable }) => {
    let rent = elevation.rent;
    if (isNaN(rent)) {
      //if elevation rent in not available
      if (isNaN(elevation.price)) rent = 0;
      //set rent to 0 if elevation price is not available too
      else rent = Math.round(parseFloat(elevation.price) * 7) / 100; //set rent to 7% of elevation price
    }

    let rent_higher_than_price = "";
    if (parseFloat(this.state.rent) >= parseFloat(this.state.price))
      rent_higher_than_price = "rent-higher-than-price";

    return (
      <>
        {!!this.props.hasAccessRights && (
          <span
            className="priceCalculationInput"
            style={pricesStyle}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Tooltip title="Price for this elevation">
              <>
                <span className="price-input-label">Price $</span>
                <CurrencyInput
                  name="elevation-price"
                  className={rent_higher_than_price}
                  value={this.state.priceTotal}
                  disabled={
                    !isWritable ||
                    this.props.gridData[this.props.serviceIndex].serviceId === 3
                  }
                  onValueChange={(e) => {
                    console.log("service indexx", this.state);
                    /// If not Hoist!
                    if (
                      this.gridData[this.props.serviceIndex].serviceId !== 3
                    ) {
                      this.handleChange(e, "priceTotal");
                    } else {
                      message.error(
                        "You need further clearance to edit this field"
                      );
                    }
                  }}
                  onBlur={(e) => {
                    this.handlePriceRentChange("priceTotal");
                  }}
                  placeholder="Please enter a number"
                  // prefix={"Rent: "}
                  // prefix={"$"}
                  step={1}
                  decimalScale={2}
                  defaultValue={0}
                />
              </>
            </Tooltip>{" "}
            {!!this.props.hasAccessRights && (
              <span
                className="priceCalculationInput"
                style={pricesStyle}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Tooltip title="Rent for this elevation">
                  <>
                    <span className="price-input-label">Rent $</span>
                    <CurrencyInput
                      name="elevation-price"
                      className={rent_higher_than_price}
                      value={this.state.totalRent}
                      disabled={
                        !isWritable ||
                        this.props.gridData[this.props.serviceIndex]
                          .serviceId === 3
                      }
                      onValueChange={(e) => {
                        this.handleChange(e, "totalRent");
                      }}
                      onBlur={(e) => {
                        this.handlePriceRentChange("totalRent");
                      }}
                      placeholder="Please enter a number"
                      // prefix={"Rent: "}
                      // prefix={"$"}
                      step={1}
                      decimalScale={2}
                      defaultValue={0}
                    />
                  </>
                </Tooltip>
              </span>
            )}
          </span>
        )}
      </>
    );
  };

  onDivKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  getElevationSum = (elevation, key) => {
    console.log("elevation", elevation);
    return +elevation.items
      ?.reduce?.(
        (acc, curr) => (acc += !isNaN(+curr?.[key]) ? +curr?.[key] : 0),
        0
      )
      ?.toFixed?.(2);
  };

  render() {
    const {
      serviceIndex,
      optionIndex,
      elevationIndex,
      elevation,
      isWritable,
      gridData,
      updateStateAndSave,
      selectedPriceSchemesForService,
    } = this.props;

    let statisticsView;
    const { unitTotal, suffix, priceSubtotal, averagePPU, rentTotal } =
      getElevationTotal(gridData[serviceIndex].serviceId, elevation.items);
    // const {unitTotal, suffix, priceSubtotal} = this.state

    const service = gridData[serviceIndex];
    const thisElevation =
      service.serviceOptions?.[optionIndex]?.[elevationIndex];

    const totalPrice = this.getElevationSum(thisElevation, "totalPrice");
    console.log("thisElevation", thisElevation);

    const totalTax = this.getElevationSum(thisElevation, "taxAmount");
    const totalHoistTax = thisElevation?.taxAmount || 0;
    const { taxRate = 0, isTaxable } = service;

    let flashes = this.state.flashes; //"false"
    // if(this.priceSubtotal !== priceSubtotal) {
    //     flash = true;
    //     this.priceSubtotal = priceSubtotal
    // }

    let rent_higher_than_price = "";
    if (rentTotal >= priceSubtotal && rentTotal !== 0)
      rent_higher_than_price = "rent-higher-than-price";

    statisticsView = (
      <span className="elevation-total" style={{ display: "flex" }}>
        {unitTotal === undefined && suffix === undefined ? (
          ""
        ) : (
          <span className={"elevation-total-element"}>
            <b
              className={
                "elevation-total-length " + "flash-" + flashes["unitTotal"]
              }
            >
              {wrapWithErrorCalculating(unitTotal)}
            </b>{" "}
            {suffix}
            {gridData[serviceIndex].serviceId === 2
              ? ` | Unit AV. ${
                  totalPrice / unitTotal > 0 && unitTotal > 0
                    ? (totalPrice / unitTotal)?.toFixed?.(2)
                    : ""
                }`
              : 0}{" "}
          </span>
        )}
        {priceSubtotal === undefined ? (
          ""
        ) : (
          <span className={"elevation-total-element " + rent_higher_than_price}>
            {/* Price total (inc. tax):{" "} */}
            <span
              className={
                "elevation-total-priceSubtotal " +
                "flash-" +
                flashes["priceSubtotal"]
              }
            >
              {/* {wrapWithErrorCalculating(priceSubtotal, true)} */}

              {formatCurrency(totalPrice)}
            </span>
          </span>
        )}
        <span className={"elevation-total-element " + rent_higher_than_price}>
          Tax:{" "}
          <span
            className={
              "elevation-total-priceSubtotal " +
              "flash-" +
              flashes["priceSubtotal"]
            }
          >
            {/* {wrapWithErrorCalculating(priceSubtotal, true)} */}
            {!checkIfServiceIsHoist(service)
              ? formatCurrency(totalTax)
              : formatCurrency(totalHoistTax)}
          </span>
        </span>
        {rentTotal === undefined
          ? ""
          : !service.isScope && (
              <span
                className={"elevation-total-element " + rent_higher_than_price}
              >
                Rent:{" "}
                <span
                  className={
                    "elevation-total-priceSubtotal " +
                    "flash-" +
                    flashes["rentTotal"]
                  }
                >
                  {wrapWithErrorCalculating(rentTotal, true, true)}
                </span>
              </span>
            )}
        {averagePPU === undefined ? (
          ""
        ) : (
          <span className={"elevation-total-element"}>
            Avg PPU:{" "}
            <span
              className={
                "elevation-total-priceSubtotal " +
                "flash-" +
                flashes["averagePPU"]
              }
            >
              {wrapWithErrorCalculating(averagePPU, true)}
            </span>
          </span>
        )}
      </span>
    );

    const selectedPriceScheme =
      selectedPriceSchemesForService?.[gridData?.[serviceIndex]?.serviceId]
        ?.priceScheme;
    let priceInputs = "";
    console.log("serviceId", gridData[serviceIndex].serviceId);

    switch (gridData[serviceIndex].serviceId) {
      case 3:
        return;
      case 38:
      case 11:
      case 13:
        priceInputs = (
          <div
            className="priceCalculationInput"
            style={pricesStyle}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Tooltip title="Desired total price without tax for this elevation">
              <>
                <span className="price-input-label">Desired $</span>
                <CurrencyInput
                  name="elevation-price"
                  disabled={!isWritable}
                  className={rent_higher_than_price}
                  value={this.state.desiredPrice}
                  onValueChange={(e) => {
                    this.handleChange(e, "desiredPrice");
                  }}
                  onBlur={(e) => {
                    this.handlePriceRentChange("desiredPrice");
                  }}
                  placeholder="Please enter a number"
                  // prefix={"Rent: "}
                  // prefix={"$"}
                  step={1}
                  decimalScale={2}
                  defaultValue={0}
                />
              </>
            </Tooltip>
            <WithTooltip
              tooltipCategory="Take Off"
              tooltipKey="forceApplyPrice"
            >
              <Popover
                className="forcePriceBtn"
                title={
                  "This will recalculate all PLI prices and price per units for this elevation. Do you want to continue?"
                }
                trigger={"click"}
                visible={this.state.applyPriceInElevationPopoverVisible}
                onVisibleChange={(visible) => {
                  this.setState({
                    applyPriceInElevationPopoverVisible: visible,
                  });
                }}
                content={
                  <div className={"floor-height-popover-content"}>
                    <Button
                      onClick={(e) => {
                        this.setState({
                          applyPriceInElevationPopoverVisible: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          true,
                          "unlocked"
                        );
                      }}
                    >
                      Apply only to unlocked PLI's
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "approved"
                        );
                      }}
                    >
                      Apply to all Approved PLI's
                    </Button>{" "}
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "all"
                        );
                      }}
                    >
                      Apply to all PLI's
                    </Button>
                  </div>
                }
              >
                <span
                  style={{
                    width: "32px",
                    height: "32px",
                    background: "#1264A3",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ForcePriceIcon />
                </span>
                {/* <Button className="forcePriceBtn" style={{
                
              }}>
                {" "}
                <img
                  className="data-grid-icon-button-img"
                  alt="force apply"
                  src={Force}
                />{" "}
              </Button> */}
              </Popover>
            </WithTooltip>
          </div>
        );
        break;
      case 16:
      case 17:
      case 19:
      // case 20: {
      //   priceInputs = this.rent_and_price({ elevation, isWritable });
      //   break;
      // }
      case 1:
      case 2:
      case 4:
      case 9:
      case 10:
      case 5:
      case 44:
      case 6:
      case 7:
      case 8:
      case 12:
      case 69:
      case 14:
      case 15:
      case 20:
      case 18: {
        //for some services rent and price is defined in elevation level
        priceInputs = !!this.props.hasAccessRights && (
          <div
            className="priceCalculationInput"
            style={pricesStyle}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <WithTooltip
              tooltipCategory="Take Off"
              tooltipKey="elevationPriceInput"
            >
              <span className="price-input-label">$</span>
              <CurrencyInput
                name="elevation-price"
                disabled={!isWritable}
                className={rent_higher_than_price}
                value={this.state.desiredPrice}
                onValueChange={(e) => {
                  this.handleChange(e, "desiredPrice");
                }}
                onBlur={(e) => {
                  this.handlePriceRentChange("desiredPrice");
                }}
                placeholder="Please enter a number"
                // prefix={"Rent: "}
                // prefix={"$"}
                step={1}
                decimalScale={2}
                defaultValue={0}
              />
            </WithTooltip>
            <WithTooltip
              tooltipCategory="Take Off"
              tooltipKey="forceApplyPrice"
            >
              <Popover
                style={{
                  padding: "20px",
                }}
                title={
                  <div
                    style={{
                      padding: "20px",
                    }}
                  >
                    {" "}
                    This will recalculate all PLI prices and price per units for
                    this elevation. Do you want to continue?
                  </div>
                }
                trigger={"click"}
                visible={this.state.applyPriceInElevationPopoverVisible}
                onVisibleChange={(visible) => {
                  this.setState({
                    applyPriceInElevationPopoverVisible: visible,
                  });
                }}
                content={
                  <div className={"floor-height-popover-content"}>
                    <Button
                      onClick={(e) => {
                        this.setState({
                          applyPriceInElevationPopoverVisible: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "all",
                          false
                        );
                      }}
                    >
                      Apply to all PLI's (keep same rent)
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          true,
                          "unlocked"
                        );
                      }}
                    >
                      Apply only to unlocked PLI's
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "approved"
                        );
                      }}
                    >
                      Apply to all Approved PLI's
                    </Button>{" "}
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "all"
                        );
                      }}
                    >
                      Apply to all PLI's
                    </Button>{" "}
                  </div>
                }
              >
                <span
                  style={{
                    width: "32px",
                    height: "32px",
                    background: "#1264A3",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ForcePriceIcon />
                </span>
                {/* <Button className="forcePriceBtn" style={{
                  
                }}>
                  {" "}
                  <img
                    className="data-grid-icon-button-img"
                    alt="force apply"
                    src={Force}
                  />{" "}
                </Button> */}
              </Popover>
            </WithTooltip>
          </div>
        );
        break;
      }
      // case 20: {
      //   //for some services rent and price is defined in elevation level
      //   priceInputs = !!this.props.hasAccessRights && (
      //     <div
      //       className="priceCalculationInput"
      //       style={pricesStyle}
      //       onClick={(event) => {
      //         event.stopPropagation();
      //       }}
      //     >
      //       <Tooltip title="Desired total price for this elevation">
      //         <>
      //           <span className="price-input-label">$</span>
      //           <CurrencyInput
      //             name="elevation-price"
      //             disabled={!isWritable}
      //             className={rent_higher_than_price}
      //             value={this.state.desiredPrice}
      //             onValueChange={(e) => {
      //               this.handleChange(e, "desiredPrice");
      //             }}
      //             onBlur={(e) => {
      //               this.handlePriceRentChange("desiredPrice");
      //             }}
      //             placeholder="Please enter a number"
      //             // prefix={"Rent: "}
      //             // prefix={"$"}
      //             step={1}
      //             decimalScale={2}
      //             defaultValue={0}
      //           />
      //         </>
      //       </Tooltip>
      //       <Tooltip title={"Force apply this price"} placement={"bottom"}>
      //         <Popover
      //           style={{
      //             padding: "20px",
      //           }}
      //           title={
      //             <div
      //               style={{
      //                 padding: "20px",
      //               }}
      //             >
      //               {" "}
      //               This will recalculate all PLI prices and price per units for
      //               this elevation. Do you want to continue?
      //             </div>
      //           }
      //           trigger={"click"}
      //           visible={this.state.applyPriceInElevationPopoverVisible}
      //           onVisibleChange={(visible) => {
      //             this.setState({
      //               applyPriceInElevationPopoverVisible: visible,
      //             });
      //           }}
      //           content={
      //             <div className={"floor-height-popover-content"}>
      //               <Button
      //                 onClick={(e) => {
      //                   this.setState({
      //                     applyPriceInElevationPopoverVisible: false,
      //                   });
      //                 }}
      //               >
      //                 Cancel
      //               </Button>{" "}
      //               <Button
      //                 type={"primary"}
      //                 onClick={(e) => {
      //                   this.reCalculatePLIPrices(
      //                     serviceIndex,
      //                     optionIndex,
      //                     elevationIndex,
      //                     isWritable,
      //                     gridData,
      //                     updateStateAndSave,
      //                     selectedPriceScheme,
      //                     false,
      //                     "all",
      //                     false
      //                   );
      //                 }}
      //               >
      //                 Apply to all PLI's (keep same rent)
      //               </Button>
      //               <Button
      //                 type={"primary"}
      //                 onClick={(e) => {
      //                   this.reCalculatePLIPrices(
      //                     serviceIndex,
      //                     optionIndex,
      //                     elevationIndex,
      //                     isWritable,
      //                     gridData,
      //                     updateStateAndSave,
      //                     selectedPriceScheme,
      //                     true,
      //                     "unlocked"
      //                   );
      //                 }}
      //               >
      //                 Apply only to unlocked PLI's
      //               </Button>
      //               <Button
      //                 type={"primary"}
      //                 onClick={(e) => {
      //                   this.reCalculatePLIPrices(
      //                     serviceIndex,
      //                     optionIndex,
      //                     elevationIndex,
      //                     isWritable,
      //                     gridData,
      //                     updateStateAndSave,
      //                     selectedPriceScheme,
      //                     false,
      //                     "approved"
      //                   );
      //                 }}
      //               >
      //                 Apply to all Approved PLI's
      //               </Button>{" "}
      //               <Button
      //                 type={"primary"}
      //                 onClick={(e) => {
      //                   this.reCalculatePLIPrices(
      //                     serviceIndex,
      //                     optionIndex,
      //                     elevationIndex,
      //                     isWritable,
      //                     gridData,
      //                     updateStateAndSave,
      //                     selectedPriceScheme,
      //                     false,
      //                     "all"
      //                   );
      //                 }}
      //               >
      //                 Apply to all PLI's
      //               </Button>{" "}
      //             </div>
      //           }
      //         >
      //           <span
      //             style={{
      //               width: "32px",
      //               height: "32px",
      //               background: "#1264A3",
      //               borderRadius: "5px",
      //               display: "flex",
      //               justifyContent: "center",
      //               alignItems: "center",
      //             }}
      //           >
      //             <ForcePriceIcon />
      //           </span>
      //           {/* <Button className="forcePriceBtn" style={{

      //           }}>
      //             {" "}
      //             <img
      //               className="data-grid-icon-button-img"
      //               alt="force apply"
      //               src={Force}
      //             />{" "}
      //           </Button> */}
      //         </Popover>
      //       </Tooltip>
      //     </div>
      //   );
      //   break;
      // }
      case 79: {
        //for some services rent and price is defined in elevation level
        priceInputs = !!this.props.hasAccessRights && (
          <div
            className="priceCalculationInput"
            style={pricesStyle}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <WithTooltip
              tooltipCategory="Take Off"
              tooltipKey="elevationPriceInput"
            >
              <span className="price-input-label">$</span>
              <CurrencyInput
                name="elevation-price"
                disabled={!isWritable}
                className={rent_higher_than_price}
                value={this.state.desiredPrice}
                onValueChange={(e) => {
                  this.handleChange(e, "desiredPrice");
                }}
                onBlur={(e) => {
                  this.handlePriceRentChange("desiredPrice");
                }}
                placeholder="Please enter a number"
                // prefix={"Rent: "}
                // prefix={"$"}
                step={1}
                decimalScale={2}
                defaultValue={0}
              />
            </WithTooltip>
            <WithTooltip
              tooltipCategory="Take Off"
              tooltipKey="forceApplyPrice"
            >
              <Popover
                style={{
                  padding: "20px",
                }}
                title={
                  <div
                    style={{
                      padding: "20px",
                    }}
                  >
                    {" "}
                    This will recalculate all PLI prices and price per units for
                    this elevation. Do you want to continue?
                  </div>
                }
                trigger={"click"}
                visible={this.state.applyPriceInElevationPopoverVisible}
                onVisibleChange={(visible) => {
                  this.setState({
                    applyPriceInElevationPopoverVisible: visible,
                  });
                }}
                content={
                  <div className={"floor-height-popover-content"}>
                    <Button
                      onClick={(e) => {
                        this.setState({
                          applyPriceInElevationPopoverVisible: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "all",
                          false
                        );
                      }}
                    >
                      Apply to all PLI's (keep same rent)
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          true,
                          "unlocked"
                        );
                      }}
                    >
                      Apply only to unlocked PLI's
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "approved"
                        );
                      }}
                    >
                      Apply to all Approved PLI's
                    </Button>{" "}
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "all"
                        );
                      }}
                    >
                      Apply to all PLI's
                    </Button>{" "}
                  </div>
                }
              >
                <span
                  style={{
                    width: "32px",
                    height: "32px",
                    background: "#1264A3",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ForcePriceIcon />
                </span>
                {/* <Button className="forcePriceBtn" style={{
                    
                  }}>
                    {" "}
                    <img
                      className="data-grid-icon-button-img"
                      alt="force apply"
                      src={Force}
                    />{" "}
                  </Button> */}
              </Popover>
            </WithTooltip>
          </div>
        );
        break;
      }
      case 73: {
        //for some services rent and price is defined in elevation level
        priceInputs = !!this.props.hasAccessRights && (
          <div
            className="priceCalculationInput"
            style={pricesStyle}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <WithTooltip
              tooltipCategory="Take Off"
              tooltipKey="elevationPriceInput"
            >
              <span className="price-input-label">$</span>
              <CurrencyInput
                name="elevation-price"
                disabled={!isWritable}
                className={rent_higher_than_price}
                value={this.state.desiredPrice}
                onValueChange={(e) => {
                  this.handleChange(e, "desiredPrice");
                }}
                onBlur={(e) => {
                  this.handlePriceRentChange("desiredPrice");
                }}
                placeholder="Please enter a number"
                // prefix={"Rent: "}
                // prefix={"$"}
                step={1}
                decimalScale={2}
                defaultValue={0}
              />
            </WithTooltip>
            <WithTooltip
              tooltipCategory="Take Off"
              tooltipKey="forceApplyPrice"
            >
              <Popover
                style={{
                  padding: "20px",
                }}
                title={
                  <div
                    style={{
                      padding: "20px",
                    }}
                  >
                    {" "}
                    This will recalculate all PLI prices and price per units for
                    this elevation. Do you want to continue?
                  </div>
                }
                trigger={"click"}
                visible={this.state.applyPriceInElevationPopoverVisible}
                onVisibleChange={(visible) => {
                  this.setState({
                    applyPriceInElevationPopoverVisible: visible,
                  });
                }}
                content={
                  <div className={"floor-height-popover-content"}>
                    <Button
                      onClick={(e) => {
                        this.setState({
                          applyPriceInElevationPopoverVisible: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "all",
                          false
                        );
                      }}
                    >
                      Apply to all PLI's (keep same rent)
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          true,
                          "unlocked"
                        );
                      }}
                    >
                      Apply only to unlocked PLI's
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "approved"
                        );
                      }}
                    >
                      Apply to all Approved PLI's
                    </Button>{" "}
                    <Button
                      type={"primary"}
                      onClick={(e) => {
                        this.reCalculatePLIPrices(
                          serviceIndex,
                          optionIndex,
                          elevationIndex,
                          isWritable,
                          gridData,
                          updateStateAndSave,
                          selectedPriceScheme,
                          false,
                          "all"
                        );
                      }}
                    >
                      Apply to all PLI's
                    </Button>{" "}
                  </div>
                }
              >
                <span
                  style={{
                    width: "32px",
                    height: "32px",
                    background: "#1264A3",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ForcePriceIcon />
                </span>
                {/* <Button className="forcePriceBtn" style={{
                    
                  }}>
                    {" "}
                    <img
                      className="data-grid-icon-button-img"
                      alt="force apply"
                      src={Force}
                    />{" "}
                  </Button> */}
              </Popover>
            </WithTooltip>
          </div>
        );
        break;
      }
      default:
        break;
    }

    return (
      <div className="elevation-panel-price-container">
        <div
          className="statistic-view"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {" "}
          {statisticsView}
        </div>
        <div
          className="price-input"
          style={{ display: "flex", alignItems: "center" }}
        >
          {" "}
          {priceInputs}{" "}
          {this?.state?.serviceId !== 3 && !this?.state?.isScope && (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                gap: "5px",
              }}
            >
              <Tooltip title={"Apply rent rate to all elevations"}>
                <ApplyRentRateAction
                  rentalRateApply={this.rentalRateApply}
                  rentTotal={Math.round(rentTotal)}
                  isWritable={this.isWritable}
                  rentalDesiredApply={this.rentalDesiredApply}
                />
              </Tooltip>{" "}
              {/* <Tooltip title={"Unlock All Elevations"}>
                <MondayButton
                  className="mondayButtonGreen"
                  hasIcon={false}
                  onClick={() => this.lockUnlockShortcut(false)}
                >
                  <UnlockFilled />
                </MondayButton>{" "}
              </Tooltip>
              <Tooltip title={"Lock All Elevations"}>
                <MondayButton
                  className="mondayButtonRed"
                  hasIcon={false}
                  onClick={() => this.lockUnlockShortcut(true)}
                >
                  <LockFilled />
                </MondayButton>
              </Tooltip>{" "} */}
              {/* <Tooltip title={"Fill from text"}>
                <AutoFiller
                  {...{
                    serviceId: serviceIndex,
                    elevationIndex: elevationIndex,
                  }}
                />
              </Tooltip> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const pricesStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  marginTop: "10px",
  paddingTop: "10px",
  borderTop: "1px solid grey",
};

const ApplyRentRateAction = ({
  rentalRateApply,
  // isWritable,
  rentTotal = 0,
  rentalDesiredApply,
}) => {
  const [state, setState] = useState(false);
  const [value, setValue] = useState(rentTotal || 0);
  const [mode, setMode] = useState("amount"); // other is percentage
  const [isWritable] = useRedux("estimationsIsWritable");
  const [darkMode] = useRedux("estimationsDarkMode");

  const onApplyNow = () => {
    rentalRateApply(value);
    setState(false);
  };

  const onDesiredRentApply = () => {
    rentalDesiredApply(value);
  };

  const onAction = () => {
    if (!isWritable) {
      message.error("You don't have permission to edit this");
      return;
    } else {
      if (mode !== "amount") {
        rentalRateApply(value);
        setState(false);
      } else {
        rentalDesiredApply(value);
        setState(false);
      }
    }
  };

  return (
    <div>
      <div
        style={
          !state
            ? {}
            : {
                padding: "3px",
                border: "2px solid #ff0000",
                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px #ff0000",
              }
        }
      >
        <MondayButton
          className="mondayButtonBlue"
          onClick={() => setState(true)}
          Icon={<>$</>}
          tooltipCategory="Take Off"
          tooltipKey="desiredRent"
        >
          Desired Rent
        </MondayButton>
      </div>
      {/* <CurrencyInput
        name="elevation-price"
        disabled={!isWritable}
        className={"rent-higher-than-price"}
        value={desiredRent}
        onValueChange={(e) => {
          this.handleChange(e, "desiredPrice");
        }}
        // onBlur={(e) => {
        //   this.handlePriceRentChange("desiredPrice");
        // }}
        placeholder="Please enter a number"
        // prefix={"Rent: "}
        // prefix={"$"}
        step={1}
        decimalScale={2}
        defaultValue={0}
      /> */}

      {state && (
        <NormalSizedModal
          visible={state}
          mask={false}
          className={darkMode ? "desired-rent-modal__dark" : ""}
          maskClosable={true}
          title="Desired Rent & Rental Rate"
          customFooter={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: 20,
              }}
            >
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseIconModal />}
                onClick={() => setState(false)}
              >
                Close
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                hasIcon={false}
                onClick={() => {
                  try {
                    if (mode === "amount") {
                      setMode("percentage");
                      setValue(0);
                    } else {
                      setMode("amount");
                      setValue(forceToNumber(rentTotal?.toFixed(2) || 0));
                    }
                  } catch (e) {
                    console.error("error switching price/perc", e);
                  } finally {
                    setValue(0);
                  }
                }}
              >
                {mode === "amount"
                  ? "Switch to Percentage"
                  : "Switch to Amount"}
              </MondayButton>
              <MondayButton onClick={onAction} Icon={<SaveIcon />}>
                APPLY NOW
              </MondayButton>
            </div>
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <strong>Please pick the particulate amount for this Rental </strong>{" "}
            <br />{" "}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <InputNumber
                prefix={mode === "amount" ? "$" : "%"}
                value={value}
                defaultValue={rentTotal}
                onChange={(e) => {
                  setValue(e);
                }}
                min={mode === "amount" ? 0 : -Infinity}
                max={mode === "amount" ? 999999999 : 1000}
              />
            </div>
            <br />
          </div>
        </NormalSizedModal>
      )}
    </div>
  );
};
