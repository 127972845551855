import React from "react";

function FullPreview({ pdfUrl }) {
  return (
    <div
      style={{
        width: "650px",
        height: "720px",
        marginTop: "10px",
        border: "1px solid #ccc",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      <iframe
        src={pdfUrl}
        style={{ width: "100%", height: "100%", border: "none" }}
        title="PDF Preview"
      ></iframe>
    </div>
  );
}

export default FullPreview;
