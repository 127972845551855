import "./BoardingSteps.scss";
import { ArrowRight } from "../../assets/icons";
import { useOnBoardingContext } from "../../OnBoardingContext";
import { useSelector } from "react-redux";
import { message } from "antd";
import { useState } from "react";

const BoardingSteps = () => {
  const { currentStep, setCurrentStep, boardingSteps, boardingStepRef } =
    useOnBoardingContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const stepConfigs = userConfiguration?.boardingConfigs || {};

  const filledSteps = Object.keys(stepConfigs).length;

  const [stepToShake, setStepToShake] = useState(false);
  const [biggestStep, setBiggestStep] = useState(filledSteps);

  if (currentStep > biggestStep) {
    setBiggestStep(currentStep);
  }

  const handleScroll = (e) => {
    const delta = Math.max(-1, Math.min(1, e.deltaY || -e.detail));
    const scrollAmount = delta * 200;
    const newScrollLeft = boardingStepRef.current.scrollLeft + scrollAmount;

    boardingStepRef.current.scrollTo?.({
      left: newScrollLeft,
      behavior: "smooth",
    });
  };

  const onStepClicked = (index, key) => {
    const { skipped, finished } = stepConfigs?.[key] || {};

    if (!!finished) {
      message.info("This step is completed!");
    } else {
      if (
        filledSteps + 1 === boardingSteps.length || //if last step
        filledSteps === index || // if clicking step that needs to be filled
        skipped
      ) {
        setCurrentStep(index);
      } else {
        setStepToShake(biggestStep);

        // Reset shaking state after animation duration
        let timeoutRef = setTimeout(() => {
          setStepToShake(false);
          clearTimeout(timeoutRef);
        }, 700);

        message.destroy();
        message.info(`Skip or Next step ${currentStep + 1} to proceed!`, 5);
      }
    }
  };

  return (
    <div
      data-testid="boarding-steps-testid"
      className={`boarding-steps ${isDarkMode ? "dark-mode" : ""}`}
      ref={boardingStepRef}
      onWheel={handleScroll}
    >
      {boardingSteps.map(({ key, icon, title, description }, index) => {
        const isFinished = !!stepConfigs?.[key]?.finished;
        return (
          <div
            key={index}
            className={`boarding-step ${
              currentStep === index ? "selected-step" : ""
            }`}
          >
            <div
              data-testid={`${key}-step`}
              className={`step-container ${
                stepToShake === index ? "shake" : ""
              }`}
              onClick={() => {
                onStepClicked(index, key);
              }}
              style={isFinished ? { cursor: "default" } : {}}
            >
              <div
                className={`step-icon ${
                  title === "Dashboard" ? "dashboard-icon" : ""
                }`}
                style={isFinished ? { opacity: 0.5 } : {}}
              >
                {icon}
              </div>

              {isFinished ? (
                <div className="step-status">Completed</div>
              ) : (
                <div className="step-content">
                  <div className="step-title">{title}</div>
                  <div className="step-description">{description} </div>
                </div>
              )}
            </div>

            {index !== boardingSteps.length - 1 && (
              //hide arrow for last progress
              <div className="step-arrow">
                <ArrowRight />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BoardingSteps;
