// import { MondayButton } from "../../../../../../../../../../../../../../../../../commonComponents"
// import { Close } from "../../../../../../../../../../../../../../../../DynamicView/src"
// import { useStateContext } from "../../../../../../../../../../../../Context/Context"
import { useRedux } from "../../../../../../../../../../../../../../../../hooks"
import "./DetailsHeader.scss"

const DetailsHeader = ({ allRow, elevationData, selectedWeek }) => {
  // const { darkMode, fakeRentals, selectedService } = useStateContext()

  const [selectedService] = useRedux("rentalsSelectedService")
  const [darkMode] = useRedux("rentalsDarkMode")
  const { elevationLabel } = elevationData || {}

  const tittle =
    selectedService === "Hoist"
      ? `${elevationLabel} - ${allRow?.elevationLabel} ${
          !!selectedWeek ? ` - Week ${selectedWeek?.week}` : ""
        }`
      : `${selectedService} - ${elevationLabel} - Pli ${allRow?.id}  ${
          !!selectedWeek ? ` - Week ${selectedWeek?.week}` : ""
        }`

  return (
    <div className={darkMode ? "rentalDetailsHeaderDark" : "rentalDetailsHeaderLight"}>
      <div className="tittle">{tittle}</div>
    </div>
  )
}

export default DetailsHeader
