import IndividualLoader from "src/components/IndividualLoader";
import { compareIncluding } from "../../../SidebarPages/utils";
import { FormGridStatusRenderer } from "../Components";
import { UploadedFilesRenderer } from "./components";
import dayjs from "dayjs";
import debounce from "lodash.debounce";
import {
  handlePopupScroll,
  searchFetchInput,
} from "src/utils/searchFetchForInput";
import { MondayButton } from "../../../commonComponents";
import { PlusIcon } from "../../../../assets";
import AlternativeAddressSelect from "./components/AlternativeAddressSelect";
import JobsiteAddressField from "./components/JobsiteAddressField";

const drawingTypes = [
  "Initial Drawing",
  "Amendment Drawing",
  "Review",
  "Supersede",
];

export const footerButtons = ({
  onCancel,
  onSave,
  saving,
  onOptonClicked,
  suffix,
}) => [
  {
    text: "cancel",
    cancel: true,
    onClick: onCancel,
  },

  {
    text: "Finish",
    primary: true,
    onClick: onSave,
    disabled: !!saving,
    options: ["Save & Close"],
    onOptonClicked: onOptonClicked,
    suffix,
  },
];

export const fieldsJSON = ({
  form,
  services = [],
  serviceOptions = [],
  projects = [],
  disabled = false,
  projectId,
  permitId,
  projectNames,
  engineersOptions,
  engineerPopoverVisible,
  EngineerPopoverContent,
  setSelectedProject,
  setSelectedDrawingType,
  filterGrid,
  allDrawingsGridApi,
  selectedDrawingType,
  setSelectedServices,
  setSelectedEngineer,
  pExecutivesNames,
  projectManagersOptions,
  projectPopoverVisible,
  ProjectPopoverContent,
  permitDrawingTeam,
  onSelect,
  onDeselect,
  setSelectedTeam,
  isDarkMode,
  setProjectManagers,
  allTeams,
  setProjects = () => {},
  loadingProjects = false,
  setLoadingProjects = () => {},
  setCurrentPageKey = () => {},
  currentPageKey = null,
  userConfiguration = {},
  setNonUserModal = () => {},
  selectedProject,
  setSelectedAltAddresses,
  selectedAltAddresses,
}) => [
  {
    category: "permitDrawingType",
    fields: [
      {
        formItemName: "drawingType",
        type: "radio",
        options: drawingTypes,
        disabled: !!permitId,
        required: true,
        onChange: ({ target: { value } }) => setSelectedDrawingType(value),
      },
    ],
  },
  {
    category: "inputs",
    fields: [
      {
        customComponent: JobsiteAddressField,
        componentProps: {
          form,
          userConfiguration,
          isDarkMode,
          projectNames,
          selectedDrawingType,
          projectId,
          setSelectedProject,
          projects,
          projectPopoverVisible,
          ProjectPopoverContent,
          setLoadingProjects,
          setProjects,
          setCurrentPageKey,
          currentPageKey,
          loadingProjects,
          allTeams,
          setSelectedTeam,
          selectedProject,
        },
      },

      selectedProject?.projectId && {
        customComponent: AlternativeAddressSelect,
        componentProps: {
          setSelectedAltAddresses,
          selectedAltAddresses,
          selectedProject,
        },
      },
      {
        disabled: true,
        label: "Project Executive",
        formItemName: "projExecutive",
        dropDownClassName: isDarkMode && "darkDropDown",
        placeholder: "Select...",
        type: "select",
        required: true,
        showSearch: true,
        options: pExecutivesNames,
        style: { width: "49%" },
      },
      {
        className: "engAssignedTo",
        disabled: false,
        label: "Eng. Assigned To",
        formItemName: "assignedTo",
        dropDownClassName: isDarkMode && "darkDropDown",
        placeholder: "Select...",
        type: "select",
        required: true,
        showSearch: true,
        customOptions: engineersOptions,
        onSelect: (value, option) => {
          filterGrid({
            column: "assignedTo",
            current: value,
            gridApi: allDrawingsGridApi,
          });
          form.setFieldsValue({ permitEngineerFilter: value });
          setSelectedEngineer(option);
        },
        dropdownRender: (menu) => {
          return (
            <>
              {menu}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <MondayButton
                  onClick={() => setNonUserModal(true)}
                  Icon={<PlusIcon />}
                >
                  New
                </MondayButton>
              </div>
            </>
          );
        },
        popoverVisible: engineerPopoverVisible,
        popoverContent: EngineerPopoverContent,
        popoverPlacement: "top",
        style: { width: "48%" },
      },
      {
        label: "Project Manager",
        formItemName: "projectManager",
        placeholder: "Select...",
        dropDownClassName: isDarkMode && "darkDropDown",
        type: "select",
        mode: "multiple",
        showSearch: true,
        // required: true,
        customOptions: projectManagersOptions,
        style: { width: "100%" },
        onChange: (val, option) => {
          form.setFieldValue(
            "projectManager",
            option.map((pM) => {
              const { label, value, cognitoUserId } = pM;
              return { label, value, cognitoUserId };
            })
          );
          setProjectManagers(
            option.map((pM) => {
              const { label, value, cognitoUserId, nameOfUser, IdentityId } =
                pM;
              return { label, value, cognitoUserId, nameOfUser, IdentityId };
            })
          );
        },
      },
      {
        label: "Team",
        placeholder: "Select team...",
        formItemName: "permitTeam",
        dropDownClassName: isDarkMode && "darkDropDown",
        type: "select",
        required: true,
        allowClear: false,
        customOptions: permitDrawingTeam,
        mode: "multiple",
        onSelect: onSelect,
        onDeselect: onDeselect,
        style: { width: "100%" },
      },
      {
        disabled,
        label: "Notes",
        placeholder: "Notes",
        formItemName: "permitNote",
        type: "textArea",
        rows: 1,
        style: { width: "100%" },
      },
    ].filter(Boolean),
  },

  {
    category: "services",
    fields: services?.map((service) => ({
      label: serviceOptions.includes(service) ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {service}
          <p style={{ color: "red", margin: 0 }}>*</p>
        </div>
      ) : (
        service
      ),
      type: "checkbox",
      formItemName: service,
      onChange: () => {
        const selectedServices = services?.filter(
          (service) => !!form.getFieldValue(service)
        );

        console.log("selectedServices", selectedServices);

        setSelectedServices(selectedServices);
      },
    })),
  },
];

export const body = ({
  drawingType,
  projectManager,
  assignedTo,
  projectExecutive,
  form,
  initialDrawingId,
  drawingAssignedTo,
  projectId,
  sow,
  permitCompany,
  requestId = "",
  teams,
  jobSiteAddress,
}) => ({
  requestId,
  fromRequest: !!requestId,
  initialDrawingId,
  drawingAssignedTo,
  projectId,
  sow,
  jobSiteAddress,
  permitCompany,
  googleDriveUploads: [],
  amendmentDriveUploads: [],
  permitNote:
    !!form.getFieldValue("permitNote") &&
    `<p>${form.getFieldValue("permitNote")}</p>`,
  drawingType,
  // jobSiteAddress: form.getFieldValue("jobSiteAddress"),
  projectExecutive,
  projectManager,
  assignedTo,
  permitStatus: compareIncluding(drawingType, "amendment drawing")
    ? "Amendment"
    : "Not Started",
  servicePDStatus: "Not Started",
  teamsConfiguration: teams,
});

const projectStatuses = [
  { status: "Amendment", color: "#9EDE73" },
  {
    status: "Under Review",
    color: "#FF9C46",
  },
  {
    status: "Delayed",
    color: "#FF6292",
  },
  {
    status: "In Progress",
    color: "#f0ad4e",
  },
  {
    status: "Cancelled",
    color: "#787677",
  },
  {
    status: "Completed",
    color: "#74B749",
  },
  {
    status: "Not Started",
    color: "#009da1",
  },
];

const drawingTypeLabels = [
  {
    status: "Initial Drawing",
    color: "#1FC74C",
  },
  {
    status: "Amendment Drawing",
    color: "#f0ad4e",
  },
  { status: "Review", color: "#FF9C46" },
  { status: "Supersede", color: "#FF6292" },
];

export const gridSidebar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
  ],
  defaultToolPanel: "filters",
  hiddenByDefault: true,
};

export const logsColumnDefs = (statuses) => [
  {
    headerName: "Member",
    filter: "agTextColumnFilter",
    field: "logMember",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Status",
    filter: "agTextColumnFilter",
    field: "logStatus",
    resizable: true,
    sortable: true,
    cellRenderer: ({ value }) => FormGridStatusRenderer({ value, statuses }),
  },
  {
    headerName: "Time",
    filter: "agTextColumnFilter",
    field: "logTime",
    resizable: true,
    sortable: true,
    cellRenderer: ({ value }) => dayjs(value).format("DD/MM/YYYY, hh:mm a"),
  },
  {
    headerName: "Reason",
    filter: "agTextColumnFilter",
    field: "logReason",
    resizable: true,
    sortable: true,
  },
];

export const dataGridColumns = ({ accessToken }) => [
  {
    headerName: "Jobsite Address",
    field: "jobSiteAddress",
    minWidth: 135,
    filter: "agTextColumnFilter",
    checkboxSelection: true,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Status",
    field: "permitStatus",
    filter: "agTextColumnFilter",
    minWidth: 170,
    resizable: true,
    sortable: true,
    cellRenderer: ({ value }) =>
      FormGridStatusRenderer({ value, statuses: projectStatuses }),
  },
  {
    headerName: "P. Executive",
    filter: "agTextColumnFilter",
    field: "projectExecutive",
    resizable: true,
    sortable: true,
    minWidth: 125,
  },

  {
    headerName: "Assigned To",
    filter: "agTextColumnFilter",
    field: "assignedTo",
    minWidth: 108,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "SOW",
    filter: "agTextColumnFilter",
    field: "sow",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Drawing Type",
    filter: "agTextColumnFilter",
    field: "drawingType",
    minWidth: 116,
    resizable: true,
    sortable: true,
    cellRenderer: ({ value }) =>
      FormGridStatusRenderer({ value, statuses: drawingTypeLabels }),
  },
  {
    headerName: "Files",
    field: "amendmentDriveUploads",
    resizable: true,
    cellRendererFramework: (params) =>
      UploadedFilesRenderer({ ...params, accessToken }),
  },
];

export const gridFilters = ({
  projectExecutives = [],
  engineers = [],
  sow = [],
}) => [
  {
    label: "Permit Status",
    type: "select",
    column: "permitStatus",
    placeholder: "Permit status...",
    formItemName: "permitStatusFilter",
    filterType: "equals",
    options: ["All", ...projectStatuses?.map(({ status }) => status)],
  },
  {
    label: "P. Executive",
    type: "select",
    column: "projectExecutive",
    formItemName: "permitPExecutiveFilter",
    placeholder: "Project Executive...",
    filterType: "equals",
    options: ["All", ...projectExecutives],
  },
  {
    label: "Assigned To",
    type: "select",
    column: "assignedTo",
    placeholder: "Assigned to...",
    filterType: "equals",
    formItemName: "permitEngineerFilter",
    options: ["All", ...engineers],
  },
  {
    label: "SOW",
    type: "select",
    column: "sow",
    placeholder: "Sow...",
    formItemName: "permitSOWFilter",
    filterType: "equals",
    options: ["All", ...sow],
  },
  {
    label: "Drawing Type",
    type: "select",
    column: "drawingType",
    filterType: "equals",
    placeholder: "Drawing type...",
    formItemName: "permitDrawingTypeFilter",
    options: ["All", ...drawingTypes],
  },
];
