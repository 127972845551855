import { Dropdown } from "antd";
import { ESFlag, USAFlag, GBFlag } from "../assets/images";
import useTranslate from "./useTranslate";
const spanStyle = {
  display: "flex",
  alignItems: "center",
  gap: 5,
  height: 32,
  cursor: "pointer",
};

function LanguageSelector(props) {
  const { changeLanguage, language } = useTranslate();
  const items = [
    {
      key: "1",
      label: "English",
      icon: <USAFlag height={20} width={20} />,
      onClick: () => changeLanguage("en"),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: "Spanish",
      icon: <ESFlag height={20} width={20} />,
      onClick: () => changeLanguage("es"),
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: [language === "en" ? "1" : "2"],
      }}
      trigger={["click"]}
      {...props}
    >
      {language === "en" ? (
        <span style={spanStyle}>
          <USAFlag height={20} width={20} />
          English
        </span>
      ) : (
        <span style={spanStyle}>
          <ESFlag height={20} width={20} />
          Spanish
        </span>
      )}
    </Dropdown>
  );
}

export default LanguageSelector;
