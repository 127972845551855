import "./BoardingHeader.scss";
import { useSelector } from "react-redux";
import { CoreWhiteLogo } from "../../assets/icons";
import CoreBoardingImage from "../../assets/images/CoreBoardingImage.png";

const BoardingHeader = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div className={`boarding-header ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="header-left">
        <div className="name-of-user">
          WELCOME {userConfiguration.nameOfUser.split(" ")[0].toUpperCase()}
        </div>
        <div className="description">
          Please take a moment to customize your preferences.
        </div>
      </div>
      <img src={CoreBoardingImage} />

      <div className="header-right">
        <CoreWhiteLogo />
      </div>
    </div>
  );
};

export default BoardingHeader;
