import React from "react";
import { ERRORSVG } from "./icons";
import "./ErrorComponent.scss";
import ErrorButton from "./ErrorButton";

type Props = {
  errorMessage: string;
  errorDescription?: string;
  buttonProps?: {
    buttonText?: string;
    buttonAction?: () => void;
    suffixIcon?: JSX.Element;
    isDisabled?: boolean;
  };
  feedBackProps?: {
    feedBackText?: string | JSX.Element;
    feedBackAction?: () => void;
  };
};

const ErrorComponent = (props: Props): JSX.Element => {
  const { errorMessage, errorDescription } = props;
  const { buttonAction, buttonText, isDisabled, suffixIcon } =
    props.buttonProps || {};
  const { feedBackAction, feedBackText } = props.feedBackProps || {};

  return (
    <div className="ErrorComponent">
      <ERRORSVG />
      <h1 className="ErrorComponent-errorMessage">{errorMessage}</h1>
      {!!errorDescription && (
        <p className="ErrorComponent-errorDescription">{errorDescription}</p>
      )}
      <div className="ErrorComponent-buttonWrapper">
        {!!props?.buttonProps && (
          <ErrorButton
            {...{
              className: "ErrorComponent-button mondayButtonBlue",
              Icon: suffixIcon,
              disabled: isDisabled,
              onClick: buttonAction,
              containerClassName: "",
              style: {},
            }}
            key="Reload"
          >
            {buttonText}
          </ErrorButton>
        )}
        {!!props.feedBackProps && (
          <button
            className="ErrorComponent-button-feedback"
            onClick={feedBackAction}
          >
            {feedBackText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorComponent;
