import { SimplePLI } from "./GeneralModels";

export class SystemScaffoldPLI extends SimplePLI {
  serviceId = 11;

  length;
  height;
  width;

  fromFloor;
  fromFloorNote;

  toFloor;
  toFloorNote;

  custom; //bool

  lock = false;
  ppu;
  price;
  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateSystemScaffoldPLI extends SimplePLI {
  serviceId = 11;

  length;
  height;
  width;

  fromFloor;
  fromFloorNote;

  toFloor;
  toFloorNote;

  custom; //bool

  lock = false;
  ppu;
  price;
  isAlternate = true;
  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
