import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
@param {String} categoryName - Name of category to find creation progress datas
@param {String} actionType - Action Name example: "Create", "Update"
*/
export const useProgressComponent = ({ categoryName, actionType } = {}) => {
  const { programFields } = useSelector((state) => state.programFields);

  const [visibleCreationProgress, setVisibleCreationProgress] = useState(false);
  const [creationProgresses, setCreationProgresses] = useState([]);

  const progressStepsConfigs = programFields?.find(
    ({ fieldName }) => fieldName === "Progress Steps Configuration"
  ).fieldOptions;

  useEffect(() => {
    returnInitalProgresses();
  }, [categoryName, actionType]);

  const returnInitalProgresses = () => {
    setCreationProgresses(
      progressStepsConfigs?.[categoryName]?.[actionType] || []
    );
  };

  /**
  @param {Number} index - Index value of progress that need to be modified - For more go to progressStepsConfigs const
  @param {Number} status - Status value with 1(for 100%), 0(for 0%) or -1(for error) 
  */
  const updateProgressStatus = (obj) => {
    const isAnyProgressUsed = creationProgresses.some(({ progressKey }) =>
      obj.hasOwnProperty(progressKey)
    );

    isAnyProgressUsed &&
      setCreationProgresses((prev) =>
        prev?.map((progress) =>
          obj.hasOwnProperty(progress.progressKey)
            ? {
                ...progress,
                progressStatus: obj[progress.progressKey],
              }
            : progress
        )
      );
  };

  /**
  @param {Number} index - Index of progress that need to be deleted
  */
  const hideProgress = (progressKey, hide = true) => {
    setCreationProgresses((prev) =>
      prev.map((progress) =>
        progress.progressKey === progressKey ? { ...progress, hide } : progress
      )
    );
  };

  return {
    visibleCreationProgress,
    setVisibleCreationProgress: (data) => {
      !data && returnInitalProgresses(); //if progress modal closed return initial values
      setVisibleCreationProgress(data);
    },
    creationProgresses,
    setCreationProgresses,
    updateProgressStatus,
    hideProgress,
  };
};
