import { Overview } from ".";
const Page = ({
  viewTitle,
  component: PageComponent,
  props,
  getEditedValue,
  currentStep,
  sideButtons,
  notesProps,
  todosProps,
  stepperParams,
  rowData,
}) => {
  return (
    <PageComponent
      {...{
        viewTitle,
        props,
        getEditedValue,
        currentStep,
        sideButtons,
        notesProps,
        todosProps,
        stepperParams,
        rowData,
      }}
    />
  );
};

Page.Overview = ({
  viewTitle,
  props,
  getEditedValue,
  currentStep,
  sideButtons,
  notesProps,
  stepperParams,
  rowData,
  todosProps,
}) => {
  return (
    <Page
      {...{
        viewTitle,
        stepperParams,
        notesProps,
        component: Overview,
        props,
        getEditedValue,
        currentStep,
        sideButtons,
        rowData,
        todosProps,
      }}
    />
  );
};

export default Page;
