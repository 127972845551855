import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";

export const updateEditLogs = async ({
  currentUser,
  userConfiguration,
  newValue,
  saveAddedLogs,
}) => {
  const newEditLog = {
    actionType: "Edit",
    category: `User Access ${currentUser.nameOfUser}`,
    recordId: currentUser?.identityId,
    recordName: currentUser?.nameOfUser,
    currentData: {
      ["Can Assign Task"]: newValue ? "Access Granted" : "Access Removed",
    },
    previousData: {
      ["Can Assign Task"]: !newValue ? "Access Granted" : "Access Removed",
    },
    label: "",
  };

  const accessTaskKeys = {
    accessGranted: `Access to assign task granted`,
    accessRemoved: `Access to assign task removed`,
  };
  await saveAddedLogs(newEditLog).then((res) => {
    broadcastNotification(
      "63",
      `onUserEdition`,
      [
        {
          common: userConfiguration.nameOfUser,
          common2: newEditLog.recordName,
          commonNext: !!newValue
            ? accessTaskKeys.accessGranted
            : accessTaskKeys.accessRemoved,
        },
        {
          userName: userConfiguration.nameOfUser,
          currentUser: userConfiguration.cognitoUserId,
        },
      ],
      ""
    );
  });
};
