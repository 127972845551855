export const lengthOfRolesUsers = (nameOfUser, allUsers, departmentName) => {
  let stringed = "";
  if (typeof nameOfUser === "object") {
    stringed = Object.keys(nameOfUser)[0];
  } else {
    stringed = nameOfUser;
  }
  const lengthOf = allUsers?.filter(
    (user) =>
      user?.groupName === stringed && user?.departmentName === departmentName
  )?.length;
  return lengthOf;
};
