import { useState } from "react";
import { MondayButton } from "../../../../../commonComponents";
import DifferencesFinder from "./DifferencesFinder";

function DifferencesFinderWrapper() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MondayButton onClick={() => setOpen(true)}>Open</MondayButton>
      {open && <DifferencesFinder open={open} setOpen={setOpen} />}
    </>
  );
}

export default DifferencesFinderWrapper;
