import { dayjsNY } from "../components/DateComponents/contants/DayjsNY";

export const dateInputValidation = (
  type,
  inputName,
  formatWithHour = false
) => {
  switch (type) {
    case "text":
      return dayjsNY(inputName).toString() !== "Invalid date"
        ? !!formatWithHour
          ? dayjsNY(inputName).format("MM/DD/YYYY h:mm a")
          : dayjsNY(inputName).format("L")
        : "--";
    default:
      return inputName !== null &&
        dayjsNY(inputName).toString() !== "Invalid date"
        ? dayjsNY(inputName)
        : null;
  }
};
