import { API } from "aws-amplify";
import { defaultPriceCalculationSheet } from "../../../../../../helpers/constants/forProject";
import { CheckIfValidId } from "./validators";

export default function pricePerUnitInfoPromise(estimationId) {
  if (!CheckIfValidId(estimationId)) {
    // eslint-disable-next-line
    throw "Project could not be found! projectId: " + estimationId;
  }

  return new Promise((resolve) => {
    API.get("priceCalculationInfo", `/priceCalculationInfo/${estimationId}`, {})
      .then((response) => {
        resolve(response.calculationInfo);
      })
      .catch(() => {
        API.post("priceCalculationInfo", "/priceCalculationInfo", {
          body: {
            estimationId: estimationId,
            calculationInfo: defaultPriceCalculationSheet,
          },
        }).then(() => {
          resolve(defaultPriceCalculationSheet);
        });
      });
  });
}

export function getPricingPromise() {
  return new Promise((resolve) => {
    API.get("pricing", `/pricing`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error("Error on priceSheet GET: " + err);
      });
  });
}

export function setPricing(servicePricing) {
  if (!CheckIfValidId(servicePricing.serviceId)) {
    // eslint-disable-next-line
    throw "Project could not be found! projectId: " + servicePricing.serviceId;
  }

  return API.put("pricing", `/pricing/${servicePricing.serviceId}`, {
    body: servicePricing,
  });
}

export function getProposedTypeOfWorkPromise() {
  return new Promise((resolve) => {
    API.get("proposedTypeOfWork", "/proposedTypeOfWork", {})
      .then((services) => {
        resolve(services?.filter((s) => s?.active !== false));
      })
      .catch((err) => {
        console.error("proposedTypeOfWork GET error: " + err);
      });
  });
}
