import {
  dispatching,
  documentation,
  estimations,
  fleet,
  fleetInspections,
  leads,
  maintenance,
  opportunities,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  safety,
} from "./subCategory";

export const circleProgressBar = ({ tables, circleProgressPicker }) => {
  return {
    Leads: () => leads({ tables, circleProgressPicker }),
    Claims: () => claims({ tables, circleProgressPicker }),
    Opportunities: () => opportunities({ tables, circleProgressPicker }),
    Projects: () => projects({ tables, circleProgressPicker }),
    Documentation: () => documentation({ tables, circleProgressPicker }),
    Scheduling: () => scheduling({ tables, circleProgressPicker }),
    Dispatching: () => dispatching({ tables }),
    Fleet: () => fleet({ tables, circleProgressPicker }),
    "Fleet Inspections": () =>
      fleetInspections({ tables, circleProgressPicker }),
    Maintenance: () => maintenance({ tables, circleProgressPicker }),
    Violations: () => violations({ tables, circleProgressPicker }),
    Estimations: () => estimations({ tables, circleProgressPicker }),
    Applications: () => applications({ tables, circleProgressPicker }),
    Rentals: () => rentals({ tables, circleProgressPicker }),
    Charges: () => charges({ tables, circleProgressPicker }),
    Invoices: () => invoices({ tables, circleProgressPicker }),
    Incidents: () => incidents({ tables, circleProgressPicker }),
    Safety: () => safety({ tables, circleProgressPicker }),
  }; //CircleProgressLine
};
