import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../../../../Fleet/utils";
import { API } from "aws-amplify";
import { categories } from "../utils/constants";
import { fetchAllData, filterTables } from "../../../../../../utils";
import { filterAndMapEstimations } from "./utils/filterAndMapEstimations";
import { mapEstimationsWithApplicationInfo } from "./utils/mapEstimationsWithApplicationInfo";

const InitialInvoiceContext = createContext();

export const useInitialInvoiceContext = () => {
  return useContext(InitialInvoiceContext);
};

export const InitialInvoiceProvider = ({
  children,
  projectData: pDp,
  invoiceData: iDp, // If is is given then modal is in Edit Mode
  accountData: aDp, //It is given when when in edit mode
  setData = () => {}, //Reflects changes if an invoice is updated/created
  setSelectedData = () => {}, //Reflect changes in Invoice Drawer when invoice updates, || given when invoiceData is given
  handleExit = () => {},
  outerSelectedCategory = false,
  fetchAndSetInvoices = () => {}, //It is given when the invoice is in > Projects / View / Accounting /Invoicing and auto fills some inputs from the projects
  createdInvoiceRetriever = () => {}, // After Creating Invoice, send invoice data
  getBodyToSaveInAutomation, //this is a function used in Automations to get body for api, to save it later automatically
  nextStepHandler = () => {}, //Opens Next Step Modal
  setSavedRecord = () => {},
}) => {
  const { invoiceId } = useParams();

  const [projectData, setProjectData] = useState(pDp);
  const [invoiceData, setInvoiceData] = useState(iDp);
  const [accountData, setAccountData] = useState(aDp);
  const [scheduleOfValues, setScheduleOfValues] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (projectData) {
      fetchData("scheduleOfValues").then((res) => {
        setScheduleOfValues(res?.schedules);
      });
      const categoryData = {};
      let latestProjectData = API.get(
        "projects",
        `/projects/${projectData?.projectId}`
      );
      const promises = categories.map((category) =>
        projectData
          ? filterTables(category, "projectId", projectData?.projectId)
          : (category.categoryName = "charges"
              ? fetchAllData(category)
              : fetchData(category))
      );
      if (!iDp) {
        //even if we are not in edit mode, we still get the account data
        //in order to update the account balance on invoice creation
        API.get("accounts", `/accounts/${projectData?.accountId}`)
          .then((res) => {
            setAccountData(res);
          })
          .catch((err) => console.log("Could not get account data: ", err));
      }
      Promise.all(promises)
        .then((responses) =>
          responses?.forEach(async (result, index) => {
            const category = categories[index];
            /*************  Special cases *************/
            if (category === "charges") {
              result = result.filter((el) => el.chargeType !== "Credit Memo");
            } else if (category === "estimations") {
              latestProjectData = projectData && (await latestProjectData);
              result = filterAndMapEstimations(
                result,
                responses,
                latestProjectData
              );
            } else if (category === "rentals") {
              result = mapEstimationsWithApplicationInfo(result, responses);
            }
            /*************                *************/
            categoryData[category] = result;

            if (Object.keys(categoryData).length === categories.length) {
              setCategoryData({ ...categoryData });
            }
          })
        )
        .then(() => setIsLoading(false));
    }
  }, [projectData]);

  useEffect(() => {
    async function fetchInvoiceData() {
      if (invoiceId) {
        const invoiceData = await API.get("invoices", `/invoices/${invoiceId}`);

        const [projectData, accountData] = await Promise.all([
          API.get("projects", `/projects/${invoiceData?.projectId}`),
          API.get("accounts", `/accounts/${invoiceData?.accountId}`),
        ]);

        setInvoiceData(invoiceData);
        setProjectData(projectData);
        setAccountData(accountData);
      }
    }

    fetchInvoiceData();
  }, [invoiceId]);

  return (
    <InitialInvoiceContext.Provider
      value={{
        projectData,
        invoiceData,
        accountData,
        scheduleOfValues,
        categoryData,
        setData,
        setSelectedData,
        handleExit,
        outerSelectedCategory,
        fetchAndSetInvoices,
        createdInvoiceRetriever,
        getBodyToSaveInAutomation,
        nextStepHandler,
        setSavedRecord,
        isLoading,
      }}
    >
      {children}
    </InitialInvoiceContext.Provider>
  );
};
