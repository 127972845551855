import { cellValueChangedDay } from "./DaysModalAgGrid/DetailsGridUtils/cellValueChangedDay"
import { useStateContext } from "../../../../../../../../../../Context/Context"
import { cellEditingStarted } from "../../rentalDetailsFunctions"
import { defaultColDef, columnDefs } from "./DaysModalAgGrid"
import { RentalAgGrid } from "../../../../../.."
import "./DaysTable.scss"

import { useSelector } from "react-redux"
import { cloneDeep } from "lodash"
import { useRedux } from "../../../../../../../../../../../../../../hooks"

const DaysTable = ({ elevationData, selectedRow, data }) => {
  // const { isWritable, unchangingFakeRentals, darkMode, selectedService } = useStateContext()

  // const [isAutoSaving, setIsAutoSaving] = useRedux("rentalsIsAutoSaving")
  // const [fakeRentals, setFakeRentals] = useRedux("fakeRentals")
  // const [color, setColor] = useRedux("rentalsColor")
  const [isWritable] = useRedux("rentalsIsWritable")
  const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals")
  const [darkMode] = useRedux("rentalsDarkMode")
  const [selectedService] = useRedux("rentalsSelectedService")

  const { isLoading } = useSelector((state) => state.rentals)

  const initialRowData =
    unchangingFakeRentals?.services
      ?.find(({ label }) => label === selectedService)
      ?.serviceOptions?.find(({ elevationId }) => elevationId === elevationData?.elevationId)
      ?.items?.find(({ id }) => id === selectedRow?.id)
      ?.rentalDetails?.details?.find(({ week }) => week === data?.week)?.days || []

  const rowData = cloneDeep(initialRowData)

  return (
    <div className="daysTableContainer">
      <RentalAgGrid
        {...{
          darkMode,
          rowData,
          defaultColDef,
          suppressRowTransform: true,
          enableCellChangeFlash: true,
          onCellEditingStarted: (params) => cellEditingStarted({ params, isLoading, isWritable }),
          columnDefs: columnDefs({
            selectedElevation: elevationData,
            selectedItem: selectedRow,
            selectedWeek: data,
          }),
          onCellValueChanged: (params) => {
            cellValueChangedDay({
              selectedElevation: elevationData,
              setUnchangingFakeRentals,
              selectedItem: selectedRow,
              unchangingFakeRentals,
              selectedWeek: data,
              selectedService,
              initialRowData,
              params,
            })
          },
        }}
      />
    </div>
  )
}

export default DaysTable
