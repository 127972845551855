import axios from "axios";

export const uriConfig = {
  /*"http://10.0.0.161:8080/api/fetchByDateRange",*/
  locale:
    process.env.NODE_ENV === "production"
      ? "http://localhost:8080/api/fetchByDateRange/prod"
      : "http://localhost:8080/api/fetchByDateRange",
  /*"http://10.0.0.161:8080/api/fetchByDateRange",*/
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/api/fetchByDateRange/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/api/fetchByDateRange",
};

/**
 * Function that returns the data of a table that falls in a specific date range
 * @param {string} tableName The table we want to get the data from
 * @param {number[] | string[] | dayjs[]} dateRange The start and end *NEEDS TO HAVE THE SAME FORMAT AS THE SAVED DATA*
 * @param {string} dateKey The key the date is stored on
 * @param {string} [filterKey] The key to filter by
 * @param {any} [filterValue] The value to filter by
 */
async function fetchByDate(
  tableName,
  dateRange,
  dateKey,
  filterKey = null,
  filterValue = null
) {
  return axios
    .post(uriConfig.online, {
      tableName,
      dateRange,
      dateKey,
      filterKey,
      filterValue,
    })
    .then(({ data }) => data);
}

export default fetchByDate;
