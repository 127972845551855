import pdfMake from "pdfmake";

export const PDFDocuments = ({ chargeItems = [], getBase64, project }) => {
	pdfMake
		.createPdf({
			content: [
				{ text: "CHARGE", style: "header" },
				{ text: "Charge #", style: "dateAndNumber" },
				{ text: "Date ", style: "dateAndNumber" },
				{
					text: project.projectAddress,
					style: "chargeAddress",
				},
				{
					style: "tableExample",
					table: {
						widths: [20, "*", "*", "*", "*", "*"],
						body: [
							[
								{ text: "Nr.", border: [false, true, false, true] },
								{ text: "Services", border: [false, true, false, true] },
								{ text: "Price", border: [false, true, false, true] },
								{ text: "Tax %", border: [false, true, false, true] },
								{ text: "Tax Amount", border: [false, true, false, true] },
								{ text: "Total Amount", border: [false, true, false, true] },
							],
							...chargeItems?.map(({ label, price }, i) => {
								return [
									{ text: `${i + 1}`, border: [false, false, false, false] },
									{ text: label, border: [false, false, false, false] },
									{ text: `$${price}`, border: [false, false, false, false] },
									{
										text: `${project.taxRate * 100}%`,
										border: [false, false, false, false],
									},
									{
										text: `$${(price * project.taxRate).toFixed(2)}`,
										border: [false, false, false, false],
									},
									{
										text: `$${(price - price * project?.taxRate).toFixed(2)}`,
										border: [false, false, false, false],
									},
								];
							}),
						],
					},
				},
			],
			styles: {
				header: {
					fontSize: 26,
					margin: [0, 0, 0, 0],
				},
				dateAndNumber: { fontSize: 12, margin: [0, 0, 0, 0] },
				chargeAddress: {
					fontSize: 12,
					margin: [0, 10, 0, 0],
					alignment: "center",
				},
				tableExample: {
					margin: [0, 5, 0, 15],
					borderSize: 10,
				},
			},
		})
		.getBase64((encodedString) => getBase64(encodedString));
};
