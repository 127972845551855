import { useState, useMemo } from "react";
import {
  RoleHeader,
  RolePermissions,
} from "../../../../Roles/components/UserRoles/components";
import { GoogleDrivePermissions } from "./components";
import { selectedTabInfoText } from "./utils";
import CardTemplate from "../CardTemplate/CardTemplate";
import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { SearchOutlined } from "@ant-design/icons";
import "./UserAccessWrites.scss";

const UserAccessWrites = ({
  form,
  gDriveCredentials,
  accessToken,
  currentUser,
  setAccessConfiguration,
  accessConfiguration,
  isDarkMode,
}) => {
  const [selectedTab, setSelectedTab] = useState("Sidebar");
  const [searchRouteConfigs, setSearchRouteConfigs] = useState("");

  const userDataHeader = {
    Route: [],
    Sidebar: [],
    ["Drive Access Rights"]: {},
  };

  const infoText = useMemo(() => {
    return selectedTabInfoText({
      selectedTab,
      nameOfUser: currentUser.nameOfUser,
    });
  }, [selectedTab, currentUser]);

  return (
    <div className="rightContainer">
      <CardTemplate
        {...{
          title: "Access Management",
          isDarkMode,
          className: "user-access-writes-container",
        }}
      >
        <RoleHeader
          {...{
            roleData: userDataHeader,
            selectedTab,
            setSelectedTab,
            setSearchRouteConfigs,
          }}
        />
        <div className="info-and-search">
          <InfoText
            {...{
              isDarkMode,
              text: infoText,
              style: { maxWidth: "unset" },
              className: "user-access-info-text",
            }}
          />
          <div className="config-search">
            <InputComponent
              placeholder="Search Configuration..."
              value={searchRouteConfigs}
              onChange={(e) => setSearchRouteConfigs(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <div className="user-access-list-header">
          <div>Route Title</div>
          <div>Access Type</div>
        </div>
        <div className="user-access-list-body">
          {selectedTab !== "Drive Access Rights" ? (
            <RolePermissions
              {...{
                selectedTab,
                accessConfiguration,
                setAccessConfiguration,
                searchRouteConfigs,
              }}
            />
          ) : (
            <GoogleDrivePermissions
              {...{
                form,
                gDriveCredentials,
                accessToken,
                currentUser,
                searchRouteConfigs,
                isDarkMode,
              }}
            />
          )}
        </div>
      </CardTemplate>
    </div>
  );
};

export default UserAccessWrites;
