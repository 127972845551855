import { forwardRef, ForwardedRef } from "react";
import { Table, Card } from "antd";

import { ListInfoType } from "../../types";

import "./DashboardListCard.scss";

type ListCardProps = {
  data: ListInfoType;
  className?: string;
  style?: { [key: string]: string };
  onMouseUp?: () => any;
  onTouchEnd?: () => any;
  onMouseDown?: () => any;
  children?: JSX.Element;
  cardKey?: string;
  isDarkMode: boolean;
};

const DashboardListCard = forwardRef(
  (
    {
      data,
      style,
      cardKey,
      children,
      onMouseUp,
      className,
      isDarkMode,
      onTouchEnd,
      onMouseDown,
    }: ListCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        {...{
          ref,
          style,
          onMouseUp,
          onTouchEnd,
          onMouseDown,
          key: cardKey,
          className: `dashboard-list-card-container ${
            isDarkMode ? "list-card-container-dark" : ""
          } ${className}`,
        }}
      >
        <Card
          {...{
            title: data.cardTitle,
            extra: data?.action ?? undefined,
            size: "default",
            className: "list-card-container",
            bordered: false,
          }}
        >
          <Table
            {...{
              ...data,
              rowClassName: "dashboard-row",
              id: `table-${data.cardTitle}`,
              pagination: false,
              scroll: {
                y:
                  document.getElementById(`table-${data.cardTitle}`)
                    ?.clientHeight -
                  (data.showHeader || data.showHeader === undefined ? 77 : 0),
              },
            }}
          />
        </Card>
        {children}
      </div>
    );
  }
);

export default DashboardListCard;
