export const getOnlyMyTasks = (rowData, userConfiguration) => {
  return rowData?.filter((task) => {
    return (
      task.taskAssignedTo?.some(
        ({ cognitoUserId = "" }) =>
          userConfiguration?.cognitoUserId === cognitoUserId
      ) || task.createdBy === userConfiguration?.nameOfUser
    );
  });
};
