import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotification } from "../../../../../utils";

/**
 * Sends a safety email notification and saves logs.
 *
 * @param {string} table - The table name.
 * @param {string} category - The category.
 * @param {Object} rowObject - The row object.
 * @param {string} defaultId - The default ID.
 * @param {Function} setEditLogs - The function to set edit logs.
 * @param {Object} authenticatedUser - The authenticated user.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Function} saveAddedLogs - The function to save added logs.
 * @returns {Promise<void>} - A promise that resolves when the email notification and logs are saved.
 */
async function safetyEmailNotif({
  table = "",
  category = "",
  rowObject = {},
  defaultId = "",
  setEditLogs = () => {},
  authenticatedUser = {},
  userConfiguration = {},
  saveAddedLogs,
}) {
  const action = table === "safety" ? "onSafetyEmail" : "onSafetyIncidentEmail";
  const notifId = table === "safety" ? "42" : "43";
  const nameKey = table === "safety" ? "safetyName" : "incidentName";

  broadcastNotification(
    notifId,
    action,
    [
      {
        common: userConfiguration?.nameOfUser,
      },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: authenticatedUser?.sub,
        cognitos: getCognitosForNotification(
          userConfiguration,
          rowObject?.teamsConfiguration
        ),
      },
    ],
    defaultId
  );

  await saveAddedLogs({
    recordId: defaultId,
    recordName: rowObject?.[nameKey],
    actionType: "Email",
    category,
    topic: rowObject?.[nameKey],
    currentData: {
      [userConfiguration.nameOfUser]: "Sent an email for this record",
    },
  })
    .then((res) => {
      setEditLogs((prev) => {
        if (!!prev?.length) {
          return [...res, ...prev];
        }
        return res;
      });
    })
    .catch((err) => console.error("Error Posting logs", err));
}

export default safetyEmailNotif;
