import { Button, message, Modal, Radio } from "antd";
import { useState, useMemo, useEffect, useCallback } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./TemplateDescModal.scss";
import { API } from "aws-amplify";
import {
  GENERAL_RENTAL_DESC_DEFINERS,
  getMyRentals,
  mapKeysRentals,
  rentalFurtherProcessing,
} from "../../../../../../../../../../../../../pages/Settings/settingsComponents/AccountingTemplates/components/NewTemplate/dataset/rentals";
import { useRedux } from "../../../../../../../../../../../../hooks/useRedux";
import { MondayButton } from "../../../../../../../../../../../../../commonComponents/index";
import { CloseIconModal } from "../../../../../../../../../../../../../../assets/index";
import { SaveIcon } from "../../../../../../../../../../../../BasePage/src/index";
import moment from "moment";
import { useSelector } from "react-redux";
import AlreadyHasDesc from "./AlreadyHasDesc";
import { customMenuRetriever, retrieveProcessedSrc } from "../utils/retrievers";
import { XIcon } from "../../../../../../../../../../../../Communication/assets";
function TemplateDescModal({
  visible,
  dataSrc,
  changeDataSrc,
  title = "Apply Template Description",
  onCancel,
  templateType = "rentalsGeneral",
  savedInKey = "rentalDescription",
  darkMode = false,
  lastModified = moment().valueOf(),
  lastModifiedUser = "N/A",
}) {
  const [savedData, setSavedData] = useState(false);
  const [myEditor, setMyEditor] = useState(null);
  const [chosenTemplate, setChosenTemplate] = useState(null);
  const [templates, setTemplates] = useState(null);
  // const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [hasExisting, setHasExisting] = useState(false);

  // Retrieve the templates from the database & filter them by templateType

  const getTemplates = async () => {
    await API.get("accountingTemplates", "/accountingTemplates").then((res) => {
      if (Array.isArray(res)) {
        const tempType = templateType;
        setTemplates(
          res?.filter(({ templateType = "" }) => templateType === tempType)
        );
      }
    });
  };

  // Edits the template already saved
  const onRegenerate = () => {
    setHasExisting(false);
    message.info("Description was cleared successfully");
  };

  const saveAndCleanUp = async () => {
    const tempData = structuredClone(dataSrc);
    const toPost = {
      templateContent: renderData,
      templateTitle: chosenTemplate,
      templateType: "rentalsGeneral",
      lastModified: moment().valueOf(),
      lastModifiedUser: userConfiguration?.nameOfUser,
    };
    tempData[savedInKey] = toPost;
    changeDataSrc(tempData);
    // setFakeRentals(tempFakeRentals);
    setHasExisting(true);
    message.info("Saved Successfully");
  };

  const validatePrevious = () => {
    let myObj = dataSrc?.[savedInKey] || {};
    if (myObj?.templateContent) {
      setSavedData(myObj?.templateContent);
      setChosenTemplate(myObj?.templateTitle);
      setHasExisting(true);
    }
  };

  const processedSrc = useMemo(() => {
    return retrieveProcessedSrc({ dataSrc, templateType });
  }, [dataSrc, templateType]);

  const rendercostumMenu = useCallback(() => {
    return (
      customMenuRetriever({
        templateType,
      }) ?? []
    );
  }, [templateType]);
  const renderData = useMemo(() => {
    if (visible && savedData) {
      var regex = new RegExp("{(.*?)(?=})}", "g");
      const matchedItems = Array.from(savedData?.matchAll(regex), (x) => x);
      let str = savedData?.replace(/\{\{(.*?)\}\}/g, function (i, match) {
        if (match.includes("&ccedil;")) {
          match = match.replace("&ccedil;", "ç");
          return processedSrc?.[match] || match;
        } else return processedSrc?.[match] || match;
      });
      return str;
      return matchedItems;
    } else return;
  }, [visible, savedData, processedSrc]);

  useEffect(() => {
    if (visible) {
      validatePrevious();
      getTemplates();
    }

    // return () => {

    // };
  }, [visible, dataSrc]);

  return (
    <div>
      <Modal
        title={title}
        open={visible}
        onCancel={onCancel}
        wrapClassName={`rental-template-desc-modal-wrapper
          ${darkMode && "dark-modal-template"}`}
        style={{ top: 20 }}
        closeIcon={<XIcon />}
        maskClosable={false}
        width={!!savedData && !hasExisting ? "80vw" : "50vw"}
        footer={
          <>
            <MondayButton
              className="mondayButtonRed"
              Icon={<CloseIconModal />}
              onClick={onCancel}
            >
              Close
            </MondayButton>
            <MondayButton
              className={hasExisting ? "mondayButtonGrey" : "mondayButtonGreen"}
              Icon={<SaveIcon />}
              disabled={hasExisting}
              onClick={saveAndCleanUp}
            >
              Save Changes
            </MondayButton>
          </>
        }
      >
        {!hasExisting ? (
          <div className="rental-template-desc-modal">
            <div className="header-pick-template">
              <div className="cardContainer">
                <div className="cardTitleCont">
                  <span className="cardTitle">
                    Choose category for template:
                  </span>
                </div>
                <div className="cardbody">
                  <Radio.Group
                    defaultValue={chosenTemplate}
                    onChange={({ target: { value } }) => {
                      setChosenTemplate(value);
                      setSavedData(
                        templates?.find(
                          ({ templateId }) => templateId === value
                        )?.templateContent
                      );
                    }}
                  >
                    <div className="cardBodyRadio">
                      {templates?.map((el) => {
                        return (
                          <div className="radioCont" key={el?.templateId}>
                            <Radio value={el.templateId}>
                              {el.templateTitle}
                            </Radio>
                          </div>
                        );
                      })}
                    </div>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div className="rental-template-desc-body">
              <div className="rental-template-desc-body__item">
                <Editor
                  editor={myEditor}
                  {...{
                    value: savedData,
                    onEditorChange: (content, editor) => {
                      setSavedData(content);
                      // setEditorObject(editor);
                      // setMyEditor(editor);
                    },
                    //   ref: proposalDesignRef,
                    // onClick: () => {
                    //   setClickedElement(undefined);
                    // },
                    // onChange: (e) => {
                    //   setTimeout(() => {
                    //     message.info("onChange", 10);
                    //     saveAsDraft().then(
                    //       ({ data: { draftId: id } }) =>
                    //         !draftId && setDraftId(id)
                    //     );
                    //   }, 10000);
                    // },
                    init: {
                      block_unsupported_drop: false,
                      selector: "textarea",
                      resize: false,

                      setup: (e) => {
                        // setEditorObject(e);
                        setMyEditor(e);
                        // var keypupTimer;
                        // e.on("KeyUp", function (e) {
                        //   clearTimeout(keypupTimer);
                        //   keypupTimer = setTimeout(() => {
                        //     setDraftSaved(Math.random());
                        //   }, 3000);
                        // });
                      },
                      //   toolbar: formatterActive,
                      plugins:
                        "preview advlist anchor media wordcount searchreplace charmap link image code table noneditable lists",
                      // height: 790,
                      // width: 800,
                      height: "60vh",
                      menubar:
                        "file edit view insert format tools table  help fields fields2",
                      ...rendercostumMenu(),
                      lists_indent_on_tab: false,

                      table_toolbar:
                        "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                      toolbar:
                        "undo redo | " +
                        "styles | " +
                        "bold italic backcolor forecolor fontsizeselect fontselect  | alignleft aligncenter | " +
                        "alignright alignjustify | " +
                        "removeformat | numlist bullist |table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol  ",
                    },
                  }}
                />
              </div>{" "}
              {!!renderData && (
                <div className="rental-template-desc-body__item">
                  <iframe
                    title="testPdf"
                    allowfullscreen
                    height="100%"
                    width="100%"
                    id="frameId"
                    output="embed"
                    sandbox="allow-forms allow-modals allow-scripts allow-same-origin"
                    // data-alloy-tabstop="true"
                    // tabindex="-1"
                    srcdoc={`<!DOCTYPE html><html><head><base href="http://localhost:3000/estimations/"><link type="text/css" rel="stylesheet" href="https://cdn.tiny.cloud/1/m7mdjxl48599wmsg7n8ghucnfu4dzdnrqmmrcw647c7qn46j/tinymce/5.10.6-132/skins/ui/oxide/content.min.css" crossorigin="anonymous"><link type="text/css" rel="stylesheet" href="https://cdn.tiny.cloud/1/m7mdjxl48599wmsg7n8ghucnfu4dzdnrqmmrcw647c7qn46j/tinymce/5.10.6-132/skins/content/default/content.min.css" crossorigin="anonymous"></head><body id="tinymce" class="mce-content-body ">
${renderData}
</body></html>`}
                  ></iframe>{" "}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <AlreadyHasDesc
              {...{
                descriptionContent: renderData,
                onRegenerate,
                lastModified,
                lastModifiedUser,
              }}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}

export default TemplateDescModal;
