export const getExcelColumnKeys = (gridApi, tableColumns) => {
  const columnDefs = gridApi.getColumnDefs();
  const columnsToExport = columnDefs.filter((colDef) =>
    tableColumns?.includes(colDef.field)
  );

  return columnsToExport
    .sort((a, b) => {
      return tableColumns.indexOf(a.colId) - tableColumns.indexOf(b.colId);
    })
    .map((colDef) => colDef.field);
};
