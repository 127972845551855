import { debounce, uniqBy } from "lodash";
import { searchFetchInput } from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import { getDispatchLocationOptions } from "../../requestFormModalData";
// import { getLabelHTML } from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/routeFields";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { assignedToObject, projectManagerSelect, teamSelect } from "..";

import sortFields from "../sortFields";

/**
 * Dispatches the request fields.
 *
 * @param {Array} fleet - The fleet array.
 * @param {Array} teams - The teams array.
 * @param {Array} drivers - The drivers array.
 * @param {Array} projects - The projects array.
 * @param {string|null} status - The status value.
 * @param {Array} schedules - The schedules array.
 * @param {string|null} requestId - The request ID value.
 * @param {Array} inputFields - The input fields array.
 * @param {boolean} isDarkMode - The flag indicating if dark mode is enabled.
 * @param {Array} projectManagers - The project managers array.
 * @param {string|null} currentPageKey - The current page key value.
 * @param {boolean} globalDisabled - The flag indicating if all fields are disabled.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {boolean} allFieldsDisabled - The flag indicating if all fields are disabled.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} setRecordsLoading - The function to set records loading state.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The sorted fields array.
 */
const dispatchRequestFields = ({
  fleet = [],
  teams = [],
  drivers = [],
  projects = [],
  status = null,
  schedules = [],
  requestId = null,
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  currentPageKey = null,
  globalDisabled = false,
  userConfiguration = {},
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  onMainFieldSelect = () => {},
  setRecordsLoading = () => {},
  dispatchRequestData = () => {},
}) => {
  const onSearch = debounce(
    async (searchValue) =>
      await searchFetchInput({
        table: ["projects", "scheduling"],
        idKey: ["projectId", "scheduleId"],
        arrayKey: ["projects", "schedules"],
        searchKey: ["projectName", "scheduleAddress"],
        searchValue,
        setLoading: setRecordsLoading,
        setRecords: (res) => {
          const resToUse = (res || [])?.reduce(
            (acc, curr) => ({
              ...acc,
              ...curr?.value,
            }),
            {}
          );

          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: {
              projects: userAccessRecords(
                userConfiguration,
                uniqBy(
                  [...(projects || []), ...(resToUse?.projects || [])],
                  "projectId"
                )
              ),
              schedules: userAccessRecords(
                userConfiguration,
                uniqBy(
                  [...(schedules || []), ...(resToUse?.schedules || [])],
                  "scheduleId"
                )
              ),
            },
          });
        },
        userConfiguration,
        setCurrentPageKey: (val) => {
          currentPageKey.current = val;
        },
      }),
    500
  );

  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]) || {};

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "jobsiteAddress") {
      delete field?.options;

      // if (selectedMainField?.scheduleId) {
      //   Object.assign(field, {
      //     ...getJobsiteFieldOptions("schedule"),
      //   });
      // } else if (selectedMainField?.projectId) {
      //   Object.assign(field, {
      //     ...getJobsiteFieldOptions("Project (off schedule)"),
      //   });
      // } else if (getYards()?.includes(getFieldValue("jobsiteAddress"))) {
      //   Object.assign(field, {
      //     ...getJobsiteFieldOptions("yard"),
      //   });
      // } else {
      //   Object.assign(field, {
      //     ...getJobsiteFieldOptions("Not a project (off schedule)"),
      //   });
      // }

      Object.assign(field, {
        groupedOptions: getDispatchLocationOptions(schedules, projects),
        onSelect: (val, opt) => onMainFieldSelect({ val, opt, field }),
        showSearch: true,
        style: { minWidth: 300 },
        allowClear: true,
        allowSearch: true,
        customOnSearch: onSearch,
      });
    }

    if (formItemName === "vehicle") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (fleet || [])?.map((el) => ({
          label: el?.fleetName,
          value: el?.fleetId,
        })),
        showSearch: true,
      });
    }

    if (formItemName === "driver") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (drivers || [])?.map((el) => ({
          value: el?.driverId,
          label: el?.driverName,
        })),
        showSearch: true,
      });
    }

    if (formItemName === "dateOnSite") {
      delete field?.options;

      Object.assign(field, {
        initialValue: dayjsNY(),
        allowClear: false,
      });
    }

    if (formItemName === "routeNotes") {
      Object.assign(field, {
        style: { minWidth: 300 },
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Fleet Dispatch View",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
          selfAssign: true,
        }),
      });
    }

    if (!!requestId) {
      if (status !== "Draft") {
        Object.assign(field, {
          disabled: true,
        });
      } else {
        Object.assign(field, {
          disabled: false,
        });
      }
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ onTeamSelect, teams, isDarkMode }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default dispatchRequestFields;
