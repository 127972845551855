import { Button as AntdButton } from "antd";
import { useSelector } from "react-redux";

export default function Button({
  children,
  isLoading,
  disabled,
  type,
  icon,
  shape,
  style,
  fullWidth,
  ...otherProps
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <AntdButton
      loading={isLoading}
      disabled={disabled}
      type={type}
      icon={icon}
      shape={shape}
      data-testid="button"
      style={{
        color: isDarkMode ? "#f2f2f2" : "#333238",
        width: fullWidth ? "100%" : "unset",
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </AntdButton>
  );
}
