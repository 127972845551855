import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCreateInvoiceContext } from "../../../context/InvoiceModalContext";
import { useUndoRedoState } from "../../../../../../../../hooks/useUndoRedoState";
import { useInitialInvoiceContext } from "../../../context/InvoiceInitialContext";
import { useInvoiceFormDataContext } from "../../../context/InvoiceFormDataContext";
import { UndoRedoButtons } from "../../../../../../../commonComponents/UndoRedoButtons/UndoRedoButtons";

const UndoRedo = () => {
  const { products, setProducts } = useCreateInvoiceContext();
  const { formData, setFormData } = useInvoiceFormDataContext();
  const { projectData } = useInitialInvoiceContext();

  const { programFields } = useSelector((state) => state.programFields);

  // Undo Redo Limit for Invoice
  const undoRedoLimit = programFields
    .find((item) => item.fieldName === "Undo Redo Limit")
    .fieldOptions.find(({ name }) => name === "Invoices").value;

  const updateInvoiceData = (newData) => {
    setProducts(newData?.products);
    setFormData(newData?.formData);
  };

  // Undo,Redo function
  const {
    changeStateHandler,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    undoClickHandler,
    redoClickHandler,
  } = useUndoRedoState(undoRedoLimit, updateInvoiceData);

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;

  //update undo/redo state[] for every formData, products change
  useEffect(() => {
    if (projectData) {
      if (products && formData && formData.projectName)
        changeStateHandler(JSON.parse(JSON.stringify({ products, formData })));
    } else if (products && formData)
      changeStateHandler(JSON.parse(JSON.stringify({ products, formData })));
  }, [products, formData]);

  return (
    <UndoRedoButtons
      {...{
        canUndo,
        canRedo,
        undoClickHandler,
        redoClickHandler,
        tooltipCategory: "Invoices",
      }}
    />
  );
};

export default UndoRedo;
