export const agGridTrialKey =
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-27_September_2020_[v2]_MTYwMTE2MTIwMDAwMA==fa343a1979d9d3e95ba19ab21c69128e";
export const gMapsAPI = "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo";
export const assignServiceColor = {
  1: "#588266",
  2: "#FFBE0B",
  3: "#53DD6C",
  4: "#FB5607",
  5: "#FF00FF",
  6: "#AA4465",
  7: "#FFA69E",
  8: "#25CED1",
  9: "#4CC9F0",
  10: "#F72585",
  11: "#FFE66D",
  12: "#FF6B6B",
  13: "#B5179E",
  14: "#7209B7",
  15: "#F08080",
  16: "#3F37C9",
  17: "#4895EF",
  18: "#93E1D8",
  19: "#C55DF6",
  20: "#7209B7",
  38: "#53DD6C",
  44: "#7209B7",
};
export const serviceColorCodes = {
  // highlight color, font color
  1: { background: "#ffff00", color: "#000000" },
  2: { background: "#ffffff", color: "#000000" },
  3: { background: "#ffffff", color: "#000000" },
  4: { background: "#ff0000", color: "#ffffff" },
  5: { background: "#ffffff", color: "#000000" },
  6: { background: "#800080", color: "#ffffff" },
  7: { background: "#ffffff", color: "#000000" },
  8: { background: "#55D4FF", color: "#000000" },
  9: { background: "#ffffff", color: "#000000" },
  10: { background: "#ffffff", color: "#000000" },
  11: { background: "#ff00ff", color: "#ffffff" },
  12: { background: "#008000", color: "#ffffff" },
  13: { background: "#0000ff", color: "#ffffff" },
  14: { background: "#ffffff", color: "#000000" },
  15: { background: "#ffffff", color: "#000000" },
  16: { background: "#ffffff", color: "#000000" },
  17: { background: "#ffffff", color: "#000000" },
  18: { background: "#ffffff", color: "#000000" },
  19: { background: "#800080", color: "#ffffff" },
};
export const serviceNames = {
  1: "Needle Beam",
  2: "Chute",
  3: "Const. Draw",
  4: "Hoist",
  5: "Fence",
  6: "Horizontal Netting",
  7: "Permit Renewal",
  8: "Pipe Scaffold",
  9: "Plywood Enclosure",
  10: "Retention",
  11: "Roof Protection",
  12: "Sidewalk Shed",
  13: "Scaffold",
  14: "Winter Enclosure",
  15: "Permit & Drawings",
  16: "Bracket Levels",
  17: "Material(s) Sales",
  18: "Material(s) Rent",
  19: "Vertical Netting",
};
export const serviceIdNames = {
  1: "NB",
  NB: 1,
  2: "CH",
  CH: 2,
  3: "CD",
  CD: 3,
  4: "HO",
  HO: 4,
  5: "FE",
  FE: 5,
  6: "HN",
  HN: 6,
  7: "PR",
  PR: 7,
  8: "PS",
  PS: 8,
  9: "PE",
  PE: 9,
  10: "RE",
  RE: 10,
  11: "RP",
  RP: 11,
  12: "SS",
  SS: 12,
  13: "SF",
  SF: 13,
  14: "WE",
  WE: 14,
  15: "PD",
  PD: 15,
  16: "BL",
  BL: 16,
  17: "MS",
  MS: 17,
  18: "MR",
  MR: 18,
  19: "VN",
  VN: 19,
};
export function getItemID(serviceId, optionIndex, id) {
  return `${serviceIdNames[serviceId]}-A${optionIndex + 1}-${("000" + id).slice(
    -3
  )}`;
}
export const totalServiceFormulas = {
  1: null,
  2: "LNFT",
  3: null,
  4: null,
  5: "LNFT",
  6: "LNFT",
  7: null,
  8: "SQFT",
  9: "SQFT",
  10: null,
  11: "SQFT",
  12: "LNFT",
  13: "SQFT",
  14: "SQFT",
  15: null,
  16: null,
  17: null,
  18: null,
  19: "LNFT",
};
export const defaultPriceCalculationSheet = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
  13: 0,
  14: 0,
  15: 0,
  16: 0,
  17: 0,
  18: 0,
  19: 0,
  20: 0,
};
export const usaTaxRates = {
  // as of 10/22/2020
  AL: 0.135, // Alabama
  AK: 0.07, // Alaska
  AZ: 0.10725, // Arizona
  AR: 0.11625, // Arkansas
  CA: 0.105, // California
  CO: 0.1, // Colorado
  CT: 0.0635, // Connecticut
  DE: 0.0, // Delaware
  DC: 0.0575, // District Of Columbia
  FL: 0.075, // Florida
  GA: 0.08, // Georgia
  GU: 0.04, // Guam
  HI: 0.04712, // Hawaii
  ID: 0.085, // Idaho
  IL: 0.1025, // Illinois
  IN: 0.07, // Indiana
  IA: 0.07, // Iowa
  KS: 0.115, // Kansas
  KY: 0.06, // Kentucky
  LA: 0.1145, // Louisiana
  ME: 0.055, // Maine
  MD: 0.06, // Maryland
  MA: 0.0625, // Massachusetts
  MI: 0.06, // Michigan
  MN: 0.07875, // Minnesota
  MS: 0.0725, // Mississippi
  MO: 0.1085, // Missouri
  MT: 0.0, // Montana
  NE: 0.075, // Nebraska
  NV: 0.0825, // Nevada
  NH: 0.0, // New Hampshire
  NJ: 0.12625, // New Jersey
  NM: 0.08688, // New Mexico
  NY: 0.08785, // New York
  NC: 0.075, // North Carolina
  ND: 0.08, // North Dakota
  OH: 0.08, // Ohio
  OK: 0.11, // Oklahoma
  OR: 0.0, // Oregon
  PA: 0.08, // Pennsylvania
  PR: 0.115, // Puerto Rico
  RI: 0.07, // Rhode Island
  SC: 0.09, // South Carolina
  SD: 0.06, // South Dakota
  TN: 0.0975, // Tennessee
  TX: 0.0825, // Texas
  UT: 0.0835, // Utah
  VT: 0.07, // Vermont
  VA: 0.06, // Virginia
  WA: 0.104, // Washington
  WV: 0.07, // West Virginia
  WI: 0.0675, // Wisconsin
  WY: 0.06, // Wyoming
};
