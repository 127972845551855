import moment from "moment";
import { camelCaseToNormalText } from "../utils";
import { keysForDates } from "../../../../../helpers/constants";

export const generateColumnDefs = ({ propColumnDefs = {}, rowData }) => {
  // When columns provided these will be columns of table
  // When avoidKeys provided and columns not columns will be generated from rowDatas
  const { columns, avoidKeys } = propColumnDefs;

  return (!!columns ? columns : getAllKeys({ rowData, avoidKeys })).map(
    ({ field, headerName, cellRenderer, ...restColProps }) => {
      const customCellRenderer = ({ value }) => {
        return value
          ? keysForDates.includes(field)
            ? moment(value).format("MM/DD/YYYY h:mm:ss a")
            : value
          : "";
      };

      return {
        headerName,
        field,
        sortable: true,
        minWidth: 150,
        ...(keysForDates.includes(field) && {
          getQuickFilterText: () => "", //avoid filters
          filter: "agDateColumnFilter",
        }),
        ...restColProps,
        cellRenderer: cellRenderer || customCellRenderer,
      };
    }
  );
};

const getAllKeys = ({ rowData, avoidKeys = [] }) => {
  let keysToAvoid = avoidKeys;

  return rowData.reduce((keys, obj) => {
    Object.keys(obj).forEach((key) => {
      if (!keys.some(({ field }) => field === key)) {
        // Return only handled keys in filters modal
        if (
          !key.includes("Id") && //                   If key inlcudes Id avoid that key
          (typeof obj[key] === "string" || //         If key is string get that key
            typeof obj[key] === "number" || //        If key is number return it
            typeof obj[key] === "boolean" || //       If key is boolean get that key
            keysForDates.includes(key) || //           If key is date return it
            (Array.isArray(obj[key]) && //            If key contains array return it only if array contain number, strings or object with nameOfUser
              obj[key].every(
                (item) =>
                  typeof item === "string" ||
                  typeof item === "number" ||
                  (typeof item === "object" &&
                    item?.hasOwnProperty("nameOfUser"))
              )))
        ) {
          //some keys can be different types, do not get keys with different types
          !keysToAvoid.includes(key) &&
            keys.push({ field: key, headerName: camelCaseToNormalText(key) });
        } else {
          keysToAvoid.push(key);
        }
      }
    });
    return keys;
  }, []);
};
