import { Badge, Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FolderFileIcon } from "../../../../../../assets";
import { Folders, UploadObject } from "./helpers";
import Upload from "../../../../../SidebarPages/Projects/Upload";
import dayjs from "dayjs";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

type Props = {
  selectedFolder: string;
  formattedDate: string;
  setSelectedFolder: React.Dispatch<React.SetStateAction<string>>;
  day: number;
  id: string;
  serviceFolders: Folders;
  createDriveFolders: (formattedDate: string) => void;
  driveRequest: any;
  dayDriveFolders: UploadObject[] | [];
  setDriveFilesByDay: (
    docs: UploadObject[],
    selectedFolder: string,
    action: "add" | "new"
  ) => void;
  disabled: boolean;
};

const FolderView = (props: Props) => {
  const {
    selectedFolder,
    formattedDate,
    setSelectedFolder,
    id,
    day,
    createDriveFolders,
    driveRequest,
    serviceFolders,
    dayDriveFolders,
    setDriveFilesByDay,
    disabled,
  } = props;

  //* When first loaded get the files for the day because they are not saved
  useEffect(() => {
    if (serviceFolders?.[formattedDate])
      driveRequest
        ?.getFilesThumbnails(serviceFolders?.[formattedDate])
        .then(async (res: any) => {
          const response = await res.json();
          setDriveFilesByDay(response?.files, formattedDate, "new");
        });
    else setDriveFilesByDay([], formattedDate, "new");
  }, [serviceFolders?.[formattedDate]]);

  const futureDay = dayjs(formattedDate).isAfter(dayjsNY()) ? "future" : "";

  return (
    <Tooltip title={!!futureDay ? "This day is in the future" : ""}>
      <div
        className={`folderContainer ${
          selectedFolder === formattedDate ? "selected" : ""
        } ${futureDay} ${disabled ? "disabled" : ""}`}
        key={id}
        onClick={() => setSelectedFolder(formattedDate)}
      >
        <Badge
          count={dayDriveFolders?.length || 0}
          offset={[0, 27]}
          color={!!dayDriveFolders?.length ? "#1264A3" : "#FE4C4A"}
          showZero
        >
          <FolderFileIcon />
        </Badge>
        <div className="folderName">
          <b>Day {day}</b>
          <span>{formattedDate}</span>
        </div>
        {!disabled && (
          <Button
            type="primary"
            className="uploadButton"
            onClick={() => createDriveFolders(formattedDate)}
            disabled={disabled}
          >
            Upload
          </Button>
        )}
      </div>
    </Tooltip>
  );
};

export default FolderView;
