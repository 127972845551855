import { API } from "aws-amplify";
import { useEffect, useMemo } from "react";
import { compareIncluding } from "../utils";
import {
  compareAddressesWithRgx,
  compareIncludingAccurate,
} from "../utils/compareIncluding";

export const useDuplicatePrevention = ({
  listOfRecords = [],
  filter = {},
  notEqualFilter = {},
  compareIncludingKeys = [],
  onDuplication = () => {},
  doNotPrevent,
  startFiltering = true,
}) => {
  const existingRecord = useMemo(() =>
    listOfRecords?.filter((record) =>
      Object.entries(notEqualFilter).every(
        ([key, value]) => value !== record?.[key]
      )
    )
  )?.find(
    (record) =>
      Object.entries(filter).every(([key, value]) =>
        compareIncludingKeys?.includes(key)
          ? key?.toUpperCase?.().includes("ADDRESS") &&
            compareIncludingKeys?.includes(key)
            ? compareAddressesWithRgx(value, record?.[key])
            : compareIncludingAccurate(value, record[key])
          : value === record[key]
      ),

    [listOfRecords, filter]
  );

  useEffect(() => {
    if (!existingRecord || !!doNotPrevent || !startFiltering) return;
    onDuplication();
  }, [existingRecord]);

  return !!doNotPrevent ? null : existingRecord;
};
