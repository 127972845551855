import { formatCurrency, formatNumberToPercentage } from "../../../../../../../../../../utils"

export const columnDefs = (service, items, programFields) => {
  const splittedData = items?.[0]?.amounts
    ? items?.[0]?.amounts?.map((amount, amountIndex) => ({
        headerName: amount.event,
        marryChildren: true,
        headerClass: amountIndex % 2 === 0 ? "oddHeaderClassName" : "evenHeaderClassName",
        children: [
          {
            field: "charge percentage",
            valueGetter: (params) =>
              `${formatNumberToPercentage(
                params.data?.amounts?.find((event) => amount.event === event.event)?.rate
              )}`,
            columnGroupShow: "open",
            flex: 1,
            headerClass: amountIndex % 2 === 0 ? "oddHeaderClassName" : "evenHeaderClassName",
          },
          {
            field: "amount",
            valueGetter: (params) =>
              `${formatCurrency(
                params.data?.amounts?.find((event) => amount.event === event.event)?.amount,
                "USD"
              )}`,
            flex: 1,
            headerClass: amountIndex % 2 === 0 ? "oddHeaderClassName" : "evenHeaderClassName",
          },
          {
            field: "difference",
            valueGetter: (params) =>
              `${formatCurrency(
                params.data?.amounts?.find((event) => amount.event === event.event)?.difference,
                "USD"
              )}`,
            columnGroupShow: "open",
            flex: 1,
            headerClass: amountIndex % 2 === 0 ? "oddHeaderClassName" : "evenHeaderClassName",
          },
        ],
      }))
    : []

  const { dimensions } =
    programFields
      ?.find(({ fieldName }) => fieldName === "SOV Details Column Definitions")
      ?.pliColumnDefs?.find(({ name }) => name === service?.label) || {}

  const originalElevationData =
    dimensions?.map(({ headerName, field }) => ({
      headerName,
      field,
      columnGroupShow: "open",
      editable: false,
      filter: "agTextColumnFilter",
      flex: 1,
      headerTooltip: `${headerName} from estimation`,
    })) || []

  return [
    {
      headerName: "Id",
      headerTooltip: "Id",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Price",
      headerTooltip: "Price",
      field: "totalPrice",
      cellRenderer: ({ value }) => formatCurrency(value),
      flex: 1,
    },
    ...originalElevationData,
    ...splittedData,
  ]
}
