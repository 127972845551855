import { Modal } from "antd";
import dayjs from "dayjs";
import React from "react";
import { openInNewTab, updateDocumentTitle } from "../../../../../../utils";
import {
  XIcon,
  XIconWhite,
} from "../../../../../SidebarPages/Communication/assets";
import { WarningTriangle } from "../../../../../SidebarPages/DynamicView/src";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { crewConflictsHeader } from "../../SchedulingFirstPage/helperData";
import "./CrewsConflicts.scss";
const CrewsConflicts = (props) => {
  const {
    crewConflicts = {},
    setCrewConflicts = () => {},
    setContinuePostponing = () => {},
  } = props;

  return (
    <div>
      <Modal
        centered
        className="crewConflictsModal"
        open={crewConflicts?.visible}
        onCancel={() => {
          updateDocumentTitle(); // reset document title to "Lead Manager";
          setCrewConflicts({ ...crewConflicts, visible: false });
        }}
        title="Crews Conflicts"
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Crews Conflicts" });
        }}
        closeIcon={<XIcon />}
        width={1000}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MondayButton
              onClick={() => {
                updateDocumentTitle;
                setCrewConflicts({ ...crewConflicts, visible: false });
              }}
              {...{
                className: "mondayButtonRed",
                Icon: <XIconWhite />,
              }}
            >
              Cancel Postponing
            </MondayButton>
            <MondayButton
              onClick={() => {
                updateDocumentTitle(); // reset document title to "Lead Manager";
                setContinuePostponing(true);
                setCrewConflicts({ ...crewConflicts, visible: false });
              }}
              {...{
                Icon: <TickIcon />,
              }}
            >
              Continue Postponing
            </MondayButton>
          </div>
        }
      >
        <div className="crewsConflictsContainer">
          <div className="crewConflictInfo">
            <WarningTriangle />
            <i
              style={{
                fontWeight: 800,
                color: "#E75651",
              }}
            >
              We noticed some crews you have selected are used in another
              project address for the same day!
            </i>
          </div>

          <table style={{ width: "100%" }}>
            <tr>
              {crewConflictsHeader?.map((header) => {
                return <th>{header}</th>;
              })}
            </tr>
            {Object.entries(crewConflicts?.data || {})?.map(([key, value]) => {
              return value?.crews?.map((item) => {
                return (
                  <tr>
                    <td
                      onClick={() =>
                        openInNewTab(`/scheduling/${value?.scheduleId}`)
                      }
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#0f5c97",
                        cursor: "pointer",
                      }}
                    >
                      {key}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item?.crewName}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item?.crewPosition}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {crewConflicts?.date}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {dayjs(item?.startTime).format("h:mm a")}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {dayjs(item?.endTime).format("h:mm a")}
                    </td>
                  </tr>
                );
              });
            })}
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default CrewsConflicts;
