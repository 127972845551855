import { Checkbox, Tooltip } from "antd";
import { useState } from "react";
import "./CheckBox.scss";
const FormItemCheckBox = ({
  value,
  form,
  name,
  label,
  callback,
  className = "",
  checkedClassName = "",
  navyClassName = "",
  disabled,
  onChange: changeHandler = () => {},
  proppedId = "",
  tooltipTitle = "",
}) => {
  const [checked, setChecked] = useState(value);
  return (
    <Tooltip title={tooltipTitle}>
      <div
        id={proppedId || label}
        className={`configElementContainer ${
          className ? className : "checkboxeditFormItem"
        }`.trim()}
        onClick={() => {
          changeHandler();
          if (!callback) {
            setChecked(!checked);
            form && form.setFieldsValue({ [name]: !checked });
          } else {
            callback(!checked, label, proppedId);
          }
        }}
      >
        <div
          className={`bluContainer ${checkedClassName}`.trim()}
          style={{ width: !!checked ? 36 : null }}
        >
          <div
            className={`navyBlueContainer ${navyClassName}`.trim()}
            style={{ opacity: !!checked ? 1 : null }}
          >
            <span style={!checked ? { opacity: 0 } : {}}>
              <Checkbox
                disabled={disabled}
                checked={checked}
                onChange={(e) => {
                  changeHandler();
                  if (!callback) {
                    setChecked(!checked);
                    form && form.setFieldsValue({ [name]: !checked });
                  }
                }}
              />
            </span>
          </div>
        </div>
        <span className="configElementTitle">{label}</span>
      </div>
    </Tooltip>
  );
};
export default FormItemCheckBox;
