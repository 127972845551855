import { SimplePLI } from "./GeneralModels";

export class SystemStairPli extends SimplePLI {
  serviceId = 80;

  width;
  length;
  height;
  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateSystemStairPli extends SimplePLI {
  serviceId = 80;

  width;
  length;
  height;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
