//this is used fo the access ride
export const accessRideHandler = {
  Leads: "Leads",
  Opportunities: "Opportunities",
  Projects: "Projects",
  Documentation: "Documentations",
  Estimations: "Estimations",
  "Add-ons": "Estimations",
  Scheduling: "Scheduling",
  Fleet: "Fleets",
  Dispatching: "Fleets Dispatching",
  Violations: "Fleets Violations",
  "Fleet Inspections": "Fleets Inspections",
  "Fleet Maintenance": "Fleet Maintenance",
  Accounting: "Accounting",
  Applications: "Applications",
  Rentals: "Rentals",
  Charges: "Charges",
  Invoices: "Invoices",
  Claims: "Claims",
  Safety: "Safety",
  Incidents: "Fleets Incidents",
  Defects: "Fleet Maintenance",
  "Work Orders": "Fleet Maintenance",
  "To Do": "To Do",
  "Project Cost": "Project Cost",
  Approvals: "Approvals",
  Inspections: "Inspections",
};

export function handlePricingAccess(routes, view, pricingView, pricingSubView) {
  const allViewTabs = routes?.find(
    (data) => data?.title?.includes(view) && !!data?.children
  );

  if (!!pricingSubView && !!pricingView) {
    return allViewTabs?.children
      .find(({ title = "" }) => title === pricingView)
      ?.children.find(({ title = "" }) => title === pricingSubView)?.write;
  } else if (!!pricingView && !pricingSubView) {
    return routes
      ?.find((data) => data?.title?.includes(view) && !!data?.children)
      ?.children.find(({ title = "" }) => title === pricingView)?.write;
  }
}
