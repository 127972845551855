import { useState } from "react";
import { Breadcrumb, Form } from "antd";
import { useSelector } from "react-redux";
import { PageHeader } from "@ant-design/pro-layout";

import {
  UserList,
  TourGuide,
  CreateNewUser,
  PasswordConfirmation,
  OneTimePasswordConfirmation,
} from "./components";
import { SignupFormData } from "./SignUpCompData";

import "./SignupComp.scss";
import "../../components/pages/Settings/settingsComponents/Roles/RolesSections.scss";

const Signup = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  const [roles, setRoles] = useState([]);
  const [tutorial, setTutorial] = useState(false);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userGroups } = useSelector((state) => state.userGroups);

  const departments = Array.from(
    new Set(userGroups.map((group) => group.departmentName))
  );

  const departmentFields = departments.map((department) => ({
    departmentName: department,
    roles: userGroups
      .filter((item) => item.departmentName === department)
      .map((item) => item.groupName),
  }));

  const setRoleOptions = (value) => {
    setRoles(
      departmentFields.find(({ departmentName }) => departmentName === value)
        .roles
    );
  };

  const populatedFields = SignupFormData(
    departments,
    roles,
    setRoleOptions,
    form,
    isDarkMode
  );

  return (
    <>
      <div className={`SettingsBody ${isDarkMode && "SettingsBodyDark"}`}>
        <PageHeader ghost={false} className="settingsPageHeader">
          <Breadcrumb
            items={[{ title: "User Management" }, { title: "New User" }]}
          />
          <TourGuide {...{ setTutorial }} />
        </PageHeader>
        <Form form={form}>
          <div className="formContainer">
            <>
              <CreateNewUser {...{ populatedFields }} />
              {user || tutorial ? (
                <OneTimePasswordConfirmation {...{ form, setUser }} />
              ) : (
                <PasswordConfirmation {...{ form, populatedFields, setUser }} />
              )}
            </>
            <UserList />
          </div>
        </Form>
      </div>
    </>
  );
};
export default Signup;
