import {
  Dispatch,
  createContext,
  SetStateAction,
  MutableRefObject,
} from "react";
import { FormInstance } from "antd";

import {
  EntryType,
  ShiftEntry,
  MapRefType,
  AccountType,
  FilterTypes,
  ScheduleType,
  CrewTeamType,
  GroupedEntry,
  AnalyticsType,
  SidebarRefType,
  ConfigDataType,
  EmployeeReportType,
  ControlPanelRefType,
  ControlPanelFormType,
} from "./payrollLiveTypes";
import {
  JobsiteType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";

type ContextType = {
  filters: FilterTypes;
  selectedPage: string;
  clientCompany?: string;
  clearFilters: () => void;
  goFullScreen: () => void;
  analytics: AnalyticsType;
  // accounts: Array<AccountType>;
  degEntries: Array<EntryType>;
  jobsites: Array<JobsiteType>;
  crewTeams: Array<CrewTeamType>;
  schedules: Array<ScheduleType>;
  shiftEntries: Array<ShiftEntry>;
  employeesInfo: string | undefined;
  schedulesInfo: string | undefined;
  filteredCrews: Array<CrewTeamType>;
  groupedEntries: Array<GroupedEntry>;
  clientConfigs: Array<ConfigDataType>;
  mapRef: MutableRefObject<MapRefType>;
  onPageSelect: (page: string) => void;
  programEmployees: Array<EmployeeType>;
  employeesReport: Array<EmployeeReportType>;
  filteredEmployees: Array<EmployeeReportType>;
  sidebarRef?: MutableRefObject<SidebarRefType>;
  inPunchedEmployees?: Array<EmployeeReportType>;
  getEmployeeReports: (getLive?: boolean) => void;
  setSelectedPage: Dispatch<SetStateAction<string>>;
  setFilters: Dispatch<SetStateAction<FilterTypes>>;
  filteredSchedules: Array<ScheduleType & JobsiteType>;
  controlPanelForm: FormInstance<ControlPanelFormType>;
  controlPanelRef?: MutableRefObject<ControlPanelRefType>;
  setDegEntries: Dispatch<SetStateAction<Array<EntryType>>>;
  // setAccounts: Dispatch<SetStateAction<Array<AccountType>>>;
  getLatestEntries: (company: string, date?: string) => void;
  setShiftEntries: Dispatch<SetStateAction<Array<ShiftEntry>>>;
  setSchedulesInfo: Dispatch<SetStateAction<string | undefined>>;
  setEmployeesInfo: Dispatch<SetStateAction<string | undefined>>;
  setGroupedEntries: Dispatch<SetStateAction<Array<GroupedEntry>>>;
  setProgramEmployees: Dispatch<SetStateAction<Array<EmployeeType>>>;
  setEmployeesReport: Dispatch<SetStateAction<Array<EmployeeReportType>>>;
};

const initialValue: ContextType = {
  filters: {
    schedules: [],
    crewSearch: "",
    liveStatus: [],
    employeeId: [],
    employeeName: [],
    scheduleMatch: [],
    punchTimeEnd: null,
    employeeNumber: [],
    employeeSearch: "",
    scheduleAddress: "",
    punchTimeStart: null,
  },
  jobsites: [],
  // accounts: [],
  crewTeams: [],
  schedules: [],
  analytics: {
    dateRange: [],
    employeeWeekTotals: {},
    missingLunchEntries: [],
    generalOverheadTotal: 0,
    employeesHoursPerDay: {},
    employeeOver24HShift: {},
    employeeOverheadShifts: {},
    employeeIncorrectShifts: {},
    employeeNotFoundLocations: [],
    shiftsWithMultipleJobsites: [],
  },
  degEntries: [],
  shiftEntries: [],
  clientConfigs: [],
  filteredCrews: [],
  mapRef: undefined,
  groupedEntries: [],
  selectedPage: "map",
  employeesReport: [],
  programEmployees: [],
  setFilters: () => {},
  sidebarRef: undefined,
  // setAccounts: () => {},
  filteredEmployees: [],
  filteredSchedules: [],
  goFullScreen: () => {},
  inPunchedEmployees: [],
  clearFilters: () => {},
  onPageSelect: () => {},
  setDegEntries: () => {},
  clientCompany: undefined,
  employeesInfo: undefined,
  schedulesInfo: undefined,
  setShiftEntries: () => {},
  setSelectedPage: () => {},
  controlPanelRef: undefined,
  setEmployeesInfo: () => {},
  setSchedulesInfo: () => {},
  getLatestEntries: () => {},
  controlPanelForm: undefined,
  setGroupedEntries: () => {},
  getEmployeeReports: () => {},
  setEmployeesReport: () => {},
  setProgramEmployees: () => {},
};

const PayrollLiveContext = createContext(initialValue);

export default PayrollLiveContext;
