import React from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Alert,
  Form,
  Input,
  message,
  Popover,
  InputNumber,
} from "antd";
import LoaderButton from "../../components/LoaderButton";
import "./ForgotPassword.css";
import "../Login/Login.scss";
import Swal from "sweetalert2";

import AuthWrapper from "../AuthWrapper/AuthWrapper";
import { InfoCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import { emailRegex } from "../../components/SidebarPages/utils/validURL";
import { ReactComponent as UserIcon } from "../../icons/Auth_icons/user_icon.svg";
import { ReactComponent as LockIcon } from "../../icons/Auth_icons/lock_icon.svg";
import { ReactComponent as EyeIcon } from "../../icons/Auth_icons/eye_icon.svg";
import { ReactComponent as EyeClosedIcon } from "../../icons/Auth_icons/eye_closed_icon.svg";

const LoginSuccessMessage = () => {
  const navigate = useNavigate();
  function handleClick() {
    localStorage.removeItem("retryTime");
    navigate("/login");
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        marginTop: 30,
      }}
    >
      <span>Password changed successfully</span>
      <span
        style={{
          color: "#1F2A44",
          fontSize: 14,
          fontWeight: 600,
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        Login with your new credentials.
      </span>
    </div>
  );
};

const CORE_LINK = "https://www.corescaffold.com";
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      codeSent: false,
      confirmed: false,
      isConfirming: false,
      isSendingCode: false,
    };
  }
  formRef = React.createRef();

  passwordValidInfo = (
    <div>
      Your password must have at least 8 characters, a lowercase letter, a
      capital letter, a number and a special character.
    </div>
  );

  successPasswordReset = (
    <Link to="/login">Login with your new credentials.</Link>
  );
  coreRedirect = () => {
    return window.open(CORE_LINK, "_blank");
  };

  redirectLogin = () => {
    window.history.back();
    // return this.props.navigate("/login");
  };

  validateResetForm = (code, password, confirmPassword) => {
    return !!code && password.length > 0 && password === confirmPassword;
  };

  handleSendCodeClick = async (email) => {
    this.setState({ email, isSendingCode: true });
    await Auth.forgotPassword(email)
      .then((res) => {
        message.success(
          "A code has been sent to your email. Please check your inbox",
          5
        );
        this.setState({ codeSent: true });
      })
      .catch((e) => {
        Swal.fire({
          text: e.message,
          icon: "warning",
          showConfirmButton: true,
        });
        this.setState({ isSendingCode: false });
      });
  };

  handleKeyPress = (event) => {
    if (event.charCode === 13) {
      console.log("Sending");
    }
  };

  onSendConfirmation = (values) => {
    const email = values.email;
    this.handleSendCodeClick(email);
  };

  onConfirmReset = (values) => {
    const code = values.code.toString();
    const password = values.password;
    const confirmPassword = values.confirmPassword;
    if (this.validateResetForm(code, password, confirmPassword)) {
      this.handleConfirmClick(code, password);
    } else {
      Swal.fire({
        text: "Password doesn't match",
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  handleConfirmClick = async (code, password) => {
    this.setState({ isConfirming: true });
    await Auth.forgotPasswordSubmit(this.state.email, code, password)
      .then((e) => {
        this.setState({ confirmed: true });
        // this.onForgetPasswordEmailSend()
      })
      .catch((e) => {
        Swal.fire({
          text: e.message,
          icon: "error",
          showConfirmButton: true,
        });

        this.setState({ isConfirming: false });
      });
  };

  renderRequestCodeForm() {
    return (
      <Form ref={this.formRef} onFinish={this.onSendConfirmation}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              pattern: new RegExp(emailRegex),
              message: "Invalid email",
            },
          ]}
        >
          <Input
            placeholder="Username/Email..."
            prefix={<UserIcon style={{ height: 25 }} />}
            onKeyPress={this.handleKeyPress}
          />
        </Form.Item>
        <div className="forgotpass_action_btn">
          <LoaderButton
            type="primary"
            className="signin-btn"
            htmlType="submit"
            isLoading={this.state.isSendingCode}
            text="Send Confirmation"
            loadingText="Sending"
          ></LoaderButton>
          <Button
            type="default"
            className="go-back"
            icon={<RollbackOutlined />}
            // onClick={this.redirectLogin}
          >
            <Link to={"/login"}>Go back</Link>
          </Button>
        </div>
      </Form>
    );
  }

  renderConfirmationForm() {
    return (
      <Form ref={this.formRef} onFinish={this.onConfirmReset}>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: "Please input your verification code",
            },
          ]}
        >
          <InputNumber
            placeholder="Verification Code"
            id="verif-code"
            key="verif-code"
            className="code_input"
            controls={false}
            autoFocus={true}
            prefix={
              <Popover
                content={`Please enter the code that was sent to '${this.state.email}'`}
                trigger="hover"
              >
                <InfoCircleOutlined
                  style={{ fontSize: 20, color: "#1F2A44" }}
                />
              </Popover>
            }
          />
        </Form.Item>
        <Alert
          message={this.passwordValidInfo}
          type="warning"
          banner
          style={{
            width: 353,
            lineHeight: 1.4,
            marginBottom: 20,
            flexDirection: "column",
            gap: 5,
            textAlign: "center",
            justifyContent: "center",
          }}
        />
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            placeholder="Password..."
            prefix={<LockIcon style={{ height: 25 }} />}
            onKeyPress={this.handleKeyPress}
            autoComplete="new-password"
            iconRender={(visible) =>
              visible ? <EyeIcon /> : <EyeClosedIcon />
            }
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your password again!",
            },
          ]}
        >
          <Input.Password
            placeholder="Confirm Password..."
            prefix={<LockIcon style={{ height: 25 }} />}
            autoComplete="new-password"
            onKeyPress={this.handleKeyPress}
            iconRender={(visible) =>
              visible ? <EyeIcon /> : <EyeClosedIcon />
            }
          />
        </Form.Item>
        <div className="forgotpass_action_btn">
          <LoaderButton
            type="primary"
            // className="go-back"
            className="signin-btn"
            htmlType="submit"
            isLoading={this.state.isConfirming}
            text="Confirm"
            loadingText="Confirming"
          />
          {/* <Button
            type="default"
            className="signin-btn"
            onClick={this.redirectLogin}
          >
            Sign In
          </Button> */}
        </div>
      </Form>
    );
  }

  renderSuccessMessage() {
    return <LoginSuccessMessage />;
  }

  render() {
    return (
      <AuthWrapper>
        {!this.state.codeSent && !this.state.confirmed ? (
          <h1>Password reset</h1>
        ) : null}
        {/* {this.state.codeSent && this.state.confirmed ? (
              <h1 style={{ margin: "80px 0 60px 0" }}>Password changed</h1>
            ) : null} */}
        {!this.state.codeSent
          ? this.renderRequestCodeForm()
          : !this.state.confirmed
          ? this.renderConfirmationForm()
          : this.renderSuccessMessage()}
      </AuthWrapper>
    );
  }
}
export default ForgotPassword;
