import { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Dropdown, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";

import {
  FleetType,
  SheetsFieldType,
} from "../../../../../../SidebarPages/FleetMaintenanceView/types";
import { useDispatchProgramFields } from "src/hooks";
import { AUDIT_FIELD_NAME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { compareIncluding } from "src/components/SidebarPages/utils";
import { ReportCheck } from "src/components/SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import { DropdownIcon } from "src/icons";
import { HoverButton, MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./LiveSheetModal.scss";

/**
 * @typedef VehicleRow
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string} sheetId
 * @property {string} sheetName
 * @property {number} lastRow
 */

/**
 * @typedef Props
 * @property {boolean} open
 * @property {() => any} onCancel
 * @property {VehicleRow} [vehicleConfig]
 * @property {FleetType[]} [fleet]
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
function LiveSheetModal({ open, onCancel, vehicleConfig, fleet = [] }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);

  const [filterValue, setFilterValue] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState(
    /** @type {{id:string; name:string}[]} */ ([])
  );

  const [form] = Form.useForm();

  /** @type {SheetsFieldType|undefined} */
  const sheetsConfig = useMemo(() => {
    if (!programFields) {
      return undefined;
    }

    return programFields?.find(
      ({ fieldName }) => fieldName === AUDIT_FIELD_NAME
    );
  }, [programFields]);

  useEffect(() => {
    if (!sheetsConfig || form.isFieldsTouched() || !vehicleConfig) {
      return;
    }

    form.setFieldsValue({
      sheetName: vehicleConfig.sheetName,
      sheetId: vehicleConfig.sheetId,
    });

    setSelectedVehicles([
      { id: vehicleConfig.fleetId, name: vehicleConfig.fleetName },
    ]);
  }, [sheetsConfig, vehicleConfig]);

  const allTrucks = selectedVehicles.length > 1;

  const { dispatchFields } = useDispatchProgramFields(
    sheetsConfig?.fieldId,
    sheetsConfig?.fieldName
  );

  /** @param {boolean} [newConfig=false]  */
  async function onConfirm(newConfig = false) {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    try {
      await dispatchFields(changeConfiguration());

      if (newConfig) {
        setSelectedVehicles([]);
        form.setFieldsValue({
          sheetId: "",
          sheetName: "",
        });
      } else {
        onCancel();
      }
    } catch (err) {
      message.destroy();
      void message.error({
        content: "Something went wrong while updating the configurations",
        key: "configs",
      });
    }
  }

  function onSelectTrucks() {
    setSelectedVehicles(
      fleet.flatMap(({ fleetId, fleetName }) => {
        if (fleetName.startsWith("Truck")) {
          return {
            id: fleetId,
            name: fleetName,
          };
        }

        return [];
      })
    );

    trucksInfo();
    form.setFieldValue("sheetName", "");
  }

  function onCheckChange(checked, id, name) {
    let newSelections = [...selectedVehicles];

    if (checked) {
      newSelections.push({ id, name });

      if (newSelections.length > 1) {
        if (newSelections.every(({ name }) => name.startsWith("Truck"))) {
          if (!allTrucks) {
            trucksInfo();
          }
        } else {
          newSelections = [{ name, id }];
        }
      }
    } else {
      newSelections = newSelections.filter(({ id: fId }) => fId !== id);
    }

    if (newSelections.length === 1) {
      const key = process.env.NODE_ENV === "production" ? "prod" : "dev";
      if (!!sheetsConfig?.fieldOptions?.[key]?.vehicleConfigs?.[id]) {
        form.setFieldsValue({
          sheetName:
            sheetsConfig?.fieldOptions?.[key]?.vehicleConfigs?.[id]?.sheetName,
          sheetId:
            sheetsConfig?.fieldOptions?.[key]?.vehicleConfigs?.[id]?.sheetId,
        });
      } else if (newSelections[0]["name"].startsWith("Truck")) {
        form.setFieldValue(
          "sheetName",
          newSelections[0]["name"].replace("Truck ", "")
        );
      } else {
        form.setFieldValue("sheetName", "");
      }
    } else {
      form.setFieldValue("sheetName", "");
    }

    setSelectedVehicles(newSelections);
  }

  function trucksInfo() {
    void message.info({
      content: "The truck number will become the default sheet name",
      key: "allTrucks",
      duration: 3.5,
    });
  }

  function changeConfiguration() {
    const newConfig = {};
    const { sheetId, sheetName } = form.getFieldsValue();
    for (const { id, name } of selectedVehicles) {
      newConfig[id] = {
        sheetName: allTrucks ? name.replaceAll("Truck ", "") : sheetName,
        sheetId,
      };
    }

    const key = process.env.NODE_ENV === "production" ? "prod" : "dev";

    return {
      ...(sheetsConfig?.fieldOptions || {}),
      [key]: {
        ...(sheetsConfig?.fieldOptions?.[key] || {}),
        vehicleConfigs: {
          ...(sheetsConfig?.fieldOptions?.[key]?.vehicleConfigs || {}),
          ...newConfig,
        },
      },
    };
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        title: vehicleConfig
          ? `${vehicleConfig?.fleetName} Configuration`
          : "New Configuration",
        centered: true,
        className: `live-sheet-modal ${
          isDarkMode ? "live-sheet-modal-dark" : ""
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
              key: "cancel",
            }}
            key={"cancel"}
          >
            Cancel
          </MondayButton>,
          <Dropdown
            key="confirm"
            menu={{
              items: [
                !vehicleConfig
                  ? {
                      key: "new",
                      label: "Save and new",
                    }
                  : null,
                {
                  key: "close",
                  label: "Save and close",
                },
              ].filter(Boolean),
              theme: isDarkMode ? "dark" : "light",
              rootClassName: isDarkMode ? "submenu-items-dark" : "",
              className: `NormalSizedModalDropdown ${
                isDarkMode && "NormalSizedModalDropdownDark"
              }`,
              onClick(ev) {
                void onConfirm(ev.key === "new");
              },
            }}
            disabled={!selectedVehicles.length}
          >
            <MondayButton
              {...{
                Icon: <DropdownIcon />,
                disabled: !selectedVehicles.length,
                className: "mondayButtonBlue",
              }}
              key={"confirm"}
            >
              Confirm
            </MondayButton>
          </Dropdown>,
        ],
      }}
    >
      <Form form={form}>
        <div className="live-sheet-controller">
          <InputComponent
            {...{
              noFormItem: true,
              label: null,
              placeholder: "Search vehicles...",
              prefix: <SearchOutlined />,
              onChange(e) {
                setFilterValue(e.target.value);
              },
            }}
          />
          <div className="controller-inner">
            {!vehicleConfig ? (
              <HoverButton
                {...{
                  text: 'Select all "Trucks"',
                  type: "action",
                  icon: <TickIcon />,
                  onClick: onSelectTrucks,
                }}
              />
            ) : null}
            {selectedVehicles?.length && !vehicleConfig ? (
              <HoverButton
                {...{
                  text: "Clear Selections",
                  type: "decline",
                  icon: <XIcon />,
                  onClick() {
                    setSelectedVehicles([]);
                    form.setFieldValue("sheetName", "");
                  },
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="vehicle-list">
          {fleet.flatMap((vehicle) => {
            if (compareIncluding(vehicle?.fleetName || "", filterValue)) {
              return (
                <div className="vehicle-option" key={vehicle.fleetId}>
                  <ReportCheck
                    label={vehicle?.fleetName}
                    id={vehicle.fleetId}
                    checked={
                      selectedVehicles.find(
                        ({ id }) => vehicle.fleetId === id
                      ) || false
                    }
                    disabled={!!vehicleConfig}
                    onChange={(checked, id) => {
                      onCheckChange(checked, id, vehicle?.fleetName);
                    }}
                  />
                </div>
              );
            }

            return [];
          })}
        </div>
        <div className="configuration-container">
          <InputComponent
            {...{
              label: "Sheet Name",
              formItemName: "sheetName",
              required: !allTrucks,
              disabled: allTrucks,
              placeholder: "Sheet name...",
            }}
          />
          <InputComponent
            {...{
              label: "Sheet Id",
              formItemName: "sheetId",
              required: true,
              placeholder: "Sheet id...",
              defaultValue:
                sheetsConfig?.fieldOptions?.[
                  process.env.NODE_ENV === "production" ? "prod" : "dev"
                ]?.defaultSheetId,
              type: "autocomplete",
              customOptions: [
                {
                  value:
                    sheetsConfig?.fieldOptions?.[
                      process.env.NODE_ENV === "production" ? "prod" : "dev"
                    ]?.defaultSheetId,
                },
              ].filter(({ value }) => !!value),
            }}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default LiveSheetModal;
