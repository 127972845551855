import { useEffect, useState } from "react";
import _ from "lodash";
import StyledButton from "../../components/StyleButton/StyledButton";
import "./CustomStatus.scss";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";

const CustomStatus = (prop) => {
  const {
    params,
    getEditedValue,
    setEditMode,
    setFormChanged = () => {},
    getFormChanged = () => {},
  } = prop;
  const { value, formItemName, options } = params.data;

  const { programFields = [] } = useSelector((state) => state.programFields);

  const types = options[1];
  const allButtons = programFields
    .find((item) => item.fieldName === "Statuses Of Categories")
    .fieldOptions["Estimations"].filter(({ status }) => status);

  const [status, setStatus] = useState(value);
  const [buttons, setButtons] = useState(
    types[value]?.map((e) => allButtons.find((el) => el.statusName === e))
  );
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [prev, setPrev] = useState();

  useEffect(() => {
    setPrev(() => _.cloneDeep(prop));
  }, [prop]);
  ///  finished

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  useEffect(() => {
    setButtons(
      types[status]?.map((e) => allButtons.find((el) => el.statusName === e))
    );
  }, [status]);

  return (
    <div className="CustomStatusContainer">
      <div className="CustomStatusDevider"></div>
      <div className="CustomStatusButtonContainer">
        {buttons && buttons?.length !== 0
          ? buttons?.map((e) => {
              return (
                <div
                  onClick={(b) => {
                    setFormChanged(true);
                    if (e?.statusName === "Resume") {
                      setStatus("Started");
                      params.getDEGValue &&
                        params.getDEGValue("Started", setStatus);
                    } else {
                      setStatus(e?.statusName);
                      params.getDEGValue &&
                        params.getDEGValue(e?.statusName, setStatus);
                    }
                  }}
                  className="CustomStatusContainerButtonStyle"
                  style={{ background: e?.statusColor }}
                >
                  <span className="CustomStatusContainerButtonText">
                    {e?.statusName}
                  </span>
                </div>
              );
            })
          : params.getDEGValue
          ? `This Estimation is ${status}`
          : `The Estimation is ${status}`}
      </div>
      <div className="CustomStatusDevider"></div>
      {params.getDEGValue === undefined && (
        <div className="confirmContainer">
          <MondayButton
            onClick={(res) => {
              if (getFormChanged()) {
                setCancelModalVisible(true);
              } else {
                onCancel();
              }
            }}
            className="cancelButton mondayButtonRed"
            Icon={<XIcon />}
          >
            Cancel
          </MondayButton>
          <MondayButton
            onClick={(res) => {
              prop.params.data.value = status;
              getEditedValue(
                { [formItemName]: status },
                undefined,
                {
                  [formItemName]: value,
                },
                prev,
                prop
              );
              setEditMode(false);
            }}
            className="confirmButton"
            Icon={<Tick width={20} height={20} />}
          >
            Confirm
          </MondayButton>
        </div>
      )}
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default CustomStatus;
