import { getDispatchLocationOptions } from "../../../../../Header/forms/RequestFormModal/requestFormModalData";
import searchIncidentAddress from "../searchIncidentAddress";

function addConditionalFields({
  field,
  fields,
  formItemName,
  immediateActions,
  typeOfEquipment,
  fieldCategoryIndex,
}) {
  if (
    formItemName === "describeTheImmediateActionsTakenFollowingTheIncident" &&
    (immediateActions === "Escorted by" || immediateActions === "Other")
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherAction",
      label:
        immediateActions === "Escorted by" ? "Escorted By" : "Other Action",
      required: true,
      placeholder:
        immediateActions === "Escorted by" ? "Escorted By" : "Other Action",
      category: field.category,
    });
  }

  if (
    formItemName === "typeOfEquipmentInvolved" &&
    typeOfEquipment === "Other"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      ...field,
      formItemName: "otherInvolvement",
      label: "Other Involvement",
      type: "input",
    });
  }
}

const otherIncidentFields = ({
  form,
  projects = [],
  defaultId = "",
  scheduling = [],
  propAddress = "",
  dynamicFields = [],
  isDarkMode = false,
  typeOfEquipment = "",
  immediateActions = "",
  schedulesForDate = [],
  userConfiguration = {},
  disableAllFields = false,
  mainDisabledField = false,
  onChangeAddress = () => {},
  changeDateOfIncident = () => {},
  dispatchIncidentFormData = () => {},
}) => {
  let fields = [];

  for (let i = 0; i < dynamicFields?.length; i++) {
    let field = structuredClone(dynamicFields[i]);

    const { formItemName, type, label } = field;

    if (type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
      };
    }

    if (type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    }

    if (type === "radio") {
      field = {
        ...field,
        style: {
          borderRadius: 10,
          backgroundColor: isDarkMode ? "#12131B" : "#f5f5f7",
          padding: 10,
        },
      };
    }

    if (formItemName === "reportPreparedBy") {
      delete field.options;
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: userConfiguration?.allUsers?.Items?.map((user) => {
          return { value: user?.identityId, label: user?.nameOfUser };
        }),
        style: { width: 200 },
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    }

    if (formItemName === "fullNameOfInjuredIndividual") {
      field = {
        ...field,
        disabled: mainDisabledField,
      };
    }

    if (
      formItemName === "dateOfIncident" ||
      formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(formItemName === "dateOfIncident"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
        // disabledDate(e) {
        //   return e > dayjsNY().add(1, "day").startOf("D").valueOf();
        // },
      };
    }

    if (formItemName === "timeOfIncident") {
      field = {
        ...field,
        validTime: "past",
        dateField: "dateOfIncident",
        form,
      };
    }
    if (formItemName === "incidentAddress") {
      field = {
        ...field,
        // disabled: addressDisabled,
        onSelect: onChangeAddress,
        initialValue: propAddress,
        groupedOptions: getDispatchLocationOptions(schedulesForDate, projects),
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchIncidentAddress({
            searchValue,
            dispatchIncidentFormData,
            userConfiguration,
            projects,
            scheduling,
            schedulesForDate,
            dateField: "dateOfIncident",
            form,
          }),
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );

    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);

      addConditionalFields({
        fields,
        fieldCategoryIndex,
        immediateActions,
        typeOfEquipment,
        formItemName,
        field,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        fieldCategoryIndex: field.length - 1,
        immediateActions,
        typeOfEquipment,
        formItemName,
        field,
      });
    }

    if (disableAllFields) {
      field.disabled = disableAllFields;
    }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );

  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  return fields;
};

export default otherIncidentFields;
