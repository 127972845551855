import types from "../../types"

const initialState = {
  fleetFields: undefined,
}

const fleetFieldsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_FLEET_FIELDS:
      return {
        ...state,
        fleetFields: payload,
      }
    default:
      return state
  }
}

export default fleetFieldsReducer
