import { Menu, Layout } from "antd";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SubmenuArrow } from "../../../../icons";
import { SideBarSubsConfig } from "../../SidebarData";

const PagesMenu = ({
  pages: pagesProps = [],
  isLoaded,
  collapsed,
  isDarkMode,
}) => {
  let location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState();

  const pages = useMemo(
    () =>
      pagesProps.reduce((acc, currentValue) => {
        for (const [key, value] of Object.entries(SideBarSubsConfig)) {
          if (!acc[key]) {
            acc[key] = { children: [] };
          }
          if (key.toLowerCase() === currentValue.title.toLowerCase()) {
            acc[key].children.push(currentValue);
          }
          if (value.children.includes(currentValue.id)) {
            acc[key].children.push(currentValue);
          }
          if (value.src) {
            acc[key].src = value.src;
          }
        }
        return acc;
      }, {}),
    [pagesProps]
  );

  useEffect(() => {
    const currentPath = `/${location.pathname.split("/")[1]}`;
    setCurrentPage(currentPath);
  }, [location]);

  const MenuItems = Object.entries(pages)
    ?.map((item, key) => {
      const SubMenuIcon = item[1]?.src;
      return (
        !!isLoaded &&
        (item[1]?.children.length === 1
          ? {
              label: item[1].children[0]?.title,
              key: item[1].children[0]?.to,
              icon: SubMenuIcon && <SubMenuIcon className="pageIcon" />,
            }
          : item[1].children.length > 1
          ? {
              label: item[0],
              key,
              icon: (
                <span style={{ fill: "#fff" }}>
                  <SubMenuIcon style={{ width: "20px" }} />
                </span>
              ),
              popupOffset: [5, 0],
              popupClassName: `sidebar-submenu-popup ${
                isDarkMode && "sidebar-submenu-popup-dark"
              }`,
              children: item[1].children.map((el, i) => {
                const MenuSubMenuIco = el?.src;
                return {
                  label: (
                    <NavLink
                      key={i}
                      to={"#"}
                      //  to={el?.to}
                    >
                      <div className="pageContainer">
                        <span className="pageIconContainer">
                          {MenuSubMenuIco && (
                            <MenuSubMenuIco className="pageIcon" />
                          )}
                        </span>
                        <span className="pageTitle">{el?.title}</span>
                      </div>
                    </NavLink>
                  ),
                  key: el?.to,
                };
              }),
            }
          : false)
      );
    })
    .filter(Boolean);

  return (
    <div className="menu">
      <Menu
        style={{
          backgroundColor: "transparent ",
        }}
        mode="vertical"
        selectedKeys={[currentPage]}
        expandIcon={
          <span
            style={{
              width: "25px",
              transform: "rotate(-90deg)",
              transformOrigin: "30% 30%",
            }}
          >
            <SubmenuArrow />
          </span>
        }
        onClick={({ key }) => navigate(key)}
        items={MenuItems}
      />
    </div>
  );
};

export default PagesMenu;
