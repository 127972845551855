import moment from "moment-timezone";
import { useState, useEffect } from "react";
import { doubleFormatNumber } from "./doubleFormatNumber";
/**
 * Calculates time remaining between 2 dates and returns in whatever format you want(with or without seconds etc..)
 * @param {start} number - Start date as timestamp in miliseconds
 * @param {end} number - End date as timestamp in miliseconds
 */
const timeLoggedIn = ({
  text = "",
  start,
  end,
  className = "",
  show = false,
  includeDays = false,
  includeHours = false,
  includeMinutes = false,
  includeSeconds = false,
}) => {
  // Convert to object to make difference
  let startObj = new Date(start);
  let endObj = end ? end : new Date(moment().valueOf());

  const [diffInSeconds, setDiffInSeconds] = useState(
    Math.abs(endObj - startObj) / 1000.0000001
  );

  useEffect(() => {
    let intervalId = setInterval(() => {
      setDiffInSeconds(Math.abs(endObj - startObj) / 1000);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [diffInSeconds]);

  //hours cant be more than 24 in definition
  const limit24hFormat = (hours) => {
    if (hours < 24) {
      return hours;
    } else {
      return Math.floor(Math.floor(hours) / 24);
    }
  };

  // // Making calculations for respective time types
  // let diffInSeconds = Math.abs(endObj - startObj) / 1000;
  let days = Math.floor(diffInSeconds / 60 / 60 / 24);
  let hours = Math.floor(diffInSeconds / 60 / 60);
  let maxHours = limit24hFormat(hours);
  let minutes = Math.floor((diffInSeconds / 60) % 60);
  let seconds = Math.floor(diffInSeconds % 60);

  // Return them as string to display them
  days = `${doubleFormatNumber(days)}:`;
  maxHours = `${doubleFormatNumber(maxHours)}:`;
  minutes = `${doubleFormatNumber(minutes)}:`;
  seconds = `${doubleFormatNumber(seconds)}`;

  return (
    show && (
      <div className={className}>
        {text && <h6>{text}</h6>}
        {end === null ? (
          "0"
        ) : (
          <span>
            {includeDays && days}
            {includeHours && maxHours}
            {includeMinutes && minutes}
            {includeSeconds && seconds}
          </span>
        )}
      </div>
    )
  );
};

export default timeLoggedIn;
