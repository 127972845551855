import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DimensionPricer } from "./components";
import { v4 as uuid } from "uuid";
import "./PriceTable.scss";
import { dimensionsSorter, isDimensionPricerDuplicate } from "./utils";
import { MondayButton } from "../../../../../../commonComponents";
import { Plus } from "../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import { uniq } from "lodash";
import { updateArrayElement } from "../../../../../../../utils";
import { useStateCallback } from "../../../../../../../hooks";

const PriceTableTakeOff = ({ prices, disabled, onChange }, ref) => {
  const [pricesData, setPricesData] = useStateCallback(prices || []);

  console.log("prices", prices);

  //Checks if the dimensions are filled correctly
  const hasErrors = () => {
    const uniqDimensions = uniq(pricesData.map(({ length }) => length));

    return (
      pricesData.some(({ length, price }) => !length || !price) || //checks if every dimension has values
      uniqDimensions.length !== pricesData.length // checks if there is any duplicates
    );
  };

  useImperativeHandle(ref, () => ({ getValue: () => pricesData, hasErrors }));

  //Deletes a dimension
  const onDimensionDelete = (idToDelete) => () => {
    let newPricesData;

    setPricesData(
      (prev) => (
        (newPricesData = prev.filter(({ id }) => id !== idToDelete)),
        newPricesData
      ),
      () => onChange?.(newPricesData)
    );
  };

  //handles dimension value change
  const onDimensionChange = (id) => (value) => {
    const newPrices = updateArrayElement(
      pricesData,
      { id, ...value },
      "id"
    ).sort(dimensionsSorter);
    onChange?.(newPrices);
    setPricesData(newPrices);
  };

  //adds an empty dimension
  const addDimension = () => setPricesData((prev) => [...prev, { id: uuid() }]);

  useEffect(() => {
    if (!prices) return;
    setPricesData(prices);
  }, [prices]);

  return (
    <div className="singleDimensionPriceTable">
      <div className="priceTable">
        <div className="table-labels">
          <div>Unit</div>
          <div>Price</div>
        </div>
        {pricesData.map(({ id, length, price }, idx, array) => (
          <DimensionPricer
            {...{
              key: id,
              disabled,
              value: { length, price },
              onDelete: onDimensionDelete(id),
              onChange: onDimensionChange(id),
              isDuplicate: isDimensionPricerDuplicate(id, length, array),
              isLastChild: idx === array.length - 1,
            }}
          />
        ))}
      </div>
      {!disabled && (
        <MondayButton Icon={<Plus />} onClick={addDimension}>
          Add Price
        </MondayButton>
      )}
    </div>
  );
};

export default memo(forwardRef(PriceTableTakeOff));
