import { EditIcon } from "../../../../../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../../../../../utils";
import DateCollision from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/AgGridComponents/DateCollision/DateCollision";
import DateInput from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/AgGridComponents/DateInput/DateInput";
import RichTextModal from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/AgGridComponents/RichTextModal/RichTextModal";
import { getWeeksDiff } from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/columnDefs";
import moment from "moment";
import ChargedIndicator from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/AgGridComponents/RentalDetails/Components/ChargedIndicator/ChargedIndicator";
import RichTextEditorWrapper from "../../../../../../../../../../../commonComponents/RichTextEditor/RichTextEditorWrapper";
import RentalDetails from "../../../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/AgGridComponents/RentalDetails/RentalDetails";
import { message } from "antd";
import { multiply, divide } from "lodash";
import { capitalizeFirstLetter } from "../../../../../../../../../../Estimations/DataEntryGrid/tools/polyfillers";
export const columnDefs = ({
  elevationData,
  programFields,
  selectedService,
}) => {
  const serviceDimensions = programFields
    ?.find(({ fieldName }) => fieldName === "Mapping of Progress")
    ?.fieldOptions?.find(({ name }) => name === selectedService)?.dimensions;

  console.log("serviceDimensions", serviceDimensions);
  console.log("elevationData", elevationData);

  // const estimationData = serviceDimensions?.map((data) => ({
  // 	headerName: `${capitalizeFirstLetter(data.charAt(0))}`,
  // 	field: data,
  // 	editable: false,
  // 	filter: "agTextColumnFilter",
  // 	columnGroupShow: "open",
  // 	cellRendererFramework: (params) => {
  // 		console.log("params", params);
  // 		if (!!params?.data?.totalProgress)
  // 			return (
  // 				<div className="estimationCellsContainer">
  // 					{params?.column?.colId === "length"
  // 						? params.value * params?.data?.totalProgress
  // 						: params.value}
  // 				</div>
  // 			);
  // 	},
  // 	minWidth: 100,
  // 	flex: 5,
  // 	headerTooltip: `${capitalizeFirstLetter(data)} from estimation`,
  // }));

  const schedulingData = serviceDimensions?.map((data) => ({
    headerName: `${capitalizeFirstLetter(data.charAt(0))}`,
    field: `scheduling${capitalizeFirstLetter(data)}`,
    editable: false,
    filter: "agTextColumnFilter",
    columnGroupShow: "open",
    cellRendererFramework: ({ value }) => (
      <div className="schedulingCellsContainer">{value}</div>
    ),
    minWidth: 100,
    flex: 5,
    headerTooltip: `${capitalizeFirstLetter(data)} from schedule`,
  }));

  return [
    {
      headerName: "PLI",
      field: "id",
      filter: "agTextColumnFilter",
      editable: false,
      cellRenderer: (params) => params?.rowIndex + 1,
      headerTooltip: "PLI number",
      minWidth: 70,
      flex: 3.5,
    },
    {
      headerName: "Charged",
      field: "charged",
      editable: false,
      cellRendererFramework: (params) => (
        <ChargedIndicator
          {...{
            thisElevation: elevationData?.elevationId,
            whichOne: "pli",
            params: { thisPli: params?.data?.id },
          }}
        />
      ),
      minWidth: 120,
      flex: 6,
    },

    // {
    // 	headerName: "Apply",
    // 	field: "applied",
    // 	filter: "agTextColumnFilter",
    // 	editable: false,
    // 	cellRendererFramework: (params) => (
    // 		<div className="applyCheckboxParams">
    // 			<ApplyRentalCheckBox {...{ pliData: params?.data, elevationData, whichOne: "pli" }} />
    // 			Charge Rental
    // 		</div>
    // 	),
    // 	minWidth: 150,
    // 	flex: 7.5,
    // },
    // {
    // 	headerName: "Details",
    // 	field: "id",
    // 	editable: false,
    // 	filter: "agTextColumnFilter",
    // 	cellRendererFramework: (gridParams) => {
    // 		return <RentalDetails {...{ elevationData, allRow: gridParams?.data }} />;
    // 	},
    // 	minWidth: 120,
    // 	flex: 6,
    // },
    {
      headerName: "Timeframe",
      headerTooltip: "Rental timeframe",
      cellRendererFramework: (params) => {
        let startDate = params?.data?.rentalDetails?.startDate || null;
        let endDate = params?.data?.rentalDetails?.endDate || null;
        let period = "N/A";
        if (!!startDate && !!endDate) {
          period = `${moment(startDate).format("MM/DD/YYYY")} - ${moment(
            endDate
          ).format("MM/DD/YYYY")}`;
        }
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {period}
          </div>
        );
      },
      minWidth: 200,
      flex: 4,
    },

    {
      headerName: "# of Weeks",
      field: "numberOfWeeks",
      headerTooltip: "Number of weeks",
      editable: false,
      valueGetter: (params) => {
        console.log("params", params);
        let endDate = !!params.data.rentalDetails.endDate
          ? moment(params.data.rentalDetails.endDate)
          : null;
        let startDate = !!params.data.rentalDetails.startDate
          ? moment(params.data.rentalDetails.startDate)
          : null;
        let numberOfWeeks = getWeeksDiff(endDate, startDate);
        return numberOfWeeks;
      },

      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Applied Rent",
      field: "appliedAmount",
      headerTooltip: "Applied rent",
      editable: false,
      // cellEditor: "PeriodCellEditor",
      cellRendererFramework: ({ value }) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {formatCurrency(value || 0)}
          {/* <EditIcon /> */}
        </div>
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Tax Applicable",
      field: "projectTaxRate",
      headerTooltip: "Tax applicable to this project",
      editable: false,
      valueGetter: (params) => {
        console.log("amount", params.data.appliedAmount);
        return params?.data?.taxRate * params?.data?.appliedAmount || 0;
      },
      cellRendererFramework: ({ value }) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {formatCurrency(value || 0)}
        </div>
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Retainage",
      field: "retainage",
      editable: false,
      headerTooltip: "Retainage amount",
      valueGetter: (params) => {
        const total =
          params?.data?.taxRate * params?.data?.appliedAmount +
            params?.data?.appliedAmount || params?.data?.appliedAmount;
        let retainageTemp = params?.data?.retainage || 0;
        if (retainageTemp > 0) {
          return multiply(divide(retainageTemp, 100), total);
        } else return 0;
      },

      // valueGetter: (params) => {
      // 	console.log("params", params);
      // 	let retainageTemp = params?.data?.retainage || 0;
      // 	if (retainageTemp > 0) {
      // 		let TaxAm = params?.data?.taxRate * params?.data?.appliedAmount || 0;
      // 		let Amount = params?.data?.appliedAmount || 0;
      // 		return (retainageTemp / 100) * (TaxAm + Amount);
      // 	} else return 0;
      // },
      cellRendererFramework: ({ value }) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {formatCurrency(value || 0)}
        </div>
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Applied + Tax",
      field: "totalPay",
      headerTooltip: "Total amount without Retaianage or Credit",
      editable: false,
      valueGetter: (params) => {
        return (
          params?.data?.taxRate * params?.data?.appliedAmount +
            params?.data?.appliedAmount || params?.data?.appliedAmount
        );
      },
      cellRendererFramework: ({ value }) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {formatCurrency(value || 0)}
        </div>
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Credit",
      field: "elevationLevelCredit",
      editable: true,
      headerTooltip: "Credit amount in Elevation level",
      minWidth: 160,
      cellStyle: {
        color: "white",
        backgroundColor: "#68C142",
      },
      flex: 8,
      cellRenderer: ({ data }) => {
        return formatCurrency(data?.elevationLevelCredit || 0);
      },
      valueSetter: (params) => {
        console.log("params", params);

        if (
          params?.newValue?.includes("%") &&
          !isNaN(parseFloat(params?.newValue?.replaceAll("%", "")))
        ) {
          let num = params?.newValue?.replaceAll("%", "") || 0;
          let tApplied =
            params?.data?.taxRate * params?.data?.appliedAmount +
              params?.data?.appliedAmount || params?.data?.appliedAmount;

          params.data.elevationLevelCredit =
            parseFloat(params.newValue) > 0
              ? -parseFloat((num / 100) * tApplied)
              : parseFloat((num / 100) * tApplied);

          params.api.refreshCells({ force: true });
          return true;
        } else if (isNaN(params.newValue)) {
          message.error("Please insert a valid number");
          return false;
        } else if (params.newValue > 1000000000000000) {
          message.error("input is larger than allowed");
          return false;
        } else {
          params.data.elevationLevelCredit =
            parseFloat(params.newValue) > 0
              ? -parseFloat(params.newValue)
              : parseFloat(params.newValue);
          params.api.refreshCells({ force: true });
          return true;
        }
      },
    },
    // {
    //   headerName: "T-C",
    //   // editable: true,
    //   minWidth: 160,
    //   flex: 8,
    //   cellStyle: {
    //     color: "white",
    //     fontWeight: "bold",
    //     // backgroundColor: "#228CDB",
    //   },
    //   cellRenderer: ({ data }) => {
    //     console.log("data", data);
    //     let credit = data?.elevationLevelCredit || 0;

    //     if (data?.taxRate > 0) {
    //       let taxAmount = data?.taxRate * data?.appliedAmount;
    //       return formatCurrency(data?.appliedAmount + taxAmount + credit);
    //     } else {
    //       return formatCurrency(data?.appliedAmount + credit);
    //     }
    //   },
    // },

    // {
    // 	headerName: "Progress",
    // 	field: "totalProgress",
    // 	cellEditor: "PeriodCellEditor",
    // 	editable: true,
    // 	cellRendererFramework: ({ value }) => (
    // 		<div className="rentalEditableCell">
    // 			{formatNumberToPercentage(value * 100)}
    // 			<EditIcon />
    // 		</div>
    // 	),
    // 	minWidth: 130,
    // 	flex: 6.5,
    // },

    // {
    // 	headerName: "Sched/Est Data",
    // 	marryChildren: false,
    // 	children: [
    // 		...schedulingData,
    // 		...estimationData,
    // 		{
    // 			headerName: "Max Weekly Amount",
    // 			field: "maxWeeklyAmount",
    // 			editable: false,
    // 			columnGroupShow: "open",
    // 			filter: "agTextColumnFilter",
    // 			cellRenderer: ({ value }) => formatCurrency(+value || 0),
    // 		},
    // 		{
    // 			headerName: "Description",
    // 			field: "description",
    // 			filter: "agTextColumnFilter",
    // 			editable: false,
    // 			cellRendererFramework: (gridParams) => {
    // 				return (
    // 					<RichTextModal
    // 						{...{
    // 							title: "Description",
    // 							value: gridParams?.value,
    // 							buttonText: "Description",
    // 							saveAvailable: false,
    // 						}}
    // 					/>
    // 				);
    // 			},
    // 			minWidth: 160,
    // 			flex: 8,
    // 		},
    // {
    // 	headerName: "Notes",
    // 	field: "note",
    // 	editable: false,
    // 	filter: "agTextColumnFilter",
    // 	cellRendererFramework: (gridParams) => {
    // 		return <RichTextEditorWrapper {...{ name: "note", initialValue: gridParams?.value }} />;
    // 	},
    // 	minWidth: 130,
    // 	flex: 6.5,
    // },
    // 	],
    // },
  ];
};

export const valueChangerHandler = (params) => {};
