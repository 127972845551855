import dayjs from "dayjs";

/**
 * Updates the input fields of a schedule day based on the provided index, event, reference, and selected schedule.
 *
 * @param {number} index - The index of the schedule day in the input fields array.
 * @param {Event} event - The event object containing the updated value.
 * @param {string} reference - The reference indicating which input field is being updated (e.g., "startDate", "endDate", "bothDates").
 * @param {Schedule} selectedSchedule - The selected schedule object.
 * @returns {ScheduleDay[]} - The updated input fields array sorted by start date.
 */
function inputChangeData(index, event, reference, selectedSchedule) {
  const inputFieldsCopy = _.cloneDeep(selectedSchedule?.scheduleDays);

  let scheduleDay = inputFieldsCopy[index];
  try {
    switch (reference) {
      case "startDate": {
        const startDateTime = dayjs(event).tz("America/New_York");
        scheduleDay.startDate = dayjs(startDateTime).tz("America/New_York");
        scheduleDay.setWeather([]);
        break;
      }
      case "endDate": {
        scheduleDay.endDate = dayjs(event).tz("America/New_York");
        break;
      }
      case "bothDates": {
        scheduleDay.startDate = event[0];
        scheduleDay.endDate = event[1];
      }
      default:
        break;
    }
  } catch (e) {
    console.log(e);
  }

  return inputFieldsCopy?.sort?.(
    (a, b) => dayjs(a?.startDate) - dayjs(b?.startDate)
  );
}

export default inputChangeData;
