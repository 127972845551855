import { formatCurrency } from "../../../../../utils";
import {
  calculateInvoiceStatus,
  categoriesName,
  getSums,
  groupBy,
} from "../../../calculatingFunctions";

export const invoices = ({ tables, circleProgressPicker }) => {
  const filteredData = calculateInvoiceStatus({
    table: tables?.invoices,
    status: circleProgressPicker,
  });

  return {
    invoiceStatus: () => ({
      percent: (
        ((filteredData?.length || 0) / tables?.invoices?.length) *
        100
      )?.toFixed(1),
      custom:
        filteredData &&
        groupBy([filteredData], "projectName")?.map((el, index) => {
          return [
            {
              "Total Amount": {
                "Invoice Amount With Tax": formatCurrency(
                  getSums({ data: [filteredData], key: "totalInvoiceAmount" })[
                    index
                  ] || 0
                ),
                "Invoice Amount Without Tax": formatCurrency(
                  getSums({ data: [filteredData], key: "subtotal" })[index] || 0
                ),
                "Total Tax": formatCurrency(
                  (getSums({ data: [filteredData], key: "totalInvoiceAmount" })[
                    index
                  ] || 0) -
                    (getSums({ data: [filteredData], key: "subtotal" })[
                      index
                    ] || 0)
                ),
              },
            },
            ...Object.entries(el).map(([key, value]) => {
              return {
                [key]: {
                  projectId: value?.[0]?.projectId,
                  "Invoice Amount With Tax": formatCurrency(
                    getSums({ data: value, key: "totalInvoiceAmount" })
                  ),
                  "Invoice Amount Without Tax": formatCurrency(
                    getSums({ data: value, key: "subtotal" })
                  ),
                  "Total Tax": formatCurrency(
                    getSums({ data: value, key: "totalInvoiceAmount" }) -
                      getSums({ data: value, key: "subtotal" })
                  ),
                },
              };
            }),
          ];
        }),
    }),
  };
};
