import { useCallback, useState } from "react";
import { Checkbox, message } from "antd";
import { useRedux } from "../../../../hooks/useRedux";

function ApprovedRend(props) {
  const [checked, setChecked] = useState();
  const [isWritable] = useRedux("estimationsIsWritable");

  console.log("isWritable", isWritable);

  const handleCheck = useCallback(
    (event) => {
      //   if (isWritable) {
      const checked = event.target.checked;
      props.toggleApproveAll(checked, props.api);
      setChecked(checked);
      // this.props.node.setDataValue(this.props.column.colId, {jumpWidth: this.state.jumpWidth, checked: checked});
      // // this.props.api.redrawRows({rowNodes: [this.props.node]})
      // this.props.api.refreshCells({rowNodes: [this.props.node]})
      //
      // if(checked === false) this.props.api.getRowNode(this.props.node.id).setExpanded(false) //close addons table
      //   }
      //   else {
      //     message.error("Please enable write mode to start editing.");
      //   }
    },
    [isWritable, props]
  );

  const shouldBeChecked = useCallback(() => {
    let allApproved = true;
    props.api.forEachNode((n) => {
      if (n.data.approved !== true) allApproved = false;
    });
    setChecked(allApproved);
    return allApproved;
  }, [props.api]);

  return (
    <div className="approved-header">
      <Checkbox
        className={"checkboxRenderer-" + props.column.colId}
        onChange={handleCheck}
        disab
        checked={checked}
      />
      Approved
    </div>
  );
}

export default ApprovedRend;
