import dayjs from "dayjs";

const taskDaysLeft = (startDate, deadline) => {
  const daysLeft = dayjs(deadline).diff(dayjs(), "day");
  if (startDate === null || deadline === null) {
    return "Not set";
  } else {
    if (dayjs().diff(dayjs(startDate), "day") < 0) {
      return daysLeft;
    } else if (dayjs(deadline).diff(dayjs(), "day") < 0) {
      return daysLeft;
    } else return daysLeft;
  }
};

export default taskDaysLeft;
