const formatSubcontractors = (subcontractors = []) =>
  subcontractors.map(({ accountName }) => accountName)

export const fieldsJSON = ({
  attorneys,
  electricians,
  boomCompanies,
  expeditors,
  safetyCompanies,
  insuranceBrokers,
}) => [
  {
    label: "Expeditor",
    formItemName: "expeditor",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: formatSubcontractors(expeditors),
  },
  {
    label: "Boom Company",
    formItemName: "boomCompany",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: formatSubcontractors(boomCompanies),
  },
  {
    label: "Safety Company",
    formItemName: "safetyCompany",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: formatSubcontractors(safetyCompanies),
  },
  {
    label: "Electrician",
    formItemName: "electrician",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: formatSubcontractors(electricians),
  },
  {
    label: "Insurance Broker",
    formItemName: "insuranceBroker",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: formatSubcontractors(insuranceBrokers),
  },
  {
    label: "Attorney",
    formItemName: "attorney",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: formatSubcontractors(attorneys),
  },
]

export const subcontractorsStepFormItemNames = fieldsJSON({}).map(
  ({ formItemName }) => formItemName
)
