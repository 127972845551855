import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import { initialFilters } from "../utils";
import { fetchData } from "../../../SidebarPages/Fleet/utils";
import { removeEmptyConditionsAndFilters } from "../../../../utils";

export const useAdvancedFilter = ({ customFilterTitle, fetchAllHandler }) => {
  const { preferences } = useSelector((state) => state.preferences);

  const selectedPreferences = preferences?.basepages?.[customFilterTitle];

  const defaultFilters =
    selectedPreferences?.[selectedPreferences?.defaultFilter]
      ?.advancedFiltersState;

  const hasDefaultFilters =
    defaultFilters &&
    removeEmptyConditionsAndFilters(defaultFilters)?.length > 0;

  const currentFilters =
    hasDefaultFilters?.length > 0 ? defaultFilters : initialFilters;

  const [filters, setFilters] = useState(currentFilters);

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const handleInputChange = (
    groupIndex,
    conditionIndex,
    field,
    value,
    doubleIndex
  ) => {
    setFilters((prevFilters) => {
      return prevFilters.map((group, currentIndex) => {
        if (currentIndex !== groupIndex) return group;

        const updatedConditions = group.conditions.map((condition, index) => {
          if (index !== conditionIndex) return condition;

          if (doubleIndex >= 0) {
            const updatedCondition = { ...condition };
            if (!Array.isArray(updatedCondition.value))
              updatedCondition.value = [];
            while (updatedCondition.value.length <= doubleIndex) {
              updatedCondition.value.push(null);
            }
            updatedCondition.value[doubleIndex] = value;
            return updatedCondition;
          } else {
            return { ...condition, [field]: value };
          }
        });

        return { ...group, conditions: updatedConditions };
      });
    });
  };

  const removeFilter = (groupIndex, conditionIndex) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const group = newFilters[groupIndex];

      // Remove the condition
      group.conditions.splice(conditionIndex, 1);

      // If the group is empty after removal, remove the group
      if (group.conditions.length === 0) {
        newFilters.splice(groupIndex, 1);
      }

      return newFilters;
    });
  };

  const createNewCondition = (operator) => {
    return {
      id: uuidv4(),
      operator: operator,
      column: "",
      value: "",
      formula: "is",
    };
  };

  const createNewGroup = (operator) => {
    return {
      id: uuidv4(),
      operator: operator,
      conditions: [createNewCondition("AND")],
    };
  };

  const addToLastGroup = (lastGroup, operator) => {
    lastGroup.conditions.push(createNewCondition(operator));
  };

  const addFilter = (globalOperator, newOperator, filterIndex) => {
    const operatorToUse =
      globalOperator !== newOperator ? newOperator : globalOperator;

    setFilters((prevFilters) => {
      if (!prevFilters) {
        return [createNewGroup(operatorToUse)];
      }
      const newGroups = [...prevFilters];
      const groupExists =
        filterIndex >= 0 &&
        filterIndex < newGroups.length &&
        newGroups[filterIndex].operator === newOperator;

      if (globalOperator === "AND" && newOperator === "AND") {
        if (groupExists) {
          addToLastGroup(newGroups[filterIndex], newOperator);
        } else {
          newGroups.push(createNewGroup(newOperator));
        }
      } else if (globalOperator === "AND" && newOperator === "OR") {
        newGroups.push(createNewGroup(newOperator));
      } else if (globalOperator === "OR" && newOperator === "AND") {
        addToLastGroup(newGroups[filterIndex], newOperator);
      } else if (globalOperator === "OR" && newOperator === "OR") {
        newGroups.push(createNewGroup(newOperator));
      }

      return newGroups;
    });
  };

  const containsBetween = useMemo(
    () =>
      filters?.some((filterGroup) =>
        filterGroup?.conditions?.some((condition) =>
          condition?.formula?.includes("between")
        )
      ),
    [filters]
  );

  const getRowData = async () => {
    const queryStringParameters =
      Array.isArray(filters) && filters.length > 0
        ? { filters: JSON.stringify(filters) }
        : undefined;
    return fetchData(queryTable, queryTable, {
      queryStringParameters,
    }).catch(() => {
      console.log({ error });
    });
  };

  return {
    filters,
    addFilter,
    setFilters,
    getRowData,
    resetFilters,
    removeFilter,
    containsBetween,
    handleInputChange,
  };
};
