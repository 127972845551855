import { Modal } from "antd";
import { useContext, useState } from "react";

import {
  MondayButton,
  WarningModal,
} from "../../../../../../../commonComponents";
import DegModalContext from "../DegModalContext";
import UploadStep from "../UploadStep/UploadStep";
import { PlusIcon } from "../../../../../../../../assets";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../../../../SidebarPages/DynamicView/src";

import "./UploadEntriesModal.scss";

function UploadEntriesModal({ open, onCancel }) {
  const {
    uploading,
    degGridApi,
    isDarkMode,
    setRowData,
    currentStep,
    setUploading,
    addEntryAction,
    stopUploadingButton,
    addActionToRegister,
  } = useContext(DegModalContext);

  const [cancelVisible, setCancelVisible] = useState(false);
  const [uploadedEntries, setUploadedEntries] = useState([]);

  function onAddRecords() {
    if (currentStep === 1) {
      degGridApi.applyTransaction({ add: uploadedEntries, addIndex: 0 });
      addActionToRegister({ type: "new", newActions: uploadedEntries });
      addEntryAction({ type: "new", entry: uploadedEntries });
    } else {
      setRowData((prev) => [...uploadedEntries, ...prev]);
      addActionToRegister({ type: "new", newActions: uploadedEntries });
      addEntryAction({ type: "new", entry: uploadedEntries });
    }
    onCancel();
  }

  /**
   * @typedef param
   * @property {string} type Type of action "add" | "remove"
   * @property {Array} data List of uploaded records
   *
   * @param {param} param
   */
  function getUploadedData({ type, data }) {
    if (type === "add") {
      setUploadedEntries((prev) => [...prev, ...data]);
    } else if (type === "remove") {
      setUploadedEntries((prev) => prev.filter((el) => data !== el?.uploadId));
    }
  }

  return (
    <Modal
      {...{
        open,
        onCancel: () => (uploading ? setCancelVisible(true) : onCancel()),
        setVisible: () => (uploading ? setCancelVisible(true) : onCancel()),
        closable: true,
        destroyOnClose: true,
        title: "Upload Entries",
        ["data-testid"]: "upload-entries-modal",
        className: `uploadEntriesModal ${
          isDarkMode && "uploadEntriesModalDark"
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            className="mondayButtonRed"
            data-testid="close-upload-modal-btn"
            Icon={<XIcon />}
            onClick={() => (uploading ? setCancelVisible(true) : onCancel())}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            className="mondayButtonBlue"
            Icon={<PlusIcon />}
            onClick={onAddRecords}
          >
            Add Records
          </MondayButton>,
        ],
      }}
    >
      <UploadStep getUploadedData={getUploadedData} />
      {cancelVisible && (
        <WarningModal
          visible={cancelVisible}
          setVisible={setCancelVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              Do you want to Stop this upload?
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelVisible(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (uploading) {
                    stopUploadingButton?.current?.click();
                    setUploading(false);
                    setCancelVisible(false);
                  }
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Modal>
  );
}

export default UploadEntriesModal;
