import { Modal, Table } from "antd";
import { useSelector } from "react-redux";
import { useState, useContext } from "react";

import DegModalContext from "../DegModalContext";
import { notFoundColumns } from "./notFoundCoulmns";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { CrewsHeader } from "../../../../../../Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";

import "../LocationsModal/DegLocationsModal.scss";

function DegNotFound({ open, onCancel }) {
  const { notFound, updateCrewHandler } = useContext(DegModalContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [crewData, setCrewData] = useState();

  let dataSource = Object.keys(notFound)
    ?.map((key) => {
      if (key !== "noEmployeeId") {
        return {
          employeeFullName: notFound[key]?.employeeFullName,
          employeeId: key,
          accountName: notFound[key]?.accountName,
          employeeNumber: notFound[key]?.employeeNumber,
        };
      }
    })
    .filter(Boolean)
    .concat(
      notFound["noEmployeeId"]?.map(
        ({ employeeFullName, accountName, employeeNumber = "" }) => ({
          employeeFullName,
          employeeId: "",
          accountName,
          employeeNumber,
        })
      )
    );

  return (
    <Modal
      {...{
        open,
        onCancel,
        closable: true,
        centered: true,
        closeIcon: <XIcon />,
        ["data-testid"]: "deg-not-found",
        className: `specificWarningsModal ${
          isDarkMode && "specificWarningsModalDark"
        }`,
        wrapClassName: "specificWarningsWrap",
        title: "Not Found Employees",
        width: 1000,
        footer: null,
      }}
    >
      <Table
        {...{
          columns: notFoundColumns({
            createHandler: setCrewData,
          }),
          pagination: {
            pageSize: 4,
          },
          dataSource,
        }}
      />
      {!!crewData && (
        <CrewsHeader
          {...{
            visible: !!crewData,
            setVisible: setCrewData,
            refreshTable(data) {
              updateCrewHandler(data, crewData);
            },
            title: "Employees",
            defaultData: crewData,
          }}
        />
      )}
    </Modal>
  );
}

export default DegNotFound;
