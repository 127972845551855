import { useContext, useMemo } from "react";
import { Drawer, Form, Tooltip } from "antd";

import { PlusIcon } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import AnalyticsChartsContext from "../../AnalyticsChartsContext";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { saveLocalPreferences } from "../../../../../../SidebarPages/Fleet/fleetsLive/utils";
import {
  XIcon,
  SearchIcon,
} from "../../../../../../SidebarPages/Communication/assets";

import "./HiddenChartsSider.scss";

function HiddenChartsSider({ open, onClose }) {
  const {
    isDarkMode,
    setLayout,
    hiddenCards: hiddenLayoutCards,
    setHiddenCards,
    setChanges,
  } = useContext(AnalyticsChartsContext);

  const [form] = Form.useForm();
  const cardsSearch = Form.useWatch("cardsSearch", form);

  function showCard(card) {
    const docEl = document.getElementById(`${card?.title}-card-container`);
    docEl.animate(
      [
        { transform: "translate3D(0, 0, 0)", opacity: 1 },
        { transform: "translate3D(100%, 0, 0)", opacity: 0 },
      ],
      {
        duration: 300,
        iterations: 1,
      }
    );
    setTimeout(() => {
      setHiddenCards((prev) => {
        const newHiddenCards = prev.filter((el) => el.i !== card.i);
        saveLocalPreferences({ analyticsHiddenCharts: newHiddenCards });
        return newHiddenCards;
      });
      setChanges(true);
      setLayout((prev) => {
        if (prev.some((el) => el.i === card.i)) {
          return prev;
        } else {
          return prev.concat(card);
        }
      });
    }, 280);
  }

  const hiddenCards = useMemo(() => {
    return hiddenLayoutCards.flatMap((card) => {
      const hiddenCard = (
        <section
          className="card-select-container"
          key={card?.title}
          id={`${card?.title}-card-container`}
        >
          <div className="card-title">{card?.title}</div>
          <Tooltip title="Show Card">
            <MondayButton
              className="mondayButtonBlue"
              Icon={<PlusIcon />}
              onClick={() => showCard(card)}
            >
              {""}
            </MondayButton>
          </Tooltip>
        </section>
      );

      if (cardsSearch?.length) {
        return (card?.title || "")
          .toLowerCase()
          .includes((cardsSearch || "").toLowerCase())
          ? hiddenCard
          : [];
      } else {
        return hiddenCard;
      }
    });
  }, [hiddenLayoutCards, cardsSearch]);

  return (
    <Drawer
      rootClassName={`hidden-charts-sider ${
        isDarkMode && "hidden-charts-sider-dark"
      }`}
      title={"Hidden Charts"}
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={<XIcon />}
      data-testid="hidden-charts-sider"
    >
      <Form form={form}>
        <InputComponent
          type="input"
          formItemName={"cardsSearch"}
          prefix={<SearchIcon />}
          placeholder="Search..."
        />
      </Form>
      <section className="hidden-cards-list">{hiddenCards}</section>
    </Drawer>
  );
}

export default HiddenChartsSider;
