import { useState } from "react";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../utils";
import { useRedux } from "../../../../../../hooks";
import { formatCurrency } from "../../../../../../utils";
import { XIcon } from "../../../../../../Communication/assets";
import { MondayButton } from "../../../../../../../commonComponents";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import AccountingWriteMode from "../../../../../../../commonComponents/AccountingWriteMode/AccountingWriteMode";

import "./Header.scss";
import { PlusOneIcon } from "../../../../../../../../icons";
import { Input, InputNumber } from "antd";

const Header = ({
  currentCharge,
  saveCharge,
  onClose,
  creditAmount,
  onAddNewInvoice,
  onCreditChange,
}) => {
  const [isAlreadySaved, setIsAlreadySaved] = useState(false);
  const [isWritable, setIsWritable] = useRedux("creditMemoIsWritable");

  const isRequisitionCategory = currentCharge?.categoryFrom === "Requisition";

  const handleSaveChanges = async () => {
    if (creditAmount === 0) {
      return showErrorMsg({
        content:
          "You cannot create a charge with a 0 value. Please check the balance before continuing.",
      });
    }

    showLoadingMsg({ content: "Saving credit..." });

    // setIsAlreadySaved(true);

    await saveCharge().catch((e) => {
      showErrorMsg({ content: "Something went wrong!" });
      setIsAlreadySaved(false);
      console.error("charges", e);
    });
  };

  return (
    <div className="credit-header-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="textTitle headerTitleText">
          {currentCharge?.chargeNumber
            ? `Edit credit memo no. ${currentCharge.chargeNumber}`
            : "Add new credit memo item"}
        </div>
        {currentCharge && (
          <AccountingWriteMode
            {...{
              isWritable,
              recordId: currentCharge?.chargeId,
              onSwitchFunction: setIsWritable,
              projectId: currentCharge?.projectId,
            }}
          />
        )}
      </div>
      <div
        className="totalCreditHolder"
        style={{
          color: "white",
        }}
      >
        Total Credit Amount:{" "}
        <InputNumber
          className="totalCreditInput"
          controls={false}
          style={{
            color: "white",
            // width: "150px",
            backgroundColor: "black",
          }}
          prefix="$"
          value={creditAmount}
          onBlur={onCreditChange}
          // disabled
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 10,
          alignItems: "center",
        }}
      >
        <MondayButton
          className="mondayButtonBlue"
          Icon={<PlusOneIcon />}
          onClick={onAddNewInvoice}
        >
          Add New Invoice
        </MondayButton>
        <MondayButton
          className={`saveChanges ${isRequisitionCategory ? "disabled" : ""}`}
          disabled={isAlreadySaved}
          style={{
            color: "black !important",
            fontWeight: "700px !important",
            cursor: isRequisitionCategory ? "not-allowed" : "default",
          }}
          onClick={handleSaveChanges}
          Icon={
            <TickIcon
              style={
                isAlreadySaved || isRequisitionCategory
                  ? { fill: "#12131b" }
                  : {}
              }
            />
          }
        >
          <span
            style={
              isAlreadySaved || isRequisitionCategory
                ? { color: "#12131b" }
                : {}
            }
          >
            Save Changes
          </span>
        </MondayButton>
        <XIcon
          fill="white"
          cursor="pointer"
          onClick={onClose}
          style={{ marginLeft: "20px" }}
        />
      </div>
    </div>
  );
};

export default Header;
