import types from "../types"

const initialState = {
  rental: null,
  rentals: [],
  newlyCreatedRental: null,
  isLoading: false,
  loaded: false,
  error: {},
}

const rentalsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOADING_RENTAL:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_RENTALS:
      return {
        ...state,
        rentals: payload,
        isLoading: false,
        loaded: true,
      }
    case types.GET_RENTAL:
      return {
        ...state,
        rental: payload,
        isLoading: false,
      }
    case types.CREATE_RENTAL:
      return {
        ...state,
        rentals: [payload, ...state.rentals],
        newlyCreatedRental: payload,
        isLoading: false,
      }
    case types.UPDATE_RENTAL:
      return {
        ...state,
        rental: payload.rental,
        rentals: state.rentals.map((rental) =>
          rental.rentalId === payload.rentalId ? payload.rental : rental
        ),
        isLoading: false,
      }
    case types.DELETE_RENTAL:
      return {
        ...state,
        rentals: state.rentals.filter((rental) => rental.rentalId !== payload),
        isLoading: false,
      }
    case types.ERROR_RENTAL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case types.CLEAR_RENTAL:
      return {
        ...state,
        rental: null,
      }
    case types.CLEAR_NEWLY_CREATED_RENTAL:
      return {
        ...state,
        newlyCreatedRental: null,
      }
    case types.RESET_RENTAL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default rentalsReducer
