import { formatCurrency } from "../../../../utils";

/**
 *
 * @param {String} s html text
 * @returns plain text inside element
 */
function getTextFromStringHtml(s) {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

/**
 *
 * @param {Array} serviceOptions service options of service
 * @returns return description with all details
 */
const generateDescription = (serviceOptions, categoryFrom = "Rental") => {
  return serviceOptions
    .map((elevation) => {
      if (elevation?.items?.length !== 0) {
        return [
          `<span>${elevation?.elevationLabel}</span><br/>`,
          elevation?.items
            ?.map((pli) => {
              return pli?.items?.map((pliItem) => {
                return `<span>-${pliItem?.length || 0}'L x ${
                  pliItem?.height || 0
                }'H x ${pliItem?.width || 0}'W ${
                  categoryFrom === "Requisition" ? "-" + pli?.event : ""
                } <span id="note" style="display:none;">${
                  pli?.note ? "-" + getTextFromStringHtml(pliItem?.note) : []
                }</span> <span id="price" style="display:none;">Price: ${
                  "" + pliItem?.price
                  // exctractPrice(categoryFrom, pli)
                }</span></span>`;
              });
            })
            ?.join(`<br/>`),
        ]?.join(``);
      } else {
        return false;
      }
    })
    .filter(Boolean)
    .join(`<br/><br/>`);
};

export default generateDescription;

const exctractPrice = (categoryFrom, pli) => {
  console.log(
    "categoryFrom",
    categoryFrom,
    "pli",
    "result",
    categoryFrom === "Requisition"
      ? formatCurrency(pli?.thisPeriod || 0)
      : formatCurrency(pli?.price || 0) || 0
  );
  return categoryFrom === "Requisition"
    ? formatCurrency(pli?.thisPeriod || 0)
    : formatCurrency(pli?.price || 0) || 0;
};
