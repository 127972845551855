import React from "react";
import "./RedirectApprovals.scss";

/**
 *
 * @param {String} value - Yes or No comes from the cell value
 * @param {String} requestId - the request id
 */
const RedirectApprovals = ({ value = "", requestId = "" }) => {
  const redirectToApprovals = () => {
    if (value === "Yes" && !!requestId) {
      window.open(`/approvals/${requestId}`, "_blank");
    }
  };

  return (
    <div
      onClick={redirectToApprovals}
      disabled={value === "No"}
      className={
        value === "Yes"
          ? "redirect-approvals-button redirect-approvals-enabled"
          : "redirect-approvals-button redirect-approvals-disabled"
      }
    >
      {value}
    </div>
  );
};

export default RedirectApprovals;
