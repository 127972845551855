import React from "react";
import { Button } from "antd";
import "./LoaderButton.css";

const LoaderButton = ({
  isLoading,
  htmlType,
  text,
  loadingText,
  className = "",
  placeholder
}) =>
  <Button
    className = {className}
    loading = {isLoading}
    htmlType = {htmlType}
    placeholder = {placeholder}
  >
    {!isLoading ? text : loadingText}
  </Button>;

export default LoaderButton