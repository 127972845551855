export const getVersionService = (myObj) => {
  const updatedArr = [];

  if (myObj && Object.keys(myObj).length !== 0) {
    for (const key of Object.keys(myObj)) {
      updatedArr.push({ id: key, value: myObj[key].services });
    }
  }

  return updatedArr;
};
