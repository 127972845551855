import { Divider, Input, message, Radio, Table } from "antd";
import { API } from "aws-amplify";
import { groupBy } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import {
  CheckIconModal,
  CloseIconModal,
} from "../../../../../../../../../../../../../assets";
import {
  fetchAllData,
  filterTables,
} from "../../../../../../../../../../../../../utils";
import { currencyFormater } from "../../../../../../../../../../../../../utils/currencyFormater";
import { MondayButton } from "../../../../../../../../../../../../commonComponents";
import { dayjsNY } from "../../../../../../../../../../../../DateComponents/contants/DayjsNY";
import { forceToNumber } from "../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { SaveIcon } from "../../../../../../../../../../../BasePage/src";
import { LoadableComp } from "../../../../../../../../../../../XComponents";
import { appliedAmountGenerator } from "../../../../../../../../../Rentals/RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../../Context/Context";
import { rentalDataExtractor } from "../../IncludeRentals/includeRentalsFunctions";
import { chainRentals } from "./actionsAPI/actions";
const columns = [
  {
    title: "No.",
    dataIndex: "key",
    width: 150,
  },
  {
    title: "Status",
    dataIndex: "rentalStatus",
    width: 200,
  },
  {
    title: "Quickbooks #",
    dataIndex: "quickbooksNumber",
    width: 200,
  },
  {
    title: "Rental No.",
    dataIndex: "rentalNo",
    width: 200,
  },
  {
    title: "Service",
    dataIndex: "services",
    width: 500,
    render: (services) => {
      return <>{getServicesWithTheirRentals(services)}</>;
    },
  },
  {
    title: "Applied Rent",
    width: 250,
    dataIndex: "appliedAmount",
    render: (totalPrice) => {
      return currencyFormater(totalPrice);
    },
  },
  {
    title: "More details...",
    width: 250,
    dataIndex: "services",
    render: (totalPrice) => {
      // console.log("services", services);
      return <>This will be updated soon...</>;
      // return currencyFormater(totalPrice);
    },
  },
  // {
  //   title: "Tax Rate",
  //   dataIndex: "taxRate",
  // },
  // {
  //   title: "Tax Amount",
  //   dataIndex: "taxAmount",
  // },
  // {
  //   title: "This Retaiange",
  //   dataIndex: "thisKeptRetainage",
  //   render: (totalPrice) => {
  //     return currencyFormater(totalPrice);
  //   },
  // },
  // {
  //   title: "Total Price",
  //   dataIndex: "totalPrice",
  //   render: (totalPrice) => {
  //     return currencyFormater(totalPrice);
  //   },
  // },
  // {
  //   title: "Created At",
  //   dataIndex: "createdAt",
  //   render: (totalPrice) => {
  //     return dayjsNY(totalPrice).format("MM/DD/YYYY");
  //   },
  // },
];

function ChainRentalsModal({ onCancel }) {
  const { isWritable, isAutoSaving, fakeApplication, darkMode, appId } =
    useStateContext();
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentChains, setCurrentChains] = useState([]);
  const dispatchContext = useDispatchContext();
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === "Disabled User",
      disabled: currentChains?.some(
        (el) =>
          el?.projectId === record?.projectId &&
          el?.rentalId === record?.rentalId
      ),
      // Column configuration not to be checked
      // name: record.rentalId,
    }),
  };
  const [data, setData] = useState([]);

  const retrieveRentals = async () => {
    let PROJECT_ID = fakeApplication.projectId;
    await filterTables("rentals", "projectId", PROJECT_ID).then((res) => {
      console.log("retrievedRentals", res);
      let PROCESSED_RENTALS = processRentals(res);
      console.log("PROCESSED_RENTALS", PROCESSED_RENTALS);
      setData(PROCESSED_RENTALS);
    });
  };
  const attachRentals = async () => {
    await filterTables(
      "includedRentals",
      "applicationId",
      fakeApplication.applicationId
    ).then((res) => {
      console.log("res", res);
      dispatchContext({ type: "ATTACH_RENTALS", payload: res });
    });
  };

  const onChainRentals = async () => {
    let rentalsToChain = data.filter((el) => selectedRowKeys.includes(el.key));
    if (rentalsToChain.length === 0) return;
    else {
      console.log("rentalsToChain", { fakeApplication, rentalsToChain });
      rentalsToChain = rentalsToChain.map((el) => {
        return {
          rentalId: el.rentalId,
          applicationId: fakeApplication.applicationId,
          clientName: fakeApplication.accountName,
          projectId: fakeApplication.projectId,
          clientId: fakeApplication.accountId,
          sovId: fakeApplication.scheduleId,
          services: el?.services || [],
          totalities: {},
          particularities: {},
          rentalNumber: el?.rentalNo,
          // createdAt:
        };
      });
    }
    await chainRentals({ rentalsArr: rentalsToChain });
    await attachRentals();
    onCancel();
    console.log("rentalsToChain", rentalsToChain);
  };

  const retrieveCurrentChains = async () => {
    let PROJECT_ID = fakeApplication.projectId;

    await filterTables("includedRentals", "projectId", PROJECT_ID).then(
      (res) => {
        console.log("currentChains", res);
        setCurrentChains(res);
      }
    );
    setLoading(false);
  };

  const onTest = async () => {
    let toPostCount = 0;
    let toPOST = [];
    let created = [];

    // await fetchAllData({
    //   endpoint: "includedRentals",
    //   resultPosition: "includedRentals",
    //   resultId: "rentalsReqId",
    //   otherStringParams: {},
    // }).then((res) => {
    //   console.log("res", res);
    //   created = res;
    // });

    await fetchAllData({
      endpoint: "applications",
      resultPosition: "applications",
      resultId: "applicationId",
      otherStringParams: {},
    }).then((res) => {
      console.log("alllll appss", res);
      let filtered = res.filter(
        (el) =>
          Array.isArray(el?.includedRentals) && el?.includedRentals.length > 0
      );
      console.log("filteredtesstt", filtered);

      let tesstt = filtered.forEach(async (el, idx) => {
        let appId = el.applicationId;
        delete el.applicationId;
        delete el.userId;
        API.patch("applications", `/applications/${appId}`, {
          body: {
            // ...el,
            includedRentals: [],
          },
        })
          .then((res) => {
            message.success("Rentals successfully logged");
          })
          .catch((err) => {
            message.error("Error chaining rentals");
            console.error("err", err);
          });
      });
      console.log("filteredtesstt", tesstt);
    });
    // await filterTables("applications", "projectId", selectedId).then(
    //   async (res) => {
    //     if (res.length === 0) return;
    //     if (res.length > 0) {
    //       let toPOST = [];
    //       let thoseWithIncluded = res
    //         .filter((e) => e.includedRentals.length > 0)
    //         .map((el) => {
    //           let included = el.includedRentals;
    //           let fakeApplication = el;
    //           included.forEach((rental) => {
    //             toPostCount++;
    //             toPOST.push({
    //               rentalId: rental.rentalId,
    //               applicationId: fakeApplication.applicationId,
    //               clientName: fakeApplication.accountName,
    //               projectId: fakeApplication.projectId,
    //               clientId: fakeApplication.accountId,
    //               sovId: fakeApplication.scheduleId,
    //               services: rental?.services || [],
    //               totalities: {},
    //               particularities: {},
    //               rentalNumber: rental?.rentalNumber,
    //               // createdAt:
    //             });
    //           });
    //         });
    //       // let rentalsToChain = thoseWithIncluded.map((el) => {
    //       //   return {
    //       //     rentalId: el.rentalId,
    //       //     applicationId: fakeApplication.applicationId,
    //       //     clientName: fakeApplication.accountName,
    //       //     projectId: fakeApplication.projectId,
    //       //     clientId: fakeApplication.accountId,
    //       //     sovId: fakeApplication.scheduleId,
    //       //     services: el?.services || [],
    //       //     totalities: {},
    //       //     particularities: {},
    //       //     rentalNumber: el?.rentalNo,
    //       //     // createdAt:
    //       //   };
    //       // });
    //       await chainRentals({ rentalsArr: toPOST });

    //       console.log("thoseWithIncluded", toPOST);
    //     }
    //   }
    // );

    console.log("final toPostCount", toPostCount);
    Swal.fire({
      title: "Success",
      text: `${toPostCount} rentals chained successfully`,
      icon: "success",
    });
    // await fetchAllData({
    //   endpoint: "applications",
    //   resultPosition: "applications",
    //   resultId: "applicationId",
    //   otherStringParams: {},
    // }).then((res) => {
    //   console.log("alllll appss", res);
    //   let filtered = res.filter(
    //     (el) =>
    //       Array.isArray(el?.includedRentals) && el?.includedRentals.length > 0
    //   );
    //   let grouped = groupBy(filtered, "projectId");
    //   console.log("filtered", grouped);
    // });
  };

  useEffect(() => {
    retrieveRentals();
    retrieveCurrentChains();
  }, []);

  return (
    <>
      <LoadableComp loading={loading} />
      {/* <MondayButton onClick={onTest}>Test</MondayButton> */}
      <div className="chainRentalsModalFooter">
        {" "}
        <MondayButton
          // hasIcon={false}
          onClick={onCancel}
          className="mondayButtonRed"
          Icon={<CloseIconModal />}
        >
          Discard Changes
        </MondayButton>
        <MondayButton
          Icon={<CheckIconModal />}
          onClick={onChainRentals}
          className="mondayButtonBlue"
        >
          Save Included Rentals
        </MondayButton>
      </div>
      {data.length > 0 && (
        <Table
          className="chainRentalsTable"
          rowSelection={{
            type: selectionType,
            ...rowSelection,
            selectedRowKeys,
          }}
          virtual={true}
          columns={columns}
          pagination={false}
          dataSource={data}
          // expandable={{
          //   expandedRowRender: (record) => (
          //     <p
          //       style={{
          //         margin: 0,
          //       }}
          //     >
          //       {getServicesWithTheirRentals(record?.services)}
          //     </p>
          //   ),
          //   rowExpandable: (record) => record.appliedAmount > 0,
          //   // expandedRowKeys: data?.map((el) => el.key),
          // }}
        />
      )}

      <LoadableComp />
    </>
  );
}

export default ChainRentalsModal;

const processRentals = (rentals) => {
  if (!rentals || !Array.isArray(rentals)) return [];
  else if (rentals.length === 0) return [];
  else {
    let toReturn = [];

    //TODO: Filter with includedTo key

    rentals.forEach((rental, idx) => {
      console.log("rentalLedjo", rental);
      let extractedRental = rentalDataExtractor({ rental });
      let test = totalitiesTotalTaxGen({ fakeRentals: rental });
      console.log("rentalLedjotest", {
        extractedRental,
        test,
      });
      let services = extractedRental || [];
      if (Array.isArray(services) && services.length > 0) {
        services = services.map((service) => {
          console.log("serviceserviceserviceservice", service);
          return {
            ...(service || {}),
            key: null,
            quickbooksNumber: rental?.services?.[0]?.quickbooksNumber || "",
          };
        });
        // services?.forEach((service) => {
        //   toReturn.push({
        //     key: null,
        //     label: service?.label || "",
        //     rentalNo: rental?.rentalNumber || "",
        //     rentalStatus: rental?.rentalStatus || "",
        //     accRetainageBalance: 0,
        //     appliedAmount: 0,
        //     creditWithTax: 0,
        //     endDate: 0, //timestamp
        //     paymentDue: 0,
        //     serviceId: service?.serviceId || "",
        //     retainagePercentage: service?.retainage || 0,
        //     startDate: 0,
        //     taxRate: rental?.projectTaxRate,
        //     thisKeptRetainage: 0,
        //     thisPeriod: 0,
        //     quickbooksNumber: service?.quickbooksNumber || "",
        //     weeks: 0, //5.0
        //   });
        // });
      }
      toReturn.push({
        key: idx + 1,
        rentalNo: rental?.rentalNumber || "",
        rentalStatus: rental?.rentalStatus || "",
        services,
        quickbooksNumber: rental?.services?.[0]?.quickbooksNumber || "",
        rentalId: rental?.rentalId || "",
        projectId: rental?.projectId || "",
        appliedAmount: services?.reduce(
          (acc, el) => acc + forceToNumber(el?.appliedAmount || 0),
          0
        ),
      });
    });
    /// add key
    return toReturn?.map((el, idx) => ({ ...el, key: idx + 1 }));
  }
};

const keysToAdd = {
  serviceLabel: "",
  rentalNo: "",
  accRetaiangeDist: 0,
  accRetainageBalance: 0,
  appliedAmount: 0,
  creditWithTax: 0,
  currentPayment: 0,
  endDate: 0, //timestamp
  paymentDue: 0,
  retainage: 0,
  retainagePercentage: 0,
  retainageTax: 0,
  startDate: 0,
  taxRate: 0,
  thisDistRetainage: 0,
  thisKeptRetainage: 0,
  thisPeriod: 0,
  weeks: 0, //5.0
};
const totalitiesTotalTaxGen = ({ fakeRentals }) => {
  // console.log("to result", { fakeRentals, elevationData, selectedService });

  let carriedProjectTax = fakeRentals?.taxRate || 0;
  let result = {
    totalTax: 0,
    totalPrice: appliedAmountGenerator({ fakeRentals }) || 0,
    appliedRent: appliedAmountGenerator({ fakeRentals }) || 0,
  };
  console.log("result", result);
  if (carriedProjectTax > 0) {
    result.totalTax = result.totalPrice * carriedProjectTax;
    result.totalPrice = result.totalPrice + result.totalTax;
  }
  return result;
};

const getServicesWithTheirRentals = (services) => {
  if (Array.isArray(services) && services.length > 0) {
    console.log("servicestosend", services);
    let toReturn = [];

    return services?.map((service, idx) => {
      return (
        <div>
          <p
            style={{
              ...getRandomHighlightStyle(false),
              padding: 0,
              width: "fit-content",
              fontWeight: "bold",
            }}
          >
            {idx + 1}. {service?.label} (
            {dayjsNY(service?.startDate).format("MM/DD/YYYY")} -{" "}
            {dayjsNY(service?.endDate).format("MM/DD/YYYY")}) -{"    "}
            {currencyFormater(service?.appliedAmount)} -{" "}
            {` Weeks: ${service?.weeks}`}
          </p>
        </div>
      );
    });
  }

  return <p>All Elevations Charged / No Partial</p>;
};

const getRandomHighlightStyle = (darkMode) => {
  return {
    backgroundColor: darkMode ? "#3d3d3d" : "#FFFDB5",
    color: darkMode ? "white" : "black",
  };
};
