import { useContext, useRef, useState, useEffect } from "react";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { Drawer, Modal, Tooltip, message } from "antd";
import {
  InfoCircleFilled,
  LockOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import "./VideoPlayerModal.scss";
import VideoCard from "../VideoCard/VideoCard";
import { v4 } from "uuid";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import {
  MondayButton,
  MondaySwitch,
  StarCheckbox,
} from "../../../../../commonComponents";
import { EmailIcon } from "../../../Approvals/assets";
import EmailBox from "../../../../../SidebarPages/Communication/components/EmailBox/EmailBox";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import htmlParser from "src/utils/htmlParser";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { LoadingDots } from "../../../../../commonComponents/3LoadingDots/LoadingDots";
import { useNavigate } from "react-router-dom";
import { useEditLogs, useResponsive } from "../../../../../../hooks";
import { updatePreferences } from "../../../../../../actions/preferences";
import { useDispatch } from "react-redux";
import { fetchAllData } from "../../../../../../utils";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { getLastSeenUsers } from "../../utils/getLastSeenUsers";
import { Notes } from "../../../../../commonComponents/Notes/Notes";
import ViewsInfo from "../ViewsInfo/ViewsInfo";
import Transcription from "../Transcription/Transcription";
import { NotesIcon } from "../../../../../../icons";
import { Tick } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { convertMinutesToSeconds } from "../../utils/convertMinutesToSeconds";
import { updateProgramFieldByName } from "../../../../../../actions/programFields";

export const VideoPlayerModal = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const videoPlayerRef = useRef();
  const [emailBox, setEmailBox] = useState(false);
  const driveReq = driveApi({ accessToken });
  const [fileSrc, setFileSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [viewsModal, setViewsModal] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoEnd, setVideoEnd] = useState(false);
  const [videViewDetails, setVideoViewDetails] = useState({
    lastSeenUsers: [],
    topUsers: [],
    totalViews: 0,
  });

  const { googleAccessToken } = useGoogleSignIn();

  const navigate = useNavigate();

  const { mobile, tablet } = useResponsive();
  const { saveAddedLogs, fetchedLogs, setFetchedLogs, handleFetchLogs } =
    useEditLogs();

  const {
    primaryArray,
    activePrimaryCat,
    setSubCategoryName,
    setActivePrimaryCat,
    videoActive,
    setIdSubActive,
    setCustomeModal,
    customeModal,
    newVideos,
    playing,
    setVideoActive,
    setPlaying,
    playingVideo,
    hasWatched,
    setHasWatched,
    subCategoryName,
    isDarkMode,
    favOrNewCategory,
    favourites,
    playedVideos,
  } = useContext(VideoTutorialContext);

  const handleTextClick = (start) => {
    setCurrentTime(start < 0.01 ? start : start + 0.01);
    videoPlayerRef.current.seekTo(start < 0.01 ? start : start + 0.01);
  };

  const dispatch = useDispatch();

  const unseenVideo = newVideos.find((video) => video.id === playingVideo.id);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const logs = await fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: `VideoTutorials ${playingVideo.id}`,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      });

      const sortedLogs = logs.sort((a, b) => {
        return b.updatedAt - a.updatedAt;
      });

      const { lastSeenUsers, topUsers, totalViews } =
        getLastSeenUsers(sortedLogs);
      setVideoViewDetails({
        lastSeenUsers,
        topUsers,
        totalViews,
      });

      driveReq
        ?.getImageSrc(videoActive.split("=")[1])
        .then((res) => {
          setFileSrc(res);
        })
        .catch((err) => {
          console.log("ERR: ", err);
          message.error("Error loading video");
        });

      setLoading(false);
    };

    if (accessToken) {
      fetchData();
    }
  }, [videoActive, accessToken]);

  const addWatchedVideo = async () => {
    const totalVideoTime = convertMinutesToSeconds(
      playingVideo.lengthVideoInMin
    );

    if (
      videoPlayerRef?.current?.getCurrentTime() > 0 &&
      videoPlayerRef?.current?.getCurrentTime() < totalVideoTime
    ) {
      const doesExists = playedVideos?.find(
        (video) => video?.id === playingVideo?.id
      );

      const updatedPlayedVideos = doesExists
        ? playedVideos.map((video) =>
            video.id === playingVideo?.id
              ? {
                  id: video.id,
                  time: videoPlayerRef?.current?.getCurrentTime(),
                }
              : { id: video.id, time: video.time }
          )
        : [
            ...playedVideos.map((video) => ({
              id: video.id,
              time: video.time,
            })),
            {
              id: playingVideo.id,
              time: videoPlayerRef?.current?.getCurrentTime(),
            },
          ];

      const preferencesToSave = {
        ...allPreferences.preferences,
        videoPreferences: {
          ...allPreferences.preferences.videoPreferences,
          playedVideos: updatedPlayedVideos,
        },
      };

      try {
        await API.patch("preferences", `/preferences`, {
          body: {
            preferences: preferencesToSave,
          },
        });
        dispatch(updatePreferences(preferencesToSave));

        if (!doesExists) {
          saveAddedLogs({
            actionType: "Partial Watched",
            category: "Video Tutorials",
            recordId: playingVideo?.id,
            recordName: "Partial Watched",
            updatedKeys: [
              {
                type: v4(),
                key: "",
                label: playingVideo?.videoName,
                previousValue: "",
                updatedValue: "Partial Watched",
              },
            ],
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    setVideoEnd(false);
  };

  const handleCancel = async () => {
    navigate(location.pathname); //remove query parameters if there are
    await addWatchedVideo();

    setPlaying(false);
    setCustomeModal(true);
    setIdSubActive(null);
    setVideoActive();
    setHasWatched(false);
    setSubCategoryName("");
    setActivePrimaryCat();
  };

  const onVideoEnd = async () => {
    if (!!videoPlayerRef?.current) {
      const updatedPlayedVideos = playedVideos
        ?.map((video) => ({ id: video.id, time: video.time }))
        ?.filter((item) => item?.id !== playingVideo?.id);

      const updatedVideoPreferences = primaryArray.map((category) => ({
        ...category,
        subCategories: category.subCategories.map((subCategory) => ({
          ...subCategory,
          videos: subCategory.videos.map((video) => ({ id: video.id })),
        })),
      }));

      const preferencesToSave = {
        ...allPreferences.preferences,
        videoPreferences: {
          ...allPreferences.preferences.videoPreferences,
          playedVideos: updatedPlayedVideos,
          order: updatedVideoPreferences,
        },
      };

      try {
        await API.patch("preferences", "/preferences", {
          body: {
            preferences: preferencesToSave,
          },
        });

        dispatch(updatePreferences(preferencesToSave));

        const filters = [
          {
            operator: "AND",
            conditions: [
              {
                operator: "AND",
                column: "actionType",
                value: "Partial Watched",
                formula: "is",
              },
            ],
          },
          {
            operator: "AND",
            conditions: [
              {
                operator: "AND",
                column: "recordId",
                value: playingVideo.id,
                formula: "is",
              },
            ],
          },
          {
            operator: "AND",
            conditions: [
              {
                operator: "AND",
                column: "nameOfUser",
                value: userConfiguration.nameOfUser,
                formula: "is",
              },
            ],
          },
        ];

        const logs = await handleFetchLogs({ filters });

        if (logs.length === 0) return;

        const logsToDelete = logs.map(({ logId }) => logId);

        try {
          await API.del("editLogs", "/editLogs/123", {
            body: {
              logs: logsToDelete,
            },
          });
          console.log("Logs deleted successfuly!");
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const userHasSeen = async () => {
    const videoTutorials =
      programFields.filter(
        (item) => item.fieldName === "Portal Video Tutorials"
      )[0]?.fieldOptions ?? [];

    if (!hasWatched) {
      setHasWatched(true);

      saveAddedLogs({
        actionType: "Watched",
        category: "Video Tutorials",
        recordId: `VideoTutorials ${playingVideo?.id}`,
        recordName: "Watched",
        updatedKeys: [
          {
            type: v4(),
            key: "",
            label: playingVideo?.videoName,
            previousValue: "",
            updatedValue: "Watched",
          },
        ],
      });
    }

    const userWatched = playingVideo.usersHaveSeen.find(
      (user) => user === userConfiguration.identityId
    );

    if (!userWatched) {
      const updatedArray = videoTutorials.map((category) => ({
        ...category,
        subCategories: category.subCategories.map((subCategory) => ({
          ...subCategory,
          videos: subCategory.videos.map((video) => {
            if (video.id === playingVideo.id) {
              return {
                ...video,
                usersHaveSeen: [
                  ...video.usersHaveSeen,
                  userConfiguration.identityId,
                ],
              };
            }

            return video;
          }),
        })),
      }));

      try {
        await API.patch(
          "programFields",
          `/programFields/7effeb2d-bf57-46e4-83ae-3e871826159d`,
          {
            body: {
              fieldOptions: updatedArray,
            },
          }
        ).then(() =>
          dispatch(
            updateProgramFieldByName({
              fieldName: "Portal Video Tutorials",
              fieldOptions: updatedArray,
            })
          )
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const VideoCards = () => {
    const videos = [];

    if (favOrNewCategory === "fav") {
      videos.push(...favourites);
    } else if (favOrNewCategory === "new") {
      videos.push(...newVideos);
    } else if (favOrNewCategory === "unfinished") {
      videos.push(...playedVideos);
    } else {
      primaryArray.map((category) =>
        category.subCategories.map((subCat) =>
          subCat.videos.map((video) => {
            if (
              video.videoLink === videoActive &&
              video.videoName === playingVideo.videoName
            ) {
              videos.push(...subCat.videos);
            }
          })
        )
      );
    }

    return videos?.map((video) => (
      <VideoCard
        key={video.id}
        data={video}
        isFavourite={favOrNewCategory === "fav" ? true : false}
        isNewVideo={favOrNewCategory === "new" ? true : false}
        isUnfinishedVideo={favOrNewCategory === "unfinished" ? true : false}
        onOpen={() => addWatchedVideo()}
      />
    ));
  };

  const checkSumVideo = () => {
    const videos = [];
    let totalSumVideos = 0;

    if (favOrNewCategory === "fav") {
      favourites.forEach(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    } else if (favOrNewCategory === "new") {
      newVideos.forEach(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    } else if (favOrNewCategory === "unfinished") {
      playedVideos.forEach(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    } else {
      primaryArray.map((category) =>
        category.subCategories.map((subCat) =>
          subCat.videos.map(
            (video) =>
              video.videoLink === videoActive &&
              video.videoName === playingVideo?.videoName &&
              videos.push([...subCat.videos])
          )
        )
      );
      videos[0]?.map(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    }

    return Math.round((Number(totalSumVideos) + Number.EPSILON) * 100) / 100;
  };

  console.log({ playingVideo });

  function onPlay() {
    setPlaying(true);
  }

  const handleNearEnd = async () => {
    setVideoEnd(true);
    await userHasSeen();
  };

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);

    const duration = state.loadedSeconds;
    const remainingTime = duration - state.playedSeconds;

    if (remainingTime <= 10 && remainingTime > 0 && !videoEnd) {
      handleNearEnd();
    }
  };

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }

  function sendEmailNotification() {
    broadcastNotification(
      "37",
      "onVideoTutorial",
      [
        {
          common: userConfiguration?.nameOfUser,
          action: "sent email, tutorial video",
          commonNext: playingVideo?.videoName,
        },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: userConfiguration?.identityId,
        },
      ],
      []
    );
  }

  function sendEmailClick() {
    if (
      (playingVideo.hasOwnProperty("transcribedText") &&
        playingVideo.transcribedText.trimStart().length < 1) ||
      !playingVideo.hasOwnProperty("transcribedText")
    ) {
      return setEmailBox(true);
    }

    const textContent = playingVideo.transcribedText;

    if (!textContent) {
      console.error("Transcription text is empty or undefined.");
      return;
    }

    const blob = new Blob([textContent], { type: "text/plain" });

    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      const attachment = {
        name: `${playingVideo.videoName.replace(/ /g, "")}-transcription.txt`,
        blob: result,
        size: blob.size,
        type: "text/plain;base64",
        status: 400,
      };

      setAttachments([attachment]);
      setEmailBox(true);
    };

    reader.readAsDataURL(blob);
  }

  const onFavoriteUnclick = async () => {
    // EDIT PREFERENCES
    setFavLoading(true);
    message.loading({
      content: "Deleting from Favourites",
      duration: 0,
    });

    const updatedFavourites = favourites
      .filter((fav) => fav.id !== playingVideo.id)
      .map((item) => item.id);

    const preferencesToSave = {
      ...allPreferences.preferences,
      videoPreferences: {
        ...allPreferences.preferences.videoPreferences,
        favourites: updatedFavourites,
      },
    };

    try {
      await API.put("preferences", "/preferences", {
        body: {
          preferences: preferencesToSave,
        },
      });
      message.destroy();
      message.success("Changes saved successfuly!");
      setFavLoading(false);
      dispatch(updatePreferences(preferencesToSave));

      // DELETE FAVOURITE LOGS
      message.loading({
        content: "Deleting Logs",
        duration: 0,
      });

      const filters = [
        {
          operator: "AND",
          conditions: [
            {
              operator: "AND",
              column: "actionType",
              value: "Favourite",
              formula: "is",
            },
          ],
        },
        {
          operator: "AND",
          conditions: [
            {
              operator: "AND",
              column: "recordId",
              value: playingVideo.id,
              formula: "is",
            },
          ],
        },
        {
          operator: "AND",
          conditions: [
            {
              operator: "AND",
              column: "nameOfUser",
              value: userConfiguration.nameOfUser,
              formula: "is",
            },
          ],
        },
      ];

      const logs = await handleFetchLogs({ filters });

      if (logs.length === 0) return;

      const logsToDelete = logs.map(({ logId }) => logId);

      try {
        await API.del("editLogs", "/editLogs/123", {
          body: {
            logs: logsToDelete,
          },
        });
        message.destroy();
        message.success("Logs deleted successfuly!");
      } catch (error) {
        console.error(error);
        message.error("Something went wrong...");
      }
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error("There was an error while saving this");
    }
  };

  const onFavoriteClick = async () => {
    setFavLoading(true);
    message.loading({
      content: "Adding to Favourites...",

      duration: 0,
    });

    const updatedFavourites = [
      ...favourites.map((fav) => fav.id),
      playingVideo.id,
    ];

    const preferencesToSave = {
      ...allPreferences.preferences,
      videoPreferences: {
        ...allPreferences.preferences.videoPreferences,
        favourites: updatedFavourites,
      },
    };

    try {
      await API.put("preferences", "/preferences", {
        body: {
          preferences: preferencesToSave,
        },
      });

      saveAddedLogs({
        actionType: "Favourite",
        category: "Video Tutorials",
        recordId: playingVideo?.id,
        recordName: "Favourite",
        updatedKeys: [
          {
            type: v4(),
            key: "",
            label: playingVideo?.videoName,
            previousValue: "",
            updatedValue: "Favourite",
          },
        ],
      });
      message.destroy();
      message.success("Changes saved successfuly!");
      setFavLoading(false);
      dispatch(updatePreferences(preferencesToSave));
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error("There was an error while saving this");
    }
  };

  const isVideoFav = () => {
    return favourites?.find((fav) => fav.id === playingVideo.id);
  };

  const getVideoLink = () => {
    const subCatName = subCategoryName?.replaceAll(" ", "-");
    return `https://portal.corescaffold.com/dashboard?selectedVideoCat=${activePrimaryCat}&selectedVideoSubCat=${subCatName}&selectedVideoId=${playingVideo.id}`;
  };

  const onCloseViewsModal = () => {
    setViewsModal(false);
  };

  const TranscribeSwitch = () => {
    if (
      (playingVideo.hasOwnProperty("transcribedText") &&
        playingVideo.transcribedText.trimStart().length < 1) ||
      !playingVideo.hasOwnProperty("transcribedText")
    ) {
      return null;
    }

    return (
      <MondaySwitch
        {...{
          className: "switch",
          checked: showTranscript,
          onToggleSwitch: () => setShowTranscript((prev) => !prev),
          onText: "Transcript",
          offText: "Disabled",
          onIcon: <Tick />,
          offIcon: <LockOutlined />,
          removeIcon: false,
        }}
      />
    );
  };

  return !mobile ? (
    <Modal
      title={
        <>
          <span className="title">{playingVideo?.videoName}</span>
          <InfoCircleFilled
            style={{
              fontSize: "20px",
              color: "#1264A3",
            }}
          />
          <span>
            The total airtime of the videos is :<b> {checkSumVideo()}</b> min
          </span>
        </>
      }
      open={!customeModal ? true : false}
      closeIcon={<XIcon />}
      footer={
        <>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={handleCancel}
          >
            Close
          </MondayButton>
          <div className="right-buttons">
            <Tooltip title="Notes">
              <MondayButton
                className="mondayButtonBlue"
                Icon={<NotesIcon style={{ width: "23px", height: "23px" }} />}
                onClick={() => setNotesModalVisible(true)}
              />
            </Tooltip>
            {!emailBox && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<EmailIcon />}
                onClick={sendEmailClick}
              >
                Send Email
              </MondayButton>
            )}
            {emailBox && playingVideo && (
              <div className="requestEmailBox">
                <EmailBox
                  {...{
                    onClose() {
                      setEmailBox(false);
                    },
                    setEmails() {},
                    rowData: {
                      ...playingVideo,
                      videoLink: getVideoLink(),
                    },
                    attachments,
                    authToken: googleAccessToken,
                    onSave(e, type, setLoading) {
                      onEmailSave({ e, type, setLoading });
                    },
                    isFromCharge: true,
                    isRedirected: [],
                    sendEmailNotification,
                    proppedRecordId: playingVideo?.Id,
                    proppedTemplateCat: "Tutorial Videos",
                  }}
                />
              </div>
            )}
          </div>
        </>
      }
      onCancel={handleCancel}
      destroyOnClose={true}
      className={`playerModal ${isDarkMode && "playerModalDark"}`}
    >
      <div
        className={`videoModal-container ${
          tablet && "videoModal-container-tablet"
        }  ${mobile && "videoModal-container-mobile"}`}
      >
        <div className="videoAndDescription">
          {!loading && !!fileSrc ? (
            <div className="reactPlayerDiv">
              <ReactPlayer
                playbackRate={1}
                playsInline
                height="100%"
                width="100%"
                controls={true}
                url={[
                  {
                    src: fileSrc.src,
                  },
                ]}
                playing={playing}
                onStart={(e) => {
                  let time = playedVideos?.find(
                    (el) => el?.id === playingVideo?.id
                  )?.time;

                  if (!!time && !!videoPlayerRef?.current) {
                    videoPlayerRef?.current?.seekTo(time, "seconds");
                  }
                }}
                onProgress={handleProgress}
                onPlay={onPlay}
                onPause={() => setPlaying(false)}
                light={true}
                onEnded={() => onVideoEnd()}
                ref={(player) => {
                  if (!!player) {
                    videoPlayerRef.current = player;
                  }
                }}
                stopOnUnmount={true}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80%",
              }}
            >
              <LoadingDots style={{ fontSize: "50px" }} />
            </div>
          )}

          <div className="description-container">
            <div className="header">
              {!loading && (
                <>
                  <div className="fav-link">
                    {!unseenVideo && (
                      <StarCheckbox
                        {...{
                          checked: isVideoFav(),
                          onChange: (favorite) =>
                            !favLoading
                              ? favorite
                                ? onFavoriteClick()
                                : onFavoriteUnclick()
                              : message.error("Cant change"),
                        }}
                      />
                    )}
                    <DynamicAvatars
                      {...{
                        title: "Seen By",
                        isDarkMode: isDarkMode,
                        members: videViewDetails.lastSeenUsers.map(
                          (user) => user
                        ),
                        nameKey: "name",
                        idKey: "id",
                        customTooltip: true,
                      }}
                    />
                    {playingVideo.link && (
                      <button onClick={() => navigate(playingVideo.link)}>
                        {playingVideo.videoName}
                      </button>
                    )}
                  </div>
                  <div className="fav-link">
                    <Tooltip title="Views info">
                      <MondayButton
                        className="mondayButtonBlue"
                        Icon={
                          <OrderedListOutlined style={{ width: "1.2rem" }} />
                        }
                        onClick={() => setViewsModal(true)}
                      />
                    </Tooltip>
                    <TranscribeSwitch />
                  </div>
                </>
              )}
            </div>
            <div className="description-container">
              <h2 className="title">Description</h2>
              <div className="description-box">
                {playingVideo.hasOwnProperty("description") &&
                playingVideo?.description?.length > 0
                  ? htmlParser(playingVideo?.description)
                  : "No Description to show"}
              </div>
            </div>
          </div>
        </div>
        <div className="containerOpenModal">
          {!!showTranscript ? (
            <Transcription
              {...{
                video: playingVideo,
                handleTextClick,
                currentTime,
              }}
            />
          ) : (
            <VideoCards />
          )}
        </div>
        {viewsModal && (
          <ViewsInfo
            {...{
              open: viewsModal,
              onCancel: onCloseViewsModal,
              topUsers: videViewDetails.topUsers,
              totalViews: videViewDetails.totalViews,
            }}
          />
        )}
        {notesModalVisible && (
          <Notes
            notesVisibility={notesModalVisible}
            setNotesVisibility={setNotesModalVisible}
            noteModalName={playingVideo.videoName}
            rowId={playingVideo.id}
            topicCategory="Video Tutorials"
          />
        )}
      </div>
    </Modal>
  ) : (
    <Drawer
      title={playingVideo?.videoName}
      open={!customeModal ? true : false}
      onClose={() => setCustomeModal(false)}
      closable={false}
      footer={
        <>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={handleCancel}
          >
            Close
          </MondayButton>
          <div className="right-buttons">
            <Notes
              noteModalName={playingVideo.videoName}
              rowId={playingVideo.id}
              topicCategory="Video Tutorials"
            />
            {!emailBox && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<EmailIcon />}
                onClick={sendEmailClick}
              >
                Send Email
              </MondayButton>
            )}
            {emailBox && playingVideo && (
              <div className="requestEmailBox">
                <EmailBox
                  {...{
                    onClose() {
                      setEmailBox(false);
                    },
                    setEmails() {},
                    rowData: {
                      ...playingVideo,
                      videoLink: getVideoLink(),
                    },
                    authToken: googleAccessToken,
                    onSave(e, type, setLoading) {
                      onEmailSave({ e, type, setLoading });
                    },
                    isFromCharge: true,
                    isRedirected: [],
                    sendEmailNotification,
                    proppedRecordId: playingVideo?.Id,
                    proppedTemplateCat: "Tutorial Videos",
                  }}
                />
              </div>
            )}
          </div>
        </>
      }
      onCancel={handleCancel}
      destroyOnClose={true}
      className={`playerModal-drawer ${isDarkMode && "playerModalDark"}`}
    >
      <div className="videoAndDescription">
        {!loading ? (
          <div className="reactPlayerDiv">
            <ReactPlayer
              playbackRate={1}
              playsInline
              height="50%"
              width="100%"
              controls={true}
              url={[
                {
                  src: fileSrc.src,
                },
              ]}
              // url={videoActive} //! It was not working anymore
              playing={playing}
              onStart={(e) => {
                let time = playedVideos?.find(
                  (el) => el?.id === playingVideo?.id
                )?.time;

                if (!!time && !!videoPlayerRef?.current) {
                  videoPlayerRef?.current?.seekTo(time, "seconds");
                }
              }}
              onPlay={onPlay}
              onPause={() => setPlaying(false)}
              light={true}
              onEnded={() => onVideoEnd()}
              ref={(player) => {
                if (!!player) {
                  videoPlayerRef.current = player;
                }
              }}
              stopOnUnmount={true}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80%",
            }}
          >
            <LoadingDots style={{ fontSize: "50px" }} />
          </div>
        )}

        <div className="description-container">
          <div className="header">
            {!loading && (
              <>
                <div className="fav-link">
                  {playingVideo.newVideo === false && (
                    <StarCheckbox
                      {...{
                        checked: isVideoFav(),
                        onChange: (favorite) =>
                          !favLoading
                            ? onFavoriteClick(favorite)
                            : message.error("Cant change"),
                      }}
                    />
                  )}
                  <DynamicAvatars
                    {...{
                      title: "Seen By",
                      isDarkMode: isDarkMode,
                      members: videViewDetails.lastSeenUsers.map(
                        (user) => user
                      ),
                      nameKey: "name",
                      idKey: "id",
                      customTooltip: true,
                    }}
                  />
                  {playingVideo.link && (
                    <button onClick={() => navigate(playingVideo.link)}>
                      {playingVideo.videoName}
                    </button>
                  )}
                </div>
                <div className="fav-link">
                  <Tooltip title="Views info">
                    <MondayButton
                      className="mondayButtonBlue"
                      Icon={<OrderedListOutlined style={{ width: "1.2rem" }} />}
                      onClick={() => setViewsModal(true)}
                    />
                  </Tooltip>
                  <TranscribeSwitch />
                </div>
              </>
            )}
          </div>
          <div className="description-container">
            <h2 className="title">Description</h2>
            <div className="description-box">
              {playingVideo.hasOwnProperty("description") &&
              playingVideo?.description?.length > 0
                ? htmlParser(playingVideo?.description)
                : "No Description to show"}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
