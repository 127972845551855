import { SearchOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { AutoComplete, Popover } from "antd";
import { useState, useEffect } from "react";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import { fetchAllData } from "../../../Fleet/utils/fetchAllData";
import "./AssignProjectGridEdit.scss";
import { useSelector } from "react-redux";
import { SmallLoadableComp } from "../../../../Sidebars/components";

function AssignProjectGridEdit(props) {
  const [searchValue, setSearchValue] = useState("");
  const [allProjects, setAllProjects] = useState([]);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [saveData, setSavedData] = useState({});
  const [loading, setLoading] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const columnDefs = [
    {
      headerName: "Project Name",
      field: "projectName",
      cellEditor: "agTextCellEditor",
    },
    {
      headerName: "Account Name",
      field: "accountName",
      cellEditor: "agSelectCellEditor",
    },
    {
      headerName: "Project Status",
      field: "projectStatus",
      cellEditor: "agTextCellEditor",
    },
    {
      headerName: "Project Exective",
      field: "projectExecutive",
      cellEditor: "agSelectCellEditor",
    },
    {
      field: "Action",
      cellRendererFramework: (params) => {
        return params?.data?.projectId === props?.params?.projectId ? (
          <MondayButton Icon={<TickIcon />} disabled={true}>
            Assigned
          </MondayButton>
        ) : params?.data?.projectId !== undefined ? (
          <MondayButton
            Icon={<TickIcon />}
            onClick={() => {
              setSavedData({
                projectId: params?.data?.projectId,
                projectName: params?.data?.projectName,
              });
              setWarningModalVisible(true);
            }}
          >
            Assign
          </MondayButton>
        ) : null;
      },
    },
  ];

  function onCancel() {
    props.setEditMode(false);
    setWarningModalVisible(false);
  }

  function saveHandler() {
    let newBody = {
      projectId: saveData?.projectId,
      jobsite: saveData?.projectName,
    };
    function getLogData(projectId) {
      return {
        id: "assignProjectGrid",
        Card: "AssignProjectGrid",
        title: "Assign Project Grid",
        params: {
          projectId,
        },
      };
    }
    props.getEditedValue(
      newBody,
      {},
      {},
      getLogData(props?.params?.projectId),
      getLogData(saveData?.projectId)
    );
    onCancel();
  }

  useEffect(() => {
    setLoading(true);
    const fetchProjects = async () => {
      let result = await fetchAllData("projects", "projects", "projectId");
      setAllProjects(result || []);
      setLoading(false);
    };

    fetchProjects();
  }, []);

  const getFilteredProjects = () => {
    return allProjects?.filter(({ projectName }) => {
      return projectName.toLowerCase().includes(searchValue.toLowerCase());
    });
  };
  const changeFilterHandler = (event) => {
    setSearchValue(event);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const gridOptions = {
    pagination: true,
    paginationPageSize: 14,
    cacheBlockSize: 14,
  };

  return (
    <div
      className={`assignProjectGridEdit ${
        isDarkMode && "assignProjectGridEditDark"
      }`}
    >
      <div className="search-Div">
        <AutoComplete
          value={searchValue}
          placeholder="Search Jobsite"
          className="searchComp"
          popupClassName="search-dropDown"
          showSearch
          dropdownRender={false}
          autoFocus={true}
          suffixIcon={<SearchOutlined />}
          onChange={changeFilterHandler}
        />
      </div>
      {loading && (
        <div style={{ textAlign: "center", fontSize: 18 }}>
          Getting all projects...
        </div>
      )}
      <SmallLoadableComp loading={loading}>
        <div
          className={`grid-container ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
          style={{ width: "100%", height: 500 }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={getFilteredProjects()}
            onGridReady={onGridReady}
            animateRows
            pagination={true}
            defaultColDef={{
              resizable: true,
              enablePivot: true,
              enableColResize: true,
              enableRowGroup: true,
              editable: false,
              sortable: true,
              flex: 1,
              minWidth: 100,
              filter: true,
            }}
            gridOptions={gridOptions}
            suppressDragLeaveHidesColumns={true}
            rowGroupPanelShow={"always"}
            rowHeight={45}
            paginationPageSize={7}
          />
        </div>
      </SmallLoadableComp>

      {warningModalVisible && (
        <WarningModal
          visible={warningModalVisible}
          setVisible={setWarningModalVisible}
          title="Confirm"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p style={{ textAlign: "center" }}>
              Do you want to relate project{" "}
              <span
                style={{ fontWeight: 600 }}
              >{`"${saveData?.projectName}"`}</span>{" "}
              with this violation?
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setWarningModalVisible(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  saveHandler();
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </div>
  );
}

export default AssignProjectGridEdit;
