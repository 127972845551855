import React from "react";
import { Checkbox, message } from "antd";

/**
 * Checkbox to approve/disapprove pli
 * */
export default class ApprovedRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.shouldBeChecked(),
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleCheck = (event) => {
    if (this.props.isWritable) {
      const checked = event.target.checked;
      this.props.toggleApproveAll(checked, this.props.api);
      this.setState({ checked });
      // this.props.node.setDataValue(this.props.column.colId, {jumpWidth: this.state.jumpWidth, checked: checked});
      // // this.props.api.redrawRows({rowNodes: [this.props.node]})
      // this.props.api.refreshCells({rowNodes: [this.props.node]})
      //
      // if(checked === false) this.props.api.getRowNode(this.props.node.id).setExpanded(false) //close addons table
    } else {
      message.error("Please enable write mode to start editing.");
    }
  };

  shouldBeChecked = () => {
    let allApproved = true;
    this.props.api.forEachNode((n) => {
      if (n.data.approved !== true) allApproved = false;
    });

    return allApproved;
  };

  render() {
    // console.log(this.state)

    const isChecked = this.state.checked;
    return (
      <div className="approved-header">
        <Checkbox
          className={"checkboxRenderer-" + this.props.column.colId}
          onChange={this.handleCheck}
          checked={isChecked}
        />
        Approved
      </div>
    );
  }
}
