import { Modal } from "antd";
import { useSelector } from "react-redux";
import { useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import {
  crewAnalyticsColumns,
  crewAnalyticsDetails,
} from "./crewAnalyticsData";
import DegModalContext from "../DegModalContext";
import { getDistributionForTeams } from "../utils";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { InfoIconBlue } from "../../../../../../../Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

function CrewAnalytics({ open, onCancel }) {
  const { programFields } = useSelector((state) => state.programFields);
  const { analytics, isDarkMode, crewTeams } = useContext(DegModalContext);

  // const [teams] = useState(
  //   programFields?.find(({ fieldName }) => fieldName === "Crew Teams")
  //     .fieldOptions?.[process.env.NODE_ENV === "production" ? "prod" : "dev"] ||
  //     []
  // );
  const [rowData] = useState(
    getDistributionForTeams({ analytics, teams: crewTeams })
  );

  const handleCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  };

  return (
    <Modal
      {...{
        title: "Teams Cost",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Teams Cost" });
        },
        open,
        onCancel: handleCancel,
        closable: true,
        closeIcon: <XIcon />,
        ["data-testid"]: "team-cost-modal",
        className: `costDispersionModal ${
          isDarkMode && "costDispersionModalDark"
        }`,
        centered: true,
        footer: [
          <MondayButton
            className="mondayButtonRed"
            data-testid="team-cost-modal-close-btn"
            Icon={<XIcon />}
            key="1"
            onClick={handleCancel}
          >
            Close
          </MondayButton>,
        ],
      }}
    >
      <div
        className={`cost-dispersion-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        {!rowData?.length ? (
          <div className="info-dispersion">
            <InfoIconBlue />
            <b>
              There need to be shifts with at least one selected SOW in order to
              view results
            </b>
          </div>
        ) : (
          <></>
        )}
        <AgGridReact
          {...{
            rowData,
            columnDefs: crewAnalyticsColumns(),
            detailCellRendererParams: crewAnalyticsDetails(),
            masterDetail: true,
            rowSelection: "multiple",
            pagination: false,
            detailRowHeight: 350,
            gridOptions: {
              alwaysShowHorizontalScroll: true,
              alwaysShowVerticalScroll: true,
            },
            suppressRowClickSelection: true,
            animateRows: true,
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
    </Modal>
  );
}

export default CrewAnalytics;
