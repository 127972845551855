import { assignedToObject, teamSelect } from "..";
import sortFields from "../sortFields";

/**
 * Generates an array of fields for the fleet inventory request form.
 *
 * @param {Array} teams - The list of teams.
 * @param {Array} inputFields - The list of input fields.
 * @param {boolean} isDarkMode - Indicates if the dark mode is enabled.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {boolean} globalDisabled - Indicates if all fields are globally disabled.
 * @param {Function} onTeamSelect - The callback function for team selection.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @returns {Array} - The sorted array of fields for the fleet inventory request form.
 */
const fleetInventoryRequestFields = ({
  teams = [],
  inputFields = [],
  isDarkMode = false,
  userConfiguration = {},
  globalDisabled = false,
  onTeamSelect = () => {},
  allFieldsDisabled = false,
}) => {
  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;

    field.className = "defaultInputComponent";
    // delete field?.defaultField;
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Fleets Inventory",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default fleetInventoryRequestFields;
