import { Modal } from "antd";
import { useSelector } from "react-redux";

import { driveApi } from "../../../../../integrations/DriveRequest";
import { formatNumber } from "../../../utils";
import ImageLoader from "../../../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/ImageLoader";
import { XIcon } from "../../../Communication/assets";

import "./ItemInfoModal.scss";

const ItemInfoModal = ({ open, defect, onCancel }) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const driveRequest = driveApi({ accessToken });

  return (
    <Modal
      {...{
        open,
        closable: true,
        closeIcon: <XIcon fill="#333238" />,
        centered: true,
        title: `${defect?.defectName} Information`,
        onCancel,
        footer: null,
        className: `defectInfoModal ${isDarkMode ? "defect-modal-dark" : ""}`,
      }}
    >
      <div className="defectInfoModalContainer">
        <div className="infoLine">
          <strong>Name: </strong>
          <span>{defect?.defectName}</span>
        </div>
        <div className="infoLine">
          <strong>Priority: </strong>
          <div className={`defectPriority ${defect?.defectPriority}`}>
            <span>{defect?.defectPriority}</span>
          </div>
        </div>
        <div className="infoLine">
          <strong>Status: </strong>
          <div
            className={`defectStatus ${defect?.defectStatus?.replaceAll(
              " ",
              ""
            )}`}
          >
            <span>{defect?.defectStatus}</span>
          </div>
        </div>
        <div className="infoLine">
          <strong>Fleet: </strong>
          <span>{defect?.fleetName}</span>
        </div>
        <div className="infoLine">
          <strong>Driver: </strong>
          <span>{defect?.driverName}</span>
        </div>
        <div className="infoLine">
          <strong>Odometer Reading: </strong>
          <span>
            {formatNumber(defect?.odometerReading, {
              unit: "mile",
              fallback: "--",
              fixed: 0,
            })}
          </span>
        </div>
        <div className="infoImages">
          <strong>Images: </strong>
          <div className="imagesContainer">
            {defect.googleDriveFolderId?.filter(Boolean)?.map((file) => {
              return (
                file?.id && (
                  <ImageLoader
                    driveRequest={driveRequest}
                    fileId={file?.id}
                    styles={{ height: 100, width: 100 }}
                  />
                )
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ItemInfoModal;
