function convertSafetyReport(data = []) {
  const dataSections = [];
  if (!Array.isArray(data)) {
    return dataSections;
  }

  for (const section of data) {
    const data = titleBodyHtmlTable(section, dataSections) || [];
    dataSections.push(...data);
  }
  return dataSections;
}

export default convertSafetyReport;

export function titleBodyHtmlTable(object, newTitleClassName) {
  const toReturn = [];
  if (!object) {
    return toReturn;
  }
  const { title, body } = object || {};
  let titleClassName = newTitleClassName
    ? newTitleClassName
    : `safety-section-header`;
  const titleFormat = {
    text: {
      paragraphs: [
        {
          text: title,
          bold: true,
          fontSize: 16,
          class: titleClassName,
        },
      ],
    },
  };
  toReturn.push(titleFormat);
  const bodyFormat = {
    table: {
      class: "safety-table",
      cols: [],
      rows: (body || []).map(({ key = "", value = "" }) => {
        const strVal = !!value ? "" + value : "";
        if (strVal.includes("data:image")) {
          return [
            key,
            {
              // image: value,
              width: 100,
              height: 100,
              src: strVal,
              class: "safety-image",
            },
          ];
        } else {
          return [key, strVal];
        }
      }),
    },
  };
  toReturn.push(bodyFormat);

  return toReturn;
}
