export const transformServices = (projectServices, services) => {
  const otherServices = {
    label: "Other Services",
    options: services.map((service) => ({
      estimationId: "",
      label: service.serviceName,
      serviceId: service.serviceId,
      value: service.serviceName,
    })),
  };

  if (!projectServices) return [otherServices];

  const result = [];

  const groupedByEstimation = {};

  // Group projectServices by estimationNumber
  Object.keys(projectServices).forEach((key) => {
    projectServices[key].forEach((service) => {
      const { estimationNumber, label, serviceId, estimationId } = service;

      if (!groupedByEstimation[estimationNumber]) {
        groupedByEstimation[estimationNumber] = {
          label: `Estimation #${estimationNumber}`,
          options: [],
        };
      }

      groupedByEstimation[estimationNumber].options.push({
        label: label,
        value: label,
        serviceId: serviceId,
        estimationId: estimationId,
      });
    });
  });

  // Convert the grouped object to an array
  for (const key in groupedByEstimation) {
    result.push(groupedByEstimation[key]);
  }

  result.push(otherServices);

  return result;
};
