import types from "../../types";

const initialState = {
  isLoggedIn: localStorage.getItem("googleAuthAccessToken") ? true : false,
};

const googleAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case types.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export default googleAuthReducer;
