import { createScheduleOfValue } from "../../../../../../../../../actions/scheduleOfValues";
import {
  MondayButton,
  WithTooltip,
} from "../../../../../../../../commonComponents";
import { Close } from "../../../../../../../DynamicView/src";
import { handleSubmit } from "./modalFooterFunctions";
import "./ModalFooter.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Dropdown } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { filterTables } from "../../../../../../../../../utils";
import { useEditLogs } from "../../../../../../../../../hooks";

const ModalFooter = ({
  setSelectedEstimations,
  selectedEstimations,
  setButtonType,
  estimations,
  buttonType,
  closeModalWithWarning,
  projectId,
  visible,
  closeModal,
  isNextStep,
  getApplicationId,
}) => {
  const [disableCount, setDisableCount] = useState(0);
  const [getSov, setGetSov] = useState([]);
  const [redirectToScheduleOfValue, setRedirectToScheduleOfValue] =
    useState(false);
  const buttonTypes = ["Save & Close", "Finish", "Save & New"];
  const { saveAddedLogs } = useEditLogs();

  const {
    isLoading: isCreatingNewSov,
    newlyCreatedScheduleOfValue,
    scheduleOfValues,
  } = useSelector((state) => state.scheduleOfValues);
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (visible) {
      setDisableCount(0);
    }
  }, [visible]);

  const menu = {
    onClick: (e) => setButtonType(e.key),
    items: buttonTypes
      ?.filter((type) => type !== buttonType)
      ?.map((type) => ({ key: type, label: type })),
  };
  const createSov = async () => {
    setDisableCount((prev) => prev + 1);
    const previousScheduleOfValues = getSov?.filter(
      (sovData) => sovData?.projectId === projectId
    );

    const previousScheduleOfValue = previousScheduleOfValues?.reduce(
      (acc, cur) => (acc?.SOVNo > cur?.SOVNo ? acc : cur),
      null
    );

    handleSubmit({
      setRedirectToScheduleOfValue,
      previousScheduleOfValue,
      setSelectedEstimations,
      createScheduleOfValue,
      selectedEstimations,
      authUser,
      estimations,
      buttonType,
      closeModalWithWarning,
      projectId,
      dispatch,
      isNextStep,
      getApplicationId,
      saveAddedLogs,
    });
  };

  // Handle redirection to newly created SOV
  useEffect(() => {
    // If we are redirecting to newly created SOV
    // newlyCreatedScheduleOfValue will be the SOV that we just created and it will resets to null, before creating another SOV
    if (redirectToScheduleOfValue && newlyCreatedScheduleOfValue) {
      !!!isNextStep &&
        navigate(`/scheduleofvalues/${newlyCreatedScheduleOfValue.scheduleId}`);
      closeModal(); //this function is needed when creating sov from shortcut button
    }
  }, [history, redirectToScheduleOfValue, newlyCreatedScheduleOfValue]);

  useEffect(() => {
    async function fetchScheduleOfValues() {
      filterTables("scheduleOfValues", "projectId", projectId).then((r) => {
        setGetSov(r);
      });
    }
    fetchScheduleOfValues();
  }, [projectId]);

  return (
    <div className="sovModalContainer">
      <MondayButton
        className="cancelButton"
        Icon={<Close />}
        onClick={closeModalWithWarning}
        tooltipCategory="Schedule Of Values"
        tooltipKey="cancel"
      >
        Cancel
      </MondayButton>

      <WithTooltip tooltipCategory="Schedule Of Values" tooltipKey="finish">
        <Dropdown.Button
          className="dropDownButtonNewSOV"
          type="primary"
          placement="bottomCenter"
          icon={<CaretDownOutlined />}
          menu={menu}
          onClick={createSov}
          disabled={
            disableCount === 1 || !selectedEstimations.length ? true : false
          }
        >
          {buttonType}
        </Dropdown.Button>
      </WithTooltip>
    </div>
  );
};

export default ModalFooter;
