import { formatCurrency } from "../../../../utils";
import {
  calculateInvoiceStatus,
  categoriesName,
  getSums,
  groupBy,
} from "../../calculatingFunctions";

export const invoices = ({ tables, programFields }) => {
  const invoicesStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = invoicesStatus?.statusName?.map((status) =>
    calculateInvoiceStatus({ table: tables?.invoices, status })
  );

  return {
    invoiceStatus: () => [
      {
        labels: invoicesStatus?.statusName,
        data: statusData?.map((el) => el?.length),
        backgroundColor: invoicesStatus?.statusColor,
        custom:
          !!statusData[0] &&
          groupBy(statusData, "projectName")?.map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Invoice Amount With Tax": formatCurrency(
                    getSums({
                      data: statusData,
                      key: "totalInvoiceAmount",
                    })[index] || 0
                  ),
                  "Invoice Amount Without Tax": formatCurrency(
                    getSums({
                      data: statusData,
                      key: "subtotal",
                    })[index] || 0
                  ),
                  "Total Tax": formatCurrency(
                    (getSums({
                      data: statusData,
                      key: "totalInvoiceAmount",
                    })[index] || 0) -
                      (getSums({
                        data: statusData,
                        key: "subtotal",
                      })[index] || 0)
                  ),
                },
              },
              ...Object?.entries(el).map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Invoice Amount With Tax": formatCurrency(
                      getSums({
                        data: value,
                        key: "totalInvoiceAmount",
                      }) || 0
                    ),
                    "Invoice Amount Without Tax": formatCurrency(
                      getSums({ data: value, key: "subtotal" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalInvoiceAmount" }) -
                        getSums({ data: value, key: "subtotal" })
                    ),
                  },
                };
              }),
            ];
          }),
      },
    ],
  };
};
