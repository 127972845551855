import { AgGridReact } from "ag-grid-react";
import {
  fieldTableColumnDefs,
  fieldTableDefaultColDefs,
  rowDataWithFilters,
  getScheduleDateRange,
  isProjectCompleted,
} from "./helpers";
import { gridOptions } from "../../../../commonComponents/UploadRecords/components/Steps/columnDefs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MobileDashboardTable from "./Mobile/MobileDashboardTable";
import { useNavigate } from "react-router-dom";
import { CardTemplate } from "../../../Settings/settingsComponents/Management/EditUser/components";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { SearchOutlined } from "@ant-design/icons";
import { MondayButton } from "../../../../commonComponents";
import { FilterIcon } from "../../../../SidebarPages/BasePage/src";
import { SmallLoadableComp } from "../../../../Sidebars/components";
import useTranslate from "../../Language/useTranslate";
import "./FieldDashboardTable.scss";
import { isEmpty } from "lodash";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import FieldTableFilters from "./FieldTableFilters/FieldTableFilters";

function FieldDashboardTable({ loading, rowData, setSelectedProject }) {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({});
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filteredRowData, setFilteredRowData] = useState([]);
  const [hasFilters, setHasFilters] = useState(false);

  useEffect(() => {
    setFilteredRowData(rowData);
  }, [rowData]);

  const { t } = useTranslate();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const fieldTableColumnDefsMemo = useMemo(
    () =>
      fieldTableColumnDefs({
        t,
      }),
    [t]
  );

  function onApply() {
    setFilteredRowData(rowDataWithFilters(rowData, filters));
    setFiltersVisible(false);
    checkHasFilters();
  }

  function clearFilters() {
    setFilters({});
    setFilteredRowData(rowData);
    setFiltersVisible(false);
    setSearchValue("");
    setHasFilters(false);
    gridApi?.setQuickFilter("");
  }

  const checkHasFilters = () => {
    let toReturn = false;
    if (Object.keys(filters)?.length > 0) {
      toReturn = Object.keys(filters)?.some((key) => !isEmpty(filters[key]));
    }
    setHasFilters(toReturn);
  };

  return (
    <CardTemplate
      {...{
        title: t("Projects"),
        className: "mobile-dashboard-table-container",
      }}
    >
      <SmallLoadableComp loading={loading}>
        <div className="table-search-container">
          <InputComponent
            {...{
              dataTestid: "search-input",
              noFormItem: true,
              value: searchValue,
              placeholder: `${t("Search")}...`,
              onChange: (e) => {
                const value = e?.target?.value || "";
                setSearchValue(value);
                gridApi?.setQuickFilter(value);
              },
              prefix: <SearchOutlined />,
              className: "table-search-input",
            }}
          />

          <MondayButton
            {...{
              "data-testid": "filter-button",
              onClick: () => {
                setFiltersVisible(true);
              },
              className: "mondayButtonGrey",
              Icon: <FilterIcon />,
            }}
          />
          {(hasFilters || searchValue) && (
            <MondayButton
              {...{
                "data-testid": "clear-filters-button",
                onClick: clearFilters,
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            />
          )}
        </div>
        {isMobileView ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
            className="field-dashboard-table-container"
          >
            <div
              className={`${
                isDarkMode
                  ? "dark-ag-theme ag-theme-alpine-dark"
                  : "light-ag-theme ag-theme-alpine"
              }`}
              style={{ height: "100%", width: "100%" }}
              id="agGridId"
            >
              <AgGridReact
                id="agGridId"
                defaultColDef={fieldTableDefaultColDefs}
                columnDefs={fieldTableColumnDefsMemo}
                rowData={filteredRowData}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                getRowStyle={(params) => {
                  const isCompleted = isProjectCompleted(
                    params?.data?.schedules
                  );

                  return { background: isCompleted && "#D5F1C9" };
                }}
                onRowClicked={(row) => {
                  // setSelectedProject(row?.data);
                  navigate(`/foremanProject/${row?.data?.projectId}`);
                }}
              />
            </div>
          </div>
        ) : (
          <MobileDashboardTable
            {...{
              searchValue,
              tableData: filteredRowData.map(
                ({
                  projectName,
                  accountName,
                  schedules,
                  projectId,
                  projectAddress,
                }) => {
                  const isCompleted = isProjectCompleted(schedules);
                  return {
                    projectName: { projectName, className: "header-text" },
                    accountName: { accountName, className: "normal-text" },
                    period: {
                      period: (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{getScheduleDateRange(schedules)}</span>
                          {isCompleted && (
                            <span className="green-text">{t("Completed")}</span>
                          )}
                        </span>
                      ),
                      className: "grey-text",
                    },
                    otherKeys: {
                      schedules,
                      projectAddress,
                      accountName,
                    },
                    linkId: { projectId },
                  };
                }
              ),
              linkKey: "projectId",
              filterKeys: ["projectName", "accountName"],
              pathName: "/foremanProject",
            }}
          />
        )}
      </SmallLoadableComp>
      {filtersVisible && (
        <FieldTableFilters
          {...{
            visible: filtersVisible,
            setVisible: setFiltersVisible,
            filters,
            setFilters,
            rowData,
            onApply,
            clearFilters,
            t,
          }}
        />
      )}
    </CardTemplate>
  );
}

export default FieldDashboardTable;
