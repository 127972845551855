import { axesTooltipCustom } from "../../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "inspType",
    groupKey: "inspectionType",
    xAxisName: "Inspection Type",
  },
  {
    chartName: "inspStatus",
    groupKey: "inspectionStatus",
    xAxisName: "Inspection Status",
  },
  {
    chartName: "projectExecutive",
    groupKey: "projectExecutive",
    xAxisName: "Project Executive",
  },
  {
    chartName: "createdBy",
    groupKey: "createdBy.name",
    xAxisName: "Inspections",
  },
];

export const getChartingOptions = (data) => {
  let inspType = new Set();
  let inspStatus = new Set();
  let projectExecutive = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const type = item.inspectionType || "No Data";
    const status = item.inspectionStatus || "No Data";
    const pExecutive = item.projectExecutive || "No Data";
    const user = item.createdBy.name || "No Data";

    inspType.add(type);
    inspStatus.add(status);
    projectExecutive.add(pExecutive);
    createdBy.add(user);
  });

  const countOnInspectionType = {
    title: {
      text: "Total Inspections Count",
    },
    subtitle: {
      text: "Based on Inspection Type",
    },
    series:
      Array.from(inspType).map((type) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: type,
          yName: type,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspections"),
          },
        };
      }) || [],
  };

  const countOnInspectionStatus = {
    title: {
      text: "Total Inspections Count",
    },
    subtitle: {
      text: "Based on Inspection Status",
    },
    series:
      Array.from(inspStatus).map((status) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: status,
          yName: status,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspections"),
          },
        };
      }) || [],
  };

  const countOnProjectExecuitve = {
    title: {
      text: "Total Opportunities Count",
    },
    subtitle: {
      text: "Based on Project Executive",
    },
    series:
      Array.from(projectExecutive).map((executive) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: executive,
          yName: executive,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspections"),
          },
        };
      }) || [],
  };

  const countOnCreatedBy = {
    title: {
      text: "Total Inspections Count",
    },
    subtitle: {
      text: "Based on Created By",
    },
    series:
      Array.from(createdBy).map((user) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: user,
          yName: user,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Inspections"),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle: "Show total Inspections count based on Inspection Type",
      params: countOnInspectionType,
      key: "inspType",
    },
    {
      optionTitle: "Show total Inspections count based on Inspection Status",
      params: countOnInspectionStatus,
      key: "inspStatus",
    },
    {
      optionTitle: "Show total Opportunities count based on Project Executive",
      params: countOnProjectExecuitve,
      key: "projectExecutive",
    },
    {
      optionTitle: "Show total Opportunities count based on Created By",
      params: countOnCreatedBy,
      key: "createdBy",
    },
  ];

  return chartingOptions;
};
