import { SimplePLI } from "./GeneralModels";

export class conEdVaultProtectionPli extends SimplePLI {
  serviceId = 71;

  length;
  width;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
