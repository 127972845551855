import { getQuickDescription } from "../components/PaymentTable/components/InvoiceOverview/InvoiceOverview";
import { forceToNumber } from "./checkers";

const filterInvoicesByKeyId = (state, invoices, projects, id, key) => {
  return [
    ...state.invoices,
    ...invoices
      .filter((el) => el[key] === id && el.amountDue > 0) // <-- filter out invoices where amountDue is greater than 0
      .map((invoice) => {
        const creditedAmount = invoice?.charges
          ?.filter((filterCharge) => filterCharge?.chargeType === "Credit Memo")
          .reduce((total, charge) => total + (charge?.chargeAmount || 0), 0);

        return {
          invoiceId: invoice?.invoiceId,
          invoiceAmount: invoice?.totalInvoiceAmount,
          accountName: invoice?.accountName || "",
          invoiceAmountDue: invoice.amountDue,
          invoicePaidPercentage: Number(
            (forceToNumber(invoice?.invoicePaidAmount || 0) * 100) /
              forceToNumber(invoice?.amountDue)
          ),
          invoiceDescription: getQuickDescription({
            applicableInvoice: invoice,
          }),
          invoicePaidAmount: invoice?.invoicePaidAmount || 0,
          invoiceNumber: invoice?.invoiceNumber,
          invoiceDate: invoice?.invoiceDate,
          invoiceChecked: invoice?.invoiceChecked ?? true,
          invoiceTaxRate: invoice?.salesTax,
          invoiceSubTotal: invoice?.subTotal,
          projectId: invoice?.projectId,
          projectAddress:
            projects?.find((el) => el?.projectId === invoice.projectId)
              ?.projectAddress || "",
          invoiceCreditedAmount: creditedAmount,
        };
      }),
  ];
};

export default filterInvoicesByKeyId;
