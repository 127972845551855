type FilterDataType = {
  globalKeyId: string;
  label: string;
  type: string;
  key: string | Array<string>;
  min?: number;
  max?: number;
};

export const gridFilters: Array<FilterDataType> = [
  {
    globalKeyId: "27460cca-0b8e-4353-9389-e6ade02fd9fe",
    label: "Punch Time Range",
    type: "TimeStampRange",
    key: "punchTimeStamp",
  },
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Full Name",
    type: "Dropdown",
    key: "employeeFullName",
  },
  {
    globalKeyId: "78af444b-a8c0-4a9c-81e6-0bd9766a731e",
    label: "Role",
    type: "Dropdown",
    key: "employeeRole",
  },
  {
    globalKeyId: "6gj1edzbn-0b8a-400f-b352-6354cb0f22ec",
    label: "Punch Type",
    type: "Dropdown",
    key: "punchType",
  },
  {
    globalKeyId: "ac810ced-788d-4ce3-a6f9-123081326d38",
    label: "Jobsite Match",
    type: "Dropdown",
    key: "jobAddress",
  },
  {
    globalKeyId: "f4ba4b38-4dcc-4080-9e59-187e8df984ad",
    label: "Punch Location",
    type: "Dropdown",
    key: "punchLocation",
  },
  {
    globalKeyId: "051de1a2-99a8-4106-8891-a5c60afcffd6",
    label: "Crew",
    type: "Dropdown",
    key: "crewTeamName",
  },
  {
    globalKeyId: "19b8778f-2b9e-425d-8ce4-a7e585f77b62",
    label: "Status",
    type: "Dropdown",
    key: "activityStatus",
  },
  {
    globalKeyId: "bcf43303-5249-4d90-a35a-ec7f5ac16cf1",
    label: "Origin",
    type: "Dropdown",
    key: "uploadName",
  },
  //   globalKeyId: "f4d3fc54-9723-426e-af6e-86455aebf3e5",
  //   label: "Distance Match",
  //   type: "SliderRange",
  //   key: "distanceFromJob",
  //   min: 100,
  //   max: 10000,
  //   range: { draggableTrack: true },
  //   marks: { 100: "100 ft", 10000: "10000 ft" },
  // },
];

export const shiftGridFilters: Array<FilterDataType> = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Full Name",
    type: "Dropdown",
    key: "employeeFullName",
  },
  {
    globalKeyId: "19b8778f-2b9e-425d-8ce4-a7e585f77b62",
    label: "Status",
    type: "Dropdown",
    key: "activityStatus",
  },
  {
    globalKeyId: "ac810ced-788d-4ce3-a6f9-123081326d38",
    label: "Jobsite Match",
    type: "Dropdown",
    key: "jobsiteAddress",
  },
  {
    globalKeyId: "78af444b-a8c0-4a9c-81e6-0bd9766a731e",
    label: "Role",
    type: "Dropdown",
    key: "employeeRole",
  },
  {
    globalKeyId: "78af444b-a8c0-4a9c-81e6-0bd9766a731e",
    label: "Shift Type",
    type: "Dropdown",
    key: "shiftType",
  },
  {
    globalKeyId: "78af444b-a8c0-4a9c-81e6-0bd9766a731e",
    label: "Team",
    type: "Dropdown",
    key: "crewTeamName",
  },
];
