import { useCallback } from "react";
import {
  personalInjuryIncidentFields,
  propertyDamageIncidentFields,
  otherIncidentFields,
} from "./formData";

/**
 *
 * @param {Object} data - Object that contains all the data that we need to create and manipulate incident fields dynamically
 * @returns {Object} - an object that contains pointers to functions that will create the incident fields
 */
const incidentFieldsFunction = (data = {}) => ({
  "Property Damage": () => propertyDamageIncidentFields({ ...data }),
  "Personal Injury": () => personalInjuryIncidentFields({ ...data }),
  "Other Trade Incident": () => otherIncidentFields({ ...data }),
});

/**
 * This custom hook calls the necessary function to create the safety fields
 * which is kept in a useCallback hook that will change when the incidentCategory changes
 *
 * @param {String} incidentCategory
 * @returns {Array} - incident fields
 */
const useIncidentFields = (incidentCategory) => {
  const incidentFields = useCallback(
    (data) => {
      try {
        return incidentFieldsFunction(data)?.[incidentCategory]();
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    [incidentCategory]
  );

  return { incidentFields };
};

export default useIncidentFields;
