export const countAdditionalRentals = (data = {}) => {
  let count = 0;

  for (const key in data) {
    for (const subKey in data[key]) {
      const rental = data[key][subKey];
      if (rental) {
        count++;
      }
    }
  }

  return count;
};
