import React, { createContext, useState, useContext } from "react";

// Create the LoadingContext
const EmailBoxBodyContext = createContext();

// Create a custom hook to use the LoadingContext
export const useEmailBoxBody = () => {
  return useContext(EmailBoxBodyContext);
};

// Create the LoadingProvider component
export const EmailBoxBodyProvider = ({ children }) => {
  const [editorContent, setEditorContent] = useState("");
  const [showEditorToolbar, setShowEditorToolbar] = useState(false);

  return (
    <EmailBoxBodyContext.Provider
      value={{
        editorContent,
        setEditorContent,
        showEditorToolbar,
        setShowEditorToolbar,
      }}
    >
      {children}
    </EmailBoxBodyContext.Provider>
  );
};
