import _ from "lodash";
import { formatNumber } from "../../utils";
import {
  FleetInspectionType,
  DefectType,
  WorkOrderType,
  InventoryType,
} from "../types";
import {
  parseInTz,
  setHourMinute,
} from "../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { HtmlReportTypes } from "../../Fleet/fleetsLive/components/LiveReportsView/utils";

type PdfData = {
  inspections: FleetInspectionType[];
  defects: DefectType[];
  workOrders: WorkOrderType[];
  include: Array<"INSPECTION" | "WORK_ORDER">;
  precedence: "DATE" | "VEHICLE";
  inventoryItems: InventoryType[];
  dateRange?: [number, number];
  selectedVehicle?: string;
};

const DEFECT_HEADER_ROW = [
  "Defect Id",
  "Defect",
  "Status",
  "Priority",
  "Severity",
];

function generateMaintenancePdfTemplate(data: PdfData) {
  const {
    defects,
    inspections,
    workOrders,
    include,
    precedence,
    inventoryItems,
    selectedVehicle,
    dateRange = [0, Infinity],
  } = data;
  const dataSections: HtmlReportTypes.DataSection[] = [];
  let groupedSections: Record<string, HtmlReportTypes.DataSection[]> = {};

  for (const section of include) {
    if (section === "INSPECTION") {
      const sortedInspections = [...inspections]
        .filter(({ inspectionDate, fleetId }) => {
          if (selectedVehicle) {
            if (fleetId !== selectedVehicle) {
              return false;
            }
          }

          const d = parseInTz(inspectionDate).startOf("day").valueOf();
          return d >= dateRange[0] && d <= dateRange[1];
        })
        .sort(
          (a, b) =>
            a.fleetName.localeCompare(b.fleetName) ||
            setHourMinute(
              parseInTz(a.inspectionDate),
              parseInTz(
                a.inspectionDetails?.find(({ label }) => label === "Time")?.time
              )
            ).valueOf() -
              setHourMinute(
                parseInTz(b.inspectionDate),
                parseInTz(
                  b.inspectionDetails?.find(({ label }) => label === "Time")
                    ?.time
                )
              ).valueOf()
        );

      dataSections.push({
        text: {
          paragraphs: [{ bold: true, text: "Inspections", class: "separator" }],
        },
      });

      for (const inspection of sortedInspections) {
        const inspectionDefects = defects.filter(
          ({ inspectionId }) => inspectionId === inspection.inspectionId
        );

        let sectionGroupKey: string;
        const formattedDate: string = parseInTz(
          inspection.inspectionDate
        ).format("MMM DD, YYYY");

        if (precedence === "DATE") {
          sectionGroupKey = formattedDate;
        } else {
          sectionGroupKey = inspection.fleetName;
        }

        if (!groupedSections?.[sectionGroupKey]) {
          const isFirst = !Object.keys(groupedSections).length;

          let className = `section-header`;
          if (isFirst) {
            className = className + ` ${isFirst ? "after-section" : ""}`;
          }

          groupedSections[sectionGroupKey] = [
            {
              text: {
                paragraphs: [
                  {
                    bold: true,
                    text:
                      precedence === "DATE"
                        ? formattedDate
                        : inspection.fleetName,
                    class: className,
                  },
                ],
              },
            },
          ];
        }

        groupedSections[sectionGroupKey] = [
          ...groupedSections[sectionGroupKey],
          {
            class:
              groupedSections[sectionGroupKey]["length"] > 1
                ? "child-section"
                : undefined,
            table: {
              cols: [],
              rows: [
                [
                  `<b>${
                    precedence === "DATE" ? "Vehicle#" : "Inspection Date"
                  }:</b> ${
                    precedence === "DATE" ? inspection.fleetName : formattedDate
                  }`,
                  `<b>Inspection Type:</b> ${inspection.inspectionType}`,
                ],
                [
                  `<b>Mechanic:</b> ${
                    inspection.mechanicInfo.mechanic ?? "No Data"
                  }`,
                  `<b>Location:</b> ${
                    inspection.inspectionDetails.find(({ label }) =>
                      label.includes("Location")
                    )?.location || "No Data"
                  }`,
                ],
                [
                  `<b>Inspection Time:</b> ${parseInTz(
                    inspection.inspectionDetails.find(({ label }) =>
                      label.includes("Time")
                    )?.time || undefined
                  ).format("hh:mm a")}`,
                  `<b>Odometer Reading:</b> ${
                    inspection.inspectionDetails.find(({ label }) =>
                      label.includes("Odometer")
                    )?.odometerReading || "No Data"
                  }`,
                ],
              ],
              class: "card-table card-table-inspection",
            },
          },
        ];

        if (inspectionDefects.length) {
          const tmpRows: HtmlReportTypes.TableType["rows"] = [];

          const defectDataSection: HtmlReportTypes.DataSection = {
            table: {
              cols: [...DEFECT_HEADER_ROW, "Work Order"],
              rows: tmpRows,
              class: "defect-table",
            },
          };

          for (const defect of inspectionDefects) {
            tmpRows.push([
              { text: defect.defectId, bold: true },
              defect.defectName,
              defect.defectStatus,
              defect.defectPriority,
              defect.defectSeverity,
              defect?.workOrderId || "",
            ]);
          }

          groupedSections[sectionGroupKey] = [
            ...groupedSections[sectionGroupKey],
            defectDataSection,
          ];
        }
      }
    } else {
      const sortedWorkOrders = [...workOrders]
        .filter(
          ({ createdAt, dueDate, issueDate, completionDate, fleetId }) => {
            if (selectedVehicle) {
              if (fleetId !== selectedVehicle) {
                return false;
              }
            }
            return (
              dateRange[0] <=
                (completionDate
                  ? parseInTz(completionDate).startOf("day").valueOf()
                  : dueDate) && dateRange[1] >= (issueDate || createdAt)
            );
          }
        )
        .sort(
          (a, b) =>
            a.fleetName.localeCompare(b.fleetName) ||
            (a.issueDate || a.createdAt) - (b?.issueDate || b?.createdAt)
        );

      const inventoryRecords: Record<string, InventoryType> = _.keyBy(
        inventoryItems,
        "itemId"
      );

      dataSections.push({
        text: {
          paragraphs: [{ bold: true, text: "Work Orders", class: "separator" }],
        },
      });

      for (const workOrder of sortedWorkOrders) {
        const woDefects = defects.filter(
          ({ workOrderId }) => workOrderId === workOrder.workOrderId
        );

        const woStart: number = workOrder?.issueDate || workOrder.createdAt;

        let sectionGroupKey: string;
        const formattedDate: string = parseInTz(woStart).format("MMM DD, YYYY");

        if (precedence === "DATE") {
          sectionGroupKey = formattedDate;
        } else {
          sectionGroupKey = workOrder.fleetName;
        }

        if (!groupedSections?.[sectionGroupKey]) {
          const isFirst = !Object.keys(groupedSections).length;

          let className = `section-header`;
          if (isFirst) {
            className = className + ` ${isFirst ? "after-section" : ""}`;
          }

          groupedSections[sectionGroupKey] = [
            {
              text: {
                paragraphs: [
                  {
                    bold: true,
                    text:
                      precedence === "DATE"
                        ? formattedDate
                        : workOrder.fleetName,
                    class: className,
                  },
                ],
              },
            },
          ];
        }

        let totHours = 0,
          totLabor = 0,
          totParts = 0;
        for (const info of workOrder.laborInformation) {
          totHours += Number(info.hoursWorked || 0);
          totLabor += Number(info.total);
        }

        for (const item of workOrder.inventoryItems) {
          totParts +=
            +item.quantity * +inventoryRecords?.[item.itemId]?.["costPerUnit"];
        }

        groupedSections[sectionGroupKey] = [
          ...groupedSections[sectionGroupKey],
          {
            class:
              groupedSections[sectionGroupKey]["length"] > 1
                ? "child-section"
                : undefined,
            table: {
              cols: [],
              rows: [
                [
                  `<b>${
                    precedence === "DATE" ? "Vehicle#" : "Issue Date"
                  }:</b> ${
                    precedence === "DATE" ? workOrder.fleetName : formattedDate
                  }`,
                  `<b>Mechanic:</b> ${
                    workOrder.mechanicInfo.mechanic ?? "No data"
                  }`,
                ],
                [
                  `<b>Due Date:</b> ${parseInTz(workOrder.dueDate).format(
                    "MMM DD, YYYY"
                  )}`,
                  `<b>Completed At:</b> ${
                    workOrder.completionDate
                      ? parseInTz(workOrder.completionDate).format(
                          "MMM DD, YYYY"
                        )
                      : "Not completed"
                  }`,
                ],
                [
                  `<b>Total Labor:</b> ${formatNumber(totLabor, {
                    currency: "USD",
                  })}`,
                  `<b>Total Items:</b> ${formatNumber(totParts, {
                    currency: "USD",
                  })}`,
                ],
              ],
              class: "card-table card-table-work-order",
            },
          },
        ];

        if (woDefects.length) {
          const tmpRows: HtmlReportTypes.TableType["rows"] = [];

          const defectDataSection: HtmlReportTypes.DataSection = {
            table: {
              cols: [...DEFECT_HEADER_ROW, "Inspection"],
              rows: tmpRows,
              class: "defect-table",
            },
          };

          for (const defect of woDefects) {
            tmpRows.push([
              { text: defect.defectId, bold: true },
              defect.defectName,
              defect.defectStatus,
              defect.defectPriority,
              defect.defectSeverity,
              defect?.inspectionId || "",
            ]);
          }

          groupedSections[sectionGroupKey] = [
            ...groupedSections[sectionGroupKey],
            defectDataSection,
          ];
        }
      }
    }

    for (const section in groupedSections) {
      for (const item of groupedSections[section]) {
        dataSections.push(item);
      }
    }

    groupedSections = {};
  }

  return dataSections;
}

export default generateMaintenancePdfTemplate;
