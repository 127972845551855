const CheckboxHandlers = [
  { title: "Mark as Read", handler: "toggleReadHandler" },
  { title: "Mark as Unread", handler: "toggleUnreadHandler" },
  { title: "Hide/Show", handler: "hideSelectionsHandler" },
  { title: "Delete", handler: "deleteSelectionsHandler" },
  { title: "Select All", handler: "selectAllHandler" },
  { title: "Deselect", handler: "removeSelectionHandler" },
];

export default CheckboxHandlers;
