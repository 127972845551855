import { memo, useMemo } from "react";
import { HolderOutlined } from "@ant-design/icons";

const style = {
  padding: "10px 10px 10px 10px",
  marginBottom: ".5rem",
  color: "#323338",
  backgroundColor: "#F8F8FA",
  cursor: "grab", // Change cursor style
  width: "500px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
};

export const Card = memo(function Card({ id, text, index, isDragging }) {
  const opacity = isDragging ? 0.2 : 1;
  const containerStyle = useMemo(() => ({ ...style, opacity }), [opacity]);
  return (
    <div style={containerStyle}>
      <HolderOutlined /> {text}
    </div>
  );
});
