import { useState } from "react";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { Select } from "antd";

export const TaskLabelsInEdit = ({ updatedTask, setUpdatedTask }) => {
  const { programFields } = useSelector((state) => state.programFields);

  const [labelsModal, setLabelsModal] = useState(false);

  const [selectedLabels, setSelectedLabels] = useState(updatedTask?.taskLabels);

  const labels = programFields.find(
    (el) => el.fieldName === "Tasks labels"
  )?.fieldOptions;

  const addLabelsToState = () => {
    setLabelsModal((prevValue) => !prevValue);
    if (!!selectedLabels) {
      if (selectedLabels.length > 0) {
        setUpdatedTask({
          ...updatedTask,
          taskLabels:
            typeof selectedLabels[0] === "object"
              ? selectedLabels.map((label) => label.labelName)
              : selectedLabels,
        });
      } else {
        setUpdatedTask({ ...updatedTask, taskLabels: [] });
      }
    }
  };

  return (
    <div className="detailsModal__collaborators task-labels">
      Labels:
      <button
        className="detailsModal__addItem"
        onClick={() => {
          setLabelsModal((prevState) => !prevState);
        }}
      >
        Add Label
      </button>
      {labelsModal && (
        <div className="detailsModal__collaborators__modal">
          <Select
            mode="tags"
            defaultValue={selectedLabels?.map(
              (label) => label?.labelName ?? label
            )}
            onChange={setSelectedLabels}
            placeholder="Add Labels"
            options={labels?.map((label) => ({
              label: label?.labelName,
              value: label?.labelName,
            }))}
          />
          <MondayButton Icon={<TickIcon />} onClick={addLabelsToState}>
            Done
          </MondayButton>
        </div>
      )}
      <div className="detailsModal__labels">
        {updatedTask?.taskLabels?.map((label, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor:
                  labels?.find(
                    ({ labelName }) => labelName === (label?.labelName ?? label)
                  )?.color ?? "#83c340",
              }}
              className="detailsModal__label"
            >
              {label?.labelName ?? label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
