import React from "react";
import { Button, Popconfirm, Select } from "antd";

const { Option } = Select;

export default class AddonEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ?? "none",
      visible: false,
      selectOpen: false,
    };
  }

  afterGuiAttached = () => {
    this.setState({
      selectOpen: true,
    });
  };

  getValue = () => {
    return this.state.value;
  };

  handleSelectChange = (value) => {
    // if (value === "span") {
    //   this.setState({ visible: true });
    // } else {
    this.setState({ value: value });
    this.stopEditing();
    // }
  };

  destroy = () => {
    this.setState({
      selectOpen: false,
    });
  };

  handleSelectBlur = () => {
    this.setState({
      selectOpen: false,
    });
  };

  // appendOneSpan = () => {
  //   this.props.saveSidewalkShedSpanData(this.props.data.id, [
  //     {
  //       id: 1,
  //     }
  //   ]);
  // }

  stopEditing = () => {
    setTimeout(() => {
      this.props.api.stopEditing();
    }, 100);
  };

  render() {
    return (
      // <Popconfirm
      //   title={
      //     <div>
      //       <p>Is this span part of a corner or extension?</p>
      //       <div style={{ float: "right", marginTop: -8 }}>
      //         <Button
      //           size="small"
      //           onClick={() => {
      //             this.setState({ value: "span", visible: false });
      //             this.stopEditing();
      //           }}
      //         >
      //           No
      //         </Button>
      //         <Button
      //           size="small"
      //           type="primary"
      //           style={{ marginLeft: 7 }}
      //           onClick={() => {
      //             this.setState({ value: "corner", visible: false });
      //             this.stopEditing();
      //           }}
      //         >
      //           Corner
      //         </Button>
      //         <Button
      //           size="small"
      //           type="primary"
      //           style={{ marginLeft: 7 }}
      //           onClick={() => {
      //             this.setState({ value: "extension", visible: false });
      //             this.stopEditing();
      //           }}
      //         >
      //           Extension
      //         </Button>
      //       </div>
      //     </div>
      //   }
      //   visible={this.state.visible}
      //   cancelButtonProps={{ style: { display: "none" } }}
      //   okButtonProps={{ style: { display: "none" } }}
      // >
      <Select
        // open={this.state.selectOpen}
        value={this.state.value}
        style={{ width: "100%" }}
        onChange={this.handleSelectChange}
        onBlur={this.handleSelectBlur}
      >
        <Option value="none" disabled>
          Choose addon:
        </Option>
        {/* <Option value="corner">Corner</Option>
        <Option value="extension">Extension</Option> */}
        <Option value="span">Span</Option>
        <Option value="Clear Access Double Leg">Clear Access Double Leg</Option>
        <Option value="Jump">Jump</Option>
      </Select>
      // </Popconfirm>
    );
  }
}
