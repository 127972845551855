export const generateSelectedColumns = (gridColumnApi) => {
  if (!gridColumnApi) return [];

  return (
    gridColumnApi?.columnModel?.columnDefs
      ?.filter(
        (item) =>
          item?.field !== "changedValue" &&
          item?.headerName !== "Used In" &&
          item?.headerName !== "Actions" &&
          item?.headerName !== "Delete" &&
          item?.headerName !== "Gallery" &&
          item?.headerName !== "View" &&
          item?.headerName !== "No" &&
          !item?.headerName?.toLowerCase()?.includes("logs")
      )
      ?.map((item) => ({ [item.field]: item.headerName })) || []
  );
};
