import types from "../types"

const initialState = {
  application: null,
  applications: [],
  newlyCreatedApplication: null,
  isLoading: false,
  loaded: false,
  error: {},
}

const applicationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOADING_APPLICATION:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_APPLICATIONS:
      return {
        ...state,
        applications: payload,
        isLoading: false,
        loaded: true,
      }
    case types.GET_APPLICATION:
      return {
        ...state,
        application: payload,
        isLoading: false,
      }
    case types.CREATE_APPLICATION:
      return {
        ...state,
        applications: [payload, ...state.applications],
        newlyCreatedApplication: payload,
        isLoading: false,
      }
    case types.UPDATE_APPLICATION:
      return {
        ...state,
        application: payload.application,
        applications: state.applications.map((application) =>
          application.applicationId === payload.applicationId ? payload.application : application
        ),
        isLoading: false,
      }
    case types.DELETE_APPLICATION:
      return {
        ...state,
        applications: state.applications.filter(
          (application) => application.applicationId !== payload
        ),
        isLoading: false,
      }
    case types.ERROR_APPLICATION:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case types.CLEAR_APPLICATION:
      return {
        ...state,
        application: null,
      }
    case types.CLEAR_NEWLY_CREATED_APPLICATION:
      return {
        ...state,
        newlyCreatedApplication: null,
      }
    case types.RESET_APPLICATION:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default applicationsReducer
