import dayjs from "dayjs";

import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

/**
 * This function returns all dates from a start date to the end date in NY timezone
 *
 * @param {dayjs.Dayjs} startDate
 * @param {dayjs.Dayjs} endDate
 *
 * @returns {dayjs.Dayjs[]}
 */
function getDateRange(startDate, endDate) {
  const start = parseInTz(startDate).startOf("day");
  const end = parseInTz(endDate).startOf("day");

  let dateRange = [];

  let iteratedDate = start;
  while (iteratedDate.valueOf() <= end.valueOf()) {
    dateRange.push(iteratedDate);
    iteratedDate = iteratedDate.add(1, "d").startOf("day");
  }

  return dateRange;
}

export default getDateRange;
