import { Tooltip } from "antd";
import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { useContext, useMemo } from "react";

import { ScheduleType } from "../../payrollLiveTypes";
import PayrollLiveContext from "../../PayrollLiveContext";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./ScheduleSidebarItem.scss";

function ScheduleSidebarItem(props: ScheduleType) {
  const {
    projectId,
    scheduleId,
    typeOfWork,
    scheduleName,
    scheduleStatus,
    addressPosition,
    scheduleAddress,
  } = props;
  const { mapRef, filters, setFilters, employeesReport, setSchedulesInfo } =
    useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const programFields = useSelector(
    (store: StoreType) => store.programFields.programFields
  );

  function onScheduleClick() {
    let coordinateMarkers = [addressPosition];
    let matchedEmployees = [];

    for (let i = 0; i < employeesReport.length; i++) {
      const emp = employeesReport[i];
      if (emp?.projectId === projectId) {
        coordinateMarkers.push(emp.punchCoordinates);
        matchedEmployees.push(emp.employeeNumber);
      }
    }
    if (filters.schedules.includes(projectId)) {
      setSchedulesInfo(undefined);
      setFilters((prev) => ({
        ...prev,
        schedules: prev.schedules.filter((id) => id !== projectId),
        employeeNumber: [],
      }));
      mapRef.current.fitBoundsToMarkers([]);
    } else {
      setSchedulesInfo(scheduleId);
      setFilters((prev) => ({
        ...prev,
        schedules: [projectId],
        employeeNumber: matchedEmployees,
      }));
      mapRef.current.fitBoundsToMarkers(coordinateMarkers);
    }
  }

  const statusColor = useMemo(() => {
    if (programFields?.length) {
      let statusesIndex = programFields.findIndex(
        (el) => el.fieldName === "Status colors"
      );

      const scheduleStatuses =
        programFields?.[statusesIndex]?.fieldOptions?.Scheduling || [];
      const sIndex = scheduleStatuses.findIndex(
        (el: { statusName: string; statusId: string; statusColor: string }) =>
          el.statusName === props.scheduleStatus
      );
      return scheduleStatuses?.[sIndex]?.statusColor;
    }
    return "#e9c466";
  }, [props, programFields]);

  const employeesIncluded = useMemo(() => {
    return groupBy(
      employeesReport.filter((el) => el?.projectId === projectId),
      (el) => el?.crewTeamName
    );
  }, [employeesReport]);

  return (
    <section
      className={`schedule-sidebar-item ${
        darkMode ? "schedule-sidebar-item-dark" : ""
      } ${filters.schedules.includes(projectId) ? "active" : ""}`}
      onClick={onScheduleClick}
    >
      <div className="schedule-status">
        <div
          className="status-pin"
          style={{ backgroundColor: statusColor }}
        ></div>
        <span>{scheduleStatus}</span>
      </div>

      <div className="schedule-address">
        <label>{scheduleAddress}</label>
        <span>{scheduleName}</span>
      </div>

      <div className="schedule-data">
        <label>Type of work:</label>
        <span>{typeOfWork}</span>
      </div>

      <div className="schedule-data">
        <label>Employees on Job:</label>
        <div>
          {employeesReport.filter((el) => el?.projectId === projectId)
            ?.length || 0}
        </div>
      </div>

      <div className="schedule-data" style={{ flexDirection: "column" }}>
        <label>Teams Included:</label>
        <div>
          {Object.keys(employeesIncluded).flatMap((teamName) => {
            return (
              <div style={{ display: "flex", gap: 10 }}>
                <label>{teamName}</label>
                <Tooltip
                  title={employeesIncluded?.[teamName]
                    .map((el) => el.employeeName)
                    .join(", ")}
                >
                  <div>({employeesIncluded?.[teamName]?.length})</div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ScheduleSidebarItem;
