//@ts-check
import React from "react";
import { Row, Col, Popover, Button } from "antd";

import CarouselView from "../Login/Components/Carousel/CarouselView";
import { newTab } from "../../components/SidebarPages/Fleet/assets";
import core_scaffold from "../../icons/Auth_icons/core_scaffold.svg";

import "./AuthWrapper.scss";

const coreLinkInfo = <div>Click link to go to 'www.corescaffold.com'</div>;
const CORE_LINK = "https://www.corescaffold.com";

const AuthWrapper = ({ children }) => {
  const coreRedirect = () => {
    return window.open(CORE_LINK, "_blank");
  };

  return (
    <Row className="auth_wrapper">
      <Col
        className="left_side"
        xs={{ span: 24 }}
        sm={{ span: 12 }}
        lg={{ span: 12 }}
      >
        <CarouselView />
      </Col>
      <Col
        className="right_side"
        xs={{ span: 24 }}
        sm={{ span: 12 }}
        lg={{ span: 12 }}
      >
        <Popover content={coreLinkInfo} trigger="hover">
          <div className="top_corner_link">
            <img alt="" src={newTab} />
            <Button type={"text"} onClick={coreRedirect}>
              {CORE_LINK}
            </Button>
          </div>
        </Popover>
        <div className="form_comp_wrapper">
          <img alt="" src={core_scaffold} className="form-comp-coreLogo" />
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default AuthWrapper;
