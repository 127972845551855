const capitalizeFirstLetter = (sentence) => {
  if (sentence === undefined || sentence.length === 0) return "";
  // if (typeof sentence !== 'string') return '';
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export default capitalizeFirstLetter;

/**
 * @param dimension {String}
 * */
export const getDimensionHeader = (dimension) => {
  if (dimension === undefined || dimension.length === 0) return "";

  return dimension.charAt(0).toUpperCase();
};
