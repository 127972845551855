import dayjs from "dayjs";

var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

export const dateFilterOptions = {
  Today: [dayjs(), dayjs().add(1, "day")],
  Yesterday: [dayjs().subtract(1, "day"), dayjs()],
  Tomorrow: [dayjs().add(1, "day"), dayjs().add(2, "day")],
  "This Week": [dayjs().weekday(0), dayjs().weekday(7)],
  "Last Week": [dayjs().weekday(-7), dayjs().weekday(0)],
  "Next Week": [dayjs().weekday(7), dayjs().weekday(15)],
  "This Month": [
    dayjs(`${dayjs().year()}-${dayjs().month() + 1}-01`),
    dayjs(
      `${dayjs().month() < 11 ? dayjs().year() : dayjs().year() + 1}-${
        (dayjs().month() + 2) % 12 || 12
      }-01`
    ),
  ],
  "Last Month": [
    dayjs(
      `${dayjs().month() > 0 ? dayjs().year() : dayjs().year() - 1}-${
        dayjs().month() || 12
      }-01`
    ),
    dayjs(`${dayjs().year()}-${dayjs().month() + 1}-01`),
  ],
  "Next Month": [
    dayjs(`${dayjs().year()}-${dayjs().month() + 2}-01`),
    dayjs(
      `${dayjs().month() < 11 ? dayjs().year() : dayjs().year() + 1}-${
        (dayjs().month() + 3) % 12 || 12
      }-01`
    ),
  ],
  "This Year": [
    dayjs(`${dayjs().year()}-01-01`),
    dayjs(`${dayjs().year() + 1}-01-01`),
  ],
  "Last Year": [
    dayjs(`${dayjs().year() - 1}-01-01`),
    dayjs(`${dayjs().year()}-01-01`),
  ],
  "Next Year": [
    dayjs(`${dayjs().year() + 1}-01-01`),
    dayjs(`${dayjs().year() + 2}-01-01`),
  ],
  Custom: [],
};

export const dateFilterOptionsKeys = Object.keys(dateFilterOptions);

export const timeFilterOptions = {
  "Last 15 Minutes": [dayjs().subtract(15, "minute"), dayjs()],
  "Last 30 Minutes": [dayjs().subtract(30, "minute"), dayjs()],
  "Last 1 Hour": [dayjs().subtract(1, "hour"), dayjs()],
  "Last 2 Hours": [dayjs().subtract(2, "hour"), dayjs()],
  "Last 4 Hours": [dayjs().subtract(4, "hour"), dayjs()],
  "Last 8 Hours": [dayjs().subtract(8, "hour"), dayjs()],
  "Last 12 Hours": [dayjs().subtract(12, "hour"), dayjs()],
  "Last 24 Hours": [dayjs().subtract(24, "hour"), dayjs()],
  "Last 48 Hours": [dayjs().subtract(48, "hour"), dayjs()],
};
export const timeFilterOptionsKeys = Object.keys(timeFilterOptions);
