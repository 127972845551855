import React, { createContext, useContext } from "react";

const GroupsContext = createContext();

export const useGroupsContext = () => {
  return useContext(GroupsContext);
};

export const GroupsProvider = ({ children, ...props }) => {
  return (
    <GroupsContext.Provider value={{ ...props }}>
      {children}
    </GroupsContext.Provider>
  );
};
