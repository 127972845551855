import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, message } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { CommentContent } from "src/components/commonComponents/Comment";
import CommentInput from "src/components/commonComponents/Comment/components/CommentInput/CommentInput";

const NoteCommentModal = ({
  headerText = "Note addition",
  visible,
  onCancel,
  onPost,
  commentToReply,
  noteCategories,
  usersWithAccess,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [categorySelected, setCategorySelected] = useState("");

  const className = isDarkMode ? "notes-modal-dark" : "";

  const handleCategory = (category) => {
    setCategorySelected(categorySelected === category ? "" : category);
  };

  return (
    <Modal
      wrapClassName={`add-note-modal ${className} ${
        isDarkMode && "notes-modal-dark"
      }`}
      footer={null}
      width={900}
      centered
      open={visible}
      onCancel={onCancel}
      title={headerText}
    >
      {/* commentPreview is true when we are posting a reply */}
      {commentToReply ? (
        <CommentContent
          {...{
            showActions: false,
            thread: {
              ...commentToReply,
              commentId: commentToReply.noteId,
              commentContent:
                commentToReply.noteComment || commentToReply.replyContent,
            },
          }}
        />
      ) : (
        <div className="note-statuses">
          <div className="more-info-square">
            <div className="info-container">
              <InfoCircleFilled />
              <h6>Please select a status below to be able to create a note.</h6>
            </div>
          </div>
          <div className="statuses-list">
            {noteCategories?.map(({ categoryName }, index) => (
              <Button
                key={index}
                onClick={() => {
                  handleCategory(categoryName);
                }}
                style={
                  categorySelected === categoryName
                    ? {
                        background: isDarkMode ? "#f8f8fa" : "#323338",
                        color: isDarkMode ? "#1f212d" : "#F5F5F7",
                      }
                    : {}
                }
              >
                {categoryName}
              </Button>
            ))}
          </div>
        </div>
      )}

      <div className="reply_actions_section">
        <div className="more-info-square">
          <div className="info-container">
            <InfoCircleFilled />
            <h6>Please add a description below</h6>
          </div>
        </div>

        <CommentInput
          {...{
            isReply: !!commentToReply,
            placeholder: commentToReply ? "Reply here..." : "Write a note...",
            usersWithAccess,
            disablePostBtn: !commentToReply && !categorySelected,
            saveComment: (props) => {
              if (commentToReply || !!categorySelected) {
                onPost({ category: categorySelected, ...props });
                onCancel();
              } else {
                message.warning("Please select an category!");
              }
            },
          }}
        />
      </div>
    </Modal>
  );
};

export default NoteCommentModal;
