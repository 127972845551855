import React from "react";
import "./StartConversation.scss";
import { Button, Select } from "antd";
import CustomMondayButton from "../../../../CustomMondayButton/CustomMondayButton";
import { TickIcon, XIcon } from "../../../../../assets/icons";
const StartConversation = ({
  users = [],
  onClosePopUp = () => {},
  setSelectedUser = () => {},
}) => {
  return (
    <div className="startConversation">
      <div className="selectUsersToChat">
        <span>Send message to</span>
        <Select
          placeholder="Select user(s) to chat..."
          mode="multiple"
          options={users.map((user) => ({
            label: user.nameOfUser,
            value: user.cognitoUserId,
          }))}
          showSearch
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </div>
      <div className="btnsContent">
        <CustomMondayButton
          {...{
            className: "mondayButtonGrey",
            Icon: <XIcon />,
            onClick: () => onClosePopUp(false),
          }}
        >
          Cancel
        </CustomMondayButton>
        <CustomMondayButton
          {...{
            className: "mondayButtonBlue",
            Icon: <TickIcon />,
          }}
        >
          Start Conversation
        </CustomMondayButton>
      </div>
    </div>
  );
};

export default StartConversation;
