import { findTutorialSteps } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

function stepsMapHelper(title) {
  const obj = {
    "Logout All": "#logoutButton",
    "Force Reset Password": "#resetPasswordButton",
    Suspend: "#SuspendButton",
    Reactivate: "#ReactivateButton",
    "Force Logout": "#forceLogoutButton",
    "WhiteList IP Address": "#allowedIpButton",
    "Change Department": "#changeDepartmentButton",
    "Change Pin & Idle Time": "#changePinButton",
  };
  return document.querySelector(obj[title]);
}
function userSessionModalGuide(
  programFields,
  isDarkMode,
  isSuspended,
  sessions = []
) {
  const dbSteps = findTutorialSteps("userSessionModal", programFields);

  const manualSteps = [
    {
      title: "Logout All",
      description:
        "Here you can log out the user from all the devices. The user will have to log in again to access the account.",
    },
    {
      title: "Force Reset Password",
      description:
        "Here you can force reset the password of the user. This will log out the user from all the devices and the user will have to reset the password to log in again.",
    },
    {
      title: "WhiteList IP Address",
      description:
        "Here you can enable or disable the whitelist IP address feature. This feature allows you to specify a list of IP addresses that are allowed to access the user account. If the whitelist is enabled, only the IP addresses on the list will be able to access the account. If the whitelist is disabled, any IP address can access the account.",
    },
    {
      title: "Change Department",
      description:
        "Here you can change the department of the user. This will update the department of the user in the system also the user will be able to access the features of the new department.",
    },
    {
      title: "Change Pin & Idle Time",
      description:
        "Here you can change the pin and idle time of the user. The pin is used to authenticate the user and the idle time is the time after which the user will be logged out automatically if there is no activity.",
    },

    {
      title: "Suspend",
      description:
        "Here you can suspend the user. The user will not be able to log in until reactivated.",
    },
    {
      title: "Reactivate",
      description:
        "Here you can reactivate the user. The user will be able to log in again.",
    },
    {
      title: "Force Logout",
      description:
        "Here you can force logout the user or ask the user to logout after the timer u set ends. The user will have to log in again to access the account.",
    },
  ];

  let newSteps = manualSteps?.map((step) => {
    return {
      ...step,
      target: () => stepsMapHelper(step?.title),
      className: !isDarkMode ? `custom-tour-light` : `custom-tour-dark`,
    };
  });

  if (!isSuspended) {
    newSteps = newSteps?.filter((step) => step?.title !== "Reactivate");
  } else {
    newSteps = newSteps?.filter((step) => step?.title !== "Suspend");
  }
  if (sessions.length === 0) {
    newSteps = newSteps?.filter((step) => step?.title !== "Force Logout");
  }
  return newSteps;
}
export default userSessionModalGuide;
