import React from "react";
import { useSelector } from "react-redux";

import { WarningIcon } from "../../../../../assets";
import { XIcon } from "../../../Communication/assets";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { Tick } from "../../../DynamicView/components/FilterView/FilterIcons";

const SaveWarning = ({ open, setIsOpen, onCancel }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  return (
    <WarningModal
      visible={open}
      setVisible={setIsOpen}
      title="Warning Message"
      closable={true}
      className="logout-warning-modal"
      onKeyPress={(e) => onEnterPress(e)}
      darkMode={isDarkMode}
    >
      <div className="logout-modal-body">
        <span>
          <WarningIcon />
        </span>
        <p>Are you sure you want to cancel?</p>
        <div className="buttons">
          <MondayButton
            onClick={() => setIsOpen(false)}
            Icon={<XIcon />}
            className="mondayButtonRed"
          >
            No
          </MondayButton>
          <MondayButton
            onClick={onCancel}
            Icon={<Tick width={17} height={17} />}
          >
            Yes
          </MondayButton>
        </div>
      </div>
    </WarningModal>
  );
};

export default SaveWarning;
