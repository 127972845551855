import { useEffect, useState, Fragment } from "react";
import { state } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import "./List.scss";
import { AscIcon, DescIcon, ScrollIcon } from "../../src";
import htmlParser from "../../../../../utils/htmlParser";
import { Modal } from "antd";
import { XIcon } from "../../../Communication/assets/index";
import { useSelector } from "react-redux";

const List = ({
  params,
  id,
  isForIncludesExcludes,
  setIsModalDescription,
  setDescription,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { columnNames, rowData } = params;
  const [data, setData] = useState({ rowData, action: "neutral" });
  const [oldList, setOldList] = useState([]);
  const [sortedColumn, setSortedColumn] = useState();
  const [scrollable, setScrollable] = useState();
  const [bottom, setBottom] = useState(false);
  const [showNote, setShowNote] = useState(false);
  useEffect(() => {
    document.getElementById(id)?.scrollHeight &&
      setScrollable(document.getElementById(id).scrollHeight > 300);
  }, [document.getElementById(id)?.scrollHeight]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData({ rowData, action: "neutral" });
      setOldList(rowData);
    }, 100);
    return () => clearTimeout(timeout);
  }, [rowData]);

  const sortData = (columnName) => {
    if (data.action === "neutral") {
      rowData.sort((a, b) => {
        if (a[columnName].value < b[columnName].value) {
          return -1;
        }
        if (a[columnName].value < b[columnName].value) {
          return 1;
        }

        return 0;
      });
      setSortedColumn(columnName);
      setData(state({ rowData, action: "asc" }));
    }
    if (data.action === "asc") {
      rowData.sort((a, b) => {
        if (a[columnName].value > b[columnName].value) {
          return -1;
        }
        if (a[columnName].value > b[columnName].value) {
          return 1;
        }

        return 0;
      });
      setSortedColumn(columnName);
      setData(state({ rowData, action: "desc" }));
    }
    if (data.action === "desc") {
      setSortedColumn(undefined);
      setData({ rowData: oldList, action: "neutral" });
    }
  };

  function getClassName(el, val) {
    try {
      if (val[el?.fieldName].includes("granted")) return "granted";
      if (val[el?.fieldName].includes("removed")) return "removed";
    } catch {}
    return "";
  }

  const openDescriptionModal = (value) => {
    setDescription(value);
    setIsModalDescription(true);
  };

  const getBackgroundColor = (val, el, isForIncludesExcludes) => {
    if (
      isForIncludesExcludes &&
      (el.fieldName === "previousValue" || el.fieldName === "updatedValue")
    ) {
      return "#add8e6";
    } else if (typeof val["currentData"] !== "object" && el.onClick) {
      return "#a19f9f";
    } else if (val[el.fieldName]?.backgroundColor) {
      return val[el.fieldName]?.backgroundColor;
    } else if (!el.onClick) {
      return "#F5F5F7";
    } else {
      return "#ECDE7C";
    }
  };

  const getCursor = (val, el, isForIncludesExcludes) => {
    if (
      isForIncludesExcludes &&
      !val.label.includes("color") &&
      !isHexColor(val[el?.fieldName]) &&
      (el.fieldName === "previousValue" || el.fieldName === "updatedValue")
    ) {
      return "pointer";
    } else if (el.onClick) {
      return typeof val["currentData"] !== "object" ? "not-allowed" : "pointer";
    } else {
      return "unset";
    }
  };

  const onClickHandler = (val, el) => {
    let value = val[el.fieldName]?.value || val[el.fieldName];

    if (val.label.includes("color") && isHexColor(val[el?.fieldName])) {
      return;
    }

    if (el.fieldName === "previousValue" || el.fieldName === "updatedValue") {
      openDescriptionModal(value);
    } else if (typeof val.currentData === "object") {
      el?.onClick?.({ value, data: val });
    }
  };

  const isHexColor = (s) => {
    const hexColorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    return hexColorPattern.test(s);
  };

  return (
    <div className="ListBody">
      <div
        className="ListContainer"
        id={id}
        onScroll={({ target: { scrollHeight, scrollTop, clientHeight } }) => {
          if (scrollHeight - Math.floor(scrollTop) === clientHeight) {
            if (bottom !== true) {
              setBottom(true);
            }
          } else {
            if (bottom !== false) {
              setBottom(false);
            }
          }
        }}
      >
        {columnNames?.map((el, i) => {
          return (
            <div key={i} className="columnHeaderContainer">
              <div className="headerDiv">
                <span
                  className="columnHeaderTitle"
                  onClick={() => sortData(el.fieldName)}
                >
                  {el.fieldTitle}
                </span>
                {sortedColumn && sortedColumn === el.fieldName && (
                  <span className="SortIcon">
                    {data.action === "asc" ? <AscIcon /> : <DescIcon />}
                  </span>
                )}
              </div>
              {isForIncludesExcludes
                ? data.rowData?.map((val, index) => {
                    let type = val.label.split(" ");
                    let lastIndex = type.length - 1;
                    return (
                      <Fragment key={index}>
                        <div
                          className="columnContentContainer"
                          onClick={() => onClickHandler(val, el)}
                          style={{
                            backgroundColor: getBackgroundColor(
                              val,
                              el,
                              isForIncludesExcludes
                            ),
                            cursor: getCursor(val, el, isForIncludesExcludes),
                          }}
                        >
                          <div className="cellTextConteiner">
                            {val.label.includes("color") &&
                            isHexColor(val[el?.fieldName]) ? (
                              <>
                                <div
                                  className="color"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "8px",
                                    borderRadius: "50%",
                                    background: val[el?.fieldName],
                                  }}
                                ></div>
                                <span>{val[el?.fieldName]}</span>
                              </>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  color: val[el.fieldName]?.backgroundColor
                                    ? "#ffffff"
                                    : isDarkMode
                                    ? "#fff"
                                    : "#323338",
                                }}
                                className={`textStyle ${getClassName(el, val)}`}
                              >
                                {isForIncludesExcludes
                                  ? el.fieldName === "previousValue" ||
                                    el.fieldName === "updatedValue"
                                    ? val[el?.fieldName]
                                    : val[el?.fieldName]
                                  : typeof val[el.fieldName]?.value === "object"
                                  ? ""
                                  : val[el.fieldName]?.value ||
                                    typeof val[el.fieldName] === "object"
                                  ? ""
                                  : val[el?.fieldName]}
                              </div>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                : data.rowData?.map((val) => {
                    return (
                      <>
                        <div
                          className="columnContentContainer"
                          onClick={() => {
                            let value =
                              val[el.fieldName]?.value || val[el.fieldName];
                            return typeof val["currentData"] === "object" &&
                              Object.keys(val["currentData"]).length
                              ? el?.onClick({ value, data: val })
                              : () => {};
                          }}
                          style={{
                            backgroundColor:
                              typeof val["currentData"] !== "object" &&
                              el?.onClick
                                ? "#a19f9f"
                                : val[el.fieldName]?.backgroundColor
                                ? val[el.fieldName]?.backgroundColor
                                : !el.onClick
                                ? "#F5F5F7"
                                : Object.keys(val["currentData"]).length === 0
                                ? "#a19f9f"
                                : "#ECDE7C",
                            cursor: el?.onClick
                              ? typeof val["currentData"] !== "object"
                                ? "not-allowed"
                                : Object.keys(val["currentData"]).length === 0
                                ? "unset"
                                : "pointer"
                              : "unset",
                          }}
                        >
                          <div className="cellTextConteiner">
                            {(val && val?.label?.includes("color")) ||
                            isHexColor(val[el?.fieldName]) ? (
                              <>
                                <div
                                  className="color"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "8px",
                                    borderRadius: "50%",
                                    background: val[el?.fieldName],
                                  }}
                                ></div>
                                <span>{val[el?.fieldName]}</span>
                              </>
                            ) : (
                              <div
                                style={{
                                  color: val[el.fieldName]?.backgroundColor
                                    ? "#ffffff"
                                    : isDarkMode
                                    ? typeof val["currentData"] === "object" &&
                                      el?.onClick
                                      ? "#2d61f0"
                                      : "#fff"
                                    : "#323338",
                                  cursor:
                                    typeof val[el?.fieldName] === "string" &&
                                    /<\/?[a-z][\s\S]*>/i.test(
                                      val[el?.fieldName]
                                    )
                                      ? "pointer"
                                      : "unset",
                                }}
                                className={`textStyle ${getClassName(el, val)}`}
                                onClick={() => {
                                  // if val[el?.fieldName] is HTML than on click can open the modal to show the HTML
                                  typeof val[el?.fieldName] === "string" &&
                                  /<\/?[a-z][\s\S]*>/i.test(val[el?.fieldName])
                                    ? setShowNote(
                                        htmlParser(val[el?.fieldName])
                                      )
                                    : {};
                                }}
                              >
                                {typeof val[el.fieldName]?.value === "object"
                                  ? ""
                                  : val[el.fieldName]?.value ||
                                    typeof val[el.fieldName] === "object"
                                  ? ""
                                  : // this validation is made to show images from html in Logs
                                  typeof val[el?.fieldName] === "string" &&
                                    /<\/?[a-z][\s\S]*>/i.test(
                                      val[el?.fieldName]
                                    )
                                  ? htmlParser(val[el?.fieldName])
                                  : val[el?.fieldName]}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
            </div>
          );
        })}
      </div>
      {scrollable && (
        <span
          className={!bottom ? "scrollBottomContainer" : "scrollTopContainer"}
        >
          <ScrollIcon />
        </span>
      )}
      {/* Added a modal to show full view of the changes that happend in notes */}
      <Modal
        title="Note View"
        open={!!showNote}
        setVisible={setShowNote}
        onCancel={() => setShowNote(false)}
        closable={true}
        closeIcon={<XIcon />}
        footer={false}
        className="showNotesModal"
      >
        <div className="noteContainer">{showNote}</div>
      </Modal>
    </div>
  );
};
export default List;
