import { InputNumber } from "antd";
import { useEffect, useState } from "react";
import { DeleteIcon } from "../../../../../Roles/src";
import "./DimensionPricer.scss";
import { generateRedColorByAmount } from "../../../../utils/colorGenerator";

const DimensionPricer = ({
  showHeatMap,
  min,
  max,
  value: proppedValue = {},
  onChange,
  onDelete,
  isDuplicate = false,
  forEditing = true,
  isLastChild,
  disabled,
}) => {
  const [hoverMode, setHoverMode] = useState(false);
  const [value, setValue] = useState(proppedValue);

  const { length, price } = value;

  //updates the state on input value change
  const onValueChange = (key) => (inputValue) =>
    setValue({ ...value, [key]: inputValue });

  //updates the parents state on input blur
  const onBlur = () => onChange(value);

  //updates the state when the proppedValue changes
  useEffect(() => {
    setValue(proppedValue);
  }, [proppedValue]);

  return (
    <div
      className={`dimensionPricer ${
        isLastChild ? "afterLessDimensionPricer" : ""
      }`.trim()}
      onMouseEnter={() => setHoverMode(true)}
      onMouseLeave={() => setHoverMode(false)}
    >
      {hoverMode && !disabled && !!forEditing && (
        <div className="dimensionPricerDeleteIcon" onClick={onDelete}>
          <DeleteIcon />
        </div>
      )}
      <div className="dimensionInputWrapper">
        <InputNumber
          className="lengthInput"
          value={length}
          type="number"
          onChange={onValueChange("length")}
          onBlur={onBlur}
          controls={false}
          min={0}
          disabled={disabled || !forEditing}
          // {...(!length ? { style: { borderColor: "#FF0000" } } : {})}
        />
      </div>
      <div className="inputsDivider"></div>
      <div className="dimensionInputWrapper">
        <InputNumber
          style={
            !!showHeatMap
              ? { backgroundColor: generateRedColorByAmount(min, max, price) }
              : {}
          }
          className="priceInput"
          type={"number"}
          value={price}
          onChange={onValueChange("price")}
          onBlur={onBlur}
          controls={false}
          min={0}
          disabled={disabled || !forEditing}
          // {...(!price ? { style: { borderColor: "#FF0000" } } : {})}
        />
      </div>

      {isDuplicate && <div className="duplicateLabel">Duplicate</div>}
    </div>
  );
};

export default DimensionPricer;
