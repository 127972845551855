import React from "react";
import { Tooltip } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { useRedux } from "../../../hooks";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { useCreateBillContext } from "../context/BillModalProvider";

const RemoveItem = ({ value }) => {
  const [isWritable] = useRedux("billIsWritable");

  const { billData } = useInitialBillContext();
  const { setRowData } = useCreateBillContext();

  const onRemoveItem = () => {
    setRowData((prev) => prev.filter((row) => row.itemId !== value));
  };

  return (
    <Tooltip title="Delete Row">
      <button
        data-testid="remove-item-button"
        style={{
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          border: "0",
          background: "inherit",
        }}
        disabled={!isWritable && billData}
        onClick={onRemoveItem}
      >
        <DeleteFilled />
      </button>
    </Tooltip>
  );
};

export default RemoveItem;
