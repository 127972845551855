import { Checkbox, Tooltip } from "antd";

import {
  isUndefined,
  onChargeCheckBoxChange,
  onChargeRentalCheckBoxChange,
} from "../ChargeCheckBox/utils";
import { useRedux } from "../../../../../../../../../hooks";

import "./TaxCheckbox.scss";

const TaxCheckbox = ({
  data,
  type,
  initialService,
  setChargeToSave,
  selectedService,
  selectedElevation,
  selectedElevationId,
}) => {
  const [chargeItemsModal] = useRedux("chargeItemsModal");
  const [isWritable] = useRedux("chargesIsWritable");
  const { categoryFrom } = chargeItemsModal;
  const isDisabled = !data?.isTaxable;

  const handleCheckBoxChange = () => {
    if (categoryFrom === "Estimation")
      onChargeCheckBoxChange(
        setChargeToSave,
        selectedService,
        initialService,
        selectedElevation,
        selectedElevationId,
        type,
        data,
        true
      );
    else if (categoryFrom === "Rental")
      onChargeRentalCheckBoxChange(
        setChargeToSave,
        selectedService,
        initialService,
        selectedElevation,
        selectedElevationId,
        type,
        data,
        true
      );
  };

  return (
    <div className="new-tax-charge-checkbox-item">
      <Tooltip title={isDisabled && "This item is tax-free."}>
        <Checkbox
          data-testid="tax-checkbox"
          checked={data.taxAmount > 0}
          className={isDisabled ? "ant-checkbox-disabled" : ""}
          disabled={isDisabled || isUndefined(isWritable)}
          onChange={handleCheckBoxChange}
        />
      </Tooltip>
    </div>
  );
};

export default TaxCheckbox;
