import "./ProgressComponent.scss";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";
import ProgressStatus from "./components/ProgressStatus";
import MondayButton from "../MondayButton/MondayButton";
import { XIcon } from "../../SidebarPages/Communication/assets";
import { useEffect } from "react";

const progressStatusOrder = {
  finished: 0,
  executing: 1,
  notStarted: 2,
  hasError: 3,
};
const possibleProgressKeys = [
  "updatingRecord",
  "creatingTask",
  "updatingEvent",
  "startingAutomation",
  "sendingNotification",
  "sendingEmail",
];

const possibleStatuses = ["notStarted", "executing", "finished", "hasError"];

const ProgressComponent = ({
  categoryName,
  actionType,
  visibleCreationProgress,
  creationProgresses = [],
  closeModal = () => {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  useEffect(() => {
    visibleCreationProgress &&
      !creationProgresses.length &&
      closeModal(visibleCreationProgress);

    const closeModalTimeout = setTimeout(() => {
      closeModal(visibleCreationProgress);
    }, 45000);

    return () => {
      clearTimeout(closeModalTimeout);
    };
  }, []);

  const handleCloseModal = () => {
    closeModal(visibleCreationProgress);
  };

  const increaseValue = Math.floor(Math.random() * (51 - 10)) + 10; //random num from 10 - 50
  const filteredProgresses = creationProgresses.filter(({ hide }) => !hide);

  return (
    <Modal
      {...{
        open: visibleCreationProgress,
        title: `Action Progresses for ${categoryName}`,
        className: `records-creation-progress-wrapper ${
          isDarkMode && "records-creation-progress-wrapper-dark"
        }`,
        centered: true,
        width: "60%",
        onCancel: handleCloseModal,
        closeIcon: <XIcon />,
        footer: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MondayButton
              data-testid="cancel-button"
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={handleCloseModal}
            >
              Cancel
            </MondayButton>
          </div>
        ),
      }}
    >
      <div
        className={`records-creation-progress ${
          isDarkMode && "records-creation-progress-dark"
        }`}
      >
        <div className="records-creation-progress-header">
          <div className="header-left">
            <InfoCircleFilled style={{ fontSize: 22, color: "#1264A3" }} />
            <p>
              Here you can see progress for each of records that are being
              created.
            </p>
          </div>
          <div className="header-right"></div>
        </div>
        <div className="records-creation-progress-content">
          <div className="progress-statuses">
            {filteredProgresses
              .sort(
                (a, b) =>
                  progressStatusOrder[a.progressStatus] -
                  progressStatusOrder[b.progressStatus]
              )
              .map((progress, currIndex) => {
                return (
                  <ProgressStatus
                    key={currIndex + `${progress.progressStatus}`}
                    {...{
                      categoryName,
                      actionType,
                      progress,
                      increaseValue,
                      creationProgresses: filteredProgresses,
                      handleCloseModal,
                      datas: visibleCreationProgress,
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProgressComponent;
