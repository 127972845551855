import React from "react";
import { Transfer } from "antd";
const targetKeys = ["2", "3"];
const AntdTransfer = ({
  dataSource,
  showSearch,
  filterOption,
  onSearch,
  render,
  targetKeys,
  setTargetKeys,
  onChange: changeHandler = () => {},
}) => {
  const onChange = (newTargetKeys) => setTargetKeys(newTargetKeys);
  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    console.log("targetKeys:", nextTargetKeys);
    console.log("direction:", direction);
    console.log("moveKeys:", moveKeys);

    // Update the targetKeys state
    // You can perform custom logic here based on the user's selection
  };

  return (
    // <Transfer
    //   dataSource={dataSource}
    //   targetKeys={targetKeys}
    //   showSearch={showSearch}
    //   filterOption={filterOption}
    //   onChange={(e) => {
    //     changeHandler();
    //     onChange(e);
    //   }}
    //   onSearch={onSearch}
    //   element={render}
    // />
    <Transfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      showSearch={showSearch}
      filterOption={filterOption}
      onSearch={onSearch}
      // selectedKeys={selectedKeys}
      onChange={(params) => {
        changeHandler(params);
        onChange(params);
      }}
      render={(item) => item.title}
    />
  );
};

export default AntdTransfer;
