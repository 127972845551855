import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { StoreType } from "../../types";
import CollapseInfo from "../CollapseInfo/CollapseInfo";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { InfoIconBlue } from "src/components/Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

import "./CollapseInfoModal.scss";

type SectionType = {
  title: string;
  items: any[];
};

type CollapseModalProps = {
  title: string;
  open: boolean;
  sections: SectionType[];
  onCancel: () => any;
  className?: string;
};

function CollapseInfoModal(props: CollapseModalProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const [filter, setFilter] = useState("");

  const { onCancel, open, title, className = "", sections = [] } = props;

  return (
    <Modal
      {...{
        open,
        title,
        onCancel,
        footer: null,
        centered: true,
        closeIcon: <XIcon />,
        className: `collapse-info-modal-container ${
          isDarkMode ? "collapse-modal-dark" : ""
        } ${className}`,
      }}
    >
      <div className="info-container">
        <InfoIconBlue />
        <div className="info-text">
          Here you can see a list of the overdue work orders and all the
          inspections whose defects are not assigned to any work order
        </div>
      </div>
      {sections?.length ? (
        <>
          {/** @ts-ignore */}
          <InputComponent
            {...{
              placeholder: "Filter...",
              noFormItem: true,
              label: null,
              allowClear: true,
              style: { marginBottom: "10px" },
              prefix: <SearchOutlined />,
              onChange(e) {
                setFilter(e.target.value);
              },
              onClear() {
                setFilter("");
              },
            }}
          />
        </>
      ) : null}
      {!sections?.length && (
        <div className="no-warning-container">There are no warnings!</div>
      )}
      {sections.flatMap((section, index) => {
        if (
          !section.title
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase())
        ) {
          return [];
        }

        return (
          <CollapseInfo
            key={`collapse-info-${index}`}
            items={section.items}
            title={section.title}
          />
        );
      })}
    </Modal>
  );
}

export default CollapseInfoModal;
