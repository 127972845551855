import _ from "lodash";
import { DefectType } from "../types";

interface Dictionary<T> {
  [key: string]: T;
}

type WoDep = Dictionary<Dictionary<string[]>>;
type InspDep = Dictionary<string[]>;

function findInspectionRelationships(defects: DefectType[]) {
  /**
   * This contains all the inspections the work order references
   */
  const workOrderDependencies: WoDep = {};

  /**
   * This contains all the defects that don't have a work order
   */
  const inspectionDependencies: InspDep = {};

  const [matched, unmatched]: [DefectType[], DefectType[]] = _.partition(
    defects,
    (d: DefectType) => !!d?.workOrderId
  );

  const groupedMatched: Dictionary<DefectType[]> = _.groupBy(
    matched,
    "workOrderId"
  );

  const groupedUnmatched: Dictionary<DefectType[]> = _.groupBy(
    unmatched,
    "inspectionId"
  );

  for (const woId in groupedMatched) {
    const inspectionGroup: Dictionary<DefectType[]> = _.groupBy(
      groupedMatched[woId],
      "inspectionId"
    );

    for (const inspId in inspectionGroup) {
      const uniqArr = inspectionGroup[inspId].map((d) => d.defectId);
      workOrderDependencies[woId] = {
        ...(workOrderDependencies[woId] || {}),
        [inspId]: uniqArr,
      };
    }
  }

  for (const inspId in groupedUnmatched) {
    const uniqArr = groupedUnmatched[inspId].map((d) => d.defectId);
    inspectionDependencies[inspId] = uniqArr;
  }

  return {
    workOrderDependencies,
    inspectionDependencies,
  };
}

export default findInspectionRelationships;
