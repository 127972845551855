// import { useDispatchContext, useStateContext } from "../../../../../../../../Context/Context"
import "./ApplyRentalCheckBox.scss";
import {
  getDisabledTooltipContent,
  checkBoxValueHandler,
  indeterminateHandler,
  functionsAdapter,
  selectedPliExtractor,
} from "./applyRentalCheckBoxFunctions";
import { message } from "antd";
import { useParams } from "react-router-dom";
import { Checkbox, Tooltip } from "antd";
import { useRedux } from "../../../../../../../../../../../../hooks";

const ApplyRentalCheckBox = ({
  pliData,
  elevationData,
  params = {},
  whichOne,
}) => {
  // const { fakeRentals, unchangingFakeRentals, isWritable, selectedService } = useStateContext()

  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux(
    "unchangingFakeRentals"
  );
  const [selectedService] = useRedux("rentalsSelectedService");
  const [isWritable] = useRedux("rentalsIsWritable");

  // const dispatchContext = useDispatchContext()
  const { isLoading } = useParams();

  const selectedPliWeekData = selectedPliExtractor({
    object: whichOne === "pli" ? fakeRentals : unchangingFakeRentals,
    thisElevation: elevationData?.elevationId,
    thisPli: pliData?.id,
    selectedService,
  });

  //handles the function that will be triggered on checkbox press
  const applyThisRow = (checked) =>
    isWritable &&
    functionsAdapter({
      fakeRentals: whichOne === "pli" ? fakeRentals : unchangingFakeRentals,
      selectedService,
      // dispatchContext,
      setFakeRentals,
      setUnchangingFakeRentals,
      elevationData,
      pliData,
      checked,
      params,
    })[whichOne]();

  //here we get the value of the checkbox
  const checkBoxValue = checkBoxValueHandler({
    selectedPliWeekData,
    thisWeek: params?.thisWeek?.week,
    thisDay: params?.thisDay?.id,
  })[whichOne];

  //here we get the value of intermediate
  const indeterminateValue = indeterminateHandler({
    thisWeek: params?.thisWeek?.week,
    selectedPliWeekData,
  })[whichOne];

  const hasChargeApplied = getDisabledTooltipContent({
    thisWeek: params?.thisWeek?.week,
    thisDay: params?.thisDay?.id,
    selectedPliWeekData,
    isWritable,
  })[whichOne];

  return (
    <Tooltip title={hasChargeApplied}>
      {/* <Checkbox
        disabled={isLoading || !!hasChargeApplied}
        checked={checkBoxValue}
        indeterminate={indeterminateValue}
        onChange={(checked) => applyThisRow(checked)}
      /> */}
      <Checkbox
        onClick={() => !isWritable && message.error("Please enable Edit Mode!")}
        className={
          !isWritable || !!hasChargeApplied || isLoading
            ? "checkBoxNotClickable"
            : ""
        }
        checked={checkBoxValue}
        indeterminate={indeterminateValue}
        //disabled={!!isLoading || !isWritable || charged}
        onChange={(checked) => isWritable && applyThisRow(checked)}
      />
    </Tooltip>
  );
};

export default ApplyRentalCheckBox;
