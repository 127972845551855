import { useEffect, useState } from "react";
import { Checkbox, Input, Form, Modal } from "antd";

import { InspectionModal } from "../../../../XComponents";
import { CloseOutlined } from "@ant-design/icons";
import "./DispatchModal.scss";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { SearchIcon, XIcon } from "../../../../Communication/assets";
import { useSelector } from "react-redux";
import { InfoIcon } from "../../../assets";
import { ReportCheck } from "../../../../Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

const { Search } = Input;

const DispatchModal = ({
  visible,
  setVisible,
  inspectors,
  form,
  currentProject,
  onStatusChange,
  refreshTable,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [searchValue, setSearchValue] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const filteredOptions = inspectors?.filter((item) =>
    item?.toUpperCase()?.includes(searchValue?.toUpperCase())
  );

  function onDispatch() {
    onStatusChange({
      projectId: currentProject?.projectId,
      status: "Dispatched",
      inspectionId: currentProject?.inspectionId,
    });
    setVisible(false);
    refreshTable();
  }

  function onCancel() {
    setVisible(false);
  }

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? filteredOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  function onSearch(e) {
    const { value = "" } = e?.target;

    const checkedToSet = [...(checkedList || [])]?.filter((item) =>
      filteredOptions?.includes(item)
    );

    setSearchValue(value);
    setCheckedList(checkedToSet);
    setIndeterminate(
      !!checkedToSet.length && checkedToSet.length < filteredOptions.length
    );
  }

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      className={`inspectionDispatchModal1 ${
        isDarkMode && "inspectionDispatchModalDark"
      }`}
      destroyOnClose
      title="Dispatch"
      centered
      closeIcon={<XIcon />}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={onCancel}
        >
          Close
        </MondayButton>,
        <MondayButton
          Icon={<TickIcon width={17} height={17} />}
          onClick={onDispatch}
        >
          Dispatch
        </MondayButton>,
      ]}
    >
      <div className="option-controller-container">
        <Form.Item name="inspectorsSearch">
          <Input
            className="search-input"
            prefix={<SearchIcon />}
            placeholder="Search"
            onChange={onSearch}
          />
        </Form.Item>
        <div className="selected-option-container">
          {checkedList?.map((item, index) => (
            <div className="selected-option" key={index}>
              {item}
              <XIcon
                onClick={() =>
                  setCheckedList(
                    [...(checkedList || [])]?.filter(
                      (el) => !el?.includes(item)
                    )
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className="options-checkbox-container">
        <div className="select-all-checkbox">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={
              checkAll && checkedList?.length === filteredOptions?.length
            }
          >
            Select All
          </Checkbox>
        </div>
        <div className="checkbox-list-container">
          {filteredOptions?.map((item, index) => (
            <ReportCheck
              {...{
                id: `${item}-${index}`,
                label: item,
                defaultChecked: checkedList?.includes(item),
                onChange: () => {
                  setCheckedList(
                    checkedList?.includes(item)
                      ? checkedList?.filter((el) => el !== item)
                      : [...checkedList, item]
                  );
                },
                disabled: false,
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default DispatchModal;
