import { getRandomColor } from "../../../../utils";
import { uniq } from "lodash";
import {
  getBoroughSum,
  repeatedElementSum,
} from "../../../NewDashboardFunctions";

export const scheduling = (programFields, updatedDynamicStates, IdHandler) => {
  return {
    typeOfWork: () => ({
      title: "Type Of Work",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Scheduling",
        field: "typeOfWork",
        labels: programFields
          ?.find((a) => a?.fieldName == "Scheduling Types")
          ?.fieldOptions?.map((a) => a.typeName),
        datasets: [
          {
            label: "Scheduling",
            data: programFields
              ?.find((a) => a?.fieldName == "Scheduling Types")
              ?.fieldOptions?.map((a) => a.typeName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) => a.typeOfWork?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),

            backgroundColor: programFields
              ?.find((a) => a?.fieldName == "Scheduling Types")
              ?.fieldOptions?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //typeofWork,

    projectExecutive: () => ({
      title: "Schedule Project Executive",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Scheduling",
        field: "projectExecutive",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (a) => a.projectExecutive
          )
        ),
        datasets: [
          {
            label: "Scheduling",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectExecutive"
              )
            ),
            backgroundColor: (updatedDynamicStates?.[IdHandler] || [])?.map(
              (a) => getRandomColor()
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectManager: () => ({
      title: "Schedule Project Manager",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Scheduling",
        field: "projectManager",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])
            ?.map((a) => a.projectManagers)
            .flat()
        ),
        datasets: [
          {
            label: "Scheduling",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectManagers"
              )
            ),
            backgroundColor: (updatedDynamicStates?.[IdHandler] || [])?.map(
              (a) => getRandomColor()
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
    scheduleAddress: () => ({
      title: "Schedule Address",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Scheduling",
        field: "scheduleAddress",
        labels: Object.keys(
          getBoroughSum(
            updatedDynamicStates?.[IdHandler] || [],
            "scheduleAddress"
          )
        ),
        datasets: [
          {
            label: "Scheduling",
            data: Object.values(
              getBoroughSum(
                updatedDynamicStates?.[IdHandler] || [],
                "scheduleAddress"
              )
            ),
            backgroundColor: Object.keys(
              getBoroughSum(
                updatedDynamicStates?.[IdHandler] || [],
                "scheduleAddress"
              )
            ).map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  }; //schaduling
};
