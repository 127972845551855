import { Button } from "antd";

import { formatCurrency } from "../../../utils";
import { InputComponent } from "../../../Fleet/components";
import { TrashIcon } from "../../../Communication/assets";

export const columns = ({
  onRemoveItem,
  onRateChange,
  onHoursChanged,
  onTotalChange,
  form,
  namesToUse,
  addOptionHandler,
}) => [
  {
    title: "Name",
    dataIndex: "laborName",
    render(value, record) {
      return (
        <>
          {!isNaN(record?.index) ? (
            <InputComponent
              {...{
                label: "",
                type: "predictAutocomplete",
                required: true,
                formItemName: `NAME:${record?.index}`,
                style: { width: "200px" },
                defaultValue: value,
                onChange(e) {
                  form.setFieldValue(`NAME:${record?.index}`, e);
                },
                onSelect(e) {
                  form.setFieldValue(`NAME:${record?.index}`, e);
                },
                addNewClick(e) {
                  addOptionHandler(e);
                },
                customOptions: namesToUse.map((e) => ({
                  label: e,
                  value: e,
                })),
              }}
            />
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    render(value, record) {
      return (
        <>
          {!isNaN(record?.index) ? (
            <InputComponent
              {...{
                label: "",
                type: "textarea",
                initialValue: value,
                required: false,
                formItemName: `DESCRIPTION:${record?.index}`,
              }}
            />
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    title: "Rate",
    dataIndex: "rate",
    render(value, record) {
      return (
        <>
          {!isNaN(record?.index) ? (
            <InputComponent
              {...{
                type: "input",
                typeNumber: true,
                required: true,
                className: "gridInput",
                initialValue: parseFloat(value)?.toFixed(2),
                formItemName: `RATE:${record?.index}`,
                placeholder: "0.00",
                addonAfter: "$",
                onFocus() {
                  form.setFieldValue(`RATE:${record?.index}`, "");
                },
                onBlur(e) {
                  form.setFieldValue(
                    `RATE:${record?.index}`,
                    parseFloat(value)?.toFixed(2)
                  );
                },
                onChange(val) {
                  onRateChange(record?.index, val);
                },
              }}
            />
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    title: "Hours Worked",
    dataIndex: "hoursWorked",
    render(value, record) {
      return (
        <>
          {!isNaN(record?.index) ? (
            <InputComponent
              {...{
                type: "input",
                typeNumber: true,
                required: true,
                className: "gridInput",
                initialValue: parseFloat(value),
                formItemName: `HOURS:${record?.index}`,
                addonAfter: "h",
                placeholder: "0",
                onFocus() {
                  form.setFieldValue(`HOURS:${record?.index}`, "");
                },
                onBlur(e) {
                  form.setFieldValue(
                    `HOURS:${record?.index}`,
                    parseFloat(value)?.toFixed(2)
                  );
                },
                onChange(val) {
                  onHoursChanged(record?.index, val);
                },
              }}
            />
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    render(value, record) {
      if (!isNaN(record?.index)) {
        return (
          <InputComponent
            {...{
              type: "input",
              typeNumber: true,
              required: true,
              className: "totalInput",
              initialValue: parseFloat(value)?.toFixed(2),
              formItemName: `TOTAL:${record?.index}`,
              style: { width: "120px" },
              addonAfter: "$",
              placeholder: "0.00",
              onFocus() {
                form.setFieldValue(`TOTAL:${record?.index}`, "");
              },
              onBlur(e) {
                form.setFieldValue(
                  `TOTAL:${record?.index}`,
                  parseFloat(value)?.toFixed(2)
                );
              },
              onChange(val) {
                onTotalChange(record?.index, val);
              },
            }}
          />
        );
      } else {
        return (
          <strong style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(value)}
          </strong>
        );
      }
    },
  },
  {
    title: "",
    width: 60,
    render(_, record) {
      if (!isNaN(record?.index)) {
        return (
          <div className="actionButtons">
            <Button
              style={{
                borderRadius: "5px",
                display: "grid",
                placeContent: "center",
              }}
              onClick={() => {
                onRemoveItem(record?.index);
              }}
            >
              <TrashIcon />
            </Button>
          </div>
        );
      }
    },
  },
];
