import { debounce, uniqBy } from "lodash";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import IndividualLoader from "../../../../../IndividualLoader";
import {
  assignedToObject,
  teamSelect,
  projectManagerSelect,
  REQUEST_API_KEYS,
} from "..";

import sortFields from "../sortFields";

/**
 *
 * @param {Array} teams - Array of teams
 * @param {Array} inputFields - Array of inputFields for this request type
 * @param {Array} projects - Array of projects
 * @param {Array} accounts - Array of accounts
 * @param {Boolean} isDarkMode - Dark mode flag
 * @param {Array} projectManagers - Array of project managers
 * @param {Object} selectedMainField - Selected main field
 * @param {Object} currentPageKey - Current page key which is a reference
 * @param {Array} projectExecutives - Array of project executives
 * @param {Boolean} globalDisabled - Global disabled flag
 * @param {Object} userConfiguration - User configuration object
 * @param {Boolean} recordsLoading - Records loading flag
 * @param {Function} onTeamSelect - Team select function
 * @param {Boolean} allFieldsDisabled - All fields disabled flag
 * @param {Function} setRecordsLoading - Set records loading function
 * @param {Function} onMainFieldSelect - Main field select function
 * @param {Function} dispatchRequestData - Dispatch request data function
 * @returns
 */
const changeOrderRequestFields = ({
  teams = [],
  inputFields = [],
  projects = [],
  accounts = [],
  isDarkMode = false,
  projectManagers = [],
  selectedMainField = {},
  currentPageKey = null,
  projectExecutives = [],
  globalDisabled = false,
  userConfiguration = {},
  recordsLoading = false,
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  setRecordsLoading = () => {},
  onMainFieldSelect = () => {},
  dispatchRequestData = () => {},
}) => {
  const onSearch = debounce(
    async (searchValue) =>
      await searchFetchInput({
        table: "projects",
        idKey: "projectId",
        arrayKey: "projects",
        searchKey: "projectName",
        searchValue,
        keysToInclude: REQUEST_API_KEYS["Change Order"].projects,
        setLoading: setRecordsLoading,
        setRecords: (res) => {
          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: {
              projects: userAccessRecords(
                userConfiguration,
                uniqBy([...(res || []), ...(projects || [])], "projectId")
              ),
            },
          });
        },
        userConfiguration,
        setCurrentPageKey: (val) => {
          currentPageKey.current = val;
        },
        customSetRecords: true,
      }),
    500
  );

  const onPopupScroll = async (e) =>
    await handlePopupScroll({
      e,
      table: "projects",
      idKey: "projectId",
      currentPageKey: currentPageKey.current.projects,
      keysToInclude: REQUEST_API_KEYS["Change Order"].projects,
      setCurrentPageKey: (val) => {
        currentPageKey.current = { projects: val };
      },
      setLoading: setRecordsLoading,
      setRecords: (res) =>
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: {
            projects: userAccessRecords(
              userConfiguration,
              uniqBy([...(projects || []), ...(res || [])], "projectId")
            ),
          },
        }),
      userConfiguration,
      customSetRecords: true,
    });

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  // const reducedAccounts = accounts?.reduce(
  //   (acc, curr) => ({
  //     ...acc,
  //     [curr.accountId]: curr.accountName,
  //   }),
  //   {}
  // );

  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "jobsiteAddress") {
      delete field.options;

      Object.assign(field, {
        customOptions: (projects || [])
          ?.map((el) => ({
            label: `${el?.projectName} ― Client : ${el?.accountName || "N/A"}`,
            value: el?.projectId,
            recordName: el?.projectName,
            accountName: el?.accountName,
          }))
          ?.filter(Boolean),
        style: { minWidth: 300 },
        showSearch: true,
        allowClear: true,

        onSelect: (val, opt) => onMainFieldSelect({ val, opt }),

        onClear: () => onMainFieldSelect({ val: null, opt: null, clear: true }),

        onSearch,

        onPopupScroll,

        dropdownRender,
      });
    }

    if (formItemName == "projectExecutive") {
      delete field.options;

      Object.assign(field, {
        options: projectExecutives?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
        disabled: !!selectedMainField?.projectExecutive,
      });
    }

    if (formItemName === "assignedTo") {
      Object.assign(field, {
        ...assignedToObject({
          title: "Estimations",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "projectManager") {
      delete field.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "team") {
      delete field.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "client") {
      delete field.options;

      Object.assign(field, {
        disabled: !!selectedMainField?.accountId,
        customOptions: (accounts || [])?.map((account) => ({
          value: account?.accountId,
          label: account?.accountName,
        })),
        disabled: true,
      });
    }

    if (field?.disabled === false) {
      field.disabled = !!allFieldsDisabled || globalDisabled;
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default changeOrderRequestFields;
