import React, { useState } from "react";
import { Spin, Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const statusesLoader = <LoadingOutlined style={{ fontSize: 75 }} spin />;
const size = "default";
const buttonShape = "default";
const avatarShape = "circle";

export default function IndividualLoader() {
  return <Spin indicator={antIcon} />;
}

export function SteperLoader() {
  return (
    <div className="blured_loader">
      <Skeleton.Button
        style={{ width: 170, height: 40 }}
        active={true}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Avatar
        style={{ width: 40, height: 40 }}
        active={true}
        size={size}
        shape={avatarShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 40 }}
        active={true}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Avatar
        style={{ width: 40, height: 40 }}
        active={true}
        size={size}
        shape={avatarShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 40 }}
        active={true}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Avatar
        style={{ width: 40, height: 40 }}
        active={true}
        size={size}
        shape={avatarShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 40 }}
        active={true}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Avatar
        style={{ width: 40, height: 40 }}
        active={true}
        size={size}
        shape={avatarShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 40 }}
        active={true}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Avatar
        style={{ width: 40, height: 40 }}
        active={true}
        size={size}
        shape={avatarShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 40 }}
        active={true}
        size={size}
        shape={buttonShape}
      />
    </div>
  );
}

export function StatusesLoader() {
  const [active, setActive] = useState(true);

  return (
    <div className="statuses_blured_loader">
      <Skeleton.Button
        style={{ width: 170, height: 50 }}
        active={active}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 50 }}
        active={active}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 50 }}
        active={active}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 50 }}
        active={active}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 50 }}
        active={active}
        size={size}
        shape={buttonShape}
      />
      <Skeleton.Button
        style={{ width: 170, height: 50 }}
        active={active}
        size={size}
        shape={buttonShape}
      />
    </div>
  );
}

export function NotesLoader() {
  return (
    <Skeleton.Button
      style={{ width: 115, height: 28, marginRight: 70 }}
      active={true}
      size={size}
      shape={buttonShape}
    />
  );
}
