import React, { useState } from "react";
import "./NewConversation.scss";
import { NewConversationIcon } from "../../../assets/icons";
import ConversationContent from "./ConversationContent/ConversationContent";
import { Popover } from "antd";
import StartConversation from "./ConversationContent/StartConversation/StartConversation";
import { HoverButton } from "../../../../commonComponents";
const NewConversation = (props) => {
  const {
    users = [],
    selectedUser = {},
    setSelectedUser = () => {},
    socket = {},
    roomId,
    messageList,
    setMessageList,
  } = props;
  const [openPopUp, setOpenPopUp] = useState(false);

  const onClosePopUp = (visible) => setOpenPopUp(visible);

  const handleOpenChange = (visible) => {
    setOpenPopUp(visible);
  };

  return (
    <div className="newConversation">
      <div className="newConversationButton">
        <Popover
          trigger="click"
          placement="bottomLeft"
          overlayClassName="popupNewConversation"
          open={openPopUp}
          onOpenChange={handleOpenChange}
          content={
            <StartConversation {...{ users, onClosePopUp, setSelectedUser }} />
          }
        >
          <HoverButton
            {...{
              icon: <NewConversationIcon width={24} height={24} />,
              text: "New Conversation",
              alwaysShowIcon: true,
              iconSize: { height: 20, width: 20 },
              type: "action",
              className: "newConversationText",
            }}
          />
        </Popover>
      </div>

      <ConversationContent
        {...{
          selectedUser,
          socket,
          roomId,
          messageList,
          setMessageList,
        }}
      />
    </div>
  );
};

export default NewConversation;
