import { sortBy } from "../../../SidebarPages/Communication/functions";
import { REQUEST_FORM_TYPES } from "../../forms/RequestFormModal/helpers";

export const requestAction = {
  Draft: "assigned you a ",
  "Needs Review": "declined your ",
  Reviewed: "reviewed the ",
};

export const declineText = {
  Draft: "Review",
  "Needs Review": "Cancel Request",
  Reviewed: "Decline",
};

export const approveText = {
  Draft: "Approve",
  "Needs Review": "Review",
  Reviewed: "Approve",
};
export const declineActions = {
  Draft: "Needs Review",
  "Needs Review": "Cancel",
  Reviewed: "Needs Review",
};

export const approveActions = {
  "Needs Review": "Reviewed",
};

const sortRequests = (arr = []) => sortBy(arr, "createdAt").reverse();

/**
 * Returns an object containing all requests grouped by request type.
 *
 * @param {Array} requestsToRender - The array of requests to be processed.
 * @returns {Object} - An object containing all requests grouped by request type.
 */
export const allRequests = (requestsToRender) => {
  let toReturn = {};

  Object?.values(REQUEST_FORM_TYPES)?.map((type) => {
    toReturn[type] = sortRequests(
      requestsToRender?.filter((request) => request?.requestType === type)
    );
  });
  return toReturn;
};

export const requestCards = [
  {
    category: "Permit Drawing",
    addressClick: true,
  },
  {
    category: "Estimation",
    addressClick: true,
  },
  {
    category: "Scheduling",
    addressClick: true,
  },
  {
    category: "Dispatch",
    addressClick: false,
  },
  {
    category: "Safety Inspection",
    addressClick: true,
  },
  {
    category: "Project",
    addressClick: false,
  },
  {
    category: "Claim",
    addressClick: false,
  },
  {
    category: "Incident",
    addressClick: false,
  },
  {
    category: "Service Inspection",
    addressClick: false,
  },
  {
    category: "Schedule Change",
    addressClick: true,
  },
  {
    category: "Documentation",
    addressClick: true,
  },
  {
    category: "Change Order",
    addressClick: true,
  },
  {
    category: "Opportunity",
    addressClick: false,
  },
  {
    category: "Work Order",
    addressClick: false,
  },
  {
    category: "Fleet Inventory",
    addressClick: false,
  },
];
