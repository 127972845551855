import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import "./dataEntryGrid.scss";
import "./dataEntryGridLight.scss";
import "react-quill/dist/quill.snow.css";
import { Alert, Button, Col, Collapse, Empty, message, Modal, Row } from "antd";
import { debounce } from "lodash";
// import Loader from "react-loader-spinner";

import {
  AddonEditor,
  CheckboxRenderer,
  Header,
  MultipleChoiceRenderer,
  rtfEditor,
  ScopeSelectorModal,
  ServiceMenuContainer,
  SidewalkShedAddonsDetail,
  SidewalkShedPPUAdvisor,
  SidewalkShedRentAdvisor,
  StatusPanel,
  rtfEditorAddon,
} from "./subcomponents";
import { calculatePrice, getMaxNoFeet } from "./tools/formatters";
import {
  // appendPLIRow,
  duplicatePLIRow,
  removePLIRow,
} from "./tools/controllers";
import SidewalkShedAddonsRenderer from "./subcomponents/SidewalkShed/SidewalkShedAddonsDetail/SidewalkShedAddonsRenderer";
import { defaultColDef } from "./tools/columnDefiners/ServiceColumnDefiner";
import { calculateRent, calculateTaxAmount } from "./tools/formatters/pricing";
import { PriceSchemeModal } from "./tools/controllers/modals";
import { getSelectedPriceSchemesForService } from "./tools/formatters/evaluators";
import {
  saveEstimation,
  saveProject,
} from "./tools/apicalls/estimationANDproject";
import { API } from "aws-amplify";
import { getNeededDataForDataEntryGrid } from "./tools/apicalls/main";
import { Service } from "./models/Service";
// import {SidewalkShedPLI} from "./models/SidewalkShedModels";
import {
  clearServices,
  handleBreakdownsOnProjects,
} from "./tools/apicalls/validators";
import { AddonType } from "../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { checkIncomingData } from "./tools/controllers/checkIncomingData";
import GeneralAddonEditor from "./subcomponents/cellRenderers/AddonEditor/GeneralAddonEditor";
import SelectEditor from "./subcomponents/cellRenderers/SelectEditor/SelectEditor";
import {
  appendElevation,
  resetAllElevationsId,
} from "./subcomponents/elevationPanel/elevationPanel";
import {
  documentationAddons,
  serviceAddons,
} from "./subcomponents/renderingFunctions/serviceAddons";
import ServiceHeader from "./subcomponents/ServiceHeader/ServiceHeader";
import ElevationPanelHeader from "./subcomponents/elevationPanel/ElevationPanelHeader";
import ElevationPanelExtra from "./subcomponents/elevationPanel/ElevationPanelExtra";
import { ElevationContent } from "./subcomponents/elevationPanel/ElevationContent";
import OptionHeader from "./subcomponents/option/OptionHeader";
import JumpRenderer from "./subcomponents/cellRenderers/jumpRenderer";
import ApprovedRenderer from "./subcomponents/cellRenderers/approvedRenderer";
import { checkIfRowHasValidData } from "./tools/formatters/validators";
import { getRawAllServicesTotals } from "./tools/formatters/totals";
import ClassRedux from "../../utils/classRedux";
import { ServiceDocumentation } from "./subcomponents/documentation/ServiceDocumentation";
import {
  replaceArrayElement,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import {
  ConfirmationModal,
  LoadingDiamonds,
  WithTooltip,
} from "../../../commonComponents";
import { DimensionEditor } from "./subcomponents/cellEditors";
import { PriceTablePPURenderer } from "./subcomponents/cellRenderers";

import { TakeOffContext, useTakeOffContext } from "./context";
import { connect, useSelector } from "react-redux";
import { getRandomColor } from "../../utils";
import { isNumber } from "chart.js/helpers";
import { defaultScopeColumnDefs } from "../../../pages/Settings/settingsComponents/OtherScopes";
import { onOkHandler } from "../../SubLeads/subLeadsComponents/filterModalData";
import IncludesExcludes from "./subcomponents/includeExcludes/IncludesExcludes";
import "./../../../../components/pages/Settings/settingsComponents/Pricing/Pricing.scss";
import ServiceAddon from "./ServiceAddon";
import { savedData } from "../../../pages/Settings/settingsComponents/Roles/RolesData";
import EstimationSecondaryHeader from "./subcomponents/Header/EstimationSecondaryHeader";
import { dataEntryGridInitials } from "./functional/initials";
import { useComponentDidUpdate } from "../../../../hooks/useComponentDidUpdate";
import { useRedux } from "../../hooks/useRedux";
import { CollapseIcon, ExpandMoreIcon } from "../../../../icons/index";
import { DropdownIcon } from "../../BasePage/src/index";
import { useUndoRedoState } from "../../../../hooks/useUndoRedoState";
import { useMemoCompare } from "../../../../hooks/useMemoCompare";
import ServiceComponent from "./subcomponents/serviceComponent/ServiceComponent";
import {
  appendScopePLIRow,
  appendPLIRow,
} from "./tools/controllers/appendPLIRow";
import { forceToNumber } from "../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import ServiceAddonsConfig from "./subcomponents/ServiceAddonsConfig/ServiceAddonsConfig";
import useTakeOffAccessRights from "./tools/controllers/useTakeOffAccessRights";
import RentalServiceCollapse from "./subcomponents/rentalCalculator/RentalServiceCollapse/RentalServiceCollapse";
import { PlusIcon } from "../../../../assets";
import loadingIcon from "../DataEntryGrid/../../Estimations/components/DataEntryGridModal/assets/loadingGif.gif";
import { attachPriceSchemes } from "./tools/priceSchemes/defaultApply";
import Swal from "sweetalert2";
import RentalCalculator from "./subcomponents/rentalCalculator/RentalCalculator";
import { currencyFormater } from "../../../../utils/currencyFormater";
import { CircleSpinner } from "../../../commonComponents/3LoadingDots/LoadingDots";
import { isApprovedEstimateUsed } from "./tools/controllers/isApprovedEstimateUsed";
import { useProgramFields } from "../../../../hooks";

const { Panel } = Collapse;

function DataEntryGridView(props) {
  const [savedData, setSavedData] = useState(dataEntryGridInitials);
  const [dataRendered, setDataRendered] = useState(false);
  const [gridData, setTakeOffTableData] = useRedux(
    "takeOffTableData",
    false,
    false
  );
  const [takeOffFullPrice, setTakeOffFullPrice] = useRedux(
    "takeOffFullPrice",
    0,
    false
  );
  const [darkMode, setDarkMode] = useRedux("estimationsDarkMode", true, false);
  const [isWritable, setIsWritable] = useRedux("estimationsIsWritable");
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const [viewActivePanels, setViewActivePanels] = useState([[[]]]);
  const [lastAgGridInstance, setLastAgGridInstance] = useState(undefined);
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const [unitMeasurement, setUnitMeasurement] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    canExport = false,
    canAddOrRemoveAddons = false,
    canAddOrRemovePLItems = false,
    canAddOrRemoveServices = false,
    canEditPrice = false,
    canAddOrRemoveElevations = false,
    canSeeRentals = false,
    canSaveInProject = false,
    canChangeStatus = false,
    canAccessProposalDesigner = false,
    canViewPrice = false,
  } = useTakeOffAccessRights("Take-Off");
  const { ["Edit Allowance"]: allowanceForEdit } = useProgramFields();

  let priceEdit = canEditPrice;
  let priceView = canViewPrice;

  //Undo Redo Limit for TakeOff
  const undoRedoLimit = programFields
    .find((item) => item.fieldName === "Undo Redo Limit")
    .fieldOptions.find(({ name }) => name === "TakeOff").value;

  console.log("my service definitions", serviceDefinitions);

  const [agGridApiForEachElevation, setAgGridApiForEachElevation] = useState(
    {}
  );

  const myMappedData = Array.isArray(gridData)
    ? gridData?.map((el) => ({
        ...el,
        borderColor: Array.isArray(savedData?.serviceDefs)
          ? savedData?.serviceDefs?.find(
              (sd) => sd.workId === el.serviceId.toString()
            )?.colorCode
          : "#fff",
      })) || []
    : gridData;
  console.log("my mapped data", myMappedData);

  const {
    itemToSaveActiveState: newGridData,
    changeStateHandler,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    undoClickHandler,
    redoClickHandler,
  } = useUndoRedoState(undoRedoLimit); //Number (10) is to set Limit for undo changes

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;

  const [priceSchemesData, setPriceSchemesData] = useState({
    activePriceSchemes: [],
    selectedPriceScheme: undefined,
    priceSchemesType: "",
  });
  const {
    estimation,
    estimationId,
    jobSiteAddress,
    // isWritable,
    saveEstimation,
    saveProject,
    saveServicesInOppHandler,
    agGridTheme,
    setTheme,
    versionId,
    // serviceDefinitions = [],
    estimationStatus,
    // darkMode,
  } = props;
  // let lastAgGridInstance = undefined;
  // let agGridApiForEachElevation = {}; //is accessed like agGridApiForEachElevation[serviceId][optionIndex][elevationIndex].api.setRowData()
  let addonsAgGridInstances = {};
  const {
    // gridData,
    pricingData,
    selectedPriceSchemesForService,
    serviceDefs,
    activePriceSheetModal,
    // priceView,
    // priceEdit,
    ServicesIndex,
    accessRights,
    project,
    opportunity,
    ServiceMenuContainerShow,
    fullScreenService,
  } = savedData;

  const record = project || opportunity;

  let hasAccessRightsElevAdding = accessRights?.children?.find(
    (item) => item.title === "Adding/Removing Service"
  );

  const frameworkComponents = {
    StatusPanel,
    CheckboxRenderer,
    JumpRenderer,
    ApprovedRenderer,
    rtfEditor,
    rtfEditorAddon,
    MultipleChoiceRenderer,
    SidewalkShedAddonsDetail,
    SidewalkShedAddonsRenderer,
    SidewalkShedPPUAdvisor,
    SidewalkShedRentAdvisor,
    AddonEditor,
    GeneralAddonEditor,
    SelectEditor,
    DimensionEditor,
    PriceTablePPURenderer,
  };

  const showIndicators = useMemo(() => {
    const temp = {
      ServiceMenuContainerShow: false,
      FullServiceContainerShow: null,
    };
    if (fullScreenService !== null || ServiceMenuContainerShow === false) {
      temp.FullServiceContainerShow = true;
    }
    if (ServiceMenuContainerShow === true && fullScreenService === null) {
      temp.ServiceMenuContainerShow = true;
    }
    return temp;
  }, [ServiceMenuContainerShow, fullScreenService]);

  // Saves Estimation

  const changeSavedData = ({ key, val }) => {
    setSavedData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const getGridData = () => {
    return _.cloneDeep(gridData);
  };

  const saveThisEstimation = async ({ customData = false }) => {
    let gridDataTemp = !customData ? getGridData() : customData;
    await saveEstimation(gridDataTemp);
  };

  const saveServicesInOpp = () => {
    let oppEstimation = _.cloneDeep(savedData.oppOfCurrentEstimation);
    let servicesListCopy = _.cloneDeep(getGridData());
    let { proposedTypeOfWork = [], opportunityId } = oppEstimation;
    let serviceList = servicesListCopy.map((service) =>
      service.serviceId.toString()
    );
    console.log("serviceList", serviceList);
    API.patch("opportunities", `/opportunities/${opportunityId}`, {
      body: { proposedTypeOfWork: [...new Set(serviceList)] },
    }).then((res) => {
      setSavedData((prev) => {
        return {
          ...prev,
          oppOfCurrentEstimation: {
            ...(prev.oppOfCurrentEstimation ?? {}),
            proposedTypeOfWork: serviceList ?? [],
          },
        };
      });
    });
  };

  console.log("savedDaya`", savedData);

  const getSavedData = async () => {
    return _.cloneDeep(savedData);
  };

  const handleSave = async (willNotify, force, customData) => {
    console.log("handleSave", {
      willNotify,
      force,
    });
    let tempData = getSavedData();
    if (tempData.AutoSave === false && !force && tempData.loading) return; //if auto save is OFF and it is not forced do not save anything
    try {
      await saveThisEstimation({
        customData: !!customData ? customData : false,
      });
      if (tempData?.estimation?.accountId === "") {
        // message.error("Please select an account first.");
        await saveServicesInOppHandler(saveServicesInOpp);
      }
      if (willNotify) {
        message.success("Successfully saved.");
      }
    } catch (err) {
      changeSavedData({
        key: "loading",
        val: false,
      });
      // message.error("Something went wrong...");
      console.error("core services PUT error: " + err);
    }
  };

  const handleSaveProject = async (saveOrUnsave) => {
    console.log("handleSaveProject", saveOrUnsave, gridData);
    let isUsed = await isApprovedEstimateUsed({
      projectId: savedData.project.projectId,
      estimationId: savedData.estimation.estimationId,
      versionId: versionId,
      estimation: savedData.estimation,
      allowanceForEdit,
    });
    console.log("isUsed", isUsed);
    if (
      isWritable &&
      savedData.project_OR_opportunity === "project" &&
      !isUsed
    ) {
      try {
        let servicesToSave =
          saveOrUnsave === false ? [] : _.cloneDeep(gridData);
        try {
          servicesToSave = handleBreakdownsOnProjects(
            servicesToSave,
            serviceDefinitions
          ); //Add breakdown for each pli
        } catch (e) {
          console.error(e);
        }
        clearServices(servicesToSave); //service without elevations will be removed, options without elevations will be removed, elevation without any pli will be removed
        let projectServices = _.cloneDeep(savedData.project.services);
        console.log("servicesToSave", projectServices);
        projectServices[savedData.estimation.estimationId] = servicesToSave;
        saveProject(projectServices, gridData);
      } catch (e) {
        console.error(e);
        Swal.fire(
          "Error",
          "In order to Save A project, only one take off must be approved & there must exist a primary take off project. Please check everything is as required and try again!",
          "error"
        );
      }
    } else {
      message.error(
        "Can not save the project. Enable write mode and make sure you are working in a project not an opportunity."
      );
    }
  };
  // [
  //   savedData.project,
  //   savedData.project_OR_opportunity,
  //   savedData.estimation,
  //   gridData,
  //   isWritable,
  // ]);

  const handleElevationFieldChange = (
    e,
    serviceIndex,
    optionIndex,
    elevationIndex,
    reference
  ) => {
    let value;
    if (reference === "elevationLabel") {
      //if text
      value = e.target.innerText;
    } else if (reference === "additionalRentalTerms") {
      value = e || {};
    } else if (reference === "rent" || reference === "price") {
      //if rent or price
      value = parseFloat(e.target.innerText).toFixed(2); //formatForPricingNumberInput(e.target.innerText);
    } else {
      message.warning(
        "Elevation edits are not being saved. Contact with support team."
      );
      return;
    }
    console.log(savedData.currentElevationName);
    if (value !== savedData.currentElevationName) {
      let temp = structuredClone([...gridData]);
      temp[serviceIndex].serviceOptions[optionIndex][elevationIndex][
        reference
      ] = value;
      updateStateAndSave(temp);
    }
  };

  const handleModifyOption = (serviceIndex, optionIndex, action, data) => {
    let tempData = _.cloneDeep(gridData);
    if (action === "new") {
      tempData[serviceIndex].serviceOptions.push(data);
    } else if (action === "duplicate") {
      tempData[serviceIndex].serviceOptions.push(data);
      //reset all elevation id
      resetAllElevationsId(savedData.serviceDefs, tempData, serviceIndex);
    } else if (action === "convertPrimaryToAlternate") {
      message.info("Converting primary to alternate...");
    } else {
      // 'remove'
      tempData[serviceIndex]?.serviceOptions?.splice(optionIndex, 1);
      resetAllElevationsId(savedData.serviceDefs, tempData, serviceIndex);
    }

    updateStateAndSave(tempData);
  };

  const stateSetter = (data, callback) => {
    return new Promise((resolve) => {
      // const temp = _.cloneDeep({ ...savedData, ...data });
      if (!!data?.gridData) {
        const tempGrid = _.cloneDeep(data.gridData);
        const shouldIUpdate = !_.isEqual(tempGrid, gridData);
        if (shouldIUpdate) {
          setTakeOffTableData(tempGrid);
        }
        // handleSave(true, true);
      }
      setSavedData((prev) => ({ ...prev, ...data }));
      callback && callback();
      resolve();
    });
  };

  // appends a row below another row
  const appendPLIRowHandle = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!isWritable) return message.warning("Please enable write mode!");

    if (!canAddOrRemovePLItems)
      return message.warning("You don't have access to add PLI");

    const service = _.cloneDeep(gridData[serviceIndex]);
    const tempData = _.cloneDeep(gridData);
    const { serviceOptions, isScope } = service;
    // console.warning("service", service);

    if (!isScope) {
      appendPLIRow(
        savedData.ServicesIndex,
        true, //iswritable
        tempData,
        stateSetter,
        gridApi,
        serviceIndex,
        optionIndex,
        elevationIndex,
        itemId,
        savedData.accessRights,
        updateStateAndSave
      );
    } else {
      appendScopePLIRow(
        savedData.ServicesIndex,
        true, //iswritable
        tempData,
        stateSetter,
        gridApi,
        serviceIndex,
        optionIndex,
        elevationIndex,
        itemId,
        savedData.accessRights,
        updateStateAndSave
      );
    }
  };

  //duplicates a PLI row below another row
  const duplicatePLIRowHandle = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!isWritable) return message.warning("Please enable write mode!");
    const hasAccessRight = canAddOrRemovePLItems;
    if (!hasAccessRight)
      return message.warning("You don't have access to duplicate PLI");
    const tempData = _.cloneDeep(gridData);
    const service = tempData[serviceIndex];
    const { serviceId, serviceOptions, isScope } = service;

    if (!isScope) {
      duplicatePLIRow(
        savedData.ServicesIndex,
        true, // iswritable
        tempData,
        stateSetter,
        gridApi,
        serviceIndex,
        optionIndex,
        elevationIndex,
        itemId,
        savedData.accessRights
      );
    } else {
      const newService = {
        ...service,
        serviceOptions: [
          serviceOptions[0].map((elevation, idx) => {
            // Gets the max existing pli id and adds 1
            const newPliId = _.max(elevation.items.map(({ id }) => id)) + 1;

            return idx !== elevationIndex
              ? elevation
              : // Returns everything elevation has plus the new pli
                {
                  ...elevation,
                  items: [
                    ...elevation.items,
                    // new duplicated pli
                    {
                      ...elevation.items.find(({ id }) => id === itemId),
                      id: newPliId,
                    },
                  ],
                };
          }),
        ],
      };

      const newGridData = replaceArrayElement(
        tempData,
        newService,
        "serviceId"
      );

      updateStateAndSave(newGridData);
    }
  };

  // removes a row and creates a new one if there are no rows left
  const removePLIRowHandle = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!isWritable) return message.warning("Please enable write mode!");
    const hasAccessRight = canAddOrRemovePLItems;
    if (!hasAccessRight)
      return message.warning("You don't have access to add PLI");

    // console.log("removePLIRowHandle", 0);

    // setState({agSelectedCells: {}});
    // let gridData = _.cloneDeep(gridDataState);
    // let rowData = [];
    // gridApi.forEachNode((node) => rowData.push(node.data));
    // console.log("rowData", rowData);
    const myData = _.cloneDeep(gridData);
    const service = myData[serviceIndex];
    const { serviceOptions } = service;

    let items =
      myData[serviceIndex].serviceOptions[optionIndex][elevationIndex]?.items ||
      [];

    items = items
      .filter((item) => item.id !== itemId)
      ?.map((item) => {
        if (item.id > itemId) {
          item.id--;
        }
        return item;
      });

    // for (let i = 0; i < items.length; i++) {
    //   let item = items[i];
    //   console.log("items2", item);
    //   if (item.id === itemId) {
    //     items.splice(i, 1); //remove from this index
    //     i--; //come once more in this index
    //   }
    //   if (item.id > itemId) {
    //     //all pli that have higher ID that then deleted pli must decrease their id by 1
    //     item.id--;
    //   }
    //   console.log("items2.2", item);
    // }

    myData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items =
      items;
    stateSetter({ agSelectedCells: {} });
    // setState({ gridData: gridDataState });
    const selectedData = gridApi.getSelectedRows();
    gridApi.applyTransaction({ remove: selectedData });
    updateStateAndSave(myData);

    // gridApi.setRowData(items);
    // gridApi.redrawRows();

    // removePLIRow(
    //   savedData.ServicesIndex,
    //   isWritable,
    //   gridData,
    //   stateSetter,
    //   gridApi,
    //   serviceIndex,
    //   optionIndex,
    //   elevationIndex,
    //   itemId,
    //   savedData.accessRights
    // );
  };

  // keeps track of active panels
  const handleCollapseChange = (value, serviceIndex, optionIndex) => {
    // setLoading(true);
    // // let activePanels = [...viewActivePanels];
    // // if (activePanels[serviceIndex]) {
    // //   activePanels[serviceIndex][optionIndex] = value;
    // // } else {
    // //   activePanels[serviceIndex] = [][optionIndex] = value;
    // // }
    // // setViewActivePanels(activePanels);
    // // changeSavedData({
    // //   key: "activePanels",
    // //   value: activePanels,
    // // });
    // setTimeout(() => {
    //   setLoading(false);
    // }, 100);
  };

  const forceLoad = () => {
    setLoading(true);
    // let activePanels = [...viewActivePanels];
    // if (activePanels[serviceIndex]) {
    //   activePanels[serviceIndex][optionIndex] = value;
    // } else {
    //   activePanels[serviceIndex] = [][optionIndex] = value;
    // }
    // setViewActivePanels(activePanels);
    // changeSavedData({
    //   key: "activePanels",
    //   value: activePanels,
    // // });
    // collapseAllElevations(serviceIndex);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  // collapses all elevations in every option
  const collapseAllElevations = (serviceIndex) => {
    let newActivePanels = [...viewActivePanels];
    newActivePanels[serviceIndex] = [[]];
    setViewActivePanels(newActivePanels);
    // changeSavedData({
    //   key: "activePanels",
    //   value: newActivePanels,
    // });
  };

  // expands all elevations in every option
  const expandAllElevations = (serviceIndex) => {
    let newActivePanels = [...viewActivePanels];
    const myData = _.cloneDeep(gridData);

    newActivePanels[serviceIndex] = myData[serviceIndex].serviceOptions.map(
      (options) => {
        return options.map((elevation) => {
          return elevation.elevationId;
        });
      }
    );
    setViewActivePanels(newActivePanels);
    // changeSavedData({
    //   key: "activePanels",
    //   value: newActivePanels,
    // });
  };

  console.log("viewActivePanels", viewActivePanels);

  // Add addons Total amount.
  const getAddonsCaption = (rowData = [], isReadOnly) => {
    let addonsTotalPrice = rowData.reduce(
      (a, b) => (a += forceToNumber(b?.totalPrice)),
      0
    );
    let addonsTax = rowData.reduce(
      (a, b) => (a += forceToNumber(b?.includedTax?.taxAmount)),
      0
    );
    let warningMessage = `${rowData.length} addons`;
    console.log("addonsTotalPrice", rowData);
    // if (!canViewPrice) {
    //   warningMessage += " - PRICE HIDDEN $";
    // }
    if (canViewPrice) {
      warningMessage += ` - (PRICE $${addonsTotalPrice?.toFixed(
        2
      )}) includes $${addonsTax > 0 ? addonsTax?.toFixed(2) : 0} Tax`;
    }
    if (!canAddOrRemoveAddons) {
      warningMessage += " - You have no clearance to add/edit Service Addons!";
    }

    return warningMessage;
  };

  const resizeGridRows = (gridApi) => {
    gridApi.resetRowHeights();
  };

  const getRowNodeId = (data) => {
    return data.id;
  };

  /**
   * Save changes of Addons in database with this function. This function calls this.handleSave() to save in database.
   * @param gridApi {{}} //ag grid instance
   * @param serviceIndex {Number} The service number example: 0 is the first service in page
   * @param optionIndex {Number}
   * @param elevationIndex {Number} what elevation are we editing (counting starts from 0)
   * @param itemId {Number} The id of PLI that we want to modify
   * @param addonsData {Object[]} An array with addons in it. The data we want to save.
   * @param addonAPI {{addonId, api}}
   * */
  const saveSidewalkShedAddonsData = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    addonsData,
    addonAPI
  ) => {
    let newGridData = _.cloneDeep(gridData);
    let items;
    message.info("Saving...");
    // if(gridData[serviceIndex].serviceId === 3) {
    //   items = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].item.test.items
    // } else {
    //   items = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
    // }
    items =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .items;

    for (const pli of items) {
      if (pli.id === itemId) {
        if (newGridData[serviceIndex].serviceId !== 1) {
          //if not in sidewalk shed, manipulate data
          for (let i = 0; i < addonsData.length; i++) {
            let generatedAddon = {};
            for (const key in addonsData[i]) {
              generatedAddon[key.toLowerCase()] = addonsData[i][key];
            }
            addonsData[i] = generatedAddon;
          }
        }

        pli.addons = addonsData;

        gridApi.applyTransaction({ update: [pli] });
        gridApi.refreshCells({ rowNodes: [pli], force: true });
        break;
      }
    }
    // gridApi.setRowData(items)
    // gridApi.redrawRows()
    stateSetter({ gridData: newGridData }, () => {});
    // updateStateAndSave(newGridData);
    // gridApi.getRowNode(itemId).setExpanded(true);
    // if (addonAPI) {
    //   const { addonId, api } = addonAPI;
    //   api.forEachNode((n) => {
    //     if (n.data.id === addonId) {
    //       n.setExpanded(true);
    //     }
    //   });
    // }
  };

  const saveHoistAddonsData = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    addonsData
  ) => {
    let newGridData = _.cloneDeep(gridData);
    for (const item of newGridData[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items) {
      if (item.id === itemId) {
        item.addons = addonsData;
        break;
      }
    }
    stateSetter({ gridData: newGridData }, () => {
      gridApi.setRowData(
        newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
          .items
      );
      handleSave(false, true);
    });
  };

  const onAddonRichTextChange = ({
    service,
    addonType,
    addons,
    serviceIndex,
    optionIndex,
    elevationIndex,
    pliId,
  }) => {
    let myData = _.cloneDeep(gridData);
    if (addonType === AddonType.serviceAddon || addonType === "documentation") {
      let correctService = myData.find(
        (s) => s.serviceId === service.serviceId
      );
      correctService.serviceAddons = service.serviceAddons;
    } else if (addonType === AddonType.pli) {
      let pli;
      // if(newGridData[serviceIndex].serviceId === 3) {
      //   pli = newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].item.test.items.find(pli => pli.id === pliId)
      // } else {
      pli = myData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items.find((pli) => pli.id === pliId);
      // }
      pli.addons = addons;
    }

    updateStateAndSave(myData);
  };

  // handles input change
  const handleInputElementChange = (
    e,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemReference
  ) => {
    let newGridData = _.cloneDeep(gridData);
    _.update(
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .item,
      itemReference,
      () => e.target.value
    );
    stateSetter({ gridData: newGridData });
  };

  //when we change ppu in service level
  const handlePricePerUnitChange = (e, serviceId, saveInDatabase) => {
    let hasWriteAccess = savedData.accessRights.children.find(
      (item) => item.title === "Price Edit"
    ).write;

    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    if (!hasWriteAccess) {
      message.error("You have no access to edit");
      return;
    }
    if (isNaN(e) || e === Infinity) e = 0;
    let value = parseFloat(e); // formatForPricingNumberInput(e.target.value.toString()) // dollarFormatter(e.target.value)
    //calculate new prices
    const newGridData = _.cloneDeep(gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems =
          /*savedData.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/ elevation.items;
        for (const pli of iterableItems) {
          if (!pli.lock) {
            //if it is not locked

            pli.ppu = value;
            pli.price = calculatePrice(serviceId, pli);
            pli.rent = calculateRent(service, pli);
            pli.taxAmount = calculateTaxAmount({ service, pli });
            pli.totalPrice =
              forceToNumber(pli?.price) + forceToNumber(pli?.taxAmount);
            // pli.totalPrice = pli.price + pli.rent;
          }
        }
        if (
          agGridApiForEachElevation &&
          agGridApiForEachElevation[serviceId] &&
          agGridApiForEachElevation[serviceId][optionIndex] &&
          agGridApiForEachElevation[serviceId][optionIndex][elevationIndex]
        ) {
          //if ag-grid api have been initialised for this elevation
          //example: if elevation has not been expanded, it has not been initialised
          agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.setRowData(elevation.items);
          agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.refreshCells({ force: true });
        }
      });
    });
    //update ppu in the service
    service.pricePerUnit = value;
    if (saveInDatabase) updateStateAndSave(newGridData);
    else stateSetter({ gridData: newGridData });
  };

  const handleDefaultPricePerUnitChange = (
    e,
    serviceId,
    saveInDatabase,
    type
  ) => {
    let hasWriteAccess = savedData.accessRights.children.find(
      (item) => item.title === "Price Edit"
    ).write;

    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    if (!hasWriteAccess) {
      message.error("You have no access to edit");
      return;
    }
    if (isNaN(e) || e === Infinity) e = 0;
    let value = parseFloat(e); // formatForPricingNumberInput(e.target.value.toString()) // dollarFormatter(e.target.value)
    //calculate new prices
    const newGridData = _.cloneDeep(gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    console.log("new service", service);

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems = elevation.items;
        console.log("iterableItems", iterableItems);
        /*savedData.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/
        for (const pli of iterableItems) {
          if (!pli.lock && pli?.isInitial) {
            //if it is not locked
            if (type !== "rent") {
              pli.ppu = value;
              pli.price = calculatePrice(serviceId, pli);
              pli.rent = calculateRent(service, pli);
              // pli.totalPrice = pli.price + pli.rent;
            } else {
              pli.rent = calculateRent({ ...service, rent: value }, pli);
              pli.price = calculatePrice(serviceId, pli);
              console.log("plitestttt", pli);
              // pli.totalPrice = pli.price + pli.rent;
            }

            if (
              agGridApiForEachElevation &&
              agGridApiForEachElevation[serviceId] &&
              agGridApiForEachElevation[serviceId][optionIndex] &&
              agGridApiForEachElevation[serviceId][optionIndex][elevationIndex]
            ) {
              //if ag-grid api have been initialised for this elevation
              //example: if elevation has not been expanded, it has not been initialised
              agGridApiForEachElevation[serviceId][optionIndex][
                elevationIndex
              ].api.setRowData(elevation.items);
              agGridApiForEachElevation[serviceId][optionIndex][
                elevationIndex
              ].api.refreshCells({ force: true });
            }
          }
        }
      });
    });
    //update ppu in the service
    if (type !== "rent") service.defaultPricePerUnit = value;
    else if (type === "rent") service.initialRent = value;
    stateSetter({ gridData: newGridData });
  };
  //when we blur some inputs an autosave is performed
  const onInputBlur = () => {
    handleSave(false, true);
  };

  //when price scheme changes
  const updateSWSPPU = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId,
    value
  ) => {
    console.log("updateSWSPPU", {
      gridApi,
    });
    let tempData = getGridData(gridData);
    for (let item of tempData[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items) {
      if (item.id === itemId) {
        item.ppu = value;
        item.lock = true;
        item.price = value * getMaxNoFeet(item.length);
        item.rent = calculateRent(tempData[serviceIndex], item, optionIndex);
        item.taxAmount = item.taxRate * item.price;
        item.totalPrice = item.price + item.taxAmount;
        console.log("editeditem", item);
        gridApi.applyTransaction({ update: [item] }); //add it into ag-grid table
        gridApi.refreshCells({
          force: true,
          // columns: ["ppu"],
          suppressFlash: false,
          rowNodes: [item],
        });

        break;
      }
    }
    console.log("after PPUUUU", tempData);
    updateStateAndSave(tempData);
  };

  console.log("savedData", savedData);

  //handles when rent in service level changes. Unlocked plis need to be updated
  const handleRentPerServiceChange = (e, serviceId, saveInDatabase) => {
    const newGridData = _.cloneDeep(gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    if (isNaN(e) || e === Infinity) e = 0;
    service.rent = parseFloat(e);

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems =
          /*savedData.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/ elevation.items;
        for (const pli of iterableItems) {
          if (!pli.lock) {
            //if it is unlocked
            pli.rent = calculateRent(service, pli);
          }
        }
        if (
          agGridApiForEachElevation &&
          agGridApiForEachElevation[serviceId] &&
          agGridApiForEachElevation[serviceId][optionIndex] &&
          agGridApiForEachElevation[serviceId][optionIndex][elevationIndex]
        ) {
          //if ag-grid api have been initialised for this elevation
          //example: if elevation has not been expanded, it has not been initialised
          agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.setRowData(elevation.items);
          agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.refreshCells({ force: true });
        }
      });
    });

    if (saveInDatabase) updateStateAndSave(newGridData);
    else stateSetter({ gridData: newGridData });
  };

  //handles when rent in service level changes. Unlocked plis need to be updated
  const handleAlternateRentPerServiceChange = (
    e,
    serviceId,
    saveInDatabase
  ) => {
    const newGridData = _.cloneDeep(gridData);
    const service = newGridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    if (isNaN(e) || e === Infinity) e = 0;
    service.alternateRent = parseFloat(e);

    service.serviceOptions.forEach((option, optionIndex) => {
      option.forEach((elevation, elevationIndex) => {
        const iterableItems =
          /*savedData.ServicesIndex["Hoist"] === serviceId ? elevation.item.test.items :*/ elevation.items;
        for (const pli of iterableItems) {
          if (!pli.lock) {
            //if it is unlocked
            pli.rent = calculateRent(service, pli);
          }
        }
        if (
          agGridApiForEachElevation &&
          agGridApiForEachElevation[serviceId] &&
          agGridApiForEachElevation[serviceId][optionIndex] &&
          agGridApiForEachElevation[serviceId][optionIndex][elevationIndex]
        ) {
          //if ag-grid api have been initialised for this elevation
          //example: if elevation has not been expanded, it has not been initialised
          agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.setRowData(elevation.items);
          agGridApiForEachElevation[serviceId][optionIndex][
            elevationIndex
          ].api.refreshCells({ force: true });
        }
      });
    });
    if (saveInDatabase) updateStateAndSave(newGridData);
    else stateSetter({ gridData: newGridData });
  };

  //when we are using quill editor as custom component in ag-grid for notes and description ususally
  const saveDataFromRTDEditor = (
    items,
    serviceIndex,
    optionIndex,
    elevationIndex
  ) => {
    const gridDataCopy = _.cloneDeep(gridData);
    gridDataCopy[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items = items;

    updateStateAndSave(gridDataCopy);
  };

  /**
   * @param gridData {Service[]}
   * */
  const updateStateAndSave = (tempData) => {
    const newGridData = _.cloneDeep(tempData);
    newGridData?.forEach?.(({ serviceId, serviceAddons, serviceOptions }) => {
      serviceOptions?.forEach?.((alternate, alternateIdx) =>
        alternate?.forEach?.(({ items = [] }, elevationIndex) => {
          const api =
            agGridApiForEachElevation?.[serviceId]?.[alternateIdx]?.[
              elevationIndex
            ]?.api;
          api?.setRowData?.(items);
          // api?.redrawRows?.()
        })
      );

      // addonsAgGridInstances?.[+serviceId]?.["service addon"]?.api?.setRowData?.(
      //   serviceAddons
      // );
    });
    console.log("newGridData", newGridData);
    const shouldIUpdate = !_.isEqual(newGridData, gridData);
    if (shouldIUpdate) {
      setTakeOffTableData(newGridData);
    }
    // setTakeOffTableData(newGridData);
    // stateSetter({ gridData: newGridData });
    handleSave(false, true);
  };

  const handleNumbersOnly = (e) => {
    let x = e.charCode || e.keyCode;
    if (
      isNaN(String.fromCharCode(x)) &&
      (x !== 46 ||
        x === 32 ||
        x === 13 ||
        (x === 46 && e.target.innerText.includes(".")))
    ) {
      e.preventDefault();
    }
  };

  //when clicking full screen button
  const fullscreenizeService = (serviceId) => {
    stateSetter({
      fullScreenService: serviceId,
      ServiceMenuContainerShow: false,
    });
  };

  //when clicking exit full screen
  const deFullscreenize = () => {
    stateSetter({
      fullScreenService: null,
      ServiceMenuContainerShow: true,
    });
  };

  /**
   * Comes from HandleShowSidewalkShedPriceSheet. This will change status with the selected price scheme. If user has pressed cancel we will not do anything.
   * @param selectedPriceSchemeId {String | undefined} If an id comes, it meas that user have pressed OK button and want to use the selected price scheme.
   * If it comes as undefined, user have pressed CANCEL and it meas that he/she wants to continue with the existing price scheme.
   * */
  const onPriceSchemeModalClose = (selectedPriceSchemeId) => {
    if (!isWritable && selectedPriceSchemeId !== undefined) {
      //if we are not in write mode and user attempt to change price scheme
      message.error("Please enable write mode");
      stateSetter({ activePriceSheetModal: false }); //close modal without changes
    } else {
      if (selectedPriceSchemeId === undefined)
        stateSetter({ activePriceSheetModal: false });
      //if nothing selected, just close the modal
      else {
        //find the correct scheme in gridData, via activePriceSheetModal (which contains the opened service) and selectedPriceSchemeId
        const selectedPriceSchemesForService = _.cloneDeep(
          savedData.selectedPriceSchemesForService
        );
        const pricingData = _.cloneDeep(savedData.pricingData);
        const correctPricingForService = pricingData.find(
          (s) =>
            s.serviceId.toString() ===
            savedData.activePriceSheetModal.toString()
        );
        const correctPricingObject = correctPricingForService.pricingObject;
        selectedPriceSchemesForService[savedData.activePriceSheetModal] = {
          priceScheme: correctPricingObject.priceSchemes.find(
            (ps) => ps.id === selectedPriceSchemeId
          ),
          priceSchemesType: correctPricingObject.priceSchemesType,
        };

        //update the selectedPriceSchemeId to estimation
        let estimation = _.cloneDeep(savedData.estimation);
        const tempData = _.cloneDeep(gridData);
        tempData.find(
          (s) => s.serviceId === savedData.activePriceSheetModal
        ).selectedPriceSchemeId = selectedPriceSchemeId;

        estimation = { ...estimation, services: tempData }; //update estimation

        if (lastAgGridInstance) lastAgGridInstance.api.refreshCells(); //handle to update grid

        //update savedData.activePriceSheetModal and set activePriceSheetModal: false to close the modal
        stateSetter(
          {
            selectedPriceSchemesForService,
            pricingData,
            estimation,
            activePriceSheetModal: false,
          },
          () => {
            handleSave(false, true);
          }
        );
      }
    }
  };

  //initialise everything just as we open the page for the first time
  const initGrid = async () => {
    stateSetter({ loading: true });
    try {
      //   const { estimation } = .props;
      console.log("estimation", estimation);
      const tempEstimation = structuredClone(estimation);
      const {
        pricingData,
        proposedTypeOfWork = [],
        // estimation,
        priceView,
        priceEdit,
        projectData,
        units,
      } = await getNeededDataForDataEntryGrid(
        // this.props.estimationId,
        userConfiguration,
        estimation
      );

      const oppId = estimation?.opportunityId || false;
      let tempOppEst = {};

      if (oppId)
        await API.get("opportunities", `/opportunities/${oppId}`).then(
          (res) => {
            tempOppEst = res;
          }
        );

      //create a dynamic enum for services id
      let ServicesIndex = {},
        ServiceNameByIndex = {};
      for (const s of proposedTypeOfWork) {
        ServicesIndex[s.workName] = parseInt(s.workId);
        ServiceNameByIndex[s.workId] = s?.workName; //also create the reverse references, for quicker access
      }
      console.log("checking services", estimation?.services);
      // modifyPricingServicesToHandleErrors(estimation.services, services, ServicesIndex);
      if (
        estimation?.services?.length > 0 &&
        typeof estimation.services[0] !== "object"
      ) {
        //if services are in type like ["3", "6", "0"]
        tempEstimation.services = estimation.services.map((serviceId) => {
          return new Service(
            parseInt(serviceId),
            proposedTypeOfWork.find(
              (s) => s.workId === serviceId.toString()
            )?.workName
          );
        });
      }

      let selectedPriceSchemesForService = getSelectedPriceSchemesForService(
        pricingData,
        tempEstimation,
        savedData,
        tempEstimation.services
      );

      const errorMessages = checkIncomingData({
        project: projectData,
        authenticatedUser: authenticatedUser,
      });
      if (errorMessages.length > 0) stateSetter({ errorMessages });

      let project_OR_opportunity;
      if (projectData?.project) project_OR_opportunity = "project";
      else if (projectData?.opportunity) project_OR_opportunity = "opportunity";

      stateSetter(
        {
          serviceDefs: proposedTypeOfWork,
          ServicesIndex,
          ServiceNameByIndex,
          estimation: tempEstimation,
          project: projectData?.project,
          opportunity: tempOppEst,
          serviceOrder: [...tempEstimation.services.keys()],
          department: authenticatedUser?.department,
          priceView,
          priceEdit,
          gridData: Service.parseServicesForFrontend(
            tempEstimation.services,
            tempEstimation
          ), //this is where all services data lives
          selectedPriceSchemesForService,
          selectOptions: { units },
          project_OR_opportunity,
          pricingData,
          loading: false,
          itemToSaveActiveState: Service.parseServicesForFrontend(
            tempEstimation.services,
            tempEstimation
          ),
          oppOfCurrentEstimation: tempOppEst,
        }
        // () => {
        //   stateSetter(() => {
        //     return {
        //       itemToSaveActiveState: gridData, //This will populate itemToSaveActiveState after gridData is filled.
        //     };
        //   });
        // }
      );
      console.log(
        "takeOffTableData",
        Service.parseServicesForFrontend(
          tempEstimation.services,
          tempEstimation
        )
      );
      setTakeOffTableData(
        attachPriceSchemes(
          Service.parseServicesForFrontend(
            tempEstimation.services,
            tempEstimation
          ),
          pricingData
        )
      );
      API.get("unitMeasurement", "/unitMeasurement").then((res) =>
        setUnitMeasurement(
          res
          // .filter(
          //   ({ typeOfWork, addonType }) =>
          //     Number(typeOfWork) === serviceID && addonType === "service addon"
          // )
        )
      );
      document.querySelectorAll(".priceCalculation").forEach((element) => {
        element.addEventListener("keypress", handleNumbersOnly);
      });
    } catch (e) {
      message.error("Couldn't find some data. You can't work here, sorry.");
      console.log("erorr", e);
      stateSetter({ loading: false });
    }
  };

  // called before grid renders. used for data set and event listeners
  const onGridReady = (
    params,
    data,
    serviceId,
    optionIndex,
    elevationIndex,
    immutableStore
  ) => {
    //define columns event listeners

    const columnsAPI = params.columnApi.getAllColumns();

    // const UIFeaturesForEachElevation = _.cloneDeep(
    //   savedData?.UIFeaturesForEachElevation
    // );

    // if (!UIFeaturesForEachElevation[serviceId])
    //   UIFeaturesForEachElevation[serviceId] = {};
    // if (!UIFeaturesForEachElevation[serviceId][optionIndex])
    //   UIFeaturesForEachElevation[serviceId][optionIndex] = {};
    // if (!UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex])
    //   UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex] = {};

    // for (const colAPI of columnsAPI) {
    //   if (
    //     !UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex]
    //       .columns
    //   )
    //     UIFeaturesForEachElevation[serviceId][optionIndex][
    //       elevationIndex
    //     ].columns = {};
    //   colAPI.addEventListener("visibleChanged", (event) => {
    //     UIFeaturesForEachElevation[serviceId][optionIndex][
    //       elevationIndex
    //     ].columns[event.column.colId] = {
    //       ...UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex]
    //         .columns[event.column.colId],
    //       visible: event.column.visible,
    //     };
    //     console.log("visibleChanged1", UIFeaturesForEachElevation);
    //     stateSetter({ UIFeaturesForEachElevation });
    //   });
    // }

    //define ag grid instances for elevation
    // setLastAgGridInstance(params);
    // const agGridApiForEachElevation_TEMP = _.cloneDeep(
    //   agGridApiForEachElevation
    // );
    // if (!agGridApiForEachElevation_TEMP) agGridApiForEachElevation_TEMP = {};
    // if (!agGridApiForEachElevation_TEMP[serviceId])
    //   agGridApiForEachElevation_TEMP[serviceId] = {};
    // if (!agGridApiForEachElevation_TEMP[serviceId][optionIndex])
    //   agGridApiForEachElevation_TEMP[serviceId][optionIndex] = {};
    // if (!agGridApiForEachElevation_TEMP[serviceId][optionIndex][elevationIndex])
    //   agGridApiForEachElevation_TEMP[serviceId][optionIndex][elevationIndex] =
    //     {};

    // agGridApiForEachElevation_TEMP[serviceId][optionIndex][elevationIndex] =
    //   params;
    // setAgGridApiForEachElevation(agGridApiForEachElevation_TEMP);

    // params.api.addEventListener("toolPanelVisibleChanged", (params) => {
    //   UIFeaturesForEachElevation[serviceId][optionIndex][
    //     elevationIndex
    //   ].toolPanelVisible = params.api.isToolPanelShowing();
    //   console.log("visibleChanged2", UIFeaturesForEachElevation);
    //   stateSetter({ UIFeaturesForEachElevation });
    // });

    params.api.setDomLayout("autoHeight");

    // params.api.setRowData(data);
    // immutableStore = data;
    // params.api.sizeColumnsToFit();
    params.api.closeToolPanel();
    // window.addEventListener('resize', () => {params.api.sizeColumnsToFit();});
    // this.setState({UIFeaturesForEachElevation})
  };
  //   const onGridReady = (
  //     params,
  //     data,
  //     serviceId,
  //     optionIndex,
  //     elevationIndex
  //   ) => {
  //     //define columns event listeners

  //     const columnsAPI = params.columnApi.getAllColumns();

  //     const UIFeaturesForEachElevation = _.cloneDeep(
  //       savedData.UIFeaturesForEachElevation
  //     );

  //     if (!UIFeaturesForEachElevation[serviceId])
  //       UIFeaturesForEachElevation[serviceId] = {};
  //     if (!UIFeaturesForEachElevation[serviceId][optionIndex])
  //       UIFeaturesForEachElevation[serviceId][optionIndex] = {};
  //     if (!UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex])
  //       UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex] = {};

  //     for (const colAPI of columnsAPI) {
  //       if (
  //         !UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex]
  //           .columns
  //       )
  //         UIFeaturesForEachElevation[serviceId][optionIndex][
  //           elevationIndex
  //         ].columns = {};
  //       colAPI.addEventListener("visibleChanged", (event) => {
  //         UIFeaturesForEachElevation[serviceId][optionIndex][
  //           elevationIndex
  //         ].columns[event.column.colId] = {
  //           ...UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex]
  //             .columns[event.column.colId],
  //           visible: event.column.visible,
  //         };
  //         changeSavedData({
  //           key: "UIFeaturesForEachElevation",
  //           value: UIFeaturesForEachElevation,
  //         });
  //         // stateSetter({ UIFeaturesForEachElevation });
  //       });
  //     }

  //     //define ag grid instances for elevation
  //     lastAgGridInstance = params;
  //     if (!agGridApiForEachElevation) agGridApiForEachElevation = {};
  //     if (!agGridApiForEachElevation[serviceId])
  //       agGridApiForEachElevation[serviceId] = {};
  //     if (!agGridApiForEachElevation[serviceId][optionIndex])
  //       agGridApiForEachElevation[serviceId][optionIndex] = {};
  //     if (!agGridApiForEachElevation[serviceId][optionIndex][elevationIndex])
  //       agGridApiForEachElevation[serviceId][optionIndex][elevationIndex] = {};

  //     agGridApiForEachElevation[serviceId][optionIndex][elevationIndex] = params;

  //     params.api.addEventListener("toolPanelVisibleChanged", (params) => {
  //       UIFeaturesForEachElevation[serviceId][optionIndex][
  //         elevationIndex
  //       ].toolPanelVisible = params.api.isToolPanelShowing();
  //       changeSavedData({
  //         key: "UIFeaturesForEachElevation",
  //         value: UIFeaturesForEachElevation,
  //       });
  //     });

  //     params.api.setDomLayout("autoHeight");

  //     params.api.setRowData(data);

  //     // params.api.sizeColumnsToFit();
  //     params.api.closeToolPanel();
  //     // window.addEventListener('resize', () => {params.api.sizeColumnsToFit();});
  //     // this.setState({UIFeaturesForEachElevation})
  //   };

  const handleAddonsGridReady = (params, service, serviceType) => {
    if (serviceType === AddonType.serviceAddon) {
      params.api.setRowData(service.serviceAddons);
      if (!addonsAgGridInstances[service.serviceId])
        addonsAgGridInstances[service.serviceId] = {};
      addonsAgGridInstances[service.serviceId][serviceType] = params;
    } else if (serviceType === "documentation") {
      params.api.setRowData(service.documentationAddons);
      if (!addonsAgGridInstances[service.serviceId])
        addonsAgGridInstances[service.serviceId] = {};
      addonsAgGridInstances[service.serviceId][serviceType] = params;
    }

    // params.api.sizeColumnsToFit();
    // window.addEventListener('resize', () => {params.api.sizeColumnsToFit();});
  };

  // called when a cell editing has stopped. used to resize rows when not in focus
  const cellEditingStopped = (params) => {
    // resizeGridRows(params.api);
    // this.setState({elevationTotalKey: this.state.elevationTotalKey + 1,});
  };

  // called when a cell editing has started. used for auto-new-row
  const cellEditingStarted = (params) => {
    let hasAccessRight = savedData.accessRights.children.find(
      (item) => item.title === "Pli Price Edit"
    )?.write;
    if (!isWritable) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
    if (["ppu", "rent", "price"].includes(params.colDef.field)) {
      if (!!!hasAccessRight) {
        params.api.stopEditing(true);
        message.error("You have no access to edit");
      }
    }
  };

  const addonsCellEditingStarted = (params) => {
    let hasWriteAccess = savedData.accessRights.children.find(
      (item) => item.title === "Addon Price Edit"
    ).write;
    if (!isWritable) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
    if (["ppu", "rent", "price"].includes(params.colDef.field)) {
      if (!!!hasWriteAccess) {
        params.api.stopEditing(true);
        message.error("You have no access to edit");
      }
    }
  };

  //when changing service addons data
  const onBottomAddonChange = (serviceId, id, api, addonData, addonType) => {
    let myData = _.cloneDeep(gridData);
    const service = myData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    let addons;
    if (addonType === AddonType.serviceAddon) addons = service.serviceAddons;
    else if (addonType === "documentation")
      addons = service.documentationAddons;

    for (let i = 0; i < addons.length; i++) {
      const addon = addons[i];
      const idOfAddonToModify = addons[i].id;
      if (id === idOfAddonToModify) {
        for (const key of addonData.values) {
          if (key.name.toLowerCase() === "name") {
            addon["name"] = addonData;
          } else addon[key.name.toLowerCase()] = key.value;
        }
        api.applyTransaction({ update: [addon] });
        break;
      }
    }
    updateStateAndSave(myData);
    onIsTaxableChange(
      service,
      !!service?.isTaxable ? service.isTaxable : false
    );
  };

  const onServiceDocumentationChange = ({ serviceId, documentationAddons }) => {
    let myData = _.cloneDeep(gridData);
    const service = myData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );
    service.documentationAddons = documentationAddons;
    updateStateAndSave(myData);
  };

  const saveEstimationDescription = async (note) => {
    showLoadingMsg({ content: "Loading" });
    await API.patch("estimations", `/estimations/${estimation?.estimationId}`, {
      body: { estimationDescription: note || "" },
    })
      .then((res) => showSuccessMsg({ content: "Note added" }))
      .catch((err) => {
        console.log(err);
      });
  };

  const addonsCellValueChanged = (params, serviceIndex, addonType) => {
    let addons,
      myData = _.cloneDeep(gridData);
    if (addonType === AddonType.serviceAddon)
      addons = myData[serviceIndex].serviceAddons;
    else if (addonType === "documentation")
      addons = myData[serviceIndex].documentationAddons;

    //TODO if field that has changed is a number, parse it to 2 decimal after decimal point

    for (let i = 0; i < addons.length; i++) {
      if (addons[i].id === params.node.id) {
        addons[i] = params.data;
        break;
      }
    }

    onIsTaxableChange(myData[serviceIndex], true, params.node.id);

    // this.updateStateAndSave(gridData);
  };

  const onIsTaxableChange = (service, isTaxable, addOnId) => {
    const sId = service.serviceId;
    const thisService = gridData.find((s) => s.serviceId === sId);
    const { serviceOptions, serviceAddons, taxRate } = _.cloneDeep(thisService);
    let myData = _.cloneDeep(gridData);
    const pliTaxUpdater = (pli) => {
      const taxAmount = isTaxable
        ? (!isNaN(+pli?.price) ? +pli?.price : 0) * +taxRate
        : 0;

      const totalPrice = +pli.price + +taxAmount;
      return {
        ...pli,
        taxRate: isTaxable ? +taxRate : 0,
        price: !isNaN(+pli?.price) ? +pli?.price : 0,
        // taxRate: pli?.taxRate * 100,
        taxAmount,
        totalPrice,
      };
    };

    const updatedService = {
      ...service,
      isTaxable,
      serviceOptions: serviceOptions.map((alternate) =>
        alternate?.map?.((elevation) => ({
          ...elevation,
          items: elevation?.items?.map?.(pliTaxUpdater),
        }))
      ),
      serviceAddons: serviceAddons.map(pliTaxUpdater),
    };

    const newGridData = replaceArrayElement(
      myData,
      updatedService,
      "serviceId"
    );

    updateStateAndSave(newGridData);
  };

  // called when a key is pressed inside of the grid. used for enter -> tab
  const onCellKeyPress = (e) => {
    console.log("key press", e);
    // keycode for tab is 9
    if (e.event.keyCode === 9) {
      message.info("Tab pressed");
    }
    console.log("enter", e);
    if (e.event.keyCode === 13) {
      // 13 is the ascii code for ENTER
      if (e.event.shiftKey) {
        e.event.preventDefault();
      } else {
        e.api.tabToNextCell();
      }
    }
  };

  //   handleExcelReport() {}

  const handleGridCellFocused = (params) => {
    const focusedCell = params.api.getFocusedCell();
    let currentCell = {
      rowNode: params.api.getDisplayedRowAtIndex(focusedCell?.rowIndex),
      column: focusedCell?.column["colDef"].field,
    };
    if (savedData.agSelectedCells.hasOwnProperty("current")) {
      let newAgSelectedCells = {
        previous: savedData.agSelectedCells.current,
        current: currentCell,
      };
      try {
        params.api.flashCells({
          rowNodes: [savedData.agSelectedCells.current.rowNode],
          columns: [savedData.agSelectedCells.current.column],
        });
      } catch (e) {
        // do not flash
      }
      changeSavedData({ key: "agSelectedCells", value: newAgSelectedCells });
    } else {
      let newAgSelectedCells = { current: currentCell };
      changeSavedData({ key: "agSelectedCells", value: newAgSelectedCells });
    }
  };

  /**
   * Comes from ApprovedRenderer. Will approve or disapprove all PLI
   * */
  const toggleApproveAll = (
    checked,
    api,
    serviceId,
    optionIndex,
    elevationIndex
  ) => {
    if (!isWritable) return message.error("Error");
    let tempData = _.cloneDeep(gridData);
    const elevation = tempData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    ).serviceOptions[optionIndex][elevationIndex];
    let anyRowWithInvalidData = false;
    for (let item of elevation.items) {
      if (item?.addons?.length > 0) {
        for (let addon of item.addons) {
          addon.approved = checked;
        }
      }
      if (checkIfRowHasValidData(item)) {
        item.approved = checked;
      } else if (checked === true) anyRowWithInvalidData = true;
    }
    api.setRowData(elevation.items);
    api.refreshCells();
    updateStateAndSave(tempData);

    if (anyRowWithInvalidData === true) {
      //set a warning oif we have rows without valid data
      message.warning(
        "Some rows don't have valid data and they were not marked as approved"
      );
    }
  };

  const handleChange = (item) => {
    item = item === null ? [] : item;
    const arr = [];
    for (let i = 0; i < item.length; i++) {
      const element = test?.find((row) => row.name === item[i].value);
      arr.push(element);
    }
    changeSavedData({ key: "addonsRowData", value: arr });
  };

  const handleChangePricingData = (serviceId, newObj) => {
    let shallowPricingData = structuredClone(savedData.pricingData).slice();
    shallowPricingData = shallowPricingData.map((item) =>
      item.serviceId == serviceId
        ? {
            ...item,
            pricingObject: {
              ...item.pricingObject,
              addonsList: item.pricingObject.addonsList.map((item) =>
                item.id === newObj.id ? newObj : item
              ),
            },
          }
        : item
    );
    changeSavedData({ key: "pricingData", value: shallowPricingData });
  };

  useEffect(() => {
    if (activePriceSheetModal !== false) {
      console.log("activePriceSheetModal", activePriceSheetModal);
      setPriceSchemesData((prev) => ({
        ...prev,
        activePriceSchemes:
          pricingData.find(
            (s) => s.serviceId.toString() === activePriceSheetModal.toString()
          ).pricingObject?.priceSchemes || [], //if no pricingObject available, return [],
        selectedPriceScheme:
          selectedPriceSchemesForService[activePriceSheetModal]?.priceScheme,
        priceSchemesType:
          selectedPriceSchemesForService[activePriceSheetModal]
            ?.priceSchemesType,
      }));
    }
  }, [activePriceSheetModal]);

  useEffect(() => {
    const tempRights = userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Estimations/View")
      ?.children.find(({ title }) => title === "Take-Off") || { children: [] };

    console.log("tempRights", tempRights);
    if (tempRights) stateSetter({ accessRights: tempRights });

    // onGridReady();
    return () => {
      // Previous Component Will Unmount
      document.querySelectorAll(".priceCalculation").forEach((element) => {
        element.removeEventListener("keypress", handleNumbersOnly);
      });
    };
  }, [userConfiguration, authenticatedUser]);

  useLayoutEffect(() => {
    if (!dataRendered) {
      initGrid();
      setDataRendered(true);
    }
  }, []);

  /// logic for undo & redo

  useEffect(() => {
    if (gridData) changeStateHandler(JSON.parse(JSON.stringify(gridData)));
  }, [gridData]);

  //update states for every undo/redo click
  useEffect(() => {
    if (newGridData) {
      setTakeOffTableData(JSON.parse(JSON.stringify(newGridData)));
    }
  }, [newGridData]);

  console.log("priceSchemesData", priceSchemesData);

  const price_sheet_modal = (
    <PriceSchemeModal
      priceSchemesData={priceSchemesData}
      priceSchemes={priceSchemesData.activePriceSchemes}
      activePriceSheetModal={savedData.activePriceSheetModal}
      priceSchemesType={priceSchemesData.priceSchemesType}
      selectedPriceScheme={priceSchemesData.selectedPriceScheme}
      onPriceSchemeModalClose={onPriceSchemeModalClose}
      serviceLabel={
        savedData.ServiceNameByIndex[savedData.activePriceSheetModal]
      }
      handleSave={handleSave}
    />
  );

  console.log("showIndicators", showIndicators);

  return (
    <TakeOffContext.Provider
      value={{
        // gridData: gridData,
        isWritable,
        pricingData,
        selectedPriceSchemesForService,
        setState: stateSetter,
        updateStateAndSave,
        agGridTheme,
      }}
    >
      <div>
        {true && <strong></strong>}
        <div></div>
      </div>
      <div
        className="allTakeOff"
        style={{
          opacity: "0.99",
        }}
      >
        {/* <Modal
          open={loading}
          centered
          footer={null}
          className="loading-indic-takeoff"
        >
          <div className="takeOffContainer">
            Hold on tight while we retrieve your data...
          </div>
        </Modal> */}
        {/* {loading && <LoadingDiamonds />} */}
        {/* <img src={loadingIcon} alt="loading..." /> */}
        {/* {loading && <img src={loading} alt="loading..." />} */}
        {/* <ClassRedux
          {...{
            varName: "authenticatedUser",
            stateName: "authenticatedUser",
            setState: (authenticatedUser) => stateSetter({ authenticatedUser }),
          }}
        /> */}
        {/* <ClassRedux
          {...{
            varName: "userConfiguration",
            stateName: "userConfig",
            setState: (userConfiguration) => stateSetter({ userConfiguration }),
          }}
        /> */}
        {savedData.errorMessages.map((e, i) => (
          <Alert key={i} message={e} banner />
        ))}
        {gridData && serviceDefs && (
          <EstimationSecondaryHeader
            canUndo={canUndo}
            canRedo={canRedo}
            myMappedData={myMappedData}
            undoClickHandler={undoClickHandler}
            redoClickHandler={redoClickHandler}
            editLogs={
              savedData.estimation?.estimationEditLogs
                ? savedData.estimation.estimationEditLogs
                : []
            }
            createdBy={savedData.estimation?.createdBy}
            projectAddress={savedData.estimation?.jobSiteAddress}
            project_OR_opportunity={savedData.project_OR_opportunity}
            accountName={
              savedData.project?.accountName ||
              savedData.opportunity?.accountName
            }
            initGrid={initGrid}
            AutoSave={savedData.AutoSave}
            ServiceMenuContainerShow={savedData.ServiceMenuContainerShow}
            loading={savedData.loading}
            handleSaveProject={(params) => {
              stateSetter({ saveInProjectModal: true });

              // if (estimationStatus !== "Approved") {
              //   stateSetter({ saveInProjectModal: true });
              // } else {
              //   handleSaveProject(params);
              // }
            }}
            agGridTheme={agGridTheme}
            setTheme={setTheme}
            estDescription={estimation?.estimationDescription}
            saveEstimationDescription={saveEstimationDescription}
            {...{
              serviceDefs,
              // isWritable,
              gridData,
              stateSetter,
              handleSave,
              accessRights,
              estimation: estimation,
              versionId: versionId,
            }}
          />
        )}
        {gridData === false ? (
          <div style={{ height: "calc(100vh - 80px)", position: "relative" }}>
            <CircleSpinner
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginLeft: "-100px",
                marginTop: "-100px",
              }}
            />
          </div>
        ) : (
          <Row
            style={{
              height: "100%",
              flexWrap: "nowrap",
              gap: 10,
            }}
          >
            <div
              className={"serviceMenuWrapper " + darkMode ? "dark" : ""}
              {...(showIndicators?.ServiceMenuContainerShow
                ? { xs: 10, sm: 8, md: 5, lg: 4, xl: 4 }
                : { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 })}
              style={{
                display: showIndicators?.ServiceMenuContainerShow
                  ? "block"
                  : "none",
              }}
            >
              <ServiceMenuContainer
                {...{
                  isWritable,
                  serviceDefs,
                  gridData,
                  handleSave,
                  ServicesIndex,
                  agGridApiForEachElevation,
                  accessRights,
                  record,
                  serviceDefinitions,
                  agGridTheme,
                  setViewActivePanels,
                  loading,
                  setLoading,
                  setIsWritable,
                  canAddOrRemoveServices,
                  canViewPrice,
                  pricingData,
                  savedData,
                }}
                activePanels={viewActivePanels?.map((el) =>
                  Array.isArray(el) ? el : []
                )}
                setState={stateSetter}
                serviceGridKey={savedData.serviceGridKey}
              />
            </div>
            <div
              className="fullServiceContainer"
              {...(showIndicators?.FullServiceContainerShow
                ? { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                : { xs: 14, sm: 16, md: 19, lg: 20, xl: 21 })}
            >
              {(() => {
                let allServicesInvisible = true,
                  allServicesInvisibleHTML = "";
                for (const service of gridData) {
                  if (service.visible) {
                    allServicesInvisible = false;
                    break;
                  }
                }
                if (allServicesInvisible) {
                  allServicesInvisibleHTML = (
                    <div className="emptyPlaceholder">
                      <Empty
                        image="https://img.icons8.com/ios/128/5b5b5b/file.png"
                        imageStyle={{ height: 128 }}
                        description={
                          <span>
                            No services selected. Get started by selecting one
                            of the services on the left.
                          </span>
                        }
                      />
                    </div>
                  );
                }
                return (
                  <div
                    className="expandedServicesContainer"
                    style={{
                      animation: "slideFromBotton 1.2s",
                    }}
                  >
                    {myMappedData.map((service, serviceIndex) => {
                      if (!service.visible) {
                        return <div key={service.serviceId} />;
                      } else {
                        if (
                          savedData.fullScreenService !== null &&
                          savedData.fullScreenService !== service.serviceId
                        ) {
                          return <div key={service.serviceId} />;
                        } else {
                          return (
                            <div
                              key={service.serviceId}
                              className="serviceContainer"
                              style={{
                                boxShadow: `0px 0px 10px 3px ${
                                  service?.borderColor || "#fff"
                                }`,
                                display: service.visible ? "block" : "none",
                                marginTop: 5,
                              }}
                            >
                              <ServiceHeader
                                {...{
                                  record,
                                  service,
                                  // isWritable,
                                  stateSetter,
                                  pricingData,
                                  priceEdit,
                                  priceView,
                                  selectedPriceSchemesForService,
                                  borderColor: service?.borderColor || "#fff",
                                  serviceIndex,
                                  // gridData,
                                  updateStateAndSave,
                                  priceSchemesData,
                                }}
                                handleSave={handleSave}
                                fullscreenizeService={fullscreenizeService}
                                fullScreenService={savedData.fullScreenService}
                                agGridTheme={agGridTheme}
                                deFullscreenize={deFullscreenize}
                                handleModifyOption={handleModifyOption}
                                collapseAllElevations={collapseAllElevations}
                                expandAllElevations={expandAllElevations}
                                handleRentPerServiceChange={
                                  handleRentPerServiceChange
                                }
                                handleAlternateRentPerServiceChange={
                                  handleAlternateRentPerServiceChange
                                }
                                handlePricePerUnitChange={
                                  handlePricePerUnitChange
                                }
                                handleDefaultPricePerUnitChange={
                                  handleDefaultPricePerUnitChange
                                }
                                onInputBlur={onInputBlur}
                              />

                              {!loading ? (
                                service.serviceOptions.map(
                                  (option, optionIndex) => {
                                    return (
                                      <div key={optionIndex}>
                                        {
                                          <div className="optionText">
                                            {optionIndex === 0
                                              ? "Primary"
                                              : "Alternate"}
                                            {getExtraInformation(
                                              service,
                                              optionIndex,
                                              gridData,
                                              updateStateAndSave,
                                              priceView
                                            )}
                                            <span
                                              style={{
                                                marginLeft: 10,
                                                paddingBottom: 10,
                                                fontSize: 14,
                                              }}
                                            >
                                              <OptionHeader
                                                serviceId={service.serviceId}
                                                {...{
                                                  priceView,
                                                  priceEdit,
                                                  option,
                                                  service,
                                                  optionIndex,
                                                }}
                                              />
                                            </span>
                                          </div>
                                        }
                                        {option.length === 0 ? (
                                          <div className="text-center">
                                            No elevations to show. Get started
                                            by clicking the "New Elevation"
                                            button below.
                                          </div>
                                        ) : (
                                          <>
                                            {/* <p>
                                              Epcot is a theme park at Walt
                                              Disney World Resort featuring
                                              exciting attractions,
                                              international pavilions,
                                              award-winning fireworks and
                                              seasonal special events.
                                            </p>
                                          </details> */}
                                            <Collapse
                                              destroyInactivePanel={true}
                                              forceRender={false}
                                              expandIcon={({ isActive }) => (
                                                <div
                                                  className="dropdownIconContainer"
                                                  onClick={
                                                    !isActive
                                                      ? handleCollapseChange
                                                      : null
                                                  }
                                                  style={{
                                                    // color: "#fff",
                                                    // fill: "#fff",
                                                    // backgroundColor: isActive
                                                    //   ? "#5b5b5b"
                                                    //   : "#1264A3",
                                                    // background: "red",
                                                    // width: "32px",
                                                    // height: "32px",
                                                    display: "flex",
                                                    height: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    // borderRadius: "50%",
                                                  }}
                                                >
                                                  {!isActive ? (
                                                    <CollapseIcon
                                                      style={{
                                                        transform:
                                                          "rotate(-90deg)",
                                                      }}
                                                    />
                                                  ) : (
                                                    <CollapseIcon />
                                                  )}
                                                </div>
                                              )}
                                              // expandIcon={
                                              //   <span>
                                              //     <ExpandMoreIcon />
                                              //   </span>
                                              // }
                                              // activeKey={
                                              //   viewActivePanels?.[
                                              //     serviceIndex
                                              //   ]?.[optionIndex] ?? []
                                              // }
                                              // onChange={(value) => {
                                              //   message.loading("Loading...", 0);
                                              //   handleCollapseChange(
                                              //     value,
                                              //     serviceIndex,
                                              //     optionIndex
                                              //   );
                                              //   message.destroy();
                                              // }}
                                            >
                                              {option.map(
                                                (elevation, elevationIndex) => {
                                                  return (
                                                    <Panel
                                                      key={
                                                        elevation.elevationId
                                                      }
                                                      style={{
                                                        paddingBottom: 10,
                                                      }}
                                                      header={
                                                        <div
                                                          onClick={(e) =>
                                                            e.stopPropagation()
                                                          }
                                                        >
                                                          <ElevationPanelHeader
                                                            {...{
                                                              elevation,
                                                              stateSetter,
                                                              handleElevationFieldChange,
                                                              serviceIndex,
                                                              optionIndex,
                                                              elevationIndex,
                                                              // isWritable,
                                                              gridData,
                                                              updateStateAndSave,
                                                              agGridApiForEachElevation,
                                                              selectedPriceSchemesForService,
                                                              priceView,
                                                              accessRights,
                                                            }}
                                                          />
                                                        </div>
                                                      } //end elevation panel header
                                                      extra={
                                                        <ElevationPanelExtra
                                                          {...{
                                                            service,
                                                            // isWritable,
                                                            gridData,
                                                            updateStateAndSave,
                                                            selectedPriceSchemesForService,
                                                            serviceIndex,
                                                            optionIndex,
                                                            elevationIndex,
                                                            agGridApiForEachElevation,
                                                            serviceDefs,
                                                            stateSetter,
                                                            handleSave,
                                                            priceView,
                                                            priceEdit,
                                                            accessRights,
                                                            forceLoad,
                                                          }}
                                                        />
                                                      }
                                                    >
                                                      <ElevationContent
                                                        {...{
                                                          service,
                                                          serviceIndex,
                                                          optionIndex,
                                                          elevationIndex,
                                                          elevation,
                                                          ServicesIndex,
                                                          loading,
                                                          accessRights,
                                                          unitMeasurement,
                                                          // darkMode,
                                                        }}
                                                        dataEntryGrid={{
                                                          state: {
                                                            ...savedData,
                                                          },
                                                          props: {
                                                            estimation,
                                                            estimationId,
                                                            jobSiteAddress,
                                                            isWritable,
                                                            saveEstimation,
                                                            saveProject,
                                                            saveServicesInOppHandler,
                                                            agGridTheme,
                                                            setTheme,
                                                            versionId,
                                                            serviceDefinitions,
                                                          },
                                                          frameworkComponents,
                                                          onGridReady,
                                                          onCellKeyPress,
                                                          addonsCellValueChanged,
                                                          onBottomAddonChange,
                                                          addonsCellEditingStarted,
                                                          handleAddonsGridReady,
                                                          changeStateHandler,
                                                          saveDataFromRTDEditor,
                                                          updateSWSPPU,
                                                          saveHoistAddonsData,
                                                          handleInputElementChange,
                                                          onAddonRichTextChange,
                                                          saveSidewalkShedAddonsData,
                                                          handleGridCellFocused,
                                                          toggleApproveAll,
                                                          handleChange,
                                                          updateStateAndSave,
                                                          stateSetter,
                                                          resizeGridRows,
                                                          handleSave,
                                                          getAddonsCaption,
                                                          cellEditingStarted,
                                                          cellEditingStopped,
                                                          appendPLIRow:
                                                            appendPLIRowHandle,
                                                          duplicatePLIRow:
                                                            duplicatePLIRowHandle,
                                                          removePLIRow:
                                                            removePLIRowHandle,

                                                          isWritable,
                                                        }}
                                                        optionIndex={
                                                          optionIndex
                                                        }
                                                        service={service}
                                                        serviceIndex={
                                                          serviceIndex
                                                        }
                                                        elevationIndex={
                                                          elevationIndex
                                                        }
                                                        elevation={elevation}
                                                        agGridTheme={
                                                          agGridTheme
                                                        }
                                                        gridData={gridData}
                                                        removePLIRow={
                                                          removePLIRowHandle
                                                        }
                                                      />
                                                    </Panel>
                                                  );
                                                }
                                              )}
                                            </Collapse>
                                          </>
                                        )}

                                        {!service.isScope && (
                                          <div className="text-center">
                                            <Button
                                              disabled={
                                                !isWritable ||
                                                !canAddOrRemoveElevations
                                              }
                                              style={{
                                                borderColor:
                                                  !canAddOrRemoveElevations &&
                                                  "red",
                                              }}
                                              className="appendElevation"
                                              onClick={() => {
                                                // service.serviceId != 9999 ?

                                                appendElevation(
                                                  serviceDefs,
                                                  gridData,
                                                  updateStateAndSave,
                                                  serviceIndex,
                                                  optionIndex
                                                );
                                                // : this.setState({
                                                //     scopeSelectorModalVisible: true,
                                                //   })
                                              }}
                                            >
                                              <div>
                                                <span>
                                                  {canAddOrRemoveElevations
                                                    ? "New Elevation"
                                                    : "No Clearance to Add Elevation"}
                                                </span>
                                                <PlusIcon
                                                  style={{
                                                    height: 14,
                                                    width: 14,
                                                  }}
                                                />
                                              </div>
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}

                              <>
                                <>
                                  {serviceDefinitions?.find(
                                    (sE) =>
                                      Number(sE?.serviceId) ===
                                      Number(service?.serviceId)
                                  )?.hasServiceAddons === true && (
                                    <>
                                      <ServiceAddon
                                        {...{
                                          service,
                                          serviceIndex,
                                          pricingData,
                                          getAddonsCaption,
                                          handleChangePricingData,
                                          gridData,
                                          updateStateAndSave,
                                          saveThisEstimation,
                                          unitMeasurement: Array.isArray(
                                            unitMeasurement
                                          )
                                            ? unitMeasurement?.filter(
                                                ({ typeOfWork, addonType }) =>
                                                  Number(typeOfWork) ===
                                                    service?.serviceID &&
                                                  addonType === "service addon"
                                              )
                                            : [],
                                        }}
                                        agGridTheme={agGridTheme}
                                      />
                                    </>
                                  )}{" "}
                                </>
                                {/* <>
                                  {
                                    // TODO: NO Documentation Logic yet 
                                  }{" "}
                                  {serviceDefinitions?.find(
                                    (sE) =>
                                      Number(sE?.serviceId) ===
                                      Number(service?.serviceId)
                                  )?.hasDocumentation === true && (
                                    <ServiceDocumentation
                                      {...{
                                        service,
                                        onServiceDocumentationChange,
                                        agGridTheme: agGridTheme,
                                      }}
                                    />
                                  )}
                                </> */}
                              </>
                              {canViewPrice && service?.isScope !== true && (
                                <RentalServiceCollapse
                                  {...{
                                    service,
                                    serviceIndex,
                                    // gridData,
                                    updateStateAndSave,
                                  }}
                                />
                              )}
                              <IncludesExcludes
                                {...{
                                  service,
                                  serviceIndex,
                                  // gridData,
                                  updateStateAndSave,
                                }}
                              />
                            </div>
                          );
                        }
                      }
                      //end of else form if (!service.visible)
                      /*end of this.state.gridData.map((service, serviceIndex)*/
                    })}
                    {allServicesInvisibleHTML}
                  </div>
                );
              })()}
            </div>
          </Row>
        )}
        {price_sheet_modal}

        <ConfirmationModal
          visible={savedData.saveInProjectModal}
          title="Confirmation Modal"
          onConfirm={() => handleSaveProject(true)}
          onCancel={() => stateSetter({ saveInProjectModal: false })}
          text={
            <span className="ModalConfirmation">
              <span className="ModalConfirmation">
                Please be cautious. This will overwrite the information of
                services in the project.
                {savedData?.estimation?.estSTATUS !== "Approved" && (
                  <>
                    <br />
                    It will also change the estimation status to approved.
                    <br />
                  </>
                )}{" "}
                Do you want to continue?
              </span>{" "}
            </span>
          }
          setVisible={() => stateSetter({ saveInProjectModal: false })}
        ></ConfirmationModal>
      </div>

      <ScopeSelectorModal
        visible={savedData.scopeSelectorModalVisible}
        setVisible={(val) => stateSetter({ scopeSelectorModalVisible: val })}
      />
    </TakeOffContext.Provider>
  );
}

export default React.memo(DataEntryGridView);
// const mapStateToProps = (state) => ({
//   serviceDefinitions: state.serviceDefinitions,
// });

// export default connect(mapStateToProps)(DataEntryGridView)

// export const attachPriceSchemes = (data, priceSchemes) => {
//   console.log("attachPriceSchemes", data, priceSchemes);
//   // Check if data is valid structurally & we can iterate
//   if (!Array.isArray(data) || !Array.isArray(priceSchemes)) return data;
//   for (const service of data) {
//     // Make sure service doesn't have a manually configured priceScheme.
//     if (!service?.priceScheme) {
//       const applicableScheme =
//         priceSchemes.find(
//           (ps) => ps?.serviceId?.toString() === service?.serviceId?.toString()
//         ) || false;
//       if (applicableScheme) {
//         // Check if the service has a priceSchemeType default configured.
//         const defaultPriceSchemeId =
//           applicableScheme?.pricingObject?.defaultPriceSchemeId;
//         if (!!defaultPriceSchemeId) {
//           // we now find the default price scheme
//           const defaultPriceScheme =
//             applicableScheme?.pricingObject?.priceSchemes?.find(
//               (scheme) =>
//                 scheme?.id?.toString() === defaultPriceSchemeId?.toString()
//             );
//           if (!!defaultPriceScheme) {
//             service.priceScheme = defaultPriceScheme;
//           }
//         }
//       }
//     }
//     // return service;
//   }
//   console.log("what i am returning", data);
//   return data;
// };

const getExtraInformation = (
  service,
  optionIndex,
  gridData,
  updateStateAndSave,
  priceView
) => {
  console.log("getExtraInformation", service);
  let averagePPU = [];
  service?.serviceOptions?.[optionIndex]?.forEach((elevation) => {
    let items = elevation?.items?.filter((item) => item?.approved) || [];
    items.forEach((item) => {
      averagePPU.push(item?.ppu);
    });
  });
  averagePPU = forceToNumber(
    averagePPU.reduce((a, b) => forceToNumber(a) + forceToNumber(b), 0) /
      averagePPU.length
  );

  if (service?.label?.toLowerCase().includes("scaffold")) {
    let temp = {
      Sqft: 0,
      "Full Deck": 0,
      "Bic Level": 0,
      "Bic Every Level": 0,
      "Full Deck Every Level": 0,
    };
    if (Array.isArray(service?.serviceOptions?.[optionIndex])) {
      for (const arr of service?.serviceOptions?.[optionIndex]) {
        if (Array.isArray(arr?.planks?.priceSheetDataSrc)) {
          arr?.planks?.priceSheetDataSrc?.forEach((plank) => {
            console.log("plank", plank);
            temp[plank?.dataKey] += forceToNumber(plank?.dataValue);
          });
        }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: 10,
          gap: 10,
        }}
      >
        <span>Planks Sqft {temp?.Sqft}</span>
        <span>Full Deck {temp?.["Full Deck"]}</span>
        <span>Bic Level {temp?.["Bic Level"]}</span>
        <span>Bic Every Level {temp?.["Bic Every Level"]}</span>
        {priceView && (
          <WithTooltip tooltipCategory="Take Off" tooltipKey="Avg PPU">
            <span>Avg PPU: {currencyFormater(averagePPU)}</span>
            {optionIndex !== 0 && (
              <RentalCalculator
                service={service}
                gridData={gridData}
                updateStateAndSave={updateStateAndSave}
                keyToSave={"alternativeRentalTerms"}
              />
            )}
          </WithTooltip>
        )}
      </div>
    );
  } else {
    return priceView ? (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: 10,
          gap: 10,
        }}
      >
        <WithTooltip tooltipCategory="Take Off" tooltipKey="Avg PPU">
          <span>Avg PPU: {currencyFormater(averagePPU)}</span>{" "}
          {optionIndex !== 0 && (
            <RentalCalculator
              service={service}
              gridData={gridData}
              updateStateAndSave={updateStateAndSave}
              keyToSave={"alternativeRentalTerms"}
            />
          )}
        </WithTooltip>
      </div>
    ) : (
      ""
    );
  }
};
