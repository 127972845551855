import AccountingWriteMode from "../../../../../../../../../commonComponents/AccountingWriteMode/AccountingWriteMode";
// import DarkModeToggle from "../../../../../../../../../commonComponents/DarkModeToggle/DarkModeToggle";
import { Close } from "../../../../../../../../DynamicView/src";
import "./MainHeader.scss";

// import { switcherHandler } from "../../headerFunction"
// import { useDispatchContext, useStateContext } from "../../../../Context/Context"

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRedux } from "../../../../../../../../hooks";
import { useEffect, useMemo, useState } from "react";
import { API } from "aws-amplify";
// import PreviousRental from "./PreviousRental/PreviousRental";
// import RentalLogs from "./RentalLogs/RentalLogs";
// import InvoiceModal from "../../../../../../../../Accounting/components/CreateInvoice/InvoiceModal";
// import InvoiceCreateAdapter from "../../../../../../../../Accounting/components/InvoiceCreateAdapter/InvoiceCreateAdapter";
import { QuestionCircleFilled } from "@ant-design/icons";
import { message, Tooltip, Tour } from "antd";
import {
  PlayVideoTutorial,
  findTutorialSteps,
} from "../../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { MondayButton } from "../../../../../../../../../commonComponents";
// import { updateDocumentTitle } from "../../../../../../../../../../utils";

const MainHeader = ({ closeHandler }) => {
  const [fakeRentals] = useRedux("fakeRentals");
  const [isWritable, setIsWritable] = useRedux("rentalsIsWritable");
  // const [darkMode, setDarkMode] = useRedux("rentalsDarkMode");
  const {
    isLoading = false,
    programFields = [],
    isDarkMode = true,
  } = useSelector((state) => ({
    isLoading: state?.rentals?.isLoading || false,
    // rentals: state.rentals,
    programFields: state?.programFields?.programFields || [],
    isDarkMode: state.darkMode.isDarkMode,
  }));
  // const { isLoading } = useSelector((state) => state?.rentals);
  const includedToApplication = !!fakeRentals?.includedTo;
  const { rentalsId } = useParams();
  const [applicationNo, setApplicationNo] = useState(null);
  // const { programFields } = useSelector((state) => state.programFields);
  // const { isDarkMode } = useSelector((state) => state.darkMode);
  // const { isWritable, darkMode, fakeRentals } = useStateContext()
  // const dispatchContext = useDispatchContext()

  const [tourOpen, setTourOpen] = useState(false);
  const [videoTutorialLink, setVideoTutorialLink] = useState(null);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const links = useMemo(
    () =>
      (Array.isArray(programFields) &&
        programFields
          ?.find((item) => item.fieldName === "Portal Video Tutorials")
          ?.fieldOptions.find(
            (item) => item.categoryName === "Project Management"
          )
          ?.subCategories[5]?.videos.filter((item, i) => i >= 9 && i <= 10)
          .map((item) => item)) ||
      [],
    [programFields]
  );

  //NOTE: unnecessary useEffect block
  // useEffect(() => {
  //   updateDocumentTitle({
  //     newTitle: `Rental: ${fakeRentals?.projectAddress} - No. ${fakeRentals?.rentalNumber}`,
  //   }); // set Title of Tab

  //   return () => {
  //     updateDocumentTitle(); // reset document title to "Lead Manager";
  //   };
  // }, [fakeRentals]);

  const retrieveReq = async () => {
    return await API.get(
      "applications",
      `/applications/${fakeRentals?.includedTo}`
    ).then((res) => {
      return res?.applicationNo;
    });
  };

  useEffect(() => {
    if (fakeRentals?.includedTo) {
      retrieveReq().then((res) => {
        setApplicationNo(res);
      });
    }
  }, [fakeRentals?.includedTo]);

  function stepsMapHelper(title) {
    const stepsMap = {
      "Auto Save": document.querySelector(".autoSaveContainer"),
      "Rental Status": document.querySelector(".createChargeContainer"),
      "Undo, Redo": document.querySelector(".undoRedoButtonsContainer"),
      "Rental Notes": document.querySelector(".notes-icon"),
      "Quick Save": document.querySelector("#quickSaveButton"),
      "More Actions": document.querySelector(".moreActionsBtn"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? "custom-tour-dark" : "custom-tour-light",
      };
    });
    return newSteps;
  }

  const tourSteps = [
    {
      title: "Rentals Tutorials",
      description: (
        <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
          {Array.isArray(links) &&
            links.map((link) => (
              <MondayButton
                {...{
                  className: "mondayButtonBlue guideButton",
                  onClick: () => {
                    setShowVideoTutorial(true);
                    setTourOpen(false);
                    setVideoTutorialLink(link.videoLink);
                  },
                  style: {
                    display: "flex",
                    justifyContent: " center",
                  },
                  Icon: <VideoTutorialIcon />,
                }}
              >
                {link.videoName}
              </MondayButton>
            ))}
        </div>
      ),
      className: isDarkMode ? "custom-tour-dark" : "custom-tour-light",
      placement: "center",
    },
    ...mapRefs(findTutorialSteps("rentalsView", programFields || [])),
  ];

  return (
    <div
      className={
        isDarkMode ? "rentalMainHeaderDarkMode" : "rentalMainHeaderLightMode"
      }
    >
      <div className="leftSideContainerRentalMainHeader">
        <div className="tittleContainer">
          Rental:{" "}
          {`${fakeRentals?.projectAddress} - No. ${fakeRentals?.rentalNumber}`}
        </div>
        <AccountingWriteMode
          {...{
            isWritable,
            recordId: rentalsId,
            disableSwitch: includedToApplication,
            disabilityReason: includedToApplication
              ? `This rental is already included in Requisition ${applicationNo}. You can't edit it.`
              : "",
            onSwitchFunction: setIsWritable,
            // switcherHandler({ setValue:setIsWritable  , checkValue: newValue, witchOne: "writeMode" }),
            darkMode: isDarkMode,
            projectId: fakeRentals?.projectId,
          }}
        />
        {/* <div className="darkModeContainer">
          <DarkModeToggle
            {...{
              onClickFunction: () => setDarkMode(!darkMode),
              // switcherHandler({
              //   dispatchContext,
              //   checkValue: !darkMode,
              //   witchOne: "darkMode",
              // }),
              darkMode,
            }}
          />
        </div>{" "} */}
        {/* <div className="darkModeContainer">
          <PreviousRental {...{ darkMode }} />
        </div> */}
        {/* <div className="darkModeContainer">
          <InvoiceCreateAdapter
            {...{
              projectId: fakeRentals?.projectId,
              invoiceCategory: "rentals",
            }}
          />
        </div> */}
        {/* <div className="darkModeContainer">
          <RentalLogs />
        </div> */}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Guide">
          <QuestionCircleFilled
            style={{ fontSize: 23, color: "#ffffff", cursor: "pointer" }}
            onClick={() => setTourOpen(true)}
          />
        </Tooltip>
        {!isLoading && (
          <div
            className="rightSideContainerRentalMainHeader"
            onClick={closeHandler}
          >
            <Close />
          </div>
        )}
      </div>
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Rentals Tutorial",
          }}
        />
      )}
    </div>
  );
};

export default MainHeader;
