import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import { formatCurrency } from "../../../../utils";
import { getAmountPerService } from "../../../addWidgetsModalData";
export const estimations = ({ tables, programFields }) => {
  return {
    engProgress: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: `${a?.engProgress || 0}%`,
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }), //engProgress
    archProgress: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: `${a?.archProgress || 0}%`,
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }), //archProgress
    estSTATUS: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: a?.estSTATUS,
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Estimations?.find(
                (b) =>
                  b?.statusName?.toLowerCase() === a?.estSTATUS?.toLowerCase()
              )?.statusColor || "#6D8299",
          id: a?.estimationId,
        };
      }),
    }), //estSTATUS
    archAssignedTo: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: a?.archAssignedTo,
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }), //archAssignedTo
    engAssignedTo: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: a?.engAssignedTo,
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }), //engAssignedTo
    statusByArch: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: a?.statusByArch,
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }), //statusByArch
    statusByEng: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: a?.statusByEng,
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }), //statusByEng

    projectExecutive: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: a?.projectExecutive,
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }),

    amount: () => ({
      statusData: tables?.Estimations?.map((a) => {
        return {
          label: a?.jobSiteAddress,
          value: formatCurrency(parseFloat(getTotalServicesPrice(a?.services))),
          color: "#ECDE7D",
          id: a?.estimationId,
        };
      }),
    }),

    amountForServices: () => ({
      statusData: Object.entries(getAmountPerService(tables?.Estimations)).map(
        ([label, value]) => ({
          label,
          value: formatCurrency(value),
          color: "#ECDE7D",
        })
      ),

      // statusData: tables?.Estimations?.flatMap((a) => a?.services)?.map(
      //   (service) => {
      //     console.log("service", service);
      //     return {
      //       label: service?.label,
      //       value: formatCurrency(parseFloat(getTotalServicesPrice([service]))),
      //       color: "#ECDE7D",
      //     };
      //   }
      // ),
    }),
  }; //Estimations
};
