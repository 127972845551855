import React, { useState } from "react";
import { useRedux } from "../../../../../hooks";
import { formatCurrency } from "../../../../../utils";
import { NewPayment } from "../../../../Tabs/Payments/components";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import MondayButton from "../../../../../../commonComponents/MondayButton/MondayButton";

const InvoiceBalance = () => {
  const [isWritable] = useRedux("invoiceIsWritable");
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const { totalDue } = useCreateInvoiceContext();

  const { projectData, invoiceData } = useInitialInvoiceContext();

  return (
    <div className="totalContainer">
      <div className="body">
        <div>
          <p>Balance Due</p>
          <h2>{formatCurrency(totalDue)}</h2>
          <MondayButton
            hasIcon={false}
            className="mondayButtonBlue"
            onClick={() => {
              setIsPaymentModalOpen(true);
            }}
            disabled={!totalDue}
          >
            Receive Payment
          </MondayButton>
        </div>
      </div>

      {isPaymentModalOpen && (
        <NewPayment
          {...{
            showCreatePaymentModal: isPaymentModalOpen,
            setShowCreatePaymentModal: setIsPaymentModalOpen,
            projectData,
            invoiceData,
          }}
        />
      )}
    </div>
  );
};

export default InvoiceBalance;
