export const opportunities = ({ tables, circleProgressPicker }) => {
  return {
    opportunityStage: () => ({
      percent: (
        (tables?.Opportunities?.filter((a) => a?.opportunityStage === circleProgressPicker)
          ?.length /
          tables?.Opportunities?.length) *
        100
      ).toFixed(1),
    }),
    opportunityStatus: () => ({
      percent: (
        (tables?.Opportunities?.filter((a) => a?.opportunityStatus === circleProgressPicker)
          ?.length /
          tables?.Opportunities?.length) *
        100
      ).toFixed(1),
      showInfo: true,
      data: tables?.Opportunities?.filter((a) => a?.opportunityStatus === circleProgressPicker)
        ?.length,
    }),
  } //opportunities
}
