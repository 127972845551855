import { Tooltip } from "antd";

import { useStateContext } from "../../../../../../../Context/Context";
import { formatNumberToPercentage } from "../../../../../../../../../../../utils";
import "./rate-cell-renderer.css";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

const RateCellRenderer = ({ value, serviceLabel, estimationId }) => {
  // Context state
  const { errors } = useStateContext();

  const hasSumOfRatesError =
    errors && errors[`${serviceLabel}/${estimationId}`]?.sumOfRates;

  return (
    <div className="rate-cell-renderer">
      <span className="rate-value">{formatNumberToPercentage(value)}</span>
      <div
        className="rate-status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {hasSumOfRatesError ? (
          <Tooltip title={hasSumOfRatesError}>
            <ExclamationCircleFilled style={{ color: "#FE4C4A" }} />
          </Tooltip>
        ) : (
          <CheckCircleFilled style={{ color: "#68C142" }} />
        )}
      </div>
    </div>
  );
};

export default RateCellRenderer;
