import {
  calculateTotalitiesTakeOff,
  takeOffsPrices,
} from "./myReducer/helpers";

const customReducer = (state = {}, action) => {
  if (action.type === "takeOffTableData") {
    return {
      ...state,
      takeOffFullPrice: takeOffsPrices(action.payload),
      [action.type]: fn(action.type, action.payload),
    };
  } else {
    return {
      ...state,
      [action.type]: fn(action.type, action.payload),
    };
  }
};

export default customReducer;

const fn = (type, payload) => {
  switch (type) {
    case "takeOffTableData":
      return calculateTotalitiesTakeOff(payload);

    default:
      return payload;
  }
};
