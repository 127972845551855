import { Tooltip } from "antd";

function filtersFieldsJSON({
  isDarkMode,
  filters,
  setFilters,
  allDepartments,
  allRoles,
}) {
  return [
    {
      type: "select",
      mode: "multiple",
      maxTagCount: 3,
      maxTagPlaceholder: (e) => {
        return (
          <Tooltip title={e.map(({ label }) => label).join(", ")}>
            <span>{`+ ${e.length} more`}</span>
          </Tooltip>
        );
      },
      placeholder: "Select Departments",
      label: "Departments",
      dropdownClassName: isDarkMode && "darkDateDropDown",
      defaultValue: filters?.departments || [],
      customOptions: allDepartments?.map(({ departmentName }) => ({
        label: departmentName,
        value: departmentName,
      })),
      onChange: (e) => {
        setFilters({
          ...filters,
          departments: e,
        });
      },
    },
    {
      type: "select",
      mode: "multiple",
      maxTagCount: 3,
      maxTagPlaceholder: (e) => {
        return (
          <Tooltip title={e.map(({ label }) => label).join(", ")}>
            <span>{`+ ${e.length} more`}</span>
          </Tooltip>
        );
      },
      placeholder: "Select Roles",
      label: "Roles",
      dropdownClassName: isDarkMode && "darkDateDropDown",
      defaultValue: filters?.roles || [],
      customOptions: allRoles,
      onChange: (e) => {
        setFilters({
          ...filters,
          roles: e,
        });
      },
    },
    {
      type: "select",
      placeholder: "Status...",
      label: "Status",
      dropdownClassName: isDarkMode && "darkDateDropDown",
      defaultValue: filters?.status,
      customOptions: ["Online", "Offline", "Logged Out"].map((status) => ({
        label: status,
        value: status,
      })),
      onChange: (e) => {
        setFilters({
          ...filters,
          status: e,
        });
      },
    },
  ];
}

export default filtersFieldsJSON;
