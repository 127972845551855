import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toCamelCase } from "../components/pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";

import {
  getNextColumn,
  parsedCell,
  getCellName,
  getIndexFromCell,
  getColumnFromCell,
} from "../components/pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";

dayjs.extend(customParseFormat);

export const EMPTY_ROW = {
  crewName: "",
  employeeId: "",
  crewPosition: "",
  crewStatus: "",
  employeeRate: "",
  foreman: false,
  salaryType: "hourly",
};

const defaultDegColumns = [
  "employee number",
  "employee list",
  "rate",
  "department",
  "role",
  "crew",
  "data 1",
];

/**
 * Function that parses the DEG sheet of an uploaded Excel
 * @param {Object} deg The DEG sheet object
 * @param {File} fileData Additional file data to be put in the row data
 */
function employeeUploadParser(deg = {}, fileData, accountName) {
  /**
   * The idea is to search for the index of the header by finding
   * a cell that has the content of a known header.
   *
   * We find the total number of columns by iterating the header index until
   * we have an empty cell
   *
   * We can find the total number of rows by looking at how many
   * cells have the same column name as the previously found header.
   *
   * Then we simply iterate all the data and turn the header names
   * in camel case to form the new row data
   */
  let headerRowIndex = -1;
  let numOfValidColumns = 0;
  let totalNumOfRows = 0;
  let headerStartColumn = "";
  let headerEndColumn = "";
  let nameCol = "";
  let parsedData = [];

  for (const cell in deg) {
    let cellContent = parsedCell(deg[cell]?.w);

    if (cellContent?.toLowerCase()?.includes("employee number")) {
      headerRowIndex = getIndexFromCell(cell);
      nameCol = getColumnFromCell(cell);
      break;
    }
  }

  //if the data is invalid and there is no header
  if (headerRowIndex === -1) {
    return parsedData;
  }

  //we get the start and end columns to create a range
  let lastCheckedColumn = "";
  while (!headerStartColumn) {
    let col = getNextColumn(lastCheckedColumn);
    let cellName = getCellName(col, headerRowIndex);

    //in order to get the column we can check the default columns or simply write
    //one of the columns that we know is present
    if (
      defaultDegColumns.includes(parsedCell(deg[cellName]?.w).toLowerCase())
      // parsedCell(deg[cellName]?.w) === "EE Code"
    ) {
      headerStartColumn = col;
      break;
    } else if (parsedCell(deg[cellName]?.w).toLowerCase() === "#value!") {
      headerStartColumn = col;
    } else {
      lastCheckedColumn = col;
    }
  }

  while (!headerEndColumn) {
    let col = getNextColumn(lastCheckedColumn);
    let cellName = getCellName(col, headerRowIndex);
    if (!parsedCell(deg[cellName]?.w)) {
      headerEndColumn = lastCheckedColumn;
      break;
    } else {
      ++numOfValidColumns;
      lastCheckedColumn = col;
    }
  }

  //we get the total number of rows by getting the maximum number of row that have a name
  //we check for the name field since the other ones may not be yet completed
  for (const cell in deg) {
    if (getColumnFromCell(cell) === nameCol) {
      ++totalNumOfRows;
    }
  }

  for (let i = 1; i <= totalNumOfRows; i++) {
    let tmpData = {};
    let rowIndexToCheck = headerRowIndex + i;
    let dataColumn = headerStartColumn;

    for (let j = 0; j < numOfValidColumns; j++) {
      let cellName = getCellName(dataColumn, rowIndexToCheck);
      let key = toCamelCase(
        parsedCell(
          deg[getCellName(dataColumn, headerRowIndex)]?.w
        )?.toLowerCase()
      );
      tmpData[key] = parsedCell(deg[cellName]?.w);
      dataColumn = getNextColumn(dataColumn);
    }

    parsedData.push({
      accountName,
      crewId: uuidv4(),
      crewName: `${tmpData["employeeList"]
        .split(",")[1]
        ?.replaceAll(" ", "")} ${tmpData["employeeList"]
        .split(",")[0]
        .replace(",", "")}`,
      crewPosition: tmpData["role"],
      crewStatus: "Active",
      employeeId: `${accountName}-${tmpData["employeeNumber"]}`,
      // fingerCheckId: Number,
      salaryType: "Hourly",
      employeeNumber: tmpData?.employeeNumber,
      employeeRate:
        parseFloat(tmpData["rate"].replaceAll("$", "").replaceAll(" ", "")) ||
        0,
      foreman:
        tmpData?.["role"]?.toLowerCase() === "foreman" || !!tmpData?.foreman,
      members: [],
      teamsConfiguration: [],
      crewTeam: tmpData["crew"],
      uploadId: fileData?.uid,
      uploadName: fileData?.name.replace(".xlsx", "")?.trim(),
    });
  }

  return {
    parsedData,
    // totalHoursPerEmployee,
  };
}

export default employeeUploadParser;
