import { Popover, Tooltip } from "antd";
import CustomProgressBar from "../../../../commonComponents/CustomProgress/CustomProgressBar";
import { typeOfWorkColors } from "../../../../Header/forms/Scheduling/DataAgGrid/columnDefs";
import "./ItemStatisticsView.scss";
import UndoProgressSchedule from "src/components/Header/forms/Scheduling/PLIPerService/UndoProgressSchedule/UndoProgressSchedule";
export const QuickStatisticsView = (props) => {
  const { scheduleTotalProgressPercentage = 0, typeOfWork = "" } = props;

  // i want progressScheduleStatisticsView animation to run each time the progress changes
  // so i use a key prop with the progress value

  return (
    <div
      key={scheduleTotalProgressPercentage}
      className="progressScheduleStatisticsView"
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
      }}
    >
      <Tooltip title={"The progress of this schedule"}>
        <span
          className="word"
          style={{
            fontSize: "1.5rem",
            fontWeight: "bolder",
            color: typeOfWorkColors?.[typeOfWork],
          }}
        >
          {(scheduleTotalProgressPercentage || 0) + "%" + " " + typeOfWork}
        </span>
      </Tooltip>
      {/* <UndoProgressSchedule
        {...{
          title: `Undo progress or all days for this schedule!`,
          // undoFunction: this.undoProgressService,
          needed: [],
        }}
      /> */}
    </div>
  );
};
export const getOtherSchedules = (allSchedules, thisSchedule) =>
  allSchedules?.filter((s) => thisSchedule?.scheduleId !== s.scheduleId);
