import { useSelector } from "react-redux";
import { iterateServicesInPLILevel } from "../../../helpers/iterators";
import { getAddonName } from "../../../../../../pages/Settings/settingsComponents/Pricing/utils/addonDataFunctions";
import { PliNoteViewer } from "./PliNoteViewer";
import { getItemDimensionsAsString } from "../../../helpers/parsers";
import "./PliNotes.scss";
/**
 * This component displays a list of all notes which comes from all items on this schedule.
 * You can edit PliNoteViewer component to edit view of a single note display.
 * */
export const PliNotes = ({ notes }) => {
  // console.log('notes :>> ', notes);
  let noteArrCopy = [...notes];
  let filteredNotes = noteArrCopy?.filter(
    (note) => typeof note?.note != "string"
  );

  // console.log('filteredNotes :>> ', filteredNotes);
  return (
    <div className={"pli-notes"}>
      {filteredNotes?.map(({ indexes, note }) =>
        typeof none === "string" ? "" : <PliNoteViewer {...{ indexes, note }} />
      )}
    </div>
  );
};

/**
 * This function is used to fetch recursively notes from items, be them pli, addon or breakdown.
 * @param pli {Object|undefined} - a pli, addon or breakdown
 * @param serviceAddon {Object|undefined} - an addon in service level. pli or serviceAddon is used not both of them.
 * @param variablesNeeded {{notes: [], indexes: Object}} - may hold whatever property, but in this case notes and indexes are important
 *
 * @return {{notes: [], indexes: Object}} - notes filled with a new note from item if it has something written in it
 * */
export const getNoteFromItem = ({ pli, serviceAddon, variablesNeeded }) => {
  let { notes, indexes } = variablesNeeded;

  let dimensionsString = getItemDimensionsAsString(pli, true);

  if (serviceAddon) {
    if (dimensionsString !== "")
      indexes = { ...indexes, serviceAddonId: dimensionsString };
    pli = serviceAddon;
  } else {
    if (dimensionsString !== "") indexes = { ...indexes };
  }

  //     if (serviceAddon) {  //For removig notes comming from DEG, replace the condition above
  //          return
  //     }

  if (pli?.addons) {
    for (let a = 0; a < pli?.addons?.length; a++) {
      variablesNeeded.indexes = {
        ...indexes,
        pliAddonId: (
          <span>
            {getAddonName(pli?.addons?.[a])} Dimensions:{" "}
            {getItemDimensionsAsString(pli?.addons?.[a], true)}
          </span>
        ),
      };
      getNoteFromItem({ pli: pli?.addons?.[a], variablesNeeded });
    }
  }
  if (pli?.breakdownValue) {
    for (let b = 0; b < pli?.breakdownValue?.length; b++) {
      variablesNeeded.indexes = {
        ...indexes,
        breakdownName: pli?.breakdownValue?.[b]?.name,
      };
      getNoteFromItem({ pli: pli?.breakdownValue?.[b], variablesNeeded });
    }
  }

  notes.push({ indexes, note: pli?.note });
};
