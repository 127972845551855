import "./styles/TaskCommentsInEdit.scss";
import { useSelector } from "react-redux";
import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";
import { API } from "aws-amplify";
import { message } from "antd";
import { CommentsContainer } from "src/components/commonComponents/Comment";

export const TaskCommentsInEdit = ({
  selectedTask,
  updatedTask,
  setUpdatedTask,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const getAssignedUsers = () => {
    const {
      taskAssignedTo = [],
      createdBy,
      createdByCognito,
    } = selectedTask || {};

    const isAuthorAssigned = taskAssignedTo.some((user) =>
      !!createdByCognito
        ? user.cognitoUserId === createdByCognito
        : user.nameOfUser === createdBy
    );

    return [
      ...taskAssignedTo,
      // include Author of tasks in comments to mention it
      ...(isAuthorAssigned
        ? []
        : [
            userConfiguration.allUsers.Items.find(
              ({ cognitoUserId, nameOfUser }) =>
                !!createdByCognito
                  ? cognitoUserId === createdByCognito
                  : nameOfUser === createdBy
            ),
          ]),
    ]
      .filter((user) => {
        //avoid me
        return (
          !!user?.cognitoUserId &&
          user.cognitoUserId !== userConfiguration.cognitoUserId
        );
      })
      .map((user, i) => ({
        id: user.cognitoUserId,
        value: user.nameOfUser,
        key: i,
      }));
  };

  const mentionNotification = ({ mentions }) => {
    const goToLink =
      updatedTask.taskTopic.toLowerCase() === "general"
        ? window.location.pathname.substring(1)
        : `${updatedTask.taskTopic.toLowerCase().replace(/\s/g, "")}/${
            updatedTask.taskTopicId
          }`;

    if (mentions?.length > 0) {
      broadcastNotification(
        "10",
        "onCommentMention",
        [
          {
            common: userConfiguration?.nameOfUser,
            commonNext: updatedTask?.taskTopic,
            commonName: selectedTask?.taskTitle,
            user: updatedTask?.taskRelatedTo,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
            cognitos: mentions.map(({ cognitoUserId }) => cognitoUserId),
            tab: "Tasks",
            selectedId: updatedTask.taskId,
            rowSelected: updatedTask,
          },
        ],
        goToLink
      );
    }
  };

  const usersWithAccess = getAssignedUsers();

  const commentsChanged = (comments) => {
    API.put("tasksManagement", `/tasksManagement/${updatedTask.taskId}`, {
      body: { taskThread: comments },
    })
      .then(() => {
        message.success("Comments Updated Successfully");
        setUpdatedTask((prev) => ({ ...prev, taskThread: comments }));
      })
      .catch((err) => console.log({ err }));
  };

  return (
    <div className="TaskCommentsInEdit">
      <div className="detailsModal__box-title">
        <span>Comments ({updatedTask?.taskThread?.length})</span>
      </div>
      <CommentsContainer
        {...{
          category: "Tasks",
          recordName: selectedTask?.taskTitle,
          comments: updatedTask?.taskThread,
          commentsChanged,
          usersWithAccess,
          customMentionNotification: mentionNotification,
        }}
      />
    </div>
  );
};
