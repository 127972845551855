import dayjs from "dayjs"

export const violations = (updatedDynamicStates, IdHandler) => {
  return {
    amount_due: () => ({
      title: "Violation Amount",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Violations",
        field: "amount_due",
        secondaryField: "violationLocation",
        id: IdHandler,
        statusData: updatedDynamicStates[IdHandler]?.map((a) => {
          return {
            label: a?.violationLocation,
            value: a?.fine_amount,
            color: "#ECDE7D",
          }
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //amount_due
    issue_date: () => ({
      title: "Violation Issue Date",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Violations",
        field: "issue_date",
        secondaryField: "violationLocation",
        id: IdHandler,
        statusData: updatedDynamicStates[IdHandler]?.map((a) => {
          return {
            label: a?.violationLocation,
            value: dayjs(a?.issue_date)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
          }
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //issue_date
  } //Violations
}
