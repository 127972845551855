import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

import { FleetInspectionType, StoreType } from "../../types";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import SmallInfoCard from "../SmallInfoCard/SmallInfoCard";
import { MondayButton } from "src/components/commonComponents";
import SmallVehicleInformation from "../SmallVehicleInformation/SmallVehicleInformation";
import { GoTasks } from "src/icons";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "./InspectionInfoViewModal.scss";

type InfoProps = {
  open: boolean;
  onCancel: () => any;
  inspection: FleetInspectionType;
};

function InspectionInfoViewModal({ open, onCancel, inspection }: InfoProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const navigate = useNavigate();

  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        footer: (
          <>
            {/** @ts-ignore */}
            <MondayButton
              {...{
                Icon: <GoTasks />,
                className: "mondayButtonBlue",
                onClick() {
                  navigate(`/fleetInspections/${inspection.inspectionId}`);
                },
              }}
            >
              Open Inspection
            </MondayButton>
          </>
        ),
        closeIcon: <XIcon />,
        title: `Inspection - ${inspection.inspectionId}`,
        className: `maintenance-view-info-modal inspection-info ${
          isDarkMode ? "maintenance-info-dark" : ""
        }`,
      }}
    >
      <SmallVehicleInformation fleetId={inspection.fleetId} />
      <SmallInfoCard
        {...{
          isDarkMode,
          title: "Inspection Information",
          data: [
            {
              title: "Inspection Type",
              value: inspection.inspectionType,
            },
            {
              title: "Mechanic",
              value: inspection?.mechanic ?? "No Assigned Mechanic",
            },
          ].concat(
            inspection.inspectionDetails.flatMap((detail) => {
              const t = structuredClone(detail);
              delete t["label"];

              const remainingKey = Object.keys(t)?.[0];

              if (!remainingKey) {
                return [];
              }

              return {
                title: detail.label,
                value: detail.label.includes("Date")
                  ? dayjsNY(detail[remainingKey]).format("MMM DD, YYYY")
                  : detail.label.includes("Time")
                  ? dayjsNY(detail[remainingKey]).format("hh:mm a")
                  : detail[remainingKey],
              };
            })
          ),
        }}
      />
      <SmallInfoCard
        {...{
          isDarkMode,
          title: "Defects",
          data: inspection.inspectionReport.flatMap((item) => {
            if (item.value === "Acceptable") {
              return [];
            }

            return {
              title: item.label,
              value: (
                <span className={`info-defect ${item.value}`}>
                  {item.value}
                </span>
              ),
            };
          }),
        }}
      />
    </Modal>
  );
}

export default InspectionInfoViewModal;
