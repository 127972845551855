import { Select } from "antd";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets/index";
import "./Dropdown.scss";
const { Option } = Select;

const FormItemDropdown = ({
  value,
  form,
  name,
  options = [],
  disabled,
  onChange,
  getTaxExept,
  placeholder = "",
  customOptions = [],
  isDarkMode,
}) => {
  return (
    <div className={`EditDropdown ${isDarkMode && "EditDropdownDark"}`}>
      <Select
        disabled={disabled}
        placeholder={placeholder}
        showSearch
        popupClassName={`EditDropDownClassName ${isDarkMode && "darkDropDown"}`}
        className="EditDropdownComponent"
        // popupMatchSelectWidth={false}
        popupMatchSelectWidth={false}
        value={
          customOptions.length !== 0
            ? value?.hasOwnProperty("email")
              ? value.email
              : value
            : value
        }
        suffixIcon={<DropDownArrow />}
        defaultValue={
          customOptions.length !== 0
            ? value?.hasOwnProperty("email")
              ? value.email
              : value
            : value
        }
        onChange={(e, data) => {
          form.setFieldsValue({
            [name]:
              customOptions.length !== 0
                ? value?.hasOwnProperty("email")
                  ? { email: data.label, id: e }
                  : [data]
                : e,
          });
          name === "taxExempt"
            ? getTaxExept(e)
            : onChange(
                customOptions.length !== 0
                  ? value?.hasOwnProperty("email")
                    ? { email: data.label, id: e }
                    : [data]
                  : e
              );
        }}
        {...(customOptions.length !== 0 ? { options: customOptions } : {})}
      >
        {options?.map((e, i) => {
          return typeof e === "object" ? (
            <Option key={i} value={e?.value}>
              {e?.label}
            </Option>
          ) : (
            <Option key={i} value={e}>
              {e}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
export default FormItemDropdown;
