import { findTutorialSteps } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";

function stepsMapHelper(title) {
  const obj = {
    "Hearing Statuses": ".formStepper",
    "Upload Files": ".docUploader",
    Details: ".detailsCard",
    Notes: ".notesCard",
    Save: ".saveButton",
    "Assign To": ".assignedToSelect",
  };
  return document.querySelector(obj[title]) || null;
}

function mapRefs(dbSteps = [], isDarkMode) {
  return dbSteps?.map((step) => ({
    ...step,
    target: () => stepsMapHelper(step?.title),
    className: !isDarkMode ? "custom-tour-light" : "custom-tour-dark",
  }));
}

function hearingGuideHelper({ programFields, isDarkMode }) {
  const steps = findTutorialSteps("hearingModal", programFields);
  return mapRefs(steps, isDarkMode);
}

export default hearingGuideHelper;
