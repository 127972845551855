export const FormGridStatusRenderer = ({ value, statuses }) => {
  try {
    const { color } = statuses.find(({ status }) =>
      value.toUpperCase().includes(status.toUpperCase())
    );

    return (
      <div className="statusCard" style={{ color: "#fff", background: color }}>
        {value}
      </div>
    );
  } catch (e) {
    return "";
  }
};
