import { dashboardLogic } from "../../../../Dashboard/logicData";

export function changeChartType(
  tables,
  programFields,
  card,
  userConfiguration
) {
  const data = dashboardLogic({
    preferenceDashData: [card],
    tables,
    programFields,
    url: "dashboard",
    userConfiguration,
  });

  if (!!data) {
    return data?.[0];
  } else {
    return card;
  }
}

export const chartBodyType = {
  PieChart: { type: "Pie", width: "390px" },
  BarChart: { type: "BarChart", width: "790px" },
  StraightProgressBar: { type: "StreightProgressBar", width: "390px" },
  LongLineSteper: { type: "LongLineSteper", width: "1600px" },
  Progress: { type: "Progress", width: "790px" },
  CircleProgressBar: { type: "CircleProgresBar", width: "390px" },
  StatusProgress: { type: "Progress", width: "390px" },
};

export const typeToRepetition = {
  StreightProgressBar: "Straight Progress Bar",
  BarChart: "Bar Chart",
  Pie: "Pie Chart",
  LongLineSteper: "Long Line Steper",
  CircleProgressBar: "Circle Progress Bar",
  Progress: "Status Progress",
};
