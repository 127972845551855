/**
 * * Formats object samples from {objectName, objectSample} to {name, data}
 *
 * @param {Array} objectSamples fetched object samples
 * @returns {Array} formatted object samples
 */
export const formatObjectSamples = (objectSamples = []) =>
  objectSamples.map(({ objectName, objectSample }) => ({
    name: objectName, //String
    data: objectSample, //Array containing only one object
  }))
