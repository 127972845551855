import React from "react";

import { WarningIcon } from "../../../../../icons";
import WarningModal from "../../../WarningModal/WarningModal";
import MondayButton from "../../../MondayButton/MondayButton";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";

const WARNING_TEXT = "Are you sure you want to continue?";

const Warning = ({ isOpen, setIsOpen, onSubmit }) => {
  return (
    <WarningModal
      visible={isOpen}
      setVisible={setIsOpen}
      title="Warning Message"
      closable={true}
      className="logout-warning-modal"
    >
      <div className="logout-modal-body">
        <span>
          <WarningIcon />
        </span>
        <p>{WARNING_TEXT}</p>
        <div className="buttons">
          <MondayButton
            onClick={() => setIsOpen(false)}
            Icon={<XIcon />}
            className="mondayButtonRed"
          >
            No
          </MondayButton>
          <MondayButton
            onClick={onSubmit}
            Icon={<TickIcon width={17} height={17} />}
          >
            Yes
          </MondayButton>
        </div>
      </div>
    </WarningModal>
  );
};

export default Warning;
