import { useMemo, useState } from "react";
import { HamburgerIcon, LogoutIcon } from "../../../../../../assets";
import { CoreHorizontal, CoreVertical } from "../../../assets/images";
import "./FieldMobileHeader.scss";
import { Drawer, Menu } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getMenuItems from "./getMenuItems";
import { LeftArrow } from "../../../../../SidebarPages/BasePage/src";
import { LogoutModal } from "../../../../../Header/components/Logout/LogoutModal";
import LanguageSelector from "../../../Language/LanguageSelector";
import useTranslate from "../../../Language/useTranslate";

function FieldMobileHeader() {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [openMenu, setOpenMenu] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslate();

  const menuItems = useMemo(
    () =>
      getMenuItems({
        setOpenMenu,
        navigate,
        t,
      }),
    [t]
  );
  function getSelectedKeys() {
    const path = window.location.pathname;
    const foundKey = menuItems?.find((item) => item?.key === path);
    if (foundKey) return [path];
    return [];
  }

  return (
    <div className="field-mobile-header">
      <HamburgerIcon
        onClick={() => setOpenMenu(true)}
        className="hamburger-icon"
      />
      <CoreHorizontal
        onClick={() => {
          navigate("/field");
        }}
      />
      {openMenu && (
        <Drawer
          {...{
            open: openMenu,
            onClose: () => setOpenMenu(false),
            placement: "left",
            closable: true,
            closeIcon: null,
            destroyOnClose: true,
            title: null,
            width: "65vw",
            className: "mobile-menu-drawer",
          }}
          data-testid="menu-drawer"
        >
          <div
            onClick={() => {
              setOpenMenu(false);
              navigate("/field");
            }}
            className="drawer-header"
          >
            <CoreVertical width={140} height={60} />
          </div>
          <div className="drawer-menu-body">
            <Menu
              defaultSelectedKeys={getSelectedKeys()}
              // defaultOpenKeys={['sub1']}
              mode="inline"
              style={{
                backgroundColor: "transparent ",
              }}
              // theme="dark"
              // inlineCollapsed={collapsed}
              items={menuItems}
            />
            <div
              className="drawer-footer logout"
              onClick={() => {
                setOpenLogout(true);
              }}
            >
              <LogoutIcon style={{ fill: "#fff", height: 18, width: 18 }} />{" "}
              {t("Logout")}
            </div>
            <div className="drawer-footer logout language">
              <LanguageSelector />
            </div>
            <div
              onClick={() => {
                setOpenMenu(false);
              }}
              className="drawer-footer"
            >
              <LeftArrow />
            </div>
          </div>
          {open && (
            <LogoutModal
              warningModalVisible={openLogout}
              setWarningModalVisible={setOpenLogout}
            />
          )}
        </Drawer>
      )}
    </div>
  );
}

export default FieldMobileHeader;
