/**
 * template of ag grid
 */
const template =
  '<div class="ag-cell-label-container" role="presentation">' +
  `  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
<g id="Group_3810" data-name="Group 3810" transform="translate(-595 -3821)">
  <path id="Path_6701" data-name="Path 6701" d="M4.2,5.249c1.8,2.312,4.46,5.732,4.46,5.732V14.86a1.556,1.556,0,0,0,1.551,1.551h0a1.556,1.556,0,0,0,1.551-1.551V10.981s2.661-3.421,4.46-5.732A.775.775,0,0,0,15.605,4H4.815A.774.774,0,0,0,4.2,5.249Z" transform="translate(595.962 3822)" fill="#323338"/>
  <g id="Rectangle_824" data-name="Rectangle 824" transform="translate(595 3821)" fill="none" stroke="#323338" stroke-width="1">
    <rect width="22" height="22" rx="5" stroke="none"/>
    <rect x="0.5" y="0.5" width="21" height="21" rx="4.5" fill="none"/>
  </g>
</g>
</svg></span>` +
  `   <span ref="eSortAsc" class="ag-header-icon "><svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
<path id="Path_8252" data-name="Path 8252" d="M9,3,5,6.99H8V14h2V6.99h3Z" transform="translate(-5 -3)" fill="#323338"/>
</svg></span>` +
  `   <span ref="eSortDesc" class="ag-header-icon "><svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
<path id="Path_8252" data-name="Path 8252" d="M16,17.01V10H14v7.01H11L15,21l4-3.99Z" transform="translate(-11 -10)" fill="#323338"/>
</svg></span>` +
  '  <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"><path id="Path_6699" data-name="Path 6699" d="M9,3,5,6.99H8V14h2V6.99h3Zm7,14.01V10H14v7.01H11L15,21l4-3.99Z" transform="translate(-5 -3)" fill="#323338"/> </svg></span>' +
  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
  '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
  "  </div>" +
  "</div>"
export default template
