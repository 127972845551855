import StyledButton from "../../components/StyleButton/StyledButton";
import _ from "lodash";
import "./ProgressBar.scss";
import { useEffect, useState } from "react";
import { Form, Slider } from "antd";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";

const ProgressBar = (prop) => {
  const { params, getEditedValue, setEditMode, form } = prop;
  let formList = {};

  const [values, setValues] = useState(params);
  //saved previus data
  const [prev, setPrev] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  useEffect(() => {
    setPrev(() => _.cloneDeep(prop));
  }, [prop]);

  ///  finished
  formList = {
    content: { [values.content.formItemName]: values.content.value },
  };

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      [params.content.formItemName]: params.content.value,
    });
    setValues({
      content: {
        formItemName: params.content.formItemName,
        value: form.getFieldsValue()[params.content.formItemName],
      },
    });
  }, [form]);

  return (
    <div className="LongTextBodyEdit">
      <Form form={form}>
        <Form.Item name={params.content.formItemName} noStyle />
      </Form>
      <div className="LongTextBodyElementContainer">
        <Slider
          min={0}
          max={100}
          step={10}
          marks={{
            0: "0",
            50: "50",
            100: "100",
          }}
          value={values.content.value ? values.content.value : 0}
          tooltip={{ formatter: (value) => `${value}%`, open: true }}
          onChange={(value) => {
            form.setFieldsValue({
              [params.content.formItemName]: value,
            });
            setValues({
              content: {
                formItemName: params.content.formItemName,
                value: form.getFieldValue(params.content.formItemName),
              },
            });
          }}
        />
      </div>
      <div className="confirmContainer">
        <MondayButton
          onClick={() => setCancelModalVisible(true)}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          onClick={(res) => {
            prop.params.content.value =
              form.getFieldsValue()[prop.params.content.formItemName];
            getEditedValue(
              form.getFieldsValue(),
              undefined,
              formList,
              prev,
              prop
            );
            setEditMode(false);
          }}
          className="confimrButton"
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default ProgressBar;
