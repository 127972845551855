/**
 * Function used to get actual statistics from vehicle trips
 * @param {Object} config
 * @param {any[]} config.allTrips
 * @param {any[]} config.allStops
 */
function getStatsFromTrips({ allTrips = [], allStops = [] }) {
  const stats = {
    milesDriven: 0,
    totalDriveTime: 0,
    totalStopDuration: 0,
    totalIdleDuration: 0,
    averageStopTime: 0,
    averageIdleTime: 0,
  };

  let stopCount = 0;
  let idleCount = 0;

  for (const trip of allTrips) {
    stats["milesDriven"] = stats["milesDriven"] + trip["distanceMiles"];
    stats["totalDriveTime"] = stats["totalDriveTime"] + trip["durationMinutes"];
  }

  for (const stop of allStops) {
    let key = "totalStopDuration";
    if (stop["stopType"]?.toLowerCase().includes("idl")) {
      ++idleCount;
      key = "totalIdleDuration";
    } else {
      ++stopCount;
    }

    stats[key] =
      stats[key] + ((stop["endDate"] - stop["beginDate"]) / 60000 || 0);
  }

  stats["averageStopTime"] = stats["totalStopDuration"] / stopCount || 0;
  stats["averageIdleTime"] = stats["totalIdleDuration"] / idleCount || 0;

  return stats;
}

export default getStatsFromTrips;
