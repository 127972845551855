import { Radio, Tooltip } from "antd";
import { DeleteIcon, GreenPin, PinIcon } from "../../../../../../assets";

const TemplateOption = ({
  index,
  filter,
  onUpdate,
  selectedDefault,
  addedItem,
  onDelete,
}) => {
  return (
    <div key={index} className="optionFilterContainer">
      <Radio value={filter} className="radioContainer" onClick={onUpdate}>
        <span
          className={
            selectedDefault === filter
              ? "radio-button-text__checked"
              : "radio-button-text"
          }
        >
          {filter}
          {selectedDefault === filter ? <GreenPin /> : <PinIcon />}
        </span>
      </Radio>
      <Tooltip
        title={
          addedItem && "You can't delete filter before saving new filters first"
        }
      >
        <span
          className="delete-icon-container"
          onClick={() => onDelete(filter)}
          style={{ cursor: addedItem ? "not-allowed" : "pointer" }}
        >
          <DeleteIcon />
        </span>
      </Tooltip>
    </div>
  );
};

export default TemplateOption;
