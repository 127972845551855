import ChargedIndicator from "./AgGridComponents/RentalDetails/Components/ChargedIndicator/ChargedIndicator";
import {
  formatCurrency,
  formatNumberToPercentage,
} from "../../../../../../../../../../utils";
import ApplyRentalCheckBox from "./AgGridComponents/ApplyRentalCheckBox/ApplyRentalCheckBox";
import LockRow from "./AgGridComponents/RentalDetails/Components/LockRow/LockRow";
import RentalDetails from "./AgGridComponents/RentalDetails/RentalDetails";
import RichTextModal from "./AgGridComponents/RichTextModal/RichTextModal";
import DateCollision from "./AgGridComponents/DateCollision/DateCollision";
import { EditIcon } from "../../../../../../../../../../DynamicView/src";
import DateInput from "./AgGridComponents/DateInput/DateInput";
import dayjs from "dayjs";
import moment from "moment";
import { appliedRentPliCalculator } from "../../RentalFooter/RentalBreakdownFooterFunctions";
import { Popover } from "antd";
import { multiply, divide } from "lodash";
import DateGaps from "./AgGridComponents/DateGaps/DateGaps";
import { capitalizeFirstLetter } from "../../../../../../../../../../Estimations/DataEntryGrid/tools/polyfillers";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { parseInTz } from "../../../../../../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { ScheduleFilled, ScheduleTwoTone } from "@ant-design/icons";
import ProgressTypeWrapper from "./AgGridComponents/ProgressType/ProgressTypeWrapper";
const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

export function getWeeksDiff(startDate, endDate) {
  // console.log("startdata", startDate, endDate);
  // const msInWeek = 1000 * 60 * 60 * 24 * 7;
  // return (Math.abs(endDate- startDate) / msInWeek).toFixed(1);
  if (!startDate || !endDate) return 0;
  const startDateTZ = parseInTz(startDate)?.valueOf();
  const endDateTZ = parseInTz(endDate)?.valueOf();
  let howManyDays = +dayjs
    ?.duration(
      parseInTz(startDateTZ)?.valueOf() - parseInTz(endDateTZ)?.valueOf()
    )
    ?.asDays()
    ?.toFixed(0);
  howManyDays = howManyDays + 1;
  let weeks = howManyDays / 7;

  return weeks.toFixed(1);
}

export const columnDefs = ({
  elevationData,
  programFields,
  selectedService,
  enableGapsFinder = false,
  enableDateCollisionFinder = false,
  details = false,
}) => {
  const serviceDimensions = programFields
    ?.find(({ fieldName }) => fieldName === "Mapping of Progress")
    ?.fieldOptions?.find(({ name }) => name === selectedService)?.dimensions;
  const estimationData = serviceDimensions?.map((data) => ({
    headerName: `${capitalizeFirstLetter(data.charAt(0))}`,
    field: data,
    editable: false,
    filter: "agTextColumnFilter",
    columnGroupShow: "open",
    cellRendererFramework: (params) => {
      if (!!params?.data?.totalProgress) {
        return (
          <div className="estimationCellsContainer">
            {params?.column?.colId === "length"
              ? Math.round(params.value * params?.data?.totalProgress)
              : Math.round(params.value)}
          </div>
        );
      } else return <></>;
    },
    minWidth: 100,
    flex: 5,
    headerTooltip: `${capitalizeFirstLetter(data)} from estimation`,
  }));

  const schedulingData = serviceDimensions?.map((data) => ({
    headerName: `${capitalizeFirstLetter(data.charAt(0))}`,
    field: `scheduling${capitalizeFirstLetter(data)}`,
    editable: false,
    filter: "agTextColumnFilter",
    columnGroupShow: "open",
    cellRendererFramework: ({ value }) => (
      <div className="schedulingCellsContainer">{Math.round(value || 0)}</div>
    ),
    minWidth: 100,
    flex: 5,
    headerTooltip: `${capitalizeFirstLetter(data)} from schedule`,
  }));

  return [
    {
      headerName: "Id",
      field: "id",
      filter: "agTextColumnFilter",
      editable: false,
      minWidth: 70,
      flex: 3.5,
    },
    {
      headerName: "Apply",
      field: "applied",
      filter: "agTextColumnFilter",
      editable: false,
      cellRendererFramework: (params) => (
        <div className="applyCheckboxParams">
          <ApplyRentalCheckBox
            {...{ pliData: params?.data, elevationData, whichOne: "pli" }}
          />
          Charge Rental
        </div>
      ),
      minWidth: 150,
      flex: 7.5,
    },
    {
      headerName: "Date Collisions",
      editable: false,
      hide: !enableDateCollisionFinder,
      cellRenderer: (params) => (
        <DateCollision
          {...{
            thisElevation: elevationData?.elevationId,
            thisPli: params?.data?.id,
          }}
        />
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Date Gaps",
      editable: false,
      hide: !enableGapsFinder,
      cellRenderer: (params) => (
        <DateGaps
          {...{
            thisElevation: elevationData?.elevationId,
            thisPli: params?.data?.id,
            type: "gap",
          }}
        />
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Scheduling Range",
      field: "schedulingEndDate",
      filter: "agTextColumnFilter",
      editable: false,
      cellRendererFramework: (gridParams) => {
        return (
          <DateInput
            {...{
              value: gridParams?.value,
              allRow: gridParams?.data,
              elevationData,
              changingKey: "startDate",
            }}
          />
        );
      },
      minWidth: 294,
      flex: 15,
    },
    {
      headerName: "# of Weeks",
      field: "numberOfWeeks",
      editable: false,
      valueGetter: (params) => {
        let endDate = !!params.data.rentalDetails.endDate
          ? moment(params.data.rentalDetails.endDate)
          : null;
        let startDate = !!params.data.rentalDetails.startDate
          ? moment(params.data.rentalDetails.startDate)
          : null;
        let numberOfWeeks = getWeeksDiff(endDate, startDate);
        return numberOfWeeks;
        // return <div className="rentalEditableCell">{numberOfWeeks.toString}</div>;
      },
      // cellRendererFramework: (params) => {
      // 	let endDate = !!params.data.rentalDetails.endDate
      // 		? moment(params.data.rentalDetails.endDate)
      // 		: null;
      // 	let startDate = !!params.data.rentalDetails.startDate
      // 		? moment(params.data.rentalDetails.startDate)
      // 		: null;
      // 	let numberOfWeeks = getWeeksDiff(endDate, startDate);
      // 	return <div className="rentalEditableCell">{numberOfWeeks}</div>;
      // },
      // editable: true,
      // cellEditor: "PeriodCellEditor",
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Details",
      field: "id",
      editable: false,
      hide: !details,
      filter: "agTextColumnFilter",
      cellRenderer: (gridParams) => {
        return (
          <RentalDetails {...{ elevationData, allRow: gridParams?.data }} />
        );
      },
      minWidth: 120,
      flex: 6,
    },
    {
      headerName: "Lock",
      field: "lock",
      editable: false,
      cellRendererFramework: (params) => {
        return (
          <LockRow
            {...{
              locked: !!params?.value,
              whichFunction: "pli",
              propsObject: {
                elevationData,
                params,
              },
            }}
          />
        );
      },
      minWidth: 90,
      flex: 4.5,
    },
    {
      headerName: "Applied Rent",
      field: "appliedAmount",
      editable: true,
      cellEditor: "PeriodCellEditor",
      // cellRenderer: (params) => {
      // 	return appliedRentPliCalculator({
      // 		rentalDetails: params?.data?.rentalDetails?.details || [],
      // 	});
      // },
      cellRendererFramework: (params) => {
        let amount = appliedRentPliCalculator({
          rentalDetails: params?.data?.rentalDetails?.details || [],
        });
        let endDate = !!params.data.rentalDetails.endDate
          ? moment(params.data.rentalDetails.endDate)
          : null;
        let startDate = !!params.data.rentalDetails.startDate
          ? moment(params.data.rentalDetails.startDate)
          : null;
        let numberOfWeeks = getWeeksDiff(endDate, startDate);
        let suggestion =
          (params?.data?.rent / 4) * numberOfWeeks ||
          (params?.data?.rentalsTotal / 4) * numberOfWeeks ||
          0;
        // const dailyAmount =
        // serviceData?.label !== "Hoist"
        // 	? +(pliData?.rent / (4 * 7))?.toFixed(2)
        // 	: +(pliData?.rentalsTotal / (4 * 7))?.toFixed(2);
        return (
          <Popover
            content={<span>{formatCurrency(suggestion)}</span>}
            title="Perfect distribution value (suggestion)"
          >
            <div className="rentalEditableCell">
              {" "}
              {formatCurrency(amount || 0)}
              <EditIcon />
            </div>
          </Popover>
        );
      },
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Tax Applicable",
      field: "projectTaxRate",
      editable: false,
      valueGetter: (params) => {
        let amount = appliedRentPliCalculator({
          rentalDetails: params?.data?.rentalDetails?.details || [],
        });
        return params?.data?.taxRate * amount || 0;
      },
      cellRendererFramework: ({ value }) => (
        <div className="rentalEditableCell">{formatCurrency(value || 0)}</div>
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Total Pay",
      field: "totalPay",
      editable: false,
      valueGetter: (params) => {
        let amount = appliedRentPliCalculator({
          rentalDetails: params?.data?.rentalDetails?.details || [],
        });
        return params?.data?.taxRate * amount + amount || amount;
      },
      cellRendererFramework: ({ value }) => (
        <div className="rentalEditableCell">{formatCurrency(value || 0)}</div>
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Retainage",
      field: "retainage",
      editable: false,
      valueGetter: (params) => {
        let amount = appliedRentPliCalculator({
          rentalDetails: params?.data?.rentalDetails?.details || [],
        });
        const total = params?.data?.taxRate * amount + amount || amount;
        let retainageTemp = params?.data?.retainage || 0;
        if (retainageTemp > 0) {
          return multiply(divide(retainageTemp, 100), total);
        } else return 0;
      },
      // valueGetter: (params) => {
      // 	console.log("params", params);
      // 	let retainageTemp = params?.data?.retainage || 0;
      // 	if (retainageTemp > 0) {
      // 		let TaxAm = params?.data?.taxRate * params?.data?.appliedAmount || 0;
      // 		let Amount = params?.data?.appliedAmount || 0;
      // 		return (retainageTemp / 100) * (TaxAm + Amount);
      // 	} else return 0;
      // },
      cellRendererFramework: ({ value }) => (
        <div className="rentalEditableCell">{formatCurrency(value || 0)}</div>
      ),
      minWidth: 160,
      flex: 8,
    },
    {
      headerName: "Progress",
      field: "totalProgress",
      cellEditor: "PeriodCellEditor",
      editable: true,
      cellRendererFramework: ({ value, data }) => (
        <div className="rentalEditableCell">
          {formatNumberToPercentage(data?.totalProgress * 100)}
          <EditIcon />
        </div>
      ),
      minWidth: 130,
      flex: 6.5,
    },
    {
      headerName: "Progress Type",
      // field: "charged",
      editable: false,
      cellRendererFramework: (params) => {
        console.log("paramsProgress", params);
        return (
          <ProgressTypeWrapper
            {...{
              comesFromScheduling: params?.data?.comesFromScheduling === true,
              elevationData,
              params,
              selectedService,
            }}
          />
          // <MondayButton
          //   className={
          //     params?.data?.comesFromScheduling === true
          //       ? "mondayButtonPrismarine"
          //       : "mondayButtonRed"
          //   }
          //   Icon={
          //     params?.data?.comesFromScheduling === true ? (
          //       <ScheduleFilled />
          //     ) : (
          //       <EditIcon />
          //     )
          //   }
          // >
          //   {params?.data?.comesFromScheduling === true
          //     ? "Scheduling"
          //     : "Manual"}
          // </MondayButton>
        );
      },
      minWidth: 220,
      flex: 6,
    },

    {
      headerName: "Charged",
      field: "charged",
      editable: false,
      cellRendererFramework: (params) => (
        <ChargedIndicator
          {...{
            thisElevation: elevationData?.elevationId,
            whichOne: "pli",
            params: { thisPli: params?.data?.id },
          }}
        />
      ),
      minWidth: 120,
      flex: 6,
    },
    {
      headerName: "Sched/Est Data",
      marryChildren: false,
      children: [
        ...(schedulingData || []),
        ...(estimationData || []),
        {
          headerName: "Max Weekly Amount",
          field: "maxWeeklyAmount",
          editable: false,
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          cellRenderer: ({ value }) => formatCurrency(+value || 0),
        },
        {
          headerName: "Description",
          field: "description",
          filter: "agTextColumnFilter",
          editable: false,
          cellRendererFramework: (gridParams) => {
            return (
              <RichTextModal
                {...{
                  title: "Description",
                  value: gridParams?.value || "",
                  buttonText: "Description",
                  saveAvailable: false,
                }}
              />
            );
          },
          minWidth: 160,
          flex: 8,
        },
        {
          headerName: "Notes",
          field: "note",
          editable: false,
          filter: "agTextColumnFilter",
          cellRendererFramework: (gridParams) => {
            return (
              <RichTextModal
                {...{
                  title: "Notes",
                  buttonText: "Notes",
                  saveAvailable: true,
                  value: gridParams?.value || "",
                  allRow: gridParams?.data,
                  elevationData,
                }}
              />
            );
          },
          minWidth: 130,
          flex: 6.5,
        },
      ],
    },
  ];
};
