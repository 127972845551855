import { AgGridReact } from "ag-grid-react";
import { Button, message, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useRedux } from "../../../../../../../../../hooks";
import ServiceRentHeader from "../../../../RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/ServiceRentHeader";
import RentalBreakdown from "../../../../RentalBreakdownList/RentalBreakdown/RentalBreakdown";
import { cloneDeep } from "lodash";
import { formatCurrency } from "../../../../../../../../../utils";
import moment from "moment-timezone";
import { generateDescription } from "../../../../../../../Charges/datas";
import RichTextModalEditor from "../../../../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor";
import { rentalDataExtractor } from "../../../../../../../Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/IncludeRentals/includeRentalsFunctions";
import { useStateContext } from "../../../../../../../Applications/ApplicationView/Context/Context";
import "./PreviousRental.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRentals } from "../../../../../../../../../../../actions/rentals";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { DeleteIcon } from "../../../../../../../../../DynamicView/src/index";
import { CloseIconModal } from "../../../../../../../../../../../assets/index";
import { exportRentalsToXLSX } from "./utils";
import { ExcelIcon } from "../../../../../../../../../Documentation/View/assets/index";
import { groupBy } from "lodash";
import { updateDocumentTitle } from "../../../../../../../../../../../utils";
import { SchedRentalTimeline } from "../SchedRentalTimeline/SchedRentalTimeline";
import { HistoryOutlined } from "@ant-design/icons";
import { XIcon } from "../../../../../../../../../Communication/assets";
function PreviousRental({
  darkMode,
  buttonName = "Rental History",
  projectId,
}) {
  const [selectedService] = useRedux("rentalsSelectedService");
  const [fakeRentals] = useRedux("fakeRentals");
  const [visible, setVisible] = useState(false);
  const { rentals } = useSelector((state) => state.rentals);
  const dispatch = useDispatch();

  const prevRental = useMemo(() => {
    if (Array.isArray(rentals)) {
      if (rentals.length > 0) {
        let sortedRentals = rentals.sort((a, b) => a?.createdAt - b?.createdAt);
        return sortedRentals;
        // console.log("sortedRentals", sortedRentals);
        // let currentIndex = sortedRentals.findIndex(
        //   (rental) => rental?.rentalId === fakeRentals?.rentalId
        // );
        // console.log("curr index", currentIndex);
        // if (currentIndex > 0) {
        //   return sortedRentals[currentIndex - 1];
        // } else {
        //   return false;
        // }
      } else return false;
    } else return false;
  }, [rentals, fakeRentals]);

  const viewPrev = () => {
    if (prevRental) setVisible(true);
    else message.error("No previous rental found");
  };

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  const rows = cloneDeep(
    !!prevRental
      ? Object.values(
          groupBy(
            prevRental
              ?.map((el) => {
                const extracted = rentalDataExtractor({ rental: el })?.filter(
                  (el) => el?.appliedAmount > 0
                );
                return extracted?.map((ex) => ({
                  ...ex,
                  rentalNumber: "Rental " + el?.rentalNumber,
                }));
              })
              ?.flatMap((el) => {
                console.log("prevELLLL", el);
                return el;
              }),
            "label"
          )
        ).flatMap((el) => el)
      : []
  );

  const handleExport = () => {
    exportRentalsToXLSX({
      projectName: fakeRentals?.projectAddress || "Rental",
      rentalData: rows,
    });
  };

  useEffect(() => {
    if (!!fakeRentals?.projectId) {
      dispatch(getRentals(fakeRentals?.projectId));
    }
  }, [fakeRentals?.projectId]);

  return (
    <div>
      <div style={{ display: "flex", gap: "10px" }}>
        {prevRental && (
          <MondayButton
            className="mondayButtonBlue"
            onClick={viewPrev}
            Icon={<HistoryOutlined />}
          >
            {buttonName}
          </MondayButton>
        )}
        <SchedRentalTimeline projectId={projectId} />
      </div>
      {prevRental && (
        <Modal
          title={`Previous Rental Info`}
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "Previous Rental Info" });
          }}
          open={visible}
          centered
          destroyOnClose
          width={"100%"}
          closeIcon={<XIcon />}
          footer={
            <div className="previousRentalInfoFooter">
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseIconModal />}
                onClick={closeModal}
              >
                Close
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                Icon={<ExcelIcon />}
                onClick={handleExport}
              >
                Export to Excel
              </MondayButton>
            </div>
          }
          className={
            darkMode
              ? "dark-mode-previous-rental"
              : "light-mode-previous-rental"
          }
          onCancel={closeModal}
        >
          {/* {fakeRentals?.services?.map((selectedServiceData) => {
					return (
						<div className="rentalBreakdownListContainer">
							<ServiceRentHeader {...{ selectedServiceData, type: "viewOnly" }} />
						</div>
					);
				})}{" "} */}
          <div
            className={
              darkMode
                ? "body agGridRentalsDetailsStyleDark ag-theme-alpine-dark dark-ak-theme"
                : "body agGridRentalsDetailsStyleLight ag-theme-alpine"
            }
          >
            <AgGridReact
              suppressSizeToFit={true}
              rowSelection="multiple"
              rowHeight={50}
              paginationPageSize={10}
              columnDefs={filteredColumnDefs}
              rowData={rows}
              ///
              statusBar={{
                statusPanels: [
                  { statusPanel: "agSelectedRowCountComponent", align: "left" },
                  { statusPanel: "agAggregationComponent", align: "left" },
                ],
              }}
              pivotPanelShow="always"
              enableRangeSelection={true}
              // animateRows={true}
              suppressDragLeaveHidesColumns={true}
              // allowDragFromColumnsToolPanel={true}
              rowGroupPanelShow="always"
              pagination={true}
              // animateRows
              // immutableData
              defaultColDef={{
                flex: 1,
                sortable: true,
                resizable: true,
                enablePivot: true,
                filter: true,
                enableColResize: true,
                enableRowGroup: true,
                // floatingFilter: true,
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default PreviousRental;

const filteredColumnDefs = [
  {
    headerName: "No.",
    cellRenderer: (params) => params?.rowIndex + 1,
    flex: 1,
  },
  {
    headerName: "Service",
    flex: 1,
    field: "label",
    cellStyle: {
      backgroundColor: "#2DA365",
      display: "flex",
      color: "#fff",
    },
  },
  {
    headerName: "Rental no",
    field: "rentalNumber",
    // cellRenderer: (params) => params?.rowIndex + 1,
    flex: 1,
  },

  {
    headerName: "Applied Rent",
    field: "appliedAmount",
    cellRenderer: ({ data }) => {
      const appliedAmount = data?.appliedAmount || 0;
      return !!data ? formatCurrency(appliedAmount) : "";
    },
    flex: 1,
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    cellRenderer: ({ data }) => {
      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount || 0;
        return formatCurrency(taxAmount);
      } else {
        return "";
      }
    },
    flex: 1,
  },
  {
    headerName: "Total Amount",
    field: "totalApplied",
    cellRenderer: ({ data }) => {
      console.log("data", data);
      if (!data) return "";
      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount;
        return formatCurrency(data?.appliedAmount + taxAmount);
      } else {
        return formatCurrency(data?.appliedAmount);
      }
    },
    flex: 1,
  },
  {
    headerName: "Weeks",
    field: "weeks",
    flex: 1,
    cellRenderer: ({ data }) => {
      if (!data) return "";
      if (!!data?.startDate && !!data?.endDate) {
        const msInWeek =
          moment(data.endDate).diff(moment(data.startDate), "days") + 1;
        let temp = (Math.abs(msInWeek) / 7).toFixed(1);
        return temp;
      } else {
        return "0";
      }
    },
  },
  {
    headerName: "Start Date",
    field: "startDate",
    flex: 1,
    cellRenderer: ({ value }) =>
      !!value ? moment(value)?.format("MM/DD/YYYY") : "--",
  },

  {
    headerName: "End Date",
    field: "endDate",
    flex: 1,
    cellRenderer: ({ value }) =>
      !!value ? moment(value)?.format("MM/DD/YYYY") : "--",
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    flex: 1,
    cellRenderer: ({ value }) => (!!value ? `${value * 100}%` : "--"),
  },
  {
    headerName: "Retainage %",
    field: "retainage",
    flex: 1,
    valueGetter: ({ data }) => {
      return data?.retainage;
      // let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
      // return totalities.retainageAmount || 0;
    },
    // cellRenderer: ({ data }) => {
    // 	let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
    // 	return formatCurrency(totalities.retainageAmount || 0);
    // },
  },
  {
    headerName: "Retainage Amount",
    field: "retainage",
    flex: 1,
    valueGetter: ({ data }) => {
      if (!data) return "";
      let tax = data?.taxRate || 0;
      if (data?.retainage > 0) {
        return (
          (data?.retainage / 100) *
          (data?.appliedAmount + data?.appliedAmount * tax)
        );
      } else {
        return 0;
      }
      // let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
      // return totalities.retainageAmount || 0;
    },
    cellRenderer: ({ value }) => {
      return formatCurrency(value || 0);
    },
  },
];
