import React, { useEffect } from "react";
import { Modal, Button } from "antd";
import { DropdownWrapper } from "..";
import "./NormalSizedModal.scss";
import { XIcon } from "../../../Communication/assets/index";
import { MondayButton } from "../../../../commonComponents";
import { Tick } from "../../../DynamicView/components/FilterView/FilterIcons";
import { useSelector } from "react-redux";
import { updateDocumentTitle } from "../../../../../utils";

const NormalSizedModal = (props) => {
  const {
    visible,
    setVisible,
    title,
    subtitle,
    children,
    darkMode,
    onCancel,
    centered,
    className = "",
    destroyOnClose = true,
    maskClosable = false,
    subtitleStyle,
    footer = [],
    newFooterDesign = [],
    style = {},
    closable = false,
    zIndex = 1000,
    footerModal,
    closeIcon = <XIcon />,
    customFooter = null,
    ...rest
  } = props;
  const { isDarkMode } = useSelector((state) => state.darkMode);

  useEffect(() => {
    visible && title && updateDocumentTitle({ newTitle: title }); // set Title of Tab

    return () => {
      visible && title && updateDocumentTitle(); // reset document title to "Lead Manager";
    };
  }, [visible, title]);

  return (
    <Modal
      wrapClassName={`normalSizedModal ${className} ${
        !!title ? "" : "noTitleNormalSizedModal"
      } ${(isDarkMode || darkMode) && "normalSizedModalDark"}`}
      open={visible}
      destroyOnClose={destroyOnClose}
      footer={footerModal || null}
      title={title}
      // afterOpenChange={(event) => {
      //   event && updateDocumentTitle({ newTitle: title });
      // }}
      centered={centered}
      keyboard
      closable={closable}
      maskClosable={maskClosable}
      onCancel={() => {
        // updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel ? onCancel() : setVisible(false);
      }}
      style={style}
      zIndex={zIndex}
      closeIcon={closeIcon}
      // newFooterDesign={newFooterDesign || null}
      {...rest}
    >
      <div
        className="subtitle"
        style={!subtitle ? { display: "none" } : { ...subtitleStyle }}
      >
        {subtitle}
      </div>
      {children}
      {(!!footer.length || customFooter) && (
        <div className="footerContainer">
          {!!customFooter && customFooter}
          {!customFooter &&
            footer
              ?.filter(Boolean)
              ?.map(
                (
                  {
                    text,
                    onClick,
                    primary,
                    cancel,
                    dropdownDisabled = false,
                    uppercase = false,
                    disabled = false,
                    danger = false,
                    options,
                    onOptionClick,
                    suffix,
                    className = "",
                  },
                  i
                ) =>
                  !options || !!dropdownDisabled ? (
                    <Button
                      key={i}
                      danger={danger}
                      onClick={() => {
                        onClick();
                        updateDocumentTitle(); // reset document title to "Lead Manager";
                      }}
                      disabled={disabled}
                      className={`${
                        disabled
                          ? "disabled"
                          : danger
                          ? ""
                          : primary
                          ? "primary"
                          : cancel
                          ? "cancel"
                          : "secondary"
                      }FooterButton ${className}`}
                      style={{
                        textTransform: uppercase ? "uppercase" : "capitalize",
                      }}
                    >
                      {text}
                    </Button>
                  ) : (
                    <DropdownWrapper
                      key={i}
                      visible={visible}
                      dropdownOptions={options}
                      onClick={(e) => {
                        onOptionClick(e);
                        updateDocumentTitle(); // reset document title to "Lead Manager";
                      }}
                    >
                      <Button
                        key={i}
                        danger={danger}
                        onClick={() => {
                          onClick();
                          updateDocumentTitle(); // reset document title to "Lead Manager";
                        }}
                        disabled={disabled}
                        className={`${
                          disabled
                            ? "disabled"
                            : danger
                            ? ""
                            : primary
                            ? "primary"
                            : cancel
                            ? "cancel"
                            : "secondary"
                        }FooterButton`}
                        style={{
                          textTransform: uppercase ? "uppercase" : "capitalize",
                        }}
                      >
                        {text}
                        {suffix}
                      </Button>
                    </DropdownWrapper>
                  )
              )}
        </div>
      )}
      {!!newFooterDesign.length && (
        <div className="newFooterContainer">
          {newFooterDesign
            ?.filter(Boolean)
            ?.map(
              (
                {
                  text,
                  onClick,
                  primary,
                  cancel,
                  dropdownDisabled = false,
                  uppercase = false,
                  disabled = false,
                  danger = false,
                  options,
                  onOptionClick,
                  suffix,
                  className = "",
                  btnIcon = false,
                  tooltipCategory,
                  tooltipKey,
                },
                i
              ) =>
                !options || !!dropdownDisabled ? (
                  <MondayButton
                    key={i}
                    danger={danger}
                    Icon={btnIcon ? btnIcon : suffix || <XIcon />}
                    tooltipCategory={tooltipCategory}
                    tooltipKey={tooltipKey}
                    onClick={() => {
                      onClick();
                      updateDocumentTitle(); // reset document title to "Lead Manager";
                    }}
                    disabled={disabled}
                    className={`${className}`}
                    style={{
                      textTransform: uppercase ? "uppercase" : "capitalize",
                    }}
                  >
                    {text}
                  </MondayButton>
                ) : (
                  <DropdownWrapper
                    key={i}
                    visible={visible}
                    dropdownOptions={options}
                    onClick={(e) => {
                      onOptionClick(e);
                      updateDocumentTitle(); // reset document title to "Lead Manager";
                    }}
                    isDarkMode={isDarkMode}
                  >
                    <MondayButton
                      key={i}
                      danger={danger}
                      onClick={() => {
                        onClick();
                        updateDocumentTitle(); // reset document title to "Lead Manager";
                      }}
                      tooltipCategory={tooltipCategory}
                      tooltipKey={tooltipKey}
                      disabled={disabled}
                      Icon={suffix}
                      className={`${className}`}
                      style={{
                        textTransform: uppercase ? "uppercase" : "capitalize",
                      }}
                    >
                      {text}
                    </MondayButton>
                  </DropdownWrapper>
                )
            )}
        </div>
      )}
    </Modal>
  );
};

export default NormalSizedModal;
