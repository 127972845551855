import { useMemo, useState } from "react";
import { scheduleDay } from "../../../../../SidebarPages/Scheduling/ScheduleChangesModal/Helpers/Types";
import { Modal, Tooltip } from "antd";
import { NormalSizedModal } from "../../../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../../../commonComponents";
import { LeftArrow } from "../../../../../SidebarPages/BasePage/src";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { FolderFileIcon } from "../../../../../../assets";
import { AllFolders, Folders } from "./helpers";
import { ServiceDocumentationView } from "./ServiceDocumentationView";
import "./ServiceDocumentation.scss";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

interface Props {
  allFolders: AllFolders;
  scheduleDays: scheduleDay[];
  updateGoogleDriveFolders: (folders: Folders, label: string) => void;
  label: string;
  serviceDays: string[];
  setAbleToSave: (ableToSave: boolean) => void;
  disabled?: boolean;
  isDarkMode: boolean;
}

export function ServiceDocumentation(props: Props) {
  const {
    allFolders,
    scheduleDays,
    updateGoogleDriveFolders,
    label,
    serviceDays,
    setAbleToSave,
    disabled = false,
    isDarkMode,
  } = props;

  const [showModal, setShowModal] = useState(false);

  //* Get the folders for the service
  const serviceFolders = allFolders?.[label];

  //* Get the schedules days that are selected in the service and are not canceled or postponed
  const serviceScheduleDays = useMemo(() => {
    return scheduleDays?.filter(
      ({ id, status }) =>
        serviceDays?.includes(id) &&
        status !== "Canceled" &&
        status !== "Postponed"
    );
  }, [serviceDays]);

  //* Get the number of folders that are in the service according to the days selected
  const nrOfFolders = Object.keys(serviceFolders || {}).filter((folderName) =>
    serviceScheduleDays?.find(
      ({ endDate }) => dayjsNY(endDate).format("MM/DD/YYYY") === folderName
    )
  ).length;

  return (
    <div>
      <Tooltip
        title={`${
          !!serviceScheduleDays?.length
            ? "Documentation"
            : "You need to add days to the service "
        } `}
      >
        <div
          className="serviceDocumentation"
          onClick={() => !!serviceScheduleDays?.length && setShowModal(true)}
          style={{
            cursor: !!serviceScheduleDays?.length ? "pointer" : "not-allowed",
          }}
        >
          {nrOfFolders}
        </div>
      </Tooltip>
      <NormalSizedModal
        open={showModal}
        setVisible={setShowModal}
        className={`serviceDocumentationModal ${
          isDarkMode && "serviceDocumentationModalDark"
        }`}
        closable={true}
        title={`${label} Documentation`}
        destroyOnClose={true}
        centered={true}
        maskClosable={true}
      >
        <ServiceDocumentationView
          {...{
            serviceFolders,
            serviceScheduleDays,
            updateGoogleDriveFolders,
            label,
            setAbleToSave,
            disabled,
          }}
        />
      </NormalSizedModal>
    </div>
  );
}
