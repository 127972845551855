export const addCreditHandlerHoist = ({
  fakeApplication,
  service,
  dispatchContext,
  elevation,
  creditAmount,
  eventName,
  note,
}) => {
  const newApplication = { ...fakeApplication };

  const newServices = newApplication.services.map((existingService) => {
    if (
      existingService.label === service.label &&
      existingService.estimationId === service.estimationId
    ) {
      const temp = existingService;
      temp.serviceOptions[0].forEach((option) => {
        if (option?.elevationLabel === elevation?.elevationLabel) {
          option.amounts.push({
            amount: -creditAmount,
            name: eventName,
            note: note,
            charged: false,
            balanceToFinish: -creditAmount,
            collectable_amount: 0,
            difference: 0,
            fromPrevious: 0,
            id: option?.amounts?.length,
            notes: null,
            percentageCompleated: 0,
            rate: 0,
            retainage: +option?.amounts[0]?.retainage || 0,
            retainagePercentage: option?.amounts[0]?.retainagePercentage || 0,
            serviceAddons: [],
            serviceOptions: [],
            taxAmount: 0,
            thisPeriod: 0,
            totalCompleated: 0,
            isCredit: true,
          });
        }
      });
      return temp;
    } else {
      return existingService;
    }
  });
  newApplication.services = newServices;
  console.log("newApplication", newApplication);
  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
};

export const addCreditHandler = ({
  fakeApplication,
  service,
  dispatchContext,
  creditAmount,
  eventName,
  note,
}) => {
  const newApplication = { ...fakeApplication };

  const newServices = newApplication.services.map((existingService) => {
    console.log("existingService", existingService);
    if (
      existingService.label === service.label &&
      existingService?.estimationId === service?.estimationId
    ) {
      return {
        ...existingService,
        amounts: [
          ...existingService.amounts,
          {
            amount: -creditAmount,
            name: eventName,
            note: note,
            charged: false,
            balanceToFinish: -creditAmount,
            collectable_amount: 0,
            difference: 0,
            fromPrevious: 0,
            id: existingService.amounts.length,
            notes: note,
            percentageCompleated: 0,
            rate: 0,
            retainage: +existingService?.amounts[0]?.retainage || 0,
            retainagePercentage:
              existingService?.amounts[0]?.retainagePercentage || 0,
            serviceAddons: [],
            serviceOptions: [],
            taxAmount: 0,
            thisPeriod: 0,
            totalCompleated: 0,
            isCredit: true,
          },
        ],
      };
    }
    return existingService;
  });
  newApplication.services = newServices;
  console.log("newApplication", newApplication);
  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
};
