import { Button, message, Modal } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MondayButton,
  Stepper,
} from "../../../../../../../../../../commonComponents";
import { DollarIcon } from "../../../../../../../../../Estimations/components/DataEntryGridModal/assets";
import PickRequisition from "./PickRequisition/PickRequisition";
import ServiceLevelCredit from "./ServiceLevelCredit/ServiceLevelCredit";
import "./RentalCredit.scss";
import { SaveIcon } from "../../../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../../../hooks";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../Context/Context";
import { useParams } from "react-router-dom";
import { updateApplication } from "../../../../../../../../../../../actions/applications";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useEditLogs } from "../../../../../../../../../../../hooks";
import { CloseIconModal } from "../../../../../../../../../../../assets";
import { API } from "aws-amplify";
import { set } from "lodash";
import { filterTables } from "../../../../../../../../../../../utils";

function RentalCredit({ rental }) {
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [creditedRequisition, setCreditedRequisition] = useState(null);
  const { applications } = useSelector((state) => state.applications);
  const { fakeApplication } = useStateContext();
  const [currentRental] = useRedux("currentRental", false, false); //To show the Description Modal
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { saveAddedLogs } = useEditLogs();
  const dispatchContext = useDispatchContext();

  const steps = useMemo(() => {
    return [
      {
        Comp: PickRequisition,
        title: "Pick Requisition",
        disabled: true,
        creditedRequisition,
        setCreditedRequisition,
        icon: null,
      },
      {
        Comp: ServiceLevelCredit,
        title: "Apply Service Level Credit",
        disabled: true,
        rental,
        icon: null,
        setVisible,
        creditedRequisition:
          applications?.find(
            (app) => app?.applicationId === creditedRequisition
          )?.applicationNo || "",
      },
    ];
  }, [creditedRequisition, applications, rental]);

  const onSaveChanges = () => {
    let prevRentals = fakeApplication?.includedRentals || [];
    if (prevRentals.length > 0) {
      let others = prevRentals.filter(
        (rental1) => rental1?.rentalId !== rental.rentalId
      );
      let curr = prevRentals.find(
        (rental2) => rental2?.rentalId === rental.rentalId
      );
      curr.services = currentRental || [];
      console.log("fakeApplication save", fakeApplication);
      let toSend = {
        ...fakeApplication,
        includedRentals: others?.length > 0 ? [...others, curr] : [curr],
      };

      dispatch(
        updateApplication(
          applicationId,
          { ...toSend },
          applications,
          saveAddedLogs
        )
      );
    }
  };

  const attachRentals = async () => {
    await filterTables("includedRentals", "applicationId", applicationId).then(
      (res) => {
        console.log("res", res);
        dispatchContext({ type: "ATTACH_RENTALS", payload: res });
      }
    );
  };

  const onRemoveRental = async () => {
    console.log("remove rental", rental);
    // firstly, we unchain from rentals table
    await API.put("rentals", `/rentals/${rental.rentalId}`, {
      body: { includedTo: "" },
    })
      .then((res) => {
        message.success("Rental unchained successfully");
      })
      .catch((err) => {
        message.error("Error unchaining rental");
      });
    // secondly we delete the record from includedRentals
    await API.del("includedRentals", `/includedRentals/${rental?.rentalsReqId}`)
      .then((res) => {
        message.success("Rental removed successfully");
      })
      .catch((err) => {
        message.error("Error removing rental");
      });
    // thirdly we update the application
    setTimeout(() => {
      attachRentals();
    }, 1000);
  };

  return (
    <div style={{ marginLeft: "10px" }}>
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <span className="add-cred-btn-holder">
          <MondayButton
            className="detailsBtn"
            onClick={() => {
              //TODO: Add Credit to Rental logic
              // message.info("You can't add credit to this rental.");
              setVisible(true);
              // message.info("Rental Credit Feature coming soon");
            }}
            Icon={<DollarIcon />}
          >
            Add Credit
          </MondayButton>
        </span>
        <span className="add-cred-btn-holder">
          <MondayButton
            className="mondayButtonRed"
            onClick={onRemoveRental}
            Icon={<CloseIconModal />}
          >
            Remove Rental
          </MondayButton>
        </span>
      </div>
      <Modal
        width={currentStep === 0 ? "50%" : "100%"}
        className="rentalCreditModalDark"
        onCancel={() => setVisible(false)}
        title="Choose Credit Details..."
        open={visible}
        closable={true}
        centered
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <div
            className="stepper-buttons"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: currentStep > 0 ? "space-between" : "flex-end",
            }}
          >
            {currentStep > 0 && (
              <MondayButton
                className="detailsBtn"
                Icon={<ArrowLeftOutlined />}
                onClick={() => setCurrentStep((prev) => prev - 1)}
              >
                Previous Step
              </MondayButton>
            )}
            {currentStep === 0 ? (
              <MondayButton
                Icon={<ArrowRightOutlined />}
                onClick={() => setCurrentStep((prev) => prev + 1)}
              >
                Next Step
              </MondayButton>
            ) : (
              <MondayButton Icon={<SaveIcon />} onClick={onSaveChanges}>
                Save Changes
              </MondayButton>
            )}
          </div>
        }
      >
        <div>
          <Stepper {...{ currentStep, setCurrentStep, steps }} />
        </div>
      </Modal>
    </div>
  );
}

export default RentalCredit;
