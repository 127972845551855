import { updateApplication } from "../../../../../../../../actions/applications";
import useStickyElement from "../../../../../../hooks/useStickyElement";
import ControlPanel from "./Components/ControlPanel/ControlPanel";
import MainHeader from "./Components/MainHeader/MainHeader";
import { useStateContext } from "../../Context/Context";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { MultiLevelTreeLogs } from "../../../../../../../commonComponents";
import { useEditLogs } from "../../../../../../../../hooks";
import { fetchAllData } from "../../../../../../../../utils";

const Header = ({
  undoClickHandler,
  redoClickHandler,
  canUndo,
  canRedo,
  closeModal,
}) => {
  const [logsVisible, setLogsVisible] = useState(false);
  const [editLogs, setEditLogs] = useState([]);
  const [currentApplication, setCurrentApplication] = useState({});
  const { isAutoSaving, isWritable, fakeApplication, appId } =
    useStateContext();
  const [headerRef, fakeHeaderRef] = useStickyElement();
  const { applicationId: currentApplicationId } = useParams();
  const { applications } = useSelector((state) => state.applications);

  const applicationId = currentApplicationId || appId;
  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  //last modified by changes every time we makes a put on db, for that reason if we put fakeApplication as dependency on uef it will make a look bcs it will always be different from the first one
  const { lastModifiedBy, ...rest } = fakeApplication || {};

  //this handle auto save
  useEffect(() => {
    if (isAutoSaving && isWritable) {
      dispatch(
        updateApplication(
          applicationId,
          fakeApplication,
          applications,
          saveAddedLogs
        )
      );
    }
  }, [JSON.stringify(rest)]);

  useEffect(() => {
    if (!!applications) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: applicationId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((r) => setEditLogs(r))
        .catch((e) => console.log("Error Getting Logs: ", e));
      setCurrentApplication(
        applications.find((el) => el.applicationId === applicationId)
      );
    }
  }, [applications]);

  return (
    <>
      <div ref={headerRef} className="applications-header">
        <MainHeader {...{ closeModal }} />
        <ControlPanel
          canUndo={canUndo}
          canRedo={canRedo}
          undoClickHandler={undoClickHandler}
          redoClickHandler={redoClickHandler}
          setLogsVisible={setLogsVisible}
        />
      </div>
      <div ref={fakeHeaderRef} />
      {/* <Drawer open={true} title="Options">
				<List
					// header={<div>Header</div>}
					// footer={<div>Footer</div>}
					bordered
					dataSource={[
						<div>
							<Button>Test</Button>
						</div>,
					]}
					renderItem={(item) => <List.Item>{item}</List.Item>}
				/>
			</Drawer> */}
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: editLogs,
            title: "Requisition History",
          }}
        />
      )}
    </>
  );
};

export default Header;
