import { ReactComponent as DarkHandleIcon } from "./DarkHandle.svg";

function DarkHandle({ innerRef, ...props }) {
  const { onMouseDown, onMouseUp, onTouchEnd } = props;
  return (
    <div
      ref={innerRef}
      className={`react-resizable-handle react-resizable-handle-${
        props?.["0"]
      }${props?.["1"] || ""}`}
      {...{ onMouseDown, onMouseUp, onTouchEnd }}
    >
      <DarkHandleIcon />
    </div>
  );
}

export default DarkHandle;
