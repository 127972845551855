const ESTIMATION_API_KEYS = {
  opportunities: [
    "opportunityId",
    "proposedTypeOfWork",
    "projectExecutive",
    "opportunityName",
    "opportunityAddress",
    "isChangeOrder",
    "opportunityStage",
    "accountId",
    "accountName",
    "teamsConfiguration",
    "binNumber",
    "leadId",
    "createdAt",
  ],
  projects: [
    "projectId",
    "projectName",
    "projectManager",
    "accountId",
    "accountName",
    "projectExecutive",
    "services",
    "opportunityId",
    "binNumber",
    "teamsConfiguration",
    "createdAt",
  ],
};

const PERMIT_DRAWING_API_KEYS = {
  projects: [
    "projectId",
    "projectName",
    "projectManager",
    "projectExecutive",
    "accountId",
    "accountName",
    "opportunityId",
    "services",
    "teamsConfiguration",
    "createdAt",
  ],
};

const SCHEDULING_API_KEYS = {
  projects: [
    "projectId",
    "projectName",
    "projectManager",
    "accountId",
    "accountName",
    "projectExecutive",
    "services",
    "teamsConfiguration",
    "createdAt",
  ],
};

const SCHEDULE_CHANGE_API_KEYS = {
  scheduling: [
    "accountId",
    "projectId",
    "scheduleAddress",
    "scheduleName",
    "typeOfWork",
    "scheduleDays",
    "toBeScheduled",
    "projectExecutive",
    "projectManagers",
    "teamsConfiguration",
    "createdAt",
    "scheduleId",
  ],
};

const DISPATCH_API_KEYS = {
  projects: ["projectId", "projectName", "createdAt"],
  scheduling: ["scheduleId", "scheduleAddress", "createdAt"],
};

const SAFETY_INSPECTION_API_KEYS = {
  safety: [
    "safetyApplicationId",
    "safetyName",
    "safetyApplicationStatus",
    "projectId",
    "accountId",
    "scheduleId",
    "teamsConfiguration",
    "createdAt",
  ],
};

const PROJECT_API_KEYS = {
  projects: ["projectId", "projectName", "projectAddress", "createdAt"],
  accounts: [
    "accountId",
    "accountName",
    "projectManager",
    "projectExecutive",
    "teamsConfiguration",
    "createdAt",
  ],
};

const CLAIM_API_KEYS = {
  safety: [
    "safetyApplicationId",
    "safetyName",
    "safetyApplicationStatus",
    "projectId",
    "accountId",
    "scheduleId",
    "teamsConfiguration",
    "createdAt",
  ],
};

const INCIDENT_API_KEYS = {
  scheduling: [
    "scheduleId",
    "projectId",
    "accountId",
    "projectManagers",
    "projectExecutive",
    "teamsConfiguration",
    "createdAt",
    "scheduleAddress",
    "scheduleName",
  ],

  projects: [
    "projectId",
    "accountId",
    "projectName",
    "projectManager",
    "projectExecutive",
    "teamsConfiguration",
    "createdAt",
  ],
};

const SERVICE_INSPECTION_API_KEYS = {
  projects: [
    "projectId",
    "projectName",
    "projectManager",
    "projectExecutive",
    "services",
    "teamsConfiguration",
    "createdAt",
    "accountId",
    "accountName",
  ],
};

const CHANGE_ORDER_API_KEYS = {
  projects: [
    "projectId",
    "projectName",
    "projectManager",
    "accountId",
    "accountName",
    "projectExecutive",
    "services",
    "teamsConfiguration",
    "googleDriveFolderIds",
    "taxRate",
    "taxExempt",
    "primaryContact",
    "insuranceRequirement",
    "laborRequirement",
    "contacts",
    "proposedStartDate",
    "createdAt",
  ],
};

const REQUEST_API_KEYS = {
  Estimation: ESTIMATION_API_KEYS || null,
  Project: PROJECT_API_KEYS,
  "Schedule Change": SCHEDULE_CHANGE_API_KEYS,
  "Service Inspection": SERVICE_INSPECTION_API_KEYS,
  "Permit Drawing": PERMIT_DRAWING_API_KEYS,
  Scheduling: SCHEDULING_API_KEYS,
  "Change Order": CHANGE_ORDER_API_KEYS,
  Dispatch: DISPATCH_API_KEYS,
  Claim: CLAIM_API_KEYS,
  "Safety Inspection": SAFETY_INSPECTION_API_KEYS,
  Incident: INCIDENT_API_KEYS,
};

export default REQUEST_API_KEYS;
