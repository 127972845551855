//@ts-check
import { API } from "aws-amplify";
import { categoriesName, categoriesServiceMap } from "./constants";
import { generateInvoices } from "./generateInvoices";
import { updateServiceInvoices } from "./updateServiceInvoices";

export const updateAllCategories = async ({
  data,
  res,
  categoryData,
  invoiceData,
  convertedData,
  projectData,
}) => {
  const objsToUpdate = {};
  const estimationsServicesToUpdateInProject = {};
  data.products?.forEach(({ category, categoryId }) => {
    if (objsToUpdate?.[category]) {
      objsToUpdate?.[category]?.push(categoryId);
    } else objsToUpdate[category] = [categoryId];
  });

  Object.keys(objsToUpdate)?.forEach((table) =>
    Array?.from(new Set(objsToUpdate[table]))?.forEach((categoryId) => {
      const invoices = generateInvoices({
        categoryData,
        table,
        categoriesName,
        categoryId,
        invoiceData,
        res,
        convertedData,
      });

      const services = categoryData[table]?.find(
        (el) => el?.[`${categoriesName[table]}Id`] === categoryId
      )?.[categoriesServiceMap[table]];

      services?.forEach((service) => {
        const invoicedService = data?.products?.find(
          ({ categoryId: cID, serviceId, name }) =>
            cID === categoryId &&
            serviceId === service?.serviceId &&
            name === service?.label
        );

        if (invoicedService)
          updateServiceInvoices({ service, invoiceData, invoicedService, res });
      });

      API.put(table, `/${table}/${categoryId}`, {
        body: {
          invoices,
          [categoriesServiceMap[table]]: services,
        },
      });

      if (table === "estimations") {
        estimationsServicesToUpdateInProject[categoryId] = services;
      }
    })
  );
  if (Object.keys(estimationsServicesToUpdateInProject)?.length > 0) {
    API.put("projects", `/projects/${projectData?.projectId}`, {
      body: {
        services: {
          ...projectData?.services,
          ...estimationsServicesToUpdateInProject,
        },
      },
    });
  }
};
