import { Form, Input } from "antd";
import React from "react";
import LoginButton from "../../../../components/LoginButton";
import "./ConfirmCode.scss";

const ConfirmCode = ({ onFinish, handleKeyPress, isLoading }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={onFinish} className="OTP_confirm_code">
      <Form.Item
        name="code"
        rules={[
          {
            required: true,
            message: "Invalid code",
          },
        ]}
      >
        <Input.OTP
          placeholder="Confirmation Code..."
          size="large"
          onKeyPress={(e) => handleKeyPress(e)}
        />
      </Form.Item>

      <Form.Item>
        <LoginButton
          type="primary"
          className="signin-btn"
          htmlType="submit"
          isLoading={isLoading}
          text="Confirm Code"
          loadingText="Confirming Code..."
        />
      </Form.Item>
    </Form>
  );
};

export default ConfirmCode;
