import { formatCurrency } from "../../../../../utils";
import { getSums, groupBy } from "../../../calculatingFunctions";

export const charges = ({ tables, circleProgressPicker }) => {
  const filteredData = tables?.charges?.filter(
    (a) => a?.categoryFrom === circleProgressPicker
  );

  return {
    categoryFrom: () => ({
      percent: (
        (filteredData?.length / tables?.charges?.length) *
        100
      )?.toFixed(1),
      custom:
        filteredData &&
        groupBy([filteredData], "projectAddress")?.map((el, index) => {
          return [
            {
              "Total Amount": {
                "Charge Amount With Tax": formatCurrency(
                  getSums({ data: [filteredData], key: "chargeAmount" })[
                    index
                  ] || 0
                ),
                "Total Tax": formatCurrency(
                  getSums({ data: [filteredData], key: "taxAmount" })[index] ||
                    0
                ),
                "Charge Amount Without Tax": formatCurrency(
                  (getSums({ data: [filteredData], key: "chargeAmount" })[
                    index
                  ] || 0) -
                    (getSums({ data: [filteredData], key: "taxAmount" })[
                      index
                    ] || 0) || 0
                ),
              },
            },
            ...Object.entries(el).map(([key, value]) => {
              return {
                [key]: {
                  projectId: value?.[0]?.projectId,
                  "Charge Amount With Tax": formatCurrency(
                    getSums({ data: value, key: "chargeAmount" })
                  ),
                  "Total Tax": formatCurrency(
                    getSums({ data: value, key: "taxAmount" })
                  ),
                  "Charge Amount Without Tax": formatCurrency(
                    getSums({ data: value, key: "chargeAmount" }) -
                      getSums({ data: value, key: "taxAmount" })
                  ),
                },
              };
            }),
          ];
        }),
    }),
  };
};
