import { useEffect } from "react";
// import Loader from "react-loader-spinner";
import { Button } from "antd";
import { MondayButton } from "../../../../commonComponents";

const LoadAllData = ({
  fetchAllData,
  isLoading,
  isAllFetched,
  tooltipCategory,
}) => {
  return (
    <>
      <MondayButton
        {...{
          loading: isLoading && !!fetchAllData,
          disabled: isAllFetched,
          onClick: fetchAllData,
          className: "mondayButtonBlue",
          tooltipCategory,
          tooltipKey: "getAllData",
        }}
      >
        Get All Data
      </MondayButton>
    </>
  );
};

export default LoadAllData;
