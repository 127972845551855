import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import { parsePhone, parseText } from "../Accounting/components/utilities";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { driveApi } from "../../../integrations/DriveRequest";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import htmlParser from "../../../utils/htmlParser";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let date = new Date().toLocaleDateString();

function camelCaseToNormalText(input) {
  if (!input) return "";
  if (typeof input === "object" && !Array.isArray(input))
    return camelCaseToNormalText(input?.props?.children?.[0] || "");
  let normalText = input?.replace(/([a-z])([A-Z])/g, "$1 $2");

  normalText = normalText?.charAt(0)?.toUpperCase() + normalText?.slice(1);

  return normalText;
}

function removeTags(str) {
  if (str === null || str === "") return "";
  else str = str?.toString();

  const rg = /(<([^>]+)>)/gi;

  if (rg.test(str)) {
    return str.replace(rg, "");
  }

  return str;
}

export function separateWitnessForPdf({
  defaultId,
  rowObject,
  witnessCount,
  witnessSignings,
}) {
  let witnessKeys = [];
  let witnessFields = [];
  let tmpField = [];
  let rg =
    /witnessName-[A-Za-z]*-\d{1,}$|witnessStatement-\d{1,}$|witnessName-\d{1,}$/;

  let replacePattern = /witnessName-|-\d{1,}$/g;
  if (!!defaultId && witnessCount > 0) {
    //separate all the keys that fulfill the witness condition
    Object.keys(rowObject?.safetyApplicationObject).forEach((key) => {
      if (rg.test(key)) {
        witnessKeys.push(key);
      }
    });

    for (let i = 0; i < witnessCount; i++) {
      let fieldsForIndex = witnessKeys.filter((key) => key?.endsWith(`-${i}`));
      fieldsForIndex.forEach((key) => {
        let keyToPush = key?.replaceAll(replacePattern, "");
        if (!isNaN(parseInt(keyToPush))) {
          keyToPush = "witnessName";
        }
        if (keyToPush?.toLowerCase()?.includes("phone")) {
          tmpField.push({
            key: keyToPush,
            value: parsePhone(rowObject?.safetyApplicationObject?.[key]),
          });
        } else {
          tmpField.push({
            key: keyToPush,
            value: rowObject?.safetyApplicationObject?.[key],
          });
        }
      });
      if (!!witnessKeys?.length) {
        tmpField.push({ key: "witnessSign", value: witnessSignings[i] });
        witnessFields.push(tmpField);
      }
      tmpField = [];
    }

    return witnessFields;
  } else return [];
}

export const mapImages = (images) => {
  let tmpArr = [];
  for (let i = 0; i < images.length / 6; i++) {
    tmpArr.push(
      pdfImageSection({
        title: "Images",
        body: images.slice(i * 6, i * 6 + 6),
      })
    );
  }

  return tmpArr;
};

const pdfNotesSection = ({ title, body = [], noColor = false }) => {};

const getNotesBody = (notes) => {};

const propertyDamageValues = ({
  element,
  rowObject,
  crewMembers,
  foremen,
  userConfiguration,
}) => {
  const { formItemName = "", label = "", type = "" } = element || {};
  const { safetyApplicationObject } = rowObject || {};

  const name = formItemName?.toLowerCase();

  if (name?.includes("preparedby")) {
    return userConfiguration?.allUsers?.Items?.find(
      ({ identityId }) => safetyApplicationObject?.[formItemName] === identityId
    )?.nameOfUser;
  } else if (name?.includes("crewmembers")) {
    return safetyApplicationObject?.[formItemName]?.map(
      (el) => crewMembers?.find(({ value }) => value === el)?.label || el?.value
    );
  } else if (name?.includes("foreman")) {
    return (
      foremen?.find(
        (el) => el.value === safetyApplicationObject?.[formItemName]
      )?.label || safetyApplicationObject?.[formItemName]?.value
    );
  } else if (name?.includes("phone")) {
    return parsePhone(safetyApplicationObject?.[formItemName]);
  } else if (label === "Team Members") {
    return element?.value;
  }

  if (type?.toLowerCase()?.includes("date")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("MM/DD/YYYY")
      : "";
  } else if (type?.toLowerCase()?.includes("time")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("HH:mm A")
      : "";
  }

  return safetyApplicationObject?.[formItemName];
};

export const propertyDamagePdfBody = ({
  category,
  fieldsJSON,
  crewMembers,
  foremen,
  rowObject,
  userConfiguration,
}) => {
  let tmpPdfData = [];
  fieldsJSON?.map(({ fields, title }) => {
    if (title === category) {
      tmpPdfData = fields?.map((e) => {
        return {
          key: e?.label,
          value: propertyDamageValues({
            element: e,
            rowObject,
            crewMembers,
            foremen,
            userConfiguration,
          }),
        };
      });
    }
    if (category === "Additional Information") {
      tmpPdfData?.push(
        {
          key: "Assigned To",
          value: Array.isArray(rowObject?.safetyApplicationObject?.assignedTo)
            ? rowObject?.safetyApplicationObject?.assignedTo
                ?.map((el) => {
                  if (!Array.isArray(el) && typeof el === "object") {
                    if (el?.hasOwnProperty("label")) {
                      return el?.label;
                    } else if (el?.hasOwnProperty("name")) {
                      return el?.name;
                    } else if (el?.hasOwnProperty("nameOfUser")) {
                      return el?.nameOfUser;
                    }
                  }
                  return el;
                })
                ?.join(", ")
            : rowObject?.safetyApplicationObject?.assignedTo,
        },
        {
          key: "Signature",
          value: rowObject?.safetyApplicationObject?.signature,
        }
      );
    }
  });
  return tmpPdfData;
};

const personalInjuryValues = ({
  element,
  rowObject,
  foremen,
  userConfiguration,
}) => {
  const { formItemName = "", label = "", type = "" } = element || {};
  const { safetyApplicationObject } = rowObject || {};

  const name = formItemName?.toLowerCase();

  if (name?.includes("preparedby")) {
    return userConfiguration?.allUsers?.Items?.find(
      ({ identityId }) => safetyApplicationObject?.[formItemName] === identityId
    )?.nameOfUser;
  } else if (name?.includes("phone")) {
    return parsePhone(safetyApplicationObject?.[formItemName]);
  } else if (name === "fullNameOfForeman") {
    return (
      foremen?.find(
        (el) => el.value === safetyApplicationObject?.[formItemName]
      )?.label || safetyApplicationObject?.[formItemName]?.value
    );
  } else if (label === "Team Members") {
    return element?.value;
  }

  if (type?.toLowerCase()?.includes("date")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("MM/DD/YYYY")
      : "";
  } else if (type?.toLowerCase()?.includes("time")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("HH:mm A")
      : "";
  }

  return safetyApplicationObject?.[formItemName];
};

export const personalInjuryPdfBody = ({
  category,
  fieldsJSON,
  rowObject,
  foremen,
  userConfiguration,
}) => {
  let tmpPdfData = [];
  fieldsJSON?.map(({ fields, title }) => {
    if (title === category) {
      tmpPdfData = fields?.map((e) => {
        return {
          key: e?.label,
          value: personalInjuryValues({
            element: e,
            rowObject,
            foremen,
            userConfiguration,
          }),
        };
      });
    }
    if (category === "Additional Information") {
      tmpPdfData?.push(
        {
          key: "Assigned To",
          value: Array.isArray(rowObject?.safetyApplicationObject?.assignedTo)
            ? rowObject?.safetyApplicationObject?.assignedTo
                ?.map((el) => {
                  if (!Array.isArray(el) && typeof el === "object") {
                    if (el?.hasOwnProperty("label")) {
                      return el?.label;
                    } else if (el?.hasOwnProperty("name")) {
                      return el?.name;
                    } else if (el?.hasOwnProperty("nameOfUser")) {
                      return el?.nameOfUser;
                    }
                  }
                  return el;
                })
                ?.join(", ")
            : rowObject?.safetyApplicationObject?.assignedTo,
        },
        {
          key: "Signature",
          value: rowObject?.safetyApplicationObject?.signature,
        }
      );
    }
  });
  return tmpPdfData;
};

const otherTradeIncidentValues = ({
  element,
  rowObject,
  userConfiguration,
}) => {
  const { formItemName = "", label = "", type = "" } = element || {};
  const { safetyApplicationObject } = rowObject || {};

  const name = formItemName?.toLowerCase();

  if (name?.includes("preparedby")) {
    return userConfiguration?.allUsers?.Items?.find(
      ({ identityId }) => safetyApplicationObject?.[formItemName] === identityId
    )?.nameOfUser;
  } else if (name?.includes("phone")) {
    return parsePhone(safetyApplicationObject?.[formItemName]);
  } else if (label === "Team Members") {
    return element?.value;
  }

  if (type?.toLowerCase()?.includes("date")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("MM/DD/YYYY")
      : "";
  } else if (type?.toLowerCase()?.includes("time")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("HH:mm A")
      : "";
  }

  return safetyApplicationObject?.[formItemName];
};

export const otherTradeIncidentPdfBody = ({
  category,
  fieldsJSON,
  rowObject,
  userConfiguration,
}) => {
  let tmpPdfData = [];
  fieldsJSON
    ?.map(({ title, fields }) => {
      if (title === category) {
        tmpPdfData = fields?.map((e) => {
          return {
            key: e?.label,
            value: otherTradeIncidentValues({
              element: e,
              rowObject,
              userConfiguration,
            }),
          };
        });
      }
    })
    ?.filter(Boolean);
  if (category === "Report Information") {
    tmpPdfData?.push(
      {
        key: "Assigned To",
        value: Array.isArray(rowObject?.safetyApplicationObject?.assignedTo)
          ? rowObject?.safetyApplicationObject?.assignedTo
              ?.map((el) => {
                if (!Array.isArray(el) && typeof el === "object") {
                  if (el?.hasOwnProperty("label")) {
                    return el?.label;
                  } else if (el?.hasOwnProperty("name")) {
                    return el?.name;
                  } else if (el?.hasOwnProperty("nameOfUser")) {
                    return el?.nameOfUser;
                  }
                }
                return el;
              })
              ?.join(", ")
          : rowObject?.safetyApplicationObject?.assignedTo,
      },
      {
        key: "Signature",
        value: rowObject?.safetyApplicationObject?.signature,
      }
    );
  }
  return tmpPdfData;
};

const vehicleDamageValues = ({
  element,
  rowObject,
  fleet,
  drivers,
  userConfiguration,
}) => {
  const { formItemName = "", label = "", type = "" } = element || {};
  const { safetyApplicationObject } = rowObject || {};

  const name = formItemName?.toLowerCase();

  if (name?.includes("preparedby")) {
    return userConfiguration?.allUsers?.Items?.find(
      ({ identityId }) => safetyApplicationObject?.[formItemName] === identityId
    )?.nameOfUser;
  } else if (name === "truckNumber") {
    return fleet?.find(
      (el) => el?.fleetId === safetyApplicationObject?.[formItemName]
    )?.fleetName;
  } else if (name === "nameOfDriver") {
    return (
      drivers?.find(
        (el) => el?.driverId === safetyApplicationObject?.[formItemName]
      )?.driverName || safetyApplicationObject?.[formItemName]?.value
    );
  } else if (name?.includes("phone")) {
    return parsePhone(safetyApplicationObject?.[formItemName]);
  } else if (label === "Team Members") {
    return element?.value;
  }

  if (type?.toLowerCase()?.includes("date")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("MM/DD/YYYY")
      : "";
  } else if (type?.toLowerCase()?.includes("time")) {
    return !!safetyApplicationObject?.[formItemName]
      ? dayjsNY(safetyApplicationObject?.[formItemName])?.format("HH:mm A")
      : "";
  }

  return safetyApplicationObject?.[formItemName];
};

export const vehicleDamagePdfBody = ({
  category,
  fieldsJSON,
  rowObject,
  fleet,
  drivers,
  userConfiguration,
}) => {
  let tmpPdfData = [];
  fieldsJSON
    ?.map(({ title, fields }) => {
      if (title === category) {
        tmpPdfData = fields?.map((e) => {
          return {
            key: e?.label,
            value: vehicleDamageValues({
              element: e,
              rowObject,
              fleet,
              drivers,
              userConfiguration,
            }),
          };
        });
      }
    })
    ?.filter(Boolean);
  if (category === "Report Information") {
    tmpPdfData?.push(
      {
        key: "Assigned To",
        value: Array.isArray(rowObject?.safetyApplicationObject?.assignedTo)
          ? rowObject?.safetyApplicationObject?.assignedTo
              ?.map((el) => {
                if (!Array.isArray(el) && typeof el === "object") {
                  if (el?.hasOwnProperty("label")) {
                    return el?.label;
                  } else if (el?.hasOwnProperty("name")) {
                    return el?.name;
                  } else if (el?.hasOwnProperty("nameOfUser")) {
                    return el?.nameOfUser;
                  }
                }
                return el;
              })
              ?.join(", ")
          : rowObject?.safetyApplicationObject?.assignedTo,
      },
      {
        key: "Signature",
        value: rowObject?.safetyApplicationObject?.signature,
      }
    );
  }

  return tmpPdfData;
};

export const safetyInspectionPdfBody = ({
  category,
  fieldsJSON,
  rowObject,
  crewMembers,
  foremen,
  fleet,
  drivers,
  userConfiguration,
}) => {
  let tmpPdfData = [];
  fieldsJSON?.map(({ fields, title }) => {
    if (title === category) {
      tmpPdfData = fields?.map((e) => {
        return {
          key: camelCaseToNormalText(e?.label),
          value:
            e?.formItemName?.toLowerCase()?.includes("inspector") ||
            e?.formItemName?.toLowerCase()?.includes("preparedby") ||
            e?.formItemName?.toLowerCase()?.includes("assignto")
              ? userConfiguration?.allUsers?.Items?.find(
                  ({ cognitoUserId }) =>
                    rowObject?.inspectionObject?.[e.formItemName] ===
                    cognitoUserId
                )?.nameOfUser
              : e?.formItemName?.toLowerCase()?.includes("crewmembers")
              ? rowObject?.inspectionObject?.[e.formItemName]?.map(
                  (el) => crewMembers?.find(({ value }) => value === el)?.label
                )
              : //?.filter(Boolean)
              e?.formItemName?.toLowerCase()?.includes("foreman")
              ? foremen?.find(
                  (el) =>
                    el.value === rowObject?.inspectionObject?.[e.formItemName]
                )?.label
              : e?.formItemName === "truck"
              ? fleet?.find(
                  (el) =>
                    el?.fleetId ===
                    rowObject?.inspectionObject?.[e.formItemName]
                )?.fleetName
              : e?.formItemName === "fullNameOfDriver"
              ? drivers?.find(
                  (el) =>
                    el?.driverId ===
                    rowObject?.inspectionObject?.[e.formItemName]
                )?.driverName
              : e?.type?.toLowerCase()?.includes("date")
              ? !!rowObject?.safetyApplicationObject?.[e.formItemName]
                ? dayjsNY(
                    rowObject?.inspectionObject?.[e.formItemName]
                  )?.format("MM/DD/YYYY")
                : ""
              : e?.type?.toLowerCase()?.includes("time")
              ? !!rowObject?.safetyApplicationObject?.[e.formItemName]
                ? dayjsNY(
                    rowObject?.inspectionObject?.[e.formItemName]
                  )?.format("HH:mm")
                : ""
              : e?.formItemName?.toLowerCase()?.includes("phone")
              ? parsePhone(rowObject?.safetyApplicationObject?.[e.formItemName])
              : e?.label === "Team Members"
              ? e?.value?.map((e) => e).join(",  ")
              : rowObject?.inspectionObject?.[e.formItemName],
        };
      });
    }
  });

  return tmpPdfData;
};

export async function imageForPdf({ rowObject, driveRequest }) {
  const driveImages = rowObject?.googleDriveUpload?.filter(
    ({ type = "", mimeType = "" }) =>
      type === "photo" || mimeType?.includes("image")
  );

  if (!driveImages?.length) {
    return [];
  }
  const imagePromises = driveImages?.map((el) => {
    return driveRequest.getImageSrc(el.id).then((res) => {
      return { img: res?.src, url: el?.url, name: el?.name };
    });
  });

  return Promise.allSettled(imagePromises).then((res) => {
    return res?.map((e) => {
      return {
        src: e?.value?.img,
        link: e?.value?.url,
        name: e?.value?.name,
      };
    });
  });
}
export const pdfImageSection = ({ title, body = [], noColor = false }) => {
  const imagesPart = body?.map((image) => {
    return body
      ? {
          columns: [
            {
              width: "*",
              text: image?.name,
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
            {
              link: image?.link,
              image: image?.src,
              width: 200,
              height: 110,
              fit: [200, 100],
              alignment: "center",
            },
          ],
        }
      : {
          width: "100%",
          text: "No images",
          alignment: "center",
          style: ["lastColumn", "cellItem"],
        };
  });
  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    ...imagesPart,
  ].filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };
  const layout = !noColor ? { ...borders, fillColor } : borders;
  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

export const pdfBorderedSection = ({ title, body = [], noColor = false }) => {
  const formattedBody = body.map((item) => {
    return title
      ? {
          columns: [
            {
              width: "45%",
              text: camelCaseToNormalText(item?.key),
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
            {
              width: "10%",
              text: "",
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
            item?.value &&
            typeof item?.value === "string" &&
            item?.value?.includes("data:image")
              ? {
                  image: item.value,
                  width: 200,
                  height: 100,
                  fit: [200, 100],
                }
              : {
                  width: "45%",
                  text: removeTags(item.value),
                  alignment: "left",
                  style: ["lastColumn", "cellItem"],
                },
          ],
        }
      : {};
  });

  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    ...formattedBody,
  ].filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody?.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

const fillColor = (rowIndex) => {
  return rowIndex % 2 === 0 ? "#f4f4f4" : "#fff";
};

export const docDefinition = (title, dynamicPdf, companyLogo) => {
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
          {
            text: `Date: ${date}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
  </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },

      dynamicPdf?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      notesHeader: { margin: [0, 10, 0, 10], fontSize: 12 },
      notesBody: { margin: [0, 0, 0, 10], fontSize: 10 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
    },
  };
};

export const savePdfToDrive = async ({
  docDefinition,
  folderId,
  accessToken,
  title,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const driveRequest = driveApi({ accessToken });
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);

      pdfDocGenerator.getBase64(async (data) => {
        pdfDocGenerator.getBlob(async (blob) => {
          // Upload the file to Google Drive
          const uploadResponse = await driveRequest.uploadFile(
            data,
            blob.type,
            {
              name: title,
              parents: [folderId],
              mimeType: blob.type,
            },
            true
          );

          // Get the file ID from the upload response
          const { id } = await uploadResponse.json();

          // Set temporary access for everyone to the file
          const expirationTime = new Date();
          expirationTime.setDate(expirationTime.getDate() + 1); // Set expiration to 1 day from now

          await driveRequest.setFilePermission(id, {
            type: "anyone",
            role: "reader",
            expirationTime: expirationTime.toISOString(),
          });

          resolve({ id, name: title });
        });
      });
    } catch (error) {
      console.error("Error saving PDF to Drive:", error);
      reject(error);
    }
  });
};

export const getRepliesRecursively = (note, array = [], index) => {
  // let array = [];
  array.push([
    {
      width: "100%",
      columns: [
        {
          width: "50%",
          alignment: "left",
          text: note?.author,
          style: ["lastColumn", "strong", "notesHeader"],
        },
        {
          width: "50%",
          text: dayjsNY(note?.createdAt).format(
            `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
          ),
          alignment: "right",
          style: ["lastColumn", "strong", "notesHeader"],
        },
      ],
      // indent:
    },
    {
      width: "100%",
      columns: [
        {
          width: "10%",
          text: "-".repeat(index),
          alignment: "right",
          style: ["lastColumn", "cellItem"],
        },
        {
          width: "90%",
          text: parseText(htmlParser(note?.replyContent || note?.noteComment)),
          alignment: "left",
          style: ["lastColumn", "cellItem"],
        },
      ],
    },
  ]);

  if (note?.replies?.length) {
    const { replies } = note;
    replies?.map((reply) => getRepliesRecursively(reply, array, index + 1));
  } else {
    return;
  }
};

export const notePdfBody = (body, noColor = false) => {
  const tableBody = [
    {
      text: "Notes",
      style: ["lastColumn", "strong", "subheader"],
    },
    ...body,
  ].filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };
  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

export const safetyPdfBody = {
  "Property Damage": propertyDamagePdfBody,
  "Personal Injury": personalInjuryPdfBody,
  "Vehicle Damage": vehicleDamagePdfBody,
  "Other Trade Incident": otherTradeIncidentPdfBody,
};

export function createSafetyPdfBody({
  defaultId = "",
  rowObject = {},
  formFields = [],
  witnessCount = 0,
  selectedTeam = [],
  safetyFormData = {},
  safetyCategory = "",
  witnessSignings = [],
  userConfiguration = {},
}) {
  const foremen = (safetyFormData?.crews || [])
    ?.filter((e) => e.foreman === true)
    ?.map(({ crewId, crewName, members }) => ({
      label: crewName,
      value: crewId,
      members,
    }));

  const crewMembers = (safetyFormData?.crews || [])
    ?.filter((e) => e.foreman !== true)
    ?.map(({ crewId, crewName }) => ({
      label: crewName,
      value: crewId,
    }));

  const drivers = safetyFormData?.drivers || [];
  const fleet = safetyFormData?.fleet || [];

  let tmpFields = formFields?.map((el) => {
    if (el?.title === "General Information") {
      return {
        ...el,
        fields: [
          ...el?.fields,
          {
            label: "Team Members",
            value: selectedTeam
              ?.map((el) => el?.members?.map((e) => e?.nameOfUser))
              ?.join(", "),
          },
        ],
      };
    }
    return el;
  });

  const configPdfData = formFields?.map((el) =>
    pdfBorderedSection({
      title: el?.title,
      body: safetyPdfBody?.[safetyCategory]({
        category: el?.title,
        fieldsJSON: tmpFields,
        rowObject,
        foremen,
        crewMembers,
        drivers,
        fleet,
        userConfiguration,
      }),
    })
  );

  const safetyDirectorSignatureSection = pdfBorderedSection({
    title: "Safety Director Signature",
    body: [
      {
        key: "Safety Director",
        value:
          rowObject?.safetyApplicationObject?.safetyDirectorSignature
            ?.nameOfUser || "",
      },
      {
        key: "Signature",
        value:
          rowObject?.safetyApplicationObject?.safetyDirectorSignature?.src ||
          "N/A",
      },
    ],
  });

  const witnessList = separateWitnessForPdf({
    defaultId,
    rowObject,
    witnessCount,
    witnessSignings,
  });

  const witnessPdfData = witnessList?.map((el) => {
    if (
      el?.every(
        ({ value }) =>
          !value || value === "" || value === null || value === undefined
      )
    ) {
      return null;
    }
    return pdfBorderedSection({
      title: "Witnesses",
      body: el,
    });
  });

  const toConcat = [
    ...(!!witnessPdfData?.length ? witnessPdfData : []),
    ...(rowObject?.safetyApplicationObject?.hasOwnProperty(
      "safetyDirectorSignature"
    )
      ? [safetyDirectorSignatureSection]
      : []),
  ];

  return configPdfData.concat(toConcat);
}
