import React from "react";
import { useCreateInvoiceContext } from "../../context/InvoiceModalContext";
import { formatCurrency } from "../../../../../utils";

const FooterAmounts = () => {
  const { totalDue, subTotal } = useCreateInvoiceContext();

  return (
    <div className="footer-invoice-group">
      <h4 data-testid="total-amount">Total: {formatCurrency(totalDue)}</h4>
      <h4 data-testid="sub-total-amount">
        Subtotal: {formatCurrency(subTotal)}
      </h4>
    </div>
  );
};

export default FooterAmounts;
