import { Modal } from "antd";
import { MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../Communication/assets";
import { useState } from "react";
import { InputComponent } from "../../../../../Fleet/components";
import "./PreIncidentModal.scss";
import { useSelector } from "react-redux";

function PreIncidentsModal({
  open,
  onCancel = () => {},
  category,
  onCategorySelected = () => {},
  setVisible = () => {}, //NEEDED FOR NEXT STEPS
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedCategory, setSelectedCategory] = useState("");
  const onCancelHandler = () => {
    onCancel();
    setVisible();
  };
  return (
    <>
      <Modal
        {...{
          open,
          onCancel: onCancelHandler,
          closable: true,
          destroyOnClose: true,
          wrapClassName: "cardLayoutModal",
          className: `${isDarkMode && "newIncidentModalDark"}`,
          footer: null,
          maskClosable: false,
          title: "New Incident Modal",
          centered: true,
          closeIcon: <XIcon />,
        }}
      >
        <div className="note-modal-content">
          <InputComponent
            showSearch
            label="Category"
            type="select"
            // defaultValue={category}
            // disabled={!!category}
            onSelect={(e) => {
              setSelectedCategory(e);
              // selectCategory(e);
            }}
            allowClear={false}
            dropdownClassName={isDarkMode && "darkDropDown"}
            customOptions={[
              { value: "Property Damage" },
              { value: "Personal Injury" },
              { value: "Vehicle Damage" },
              { value: "Other Trade Incident" },
            ]}
          />
          <div className="modalFooter">
            <MondayButton
              {...{
                Icon: <TickIcon width={17} height={17} />,
                onClick: () => {
                  onCategorySelected(selectedCategory);
                },
                disabled: !selectedCategory,
                tooltipCategory: "Incidents",
                tooltipKey: "open",
              }}
            >
              Open Incident Form
            </MondayButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PreIncidentsModal;
