import moment from "moment";
import { totalitiesForService } from "../../Projects/Accounting/Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/utils";
import { formatCurrency, getMonthsTimestamps } from "../../utils";

export const groupBy = (data, groupKey) =>
  data?.map((e) => {
    return e?.reduce((acc, curr) => {
      acc[curr?.[groupKey]] = [...(acc[curr?.[groupKey]] || []), curr];
      return acc;
    }, {});
  });

export const categoriesName = {
  estimations: "Estimation",
  applications: "Requisition",
  charges: "Charge",
  rentals: "Rental",
};

export const getSums = ({ data, key }) => {
  if (Array.isArray(data?.[0])) {
    return data?.map((e) => {
      return e?.reduce((acc, curr) => {
        return acc + (Number(curr?.[key]) || 0);
      }, 0);
    });
  } else {
    return data?.reduce((acc, curr) => {
      return acc + (Number(curr?.[key]) || 0);
    }, 0);
  }
};

export const getPeriodMonth = ({ periodTo, start, end }) => {
  if (!!periodTo && !!start && !!end) {
    const periodStart = moment(periodTo.start).valueOf();
    const periodEnd = moment(periodTo.end).valueOf();

    const parsedStart = moment(start).valueOf();
    const parsedEnd = moment(end).valueOf();

    if (periodStart <= parsedStart && periodEnd >= parsedEnd) {
      return parsedEnd - parsedStart;
    } else if (periodStart >= parsedStart && periodStart <= parsedEnd) {
      if (periodEnd < parsedEnd) return periodEnd - periodStart;
      else if (periodEnd > parsedEnd) return parsedEnd - periodStart;
    } else if (periodEnd >= parsedStart && periodEnd <= parsedEnd) {
      if (periodStart < parsedStart) return periodEnd - parsedStart;
      else if (periodStart > parsedStart) return periodEnd - periodStart;
    } else return 0;
  } else {
    return 0;
  }
};

export const calculateInvoiceStatus = ({ table, status }) => {
  return table
    ?.map((a) => {
      const invoiceItems = a?.invoiceItems?.filter(({ category }) => {
        return categoriesName?.[category] === status;
      });
      return {
        ...a,
        invoiceItems,
        totalInvoiceAmount: invoiceItems?.reduce(
          (prev, { amount, taxAmount, tax }) =>
            prev + (amount + (tax ? taxAmount : 0)),
          0
        ),
        subtotal: invoiceItems?.reduce((prev, { amount }) => prev + amount, 0),
      };
    })
    ?.filter(({ invoiceItems }) => invoiceItems?.length > 0);
};

export const getMonthData = (table, idName) => {
  const start = getMonthsTimestamps();
  const end = getMonthsTimestamps("end");
  const data = Array(12).fill({ id: [], value: 0 });
  table?.map((el) => {
    const temp = Array(12).fill(0);
    temp?.map((val, index) => {
      temp[index] = getPeriodMonth({
        periodTo: !!el?.periodTo?.start
          ? el?.periodTo
          : {
              start: el?.createdAt,
              end: el?.createdAt + 1,
            },
        start: start[index],
        end: end[index],
      });
      return val;
    });

    const index = temp?.indexOf(Math.max(...temp));

    data[index] = {
      id: [...(data?.[index]?.id || []), el?.[idName]],
      value: data?.[index]?.value + 1,
    };
    return el;
  });
  return data;
};

export const allServicesTogether = (table) => {
  const temp = !!table && totalitiesForService(table);
  const allServicesTogether = {};

  !!temp &&
    temp?.forEach((curr) => {
      if (Object.keys(curr?.amounts).length) {
        Object?.entries(curr.amounts)?.forEach(([key, value]) => {
          if (!!allServicesTogether[key]) {
            allServicesTogether[key] = {
              appliedRent:
                allServicesTogether[key]?.appliedRent + value?.appliedRent,
              totalPrice:
                allServicesTogether[key]?.totalPrice + value?.totalPrice,
              totalTax: allServicesTogether[key]?.totalTax + value?.totalTax,
              retainage: allServicesTogether[key]?.retainage + value?.retainage,
              projects: {
                ...allServicesTogether[key]?.projects,
                [curr?.projectAddress]: {
                  projectId: curr?.projectId,
                  "Total Price": formatCurrency(curr?.totalPrice || 0),
                  "Total Applied Rent": formatCurrency(curr?.appliedRent || 0),
                  "Total Tax": formatCurrency(curr?.totalTax || 0),
                  "Retainage Amount": formatCurrency(
                    curr?.retainageAmount || 0
                  ),
                },
              },
            };
          } else {
            allServicesTogether[key] = {
              appliedRent: value?.appliedRent,
              totalPrice: value?.totalPrice,
              totalTax: value?.totalTax,
              retainage: value?.retainage,
              projects: {
                [curr?.projectAddress]: {
                  projectId: curr?.projectId,
                  "Total Price": formatCurrency(curr?.totalPrice),
                  "Total Applied Rent": formatCurrency(curr?.totalAppliedRent),
                  "Total Tax": formatCurrency(curr?.totalTax || 0),
                  "Retainage Amount": formatCurrency(
                    curr?.retainageAmount || 0
                  ),
                },
              },
            };
          }
        });
      }
    });

  return allServicesTogether;
};
