import "./NotificationFields.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Divider, Tooltip, message } from "antd";
import NotificationConfigs from "./NotificationConfigs/NotificationConfigs";
import NotificationVideoTutorial from "./NotificationVideoTutorial/NotificationVideoTutorial";
import { useUserProfileContext } from "../UserProfileContext";
import { Switcher, ToggleSwitch } from "../../../../../commonComponents";
import { NotificationRoundedIcon } from "src/assets";
import { VideoTutorialIcon } from "src/icons";

const NotificationFields = () => {
  const { profileChanges, setProfileChanges } = useUserProfileContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);

  const [browserNotifications, setBrowserNotifications] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications");
      return;
    }
    setBrowserNotifications(Notification?.permission === "granted");
  }, []);

  const requestBrowserNotifications = () => {
    if (!("Notification" in window)) {
      message.info("This browser does not support desktop notification!");
    } else if (Notification?.permission !== "denied") {
      try {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            setBrowserNotifications(true);
            new Notification("You will get notifications every time!");
          } else {
            message.warning("Permission for notifications denied!");
          }
        });
      } catch (error) {
        message.info(`requestBrowserNotifications error ${error}`);
      }
    } else {
      message.info(`Notifications by default are ${Notification?.permission}!`);
    }
  };

  const handleAllNotifications = (e) => {
    setProfileChanges((prev) => ({
      ...prev,
      automationPreferences: Object.keys(finalNotifSettings).reduce(
        (acc, functionName) => ({ ...acc, [functionName]: e }),
        {}
      ),
      myNotifications: Object.entries(
        preferences.preferences.notifications.topics
      ).reduce(
        (acc, [topicId, actions]) => ({
          ...acc,
          [topicId]: Object.keys(actions).reduce(
            (acc, actionName) => ({ ...acc, [actionName]: e }),
            {}
          ),
        }),
        {}
      ),
    }));
  };

  const finalNotifSettings =
    profileChanges?.automationPreferences ||
    preferences.preferences?.notifications?.automationPreferences ||
    {};

  const allNotificationsOn = Object.values({
    ...(profileChanges?.myNotifications ||
      preferences.preferences.notifications.topics ||
      {}),
    ...finalNotifSettings,
  }).every((topic) => {
    return typeof topic === "object"
      ? Object.values(topic).every((topic) => !!topic)
      : !!topic;
  });

  return (
    <div
      className={`notification-fields-container ${
        collapsed ? "collapsed" : ""
      }`}
    >
      <div
        className={`CardContentWrapper ${collapsed ? "collapsed" : ""} ${
          isDarkMode ? "dark-mode" : ""
        }`}
      >
        <div
          className="CardContentWrapper-header"
          onClick={() => {
            setCollapsed((prev) => !prev);
          }}
        >
          <div className="header-left">Notifications Field </div>
          <div className="header-right">
            <Tooltip
              title={`Turn ${
                allNotificationsOn ? "ON" : "OFF"
              } all notifications!`}
            >
              <ToggleSwitch
                variant="primary"
                checked={allNotificationsOn}
                label={
                  <span>
                    Notifications <b>{allNotificationsOn ? "ON" : "OFF"}</b>
                  </span>
                }
                icon={<NotificationRoundedIcon />}
                onChange={handleAllNotifications}
                dataTestId="switch-notifications-testid"
              />
            </Tooltip>
            <div
              className="tutorial-icon"
              onClick={(e) => {
                e.stopPropagation();
                setTourOpen(true);
              }}
            >
              <VideoTutorialIcon />
            </div>
          </div>
        </div>

        <div className="CardContentWrapper-content">
          <NotificationConfigs
            {...{
              myNotifications: profileChanges.myNotifications,
              setMyNotifications: (newNotifications) => {
                setProfileChanges((prev) => ({
                  ...prev,
                  myNotifications: newNotifications,
                }));
              },
              myAutomationPrefs: profileChanges.automationPreferences,
              setPreferences: (newNotifications) => {
                setProfileChanges((prev) => ({
                  ...prev,
                  automationPreferences: newNotifications,
                }));
              },
            }}
          />

          {!browserNotifications && (
            <>
              <Divider>Desktop Notifications Status</Divider>

              <div style={{ width: "100%" }}>
                <Switcher
                  label="Desktop Notification"
                  status={browserNotifications}
                  onClick={(e) => {
                    requestBrowserNotifications();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <NotificationVideoTutorial {...{ tourOpen, setTourOpen }} />
    </div>
  );
};

export default NotificationFields;
