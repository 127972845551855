import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

import MondayButton from "../MondayButton/MondayButton";
import { XIcon } from "../../SidebarPages/Communication/assets";

import "./WarningModal.scss";

const WarningModal = ({
  visible,
  setVisible,
  title,
  children,
  onCancel,
  className = "",
  destroyOnClose = true,
  maskClosable = false,
  footer = [],
  closable = true,
  onKeyPress = () => {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      onKeyDown={onKeyPress}
      style={{ display: visible ? "" : "none" }}
      data-testid="warning-modal"
      className="warning-modal"
    >
      <Modal
        wrapClassName={`warningModal-light ${
          isDarkMode && "warningModal-dark"
        } ${className}`}
        open={visible}
        data-testid="warning-modal"
        destroyOnClose={destroyOnClose}
        footer={
          !!footer.length ? (
            <div className="footerContainer">
              {footer?.map(
                ({ text, onClick, disabled, icon, className }, i) => (
                  <MondayButton
                    key={i}
                    onClick={onClick}
                    Icon={icon}
                    disabled={disabled}
                    className={className}
                    data-testid="warning-modal-button"
                  >
                    {text}
                  </MondayButton>
                )
              )}
            </div>
          ) : null
        }
        title={title}
        centered={true}
        keyboard
        closable={closable}
        closeIcon={<XIcon />}
        maskClosable={maskClosable}
        onCancel={() => {
          onCancel ? onCancel() : setVisible(false);
        }}
      >
        {children}
      </Modal>
    </div>
  );
};

export default WarningModal;
