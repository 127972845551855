import { Avatar } from "antd";
import { useSelector } from "react-redux";
import AvatarImage from "../../../AvatarImage/AvatarImage";

const getAuthors = (category, notes = []) => {
  return [
    ...new Set(
      notes
        .filter(
          (filterComment) => filterComment.category === category.categoryName
        )
        .map((item) => item.cognitoUserId)
    ),
  ];
};

const NotesCategoryPanel = ({ category, filteredNotes }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const authors = getAuthors(category, filteredNotes).map((cognito) =>
    userConfiguration.allUsers.Items.find(
      ({ cognitoUserId }) => cognitoUserId === cognito
    )
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {category.categoryName}

      <Avatar.Group
        className="image-row"
        maxCount={3}
        size="large"
        maxPopoverPlacement="bottom"
        maxStyle={{ color: "#ffffff", backgroundColor: "#1264a3" }}
      >
        {authors.map(({ nameOfUser, googleDriveFileId }, i) => (
          <AvatarImage key={i} {...{ nameOfUser, googleDriveFileId }} />
        ))}
      </Avatar.Group>
    </div>
  );
};

export default NotesCategoryPanel;
