import { Form } from "antd";
import RichTextEditor from "./RichTextEditor";

const RichTextEditorWrapper = ({
  disabled = false,
  onChange,
  onBlur,
  initialValue,
  placeholder = "",
  isDarkMode,
  ...rest
}) => (
  <Form.Item {...rest}>
    <RichTextEditor
      {...{
        onChange,
        disabled,
        initialValue,
        placeholder,
        isDarkMode,
        onBlur,
        ...rest,
      }}
    />
  </Form.Item>
);

export default RichTextEditorWrapper;
