// import MaskedInput from "antd-mask-input";
import PhoneInput from "react-phone-input-2";
import "./Phone.scss";
const FormItemPhone = ({
  value,
  form,
  name,
  disabled,
  placeholder,
  onChange,
  onKeyDown,
}) => {
  return (
    <div className="EditPhone">
      <PhoneInput
        width="100%"
        disableCountryGuess
        // disableInitialCountryGuess
        {...{
          country: "us",
          // preferredCountries: ["al", "us", "il"],
          onlyCountries: ["us"],
          disableCountryCode: true,

          placeholder,
          disabled,
          value: value,
          specialLabel: "",
          copyNumbersOnly: false,
          onKeyDown,
          onChange,
        }}
      ></PhoneInput>
    </div>
  );
};
export default FormItemPhone;
