export const safety = (updatedDynamicStates, IdHandler, programFields) => {
  const safetyStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    safetyApplicationStatus: () => ({
      title: "Safety Application Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Safety",
        field: "safetyApplicationStatus",
        filterType: { type: "dashboardId" },
        noTab: true,
        secondaryField: "safetyName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.safetyName || "Save Draft",
            value: a?.safetyApplicationStatus,
            color:
              safetyStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.safetyApplicationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.safetyApplicationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    safetyName: () => ({
      title: "Safety Application Name",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Safety",
        field: "safetyName",
        filterType: { type: "dashboardId" },
        noTab: true,
        secondaryField: "safetyApplicationStatus",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.safetyName || "Save Draft",
            value: a?.safetyApplicationStatus,
            color:
              safetyStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.safetyApplicationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.safetyApplicationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
    projectName: () => ({
      title: "Safety Application Project",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Safety",
        field: "projectName",
        filterType: { type: "dashboardId" },
        // noTab: true,
        secondaryField: "safetyApplicationObject.projectName",
        pathname: "/projects",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.safetyApplicationObject?.projectName,
            value: a?.safetyApplicationStatus,
            color:
              safetyStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.safetyApplicationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.projectId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    accountName: () => ({
      title: "Safety Account Name",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Safety",
        field: "accountName",
        filterType: { type: "dashboardId" },
        // noTab: true,
        secondaryField: "safetyApplicationObject.accountName",
        pathname: "/accounts",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.safetyApplicationObject?.accountName,
            value: a?.safetyApplicationStatus,
            color:
              safetyStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.safetyApplicationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.accountId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
    scheduleAddress: () => ({
      title: "Safety Schedule Address",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Safety",
        field: "scheduleAddress",
        filterType: { type: "dashboardId" },
        // noTab: true,
        secondaryField: "safetyApplicationObject.scheduleAddress",
        pathname: "/scheduling",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.safetyApplicationObject?.scheduleAddress,
            value: a?.safetyApplicationStatus,
            color:
              safetyStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.safetyApplicationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.scheduleId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
