const PreviewAccessCard = ({
  data,
  style = {},
  classFunction,
  parent = false,
  tab,
}) => {
  return (
    <div style={style} className="elementCategoryTitle">
      <span className="elementCategoryTitleLabel">{data?.title}</span>
      <div className="actionClassName">
        <span
          className={
            classFunction ? "labelTrueClassName" : "labelFalseClassName"
          }
        >
          Read
        </span>
        {parent && tab !== "Route" ? (
          <></>
        ) : (
          <span
            className={
              classFunction && classFunction.write
                ? "labelTrueClassName"
                : "labelFalseClassName"
            }
          >
            Write
          </span>
        )}
      </div>
    </div>
  );
};

export default PreviewAccessCard;
