import UploadImageStep from "../components/UploadImageStep/UploadImageStep";
import NotificationsStep from "../components/NotificationsStep/NotificationsStep";
import GoogleSignInStep from "../components/GoogleSignInStep/GoogleSignInStep";
import ThemeStep from "../components/ThemeStep/ThemeStep";
import TutorialsStep from "../components/TutorialsStep/TutorialsStep";
import AutomationStep from "../components/AutomationStep/AutomationStep";
import {
  Camera,
  CameraFilled,
  Notifications,
  NotificationsFilled,
  Google,
  GoogleFilled,
  LightModeIcon,
  VideoIcon,
  VideoIconFilled,
  DarkModeIcon,
  LightModeIconFilled,
  DarkModeIconFilled,
  AutomationIconFilled,
} from "../assets/icons";
import { NewAutomationIcon } from "../../../assets";
import { DashboardWhite } from "../../../icons";
import DashboardStep from "../components/DashboardStep/DashboardStep";

export const getBoardingSteps = ({ currentStep, isDarkMode }) => {
  return [
    uploadImageStep({ currentStep, index: 0 }),
    notificationStep({ currentStep, index: 1 }),
    googleSignInStep({ currentStep, index: 2 }),
    themeStep({ currentStep, index: 3, isDarkMode }),
    tutorialsStep({ currentStep, index: 4 }),
    automationStep({ currentStep, index: 5 }),
    dashboardStep({ currentStep, index: 6 }),
  ];
};

const uploadImageStep = ({ currentStep, index }) => {
  const Icon = currentStep === index ? CameraFilled : Camera;

  return {
    key: "uploadImageStep",
    index,
    title: "Set Your Profile Photo",
    description: "Customize your profile by adding a photo",
    component: UploadImageStep,
    icon: <Icon />,
  };
};

const notificationStep = ({ currentStep, index }) => {
  const Icon = currentStep === index ? NotificationsFilled : Notifications;
  return {
    key: "notificationStep",
    index,
    title: "Configure Notification Preferences",
    description: "Customize your notifications",
    component: NotificationsStep,
    icon: <Icon />,
  };
};

const googleSignInStep = ({ currentStep, index }) => {
  const Icon = currentStep === index ? GoogleFilled : Google;
  return {
    key: "googleSignInStep",
    index,
    title: "Google Sign-In Configuration",
    description: "Connect your Google account for a better experience",
    component: GoogleSignInStep,
    icon: <Icon />,
  };
};

const themeStep = ({ currentStep, index, isDarkMode }) => {
  const Icon =
    currentStep === index
      ? isDarkMode
        ? DarkModeIconFilled
        : LightModeIconFilled
      : isDarkMode
      ? DarkModeIcon
      : LightModeIcon;
  return {
    key: "themeStep",
    index,
    title: "Set Preferred Theme",
    description: "Choose between dark and light mode",
    component: ThemeStep,
    icon: <Icon />,
  };
};

const tutorialsStep = ({ currentStep, index }) => {
  const Icon = currentStep === index ? VideoIconFilled : VideoIcon;
  return {
    key: "tutorialsStep",
    index,
    title: "Explore Video Tutorials",
    description: "Learn how to use the software with our videos",
    component: TutorialsStep,
    icon: <Icon />,
  };
};

const automationStep = ({ currentStep, index }) => {
  const Icon = currentStep === index ? AutomationIconFilled : NewAutomationIcon;

  return {
    key: "automationStep",
    index,
    title: "Automations",
    description: "Config automation notifications",
    component: AutomationStep,
    icon: <Icon />,
  };
};

const dashboardStep = ({ currentStep, index }) => {
  const Icon = DashboardWhite;

  return {
    key: "dashboardStep",
    index,
    title: "Dashboard",
    description: "Customize your dashboard",
    component: DashboardStep,
    icon: <Icon />,
  };
};
