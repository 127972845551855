import React, { createContext, useContext } from "react";
import { useProgressComponent } from "../../../../hooks/useProgressComponent";

const ProgressContext = createContext();

export const useProgressContext = () => {
  return useContext(ProgressContext);
};

export const ProgressProvider = ({ children, ...props }) => {
  const progress = useProgressComponent({ ...props });
  return (
    <ProgressContext.Provider value={{ ...progress }}>
      {children}
    </ProgressContext.Provider>
  );
};
