import { datasetsFilter } from ".";

export const extractDataSources = (report) => {
  const { definition = {} } = report;
  const { DataSets = [] } = definition;

  if (DataSets.length === 0) {
    return report;
  }

  return {
    ...report,
    definition: {
      ...definition,
      DataSources: DataSets.filter(datasetsFilter).map(
        (dataset) => dataset?.Query?.DataSourceName
      ),
    },
  };
};
