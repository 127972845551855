import { Tick } from "../../../../../../../../../../DynamicView/components/FilterView/FilterIcons";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { PercentageIcon } from "../../../../../../../../../../BasePage/src";
import {
  applyRetainageToAllServices,
  newApplicationHandler,
} from "./changeRetenageModalFunction";
import { Close } from "../../../../../../../../../../DynamicView/src";
import "./ChangeRetenageModal.scss";

import { InputNumber, message, Modal } from "antd";
import { useEffect, useState } from "react";
import ChangeRetainageActions from "../../../../HoistService/Components/ChangeRetenageModal/ChangeRetainageActions/ChangeRetainageActions";
import AccountingConfirmationModal from "../../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import { useSelector } from "react-redux";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const ChangeRetenageModal = ({ service }) => {
  const [retainageState, setRetainageState] = useState();
  const [isRetainageModalVisible, setIsRetainageModalVisible] = useState(false);
  const { fakeApplication, isWritable, darkMode } = useStateContext();
  const dispatchContext = useDispatchContext();
  const [writeableObject, setWriteableObject] = useState(null);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const { applications } = useSelector((state) => state.applications);

  //close the modal
  const closeRetainageModal = () => {
    setIsRetainageModalVisible(false);
    setWriteableObject(null);
  };

  const setApplication = (newApplication) => {
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
    dispatchContext({
      type: "SET_UNCHANGING_FAKE_APPLICATION",
      payload: newApplication,
    });
  };

  //sets the new retenage and the new amounts
  const handleChangeRetainage = () => {
    //this function calculates the new values of application
    const newApplication = newApplicationHandler({
      fakeApplication,
      service,
      retainageState,
    });

    //sets the new application to context
    setApplication(newApplication);

    //close the modal
    closeRetainageModal();
  };

  const handleChangeRetForAll = ({ type = "increase" }) => {
    const newApplication = applyRetainageToAllServices({
      fakeApplication,
      writeableObject,
      applications,
      type,
    });
    //sets the new application to context
    setApplication(newApplication);

    //close the modal
    closeRetainageModal();
  };

  //handles the new value that we put on input
  const inputChangerHandler = (e) => {
    let initialValue = e;
    console.log("initial value", initialValue);
    if (initialValue < 0) {
      setRetainageState(0);
      message.warning("Input less than 0!!");
    } else if (initialValue > 100 || isNaN(parseFloat(initialValue))) {
      // setRetainageState(100);
      message.warning("Input NOT valid!!");
    } else {
      setRetainageState(parseFloat(initialValue));
    }
  };

  const onPreCheck = () => {
    setIsRetainageModalVisible(true);
  };

  useEffect(() => {
    if (isRetainageModalVisible && !!fakeApplication?.services) {
      // console.log("fakeApplication?.services", fakeApplication?.services);
      const fakeService = fakeApplication?.services?.find(
        (s) => s?.label === service?.label
      );
      // console.log("service", fakeService);
      setRetainageState(fakeService?.retainage);
    }
  }, [JSON.stringify(fakeApplication?.services), isRetainageModalVisible]);
  // stringify in use effect

  return (
    <div className="changePercentageApplicationContainer">
      <MondayButton
        className={`changeRetainageSOVButton`}
        onClick={onPreCheck}
        Icon={<PercentageIcon />}

        //disabled={!isWritable}
      >
        {isWritable ? "Change Retainage" : "View Retainage"}
      </MondayButton>

      <Modal
        open={isRetainageModalVisible}
        title={isWritable ? "Change Retainage" : "View Retainage"}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName={
          darkMode
            ? "changeRetenagePercentageApplicationModalDark"
            : "changeRetenagePercentageApplicationModalLight"
        }
        onCancel={closeRetainageModal}
        closeIcon={<Close />}
        footer={
          isWritable && (
            <div
              className="serviceFooterRetainageModal"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <MondayButton
                className="cancelMondayButton"
                Icon={<Close />}
                onClick={closeRetainageModal}
              >
                Cancel
              </MondayButton>
              {/* <MondayButton
							className="applyMondayButton"
							Icon={<Tick />}
							onClick={handleChangeRetainage}
						>
							Apply
						</MondayButton> */}
              <MondayButton
                className="applyMondayButton"
                Icon={<Tick />}
                onClick={() => setConfirmationVisible(true)}
                style={{ marginLeft: "10px" }}
              >
                Apply for All
              </MondayButton>
              <AccountingConfirmationModal
                {...{
                  darkMode,
                  visible: confirmationVisible,
                  setVisible: setConfirmationVisible,
                  onConfirm: () => handleChangeRetForAll({ type: "increase" }),
                  text: "Are you sure you want to apply this retainage to all services?",
                  title: "CONFIRMATION REQUIRED",
                  confirmButtonText: "Apply",
                }}
              />
            </div>
          )
        }
      >
        {/* <InputNumber
					className="changeRetainageInput"
					placeholder="Enter new Retainage"
					value={retainageState}
					onChange={inputChangerHandler}
				/> */}
        {isRetainageModalVisible && (
          <ChangeRetainageActions
            {...{
              fakeApplication,
              writeableObject,
              setWriteableObject,
              isRetainageModalVisible,
              applications,
              isWritable,
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default ChangeRetenageModal;
