import React from "react";

import { InspectionModal } from "../../../../XComponents";

const FailInspectionModal = ({ visible, setVisible, onFail }) => {
  return (
    <InspectionModal
      visible={visible}
      setVisible={setVisible}
      maskClosable={false}
      footer={[
        {
          text: "cancel",
          onClick: () => setVisible(false),
          primary: false,
        },
        {
          text: "finish",
          onClick: () => (onFail(), setVisible(false)),
          danger: true,
        },
      ]}
    >
      <div style={{ height: "150px" }}>
        <div className="forceToInspectModalTitle">Warning!</div>
        <div>You are failing this inspection!!!</div>
      </div>
    </InspectionModal>
  );
};

export default FailInspectionModal;
