import { useContext, useEffect, useState } from "react";
import { PlaySquareFilled } from "@ant-design/icons";
import { VideoTutorialIcon } from "../../assets/svg";
import NewCategories from "../NewCategories/NewCategories";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { message } from "antd";

const UnfinishedVideos = () => {
  const { playedVideos, setActivePrimaryCat, setSubCatVideos } =
    useContext(VideoTutorialContext);

  const [openUnfinishedVideos, setOpenUnfinishedVideos] = useState(false);

  const handleClick = () => {
    if (playedVideos.length === 0) return message.warning("No Video to show");

    setOpenUnfinishedVideos((prev) => !prev);
    setActivePrimaryCat();
    setSubCatVideos();
  };

  useEffect(() => {
    playedVideos.length === 0 && setOpenUnfinishedVideos(false);
  }, [playedVideos]);

  return (
    <>
      <div
        data-testid="unfinishedTutorialsCard"
        className={`unfinishedTutorialsCard ${
          openUnfinishedVideos ? "active" : ""
        }`}
        onClick={handleClick}
      >
        <PlaySquareFilled className="video-icon" />
        <p className="text">
          Continue Watching ({playedVideos.length}) tutorials
        </p>
        <VideoTutorialIcon className="videoPlayerIcon" />
      </div>
      {openUnfinishedVideos && (
        <NewCategories newVideos={playedVideos} isUnfinishedVideo={true} />
      )}
    </>
  );
};

export default UnfinishedVideos;
