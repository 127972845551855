import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { columnDefs } from "../../../../Applications/ApplicationView/components/ServiceList/Rentals/Components/RentalBody/GridParams/columnDefs";
import { template } from "../../../datas";
import { useCallback } from "react";
import { defaultColDef } from "../../../../Applications/ApplicationView/components/ServiceList/Rentals/Components/RentalBody/GridParams/defaultColumnDefs";

export const IncludeRentals = ({ rentalNumber, rentalStatus, services }) => (
  <div
    key={rentalNumber}
    className="applicationAgGridContainer dark-ag-theme documentationsTable reqTables ag-theme-alpine-dark"
  >
    <div className="header">
      <span className="titleHeaderLabel">{`Rental ${rentalNumber}`}</span>
      {/* <div className="editContainer">{`Rentals Status: ${rentalStatus}`}</div> */}
      {/* <CreditEnabler
          {...{
            service: label,
            elevation,
          }}
        /> */}
    </div>
    <AgGridReact
      // onGridReady={onGridReady}
      columnDefs={columnDefs}
      enableRangeSelection={true}
      suppressSizeToFit={true}
      headerHeight={90}
      rowData={services}
      rowHeight={40}
      suppressDragLeaveHidesColumns={true}
      defaultColDef={{
        headerHeight: 90,
        enablePivot: true,
        editable: true,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
        resizable: true,
        headerComponentParams: {
          template,
        },
      }}
    />
  </div>
);
