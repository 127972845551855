import React, { createContext } from "react";

import { useCustomerWebsocket } from "./useCustomerWebsocket";

export const WebsocketContext = createContext();

export const WebsocketProvider = ({ children }) => {
  const websocket = useCustomerWebsocket();

  return (
    <WebsocketContext.Provider value={websocket}>
      {children}
    </WebsocketContext.Provider>
  );
};
