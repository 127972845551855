export const safety = ({ tables, programFields }) => {
  const safetyStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    safetyApplicationStatus: () => ({
      statusData: tables?.Safety?.map((a) => {
        return {
          label: a?.safetyName || "Save Draft",
          value: a?.safetyApplicationStatus,
          color:
            safetyStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.safetyApplicationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.safetyApplicationId,
        };
      }),
    }),

    safetyName: () => ({
      statusData: tables?.Safety?.map((a) => {
        return {
          label: a?.safetyName || "Save Draft",
          value: a?.safetyApplicationStatus,
          color:
            safetyStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.safetyApplicationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.safetyApplicationId,
        };
      }),
    }),
    projectName: () => ({
      statusData: tables?.Safety?.map((a) => {
        return {
          label: a?.safetyApplicationObject?.projectName,
          value: a?.safetyApplicationStatus,
          color:
            safetyStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.safetyApplicationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.projectId,
        };
      }),
    }),

    accountName: () => ({
      statusData: tables?.Safety?.map((a) => {
        return {
          label: a?.safetyApplicationObject?.accountName,
          value: a?.safetyApplicationStatus,
          color:
            safetyStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.safetyApplicationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.accountId,
        };
      }),
    }),

    scheduleAddress: () => ({
      statusData: tables?.Safety?.map((a) => {
        return {
          label: a?.safetyApplicationObject?.scheduleAddress,
          value: a?.safetyApplicationStatus,
          color:
            safetyStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.safetyApplicationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.scheduleId,
        };
      }),
    }),
  };
};
