import { isEqual } from "lodash";
import { lazyFetch } from "../../../../../../../../utils";
import Geofence from "../../../../../../../commonComponents/Map/Geofence/Geofence";
import { SmallLoadableComp } from "../../../../../../../Sidebars/components";
import { useRequestModalContext } from "../../../../store";
import { getLatLng } from "./utils";
import { useEffect, useState } from "react";

const GeofenceDrawRequest = ({
  onChangeAddressValue,
  onChangeAccountName,
  setChanges,
}) => {
  const {
    geoFenceInfo,
    setGeoFenceInfo,
    geoLocation,
    setGeoLocation,
    originalRequestForm,
  } = useRequestModalContext();
  const hasGeoChanges = originalRequestForm?.requestObject?.geoFenceInfo?.length
    ? !isEqual(originalRequestForm?.requestObject?.geoFenceInfo, geoFenceInfo)
    : false;

  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");

  const rowData = {
    projectAddress: onChangeAddressValue || "",
    opportunityAddress: onChangeAddressValue || "",
    accountName,
  };

  useEffect(() => {
    // setTimeout(() => {
    if (onChangeAddressValue.length && onChangeAddressValue.length > 4)
      setLoading(true);
    getLatLng(onChangeAddressValue)
      .then((location) => {
        if (location) {
          setGeoLocation(location);
        } else {
          setGeoLocation();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoading(false);
        console.error("Error occurred:", error);
      });

    // }, 3000);
  }, [onChangeAddressValue]);

  useEffect(() => {
    if (onChangeAccountName) {
      lazyFetch({
        tableName: "accounts",
        filterKey: "accountId",
        filterValue: onChangeAccountName,
      }).then((res) => {
        if (res) {
          const proceedRes = Array.isArray(res)
            ? res?.[0]?.accountName || ""
            : [res?.[0]?.accountName];
          setAccountName(proceedRes);
        }
      });
    }
  }, [onChangeAccountName]);

  useEffect(() => {
    if (hasGeoChanges) {
      setChanges(hasGeoChanges);
    } else {
      setChanges(hasGeoChanges);
    }
  }, [hasGeoChanges]);

  return (
    <SmallLoadableComp loading={loading}>
      {!loading && !geoLocation ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
          }}
        >
          No location found or invalid address
        </div>
      ) : (
        !loading && (
          <Geofence
            {...{
              places: [
                {
                  coordinates: geoLocation,
                  address: onChangeAddressValue,
                },
              ],
              geoFenceInfo,
              setGeoFenceInfo,
              rowData,
            }}
          />
        )
      )}
    </SmallLoadableComp>
  );
};

export default GeofenceDrawRequest;
