import { Modal } from "antd";
import "./NoteImageViewer.scss";

export const NoteImageViewer = ({
  imgUrl,
  visible,
  handleImageModalVisibility,
}) => {
  return (
    <Modal
      wrapClassName="image-preview-modal"
      open={visible}
      closable={false}
      centered={true}
      onCancel={handleImageModalVisibility}
      footer={null}
      maskClosable={true}
    >
      <img src={imgUrl} style={{ width: 50, height: 50 }} alt="No image"></img>
    </Modal>
  );
};

export default NoteImageViewer;
