import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { Redirect } from "../../../../../../../../../../BasePage/src";
import { redirectToSchedule } from "./redirectToScheduleFunctions";
import { useRedux } from "../../../../../../../../../../hooks";
import PdfProjectPreview from "../../../../../../../../../ExportToExel/PdfProjectPreview";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { SchedulingWhite } from "../../../../../../../../../../../../icons";
import "./RedirectToSchedule.scss";
import RentalPreview from "../RentalPreview/RentalPreview";
import { ScheduleOfValuesIcon } from "../../../../../../../../../../../../icons/Settings";
import { CalendarOutlined } from "@ant-design/icons";
// import { useStateContext } from "../../../../../../Context/Context"

const RedirectToSchedule = () => {
  const [fakeRentals] = useRedux("fakeRentals");
  const [visible, setVisible] = useState(false);
  // const { fakeRentals } = useStateContext()
  const [schedules, setSchedules] = useState([]);
  const [client, setClient] = useState("");

  useEffect(() => {
    if (fakeRentals?.scheduleId && visible) {
      API.get("scheduling", `/scheduling/${fakeRentals?.scheduleId}`).then(
        (res) => {
          console.log("res", { res, fakeRentals });
          setSchedules([res]);
        }
      );
      API.get("projects", `/projects/${fakeRentals?.projectId}`).then((res) => {
        if (!!res?.accountName) {
          setClient(res?.accountName ?? "");
        }
      });
    }
  }, [fakeRentals?.scheduleId, visible]);

  return (
    <>
      {fakeRentals?.scheduleId && (
        <div
          className="redirectToScheduleContainer"
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          <MondayButton
            className="mondayButtonBlue"
            onClick={() =>
              redirectToSchedule({ scheduleId: fakeRentals?.scheduleId })
            }
            hasIcon={false}
            // Icon={<Redirect />}
          >
            <div className="iconHolder">
              <Redirect />
            </div>
          </MondayButton>
          <MondayButton
            onClick={() => setVisible(true)}
            {...{
              className: "mondayButtonBlue",
              hasIcon: false,
            }}
          >
            <div className="iconHolder">
              <SchedulingWhite
                style={{
                  transform: "scale(0.7)",
                }}
              />
            </div>
          </MondayButton>
          {visible && (
            <PdfProjectPreview
              {...{
                previewVisibile: visible,
                setPreviewVisible: setVisible,
                services: [],
                projectAccountName: client,
                schedule: schedules,
                darkMode: true,
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RedirectToSchedule;
