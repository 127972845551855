import { queryParams } from "../../../../../../utils/queryParams";

export const exitIfInEditMode = (location, invoiceData, navigate) => {
  if (location?.state?.goBackTo === "accountingGrid") {
    navigate(-1);
  } else {
    navigate(
      //the first parameter is the link
      `/projects/${invoiceData.projectId}?${queryParams({
        tab: "Accounting",
        subTab: "Invoice",
      })}`,
      //those parameters are used to send it directly to the rentals tab
      {
        fromAccounting: true,
        accountingTab: "Invoice",
        tabPosition: "accounting",
      }
    );
  }
};
