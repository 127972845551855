import {
  getBoroughSum,
  repeatedElementSum,
} from "../../../../NewDashboardFunctions";

export const scheduling = ({ tables, programFields }) => {
  return {
    typeOfWork: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Scheduling Types")
        ?.fieldOptions?.map(({ typeName }) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Scheduling?.filter((a) => a?.typeOfWork === typeName)
              .length /
              tables?.Scheduling?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: typeName,
          done: tables?.Scheduling?.filter((a) => a?.typeOfWork === typeName)
            .length,
        })),
    }), //typeofWork

    projectExecutive: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Scheduling, "projectExecutive")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Scheduling?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    projectManager: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Scheduling, "projectManagers")
      )?.map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Scheduling?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
    scheduleAddress: () => ({
      content: Object.entries(
        getBoroughSum(tables?.Scheduling, "scheduleAddress")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Scheduling?.length) * 100)?.toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
  }; //schaduling
};
