import { EditOutlined } from "@ant-design/icons";
import { InfoWindow } from "@react-google-maps/api";
import moment from "moment";
import MondayButton from "src/components/commonComponents/MondayButton/MondayButton";
import useCalculateCenterOfCoordinates from "src/hooks/useCalculateCenterOfCoordinates";

const ShapeWidowInfo = ({
  movingMouseAroundPolygon,
  setClickedShape,
  setShapeInfo,
  setMovingMouseAroundPolygon,
  setLogOfMovingMouseAroundShape,
  allowEditing,
  showInfoWindow,
  handleClickedShape,
  getProgramFieldsField,
}) => {
  const allowEditingDynamicly =
    movingMouseAroundPolygon.type === "Polygon"
      ? !getProgramFieldsField("Polygon")?.allowEditing
      : !getProgramFieldsField("Circle")?.allowEditing;
  const { calculateCenter } = useCalculateCenterOfCoordinates();
  return (
    showInfoWindow && (
      <InfoWindow
        position={calculateCenter(movingMouseAroundPolygon.geoFenceInfo)}
      >
        <div className="window-tooltip-info">
          {/* <div className="window-tooltip-head">Shape Info</div> */}
          <div className="window-tooltip-body">
            <span>Type</span>
            <span>{movingMouseAroundPolygon.type || "-||-"}</span>
          </div>
          <div className="window-tooltip-body-title">
            <span>Title:</span>
            <span className="window-tooltip-body-title-text">
              {movingMouseAroundPolygon.title || "-||-"}
            </span>
          </div>
          <div className="window-tooltip-body">
            <span>Desciption</span>
            <span
              title={
                movingMouseAroundPolygon?.description?.length > 17 &&
                movingMouseAroundPolygon?.description
              }
            >
              {movingMouseAroundPolygon?.description?.length > 17
                ? `${movingMouseAroundPolygon?.description?.substring(
                    0,
                    15
                  )}...`
                : movingMouseAroundPolygon?.description || "-||-"}
            </span>
          </div>
          <div className="window-tooltip-body">
            <span>Created By</span>
            <span>{movingMouseAroundPolygon.createdBy || "-||-"}</span>
          </div>
          <div className="window-tooltip-body">
            <span>Created At</span>
            <span>{moment(movingMouseAroundPolygon.createdAt).fromNow()}</span>
          </div>
          {allowEditing && (
            <div className="window-tooltip-footer">
              {/* <InfoCircleFilled style={{ fontWeight: "500", fontSize: "13px" }} />
            <span style={{ color: "#e6951e" }}>
              Click the shape to go in edit mode
            </span> */}
              <span>
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<EditOutlined />}
                  disabled={allowEditingDynamicly}
                  onClick={() => {
                    setClickedShape(movingMouseAroundPolygon);
                    setShapeInfo({
                      title: movingMouseAroundPolygon?.title,
                      description: movingMouseAroundPolygon?.description,
                    });
                    setMovingMouseAroundPolygon(false);
                    setLogOfMovingMouseAroundShape(movingMouseAroundPolygon);
                    handleClickedShape(movingMouseAroundPolygon);
                  }}
                >
                  Click to Go Edit Mode
                </MondayButton>
              </span>
            </div>
          )}
        </div>
      </InfoWindow>
    )
  );
};

export default ShapeWidowInfo;
