import { API } from "aws-amplify";
import { showErrorMsg } from "../../../../../../../../utils";

export const updateExistingPayments = async ({
  paymentData,
  invoiceId,
  newAmountDue,
  newCreditedAmount,
}) => {
  try {
    let totalPaidAmount = 0;
    paymentData.forEach((payment) => {
      payment.invoices.forEach((invoice) => {
        if (invoice.invoiceId === invoiceId) {
          // Update invoiceAmountDue
          invoice.invoiceAmountDue = newAmountDue;

          // Update invoiceCreditedAmount
          invoice.invoiceCreditedAmount = newCreditedAmount;
          totalPaidAmount += invoice.invoicePaidAmount;
        }
      });
    });

    const filteredUpdatedPayments = paymentData
      .filter((payment) =>
        payment.invoices.some((invoice) => invoice.invoiceId === invoiceId)
      )
      .map((payment) => {
        return {
          ...payment,
          invoices: payment.invoices
            .filter((invoice) => invoice.invoiceId === invoiceId)
            .map((invoice) => {
              return {
                ...invoice,
                invoiceAmountDue:
                  invoice.invoiceAmountDue -
                  (totalPaidAmount - invoice.invoicePaidAmount),
              };
            }),
        };
      });

    filteredUpdatedPayments.forEach(async (payment) => {
      await API.put("payments", `/payments/${payment.paymentId}`, {
        body: {
          invoices: payment.invoices,
        },
      });
    });
  } catch (error) {
    console.log("Error updating payments:", error);
    showErrorMsg({
      content: "Error updating payments. Please contact the administrator.",
    });
  }
};
