import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../utils";

export const createDriveFolders = async ({
  folderName,
  driveParentId,
  driveRequest,
}) => {
  let ids;
  showLoadingMsg({ content: "Google Drive folders are being created..." });
  try {
    await driveRequest
      .getFolderIdOrCreate({
        name: folderName.replace("&", ""),
        parents: [driveParentId],
      })
      .then(async (id) => {
        if (id) {
          await driveRequest
            .createFolders({
              folderNames: [
                "Opportunities",
                "Projects",
                "Documentation",
                "Contacts",
              ],
              parents: [id],
            })
            .then((r = {}) => {
              ids = { folderId: id, subFolderId: { ...r } };

              showSuccessMsg({
                content: "Google Drive folders were created successfully!",
              });
            })
            .catch((e) => console.error(e));
        }
      });
  } catch (e) {
    console.error(e);
    showErrorMsg({
      content: "Something went wrong, Google Drive folders were not created.",
    });
  }

  return ids;
};
