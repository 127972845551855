import { EditIcon } from "../../../../../../../../../../../../../../../DynamicView/src"
import ApplyRentalCheckBox from "../../../../ApplyRentalCheckBox/ApplyRentalCheckBox"
import { formatCurrency } from "../../../../../../../../../../../../../../../utils"
import LockRow from "../../LockRow/LockRow"

import dayjs from "dayjs"
import ChargedIndicator from "../../ChargedIndicator/ChargedIndicator"

const whichOne = "day"

export const columnDefs = ({ selectedElevation, selectedItem, selectedWeek }) => [
  {
    headerName: "Day",
    field: "id",
    cellRenderer: ({ value }) => `Day ${value}`,
    editable: false,
    flex: 1,
  },
  {
    headerName: "Apply",
    field: "applied",
    cellRendererFramework: (params) => (
      <div className="applyCheckboxParams">
        <ApplyRentalCheckBox
          {...{
            pliData: selectedItem,
            elevationData: selectedElevation,
            whichOne,
            params: { thisDay: params?.data, thisWeek: selectedWeek },
          }}
        />
        Apply Rental
      </div>
    ),
    editable: false,
    flex: 1.5,
  },
  {
    headerName: "Date",
    field: "day",
    cellRenderer: ({ value }) => dayjs(value)?.format("MM/DD/YYYY"),
    editable: false,
    flex: 1,
  },

  {
    headerName: "Daily Amount",
    cellEditor: "PeriodCellEditor",
    field: "dailyAmount",
    cellRendererFramework: ({ value }) => (
      <div className="rentalEditableCell">
        {formatCurrency(value || 0)}
        <EditIcon />
      </div>
    ),
    flex: 1.5,
  },
  {
    headerName: "Charged",
    field: "charged",
    editable: false,
    flex: 1,
    cellRendererFramework: (params) => (
      <ChargedIndicator
        {...{
          thisElevation: selectedElevation?.elevationId,
          whichOne,
          params: {
            thisPli: selectedItem?.id,
            thisWeek: selectedWeek?.week,
            thisDay: params?.data?.id,
          },
        }}
      />
    ),
  },
  {
    headerName: "Lock",
    field: "lock",
    cellRendererFramework: (params) => (
      <LockRow
        {...{
          locked: !!params?.value,
          whichFunction: "day",
          propsObject: {
            params,
            selectedElevation,
            selectedItem,
            selectedWeek,
          },
        }}
      />
    ),
    editable: false,
    flex: 0.5,
  },
]
