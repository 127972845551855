import API from "@aws-amplify/api";

export const fetchData = async (
  endpoint,
  query,
  queryStringParameters = undefined
) => (
  (query = query || endpoint),
  await API.get(endpoint, `/${query}`, queryStringParameters)
);
