import { message } from "antd";
import moment from "moment";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { formatCurrency } from "../../../../../../../../../../utils";
import ElevationLevelCredit from "../ElevationLevelCredit/ElevationLevelCredit";
import AmountReq from "../../../../Service/ServiceContent/GridData/GridUtils/amountFactory";

export const columnDefs = ({
  rental,
  setRowData,
  rowData,
  setServiceVis,
  setCurrentRental,
}) => [
  {
    headerName: "No.",
    cellRenderer: (params) => params?.rowIndex + 1,
    flex: 1,
  },
  {
    headerName: "Service",
    flex: 2,
    field: "label",
  },
  // {
  // 	headerName: "Start Date",
  // 	field: "startDate",
  // 	flex: 2,
  // 	cellRenderer: ({ value }) => (!!value ? moment(value)?.format("MM/DD/YYYY") : "--"),
  // },

  // {
  // 	headerName: "End Date",
  // 	field: "endDate",
  // 	flex: 2,
  // 	cellRenderer: ({ value }) => (!!value ? moment(value)?.format("MM/DD/YYYY") : "--"),
  // },
  {
    headerName: "Weeks",
    field: "weeks",
    flex: 1,
    cellRenderer: ({ data }) => {
      if (!!data?.startDate && !!data?.endDate) {
        const msInWeek =
          moment(data.endDate).diff(moment(data.startDate), "days") + 1;
        let temp = (Math.abs(msInWeek) / 7).toFixed(1);
        return temp;
      } else {
        return "0";
      }
    },
  },
  {
    headerName: "Applied Rent",
    field: "appliedAmount",
    headerTooltip: "Applied Rent without tax",
    cellRenderer: ({ data }) => {
      return formatCurrency(data?.appliedAmount || 0);
    },
    flex: 1,
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    headerTooltip: "Tax Rate in %",
    flex: 2,
    cellRenderer: ({ value }) => (!!value ? `${value * 100}%` : "--"),
  },

  {
    headerName: "Tax Amount",
    field: "taxAmount",
    headerTooltip: "Tax Amount on Applied Rent",
    cellRenderer: ({ data }) => {
      let credit =
        data?.serviceLevelCredit ||
        0 + data?.serviceLevelCredit ||
        0 * data?.taxRate ||
        0;

      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount || 0 + credit;
        return formatCurrency(taxAmount);
      } else {
        return formatCurrency(0);
      }
    },
    flex: 1,
  },
  {
    headerName: "Retainage %",
    field: "retainage",
    headerTooltip: "Retainage %",
    flex: 2,
    valueGetter: ({ data }) => {
      return data?.retainage;
      // let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
      // return totalities.retainageAmount || 0;
    },
    // cellRenderer: ({ data }) => {
    // 	let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
    // 	return formatCurrency(totalities.retainageAmount || 0);
    // },
  },
  {
    headerName: "Retainage Amount",
    field: "retainage",
    headerTooltip: "Retainage Amount",
    flex: 2,
    valueGetter: ({ data }) => {
      let credit =
        data?.serviceLevelCredit ||
        0 + data?.serviceLevelCredit ||
        0 * data?.taxRate ||
        0;
      let tax = data?.taxRate || 0;
      if (data?.retainage > 0) {
        return (
          (data?.retainage / 100) *
          (data?.appliedAmount + data?.appliedAmount * tax + credit)
        );
      } else {
        return 0;
      }
      // let totalities = totalitiesTotalTaxGen({ fakeRentals: data });
      // return totalities.retainageAmount || 0;
    },
    cellRenderer: ({ value }) => {
      return formatCurrency(value || 0);
    },
  },
  {
    headerName: "Applied + Tax",
    field: "totalApplied",
    headerTooltip: "Applied Rent with tax",
    cellRenderer: ({ data }) => {
      // console.log("data", data);

      if (data?.taxRate > 0) {
        let taxAmount = data?.taxRate * data?.appliedAmount;
        // let retainage = (data?.retainage / 100) * (data?.appliedAmount + taxAmount) || 0;
        return formatCurrency(data?.appliedAmount + taxAmount);
      } else {
        return formatCurrency(data?.appliedAmount);
      }
    },
    flex: 1,
  },
  {
    headerName: "Credit",
    editable: true,
    field: "serviceLevelCredit",
    headerTooltip: "Service Level Credit",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRenderer: ({ data }) => {
      return formatCurrency(data?.serviceLevelCredit || 0);
    },
    valueSetter: (params) => {
      if (
        params?.newValue?.includes("%") &&
        !isNaN(parseFloat(params?.newValue?.replaceAll("%", "")))
      ) {
        let data = params?.data;
        let num = params?.newValue?.replaceAll("%", "") || 0;
        let taxAmount = data?.taxRate * data?.appliedAmount;
        // return formatCurrency(data?.appliedAmount + taxAmount);
        let tApplied = params?.data?.appliedAmount + taxAmount;
        if (!isNaN(data?.retainage))
          tApplied = tApplied + (tApplied * data?.retainage) / 100;

        params.data.serviceLevelCredit =
          parseFloat(params.newValue) > 0
            ? -parseFloat((num / 100) * tApplied)
            : parseFloat((num / 100) * tApplied);

        params.api.refreshCells({ force: true });
        return true;
      } else if (isNaN(params.newValue)) {
        message.error("Please insert a valid number");
        return false;
      } else if (params.newValue > 1000000000000000) {
        message.error("input is larger than allowed");
        return false;
      } else {
        params.data.serviceLevelCredit =
          parseFloat(params.newValue) > 0
            ? -parseFloat(params.newValue)
            : parseFloat(params.newValue);
        let myAmount = new AmountReq().addRentalServiceCredit(params.data);

        params.data = { ...myAmount };
        params.api.refreshCells({ force: true });
        return true;
      }

      /// FINALLY
    },
  },
  //   {
  //     headerName: "T-C",
  //     // field: "TotalCredit",
  //     cellStyle: {
  //       color: "white",
  //       fontWeight: "bold",
  //     },
  //     cellRenderer: ({ data }) => {
  //       console.log("data", data);
  //       let credit =
  //         data?.serviceLevelCredit ||
  //         0 + data?.serviceLevelCredit ||
  //         0 * data?.taxRate;
  //       if (data?.taxRate > 0) {
  //         let taxAmount = data?.taxRate * data?.appliedAmount;
  //         let retainage1 =
  //           (data?.retainage / 100) * (data?.appliedAmount + taxAmount);
  //         return formatCurrency(
  //           data?.appliedAmount + taxAmount + credit + retainage1
  //         );
  //       } else {
  //         let retainage2 = (data?.retainage / 100) * data?.appliedAmount;
  //         return formatCurrency(data?.appliedAmount + credit + retainage2);
  //       }
  //     },
  //   },
  {
    headerName: "Details",
    cellRendererFramework: (params) => {
      return (
        <ElevationLevelCredit
          {...{
            params,
            rental,
            setRowData,
            rowData,
            setServiceVis,
            setCurrentRental,
          }}
        />
      );
    },
  },
];

const totalitiesTotalTaxGen = ({ fakeRentals }) => {
  // console.log("to result", { fakeRentals });

  let carriedProjectTax = fakeRentals?.taxRate || 0;
  let result = {
    totalTax: 0,
    totalPrice: Math.round(appliedAmountGenerator({ fakeRentals })) || 0,
  };
  if (carriedProjectTax > 0) {
    result.totalTax = result.totalPrice * carriedProjectTax;
    result.totalPrice = result.totalPrice + result.totalTax;
  }
  result.retainageAmount = fakeRentals?.services?.reduce((acc, curr) => {
    let currentService = Math.round(
      appliedAmountGenerator({
        fakeRentals: { ...fakeRentals, services: [curr] },
      })
    );
    let tempRet = curr?.retainage || 0;
    if (tempRet > 0) {
      return acc + currentService * (tempRet / 100);
    } else return acc + 0;
  }, 0);

  return result;
};
const appliedAmountGenerator = ({ fakeRentals }) => {
  return (
    fakeRentals?.services
      // here we extract all services
      ?.flatMap(({ serviceOptions }) =>
        serviceOptions?.flatMap(({ items }) =>
          // from the extracted services we extract all weeks and take the amount from those weeks
          items?.flatMap(({ rentalDetails }) =>
            rentalDetails?.details?.map(({ weeklyAmount }) => weeklyAmount)
          )
        )
      )
      //here we add all extracted weekAmounts together
      ?.reduce((acc, curr) => acc + curr, 0)
  );
};

export const valueChangerHandler = ({
  params,
  rowData,
  setRowData,
  setTotalities,
  setCurrentRental,
}) => {
  // console.log("new params", {
  //   params,
  //   rowData,
  //   totalities: getTotalities({ rowData }),
  // });
  setTotalities(getTotalities({ rowData }));
  setCurrentRental(rowData);
  // setRowData([]);
  // console.log("params", params);
  // if (parseFloat(params?.newValue) > 400) {
  // 	return false;
  // } else {
  // 	message.success("Value Changed");
  // }
};

export const getTotalities = ({ rowData }) => {
  // let credit = data?.serviceLevelCredit || 0;
  // console.log("row data render", rowData);
  let result = {
    totalTax: 0,
    totalRetainage: 0,
    totalCredit: 0,
    totalPrice: 0,
  };
  if (Array.isArray(rowData)) {
    rowData?.forEach((row) => {
      result.totalTax += row?.taxRate * row?.appliedAmount || 0;
      result.totalRetainage +=
        (row?.retainage / 100) *
        (row?.appliedAmount + result?.totalTax + result.totalCredit);
      result.totalCredit +=
        row?.serviceLevelCredit ||
        0 + (row?.serviceLevelCredit || 0 * row?.taxRate || 0);
      result.totalPrice +=
        row?.appliedAmount +
        (row?.taxRate * row?.appliedAmount +
          result.totalRetainage +
          result.totalCredit);
    });
    // result.totalPrice;
    return result;
  } else {
    return result;
  }
};

export const autoSizeGridColumns = (event) => {
  setTimeout(() => {
    const widths = {
      rowWidth: parseInt(
        document.getElementsByClassName("ag-row")[0]?.offsetWidth
      ),
      parentWidth: parseInt(
        document.getElementsByClassName("ag-body-viewport")[0]?.offsetWidth
      ),
    };
    if (widths.rowWidth < widths.parentWidth - 50) {
      event?.api.sizeColumnsToFit();
    } else {
      event?.columnApi.autoSizeAllColumns(false);
    }
  }, 500);
};
