import dayjs from "dayjs";

// Generates start of times when needed to subtract 1(day, week, month, or year)
const getLastStartTimeUnit = (timeUnit) => (value) =>
  dayjs(value)?.startOf(timeUnit)?.subtract(1, timeUnit)?.valueOf();

// Generates end of times when needed to subtract 1(day, week, month, or year)
const getLastEndTimeUnit = (timeUnit) => (value) =>
  dayjs(value)?.endOf(timeUnit)?.subtract(1, timeUnit)?.valueOf();

// Generates start of times (day, week, month, or year)
const getStartOfTime = (timeUnit) => (value) =>
  dayjs(value)?.startOf(timeUnit).valueOf();

// Generates end of times (day, week, month, or year)
const getEndOfTime = (timeUnit) => (value) =>
  dayjs(value)?.endOf(timeUnit).valueOf();

export const getStartOf = {
  today: getStartOfTime("day"),
  yesterday: getLastStartTimeUnit("day"),
  thisWeek: getStartOfTime("week"),
  lastWeek: getLastStartTimeUnit("week"),
  thisMonth: getStartOfTime("month"),
  lastMonth: getLastStartTimeUnit("month"),
  thisYear: getStartOfTime("year"), // Added this line
  lastYear: getLastStartTimeUnit("year"), // Added this line
};

export const getEndOf = {
  today: getEndOfTime("day"),
  yesterday: getLastEndTimeUnit("day"),
  thisWeek: getEndOfTime("week"),
  lastWeek: getLastEndTimeUnit("week"),
  thisMonth: getEndOfTime("month"),
  lastMonth: getLastEndTimeUnit("month"),
  thisYear: getEndOfTime("year"), // Added this line
  lastYear: getLastEndTimeUnit("year"), // Added this line
};
