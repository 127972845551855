export const invoices = (
  programFields,
  updatedDynamicStates,
  invoicesData,
  IdHandler
) => {
  const invoiceStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Invoices")
    ?.fieldOptions?.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    invoiceStatus: () => ({
      title: "Invoices - Invoice Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Invoices",
        field: "invoiceStatus",
        labels: invoiceStatus?.statusName,
        datasets: [
          {
            label: "Invoices",
            data: invoiceStatus?.statusName?.map(
              (status) =>
                invoicesData?.filter((a) => a?.invoiceStatus === status)?.length
            ),
            backgroundColor: invoiceStatus?.statusColor,
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
