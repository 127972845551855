import React, { useState } from "react";

import ServicesModal from "./ServicesModal";
import Button from "../../../../../../../commonComponents/Buttons/Button";

import "./MoreServices.scss";

const MORE_SERVICES_DESCRIPTION = " More Services | Watch All Services";

const MoreServices = ({ children, hasOtherServices, services, title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="moreChargeServices">
      {children}
      {hasOtherServices > 0 && (
        <Button
          className="mondayButtonBlue ant-design-button-services"
          hasIcon={false}
          size="small"
          onClick={() => setIsModalOpen(true)}
        >
          {hasOtherServices} {MORE_SERVICES_DESCRIPTION}
        </Button>
      )}
      {isModalOpen && (
        <ServicesModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          services={services}
          title={title}
        />
      )}
    </div>
  );
};

export default MoreServices;
