import {
  Avatar,
  Button,
  Checkbox,
  Input,
  message,
  Select,
  Tooltip,
  TimePicker,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MondayButton } from "../../../../../../../commonComponents";
import { Tick } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { createCrewsWithHierarchy } from "../../../../helpers/crews-dispatch-fleet/crews-utils";
import CrewActivityModal from "./CrewActivityModal";
import { CREW_FLEET_POPOVER_TYPE } from "../../CrewTrucksSelectPopover";
import {
  InfoIconBlue,
  NotWorkedHereIcon,
  WorkedHereIcon,
} from "../TrucksModal/HelpingComponents/icons";
import { getRandomColor } from "../../../../helpers/creators";
import moment from "moment";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import CrewNrForDay from "./CrewNrForDay";
import { setDateTime } from "../../../../SchedulingFirstPage/helperData";
import { DropDownArrow } from "../../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const CrewsModal = ({
  dayIndex,
  crewsInfo: { crews, crewActivity },
  scheduleDay,
  onPopoverVisibilityChanges,
  isDarkMode,
  thisProjectSchedules,
  updateScheduleCrews,
  scheduleCrews,
  createdAt,
  newCrewsUpdate,
  listOfTeams,
}) => {
  const [crewNrVisible, setCrewNrForVisible] = useState(false);
  const [desiredNr, setDesiredNr] = useState(
    parseFloat(scheduleDay?.desiredCrewNr) || 0
  );

  const [crewObject, setCrewObject] = useState(
    createCrewsWithHierarchy(
      crews,
      crewActivity,
      scheduleCrews,
      thisProjectSchedules,
      listOfTeams,
      scheduleDay,
      createdAt,
      newCrewsUpdate
    )
  );

  const totalCrewNrForDay = thisProjectSchedules?.map((a) => ({
    scheduleName: a?.scheduleName,
    scheduleAddress: a?.scheduleAddress,
    scheduleId: a?.scheduleId,
    typeOfWork: a?.typeOfWork,
    crews: a?.scheduleDays
      ?.filter(
        (el) =>
          el?.status !== "Canceled" &&
          el?.status !== "Postponed" &&
          el?.status !== "Skipped" &&
          dayjsNY(el?.startDate).isSame(dayjsNY(scheduleDay?.startDate), "day")
      )
      ?.flatMap(({ crews = [] }) => crews),
  }));

  const usedCrewNames = totalCrewNrForDay?.flatMap((a) => a?.crews);

  const usedCrewNr = totalCrewNrForDay?.reduce(
    (prev, curr) => prev + curr?.crews?.length,
    0
  );

  const [filteredCrews, setFilteredCrews] = useState({});
  const [activityTableData, setActivityTableData] = useState(undefined);
  const [selectedCrewsObject, setSelectedCrewsObject] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchRole, setSearchRole] = useState("");
  const [crewActivityModalVisible, setCrewActivityModalVisible] =
    useState(false);
  const crewsAsNumber = !Array.isArray(scheduleDay?.crews)
    ? scheduleDay?.crews
    : 0;

  const onConfirm = (selectedCrewObject) => {
    if (!!newCrewsUpdate) {
      updateScheduleCrews(scheduleDay, selectedCrewObject);
    } else {
      scheduleDay.crews = !selectedCrewObject?.length
        ? []
        : selectedCrewObject?.map((crew) => {
            return {
              crewName: crew?.crewName,
              crewId: crew?.crewId,
              crewPosition: crew?.crewPosition,
              startTime: crew?.startTime,
              endTime: crew?.endTime,
              crewStatus: crew?.crewStatus,
              members: crew?.members || [],
              open:
                crew?.members?.length > 0
                  ? crew?.members?.some((m) => !!m?.selected)
                  : false,
              selected: crew?.selected,
              accountName: crew?.accountName,
              crewTeamName: crew?.crewTeamName,
            };
          });
      scheduleDay.desiredCrewNr = parseFloat(desiredNr);
      if (scheduleDay?.crews?.length === 0) {
        if (crewsAsNumber > 0) scheduleDay.crews = crewsAsNumber;
        else onPopoverVisibilityChanges(false, false);
      }
    }

    onPopoverVisibilityChanges(
      false,
      true,
      scheduleDay,
      dayIndex,
      CREW_FLEET_POPOVER_TYPE.CREWS
    );
  };

  const onCancel = () => {
    setCrewObject(
      createCrewsWithHierarchy(
        crews,
        crewActivity,
        scheduleDay,
        thisProjectSchedules
      )
    );
    onPopoverVisibilityChanges(
      false,
      false,
      null,
      null,
      CREW_FLEET_POPOVER_TYPE.CREWS
    );
  };

  const filterCrews = (crewDataObject, regexToUse) => {
    const selectedCrewData = {};
    if (regexToUse === "") return crewDataObject;
    // regexToUse = new RegExp(regexToUse, "i");
    for (const [foremanId, foreman] of Object.entries(crewDataObject || {})) {
      const foreman_copy = structuredClone(foreman);
      foreman_copy.members = foreman_copy?.members?.filter(
        (m) => m?.crewName?.toLowerCase().search(regexToUse?.toLowerCase()) > -1
      );
      if (foreman_copy?.members?.length > 0) {
        foreman_copy.open = true;
      }
      if (
        foreman_copy?.members?.length === 0 &&
        !(
          foreman_copy?.crewName
            ?.toLowerCase()
            .search(regexToUse?.toLowerCase()) > -1
        )
      )
        continue;
      selectedCrewData[foremanId] = foreman_copy;
    }
    return selectedCrewData;
  };
  const collapseHandler = (foremanId) => {
    const filteredCrewObjectCopy = structuredClone(crewObject);
    const foreman = filteredCrewObjectCopy?.[foremanId];
    foreman.open = !foreman?.open;
    setCrewObject(filteredCrewObjectCopy);
  };

  const handleSelectForeman = (selected, foremanId) => {
    const filteredCrewObjectCopy = structuredClone(crewObject);
    const foreman = filteredCrewObjectCopy[foremanId];

    if (
      !crewActivity?.find(
        (c) =>
          c?.crewId === foremanId &&
          moment(c?.startDate).format("MM/DD/YYYY h:mm a") ===
            moment(scheduleDay?.startDate).format("MM/DD/YYYY h:mm a")
      )
    ) {
      foreman.selected = selected;
      for (const member of foreman?.members || []) {
        member.selected = selected;
      }
    } else {
      message.error("You can't select a crew that has already worked today!");
    }

    setCrewObject(filteredCrewObjectCopy);
  };

  const handleSelectMember = (selected, foremanId, memberId) => {
    const filteredCrewDataCopy = structuredClone(crewObject);
    let foreman = filteredCrewDataCopy[foremanId];

    for (const member of foreman?.members || []) {
      if (member?.crewId === memberId) {
        member.selected = selected;
        break;
      }
    }
    setCrewObject(filteredCrewDataCopy);
  };

  const getSelectedCrewsObject = (crewDataObject) => {
    const crewDataObject_copy = structuredClone(crewDataObject);
    let selectedCrews = [];
    for (const [foremanId, foreman] of Object.entries(
      crewDataObject_copy || []
    )) {
      if (foreman?.selected) selectedCrews.push(foreman);
      for (const member of foreman?.members || []) {
        if (member?.selected) selectedCrews.push(member);
      }
    }
    return selectedCrews;
  };

  const filteredPosition = (e, type) => {
    if (e === "") {
      setFilteredCrews(filterCrews(crewObject, searchText));
    } else {
      const newCrews = Object.entries(crewObject || {})?.reduce(
        (prev, [key, value]) => {
          let findData = value?.[type]
            ?.toLowerCase()
            ?.includes(e?.toLowerCase());
          if (findData) prev[key] = value;
          return prev;
        },
        {}
      );
      setFilteredCrews(newCrews);
    }
  };

  const workingHoursForCrews = (
    date = null,
    id = "",
    type = "",
    position = "",
    crew = {},
    scheduleDay = {}
  ) => {
    const prvCrews = totalCrewNrForDay?.flatMap((a) => a?.crews);
    const findSameCrew = prvCrews?.filter((a) => a?.crewId === id);

    const getAllRangeHours = findSameCrew
      ?.map((a) => {
        if (a?.startTime && a?.endTime) {
          return {
            startTime: setDateTime(a?.startTime),
            endTime: setDateTime(a?.endTime),
          };
        }
      })
      ?.filter(Boolean);

    if (getAllRangeHours?.length > 0) {
      if (type === "startTime") {
        if (!!crew?.endTime) {
          const currentRange = [setDateTime(date), setDateTime(crew?.endTime)];
          const checkConflicts = getAllRangeHours?.some((a) => {
            return (
              currentRange[0].isBefore(a?.endTime) &&
              currentRange[1].isAfter(a?.startTime)
            );
          });
          if (checkConflicts) {
            message.error(
              "Crew has already been scheduled for this time! Please select another time!"
            );
            return;
          }
        }
      }

      if (type === "endTime") {
        if (!!crew?.startTime) {
          const currentRange = [
            setDateTime(crew?.startTime),
            setDateTime(date),
          ];

          const checkConflicts = getAllRangeHours?.some((a) => {
            return (
              currentRange[0].isBefore(a?.endTime) &&
              currentRange[1].isAfter(a?.startTime)
            );
          });

          if (checkConflicts) {
            message.error(
              "Crew has already been scheduled for this time! Please select another time!"
            );
            return;
          }
        }
      }
    }

    if (type === "startTime" && !!date) {
      if (dayjsNY(date).startOf("minute")?.isAfter(crew?.endTime)) {
        message.error("Start time cannot be after the end time!");
        return;
      } else if (
        parseInTz(setDateTime(date))
          .startOf("minute")
          ?.isBefore(
            setDateTime(dayjsNY(scheduleDay?.startDate)).startOf("minute")
          )
      ) {
        message.error(
          "Start time for a crew cannot be before the starting time of the day!"
        );
        return;
      }
    } else if (type === "endTime" && !!date) {
      if (dayjsNY(date).startOf("minute")?.isBefore(crew?.startTime)) {
        message.error("End time cannot be before the start time!");
        return;
      } else if (
        dayjsNY(date)
          .startOf("minute")
          ?.isAfter(
            setDateTime(dayjsNY(scheduleDay?.endDate)).startOf("minute")
          )
      ) {
        message.error(
          "End time for a crew cannot be after the ending time of the day!"
        );
        return;
      }
    }
    const newCrews = Object.entries(crewObject || {})?.reduce(
      (prev, [key, value]) => {
        if (position === "foreman") {
          if (value?.crewId === id) {
            prev[key] = {
              ...value,
              [type]: !!date ? parseInTz(date).toISOString() : "",
            };
          } else prev[key] = value;
        } else {
          let findMember = value?.members?.find((q) => q?.crewId === id);
          if (findMember) {
            let newMembers = value?.members?.map((m) => {
              if (m?.crewId === id) {
                return {
                  ...m,
                  [type]: !!date ? parseInTz(date).toISOString() : "",
                };
              } else return m;
            });
            prev[key] = { ...value, members: newMembers };
          } else prev[key] = value;
        }
        return prev;
      },
      {}
    );
    setCrewObject(newCrews);
  };
  const subContractors =
    [
      ...new Set(
        Object.values(crews || {})?.map((crew) => {
          return crew?.accountName;
        })
      ),
    ]?.filter(Boolean) || [];

  useEffect(() => {
    setFilteredCrews(filterCrews(crewObject, searchText));
    setSelectedCrewsObject(getSelectedCrewsObject(crewObject));
  }, [searchText, crewObject]);

  return (
    <>
      <div className="trucks-modal-body">
        <div className="trucks-modal-body-header crews-modal-header">
          <div className="trucks-modal-body-header-info">
            <InfoIconBlue />
            <span>Total Crews for this day:</span>
            <Button
              onClick={() => {
                if (usedCrewNr === 0)
                  message.warning("No crews found for this day!");
                else setCrewNrForVisible(true);
              }}
            >
              {usedCrewNr || 0}
            </Button>
            <span>Desired Crew Number:</span>
            <Input
              {...{
                type: "number",
                value: Number(desiredNr),
                onChange: (e) => {
                  if (isNaN(e.target.value) || Number(e.target.value) < 0) {
                    message.error("Please enter a valid number!");
                    return;
                  } else setDesiredNr(Number(e.target.value));
                },
                placeholder: "Enter number...",
                style: {
                  width: 150,
                  borderRadius: 5,
                  backgroundColor: "#F8F8FA",
                  border: "none",
                },
              }}
            />
          </div>

          <div className="trucks-modal-body-header-functionality">
            <div
              className="trucks-modal-search-div crews-modal-search-div"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <Input
                {...{
                  placeholder: "Search crew...",
                  value: searchText,
                  onChange: (e) => setSearchText(e.target.value),
                  style: {
                    width: 260,
                    border: "none",
                    backgroundColor: "#f8f8fa",
                  },
                }}
              />
              <Input
                {...{
                  placeholder: "Search role...",
                  value: searchRole,
                  onChange: (e) => {
                    setSearchRole(e.target.value);
                    filteredPosition(e.target.value, "crewPosition");
                  },
                  style: {
                    width: 260,
                    border: "none",
                    backgroundColor: "#f8f8fa",
                  },
                }}
              />
              <Select
                {...{
                  placeholder: "Subcontractor...",
                  suffixIcon: <DropDownArrow />,
                  popupClassName: isDarkMode && "darkDropDown",
                  allowClear: true,
                  style: {
                    width: 200,
                    border: "none !important",
                    backgroundColor: "#f8f8fa !important",
                  },
                  onSelect: (e) => filteredPosition(e, "accountName"),
                  onClear: () => filteredPosition("", "accountName"),
                }}
              >
                {subContractors?.map((crew, index) => {
                  return <Select.Option value={crew}>{crew}</Select.Option>;
                })}
              </Select>

              <Select
                {...{
                  placeholder: "Availability...",
                  allowClear: true,
                  suffixIcon: <DropDownArrow />,
                  popupClassName: isDarkMode && "darkDropDown",
                  style: {
                    width: 200,
                    border: "none",
                    backgroundColor: "#f8f8fa",
                  },
                  onClear: () => {
                    filteredPosition("");
                  },
                  onSelect: (e) => {
                    const obj = Object.entries(crewObject || {})?.reduce?.(
                      (prev, [key, value]) => {
                        const findData = usedCrewNames?.find(
                          (q) => q?.crewId === value?.crewId
                        );
                        if (
                          (e === "available" && !findData) ||
                          (e === "unavailable" && findData)
                        ) {
                          prev[key] = value;
                        } else if (e === "all") {
                          prev[key] = value;
                        }
                        return prev;
                      },
                      {}
                    );

                    setFilteredCrews(obj);
                  },
                }}
              >
                <Select.Option value="available">Available</Select.Option>
                <Select.Option value="unavailable">Unavailable</Select.Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="trucks-modal-body-content">
          <table className="trucks-modal-body-content-table">
            <thead>
              <tr>
                <th>Select</th>
                <th style={{ textAlign: "left" }}>Crew Name</th>
                <th style={{ textAlign: "left" }}>Role</th>
                <th style={{ textAlign: "left" }}>Subcontractor</th>
                <th>Availability</th>
                <th>Worked Here</th>
                <th>Working Hours</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(filteredCrews || {})?.map((foreman, crewIndex) => {
                let isAvailable = !usedCrewNames?.find(
                  (q) => q?.crewId === foreman?.crewId
                );
                const {
                  crewName: foremanName,
                  members,
                  open,
                  selected: foremanSelected,
                  crewId,
                  crewPosition,
                  startTime,
                  endTime,
                } = foreman;

                const membersCount = members?.length;
                const selectedMembersCount = members?.filter(
                  (m) => m?.selected === true
                )?.length;

                return (
                  <>
                    <tr key={crewIndex}>
                      <td>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "60%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Checkbox
                              // disabled={!isAvailable}
                              className="trucks-modal-checkbox"
                              checked={foremanSelected}
                              onChange={(e) => {
                                handleSelectForeman(!foremanSelected, crewId);
                              }}
                            ></Checkbox>
                          </div>
                          {membersCount > 0 && (
                            <div
                              style={{
                                width: "40%",
                                display: "flex",
                                justifyContent: open ? "flex-end" : "center",
                                alignItems: "center",
                                paddingRight: open ? 8 : 0,
                                transform: open ? "" : "rotate(-90deg)",
                              }}
                              onClick={(e) => collapseHandler(crewId)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11.195"
                                height="7.16"
                                viewBox="0 0 11.195 7.16"
                              >
                                <path
                                  id="Path_7447"
                                  data-name="Path 7447"
                                  d="M8.876,12.666,12.914,16.7a1.553,1.553,0,0,0,2.2,0l4.038-4.038A1.564,1.564,0,0,0,18.043,10H9.967A1.559,1.559,0,0,0,8.876,12.666Z"
                                  transform="translate(-8.414 -10)"
                                  fill="#323338"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        style={{ textAlign: "left" }}
                        onClick={(e) => collapseHandler(crewId)}
                      >
                        {`${foremanName} ( ${selectedMembersCount} / ${membersCount} )`}
                      </td>
                      <td style={{ textAlign: "left" }}>{crewPosition}</td>
                      <td style={{ textAlign: "left" }}>
                        {foreman?.accountName || ""}
                      </td>
                      <td>
                        <StatusButton
                          status={isAvailable ? "Available" : "Unavailable"}
                          color={isAvailable ? "#71CF48" : "#FE4C4A"}
                          // onClick={() => {
                          //   setActivityTableData(foreman?.activities);
                          //   setCrewActivityModalVisible(true);
                          // }}
                        />
                      </td>
                      <td onClick={(e) => collapseHandler(crewId)}>
                        {foreman?.workedHere ? (
                          <WorkedHereIcon />
                        ) : (
                          <NotWorkedHereIcon />
                        )}
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 40,
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <span>Start Time:</span>
                            <TimePicker
                              disabled={!foremanSelected}
                              value={!startTime ? null : dayjsNY(startTime)}
                              popupClassName={isDarkMode && "darkDateDropDown"}
                              onChange={(e) => {
                                workingHoursForCrews(
                                  e,
                                  foreman?.crewId,
                                  "startTime",
                                  "foreman",
                                  foreman,
                                  scheduleDay
                                );
                              }}
                              placeholder="Start Time"
                              format={"h:mm a"}
                              style={{
                                border: "none",
                                width: 130,
                                backgroundColor: "#f8f8fa",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <span>End Time:</span>
                            <TimePicker
                              disabled={!foremanSelected}
                              value={!endTime ? null : dayjsNY(endTime)}
                              popupClassName={isDarkMode && "darkDateDropDown"}
                              onChange={(e) => {
                                workingHoursForCrews(
                                  e,
                                  foreman?.crewId,
                                  "endTime",
                                  "foreman",
                                  foreman,
                                  scheduleDay
                                );
                              }}
                              placeholder="Start Time"
                              format={"h:mm a"}
                              style={{
                                border: "none",
                                width: 130,
                                backgroundColor: "#f8f8fa",
                              }}
                            />
                          </div>
                        </div>
                        {/* <InputComponent type="rangepicker" placeholder="Date" /> */}
                      </td>
                    </tr>
                    {open &&
                      members?.map((member, memberIndex) => {
                        let memberIsAvailable = !usedCrewNames?.find(
                          (q) => q?.crewId === member?.crewId
                        );
                        const {
                          crewName,
                          selected: memberSelected,
                          workedHere: memberWorkedHere,
                          crewId: memberId,
                          foreman: foremanId,
                          crewPosition,
                          startTime,
                          endTime,
                        } = member;
                        return (
                          <tr key={memberIndex}>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Checkbox
                                  disabled={!memberIsAvailable}
                                  className="trucks-modal-checkbox"
                                  checked={memberSelected}
                                  onChange={() =>
                                    handleSelectMember(
                                      !memberSelected,
                                      foreman?.crewId,
                                      memberId
                                    )
                                  }
                                ></Checkbox>
                              </div>
                            </td>
                            <td style={{ textAlign: "left", paddingLeft: 15 }}>
                              {crewName}
                            </td>
                            <td style={{ textAlign: "left", paddingLeft: 15 }}>
                              {crewPosition}
                            </td>
                            <td style={{ textAlign: "left", paddingLeft: 15 }}>
                              {foreman?.accountName || ""}
                            </td>
                            <td>
                              <StatusButton
                                status={
                                  memberIsAvailable
                                    ? "Available"
                                    : "Unavailable"
                                }
                                color={
                                  memberIsAvailable ? "#71CF48" : "#FE4C4A"
                                }
                              />
                            </td>
                            <td>
                              {memberWorkedHere ? (
                                <WorkedHereIcon />
                              ) : (
                                <NotWorkedHereIcon />
                              )}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 40,
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>Start Time:</span>
                                  <TimePicker
                                    disabled={!memberSelected}
                                    popupClassName={
                                      isDarkMode && "darkDateDropDown"
                                    }
                                    placeholder="Start Time"
                                    value={
                                      !startTime ? null : dayjsNY(startTime)
                                    }
                                    current
                                    format={"h:mm a"}
                                    onChange={(e) => {
                                      workingHoursForCrews(
                                        e,
                                        member?.crewId,
                                        "startTime",
                                        "member",
                                        member,
                                        scheduleDay
                                      );
                                    }}
                                    style={{
                                      border: "none",
                                      width: 130,
                                      backgroundColor: "#f8f8fa",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>End Time:</span>
                                  <TimePicker
                                    disabled={!memberSelected}
                                    popupClassName={
                                      isDarkMode && "darkDateDropDown"
                                    }
                                    placeholder="End Time"
                                    value={!endTime ? null : dayjsNY(endTime)}
                                    format={"h:mm a"}
                                    onChange={(e) => {
                                      workingHoursForCrews(
                                        e,
                                        member?.crewId,
                                        "endTime",
                                        "member",
                                        member,
                                        scheduleDay
                                      );
                                    }}
                                    style={{
                                      border: "none",
                                      width: 130,
                                      backgroundColor: "#f8f8fa",
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                );
              })}
            </tbody>
            <>
              {crewNrVisible && (
                <CrewNrForDay
                  {...{
                    crewNrVisible,
                    setCrewNrForVisible,
                    scheduleDay,
                    totalCrewNrForDay,
                  }}
                />
              )}
            </>
          </table>
        </div>
        <div className="truck-modal-body-footer crews-modal-footer">
          {Object.values(selectedCrewsObject || {})?.map((sc, i) => {
            const initials = sc?.crewName
              ?.split(" ")
              ?.map((n) => n[0])
              ?.join("")
              ?.toUpperCase();
            return (
              <AvatarCustom
                key={i}
                {...{ members: sc?.members, foreman: sc, initials: initials }}
              />
            );
          })}
        </div>
      </div>
      {crewActivityModalVisible && (
        <CrewActivityModal
          {...{
            visible: crewActivityModalVisible,
            setVisible: setCrewActivityModalVisible,
            activities: activityTableData,
            thisProjectSchedules: thisProjectSchedules,
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <MondayButton
          Icon={<XIcon />}
          className="trucks-modal-button mondayButtonRed"
          onClick={() => onCancel()}
        >
          Cancel
        </MondayButton>
        <MondayButton
          disabled={scheduleDay?.isNotAvailable === true ? true : false}
          Icon={<Tick width={19} height={19} />}
          className="trucks-modal-button"
          onClick={() => onConfirm(selectedCrewsObject)}
        >
          Confirm
        </MondayButton>
      </div>
    </>
  );
};

export default CrewsModal;

const StatusButton = React.memo(({ status, color, onClick }) => {
  return (
    <Button
      style={{
        width: 129,
        height: 32,
        background: `${color} 0% 0% no-repeat padding-box`,
        borderRadius: 5,
        boxShadow: "none",
        border: "none",
      }}
      icon={
        <p
          style={{
            font: "normal normal normal 16px/22px Open Sans",
            letterSpacing: 0,
            color: "#fff",
            margin: 0,
            padding: 0,
          }}
        >
          {status}
        </p>
      }
      onClick={onClick}
    ></Button>
  );
});

const AvatarCustom = React.memo(({ members, foreman, initials }) => {
  const bgColor = useMemo(() => getRandomColor(), [initials]);
  return (
    <Tooltip
      overlayClassName="trucks-modal-tooltip crews-modal-tooltip"
      placement="bottom"
      title={
        <>
          <ul>
            <li>
              <h5 style={{ color: "#fff" }}>{foreman?.crewName}</h5>
            </li>
            {/* {members?.map((m, i) => (
              <li key={i}>{m?.crewName}</li>
            ))} */}
          </ul>
        </>
      }
    >
      <Avatar
        style={{ backgroundColor: bgColor, verticalAlign: "middle" }}
        key={foreman?.crewId}
        size={36}
        icon={<span>{initials}</span>}
      ></Avatar>
    </Tooltip>
  );
});
