import { driveApi } from "../../../../../../../../../integrations/DriveRequest";

export const updateGoogleDrivePermissions = async ({
  form,
  accessToken,
  currentUser,
  foldersAccess,
  hotCredentials,
  allFoldersAccess,
}) => {
  const driveRequest = driveApi({ accessToken });

  const gDriveCredentials = hotCredentials?.filter(
    ({ isGDrive = "" }) => !!isGDrive
  );

  const getFolderValue = (value) =>
    hotCredentials.find(({ credentialKey }) => credentialKey === value)
      .credentialValue;

  await Promise.all(
    gDriveCredentials.map((credential) => {
      return form.getFieldValue(credential?.credentialKey) !== "none"
        ? foldersAccess[credential.credentialKey]
          ? driveRequest.updatePermission(
              getFolderValue(credential?.credentialKey),
              form.getFieldValue(credential?.credentialKey),
              allFoldersAccess?.[credential.credentialKey]?.id
            )
          : driveRequest.share(
              getFolderValue(credential?.credentialKey),
              form.getFieldValue(credential?.credentialKey),
              "user",
              currentUser?.userName
            )
        : allFoldersAccess?.[credential.credentialKey]?.id &&
            driveRequest.deletePermission(
              getFolderValue(credential?.credentialKey),
              allFoldersAccess?.[credential.credentialKey]?.id
            );
    })
  );
};
