import { Tooltip } from "antd";
import WarningIcon from "../../../../../../icons/Warning_Icon.webp";
import React from "react";
import { WarningTakeOff } from "../../../../../../assets";
import { WithTooltip } from "../../../../../commonComponents";

export const wrapWithErrorCalculating = (
  supposedNumberValue,
  roundWith2DigitsAfterDecimalPoint,
  allowZero
) => {
  let errorCalculating = (
    <WithTooltip tooltipCategory="Take Off" tooltipKey="errorCalculating">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <WarningTakeOff />
      </div>
      {/* <img
        src={WarningIcon}
        alt={"error icon"}
        className={"price-not-calculated-icon"}
      /> */}
    </WithTooltip>
  );

  if (allowZero === true) {
    if (
      isNaN(supposedNumberValue) ||
      supposedNumberValue === Infinity ||
      supposedNumberValue < 0
    ) {
      return errorCalculating;
    }
  } else {
    if (
      isNaN(supposedNumberValue) ||
      supposedNumberValue === Infinity ||
      supposedNumberValue <= 0
    ) {
      return errorCalculating;
    }
  }

  return roundWith2DigitsAfterDecimalPoint === true
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })
        .format(supposedNumberValue)
        .substring(1)
    : supposedNumberValue;

  // return isNaN(supposedNumberValue)? errorCalculating : (roundWith2DigitsAfterDecimalPoint === true ? supposedNumberValue.toFixed(2) : supposedNumberValue)
  // return isNaN(supposedNumberValue)? errorCalculating : (roundWith2DigitsAfterDecimalPoint === true ? Math.round(supposedNumberValue*100)/100 : supposedNumberValue)
  //toFixed has very bad performance
};
