import { message } from "antd";

const AmountChangeInput = ({
  selectedAmount,
  onSelectedAmountChangeHandler,
  currentCharge,
  isWritable,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
      }}
      data-testid="amount-component"
    >
      <span style={{ color: "white", fontSize: 17 }}>$</span>
      <input
        type="number"
        defaultValue={selectedAmount}
        min={1}
        data-testid="amount-input"
        className="input-percentage-amount"
        onClick={(e) => {
          if (currentCharge && !isWritable) {
            e.target.blur();
            message.info("Please enable Write Mode");
          }
        }}
        onChange={onSelectedAmountChangeHandler}
        style={{
          cursor: currentCharge && !isWritable ? "not-allowed" : "auto",
        }}
      />
    </div>
  );
};

export default AmountChangeInput;
