const filterReport = ({ allPreferences, reportsConfiguration }) => {
  const favouriteReports = allPreferences?.preferences?.reportPreferences;

  const filteredReports = reportsConfiguration.filter((report) => {
    return report?.reportsAvailable.some(
      ({ reportId }) => favouriteReports?.[reportId]
    );
  });

  return filteredReports ?? [];
};

export default filterReport;
