import { API } from "aws-amplify";
import { preventDuplicate } from "../../forms/Scheduling/SchedulingFirstPage/helperData";
import { filterTables } from "src/utils";
import { message } from "antd";

export const filterData = ({ rowData }) => {
  return {
    Status: [
      { label: "New", value: "New" },
      { label: "In Progress", value: "In Progress" },
      { label: "Cancelled", value: "Cancelled" },
      { label: "Done", value: "Done" },
    ],
    Category: preventDuplicate(rowData, "todoCategory")?.map((e) => ({
      label: e?.todoCategory?.capitalize(),
      value: e?.todoCategory?.toLowerCase(),
    })),
  };
};

//Function that gets dnd logs
export async function getLogs(
  todosDispatch = () => {},
  userConfiguration = {}
) {
  await fetchAllData({
    endpoint: "editLogs",
    resultPosition: "editLogs",
    resultId: "logId",
    otherStringParams: {
      getMaxLimit: "true",
      filters: JSON.stringify([
        {
          conditions: [
            {
              column: "category",
              value: "To Do Drag and Drop",
              formula: "is",
            },
          ],
        },
      ]),
    },
  })
    ?.then((res) => {
      if (userConfiguration?.groupName === "Admin") {
        todosDispatch({ type: "logs", payload: res });
      } else {
        let logs = res?.filter(
          (el) => el?.cognitoUserId === userConfiguration?.cognitoUserId
        );
        todosDispatch({ type: "logs", payload: logs });
      }
    })
    ?.catch((e) => console.error("Error getting logs: ", e));
}

//Function that gets todos for admin
export async function getTodos(todosDispatch = () => {}) {
  await API.get("todos", "/todos")
    .then((res) => {
      getPreferences(res, todosDispatch);
    })
    ?.catch((e) => console.error("Error Getting ToDos: ", e));
}

//Function that gets todos for user
export async function filterTodos(
  userConfiguration = {},
  todosDispatch = () => {}
) {
  await filterTables("todos", "cognitoUserId", userConfiguration?.cognitoUserId)
    ?.then((res) => {
      if (userConfiguration?.groupName === "Admin") {
        getPreferences(res, todosDispatch);
      } else {
        todosDispatch({ type: "getTodos", payload: res });
      }
    })
    ?.catch((e) => console.error("Error getting To dos", e));
}

async function getPreferences(
  // setCustomSortDot,
  rowData,
  todosDispatch = () => {}
) {
  await API.get("preferences", "/preferences")
    .then((res) => {
      todosDispatch({ type: "preferences", payload: res?.preferences });

      if (res?.preferences?.todosPreferences?.length) {
        // setCustomSortDot(true);

        let ordered = orderRecords(
          rowData,
          res?.preferences?.todosPreferences,
          "todoId"
        );
        todosDispatch({
          type: "orderByPreferences",
          payload: { ordered, rowData },
        });
      } else {
        // setCustomSortDot(false);
        todosDispatch({ type: "getTodos", payload: rowData });
      }
    })
    .catch((err) => console.error("Error getting preferences: ", err));
}

//Function that posts dnd order on user preferences
export async function handleSave(ordered, preferences, setDisabled = () => {}) {
  message.loading({
    key: "orderSave",
    content: "Saving changes...",
    duration: 0,
  });
  setDisabled(true);

  let orderedBody = ordered?.map((e) => e?.todoId);

  if (!!preferences) {
    await API.patch("preferences", "/preferences", {
      body: {
        preferences: {
          ...preferences,
          todosPreferences: orderedBody,
        },
      },
    })
      ?.then(() => {
        message.success({
          key: "orderSave",
          content: "Changes saved successfully!",
          duration: 2,
        });
      })
      ?.catch((err) => {
        console.error("Error saving preferences: ", err);
        message.error({
          key: "orderSave",
          content: "Error saving changes",
          duration: 2,
        });
      });
  }
}

//Function that orders Todos according to preference order
export function orderRecords(rowData, order, orderKey) {
  const idMap = new Map(rowData?.map((el) => [el?.[orderKey], el]));

  const orderSet = new Set(order);

  const orderedRowData = order?.map((id) => idMap.get(id));

  let remainingTodos = rowData?.filter((obj) => !orderSet.has(obj?.[orderKey]));
  if (remainingTodos?.length) {
    orderedRowData.push(...remainingTodos);
  }

  return orderedRowData?.filter(Boolean);
}
