import { FormCard } from "../../../Fleet/components";
import "./CategorySelectorCard.scss";

const CategorySelectorCard = ({
  categoriesWithDocs = [],
  currentCategory = "",
  onClick,
  isDarkMode,
}) => {
  const filtered = categoriesWithDocs?.filter(Boolean);
  return (
    <FormCard
      {...{
        className: `documentationPageCategorySelector ${
          isDarkMode && "documentationCategorySelectorDark"
        }`,
        childrenClassName: "categorySelectorContent",
      }}
    >
      <div className="categoryButtonsContainer">
        {filtered?.length > 0 ? (
          filtered?.map((categoryName, i) => (
            // categoryName === undefined ? null :
            <div
              key={i}
              className={`docCategoryButton ${
                !!currentCategory
                  ? currentCategory === categoryName
                    ? "selectedDocCategory"
                    : "waitingDocCategory"
                  : ""
              }`.trim()}
              onClick={() => onClick(categoryName)}
            >
              {categoryName}
            </div>
          ))
        ) : (
          <div className="noDocsMessage">No documentation categories</div>
        )}
      </div>
    </FormCard>
  );
};

export default CategorySelectorCard;
