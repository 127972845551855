import { useEffect } from "react";
import { Modal, Form } from "antd";

import { overviewFilters } from "../DegOverviewStep/overviewStepData";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { RenderDynamicComponents } from "../../../../../../../Header/forms/Components";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { ClearFilterIcon } from "../../../../../../../SidebarPages/DynamicView/src";
import { FilterIcon } from "../../../../../../../SidebarPages/BasePage/src";
import { updateDocumentTitle } from "../../../../../../../../utils";

import "./OverviewFilterModal.scss";

function OverviewFilterModal({
  open,
  onCancel,
  onFilter = () => {},
  rowData = [],
  matchDetails = false,
  filter = {},
  customFilters = false,
  isDarkMode,
  customClearFilters = () => {},
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      let tmp = structuredClone(filter);
      if (filter["timeFrame"]) {
        tmp["timeFrame"] = (filter["timeFrame"] || []).map((e) => dayjsNY(e));
      }

      form.setFieldsValue(tmp);
    }
  }, [open]);
  console.log("matchDetails: ", matchDetails);
  
  return (
    <Modal
      {...{
        title: "Overview Filter",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Overview Filter" });
        },
        closable: true,
        centered: true,
        closeIcon: <XIcon />,
        ["data-testid"]: "overview-filter-modal",
        className: `overviewFilterModal ${
          isDarkMode && "overviewFilterModalDark"
        }`,
        wrapClassName: "overviewFilterWrap",
        open,
        onCancel: () => {
          onCancel();
          updateDocumentTitle(); // reset document title to "Lead Manager";
        },
        footer: [
          <MondayButton
            {...{
              className: "mondayButtonRed",
              key: "removeFilterBtn",
              ["data-testid"]: "close-overview-filter-modal-btn",
              Icon: <ClearFilterIcon />,
              onClick() {
                let emptyData = {
                  selectedEmployees: [],
                  selectedJobsites: [],
                  timeFrame: undefined,
                };
                form.setFieldsValue(emptyData);
                onFilter(emptyData);
                updateDocumentTitle(); // reset document title to "Lead Manager";
                customClearFilters(form);
                onCancel();
              },
            }}
          >
            Remove Filter
          </MondayButton>,
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              key: "filterBtn",
              Icon: <FilterIcon />,
              onClick() {
                onFilter(form.getFieldsValue());
                onCancel();
              },
            }}
          >
            Filter
          </MondayButton>,
        ],
      }}
    >
      <Form form={form}>
        {RenderDynamicComponents(
          customFilters
            ? customFilters({ rowData, matchDetails, form })
            : overviewFilters({ rowData, matchDetails, form }),
          {
            form,
          }
        )}
      </Form>
    </Modal>
  );
}

export default OverviewFilterModal;
