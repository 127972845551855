import types from '../../types'

const initialState = {
  proposedConstructionServices: undefined,
}

const proposedConstructionServicesReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_CONSTRUCTIONS_SERVICES:
      return {
        ...state,
        proposedConstructionServices: payload,
      }
    default:
      return state
  }
}

export default proposedConstructionServicesReducer
