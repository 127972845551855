import { useRedux } from "../../../../../../../../../../../../../../hooks";
import { chargeStatusHandler } from "./ChargedIndicatorFunctions";
import "./ChargedIndicator.scss";
import {
  ChargedSomeDaysIcon,
  ChargedAllDaysIcon,
  NotCharged,
} from "../../../../../../../../../../../../../../DynamicView/src";

// import { useStateContext } from "../../../../../../../../../../Context/Context"

import { Tooltip } from "antd";

const ChargedIndicator = ({ thisElevation, whichOne, params, charged }) => {
  const { thisPli, thisWeek, thisDay } = params;
  const [unchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [fakeRentals] = useRedux("fakeRentals");
  // const { fakeRentals, unchangingFakeRentals, selectedService } = useStateContext()

  const { allDaysCharged, someDaysCharged } = chargeStatusHandler({
    fakeRentals: whichOne === "pli" ? fakeRentals : unchangingFakeRentals,
    selectedService,
    thisElevation,
    thisPli,
    thisWeek,
    thisDay,
    whichOne,
  });

  //if all days are charged return charged
  const completelyCharged = allDaysCharged;
  //if not all days are charged and only some are return partially charged
  const partlyCharged = !allDaysCharged && someDaysCharged;
  console.log(charged);
  return (
    <div className="chargedIndicatorContainer">
      {charged ? (
        <Tooltip title="Fully charged!">
          <div className="allChargedIconHolder">
            <ChargedAllDaysIcon />
          </div>
        </Tooltip>
      ) : partlyCharged ? (
        <Tooltip title="Partially charged!">
          <div className="partiallyChargedIconHolder">
            <ChargedSomeDaysIcon />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title="Not charged!">
          <div className="notChargedIconHolder">
            <NotCharged />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ChargedIndicator;
