import "./EstimationPicker.scss";

import { Checkbox, message, Tooltip } from "antd";
import { ChangeOrderIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { useEffect, useMemo, useState } from "react";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";

const CheckboxGroup = Checkbox.Group;

const EstimationPicker = ({
  setSelectedEstimations,
  selectedEstimations,
  estimations,
  isLoading,
  chosenWay,
  editSov,
  hasIndex,
  includedAlreadyServices,
  setFormChanged = () => {},
}) => {
  
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  return (
    <div className="estimationHolder">
      <div className="select-options-estimations-title">Estimations</div>
      {!editSov ? (
        !isLoading && chosenWay === "Estimation" ? (
          <div className="selectOptionsEstimationsNewSov">
            {!!estimations.length ? (
              <CheckboxGroup
                options={estimations?.map(
                  (
                    {
                      estimationId,
                      isChangeOrder,
                      services = [],
                      estimationNumber = "",
                    },
                    index
                  ) => ({
                    label: (
                      <div className="newSovEstimationLabelContainer">
                        <div className="labelContainer">
                          {!!hasIndex
                            ? estimations[index]?.label
                            : `Estimation #${estimationNumber}`}
                        </div>
                        <div>
                          {getEstimationQuickDesc({
                            structuredDataArr: services,
                            proposedTypeOfWork,
                          })}
                        </div>
                        <div>
                          {!!isChangeOrder && (
                            <Tooltip title="Change order">
                              <ChangeOrderIcon />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    ),
                    value: estimationId || 0,
                    disabled: services?.length === 0,
                  })
                )}
                value={selectedEstimations}
                onChange={(selected) => {
                  setFormChanged(true);
                  setSelectedEstimations(selected);
                }}
              />
            ) : (
              <p className="no-approved-estimations">No approved estimations</p>
            )}
          </div>
        ) : null
      ) : (
        <div>
          <p>...</p>
        </div>
      )}
    </div>
  );
};

export default EstimationPicker;

export const getEstimationQuickDesc = ({
  structuredDataArr = [],
  proposedTypeOfWork,
}) => {
  let res = false;

  if (Array.isArray(structuredDataArr)) {
    let minStart,
      maxEnd = 0;
    structuredDataArr?.forEach((el, i) => {
      if (i === 0) res = "";
      res +=
        getServiceID(proposedTypeOfWork, el) +
        `${i === structuredDataArr?.length - 1 ? "" : ", "}`;
    });
    return (
      res || (
        <strong style={{ color: "red" }}>
          No services/all already included!
        </strong>
      )
    );
  } else {
  }
};

const getServiceID = (proposedTypeOfWork, el) => {
  let ID = "";
  if (proposedTypeOfWork?.length) {
    const temp =
      proposedTypeOfWork?.find((p) => p?.workName === el?.label)?.idCode ||
      false;
    if (temp) ID += temp;
    else {
      ID += `Other Scope`;
    }
  }
  return ID;
};
