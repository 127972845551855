import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";

export const dispatching = (dispatchData, updatedDynamicStates, IdHandler) => {
  const allDrivers = updatedDynamicStates[IdHandler]?.map((a) =>
    a?.routes?.map((e) => e?.driverName)
  )?.flat();
  return {
    fleetName: () => ({
      title: "Dispatching Fleet Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Dispatching",
        field: "fleetName",
        labels: uniq(dispatchData?.map((a) => a?.fleetName)),
        datasets: [
          {
            label: "Dispatching",
            data: uniq(dispatchData?.map((a) => a?.fleetName))?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (a) => a.fleetName?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(dispatchData?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }), //fleetName
    driverName: () => ({
      title: "Dispatching Driver Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Dispatching",
        field: "driverName",
        labels: uniq(allDrivers),
        datasets: [
          {
            label: "Dispatching",
            data: uniq(allDrivers)?.map(
              (status) =>
                allDrivers?.filter(
                  (a) => a?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(allDrivers)?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }), //driverName
    dispatchedBy: () => ({
      title: "Dispatched By",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Dispatching",
        field: "dispatchedBy",
        labels: uniq(
          dispatchData?.map((a) =>
            a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
          )
        ),
        datasets: [
          {
            label: "Dispatching",
            data: uniq(
              dispatchData?.map((a) =>
                a?.dispatchedBy?.name ? a?.dispatchedBy?.name : a?.dispatchedBy
              )
            )?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter((a) =>
                  a.dispatchedBya?.dispatchedBy?.name
                    ? a?.dispatchedBy?.name?.toLowerCase()
                    : a?.dispatchedBy?.toLowerCase() === status?.toLowerCase()
                )?.length
            ),
            backgroundColor: uniq(dispatchData?.map((a) => getRandomColor())),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }), //dispatchedBy
  }; //Dispatching
};
