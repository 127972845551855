import types from "../types";

export const handleDarkMode = (e) => async (dispatch) => {
  localStorage.setItem("darkmode", e);

  dispatch({
    type: types.DARK_MODE,
    payload: e,
  });
};
