import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { formatDuration } from "../../../utils";

const columnDefs = [
  {
    headerName: "Vehicle",
    field: "fleetName",
    cellRenderer({ value, context, data }) {
      return (
        <div
          style={{
            fontWeight: 500,
            color: "#1065A4",
            cursor: "pointer",
          }}
          onClick={() => {
            if (context?.onVehicleClick) {
              context.onVehicleClick({ ...data });
            }
          }}
        >
          {value}
        </div>
      );
    },
  },
  {
    headerName: "Stop Type",
    field: "stopType",
    cellRenderer({ value }) {
      let className = "stop-idling";

      if (value?.toLowerCase().includes("off")) {
        className = "stop-off";
      }

      return <div className={`stop-status ${className}`}>{value}</div>;
    },
  },
  {
    headerName: "Address",
    field: "address",
  },
  {
    headerName: "From Date",
    field: "beginDate",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
      },
    },
  },
  {
    headerName: "To Date",
    field: "endDate",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return dayjsNY(value).format("MMM DD, YYYY hh:mm a");
      },
    },
  },
  {
    headerName: "Duration",
    field: "duration",
    cellRenderer({ value }) {
      return formatDuration(value, "minutes").text;
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return formatDuration(value, "minutes").text;
      },
    },
  },
  {
    headerName: "Reason",
    field: "reason",
  },
];

export const columnKeys = [
  "fleetName",
  "stopType",
  "address",
  "beginDate",
  "endDate",
  "duration",
  "reason",
];

export default columnDefs;
