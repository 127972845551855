import React from "react";
import { useSelector } from "react-redux";

import { RedWarningModal } from "../../../commonComponents";
import { useAuth } from "../../../../hooks/useAuth";

import "./LogoutModal.scss";

export const LogoutModal = (props) => {
  const { setWarningModalVisible, warningModalVisible } = props;
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { signOut } = useAuth();

  return (
    <RedWarningModal
      {...{
        visible: warningModalVisible,
        onCancel: () => setWarningModalVisible(false),
        footerProps: {
          onConfirm: signOut,
          saveText: "Got it, log out",
          closeText: "Don't log out",
        },
        titleText: "Log out confirmation",
      }}
    >
      <p>
        Hi <b>{userConfiguration.nameOfUser}</b>, you're logging out, please
        keep your login credentials confidential. Avoid sharing your username
        and password with anyone.
      </p>
    </RedWarningModal>
  );
};
