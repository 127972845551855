import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const incidents = ({ tables, programFields }) => {
  const incidentStatuses = programFields?.find(
    (a) => a?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Incidents;
  return {
    driverName: () => ({
      labels: uniq(tables?.Incidents?.map((a) => a?.driverName)),
      data: uniq(tables?.Incidents?.map((a) => a?.driverName))?.map(
        (status) =>
          tables?.Incidents?.filter((a) => a.driverName === status)?.length
      ),
      backgroundColor: uniq(tables?.Incidents?.map((a) => getRandomColor())),
    }),
    fleetName: () => ({
      labels: uniq(tables?.Incidents?.map((a) => a?.fleetName)),
      data: uniq(tables?.Incidents?.map((a) => a?.fleetName))?.map(
        (status) =>
          tables?.Incidents?.filter((a) => a.fleetName === status)?.length
      ),
      backgroundColor: uniq(tables?.Incidents?.map((a) => getRandomColor())),
    }),

    incidentStatus: () => ({
      labels: incidentStatuses?.map((a) => a?.statusName),
      data: incidentStatuses?.map(
        (status) =>
          tables?.Incidents?.filter(
            (a) => a?.incidentStatus === status?.statusName
          )?.length
      ),
      backgroundColor: incidentStatuses?.map((a) => a?.statusColor),
    }),

    incidentCategory: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.Incidents, "incidentCategory")
      ),
      data: Object?.values(
        repeatedElementSum(tables?.Incidents, "incidentCategory")
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.Incidents, "incidentCategory")
      )?.map((a) => getRandomColor()),
    }),

    incidentName: () => ({
      label: Object.keys(repeatedElementSum(tables?.Incidents, "incidentName")),
      data: Object?.values(
        repeatedElementSum(tables?.Incidents, "incidentName")
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.Incidents, "incidentName")
      )?.map((a) => getRandomColor()),
    }),

    projectName: () => ({
      label: Object.keys(
        repeatedElementSum(
          tables?.Incidents?.map((el) => el?.incidentObject),
          "projectName"
        )
      ),
      data: Object?.values(
        repeatedElementSum(
          tables?.Incidents?.map((el) => el?.incidentObject),
          "projectName"
        )
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(
          tables?.Incidents?.map((el) => el?.incidentObject),
          "projectName"
        )
      )?.map((a) => getRandomColor()),
    }),
  };
};
