import "./UserProfile.scss";
import "../../settingsCommonComponents/styles/CardContentWrapper.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AccountInformation from "./AccountInformation/AccountInformation";
import DashboardPrefs from "./DashboardPrefs/DashboardPrefs";
import NotificationFields from "./NotificationFields/NotificationFields";
import { MondayButton } from "../../../../commonComponents";
import { TickIcon } from "../Roles/src";
import { UserProfileContext } from "./UserProfileContext";
import { driveApi } from "../../../../../integrations/DriveRequest";
import {
  UserConfig,
  preferences as preferencesDispatch,
} from "../../../../../actions";
import { handleSaveLogs } from "./helpers";
import { useEditLogs } from "../../../../../hooks";

const UserProfile = () => {
  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { preferences } = useSelector((state) => state.preferences);

  const [profileChanges, setProfileChanges] = useState({}); //save here all user changes
  const [tmpSignature, setTmpSignature] = useState(
    userConfiguration?.userSignature?.src
  );
  const { saveAddedLogs } = useEditLogs();

  const driveRequest = driveApi({ accessToken });

  const saveChangesHandler = async () => {
    message.loading("Saving Changes...");

    // Changes to userConfiguration
    if (
      profileChanges.hasOwnProperty("userImage") ||
      profileChanges.hasOwnProperty("pinNumber") ||
      profileChanges.hasOwnProperty("idleTimeOut") ||
      profileChanges.hasOwnProperty("userSignature")
    ) {
      const { userImage, pinNumber, idleTimeOut, userSignature } =
        profileChanges;

      const updatedUserConfig = {
        ...(profileChanges.hasOwnProperty("userImage") && {
          googleDriveFileId: userImage,
        }),
        ...(pinNumber && { pinNumber }),
        ...(idleTimeOut && { idleTimeOut }),
        ...(!!userSignature && { userSignature }),
      };

      await API.patch("userConfiguration", "/userConfiguration", {
        body: updatedUserConfig,
      }).then(() => {
        dispatch(UserConfig({ ...userConfiguration, ...updatedUserConfig }));
      });
    }

    // Changes to preferences
    if (
      profileChanges.hasOwnProperty("dashboardPrefs") ||
      profileChanges.hasOwnProperty("myNotifications") ||
      profileChanges.hasOwnProperty("profileColor") ||
      profileChanges.hasOwnProperty("automationPreferences")
    ) {
      const {
        dashboardPrefs: { hiddenCards, nonHiddenCards } = {},
        myNotifications,
        profileColor,
        automationPreferences,
      } = profileChanges;

      const updatedPreferences = {
        ...(hiddenCards && {
          widgetHiddenConfiguration: { dashboard: hiddenCards },
        }),
        ...(nonHiddenCards && {
          widgetConfiguration: { dashboard: nonHiddenCards },
        }),

        preferences: {
          ...preferences.preferences,
          ...(profileColor && { profileColor }),
          notifications: {
            ...preferences.preferences.notifications,
            ...(!!myNotifications && { topics: myNotifications }),
            ...(!!automationPreferences && { automationPreferences }),
          },
        },
      };

      await API.patch("preferences", "/preferences", {
        body: updatedPreferences,
      }).then(() => {
        dispatch(
          preferencesDispatch({ ...preferences, ...updatedPreferences })
        );
      });
    }

    message.destroy();
    message.success("Changes Saved Successfully!");

    setProfileChanges({});

    // save new logs
    handleSaveLogs({
      userConfiguration,
      preferences,
      profileChanges,
      saveAddedLogs,
    });
  };

  const onDiscardChanges = () => {
    setProfileChanges({});
    setTmpSignature(userConfiguration?.userSignature?.src);

    if (!!profileChanges?.userImage) {
      driveRequest.deleteDriveItem(profileChanges.userImage);
    }
  };

  return (
    <div className={`user-profile-container ${isDarkMode ? "dark-mode" : ""}`}>
      <UserProfileContext.Provider
        value={{
          profileChanges,
          setProfileChanges,
          tmpSignature,
          setTmpSignature,
        }}
      >
        <div className="user-profile-content ">
          <div className="left-section">
            <AccountInformation />
            {userConfiguration?.groupName !== "Foreman" && <DashboardPrefs />}
          </div>
          <div className="right-section">
            {userConfiguration?.groupName !== "Foreman" && (
              <NotificationFields />
            )}
          </div>
        </div>
        <div className="user-profile-footer">
          <MondayButton
            className="mondayButtonRed"
            Icon={<CloseOutlined />}
            disabled={Object.keys(profileChanges).length === 0}
            onClick={onDiscardChanges}
          >
            Discard Changes
          </MondayButton>

          <MondayButton
            disabled={Object.keys(profileChanges).length === 0}
            onClick={saveChangesHandler}
            Icon={<TickIcon />}
          >
            Save Changes
          </MondayButton>
        </div>
      </UserProfileContext.Provider>
    </div>
  );
};

export default UserProfile;
