import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Button, Modal } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

import {
  formatNumber,
  formatCurrency,
} from "../../../../../../SidebarPages/utils";
import { generateTimeCardPdf } from "../../utils/generateTimeCardPdf";
import PayrollContext from "../../../../PayrollContext";
import { withinRadius } from "../payrollActivityModalData";
import { MondayButton } from "../../../../../../commonComponents";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../DEG/components/modalComponents/utils/cellFunctions";
import { InfoData, EntryMarker, JobsiteMarker } from "./modalComponents";
import { SmallLoadableComp } from "../../../../../../Sidebars/components";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { JobsiteType } from "../../../../../../SidebarPages/FleetMaintenanceView/types";
import {
  MAP_DARK,
  MAP_DEFAULT,
  GOOGLE_API_KEY,
  NEW_YORK_LOCATION,
} from "../../../../../../SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";

import "./AnalyticShiftView.scss";
import { ExportPdfIcon } from "../../../../../../../assets";

/**
 * @typedef CoordinateType
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef JobsiteMatch
 * @property {string} jobName
 * @property {string} jobsiteId
 * @property {string} jobAddress
 * @property {boolean} reimbursement
 * @property {string} googleSheetLink
 * @property {Array<string>} services
 */

/**
 * @typedef ShiftType
 * @property {number} breakHours
 * @property {number} clockOut - timestamp
 * @property {string} company
 * @property {string} companyName
 * @property {string} crewId
 * @property {string} shiftId
 * @property {number} distanceFromJob
 * @property {number} duration
 * @property {string} employeeFullName
 * @property {string} employeeId
 * @property {number} employeeRate
 * @property {string} employeeRole
 * @property {Array<string>} entries
 * @property {number} firstClockIn - timestamp
 * @property {number} lunchStart - timestamp
 * @property {boolean} hrShift
 * @property {string} jobsiteAddress
 * @property {string} jobsiteId
 * @property {JobsiteMatch} jobsiteMatch
 * @property {number} otAmount
 * @property {number} overtimeHours
 * @property {number} ovhAmount
 * @property {number} ovhOvertime
 * @property {number} ovhRegular
 * @property {"Certified Payroll" | "Open Shop" | "Prevailing Wage"} payrollType
 * @property {CoordinateType} punchCoordinates
 * @property {string} punchDate
 * @property {string} punchTime
 * @property {string | undefined} reason
 * @property {number} regAmount
 * @property {boolean} reimbursement
 * @property {"Hourly" | "Salary"} salaryType
 * @property {number} total
 * @property {number} totalOvh
 * @property {string} uploadId
 * @property {string} uploadName
 * @property {number} workHours
 */

/**
 *
 * @param {{
 *  open: boolean
 *  onCancel: () => void
 *  selectedShift: ShiftType
 *  jobsites: Array<JobsiteType>
 * }} props
 */
const AnalyticShiftView = ({ open, onCancel, selectedShift, jobsites }) => {
  const { analytics } = useContext(PayrollContext);
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const { base64 } = useSelector((state) => state?.base64);

  const [map, setMap] = useState();
  const [jobMatch, setJobMatch] = useState();
  const [shiftEntries, setShiftEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState({});
  const [infoWindowToggle, setInfoWindowToggle] = useState(false);
  const [exportToPdsLoading, setExportToPdsLoading] = useState(false);

  const polygonRef = useRef(null);

  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    libraries: ["drawing"],
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  // find the zoom level and center to contain all markers in map
  function fitBoundsToMarkers(listOfLocations) {
    if (map && listOfLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      listOfLocations.forEach((marker) => {
        if (marker?.lat) {
          bounds.extend(marker);
        }
      });
      map.fitBounds(bounds);
    }
  }

  const handleMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onExportToPdfClicked = async () => {
    setExportToPdsLoading(true);
    await generateTimeCardPdf({
      body: { ...selectedEntry, ...selectedShift },
      jobMatch,
      punchLocationMapRef: polygonRef,
      base64,
    });
    setExportToPdsLoading(false);
  };
  const shiftInfoBody = useMemo(() => {
    const lunchOut =
      selectedShift.lunchStart +
      (selectedShift.workHours + selectedShift.overtimeHours) * 36000;

    const totalAmount = selectedShift?.total + selectedShift?.totalOvh;

    const totalWorkHours = (
      selectedShift?.workHours + selectedShift?.overtimeHours
    ).toFixed(2);
    return (
      <div className="info-body">
        <InfoData
          label={"Employee Name"}
          value={selectedShift.employeeFullName}
        />
        <InfoData label={"Employee Id"} value={selectedShift.employeeId} />
        <InfoData label={"Employee Role"} value={selectedShift.employeeRole} />
        <InfoData label={"Rate"} value={selectedShift.employeeRate} />
        <InfoData label={"Shift Type"} value={selectedShift?.shiftType} />
        <InfoData
          label={"Shift Date"}
          value={dayjsNY(selectedShift.punchDate).format(DEG_DATE_FORMAT)}
        />
        <InfoData
          label={"Jobsite"}
          value={selectedShift.jobsiteAddress}
          onClick={() => navigate(`/projects/${jobMatch?.projectId || ""}`)}
        />
        <InfoData
          label={"Scope Of Work"}
          value={selectedShift?.sow.join(", ")}
        />
        <InfoData
          dataSet={[
            {
              dataSetLabel: "Shift Start",
              dataSetValue: dayjsNY(selectedShift.firstClockIn).format(
                DEG_TIME_FORMAT
              ),
            },
            {
              dataSetLabel: "Shift End",
              dataSetValue: dayjsNY(selectedShift.clockOut).format(
                DEG_TIME_FORMAT
              ),
            },
          ]}
        />
        {selectedShift.lunchStart ? (
          <InfoData
            dataSet={[
              {
                dataSetLabel: "Break Start",
                dataSetValue: dayjsNY(selectedShift.lunchStart).format(
                  DEG_TIME_FORMAT
                ),
              },
              {
                dataSetLabel: "Break End",
                dataSetValue: dayjsNY(lunchOut).format(DEG_TIME_FORMAT),
              },
            ]}
          />
        ) : null}
        <InfoData
          label={"Work Hours"}
          value={formatNumber(selectedShift?.workHours.toFixed(2), {
            unit: "hour",
          })}
        />
        <InfoData
          label={"Regular Amount"}
          value={formatCurrency(selectedShift?.regAmount)}
        />
        <InfoData
          label={"Overtime Hours"}
          value={formatNumber(selectedShift?.overtimeHours.toFixed(2), {
            unit: "hour",
          })}
        />
        <InfoData
          label={"Overtime Amount"}
          value={formatCurrency(selectedShift?.otAmount)}
        />
        <InfoData
          label={"Total Work Hours"}
          value={formatNumber(totalWorkHours, { unit: "hour" })}
        />
        <InfoData label={"Total Amount"} value={formatCurrency(totalAmount)} />
      </div>
    );
  }, [selectedShift, jobMatch]);

  const entryInfoBody = useMemo(() => {
    return selectedEntry ? (
      <div className="info-body">
        <InfoData label={"Punch Type"} value={selectedEntry?.punchType} />
        <InfoData
          label={"Punch Date"}
          value={dayjsNY(selectedEntry?.punchDate).format(DEG_DATE_FORMAT)}
        />
        <InfoData
          label={"Punch Time"}
          value={dayjsNY(selectedEntry?.punchTime).format(DEG_TIME_FORMAT)}
        />
        <InfoData
          label={"Punch Location"}
          value={selectedEntry?.punchLocation}
        />
        <InfoData label={"Payroll Type"} value={selectedEntry?.payrollType} />
        <InfoData
          label={"Distance From Job"}
          value={`${
            selectedEntry?.distance ||
            withinRadius(
              selectedEntry?.punchCoordinates,
              jobMatch?.addressPosition
            ).distanceInFeet
          } ft`}
        />
      </div>
    ) : null;
  }, [selectedEntry]);

  useEffect(() => {
    if (selectedShift && !shiftEntries?.length) {
      let entriesIncluded = [];
      let matchedJob;
      for (let i = 0; i < analytics.length; i++) {
        const entry = { ...analytics[i], color: "#1264a3" };
        if ((selectedShift?.entries || []).includes(entry?.entryId)) {
          entriesIncluded.push(entry);
          if (entry?.punchType === "ID") {
            setSelectedEntry(entry);
          }
        }
      }
      for (let i = 0; i < jobsites.length; i++) {
        const job = jobsites[i];
        if (job.jobsiteId === selectedShift?.jobsiteId) {
          matchedJob = job;
        }
      }
      const boundsCoordinates = entriesIncluded
        .map((el) => el.punchCoordinates)
        .concat(matchedJob?.addressPosition);

      fitBoundsToMarkers(boundsCoordinates);
      setShiftEntries(entriesIncluded);
      setJobMatch(matchedJob);
    }
  }, [selectedShift, analytics, shiftEntries]);
  console.log("jobMatch: ", jobMatch);
  return (
    <Modal
      title={`Analytic Shift View`}
      open={open}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      closable={true}
      centered={true}
      destroyOnClose={true}
      className={`analytic-shift-view ${
        isDarkMode ? "analytic-shift-view-dark" : ""
      }`}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={onCancel}
        >
          Close
        </MondayButton>,
        <MondayButton
          id="pdfForService"
          className="mondayButtonBlue"
          loading={exportToPdsLoading}
          Icon={<ExportPdfIcon />}
          onClick={() => onExportToPdfClicked()}
        >
          {exportToPdsLoading ? "Exporting..." : "Export to PDF"}
        </MondayButton>,
      ]}
    >
      <section className="shift-data">
        <label className="info-header">Shift Info</label>
        {shiftInfoBody}
      </section>
      <section className="selected-entry-data">
        <label className="info-header">Selected Entry Info</label>
        {entryInfoBody}
      </section>
      <section className="google-map">
        {isLoaded ? (
          <GoogleMap
            ref={polygonRef}
            center={
              !!selectedShift?.punchCoordinates?.lat
                ? selectedShift.punchCoordinates
                : NEW_YORK_LOCATION
            }
            id="mapContainer"
            zoom={16}
            onLoad={handleMapLoad}
            mapContainerClassName={"map-analytic-view"}
            options={{
              clickableIcons: false,
              styles: isDarkMode ? MAP_DARK : MAP_DEFAULT,
              mapTypeId: "terrain",
            }}
          >
            <SmallLoadableComp loading={!selectedShift} darkMode={isDarkMode}>
              {jobMatch ? (
                <JobsiteMarker
                  position={jobMatch?.addressPosition}
                  address={jobMatch?.jobAddress}
                  key={jobMatch?.jobsiteId}
                  radius={jobMatch?.locationRadius || 300}
                  onClick={() => navigate(`/projects/${jobMatch.projectId}`)}
                  geoFenceInfo={
                    jobMatch?.geoFenceInfo || jobMatch?.geofenceInfo
                  }
                />
              ) : null}
              {!!shiftEntries?.length
                ? shiftEntries.map((entry) => {
                    return (
                      <EntryMarker
                        position={entry.punchCoordinates}
                        address={entry.jobAddress}
                        key={entry.entryId}
                        color={entry.color}
                        onClick={() => setSelectedEntry(entry)}
                        entryType={entry.punchType}
                        infoWindowToggle={infoWindowToggle}
                      />
                    );
                  })
                : null}
              <section className="drawing-buttons">
                <Button
                  className="draw-button"
                  onClick={() => setInfoWindowToggle((prev) => !prev)}
                >
                  Toggle Info
                </Button>
              </section>
            </SmallLoadableComp>
          </GoogleMap>
        ) : null}
      </section>
    </Modal>
  );
};

export default AnalyticShiftView;
