import { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Form, message } from "antd";

import { GreenCheckMarkIcon } from "../icons";
import CustomOtpInput from "./CustomOtpInput";
import { validateFields } from "../utils";
import { showErrorMsg, showSuccessMsg } from "../../../utils";

const OneTimePasswordConfirmation = ({ form, setUser }) => {
  const [otpValue, setOtpValue] = useState("");

  const otpChange = (otp) => setOtpValue(otp);

  const userConfig = () => {
    Auth.confirmSignUp(
      form.getFieldValue("username"),
      form.getFieldValue("verifyCode")
    )
      .then(async (res) => {
        if (res === "SUCCESS") {
          showSuccessMsg({ content: "New account is confirmed" });
          form.resetFields();
          setUser(null);
        }
      })
      .catch((error) => {
        if (error.code === "CodeMismatchException") {
          showErrorMsg({
            content: "Invalid verification code provided, please try again.",
          });
          return;
        }
        showErrorMsg({
          content:
            "There was a problem confirming you account, please try again.",
        });
        console.log({ error });
      });
  };

  return (
    <div className="PasswordConfirmation">
      <GreenCheckMarkIcon />

      <h2>Confirm Your Account</h2>
      <p>Please insert the pass code that was sent to your email.</p>
      <Form.Item className="pascodeInputs" name="verifyCode">
        <CustomOtpInput
          value={otpValue}
          className={"otpInput"}
          onChange={otpChange}
          length={6}
          inputStyle={"inputStyle"}
          focusStyle={"focusStyle"}
        />
      </Form.Item>
      <Button
        onClick={() => validateFields(form, userConfig, ["verifyCode"])}
        className="formButtonElement"
      >
        <span className="buttonText">Confirm Account</span>
      </Button>
    </div>
  );
};

export default OneTimePasswordConfirmation;
