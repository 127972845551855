import { Fragment } from "react";
import { MondayButton } from "src/components/commonComponents";
import { PrintIcon } from "src/components/SidebarPages/BasePage/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

type ExportFooterProps = {
  onCancel: () => any;
  onPrint: () => any;
};

function ExportFooter(props: ExportFooterProps) {
  return (
    <Fragment>
      {/** @ts-ignore */}
      <MondayButton
        Icon={<XIcon />}
        onClick={props.onCancel}
        className="mondayButtonRed"
      >
        Cancel
      </MondayButton>
      <div className="export-container">
        {/** @ts-ignore */}
        <MondayButton
          Icon={<PrintIcon />}
          onClick={props.onPrint}
          className="mondayButtonBlue"
        >
          Print
        </MondayButton>
      </div>
    </Fragment>
  );
}

export default ExportFooter;
