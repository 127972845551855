import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { filterTables, getFiltersObject, getPanelObject } from "../../../utils";
import BasePage from "../BasePage";
import { LoadableComp } from "../XComponents";
import { columnDefs, chartingOptions, excelColumnsConfig } from "./AgGridData";
import MobileSubcontractorBasePage from "./MobileSubcontractorBasePage";
import { useLocation } from "react-router-dom";
import { getExcelColumnKeys } from "../../../utils/getExcelColumnKeys";
import { chartConfigs, getChartingOptions } from "./utils/getChartingOptions";
import { getModifiedChartData } from "../../pages/Settings/settingsComponents/RentalPaymentTermsSettings/components/RentalPaymentTermsSettings/utils/getModifiedChartData";
export const getSubcontractors = async () =>
  await filterTables("accounts", "accountRecordType", "Subcontractors").then(
    (res) => res
  );
const SubcontractorsBasePage = ({ rowData, reloadGrid = () => {} }) => {
  let { state: locationState } = useLocation();
  const keysToUpdate = locationState?.chartFilter || {};

  // redux
  const { programFields } = useSelector((state) => state.programFields);

  const panelObject = getPanelObject(programFields, "Subcontractors");
  const filtersObject = getFiltersObject(
    programFields,
    "Subcontractors",
    keysToUpdate
  );

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [accessRight, setAccessRight] = useState();
  const [viewAccessRight, setViewAccessRight] = useState();
  const [gridApi, setGridApi] = useState();

  const [panelFilter, setPanelFilter] = useState({
    status: panelObject?.createdAt?.label,
    checked: false,
    visible: true,
  });

  const changePanels = (e) => {
    e
      ? setPanelFilter({
          status: panelObject?.subcontractorType?.label,
          checked: true,
          visible: true,
        })
      : setPanelFilter({
          status: panelObject?.createdAt?.label,
          checked: false,
          visible: true,
        });
  };

  useEffect(() => {
    setAccessRight(
      userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Subcontractors"
      )
    );
    setViewAccessRight(
      userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Accounts/View"
      )
    );
  }, [userConfiguration]);

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;

        return value === undefined ? "" : `${value}`;
      },
    });
  };

  const columnDefsMemo = useMemo(() => {
    return columnDefs({
      view: viewAccessRight,
    });
  }, [viewAccessRight]);

  return (
    <LoadableComp loading={!!!rowData}>
      <BasePage
        {...{
          title: "Subcontractors",
          rowData,
          filtersObject,
          chartingOptions,
          videoLinks: true,
          columnDefs: columnDefsMemo,
          getGridApi: (e) => {
            setGridApi(e);
          },
          getChartingOptions: (data) => getChartingOptions(data),
          getModifiedChartData: (data, startDate, endDate) =>
            getModifiedChartData(data, chartConfigs, startDate, endDate),
          defaultExcelExportParams: {
            columnKeys: excelColumnsConfig,
            fileName: "Subcontractors",
          },
          exportGridToExcel,
          hasNew: accessRight?.write,
          panelObject: panelFilter.checked
            ? panelObject?.subcontractorType
            : panelObject?.createdAt,
          panelFilter: panelFilter,
          setPanelFilter: changePanels,
          reloadGrid,
        }}
      />
    </LoadableComp>
  );
};
export default SubcontractorsBasePage;
