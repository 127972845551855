import React from "react";
import { Switch, message } from "antd";

import { useRedux } from "../../../../../hooks";
import { NewIcon } from "../../../../../BasePage/src";
import { MondayButton } from "../../../../../../commonComponents";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import { useInvoiceFormDataContext } from "../../context/InvoiceFormDataContext";

const TableHeader = ({
  autoRow,
  addNewRow,
  setRowData,
  setAutoRow,
  servicesInRef,
}) => {
  const [isWritable] = useRedux("invoiceIsWritable");
  const { invoiceData } = useInitialInvoiceContext();
  const { formData } = useInvoiceFormDataContext();

  const servicesPricePercentage = (value) => {
    if (value?.target?.value === "") return;
    if (parseFloat(value?.target?.value) > 100) {
      value.target.value = 100;
    }
    let percentage = parseFloat(value?.target?.value) || 1;
    setRowData((prev) => {
      const nowAllowedEls = prev?.map(
        (data) => (data.maxSuggestedAmount / data?.scopeAmount) * 100
      );
      const maxPercAllowed = Math.min(...nowAllowedEls);

      if (maxPercAllowed < percentage) {
        message.error("Percentage is not allowed!");
        percentage = parseFloat(maxPercAllowed.toFixed(2));
        value.target.value = percentage;
      }

      prev?.forEach((changedProw) => {
        if (changedProw?.isExtraService) {
          changedProw.amount = parseFloat(
            (percentage * changedProw.amount) / 100
          );
          changedProw.amountPercentage = 100;
        } else {
          changedProw.amount = parseFloat(
            (percentage * changedProw.scopeAmount) / 100
          );
          changedProw.amountPercentage = percentage;
        }
        changedProw.taxAmount = parseFloat(
          changedProw.amount * formData?.taxRate
        );
        changedProw.total =
          parseFloat(changedProw?.amount) +
          (changedProw?.tax ? changedProw?.taxAmount || 0 : 0);
      });
      return JSON.parse(JSON.stringify(prev));
    });
  };

  const onChangeAutoRowToggle = () =>
    setAutoRow((prev) => {
      window.localStorage.setItem("autoRow", !prev);
      return !prev;
    });

  return (
    <div className="header">
      <div className="title">Invoice Details</div>
      <div className="servicesPricePercentage">
        %
        <input
          data-testid="input-percentage"
          ref={servicesInRef}
          style={{ height: 33 }}
          onWheel={(e) => e.blur()}
          disabled={!isWritable && invoiceData}
          type="number"
          min={1}
          onChange={servicesPricePercentage}
          max={100}
          onClick={(e) => {
            if (!isWritable && invoiceData) {
              message.error("Enable write mode!");
              e?.target?.blur();
            }
          }}
        />
      </div>
      <div>
        <div className="autoRowToggleContainer">
          <label style={{ marginRight: 10 }}>Auto Row</label>
          <Switch
            style={{ color: "#1264A3 !important" }}
            checked={autoRow}
            disabled={!isWritable && !!invoiceData}
            onChange={onChangeAutoRowToggle}
          />
        </div>
        <MondayButton
          className={"addNewLineButton"}
          Icon={<NewIcon />}
          onClick={addNewRow}
          disabled={!isWritable && !!invoiceData}
        >
          Add Line
        </MondayButton>
      </div>
    </div>
  );
};

export default TableHeader;
