import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { BackIcon, XIcon } from "../../../../SidebarPages/Communication/assets";
import {
  floorHeaders,
  tableHeaderForJumps,
} from "../SchedulingFirstPage/helperData";
import "./SubPliBreakdown.scss";
import { MondayButton } from "../../../../commonComponents";
import { updateDocumentTitle } from "../../../../../utils";
const JumpsPreviewModal = ({ jumpsForHoist, setJumpsForHoist, isDarkMode }) => {
  const [switchData, setSwitchData] = useState({});
  const tableHeader =
    Object.keys(switchData)?.length > 0 ? floorHeaders : tableHeaderForJumps;

  return (
    <div>
      <Modal
        centered
        open={jumpsForHoist?.open}
        title={
          (Object.keys(switchData)?.length > 0 ? switchData?.jump : "Jumps") +
          " - " +
          jumpsForHoist?.date
        }
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Jumps Preview" });
        }}
        closeIcon={<XIcon />}
        onCancel={() => {
          updateDocumentTitle(); // reset document title to "Lead Manager";
          setJumpsForHoist({ ...jumpsForHoist, open: false });
          setSwitchData({});
        }}
        width={380}
        className={`jumpsPreview-modal ${
          isDarkMode && "jumpsPreview-modal-dark"
        }`}
        footer={
          Object.keys(switchData)?.length > 0 ? (
            <>
              <MondayButton
                onClick={() => setSwitchData({})}
                {...{
                  className: "mondayButtonBlue",
                  Icon: <BackIcon />,
                }}
              >
                Back
              </MondayButton>
            </>
          ) : null
        }
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <tr>
              {tableHeader?.map((header) => {
                return (
                  <th
                    style={{
                      border: "1px solid #e8e8e8",
                    }}
                  >
                    {header}
                  </th>
                );
              })}
            </tr>
            {!Object.keys(switchData)?.length ? (
              jumpsForHoist?.data?.map((a) => {
                const isDayUsed = a?.progressByDay?.find(
                  (b) => b?.day === jumpsForHoist?.dayId
                );
                return (
                  <tr
                    style={{
                      background: !isDayUsed ? "#f5f5f5" : "#368636",
                      color: !isDayUsed ? "#333238" : "#fff",
                      border: "1px solid #e8e8e8",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "center",
                        fontWeight: !isDayUsed ? "" : 600,
                      }}
                    >
                      {a?.name}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Tooltip
                        title={
                          !isDayUsed ? "Day is not used in this jump" : null
                        }
                      >
                        <Button
                          disabled={!isDayUsed}
                          onClick={() =>
                            setSwitchData({
                              jump: a?.name,
                              floors: isDayUsed?.progress,
                            })
                          }
                          style={{
                            background: "#0f5c97",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          {a?.floors?.length}
                        </Button>
                      </Tooltip>
                    </td>
                    <td
                      style={{
                        fontWeight: !isDayUsed ? "" : 600,
                        textAlign: "center",
                      }}
                    >
                      {isDayUsed ? "Yes" : "No"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                {switchData?.floors?.map((q) => {
                  return (
                    <tr
                      style={{
                        background: q?.progress <= 0 ? "#f5f5f5" : "lightgreen",
                        border: "1px solid #e8e8e8",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: q?.progress > 0 ? 600 : "",
                        }}
                      >
                        {q?.floor}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: q?.progress > 0 ? 600 : "",
                        }}
                      >
                        {q?.progress}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default JumpsPreviewModal;
