import {
  useState,
  useEffect,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Tooltip, message, Tabs } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

import {
  isYard,
  getFence,
  getLiveColor,
  findWaypoints,
  isEnteredAlert,
  getLiveActivity,
  linxupEndpoints,
  FleetsLiveContext,
  loadLivePreference,
  saveLocalPreferences,
  findGeofenceCenterCoordinate,
} from "../../../../utils";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import LiveMapContext from "../../LiveMapContext";
import { SideFenceActivity, SideTripsActivity } from "./components";
import { GPlacesAutocompleteInput } from "../../../../../components";
import { FleetWhite } from "../../../../../../../../icons";
import { XIcon } from "../../../../../../Communication/assets";
import { FlagIcon, CommuteIcon } from "../../../../../../../../assets";

import "./SidebarTrip.scss";

const SidebarTrip = forwardRef((_, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    geofences,
    locations,
    lastUpdate,
    globalAudits,
    auditsForDate,
    getTodayLocations,
    actualTripsForTruck,
    plannedTripsForTruck,
  } = useContext(FleetsLiveContext);

  const {
    drawLine,
    mapInstance,
    drawPolygon,
    drawGeofence,
    updateWaypoints,
    onGeofenceRemove,
  } = useContext(LiveMapContext);

  const [activeTrip, setActiveTrip] = useState(-1);
  const [activeFence, setActiveFence] = useState(-1);
  const [liveActivity, setLiveActivity] = useState({});
  const [renderInstance, setRenderInstance] = useState();
  const [activeKey, setActiveKey] = useState("GEOFENCE");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [replayData, setReplayData] = useState({
    allLocations: [],
    allTrips: [],
  });

  useEffect(() => {
    let defaultLocation = loadLivePreference("sidebarTripLocation");
    if (defaultLocation && locations?.length) {
      let location = locations?.find(
        ({ fleetId }) => fleetId === defaultLocation
      );
      if (location) {
        selectLocationHandler(location);
      }
    } else {
      saveLocalPreferences({ sidebarTripLocation: "" });
    }
  }, [locations]);

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }

    let todayFormat = dayjsNY(loadLivePreference("liveSelectedDate")).format();
    let auditToConsider = {};
    if (globalAudits?.auditDate === todayFormat) {
      auditToConsider = structuredClone(
        globalAudits?.alertChanges?.[selectedLocation?.fleetId]
      );
    } else {
      auditToConsider = structuredClone(
        auditsForDate?.[todayFormat]?.alertChanges?.[
          selectedLocation?.fleetId
        ] || {}
      );
    }

    setLiveActivity(
      getLiveActivity({
        geofences,
        planForTruck: plannedTripsForTruck?.[selectedLocation?.fleetId],
        actualActivities: actualTripsForTruck?.[selectedLocation?.fleetId],
        auditsForTruck: auditToConsider,
      })
    );
  }, [
    geofences,
    globalAudits,
    auditsForDate,
    selectedLocation,
    actualTripsForTruck,
    plannedTripsForTruck,
  ]);

  function selectLocationHandler(location) {
    let bar = document.getElementById("live-sidebar-trip");
    if (bar) {
      if (bar.classList.contains("live-sidebar-collapsed")) {
        bar.classList.remove("live-sidebar-collapsed");
      }
      bar.classList.add("live-sidebar-expanded");
    }

    setReplayData({
      allLocations: [],
      allTrips: [],
    });
    drawPolygon([]);
    drawLine([]);
    setActiveTrip(-1);
    setActiveKey("GEOFENCE");
    setSelectedLocation(location);
    removeDirectionsHandler();
    saveLocalPreferences({ sidebarTripLocation: location?.fleetId });
    handleMapController(false);
  }

  let truckActivities = [];
  if (actualTripsForTruck?.[selectedLocation?.["fleetId"]]) {
    let actualTrips = actualTripsForTruck?.[selectedLocation?.["fleetId"]];
    truckActivities = [
      ...actualTrips["truckActivities"],
      ...actualTrips["conflictingActivities"],
    ];
    truckActivities = truckActivities?.filter((activity) => {
      return (
        !isEnteredAlert(activity?.[0]?.["alertCode"]) &&
        isEnteredAlert(activity?.[activity?.length - 1]?.["alertCode"])
      );
    });
    truckActivities.sort(
      (a, b) => a?.[0]?.["alertDateTime"] - b?.[0]?.["alertDateTime"]
    );
    truckActivities.push(actualTrips?.["lastActivity"]?.[0]);
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        showTripDetails(location) {
          selectLocationHandler(location);
        },
        removeDirections() {
          removeDirectionsHandler();
        },
        getSelectedVehicle() {
          return selectedLocation;
        },
      };
    },
    [renderInstance, selectedLocation]
  );

  function handleMapController(willCollapse) {
    let mapController = document.getElementById("live-map-controller");
    if (mapController) {
      let sideWidth = loadLivePreference("liveSidebar") ? 65 : 200;
      let tripWidth =
        loadLivePreference("mapSidebar") === "expanded" ? "18vw" : "0px";

      if (willCollapse) {
        mapController.style.width = `calc(100dvw - ${sideWidth}px - ${tripWidth})`;
      } else {
        mapController.style.width = `calc(100dvw - 18dvw - ${sideWidth}px - ${tripWidth})`;
      }
    }
  }

  function closeBar() {
    let bar = document.getElementById("live-sidebar-trip");
    if (bar) {
      if (bar.classList.contains("live-sidebar-expanded")) {
        bar.classList.remove("live-sidebar-expanded");
      }
      bar.classList.add("live-sidebar-collapsed");
    }

    drawLine([]);
    drawPolygon([]);
    setSelectedLocation(null);
    removeDirectionsHandler();
    saveLocalPreferences({ sidebarTripLocation: "" });
    handleMapController(true);
  }

  async function renderDirections(address) {
    if (!mapInstance || !address) {
      return;
    }

    message.loading({
      content: "Loading directions...",
      key: "directionService",
    });

    let waypoints = [];
    let origin = selectedLocation?.position;

    const { actualStart, actualEnd, fromLocation, toLocation } = liveActivity;

    if (actualStart) {
      if (!actualEnd) {
        origin = fromLocation;
        waypoints = await calcWaypoints(actualStart);
      } else {
        origin = toLocation;
        waypoints = await calcWaypoints(actualEnd);
      }
    }

    updateWaypoints(waypoints);

    const direction = new google.maps.DirectionsService();
    await direction
      .route(
        {
          origin,
          destination: address,
          travelMode: window.google.maps.TravelMode.DRIVING,
          avoidFerries: true,
          avoidHighways: true,
          avoidTolls: true,
          waypoints: waypoints.map(({ location }) => ({ location })),
        },
        (directionRes) => {
          message.destroy("directionService");
          const renderer = new google.maps.DirectionsRenderer({
            directions: directionRes,
            draggable: false,
            map: mapInstance,
            suppressPolylines: false,
            suppressMarkers: true,
          });
          if (renderInstance) {
            renderInstance.setMap(null);
          }
          setRenderInstance(renderer);
        }
      )
      .catch((err) => {
        message.error({
          content: "Something went wrong while getting directions",
          key: "directionService",
        });
        console.log("Error getting directions: ", err);
      });
  }

  function removeDirectionsHandler() {
    if (renderInstance) {
      renderInstance.setMap(null);
      setRenderInstance();
    }

    onGeofenceRemove();
    updateWaypoints([]);
  }

  function goToMapHandler() {
    if (mapInstance) {
      mapInstance.panTo(selectedLocation?.position);
      mapInstance.setZoom(18);
    }
  }

  function onActivityClick(tripIndex, willCollapse = false) {
    if (willCollapse) {
      setActiveFence(-1);
      drawPolygon([]);
    } else {
      setActiveFence(tripIndex);

      const enterFence = getFence(
        truckActivities[tripIndex][truckActivities[tripIndex].length - 1]?.[
          "fenceName"
        ]
      );

      drawPolygon(geofences[enterFence]?.["points"] || []);
    }
  }

  function onRouteClicked(tripIndex, willCollapse = false) {
    if (willCollapse) {
      setActiveTrip(-1);
      drawLine([]);

      setTimeout(() => {
        goToMapHandler();
      }, 1000);
    } else {
      setActiveTrip(tripIndex);

      if (tripIndex === -2) {
        drawLine(
          replayData.allLocations.map(({ latitude, longitude }) => {
            return { latitude, longitude };
          })
        );
      } else {
        const selectedTrip = replayData.allTrips[tripIndex];

        drawLine(
          replayData.allLocations.flatMap(({ date, latitude, longitude }) => {
            if (
              date < selectedTrip?.["startDateTime"] ||
              date > selectedTrip?.["endDateTime"]
            ) {
              return [];
            }

            return { latitude, longitude };
          })
        );
      }
    }
  }

  async function calcWaypoints(fromDate) {
    if (!selectedLocation) {
      return undefined;
    }

    let waypoints = [];

    try {
      waypoints = await axios
        .post(linxupEndpoints.locationsForVehicle, {
          imei: selectedLocation?.imei,
          fromDate: fromDate,
          toDate: lastUpdate,
        })
        .then((res) => {
          let locationsData = res.data.data.locations;
          return findWaypoints(locationsData);
        });
    } catch (err) {
      waypoints = undefined;
      console.log("Error getting waypoints: ", err);
    }

    return waypoints;
  }

  return (
    <div
      className={`live-sidebar-trip live-sidebar-collapsed ${
        isDarkMode ? "sidebar-trip-dark" : ""
      }`}
      id="live-sidebar-trip"
    >
      <div className="trip-header">
        <Tooltip title={"Go to map location"} trigger={"hover"} align={"top"}>
          <div
            className="trip-title"
            onClick={goToMapHandler}
            id="selected-fleet-name"
          >
            <FleetWhite fill={isDarkMode ? "#fff" : "#000"} />
            <span>{selectedLocation?.fleetName}</span>
          </div>
        </Tooltip>
        <Tooltip title="Close">
          <div className="trip-close" onClick={closeBar}>
            <XIcon fill={isDarkMode ? "#fff" : "#000"} />
          </div>
        </Tooltip>
      </div>
      <span className="trip-vin">
        <span>{selectedLocation?.vinNumber}</span>
        {selectedLocation?.locationStatus && (
          <div className="vin-status">
            <span
              style={{ color: getLiveColor(selectedLocation?.locationStatus) }}
            >
              {selectedLocation?.locationStatus}
            </span>
            <span
              className="status-dot"
              style={{
                backgroundColor: getLiveColor(selectedLocation?.locationStatus),
              }}
            ></span>
          </div>
        )}
      </span>
      <div className="trip-body">
        <Tooltip
          title={
            liveActivity?.["nextLocation"]
              ? "See directions to the location"
              : null
          }
        >
          <div
            className={`next-location-info ${
              liveActivity?.["nextLocation"] ? "title-hover" : ""
            }`}
            onClick={() => {
              if (liveActivity?.["nextLocation"]) {
                let location = getFence(liveActivity?.["nextLocation"]);
                let locationFencePoints = geofences?.[location]?.["points"];
                let destination = location;
                if (locationFencePoints) {
                  if (!isYard(location)) {
                    destination =
                      findGeofenceCenterCoordinate(locationFencePoints);
                  }
                  drawGeofence(locationFencePoints);
                }
                renderDirections(destination);
              }
            }}
          >
            <div className="next-location-title">
              <FlagIcon height={18} width={18} fill="#ea4325" />
              <span>Next Location</span>
            </div>
            <span>{liveActivity?.["nextLocation"] || "No Location Found"}</span>
          </div>
        </Tooltip>
        <div className="custom-location-renderer">
          <GPlacesAutocompleteInput
            {...{
              label: "Load directions to location",
              noFormItem: true,
              required: false,
              className: "locations-input",
              style: { width: "100%" },
              onClear: removeDirectionsHandler,
              popupClassName: isDarkMode ? "darkDropDown" : undefined,
              onSelect(value, option) {
                let destination = value;
                if (option?.points) {
                  if (!isYard(destination)) {
                    destination = findGeofenceCenterCoordinate(option?.points);
                  }
                  drawGeofence(option?.points);
                }
                renderDirections(destination);
              },
              placeholder: "Search geofence or locations...",
              groupedOptions: [
                {
                  label: "Geofences",
                  options: Object.values(geofences || {})?.map(
                    ({ name, points }) => ({
                      label: name,
                      value: name,
                      points,
                    })
                  ),
                },
              ],
            }}
          />
        </div>
        {renderInstance && (
          <div className="sidebar-route-details">
            <div className="route-detail">
              <CommuteIcon height={16} width={16} />
              <b className="detail-title">Distance:</b>
              <span className="detail-content">
                {
                  renderInstance?.directions?.routes?.[0]?.legs?.[
                    renderInstance?.directions?.routes?.[0]?.legs?.length - 1
                  ]?.distance?.text
                }
              </span>
            </div>
            <div className="route-detail">
              <ClockCircleOutlined height={16} width={16} />
              <b className="detail-title">Duration:</b>
              <span className="detail-content">
                {
                  renderInstance?.directions?.routes?.[0]?.legs?.[
                    renderInstance?.directions?.routes?.[0]?.legs?.length - 1
                  ]?.duration?.text
                }
              </span>
            </div>
          </div>
        )}
        <Tabs
          {...{
            activeKey,
            onChange(key) {
              setActiveKey(key);
              if (key === "TRIPS") {
                getTodayLocations(selectedLocation).then((res) => {
                  setReplayData(res);
                });
              }
            },
            className: "tabComponent",
            items: [
              {
                key: "GEOFENCE",
                label: "Geofence Activity",
                children: (
                  <SideFenceActivity
                    isDarkMode={isDarkMode}
                    truckActivities={truckActivities}
                    onActivityClick={onActivityClick}
                    activeFence={activeFence}
                  />
                ),
              },
              {
                key: "TRIPS",
                label: "Vehicle Trips",
                children: (
                  <SideTripsActivity
                    isDarkMode={isDarkMode}
                    activeTrip={activeTrip}
                    onRouteClicked={onRouteClicked}
                    allTrips={replayData.allTrips}
                    lastUpdate={lastUpdate}
                  />
                ),
              },
            ],
          }}
        />
      </div>
    </div>
  );
});

export default SidebarTrip;
