import moment from "moment";
export const getDisabledHours = () => {
  let hours = [];
  let momentHour = moment().hour();
  Array(momentHour)
    .fill()
    .map((_, idx) => hours.push(idx));
  // console.log("All hours", hours);
  return hours;
};

export const getDisabledMinutes = (selectedHour, day) => {
  let minutes = [];
  const momentMinutes = moment().minutes();
  if (
    selectedHour === moment().hour() &&
    moment().format("L") === moment(day).format("L")
  ) {
    Array(momentMinutes)
      .fill()
      .map((_, idx) => minutes.push(idx));
    return minutes;
  }
};

export const getDisabledEndHours = (start) => {
  let endHours = [];
  const momentHours = moment(start).hour();
  Array(momentHours)
    .fill()
    .map((_, idx) => endHours.push(idx));

  return endHours;
};

export const getDisabledEndMinutes = (selectedHour, startTime) => {
  let endMinutes = [];
  let momentMinutes = moment(startTime).minutes();
  if (selectedHour === moment(startTime).hour()) {
    Array(momentMinutes)
      .fill()
      .map((_, idx) => endMinutes.push(idx));

    return endMinutes;
  }
  return [];
};
