// import { Alert } from "antd";
import moment from "moment";
export const ValidateEmail = (input) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (input.match(validRegex)) {
    return true;
  }
  window.alert("Invalid email format");

  return false;
};

export const filterCalendars = (calendarsObject) => {
  const filteredCalendars = calendarsObject.filter(
    (calendar) => calendar.accessRole === "owner"
  );
  return filteredCalendars;
};

//  dateObject = { startTime: "", endTime: "", startDay: "", endDay: "" };

export const isDateTimeRangeValid = ({ dateObject }) => {
  if (dateObject.startDay === dateObject.endDay) {
    if (dateObject.startTime > dateObject.endTime) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const validateTimeDateRangeBefore = (dateTimeObject) => {
  if (
    dateTimeObject?.dateRange[0]?.getDay() ===
      dateTimeObject?.dateRange[1]?.getDay() ||
    dateTimeObject?.dateRange[1] === null
  ) {
    if (dateTimeObject.startTime.getTime() > dateTimeObject.endTime.getTime()) {
      window.alert(
        "You cannot pick an earlier time for the end of time of event!"
      );

      return true;
    }
    return false;
  }
};

// dateObjRange = {startFull :"", endFull:""}
export const compareStartEndRange = (dateObjRange) => {
  if (
    dateObjRange.hasOwnProperty("startFull") &&
    dateObjRange.hasOwnProperty("endFull")
  ) {
    const startTime = moment(dateObjRange.startFull).unix();
    const endTime = moment(dateObjRange.endFull).unix();
    try {
      if (startTime > endTime) {
        window.alert(
          "You cannot select as end date a time prior to the start of event. Please select another."
        );
        return false;
      }
      return true;
    } catch (e) {
      console.error("Error Instance for comparing two date ranges", e);
    }
  }
};
