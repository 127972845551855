import { createApplication } from "../../../../../../../../../actions/applications";
import { MondayButton } from "../../../../../../../../commonComponents";
import { newApplicationGenerator } from "./modalFooterFunctions";
import { Close } from "../../../../../../../DynamicView/src";
import "./ModalFooter.scss";

import { Menu, Tooltip, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import broadcastNotification from "../../../../../../../../../helpers/controllers/broadcastNotification";
import { totalCalculator } from "../../../../ApplicationView/components/Header/Components/ControlPanel/controlPanelFunctions";
import { checkIfServiceIsHoist } from "../../../../../../../Estimations/DataEntryGrid/models/Service";
import { CaretDownOutlined } from "@ant-design/icons";
import { TaskIcon } from "src/icons/NotificationIcons";
import { useEditLogs } from "../../../../../../../../../hooks";
import { filterTables } from "../../../../../../../../../utils";

const ModalFooter = ({
  setRedirectToApplication,
  setSelectedMonth,
  selectedMonth,
  // applications,
  closeModal,
  setDueDate,
  chosenSOV,
  canCreate,
  projectId,
  dueDate,
  sovs,
  isNextStep,
  getApplicationId,
}) => {
  const { applications } = useSelector((state) => state.applications);
  const [buttonType, setButtonType] = useState("Finish");
  const buttonTypes = ["Save & Close", "Finish"];
  // const [{ authenticatedUser },  { userConfiguration }] =
  //   useSelector((state) => [
  //     state.authenticatedUser,
  //     state.userConfig,
  //   ]);
  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const { projectName, scheduleId, estimationsSelected, services } =
    sovs?.find((sov) => sov?.scheduleId === chosenSOV) || {};

  const previousApplications = applications?.filter(
    (application) =>
      application?.projectId === projectId &&
      application?.scheduleId === scheduleId
  );

  //here we find the last application for this sov
  const previousApplication = previousApplications?.reduce(
    (acc, cur) => (acc?.applicationNo > cur?.applicationNo ? acc : cur),
    null
  );

  const prevApplicationEvents =
    previousApplication?.services
      ?.flatMap(({ amounts, serviceOptions, label }, idx, arr) =>
        checkIfServiceIsHoist(arr[idx])
          ? serviceOptions[0]?.flatMap(({ amounts }) => amounts)
          : amounts
      )
      ?.filter(Boolean) || [];

  //here we check if the previous application is compleated or not
  const isLastApplicationCompleated =
    prevApplicationEvents?.length !== 0
      ? prevApplicationEvents?.every(
          ({ percentageCompleated, retainage }) =>
            percentageCompleated === 100 && retainage === 0
        )
      : false;

  console.log(
    "prevApplicationEvents",
    prevApplicationEvents?.filter(
      ({ percentageCompleated, retainage }) =>
        percentageCompleated !== 100 || retainage !== 0
    )
  );

  const handleSubmit = async () => {
    //it works like this ,you get services from previous application and adds ony those services that are in previous application, it does not add
    //values that we can add after in sov
    const MERGED_PREVIOUS_APPLICATION = !!previousApplication?.applicationId
      ? await filterTables(
          "includedRentals",
          "applicationId",
          previousApplication?.applicationId || ""
        ).then((res) => {
          return res;
        })
      : [];
    console.log("MERGED_PREVIOUS_APPLICATION", MERGED_PREVIOUS_APPLICATION);
    const application = await newApplicationGenerator({
      previousApplication,
      estimationsSelected,
      selectedMonth,
      projectName,
      scheduleId,
      projectId,
      dueDate,
      services,
      previousApplications,
      MERGED_PREVIOUS_APPLICATION,
    }).then((res) => {
      return res;
    });
    // remove duplicates from array
    const uniqueServices = [
      ...new Set(application?.services?.map((item) => item)),
    ];

    console.log("uniqueServices", application);

    // const totalities = totalCalculator(application, applications);
    // if (
    //   Array.isArray(previousApplications) &&
    //   previousApplications?.length > 0
    // ) {
    //   totalities["prevRentalsRetainage"] =
    //     previousApplication?.totalities["prevRentalsRetainage"] || {};
    // }
    // application.totalities = {
    //   ...totalities,
    //   currentPaymentDue: 0,
    // };
    // console.log("to be saved", {
    //   application,
    //   applications,
    // });

    // Save application to db
    // const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;
    dispatch(
      createApplication(
        application,
        saveAddedLogs,
        isNextStep,
        getApplicationId
      )
    );

    // Close modal and reset selected estimations
    if (buttonType === "Save & Close") {
      closeModal();
    }
    // Redirect to newly created Application
    else if (buttonType === "Finish") {
      setRedirectToApplication(true);
    }
    setSelectedMonth(null);
    setDueDate(null);
  };

  return (
    <div className="newApplicationFooterContainer">
      <MondayButton
        className="cancelButton"
        Icon={<Close />}
        onClick={() => {
          closeModal();
          setSelectedMonth(null);
          setDueDate(null);
        }}
        tooltipCategory="Applications"
        tooltipKey="cancel"
      >
        Cancel
      </MondayButton>
      <div className="rightContainerNewApplication">
        <MondayButton
          className="clearSelection"
          Icon={<Close />}
          onClick={() => (setSelectedMonth(null), setDueDate(null))}
          tooltipCategory="Applications"
          tooltipKey="clear"
        >
          Clear
        </MondayButton>

        <div className="dropdownClassNameNewApplication">
          <Tooltip
            title={
              //   !!isLastApplicationCompleated
              //   	? "Last application for this sov is already completed"
              //   	:
              !canCreate
                ? "Please pick the period and SOV"
                : !!dueDate
                ? ""
                : "Please pick due date"
            }
          >
            <Dropdown.Button
              type="primary"
              placement="topRight"
              icon={<CaretDownOutlined />}
              menu={{
                onClick: (e) => setButtonType(e.key),
                items: buttonTypes
                  ?.filter((type) => type !== buttonType)
                  ?.map((type) => ({
                    key: type,
                    label: type,
                  })),
              }}
              onClick={handleSubmit}
              disabled={!dueDate || !chosenSOV}
            >
              {buttonType}
            </Dropdown.Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ModalFooter;
