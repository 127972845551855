import { SimplePLI } from "./GeneralModels";

export class WorkingPlatformPLI extends SimplePLI {
  serviceId = 10;

  length;
  height;
  width;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateWorkingPlatformPLI extends SimplePLI {
  serviceId = 10;

  length;
  height;
  width;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
