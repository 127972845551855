import InternalChat from "./InternalChat/InternalChat";
import {
  ForceChangePassword,
  NewDashboard,
  MainDynamicLeadsView,
  DynamicLeadsView,
  MainDynamicEstimationsView,
  DynamicEstimationsView,
  MainDynamicOpportunityView,
  DynamicOpportunitiesView,
  MainDynamicProjectsView,
  GeneralProjectOverview,
  DynamicProjectsView,
  ClaimsBasePage,
  ClaimsDynamicView,
  HearingsBasePage,
  HearingsDynamicView,
  MainDynamicClientsView,
  DynamicAccountView,
  ContactsOverview,
  DynamicContactView,
  MainDynamicInspectionView,
  DynamicInspectionView,
  SubcontractorsOverviewBase,
  MainDynamicScheduleView,
  DynamicScheduleView,
  SubLeadsPage,
  PermitDrawingsDynamicView,
  DynamicPermitDrawingView,
  AccountingDynamicView,
  ScheduleOfValueView,
  ApplicationView,
  EditCharge,
  InvoiceModal,
  RentalsView,
  EditPayment,
  PDFTemplates,
  MainDynamicTasksView,
  Pdf,
  FleetManagementDynamicView,
  VehicleDamage,
  PropertyDamage,
  PersonalInjury,
  OtherTradeIncident,
  MainSafetyInspectionView,
  FleetMaintenanceOverview,
  DynamicFleetView,
  DynamicDriverView,
  ViolationDetails,
  // WrapperViolationView,
  NewViolationView,
  MainDynamicDocumentationsView,
  MainDynamicApprovalsView,
  ReportsPage,
  MappingDynamicView,
  DynamicNotesView,
  Defects,
  DefectsView,
  WorkOrderBasePage,
  DynamicWorkOrderView,
  ScheduleMaintenance,
  ScheduleMaintenanceModal,
  FleetsInventoryOverviewBage,
  SafetyBasePage,
  SafetyModal,
  // SafetyInspectionModal,
  IncidentsBasePage,
  IncidentModal,
  DynamicSafetyInspection,
  MaintenanceBasePage,
  MaintenanceModal,
  InspectionBasePage,
  NewDispatchModal,
  MainDynamicFleetInspectionView,
  ToDoBasePage,
  DynamicMaintenanceView,
  DynamicFleetInspectionView,
  ToDoPathModal,
  MainDynamicVendorsView,
  DynamicVendorsView,
  NewBillModal,
  NewRequestPath,
  HrManagementBasePage,
  DynamicEmployeeView,
} from "./RouteLazyComponents";
import Payroll from "./pages/Payroll/Payroll";

export const routeConfigurations = [
  {
    path: "/",
    component: NewDashboard,
    redirectPath: "/dashboard",
  },
  {
    path: "/resetPassword",
    component: ForceChangePassword,
  },
  {
    path: "/login",
    component: NewDashboard,
    redirectPath: "/dashboard",
  },
  {
    path: "/forgotpassword",
    component: NewDashboard,
    redirectPath: "/dashboard",
  },
  {
    path: "/register",
    component: NewDashboard,
    redirectPath: "/dashboard",
  },
  {
    path: "/dashboard",
    component: NewDashboard,
  },
  {
    path: "/internalChat",
    component: InternalChat,
  },
  {
    path: "/leads",
    component: MainDynamicLeadsView,
  },
  {
    path: "/leads/:id",
    component: DynamicLeadsView,
  },
  {
    path: "/estimations",
    component: MainDynamicEstimationsView,
  },
  {
    path: "/estimations/:id",
    component: DynamicEstimationsView,
  },
  {
    path: "/opportunities",
    component: MainDynamicOpportunityView,
  },
  {
    path: "/opportunities/:id",
    component: DynamicOpportunitiesView,
  },
  {
    path: "/projects",
    component: MainDynamicProjectsView,
  },
  {
    path: "/projectsOverview",
    component: GeneralProjectOverview,
  },
  {
    path: "/projects/:id",
    component: DynamicProjectsView,
  },
  {
    path: "/claims",
    component: ClaimsBasePage,
  },
  {
    path: "/claims/:claimId",
    component: ClaimsDynamicView,
  },
  {
    path: "/hearings",
    component: HearingsBasePage,
  },
  {
    path: "/hearings/:id",
    component: HearingsDynamicView,
  },
  {
    path: "/accounts",
    component: MainDynamicClientsView,
  },
  {
    path: "/accounts/:id",
    component: DynamicAccountView,
  },
  {
    path: "/contacts",
    component: ContactsOverview,
  },
  {
    path: "/contacts/:id",
    component: DynamicContactView,
  },
  {
    path: "/inspections",
    component: MainDynamicInspectionView,
  },
  {
    path: "/inspectionsView/:id",
    component: DynamicInspectionView,
  },
  {
    path: "/subcontractors",
    component: SubcontractorsOverviewBase,
  },
  {
    path: "/scheduling",
    component: MainDynamicScheduleView,
  },
  {
    path: "/scheduling/:id",
    component: DynamicScheduleView,
  },
  {
    path: "/dob",
    component: SubLeadsPage,
  },
  {
    path: "/permitdrawings",
    component: PermitDrawingsDynamicView,
  },
  {
    path: "/permitdrawings/:permitId",
    component: DynamicPermitDrawingView,
  },
  {
    path: "/accounting",
    component: AccountingDynamicView,
  },
  {
    path: "/scheduleofvalues/:scheduleId",
    component: ScheduleOfValueView,
  },
  {
    path: "/applications/:applicationId",
    component: ApplicationView,
  },
  {
    path: "/charges/:chargeId",
    component: EditCharge,
  },
  {
    path: "/invoices/:invoiceId",
    component: InvoiceModal,
  },
  {
    path: "/rentals/:rentalsId",
    component: RentalsView,
  },
  {
    path: "/payments/:paymentId",
    component: EditPayment,
  },
  {
    path: "/pdf-templates",
    component: PDFTemplates,
  },
  {
    path: "/tasksManager",
    component: MainDynamicTasksView,
  },
  {
    path: "/pdf",
    component: Pdf,
  },
  {
    path: "/fleets/overview",
    component: FleetManagementDynamicView,
  },
  {
    path: "/fleetDispatching/:id",
    component: NewDispatchModal,
  },
  {
    path: "/vehicleDamage",
    component: VehicleDamage,
  },
  {
    path: "/propertyDamage",
    component: PropertyDamage,
  },
  {
    path: "/personalInjury",
    component: PersonalInjury,
  },
  {
    path: "/otherTradeIncident",
    component: OtherTradeIncident,
  },
  {
    path: "/fleetMaintenance",
    component: MaintenanceBasePage,
  },
  {
    path: "/fleets/:id",
    component: DynamicFleetView,
  },
  {
    path: "/fleet/drivers/:id",
    component: DynamicDriverView,
  },
  {
    path: "/fleetsPaperwork",
    component: FleetManagementDynamicView,
  },
  {
    path: "/fleets/incidents",
    component: FleetManagementDynamicView,
  },
  {
    path: "/fleetsViolationsReport",
    component: ViolationDetails,
  },
  {
    path: "/fleetViolations/:id",
    component: NewViolationView,
  },
  {
    path: "/documentation",
    component: MainDynamicDocumentationsView,
  },
  {
    path: "/approvals",
    component: MainDynamicApprovalsView,
  },
  {
    path: "/reports",
    component: ReportsPage,
  },
  {
    path: "/mapping",
    component: MappingDynamicView,
  },
  {
    path: "/todos",
    component: ToDoBasePage,
  },
  {
    path: "/notes",
    component: DynamicNotesView,
  },
  {
    path: "/hrManagement",
    component: HrManagementBasePage,
  },
  {
    path: "/hrManagement/Employee/:id",
    component: DynamicEmployeeView,
  },
  {
    path: "/fleetDefects",
    // component: Defects,
    component: MaintenanceBasePage,
  },
  {
    path: "/maintenanceChart",
    component: MaintenanceBasePage,
  },
  {
    path: "/defects/:id",
    component: DefectsView,
  },
  {
    path: "/workOrders",
    // component: WorkOrderBasePage,
    component: MaintenanceBasePage,
  },
  {
    path: "/workOrders/:id",
    component: DynamicWorkOrderView,
  },
  {
    path: "/scheduleMaintenance",
    // component: ScheduleMaintenance,
    component: MaintenanceBasePage,
  },
  {
    path: "/scheduleMaintenance/:id",
    component: ScheduleMaintenanceModal,
  },
  {
    path: "/fleetsInventory",
    component: FleetsInventoryOverviewBage,
  },
  {
    path: "/safety",
    component: SafetyBasePage,
  },
  {
    path: "/vendors",
    component: MainDynamicVendorsView,
  },
  {
    path: "/vendors/:vendorId",
    component: DynamicVendorsView,
  },
  {
    path: "/bills/:billId",
    component: NewBillModal,
  },
  {
    path: "/safetyInspections",
    component: MainSafetyInspectionView,
  },
  {
    path: "/safety/:id",
    component: SafetyModal,
  },
  {
    path: "/incidents",
    component: IncidentsBasePage,
  },
  {
    path: "/incidents/:id",
    component: IncidentModal,
  },
  {
    path: "/safetyInspections/:id",
    component: DynamicSafetyInspection,
  },
  {
    path: "/fleetInspections",
    component: MaintenanceBasePage,
  },
  {
    path: "/fleetInspections/:id",
    component: DynamicFleetInspectionView,
  },
  {
    path: "/todos/:id",
    component: ToDoPathModal,
  },
  {
    path: "/projectCost",
    component: Payroll,
  },
  {
    path: "/approvals/:id",
    component: NewRequestPath,
  },
  // Add more route configurations here...
];
