import {
  dataGridColumns,
  dispatchFilters,
} from "../../OldModal/newDispatchModalData";
import uniq from "lodash/uniq";
import { AgGridReact } from "ag-grid-react";
import { SelectFilter } from "../../../../../../XComponents";
import { Button } from "antd";
import { clearAllFilters } from "../../../../../../utils";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

const DispatchesTable = ({
  dispatches,
  form,
  onGridReady,
  gridApi,
  setTableHide,
  rowData,
  isDarkMode,
}) => {
  //Populates the JSON of the table filters
  const filters = dispatchFilters({
    trucks: uniq(dispatches?.map((el) => el?.fleetName)),
    drivers: uniq(
      dispatches?.reduce(
        (acc, val) => [
          ...acc,
          ...val?.routes?.map((el) => el?.driverName ?? val?.driverName),
        ],
        []
      )
    ),
    date: uniq(
      dispatches?.map(({ dispatchDate }) =>
        dayjsNY(dispatchDate).format("MM/DD/YYYY")
      )
    ),
    dropOffLocations: uniq(
      dispatches?.reduce(
        (accumulated, { routes = [] }) => [
          ...accumulated,
          ...routes?.reduce(
            (acc, { dropOffLocation }) => [...acc, dropOffLocation],
            []
          ),
        ],
        []
      )
    ),
  });

  //Clears all filters
  const onClearAllFilters = () => {
    form.resetFields(filters?.map(({ formItemName }) => formItemName));
    clearAllFilters({
      filters: dataGridColumns?.map(({ field }) => field),
      gridApi,
    });
  };

  return (
    <div className="background-grid-modal">
      <div className="contentContainer">
        <div className="filtersCard">
          <div
            className="inputContainer"
            style={{ alignItems: "end", justifyContent: "space-between" }}
          >
            <div className="select-wraper">
              {filters?.map(
                ({ label, formItemName, column, filterType, options }, i) => (
                  <div
                    className="selectFilterContainer"
                    key={i}
                    style={{
                      marginBottom: 0,
                      width: 300,
                    }}
                  >
                    <SelectFilter
                      {...{
                        label,
                        formItemName,
                        defaultValue: "All",
                        column,
                        gridApi,
                        form,
                        filterType,
                        options,
                        isDarkMode,
                      }}
                    />
                  </div>
                )
              )}
            </div>
            <div className="button-div">
              <div
                className="hideTable"
                onClick={() => setTableHide(false)}
                data-testid="dispatch-hide-table"
              >
                Hide Table
              </div>
              <Button
                className="clearFilterBtn-modal"
                onClick={onClearAllFilters}
              >
                Clear Filters
              </Button>
            </div>
          </div>
        </div>
        <div
          className={`${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "ag-theme-custom-react ag-theme-alpine"
          }`}
          style={{ width: "100%", height: 300 }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={dataGridColumns}
            animateRows
            rowHeight={38}
            rowData={rowData}
            defaultColDef={{
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DispatchesTable;
