import { Alert, Progress } from "antd";
import React, { useEffect, useRef, useState } from "react";

const ReportProgress = ({ type, progress }) => {
  const [percent, setPercent] = useState(progress || 0);

  const intervalRef = useRef(null);

  if (percent >= 99 && intervalRef.current) {
    clearInterval(intervalRef.current);
  }

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setPercent((prevPercent) => {
        if (prevPercent < progress) {
          return progress;
        } else {
          return prevPercent + 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, [progress]);

  return (
    <div className="report-progress-bar">
      {percent >= 99 && (
        <Alert
          message="It is taking longer to open report. Please wait..."
          type="warning"
        />
      )}
      {percent < 99 && (
        <div className="report-progress-info">
          <Alert message="Preparing report. Please wait..." type="warning" />
          <Progress percent={percent} type={type} />
        </div>
      )}
    </div>
  );
};

export default ReportProgress;
