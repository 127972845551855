import React from "react";
import { message } from "antd";
import { Select } from "antd";

import _ from "lodash";

const { Option } = Select;
export default class SelectEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getValue(this.props) || "none",
      visible: false,
      selectOpen: false,
    };
  }

  componentDidMount() {
    // const items = this.props.gridData[this.props.serviceIndex].serviceOptions[this.props.optionIndex][this.props.elevationIndex].items;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // const items = this.props.gridData[this.props.serviceIndex].serviceOptions[this.props.optionIndex][this.props.elevationIndex].items;
    // const itemsPrev = prevProps.gridData[this.props.serviceIndex].serviceOptions[this.props.optionIndex][this.props.elevationIndex].items;

    const currentValue = this.getValue(this.props);
    if (this.getValue(prevProps) !== currentValue) {
      this.setState({ value: currentValue });
    }
  }

  getValue = (props) => {
    return props.data[props.colDef.field];
  };

  handleSelectChange = (value) => {
    if (this.props.isWritable) {
      this.setState({ value });

      const gridData = _.cloneDeep(this.props.gridData);
      let items = this.getAllRows(this.props.api);

      for (let i = 0; i < items.length; i++) {
        if (items[i].id === this.props.node.id) {
          items[i][this.props.colDef.field] = value;
          break;
        }
      }

      gridData[this.props.serviceIndex].serviceOptions[this.props.optionIndex][
        this.props.elevationIndex
      ].items = items;

      this.props.updateStateAndSave(gridData);
    } else {
      message.error("Please enable write mode to start editing");
    }
    // this.stopEditing(true);
  };

  getAllRows(api) {
    let rowData = [];
    api.forEachNode((node) => rowData.push(node.data));
    return rowData;
  }

  handleSelectBlur = () => {
    this.setState({
      selectOpen: false,
    });
  };

  stopEditing = () => {
    setTimeout(() => {
      this.props.api.stopEditing();
    }, 100);
  };

  handleClose = () => {
    this.setState({ selectOpen: false });
  };

  handleOpen = () => {
    this.setState({ selectOpen: true });
  };

  render() {
    const selectId = Math.random().toString();

    // if(!this.props.isWritable) message.error("Please enable write mode to start editing.")

    return (
      <div>
        <Select
          variant="filled"
          disabled={!this.props.isWritable}
          id={selectId}
          className={
            this?.props?.agGridTheme === "ag-theme-balham-dark"
              ? "dataEntryGrid-cell-select-DARK"
              : "dataEntryGrid-cell-select"
          }
          placeholder="Select an option"
          // open={this.state.selectOpen}
          value={this.state.value}
          onChange={(event) => this.handleSelectChange(event.target.value)}
        >
          <Option value="none">Not defined</Option>
          {this.props.values.map((v, i) => {
            return (
              <Option key={i} value={v}>
                {v}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  }
}
