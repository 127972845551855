import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 *
 * @param {String} date date or time value from formField,
 * @param {String} format format of the  input
 * @param {String} type to check if it is timeInput
 * @returns
 */
function dateToSave(date, format, type) {
  if (type === "time") {
    let today = dayjs().format("MM/DD/YYYY");
    return dayjs
      .tz(`${today} ${date}`, `MM/DD/YYYY ${format}`, "America/New_York")
      .format();
  }
  return dayjs.tz(date, format, "America/New_York").format();
}

export default dateToSave;
