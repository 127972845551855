import { Stepper } from "../../../../../commonComponents";

const TaskStepper = ({ currentStep, setCurrentStep, steps }) => {
  return (
    <Stepper
      {...{
        stepRenderer: false,
        currentStep,
        steps,
        setCurrentStep,
        stepperClassName: "taskStatusSteper",
      }}
      // setFormChanged={setFormChanged}
    />
  );
};

export default TaskStepper;
