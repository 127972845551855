import { keys } from "../../pages/Settings/settingsComponents/Roles/RolesData";
import { widgetPopulationFunction } from "./logicDataFunctions/widgetPopulationFunction";
// import { Result, message } from "antd";

/**
 * Generates the logic for populating the dashboard widgets based on the provided data.
 *
 * @param {Array} preferenceDashData - The preference dashboard data.
 * @param {Object} programFields - The program fields data.
 * @param {Object} tables - The tables data.
 * @param {string} url - The URL.
 * @param {Object} userConfiguration - The user configuration data.
 * @returns {Array} - The updated preference dashboard data.
 */
export const dashboardLogic = ({
  preferenceDashData,
  programFields,
  tables,
  url,
  userConfiguration,
}) => {
  //this function returns an object with values of widget
  const populateWidgetData = widgetPopulationFunction({
    tables,
    programFields,
    userConfiguration,
  });
  const noFunctionCondition = (a) => {
    return !!populateWidgetData[a?.type]()?.[a?.BodyComponentParams?.table]()?.[
      a?.BodyComponentParams?.field
    ];
  };
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "LineChartFull")
    ?.map((a) => {
      if (noFunctionCondition(a)) {
        a.BodyComponentParams.datasets = a.BodyComponentParams.datasets?.map(
          (el, index) => ({
            ...(el || {}),
            ...(populateWidgetData[a?.type]()
              [a?.BodyComponentParams?.table]()
              [a?.BodyComponentParams?.field]()[index] || {}),
          })
        );

        a.BodyComponentParams.labels = populateWidgetData[a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.[0]?.labels;

        a.BodyComponentParams.custom = populateWidgetData[a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.[0]?.custom;
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  //this function populates barCharts that comes from db
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "BarChart")
    ?.map((a) => {
      if (noFunctionCondition(a)) {
        if (url === "accounting") {
          a.BodyComponentParams.datasets = a.BodyComponentParams.datasets?.map(
            (el, index) => ({
              ...(el || {}),
              ...(populateWidgetData[a?.type]()
                [a?.BodyComponentParams?.table]()
                [a?.BodyComponentParams?.field]()[index] || {}),
            })
          );

          a.BodyComponentParams.labels = populateWidgetData[a?.type]()
            [a?.BodyComponentParams?.table]()
            [a?.BodyComponentParams?.field]()?.[0]?.labels;

          a.BodyComponentParams.custom = populateWidgetData[a?.type]()
            [a?.BodyComponentParams?.table]()
            [a?.BodyComponentParams?.field]()?.[0]?.custom;
        } else {
          // message.error("Entered else");
          a.BodyComponentParams.labels = populateWidgetData[a?.type]()
            [a?.BodyComponentParams?.table]()
            [a?.BodyComponentParams?.field]()?.labels;
          a.BodyComponentParams.datasets[0].backgroundColor =
            populateWidgetData[a?.type]()
              [a?.BodyComponentParams?.table]()
              [a?.BodyComponentParams?.field]()?.backgroundColor;
          a.BodyComponentParams.datasets[0].data = populateWidgetData[a?.type]()
            [a?.BodyComponentParams?.table]()
            [a?.BodyComponentParams?.field]()?.data;
        }
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  //this function populates longBarCharts that comes from db
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "BarChartFull")
    ?.map((a) => {
      if (noFunctionCondition(a)) {
        a.BodyComponentParams.labels = populateWidgetData[a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.labels;

        a.BodyComponentParams.datasets[0].backgroundColor = populateWidgetData[
          a?.type
        ]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.backgroundColor;

        a.BodyComponentParams.datasets[0].data = populateWidgetData[a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.data;
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  //this function populates PieCharts that comes from db
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "Pie")
    ?.map((a) => {
      if (noFunctionCondition(a)) {
        if (url === "accounting") {
          a.BodyComponentParams.datasets = a.BodyComponentParams.datasets?.map(
            (el, index) => ({
              ...(el || {}),
              ...(populateWidgetData[a?.type]()
                [a?.BodyComponentParams?.table]()
                [a?.BodyComponentParams?.field]()[index] || {}),
            })
          );

          a.BodyComponentParams.custom = populateWidgetData[a?.type]()
            [a?.BodyComponentParams?.table]()
            [a?.BodyComponentParams?.field]()?.[0]?.custom;
        } else {
          a.BodyComponentParams.labels = populateWidgetData[a?.type]()
            [a?.BodyComponentParams?.table]()
            [a?.BodyComponentParams?.field]()?.labels;

          a.BodyComponentParams.datasets[0].backgroundColor =
            populateWidgetData[a?.type]()
              [a?.BodyComponentParams?.table]()
              [a?.BodyComponentParams?.field]()?.backgroundColor;

          a.BodyComponentParams.datasets[0].data = populateWidgetData[a?.type]()
            [a?.BodyComponentParams?.table]()
            [a?.BodyComponentParams?.field]()?.data;
        }
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  //this function populates ProgressBars that comes from db
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "Progress")
    ?.map?.((a) => {
      if (noFunctionCondition(a)) {
        a.BodyComponentParams.statusData = populateWidgetData[a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.statusData;
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  //this function populates StraightProgressBars that comes from db
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "StreightProgressBar")
    ?.map((a) => {
      if (noFunctionCondition(a)) {
        a.BodyComponentParams.content = populateWidgetData[a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.content;
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  //this function populates longLineSteppers that comes from db
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "LongLineSteper")
    ?.map((a) => {
      if (noFunctionCondition(a)) {
        a.BodyComponentParams.content = populateWidgetData[a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.content;
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  //this function populates circleProgressBars that comes from db
  preferenceDashData
    ?.filter(({ BodyComponent }) => BodyComponent === "CircleProgresBar")
    ?.map((a) => {
      if (noFunctionCondition(a)) {
        const circleProgressPicker = a?.BodyComponentParams?.content;
        a.BodyComponentParams.percent = widgetPopulationFunction({
          tables,
          circleProgressPicker,
          userConfiguration,
        })
          [a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.percent;

        a.BodyComponentParams.custom = widgetPopulationFunction({
          tables,
          circleProgressPicker,
          userConfiguration,
        })
          [a?.type]()
          [a?.BodyComponentParams?.table]()
          [a?.BodyComponentParams?.field]()?.custom;
      } else {
        a.BodyComponentParams = {
          ...a.BodyComponentParams,
          noFunction: true,
        };
      }
    });

  return preferenceDashData;
};
//this functions populates dynamic states
export const populateDynamicStates = ({ statesData, tables }) => {
  return keys?.(statesData)?.reduce?.(
    (acc, key) => ({ ...acc, [key]: tables[statesData[key]] }),
    {}
  );
};
