import { Tooltip } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import FenceList from "../FenceList/FenceList";
import { ManageAlternatesIcon, ManageAlternatesIconBlack } from "src/assets";

function NearestFences({
  mode,
  view,
  toggleInfo,
  toggleView,
  isDarkMode,
  dataVisible,
  onFenceClick,
  sortedFences,
  projectToLink,
  selectedFence,
  sortedProjects,
  projectsLoading,
}) {
  return (
    <div className="nearest-geofences">
      <div className="nearest-title">
        <Tooltip title={dataVisible ? "Collapse Stop Info" : "Show Stop Info"}>
          <div className="side-arrow" onClick={toggleInfo}>
            <MenuOutlined />
          </div>
        </Tooltip>
        <div className="view-title">
          <b>{view[0] + view.slice(1).toLowerCase()}</b>
          {!projectToLink && mode !== "DRAW" && (
            <Tooltip
              title={
                view === "PROJECTS"
                  ? "View nearest geofences"
                  : "View nearest projects"
              }
            >
              {isDarkMode ? (
                <ManageAlternatesIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleView();
                  }}
                />
              ) : (
                <ManageAlternatesIconBlack
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleView();
                  }}
                />
              )}
            </Tooltip>
          )}
        </div>
      </div>
      <FenceList
        {...{
          view,
          onFenceClick,
          sortedFences,
          selectedFence,
          sortedProjects,
          projectsLoading,
        }}
      />
    </div>
  );
}

export default NearestFences;
