import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";
import { compareIncluding } from "../../utils";
import createPDF from "../../../../integrations/createPDF";
import { savePdfToDrive } from "../../Safety/safetyPdf";
import { parseText } from "../../Accounting/components/utilities";
import htmlParser from "../../../../utils/htmlParser";
import { fetchPdfImages } from "../../Safety/SafetyModal/components/SafetyExportPreview/helpers/initialExportData";
import { titleBodyHtmlTable } from "../../Safety/SafetyModal/components/SafetyExportPreview/helpers/convertSafetyReport";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let date = new Date().toLocaleDateString();

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str?.toString();
  return str?.replace(/(<([^>]+)>)/gi, "");
}

const fieldsForPdf = (topicCategories, relatedTo) => {
  const topicObjects = topicCategories?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr?.categoryName]: {
        table: curr?.apiName,
        searchKey: curr?.rowName,
        idKey: curr?.primaryKey,
      },
    }),
    {}
  );
  return [
    {
      label: "Title",
      formItemName: "todoName",
    },
    {
      label: "Related To",
      formItemName: "relatedTo",
    },
    !!topicObjects[relatedTo] && {
      // records?.length > 0 &&
      label: "Related Record",
      formItemName: "subRecordName",
    },
    {
      label: "Due Date",
      formItemName: "todoDueDate",
    },
    {
      label: "Description",
      formItemName: "todoDescription",
    },
    {
      label: "Comments",
      formItemName: "comments",
    },
  ].filter(Boolean);
};

const pdfBody = ({ rowObject, fields }) => {
  let tmpPdfData = [
    {
      title: "Category",
      value: rowObject?.todoCategory,
    },
    {
      title: "Status",
      value: rowObject?.todoStatus,
    },
    {
      title: "Record",
      value: rowObject?.recordName,
    },
  ];
  let labelsData = [
    ...fields,
    {
      formItemName: "dueDate",
      label: "Due Date",
    },
  ]?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr?.formItemName]: curr?.label,
    }),
    {}
  );

  Object.keys(rowObject)?.forEach((key) => {
    if (labelsData[key] && key !== "comments") {
      if (key?.includes("Date")) {
        tmpPdfData.push({
          title: labelsData[key],
          value: dayjs(rowObject[key])?.format("MM/DD/YYYY"),
        });
      } else if (
        rowObject?.[key]?.hasOwnProperty("name") &&
        rowObject?.[key]?.hasOwnProperty("id")
      ) {
        tmpPdfData.push({
          title: labelsData[key],
          value: rowObject[key]?.name,
          path: `${
            window.location.origin
          }/${rowObject?.relatedTo?.toLowerCase()}/${rowObject?.[key]?.id}`,
        });
      } else {
        tmpPdfData.push({
          title: labelsData[key],
          value: rowObject[key],
        });
      }
    }
  });

  //   tmpPdfData?.push();

  return tmpPdfData;
};

const todoNoteParser = (note, noteArray, index) => {
  noteArray.push({
    title: `${"—".repeat(index) + note?.author} - ${dayjs(
      note?.createdAt
    ).format("MM/DD/YYYY hh:mm A")}`,
    value: parseText(htmlParser(note?.commentContent || note?.replyContent)),
  });

  if (note?.replies?.length > 0) {
    const { replies = [] } = note;
    replies?.map((reply) => todoNoteParser(reply, noteArray, index + 1));
  } else return;
};

const commentsBody = ({ comments }) => {
  let commentsArray = [];
  comments?.map((note) => todoNoteParser(note, commentsArray, 0));

  return commentsArray;
};
const fillColor = (rowIndex) => {
  return rowIndex % 2 === 0 ? "#f4f4f4" : "#fff";
};

const pdfBorderedSection = ({ title, body = [], noColor = false }) => {
  const formattedBody = body.map((item) => {
    return title
      ? {
          columns: [
            {
              width: "35%",
              text: item?.title,
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
            {
              width: "5%",
              text: "",
              alignment: "center",
              style: ["lastColumn", "cellItem"],
            },
            {
              width: "60%",
              text: item?.value,
              link: item?.path || null,
              alignment: "left",
              style: ["lastColumn", "cellItem"],
            },
          ],
        }
      : {};
  });

  const tableBody = [
    title && {
      text: title,
      style: ["lastColumn", "strong", "subheader"],
    },
    ...formattedBody,
  ]?.filter((item) => !!item);

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: tableBody?.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

const docDefinition = (title, dynamicPdf, companyLogo) => {
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: title ? title : "",
                style: ["strong", "large"],
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
          {
            text: `Date: ${date}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
      <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
    </svg>`,
        width: 800,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },

      dynamicPdf?.flatMap((el) => el),
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
    },
  };
};

export function createTodoPdf({
  base64 = [],
  rowObject = {},
  relatedTo = "",
  topicCategories = [],
}) {
  const configPdfData = [
    pdfBorderedSection({
      title: "To Do Details",
      body: pdfBody({
        rowObject,
        fields: fieldsForPdf(topicCategories, relatedTo),
      }),
    }),
    pdfBorderedSection({
      title: "To Do Comments",
      body: commentsBody({ comments: rowObject?.comments }),
    }),
  ];

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  createPDF({
    action: "open",
    docDefinition: docDefinition(
      `${rowObject?.todoName || "To Do"}`,
      configPdfData,
      companyLogo
    ),
  });
}

export async function emailTodo({
  base64 = [],
  relatedTo = "",
  rowObject = {},
  accessToken = "",
  topicCategories = [],
}) {
  const configPdfData = [
    pdfBorderedSection({
      title: "To Do Details",
      body: pdfBody({
        rowObject,
        fields: fieldsForPdf(topicCategories, relatedTo),
      }),
    }),
    pdfBorderedSection({
      title: "To Do Comments",
      body: commentsBody({ comments: rowObject?.comments }),
    }),
  ];

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  return new Promise(
    async (resolve, reject) =>
      await savePdfToDrive({
        docDefinition: docDefinition(
          `${rowObject?.todoName || "To Do"}`,
          configPdfData,
          companyLogo
        ),
        folderId: "1GHCrIfhLGUT1-eLoDdDkeCMatWaQdIvH",
        accessToken,
        title: `${rowObject?.todoName || "To Do"}`,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
  );
}

export async function exportToDoData({
  rowObject = {},
  relatedTo = "",
  topicCategories = [],
  accessToken,
}) {
  function titleToKey({ title, value }) {
    return {
      key: title,
      value,
    };
  }
  const detailsFields = pdfBody({
    rowObject,
    fields: fieldsForPdf(topicCategories, relatedTo),
  }).map(titleToKey);

  const comments = commentsBody({ comments: rowObject?.comments }).map(
    titleToKey
  );
  const configPdfData = [
    {
      title: "To Do Details",
      body: detailsFields,
    },
    {
      title: "To Do Comments",
      body: comments,
    },
  ];
  const images =
    (await fetchPdfImages(
      { googleDriveUpload: rowObject?.googleDriveUploads || [] },
      accessToken
    )) || [];

  return configPdfData.concat(images);
}

export function convertToDoReport(data) {
  const dataSections = [];
  if (!Array.isArray(data)) {
    return dataSections;
  }

  for (const section of data) {
    if (section.title === "To Do Comments") {
      const { title, body } = section;
      const titleClassName = `safety-section-header`;
      const titleFormat = {
        text: {
          paragraphs: [
            {
              text: title,
              bold: true,
              fontSize: 16,
              class: titleClassName,
            },
          ],
        },
      };
      dataSections.push(titleFormat);

      const comments = {
        list: {
          items: body.map(({ key, value }) => {
            return `<li class="todo-comment ${
              key?.includes("-") ? "todo-comment-reply" : ""
            }">
                    <div class="todo-comment-title">• ${key}</div>
                    <div class="todo-comment-value">${value}</div>
                  </li>`;
          }),
          customRender: true,
          listClass: "todo-comment-list",
        },
      };

      dataSections.push(comments);
    } else {
      const data = titleBodyHtmlTable(section, dataSections) || [];
      dataSections.push(...data);
    }
  }

  return dataSections;
}
