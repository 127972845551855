function getAllRolesOptions({ userGroups, departments = [] }) {
  const roles = transformData(userGroups) || [];
  if (departments?.length > 0) {
    return roles?.filter(({ label = "" }) => departments?.includes(label));
  } else {
    return roles;
  }
}

export default getAllRolesOptions;

function transformData(userGroups) {
  const result = [];

  userGroups.forEach(({ groupName, departmentName }) => {
    let department = result?.find((dep) => dep?.label === departmentName);

    if (!department) {
      department = {
        label: departmentName,
        title: departmentName,
        options: [],
      };
      result?.push(department);
    }
    if (!department?.options?.find((opt) => opt?.label === groupName)) {
      department?.options?.push({
        label: groupName,
        value: department?.label + "*" + groupName,
        key: departmentName + groupName,
      });
    }
  });

  return result;
}
