import { Form, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { EyeInvisibleFilled } from "@ant-design/icons";

import {
  FilterIcon,
  DownloadIcon,
} from "../../../../../SidebarPages/BasePage/src";
import {
  BackArrow,
  ClearFilterIcon,
} from "../../../../../SidebarPages/DynamicView/src";
import PayrollContext from "../../../PayrollContext";
import { PDFIcon, PlusIcon } from "../../../../../../assets";
import ChartCardModal from "./ChartCardModal/ChartCardModal";
import { MondayButton } from "../../../../../commonComponents";
import AnalyticsChartsContext from "../AnalyticsChartsContext";
import ChartsPdfExport from "./ChartsPdfExport/ChartsPdfExport";
import HiddenChartsSider from "./HiddenChartsSider/HiddenChartsSider";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import CardIndividualFilter from "./CardIndividualFilter/CardIndividualFilter";

function AnalyticsChartsController() {
  const {
    layout,
    changes,
    dataSets,
    setLayout,
    setChanges,
    saveLayout,
    filtersData,
    clearFilters,
    setFilterOpen,
    revertChanges,
    individualFilters,
    selectedDataOptions,
    individualFilterModal,
    setIndividualFilterModal,
  } = useContext(AnalyticsChartsContext);
  const { form, fetchAnalytics } = useContext(PayrollContext);
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  const [pdfExport, setPdfExport] = useState(false);
  const [createCardModal, setCreateCardModal] = useState(false);
  const [hiddenCardsDrawer, setHiddenCardsDrawer] = useState(false);

  const filterKeys = Object.keys(filtersData).flatMap((key) =>
    !!filtersData[key] ? key : []
  );

  const indvFilterKeys = Object.keys(individualFilters);

  return (
    <header className="charts-controller">
      <section className="filter-section">
        <MondayButton
          className="mondayButtonBlue"
          Icon={<FilterIcon />}
          onClick={() => setFilterOpen(true)}
          data-testid="charts-filter-btn"
        >
          Filter
        </MondayButton>
        <MondayButton
          className={
            !!filterKeys?.length || !!indvFilterKeys?.length
              ? "mondayButtonRed"
              : "mondayButtonGrey"
          }
          disabled={!filterKeys?.length && !indvFilterKeys?.length}
          Icon={<ClearFilterIcon />}
          onClick={clearFilters}
        >
          Clear Filters
        </MondayButton>
      </section>

      <section className="layout-controller">
        <Form
          form={form}
          style={{ display: "flex", flexDirection: "row", gap: 10 }}
        >
          <InputComponent
            type="rangepicker"
            placeholder="Set Date Range..."
            formItemName={"analyticsDateRange"}
            dataTestid={"analyticsDateRange"}
            dropdownClassName={isDarkMode && "darkDateDropDown"}
          />
          <InputComponent
            type="select"
            placeholder="Select company..."
            formItemName={"companySelected"}
            dataTestid={"companySelected"}
            customOptions={[
              {
                key: 0,
                label: "GMNY Construction",
                value: "GMNY Construction",
              },
              {
                key: 1,
                label: "Yard Management Services",
                value: "Yard Management Services",
              },
            ]}
            dropdownClassName={isDarkMode && "darkDropDown"}
          />
          <MondayButton
            className="mondayButtonBlue"
            onClick={fetchAnalytics}
            Icon={<DownloadIcon />}
          >
            Fetch Analytics
          </MondayButton>
        </Form>
        {changes ? (
          <MondayButton
            className="mondayButtonBlue"
            Icon={<BackArrow />}
            onClick={revertChanges}
          >
            Revert Changes
          </MondayButton>
        ) : null}
        <Tooltip title="Export to PDF" placement="bottom">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<PDFIcon style={{ marginInlineStart: 0 }} />}
            onClick={() => setPdfExport(true)}
            data-testid="charts-pdf-btn"
          >
            {""}
          </MondayButton>
        </Tooltip>
        <Tooltip title="Hidden cards" placement="bottom">
          <MondayButton
            className="mondayButtonBlue"
            Icon={<EyeInvisibleFilled style={{ marginInlineStart: 0 }} />}
            onClick={() => setHiddenCardsDrawer(true)}
            data-testid="hidden-charts-btn"
          >
            {""}
          </MondayButton>
        </Tooltip>
        <MondayButton
          className="mondayButtonBlue"
          Icon={<PlusIcon />}
          onClick={() => setCreateCardModal(true)}
          data-testid="chart-generator-btn"
        >
          Add Chart Card
        </MondayButton>
        <MondayButton
          disabled={!changes}
          Icon={<TickIcon />}
          onClick={saveLayout}
        >
          Save Layout
        </MondayButton>
      </section>
      {createCardModal && (
        <ChartCardModal
          layout={layout}
          dataSets={dataSets}
          setLayout={setLayout}
          open={createCardModal}
          setChanges={setChanges}
          selectedDataOptions={selectedDataOptions}
          onCancel={() => setCreateCardModal(false)}
        />
      )}
      {hiddenCardsDrawer && (
        <HiddenChartsSider
          open={hiddenCardsDrawer}
          onClose={() => setHiddenCardsDrawer(false)}
        />
      )}
      {pdfExport && (
        <ChartsPdfExport open={pdfExport} onClose={() => setPdfExport(false)} />
      )}
      {!!individualFilterModal?.cardKey && (
        <CardIndividualFilter
          open={!!individualFilterModal?.cardKey}
          onCancel={() => setIndividualFilterModal(false)}
          cardData={individualFilterModal}
        />
      )}
    </header>
  );
}

export default AnalyticsChartsController;
