export const bodyObject = ({ form }) => ({
  itemName: form.getFieldValue("itemName"),
  itemCategory: form.getFieldValue("itemCategory"),
  itemDescription: form.getFieldValue("itemDescription"),
  itemService: form.getFieldValue("itemService"),
});

export const fieldsJson = (items, services) => {
  const categories = Array.from(
    new Set(items.map((item) => item.itemCategory))
  ).map((category) => ({
    label: category,
    value: category,
  }));

  const allServices = Array.from(
    new Set(services.map((service) => service.serviceName))
  ).map((service) => ({
    label: service,
    value: service,
  }));

  return [
    {
      label: "Name",
      formItemName: "itemName",
      placeholder: "Name",
      type: "input",
      required: true,
    },
    {
      label: "Category",
      formItemName: "itemCategory",
      placeholder: "Category",
      type: "select",
      required: true,
      customOptions: categories,
    },
    {
      label: "Service",
      formItemName: "itemService",
      placeholder: "Service",
      type: "select",
      required: true,
      customOptions: allServices,
    },
    {
      label: "Description",
      formItemName: "itemDescription",
      placeholder: "Description",
      type: "textarea",
      required: true,
      rows: 5,
    },
  ];
};
