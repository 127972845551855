import "./MultiplyNotesDrawer.scss";

import { Alert, Drawer } from "antd";
import Geofence from "../../../../../../../commonComponents/Map/Geofence/Geofence";
import { NotesComponent } from "../../../../../../../commonComponents/Notes/components/NotesComponent";
import { LoadingDots } from "../../../../../../../commonComponents/3LoadingDots/LoadingDots";
import { useSelector } from "react-redux";

const MultiplyNotesDrawer = ({
  open,
  setOpen,
  handleClickedShape,
  currNotes,
  clickedShape,
  setCurrNotes,
  loading,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const titleOfDrawer = clickedShape
    ? clickedShape?.title
    : open?.recordAddress;

  const customLinkTo = `/${open.isDrawedIn.toLowerCase()}/${open.recordId}`;

  return (
    <Drawer
      rootClassName={`multiplyNotesDrawer ${isDarkMode && "dark"}`}
      title={titleOfDrawer}
      width={588}
      open={open}
      onClose={setOpen}
    >
      {!clickedShape && (
        <Alert
          type="info"
          message="Click a shape to show or create notes"
          showIcon
          closable
          style={{ marginBottom: "20px" }}
        />
      )}
      <Geofence
        {...{
          places: [{ coordinates: open.coordinates }],
          showDrawingManager: false,
          geoFenceInfo: open.geoFenceInfo,
          handleClickedShape: handleClickedShape,
          showNotesOption: false,
          allowEditing: false,
        }}
      />
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LoadingDots style={{ fontSize: "50px" }} />
        </div>
      )}

      {clickedShape && !loading && (
        <NotesComponent
          {...{
            hasWriteAccess: true,
            notes: currNotes,
            setRowNotes: setCurrNotes,
            noteModalName: clickedShape?.title,
            rowId: clickedShape?.shapeId,
            topicCategory: "Geofence",
            customLinkTo,
            isFromSettings: true,
          }}
        />
      )}
    </Drawer>
  );
};

export default MultiplyNotesDrawer;
