import { RobotOutlined } from "@ant-design/icons";
import { ClaimsIcon, IncidentsIcon, SafetyIcon } from "../../../../../assets";
import {
  ClientsWhite,
  ContactsWhite,
  EstimationsWhite,
  LeadsWhite,
  OpportunitiesWhite,
  ProjectsWhite,
  FleetWhite,
  PermitDrawingsWhite,
  ApprovalsWhite,
  SchedulingWhite,
  InspectionNotificationIcon,
  SubcontractorsWhite,
  NotesIcon,
  InspectionsWhite,
  ReportsBlank,
  VideoTutorialIcon,
} from "../../../../../icons";
import {
  NotificationIcon,
  TaskIcon,
  DobIcon,
  NewUserIcon,
  PageStatusesIcon,
  UserAccessIcon,
  AccountingIcon,
  AllUsersIcon,
  RolesAccessIcon,
  TeamsIcon,
  UndoRedoIcon,
  FoldersIcon,
  Mention,
} from "../../../../../icons/NotificationIcons";
import { CommunicationIcon } from "../../../../SidebarPages/Communication/assets";
import { EmailIcon } from "../../Approvals/assets/index";
import { AutomationIconFilled } from "../../../../OnBoarding/assets/icons";

const Icons = {
  default: NotificationIcon,

  Contacts: ContactsWhite,
  Clients: ClientsWhite,
  Estimations: EstimationsWhite,
  Leads: LeadsWhite,
  Opportunities: OpportunitiesWhite,
  Projects: ProjectsWhite,
  Tasks: TaskIcon,
  "Task Manager": TaskIcon,
  Mention: Mention,
  Fleets: FleetWhite,
  Documentation: PermitDrawingsWhite,
  Request: ApprovalsWhite,
  Scheduling: SchedulingWhite,
  Applications: SchedulingWhite,
  Inspections: InspectionNotificationIcon,
  Reminder: TaskIcon,
  Crews: SubcontractorsWhite,
  Claims: ClaimsIcon,
  Hearings: ClaimsIcon,
  Safety: SafetyIcon,
  Incidents: IncidentsIcon,
  "Safety Incidents": IncidentsIcon,
  "Safety Inspections": SafetyIcon,
  Automation: AutomationIconFilled,
  "Automation Configuration": RobotOutlined,
  "Accounting Templates": AccountingIcon,
  "Accounting Statuses": AccountingIcon,
  "Recieved Payments": AccountingIcon,
  Invoices: AccountingIcon,
  Charges: AccountingIcon,
  "Maintenance & Inspection": FleetWhite,
  Reports: ReportsBlank,
  "Reports Categorization": ReportsBlank,
  "To Do": TaskIcon,

  "Email Templates": CommunicationIcon,
  "User Access": UserAccessIcon,
  Statuses: PageStatusesIcon,
  Pricing: EstimationsWhite,
  "New User": NewUserIcon,
  "DOB Inspections": DobIcon,
  "Crew Positions": SubcontractorsWhite,
  "Other Scopes": EstimationsWhite,
  Breakdowns: EstimationsWhite,
  "Includes & Excludes": EstimationsWhite,
  "Approvals Dynamic Forms": ApprovalsWhite,
  Services: EstimationsWhite,
  "Rental Payment Terms": AccountingIcon,
  "Crew Statuses": SubcontractorsWhite,
  "Inspection Statuses": InspectionsWhite,
  "All Users": AllUsersIcon,
  "Roles Access": RolesAccessIcon,
  "Crew Time Reasons": SubcontractorsWhite,
  Tutorials: VideoTutorialIcon,
  Teams: TeamsIcon,
  "Documentation Configuration": PermitDrawingsWhite,
  "Undo Redo Limit": UndoRedoIcon,
  "Email Settings": CommunicationIcon,
  "Device Management": CommunicationIcon,
  // "Device Management": DeviceIcon,
  "Documentation Mapping": PermitDrawingsWhite,
  "Documentation Types": PermitDrawingsWhite,
  "Documentation Extra Folders": PermitDrawingsWhite,
  "Inspection Types": InspectionNotificationIcon,
  "Scheduling Types": SchedulingWhite,
  "Scheduling Inspections": SchedulingWhite,
  "Net Terms Of Invoice": AccountingIcon,
  "Tasks Keywords": TaskIcon,
  "Tasks Labels": TaskIcon,
  Folders: FoldersIcon,
  Subtopic: TaskIcon,
  Accounts: ClientsWhite,
  Formulas: EstimationsWhite,
  "Fleet Statuses": FleetWhite,
  "Take Off": EstimationsWhite,
  Communication: EmailIcon,
};

export default Icons;
