import React, { useEffect, useState } from "react";
import { MondayButton } from "../../../../../../../../../../../../../../../commonComponents";
import {
  CheckIconModal,
  CloseIconModal,
  DragAndDropIcon,
} from "../../../../../../../../../../../../../../../../assets";
import DraggableSort from "../../../../../../../../../../../../../../Estimations/DataEntryGrid/subcomponents/Header/WordFactory/DraggableSort/DraggableSort";
import { NormalSizedModal } from "../../../../../../../../../../../../../../Fleet/components";
import { useStateContext } from "../../../../../../../../../../Context/Context";
import { forceToNumber } from "../../../../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { useDispatch } from "react-redux";
import { updateScheduleOfValue } from "../../../../../../../../../../../../../../../../actions/scheduleOfValues";

function DragAndEvents({
  currentService,
  showDraggable,
  itemsToOrder,
  setItemsToOrder,
  onClose,
}) {
  const [visible, setVisible] = useState(showDraggable);
  const { fakeSOV = {} } = useStateContext();
  const dispatch = useDispatch();

  const onSave = () => {
    console.log("itemsToOrder", itemsToOrder);
    let totalAmount = itemsToOrder?.reduce((acc, el) => {
      return acc + forceToNumber(el?.amount);
    }, 0);
    let NEW_EVENTS = itemsToOrder?.map((el) => {
      totalAmount -= forceToNumber(el?.amount);
      let objToReturn = {
        ...el,
        difference: forceToNumber(totalAmount),
      };
      delete objToReturn?.label;
      return objToReturn;
    });
    let NEW_FAKE_SOV = structuredClone(fakeSOV);
    NEW_FAKE_SOV?.services?.forEach((service) => {
      if (service?.serviceId === currentService?.serviceId) {
        service.amounts = NEW_EVENTS;
      }
    });
    dispatch(updateScheduleOfValue(fakeSOV?.scheduleId, NEW_FAKE_SOV));
    onClose();
  };

  return (
    <NormalSizedModal
      title="Sort Events according to your preferences..."
      {...{
        visible: visible,
        setVisible,
        closable: true,
        onCancel: () => setVisible(false),
        maskClosable: true,
        // closeIcon: <CloseIconModal />,
        centered: true,
      }}
    >
      <div
        style={{
          padding: "-10px 0px 0px 20px",
          minWidth: "500px",
        }}
      >
        <DraggableSort {...{ itemsToOrder, setItemsToOrder }} />{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "30px",
            paddingBottom: "20px",
          }}
        >
          <MondayButton
            className="mondayButtonRed"
            Icon={<CloseIconModal />}
            onClick={() => setVisible(false)}
          >
            Close
          </MondayButton>{" "}
          <MondayButton onClick={onSave} Icon={<CheckIconModal />}>
            Save Changes
          </MondayButton>
        </div>
      </div>
    </NormalSizedModal>
  );
}

export default DragAndEvents;
