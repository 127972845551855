import { message } from "antd";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";

import {
  validateCellChangeAndSetCharge,
  validateHoistCellChangeAndSetCharge,
  validateCellChangeAndSetChargeRentalState,
  validateCellChangeAndSetChargeHoistRentalState,
  validateCellChangeAndSetChargeHoistSOVState,
  validateCellChangeAndSetChargeSOVState,
} from "./utils/validateCellChangeAndSetCharge";
import { useRedux } from "../../../../../../../hooks/useRedux";
import { columnDefsSetter } from "../../utils/columnDefsSetter";
import AgGridMathful from "../../../../../Applications/ApplicationView/components/ServiceList/Service/ServiceContent/AgGridSum/AgGridMathful";
import { showErrorMsg } from "../../../../../../../../../utils";

const ChargeAgGrid = ({
  charge,
  rowData,
  chargeToSave,
  objectData,
  currentCharge,
  initialService,
  selectedService = "",
  selectedElevation = "",
  selectedElevationId = "",
  setChargeToSave,
  type = "services",
}) => {
  const [darkMode] = useRedux("chargesDarkMode");
  const [isWritable] = useRedux("chargesIsWritable");
  const [selected, setSelected] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [credits, setCredits] = useState();
  const [columnDefs, setColumnDefs] = useState();

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState();
  const { programFields } = useSelector((state) => state.programFields);
  const [chargeItemsModal, setChargeItemsModal] = useRedux("chargeItemsModal");
  const { categoryFrom } = chargeItemsModal;

  const onGridReady = (params) => {
    setGridApi(params);
  };

  const onCellEditingStarted = (params) => {
    if (!isWritable && currentCharge) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  const onCellEditingStopped = (params) => {
    const colDef = params.colDef.field;
    const isPricePercent = params.colDef.field === "pricePercent";
    const value = Number(params.newValue);

    if (value < 0) {
      showErrorMsg({
        content:
          "Please provide a valid percentage value. Negative percentages are not allowed.",
      });
      params.node.setDataValue(colDef, params.oldValue);

      return;
    }

    params.node.setDataValue(colDef, value);

    const isEstimation = categoryFrom === "Estimation";
    const isRental = categoryFrom === "Rental";
    const isScheduleOfValue = categoryFrom === "Schedule Of Value";
    const isHoist = charge?.label === "Hoist" || charge?.isHoist;

    if (isEstimation)
      if (isHoist) {
        validateHoistCellChangeAndSetCharge(
          charge,
          initialService,
          isPricePercent,
          setChargeToSave,
          type
        );
      } else {
        validateCellChangeAndSetCharge(
          charge,
          initialService,
          isPricePercent,
          setChargeToSave,
          type
        );
      }
    else if (isRental) {
      if (isHoist)
        validateCellChangeAndSetChargeHoistRentalState(
          charge,
          initialService,
          isPricePercent,
          setChargeToSave,
          type
        );
      else
        validateCellChangeAndSetChargeRentalState(
          charge,
          initialService,
          isPricePercent,
          setChargeToSave,
          type
        );
    } else if (isScheduleOfValue) {
      if (isHoist)
        validateCellChangeAndSetChargeHoistSOVState(
          charge,
          initialService,
          isPricePercent,
          setChargeToSave,
          type
        );
      else
        validateCellChangeAndSetChargeSOVState(
          charge,
          initialService,
          isPricePercent,
          setChargeToSave,
          type
        );
    }
  };

  useEffect(() => {
    if (programFields && gridRef?.current?.api) {
      const gridApi = gridRef?.current?.api;

      columnDefsSetter({
        setColumnDefs,
        programFields,
        categoryFrom,
        gridApi,
        setDetailsVisible,
        setCredits,
        chargeItemsModal,
        objectData,
        currentCharge,
        setChargeToSave,
        chargeToSave,
        selectedService,
        selectedElevation,
        selectedElevationId,
        type,
        charge,
        initialService,
      });
    }
  }, [programFields, gridApi]);

  return (
    <div
      className={`commonChargeAgGrid applicationAgGridContainer`}
      data-testid="charge-ag-grid"
    >
      {selected && (
        <div style={{ marginRight: 15 }}>
          <AgGridMathful darkMode={darkMode} event={selected} />
        </div>
      )}
      <AgGridReact
        ref={gridRef}
        rowData={rowData || []}
        columnDefs={columnDefs}
        onGridReady={(el) => {
          onGridReady(el);
        }}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onSelectionChanged={() => onSelectionChanged(selectedService, type)}
      />
    </div>
  );
};

export default ChargeAgGrid;
