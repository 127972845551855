import Swal from "sweetalert2";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import {
  fetchImageSrc,
  pdfHelper,
} from "../../../../../Header/forms/RequestFormModal/components/RequestFooter/fetchImageSrc";
import { getBase64 } from "../../../../Incidents/Modals/utils/getBase64Pdf";
import axios from "axios";
import { devUrl, prodUrl } from "../../../../../../utils/mergePdfDocsUrl";
import { b64toBlob } from "../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";

export const generatePdf = async ({
  companyLogo,
  title,
  data,
  images = [],
  driveRequest,
}) => {
  Swal.fire({
    title: "<strong>Generating PDF</strong>",
    icon: "info",
    html: "Please wait to generate PDF...!",
    didOpen: () => {
      Swal.showLoading();
    },
  });

  const getImages = await fetchImageSrc({
    googleDriveUpload: images || [],
    driveRequest: driveRequest,
  });

  const docDefinition = content({ data, images: getImages });

  const createDocDef = documentationDocDefinition(
    title,
    docDefinition,
    companyLogo
  );

  const pdfDocGenerator = pdfMake.createPdf(createDocDef);
  let fileList = [];
  const existedObject = await getBase64({
    pdfDocGenerator,
    title: title,
    name: "test",
  });

  fileList.push(existedObject);

  await Promise.allSettled(
    images?.map(async (element) => {
      let pdfCondition = element.mimeType?.includes("application/pdf");
      let blob;
      let size;
      let file;

      if (pdfCondition) {
        await driveRequest
          .getImageSrc(element?.id)
          .then((r) => r)
          .then((res) => (blob = res?.src));

        const fileSrc = new Promise((resolve, reject) => {
          resolve(blob);
        });

        file = {
          fileSrc: fileSrc,
          name: element?.name,
          originFileObj: {
            uid: element?.id,
          },
          percent: 0,
          size: size,
          status: "uploading",
          type: "local",
          uid: element?.id,
        };

        fileList.push(file);
      }
    })
  );

  await axios
    .post(process.env.NODE_ENV === "production" ? prodUrl : devUrl, {
      originalPdf: [
        ...(await Promise.all(fileList?.map((el) => el?.fileSrc))),
      ]?.filter((el) => typeof el === "string"),
      configuration: {
        fileList: fileList,
        pagesConfig: {},
      },
    })
    .then(async ({ data: mergedPdf }) => {
      Swal.close();

      var blob = b64toBlob(mergedPdf, "application/pdf");
      const blobURL = URL.createObjectURL(blob);

      window.open(blobURL);
    })
    .catch((e) => {
      Swal.fire({
        title: "<strong>Error</strong>",
        icon: "error",
        html: "There was an error while generating PDFs! ",
      });
    });
};

const content = ({ data, images }) => {
  const contentBody = {
    table: {
      widths: ["*"],
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: data.map((item) => {
                return [
                  {
                    text: [
                      {
                        text: `${item?.label} :  `,
                        bold: true,
                      },
                      {
                        text:
                          item?.type !== "datepicker"
                            ? item?.value
                            : dayjsNY(item?.value).format("MM/DD/YYYY"),
                        italics: true,
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                ];
              }),
            },
            margin: [10, 10, 10, 10],
            layout: { hLineWidth: () => 0, vLineWidth: () => 0, fillColor },
          },
        ],
      ],
    },
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
    margin: [0, 60, 0, 30],
  };

  return [
    contentBody,
    pdfHelper({
      title: "Images",
      body: images,
    }),
  ];
};

export function documentationDocDefinition(title, docDefinition, companyLogo) {
  return {
    pageMargins: [20, 20, 20, 20],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: `${title ? title : ""}`,
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
            style: ["strong", "large"],
          },
          {
            text: `Date : ${dayjsNY().format("MM/DD/YYYY")}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],

        style: "subheader",
      },

      ...docDefinition?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo,
    },

    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      paragraphWithBorder: {
        border: "1px solid black",
        padding: 10,
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
    },
    background: [
      {
        canvas: [
          {
            type: "line",
            x1: 20,
            y1: 90,
            x2: 575,
            y2: 90,
            lineWidth: 1,
            lineColor: "#D2D2D2",
          },
        ],
      },
    ],
  };
}

const fillColor = (rowIndex) => {
  return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
};
