import { useState } from "react";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { NormalSizedModal } from "../../../../../../../../../../Fleet/components";
import { Input, InputNumber } from "antd";
import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import Swal from "sweetalert2";
import { formatCurrency } from "../../../../../../../../../../utils";
import { customBalancePriceOfSOVHandler } from "../../../../../Header/Components/ControlPanel/controlPanelFunctions";
import { changeRetainageHandler } from "../RetainageModal/retainageModalFunctions";
import { checkIfServiceIsHoist } from "../../../../../../../../../../Estimations/DataEntryGrid/models/Service";
import { handleChangeRetainage } from "../../../../HoistService/ServiceHeader/Components/RetainageModal/retainageModalFunctions";
import { calculateDifference } from "../../../ServiceContent/GridData/utils";
import { calculateDifference as hoistCalculateDifference } from "../../../../HoistService/ServiceContent/GridData/utils";
import { SaveIcon } from "../../../../../../../../../../BasePage/src";
import {
  CheckIconModal,
  CloseIconModal,
} from "../../../../../../../../../../../../assets";
import "./ChangeSOVAmount.scss";
function ChangeSOVAmount({ service = {}, elevation = {}, isHoist = false }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    totalPrice: 0,
    legacyPrice: 0,
    balance: 0,
  });
  const {
    proposedTypeOfWork,
    isWritable,
    fakeSOV,
    errors,
    darkMode,
    retracted,
  } = useStateContext();
  const handleOpen = () => {
    setIsModalVisible(true);
    console.log("eelevation", elevation);
    setDefaultValues({
      totalPrice: forceToNumber(
        !isHoist ? service?.totalPrice ?? 0 : elevation?.totalPrice ?? 0
      ),
      legacyPrice: forceToNumber(
        !isHoist
          ? service?.legacyPrice ?? service?.totalPrice
          : elevation?.legacyPrice ?? elevation?.totalPrice
      ),
      balance: forceToNumber(service?.balance ?? 0),
    }); // Reset default values
  };
  const dispatch = useDispatchContext();

  const onChangeInput = (e, key) => {
    console.log("onChangeInput", e, key);
    if (!isHoist) {
      setDefaultValues((prev) => ({
        ...prev,
        totalPrice: forceToNumber(e),
        balance: forceToNumber(e) - prev?.legacyPrice,
      }));
    } else {
      setDefaultValues((prev) => ({
        ...prev,
        totalPrice: forceToNumber(e),
        balance: forceToNumber(e) - prev?.legacyPrice,
      }));
    }
  };

  const changeSOVPrice = () => {
    console.log("changeSOVPrice", fakeSOV);
    let tempFakeSOV = { ...fakeSOV };
    tempFakeSOV?.services?.forEach((item) => {
      if (item?.serviceId?.toString() === service?.serviceId?.toString()) {
        if (!isHoist) {
          item.totalPrice = defaultValues?.totalPrice;
          item.legacyPrice = defaultValues?.legacyPrice;
          item.balance = defaultValues?.balance;
          let tempAmounts = item?.amounts?.map((amountItem) => {
            return {
              ...amountItem,
              amount:
                (forceToNumber(defaultValues?.totalPrice) *
                  forceToNumber(amountItem?.rate)) /
                100,
            };
          });
          item.amounts = calculateDifference(item, tempAmounts);
        } else {
          item?.serviceOptions?.[0]?.forEach((option) => {
            if (option.elevationId === elevation?.elevationId) {
              option.totalPrice = defaultValues?.totalPrice;
              option.legacyPrice = defaultValues?.legacyPrice;
              option.balance = defaultValues?.balance;
              let tempElevationAmounts = option?.amounts?.map((amountItem) => {
                return {
                  ...amountItem,
                  amount:
                    (forceToNumber(defaultValues?.totalPrice) *
                      forceToNumber(amountItem?.rate)) /
                    100,
                };
              });
              option.amounts = hoistCalculateDifference(
                service,
                option,
                tempElevationAmounts
              );
            }
          });
        }
      }
    });

    console.log("changeSOVPrice", tempFakeSOV);
    dispatch({ type: "SET_FAKE_SOV", payload: tempFakeSOV });
    Swal.fire("Success!", "SOV Price Changed", "success").then(() => {
      let balance = customBalancePriceOfSOVHandler({ fakeSOV })?.balanceAmount;
      console.log("curr balance", customBalancePriceOfSOVHandler({ fakeSOV }));
      Swal.fire(
        "INFO!",
        `CURR BALANCE IS: ${formatCurrency(
          balance
        )}! Also, be careful all amounts are now changed accordingly including charge amounts, retainage and tax!`,
        "info"
      ).then(() => {
        setIsModalVisible(false);
        cleanUpAfterChange({
          sovFake: tempFakeSOV,
          tempService: tempFakeSOV?.services?.find(
            (el) => el?.serviceId?.toFixed(2) === service?.serviceId?.toFixed(2)
          ),
        });
      });
    });
    setIsModalVisible(false);
  };

  const formatterAndParser = {
    formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
    controls: false,
    min: 0,
  };

  const cleanUpAfterChange = ({ sovFake, tempService }) => {
    console.log("tempService", tempService);
    let retainage = forceToNumber(tempService?.retainage);
    return checkIfServiceIsHoist(tempService)
      ? handleChangeRetainage({
          closeRetainageModal: () => {},
          dispatchContext: dispatch,
          retainageState: retainage,
          serviceId: tempService?.serviceId,
          fakeSOV: sovFake,
        })
      : changeRetainageHandler({
          setIsRetainageModalVisible: () => {},
          dispatchContext: dispatch,
          retainageState: retainage,
          fakeSOV: sovFake,
          service: tempService,
        });
  };

  return (
    <div>
      {/* <MondayButton
        className="mondayButtonRed"
        onClick={handleOpen}
        hasIcon={false}
      >
        Change SOV PRICE
      </MondayButton> */}
      <span
        onClick={handleOpen}
        style={{
          color: "#fff",
          cursor: "pointer",
        }}
      >
        change sov price
      </span>
      <NormalSizedModal
        title="Change SOV Price"
        darkMode={darkMode}
        open={isModalVisible}
        closable={true}
        className="changeSovModal"
        onCancel={() => setIsModalVisible(false)}
        footerModal={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "10px",
              width: "100%",
            }}
          >
            {" "}
            <MondayButton
              onClick={() => setIsModalVisible(false)}
              className="mondayButtonRed"
              Icon={<CloseIconModal />}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={changeSOVPrice}
              className="mondayButtonGreen"
              Icon={<CheckIconModal />}
            >
              Apply Custom
            </MondayButton>
          </div>
        }
      >
        <div
          className="changeSovModalBody"
          style={{
            display: "flex",
            width: "500px",
            height: "100px",
            flexDirection: "row",
            // justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            className="sovHeaderData"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <InputNumber
              value={defaultValues?.totalPrice}
              defaultValue={defaultValues?.totalPrice}
              addonBefore="$"
              // formatterAndParser={formatterAndParser}
              style={{ width: "450px" }}
              type="number"
              onChange={(e) => {
                onChangeInput(e, "totalPrice");
              }}
            />{" "}
          </div>
        </div>
      </NormalSizedModal>
    </div>
  );
}

export default ChangeSOVAmount;
