export const broadcastArrayToString = (arr) => {
  if (arr.length === 0) {
    return "";
  }

  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`;
  }

  const lastItem = arr.pop();
  const formattedString = arr.join(", ");
  return `${formattedString} and ${lastItem}`;
};
