import importedDayjs from "dayjs";
import moment from "moment";
import { getNYDate } from "../../ApplicationView/utils/dateFunctions";

const timezone = require("dayjs/plugin/timezone");
importedDayjs.extend(timezone);
const dayjs = importedDayjs.tz;

//this function disables all dates after last application
export const disabledStartDateHandler = ({
  current,
  selectedMonth,
  lastPeriod,
}) => {
  let disableValue;
  // console.log("current", { current, selectedMonth, lastPeriod });

  // if (!!selectedMonth?.start ) {
  // 	disableValue =
  // 		current < moment(selectedMonth?.start)?.valueOf() ||
  // 		current > moment(selectedMonth?.end)?.valueOf();
  // } else {
  !!lastPeriod
    ? (disableValue = current < moment(lastPeriod)?.add(1, "day")?.valueOf())
    : (disableValue = false);
  // }
  return disableValue;
};

export const periodDatesPickerHandler = ({
  setSelectedMonth,
  setDueDate,
  lastPeriod,
  custom,
  date,
}) => {
  if (!!date) {
    if (!!custom) {
      console.log("changing Date CUSTOM", {
        date: getNYDate(date[0]).formatted,
        lastPeriod: getNYDate(lastPeriod).formatted,
        custom,
        error: !!lastPeriod
          ? dayjs(lastPeriod)?.valueOf() <=
            dayjs(date)?.startOf("month").subtract(1, "day")?.valueOf()
            ? true
            : false
          : false,
      });
      setSelectedMonth({
        start: dayjs(date[0])?.valueOf(),
        end: dayjs(date[1])?.valueOf(),
        error: !!lastPeriod
          ? dayjs(lastPeriod)?.valueOf() <=
            dayjs(date[0])?.startOf("month").subtract(1, "day")?.valueOf()
            ? true
            : false
          : false,
      });
    } else {
      console.log("changing Date else", {
        date: getNYDate(date).formatted,
        lastPeriod: getNYDate(lastPeriod).formatted,
        custom,
        error: !!lastPeriod
          ? dayjs(lastPeriod)?.valueOf() <=
            dayjs(date)?.startOf("month").subtract(1, "day")?.valueOf()
            ? true
            : false
          : false,
      });
      setSelectedMonth({
        start:
          lastPeriod > dayjs(date)?.startOf("month")?.valueOf()
            ? lastPeriod
            : dayjs(date)?.startOf("month")?.valueOf(),
        end: dayjs(date)?.endOf("month").subtract(1, "hour")?.valueOf(),
        error: !!lastPeriod
          ? dayjs(lastPeriod)?.valueOf() <=
            dayjs(date)?.startOf("month").subtract(1, "day")?.valueOf()
            ? true
            : false
          : false,
      });
      setDueDate(dayjs(date)?.endOf("month").subtract(1, "hour")?.valueOf());
    }
  } else {
    setSelectedMonth(null);
  }
};
