import StyledButton from "../../components/StyleButton/StyledButton";
import _ from "lodash";
import "./LongText.scss";
import FormItem from "../../components/FormItems/FormItem";
import { useEffect, useState } from "react";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";

const Description = (prop) => {
  const {
    params: {
      content: { formItemName, value },
    },
    form,
    getEditedValue,
    setEditMode,
    getFormChanged = () => {},
    setFormChanged = () => {},
  } = prop;
  const values = { [formItemName]: value };
  //saved previus data
  const [prev, setPrev] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  useEffect(() => {
    setPrev(() => _.cloneDeep(prop));
  }, [prop]);
  ///  finished

  const onTextChanged = () => {
    setFormChanged(true);
  };

  return (
    <div className="LongTextBodyEdit">
      <div className="LongTextBodyElementContainer">
        <FormItem.RichText
          {...{
            value,
            form,
            name: formItemName,
            onChange: onTextChanged,
          }}
        />
      </div>
      <div className="confirmContainer">
        <MondayButton
          onClick={(res) => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          onClick={(res) => {
            !_.isEqual(values, form.getFieldsValue()) &&
              getEditedValue(
                form.getFieldsValue(),
                undefined,
                values,
                prev,
                keys(form.getFieldsValue()).map((e) => {
                  prop.params.content.value = form.getFieldsValue()[e];
                  return prop;
                })[0]
              );
            setEditMode(false);
          }}
          className="confirmButton"
          Icon={<Tick width={20} height={20} />}
        >
          Save
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default Description;
