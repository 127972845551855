import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Image, Card, Dropdown } from "antd";
// import { ItemType } from "antd/es/menu/hooks/useItems";
import { EllipsisOutlined } from "@ant-design/icons";

import { StoreType } from "../../types";

import "./SignaturesCard.scss";
import { ItemType } from "antd/lib/menu/interface";

type SignatureType = {
  label: string;
  signature: string | null;
};

type SignaturesCardPropsType = {
  title?: ReactNode;
  className?: string;
  headerActions?: ItemType[];
  signatures: SignatureType[];
  noSignaturesDescription?: string;
};

function SignaturesCard(props: SignaturesCardPropsType) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const {
    signatures,
    className = "",
    headerActions = [],
    title = "Signatures",
    noSignaturesDescription = "No signature was found",
  } = props;

  return (
    <div
      className={`inspection-grid-card inspection-signatures-container ${
        isDarkMode ? "grid-card-dark" : ""
      } ${className ?? ""}`}
    >
      {title ? (
        <div className="card-title">
          <div className="card-title-text">{title}</div>
          {!!headerActions?.length && (
            <Dropdown
              trigger={["click", "hover"]}
              placement="bottomRight"
              overlayClassName={`header-action-dropdown ${
                isDarkMode ? "dropdown-dark" : ""
              }`}
              menu={{
                items: headerActions,
              }}
            >
              <EllipsisOutlined
                style={{ transform: "rotate(90deg)", cursor: "pointer" }}
              />
            </Dropdown>
          )}
        </div>
      ) : null}
      <div className={`card-body ${title ? "with-title" : "no-title"}`}>
        {signatures.length ? (
          signatures.map((signature, index) => {
            return (
              <Card.Meta
                {...{
                  title: signature.label,
                  avatar: null,
                  key: `signature-${index}`,
                  description: signature.signature ? (
                    <Image
                      src={signature.signature}
                      className="signature-image"
                    />
                  ) : (
                    <div className="signature-image no-signature">
                      {noSignaturesDescription}
                    </div>
                  ),
                }}
              />
            );
          })
        ) : (
          <div className="no-files-found">{noSignaturesDescription}</div>
        )}
      </div>
    </div>
  );
}

export default SignaturesCard;
