import _ from "lodash";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { pointsInsideAPolygon } from "../components/Map/utilities/pointsInsideAPolygon";
import { FleetActivityType } from "../../../FleetMaintenanceView/types";

/**
 * @typedef ChangeLog
 * @property {string} field
 * @property {string} value
 * @property {boolean} discarded
 * @property {number[]} eventRange
 */

/**
 * @typedef IdleData
 * @property {number} date
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string|undefined} driverName
 * @property {string|undefined} driverId
 * @property {number} duration
 * @property {number} beginDate
 * @property {number} endDate
 * @property {string} address
 * @property {string|undefined} fromLocation
 * @property {string|undefined} toLocation
 * @property {string|undefined} notes
 * @property {string|undefined} reason
 * @property {string|undefined} jobsiteMatch
 */

/**
 * @typedef TimelineInfo
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string|undefined} fromLocation
 * @property {string|undefined} toLocation
 * @property {number} start
 * @property {number} end
 * @property {string|undefined} driverName
 * @property {string|undefined} driverId
 */

/**
 * @returns {IdleData[]}
 */
function matchIdleData({
  allStops,
  geofences,
  locations,
  globalAudits,
  selectedDate,
  plannedTripsForDate,
  plannedTripsForTruck,
}) {
  /** @type {IdleData[]} */
  const data = [];

  /** @type {Record<string, TimelineInfo[]>} */
  const timelinesInfo = {};

  /** @type {Record<string, string>} */
  const fencesById = {};
  for (const fenceName in geofences) {
    fencesById[geofences[fenceName]["geofenceUUID"]] = fenceName;
  }

  const dateStart = dayjsNY(selectedDate).valueOf();
  const dateEnd = dayjsNY(selectedDate).endOf("day").valueOf();

  /** @type {Record<string, FleetActivityType[]>} */
  let vehicleTrips = {};
  if (dateStart === dayjsNY().startOf("day").valueOf()) {
    vehicleTrips = plannedTripsForTruck;
  } else {
    vehicleTrips = plannedTripsForDate;
  }

  /**
   * We collect some basic information on the vehicle trips
   * that make filtering and finding information later easier
   */
  for (const fleetId in vehicleTrips) {
    const activities = [...vehicleTrips[fleetId]];
    for (let i = 0; i < activities.length; i++) {
      const route = activities[i];

      const start = route?.actualDepart ?? route.startingTime;
      const end =
        i === activities.length - 1
          ? route?.actualArrive ?? route.timeAtLocation
          : activities[i + 1]?.actualDepart ?? activities[i + 1]?.startingTime;

      if (!start || !end) {
        continue;
      }

      if (!timelinesInfo[route.fleetId]) {
        timelinesInfo[route.fleetId] = [];
      }

      timelinesInfo[route.fleetId] = [
        ...timelinesInfo[route.fleetId],
        {
          start: dayjsNY(start).valueOf(),
          end: dayjsNY(end).valueOf(),
          fleetId: route.fleetId,
          fleetName: route.fleetName,
          driverId: route.driverId,
          driverName: route.driverName,
          fromLocation: route.pickUpLocation,
          toLocation: route.dropOffLocation,
        },
      ];
    }
  }

  for (const fleetId in timelinesInfo) {
    const stopsForVehicle = allStops?.[fleetId];

    if (!stopsForVehicle || !stopsForVehicle?.length) {
      continue;
    }

    const idleViewChanges =
      globalAudits?.["alertChanges"]?.[fleetId]?.["idleViewChanges"] || [];

    const stopReasons =
      globalAudits?.["alertChanges"]?.[fleetId]?.["stopReasons"] || [];

    const timelines = timelinesInfo[fleetId];
    timelines.sort((a, b) => a.start - b.start);

    for (const stop of stopsForVehicle) {
      // Taking in consideration previous and next date routes
      if (
        stop?.endDate < Math.min(dateStart, timelines[0]["start"]) ||
        stop?.beginDate >
          Math.max(dateEnd, timelines[timelines.length - 1]["end"])
      ) {
        continue;
      }

      // Filter all the stops with "Engine Off"
      if ((stop?.stopType || "").toLowerCase().includes("off")) {
        continue;
      }

      /** @type {ChangeLog[]} */
      const manualChanges = idleViewChanges.filter(
        ({ eventRange }) =>
          eventRange?.[0] === stop.beginDate &&
          eventRange?.[1] === stop?.endDate
      );

      if (manualChanges.find(({ discarded }) => discarded)) {
        continue;
      }

      const notes =
        manualChanges.find(({ field }) => field === "notes")?.value || "";

      const reason =
        stopReasons.find(
          ({ beginDate, endDate }) =>
            beginDate === stop.beginDate && endDate === stop.endDate
        )?.reason || "";

      const stopTimeline = timelines.find(
        ({ start, end }) =>
          (stop?.beginDate >= start && stop?.beginDate <= end) ||
          (stop?.endDate >= start && stop?.endDate <= end)
      );

      if (stopTimeline) {
        data.push({
          address: stop.address,
          beginDate: stop?.beginDate,
          date: stop?.beginDate,
          driverId: stopTimeline.driverId,
          driverName: stopTimeline.driverName,
          duration: stop?.duration,
          endDate: stop?.endDate,
          fleetId,
          fleetName: stopTimeline?.fleetName,
          fromLocation: stopTimeline?.fromLocation,
          toLocation: stopTimeline?.toLocation,
          jobsiteMatch: stopTimeline?.toLocation,
          notes,
          reason,
        });
      } else {
        const fenceId = stop?.["geofenceUUID"];
        let jobsiteMatch = geofences?.[fencesById?.[fenceId]]?.name;
        if (!jobsiteMatch) {
          const stopCoords = [stop?.position?.lat, stop?.position?.lng];

          jobsiteMatch = Object.values(geofences)?.find(
            ({ fenceGroup, points }) =>
              fenceGroup?.includes("Zone")
                ? false
                : pointsInsideAPolygon(
                    stopCoords,
                    points.map(({ latitude, longitude }) => [
                      latitude,
                      longitude,
                    ])
                  )
          )?.name;
        }

        data.push({
          address: stop.address,
          beginDate: stop?.beginDate,
          date: stop?.beginDate,
          driverId: "",
          driverName: "",
          duration: stop?.duration,
          endDate: stop?.endDate,
          fleetId,
          fleetName: timelinesInfo[fleetId][0]["fleetName"],
          fromLocation: "",
          toLocation: "",
          notes,
          reason,
          jobsiteMatch,
        });
      }
    }
  }

  for (const fleetId in allStops) {
    if (fleetId in timelinesInfo) {
      continue;
    }

    const vehicle = locations?.find(({ fleetId: id }) => id === fleetId);
    if (!vehicle) {
      continue;
    }

    const stopsForVehicle = allStops[fleetId];
    if (!stopsForVehicle || !stopsForVehicle?.length) {
      continue;
    }

    const idleViewChanges =
      globalAudits?.["alertChanges"]?.[fleetId]?.["idleViewChanges"] || [];

    const stopReasons =
      globalAudits?.["alertChanges"]?.[fleetId]?.["stopReasons"] || [];

    for (const stop of stopsForVehicle) {
      if (stop?.endDate < dateStart || stop?.beginDate > dateEnd) {
        continue;
      }

      if ((stop?.stopType || "").toLowerCase().includes("off")) {
        continue;
      }

      /** @type {ChangeLog[]} */
      const manualChanges = idleViewChanges.filter(
        ({ eventRange }) =>
          eventRange?.[0] === stop.beginDate &&
          eventRange?.[1] === stop?.endDate
      );

      if (manualChanges.find(({ discarded }) => discarded)) {
        continue;
      }

      const notes =
        manualChanges.find(({ field }) => field === "notes")?.value || "";

      const reason =
        stopReasons.find(
          ({ beginDate, endDate }) =>
            beginDate === stop.beginDate && endDate === stop.endDate
        )?.reason || "";

      const fenceId = stop?.["geofenceUUID"];
      let jobsiteMatch = geofences?.[fencesById?.[fenceId]]?.name;
      if (!jobsiteMatch) {
        const stopCoords = [stop?.position?.lat, stop?.position?.lng];

        jobsiteMatch = Object.values(geofences)?.find(
          ({ fenceGroup, points }) =>
            fenceGroup?.includes("Zone")
              ? false
              : pointsInsideAPolygon(
                  stopCoords,
                  points.map(({ latitude, longitude }) => [latitude, longitude])
                )
        )?.name;
      }

      data.push({
        address: stop.address,
        beginDate: stop?.beginDate,
        date: stop?.beginDate,
        driverId: "",
        driverName: "",
        duration: stop?.duration,
        endDate: stop?.endDate,
        fleetId,
        fleetName: vehicle?.fleetName,
        fromLocation: "",
        toLocation: "",
        notes,
        reason,
        jobsiteMatch,
      });
    }
  }

  data.sort((a, b) => {
    return (
      a.fleetName
        .toLocaleLowerCase()
        .localeCompare(b.fleetName.toLocaleLowerCase()) ||
      a.beginDate - b.beginDate
    );
  });

  return data;
}

export default matchIdleData;
