import { API } from "aws-amplify";
import { EntryType } from "../payrollLiveTypes";

type PropType = {
  id?: string;
  body?: Partial<EntryType> | Array<EntryType> | Array<string>;
  method: "post" | "put" | "delete" | "get";
  queryStringParameters?: {
    ExclusiveStartKey?: string;
    withPagination?: string;
    getMaxLimit?: string;
    filters?: string;
  };
};

const TABLE = "employeeReports";

export async function entriesApiCrud({
  method,
  body,
  id,
  queryStringParameters,
}: PropType) {
  if (method === "post") {
    return API.post(TABLE, `/${TABLE}`, {
      body: {
        entries: body,
      },
    });
  }
  if (method === "put") {
    return API.put(TABLE, `/${TABLE}/${id}`, { body });
  }
  if (method === "delete") {
    return API.del(TABLE, `/${TABLE}/${id}`, {
      body: {
        entries: body,
      },
    });
  }
  if (method === "get") {
    return API.get(TABLE, `/${TABLE}`, { queryStringParameters });
  }
}
