import { ReactNode } from "react";
import { Radio, Card } from "antd";

/**
 * @param {Object} props
 * @param {ReactNode} props.icon
 * @param {ReactNode} props.title
 * @param {any} props.value
 * @param {boolean} [props.disabled]
 * @param {string} [props.buttonType]
 * @param {string} [props.className]
 */
function RadioIconLabel({
  icon,
  title,
  value,
  className,
  buttonType = "primary",
  disabled = false,
}) {
  return (
    <Radio.Button
      className={`radio-icon-label-container ${className ?? ""}`}
      value={value}
      type={buttonType}
      disabled={disabled}
    >
      <Card.Meta
        {...{
          avatar: icon,
          className: "radio-meta",
          title: title,
        }}
      />
    </Radio.Button>
  );
}

export default RadioIconLabel;
