import { Modal } from "antd";
import React from "react";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import "./ViewsInfo.scss";
import { useSelector } from "react-redux";

const ViewsInfo = ({ open, onCancel, topUsers, totalViews }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <Modal
      title="Views Info"
      open={open}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      className={`viewsInfo ${isDarkMode && "viewsInfo-dark"}`}
      footer={
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={onCancel}
        >
          Close
        </MondayButton>
      }
    >
      <div className="body">
        <p>Top viewed users</p>
        {topUsers.length > 0 ? (
          <ul>
            {topUsers.map((user, index) => (
              <li key={index}>
                <span>{user.nameOfUser}</span> - <span>{user.viewCount}</span>
              </li>
            ))}
          </ul>
        ) : (
          <span>No viewers yet</span>
        )}

        <p>
          Total Views: <span>{totalViews}</span>
        </p>
      </div>
    </Modal>
  );
};

export default ViewsInfo;
