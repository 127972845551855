/**
 * Retries a fetch operation when a 429 (Too Many Requests) error occurs.
 *
 * This function attempts to execute the provided `fetchFunction`. If the function throws an error with a 429 status code,
 * it will wait for a specified delay and retry the operation up to a specified number of times. If the fetch function
 * succeeds, the result is returned. If all retries are exhausted or an error other than 429 is encountered, the error is thrown.
 *
 * @param {Function} fetchFunction - The function that performs the fetch operation. It should return a promise.
 * @param {number} [retries=5] - The number of times to retry the fetch operation if a 429 error occurs.
 * @param {number} [delay=1000] - The delay (in milliseconds) between retries.
 * @returns {Promise} - A promise that resolves with the result of the fetch operation or rejects with an error.
 * @throws {Error} - Throws an error if the fetch operation fails with a status other than 429 or if retries are exhausted.
 */
export const retryFetch = async (fetchFunction, retries = 5, delay = 1000) => {
  try {
    return await fetchFunction();
  } catch (error) {
    if (error.response?.status === 429 && retries > 0) {
      console.error({ retries });
      console.error({ error });
      console.error(
        `Retrying fetch due to 429 status. Retries left: ${retries}`
      );
      await new Promise((res) => setTimeout(res, delay));
      return retryFetch(fetchFunction, retries - 1, delay);
    } else {
      console.error({ retries, error });
      throw error;
    }
  }
};

/**
 * Pauses execution for a specified amount of time.
 *
 * This function returns a Promise that resolves after a delay specified by the `ms` parameter.
 * It can be used to introduce delays in asynchronous code, such as waiting between API requests
 * or delaying execution to avoid rate limits.
 *
 * @param {number} ms - The number of milliseconds to wait before resolving the Promise.
 * @returns {Promise<void>} A Promise that resolves after the specified delay.
 *
 * @example
 * async function example() {
 *   console.log('Starting delay...');
 *   await sleep(2000); // Wait for 2 seconds
 *   console.log('Delay complete!');
 * }
 *
 * example();
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
