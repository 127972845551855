import { MondayButton } from "../../../../../commonComponents";
import "./FilePreviewModal.scss";
import { GDriveIcon } from "../../assets";
import { openInNewTab } from "../../../../../../utils";
import { XIcon } from "../../../../Communication/assets";
import {
  DownloadIcon,
  EmailIcon,
  LeftArrow,
  RightArrow,
} from "../../../../BasePage/src";
import EmailBox from "../../../../Communication/components/EmailBox/EmailBox";
import { useState } from "react";
import { Modal } from "antd";
import FileView from "./FileView";
import { useSelector } from "react-redux";

/**
 *
 * @param {boolean} visible - value of the modal visibility
 * @param {function} setVisible - function to set the visibility of the modal
 * @param {object} file - object containing the file details
 * @param {number} downloadProgress - value of the download progress
 * @param {boolean} progressVisible - value of the progress visibility
 * @param {number || false} carouselLength - value of the carousel length
 * @param {function} changeCarouselSlide - function to change the carousel slide. Needs @param {number} - next or prev slide number
 * @returns {JSX.Element} - FilePreviewModal
 */

const FilePreviewModal = ({
  visible,
  setVisible,
  file = {},
  downloadProgress,
  progressVisible,
  carouselLength = false,
  changeCarouselSlide = () => {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [emailBox, setEmailBox] = useState(false);
  const { id, name = "", src, mimeType, url, blob, index = null } = file;

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }

  const canNotEmail = location.pathname.includes("foremanProject");

  return (
    <Modal
      {...{
        open: visible,
        setVisible,
        className: `filePreviewModal ${isDarkMode && "filePreviewModalDark"} ${
          !!carouselLength && "carouselFilePreviewModal"
        } ${
          index === 0 && !!carouselLength
            ? "noRightPadding"
            : index === carouselLength - 1 && !!carouselLength
            ? "noLeftPadding"
            : ""
        }`,
        destroyOnClose: true,
        centered: true,
        maskClosable: true,
        title: name || "File Preview",
        closeIcon: <XIcon />,
        onCancel: () => {
          setEmailBox(false);
          setVisible(false);
        },
        footer: (
          <div className="gDriveButtons">
            {!!id && (
              <MondayButton
                {...{
                  className: "mondayButtonBlue",
                  Icon: <DownloadIcon />,
                  onClick: () => {
                    window.open(
                      `https://drive.google.com/u/1/uc?id=${id}&export=download&authuser=0`
                    );
                  },
                }}
              >
                Download
              </MondayButton>
            )}
            <div className="gDriveButtonsRight">
              {!!url && (
                <MondayButton
                  {...{
                    className: "mondayButtonBlue driveIcon",
                    Icon: <GDriveIcon />,
                    onClick: () => openInNewTab(url),
                  }}
                >
                  Open In Drive
                </MondayButton>
              )}
              {!!id && !canNotEmail && (
                <MondayButton
                  {...{
                    className: "mondayButtonBlue",
                    Icon: <EmailIcon />,
                    onClick: () => {
                      setEmailBox(true);
                    },
                  }}
                >
                  Send Email
                </MondayButton>
              )}
            </div>
          </div>
        ),
      }}
    >
      {mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      mimeType === "application/msword" ? (
        <>
          <p>
            This is an Excel or Word file. Preview not available. Please
            download to pc to view it.
          </p>
          <a href={src} download={file.name}>
            Download File
          </a>
        </>
      ) : (
        <>
          <div className="filePreviewCarousel">
            <div className="filePreviewLeft">
              {index !== 0 && !!carouselLength ? (
                <MondayButton
                  className="mondayButtonBlue"
                  onClick={() => {
                    changeCarouselSlide(index - 1);
                  }}
                  Icon={<LeftArrow fill="#000" />}
                ></MondayButton>
              ) : (
                <div className="filePreviewLeft"></div>
              )}
            </div>
            <div className="filePreviewContent">
              <FileView
                {...{
                  src,
                  id,
                  downloadProgress,
                  progressVisible,
                  mimeType,
                  blob,
                }}
              />
            </div>
            <div className="filePreviewRight">
              {index !== carouselLength - 1 && !!carouselLength ? (
                <MondayButton
                  onClick={() => {
                    changeCarouselSlide(index + 1);
                  }}
                  className="mondayButtonBlue"
                  Icon={<RightArrow fill="#000" />}
                ></MondayButton>
              ) : (
                <div className="filePreviewRight"></div>
              )}
            </div>
          </div>
          {index !== null && carouselLength ? (
            <div className="filePreviewFooter">
              {`${index + 1} of ${carouselLength}`}
            </div>
          ) : (
            <></>
          )}

          {emailBox && (
            <div className="safetyEmailBox">
              <EmailBox
                {...{
                  onClose: () => setEmailBox(false),
                  setEmails: () => {},
                  rowData: {
                    drafts: [],
                  },
                  onSave: (e, type, setLoading) => {
                    onEmailSave({ e, type, setLoading });
                  },
                  isRedirected: [id],
                  isFromCharge: true,
                  // sendEmailNotification,
                  proppedTemplateCat: "Documentation",
                }}
              />
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default FilePreviewModal;
