import { filterTables } from "../../../../../utils";
import deletePromise from "./deletePromise";

/**
 * @param {String} recordId the id of the record being deleted
 * @function {} we get all todos for the current record through filterTables and then loop through them to create a delete promise for them
 * @returns returns a Promise.allSettled on the delete promises
 */
async function deleteAllRelatedToDos({ recordId }) {
  if (!recordId) return;
  try {
    const todosToDelete = [];

    await filterTables("todos", "todoRecordId", recordId)?.then((data) => {
      !!data?.length &&
        data.forEach((item) => {
          todosToDelete.push(
            deletePromise({ id: item?.todoId, endpoint: "todos" })
          );
        });
    });

    if (!!todosToDelete?.length) {
      return await Promise.allSettled(todosToDelete)?.then((e) =>
        console.log("To dos deleted Successfully", e)
      );
    }
  } catch {
    console.error("Error deleting To Dos");
  }
}

export default deleteAllRelatedToDos;
