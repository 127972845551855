export const compareIncluding = (first = "", second = "") =>
  first?.toUpperCase?.()?.includes?.(second?.toUpperCase?.());

export const compareAddressesWithRgx = (first = "", second = "") => {
  return (
    new String(
      first
        ?.replace(/\s+/g, "")
        ?.replace(/,/g, "")
        ?.replace(/(U)(S)(A)(?=\s|$)/g, "")
    ).valueOf() ===
    new String(
      second
        ?.replace(/\s+/g, "")
        ?.replace(/,/g, "")
        ?.replace(/(U)(S)(A)(?=\s|$)/g, "")
    ).valueOf()
  );
};

export const compareIncludingAccurate = (first = "", second = "") => {
  return (
    new String(first?.replace(/\s+/g, "")?.replace(/,/g, ""))
      .valueOf()
      ?.toUpperCase() ===
    new String(second?.replace(/\s+/g, "")?.replace(/,/g, ""))
      .valueOf()
      ?.toUpperCase()
  );
};

export const compareMoreLike = (first = "", arrString = []) => {
  return arrString?.map((str) => ({
    str,
    similarity: similarity(
      first?.removeSpacesAndCommas(),
      str?.removeSpacesAndCommas()
    ),
  }));
};

export const compareTwoFloats = (value, max) => {
  return (
    Math.round(parseFloat(value) * 100000) <
    Math.round(parseFloat(max) * 100000)
  );
};

function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}
