import FormItem from "../../components/FormItems/FormItem";
import StyledButton from "../../components/StyleButton/StyledButton";
import _ from "lodash";
import { validateForm } from "../../../Fleet/utils";
import { useEffect, useState } from "react";
import "./MultiBox.scss";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
const Information = (prop) => {
  const {
    params: { rowData },
    form,
    getEditedValue,
    setEditMode,
    setFormChanged = () => {},
    getFormChanged = () => {},
  } = prop;
  let formList = {};
  //saved previus data
  const [prev, setPrev] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };
  useEffect(() => {
    setPrev(() => _.cloneDeep(prop));
  }, [prop]);
  ///  finished
  const values = rowData?.reduce((ac, { formItemName, value }) => {
    if (formItemName !== "createdBy" && formItemName !== "lastModifiedBy") {
      if (ac.formItemName) {
        formList[ac.formItemName] = ac.value;
      }
      formList[formItemName] = value;
    }
    return formList;
  });

  const validateFields = (onSuccessFunc, onError) => {
    validateForm(form, onSuccessFunc, onError);
  };

  return (
    <div className="MultiBoxComponent">
      <div className="MultiBoxContainer">
        {rowData
          .filter(
            ({ label, type }) =>
              label !== "Created By" && label !== "Last Modified By" && type
          )
          .map(
            ({
              label,
              value,
              formItemName,
              type,
              options = [],
              disabled,
              rules,
            }) => (
              <>
                <div className="InforamtionBodyElementContainer">
                  {FormItem[type]({
                    name: formItemName,
                    value,
                    getEditedValue,
                    form,
                    rules,
                    options,
                    label,
                    disabled,
                    getTaxExept: "",
                    onChange() {
                      setFormChanged(true);
                    },
                  })}
                </div>
              </>
            )
          )}
      </div>
      <div className="confirmContainer">
        <MondayButton
          onClick={(res) => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          onClick={(res) => {
            if (!_.isEqual(values, form.getFieldsValue())) {
              validateFields(
                () => {
                  getEditedValue(
                    form.getFieldsValue(),
                    undefined,
                    values,
                    prev,
                    keys(form.getFieldsValue()).map((e) => {
                      prop.params.rowData.find(
                        ({ formItemName }) => formItemName === e
                      ).value = form.getFieldsValue()[e];
                      return prop;
                    })[0]
                  );
                  setEditMode(false);
                },
                () => {}
              );
            } else {
              setEditMode(false);
            }
          }}
          className="confirmButton"
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default Information;
