import { Dropdown, Menu } from "antd";

const DropdownWrapper = ({
  onClick,
  dropdownDisabled = false,
  dropdownOptions,
  children,
  isDarkMode,
}) => {
  const menu =
    dropdownOptions?.map((item) => ({
      key: item,
      label: item,
    })) || [];

  return (
    <Dropdown
      disabled={!!dropdownDisabled}
      menu={{
        items: menu,
        onClick,
        disabled: !!dropdownDisabled,
        className: `NormalSizedModalDropdown ${
          isDarkMode && "NormalSizedModalDropdownDark"
        }`,
      }}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownWrapper;
