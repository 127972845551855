// import uniq from "lodash/uniq";
// import { getRandomColor } from "../../../../utils";

export const workOrders = (
  workOrdersData,
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  const workOrdersStatuses = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions["Work Orders"];

  return {
    workOrderStatus: () => ({
      title: "Work Order Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Work Orders",
        field: "workOrderStatus",
        labels: workOrdersStatuses?.map?.(({ statusName }) => statusName),
        datasets: [
          {
            label: "My First Dataset",
            data: workOrdersStatuses?.map?.(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a.workOrderStatus?.toLowerCase() ===
                    status?.statusName?.toLowerCase()
                )?.length
            ),
            backgroundColor: workOrdersStatuses?.map((a) => a?.statusColor),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    // fleetName: () => ({
    //   title: "Work Order: Fleet Name",
    //   BodyComponent: "Pie",
    //   type: "Pie",
    //   id: IdHandler,
    //   BodyComponentParams: {
    //     table: "Work Orders",
    //     field: "fleetName",
    //     labels: uniq(workOrdersData?.map((a) => a?.fleetName)),
    //     datasets: [
    //       {
    //         label: "My First Dataset",
    //         data: uniq(workOrdersData?.map((a) => a?.fleetName))?.map(
    //           (status) =>
    //             (updatedDynamicStates?.[IdHandler] || [])?.filter(
    //               (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
    //             )?.length
    //         ),
    //         backgroundColor: uniq(workOrdersData?.map((a) => getRandomColor())),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    // }),
  };
};
