import { safetyInspectionPdfBody } from "../../../../safetyPdf";

function safetyInspectionWordData({
  dynamicFields,
  selectedTeam,
  inspectionCategory,
  formDataUsers,
  userConfiguration,
  rowObject,
}) {
  let toReturn = [];
  let tmpFields = dynamicFields?.map((el) => {
    if (
      el?.title === "General Information" ||
      el?.title === "Basic Information"
    ) {
      return {
        ...el,
        fields: [
          ...el?.fields,
          {
            label: "Team Members",
            value: selectedTeam?.map((team) =>
              team?.members?.map((e) => e?.nameOfUser)
            ),
          },
        ],
      };
    }
    return el;
  });

  if (inspectionCategory === "General Inspection") {
    tmpFields?.push({
      title: "Signature",
      fields: [
        {
          label: "End Time Of Inspection",
          formItemName: "endTimeOfInspection",
        },
        {
          label: "Full Name Of Inspector",
          formItemName: "inspectorName",
        },
        {
          label: "Signature",
          formItemName: "signature",
        },
      ],
    });
  } else {
    tmpFields?.push({
      title: "Signature",
      fields: [
        {
          label: "Prepared On",
          formItemName: "preparedOn",
        },
        {
          label: "Prepared By",
          formItemName: "preparedBy",
        },
        {
          label: "Signature",
          formItemName: "signature",
        },
      ],
    });
  }
  toReturn = tmpFields?.map((el) => {
    return {
      title: el?.title,
      body: safetyInspectionPdfBody({
        category: el?.title,
        fieldsJSON: tmpFields,
        rowObject,
        ...formDataUsers,

        userConfiguration,
      }),
    };
  });
  return toReturn;
}

export default safetyInspectionWordData;
