export const updateServiceInvoices = ({
  service,
  invoiceData,
  invoicedService,
  res,
}) => {
  const invoice = {
    invoiceId: res ? res?.invoiceId : invoiceData?.invoiceId,
    total: invoicedService?.total,
    amount: invoicedService?.amount,
    amountPercent: invoicedService?.amountPercentage,
  };

  if (invoicedService) {
    service.invoices = res
      ? [...(service?.invoices || []), invoice]
      : [
          ...(service?.invoices?.filter(
            ({ invoiceId }) => invoiceId !== invoiceData?.invoiceId
          ) || []),
          invoice,
        ];
  }
};
