export const saveStepLogs = (logs, saveAddedLogs, userConfiguration) => {
  saveAddedLogs(
    logs.map(({ key, stepKey, stepTitle }) => ({
      actionType: key?.charAt(0).toUpperCase() + key?.slice(1),
      recordId: userConfiguration.cognitoUserId,
      recordName: userConfiguration.nameOfUser,
      category: "On Boarding",
      topic: stepKey,
      label: stepTitle,
    }))
  );
};
