import { applications, rentals, charges,invoices } from "./subCategory";

export const lineChartFull = ({ tables, programFields }) => {
  return {
    Applications: () => applications({ tables, programFields }),
    Rentals: () => rentals({ tables, programFields }),
    Charges: () => charges({ tables, programFields }),
    Invoices: () => invoices({ tables, programFields }),
  };
};
