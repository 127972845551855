import { defaultExcelBodyStyle } from "../../../../../../helpers/constants/defaultExcelBodyStyle";
import { defaultExcelHeaderStyle } from "../../../../../../helpers/constants/defaultExcelHeaderStyle";
import { exportExcel } from "../../../../../../utils/exportExcel";
import { sessionsTableHeaders } from "./sessionLogsPDF";
import dayjs from "dayjs";

export const sessionExportToExcel = (
  getDocDefinition = false,
  headersData,
  filteredLogs,
  allUsersObj,
  onGeneratePDF
) => {
  if (getDocDefinition) return onGeneratePDF("download", getDocDefinition);

  const columnsToInclude =
    headersData || tableHeadersExcel.map((item, index) => index);

  const rows = filteredLogs.map((log) => {
    const {
      sessionId,
      cognitoUserId,
      sessionStatus,
      nameOfUser,
      userDepartment,
      userRole,
      sessionInfo,
    } = log || {};
    const { ip, osName, browserName, logTime, identityId } = sessionInfo || {};

    const name = nameOfUser || allUsersObj?.[identityId]?.nameOfUser || "";

    const device =
      osName && browserName ? `${osName} - ${browserName}` : "Not Found";

    return [
      { v: name || "", s: defaultExcelBodyStyle },
      { v: sessionStatus || "", s: defaultExcelBodyStyle },
      {
        v: dayjs(logTime).format("MM/DD/YYYY HH:mm:ss") || "",
        s: defaultExcelBodyStyle,
      },
      { v: ip || "", s: defaultExcelBodyStyle },
      { v: device || "", s: defaultExcelBodyStyle },
      { v: userRole || "", s: defaultExcelBodyStyle },
      { v: userDepartment || "", s: defaultExcelBodyStyle },
      { v: sessionId || "", s: defaultExcelBodyStyle },
    ];
  });

  exportExcel(
    tableHeadersExcel,
    columnsToInclude,
    rows,
    "Session Logs",
    "Session Logs"
  );
};

export const tableHeadersExcel = sessionsTableHeaders.map((header) => ({
  v: header,
  s: defaultExcelHeaderStyle,
}));
