import React from "react";
import ErrorComponent from "./assets/ErrorComponent";
import { LeftArrow } from "./components/SidebarPages/BasePage/src";
import { message } from "antd";
import "./index.scss";
import { OpenedEyeIcon } from "./assets";
import ErrorInfoModal from "./assets/ErrorInfoModal";
import { callGmailAPI } from "./components/SidebarPages/Communication/functions";
import { Auth } from "aws-amplify";

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
  infoModal: boolean;
  errorInfo: {
    error?: Error;
    errorInfo?: React.ErrorInfo;
  };
  userInfo: {
    given_name?: string;
    family_name?: string;
    email?: string;
  };
  explanation?: string;
};

const baseUrl: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://portal.corescaffold.com";
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      infoModal: false,
      errorInfo: {},
      userInfo: {},
      explanation: null,
    };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      infoModal: false,
    };
  }
  async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error("ERROR BOUNDARY", error, errorInfo);
    const user = await Auth.currentAuthenticatedUser();
    this.setState({
      errorInfo: { error, errorInfo },
      userInfo: {
        given_name: user.attributes.given_name,
        family_name: user.attributes.family_name,
        email: user.attributes.email,
      },
    });
  }

  infoModalHandler = (boolean: boolean) => {
    this.setState({ infoModal: boolean });
  };

  sendEmail = async (otherFunc = () => {}) => {
    const hideLoading = message.loading("Sending Email...", 0);
    if (process.env.NODE_ENV === "production") {
      await callGmailAPI("sendEmail", {
        to: ["developers@flex.al", "lona@flex.al"],
        // to: ["enea@flex.al"],
        subject: `Error Report - @${window.location.pathname}`,
        body: `<h1>${this.state.userInfo.given_name} ${
          this.state.userInfo.family_name
        } - ${this.state.userInfo.email}</h1>
        <br/>
        <h2>Explanation: ${this.state.explanation || ""}</h2>
        <br/>
        <h3>${this.state.errorInfo.error?.message}</h3>
        <br/>
        <p>${this.state.errorInfo.errorInfo?.componentStack}</p>`,
        attachments: [],
        category: "error",
        authData: false,
        companyName: "Core Scaffold Systems Inc",
      })
        .then(() => {
          hideLoading();
          if ("referrer" in document)
            window.location.replace(document.referrer);
          else window.location.reload();
        })
        .catch((e) => {
          if ("referrer" in document)
            window.location.replace(document.referrer);
          else window.location.reload();
          hideLoading();
          console.log("Error sending email", e);
        });
    } else {
      hideLoading();
      otherFunc();
      if ("referrer" in document) window.location.replace(document.referrer);
      else window.location.reload();
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary">
          <ErrorComponent
            {...{
              errorMessage: "Something Went Wrong",
              errorDescription: "Contact the Software Development Department",
              buttonProps: {
                buttonText: "Go Back",
                buttonAction: async () =>
                  await this.sendEmail(() => {
                    if ("referrer" in document)
                      window.location.replace(document.referrer);
                    else window.location.reload();
                  }),
                suffixIcon: <LeftArrow />,
                isDisabled: false,
              },
              feedBackProps: {
                feedBackAction: () =>
                  this.infoModalHandler(!this.state.infoModal),
                feedBackText: <OpenedEyeIcon />,
              },
            }}
          />
          {this.state.infoModal && (
            <ErrorInfoModal
              {...{
                visible: this.state.infoModal,
                setVisible: this.infoModalHandler,
                errorInfoProps: {
                  error: this.state.errorInfo.error,
                  errorInfo: this.state.errorInfo.errorInfo,
                },
                explanation: this.state.explanation,
                setExplanation: (explanation: string) =>
                  this.setState({ explanation }),
                buttonAction: async () => {
                  await this.sendEmail();
                  this.infoModalHandler(false);
                },
              }}
            />
          )}
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
