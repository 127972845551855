import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  ChangeEvent,
} from "react";
import { Form, message, Modal } from "antd";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { groupBy, debounce } from "lodash";
import { GridApi } from "ag-grid-community";
import { SearchOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

import { MondayButton } from "src/components/commonComponents";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { punchTableColumns } from "src/components/pages/PayrollLive/utils";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import {
  MAP_DARK,
  MAP_DEFAULT,
  GOOGLE_API_KEY,
  NEW_YORK_LOCATION,
} from "src/components/SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";
import PayrollLiveContext from "src/components/pages/PayrollLive/PayrollLiveContext";
import { EmployeeReportType } from "src/components/pages/PayrollLive/payrollLiveTypes";
import { getPayrollLiveReport } from "../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import EmployeeMarker from "src/components/pages/PayrollLive/components/EmployeeMarker/EmployeeMarker";

import "./LastPunchOverview.scss";

type Props = { open: boolean; onCancel: () => void };
type MapInstanceType = google.maps.Map;

const defaultColDefs = {
  flex: 1,
  filter: true,
  sortable: true,
  editable: false,
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
  enableColResize: true,
};

const punchStatusColor = {
  In: "#00a464",
  Out: "#787677",
  "No Punch": "#f04f4e",
};

const LastPunchOverview = (props: Props) => {
  const { open, onCancel } = props;
  const { controlPanelForm, clientConfigs, getLatestEntries } =
    useContext(PayrollLiveContext);
  const { isDarkMode } = useSelector((store: StoreType) => store.darkMode);

  const [map, setMap] = useState<MapInstanceType>();
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string | undefined>();
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [empLastReports, setEmpLastReports] = useState<
    Array<EmployeeReportType>
  >([]);

  const { isLoaded } = useLoadScript({
    libraries: ["drawing"],
    googleMapsApiKey: GOOGLE_API_KEY,
  });
  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);
  const clientCompany = Form.useWatch("clientCompany", controlPanelForm);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  function onFilterTextChange(e: ChangeEvent<HTMLInputElement>) {
    gridApi.setQuickFilter(e.target.value);
    setSearchInput(e.target.value);
  }

  function onStatusFilter(status: string) {
    setStatusFilter((prev) => {
      if (prev === status) {
        gridApi.setFilterModel(null);
        if (!!searchInput) {
          gridApi.setQuickFilter(searchInput);
        }
        return undefined;
      } else {
        gridApi?.getFilterInstance?.("liveStatus")?.setModel?.({
          type: "Dropdown",
          values: [status],
        });
        gridApi?.onFilterChanged?.();
        return status;
      }
    });
  }

  function getEntriesOfDate() {
    const selectedClient = clientConfigs.find(
      (el) => el.configId === clientCompany
    );
    if (!selectedClient?.clientName) {
      message.error("There is no client selected");
      return;
    }
    getLatestEntries(
      selectedClient?.clientName,
      selectedDate?.format?.("YYYY-MM-DD")
    );
    onCancel();
  }

  const mapOptions = useMemo(() => {
    return {
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      styles: isDarkMode ? MAP_DARK : MAP_DEFAULT,
      mapTypeControlOptions: {
        position: google?.maps?.ControlPosition?.BOTTOM_LEFT || 7,
        style: google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR || 2,
      },
    };
  }, [isDarkMode]);

  const columnDefs = useMemo(() => {
    return punchTableColumns({});
  }, []);

  const employeeMarkers = useMemo(() => {
    let markers = [];
    if (gridApi) {
      gridApi.forEachNodeAfterFilter((node) => markers.push(node.data));
    }

    if (!markers?.length && empLastReports?.length) {
      markers = empLastReports;
    }

    return markers?.length
      ? markers.flatMap((el) => {
          return el?.punchCoordinates?.lat ? (
            <EmployeeMarker
              data={el}
              key={el.fingerCheckId}
              color={el?.color || punchStatusColor?.[el.liveStatus]}
            />
          ) : (
            []
          );
        })
      : null;
  }, [gridApi, searchInput, statusFilter, empLastReports]);

  const statusCards = useMemo(() => {
    const liveStatusTypes = groupBy(
      empLastReports,
      (el: EmployeeReportType) => el.liveStatus
    );
    return (
      <div className="status-cards">
        {Object.keys(liveStatusTypes).map((key) => {
          return (
            <div
              className={`punch-status ${key.replaceAll(" ", "")} ${
                statusFilter === key ? "active" : ""
              }`}
              onClick={() => onStatusFilter(key)}
            >
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {liveStatusTypes[key]?.length}
              </span>
              {` ${key}`}
            </div>
          );
        })}
      </div>
    );
  }, [empLastReports, statusFilter]);

  function handleMapLoad(mapInstance: MapInstanceType) {
    setMap(mapInstance);
  }

  useEffect(() => {
    if (clientConfigs?.length && selectedDate) {
      const selectedClient = clientConfigs.find(
        (el) => el.configId === clientCompany
      );

      if (selectedClient) {
        getPayrollLiveReport({
          excludedEmployees: [],
          clientKey: selectedClient.clientKey,
          selectedDate: selectedDate.format("YYYY-MM-DD"),
        }).then((res: AxiosResponse<Array<EmployeeReportType>>) =>
          setEmpLastReports(res.data)
        );
      }
    }
  }, [clientConfigs, clientCompany, selectedDate]);

  return (
    <Modal
      open={open}
      closable={true}
      centered={true}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      title={`Last Punch Overview ${
        selectedDate?.format?.("MM/DD/YYYY") || ""
      }`}
      className={`last-punch-overview-modal ${
        isDarkMode ? "last-punch-overview-modal-dark" : ""
      }`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          onClick={onCancel}
          className="mondayButtonRed"
        >
          Cancel
        </MondayButton>,
        // <MondayButton
        //   Icon={<DownloadIcon />}
        //   onClick={getEntriesOfDate}
        //   className="mondayButtonBlue"
        // >
        //   Update {selectedDate?.format?.("MM/DD/YYYY")} entries
        // </MondayButton>,
      ]}
    >
      <section className="control-panel">
        <InputComponent
          type="input"
          placeholder="Search..."
          prefix={<SearchOutlined />}
          onChange={debounce(onFilterTextChange, 600)}
        />
        {statusCards}
      </section>
      <section className="reports-overview">
        <div
          className={`punch-actions-grid ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
        >
          <AgGridReact
            rowHeight={32}
            pagination={true}
            animateRows={true}
            paginationAutoPageSize
            columnDefs={columnDefs}
            rowData={empLastReports}
            onGridReady={onGridReady}
            rowGroupPanelShow={"always"}
            defaultColDef={defaultColDefs}
            sideBar={{
              toolPanels: [
                {
                  id: "columns",
                  iconKey: "columns",
                  labelKey: "columns",
                  labelDefault: "Columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  iconKey: "filter",
                  labelKey: "filters",
                  labelDefault: "Filters",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
            }}
          />
        </div>
      </section>
      <section className="map-view">
        {isLoaded ? (
          <GoogleMap
            zoom={12}
            id="mapContainer"
            options={mapOptions}
            onLoad={handleMapLoad}
            center={NEW_YORK_LOCATION}
            mapContainerClassName="payroll-live-map"
          >
            {employeeMarkers}
          </GoogleMap>
        ) : null}
      </section>
    </Modal>
  );
};

export default LastPunchOverview;
