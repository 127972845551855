import React from "react";

import { Button, message } from "antd";
import _ from "lodash";
import { get_ProgressDimensionsByServiceId } from "../../../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { get_new_progressByDay } from "../../../../../../SidebarPages/Scheduling/helpers/setters";
import { getSurface } from "../../../helpers/creators";
import { showLoadingMsg, showSuccessMsg } from "../../../../../../../utils";
import { MinusIcon, PlusIcon } from "../../../../../../../assets";
import { DropDownArrow } from "../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { unablePli } from "../../../SchedulingFirstPage/helperData";

export class ModifyRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props?.value,
      length: props?.value,
    };
  }

  refresh(props) {
    this.setState({ value: props?.value });
    return true;
  }

  handleAdd = () => {
    if (
      (this?.props?.node?.data?.typeOfProgress !== "" &&
        this?.props?.node?.data?.typeOfProgress !== "subPli") ||
      unablePli(this?.props?.node?.data)
    ) {
      message.warning(
        "Can't add Sub-Pli because you have set days in other progress type."
      );
      return;
    } else {
      showLoadingMsg({ content: "Creating data..." });
      try {
        const { serviceId, elevationIndex } = this.props;
        const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
        let pliData = _.cloneDeep(this?.props?.node?.data);
        let newSubPLis = pliData?.subPli || [];

        if (pliData?.subPli?.length === 0) {
          //if split is done for the first time

          //first sub-pli
          let addedSubPLi = this.getEmptySubPLI(pliData, { d1, d2 });
          addedSubPLi.days = pliData.days;
          addedSubPLi.progressByDay = get_new_progressByDay(pliData.days, [], {
            d1,
            d2,
          });
          addedSubPLi.parentId = this?.props?.data?.id;
          addedSubPLi.id = 1;

          addedSubPLi[d1] = Math.round(pliData?.[d1] / 2);
          if (d2) {
            addedSubPLi[d2] = pliData?.[d2];
            addedSubPLi["surface"] = getSurface(addedSubPLi, { d1, d2 });
          }

          pliData.subPli.push(addedSubPLi);

          //second sub-pli
          addedSubPLi = this.getEmptySubPLI(pliData, { d1, d2 });
          addedSubPLi.days = pliData.days;
          addedSubPLi.progressByDay = get_new_progressByDay(pliData.days, [], {
            d1,
            d2,
          });
          addedSubPLi.parentId = this?.props?.data?.id;
          addedSubPLi.id = 2;

          addedSubPLi[d1] = pliData[d1] - pliData?.subPli?.[0]?.[d1]; //put remaining from whats left
          if (d2) {
            addedSubPLi[d2] = pliData[d2];
            addedSubPLi["surface"] = getSurface(addedSubPLi, { d1, d2 });
          }

          pliData.subPli.push(addedSubPLi);
        } else {
          const addedSubPLi = this.getEmptySubPLI(pliData, { d1, d2 });
          addedSubPLi.progressByDay = get_new_progressByDay([], [], { d1, d2 });
          addedSubPLi.parentId = this?.props?.data?.id;
          addedSubPLi.id = pliData?.subPli?.length + 1;
          addedSubPLi["surface"] = 0;

          // console.log(addedSubPLi)
          pliData.subPli.push(addedSubPLi);
          pliData.typeOfProgress = "subPli";
        }

        var newLength = newSubPLis?.length;
        this.setState({ length: newLength });

        var sum = 0;

        newSubPLis.forEach((subPLi) => {
          sum += parseInt(subPLi.length);
        });
        this.props.node.setDataValue(this.props.column.colId, newSubPLis);
        this.props.node.setExpanded(true);
        // let rowNode = this.props.api.getRowNode(pliData.id)

        //  this.props.api.redrawRows();
        setTimeout(() => {
          showSuccessMsg({ content: "Subpli created successfully!" });
          this.props.node.setExpanded(true);
          this.props.api.applyTransaction({ update: [pliData] });
          this.props.api.resetRowHeights();
          // this.props.api.redrawRows();
        }, 1000);

        if (this.props.onSubPLIAddRemove)
          this.props.onSubPLIAddRemove(serviceId, elevationIndex, pliData);
      } catch (e) {
        console.error(e);
      }
    }
  };

  getEmptySubPLI = (pli, { d1, d2 }) => {
    const subPLI = { days: [] };
    subPLI[d1] = 0;
    if (d2) {
      subPLI[d2] = 0;
    }

    subPLI["totalProgress"] = 0;
    return subPLI;
  };

  handleRemove = () => {
    try {
      // if (this?.props?.node?.expanded !== true) {
      //   message.info("First open rows, then start removing.");
      //   return;
      // }

      var pli = _.cloneDeep(this?.props?.node?.data);
      const { serviceId, elevationIndex, concatDays_From_PLI_ToElevation } =
        this.props;

      var subPLis = pli?.subPli;
      if (subPLis?.length === 0) {
        message.info("No sub-pli to remove.");
        pli.days = [];
        pli.progressByDay = [];
        pli.typeOfProgress = "";
        return;
      }

      if (subPLis?.[subPLis?.length - 1]?.totalProgress > 0) {
        message.warning(
          `Can't remove sub-pli ${
            subPLis?.[subPLis?.length - 1]?.id
          } because progress is already set for it!`
        );
        return;
      }

      if (subPLis?.length === 2) {
        // subPLis = []
        subPLis.pop(); // remove one item
        subPLis.pop(); // remove one item
        pli.days = [];

        let totalDays = [];
        this?.props?.api?.forEachNode((n) => {
          if (n?.id === this?.props?.node?.id) n.data.days = [];

          totalDays = totalDays?.concat(n?.data?.days);
        });
        totalDays = [...new Set(totalDays)];

        // console.log(pli)
        concatDays_From_PLI_ToElevation(
          serviceId,
          elevationIndex,
          totalDays,
          pli
        );
      } else {
        subPLis.pop(); // remove one item

        let sum = 0;
        subPLis?.forEach((subPLi) => {
          sum += parseInt(subPLi?.length);
        });
      }

      this.setState({ length: subPLis?.length });
      this.props.node.setDataValue(this?.props?.column?.colId, subPLis);
      this.props.api.applyTransaction({ update: [pli] });
      this.props.node.setExpanded(true);
      this.props.api.resetRowHeights();
      this.props.api.redrawRows();

      // console.log(pli)

      if (this.props.onSubPLIAddRemove)
        this.props.onSubPLIAddRemove(serviceId, elevationIndex, pli);
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <>
        {!this?.props?.node?.group ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
            }}
          >
            <div
              style={{
                border: "none",
                background: "none",
                color: !this?.props?.node?.expanded
                  ? "#1890ff"
                  : "rgb(254, 76, 74)",
                width: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                if (
                  !this?.props?.node?.data?.subPli?.length &&
                  !this?.props?.node?.expanded
                ) {
                  message.warning("Please add sub PLI first.");
                } else {
                  this.props.node.setExpanded(!this?.props?.node?.expanded);
                  this.props.api.resetRowHeights();
                  // this.props.refreshCells();
                }
              }}
            >
              {this?.props?.node?.expanded ? (
                <DropDownArrow />
              ) : (
                <DropDownArrow
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                />
              )}
            </div>
            {"(" + (this?.state?.value?.length || 0) + ")"}

            <Button
              aria-label="Remove"
              size="small"
              shape="circle"
              className="rowModifierAdd"
              onClick={this.handleRemove}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#FE4C4A",
                width: 26,
                height: 26,
                color: "#FE4C4A",
                borderRadius: 5,
                border: "none",
              }}
            >
              <MinusIcon />
            </Button>
            <Button
              size="small"
              shape="circle"
              className="rowModifierAdd"
              onClick={this.handleAdd}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#71CF48",
                width: 26,
                height: 26,
                color: "#71CF48",
                borderRadius: 5,
                border: "none",
              }}
            >
              <PlusIcon />
            </Button>
            <Button
              style={{
                border: "none",
                background: "none",
                color: !this?.props?.node?.expanded
                  ? "#1890ff"
                  : "rgb(254, 76, 74)",
              }}
              onClick={() => {
                if (
                  !this?.props?.node?.data?.subPli?.length &&
                  !this?.props?.node?.expanded
                ) {
                  message.warning("Please add sub PLI first.");
                } else {
                  this.props.node.setExpanded(!this?.props?.node?.expanded);
                  this.props.api.resetRowHeights();
                  this.props.refreshCells();
                }
              }}
            ></Button>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

// export default function ModifyRenderer(props) {
//   console.log("sfasgasgas", { props });
//   return <div>hiiiiiiii</div>;
// }
