import { Select } from "antd";

import React, { useState } from "react";
import { useRedux } from "../../../../../hooks/useRedux";

const { Option } = Select;

function SelectCellEditor(props) {
  const [state, setState] = useState({
    value: props?.data?.[props?.colDef?.field] ?? "none",
    visible: false,
    selectOpen: false,
  });
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [isWritable] = useRedux("estimationsIsWritable");

  const getValue = (props) => {
    return props.data[props.colDef.field];
  };
  const selectId = Math.random().toString();

  const handleSelectChange = (value) => {
    if (isWritable) {
      setState((prev) => ({ ...prev, value: value }));

      const gridDataTemp = _.cloneDeep(gridData);
      let items = getAllRows(props.api);
      console.log("itemsProps", props);
      for (let i = 0; i < items.length; i++) {
        if (items[i].id.toString() === props.node.id.toString()) {
          console.log(
            "items[i][props.colDef.field]",
            items[i][props.colDef.field],
            {
              value,
            }
          );
          items[i][props.colDef.field] = value;
          break;
        }
      }

      gridDataTemp[props.serviceIndex].serviceOptions[props.optionIndex][
        props.elevationIndex
      ].items = items;
      console.log("gridDataTemp", gridDataTemp);
      setTakeOffTableData(gridDataTemp);
      // props.updateStateAndSave(gridDataTemp);
      props.api.refreshCells({ force: true });
    } else {
      message.error("Please enable write mode to start editing");
    }
  };

  const getAllRows = (api) => {
    const items = [];
    api.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, selectOpen: false }));
  };
  const handleOpen = () => {
    setState((prev) => ({ ...prev, selectOpen: true }));
  };

  return (
    <div>
      {" "}
      <div>
        <Select
          disabled={!isWritable}
          id={selectId}
          className={
            props?.agGridTheme === "ag-theme-balham-dark"
              ? "dataEntryGrid-cell-select-DARK"
              : "dataEntryGrid-cell-select"
          }
          open={state.selectOpen}
          defaultValue={state.value}
          onChange={handleSelectChange}
          onBlur={handleClose}
          onFocus={handleOpen}
        >
          <Option value="none">Not Specified</Option>
          {props?.values?.map((v, i) => (
            <Option key={i} value={v}>
              {v}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default SelectCellEditor;
