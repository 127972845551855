import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const documentation = (
  IdHandler,
  programFields,
  updatedDynamicStates
) => {
  return {
    docType: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docType"
      );

      return {
        title: "Documentation Type",
        BodyComponent: "Pie",
        type: "Pie",
        id: IdHandler,
        BodyComponentParams: {
          table: "Documentation",
          field: "docType",
          labels: Object?.keys(data),
          datasets: [
            {
              label: "My First Dataset",
              data: Object?.values(data),

              backgroundColor: Object.keys(data).map(getRandomColor),
              borderWidth: 0,
            },
          ],
        },
        id: IdHandler,
        width: "390px",
      };
    }, //doctype

    docStatus: () => {
      const data = repeatedElementSum(
        updatedDynamicStates?.[IdHandler] || [],
        "docStatus"
      );

      return {
        title: "Documentation Status",
        BodyComponent: "Pie",
        type: "Pie",
        id: IdHandler,
        BodyComponentParams: {
          table: "Documentation",
          field: "docStatus",
          labels: Object?.keys(data),
          datasets: [
            {
              label: "My First Dataset",
              data: Object?.values(data),

              backgroundColor: Object.keys(data).map(getRandomColor),
              borderWidth: 0,
            },
          ],
        },
        width: "390px",
      };
    },
  }; //Documentation
};
