export const footerButtons = (
  onCancel,
  onSave,
  onOptionClick,
  suffix,
  saveButtonReversed
) => [
  {
    text: "Cancel",
    cancel: true,
    onClick: onCancel,
    className: "mondayButtonRed",
    tooltipCategory: "Fleets Drivers",
    tooltipKey: "cancel",
  },

  {
    text: saveButtonReversed ? "Save & Close" : "Finish",
    primary: true,
    onClick: onSave,
    options: [saveButtonReversed ? "Finish" : "Save & Close"],
    onOptionClick: onOptionClick,
    className: "mondayButtonBlue",
    suffix,
    tooltipCategory: "Fleets Drivers",
    tooltipKey: saveButtonReversed ? "save" : "finish",
  },
];
