import { Modal, InputNumber, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AccountingConfirmationModal from "../../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import { EditIcon } from "../../../../../../../../../../DynamicView/src";
import { useRedux } from "../../../../../../../../../../hooks";
import { handleSave } from "../../../../headerFunction";
import { updateRental } from "../../../../../../../../../../../../actions/rentals";
import { Checkbox } from "antd";
import "./ChangeTax.scss";
import { MondayButton } from "../../../../../../../../../../../commonComponents/index";
import { API } from "aws-amplify";
function ChangeTax() {
  const [visible, setVisible] = useState(false);
  const [fakeRentals] = useRedux("fakeRentals");
  const [newRentalTax, setNewRentalTax] = useState(0);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  console.log("fakeRentals2", { fakeRentals });
  const dispatch = useDispatch();

  const onChange = (e) => {
    if (e.target.checked) {
      setNewRentalTax(fakeRentals?.projectTaxRate || 0);
    } else {
      setNewRentalTax(0);
    }
  };

  const onConfirm = () => {
    let newRental = structuredClone(fakeRentals);
    if (newRental?.taxRate !== newRentalTax) {
      newRental.taxRate = newRentalTax;
      newRental?.services?.forEach((service) => {
        service?.serviceOptions?.forEach((sOption) => {
          sOption?.items?.forEach((item) => {
            item.taxRate = newRentalTax;
            item.taxAmount = newRentalTax * item.totalPrice;
            item.total = item.price + item.tax;
          });
        });
      });
      message.info("Saving...", 0);
      console.log("fakeRentals", fakeRentals);
      setConfirmationVisible(false);

      handleSave({ dispatch, updateRental, fakeRentals: newRental });
      message.destroy();
      message.success("Saved successfully", 1);
      setTimeout(() => {
        setVisible(false);
      }, 700);
    } else {
      message.info("No changes made");
    }
  };

  const getNewRentalTax = async () => {
    message.loading("Loading Tax Information...", 0);
    await API.get("projects", `/projects/${fakeRentals?.projectId}`)
      .then((r) => {
        console.log("myProject", r);
        if (r?.taxExempt.toLowerCase() !== "yes") {
          setNewRentalTax(r?.taxRate || 0);
        }
      })
      .finally(() => {
        setVisible(true);
        message.destroy();
      });
  };

  const onOpen = () => {
    getNewRentalTax();
  };

  useEffect(() => {
    setNewRentalTax(fakeRentals?.taxRate || 0);
  }, [fakeRentals]);

  return (
    <div>
      {/* <span
        onClick={() => setVisible(true)}
        style={{
          width: "32px",
          height: "32px",
          marginLeft: "0px",
          backgroundColor: "#fe4c4a",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        <EditIcon />
      </span> */}
      <MondayButton
        className="mondayButtonBlue"
        Icon={<EditIcon />}
        onClick={onOpen}
      >
        Edit Rental Tax
      </MondayButton>
      <Modal
        title={`Change Rental Tax`}
        wrapClassName="changeTaxModal"
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setConfirmationVisible(true)}
      >
        <div
          className="container-change-rental-tax"
          style={{
            display: "flex",
            gap: "0px",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={newRentalTax > 0 && !isNaN(newRentalTax)}
            onChange={onChange}
          >
            <strong style={{ color: "#fff" }}>Project Tax:</strong>
          </Checkbox>
          <InputNumber
            min={0}
            style={{
              color: "#fff",
            }}
            max={100}
            onChange={onChange}
            value={newRentalTax * 100}
            disabled
          />
        </div>
        {confirmationVisible && (
          <AccountingConfirmationModal
            {...{
              visible: confirmationVisible,
              setVisible: setConfirmationVisible,
              onConfirm: onConfirm,
              text: "This will change the tax rate for all the services in this project. This action cannot be undone.",
              title: "Are you ABSOLUTELY sure to change the tax rate? ",
              confirmButtonText: "Yes, change.",
              cancelButtonText: "Cancel",
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default ChangeTax;
