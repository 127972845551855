/**
@param {Array} childs - Array with objects to reursively find in
@param {String} keyToMatch - Key of element to check if its value is equal to valToMatch
@param {String} valToMatch - Value of element's keyToMatch
@param {String} recursiveKey - Key to resursive in
@param {String} keyToSave - Key to save in to array of recursion level
*/
//This function finds objects inside arrays, exmple: { title: "test", children: [{ title: "childtest", children: [{}] }] }
export const recursivelyFindObj = (
  childs,
  keyToMatch,
  valToMatch,
  recursiveKey,
  keyToSave
) => {
  if (!childs || !Array.isArray(childs)) {
    return null;
  }

  for (let i = 0; i < childs.length; i++) {
    const elemet = childs[i];
    if (elemet[keyToMatch] === valToMatch) {
      return { obj: elemet, keys: [elemet[keyToSave]] };
    }

    if (elemet[recursiveKey] && elemet[recursiveKey].length > 0) {
      const foundInChilds = recursivelyFindObj(
        elemet[recursiveKey],
        keyToMatch,
        valToMatch,
        recursiveKey,
        keyToSave
      );
      if (foundInChilds?.obj) {
        return {
          obj: foundInChilds.obj,
          keys: [elemet[keyToSave], ...foundInChilds.keys],
        };
      }
    }
  }

  return null;
};
