import { getRandomColor } from "../../../../utils";
import { uniq } from "lodash";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const scheduling = (IdHandler, programFields, updatedDynamicStates) => {
  return {
    typeOfWork: () => ({
      title: "Type Of Work",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Scheduling",
        field: "typeOfWork",
        labels: programFields
          ?.find((a) => a?.fieldName == "Scheduling Types")
          ?.fieldOptions?.map((a) => a.typeName),
        datasets: [
          {
            label: "My First Dataset",
            data: programFields
              ?.find((a) => a?.fieldName == "Scheduling Types")
              ?.fieldOptions?.map((a) => a.typeName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) => a.typeOfWork?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),

            backgroundColor: programFields
              ?.find((a) => a?.fieldName == "Scheduling Types")
              ?.fieldOptions?.map((a) => getRandomColor()),
            borderColor: "black",
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //typeOfWork

    projectExecutive: () => ({
      title: "Schedule Project Executive",
      BodyComponent: "Pie",
      type: "Pie",
      BodyComponentParams: {
        table: "Scheduling",
        field: "projectExecutive",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (a) => a.projectExecutive
          )
        ),
        datasets: [
          {
            label: "Scheduling",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectExecutive"
              )
            ),
            backgroundColor: (updatedDynamicStates?.[IdHandler] || [])?.map(
              (a) => getRandomColor()
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    projectManager: () => ({
      title: "Schedule Project Manager",
      BodyComponent: "Pie",
      type: "Pie",
      BodyComponentParams: {
        table: "Scheduling",
        field: "projectManager",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])
            ?.map((a) => a.projectManagers)
            .flat()
        ),
        datasets: [
          {
            label: "Scheduling",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectManagers"
              )
            ),
            backgroundColor: (updatedDynamicStates?.[IdHandler] || [])?.map(
              (a) => getRandomColor()
            ),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  }; //scheduling
};
