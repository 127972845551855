import { Button } from "antd";
import { Link } from "react-router-dom";
import { OdometerIcon } from "../../../../../../../assets";
import { formatNumber } from "../../../../../utils";

export const columnDefs = ({
  selectedItems,
  addItem,
  removeItem,
  isDarkMode,
}) => [
  {
    headerName: "Id",
    field: "defectId",
    link: (data) => `/defects/${data?.defectId}`,
    cellRenderer({ data }) {
      return (
        <Link
          style={{ color: "#1065A4", fontWeight: "500", fontSize: "0.7rem" }}
          to={{
            pathname: `/defects/${data?.defectId}`,
          }}
        >
          {data?.defectId}
        </Link>
      );
    },
  },
  {
    headerName: "Name",
    field: "defectName",
    cellRenderer({ data }) {
      return (
        <span style={{ fontSize: "1rem", fontWeight: "530" }}>
          {data?.defectName}
        </span>
      );
    },
  },
  {
    headerName: "Mileage",
    field: "odometerReading",
    cellRenderer({ data }) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <OdometerIcon fill="#acacca" height={16} width={16} />
          <span
            style={{
              color: isDarkMode ? "#acacca" : "#4d4d4e",
              fontSize: "1rem",
              fontWeight: "530",
            }}
          >
            {`at ${formatNumber(data?.odometerReading, {
              unit: "mile",
              fallback: "--",
              fixed: 0,
            })}`}
          </span>
        </div>
      );
    },
  },
  {
    headerName: "Priority",
    field: "defectPriority",
    cellRenderer({ data }) {
      return (
        <div
          className="priorityView"
          style={{
            backgroundColor:
              data?.defectPriority === "High" ? "#f34213" : "#B3D99B",
            borderRadius: "5px",
          }}
        >
          {`${data?.defectPriority} priority`}
        </div>
      );
    },
  },
  {
    headerName: "Action",
    cellRenderer({ data }) {
      let selected = selectedItems?.includes(data?.defectId);
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeContent: "center",
          }}
        >
          <Button
            className={`${selected ? "buttonSelected" : "buttonUnSelected"}`}
            onClick={() => {
              if (selected) {
                removeItem(data?.defectId);
              } else {
                addItem(data?.defectId);
              }
            }}
          >
            {selected ? "Remove" : "Add"}
          </Button>
        </div>
      );
    },
  },
];
