import { assignedToObject, projectManagerSelect, teamSelect } from "..";

import sortFields from "../sortFields";

/**
 * Generates an array of new fields based on the input fields and applies modifications to each field.
 *
 * @param {Array} teams - The array of teams.
 * @param {Array} projects - The array of projects.
 * @param {Array} accounts - The array of accounts.
 * @param {Array} inputFields - The array of input fields.
 * @param {boolean} isDarkMode - Indicates whether the dark mode is enabled.
 * @param {Array} projectManagers - The array of project managers.
 * @param {Array} projectExecutives - The array of project executives.
 * @param {boolean} globalDisabled - Indicates whether all fields are globally disabled.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {string|null} selectedMainField - The selected main field.
 * @param {boolean} allFieldsDisabled - Indicates whether all fields are disabled.
 * @returns {Array} The sorted array of new fields.
 */
const projectRequestFields = ({
  teams = [],
  projects = [],
  accounts = [],
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  projectExecutives = [],
  globalDisabled = false,
  userConfiguration = {},
  onTeamSelect = () => {},
  selectedMainField = null,
  allFieldsDisabled = false,
}) => {
  const newFields = [];

  console.log("projects", projects);
  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "jobsiteAddress") {
      Object.assign(field, {
        style: { minWidth: 170, width: 200 },

        rules: [
          {
            validator: (_, value) => {
              if (
                !!projects?.some(
                  (el) => el?.projectName?.trim() === value?.trim()
                )
              ) {
                return Promise.reject(new Error("Project already exists!"));
              }

              return Promise.resolve();
            },
          },
        ],
      });
    }

    if (formItemName === "projectExecutive") {
      delete field?.options;

      Object.assign(field, {
        options: (projectExecutives || [])?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
      });
    }

    if (formItemName === "companyName") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (
          accounts?.filter(
            (el) => el?.accountRecordType?.toLowerCase() !== "subcontractors"
          ) || []
        )?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
        style: { minWidth: 170, width: 200 },
        showSearch: true,
        allowClear: true,
      });
    }

    if (formItemName === "subcontractor") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (
          accounts?.filter(
            (el) => el?.accountRecordType?.toLowerCase() === "subcontractors"
          ) || []
        )?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
        showSearch: true,
        allowClear: true,
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Projects",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "projectExecutive") {
      delete field?.options;

      Object.assign(field, {
        options: (projectExecutives || [])?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
        disabled:
          !!allFieldsDisabled ||
          globalDisabled ||
          !!selectedMainField?.projectExecutive,
      });
    }

    if (formItemName === "client") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (accounts || [])?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),

        style: { minWidth: 170, width: 200 },
        showSearch: true,
        allowClear: true,
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default projectRequestFields;
