import uniq from "lodash/uniq";
import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const fleetInspections = ({ tables, programFields }) => {
  const inspectionStatuses = programFields?.find(
    (el) => el?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Inspections;

  return {
    inspectedBy: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"] || [],
        "inspectedBy"
      );

      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //inspectedBy
    driverName: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"]?.map((el) => el?.driverInfo) || [],
        "driver"
      );

      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //driverName
    createdAt: () => ({
      labels: months,
      data: (() => {
        let data = [];
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            tables?.["Fleet Inspections"]
              ?.map((a) => a?.createdAt)
              ?.filter(
                (b) =>
                  b >= getMonthsTimestamps()[i] &&
                  b <= getMonthsTimestamps("end")[i]
              )?.length,
          ];
        }
        return data;
      })(),
      backgroundColor: months?.map((a) => getRandomColor()),
    }), //createdAt

    fleetName: () => ({
      labels: Object.keys(
        repeatedElementSum(tables?.["Fleet Inspections"] || [], "fleetName")
      ),
      data: Object.values(
        repeatedElementSum(tables?.["Fleet Inspections"] || [], "fleetName")
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(tables?.["Fleet Inspections"] || [], "fleetName")
      )?.map(() => getRandomColor()),
    }), //fleetName

    inspectionStatus: () => ({
      labels: inspectionStatuses?.map((a) => a?.statusName),
      data: inspectionStatuses?.map(
        (status) =>
          tables?.["Fleet Inspections"]?.filter(
            (a) => a?.inspectionStatus === status?.statusName
          )?.length
      ),
      backgroundColor: inspectionStatuses?.map(
        (el) => el?.statusColor || getRandomColor()
      ),
    }), //inspectionStatus

    inspectionType: () => ({
      labels: Object.keys(
        repeatedElementSum(
          tables?.["Fleet Inspections"] || [],
          "inspectionType"
        )
      ),
      data: Object.values(
        repeatedElementSum(
          tables?.["Fleet Inspections"] || [],
          "inspectionType"
        )
      ),
      backgroundColor: Object.keys(
        repeatedElementSum(
          tables?.["Fleet Inspections"] || [],
          "inspectionType"
        )
      )?.map(() => getRandomColor()),
    }), //inspectionType

    mechanic: () => {
      const data = repeatedElementSum(
        tables?.["Fleet Inspections"] || [],
        "mechanic"
      );

      return {
        data: Object.keys(data),
        labels: Object.values(data),
        backgroundColor: Object.keys(data)?.map(() => getRandomColor()),
      };
    }, //mechanic
  }; //Inspections
};
