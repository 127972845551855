/**
 * Removes a day from the selected schedule.
 *
 * @param {string} id - The ID of the day to be removed.
 * @param {object} selectedSchedule - The selected schedule object.
 * @returns {object} - The updated selected schedule object with the specified day removed.
 */
function onRemoveDay(id = "", selectedSchedule = {}) {
  return {
    ...selectedSchedule,
    scheduleDays: selectedSchedule?.scheduleDays?.filter(
      ({ id: dayId }) => dayId !== id
    ),
  };
}

export default onRemoveDay;
