import { defaultExcelHeaderStyle } from "../../../../../../../helpers/constants/defaultExcelHeaderStyle";

export const tableHeaders = {
  route: ["Topic", "Read", "Write"],
  sidebar: ["Topic", "Read"],
};

export const excelRouteHeaders = [
  {
    v: "Topic",
    s: defaultExcelHeaderStyle,
  },
  {
    v: "Read",
    s: defaultExcelHeaderStyle,
  },
  {
    v: "Write",
    s: defaultExcelHeaderStyle,
  },
];

export const excelSidebarHeaders = [
  {
    v: "Topic",
    s: defaultExcelHeaderStyle,
  },
  {
    v: "Read",
    s: defaultExcelHeaderStyle,
  },
];
