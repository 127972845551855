import Swal from "sweetalert2";

const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again later.";
const DEFAULT_SUCCESS_MESSAGE = "Successfully completed.";
const DEFAULT_INFORMATION_MESSAGE = "Please confirm your action.";
const DEFAULT_WARNING_MESSAGE = "Are you sure you want to do this?";
const DEFAULT_QUESTION_MESSAGE = "Are you sure you want to do this?";

const DEFAULT_TIMER = 2000;

export const showSwalMessage = async (options) =>
  await Swal.fire({ ...options });

export const showSwalSuccessMessage = async ({
  text = DEFAULT_SUCCESS_MESSAGE,
  showConfirmButton = false,
  timer = DEFAULT_TIMER,
  ...rest
}) => {
  await showSwalMessage({
    text,
    icon: "success",
    showConfirmButton,
    timer,
    ...rest,
  });
};

export const showSwalErrorMessage = async ({
  text = DEFAULT_ERROR_MESSAGE,
  showConfirmButton = false,
  timer = DEFAULT_TIMER,
  ...rest
}) => {
  await showSwalMessage({
    text,
    icon: "error",
    showConfirmButton,
    timer,
    ...rest,
  });
};

export const showSwalInfoMessage = async ({
  text = DEFAULT_INFORMATION_MESSAGE,
  showConfirmButton = true,
  timer = DEFAULT_TIMER,
  ...rest
}) => {
  await showSwalMessage({
    text,
    icon: "info",
    showConfirmButton,
    timer,
    ...rest,
  });
};

export const showSwalWarningMessage = async ({
  text = DEFAULT_WARNING_MESSAGE,
  showConfirmButton = true,
  timer = DEFAULT_TIMER,
  ...rest
}) => {
  await showSwalMessage({
    text,
    icon: "warning",
    showConfirmButton,
    timer,
    ...rest,
  });
};

export const showSwalQuestionMessage = async ({
  text = DEFAULT_QUESTION_MESSAGE,
  showConfirmButton = true,
  timer = DEFAULT_TIMER,
  ...rest
}) => {
  await showSwalMessage({
    text,
    icon: "question",
    showConfirmButton,
    timer,
    ...rest,
  });
};
