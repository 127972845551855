import { AvatarIcon } from "../../../../../../../../../SidebarPages/Reports/assets"
import { SocketIcon } from "../../../assets"
import { CalendarIcon } from "../../../../../../../../../Header/subcomponents"
import dayjs from "dayjs"

function Content({ type, createdBy, createdAt }) {
  return (
    <div className="content">
      <div className="metadata">
        <SocketIcon />
        <div>
          Type <b>{type}</b>
        </div>

        <AvatarIcon />
        <div>
          Created by <b>{createdBy}</b>
        </div>

        <CalendarIcon />
        <div>
          Created at <b>{dayjs(createdAt).format("MM/DD/YYYY")}</b>
        </div>
      </div>
    </div>
  )
}

export default Content
