import { ScheduleFilled } from "@ant-design/icons";
import React from "react";
import { useState } from "react";
import { MondayButton } from "../../../../../../../../../../../../../commonComponents";
import { EditIcon } from "../../../../../../../../../../../../DynamicView/src";
import ProgressType from "./ProgressType";

function ProgressTypeWrapper({
  comesFromScheduling,
  elevationData,
  params,
  selectedService,
}) {
  const [clickedProgress, setClickedProgress] = useState({
    active: false,
  });
  return (
    <div>
      <MondayButton
        onClick={() =>
          setClickedProgress({
            active: !clickedProgress.active,
          })
        }
        Icon={comesFromScheduling === true ? <ScheduleFilled /> : <EditIcon />}
        className={
          comesFromScheduling ? "mondayButtonPrismarine" : "mondayButtonRed"
        }
      >
        {comesFromScheduling ? "Schedule" : "Manual"}
      </MondayButton>
      {!!clickedProgress.active && (
        <ProgressType
          {...{
            elevationData,
            params,
            selectedService,
          }}
        />
      )}
    </div>
  );
}

export default ProgressTypeWrapper;
