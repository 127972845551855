import { useState } from "react";
import { Form, message } from "antd";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import { FeePaymentIcon } from "../../../../../icons";
import { data } from "../../../Fleet/view/violationView/components/PieChart/PieChartData";
import "./ViolationPieChartEdit.scss";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import { XIcon } from "../../../Communication/assets";
import { validateForm } from "../../../Fleet/utils";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { useSelector } from "react-redux";
function ViolationPieChartEdit(props) {
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);
  const [initialData] = useState(structuredClone(props.params.rowData ?? []));
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const form = props.form;
  let { rowData } = props?.params || {};
  let {
    reduction_amount,
    fine_amount,
    interest_amount,
    penalty_amount,
    payment_amount,
    violation_status,
  } = rowData || {};

  function onCancel() {
    props.setEditMode(false);
    setCancelModalVisible(false);
  }

  function saveHandler() {
    setSaveDisable(false);
    let newBody = {
      reduction_amount: parseInt(form.getFieldValue("reduction_amount")),
      fine_amount: parseInt(form.getFieldValue("fine_amount")),
      interest_amount: parseInt(form.getFieldValue("interest_amount")),
      penalty_amount: parseInt(form.getFieldValue("penalty_amount")),
      payment_amount: parseInt(form.getFieldValue("payment_amount")),
    };

    let amount_due =
      parseInt(form.getFieldValue("fine_amount")) -
      parseInt(form.getFieldValue("payment_amount")) +
      parseInt(form.getFieldValue("interest_amount")) +
      parseInt(form.getFieldValue("penalty_amount")) -
      parseInt(form.getFieldValue("reduction_amount"));

    let bodyToSend = {
      ...newBody,
      amount_due: amount_due,
    };

    let newRowData = {
      ...rowData,
      ...bodyToSend,
    };
    let prevLog = {
      id: "violationPieChart",
      Card: "ViolationPieChart",
      title: "Violation Pie Chart",
      params: {
        rowData: initialData,
      },
    };

    let nextLog = {
      id: "violationPieChart",
      Card: "ViolationPieChart",
      title: "Violation Pie Chart",
      params: {
        rowData: newRowData,
      },
    };

    props.getEditedValue(bodyToSend, {}, {}, prevLog, nextLog);
    onCancel();
  }

  return (
    <div
      className={`violationPieChartEdit ${
        isDarkMode && "violationPieChartEditDark"
      }`}
    >
      <Form
        form={form}
        initialValues={{
          reduction_amount,
          fine_amount,
          interest_amount,
          penalty_amount,
          payment_amount,
        }}
      >
        <FeePaymentIcon className="feePaymentIcon" />
        <div className="amountFormDiv">
          {RenderDynamicComponents(data(form, violation_status))}
        </div>
        <div className="confirmContainer">
          <MondayButton
            onClick={() => {
              setCancelModalVisible(true);
            }}
            className="cancelButton mondayButtonRed"
            Icon={<XIcon />}
          >
            Cancel
          </MondayButton>
          <MondayButton
            onClick={() =>
              validateForm(
                form,
                saveHandler,
                () => {
                  //   message.error("Please fill the fields correctly");
                  //   setSaveDisable(true);
                },
                data(form, violation_status)
                  ?.filter((item) => item.disabled === false)
                  ?.map((item) => item.formItemName)
              )
            }
            className="confirmButton"
            Icon={<TickIcon />}
            disabled={saveDisable}
          >
            Confirm
          </MondayButton>
        </div>
      </Form>
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<TickIcon />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </div>
  );
}

export default ViolationPieChartEdit;
