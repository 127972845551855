import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const projects = (
  updatedDynamicStates,
  IdHandler,
  programFields,
  projectsData
) => {
  return {
    projectStatus: () => ({
      title: "Projects",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Projects",
        field: "projectStatus",
        title: "Status of Projects",
        subtitle: "",
        content: programFields
          ?.find((a) => a?.fieldName === "Project Status")
          ?.fieldOptions?.map(({ statusName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (a) => a?.projectStatus === statusName
              ).length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: statusName,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (a) => a?.projectStatus === statusName
            ).length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }), //projectStatus
    attorney: () => ({
      title: "Projects",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Projects",
        field: "attorney",
        title: "Projects Atorney",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "attorney"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //attorney
    expeditor: () => ({
      title: "Projects",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Projects",
        field: "expeditor",
        title: "Projects Attorney",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "expeditor"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }), //expeditor

    projectExecutive: () => ({
      title: "Projects",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Projects",
        field: "projectExecutive",
        title: "Projects Executive",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),

    projectManager: () => ({
      title: "Projects",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Projects",
        field: "projectManager",
        title: "Projects Manager",
        subtitle: "",
        content: Object.entries(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])
              ?.map((a) => a?.projectManager)
              ?.flat(),
            "nameOfUser"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  }; //Projects
};
