import { Badge, Button, Dropdown, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { PermitDrawingsWhite } from "../../../../../../../../../icons";
import { filterTables } from "../../../../../../../../../utils";
import DocTypesFilesModal from "./doc-types-modal/DocTypesFilesModal";
import { useEmailBox } from "../../../../providers/EmailBoxProvider";
import _ from "lodash";
import { useDocumentationTypes } from "../../../../providers/DocumentationTypesProvider";

import { useEmailTemplates } from "../../../../providers/EmailTemplatesProvider";

import "./documentation-types.scss";
import CustomDropdown from "../../../custom-dropdown/CustomDropdown";
import { useFilePreviewModal } from "../../../../providers/FilePreviewModalProvider";

function DocumentationTypes({ recordDetails }) {
  const { setSelectedFile, setFilePreview } = useFilePreviewModal();
  const [isDocTypesModalOpen, setIsDocTypesModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const { documentationTypes, setDocumentationTypes, documentationTypesFiles } =
    useDocumentationTypes();
  const { loading } = useEmailTemplates();

  const count = documentationTypesFiles?.length;

  useEffect(() => {
    filterTables(
      "documentation",
      "categoryName",
      recordDetails?.categoryType === "permitdrawings"
        ? "Permit-Drawings"
        : recordDetails?.categoryType === "accounts"
        ? "Clients"
        : recordDetails?.categoryType &&
          recordDetails?.categoryType?.charAt(0).toUpperCase() +
            recordDetails?.categoryType?.slice(1)
    )
      .then((res) => {
        return res
          .filter(({ recordId }) => recordId === recordDetails.recordId)
          .map(({ docType, folderId, googleDriveUploads }) => ({
            docType,
            folderId,
            googleDriveUploads,
          }));
      })
      .then(async (final) => {
        setDocumentationTypes(final);
      });
  }, []);

  const items = _.uniqBy(documentationTypes, "folderId").map(
    ({ docType, folderId, googleDriveUploads }) => ({
      key: folderId,
      label: (
        <Button
          type="link"
          className="dropdown-item-button"
          onClick={() => {
            setIsDocTypesModalOpen(true);
            setFiles(googleDriveUploads);
          }}
          data-testid="dropdown-item-label"
        >
          {docType}
        </Button>
      ),
    })
  );

  const toolTipData =
    documentationTypesFiles?.length > 0
      ? documentationTypesFiles?.map(({ name, blob, type, id }) => (
          <p
            key={id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedFile({
                src: blob,
                mimeType: type,
              });
              setFilePreview(true);
            }}
            data-testid={`drive-document-item-${id}`}
          >
            {name}
          </p>
        ))
      : "Documentation Types";

  if (loading)
    return (
      <div className="documentation-types-loading-container documentation-types-container">
        <Badge
          status="processing"
          className="documentation-types-loading-badge"
        />
        <PermitDrawingsWhite className="documentation-types-icon" />
      </div>
    );

  return (
    <div className="documentation-types-container">
      <Tooltip placement="left" title={toolTipData}>
        <Badge count={count}>
          <CustomDropdown items={items} placement="top" trigger={["click"]}>
            <a
              onClick={(e) => e.preventDefault()}
              data-testid="documentation-types-dropdown-trigger"
            >
              <PermitDrawingsWhite className="documentation-types-icon" />
            </a>
          </CustomDropdown>
        </Badge>
      </Tooltip>

      {isDocTypesModalOpen && (
        <DocTypesFilesModal
          isDocTypesModalOpen={isDocTypesModalOpen}
          setIsDocTypesModalOpen={setIsDocTypesModalOpen}
          files={files}
        />
      )}
    </div>
  );
}

export default DocumentationTypes;
