export const safety = ({ tables, programFields }) => {
  const safetyStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    safetyApplicationStatus: () => ({
      labels: safetyStatus?.map(({ statusName }) => statusName),
      data: safetyStatus?.map(
        (status) =>
          tables?.Safety?.filter(
            (a) => a?.safetyApplicationStatus === status?.statusName
          )?.length
      ),
      backgroundColor: safetyStatus?.map(({ statusColor }) => statusColor),
    }),
  };
};
