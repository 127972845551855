import { Form, Radio } from "antd";
import "./AccessRadioGroup.scss";

const renderRadio = ({ value, label, checked, onChange, disabled }) => {
  return (
    <Radio
      {...{
        key: label,
        checked,
        value,
        className: `radio-button-${value}`,
        onChange,
        disabled,
      }}
    >
      {label}
    </Radio>
  );
};

function AccessRadioGroup({
  title = "",
  onLabelClick = () => {},
  isDarkMode = false,
}) {
  let options = [
    { label: "None", value: "none" },
    { label: "Reader", value: "reader" },
    { label: "Writer", value: "writer" },
  ];
  return (
    <div
      className={`radio-group-wrapper drive-access-radio-group ${
        isDarkMode && "radio-group-wrapper-dark"
      }`}
    >
      <div className={`radio-group-label`}>
        <span onClick={onLabelClick}>{title}</span>
      </div>

      <Form.Item name={title} key={title}>
        <Radio.Group>{options.map(renderRadio)}</Radio.Group>
      </Form.Item>
    </div>
  );
}

export default AccessRadioGroup;
