import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
} from "react";
import "./ProjectsInfo.scss";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { useRedux } from "src/components/SidebarPages/hooks";
import { fetchData } from "src/components/SidebarPages/Fleet/utils";
import { generateProjects } from "src/components/SidebarPages/Projects/utils";
import { filterRowDataByTeamConfig } from "src/utils";
import { useSelector } from "react-redux";
import { currencyFormatter } from "src/components/SidebarPages/utils/currencyFormatter";
import { DynamicAvatars } from "src/components/commonComponents/DynamicAvatars/DynamicAvatars";
import EstimationAmountNoAPIcall from "src/components/SidebarPages/components/AgGridComponents/EstimatesAmmount/EstimationServiceTotal/EstimationAmountNoAPIcall";
import Services from "src/components/SidebarPages/components/AgGridComponents/Services";
import { Notes } from "src/components/commonComponents/Notes/Notes";
import { estimationServices } from "src/components/Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { Alert, Input } from "antd";
import { debounce } from "lodash";
import { fetchSearchRecords } from "../../../../../../utils";

function ProjectsInfo({ onSelectDataSrc, typeView = "projects" }) {
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);
  const [{ programFields }, { userConfiguration }, { isDarkMode }] =
    useSelector((state) => [
      state.programFields,
      state.userConfig,
      state.darkMode,
    ]);
  const [proposalFeedData, setProposalFeedData] = useRedux(
    "proposalFeedData"
    // {
    //   projects: [],
    //   opportunities: [],
    //   loading: false,
    // },
    // false
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridApi?.getSelectedRows();
    console.log("selectedRows", selectedRows);
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      // gridApi?.showLoadingOverlay();
      onSelectDataSrc({
        label:
          selectedRows?.[0]?.projectName || selectedRows?.[0]?.opportunityName,
        value:
          selectedRows?.[0]?.[
            typeView === "Opportunity" ? "opportunityId" : "projectId"
          ],
        key: selectedRows?.[0]?.[
          typeView === "Opportunity" ? "opportunityId" : "projectId"
        ],
        item: selectedRows?.[0],
      });
    }
  }, [gridApi]);

  const filterData = useCallback(
    async (e) => {
      gridApi.showLoadingOverlay();
      if (!!e?.target?.value && e?.target?.value !== "") {
        await fetchSearchRecords(
          `${typeView === "Opportunity" ? "opportunities" : "projects"}`,
          // "searchableProjectAddress", e.toLowerCase()
          `${
            typeView === "Opportunity" ? "opportunityAddress" : "projectAddress"
          }`,
          e.target.value
        )
          .then((res) => {
            console.log("resssss", res);
            let filtered =
              filterRowDataByTeamConfig(userConfiguration, res) || [];
            setRowData(filtered);
            if (filtered.length === 0) {
              gridApi.hideOverlay();
              gridApi.showNoRowsOverlay();
            } else {
              gridApi.hideOverlay();
            }
          })
          .finally(() => {
            // gridApi.hideOverlay();
          });
      } else {
        await fetchData(
          `${typeView === "Opportunity" ? "opportunities" : "projects"}`
        )
          .then((res) => {
            let allFiltered = filterRowDataByTeamConfig(
              userConfiguration,
              res?.projects
            );
            setRowData(allFiltered);
            if (allFiltered.length === 0) {
              gridApi.hideOverlay();
              gridApi.showNoRowsOverlay();
            } else {
              gridApi.hideOverlay();
            }
            // remove grid api filter
            gridApi.setQuickFilter("");
            gridApi.setFilterModel(null);
          })
          .finally(() => {
            // gridApi.hideOverlay();
          });
      }
    },
    [gridApi, typeView]
  );

  const onChangeSearch = useCallback(
    (e) => {
      const debouncedSearch = debounce((e) => {
        filterData(e);
      }, 300);
      debouncedSearch(e);
    },
    [gridApi]
  );

  console.log("ledjoTypeView", typeView);

  useEffect(() => {
    fetchData(
      `${typeView === "Opportunity" ? "opportunities" : "projects"}`
    ).then((projectsRes) => {
      console.log("projectsRes", projectsRes);
      setRowData(
        generateProjects({
          projects: filterRowDataByTeamConfig(
            userConfiguration,
            typeView === "Opportunity"
              ? projectsRes?.opportunities
              : projectsRes?.projects
          ),
        }) || []
      );
    });
  }, [proposalFeedData?.projects, typeView]);

  return (
    <div className="projectsInfoCont">
      {" "}
      <div
        className={`documentationsTable ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Alert message="Search..." type="info" showIcon />
          <Input placeholder="Search" onChange={onChangeSearch} />
        </div>

        <AgGridReact
          columnDefs={
            // projectInfoColumns({ view: true, navigate })
            typeView === "Opportunity"
              ? oppInfoColumns({ view: true })
              : projectInfoColumns({ view: true, navigate })
          }
          rowData={rowData}
          onSelectionChanged={onSelectionChanged}
          pagination={true}
          paginationPageSize={10}
          height="500px"
          onGridReady={({ api, columnApi }) => {
            setGridApi(api);
            // show the loading overlay
            api.showLoadingOverlay();
            //   setGridColumnApi(columnApi);
          }}
          //   defaultColDef={defaultColDef}
          //   sideBar={gridSidebar}
          rowGroupPanelShow="always"
          enableRowGroup={true}
        />
      </div>
    </div>
  );
}

export default ProjectsInfo;

const projectInfoColumns = ({ view, navigate }) => [
  {
    headerName: "Project Name",
    field: "projectName",
    checkboxSelection: true,
    minWidth: 400,
    resizable: true,
    cellRendererFrameworkParams: { view },
    valueGetter: ({ data }) => ({
      value: data?.projectName,
      link: `/projects/${data.projectId}`,
    }),
    cellRendererFramework: function (params) {
      try {
        return (
          <Link
            to={
              view
                ? {
                    pathname: `/projects/${params?.data?.projectId}`,
                  }
                : null
            }
            style={{ color: "#1065A4" }}
          >
            {params.value.value}
          </Link>
        );
      } catch (e) {
        if (params.value.value) {
          return params.value.value;
        } else {
          return null;
        }
      }
    },
    chartDataType: "series",
  },
  {
    headerName: "Account Name",
    field: "accountName",
    resizable: true,
    chartDataType: "category",
  },
  {
    headerName: "Project Status",
    field: "projectStatus",
    resizable: true,
    chartDataType: "excluded",
  },
  {
    headerName: "Project Executive",
    field: "projectExecutive",
    chartDataType: "category",
  },
  {
    headerName: "Estimates Amount",
    field: "price",
    cellRendererFramework: ({ data }) =>
      EstimationAmountNoAPIcall({ data, isOppEstimations: false }),
    chartDataType: "series",
    valueGetter: ({ data }) => currencyFormatter(data?.price, "$") || "",
    getQuickFilterText: () => "",
  },
  {
    headerName: "Labor Requirement",
    field: "laborRequirement",
    chartDataType: "excluded",
  },
  {
    headerName: "label",
    field: "label",
    chartDataType: "excluded",
  },
  {
    headerName: "Project Manager",
    field: "projectManagerValues",
    valueGetter: ({ data }) => {
      return Array.isArray(data?.projectManagerValues)
        ? data?.projectManagerValues?.map((el) => {
            return el;
          })
        : [];
    },
    cellRendererFramework: ({ data }) => {
      return (
        <DynamicAvatars
          {...{
            members: Array.isArray(data?.projectManagerValues)
              ? data?.projectManagerValues?.map((el) => {
                  return { nameOfUser: el };
                })
              : [],
          }}
        />
      );
    },
  },
  {
    headerName: "Multiple Addresses",
    field: "alternativeAddresses",
    valueFormatter: function (params) {
      try {
        if (params.data?.alternativeAddresses.length !== undefined) {
          return "(" + params.data.alternativeAddresses.length + ")";
        }
      } catch (e) {}
    },
    valueGetter: function (params) {
      try {
        if (params.data?.alternativeAddresses.length !== undefined) {
          return "(" + params.data.alternativeAddresses.length + ")";
        }
      } catch (e) {
        message.error(e);
      }
    },
    chartDataType: "excluded",
  },
  {
    headerName: "Services",
    field: "projectEstimations",
    showRowGroup: true,
    enableRowGroup: false,
    cellRendererFramework: ({ data = {} }) => {
      return Services({
        value: estimationServices(
          data?.projectEstimations || data?.services || {},
          "multiple"
        ),
        title: "Project Services",
      });
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        const data = value;
        let length = 0;

        for (const [key, value] of Object.entries(data)) {
          length += value.length;
        }

        return length;
      },
    },
    chartDataType: "excluded",
  },
  {
    headerName: "Project Notes",
    field: "projectNotes",
    filter: "agMultiColumnFilter",
    minWidth: 100,
    enableRowGroup: false,
    cellRendererFramework: (params) => {
      return Notes({
        noteModalName: params?.data?.projectName,
        rowId: params?.data?.projectId,
        topicCategory: "Projects",
        teamsConfiguration: params?.data?.teamsConfiguration,
      });
    },
    valueGetter: ({ data }) => {
      try {
        return data?.projectNotes?.map(({ comment }) =>
          comment?.replace(/<\/?[^>]+(>|$)/g, "")
        );
      } catch {}
    },
  },

  {
    headerName: "Tax Exempt",
    field: "taxExempt",
    chartDataType: "excluded",
  },
  {
    headerName: "Attorney",
    field: "attorney",
    // hide: true,
    chartDataType: "excluded",
  },
  {
    headerName: "Expeditor",
    field: "expeditor",
    // hide: true,
    chartDataType: "excluded",
  },
  {
    headerName: "Created At",
    field: "createdAt",
    // hide: true,
    sort: "desc",
    filterParams: { apply: true, newRowsAction: "keep" },
    showRowGroup: true,
    valueGetter: ({ data }) => {
      try {
        return data?.createdAt
          ? new Date(data?.createdAt).toLocaleDateString() !== "Invalid Date"
            ? new Date(data?.createdAt).toLocaleDateString()
            : ""
          : "";
      } catch (err) {
        return data?.createdAt;
      }
    },
    chartDataType: "excluded",
  },
  {
    headerName: "Team",
    field: "teamsConfiguration",
    enableRowGroup: false,
    cellRenderer({ value }) {
      const otherCondition = (variable) => {
        if (typeof variable !== "string") {
          return [];
        } else {
          return /[a-zA-Z]/.test(variable) ? [{ nameOfUser: variable }] : [];
        }
      };
      return (
        <DynamicAvatars
          {...{
            title: "Team",
            members: Array.isArray(value)
              ? value?.reduce((acc, { members }) => [...acc, ...members], [])
              : otherCondition(value),
          }}
        />
      );
    },
  },
  {
    headerName: "From Request",
    minWidth: 100,
    valueGetter: ({ data }) => {
      return !!data?.requestId ? "Yes" : "No";
    },
    cellStyle: { display: "flex", justifyContent: "center" },
    cellRendererFramework: ({ value, data }) => {
      return value === "Yes" ? (
        <div
          style={{
            backgroundColor: "#1065a4",
            borderRadius: "5px",
            color: "white",
            height: "25px",
            width: "50px",
            padding: "10px",
            lineHeight: "5px",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/approvals", {
              state: {
                requestId: data?.requestId,
                prevPath: location.pathname,
              },
            });
          }}
        >
          {value}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "grey",
            borderRadius: "5px",
            color: "black",
            height: "25px",
            padding: "10px",
            width: "50px",
            lineHeight: "5px",
            textAlign: "center",
            cursor: "disabled",
          }}
        >
          {value}
        </div>
      );
    },
  },
];

const oppInfoColumns = ({ view }) => [
  {
    headerName: "Opportunity Address",
    field: "opportunityAddress",
    resizable: true,
    checkboxSelection: true,
    width: 600,
    cellRendererFrameworkParams: { view },
    link: (data) => `/opportunities/${data.opportunityId}`,
    cellRendererFramework: function (params) {
      try {
        return (
          <Link
            to={
              view
                ? {
                    pathname: `/opportunities/${params.data.opportunityId}`,
                  }
                : null
            }
            style={{ color: "#1065A4" }}
          >
            {params.data?.opportunityAddress}
          </Link>
        );
      } catch (e) {
        if (params.data?.opportunityAddress) {
          return params.data?.opportunityAddress;
        } else {
          return null;
        }
      }
    },
    chartDataType: "category",
  },
  // {
  //   headerName: "View take off",
  //   resizable: true,
  //   chartDataType: "category",
  //   cellRendererFramework: ({ data = {} }) => {
  //     let services = {};
  //     data?.estimations?.forEach((estimation) => {
  //       services[`${estimation}`] = [];
  //     });
  //     return (
  //       <TakeOffListWrapper
  //         data={{
  //           ...data,
  //           services,
  //           linkTo: `${data?.estimationId}`,
  //         }}
  //       />
  //     );
  //   },
  // },
  {
    headerName: "Opportunity Name",
    field: "opportunityName",
    chartDataType: "excluded",
  },
  {
    headerName: "Account Name",
    field: "accountName",
    resizable: true,
    chartDataType: "category",
  },
  {
    headerName: "Project Executive",
    field: "projectExecutive",
    resizable: true,
    chartDataType: "category",
  },
  {
    headerName: "Labor Requirement",
    field: "laborRequirement",
    resizable: true,
    chartDataType: "excluded",
  },
  {
    headerName: "Tax Exempt",
    field: "taxExempt",
    chartDataType: "excluded",
  },
  {
    headerName: "Change Order",
    field: "isChangeOrder",
    resizable: true,
    valueGetter: ({ data }) => {
      return data?.isChangeOrder === true ? "Yes" : "No";
    },
    chartDataType: "excluded",
  },

  {
    headerName: "Opportunity Type",
    field: "opportunityType",
    chartDataType: "excluded",
  },
  {
    headerName: "Opportunity Status",
    field: "opportunityStatus",
  },

  {
    headerName: "Team",
    field: "teamsConfiguration",
    enableRowGroup: false,
    pdfExportOptions: {
      skipColumn: true,
    },
    cellRenderer({ value, data }) {
      console.log("value", { value, data });
      const otherCondition = (variable) => {
        if (typeof variable !== "string") {
          return [];
        } else {
          return /[a-zA-Z]/.test(variable) ? [{ nameOfUser: variable }] : [];
        }
      };
      return (
        <DynamicAvatars
          {...{
            title: `${data?.opportunityName} - Team`,
            members: Array.isArray(value)
              ? value?.reduce((acc, { members }) => [...acc, ...members], [])
              : otherCondition(value),
            // fetchPhoto: false,
          }}
        />
      );
    },
  },
  {
    headerName: "Estimates Amount",
    filter: "agTextColumnFilter",
    field: "estimates",
    sortable: true,
    cellRendererFramework: ({ data }) =>
      EstimationAmountNoAPIcall({ data, isOppEstimations: true }),
    minWidth: 205,
    chartDataType: "series",
    enableRowGroup: false,
  },
];
