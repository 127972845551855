import {
  useState,
  useMemo,
  useContext,
  forwardRef,
  useLayoutEffect,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import dayjs from "dayjs";

import { camelToTitle, hexToRgba } from "src/utils";
import {
  saveLocalPreferences,
  loadLivePreference,
  FleetsLiveContext,
} from "../../../../utils";
import { getRandomColor } from "src/components/Header/forms/Scheduling/helpers/creators";
import { LayoutCard, DarkHandle } from "./components";

import "./ReportsGridLayout.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const LayoutBody = WidthProvider(Responsive);

const ReportsGridLayout = forwardRef((_, ref) => {
  const { analytics, totals } = useContext(FleetsLiveContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [breakpoints] = useState({
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  });
  const [currentBreakpoint, setCurrentBreakpoint] = useState(calcBreakpoint());
  const [layout, setLayout] = useState(loadLivePreference("liveReportsLayout"));
  const [vehicleFilter, setVehicleFilter] = useState(
    loadLivePreference("liveReportsVehicleFilter")
  );

  const cards = useMemo(() => {
    return layout?.map(({ static: s, i, ...rest }) => {
      return (
        <LayoutCard
          className={s ? "static" : ""}
          key={i}
          cardKey={i}
          title={i}
          {...rest}
        />
      );
    });
  }, [layout]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getLayout() {
          return layout;
        },
        changeVehicleFilter(vehicle) {
          if (vehicle === vehicleFilter) {
            return;
          }
          setVehicleFilter(vehicle);
          saveLocalPreferences({ liveReportsVehicleFilter: vehicle });
        },
      };
    },
    [layout, vehicleFilter]
  );

  useLayoutEffect(() => {
    if (!Object.keys(analytics)?.length) {
      sessionStorage.setItem("liveReportsLayout", "[]");
      setLayout([]);
      return;
    }

    let newLayout = [];
    let allDates = Object.keys(totals || {}).sort(
      (a, b) =>
        dayjs(a, "MM/DD/YYYY").valueOf() - dayjs(b, "MM/DD/YYYY").valueOf()
    );
    let allTitles = Object.keys(totals[allDates?.[0]] || {});

    let defaultHeight = 13;
    let defaultWidth = 4;
    let x = 0,
      y = 0;

    for (const tt of allTitles) {
      let labels = [];
      let data = [];
      let backgroundColor = [];
      let borderColor = [];

      for (const date of allDates) {
        let step = date.replace(/\/\d{4,}/g, "");
        labels.push(step);

        if (!vehicleFilter) {
          data.push(Number(totals[date][tt].toFixed(2)));
        } else {
          data.push(Number(analytics[vehicleFilter][date][tt].toFixed(2)));
        }

        let color = getRandomColor();
        backgroundColor.push(hexToRgba(color, 0.4));
        borderColor.push(color);
      }

      let title = camelToTitle(tt);
      if (title?.includes("Duration")) {
        title = title + " (in hours)";
        data = data.map((e) => Number((e / 3600000).toFixed(2)));
      } else if (title?.includes("Distance")) {
        title = title + " (in miles)";
      }

      let tmpData = {
        title,
        chartData: {
          labels,
          datasets: [
            {
              label: title,
              data,
              backgroundColor,
              borderColor,
              borderWidth: 2,
            },
          ],
        },
      };

      let existingIndex = layout?.findIndex(({ title: t }) => title === t);
      if (existingIndex > -1) {
        tmpData = {
          ...layout[existingIndex],
          ...tmpData,
        };
      } else {
        let l = newLayout?.length;
        if (l && l % 3 === 0) {
          x = 0;
          y += defaultHeight;
        }
        tmpData = {
          ...tmpData,
          ...{
            i: title,
            x,
            y,
            h: defaultHeight,
            w: defaultWidth,
            minW: 3,
            minH: 10,
          },
        };
        x += defaultWidth;
      }
      newLayout.push(tmpData);
    }

    updatePreferenceHandler(newLayout);
  }, [analytics, totals, vehicleFilter]);

  function calcBreakpoint() {
    let r = "lg";
    let w =
      (document.querySelector(".live-reports-view-container") || document.body)
        ?.clientWidth || null;

    let br = Object.keys(breakpoints).sort(
      (a, b) => breakpoints[a] - breakpoints[b]
    );

    for (const b of br) {
      if (breakpoints[b] >= w) {
        r = b;
        break;
      }
    }

    return r;
  }

  function updatePreferenceHandler(e) {
    if (JSON.stringify(layout) !== JSON.stringify(e)) {
      let newLayout = [];
      for (const l of e) {
        let existingIndex = layout?.findIndex(({ i }) => i === l.i);
        if (existingIndex > -1) {
          newLayout.push({
            ...layout[existingIndex],
            ...l,
          });
        } else {
          newLayout.push(l);
        }
      }

      saveLocalPreferences({
        liveReportsLayout: newLayout,
      });
      setLayout(newLayout);
    }
  }

  return (
    <div
      className={`reports-view-body${isDarkMode ? " reports-body-dark" : ""}`}
    >
      <LayoutBody
        {...{
          layouts: {
            [currentBreakpoint]: layout,
          },
          id: "live-cards-layout",
          className: "layout",
          allowOverlap: false,
          breakpoints,
          cols: { lg: 12, md: 12, sm: 8, xs: 4, xxs: 4 },
          rowHeight: 30,
          isBounded: false,
          margin: [10, 10],
          isDraggable: true,
          isResizable: true,
          currentBreakpoint,
          resizeHandles: ["sw", "se"],
          useCSSTransforms: true,
          onBreakpointChange: setCurrentBreakpoint,
          onLayoutChange: updatePreferenceHandler,
          draggableHandle: ".card-header",
          resizeHandle: !isDarkMode
            ? undefined
            : (props, ref) => {
                return <DarkHandle {...props} innerRef={ref} />;
              },
        }}
      >
        {cards}
      </LayoutBody>
    </div>
  );
});

export default ReportsGridLayout;
