import { useEffect } from "react";

// Define a custom hook called "useBeforeUnload" that takes an itemId argument
export const useBeforeUnload = (itemId) => {
  // Use the useEffect hook to add and remove the beforeunload event listener
  useEffect(() => {
    // Define the handleBeforeUnload function that will be called when the beforeunload event is fired
    function handleBeforeUnload(e) {
      e.returnValue = "Are you sure you want to leave this page?";

      // Remove the item with the with tabCloseKey and  itemId from local storage
      if (localStorage.getItem("tabCloseKey") === itemId) {
        localStorage.removeItem("tabCloseKey");
      }
    }

    function handleOnUnload(e) {
      // Remove the item with the specified itemId from local storage
      localStorage.removeItem(itemId);
    }

    // Add the unload event listener to the window object
    window.addEventListener("unload", handleOnUnload);

    // Add the beforeunload event listener to the window object
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Return a function that will remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("unload", handleOnUnload);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [itemId]); // The useEffect hook will only be re-run when the itemId argument changes
};
