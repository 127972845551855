import { useState } from "react";
import { RetainageIcon } from "../../../../../../../../../../../../icons/index";
import { MondayButton } from "../../../../../../../../../../../commonComponents/index";
import { NormalSizedModal } from "../../../../../../../../../../Fleet/components/index";
import { InputNumber } from "antd";
import { CloseIconModal } from "../../../../../../../../../../../../assets/index";
import { SaveIcon } from "../../../../../../../../../../BasePage/src/index";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import "./ChangeRentalRetainage.scss";
import AmountReq from "../../../../Service/ServiceContent/GridData/GridUtils/amountFactory";
function ChangeRentalRetainage({ rentalNumber = "" }) {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState();
  const { darkMode, fakeApplication, isWritable } = useStateContext();
  const dispatchContext = useDispatchContext();

  const cleanUp = () => {
    // setValue(0);
    setVisible(false);
  };

  const setApplication = (newApplication) => {
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
    dispatchContext({
      type: "SET_UNCHANGING_FAKE_APPLICATION",
      payload: newApplication,
    });
  };

  const startOffModal = () => {
    setVisible(true);
    //TODO: Maybe in the future display more information
    // However this is not needed for now because
    // Services can have different retaianage percentage (so unfortunate!!!)
    // let ACC_RENTALS = fakeApplication?.accumulatedRentals || {};
    // if (Object.keys(ACC_RENTALS).length !== 0) {
    //   for (const appNo in ACC_RENTALS) {
    //     if (Object.keys(ACC_RENTALS[appNo]).length !== 0) {
    //       for (const rentalNo in ACC_RENTALS[appNo]) {
    //         if (rentalNo.toString() === rentalNumber.toString()) {
    //           // now we check services!

    //         }
    //       }
    //     }
    //   }
    // }
  };

  const changeRentalRet = (applyToAll) => {
    // console.log("value", value);
    // const newApplication = structuredClone(fakeApplication);
    // newApplication.totalities["prevRentalsRetainage"] = {
    //   ...(newApplication.totalities["prevRentalsRetainage"] || {}),
    //   [`Rental${rentalNumber}`]: value,
    // };
    // setApplication(newApplication);
    if (!applyToAll) {
      let myAmount = new AmountReq({
        rentalNumber,
        application: fakeApplication,
      });
      const newApplication = structuredClone(fakeApplication);
      newApplication.accumulatedRentals = myAmount.applyRentalRetaiange(value);

      setApplication(newApplication);
    } else {
      let allRentals = fakeApplication?.accumulatedRentals || {};
      console.log("allRentals", allRentals);
      let rentalNos = [];
      if (Object.keys(allRentals).length !== 0) {
        Object.keys(allRentals).forEach((appNo) => {
          let appRentals = allRentals[appNo];
          if (Object.keys(appRentals).length !== 0) {
            Object.keys(appRentals).forEach((rentalNo) => {
              if (!rentalNos.includes(rentalNo)) {
                rentalNos.push(rentalNo);
              }
            });
          }
        });
      }
      rentalNos = rentalNos.map((rentalNo) => {
        return new AmountReq({
          rentalNumber: rentalNo,
          application: fakeApplication,
        });
      });
      const newApplication = structuredClone(fakeApplication);

      rentalNos.forEach((rental) => {
        newApplication.accumulatedRentals = rental.applyRentalRetaiange(value);
      });
      setApplication(newApplication);

      console.log("changeRentalRet", rentalNos);
    }
    cleanUp();
  };
  return (
    <div className={darkMode ? "changeRentalRetDark" : "changeRentalRetLight"}>
      {" "}
      <MondayButton
        className="mondayButtonBlue"
        Icon={<RetainageIcon />}
        onClick={startOffModal}
      >
        Change Rental Retainage{" "}
      </MondayButton>
      <NormalSizedModal
        {...{
          visible,
          setVisible,
          title: `Change Rental Retainage for Rental ${rentalNumber}`,
          centered: true,
          className: darkMode ? "changeRentalRetDark" : null,
          footerModal: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                width: "100%",
              }}
            >
              <MondayButton
                className="mondayButtonRed"
                Icon={<CloseIconModal />}
                onClick={cleanUp}
              >
                Cancel
              </MondayButton>{" "}
              <MondayButton
                Icon={<SaveIcon />}
                onClick={() => changeRentalRet(false)}
              >
                Apply to only this Rental
              </MondayButton>{" "}
              <MondayButton
                Icon={<SaveIcon />}
                className="mondayButtonBlue"
                onClick={() => changeRentalRet(true)}
              >
                Apply To All
              </MondayButton>
            </div>
          ),
        }}
      >
        <div
          style={{
            width: "400px",
            height: "200px",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              //   justifyContent: "center",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div>Change Retainage To:</div>
            <InputNumber defaultValue={value} onChange={(e) => setValue(e)} />
          </div>
        </div>
      </NormalSizedModal>
    </div>
  );
}

export default ChangeRentalRetainage;
