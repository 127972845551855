import utf8 from "utf8";
import { encodedUtf8ToByteArray } from "./utils";
import {
  URL_FILES,
  CONTENT_TYPE_JSON,
  URL_UPLOAD,
  MIME_FOLDER,
  _CREATE_HEADERS,
  _CREATE_QUERY_PARAMS,
} from "./driveStatics";
import { blobToBase64 } from "../../components/SidebarPages/utils/blobToBase64";

const driveApi = ({ accessToken, boundary = "" }) => {
  const params = { boundary: boundary || "foo_bar_baz" };

  const uploadExcelFile = async (blob, mediaType, metadata) => {
    try {
      const base64Data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

      const body =
        `--${params.boundary}\n` +
        `Content-Type: ${CONTENT_TYPE_JSON}\n\n` +
        `${JSON.stringify(metadata)}\n\n--${params.boundary}\n` +
        `Content-Transfer-Encoding: base64\n` +
        `Content-Type: ${mediaType}\n\n` +
        `${base64Data}\n--${params.boundary}--`;

      const response = await fetch(`${URL_UPLOAD}?uploadType=multipart`, {
        method: "POST",
        headers: _CREATE_HEADERS(
          accessToken,
          `multipart/related; boundary=${params.boundary}`,
          body.length
        ),
        body,
      });

      return response;
    } catch (error) {
      throw new Error(`Error uploading file: ${error.message}`);
    }
  };

  const uploadFile = (media, mediaType, metadata, isBase64) => {
    const ddb = `--${params.boundary}`;
    const ending = `\n${ddb}--`;

    let body =
      `\n${ddb}\n` +
      `Content-Type: ${CONTENT_TYPE_JSON}\n\n` +
      `${JSON.stringify(metadata)}\n\n${ddb}\n` +
      (isBase64 ? "Content-Transfer-Encoding: base64\n" : "") +
      `Content-Type: ${mediaType}\n\n`;

    if (typeof media === "string") {
      body += `${media}${ending}`;
    } else {
      body = new Uint8Array(
        encodedUtf8ToByteArray(utf8.encode(body))
          .concat(media)
          .concat(encodedUtf8ToByteArray(utf8.encode(ending)))
      );
    }

    return fetch(`${URL_UPLOAD}?uploadType=multipart`, {
      method: "POST",
      headers: _CREATE_HEADERS(
        accessToken,
        `multipart/related; boundary=${params.boundary}`,
        body.length
      ),
      body,
    });
  };

  const deleteDriveItem = (fileId) => {
    if (fileId)
      return fetch(`${URL_FILES}/${fileId}`, {
        method: "DELETE",
        headers: _CREATE_HEADERS(accessToken),
      });
  };

  const list = (queryParams) => {
    if (queryParams)
      return fetch(`${URL_FILES}${_CREATE_QUERY_PARAMS(queryParams)}`, {
        headers: _CREATE_HEADERS(accessToken),
      });
  };
  /**
   *
   * @param {String} fileId id of file to change the permission
   */
  const share = async (
    fileId,
    role = "writer",
    type = "anyone",
    emailAddress = "",
    /**
     *added @dontSendEmail {boolean} to stop e-mail notification
     */
    dontSendEmail = false
  ) => {
    if (fileId)
      if (dontSendEmail) {
        return await fetch(
          `${URL_FILES}/${fileId}/permissions?sendNotificationEmail=false`,
          {
            method: "POST",
            headers: _CREATE_HEADERS(accessToken, "application/json"),
            body: JSON.stringify({
              role,
              type,
              emailAddress,
              transferOwnership: "false",
              withLink: true,
            }),
          }
        );
      } else {
        return await fetch(`${URL_FILES}/${fileId}/permissions`, {
          method: "POST",
          headers: _CREATE_HEADERS(accessToken, "application/json"),
          body: JSON.stringify(
            emailAddress === ""
              ? {
                  role,
                  type,
                  transferOwnership: "false",
                  withLink: true,
                }
              : {
                  role,
                  type,
                  emailAddress,
                  transferOwnership: "false",
                  withLink: true,
                }
          ),
        });
      }
  };
  const updatePermission = async (fileId, role = "writer", permissionId) => {
    if (fileId && permissionId)
      return await fetch(`${URL_FILES}/${fileId}/permissions/${permissionId}`, {
        method: "PATCH",
        headers: _CREATE_HEADERS(accessToken, "application/json"),
        body: JSON.stringify({
          role,
        }),
      });
  };

  const deletePermission = async (fileId, permissionId) => {
    if (fileId && permissionId)
      return await fetch(`${URL_FILES}/${fileId}/permissions/${permissionId}`, {
        method: "DELETE",
        headers: _CREATE_HEADERS(accessToken, "application/json"),
      });
  };

  const permissionList = async (fileId) => {
    if (fileId)
      return await fetch(`${URL_FILES}/${fileId}?fields=permissions`, {
        method: "GET",
        headers: _CREATE_HEADERS(accessToken, "application/json"),
      });
  };

  const setFilePermission = async (fileId, permission) => {
    return fetch(`${URL_FILES}/${fileId}/permissions`, {
      method: "POST",
      headers: _CREATE_HEADERS(accessToken, "application/json"),
      body: JSON.stringify(permission),
    }).then((res) => res.json());
  };

  const getFilesByFolderId = async (folderId) =>
    await list({
      q: `'${folderId}' in parents`,
      fields:
        "files(id,name,mimeType,size,kind,permissions,webViewLink,thumbnailLink)",
    });

  const getFilesThumbnails = async (folderId) => {
    return await list({
      q: `'${folderId}' in parents`,
      fields: "files(id,thumbnailLink,name,mimeType,webViewLink,size)",
    });
  };
  const getFileThumbnail = async (folderId) => {
    if (folderId)
      return fetch(
        `https://www.googleapis.com/drive/v3/files/${folderId}?fields=thumbnailLink`,
        {
          method: "GET",
          headers: _CREATE_HEADERS(accessToken, "application/json"),
        }
      );
  };

  const getDriveItemId = async ({
    name,
    parents,
    mimeType,
    trashed = false,
  }) => {
    const queryParams = { name, trashed };

    if (mimeType) {
      queryParams.mimeType = mimeType;
    }

    let result = await list({
      q:
        _CREATE_QUERY_PARAMS(queryParams, "", " and ", true) +
        ` and '${parents[0]}' in parents`,
    });

    if (!result.ok) {
      throw result;
    }

    const file = (await result.json()).files[0];

    return file ? file.id : file;
  };

  const getFolderIdOrCreate = async ({ name, parents }) => {
    const mimeType = MIME_FOLDER;
    const formattedName =
      name?.replace(/[`~!@#$%^&*()_|+\=?.<>\{\}\[\]\\\/]/gim, "-") ||
      name ||
      "";
    let id = await getDriveItemId({ name: formattedName, parents, mimeType });

    if (!id) {
      const body = JSON.stringify({ name: formattedName, parents, mimeType });

      let result = await fetch(URL_FILES, {
        method: "POST",
        headers: _CREATE_HEADERS(accessToken, CONTENT_TYPE_JSON, body.length),
        body,
      });

      if (!result.ok) {
        throw result;
      }

      id = (await result.json()).id;
    }
    return id;
  };

  const createFolders = async ({ folderNames = [], parents }) => {
    const folders = await Promise.all(
      folderNames.map(async (folderName) => ({
        folderName,
        folderId: await getFolderIdOrCreate({ name: folderName, parents }),
      }))
    );

    return folders?.reduce(
      (acc, { folderName, folderId }) => ({ ...acc, [folderName]: folderId }),
      {}
    );
  };

  const getDriveItem = (fileId, queryParams = {}) => {
    if (fileId && !!accessToken) {
      const parameters = _CREATE_QUERY_PARAMS(queryParams);

      return fetch(`${URL_FILES}/${fileId}${parameters}`, {
        headers: _CREATE_HEADERS(accessToken),
      });
    }
  };

  const updateDriveItem = (fileId, queryParams) => {
    let resource = queryParams.resource;
    delete queryParams.resource;

    let config = {
      headers: _CREATE_HEADERS(accessToken, "application/json"),
      method: "PATCH",
    };

    if (resource) {
      config.body = JSON.stringify(resource);
    }
    if (fileId)
      return fetch(
        `${URL_FILES}/${fileId}${_CREATE_QUERY_PARAMS(queryParams)}`,
        config
      );
  };

  const copyFileToNewParent = async (fileId, newParentId) => {
    try {
      // Check if the file exists in Drive
      const fileResponse = await fetch(`${URL_FILES}/${fileId}`, {
        method: "GET",
        headers: _CREATE_HEADERS(accessToken),
      });

      if (!fileResponse.ok) {
        throw new Error("File not found in Drive");
      }

      // Copy the file to the new parent
      const copyResponse = await fetch(`${URL_FILES}/${fileId}/copy`, {
        method: "POST",
        headers: _CREATE_HEADERS(accessToken, "application/json"),
        body: JSON.stringify({
          parents: [newParentId],
        }),
      });

      if (!copyResponse.ok) {
        throw new Error("Error copying file to new parent");
      }

      const copyData = await copyResponse.json();
      const newFileId = copyData.id;
      //return new file id
      return newFileId;
    } catch (error) {
      throw new Error(`Error copying file to new parent: ${error.message}`);
    }
  };

  const getImageSrc = async (fileId) => {
    let src, blob;

    await getDriveItem(fileId, { alt: "media" })
      .then((r) => r.blob())
      .then(async (data) => {
        blob = URL.createObjectURL(data);
        await blobToBase64(data).then((res) => (src = res));
      })
      .catch((e) => console.error("e", e));

    return { src, blob };
  };

  const updateFolderName = async (folderId, newName) => {
    const queryParams = {
      resource: {
        name: newName,
      },
    };
    return await updateDriveItem(folderId, queryParams);
  };

  const getParents = (fileId) => {
    if (fileId) {
      return fetch(`${URL_FILES}/${fileId}?fields=parents`, {
        headers: _CREATE_HEADERS(accessToken),
      });
    }
  };

  const getFolderName = (fileId) => {
    if (fileId) {
      return fetch(`${URL_FILES}/${fileId}?fields=name,createdTime`, {
        headers: _CREATE_HEADERS(accessToken),
      });
    }
  };

  const getFileSize = async (fileId) => {
    try {
      const response = await fetch(`${URL_FILES}/${fileId}?fields=size`, {
        headers: _CREATE_HEADERS(accessToken),
      });
      const data = await response.json();
      return data.size;
    } catch (error) {
      throw new Error(`Error getting file size: ${error.message}`);
    }
  };

  /**
   *
   * @param {String} itemId string of the file that we want to delete
   * @returns {Promise}  containing the deleteDriveItem function, this promise is used when we want to delete multiple files
   */
  const deleteDriveItemPromise = async (itemId) => {
    return await new Promise(
      async (resolve, reject) =>
        await deleteDriveItem(itemId).then(resolve, reject)
    );
  };

  /**
   *
   * @param {String} fileId - id of the file to be updated
   * @param {String} newParentId - id of the new parent folder
   * @param {String} oldParentId - id of the old parent folder
   * @returns {Promise} - containing the moveDriveItem function, this promise is used when we want to move multiple files
   */
  const moveDriveItemPromise = async ({ fileId, newParentId, oldParentId }) => {
    return new Promise(async (resolve, reject) => {
      return await updateDriveItem(fileId, {
        addParents: newParentId,
        removeParents: oldParentId,
      })
        .then(resolve)
        .catch(() => {
          reject(fileId);
        });
    });
  };

  return {
    getFolderName,
    getParents,
    createFolders,
    uploadFile,
    deleteDriveItem,
    list,
    getFolderIdOrCreate,
    getFilesByFolderId,
    getDriveItem,
    getDriveItemId,
    updateDriveItem,
    getImageSrc,
    share,
    deletePermission,
    updatePermission,
    permissionList,
    getFilesThumbnails,
    accessToken,
    getFileThumbnail,
    updateFolderName,
    uploadExcelFile,
    setFilePermission,
    getFileSize,
    copyFileToNewParent,
    deleteDriveItemPromise,
    moveDriveItemPromise,
  };
};

export default driveApi;
