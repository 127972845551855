export const onRowSelected = (el) => {
	el.node.updateData(
		el.node.selected
			? {
					...el.data,
					// applyAmount: parseFloat(
					//   (el.data.chargeAmount || 0) - (el.data.appliedAmount || 0)
					// ),
			  }
			: {
					...el.data,
					// remainingAmount: parseFloat(
					//   (el.data.chargeAmount || 0) - (el.data.appliedAmount || 0)
					// ),
					// applyAmount: 0,
			  }
	);
	el.api.refreshCells({ force: true });
};
