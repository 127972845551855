import { Badge } from "antd";
import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { MondayButton } from "../../../../../commonComponents";
import TableHoistCalcModal from "./TableHoistCalc/TableHoistCalcModal";

function TableHoistPriceCalculator({
  indexes = {},
  service = {},
  updateStateAndSave,
}) {
  const [showModal, setShowModal] = useState(false);
  const { serviceIndex = 0, optionIndex = 0, elevationIndex = 0 } = indexes;
  let currentElevation =
    service?.serviceOptions[optionIndex]?.[elevationIndex] || 0;
  console.log("currentElevation", {
    t: currentElevation,
    indexes,
    autoScheme: currentElevation?.autoScheme,
  });

  const onShowModal = (e) => {
    // Swal.fire({
    //   title: "Coming Soon",
    //   text: "This feature is not available yet",
    //   icon: "warning",
    // });
    // return;
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div>
      {" "}
      <Badge.Ribbon
        text={
          !currentElevation?.autoScheme
            ? "NO CONFIG"
            : currentElevation?.autoScheme?.isDraft
            ? "DRAFT"
            : "APPROVED"
        }
        color={
          !currentElevation?.autoScheme
            ? "red"
            : currentElevation?.autoScheme?.isDraft
            ? "#FF7F3E"
            : "#40A578"
        }
        style={{
          marginTop: "-25px",
        }}
      >
        <MondayButton
          hasIcon={false}
          className="mondayButtonBlue"
          onClick={onShowModal}
        >
          Hoist Price Calculator (NEW)
        </MondayButton>
      </Badge.Ribbon>
      {showModal && (
        <TableHoistCalcModal
          {...{
            visible: showModal,
            setVisible: setShowModal,
            indexes,
            service,
            updateStateAndSave,
          }}
        />
      )}
    </div>
  );
}

export default TableHoistPriceCalculator;
