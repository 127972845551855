import dayjs from "dayjs";
import { getWeeksDiff } from "../../../../RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/columnDefs";
import moment from "moment";
export const extractDataHandler = ({ params }) => {
  return params?.data?.services?.map(({ label, serviceOptions }) => {
    const weeks = serviceOptions
      ?.flatMap(({ items }) => items)
      ?.flatMap(({ rentalDetails }) => rentalDetails?.details);
    const startDate = !!weeks?.length
      ? weeks?.reduce(
          (acc, curr) =>
            acc > curr?.weekStart ? (acc = curr?.weekStart) : acc,
          99999999999999999999999
        )
      : undefined;
    const endDate = !!weeks?.length
      ? weeks?.reduce(
          (acc, curr) => (acc < curr?.weekEnd ? (acc = curr?.weekEnd) : acc),
          -9999999999999999999999
        )
      : undefined;
    // const weeks = serviceOptions
    // ?.flatMap(({ items }) => items)
    // ?.flatMap(({ rentalDetails }) => rentalDetails?.details);
    // const weeks = getWeeksDiff(startDate, endDate);
    return {
      label,
      // weeksNo: getWeeksDiff(startDate, endDate),
      startDate,
      endDate,
    };
  });
};
export const columnDefs = [
  {
    headerName: "No.",
    cellRendererFramework: (params) => params?.rowIndex + 1,
  },
  {
    headerName: "Label",
    flex: 2,
    field: "label",
  },
  {
    headerName: "Weeks",
    field: "weeksNo",
    valueGetter: (params) => {
      let endDate = !!params.data.endDate ? moment(params.data.endDate) : null;
      let startDate = !!params.data.startDate
        ? moment(params.data.startDate)
        : null;
      let numberOfWeeks = getWeeksDiff(endDate, startDate);
      return numberOfWeeks;
      // return <div className="rentalEditableCell">{numberOfWeeks.toString}</div>;
    },
  },
  {
    headerName: "Start Date",
    field: "startDate",
    flex: 2,
    cellRenderer: ({ value }) =>
      !!value ? dayjs(value)?.format("MM/DD/YYYY") : "--",
  },
  {
    headerName: "End Date",
    field: "endDate",
    flex: 2,
    cellRenderer: ({ value }) =>
      !!value ? dayjs(value)?.format("MM/DD/YYYY") : "--",
  },
];

export const defaultColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
  enablePivot: true,
  filter: true,
  enableColResize: true,
  enableRowGroup: true,
  rowHeight: 40,
};
