import { getBinNumber } from ".";
import { filterTables, lazyFetch } from "../../../../../utils";
import { apiRoutes, fetchData } from "../../../Fleet/utils";
import { uniq } from "lodash";

const ELECTRICAL_SUBCONTRACTOR = "012021";

export const fetchDobData = async ({
  projectName,
  projectId,
  generalContractor,
  setDobData,
  setDocConfig,
  setDocumentation,
  setLoading,
}) => {
  const binNr = await getBinNumber(projectName);

  const [
    { value: approvedPermits },
    { value: issuedPermits },
    { value: electricalPermits },
    { value: complaints },
    { value: violations },
    {
      value: { documentationsAvailable = [] },
    },
    { value: documentation },
  ] = await Promise.allSettled([
    ...[
      fetch(
        `https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${binNr}&applicant_license=${generalContractor}`
      ),
      fetch(
        `https://data.cityofnewyork.us/resource/ipu4-2q9a.json?bin__=${binNr}&permittee_s_license__=${
          "0" + generalContractor
        }`
      ),
      fetch(
        `https://data.cityofnewyork.us/resource/dm9a-ab7w.json?bin=${binNr}&license_number=${ELECTRICAL_SUBCONTRACTOR}`
      ),
      fetch(
        `https://data.cityofnewyork.us/resource/eabe-havv.json?bin=${binNr}`
      ),
      fetch(
        `https://data.cityofnewyork.us/resource/3h2n-5cm9.json?bin=${binNr}`
      ),
    ].map(async (promise) => (await promise).json()),
    fetchData(
      apiRoutes.docConfiguration,
      `${apiRoutes.docConfiguration}/Projects`
    ),
    filterTables(apiRoutes.documentation, "recordId", projectId),
  ]);

  setDocConfig(documentationsAvailable);
  setDocumentation(documentation);
  setDobData({
    approvedPermits,
    issuedPermits,
    electricalPermits,
    complaints,
    violations,
  });

  setLoading(false);
};

export const fetchMultipleDobAddresses = async ({
  projectName,
  alternativeAddresses,
  binNumber,
  projectId,
  generalContractor,
  setDobData,
  setDocConfig,
  setDocumentation,
  setLoading,
  setBinNumbers,
}) => {
  const allAddresses = [...alternativeAddresses, projectName];
  let approvedPermitsTemp = [];
  let issuedPermitsTemp = [];
  let electricalPermitsTemp = [];
  let complaintsTemp = [];
  let violationsTemp = [];
  let documentationsAvailableTemp = [];
  let documentationTemp = [];
  let binNumbersTemp = [];

  for (let i = 0; i < allAddresses.length; i++) {
    const binNr = await getBinNumber(allAddresses[i], true);
    const binNumbers = Array.isArray(binNr) ? binNr : [{ bin: binNr }];
    const binQueryString = binNumbers
      .map((number) => `caseless_one_of(\`bin__\`, "${number.bin}")`)
      .join(" OR ");
    const binQueryString2 = binNumbers
      .map((number) => `caseless_one_of(\`bin\`, "${number.bin}")`)
      .join(" OR ");

    if (!binNumbers.length) {
      setLoading(false);
      return;
    }

    const [
      { value: approvedPermits },
      { value: issuedPermits },
      { value: electricalPermits },
      { value: complaints },
      { value: violations },
      {
        value: { documentationsAvailable = [] },
      },
      { value: documentation },
    ] = await Promise.allSettled([
      ...[
        fetch(
          `https://data.cityofnewyork.us/resource/rbx6-tga4.json?$query=SELECT * WHERE ${binQueryString2} AND applicant_license='${generalContractor}'`
        ),
        fetch(
          `https://data.cityofnewyork.us/resource/ipu4-2q9a.json?$query=SELECT * WHERE ${binQueryString} AND permittee_s_license__='${
            "0" + generalContractor
          }'`
        ),
        fetch(
          `https://data.cityofnewyork.us/resource/dm9a-ab7w.json?$query=SELECT * WHERE ${binQueryString2} AND license_number='${ELECTRICAL_SUBCONTRACTOR}'`
        ),
        fetch(
          `https://data.cityofnewyork.us/resource/eabe-havv.json?$query=SELECT * WHERE ${binQueryString2}`
        ),
        fetch(
          `https://data.cityofnewyork.us/resource/3h2n-5cm9.json?$query=SELECT * WHERE ${binQueryString2}`
        ),
      ].map(async (promise) => (await promise).json()),
      fetchData(
        apiRoutes.docConfiguration,
        `${apiRoutes.docConfiguration}/Projects`
      ),
      filterTables(apiRoutes.documentation, "recordId", projectId),
    ]);

    approvedPermitsTemp = [...approvedPermitsTemp, ...approvedPermits];
    issuedPermitsTemp = [...issuedPermitsTemp, ...issuedPermits];
    electricalPermitsTemp = [...electricalPermitsTemp, ...electricalPermits];
    complaintsTemp = [...complaintsTemp, ...complaints];
    violationsTemp = [...violationsTemp, ...violations];
    documentationsAvailableTemp = [
      ...documentationsAvailableTemp,
      ...documentationsAvailable,
    ];
    documentationTemp = [...documentationTemp, ...documentation];
    binNumbersTemp = binNumbers;
  }
  try {
    setDocConfig(documentationsAvailableTemp);
    setDocumentation(documentationTemp);
    setDobData({
      approvedPermits: approvedPermitsTemp,
      issuedPermits: issuedPermitsTemp,
      electricalPermits: electricalPermitsTemp,
      complaints: complaintsTemp,
      violations: violationsTemp,
    });
    setBinNumbers(binNumbersTemp);
    setLoading(false);
  } catch (error) {
    console.log("error fetch", error);
  }
};
