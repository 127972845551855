import React from "react";
import "./OpenBalanceGridHeader.scss";
import { useSelector } from "react-redux";

const OpenBalanceGridHeader = ({ rowData }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const paidInvoices = rowData?.length || 0;
  const notPaidInvoices =
    rowData?.filter((row) => row.openBalance !== 0)?.length || 0;

  return (
    <div
      className={`open-balance-table-header ${
        isDarkMode && "open-balance-table-header-dark"
      }`}
    >
      <h3>Overview</h3>
      <div className="total-invoices">
        <div className="invoice-count">
          <strong>{paidInvoices}</strong>
          <span>invoices</span>
        </div>
        <div className="invoice-count">
          <strong>{notPaidInvoices}</strong>
          <span>unpaid invoices</span>
        </div>
      </div>
    </div>
  );
};

export default OpenBalanceGridHeader;
