export const estimations = (circleProgressPicker, IdHandler, updatedDynamicStates) => {
  return {
    estSTATUS: () => ({
      title: "Estimation Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "estSTATUS",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.estSTATUS?.toLowerCase() === circleProgressPicker?.toLocaleLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.estSTATUS?.toLowerCase() === circleProgressPicker?.toLocaleLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //estSTATUS
    archAssignedTo: () => ({
      title: "Architect Assigned To",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "archAssignedTo",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.archAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: "Projects Assigned To " + circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.archAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //archAssignedTo
    engAssignedTo: () => ({
      title: "Engineer Assigned To",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "engAssignedTo",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.engAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: "Projects Assigned To " + circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.engAssignedTo?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //engAssignedTo
    statusByArch: () => ({
      title: "Architect Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByArch",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.statusByArch?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.statusByArch?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //statusByArch
    statusByEng: () => ({
      title: "Engineer Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByEng",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.statusByEng?.toLowerCase() === circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.statusByEng?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //statusByEng
  } //Estimations
}
