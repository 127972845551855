import { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useProgress from "../../../../../hooks/useProgress";
import { useQuery } from "../../../../../hooks";

const VideoTutorialContext = createContext();

export function VideoTutorialProvider({ children }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [primaryArray, setPrimaryArray] = useState([]);
  const [unsavedPrimaryArray, setUnsavedPrimaryArray] = useState([]);
  const [activePrimaryCat, setActivePrimaryCat] = useState();
  const [favourites, setFavourites] = useState([]);
  const [videoActive, setVideoActive] = useState();
  const [filterInput, setFilterInput] = useState("");
  const [idSubActive, setIdSubActive] = useState(null);
  const [newOrNo, setNewOrNo] = useState(true);
  const [playedVideos, setPlayedVideos] = useState([]);
  const [customeModal, setCustomeModal] = useState(true);
  const [modalSubIdArrs, setModalSubIdArrs] = useState([]);
  const [isActive, setActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [hasWatched, setHasWatched] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCatVideos, setSubCatVideos] = useState();
  const [playingVideo, setPlayingVideo] = useState();
  const [logsData, setLogsData] = useState([]);
  const [logsModal, setLogsModal] = useState(false);
  const [favOrNewCategory, setFavOrNewCategory] = useState("");
  const [allLogs, setAllLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [newVideos, setNewVideos] = useState([]);

  const queryParam = useQuery();

  const selectedVideoCategory = queryParam.get("selectedVideoCat");
  const selectedVideoSubCat = queryParam.get("selectedVideoSubCat");
  const selectedVideoId = queryParam.get("selectedVideoId");

  const { progress, endProgress, startProgress } = useProgress();

  let lengthVideos = [];
  let idOfSubArray = [];
  let anotherarrRedirect = [];
  let sumVideo = 0;

  const allVideos = primaryArray.flatMap((item) =>
    item.subCategories.flatMap((subcategory) =>
      subcategory.videos.map((video) => ({
        ...video,
        categoryName: item.categoryName,
        subCategoryName: subcategory.subCategoryName,
      }))
    )
  );

  const [idOfSubArrayOnChange, setIdOfSubArrayOnChange] =
    useState(anotherarrRedirect);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const play = () => setPlaying(true);
  const pause = () => setPlaying(false);
  const handleCancel = () => {
    setIsModalVisible(false);
    pause();
  };

  primaryArray
    .filter((category) => category.id === activePrimaryCat)
    .map((category) =>
      category.subCategories.map((subCategory) =>
        subCategory.videos.map((video) =>
          lengthVideos.push(video.lengthVideoInMin)
        )
      )
    );

  primaryArray.map((category) => {
    category.subCategories.map((subCategory) => {
      subCategory.videos.map((video) => {
        anotherarrRedirect.push(`${video.id}`);
      });
      subCategory.videos
        .filter(
          (filterItem) =>
            filterItem.newvideo === true &&
            !filterItem.usersHaveSeen.includes(userConfiguration.identityId)
        )
        .map((video) => {
          idOfSubArray.push({
            videoLink: video.videoLink,
            otherInfo: [
              {
                categoryId: category.id,
                subCategoryId: subCategory.id,
                videoId: video.id,
              },
            ],
          });
        });
    });
  });

  for (let i = 0; i < lengthVideos.length; i++) {
    sumVideo += Number(lengthVideos[i]);
  }

  sumVideo = Math.round((Number(sumVideo) + Number.EPSILON) * 100) / 100;

  useEffect(() => {
    if (selectedVideoCategory && selectedVideoSubCat && selectedVideoId) {
      setVisible(true);
    }
  }, [selectedVideoCategory, selectedVideoSubCat, selectedVideoId]);

  const expandCategory = (newId) => {
    if (idOfSubArrayOnChange.includes(`${newId}`)) {
      setIdOfSubArrayOnChange(
        // filterInput !== "" &&
        idOfSubArrayOnChange.filter((e) => e !== `${newId}`)
      );
    } else {
      setIdOfSubArrayOnChange(
        // filterInput !== "" &&
        [...idOfSubArrayOnChange, `${newId}`]
      );
    }
  };

  const expandSubCat = (subIdRight) => {
    if (modalSubIdArrs.includes(`${subIdRight}`)) {
      setModalSubIdArrs(modalSubIdArrs.filter((e) => e !== `${subIdRight}`));
    } else {
      setModalSubIdArrs([...modalSubIdArrs, `${subIdRight}`]);
    }
  };

  const toggleActiveStyleSubCat = (subIdRight) => {
    if (subCatVideos === subIdRight) {
      return "activesubCat";
    } else {
      return "inactiveSubCat";
    }
  };

  return (
    <VideoTutorialContext.Provider
      value={{
        selectedVideoCategory,
        selectedVideoSubCat,
        selectedVideoId,
        primaryArray,
        newVideos,
        setNewVideos,
        allVideos,
        setPrimaryArray,
        activePrimaryCat,
        setActivePrimaryCat,
        videoActive,
        allLogs,
        setAllLogs,
        filteredLogs,
        setFilteredLogs,
        setVideoActive,
        filterInput,
        setFilterInput,
        idSubActive,
        setIdSubActive,
        newOrNo,
        setNewOrNo,
        customeModal,
        setCustomeModal,
        playedVideos,
        setPlayedVideos,
        subCatVideos,
        setSubCatVideos,
        modalSubIdArrs,
        setModalSubIdArrs,
        sumVideo,
        idOfSubArray,
        unsavedPrimaryArray,
        setUnsavedPrimaryArray,
        toggleActiveStyleSubCat,
        expandSubCat,
        isActive,
        setActive,
        isModalVisible,
        setIsModalVisible,
        playing,
        setPlaying,
        favourites,
        setFavourites,
        showModal,
        play,
        playingVideo,
        setPlayingVideo,
        pause,
        handleCancel,
        anotherarrRedirect,
        idOfSubArrayOnChange,
        setIdOfSubArrayOnChange,
        expandCategory,
        // search,
        visible,
        setVisible,
        hasWatched,
        setHasWatched,
        subCategoryName,
        setSubCategoryName,
        logsData,
        setLogsData,
        logsModal,
        setLogsModal,
        progress,
        endProgress,
        startProgress,
        isDarkMode,
        favOrNewCategory,
        setFavOrNewCategory,
      }}
    >
      {children}
    </VideoTutorialContext.Provider>
  );
}

export default VideoTutorialContext;
