import {
  addons,
  dispatching,
  documentation,
  estimations,
  fleet,
  fleetInspections,
  leads,
  maintenance,
  opportunities,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  defects,
  workOrders,
  safety,
  todos,
  projectCost,
  approvals,
  inspections,
} from "./subCategory";

export const pieChart = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  projectsData,
  engData,
  archData,
  inspectionsData,
  fleetData,
  dispatchData,
  applicationsData,
  rentalsData,
  chargesData,
  invoicesData,
  // incidentsData,
  defectsData,
  workOrdersData,
  jobsites,
  reducedUsers
) => {
  return {
    Leads: () => leads(IdHandler, programFields, updatedDynamicStates),
    Incidents: () => incidents(IdHandler, updatedDynamicStates, programFields),
    Defects: () =>
      defects(IdHandler, updatedDynamicStates, defectsData, programFields),
    Claims: () => claims(IdHandler, programFields, updatedDynamicStates),
    "Work Orders": () =>
      workOrders(
        workOrdersData,
        updatedDynamicStates,
        IdHandler,
        programFields
      ),
    Opportunities: () =>
      opportunities(IdHandler, programFields, updatedDynamicStates),
    Projects: () =>
      projects(IdHandler, programFields, updatedDynamicStates, projectsData),
    Documentation: () =>
      documentation(IdHandler, programFields, updatedDynamicStates),
    Scheduling: () =>
      scheduling(IdHandler, programFields, updatedDynamicStates),
    Dispatching: () =>
      dispatching(IdHandler, dispatchData, updatedDynamicStates),
    Fleet: () => fleet(IdHandler, fleetData, updatedDynamicStates),
    "Fleet Inspections": () =>
      fleetInspections(
        IdHandler,
        updatedDynamicStates,
        inspectionsData,
        programFields
      ),
    Maintenance: () => maintenance(IdHandler, updatedDynamicStates),
    Violations: () => violations(IdHandler, updatedDynamicStates),
    Estimations: () =>
      estimations(
        IdHandler,
        updatedDynamicStates,
        programFields,
        engData,
        archData
      ),
    "Add-ons": () => addons(updatedDynamicStates, IdHandler),
    Applications: () =>
      applications(
        programFields,
        updatedDynamicStates,
        applicationsData,
        IdHandler
      ),
    Rentals: () =>
      rentals(programFields, updatedDynamicStates, rentalsData, IdHandler),
    Charges: () =>
      charges(programFields, updatedDynamicStates, chargesData, IdHandler),
    Invoices: () =>
      invoices(programFields, updatedDynamicStates, invoicesData, IdHandler),
    Safety: () => safety(IdHandler, programFields, updatedDynamicStates),
    "To Do": () => todos(IdHandler, programFields, updatedDynamicStates),
    "Project Cost": () =>
      projectCost(IdHandler, programFields, updatedDynamicStates, jobsites),
    Approvals: () =>
      approvals(programFields, updatedDynamicStates, IdHandler, reducedUsers),
    Inspections: () =>
      inspections(IdHandler, programFields, updatedDynamicStates),
  }; //PieChart
};
