import { useState } from "react";

import { useEditLogs } from "./useEditLogs";

const useSaveLogs = (categoryName = "") => {
  const [logsModalVisible, setLogsModalVisible] = useState(false);

  const { saveAddedLogs, fetchedLogs, handleFetchLogs, loading } =
    useEditLogs();

  const sendLogToApi = (logsData) => {
    let logSample = Array.isArray(logsData) ? [] : {};
    if (Array.isArray(logsData)) {
      const formattedLogs = logsData.map((logInfo) => {
        return {
          recordId: logInfo.recordId,
          recordName: logInfo.recordName,
          topic: categoryName,
          actionType: logInfo.actionType,
          category: categoryName,
          currentData: logInfo.currentData,
          previousData: logInfo.previousData,
          updatedKeys: logInfo.updatedKeys,
        };
      });
      logSample.push(...formattedLogs);
    }
    if (!Array.isArray(logsData)) {
      logSample = {
        recordId: logsData.recordId,
        recordName: logsData.recordName,
        topic: categoryName,
        actionType: logsData.actionType,
        category: categoryName,
        currentData: logsData.currentData,
        previousData: logsData.previousData,
        updatedKeys: logsData.updatedKeys,
        ...(logsData.label && { label: logsData.label }),
      };
    }

    saveAddedLogs(logSample);
  };

  const getLogsFromDb = () => {
    const filters = [
      {
        conditions: [
          {
            operator: "AND",
            column: "category",
            value: categoryName,
            formula: "contains",
          },
        ],
      },
    ];
    handleFetchLogs({ filters }).then(() => setLogsModalVisible(true));
  };

  function modalToggler() {
    if (!loading) {
      getLogsFromDb();
    }
  }

  return {
    sendLogToApi,
    modalToggler,
    editLogs: fetchedLogs,
    logsModalVisible,
    setLogsModalVisible,
    loading,
  };
};

export default useSaveLogs;
