import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { lazyFetch } from "../../../../../../../../utils";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

/**
 * This property filters the result of getCostAnalytics function
 * @typedef filter
 * @property {string} key - specified key that will filter the result
 * @property {any} value - the filter value
 * @property {string} type - type of the column that will filter the result
 *
 * Payroll object
 * @typedef payroll
 * @property {string} degId
 * @property {string} degStatus
 * @property {number} fromDate
 * @property {number} toDate
 */

/**
 *  Get cost analytics in a specified date range.
 * @param {{dateRange: number[] | Date[], filters:filter[], crewId: string}, listOfPayrolls: payroll[], company: string} param
 */
async function getCostAnalytics({
  dateRange,
  filters,
  listOfPayrolls = [],
  company,
  crewId = "",
}) {
  try {
    const [dateStart, dateEnd] = dateRange.map((el) =>
      dayjsNY(el).startOf("D").valueOf()
    );

    // Get only Completed payrolls
    let completedPayrolls = [];

    if (listOfPayrolls?.length) {
      completedPayrolls = listOfPayrolls.filter(
        (el) => el?.degStatus === "Completed"
      );
    } else {
      completedPayrolls = await lazyFetch({
        tableName: "deg",
        filterKey: "degStatus",
        filterValue: "Completed",
        listOfKeys: ["degId", "fromDate", "toDate", "degStatus"],
      });
    }

    // Filter payrolls by date range
    const payrolls = completedPayrolls.filter((el) => {
      const fromDate = dayjsNY(el?.fromDate).startOf("D").valueOf();
      const toDate = dayjsNY(el?.toDate).startOf("D").valueOf();

      const isBetweenDateRange = dateStart >= fromDate && dateEnd <= toDate;

      const isStartBetweenDateRange =
        dateStart <= fromDate && fromDate <= dateEnd;

      const isEndBetweenDateRange = dateStart <= toDate && toDate <= dateEnd;

      return (
        isBetweenDateRange || isEndBetweenDateRange || isStartBetweenDateRange
      );
    });

    // Create filter for entries that are inside the date range and are connected
    // to any of the completedPayrolls inside the date range
    const entiresAppliedFilters = payrolls.map((el) => {
      const appliedFilters = {
        conditions: [
          {
            column: "punchTimeStamp",
            columnType: "date",
            dataType: undefined,
            formula: "is between",
            id: uuidv4(),
            operator: "AND",
            value: [dateStart, dateEnd],
          },
          {
            column: "degId",
            columnType: "string",
            dataType: "string",
            formula: "is",
            id: uuidv4(),
            operator: "AND",
            value: el?.degId,
          },
        ],
        id: uuidv4(),
        operator: "OR",
      };

      if (!!filters?.length) {
        const filtersObj = filters.map((filter) => ({
          column: filter?.key,
          columnType: filter?.type,
          dataType: filter?.type,
          formula: "is",
          id: uuidv4(),
          value: filter.value,
          operator: "AND",
        }));

        Object.assign(appliedFilters, {
          conditions: appliedFilters.conditions.concat(filtersObj),
        });
      }

      if (!!company) {
        const companyFilters = [
          {
            column: "company",
            columnType: "string",
            dataType: "string",
            formula: "is",
            id: uuidv4(),
            operator: "OR",
            value: company,
          },
          {
            column: "companyName",
            columnType: "string",
            dataType: "string",
            formula: "is",
            id: uuidv4(),
            operator: "OR",
            value: company,
          },
        ];
        Object.assign(appliedFilters, {
          conditions: appliedFilters?.conditions.concat(companyFilters),
        });
      }

      // const crewId = "b15ffa95-ad4b-4538-b607-37a2756d5f04";

      if (!!crewId) {
        const crewFilters = [
          {
            column: "crewId",
            columnType: "string",
            dataType: "string",
            formula: "is",
            id: uuidv4(),
            operator: "AND",
            value: crewId,
          },
        ];
        Object.assign(appliedFilters, {
          conditions: appliedFilters?.conditions.concat(crewFilters),
        });
      }

      return appliedFilters;
    });

    const analytics = await API.get("degEntries", "/degEntries", {
      queryStringParameters: {
        ExclusiveStartKey: undefined,
        withPagination: "true",
        getMaxLimit: "true",
        filters: JSON.stringify(entiresAppliedFilters),
      },
    });

    if (!!payrolls?.length) {
      return analytics?.degEntries || [];
    } else {
      return [];
    }
  } catch (error) {
    console.log("Error getting costAnalytics: ", error);
    return [];
  }
}

export default getCostAnalytics;
