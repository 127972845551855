import AmountReq from "../../../Service/ServiceContent/GridData/GridUtils/amountFactory";

//this finds checkbox value
export const compleatedHandler = ({ fakeApplication, service, eventId }) => {
  const eventData = fakeApplication?.services
    ?.find(
      (servicesData) =>
        servicesData?.serviceId === service?.serviceId &&
        servicesData?.estimationId === service?.estimationId
    )
    ?.amounts?.find(({ id }) => id === eventId);

  const { balanceToFinish, amount, fromPrevious, charged, thisPeriod } =
    eventData || {};
  // console.log("Event Data", eventData);
  return {
    someChecked:
      fromPrevious === 0
        ? !(
            Math?.floor(thisPeriod) === Math?.floor(amount - fromPrevious) ||
            thisPeriod === 0
          )
        : !(Math?.floor(thisPeriod) === Math?.floor(amount - fromPrevious)),
    allChecked: Math?.floor(balanceToFinish) === 0,
    hasPrevious: Math?.floor(fromPrevious) !== 0,
    charged: charged,
  };
};

//elevationCalculator
const serviceOptionsCalculator = ({
  checkboxToggle,
  serviceOptions,
  retainagePercentage,
}) => {
  return serviceOptions?.map((elevationData) => ({
    ...elevationData,
    items: elevationData?.items?.map((pliData) => {
      const { fromPrevious, amount } = pliData || {};
      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
      //else if we want to uncheck all this period will be 0
      const thisPeriod = !!checkboxToggle ? amount - fromPrevious : 0;
      //if the we want to check all this period is calculated by (the hole amount for this event)
      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
      const totalCompleated = !!checkboxToggle ? amount : fromPrevious;
      //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
      const percentageCompleated = !!checkboxToggle
        ? ((thisPeriod + fromPrevious) / amount) * 100
        : (fromPrevious / amount) * 100;
      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
      //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
      const balanceToFinish = !!checkboxToggle
        ? amount - fromPrevious - thisPeriod
        : amount - fromPrevious;
      //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
      const retainage = (totalCompleated * retainagePercentage) / 100;

      return {
        ...pliData,
        retainage,
        thisPeriod,
        totalCompleated,
        percentageCompleated,
        balanceToFinish,
      };
    }),
  }));
};

//addons calculator
const serviceAddonsCalculator = ({
  checkboxToggle,
  serviceAddons,
  retainagePercentage,
}) =>
  serviceAddons?.map((addonsData) => {
    const { fromPrevious, amount } = addonsData || {};
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
    //else if we want to uncheck all this period will be 0
    const thisPeriod = !!checkboxToggle ? amount - fromPrevious : 0;
    //if the we want to check all this period is calculated by (the hole amount for this event)
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
    const totalCompleated = !!checkboxToggle ? amount : fromPrevious;
    //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
    const percentageCompleated = !!checkboxToggle
      ? ((thisPeriod + fromPrevious) / amount) * 100
      : (fromPrevious / amount) * 100;
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
    //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
    const balanceToFinish = !!checkboxToggle
      ? amount - fromPrevious - thisPeriod
      : amount - fromPrevious;
    //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    const retainage = (totalCompleated * retainagePercentage) / 100;

    return {
      ...addonsData,
      retainage,
      thisPeriod,
      totalCompleated,
      percentageCompleated,
      balanceToFinish,
    };
  });

//amount calculator
const amountsCalculator = ({ checkboxToggle, amounts, eventId, service }) =>
  amounts?.map((amountsData) => {
    const {
      fromPrevious,
      amount,
      charged,
      retainagePercentage,
      reducedBalance = 0,
    } = amountsData || {};
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
    //else if we want to uncheck all this period will be 0
    const thisPeriod = !!checkboxToggle ? amount - fromPrevious : 0;
    //if the we want to check all this period is calculated by (the hole amount for this event)
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
    const totalCompleated = !!checkboxToggle ? amount : fromPrevious;
    //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
    //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
    const percentageCompleated = !!checkboxToggle
      ? ((thisPeriod + fromPrevious) / amount) * 100
      : (fromPrevious / amount) * 100;
    //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
    //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
    const balanceToFinish = !!checkboxToggle
      ? amount - fromPrevious - thisPeriod
      : amount - fromPrevious;
    //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
    const retainage = (totalCompleated * retainagePercentage) / 100;

    const keptRetainage = thisPeriod * (retainagePercentage / 100);

    const paymentDue = thisPeriod - keptRetainage + reducedBalance;
    let myAmount = new AmountReq(amountsData);
    myAmount.chargeThisPeriod(thisPeriod, _, service);

    return amountsData?.id === eventId
      ? charged //if this pli is charged return it unchanged
        ? amountsData //unchangedData
        : //else if its not charged and checkboxToggle is true do this
          {
            ...myAmount.getData(),
            // ...amountsData,
            // keptRetainage,
            // thisPeriod,
            // paymentDue,
            // totalCompleated,
            // percentageCompleated,
            // balanceToFinish,
            // retainage,
            // serviceOptions: serviceOptionsCalculator({
            //   checkboxToggle,
            //   serviceOptions: amountsData?.serviceOptions,
            //   retainagePercentage,
            // }),
            // serviceAddons: serviceAddonsCalculator({
            //   checkboxToggle,
            //   serviceAddons: amountsData?.serviceAddons,
            //   retainagePercentage,
            // }),
          }
      : amountsData;
  });

//this function calculates the new values of application
export const applicationHandler = ({
  fakeApplication,
  checkboxToggle,
  service,
  eventId,
}) => ({
  ...fakeApplication,
  services: fakeApplication?.services?.map((serviceData) => {
    const { amounts } = serviceData || {};
    console.log("Service Data", serviceData);
    return serviceData?.serviceId === service?.serviceId &&
      serviceData?.estimationId === service?.estimationId
      ? {
          ...serviceData,
          amounts: amountsCalculator({
            checkboxToggle,
            amounts,
            eventId,
            service: serviceData,
          }),
        }
      : serviceData;
  }),
});

//=============================================HOIST FUNCTIONS========================================================

export const applicationHoistHandler = ({
  fakeApplication,
  checkboxToggle,
  estimationId,
  elevationId,
  serviceId,
  eventId,
}) => ({
  ...fakeApplication,
  services: fakeApplication?.services?.map((serviceData) =>
    serviceData?.serviceId === serviceId &&
    serviceData?.estimationId === estimationId
      ? {
          ...serviceData,
          serviceOptions: [
            serviceData?.serviceOptions[0]?.map((elevationData) =>
              elevationData?.elevationId === elevationId
                ? {
                    ...elevationData,
                    amounts: elevationData?.amounts?.map((eventData) => {
                      const {
                        fromPrevious,
                        amount,
                        charged,
                        reducedBalance = 0,
                      } = eventData || {};
                      const retainagePercentage = serviceData?.retainage;

                      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications)
                      //else if we want to uncheck all this period will be 0
                      const thisPeriod = !!checkboxToggle
                        ? amount - fromPrevious
                        : 0;
                      //if the we want to check all this period is calculated by (the hole amount for this event)
                      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)
                      const totalCompleated = !!checkboxToggle
                        ? amount
                        : fromPrevious;
                      //if the we want to check all this period is calculated by ((the amount applied in this period) + (the amount that is applied in previous applications))/(the hole amount for this event) times 100 to turn it to percentage
                      //else if we want to uncheck all this period will be (the amount that is applied in previous applications)/(the hole amount for this event) times 100 to turn it to percentage
                      const percentageCompleated = !!checkboxToggle
                        ? ((thisPeriod + fromPrevious) / amount) * 100
                        : (fromPrevious / amount) * 100;
                      //if the we want to check all this period is calculated by (the hole amount for this event) - (the amount that is applied in previous applications) - (the amount applied in this period)
                      //else if we want to uncheck all this period will be (the hole amount for this event) - (the amount that is applied in previous applications)
                      const balanceToFinish = !!checkboxToggle
                        ? amount - fromPrevious - thisPeriod
                        : amount - fromPrevious;
                      //retainage is calculated (totalCompleated that we calculated before) * (retainagePercentage) / 100
                      const retainage =
                        (totalCompleated * retainagePercentage) / 100;

                      // Kept Retaiange for event
                      const keptRetainage =
                        thisPeriod * (retainagePercentage / 100);
                      const paymentDue =
                        thisPeriod - keptRetainage + reducedBalance;
                      let myAmount = new AmountReq({
                        ...eventData,
                      });
                      myAmount.chargeThisPeriod(thisPeriod, _, serviceData);

                      return !!charged
                        ? eventData
                        : eventData?.id === eventId
                        ? {
                            ...(myAmount.getData() || {}),
                          }
                        : eventData;
                    }),
                  }
                : elevationData
            ),
          ],
        }
      : serviceData
  ),
});

//this finds checkbox value
export const compleatedHoistHandler = ({
  fakeApplication,
  elevationId,
  serviceId,
  eventId,
  estimationId,
}) => {
  const eventData = fakeApplication?.services
    ?.find(
      (serviceData) =>
        serviceData?.serviceId === serviceId &&
        serviceData?.estimationId === estimationId
    )
    ?.serviceOptions[0]?.find(
      (elevationData) => elevationData?.elevationId === elevationId
    )
    ?.amounts?.find((eventData) => eventData?.id === eventId);

  const { balanceToFinish, amount, fromPrevious, charged, thisPeriod } =
    eventData || {};

  return {
    someChecked: !(
      Math?.floor(thisPeriod) === Math?.floor(amount - fromPrevious) ||
      thisPeriod === 0
    ),
    allChecked: Math?.floor(balanceToFinish) === 0,
    charged: charged,
    hasPrevious: Math?.floor(fromPrevious) !== 0,
  };
};
