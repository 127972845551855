import { Collapse, Drawer, Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { NotesIcon } from "../../../../../SidebarPages/DynamicView/src";
import { getNoteFromItem, PliNotes } from "./PliNotes/PliNotes";
import { ScheduleDaysNotes } from "./ScheduleDaysNotes/ScheduleDaysNotes";
import { useSelector } from "react-redux";
import { NotesComponent } from "../../../../../commonComponents/Notes/components/NotesComponent";
import _ from "lodash";
import { extractContent } from "../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";
import { ScheduleEditNote } from "../../../../../../assets";
import { Notes } from "../../../../../commonComponents/Notes/Notes";
import { API } from "aws-amplify";
import {
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../utils/windowMessages";
import { filterTables } from "../../../../../../utils/filterTables";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";

export const NotesDrawer = ({
  scheduleId,
  // scheduleNotes,
  scheduleAddress,
  notesChanged,
  notesAccessRights,
  isDarkMode,
}) => {
  const [drawerOpen, set_drawerOpen] = useState(false);
  const [noteCategories, set_noteCategories] = useState([]);
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  const [scheduleNotes, setScheduleNotes] = useState();

  useEffect(() => {
    scheduleId &&
      filterTables("notes", "recordId", scheduleId)
        .then((res) => setScheduleNotes(res))
        .catch((err) => console.log({ err }));
  }, [scheduleId]);

  const {
    authenticatedUser: { authenticatedUser },
    userConfig: { userConfiguration } = {},
    programFields: { programFields },
  } = useSelector((state) => state);

  const { toBeScheduled, scheduleDays, notesToAddForScheduleDays } =
    useSelector((state) => state.schedule);

  useEffect(() => {
    const noteCategories = programFields?.find(
      (pd) => pd?.fieldName === "Categories for Notes"
    )?.fieldOptions?.["Scheduling"];
    set_noteCategories(noteCategories);
  }, [programFields]);

  const toggleDrawer = () => {
    set_drawerOpen(!drawerOpen);
  };

  //pli notes
  let notes = []; //init notes as an empty array
  //populate it with notes from items in services
  // iterateServicesInPLILevel({
  //   toBeScheduled,
  //   actionFunction: getNoteFromItem,
  //   variablesNeeded: { notes },
  // });

  notes = notes?.filter(
    ({ note }) =>
      !(
        note === undefined ||
        note === null ||
        extractContent(note) === "-" ||
        typeof note?.note != "string"
      )
  );

  //     console.log(notes)

  //schedule day notes
  let scheduleDays_withNotes = _.cloneDeep(scheduleDays);

  //add new notes into schedule days
  for (const scheduleDayId of Object.keys(notesToAddForScheduleDays || {}) ||
    []) {
    const note = notesToAddForScheduleDays?.[scheduleDayId];
    const thisScheduleDayNotes =
      scheduleDays_withNotes?.find((sch) => sch?.id === scheduleDayId)?.notes ||
      [];

    if (!thisScheduleDayNotes?.find((n) => n?.id === note?.id)) {
      note.addedHere = true;
      thisScheduleDayNotes.push(note);
    }
  }

  let scheduleDay_notes_total = 0;
  for (const scheduleDaysWithNote of scheduleDays_withNotes || []) {
    scheduleDay_notes_total += scheduleDaysWithNote?.notes?.length;
  }

  // console.log(notes ,scheduleDay_notes_total, scheduleNotes.length)

  // const totalNotesCount =
  //   notes.length + scheduleDay_notes_total + scheduleNotes.length;

  const headerContent = (
    <div className="schedule-note-header">
      {/* <h3 style={{ color: "#fff" }}>Schedule Notes</h3> */}
      <Tooltip title={scheduleAddress || ""}>
        <h5
          style={{
            color: "#fff",
            width: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {scheduleAddress}
        </h5>
      </Tooltip>
    </div>
  );

  const postNotes = (note) => {
    showLoadingMsg();

    API.post("notes", `/notes`, {
      body: note,
    })
      .then((res) => {
        setScheduleNotes((prev) => [...prev, res]);
        showSuccessMsg({ content: "Notes updated successfully" });
      })
      .catch((e) => console.error(e));
  };

  const deleteNote = (noteId) => {
    API.del("notes", `/notes/${noteId}`).then(() =>
      setScheduleNotes((prev) => prev.filter((note) => note.noteId !== noteId))
    );
  };

  const postReplies = (noteId, replies) => {
    API.put("notes", `/notes/${noteId}`, {
      body: {
        repliesVisibility: false,
        replies: replies,
      },
    }).then((res) => {
      const updatedNotes = notes.map((note) => {
        return note.noteId === noteId ? { ...note, replies: replies } : note;
      });
      setScheduleNotes(updatedNotes);
    });
  };

  return (
    <>
      {/* <Badge */}
      {/* // className={"schedule-form-notes-drawer-button"} */}
      {/* // badgeContent={totalNotesCount} */}
      {/* // onClick={toggleDrawer} */}
      {/* > */}
      <ScheduleEditNote onClick={toggleDrawer} />
      {/* </Badge> */}
      {/*<IconButton className={"schedule-form-notes-drawer-button"} variant="outlined" onClick={toggleDrawer} ><NotesIcon/> {totalNotesCount} </IconButton>*/}
      <Drawer
        rootClassName={`notes_drawer ${isDarkMode && "notes-modal-dark"}`}
        title={headerContent}
        placement="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        maskClosable={true}
        closeIcon={null}
      >
        <Tabs
          items={[
            {
              key: "1",
              label: "Pli",
              children: <PliNotes {...{ notes }} />,
            },
            {
              key: "2",
              label: "Schedule Days",
              children: (
                <ScheduleDaysNotes
                  {...{
                    scheduleDays: scheduleDays_withNotes,
                    authenticatedUser,
                  }}
                />
              ),
            },
            {
              key: "3",
              label: "Others",
              children: (
                <div className="notes-other-schedule">
                  <NotesComponent
                    notes={scheduleNotes}
                    noteCategories={noteCategories}
                    postNotes={postNotes}
                    noteModalName={scheduleAddress}
                    rowId={scheduleId}
                    hasWriteAccess={notesAccessRights}
                    topicCategory={"Scheduling"}
                    deleteNote={deleteNote}
                    postReplies={postReplies}
                    setRowNotes={setScheduleNotes}
                  />
                </div>
              ),
            },
          ]}
        ></Tabs>
      </Drawer>
    </>
  );
};
