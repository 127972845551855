import "./OtherPricingLabel.scss"

const OtherPricingLabel = ({ label, value, lastChild }) => {
  return (
    <div
      className="other-pricing-label"
      style={{
        ...(!!lastChild && { backgroundColor: "#EEEEF3", fontWeight: "600" }),
      }}
    >
      <div className="pricing-label">{label}</div>
      <div className="pricing-value">{value}</div>
    </div>
  )
}

export default OtherPricingLabel
