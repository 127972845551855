/**
 * @param {string} src
 * @returns {Promise<void>}
 */
function validateImage(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = resolve;
    image.onerror = reject;
  });
}

export default validateImage;
