import { message, Popconfirm, Tooltip } from "antd";
import _ from "lodash";
import React from "react";
import { calculatePrice, getMaxNoFeet } from "../../tools/formatters";
import { defaultEmptyElevation } from "../../tools/constants";
import { createEmptyPLI } from "../../tools/polyfillers/PLICreator";
import { removeArrayIndexes } from "../../../../utils";
import { replaceArrayElement } from "../../../../../../utils";
import {
  DeleteElevationIcon,
  DuplicateElevation,
} from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";
import { MathActionsIcon } from "../../../../../../icons";
import { ExceptionOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { AddIcon } from "../../../../../Header/components/GoogleEvents/assets";

/**
 * When you click this button all price suggestions are applied to all PLI is this elevation.
 * @param isWritable {boolean}
 * @param gridDataState {{serviceId: number ,selectedPriceSchemeId: String,serviceOptions: {items: SidewalkShedPLI}[][]}[]}
 * @param updateStateAndSave {function}
 * @param sidewalkShedPriceSheet {PriceSheet}
 * @param serviceIndex {number}
 * @param optionIndex {number}
 * @param elevationIndex {number}
 * @param agGridApiForEachElevation {{api: gridApi, columnApi: columnApi}}
 * */
export const applyPriceSheetSuggestionToAll = (
  isWritable,
  gridDataState,
  updateStateAndSave,
  sidewalkShedPriceSheet,
  serviceIndex,
  optionIndex,
  elevationIndex,
  agGridApiForEachElevation
) => (
  <Tooltip
    title="Apply price sheet suggestion to all"
    placement="top"
    key={"elevationSuggestionApplier"}
  >
    <button
      className="iconHolder"
      onClick={(e) => {
        //    console.log('gridDataState :>> ', gridDataState);
        //    console.log('sidewalkShedPriceSheet :>> ', sidewalkShedPriceSheet);
        //    console.log('serviceIndex :>> ', serviceIndex);
        //    console.log('optionIndex :>> ', optionIndex);
        //    console.log('elevationIndex :>> ', elevationIndex);
        //    console.log('agGridApiForEachElevation :>> ', agGridApiForEachElevation);
        e.stopPropagation();
        if (isWritable) {
          let gridData = _.cloneDeep(gridDataState);
          //find which price sheet is being used
          // const usedPriceSchemeId = gridData.find(s => s.serviceId === serviceIndex).selectedPriceSchemeId || 0; //if none has been selected select the first one

          for (let item of gridData[serviceIndex]?.serviceOptions[optionIndex][
            elevationIndex
          ]?.items) {
            if (item?.lock === true) continue; //if item is locked, there will be no changes

            if (
              sidewalkShedPriceSheet.x.includes(
                getMaxNoFeet(item.width)?.toString()
              ) &&
              sidewalkShedPriceSheet.y.includes(
                getMaxNoFeet(item.height)?.toString()
              )
            ) {
              for (const [xIndex, x] of sidewalkShedPriceSheet.x.entries()) {
                if (x === getMaxNoFeet(item.width)?.toString()) {
                  for (const [
                    yIndex,
                    y,
                  ] of sidewalkShedPriceSheet.y.entries()) {
                    if (y === getMaxNoFeet(item.height)?.toString()) {
                      item.ppu = sidewalkShedPriceSheet.z[yIndex][xIndex];
                      item.price = calculatePrice(
                        gridData[serviceIndex].serviceId,
                        item
                      );
                      break;
                    }
                  }
                  break;
                }
              }
            }
          }
          try {
            const agGridApi =
              agGridApiForEachElevation[gridData[serviceIndex].serviceId][
                optionIndex
              ][elevationIndex];
            if (agGridApi)
              agGridApi.api.setRowData(
                gridData[serviceIndex].serviceOptions[optionIndex][
                  elevationIndex
                ].items
              ); //if grid api has been initialised
          } catch (e) {
            console.error(e);
          }

          updateStateAndSave(gridData);
        } else {
          message.error("Please enable write mode.");
        }
      }}
      style={{
        fontSize: "18px",
      }}
      // style={{
      //   // padding: "5px",
      //   backgroundColor: "red",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   borderRadius: "5px",
      //   width: "32px",
      //   height: "32px",
      // }}
    >
      $
      {/* <AssignmentReturnedOutlined
        onClick={(e) => {
          //    console.log('gridDataState :>> ', gridDataState);
          //    console.log('sidewalkShedPriceSheet :>> ', sidewalkShedPriceSheet);
          //    console.log('serviceIndex :>> ', serviceIndex);
          //    console.log('optionIndex :>> ', optionIndex);
          //    console.log('elevationIndex :>> ', elevationIndex);
          //    console.log('agGridApiForEachElevation :>> ', agGridApiForEachElevation);
          e.stopPropagation();
          if (isWritable) {
            let gridData = _.cloneDeep(gridDataState);
            //find which price sheet is being used
            // const usedPriceSchemeId = gridData.find(s => s.serviceId === serviceIndex).selectedPriceSchemeId || 0; //if none has been selected select the first one

            for (let item of gridData[serviceIndex].serviceOptions[optionIndex][
              elevationIndex
            ].items) {
              if (item.lock === true) continue; //if item is locked, there will be no changes

              if (
                sidewalkShedPriceSheet.x.includes(
                  getMaxNoFeet(item.width)?.toString()
                ) &&
                sidewalkShedPriceSheet.y.includes(
                  getMaxNoFeet(item.height)?.toString()
                )
              ) {
                for (const [xIndex, x] of sidewalkShedPriceSheet.x.entries()) {
                  if (x === getMaxNoFeet(item.width)?.toString()) {
                    for (const [
                      yIndex,
                      y,
                    ] of sidewalkShedPriceSheet.y.entries()) {
                      if (y === getMaxNoFeet(item.height)?.toString()) {
                        item.ppu = sidewalkShedPriceSheet.z[yIndex][xIndex];
                        item.price = calculatePrice(
                          gridData[serviceIndex].serviceId,
                          item
                        );
                        break;
                      }
                    }
                    break;
                  }
                }
              }
            }
            try {
              const agGridApi =
                agGridApiForEachElevation[gridData[serviceIndex].serviceId][
                  optionIndex
                ][elevationIndex];
              if (agGridApi)
                agGridApi.api.setRowData(
                  gridData[serviceIndex].serviceOptions[optionIndex][
                    elevationIndex
                  ].items
                ); //if grid api has been initialised
            } catch (e) {
              console.error(e);
            }

            updateStateAndSave(gridData);
          } else {
            message.error("Please enable write mode.");
          }
        }}
      /> */}
    </button>
  </Tooltip>
);

// adds an elevation
export const appendElevation = (
  serviceDefs,
  gridDataState,
  updateStateAndSave,
  serviceIndex,
  optionIndex
) => {
  let newGridData = _.cloneDeep(gridDataState);
  let elevation,
    option = newGridData[serviceIndex].serviceOptions[optionIndex];

  if (
    newGridData[serviceIndex].serviceId === 3 ||
    newGridData[serviceIndex].serviceId?.toString() === "38"
  ) {
    option.push(_.cloneDeep(defaultEmptyElevation.hoist));
  } else {
    option.push(_.cloneDeep(defaultEmptyElevation.empty));
  }
  //modify elevation
  elevation = option[option.length - 1];
  if (
    newGridData[serviceIndex].serviceId !== 3 &&
    newGridData[serviceIndex].serviceId?.toString() !== "38"
  ) {
    //add an empty pli, except hoist
    elevation.items.push(
      createEmptyPLI(
        newGridData[serviceIndex].serviceId,
        1,
        newGridData[serviceIndex],
        serviceIndex,
        optionIndex
      )
    );
  }
  elevation.elevationId = getElevationId(
    serviceDefs,
    newGridData[serviceIndex].serviceId,
    optionIndex,
    newGridData[serviceIndex].serviceOptions[optionIndex].length
  );
  console.log("appendElevation", elevation);

  console.log("elevation.elevationId", elevation.elevationId);
  console.log("elevation", elevation);
  console.log({ newGridData });
  updateStateAndSave(newGridData);
};

// deletes an elevation
export const removeElevation = (
  serviceDefs,
  isWritable,
  gridDataState,
  stateSetter,
  handleSave,
  serviceIndex,
  optionIndex,
  elevationIndex,
  setOpen
) =>
  !gridDataState[serviceIndex].isScope && (
    <Tooltip
      title={
        "Remove Elevation " +
        serviceDefs?.find(
          (serviceDef) =>
            serviceDef["workId"].toString() ===
            gridDataState[serviceIndex].serviceId.toString()
        )?.["workName"]
      }
      placement="top"
      key={"elevationRemover"}
    >
      <Popconfirm
        title="Are you sure you want to remove this elevation?"
        placement="bottomRight"
        onConfirm={(e) => {
          e.stopPropagation();
          if (isWritable) {
            let newGridData;

            // if (!service.isScope) {
            newGridData = [...gridDataState];
            newGridData[serviceIndex].serviceOptions[optionIndex].splice(
              elevationIndex,
              1
            );

            resetAllElevationsId(serviceDefs, newGridData, serviceIndex);
            // } else {
            //   const { serviceOptions } = service
            //   const updatedService = {
            //     ...service,
            //     serviceOptions: [
            //       removeArrayIndexes(serviceOptions[0], elevationIndex),
            //     ],
            //   }

            //   newGridData = replaceArrayElement(
            //     gridDataState,
            //     updatedService,
            //     "serviceId"
            //   )
            // }

            stateSetter({
              gridData: newGridData,
            });
            handleSave(false);
            setOpen(false);
          } else {
            message.error("Please enable write mode.");
          }
        }}
        onCancel={() => {}}
        okText="Remove"
        cancelText="Cancel"
      >
        <button
          className="iconHolder"
          style={{
            background: "#FE4C4A",
            width: "32px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span style={{ marginLeft: "-5px" }}>
            <DeleteElevationIcon />
          </span>
        </button>
        {/*<img src="https://img.icons8.com/material-outlined/20/f5f5f5/delete-trash.png" alt="" onClick={e => {e.stopPropagation();}}/>*/}
      </Popconfirm>
    </Tooltip>
  );

export const duplicateElevation = (
  serviceDefs,
  serviceId,
  isWritable,
  gridDataState,
  stateSetter,
  serviceIndex,
  optionIndex,
  elevationIndex,
  setOpen,
  updateStateAndSave,
  forceLoad
) => (
  <Tooltip
    title={
      "Duplicate Elevation " +
      serviceDefs?.find(
        (serviceDef) => serviceDef["workId"].toString() === serviceId.toString()
      )?.["workName"]
    }
    placement="top"
    key={"elevationDuplicator"}
  >
    <button
      className="iconHolder"
      style={{
        background: "#71CF48",
        width: "32px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (isWritable) {
          let gridData = _.cloneDeep(gridDataState);
          const elevation = _.cloneDeep(
            gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
          );
          gridData[serviceIndex].serviceOptions[optionIndex] = [
            ...gridData[serviceIndex].serviceOptions[optionIndex],
            elevation,
          ];

          //reset all elevation id
          let temp = resetAllElevationsId(serviceDefs, gridData, serviceIndex);
          updateStateAndSave(structuredClone(temp));
          forceLoad("forceLoad", serviceIndex, optionIndex);
          stateSetter({ gridData: temp });
          setOpen(false);
        } else {
          message.error("Please enable write mode.");
        }
      }}
    >
      {/* <DuplicateElevation
          style={{
            fontSize: "23px",
          }}
        /> */}
      <AddIcon
        style={{
          fontSize: "1.5rem",
          marginLeft: "-1.5px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (isWritable) {
            let gridData = _.cloneDeep(gridDataState);
            const elevation = _.cloneDeep(
              gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
            );
            gridData[serviceIndex].serviceOptions[optionIndex].push(elevation);

            //reset all elevation id
            resetAllElevationsId(serviceDefs, gridData, serviceIndex);

            stateSetter({ gridData: gridData });
          } else {
            message.error("Please enable write mode.");
          }
        }}
      />
    </button>
  </Tooltip>
);

export const resetAllElevationsId = (serviceDefs, gridData, serviceIndex) => {
  const serviceCode =
    serviceDefs?.find(
      (serviceDef) =>
        parseInt(serviceDef?.["workId"]) === gridData[serviceIndex]?.serviceId
    )?.["idCode"] ?? "Other Scope";

  for (let i = 0; i < gridData[serviceIndex].serviceOptions.length; i++) {
    for (let j = 0; j < gridData[serviceIndex].serviceOptions[i].length; j++) {
      const elevation = gridData[serviceIndex].serviceOptions[i][j];
      elevation.elevationId = getElevationIdByServiceCode(
        serviceCode,
        gridData[serviceIndex].serviceId,
        i,
        j
      );
    }
  }
  return gridData;
};

// formatting for elevation id

export const getElevationId = (
  serviceDefs,
  serviceId,
  optionIndex,
  elevationIndex
) => {
  return `(${
    serviceDefs?.find(
      (serviceDef) => parseInt(serviceDef?.["workId"]) === serviceId
    )?.["idCode"]
  }-${optionIndex + 1}-${("00" + (elevationIndex + 1)).slice(-2)})`;
};

const getElevationIdByServiceCode = (
  serviceCode,
  serviceId,
  optionIndex,
  elevationIndex
) => {
  return `(${serviceCode}-${optionIndex + 1}-${(
    "00" +
    (elevationIndex + 1)
  ).slice(-2)})`;
};
