export const documentation = (circleProgressPicker, IdHandler, updatedDynamicStates) => {
  return {
    docType: () => ({
      title: "Documentation Type",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Documentation",
        field: "docType",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter((a) => a?.docType === circleProgressPicker)
            ?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter((a) => a?.docType === circleProgressPicker)
          ?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //doctype
  } //Documentation
}
