import { message } from "antd";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { getDateRange } from "../utils";
import { findTeamForEmployee } from "./editShiftModalData";
import { DEG_DATE_FORMAT, findTimeDiff } from "../utils/cellFunctions";
import { createShiftsForCrews } from "../TeamShiftsModal/teamShiftModalData";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";
import {
  setHourMinute,
  getDateOffset,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

export function onShiftEdit({
  shift,
  crews,
  fields = {},
  rowData = [],
  setCrews,
  formRate,
  setRowData = () => {},
  alteredRate,
  selectedTeam,
  employeeMatch,
  scheduleMatch,
  addEntryAction,
  entriesActions,
  matchedJobsite,
  addActionToRegister,
  changeRateForEmployee = false,
}) {
  const { reason, punchDate, shiftStatus, shiftType, lunchIncluded, sow } =
    fields;

  let entriesForShift = [];
  let newEntries = [];

  let oldEditedEntries = [];
  let editedEntries = []; // List of edited entries
  let removedEntries = []; // List of removed entries
  let newAddedEntriesAction = []; // List of new entries created

  for (const entryId of shift?.["entries"] || []) {
    let entry = rowData?.findIndex(({ entryId: id }) => id === entryId);
    if (entry > -1) {
      entriesForShift.push(rowData[entry]);
    }
  }

  if (shiftType === "Regular Shift") {
    let fieldsToIterate = lunchIncluded
      ? ["clockIn", "lunchStart", "lunchEnd", "clockOut"]
      : ["clockIn", "clockOut"];

    for (const field of fieldsToIterate) {
      let punchType = "";
      if (field === "clockIn") {
        punchType = "ID";
      } else if (field === "lunchStart") {
        punchType = "OL";
      } else if (field === "lunchEnd") {
        punchType = "IL";
      } else {
        punchType = "OD";
      }

      const distanceFromJob =
        !!matchedJobsite?.addressPosition?.lat &&
        withinRadius(matchedJobsite?.addressPosition, shift?.punchCoordinates)
          .distanceInFeet;
      const duration = distanceFromJob && distanceFromJob / 3.5;

      const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
        (el) =>
          dayjsNY(el?.startDate).format(DEG_DATE_FORMAT) ===
          punchDate?.format?.(DEG_DATE_FORMAT)
      );

      newEntries.push({
        entryId: uuidv4(),
        punchLocation: matchedJobsite?.jobAddress,
        jobsiteId: matchedJobsite?.jobsiteId,
        jobsiteMatch: {
          jobName: matchedJobsite?.jobName,
          jobsiteId: matchedJobsite?.jobsiteId,
          jobAddress: matchedJobsite?.jobAddress,
          services: matchedJobsite?.services,
          googleSheetLink: matchedJobsite?.googleSheetLink,
          rates: matchedJobsite?.rates,
          reimbursement: matchedJobsite?.reimbursement,
        },
        payrollType: matchedJobsite?.payrollType,
        punchDate,
        punchTime: fields[field],
        punchTimeStamp: dayjsNY(fields[field]).valueOf(),
        // punchCoordinates: matchedJobsite?.addressPosition,
        punchCoordinates: shift.punchCoordinates,
        sow,
        employeeRate: formRate
          ? formRate
          : !isNaN(Number(alteredRate))
          ? Number(alteredRate)
          : employeeMatch?.employeeRate,
        crewId: employeeMatch?.crewId,
        employeeId: shift?.employeeId,
        crewTeamId: selectedTeam?.crewTeamId,
        crewTeamName: selectedTeam?.crewTeamName,
        activityStatus: shiftStatus,
        employeeFullName: shift?.employeeFullName,
        company: shift?.company,
        companyName: shift?.companyName,
        scheduleId: scheduleMatch?.scheduleId,
        scheduleAddress: scheduleMatch?.scheduleAddress,
        scheduleDayId: scheduleDay?.id,
        punchType,
        employeeRole: shift?.employeeRole,
        reason,
        salaryType: employeeMatch?.salaryType,
        distanceFromJob: distanceFromJob || 0,
        duration: duration || 0,
      });
    }
  } else {
    newEntries = [
      {
        entryId: uuidv4(),
        punchLocation: undefined,
        jobsiteId: "",
        jobsiteMatch: {},
        payrollType: undefined,
        punchDate,
        punchTime: fields["clockIn"],
        punchTimeStamp: dayjsNY(fields["clockIn"]).valueOf(),
        punchCoordinates: undefined,
        sow: [],
        employeeRate: formRate
          ? formRate
          : !isNaN(Number(alteredRate))
          ? Number(alteredRate)
          : Number(employeeMatch?.employeeRate),
        crewId: employeeMatch?.crewId,
        employeeId: shift?.employeeId,
        crewTeamId: selectedTeam?.crewTeamId,
        crewTeamName: selectedTeam?.crewTeamName,
        scheduleId: scheduleMatch?.scheduleId,
        scheduleAddress: scheduleMatch?.scheduleAddress,
        scheduleDayId: scheduleDay?.id,
        activityStatus: shiftStatus,
        employeeFullName: shift?.employeeFullName,
        company: shift?.company,
        companyName: shift?.companyName,
        punchType: "HR",
        employeeRole: employeeMatch?.employeeRole,
        reason,
        salaryType: employeeMatch?.salaryType,
      },
    ];
  }

  if (shift?.hrShift) {
    if (shiftType === "Regular Shift") {
      /**
       * The shift was changed from HR to regular.
       * In this case we need to create the remaining punches
       */
      setRowData((p) => {
        let prev = _.cloneDeep(p);
        let entryIndex = prev?.findIndex(
          ({ entryId }) => entryId === shift?.entries[0]
        );
        let tmpEntries = [];
        if (entryIndex > -1) {
          prev[entryIndex] = {
            ...newEntries[0],
            entryId: shift?.entries[0],
          };

          oldEditedEntries.push(p[entryIndex]);
          editedEntries.push(prev[entryIndex]);
        }
        for (let i = 1; i < newEntries?.length; i++) {
          tmpEntries.push(newEntries[i]);
        }
        newAddedEntriesAction = newAddedEntriesAction.concat(tmpEntries);
        return tmpEntries.concat(prev);
      });
    } else {
      setRowData((p) => {
        let prev = _.cloneDeep(p);
        let entryIndex = prev?.findIndex(
          ({ entryId }) => entryId === shift?.entries[0]
        );
        if (entryIndex > -1) {
          prev[entryIndex] = {
            ...newEntries[0],
            entryId: shift?.entries[0],
          };
          oldEditedEntries.push(p[entryIndex]);
          editedEntries.push(prev[entryIndex]);
        }
        return prev;
      });
    }
  } else {
    if (shiftType === "HR Shift") {
      /**
       * The shift was changed from regular to HR.
       * In this case we need to remove three entries and edit one
       */
      setRowData((p) => {
        let prev = _.cloneDeep(p);
        let entryIndex = prev?.findIndex(
          ({ entryId }) => entryId === shift?.entries[0]
        );
        if (entryIndex > -1) {
          prev[entryIndex] = {
            ...newEntries[0],
            entryId: shift?.entries[0],
          };
          oldEditedEntries.push(p[entryIndex]);
          editedEntries.push(prev[entryIndex]);
        }
        for (let i = 1; i < shift?.entries?.length; i++) {
          let ind = prev?.findIndex(
            ({ entryId }) => entryId === shift?.entries[i]
          );
          removedEntries.push(prev[ind]);
          prev.splice(ind, 1);
        }
        return prev;
      });
    } else {
      if (!shift?.breakHours) {
        if (lunchIncluded) {
          /**
           * The user added a lunch break to a shift that didn't have it
           */
          setRowData((p) => {
            let prev = _.cloneDeep(p);
            let startIndex = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[0]
            );
            let endIndex = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[1]
            );
            if (startIndex > -1) {
              prev[startIndex] = {
                ...newEntries[0],
                entryId: prev[startIndex]["entryId"],
              };
              oldEditedEntries.push(p[startIndex]);
              editedEntries.push(prev[startIndex]);
            }
            if (endIndex > -1) {
              prev[endIndex] = {
                ...newEntries[3],
                entryId: prev[endIndex]["entryId"],
              };
              oldEditedEntries.push(p[endIndex]);
              editedEntries.push(prev[endIndex]);
            }
            newAddedEntriesAction = newAddedEntriesAction.concat([
              newEntries[1],
              newEntries[2],
            ]);
            return [newEntries[1], newEntries[2]].concat(prev);
          });
        } else {
          setRowData((p) => {
            let prev = _.cloneDeep(p);
            let startIndex = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[0]
            );
            let endIndex = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[1]
            );
            if (startIndex > -1) {
              prev[startIndex] = {
                ...newEntries[0],
                entryId: prev[startIndex]["entryId"],
              };
              oldEditedEntries.push(p[startIndex]);
              editedEntries.push(prev[startIndex]);
            }
            if (endIndex > -1) {
              prev[endIndex] = {
                ...newEntries[1],
                entryId: prev[endIndex]["entryId"],
              };
              oldEditedEntries.push(p[endIndex]);
              editedEntries.push(prev[endIndex]);
            }
            return prev;
          });
        }
      } else {
        if (!lunchIncluded) {
          /**
           * The user removed the lunch break from a shift
           */
          setRowData((p) => {
            let prev = _.cloneDeep(p);
            let removeLunchStart = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[1]
            );
            let removeLunchEnd = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[2]
            );
            let startShift = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[0]
            );
            let endShift = prev?.findIndex(
              ({ entryId }) => entryId === shift?.entries[3]
            );

            if (startShift > -1) {
              prev[startShift] = {
                ...newEntries[0],
                entryId: prev[startShift]["entryId"],
              };
              oldEditedEntries.push(p[startShift]);
              editedEntries.push(prev[startShift]);
            }
            if (endShift > -1) {
              prev[endShift] = {
                ...newEntries[1],
                entryId: prev[endShift]["entryId"],
              };
              oldEditedEntries.push(p[endShift]);
              editedEntries.push(prev[endShift]);
            }

            if (removeLunchStart > -1) {
              removedEntries.push(prev[removeLunchStart]);
              prev.splice(removeLunchStart, 1);
            }

            if (removeLunchEnd > -1) {
              removedEntries.push(prev[removeLunchEnd]);
              prev.splice(
                removeLunchEnd > removeLunchStart
                  ? removeLunchEnd - 1
                  : removeLunchEnd,
                1
              );
            }

            return prev;
          });
        } else {
          setRowData((p) => {
            let prev = _.cloneDeep(p);
            for (let i = 0; i < newEntries?.length; i++) {
              let index = prev.findIndex(
                ({ entryId }) => entryId === shift?.entries[i]
              );
              if (index > -1) {
                prev[index] = {
                  ...newEntries[i],
                  entryId: shift?.entries[i],
                };
                oldEditedEntries.push(p[index]);
                editedEntries.push(prev[index]);
              }
            }
            return prev;
          });
        }
      }
    }
  }
  if (changeRateForEmployee) {
    const newCrewsList = crews.map((crew) => {
      if (crew?.crewId === employeeMatch?.crewId) {
        return Object.assign(crew, { employeeRate: formRate });
      } else {
        return crew;
      }
    });
    API.put("crews", `/crews/${employeeMatch?.crewId}`, {
      body: {
        employeeRate: formRate,
      },
    })
      .then(() => setCrews(newCrewsList))
      .catch((err) => console.log("changing employee rate Error: ", err));
  }

  const removeActions = {
    newEntries: [],
    editedEntries: [],
    removedEntries: [],
  };

  const editActions = {
    editedEntries: [],
    newEditedEntries: [],
  };

  removedEntries.forEach((row) => {
    let editIncluded = entriesActions?.editedEntries.findIndex(
      (el) => el?.entryId === row?.entryId
    );
    let newIncluded = entriesActions?.newEntries.findIndex(
      (el) => el?.entryId === row?.entryId
    );

    if (editIncluded > -1) {
      Object.assign(removeActions, {
        ["editedEntries"]: removeActions.editedEntries.concat(row),
      });
    } else if (newIncluded > -1) {
      Object.assign(removeActions, {
        ["newEntries"]: removeActions.newEntries.concat(row),
      });
    } else {
      Object.assign(removeActions, {
        ["removedEntries"]: removeActions.removedEntries.concat(row),
      });
    }
  });

  editedEntries.forEach((row) => {
    let editIncluded = (entriesActions?.editedEntries || []).findIndex(
      (el) => el?.entryId === row?.entryId
    );
    if (editIncluded === -1) {
      Object.assign(editActions, {
        ["newEditedEntries"]: editActions.newEditedEntries.concat(row),
      });
    } else {
      Object.assign(editActions, {
        ["editedEntries"]: editActions.newEditedEntries.concat(row),
      });
    }
  });

  addActionToRegister({
    type: "massChanges",
    massChangeActions: {
      newEntries: newAddedEntriesAction,
      editedEntries: {
        prev: oldEditedEntries,
        curr: editedEntries,
        editActions,
      },
      removedEntries: removeActions,
    },
  });

  addEntryAction({ type: "edit", entry: editedEntries || [] });
  addEntryAction({ type: "remove", entry: removedEntries || [] });
  addEntryAction({ type: "new", entry: newAddedEntriesAction || [] });
}

export async function onShiftCreate({
  form,
  crews,
  setCrews,
  jobsites,
  onCancel,
  formRate,
  crewTeams,
  setRowData,
  scheduleMatch,
  addEntryAction,
  setExistingShifts,
  addActionToRegister,
  employeesHoursPerDay,
  checkForExisting = true,
  setExistingShiftsWarning,
  changeRateForEmployee = false,
}) {
  message.loading({
    content: "Creating Shifts...",
    key: "creatingShifts",
  });
  const tmpObj = form.getFieldsValue();
  const formObj = {
    selectedJobsite: tmpObj?.jobsite,
    selectedMembers: tmpObj?.selectedMembers,
    selectedDate: tmpObj?.punchDate,
    selectedTime: tmpObj?.clockIn,
    selectedEndTime: tmpObj?.clockOut,
    launchStartTime: tmpObj?.lunchStart,
    launchEndTime: tmpObj?.lunchEnd,
    services: tmpObj?.sow,
    shiftStatus: tmpObj?.shiftStatus,
    shiftType: tmpObj?.shiftType,
    reason: tmpObj?.reason,
  };

  await createShiftsForCrews({
    crews,
    jobsites,
    crewTeams,
    scheduleMatch,
    form: formRate
      ? Object.assign(formObj, { employeeRate: formRate })
      : formObj,
    launch: tmpObj?.lunchIncluded,
  })
    .then(({ newShifts }) => {
      let listOfExistingShifts = [];
      if (checkForExisting) {
        newShifts.forEach((data) => {
          const datesPerEmployee = Object.keys(
            employeesHoursPerDay?.[data?.employeeId] || {}
          ).filter(
            (date) => date === dayjsNY(data?.punchDate).format("MM/DD/YYYY")
          );
          if (!!datesPerEmployee?.length) {
            datesPerEmployee.forEach((date) => {
              const shift = employeesHoursPerDay?.[data?.employeeId]?.[date];
              if (
                dayjsNY(shift?.firstClockIn).format("hh A") ===
                dayjsNY(data?.punchTime).format("hh A")
              ) {
                const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
                  (el) =>
                    dayjsNY(el?.startDate).format(DEG_DATE_FORMAT) ===
                    dayjsNY(shift?.punchDate).format(DEG_DATE_FORMAT)
                );

                Object.assign(shift, {
                  employeeFullName: data?.employeeFullName,
                  employeeId: data?.employeeId,
                  jobsiteId: data?.jobsiteMatch?.jobsiteId,
                  jobsiteMatch: data?.jobsiteMatch?.jobName,
                  scheduleId: scheduleMatch?.scheduleId,
                  scheduleAddress: scheduleMatch?.scheduleAddress,
                  scheduleDayId: scheduleDay?.id,
                });
                listOfExistingShifts.push(shift);
              }
            });
          }
        });
      }
      if (changeRateForEmployee) {
        const newCrewsList = crews.map((crew) => {
          if (formObj?.includes(crew?.crewId)) {
            return Object.assign(crew, { employeeRate: formRate });
          } else {
            return crew;
          }
        });
        formObj?.selectedMembers.forEach((employeeId) =>
          API.put("crews", `/crews/${employeeId}`, {
            body: {
              employeeRate: formRate,
            },
          }).catch((err) => console.log("changing employee rate Error: ", err))
        );
        setCrews(newCrewsList);
      }
      if (!!listOfExistingShifts?.length) {
        setExistingShifts(listOfExistingShifts);
      }
      if (!!listOfExistingShifts?.length) {
        setExistingShiftsWarning(true);
        return;
      } else {
        setRowData((prev) => newShifts.concat(prev));
        addEntryAction({ type: "new", entry: newShifts || [] });
        addActionToRegister({ type: "new", newActions: newShifts });
      }
      message.success({
        content: "Shifts Created Successfully!",
        key: "creatingShifts",
      });
      onCancel();
    })
    .catch((err) => {
      message.error({
        content: "Something went wrong while creating the shifts!",
        key: "creatingShifts",
      });
      console.log("Error creating shifts: ", err);
      onCancel();
    });
}

export async function onDateRangeShiftCreate({
  form,
  crews,
  setCrews,
  jobsites,
  onCancel,
  formRate,
  crewTeams,
  setRowData,
  scheduleMatch,
  addEntryAction,
  setExistingShifts,
  addActionToRegister,
  employeesHoursPerDay,
  checkForExisting = true,
  setExistingShiftsWarning,
  changeRateForEmployee = false,
}) {
  message.loading({
    content: "Creating Shifts...",
    key: "creatingShifts",
  });
  const tmpObj = form.getFieldsValue();
  const dateRange = tmpObj?.punchDateRange;
  const dates = getDateRange(dateRange?.[0], dateRange?.[1]);

  let newShiftsCreated = [];

  for (const selectedDate of dates) {
    const formObj = {
      selectedJobsite: tmpObj?.jobsite,
      selectedMembers: tmpObj?.selectedMembers,
      selectedDate,
      selectedTime: tmpObj?.clockIn,
      selectedEndTime: tmpObj?.clockOut,
      launchStartTime: tmpObj?.lunchStart,
      launchEndTime: tmpObj?.lunchEnd,
      services: tmpObj?.sow,
      shiftStatus: tmpObj?.shiftStatus,
      shiftType: tmpObj?.shiftType,
      reason: tmpObj?.reason,
    };

    if (
      formObj?.selectedTime?.get("date") < formObj?.selectedEndTime?.get("date")
    ) {
      Object.assign(formObj, {
        selectedEndTime: setHourMinute(
          selectedDate.add(1, "d"),
          formObj?.selectedEndTime
        ),
      });
    } else {
      Object.assign(formObj, {
        selectedEndTime: setHourMinute(selectedDate, formObj?.selectedEndTime),
      });
    }
    if (
      formObj?.launchStartTime?.get("date") <
      formObj?.launchEndTime?.get("date")
    ) {
      Object.assign(formObj, {
        launchEndTime: setHourMinute(
          selectedDate.add(1, "d"),
          formObj?.launchEndTime
        ),
      });
    } else {
      Object.assign(formObj, {
        launchEndTime: setHourMinute(selectedDate, formObj?.launchEndTime),
      });
    }
    Object.assign(formObj, {
      selectedTime: setHourMinute(selectedDate, formObj?.selectedTime),
    });
    Object.assign(formObj, {
      launchStartTime: setHourMinute(selectedDate, formObj?.launchStartTime),
    });
    await createShiftsForCrews({
      crews,
      jobsites,
      crewTeams,
      form: formRate
        ? Object.assign(formObj, { employeeRate: formRate })
        : formObj,
      launch: tmpObj?.lunchIncluded,
    })
      .then(({ newShifts }) => {
        newShiftsCreated = newShiftsCreated.concat(
          newShifts.map((el) => {
            const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
              (day) =>
                dayjsNY(day?.startDate).format(DEG_DATE_FORMAT) ===
                dayjsNY(el?.punchDate).format(DEG_DATE_FORMAT)
            );
            return {
              ...el,
              scheduleId: scheduleMatch?.scheduleId,
              scheduleAddress: scheduleMatch?.scheduleAddress,
              scheduleDayId: scheduleDay?.id,
            };
          })
        );
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while creating the shifts!",
          key: "creatingShifts",
        });
        console.log("Error creating shifts: ", err);
      });
  }

  let listOfExistingShifts = [];
  if (checkForExisting) {
    newShiftsCreated.forEach((data) => {
      const datesPerEmployee = Object.keys(
        employeesHoursPerDay?.[data?.employeeId] || {}
      ).filter(
        (date) => date === dayjsNY(data?.punchDate).format("MM/DD/YYYY")
      );

      if (!!datesPerEmployee?.length) {
        datesPerEmployee.forEach((date) => {
          const shift = employeesHoursPerDay?.[data?.employeeId]?.[date];
          if (
            dayjsNY(shift?.firstClockIn).format("hh A") ===
            dayjsNY(data?.punchTime).format("hh A")
          ) {
            Object.assign(shift, {
              employeeFullName: data?.employeeFullName,
              employeeId: data?.employeeId,
              jobsiteId: data?.jobsiteMatch?.jobsiteId,
              jobsiteMatch: data?.jobsiteMatch?.jobName,
            });
            listOfExistingShifts.push(shift);
          }
        });
      }
    });
  }

  if (changeRateForEmployee) {
    const newCrewsList = crews.map((crew) => {
      if (formObj?.includes(crew?.crewId)) {
        return Object.assign(crew, { employeeRate: formRate });
      } else {
        return crew;
      }
    });
    formObj?.selectedMembers.forEach((employeeId) =>
      API.put("crews", `/crews/${employeeId}`, {
        body: {
          employeeRate: formRate,
        },
      }).catch((err) => console.log("changing employee rate Error: ", err))
    );
    setCrews(newCrewsList);
  }
  if (!!listOfExistingShifts?.length) {
    setExistingShifts(listOfExistingShifts);
  }
  if (!!listOfExistingShifts?.length) {
    setExistingShiftsWarning(true);
    return;
  } else {
    setRowData((prev) => newShiftsCreated.concat(prev));
    addEntryAction({ type: "new", entry: newShiftsCreated || [] });
    addActionToRegister({ type: "new", newActions: newShiftsCreated });
  }
  message.success({
    content: "Shifts Created Successfully!",
    key: "creatingShifts",
  });
  onCancel();
}

export function onMassEntry({
  form,
  crews,
  rowData,
  jobsites,
  crewTeams,
  setRowData,
  shiftsGridApi,
  scheduleMatch,
  entriesActions,
  addEntryAction,
  addActionToRegister,
}) {
  const selectedShifts = shiftsGridApi
    .getSelectedNodes()
    .map(({ data }) => data);

  const formObj = form.getFieldsValue();
  const employee = crews.find(
    ({ crewId }) => crewId === formObj?.selectedMembers
  );

  const teamMatch = findTeamForEmployee(
    crewTeams,
    employee?.crewId,
    employee?.employeeId
  );

  const selectedJobsite = jobsites.find(
    ({ jobsiteId }) => jobsiteId === formObj?.jobsite
  );

  const scheduleDay = (scheduleMatch?.scheduleDays || []).find((day) => {
    return (
      dayjsNY(day?.startDate).format(DEG_DATE_FORMAT) ===
      dayjsNY(formObj?.punchDate).startOf("D").format(DEG_DATE_FORMAT)
    );
  });

  const workActivities = ["ID", "OD"];
  const lunchActivities = ["IL", "OL"];

  let newActivities = [];
  let entriesToRemove = [];
  let editedEntries = [];
  let oldEditedEntries = [];

  // Object holding reference for each punchType activity
  const activitiesShift = {
    newActivityID: {
      activityStatus: formObj?.shiftStatus,
      company: employee?.accountName,
      companyName: employee?.accountName,
      crewId: employee?.crewId,
      employeeFullName: employee?.crewName,
      employeeId: employee?.employeeId,
      crewTeamName: teamMatch?.crewTeamName,
      crewTeamId: teamMatch?.crewTeamId,
      punchLocation: selectedJobsite?.jobAddress,
      employeeRate:
        selectedJobsite?.payrollType !== "Prevailing Wage"
          ? parseFloat(employee?.employeeRate || 0)
          : parseFloat(selectedJobsite?.rates?.[employee?.crewPosition] || 0),
      punchDate: formObj?.punchDate
        ? dayjsNY(formObj?.punchDate).startOf("D")
        : undefined,
      punchTime: formObj?.clockIn,
      punchTimeStamp: formObj?.clockIn?.valueOf(),
      jobsiteId: selectedJobsite?.jobsiteId,
      jobsiteMatch: selectedJobsite && {
        jobAddress: selectedJobsite?.jobAddress,
        jobName: selectedJobsite?.jobName,
        jobsiteId: selectedJobsite?.jobsiteId,
        services: selectedJobsite?.services,
        reimbursement: selectedJobsite?.reimbursement,
      },
      scheduleId: scheduleMatch?.scheduleId,
      scheduleAddress: scheduleMatch?.scheduleAddress,
      scheduleDayId: scheduleDay?.id,
      payrollType: selectedJobsite?.payrollType,
      punchType: "ID",
      employeeRole: employee?.crewPosition,
      salaryType: employee?.salaryType || "Hourly",
      sow: formObj?.sow,
    },
  };

  Object.assign(activitiesShift, {
    newActivityOD: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "OD",
        punchTime: formObj?.clockOut,
        punchTimeStamp: formObj?.clockOut?.valueOf(),
      }
    ),

    newActivityOL: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "OL",
        punchTime: formObj?.lunchStart,
        punchTimeStamp: formObj?.lunchStart?.valueOf(),
      }
    ),

    newActivityIL: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "IL",
        punchTime: formObj?.lunchEnd,
        punchTimeStamp: formObj?.lunchEnd?.valueOf(),
      }
    ),

    newActivityHR: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "HR",
        punchTime: formObj?.clockIn,
        reason: formObj?.reason,
      }
    ),
  });

  for (const shift of selectedShifts) {
    let entriesOfShift = rowData.filter(({ entryId, punchType }) => {
      if (
        formObj?.shiftType === "HR Shift" &&
        shift?.entries?.includes(entryId)
      ) {
        // entriesToRemove.push(entryId);
        if (punchType === "ID" || punchType === "HR") {
          return shift?.entries?.includes(entryId);
        }
      } else if (formObj?.shiftType === "Regular Shift") {
        return shift?.entries?.includes(entryId);
      }
    });

    // update each entry with form values
    for (const entry of entriesOfShift) {
      const employeeRate =
        selectedJobsite && selectedJobsite?.payrollType === "Prevailing Wage"
          ? selectedJobsite?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.jobsiteMatch?.rates
          ? entry?.jobsiteMatch?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.employeeRate;

      // Get duration and distance from jobsite for each ID entry
      if (
        entry?.punchType === "ID" &&
        formObj?.shiftType === "Regular Shift" &&
        !!selectedJobsite?.addressPosition?.lat
      ) {
        const distanceFromJob = withinRadius(
          selectedJobsite.addressPosition,
          entry.punchCoordinates
        ).distanceInFeet;
        const duration = distanceFromJob / 3.5;
        Object.assign(entry, { distanceFromJob, duration });
      }

      // when entry is HR and we want to transform it to a regular shift we iterate workActivities and create 2 new activities ID and OD
      if (entry?.punchType === "HR" && formObj?.shiftType === "Regular Shift") {
        for (const punchType of workActivities) {
          let activityType = Object.assign(
            // structuredClone(activitiesShift[`newActivity${punchType}`]),
            { ...activitiesShift?.[`newActivity${punchType}`] },
            {
              punchTime: activitiesShift[`newActivity${punchType}`]?.punchTime,
              punchDate: activitiesShift[`newActivity${punchType}`]?.punchDate,
              punchTimeStamp:
                activitiesShift[`newActivity${punchType}`]?.punchTimeStamp,
            }
          );

          for (const key of Object.keys(activityType)) {
            if (activityType[key] !== 0 && !activityType[key]) {
              delete activityType[key];
            }
          }

          const activityDate = activityType?.punchDate
            ? activityType?.punchDate
            : entry?.punchDate;

          const newEntry = Object.assign(
            { ...entry },
            {
              ...activityType,
              activityStatus: activityType?.activityStatus
                ? activityType?.activityStatus
                : entry.activityStatus,
              entryId: punchType === "OD" ? uuidv4() : entry?.entryId,
              punchTimeStamp: activityType?.punchTime
                ? setHourMinute(activityDate, activityType?.punchTime).valueOf()
                : entry.punchTimeStamp,
              employeeRate: employeeRate
                ? employeeRate
                : activityType?.employeeRate
                ? activityType?.employeeRate
                : entry?.employeeRate,
              punchDate: activityDate,
              punchTime:
                punchType === "OD"
                  ? activityType?.punchTime
                    ? setHourMinute(activityDate, activityType?.punchTime)
                    : setHourMinute(activityDate, entry?.punchTime.add(8, "h"))
                  : activityType?.punchTime
                  ? setHourMinute(activityDate, activityType?.punchTime)
                  : setHourMinute(activityDate, entry?.punchTime),
            }
          );
          // assign punchDate and punchTime on push to keep them as dayjsObject format not string
          newActivities.push(newEntry);
        }
      }
      //  else if (entry?.punchType === "HR" && formObj?.shiftType === "HR Shift") {

      
      // }
      else {
        // else we update the values of entries from the form excluding undefined values

        let clockInTime = // Start time of the shift
          activitiesShift[`newActivityID`]?.punchTime ||
          entriesOfShift?.find(({ punchType }) => punchType === "ID")
            ?.punchTime;

        let tmpPunchDate = // Start date of the shift
          formObj?.shiftType === "Regular Shift"
            ? activitiesShift[`newActivityID`]?.punchDate ||
              dayjsNY(
                entriesOfShift?.find(({ punchType }) => punchType === "ID")
                  ?.punchDate
              )
            : activitiesShift[`newActivityHR`]?.punchDate ||
              dayjsNY(entry?.punchDate);

        const activityType =
          formObj?.shiftType === "Regular Shift"
            ? // Object.assign(
              {
                ...activitiesShift[`newActivity${entry?.punchType}`],
                punchTime:
                  activitiesShift[`newActivity${entry?.punchType}`]?.punchTime,
                punchDate:
                  activitiesShift[`newActivity${entry?.punchType}`]?.punchDate,
                punchTimeStamp:
                  activitiesShift[`newActivity${entry?.punchType}`]
                    ?.punchTimeStamp,
              }
            : // ,
              //   {
              //     punchTime:
              //       activitiesShift[`newActivity${entry?.punchType}`]
              //         ?.punchTime,
              //     punchDate:
              //       activitiesShift[`newActivity${entry?.punchType}`]
              //         ?.punchDate,
              //     punchTimeStamp:
              //       activitiesShift[`newActivity${entry?.punchType}`]
              //         ?.punchTimeStamp,
              //   }
              // )
              Object.assign(
                { ...activitiesShift[`newActivityHR`] },
                {
                  punchTime:
                    activitiesShift[`newActivityHR`]?.punchTime ||
                    entry?.punchTime,
                  punchDate:
                    activitiesShift[`newActivityHR`]?.punchDate ||
                    entry?.punchDate,
                  punchTimeStamp:
                    activitiesShift[`newActivityHR`]?.punchTimeStamp ||
                    entry?.punchTimeStamp,
                }
              );

        for (const key of Object.keys(activityType)) {
          if (activityType[key] !== 0 && !activityType[key]) {
            delete activityType[key];
          }
        }

        const newEntry = Object.assign(
          { ...entry },
          {
            ...activityType,
            activityStatus: activityType?.activityStatus
              ? activityType?.activityStatus
              : entry.activityStatus,
            punchTimeStamp: activityType?.punchTime
              ? setHourMinute(tmpPunchDate, activityType?.punchTime).valueOf()
              : setHourMinute(tmpPunchDate, entry?.punchTime).valueOf(),
            employeeRate: employeeRate
              ? employeeRate
              : activityType?.employeeRate
              ? activityType?.employeeRate
              : entry?.employeeRate,
            punchDate: tmpPunchDate,
            punchTime: activityType?.punchTime
              ? setHourMinute(tmpPunchDate, activityType?.punchTime)
              : setHourMinute(tmpPunchDate, entry?.punchTime),
          }
        );

        const newEntryPunchTime = activityType?.punchTime
          ? activityType?.punchDate
            ? setHourMinute(activityType?.punchDate, activityType?.punchTime)
            : activityType?.punchTime
          : entry?.punchTime;

        // let todayDate = dayjsNY();
        // Check if other activities have passed to the next day
        if (
          activityType?.punchType !== "ID" &&
          activityType?.punchType !== "HR"
        ) {
          if (getDateOffset(clockInTime, newEntryPunchTime) > 0) {
            Object.assign(newEntry, {
              punchTime: setHourMinute(
                tmpPunchDate.add(1, "d"),
                newEntry.punchTime
              ),
              punchDate: tmpPunchDate.add(1, "d"),
              punchTimeStamp: setHourMinute(
                tmpPunchDate.add(1, "d"),
                newEntry.punchTime
              ).valueOf(),
            });
          }
        }
        if (
          // fix break time when changing only the shift clock in and clock out
          entriesOfShift?.length === 4 &&
          !formObj?.lunchIn &&
          !formObj?.lunchOut &&
          (entry?.punchType === "OL" || entry?.punchType === "IL") &&
          (!!formObj?.clockIn || !!formObj?.clockOut)
        ) {
          const oldClockIn = entriesOfShift.find(
            ({ punchType }) => punchType === "ID"
          )?.punchTime;
          const oldLunchStart = entriesOfShift.find(
            ({ punchType }) => punchType === "OL"
          ).punchTime;
          const oldLunchEnd = entriesOfShift.find(
            ({ punchType }) => punchType === "IL"
          ).punchTime;
          const diffFromClockIn = findTimeDiff(
            oldClockIn,
            oldLunchStart,
            "minute"
          );

          const breakLength = findTimeDiff(
            oldLunchStart,
            oldLunchEnd,
            "minute"
          );

          const newLunchIn = setHourMinute(
            tmpPunchDate,
            clockInTime.add(diffFromClockIn, "minutes")
          );

          const newLunchOut = newLunchIn.add(breakLength, "minutes");

          if (entry.punchType === "OL") {
            Object.assign(newEntry, {
              punchTime: newLunchIn,
              punchDate: newLunchIn.startOf("d"),
              punchTimeStamp: newLunchIn.valueOf(),
            });
          } else if (entry.punchType === "IL") {
            Object.assign(newEntry, {
              punchTime: newLunchOut,
              punchDate: newLunchOut.startOf("d"),
              punchTimeStamp: newLunchOut.valueOf(),
            });
          }
        }
        // assign punchDate and punchTime on push to keep them as dayjsObject format not string
        newActivities.push(newEntry);
      }
    }

    // When we are changing break time
    // if (
    //   entriesOfShift?.length > 2 &&
    //   formObj?.lunchIncluded &&
    //   formObj?.shiftType === "Regular Shift"
    // ) {

    //   const isILNextDay =
    //     activitiesShift[`newActivityOL`]?.punchTime &&
    //     activitiesShift[`newActivityIL`]?.punchTime &&
    //     getDateOffset(
    //       activitiesShift[`newActivityOL`]?.punchTime,
    //       activitiesShift[`newActivityIL`]?.punchTime
    //     ) > 0;
    //   const tmpPunchDate =
    //     activitiesShift[`newActivityID`]?.punchTime ||
    //     entriesOfShift[0]?.punchDate;

    //   lunchActivities.forEach((punchType) => {
    //     const entry = entriesOfShift.find(
    //       ({ punchType: type }) => type === punchType
    //     );
    //     const activityType = activitiesShift[`newActivity${punchType}`];
    //     for (const key of Object.keys(activityType)) {
    //       if (activityType[key] !== 0 && !activityType[key]) {
    //         delete activityType[key];
    //       }
    //     }

    //     const entryPunchDate = !!activityType?.punchDate
    //       ? activityType?.punchDate
    //       : tmpPunchDate;

    //     const entryPunchTime = activityType?.punchTime
    //       ? activityType?.punchTime
    //       : entry?.punchTime;

    
    //     const newEntry = {
    //       ...entry,
    //       ...activityType,
    //       punchTime: setHourMinute(entryPunchDate, entryPunchTime),
    //       punchTimeStamp: setHourMinute(
    //         entryPunchDate,
    //         entryPunchTime
    //       ).valueOf(),
    //       punchDate: entryPunchDate,
    //     };
    //     if (punchType === "IL" && isILNextDay) {
    //       Object.assign(newEntry, {
    //         punchDate: newEntry.punchTime.add(1, "day").startOf("d"),
    //         punchTime: newEntry.punchTime.add(1, "day"),
    //         punchTimeStamp: newEntry.punchTime.add(1, "day").valueOf(),
    //       });
    //     }

    //     newActivities.push(newEntry);
    //   });
    // }

    // if entry does not have break create break from form values
    if (
      entriesOfShift?.length <= 2 &&
      formObj?.lunchIncluded &&
      formObj?.shiftType === "Regular Shift"
    ) {
      const entry = { ...entriesOfShift[0] };
      const employeeRate =
        selectedJobsite && selectedJobsite?.payrollType !== "Prevailing Wage"
          ? selectedJobsite?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.jobsiteMatch?.rates
          ? entry?.jobsiteMatch?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.employeeRate;

      lunchActivities.forEach((punchType) => {
        let activityType = Object.assign(
          { ...activitiesShift[`newActivity${punchType}`] },
          {
            punchDate: activitiesShift[`newActivity${punchType}`]?.punchDate,
            punchTime: activitiesShift[`newActivity${punchType}`]?.punchTime,
            punchTimeStamp:
              activitiesShift[`newActivity${punchType}`]?.punchTimeStamp,
          }
        );

        for (const key of Object.keys(activityType)) {
          if (activityType[key] !== 0 && !activityType[key]) {
            delete activityType[key];
          }
        }

        const isILNextDay =
          activitiesShift[`newActivityOL`]?.punchTime &&
          activitiesShift[`newActivityIL`]?.punchTime &&
          getDateOffset(
            activitiesShift[`newActivityOL`]?.punchTime,
            activitiesShift[`newActivityIL`]?.punchTime
          ) > 0;

        const activityDate = activityType?.punchDate
          ? activityType?.punchDate
          : entry?.punchDate;

        const newEntry = Object.assign(
          { ...entry },
          {
            ...activityType,
            entryId: uuidv4(),
            activityStatus: activityType?.activityStatus
              ? activityType?.activityStatus
              : entry.activityStatus,
            punchTimeStamp: activityType?.punchTime
              ? setHourMinute(activityDate, activityType?.punchTime).valueOf()
              : entry.punchTimeStamp,
            employeeRate: employeeRate
              ? employeeRate
              : activityType?.employeeRate
              ? activityType?.employeeRate
              : entry?.employeeRate,
            punchDate: activityDate,
            punchTime: activityType?.punchTime
              ? setHourMinute(activityDate, activityType?.punchTime)
              : setHourMinute(activityDate, entry?.punchTime),
          }
        );

        // When there is an overnight break we add one day to the time
        if (isILNextDay && punchType === "IL") {
          Object.assign(newEntry, {
            punchDate: newEntry.punchTime.add(1, "day").startOf("d"),
            punchTime: newEntry.punchTime.add(1, "day"),
            punchTimeStamp: newEntry.punchTime.add(1, "day").valueOf(),
          });
        }
        // assign punchDate and punchTime on push to keep them as dayjsObject format not string
        newActivities.push(newEntry);
      });
    }
  }

  // update rowData with new entries and add new activities to rowData
  const newRowData = rowData
    .flatMap((activity) => {
      let activityIndex = newActivities.findIndex(
        ({ entryId }) => entryId === activity?.entryId
      );
      let newActivity = newActivities?.[activityIndex];
      if (newActivity) {
        newActivities = newActivities.filter(
          ({ entryId }) => entryId !== newActivity?.entryId
        );

        editedEntries.push(newActivity);
        oldEditedEntries.push(activity);
        return newActivity;
      } else if (!entriesToRemove.includes(activity?.entryId)) {
        return activity;
      } else {
        return [];
      }
    })
    .concat(newActivities);

  const removedRowData = (rowData || []).filter(({ entryId }) =>
    entriesToRemove?.includes(entryId)
  );

  const removeActions = {
    newEntries: [],
    editedEntries: [],
    removedEntries: [],
  };

  const editActions = {
    editedEntries: [],
    newEditedEntries: [],
  };

  removedRowData.forEach((row) => {
    let editIncluded = entriesActions?.editedEntries.findIndex(
      (el) => el?.entryId === row?.entryId
    );
    let newIncluded = entriesActions?.newEntries.findIndex(
      (el) => el?.entryId === row?.entryId
    );

    if (editIncluded > -1) {
      Object.assign(removeActions, {
        ["editedEntries"]: removeActions.editedEntries.concat(row),
      });
    } else if (newIncluded > -1) {
      Object.assign(removeActions, {
        ["newEntries"]: removeActions.newEntries.concat(row),
      });
    } else {
      Object.assign(removeActions, {
        ["removedEntries"]: removeActions.removedEntries.concat(row),
      });
    }
  });

  editedEntries.forEach((row) => {
    let editIncluded = (entriesActions?.editedEntries || []).findIndex(
      (el) => el?.entryId === row?.entryId
    );
    if (editIncluded === -1) {
      Object.assign(editActions, {
        ["newEditedEntries"]: editActions.newEditedEntries.concat(row),
      });
    } else {
      Object.assign(editActions, {
        ["editedEntries"]: editActions.newEditedEntries.concat(row),
      });
    }
  });

  addActionToRegister({
    type: "massChanges",
    massChangeActions: {
      newEntries: newActivities,
      editedEntries: {
        prev: oldEditedEntries,
        curr: editedEntries,
        editActions,
      },
      removedEntries: removeActions,
    },
  });

  addEntryAction({
    type: "remove",
    entry: removedRowData,
  });
  addEntryAction({ type: "edit", entry: editedEntries });
  addEntryAction({ type: "new", entry: newActivities });

  setRowData(newRowData);
}
