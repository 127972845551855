import React, { useState, useMemo, useEffect, useCallback } from "react";
import { AutoComplete, Form, Modal, Tooltip } from "antd";
import { MondayButton } from "../../../../../../commonComponents";
import {
  antdModalProps,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../utils";
import { AnimatedCheckBox } from "../../../../../Fleet/components/InputComponent/components";
import { getRandomColor } from "../../../../../utils";
import { apiRoutes, fetchData } from "../../../../../Fleet/utils";
import { formatObjectSamples } from "../../../../utils";
import { useLocation } from "react-router-dom";
import { requestAllPages } from "../../../../../../../utils/paginationFetchData";
import { LoadableComp } from "../../../../../XComponents";
import { useRecordId, useProgramFields } from "../../../../../../../hooks";
import { uuidRegex } from "../../../../../Documentation/View/documentationViewData";
import { isArray } from "chart.js/helpers";
import { camelCase, capitalize } from "lodash";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { programFields } from "../../../../../../../actions";
import { XIcon } from "../../../../../Communication/assets";
import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";

/**
 * * @EneaXharau ->
 * * @param {string} word
 * * @returns a string that is spaced out and capitalized the first letter
 */
export const displayKey = (word = "") => {
  const capitalCharSplit = word.split(/(?=[A-Z])/);
  capitalCharSplit[0] = capitalize(capitalCharSplit[0]);
  return capitalCharSplit.join(" ");
};

const GeneralReportsFiltersModal = ({ visible, setVisible, isDarkMode }) => {
  let location = useLocation();
  const recordId = useRecordId();
  const [form] = Form.useForm();
  const formattedPath = location.pathname.replace(uuidRegex, "");
  const [keysToFilter, setKeysToFilter] = useState([]);
  const [sampleObjects, setSampleObjects] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedAll, setSelectAll] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const { ["Reports Integration Config"]: reportsIntegrationConfig } =
    useProgramFields();
  const { categoryName, filters, partitionKeys } = useMemo(
    () => reportsIntegrationConfig?.[formattedPath] || {},
    [reportsIntegrationConfig, location]
  );
  const dispatch = useDispatch();
  /**
   * * @EneaXharau -> Fetches sampleObjects from DynamoDB
   * * 1. It formats them
   * * 2. It filters them by categoryName (ex.: leads)
   * * @returns The filtered array by categoryName
   */
  const sampleObjectsHandler = async () => {
    const fetchedData = await fetchData(apiRoutes.sampleObjects);
    const sampleObjectsByCategory = formatObjectSamples(fetchedData).filter(
      (el) => {
        switch (categoryName) {
          case "Clients":
            {
              return el.name === "Accounts";
            }
            break;
          case "Tasks":
            {
              return el.name === "TasksManager";
            }
            break;
          case "Permit-Drawings": {
            return el.name === "PermitDrawings";
          }
          case "Accounting": {
            return el.name === "Invoice";
          }
          default:
            return el.name === categoryName;
            break;
        }
      }
    )[0]?.data[0];
    return sampleObjectsByCategory;
  };

  /**
   * * @EneaXharau -> Function that takes sampleObjects keys
   * * 1. It maps through them and the @returns value
   * * 2. Filters said keys by 3 conditions
   * * * * * - If it already exists in filters
   * * * * * - If it it includes id in its name
   * * * * * - If it is a string, boolean, or int/number
   * * is a filtered Array with strings for elements
   * * @returns The keys of the filtered objects in an Array of strings
   */
  const filterSampleObjects = async () => {
    let object = {};
    const keysTemp = Object.keys(sampleObjects);
    const camelCasedCategoryName = camelCase(categoryName).includes("clients")
      ? "accounts"
      : camelCase(categoryName).includes("tasks")
      ? "tasksManager"
      : camelCase(categoryName).includes("permit")
      ? "permitdrawings"
      : camelCase(categoryName).includes("inspection")
      ? "inspectionsView/:id"
      : camelCase(categoryName);
    keysTemp.map((key) => {
      if (!!filters && !!filters[camelCasedCategoryName]) {
        if (
          !filters[camelCasedCategoryName].some((elem) => {
            return elem.formItemName === key;
          })
        ) {
          switch (typeof sampleObjects[key]) {
            case "string":
              {
                if (!key.toLowerCase().includes("id"))
                  object = { ...object, [key]: sampleObjects[key] };
              }
              break;
            case "number":
              {
                if (!key.toLowerCase().includes("id"))
                  object = { ...object, [key]: sampleObjects[key] };
              }
              break;
            case "boolean":
              {
                if (!key.toLowerCase().includes("id"))
                  object = { ...object, [key]: sampleObjects[key] };
              }
              break;
            default:
              break;
          }
        }
      } else {
        switch (typeof sampleObjects[key]) {
          case "string":
            {
              if (!key.toLowerCase().includes("id"))
                object = { ...object, [key]: sampleObjects[key] };
            }
            break;
          case "number":
            {
              if (!key.toLowerCase().includes("id"))
                object = { ...object, [key]: sampleObjects[key] };
            }
            break;
          case "boolean":
            {
              if (!key.toLowerCase().includes("id"))
                object = { ...object, [key]: sampleObjects[key] };
            }
            break;
          default:
            break;
        }
      }
    });
    return Object.keys(object) || [];
  };

  const handleCancel = () => {
    form.setFieldsValue({});
    setVisible(false);
    setSelectAll(false);
    setDisabled(false);
  };

  /**
   * * @EneaXharau -> Function to push the new data to DynamoDB
   * * @param {String} name
   * * @param {Object} tempFilter
   * * In the end it updates programFields in Redux store to re-render GeneralReportsFilters.jsx
   * * also cleans up the modal
   */
  const handleDBpush = async (name, tempFilter) => {
    if (!!tempFilter[name] && !!name) {
      await API.patch(
        "programFields",
        `/programFields/82e0c5b9-ab6e-4dd8-a5ff-581d172ec8bb`,
        {
          body: {
            fieldOptions: {
              ...reportsIntegrationConfig,
              [`/${name}`]: {
                ...reportsIntegrationConfig[`/${name}`],
                filters: tempFilter,
              },
            },
          },
        }
      )
        .then(async () => {
          showSuccessMsg();
          await API.get("programFields", "/programFields").then((res) => {
            dispatch(programFields(res));
          });
          handleCancel();
        })
        .catch((e) => {
          showErrorMsg({ content: e });
        });
    }
  };

  const searchHandler = (e) => {
    let substring = e;
    let filteredData = keysToFilter
      ?.map((el) => displayKey(el))
      ?.filter?.((entry) => entry.toLowerCase().includes(substring));
    setInputValue(substring);
    setSearchTerm(filteredData);
    substring !== "" ? setFilterActive(true) : setFilterActive(false);
  };

  /**
   * * @EneaXharau -> Function that runs when you hit submit
   * * 1. It gets the checked Checkboxes from form.getFieldsValue()
   * * * puts the keys in an Array of Strings
   * * 2. It maps over them with many validations and conditions
   * * * * - It checks if reportsIntegrationConfig includes "filters" key
   * * * * * - It checks if if tempFilter is neither null, NaN, undefined and
   * * * * * * has a key = to categoryName
   * * * * * - It checks if "filters" key contains said categoryName
   * * 3. In the end it runs the function handleDBpush() to push it to DynamoDB
   */
  const handleOk = async () => {
    const formValues = form.getFieldsValue();
    const formKeys = Object.keys(formValues);
    const camelCasedCategoryName = camelCase(categoryName).includes("clients")
      ? "accounts"
      : camelCase(categoryName).includes("tasks")
      ? "tasksManager"
      : camelCase(categoryName).includes("permit")
      ? "permitdrawings"
      : camelCase(categoryName).includes("inspection")
      ? "inspectionsView/:id"
      : camelCase(categoryName);
    let tempFilter = {};
    showLoadingMsg();
    if (formKeys.some((el) => formValues[el])) {
      formKeys.map((el) => {
        if (formValues[el]) {
          if (
            !Object.keys(
              reportsIntegrationConfig[`/${camelCasedCategoryName}`]
            ).includes("filters")
          ) {
            if (!!tempFilter && !!tempFilter[camelCasedCategoryName]) {
              tempFilter = {
                ...tempFilter,
                [camelCasedCategoryName]: [
                  ...tempFilter[camelCasedCategoryName],
                  {
                    formItemName: el,
                    label: displayKey(el),
                    placeholder: "Select...",
                    type: "select",
                  },
                ],
              };
            } else {
              tempFilter = {
                [camelCasedCategoryName]: [
                  {
                    formItemName: el,
                    label: displayKey(el),
                    placeholder: "Select...",
                    type: "select",
                  },
                ],
              };
            }
          } else {
            if (
              !filters[camelCasedCategoryName].some((elem) => {
                return elem.formItemName === el;
              })
            ) {
              if (!!tempFilter[camelCasedCategoryName] && !!tempFilter) {
                tempFilter = {
                  ...tempFilter,
                  [camelCasedCategoryName]: [
                    ...tempFilter[camelCasedCategoryName],
                    {
                      formItemName: el,
                      label: displayKey(el),
                      placeholder: "Select...",
                      type: "select",
                    },
                  ],
                };
              } else {
                tempFilter = {
                  ...filters,
                  [camelCasedCategoryName]: [
                    ...filters[camelCasedCategoryName],
                    {
                      formItemName: el,
                      label: displayKey(el),
                      placeholder: "Select...",
                      type: "select",
                    },
                  ],
                };
              }
            }
          }
        }
      });
      handleDBpush(camelCasedCategoryName, tempFilter);
    } else {
      showErrorMsg({ content: "You havent selected at least one" });
    }
  };

  const submitButtonOpenHandler = useCallback(() => {
    const formValues = form.getFieldsValue();
    const formKeys = Object.keys(formValues);
    if (formKeys?.some((el) => formValues[el] === true)) {
      setDisabled(() => {
        return true;
      });
    } else {
      setDisabled(() => {
        return false;
      });
    }
  }, [disabled]);

  const selectAll = useCallback(() => {
    let formValues = form.getFieldsValue();
    const formKeys = Object.keys(formValues);
    if (selectedAll === false) {
      let selectedAllTemp = formKeys.map((el) => {
        const newObj = { [el]: true };
        return { ...newObj, [el]: true };
      });
      const result = Object.assign({}, ...selectedAllTemp);
      setSelectAll(true);
      form.setFieldsValue(result);
    } else {
      setSelectAll(false);
      form.setFieldsValue({});
    }
  }, [selectedAll]);

  useEffect(() => {
    sampleObjectsHandler().then((r) => {
      setSampleObjects(r);
    });
  }, [reportsIntegrationConfig, visible]);

  useEffect(() => {
    if (!!sampleObjects) {
      filterSampleObjects().then((r) => {
        setKeysToFilter(r);
      });
    }
  }, [sampleObjects]);

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        setVisible,
        title: "Add new filter",
        wrapClassName: `cardLayoutModal documentationTemplatesModal ${
          isDarkMode && "documentationTemplatesModalDark"
        }`,
        onCancel: handleCancel,
        closeIcon: <XIcon />,
      }}
    >
      <Form {...{ form, preserve: false }}>
        <div className="documentationTemplatesModalContainer">
          {!!keysToFilter && keysToFilter?.length > 0 && (
            <div className="search-div">
              <AutoComplete
                className="search-comp"
                placeholder="Search filters"
                // prefixIcon={<SearchOutlined />}
                onChange={(e) => {
                  searchHandler(e);
                }}
              ></AutoComplete>
              <SearchOutlined className="search-logo" />
            </div>
          )}
          <div className="checkboxContainer-123">
            <div className="checkBoxGrid">
              {filterActive
                ? searchTerm.map((key) => {
                    const color = "#71CF48";
                    return (
                      <AnimatedCheckBox
                        {...{
                          key: key,
                          label: (
                            <div className="checkboxLabel">
                              <div className="templateLabel">
                                {displayKey(key)}
                              </div>
                              <Tooltip
                                {...{
                                  placement: "right",
                                  color,
                                  mouseEnterDelay: 0.5,
                                  mouseLeaveDelay: 0,
                                }}
                              ></Tooltip>
                            </div>
                          ),
                          form,
                          formItemName: key,
                          containerClassName: "templateCheckbox",
                          tooltipVisible: false,
                          primaryStyle: { backgroundColor: color },
                          secondaryStyle: { backgroundColor: "#FFF" },
                          onButtonReveal: submitButtonOpenHandler,
                          selectedAllCheck: selectedAll,
                        }}
                      />
                    );
                  })
                : keysToFilter.map((key) => {
                    const color = "#71CF48";
                    return (
                      <AnimatedCheckBox
                        {...{
                          key: key,
                          label: (
                            <div className="checkboxLabel">
                              <div className="templateLabel">
                                {displayKey(key)}
                              </div>
                              <Tooltip
                                {...{
                                  placement: "right",
                                  color,
                                  mouseEnterDelay: 0.5,
                                  mouseLeaveDelay: 0,
                                }}
                              ></Tooltip>
                            </div>
                          ),
                          form,
                          formItemName: key,
                          containerClassName: "templateCheckbox",
                          tooltipVisible: false,
                          primaryStyle: { backgroundColor: color },
                          secondaryStyle: { backgroundColor: "#FFF" },
                          onButtonReveal: submitButtonOpenHandler,
                          selectedAllCheck: selectedAll,
                        }}
                      />
                    );
                  })}
            </div>
          </div>
          <div className="templateModalActionButtons">
            <MondayButton
              {...{
                className: "rejectButton",
                Icon: <XIcon />,
                onClick: handleCancel,
              }}
            >
              Cancel
            </MondayButton>
            {disabled && (
              <MondayButton
                {...{
                  className: "submitButton",
                  Icon: <CheckOutlined />,
                  onClick: handleOk,
                }}
              >
                Submit
              </MondayButton>
            )}
            {!!keysToFilter && keysToFilter.length > 0 && (
              <MondayButton
                {...{
                  className: "mondayButtonBlue",
                  style: { marginLeft: 28 },
                  onClick: selectAll,
                  Icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.875"
                      height="16.243"
                      viewBox="0 0 22.875 16.243"
                    >
                      <path
                        id="checklist_FILL1_wght400_GRAD0_opsz48"
                        d="M17.9,13.819a.871.871,0,1,1,0-1.742H26.6a.871.871,0,1,1,0,1.742Zm0,9.289a.871.871,0,1,1,0-1.742H26.6a.871.871,0,1,1,0,1.742ZM7.764,15.416l-2.9-2.9a.842.842,0,0,1,0-1.219.786.786,0,0,1,.61-.247.952.952,0,0,1,.61.247l2.293,2.264L12.96,8.971a.786.786,0,0,1,.61-.247A.891.891,0,0,1,14.18,9a.842.842,0,0,1,0,1.219l-5.2,5.2a.842.842,0,0,1-1.219,0Zm0,9.289-2.9-2.9a.842.842,0,0,1,0-1.219.786.786,0,0,1,.61-.247.952.952,0,0,1,.61.247l2.293,2.264,4.587-4.587a.786.786,0,0,1,.61-.247.891.891,0,0,1,.61.276.842.842,0,0,1,0,1.219l-5.2,5.2a.842.842,0,0,1-1.219,0Z"
                        transform="translate(-4.6 -8.724)"
                        fill="#fff"
                      />
                    </svg>
                  ),
                }}
              >
                {selectedAll ? "Deselect All" : "Select All"}
              </MondayButton>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default GeneralReportsFiltersModal;
