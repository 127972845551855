import dayjs from "dayjs";
import { sortBy, reverse } from "lodash";

export const formatDobData = (data, unslicedData, selectedCategory) => {
  const formattedData = {
    ...unslicedData,
    [selectedCategory]:
      //the case when selected category is job application filing
      selectedCategory === "job application filing"
        ? reverse(
            sortBy(
              data
                ?.filter(({ filing_date }) => !!filing_date)
                ?.map((jobApplicationFiling) => ({
                  ...jobApplicationFiling,
                  applicant_full_name: `${jobApplicationFiling?.applicant_first_name} ${jobApplicationFiling?.applicant_last_name}`,
                  filing_date: dayjs(
                    jobApplicationFiling?.filing_date
                  ).valueOf(),
                  current_status_date: dayjs(
                    jobApplicationFiling?.current_status_date
                  ).valueOf(),
                  filing_representative_full_name: `${jobApplicationFiling?.filing_representative_first_name} ${jobApplicationFiling?.filing_representative_last_name}`,
                  filing_representative_full_address: `${jobApplicationFiling?.filing_representative_street_name}, ${jobApplicationFiling?.filing_representative_city} ${jobApplicationFiling?.filing_representative_zip}`,
                  initial_cost: +jobApplicationFiling?.initial_cost,
                  Coordinates: `Lat: ${jobApplicationFiling?.latitude} Lng: ${jobApplicationFiling?.longitude}`,
                  jobsite_full_address: `${jobApplicationFiling?.house_no} ${jobApplicationFiling?.street_name}, ${jobApplicationFiling?.borough}, NY ${jobApplicationFiling?.zip}`,
                })),
              ["filing_date"]
            )
          )
        : //the case when selected category is issued permits
        selectedCategory === "issued permits"
        ? reverse(
            sortBy(
              data?.map((issuedPermits) => ({
                ...issuedPermits,
                dobrundate: dayjs(issuedPermits?.dobrundate)?.valueOf(),
                filing_date: dayjs(issuedPermits?.filing_date)?.valueOf(),
                issuance_date: dayjs(issuedPermits?.issuance_date)?.valueOf(),
                expiration_date: dayjs(
                  issuedPermits?.expiration_date
                )?.valueOf(),
                job_start_date: dayjs(issuedPermits?.job_start_date)?.valueOf(),
                jobsite_full_address: `${issuedPermits?.house__} ${issuedPermits?.street_name}, ${issuedPermits?.borough}, NY`,
                owners_full_name: `${issuedPermits?.owner_s_first_name} ${issuedPermits?.owner_s_last_name}`,
                full_coordinates: `Lat: ${issuedPermits?.gis_latitude} Lng: ${issuedPermits?.gis_longitude}`,
                permit_fullName: `${issuedPermits?.permittee_s_first_name} ${issuedPermits?.permittee_s_last_name}`,
              })),
              ["dobrundate"]
            )
          )
        : //the case when selected category is approved permits
        selectedCategory === "approved permits"
        ? reverse(
            sortBy(
              data?.map((approvedPermit) => ({
                ...approvedPermit,
                issued_date: dayjs(approvedPermit?.issued_date)?.valueOf(),
                approved_date: dayjs(approvedPermit?.approved_date)?.valueOf(),
                expired_date: dayjs(approvedPermit?.expired_date)?.valueOf(),
                owners_full_name: approvedPermit?.owner_name,
                jobsite_full_address: `${approvedPermit?.house_no} ${approvedPermit?.street_name}, ${approvedPermit?.borough}, NY`,
                applicant_fullName: `${approvedPermit?.applicant_first_name} ${approvedPermit?.applicant_last_name}`,
                filing_representative_fullName: `${approvedPermit?.filing_representative_first_name} ${approvedPermit?.filing_representative_last_name}`,
              })),
              ["approved_date"]
            )
          )
        : //the case when selected category is electrical permits
          reverse(
            sortBy(
              data?.map((electricalPermits) => ({
                ...electricalPermits,
                permit_issued_date: dayjs(
                  electricalPermits?.permit_issued_date
                )?.valueOf(),
                filing_date: dayjs(electricalPermits?.filing_date)?.valueOf(),
                completion_date: dayjs(
                  electricalPermits?.completion_date
                )?.valueOf(),
                disability_expiration_date: dayjs(
                  electricalPermits?.disability_expiration_date
                )?.valueOf(),
                job_start_date: dayjs(
                  electricalPermits?.job_start_date
                )?.valueOf(),
                worker_comp_expiration_date: dayjs(
                  electricalPermits?.worker_comp_expiration_date
                )?.valueOf(),
                general_liability_expiration: dayjs(
                  electricalPermits?.general_liability_expiration
                )?.valueOf(),
                jobsite_full_address: `${electricalPermits?.house_number} ${electricalPermits?.street_name}, ${electricalPermits?.borough}, NY ${electricalPermits?.zip_code}`,
                owners_full_name: `${electricalPermits?.owner_first_name} ${electricalPermits?.owner_last_name}`,
                full_coordinates: `Lat: ${electricalPermits?.gis_latitude} Lng: ${electricalPermits?.gis_longitude}`,
                applicant_fullName: `${electricalPermits?.applicant_first_name} ${electricalPermits?.applicant_last_name}`,
              })),
              ["permit_issued_date"]
            )
          ),
  };

  return formattedData;
};
