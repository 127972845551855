import React, { useState } from "react";

import {
  TaskDatesInput,
  TaskFormFields,
  TaskReminderInput,
} from "../../../../SidebarPages/TaskManager/Tasks/TasksModals/NewTask/components";
import { customProps, remindMeInitialState } from "./helpers";

const NewTask = ({ form, savedRecord, category }) => {
  const [remindMe, setRemindMe] = useState(remindMeInitialState);
  const customTaskProps = customProps(savedRecord, category)?.[category];

  return (
    <div style={{ paddingTop: "30px" }}>
      <TaskFormFields
        {...{
          form,
          ...(customTaskProps && { customTaskProps }),
        }}
      />
      <TaskDatesInput {...{ form }} />
      <TaskReminderInput {...{ form, remindMe, setRemindMe }} />
    </div>
  );
};

export default NewTask;
