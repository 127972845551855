import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { API } from "aws-amplify";

import { NewInspectionModal } from "../../../../../SidebarPages/Inspections/components/BasePage/modals";
import { filterTables, lazyFetch } from "../../../../../../utils";

const NewInspectionShortcut = ({
  visible,
  setVisible,
  requestId,
  propedRowData,
  requestObject = null,
  isNextStep,
  afterSaveRequest = async () => {},
}) => {
  const [currentProject, setCurrentProject] = useState("");
  const [form] = Form.useForm();
  const [inspectors, setInspectors] = useState([]);
  const [projects, setProjects] = useState([]);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [estimations, setEstimations] = useState([]);

  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const { programFields } = useSelector((state) => state.programFields);

  useEffect(() => {
    async function fetchNewInspectionData() {
      const [
        { value: { projects } = [] },
        { value: inspector = [] },
        { value: estimations = [] },
      ] = await Promise.allSettled([
        API.get("projects", `/projects`),
        filterTables("userConfiguration", "groupName", "Inspector"),
        lazyFetch({
          tableName: "estimations",
          listOfKeys: ["estimationId", "isChangeOrder"],
        }),
      ]);

      setProjects(projects?.map((item) => ({ ...item })));
      setInspectors(
        inspector?.map(({ cognitoUserId, nameOfUser }) => ({
          name: nameOfUser,
          cognitoUserId,
        }))
      );
      setEstimations(estimations);

      setInspectionTypes(
        programFields
          .find(({ fieldName }) => fieldName === "Inspection Types")
          .fieldOptions?.map(({ typeName }) => typeName)
      );
    }
    fetchNewInspectionData();
  }, []);

  return (
    <>
      <Form form={form}>
        <NewInspectionModal
          {...{
            title: "Inspection",
            visible,
            setVisible,
            isTab: false,
            form,
            currentProject,
            setCurrentProject,
            requestId,
            afterSaveRequest,
            otherProps: {
              inspectors,
              propedRowData,
              requestObject,
              projects,
              inspectionTypes,
              allDimensions:
                serviceDefinitions?.map(({ serviceName, dimensions }) => ({
                  serviceName,
                  dimensions,
                })) ?? [],
              estimations,
              setDescription: () => {},
              setDescriptionModalVisible: () => {},
              isNextStep,
            },
          }}
        />
      </Form>
    </>
  );
};
export default NewInspectionShortcut;
