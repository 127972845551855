import { message } from "antd";

export const customFloorSignificance = (value, siginificance = 1) => {
  let Z = Math.floor(value);
  return Z + (value - Z >= siginificance ? 1 : 0);
};

// ONLY POSITIVE
export const getNumberValOnly = (val) => {
  const numInput = Number(val);
  if (isNaN(numInput)) {
    message.error("Invalid number input");
    return 0;
  } else {
    if (val === null || val === undefined || val === "") {
      return 0;
    } else {
      return numInput < 0 ? 0 : numInput;
    }
  }
};
