import { message } from "antd";
import { API } from "aws-amplify";

import { template } from "../datas";
import {
  filterTables,
  sendNotification,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../utils";
import { updateExistingPayments } from "../components/NewCreditMemo/utils";
import { updateClientBalance } from "../../../../Accounting/Tabs/Payments/utils/paymentUtils";

export const numberToFixed2 = (value) =>
  parseFloat(parseFloat(value).toFixed(2));

export const defaultColDef = {
  editable: true,
  autoWidth: true,
  resizable: true,
  statusBar: {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
    ],
  },
  headerComponentParams: {
    template,
  },
};

const updateServiceItems = (items, chargeToDeleteId, categoryFrom) => {
  return items?.map((item) => {
    const totalPaidPrice = item?.charges?.reduce(
      (prev, curr) => prev + curr?.chargeAmount,
      0
    );
    const newCharges =
      item?.charges?.filter(
        (chargeItem) => chargeItem.chargeId !== chargeToDeleteId
      ) || [];
    const newChargeStatus =
      totalPaidPrice >=
      (categoryFrom === "Rental" ? item.appliedAmount : item.priceAmount)
        ? "fully"
        : totalPaidPrice > 0
        ? "partially"
        : NaN;
    return {
      ...item,
      charges: newCharges,
      charged: newChargeStatus,
    };
  });
};

const updateHoistService = (service, chargeToDeleteId, categoryFrom) => {
  const newCharges =
    service?.charges?.filter(
      (chargeItem) => chargeItem.chargeId !== chargeToDeleteId
    ) || [];
  const totalPaidPrice = service?.charges?.reduce(
    (prev, curr) => prev + curr?.chargeAmount,
    0
  );
  const newChargeStatus =
    totalPaidPrice >=
    (categoryFrom === "Rental" ? service.appliedAmount : service.priceAmount)
      ? "fully"
      : totalPaidPrice === 0
      ? NaN
      : "partially";
  return {
    ...service,
    charges: newCharges,
    charged: newChargeStatus,
  };
};

const updateServiceAddons = (serviceAddons, chargeToDeleteId, categoryFrom) => {
  return serviceAddons?.map((addon) => {
    const newCharges =
      addon?.charges?.filter(
        (chargeItem) => chargeItem.chargeId !== chargeToDeleteId
      ) || [];
    const totalPaidPrice = addon?.charges?.reduce(
      (prev, curr) => prev + curr?.chargeAmount,
      0
    );
    const newChargeStatus =
      totalPaidPrice >=
      (categoryFrom === "Rental" ? service.appliedAmount : addon.priceAmount)
        ? "fully"
        : totalPaidPrice === 0
        ? NaN
        : "partially";

    return {
      ...addon,
      charges: newCharges,
      charged: newChargeStatus,
    };
  });
};
const updateAdditionalRentals = (
  rentalTerms,
  chargeToDeleteId,
  categoryFrom
) => {
  const newCharges =
    rentalTerms?.charges?.filter(
      (chargeItem) => chargeItem.chargeId !== chargeToDeleteId
    ) || [];
  const totalPaidPrice = rentalTerms?.charges?.reduce(
    (prev, curr) => prev + curr?.chargeAmount,
    0
  );
  const newChargeStatus =
    totalPaidPrice >=
    (categoryFrom === "Rental"
      ? service.appliedAmount
      : rentalTerms.priceAmount)
      ? "fully"
      : totalPaidPrice === 0
      ? NaN
      : "partially";

  return { ...rentalTerms, charges: newCharges, charged: newChargeStatus };
};

export const updateChargeServices = (otherCharges, chargeToDelete) => {
  const chargeToDeleteId = chargeToDelete?.chargeId;
  const categoryFrom = chargeToDelete.categoryFrom;

  const chargeToUpdate = otherCharges.map((charge) => {
    if (
      (charge.label === "Hoist" || charge.isHoist === true) &&
      categoryFrom === "Estimation"
    ) {
      return {
        ...charge,
        serviceOptions: charge.serviceOptions.map((serviceOption) =>
          Array.isArray(serviceOption)
            ? serviceOption.map((option) =>
                updateHoistService(option, chargeToDeleteId, categoryFrom)
              )
            : updateHoistService(serviceOption, chargeToDeleteId, categoryFrom)
        ),
        serviceAddons:
          charge?.serviceAddons &&
          updateServiceAddons(
            charge.serviceAddons,
            chargeToDeleteId,
            categoryFrom
          ),
        additionalRentalTerms:
          charge?.additionalRentalTerms &&
          charge?.additionalRentalTerms?.newPrice &&
          Object.keys(charge?.additionalRentalTerms).length > 0
            ? (() => {
                const rentalTerms = charge?.additionalRentalTerms;
                rentalTerms &&
                  updateAdditionalRentals(
                    rentalTerms,
                    chargeToDeleteId,
                    categoryFrom
                  );
              })()
            : {},
      };
    } else {
      return {
        ...charge,
        serviceOptions: charge.serviceOptions?.map((serviceOption) =>
          Array.isArray(serviceOption)
            ? serviceOption.map((option) => ({
                ...option,
                items: updateServiceItems(
                  option.items,
                  chargeToDeleteId,
                  categoryFrom
                ),
              }))
            : {
                ...serviceOption,
                items: updateServiceItems(
                  serviceOption.items,
                  chargeToDeleteId,
                  categoryFrom
                ),
              }
        ),
        serviceAddons:
          charge?.serviceAddons &&
          updateServiceAddons(
            charge.serviceAddons,
            chargeToDeleteId,
            categoryFrom
          ),
        additionalRentalTerms:
          charge?.additionalRentalTerms &&
          charge?.additionalRentalTerms?.newPrice &&
          Object.keys(charge?.additionalRentalTerms).length > 0
            ? (() => {
                const rentalTerms = charge?.additionalRentalTerms;
                rentalTerms &&
                  updateAdditionalRentals(
                    rentalTerms,
                    chargeToDeleteId,
                    categoryFrom
                  );
              })()
            : {},
      };
    }
  });
  return chargeToUpdate;
};

export const updateSOVChargeServices = (otherCharges, chargeToDelete) => {
  const chargeToDeleteId = chargeToDelete?.chargeId;
  const categoryFrom = chargeToDelete.categoryFrom;

  const chargeToUpdate = otherCharges.map((charge) => {
    if (charge.label === "Hoist" || charge.isHoist) {
      return {
        ...charge,
        serviceOptions: charge.serviceOptions.map((serviceOption) =>
          serviceOption.map((option) => ({
            ...option,
            amounts: option.amounts.map((service) =>
              updateHoistService(service, chargeToDeleteId, categoryFrom)
            ),
          }))
        ),
      };
    } else {
      return {
        ...charge,
        amounts: updateServiceItems(
          charge.amounts,
          chargeToDeleteId,
          categoryFrom
        ),
      };
    }
  });
  return chargeToUpdate;
};

const updateOtherChargesChargedAmount = (chargeToDelete) => {
  const chargeToDeleteId = chargeToDelete.chargeId;
  const categoryFrom = chargeToDelete.categoryFrom;

  filterTables("charges", "projectId", chargeToDelete.projectId).then(
    (projectCharges) =>
      projectCharges.length > 0 &&
      projectCharges
        .filter(
          (charges) =>
            charges.chargeId !== chargeToDeleteId &&
            charges.categoryFrom === categoryFrom
        )
        .forEach((charge) => {
          const updatedChargeItems =
            categoryFrom === "Schedule Of Value"
              ? updateSOVChargeServices(
                  charge.chargeItems,
                  chargeToDeleteId,
                  categoryFrom
                )
              : updateChargeServices(
                  charge.chargeItems,
                  chargeToDeleteId,
                  categoryFrom
                );

          API.put("charges", `/charges/${charge.chargeId}`, {
            body: {
              chargeItems: updatedChargeItems,
            },
          });
        })
  );
};

export const onDeleteCharge = async (
  chargeToDelete,
  authUser,
  userConfiguration,
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
  hideProgress = () => {},
  saveAddedLogs = () => {}
) => {
  const chargeToDeleteId = chargeToDelete?.chargeId;

  try {
    const categoryTables = {
      Requisition: "applications",
      Rental: "rentals",
      Estimation: "estimations",
      "Schedule Of Value": "scheduleOfValues",
    };
    const table = categoryTables[chargeToDelete?.categoryFrom];
    if (
      chargeToDelete?.categoryFrom === "Estimation" ||
      chargeToDelete?.categoryFrom === "Rental"
    ) {
      await API.get(table, `/${table}/${chargeToDelete?.recordId}`).then(
        (record) => {
          const newServices = updateChargeServices(
            record?.services,
            chargeToDelete
          );
          const newCharges =
            record?.charges?.filter(
              (chargeItem) => chargeItem.chargeId !== chargeToDeleteId
            ) || [];

          API[chargeToDelete?.categoryFrom === "Rental" ? "put" : "patch"](
            table,
            `/${table}/${chargeToDelete?.recordId}`,
            {
              body: {
                services: newServices,
                charges: newCharges,
              },
            }
          );
        }
      );
      if (chargeToDelete?.categoryFrom === "Estimation") {
        API.get("projects", `/projects/${chargeToDelete?.projectId}`).then(
          ({ charges, services }) => {
            const newServices = updateChargeServices(
              services[chargeToDelete?.recordId],
              chargeToDelete
            );
            const newCharges =
              charges?.filter(
                ({ chargeId }) => chargeId !== chargeToDeleteId
              ) || [];

            API.put("projects", `/projects/${chargeToDelete?.projectId}`, {
              body: {
                services: {
                  ...services,
                  [chargeToDelete?.recordId]: newServices,
                },
                charges: newCharges,
              },
            });
          }
        );
      }
    } else if (chargeToDelete?.categoryFrom === "Requisition") {
      API.patch(table, `/${table}/${chargeToDelete?.recordId}`, {
        body: {
          charged: false,
        },
      });
      chargeToDelete?.includedRentals?.forEach((id) => {
        API.put("rentals", `/rentals/${id}`, {
          body: {
            chargedFromReq: "",
            charges: [],
          },
        });
      });
    } else if (chargeToDelete?.categoryFrom === "Schedule Of Value") {
      await API.get(table, `/${table}/${chargeToDelete?.recordId}`).then(
        (record) => {
          const newServices = updateSOVChargeServices(
            record?.services,
            chargeToDelete
          );
          const newCharges =
            record?.charges?.filter(
              (chargeItem) => chargeItem.chargeId !== chargeToDeleteId
            ) || [];

          API.put(table, `/${table}/${chargeToDelete?.recordId}`, {
            body: {
              services: newServices,
              charges: newCharges,
            },
          });
        }
      );
    }
  } catch (err) {
    console.error("Patch Error:", err);
  }

  hideProgress("sendingNotification");
  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });

  await API.del("charges", `/charges/${chargeToDeleteId}`)
    .then(async () => {
      updateOtherChargesChargedAmount(chargeToDelete);
      saveAddedLogs({
        recordId: chargeToDeleteId,
        recordName: `Charge ${chargeToDelete?.chargeNumber}`,
        category: "Charges",
        actionType: "Delete",
        topic: `${chargeToDelete?.projectAddress} - ${chargeToDelete?.categoryFrom} ${chargeToDelete?.categoryNumber}`,
      });

      const project = await filterTables(
        "projects",
        "projectId",
        chargeToDelete.projectId
      );
      const account = await filterTables(
        "accounts",
        "accountId",
        project?.[0]?.accountId
      );

      if (account.length > 0) {
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });
        hideProgress("sendingNotification", false);
        await sendNotification({
          id: "97",
          action: "onChargeDeletion",
          team: account?.[0]?.teamsConfiguration || [],
          otherKeys: {
            topicName: account?.[0]?.accountName,
            chargeNumber: chargeToDelete?.chargeNumber,
            chargeType: "Charge",
            categoryFrom: chargeToDelete.categoryFrom,
          },
          recordId: chargeToDelete.chargeId,
          authenticatedUser: authUser,
          userConfiguration,
        }).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      } else {
        updateProgressStatus({ updatingRecord: "finished" });
      }
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Deletion Error: ", err);
      message.error("Something went wrong!");
      return false;
    });
  message.success("Charge deleted successfully!");
};

export const onDeleteChargeFromInvoice = async (chargeToDelete, accountId) => {
  const chargeItems = chargeToDelete?.chargeItems;
  const chargeToDeleteId = chargeToDelete?.chargeId;
  showLoadingMsg({ content: "Deleting charge. Please Wait..." });
  const paymentData = await filterTables("payments", "accountId", accountId);

  try {
    const invoicesId = Array?.from(
      new Set(chargeItems?.map(({ invoiceId }) => invoiceId))
    );

    const invoices = await Promise.all(
      invoicesId?.map((invoiceId) =>
        API.get("invoices", `/invoices/${invoiceId}`)
      )
    );
    invoices?.forEach(
      async ({
        invoiceId,
        invoiceItems,
        charges,
        amount,
        totalInvoiceAmount,
      }) => {
        const paymentAmount = paymentData
          .filter((payment) =>
            payment.invoices.some((invoice) => invoice.invoiceId === invoiceId)
          )
          .reduce(
            (total, payment) =>
              total +
              payment.invoices.reduce(
                (invoiceTotal, invoice) =>
                  invoice.invoiceId === invoiceId
                    ? invoiceTotal + invoice.invoicePaidAmount
                    : invoiceTotal,
                0
              ),
            0
          );

        const paidPercentage = parseFloat(paymentAmount / totalInvoiceAmount);

        const newInvoiceItems = invoiceItems.map((invoiceItem) => {
          const newCredits =
            invoiceItem?.credits.filter(
              (item) => item.chargeId !== chargeToDeleteId
            ) || [];

          const deletedCharges =
            invoiceItem?.credits.filter(
              (item) => item.chargeId === chargeToDeleteId
            ) || [];

          const deletedAmountFromInvoice = deletedCharges.reduce(
            (total, credit) => total + credit.creditedAmount,
            0
          );

          const paidAmount =
            paidPercentage * (invoiceItem.scopeAmount || invoiceItem.amount);

          const deletedTaxAmountFromInvoice = deletedCharges.reduce(
            (total, credit) => total + credit.creditedTaxAmount,
            0
          );

          const otherChargesCreditedAmount = newCredits.reduce(
            (total, credit) => total + credit.creditedAmount,
            0
          );
          const otherChargesCreditedTaxAmount = newCredits.reduce(
            (total, credit) => total + credit.creditedTaxAmount,
            0
          );

          const newInvoiceAmount =
            (invoiceItem.scopeAmount || invoiceItem.amount) -
            otherChargesCreditedAmount -
            paidAmount;
          const newInvoiceTaxAmount =
            (invoiceItem.scopeTaxAmount || invoiceItem.taxAmount) -
            otherChargesCreditedTaxAmount;

          return {
            ...invoiceItem,
            amount: newInvoiceAmount,
            taxAmount: newInvoiceTaxAmount,
            total: newInvoiceAmount + newInvoiceTaxAmount,
            credits: newCredits,
            creditedAmount: otherChargesCreditedAmount,
            creditedTaxAmount: otherChargesCreditedTaxAmount,
          };
        });

        const newAmountDue = newInvoiceItems?.reduce(
          (total, invoiceItem) => total + (invoiceItem?.total || 0),
          0
        );
        const newCharges =
          charges?.filter(({ chargeId }) => chargeId !== chargeToDeleteId) ||
          [];

        const creditCharges = newCharges.filter(
          (charge) => charge.chargeType === "Credit Memo"
        );

        const newCreditedAmount = creditCharges.reduce(
          (total, charge) => total + charge.chargeAmount,
          0
        );
        showLoadingMsg({
          content: "Updating Invoices and Payments. Please Wait...",
        });

        await API.put("invoices", `/invoices/${invoiceId}`, {
          body: {
            amountDue: newAmountDue,
            invoiceItems: newInvoiceItems,
            charges: newCharges,
          },
        }).then(() => {
          updateExistingPayments({
            paymentData,
            invoiceId,
            newAmountDue,
            newCreditedAmount,
          });
        });
      }
    );
  } catch (err) {
    console.log({ err });
    showErrorMsg({ content: "There was a problem deleting charge." });
  }
  API.del("charges", `/charges/${chargeToDeleteId}`)
    .then(async () => {
      await updateClientBalance({
        accountId: accountId,
        paymentAmount: chargeToDelete.chargeAmount,
      });
      showSuccessMsg({ content: "Charge deleted successfully" });
    })
    .catch(() =>
      showErrorMsg({ content: "There was a problem deleting charge." })
    );
};
