import { AutoComplete, Form, Select, Tooltip, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useResponsive } from "../../../../../hooks";
import { FilePreviewModal } from "../../../Documentation/View/components";
import { DeleteIcon } from "../../../DynamicView/src";
import { MaximazeIcon, MinimizeIcon, XIcon } from "../../assets";
import { getLength } from "../../functions";
import { LoadingProvider } from "./components/LoadingProvider";
import EmailBoxHeaderInputs from "./components/emailbox-header-inputs-view/EmailBoxHeaderInputs";
import EmailBoxFooter from "./components/footer/EmailBoxFooter/EmailBoxFooter";
import {
  EmailBoxBodyProvider,
  useEmailBoxBody,
} from "./providers/EmailBoxBodyProvider";
import { EmailBoxProvider, useEmailBox } from "./providers/EmailBoxProvider";
import { DocumentationTypesProvider } from "./providers/DocumentationTypesProvider";
import {
  EmailTemplatesProvider,
  useEmailTemplates,
} from "./providers/EmailTemplatesProvider";
import {
  InitialEmailBoxPropsProvider,
  useInitialEmailBoxProps,
} from "./providers/InitialEmailBoxProvider";
import {
  fetchSignature,
  getDraft,
  handleEditorContent,
  handleSetFormFields,
  onHeaderClick,
  removeDraft,
  saveAsDraft,
  saveDraftsToRecord,
} from "./utils/emailbox-helpers";

import "./EmailBox.scss";
import {
  AttachmentsProvider,
  useAttachments,
} from "./providers/AttachmentsProvider";
import CommunicationRichEditor from "../rich-editor/CommunicationRichEditor";
import { DriveDocumentsProvider } from "./providers/DriveDocumentsProvider";
import {
  FilePreviewModalProvider,
  useFilePreviewModal,
} from "./providers/FilePreviewModalProvider";
import getRecordDetails from "./utils/getRecordDetails";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const EmailBox = (props) => {
  const recordDetails = getRecordDetails(props);

  return (
    <InitialEmailBoxPropsProvider {...props}>
      <EmailBoxProvider recordDetails={recordDetails}>
        <EmailBoxBodyProvider>
          <EmailTemplatesProvider {...props} recordDetails={recordDetails}>
            <AttachmentsProvider>
              <DriveDocumentsProvider>
                <FilePreviewModalProvider>
                  <NewEmailBox />
                </FilePreviewModalProvider>
              </DriveDocumentsProvider>
            </AttachmentsProvider>
          </EmailTemplatesProvider>
        </EmailBoxBodyProvider>
      </EmailBoxProvider>
    </InitialEmailBoxPropsProvider>
  );
};

const NewEmailBox = () => {
  const {
    setSignatureState,
    signature,
    draftId,
    setDraftId,
    recordDetails,
    setClickedElement,
    form,
  } = useEmailBox();

  const navigate = useNavigate();

  const { selectedFile, filePreview, setFilePreview } = useFilePreviewModal();

  const { attachments, setAttachments } = useAttachments();

  const {
    onClose,
    rowData,
    authToken,
    selectedDraft,
    isFull,
    isFromCharge,
    chargePdf,
    recordID,
    proppedTemplateCat,
    boxKey,
    authData,
    attachments: propsAttachments,
  } = useInitialEmailBoxProps();

  const { setSelectedEmailTemplate, selectedEmailTemplate } =
    useEmailTemplates();
  const { editorContent, setEditorContent } = useEmailBoxBody();
  const {
    drafts = [],
    recordData: { recordName, recordId, ...rest },
  } = useSelector((state) => state.communication);
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [documentsActive, setDocumentsActive] = useState(true);
  const [maximaze, setMaximaze] = useState(true);
  const { programFields } = useSelector((state) => state.programFields);
  const [fullSize, setFullSize] = useState(isFull);
  const { mobile } = useResponsive();

  const [
    {
      authenticatedUser: { email },
    },
  ] = useSelector(({ authenticatedUser }) => [authenticatedUser]);

  const coreStorageSignature = programFields?.find(
    (field) => field.fieldName === "Core Storage Signature"
  ).fieldOptions;

  useEffect(() => {
    if (selectedDraft) {
      setDraftId(selectedDraft);
    }

    if (propsAttachments) {
      setAttachments((prev) => [...prev, ...propsAttachments]);
    }

    if (chargePdf) {
      setAttachments((prevState) => [...prevState, ...chargePdf]);
    }
  }, [selectedDraft, propsAttachments, chargePdf]);

  // it fetches a draft, sets form fields, updates editor content, and manages state based on the fetched data.
  useEffect(() => {
    if (selectedDraft) {
      getDraft(selectedDraft, authData).then((res) => {
        if (res.data) {
          const { data: draft } = res;
          const {
            template,
            body,
            draftId,
            signature,
            attachments: draftAttachments,
          } = draft;

          if (draftAttachments) {
            setAttachments((prevState) => [...prevState, ...draftAttachments]);
          }

          handleSetFormFields(form, draft);

          handleEditorContent(
            setEditorContent,
            setSelectedEmailTemplate,
            body,
            template
          );

          setDraftId(draftId);
          setSignatureState(signature);
        } else {
          message.warning("An error occurred during communication");
        }
      });
    }
  }, []);

  //get signature & undoTime when selected user changed
  useEffect(() => {
    fetchSignature(authData, setSignatureState);
  }, [authToken]);

  const handleCloseEmailBox = () => {
    saveAsDraft(
      form,
      draftId,
      email,
      editorContent,
      selectedEmailTemplate,
      authData,
      signature,
      attachments,
      setDraftId,
      dispatch,
      drafts
    ).then((newDraft) => {
      if (newDraft.data.id) {
        saveDraftsToRecord(drafts, recordName, recordId);
      }
    });
    onClose(true);
    navigate(`${location.pathname}?tab=Communication`, { replace: true });
  };

  useEffect(() => {
    if (!selectedDraft) {
      saveAsDraft(
        form,
        draftId,
        email,
        editorContent,
        selectedEmailTemplate,
        authData,
        signature,
        attachments,
        setDraftId,
        dispatch,
        drafts
      );
    }
  }, []);

  return (
    <div className={fullSize ? "ModalMode" : ""}>
      <div
        {...{
          className: maximaze
            ? `EmailBoxContainer ${isDarkMode && "EmailBoxContainerDark"} ${
                boxKey === 1 ? "second" : ""
              } ${maximaze ? "maximize-width" : ""}`
            : `EmailBoxContainerMinimaze
            ${boxKey === 1 ? "second" : ""}
            ${isDarkMode && "EmailBoxContainerMinimazeDark"}`,
        }}
      >
        <div
          {...{
            className: "emailBoxHeader",
            onClick: (e) => {
              if (!isFromCharge) {
                onHeaderClick(e, setMaximaze, setFullSize);
              } else {
                return null;
              }
            },
          }}
        >
          <div className="titleContainer">
            <span {...{ className: "emailBoxHeaderTitle" }}>
              {form.getFieldValue("subject") || "New Message"}
            </span>
          </div>

          <div>
            {!isFromCharge && (
              <span
                {...{
                  onClick: (e) => {
                    e.stopPropagation();
                    setFullSize(fullSize ? false : true);
                    setMaximaze(true);
                  },
                  className: "emailBoxHeaderMaximaze",
                }}
              >
                {!mobile ? (
                  fullSize ? (
                    <MinimizeIcon />
                  ) : (
                    <MaximazeIcon />
                  )
                ) : null}
              </span>
            )}
            <span onClick={handleCloseEmailBox} className="emailBoxHeaderClose">
              <XIcon />
            </span>
          </div>
        </div>

        <div
          {...{
            className: "emailBoxContent",
          }}
        >
          <Form {...{ form }}>
            <EmailBoxHeaderInputs
              setClickedElement={setClickedElement}
              form={form}
              rowData={rowData}
            />

            <div className="subject-container">
              <Form.Item {...{ name: "subject" }}>
                <AutoComplete
                  {...{
                    className: "",
                    placeholder: "Subject",
                    onClick: () => setClickedElement(document.activeElement),
                  }}
                />
              </Form.Item>
            </div>

            <CommunicationRichEditor />

            <div {...{ className: "emailBoxFooter" }}>
              <div {...{ className: "footerLeftPart" }}>
                <EmailBoxFooter
                  rowData={rowData}
                  recordDetails={recordDetails}
                />
              </div>

              <div {...{ className: "footerRightPart" }}>
                {!mobile && attachments?.length !== 0 && (
                  <span
                    {...{
                      onClick: () => setDocumentsActive(!documentsActive),
                      className: "documentsStyle",
                    }}
                  >{`${attachments?.length} document${
                    attachments?.length === 1 ? "" : "s"
                  }`}</span>
                )}
                {draftId && (
                  <Tooltip {...{ title: "Remove draft" }}>
                    <span
                      {...{
                        className: "removeIcon",
                        onClick: () =>
                          removeDraft(
                            draftId,
                            authData,
                            rowData,
                            recordDetails,
                            recordID,
                            dispatch,
                            onClose
                          ),
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>

      {filePreview && (
        <FilePreviewModal
          {...{
            visible: filePreview,
            setVisible: () => setFilePreview(false),
            file: selectedFile,
          }}
        />
      )}
    </div>
  );
};
export default EmailBox;
