//This function finds objects inside objects, exmple: {key:{key:{key:{}}}}
export const findNestedElement = (obj, keys = []) => {
  let nestedObj = obj;

  for (let index = 0; index < keys.length; index++) {
    const title = keys[index];

    const findedEl = nestedObj?.[title];

    if (!findedEl) return {}; //if key not found return null

    //check if is last element and return that element, else get its childrens
    if (index < keys.length - 1) {
      nestedObj = findedEl;
    } else {
      return findedEl;
    }
  }

  return {};
};
