import { useState, useImperativeHandle, forwardRef } from "react";

import { ACTIVE_VIEW_DATA } from "../../data";
import { loadLivePreference, saveLocalPreferences } from "../../utils";
import { updateDocumentTitle } from "src/utils";

import "./FleetLiveActiveView.scss";

const FleetLiveActiveView = forwardRef((_, ref) => {
  const [activeView, setActiveView] = useState(
    loadLivePreference("pageLoaded")
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        changeMenuView(view) {
          setActiveView(view);
          saveLocalPreferences({ pageLoaded: view });

          let tabName = view.toLowerCase().split("_");
          let newSubTitle = ``;
          for (const part of tabName) {
            newSubTitle =
              newSubTitle + part.charAt(0).toUpperCase() + part.slice(1) + ` `;
          }
          newSubTitle = newSubTitle.trim();

          updateDocumentTitle({ newTitle: "Fleets Live", newSubTitle });
        },
      };
    },
    [activeView]
  );

  const View = ACTIVE_VIEW_DATA[activeView];

  return (
    <div className="live-active-view">
      <View />
    </div>
  );
});

export default FleetLiveActiveView;
