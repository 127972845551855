import html2canvas from "html2canvas";
import createPDF from "../../../../../../integrations/createPDF";
import { compareIncluding } from "../../../../../SidebarPages/utils";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../DEG/components/modalComponents/utils/cellFunctions";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { shiftFields, selectedEntryFields } from "./fields";

const pdfTaskBorderedSection = ({ body = [], jobMatch, noColor = false }) => {
  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };

  const formattedBody = [
    {
      text: "Shift Info",
      style: ["lastColumn", "strong", "subheader"],
    },
    ...shiftFields(body).map((field) => ({
      columns: [
        {
          width: "50%",
          text: field.name,
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          width: "50%",
          text: field.value,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    })),
    {
      text: "Selected Entry Info",
      style: ["lastColumn", "strong", "subheader"],
    },
    ...selectedEntryFields(body, jobMatch).map((field) => ({
      columns: [
        {
          width: "50%",
          text: field.name,
          alignment: "left",
          style: ["strong", "lastColumn"],
        },
        {
          width: "50%",
          text: field.value,
          alignment: "right",
          style: ["lastColumn"],
        },
      ],
    })),
  ];
  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: formattedBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

export const taskDocDefinition = (
  title,
  dynamicPdf,
  companyLogo,
  punchLocationMap
) => {
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: `${title ? title : ""}`,
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
            style: ["strong", "large"],
          },
          {
            text: `Date : ${dayjsNY().format(DEG_DATE_FORMAT)}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      dynamicPdf?.flatMap((item) => item),
      {
        width: "400",
        alignment: "center",
        image: punchLocationMap,
      },
    ],
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
      category: { fontSize: 10, color: "#4a4a4a" },
      commentContent: { margin: [10, 0, 10, 10] },
      replyContent: { margin: [20, 0, 10, 10] },
      replyHeader: { fontSize: 12, color: "#4a4a4a" },
    },
  };
};

const configPdfData = ({ title, jobMatch, body }) => [
  pdfTaskBorderedSection({
    title,
    jobMatch,
    body,
  }),
];

export const generateTimeCardPdf = async ({
  body,
  jobMatch,
  punchLocationMapRef,
  base64,
}) => {
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  let punchLocationMap = null;
  if (punchLocationMapRef.current) {
    let canvas = await html2canvas(punchLocationMapRef.current.mapRef, {
      allowTaint: true,
      useCORS: true,
      optimized: false,
      ignoreElements: function (element) {
        const classNamesToHide = [
          "gmnoprint",
          "drawing-buttons",
          "gm-fullscreen-control",
        ];

        // Remove elements fom the canva preview
        for (const className of classNamesToHide) {
          if (element.classList.contains(className)) {
            return true;
          }
        }
      },
    });

    punchLocationMap = canvas.toDataURL("image/jpg");
  }

  createPDF({
    action: "open",
    docDefinition: taskDocDefinition(
      "Time Card",
      configPdfData({ title: "Time Card", jobMatch, body }),
      (companyLogo = companyLogo),
      punchLocationMap
    ),
  });

  // Clear Location Map bas64 from memory (for optimization)
  punchLocationMap = null;

  return;
};
