export const claims = (
  circleProgressPicker,
  updatedDynamicStates,
  IdHandler
) => {
  return {
    claimStatus: () => ({
      title: "Claim Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Claims",
        field: "claimStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (e) => e?.claimStatus === circleProgressPicker
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (e) => e?.claimStatus === circleProgressPicker
        ),
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
