import dayjs from "dayjs";

export const fleetInspections = (
  updatedDynamicStates,
  IdHandler,
  programFields
) => {
  const inspectionStatuses = programFields
    ?.find((el) => el?.fieldName === "Statuses Of Categories")
    ?.fieldOptions?.Inspections?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.statusName]: curr?.statusColor,
      }),
      {}
    );

  return {
    inspectedBy: () => ({
      title: "Inspected By",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "inspectedBy",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetName,
            value: a?.inspectedBy,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //inspectedBy
    driverName: () => ({
      title: "Inspection Driver Name",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "driverName",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetName,
            value: a?.driver,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //driverName
    createdAt: () => ({
      title: "Inspection Creation Date",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "createdAt",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.fleetName,
            value: dayjs(a?.createdAt)?.format("MM/DD/YYYY"),
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //createdAt

    inspectionStatus: () => ({
      title: "Inspection Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "inspectionStatus",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => ({
          label: a?.fleetName,
          value: a?.inspectionStatus,
          color: inspectionStatuses?.[a?.inspectionStatus],
          date: a?.createdAt,
        })),
      },
      id: IdHandler,
      width: "790px",
    }), //inspectionStatus

    inspectionType: () => ({
      title: "Inspection Type",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "inspectionType",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((el) => ({
          label: el?.fleetName,
          value: el?.inspectionType,
          color: "#ECDE7D",
          date: el?.createdAt,
        })),
      },
      id: IdHandler,
      width: "790px",
    }), //inspectionType

    mechanic: () => ({
      title: "Mechanic",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Fleet Inspections",
        field: "mechanic",
        secondaryField: "fleetName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((el) => ({
          label: el?.fleetName,
          value: el?.mechanic,
          color: "#ECDE7D",
          date: el?.createdAt,
        })),
      },
      id: IdHandler,
      width: "790px",
    }), //mechanic
  }; //Inspections
};
