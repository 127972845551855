import moment from "moment";

export const getLastSeenUsers = (logs) => {
  const lastSeenUsers = [];
  const userCounts = {};
  const totalViews = logs.length;

  logs.forEach((log) => {
    if (userCounts[log.nameOfUser]) {
      userCounts[log.nameOfUser].viewCount++;
    } else {
      userCounts[log.nameOfUser] = {
        nameOfUser: log.nameOfUser,
        viewCount: 1,
      };
    }

    if (!lastSeenUsers.some((user) => user.id === log.cognitoUserId)) {
      lastSeenUsers.push({
        name: log.nameOfUser,
        id: log.cognitoUserId,
        tooltip: `${log.nameOfUser} | ${moment(log.updatedAt).format(
          "MMMM Do YYYY, h:mm a"
        )}`,
      });
    }
  });

  const sortedUsers = Object.values(userCounts).sort(
    (a, b) => b.viewCount - a.viewCount
  );

  const topUsers = sortedUsers.slice(0, Math.min(5, sortedUsers.length));

  return { lastSeenUsers, topUsers, totalViews };
};
