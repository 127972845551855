import { TickIcon } from "../../../Roles/src";
import { MondayButton } from "../../../../../../commonComponents";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { HistoryOutlined } from "@ant-design/icons";

export const footerButtons = ({
  onCancel,
  handleUpdate,
  openLogs,
  cancelRef,
  logsRef,
  saveRef,
}) => {
  return [
    <div className="footerButtons">
      <div ref={cancelRef}>
        <MondayButton
          className="mondayButtonRed"
          onClick={onCancel}
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
      </div>
      <div ref={logsRef} className="crewLogsDiv">
        <MondayButton
          className="mondayButtonBlue"
          onClick={openLogs}
          Icon={<HistoryOutlined />}
          tooltipKey="showLogs"
        >
          Logs
        </MondayButton>
      </div>
      <div ref={saveRef}>
        <MondayButton
          Icon={<TickIcon height={17} width={17} />}
          onClick={handleUpdate}
        >
          Save Changes
        </MondayButton>
      </div>
    </div>,
  ];
};

export function employeeFields(isDarkMode, role, status) {
  return [
    {
      label: "Full Name",
      type: "input",
      placeholder: "Name...",
      formItemName: "crewName",
      className: "crewNameUpdate",
      required: true,
      dataTestid: "input-name",
    },
    {
      label: "Role",
      type: "select",
      placeholder: "Select role...",
      formItemName: "crewPosition",
      popupClassName: isDarkMode && "darkDropDown",
      className: "crewPositionSelect",
      customOptions: (role || [])?.map(({ statusName }, key) => ({
        key,
        label: statusName,
        value: statusName,
      })),
      required: true,
      getPopUpContainer: document.body,
      dataTestid: "select-role",
    },
    {
      label: "Employee Type",
      type: "select",
      placeholder: "Select employee type...",
      formItemName: "foreman",
      customOptions: [
        { key: 0, label: "Member", value: false },
        { key: 1, label: "Foreman", value: true },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "Status",
      type: "select",
      placeholder: "Select employee status...",
      formItemName: "crewStatus",
      customOptions: (status || []).map(({ statusName }, key) => {
        return {
          key,
          label: statusName,
          value: statusName,
        };
      }),
      required: true,
      getPopUpContainer: document.body,
    },

    {
      label: "Pay Rate",
      type: "input",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      addonBefore: "$",
      required: true,
    },
    {
      label: "Salary Type",
      type: "select",
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "Employee ID",
      type: "input",
      placeholder: "Employee Id...",
      formItemName: "employeeId",
      className: "employeeIdEdit",
      required: true,
    },
    {
      type: "input",
      formItemName: "fingerCheckId",
    },
    {
      type: "input",
      formItemName: "employeeNumber",
    },
  ];
}
