import { roundToDecimals } from '../../../../../../../../../../utils'

export const calculateDifference = (serviceToEdit, newAmounts) => {
  // Re calculate all amounts to update other rows based on the row that we have updated
  const amounts = newAmounts?.map((newAmount, newAmountIndex, newAmountsArray) => {
    const amount = roundToDecimals(((serviceToEdit.totalPrice * newAmount.rate) / 100), 2)                                          // Calculate amount based on rate
    let i = newAmountIndex                                                                                                          // Initialize index
    let total = serviceToEdit.totalPrice                                                                                            // Initial total is equal to total price

    // Calculate total for this row
    while (i > 0) {                                                                                                                 // If we are on row other than first one
      total = roundToDecimals((total - roundToDecimals(((serviceToEdit.totalPrice * newAmountsArray[i - 1].rate) / 100), 2)), 2)    // Subtract all previous amounts from total price
      i--                                                                                                                           // Until we reach the current row
    }

    let difference = roundToDecimals((total - amount), 2)                                                                           // Calculate difference
    const retainage = roundToDecimals((amount * serviceToEdit?.retainage) / 100, 2)                                                 // Calculate retainage
    const collectable_amount = roundToDecimals(amount - retainage, 2)                                                               // Get collectable amount without retainage

    // Return new amounts
    return {
      ...newAmount,
      amount,
      difference,
      retainage,
      collectable_amount
    }
  })

  return amounts
}