export const defaultColDef = {
  minWidth: 100,
  editable: true,
  flex: 1,
  hide: false,
  filter: true,
  sortable: false,
  resizable: true,
  enableRowGroup: false,
}
