import React, { useState } from "react";
import { ProposalBuilderIcon } from "../../../../../../../../../../../../../icons/Settings/index";
import { MondayButton } from "../../../../../../../../../../../../commonComponents/index";
import { useRedux } from "../../../../../../../../../../../hooks/useRedux";
import TemplateDescModal from "./modal/TemplateDescModal";
import { useSelector } from "react-redux";

function RentalGeneralDescription() {
  const [visible, setVisible] = useState(false);
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const { isDarkMode } = useSelector(state => state.darkMode);

  const changeRentals = (newRentals) => {
    setFakeRentals(newRentals);
  };

  const onCancel = () => {
    setVisible(false);
  };
  return (
    <div>
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => setVisible(true)}
        Icon={
          <span style={{ width: 22, height: 22 }}>
            <ProposalBuilderIcon />
          </span>
        }
      >
        Templates
      </MondayButton>
      <TemplateDescModal
        {...{
          visible,
          onCancel,
          dataSrc: fakeRentals,
          changeDataSrc: changeRentals,
          darkMode: isDarkMode,
        }}
      />
    </div>
  );
}

export default RentalGeneralDescription;
