import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const defects = (
  idHandler,
  updatedDynamicStates,
  defectsData,
  programFields
) => {
  const defectStatuses = programFields?.find?.(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Defects;

  // const defectPriority = programFields?.find?.(
  //   ({ fieldName }) => fieldName === "Priority Status"
  // )?.fieldOptions;
  return {
    defectStatus: () => ({
      title: "Defects Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: idHandler,
      BodyComponentParams: {
        table: "Defects",
        field: "defectStatus",
        labels: defectStatuses?.map((a) => a?.statusName),
        datasets: [
          {
            label: "My First Dataset",
            data: defectStatuses
              ?.map((a) => a?.statusName)
              ?.map(
                (status) =>
                  updatedDynamicStates[idHandler]?.filter(
                    (a) =>
                      a?.defectStatus?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),
            backgroundColor: defectStatuses?.map((a) => a?.statusColor),
            borderWidth: 0,
          },
        ],
      },
      width: "390px",
    }),

    // defectName: () => ({
    //   title: "Defects Name",
    //   BodyComponent: "Pie",
    //   type: "Pie",
    //   id: idHandler,
    //   BodyComponentParams: {
    //     table: "Defects",
    //     field: "defectName",
    //     labels: uniq(defectsData?.map((a) => a?.defectName)),
    //     datasets: [
    //       {
    //         label: "My First Dataset",
    //         data: uniq(defectsData?.map((a) => a?.defectName))?.map(
    //           (status) =>
    //             updatedDynamicStates[idHandler]?.filter(
    //               (a) => a?.defectName?.toLowerCase() === status?.toLowerCase()
    //             )?.length
    //         ),
    //         backgroundColor: uniq(defectsData?.map((a) => getRandomColor)),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    //   width: "390px",
    // }),

    // driverName: () => ({
    //   title: "Defects: Driver Name",
    //   BodyComponent: "Pie",
    //   type: "Pie",
    //   id: idHandler,
    //   BodyComponentParams: {
    //     table: "Defects",
    //     field: "driverName",
    //     labels: uniq(defectsData?.map((a) => a?.driverName)),
    //     datasets: [
    //       {
    //         label: "My First Dataset",
    //         data: uniq(defectsData?.map((a) => a?.driverName))?.map(
    //           (status) =>
    //             updatedDynamicStates[idHandler]?.filter(
    //               (a) => a?.driverName?.toLowerCase() === status?.toLowerCase()
    //             )?.length
    //         ),
    //         backgroundColor: uniq(defectsData?.map((a) => getRandomColor())),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    //   width: "390px",
    // }),

    // fleetName: () => ({
    //   title: "Defects: Fleet Name",
    //   BodyComponent: "Pie",
    //   type: "Pie",
    //   id: idHandler,
    //   BodyComponentParams: {
    //     table: "Defects",
    //     field: "fleetName",
    //     labels: uniq(defectsData?.map((a) => a?.fleetName)),
    //     datasets: [
    //       {
    //         label: "My First Dataset",
    //         data: uniq(defectsData?.map((a) => a?.fleetName))?.map(
    //           (status) =>
    //             updatedDynamicStates[idHandler]?.filter(
    //               (a) => a?.fleetName?.toLowerCase() === status?.toLowerCase()
    //             )?.length
    //         ),
    //         backgroundColor: uniq(defectsData?.map((a) => getRandomColor())),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    //   width: "390px",
    // }),

    // inspectionName: () => ({
    //   title: "Defects: Inspection Name",
    //   BodyComponent: "Pie",
    //   type: "Pie",
    //   id: idHandler,
    //   BodyComponentParams: {
    //     table: "Defects",
    //     field: "inspectionName",
    //     labels: uniq(defectsData?.map((a) => a?.inspectionName)),
    //     datasets: [
    //       {
    //         label: "My First Dataset",
    //         data: uniq(defectsData?.map((a) => a?.inspectionName))?.map(
    //           (status) =>
    //             updatedDynamicStates[idHandler]?.filter(
    //               (a) =>
    //                 a?.inspectionName?.toLowerCase() === status?.toLowerCase()
    //             )?.length
    //         ),
    //         backgroundColor: uniq(defectsData?.map((a) => getRandomColor())),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    //   width: "390px",
    // }),

    // defectPriority: () => ({
    //   title: "Defects: Priority",
    //   BodyComponent: "Pie",
    //   type: "Pie",
    //   id: idHandler,
    //   BodyComponentParams: {
    //     table: "Defects",
    //     field: "defectPriority",
    //     labels: uniq(defectsData?.map((a) => a?.defectPriority)),
    //     datasets: [
    //       {
    //         label: "My First Dataset",
    //         data: uniq(defectsData?.map((a) => a?.defectPriority))?.map(
    //           (status) =>
    //             updatedDynamicStates[idHandler]?.filter(
    //               (a) =>
    //                 a?.defectPriority?.toLowerCase() === status?.toLowerCase()
    //             )?.length
    //         ),
    //         backgroundColor: uniq(defectsData?.map((a) => getRandomColor())),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    //   width: "390px",
    // }),
  };
};
