import { message } from "antd";
import _ from "lodash";
import { getNewId } from "../polyfillers";
import { createEmptyPLI } from "../polyfillers/PLICreator";

/**
 * This function is called from GridRowModifier or from getContextMenuItems() in dataEntryGrid
 * */
export function appendPLIRow(
  ServicesIndex,
  isWritable,
  gridDataState,
  setState,
  gridApi,
  serviceIndex,
  optionIndex,
  elevationIndex,
  itemId,
  accessRights,
  updateStateAndSave
) {
  if (isWritable) {
    let hasAccessRight = accessRights.children.find(
      (item) => item.title === "Adding/Removing pli's"
    ).write;
    if (hasAccessRight) {
      // let gridData = _.cloneDeep(gridDataState);
      let rowData = [];
      gridApi.forEachNode((node) => rowData.push(node.data));
      console.log("rowData", rowData);
      let items = rowData;

      let highestId = 1;
      let pli = createEmptyPLI(
        gridDataState[serviceIndex].serviceId,
        highestId,
        gridDataState[serviceIndex],
        optionIndex
      );
      pli.ppu = gridDataState[serviceIndex].pricePerUnit;

      if (items.length > 0) {
        //if there are existing rows
        if (itemId === undefined) {
          //if append row has been clicked not in any row
          highestId = getNewId(items);
          pli.id = highestId;
          items.push(pli);
        } else {
          //if it is clicked in any row (example in row with id=5, it will add a new row in between row with id=5 and next row)

          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.id === itemId) {
              //when we go to the position that we want t append row
              pli.id = itemId + 1;
              items.splice(i + 1, 0, pli); //append a new pli with an id just one more than the current row in the next position
              i++; //skip the nex position as it is new new added id
            }
            if (item.id > itemId) {
              //all elements with id higher than the added element must be increased by 1
              item.id++;
            }
          }
        }
      } else {
        //if no row is there
        items.push(pli);
      }
      gridDataState[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items = items;
      setState({ gridData: gridDataState });
      setState({ agSelectedCells: {} });
      gridApi.setRowData(items);
      gridApi.refreshCells();
    } else {
      message.error("You have no access to add pli");
    }
  } else {
    message.error("Please enable write mode.");
  }
}

export function appendScopePLIRow(
  ServicesIndex,
  isWritable,
  gridDataState,
  setState,
  gridApi,
  serviceIndex,
  optionIndex,
  elevationIndex,
  itemId,
  accessRights,
  updateStateAndSave
) {
  if (isWritable) {
    let hasAccessRight = accessRights.children.find(
      (item) => item.title === "Adding/Removing pli's"
    ).write;
    if (hasAccessRight) {
      // let gridData = _.cloneDeep(gridDataState);
      let rowData = [];
      gridApi.forEachNode((node) => rowData.push(node.data));
      console.log("rowData", rowData);
      let items = rowData;

      let highestId =
        rowData.length > 0 ? rowData[rowData.length - 1].id + 1 : 1;
      let pli = { ...(rowData?.[0] || {}), id: highestId };
      items.push(pli);

      gridDataState[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items = items;
      setState({ gridData: gridDataState });
      setState({ agSelectedCells: {} });
      gridApi.setRowData(items);
      gridApi.refreshCells();
    } else {
      message.error("You have no access to add pli");
    }
  } else {
    message.error("Please enable write mode.");
  }
}
