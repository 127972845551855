import { useState } from "react";
import { MondayButton } from "../../../../../../commonComponents";
import { Tick } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { FiltersModal } from "../../../../../BasePage/components";
import { FilterIcon } from "../../../../../BasePage/src";
import { isEmpty } from "lodash";
import "./PermitsFilterCard.scss";
import { openInNewTab } from "../../../../../../../utils";
import { ClearFilterIcon } from "../../../../../DynamicView/src/index";
import { Tooltip, message } from "antd";
import { ExportPdfIcon } from "../../../../../../../assets";
import VideoModal from "../../../../../../VideoModal/VideoModal";
import { VideoTutorialIcon } from "../../../../../../../icons";
import MultipleBinModal from "../../MultipleBinModal/MultipleBinModal";
import { tabCategories } from "../../../dobTabData";

const PermitsFilterCard = ({
  filters,
  getFilters,
  rowData,
  dobData,
  clearGridFilters,
  filtersData,
  onGeneratePDF,
  binNumbers,
  selectedCategory,
  selectedBins,
  setSelectedBins,
  isMultipleBinsOpen,
  setIsMultipleBinsOpen,
}) => {
  const [filtersModalVisible, setFiltersModalVisible] = useState();
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  const hasMultipleBins = tabCategories.reduce((acc, category) => {
    acc[category] = binNumbers?.length > 1 && dobData?.[category]?.length > 0;
    return acc;
  }, {});

  const { bin, bin__ } =
    Object.values(dobData)
      .flat()
      .find(({ bin, bin__ }) => !!bin || bin__) || {};

  const binNr = bin || bin__;
  return (
    <>
      <div className="filterCard permitsFilterCard">
        <div className="leftSection">
          <MondayButton
            {...{
              Icon: <FilterIcon />,
              onClick: () => setFiltersModalVisible(true),
              className: "filterButton",
            }}
          >
            Open Filters
          </MondayButton>
          <MondayButton
            {...{
              Icon: <ClearFilterIcon />,
              className: `clearFilterButton ${
                !isEmpty(filtersData) ? "activeFilters" : ""
              }`.trim(),
              onClick: clearGridFilters,
            }}
          >
            Clear Filters
          </MondayButton>
        </div>
        <div className="rightSection" style={{ display: "flex", gap: "20px" }}>
          {hasMultipleBins[selectedCategory] && (
            <MultipleBinModal
              {...{
                open: isMultipleBinsOpen,
                setOpen: setIsMultipleBinsOpen,
                hasMultipleBins,
                getFilters,
                binNumbers,
                selectedCategory,
                selectedBins,
                setSelectedBins,
              }}
            />
          )}
          <MondayButton
            className="mondayButtonBlue"
            Icon={<VideoTutorialIcon />}
            onClick={() => setVideoModalVisible(true)}
          />
          <MondayButton
            className="mondayButtonBlue"
            Icon={<ExportPdfIcon />}
            onClick={() => onGeneratePDF("open")}
          >
            Export PDF
          </MondayButton>
          <Tooltip title="Verify BIN number from here">
            <MondayButton
              {...{
                className: "submitButton",
                Icon: (
                  <div>
                    <Tick className="submitButtonIcon" />
                  </div>
                ),
                onClick: () =>
                  !!binNr
                    ? openInNewTab(
                        `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?bin=${binNr}`
                      )
                    : message.warning("There is no BIN to verify!!!"),
              }}
            >
              Verify Bin
            </MondayButton>
          </Tooltip>
        </div>
      </div>

      {!!filtersModalVisible && (
        <FiltersModal
          {...{
            filters,
            filtersData,
            getFilters,
            rowData,
            setOpen: setFiltersModalVisible,
          }}
        />
      )}
      {videoModalVisible && (
        <VideoModal
          {...{
            modalOpen: videoModalVisible,
            setModalOpen: setVideoModalVisible,
            title: "DOB",
          }}
        />
      )}
    </>
  );
};
export default PermitsFilterCard;
