import { Radio } from "antd";
import { GPickerButton } from "../../../../../../../../components";
import { InputComponent } from "../../../../../../../../components";
import InspectionGallery from "../../../../../../../../../../../integrations/AgGridDatas/components/InspectionGallery";

const CheckboxItem = ({
  item,
  form,
  globalDisabled,
  onRadioSelect,
  fileNames,
  rowObject,
  inspectionReport,
  inspectionItems,
  defectList,
  gDriveFolderId,
  accessToken,
  onPickerSuccess,
}) => {
  return (
    <div>
      <InputComponent
        label={item.label}
        required={item.required}
        type={item.type}
        form={form}
        className={form.getFieldValue(item.formItemName)}
        formItemName={item.formItemName}
        disabled={globalDisabled}
        onChange={(e) => onRadioSelect(e, item)}
        options={item.options.map((itemm, i) => (
          <Radio key={i} value={itemm}>
            {itemm}
          </Radio>
        ))}
      />
      {globalDisabled ? (
        fileNames.find(({ uploaderId }) => uploaderId === item.label) && (
          <div
            className="labeledInputContainer"
            style={{ backgroundColor: "#ad0909 !important" }}
          >
            <div className="labeledInputLabel">{item.label} Gallery</div>
            <InspectionGallery
              data={rowObject}
              fileNames={
                fileNames.find(({ uploaderId }) => uploaderId === item.label)
                  .files
              }
            />
          </div>
        )
      ) : (!!inspectionReport?.length &&
          !!inspectionReport?.find(({ label }) => label === item.label)
            ?.value !== undefined) ||
        inspectionItems.find(({ label }) => label === item.label)?.value !==
          undefined ? (
        <GPickerButton
          required={
            defectList.some(({ label }) => label === item.label) ? true : false
          }
          parentId={globalDisabled ? false : gDriveFolderId}
          accessToken={accessToken}
          label={`Upload for ${item.label}`}
          uploaderId={item.label}
          fileNames={fileNames}
          onPickerSuccess={onPickerSuccess}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CheckboxItem;
