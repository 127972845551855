import React, { useCallback, useState } from "react";
import { MondayButton } from "../../../../../../../../../../../../../../../commonComponents";
import { Input, InputNumber, Modal, Tag } from "antd";
import { NormalSizedModal } from "../../../../../../../../../../../../../../Fleet/components";
import { set } from "lodash";
import { CheckIconModal } from "../../../../../../../../../../../../../../../../assets";
import { forceToNumber } from "../../../../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getElevationPrice } from "../../EventsCalculatorBody";
import { getElevationAddonsPrice } from "../../../../../../../../../../../../../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/ServicePrices";
import { useRedux } from "../../../../../../../../../../../../../../hooks";
import { getRowData } from "../rowData";

function ChangeElevationPrice({ serviceOption, service }) {
  const [visible, setVisible] = useState(false);
  const [number, setNumber] = useState(0);
  const [rowDatas, setRowDatas] = useRedux("rowDatasEventsSov", [], false);
  const onChangeElevationPrice = () => {
    if (visible) {
      onConfirm();
      setVisible(false);
    } else {
      setVisible(true);
    }
  };
  const [distributedElevationData, setDistributedElevationData] = useRedux(
    "distributedElevationData"
  );

  const onConfirm = () => {
    const newAmount = forceToNumber(number);
    let myData = structuredClone(distributedElevationData);
    for (const data of myData) {
      if (data?.elevationId === serviceOption?.elevationId) {
        data.maxPrice = newAmount;
      }
    }
    console.log("onConfirmnewAmount", { myData, newAmount });
    if (Array.isArray(service?.expandedAmounts)) {
      setRowDatas(service?.expandedAmounts);
    } else {
      let myRows = [];

      if (Array.isArray(myData) && myData.length > 0) {
        myData.forEach((item) => {
          myRows.push(getRowData({ service, serviceOption: item }));
        });
      }
      console.log("myRows", myRows);
      setRowDatas(myRows);
    }
    setDistributedElevationData(myData);
  };

  return (
    <>
      {visible && (
        <InputNumber
          onChange={(e) => {
            setNumber(e);
          }}
        />
      )}
      <Tag
        onClick={onChangeElevationPrice}
        color={"#0766AD"}
        style={{
          fontSize: 19,
          cursor: "pointer",
          marginLeft: 10,
        }}
      >
        {visible ? "Save" : "Change Elevation Price"}
      </Tag>
    </>
  );
}

export default ChangeElevationPrice;
