import { pdfjs } from "react-pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const makeThumb = (page) => {
  // draw page to fit into 96x96 canvas
  var vp = page.getViewport({ scale: 1 })
  var canvas = document.createElement("canvas")
  var scalesize = 1
  canvas.width = vp.width * scalesize
  canvas.height = vp.height * scalesize
  var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height)
  return page
    .render({
      canvasContext: canvas.getContext("2d"),
      viewport: page.getViewport({ scale: scale }),
    })
    .promise.then(() => canvas)
}

export const generatePdfThumbnail = async (pdfFile) => {
  const doc = await pdfjs.getDocument(pdfFile).promise

  const firstPage = await doc.getPage(1)

  const canvas = await makeThumb(firstPage)

  return canvas.toDataURL()
}
