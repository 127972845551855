import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const DepartmentCard = ({ department, departmentsList, index, tab }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const allUsers = userConfiguration?.allUsers?.Items || {};

  const users = allUsers.filter(
    (item) => item.departmentName === department.departmentName
  );

  const destinationPath = `/settings/${
    tab === 7 ? "rolesAccess" : "userAccess"
  }/${department.departmentName}`;

  return (
    <div
      className="entryPageDepartmentName"
      id={index === 0 ? "departmentCard" : ""}
    >
      <div className="header">
        <span className="title">{department.departmentName}</span>
      </div>
      <Link
        key={department.departmentName}
        to={destinationPath}
        state={{
          tab,
          selectedUsers: users,
          selectedDepartmentColor: department.color,
          departmentsList,
          allUsers,
        }}
        department={department.department}
        className="link"
      >
        <div
          className="body"
          style={{
            backgroundColor: department.color,
          }}
        >
          <p className="number">{users.length}</p>
          <p className="text">{`${department.departmentName} Users`}</p>
        </div>
      </Link>
    </div>
  );
};

export default DepartmentCard;
