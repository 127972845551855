import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { formatDuration } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { FlagIcon } from "src/assets";
import { ClockIcon } from "src/components/SidebarPages/DynamicView/components/FilterView/FilterIcons";

import "./SideTripsActivity.scss";

function SideTripsActivity({
  isDarkMode,
  activeTrip,
  lastUpdate,
  allTrips = [],
  onRouteClicked = () => {},
}) {
  function toggleTripInfo(index) {
    if (index === activeTrip) {
      onRouteClicked(+index, true);
    } else {
      onRouteClicked(+index);
    }
  }

  return (
    <div
      className={`sidebar-routes-activity ${
        isDarkMode ? "routes-activity-dark" : ""
      }`}
    >
      {!allTrips?.length && (
        <div
          className={`trip-details-container ${
            activeTrip === -2 ? "trip-selected" : "trip-unselected"
          }`}
          id={`trip-details-${-2}`}
          key={`trip-${-2}`}
          onClick={() => {
            toggleTripInfo(-2);
          }}
        >
          <div className="trip-location-title">
            <ClockIcon
              fill={isDarkMode ? "#fff" : "#323338"}
              height={28}
              width={28}
            />
            00:00 am - {dayjsNY(lastUpdate || undefined).format("hh:mm a")} (All
            Day)
          </div>
        </div>
      )}
      {allTrips.map((trip, index) => {
        return (
          <div
            className={`trip-details-container ${
              index === activeTrip ? "trip-selected" : "trip-unselected"
            }`}
            id={`trip-details-${index}`}
            key={`trip-${index}`}
            onClick={() => {
              toggleTripInfo(index);
            }}
          >
            <div className="trip-location-title">
              <ClockIcon
                fill={isDarkMode ? "#fff" : "#323338"}
                height={28}
                width={28}
              />
              {dayjsNY(trip.startDateTime).format("hh:mm a")} -{" "}
              {dayjsNY(trip?.endDateTime).format("hh:mm a")} (
              {formatDuration(trip?.endDateTime - trip?.startDateTime).text})
            </div>
            <div
              className={`expandable-info ${
                index === activeTrip ? "info-expanded" : "info-collapsed"
              }`}
              id={`trip-info-${index}`}
            >
              <div className="trip-location">
                <div className="alert-icon">
                  <FlagIcon
                    height={14}
                    width={14}
                    fill={isDarkMode && "#fff"}
                  />
                </div>
                <div className="trip-address">{trip.startAddress}</div>
              </div>
              <div className="trip-location">
                <div className="alert-icon">
                  <FlagIcon
                    height={14}
                    width={14}
                    fill={isDarkMode && "#fff"}
                  />
                </div>
                <div className="trip-address">{trip.endAddress}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SideTripsActivity;
