import { useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Modal } from "antd";

import { driverColumns, dispatchesDetails } from "./HelpingComponents/utils";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../../../../../commonComponents";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";

import "./DriverSelectionModal.scss";
import { updateDocumentTitle } from "../../../../../../../../utils";

function DriverSelectionModal({
  open,
  onCancel = () => {},
  drivers,
  onDriverSelect = () => {},
  getRouteInfo,
  dispatches = [],
  routeSelections = [],
  selectedFleet,
  driverSelections,
  isDarkMode,
}) {
  const [gridApi, setGridApi] = useState();

  const onGridReady = useCallback((param) => {
    setGridApi(param);
  }, []);

  const getRowId = useCallback((param) => {
    return param?.data?.driverId;
  }, []);

  const onFirstDataRendered = useCallback(() => {
    if (gridApi) {
      let driverToCheck = driverSelections?.find(
        ({ fleetId }) => fleetId === selectedFleet
      );
      if (driverToCheck) {
        let node = gridApi.api.getRowNode(driverToCheck?.driverId);
        node.setSelected(true);
      }
      gridApi.columnApi.columnModel.autoSizeColumn("driverName");
    }
  }, [gridApi, drivers]);

  function getRowStyle(param) {
    if (
      routeSelections?.find(
        ({ driverId }) => param?.node?.data?.driverId === driverId
      )
    ) {
      return { background: "#CDE5F8" };
    }
  }

  function onSubmit() {
    onDriverSelect(gridApi.api.getSelectedNodes()?.[0]?.data, selectedFleet);
    onCancel();
  }

  function onSearch(e) {
    if (gridApi) {
      gridApi.api.setFilterModel({
        driverName: {
          filterType: "text",
          type: "contains",
          filter: e,
        },
      });
    }
  }

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  };

  return (
    <Modal
      {...{
        className: `schedule-driverSelection-container ${
          isDarkMode && "schedule-driverSelection-container-dark"
        }`,
        wrapClassName: "schedule-driverSelection-wrap",
        closable: true,
        centered: true,
        closeIcon: <XIcon />,
        title: "Driver Selection",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Driver Selection" });
        },
        open,
        destroyOnClose: true,
        onCancel: closeModal,
        footer: [
          <MondayButton
            {...{
              className: "mondayButtonRed",
              Icon: <XIcon />,
              onClick: closeModal,
            }}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              Icon: <TickIcon width={17} height={17} />,
              onClick: onSubmit,
            }}
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <div className="header-search">
        <InputComponent
          {...{
            type: "autocomplete",
            formItemName: "inputSearch",
            placeholder: "Search Driver Name...",
            onSearch,
            style: { width: 300, maxWidth: "80dvw" },
          }}
        />
      </div>
      <div
        className={`schedule-driver-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData: drivers,
            columnDefs: driverColumns({
              dispatches,
            }),
            onGridReady,
            onFirstDataRendered,
            getRowId,
            getRowStyle,
            rowSelection: "single",
            pagination: false,
            masterDetail: true,
            detailCellRendererParams: dispatchesDetails({
              dispatches,
              getRouteInfo,
              grid: "driver",
            }),
            suppressRowClickSelection: true,
            animateRows: true,
            gridOptions: {
              alwaysShowHorizontalScroll: true,
              alwaysShowVerticalScroll: true,
            },
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
    </Modal>
  );
}

export default DriverSelectionModal;
