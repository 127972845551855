import RichTextModalEditor from "../../../../../../../../../../commonComponents/RichTextModalEditor/RichTextModalEditor";
import {
  useStateContext,
  useDispatchContext,
} from "../../../../../Context/Context";
import { noteExtractor, onConfirmHandler } from "./descriptionModalFunctions";
import "./DescriptionModal.scss";

const DescriptionModal = ({ service, event }) => {
  const { darkMode, fakeApplication } = useStateContext();
  const dispatchContext = useDispatchContext();

  const { label, serviceId, estimationId } = service || {};
  const { name, id: eventId } = event || {};

  const initialValue = `<div contenteditable="false" user-select: "none" ><h3>Description:</h3> Service: ${label}, Event: ${name}<h3 >Notes:</h3></div>`;

  const note = noteExtractor({ fakeApplication, serviceId, eventId });

  const onConfirm = ({ newValue }) =>
    onConfirmHandler({
      dispatchContext,
      fakeApplication,
      initialValue,
      estimationId,
      serviceId,
      newValue,
      eventId,
    });

  return (
    <div className="descriptionModalContainer">
      <RichTextModalEditor
        {...{
          darkMode,
          value: note,
          onConfirm,
        }}
      />
    </div>
  );
};

export default DescriptionModal;
