import { useContext, useEffect, useState } from "react";
import { VideoTutorialIcon } from "../../assets/svg";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import NewCategories from "../NewCategories/NewCategories";
import { StarFilled } from "@ant-design/icons";
import { message } from "antd";

const FavouritesCard = () => {
  const { favourites } = useContext(VideoTutorialContext);

  const [openFavVideos, setOpenFavVideos] = useState(false);

  const handleClick = () => {
    if (favourites.length === 0) return message.warning("No Video to show");
    setOpenFavVideos((prev) => !prev);
  };

  useEffect(() => {
    favourites.length === 0 && setOpenFavVideos(false);
  }, [favourites]);

  return (
    <>
      <div
        className={`favTutorialsCard ${openFavVideos ? "active" : ""}`}
        onClick={handleClick}
      >
        <StarFilled className="star-icon" />
        <p className="text">
          Click to see your ({favourites.length}) favorite tutorials
        </p>
        <VideoTutorialIcon className="videoPlayerIcon" />
      </div>
      {openFavVideos && (
        <NewCategories newVideos={favourites} isFavourite={true} />
      )}
    </>
  );
};

export default FavouritesCard;
