function getLiveColor(status) {
  switch (status) {
    case "Idling":
      return "#e9c400";
    case "Moving":
      return "#7cca00";
    case "Stopped":
      return "#dc4045";
    case "In Motion":
      return "#0a8800";
    case "InMotion":
      return "#0a8800";
  }
  return "#5b99e2";
}

export default getLiveColor;
