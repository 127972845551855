/**
 * @param {string} str
 * @returns {string}
 */
function camelToTitle(str) {
  let res = str.replace(/([A-Z]+)|(\d+)/g, function (_, g1, g2) {
    return " " + (g1 || g2);
  });

  res = res.trim();
  res = res.charAt(0).toUpperCase() + res.slice(1);
  return res;
}

export default camelToTitle;
