export const leads = (circleProgressPicker, updatedDynamicStates, IdHandler) => {
  return {
    leadStatus: () => ({
      title: "Lead Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Leads",
        field: "leadStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter((a) => a?.leadStatus === circleProgressPicker)
            ?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter((a) => a?.leadStatus === circleProgressPicker)
          ?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //leadStatus
    leadAddress: () => ({
      title: "Lead Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Leads",
        field: "leadAddress",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) => a?.borough?.toLocaleLowerCase() === circleProgressPicker.toLocaleLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: "Leads In " + circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) => a?.borough?.toLocaleLowerCase() === circleProgressPicker.toLocaleLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }),
  }
}
