import ServiceDetails from "../Service/ServiceContent/ServiceDetails/ServiceDetails";
import ChargedIndicator from "./GridComponents/ChargedIndicator/ChargedIndicator";
import DescriptionModal from "./GridComponents/DescriptionModal/DescriptionModal";
import { EditIcon } from "../../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../../utils";
import { CompleatedCheckBox } from "./GridComponents";
import "./columnDefs.scss";
import { Button } from "antd";
import DeleteService from "../Service/ServiceCredit/DeleteService/DeleteService";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import EventReqChanger from "./GridComponents/EventReqChanger/EventReqChanger";

export const columnDefs = ({ service, color, fieldChanger }) => {
  return [
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>A</div><div class='textContainerStretched'>#</div></div>",
      },
      headerTooltip: "Id",
      field: "id",
      editable: false,
      minWidth: 70,
      cellRenderer: ({ value }) => value + 1,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Action</div></div>",
      },
      headerTooltip: "Total Completed",
      field: "totalCompleated",
      editable: false,
      minWidth: 140,
      cellRendererFramework: (gridParams) => (
        <div className="completeCheckboxTextContainer">
          <CompleatedCheckBox {...{ gridParams, service, type: "notHoist" }} />{" "}
          Completed
        </div>
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>B</div><div class='textContainerStretched'>Description of work</div></div>",
      },
      headerName: "Description of work",
      headerTooltip: "Events",
      field: "name",
      minWidth: 370,
      editable: true,
      cellRendererFramework: (gridParams) => {
        return (
          <>
            <div className="descriptionOfWorkCellContainer">
              {gridParams?.value}
              {gridParams?.data?.isCredit !== true ? (
                <></>
              ) : (
                // <ServiceDetails
                //   {...{
                //     borderColor: color,
                //     gridParams,
                //     service,
                //   }}
                // />
                <DeleteService
                  {...{ type: "Service", grid: gridParams, service }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>C</div><div class='textContainerStretched'>Schedule of value</div></div>",
      },
      headerName: "Schedule of value",
      headerTooltip: "Amount",
      field: "amount",
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      minWidth: 160,
      editable: false,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>D</div><div class='textContainer'>From previous requisition</div><div class='actionsContainer'>(D+E)</div></div>",
      },
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      headerTooltip: "From Previous Requisition",
      headerName: "From previous requisition",
      field: "fromPrevious",
      minWidth: 260,
      editable: false,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>Payment Due</div></div>",
      },
      headerName: "Payment Due",
      headerTooltip: "Payment Due",
      field: "paymentDue",
      minWidth: 200,
      cellStyle: {
        // backgroundColor: "white",
        color: "#68C142",
      },
      editable: false,
      // valueGetter: (params) => {
      //   const {
      //     thisPeriod = 0,
      //     taxAmount = 0,
      //     retainage = 0,
      //     reducedAmount = 0,
      //   } = params?.data || {};
      //   const res =
      //     Number(thisPeriod) +
      //     Number(taxAmount) -
      //     Number(retainage) +
      //     Number(reducedAmount);
      //   const fullRetainage =
      //     (params?.data?.amount * params?.data?.retainagePercentage) / 100;
      //   // params.data.paymentDue = res;
      //   // fieldChanger({ key: "paymentDue", value: res, record: params });
      //   return paymentDuePLICalculator(params, fullRetainage);
      //   return res < 0 ? 0 : res;
      // },
      valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>E</div><div class='textContainerStretched'>This period</div></div>",
      },
      headerName: "This period",
      headerTooltip: "This Period",
      field: "thisPeriod",
      minWidth: 160,
      cellEditor: "PeriodCellEditor",
      cellStyle: {
        color: "white",
        backgroundColor: "#68C142",
      },
      cellRendererFramework: (params) => (
        <div className="editableCellContainer">
          <div>{formatCurrency(params?.value, "USD")}</div>
          <div className="editableCellIcon">
            <EditIcon />
          </div>
        </div>
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>This Retaiange</div></div>",
      },
      headerName: "This Retaiange",
      headerTooltip: "This Retaiange",
      field: "thisKeptRetainage",
      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Acc Ret Balance</div></div>",
      },
      headerName: "Accumulated Retainage Balance",
      headerTooltip: "Accumulated Retainage Balance",
      enableFillHandle: true,
      fillHandleDirection: "y",
      field: "accRetainageBalance",
      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>This Distributed Retaiange</div></div>",
      },
      headerName: "This Distributed Retaiange",
      headerTooltip: "This Distributed Retaiange",
      field: "thisDistRetainage",
      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Acc. Distributed Ret</div></div>",
      },
      headerName: "Accumulated Distributed Retainage",
      headerTooltip: "Accumulated Distributed Retainage",
      field: "accRetaiangeDist",
      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>G</div><div class='textContainerStretched'>Total completed & stored to date</div></div>",
      },
      headerName: "Total completed & stored to date",
      headerTooltip: "Total Completed",
      field: "totalCompleated",
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      minWidth: 260,
      editable: false,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>%</div><div class='actionsContainer'>(G/C)</div></div>",
      },
      headerName: "%",
      headerTooltip: "Percentage Completed",
      cellEditor: "PeriodCellEditor",
      field: "percentageCompleated",
      minWidth: 130,
      editable: true,
      cellStyle: {
        color: "white",
        backgroundColor: "#68C142",
      },
      cellRendererFramework: ({ value }) => {
        return (
          <div className="editableCellContainer">
            <div>{!!value ? +(+value)?.toFixed(2) + "%" : "0.00%"}</div>
            <div className="editableCellIcon">
              <EditIcon />
            </div>
          </div>
        );
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Charged</div></div>",
      },
      headerName: "Charged",
      headerTooltip: "charged",
      field: "charged",
      minWidth: 130,
      editable: false,
      cellRendererFramework: (params) => {
        return params?.data?.isCredit ? (
          ""
        ) : (
          <ChargedIndicator
            {...{ service, eventId: params?.data?.id, thisFunction: "event" }}
          />
        );
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Balance to finish</div><div class='actionsContainer'>(C-G)</div></div>",
      },
      headerName: "Balance to finish",
      headerTooltip: "Balance To Finish",
      field: "balanceToFinish",
      minWidth: 200,
      editable: false,
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
    },

    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>Included Retainage</div></div>",
    //   },
    //   headerName: "Included Retainage",
    //   headerTooltip: "Included Retainage",
    //   field: "carriedRetainage",
    //   minWidth: 200,
    //   editable: false,
    //   valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    // },
    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>This Retainage</div></div>",
    //   },
    //   headerName: "This Retainage",
    //   headerTooltip: "This Retainage",
    //   // field: "carriedRetainage",
    //   minWidth: 200,
    //   editable: false,
    //   valueGetter: (params) => {
    //     console.log("thisRettttt", params?.data);
    //     const {
    //       retainage = 0,
    //       carriedRetainage = 0,
    //       reducedAmount = 0,
    //       retainagePercentage = 0,
    //       thisPeriod = 0,
    //       previousRetainage = 0,
    //     } = params?.data;
    //     const res =
    //       (thisPeriod * retainagePercentage) / 100 +
    //       (reducedAmount > 0 ? previousRetainage - reducedAmount : 0);
    //     const finalRes = thisPeriod > 0 || reducedAmount > 0 ? res || 0 : 0;
    //     return formatCurrency(finalRes > 0 ? finalRes : 0, "USD");
    //   },
    // },
    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>I</div><div class='textContainer'>Retainage</div><div class='actionsContainer'>(Balance)</div></div>",
    //   },
    //   headerName: "Retainage",
    //   headerTooltip: "Retainage",
    //   field: "retainage",
    //   minWidth: 140,
    //   editable: false,
    //   valueFormatter: (params) => {
    //     // let curr =
    //     //   params?.data?.thisPeriod !== 0
    //     //     ? formatCurrency(params?.value, "USD")
    //     //     : formatCurrency(0, "USD");
    //     return formatCurrency(params?.value || 0, "USD");
    //   },
    // },
    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>I</div><div class='textContainer'>AMt. No Ret</div><div class='actionsContainer'>(Balance)</div></div>",
    //   },
    //   headerName: "Amount with no retainage",
    //   headerTooltip: "Amount with no retainage",
    //   field: "amountWithNoRetainage",
    //   minWidth: 140,
    //   editable: false,
    //   valueFormatter: (params) => {
    //     // let curr =
    //     //   params?.data?.thisPeriod !== 0
    //     //     ? formatCurrency(params?.value, "USD")
    //     //     : formatCurrency(0, "USD");
    //     return formatCurrency(params?.value || 0, "USD");
    //   },
    // },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Tax Amount</div></div>",
      },
      headerName: "Tax Amount",
      headerTooltip: "Tax Amount",
      field: "taxAmount",
      minWidth: 130,
      editable: false,
      valueFormatter: ({ value }) => formatCurrency(value, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Collectable amount</div><div class='actionsContainer'>(C-D-I)</div></div>",
      },
      headerName: "Collectable amount",
      headerTooltip: "Collectable Amount",
      field: "collectable_amount",
      minWidth: 160,
      editable: false,
      hide: true,
      valueFormatter: (params) =>
        formatCurrency(
          params?.value < 0 || !params.value ? 0 : params?.value,
          "USD"
        ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Collectable amount</div><div class='actionsContainer'>(C-I)</div></div>",
      },
      headerName: "Collectable amount",
      headerTooltip: "Collectable Amount",
      // field: "collectable_amount",
      minWidth: 160,
      editable: false,
      valueGetter: (params) => {
        const {
          amount = 0,
          retainagePercentage = 0,
          accRetainageBalance = 0,
          thisKeptRetainage = 0,
          totalCompleated = 0,
        } = params?.data || {};
        const C_AMOUNT =
          forceToNumber(amount) -
          forceToNumber(totalCompleated) -
          forceToNumber(accRetainageBalance);
        console.log("myparasmss", params?.data);
        return C_AMOUNT < 0 ? 0 : C_AMOUNT;
      },
      valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Description</div></div>",
      },
      headerName: "Description",
      headerTooltip: "Description",
      field: "description",
      minWidth: 130,
      editable: false,
      cellRendererFramework: (params) => (
        <DescriptionModal {...{ service, event: params?.data }} />
      ),
    },
    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Description</div></div>",
    //   },
    //   headerName: "Change Retainage",
    //   headerTooltip: "Description",
    //   // field: "description",
    //   minWidth: 240,
    //   editable: false,
    //   cellRendererFramework: (params) => (
    //     <EventReqChanger {...{ service, event: params?.data, params }} />
    //   ),
    // },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Initial Ret %</div></div>",
      },
      headerName: "Event Req %",
      // headerTooltip: "Description",
      field: "retainagePercentage",
      minWidth: 240,
      editable: true,
      valueFormatter: (params) => {
        return params?.value ? +(+params?.value)?.toFixed(2) + "%" : "0.00%";
      },
      // cellRendererFramework: (params) => (
      //   <EventReqChanger {...{ service, event: params?.data, params }} />
      // ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Ret +/- in %</div></div>",
      },
      headerName: "Event Req %",
      // headerTooltip: "Description",
      // field: "retainagePercentage",
      field: "retainageDndInd",
      minWidth: 240,
      editable: true,
      // cellRendererFramework: (params) => (
      //   <EventReqChanger {...{ service, event: params?.data, params }} />
      // ),
    },
  ];
};

const paymentDuePLICalculator = (valueParamsObj = {}, fullRetainage) => {
  console.log("valueParamsObj:", valueParamsObj?.data);
  const {
    thisPeriod = 0,
    taxAmount = 0,
    retainage = 0,
    reducedAmount = 0,
    previousRetainage = 0,
  } = valueParamsObj?.data || {};
  // const fullRetainage = 0;
  const res =
    Number(thisPeriod) +
    Number(taxAmount) -
    (Number(fullRetainage) - Number(reducedAmount));
  // params.data.paymentDue = res;
  return Math.abs(res);
  if (thisPeriod === 0 && reducedAmount === 0) return 0;
  else if (reducedAmount !== 0) return Math.abs(res);
  else return Math.abs(res);
};
