import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { FormGridStatusRenderer } from "../../Header/forms/Components";
import { UploadedFilesRenderer } from "../../Header/forms/PermitDrawingModal/components";
import { MissingFilesRenderer, RecordFilesRenderer } from "./components";
import { queryParams } from "../../../utils/queryParams";
import { Notes } from "../../commonComponents/Notes/Notes";
import ProjManagerAvatar from "../../commonComponents/ProjectManagerAvatar/ProjManagerAvatar";
import { DynamicAvatars } from "../../commonComponents/DynamicAvatars/DynamicAvatars";
import DocShownIn from "./DocShownIn";
import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import { sortDates } from "../ToDos/AgGridData/columnDefs";

const DATE_FORMAT = "MM/DD/YYYY";

export const categoryViewStatuses = [
  {
    status: "Missing Documentations",
    color: "#FE4C4A",
  },
  {
    status: "Everything Up-to-date",
    color: "#71CF48",
  },
];

export const excelColumnsConfig = [
  "docType",
  "docStatus",
  "categoryName",
  "projectManager",
  "isIncludedIn",
  "expirationDate",
  "teamsConfiguration",
  "createdBy",
  "createdAt",
  "recordName",
  "status",
  "allGoogleDriveUploads",
  "missingFiles",
  "nearestExpDate",
];

export const formatCellValueHandler = (data) => {
  if (data.length < 1) return "0";
  if (data[0].hasOwnProperty("members"))
    return data[0].members.map(({ nameOfUser }) => nameOfUser).join(", ");
  if (data[0].hasOwnProperty("downloadUrl"))
    return data.map(({ name }) => name).join(", ");
};

export const documentationsPageColDef = ({
  accessToken,
  docMapping,
  statuses,
  selectedCategory = "",
  docConfig,
  isDarkMode,
  setDocumentationModalVisible,
  setDocumentationModalProps,
}) => {
  const centerItem = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return !selectedCategory
    ? [
        {
          headerName: "Type",
          field: "docType",
          filter: "agTextColumnFilter",
          link: (data) => {
            let endpoint =
              docMapping[data.categoryName]?.endpoint?.toLowerCase();
            if (data?.categoryName === "Drivers") {
              endpoint = "fleet/drivers";
            }
            if (data?.categoryName === "Fleet") {
              endpoint = "fleets";
            }
            if (data?.categoryName === "Incidents") {
              endpoint = "incidents";
            }

            if (data?.categoryName === "Employee") {
              endpoint = "hrManagement/Employee";
            }

            return `/${endpoint}/${data.recordId}?tab=Documentation&docId=${data.docId}`;
          },
          cellRendererFramework: function (params) {
            try {
              let endpoint =
                docMapping[params.data.categoryName]?.endpoint?.toLowerCase();
              if (params.data.categoryName === "Drivers") {
                endpoint = "fleet/drivers";
              }
              if (params.data.categoryName === "Fleet") {
                endpoint = "fleets";
              }
              if (params.data.categoryName === "Employee") {
                endpoint = "hrManagement/Employee";
              }

              const categoriesWithOutDocumentationPage = [
                "Safety",
                "Incidents",
                "Inspections",
              ];
              // If category doesn't have a Dedicated Documentation Page open the DocumentationModal
              if (
                categoriesWithOutDocumentationPage.includes(
                  params?.data?.categoryName
                )
              ) {
                return (
                  <div
                    style={{ color: "#1065A4" }}
                    onClick={() => {
                      setDocumentationModalVisible(true);
                      setDocumentationModalProps(params.data);
                    }}
                  >
                    {params.data?.docType}
                  </div>
                );
              } else {
                // If they have a Documentation Page, Link to that page
                return (
                  <Link
                    to={{
                      pathname: `/${endpoint}/${params.data.recordId}`,
                      search: queryParams({
                        tab: "Documentation",
                        docId: params.data.docId,
                      }),
                      state: {
                        data: params.data,
                        tabPosition: "documentation",
                      },
                    }}
                    style={{ color: "#1065A4" }}
                  >
                    {params.data?.docType}
                  </Link>
                );
              }
            } catch (err) {
              if (params.data?.docType) {
                return params.data?.docType;
              } else {
                return null;
              }
            }
          },
          enableRowGroup: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Status",
          field: "docStatus",
          filter: "agTextColumnFilter",
          cellRenderer: ({ value }) =>
            FormGridStatusRenderer({ value, statuses }),
          cellClass: "centeredCell",
          resizable: true,
          sortable: true,
          enableRowGroup: true,
        },
        {
          headerName: "Category",
          field: "categoryName",
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          enableRowGroup: true,
          cellClass: "centeredCell",
        },
        {
          headerName: "Record Name",
          field: "recordName",
          filter: "agTextColumnFilter",
        },
        // {
        //   headerName: "Project Manager",
        //   field: "projectManager",
        //   enableRowGroup: false,
        //   cellRendererFramework: ({ data }) => {
        //     return (
        //       <>
        //         <ProjManagerAvatar
        //           category={data?.categoryName}
        //           recordId={data?.recordId}
        //           data={data}
        //         />
        //       </>
        //     );
        //   },
        // },
        {
          headerName: "Shown In",
          field: "isIncludedIn",
          filter: "agTextColumnFilter",
          cellRendererFramework: ({ value }) => {
            return (
              <div style={centerItem}>
                {DocShownIn({
                  value: value || [],
                  title: "This Doc is shown in",
                })}
              </div>
            );
          },
        },
        {
          headerName: "Files Uploaded",
          field: "googleDriveUploads",
          filter: "agTextColumnFilter",
          cellRendererFramework: (params) => {
            return UploadedFilesRenderer({
              documentation: params.data,
              node: { data: { googleDriveUploads: params?.value } },
              accessToken,
              fromDoc: true,
            });
          },
          pdfExportOptions: {
            modifyCellValue: (value) => {
              return value;
            },
          },
          cellClass: "centeredCell",
          resizable: true,
          sortable: true,
          enableRowGroup: false,
        },
        {
          headerName: "Expiration Date",
          field: "expirationDate",
          filter: "agTextColumnFilter",
          cellClass: "centeredCell",
          valueGetter: ({ data }) => {
            return data.expirationDate
              ? dayjsNY(data.expirationDate).format("MM/DD/YYYY")
              : "";
          },
          comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
          resizable: true,
          sortable: true,
          enableRowGroup: true,
        },
        {
          headerName: "Notes",
          field: "notes",
          filter: "agMultiColumnFilter",
          enableRowGroup: false,
          pdfExportOptions: {
            skipColumn: true,
          },
          cellRendererFramework: (params) => {
            return Notes({
              noteModalName: params?.data?.docType,
              rowId: params?.data?.docId,
              topicCategory: "Documentations",
            });
          },
        },
        {
          headerName: "Team",
          field: "teamsConfiguration",
          enableRowGroup: false,
          cellRendererFramework: ({ data }) => {
            let myTeam = [];

            let categories =
              docConfig?.find((el) => {
                return el?.categoryName === data?.categoryName;
              })?.documentationsAvailable ?? [];

            if (Array.isArray(categories)) {
              let docsAvailable = categories?.find(
                (cat) => cat?.docName === data?.docType
              );

              if (!!docsAvailable) {
                myTeam = docsAvailable?.teamsConfiguration ?? [];
              }
            }

            return (
              <DynamicAvatars
                {...{
                  title: "Team",
                  isDarkMode: isDarkMode,
                  members: myTeam.reduce(
                    (acc, { members }) => [...acc, ...members],
                    []
                  ),
                }}
              />
            );
          },
        },
        {
          headerName: "Created At",
          field: "createdAt",
          valueGetter: ({ data }) =>
            dayjs(data?.createdAt).format("MM/DD/YYYY"),
          sort: "desc",
          filterParams: { apply: true, newRowsAction: "keep" },
          // hide: true,
          minWidth: 150,
          comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
        },
        {
          headerName: "Created By",
          field: "createdBy",
          valueGetter: ({ data }) =>
            !!data?.createdBy?.name
              ? data?.createdBy?.name
              : data?.createdBy?.nameOfUser || data?.createdBy,
          filterParams: { apply: true, newRowsAction: "keep" },
          enableRowGroup: true,
          // hide: true,
          minWidth: 150,
          cellRenderer({ value }) {
            return (
              <DynamicAvatars
                {...{
                  members: [value],
                }}
              />
            );
          },
        },
      ]
    : [
        {
          headerName: "Name",
          field: "recordName",
          filter: "agTextColumnFilter",
          link: (data) =>
            `${docMapping[data?.categoryName]?.route?.toLowerCase()}/${
              data?.recordId
            }`,
          cellRendererFramework: function (params) {
            try {
              const categoriesWithOutDocumentationPage = [
                "Safety",
                "Incidents",
                "Inspections",
              ];
              // If category doesn't have a Dedicated Documentation Page open the DocumentationModal
              if (
                categoriesWithOutDocumentationPage.includes(
                  params?.data?.categoryName
                )
              ) {
                // add all Google DriveUploads files to params.data.googleDriveUploads to render on the DocUploader
                params.data.googleDriveUploads =
                  params?.data?.allGoogleDriveUploads
                    .map((item) => item.googleDriveUploads)
                    .flat();
                return (
                  <div
                    onClick={() => {
                      setDocumentationModalVisible(true);
                      setDocumentationModalProps(params.data);
                    }}
                  >
                    {params.data?.docType}
                  </div>
                );
              } else {
                // If they have a Documentation Page, Link to that page
                return (
                  <Link
                    to={{
                      pathname: `${docMapping[
                        params?.data?.categoryName
                      ]?.route?.toLowerCase()}/${params?.data?.recordId}`,
                    }}
                    state={{ tabPosition: "documentation" }}
                  >
                    {params.data?.recordName || params.data?.docType}
                  </Link>
                );
              }
            } catch (err) {
              if (params.data?.recordName) {
                return params.data?.recordName;
              } else {
                return null;
              }
            }
          },
          resizable: true,
          sortable: true,
          enableRowGroup: true,
        },
        {
          headerName: "Documentations Status",
          field: "status",
          filter: "agTextColumnFilter",
          valueGetter: ({ data }) => {
            return data?.missingFiles?.length
              ? "Missing Documentations"
              : "Everything Up-to-date";
          },
          cellRenderer: (params) =>
            FormGridStatusRenderer({
              value: !!params?.data?.missingFiles?.length
                ? "Missing Documentations"
                : "Everything Up-to-date",
              statuses: categoryViewStatuses,
            }),
          cellClass: "centeredCell",
          resizable: true,
          sortable: true,
          enableRowGroup: true,
        },
        {
          headerName: "Files Uploaded",
          field: "allGoogleDriveUploads",
          filter: "agTextColumnFilter",
          cellRendererFramework: (params) => {
            return RecordFilesRenderer({ ...params, docMapping });
          },
          cellClass: "centeredCell",
          resizable: true,
          sortable: true,
          enableRowGroup: false,
          pdfExportOptions: {
            skipColumn: true,
          },
        },
        {
          headerName: "Missing Files",
          field: "missingFiles",
          filter: "agTextColumnFilter",
          cellRendererFramework: (params) =>
            MissingFilesRenderer({ ...params, docMapping }),
          cellClass: "centeredCell",
          resizable: true,
          sortable: true,
          enableRowGroup: true,
        },
        {
          headerName: "Nearest Exp. Date",
          field: "nearestExpDate",
          filter: "agTextColumnFilter",
          cellClass: "centeredCell",
          valueGetter: ({ data }) => {
            return data?.nearestExpDate
              ? dayjs(data?.nearestExpDate).format(DATE_FORMAT)
              : "";
          },
          resizable: true,
          sortable: true,
          enableRowGroup: true,
          comparator: (date1, date2) => sortDates(date1, date2, DATE_FORMAT),
        },
        {
          headerName: "Notes",
          field: "notes",
          filter: "agMultiColumnFilter",
          enableRowGroup: false,
          cellRendererFramework: (params) => {
            return Notes({
              noteModalName: params?.data?.recordName,
              rowId: params?.data?.recordId,
              topicCategory: params?.data?.categoryName,
            });
          },
        },
        {
          headerName: "Created By",
          field: "createdBy",
          valueGetter: ({ data }) => data?.createdBy,
          filterParams: { apply: true, newRowsAction: "keep" },
          enableRowGroup: true,
          // hide: true,
          minWidth: 150,
          cellRenderer({ value }) {
            return (
              <DynamicAvatars
                {...{
                  members: value,
                }}
              />
            );
          },
        },
      ];
};
