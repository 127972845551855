import { Fragment, useState } from "react";

import { useRedux } from "../../../../../../hooks/useRedux";
import { renderServices } from "../utils/renderServicesContainer";
import { checkCheckboxStatus } from "../utils/checkCheckboxStatus";
import { lengthOfItemsSelected } from "../utils/lengthOfItemsSelected";
import { LeftArrow, RightArrow } from "../../../../../../BasePage/src";
import { formatCurrency } from "../../../../../../utils/formatCurrency";
import { useCreateChargeContext } from "../context/NewChargeItemContext";
import { countAdditionalRentals } from "../utils/countAdditionalRentals";
import NewCheckBoxWithLabel from "src/components/commonComponents/NewCheckBoxWithLabel/NewCheckBoxWithLabel";
import EyeCheckBox from "../../../../../../../commonComponents/EyeCheckBox/EyeCheckBox";
import ServiceGroupItem from "../../../../../../../commonComponents/ServiceGroupItem/ServiceGroupItem";
import { groupServicesByEstimationId } from "../../ChooseChargeFromModal/utils/groupServicesByEstimationId";

export function ServicesContainer() {
  const [{ categoryFrom, whereToFind, selectedGroup }] =
    useRedux("chargeItemsModal");

  const [collapsed, setCollapsed] = useState(false);

  const {
    selectedServices,
    setSelectedServices,
    chargeToSave,
    includeRentals,
    setIncludeRentals,
    currentCharge,
  } = useCreateChargeContext();

  const onServiceClick = (label, estimationId, e) => {
    e.preventDefault();
    setSelectedServices((prev) => {
      const updatedServices = prev.map((estimation) =>
        estimation.estimationId === estimationId
          ? {
              ...estimation,
              services: {
                ...estimation.services,
                [label]: !estimation.services[label],
              },
            }
          : estimation
      );
      return structuredClone(updatedServices);
    });
  };

  const onCheckboxClick = (event, estimationId) => {
    event.preventDefault();
    const checked = event.target.checked;
    setSelectedServices((prev) => {
      const updatedServices = prev.map((estimation) =>
        estimation.estimationId === estimationId
          ? {
              ...estimation,
              services: Object.fromEntries(
                Object.entries(estimation.services).map(([key]) => [
                  key,
                  checked,
                ])
              ),
            }
          : estimation
      );
      return structuredClone(updatedServices);
    });
  };

  const chargeItems = chargeToSave.chargeItems;

  const rentalsLength = whereToFind?.includedRentals?.length;

  const estimations = groupServicesByEstimationId(
    renderServices({
      categoryFrom,
      whereToFind,
      selectedGroup,
      currentCharge,
    })
  );

  const accumulatedRentals = countAdditionalRentals(
    whereToFind?.accumulatedRentals
  );

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={`itemsGroupContainer ${collapsed && "collapsed"}`}
      data-testid="services-container"
    >
      <div class="services-collapse" onClick={toggleCollapse}>
        {collapsed ? <RightArrow /> : <LeftArrow />}
      </div>
      {estimations.map((estimation) => {
        const currentSelected = selectedServices.find(
          (service) => service.estimationId === estimation.estimationId
        );

        const isEnabled = checkCheckboxStatus(currentSelected);

        return (
          <Fragment key={estimation.estimationId}>
            {estimations.length > 1 && (
              <NewCheckBoxWithLabel
                label={estimation.name}
                className="service-estimation-name"
                checked={isEnabled}
                onClick={(e) => onCheckboxClick(e, estimation.estimationId)}
                indeterminate={isEnabled === "indeterminate"}
              />
            )}
            {estimation.services?.map(
              ({ label = "", estimationId, serviceId }, key) => {
                const isChecked = currentSelected?.services?.[label];
                const length = lengthOfItemsSelected(
                  label,
                  estimationId,
                  chargeToSave,
                  categoryFrom
                );

                const item = chargeItems?.find(
                  (service) =>
                    service.serviceId === serviceId &&
                    service.estimationId === estimationId
                );
                const totalPrice = item?.totalPaymentDue || item?.price || 0;
                const totalTaxAmount = item?.taxAmount || 0;
                const total = totalPrice + totalTaxAmount;

                return (
                  <ServiceGroupItem
                    {...{
                      index: key,
                      label,
                      length,
                      subLabel: `Total: ${formatCurrency(total)}`,
                      checked: isChecked,
                      onClick: (e) => onServiceClick(label, estimationId, e),
                    }}
                  />
                );
              }
            )}
          </Fragment>
        );
      })}
      {categoryFrom === "Requisition" &&
        whereToFind?.includedRentals &&
        whereToFind?.includedRentals?.length !== 0 && (
          <ServiceGroupItem
            {...{
              index: 0,
              label: "Include Rentals",
              length,
              subLabel: `${rentalsLength} 
                ${rentalsLength === 1 ? "Rental" : "Rentals"}`,
              checked: includeRentals,
              onClick: (e) => {
                e.preventDefault();
                setIncludeRentals(
                  !includeRentals
                    ? whereToFind?.includedRentals.map(
                        ({ rentalId }) => rentalId
                      )
                    : false
                );
              },
            }}
          />
        )}

      {accumulatedRentals > 0 && (
        <div className="groupItem">
          <div
            checked={accumulatedRentals}
            className={`checkBox ${accumulatedRentals ? "checkedActive" : ""}`}
            data-testid="checkbox-toggle-included-rentals"
          >
            <EyeCheckBox checked={accumulatedRentals} />
          </div>
          <div className="serviceContainer">
            <span className="groupItemLabel">Accumulated Rentals</span>
            <span className="groupItemSubLabel">
              {`${accumulatedRentals} `}
              {accumulatedRentals === 1 ? "Rental" : "Rentals"}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
