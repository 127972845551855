import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Table } from "antd";
import { API } from "aws-amplify";
import dayjs from "dayjs";

import {
  TrashIcon,
  XIcon,
} from "../../../../../../SidebarPages/Communication/assets";
import { MondayButton, WarningModal } from "../../../../../../commonComponents";
import { analyticColumns } from "./analyticsDeleteData";
import { InfoIcon } from "../../../../../../../assets";
import { TickIcon } from "../../../../../Settings/settingsComponents/Roles/src";
import { fetchByDate } from "../../../../../../../utils";
import {
  getEmployeeAnalytics,
  groupEntriesInShifts,
} from "../../../DEG/components/modalComponents/utils";
import { WarningTriangle } from "../../../../../../SidebarPages/DynamicView/src";

import "./AnalyticsDeleteModal.scss";

function AnalyticsDeleteModal({
  open,
  onCancel,
  entry,
  gridApi,
  setRemovedRowData,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [deleteWarning, setDeleteWarning] = useState(false);
  const [rowData, setRowData] = useState([]);

  function deleteEntryAnalytics() {
    for (const entry of rowData) {
      API.put("degEntries", `/degEntries/${entry.entryId}`, {
        body: {
          removed: true,
        },
      });
    }
    gridApi.applyTransaction({ remove: rowData });
    setRemovedRowData((prev) => prev.concat(rowData));
  }

  useEffect(() => {
    if (!!entry?.degId) {
      // Get all entries of the Payroll
      fetchByDate(
        "degEntries",
        [
          dayjs(entry.punchTimeStamp).startOf("d").subtract(1, "day").valueOf(),
          dayjs(entry.punchTimeStamp).startOf("d").add(1, "day").valueOf(),
        ],
        "punchTimeStamp",
        "employeeId",
        entry?.employeeId
      ).then((res) => {
        // filterTables("costAnalytics", "degId", entry.degId).then((res) => {
        const degRows = res.filter((entry) => !entry?.removed);
        let tmpAnalytics = {};
        // get analytics of the payroll
        getEmployeeAnalytics({
          degRows,
          degGridApi: {},
          analyticsUpdate: (data) => (tmpAnalytics = data),
          externalFiltersPass: () => {
            return true;
          },
        });
        // Get shifts of the Payroll from analytics
        const shifts = groupEntriesInShifts({
          analytics: tmpAnalytics,
          rowData: degRows,
        });
        // find shift of the selected entry
        const shiftOfEntry = shifts.find(({ entries }) =>
          entries.includes(entry?.entryId)
        );
        // get entries of the shift
        const shiftEntries = degRows.filter(({ entryId }) =>
          shiftOfEntry.entries.includes(entryId)
        );
        setRowData(shiftEntries);
      });
    }
  }, [entry]);

  return (
    <Modal
      {...{
        open,
        onCancel,
        closeIcon: <XIcon />,
        closable: true,
        title: `Analytics Delete`,
        className: `analyticsDeleteModal ${
          isDarkMode && "analyticsDeleteModalDark"
        }`,
        centered: true,
        destroyOnClose: true,
        footer: [
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={onCancel}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            Icon={<TrashIcon />}
            onClick={() => setDeleteWarning(true)}
          >
            Delete
          </MondayButton>,
        ],
      }}
    >
      <section className="infoSection">
        <InfoIcon />
        <span>Table below shows the shift of the selected entry.</span>
      </section>
      <section className="tableWrapper">
        <Table dataSource={rowData} columns={analyticColumns} />
      </section>
      {deleteWarning && (
        <WarningModal
          visible={deleteWarning}
          setVisible={setDeleteWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to delete this shift?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setDeleteWarning(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  deleteEntryAnalytics();
                  setDeleteWarning(false);
                  onCancel();
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Modal>
  );
}

export default AnalyticsDeleteModal;
