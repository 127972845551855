import uniq from "lodash/uniq";
import { boroughs } from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const leads = ({ tables, programFields }) => {
  return {
    leadStatus: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Lead Status Types")
        ?.fieldOptions?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: tables?.Leads?.length,
          taskDone: tables?.Leads?.filter((a) => a?.leadStatus === status)
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Lead`,
          contentsHandler: ` ${status} Leads`,
        })),
    }),
    leadAddress: () => ({
      content: boroughs?.map((status, index) => ({
        key: index,
        title: status,
        taskNumber: tables?.Leads?.length,
        taskDone: tables?.Leads?.filter((a) => a?.borough === status)?.length,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: " Lead in " + status,
        contentsHandler: " Leads in " + status,
      })),
    }),

    projectExecutive: () => ({
      content: Object.entries(
        repeatedElementSum(tables?.Leads, "projectExecutive")
      )?.map(([key, value], index) => ({
        key: index,
        title: key,
        taskNumber: tables?.Leads?.length,
        taskDone: value,
        color: "#71CF48",
        backgroundColor: "#B2DD9B",
        contentHandler: ` ${key} Lead`,
        contentsHandler: ` ${key} Leads`,
      })),
    }),
  };
};
