import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import { compareIncluding, formatCurrency } from "../../../../../../../utils";
import { parsePhone } from "../../../../../../../Accounting/components/utilities";
import {
  pdfBorderedSection,
  docDefinition,
} from "../../../../../../../../pages/Settings/pdfExportOnComponents";

import { HistoryOutlined } from "@ant-design/icons";
import { DeleteIcon, NewTabIcon } from "../../../../../../../DynamicView/src";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { ExportPdfIcon } from "../../../../../../../../../assets";
import { getDispatchLocationOptions } from "../../../../../../../../Header/forms/RequestFormModal/requestFormModalData";
import { MondayButtonIcon } from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

export const footerButtons = ({
  onCancel,
  onSave,
  saving,
  onDelete,
  canDelete,
  onLogClick,
  onCreatePdf = () => {},
}) => [
  {
    text: "Cancel",
    cancel: true,
    onClick: onCancel,
    className: "mondayButtonRed",
  },
  canDelete && {
    text: "Show Logs",
    onClick: onLogClick,
    className: "mondayButtonBlue",
    btnIcon: <HistoryOutlined />,
  },
  canDelete && {
    text: "Delete",
    cancel: true,
    onClick: onDelete,
    className: "mondayButtonRed",
    btnIcon: <DeleteIcon />,
  },
  canDelete && {
    text: "Export to PDF",
    className: "mondayButtonBlue",
    onClick: onCreatePdf,
    btnIcon: <ExportPdfIcon />,
  },
  {
    text: "Save",
    primary: true,
    onClick: onSave,
    disabled: !!saving,
    btnIcon: <TickIcon width={18} height={18} />,
  },
];

const disabledDate = (curr) => curr > dayjsNY();

export const inputFields = ({
  vehicles,
  drivers,
  incidentStatuses,
  fleetId,
  driverName = "",
  createdBy = "",
  createdAt,
  lastModifiedBy = "",
  projects,
  schedulesForDate,
  changeDateOfIncident,
  onChangeAddress,
  disableAllFields,
  addressSelectionInfo,
  navigate,
  defaultId,
}) =>
  [
    {
      label: "Incident Address",
      formItemName: "incidentAddress",
      placeholder: "Click and Search...",
      required: true,
      type: "placesautocomplete",
      groupedOptions: getDispatchLocationOptions(schedulesForDate, projects),
      onSelect: onChangeAddress,
      disabled: disableAllFields,
    },
    defaultId &&
      (!!addressSelectionInfo?.projectId ||
        !!addressSelectionInfo?.scheduleId) && {
        customComponent: MondayButtonIcon,
        componentProps: {
          Icon: <NewTabIcon />,
          onClick: () => {
            !!addressSelectionInfo?.projectId
              ? navigate(`/projects/${addressSelectionInfo?.projectId}`)
              : !!addressSelectionInfo?.scheduleId
              ? navigate(`/scheduling/${addressSelectionInfo?.scheduleId}`)
              : null;
          },
          name: !!addressSelectionInfo?.projectId
            ? "Go to Project"
            : !!addressSelectionInfo?.scheduleId
            ? "Go to Schedule"
            : null,
          style: { alignSelf: "center", marginRight: 10 },
        },
      },
    {
      label: "Reported By",
      placeholder: "Reported By",
      formItemName: "reportedBy",
      type: "input",
      required: true,
      disabled: disableAllFields,

      // style: { minWidth: 260, width: 260 },
    },
    {
      label: "Date",
      placeholder: "Date",
      formItemName: "incidentDate",
      type: "datepicker",
      disabledDate,
      required: true,
      disabled: !!fleetId || disableAllFields,
      onChange: changeDateOfIncident,
      // style: { minWidth: 260, width: 260 },
    },
    {
      label: "Time",
      placeholder: "Time",
      formItemName: "incidentTime",
      type: "timepicker",
      required: true,
      disabled: disableAllFields,
      // style: { minWidth: 260, width: 260 },
    },
    {
      label: "Vehicle",
      formItemName: "fleetName",
      placeholder: "Select...",
      required: true,
      disabled: !!fleetId || disableAllFields,
      type: !!fleetId ? "input" : "select",
      showSearch: true,
      options: vehicles,
      // style: { minWidth: 260, width: 329 },
    },
    {
      label: "Driver",
      formItemName: "driverName",
      placeholder: "Select...",
      required: true,
      disabled: !!driverName || disableAllFields,
      type: "select",
      showSearch: true,
      options: drivers,
      // style: { minWidth: 329, width: 329 },
    },

    // {
    //   label: "Status",
    //   formItemName: "incidentStatus",
    //   placeholder: "Select...",
    //   required: true,
    //   type: "select",
    //   showSearch: true,
    //   options: incidentStatuses,
    //   // style: { minWidth: 260, width: 329 },
    // },
    !!createdBy && {
      label: "Created By",
      noFormItem: true,
      initialValue: `${createdBy} at ${dayjsNY(createdAt).format(
        "MM/DD/YYYY hh:mm a"
      )}`,
      disabled: true,
      style: { minWidth: 280 },
    },
    !!lastModifiedBy && {
      label: "Last Modified By",
      noFormItem: true,
      initialValue: `${lastModifiedBy?.name} at ${dayjsNY(
        lastModifiedBy?.date
      ).format("MM/DD/YYYY hh:mm a")}`,
      disabled: true,
      style: { minWidth: 280 },
    },
  ].filter(Boolean);

export const questionAnswers = ["Yes", "No"];

export function getInjuredPeopleDetails(
  incident,
  injuredQuestions,
  word = false
) {
  let rowFields = incident?.incidentReport?.find(
    ({ category }) => category === "Injured Person Details"
  );
  if (!rowFields) {
    return [];
  }

  rowFields = rowFields?.values;
  let injuredValues = new Array(rowFields[0]?.value?.length).fill([]);
  for (let i = 0; i < injuredQuestions?.length; i++) {
    rowFields[i]?.value?.forEach((e, j) => {
      injuredValues[j] = [
        ...injuredValues[j],
        {
          label: rowFields[i]?.label,
          value: rowFields[i]?.label?.includes("Date")
            ? dayjsNY(e)?.format("MM/DD/YYYY")
            : rowFields[i]?.label?.includes("phone") ||
              rowFields[i]?.label?.includes("Emergency Contact")
            ? parsePhone(e)
            : e,
        },
      ];
    });
  }
  if (word) {
    return injuredValues?.map((e, i) => ({
      title: `Injured Person ${i + 1}`,
      body: e.map(({ label, value }) => ({
        key: label,
        value: value || "",
      })),
    }));
  }

  return injuredValues?.map((e) =>
    pdfBorderedSection({ title: "Injured Person", body: e })
  );
}

export function getFormattedIncidentBody(incident) {
  return [
    { label: "Incident Location", value: incident?.incidentAddress },
    { label: "Truck #", value: incident?.fleetName },
    { label: "Driver Name", value: incident?.driverName },
    {
      label: "Incident Date",
      value: incident?.incidentDate
        ? dayjsNY(incident?.incidentDate).format("MM/DD/YYYY")
        : "--",
    },
    {
      label: "Incident Time",
      value: incident?.incidentTime
        ? dayjsNY(incident?.incidentTime).format("HH:mm")
        : "--",
    },
    { label: "Incident Status", value: incident?.incidentStatus },
    { label: "Client Name", value: incident?.accountName },
    {
      label: "Project Address",
      value:
        incident?.projectName !== "N/A"
          ? incident?.projectName
          : incident?.scheduleAddress !== "N/A"
          ? incident?.scheduleAddress
          : "N/A",
    },
    { label: "Incident Notes", value: incident?.incidentNotes },
    {
      label: "Team Members",
      value: incident?.teamsConfiguration?.map((e) =>
        e?.members?.map((a) => a?.nameOfUser)?.join(", ")
      ),
    },
  ];
}

export function getIncidentBooleansValues(incident) {
  let booleans = incident?.incidentReport?.find(
    (e) => e?.category === "Booleans"
  );
  if (!booleans) {
    return [];
  }
  return booleans?.values?.map(({ label, value }) => ({
    label,
    value,
  }));
}

function getAuthoritiesShowedUp(incident) {
  let rowFields = incident?.incidentReport?.find(
    ({ category }) => category === "Details"
  );
  if (!rowFields) {
    return [];
  }
  return pdfBorderedSection({
    title: "Authorities Showed Up",
    body: rowFields?.values?.map(({ label, value }) => ({
      label,
      value,
    })),
  });
}

export function getIncidentUploads(uploads, word = false) {
  if (!uploads) {
    return [];
  }

  let uploadSections = {};

  uploads?.forEach((upload) => {
    if (!!uploadSections?.[upload?.title]) {
      uploadSections[upload?.title] = [
        ...uploadSections[upload?.title],
        {
          label: upload?.label,
          image: upload?.src,
          link: upload?.link,
          height: 175,
          width: 200,
          fit: [200, 200],
        },
      ];
    } else {
      uploadSections[upload?.title] = [
        {
          label: upload?.label,
          image: upload?.src,
          link: upload?.link,
          height: 175,
          width: 200,
          fit: [200, 200],
        },
      ];
    }
  });

  if (word) {
    return Object.keys(uploadSections)?.map((key) => {
      return {
        title: key,
        body: uploadSections?.[key]?.map(({ label, image }) => ({
          key: label,
          value: image || "",
        })),
      };
    });
  }

  return Object.keys(uploadSections)?.map((key) => {
    return pdfBorderedSection({
      title: key,
      body: uploadSections[key],
    });
  });
}

export function getIncidentPdfLayout({
  rowObject,
  base64,
  dynamicDetails: injuredQuestions,
  uploads,
}) {
  const incident = { ...rowObject, ...rowObject?.incidentObject };
  return docDefinition(
    "Vehicle Damage Incident",
    [
      pdfBorderedSection({
        title: "General Information",
        body: getFormattedIncidentBody(incident)
          .concat(getIncidentBooleansValues(incident))
          .concat({
            label: "Signature",
            image: "data:image/png;base64," + incident?.incidentSignature,
          }),
      }),
      getAuthoritiesShowedUp(incident),
      getInjuredPeopleDetails(incident, injuredQuestions),
      ...getIncidentUploads(uploads),
    ],
    base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64
  );
}
