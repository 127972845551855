import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../../../../../commonComponents/index";
import { Select, Modal, message } from "antd";
import AnimatedToggler from "../../../../../../../commonComponents/AnimatedToggler/AnimatedToggler";
import { CloseIconModal } from "../../../../../../../../assets/index";
import { SaveIcon } from "../../../../../../BasePage/src/index";
import { ChainScopeIcon } from "../../../../../../../../icons/index";
import "./ChainScopeTakeOff.scss";
function ChainScopeTakeOff({ gridData, selectedScope, updateStateAndSave }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAutoSum, setIsAutoSum] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const mainServices = Array.isArray(gridData)
    ? gridData?.filter(({ isScope = false }) => isScope === false)
    : [];
  const retreiveAutoSum = (val) => {
    console.log("val", val);
    setIsAutoSum(val);
  };

  const handleSave = () => {
    const previousData = structuredClone(gridData) || {};
    const myScopeTemp = structuredClone(selectedScope) || {};
    const tempServiceData = mainServices?.find(
      ({ serviceId = "" }) => serviceId === selectedService
    );
    myScopeTemp.chainedService = {
      serviceId: tempServiceData?.serviceId,
      serviceName: tempServiceData?.label,
      isAutoSum,
    };
    if (!!tempServiceData) {
      previousData?.forEach((item) => {
        if (item?.serviceId === myScopeTemp?.serviceId) {
          item.chainedService = myScopeTemp.chainedService;
        }
      });
      updateStateAndSave(previousData);
      message.success("Saved Successfully!");
      //   dispatch(editScope(myScopeTemp));
    } else {
      message.error("Please fill all the fields");
    }
  };

  const handleChange = (value) => {
    setSelectedService(value);
  };

  const cleanUp = () => {
    setIsModalOpen(false);
    setSelectedService(null);
    setIsAutoSum(false);
  };
  useEffect(() => {
    console.log("selectedScope", selectedScope);
    if (selectedScope?.chainedService?.serviceId) {
      setSelectedService(Number(selectedScope?.chainedService?.serviceId));
      setIsAutoSum(selectedScope?.chainedService?.isAutoSum);
    }
  }, [
    JSON.stringify(selectedScope?.chainedService?.serviceId),
    isModalOpen,
    gridData,
  ]);

  return (
    <div>
      {" "}
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => setIsModalOpen(true)}
        Icon={<ChainScopeIcon />}
      >
        Link Scope With Service
      </MondayButton>{" "}
      <Modal
        title="Chaining Scope With Service"
        wrapClassName="chainServiceModal"
        open={isModalOpen}
        destroyOnClose
        onOk={handleSave}
        onCancel={cleanUp}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MondayButton
              className="mondayButtonRed"
              Icon={<CloseIconModal />}
              onClick={cleanUp}
            >
              Cancel
            </MondayButton>
            <MondayButton
              className="mondayButtonBlue"
              Icon={<SaveIcon />}
              onClick={handleSave}
            >
              Make the connection
            </MondayButton>
          </div>
        }
      >
        <div className="chainServiceContainer">
          <div className="chainServiceSelect">
            <span className="label-text-chain-service">Chained Service:</span>{" "}
            <Select
              style={{ width: "280px" }}
              optionFilterProp="children"
              showSearch
              className="select-service__select"
              value={selectedService}
              //   defaultValue={selectedScope?.chainedService?.serviceId || null}
              onChange={handleChange}
              options={mainServices?.map(({ serviceId, label = "" }) => ({
                value: serviceId,
                label: label,
              }))}
            />
          </div>

          <div className="chainServiceAutoSum">
            <span className="label-text-chain-service">
              Autosum Amount to Service?
            </span>{" "}
            <div className="animated-toggler-cont">
              <AnimatedToggler
                {...{ checked: isAutoSum, retrieveValue: retreiveAutoSum }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ChainScopeTakeOff;
