const MAX_SIZE_MB = 5; // Maximum size in MB
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert MB to bytes

const getSizeInBytes = (data) => {
  return new Blob([JSON.stringify(data)]).size;
};

export const trimDataIfLargerThan5mb = (data) => {
  let size = getSizeInBytes(data);
  while (size > MAX_SIZE_BYTES) {
    data.shift();
    size = getSizeInBytes(data);
  }
  return data;
};
