import { useState } from "react";
import CustomCheckBox from "../CustomCheckBox/CustomCheckBox";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { showWarningMsg } from "../../../utils";
import { sortCheckedAndUnchecked } from "./utils/sortCheckedAndUnchecked";

const HeadersCategory = ({
  data,
  categoryName,
  allData,
  setAllData,
  search,
}) => {
  const [allHeaders, setAllHeaders] = useState(
    data.map((header, index) => ({
      name: header,
      id: index,
    }))
  );

  const [selectedCategoryHeaders, setSelectedCategoryHeaders] =
    useState(allHeaders);
  const maxLength = 9;

  const sortedHeaders = allHeaders.filter(
    (header) =>
      selectedCategoryHeaders.some(
        (selectedHeader) => selectedHeader.name === header.name
      ) || header.name.toLowerCase().includes(search.toLowerCase())
  );

  const modifydata = (key, number, action) => {
    if (allData.hasOwnProperty(key)) {
      const index = allData[key].indexOf(number);
      if (index !== -1 && action === "remove") {
        allData[key].splice(index, 1);
      } else if (index === -1 && action === "add") {
        allData[key].push(number);
      } else {
        console.log(
          `${number} is already ${action === "remove" ? "not" : ""} in ${key}`
        );
      }
    }

    return allData;
  };

  const addOptionToTable = (option) => {
    if (selectedCategoryHeaders.length > maxLength) {
      showWarningMsg({
        content: `You can select maximum ${maxLength + 1} table columns`,
      });
      return;
    }

    setAllData(modifydata(categoryName, option.id, "add"));

    setSelectedCategoryHeaders((prev) => [...prev, option]);
  };

  const removeOptionFromTable = (option) => {
    if (selectedCategoryHeaders.length === 1) {
      showWarningMsg({
        content: "At least one table column should be selected",
      });
      return;
    }

    setAllData(modifydata(categoryName, option.id, "remove"));

    setSelectedCategoryHeaders((prev) =>
      prev.filter((tableOption) => tableOption.id !== option.id)
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    if (source.index === destination.index) return;

    const movedItem = allHeaders[source.index];
    const updatedHeaders = Array.from(allHeaders);
    updatedHeaders.splice(source.index, 1);
    updatedHeaders.splice(destination.index, 0, movedItem);

    const sortedHeaders = sortCheckedAndUnchecked(
      updatedHeaders,
      allData[categoryName]
    );

    setAllHeaders(sortedHeaders);

    const updatedArray = sortedHeaders.map(({ id }) => id);

    setAllData((prev) => ({
      ...prev,
      [categoryName]: updatedArray,
    }));
  };

  return (
    <div>
      <h4>{categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}</h4>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortedHeaders.map((header, index) => {
                const isChecked = selectedCategoryHeaders.find(
                  ({ id }) => id === header.id
                );
                return (
                  <Draggable
                    key={header.id.toString()}
                    draggableId={header.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CustomCheckBox
                          key={header.id}
                          {...{
                            name: header.name,
                            checked: isChecked,
                            onChange: () =>
                              isChecked
                                ? removeOptionFromTable(header)
                                : addOptionToTable(header),
                            style: { marginBottom: "10px", width: "99%" },
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default HeadersCategory;
