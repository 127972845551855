import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const maintenance = (updatedDynamicStates, IdHandler) => {
  return {
    createdAt: () => ({
      title: "Maintenance",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Maintenance",
        field: "createdAt",
        title: "Creation Date",
        subtitle: "",
        content: (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                dateCreated: 1630965600000,

                percent: (
                  ((updatedDynamicStates?.[IdHandler] || [])
                    ?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                    ?.map((a) => a?.createdAt)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    (updatedDynamicStates?.[IdHandler] || [])?.filter(
                      (b) => b.createdAt >= getMonthsTimestamps()[0]
                    ).length) *
                  100
                ).toFixed(1),
                showInfo: true,
                status: months[i],
                done: (updatedDynamicStates?.[IdHandler] || [])
                  ?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.createdAt)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),
      },
      id: IdHandler,
      width: "390px",
    }), //createdAt
  }; //Maintenance
};
