export const getColumnIds = (columnApi) => {
  return columnApi?.getColumns().map((column) => column.getId()) || [];
};
export const getColumnWidths = () => {
  const rowWidth = parseInt(
    document.getElementsByClassName("ag-row")[0]?.offsetWidth
  );
  const parentWidth = parseInt(
    document.getElementsByClassName("ag-body-viewport")[0]?.offsetWidth
  );
  return { rowWidth, parentWidth };
};
export const autoSizeAllColumns = (rowData, grid, skipHeader) => {
  if (rowData && grid) {
    const { columnApi, api } = grid;
    const allColumnIds = getColumnIds(columnApi);
    columnApi?.autoSizeColumns(allColumnIds, skipHeader);
    const widths = getColumnWidths();
    if (widths.rowWidth < widths.parentWidth) {
      api?.sizeColumnsToFit();
    }
  }
};
