import { PlusIcon } from "../../../../assets";
import { onClickFormHandler } from "../../../../utils";
import { MondayButton } from "../../../commonComponents";
import { AnimatedCheckBox } from "../../../SidebarPages/Fleet/components/InputComponent/components";
import {
  compareIncluding,
  compareIncludingAccurate,
} from "../../../SidebarPages/utils/compareIncluding";

export const footerButtons = ({
  onCancel,
  onSave,
  onOptionClick,
  suffix,
  saving,
  saveOnly = false,
  preventContinue,
}) => {
  const onClick = onClickFormHandler(saving);
  let secondOption = preventContinue ? "Finish" : "Save & Close";

  return [
    {
      text: "Cancel",
      cancel: true,
      onClick: onCancel,
      className: "mondayButtonRed",
      tooltipCategory: "Clients",
      tooltipKey: "cancel",
    },
    {
      text: preventContinue ? "Save & Close" : "Finish",
      primary: true,
      onClick: onClick(onSave),
      disabled: !!saving,
      dropdownDisabled: !!saveOnly || !!saving,
      options: ["Save & New", secondOption],
      onOptionClick: onClick(onOptionClick),
      className: "mondayButtonBlue saveButton",
      suffix: suffix,
      tooltipCategory: "Clients",
      tooltipKey: "finish",
    },
  ];
};

export const COMPANY_INFORMATION = "Company Information";
export const ADDRESS_INFORMATION = "Address Information";
const clientTypes = ["Clients", "Competitor", "Subcontractors", "Vendor"];

const employeeInput = document.getElementsByClassName("employees")[0];
const emailInput = document.getElementsByClassName("email")[0];
const websiteInput = document.getElementsByClassName("website")[0];

export const fieldsJSON = ({
  accounts,
  leads,
  proppedType,
  description,
  setDescription,
  setExistingClientsOptions,
  existingClientsOptions,
  subcontractorTypes,
  buildingManagementCategories,
  creatingOnLeadView,
  creatingOnProjectView,
  setSelectedCompanyName,
  setSelectedSubcontractorType,
  selectedSubcontractorType,
  onSelect,
  accountName,
  onDeselect,
  clientTeam,
  preQualificationDisabled,
  // setSelectedAccountName,
  accountPhone,
  accountMobile,
  accountEmail,
  phoneExtension,
  leadWebsite,
  projectExecutives,
  salesPerson,
  prequalificationStages,
  preQualificationOnChange,
  mandatoryPreQualificationStages,
  accessToken,
  onPickerSuccess,
  fileNames,
  parentId,
  checkShabbat,
  setCheckShabbat,
  type,
  form,
  setSubcontractorModal,
  customerPortalUsers = [],
}) => [
  {
    category: "recordType",
    fields: [
      {
        formItemName: "clientRecordType",
        type: "radio",
        options: clientTypes,
        required: true,
      },
    ],
  },
  {
    category: COMPANY_INFORMATION,
    fields: [
      {
        className: "companyName",
        label: "Company Name",
        formItemName: "accountName",
        placeholder: "Company Name",
        type: "autocomplete",
        disabled: !!accountName,
        required: true,
        popupMatchSelectWidth: 400,
        customOptions: [
          form?.getFieldValue("accountName")?.length > 2 && {
            label: "Existing Clients",
            options: existingClientsOptions,
          },
        ].filter(Boolean),
        onSearch: (value) => {
          if (value.length > 1) {
            setExistingClientsOptions(
              accounts
                .filter((record) =>
                  compareIncluding(record?.accountName, value)
                )
                .sort((a, b) => {
                  return a?.accountName?.length - b?.accountName?.length;
                })
                .map(({ accountName, accountId }) => ({
                  value: accountName,
                  label: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {accountName}
                      <a href={`/accounts/${accountId}`} target="_blank">
                        Go To
                      </a>
                    </div>
                  ),
                  disabled: true,
                }))
            );
          } else {
            setExistingClientsOptions(
              accounts?.map(({ accountName, accountId }) => ({
                value: accountName,
                disabled: true,
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {accountName}
                    <a href={`/accounts/${accountId}`} target="_blank">
                      Go To
                    </a>
                  </div>
                ),
              }))
            );
          }
        },
        // onChange: ({ target: { value } }) => {
        //   // setSelectedAccountName(value);
        //   // setSelectedCompanyName(value);
        // },

        rules: [
          {
            validator: (_, value) =>
              !!value &&
              accounts.some(
                ({ accountName, accountRecordType }) =>
                  compareIncludingAccurate(accountName, value) &&
                  accountRecordType === proppedType
              )
                ? Promise.reject(
                    new Error(
                      "There is an existing Client with this account name!"
                    )
                  )
                : Promise.resolve(),
          },
          {
            validator: (_, value) => {
              return !creatingOnLeadView &&
                !creatingOnProjectView &&
                !!value &&
                leads?.some(({ leadCompany }) =>
                  compareIncludingAccurate(leadCompany, value)
                )
                ? Promise.reject(
                    new Error(
                      "There is an existing Lead with this company name!"
                    )
                  )
                : Promise.resolve();
            },
          },
        ],
      },
      {
        label: "Phone",
        formItemName: "accountPhone",
        placeholder: "Phone",
        type: "phone",
      },
      {
        label: "Phone Extension",
        formItemName: "phoneExtension",
        placeholder: "Phone Extension",
        initialValue: !!phoneExtension ? phoneExtension : null,
        typeNumber: true,
      },
      {
        label: "Mobile",
        formItemName: "accountMobile",
        placeholder: "Mobile",
        type: "phone",
      },
      {
        label: "P. Executive",
        formItemName: "projectExecutive",
        placeholder: "Project Executive",
        type: "select",
        showSearch: true,
        options: projectExecutives,
      },
      {
        label: "Sales Person",
        formItemName: "leadSalePerson",
        placeholder: "Sales Person",
        mode: "tags",
        type: "select",
        showSearch: true,
        options: salesPerson,
      },
      proppedType === "Subcontractors" && {
        className: "subcontractorType",
        label: "Subcontractor Type",
        formItemName: "subcontractorType",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        required: true,
        options: subcontractorTypes,
        onSelect: (value) => {
          if (value === "Vendor") {
            employeeInput?.classList.remove("employeesAnimationReverse");
            emailInput?.classList.remove("email-website-animation-reverse");
            websiteInput?.classList.remove("email-website-animation-reverse");
            employeeInput?.classList.add("employeesAnimation");

            setTimeout(() => {
              setSelectedSubcontractorType({ type: value, animated: true });
              employeeInput?.classList.add("employeesHide");
              emailInput?.classList.add("email-website-animation");
              websiteInput?.classList.add("email-website-animation");
            }, 500);
          } else {
            employeeInput?.classList.remove("employeesAnimation");
            emailInput?.classList.remove("email-website-animation");
            websiteInput?.classList.remove("email-website-animation");
            employeeInput?.classList.remove("employeesHide");
            employeeInput?.classList.add("employeesAnimationReverse");
            emailInput?.classList.add("email-website-animation-reverse");
            websiteInput?.classList.add("email-website-animation-reverse");
            setSelectedSubcontractorType({ type: value, animated: false });
          }
        },
        dropdownRender: (menu) => {
          return (
            <>
              {menu}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <MondayButton
                  onClick={() => setSubcontractorModal(true)}
                  Icon={<PlusIcon />}
                >
                  New
                </MondayButton>
              </div>
            </>
          );
        },
      },
      proppedType === "Client" && {
        label: "Building Management Category",
        formItemName: "buildingManagementCategory",
        placeholder: "Select...",
        type: "select",
        showSearch: true,
        options: buildingManagementCategories,
      },
      // selectedSubcontractorType !== "Vendor" &&
      {
        label: "Employees",
        formItemName: "employees",
        placeholder: "Employees",
        type: "input",
        typeNumber: true,
        style: { width: 85 },
        rules: [
          {
            validator: (_, value) =>
              !!value
                ? parseInt(value) >= 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Please enter a valid value"))
                : Promise.resolve(),
          },
        ],
        className: "employees",
      },
      {
        label: "Email",
        formItemName: "accountEmail",
        placeholder: "Email",
        // required: true,
        rules: [
          {
            type: "email",
            message: "Please enter a valid email",
          },
        ],
        className: "email",
      },
      {
        label: "Website",
        formItemName: "website",
        placeholder: "Website",
        type: "input",
        initialValue: !!leadWebsite ? leadWebsite : null,
        rules: [
          {
            pattern:
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
            message: "This is not a valid website",
          },
        ],
        className: "website",
      },
      {
        label: "Team",
        formItemName: "teamClient",
        placeholder: "Select team...",
        type: "select",
        customOptions: clientTeam,
        mode: "multiple",
        onSelect: onSelect,
        onDeselect: onDeselect,
        required: true,
        allowClear: false,
      },
      proppedType === "Client" && {
        className: "client-pre-qualification-stages",
        label: "Pre-qualification Stage",
        formItemName: "preQualificationStage",
        placeholder: "Select stage...",
        type: "select",
        showSearch: true,
        options: prequalificationStages,
        onSelect: (e) => preQualificationOnChange(e),
      },
      proppedType === "Client" && {
        label: "Pre-qualification website",
        formItemName: "preQualificationWebsite",
        placeholder: "Pre-qualification website",
        type: "input",
        disabled: preQualificationDisabled,
        required: mandatoryPreQualificationStages,
        rules: [
          {
            pattern:
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
            message: "This is not a valid website",
          },
        ],
      },
      proppedType === "Client" && {
        label: "Pre-qualification date",
        disabled: preQualificationDisabled,
        formItemName: "preQualificationDate",
        type: "datepicker",
        required: mandatoryPreQualificationStages,
      },
      proppedType === "Client" && {
        label: "Pre-qualification upload",
        type: "gPicker",
        accessToken,
        uploaderId: "Pre-qualification upload",
        onPickerSuccess,
        fileNames,
        disabled: preQualificationDisabled,
        parentId,
        required: mandatoryPreQualificationStages,
      },
      // proppedType === "Client" && {
      //   label: "Active Client",
      //   formItemName: "activeClient",
      //   type: "checkbox",
      // },
      type === "Client" && {
        customComponent: AnimatedCheckBox,
        componentProps: {
          primaryStyle: checkShabbat
            ? { backgroundColor: "#5fb13b" }
            : { backgroundColor: "#0060b9" },
          label: "Shabbat Restrictions",
          initialValue: checkShabbat,
          checkBoxProps: { onChange: (e) => setCheckShabbat(e) },
        },
      },
      {
        type: "select",
        label: "Link Client",
        formItemName: "cognitoUserId",
        placeholder: "Link Client With User",
        customOptions: customerPortalUsers?.map(
          ({ email, cognitoUserId, nameOfUser }) => ({
            label: `${nameOfUser} - ${email}`,
            value: cognitoUserId,
          })
        ),
      },
      // {
      //   label: "Description",
      //   type: "textEditor",
      //   value: description,
      //   onChange: (value) => setDescription(value),
      //   style: { width: "100%" },
      // },
    ].filter(Boolean),
  },
];

export const body = ({ form, type, description = "", checkShabbat }) => {
  const formValues = form.getFieldsValue();

  const {
    billingAddCity,
    primaryAddress,
    billingAddStreet,
    billingAddState,
    billingAddPostalCode,
    secondaryAddress,
    shippingAddStreet,
    shippingAddCity,
    shippingAddState,
    shippingAddPostalCode,
    preQualificationStage,
    preQualificationWebsite,
    preQualificationDate,
    projectExecutive,
    leadSalePerson,
  } = formValues;

  return {
    ...formValues,
    borough: billingAddCity,
    accountRecordType: type,
    accountDescription: description,
    billingAddress: primaryAddress,
    billingStreet: billingAddStreet,
    billingCity: billingAddCity,
    billingState: billingAddState,
    billingZip: billingAddPostalCode,
    shippingAddress: secondaryAddress,
    shippingStreet: shippingAddStreet,
    shippingCity: shippingAddCity,
    shippingState: shippingAddState,
    shippingZip: shippingAddPostalCode,
    preQualificationStage: preQualificationStage,
    preQualificationWebsite: preQualificationWebsite,
    preQualificationDate: preQualificationDate,
    projectExecutive: projectExecutive,
    leadSalePerson: leadSalePerson,
    activeClient: true,
    jewishClient: checkShabbat,
  };
};
