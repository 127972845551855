import { v4 } from "uuid";

/**
 * @typedef InactivityType
 * @property {number} start
 * @property {number} end
 * @property {string} reason
 * @property {string} id
 */

/**
 * Function that manipulates the inactivity timeline
 * based on the newly selected status
 * @param {Object} param
 * @param {string} param.status
 * @param {boolean} param.isTerminated
 * @param {boolean} param.wasTerminated
 * @param {InactivityType[]} param.inactivityTimeline
 * @return {InactivityType[]}
 */
function calcSuspensionTimeline({
  status,
  isTerminated,
  wasTerminated,
  inactivityTimeline = [],
}) {
  const newTimeline = [...inactivityTimeline];
  let currentTimeline = newTimeline.find(({ end }) => !end);

  if (wasTerminated) {
    if (isTerminated) {
      /**
       * The user changed the inactivity reason.
       * In this case we change the reason on the current timeline
       */
      if (currentTimeline) {
        currentTimeline.reason = status;
      }
    } else {
      /**
       * The user re-activated the record, so we need
       * to end the current suspension timeline
       */
      if (currentTimeline) {
        currentTimeline.end = Date.now();
      }
    }
  } else {
    if (isTerminated) {
      /**
       * The user wants to suspend the record, so we need to add a new timeline
       */
      newTimeline.push({
        end: null,
        id: v4(),
        start: Date.now(),
        reason: status,
      });
    }
  }

  return newTimeline;
}

export default calcSuspensionTimeline;
