import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import MobileHtmlTabel from "../../../../utils/MobileHtmlTabel";
import { setDateMonth } from "../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { Button } from "antd";

function MobileSafetyInspections({ rowData, setEdit }) {
  return (
    <div className="mobile-basePage-With-header-noTabs">
      <div className="Header-mobile-div">
        <Button className="Header-modal-btn" onClick={() => setEdit(true)}>
          New Inspection
        </Button>
      </div>
      <div
        className="base-mobile-div"
        style={{ margin: 0, padding: 0, height: "78vh" }}
      >
        <MobileHtmlTabel
          header={[
            "No.",
            "Address",
            "Date",
            "Status",
            "Created At",
            "Created By",
          ]}
          tableData={rowData?.map(
            (
              { inspectionObject, createdAt, createdBy, inspectionStatus },
              index
            ) => {
              let time = inspectionObject?.inspectionTime;
              let date = inspectionObject?.inspectionDate;

              return {
                no: index + 1,
                address: inspectionObject?.inspectionAddress,
                date: setDateMonth(time, date).format("MM/DD/YYYY hh:mm a"),
                statusHeaderKey: inspectionStatus,
                updatedAt: dayjsNY(createdAt).format("MM/DD/YYYY"),
                createdBy: createdBy?.name,
              };
            }
          )}
          isHeader={true}
        />
      </div>
    </div>
  );
}

export default MobileSafetyInspections;
