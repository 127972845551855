export const scheduling = ({ tables, programFields }) => {
  return {
    typeOfWork: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Scheduling Types")
        ?.fieldOptions?.map(({ typeName }, index) => ({
          key: index,
          title: typeName,
          taskNumber: tables?.Scheduling?.length,
          taskDone: tables?.Scheduling?.filter(
            (a) => a?.typeOfWork?.toLowerCase() === typeName.toLocaleLowerCase()
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Schedule In " + typeName,
          contentsHandler: " Schedules In " + typeName,
        })),
    }), //typeofWork
  } //schaduling
}
