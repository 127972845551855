import { updateRental } from "../../../../../../../../../../../../Utils/rentalManipulation"

export const lockRowWeek = ({
  setUnchangingFakeRentals,
  unchangingFakeRentals,
  selectedElevation,
  selectedService,
  selectedItem,
  params,
}) => {
  //the id of the elevation that the week is in
  const thisElevation = selectedElevation?.elevationId
  //the id of the pli that the week is in
  const thisPli = selectedItem?.id

  // this object is the new rental object
  const newRental = updateRental({
    fakeRentals: unchangingFakeRentals,
    thisWeek: params?.data?.week,
    selectedService,
    thisElevation,
    thisPli,
    weekParams: {
      lock: !params?.value,
    },
  })

  //here we sett changed data to context
  setUnchangingFakeRentals(newRental)
}

//this function tell the user why the lock button is disabled
export const getDisabledTooltipContentWeek = ({
  unchangingFakeRentals,
  selectedElevation,
  selectedService,
  selectedItem,
  isWritable,
  params,
}) => {
  //this is the object of the week that we are trying to charge
  const data = unchangingFakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === selectedElevation?.elevationId)
    ?.items?.find(({ id }) => id === selectedItem?.id)
    ?.rentalDetails?.details?.find(({ week }) => week === params?.data?.week)

  return !!data?.charged
    ? "This week is charged you cant unlock it!!" //the case where all day are charged
    : !isWritable
    ? "Enable write mode to edit!" //the case where write mode is off
    : "" //the case where non of those above are true
}
