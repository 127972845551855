const {v4: uuidv4} = require('uuid');

/**
 * Notes for schedule days. It should not be mistaken with notes inside PLI or notes in schedule level, even thought ScheduleDayNote
 * structure is similar to notes in schedule level
 * */
export default class ScheduleDayNote {
    id: string
    createdDate: Date
    text: string = ""
    mentionedPeople: Array<string>
    type: ScheduleDayNoteType.StatusChange | ScheduleDayNoteType.General
    createdBy: string = ""

    constructor(text: string, type: ScheduleDayNoteType.StatusChange | ScheduleDayNoteType.General, mentionedPeople= []) {
        this.id = uuidv4()
        this.createdDate = new Date()
        this.mentionedPeople = mentionedPeople
        this.text = text
        this.type = type
        
    }
}


export enum ScheduleDayNoteType {
    StatusChange = "StatusChange", //when a day status changes we use this type.
    General = "General",
    Confirmed = "Confirmed",
    Canceled = "Canceled",
    Postponed = "Postponed",
    Reconfirmed = "Reconfirmed"
}

export const ScheduleDayNoteTypeLabel = (type: ScheduleDayNoteType) => {
  switch (type) {
      case ScheduleDayNoteType.General: return "General"
      case ScheduleDayNoteType.StatusChange: return  "Status Change"
      case ScheduleDayNoteType.Confirmed: return  "Confirmed"
      case ScheduleDayNoteType.Canceled: return  "Canceled"
      case ScheduleDayNoteType.Postponed: return  "Postponed"
      case ScheduleDayNoteType.Reconfirmed: return  "Reconfirmed"
      default: throw new Error("Please define a proper ScheduleDayNoteType")
  }
}