import { Checkbox, message } from "antd";
import { useRedux } from "../../../../../../../hooks/useRedux";
import "./ProjectsTable.scss";
import { WithTooltip } from "../../../../../../../../commonComponents";

const ProjectsTable = ({
  selectedClientProjects,
  onProjectSelection,
  paymentData,
  fakePayment,
  invoices,
}) => {
  const [isWritable] = useRedux("paymentIsWritable", false);

  return (
    <div className={`payment-projects-container `}>
      <div className="payment-projects-wrapper">
        <div className="payment-projects-header">
          <div>Projects</div>
        </div>
        <div className="payment-projects-body">
          {selectedClientProjects.map((project) => {
            const isDisabled = !invoices.some(
              (invoice) =>
                invoice.projectId === project.projectId && invoice.amountDue > 0
            );
            return (
              <div
                className={
                  isDisabled
                    ? "payment-project-disabled"
                    : "payment-project-item vvvvvvvv"
                }
                key={project.projectId}
              >
                <WithTooltip
                  tooltipCategory="Payments"
                  tooltipKey="selectProject"
                >
                  <Checkbox
                    className="payment-project-checkbox"
                    checked={fakePayment?.invoices?.find(
                      (invoice) => invoice.projectId === project.projectId
                    )}
                    onChange={(e) =>
                      isDisabled
                        ? message.info(
                            "This project does not have unpaid Invoices"
                          )
                        : onProjectSelection(project, e?.target?.checked)
                    }
                    disabled={!isWritable && paymentData}
                  />
                </WithTooltip>
                <span>{project.projectName}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectsTable;
