import { findRoute } from "../../../../../../../utils/AccessRights/getRouteAccessRights";
import { getAccessRights } from "src/utils";

export const accessRightsCategories = [
  { title: "Projects", viewTitle: "Projects/View" },
  { title: "Leads", viewTitle: "Leads/View" },
  { title: "Inspections", viewTitle: "Inspections/View" },
  { title: "Scheduling", viewTitle: "Scheduling/View" },
  { title: "Opportunities", viewTitle: "Opportunities/View" },
  { title: "Estimations", viewTitle: "Estimations/View" },
  { title: "Clients", viewTitle: "Accounts/View" },
  { title: "Hearings", viewTitle: "Hearings/View" },
  { title: "Claims", viewTitle: "Claims/View" },
  { title: "Contacts", viewTitle: "Contacts/View" },
  { title: "Fleets Drivers", viewTitle: "Fleets Drivers/View" },
  { title: "Fleets", viewTitle: "Fleets/View" },
  { title: "Permit Drawings", viewTitle: "Permit Drawings/View" },
  { title: "Vendors", viewTitle: "Claims/View" },
];

export const getOptions = (userConfiguration) => {
  const options = accessRightsCategories?.map(({ title, viewTitle }) => {
    let routeTitle;

    switch (title) {
      case "Clients":
        routeTitle = "Accounts";
        break;
      case "Clients":
        routeTitle = "Accounts";
        break;
      case "Fleets":
        routeTitle = "Fleet";
        break;
      default:
        routeTitle = title;
    }

    const isAuthorized =
      (getAccessRights({
        userConfiguration,
        title: routeTitle,
      })?.routeConfig?.read &&
        findRoute(userConfiguration?.routeConfig, viewTitle)?.read) ||
      false;

    return {
      label: title,
      value: title.toLowerCase(),
      isAuthorized,
    };
  });

  return options.filter((option) => option.isAuthorized);
};

export const getEndpoint = (title) => {
  let endpoint;
  switch (title.toLowerCase()) {
    case "clients":
      endpoint = "accounts";
      break;
    case "fleets drivers":
      endpoint = "drivers";
      break;
    case "fleets":
      endpoint = "fleet";
      break;
    case "permit drawings":
      endpoint = "permitDrawings";
      break;
    default:
      endpoint = title;
  }

  return endpoint;
};

export const communicationsRecords = [
  {
    endpoint: getEndpoint("projects"),
    listOfKeys: ["projectId", "projectName"],
  },
  {
    endpoint: getEndpoint("leads"),
    listOfKeys: ["leadId", "leadCompany"],
  },
  {
    endpoint: getEndpoint("inspections"),
    listOfKeys: ["inspectionId", "projectName"],
  },
  {
    endpoint: getEndpoint("scheduling"),
    listOfKeys: ["Scheduling", "scheduleName"],
  },
  {
    endpoint: getEndpoint("opportunities"),
    listOfKeys: ["opportunityId", "opportunityName"],
  },
  {
    endpoint: getEndpoint("estimations"),
    listOfKeys: ["estimationId", "jobSiteAddress"],
  },
  {
    endpoint: getEndpoint("clients"),
    listOfKeys: ["accountId", "accountName"],
  },
  {
    endpoint: getEndpoint("hearings"),
    listOfKeys: ["hearingId", "hearingName"],
  },
  {
    endpoint: getEndpoint("claims"),
    listOfKeys: ["claimId", "claimNumber"],
  },
  {
    endpoint: getEndpoint("contacts"),
    listOfKeys: ["contactId", "contactEmail"],
  },
  {
    endpoint: getEndpoint("fleets drivers"),
    listOfKeys: ["driverId", "driverName"],
  },
  {
    endpoint: getEndpoint("fleets"),
    listOfKeys: ["fleetId", "fleetName"],
  },
  {
    endpoint: getEndpoint("permit drawings"),
    listOfKeys: ["permitId", "jobSiteAddress"],
  },
  {
    endpoint: getEndpoint("vendors"),
    listOfKeys: ["vendorId", "vendorName"],
  },
];

export const getRedirectPath = (title) => {
  let path;
  switch (title.toLowerCase()) {
    case "inspections":
      path = "inspectionsView";
      break;
    case "fleets drivers":
      path = "fleet/drivers";
      break;
    case "permit drawings":
      path = "permitDrawings";
      break;
    default:
      path = title;
  }

  return path;
};
