import { fetchData } from "../../../../../components/SidebarPages/Fleet/utils";
import {
  fetchSearchRecords,
  filterRowDataByTeamConfig,
} from "../../../../../utils";

export const onDynamicSearch = async (
  tableName,
  tableKey,
  tableValue,
  gridApi,
  setRowData,
  userConfiguration
) => {
  gridApi.showLoadingOverlay();
  if (!!tableValue && tableValue !== "") {
    await fetchSearchRecords(tableName, tableKey, tableValue)
      .then((res) => {
        let filtered = filterRowDataByTeamConfig(userConfiguration, res) || [];
        setRowData(filtered);
        if (filtered.length === 0) {
          gridApi.hideOverlay();
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      })
      .finally(() => {
        // gridApi.hideOverlay();
      });
  } else {
    await fetchData(tableName)
      .then((res) => {
        let allFiltered = filterRowDataByTeamConfig(
          userConfiguration,
          res?.[tableName]
        );
        setRowData(allFiltered);
        if (allFiltered.length === 0) {
          gridApi.hideOverlay();
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
        // remove grid api filter
        gridApi.setQuickFilter("");
        gridApi.setFilterModel(null);
      })
      .finally(() => {
        // gridApi.hideOverlay();
      });
  }
};

export const getSearchConfig = async (
  title,
  value,
  gridApi,
  setRowData,
  userConfiguration
) => {
  const newValue = value; //.toLowerCase();

  if (title === "Projects") {
    return await onDynamicSearch(
      "projects",
      //  "searchableProjectAddress"
      "projectAddress",
      newValue,
      gridApi,
      setRowData,
      userConfiguration
    );
  } else if (title === "Estimations") {
    return await onDynamicSearch(
      "estimations",
      //  "searchablejobSiteAddress"
      "jobSiteAddress",
      newValue,
      gridApi,
      setRowData,
      userConfiguration
    );
  } else if (title === "Scheduling") {
    return await onDynamicSearch(
      "scheduling",
      // "searchableScheduleAddress"
      "scheduleAddress",
      newValue,
      gridApi,
      setRowData,
      userConfiguration
    );
  } else if (title === "Opportunities") {
    return await onDynamicSearch(
      "opportunities",
      // "searchableOpportunityAddress"
      "opportunityAddress",
      newValue,
      gridApi,
      setRowData,
      userConfiguration
    );
  } else if (title === "Documentations") {
    return await onDynamicSearch(
      "documentation",
      "docType",
      newValue,
      gridApi,
      setRowData,
      userConfiguration
    );
  }
};
