import {
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  MutableRefObject,
} from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { message, Modal } from "antd";
import { GridApi } from "ag-grid-enterprise";
import { RowDataUpdatedEvent } from "ag-grid-community";

import columnDefs from "./columnDefs";
import { ProjectMatchContext } from "../../data";
import { fetchAllData } from "../../../../../../../../utils";
import { MondayButton } from "src/components/commonComponents";
import InputComponent from "../../../../../components/InputComponent/InputComponent";
import { GridTemplateCard } from "../../../../../../../pages/Settings/settingsComponents/FleetLiveSheets/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import {
  InfoIconBlue,
  SearchIcon,
} from "../../../../../../../Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

import "./ManualProjectMatchModal.scss";

function ManualProjectMatchModal({ open, onCancel }) {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { onManualMatchConfirm, offProjectData, matchedData, mapProject } =
    useContext(ProjectMatchContext);

  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [gridApi, setGridApi] = useState(/** @type {GridApi} */ (undefined));
  const [selectedAddress, setSelectedAddress] = useState(
    /** @type {string} */ ("")
  );

  /** @type {MutableRefObject<HTMLDivElement>} */
  const actionsRef = useRef(null);
  const abortRef = useRef(new AbortController());

  useEffect(() => {
    //#region CLEANUP
    () => {
      abortRef.current.abort();
    };
  }, []);

  const rowData = useMemo(() => {
    //#region ROW DATA
    const dataByAddress = matchedData?.[selectedAddress] || {};
    const projectsByMatch = [];
    for (const projectId in dataByAddress) {
      projectsByMatch.push({
        ...dataByAddress[projectId]["item"]["originalItem"],
      });
    }

    const filteredProjectsByFetch = searchResults.flatMap((e) => {
      if (!(e.id in dataByAddress)) {
        return e.originalItem;
      }

      return [];
    });

    return projectsByMatch.concat(filteredProjectsByFetch);
  }, [selectedAddress, searchResults, matchedData]);

  const onRowDataUpdated = useCallback(
    /**
     * @param {RowDataUpdatedEvent} event
     */
    (event) => {
      //#region ROW DATA UPDATE
      event.api.sizeColumnsToFit();
      const dataByAddress = matchedData?.[selectedAddress] || {};
      if (!selectedAddress || !Object.keys(dataByAddress).length) {
        return;
      }

      event.api.forEachNode((node) => {
        if (node?.data?.projectId in dataByAddress) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    },
    [rowData, matchedData, selectedAddress]
  );

  const getRowId = useCallback((event) => {
    //#region ROW ID
    return event?.data?.projectId;
  }, []);

  const toTitle = useCallback((str) => {
    //#region TO TITLE
    if (!str || typeof str !== "string") {
      return "";
    }

    return str
      .replace(/(^[a-z])|(\s[a-z])/g, function (m, m2) {
        return (m || m2).toUpperCase();
      })
      .replace(/[a-z][A-Z]/g, function (m) {
        return m[0] + " " + m[1];
      });
  }, []);

  const onSearch = useCallback(
    async (event) => {
      //#region ON SEARCH
      const value = event?.target?.value;
      if (!value) {
        setSearchResults([]);
        return;
      }

      setLoading(true);
      abortRef.current.abort();

      const projects = await fetchAllData({
        endpoint: "projects",
        resultId: "projectId",
        resultPosition: "projects",
        otherStringParams: {
          filters: JSON.stringify([
            {
              operator: "OR",
              conditions: [
                {
                  operator: "OR",
                  column: "searchableProjectAddress",
                  formula: "contains",
                  value: value.toLowerCase(),
                },
                {
                  operator: "OR",
                  column: "projectAddress",
                  formula: "contains",
                  value: toTitle(value),
                },
              ],
            },
          ]),
          keysToInclude: JSON.stringify([
            "projectName",
            "projectId",
            "accountName",
            "projectLatitude",
            "projectLongitude",
            "geoFenceInfo",
            "accountId",
            "projectExecutive",
          ]),
          signal: abortRef.current.signal,
        },
      })
        .catch((err) => {
          console.log("Error getting projects: ", err);
          message.error({
            content: "Something went wrong while searching projects",
            key: "projectSearch",
          });

          return [];
        })
        .finally(() => {
          setLoading(false);
        });

      setSearchResults(projects.map(mapProject));
    },
    [toTitle]
  );

  /**
   * @param {string} address
   */
  function onChangeSelectedAddress(address) {
    //#region CHANGE SELECTED ADDRESS
    setSearchResults([]);
    setSelectedAddress(address);

    setTimeout(() => {
      if (actionsRef.current) {
        const clearElement =
          actionsRef.current.querySelector(".ant-input-suffix");
        if (clearElement) {
          clearElement.firstChild.click();
        }
      }
    }, 10);
  }

  async function onConfirm() {
    //#region ON CONFIRM
    if (!gridApi) {
      return;
    }

    const selectedData = {};
    gridApi.forEachNode((node) => {
      if (!node.isSelected()) {
        return;
      }

      const { projectId } = node.data;
      if (matchedData?.[selectedAddress]?.[projectId]) {
        selectedData[projectId] = {
          ...matchedData?.[selectedAddress]?.[projectId],
          forceMatch: true,
        };
      } else {
        const tmp = searchResults.find(({ id }) => id === projectId);
        if (tmp) {
          selectedData[projectId] = {
            item: { ...tmp, forceMatch: true },
            subItems: null,
          };
        }
      }
    });

    onManualMatchConfirm(selectedData, selectedAddress);
    onCancel();
  }

  //#region JSX
  return (
    <Modal
      {...{
        open,
        onCancel,
        title: "Manual Project Matching",
        centered: true,
        className: `manual-project-match ${
          isDarkMode ? "manual-project-match-dark" : ""
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
            }}
            key="cancel"
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onConfirm,
              disabled: !selectedAddress,
            }}
            key="confirm"
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <div className="match-info">
        <InfoIconBlue />
        <span>
          Here you can manually match the projects from the system with the
          dispatch route addresses
        </span>
      </div>
      <div className="grid-actions" ref={actionsRef}>
        <InputComponent
          {...{
            placeholder: "Dispatch Addresses...",
            label: "Dispatch Addresses",
            type: "select",
            noFormItem: true,
            onSelect: onChangeSelectedAddress,
            onClear() {
              onChangeSelectedAddress("");
            },
            customOptions: Object.keys(offProjectData).map((e) => ({
              label: e,
              value: e,
            })),
          }}
        />
        <InputComponent
          {...{
            prefix: <SearchIcon />,
            placeholder: "Search Projects...",
            label: "Project Search",
            noFormItem: true,
            inputAllowClear: true,
            onChange: _.debounce(onSearch, 500),
          }}
        />
      </div>
      <GridTemplateCard
        {...{
          rowData,
          loading,
          columnDefs,
          hideHeader: true,
          hideFilters: true,
          gridProps: {
            getRowId,
            pagination: false,
            onGridReady(event) {
              setGridApi(event.api);
            },
            rowSelection: "multiple",
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: false,
            onRowDataUpdated: onRowDataUpdated,
            rowGroupPanelShow: "onlyWhenGrouping",
          },
        }}
      />
    </Modal>
  );
}

export default ManualProjectMatchModal;
