import { Modal } from "antd";
import { MondayButton } from "../../../../commonComponents/index";
import { XIcon } from "../../../../SidebarPages/Communication/assets/index";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src/index";
import "./DeleteModal.scss";

const DeleteModal = ({ open, onCancel, onSubmit, notificationLength }) => {
  return (
    <Modal
      open={open}
      className="deleteModal"
      title="Delete Confirmation"
      onCancel={() => onCancel()}
      footer={
        <>
          <MondayButton
            className="close"
            onClick={onCancel}
            Icon={<XIcon height={13} width={13} />}
          >
            Cancel
          </MondayButton>

          <MondayButton
            className="submit"
            onClick={async () => {
              onCancel();
              await onSubmit();
            }}
            Icon={<TickIcon height={13} width={13} />}
          >
            Confirm
          </MondayButton>
        </>
      }
    >
      {`Are you sure you want to delete 
                ${notificationLength} 
                ${notificationLength > 1 ? "notifications" : "notification"}
            ?`}
    </Modal>
  );
};

export default DeleteModal;
