import { quickStatistics_Schedule_and_Project } from "../../../../Scheduling/helpers/quickStatistics";

export const scheduling = (updatedDynamicStates, IdHandler, programFields) => {
  return {
    typeOfWork: () => ({
      title: "Type Of Work",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Scheduling",
        field: "typeOfWork",
        secondaryField: "scheduleAddress",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.scheduleAddress,
            value: a?.typeOfWork,
            color: "#ECDE7D",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //typeofWork

    projectExecutive: () => ({
      title: "Project Executive",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Scheduling",
        field: "projectExecutive",
        secondaryField: "scheduleAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.scheduleAddress,
            value: a?.projectExecutive,
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.scheduleId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    scheduleAddress: () => ({
      title: "Schedule Address",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Scheduling",
        field: "scheduleAddress",
        secondaryField: "scheduleStatus",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((status) => {
          return {
            label: status?.scheduleAddress,
            value: status?.scheduleStatus,
            color:
              programFields
                ?.find((e) => e?.fieldName === "Statuses Of Categories")
                ?.fieldOptions?.Scheduling?.find(
                  (el) =>
                    el?.statusName?.toLowerCase() ===
                    status?.scheduleStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: status?.createdAt,
            id: status?.scheduleId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    scheduleTotalProgressPercentage: () => ({
      title: "Schedule Total Progress Percentage",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Scheduling",
        field: "scheduleTotalProgressPercentage",
        secondaryField: "scheduleAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.scheduleAddress,
            value:
              quickStatistics_Schedule_and_Project({
                project: [],
                toBeScheduled: a?.toBeScheduled,
                schedules: [],
                typeOfWork: a?.typeOfWork,
              })?.scheduleTotalProgressPercentage + "%",
            color: "#ECDE7D",
            date: a?.createdAt,
            id: a?.scheduleId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
  }; //schaduling
};
