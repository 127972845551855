import axios from "axios";
import { getCoordinatesAndZip } from "../../../../SidebarPages/Fleet/utils";

export const getTaxRate = async (address) => {
  const locInfo = await getCoordinatesAndZip(address);

  const result =
    !!locInfo.zipCode &&
    (await axios.post(
      "https://leadmanager-express-backend.ue.r.appspot.com/api/salesTax",
      {
        postalCode: locInfo.zipCode,
      }
    ));

  const taxRate = parseFloat(result.data?.results[0]?.taxSales) * 100;

  return taxRate;
};
