/**
 * @typedef FormatOptions
 * @property {string} [locale="en-US"]
 * @property {number} [fixed=2]
 * @property {string} [prefix=""]
 * @property {string} [suffix=""]
 * @property {number} [fallback=0]
 *
 * Function that parses a number
 * @param {number|string} number
 * @param {Intl.NumberFormatOptions & FormatOptions} [options]
 * @returns {string}
 */
export function formatNumber(number, options = {}) {
  const {
    locale = "en-US",
    fixed = 2,
    /**
     * custom text to put in the beginning and end of the result
     */
    suffix = "",
    prefix = "",

    /**
     * units are defined in the unicode source code
     */
    unit = undefined,
    style = undefined,
    currency = undefined,

    /**
     * value to operate on if number isNaN
     * if it doesn't represent a number, it will
     * be returned instead of the formatted number
     */
    fallback = 0,
    ...rest
  } = options;

  let n = Number(number);

  if (isNaN(n)) {
    if (isNaN(Number(fallback))) {
      return fallback || "";
    }
    n = Number(fallback);
  }

  if (!isNaN(fixed)) {
    n = n.toFixed(fixed);
  }

  return `${prefix}${prefix ? " " : ""}${new Intl.NumberFormat(locale, {
    style: unit ? "unit" : currency ? "currency" : style || "decimal",
    unit,
    currency,
    ...rest,
  }).format(n)}${suffix ? " " : ""}${suffix}`;
}
