export const FILTER_INIT = {
  incompleteHighlighted: true,
  incorrectHighlighted: false,
  hrHighlighted: true,
  missingLunchHighlighted: false,
  overheadEntriesHighlighted: false,
};

function FILTER_REDUCER(state, action) {
  switch (action.type) {
    case "INCOMPLETE":
      return {
        ...state,
        incompleteHighlighted: !state.incompleteHighlighted,
      };
    case "INCORRECT":
      return {
        ...state,
        incorrectHighlighted: !state.incorrectHighlighted,
      };
    case "HR":
      return {
        ...state,
        hrHighlighted: !state.hrHighlighted,
      };
    case "MISSINGLUNCH":
      return {
        ...state,
        missingLunchHighlighted: !state.missingLunchHighlighted,
      };
    case "OVERHEAD":
      return {
        ...state,
        overheadEntriesHighlighted: !state.overheadEntriesHighlighted,
      };
  }
}

export default FILTER_REDUCER;
