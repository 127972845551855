import { AddIcon } from "../../../../../../../../../../Header/components/GoogleEvents/assets";
import { TrashIcon } from "../../../../../../../../../Communication/assets";
import "./Actions.scss";
import { useStateContext } from "../../../../../Context/Context";
import { message } from "antd";
import { Tooltip, Popconfirm } from "antd";
import { MondayButton } from "../../../../../../../../../../commonComponents";

const Actions = ({
  rowId,
  appendRowInGrid,
  data,
  removeRowInGrid,
  makeRowTaxable,
  removeRetainage,
}) => {
  const { isWritable } = useStateContext();

  const style = !isWritable
    ? {
        opacity: 0.4,
        cursor: "not-allowed",
      }
    : {};

  const toggleTax = () => {
    makeRowTaxable(rowId, data);
  };

  const removeRet = () => {
    // data.retainage = 0;
    // data.retainagePercentage = 0;
    return removeRetainage(rowId, data);
  };

  return (
    <div className="actionButtonsContainerSov">
      <Tooltip title="Add another row below!">
        <button
          className="addRowBelowThisButton"
          onClick={() =>
            isWritable
              ? appendRowInGrid(rowId)
              : message.error("Please enable Edit Mode!")
          }
          style={style}
        >
          <AddIcon />
        </button>
      </Tooltip>
      <Popconfirm
        title="Are you absolutely sure?"
        onConfirm={() =>
          isWritable
            ? removeRowInGrid(rowId)
            : message.error("Please enable Edit Mode!")
        }
        // onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Remove this row">
          <button
            style={style}
            className="removeThisRowButton"
            // onClick={() =>
            //   isWritable
            //     ? removeRowInGrid(rowId)
            //     : message.error("Please enable Edit Mode!")
            // }
          >
            <TrashIcon />
          </button>
        </Tooltip>{" "}
      </Popconfirm>
      <Popconfirm
        title="Are you absolutely sure?"
        onConfirm={toggleTax}
        // onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="TAX This Event">
          <span
            style={{
              background: data?.custom_tax_amount > 0 ? "green" : "red",
              color: "white",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            // onClick={() => toggleTax()}
          >
            TAX
          </span>
        </Tooltip>
      </Popconfirm>{" "}
      {/* <Popconfirm
        title="Are you absolutely sure?"
        onConfirm={toggleTax}
        // onCancel={cancel}
        okText="Yes"
        cancelText="No"
      > */}
      {/* <Tooltip title="Remove Retainage from this event"> */}
      <MondayButton
        className="mondayButtonBlue"
        hasIcon={false}
        onClick={removeRet}
      >
        Remove Ret
      </MondayButton>
      {/* </Tooltip> */}
      {/* </Popconfirm> */}
    </div>
  );
};

export default Actions;
