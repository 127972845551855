import { formatCurrency } from "../../../../../utils/formatCurrency";
import { checkIfServiceIsHoist } from "../../../models/Service";

export const otherScopeServiceTotalPriceMapper = (paramService) => {
  let temp = 0;
  let taxRate = paramService?.isTaxable ? paramService?.taxRate || 0 : 0;
  temp =
    [
      ...(paramService?.serviceOptions?.[0]?.flatMap?.(({ items }) => items) ||
        []),
    ]
      ?.map((el) => {
        if (el?.price || el?.Price) {
          return Number(el?.price || el?.Price || 0);
        }
        return 0;
      })
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0)
      ?.toFixed?.(2) || 0;
  const taxAmount = temp * taxRate || 0;
  temp = +temp + +taxAmount;
  return temp;
};

export const otherScopeServiceTaxMapper = (paramService) => {
  let temp = 0;
  let taxRate = paramService?.isTaxable ? paramService?.taxRate || 0 : 0;
  temp =
    [
      ...(paramService?.serviceOptions?.[0]?.flatMap?.(({ items }) => items) ||
        []),
    ]
      ?.map((el) => {
        if (el?.price || el?.Price) {
          return Number(el?.price || el?.Price || 0);
        }
        return 0;
      })
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0)
      ?.toFixed?.(2) || 0;
  const taxAmount = temp * taxRate || 0;

  return taxAmount;
};

export const servicePriceTakeOffCalculator = (service, formatted = true) => {
  const totalPriceMapper = (el) =>
    !isNaN(+el?.totalPrice) ? +el?.totalPrice : 0;
  const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;
  console.log("service", service);
  let tempPrice = 0;
  if (service?.isScope === true) {
    tempPrice = otherScopeServiceTotalPriceMapper(service);
    console.log("tempPrice1", tempPrice);
  } else if (!checkIfServiceIsHoist(service)) {
    tempPrice =
      [
        ...service?.serviceOptions?.[0]?.flatMap?.(
          ({ items }) =>
            items
              ?.filter?.(({ approved }) => !!approved)
              ?.map?.(totalPriceMapper) || [0]
        ),
        ...(service?.serviceAddons?.map?.(totalPriceMapper) || [0]),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0)
        ?.toFixed?.(2) || 0;
    console.log("tempPrice2", tempPrice);
  } else if (checkIfServiceIsHoist(service)) {
    tempPrice =
      [
        ...service?.serviceOptions?.[0]?.map(hoistTotalPriceMapper),
        ...service?.serviceAddons.map(totalPriceMapper),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0)
        ?.toFixed?.(2) || 0;
    console.log("tempPrice3", tempPrice);
  }

  return formatted ? formatCurrency(tempPrice || 0) : Number(tempPrice || 0);
};

export const takeOffEstimationPrice = (services = []) => {
  const temp =
    services
      ?.map((e) => servicePriceTakeOffCalculator(e, false))
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0)
      ?.toFixed?.(2) || 0;
  console.log("temp est price", temp);
  return formatCurrency(temp);
};
