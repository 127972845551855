import React, { useState } from "react";
import "./JumpsConfigModal.scss";
import { Button, message } from "antd";
import { MondayButton } from "../../../../../../../commonComponents";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { preventDuplicateStrings } from "../../../../SchedulingFirstPage/helperData";
const PopupToAddFloorsToJump = ({
  set_jumps,
  jumps,
  jump,
  availableFloors,
  comesFromProgress,
  scheduleDays,
}) => {
  const daysForThisSchedule =
    scheduleDays
      ?.filter((el) => jump?.days?.includes(el?.id))
      ?.map((el) => el?.day) || [];

  const [selectedFloors, setSelectedFloors] = useState(
    !comesFromProgress ? [] : daysForThisSchedule
  );

  const updateNewFloors = (floor) => {
    if (selectedFloors?.includes(floor)) {
      setSelectedFloors((prev) => {
        return prev?.filter((f) => f !== floor);
      });
    } else {
      setSelectedFloors((prev) => {
        return [...prev, floor];
      });
    }
  };

  const addFunction = () => {
    if (!comesFromProgress) {
      const newJumps = jumps?.map((j) => {
        if (j?.name === jump?.name) {
          const updatedFloors = selectedFloors?.map((floor) => ({
            floor,
          }));
          return {
            ...j,
            floors: [...j?.floors, ...updatedFloors],
          };
        }
        return j;
      });
      set_jumps(newJumps);
    } else {
      const daysToAdd = scheduleDays
        ?.filter((day) => selectedFloors?.includes(day?.day))
        ?.map((el) => el?.id);
      const newJump = { ...jump };
      const previousProgressByDays =
        newJump?.progressByDay?.filter(
          (el) => !scheduleDays?.some((day) => day?.id === el?.day)
        ) || [];
      const previousDays =
        newJump?.days?.filter(
          (el) => !scheduleDays?.some((day) => day?.id === el)
        ) || [];

      newJump.days = preventDuplicateStrings([
        ...(previousDays || []),
        ...daysToAdd,
      ]);
      (newJump.progressByDay = [
        ...previousProgressByDays?.flat(),
        ...(newJump?.progressByDay || []),
        ...(daysToAdd
          ?.filter(
            (day) => !newJump?.progressByDay?.some((q) => q?.day === day)
          )
          ?.map((dayId) => ({
            day: dayId,
            progress: newJump?.floors?.map((el) => ({
              ...el,
              floor: el?.floor,
              progress: 0,
            })),
          })) || []),
      ]),
        set_jumps(newJump);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
        }}
      >
        {(comesFromProgress ? scheduleDays : availableFloors)?.map((floor) => {
          const display = comesFromProgress ? floor?.day : floor?.floor;
          const displayColors = selectedFloors?.includes(display);
          return (
            <Button
              onClick={() => updateNewFloors(display)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 32,
                height: 32,
                borderRadius: 5,
                border: "none",
                backgroundColor: displayColors ? "#D5F1C9" : "#F2F2F2",
              }}
            >
              {comesFromProgress ? "D" + display : display}
            </Button>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "5px",
          marginTop: 10,
        }}
      >
        <MondayButton
          disabled={!!comesFromProgress ? false : !selectedFloors?.length}
          onClick={() => {
            addFunction();
          }}
          {...{ Icon: <TickIcon /> }}
        >
          {`Add selected ${comesFromProgress ? "days" : "floors"}`}
        </MondayButton>
      </div>
    </div>
  );
};

export default PopupToAddFloorsToJump;
