import React from "react";
import {Checkbox} from 'antd';

export class CheckboxForOther extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.value,
    }
  }

  handleCheck = (e) => {
    this.setState({checked: e.target.checked});

    this.props.node.setDataValue(this.props.column.colId, e.target.checked);
    this.props.setCompletedAddon(this.props.node.data.id, e.target.checked)
  }

  render() {
    return (
      <Checkbox
        onChange = {this.handleCheck}
        checked={this.props.value}
      />
    )
  }
}