import { getExcelColumnKeys } from "../../../../../../../../../utils/getExcelColumnKeys";

export const exportGridToExcel = (tableColumns, gridApi) => {
  gridApi.exportDataAsExcel({
    columnKeys: getExcelColumnKeys(gridApi, tableColumns),
    processCellCallback(params) {
      const value = params?.value;

      return value === undefined ? "" : `${value}`;
    },
  });
};
