function fixedAndFailedRecords(fixed, failed, fromDoc) {
  if (fromDoc) {
    return {
      fixedRecords: [
        ...fixed
          .filter((record) => record?.docId)
          .map((record) => ({
            ...record,
            folderId: record?.folderId || record?.createdFolderId,
            statusAfterUpdate:
              !record?.folderId && record?.createdFolderId
                ? "Fixed"
                : record?.folderId
                ? "No Change"
                : "Failed",
            googleDriveUploads:
              record?.googleDriveUploads?.map((upload) => ({
                ...upload,
                statusAfterUpdate:
                  record?.failedUploads?.length > 0 &&
                  record?.failedUploads?.find(
                    (failedUpload) => failedUpload?.id === upload?.id
                  )
                    ? "Failed"
                    : "Fixed",
              })) || [],
          })),
        ...failed,
      ],

      failedRecords: [
        ...failed,
        ...fixed
          .filter((record) => record?.failedUploads.length > 0)
          .map((record) => ({
            ...record,
            googleDriveUploads: record.failedUploads,
            folderId: record.folderId || record.createdFolderId,
          })),
      ],
    };
  } else {
    return {
      fixedRecords: fixed,
      failedRecords: failed,
    };
  }
}

export default fixedAndFailedRecords;
