import AgGrid from "../../AgGrid/AgGrid";
import "./base-page-ag-grid.css";

const BasePageAgGrid = ({ gridHeight, onFilterGrid, isDarkMode, ...rest }) => {
  return (
    <div
      className={`mainGridContainer ${
        isDarkMode
          ? "mainGridContainerDark dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
    >
      <AgGrid {...rest} />
    </div>
  );
};

export default BasePageAgGrid;
