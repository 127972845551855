export class PlywoodEnclosurePLI {
  id;
  approved = false;
  serviceId = 14;

  length;
  width;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
export class AlternatePlywoodEnclosurePLI {
  id;
  approved = false;
  serviceId = 14;

  length;
  width;

  addons = [];

  description;
  note;

  ppu;
  rent;
  price;

  lock = false;

  isAlternate = true;

  constructor(id, taxRate) {
    this.id = id;
    this.taxRate = taxRate;
  }
}
