import { Spin } from "antd";
import moment from "moment";
import dayjs from "dayjs";

import { transformServices } from "./transformServices";

export const fieldsJSON = ({
  billData,
  isWritable,
  onTermChange,
  categories,
  terms,
  vendors,
  vendor,
  onProjectSearch,
  onProjectSelect,
  isProjectLoading,
  projects,
  selectedProject,
  onServiceSelection,
  project,
  services,
  onAmountChange,
  onVendorSelection,
  form,
}) => {
  const vendorOptions = vendors.reduce((acc, vendor) => {
    const groupName = vendor.isAccount ? "SubContractors" : "Vendors";
    const groupIndex = acc.findIndex((group) => group.label === groupName);

    const vendorData = {
      label: vendor.vendorName,
      value: vendor.vendorName,
      ...vendor,
    };

    if (groupIndex !== -1) {
      acc[groupIndex].options.push(vendorData);
    } else {
      acc.push({
        label: groupName,
        options: [vendorData],
      });
    }

    return acc;
  }, []);

  const categoriesOptions = categories.map((category) => ({
    ...category,
    label: category.name,
    value: category.name,
  }));
  const termsOptions = terms.map((term) => ({
    ...term,
    label: term.name,
    value: term.name,
  }));
  const projectOptions = projects.map((project) => ({
    label: project.projectName,
    value: project.projectName,
  }));

  const projectServices = transformServices(
    selectedProject?.services,
    services
  );

  return [
    {
      label: "Vendor Name",
      formItemName: "vendorName",
      placeholder: "Vendor Name",
      type: "select",
      customOptions: vendorOptions,
      required: true,
      disabled: (!!billData || !!vendor?.isFromVendor) && !isWritable,
      onSelect: onVendorSelection,
    },
    {
      label: "Terms",
      formItemName: "billTerms",
      placeholder: "Terms",
      type: "select",
      customOptions: termsOptions,
      disabled: !!billData && !isWritable,
      onChange: onTermChange,
    },
    {
      label: "Category",
      formItemName: "billCategory",
      placeholder: "Category",
      type: "select",
      customOptions: categoriesOptions,
      required: true,
      disabled: !!billData && !isWritable,
    },
    {
      label: "Project",
      formItemName: "projectName",
      placeholder: "Select Project...",
      type: "autocomplete",
      disabled: (!!billData || !!project) && !isWritable,
      customOptions: projectOptions,
      onSearch: onProjectSearch,
      onSelect: onProjectSelect,
      notFoundContent: isProjectLoading ? (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px",
          }}
          size="small"
        />
      ) : null,
    },
    {
      form,
      label: "Bill Date",
      validTime: "all",
      formItemName: "billDate",
      type: "dateinput",
      className: "date-input",
      required: true,
      disabled: !!billData && !isWritable,
    },
    {
      form,
      label: "Due Date",
      validTime: "all",
      formItemName: "billDueDate",
      type: "dateinput",
      className: "date-input",
      required: true,
      disabled: !!billData && !isWritable,
    },
    {
      label: "QuickBooks No",
      formItemName: "quickBooksNumber",
      placeholder: "QuickBooks No",
      type: "input",
      disabled: !!billData && !isWritable,
    },
    {
      label: "Tax Rate",
      formItemName: "vendorTaxRate",
      placeholder: "Tax Rate",
      type: "input",
      defaultValue: vendor?.vendorTaxRate * 100 || 0,
      disabled: true,
    },
    {
      label: "Services",
      formItemName: "billServices",
      placeholder: "Select Services",
      type: "select",
      mode: "multiple",
      required: true,
      customOptions: projectServices,
      disabled: !!billData && !isWritable,
      onSelect: (value, data) => onServiceSelection(value, data, true),
      onDeselect: (value, data) => onServiceSelection(value, data, false),
    },
    {
      label: "Amount",
      formItemName: "billAmount",
      placeholder: "Amount",
      type: "number",
      disabled: !!billData && !isWritable,
      controls: false,
      required: true,
      addonBefore: "$",
      onBlur: onAmountChange,
      onPressEnter: onAmountChange,
    },
    {
      label: "Memo",
      formItemName: "billMemo",
      placeholder: "Memo",
      type: "textarea",
      disabled: !!billData && !isWritable,
    },
  ];
};

export const bodyObject = ({ form }) => ({
  vendorName: form.getFieldValue("vendorName"),
  billTerms: form.getFieldValue("billTerms"),
  billCategory: form.getFieldValue("billCategory"),
  billDate: dayjs
    .tz(form.getFieldValue("billDate"), "MM/DD/YYYY", "America/New_York")
    .valueOf(),
  billDueDate: dayjs
    .tz(form.getFieldValue("billDueDate"), "MM/DD/YYYY", "America/New_York")
    .valueOf(),
  quickBooksNumber: form.getFieldValue("quickBooksNumber") || "",
  billMemo: form.getFieldValue("billMemo") || "",
  projectName: form.getFieldValue("projectName") || "",
});

export const bodyCreditObject = ({ form }) => ({
  creditReferenceNumber: form.getFieldValue("creditReferenceNumber") || "",
  creditMemo: form.getFieldValue("creditMemo") || "",
  creditDate: moment(form.getFieldValue("creditDate"))
    .tz("America/New_York")
    .valueOf(),
  creditAmount: form.getFieldValue("creditAmount") || 0,
});

export const fieldsCreditJSON = ({
  form,
  billData,
  isWritable,
  vendors,
  rowData,
  isBill,
}) => {
  const vendorOptions = vendors.reduce((acc, vendor) => {
    const groupName = vendor.isAccount ? "SubContractors" : "Vendors";
    const groupIndex = acc.findIndex((group) => group.label === groupName);

    const vendorData = {
      label: vendor.vendorName,
      value: vendor.vendorName,
      ...vendor,
    };

    if (groupIndex !== -1) {
      acc[groupIndex].options.push(vendorData);
    } else {
      acc.push({
        label: groupName,
        options: [vendorData],
      });
    }

    return acc;
  }, []);

  const billAmount = rowData.reduce((acc, item) => acc + item.total, 0);

  form.setFieldValue("creditAmount", billData?.creditAmount || billAmount);

  return [
    {
      label: "Vendor Name",
      formItemName: "vendorName",
      placeholder: "Vendor Name",
      type: "select",
      required: !isBill,
      customOptions: vendorOptions,
      disabled: true,
    },
    {
      label: "Credit Date",
      formItemName: "creditDate",
      placeholder: "MM/DD/YYYY",
      type: "datepicker-moment",
      required: !isBill,
      disabled: !!billData && !isWritable,
    },
    {
      label: "Credit Amount",
      formItemName: "creditAmount",
      placeholder: "0.00",
      controls: false,
      type: "number",
      style: { width: "100%" },
      required: !isBill,
      disabled: !!billData && !isWritable,
    },
    {
      label: "Ref No",
      formItemName: "creditReferenceNumber",
      placeholder: "Ref No",
      type: "input",
      disabled: !!billData && !isWritable,
    },
    {
      label: "Memo",
      formItemName: "creditMemo",
      placeholder: "Memo",
      type: "textarea",
      disabled: !!billData && !isWritable,
    },
  ];
};
