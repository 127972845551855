import dayjs from "dayjs";
import { API } from "aws-amplify";
import { message } from "antd";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils/windowMessages";
import { sendNotification } from "../../../../utils/sendNotification";
import { filterTables } from "../../../../utils/filterTables";
import { formatCurrency } from "../../utils/formatCurrency";
import { checkIfServiceIsHoist } from "../../Estimations/DataEntryGrid/models/Service";
import { getServiceLegacyPrice } from "../../Projects/Accounting/calculations/servicePrices";
import { calculatePriceForIncludedRentals } from "../../Projects/Accounting/Charges/components/NewChargeItem/utils";
import { exportToExcelData } from "../../Projects/Accounting/Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/ExportToExcel/exportToExcelFunctions";
import { rentalDataExtractor } from "../../Projects/Accounting/Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/IncludeRentals/includeRentalsFunctions";
import { deleteAllRelatedToDos } from "../../ToDos/forms/helpers";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";

const currencyKeywords = [
  "tax",
  "Tax",
  "value",
  "Value",
  "amount",
  "Amount",
  "subtotal",
  "Subtotal",
  "price",
  "Price",
  "rate",
  "Rate",
  "retainage",
  "Retainage",
  "Payment",
  "payment",
  "balance",
  "Balance",
  "rent",
  "Rent",
  "Cost",
  "cost",
];

const TIME_FORMAT = "hh:mm a";
const DATE_FORMAT = "MM/DD/YYYY";

/**
 * Parses strings into phone numbers
 * @param {String} value The phone number
 * @returns a parsed phone number
 */
export const parsePhone = (value) => {
  if (!value) return value;
  //if the value is not a valid phone number
  const phoneValidator =
    /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  if (!phoneValidator.test(value)) return value;

  //we remove all the characters that are not digits
  let valToBeParsed = value.toString().replace(/\D+/g, "");
  //if the first digit is 1, that means that the phone number
  //was input in the format "+1..." which is redundant, we remove the prefix
  //there are no valid phone numbers that have the second digit "1"
  if (valToBeParsed?.charAt(0) === "1") {
    valToBeParsed = valToBeParsed.slice(1);
  }

  return valToBeParsed.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

/**
 * This function compares 2 objects and returns the difference
 *
 * @param {Object} curr Current changed object
 * @param {Object} prev Object before changes
 * @returns {{curr: object, prev: object}}
 */
export function getChangedData(curr, prev) {
  if (typeof prev === "object" && typeof curr === "object") {
    const toReturn = {
      curr: {},
      prev: {},
    };
    const currKeys = Object.keys(curr);
    const prevKeys = Object.keys(prev);
    const keys = Array.from(new Set([...currKeys, ...prevKeys]));

    keys.forEach((key) => {
      try {
        if (key in prev && key in curr) {
          const result = getChangedData(curr[key], prev[key]);
          if (result !== false) {
            toReturn.curr[key] = result.curr;
            toReturn.prev[key] = result.prev;
          }
        } else {
          toReturn.curr[key] =
            key in curr
              ? getChangedData(curr[key], "'Does not exist'").curr
              : "'Does not exist'";
          toReturn.prev[key] =
            key in prev
              ? getChangedData("'Does not exist'", prev[key]).prev
              : "'Does not exist'";
        }
      } catch (err) {}
    });
    return Object.keys(toReturn.prev).length === 0 &&
      Object.keys(toReturn.curr).length === 0
      ? false
      : toReturn;
  } else if (typeof curr === "object") {
    return getChangedData(curr, {});
  } else if (typeof prev === "object") {
    return getChangedData({}, prev);
  } else {
    if (prev !== curr)
      return {
        curr,
        prev,
      };
    else return false;
  }
}

export function calculateTotalPriceForService(service, selectedCategory) {
  let amount = 0;
  let taxAmount = 0;
  if (
    selectedCategory === "requisitions" ||
    selectedCategory === "applications"
  ) {
    const calcPaymentDue = ({ service }) =>
      service?.amounts?.reduce((acc, { paymentDue }) => acc + paymentDue, 0);
    if (checkIfServiceIsHoist(service)) {
      service?.serviceOptions?.flat(1)?.forEach((service) => {
        amount += calcPaymentDue({ service });
      });
    } else amount += calcPaymentDue({ service });
  } else if (selectedCategory === "estimations") {
    const additionalRental = service?.additionalRentalTerms;
    const additionalRentalTax =
      additionalRental && additionalRental.taxRate
        ? (additionalRental.newPrice * (additionalRental.taxRate || 0)) /
          (1 + (additionalRental.taxRate || 0))
        : 0;
    const serviceTax =
      (service?.totalities?.includedTaxes || 0) + (additionalRentalTax || 0);
    amount += getServiceLegacyPrice(service) - serviceTax;

    if (service.isTaxable)
      taxAmount +=
        (service.totalities.includedTaxes || 0) + (additionalRentalTax || 0);
    /***Calculate Total Amount ***/
    // const addToTaxAmount = (el) => {
    //   const getTaxAmountForItem = {
    //     estimations: el?.items?.reduce(
    //       (prev, { taxAmount }) => prev + forceToNumber(taxAmount),
    //       0
    //     ),
    //   };
    //   return getTaxAmountForItem[selectedCategory];
    // };
    // if (checkIfServiceIsHoist(service)) {
    //   service?.serviceOptions?.flat(1)?.forEach((el) => {
    //     if (service?.isTaxable) taxAmount += addToTaxAmount(el) || 0;
    //     amount += el.price || 0;
    //   });
    // }
    // if (service.isScope) {
    //   amount += getServiceLegacyPrice(service);
    //   if (service.isTaxable) taxAmount += service.totalities.includedTaxes;
    // } else
    //   service?.serviceOptions?.flat(1)?.forEach((el) => {
    //     if (el?.items) {
    //       amount += el?.items?.reduce(
    //         (prev, { price }) => prev + forceToNumber(price),
    //         0
    //       );
    //       if (service?.isTaxable) taxAmount += addToTaxAmount(el);
    //     } else {
    //       amount += getServiceLegacyPrice(service);
    //       if (service.isTaxable) taxAmount += service.totalities.includedTaxes;
    //     }
    //   });
  } else if (selectedCategory === "rentals") {
    amount += rentalDataExtractor({
      rental: {
        label: "",
        services: [service],
      },
    })[0]?.appliedAmount;
  }
  //////////
  //?!TO DO
  if (selectedCategory !== "applications" && selectedCategory !== "rentals") {
    // Calculate service addons items if there are any
    // service?.serviceAddons
    //   ?.flat(1)
    //   ?.forEach(({ totalPrice }) => (amount += totalPrice));
    // //Adding retainAge if it exists
    // amount += (amount * service?.retainage) / 100 || 0;
  }
  //////////
  //Checking if the product is use before in an invoice if so subtract the amount of the used item
  return { amount: amount || 0.0, taxAmount: taxAmount || 0.0 };
}

export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const calculateTotalForServiceRequisition = (
  selectedGroup,
  categoriesResponse
) => {
  const fakeApplication = categoriesResponse?.find(
    ({ applicationId: id }) => id === selectedGroup
  );
  const lastRequisition =
    categoriesResponse?.find(
      ({ applicationNo: no }) => no === fakeApplication?.applicationNo - 1
    ) || null;
  let othersRentalData = categoriesResponse
    .filter(({ applicationId: id }) => id !== selectedGroup)
    ?.map((el) => {
      return rentalDataExtractor({
        fakeApplication: el,
      })
        ?.filter(Boolean)
        ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
        ?.map((e) => ({
          ...e,
          isPrevious: true,
          retainage:
            !!fakeApplication?.prevRentalsRetainage ||
            fakeApplication?.prevRentalsRetainage === 0
              ? fakeApplication?.prevRentalsRetainage
              : e?.retainage,
        }));
    })
    .flatMap((e) => e);
  let total =
    exportToExcelData({
      fakeApplication,
      lastRequisition,
      othersRentalData,
    })?.workSheet1DataExcel?.currentPaymentDue || 0;
  const rentTotal = calculatePriceForIncludedRentals(
    fakeApplication?.includedRentals
  );
  total += rentTotal;
  return total;
};

export function onOptionClickHandler({
  services,
  setRowData,
  selectedElement,
}) {
  //Auto filling the rest of the rows
  setRowData((prev) => {
    prev = prev
      ?.filter(({ name, id }) => name !== "")
      .filter(({ id }) => selectedElement?.id !== id);

    services
      ?.filter(
        ({ data, name }) =>
          !prev?.find(
            (el) =>
              el && el?.categoryId === data?.categoryId && name === el?.name
          )
      )
      ?.forEach((service) => {
        const description = getServiceDescription(service);

        const maxId = Math.max(...prev.map((item) => item.id));

        prev.push(
          structuredClone({
            serviceId: service?.serviceId,
            scopeAmount: service?.scopeAmount,
            isHoist: service?.isHoist,
            id: maxId + 1,
            name: service.name,
            description,
            amount: service.amount,
            tax: service?.tax,
            category: service.data.type,
            categoryId: service.data.categoryId,
            categoryElementIndex: service.data.categoryElementIndex,
            group: service.data.groupName,
            data: service.data,
            ...(service?.rentalId ? { rentalId: service?.rentalId } : {}),
            taxAmount: service.taxAmount || 0,
            maxSuggestedAmount: service?.maxSuggestedAmount,
            includedRequisitionId: service?.includedRequisitionId,
            amountPercentage: service?.amountPercentage,
            total: service?.total,
            creditedAmount: service?.creditedAmount,
          })
        );
      });

    return prev.map((item, index) => ({ ...item, id: index + 1 }));
  });
}

const generateDescriptionForRental = (data) => {
  const { startDate, endDate } = data;
  let desc = `
    <p>Start Date: ${dayjsNY(startDate).format("DD/MM/YYYY")}</p>
    <p>End Date: ${dayjsNY(endDate).format("DD/MM/YYYY")}</p>
    <p>Weeks: ${(endDate - startDate) / (3600000 * 24 * 6).toFixed(1)}</p>
  `;
  return desc || "";
};
//Returns the description of the service converted in html that is used by react quill
export function getServiceDescription(service) {
  const { chargeCategoryFrom, isAccumulatedRental, isFromRental, data } =
    service;

  //Converts items diff data like width, height, price etc. into html elements
  const { serviceOptions, type: categoryTable, amounts } = data;

  const isHoist = service?.name === "Hoist" || service?.isHoist;

  if (isAccumulatedRental) {
    let desc = `
      <h2>Accumulated Rental</h2> 
      `;

    serviceOptions?.forEach(({ label, rentalNumber, requisitionNumber }) => {
      desc += `<h3>Service: ${label}</h3> `;
      desc += `<p>Rental #: ${rentalNumber}</p> `;
      if (requisitionNumber)
        desc += `<p>Requisition #: ${requisitionNumber}</p> `;
    });
    return desc;
  }

  if (categoryTable === "applications") {
    let desc = "";
    amounts?.flat(1)?.forEach(({ name, note }) => {
      note = note.replace('contenteditable="false"', "");
      desc += `<h3>Event: ${name}<h3>`;
      desc += `\t${note}\n`;
    });
    return desc;
  }

  if (categoryTable === "charges" && chargeCategoryFrom === "Requisition") {
    let desc = "";

    if (isFromRental) {
      return generateDescriptionForRental(data);
    }

    if (isHoist) {
      serviceOptions?.forEach(
        ({ serviceOptions, elevationId, elevationLabel }) => {
          desc += `<h1>${elevationLabel} ${elevationId}</h1>`;
          serviceOptions?.forEach(
            ({ note }) => (desc += note.replace('contenteditable="false"', ""))
          );
        }
      );
      return desc;
    }

    return serviceOptions?.reduce(
      (p, { note }) => p + note?.replace('contenteditable="false"', ""),
      ""
    );
  }

  if (categoryTable === "rentals") {
    return generateDescriptionForRental(data);
  }

  function convertIntoHTML(items) {
    let value = "";
    items.forEach((el) => {
      value += `<p>${
        el?.length
          ? parseInt(el.length) +
            `'L${el?.height || el.width || el?.diameter ? " x " : ""}`
          : ""
      }${
        el?.height
          ? parseInt(el.height) + `'H${el.width || el?.diameter ? " x " : ""}`
          : ""
      }${
        el?.diameter ? parseInt(el.diameter) + `'D${el.width ? " x " : ""}` : ""
      }${el.width ? parseInt(el.width) + "'W" : ""} ${
        "price" in el
          ? el.price
            ? `Price: ${formatCurrency(el.price)} ${
                !el?.note?.note ? "\n" : ""
              }`
            : ""
          : "" ///////////////////////////////////
      }${el?.note?.note ? `\n${el.note.note}\n` : ""} </p>`;
    });
    return value;
  }
  let value = "";
  serviceOptions?.forEach?.((el) => {
    if (el?.items) value += convertIntoHTML(el?.items);
    else
      el?.forEach?.((el) => {
        value += convertIntoHTML(el?.items);
      });
  });
  return value;
}

export const getInfoForRentalServices = (rental) =>
  rental?.services
    ?.map?.((service) =>
      service?.serviceOptions
        ?.flat(1)
        ?.map((elevation) =>
          elevation?.items?.map((pli) => ({
            serviceName: service?.label,
            elevationName: elevation?.elevationLabel,
            startDate: pli?.rentalDetails?.startDate,
            endDate: pli?.rentalDetails?.endDate,
          }))
        )
        .flat(1)
    )
    ?.flat(1)
    ?.reduce((acc, curr) => {
      let findedItem =
        acc[curr?.serviceName]?.find(
          ({ elevationName }) => elevationName === curr?.elevationName
        ) || [];
      acc[curr?.serviceName] = findedItem
        ? [
            ...(acc[curr?.serviceName] || []).filter(
              ({ elevationName }) => elevationName !== curr?.elevationName
            ),
            {
              ...findedItem,
              startDate:
                findedItem?.startDate < curr?.startDate
                  ? findedItem?.startDate
                  : curr?.startDate,
              endDate:
                findedItem.endDate < curr?.endDate
                  ? findedItem?.endDate
                  : curr?.endDate,
            },
          ].filter(Boolean)
        : [
            ...(acc[curr.serviceName] || []),
            curr?.startDate &&
              curr?.endDate && {
                elevationName: curr?.elevationName,
                startDate: curr?.startDate,
                endDate: curr?.endDate,
              },
          ].filter(Boolean);
      return Object.keys(acc)
        .filter((e) => acc[e].length !== 0)
        .reduce((acc1, curr) => {
          acc1[curr] = acc[curr];
          return acc1;
        }, {});
    }, {});

//This function solves the parsing of html descriptions and notes
//Returns a concise string to be put in the logs
export function parseText(html, result = "") {
  //we convert a single element to an array
  if (!Array.isArray(html)) {
    html = [html].flat();
  }
  for (let i = 0; i < html.length; i++) {
    let tmp = html[i]?.props?.children;
    if (!!tmp) {
      if (typeof tmp === "string") {
        result = result + tmp?.trim() + ", ";
      } else {
        return parseText(html[i]?.props?.children, result);
      }
    }
  }
  return result.replace(/,\s$/, "");
}

//This function eliminates unnecessary single-element objects
//from the main object, thus reducing unnecessary nesting
export const objectInnerTrim = (object, fromSettings) => {
  for (var key in object) {
    if (typeof object[key] === "object") {
      if (key === "editLogs") continue;
      if (!object[key]) continue;

      let objectKeys = Object.keys(object[key]);
      //when an object has a single value, the value of the single key becomes the new value
      if (objectKeys?.length === 1) {
        // when the object is empty it will return Created
        if (object[key][objectKeys[0]] === undefined) {
          object[key] = "Created";
        } else {
          object[key] = object[key][objectKeys[0]];
          objectInnerTrim(object, fromSettings);
        }
      }
      // objectInnerTrim(object[key])?.question?
      // objectInnerTrim(object[key])?.question:
      objectInnerTrim(object[key], fromSettings);
    } else {
      //we remove null elements
      if (!object[key]) {
        if (object[key] === 0) {
          object[key] = undefined;
        } else {
          delete object[key];
        }
        continue;
      }

      //we split the key words
      let camelKey;
      if (key.split("_").length > 1) {
        camelKey = key.split("_");
      } else {
        camelKey = key.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");
      }

      //we remove IDs from the results
      if (
        camelKey.includes("id") ||
        camelKey.includes("Id") ||
        camelKey.includes("index") ||
        camelKey.includes("Index") ||
        camelKey.includes("Signature") ||
        (key === "type" && !fromSettings) ||
        key === "color"
      ) {
        delete object[key];
      } else if (key === "safetyExpirationDate") {
        let format = DATE_FORMAT;
        object[key] = dayjs(object[key]).format(`${format}`);
      } else if (
        camelKey.includes("date") ||
        camelKey.includes("Date") ||
        camelKey.includes("time") ||
        camelKey.includes("Time") ||
        key === "createdAt" ||
        key === "lastModifiedAt" ||
        key === "uploadedAt" ||
        key === "arriveBy" ||
        key === "departAt" ||
        key === "timeExit" ||
        // this key is found in notes logs
        key === "published"
      ) {
        // Check if data came from settings pages
        if (!!fromSettings && key !== "createdAt") {
          object[key];
        } else {
          //we parse the dates
          let format = DATE_FORMAT;
          if (!camelKey?.includes("Birth")) {
            format = format + ` ${TIME_FORMAT}`;
          }
          if (key !== "timeScheduled") {
            object[key] = dayjs(object[key]).format(`${format}`);
          }
        }
      } else if (
        camelKey.includes("percentage") ||
        camelKey.includes("Percentage") ||
        camelKey.includes("Percent") ||
        camelKey.includes("percent")
      ) {
        //we parse the percentages
        if (!isNaN(parseFloat(object[key]))) {
          object[key] = `${parseFloat(object[key])?.toFixed(2)}%`;
        }
      } else if (
        key.includes("phone") ||
        key.includes("Phone") ||
        key === "Emergency Contact"
      ) {
        object[key] = parsePhone(object[key]);
      } else {
        //we parse currencies
        let currencyCondition = false;
        currencyKeywords.forEach((el) => {
          if (camelKey.includes(el)) {
            currencyCondition = true;
          }
        });
        if (
          key === "currentPeriod" ||
          key === "thisPeriod" ||
          key === "totalCompleated" //intentional spelling error
        ) {
          currencyCondition = true;
        }
        if (!!fromSettings) {
          object[key];
        } else if (currencyCondition && !isNaN(parseFloat(object[key]))) {
          object[key] = formatCurrency(object[key]);
        }
      }
    }
  }
  return object;
};

//function that de-structures and regulates the data from the multi level edit logs
export const getLogsData = (editLogs, fromSettings) => {
  if (editLogs?.length === 0 || !editLogs) return null;

  let tmpProps = [];
  let tmpEditLogs = _.cloneDeep(editLogs);

  for (let i = 0; i < tmpEditLogs?.length; i++) {
    //differentiates between logs saved with the correct spelling
    let tmpIdentifier = "previusData";
    if (tmpEditLogs[i].hasOwnProperty("previousData"))
      tmpIdentifier = "previousData";

    let tmpPropObject = {
      currentData: {},
      [tmpIdentifier]: {},
    };
    let currentObject;
    let previousObject;

    currentObject = tmpEditLogs[i]?.currentData || {};
    previousObject = tmpEditLogs[i][tmpIdentifier];

    //gets the current data's keys
    let currentKeys = Object.keys(currentObject);

    //we skip the empty data
    if (currentKeys?.length === 0) continue;
    if (!currentKeys) continue;

    //firstly we push the generic data
    tmpPropObject["member"] = tmpEditLogs[i]["member"];
    tmpPropObject["updatedAt"] = tmpEditLogs[i]["updatedAt"];
    tmpPropObject["updatedKeys"] = [{ details: "See Details" }];

    //we loop through the "current" keys and solve for notes
    tmpPropObject["currentData"] = objectInnerTrim(currentObject, fromSettings);
    tmpPropObject[tmpIdentifier] = objectInnerTrim(
      previousObject,
      fromSettings
    );
    tmpProps.push(tmpPropObject);
  }
  return tmpProps;
};

export async function updateClientBalance(selectedData) {
  showLoadingMsg({ content: "Updating client balance. Please wait..." });

  try {
    const account = await filterTables(
      "accounts",
      "accountId",
      selectedData?.accountId
    );
    const updatedBalance =
      account[0].clientBalance - selectedData.totalInvoiceAmount;

    await API.put("accounts", `/accounts/${selectedData?.accountId}`, {
      body: {
        clientBalance: updatedBalance,
      },
    });
  } catch (error) {
    showErrorMsg({ content: "There was a problem updating clientBalance" });
  }
}

async function deleteInvoice(
  setData,
  onClose,
  setConfirmModalVisible,
  setAllInvoices,
  selectedData,
  saveAddedLogs = () => {}
) {
  API.del("invoices", `/invoices/${selectedData?.invoiceId}`).then(() => {
    deleteAllRelatedToDos({ recordId: selectedData?.invoiceId });
    setData((prev) =>
      prev.filter(({ invoiceId }) => invoiceId !== selectedData?.invoiceId)
    );
    onClose();
    setConfirmModalVisible(false);
    setAllInvoices((prev) =>
      prev.filter(({ invoiceId }) => invoiceId !== selectedData?.invoiceId)
    );

    saveAddedLogs({
      recordId: selectedData?.invoiceId,
      recordName: `No. ${selectedData?.quickBooksNumber}`,
      actionType: "Delete",
      topic: selectedData?.projectName,
      category: "Invoice",
    });
  });
}

export async function onDeleteClickHandler({
  setData = () => {},
  onClose = () => {},
  setConfirmModalVisible = () => {},
  setAllInvoices = () => {},
  selectedData,
  authenticatedUser: authUser = {
    given_name: "",
    family_name: "",
    sub: "",
  },
  projectData,
  userConfiguration,
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
}) {
  /* Validations */
  // if (selectedData?.charges?.length > 0) {
  //   message.error(
  //     <div>
  //       This invoice is credited in:{" "}
  //       {selectedData?.charges?.map(({ chargeId, chargeNumber }) => (
  //         <a href={`/charges/${chargeId}`} target="_blank">
  //           {chargeNumber}
  //         </a>
  //       ))}{" "}
  //       !
  //     </div>
  //   );
  //   return;
  // }
  /* ***************************** */

  const accountData = await filterTables(
    "accounts",
    "accountId",
    selectedData.accountId
  );

  setVisibleCreationProgress(selectedData);
  updateProgressStatus({ updatingRecord: "executing" });
  Promise.all([
    updateClientBalance(selectedData),
    deleteInvoice(
      setData,
      onClose,
      setConfirmModalVisible,
      setAllInvoices,
      selectedData,
      authUser
    ),
  ])
    .then(() => {
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      sendNotification({
        id: "96",
        action: "onInvoiceDeletion",
        team: accountData?.[0]?.teamsConfiguration || [],
        otherKeys: {
          topicName: selectedData?.accountName,
          invoiceNumber: selectedData?.invoiceNumber,
        },
        recordId: selectedData.invoiceId,
        authenticatedUser: authUser,
        userConfiguration,
      }).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });
      showSuccessMsg({ content: "Invoice deleted successfully." });
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      showErrorMsg({ content: "There was a problem deleting invoice", err });
    });

  const objsToUpdate = {};
  selectedData?.invoiceItems?.forEach(({ category, categoryId }) => {
    if (objsToUpdate?.[category]) {
      objsToUpdate?.[category]?.push(categoryId);
    } else objsToUpdate[category] = [categoryId];
  });
  Object.keys(objsToUpdate)?.forEach((table) =>
    Array?.from(new Set(objsToUpdate[table]))?.forEach((categoryId) =>
      API.get(table, `/${table}/${categoryId}`)?.then(
        ({ invoices, services, chargeItems }) => {
          (services || chargeItems)?.forEach(
            (service) =>
              (service.invoices = service?.invoices?.filter(
                ({ invoiceId }) => invoiceId !== selectedData?.invoiceId
              )) || []
          );
          API.put(table, `/${table}/${categoryId}`, {
            body: {
              invoices: invoices?.filter(
                ({ invoiceId }) => invoiceId !== selectedData?.invoiceId
              ),
              [services ? "services" : "chargeItems"]: services || chargeItems,
            },
          });
          if (table === "estimations") {
            API.put("projects", `/projects/${selectedData?.projectId}`, {
              body: {
                services: {
                  ...projectData?.services,
                  [categoryId]: services,
                },
              },
            });
          }
        }
      )
    )
  );
}
//Calculating credited memo amount for service from categoory
export const calculateCreditedAmountForService = ({
  service,
  categoryFrom,
}) => {
  let creditedTotal = 0;
  let creditAmount = 0;
  if (checkIfServiceIsHoist(service)) {
    if (categoryFrom === "estimations")
      service?.serviceOptions?.flat(1)?.forEach(({ charges }) => {
        charges
          ?.filter(
            ({ chargeType, type }) =>
              chargeType === "Credit Memo" || type === "Credit Memo"
          )
          ?.forEach(({ chargeAmount, taxRate }) => {
            creditedTotal += chargeAmount * (1 + taxRate || 0) || 0;
            creditAmount += chargeAmount;
          });
      });
    if (categoryFrom === "rentals")
      service?.serviceOptions?.flat(1)?.forEach(({ items }) => {
        items?.forEach(({ charges }) => {
          charges
            ?.filter(
              ({ chargeType, type }) =>
                chargeType === "Credit Memo" || type === "Credit Memo"
            )
            ?.forEach(({ chargeAmount, taxRate }) => {
              creditedTotal += chargeAmount * (1 + taxRate || 0) || 0;
              creditAmount += chargeAmount;
            });
        });
      });
  } else {
    service?.serviceOptions?.flat(1)?.forEach(({ items }) =>
      items?.find(({ charges, items }) => {
        if (items)
          return items?.forEach(({ charges }) => {
            charges
              ?.filter(
                ({ chargeType }) =>
                  chargeType === "Credit Memo" || type === "Credit Memo"
              )
              ?.forEach(({ chargeAmount, taxRate }) => {
                creditedTotal += chargeAmount * (1 + taxRate || 0) || 0;
                creditAmount += chargeAmount;
              });
          });
        charges
          ?.filter(
            ({ chargeType, type }) =>
              chargeType === "Credit Memo" || type === "Credit Memo"
          )
          ?.forEach(({ chargeAmount, taxRate }) => {
            creditedTotal += chargeAmount * (1 + taxRate || 0) || 0;
            creditAmount += chargeAmount;
          });
      })
    );
  }
  return { creditedTotal, creditAmount };
};

export const calculatePreviousInvoiceTotals = ({
  service,
  exceptionInvoicesId = [],
}) => {
  return (
    service?.invoices
      ?.filter(({ invoiceId }) => !exceptionInvoicesId?.includes(invoiceId))
      ?.reduce(
        (
          { previousInvoicedAmount, previousInvoicedTotal },
          { amount, total }
        ) => ({
          previousInvoicedAmount: previousInvoicedAmount + amount,
          previousInvoicedTotal: previousInvoicedTotal + total,
        }),
        { previousInvoicedAmount: 0, previousInvoicedTotal: 0 }
      ) || 0
  );
};

export const calculateInvoicedAmountForCategoryDataObj = ({
  categoryDataObj,
  invoiceIdsException = [],
}) =>
  categoryDataObj?.invoices
    ?.filter(({ invoiceId }) => !invoiceIdsException.includes(invoiceId))
    ?.reduce((p, { invoiceAmount }) => p + invoiceAmount, 0) || 0;

// export const calculateTaxForSov = (data) => {
//   const taxAmount = 0;
//   data?.services ||
//     [].map(
//       (item) =>
//         (item?.serviceOptions || []).flatMap((serviceOption) =>
//           (serviceOption || []).flatMap((option) =>
//             (option?.amounts || []).reduce(
//               (amountTaxAmount, amount) =>
//                 amountTaxAmount + (amount.taxAmount || 0),
//               0
//             )
//           )
//         ),
//       0
//     );
//   return taxAmount;
// };

export const calculateTaxForSov = (data) => {
  let taxAmount = 0;
  data?.services ||
    [].map((charge) => {
      if (charge.label === "Hoist" || charge.isHoist) {
        charge.serviceOptions.map((serviceOption) =>
          serviceOption.map((option) =>
            option.amounts.map(
              (service) => (taxAmount += service.taxAmount || 0)
            )
          )
        );
      } else {
        charge.amounts.map((service) => (taxAmount += service.taxAmount || 0));
      }
    });
  return taxAmount;
};
