import "./DashboardPrefs.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import UserAutomationExecutions from "./UserAutomationExecutions/UserAutomationExecutions";
import CustomTransfer from "../../../../../commonComponents/CustomTransfer/CustomTransfer";
import { useUserProfileContext } from "../UserProfileContext";
import { ToggleSwitch } from "../../../../../commonComponents";
import { DashboardIcon } from "src/assets";
import { NewAutomationIcon } from "../../../../../../assets";

const DashboardPrefs = () => {
  const { profileChanges, setProfileChanges } = useUserProfileContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    preferences: {
      widgetConfiguration: { dashboard: nonHiddenCards = [] } = {},
      widgetHiddenConfiguration: { dashboard: hiddenCards = [] } = {},
    },
  } = useSelector((state) => state.preferences);

  const [switchContainer, setSwitchContainer] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const allCards = [...nonHiddenCards, ...hiddenCards];

  const finalNonHiddenCards =
    profileChanges?.dashboardPrefs?.nonHiddenCards || nonHiddenCards;
  const finalHiddenCards =
    profileChanges?.dashboardPrefs?.hiddenCards || hiddenCards;

  return (
    <div
      className={`dashboard-prefs-container ${collapsed ? "collapsed" : ""}`}
    >
      <div
        className={`CardContentWrapper ${collapsed ? "collapsed" : ""} ${
          isDarkMode ? "dark-mode" : ""
        }`}
      >
        <div
          className="CardContentWrapper-header"
          onClick={() => {
            setCollapsed((prev) => !prev);
          }}
        >
          <div className="header-left">Dashboard Preferences</div>
          <div className="header-right">
            <ToggleSwitch
              checked={switchContainer}
              label={switchContainer ? "Automations" : "Dashboard"}
              icon={switchContainer ? <NewAutomationIcon /> : <DashboardIcon />}
              onChange={(e) => {
                setSwitchContainer(e);
              }}
            />
          </div>
        </div>

        <div className="CardContentWrapper-content">
          {switchContainer ? (
            <UserAutomationExecutions />
          ) : (
            <CustomTransfer
              {...{
                redSectionTitle: `${finalHiddenCards.length} Hidden Cards`,
                greenSectionTitle: `${finalNonHiddenCards.length} Unhidden Cards`,
                dataSource: allCards?.map(({ id, title }) => ({
                  key: id,
                  title,
                })),
                value: finalNonHiddenCards?.map(({ id }) => id),
                onChange: (nonHiddenIds) => {
                  setProfileChanges((prev) => ({
                    ...prev,
                    dashboardPrefs: {
                      nonHiddenCards: allCards.filter(({ id }) =>
                        nonHiddenIds.includes(id)
                      ),
                      hiddenCards: allCards.filter(
                        ({ id }) => !nonHiddenIds.includes(id)
                      ),
                    },
                  }));
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPrefs;
