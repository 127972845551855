const addDummyKeyOnSingleKeyObj = (Obj) => {
  let objectKeys = Object.keys(Obj);
  // if object have only 1 key it will add dummyKey
  if (objectKeys?.length === 1) {
    Object.assign(Obj, { dummyKey: undefined });
    // adds dummy key if nested object have only one key
    addDummyKeyOnSingleKeyObj(Obj[objectKeys[0]]);
  }
  return Obj;
};

export const updateEditLogs = (result) => {
  // Filter the result object to take only the keys we need
  result.curr.definition = Object.fromEntries(
    Object.entries(result.curr.definition).filter(
      ([key]) =>
        key.includes("DataSets") ||
        key.includes("DataSources") ||
        key.includes("Body") ||
        key.includes("Header") ||
        key.includes("Footer")
    )
  );

  let currChange = result.curr.definition;
  let prevChange = result.prev.definition;
  // if there are changes on DataSets we format the data
  if (!!currChange.DataSets && currChange.DataSets !== "'Does not exist'") {
    // change the keys from 0, 1, 2... to the name of each DataSet
    Object.keys(currChange.DataSets).forEach((key) => {
      if (currChange.DataSets[key]?.Name?.length > 0) {
        currChange.DataSets = {
          ...currChange.DataSets,
          [currChange.DataSets[key].Name]: currChange.DataSets[key],
          dummyKey: 0,
        };
        if (prevChange.DataSets) {
          prevChange.DataSets = {
            ...prevChange.DataSets,
            [currChange.DataSets[key].Name]: prevChange.DataSets[key],
            dummyKey: 0,
          };
          delete prevChange.DataSets?.[key];
        }
        delete currChange.DataSets[key];
      }
    });
    // On deletion
    if (prevChange.DataSets) {
      Object.keys(prevChange.DataSets).forEach((prevKey) => {
        // if there is a deleted DataSet format currChange and prevChange to show the name of DataSet that was deleted
        if (currChange.DataSets[prevKey] === "'Does not exist'") {
          currChange.DataSets = {
            ...currChange.DataSets, // part of obj that will be deleted
            // show the name of DataSet and that it does not exist anymore
            [prevChange.DataSets[prevKey].Name]: "'Does not exist'",
            dummyKey: 0,
          };
          prevChange.DataSets = {
            ...prevChange.DataSets, // part of object that will be deleted
            // show the name of DataSet
            [prevChange.DataSets[prevKey].Name]:
              prevChange.DataSets[prevKey].Name,
            dummyKey: 0,
          };
          // delete the previous part of object
          delete prevChange.DataSets?.[prevKey];
          delete currChange.DataSets[prevKey];
        }
      });
    }
  }

  // if there are changes on DataSources we format the data
  if (
    !!currChange.DataSources &&
    currChange.DataSources !== "'Does not exist'"
  ) {
    // change key from 0, 1, 2, 3... to the name of each DataSource
    Object.keys(currChange.DataSources).forEach((key) => {
      currChange.DataSources = {
        ...currChange.DataSources,
        [currChange.DataSources[key]]: currChange.DataSources[key],
      };
      delete currChange.DataSources[key];
    });
  }
  // if there are changes on Body.ReportItems we format the data
  if (currChange?.Body?.ReportItems) {
    // change key from 0, 1, 2, 3... to the name of each ReportItem
    Object.keys(currChange.Body.ReportItems).forEach((key) => {
      // on Edit of Body
      if (
        currChange.Body.ReportItems[key] !== "'Does not exist'" &&
        currChange.Body.ReportItems !== "'Does not exist'"
      ) {
        currChange.Body.ReportItems = {
          ...currChange.Body.ReportItems,
          ReportItems: {
            [currChange.Body.ReportItems[key].Name]:
              currChange.Body.ReportItems[key],
          },
        };
        delete currChange.Body.ReportItems[key];
      }
    });
    // On deletion
    if (prevChange.Body) {
      Object.keys(prevChange.Body.ReportItems).forEach((prevKey) => {
        // if there are deleted items change key from 0, 1, 2, 3... to the name of each ReportItem
        if (prevChange.Body.ReportItems[prevKey] !== "'Does not exist'") {
          // if all report items are deleted
          if (
            currChange.Body.ReportItems === "'Does not exist'" ||
            currChange.Body.ReportItems[1] === "'Does not exist'"
          ) {
            // set currChanges.Body to empty object
            currChange.Body.ReportItems = {};
          }

          currChange.Body.ReportItems = {
            ...currChange.Body.ReportItems,
            [prevChange.Body.ReportItems[prevKey].Name]: "'Does not exist'",
            dummyKey: undefined,
          };
          prevChange.Body.ReportItems = {
            ...prevChange.Body.ReportItems,
            [prevChange.Body.ReportItems[prevKey].Name]:
              prevChange.Body.ReportItems[prevKey].Name,
            dummyKey: undefined,
          };
          // delete previous part of object
          delete prevChange.Body.ReportItems?.[prevKey];
          delete currChange.Body.ReportItems[prevKey];
        }
      });
    }

    // add nesting in ReportItems to understand the changes better in LogModals
    currChange.Body = addDummyKeyOnSingleKeyObj(currChange.Body.ReportItems);
    // on deletion
    if (prevChange.Body) {
      // add nesting in ReportItems to understand the changes better in LogModals
      prevChange.Body = addDummyKeyOnSingleKeyObj(prevChange.Body.ReportItems);
    }
  }

  // same thing done on Body is done on Header

  // if there are changes on Header.ReportItems we format the data
  if (currChange?.Header?.ReportItems) {
    // change key from 0, 1, 2, 3... to the name of each ReportItem
    Object.keys(currChange.Header.ReportItems).forEach((key) => {
      currChange.Header.ReportItems = {
        ...currChange.Header.ReportItems,
        ReportItems: {
          [currChange.Header.ReportItems[key].Name]:
            currChange.Header.ReportItems[key],
        },
      };
      delete currChange.Header.ReportItems[key];
    });
    // On deletion
    if (prevChange.Header) {
      Object.keys(prevChange.Header.ReportItems).forEach((prevKey) => {
        if (currChange.Header.ReportItems === "'Does not exist'") {
          currChange.Header.ReportItems = {
            ...currChange.Header,
            ReportItems: {
              [prevChange.Header.ReportItems[prevKey].Name]: "'Does not exist'",
              dummyKey: undefined,
            },
          };
          prevChange.Header.ReportItems = {
            ...prevChange.Header.ReportItems,
            ReportItems: {
              [prevChange.Header.ReportItems[prevKey].Name]:
                prevChange.Header.ReportItems[prevKey].Name,
              dummyKey: undefined,
            },
          };
          delete prevChange.Header.ReportItems?.[prevKey];
        }
      });
    }

    // add nesting in LogModals to understand the changes better
    currChange.Header = addDummyKeyOnSingleKeyObj(
      currChange.Header.ReportItems
    );
    if (prevChange.Header) {
      prevChange.Header = addDummyKeyOnSingleKeyObj(
        prevChange.Header.ReportItems
      );
    }
  }

  // same thing done in Header is done in Footer

  // if there are changes on Footer.ReportItems we format the data
  if (currChange?.Footer?.ReportItems) {
    // change key from 0, 1, 2, 3... to the name of each ReportItem
    Object.keys(currChange.Footer.ReportItems).forEach((key) => {
      currChange.Footer.ReportItems = {
        ...currChange.Footer.ReportItems,
        ReportItems: {
          [currChange.Footer.ReportItems[key].Name]:
            currChange.Footer.ReportItems[key],
        },
      };
      delete currChange.Footer.ReportItems[key];
    });
    // On deletion
    if (prevChange.Footer) {
      Object.keys(prevChange.Footer.ReportItems).forEach((prevKey) => {
        if (currChange.Footer.ReportItems === "'Does not exist'") {
          currChange.Footer.ReportItems = {
            ...currChange.Footer,
            ReportItems: {
              [prevChange.Footer.ReportItems[prevKey].Name]: "'Does not exist'",
              dummyKey: undefined,
            },
          };
          prevChange.Footer.ReportItems = {
            ...prevChange.Footer.ReportItems,
            ReportItems: {
              [prevChange.Footer.ReportItems[prevKey].Name]:
                prevChange.Footer.ReportItems[prevKey].Name,
              dummyKey: undefined,
            },
          };
          delete prevChange.Footer.ReportItems?.[prevKey];
        }
      });
    }

    // add nesting in LogModals to understand the changes better
    currChange.Footer = addDummyKeyOnSingleKeyObj(
      currChange.Footer.ReportItems
    );
    if (prevChange.Footer) {
      prevChange.Footer = addDummyKeyOnSingleKeyObj(
        prevChange.Footer.ReportItems
      );
    }
  }

  // update new log object with current changes
  return { currChange, prevChange };
};
