export const styleSettings = {
  fontFamily: "sans-serif",
  colorText: "#1c1c1c",
  colorStrong: "#101010",
  colorTable: "#454545",
};

export const styleInvoice = {
  headerInvoiceCont: {
    boxSizing: "border-box",
    width: "590px",
    height: "auto",
    display: "grid",
    gridTemplateColumns: "45% 45%",
    gridTemplateRows: "auto",
    justifyContent: "center",
    alignContent: "center",
    gap: "0",
  },

  leftPartCont: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    justifySelf: "end",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto",
    gap: "5px 0",
  },

  imgLogo: {
    gridArea: "auto",
    width: "96px",
    height: "18px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    objectFit: "contain",
  },

  infoPartOne: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    justifySelf: "end",
    display: "grid",
    gridTemplateColumns: "100%",
    justifyContent: "start",
    alignItems: "start",
  },

  infoPartTwo: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    justifySelf: "end",
    display: "grid",
    gridTemplateColumns: "100%",
    justifyContent: "start",
    alignItems: "start",
  },

  infoPartTwoH3: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    margin: "5px 0",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorStrong,
    fontSize: "9px",
    fontWeight: "800",
    textAlign: "start",
  },

  textLeftSide: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorText,
    fontSize: "9px",
    fontWeight: "200",
    textAlign: "start",
  },

  rightPartCont: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    justifySelf: "end",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto",
    alignContent: "start",
  },

  head1: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorStrong,
    textAlign: "end",
    alignSelf: "end",
    fontSize: "15px",
    fontWeight: "800",
  },

  infoPartOneR: {
    gridArea: "auto",
    width: "70%",
    height: "fit-content",
    justifySelf: "end",
    border: `4px double ${styleSettings.colorTable}`,
    display: "grid",
    gridTemplateColumns: "49% 49%",
    gridTemplateRows: "fit-content",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0 1%",
  },

  tableTextL: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    padding: "2px",
    borderRight: `1px solid ${styleSettings.colorTable}`,
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorText,
    fontSize: "8px",
    fontWeight: "200",
    textAlign: "center",
  },

  tableTextR: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    padding: "2px",
    borderLeft: `1px solid ${styleSettings.colorTable}`,
    fontFamily: styleSettings.fontFamily,
    color: "#0a0a0a",
    fontSize: "8px",
    fontWeight: "200",
    textAlign: "center",
  },

  tableH3L: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    padding: "2px",
    borderTop: `4px double ${styleSettings.colorTable}`,
    borderRight: `1px solid ${styleSettings.colorTable}`,
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorStrong,
    fontSize: "10px",
    fontWeight: "800",
    textAlign: "center",
  },

  tableH3R: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    padding: "2px",
    borderTop: `4px double ${styleSettings.colorTable}`,
    borderLeft: `1px solid ${styleSettings.colorTable}`,
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorStrong,
    fontSize: "10px",
    fontWeight: "800",
    textAlign: "center",
  },

  infoPartTwoR: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    justifySelf: "end",
    display: "grid",
    gridTemplateColumns: "100%",
    justifyContent: "start",
    alignContent: "start",
  },

  infoPartTwoH3R: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    margin: "5px 0",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorText,
    fontSize: "9px",
    fontWeight: "800",
    textAlign: "end",
  },

  textRightSide: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorText,
    fontSize: "9px",
    fontWeight: "200",
    textAlign: "end",
  },
};
