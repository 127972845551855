import { approvalsViewAccess } from ".";

/**
 * Disables request fields based on user configuration and request form data.
 *
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Object} requestForm - The request form object.
 * @param {string} from - The source of the request.
 * @returns {boolean} - Returns true if the request fields should be disabled, false otherwise.
 */
function disableRequestFields({ userConfiguration, requestForm, from }) {
  if (!!requestForm) {
    if (
      requestForm?.responseFrom === "approved" ||
      (requestForm?.responseFrom === "declined" &&
        requestForm?.requestObject?.status === "Cancelled")
    ) {
      return true;
    } else if (
      ![
        requestForm?.createdBy,
        requestForm?.userRequesting,
        // requestForm?.assignedTo,
      ].includes(userConfiguration?.cognitoUserId) &&
      from !== "header"
    ) {
      // if (
      //   getCognitosForNotification(userConfiguration, selectedTeam)?.includes(
      //     userConfiguration?.cognitoUserId
      //   )
      // ) {
      //   return false;
      // }
      return true;
    }
    if (
      userConfiguration?.groupName === "Admin" ||
      userConfiguration?.groupName === "Super Admin"
    ) {
      return false;
    }
    return true;
  }

  if (
    userConfiguration?.groupName === "Admin" ||
    userConfiguration?.groupName === "Super Admin"
  ) {
    return false;
  }

  if (approvalsViewAccess(userConfiguration)?.write === false) {
    return true;
  }

  return false;
}

export default disableRequestFields;
