import "./Header.scss";

import { Button, DatePicker, Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const Header = ({
  setSelectedMonth,
  selectedMonth,
  setDueDate,
  isDarkMode,
  canCreate,
  setCustom,
  dueDate,
  custom,
}) => {
  //this sets dueDate state to the value we chose from date picker
  const dueDateHandler = (date) => {
    !!date ? setDueDate(dayjs(date)?.valueOf()) : setDueDate(null);
  };

  console.log("custommm", custom, selectedMonth);

  //can't select dates smaller than the start of application
  const dueDateDisabledDate = (current) => {
    return (
      current &&
      current < moment(selectedMonth?.start).subtract(1, "day").endOf("day")
    );
  };

  return (
    <div className="dateTypePickerContainer">
      <Button
        type="primary"
        className={!!custom ? "monthPicker" : "monthPickerUnSelected"}
        onClick={() => (!!custom && setCustom(false), setSelectedMonth(null))}
      >
        Month
      </Button>
      <Button
        type="primary"
        className={!!!custom ? "customPicker" : "customPickerUnSelected"}
        onClick={() => (!!!custom && setCustom(true), setSelectedMonth(null))}
      >
        Custom
      </Button>

      <div className="dueDateContainer">
        <Tooltip title={!canCreate ? "Please pick the period first" : ""}>
          <DatePicker
            onChange={dueDateHandler}
            placeholder={"Due date"}
            disabledDate={dueDateDisabledDate}
            popupClassName={isDarkMode && "darkDataDropDown"}
            disabled={!canCreate}
            value={!!dueDate ? dayjs.tz(dueDate) : null}
            format="MM/DD/YYYY"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
