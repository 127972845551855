import {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, message } from "antd";
import { useSelector } from "react-redux";
import {
  CircleF,
  PolygonF,
  GoogleMap,
  OverlayView,
  OverlayViewF,
  useLoadScript,
  DrawingManagerF,
  DirectionsRenderer,
} from "@react-google-maps/api";

import {
  GOOGLE_API_KEY,
  NEW_YORK_LOCATION,
} from "src/components/SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";
import { Marker } from "src/components/commonComponents";
import {
  MAP_DARK,
  MAP_DEFAULT,
} from "../../../../../../SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";

import "./PayrollActivityMap.scss";

const polygonOptions = {
  fillColor: "#d99f00",
  strokeColor: "#d99f00",
  fillOpacity: 0.4,
  strokeWeight: 2,
};
const circleOptions = {
  fillColor: "#4e8afa",
  strokeColor: "#0059ff",
  fillOpacity: 0.2,
  strokeWeight: 2,
};

const PayrollActivityMap = forwardRef(function (props, ref) {
  const {
    radius,
    mapOptions,
    defaultMarker,
    defaultAddress,
    directions = false,
    editGeofence = false,
    employeePosition = false,
  } = props;
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { isLoaded } = useLoadScript({
    libraries: ["drawing"],
    googleMapsApiKey: GOOGLE_API_KEY,
  });
  const [map, setMap] = useState();
  const [drawingMode, setDrawingMode] = useState("");
  const [geoFenceInfo, setGeoFenceInfo] = useState([]);

  const polygonRef = useRef();

  const handleMapLoad = useCallback(function (mapInstance) {
    if (!map) {
      setMap(mapInstance);
    }
  }, []);

  const fitBoundsToMarkers = (listOfLocations) => {
    if (map && listOfLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      listOfLocations.forEach((marker) => {
        if (marker?.lat && marker?.lng) {
          bounds.extend({ lat: marker?.lat, lng: marker?.lng });
        }
      });
      map?.fitBounds(bounds);
    }
  };

  const onPolygonComplete = useCallback(
    function onPolygonComp(poly) {
      const polyArray = poly?.getPath?.().getArray?.();
      let paths = [];
      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });

      const newGeofence = {
        shapeId: uuidv4(),
        type: "Polygon",
        title: defaultAddress,
        manuallyCreated: true,
        description: "",
        createdAt: Date.now(),
        createdBy: userConfiguration?.nameOfUser,
        geoFenceInfo: paths,
      };
      setGeoFenceInfo([newGeofence]); // if the geofence is created from modal
      setDrawingMode("");
      poly?.setMap?.(null);
      message.success("Polygon created successfully!");
    },
    [userConfiguration?.nameOfUser, setDrawingMode, setGeoFenceInfo]
  );

  useImperativeHandle(ref, function () {
    return {
      getGeofenceInfo: () => {
        return geoFenceInfo;
      },
      updateGeofenceInfo: (newGeofenceInfo) => {
        setGeoFenceInfo(newGeofenceInfo);
      },
    };
  });

  useEffect(() => {
    if (map && defaultMarker?.lat && employeePosition?.lat) {
      fitBoundsToMarkers([defaultMarker, employeePosition]);
    } else if (map && employeePosition?.lat) {
      fitBoundsToMarkers([employeePosition]);
    }
  }, [map, defaultMarker, employeePosition]);

  return (
    <div
      className="payrollActivityMapContainer"
      data-testid="payroll-activity-map"
    >
      {isLoaded && (
        <GoogleMap
          ref={polygonRef}
          center={defaultMarker?.lat ? defaultMarker : NEW_YORK_LOCATION}
          zoom={16}
          onLoad={handleMapLoad}
          mapContainerClassName={"payrollActivityMap"}
          options={{
            styles: isDarkMode ? MAP_DARK : MAP_DEFAULT,
            ...mapOptions,
          }}
        >
          {!!directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                suppressMarkers: true,
              }}
            />
          )}
          {defaultMarker?.lat && (
            <OverlayView position={defaultMarker} mapPaneName={"markerLayer"}>
              <Marker
                address={defaultAddress}
                lat={defaultMarker?.lat}
                lng={defaultMarker?.lng}
              />
            </OverlayView>
          )}
          {employeePosition?.lat && (
            <OverlayViewF position={employeePosition} mapPaneName="markerLayer">
              <Marker
                lat={employeePosition?.lat}
                lng={employeePosition?.lng}
                color={"pink"}
              />
            </OverlayViewF>
          )}
          {!!geoFenceInfo?.length &&
            geoFenceInfo?.map((geofence) => {
              return (
                <PolygonF
                  ref={polygonRef}
                  options={{
                    options: polygonOptions,
                    editable: false,
                    draggable: false,
                  }}
                  path={geofence?.geoFenceInfo}
                />
              );
            })}
          {!!radius && (
            <CircleF
              options={circleOptions}
              radius={parseFloat(radius) / 3.281}
              center={defaultMarker}
            />
          )}
          {editGeofence && geoFenceInfo?.[0]?.manuallyCreated !== false && (
            <>
              <section className="drawing-buttons">
                <Button
                  className="draw-button"
                  onClick={
                    !!drawingMode
                      ? () => setDrawingMode("")
                      : () => setDrawingMode("polygon")
                  }
                >
                  {!!drawingMode ? "Cancel" : "Draw"}
                  <img
                    width="25"
                    height="25"
                    src="https://img.icons8.com/ios-filled/25/polygon.png"
                    alt="polygon"
                  />
                </Button>
              </section>
              <DrawingManagerF
                onPolygonComplete={onPolygonComplete}
                options={{
                  drawingControl: false,
                  drawingMode,
                  polygonOptions,
                  circleOptions,
                }}
              />
            </>
          )}
        </GoogleMap>
      )}
    </div>
  );
});
export default PayrollActivityMap;
