import { getMonthsTimestamps, getRandomColor } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const violations = (IdHandler, updatedDynamicStates) => {
  return {
    amount_due: () => ({
      title: "Violations Amount",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        labels: months,
        table: "Violations",
        field: "amount_due",
        datasets: [
          {
            label: "My First Dataset",
            data: (() => {
              let violations = [];
              for (let i = 0; i < 12; i++) {
                violations = [
                  ...violations,
                  (() => {
                    const amount = (
                      updatedDynamicStates?.[IdHandler] || []
                    )?.filter(
                      (b) =>
                        b?.issue_date >= getMonthsTimestamps()[i] &&
                        b?.issue_date <= getMonthsTimestamps("end")[i]
                    );

                    return !!amount?.length
                      ? amount
                          ?.map((a) => a.amount_due)
                          ?.reduce(
                            (previousValue, currentValue) =>
                              parseInt(previousValue) + parseInt(currentValue)
                          )
                      : 0;
                  })(),
                ];
              }
              return violations;
            })(),

            backgroundColor: (() => {
              let colors = [];
              for (let i = 0; i < 12; i++) {
                colors = [...colors, getRandomColor()];
              }
              return colors;
            })(),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //amount_due
    issue_date: () => ({
      title: "Violations Issue Date",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        labels: months,
        table: "Violations",
        field: "issue_date",
        datasets: [
          {
            label: "My First Dataset",
            data: (() => {
              let violations = [];
              for (let i = 0; i < 12; i++) {
                violations = [
                  ...violations,
                  (updatedDynamicStates?.[IdHandler] || [])
                    ?.map((a) => a?.issue_date)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length,
                ];
              }
              return violations;
            })(),

            backgroundColor: (() => {
              let colors = [];
              for (let i = 0; i < 12; i++) {
                colors = [...colors, getRandomColor()];
              }
              return colors;
            })(),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //issue_date
  }; //Violations
};
