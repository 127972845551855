import { pdfHelpers } from "./utils";
import { getDateAndTime } from "../../components/SidebarPages/utils/getDateAndTime";
import { compareIncluding } from "../../components/SidebarPages/utils";
import { pdfTextFormatter } from "./pdfTextFormatter";

export default function getDocDefinition({
  params,
  gridApi,
  rowData,
  gridColumnApi,
  tableColumn,
  history,
  historyTab,
  base64,
  formatCellValueHandler,
  forExcelAndPdfAttachment = false,
  dataIsRowData = false,
}) {
  const getCompanyLogo = () => {
    return base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;
  };

  const companyLogo = getCompanyLogo();

  const {
    getColumnGroupsToExport,
    getColumnsToExport,
    getExportedColumnsWidths,
    getRowsToExport,
  } = pdfHelpers({
    params,
    gridApi,
    gridColumnApi,
    tableColumn,
    companyLogo,
    base64,
    history,
    formatCellValueHandler,
    forExcelAndPdfAttachment,
    ...(dataIsRowData ? { rowData } : {}),
  });

  const {
    PDF_HEADER_COLOR = "#B7C4CB",
    PDF_INNER_BORDER_COLOR = "#dde2eb",
    PDF_OUTER_BORDER_COLOR = "#babfc7",
    PDF_ODD_BKG_COLOR = "#fcfcfc",
    PDF_EVEN_BKG_COLOR = "#fff",
    PDF_HEADER_HEIGHT = 25,
    PDF_ROW_HEIGHT = 15,
    PDF_PAGE_ORITENTATION = "landscape",
    PDF_WITH_HEADER_IMAGE = true,
    PDF_WITH_FOOTER_PAGE_COUNT = true,
    PDF_LOGO = companyLogo,
  } = params;

  return (function () {
    const columnGroupsToExport = getColumnGroupsToExport();

    const columnsToExport = getColumnsToExport();

    const widths = getExportedColumnsWidths(columnsToExport);

    const rowsToExport = getRowsToExport(columnsToExport);

    const body = columnGroupsToExport
      ? [columnGroupsToExport, columnsToExport, ...rowsToExport]
      : [columnsToExport, ...rowsToExport];

    const headerRows = columnGroupsToExport ? 1 : 1;

    const header = PDF_WITH_HEADER_IMAGE
      ? {
          columns: [
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      image: "logo",
                      width: 150,
                      alignment: "center",
                      margin: [30, 0],
                    },
                  ],
                  [
                    {
                      columns: [
                        {
                          width: "*",
                          height: 20,
                          text: `Title: ${pdfTextFormatter(
                            history,
                            rowData,
                            params,
                            historyTab
                          )}`,
                          fontSize: 12,
                          bold: true,
                          alignment: "left",
                        },
                        {
                          width: "*",
                          height: 20,
                          text: getDateAndTime(),
                          fontSize: 10,
                          alignment: "right",
                        },
                      ],
                      margin: [20, 10],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
        }
      : null;

    const footer = PDF_WITH_FOOTER_PAGE_COUNT
      ? function (currentPage, pageCount) {
          return {
            text: "Page " + currentPage.toString() + " of " + pageCount,
            margin: [20, 0],
            alignment: "right",
            // background: '#EBEFF1',
          };
        }
      : null;

    const pageMargins = [
      20,
      PDF_WITH_HEADER_IMAGE ? 120 : 20,
      20,
      PDF_WITH_FOOTER_PAGE_COUNT ? 40 : 10,
    ];

    const heights = (rowIndex) =>
      rowIndex < headerRows ? PDF_HEADER_HEIGHT : PDF_ROW_HEIGHT;

    const fillColor = (rowIndex, node, columnIndex) => {
      if (rowIndex < node.table.headerRows) {
        return PDF_HEADER_COLOR;
      }
      return rowIndex % 2 === 0 ? PDF_ODD_BKG_COLOR : PDF_EVEN_BKG_COLOR;
    };

    const hLineWidth = (i, node) =>
      i === 0 || i === node.table.body.length ? 1 : 1;

    const vLineWidth = (i, node) =>
      i === 0 || i === node.table.widths.length ? 1 : 1;

    const hLineColor = (i, node) =>
      i === 0 || i === node.table.body.length
        ? PDF_OUTER_BORDER_COLOR
        : PDF_INNER_BORDER_COLOR;

    const vLineColor = (i, node) =>
      i === 0 || i === node.table.widths.length
        ? PDF_OUTER_BORDER_COLOR
        : PDF_INNER_BORDER_COLOR;

    const docDefintion = {
      pageOrientation: PDF_PAGE_ORITENTATION,
      pageMargins,
      header,
      footer,
      content: [
        {
          style: "myTable",
          table: {
            headerRows,
            widths,
            body,
            heights,
          },
          layout: {
            fillColor,
            hLineWidth,
            vLineWidth,
            hLineColor,
            vLineColor,
          },
        },
      ],
      images: {
        logo: PDF_LOGO,
      },
      styles: {
        myTable: {
          margin: [0, 0, 0, 0],
        },
        tableHeader: {
          bold: true,
          margin: [0, PDF_HEADER_HEIGHT / 3, 0, 0],
        },
        tableCell: {
          color: "#323338",
          // margin: [0, 0, 0, 0],
          alignment: "left",
        },
      },
    };

    //Send row to new page if not fit in the page
    docDefintion.content[0].table.dontBreakRows = true;
    return docDefintion;
  })();
}
