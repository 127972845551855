import { Modal } from "antd";
import { useEffect, useState } from "react";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import "./DetailsForDayModal.scss";
import { RichTextEditor } from "../../../../../../commonComponents";
import { updateDocumentTitle } from "../../../../../../../utils";
const DetailsForDayModal = ({
  openDetails,
  setOpenDetails,
  onChange,
  isDarkMode,
  disabled,
}) => {
  const [temp, setTemp] = useState(openDetails?.details?.description ?? "");

  return (
    <div>
      <Modal
        {...{
          className: `details-for-day-modal ${
            isDarkMode && "details-for-day-modal-dark"
          }`,
          title: `Details for ${dayjsNY(openDetails?.details?.startDate).format(
            "MM/DD/YYYY"
          )}`,
          afterOpenChange: (event) => {
            event &&
              updateDocumentTitle({
                newTitle: `Details for ${dayjsNY(
                  openDetails?.details?.startDate
                ).format("MM/DD/YYYY")}`,
              });
          },
          open: openDetails?.open,
          centered: true,
          closeIcon: <XIcon />,
          footer: null,
          onCancel: () => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            setOpenDetails({
              ...openDetails,
              open: false,
            });
          },
        }}
      >
        <RichTextEditor
          {...{
            initialValue: temp,
            onChange: (e) => {
              setTemp(e);
              onChange(e, openDetails?.details?.key, "description");
            },
            placeholder: "Add description...",
            disabled,
          }}
        />
      </Modal>
    </div>
  );
};

export default DetailsForDayModal;
