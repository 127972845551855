import { Button, Modal, Tooltip, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import "./ProgressAddons.scss";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { PercentageIcon } from "../../../../../SidebarPages/BasePage/src";
import { WarningIcon } from "../../../../../../assets";
const ProgressAddons = (props) => {
  const {
    progressAddons = {},
    scheduleDays = [],
    closeProgressAddonModal = () => {},
    updateProgressForServiceAddon = () => {},
    isDarkMode = false,
    editMode = false,
  } = props;
  const {
    visible = false,
    serviceId = "",
    serviceIndex = "",
    addonIndex = 0,
    addonName = "",
    progressByDay = [],
  } = progressAddons;

  const [progressByDayAddons, setProgressByDayAddons] = useState([]);
  const [allDays, setAllDays] = useState({});

  const selectedDays = (day) => {
    if (progressByDayAddons?.some((a) => a?.id === day?.id)) {
      setProgressByDayAddons((prev) => [
        ...prev?.filter((x) => x?.id !== day?.id),
      ]);
    } else {
      setProgressByDayAddons((prev) => [...prev, day]);
    }
  };

  const onChangeProgressAddon = (value, dayId = "") => {
    if (!editMode) {
      message.error("This is available in edit mode only!");
      return;
    }
    if (isNaN(value)) {
      message.error("Please enter a valid number");
      return;
    }
    setProgressByDayAddons((prev) =>
      prev?.map((el) => {
        if (el?.id === dayId) {
          return {
            day: el?.day,
            id: el?.id,
            progress: value,
          };
        }
        return el;
      })
    );
  };

  const onSaveAddons = () => {
    updateProgressForServiceAddon(
      serviceIndex,
      addonIndex,
      progressByDayAddons
    );
    closeProgressAddonModal(false);
    message.success("Progress updated successfully!");
  };

  let daysToPlis = progressByDayAddons?.filter((a) =>
    scheduleDays?.some((b) => b?.id === a?.id)
  );

  daysToPlis = daysToPlis?.sort((a, b) => a?.day - b?.day);

  useEffect(() => {
    if (!!visible) {
      setProgressByDayAddons(progressByDay || []);
    }
  }, [visible]);

  useEffect(() => {
    if (typeof allDays === "boolean") {
      if (allDays) {
        setProgressByDayAddons([
          ...scheduleDays?.map((el) => ({
            id: el?.id,
            startDate: el?.startDate,
            progress: 0,
            day: el?.day,
          })),
        ]);
      } else {
        setProgressByDayAddons([]);
      }
    }
  }, [allDays]);

  return (
    <div>
      <Modal
        title={addonName}
        open={visible}
        onCancel={() => closeProgressAddonModal(false)}
        centered
        closeIcon={<XIcon />}
        width={"fit-content"}
        className={`progressAddonsModal${isDarkMode ? "darkMode" : ""}`}
        maskClosable={false}
        footer={
          <>
            <MondayButton
              onClick={() => {
                closeProgressAddonModal(false);
              }}
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={() => onSaveAddons()}
              {...{
                Icon: <TickIcon width={17} height={17} />,
              }}
            >
              Save
            </MondayButton>
          </>
        }
      >
        <div className="progressAddonsModalContent">
          <div className="daysContainerShortcut">
            {scheduleDays?.map((el) => {
              return (
                <Tooltip
                  title={dayjsNY(el?.startDate)
                    .startOf("day")
                    .format("MM/DD/YYYY")}
                >
                  <Button
                    onClick={() => selectedDays(el)}
                    style={
                      progressByDayAddons?.some((q) => q?.id === el?.id)
                        ? {
                            backgroundColor: "#0f5c97",
                            color: "#fff",
                          }
                        : {}
                    }
                    className="shortcut-pliDays-buttons"
                  >
                    {"D" + el?.day}
                  </Button>
                </Tooltip>
              );
            })}
            <Button
              onClick={() => {
                if (typeof allDays === "boolean") {
                  setAllDays(!allDays);
                } else {
                  setAllDays(false);
                }
              }}
              className="allDaysShortcutPLIButton"
              style={
                scheduleDays?.every((el) =>
                  progressByDayAddons?.some((a) => a?.id === el?.id)
                )
                  ? {
                      background: "#0f5c97",
                      color: "#fff",
                    }
                  : {}
              }
            >
              All days
            </Button>
          </div>
          <div className="inputAddonsProgressContent">
            {daysToPlis?.map((el) => {
              return (
                <InputComponent
                  key={el?.id}
                  type="input"
                  prefix={"D" + el?.day}
                  value={el?.progress || 0}
                  suffixIcon={<PercentageIcon />}
                  placeholder={`Give progress here...`}
                  className="inputAddonsProgress"
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    onChangeProgressAddon(value, el?.id);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProgressAddons;
