import { forwardRef, useRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { Input } from "antd";

const RatesNumberEditor = forwardRef(({ value }, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const amount = useRef(Number(value) || 0);

  const allowedKeys = [
    "Backspace",
    "Shift",
    "ArrowRight",
    "ArrowLeft",
    "Control",
  ];

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return Number(amount.current) || 0;
        },
        isCancelBeforeStart() {
          return false;
        },
      };
    },
    []
  );

  return (
    <Input
      {...{
        type: "number",
        className: `gridInput ${isDarkMode && "gridInputDark"}`,
        onKeyDown(event) {
          let key = event.key;
          if (!/[0-9,.]/.test(key) && !allowedKeys.includes(key)) {
            event.preventDefault();
          }
        },
        onChange(event) {
          let value = Number(event.target.value);
          if (value >= 0) {
            amount.current = event?.target?.value || 0;
          } else {
            amount.current = 0;
          }
        },
        defaultValue: Number(value) || 0,
        autoFocus: true,
        style: { width: "100%", borderRadius: 0 },
      }}
    />
  );
});

export default RatesNumberEditor;
