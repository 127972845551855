import { useState } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

import { keys } from "../RolesData";
import { userGroups } from "../../../../../../actions";
import { DeleteConfirmModal } from "./DeleteConfirmModal";
import { showLoadingMsg, showSuccessMsg } from "../../../../../../utils";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { message } from "antd";
import { companyDetails } from "../../../../../../helpers/constants";

const RolesCard = ({
  role,
  index,
  tab,
  color,
  userGroupsData,
  departmentName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const dispatch = useDispatch();

  const allUsers = userConfiguration?.allUsers?.Items || {};

  const users =
    allUsers?.filter(
      (item) =>
        item.groupName === keys(role)[0] &&
        item.departmentName === departmentName
    ) || [];

  const deleteRole = async (roleName) => {
    const userGroupsToDelete = userGroupsData.find(
      (user) => user.groupName == roleName[0]
    );
    const newUserGroups = userGroupsData.filter(
      (user) => user.groupId !== userGroupsToDelete.groupId
    );

    await API.del("userGroups", `/userGroups/${userGroupsToDelete.groupId}`)
      .then(() => {
        dispatch(userGroups(newUserGroups));
        broadcastNotification(
          "64",
          `onDeleteRole`,
          [
            {
              common: userConfiguration.nameOfUser,
              commonNext: roleName[0],
            },
            {
              userName: userConfiguration.nameOfUser,
              currentUser: userConfiguration.cognitoUserId,
            },
          ],
          ""
        );
        setIsModalOpen(false);
        showSuccessMsg({
          content: "Role has been deleted successfully!",
          key: "delete",
        });
      })
      .catch((err) => {
        setIsModalOpen(false);
        message.error({
          content: "Something went wrong. Please try again!",
          key: "delete",
        });
        console.log("deleteRole ~ err", err);
      });
  };

  const handleDeleteRoleConfirm = () => {
    showLoadingMsg({ content: "Deleting role. Please wait...", key: "delete" });
    const roleName = keys(role);
    console.log("handleDeleteRoleConfirm ~ roleName", {
      roleName: roleName[0],
    });
    deleteRole(roleName);
  };

  const handleRoleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <>
      <div id={index === 0 ? "roleCard" : ""}>
        <div department={keys(role)} className="entryPageDepartmentName">
          <div className="header">
            <span className="title">{keys(role)}</span>
            {users.length < 1 &&
              tab === 7 &&
              keys(role)[0] !== "Super Admin" && (
                <DeleteOutlined
                  className="deleteButton"
                  onClick={handleRoleModal}
                />
              )}
          </div>
          <Link key={index} to={`${keys(role)}`} className="link">
            <div
              className="body"
              style={{
                backgroundColor: color,
              }}
            >
              <p className="number">{users.length}</p>
              <p className="text">{`${keys(role)} Users`}</p>
            </div>
          </Link>
        </div>
      </div>
      {isModalOpen && (
        <DeleteConfirmModal
          {...{
            isModalOpen,
            setIsModalOpen,
            modalType: "role",
            onCancel: handleRoleModal,
            onConfirm: handleDeleteRoleConfirm,
          }}
        />
      )}
    </>
  );
};

export default RolesCard;
