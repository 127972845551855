import React from "react";
import { Button, message } from "antd";
import Jump from "../../../../../../icons/DataGrid_Jump_Icon.webp";
import { roundTo2Digits } from "../../../../../pages/Settings/settingsComponents/Pricing/utils/validation";

/**
 * It is used when a sidewalk shed pli is a jump. It has a boolean showing that it is a jump and it span where people or cars can pass.
 * */
export default class JumpRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.node.data?.isJump?.checked || false,
      jumpWidth: props.node.data?.isJump?.jumpWidth,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleCheck = (checked) => {
    if (this.props.isWritable) {
      this.setState({ checked: checked });
      this.props.node.setDataValue(this.props.column.colId, {
        jumpWidth: this.state.jumpWidth,
        checked: checked,
      });
      // this.props.api.redrawRows({rowNodes: [this.props.node]})
      this.props.api.refreshCells({ rowNodes: [this.props.node] });

      if (checked === false)
        this.props.api.getRowNode(this.props.node.id).setExpanded(false); //close addons table
    } else {
      message.error("Please enable write mode to start editing.");
    }
  };

  handleJumpWidthChange = (e) => {
    if (this.props.isWritable) {
      let val = e.target.value;
      this.setState({ jumpWidth: val });
    } else {
      message.error("Please enable write mode to start editing.");
    }
  };

  onJumpWidthBlur = () => {
    if (this.props.isWritable) {
      let val = roundTo2Digits(this.state.jumpWidth);
      const PLILength = this.props.node.data.length;

      if (isNaN(val) || val <= 0 || !PLILength) {
        //if we have incorrect value
        message.warning(
          "The span width must be a positive number smaller than PLI Length."
        );
        this.setState({ jumpWidth: 0 });
        return;
      } else if (!isNaN(PLILength) && PLILength <= val) {
        message.warning(
          "The span width must be a smaller than total PLI length."
        );
        this.setState({ jumpWidth: PLILength - 1 >= 0 ? PLILength - 1 : 1 });
        return;
      }

      this.props.node.setDataValue(this.props.column.colId, {
        checked: this.state.checked,
        jumpWidth: val,
      });
      // this.props.api.redrawRows({rowNodes: [this.props.node]})
      this.props.api.refreshCells({ rowNodes: [this.props.node] });
    } else {
      message.error("Please enable write mode to start editing.");
    }
  };

  render() {
    const isChecked = this.state.checked;
    return (
      <div className={"jump-renderer"}>
        <Button
          className={
            "checkboxRenderer-" + this.props.column.colId + "-" + isChecked
          }
          // component="span"
          onClick={(e) => {
            this.handleCheck(!isChecked);
          }}
        >
          {isChecked ? <img src={Jump} alt={"jump"} /> : ""}
        </Button>
        <input
          className={"jump-input"}
          type="text"
          value={this.state.jumpWidth}
          placeholder="span"
          style={{ display: isChecked ? "block" : "none" }}
          onChange={this.handleJumpWidthChange}
          onBlur={this.onJumpWidthBlur}
        />
      </div>
    );
  }
}
