// import { API } from "aws-amplify";
import { fetchAllData } from "../../../../../utils";
import { userAccessRecords } from "../../../Dashboard/NewDashboardFunctions";
import { getRecordPrefix, getRecordSuffix } from ".";

/**
 * @param {String} value - related to category
 * @param {Array} records - current records if we have any
 * @param {String} recordId - record id of the record we are creating a to do for
 * @param {Object} topicObjects - object with all the related to categories and their api and filter data
 * @param {Array} relatedToOptions - all the related to options
 * @param {Function} setRecords - function to set the records
 * @param {Object} userConfiguration - user configuration object
 * @param {Function} setLoadingRecords - function to set the loading state
 * @returns {Array} - sets the records in the setRecords function
 */
async function getRelatedToRecords({
  value = "",
  records = [],
  recordId = null,
  topicObjects = {},
  relatedToOptions = [],
  setRecords = () => {},
  userConfiguration = {},
  setLoadingRecords = () => {},
}) {
  const categoryToUse = value === "Accounts" ? "Clients" : value;

  setLoadingRecords(true);
  const { filterData = {} } =
    relatedToOptions?.find((e) => e?.value === value) || {};

  if (!topicObjects[value]) {
    setLoadingRecords(false);
    return;
  }

  const { table, idKey, searchKey } = topicObjects[value] || {};

  if (records?.length) {
    setRecords([]);
  }

  /**
   * If the filterData includes is true we will fetch all the data and then filter it
   */
  if (filterData?.includes) {
    fetchAllData({
      endpoint: table,
      resultPosition: table,
      resultId: idKey,
      otherStringParams: {
        keysToInclude: JSON.stringify(
          [
            idKey,
            searchKey,
            "teamsConfiguration",
            "createdAt",
            "accountName",
            "estimationNumber",
            "scheduleName",
            filterData?.status || null,
            filterData?.filterId || null,
          ].filter(Boolean)
        ),
      },
    })
      ?.then((res) => {
        const {
          status = "",
          statusValue = "",
          label = "",
          labelSuffix = "",
        } = filterData;

        const dataToFilter = Array.isArray(res) ? res : res?.[table];

        let data = [];

        setLoadingRecords(false);
        /**
         * If we have a status and statusValue we will filter the data based on that
         */
        if (!!status?.length || !!statusValue?.length) {
          data = userAccessRecords(userConfiguration, dataToFilter)?.filter(
            (e) =>
              e?.[status] !== statusValue &&
              e?.[filterData?.filterId]?.includes(recordId)
          );
        } else {
          data = userAccessRecords(userConfiguration, dataToFilter)?.filter(
            (e) => e?.[filterData?.filterId]?.includes(recordId)
          );
        }
        /**
         * If we have a label or labelSuffix we will add that to the label
         */
        if (!!label?.length || !!labelSuffix?.length) {
          let prefix = label ? `${label} : ` : "";
          let suffix = labelSuffix ? ` - ${labelSuffix}` : "";
          setRecords(
            data?.map((e) => ({
              value: e?.[idKey],
              label: `${prefix} ${e?.[searchKey]} ${suffix}`,
            }))
          );
        } else {
          setRecords(
            data?.map((e) => ({
              value: e?.[idKey],
              label:
                getRecordPrefix(e, categoryToUse) +
                e?.[searchKey] +
                getRecordSuffix(e, categoryToUse),
            }))
          );
        }
      })
      ?.catch((err) => {
        console.error("Error fetching related to records", err);
      });
  } else {
    /**
     * If the filterData includes is false we will fetch the data based on the recordId
     */
    fetchAllData({
      endpoint: table,
      resultPosition: table,
      resultId: idKey,
      otherStringParams: {
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: filterData?.filterId,
                value: recordId,
                formula: "is",
              },
            ],
          },
        ]),
        keysToInclude: JSON.stringify(
          [
            idKey,
            searchKey,
            "teamsConfiguration",
            "createdAt",
            "accountName",
            "estimationNumber",
            "scheduleName",
            filterData?.status || null,
            filterData?.filterId || null,
          ].filter(Boolean)
        ),
      },
    })
      ?.then((res) => {
        const dataToFilter = Array.isArray(res) ? res : res?.[table];

        setLoadingRecords(false);
        const { status = "", statusValue = "", label = "" } = filterData;
        let data = [];

        /**
         * If we have a status and statusValue we will filter the data based on that
         */
        if (!!status?.length || !!statusValue?.length) {
          data = userAccessRecords(userConfiguration, dataToFilter)?.filter(
            (e) => e?.[status] !== statusValue
          );
        } else {
          data = userAccessRecords(userConfiguration, dataToFilter);
        }

        /**
         * If we have a label we will add that to the label
         */
        if (!!label?.length) {
          setRecords(
            data?.map((e) => ({
              value: e?.[idKey],
              label: `${label} : ${e?.[searchKey]}`,
            }))
          );
        } else {
          setRecords(
            data?.map((e) => ({
              value: e?.[idKey],
              label:
                getRecordPrefix(e, categoryToUse) +
                e?.[searchKey] +
                getRecordSuffix(e, categoryToUse),
            }))
          );
        }
      })
      ?.catch((err) => {
        console.error("Error fetching related to records", err);
      });
  }
}

export default getRelatedToRecords;
