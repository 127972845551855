import { API } from "aws-amplify";
import createNewTask from "../../../Incidents/Modals/utils/createNewTask";
import sendSafetyCreateNotifs from "./sendSafetyCreateNotifs";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { message } from "antd";

/**
 * Creates a safety record and performs additional actions based on the provided parameters.
 *
 * @param {Function} [dispatch=() => {}] - The dispatch function.
 * @param {string} [table=""] - The table name.
 * @param {string} [nameKey=""] - The name key.
 * @param {Object} [tmpBody={}] - The temporary body object.
 * @param {Array} loggedOnTasks - The logged on tasks array.
 * @param {string} [category=""] - The category.
 * @param {Object} [rowObject={}] - The row object.
 * @param {string} [incidentId=""] - The incident ID.
 * @param {Array} [assignedTo=[]] - The assigned to array.
 * @param {Object} [preferences={}] - The preferences object.
 * @param {string} [createAction=""] - The create action.
 * @param {boolean} [saveDraft=false] - Indicates whether to save the record as a draft.
 * @param {Array} [programFields=[]] - The program fields array.
 * @param {Function} handleSavedRecord - The handle saved record function.
 * @param {Object} [authenticatedUser={}] - The authenticated user object.
 * @param {Object} [userConfiguration={}] - The user configuration object.
 * @param {Function} [refreshTable=() => {}] - The refresh table function.
 * @param {Function} [hideProgress=() => {}] - The hide progress function.
 * @param {Function} [setAskTaskModal=() => {}] - The set ask task modal function.
 * @param {Function} [updateIncidentStatus=() => {}] - The update incident status function.
 * @param {Function} [updateProgressStatus=() => {}] - The update progress status function.
 * @param {Function} [setVisibleCreationProgress=() => {}] - The set visible creation progress function.
 * @param {Function} [saveAddedLogs=async () => {}] - The save added logs function.
 * @returns {Promise<Object>} A promise that resolves to the created safety record.
 */
async function createSafety({
  dispatch = () => {},
  table = "",
  nameKey = "",
  tmpBody = {},
  loggedOnTasks,
  category = "",
  rowObject = {},
  incidentId = "",
  assignedTo = [],
  preferences = {},
  createAction = "",
  saveDraft = false,
  programFields = [],
  handleSavedRecord,
  authenticatedUser = {},
  userConfiguration = {},
  refreshTable = () => {},
  hideProgress = () => {},
  setAskTaskModal = () => {},
  updateIncidentStatus = () => {},
  updateProgressStatus = () => {},
  setVisibleCreationProgress = () => {},
  saveAddedLogs = async () => {},
}) {
  const postCreationFieldOptions = programFields?.find(
    (prog) => prog.fieldName === "Automated Tasks"
  )?.fieldOptions;

  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });

  if (!postCreationFieldOptions?.Safety || saveDraft) {
    hideProgress("creatingTask");
  }

  // const result =
  await API.post("safety", `/safety`, {
    body: {
      ...tmpBody,
      safetyApplicationCategory: category,
      createdBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    .then(async (res) => {
      updateProgressStatus({ updatingRecord: "finished" });
      setVisibleCreationProgress(res); // save datas to generate logs in case of any fail
      // res?.safetyApplicationStatus !== "Draft" &&
      handleSavedRecord(res);
      if (postCreationFieldOptions?.Safety && !saveDraft) {
        updateProgressStatus({ creatingTask: "executing" });
        await createNewTask({
          category: "Safety",
          taskAssignedTo: assignedTo?.map((user) => ({
            cognitoUserId: user.cognitoUserId,
            nameOfUser: user.nameOfUser,
            userName: user?.userName,
          })),
          taskRelatedTo: res?.safetyName,
          taskTopicId: res?.safetyApplicationId,
          taskSubTopic: category,
          userConfiguration,
          calendarPreference: preferences?.calendarPreference,
          loggedOnTasks,
          dispatch,
        })
          .then(() => {
            updateProgressStatus({ creatingTask: "finished" });
          })
          .catch((err) => {
            console.error({ err });
            updateProgressStatus({ creatingTask: "hasError" });
          });
      }

      if (incidentId && table === "safety") {
        updateIncidentStatus([rowObject], "safety");
        await API.put("incidents", `/incidents/${incidentId}`, {
          body: {
            incidentStatus: "Proceeded to Safety",
          },
        }).catch((err) => console.error("Error", { err }));
      }
      refreshTable(res);

      if (!saveDraft) {
        setAskTaskModal(res);
      }
      // else {
      //   onClose();
      // }

      updateProgressStatus({ sendingNotification: "executing" });
      if (!saveDraft) {
        sendSafetyCreateNotifs({
          userConfiguration,
          authenticatedUser,
          res,
          createAction,
          category,
          tmpBody,
          table: "safety",
        }).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      } else {
        broadcastNotification(
          "42",
          "onSaveDraft",
          [
            { common: userConfiguration?.nameOfUser },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
            },
          ],
          res?.safetyApplicationId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      }

      await saveAddedLogs({
        recordId: res?.safetyApplicationId,
        recordName: res?.[nameKey],
        category,
        topic: res?.[nameKey],
      });

      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });
      return res;
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Error creating safety", err);
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
    });
  // return result;
}

export default createSafety;
