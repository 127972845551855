import { filterTables } from "../../../../../utils";
import { safetyExcelNoteParser } from ".";

/**
 * Exports safety data to Excel.
 *
 * @param {Object} gridApi - The grid API object.
 * @param {string} topicCategory - The topic category.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
const exportSafetyToExcel = async ({ gridApi, topicCategory }) => {
  const notes = await filterTables(
    "notes",
    "topicCategory",
    topicCategory
  )?.catch((err) => {
    console.error("Error Getting Data: ", err);
  });
  gridApi.exportDataAsExcel({
    processCellCallback(params) {
      const headerName = params?.column?.userProvidedColDef?.headerName;

      if (
        typeof headerName === "string" &&
        headerName.toLowerCase().includes("notes")
      ) {
        const recordNotes = notes?.filter(
          (el) => el?.recordId === params?.node?.data?.safetyApplicationId
        );

        if (!recordNotes?.length) return "No notes";
        const notesArr = [];
        let index = 0;

        recordNotes?.map((note) =>
          safetyExcelNoteParser(note, notesArr, index)
        );

        return JSON.stringify(notesArr.join("\n")).replace(/"/g, "");
      }
      const value = params?.value;

      return value === undefined ? "" : `${value}`;
    },
  });
};

export default exportSafetyToExcel;
