export const todos = ({ tables, programFields }) => {
  const todoStatus = (todoStatus) => {
    return (
      programFields
        ?.find((a) => a?.fieldName === "Status colors")
        ?.fieldOptions?.["To Do"]?.find(
          (b) => b?.statusName?.toLowerCase() === todoStatus?.toLowerCase()
        )?.statusColor || "#6D8299"
    );
  };

  return {
    todoStatus: () => ({
      statusData: tables?.["To Do"]?.map((a) => {
        return {
          label: a?.todoStatus,
          value: a?.todoName,
          color: todoStatus(a?.todoStatus),
          date: a?.createdAt,
        };
      }),
    }),

    todoCategory: () => ({
      statusData: tables?.["To Do"]?.map((a) => {
        return {
          label: a?.todoName,
          value: a?.todoCategory,
          color: todoStatus(a?.todoStatus),
          date: a.createdAt,
        };
      }),
    }),

    relatedTo: () => ({
      statusData: tables?.["To Do"]?.map((a) => {
        return {
          label: a?.todoName,
          value: a?.relatedTo,
          color: todoStatus(a?.todoStatus),
          date: a.createdAt,
        };
      }),
    }),

    todoUser: () => ({
      statusData: tables?.["To Do"]?.map((a) => {
        return {
          label: a?.todoName,
          value: a?.todoUser,
          color: todoStatus(a?.todoStatus),
          date: a.createdAt,
        };
      }),
    }),
  };
};
