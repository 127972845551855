import { generateWeeks } from "../../../../../../../../../../../../../utils";
import { message } from "antd";
import dayjs from "dayjs";
import { subtract } from "lodash";
import { checkIfServiceIsHoist } from "../../../../../../../../../../../Estimations/DataEntryGrid/models/Service";
import { forceToNumber } from "../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { parseInTz } from "../../../../../../../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { retrieveSchedulingProgress } from "../../../../../../Header/Components/MainHeader/SchedRentalTimeline/scheduleRentalConnection";
import { getDailyProgressFromScheduling } from "../../../../../../../../RentalsTab/NewRentals/Components/Footer/createRentalFromEstimation";
export const headerDateInputHandler = ({
  setUnchangingFakeRentals,
  selectedService,
  setFakeRentals,
  elevationData,
  fakeRentals,
  date,
}) => {
  // let dayjs = parseInTz();
  //if we have selected the start and end day we take the moment object and turn it to timestamp
  let daysPerMonth = 28;
  const startDate = !!date ? parseInTz(date[0]).valueOf() : undefined;
  const endDate = !!date ? parseInTz(date[1]).valueOf() : undefined;
  const isFullMonth =
    fakeRentals?.services?.find((serviceData) => {
      return serviceData?.label === selectedService;
    })?.rentalPaymentTerms?.valueDays === "monthly";

  //here we generates all the weeks and days that are between those 2 selected dates
  const details = generateWeeks(startDate, endDate) || [];

  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);

  //here we find how many days are between those 2 selected days
  const howManyDays = +(
    dayjs
      ?.duration(
        parseInTz(endDate)?.startOf("day")?.valueOf() -
          parseInTz(startDate)?.startOf("day")?.valueOf()
      )
      ?.asDays() + 1
  )?.toFixed(0);

  if (isFullMonth) {
    daysPerMonth = dayjs(parseInTz(startDate)).daysInMonth();
    // let firstMonth = parseInTz(date[0]).month();
    // let secondMonth = parseInTz(date[1]).month();
    // let checkSecondMonthIsAfter = secondMonth - firstMonth;

    // if (
    //   parseInTz(date[0]).format("DD") === parseInTz(date[1]).format("DD") &&
    //   checkSecondMonthIsAfter === 1
    // ) {
    //   message.error("One full month.");
    //   daysPerMonth = howManyDays;
    //   // return;
    // } else {
    //   message.error(
    //     "Not a full month, please uphold the Rental terms configured by Accounting Managers."
    //   );
    //   return;
    // }
  }
  console.log("daysPerMonth", daysPerMonth);

  //this is the new rental object that we will create
  const newFakeRental = {
    ...fakeRentals,
    //access only service key in object
    services: fakeRentals?.services?.map((serviceData) => {
      //check if this is the service that we have selected so we can modify it
      return serviceData?.label === selectedService
        ? {
            ...serviceData,
            serviceOptions: serviceData?.serviceOptions?.map((elevation, idx) =>
              //if this is the elevation that we are trying to modify, access and change it
              elevation?.elevationId === elevationData?.elevationId
                ? {
                    ...elevation,
                    items: elevation?.items?.map((pliData) => {
                      //we take the maxWeekly amount and with that we find the max daily amount
                      const dailyAmount = !checkIfServiceIsHoist(serviceData)
                        ? +(
                            forceToNumber(pliData?.rent) / daysPerMonth
                          )?.toFixed(2)
                        : +(
                            forceToNumber(pliData?.rentalsTotal) / daysPerMonth
                          )?.toFixed(2);
                      console.log("dailyAmountDays", {
                        dailyAmount,
                        daysPerMonth,
                      });

                      let tempDetails = getDetailsRentDaily({
                        details,
                        rent: !checkIfServiceIsHoist(serviceData)
                          ? forceToNumber(pliData?.rent)
                          : forceToNumber(pliData?.rentalsTotal),
                        dailyAmount,
                        howManyDays,
                        lastOne: idx === elevation?.items?.length - 1,
                        isFullMonth,
                        startDate,
                        endDate,
                      });
                      let tempApplied = Array.isArray(tempDetails)
                        ? tempDetails?.reduce((acc, curr) => {
                            return (
                              acc +
                              curr?.days?.reduce((acc2, curr2) => {
                                return acc2 + forceToNumber(curr2?.dailyAmount);
                              }, 0)
                            );
                          }, 0)
                        : 0;
                      return !pliData?.charged
                        ? //if this pli is not locked
                          !pliData?.lock
                          ? {
                              ...pliData,
                              applied: false,
                              maxWeeklyAmount: pliData?.rent / 4,
                              //here we set the pli amount equal to the daily amount times the number of days
                              // appliedAmount: forceToNumber(
                              //   dailyAmount * howManyDays
                              // ),
                              appliedAmount: forceToNumber(
                                tempApplied?.toFixed(2) || 0
                              ),
                              rentalDetails: {
                                ...pliData?.rentalDetails,
                                endDate,
                                startDate,
                                details: tempDetails,
                                // details: getDetailsRentDaily({
                                //   details,
                                //   rent: !checkIfServiceIsHoist(serviceData)
                                //     ? forceToNumber(pliData?.rent)
                                //     : forceToNumber(pliData?.rentalsTotal),
                                //   dailyAmount,
                                //   howManyDays,
                                //   lastOne: idx === elevation?.items?.length - 1,
                                // }),
                                // details?.map((detailsData) => ({
                                //   ...detailsData,
                                //   charged: false,
                                //   //here we set the weekly amount equal to the daily amount times the number of days in this week
                                //   weeklyAmount: dailyAmount * 7,
                                //   lock: false,
                                //   applied: false,
                                //   days: detailsData?.days?.map((daysData) => ({
                                //     ...daysData,
                                //     applied: false,
                                //     charged: false,
                                //     lock: false,
                                //     dailyAmount: dailyAmount,
                                //   })),
                                // })),
                              },
                            }
                          : //if this pli is locked we returns t unchanged
                            pliData
                        : //if this is not the pli that we are trying to modify, return it unchanged
                          pliData;
                    }),
                  }
                : //if this is not the elevation that we are trying to modify, return it unchanged
                  elevation
            ),
          }
        : //if this is not the selected service return it unchanged
          serviceData;
    }),
  };

  // here we save the newly created rental to context
  setFakeRentals(newFakeRental);
  setUnchangingFakeRentals(newFakeRental);
};

//this function tell the user why the date input is disabled
export const getDisabledTooltipContent = ({
  selectedService,
  elevationData,
  fakeRentals,
  isWritable,
}) => {
  //those are ll the pli's in the selected service,in the elevation that the input is on
  const data = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === elevationData?.elevationId
    )?.items;

  //the case where write mode is off
  return !isWritable
    ? "Enable write mode to edit!"
    : //the case where all pli are charged
    data?.every(({ charged }) => !!charged)
    ? "All pli are charged you cant change anything!!"
    : //the case where all pli are locked
    data?.every(({ lock }) => !!lock)
    ? "All pli are locked you cant change anything!!"
    : //the case where all pli are charged or locked
    data?.every(({ lock, charged }) => !!lock || !!charged)
    ? "All pli are charged or locked you cant change anything!!"
    : //the case where non of those above are true
      "";
};

//TODO: NOT DONE YET
export const multiHeaderDateInputHandler = async ({
  setUnchangingFakeRentals,
  selectedService,
  setFakeRentals,
  elevationData,
  fakeRentals,
  date,
  fromSchedule = true,
}) => {
  // let dayjs = parseInTz();
  let daysPerMonth = 28;

  //if we have selected the start and end day we take the moment object and turn it to timestamp
  const startDate = !!date ? parseInTz(date[0])?.valueOf() : undefined;
  const endDate = !!date ? parseInTz(date[1])?.valueOf() : undefined;

  //here we generates all the weeks and days that are between those 2 selected dates
  const details = generateWeeks(startDate, endDate) || [];

  const serviceDetail = fakeRentals?.services?.find((serviceData) => {
    return serviceData?.label === selectedService;
  });
  let isFullMonth = serviceDetail?.rentalPaymentTerms?.valueDays === "monthly";
  // const scheduleProgress = getDailyProgressFromScheduling({
  //   // rentals,
  //   // quickbooksNumber,
  //   estimationId: fakeRentals?.estimationId,
  //   serviceData: serviceDetail,
  //   isFullMonth,
  //   startDate,
  //   endDate,
  // });
  let extractedSchedulingData = await retrieveSchedulingProgress({
    startDate,
    endDate,
    projectId: fakeRentals?.projectId,
    serviceId: serviceDetail?.serviceId,
    elevationId: elevationData?.elevationId,
  });
  console.log(
    "extractedSchedulingData",
    extractedSchedulingData,
    fakeRentals?.projectId,
    serviceDetail
  );
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);

  //here we find how many days are between those 2 selected days
  let howManyDays = +dayjs
    ?.duration(parseInTz(endDate)?.valueOf() - parseInTz(startDate)?.valueOf())
    ?.asDays()
    ?.toFixed(0);
  howManyDays = howManyDays + 1;

  if (isFullMonth) {
    daysPerMonth = dayjs(parseInTz(startDate)).daysInMonth();
  }

  console.log("howManyDays", { howManyDays, startDate, endDate });

  const newFakeRental = {
    ...fakeRentals,
    services: await Promise.all(
      fakeRentals?.services?.map(async (serviceData) => {
        if (serviceData?.label !== selectedService) {
          return serviceData;
        } else if (!fromSchedule) {
          return {
            ...serviceData,
            serviceOptions: serviceData?.serviceOptions?.map((elevation) => {
              if (
                !elevationData?.find(
                  (elData) => elData?.elevationId === elevation?.elevationId
                )
              ) {
                return elevation;
              }
              let trackRent = 0;
              return {
                ...elevation,
                items: elevation?.items?.map((pliData, idx) => {
                  let dailyAmount = !checkIfServiceIsHoist(serviceData)
                    ? +forceToNumber(pliData?.rent / daysPerMonth)
                    : +forceToNumber(pliData?.rentalsTotal / daysPerMonth);

                  // let extractedPliSchedule =
                  //   extractedSchedulingData?.[pliData?.id] ?? false;
                  // if (extractedPliSchedule) {
                  //   const rateMultiplier = extractedPliSchedule / 100;
                  //   dailyAmount = dailyAmount * rateMultiplier;
                  //   pliData.totalProgress = rateMultiplier;
                  //   pliData.comesFromScheduling = true;
                  // } else {
                  //   pliData.totalProgress = 1;
                  //   pliData.comesFromScheduling = false;
                  // }
                  pliData.totalProgress = 1;
                  pliData.comesFromScheduling = false;
                  trackRent += forceToNumber(pliData?.rent);
                  let tempRent = !checkIfServiceIsHoist(serviceData)
                    ? forceToNumber(pliData?.rent)
                    : forceToNumber(pliData?.rentalsTotal);

                  let tempDetails = getDetailsRentDaily({
                    details,
                    rent: tempRent * pliData?.totalProgress,
                    dailyAmount,
                    howManyDays,
                    lastOne: idx === elevation?.items?.length - 1,
                    startDate,
                    endDate,
                    isFullMonth,
                  });
                  let tempApplied = Array.isArray(tempDetails)
                    ? tempDetails?.reduce((acc, curr) => {
                        return (
                          acc +
                          curr?.days?.reduce((acc2, curr2) => {
                            return acc2 + forceToNumber(curr2?.dailyAmount);
                          }, 0)
                        );
                      }, 0)
                    : 0;
                  return !pliData?.charged
                    ? !pliData?.lock
                      ? {
                          ...pliData,
                          applied: false,
                          maxWeeklyAmount: pliData?.rent / 4,
                          appliedAmount: forceToNumber(
                            tempApplied?.toFixed(2) || 0
                          ),
                          rentalDetails: {
                            ...pliData?.rentalDetails,
                            endDate,
                            startDate,
                            details: tempDetails,
                          },
                        }
                      : pliData
                    : pliData;
                }),
              };
            }),
          };
        } else {
          // fillTheDatesBetween(extractedSchedulingData);
          const prim = await getDailyProgressFromScheduling({
            estimationId: fakeRentals?.estimationId,
            serviceData: serviceDetail,
            isFullMonth,
            startDate,
            endDate,
            toSetService: serviceData,
          });
          return prim;
        }
      })
    ),
  };

  setFakeRentals(newFakeRental);
  setUnchangingFakeRentals(newFakeRental);
};

export const getDetailsRentDaily = ({
  details,
  rent = 0,
  dailyAmount,
  lastOne,
  howManyDays,
  isFullMonth,
  startDate,
  endDate,
}) => {
  let howManyWeeks = howManyDays / 7;
  let weeklyAmount = rent / 4;
  let carriedBalance = isFullMonth
    ? dailyAmount * howManyDays
    : howManyWeeks * weeklyAmount;
  // if (isFullMonth) {
  //   let firstMonth = parseInTz(startDate).month();
  //   let secondMonth = parseInTz(endDate).month();
  //   let checkSecondMonthIsAfter = secondMonth - firstMonth;

  //   if (
  //     parseInTz(startDate).format("DD") === parseInTz(endDate).format("DD") &&
  //     checkSecondMonthIsAfter === 1
  //   ) {
  //     carriedBalance = rent;
  //   }

  // }
  // console.log("getDetailsRentDaily", {
  //   dailyAmount,
  // });
  const res = details?.map((detailsData, id) => {
    return {
      ...detailsData,
      charged: false,
      //here we set the weekly amount equal to the daily amount times the number of days in this week
      weeklyAmount: dailyAmount * 7,
      lock: false,
      applied: false,
      days: detailsData?.days?.map((daysData, idx) => {
        //here we set the daily amount equal to the daily amount times the number of days in this week
        // console.log("tracking", {
        //   idx: idx,
        //   id: id,
        //   lastOne: lastOne,
        //   idxLength: detailsData?.days?.length,
        //   idLength: details.length,
        //   carriedBalance: carriedBalance,
        // });
        if (
          idx === detailsData?.days?.length - 1 &&
          id === details.length - 1
          //  &&
          // lastOne
        ) {
          // message.info(
          //   "This is the auto distributed carriedBalance on the last day: " +
          //     Number(carriedBalance || 0).toFixed(2)
          // );
          const temp = {
            ...daysData,
            applied: false,
            charged: false,
            lock: false,
            dailyAmount: carriedBalance,
          };
          carriedBalance = 0;
          return temp;
        } else {
          carriedBalance = subtract(carriedBalance, dailyAmount || 0);
          return {
            ...daysData,
            applied: false,
            charged: false,
            lock: false,
            dailyAmount: dailyAmount,
          };
        }
      }),
    };
  });
  return res;
};
export const getDetailsRentDailyOld = ({
  details,
  rent = 0,
  dailyAmount,
  lastOne,
  howManyDays,
  isFullMonth,
  startDate,
  endDate,
}) => {
  let howManyWeeks = howManyDays / 7;
  let weeklyAmount = rent / 4;
  let carriedBalance = isFullMonth
    ? dailyAmount * howManyDays
    : howManyWeeks * weeklyAmount;
  // if (isFullMonth) {
  //   let firstMonth = parseInTz(startDate).month();
  //   let secondMonth = parseInTz(endDate).month();
  //   let checkSecondMonthIsAfter = secondMonth - firstMonth;

  //   if (
  //     parseInTz(startDate).format("DD") === parseInTz(endDate).format("DD") &&
  //     checkSecondMonthIsAfter === 1
  //   ) {
  //     carriedBalance = rent;
  //   }
  // }
  // console.log("getDetailsRentDaily", {
  //   dailyAmount,
  // });
  const res = details?.map((detailsData, id) => {
    return {
      ...detailsData,
      charged: false,
      //here we set the weekly amount equal to the daily amount times the number of days in this week
      weeklyAmount: dailyAmount * 7,
      lock: false,
      applied: false,
      days: detailsData?.days?.map((daysData, idx) => {
        //here we set the daily amount equal to the daily amount times the number of days in this week
        // console.log("tracking", {
        //   idx: idx,
        //   id: id,
        //   lastOne: lastOne,
        //   idxLength: detailsData?.days?.length,
        //   idLength: details.length,
        //   carriedBalance: carriedBalance,
        // });
        if (
          idx === detailsData?.days?.length - 1 &&
          id === details.length - 1
          //  &&
          // lastOne
        ) {
          // message.info(
          //   "This is the auto distributed carriedBalance on the last day: " +
          //     Number(carriedBalance || 0).toFixed(2)
          // );
          const temp = {
            ...daysData,
            applied: false,
            charged: false,
            lock: false,
            dailyAmount: carriedBalance,
          };
          carriedBalance = 0;
          return temp;
        } else {
          carriedBalance = subtract(carriedBalance, dailyAmount || 0);
          return {
            ...daysData,
            applied: false,
            charged: false,
            lock: false,
            dailyAmount: dailyAmount,
          };
        }
      }),
    };
  });
  return res;
};
