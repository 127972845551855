import { getFence } from "./filterAlertsOnTruck";
import loadLivePreference from "./loadLivePreference";
import { ONE_DAY, TWO_DAYS, ONE_HOUR } from "./getTripsFromActualActivities";
import { getYards } from "../../Dispatch/modals/NewDispatchModal/utils/routeFields";
import {
  dayjsNY,
  getOffsetTz,
} from "src/components/DateComponents/contants/DayjsNY";

/**
 * Function used to get all the selected checkboxes from the
 * actual activities card. We used the checked locations to
 * create default data for a new dispatch
 */
export function getAllChecked() {
  let locationInfo = [];
  let allCheckboxes = document.getElementsByClassName("actual-location-check");
  for (let i = 0; i < allCheckboxes?.length; i++) {
    let checkbox = allCheckboxes.item(i).querySelector("input");
    if (checkbox?.checked) {
      let splitClass = allCheckboxes.item(i).className.split("--");
      let location = "";
      let time = "";
      for (const str of splitClass) {
        if (str.startsWith("FENCE:")) {
          location = str.replace("FENCE:", "");
        } else if (str.startsWith("TIME:")) {
          time = str.replace("TIME:", "");
        }
      }
      if (location && time) {
        if (!locationInfo?.length) {
          locationInfo.push({
            pickUpLocation: getYards()?.[0],
            dropOffLocation: location,
            arriveBy: time,
          });
        } else {
          locationInfo.push({
            pickUpLocation:
              locationInfo[locationInfo?.length - 1]["dropOffLocation"],
            dropOffLocation: location,
            arriveBy: time,
          });
        }
      }
    }
  }

  if (locationInfo?.length) {
    locationInfo.push({
      pickUpLocation: locationInfo[locationInfo?.length - 1]["dropOffLocation"],
      dropOffLocation: getYards()?.[0],
      departAt: dayjsNY(locationInfo[locationInfo?.length - 1]["arriveBy"])
        .add(1, "hour")
        .format(),
    });
  }

  return locationInfo;
}

/**
 * Function that adds the checked locations to the fetched dispatch
 */
export function mergeDispatches(originalRoutes = []) {
  let checkedRoutes = getAllChecked();
  checkedRoutes.pop();

  if (!checkedRoutes?.length) {
    return originalRoutes;
  }

  let dispatchRoutes = structuredClone(originalRoutes);
  let returnRoute = dispatchRoutes.pop();

  let mergedRoutes = [...checkedRoutes, ...dispatchRoutes];
  mergedRoutes.sort(
    (a, b) => dayjsNY(a?.arriveBy).valueOf() - dayjsNY(b?.arriveBy).valueOf()
  );

  mergedRoutes.forEach((route, index) => {
    if (index) {
      route["pickUpLocation"] = mergedRoutes[index - 1]["dropOffLocation"];
    }
  });

  mergedRoutes = mergedRoutes.filter(
    ({ pickUpLocation, dropOffLocation }) =>
      getFence(pickUpLocation) !== getFence(dropOffLocation)
  );

  returnRoute.pickUpLocation =
    mergedRoutes[mergedRoutes.length - 1]["dropOffLocation"];

  let finalRoutes = [...mergedRoutes, returnRoute];

  let suggestionRoute = finalRoutes?.findIndex(({ departAt }) => !departAt);

  if (suggestionRoute > -1) {
    for (let index = suggestionRoute; index < finalRoutes?.length; index++) {
      if (
        index !== finalRoutes?.length - 1 &&
        !finalRoutes[index]?.["driverId"]
      ) {
        finalRoutes[index]["driverId"] = finalRoutes[index + 1]["driverId"];
        finalRoutes[index]["driverName"] = finalRoutes[index + 1]["driverName"];
        finalRoutes[index]["driverSub"] = finalRoutes[index + 1]["driverSub"];
      }

      if (index > suggestionRoute) {
        finalRoutes[index]["departAt"] = null;
        finalRoutes[index]["arriveBy"] = null;
        finalRoutes[index]["timeExit"] = null;
      }
    }
  }

  return finalRoutes;
}

/**
 * Function that finds the smallest and greatest audit date for a vehicle
 * @param {object} alertChangesForTruck The changes for the alerts
 * @param {dayjs.Dayjs} selectedDate The selected date
 * @param {Boolean} forceCheck Make the function check every alert
 */
export function findAuditDateExtremities(
  alertChangesForTruck = {},
  forceCheck = false
) {
  /**
   * The point of the function is to find the maximum date-span
   * needed to get the correct audit data when opening a vehicle
   */
  let minDate = loadLivePreference("liveSelectedDate");
  let maxDate = minDate;
  for (const alertId in alertChangesForTruck) {
    if (alertChangesForTruck[alertId]?.["audited"] || forceCheck) {
      let alertDateTime = dayjsNY(
        alertChangesForTruck[alertId]["alertDateTime"]
      )
        .startOf("D")
        .valueOf();
      if (alertDateTime > maxDate) {
        maxDate = alertDateTime;
      } else if (alertDateTime < minDate) {
        minDate = alertDateTime;
      }
    }
  }
  return { minDate, maxDate };
}

/**
 * Function that splits a timeline of an audit into pieces of 48 hours
 * @param {{minDate: number, maxDate: number }} [config]
 */
export function splitTimelineCalls(config = {}) {
  /**
   * The point is to create a mappable array of the maximum
   * time span allowed by linxup, to make requests easier
   */
  const {
    minDate = dayjsNY(loadLivePreference("liveSelectedDate") || undefined)
      .startOf("D")
      .valueOf(),
    maxDate = dayjsNY(loadLivePreference("liveSelectedDate") || undefined)
      .startOf("D")
      .valueOf(),
  } = config;

  let res = [];
  if (minDate === maxDate) {
    let maxEndDay = dayjsNY(maxDate).endOf("D").valueOf();
    res.push({
      fromDate: minDate,
      toDate: maxEndDay,
    });
  } else {
    let dayDiff = dayjsNY(maxDate - 1).diff(dayjsNY(minDate), "day");
    let incrementor = TWO_DAYS;

    for (let i = minDate; i < maxDate - 1; i += incrementor) {
      incrementor = TWO_DAYS;

      const startOffset = getOffsetTz(i);
      const endOffset = getOffsetTz(i + incrementor);

      if (startOffset !== endOffset) {
        if (startOffset === "Etc/GMT+4") {
          incrementor = incrementor + ONE_HOUR;
        } else {
          incrementor = incrementor - ONE_HOUR;
        }
      }

      res.push({
        fromDate: i,
        toDate: i + incrementor,
      });
    }

    if (dayDiff % 2 === 1) {
      res.push({
        fromDate: maxDate,
        toDate: maxDate + ONE_DAY,
      });
    }
  }

  return res;
}

/**
 * Function that returns all the individual dates for the range
 */
export function getAllDatesInRange(minDate, maxDate) {
  let selectedDate = loadLivePreference("liveSelectedDate");
  let min = dayjsNY(minDate || selectedDate)
    .startOf("D")
    .valueOf();
  let max = dayjsNY(maxDate || selectedDate)
    .startOf("D")
    .valueOf();

  let range = [];

  if (min !== max) {
    for (let i = min; i <= maxDate; i += ONE_DAY) {
      let dateToPush = dayjsNY(i).startOf("D");
      if (dateToPush.valueOf() !== selectedDate) {
        range.push(dateToPush.format());
      }
    }
  }

  return range;
}

/**
 * Function that merges the audits for the selected date
 * and all the other audits for the fetched dates
 */
export function getAllAuditsForVehicle({
  auditsForDate,
  globalAudits,
  selectedVehicle,
}) {
  let auditsForTruck = {
    audited: [
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.["audited"] || []),
    ],
    changed: {
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.["changed"] || {}),
    },
    discarded: [
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.["discarded"] ||
        []),
    ],
    discardedStops: [
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.[
        "discardedStops"
      ] || []),
    ],
    linkedStops: [
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.["linkedStops"] ||
        []),
    ],
    stopReasons: [
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.["stopReasons"] ||
        []),
    ],
    timeChanges: {
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.["timeChanges"] ||
        {}),
    },
    createdAlerts: {
      ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.[
        "createdAlerts"
      ] || {}),
    },
  };

  for (const date in auditsForDate) {
    let audit = auditsForDate?.[date]?.["alertChanges"]?.[selectedVehicle];
    if (audit) {
      auditsForTruck["audited"] = [
        ...auditsForTruck["audited"],
        ...(audit?.["audited"] || []),
      ];
      auditsForTruck["discarded"] = [
        ...auditsForTruck["discarded"],
        ...(audit?.["discarded"] || []),
      ];
      auditsForTruck["changed"] = {
        ...auditsForTruck["changed"],
        ...(audit?.["changed"] || {}),
      };
      auditsForTruck["discardedStops"] = _.uniqBy(
        [
          ...auditsForTruck["discardedStops"],
          ...(audit?.["discardedStops"] || []),
        ],
        "beginDate"
      );
      auditsForTruck["linkedStops"] = _.uniqBy(
        [...auditsForTruck["linkedStops"], ...(audit?.["linkedStops"] || [])],
        "beginUUID"
      );
      auditsForTruck["stopReasons"] = [
        ...auditsForTruck["stopReasons"],
        ...(audit?.["stopReasons"] || []),
      ];
      auditsForTruck["timeChanges"] = {
        ...auditsForTruck["timeChanges"],
        ...(audit?.["timeChanges"] || []),
      };
      auditsForTruck["createdAlerts"] = {
        ...auditsForTruck["createdAlerts"],
        ...(audit?.["createdAlerts"] || []),
      };
    }
  }

  return auditsForTruck;
}
