import React, { useEffect, useMemo, useState } from "react";
import "./ForemanProject.scss";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import DayForemanView from "./DayForemanView/DayForemanView";
import { updateDocumentTitle } from "../../../../../utils";
import { isEmpty } from "lodash";
import {
  dayDetail,
  upperCaseFirstLetter,
} from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { useNavigate } from "react-router-dom";
import { CardTemplate } from "../../../Settings/settingsComponents/Management/EditUser/components";
import ScrollableContainer from "./ScrollableContainer/ScrollableContainer";
import { SmallLoadableComp } from "../../../../Sidebars/components";
import useTranslate from "../../Language/useTranslate";
import { LoadingDots } from "../../../../commonComponents/3LoadingDots/LoadingDots";
import getWeatherIcon from "../../../../Header/forms/Scheduling/helpers/getWeatherIcon";
import { FloatButton, message } from "antd";
import { LeftArrow } from "../../../../SidebarPages/BasePage/src";
import getForemanDocuments from "./getForemanDocuments";
import { MultiLevelTreeLogs } from "../../../../commonComponents";

const ForemanProject = ({ selectedProject: foremanProject, loading }) => {
  const navigate = useNavigate();
  const { t, language } = useTranslate();

  const {
    projectAddress = "",
    accountName = "",
    projectLatitude: lat = "",
    projectLongitude: lng = "",
  } = foremanProject || {};

  const [selectedDay, setSelectedDay] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [weatherLoading, setWeatherLoading] = useState(false);
  const [foremanDocuments, setForemanDocuments] = useState({});
  const [logsData, setLogsData] = useState([]);
  const [openLogs, setOpenLogs] = useState(false);

  const foremanProgressColor = (services) => {
    const servicesProgress = services
      ?.flatMap((ser) => ser?.services)
      ?.map(({ serviceProgress = 0 }) => serviceProgress);
    let color = "";
    if (servicesProgress?.length > 0) {
      if (servicesProgress?.every((progress) => progress === 100)) {
        color = "#D5F1C9"; //green
      } else if (servicesProgress?.every((progress) => progress === 0)) {
        color = "#FDE5E4"; //red
      } else if (servicesProgress?.some((progress) => progress > 0)) {
        color = "#FFECB8"; //yellow
      }
    }
    return color;
  };

  async function handleWeather(schedules = []) {
    let toReturn = schedules;
    if (lat && lng) {
      try {
        let response = await fetch(
          `https://api.weather.gov/points/${lat},${lng}`
        );

        let data = !!response ? await response.json() : {};

        let weatherObject = await fetch(data?.properties?.forecast);
        weatherObject = await weatherObject.json();

        const fullWeatherArray = weatherObject?.properties?.periods;

        toReturn = schedules.map((schedule) => {
          let { days } = schedule;
          let newDays = [];
          newDays = days.map((day) => {
            const { startDate } = day;
            const dayWeather = fullWeatherArray?.find((weather) =>
              dayjsNY(weather?.startTime)?.isSame(dayjsNY(startDate), "day")
            );
            return {
              ...day,
              weather: dayWeather,
            };
          });
          return { ...schedule, days: newDays };
        });
      } catch (err) {
        console.error("forecast error", err);
        return schedules;
      }
    } else {
      console.error("No lat and lng for weather data");
      return schedules;
    }
    return toReturn;
  }
  useEffect(() => {
    if (!isEmpty(foremanProject)) {
      updateDocumentTitle({ newTitle: "Foreman Project" });
      setWeatherLoading(true);
      handleWeather(foremanProject?.schedules).then(
        async (schedulesWithWeather) => {
          const foremanDocuments = await getForemanDocuments({
            projectId: foremanProject?.projectId,
          });
          setForemanDocuments(foremanDocuments);
          setSchedules(schedulesWithWeather);
          setWeatherLoading(false);
          (schedulesWithWeather || [])?.map((schedule) => {
            schedule?.days?.map((day, i) => {
              const isToday = dayjsNY(day?.startDate).isSame(dayjsNY(), "day");
              if (isToday) {
                const dayInfo = dayDetail(
                  schedule?.toBeScheduled,
                  [day?.dayId],
                  true
                )[day?.dayId];
                setSelectedDay({
                  ...day,
                  details: dayInfo,
                  toBeUpdate: schedule?.est,
                  typeOfWork: schedule?.typeOfWork,
                  googleDriveFolderIds: schedule?.googleDriveFolderIds,
                  scheduleDays: schedule?.scheduleDays,
                  scheduleId: schedule?.scheduleId,
                  scheduleName: schedule?.scheduleName,
                  projectId: foremanProject?.projectId,
                });
              }
            });
          });
        }
      );
    }
  }, [foremanProject?.projectId]);

  const title = useMemo(() => {
    return loading ? (
      <LoadingDots />
    ) : (
      <>{projectAddress + " - " + accountName}</>
    );
  }, [loading, foremanProject]);

  return (
    <CardTemplate
      {...{
        title,
        isDarkMode: false,
        className: "mobile-dashboard-table-container foremanProjectView",
      }}
    >
      <span className="backToProject" onClick={() => navigate("/field")}>
        {t("Back to Projects")}
      </span>
      <SmallLoadableComp loading={loading || weatherLoading}>
        <div className="foremanProjectView-header">
          {schedules?.length > 0 ? (
            <ScrollableContainer
              {...{
                className: "foremanProjectView-header-slider",
                bodyClassName: "foremanProjectView-slider-body",
                // transparentArrows: true,
              }}
            >
              {schedules?.map((schedule) => {
                return schedule?.days
                  ?.sort(
                    (a, b) => new Date(a?.startDate) - new Date(b?.startDate)
                  )
                  ?.map((day, i) => {
                    const dayInfo = dayDetail(
                      schedule?.toBeScheduled,
                      [day?.dayId],
                      true
                    )?.[day?.dayId];

                    const weatherIconSrc = getWeatherIcon(
                      day?.weather?.shortForecast
                    );

                    return (
                      <div
                        key={day?.dayId}
                        style={{
                          backgroundColor:
                            day?.dayId !== selectedDay?.dayId &&
                            foremanProgressColor(dayInfo),
                        }}
                        className={`foremanProjectView-slider-body-day ${
                          day?.dayId === selectedDay?.dayId && "selected"
                        }`}
                        onClick={() =>
                          setSelectedDay({
                            ...day,
                            details: dayInfo,
                            toBeUpdate: schedule?.est,
                            typeOfWork: schedule?.typeOfWork,
                            googleDriveFolderIds:
                              schedule?.googleDriveFolderIds,
                            scheduleDays: schedule?.scheduleDays,
                            scheduleId: schedule?.scheduleId,
                            scheduleAddress: projectAddress,
                            scheduleTeam: schedule?.teamConfiguration,
                            projectId: foremanProject?.projectId,
                          })
                        }
                      >
                        <span className="day-number">
                          {upperCaseFirstLetter(
                            new Date(day?.startDate).toLocaleDateString(
                              language === "es" ? "es-ES" : "en-US",
                              {
                                weekday: "long",
                              }
                            )
                          )}
                        </span>
                        {day?.weather &&
                          weatherIconSrc !== "No icon for this usecase" && (
                            <img
                              style={{ width: 25, height: 25 }}
                              src={weatherIconSrc}
                              alt="..."
                            />
                          )}

                        <span className="day-date">
                          {dayjsNY(day?.startDate).format("MM/DD/YYYY")}
                        </span>
                      </div>
                    );
                  });
              })}
            </ScrollableContainer>
          ) : (
            <div className="no-schedule-found">{t("No schedules found")}</div>
          )}
        </div>
        {Object.keys(selectedDay || {}).length > 0 ? (
          <DayForemanView {...{ selectedDay, foremanDocuments, setLogsData }} />
        ) : (
          <div className="no-schedule-found">
            {t("Select a day to view the estimation details")}
          </div>
        )}
      </SmallLoadableComp>
      <FloatButton
        {...{
          onClick: () => navigate("/field"),
          icon: <LeftArrow fill="#000" />,
          description: t("Projects"),
          shape: "square",
          style: { width: 60 },
        }}
      />
    </CardTemplate>
  );
};

export default ForemanProject;
