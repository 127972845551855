import { debounce, uniq, uniqBy } from "lodash";
import {
  assignedToObject,
  onDocRecordSelect,
  projectManagerSelect,
  teamSelect,
} from "..";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import IndividualLoader from "../../../../../IndividualLoader";
import sortFields from "../sortFields";

/**
 * Generates an array of new fields based on the input fields and other parameters.
 *
 * @param {Object} fetchedData - The fetched data object.
 * @param {Array} inputFields - The input fields array.
 * @param {boolean} isDarkMode - Indicates whether the dark mode is enabled.
 * @param {Array} projectManagers - The project managers array.
 * @param {string|null} currentPageKey - The current page key.
 * @param {boolean} globalDisabled - Indicates whether all fields are globally disabled.
 * @param {boolean} recordsLoading - Indicates whether the records are loading.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Object} selectedMainField - The selected main field object.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {Function} setFieldsValue - The function to set field values.
 * @param {boolean} allFieldsDisabled - Indicates whether all fields are disabled.
 * @param {Function} setRecordsLoading - The function to set records loading state.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The sorted array of new fields.
 */
const documentationRequestFields = ({
  fetchedData = {},
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  currentPageKey = null,
  globalDisabled = false,
  recordsLoading = false,
  userConfiguration = {},
  selectedMainField = {},
  onTeamSelect = () => {},
  setFieldsValue = () => {},
  allFieldsDisabled = false,
  setRecordsLoading = () => {},
  onMainFieldSelect = () => {},
  dispatchRequestData = () => {},
}) => {
  const newFields = [];

  const accessOptions = (fetchedData?.docConfiguration || [])?.filter(
    (el) =>
      userConfiguration?.routeConfig?.find((a) => a?.title === el?.categoryName)
        ?.write
  );

  const extraFetchKeys = [
    "accountName",
    "accountId",
    "leadId",
    "estimationNumber",
    "scheduleName",
    "teamsConfiguration",
  ];
  const onSearch = debounce(async (searchValue) => {
    const arrayKey =
      selectedMainField?.apiData?.table === "scheduling"
        ? "schedules"
        : selectedMainField?.apiData?.table;

    return await searchFetchInput({
      table: selectedMainField?.apiData?.table,
      idKey: selectedMainField?.apiData?.primaryKey,
      arrayKey,
      searchKey: selectedMainField?.apiData?.rowName,
      searchValue,
      userConfiguration,
      keysToInclude: uniq([
        ...(selectedMainField?.apiData?.keysToInclude || []),
        ...(extraFetchKeys || []),
      ]),
      setLoading: setRecordsLoading,
      setRecords: (res) =>
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: {
            [arrayKey]: userAccessRecords(
              userConfiguration,
              uniqBy(
                [...(res || []), ...(fetchedData?.[arrayKey] || [])],
                selectedMainField?.apiData?.primaryKey
              )
            ),
          },
        }),
      customSetRecords: true,
      // setCurrentPageKey: (val) => {
      //   Object.assign(currentPageKey, {
      //     current: {
      //       ...currentPageKey?.current,
      //       [recordApiData.apiName]: key,
      //     },
      //   });
      // },
    });
  }, 500);

  const onPopupScroll = async (e) => {
    return await handlePopupScroll({
      e,
      table: selectedMainField?.apiData?.table,
      idKey: selectedMainField?.apiData?.primaryKey,
      currentPageKey:
        currentPageKey?.current?.[selectedMainField?.apiData?.table],
      keysToInclude: uniq([
        ...(selectedMainField?.apiData?.keysToInclude || []),
        ...(extraFetchKeys || []),
      ]),
      setLoading: setRecordsLoading,
      setRecords: (res) =>
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: {
            [selectedMainField?.apiData?.table]: userAccessRecords(
              userConfiguration,
              uniqBy(
                [
                  ...(fetchedData?.[selectedMainField?.apiData?.table] || []),
                  ...(res || []),
                ],
                selectedMainField?.apiData?.primaryKey
              )
            ),
          },
        }),
      userConfiguration,
      setCurrentPageKey: (val) => {
        Object.assign(currentPageKey, {
          current: {
            ...currentPageKey?.current,
            [selectedMainField?.apiData?.table]: val,
          },
        });
      },
      customSetRecords: true,
    });
  };

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "documentationCategory") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (accessOptions || [])?.map((el) => ({
          value: el?.categoryName,
          label: el?.categoryName,
        })),
        style: { minWidth: 170, minWidth: 300 },
        allowClear: true,
        showSearch: true,

        onSelect: (val) => onMainFieldSelect({ val }),

        onClear: () => onMainFieldSelect({ clear: true }),
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: selectedMainField?.category,
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "team") {
      Object.assign(field, {
        ...teamSelect({ onTeamSelect, teams: fetchedData?.teams, isDarkMode }),
      });
    }

    if (formItemName === "documentationType") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (selectedMainField?.docTypes || [])?.map((el) => ({
          value: el,
          label: el,
        })),
        showSearch: true,
        allowClear: true,
        required: selectedMainField?.docTypes?.length > 0,
      });
    }

    if (formItemName === "record") {
      delete field?.options;

      Object.assign(field, {
        showSearch: true,
        allowClear: true,
        onSearch,
        onPopupScroll,
        dropdownRender,

        onSelect: (value, opt) => {
          onDocRecordSelect({
            value,
            opt,
            fetchedData,
            setFieldsValue,
            dispatchRequestData,
            selectedMainField,
          });
        },

        customOptions: docCustomRecordOptions({
          apiData: selectedMainField?.apiData,
          fetchedData,
        }),

        filterOption: (input, option) =>
          option?.recordName?.toLowerCase()?.includes(input?.toLowerCase()),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default documentationRequestFields;

const clientDiv = (el) => {
  const style = {
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#fff",
    borderRadius: "5px",
    backgroundColor: "#1264a3",
    marginLeft: "10px",
    height: "fit-content",
  };
  if (!el?.accountId) {
    return (
      <div
        style={{
          ...style,
          backgroundColor: "#f1c40f",
        }}
      >
        Lead : {el?.accountName}
      </div>
    );
  }
  return (
    <div
      style={{
        paddingLeft: "10px",
        paddingRight: "10px",
        color: "#fff",
        borderRadius: "5px",
        backgroundColor: "#1264a3",
        marginLeft: "10px",
        height: "fit-content",
      }}
    >
      Client : {el?.accountName}
    </div>
  );
};

function docCustomRecordOptions({ apiData, fetchedData }) {
  let fetchedDataKey =
    apiData?.table === "scheduling" ? "schedules" : apiData?.table;

  if (apiData?.table === "scheduling") {
    return fetchedData?.[fetchedDataKey]?.map((el) => ({
      value: el?.scheduleId,
      label: `${el?.scheduleName} ― ${el?.[apiData?.rowName]}`,
      recordName: el?.[apiData?.rowName],
    }));
  } else if (
    apiData?.table === "projects" ||
    apiData?.table === "opportunities" ||
    apiData?.table === "estimations"
  ) {
    if (apiData?.table === "estimations") {
      return fetchedData?.[fetchedDataKey]?.map((el) => {
        return {
          value: el?.estimationId,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              EST# {el?.estimationNumber || "N/A"} : {el?.[apiData?.rowName]}
              {clientDiv(el)}
            </div>
          ),
          recordName: el?.[apiData?.rowName],
        };
      });
    } else if (apiData?.table === "opportunities") {
      return fetchedData?.[fetchedDataKey]?.map((el) => {
        return {
          value: el?.opportunityId,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {el?.[apiData?.rowName]}
              {clientDiv(el)}
            </div>
          ),
          recordName: el?.[apiData?.rowName],
        };
      });
    } else {
      return fetchedData?.[fetchedDataKey]?.map((el) => ({
        value: el?.[apiData?.primaryKey],
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {el?.[apiData?.rowName]}
            {clientDiv(el)}
          </div>
        ),
        recordName: el?.[apiData?.rowName],
      }));
    }
  } else if (apiData?.table === "inspections") {
    return fetchedData?.[fetchedDataKey]?.map((el) => ({
      value: el?.inspectionId,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {el?.projectName}
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              color: "#fff",
              borderRadius: "5px",
              backgroundColor: "#1264a3",
              marginLeft: "10px",
              height: "fit-content",
            }}
          >
            {el?.[apiData?.rowName]}
          </div>
        </div>
      ),
      recordName: el?.[apiData?.rowName],
    }));
  } else {
    return fetchedData?.[fetchedDataKey]?.map((el) => ({
      value: el?.[apiData?.primaryKey],
      label: el?.[apiData?.rowName],
      recordName: el?.[apiData?.rowName],
    }));
  }
}
