import { getMonthsTimestamps } from "../../../../utils";
import { months } from "../../../addWidgetsModalData";

export const violations = (updatedDynamicStates, IdHandler) => {
  return {
    amount_due: () => ({
      title: "Violations Amount",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Violations",
        field: "amount_due",
        content: (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                title: months[i],
                taskNumber: (() => {
                  const formattedYearArray = (
                    updatedDynamicStates?.[IdHandler] || []
                  )?.filter((b) => b?.issue_date >= getMonthsTimestamps()[0]);
                  return !!formattedYearArray?.length
                    ? formattedYearArray
                        ?.map((a) => a?.amount_due)
                        ?.reduce(
                          (previousValue, currentValue) =>
                            parseInt(previousValue) + parseInt(currentValue)
                        )
                    : 0;
                })(),

                taskDone: (() => {
                  const formattedArray = (
                    updatedDynamicStates?.[IdHandler] || []
                  )?.filter(
                    (a) =>
                      a?.issue_date >= getMonthsTimestamps()[i] &&
                      a?.issue_date <= getMonthsTimestamps("end")[i]
                  );

                  return !!formattedArray?.length
                    ? formattedArray
                        ?.map((b) => b?.amount_due)
                        ?.reduce(
                          (previousValue, currentValue) =>
                            parseInt(previousValue) + parseInt(currentValue)
                        )
                    : 0;
                })(),

                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: "$ In " + months[i],
                contentsHandler: "$ In " + months[i],
              },
            ];
          }
          return violations;
        })(),
      },

      id: IdHandler,
      width: "1600px",
    }), //amount_due
    issue_date: () => ({
      title: "Violations Issue Date",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Violations",
        field: "issue_date",
        content: (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                title: months[i],
                taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (b) => b.issue_date >= getMonthsTimestamps()[0]
                )?.length,
                taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (a) =>
                    a.issue_date >= getMonthsTimestamps()[i] &&
                    a.issue_date <= getMonthsTimestamps("end")[i]
                )?.length,
                color: "#71CF48",
                backgroundColor: "#B2DD9B",
                contentHandler: " Violation In " + months[i],
                contentsHandler: " Violations In " + months[i],
              },
            ];
          }
          return violations;
        })(),
      },

      id: IdHandler,
      width: "1600px",
    }), //issue_date
  }; //Violations
};
