// import EmployeeModal from "../Tabs/Employees/components/EmployeeModal";
import JobsiteModal from "../Tabs/Jobsite/components/JobsiteModal";
import DegModal from "../Tabs/DEG/components/DegModal";
import PayrollActivityModal from "../Tabs/Activity/components/PayrollActivityModal";
import { ClientModal } from "../../../Header/forms";
import { CrewsHeader } from "../../Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";
import CrewTeamModal from "../../Settings/settingsComponents/CrewTeams/components/CrewTeamModal";
import FleetServicesModal from "../../Settings/settingsComponents/FleetServices/FleetServicesModal/FleetServiceModal";

const payrollModalData = {
  Employees: CrewsHeader,
  Jobsites: JobsiteModal,
  DEG: DegModal,
  Activity: PayrollActivityModal,
  Clients: ClientModal,
  ["Crew Teams"]: CrewTeamModal,
  ["Service Tasks"]: FleetServicesModal,
};

export default payrollModalData;
