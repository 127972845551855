import { message } from "antd"
import { totalCalculator } from "../../../Header/Components/MainHeader/mainHeaderFunctions"

// Called when a cell editing has started.
export const cellEditingStarted = ({ params, isWritable, isLoading }) => {
  //if erite mode is not on editable mode of agGrid is turned of automatically
  if (!isWritable) {
    params.api.stopEditing(true)
    message.error("Please enable write mode")
  }
  //if application is in loading mode editable mode of agGrid is turned of automatically
  if (isLoading) {
    params.api.stopEditing(true)
    message.error("Please wait until it finishes updating")
  }
  //if the event is charged editable mode of agGrid is turned of automatically
  if (params?.data?.charged) {
    params.api.stopEditing(true)
    message.error("This row is already charged")
  }
}

//this saves the new application object on state context
export const setApplicationHandler = ({ newApplication, dispatchContext }) => {
  //this handles save om the main application context
  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication })
  // this handles save on the details application context
  dispatchContext({
    type: "SET_UNCHANGING_FAKE_APPLICATION",
    payload: newApplication,
  })
}
