export const checkCheckboxStatus = (estimation) => {
  if (!estimation?.services) {
    return false;
  }

  let allTrue = true;
  let allFalse = true;

  for (const value of Object.values(estimation.services)) {
    if (value === false) {
      allTrue = false;
    } else {
      allFalse = false;
    }
  }

  return allTrue ? true : allFalse ? false : "indeterminate";
};
