import { safeQuoteIfString, ArrayStringifier } from "./utils";

export const URL_FILES = "https://www.googleapis.com/drive/v3/files";
export const URL_UPLOAD = "https://www.googleapis.com/upload/drive/v3/files";
export const CONTENT_TYPE_JSON = "application/json; charset=UTF-8";
export const MIME_FOLDER = "application/vnd.google-apps.folder";
export const DRIVE_API_KEY = "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo";

export const _CREATE_HEADERS = (
  accessToken,
  contentType,
  contentLength,
  ...additionalPairs
) => {
  let pairs = [["Authorization", `Bearer ${accessToken}`]];

  [
    ["Content-Type", contentType],
    ["Content-Length", contentLength],
  ].forEach((data) => (data[1] ? pairs.push(data) : undefined));

  if (additionalPairs) {
    pairs = pairs.concat(additionalPairs);
  }

  const headers = new Headers();

  for (let pair of pairs) {
    headers.append(pair[0], pair[1]);
  }

  return headers;
};

export const _CREATE_QUERY_PARAMS = (
  queryParams,
  prefix = "?",
  separator = "&",
  quoteIfString
) => {
  const array = [];

  Object.keys(queryParams).forEach((key) =>
    array.push(`${key}=${safeQuoteIfString(queryParams[key], quoteIfString)}`)
  );

  return new ArrayStringifier(array)
    .setPrefix(prefix)
    .setSeparator(separator)
    .process();
};
