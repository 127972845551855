import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Form, Modal, Tooltip, message } from "antd";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

import { useEditLogs, useUploadedFiles } from "../../../../../hooks";
import { useCreateDriveFolders } from "../../../../../hooks/useCreateDriveFolders";
import { getDriveFolderKeyName } from "../../../utils/getDriveFolderKeyName";
import { getChangedData } from "../../../Accounting/components/utilities";
import { setDateMonth } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import {
  fetchAllData,
  fetchData,
  filterTables,
  getCognitosForNotification,
  lazyFetch,
  updateDocumentTitle,
  updateTeamsConfiguration,
} from "../../../../../utils";
import { getMentions } from "../../PersonalInjury/Modals/utils";
import { filterSchedulesOnDate } from "../../SafetyModal/utils/filterSchedulesOnDate";
import { fixFormFields } from "./fixFormFields";
import { getNewLabelHTML } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/routeFunctions";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import { BorderedTextCard } from "../../../Fleet/components";
import { DocUploader } from "../../../Documentation/View/components/DocumentationModal/components";
import { InputComponent } from "../../../Fleet/components";
import SignControllerButtons from "../../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/components/SignControllerButtons";
import { SignatureModal } from "../../../XComponents";
import { MultiLevelTreeLogs, Stepper } from "../../../../commonComponents";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { API } from "aws-amplify";
import { driveApi } from "../../../../../integrations/DriveRequest";
import { getGridProjectInfo } from "../../SafetyBasePage/getGridProjectInfo";
import {
  pdfBorderedSection,
  docDefinition,
  imageForPdf,
  mapImages,
  safetyInspectionPdfBody,
} from "../../safetyPdf";
import { compareIncluding } from "../../../utils";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import dayjs from "dayjs";
import { PlusIcon } from "../../../../../assets";
import DynamicTeamModal from "../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import EmailBox from "../../../Communication/components/EmailBox/EmailBox";
import InspectionModal from "../../../components/AgGridComponents/InspectionModal";
import TasksBasePage from "../../../TaskManager/Tasks/TasksBasePage";
import { useNavigate } from "react-router-dom";
import { deleteNotifications } from "../../../../../utils/notificationHelpers";

import { checkSaveDraft } from "../../helpers";
import modalFooter from "./modalFooter";
import IncidentComments from "../../../Incidents/Components/IncidentComments";
import Report from "../../../DynamicView/Pages/Overview/components/Report/Report";
import { openEmailBox } from "../../../Communication/functions";
import { getBase64 } from "../../../Incidents/Modals/utils/getBase64Pdf";
import { b64toBlob } from "../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
import axios from "axios";
import Swal from "sweetalert2";
import { savePdfToDrive } from "../../../Incidents/Modals/utils/helpers";
import { devUrl, prodUrl } from "../../../../../utils/mergePdfDocsUrl";
import createPDF from "../../../../../integrations/createPDF";
import { handleMissingRecord } from "./handleMissingRecord";
import { SettingIcon } from "../../../../Header/subcomponents";
import Signature from "../../../../commonComponents/Signature/Signature";
import { DocumentationModal } from "../../../Documentation/View/components";
import { useDispatch } from "react-redux";
import SafetyInspectionExportPreview from "./SafetyInspectionExportPreview";

export const viewSafetyUsed = (safetyUsingIncident) => {
  Modal.info({
    title:
      "View Safety Report that is being used in this Inspection. Click on the Safety Report to open it.",
    content: (
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {safetyUsingIncident?.map(({ safetyApplicationId, safetyName }) => {
          return (
            <a
              href={`/safety/${safetyApplicationId}`}
              target="_blank"
              style={{
                color: "white",
                minWidth: "150px",
                maxWidth: "330px",
                height: 32,
                background: "#1264a3",
                borderRadius: 5,
                textAlign: "center",
                whiteSpace: "nowrap",
                display: "inline-block",
                verticalAlign: "middle",
                fontSize: 16,
                lineHeight: 2,
              }}
            >
              {safetyName}
            </a>
          );
        })}
      </div>
    ),
    onOk() {},
    maskClosable: true,
    centered: true,
  });
};

/**
 * Renders a form for creating a new safety inspection.
 *
 * @param {boolean} visible - Determines the visibility of the form.
 * @param {function} setVisible - Sets the visibility of the form.
 * @param {function} getSingleSafetyInspectionsData - Async Function used to fetch all the data for a single Safety Inspections
 * @param {function} [refreshTable] - Callback function to refresh the table after saving.
 * @param {Object} rowObject - The row object containing inspection data.
 * @param {string} inspectionCategory - The category of the inspection.
 * @param {Object} safetyData - The safety data.
 * @param {string} [requestId=""] - The ID of the request.
 * @param {string} [propProjId=""] - The ID of the project.
 * @param {string} [propAddress=""] - The address of the project.
 * @param {function} [afterSaveRequest] - Callback function after saving the request.
 * @returns {JSX.Element} The JSX element representing the new safety inspection form.
 */
function NewSafetyInspection({
  visible,
  setVisible,
  getSingleSafetyInspectionsData,
  refreshTable = () => {},
  rowObject,
  inspectionCategory,
  safetyData,
  requestId = "",
  propProjId = "",
  propAddress = "",
  afterSaveRequest = async () => {},
  documentationModalFiles,
  setDocumentationModalFiles,
}) {
  const [
    { accessToken },
    { userConfiguration },
    { hotCredentials },
    { programFields },
    { authenticatedUser },
    { base64 },
    { notifications },
    { isDarkMode },
  ] = useSelector((state) => [
    state.accessToken,
    state.userConfig,
    state.hotCredentials,
    state.programFields,
    state.authenticatedUser,
    state.base64,
    state.notifications,
    state.darkMode,
  ]);

  const {
    inspectionId: defaultId,
    inspectionObject: defaultObj,
    // inspectionCategory: safetyCategory,
    jobsiteAddress: inspectionAddress,
    gDriveUploads: requestUploads = [],
  } = rowObject || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders("Safety Inspection");
  const driveRequest = driveApi({ accessToken });
  const [pdfId, setPdfId] = useState("");
  const [emailBox, setEmailBox] = useState(false);

  const folderDriveKeyName = getDriveFolderKeyName({
    programFields,
    name: "Safety Inspection",
  });

  const inspectionHotCredential = hotCredentials?.find(
    (obj) => obj.credentialId === "19"
  );

  // set props for Link Component
  const popoverProps = {
    accountId: rowObject?.accountId,
    projectId: rowObject?.projectId,
    scheduleId: rowObject?.scheduleId,
  };

  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [editLogs, setEditLogs] = useState([]);
  const [dynamicFields, setDynamicFields] = useState();
  const [projects, setProjects] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedulesForDate, setSchedulesForDate] = useState([]);
  const [divClickable, setDivClickable] = useState(true);
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [sign, setSign] = useState();
  const [whichSignToChange, setWhichSignToChange] = useState();
  const [witnessCount, setWitnessCount] = useState(1);
  const [witnessSignings, setWitnessSignings] = useState();
  const [witnessStatements, setWitnessStatements] = useState();
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [statusesColors, setStatusesColors] = useState([]);
  const [descriptionValues, setDescriptionValues] = useState({});
  const [addressDisabled, setAddressDisabled] = useState(false);
  const [requiredTextArea, setRequiredTextArea] = useState([]);
  const [addressSelectionInfo, setAddressSelectionInfo] = useState({
    accountId: rowObject?.accountId || propProjId || "",
    projectId: rowObject?.projectId || "",
    scheduleId: rowObject?.scheduleId || "",
  });
  const [displayViewLogs, setDisplayViewLogs] = useState(false);
  const [foremen, setForemen] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  const [scopeProject, setScopeProject] = useState([]);
  const [drivers, setDrivers] = useState();
  const [fleet, setFleet] = useState();
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamModalVisible, setTeamModalVisible] = useState(false);
  const [changes, setChanges] = useState(false);
  const [selectedSafety, setSelectedSafety] = useState([]);
  const [tasksVisible, setTasksVisible] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [emailBoxes, setEmailBoxes] = useState([]);
  const [videoTutorial, setVideoTutorial] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [showPdfPreview, setShowPdfPreview] = useState(false);

  const [scopeOfWorkOptions, setScopeOfWorkOptions] = useState(false);

  const { saveAddedLogs } = useEditLogs();

  const videoTutorialLink = programFields
    ?.filter((item) => item?.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item?.categoryName === "Safety Isnpection")
    ?.subCategories[0]?.videos?.find(
      (item) => item?.videoName === "New Safety Inspection"
    )?.videoLink;

  const [form] = Form.useForm();

  const windowHandler = useCallback((event) => {
    event.preventDefault();
    event.returnValue = "Are you sure you want to leave the page?";
    return "Are you sure you want to leave the page?";
  }, []);

  function checkForDateField(formItemName) {
    let dynamicField = dynamicFields?.find(
      ({ formItemName: fN }) => fN === formItemName
    );
    if (dynamicField) {
      return (
        dynamicField?.type?.toLowerCase() === "datepicker" ||
        dynamicField?.type?.toLowerCase() === "timepicker"
      );
    }
    return false;
  }

  const noteAccess = useMemo(
    () =>
      userConfiguration?.routeConfig
        ?.find(({ title }) => title === "Notes/View")
        ?.children?.find((el) => el?.title === "Inspections"),
    [userConfiguration?.routeConfig]
  );

  useEffect(() => {
    if (visible) {
      message.loading({
        content: "Opening Form...",
        key: "modalLoading",
        duration: 0,
      });
      Promise.allSettled([
        filterTables("programFields", "fieldName", "Safety Inspections"),
        fetchData({
          endpoint: "projects",
          queryStringParams: {
            queryStringParameters: {
              keysToInclude: JSON.stringify([
                "projectId",
                "projectName",
                "accountId",
                "accountName",
                "createdAt",
                "teamsConfiguration",
                "services",
              ]),
            },
          },
        }),
        fetchData({
          endpoint: "scheduling",
          queryStringParams: {
            queryStringParameters: {
              keysToInclude: JSON.stringify([
                "scheduleId",
                "scheduleAddress",
                "scheduleName",
                "scheduleDays",
                "createdAt",
                "teamsConfiguration",
              ]),
            },
          },
        }),
        lazyFetch({
          tableName: "crews",
          listOfKeys: ["crewId", "crewName", "foreman", "members"],
          filterKey: "crewStatus",
          filterValue: "Active",
        }),
        lazyFetch({
          tableName: "fleet",
          listOfKeys: ["fleetName", "fleetId"],
          filterKey: "fleetStatus",
          filterValue: "Active",
        }),
        lazyFetch({
          tableName: "drivers",
          listOfKeys: ["driverName", "driverId"],
          filterKey: "driverStatus",
          filterValue: "Ready to Work",
        }),
        API.get("teams", "/teams"),
        filterTables("safety", "safetyApplicationCategory", inspectionCategory),
      ])
        .then(
          ([
            { value: dynF },
            { value: pr },
            { value: sch },
            { value: crews },
            { value: fleet },
            { value: drivers },
            { value: teams },
            { value: safeties },
          ]) => {
            message.destroy("modalLoading");
            window.addEventListener("beforeunload", windowHandler);

            if (!!defaultId) {
              handleMissingRecord({
                rowObject,
                projects: pr?.projects,
                scheduling: sch?.scheduling,
              });
            }

            setTeams(teams);
            setProjects(pr?.projects);
            setSchedules(sch?.scheduling);
            "safetyApplicationId" in (rowObject?.inspectionObject || {}) &&
              setSelectedSafety(
                safeties?.filter(
                  ({ safetyApplicationId }) =>
                    safetyApplicationId ===
                    rowObject?.inspectionObject?.safetyApplicationId
                ) || []
              );
            setSchedulesForDate(
              filterSchedulesOnDate(
                sch?.scheduling || [],
                defaultObj?.inspectionDate || dayjsNY()
              )?.schedulesForDay || []
            );

            let formFields = dynF[0]?.fieldOptions?.[inspectionCategory] || [];
            let tmpDescriptions = {};
            for (let i = 0; i < formFields?.length; i++) {
              if (formFields[i].type === "textEditor") {
                tmpDescriptions[formFields[i].formItemName] = "";
              }
            }
            setDescriptionValues(tmpDescriptions);
            setDynamicFields(formFields);
            setForemen(
              crews
                ?.filter((e) => e.foreman === true)
                ?.map(({ crewId, crewName, members }) => ({
                  label: crewName,
                  value: crewId,
                  members,
                }))
            );
            setCrewMembers(
              crews
                ?.filter((e) => e.foreman !== true)
                ?.map(({ crewId, crewName }) => ({
                  label: crewName,
                  value: crewId,
                }))
            );
            setFleet(fleet);
            setDrivers(drivers);
          }
        )
        .catch((err) => {
          message.error({
            content: "Something Went Wrong!",
            key: "modalLoading",
          });
          console.error("Error Getting Fields: ", err);
          closeModal();
        });

      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );
      let tmpColors = fieldObj["Statuses Of Categories"]["Safety"];
      setSteps(
        fieldObj["Statuses Of Categories"]["Safety"]
          ?.map(({ statusName }) => ({
            title: statusName,
          }))
          ?.filter(({ title }) => title !== "Proceeded to Claim")
      );
      setStatusesColors(tmpColors);
      if (!!rowObject) {
        let stepIndex = tmpColors?.findIndex(
          ({ statusName }) =>
            statusName === rowObject?.inspectionStatus || "Draft"
        );
        setCurrentStep(stepIndex === -1 ? 0 : stepIndex);
        form.setFieldValue("stepper", stepIndex);
      }
    }

    return () => {
      window.removeEventListener("beforeunload", windowHandler);
    };
  }, []);

  /**
   * Changes the date of the incident and updates the schedules accordingly.
   * @param {Date} date - The new date of the incident.
   */
  function changeDateOfIncident(date) {
    let selectedSchedule = addressSelectionInfo?.scheduleId || null;
    let res = filterSchedulesOnDate(schedules, dayjsNY(date), selectedSchedule);
    setSchedulesForDate(res?.schedulesForDay);
    if (res?.isCleared) {
      form.setFieldValue("inspectionAddress", "");
      setAddressSelectionInfo({
        projectId: "",
        scheduleId: "",
        accountId: "",
      });
    }
  }

  /**
   * Handles the change event for the address input.
   *
   * @param {string} val - The selected value from the address input.
   * @param {object} option - The selected option from the address input.
   */
  function onChangeAddress(val, option) {
    let selectedAddress = val?.trim();
    let type = getNewLabelHTML({ option })?.type;
    if (type === "schedule") {
      let selectedSchedule = schedulesForDate?.find(
        (el) => el?.scheduleAddress === selectedAddress
      );
      if (!!selectedSchedule) {
        setAddressSelectionInfo({
          scheduleId: selectedSchedule?.scheduleId,
          projectId: "",
          accountId: "",
        });
      }
    } else if (type === "project") {
      let selectedProject = projects?.find(
        (el) => el?.projectName === selectedAddress
      );
      setScopeProject(selectedProject);
      if (!!selectedProject) {
        setAddressSelectionInfo({
          projectId: selectedProject?.projectId,
          accountId: selectedProject?.accountId,
          scheduleId: "",
        });
      }
    } else {
      setAddressSelectionInfo({
        projectId: "",
        scheduleId: "",
        accountId: "",
      });
    }
  }
  const selectedForeman = Form.useWatch("fullNameOfForeman", form);
  const fieldsJSON = useMemo(() => {
    if (!dynamicFields) {
      return [];
    }

    return fixFormFields({
      dynamicFields,
      descriptionValues,
      schedulesForDate,
      addressDisabled,
      projects,
      setDescriptionValues,
      userConfiguration,
      setRequiredTextArea,
      changeDateOfIncident,
      onChangeAddress,
      foremen,
      crewMembers,
      selectedForeman,
      scopeProject,
      fleet,
      drivers,
      addressSelectionInfo,
      navigate,
      defaultId,
      propAddress,
      setProjects,
      scopeOfWorkOptions,
      setScopeOfWorkOptions,
    });
  }, [
    scopeOfWorkOptions,
    dynamicFields,
    descriptionValues,
    schedulesForDate,
    addressDisabled,
    foremen,
    crewMembers,
    selectedForeman,
    scopeProject,
    fleet,
    drivers,
  ]);

  useEffect(() => {
    if (!!defaultId) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                { column: "recordId", value: defaultId, formula: "is" },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          setEditLogs(res);
        })
        .catch((error) => {
          console.error("Error fetching logs: ", error);
          setEditLogs([]);
        });
    }

    if ((!!rowObject && !!dynamicFields) || !!inspectionAddress) {
      if (rowObject?.teamsConfiguration?.length > 0) {
        setSelectedTeam(
          rowObject?.teamsConfiguration?.map((team) => ({
            value: team?.value,
            members: team?.members,
          }))
        );
      }
      if (!pdfId) {
        setPdfId(rowObject?.safetyApplicationObject?.gDrivePdfId || "");
      }
      setTimeout(() => {
        let defaultObject = {};
        defaultObject = structuredClone(defaultObj);
        let witnessNo = 0;
        let rg = /witnessName-*[0-9]$/;
        Object.keys(defaultObject || {})?.forEach((key) => {
          if (
            !!checkForDateField(key) ||
            key === "preparedOn" ||
            key === "endTimeOfInspection"
          ) {
            if (!!defaultObj?.[key]) {
              defaultObject[key] = dayjsNY(defaultObj?.[key]);
            } else {
              delete defaultObject[key];
            }
          }

          if (rg.test(key)) {
            witnessNo = witnessNo + 1;
          }
        });

        let tStatements = [],
          tSignatures = [];
        for (let i = 0; i < witnessNo; i++) {
          tStatements.push(defaultObject[`witnessStatement-${i}`]);
          tSignatures.push(defaultObject[`witnessSign-${i}`]);
        }

        setWitnessCount(witnessNo);
        setWitnessStatements(tStatements);
        setWitnessSignings(tSignatures);
        setSign(defaultObject?.signature);
        if (!!defaultId) {
          if (
            !!rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
            !!driveFunctions.parentFolderId
          ) {
            setDivClickable(false);
          }
          let { viewLogs = {} } = rowObject;
          viewLogs = {
            ...viewLogs,
            [authenticatedUser?.sub]: {
              lastSeen: Date.now(),
              name: userConfiguration?.nameOfUser,
            },
          };
          API.put(
            "allInspections",
            `/allInspections/${rowObject?.inspectionId}`,
            {
              body: { viewLogs },
            }
          )
            .then(() => {
              setDisplayViewLogs(viewLogs);
            })
            .catch((err) => console.error("Error Updating View Logs: ", err));
        }
        let tmpDescription = {};
        Object.keys(descriptionValues).forEach((key) => {
          tmpDescription[key] = defaultObject?.[key] ?? "";
        });
        setDescriptionValues(tmpDescription);
        form.setFieldsValue({ ...defaultObject });
      }, 0);
      if (!Array.isArray(rowObject?.googleDriveUpload)) {
        setUploadedFiles([]);
      } else {
        setUploadedFiles(rowObject?.googleDriveUpload);
      }
    }
  }, [rowObject, dynamicFields]);

  /**
   * Saves the safety inspection.
   *
   * @param {boolean} saveDraft - Indicates whether to save the inspection as a draft.
   * @returns {void}
   */
  async function onSave(saveDraft = false) {
    let findRequiredArea = Object.keys(descriptionValues)?.filter((key) => {
      if (requiredTextArea.includes(key) && !descriptionValues[key]) {
        return key;
      }
    });

    if (findRequiredArea?.length > 0 && !saveDraft) {
      findRequiredArea?.forEach((key) => {
        message.error(
          `${
            dynamicFields?.find((el) => el.formItemName === key)?.label
          } is required`
        );
      });
      return;
    }

    if (!sign && !saveDraft) {
      message.error("Please Sign this Safety Inspection");
      return;
    }
    let inspectionObject = {
      ...form.getFieldsValue(),
      ...getGridProjectInfo({
        projects,
        schedules,
        data: addressSelectionInfo,
      }),
      safetyApplicationId: rowObject?.safetyApplicationId || "",
      signature: sign,
      requestId,
    };

    inspectionObject["preparedOn"] = dayjsNY(
      form.getFieldValue("preparedOn")
    ).format();

    inspectionObject["endTimeOfInspection"] = dayjsNY(
      form.getFieldValue("endTimeOfInspection")
    ).format();
    message.loading({
      content: "Saving...",
      key: "propertyDamageLoading",
    });

    let allFolders = null;
    if (!driveFunctions.parentFolderId && !defaultId) {
      try {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${(
            form.getFieldValue("inspectionAddress") ||
            form.getFieldValue("jobsiteAddress") ||
            "Draft"
          ).trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: inspectionHotCredential?.credentialValue,
        });
        allFolders = folders;
      } catch (error) {
        console.error("Error Creating Folder: ", error);
        allFolders = null;
      }
    }

    const driveFolderCondition = () => {
      if (rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
        return rowObject?.googleDriveFolderIds;
      } else if (driveFunctions?.parentFolderId) {
        return driveFunctions?.allFolders;
      } else {
        return allFolders;
      }
    };

    // At formattedFiles added e new Documented Object if file has been Documented
    if (!!documentationModalFiles) {
      const documentedIds = documentationModalFiles.map((d) => d.id);

      formattedFiles.forEach((file) => {
        if (documentedIds.includes(file.id)) {
          file.documented = {
            isDocumented: true,
            docId: documentationModalFiles.docId,
          };
        }
      });
    }

    let tmpBody = structuredClone({
      inspectionObject,
      inspectionName: (
        form.getFieldValue("inspectionAddress") ||
        form.getFieldValue("jobsiteAddress") ||
        ""
      )?.trim(),
      inspectionStatus: saveDraft
        ? "Draft"
        : statusesColors[currentStep]?.statusName || "Draft",
      ...addressSelectionInfo,
      googleDriveUpload: [...formattedFiles, ...(requestUploads || [])],
      googleDriveFolderIds: driveFolderCondition(),
      teamsConfiguration: updateTeamsConfiguration(
        userConfiguration,
        selectedTeam
      ),
    });

    Object.keys(inspectionObject).forEach((key) => {
      if (checkForDateField(key)) {
        tmpBody.inspectionObject[key] = dayjsNY(inspectionObject[key]).format();
      }

      if (key === "inspectionAddress" && !!inspectionObject[key]) {
        tmpBody.inspectionObject[key] = inspectionObject[key].trim();
      }
    });

    if (!!defaultId) {
      API.put("allInspections", `/allInspections/${rowObject?.inspectionId}`, {
        body: {
          ...tmpBody,
          lastModifiedBy: {
            name: userConfiguration.nameOfUser,
            id: userConfiguration.cognitoUserId,
          },
        },
      })
        .then((res) => {
          message.success({
            content: "Saved Successfully!",
            key: "propertyDamageLoading",
            duration: 3,
          });
          let { inspectionObject: prevObj } = rowObject;
          let { inspectionObject: currObj } = tmpBody;

          let changes = getChangedData(prevObj, currObj);
          if (!!changes) {
            saveAddedLogs({
              recordId: defaultId,
              recordName:
                form.getFieldValue("inspectionAddress") ||
                form.getFieldValue("jobsiteAddress"),
              category: inspectionCategory,
              actionType: "Edit",
              topic:
                form.getFieldValue("inspectionAddress") ||
                form.getFieldValue("jobsiteAddress"),
              currentData: changes?.curr || {},
              previousData: changes?.prev || {},
              updatedKeys: ["See Details"],
            });
          }

          broadcastNotification(
            "44",
            "onSafetyInspectionEdit",
            [
              {
                common: userConfiguration?.nameOfUser,
              },
              {
                userName: userConfiguration?.nameOfUser,
                currentUser: authenticatedUser?.sub,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  rowObject?.teamsConfiguration
                ),
              },
            ],
            res?.inspectionId
          );
          onClose();
        })
        .catch((err) => {
          message.error({
            content: "Something Went Wrong While Saving",
            key: "propertyDamageLoading",
          });
          console.error("Error Saving: ", err);
        });
    } else {
      API.post("allInspections", "/allInspections", {
        body: {
          ...tmpBody,
          inspectionCategory,
          createdBy: {
            name: userConfiguration.nameOfUser,
            id: userConfiguration.cognitoUserId,
          },
        },
      })
        .then(async (res) => {
          message.success({
            content: "Saved Successfully!",
            key: "propertyDamageLoading",
            duration: 3,
          });

          if (!!requestId) {
            await afterSaveRequest({
              newRecordId: res?.inspectionId,
              // requestId,
              path: "safetyInspections",
              // cognitoUserId: userConfiguration?.cognitoUserId,
              moveFilesParams: {
                fileToMove: requestUploads,
                newParentId: res?.googleDriveFolderIds?.[folderDriveKeyName],
                accessToken,
              },
            });
            // if (requestUploads.length > 0) {
            //   await moveRequestFiles({});
            // }
          }
          refreshTable([
            {
              ...res,
              inspectionDate: setDateMonth(
                res?.inspectionObject?.inspectionTime ||
                  res?.inspectionObject?.startTimeOfInspection,
                res?.inspectionObject?.inspectionDate ||
                  res?.inspectionObject?.endTimeOfInspection
              ).valueOf(),
              ...getGridProjectInfo({
                projects,
                schedules,
                data: res,
              }),
            },
          ]);

          await saveAddedLogs({
            recordId: res?.inspectionId,
            recordName:
              form.getFieldValue("inspectionAddress") ||
              form.getFieldValue("jobsiteAddress") ||
              "",
            category: inspectionCategory,
            label: "",
            topic:
              form.getFieldValue("inspectionAddress") ||
              form.getFieldValue("jobsiteAddress") ||
              "",
          });

          if (!saveDraft) {
            broadcastNotification(
              "44",
              "onSafetyInspectionCreation",
              [
                { common: userConfiguration?.nameOfUser },
                {
                  userName: userConfiguration?.nameOfUser,
                  currentUser: authenticatedUser?.sub,
                  cognitos: getCognitosForNotification(
                    userConfiguration,
                    tmpBody?.teamsConfiguration
                  ),
                },
              ],
              res?.inspectionId
            );
          } else {
            broadcastNotification(
              "44",
              "onSaveDraft",
              [
                {
                  common: userConfiguration?.nameOfUser,
                },
                {
                  userName: userConfiguration?.nameOfUser,
                  currentUser: authenticatedUser?.sub,
                },
              ],
              res?.inspectionId
            );
          }
          onClose();
        })
        .catch((err) => {
          message.error({
            content: "Something Went Wrong While Saving",
            key: "propertyDamageLoading",
          });
          console.error("Error Saving: ", err);
        });
    }
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
  }

  /**
   * Deletes a row from the safety inspections table.
   * @async
   * @function onDeleteRow
   */
  async function onDeleteRow() {
    if (!!defaultId) {
      message.loading({
        content: "Deleting...",
        key: "messageDeleting",
      });
      API.del("allInspections", `/allInspections/${rowObject?.inspectionId}`)
        .then(() => {
          refreshTable(rowObject, "Delete");
          deleteNotifications(notifications, rowObject?.inspectionId, dispatch);
          message.success({
            content: "Delete Successfully",
            key: "messageDeleting",
            duration: 3,
          });

          let deletePostLogsBody = {
            recordId: defaultId,
            recordName: rowObject?.inspectionAddress,
            category: inspectionCategory,
            actionType: "Delete",
            topic: rowObject?.inspectionAddress,
          };

          saveAddedLogs(deletePostLogsBody);

          API.del("notes", `/notes/${defaultId}`).catch((err) => {
            console.error("Error Deleting Note: ", err);
          });

          broadcastNotification("44", "onSafetyInspectionDeletion", [
            { common: userConfiguration?.nameOfUser },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotification(
                userConfiguration,
                rowObject?.teamsConfiguration
              ),
            },
          ]);

          onClose({
            deleteAction: true,
            deletePostLogsBody,
          });
        })
        .catch((err) => {
          console.error("Error deleting:", err);
          message.error({
            content: "Something Went Wrong",
            key: "messageDeleting",
          });
        });
    }
  }

  /**
   * Handles the closing of the modal.
   *
   * @param {boolean} save - Indicates whether to save the changes.
   * @param {boolean} cancel - Indicates whether to cancel the changes.
   * @param {boolean} deleteAction - Indicates whether to perform a delete action.
   * @param {Object} deletePostLogsBody - The body for deleting post logs.
   * @returns {void}
   */
  async function onClose(param = {}) {
    const {
      save = false,
      cancel = false,
      deleteAction = false,
      deletePostLogsBody = {},
    } = param || {};
    if (deleteAction) {
      !!driveFunctions?.parentFolderId
        ? driveFunctions?.delete()?.then(() => {
            if (Object.keys(deletePostLogsBody).length > 0) {
              saveAddedLogs({
                ...deletePostLogsBody,
                recordName: rowObject?.inspectionAddress + "Drive Folder",
              });
            }
          })
        : driveRequest
            ?.deleteDriveItem(
              rowObject?.googleDriveFolderIds?.[folderDriveKeyName]
            )
            ?.then(() => {
              if (Object.keys(deletePostLogsBody).length > 0) {
                saveAddedLogs({
                  ...deletePostLogsBody,
                  recordName: rowObject?.inspectionAddress + "Drive Folder",
                });
              }
            });
    }

    if (cancel || deleteAction) {
      Promise.allSettled(
        filesToBeDeleted.map(
          async (id) => await driveRequest.deleteDriveItem(id)
        )
      ).then(() => setFilesToBeDeleted([]));
    }

    driveFunctions.reset();

    form.resetFields();
    setCancelModalVisible(false);
    closeModal();
    setUploadedFiles([]);
  }

  //upload button handler
  const uploaderButton = document.querySelector(".uploaderButton");
  async function onContinueToUpload() {
    form
      .validateFields(["inspectionAddress", "jobsiteAddress"])
      .then(async () => {
        const { parentId } = await driveFunctions.create({
          parentFolderName: `${(
            form.getFieldValue("inspectionAddress") ||
            form.getFieldValue("jobsiteAddress") ||
            ""
          ).trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: inspectionHotCredential?.credentialValue,
        });
        setAddressDisabled(true);
        setCancelModalVisible(false);
        setDivClickable(false);
        setTimeout(() => {
          uploaderButton.click();
        }, 1000);
      })
      .catch((err) => {
        setCancelModalVisible(false);
        console.error("Error Upload: ", err);
      });
  }

  /**
   * Handles the selection of a team.
   *
   * @param {Object} _ - The event object (not used in this function).
   * @param {Object} data - The data object containing the selected team information.
   */
  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  /**
   * Handles the deselection of a team.
   *
   * @param {Event} _ - The event object.
   * @param {Object} data - The data object containing the deselected team.
   */
  const onTeamDeselect = (_, data) => {
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== data.value));
    if (form.getFieldValue("team").length < 1) {
      setSelectedTeam([]);
    }
  };
  //region PDF BODY
  function createPdfBody() {
    let tmpFields = fieldsJSON?.map((el) => {
      if (
        el?.title === "General Information" ||
        el?.title === "Basic Information"
      ) {
        return {
          ...el,
          fields: [
            ...el?.fields,
            {
              label: "Team Members",
              value: selectedTeam?.map((team) =>
                team?.members?.map((e) => e?.nameOfUser)
              ),
            },
          ],
        };
      }
      return el;
    });

    if (inspectionCategory === "General Inspection") {
      tmpFields?.push({
        title: "Signature",
        fields: [
          {
            label: "End Time Of Inspection",
            formItemName: "endTimeOfInspection",
          },
          {
            label: "Full Name Of Inspector",
            formItemName: "inspectorName",
          },
          {
            label: "Signature",
            formItemName: "signature",
          },
        ],
      });
    } else {
      tmpFields?.push({
        title: "Signature",
        fields: [
          {
            label: "Prepared On",
            formItemName: "preparedOn",
          },
          {
            label: "Prepared By",
            formItemName: "preparedBy",
          },
          {
            label: "Signature",
            formItemName: "signature",
          },
        ],
      });
    }

    return tmpFields?.map((el) => {
      return pdfBorderedSection({
        title: `${el?.title}`,
        body: safetyInspectionPdfBody({
          category: el?.title,
          fieldsJSON: tmpFields,
          rowObject,
          crewMembers,
          foremen,
          fleet,
          drivers,
          userConfiguration,
        }),
      });
    });
  }

  function getDisabledHours() {
    let startHour = dayjs(form.getFieldValue("startTimeOfInspection")).hours();
    return [...new Array(24).keys()]?.filter((i) => {
      return i < startHour;
    });
  }

  function getDisabledMinutes() {
    let startMinute = dayjs(
      form.getFieldValue("startTimeOfInspection")
    ).minute();
    return [...new Array(60).keys()]?.filter((i) => {
      return i <= startMinute;
    });
  }
  //region PDF
  async function onGeneratePdf() {
    Swal.fire({
      title: "<strong>Generating PDF</strong>",
      icon: "info",
      html: "Please wait to generate PDF...!",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const images = await imageForPdf({ rowObject, driveRequest });
    const imagePdfData = mapImages(images);

    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;

    const createDocDef = docDefinition(
      `${rowObject?.inspectionName} Property Damage Form`,
      createPdfBody()?.concat(imagePdfData),
      (companyLogo = companyLogo)
    );

    const pdfDocGenerator = pdfMake.createPdf(createDocDef);
    let fileList = [];

    try {
      createPDF({
        action: "open",
        docDefinition: docDefinition(
          `${rowObject?.inspectionName} Property Damage Form`,
          createPdfBody()?.concat(imagePdfData),
          (companyLogo = companyLogo)
        ),
      });
      Swal.close();
    } catch (error) {
      Swal.fire({
        title: "<strong>Error</strong>",
        icon: "error",
        html: "There was an error while generating PDFs! ",
      });
    }

    return;

    const existedObject = await getBase64({
      pdfDocGenerator,
      title: "Inspecton",
      name: rowObject?.inspectionName,
    });

    fileList.push(existedObject);

    await Promise.allSettled(
      rowObject?.googleDriveUpload?.map(async (element) => {
        let pdfCondition = element.mimeType?.includes("application/pdf");
        let blob;
        let size;
        let file;

        if (pdfCondition) {
          await driveRequest
            .getImageSrc(element?.id)
            .then((r) => r)
            .then((res) => (blob = res?.src));

          const fileSrc = new Promise((resolve, reject) => {
            resolve(blob);
          });

          file = {
            fileSrc: fileSrc,
            name: element?.name,
            originFileObj: {
              uid: element?.id,
            },
            percent: 0,
            size: size,
            status: "uploading",
            type: "local",
            uid: element?.id,
          };

          fileList.push(file);
        }
      })
    );

    await axios
      .post(process.env.NODE_ENV === "production" ? prodUrl : devUrl, {
        originalPdf: [
          ...(await Promise.all(fileList?.map((el) => el?.fileSrc))),
        ]?.filter((el) => typeof el === "string"),
        configuration: {
          fileList: fileList,
          pagesConfig: {},
        },
      })
      .then(async ({ data: mergedPdf }) => {
        Swal.close();

        var blob = b64toBlob(mergedPdf, "application/pdf");
        const blobURL = URL.createObjectURL(blob);

        window.open(blobURL);

        await savePdfToDrive({
          blobData: mergedPdf,
          title: `Safety Inspections: ${rowObject?.inspectionName}`,
          driveRequest: driveRequest,
          folderId: rowObject?.googleDriveFolderIds?.[folderDriveKeyName],
        })
          .then((res) => {
            if (!!pdfId) {
              driveRequest.deleteDriveItem(pdfId);
            }
            setPdfId(res?.id);

            API.put("allInspections", `/allInspections/${defaultId}`, {
              body: {
                inspectionObject: {
                  ...defaultObj,
                  gDrivePdfId: res?.id,
                },
              },
            }).catch((err) => {
              console.error("Error Updating Inspection: ", err);
            });
          })
          .catch((err) => console.log({ Errrrrooooorr: err }));

        saveAddedLogs({
          recordId: defaultId,
          recordName: rowObject?.inspectionName,
          category: inspectionCategory,
          actionType: "Edit",
          topic: rowObject?.inspectionName,
          currentData: { User: "Created a PDF for this record" },
        })
          .then((res) => {
            setEditLogs((prev) => [...prev, ...res]);
          })
          .catch((err) => {
            console.error("Error Posting Logs: ", err);
          });
      })
      .catch((err) => console.log({ Erroooooooooooooooooor: err }))
      .catch(() => {
        Swal.fire({
          title: "<strong>Error</strong>",
          icon: "error",
          html: "There was an error while generating PDFs! ",
        });
      });
  }

  /**
   * Handles saving the email and updating the state.
   *
   * @param {Event} e - The event object.
   * @param {string} key - The key for the email.
   * @param {string} type - The type of the email.
   * @param {function} setLoading - The function to set the loading state.
   */
  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }

  function checkForChanges() {
    if (!defaultId) {
      let requiredFields = fieldsJSON
        ?.find(({ title }) => title === "General Information")
        ?.fields?.filter(({ required }) => required)
        ?.map(({ formItemName }) => formItemName);
      let fields = form.getFieldsValue(requiredFields);
      if (Object.values(fields).some((value) => !!value)) {
        setCancelModalVisible(true);
      } else {
        onClose();
      }
    } else {
      setCancelModalVisible(true);
    }
  }

  function onCancel() {
    if (!!changes) {
      checkForChanges();
    } else if (!changes && !defaultId) {
      checkForChanges();
    } else {
      onClose();
    }
  }
  const isMobileView = useMediaQuery({
    query: "(max-width: 575px)",
  });
  function sendEmailNotification() {
    broadcastNotification(
      "42",
      "onSafetyEmail",
      [
        {
          common: userConfiguration?.nameOfUser,
        },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: authenticatedUser?.sub,
          cognitos: getCognitosForNotification(
            userConfiguration,
            rowObject?.teamsConfiguration
          ),
        },
      ],
      defaultId
    );

    saveAddedLogs({
      recordId: defaultId,
      recordName: rowObject?.safetyName,
      actionType: "Email",
      category: inspectionCategory,
      topic: rowObject?.safetyName,
      currentData: { User: "Sent an email for this record" },
    })
      .then((res) => {
        setEditLogs((prev) => [...prev, res]);
      })
      .catch((err) => console.error("Error Posting logs", err));
  }

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  const sendEmailProps = {
    email: {
      // safetyInspection: true,
      // isFull: true,
      onClose: () => setEmailBox(false),
      setEmails: () => {},
      rowData: rowObject,
      onSave: (e, type, setLoading) => {
        onEmailSave({ e, type, setLoading });
        // setEmailBox(false);
      },
      isRedirected: [
        pdfId,
        ...uploadedFiles?.concat(requestUploads)?.map((el) => el?.id),
      ],
      isFromCharge: true,
      sendEmailNotification,
      proppedTemplateCat: "Safety",
    },

    reports: {
      rowData: rowObject,
      onClose: () => {
        if (attachments.length > 0) setAttachments([]);
        setEmailBoxes([]);
      },
      attachments,
      openEmailBox,
    },
  };
  //region JSX
  return (
    <>
      <Modal
        {...{
          open: visible,
          onCancel,
          title: (
            <CustomModalHeader
              title={`Safety Inspection Form (${inspectionCategory})`}
              headerInfo={() =>
                (userConfiguration?.groupName === "Admin" ||
                  userConfiguration?.groupName === "Super Admin") && (
                  <Tooltip title="Add New Fields">
                    <SettingIcon
                      className="settingIcon"
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/settings/safetyInspections`
                        )
                      }
                    />
                  </Tooltip>
                )
              }
              onClick={() => {
                !!videoTutorialLink
                  ? setVideoTutorial(true)
                  : message.info("No video tutorial available for this form");
              }}
            />
          ),
          afterOpenChange: (event) => {
            event &&
              updateDocumentTitle({
                newTitle: `Safety Inspection Form (${inspectionCategory})`,
              });
          },
          className: "safetyDynamicForms",
          closable: true,
          centered: true,
          wrapClassName: `safetyDynamicFormsWrap ${
            isDarkMode && "safetyDynamicFormsWrapDark"
          }`,
          closeIcon: <XIcon />,
          style: {
            display: !!dynamicFields ? "" : "none",
          },
          maskClosable: false,
          destroyOnClose: true,
          //region Modal Footer
          footer: modalFooter({
            changes,
            onCancel,
            isDarkMode,
            selectedSafety,
            setTasksVisible,
            onSave() {
              checkSaveDraft({
                saveFunction: onSave,
                setCancelModalVisible,
                form,
                setVisible,
                populatedInputFields: dynamicFields,
                descriptionValues,
                requiredTextArea,
                changes,
              });
            },
            form,
            defaultId,
            onDelete() {
              setCancelModalVisible("Delete");
            },
            onShowLogs() {
              setEditLogsVisibility(true);
            },
            onEmail() {
              setEmailBox(true);
              !pdfId && message.info("There is no PDF file for this report");
            },
            onGeneratePdf,
            selectedTeam,
            onReportView: () => setReportModal(true),
            hasReport: true,
            noteAccess,
            inspectionName: rowObject?.inspectionName,
            inspectionCategory,
            popoverProps,
            setShowPdfPreview,
          }),
        }}
      >
        {isMobileView ? (
          <div className="stepper-section-mobile">
            <InputComponent
              {...{
                label: "Status",
                type: "select",
                formItemName: "stepper",
                dropdownClassName: isDarkMode && "darkDropDown",
                customOptions: statusesColors?.map(
                  ({ statusId, statusName }) => ({
                    value: statusId,
                    label: statusName,
                  })
                ),
                onSelect(e) {
                  setCurrentStep(e);
                },
                defaultValue: currentStep,
              }}
            />
          </div>
        ) : (
          <div className="stepper-section">
            <Stepper
              {...{
                currentStep,
                setCurrentStep(e) {
                  form.setFieldValue("stepper", e);
                  setCurrentStep(e);
                },
                steps,
                statusesColors,
                stepperClassName: "stepper-component",
                stepRenderer: false,
              }}
            />
          </div>
        )}
        <Form form={form} onFieldsChange={() => setChanges(true)}>
          <div className="form-section">
            {fieldsJSON?.map(({ title, fields }) => {
              let sortedFields = fields;
              if (sortedFields.some((e) => !!e?.index)) {
                sortedFields = sortedFields.sort((a, b) => a?.index - b?.index);
              }
              return (
                <BorderedTextCard
                  title={title}
                  key={title}
                  isDarkMode={isDarkMode}
                >
                  {RenderDynamicComponents(sortedFields, { form })}

                  {(title === "Basic Information" ||
                    title === "General Information") && (
                    <div style={{ display: "flex", gap: "20px" }}>
                      <InputComponent
                        mode="multiple"
                        type="select"
                        label="Team"
                        required={true}
                        formItemName="team"
                        dropdownClassName={isDarkMode && "darkDropDown"}
                        allowClear={false}
                        customOptions={teams?.map((team) => ({
                          value: team?.teamName,
                          label: team?.teamName,
                          members: team?.members?.map(
                            ({
                              identityId,
                              nameOfUser,
                              cognitoUserId = "",
                            }) => ({
                              identityId,
                              nameOfUser,
                              cognitoUserId,
                            })
                          ),
                        }))}
                        onSelect={onTeamSelect}
                        onDeselect={onTeamDeselect}
                        showSearch={true}
                      />
                      {!!selectedTeam?.length && (
                        <MondayButton
                          {...{
                            onClick: () => {
                              setTeamModalVisible(true);
                            },
                            Icon: <PlusIcon />,
                            style: { marginTop: "25px" },
                          }}
                        >
                          Team Members
                        </MondayButton>
                      )}
                    </div>
                  )}
                </BorderedTextCard>
              );
            })}
            {inspectionCategory !== "General Inspection" && (
              <BorderedTextCard
                title={"Signature"}
                key={"signature"}
                isDarkMode={isDarkMode}
              >
                <InputComponent
                  type="datePicker"
                  formItemName="preparedOn"
                  dropdownClassName={isDarkMode && "darkDateDropDown"}
                  label="Prepared On"
                  required={true}
                  // style={{ marginTop: 8 }}
                  disabledDate={(e) => {
                    return e > dayjsNY().add(1, "day").startOf("D").valueOf();
                  }}
                />
                <InputComponent
                  required={true}
                  type="select"
                  label="Prepared By"
                  formItemName="preparedBy"
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  showSearch={true}
                  allowClear={true}
                  options={userConfiguration?.allUsers?.Items?.map(
                    ({ nameOfUser, identityId }) => (
                      <Option key={identityId} value={identityId}>
                        {nameOfUser}
                      </Option>
                    )
                  )}
                  // style={{ marginTop: "8px !important" }}
                />
                <Signature
                  {...{
                    signature: sign,
                    setSignature: setSign,
                  }}
                />
              </BorderedTextCard>
            )}
            {inspectionCategory === "General Inspection" && (
              <BorderedTextCard
                title={"Signature"}
                key={"signature"}
                isDarkMode={isDarkMode}
              >
                <InputComponent
                  type="timePicker"
                  formItemName="endTimeOfInspection"
                  dropdownClassName={isDarkMode && "darkDateDropDown"}
                  label="End Time of Inspection"
                  required={true}
                  // style={{ marginTop: 8 }}
                  disabledHours={() => getDisabledHours()}
                  disabledMinutes={() => getDisabledMinutes()}
                />
                <InputComponent
                  required={true}
                  type="select"
                  label="Full Name of Inspector"
                  formItemName="inspectorName"
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  showSearch={true}
                  allowClear={true}
                  customOptions={userConfiguration?.allUsers?.Items?.filter(
                    (e) => e?.groupName === "Inspector"
                  ).map((e) => ({
                    value: e.identityId,
                    label: e.nameOfUser,
                  }))}
                  // style={{ marginTop: "8px !important" }}
                />
                <Signature
                  {...{
                    signature: sign,
                    setSignature: setSign,
                  }}
                />
              </BorderedTextCard>
            )}
            <BorderedTextCard
              title="Notes"
              className="notesCard"
              isDarkMode={isDarkMode}
            >
              <IncidentComments
                {...{
                  form,
                  nameKey: "inspectionAddress",
                  rowObject,
                  setChanges,
                  userConfiguration,
                  refreshTable,
                  table: "allInspections",
                }}
              />
            </BorderedTextCard>
            <SafetyInspectionExportPreview
              {...{
                showPdfPreview,
                setShowPdfPreview,
                rowObject,
                userConfiguration,
                accessToken,
                inspectionCategory,
                fieldsJSON,
                selectedTeam,
                crewMembers,
                foremen,
                fleet,
                drivers,
              }}
            />
            {teamModalVisible && (
              <DynamicTeamModal
                {...{
                  open: teamModalVisible,
                  setOpen: setTeamModalVisible,
                  selectedTeam,
                  setSelectedTeam,
                  isDarkMode,
                  ClearOptions: () => {
                    setTeamModalVisible(false);
                  },
                }}
              />
            )}
          </div>
          <div className="uploaderSection">
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: divClickable ? 10 : 0,
                }}
                onClick={() => {
                  divClickable && setCancelModalVisible("Upload");
                }}
              ></div>
              <DocUploader
                {...{
                  uploadedFiles: [...uploadedFiles, ...requestUploads],
                  onPickerSuccess,
                  onDelete,
                  disabled:
                    !rowObject?.googleDriveFolderIds?.[folderDriveKeyName] &&
                    !driveFunctions.parentFolderId,
                  onChange() {},
                  folderId:
                    rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
                    driveFunctions.parentFolderId,
                  isCreateDocumentEnabled: true,
                  setDocumentationModalFiles,
                }}
              />
            </div>
          </div>
        </Form>
        {emailBox ||
          (emailBoxes?.length > 0 && (
            <div className="safetyEmailBox">
              <EmailBox
                {...(emailBox
                  ? { ...sendEmailProps?.email }
                  : { ...sendEmailProps?.reports })}
              />
            </div>
          ))}
      </Modal>

      {editLogsVisibility && (
        <MultiLevelTreeLogs
          {...{
            visible: editLogsVisibility,
            setVisible: setEditLogsVisibility,
            logsData: editLogs,
            title: "Safety Inspection Logs",
            viewLogs: displayViewLogs,
          }}
        />
      )}

      {tasksVisible && (
        <InspectionModal
          {...{
            visible: tasksVisible,
            onCancel: () => {
              setTasksVisible(false);
            },
            title: `Task for ${rowObject?.inspectionName}`,
            width: 1600,
            footer: false,
            closable: true,
            closeIcon: <XIcon fill="#333238" />,
            className: tasksVisible && `header-tasks-modal-light`,
          }}
        >
          <TasksBasePage
            {...{
              customTaskProps: {
                rowData: rowObject,
                taskTopic: "Safety",
                taskTopicId: defaultId,
                taskRelatedTo: rowObject?.inspectionName,
              },
            }}
          />
        </InspectionModal>
      )}
      {videoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: videoTutorial,
            setVisible: setVideoTutorial,
            url: videoTutorialLink,
            title: "Property Damage Form Tutorial",
          }}
        />
      )}

      {!!reportModal && (
        <Report
          {...{
            isModalVisible: reportModal,
            onCancel: () => setReportModal(false),
            customCategoryName: "Safety Inspection",
            customPartitionKeys: { identityId: "inspectionId" },
            recordId: defaultId,
            customData: [rowObject],
            setVisibleReport: setReportModal,
            emailBoxes,
            setEmailBoxes,
            setAttachments,
            sendByEmail: true,
          }}
        />
      )}

      {!!documentationModalFiles && (
        <DocumentationModal
          {...{
            visible: !!documentationModalFiles,
            setVisible: setDocumentationModalFiles,
            viewTitle: rowObject?.inspectionName,
            categoryName: "Inspections",
            docType: rowObject?.inspectionCategory,
            folderId: rowObject?.googleDriveFolderIds,
            recordId: rowObject?.inspectionId,
            recordValue: rowObject?.inspectionName,
            docStatus: rowObject?.inspectionStatus,
            docStatuses: statusesColors,
            googleDriveUploads: documentationModalFiles,
            setDocumentation: () => {},
            recordName: rowObject?.inspectionName,
            record: rowObject,
            accessToken,
            teamsConfiguration: selectedTeam.map((team) => ({
              teamName: team?.value,
              members: team?.members,
            })),
            isModalOpenedFromFileItem: true,
            onSaveParentModal: onSave,
          }}
        />
      )}

      <WarningModal
        visible={!!cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          {cancelModalVisible === "Upload" ? (
            <>
              <strong style={{ textAlign: "center" }}>
                If you choose to continue, you will not be able to change the
                address anymore
              </strong>
              <p>Are you sure you want to continue?</p>
            </>
          ) : cancelModalVisible === "Delete" ? (
            <p>Are you sure you want to delete?</p>
          ) : defaultId && cancelModalVisible !== "saveDraft" ? (
            <p>Are you sure you want to cancel?</p>
          ) : (
            <div style={{ textAlign: "center" }}>
              <strong>
                {cancelModalVisible !== "saveDraft"
                  ? "You are about to exit the form with unsaved data"
                  : "You have made some changes"}
              </strong>
              <p>Would you like to save the current changes as a draft?</p>
            </div>
          )}
          <div className="buttons">
            <MondayButton
              onClick={() => {
                if (
                  defaultId ||
                  cancelModalVisible === "Upload" ||
                  cancelModalVisible === "Delete"
                ) {
                  setCancelModalVisible(false);
                } else {
                  onClose({
                    deleteAction: true,
                  });
                }
              }}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                if (cancelModalVisible === "Upload") {
                  onContinueToUpload();
                } else if (cancelModalVisible === "Delete") {
                  onDeleteRow();
                } else {
                  if (defaultId && cancelModalVisible !== "saveDraft") {
                    onClose({
                      cancel: true,
                    });
                  } else {
                    onSave(true);
                  }
                }
              }}
              Icon={<TickIcon />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </>
  );
}

export default NewSafetyInspection;
