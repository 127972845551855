import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MondaySwitch } from "../../../../../commonComponents";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { DragIcon } from "../../../../../../icons";

function SortDnD({
  sortCategories,
  sortSwitch,
  setSortSwitch,
  category,
  setCategory,
  categories,
  data,
  reorderElement,
  isDarkMode,
  proppedCategory,
}) {
  return (
    <>
      {sortCategories && (
        <MondaySwitch
          {...{
            checked: sortSwitch,
            onToggleSwitch: (e) => {
              setSortSwitch(e);
            },
            onText: "Sort Categories",
            offText: "Sort Fields",
          }}
        />
      )}

      {!sortSwitch ? (
        <>
          <InputComponent
            {...{
              disabled: !!proppedCategory,
              label: "Category",
              initialValue: category,
              // value: category,
              onChange: (e) => setCategory(e),
              type: "select",
              customOptions: categories.map((el) => ({ label: el, value: el })),
              dropdownClassName: isDarkMode && "darkDropDown",
            }}
          />
          {category && data[category]?.length > 1 ? (
            <DragDropContext onDragEnd={reorderElement}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="droppableDiv"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {data[category]?.map((el, i) => {
                      return (
                        <Draggable
                          key={el.id}
                          draggableId={el.id.toString()}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              variant={
                                snapshot.isDragging ? "elevation" : "outlined"
                              }
                              elevation={4}
                            >
                              <div className="draggableDiv">
                                <span className="draggableSpan">
                                  {el.label}
                                </span>
                                <DragIcon />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            data[category]?.map((el, i) => {
              return (
                <div className="draggableDiv">
                  <span className="draggableSpan">{el.label}</span>
                </div>
              );
            })
          )}
        </>
      ) : (
        <>
          <DragDropContext onDragEnd={reorderElement}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="droppableDiv"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {Object.keys(data).map((cat, i) => {
                    return (
                      <Draggable
                        key={cat}
                        draggableId={cat.toString()}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            variant={
                              snapshot.isDragging ? "elevation" : "outlined"
                            }
                            elevation={4}
                          >
                            <div className="draggableDiv">
                              <span className="draggableSpan">{cat}</span>
                              <DragIcon />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </>
  );
}

export default SortDnD;
