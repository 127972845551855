import { filterTables } from "src/utils";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { generateInputFields } from "./helpers";
import { NextStepInputs } from "../NextStepInputs";

const Charge = ({ title, inputData, setInputData, savedRecord, category }) => {
  const [documentTypes, setDocumentTypes] = useState([]);

  const documents = documentTypes.flatMap((document) =>
    document.documentationsAvailable.map((type, index) => ({
      label: type.docName,
      value: type.docName,
      id: index,
    }))
  );
  const currentCategory = category === "Fleets" ? "Fleet" : category;

  const inputFields = generateInputFields({ documents });

  useEffect(() => {
    filterTables("docConfiguration", "categoryName", currentCategory).then(
      (result) => setDocumentTypes(result)
    );
  }, []);

  return <NextStepInputs {...{ inputData, setInputData, inputFields }} />;
};

export default Charge;
