import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import {
  InputComponent,
  NormalSizedModal,
} from "src/components/SidebarPages/Fleet/components";
import { MondayButton, WarningModal } from "src/components/commonComponents";
import { ReactComponent as DragIcon } from "src/icons/drag.svg";
import { TickIcon } from "../../Roles/src";
import { WarningIcon } from "src/icons";
import "./SortFields.scss";
import { LogsIcon } from "../../../../../SidebarPages/DynamicView/src";
import {
  MondaySwitch,
  MultiLevelTreeLogs,
  RedWarningModal,
} from "../../../../../commonComponents";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { useSelector } from "react-redux";
import { filterTables } from "../../../../../../utils";
import { message } from "antd";
import { cloneDeep } from "lodash";
import SortDnD from "./SortDnD";

export function removeNotChangedKeys(obj1, obj2) {
  const result = { ...obj2 };
  for (const key in obj1) {
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      result[key] = removeNotChangedKeys(obj1[key], obj2[key]);
    } else if (obj1[key] === obj2[key]) {
      delete result[key];
    }
  }
  return result;
}

export function removeEmptyKeys(obj) {
  const result = { ...obj };
  for (const key in obj) {
    if (
      (typeof obj[key] === "object" && !Object.keys(obj[key]).length) ||
      !obj[key]
    ) {
      delete result[key];
    }
  }
  return result;
}

function reduceData(data, fieldKey = "") {
  const cloneData = cloneDeep(data);
  let toReturn = {};
  toReturn = cloneData.reduce((acc, el) => {
    const cat = el[fieldKey];
    if (!acc[cat]) {
      acc[cat] = [];
    }
    acc[cat].push(el);
    return acc;
  }, {});
  return toReturn;
}

function SortFields({
  visible,
  setVisible,
  rowData = [],
  isDarkMode,
  fieldKey = "",
  title = "",
  onFinish = () => {},
  proppedCategory = "",
  sortCategories = false,
  saveText = "Save",
}) {
  const defaultArr = reduceData(rowData, fieldKey);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [category, setCategory] = useState(proppedCategory);
  const [changes, setChanges] = useState(false);
  const [warningModal, setWarningModal] = useState(null);
  const [data, setData] = useState(cloneDeep(defaultArr));
  const [sortSwitch, setSortSwitch] = useState(false);

  const [logsVisible, setLogsVisible] = useState(false);
  const [logsData, setLogsData] = useState([]);

  const [collectLogs, setCollectLogs] = useState({
    curr: {},
    prev: {},
  });

  const recordName = `${title} Sorted Dynamic Fields`;

  const newEditLog = () => {
    let tmp = removeEmptyKeys(
      removeNotChangedKeys(collectLogs.prev, collectLogs.curr)
    );
    let updatedLogs = {
      curr: removeNotChangedKeys(collectLogs.prev, collectLogs.curr),
      prev: removeNotChangedKeys(collectLogs.curr, collectLogs.prev),
      updatedKeys: Object.values(tmp).flatMap(Object.keys),
    };
    return {
      recordId: recordName,
      recordName: recordName,
      actionType: "Edit",
      topic: `Sorted ${title} Fields`,
      category: "Sorted Fields",
      currentData: removeEmptyKeys(updatedLogs.curr),
      label: `Sorted ${title} Fields`,
      member: userConfiguration.nameOfUser,
      nameOfUser: userConfiguration.nameOfUser,
      cognitoUserId: userConfiguration?.cognitoUserId,
      previousData: removeEmptyKeys(updatedLogs.prev),
      updatedKeys: updatedLogs.updatedKeys,
      updatedAt: dayjsNY().valueOf(),
    };
  };

  const categories = [
    ...new Set(
      rowData.map((el) => {
        let cat = el[fieldKey];
        return cat;
      })
    ),
  ];

  function reorderElement(result) {
    if (!result.destination) return;
    const startIndex = result?.source?.index;
    const endIndex = result?.destination?.index;
    if (startIndex === endIndex) return;
    !changes && setChanges(true);
    if (!sortSwitch) {
      let tmp = data[category];
      const [removed] = tmp.splice(startIndex, 1);
      tmp.splice(endIndex, 0, removed);
      tmp.forEach((item, index) => {
        item.index = index + 1;
      });
      setData((prev) => ({ ...prev, [category]: tmp }));
      let name = tmp[endIndex].label;
      let oldIndex = defaultArr[category].findIndex(
        (el) => el.id === result.draggableId
      );
      setCollectLogs((prev) => ({
        prev: {
          ...prev.prev,
          [category]: {
            ...prev.prev[category],
            [name]: oldIndex + 1,
          },
        },
        curr: {
          ...prev.curr,
          [category]: {
            ...prev.curr[category],
            [name]: endIndex + 1,
          },
        },
      }));
    } else {
      let tmp = [...Object.entries(data)];
      const [removed] = tmp.splice(startIndex, 1);
      tmp.splice(endIndex, 0, removed);
      tmp.forEach((item, index) => {
        item[1].forEach((el, i) => {
          el.catIndex = index + 1;
        });
      });
      setData(Object.fromEntries(tmp));
      let name = removed[0];
      let oldIndex = startIndex;
      setCollectLogs((prev) => ({
        prev: {
          ...prev.prev,
          "Section Move": {
            ...prev.prev["Section Move"],
            [name]: oldIndex + 1,
          },
        },
        curr: {
          ...prev.curr,
          "Section Move": {
            ...prev.curr["Section Move"],
            [name]: endIndex + 1,
          },
        },
      }));
    }
  }
  useEffect(() => {
    if (!sortSwitch) {
      if (!category) return;
      let tmp = data?.[category] || [];
      if (tmp.some((el) => !!el.index)) {
        tmp.sort((a, b) => a.index - b.index);
      }
      setData((prev) => ({ ...prev, [category]: tmp }));
    } else {
      let tmp = Object.entries(data);
      if (tmp.some((el) => !!el[1][0].catIndex)) {
        tmp.sort((a, b) => a[1][0].catIndex - b[1][0].catIndex);
      }
      setData(Object.fromEntries(tmp));
    }
  }, [category, sortSwitch]);

  function onSave() {
    let flat = Object.values(data).flat();
    const newLog = newEditLog();
    onFinish(flat, newLog);
    setVisible(false);
  }
  function onCancel() {
    setData(defaultArr);
    setVisible(false);
  }

  async function openLogs() {
    message.loading({
      content: "Getting Logs...",
      key: "logsLoading",
      duration: 0,
    });
    await filterTables(
      "editLogs",
      "recordName",
      `${title} Sorted Dynamic Fields`
    ).then((res) => {
      message.success({
        content: "Logs Loaded",
        key: "logsLoading",
      });
      setLogsData(res);
      setLogsVisible(true);
    });
  }

  return (
    <NormalSizedModal
      {...{
        visible,
        setVisible,
        title: "Sort Fields",
        onCancel: () => {
          !changes ? onCancel() : setWarningModal("cancel");
        },
        centered: true,
        className: `sortFieldsModal ${isDarkMode && "sortFieldsModalDark"}`,
        closeIcon: <XIcon />,
        maskClosable: true,
        destroyOnClose: true,
        closable: true,
        customFooter: (
          <div className="sortFieldsModalFooter">
            <MondayButton
              {...{
                className: "mondayButtonRed",
                onClick: () => {
                  !changes ? onCancel() : setWarningModal("cancel");
                },
                Icon: <XIcon />,
              }}
            >
              Cancel
            </MondayButton>
            {saveText === "Save" ? (
              <MondayButton
                {...{
                  onClick: openLogs,
                  Icon: <LogsIcon />,
                  className: "mondayButtonBlue",
                }}
              >
                Logs
              </MondayButton>
            ) : null}
            <MondayButton
              {...{
                onClick: () => {
                  setWarningModal("save");
                },
                Icon: <TickIcon />,
                disabled: !changes,
              }}
            >
              {saveText}
            </MondayButton>
          </div>
        ),
      }}
    >
      <SortDnD
        {...{
          sortCategories,
          sortSwitch,
          setSortSwitch,
          category,
          setCategory,
          categories,
          data,
          reorderElement,
          isDarkMode,
          proppedCategory,
        }}
      />
      {!!warningModal && (
        <RedWarningModal
          visible={!!warningModal}
          onCancel={() => setWarningModal(false)}
          children={
            warningModal === "cancel" ? (
              <p>Are you sure you want to cancel all changes?</p>
            ) : (
              <p>Are you sure you want to save changes</p>
            )
          }
          titleText="Warning Message"
          confirmModal={true}
          footerProps={{
            onConfirm: warningModal === "cancel" ? onCancel : onSave,
          }}
        />
      )}
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: logsData || [],
            title: "Sorting Dynamic Fields Logs",
          }}
        />
      )}
    </NormalSizedModal>
  );
}

export default SortFields;
