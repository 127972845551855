import { v4 as uuidv4 } from "uuid";

export const getBase64 = async ({
  pdfDocGenerator,
  title = "Property Damage",
  name,
}) => {
  return new Promise((resolve, reject) => {
    pdfDocGenerator.getBase64((base64String) => {
      let id = uuidv4();
      const file = {
        fileSrc: new Promise((resolve, reject) => {
          resolve(`data:application/pdf;base64,${base64String}`);
        }),
        // lastModifiedDate: elementData?.modifiedTime,
        name: `${title} ${name}`,
        originFileObj: {
          uid: id,
        },
        percent: 0,
        size: Math.ceil((base64String.length * 3) / 4),
        status: "uploading",
        type: "local",
        uid: id,
      };

      resolve(file);
    });
  });
};
