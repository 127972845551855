import { debounce, keys, uniqBy } from "lodash";
import { searchFetchInput } from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import { getDispatchLocationOptions } from "../../requestFormModalData";
import {
  assignedToObject,
  teamSelect,
  defaultSafetyCategories,
  projectManagerSelect,
  REQUEST_API_KEYS,
} from "..";
import { message } from "antd";
import sortFields from "../sortFields";

/**
 * Generates an array of fields for an incident request form.
 *
 * @param {Array} teams - The list of teams.
 * @param {Array} projects - The list of projects.
 * @param {Array} schedules - The list of schedules.
 * @param {Array} inputFields - The list of input fields.
 * @param {boolean} isDarkMode - Indicates if the form is in dark mode.
 * @param {Array} projectManagers - The list of project managers.
 * @param {string|null} currentPageKey - The current page key.
 * @param {Object} userConfiguration - The user configuration.
 * @param {boolean} globalDisabled - Indicates if all fields are disabled.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The sorted array of fields for the incident request form.
 */
const incidentRequestFields = ({
  teams = [],
  projects = [],
  schedules = [],
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  currentPageKey = null,
  userConfiguration = {},
  globalDisabled = false,
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  onMainFieldSelect = () => {},
  // setRecordsLoading = () => {},
  dispatchRequestData = () => {},
}) => {
  const onSearch = debounce(
    async (searchValue) =>
      await searchFetchInput({
        table: ["projects", "scheduling"],
        idKey: ["projectId", "scheduleId"],
        arrayKey: ["projects", "schedules"],
        searchKey: ["projectName", "scheduleAddress"],
        searchValue,
        keysToInclude: REQUEST_API_KEYS.Incident,
        setLoading: (val) => {
          if (val === true) {
            message.loading({
              content: "Getting data...",
              duration: 0,
              key: "dataLoad",
            });
          } else {
            message.destroy("dataLoad");
          }
        },
        setRecords: (res) => {
          const resToUse = (res || [])?.reduce(
            (acc, curr) => ({
              ...acc,
              ...curr?.value,
            }),
            {}
          );

          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: {
              projects: userAccessRecords(
                userConfiguration,
                uniqBy(
                  [...(projects || []), ...(resToUse?.projects || [])],
                  "projectId"
                )
              ),
              schedules: userAccessRecords(
                userConfiguration,
                uniqBy(
                  [...(schedules || []), ...(resToUse?.schedules || [])],
                  "scheduleId"
                )
              ),
            },
          });
        },

        userConfiguration,
        setCurrentPageKey: () => {
          currentPageKey.current = val;
        },
      }),
    500
  );

  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "incidentCategory") {
      delete field?.options;

      Object.assign(field, {
        options: defaultSafetyCategories || [],
      });
    }

    if (formItemName === "jobsiteAddress") {
      delete field?.options;

      Object.assign(field, {
        type: "placesautocomplete",
        groupedOptions: getDispatchLocationOptions(schedules, projects),
        style: { minWidth: 300 },
        showSearch: true,

        onSelect: (_, opt) => {
          onMainFieldSelect({
            val: opt?.id,
            opt,
          });
        },

        onClear: () =>
          onMainFieldSelect({
            val: "",
            projects: [],
            schedules: [],
            clear: true,
          }),

        customOnSearch: onSearch,
      });
    }

    if (formItemName === "assignedTo") {
      Object.assign(field, {
        ...assignedToObject({
          title: "Incidents",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "team") {
      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "projectManager") {
      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default incidentRequestFields;
