import { useStateContext } from "../../../../../../../../../../Context/Context";
import DetailsHeader from "./Components/DetailsHeader/DetailsHeader";
import DetailsBody from "./Components/DetailsBody/DetailsBody";
import "./DetailsContainer.scss";
import { useRedux } from "../../../../../../../../../../../../../../hooks";

const DetailsContainer = ({
  setSelectedWeek,
  selectedWeek,
  elevationData,
  allRow,
}) => {
  // const { color } = useStateContext()
  const [color] = useRedux("rentalsColor");
  return (
    <div
      className="rentalDetailsBodyContainer"
      style={{ boxShadow: `0px 0px 6px 3px ${color}` }}
    >
      <DetailsHeader {...{ allRow, elevationData, selectedWeek }} />
      <DetailsBody
        {...{ elevationData, allRow, setSelectedWeek, selectedWeek }}
      />
    </div>
  );
};

export default DetailsContainer;
