import uniq from "lodash/uniq";

export const incidents = ({ tables }) => {
  const nypd = { Yes: 0, No: 0 };
  const fdny = { Yes: 0, No: 0 };
  const paramedics = { Yes: 0, No: 0 };
  const osha = { Yes: 0, No: 0 };
  const dobBestSquad = { Yes: 0, No: 0 };
  const incidentWitnessed = { Yes: 0, No: 0 };
  const injuredPerson = { Yes: 0, No: 0 };

  tables?.Incidents?.forEach((a) => {
    nypd[a?.incidentReport[1]?.values[0]?.value] += 1;
    fdny[a?.incidentReport[1]?.values[1]?.value] += 1;
    paramedics[a?.incidentReport[1]?.values[2]?.value] += 1;
    osha[a?.incidentReport[1]?.values[3]?.value] += 1;
    dobBestSquad[a?.incidentReport[1]?.values[4]?.value] += 1;
    incidentWitnessed[a?.incidentReport[2]?.values[0]?.value] += 1;
    injuredPerson[a?.incidentReport[2]?.values[1]?.value] += 1;
  });
  return {
    driverName: () => ({
      content: uniq(tables?.Incidents?.map((a) => a?.driverName))?.map(
        (driverName, index) => ({
          key: index,
          title: driverName,
          taskNumber: tables?.Incidents?.length,
          taskDone: tables?.Incidents?.filter(
            (a) => a?.driverName === driverName
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Incidents By " + driverName,
          contentsHandler: " Incidents By " + driverName,
        })
      ),
    }),

    incidentStatus: () => ({
      content: uniq(tables?.Incidents?.map((a) => a?.incidentStatus))?.map(
        (incidentStatus, index) => ({
          key: index,
          title: incidentStatus,
          taskNumber: tables?.Incidents?.length,
          taskDone: tables?.Incidents?.filter(
            (a) => a?.incidentStatus === incidentStatus
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " " + incidentStatus,
          contentsHandler: " " + incidentStatus,
        })
      ),
    }),

    fleetName: () => ({
      content: uniq(tables?.Incidents?.map((a) => a?.fleetName))?.map(
        (fleetName, index) => ({
          key: index,
          title: fleetName,
          taskNumber: tables?.Incidents?.length,
          taskDone: tables?.Incidents?.filter((a) => a?.fleetName === fleetName)
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Incidents By " + fleetName,
          contentsHandler: " Incidents By " + fleetName,
        })
      ),
    }),

    NYPD: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Incidents?.length,
          taskDone: nypd?.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes`,
          contentsHandler: ` Yes`,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Incidents?.length,
          taskDone: nypd?.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No`,
          contentsHandler: ` No`,
        },
      ],
    }),

    FDNY: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Incidents?.length,
          taskDone: fdny?.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes`,
          contentsHandler: ` Yes`,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Incidents?.length,
          taskDone: fdny?.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No`,
          contentsHandler: ` No`,
        },
      ],
    }),

    PARAMEDICS: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Incidents?.length,
          taskDone: paramedics?.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes`,
          contentsHandler: ` Yes`,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Incidents?.length,
          taskDone: paramedics?.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No`,
          contentsHandler: ` No`,
        },
      ],
    }),

    OSHA: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Incidents?.length,
          taskDone: osha?.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes`,
          contentsHandler: ` Yes`,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Incidents?.length,
          taskDone: osha?.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No`,
          contentsHandler: ` No`,
        },
      ],
    }),

    dobBestSquad: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Incidents?.length,
          taskDone: dobBestSquad?.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes`,
          contentsHandler: ` Yes`,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Incidents?.length,
          taskDone: dobBestSquad?.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No`,
          contentsHandler: ` No`,
        },
      ],
    }),

    incidentWitnessed: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Incidents?.length,
          taskDone: incidentWitnessed?.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes`,
          contentsHandler: ` Yes`,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Incidents?.length,
          taskDone: incidentWitnessed?.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No`,
          contentsHandler: ` No`,
        },
      ],
    }),

    injuredPerson: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Incidents?.length,
          taskDone: injuredPerson?.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes`,
          contentsHandler: ` Yes`,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Incidents?.length,
          taskDone: injuredPerson?.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No`,
          contentsHandler: ` No`,
        },
      ],
    }),
  };
};
