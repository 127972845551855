import { dayDetail } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";

function isProjectCompleted(schedules = []) {
  if (!schedules || !schedules?.length) return false;
  let temp = [];
  schedules?.forEach((schedule) => {
    const { toBeScheduled = {}, days = [] } = schedule;
    temp = Object.values(
      dayDetail(
        toBeScheduled,
        days?.map(({ dayId = "" }) => dayId),
        true
      ) || {}
    )
      ?.flat()
      ?.flatMap(({ services = [] }) => services);
  });
  return temp?.length > 0 && temp?.every((ser) => ser?.serviceProgress === 100);
}

export default isProjectCompleted;
