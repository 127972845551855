import { Modal, Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { antdModalProps, updateArrayElement } from "../../../../../../utils";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { useTakeOffContext } from "../../context";
import "./ScopeSelectorModal.scss";

const ScopeSelectorModal = ({ visible, setVisible }) => {
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const scopes = serviceDefinitions.filter(({ isScope }) => !!isScope);
  const { gridData, updateStateAndSave } = useTakeOffContext();

  // <Select/> value
  const [selectedScopeIds, setSelectedScopeIds] = useState([]);

  // Destructures the "Other Scope" service
  const otherScopeService =
    gridData?.find?.(({ serviceId }) => serviceId == 9999) || {};
  const { serviceOptions = [[]] } = otherScopeService;

  // Returns the scopes that have not been added to the grid
  const availableScopesToAdd = scopes.filter(
    ({ serviceId }) =>
      !serviceOptions[0].some(({ elevationId }) => elevationId === serviceId)
  );

  // Closes the modal
  const onCancel = () => {
    setSelectedScopeIds([]);
    setVisible(false);
  };

  // Adds selected scopes to the grid
  const onSave = () => {
    // Returns an object with the scope id as the key and the scope as the value
    const scopesObject = scopes.reduce(
      (acc, curr) => ({ ...acc, [curr.serviceId]: curr }),
      {}
    );

    // Array with the selected scopes objects
    const selectedScopes = selectedScopeIds.map((id) => scopesObject[id]);

    // "Other Scope" service with the new elevations and PLI-s
    const updatedOtherScopeService = {
      ...otherScopeService,
      serviceOptions: [
        [
          ...serviceOptions[0],
          ...selectedScopes.map(
            ({ serviceId, serviceName, columnDefinitions }) => ({
              elevationId: serviceId,
              elevationLabel: serviceName,
              items: [
                columnDefinitions.reduce(
                  (acc, { field, initialValue }) => ({
                    ...acc,
                    [field]: initialValue,
                  }),
                  { id: 1 }
                ),
              ],
            })
          ),
        ],
      ],
    };

    const newGridData = updateArrayElement(
      gridData,
      updatedOtherScopeService,
      "serviceId"
    );

    updateStateAndSave(newGridData);
    onCancel();
  };

  //updates the state
  const onScopeSelect = (scopeIds) => setSelectedScopeIds(scopeIds);

  return (
    <Modal
      {...{ ...antdModalProps, open: visible, onCancel, title: "Add Scope" }}
    >
      <div className="scope-selector-modal-content">
        <Select
          showSearch
          mode="multiple"
          value={selectedScopeIds}
          placeholder="Select scopes..."
          className="scope-selector"
          onChange={onScopeSelect}
          options={availableScopesToAdd.map(({ serviceName, serviceId }) => ({
            label: serviceName,
            value: serviceId,
          }))}
        />
        <div className="footer">
          <MondayButton Icon={<TickIcon />} onClick={onSave}>
            Save
          </MondayButton>
        </div>
      </div>
    </Modal>
  );
};

export default ScopeSelectorModal;
