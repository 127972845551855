import { axesTooltipCustom } from "../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "subType",
    groupKey: "subcontractorType",
    xAxisName: "Subcontractor Type",
  },
  {
    chartName: "recordType",
    groupKey: "accountRecordType",
    xAxisName: "Opportunity Status",
  },
  {
    chartName: "borough",
    groupKey: "borough",
    xAxisName: "Opportunity Stage",
  },
  {
    chartName: "createdBy",
    groupKey: "createdBy",
    xAxisName: "Subcontractors",
  },
];

export const getChartingOptions = (data) => {
  let subType = new Set();
  let recordType = new Set();
  let borough = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const subcontractType = item.subcontractorType || "No Data";
    const accRecordType = item.accountRecordType || "No Data";
    const oStage = item.borough || "No Data";
    const user = item.createdBy || "No Data";

    subType.add(subcontractType);
    recordType.add(accRecordType);
    borough.add(oStage);
    createdBy.add(user);
  });

  const countOnSubcontractorType = {
    title: {
      text: "Total Subcontractors Count",
    },
    subtitle: {
      text: "Based on Subcontractor Type",
    },
    series:
      Array.from(subType).map((type) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: type,
          yName: type,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Subcontractors"),
          },
        };
      }) || [],
  };

  const countOnAccRecordType = {
    title: {
      text: "Total Subcontractors Count",
    },
    subtitle: {
      text: "Based on Account Record Type",
    },
    series:
      Array.from(recordType).map((status) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: status,
          yName: status,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Subcontractors"),
          },
        };
      }) || [],
  };

  const countOnBorough = {
    title: {
      text: "Total Subcontractors Count",
    },
    subtitle: {
      text: "Based on Borough",
    },
    series:
      Array.from(borough).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Subcontractors"),
          },
        };
      }) || [],
  };

  const countOnCreatedBy = {
    title: {
      text: "Total Subcontractors Count",
    },
    subtitle: {
      text: "Based on Created By",
    },
    series:
      Array.from(createdBy).map((user) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: user,
          yName: user,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Subcontractors"),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle:
        "Show total Subcontractors count based on Subcontractor Type",
      params: countOnSubcontractorType,
      key: "subType",
    },
    {
      optionTitle:
        "Show total Subcontractors count based on Account Record Type",
      params: countOnAccRecordType,
      key: "recordType",
    },
    {
      optionTitle: "Show total Subcontractors count based on Borough",
      params: countOnBorough,
      key: "borough",
    },
    {
      optionTitle: "Show total Subcontractors count based on Created By",
      params: countOnCreatedBy,
      key: "createdBy",
    },
  ];

  return chartingOptions;
};
