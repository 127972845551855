import { Button, Popover } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";

import ExportOrEmailDropdown from "../../../ExportOrEmailDropdown/ExportOrEmailDropdown";
import {
  preparedExcelTColumnsForGeoFences,
  preparedExcelToExportForGeoFences,
  resolveStructureToShowInPdf,
} from "../utils.js";
import { useSelector } from "react-redux";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "./../../../../pages/Settings/settingsComponents/DocumentationConfiguration/components/DocumentationsPanel/ExpirationDateEnhacment/utils.js";

const FenceActions = ({ geofenceStrucutre, showNavigator }) => {
  const { base64 } = useSelector((state) => state.base64);

  const columnsToExport = [
    "Created At",
    "Created By",
    "Description",
    "title",
    "Type",
    "Image",
  ];

  const popoverContent = (
    <div className="custom-drawing-manager">
      <div style={{ display: "flex", gap: "10px" }}>
        <div className="">
          <Button
            icon={<MenuUnfoldOutlined />}
            onClick={showNavigator}
            className="fenceActionsButton"
          >
            Details Sider
          </Button>
        </div>
        <div className="">
          <ExportOrEmailDropdown
            {...{
              rowData: true,
              defaultDocDefinition: true,
              title: "Geofence",
              topicCategory: "Geofence",
              position: "absolute",

              onGeneratePDF: async (action, getDocDefinition) => {
                const dataToExport = await resolveStructureToShowInPdf(
                  geofenceStrucutre
                );
                return onGeneratePDF({
                  action,
                  base64,
                  getDocDefinition,
                  dataToExport,
                  columnHeader: columnsToExport,
                  titleOfPd: "GeoFences",
                });
              },
              exportGridToExcel: (getDocDefinition) =>
                exportToExcel({
                  getDocDefinition,
                  dataToExport:
                    preparedExcelToExportForGeoFences(geofenceStrucutre),
                  columnHeader: preparedExcelTColumnsForGeoFences,
                  title: "GeoFences",
                }),
              uploadExcelToDrive: (driveRequest, driveFolderId) =>
                uploadExcelToDrive({
                  dataToExport: preparedExcelToExportForGeoFences(),
                  columnHeader:
                    preparedExcelTColumnsForGeoFences(geofenceStrucutre),
                  driveRequest,
                  driveFolderId,
                  title: "GeoFences",
                }),
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        className="fenceActionsPopover"
        trigger="hover"
        content={popoverContent}
        placement="bottom"
      >
        <button>
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/ios-filled/25/menu-2.png"
            alt="menu-2"
          />
        </button>
      </Popover>
    </>
  );
};

export default FenceActions;
