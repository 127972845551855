import { useEffect } from "react";
import { Badge } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { updateDocumentTitle } from "../../../../utils";
import { WithTooltip } from "../../../commonComponents";
import { NotificationIcon } from "../../subcomponents";
import NotificationsDrawer from "./NotificationsDrawer";
import { fetchNotifications } from "./utils/fetchNotifications";
import {
  calculateTotalNotificationsUnreadCount,
  getFilters,
} from "./utils/helpers";
import { useNotificationsDrawer } from "./providers/NotificationsDrawerProvider";

function Notifications() {
  const dispatch = useDispatch();
  const { open, toggleDrawer } = useNotificationsDrawer();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { notificationSettings } = useSelector(
    (state) => state.notificationSettings
  );
  const { notifications, tab, filterBy } = useSelector(
    (state) => state.notifications
  );

  const totalCount = calculateTotalNotificationsUnreadCount(notifications);

  const { notifications: actualNotifications, lastEvaluatedKey } = filterBy
    ? notifications[tab][filterBy] || []
    : notifications[tab]?.unfiltered || [];

  const fetchData = () => {
    if (notificationSettings?.length && authenticatedUser?.sub) {
      const filtersStructured = getFilters(
        notificationSettings,
        filterBy?.toLowerCase() || "",
        tab?.toLowerCase() || "",
        authenticatedUser?.sub
      );

      try {
        fetchNotifications(
          authenticatedUser,
          notificationSettings,
          dispatch,
          filtersStructured,
          lastEvaluatedKey,
          filterBy
        );
      } catch (error) {
        console.log({ error });
      }
    }
  };

  useEffect(() => {
    if (actualNotifications?.length === 0 && tab !== "communication") {
      fetchData();
    }
  }, [notificationSettings, authenticatedUser, tab, filterBy]);

  useEffect(() => {
    updateDocumentTitle({ notificationsCount: notifications?.length });
  }, [actualNotifications?.length]);

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="notifications">
        <Badge className="ignore-onclickoutside" count={totalCount}>
          <NotificationIcon
            onClick={() => toggleDrawer()}
            className="header-icon"
          />
        </Badge>
      </WithTooltip>

      <NotificationsDrawer
        open={open}
        fetchData={fetchData}
        notificationsData={actualNotifications}
        lastEvaluatedKey={lastEvaluatedKey}
      />
    </div>
  );
}

export default Notifications;
