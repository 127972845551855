import moment from "moment"
export const findDispatchInformations = ({
  list,
  parent,
  getDate,
  getTime,
}) => {
  const date = moment(getDate).format("MM/DD/YYYY")
  return !!list.filter((value) => value.fleetName === parent.fleetName).length
    ? list
        .filter((value) => value.fleetName === parent.fleetName)
        .filter((el) => {
          return (
            parseInt(el.timeScheduled) <
              parseInt(
                moment(`${date} ${getTime}`, "MM/DD/YYYY h:mma").unix()
              ) &&
            parseInt(el.timeExitingTheLocation) >
              parseInt(moment(`${date} ${getTime}`, "MM/DD/YYYY h:mma").unix())
          )
        })
        ?.map(({ driverName, dropOffLocation }) => {
          return {
            driverName,
            dropOffLocation,
          }
        })[0] || {}
    : {}
}
