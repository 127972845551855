import { txtForBadgeRibbonMigrate } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import getScheduleDateRange from "./getScheduleDateRange";
// import InputGridEditor from "../../../../Settings/settingsComponents/Crews/Components/UploadEmployees/components/Cell Components/InputGridEditor";

const fieldTableColumnDefs = ({ t }) => {
  return [
    {
      headerName: t("Project Address") || "Project Address",
      field: "projectName",
      headerClass: "custom-header",
      cellClass: "custom-cell",
      enableRowGroup: true,
    },
    {
      headerName: t("Estimates") || "Estimates",
      field: "services",
      headerClass: "custom-header",
      cellClass: "custom-cell",
      enableRowGroup: true,
      valueGetter: (params) => {
        return txtForBadgeRibbonMigrate(
          Object.keys(params?.data?.services || {})?.length,
          t("Estimates")
        );
      },
    },
    {
      headerName: t("Period") || "Period",
      field: "schedules",
      headerClass: "custom-header",
      cellClass: "custom-cell",
      enableRowGroup: true,
      valueGetter: (params) => {
        return getScheduleDateRange(params?.data?.schedules);
      },
      // cellRenderer: ({ data, context }) => {
      //   context.navigate();
      // },
    },
    // {
    //   headerName: "Employee Name",
    //   field: "crewName",
    //   editable: true,
    //   singleClickEdit: true,
    //   cellRenderer: () => {
    //     return 211;
    //   },
    //   cellEditor: InputGridEditor,
    // },
  ];
};

export default fieldTableColumnDefs;
