import { Tick } from "../../../../../../../../../../DynamicView/components/FilterView/FilterIcons";
import {
  useStateContext,
  useDispatchContext,
} from "../../../../../../Context/Context";
import {
  changeRetainageHandler,
  inputChangerHandler,
} from "./retainageModalFunctions";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { PercentageIcon } from "../../../../../../../../../../BasePage/src";
import "./retainage-modal.scss";
import { message } from "antd";
import { Modal, Input } from "antd";
import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { handleChangeRetForAll } from "../../../../HoistService/ServiceHeader/Components/RetainageModal/retainageModalFunctions";

const RetainageModal = ({ service }) => {
  const [isRetainageModalVisible, setIsRetainageModalVisible] = useState(false);
  const [retainageState, setRetainageState] = useState(service?.retainage || 0);

  // Context state & dispatch
  const { fakeSOV, darkMode, isWritable } = useStateContext();
  const dispatchContext = useDispatchContext();

  const closeRetainageModal = () => {
    setIsRetainageModalVisible(false);
  };

  const changeRetainage = () => {
    changeRetainageHandler({
      setIsRetainageModalVisible,
      dispatchContext,
      retainageState,
      fakeSOV,
      service,
    });
    setIsRetainageModalVisible(false);
  };

  const changeRetainageForAll = () => {
    return handleChangeRetForAll({
      closeRetainageModal,
      dispatchContext,
      retainageState,
      fakeSOV,
    });
  };

  //handles the new value that we put on input
  const inputChanger = (e) => inputChangerHandler({ e, setRetainageState });

  return (
    <>
      <MondayButton
        className={`changeRetainageSOVButton ${
          isWritable ? "" : "buttonNotClickable"
        }`}
        onClick={() =>
          isWritable
            ? setIsRetainageModalVisible(true)
            : message.error("Please enable Edit Mode!")
        }
        Icon={<PercentageIcon />}
        //disabled={!isWritable}
      >
        Change Retainage
      </MondayButton>

      <Modal
        open={isRetainageModalVisible}
        title="Change Retainage"
        destroyOnClose={true}
        wrapClassName={
          darkMode
            ? "service-header-retainage-modal-dark"
            : "service-header-retainage-modal"
        }
        onCancel={closeRetainageModal}
        footer={
          <div className="serviceFooterRetainageModal">
            <MondayButton
              className="cancelMondayButton"
              Icon={<CloseOutlined />}
              onClick={closeRetainageModal}
            >
              Cancel
            </MondayButton>
            <MondayButton
              className="applyMondayButton"
              Icon={<Tick />}
              onClick={changeRetainage}
            >
              Apply
            </MondayButton>{" "}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<Tick />}
              style={{ marginLeft: "10px" }}
              onClick={changeRetainageForAll}
            >
              Apply to All
            </MondayButton>
          </div>
        }
      >
        <Input
          className="changeRetainageInput"
          placeholder="Enter new Retainage"
          value={retainageState}
          onChange={inputChanger}
        />
      </Modal>
    </>
  );
};

export default RetainageModal;
