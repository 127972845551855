import dayjs from "dayjs";

/**
 * Generates whole weeks based on the provided start date and end date
 *
 * @param {int} startDate timestamp
 * @param {int} endDate timestamp
 * @returns {Object} Object of whole weeks {[day]:{startDate}}
 */
const weekDays = (startDate, endDate, acc = [], id = 1) => {
  const calculatedStartDate = dayjs(startDate)
    ?.startOf("day")
    ?.add(1, "day")
    ?.valueOf();
  acc = [...acc, { id, day: dayjs(startDate)?.startOf("day")?.valueOf() }];

  return dayjs(startDate)?.add(1, "day")?.valueOf() <
    dayjs(endDate).endOf("day")
    ? weekDays(calculatedStartDate, endDate, acc, id + 1)
    : acc;
};
/**
 * Generates whole weeks based on the provided start date and end date
 *
 * @param {int} startDate timestamp
 * @param {int} endDate timestamp
 * @returns {Object} Object of whole weeks {[week]:{startDate,endDate}}
 */

export const generateWeeks = (
  startDate,
  endDate,
  week = 1,
  acc = [],
  month = 1,
  controller = 0
) => {
  if (!startDate || !endDate) return;
  const calculatedStartDate = dayjs(startDate)
    ?.startOf("day")
    ?.add(week === 1 ? 0 : 1, "week")
    ?.valueOf();

  const calculatedEndDate = dayjs(calculatedStartDate)
    ?.startOf("day")
    ?.add(6, "day")
    ?.valueOf();

  if (controller !== 4) {
    controller = controller + 1;
  } else {
    controller = 1;
    month = month + 1;
  }

  acc = [
    ...acc,
    {
      rowSpan: controller === 1,
      month,
      week,
      weekStart: calculatedStartDate,
      weekEnd:
        dayjs(calculatedEndDate)?.endOf("day")?.valueOf() <
        dayjs(endDate)?.startOf("day")?.valueOf()
          ? calculatedEndDate
          : endDate,

      days: weekDays(
        calculatedStartDate,
        dayjs(calculatedEndDate)?.endOf("day") > dayjs(endDate).startOf("day")
          ? endDate
          : calculatedEndDate
      ),
    },
  ];

  if (
    dayjs(calculatedEndDate)?.endOf("day")?.valueOf() >=
    dayjs(endDate)?.startOf("day")?.valueOf()
  ) {
    return acc;
  }

  return generateWeeks(
    calculatedStartDate,
    endDate,
    week + 1,
    acc,
    month,
    controller
  );
};
