import CustomCheckBox from "../CustomCheckBox/CustomCheckBox";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { sortCheckedAndUnchecked } from "./utils/sortCheckedAndUnchecked";

const HeadersList = ({
  headers,
  setHeaders,
  selectedHeaders,
  removeOptionFromTable,
  addOptionToTable,
  search,
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    if (source.index === destination.index) return;

    const movedItem = headers[source.index];
    const updatedHeaders = Array.from(headers);
    updatedHeaders.splice(source.index, 1);
    updatedHeaders.splice(destination.index, 0, movedItem);

    const sortedHeaders = sortCheckedAndUnchecked(
      updatedHeaders,
      selectedHeaders
    );

    setHeaders(sortedHeaders);
  };

  const filteredHeaders = headers.filter(
    (header) =>
      selectedHeaders.some(({ name }) => name === header.name) ||
      header.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {filteredHeaders.map((header, index) => {
              const isChecked = selectedHeaders.find(
                ({ id }) => id === header.id
              );
              return (
                <Draggable
                  key={header.id.toString()}
                  draggableId={header.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CustomCheckBox
                        name={header.name}
                        checked={isChecked}
                        onChange={() =>
                          isChecked
                            ? removeOptionFromTable(header)
                            : addOptionToTable(header)
                        }
                        style={{ marginBottom: "10px", width: "99%" }}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default HeadersList;
