import { defaultExcelHeaderStyle } from "../../../../../helpers/constants/defaultExcelHeaderStyle";

export const getExcelHeaders = (status) => {
  return status === "Active Automations"
    ? [
        { v: "Automation Name", s: defaultExcelHeaderStyle },
        { v: "Created By", s: defaultExcelHeaderStyle },
        { v: "Category", s: defaultExcelHeaderStyle },
        { v: "Status", s: defaultExcelHeaderStyle },
        { v: "Frequency", s: defaultExcelHeaderStyle },
      ]
    : [
        { v: "Error", s: defaultExcelHeaderStyle },
        { v: "Response", s: defaultExcelHeaderStyle },
        { v: "Description", s: defaultExcelHeaderStyle },
        { v: "Date", s: defaultExcelHeaderStyle },
      ];
};
