import React, { useState, useEffect } from "react";
import { Button, message, Tooltip, Table } from "antd";
import { WeekWeatherIcon } from "../../../assets";
import getWeatherIcon from "./Scheduling/helpers/getWeatherIcon";
import { MondayButton } from "../../commonComponents";
import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import { NormalSizedModal } from "../../SidebarPages/Fleet/components";
import "./WeatherComponent.scss";

const WeatherComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [weatherAlert, setWeatherAlert] = useState(false);
  const [weatherAlert1, setWeatherAlert1] = useState(false);
  const [weatherAlert2, setWeatherAlert2] = useState(false);
  const [weatherAlert3, setWeatherAlert3] = useState(false);
  const [highestSpeed, setHighestSpeed] = useState("");
  const fullWeatherArray = props?.fullWeatherArray || [];

  useEffect(() => {
    if (props?.weatherThisDay) {
      handleWind(props?.weatherThisDay);
    }
  }, [props?.weatherThisDay]);

  useEffect(() => {
    if (
      new Date(props?.startDate).getTime() !==
        new Date(props?.prevProps).getTime() ||
      props?.lat !== props?.prevProps?.lat
    ) {
      handleWind(props?.weatherThisDay);
    }
  }, [props?.startDate, props?.lat]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleWind = (weatherThisDay) => {
    try {
      let windSpeed = weatherThisDay?.[0]?.windSpeed;
      let highestSpeed;
      if (windSpeed?.includes("to ")) {
        let speeds = windSpeed?.split("to ");
        highestSpeed = speeds?.[1]?.split(" mph");
      } else {
        highestSpeed = windSpeed?.split(" mph");
      }

      const alerts = checkAlerts(highestSpeed?.[0]);
      setHighestSpeed(highestSpeed);
      setWeatherAlert(alerts?.weatherAlert);
      setWeatherAlert1(alerts?.weatherAlert1);
      setWeatherAlert2(alerts?.weatherAlert2);
      setWeatherAlert3(alerts?.weatherAlert3);
    } catch (e) {
      console.error(e);
      message.warning("No weather data for this day");
    }
  };

  const checkAlerts = (highestSpeed) => {
    let weatherAlert = false;
    let weatherAlert1 = false;
    let weatherAlert2 = false;
    let weatherAlert3 = false;
    const highestSpeedInt = parseInt(highestSpeed);

    if (highestSpeedInt > 12 && highestSpeedInt < 15) {
      weatherAlert = true;
    }
    if (highestSpeedInt > 15 && highestSpeedInt < 20) {
      weatherAlert1 = true;
    }
    if (highestSpeedInt > 20 && highestSpeedInt < 25) {
      weatherAlert2 = true;
    }
    if (highestSpeedInt > 25) {
      weatherAlert3 = true;
    }

    return { weatherAlert, weatherAlert1, weatherAlert2, weatherAlert3 };
  };

  const columns = [
    {
      title: "DAY",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "shortForecast",
      key: "shortForecast",
      align: "center",
      render: (text, record) => (
        <span>
          {text}{" "}
          <img src={getWeatherIcon(text || "")} alt="" style={{ height: 40 }} />
        </span>
      ),
    },
    {
      title: "TEMPERATURE",
      dataIndex: "temperature",
      key: "temperature",
      align: "center",
      render: (text, record) => (
        <span>
          {text} {record?.temperatureUnit}
        </span>
      ),
    },
    {
      title: "WIND DIRECTION",
      dataIndex: "windDirection",
      key: "windDirection",
      align: "center",
    },
    {
      title: "WIND SPEED",
      dataIndex: "windSpeed",
      key: "windSpeed",
      align: "center",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props?.weatherThisDay ? (
          <Button
            type="primary"
            onClick={openModal}
            className="mondayButtonBlue"
            icon={<WeekWeatherIcon />}
          >
            Check This Week's Weather
          </Button>
        ) : (
          ""
        )}

        <div style={{ paddingTop: 20 }}>
          {props?.weatherThisDay?.map((element, index) => (
            <div key={index.toString()}>
              <div className="ui-tablist">
                <div className="ui-tablist-item">
                  <div className="inline-info">
                    <span>
                      {dayjsNY(props?.startDate).format("MM/DD/YYYY")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ui-tablist">
                <div className="ui-tablist-item">
                  <div className="inline-info">
                    <img
                      src={getWeatherIcon(element?.shortForecast)}
                      alt=""
                      style={{ fontSize: 14, height: 40, width: 40 }}
                      className="inline-info"
                    />
                  </div>
                </div>
              </div>
              <div className="ui-tablist">
                <div
                  className="ui-tablist-item"
                  style={{ border: "none", width: 260 }}
                >
                  <div className="inline-info">
                    <span>{element?.shortForecast}</span>
                  </div>
                </div>
              </div>
              <div className="ui-tablist">
                <div className="ui-tablist-item">
                  <div className="inline-info">
                    <b>Temperature:</b>{" "}
                    {!!element?.temperatureUnit
                      ? element?.temperature + " " + element?.temperatureUnit
                      : element?.temperature}
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="ui-tablist">
                <div className="ui-tablist-item">
                  <div className="inline-info">
                    <b>Wind Direction:</b>&nbsp;
                    <span>{element?.windDirection}</span>
                  </div>
                </div>
              </div>
              {weatherAlert1 &&
              !weatherAlert &&
              !weatherAlert2 &&
              !weatherAlert3 ? (
                <Tooltip
                  title="More Likely To Cancel!"
                  arrow
                  placement="bottom"
                >
                  <div
                    className="ui-tablist"
                    style={{ borderColor: "#cf1b1b" }}
                  >
                    <div className="ui-tablist-item" style={{ width: 250 }}>
                      <div className="inline-info">
                        <b>Wind Speed:</b>&nbsp;
                        <div style={{ fontSize: 16 }}>{element?.windSpeed}</div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
              {weatherAlert &&
              !weatherAlert1 &&
              !weatherAlert2 &&
              !weatherAlert3 ? (
                <Tooltip title="Possible Cancel!" arrow placement="bottom">
                  <div
                    className="ui-tablist"
                    style={{ borderColor: "#cf1b1b" }}
                  >
                    <div className="ui-tablist-item" style={{ width: 250 }}>
                      <div className="inline-info">
                        <b>Wind Speed: </b>&nbsp;
                        {element?.windSpeed}
                      </div>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
              {weatherAlert2 &&
              !weatherAlert &&
              !weatherAlert3 &&
              !weatherAlert1 ? (
                <Tooltip
                  title="Very Likely To Cancel!"
                  arrow
                  placement="bottom"
                >
                  <div
                    className="ui-tablist"
                    style={{ borderColor: "#cf1b1b" }}
                  >
                    <div className="ui-tablist-item" style={{ width: 250 }}>
                      <div className="inline-info">
                        <b>Wind Speed: </b>&nbsp;
                        {element?.windSpeed}
                      </div>
                      <div style={{ fontSize: 16 }}></div>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
              {weatherAlert3 &&
              !weatherAlert &&
              !weatherAlert1 &&
              !weatherAlert2 ? (
                <Tooltip title="Absolutely Cancel!" arrow placement="bottom">
                  <div
                    className="ui-tablist"
                    style={{ borderColor: "#cf1b1b" }}
                  >
                    <div className="ui-tablist-item" style={{ width: 250 }}>
                      <div className="inline-info">
                        <b>Wind Speed: </b>&nbsp;
                        {element?.windSpeed}
                      </div>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
              {!weatherAlert &&
              !weatherAlert1 &&
              !weatherAlert2 &&
              !weatherAlert3 ? (
                <div className="ui-tablist">
                  <div className="ui-tablist-item" style={{ width: 250 }}>
                    <div className="inline-info">
                      <b>Wind Speed: </b>&nbsp;
                      {element?.windSpeed}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {index !== props?.weatherThisDay?.length - 1 && (
                <hr style={{ border: "1px solid #323338" }}></hr>
              )}
            </div>
          ))}
        </div>
        {open && (
          <NormalSizedModal
            visible={open}
            onCancel={closeModal}
            maskClosable={true}
            maxWidth="lg"
            closable={true}
            title={`This Week’s Weather | ${dayjsNY(
              fullWeatherArray?.[0]?.endTime
            ).format("MM/DD/YYYY")} - ${dayjsNY(
              fullWeatherArray?.[fullWeatherArray?.length - 1]?.endTime
            ).format("MM/DD/YYYY")}`}
            className={`fullWeekWeather ${
              props?.isDarkMode && "fullWeekWeatherDark"
            }`}
          >
            <Table
              columns={columns}
              dataSource={fullWeatherArray}
              scroll={{ y: 555 }}
              pagination={false}
            />
          </NormalSizedModal>
        )}
      </div>
    </div>
  );
};

export default WeatherComponent;
