import { createContext } from "react";
import {
  DispatchType,
  VendorType,
  DriverType,
  FleetType,
  GeoFenceInfo,
} from "src/components/SidebarPages/FleetMaintenanceView/types";

/**
 * @typedef {lat: number, lng: number} LatLng
 */

/**
 * @typedef OffProjectData
 * @property {string} dispatchDate
 * @property {string} dropOff
 * @property {string} pickUp
 * @property {LatLng} dropOffCoordinates
 * @property {LatLng} pickUpCoordinates
 * @property {string} activityId
 * @property {string} dispatchId
 * @property {number} routeIndex
 * @property {string} driverName
 * @property {string} driverId
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string} departAt
 * @property {string} arriveBy
 * @property {"Loaded"|"Empty"} cargo
 * @property {boolean} isAudited
 */

/**
 * @typedef {OffProjectData & {projectId: string}} OffScheduleData
 */

/**
 * @typedef ViewFilters
 * @property {string|null} fleetId
 * @property {string|null} driverId
 * @property {string|null} date
 * @property {"Off Project"|"Off Schedule"} records
 * @property {"USER"|"AUTOMATIC"} dateChangeReason
 */

/**
 * @typedef SelectedViewData
 * @property {string|null} id
 * @property {string|null} address
 */

/**
 * @template T
 * @typedef {Record<string, T>} Dictionary
 */

/**
 * @typedef MatchDataItem
 * @property {string} id
 * @property {string} name
 * @property {string} differentiatorName - For records with the same name, we want a way to differentiate between those
 * @property {string} differentiatorId
 * @property {string} address
 * @property {LatLng} [position]
 * @property {boolean} [forceMatch=false]
 * @property {GeoFenceInfo[]} [geoFenceInfo]
 * @property {boolean} [isNameSameAsAddress=true]
 * @property {any} originalItem
 */

/**
 * @typedef MatchData
 * @property {MatchDataItem} item
 * @property {any[]|null} subitems - If we don't have subitems, then we need to fetch them
 */

/**
 * @typedef UpdateViewData
 * @property {"PROJECT"|"ALL"} type
 * @property {string|null} address
 * @property {string[]|null} ids
 */

/**
 *
 * @typedef {(param: {error: "NOT_FOUND"|"MULTIPLE_IDS"|null, data: MatchDataItem[]}) => any} UpdateViewCallback
 */

/**
 * @typedef FilteredProjectsType
 * @property {string} address
 * @property {"Off Project"|"Off Schedule"} type
 */

/**
 * @typedef ProjectMatchContextType
 * @property {Record<string, OffProjectData[]>} offProjectData
 * @property {VendorType[]} vendors
 * @property {Record<string, DispatchType>} dispatchRecords
 * @property {DispatchType[]} dispatches
 * @property {Record<string, OffScheduleData[]>} offScheduleData
 * @property {ViewFilters} filters
 * @property {(newFilter: Partial<ViewFilters>) => any} onFilterChange
 * @property {(data: UpdateViewData, [callback]: UpdateViewCallback) => Promise<void>} updateViewType
 * @property {(text: string) => void} textFilterHandler
 * @property {DriverType[]} drivers
 * @property {FleetType[]} vehicles
 * @property {SelectedViewData} selectedViewData
 * @property {"PROJECT"|"ALL"} viewType
 * @property {boolean} mapLoaded
 * @property {Dictionary<Dictionary<MatchData>>} matchedData  - { [address]: { [id1 for address]: {item, [subitems]}, [id2]: {...} } }
 * @property {(param: {address: string, id: string, data: Partial<MatchData>, address?:string}) => any} updateMatchedData
 * @property {string|null} selectedId
 * @property {(newId: string) => any} updateSelectedId
 * @property {(state: boolean) => any} changeConfirmDisabled
 * @property {(text: string|undefined) => any} changeConfirmText
 * @property {(dispatches: DispatchType[]) => any} updateDispatchesHandler
 * @property {() => void} openFilteredProjectsModal
 * @property {FilteredProjectsType[]} filteredAddresses
 * @property {(addresses: FilteredProjectsType[]) => void} updateFilteredAddresses
 * @property {() => void} openManualMatchModal
 * @property {(newMatches: Dictionary<MatchData>, address: string) => void} onManualMatchConfirm
 * @property {(project: Record<string, any>, [opened=true]) => MatchDataItem} mapProject
 */

/** @type {ProjectMatchContextType} */
const contextInitialValue = {};

const ProjectMatchContext = createContext(contextInitialValue);

export default ProjectMatchContext;
