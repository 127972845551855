import { useContext, useEffect } from "react";
import VideoTutorialContext from "./contexts/VideoTutorialContext";
import { VideoTutorialIcon } from "./assets/svg";
import TestTutorial from "./VideoTutorial";
import { Badge } from "antd";
import { useQuery } from "../../../../hooks";
import { WithTooltip } from "../../../commonComponents";

export const ModalVideoTutorials = () => {
  const tutorialsOpen = useQuery().get("tutorialsOpen");

  const { setActivePrimaryCat, idOfSubArray, visible, setVisible } =
    useContext(VideoTutorialContext);

  //open modal from On Boarding component
  useEffect(() => {
    if (!!tutorialsOpen) {
      setVisible(true);
    }
  }, [tutorialsOpen]);

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="videoTutorials">
        <Badge className="ignore-onclickoutside" count={idOfSubArray.length}>
          <VideoTutorialIcon
            onClick={() => {
              setActivePrimaryCat();
              setVisible((prev) => !prev);
            }}
            className="header-icon"
          />
        </Badge>
      </WithTooltip>

      {visible && <TestTutorial />}
    </div>
  );
};
