export const defaultColDef = {
	editable: false,
	hide: false,
	filter: true,
	sortable: false,
	resizable: true,
	autoHeight: true,
	enablePivot: true,
	enableRowGroup: false,
	// flex: 0.7,
	width: 200,
	flex: 1,
	minWidth: 200,
};
