import InputGridEditor from "../components/Cell Components/InputGridEditor";
import SelectGridEditor from "../components/Cell Components/SelectGridEditor";

const columnDefs = [
  {
    headerName: "Employee Name",
    field: "crewName",
    editable: true,
    singleClickEdit: true,

    cellEditor: InputGridEditor,
  },
  {
    headerName: "Role",
    field: "crewPosition",
    editable: true,
    cellEditor: SelectGridEditor,
  },
  {
    headerName: "Employee Id",
    field: "employeeId",
    editable: true,
    cellEditor: InputGridEditor,
  },
  {
    headerName: "Rate",
    field: "employeeRate",
    editable: true,
    cellEditor: InputGridEditor,
  },
  {
    headerName: "Salary",
    field: "salaryType",
    editable: true,
    cellEditor: SelectGridEditor,
  },
  {
    headerName: "Company",
    field: "accountName",
    editable: true,
    cellEditor: SelectGridEditor,
  },
  {
    headerName: "Foreman",
    field: "foreman",
    cellRenderer: ({ data }) => {
      return data.foreman ? "Foreman" : "Employee";
    },
  },
  {
    headerName: "Remove",
    cellRenderer: ({ api, data }) => {
      return (
        <span
          className="remove-member-cell"
          onClick={() => {
            api.applyTransaction({ remove: [data] });
          }}
        >
          Remove Member
        </span>
      );
    },
  },
];

export default columnDefs;
