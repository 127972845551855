import { Button, Modal, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import {
  PriceList,
  PriceSchemesType,
  PriceSheet,
} from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { generateRedColorByAmount } from "../../../../../pages/Settings/settingsComponents/Pricing/utils/colorGenerator";
import { useRedux } from "../../../../hooks";

const { Option } = Select;

const emptyPriceScheme = {
  ...new PriceSheet("empty"),
  ...new PriceList("empty"),
};

/**
 * @param props.priceSchemes {PricingObject.priceSchemes}
 * @param props.activePriceSheetModal {number | Boolean}
 * @param props.selectedPriceScheme {PriceSheet|PriceList}
 * @param props.onHandleShowSidewalkShedPriceSheetClose {function}
 * @param props.priceSchemesType {String= 'sheet'|'list'}
 * @param props.serviceLabel {String} Name of service
 * */
export const PriceSchemeModal = (props) => {
  const activePriceSheetModal = props.activePriceSheetModal;
  const priceSchemes = props.priceSchemes;
  const [darkMode] = useRedux("estimationsDarkMode");

  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [showHeatMap, setShowHeatMap] = useState(true);

  const currentScheme =
    gridData?.find((service) => service?.label === props?.serviceLabel)
      ?.priceScheme || emptyPriceScheme;
  const [selectedPriceScheme, setSelectedPriceScheme] = useState(currentScheme);
  const { x, y, z, prices, id } = selectedPriceScheme;
  const defaultVal = !!props?.priceSchemes?.find((ps) => ps?.id === id)
    ? id
    : null;
  useEffect(() => {
    //when price schemes changes (everytime user open/closes this modal)
    // if (props.selectedPriceScheme === undefined)
    //   setSelectedPriceScheme(emptyPriceScheme);
    // //if modal is closed and no price scheme is available
    // else {
    //when modal is open and the selected scheme from parent (dataEntryGrid) is passed
    setSelectedPriceScheme(currentScheme);
    // }
  }, [priceSchemes, gridData, activePriceSheetModal]);

  const handleChangePriceScheme = (value) => {
    //when we change price scheme from <Select>
    console.log("handleChangePriceScheme", { gridData, props });
    setSelectedPriceScheme(props.priceSchemes.find((ps) => ps.id === value));
  };

  function handleOk() {
    if (props.selectedPriceScheme !== undefined) {
      let temp = structuredClone(gridData);
      let myService = temp.find(
        (service) => service.label === props?.serviceLabel
      );
      myService.priceScheme = selectedPriceScheme;
      setTakeOffTableData(temp);
      props.onPriceSchemeModalClose(selectedPriceScheme.id);
      props?.handleSave(true, true, temp);
    }
    //if there are schemes available
    else props.onPriceSchemeModalClose(); //if modal is open but there has not been any scheme available
  }

  function handleCancel() {
    props.onPriceSchemeModalClose();
  }

  console.log("selectedPriceScheme", {
    priceSchemes,
    id,
    props,
  });
  // if(selectedPriceScheme)
  return (
    <Modal
      width={"100%"}
      className={"addonSchemePicker__modal" + (darkMode ? "__dark" : "")}
      title={`${props.serviceLabel} Price ${props.priceSchemesType}`}
      open={
        activePriceSheetModal !== false &&
        props.priceSchemesType !== PriceSchemesType.TABLE
      }
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {Array.isArray(priceSchemes) && priceSchemes.length > 0 ? ( //when no price schemes are available (may be as a result that pricingObject may not exist at all)
        <div className="price-modal-container">
          <Select
            value={defaultVal}
            onChange={handleChangePriceScheme}
            placeholder="Select a price scheme"
            style={{
              width: "300px",
            }}
          >
            {priceSchemes
              ?.filter(
                (sch) => sch?.markedAsDeleted !== true && sch?.type !== "PLI"
              )
              ?.map((ps) => (
                <Option value={ps.id}>{ps.name}</Option>
              ))}
          </Select>
          <Switch
            style={{ marginLeft: 10 }}
            checked={showHeatMap}
            onChange={() => {
              setShowHeatMap(!showHeatMap);
            }}
            unCheckedChildren={"Heat Map"}
            checkedChildren={"Heat Map"}
          />
          {props.priceSchemesType === PriceSchemesType.SHEET
            ? renderSheet(x, y, z, showHeatMap)
            : renderList(prices, showHeatMap)}
        </div>
      ) : (
        <div>No Price Schemes available for this service</div>
      )}
    </Modal>
  );
};
const renderSheet = (x, y, z = [], showHeatMap) => {
  let min = z?.[0]?.[0],
    max = 0;
  for (const row of z) {
    for (const cell of row) {
      const cellFloat = parseFloat(cell);
      if (cellFloat < min) min = cellFloat;
      if (cellFloat > max) max = cellFloat;
    }
  }

  return (
    <div className="price-sheet-component">
      <div className="price-sheet-width-label">Width</div>
      <div className="price-sheet-height-table-element">
        <div className="price-sheet-height-label">Height</div>
        <div className="sidewalkShedPriceSheetTableContainer">
          <div className="sSPSRow xAxis">
            <div className="sSPSCol blankArea" />
            <div className="sSPSCol aggregateCorner" />
            {x?.map((key, xIndex) => (
              <div key={xIndex} className="sSPSCol yAxis">
                <input
                  disabled={true}
                  type="number"
                  className="sSPSInput"
                  min={0}
                  value={key}
                />
              </div>
            ))}
          </div>
          {z?.map((z2, yIndex) => {
            return (
              <div key={yIndex} className="sSPSRow">
                <div className="sSPSCol yAxis">
                  <input
                    disabled={true}
                    type="number"
                    className="sSPSInput"
                    min={0}
                    value={y[yIndex]}
                  />
                </div>
                {z2?.map((value, xIndex) => {
                  return (
                    <div key={xIndex} className="sSPSCol">
                      <input
                        disabled={true}
                        type="number"
                        className="sSPSInput"
                        min={0}
                        value={value}
                        {...(showHeatMap
                          ? {
                              style: {
                                backgroundColor: generateRedColorByAmount(
                                  min,
                                  max,
                                  value
                                ),
                              },
                            }
                          : {})}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const renderList = (prices, showHeatMap) => {
  //calculate min and max
  let min = prices?.[0]?.value || 0,
    max = 0;

  if (Array.isArray(prices) && prices.length > 0) {
    for (const p of prices) {
      const v = parseFloat(p.value);
      if (v < min) min = v;
      if (v > max) max = v;
    }
  }

  return (
    <div className="price-list-container">
      <div className="price-list-header">
        <div className="price-list-column-header">Name</div>
        <div className="price-list-column-header">Price</div>
      </div>
      <div className="price-list-body">
        {prices?.map((price, index) => {
          const coloredStyle = showHeatMap
            ? {
                backgroundColor: generateRedColorByAmount(
                  min,
                  max,
                  price.value
                ),
              }
            : {};

          return (
            <div key={index} className="price-list-row">
              <div className="price-list-cell">{price.name}</div>
              <div className="price-list-cell" style={coloredStyle}>
                {price.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
