import { useEffect } from "react"
import { useSelector } from "react-redux"

const ClassRedux = ({ varName, stateName, setState, notNested = false }) => {
  let value = useSelector((state) => state[stateName])
  useEffect(() => {
    setState(notNested ? value : value[varName])
  }, [varName, value])
  return null
}
export default ClassRedux
