import React from "react";
import NoConversationImage from "../../../../../assets/icons/noConversation.png";
import "./NoConversationContent.scss";
const NoConversationContent = () => {
  return (
    <div className="noConversationContent">
      <img src={NoConversationImage} />
      <span className="welcomeToInternalChat">Welcome to Internal Chat!</span>
      <div className="texts">
        <span>Select an ongoing conversation from the list on the left.</span>
        <span>
          Start a new conversation using the "New Conversation" button above.
        </span>
        <span>
          If you need help, the Virtual Assistant is here to assist you with any
          queries.
        </span>
      </div>
    </div>
  );
};

export default NoConversationContent;
