import { MondayButton } from "../../../../../../../commonComponents"
import { Plus } from "../../../../../../DynamicView/components/FilterView/FilterIcons"
import { Close } from "../../../../../../DynamicView/src"

function Footer({ onCancel, onSave }) {
  return (
    <div className="footer-section">
      <MondayButton
        className="mondayButtonRed"
        Icon={<Close />}
        onClick={onCancel}
      >
        Cancel
      </MondayButton>
      <MondayButton Icon={<Plus />} onClick={onSave}>
        Save
      </MondayButton>
    </div>
  )
}

export default Footer
