import { Buffer } from "buffer";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { markEmailsAsRead } from "../../../../../../../actions/notificationsActions";
import { useGoogleSignIn } from "../../../../../../../hooks/useGoogleSignIn";
import { callGmailAPI } from "../../../../../../SidebarPages/Communication/functions";
import { EmailIcon } from "../../../../Approvals/assets";

import { formatCommunicationTimestamp } from "../../../../../../SidebarPages/Communication/utils/formatCommunicationTimestamp";
import "./communication-item-view.scss";
import { useNotificationsDrawer } from "../../../providers/NotificationsDrawerProvider";

function CommunicationItemView({ email }) {
  const { id, from, to, subject, timestampDate, recordName, recordId, labels } =
    email;
  const navigate = useNavigate();
  const { toggleDrawer } = useNotificationsDrawer();
  const dispatch = useDispatch();
  const { googleAccessToken, userLoggedIn } = useGoogleSignIn();

  const createdAt = parseInt(timestampDate);
  const formattedCreatedAt = formatCommunicationTimestamp(createdAt);

  let authData = userLoggedIn
    ? Buffer.from(JSON.stringify(googleAccessToken)).toString("base64")
    : null;

  const handleOnClick = async () => {
    const req = {
      emailIds: [id],
      moveTo: [],
      removeFrom: [],
      markAs: ["READ"],
      authData,
    };

    const { data } = await callGmailAPI("changeLabel", req);

    if (data === "success") {
      dispatch(markEmailsAsRead(id, false));
      navigate(
        `${recordName}/${recordId}?tab=Communication&category=inbox&threadId=${id}`
      );
      toggleDrawer();
    }
  };

  return (
    <div
      className={`communication-item-view-container ${
        labels.includes("UNREAD") ? "unread" : ""
      }`}
      onClick={handleOnClick}
    >
      <div className="communication-item-view-content-container">
        <div className="email-icon-container">
          <EmailIcon />
        </div>
        <p>
          <strong>{from}</strong> has sent email to <strong>{to}</strong> with
          subject <strong>{subject}</strong>
        </p>
      </div>
      <p className="created-at">{formattedCreatedAt}</p>
    </div>
  );
}

export default CommunicationItemView;
