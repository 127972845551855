import _ from "lodash";

//in this function we extract all the weeks from the selected elevation, in the elevation that we want to find the applied value
export const appliedRentalGenerator = ({ fakeRentals, elevationData, selectedService }) => {
	let result = fakeRentals?.services
		//here we find the object of the service that we have selected
		?.find(({ label }) => label === selectedService)
		//here we extract the object of the elevation that we want to find the applied amount
		?.serviceOptions?.find(({ elevationId }) => elevationId === elevationData?.elevationId)
		//here we extract all weeks of that elevation and saves them in an array
		?.items?.flatMap(({ rentalDetails: { details } }) => details)
		.flatMap(({ days }) => days)
		//here we calculate the total applied amount from those weeks
		?.reduce((acc, curr) => _.add(acc, curr?.dailyAmount), 0);
	return result;
};

export const totalitiesTaxGen = ({ fakeRentals, elevationData, selectedService }) => {
	let carriedProjectTax = fakeRentals?.taxRate || 0;
	let result = {
		totalTax: 0,
		totalPrice: appliedRentalGenerator({ fakeRentals, elevationData, selectedService }) || 0,
		retainage:
			fakeRentals?.services?.find(({ label }) => label === selectedService)?.retainage || 0,
	};
	if (carriedProjectTax > 0) {
		result.totalTax = result.totalPrice * carriedProjectTax;
		result.totalPrice = result.totalPrice + result.totalTax;
	}
	result.retainageAmount = (result?.retainage / 100) * result?.totalPrice;
	return result;
};

export const appliedRentPliCalculator = ({ rentalDetails = [] }) => {
	let result = rentalDetails
		?.flatMap(({ days }) => days)
		?.reduce((acc, curr) => _.add(acc, curr?.dailyAmount), 0);
	return result;
};
