import { updateRental } from "../../../../../../../../Utils/rentalManipulation";

//this function is used to update fake rentals context
export const handleSave = ({
  setModalVisibility,
  setFakeRentals,
  selectedService,
  elevationData,
  fakeRentals,
  allRow,
  form,
}) => {
  //id of the elevation that the richText modal is in
  const thisElevation = elevationData?.elevationId;
  //if of the pli that the richText modal is in
  const thisPli = allRow?.id;

  // here generates the new fake rental with the modified row(the row that we open notes)
  const newFakeRental = updateRental({
    selectedService,
    thisElevation,
    fakeRentals,
    thisPli,
    pliProps: { note: form.getFieldValue("note") },
  });

  //here we save the newly created rental to context
  setFakeRentals(newFakeRental);
  //this closes the modal after save
  setModalVisibility(false);
};
