import PanelHeaderInput from "../../GridData/detailsComponents/panelHeaderInput/PanelHeaderInput";
import HeaderCheckBox from "../../GridData/detailsComponents/headerCheckBox/HeaderCheckBox";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../../Context/Context";
import { defaultColDef, frameworkComponents } from "../../../../../GridData";
import {
  Expend,
  Retrackt,
} from "../../../../../../../../../../../BasePage/src";
import { formatCurrency } from "../../../../../../../../../../../utils";
import { cellEditingStarted } from "../../../serviceContentFunctions";
import { valueChanger } from "../../GridData/functions/valueChanger";
import { addonsColumnDefs, columnDefs } from "../../GridData";
import "./ServiceDetailsBody.scss";
import {
  setDetailsApplicationHandler,
  getTotalPriceOfElevation,
} from "./serviceDetailsBodyFunctions";

import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "antd";
import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import AgGridMathful from "../../../AgGridSum/AgGridMathful";
const { Panel } = Collapse;

const ServiceDetailsBody = ({
  setActivePanel,
  activePanel,
  borderColor,
  gridParams,
  service,
  panels,
}) => {
  const { unchangingFakeApplication, fakeApplication, darkMode, isWritable } =
    useStateContext();
  const { programFields } = useSelector((state) => state.programFields);
  const [selected, setSelected] = useState(false); // Context state & dispatch

  const dispatchContext = useDispatchContext();
  const { applicationId } = useParams();
  const dispatch = useDispatch();

  const retainage = fakeApplication?.services?.find(
    (serviceData) =>
      serviceData?.serviceId === service?.serviceId &&
      serviceData?.estimationId === service?.estimationId
  )?.retainage;

  const setDetailsApplication = (newApplication) =>
    setDetailsApplicationHandler({
      dispatchContext,
      newApplication,
      newApplication,
    });

  // Calculate total price of elevation
  const totalPriceOfElevation = (elevation) =>
    getTotalPriceOfElevation({ elevation, service });

  const rowData = ({ panel }) =>
    cloneDeep(panel?.panelType === "elevation" ? panel.items : [panel]);
  const onRangeSelectionChanged = useCallback((event) => {
    // console.log("hoist selection changed", event);
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  }, []);
  return (
    <div
      className={
        darkMode ? "serviceDetailsBodyDark" : "serviceDetailsBodyLight"
      }
    >
      {" "}
      <Collapse
        activeKey={activePanel}
        expandIcon={(data) => {
          return data?.isActive ? (
            <button
              className="extendRetractedButtonPanelHeader"
              onClick={() =>
                setActivePanel(
                  activePanel?.filter(
                    (filterData) => filterData !== data?.panelKey
                  )
                )
              }
            >
              <Retrackt />
            </button>
          ) : (
            <button
              className="extendRetractedButtonPanelHeader"
              onClick={() => setActivePanel([...activePanel, data?.panelKey])}
            >
              <Expend />
            </button>
          );
        }}
      >
        {selected && (
          <div style={{ width: "100%", marginTop: "40px" }}>
            <AgGridMathful darkMode={true} event={selected} />
          </div>
        )}
        {!!panels.length &&
          panels?.map((panel) => (
            <Panel
              style={{
                boxShadow: `0px 0px 6px 3px ${borderColor}`,
                marginTop: "20px",
              }}
              key={
                panel?.panelType === "elevation" ? panel.elevationId : panel.id
              }
              header={
                <div className="headerContainerDetailsPanel">
                  <div className="service-details-card-collapse-panel">
                    {`  ${
                      panel?.panelType === "elevation" ? panel.elevationId : ""
                    } ${
                      panel?.panelType === "elevation"
                        ? panel.elevationLabel
                        : panel?.name
                    }
           - Price total: ${formatCurrency(
             panel?.panelType === "elevation"
               ? totalPriceOfElevation(panel)
               : panel?.totalPrice,
             "USD"
           )}`}
                    <HeaderCheckBox
                      {...{
                        id:
                          panel?.panelType === "elevation"
                            ? panel.elevationId
                            : panel?.id,
                        type: panel?.panelType,
                        gridParams,
                        service,
                      }}
                    />
                    Complete all
                  </div>
                  <PanelHeaderInput
                    {...{
                      id:
                        panel?.panelType === "elevation"
                          ? panel.elevationId
                          : panel?.id,
                      type: panel?.panelType,
                      service,
                      gridParams,
                      totalPrice:
                        panel?.panelType === "elevation"
                          ? +totalPriceOfElevation(panel)
                          : +panel?.totalPrice,
                    }}
                  />
                </div>
              }
            >
              <div
                className={
                  darkMode
                    ? "agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
                    : "agGridRentalsDetailsStyleLight ag-theme-alpine"
                }
                style={{
                  height:
                    panel?.panelType === "elevation"
                      ? `${panel.items.length * 50 + 150}px`
                      : "450px",
                }}
              >
                {" "}
                <AgGridReact
                  defaultColDef={{
                    editable: true,
                    hide: false,
                    filter: true,
                    sortable: false,
                    resizable: true,
                    // autoHeight: true,
                    enablePivot: true,
                    enableRowGroup: false,
                    // flex: 0.7,
                  }}
                  onCellEditingStarted={(params) => {
                    cellEditingStarted({ params, isWritable });
                  }}
                  rowSelection="multiple"
                  rowHeight={60}
                  enableRangeSelection={true}
                  frameworkComponents={frameworkComponents}
                  onCellValueChanged={(params) => {
                    valueChanger({
                      setDetailsApplication,
                      // handleSave,
                      params,
                      unchangingFakeApplication,
                      service,
                      type: panel?.panelType,
                      id:
                        panel?.panelType === "elevation"
                          ? panel.elevationId
                          : panel?.id,
                      applicationId,
                      gridParams,
                    });
                  }}
                  columnDefs={
                    panel?.panelType === "elevation"
                      ? columnDefs({
                          id:
                            panel?.panelType === "elevation"
                              ? panel.elevationId
                              : panel?.id,
                          items: panel.items,
                          programFields,
                          gridParams,
                          retainage,
                          service,
                        })
                      : addonsColumnDefs({
                          id:
                            panel?.panelType === "elevation"
                              ? panel.elevationId
                              : panel?.id,
                          programFields,
                          addon: panel,
                          gridParams,
                          retainage,
                          service,
                        })
                  }
                  onRangeSelectionChanged={(event) =>
                    onRangeSelectionChanged(event)
                  }
                  rowData={rowData({ panel })}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default ServiceDetailsBody;
