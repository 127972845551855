import dayjs from "dayjs";

/**
 * Returns an array of schedule days sorted by start date.
 *
 * @param {Array} scheduleDays - The array of schedule days.
 * @returns {Array} - The sorted array of schedule days.
 */
const days = (scheduleDays = []) => {
  let toReturn = [];
  let postponedDays = scheduleDays?.filter(
    (day) => day?.status === "Postponed"
  );
  let normalDays = scheduleDays?.filter((day) => day?.status !== "Postponed");
  normalDays?.forEach((day, index) => {
    day.day = index + 1;
  });
  toReturn = [...normalDays, ...postponedDays]?.sort(
    (a, b) => dayjs(a?.startDate)?.valueOf() - dayjs(b?.startDate)?.valueOf()
  );

  return toReturn;
};

export default days;
