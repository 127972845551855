import {
  useContext,
  useState,
  Fragment,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { GridApi } from "ag-grid-enterprise";
import { SelectionChangedEvent } from "ag-grid-community";

import { ProjectMatchContext, addressDispatchesColumns } from "../../data";
import { RouteShower } from "../../../NewDispatchModal/components";
import { parseInTz } from "../../../NewDispatchModal/utils/dateFunctions";
import { findPaginationSize } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";

import "./AddressDispatchesGrid.scss";

const AddressDispatchesGrid = forwardRef(({ getSelectedDayInfo }, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    selectedViewData,
    filters,
    dispatchRecords,
    offProjectData,
    offScheduleData,
    changeConfirmDisabled,
    changeConfirmText,
    matchedData,
    selectedId,
  } = useContext(ProjectMatchContext);

  const [gridApi, setGridApi] = useState(/** @type {GridApi} */ (undefined));
  const [openDispatch, setOpenDispatch] = useState();

  //#region ROW DATA
  const rowData = useMemo(() => {
    let data = [];
    if (filters["records"] === "Off Schedule") {
      // this data is guaranteed to generate a match since these were actual records
      data = offScheduleData[selectedViewData["address"]];
    } else {
      if (offProjectData[selectedViewData["address"]]) {
        data = offProjectData[selectedViewData["address"]];
      } else {
        // if we don't find the records by exact match, we need to find a similar address
        for (const address in matchedData) {
          if (offProjectData[address] && selectedId in matchedData[address]) {
            data = offProjectData[address];
          }
        }
      }
    }

    if (filters["date"] || filters["fleetId"] || filters["driverId"]) {
      const tmpData = [];
      for (const record of data) {
        if (filters["date"]) {
          if (
            parseInTz(record["departAt"]).startOf("day").format() !==
            filters["date"]
          ) {
            continue;
          }

          if (filters["fleetId"]) {
            if (record["fleetId"] !== filters["fleetId"]) {
              continue;
            }
          }

          if (filters["driverId"]) {
            if (record["driverId"] !== filters["driverId"]) {
              continue;
            }
          }

          tmpData.push(record);
        }
      }

      return tmpData;
    }

    return data;
  }, [
    offScheduleData,
    offProjectData,
    filters,
    selectedViewData,
    matchedData,
    selectedId,
  ]);

  //#region GRID DATA
  const getRowId = useCallback((param) => {
    return param.data.activityId;
  }, []);

  const onFirstDataRendered = useCallback((param) => {
    setGridApi(param.api);
    param.columnApi.autoSizeAllColumns(false);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableColResize: true,
      sortable: true,
      filter: true,
      flex: 1,
      enableRowGroup: true,
      suppressSizeToFit: true,
      minWidth: 130,
      suppressDragLeaveHidesColumns: false,
      enablePivot: false,
    };
  }, []);

  const onSelectionChanged = useCallback(
    /** @param {SelectionChangedEvent} event */
    (event) => {
      const selectedNodes = event.api.getSelectedRows();
      if (!selectedNodes.length) {
        changeConfirmDisabled(true);
        changeConfirmText();
      } else {
        const selectedDay = getSelectedDayInfo();
        let condition = true;

        if (filters["records"] === "Off Schedule") {
          if (
            !selectedDay &&
            Object.keys(matchedData[selectedViewData["address"]]).length === 1
          ) {
            changeConfirmDisabled(true);
            changeConfirmText();

            condition = false;
          }
        }

        if (condition) {
          changeConfirmDisabled(false);
          if (!selectedDay) {
            changeConfirmText("Match routes with selected project");
          } else {
            changeConfirmText(
              `Match routes with schedule day - ${dayjsNY(
                selectedDay.startDate
              ).format("MMM DD")}`
            );
          }
        }
      }
    },
    []
  );

  //#region REF HANDLER
  useImperativeHandle(
    ref,
    () => {
      return {
        getSelectedRoutes() {
          if (!gridApi) {
            return [];
          }

          return gridApi.getSelectedRows();
        },
      };
    },
    [gridApi]
  );

  //#region HANDLERS
  function onShowDispatch(data) {
    setOpenDispatch(data.dispatchId);
  }

  //#region JSX
  return (
    <Fragment>
      <div className="project-match-card address-dispatches-grid">
        <div className="project-match-card-header">
          Routes to {selectedViewData?.address} - {filters["records"]}
        </div>
        <div className="project-match-body" id="grid-routes-body">
          <div className="routes-grid-container">
            <div
              className={
                isDarkMode
                  ? "dark-ag-theme ag-theme-alpine-dark"
                  : "light-ag-theme ag-theme-alpine"
              }
              style={{ height: "100%" }}
            >
              <AgGridReact
                {...{
                  onFirstDataRendered,
                  getRowId,
                  columnDefs: addressDispatchesColumns,
                  rowData: rowData,
                  onSelectionChanged,
                  animateRows: true,
                  rowSelection: "multiple",
                  enableRangeSelection: true,
                  rowMultiSelectWithClick: true,
                  rowGroupPanelShow: "always",
                  suppressRowClickSelection: true,
                  context: {
                    onShowDispatch,
                  },
                  paginationPageSize: findPaginationSize(
                    "grid-routes-body",
                    41
                  ),
                  pagination: true,
                  defaultColDef,
                  sideBar: {
                    toolPanels: [
                      {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                      },
                      {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {openDispatch && (
        <RouteShower
          {...{
            open: !!openDispatch,
            onCancel() {
              setOpenDispatch(undefined);
            },
            routes: dispatchRecords[openDispatch].routes.map((route) => ({
              ...route,
              fleetName: dispatchRecords[openDispatch]["fleetName"],
            })),
            selectedDate: dispatchRecords[openDispatch]["dispatchDate"],
            isDarkMode,
          }}
        />
      )}
    </Fragment>
  );
});

export default AddressDispatchesGrid;
