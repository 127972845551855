const linxupEndpoints = {
  location: "https://leadmanager-sockets.ue.r.appspot.com/api/location",
  geofences: "https://leadmanager-sockets.ue.r.appspot.com/api/geofences",
  alerts: "https://leadmanager-sockets.ue.r.appspot.com/api/alerts",
  stops: "https://leadmanager-sockets.ue.r.appspot.com/api/stops",
  vehicleReports:
    "https://leadmanager-sockets.ue.r.appspot.com/api/vehicleReports",
  locationsForVehicle:
    "https://leadmanager-sockets.ue.r.appspot.com/api/locationsForVehicle",
  locationsForDateRange:
    "https://leadmanager-sockets.ue.r.appspot.com/api/locationsForDateRange",
  newGeofence: "https://leadmanager-sockets.ue.r.appspot.com/api/newGeofence",
  vehicleTrips: "https://leadmanager-sockets.ue.r.appspot.com/api/vehicleTrips",
};

// const linxupEndpoints = {
//   location: "http://localhost:8080/api/location",
//   geofences: "http://localhost:8080/api/geofences",
//   alerts: "http://localhost:8080/api/alerts",
//   stops: "http://localhost:8080/api/stops",
//   vehicleReports: "http://localhost:8080/api/vehicleReports",
//   locationsForVehicle: "http://localhost:8080/api/locationsForVehicle",
//   locationsForDateRange: "http://localhost:8080/api/locationsForDateRange",
//   newGeofence: "http://localhost:8080/api/newGeofence",
//   vehicleTrips: "http://localhost:8080/api/vehicleTrips",
// };

export default linxupEndpoints;
