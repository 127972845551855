import { useState } from "react";
import { Modal, Button, Alert } from "antd";
import { useRedux } from "../../../../../hooks";
import ScaffoldPlanks from "../ScaffoldPLIAddon/ScaffoldPlanks";
import { AgGridReact } from "ag-grid-react";
import { customFloorSignificance, getNumberValOnly } from "../utils";
import CustomFooter from "../../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomFooter";
import { MondayButton } from "../../../../../../commonComponents";
import { EyeIcon } from "../../../../../DynamicView/src";
import { getBicycleTypeCoefficient } from "../ScaffoldPLIAddon/scaffoldPLIAddonColumnDefs";

export default function ScaffoldServicePlanks({ serviceIndex }) {
  const [visible, setVisible] = useState(false);
  const [gridData] = useRedux("takeOffTableData");
  const showModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };
  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<EyeIcon />}
        onClick={showModal}
      >
        Service Level Summary
      </MondayButton>
      {visible && (
        <Modal
          className="scaffold-pliaddon-planks-modal"
          {...{
            width: "100%",
            open: visible,
            onCancel: closeModal,
          }}
        >
          {gridData?.[serviceIndex]?.serviceOptions?.[0]?.map((row, idx) => {
            let hasPlanks = Array.isArray(row?.planks?.dataSrc);
            return hasPlanks ? (
              <PlanksElevationTable
                {...{
                  dataSrc: row?.planks?.dataSrc || [],
                  priceSheetDataSrc: row?.planks?.priceSheetDataSrc || [],
                  elevationName: row?.elevationLabel || "",
                }}
              />
            ) : null;
          })}
          {/* <h2>this is me</h2> */}
        </Modal>
      )}
    </>
  );
}

const PlanksElevationTable = ({
  dataSrc = [],
  priceSheetDataSrc = [],
  elevationName = "",
}) => {
  return (
    <div className="scaffold-pliaddon-planks-content">
      <div>
        <br />
        <Alert
          type="info"
          message={`This is PLANKS configuration for Elevation: ${elevationName}`}
        />
        <br />
      </div>
      <div className="agGridContainer">
        <div className="dark-ag-theme documentationsTable ag-theme-alpine-dark">
          <AgGridReact
            headerHeight={50}
            columnDefs={[
              {
                headerName: "L",
                field: "length",
                // width: 70,
              },
              {
                headerName: "H",
                field: "height",
                // width: 70,
              },
              {
                headerName: "Sqft",
                editable: false,
                field: "sqft",
                // width: 70,
                valueGetter: (params) => {
                  try {
                    const length = params?.data?.length || 0;
                    const height = params?.data?.height || 0;
                    const res = getNumberValOnly(length * height);
                    params.data.sqft = res;
                    return res;
                  } catch (err) {
                    return 0;
                  }
                },
              },
              {
                headerName: "Bays Long",
                // width: 100,
                editable: false,

                field: "baysLong",
                valueGetter: (params) => {
                  try {
                    const length = params?.data?.length || 0;
                    let res = customFloorSignificance(length / 8 + 0.9, 1);
                    res = getNumberValOnly(res);
                    params.data.baysLong = res || 0;
                    return res;
                  } catch (err) {
                    return 0;
                  }
                },
              },
              {
                headerName: "Frames High",
                // width: 100,
                editable: false,
                field: "framesHigh",
                valueGetter: (params) => {
                  try {
                    const height = params?.data?.height || 0;
                    let res = customFloorSignificance(height / 6.4 + 0.75, 1);
                    console.log("res", res);
                    res = getNumberValOnly(res);
                    params.data.framesHigh = res || 0;
                    return res;
                  } catch (err) {
                    return 0;
                  }
                },
              },
              {
                headerName: "# Full Decks",
                field: "fullDecksNo",
                // width: 100,
              },
              {
                headerName: "Full Deck",
                // width: 100,
                field: "fullDeck",
                editable: false,

                valueGetter: (params) => {
                  try {
                    const noFullDecks = params?.data?.fullDecksNo || 0;
                    const baysLong = params?.data?.baysLong || 0;
                    const applicableCoefficient = getBicycleTypeCoefficient(
                      forceToNumber(params?.data?.bicType)
                    );
                    const res = getNumberValOnly(
                      noFullDecks * (11 + applicableCoefficient) * baysLong
                    );
                    params.data.fullDeck = res;
                    return res;
                  } catch (err) {
                    return 0;
                  }
                },
              },
              {
                headerName: "Bicycle Type",
                // width: 100,
                field: "bicType",
                editable: true,
              },
              {
                headerName: "# Bic Levels",
                // width: 100,
                field: "bicLevelNo",
                editable: true,
              },
              {
                headerName: "Bic Level",
                // width: 100,
                field: "bicLevel",
                editable: false,

                valueGetter: (params) => {
                  try {
                    const bicLevelNo = params?.data?.bicLevelNo || 0;
                    const baysLong = params?.data?.baysLong || 0;
                    const applicableCoefficient = getBicycleTypeCoefficient(
                      forceToNumber(params?.data?.bicType)
                    );
                    const res = getNumberValOnly(
                      bicLevelNo * (5 + applicableCoefficient) * baysLong
                    );
                    params.data.bicLevel = res;
                    return res;
                  } catch (err) {
                    return 0;
                  }
                },
              },
              {
                headerName: "Full Deck Every Level",
                // width: 140,
                field: "fullDeckEveryLevel",
                editable: false,

                valueGetter: (params) => {
                  try {
                    console.log("myparams", params);
                    const fullDeck = params?.data?.fullDeck || 0;
                    const framesH = params?.data?.framesH || 0;
                    const res = getNumberValOnly(
                      Number(fullDeck) * Number(framesH)
                    );
                    params.data.fullDeckEveryLevel = res;
                    return res;
                  } catch (err) {
                    return 0;
                  }
                },
              },
              {
                headerName: "Bic Every Level",
                // width: 140,
                field: "bicEveryLevel",
                editable: false,

                valueGetter: (params) => {
                  try {
                    console.log("myparams", params);
                    const bicLevel = params?.data?.bicLevel || 0;
                    console.log("bicLevel", bicLevel);
                    const framesH = params?.data?.framesH || 0;
                    const res = getNumberValOnly(
                      Number(bicLevel) * Number(framesH)
                    );
                    params.data.bicEveryLevel = res;
                    return res;
                  } catch (err) {
                    return 0;
                  }
                },
              },
              {
                headerName: "Frames H W/O Safety and FD",
                field: "framesH",
                editable: false,

                // width: 140,
                valueGetter: (params) => {
                  try {
                    console.log("myparams", params);
                    const framesHigh = params?.data?.framesHigh || 0;
                    const noDecks = params?.data?.fullDecksNo || 0;
                    const res = getNumberValOnly(
                      framesHigh - (Number(noDecks) + 1)
                    );
                    params.data.framesH = res;
                    return res >= 0 ? res : 0;
                  } catch (err) {
                    return 0;
                  }
                },
              },
            ]}
            rowData={dataSrc}
            enableRangeSelection={true}
            suppressSizeToFit={true}
            suppressDragLeaveHidesColumns={true}
            width={"auto"}
            // width={"100%"}
            // onGridReady={(params) => {
            //   setScaffoldPlanksGridAPI(params.api);
            //   // params?.api?.setHeaderHeight(50);
            //   // setColumnApi(params?.columnApi);
            //   // const widths = {
            //   //   rowWidth: parseInt(
            //   //     document.getElementsByClassName(
            //   //       "ag-center-cols-container"
            //   //     )[0].offsetWidth
            //   //   ),
            //   //   parentWidth: parseInt(
            //   //     document.getElementsByClassName("ag-body-viewport")[0]
            //   //       .offsetWidth
            //   //   ),
            //   // };

            //   // if (widths.rowWidth > widths.parentWidth) {
            //   //   params.columnApi.autoSizeAllColumns(false);
            //   // } else if (widths.rowWidth < widths.parentWidth) {
            //   params.api.sizeColumnsToFit();
            //   // }
            // }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            autoSizeAllColumns={true}
            rowHeight={40}
            defaultColDef={{
              enablePivot: true,
              editable: false,
              resizable: true,
            }}
            // onCellValueChanged={(params) => {
            //   const shouldIAddEmpty = dataSrc?.every(
            //     (el) => Object.keys(el).length > 11
            //   );
            //   if (shouldIAddEmpty) {
            //     setDataSrc((prev) => [...prev, {}]);
            //   } else setDataChanged((prev) => !prev);
            // }}
          />
          <CustomFooter
            dataSrc={dataSrc}
            mathAction={"sum"}
            style={footerStyle}
            labelsKeys={[
              {
                key: "sqft",
                name: "Total Sqft",
                type: "number",
                mathAction: "sum",
              },
              {
                key: "fullDeck",
                name: "Total Deck",
                type: "number",
                mathAction: "sum",
              },
              {
                key: "bicLevel",
                name: "Total Bicycle Level",
                type: "number",
                mathAction: "sum",
              },
              {
                key: "fullDeckEveryLevel",
                name: "Total Every Level",
                type: "number",
                mathAction: "sum",
              },
              {
                key: "bicEveryLevel",
                name: "Total Bicycle Every Level",
                type: "number",
                mathAction: "sum",
              },
            ]}
          />
        </div>
      </div>
      <div>
        <br />
        <br />
        <br />
        <div className="agGridContainer">
          <div className="dark-ag-theme documentationsTable  ag-theme-alpine-dark">
            <AgGridReact
              rowData={priceSheetDataSrc}
              headerHeight={50}
              columnDefs={[
                {
                  headerName: "Data",
                  field: "dataKey",
                  // width: 70,
                  flex: 1,

                  editable: false,
                },
                {
                  headerName: "Value",
                  field: "dataValue",
                  // width: 70,
                  flex: 1,
                  editable: false,
                },
                {
                  headerName: "PPU",
                  // editable: false,
                  flex: 1,
                  field: "dataPPU",
                  // width: 70,
                  editable: true,
                  valueFormatter: (params) => {
                    try {
                      const res = getNumberValOnly(params.value);
                      // setPriceSheetDataSrc((prev) => {
                      //   const newPrev = [...prev];
                      //   const index = newPrev.findIndex(
                      //     (el) => el.dataKey === params?.data?.dataKey
                      //   );
                      //   newPrev[index].dataPPU = res;
                      //   return newPrev;
                      // });
                      return formatCurrency(res);
                    } catch (err) {
                      return 0;
                    }
                  },
                },
                {
                  headerName: "Total Price",
                  // width: 100,
                  flex: 1,
                  editable: false,
                  field: "dataTotalPrice",
                  valueFormatter: (params) => {
                    try {
                      const res = getNumberValOnly(params.value);
                      return formatCurrency(res);
                    } catch (err) {
                      return 0;
                    }
                  },

                  valueGetter: (params) => {
                    try {
                      const PPU = params?.data?.dataPPU || 0;
                      const dataVal = params?.data?.dataValue || 0;
                      const res = getNumberValOnly(PPU * dataVal);
                      params.data.dataTotalPrice = res;

                      // setPriceSheetDataSrc((prev) => {
                      //   const newPrev = [...prev];
                      //   const index = newPrev.findIndex(
                      //     (el) => el.dataKey === params?.data?.dataKey
                      //   );
                      //   newPrev[index].dataTotalPrice = res;
                      //   return newPrev;
                      // });
                      return res;
                    } catch (err) {
                      return 0;
                    }
                  },
                },
              ]}
              enableRangeSelection={true}
              suppressSizeToFit={true}
              suppressDragLeaveHidesColumns={true}
              // autoSizeColumns={true}
              // width={"100%"}
              // onGridReady={(params) => {
              //   setPriceSheetGridAPI(params.api);
              //   // params?.api?.setHeaderHeight(50);
              //   // setColumnApi(params?.columnApi);
              //   // const widths = {
              //   //   rowWidth: parseInt(
              //   //     document.getElementsByClassName(
              //   //       "ag-center-cols-container"
              //   //     )[0].offsetWidth
              //   //   ),
              //   //   parentWidth: parseInt(
              //   //     document.getElementsByClassName("ag-body-viewport")[0]
              //   //       .offsetWidth
              //   //   ),
              //   // };

              //   // if (widths.rowWidth > widths.parentWidth) {
              //   //   params.columnApi.autoSizeAllColumns(false);
              //   // } else if (widths.rowWidth < widths.parentWidth) {
              //   params.api.sizeColumnsToFit();
              //   // }
              // }}
              // width={"auto"}
              autoSizeAllColumns={true}
              rowHeight={40}
              defaultColDef={{
                enablePivot: true,
                // editable: true,
                resizable: true,
              }}
              // onCellValueChanged={(params) => {
              //   console.log("myDatASrc", dataSrc);
              //   const shouldIAddEmpty = dataSrc?.every(
              //     (el) => Object.keys(el).length > 10
              //   );
              //   if (shouldIAddEmpty) {
              //     setDataSrc((prev) => [...prev, {}]);
              //   }
              // }}
            />
            <CustomFooter
              dataSrc={priceSheetDataSrc?.map((el) => {
                console.log("el", el);
                return el;
              })}
              style={footerStyle}
              mathAction={"sum"}
              labelsKeys={[
                {
                  key: "dataTotalPrice",
                  name: "Estimated Sum",
                  type: "currency",
                  mathAction: "sum",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const footerStyle = {
  backgroundColor: "#1264A3",
};
