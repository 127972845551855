import ServiceDescription from "./ServiceDescription";

const colors = ["#30973A", "#EEB522", "#4662F2"];

export const InvoiceDescription = ({ information }) => {
  console.log("information", information);
  return (
    <div className="GroupContainer">
      {information?.description?.map((item, key) => {
        if (item.amount <= 0) return null;
        return (
          <ServiceDescription
            key={key}
            service={item.label}
            elevations={item.data?.serviceOptions?.map(
              (elevation) => elevation?.elevationLabel
            )}
            isTaxable={item.taxAmount > 0}
            showDivider={key + 1 !== information?.description?.length}
            color={colors[key % colors.length]}
            total={item.amount + (item.tax ? item.taxAmount : 0)}
          />
        );
      })}
    </div>
  );
};
