import dayjs from "dayjs";
import { useSelector } from "react-redux";
import timezone from "dayjs/plugin/timezone";
import { useEffect, useMemo, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";

import ReportView from "../view/ReportView";
import { compareIncluding } from "../../utils";
import { PageControlPanel } from "../components";
import { filterTables } from "../../../../utils";
import regex from "./../components/helpers/regex";
import { useResponsive } from "../../../../hooks";
import { useDebounce } from "../../../../hooks/useDebounce";
import ReportPreviewCard from "../components/ReportPreviewCard/ReportPreviewCard";

import "./ReportsPage.scss";

dayjs.extend(timezone);

const ReportsPage = ({
  userList,
  identityId,
  configDatas,
  activeFilters,
  filterCategory,
  setActiveFilters,
  reportsList = [],
  viewReport = () => {},
  reportsConfiguration,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );
  const [filterTerm, setFilterTerm] = useState("");
  const [categorySelected, setCategorySelected] = useState(undefined);
  const [dragItems, setDragItems] = useState(reportsList);
  const [playDrag, setPlayDrag] = useState(false);

  const { desktop, tablet } = useResponsive();

  const getSearchTerm = (term) => {
    setFilterTerm(term);
  };

  const handleClickedCategory = ({ category }) => {
    setCategorySelected(category);
  };

  const searchTerm = useDebounce(filterTerm, 0);

  const reportsToRender = useMemo(() => {
    const list = reportsList.filter((report) => {
      const { reportObj } = report || {};
      const currFilters = Object.keys(activeFilters).reduce((acc, curr) => {
        const value = activeFilters[curr];
        return !!value ? { ...acc, [curr]: value } : acc;
      }, {});

      const { createdAt, rangeCreatedAt, ...restOfFilters } = currFilters;

      const restOfFiltersKeys = Object.keys(restOfFilters || {});

      return (
        !!reportObj &&
        (restOfFiltersKeys.length
          ? restOfFiltersKeys.every((key) =>
              compareIncluding(report[key], activeFilters[key])
            )
          : true) &&
        (!!createdAt
          ? dayjs.tz(createdAt).startOf("day").valueOf() ===
            dayjs.tz(report.createdAt).startOf("day").valueOf()
          : true) &&
        (!!rangeCreatedAt?.length
          ? dayjs(report.createdAt).isBetween(
              rangeCreatedAt[0],
              rangeCreatedAt[1],
              null,
              "[]"
            )
          : true)
      );
    });

    return list;
  }, [reportsList, activeFilters]);

  const filteredReportsByCategory = useMemo(() => {
    if (!categorySelected) return reportsToRender;
    return reportsToRender?.filter(
      ({ categoryName }) => categoryName === categorySelected
    );
  }, [categorySelected, reportsToRender]);

  const filteredReportsToRender = useMemo(() => {
    const filteredDragItems = filteredReportsByCategory.filter(
      (report) =>
        regex(report.reportName).includes(regex(searchTerm)) ||
        String(regex(dayjs(report.createdAt).format("MM/DD/YYYY"))).includes(
          regex(searchTerm)
        ) ||
        regex(report.createdBy).includes(regex(searchTerm)) ||
        String(regex(report.datasources.length)).includes(regex(searchTerm))
    );

    setDragItems(filteredDragItems);

    return filteredDragItems;
  }, [searchTerm, filteredReportsByCategory]);

  const [reportPrefs, setReportPrefs] = useState(null);

  const finalReportPrefs =
    reportPrefs || allPreferences?.preferences?.reportPreferences || {};

  const handleFavoriteClick = (ID) => {
    setReportPrefs({
      ...finalReportPrefs,
      [ID]: !finalReportPrefs?.[ID],
    });
  };

  const [notes, setNotes] = useState();

  useEffect(() => {
    filterTables("notes", "topicCategory", "Reports")
      .then((res) => {
        setNotes(
          res.filter((note) =>
            !!note?.privateOnlyTo
              ? note?.privateOnlyTo === userConfiguration?.cognitoUserId
              : note
          )
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const onDragEnd = (sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex) {
      setPlayDrag(false);
      return;
    }
    const newItems = Array.from(dragItems);
    const [reorderedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, reorderedItem);
    setPlayDrag(true);
    setDragItems(newItems);
  };

  const itemsToRender = useMemo(
    () =>
      dragItems.map((dragReport, index) => ({
        reportId: dragReport?.reportId,
        index,
      })),
    [dragItems]
  );

  return (
    <>
      <PageControlPanel
        {...{
          activeFilters,
          setActiveFilters,
          configDatas,
          userList,
          filterCategory,
          reportsConfiguration,
          identityId,
          reportPrefs,
          setPlayDrag,
          playDrag,
          dragItems,
          setDragItems,
          setReportPrefs,
          reportsToRender: filteredReportsToRender,
          getSearchTerm,
          handleClickedCategory,
          categorySelected,
        }}
      />
      <div
        className={`reportsPageContainer ${
          isDarkMode && "reportsPageContainerDark"
        }`}
      >
        <div className="reportsContainer">
          <ListManager
            key={1}
            // because of lag while dragAndDrop big data, as item we give items: [Array just with ids] and we find report by reportId
            items={itemsToRender}
            direction="horizontal"
            maxItems={desktop ? 5 : tablet ? 3 : 1}
            render={(report) => {
              const foundedReport = dragItems.find(
                (reportItem) => reportItem?.reportId === report?.reportId
              );

              return (
                <div
                  style={{
                    margin: "10px",
                  }}
                >
                  <ReportPreviewCard
                    {...{
                      report: foundedReport,
                      onClick: (e) => {
                        if (e.defaultPrevented) return;
                        viewReport(foundedReport);
                      },
                      onFavoriteClick: handleFavoriteClick,
                      notes,
                      setNotes,
                    }}
                  />
                </div>
              );
            }}
            onDragEnd={onDragEnd}
          />
        </div>
      </div>

      <ReportView />
    </>
  );
};

export default ReportsPage;
