import { useMemo, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

function AdditionalRentalChart({ amounts = {} }) {
  console.log("amounts", amounts);

  const options = useMemo(() => {
    return getData(amounts || {});
  }, [amounts]);

  return <AgChartsReact options={options} />;
}

export default AdditionalRentalChart;
const numFormatter = new Intl.NumberFormat("en-US");

const getData = (amounts = {}) => {
  return {
    autoSize: true,
    data: [
      {
        type: "Free Months",
        count:
          forceToNumber(amounts.freeMonths || 0) +
          forceToNumber(amounts?.defaultMonths || 0),
      },
      {
        type: "Additional Months",
        count: forceToNumber(amounts.months || 0),
      },
    ],
    theme: "ag-default-dark",

    title: {
      text: "Rental Months For Service",
      fontSize: 18,
      spacing: 25,
    },
    footnote: {
      text: "Source: Home Office",
    },
    series: [
      {
        type: "pie",
        calloutLabelKey: "type",
        fillOpacity: 0.9,
        strokeWidth: 0,
        angleKey: "count",
        sectorLabelKey: "count",
        calloutLabel: {
          enabled: false,
        },
        sectorLabel: {
          color: "white",
          fontWeight: "bold",
          formatter: ({ datum, sectorLabelKey }) => {
            const value = datum[sectorLabelKey];
            return numFormatter.format(value);
          },
        },

        fills: [
          "#fb7451",
          "#f4b944",
          "#57cc8b",
          "#49afda",
          "#3988dc",
          "#72508c",
          "#b499b5",
          "#b7b5ba",
        ],
        innerRadiusRatio: 0.5,
        innerLabels: [
          {
            text: numFormatter.format(110),
            fontSize: 24,
            fontWeight: "bold",
          },
          {
            text: "Total Number of months",
            fontSize: 12,
          },
        ],
        highlightStyle: {
          item: {
            fillOpacity: 0,
            stroke: "#535455",
            strokeWidth: 1,
          },
        },
        tooltip: {
          renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
            return {
              title,
              content: `${datum[calloutLabelKey]}: ${numFormatter.format(
                datum[sectorLabelKey]
              )}`,
            };
          },
        },
      },
    ],
  };
};
