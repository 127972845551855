import { Input, Modal } from "antd";
import { API } from "aws-amplify";
import { SearchIcon, XIcon } from "../../../SidebarPages/Communication/assets";
import { useEffect, useMemo, useState } from "react";
import { Transfer } from "../../../SidebarPages/DynamicView/editComponents";
import "./DynamicTeamModal.scss";
import { updateDocumentTitle } from "../../../../utils";
import { useSelector } from "react-redux";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import CustomTransfer from "../../../commonComponents/CustomTransfer/CustomTransfer";
import { InfoCircleFilled } from "@ant-design/icons";

/**
 * Represents team transfer modal that adds or removes users from a team.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines whether the modal is open or not.
 * @param {function} props.setOpen - A function to set the open state of the modal.
 * @param {Array} props.selectedTeam - The selected team.
 * @param {function} props.setSelectedTeam - A function to set the changed values to selectedTeam.
 * @param {boolean} props.isDarkMode - Determines whether the dark mode is enabled or not.
 * @param {function} props.ClearOptions - A function to clear options.
 * @param {boolean} [props.footerDisabled=false] - Determines whether the footer buttons are disabled or not.
 * @returns {JSX.Element} The team transfer modal. If Changes are made it sets the selected team as [{value:'Team',members:[{identityId, cognitoUserId, nameOfUser}],teamId:[]}]
 */
const DynamicTeamModal = ({
  open,
  setOpen,
  selectedTeam,
  setSelectedTeam,
  ClearOptions,
  footerDisabled = false,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [getUsers] = useState(
    userConfiguration?.allUsers?.Items?.filter(
      ({ isSuspended }) => !isSuspended
    )
  );
  const [searchTeam, setSearchTeam] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);

  const teamSelectedValues = useMemo(() => {
    if (!!selectedTeam) {
      let funcArr = [];
      selectedTeam?.map((el) => {
        el?.members?.map((x) => {
          funcArr.push(x?.identityId);
        });
      });
      return funcArr;
    }
  }, [selectedTeam]);

  function debounceSearch(func, timeout = 150) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const onSearchTeam = (e) => {
    let substring = e?.target?.value;
    let filteredData = getUsers?.filter((item) =>
      item?.nameOfUser?.toLowerCase().includes(substring.toLowerCase())
    );
    setSearchTeam(filteredData);
    substring !== "" ? setActiveFilter(true) : setActiveFilter(false);
  };

  function getEditedValue(team) {
    handleCloseModal();
    var result = [];
    getUsers?.forEach(function (user, key) {
      team?.members?.forEach(function (teamUser, key2) {
        if (user?.identityId === teamUser) {
          result?.push({
            identityId: user?.identityId,
            nameOfUser: user?.nameOfUser,
            cognitoUserId: user?.cognitoUserId,
          });
        }
      });
    });

    const preventDuplicateInArray = new Set();
    const uniqueArray = result?.filter((data) => {
      const findDuplicateData = preventDuplicateInArray?.has(data?.identityId);
      preventDuplicateInArray?.add(data?.identityId);
      if (!findDuplicateData) {
        return true;
      }
      return false;
    });

    setSelectedTeam([
      {
        value: "Team",
        members: uniqueArray,
        teamId: selectedTeam.flatMap(({ teamId }) => teamId || []),
      },
    ]);
  }

  function getContent() {
    if (!activeFilter) {
      return getUsers?.map((users) => ({
        title: users?.nameOfUser,
        id: users?.identityId,
      }));
    } else {
      return searchTeam?.map((users) => ({
        title: users?.nameOfUser,
        id: users?.identityId,
      }));
    }
  }

  const handleCloseModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setOpen(false);
  };

  return (
    <Modal
      className={`memberTeamModal ${isDarkMode && "memberTeamModalDark"}`}
      title="Configured Team"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Configured Team" });
      }}
      footer={null}
      open={open}
      closable={true}
      closeIcon={<XIcon />}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      centered={true}
    >
      <div className="memberTeam-container">
        <div className="dynamicTeamModal-info">
          <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
          <p className="dynamicTeamModal-text">
            If you're making changes, please do not forget to save them by
            clicking the button below.
          </p>
        </div>
        <div className="dynamicTeam-search">
          <InputComponent
            {...{
              className: "searchEditMembers",
              placeholder: "Search members...",
              allowClear: true,
              prefix: <SearchIcon width={15} height={15} />,
              onChange: () => {
                debounceSearch((e) => onSearchTeam(e));
              },
            }}
          />
        </div>
        <div className="editMembers-container">
          <Transfer
            footerDisabled={footerDisabled}
            params={{
              content: {
                value: teamSelectedValues,
                data: getContent(),
                formItemName: "members",
              },
            }}
            getEditedValue={getEditedValue}
            setEditMode={() => {}}
            setCloseTeamModalsOnCancel={() => {
              onSearchTeam({ target: { value: "" } });
              ClearOptions();
            }}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DynamicTeamModal;
