import {
  formatCurrency,
  formatNumberToPercentage,
} from "../../../../../../../../utils";
import { useStateContext } from "../../../../Context/Context";
import "./ServiceFooter.scss";
import Decimal from "decimal.js-light";
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { DoneAllIcon } from "../../../../../../../../../../icons";

const ServiceFooter = ({ elevation, events }) => {
  const { totalRate, totalAmount } = elevation?.amounts
    ? elevation?.amounts?.reduce(
        (acc, cur) => ({
          totalRate: Number(
            new Decimal(acc?.totalRate || 0).plus(
              new Decimal(Number(cur?.rate).toFixed(2) || 0)
            )
          ),
          totalAmount: Number(
            new Decimal(acc?.totalAmount || 0).plus(
              new Decimal(cur?.amount || 0)
            )
          ),
        }),
        { totalRate: new Decimal(0), totalAmount: new Decimal(0) }
      )
    : { totalRate: new Decimal(0), totalAmount: new Decimal(0) };

  const canAddEvents = elevation?.amounts
    ? elevation?.amounts?.length < events?.length
    : 0 < events?.length;
  const eventsToAdd =
    canAddEvents &&
    events?.length - (elevation?.amounts ? elevation?.amounts?.length : 0);
  console.log("Hoist service footer", {
    elevation,
    events,
    totalRate,
    totalAmount,
  });

  const { darkMode } = useStateContext();

  console.log("check", { totalRate });

  return (
    <div
      className={
        darkMode
          ? "sov-service-content-info-details-hoist-dark"
          : "sov-service-content-info-details-hoist"
      }
    >
      <div className="scheduleOfValuesContentInfoDetailsHoist">
        <div className="sovDetailsHoistLabel">
          {canAddEvents
            ? `You can add ${eventsToAdd} more event${
                eventsToAdd === 1 ? "" : "s"
              }`
            : `You can't add anymore events`}
        </div>
        <div className="sovDetailsHoistAmount"></div>
      </div>

      <div className="scheduleOfValuesContentInfoDetailsHoist">
        <div className="sovDetailsHoistLabel">Total rate is:</div>
        <div className="sovDetailsHoistAmount">
          {formatNumberToPercentage(totalRate)}
        </div>
        {totalRate === 100 && (
          <DoneAllIcon style={{ color: "#68C142", marginLeft: "5px" }} />
        )}
        {totalRate > 100 && (
          <span
            className="sov-service-content-info-details-rate-error"
            style={{ color: "#FE4C4A" }}
          >
            + {formatNumberToPercentage(totalRate - 100)}
            <RiseOutlined style={{ color: "#FE4C4A" }} />
          </span>
        )}
        {totalRate < 100 && (
          <span
            className="sov-service-content-info-details-rate-error"
            style={{ color: "#ECDE7C" }}
          >
            - {formatNumberToPercentage(100 - totalRate)}
            <FallOutlined style={{ color: "#ECDE7C" }} />
          </span>
        )}
      </div>

      <div className="scheduleOfValuesContentInfoDetailsHoist">
        <div className="sovDetailsHoistLabel">Total amount is:</div>
        <div className="sovDetailsHoistAmount">
          {formatCurrency(totalAmount, "USD")}
        </div>
        {totalAmount === elevation?.totalPrice && (
          <DoneAllIcon style={{ color: "#68C142", marginLeft: "5px" }} />
        )}
        {totalAmount > elevation.price && (
          <span
            className="sov-service-content-info-details-amount-error"
            style={{ color: "#FE4C4A" }}
          >
            + {formatCurrency(totalAmount - elevation?.totalPrice, "USD")}
            <RiseOutlined style={{ color: "#FE4C4A" }} />
          </span>
        )}
        {totalAmount < elevation?.totalPrice && (
          <span
            className="sov-service-content-info-details-amount-error"
            style={{ color: "#ECDE7C" }}
          >
            - {formatCurrency(elevation?.totalPrice - totalAmount, "USD")}
            <FallOutlined style={{ color: "#ECDE7C" }} />
          </span>
        )}
      </div>
    </div>
  );
};
export default ServiceFooter;
