import { useEffect, useRef, useState } from "react";
// import CanvasDraw from "react-canvas-draw";
import Canvas from "./Canvas";
import { MondayButton } from "../../../../../../../../commonComponents";
import useTranslate from "../../../../../../../../pages/ScheduleProgress/Language/useTranslate";

function CanvasDrawImage({ src, onDrawSave }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [canvasSize, setCanvasSize] = useState({ width: 200, height: 200 });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  // const imageRef = useRef(null);
  const { t } = useTranslate();

  useEffect(() => {
    setImageUrl(src);
    const wait = setTimeout(() => {
      const body = document.getElementById("image-container");
      if (body) {
        setCanvasSize({
          width: body.offsetWidth,
          height: body.offsetHeight,
        });
        setLoading(false);
      }
    }, 1000);
    return () => clearTimeout(wait);
  }, [src]);

  return (
    <div id="image-container">
      <MondayButton
        {...{
          hasIcon: false,
          style: { marginBottom: !editMode ? "10px" : "unset" },
          className: "mondayButtonBlue",
          onClick: () => setEditMode(!editMode),
          disabled: !!loading,
        }}
      >
        {t("Draw")}
      </MondayButton>
      {imageUrl &&
        (!editMode ? (
          <img
            // ref={imageRef}
            className="displayedImage"
            src={imageUrl}
            alt=""
          />
        ) : (
          <Canvas
            {...{
              initialImageSrc: imageUrl,
              width: canvasSize.width,
              height: canvasSize.height - 42,
              markerColor: "#FF5733",
              onChange: (e) => {},
              onSave: (e) => onDrawSave(e),
              onEditClick: () => setEditMode((prev) => !prev),
            }}
          />
        ))}
    </div>
  );
}

export default CanvasDrawImage;
