import axesTooltip, { pieTooltip } from "../../../../helpers/axesTooltip";

const countOnType = {
  title: {
    text: "Total Number of Documents",
  },
  subtitle: {
    text: "Based on Topic",
  },
  aggFunc: "count",

  bar: {
    series: [
      {
        xKey: "docType",
        yKey: ["docId"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "docType",
        angleKey: ["docId"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const documentsByType = {
  title: {
    text: "Total Number of Documents",
  },
  subtitle: {
    text: "Based on Type",
  },
  aggFunc: "count",

  bar: {
    series: [
      {
        xKey: "categoryName",
        yKey: ["docId"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "categoryName",
        angleKey: ["docId"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const filesPerType = {
  title: {
    text: "Total Number of Files",
  },
  subtitle: {
    text: "Based on Type",
  },
  aggFunc: "count",

  bar: {
    series: [
      {
        xKey: "docType",
        yKey: ["googleDriveUploads"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "docType",
        angleKey: ["googleDriveUploads"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const filesPerCategory = {
  title: {
    text: "Total Number of Files",
  },
  subtitle: {
    text: "Based on Category",
  },
  aggFunc: "count",

  bar: {
    series: [
      {
        xKey: "categoryName",
        yKey: ["googleDriveUploads"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "categoryName",
        angleKey: ["googleDriveUploads"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const statusPerType = {
  title: {
    text: "Status of Documents",
  },
  subtitle: {
    text: "Based on Category",
  },
  aggFunc: "count",

  bar: {
    series: [
      {
        xKey: "docType",
        yKey: ["docStatus"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "docType",
        angleKey: ["docStatus"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const chartingOptions = [
  {
    optionTitle: "Show total number of Documentations for each type",
    params: countOnType,
  },
  {
    optionTitle: "Group Documents by Category",
    params: documentsByType,
  },
  {
    optionTitle: "Show total number of files for each type",
    params: filesPerType,
  },
  {
    optionTitle: "Total number of files per Category",
    params: filesPerCategory,
  },
  {
    optionTitle: "Status of Documents per Type",
    params: statusPerType,
  },
  {
    optionTitle: "Status of Documents per Category",
    params: statusPerType,
  },
];

export default chartingOptions;
