import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Modal } from "antd";
import { columnDefs } from "./components/columnDefs";
import { defaultColDef } from "../../utils";
import { useEffect, useState } from "react";
import "./ApplyCredit.scss";
import { useRedux } from "../../../../../hooks";
import { onRowSelected } from "./utils";
import { updateDocumentTitle } from "../../../../../../../utils";

const ApplyCredit = ({ getAppliedCredit }) => {
  const [gridApi, setGridApi] = useState();
  const [data] = useRedux("rowData");
  const [creditApplied] = useRedux("creditApplied");
  const [visible, setVisible] = useRedux("applyCredit");
  const [rowData, setRowData] = useState([]);

  const onGridReady = (params) => {
    params.columnApi.autoSizeAllColumns();
    setGridApi(params.api);
  };

  const onCancel = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  const onOk = () => {
    getAppliedCredit(
      gridApi
        .getModel()
        ?.rowsToDisplay?.map((displayD) => displayD?.data)
        .filter((el) => {
          return el?.applyAmount !== undefined;
        })
        ?.map((chargeEl) => {
          const {
            chargeNumber,
            applyAmount,
            chargeId,
            chargeLogs = [],
            chargeAmount,
          } = chargeEl;
          return {
            chargeId,
            chargeNumber,
            applyAmount,
            chargeLogs,
            // ...chargeEl,
            chargeAmount,
            remainingAmount: chargeAmount - applyAmount,
          };
        })
        .filter(({ applyAmount }) => applyAmount)
    );
    onCancel();
  };

  // const getRowNodeId = ({ chargeNumber }) => chargeNumber;

  useEffect(() => {
    if (rowData?.length === 0) {
      const temp = data
        ?.filter(({ chargeType }) => chargeType === "Credit Memo")
        ?.filter(Boolean)
        ?.map((credit) => ({
          ...(credit || {}),
          applyAmount:
            creditApplied
              ?.filter(Boolean)
              .find(({ chargeNumber }) => credit.chargeNumber === chargeNumber)
              ?.applyAmount || 0,
        }));
      setRowData(temp);
    }
  }, []);

  return (
    <Modal
      {...{
        onOk,
        open: visible,
        onCancel,
        title: "Apply Credit",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Apply Credit" });
        },
        className: "applyCreditModal",
      }}
    >
      <div className="ag-theme-alpine agGridCustomStyle">
        <AgGridReact
          {...{
            rowData,
            columnDefs: columnDefs(rowData, setRowData),
            defaultColDef,
            // getRowNodeId,
            onRowSelected,
            onGridReady,
          }}
          immutableData
          enableRangeSelection
          suppressMakeColumnVisibleAfterUnGroup
          suppressDragLeaveHidesColumns={true}
        />
      </div>
    </Modal>
  );
};
export default ApplyCredit;
