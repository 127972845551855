import React, { useEffect, useRef, useState } from "react";
import { Avatar, Badge, Input, Mentions, message, Popover } from "antd";
import "./ConversationContent.scss";
import EmojiPicker from "emoji-picker-react";
import NoConversationContent from "./NoConversationContent/NoConversationContent";
import { getInitials } from "../../../../../../utils";
import {
  EmailIcon,
  PinnedIcon,
  ArchiveIcon,
  DeleteIcon,
  SendChatIcon,
  UploadIcon,
  EmojiIcon,
} from "../../../../assets/icons";
import { messages } from "./randomMessages";
import ChatMessage from "./ChatMessage";
import { HoverButton } from "../../../../../commonComponents";
import { useSelector } from "react-redux";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import ReplyMessages from "./StartConversation/ReplyMessages/ReplyMessages";

const ConversationContent = (props) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const {
    selectedUser = {},
    socket = {},
    roomId = "",
    messageList = {},
    setMessageList = () => {},
  } = props;
  const [currentMessage, setCurrentMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);
  const [replyTo, setReplyTo] = useState(null);

  const onWriteAction = (e) => {
    setCurrentMessage(e);
    socket.emit("typing", { user: userConfiguration.nameOfUser });
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      socket.emit("stop typing", { user: userConfiguration.nameOfUser });
    }, 2000);
  };

  const sendMessage = () => {
    if (currentMessage.trim() !== "") {
      const messageData = {
        id: new Date().getTime(),
        cognitouserId: userConfiguration.cognitoUserId,
        sender: userConfiguration.nameOfUser,
        message: currentMessage,
        time: dayjsNY().valueOf(),
        replyTo,
        edited: false,
      };

      socket.emit("send_message", { messageData, roomId });

      socket.emit("stop typing", { user: userConfiguration.nameOfUser });

      clearTimeout(typingTimeoutRef.current);

      setMessageList((prev) => {
        return {
          ...prev,
          [roomId]: [...(prev[roomId] || []), messageData],
        };
      });

      setReplyTo(null);
      setCurrentMessage("");
    }
  };

  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      socket.on("receive_message", (data) => {
        setMessageList((prev) => {
          return {
            ...prev,
            [data.roomId]: [...(prev[data.roomId] || []), data.messageData],
          };
        });
      });
      return () => {
        socket.off("receive_message");
      };
    }
  }, [socket, selectedUser]);

  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      socket.on("typing", (data) => {
        if (data.user !== userConfiguration.nameOfUser) {
          setIsTyping(true);
        }
      });

      socket.on("stop typing", (data) => {
        if (data.user !== userConfiguration.nameOfUser) {
          setIsTyping(false);
        }
      });

      return () => {
        socket.off("typing");
        socket.off("stop typing");
      };
    }
  }, [userConfiguration.nameOfUser]);

  return (
    <>
      {Object.keys(selectedUser).length > 0 ? (
        <div className="userChatting">
          <div className="userChattingHeaderInfo">
            <div className="userChattingStatus">
              <Avatar shape="circle" className="avatarInitialsUser">
                {getInitials(selectedUser.nameOfUser)}
              </Avatar>
              <div className="userChattingName">
                <div className="sessionBadgeStatus">
                  <Badge status="success" />
                  <span>Online</span>
                </div>
                <span className="nameOfUser">{selectedUser.nameOfUser}</span>
                <span>{selectedUser.departmentName}</span>
                {isTyping && <span>Typing...</span>}
              </div>
            </div>
            <div className="userActions">
              <HoverButton
                {...{
                  hasIcon: false,
                  text: "Send an email instead",
                  type: "action",
                  className: "sendEmailInstead",
                }}
              />
              <div className="divider"></div>
              <EmailIcon cursor={"pointer"} />
              <PinnedIcon cursor={"pointer"} />
              <ArchiveIcon cursor={"pointer"} />
              <DeleteIcon cursor={"pointer"} />
            </div>
          </div>
          <div className="startTypingContent">
            <div className="dateOfLastChat">
              <hr className="line left" />
              <span>Thu, 15 Aug</span>
              <hr className="line right" />
            </div>
            <div className="chatData">
              <div className="chat-container">
                {messageList?.[roomId]?.map((message) => (
                  <ChatMessage
                    {...{
                      id: message?.id,
                      sender: message?.sender,
                      message: message?.message,
                      time: message?.time,
                      userConfiguration,
                      replyTo: message?.replyTo,
                      setReplyTo,
                    }}
                  />
                ))}
              </div>
              <div className="textAreaChat">
                {replyTo && <ReplyMessages {...{ replyTo, setReplyTo }} />}
                <Mentions
                  placeholder="Type here..."
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="chatInput"
                  value={currentMessage}
                  onChange={(e) => {
                    onWriteAction(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoConversationContent />
      )}
    </>
  );
};

export default ConversationContent;
