import moment from "moment-timezone";

moment.tz.setDefault("America/New_York");

export const NO_SERVICES_INSPECTION_TYPES = [
  "PRE-INSTALLATION INSPECTION",
  "HOIST MAINTENANCE",
];

export const CREATE_INSPECTIONS_FIELDS = [
  "newInspectionProjectName",
  "newInspectionInspType",
  "newInspectionProjectExecutive",
  "newInspectionInspDate",
  "newInspectionInspectedBy",
  "newInspectionTeams",
];

export const UPDATABLE_INSPECTION_STATUSES = ["PARTIALLY INSPECTED", "PENDING"];

export const dictionary = {
  all: undefined,
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This Week",
  previousWeek: "Last Week",
  thisMonth: "This Month",
  previousMonth: "Last Month",
  custom: undefined,
};

export const PERIOD_FILTER = (startDate = "", endDate = "") => {
  return {
    all: [],
    today: [moment().startOf("day").valueOf(), moment().endOf("day").valueOf()],
    yesterday: [
      moment().subtract(1, "days").startOf("day").valueOf(),
      moment().subtract(1, "days").endOf("day").valueOf(),
    ],
    thisWeek: [
      moment().startOf("isoWeek").valueOf(),
      moment().endOf("isoWeek").valueOf(),
    ],
    previousWeek: [
      moment().subtract(1, "week").startOf("isoWeek").valueOf(),
      moment().subtract(1, "week").endOf("isoWeek").valueOf(),
    ],
    thisMonth: [
      moment().startOf("month").valueOf(),
      moment().endOf("month").valueOf(),
    ],
    previousMonth: [
      moment().subtract(1, "month").startOf("month").valueOf(),
      moment().subtract(1, "month").endOf("month").valueOf(),
    ],
    custom: [new Date(startDate).valueOf(), new Date(endDate).valueOf()],
  };
};

export const ALL_FILTERS = [
  {
    period: "all",
    inspectedBy: "all",
    service: "all",
    type: "all",
  },
  {
    period: "all",
    inspectedBy: "all",
  },
  {
    status: "Failed",
  },
  {
    status: "all",
  },
];
