import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useLocalStorageLock = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  // 'disableReopeningTab' checks if the itemId is stored in localStorage
  const disableReopeningTab = (itemId, changeRoutePath, twoSteps) => {
    if (itemId) {
      // Check if a value is already stored in local storage with the same key as 'itemId'
      if (localStorage.getItem(itemId) === "true") {
        // If a value is present, it means that the item is already open in another tab, so set the 'isOpen' state variable to 'true'
        setIsOpen(true);
      } else if (!!!twoSteps) {
        // If no value is present, set the value in local storage to indicate that the item is now open in this tab
        localStorage.setItem(itemId, "true");
        // Set the 'isOpen' state variable to 'false'
        setIsOpen(false);
        // Navigate to the specified 'changeRoutePath' using the 'history' object, but only if 'changeRoutePath' is truthy
        if (changeRoutePath) navigate(changeRoutePath);
      }
    }
  };

  const closeOpenedTab = (itemId) => {
    if (itemId) {
      localStorage.setItem("tabCloseKey", itemId);
    }
  };

  return { isOpen, disableReopeningTab, setIsOpen, closeOpenedTab };
};
