import axesTooltip, { pieTooltip } from "../../../../../helpers/axesTooltip";

const countOnCategory = {
  title: {
    text: "Total Number of Safety Inspections",
  },
  subtitle: {
    text: "Based on Category",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "inspectionCategory",
        yKey: ["inspectionName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "inspectionCategory",
        angleKey: ["inspectionName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnStatus = {
  title: {
    text: "Total Number of Safety Inspections",
  },
  subtitle: {
    text: "Based on Status",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "inspectionStatus",
        yKey: ["inspectionName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "inspectionStatus",
        angleKey: ["inspectionName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnAddress = {
  title: {
    text: "Total Number of Safety Inspections",
  },
  subtitle: {
    text: "Based on Address",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "inspectionName",
        yKey: ["inspectionStatus"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "inspectionName",
        angleKey: ["inspectionStatus"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnProject = {
  title: {
    text: "Total Number of Safety Inspections",
  },
  subtitle: {
    text: "Based on Projects",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "projectName",
        yKey: ["inspectionName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "projectName",
        angleKey: ["inspectionName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnClient = {
  title: {
    text: "Total Number of Safety Inspections",
  },
  subtitle: {
    text: "Based on Client",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "accountName",
        yKey: ["inspectionName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "accountName",
        angleKey: ["inspectionName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnSchedule = {
  title: {
    text: "Total Number of Safety Inspections",
  },
  subtitle: {
    text: "Based on Schedule Address",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "scheduleAddress",
        yKey: ["inspectionName"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "scheduleAddress",
        angleKey: ["inspectionName"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};
const chartingOptions = [
  {
    optionTitle: "Show total Safety Inspections based on Category",
    params: countOnCategory,
  },
  {
    optionTitle: "Show total Safety Inspections based on status",
    params: countOnStatus,
  },
  {
    optionTitle: "Show total Safety Inspections based on address",
    params: countOnAddress,
  },
  {
    optionTitle: "Show total Safety Inspections based on project",
    params: countOnProject,
  },
  {
    optionTitle: "Show total Safety Inspections based on client",
    params: countOnClient,
  },
  {
    optionTitle: "Show total Safety Inspections based on schedule address",
    params: countOnSchedule,
  },
];

export default chartingOptions;
