import { Cancel, Close } from "../../../DynamicView/src/index";
import { MondayButton } from "../../../../commonComponents";
import { FilterIcon } from "../../../BasePage/src";
import { useState } from "react";
import { Modal, message } from "antd";
import "./FilterView.scss";
import {
  BarChart,
  BarChartFull,
  CircleProgresBar,
  LongLineSteper,
  Pie,
  Progress,
  StreightProgressBar,
  LineChartFull,
} from "../FilterComponents";
import { boroughs } from "../../../Dashboard/addWidgetsModalData";
import {
  appendFiltersToWidgets,
  filterChart,
  filterData,
  filterDynamicStates,
} from "./filterViewUtils";
import {
  compareFilterRange,
  restructureFilterRanges,
  setTablesFilterToStorage,
  validateFilterRange,
} from "../../../Dashboard/utils/dashboardSession";
import {
  tableIdKeys,
  tableKeyMapping,
  tableMapping,
} from "../../../Dashboard/constants";
import { toCamelCase } from "../../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import { fetchOnFilter } from "../../../Dashboard/NewDashboardFunctions";
import { useSelector } from "react-redux";

const FilterView = ({
  setUpdatedDynamicStates,
  updatedDynamicStates,
  setIsModalVisible,
  setDynamicStates,
  inspectionsData,
  estimationsData,
  maintenanceData,
  setChildParams,
  isModalVisible,
  dynamicStates,
  BodyComponent,
  programFields,
  dispatchData,
  projectsData,
  childParams,
  setRowData,
  fleetData,
  archData,
  rowData,
  engData,
  params,
  title,
  type,
  id,
  filterStates,
  setFilterStates,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  setAllFilterStates,
  tables,
  dispatchTable,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { topicCategories = [] } = useSelector(
    (state) => state.topicCategories
  );

  const defaultFilterStates = {
    filterByDay: null,
    filterByRange: null,
    filterByTime: "Pick Range",
    filterByName: null,
    filterByStatus: null,
    filterByProjectManager: null,
    filterByPercentage: null,
  };
  const [tmpFilterStates, setTmpFilterStates] = useState(
    params?.filterStates || defaultFilterStates
  );

  const functionParams = {
    programFields,
    updatedDynamicStates,
    id,
    boroughs,
    dynamicStates,
    tables,
  };

  //on click sets filter field to default and also card information to default
  const handleClearButton = () => {
    const clearButton = document.getElementById("clear-filter");
    !!clearButton && clearButton.setAttribute("disabled", "true");

    setClearFilterChart(id);
    //on filter modal close this sets filer fields value tu default

    setFilterStates(defaultFilterStates);
    setTmpFilterStates(defaultFilterStates);
    setUpdatedDynamicStates(() => ({
      ...updatedDynamicStates,
      [id]: dynamicStates[id],
    }));
    filterChart(
      filterData(type, { ...functionParams, clear: true, userConfiguration }),
      setRowData,
      id,
      window.location.pathname.split("/")[1],
      params?.table,
      params?.field,
      type,
      params?.content,
      updatedDynamicStates
    );
    appendFiltersToWidgets(
      tmpFilterStates,
      params,
      defaultFilterStates,
      preferences,
      childParams,
      setCardFilterPreferences,
      id,
      true
    );
    setAllFilterStates((prev) => {
      let tmp = { ...prev };
      delete tmp[id];
      return tmp;
    });
    setIsModalVisible(false);
  };
  //close filter modal
  const handleOk = async () => {
    const applyButton = document.getElementById("apply-filter");
    !!applyButton && applyButton.setAttribute("disabled", "true");

    setClearFilterChart(null);

    const apiTable = !!tableMapping[params?.table.toLowerCase()]
      ? tableMapping[(params?.table).toLowerCase()]
      : toCamelCase(params?.table);

    const sessionData = validateFilterRange(apiTable, tmpFilterStates) || [];
    let fetchRange = [];

    const statusKey =
      topicCategories?.find((el) => el?.categoryName === params?.table)
        ?.rowDataKeys?.statusKey || "";

    const { keysToUse = [""] } =
      topicCategories?.find((el) => el?.categoryName === params?.table) || {};

    const addressKey = keysToUse?.find(
      (el) =>
        el?.toLowerCase().includes("address") ||
        el?.toLowerCase() === "projectname"
    );

    if (sessionData?.some((el) => !el)) {
      fetchRange = null;
    } else {
      const restructuredSessionData = sessionData?.every((el) =>
        Array.isArray(el)
      )
        ? restructureFilterRanges(sessionData?.filter((el) => !!el))?.flat()
        : sessionData?.filter((el) => !!el);

      fetchRange = !!restructuredSessionData
        ? setTablesFilterToStorage(
            typeof restructuredSessionData === "string"
              ? toCamelCase(restructuredSessionData)
              : restructuredSessionData,
            apiTable,
            id
          )
        : null;
    }

    if (!!fetchRange && !!compareFilterRange(fetchRange, "initial")) {
      message.loading({
        content: "Getting Data...",
        key: "filter",
        duration: 0,
      });
      await fetchOnFilter({
        fetchRange,
        table: params?.table,
        apiTable,
        tableData: tables[params?.table],
        dispatchTable,
        idKey: tableIdKeys[apiTable],
        userConfiguration,
      })
        ?.then((res) => {
          const filteredData = filterDynamicStates({
            id,
            table: params?.table,
            statusKey,
            tmpFilterStates,
            dynamicStates: {
              ...dynamicStates,
              [id]: res,
            },
            setUpdatedDynamicStates,
            setFilterStates,
            secondaryField: params?.secondaryField,
            fields: params?.fields,
            addressKey,
          });

          filterChart(
            filterData(type, {
              ...functionParams,
              updatedDynamicStates: {
                ...updatedDynamicStates,
                [id]: filteredData,
              },
              userConfiguration,
            }),
            setRowData,
            id,
            window.location.pathname.split("/")[1],
            params?.table,
            params?.field,
            type,
            params?.content,
            res
          );
          appendFiltersToWidgets(
            tmpFilterStates,
            params,
            defaultFilterStates,
            preferences,
            childParams,
            setCardFilterPreferences,
            id
          );

          message.success({
            content: "Data successfully fetched",
            key: "filter",
          });
        })
        ?.catch((err) => {
          message.error({
            content: "Error getting data on filter, please try again later",
            key: "filter",
          });
          console.error("Error getting data on filter: ", err);
        });
    } else {
      const filteredData = filterDynamicStates({
        id,
        table: params?.table,
        statusKey,
        tmpFilterStates,
        dynamicStates,
        setUpdatedDynamicStates,
        setFilterStates,
        secondaryField: params?.secondaryField,
        fields: params?.fields,
        addressKey,
        // thirdField: params?.thirdField,
      });

      filterChart(
        filterData(type, {
          ...functionParams,
          updatedDynamicStates: {
            ...updatedDynamicStates,
            [id]: filteredData,
          },
          userConfiguration,
        }),
        setRowData,
        id,
        window.location.pathname.split("/")[1],
        params?.table,
        params?.field,
        type,
        params?.content,
        updatedDynamicStates
      );
      appendFiltersToWidgets(
        tmpFilterStates,
        params,
        defaultFilterStates,
        preferences,
        childParams,
        setCardFilterPreferences,
        id
      );
    }

    setAllFilterStates((prev) => {
      let tmp = { ...prev };
      delete tmp[id];
      return tmp;
    });

    // setFilterStates(tmpFilterStates);
    setIsModalVisible(false);
  };

  //close filter modal
  const handleCancel = () => setIsModalVisible(false);

  //this makes the speace to add addNewFIlter button if type is progress
  return (
    <Modal
      wrapClassName="filter-modal-dashboard-style"
      title={title + " Filter"}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={<Cancel />}
      destroyOnClose
      footer={
        <div className="footerContainer">
          <MondayButton
            className="clearFilterButton"
            Icon={<Close />}
            onClick={() => {
              handleClearButton();
            }}
            id="clear-filter"
          >
            Clear
          </MondayButton>
          <MondayButton
            className="filterButton"
            Icon={<FilterIcon />}
            onClick={handleOk}
            id="apply-filter"
          >
            Apply
          </MondayButton>
        </div>
      }
    >
      <BodyComponent
        {...{
          title,
          params,
          id,
          isModalVisible,
          setIsModalVisible,
          handleOk,
          handleCancel,
          BodyComponent,
          dynamicStates,
          setDynamicStates,
          updatedDynamicStates,
          setUpdatedDynamicStates,
          rowData,
          setRowData,
          programFields,
          projectsData,
          dispatchData,
          fleetData,
          inspectionsData,
          maintenanceData,
          archData,
          engData,
          estimationsData,
          setChildParams,
          childParams,
          filterStates,
          tmpFilterStates,
          /**This function reference is passed so as to not change setFilterStates in every file and create accidental bugs */
          setFilterStates: setTmpFilterStates,
          clearFilterChart,
          defaultFilterStates,
        }}
      />
    </Modal>
  );
};
//filter body for type pie
FilterView.Pie = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  handleClearButton,
  type,
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  setChildParams,
  childParams,
  filterStates,
  setFilterStates,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  setAllFilterStates,
  tables,
  dispatchTable,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: Pie,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      handleClearButton,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      setChildParams,
      childParams,
      filterStates,
      setFilterStates,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      setAllFilterStates,
      tables,
      dispatchTable,
    }}
  />
);
//filer body for type long line stepper
FilterView.LongLineSteper = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  filterStates,
  setFilterStates,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  handleClearButton,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  setAllFilterStates,
  tables,
  dispatchTable,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: LongLineSteper,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      filterStates,
      setFilterStates,
      handleClearButton,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      setAllFilterStates,
      tables,
      dispatchTable,
    }}
  />
);
//filter body for type straight progress bar
FilterView.StreightProgressBar = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  filterStates,
  setFilterStates,
  handleClearButton,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  setAllFilterStates,
  tables,
  dispatchTable,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: StreightProgressBar,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      estimationsData,
      filterStates,
      setFilterStates,
      handleClearButton,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      setAllFilterStates,
      tables,
      dispatchTable,
    }}
  />
);
//filter body for circle progress bar
FilterView.CircleProgresBar = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  filterStates,
  setFilterStates,
  handleClearButton,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  setAllFilterStates,
  tables,
  dispatchTable,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: CircleProgresBar,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      filterStates,
      setFilterStates,
      handleClearButton,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      setAllFilterStates,
      tables,
      dispatchTable,
    }}
  />
);
//filter body for bar chart
FilterView.BarChart = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  filterStates,
  setFilterStates,
  applicationsData,
  chargesData,
  rentalsData,
  invoicesData,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  setAllFilterStates,
  tables,
  dispatchTable,

  // handleClearButton,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: BarChart,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      filterStates,
      setFilterStates,
      applicationsData,
      chargesData,
      rentalsData,
      invoicesData,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      setAllFilterStates,
      tables,
      dispatchTable,
      // handleClearButton,
    }}
  />
);

FilterView.LineChartFull = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  filterStates,
  setFilterStates,
  applicationsData,
  chargesData,
  rentalsData,
  invoicesData,
  handleClearButton,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  tables,
  dispatchTable,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: LineChartFull,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      filterStates,
      setFilterStates,
      applicationsData,
      chargesData,
      rentalsData,
      invoicesData,
      handleClearButton,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      tables,
      dispatchTable,
    }}
  />
);
//filter body for the big bar chart
FilterView.BarChartFull = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  filterStates,
  setFilterStates,
  handleClearButton,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  tables,
  dispatchTable,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: BarChartFull,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      filterStates,
      setFilterStates,
      handleClearButton,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      tables,
      dispatchTable,
    }}
  />
);
//filter body for the progress status card
FilterView.Progress = ({
  title,
  params,
  id,
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  filterStates,
  setFilterStates,
  handleClearButton,
  setClearFilterChart,
  clearFilterChart,
  setCardFilterPreferences,
  preferences,
  setAllFilterStates,
  tables,
  dispatchTable,
}) => (
  <FilterView
    {...{
      title,
      params,
      id,
      isModalVisible,
      setIsModalVisible,
      handleOk,
      handleCancel,
      BodyComponent: Progress,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      filterStates,
      setFilterStates,
      handleClearButton,
      setClearFilterChart,
      clearFilterChart,
      setCardFilterPreferences,
      preferences,
      setAllFilterStates,
      tables,
      dispatchTable,
    }}
  />
);

export default FilterView;
