import { Modal, Button, Collapse, message, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import "./ScheduleDaysPreview.scss";
import {
  calcData,
  checkIfServiceIsOtherScope,
  columnDaysForDaysPreview,
  dayDetail,
  progressInPerc,
  remainingPliProgress,
  swalToast,
} from "../SchedulingFirstPage/helperData";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { MondayButton } from "../../../../commonComponents";
import { get_ProgressDimensionsByServiceId } from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import SubPliBreakdown from "./SubPliBreakdown";
import CustomProgressBar from "../../../../commonComponents/CustomProgress/CustomProgressBar";
import { CollapseArrow } from "../../../../../assets";
import JumpsPreviewModal from "./JumpsPreviewModal";
import { fetchAllData, updateDocumentTitle } from "../../../../../utils";
import { SchedulingIconNormalSize } from "../../../../../icons";
import { LeftArrow, RightArrow } from "../../../../SidebarPages/BasePage/src";
import ProgressDaysView from "./ProgressDaysView";
import Swal from "sweetalert2";
import { groupBy } from "lodash";
const ScheduleDaysPreview = (props) => {
  const {
    scheduleDays = [],
    typeOfWork = "",
    infoForOneDay = false,
    preview = false,
    setPreview = () => {},
    isDarkMode = false,
    scheduleId = "",
    services = {},
  } = props;

  const [toBeScheduled, setToBeScheduled] = useState({});
  const [progressMode, setProgressMode] = useState(false);
  const [allDaysToBeDisplayed, setAllDaysToBeDisplayed] = useState(
    scheduleDays || []
  );
  const [activeViewPanels, setActiveViewPanels] = useState([]);
  const commonInitialState = {
    open: false,
    data: [],
    date: null,
    dayId: "",
  };

  const onlyProgressDays = [];

  const [jumpsForHoist, setJumpsForHoist] = useState({
    ...commonInitialState,
    floors: [],
  });

  const [subPliBreakdowns, setSubPliBreakdowns] = useState({
    ...commonInitialState,
    comesFrom: "",
    serviceId: null,
  });

  const [days, setDays] = useState([]);
  const { Panel } = Collapse;
  const daysToShow = (day) => {
    setDays((prevDays) => {
      if (prevDays?.some((a) => a?.id === day?.id)) {
        return prevDays?.filter((x) => x?.id !== day?.id);
      } else {
        return [...prevDays, day];
      }
    });

    setAllDaysToBeDisplayed([]);
  };
  let scheduleDaysDetails = !allDaysToBeDisplayed?.length
    ? days
    : allDaysToBeDisplayed;
  scheduleDaysDetails = scheduleDaysDetails?.sort((a, b) => a?.day - b?.day);
  const allDaysIds = scheduleDaysDetails?.map((id) => id?.id);
  const dayDetails = dayDetail(toBeScheduled, allDaysIds);

  useEffect(() => {
    if (!!preview) {
      if (!days?.length) {
        setAllDaysToBeDisplayed(scheduleDays);
      }
    }
  }, [preview, scheduleDays]);

  useEffect(() => {
    if (!!preview && !!scheduleId) {
      if (!Object.keys(services || {}).length) {
        const fetchEstimations = async () => {
          try {
            message.loading({
              content: "Loading estimations...",
              key: "loadingEstimations",
            });
            const getData = await fetchAllData({
              endpoint: "toBeScheduled",
              resultPosition: "toBeScheduled",
              resultId: "toBeScheduledId",
              otherStringParams: {
                filters: JSON.stringify([
                  {
                    conditions: [
                      {
                        column: "scheduleId",
                        value: scheduleId,
                        formula: "is",
                      },
                    ],
                  },
                ]),
              },
            });

            const groupData = groupBy(getData, "estimationId");
            setToBeScheduled(groupData);
          } catch (err) {
            message.error("Error while loading estimations!");
            console.log(err);
          } finally {
            message.destroy("loadingEstimations");
            message.success("Estimations loaded successfully!");
          }
        };

        fetchEstimations();
      } else {
        setToBeScheduled(services);
      }
    }
  }, [preview]);

  return (
    <div>
      <Modal
        open={preview}
        centered
        footer={null}
        title={progressMode ? "Progress Days Preview" : "Schedule Days Preview"}
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Schedule Days Preview" });
        }}
        closeIcon={<XIcon />}
        className={`schedulePreviewModal ${
          isDarkMode && "schedulePreviewModalDark"
        }`}
        onCancel={() => {
          updateDocumentTitle(); // reset document title to "Lead Manager"
          setPreview(false);
          setProgressMode(false);
        }}
        width={1371}
        destroyOnClose={true}
        maskClosable={false}
      >
        {!progressMode && (
          <div className="schedulePreviewModal-content">
            <div className="schedulePreviewModal-days">
              {!infoForOneDay &&
                scheduleDays?.map((el, index) => {
                  return (
                    <Tooltip
                      key={index}
                      // placement="top"
                      title={dayjsNY(el?.startDate)
                        .startOf("day")
                        .format("MM/DD/YYYY")}
                    >
                      <Button
                        onClick={() => daysToShow(el)}
                        className="schedulePreviewModal-buttons"
                        style={
                          days?.some((q) => q?.id === el?.id)
                            ? {
                                background: "#0f5c97",
                                color: "#fff",
                              }
                            : {}
                        }
                      >
                        {"D" + el?.day}
                      </Button>
                    </Tooltip>
                  );
                })}
              {!infoForOneDay && (
                <Button
                  onClick={() => {
                    setAllDaysToBeDisplayed(scheduleDays);
                    setDays([]);
                  }}
                  className="schedulePreviewModal-buttons-allDays"
                  style={
                    allDaysToBeDisplayed?.length
                      ? {
                          background: "#0f5c97",
                          color: "#fff",
                        }
                      : {}
                  }
                >
                  All Days
                </Button>
              )}
              {/* <MondayButton onClick={() => expandAll()}>EXPAND ALL</MondayButton> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                maxHeight: 716,
                overflowY: "scroll",
              }}
            >
              {scheduleDaysDetails?.map((day, index) => {
                const dataInExtra = {
                  estimation:
                    dayDetails?.[day?.id]
                      ?.map((a) => a?.estimationNumber)
                      ?.join(", ") || null,
                  service:
                    dayDetails?.[day?.id]
                      ?.flatMap?.(({ services = [] }) =>
                        services?.map?.(({ serviceName = "" }) => serviceName)
                      )
                      ?.join(", ") || null,
                };
                return (
                  <div key={index}>
                    <Collapse
                      onChange={(e) => {
                        if (!e?.length) {
                          setActiveViewPanels([]);
                        } else {
                          return;
                        }
                      }}
                      destroyInactivePanel={true}
                      forceRender={false}
                      bordered={false}
                      collapsible={
                        !dayDetails?.[day?.id]?.length ? "disabled" : ""
                      }
                      expandIcon={({ isActive }) => (
                        <CollapseArrow
                          style={{
                            transform: !isActive ? "rotate(90deg)" : "",
                          }}
                          height={12}
                          width={12}
                        />
                      )}
                      defaultActiveKey={activeViewPanels}
                    >
                      <Panel
                        key={day?.id}
                        header={
                          dayDetails?.[day?.id]?.length ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                              }}
                            >
                              <b>
                                {dayjsNY(day?.startDate)
                                  .startOf("day")
                                  .format("MM/DD/YYYY")}
                              </b>
                              <MondayButton
                                onClick={() => {
                                  setActiveViewPanels(day?.id);
                                }}
                                {...{
                                  className: "mondayButtonBlue",
                                  hasIcon: false,
                                }}
                              >
                                Expand all
                              </MondayButton>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                              }}
                            >
                              <b>
                                {dayjsNY(day?.startDate)
                                  .startOf("day")
                                  .format("MM/DD/YYYY")}
                              </b>
                              <b
                                style={{
                                  color: "gray",
                                }}
                              >
                                Not Scheduled!
                              </b>
                            </div>
                          )
                        }
                        extra={
                          dayDetails?.[day?.id]?.length ? (
                            <div
                              style={{
                                display: "flex",
                                gap: 15,
                              }}
                            >
                              <div>
                                <b
                                  style={{
                                    color: isDarkMode ? "#fff" : "",
                                  }}
                                >
                                  ESTIMATE :
                                </b>{" "}
                                <span
                                  style={isDarkMode ? { color: "#fff" } : {}}
                                >
                                  {dataInExtra?.estimation}
                                </span>
                              </div>
                              <div>
                                <b
                                  style={{
                                    color: isDarkMode ? "#fff" : "",
                                  }}
                                >
                                  SOW :
                                </b>{" "}
                                <span
                                  style={isDarkMode ? { color: "#fff" } : {}}
                                >
                                  {dataInExtra?.service}
                                </span>
                              </div>
                              <b></b>
                            </div>
                          ) : null
                        }
                      >
                        {dayDetails?.[day?.id]?.map?.((detail, index) => {
                          return (
                            <Collapse
                              key={index}
                              destroyInactivePanel={true}
                              forceRender={false}
                              defaultActiveKey={activeViewPanels}
                              bordered={false}
                              expandIcon={({ isActive }) => (
                                <CollapseArrow
                                  style={{
                                    transform: !isActive ? "rotate(90deg)" : "",
                                  }}
                                  height={12}
                                  width={12}
                                />
                              )}
                            >
                              <Panel
                                key={day?.id}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <b>
                                      {"Estimation" +
                                        " " +
                                        detail?.estimationNumber}
                                    </b>
                                    <CustomProgressBar
                                      progressDone={detail?.estimationProgress}
                                      breakDownProp={true}
                                    />
                                  </div>
                                }
                              >
                                {detail?.services?.map((service, index) => {
                                  const { d1, d2 } =
                                    get_ProgressDimensionsByServiceId(
                                      service?.serviceId
                                    );
                                  return (
                                    <Collapse
                                      key={index}
                                      destroyInactivePanel={true}
                                      forceRender={false}
                                      defaultActiveKey={activeViewPanels}
                                      bordered={false}
                                      expandIcon={({ isActive }) => (
                                        <CollapseArrow
                                          style={{
                                            transform: !isActive
                                              ? "rotate(90deg)"
                                              : "",
                                          }}
                                          height={12}
                                          width={12}
                                        />
                                      )}
                                    >
                                      <Panel
                                        key={day?.id}
                                        header={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: 10,
                                            }}
                                          >
                                            <b>{service?.serviceName}</b>
                                            <CustomProgressBar
                                              progressDone={
                                                service?.serviceProgress
                                              }
                                              breakDownProp={true}
                                            />
                                          </div>
                                        }
                                      >
                                        {service?.serviceOptions?.length > 0 &&
                                          service?.serviceOptions?.map(
                                            (option, index) => {
                                              return (
                                                <Collapse
                                                  key={index}
                                                  destroyInactivePanel={true}
                                                  forceRender={false}
                                                  defaultActiveKey={
                                                    activeViewPanels
                                                  }
                                                  bordered={false}
                                                  expandIcon={({
                                                    isActive,
                                                  }) => (
                                                    <CollapseArrow
                                                      style={{
                                                        transform: !isActive
                                                          ? "rotate(90deg)"
                                                          : "",
                                                      }}
                                                      height={12}
                                                      width={12}
                                                    />
                                                  )}
                                                >
                                                  <Panel
                                                    key={day?.id}
                                                    header={
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          gap: 10,
                                                        }}
                                                      >
                                                        <b>
                                                          {" "}
                                                          {
                                                            option?.elevationLabel
                                                          }
                                                        </b>
                                                        <CustomProgressBar
                                                          progressDone={
                                                            option?.elevationProgress
                                                          }
                                                          breakDownProp={true}
                                                        />
                                                      </div>
                                                    }
                                                  >
                                                    {option?.items?.map(
                                                      (item, index) => {
                                                        const progressByDimension =
                                                          item?.progressByDay?.find(
                                                            (a) =>
                                                              a?.day === day?.id
                                                          );
                                                        return (
                                                          <Collapse
                                                            key={index}
                                                            destroyInactivePanel={
                                                              true
                                                            }
                                                            forceRender={false}
                                                            defaultActiveKey={
                                                              activeViewPanels
                                                            }
                                                            bordered={false}
                                                            expandIcon={({
                                                              isActive,
                                                            }) => (
                                                              <CollapseArrow
                                                                style={{
                                                                  transform:
                                                                    !isActive
                                                                      ? "rotate(90deg)"
                                                                      : "",
                                                                }}
                                                                height={12}
                                                                width={12}
                                                              />
                                                            )}
                                                          >
                                                            <Panel
                                                              key={day?.id}
                                                              header={
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "center",
                                                                    gap: 10,
                                                                  }}
                                                                >
                                                                  <b>
                                                                    {(service?.isHoist
                                                                      ? "Floor"
                                                                      : "Pli") +
                                                                      " " +
                                                                      item?.id}
                                                                  </b>
                                                                  <CustomProgressBar
                                                                    progressDone={progressInPerc(
                                                                      item?.totalProgress,
                                                                      d1,
                                                                      d2,
                                                                      item
                                                                    )}
                                                                    breakDownProp={
                                                                      true
                                                                    }
                                                                  />
                                                                </div>
                                                              }
                                                            >
                                                              <div>
                                                                <table
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  <tr>
                                                                    {columnDaysForDaysPreview(
                                                                      d1,
                                                                      d2
                                                                    )?.map(
                                                                      (
                                                                        header,
                                                                        index
                                                                      ) => {
                                                                        return (
                                                                          <th
                                                                            key={
                                                                              index
                                                                            }
                                                                          >
                                                                            {
                                                                              header
                                                                            }
                                                                          </th>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {item?.[
                                                                        d1
                                                                      ] || "-"}
                                                                    </td>
                                                                    {!!item?.[
                                                                      d2
                                                                    ] && (
                                                                      <td
                                                                        style={{
                                                                          textAlign:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        {item?.[
                                                                          d2
                                                                        ] ||
                                                                          "-"}
                                                                      </td>
                                                                    )}
                                                                    {service?.isHoist && (
                                                                      <td
                                                                        style={{
                                                                          textAlign:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <Button
                                                                          onClick={() => {
                                                                            setJumpsForHoist(
                                                                              {
                                                                                floors:
                                                                                  option?.items,
                                                                                open: true,
                                                                                data: option?.jumps,
                                                                                date: dayjsNY(
                                                                                  day?.startDate
                                                                                )
                                                                                  .startOf(
                                                                                    "day"
                                                                                  )
                                                                                  .format(
                                                                                    "MM/DD/YYYY"
                                                                                  ),
                                                                                dayId:
                                                                                  day?.id,
                                                                              }
                                                                            );
                                                                          }}
                                                                          style={{
                                                                            background:
                                                                              "#0f5c97",
                                                                            color:
                                                                              "#fff",
                                                                            border:
                                                                              "none",
                                                                          }}
                                                                        >
                                                                          {
                                                                            option
                                                                              ?.jumps
                                                                              ?.length
                                                                          }
                                                                        </Button>
                                                                      </td>
                                                                    )}
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {
                                                                        item?.totalProgress
                                                                      }
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {!item?.typeOfProgress
                                                                        ? "-"
                                                                        : item?.typeOfProgress ===
                                                                          "dimensions"
                                                                        ? !item?.[
                                                                            d2
                                                                          ]
                                                                          ? progressByDimension?.[
                                                                              d1
                                                                            ]
                                                                          : progressByDimension?.[
                                                                              d1
                                                                            ] *
                                                                            progressByDimension?.[
                                                                              d2
                                                                            ]
                                                                        : service?.isHoist
                                                                        ? "Check jumps & breakdowns"
                                                                        : "Check" +
                                                                          " " +
                                                                          item?.typeOfProgress}
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {service?.isHoist
                                                                        ? "-"
                                                                        : item?.typeOfProgress ||
                                                                          "-"}
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      <Button
                                                                        onClick={() => {
                                                                          if (
                                                                            !item
                                                                              ?.subPli
                                                                              ?.length
                                                                          ) {
                                                                            message.warning(
                                                                              "No Sub Pli Available"
                                                                            );
                                                                          } else {
                                                                            setSubPliBreakdowns(
                                                                              {
                                                                                open: true,
                                                                                data: item?.subPli,
                                                                                comesFrom:
                                                                                  "Sub Pli",
                                                                                dayId:
                                                                                  day?.id,
                                                                                date: dayjsNY(
                                                                                  day?.startDate
                                                                                )
                                                                                  .startOf(
                                                                                    "day"
                                                                                  )
                                                                                  .format(
                                                                                    "MM/DD/YYYY"
                                                                                  ),
                                                                                serviceId:
                                                                                  service?.serviceId,
                                                                              }
                                                                            );
                                                                          }
                                                                        }}
                                                                        style={{
                                                                          background:
                                                                            "#0f5c97",
                                                                          color:
                                                                            "#fff",
                                                                          border:
                                                                            "none",
                                                                        }}
                                                                      >
                                                                        {
                                                                          item
                                                                            ?.subPli
                                                                            ?.length
                                                                        }
                                                                      </Button>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      <Button
                                                                        onClick={() => {
                                                                          if (
                                                                            !item
                                                                              ?.breakdownValue
                                                                              ?.length
                                                                          ) {
                                                                            message.warning(
                                                                              "No Breakdowns Available"
                                                                            );
                                                                          } else
                                                                            setSubPliBreakdowns(
                                                                              {
                                                                                open: true,
                                                                                data: item?.breakdownValue,
                                                                                comesFrom:
                                                                                  service?.isHoist
                                                                                    ? "Jump Breakdowns"
                                                                                    : "Breakdowns",
                                                                                dayId:
                                                                                  day?.id,
                                                                                date: dayjsNY(
                                                                                  day?.startDate
                                                                                )
                                                                                  .startOf(
                                                                                    "day"
                                                                                  )
                                                                                  .format(
                                                                                    "MM/DD/YYYY"
                                                                                  ),
                                                                                serviceId:
                                                                                  service?.serviceId,
                                                                              }
                                                                            );
                                                                        }}
                                                                        style={{
                                                                          background:
                                                                            "#0f5c97",
                                                                          color:
                                                                            "#fff",
                                                                          border:
                                                                            "none",
                                                                        }}
                                                                      >
                                                                        {
                                                                          item
                                                                            ?.breakdownValue
                                                                            ?.length
                                                                        }
                                                                      </Button>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {remainingPliProgress(
                                                                        item,
                                                                        d1,
                                                                        d2
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                              </div>
                                                            </Panel>
                                                          </Collapse>
                                                        );
                                                      }
                                                    )}
                                                  </Panel>
                                                </Collapse>
                                              );
                                            }
                                          )}
                                        {service?.serviceAddon?.length > 0 &&
                                          service?.serviceAddon?.map(
                                            (addon, index) => {
                                              return (
                                                <Collapse
                                                  key={index}
                                                  destroyInactivePanel={true}
                                                  forceRender={false}
                                                  defaultActiveKey={
                                                    activeViewPanels
                                                  }
                                                  bordered={false}
                                                  expandIcon={({
                                                    isActive,
                                                  }) => (
                                                    <CollapseArrow
                                                      style={{
                                                        transform: !isActive
                                                          ? "rotate(90deg)"
                                                          : "",
                                                      }}
                                                      height={12}
                                                      width={12}
                                                    />
                                                  )}
                                                >
                                                  <Panel
                                                    key={day?.id}
                                                    header={
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          gap: 10,
                                                        }}
                                                      >
                                                        <b>{addon?.name}</b>
                                                        <CustomProgressBar
                                                          progressDone={
                                                            calcData(
                                                              addon?.progressByDay,
                                                              "progress"
                                                            ) /
                                                            addon?.progressByDay
                                                              ?.length
                                                          }
                                                          breakDownProp={true}
                                                        />
                                                      </div>
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 5,
                                                        paddingLeft: 25,
                                                      }}
                                                    >
                                                      <span>
                                                        Day Progress:{" "}
                                                      </span>
                                                      <b>
                                                        {(addon?.progressByDay?.find(
                                                          (a) =>
                                                            a?.id === day?.id
                                                        )?.progress || 0) + "%"}
                                                      </b>
                                                    </div>
                                                  </Panel>
                                                </Collapse>
                                              );
                                            }
                                          )}
                                      </Panel>
                                    </Collapse>
                                  );
                                })}
                              </Panel>
                            </Collapse>
                          );
                        })}
                      </Panel>
                    </Collapse>
                  </div>
                );
              })}
            </div>
            {/* s */}
          </div>
        )}
        {subPliBreakdowns?.open && (
          <SubPliBreakdown
            {...{
              subPliBreakdowns,
              setSubPliBreakdowns,
              isDarkMode: isDarkMode,
            }}
          />
        )}
        {jumpsForHoist?.open && (
          <JumpsPreviewModal
            {...{
              jumpsForHoist,
              setJumpsForHoist,
              isDarkMode: isDarkMode,
            }}
          />
        )}
        {progressMode && <ProgressDaysView {...{ onlyProgressDays }} />}
      </Modal>
    </div>
  );
};

export default ScheduleDaysPreview;
