import uniq from "lodash/uniq"
import { getMonthsTimestamps } from "../../../../utils"
import { months } from "../../../addWidgetsModalData"

export const fleet = ({ tables }) => {
  return {
    fleetYear: () => ({
      content: uniq(tables?.Fleet?.map((a) => a?.fleetYear))
        ?.sort()
        ?.map((fleetYear, index) => ({
          key: index,
          title: fleetYear,
          taskNumber: tables?.Fleet?.length,
          taskDone: tables?.Fleet?.filter((a) => a?.fleetYear === fleetYear)?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Fleet In " + fleetYear,
          contentsHandler: " Fleets In " + fleetYear,
        })),
    }), //fleetYear
    registrationExpDate: () => ({
      content: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              title: months[i],
              taskNumber: tables?.Fleet?.filter(
                (b) => b.registrationExpDate >= getMonthsTimestamps()[0]
              )?.length,
              taskDone: tables?.Fleet?.filter(
                (a) =>
                  a.registrationExpDate >= getMonthsTimestamps()[i] &&
                  a.registrationExpDate <= getMonthsTimestamps("end")[i]
              )?.length,
              color: "#71CF48",
              backgroundColor: "#B2DD9B",
              contentHandler: " Expiration In " + months[i],
              contentsHandler: " Expiration In " + months[i],
            },
          ]
        }
        return data
      })(),
    }), //registrationExpDate
    inspectionExpDate: () => ({
      content: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              title: months[i],
              taskNumber: tables?.Fleet?.filter(
                (b) => b.inspectionExpDate >= getMonthsTimestamps()[0]
              )?.length,
              taskDone: tables?.Fleet?.filter(
                (a) =>
                  a.inspectionExpDate >= getMonthsTimestamps()[i] &&
                  a.inspectionExpDate <= getMonthsTimestamps("end")[i]
              )?.length,
              color: "#71CF48",
              backgroundColor: "#B2DD9B",
              contentHandler: " Expiration In " + months[i],
              contentsHandler: " Expiration In " + months[i],
            },
          ]
        }
        return data
      })(),
    }), //inspectionExpDate
  } //Fleet
}
