export const formatAccumulatedRentals = (dataObj) => {
  let result = [];

  for (let key in dataObj) {
    for (let rentalKey in dataObj[key]) {
      for (let label in dataObj[key][rentalKey]) {
        result.push({
          ...dataObj[key][rentalKey][label],
          rentalNumber: rentalKey,
          requisitionNumber: key,
        });
      }
    }
  }

  return result;
};
