export const rentals = (
  circleProgressPicker,
  IdHandler,
  updatedDynamicStates,
  rentalsData
) => {
  return {
    rentalStatus: () => ({
      title: "Rentals - Category From",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Rentals",
        field: "rentalStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (rentalsData?.filter((a) => a?.rentalStatus === circleProgressPicker)
            ?.length /
            rentalsData?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: rentalsData?.filter(
          (a) => a?.rentalStatus === circleProgressPicker
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }), //rentalStatus
  };
};
