import { Button, Checkbox, message, Modal } from "antd";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { MondayButton } from "../../../../../../../commonComponents";
import { AddIcon } from "../../../../../../../Header/components/GoogleEvents/assets";
import { EditIcon } from "../../../../../../DynamicView/src";
import "./../../../../Applications/ApplicationView/components/ServiceList/Service/ServiceHeader/ServiceHeaderComponents/ChangeRetenageModal/ChangeRetenageModal.scss";
function AddServices({
  estimationId,
  services,
  addServices,
  disabled,
  darkMode,
}) {
  const [active, setActive] = useState(false);
  const [loadedServices, setLoadedServices] = useState([]);
  const [pickedServices, setPickedServices] = useState([]);
  const handleView = () => {
    setActive(!active);
    setLoadedServices([]);
    setPickedServices([]);
  };
  const retrieveEstimation = async () => {
    await API.get("estimations", `/estimations/${estimationId}`).then((res) => {
      console.log("Specific  Res", res);
      let temp = [];
      let already = services || [];
      res.services.forEach((service) => {
        if (!already.some((s) => s.serviceId === service.serviceId)) {
          temp.push({
            ...service,
            estimationId: res?.estimationId,
            isChangeOrder: res?.isChangeOrder || false,
          });
        }
      });
      setLoadedServices(temp);
    });
  };
  //key, service?.serviceId, e?.target?.checked
  const onPickServices = (sId, checked) => {
    let temp = pickedServices || [];
    if (checked) {
      if (!temp.some((s) => s.serviceId === sId?.serviceId)) {
        temp.push({
          ...sId,
          totalPrice: 0,
          amounts: [],
          // isScope: false,
          isTaxable: sId?.taxRate !== 0,
          typeOfSOV: "",
        });
        setPickedServices(temp);
      }
    } else {
      temp = temp.filter((s) => s.serviceId !== sId?.serviceId);
      setPickedServices(temp);
    }
  };
  const onSaveChanges = async () => {
    await addServices(estimationId, pickedServices);
    handleView();
  };
  useEffect(() => {
    if (!!estimationId && active) {
      message.info("Loading Data from Estimation");
      retrieveEstimation();
    }
  }, [estimationId, active]);

  return (
    <div>
      <div
        style={{
          marginTop: "5px",
          marginLeft: "5px",
          marginRight: "5px",
          // width: "298px",
        }}
      >
        <span
          onClick={handleView}
          style={{
            fontSize: "12px",
            fontWeight: "600",
            cursor: "pointer",
          }}
        >
          edit
        </span>
        {/* <MondayButton onClick={handleView} disabled={disabled}>
          Edit
        </MondayButton> */}
      </div>
      <Modal
        title="Include New Service?"
        wrapClassName={
          darkMode
            ? "changeRetenagePercentageApplicationModalDark"
            : "changeRetenagePercentageApplicationModalLight"
        }
        open={active}
        okText="Save Changes"
        // open={handleView}
        onOk={onSaveChanges}
        onCancel={handleView}
        destroyOnClose
      >
        {loadedServices?.length > 0 ? (
          loadedServices?.map((service) => (
            <div
              style={{
                display: "flex",
                gap: "10px",
                color: darkMode ? "white" : "black",
              }}
            >
              <Checkbox
                className="estimation-services-checkbox"
                // checked={true}
                onChange={(e) => onPickServices(service, e?.target?.checked)} // key is estimationId, or instead you can put service.estimationId
                // disabled={!isWritable || isLoading}
              />
              <span>{service?.label}</span>
            </div>
          ))
        ) : (
          <div style={{ color: darkMode ? "white" : "black" }}>
            No Services to Add
          </div>
        )}
      </Modal>
    </div>
  );
}
export default AddServices;
