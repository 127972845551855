import RoleHeaderTab from "./RoleHeaderTab";

const RoleHeader = ({
  roleData,
  selectedTab,
  setSelectedTab,
  setSearchRouteConfigs = () => {},
}) => {
  const sidebarElements = roleData ? Object.keys(roleData) : [];

  return (
    <div className="collapsedContainer-header">
      {sidebarElements.map((title) => {
        return (
          <RoleHeaderTab
            key={title}
            {...{ title, selectedTab, setSelectedTab, setSearchRouteConfigs }}
          />
        );
      })}
    </div>
  );
};

export default RoleHeader;
