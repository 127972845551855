import { DatePicker, Tooltip } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
const { RangePicker } = DatePicker;
export const PeriodComp = ({ aggridHeader }) => {
  const { startDate = undefined, endDate = undefined } = aggridHeader;
  return (
    <Tooltip title={`Period`}>
      <div>
        <RangePicker
          className="titleHeaderLabel withTooltipTitle EditDatePickerComponent"
          disabled
          presets={getRangePresets()}
          value={[
            startDate ? dayjs.tz(startDate) : null,
            startDate ? dayjs.tz(endDate) : null,
          ]}
          format="MM/DD/YYYY"
        />
      </div>
    </Tooltip>
  );
};
