export const TypeOfWorkDefinitions = {
  INSTALLATION: {
    label: "Installation",
    progressStatuses: {
      NOT_DONE: "Not done",
      PARTIALLY_DONE: "Partially done",
      TOTAL_DONE: "Totally done",
      OVER_DONE: "Over done",
    },
  },
  REMOVAL: {
    label: "Removal",
    progressStatuses: {
      NOT_DONE: "Not done",
      PARTIALLY_DONE: "Partially done",
      TOTAL_DONE: "Totally done",
      OVER_DONE: "Over done",
    },
  },
  REPAIR: {
    label: "Repair",
    progressStatuses: {
      NOT_DONE: "Not done",
      PARTIALLY_DONE: "Partially done",
      TOTAL_DONE: "Totally done",
      OVER_DONE: "Over done",
    },
  },
  INSPECTION: {
    label: "Inspection",
    progressStatuses: {
      NOT_DONE: "Not done",
      PARTIALLY_DONE: "Partially done",
      TOTAL_DONE: "Totally done",
      OVER_DONE: "Over done",
    },
  },
  "PICK UP MATERIAL": {
    label: "Pick up material",
    progressStatuses: {
      NOT_DONE: "Not done",
      PARTIALLY_DONE: "Partially done",
      TOTAL_DONE: "Totally done",
      OVER_DONE: "Over done",
    },
  },
  "DELIVER MATERIAL": {
    label: "Deliver material",
    progressStatuses: {
      NOT_DONE: "Not done",
      PARTIALLY_DONE: "Partially done",
      TOTAL_DONE: "Totally done",
      OVER_DONE: "Over done",
    },
  },
  MAINTENANCE: {
    label: "Maintenance",
    progressStatuses: {
      NOT_DONE: "Not done",
      PARTIALLY_DONE: "Partially done",
      TOTAL_DONE: "Totally done",
      OVER_DONE: "Over done",
    },
  },
};

export const progressStatuses = [
  "NOT_DONE",
  "PARTIALLY_DONE",
  "TOTAL_DONE",
  "OVER_DONE",
];
