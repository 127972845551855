import { API } from "aws-amplify";
import { updateProgramFieldByName } from "../../../../actions/programFields";
import { message } from "antd";

const dispatchExportTemplates = async (fieldId, fieldOptions, dispatch) => {
  message.loading({ content: "Saving...", key: "exportTemplate", duration: 0 });
  return await API.patch("programFields", `/programFields/${fieldId}`, {
    body: { fieldOptions },
  })
    .then(() => {
      dispatch(
        updateProgramFieldByName({
          fieldName: "Pdf Export Filter Templates",
          fieldOptions,
        })
      );
      message.success({
        content: "Saved!",
        key: "exportTemplate",
        duration: 2,
      });
    })
    .catch((error) => {
      console.log({ error });
      message.error({ content: "There was a problem!", key: "exportTemplate" });
    });
};

export default dispatchExportTemplates;
