import { Select } from "antd";
import { useState } from "react";
import { DropdownIcon } from "../../../../../../../BasePage/src";
import { MondayButton } from "../../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../Communication/assets";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";

const AddDependency = ({
  tasks = [],
  closeAddDependency = () => {},
  addDependency = () => {},
  blocked,
}) => {
  const [task, setTask] = useState("");

  return (
    <>
      <Select
        value={task || "Select one"}
        onChange={setTask}
        suffixIcon={<DropdownIcon />}
      >
        {tasks.map((task, index) => {
          return (
            <Select.Option value={task.taskId} key={index}>
              {task.taskTitle}
            </Select.Option>
          );
        })}
      </Select>
      {!!task && (
        <div className="detailsModal__dependency__buttons">
          <MondayButton Icon={<XIcon />} onClick={closeAddDependency}>
            Close
          </MondayButton>
          <MondayButton
            Icon={<TickIcon />}
            onClick={() => {
              addDependency(task, blocked);
              setTask("");
            }}
          >
            Add
          </MondayButton>
        </div>
      )}
    </>
  );
};

export default AddDependency;
