import { Modal } from "antd";
import ReportLabel from "../ReportLabel/ReportLabel";
import { XIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";
import filterReport from "./helpers";

const FavoriteCategoriesModal = ({
  favoritesModal,
  setFavoritesModal,
  onCategoryClick = () => {},
  reportsConfiguration = [],
  identityId,
  isDarkMode,
}) => {
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  return (
    <Modal
      {...{
        open: favoritesModal,
        onCancel: () => setFavoritesModal(false),
        title: "Favorite Categories",
        className: `reportsPageModals ${isDarkMode && "reportsPageModalsDark"}`,
        footer: null,
        closeIcon: <XIcon />,
      }}
    >
      <div className="listContainer">
        {filterReport({ allPreferences, reportsConfiguration }).map(
          ({ categoryName, department }) => (
            <ReportLabel
              {...{
                key: categoryName,
                categoryName,
                department,
                onClick: () => onCategoryClick(categoryName),
              }}
            />
          )
        )}
      </div>
    </Modal>
  );
};

export default FavoriteCategoriesModal;
