import { Dropdown } from "antd";
import { useSelector } from "react-redux";
import { EllipsisOutlined } from "@ant-design/icons";
import { useState, forwardRef, useMemo, useCallback, useRef } from "react";

import {
  getReportCardOptions,
  getReportCardOptionsWithRemove,
} from "../../../../../../data";
import LayoutChart from "../LayoutChart/LayoutChart";
import {
  loadLivePreference,
  saveLocalPreferences,
} from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { formatNumber } from "src/components/SidebarPages/utils";
import { SmallLoadableComp } from "../../../../../../../../../Sidebars/components";

import "./LayoutCard.scss";

const LayoutCard = forwardRef(
  (
    {
      style,
      title,
      onHide,
      cardKey,
      loading,
      onRemove,
      children,
      className,
      onMouseUp,
      onTouchEnd,
      onMouseDown,
      cardRemovable,
      headerComponents,
      updateCardInLayout,
      ...rest
    },
    ref
  ) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const [chartType, setChartType] = useState(
      rest?.chartData?.chartType
        ? rest?.chartData?.chartType
        : loadLivePreference("liveChartTypes")?.[title] || "Line"
    );
    const cardBodyRef = useRef();
    const totalsContainerRef = useRef();

    const { chartData } = rest;
    const roundCharts = ["Pie", "Doughnut"];

    const updateChartType = useCallback(
      (type, dataset) => {
        if (type === chartType && !dataset) {
          return;
        }

        if (dataset) {
          const roundTypes = ["pie", "doughnut"];
          const roundCharts =
            roundTypes.includes(type) &&
            !roundTypes.includes(chartType.toLowerCase());

          const barCharts =
            !roundTypes.includes(type) &&
            roundTypes.includes(chartType.toLowerCase());

          const datasetToModify = chartData.datasets.find(
            ({ label }) => dataset.label === label
          );

          const newDataSet = { ...datasetToModify, type };
          let newDatasets = [];

          if (roundCharts || barCharts) {
            newDatasets = chartData.datasets.map((el) =>
              el.label === newDataSet.label ? newDataSet : { ...el, type }
            );
          } else {
            newDatasets = chartData.datasets.map((el) =>
              el.label === newDataSet.label ? newDataSet : el
            );
          }

          updateCardInLayout({
            cardKey,
            chartData: {
              ...chartData,
              datasets: newDatasets,
              labels: dataset.labels,
            },
          });
        } else {
          let existingTypes = loadLivePreference("liveChartTypes");
          existingTypes = {
            ...(existingTypes || {}),
            [title]: type,
          };

          saveLocalPreferences({ liveChartTypes: existingTypes });
          setChartType(type);
        }
      },
      [chartType]
    );

    const reportCardOptions = useMemo(() => {
      if (cardRemovable) {
        return getReportCardOptionsWithRemove({
          chartType,
          updateChartType,
          onRemove,
          onHide,
          cardKey,
          isDarkMode,
          datasets: chartData.datasets,
          filter: rest?.filter,
          clearFilter: rest?.clearFilter,
        });
      } else {
        return getReportCardOptions({ chartType, updateChartType });
      }
    }, [chartType, isDarkMode, rest?.filter, rest?.clearFilter]);

    const getActualContainerWidth = useCallback(() => {
      const cardBody = cardBodyRef?.current;
      const totalsContainer = totalsContainerRef?.current;

      if (cardBody && totalsContainer) {
        const totalsHeight = totalsContainer?.clientHeight;
        const containerWidth = cardBody?.clientWidth;
        const containerHeight = cardBody?.clientHeight;

        const chartAvailableHeight = containerHeight - totalsHeight;

        const maxWidth = containerHeight * 1.6923564356;

        if (containerWidth > 2 * chartAvailableHeight) {
          return maxWidth - 40;
        } else {
          return containerWidth;
        }
      }

      return undefined;
    }, [chartType, rest, cardBodyRef?.current, totalsContainerRef?.current]);

    const chart = useMemo(() => {
      return LayoutChart[chartType]({ chartData });
    }, [chartType, JSON.stringify(chartData)]);

    function calcTotal(average = false) {
      let data = chartData?.datasets?.[0]?.data || [];
      if (!data?.length) {
        return 0;
      }

      let unit = undefined;
      let total = data?.reduce((acc, val) => acc + val, 0);

      if (title?.toLowerCase().includes("hours")) {
        unit = { unit: "hour" };
      } else if (title?.toLowerCase().includes("in miles")) {
        unit = { unit: "mile" };
      } else if (title.toLowerCase().includes("amount")) {
        unit = { currency: "USD" };
      }

      if (average) {
        return formatNumber(total / data?.length, unit);
      }

      return formatNumber(total, unit);
    }

    return (
      <div
        {...{
          ref,
          style,
          onMouseUp,
          onTouchEnd,
          onMouseDown,
          className: `live-layout-card ${className}${
            isDarkMode ? " layout-card-dark" : ""
          }`,
        }}
        key={cardKey}
      >
        <div className="card-header">
          <span className="header-title">{title}</span>
          {headerComponents?.length ? (
            <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
              {headerComponents}
            </div>
          ) : null}

          <div className="header-actions">
            {/* {rest?.clearFilter ? (
              <Tooltip title="Clear Chart filters">
                <MondayButton
                  className="mondayButtonRed"
                  Icon={<ClearFilterIcon />}
                  onClick={rest?.clearFilter}
                />
              </Tooltip>
            ) : null} */}
            <Dropdown
              overlayClassName={`layout-card-dropdown${
                isDarkMode ? " layout-dropdown-dark" : ""
              }`}
              trigger={["hover", "click"]}
              menu={{ items: reportCardOptions }}
              placement="bottomRight"
            >
              <EllipsisOutlined
                height={18}
                width={18}
                color={!isDarkMode ? "#fff" : "#000"}
                style={{
                  transform: "rotate(90deg)",
                  cursor: "pointer",
                }}
              />
            </Dropdown>
          </div>
        </div>
        <div className="card-body" id={`${title}-card-body`} ref={cardBodyRef}>
          <div
            className="total-container"
            id={`${title}-total-container`}
            ref={totalsContainerRef}
          >
            <div className="inner-total">
              <span className="inner-total-label">Total</span>
              <span className="inner-total-value">{calcTotal()}</span>
            </div>
            <div className="inner-total inner-total-right">
              <span className="inner-total-label">Average</span>
              <span className="inner-total-value">{calcTotal(true)}</span>
            </div>
          </div>
          <div
            className={`chart-container ${
              roundCharts.includes(chart?.props?.type)
                ? "pie-chart-container"
                : ""
            }`}
            style={{
              width: getActualContainerWidth(),
            }}
          >
            {loading ? (
              <SmallLoadableComp loading={loading} darkMode={isDarkMode} />
            ) : (
              chart
            )}
          </div>
        </div>
        {children}
      </div>
    );
  }
);

export default LayoutCard;
