import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { checkDateField, checkDateInput } from ".";

/**
 *
 * @param {Object} requestForm - The request form object
 * @param {Array} inputFields - The input fields array
 * @returns  {Object} - The object to set in the form with the corresponding formItemName as key and the correct parsed value
 */
const populateRequestForm = ({ requestForm = {}, inputFields = [] }) => {
  const objToSet = {};

  if (!inputFields.length) {
    return {};
  }

  const currFields = inputFields?.map((el) => el?.formItemName);

  Object.keys(requestForm).forEach((key) => {
    if (currFields?.includes(key)) {
      if (!requestForm[key]) {
        Object.assign(objToSet, { [key]: null });
      } else if (checkDateField(key, inputFields)) {
        Object.assign(objToSet, { [key]: dayjsNY(requestForm[key]) });
      } else if (checkDateInput(key, inputFields)) {
        Object.assign(objToSet, {
          [key]: dayjsNY(requestForm[key]).format("MM/DD/YYYY"),
          [`datePicker${key}`]: dayjsNY(requestForm[key]),
        });
      } else if (key?.toLowerCase()?.includes("projectmanager")) {
        if (!requestForm[key]) {
          Object.assign(objToSet, { [key]: null });
        } else if (Array.isArray(requestForm[key])) {
          Object.assign(objToSet, { [key]: requestForm[key] });
        } else {
          Object.assign(objToSet, { [key]: [requestForm[key]] });
        }
      } else {
        Object.assign(objToSet, { [key]: requestForm[key] });
      }
    }
  });

  Object.keys(objToSet).forEach((key) => {
    if (!objToSet[key]) {
      delete objToSet[key];
    }

    if (objToSet[key] === undefined) {
      delete objToSet[key];
    }
  });

  return objToSet;
};

export default populateRequestForm;
