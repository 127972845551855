import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { appliedRentPliCalculator } from "../../../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalFooter/RentalBreakdownFooterFunctions";
import {
  getPriceChargedCredited,
  getServiceLegacyPriceWithoutTax,
  getServiceLegacyTaxAmount,
} from "../../../utils/index";

export const calculatePriceDetails = (
  oldPrice,
  isPricePercent,
  serviceItem
) => {
  const isChargedCredited = getPriceChargedCredited(serviceItem?.charges) || 0;
  const maxAllowedPrice = oldPrice - isChargedCredited;
  const maxPercentage = (maxAllowedPrice / oldPrice) * 100;
  const isPercentageMax = maxPercentage <= serviceItem.pricePercent;
  const isMaxAllowedPrice = forceToNumber(serviceItem.price) > maxAllowedPrice;

  const currentPrice = isPricePercent
    ? isPercentageMax
      ? maxAllowedPrice
      : (forceToNumber(serviceItem.pricePercent) / 100) * oldPrice
    : isMaxAllowedPrice
    ? maxAllowedPrice
    : forceToNumber(serviceItem.price);

  const taxAmount = serviceItem?.isTaxable
    ? currentPrice * (serviceItem?.taxRate || 0)
    : 0;

  return {
    ...serviceItem,
    price: currentPrice,
    priceAmount: oldPrice,
    totalPrice: currentPrice + taxAmount,
    taxRate: serviceItem?.taxRate,
    pricePercent: isPricePercent
      ? isPercentageMax
        ? maxPercentage
        : forceToNumber(serviceItem.pricePercent)
      : isMaxAllowedPrice
      ? forceToNumber(maxAllowedPrice / serviceItem.priceAmount) * 100
      : forceToNumber(serviceItem.price / serviceItem.priceAmount) * 100,
    taxAmount,
    selected: currentPrice > 0 ? true : false,
  };
};

export const validateCellChangeAndSetCharge = (
  charge,
  initialService,
  isPricePercent,
  setChargeToSave,
  type
) => {
  const chargeItems = structuredClone(charge);

  switch (type) {
    case "services":
      chargeItems.serviceOptions = charge.serviceOptions.map(
        (serviceOption, serviceOptionIndex) =>
          serviceOption.map((service, serviceIndex) => ({
            ...service,
            items: service.items.map((serviceItem, serviceItemIndex) => {
              const item =
                initialService.serviceOptions[serviceOptionIndex][serviceIndex]
                  .items[serviceItemIndex];
              const oldPrice =
                forceToNumber(item.price) +
                  (forceToNumber(item.pliAddonTotal) || 0) ||
                forceToNumber(item.Price);
              const newServiceItem =
                charge.isScope && charge.isTaxable
                  ? { ...serviceItem, taxRate: charge.taxRate }
                  : serviceItem;
              return calculatePriceDetails(
                oldPrice,
                isPricePercent,
                newServiceItem
              );
            }),
          }))
      );
      break;

    case "Elevation Addons":
      chargeItems.serviceOptions = charge.serviceOptions.map(
        (serviceOption, serviceOptionIndex) =>
          serviceOption.map((service, serviceIndex) => ({
            ...service,
            ...(service?.elevationAddons && {
              elevationAddons: service.elevationAddons.map(
                (elevationAddon, elevationAddonIndex) => {
                  const item =
                    initialService.serviceOptions[serviceOptionIndex][
                      serviceIndex
                    ].elevationAddons[elevationAddonIndex];
                  const oldPrice = forceToNumber(
                    item.includedTax.priceWithoutTax
                  );

                  return calculatePriceDetails(
                    oldPrice,
                    isPricePercent,
                    elevationAddon
                  );
                }
              ),
            }),
          }))
      );
      break;

    case "Service Addons":
      chargeItems.serviceAddons = charge.serviceAddons.map(
        (addon, addonIndex) => {
          const defaultValue =
            initialService?.serviceAddons[addonIndex]?.includedTax
              ?.priceWithoutTax ||
            initialService?.serviceAddons[addonIndex].totalPrice ||
            0;
          return calculatePriceDetails(defaultValue, isPricePercent, addon);
        }
      );
      break;

    case "Additional Rental":
      chargeItems.additionalRentalTerms =
        charge?.additionalRentalTerms &&
        Object.keys(charge?.additionalRentalTerms)
          ? (() => {
              const rentalTerms = charge?.additionalRentalTerms;
              const priceWithoutTax =
                rentalTerms.newPrice / (1 + rentalTerms?.taxRate || 1);
              return calculatePriceDetails(
                priceWithoutTax,
                isPricePercent,
                rentalTerms
              );
            })()
          : {};

      break;

    default:
      break;
  }
  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                }
              : service
          )
        : [],
    };
  });
};

export const validateCellChangeAndSetChargeRentalState = (
  charge,
  initialService,
  isPricePercent,
  setChargeToSave
) => {
  const chargeItems = {
    ...charge,
    serviceOptions: charge.serviceOptions.map((service, serviceIndex) => ({
      ...service,
      items: service.items
        .filter((filterService) => filterService.maxWeeklyAmount)
        .map((serviceItem, serviceItemIndex) => {
          const item =
            initialService.serviceOptions[serviceIndex]?.items[
              serviceItemIndex
            ];
          const oldPrice = appliedRentPliCalculator({
            rentalDetails: item?.rentalDetails?.details || [],
          });
          return {
            ...calculatePriceDetails(oldPrice, isPricePercent, serviceItem),
            completation: 100,
          };
        }),
    })),
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                }
              : service
          )
        : [],
    };
  });
};

export const validateHoistCellChangeAndSetCharge = (
  charge,
  initialService,
  isPricePercent,
  setChargeToSave,
  type
) => {
  const chargeItems = structuredClone(charge);

  switch (type) {
    case "services":
      chargeItems.serviceOptions = charge.serviceOptions.map(
        (serviceOption, serviceOptionIndex) =>
          serviceOption.map((serviceItem, serviceIndex) => {
            const item =
              initialService.serviceOptions[serviceOptionIndex][serviceIndex];
            const oldPrice =
              forceToNumber(item.price) +
                (forceToNumber(item.pliAddonTotal) || 0) ||
              forceToNumber(item.Price);
            return calculatePriceDetails(oldPrice, isPricePercent, serviceItem);
          })
      );
      break;

    case "Service Addons":
      chargeItems.serviceAddons = charge.serviceAddons.map(
        (addon, addonIndex) => {
          const defaultValue =
            initialService?.serviceAddons[addonIndex]?.includedTax
              ?.priceWithoutTax ||
            initialService?.serviceAddons[addonIndex].totalPrice ||
            0;
          return calculatePriceDetails(defaultValue, isPricePercent, addon);
        }
      );
      break;

    case "Additional Rental":
      chargeItems.additionalRentalTerms =
        charge?.additionalRentalTerms &&
        Object.keys(charge?.additionalRentalTerms)
          ? (() => {
              const rentalTerms = charge?.additionalRentalTerms;
              const priceWithoutTax =
                rentalTerms.newPrice / (1 + rentalTerms?.taxRate || 1);
              return calculatePriceDetails(
                priceWithoutTax,
                isPricePercent,
                rentalTerms
              );
            })()
          : {};

      break;

    default:
      break;
  }
  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                }
              : service
          )
        : [],
    };
  });
};

export const validateCellChangeAndSetChargeHoistRentalState = (
  charge,
  initialService,
  isPricePercent,
  setChargeToSave
) => {
  const chargeItems = {
    ...charge,
    serviceOptions: charge.serviceOptions.map((service, serviceIndex) => ({
      ...service,
      items: service.items.map((serviceItem, serviceItemIndex) => {
        const item =
          initialService.serviceOptions[serviceIndex].items[serviceItemIndex];
        const oldPrice = forceToNumber(item.appliedAmount);
        return {
          ...calculatePriceDetails(oldPrice, isPricePercent, serviceItem),
          completation: 100,
        };
      }),
    })),
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                }
              : service
          )
        : [],
    };
  });
};

export const validateCellChangeAndSetChargeHoistSOVState = (
  charge,
  initialService,
  isPricePercent,
  setChargeToSave
) => {
  const chargeItems = {
    ...charge,
    serviceOptions: charge.serviceOptions.map(
      (serviceOption, serviceOptionIndex) =>
        serviceOption.map((service, serviceIndex) => ({
          ...service,
          amounts: service.amounts.map((serviceItem, serviceItemIndex) => {
            const item =
              initialService.serviceOptions[serviceOptionIndex][serviceIndex]
                .amounts[serviceItemIndex];
            const priceWithoutTax =
              item.amount / (1 + item.custom_tax_amount || 0);
            const oldPrice = forceToNumber(priceWithoutTax);
            const newServiceItem = {
              ...serviceItem,
              taxRate: item.custom_tax_amount || 0,
            };

            return calculatePriceDetails(
              oldPrice,
              isPricePercent,
              newServiceItem
            );
          }),
        }))
    ),
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(
      chargeItems,
      "Schedule Of Value"
    );

    const newTaxAmount = getServiceLegacyTaxAmount(
      chargeItems,
      "Schedule Of Value"
    );
    const totalPrice = chargeItems.serviceOptions.reduce(
      (accOption, serviceOption) =>
        accOption +
        serviceOption.reduce(
          (accService, service) =>
            accService +
            service.amounts.reduce(
              (accAmount, item) => accAmount + (item?.amount || 0),
              0
            ),
          0
        ),
      0
    );

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label &&
            service.estimationId === chargeItems.estimationId
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount,
                  totalPrice,
                }
              : service
          )
        : [],
    };
  });
};
export const validateCellChangeAndSetChargeSOVState = (
  charge,
  initialService,
  isPricePercent,
  setChargeToSave
) => {
  const chargeItems = {
    ...charge,
    amounts: charge.amounts.map((serviceItem, serviceItemIndex) => {
      const item = initialService.amounts[serviceItemIndex];
      const priceWithoutTax = item.amount / (1 + item.custom_tax_amount || 0);
      const oldPrice = forceToNumber(priceWithoutTax);
      const newServiceItem = {
        ...serviceItem,
        taxRate: item.custom_tax_amount || 0,
      };

      return calculatePriceDetails(oldPrice, isPricePercent, newServiceItem);
    }),
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(
      chargeItems,
      "Schedule Of Value"
    );
    const newTaxAmount = getServiceLegacyTaxAmount(
      chargeItems,
      "Schedule Of Value"
    );
    const totalPrice = chargeItems.amounts.reduce(
      (accAmount, item) => accAmount + (item?.amount || 0),
      0
    );

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label &&
            service.estimationId === chargeItems.estimationId
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount,
                  totalPrice,
                }
              : service
          )
        : [],
    };
  });
};
