import {
  BarChartIcon,
  CircleProgressBarIcon,
  LongLineStepperIcon,
  PieChartIcon,
  StatusProgressIcon,
  StraightProgressBarIcon,
} from "../../../../../../assets";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";

const DynamicChartIcon = (title) => {
  switch (title) {
    case "Pie Chart":
      return <PieChartIcon />;
    case "Bar Chart":
      return <BarChartIcon />;
    case "Straight Progress Bar":
      return <StraightProgressBarIcon />;
    case "Circle Progress Bar":
      return <CircleProgressBarIcon />;
    case "Long Line Steper":
      return <LongLineStepperIcon />;
    case "Status Progress":
      return <StatusProgressIcon />;
    default:
      return <TickIcon />;
  }
};

export default DynamicChartIcon;
