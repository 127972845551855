import React from "react"
import { Select, Form } from "antd"

import { dateFilterOptionsKeys } from "../ControlPanel/datas"
import { compareIncluding } from "../../utils"
import { leftArrow, rightArrow } from "../../assets"

import "./DateSelectFilter.scss"

const { Option } = Select

const DateFilterArrow = ({ current, icon, onArrowClick }) => (
  <div className="dateFilterArrow" onClick={() => onArrowClick(current)}>
    <img src={icon} alt={icon} />
  </div>
)

const DateSelectFilter = ({
  label,
  current,
  defaultValue,
  formItemName,
  form,
  onDateSelect,
  onArrowClick,
}) => (
  <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: "20px",
      }}
    >
      <div className="labeledInputLabel">{label}</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DateFilterArrow
          current={current < 1 ? dateFilterOptionsKeys.length - 1 : current - 1}
          icon={leftArrow}
          onArrowClick={onArrowClick}
        />

        <Form.Item name={formItemName} className="dateFilterSelect">
          <Select
            defaultValue={defaultValue}
            onChange={() =>
              onDateSelect(
                dateFilterOptionsKeys
                  .map((item, i) =>
                    compareIncluding(
                      item,
                      form.getFieldValue("dateFilterSelect")
                    )
                      ? i
                      : null
                  )
                  .filter((item) => !!item)[0]
              )
            }
          >
            {dateFilterOptionsKeys.map((item, i) => (
              <Option key={i} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <DateFilterArrow
          current={current < dateFilterOptionsKeys.length - 1 ? current + 1 : 0}
          icon={rightArrow}
          onArrowClick={onArrowClick}
        />
      </div>
    </div>
  </>
)

export default DateSelectFilter
