import { Modal } from "antd";
import { useRef } from "react";
import { useSelector } from "react-redux";

import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import JobsiteRatesGrid from "../../Jobsite/components/JobsiteRatesGrid/JobsiteRatesGrid";

import "./JobsiteRatesModal.scss";

function JobsiteRatesModal({ open, onCancel = () => {}, jobsite }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const jobsiteGridRef = useRef();
  console.log("jobsite: ", jobsite);
  return (
    <Modal
      {...{
        open,
        onCancel,
        className: `jobsiteRatesModal ${isDarkMode && "jobsiteRatesModalDark"}`,
        centered: true,
        footer: null,
        closeIcon: <XIcon />,
        title: `${jobsite?.jobName} (${jobsite?.jobAddress})`,
      }}
    >
      <JobsiteRatesGrid
        ref={jobsiteGridRef}
        {...{
          ratesForRole: jobsite?.rates,
          editable: false,
          isDarkMode,
        }}
      />
    </Modal>
  );
}

export default JobsiteRatesModal;
