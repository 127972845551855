import { Auth } from "aws-amplify";

//Fetches the current logged in user~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getLoggedUser = async () => {
  let user;
  await Auth.currentAuthenticatedUser()
    .then(
      ({ attributes }) =>
        (user = `${attributes.given_name} ${attributes.family_name}`)
    )
    .catch((e) => console.error(e));
  return user;
};
