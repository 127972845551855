import {
  getFormattedIncidentBody,
  getIncidentBooleansValues,
  getIncidentUploads,
  getInjuredPeopleDetails,
} from "../../../../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/incidentModalData";

function vehicleDamageWordData({ rowObject, dynamicDetails, uploads }) {
  const { incidentObject, ...rest } = rowObject;
  const incident = { ...rest, ...incidentObject };

  const mainBody = getFormattedIncidentBody(incident).map(
    ({ label, value }) => ({ key: label, value: value || "" })
  );

  const booleans = getIncidentBooleansValues(incident).map(
    ({ label, value }) => ({ key: label, value: value || "" })
  );

  const signature = {
    key: "Signature",
    value: incident?.incidentSignature
      ? "data:image/png;base64," + incident?.incidentSignature
      : "N/A",
  };

  return [
    {
      title: "General Information",
      body: [...mainBody, ...booleans, signature],
    },
    ...getAuthoritiesShowedUp(incident),
    ...getInjuredPeopleDetails(incident, dynamicDetails, true),
    ...getIncidentUploads(uploads, true),
  ];
}

export default vehicleDamageWordData;

function getAuthoritiesShowedUp(incident) {
  let rowFields = incident?.incidentReport?.find(
    ({ category }) => category === "Details"
  );
  if (!rowFields) return [];
  return [
    {
      title: "Authorities Showed Up",
      body: rowFields?.values?.map(({ label, value }) => ({
        key: label,
        value,
      })),
    },
  ];
}
