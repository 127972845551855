import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import AuthWrapper from "../AuthWrapper/AuthWrapper";
import ConfirmCode from "./Components/ConfirmCode/ConfirmCode";
import { LoginFormData } from "./Components/LoginFormData/LoginFormData";

import "./Login.scss";

const Login = () => {
  const [retryTime, setRetryTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);

  const { isLoading, codeSent, signIn, confirmSignIn } = useAuth();

  function startTimer(retryTime) {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = retryTime - now;
      if (timeDiff <= 0) {
        clearInterval(interval);
        localStorage.removeItem("retryTime");
        setTimeLeft(null);
        setRetryTime(null);
      } else {
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = String(
          Math.floor((timeDiff % (1000 * 60)) / 1000)
        ).padStart(2, "0");
        setTimeLeft(`${minutes}:${seconds}`);
      }
    }, 1000);
  }

  useEffect(() => {
    const storedRetryTime =
      JSON.parse(localStorage.getItem("retryTime")) || null;
    if (storedRetryTime) {
      const currentTime = new Date();
      if (storedRetryTime > currentTime) {
        setRetryTime(storedRetryTime);
        startTimer(storedRetryTime);
      }
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
    }
  };

  const onFinish = (values) => {
    const username = values.username;
    const password = values.password;
    signIn(username, password);
  };

  return (
    <AuthWrapper>
      <h1 className="form-comp-header">
        {retryTime ? "Password attempts exceeded" : "Login to your account"}
      </h1>
      {!codeSent ? (
        <LoginFormData
          onFinish={(e) => onFinish(e)}
          handleKeyPress={handleKeyPress}
          isLoading={isLoading}
          timeLeft={timeLeft}
        />
      ) : (
        <ConfirmCode
          onFinish={confirmSignIn}
          handleKeyPress={handleKeyPress}
          isLoading={isLoading}
        />
      )}
    </AuthWrapper>
  );
};

export default Login;
