const streetAbbreviations = {
  aly: "Alley",
  axn: "Annex",
  arc: "Arcade",
  ave: "Avenue",
  yu: "Bayou",
  bch: "Beach",
  bnd: "Bend",
  blf: "Bluff",
  btm: "Bottom",
  blvd: "Boulevard",
  blv: "Boulevard",
  br: "Branch",
  brg: "Bridge",
  brk: "Brook",
  bg: "Burg",
  byp: "Bypass",
  cp: "Camp",
  cyn: "Canyon",
  cpe: "Cape",
  cswy: "Causeway",
  ctr: "Center",
  cir: "Circle",
  clfs: "Cliffs",
  clb: "Club",
  cor: "Corner",
  cors: "Corners",
  crse: "Course",
  ct: "Court",
  cres: "Crescent",
  crk: "Creek",
  cts: "Courts",
  cv: "Cove",
  cy: "Key",
  dl: "Dale",
  dm: "Dam",
  dr: "Drive",
  dv: "Divide",
  est: "Estates",
  expy: "Expressway",
  ext: "Extension",
  fall: "Fall",
  fgr: "Forge",
  fld: "Field",
  flds: "Fields",
  fls: "Falls",
  flt: "Flats",
  for: "Ford",
  fork: "Fork",
  frks: "Forks",
  frst: "Forest",
  fry: "Ferry",
  ft: "Fort",
  fwy: "Freeway",
  gdns: "Gardens",
  gln: "Glen",
  gn: "Green",
  grv: "Grove",
  gtwy: "Gateway",
  hbr: "Harbor",
  hl: "Hill",
  hls: "Hills",
  holw: "Hollow",
  hts: "Heights",
  hvn: "Haven",
  hwy: "Highway",
  inlt: "Inlet",
  is: "Island",
  isle: "Isle",
  iss: "Islands",
  jct: "Junction",
  knls: "Knolls",
  lcks: "Locks",
  ldg: "Lodge",
  lf: "Loaf",
  lgt: "Light",
  lk: "Lake",
  lks: "Lakes",
  ln: "Lane",
  lndg: "Landing",
  loop: "Loop",
  mall: "Mall",
  mdws: "Meadows",
  ml: "Mill",
  mls: "Mills",
  mnr: "Manor",
  msn: "Mission",
  mt: "Mount",
  mtn: "Mountain",
  nck: "Neck",
  orch: "Orchard",
  oval: "Oval",
  park: "Park",
  pass: "Pass",
  path: "Path",
  pike: "Pike",
  pky: "Parkway",
  pl: "Place",
  pln: "Plain",
  plns: "Plains",
  plz: "Plaza",
  pnes: "Pines",
  pr: "Prairie",
  prt: "Port",
  pt: "Point",
  radl: "Radial",
  rd: "Road",
  rdg: "Ridge",
  riv: "River",
  rnch: "Ranch",
  row: "Row",
  rpds: "Rapids",
  rst: "Rest",
  run: "Run",
  shl: "Shoal",
  shls: "Shoals",
  shr: "Shore",
  shrs: "Shores",
  smt: "Summit",
  spg: "Spring",
  spgs: "Springs",
  spur: "Spur",
  sq: "Square",
  st: "Street",
  sta: "Station",
  stra: "Stravenues",
  strm: "Stream",
  ter: "Terrace",
  tpke: "Turnpike",
  trak: "Track",
  trce: "Trace",
  trl: "Trail",
  trlr: "Trailer",
  tunl: "Tunnel",
  un: "Union",
  via: "Viaduct",
  vis: "Vista",
  vl: "Ville",
  vlg: "Village",
  vly: "Valley",
  vw: "View",
  walk: "Walk",
  way: "Way",
  wls: "Wells",
  xing: "Crossing",
};

export const ABBREVIATIONS_RG = new RegExp(
  Object.keys(streetAbbreviations)
    .map((e) => ` ${e}[,\\s]`)
    .join("|"),
  "i"
);

export default streetAbbreviations;
