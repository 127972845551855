export const noteExtractor = ({ fakeApplication, serviceId, elevationId, eventId }) =>
  fakeApplication?.services
    ?.find((serviceData) => serviceData?.serviceId === serviceId)
    ?.serviceOptions[0]?.find((elevationData) => elevationData?.elevationId === elevationId)
    ?.amounts?.find((eventData) => eventData?.id === eventId)?.note

export const onConfirmHandler = ({
  dispatchContext,
  fakeApplication,
  initialValue,
  estimationId,
  elevationId,
  serviceId,
  newValue,
  eventId,
}) => {
  const newApplication = {
    ...fakeApplication,
    services: fakeApplication?.services?.map((serviceData) =>
      serviceData?.serviceId === serviceId && serviceData?.estimationId === estimationId
        ? {
            ...serviceData,
            serviceOptions: [
              serviceData?.serviceOptions[0]?.map((elevationData) =>
                elevationData?.elevationId === elevationId
                  ? {
                      ...elevationData,
                      amounts: elevationData?.amounts?.map((eventData) =>
                        eventData?.id === eventId
                          ? { ...eventData, note: newValue || initialValue }
                          : eventData
                      ),
                    }
                  : elevationData
              ),
            ],
          }
        : serviceData
    ),
  }

  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication })
}
