import { ReactNode } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "./WarningInfoCard.scss";

type WarningInfoCardProps = {
  title?: ReactNode;
  description?: ReactNode;
};

function WarningInfoCard({
  title = "Warning",
  description = "",
}: WarningInfoCardProps) {
  return (
    <div className="small-warning-info-card">
      <div className="warning-title-container">
        {/**@ts-ignore */}
        <ExclamationCircleOutlined
          style={{
            color: "#eb4345",
          }}
        />
        <div className="warning-title">{title}</div>
      </div>
      <div className="warning-description">{description}</div>
    </div>
  );
}

export default WarningInfoCard;
