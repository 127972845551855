import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const inspections = (IdHandler, updatedDynamicStates) => ({
  inspectionType: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "inspectionType"
    );

    return {
      title: "Service Inspection Type",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionType",
        title: "Service Inspection Type",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },

      id: IdHandler,
      width: "390px",
    };
  },

  inspectionStatus: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "inspectionStatus"
    );

    return {
      title: "Service Inspection Status",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionStatus",
        title: "Service Inspection Status",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },

      id: IdHandler,
      width: "390px",
    };
  },

  inspectedBy: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler]?.map((el) => el?.inspectedBy) || [],
      "nameOfUser"
    );

    return {
      title: "Service Inspected By",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectedBy",
        title: "Service Inspected By",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },

      id: IdHandler,
      width: "390px",
    };
  },
});
