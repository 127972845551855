import { Popover } from "antd";

const DescriptionPreview = ({ value }) => {
  return (
    <Popover
      content={value}
      trigger="hover"
      overlayStyle={{ maxWidth: "400px" }}
    >
      {value?.length > 25 ? value?.substring(1, 30) : value}
    </Popover>
  );
};

export default DescriptionPreview;
