import { getChangedData } from "../../../../../../Accounting/components/utilities";

export const updateEditLogs = ({
  currentCharge,
  saveAddedLogs,
  newChargeObject,
  isEditing = false,
}) => {
  let newLogObj = {
    recordId: currentCharge?.chargeId,
    recordName: `Charge ${currentCharge?.chargeNumber}`,
    category: "Credit",
    actionType: isEditing ? "Edit" : "Create",
    topic: `${currentCharge.projectAddress} - ${currentCharge?.categoryFrom} ${currentCharge?.categoryNumber}`,
    currentData: {},
    previousData: {},
    updatedKeys: [],
  };

  if (isEditing) {
    for (let key in newChargeObject) {
      let result;
      if (!!newChargeObject[key] && currentCharge[key] && key !== "editLogs") {
        result = getChangedData(newChargeObject[key], currentCharge[key]);
      } else {
        continue;
      }
      if (result !== false) {
        newLogObj.currentData[key] = result.curr;
        newLogObj.previousData[key] = result.prev;
        newLogObj.updatedKeys.push(key);
      }
    }
  }

  saveAddedLogs(newLogObj);
};
