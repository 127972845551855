import { getRandomColor } from "../../../../utils";

export const inspections = ({
  tables = {
    Inspections: [],
  },
  programFields,
}) => {
  const statuses = programFields
    ?.find((el) => el?.fieldName === "Statuses Of Categories")
    ?.fieldOptions?.Inspection?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.statusName]: curr?.statusColor,
      }),
      {}
    );

  return {
    inspectionType: () => ({
      statusData: tables?.Inspections?.map((a) => ({
        label: a?.inspectionType,
        value: a?.projectName,
        color: statuses?.[a?.inspectionType] || getRandomColor(),
        id: a?.inspectionId,
      })),
    }),

    inspectionStatus: () => ({
      statusData: tables?.Inspections?.map((a) => ({
        label: a?.inspectionStatus,
        value: a?.projectName,
        color: statuses?.[a?.inspectionStatus] || getRandomColor(),
        id: a?.inspectionId,
      })),
    }),

    inspectedBy: () => ({
      statusData: tables?.Inspections?.map((a) => ({
        label: a?.inspectedBy,
        value: a?.projectName,
        color: statuses?.[a?.inspectionStatus] || getRandomColor(),
        id: a?.inspectionId,
      })),
    }),
  };
};
