async function getFilesWarnings(files, folderId, getParents) {
  const warnings = await Promise.all(
    files.map(async (file) => {
      const res = await getParents(file.id);
      const parentId = await res.json();
      const parentIdOfFile = parentId?.parents;
      let status;
      if (!parentIdOfFile) {
        status = "Not in Drive";
      } else if (parentIdOfFile?.includes(folderId)) {
        status = "Ok";
      } else {
        status = "Wrong Folder";
      }
      return (
        status !== "Ok" && {
          ...file,
          status,
          ...(status === "Wrong Folder" ? { parent: parentIdOfFile?.[0] } : {}),
        }
      );
    })
  );
  return warnings.filter(Boolean);
}

export default getFilesWarnings;
