import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import {
  DeleteElevationIcon,
  DeleteIconModal,
} from "../../../../../../../assets";
import { currencyFormater } from "../../../../../../../utils/currencyFormater";
import { MondayButton } from "../../../../../../commonComponents";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { DeleteIcon } from "../../../../../DynamicView/src";
import textEditor from "./gridComponents/textEditor";
import {
  getPriceScheme,
  mappingOfKeysHoistTable,
  TABLE_HOIST_REFERENCE_DATA,
} from "./referenceData";
const cellEditorSelector = (params) => {
  console.log("cellEditorSelector", params);
  return {
    component: "agSelectCellEditor",
    params: {
      values: [
        ...getValues(
          params?.data?.name,
          params?.context?.hoistData?.type === "dual"
        ),
        "Custom",
      ],
      cellHeight: 55,
    },
  };
};

export const TABLE_DATA_HOIST_CALCULATOR = [
  {
    id: 1,
    name: "Hoist Base Price",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 2,
    name: "Cabin Size (L)",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 3,
    name: "Cabin Size (H)",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 4,
    name: "Speed",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 5,
    name: "Lift Height",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 6,
    name: "Location",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 7,
    name: "Jumps",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 70,
    name: "Stops",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 8,
    name: "Rental for Hoist",
    units: 6,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 80,
    name: "Additional Rental for Hoist",
    units: 6,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },

  // {
  //   id: 9,
  //   name: "Runbacks",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 10,
  //   name: "Shoring under Runback",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 11,
  //   name: "Shoring",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 12,
  //   name: "Loading Dock",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  {
    id: 13,
    name: "Winterization",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 14,
    name: "Inserts",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  // {
  //   id: 15,
  //   name: "Needle Beam Ramp",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 16,
  //   name: "Ramp on Roof",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  {
    id: 17,
    name: "MTA Approval",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 19,
    name: "Call Button System",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 20,
    name: "Other Charge #1",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 23,
    name: "Other Charge #2",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 24,
    name: "Other Charge #3",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 21,
    name: "Credit",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 22,
    name: "Discount",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 25,
    name: "Discount with Rental",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
];

export const TABLE_DATA_COLUMN_HOIST_CALCULATOR = [
  {
    headerName: "Entity Name",
    field: "name",
    key: "name",
  },
  {
    headerName: "ID",
    field: "id",
    key: "id",
    // width: 200,
    hide: true,
  },
  {
    headerName: "Entity Value",
    field: "entityValue",
    key: "Entity Value",
    cellStyle: (params) => {
      return { color: "#94FFD8" };
    },

    // width: 400,
    editable: true,
    cellEditorSelector: cellEditorSelector,
    // valueGetter: (params) => {},
    // cellEditorParams: {
    //   values: getValueFromServer(),
    //   allowTyping: true,
    //   filterList: true,
    //   highlightMatch: true,
    //   valueListMaxHeight: 220,
    // },
  },
  {
    headerName: "Units",
    field: "units",
    // width: 100,
    key: "units",
    valueGetter: (params) => {
      return unitsValueGetter(params);
    },
  },
  {
    headerName: "Unit Category",
    field: "unitCategory",
    // width: 220,
    hide: true,
    key: "unitCategory",
    valueGetter: (params) => {
      return params?.data?.unitCategory || "";
    },
  },
  {
    headerName: "PPU",
    field: "ppu",
    // width: 200,
    editable: true,
    key: "ppu",
    valueFormatter: ({ value }) => {
      return currencyFormater(value);
    },
    valueGetter: pricePerUnitValueGetter,
  },
  {
    headerName: "Price",
    field: "price",
    // width: 200,
    key: "price",
    valueGetter: priceValueGetter,
    valueFormatter: ({ value }) => {
      return currencyFormater(value);
    },
  },
  {
    headerName: "Free months (Rent)",
    field: "freeMonths",
    // width: 220,
    key: "freeMonths",
    valueFormatter: ({ value = "" }) => {
      if (value === 0) return "No";
    },
    // valueGetter: ({ data }) => {
    //   return forceToNumber(data?.freeMonths);
    // },
  },
  // {
  //   headerName: "Rent After",
  //   field: "rentAfter",
  //   width: 200,
  //   key: "rentAfter",
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.rentAfter);
  //   },
  //   valueFormatter: ({ value }) => {
  //     return currencyFormater(value);
  //   },
  // },
  // {
  //   headerName: "Tax Rate (%)",
  //   field: "taxRate",
  //   width: 200,
  //   hide: true,
  //   key: "taxRate",
  //   editable: true,
  //   valueFormatter: ({ value = 0 }) => {
  //     return currencyFormater(value);
  //   },
  //   valueGetter: (params) => {
  //     console.log("paramsContext", params.context);
  //     return forceToNumber(params?.context?.hoistData?.taxRate);
  //   },
  // },
  // {
  //   headerName: "Tax",
  //   field: "tax",
  //   width: 200,
  //   key: "tax",
  //   hide: true,
  //   editable: false,
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.taxRate) * forceToNumber(data?.price) * 0.01;
  //   },
  //   valueFormatter: ({ value = 0 }) => {
  //     return currencyFormater(value);
  //   },
  // },
  {
    headerName: "Total Price",
    field: "totalPrice",
    // width: 200,
    key: "totalPrice",
    valueFormatter: ({ value }) => {
      return currencyFormater(value);
    },
    valueGetter: totalPriceValueGetter,
  },
  {
    headerName: "Rental [A.G]",
    field: "rentalAfter",
    // width: 200,
    key: "rentalAfter",
    valueFormatter: ({ value }) => {
      return currencyFormater(value);
    },
    valueGetter: ({ data }) => {
      data.rentalAfter = forceToNumber(data?.rentalAfter);
      return forceToNumber(data?.rentalAfter);
    },
    // valueGetter: totalPriceValueGetter,
  },
  {
    headerName: "Note",
    field: "note",
    // width: 200,
    minWidth: 430,
    key: "note",
  },
  // {
  //   headerName: "Additional Rent Months",
  //   field: "additionalRentMonths",
  //   width: 200,
  //   key: "additionalRentMonths",
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.additionalRentMonths);
  //   },
  // },
  // {
  //   headerName: "Additional Rent",
  //   field: "additionalRent",
  //   width: 200,
  //   key: "additionalRent",
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.additionalRent);
  //   },
  //   valueFormatter: ({ value }) => {
  //     return currencyFormater(value);
  //   },
  // },
];

export const getTableDataColumns = ({ removeRow, addRow }) => {
  let afterColumns = [
    {
      headerName: "Action",
      field: "action",
      // minWidth: 140,
      editable: false,
      cellRenderer: (params) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "space-between",
            }}
          >
            <MondayButton
              // Icon={EditOutline}
              onClick={() => {
                params.api.applyTransaction({ remove: [params?.data] });
                removeRow(params?.data);
                // params.api.set
              }}
              hasIcon={false}
              className="mondayButtonRed"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  marginTop: 5,
                }}
              >
                <DeleteElevationIcon />
              </div>
            </MondayButton>
            <MondayButton
              // Icon={EditOutline}
              hasIcon={false}
              className="mondayButtonGreen"
              style={{ width: "100%" }}
              onClick={() => {
                let rowToAdd = addCustomRow(params);
                params.api.applyTransaction({ add: [rowToAdd] });
                addRow(rowToAdd);
              }}
            >
              <AddIcon />
            </MondayButton>
          </div>
        );
      },
    },
  ];
  return [...afterColumns, ...TABLE_DATA_COLUMN_HOIST_CALCULATOR];
};

function getValues(nameRef, isDual) {
  console.log("getValues", nameRef);
  const { TABLE_HOIST_REFERENCE_DATA } = getPriceScheme({ isDual });
  switch (nameRef) {
    case "Hoist Base Price": {
      return TABLE_HOIST_REFERENCE_DATA.hoistType.options;
    }
    case "Cabin Size (L)": {
      return TABLE_HOIST_REFERENCE_DATA.cabinLength.options;
    }
    case "Cabin Size (H)": {
      return TABLE_HOIST_REFERENCE_DATA.cabinHeight.options;
    }
    case "Speed": {
      return TABLE_HOIST_REFERENCE_DATA.hoistSpeed.options;
    }
    case "Lift Height": {
      return TABLE_HOIST_REFERENCE_DATA.liftHeight.options;
    }
    case "Location": {
      return TABLE_HOIST_REFERENCE_DATA.location.options;
    }
    case "Jumps": {
      return TABLE_HOIST_REFERENCE_DATA.jumps.options;
    }
    case "Rental for Hoist": {
      return TABLE_HOIST_REFERENCE_DATA.rentalForHoist.options;
    }
    case "Additional Rental for Hoist": {
      return TABLE_HOIST_REFERENCE_DATA.additionalRentalForHoist.options;
    }
    case "Runbacks": {
      return TABLE_HOIST_REFERENCE_DATA.runbacks.options;
    }
    case "Shoring under Runback": {
      return TABLE_HOIST_REFERENCE_DATA.shoringUnderRunback.options;
    }
    case "Shoring": {
      return TABLE_HOIST_REFERENCE_DATA.shoring.options;
    }
    case "Loading Dock": {
      return TABLE_HOIST_REFERENCE_DATA.loadingDock.options;
    }
    case "Winterization": {
      return TABLE_HOIST_REFERENCE_DATA.winterization.options;
    }
    case "Inserts": {
      return TABLE_HOIST_REFERENCE_DATA.inserts.options;
    }
    case "Needle Beam Ramp": {
      return TABLE_HOIST_REFERENCE_DATA.needleBeamRamp.options;
    }
    case "Ramp on Roof": {
      return TABLE_HOIST_REFERENCE_DATA.rampOnRoof.options;
    }
    case "MTA Approval": {
      return TABLE_HOIST_REFERENCE_DATA.mtaApproval.options;
    }
    case "Call Button System": {
      return TABLE_HOIST_REFERENCE_DATA.callButtonSystem.options;
    }
    case "Other Charges": {
      return TABLE_HOIST_REFERENCE_DATA.otherCharges.options;
    }
    case "Credit": {
      return TABLE_HOIST_REFERENCE_DATA.credit.options;
    }
    case "Discount": {
      return TABLE_HOIST_REFERENCE_DATA.discounts.options;
    }

    default: {
      return [];
    }
  }
}

function pricePerUnitValueGetter(params) {
  console.log("pricePerUnitValueGetter", params);
  return forceToNumber(params?.data?.ppu);
  let refName = params?.data.name;
  let refValue = params?.data.entityValue;
  let ppu = 0;

  if (refValue === "Custom") return forceToNumber(params?.data?.ppu);

  let refData =
    TABLE_HOIST_REFERENCE_DATA[mappingOfKeysHoistTable[refName]]?.[refValue] ||
    {};
  if (!!refData?.ppu) {
    ppu = refData?.ppu;
  }
  params.data.ppu = ppu;
  return ppu;
}
function priceValueGetter(params) {
  let PPU = params?.data.ppu;
  let units = params?.data.units;
  let priceWithoutTax = forceToNumber(PPU) * forceToNumber(units);
  params.data.price = priceWithoutTax;
  return priceWithoutTax;
}
function totalPriceValueGetter(params) {
  let price = params?.data.price;
  let fullPrice = forceToNumber(price);
  params.data.totalPrice = fullPrice;
  return fullPrice;
}
function unitsValueGetter(params) {
  let { name = "", units = 0 } = params?.data;
  if (name === "Call Button System") {
    // access Rental for hoist which is another row
    let noOfMonths = 0;
    params?.api?.forEachNode((node) => {
      if (
        node?.data?.name === "Rental for Hoist" ||
        node?.data?.name === "Additional Rental for Hoist"
      ) {
        noOfMonths += forceToNumber(node?.data?.units);
      }
    });
    let noOfFloors = forceToNumber(
      params?.context?.hoistData?.items?.length || 0
    );
    // console.log("unitsValueGetter", params.context);
    // params.data.note =
    //   `No of Floors ${noOfFloors} * No of Months${noOfMonths}` = noOfMonths * noOfFloors;
    params.data.units = noOfMonths * noOfFloors;
    return forceToNumber(noOfMonths * noOfFloors);
  }
  return forceToNumber(units);
}

function addCustomRow(params) {
  console.log("addCustomRow", params);
  let lastIdx = params.api.getDisplayedRowCount() - 1;
  let lastRow = params.api.getDisplayedRowAtIndex(lastIdx);
  let newRow = { ...lastRow.data };
  newRow.name = "Custom";
  newRow.entityValue = "Custom";
  newRow.ppu = 0;
  newRow.price = 0;
  newRow.rent = 0;
  newRow.taxRate = 0;
  newRow.tax = 0;
  newRow.totalPrice = 0;
  newRow.note = "";
  newRow.units = 1;
  newRow.id = lastRow.data.id + 1;
  newRow.field = "customField_" + lastRow.data.id;
  return newRow;
}
