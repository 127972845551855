import { MondayButton, WarningModal } from "../../../commonComponents";
import { WarningTriangle } from "../../DynamicView/src";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../Communication/assets";
import { Modal } from "antd";
import "./NewToDo.scss";
import { useSelector } from "react-redux";
import { useEditLogs } from "../../../../hooks";
import { createToDo } from "./helpers";
import { deleteToDo } from "./helpers";

/**
 *
 * @param {Object} warningModal - the object that contains the warning message and the type of the warning  and if the modal is visible or not
 * @param {Function} setWarningModal - function to set the warning modal
 * @param {Function} setRowData - function to set the row data
 * @param {Object} userConfiguration - the user configuration object
 * @param {Object} form - the form instance object
 * @param {Function} setVisible - function to set the visibility of the modal
 * @param {String} todoId - the id of the to do
 * @param {Function} setRowObject - function to set the row object
 * @param {Object} rowObject - the object of the row
 * @param {String} recordId - the id of the record
 * @param {Array} statuses - the statuses of the to do
 * @param {Number} currentStep - the current step of the to do
 * @param {Boolean} isDarkMode - whether the dark mode is enabled or not
 * @param {Array} uploadedFiles - the uploaded files
 * @param {Array} category - the category of the to do
 * @param {Array} formattedFiles - the formatted files
 * @param {String} createFolderId - the id of the folder
 * @param {Function} onDeleteToDo - function to delete the to do
 * @param {Function} onAddTodo - function to add the to do
 * @param {Function} onEditTodo - function to edit the to do
 * @param {String} recordName - the name of the record
 * @param {Array} records - the records
 * @param {Function} setVisibleCreationProgress - function to set the visibility of the progress
 * @param {Function} updateProgressStatus - function to update the progress status
 * @param {Function} deleteDriveItem - function to delete the drive item
 * @param {Boolean} isCreate - whether we are creating a new to do or not
 * @param {Array} filesToBeDeleted - the files to be deleted
 * @param {Function} deleteDriveFolder - function to delete the drive folder
 * @param {Function} nextStepHandler - function to handle the next step
 * @param {Function} handleSavedRecord - function to handle the saved record
 * @param {Function} setChanges - function to set the changes
 * @returns
 */
const WarningSection = ({
  warningModal = {
    visible: false,
    type: "",
    message: "",
  },
  setWarningModal = () => {},
  setRowData = () => {},
  userConfiguration = {},
  form = {},
  setVisible = () => {},
  setClonedTodo = () => {},
  todoId = null,
  setRowObject = () => {},
  rowObject = null,
  recordId = "",
  statuses = [],
  currentStep = 0,
  isDarkMode = false,
  uploadedFiles = [],
  category = "",
  formattedFiles = [],
  createFolderId = "",
  onDeleteToDo = () => {},
  onAddTodo = () => {},
  onEditTodo = () => {},
  recordName = "",
  relatedTo = null,
  customLinkTo = null,
  records = [],
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
  deleteDriveItem = () => {},
  isCreate = false,
  filesToBeDeleted = [],
  deleteDriveFolder = () => {},
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  setChanges = () => {},
  geoFenceInfo = [],
  geoCoords,
  // closeModal = () => {},
}) => {
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const { notifications } = useSelector((state) => state.notifications);
  const { saveAddedLogs } = useEditLogs();

  function disableButton(type) {
    const button = document.getElementById(`${type}ToDoButton`);

    setChanges(false);
    if (button) {
      button.disabled = true;
      // button.style.backgroundColor = "grey";
    }
  }

  return (
    <Modal
      {...{
        open: warningModal?.visible,
        onCancel: () => {
          setWarningModal((prev) => {
            return {
              ...prev,
              visible: false,
            };
          });
          setRowObject();
          window.history.replaceState({}, document.title);
        },
        title: "Warning Message",
        className: `warning-modal ${isDarkMode && "warning-modal-dark"}`,
        closable: true,
        centered: true,
        footer: [
          <div className="buttons" key="warning-section-footer-buttons">
            <MondayButton
              {...{
                onClick: () => {
                  setWarningModal({
                    message: "",
                    type: "",
                    visible: false,
                  });
                  window.history.replaceState({}, document.title);
                },
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              No
            </MondayButton>
            <MondayButton
              {...{
                onClick: async () => {
                  if (warningModal?.type === "delete") {
                    disableButton("delete");
                    deleteToDo({
                      todoId,
                      setRowData,
                      setVisible,
                      userConfiguration,
                      defaultObject: rowObject,
                      setRowObject,
                      onDeleteToDo,
                      notifications,
                      deleteDriveFolder,
                      saveAddedLogs,
                    });
                  } else if (warningModal?.type === "save") {
                    disableButton("save");

                    /**
                     * Validate the form before saving
                     */
                    form
                      .validateFields()
                      ?.then(() => {
                        createToDo({
                          form,
                          filesToBeDeleted,
                          deleteDriveItem,
                          setVisible,
                          userConfiguration,
                          createFolderId,
                          setRowData,
                          todoId,
                          setRowObject,
                          recordId,
                          statuses,
                          currentStep,
                          recordName,
                          onAddTodo,
                          records,
                          relatedTo,
                          formattedFiles,
                          authenticatedUser,
                          category,
                          setVisibleCreationProgress,
                          updateProgressStatus,
                          nextStepHandler,
                          handleSavedRecord,
                          saveAddedLogs,
                          geoFenceInfo,
                          geoCoords,
                          customLinkTo,
                        });
                        setVisible(false);
                        setClonedTodo();
                      })
                      ?.catch((err) => {
                        console.error(err);
                      });

                    // validateForm(form, () => {});
                  } else if (warningModal?.type === "edit") {
                    disableButton("save");

                    await form
                      .validateFields()
                      .then(() => {
                        createToDo({
                          type: "edit",
                          filesToBeDeleted,
                          deleteDriveItem,
                          createFolderId,
                          form,
                          setVisible,
                          userConfiguration,
                          formattedFiles,
                          setRowData,
                          setRowObject,
                          recordId,
                          todoId,
                          statuses,
                          currentStep,
                          onEditTodo,
                          defaultObject: rowObject,
                          records,
                          relatedTo,
                          authenticatedUser,
                          category,
                          setVisibleCreationProgress,
                          updateProgressStatus,
                          saveAddedLogs,
                          geoCoords,
                          recordName,
                        });
                      })
                      ?.catch((err) => {
                        console.error(err);
                      });
                  } else if (warningModal?.type === "cancel" && !!rowObject) {
                    uploadedFiles
                      ?.filter(({ newDocFile }) => !!newDocFile)
                      ?.map(async ({ id }) => await deleteDriveItem(id));

                    setVisible(false);
                    setRowObject();
                  } else if (
                    warningModal?.type === "cancel" &&
                    !!!rowObject &&
                    isCreate
                  ) {
                    deleteDriveFolder();
                    setVisible(false);
                    setRowObject(null);
                  } else {
                    setVisible(false);
                    setRowObject();
                  }

                  window.history.replaceState({}, document.title);
                },
                Icon: <TickIcon width={17} height={17} />,
              }}
            >
              Yes
            </MondayButton>
          </div>,
        ],
        closeIcon: <XIcon />,
        // darkMode: isDarkMode,
      }}
    >
      <div className="warning-modal-body">
        {/* <span> */}
        <WarningTriangle />
        <p>{warningModal?.message}</p>
        {/* </span> */}
      </div>
    </Modal>
  );
};

export default WarningSection;
