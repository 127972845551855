import { useLayoutEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Modal, Form, Button, message } from "antd";
import { useSelector } from "react-redux";

import {
  getEmployeeAnalytics,
  getCostDispersion,
} from "../../DEG/components/modalComponents/utils";
import {
  mainColDefs,
  detailColDefs,
} from "../../DEG/components/modalComponents/CostDispersionModal/costDispersionData";
import { parseInTz } from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { updateDocumentTitle } from "../../../../../../utils";

import "./AnalyticsCostDistribution.scss";

function AnalyticsCostDistribution({
  open,
  onCancel,
  gridApi,
  jobsites,
  fromOverview = false,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [rowData, setRowData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [form] = Form.useForm();

  function getDistributionForDate() {
    if (gridApi) {
      let selectedDateRange = form
        .getFieldValue("dates")
        .map((e) => parseInTz(e.startOf("D")).valueOf());

      let analytics = {};
      getEmployeeAnalytics({
        degGridApi: gridApi,
        analyticsUpdate: (data) => {
          analytics = data;
        },
        externalFiltersPass({ data }) {
          if (data?.punchTime) {
            return (
              data?.punchTimeStamp >= selectedDateRange[0] &&
              data?.punchTimeStamp <= selectedDateRange[1]
            );
          }
          return false;
        },
      });

      let dispersionRows = getCostDispersion({ analytics, jobsites });
      if (!dispersionRows?.length) {
        message.warning({
          content:
            "There are no entries that match a jobsite for the selected dates",
          key: "noEntries",
        });
      }
      setRowData(dispersionRows);
    }
  }

  useLayoutEffect(() => {
    if (fromOverview) {
      let analytics = {};

      getEmployeeAnalytics({
        degGridApi: gridApi,
        analyticsUpdate: (data) => {
          analytics = data;
        },
        externalFiltersPass({ data }) {
          return data;
        },
      });

      let dispersionRows = getCostDispersion({ analytics, jobsites });
      if (!dispersionRows?.length) {
        message.warning({
          content:
            "There are no entries that match a jobsite for the selected dates",
          key: "noEntries",
        });
      }
      setRowData(dispersionRows);
    }
  }, [fromOverview]);

  return (
    <Modal
      title="Cost Distribution"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Cost Distribution" });
      }}
      open={open}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel();
      }}
      closable={true}
      closeIcon={<XIcon />}
      footer={null}
      className={`analyticsCostDistributionModal ${
        isDarkMode && "analyticsCostDistributionModalDark"
      }`}
      centered
      data-testid={"analytics-cost-distribution-modal"}
    >
      {!fromOverview && (
        <Form form={form}>
          <InputComponent
            {...{
              label: "Select a date span",
              type: "rangepicker",
              showTime: false,
              formItemName: "dates",
              dropdownClassName: isDarkMode && "darkDateDropDown",
              onChange(e) {
                if ((e || []).length) {
                  setButtonDisabled(false);
                } else {
                  setButtonDisabled(true);
                }
              },
            }}
          />
          <Button
            type="primary"
            disabled={buttonDisabled}
            onClick={getDistributionForDate}
          >
            Get values
          </Button>
        </Form>
      )}
      <div
        className={`cost-dispersion-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            columnDefs: mainColDefs(),
            detailCellRendererParams: detailColDefs(),
            masterDetail: true,
            rowSelection: "multiple",
            pagination: false,
            detailRowHeight: 500,
            gridOptions: {
              alwaysShowHorizontalScroll: true,
              alwaysShowVerticalScroll: true,
            },
            suppressRowClickSelection: true,
            animateRows: true,
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
    </Modal>
  );
}

export default AnalyticsCostDistribution;
