import { useState } from "react";
import { useSelector } from "react-redux";

import { MondayButton } from "../index";
import WarningModal from "../WarningModal/WarningModal";
import { FetchAllIcon } from "../../SidebarPages/BasePage/src";
import { ReactComponent as WarningIcon } from "../../../icons/warningTriangle.svg";
import { ReactComponent as CloseIcon } from "../../../components/SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../components/pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { useAdvancedFilterContext } from "../AdvancedFilters/context";
import { useLocation } from "react-router-dom";

const BUTTON_NAME = "Get All Data";
const WARNING_MESSAGE = "Are you sure you want to get all the data?";
const WARNING_DESCRIPTION =
  " The number of records displayed is limited by design. This operation may require several minutes to complete.";

const CANCEL_BUTTON = "No";
const SUBMIT_BUTTON = "Yes";

const DISABLE_GET_MAX_DATA = [];

function GetAllWarningModal({ fetchAllHandler, gridApi, tooltipCategory }) {
  const [visible, setVisible] = useState(false);

  const { resetFilters } = useAdvancedFilterContext();

  const location = useLocation();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const onSubmitButtonClickHandler = () => {
    const getMaxLimit = location.pathname !== "/accounting";
    fetchAllHandler(getMaxLimit, gridApi);
    resetFilters();
    setVisible(false);
  };

  return (
    <>
      <MondayButton
        Icon={<FetchAllIcon />}
        className="fetchAllButton"
        onClick={() => {
          setVisible(true);
        }}
        tooltipCategory={tooltipCategory}
        tooltipKey="getAllData"
      >
        {BUTTON_NAME}
      </MondayButton>
      <WarningModal
        visible={visible}
        setVisible={setVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
        key="cancelModal"
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <strong>{WARNING_MESSAGE}</strong>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {WARNING_DESCRIPTION}
          </p>
          <div className="buttons">
            <MondayButton
              onClick={() => setVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              {CANCEL_BUTTON}
            </MondayButton>
            <MondayButton onClick={onSubmitButtonClickHandler} Icon={<Tick />}>
              {SUBMIT_BUTTON}
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </>
  );
}

export default GetAllWarningModal;
