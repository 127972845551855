/**
 * @param {Object} param
 * @param {string} param.spreadsheetId
 * @param {string} param.spreadsheetName
 * @param {string} param.access_token
 * @return {Promise<Record<string, number>>}
 */
async function getLastRows({ spreadsheetId, spreadsheetName, access_token }) {
  if (!window.gapi) {
    return Promise.reject("Google API not yet initialized");
  }

  return gapi.client.sheets.spreadsheets.values
    .get({
      spreadsheetId,
      range: `'${spreadsheetName}'`,
      dateTimeRenderOption: "SERIAL_NUMBER",
      majorDimension: "ROWS",
      valueRenderOption: "FORMATTED_VALUE",
      access_token,
    })
    .then((r) => {
      /** @type {[string, string][]} */
      const values = r?.result?.values || [];

      return values.reduce(
        (acc, val) => ({
          ...acc,
          [val[0]]: +val[1],
        }),
        {}
      );
    });
}

export default getLastRows;
