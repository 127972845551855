import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

const dateFormat = "MM/DD/YYYY";
const timeFormat = "hh:mm";

const styleDiv = {
  display: "flex",
  flexDirection: "column",
  gap: 20,
  color: "#323338",
};

const boldStyle = {
  fontWeight: 600,
};

const formatCurrentDateTime = (logTime) => {
  return (
    <>
      on{" "}
      <span
        style={{
          fontWeight: 600,
        }}
      >
        {dayjs(logTime).format(dateFormat)}
      </span>{" "}
      at{" "}
      <span
        style={{
          fontWeight: 600,
        }}
      >
        {dayjs(logTime).format(timeFormat)}
      </span>
    </>
  );
};
const sessionNotificationDescription = (body, allUsersObject) => {
  const { type, activeSessionsCounter, notificationObject } = body;
  const { nameOfUser, sessionInfo, cognitoUserId, sessionStatus } =
    notificationObject || {};
  const { ip, osName, browserName, logTime, identityId } = sessionInfo || {};
  const name = nameOfUser || allUsersObject?.[identityId]?.nameOfUser || "";

  const getMessage = (status, color) => (
    <div style={styleDiv}>
      <span>
        {name} is now <span style={{ ...boldStyle, color }}>{status}</span>{" "}
        {formatCurrentDateTime(logTime)} from IP Address{" "}
        <span style={boldStyle}>{ip}</span>, using{" "}
        <span style={boldStyle}>
          {osName} - {browserName}
        </span>
        .
      </span>
      <span>
        {activeSessionsCounter} active sessions
        {status === "offline" ? " left" : ""}.
      </span>
    </div>
  );

  const messages = {
    open: getMessage("online", "#00A464"),
    logout: getMessage("logged out", "#F04F4E"),
    close: getMessage("offline", "#787677"),
    idle: getMessage("idle", "#F04F4E"),
    backFromIdle: getMessage("active", "#00A464"),
  };

  return messages[type];
};

export const sessionsNotificationBody = ({
  body,
  navigate,
  notification,
  allUsersObject,
}) => {
  const id = uuidv4();
  return {
    message: "Session Notification",
    key: body?.logId || id,
    description: sessionNotificationDescription(body, allUsersObject),
    placement: "bottomLeft",
    duration: 10,
    className: "info__notification",
    onClick: () => {
      if (body?.userId) {
        const path = window.location.pathname;
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("sessionUserId", body?.userId);
        const newUrl = `${path}?${queryParams.toString()}`;
        navigate(newUrl);
        setTimeout(() => notification.destroy(), 1000);
      }
    },
  };
};
