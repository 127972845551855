import { uniqBy } from "lodash";

import {
  CrewTeamType,
  ScheduleType,
  EmployeeReportType,
} from "../payrollLiveTypes";
import { JobsiteType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import findPolygonRange from "../../Payroll/Tabs/DEG/components/modalComponents/utils/findPolygonRange";

type RangeType = {
  inRange: boolean;
  distanceInFeet: number;
};

type CoordinatesMatchProps = {
  crewTeams: Array<CrewTeamType>;
  employees: Array<EmployeeReportType>;
  jobs: Array<JobsiteType>;
  schedules: Array<ScheduleType>;
};

type CoordinatesMatchResponse = {
  matchedEmployees: Array<EmployeeReportType>;
  jobsitesMatch: Array<JobsiteType | ScheduleType>;
};

export function coordinatesMatch({
  jobs,
  crewTeams,
  employees,
  schedules,
}: CoordinatesMatchProps): CoordinatesMatchResponse {
  let jobsitesMatch = [];
  let matchedEmployees = [];
  // const schedulesIncluded = schedules.map((sch) => sch.projectId);
  // const scheduleJobs = jobs.filter((job) =>
  //   schedulesIncluded.includes(job?.projectId)
  // );

  for (let i = 0; i < employees.length; i++) {
    const emp = employees[i];
    let jobMatch: JobsiteType | ScheduleType | undefined;

    for (let j = 0; j < schedules.length; j++) {
      const job = schedules[j];

      let range: RangeType;
      const geoInfo = job.geoFenceInfo;
      // Find closest jobsite from geofence
      if (emp.punchCoordinates?.lat) {
        if (!!geoInfo?.length) {
          range = findPolygonRange({
            points: geoInfo[0]?.geoFenceInfo,
            position: emp.punchCoordinates,
            radius: +job?.locationRadius || 300,
          });
          if (!range?.inRange) {
            const distanceFromJob = withinRadius(
              job?.addressPosition,
              emp?.punchCoordinates,
              +job?.locationRadius || 300
            );
            range = {
              inRange: distanceFromJob.withinRange,
              distanceInFeet: distanceFromJob.distanceInFeet,
            };
          }
        } else {
          // when there is no geofence get the closest jobsite from jobsite position
          const distanceFromJob = withinRadius(
            job?.addressPosition,
            emp?.punchCoordinates,
            +job?.locationRadius || 300
          );
          range = {
            inRange: distanceFromJob.withinRange,
            distanceInFeet: distanceFromJob.distanceInFeet,
          };
        }
      }

      if (range?.inRange && emp.liveStatus !== "No Punch") {
        jobMatch = job;
      } else if (!range?.inRange && emp.liveStatus !== "No Punch") {
        // let
        for (let zh = 0; zh < jobs.length; zh++) {
          const jobsite = jobs[zh];
          let jobsiteRange: RangeType;

          if (emp.punchCoordinates?.lat) {
            if (!!jobsite.geoFenceInfo?.length) {
              jobsiteRange = findPolygonRange({
                points: jobsite.geoFenceInfo[0]?.geoFenceInfo,
                position: emp.punchCoordinates,
                radius: +jobsite?.locationRadius || 300,
              });
              if (!jobsiteRange?.inRange) {
                const distanceFromJob = withinRadius(
                  jobsite?.addressPosition,
                  emp?.punchCoordinates,
                  +jobsite?.locationRadius || 300
                );
                jobsiteRange = {
                  inRange: distanceFromJob.withinRange,
                  distanceInFeet: distanceFromJob.distanceInFeet,
                };
              }
            } else {
              // when there is no geofence get the closest jobsite from jobsite position
              const distanceFromJob = withinRadius(
                jobsite?.addressPosition,
                emp?.punchCoordinates,
                +jobsite?.locationRadius || 300
              );
              jobsiteRange = {
                inRange: distanceFromJob.withinRange,
                distanceInFeet: distanceFromJob.distanceInFeet,
              };
            }
            if (jobsiteRange.inRange) {
              jobMatch = jobsite;
            }
          }
        }
      }
    }
    if (!!jobMatch?.projectId) {
      jobsitesMatch.push(jobMatch);
      let foreman = false;
      let teamIndex = crewTeams.findIndex((el) => {
        const isForeman =
          Number((el?.crewForeman?.employeeId || "").split("-")?.[1]) ===
          Number(emp.employeeNumber);

        const isMember =
          el.crewMembers.findIndex(
            (mem) =>
              Number((mem?.employeeId || "").split("-")?.[1]) ===
              Number(emp.employeeNumber)
          ) > -1;
        if (isForeman) {
          foreman = isForeman;
        }
        return isForeman || isMember;
      });

      matchedEmployees.push({
        ...emp,
        isForeman: foreman,
        projectId: jobMatch?.projectId,
        crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
        crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
      });
    }
  }
  return { jobsitesMatch, matchedEmployees };
}
