import { Button, Checkbox, message } from "antd";
import { toFixedFormatter } from "../formatters";
import { evaluateMonth } from "../formatters/evaluators";
import { cellarFloors } from "../polyfillers/cellarFloors";
import { Locked, Unlocked } from "../../../../DynamicView/src";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
export const hoist = [
  {
    headerName: "#",
    headerTooltip: "#",
    field: "id",
    // minWidth: 40,
    width: 60,
    editable: false,
    comparator: (v1, v2) => {
      //necessary becaouse by default it will order like [-1,-2,-3,0,1,2,3,4,5]
      return v1 - v2;
    },
  },
  {
    headerName: "Floor",
    headerTooltip: "floor",
    field: "floor",
    // minWidth: 40,
    width: 180,
    editable: false,
    valueFormatter: cellarFloors,
  },
  {
    headerName: "Stop",
    headerTooltip: "Stop",
    field: "stop",
    editable: false,
    cellRenderer: "CheckboxRenderer",
    // minWidth: 30,
    width: 100,
    filter: true,
  },
  {
    headerName: "DualStop",
    headerTooltip: "DualStop",
    field: "dual",
    editable: false,
    cellRenderer: "CheckboxRenderer",
    // minWidth: 30,
    width: 130,
    filter: true,
    hide: false,
  },
  {
    headerName: "Floor Height",
    headerTooltip: "Floor Height",
    field: "floor_height",
    width: 200,
    // minWidth: 40,
    valueFormatter: (params) => {
      if (
        isNaN(params.value) ||
        params.value === Infinity ||
        params.value < 0
      ) {
        // return "INVALID";
        return "-";
      } else {
        return params.value + " ft";
      }
    },
    valueParser: (params) => {
      if (params?.data?.locked) {
        message.error(
          "Floor Height is locked. Please unlock to edit the value."
        );
        return params.oldValue;
      }

      let value = parseFloat(params.newValue);

      if (isNaN(value) || value === Infinity || value < 0) {
        //0 is not allowed
        // return "INVALID";
        message.error(
          "Invalid value for Floor Height. Please enter a number greater than 0. "
        );
        return params.oldValue;
        // return "-";
      }
      message.info({
        content: "Floor Height has been updated.",
        // duration: 5,
        style: {
          marginTop: "40vh",
        },
      });
      return value;
    },
  },
  {
    headerName: "Floor Height (converted)",
    headerTooltip: "Floor Height (converted)",
    field: "floor_height_converted",
    width: 200,
    editable: true,
    valueFormatter: (params) => {
      return convertFeetToInches(params?.data?.floor_height);
    },
  },
  {
    headerName: "Lock Height",
    headerTooltip: "Lock Height",
    field: "locked",
    editable: false,
    width: 120,
    cellStyle: (params) => {
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // cursor: "pointer",
        width: "100px",
      };
    },
    cellRendererFramework: (params) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "32px",
            height: "32px",
            cursor: "pointer",
          }}
          onClick={() => {
            params.data.locked = !params?.data?.locked;
            params.api.refreshCells({
              columns: ["locked"],
            });
          }}
        >
          {/* // <Button
        //   onClick={() => {
        //     params.data.locked = !params?.data?.locked;
        //     params.api.refreshCells({
        //       columns: ["locked"],
        //     });
        //   }}
        // > */}
          {params?.data?.locked ? <Locked /> : <Unlocked />}
        </div>
      );
    },
  },
  {
    headerName: "Addons",
    headerTooltip: "Addons",
    field: "addons",
    width: 140,
    // minWidth: 140,
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    cellRendererParams: {
      suppressCount: true,
      // checkbox: true,
      innerRenderer: "SidewalkShedAddonsRenderer",
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
    },
    valueGetter: (params) => {
      if (params.data.isJump) {
        return null;
      } else {
        return params.data.addons;
      }
    },
    valueFormatter: (params) => {
      if (params.data.addons) {
        return `(${params.data?.addons?.length})`;
      } else {
        return "N/A";
      }
    },
  },
  {
    headerName: "Description",
    headerTooltip: "Description",
    field: "description",
    width: 300,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "Internal Note",
    headerTooltip: "Internal Note",
    field: "note",
    width: 300,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "Months",
    headerTooltip: "Months",
    field: "months",
    // width: 100,
    valueFormatter: evaluateMonth,
  },
];

export function convertFeetToInches(lengthInFeet) {
  let num_lenghtInFeet = forceToNumber(lengthInFeet);
  // Calculate the whole feet
  const feet = Math.floor(num_lenghtInFeet);

  // Calculate the remaining inches
  const inches = Math.round((num_lenghtInFeet - feet) * 12);
  console.log("feetinch", {
    feet,
    inches,
  });

  if (feet === 0 || isNaN(feet) || feet === Infinity || feet < 0) {
    return "-";
  }

  if (inches === 0) {
    return feet + " ft";
  } else {
    // Return the result as a string
    return feet + "'" + inches + "”";
  }
}
