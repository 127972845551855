export function filterFields({ dropdownData }) {
  const {
    uploadNames,
    employeeNames,
    employeeIds,
    companyNames,
    jobsiteNames,
  } = dropdownData;
  return [
    {
      globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
      label: "Upload Name",
      formItemName: "uploadName",
      //   type: "select",
      type: "styledmultiselect",
      mode: "multiple",
      placeholder: "Select upload name",
      showSearch: true,
      customOptions: uploadNames,
    },
    {
      label: "Employee Id",
      formItemName: "employeeId",
      //   type: "select",
      type: "styledmultiselect",
      mode: "multiple",
      placeholder: "Select employee",
      showSearch: true,
      customOptions: employeeIds,
    },
    {
      label: "Employee Full Name",
      formItemName: "employeeFullName",
      //   type: "select",
      type: "styledmultiselect",
      mode: "multiple",
      placeholder: "Select employee",
      showSearch: true,
      customOptions: employeeNames,
    },
    {
      label: "Company",
      formItemName: "companyName",
      //   type: "select",
      type: "styledmultiselect",
      mode: "multiple",
      placeholder: "Select company",
      showSearch: true,
      customOptions: companyNames,
    },
    {
      label: "Jobsite Match",
      formItemName: "jobsiteMatch",
      //   type: "select",
      type: "styledmultiselect",
      mode: "multiple",
      placeholder: "Select jobsite",
      showSearch: true,
      customOptions: jobsiteNames,
    },
    // {
    //   label: "SOW",
    //   formItemName: "sow",
    //   type: "select",
    //   mode: "multiple",
    //   placeholder: "Select sow",
    //   showSearch: true,
    //   customOptions: [],
    // },
    {
      label: "Punch Date",
      formItemName: "dateRange",
      type: "rangepicker",
      placeholder: "Select date",
    },
  ];
}
