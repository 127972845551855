import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getMaxNoFeet } from "./getMax";

// returns range formation from a data entry grid input. used in column definitions
export function evaluateMathExpression(params) {
  const fullExpression = params.value;
  console.log("fullExpression", fullExpression);
  if (fullExpression) {
    if (fullExpression.toString().includes(">")) {
      let expressions = fullExpression.split(">");
      return `${evaluate(expressions[0])} - ${evaluate(expressions[1])}`;
    } else {
      return evaluate(fullExpression);
    }
  } else {
    return "-";
  }
}

export function evaluateMonth(params) {
  try {
    let result = eval(params.value);
    if (result > 12) {
      return "Not a valid Month";
    } else if (result <= 0) {
      return "Not a valid Month";
    } else {
      return result;
    }
  } catch (error) {
    return "Invalid result";
  }
}

export function valueGetter(params) {
  if (
    params === undefined ||
    params.data === undefined ||
    params.column === undefined
  )
    return "-";

  const field = params.column.colId,
    item = params.data;

  if (
    item[field] === undefined ||
    item[field] === null ||
    item[field].toString() === ""
  )
    return "-";
  return item[field];
}

export function evaluate(expression) {
  try {
    // eslint-disable-next-line
    let result = eval(expression);
    if (result > 0 && result !== Infinity && typeof result === "number") {
      return Math.round(result * 100) / 100;
    } else if (typeof result !== "number") {
      return "Not a Number";
    } else {
      return result;
    }
  } catch (error) {
    return "Invalid result";
  }
}

export function evaluateMathExpression2(params) {
  if (params.newValue?.toString() === "-") return params.newValue;

  const fullExpression = params.newValue;
  if (fullExpression) {
    if (fullExpression.toString().includes(">")) {
      let expressions = fullExpression.split(">");
      return `${evaluate(expressions[0])} - ${evaluate(expressions[1])}`;
    } else {
      return evaluate(fullExpression);
    }
  } else {
    return "no value";
  }
}

export function evaluateMathExpressionAddons(params) {
  if (params.newValue?.toString() === "-") return params.newValue;

  const fullExpression = params.newValue;
  if (fullExpression) {
    if (fullExpression.toString().includes(">")) {
      let expressions = fullExpression.split(">");
      return `${evaluate(expressions[0])} > ${evaluate(expressions[1])}`;
    } else {
      return evaluate(fullExpression);
    }
  } else {
    return undefined;
  }
}

export const dimensionFormatter = (params) => {
  if (params.value?.toString().includes(">")) {
    return params.value;
    // let expressions = params.value.split('>');
    // return `${(expressions[0])} - ${(expressions[1])}`;
  }
  if (params.value?.toString() === "-") return params.value;
  let value = parseFloat(params.value);
  if (isNaN(value) || value === Infinity || value <= 0) {
    //0 is not allowed
    // return "INVALID"
    return "-";
  }

  return value;
};

export const dimensionFormatterAddons = (params) => {
  console.log("on add on", params);
  if (params.value === undefined) return "-";
  let value = parseFloat(params.value);

  if (isNaN(value) || value === Infinity || value <= 0) {
    //0 is not allowed
    // return "INVALID"
    return "-";
  }

  return value;
};

// returns a formatted included corner line from a grid entry
export function evaluateCornerExpression(params) {
  let fullExpression = params.value;
  if (fullExpression) {
    if (fullExpression.toString().includes("x")) {
      let expressions = fullExpression.toString().split("x");
      if (expressions.length === 2) {
        return `${expressions[0]} x ${getMaxNoFeet(expressions[1])}′`;
      } else {
        return "Bad format.";
      }
    } else {
      return `${getMaxNoFeet(fullExpression)}′`;
    }
  } else {
    return "-";
  }
}
export function getSelectedPriceSchemesForService(
  pricingData,
  estimation,
  savedData,
  gridData
) {
  let selectedPriceSchemesForService = {};
  for (const pricingForService of pricingData) {
    if (
      pricingForService.pricingObject?.priceSchemes?.length > 0 &&
      Array.isArray(gridData)
    ) {
      let curr = gridData?.find(
        (e) =>
          e?.serviceId?.toString() === pricingForService?.serviceId?.toString()
      )?.priceScheme;
      const selectedPriceSchemeId =
        curr?.id ||
        estimation.services.find(
          (s) =>
            s.serviceId.toString() === pricingForService.serviceId.toString()
        )?.selectedPriceSchemeId;
      let selectedPriceScheme;
      if (selectedPriceSchemeId) {
        //if there is an already a picked price scheme, use it
        selectedPriceScheme = pricingForService.pricingObject.priceSchemes.find(
          (ps) => ps.id === selectedPriceSchemeId
        );

        if (selectedPriceScheme === undefined)
          selectedPriceScheme = pricingForService.pricingObject.priceSchemes[0]; //if it couldn't find the selected scheme because it may have been deleted, pick the first one
      } else {
        //if not just pick the first
        let defaultPriceScheme =
          pricingForService.pricingObject?.defaultPriceSchemeId || false;
        let temp = pricingForService.pricingObject.priceSchemes.findIndex(
          (ps) => ps.id === defaultPriceScheme
        );
        if (defaultPriceScheme && temp > -1) {
          selectedPriceScheme =
            pricingForService.pricingObject.priceSchemes[temp];
        } else
          selectedPriceScheme = pricingForService.pricingObject.priceSchemes[0];
      }

      selectedPriceSchemesForService[pricingForService.serviceId] = {
        priceScheme: selectedPriceScheme,
        priceSchemesType: pricingForService.pricingObject.priceSchemesType,
        addonsColumnDefs: pricingForService.pricingObject.addonsColumnDefs,
      };
    }
  }
  console.log("selectedPriceSchemesForService", selectedPriceSchemesForService);
  return selectedPriceSchemesForService;
}
