import { Input } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { SearchIcon } from "../../../../../SidebarPages/Communication/assets";

const SearchDepartments = ({
  onSearchDepartment,
  id,
  placeholder,
  label = "",
}) => {
  return (
    <div className="headerSearch">
      <UserOutlined style={{ fontSize: "32px" }} />
      <p className="header">{`Search a ${label ? label : "department"}`}</p>
      <div id={id} className="headerSearch-iconInput">
        <Input
          className="searchInput"
          placeholder={placeholder}
          allowClear
          onChange={onSearchDepartment}
          style={{ width: 200, height: 32 }}
          prefix={<SearchIcon width={15} height={15} />}
        />
      </div>
    </div>
  );
};

export default SearchDepartments;
