import { convertCategory } from "../../../../../../../../helpers/constants/chargeConstants";
import { calculateTotalPriceForService } from "../../../../../../Accounting/components/utilities";
import { totalitiesTotalTaxGen } from "../../../../Rentals/RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";
import { calculatePriceForIncludedRentals } from "../../NewChargeItem/utils";

export const calculateTotalForRowData = (
  data,
  rData,
  categoryFrom,
  forChargeDisplay = false
) => {
  if (categoryFrom === "Schedule Of Value") return rData?.totalPrice;
  if (categoryFrom === "Invoice") return rData?.totalInvoiceAmount;
  if (categoryFrom === "Requisition")
    return rData?.totalities?.currentPaymentDue;
  if (categoryFrom === "Rental")
    return (
      totalitiesTotalTaxGen({ fakeRentals: rData, forChargeDisplay })
        ?.totalPrice || 0
    );
  else if (categoryFrom === "Invoice") {
    return rData?.totalInvoiceAmount;
  } else {
    let toReturn = data?.reduce((prev, curr) => {
      const { amount, taxAmount } = calculateTotalPriceForService(
        curr,
        convertCategory[categoryFrom]
      );

      return prev + (amount || 0) + (taxAmount || 0);
    }, 0);
    if (categoryFrom === "Requisition")
      toReturn += calculatePriceForIncludedRentals(rData?.includedRentals);
    return toReturn;
  }
};
