export const getLatLngByAddress = async (address) => {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          const zipCode =
            results?.[0]?.address_components?.find((el) =>
              el?.types?.includes("postal_code")
            )?.long_name || undefined;
          resolve({
            coordinates: { lat: lat(), lng: lng() },
            zipCode,
            geocode: results[0],
          });
        } else {
          reject(new Error("No results"));
        }
      } else {
        reject(new Error("Geocode failed due to: " + status));
      }
    });
  });
};
