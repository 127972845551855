import axios from "axios";

export const vehiclesAPI = axios.create({
  baseURL: "https://vpic.nhtsa.dot.gov/api/vehicles",
});

const additionalManufacturers = [
  {
    Country: "UNITED STATES (USA)",
    Mfr_CommonName: null,
    Mfr_Name: "TRAIL KING INDUSTRIES",
    Mfr_ID: 13498,
    VehicleTypes: [],
  },
  {
    Country: "UNITED STATES (USA)",
    Mfr_CommonName: null,
    Mfr_Name: "UTILITY TRAILER MANUFACTURING COMPANY",
    Mfr_ID: 10289,
    VehicleTypes: [
      {
        GVWRFrom: "Class 8: 33,001 lb and above (14,969 kg and above)",
        GVWRTo: "Class 8: 33,001 lb and above (14,969 kg and above)",
        IsPrimary: true,
        Name: "Trailer",
      },
    ],
  },
];

export const fetchVehicleMakes = async ({ manufacturer, year }) => {
  let response = [];
  manufacturer &&
    year &&
    (await vehiclesAPI
      .get(
        `/GetMakesForManufacturerAndYear/${manufacturer}?year=${year}&format=json`
      )
      .then((res) => {
        response = [...res.data.Results];
      })
      .catch((e) => console.error("Fleet Vehicles Make fetch fail ", e)));

  return response;
};

export const fetchVehicleManufacturers = async () => {
  let response = [];
  await vehiclesAPI
    .get("/GetAllManufacturers?format=json")
    .then((res) => {
      response = [...res.data.Results, ...additionalManufacturers];
    })
    .catch((e) => console.error("Fleet Vehicles Manufacturer fetch fail ", e));

  return response;
};

export const fetchVehicleModels = async (selectedVehicleMake) => {
  let response = [];
  selectedVehicleMake &&
    (await vehiclesAPI
      .get(`getmodelsformake/${selectedVehicleMake}?format=json`)
      .then((res) => {
        response = res.data.Results.map((item) => ({
          Model_ID: item.Model_ID,
          Model_Name: item.Model_Name,
        }));
      })
      .catch((e) => console.error("Fleet Vehicles Model fetch fail ", e)));

  return response;
};

export const fetchVehicleModelsByYear = async (
  selectedVehicleMake,
  selectedVehicleYear
) => {
  let response = [];
  selectedVehicleYear &&
    selectedVehicleMake &&
    (await vehiclesAPI
      .get(
        `getmodelsformakeyear/make/${selectedVehicleMake}/modelyear/${selectedVehicleYear}?format=json`
      )
      .then((res) => {
        response = res.data.Results.map((item) => ({
          Model_ID: item.Model_ID,
          Model_Name: item.Model_Name,
        }));
      })
      .catch((e) =>
        console.error("Fleet Vehicles Model By Year fetch fail ", e)
      ));

  return response;
};
