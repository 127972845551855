// import "./Tooltip.css";
import { Tooltip } from "antd";

const TooltipMonday = ({ text, position, children, hasDisabledEl }) => {
  const textInsideButton = hasDisabledEl ? text : children?.props?.children;

  return (
    <Tooltip title={text} placement={position}>
      {children}
    </Tooltip>
  );
};

export default TooltipMonday;
