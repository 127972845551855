export const filterVisibleSteps = ({
  useExistingData,
  title,
  existingEstimations,
}) =>
  !useExistingData || !existingEstimations.length
    ? !["estimations", "services", "documentation"].includes(
        title.toLowerCase()
      )
    : true
