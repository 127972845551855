import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import { DropdownIcon } from "../../../../../../../../../../BasePage/src";
import { changeStatusHandler } from "./statusChangerFunctions";
import "./StatusChanger.scss";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Select } from "antd";

const StatusChanger = () => {
  const { programFields } = useSelector((state) => state.programFields);
  const { isWritable, fakeSOV } = useStateContext();

  const dispatchContext = useDispatchContext();
  const { isLoading } = useParams();

  const options = programFields
    ?.find(({ fieldName }) => fieldName === "Status of Schedule of Values")
    ?.fieldOptions?.filter(({ status }) => status)
    .map(({ statusName }) => ({ value: statusName, label: statusName }));

  const changeStatus = (value) =>
    changeStatusHandler({ fakeSOV, value, dispatchContext });

  return (
    <div className="statusChangerContainer">
      <Select
        disabled={!isWritable || isLoading}
        value={fakeSOV?.sovStatus}
        options={options}
        style={{ width: 130 }}
        onChange={changeStatus}
        suffixIcon={<DropdownIcon />}
      />
    </div>
  );
};

export default StatusChanger;
