export const sampleTemplate = {
  definition: {
    Name: "multisectionreport1",
    Width: "0in",
    Layers: [
      {
        Name: "default",
      },
    ],
    CustomProperties: [
      {
        Name: "DisplayType",
        Value: "Page",
      },
      {
        Name: "SizeType",
        Value: "Default",
      },
      {
        Name: "PaperOrientation",
        Value: "Portrait",
      },
    ],
    Page: {
      PageWidth: "8.5in",
      PageHeight: "11in",
      RightMargin: "0in",
      LeftMargin: "0in",
      TopMargin: "0in",
      BottomMargin: "0in",
      Columns: 1,
      ColumnSpacing: "0.5in",
    },
    ReportSections: [
      {
        Type: "Continuous",
        Name: "ContinuousSection1",
        Page: {
          PageWidth: "8.5in",
          PageHeight: "11in",
          RightMargin: "0in",
          LeftMargin: "0in",
          TopMargin: "0in",
          BottomMargin: "0in",
          Columns: 1,
          ColumnSpacing: "0in",
        },
        Width: "2pt",
        PageHeader: {
          Height: "1.0208in",
        },
        Body: {
          Height: "0in",
        },
        PageFooter: {
          Height: "0.9479in",
        },
      },
    ],
  },
};
