import { Popover } from "antd";
import React from "react";
import { ActionsIcon } from "../../../../../../../SidebarPages/DynamicView/src";

function MoreActions({ animatedClassName, content }) {
  return (
    <Popover
      key={animatedClassName[1]}
      placement="left"
      trigger="click"
      content={<div className="notification-popover-content">{content}</div>}
      overlayClassName="notification-more-popover"
    >
      <span className="more-icon">
        <ActionsIcon />
      </span>
    </Popover>
  );
}

export default MoreActions;
