import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const paymentInitialState = {
  accountName: "",
  accountId: "",
  projects: [],
  paymentMethod: "",
  paymentDate: null,
  qbReferenceNumber: "",
  qbSyncStatus: "",
  qbLastSync: moment().valueOf(),
  invoices: [],
  payments: [],
  clientBalance: 0,
  paymentMemo: "",
  credits: 0,
  appliedAmount: 0,
  discounts: {
    discountDate: null,
    discountAmount: 0,
    discountReason: 0,
  },
  credits: {},
};

export default paymentInitialState;
