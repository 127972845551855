import { checkIfServiceIsHoist } from "../../../../../../../Estimations/DataEntryGrid/models/Service";
import { getScopeRentPerc } from "./FooterFunctions";
import { v4 as uuid } from "uuid";
import { groupBy, max, min } from "lodash";
import dayjs from "dayjs";
import _ from "lodash";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import { filterTables, generateWeeks } from "../../../../../../../../../utils";
import {
  getDetailsRentDaily,
  getDetailsRentDailyOld,
} from "../../../../RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/HeaderDateInput/HeaderDateInputFunctions";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { createRental } from "../../../../../../../../../actions/rentals";
import { autoFillRentals } from "../../newRentalFunctions";
export const createRentalFromEstimationHandler = ({
  handleCloseForm,
  setChosenData,
  estimations,
  buttonType,
  chosenData,
  projectId,
  dispatch,
  specificProject,
  sovServices,
  getBodyToSaveInAutomation,
  navigate,
  isNextStep,
  getRental,
  saveAddedLogs,
  rentals,
}) => {
  console.log("createRentalFromEstimationHandler3", sovServices);
  const {
    services,
    jobSiteAddress,
    estimationId,
    quickbooksNumber = "",
    estimationNumber = "",
    // estimationId: estimationIdFromChosenData,
    // ...rest
  } = estimations?.find(
    ({ estimationId }) => estimationId === chosenData?.selectedId
  );

  const taxRate = specificProject?.taxRate || 0;
  const PROCESSED_SERVICES = processAndFormatServices(
    chosenData,
    services,
    estimationId
  );
  const progressCalculator = 1; //its 100 by default
  const NEW_RENTAL = {
    projectId: projectId,
    projectAddress: jobSiteAddress,
    taxRate: taxRate,
    projectTaxRate: taxRate,
    estimationId: estimationId,
    services: PROCESSED_SERVICES?.map((serviceData) => {
      const rentPercentage = getRentPercentage(serviceData);
      const sovServiceData = sovServices?.find(
        (el) =>
          el?.label === serviceData?.label && el?.estimationId === estimationId
      );
      //   console.log("sovServiceData", {
      //     sovServiceData,
      //     sovServices,
      //     estimationId,
      //   });
      return createRentalServiceStructuralHandler({
        serviceData,
        rentPercentage,
        sovServiceData,
        progressCalculator,
        quickbooksNumber,
        estimationNumber,
        rentals,
        quickbooksNumber,
        estimationNumber,
        estimationId,
      });
    }),
  };

  console.log("NEW_RENTAL", NEW_RENTAL);

  // After we create the rental object, we proceed with the creation of the rental and adequate handling of the response (logs/automations)
  if (!!getBodyToSaveInAutomation) {
    getBodyToSaveInAutomation({ bodyForApi: NEW_RENTAL });
  } else {
    const redirectToCreatedRental = !!(buttonType === "Finish");

    dispatch(
      createRental(
        NEW_RENTAL,
        redirectToCreatedRental,
        navigate,
        isNextStep,
        getRental,
        saveAddedLogs
      )
    );

    setChosenData({
      estimation: undefined,
      service: undefined,
    });

    if (buttonType === "Finish") {
      handleCloseForm();
    } else if (buttonType === "Save & Close") {
      handleCloseForm();
    }
  }
};

export const createRentalServiceStructuralHandler = ({
  serviceData = {},
  rentPercentage,
  sovServiceData,
  progressCalculator,
  rentals,
  quickbooksNumber,
  estimationNumber,
  estimationId,
}) => {
  // const scheduleProgress = getDailyProgressFromScheduling({
  //   rentals,
  //   quickbooksNumber,
  //   estimationId,
  // });
  if (checkIfServiceIsHoist(serviceData))
    return {
      label: serviceData?.label,
      serviceId: serviceData?.serviceId,
      rentPercentage,
      isScope: false,
      retainage: sovServiceData?.retainage || 0,
      rentalPaymentTerms: serviceData?.rentalPaymentTerms || {},
      quickbooksNumber:
        serviceData?.quickbooksNumber || quickbooksNumber || estimationNumber,
      serviceOptions: [
        {
          elevationId: uuid(),
          elevationLabel: "Hoist",
          items: serviceData?.serviceOptions[0]?.map((hoistData, index) => {
            return {
              ...hoistData,
              id: index + 1,
              appliedAmount: 0,
              totalProgress: progressCalculator,
              charged: false,
              charges: [],
              applied: false,
              lock: false,
              approved: true,
              maxWeeklyAmount: +(
                (hoistData?.rentalsTotal / 4) *
                progressCalculator
              )?.toFixed(2),
              rentalDetails: {
                startDate: undefined,
                endDate: undefined,
                weeklyAmount: +(
                  (hoistData?.rentalsTotal / 4) *
                  progressCalculator
                )?.toFixed(2),
                details: [],
              },
              description: hoistDescriptionGenerator({ hoistData }),
              ...(autoFillRentals({
                rentals: rentals,
                currentElevation: hoistData,
                currentService: serviceData,
                quickbooksNumber:
                  serviceData?.quickbooksNumber ||
                  quickbooksNumber ||
                  estimationNumber,
                pliData: hoistData,
                estimationId,
              }) || {}),
              charged: false,
              charges: [],
              applied: false,
            };
          }),
        },
      ],
    };
  else {
    return {
      label: serviceData?.label,
      serviceId: serviceData?.serviceId,
      rentPercentage,
      retainage: sovServiceData?.retainage || 0,
      isScope: serviceData?.isScope,
      rentalPaymentTerms: serviceData?.rentalPaymentTerms || {},
      serviceOptions: serviceData?.serviceOptions[0]?.map((elevationData) => {
        if (serviceData?.isScope) {
          attachStructureToElevation(elevationData, serviceData);
        }
        return {
          ...elevationData,
          items: elevationData?.items
            ?.filter((e) => e?.approved === true)
            .map(
              //dont delete width, height, length because i use them with eval
              (pliData) => {
                // elevationData.rent = serviceData?.isScope
                //   ? getScopeElevationRent(elevationData)
                //   : elevationData?.rent;
                let merged =
                  autoFillRelatedRentals({
                    rentals: rentals,
                    currentElevation: elevationData,
                    currentService: serviceData,
                    quickbooksNumber:
                      serviceData?.quickbooksNumber ||
                      quickbooksNumber ||
                      estimationNumber,
                    pliData,
                    estimationId,
                  }) || {};
                console.log("ledjotest", { merged });
                return {
                  ...pliData,
                  appliedAmount: 0,
                  totalProgress: progressCalculator,
                  charged: false,
                  charges: [],
                  applied: false,
                  lock: false,
                  maxWeeklyAmount: +(
                    (pliData?.rent / 4) *
                    progressCalculator *
                    10 *
                    (rentPercentage / 100)
                  )?.toFixed(2),
                  rentalDetails: {
                    startDate: undefined,
                    endDate: undefined,
                    weeklyAmount: +(
                      (elevationData?.rent / 4) *
                      progressCalculator *
                      (rentPercentage / 100)
                    )?.toFixed(2),
                    details: [],
                  },
                  ...merged,
                  charged: false,
                  charges: [],
                  applied: false,
                };
              }
            ),
        };
      }),
      quickbooksNumber:
        serviceData?.quickbooksNumber || quickbooksNumber || estimationNumber,
    };
  }
};
export const hoistDescriptionGenerator = ({ hoistData }) =>
  [
    [
      "<b>Label</b>",
      `${capitalizeFirstLetter(hoistData?.elevationLabel)}`,
    ]?.join(": "),
    ["<b>Location</b>", `${capitalizeFirstLetter(hoistData?.location)}`]?.join(
      ": "
    ),
    ["<b>Access</b>", `${capitalizeFirstLetter(hoistData?.access)}`]?.join(
      ": "
    ),
    ["<b>Type</b>", `${capitalizeFirstLetter(hoistData?.type)}`]?.join(": "),
    [
      "<b>Jumps</b> <br/>",
      hoistData?.jumps
        ?.map(({ name, floors }) =>
          [
            `${name}: `,
            "{ ",
            floors?.flatMap(({ floor }) => `Floor: ${floor}`)?.join(", "),
            " }",
          ]?.join("")
        )
        ?.join(" <br/> "),
    ]?.join(""),
  ]?.join("<br/>");
export const autoFillRelatedRentals = ({
  rentals,
  currentElevation,
  currentService,
  quickbooksNumber = "",
  pliData,
  estimationId,
}) => {
  console.log("autoFillRelatedRentals", {
    rentals,
    currentElevation,
    currentService,
    quickbooksNumber,
    pliData,
    estimationId,
  });
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);
  let CURRENT_EXTREMITIES = {
    startDate: 0,
    endDate: 0,
  };

  console.log("ledjoRentals", rentals);

  let filteredRentals = rentals?.filter((el) => {
    return el?.services?.find(
      (el) =>
        el?.serviceId.toString() === currentService?.serviceId.toString() &&
        el?.quickbooksNumber?.toString() === quickbooksNumber?.toString()
    );
  });
  console.log("ledjoFilteredRentals", filteredRentals);

  let filteredServices = filteredRentals
    ?.flatMap((el) => el?.services)
    .flatMap((el) => el?.serviceOptions);
  let currPli = {};
  if (filteredServices?.length > 0) {
    let filteredElevation = filteredServices?.filter((el) =>
      checkIfServiceIsHoist(currentService)
        ? el?.elevationLabel === "Hoist"
        : el?.elevationId === currentElevation?.elevationId
    );
    let filteredPlis = filteredElevation
      ?.flatMap((el) => el?.items)
      .filter((el) => {
        return checkIfServiceIsHoist(currentService)
          ? el?.elevationId === pliData?.elevationId
          : el?.id === pliData?.id;
      })
      ?.sort((a, b) => b?.rentalDetails?.endDate - a?.rentalDetails?.endDate);

    console.log("ledjotrack", {
      tesT: filteredElevation?.flatMap((el) => el?.items),
      pliData,
      filteredPlis,
    });
    if (filteredPlis?.length > 0 && !!filteredPlis[0]?.rentalDetails?.endDate) {
      let diff =
        dayjsNY(filteredPlis[0]?.rentalDetails?.endDate).diff(
          dayjsNY(filteredPlis[0]?.rentalDetails?.startDate),
          "day"
        ) + 1;
      CURRENT_EXTREMITIES = {
        startDate: dayjsNY(filteredPlis[0]?.rentalDetails?.endDate)
          .add(1, "day")
          .valueOf(),
        endDate: dayjsNY(filteredPlis[0]?.rentalDetails?.endDate)
          .add(diff, "day")
          .valueOf(),
      };
    } else return {};

    currPli = filteredPlis?.[0] || {};

    console.log("CURRENT_EXTREMITIES", filteredPlis);
    let details = generateWeeks(
      CURRENT_EXTREMITIES?.startDate,
      CURRENT_EXTREMITIES?.endDate
    );
    let startDate = CURRENT_EXTREMITIES?.startDate;
    let endDate = CURRENT_EXTREMITIES?.endDate;
    console.log("rentttttt", startDate, endDate);
    if (!startDate || !endDate) return {};
    const howManyDays =
      !!startDate && !!endDate
        ? +(
            dayjs
              ?.duration(
                dayjs(endDate)?.startOf("day")?.valueOf() -
                  dayjs(startDate)?.startOf("day")?.valueOf()
              )
              ?.asDays() + 1
          )?.toFixed(0)
        : 0;
    const dailyAmount = !checkIfServiceIsHoist(currentService)
      ? +(forceToNumber(pliData?.rent) / (4 * 7))?.toFixed(2)
      : +(forceToNumber(currentElevation?.rentalsTotal) / (4 * 7))?.toFixed(2);
    let tempDetails = getDetailsRentDailyOld({
      details,
      rent: !checkIfServiceIsHoist(currentService)
        ? forceToNumber(pliData?.rent)
        : forceToNumber(currentElevation?.rentalsTotal),
      dailyAmount,
      howManyDays,
      isFullMonth: false,
      // lastOne: idx === lineItemsData?.items?.length - 1,
    });
    console.log("ledjotemppppp", {
      howManyDays,
      dailyAmount,
      currPliRent: currentElevation?.rent,
      currPli,
      tempDetails,
      details,
      currentElevation,
    });
    let tempApplied = Array.isArray(tempDetails)
      ? tempDetails?.reduce((acc, curr) => {
          return (
            acc +
            curr?.days?.reduce((acc2, curr2) => {
              return acc2 + forceToNumber(curr2?.dailyAmount);
            }, 0)
          );
        }, 0)
      : 0;
    if (Object.keys(currPli).length > 0) {
      currPli = {
        ...(currPli ?? {}),
        lock: false,
        applied: false,
        appliedAmount: forceToNumber(tempApplied?.toFixed(2) ?? 0),
        rentalDetails: {
          ...currPli?.rentalDetails,
          endDate,
          startDate,
          details: tempDetails,
        },
      };
    }
  }
  console.log("currPli", currPli);

  return currPli;
};

export const processAndFormatServices = (
  chosenData,
  services,
  estimationId
) => {
  return chosenData?.service?.map((serviceData) => {
    const splittedServiceLabel = serviceData.split("|")[0];
    const splittedEstimationId = serviceData.split("|")[1];

    return services?.find(
      ({ label }) =>
        label === splittedServiceLabel && estimationId === splittedEstimationId
    );
  });
};

export const getRentPercentage = (serviceData) => {
  return serviceData?.isScope
    ? getScopeRentPerc(serviceData)
    : serviceData?.rent;
};

export const getDailyProgressFromScheduling = async ({
  // rentals,
  // currentElevation,
  // currentService,
  // quickbooksNumber,
  // pliData,
  estimationId = "",
  isFullMonth = false,
  serviceData = {},
  startDate = null,
  endDate = null,
  toSetService = {},
  elevationData = {},
}) => {
  const SCHEDULE_DATA = {
    installationDate: null, //this is the date when the service is installed
    initialDatesProgress: {},
  };
  await filterTables("progressItems", "estimationId", estimationId).then(
    (tempData) => {
      if (tempData?.length > 0) {
        // Calculate minimum date
        tempData = tempData.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );

        let minDate = tempData[0]?.startDate;

        // Set the minimum date
        SCHEDULE_DATA.installationDate = minDate;

        // Group by serviceId and process further
        let initialDatesProgress = tempData.reduce((acc, el) => {
          let { serviceId, elevationId, pli } = el;

          if (!acc[serviceId]) acc[serviceId] = {};
          if (!acc[serviceId][elevationId]) acc[serviceId][elevationId] = {};

          if (!acc[serviceId][elevationId][pli])
            acc[serviceId][elevationId][pli] = [];
          acc[serviceId][elevationId][pli].push(el);

          return acc;
        }, {});

        // Set the processed data to SCHEDULE_DATA
        SCHEDULE_DATA.initialDatesProgress = initialDatesProgress;
      }
    }
  );
  let monthRent = !checkIfServiceIsHoist(serviceData)
    ? elevationData?.rent
    : elevationData?.rentalsTotal;
  let temp = {
    ...serviceData,
    serviceOptions: toSetService?.serviceOptions?.map((elevationData) => {
      return {
        ...(elevationData ?? {}),
        items: elevationData?.items?.map((pliData, idx) => {
          return getPLIData({
            pliReference: pliData,
            SCHEDULE_DATA,
            elevationData,
            startDate,
            idx,
            endDate,
            serviceData,
          });
        }),
      };
    }),
  };
  console.log("getDailyProgressFromScheduling", temp);

  return temp;
};

const getPLIData = ({
  pliReference = {},
  SCHEDULE_DATA,
  elevationData = {},
  startDate = null,
  endDate = null,
  idx,
  serviceData,
}) => {
  if (!pliReference?.charged && !pliReference?.lock) {
    const details = generateWeeks(startDate, endDate) || [];

    const MONTH_RENT = forceToNumber(pliReference?.rent);
    const DAILY_AMOUNT = MONTH_RENT / 28;
    let SUM_PROGRESS_OF_DAYS = 0;
    let counter = 0;
    let sumAmount = 0;
    let numberOfWeeksPastInst = serviceData?.rentalPaymentTerms?.range || 0;
    let newInstallationDate = dayjsNY(SCHEDULE_DATA?.installationDate)
      .add(numberOfWeeksPastInst, "week")
      .valueOf();
    console.log("ledjodata", {
      startDate: dayjsNY(startDate).format("YYYY-MM-DD"),
      endDate: dayjsNY(endDate).format("YYYY-MM-DD"),
      serviceData,
      SCHEDULE_DATA,
      oldInstallationDate: dayjsNY(SCHEDULE_DATA?.installationDate).format(
        "YYYY-MM-DD"
      ),
      newInstallationDate: dayjsNY(newInstallationDate).format("YYYY-MM-DD"),
      newStartDate: dayjsNY(startDate)
        .add(numberOfWeeksPastInst, "week")
        .format("YYYY-MM-DD"),
      newEndDate: dayjsNY(endDate)
        .add(numberOfWeeksPastInst, "week")
        .format("YYYY-MM-DD"),
    });

    console.log("getPLIDATA", {
      details,
      SCHEDULE_DATA,
      elevationData,
      MONTH_RENT,
      DAILY_AMOUNT,
    });
    const tempDetails = getDetailsRentDailyScheduler({
      details,
      rent: MONTH_RENT,
      dailyAmount: DAILY_AMOUNT,
      howManyDays: 28,
      lastOne: idx === elevationData?.items?.length - 1,
      startDate,
      endDate,
      isFullMonth: false, //TODO: To change this
      startDate,
      endDate,
      SCHEDULE_DATA,
      serviceData,
      elevationData,
      pliReference,
    });
    tempDetails?.forEach((el) => {
      el?.days?.forEach((el2) => {
        SUM_PROGRESS_OF_DAYS += el2?.progress;
        counter++;
        sumAmount += forceToNumber(el2?.dailyAmount);
      });
    });
    console.log("tempDetails222", {
      counter,
      tempDetails,
      sumAmount,
      SUM_PROGRESS_OF_DAYS,
    });
    let toReturn = {
      ...pliReference,
      totalProgress: SUM_PROGRESS_OF_DAYS / counter / 100,
      comesFromScheduling: true,
      applied: false,
      maxWeeklyAmount: +(MONTH_RENT / 4).toFixed(2),
      appliedAmount: sumAmount,
      rentalDetails: {
        ...pliReference?.rentalDetails,
        endDate,
        startDate,
        details: tempDetails,
        // details: getDetailsRentDaily({
        //   details: details,
        //   rent: !checkIfServiceIsHoist(serviceData)
        //     ? forceToNumber(pliData?.rent)
        //     : forceToNumber(pliData?.rentalsTotal),
        //   dailyAmount,
        //   howManyDays,
        //   lastOne:
        //     idx === elevation?.items?.length - 1,
        // }),
      },
    };
    return toReturn;
  } else {
    return pliReference;
  }
};

const getDetailsRentDailyScheduler = ({
  details,
  rent = 0,
  dailyAmount,
  lastOne,
  howManyDays,
  isFullMonth,
  startDate,
  endDate,
  SCHEDULE_DATA,
  serviceData,
  elevationData,
  pliReference,
}) => {
  console.log("getDetailsRentDailyScheduler", { SCHEDULE_DATA, serviceData });
  const WEEKS_AFTER_INST = forceToNumber(
    serviceData?.rentalPaymentTerms?.range
  );
  const MIN_DATE = 0;
  const days = [];
  let applicable =
    SCHEDULE_DATA?.initialDatesProgress?.[serviceData?.serviceId]?.[
      elevationData?.elevationId
    ]?.[pliReference?.id] || [];
  //TODO: ADD logic for removal
  applicable = applicable.filter((el) => el?.typeOfWork !== "Removal");
  console.log("applicable", applicable);
  if (Array.isArray(applicable) && applicable.length > 0) {
    applicable = applicable
      .sort((a, b) => a.startDate - b.startDate)
      .map((el) => {
        console.log("ledjoel", el);
        return {
          startDate:
            el?.typeOfWork === "Installation"
              ? dayjsNY(el.startDate)
                  .add(WEEKS_AFTER_INST, "week")
                  .format("YYYY-MM-DD")
              : dayjsNY(el.startDate).format("YYYY-MM-DD"),
          progress: el.pliProgressPercentage,
        };
      });
    applicable = fillGaps(applicable, endDate);
    console.log("applicable", applicable);
  }

  let howManyWeeks = howManyDays / 7;
  let weeklyAmount = rent / 4;
  let carriedBalance = isFullMonth
    ? dailyAmount * howManyDays
    : howManyWeeks * weeklyAmount;
  const res = details?.map((detailsData, id) => {
    return {
      ...detailsData,
      charged: false,
      //here we set the weekly amount equal to the daily amount times the number of days in this week
      weeklyAmount: rent / 4,
      lock: false,
      applied: false,
      days: detailsData?.days?.map((daysData, idx) => {
        let dayProgress = applicable?.find(
          (el) =>
            el.startDate ===
            dayjsNY(daysData?.day).add(1, "days").format("YYYY-MM-DD")
        );
        console.log("dayProgress", {
          dayProgress,
          applicable,
          test: dayjsNY(daysData?.day).add(1, "days").format("YYYY-MM-DD"),
        });
        if (!dayProgress) {
          let temp = applicable?.filter((el) => el.valDate <= daysData?.day);
          if (temp.length > 0) {
            dayProgress = temp[temp.length - 1];
          } else {
            dayProgress = applicable[applicable.length - 1];
          }
        }
        dayProgress = dayProgress?.progress || 0;
        // console.log("dayProgress", dayProgress);
        const temp = {
          ...daysData,
          applied: false,
          charged: false,
          lock: false,
          progress: dayProgress,
          dailyAmount: (dailyAmount * dayProgress) / 100,
        };
        return temp;
      }),
    };
  });
  return res;
};

const getScheduleDailyAmount = ({ SCHEDULE_DATA, startDate, endDate }) => {};

function fillGaps(datesArray, endDate) {
  console.log("fillGaps", datesArray);

  if (!datesArray || datesArray.length === 0) {
    return []; // Return an empty array if no dates are provided
  }

  let filledArray = [];
  let currentProgress = null;
  let finalEndDate = new Date(endDate); // Known end date

  // If only one date is present, fill until the endDate
  if (datesArray.length === 1) {
    let singleDate = new Date(datesArray[0].startDate);
    currentProgress = datesArray[0].progress;

    // Fill all days from the single start date until the known end date
    while (singleDate <= finalEndDate) {
      filledArray.push({
        startDate: singleDate.toISOString().split("T")[0],
        progress: currentProgress,
        valDate: dayjsNY(singleDate).valueOf(),
      });
      singleDate.setDate(singleDate.getDate() + 1);
    }
    return filledArray;
  }

  // Loop through the datesArray and fill gaps between each date
  for (let i = 0; i < datesArray.length - 1; i++) {
    let startDate = new Date(datesArray[i].startDate);
    let nextDate = new Date(datesArray[i + 1].startDate);
    currentProgress = datesArray[i].progress;

    // Fill all days between startDate and the nextDate (exclusive of nextDate)
    while (startDate < nextDate) {
      filledArray.push({
        startDate: startDate.toISOString().split("T")[0],
        progress: currentProgress,
        valDate: dayjsNY(startDate).valueOf(),
      });
      startDate.setDate(startDate.getDate() + 1);
    }
  }

  // Fill the gap from the last date in datesArray to the provided endDate
  let lastDate = new Date(datesArray[datesArray.length - 1].startDate);
  currentProgress = datesArray[datesArray.length - 1].progress;

  // Fill all days from lastDate to finalEndDate (inclusive)
  while (lastDate <= finalEndDate) {
    filledArray.push({
      startDate: lastDate.toISOString().split("T")[0],
      progress: currentProgress,
      valDate: dayjsNY(lastDate).valueOf(),
    });
    lastDate.setDate(lastDate.getDate() + 1);
  }

  return filledArray;
}
