import { useEffect, useState } from "react";
import { Alert, Modal, message } from "antd";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton } from "src/components/commonComponents";
import { InfoIcon, PlusIcon } from "src/assets";
import { useSelector } from "react-redux";
import { useCreateDriveFolders } from "src/hooks/useCreateDriveFolders";
import { driveApi } from "src/integrations/DriveRequest";
import { _CREATE_HEADERS } from "src/integrations/DriveRequest/driveStatics";
import {
  clientsCases,
  FOLDER_FETCH_URL,
  getParentFolderId,
} from "./addDriveFolderData";
import { API } from "aws-amplify";
import dayjs from "dayjs";

import "./AddDriveFolderModal.scss";
import { RedirectIcon } from "../../../../../../../icons";
import { UpdateIcon } from "../../../../../Documentation/View/components/DocumentationListCard/components/DocWarningsModal";
import { findProp } from "../DrivePermission/utils/utils";

const sameNames = [
  "Claims",
  "Hearings",
  "Property Damage",
  "Vehicle Damage",
  "Personal Injury",
  "Other Trade Incident",
];

const getParentFolderName = (parentFolder, rowData, nameKey) => {
  const name = rowData?.[nameKey] || findProp(rowData, nameKey)?.[0] || "";
  if (parentFolder === "Estimations" || parentFolder === "Permit Drawings") {
    return `${name} ${dayjs(rowData?.createdAt).format("DD-MM-YYYY hh:mm:ss")}`;
  } else if (parentFolder === "Violations") {
    return `${dayjs(name).format("MM/DD/YYYY, hh:mm:ss")}`;
  } else if (sameNames.includes(parentFolder)) {
    return `${name} ${dayjs(rowData?.createdAt).format(
      "MM/DD/YYYY, hh:mm:ss"
    )}`;
  } else {
    return `${name}`;
  }
};

function AddDriveFolderModal({
  open,
  onCancel,
  folderData,
  rowData,
  setDriveFolder,
  driveRequest,
  hasMissingFolders,
  callBack = () => {},
}) {
  const [{ isDarkMode }, { hotCredentials }, { fleetFields = [] }] =
    useSelector((state) => [
      state.darkMode,
      state.hotCredentials,
      state.fleetFields,
    ]);

  const [mainFolderId, setMainFolderId] = useState();
  const [loading, setLoading] = useState(false);
  const [goToLink, setGoToLink] = useState({ visible: false, link: null });

  const {
    folderName: parentFolder,
    nameKey,
    key,
    tableName,
    idKey,
  } = folderData;

  const driveFunctions = useCreateDriveFolders(parentFolder);

  async function handleDriveFolderAction() {
    setLoading(true);
    let response;
    try {
      const driveRes = await driveRequest.getDriveItem(mainFolderId);
      if (driveRes?.ok) {
        const name = getParentFolderName(parentFolder, rowData, nameKey);
        if (hasMissingFolders) {
          response = await driveFunctions.update({
            parentFolderName: name,
            parentId: mainFolderId,
            oldFolders: rowData?.googleDriveFolderIds,
            updateFrom: parentFolder,
          });
        } else {
          response = await driveFunctions.create({
            parentFolderName: name,
            parentId: mainFolderId,
          });
        }
      } else {
        throw new Error("Folder not found");
      }
    } catch (err) {
      response = { error: err };
      console.log("Error creating/updating folder: ", err);
    }

    if (response?.error || !response?.folders) {
      const content = hasMissingFolders
        ? "Error updating folder"
        : "Error creating folder";
      message.error({
        content,
        key: "driveFolders",
        duration: 5,
      });
      setLoading(false);
      console.log("Error creating/updating folder: ", response?.error);
      setGoToLink((prev) => ({ ...prev, visible: true }));
      return;
    }

    API.put(tableName, `/${tableName}/${rowData?.[idKey]}`, {
      body: {
        googleDriveFolderIds: {
          ...response.folders,
        },
      },
    })
      .then((res) => {
        Object.assign(rowData, {
          googleDriveFolderIds: {
            ...response.folders,
          },
        });
        callBack();
      })
      .catch(async (err) => {
        setLoading(false);
        message.error({
          content: "Error updating record",
          key: "driveFolders",
          duration: 5,
        });
        console.log("Error updating record: ", { err });
        !hasMissingFolders && (await driveFunctions.delete());
      });

    setDriveFolder(response?.parentFolder);
    onCancel();
    setLoading(false);
  }

  useEffect(() => {
    getParentFolderId({
      parentFolder,
      rowData,
      hotCredentials,
      fleetFields,
    }).then((res) => {
      const { parentFolderId = "", goTo = "" } = res;
      setMainFolderId(parentFolderId);
      if (parentFolderId) {
        setGoToLink({ visible: false, link: goTo });
      } else {
        setGoToLink({ visible: true, link: goTo });
      }
    });
  }, [parentFolder]);

  return (
    <Modal
      open={open}
      title={"Add Drive Folder Modal"}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      centered={true}
      className={`driveFolderModal ${isDarkMode && "driveFolderModalDark"}`}
      destroyOnClose={true}
      footer={[
        <div key={1}>
          <MondayButton
            className="mondayButtonRed"
            onClick={onCancel}
            Icon={<XIcon />}
          >
            Close
          </MondayButton>
        </div>,
      ]}
    >
      <section className="infoSection">
        <Alert
          showIcon
          message={
            !goToLink.visible && !hasMissingFolders
              ? "This record does not have a drive folder"
              : hasMissingFolders
              ? "This record is missing some drive folders"
              : "There is a problem accessing the drive folder of the parent record."
          }
          type={!goToLink.visible && !hasMissingFolders ? "info" : "warning"}
        />
      </section>
      <section className="addButtonSection">
        <MondayButton
          Icon={hasMissingFolders ? <UpdateIcon /> : <PlusIcon />}
          disabled={!mainFolderId || loading}
          onClick={handleDriveFolderAction}
        >
          {hasMissingFolders
            ? "Update Drive Folders"
            : "Add Drive Folder For This Record"}
        </MondayButton>
        {hasMissingFolders && rowData?.googleDriveFolderIds[key] && (
          <MondayButton
            Icon={<RedirectIcon />}
            onClick={() =>
              window.open(
                `https://drive.google.com/drive/folders/${rowData?.googleDriveFolderIds[key]}`
              )
            }
            className="mondayButtonBlue"
          >
            Go To Drive
          </MondayButton>
        )}
        {goToLink.visible && !!goToLink.link && (
          <MondayButton Icon={<RedirectIcon />}>
            <a
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              href={goToLink.link}
              target="_blank"
              rel="noreferrer"
            >
              Go To Parent Record
            </a>
          </MondayButton>
        )}
      </section>
    </Modal>
  );
}

export default AddDriveFolderModal;
