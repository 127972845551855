export const scheduling = ({ tables, circleProgressPicker }) => {
  return {
    typeOfWork: () => ({
      percent: (
        (tables?.Scheduling?.filter((a) => a?.typeOfWork === circleProgressPicker)?.length /
          tables?.Scheduling?.length) *
        100
      ).toFixed(1),
      data: tables?.Scheduling?.filter((a) => a?.typeOfWork === circleProgressPicker)?.length,
    }),
  } //schaduling
}
