import { read } from "xlsx";
import { v4 as uuidv4 } from "uuid";
import { Upload, message, Form } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState, useContext, useRef, useMemo } from "react";

import {
  degParser,
  addDataToArray,
  matchHrFingerCheckData,
  matchDataFromFingerCheck,
} from "../utils";
import DegModalContext from "../DegModalContext";
import { ExcelIcon } from "../../../../../../../../assets";
import UploadProgress from "../../UploadProgress/UploadProgress";
import { MondayButton } from "../../../../../../../commonComponents";
import { addJobsiteOnIdEntries } from "../utils/addJobsiteOnIdEntries";
import { DownloadIcon } from "../../../../../../../SidebarPages/BasePage/src";
import { TrashIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { useSelector } from "react-redux";

const { Dragger } = Upload;

function UploadStep({ getUploadedData }) {
  const {
    form,
    crews,
    accounts,
    jobsites,
    crewTeams,
    uploading,
    setRowData,
    isDarkMode,
    accountName,
    currentStep,
    setUploading,
  } = useContext(DegModalContext);
  const programFields = useSelector(
    (store) => store.programFields.programFields
  );
  const [uploadInfo, setUploadInfo] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);

  const progressRef = useRef();
  const testDateRange = Form.useWatch("testDateRange", form);

  const clientsConfig = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (el) => el.fieldName === "Payroll Configuration"
      );
      return programFields[index]?.fieldOptions;
    }
    return [];
  }, [programFields]);

  function removeFetchedData(file) {
    if (getUploadedData) {
      getUploadedData({ type: "remove", data: file?.uid });
      setFetchedData((prev) => prev.filter((el) => el?.uid !== file?.uid));
    } else {
      setRowData((prev) => prev.filter((el) => el?.uploadId !== file?.uid));
      setFetchedData((prev) => prev.filter((el) => el?.uid !== file?.uid));
    }
  }

  async function getPayrollDataOnDateRange() {
    setUploading(true);
    message.loading({
      content: "Uploading payroll data...",
      key: "upload",
      duration: 0,
    });
    const selectedClient = clientsConfig.find(
      (el) => el.clientName === accountName
    );

    const modifiedParsedData = await matchDataFromFingerCheck({
      crews,
      jobsites,
      crewTeams,
      selectedClient,
      dateRange: testDateRange,
      progressRef: progressRef.current,
    })
      .then((res) => {
        if (res === "Stopped") {
          message.warning({
            content: "Upload stopped!",
            key: "upload",
            duration: 1.5,
          });
          form.resetFields();
        } else {
          message.success({
            content: "Payroll uploaded successfully!",
            key: "upload",
            duration: 1.5,
          });
        }
        setUploading(false);

        return res;
      })
      .catch((error) => {
        setUploading(false);
        console.log("Add data to array error: ", error);
        message.error({
          content: "There was a problem uploading data!",
          duration: 1.5,
          key: "upload",
        });
      });

    if (modifiedParsedData === "Stopped") {
      return;
    }

    if (getUploadedData) {
      const uploadId = uuidv4();
      let tmpData = addJobsiteOnIdEntries(modifiedParsedData || []).map((el) =>
        Object.assign(el, { uploadId })
      );

      let fileSize = (1021 * tmpData?.length || 0) / 1024;
      let suffix = "KB";
      if (fileSize / 1024 >= 1) {
        fileSize = fileSize / 1024;
        suffix = "MB";
      }

      let tmpUploadInfo = {
        uid: uploadId,
        size: `${fileSize.toFixed(2)} ${suffix}`,
        status: "done",
        name: "FingerCheck Upload",
        rowLength: tmpData?.length,
        columnLength: 12,
      };

      setFetchedData((prev) => [...prev, tmpUploadInfo]);
      getUploadedData({
        type: "add",
        data: tmpData,
      });
    } else {
      const uploadId = uuidv4();
      let tmpData = addJobsiteOnIdEntries(modifiedParsedData).map((el) =>
        Object.assign(el, { uploadId })
      );

      let fileSize = (1021 * tmpData?.length || 0) / 1024;
      let suffix = "KB";
      if (fileSize / 1024 >= 1) {
        fileSize = fileSize / 1024;
        suffix = "MB";
      }

      let tmpUploadInfo = {
        uid: uploadId,
        size: `${fileSize.toFixed(2)} ${suffix}`,
        status: "done",
        name: "FingerCheck Upload",
        rowLength: tmpData?.length,
        columnLength: 12,
      };

      setRowData((prev) => {
        let tmp = [...prev, ...tmpData];
        return tmp;
      });
      setFetchedData((prev) => [...prev, tmpUploadInfo]);
    }
  }

  async function getPayrollHrData() {
    setUploading(true);
    message.loading({
      content: "Uploading HR data...",
      key: "upload",
      duration: 0,
    });
    const selectedClient = clientsConfig.find(el => el.clientName === accountName);
    const modifiedParsedData = await matchHrFingerCheckData({
      crews,
      crewTeams,
      selectedClient,
      dateRange: testDateRange,
      progressRef: progressRef.current,
    })
      .then((res) => {
        if (res === "Stopped") {
          message.warning({
            content: "Upload stopped!",
            key: "upload",
            duration: 1.5,
          });
          form.resetFields();
        } else {
          message.success({
            content: "Payroll uploaded successfully!",
            key: "upload",
            duration: 1.5,
          });
        }
        setUploading(false);

        return res;
      })
      .catch((error) => {
        setUploading(false);
        console.log("Add data to array error: ", error);
        message.error({
          content: "There was a problem uploading data!",
          duration: 1.5,
          key: "upload",
        });
      });

    if (modifiedParsedData === "Stopped") {
      return;
    }
    if (getUploadedData) {
      const uploadId = uuidv4();
      let tmpData = modifiedParsedData.map((el) =>
        Object.assign(el, { uploadId })
      );

      let fileSize = (1021 * tmpData?.length || 0) / 1024;
      let suffix = "KB";
      if (fileSize / 1024 >= 1) {
        fileSize = fileSize / 1024;
        suffix = "MB";
      }

      let tmpUploadInfo = {
        uid: uploadId,
        size: `${fileSize.toFixed(2)} ${suffix}`,
        status: "done",
        name: "FingerCheck Upload",
        rowLength: tmpData?.length,
        columnLength: 12,
      };

      setFetchedData((prev) => [...prev, tmpUploadInfo]);
      getUploadedData({
        type: "add",
        data: tmpData,
      });
    } else {
      const uploadId = uuidv4();
      let tmpData = (modifiedParsedData || []).map((el) =>
        Object.assign(el, { uploadId })
      );

      let fileSize = (1021 * tmpData?.length || 0) / 1024;
      let suffix = "KB";
      if (fileSize / 1024 >= 1) {
        fileSize = fileSize / 1024;
        suffix = "MB";
      }

      setRowData((prev) => {
        let tmp = [...prev, ...(tmpData || [])];
        return tmp;
      });

      let tmpUploadInfo = {
        uid: uploadId,
        size: `${fileSize.toFixed(2)} ${suffix}`,
        status: "done",
        name: "FingerCheck Upload",
        rowLength: tmpData?.length,
        columnLength: 12,
      };

      setFetchedData((prev) => [...prev, tmpUploadInfo]);
    }
  }

  return (
    <div className="upload-step" data-testid="upload-step">
      <Form form={form}>
        <InputComponent
          type="select"
          dataTestid="company-select"
          label={"Chose Company"}
          placeholder="Chose Subcontractor..."
          formItemName={"accountName"}
          dropdownClassName={isDarkMode && "darkDropDown"}
          form={form}
          customOptions={clientsConfig.map(({ clientName }, key) => ({
            key,
            label: clientName,
            value: clientName,
          }))}
        />
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: 10,
          }}
        >
          <InputComponent
            type="rangepicker"
            label={"Date Range"}
            dataTestid={"upload-date-range"}
            onChange={(e) =>
              form.setFieldValue(
                "testDateRange",
                e.map((i) => parseInTz(i))
              )
            }
            dropdownClassName={isDarkMode && "darkDateDropDown"}
            formItemName={"testDateRange"}
          />
          <MondayButton
            className={
              !testDateRange?.length || !accountName || uploading
                ? ""
                : "mondayButtonBlue"
            }
            disabled={!testDateRange?.length || !accountName || uploading}
            onClick={getPayrollDataOnDateRange}
            data-testid="get-payroll-data"
            Icon={<DownloadIcon />}
          >
            Get Payroll on Date Range
          </MondayButton>
          {!!currentStep ? (
            <MondayButton
              className={
                !testDateRange?.length || !accountName || uploading
                  ? ""
                  : "mondayButtonBlue"
              }
              disabled={!testDateRange?.length || !accountName || uploading}
              onClick={getPayrollHrData}
              data-testid="get-hr-payroll-data"
              Icon={<DownloadIcon />}
            >
              Get Only Hr Entries
            </MondayButton>
          ) : null}
        </div>
      </Form>
      <UploadProgress ref={progressRef} />
      <div className="upload-section">
        <Dragger
          {...{
            name: "file",
            accept: ".xlsx",
            multiple: true,
            disabled: !accountName,
            action: "",
            async customRequest({ file, onSuccess, onError }) {
              message.loading({
                content: "Uploading data...",
                duration: 0,
                key: "upload",
              });
              setUploading(true);
              let fileReader = new FileReader();
              fileReader.addEventListener("load", async () => {
                let { Sheets } = read(fileReader.result, {
                  sheets: "Output",
                });
                if (Sheets?.Output) {
                  const { parsedData } = degParser(Sheets.Output, file);

                  const modifiedParsedData = await addDataToArray({
                    crews,
                    jobsites,
                    crewTeams,
                    accountName,
                    array: parsedData,
                    progressRef: progressRef.current,
                  })
                    .then((res) => {
                      if (!!res?.length) {
                        message.success({
                          content: "All data uploaded successfully.",
                          key: "upload",
                        });
                      } else {
                        message.warning({
                          content: "Uploading data have Stopped!.",
                          key: "upload",
                        });
                      }
                      setUploading(false);
                      return res;
                    })
                    .catch((error) => {
                      console.log("Add data to array error: ", error);
                      setUploading(false);
                      message.error({
                        content: "There was a problem uploading data!",
                        key: "upload",
                      });
                    });

                  if (getUploadedData) {
                    let tmp = modifiedParsedData || [];
                    getUploadedData({
                      type: "add",
                      data: addJobsiteOnIdEntries(tmp),
                    });
                  } else {
                    setRowData((prev) => {
                      let tmp = [...prev, ...(modifiedParsedData || [])];
                      return addJobsiteOnIdEntries(tmp);
                    });
                  }

                  let fileSize = file.size / 1024;
                  let suffix = "KB";
                  if (fileSize / 1024 >= 1) {
                    fileSize = fileSize / 1024;
                    suffix = "MB";
                  }
                  let tmpData = {
                    uploadId: file.uid,
                    size: `${fileSize.toFixed(2)} ${suffix}`,
                    name: file.name,
                    rowLength: modifiedParsedData?.length,
                    columnLength: Object.keys(parsedData?.[0] || {}).length,
                  };
                  if (!!tmpData?.rowLength) {
                    setUploadInfo((prev) => [...prev, tmpData]);
                  }

                  return onSuccess();
                } else {
                  message.error({
                    content: "Invalid File!",
                    key: "invalidFileMessage",
                  });
                  return onError();
                }
              });

              fileReader.readAsArrayBuffer(file);
            },
            onRemove(file) {
              if (getUploadedData) {
                getUploadedData({
                  type: "remove",
                  data: file?.uid,
                });
              } else {
                setRowData((prev) =>
                  prev?.filter(({ uploadId }) => uploadId !== file?.uid)
                );
              }
            },
            itemRender(notUsableData, file, notUsableData2, actions) {
              let fileData = uploadInfo?.find(
                ({ uploadId = "" }) => uploadId === file?.uid
              );
              return fileData ? (
                <div
                  data-testid={`uploaded-data-${uploadInfo?.findIndex(
                    ({ uploadId = "" }) => uploadId === file?.uid
                  )}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: isDarkMode ? "#12131B" : "#F8F8FA",
                    borderRadius: 5,
                    marginTop: 5,
                    padding: "0px 5px",
                    gap: 10,
                  }}
                >
                  <div style={isDarkMode ? { color: "#fff" } : {}}>
                    <ExcelIcon />
                    &nbsp;{fileData?.name}
                  </div>
                  <div style={isDarkMode ? { color: "#fff" } : {}}>
                    <b>File Size: &nbsp;</b>
                    {fileData?.size}
                  </div>
                  <div style={isDarkMode ? { color: "#fff" } : {}}>
                    <b>Columns: &nbsp;</b>
                    {fileData?.columnLength}
                  </div>
                  <div style={isDarkMode ? { color: "#fff" } : {}}>
                    <b>Entries: &nbsp;</b>
                    {fileData?.rowLength}
                  </div>
                  <TrashIcon
                    onClick={() => actions.remove(file)}
                    width={16}
                    height={16}
                    fill="#FE4C4A"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : null;
            },
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload{" "}
            <strong>(Files must contain "Output" sheet )</strong>
          </p>
        </Dragger>
        {!!fetchedData?.length &&
          fetchedData.map((fileData, index) => {
            return (
              <div
                data-testid={`uploaded-data-${index}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: isDarkMode ? "#12131B" : "#F8F8FA",
                  borderRadius: 5,
                  marginTop: 5,
                  padding: "0px 5px",
                  gap: 10,
                }}
              >
                <div style={isDarkMode ? { color: "#fff" } : {}}>
                  <ExcelIcon />
                  &nbsp;{fileData?.name}
                </div>
                <div style={isDarkMode ? { color: "#fff" } : {}}>
                  <b>File Size: &nbsp;</b>
                  {fileData?.size}
                </div>
                <div style={isDarkMode ? { color: "#fff" } : {}}>
                  <b>Columns: &nbsp;</b>
                  {fileData?.columnLength}
                </div>
                <div style={isDarkMode ? { color: "#fff" } : {}}>
                  <b>Entries: &nbsp;</b>
                  {fileData?.rowLength}
                </div>
                <TrashIcon
                  onClick={() => removeFetchedData(fileData)}
                  width={16}
                  height={16}
                  fill="#FE4C4A"
                  style={{ cursor: "pointer" }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default UploadStep;
