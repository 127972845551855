import { message } from "antd";
import { checkIfServiceIsHoist } from "../../../../../../../../../../Estimations/DataEntryGrid/models/Service";
import AmountReq from "../../../ServiceContent/GridData/GridUtils/amountFactory";

export const newApplicationHandler = ({
  fakeApplication,
  service,
  retainageState,
}) => ({
  ...fakeApplication,
  services: fakeApplication?.services?.map((serviceData) => {
    return serviceData?.serviceId === service?.serviceId &&
      serviceData?.estimationId === service?.estimationId
      ? {
          ...serviceData,
          retainage: retainageState,
          amounts: serviceData?.amounts?.map((amountsData) => {
            const { fromPrevious, amount, charged, retainagePercentage } =
              amountsData;

            const retainage = (amount * retainageState) / 100;
            const prevRetainage = (amount * retainagePercentage) / 100;
            const isReduced =
              retainagePercentage > retainageState ? true : false;
            const reducedAmount =
              amount * (retainagePercentage / 100) - retainage;

            return !charged
              ? {
                  //event calculations
                  ...amountsData,
                  reducedAmount,
                  reducedBalance: reducedAmount,
                  retainage:
                    amountsData?.percentageCompleated !== 100 ? retainage : 0,
                  collectable_amount: amount - fromPrevious - prevRetainage,
                  retainagePercentage:
                    amountsData?.percentageCompleated !== 100
                      ? retainageState
                      : 0,
                  isReduced,
                  //addons calculations
                  serviceAddons: amountsData?.serviceAddons?.map(
                    (addonsData) => {
                      const { fromPrevious, amount } = addonsData;
                      const retainage = (amount * retainageState) / 100;
                      return {
                        ...addonsData,
                        retainage,
                        collectable_amount:
                          amount - fromPrevious - prevRetainage,
                      };
                    }
                  ),
                  //elevation calculations
                  serviceOptions: amountsData?.serviceOptions?.map(
                    (elevationData) => ({
                      ...elevationData,
                      items: elevationData?.items?.map((pliData) => {
                        const { fromPrevious, amount } = pliData;
                        const retainage = (amount * retainageState) / 100;

                        return {
                          ...pliData,
                          retainage,
                          collectable_amount:
                            amount - fromPrevious - pliData?.retainage || 0,
                        };
                      }),
                    })
                  ),
                }
              : {
                  ...amountsData,
                  isReduced,
                  reducedAmount,
                  reducedBalance: reducedAmount,
                };
          }),
        }
      : serviceData;
  }),
});

export const applyRetainageToAllServices = ({
  fakeApplication,
  writeableObject,
  applications,
  type,
}) => {
  console.log("writeableObject", writeableObject);
  let theseRentals = fakeApplication?.includedRentals || [];
  let previousRentals =
    applications
      ?.filter((app) => app.applicationNo < fakeApplication?.applicationNo)
      ?.map((t) => t?.includedRentals)
      ?.flat() || [];

  //TODO: ADD TYPE
  if (1) {
    let temp = {
      ...fakeApplication,
      services: fakeApplication?.services.map((serviceData, idx) => {
        console.log("serviceData", serviceData);
        if (
          serviceData?.retainage === writeableObject?.[serviceData?.label + idx]
        ) {
          return serviceData;
        }
        let retainageState = writeableObject?.[serviceData?.label + idx] || 0;
        // console.log("ledjoservicedata", );
        return !checkIfServiceIsHoist(serviceData)
          ? {
              ...serviceData,
              retainage: retainageState,
              leftOverRetainage: retainageState,
              amounts: serviceData?.amounts?.map((amountsData) => {
                let myAmount = new AmountReq(amountsData);
                myAmount = myAmount.retainageDeducted(
                  fakeApplication,
                  serviceData,
                  retainageState
                );
                console.log("myAmount", myAmount);
                return {
                  ...myAmount,
                };
              }),
              // amounts: serviceData?.amounts?.map((amountsData) => {
              //   const {
              //     fromPrevious = 0,
              //     thisPeriod = 0,
              //     amount = 0,
              //     charged,
              //     retainagePercentage = 0,
              //     percentageCompleated = 0,
              //   } = amountsData;

              //   const totalCompleted = (amount * percentageCompleated) / 100;
              //   const reducedAmount =
              //     (totalCompleted * (retainagePercentage - retainageState)) /
              //     100;

              //   const retainage = (amount * retainageState) / 100;
              //   const prevRetainage =
              //     ((thisPeriod + fromPrevious) * retainagePercentage) / 100;
              //   const isReduced =
              //     retainagePercentage > retainageState ? true : false;
              //   // const reducedAmount =
              //   //   amount * (retainagePercentage / 100) - prevRetainage;
              //   const keptRetainage =
              //     thisPeriod * ((retainagePercentage - retainageState) / 100);
              //   const paymentDue = thisPeriod - keptRetainage + reducedAmount;
              //   console.log("not hoist reduced", {
              //     retainagePercentage,
              //     retainageState,
              //     reducedAmount,
              //     totalCompleted,
              //     paymentDue,
              //     keptRetainage,
              //     reducedAmount,
              //   });
              //   return !charged
              //     ? {
              //         //event calculations
              //         ...amountsData,
              //         isReduced,
              //         reducedAmount,
              //         paymentDue,
              //         keptRetainage,
              //         reducedBalance: reducedAmount,
              //         retainage,
              //         collectable_amount: amount - fromPrevious - prevRetainage,
              //         retainagePercentage: retainageState,
              //         //addons calculations
              //         // serviceAddons: amountsData?.serviceAddons?.map(
              //         //   (addonsData) => {
              //         //     const { fromPrevious, amount, retainagePercentage } =
              //         //       addonsData;
              //         //     const retainage = (amount * retainageState) / 100;
              //         //     const isReduced =
              //         //       retainagePercentage > retainageState ? true : false;
              //         //     const prevRetainage =
              //         //       (amount * retainagePercentage) / 100;

              //         //     const reducedAmount =
              //         //       amount * (retainagePercentage / 100) - retainage;
              //         //     return {
              //         //       ...addonsData,
              //         //       isReduced,
              //         //       reducedAmount,
              //         //       reducedBalance: reducedAmount,

              //         //       retainage,
              //         //       collectable_amount:
              //         //         amount - fromPrevious - prevRetainage,
              //         //     };
              //         //   }
              //         // ),
              //         // //elevation calculations
              //         // serviceOptions: amountsData?.serviceOptions?.map(
              //         //   (elevationData) => ({
              //         //     ...elevationData,
              //         //     items: elevationData?.items?.map((pliData) => {
              //         //       const {
              //         //         fromPrevious,
              //         //         amount,
              //         //         retainagePercentage,
              //         //       } = pliData;
              //         //       const retainage = (amount * retainageState) / 100;
              //         //       const isReduced =
              //         //         retainagePercentage > retainageState
              //         //           ? true
              //         //           : false;
              //         //       const reducedAmount =
              //         //         amount * (retainagePercentage / 100) - retainage;

              //         //       return {
              //         //         ...pliData,
              //         //         retainage,
              //         //         isReduced,
              //         //         reducedAmount,
              //         //         reducedBalance: reducedAmount,

              //         //         collectable_amount:
              //         //           amount - fromPrevious - pliData?.retainage || 0,
              //         //       };
              //         //     }),
              //         //   })
              //         // ),
              //       }
              //     : { amountsData, reducedBalance: reducedAmount };
              // }),
            }
          : {
              ...serviceData,
              retainage: retainageState,
              serviceOptions: [
                ...serviceData?.serviceOptions?.map((hoistElevatinsArr) => {
                  return [
                    ...hoistElevatinsArr?.map((elevationData) => ({
                      ...elevationData,
                      amounts: elevationData?.amounts?.map((amountsData) => {
                        let myAmount = new AmountReq(amountsData);
                        myAmount = myAmount.retainageDeducted(
                          fakeApplication,
                          serviceData,
                          retainageState
                        );
                        console.log("myAmount", myAmount);
                        return {
                          ...myAmount,
                        };
                      }),
                      // amounts: elevationData?.amounts?.map((eventData) => {
                      //   const {
                      //     fromPrevious,
                      //     amount,
                      //     thisPeriod,
                      //     percentageCompleated,
                      //   } = eventData || {};
                      //   console.log("amountsData", serviceData);
                      //   const retainagePercentage = serviceData?.retainage || 0;

                      //   const prevRetainage =
                      //     ((thisPeriod + fromPrevious) * retainagePercentage) / 100;
                      //   const tempretainage = (amount * retainageState) / 100;
                      //   const collectable_amount =
                      //     amount - fromPrevious - prevRetainage;
                      //   const isReduced =
                      //     retainagePercentage > retainageState ? true : false;
                      //   // const reducedAmount =
                      //   //   amount * (retainagePercentage / 100) - prevRetainage;
                      //   // const totalCompleted = fromPrevious + thisPeriod;
                      //   // const reducedAmount = (totalCompleted * retainageState) / 100;
                      //   const totalCompleted =
                      //     (amount * percentageCompleated) / 100;
                      //   const reducedAmount =
                      //     (totalCompleted *
                      //       (retainagePercentage - retainageState)) /
                      //     100;
                      //   console.log("not hoist reduced", reducedAmount);
                      //   const keptRetainage =
                      //     thisPeriod *
                      //     ((retainagePercentage - retainageState) / 100);

                      //   const retainage = (amount * retainageState) / 100;
                      //   const paymentDue =
                      //     thisPeriod - keptRetainage + reducedAmount;
                      //   return {
                      //     ...eventData,
                      //     keptRetainage,
                      //     retainage: tempretainage,
                      //     collectable_amount,
                      //     paymentDue,
                      //     isReduced,
                      //     reducedAmount,
                      //     reducedBalance: reducedAmount,
                      //   };
                      // }),
                    })),
                  ];
                }),
              ],
            };
      }),
      includedRentals: fakeApplication?.includedRentals?.map((rentalData) => {
        let retainage =
          writeableObject[`Rental${rentalData?.rentalNumber}`] || 0;
        return {
          ...rentalData,
          services: rentalData?.services?.map((serviceData) => ({
            ...serviceData,
            retainage,
          })),
        };
      }),
    };
    let id = 0;

    Object.entries(writeableObject).forEach(([key, value]) => {
      if (id === 0) {
        temp.prevRentalsRetainage = value;
        temp.totalities["prevRentalsRetainage"] = processPrevRentals(
          previousRentals,
          value
        );

        id++;
      }
    });
    return temp;
  } else {
    return increaseRetainage({
      fakeApplication,
      writeableObject,
      previousRentals,
    });
  }
};

const processPrevRentals = (prevRentals = [], retainageState) => {
  let temp = {};
  prevRentals?.forEach((rentalData) => {
    temp[`Rental${rentalData?.rentalNumber}`] = retainageState;
  });
  return temp;
};

const increaseRetainage = ({
  fakeApplication,
  writeableObject,
  previousRentals,
}) => {
  let temp = {
    ...fakeApplication,
    services: fakeApplication?.services?.map((serviceData, idx) => {
      let retainageState = writeableObject?.[serviceData?.label + idx] || 0;
      if (serviceData?.retainage === retainageState) {
        message.info("Retainage is already applied to this service");
        return serviceData;
      } else
        return !checkIfServiceIsHoist(serviceData)
          ? {
              ...serviceData,
              retainage: retainageState,
              amounts: serviceData?.amounts?.map((amountsData) => {
                const {
                  fromPrevious = 0,
                  thisPeriod = 0,
                  amount = 0,
                  charged,
                  retainagePercentage = 0,
                  percentageCompleated = 0,
                  totalCompleated = 0,
                } = amountsData;

                console.log("amountsData", amountsData);
                const totalCompleted = (amount * percentageCompleated) / 100;

                const calculateAmountsDataRet = ({ amountsData }) => {
                  const {
                    fromPrevious = 0,
                    thisPeriod = 0,
                    amount = 0,
                    charged,
                    retainagePercentage = 0,
                    percentageCompleated = 0,
                    totalCompleated = 0,
                    reducedAmount = 0,
                    amountWithNoRetainage = 0,
                  } = amountsData;
                  //We have three cases.
                  // CASE 1: We have completed amount from the past - no retaiange needed.
                  // CASE 2: Some amount is completed, need retainge for the rest
                  // CASE 3: No amount is completed, need retainage for the whole amount
                  // Formula Amount (totalSOV) - totalCompleted * (retainageState/100)
                  //CASE1
                  if (percentageCompleated === 100) {
                    let hundred_reduced = amount * (retainageState / 100);
                    return {
                      keptRetainage: 0,
                      retainage: 0,
                      prevRetainage: 0,
                      isReduced: false,
                      reducedAmount: 0,
                      paymentDue: 0,
                      amountWithNoRetainage: totalCompleated,
                    };
                  } else if (percentageCompleated >= 0) {
                    let tempReduced =
                      (amount - totalCompleated) * (retainageState / 100);
                    message.info(amount - totalCompleated);
                    return {
                      reducedAmount: 0,
                      retainage: tempReduced,
                      prevRetainage: 0,
                      isReduced: false,
                      keptRetainage: 0,
                      paymentDue: 0,
                      amountWithNoRetainage: totalCompleated,
                    };
                  }
                };

                const {
                  reducedAmount = 0,
                  retainage = 0,
                  prevRetainage = 0,
                  isReduced = false,
                  keptRetainage = 0,
                  paymentDue = 0,
                  amountWithNoRetainage = 0,
                } = calculateAmountsDataRet({ amountsData });

                // let reducedAmount =
                //   (totalCompleted * (retainagePercentage - retainageState)) /
                //   100;

                // let retainage = (amount * retainageState) / 100;
                // let prevRetainage =
                //   ((thisPeriod + fromPrevious) * retainagePercentage) / 100;
                // let isReduced = false;
                // // const reducedAmount =
                // //   amount * (retainagePercentage / 100) - prevRetainage;
                // let keptRetainage =
                //   thisPeriod * ((retainagePercentage - retainageState) / 100);
                // let paymentDue = thisPeriod - keptRetainage + reducedAmount;
                console.log("not hoist reduced", {
                  retainagePercentage,
                  retainageState,
                  reducedAmount,
                  totalCompleted,
                  paymentDue,
                  keptRetainage,
                  reducedAmount,
                });
                return !charged
                  ? {
                      //event calculations
                      ...amountsData,
                      isReduced,
                      reducedAmount,
                      paymentDue,
                      keptRetainage,
                      amountWithNoRetainage,
                      reducedBalance: reducedAmount,
                      retainage: retainage,
                      collectable_amount: amount - fromPrevious - prevRetainage,
                      retainagePercentage: retainageState,
                      //addons calculations
                      serviceAddons: amountsData?.serviceAddons?.map(
                        (addonsData) => {
                          const { fromPrevious, amount, retainagePercentage } =
                            addonsData;
                          const retainage = (amount * retainageState) / 100;
                          const isReduced =
                            retainagePercentage > retainageState ? true : false;
                          const prevRetainage =
                            (amount * retainagePercentage) / 100;

                          const reducedAmount =
                            amount * (retainagePercentage / 100) - retainage;
                          return {
                            ...addonsData,
                            isReduced,
                            reducedAmount,
                            reducedBalance: reducedAmount,

                            retainage,
                            collectable_amount:
                              amount - fromPrevious - prevRetainage,
                          };
                        }
                      ),
                      //elevation calculations
                      serviceOptions: amountsData?.serviceOptions?.map(
                        (elevationData) => ({
                          ...elevationData,
                          items: elevationData?.items?.map((pliData) => {
                            const {
                              fromPrevious,
                              amount,
                              retainagePercentage,
                            } = pliData;
                            const retainage = (amount * retainageState) / 100;
                            const isReduced =
                              retainagePercentage > retainageState
                                ? true
                                : false;
                            const reducedAmount =
                              amount * (retainagePercentage / 100) - retainage;

                            return {
                              ...pliData,
                              retainage,
                              isReduced,
                              reducedAmount,
                              reducedBalance: reducedAmount,

                              collectable_amount:
                                amount - fromPrevious - pliData?.retainage || 0,
                            };
                          }),
                        })
                      ),
                    }
                  : { amountsData, reducedBalance: reducedAmount };
              }),
            }
          : {
              ...serviceData,
              retainage: retainageState,
              serviceOptions: [
                serviceData?.serviceOptions[0]?.map((elevationData) => ({
                  ...elevationData,
                  amounts: elevationData?.amounts?.map((eventData) => {
                    const {
                      fromPrevious,
                      amount,
                      thisPeriod,
                      percentageCompleated,
                    } = eventData || {};
                    console.log("amountsData", serviceData);
                    const retainagePercentage = serviceData?.retainage || 0;

                    const prevRetainage =
                      ((thisPeriod + fromPrevious) * retainagePercentage) / 100;
                    const tempretainage = (amount * retainageState) / 100;
                    const collectable_amount =
                      amount - fromPrevious - prevRetainage;
                    const isReduced =
                      retainagePercentage > retainageState ? true : false;
                    // const reducedAmount =
                    //   amount * (retainagePercentage / 100) - prevRetainage;
                    // const totalCompleted = fromPrevious + thisPeriod;
                    // const reducedAmount = (totalCompleted * retainageState) / 100;
                    const totalCompleted =
                      (amount * percentageCompleated) / 100;
                    const reducedAmount =
                      (totalCompleted *
                        (retainagePercentage - retainageState)) /
                      100;
                    console.log("not hoist reduced", reducedAmount);
                    const keptRetainage =
                      thisPeriod *
                      ((retainagePercentage - retainageState) / 100);

                    const retainage = (amount * retainageState) / 100;
                    const paymentDue =
                      thisPeriod - keptRetainage + reducedAmount;
                    return {
                      ...eventData,
                      keptRetainage,
                      retainage:
                        eventData?.percentageCompleated !== 100
                          ? tempretainage
                          : 0,
                      collectable_amount,
                      paymentDue,
                      isReduced,
                      reducedAmount,
                      reducedBalance: reducedAmount,
                    };
                  }),
                })),
              ],
            };
    }),
    // TODO: Check Rentals!
    includedRentals: fakeApplication?.includedRentals?.map((rentalData) => {
      let retainage = writeableObject[`Rental${rentalData?.rentalNumber}`] || 0;
      return {
        ...rentalData,
        services: rentalData?.services?.map((serviceData) => ({
          ...serviceData,
          retainage,
        })),
      };
    }),
  };
  let id = 0;

  Object.entries(writeableObject).forEach(([key, value]) => {
    if (id === 0) {
      temp.prevRentalsRetainage = value;
      temp.totalities["prevRentalsRetainage"] = processPrevRentals(
        previousRentals,
        value
      );

      id++;
    }
  });
  return temp;
};
