import { Form, Modal } from "antd";
import { useCallback, useMemo, useState } from "react";

import { PlusIcon } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import ChartCreationForm from "./chartModalComponents/ChartCreationForm";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { getRandomColor } from "../../../../../../Header/forms/Scheduling/helpers/creators";
import LayoutChart from "../../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportsGridLayout/components/LayoutChart/LayoutChart";

import "./ChartCardModal.scss";
import { useSelector } from "react-redux";

function ChartCardModal({
  open,
  layout,
  onCancel,
  dataSets,
  setLayout,
  setChanges,
  selectedDataOptions,
}) {
  const darkMode = useSelector((store) => store.darkMode.isDarkMode);
  const [title, setTitle] = useState("");

  const [form] = Form.useForm();
  const datasets = Form.useWatch("datasets", form);
  const chartMode = Form.useWatch("chartMode", form);
  const showDataFor = Form.useWatch("showDataFor", form);

  const displayedChart = useMemo(() => {
    const labels =
      showDataFor === "Employees"
        ? dataSets?.employeesLabels
        : showDataFor === "Jobsites"
        ? dataSets?.jobsiteLabels
        : showDataFor === "Teams"
        ? dataSets?.teamLabels
        : [];

    const borderColors = labels.map(() => getRandomColor());
    const backgroundColors = borderColors.map((color) => color + "66");

    const chartDatasets = (datasets || []).flatMap((dataset) => {
      if (!!dataset?.data) {
        return {
          label: `${title} ${dataset?.data}`,
          type: getChartType(dataset?.chartType),
          borderColor: borderColors,
          backgroundColor: backgroundColors,
          data: selectedDataOptions?.[showDataFor]?.[dataset?.data],
          borderWidth: 2,
        };
      } else {
        return [];
      }
    });

    return LayoutChart?.[datasets?.[0]?.chartType || "Line"]({
      chartData: { labels, datasets: chartDatasets },
    });
  }, [showDataFor, datasets, title, JSON.stringify(dataSets), chartMode]);

  const getActualContainerWidth = useCallback(() => {
    const cardBody = document.getElementById(`chartContainer`);
    const cardBodyWidth = cardBody?.clientWidth * 0.7 - 10;
    const totalsContainer = { clientHeight: 0, clientWidth: 0 };

    if (
      datasets?.[0]?.chartType === "Pie" ||
      datasets?.[0]?.chartType === "Doughnut"
    ) {
      if (cardBody && totalsContainer) {
        // let width = cardBodyWidth;
        // let chartAvailableHeight =
        //   cardBody.clientHeight - totalsContainer.clientHeight;

        // if (width > chartAvailableHeight) {
        //   return chartAvailableHeight - 20;
        // }
        return cardBody?.clientWidth || 0;
      }
    } else {
      if (cardBody && totalsContainer) {
        const containerWidth = cardBodyWidth;
        return containerWidth;
      }
    }

    return undefined;
  }, [datasets]);

  function getChartType(type) {
    if (!type) {
      return "line";
    }
    if (
      type.toLowerCase().includes("horizontal") ||
      type.toLowerCase().includes("vertical")
    ) {
      return "bar";
    }
    if (type.toLowerCase().includes("line")) {
      return "line";
    }
    if (type.toLowerCase().includes("pie")) {
      return "pie";
    }
    if (type.toLowerCase().includes("doughnut")) {
      return "doughnut";
    }
  }

  function addChartCard() {
    form.validateFields().then(() => {
      const lastLayoutData = layout?.[layout?.length - 1];
      let y = 0;
      let x = 0;

      const labels =
        showDataFor === "Employees"
          ? dataSets?.employeesLabels
          : showDataFor === "Jobsites"
          ? dataSets?.jobsiteLabels
          : showDataFor === "Teams"
          ? dataSets?.teamLabels
          : [];

      const borderColors = labels.map(() => getRandomColor());
      const backgroundColors = borderColors.map((color) => color + "66");

      const chartDatasets = (datasets || []).flatMap((dataset) => {
        if (!!dataset?.data) {
          return {
            label: `${title} ${dataset?.data}`,
            type: getChartType(dataset?.chartType),
            borderColor: borderColors,
            backgroundColor: backgroundColors,
            data: selectedDataOptions?.[showDataFor]?.[dataset?.data],
            labels,
            showDataFor,
            selectedDataOption: dataset?.data,
            borderWidth: 2,
          };
        } else {
          return [];
        }
      });

      if (layout?.length % 2 === 0) {
        y = (lastLayoutData?.y || 0) + 13;
      } else {
        y = lastLayoutData?.y || 0;
        x = 6;
      }

      const newLayout = {
        cardKey: title,
        chartData: {
          labels,
          datasets: chartDatasets,
          showDataFor,
          chartType: datasets?.[0]?.chartType || "Line",
        },
        h: 13,
        w: 6,
        i: title,
        minH: 10,
        minW: 3,
        moved: false,
        static: false,
        title,
        x,
        y,
      };

      setLayout((prev) => [...prev, newLayout]);
      setChanges(true);
      onCancel();
    });
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        title: "Chart Card Generator",
        setVisible: onCancel,
        destroyOnClose: true,
        className: `chartCardModal ${darkMode && "chartCardModalDark"}`,
        closable: true,
        centered: true,
        closeIcon: <XIcon />,
        ["data-testid"]: "chart-card-modal",
        footer: [
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            key="cancel-btn"
            onClick={onCancel}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            Icon={<PlusIcon width={20} height={20} />}
            key="create-btn"
            onClick={addChartCard}
          >
            Create Card
          </MondayButton>,
        ],
      }}
    >
      <section
        className="chart-creation-container"
        id="chartContainer"
        style={{
          display: "flex",
        }}
      >
        <ChartCreationForm form={form} setTitle={setTitle} />
        <section
          className="chart-overview-container"
          style={{
            width: getActualContainerWidth(),
            scale:
              displayedChart?.props?.type === "Pie" ||
              displayedChart?.props?.type === "Doughnut"
                ? "0.7"
                : "1",
          }}
        >
          {displayedChart}
        </section>
      </section>
    </Modal>
  );
}

export default ChartCardModal;
