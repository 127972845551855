import { Button } from "antd";
import { dayjsNY } from "../../../../../../../../../DateComponents/contants/DayjsNY";

const DATE_FORMAT = "MM/DD/YYYY";

function driverColumns({ dispatches }) {
  return [
    {
      headerName: "Driver Name",
      field: "driverName",
      filter: "agTextColumnFilter",
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    {
      headerName: "Dispatches Info",
      valueGetter: ({ data }) => {
        let dispatchesInvolvingDriver = dispatches?.filter(({ routes }) =>
          routes?.some(({ driverId }) => driverId === data?.driverId)
        );
        if (dispatchesInvolvingDriver?.length) {
          return `Show Dispatches (${dispatchesInvolvingDriver?.length})`;
        } else {
          return "No Dispatches To Show";
        }
      },
      cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        display: "grid",
        placeItems: "center",
      },
    },
    {
      headerName: "Dl Expiration Date",
      field: "DlExpirationDate",
      cellRenderer({ value }) {
        if (!value) {
          return <strong style={{ color: "red" }}>Missing Doc.</strong>;
        }
        return (
          <strong style={{ color: "#323338" }}>
            {dayjsNY(value).format(DATE_FORMAT)}
          </strong>
        );
      },
    },
    {
      headerName: "Mc Expiration Date",
      field: "McExpirationDate",
      cellRenderer({ value }) {
        if (!value) {
          return <strong style={{ color: "red" }}>Missing Doc.</strong>;
        }
        return (
          <strong style={{ color: "#323338" }}>
            {dayjsNY(value).format(DATE_FORMAT)}
          </strong>
        );
      },
    },
  ];
}

export default driverColumns;
