import { ProgressStatistics } from "../../../../SidebarPages/Scheduling/helpers/totals";
import { get_item_Scheduled } from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { DayStatus } from "../Subcomponents/DayStatusChangeModal/DayStatuses";
import { breakdown_dimension } from "../PLIPerService/componentsForPli/Breakdown/breakdown";

/**
 * This file will get formations from project line items. similar to PLI-setters-getter file but this usually receives more complex data
 * */

//items that are scheduled
export const selectedItems = (items) => {
  return Array.isArray(items) && items?.length !== 0
    ? items?.filter((item) => item?.progressByDay?.length > 0)
    : [];
};

//items that are scheduled and completed in progress
export const completedItems = (items, { d1, d2 }) => {
  return Array.isArray(items) && !!items?.length
    ? items?.filter(
        (item) =>
          item?.progressByDay?.reduce(
            (a, b) => a + b?.[breakdown_dimension],
            0
          ) === 100
      )
    : [];
};

export const completedBreakdowns = (items) => {
  return Array.isArray(items) && items?.length !== 0
    ? items?.filter((item) => item?.breakdown_dimension === item?.totalProgress)
    : [];
};

//a function that will set total progress of item based on completion of breakdowns
export const estimatedBreakdownsProgress = (pli, breakdowns, { d1, d2 }) => {
  const scheduled = get_item_Scheduled(pli, { d1, d2 });

  let completion = 0;

  // const selectedBreakdowns = selectedItems(breakdowns)

  // const selectedBreakdownsTotalRate = getTotalRate(selectedBreakdowns)
  let areAllCompleted = true;
  for (const breakdown of breakdowns) {
    completion += ((breakdown["totalProgress"] || 0) * breakdown?.rate) / 100;

    if (
      parseInt(breakdown["totalProgress"]) !==
      parseInt(breakdown[breakdown_dimension])
    ) {
      areAllCompleted = false;
    }
  }

  // console.log(breakdowns)

  if (areAllCompleted && breakdowns?.length > 0) {
    //if all are completed no matter the rate we have 100% completion
    return scheduled;
  }
  return Math.round((scheduled * completion) / 100);
};

const getTotalRate = (breakdowns) => {
  let totalRate = 0;
  for (const breakdown of breakdowns) {
    totalRate += breakdown.rate;
  }

  return totalRate;
};

export const isDayDisabled = (dayId, scheduleDays) => {
  const dayStatus = scheduleDays?.find((sch) => sch?.id === dayId)?.status;
  return dayStatus === DayStatus.Canceled || dayStatus === DayStatus.Postponed;
};

/**
 * gets a list of items and checks if all of them some properties equal.
 * @param items {Object[]} - a list of items (pli, addon,etc)
 * @param keysToCompare {string[]} - what properties you want to check?
 * */
export const areItemsIdentical = (items, keysToCompare) => {
  let item_sample;
  let areAllTheSame = true;

  for (let i = 0; i < items.length; i++) {
    if (i === 0) {
      item_sample = items[i];
    } else {
      for (const key of keysToCompare) {
        if (
          JSON.stringify(item_sample[key]) !== JSON.stringify(items[i][key])
        ) {
          areAllTheSame = false;
          break;
        }
      }
      if (areAllTheSame === false) break;
    }
  }

  return areAllTheSame;
};
