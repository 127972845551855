import "./ToDoComment.scss";
import { Form, message } from "antd";
import { API } from "aws-amplify";
import { CommentsContainer } from "../../../../commonComponents/Comment";

const ToDoComment = ({ form, setChanges, todoId, setRowData }) => {
  const formComments = Form.useWatch("comments", form) || [];
  const title = Form.useWatch("title", form) || [];

  const commentsChanged = (comments) => {
    setChanges(true);

    // if editing
    if (!!todoId) {
      API.put("todos", `/todos/${todoId}`, {
        body: { comments },
      })
        .then(() => {
          message.success("Comments Updated Successfully");

          form.setFieldValue("comments", comments);

          setRowData((prev) =>
            prev.map((todo) =>
              todo.todoId === todoId ? { ...todo, comments } : todo
            )
          );
        })
        .catch((err) => console.log({ err }));
    } else {
      form.setFieldValue("comments", comments);
    }
  };

  return (
    <div className="todo-comment">
      <CommentsContainer
        {...{
          category: "ToDo's",
          recordName: title,
          comments: formComments,
          commentsChanged,
        }}
      />
    </div>
  );
};

export default ToDoComment;
