import { useSelector } from "react-redux";

import { useRedux } from "../../../hooks";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets";
import InputComponent from "../../../Fleet/components/InputComponent/InputComponent";

import "./ItemSelect.scss";

const ServiceSelect = ({ value, data }) => {
  const [isWritable] = useRedux("billIsWritable");

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { billData, services } = useInitialBillContext();
  const { setRowData } = useCreateBillContext();

  const options = services.map((service) => ({
    ...services,
    label: service.serviceName,
    value: service.serviceName,
    id: service.serviceId,
  }));

  const handleSelect = (value) => {
    setRowData((prev) => {
      return prev.map((row) => {
        if (row.itemId === data.itemId) {
          return {
            ...row,
            itemService: value,
          };
        }
        return row;
      });
    });
  };

  const handleClear = () => {
    setRowData((prev) => {
      return prev.map((row) => {
        if (row.itemId === data.itemId) {
          return {
            ...row,
            itemService: "",
          };
        }
        return row;
      });
    });
  };

  return (
    <InputComponent
      type="select"
      suffixIcon={<DropDownArrow />}
      defaultValue={value}
      className="bill-item-select"
      disabled={!!billData && !isWritable}
      name="itemService"
      showSearch
      placeholder="Select service"
      optionFilterProp="children"
      popupClassName={isDarkMode && "darkDropDown"}
      onSelect={handleSelect}
      customOptions={options}
      onClear={handleClear}
    />
  );
};

export default ServiceSelect;
