import { message, notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import { callGmailAPI, updateTable } from "../../../functions";
import {
  addEmails,
  deleteDraftById,
} from "../../../../../../actions/communicationActions";

export const handleSendEmail = async (
  uploadedFiles,
  driveDocuments,
  selectedDocTypes,
  driveRequest,
  hotCredentials,
  authData,
  withSignature,
  signature,
  coreStorageSignature,
  userConfiguration,
  onClose,
  form,
  email,
  editorContent,
  isDarkMode
) => {
  const { documents, attachmentsWithoutDrive } = await processAttachments(
    uploadedFiles,
    driveDocuments,
    selectedDocTypes,
    driveRequest,
    hotCredentials
  );

  const finalSignature = getSignature(
    withSignature,
    signature,
    coreStorageSignature,
    userConfiguration,
    authData
  );

  let undoSend = false;
  let undoTimer = 5;

  notification?.info({
    description: (
      <div className="description">
        Message Sent
        <span
          className="undo"
          onClick={() => {
            undoSend = true;
            notification.destroy();
          }}
          data-testid="notification-info"
        >
          Undo
        </span>
      </div>
    ),
    placement: "bottomLeft",
    className: `undo_notification undo_notification_communication ${
      isDarkMode && "undo_notification_dark"
    }`,
    bottom: 0,
    key: uuidv4(),
    duration: undoTimer,
  });

  onClose();

  const emailToSend = {
    ...form.getFieldValue(),
    from: email,
    body: editorContent + documents + `<span>${finalSignature || ""}</span>`,
    attachments: attachmentsWithoutDrive,
    category: window.location.pathname,
    authData,
  };

  for (let timePassed = 0; timePassed < undoTimer; timePassed++) {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (!undoSend && timePassed === undoTimer - 1) {
      try {
        const response = await callGmailAPI("sendEmail", emailToSend);
        return response;
      } catch (error) {
        console.error("Error sending email:", error);
        throw error;
      }
    } else if (undoSend) {
      message.info("Email was not sent.");
      throw new Error("Email was cancelled");
    }
  }
};

export const saveEmail = async (
  e,
  draftId,
  recordName,
  recordId,
  rest,
  authData,
  isFromCharge,
  drafts,
  dispatch,
  onClose,
  handleGenerateNewLog,
  addEmails
) => {
  const draftIdToDelete = draftId;

  const handleError = (error) => {
    message.error("An error occurred while handling Save Email");
    console.error("Error while API Patch on saveEmail EmailBox", error);
  };

  if (e?.data) {
    try {
      const { data } = e;
      await updateEmailsInTable(data, recordName, recordId, rest);
      await removeDraftAndHandleResponse(
        draftIdToDelete,
        authData,
        isFromCharge,
        recordName,
        recordId,
        drafts,
        dispatch,
        onClose
      );
      handleSuccess(data, handleGenerateNewLog, dispatch);
      return "success";
    } catch (error) {
      handleError(error);
    }
  } else {
    handleNoData();
  }
};

export const updateEmailsInTable = async (data, recordName, recordId, rest) => {
  const tableName =
    recordName === "permitdrawings" ? "permitDrawings" : recordName;
  try {
    await API.put(tableName, `/${tableName}/${recordId}`, {
      body: {
        emails: [
          { id: data.id, threadId: data.threadId },
          ...(rest?.emails || []),
        ],
        emailMessagesIds: [data.emailId, ...(rest?.emailMessagesIds || [])],
      },
    });
  } catch (error) {
    throw new Error("Error while updating emails in the table");
  }
};

export const removeDraftAndHandleResponse = async (
  draftIdToDelete,
  authData,
  isFromCharge,
  recordName,
  recordId,
  drafts,
  dispatch,
  onClose
) => {
  try {
    const res = await callGmailAPI("removeDraft", {
      id: draftIdToDelete,
      authData,
    });

    if (!isFromCharge && res.data && res.status === 200) {
      try {
        await updateTable(
          recordName === "permitdrawings" ? "permitDrawings" : recordName,
          recordId,
          "drafts",
          drafts?.filter(({ draftId: id }) => id !== draftIdToDelete)
        );

        dispatch(deleteDraftById(draftIdToDelete));
        onClose(true);
      } catch (error) {
        console.error("error while updating drafts table");
      }
    } else {
      console.error(
        "Error updating drafts table. Reasons: isFromCharge was true, response did not include data or data status was not 200"
      );
    }
  } catch (error) {
    console.error("Error while removing draft:", error);
    throw new Error("Error while removing draft");
  }
};

export const handleSuccess = (data, handleGenerateNewLog, dispatch) => {
  message.success("Email sent");

  handleGenerateNewLog(
    "Create",
    "Sent",
    "Does not exist",
    "Email sent",
    "Communication"
  );

  dispatch(addEmails([data]));

  handleGenerateNewLog(
    "Delete",
    "Delete",
    "Does not exist",
    "Draft deleted",
    "Communication"
  );
};

export const handleNoData = () => {
  message.warning("Error while saving email");
};

export const processAttachments = async (
  uploadedFiles,
  driveDocuments,
  selectedDocTypes,
  driveRequest,
  hotCredentials
) => {
  const filteredAttachments = [
    ...uploadedFiles,
    ...driveDocuments,
    ...selectedDocTypes,
  ].filter((attachment) => attachment.blob);

  const { attachmentsWithoutDrive, attachmentsWithDrive, size } =
    separateAttachmentsBySize(filteredAttachments);

  const links = await uploadFilesToDrive(
    attachmentsWithDrive,
    driveRequest,
    hotCredentials
  );

  const documents = await getDocumentLinks(links);

  return { documents, attachmentsWithoutDrive, attachmentsWithDrive };
};

export const separateAttachmentsBySize = (attachments) => {
  let size = 0;
  let attachmentsWithDrive = [];
  let attachmentsWithoutDrive = [];

  attachments.forEach((e) => {
    if (size < 36500000) {
      if (e.size + size < 36500000) {
        size = size + e.size;
        attachmentsWithoutDrive.push(e);
      } else {
        attachmentsWithDrive.push(e);
      }
    } else {
      attachmentsWithDrive.push(e);
    }
  });

  return {
    attachmentsWithoutDrive,
    attachmentsWithDrive,
    size,
  };
};

export const uploadFilesToDrive = async (
  attachments,
  driveRequest,
  hotCredentials
) => {
  const links = await Promise.all(
    attachments.map(async (file) => {
      try {
        const res = await driveRequest.uploadFile(
          file.blob.split(",")[1],
          file.type,
          {
            name: file.name,
            mimeType: file.type,
            writersCanShare: true,
            parents: [
              hotCredentials.find(
                ({ credentialKey }) => credentialKey === "GMAIL ATTACHMENTS"
              ).credentialValue,
            ],
          },
          true
        );

        const { id, name } = await res.json();
        await driveRequest.share(id);
        return { id, name };
      } catch (error) {
        return error;
      }
    })
  );

  return links;
};

const getDocumentLinks = async (links) => {
  return links
    .map(
      ({ id, name }) =>
        ` <a href="https://drive.google.com/file/d/${id}/view">${name}</a><br/>`
    )
    .join("");
};

export const getSignature = (
  withSignature,
  signature,
  coreStorageSignature,
  userConfiguration,
  authData
) => {
  if (withSignature) {
    return authData === false
      ? coreStorageSignature.keySignature || signature
      : userConfiguration?.keySignature || signature;
  }
  return "";
};
