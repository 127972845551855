import React, { useState } from "react";
import { Button, Checkbox, message } from "antd";
import { checkIfRowHasValidData } from "../../../tools/formatters/validators";
import { useRedux } from "../../../../../hooks/useRedux";
import { LockFilled, UnlockOutlined } from "@ant-design/icons";
import { MondayButton } from "../../../../../../commonComponents";

function CheckerRender(props) {
  const [checked, setChecked] = useState(
    props.node.data?.[props.column.colId] || false
  );
  const [isWritable] = useRedux("estimationsIsWritable");
  const { handleCheckboxElementChange = () => {} } = props;

  const handleCheck = (e) => {
    if (isWritable) {
      if (props.column.colId === "approved") {
        //if we are in approved renderer, check if row has all needed data

        if (props.pliApproved === undefined) {
          //if we are not is sidewalkshed
          if (!checkIfRowHasValidData(props.node.data)) {
            //if row doesn't have all needed data
            message.info("Row can't be approved if there is any invalid data");
            return;
          }
        } else {
          //if we are in sidewalk-shed, we have passed pliApproved property
          if (props.pliApproved === false) {
            message.info("Addon can't be approved if PLI is not approved");
            return;
          }
        }
      }

      setChecked(e.target.checked);
      props.node.setDataValue(props.column.colId, e.target.checked);
      // this.props.api.redrawRows({rowNodes: [this.props.node]})
      handleCheckboxElementChange(e, e.target.value);
      props.api.refreshCells({ rowNodes: [props.node] });
      if (props.column.colId === "approved") {
        props.api.refreshHeader();

        //if we are in sidewalkshed
        const detail_api = props.api.getDetailGridInfo()?.api;
        if (detail_api) {
          if (e.target.checked === false) {
            //upapprove all addons

            if (detail_api.getColumnDef(props.column.colId)) {
              detail_api.forEachNode((n) => {
                n.setDataValue(props.column.colId, false);
              });
              detail_api.refreshCells();
            }
          }
        }
        handleCheckboxElementChange(e, e.target.value);
      }
    } else {
      message.error("Please enable write mode to start editing.");
    }
  };

  return (
    <div>
      {props.node.group ? (
        <span>--</span>
      ) : props.column.colId === "lock" ? (
        <MondayButton
          // className={"checkboxRenderer-" + props.column.colId + "-" + checked}
          className={checked ? "mondayButtonRed" : "mondayButtonGreen"}
          hasIcon={false}
          onClick={(e) => {
            handleCheck({ target: { checked: !checked } });
          }}
        >
          {checked ? <LockFilled /> : <UnlockOutlined />}
        </MondayButton>
      ) : (
        <Checkbox
          className={"checkboxRenderer-" + props.column.colId}
          onChange={handleCheck}
          checked={checked}
        />
      )}
    </div>
  );
}

export default CheckerRender;
