import { generateWeeks } from "../../../../../../../../../../../../../../utils";

import dayjs from "dayjs";
import _ from "lodash";
import { checkIfServiceIsHoist } from "../../../../../../../../../../../../Estimations/DataEntryGrid/models/Service";
import { forceToNumber } from "../../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getDetailsRentDaily } from "../../../../RentalHeader/Components/HeaderDateInput/HeaderDateInputFunctions";

export const dateInputHandler = ({
  setUnchangingFakeRentals,
  setFakeRentals,
  selectedService,
  fakeRentals,
  elevationData,
  allRow,
  date,
}) => {
  //if we have selected the start and end day we take the moment object and turn it to timestamp
  const startDate = !!date ? dayjs(date[0])?.valueOf() : undefined;
  const endDate = !!date ? dayjs(date[1])?.valueOf() : undefined;
  const details = generateWeeks(startDate, endDate) || [];

  console.log("rentttttt", startDate, endDate, details);

  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);

  //here we find how many days are between those 2 selected days
  const howManyDays =
    !!startDate && !!endDate
      ? +(
          dayjs
            ?.duration(
              dayjs(endDate)?.startOf("day")?.valueOf() -
                dayjs(startDate)?.startOf("day")?.valueOf()
            )
            ?.asDays() + 1
        )?.toFixed(0)
      : 0;

  // const details = ({ dailyAmount }) => {
  // 	return (
  // 		generateWeeks(startDate, endDate)?.map((detailsData) => ({
  // 			...detailsData,
  // 			//here we set the weekly amount equal to the daily amount times the number of days in this week
  // 			weeklyAmount: dailyAmount * detailsData?.days?.length,
  // 			lock: false,
  // 			applied: false,
  // 			charged: false,
  // 			days: detailsData?.days?.map((daysData) => ({
  // 				...daysData,
  // 				lock: false,
  // 				dailyAmount,
  // 				applied: false,
  // 				charged: false,
  // 			})),
  // 		})) || []
  // 	);
  // };

  //this is the new rental object that we will create
  const newFakeRental = {
    ...fakeRentals,
    services: fakeRentals?.services?.map((serviceData) =>
      serviceData?.label === selectedService
        ? {
            ...serviceData,
            serviceOptions: serviceData?.serviceOptions?.map(
              (lineItemsData, idx) =>
                lineItemsData?.elevationId === elevationData?.elevationId
                  ? //find the elevation that we want to edit and edit only items key
                    {
                      ...lineItemsData,
                      items: lineItemsData?.items?.map((itemsData) => {
                        console.log("items dataa", itemsData);
                        const dailyAmount = !checkIfServiceIsHoist(serviceData)
                          ? +(
                              forceToNumber(itemsData?.rent) /
                              (4 * 7)
                            )?.toFixed(2)
                          : +(
                              forceToNumber(itemsData?.rentalsTotal) /
                              (4 * 7)
                            )?.toFixed(2);
                        // const tempDaily = _.divide(itemsData?.maxWeeklyAmount, 7);
                        const tempDaily = !checkIfServiceIsHoist(serviceData)
                          ? +(itemsData?.rent / (4 * 7))?.toFixed(2)
                          : +(itemsData?.rentalsTotal / (4 * 7))?.toFixed(2);
                        let tempDetails = getDetailsRentDaily({
                          details,
                          rent: !checkIfServiceIsHoist(serviceData)
                            ? forceToNumber(itemsData?.rent)
                            : forceToNumber(itemsData?.rentalsTotal),
                          dailyAmount,
                          howManyDays,
                          lastOne: idx === lineItemsData?.items?.length - 1,
                        });
                        let tempApplied = Array.isArray(tempDetails)
                          ? tempDetails?.reduce((acc, curr) => {
                              return (
                                acc +
                                curr?.days?.reduce((acc2, curr2) => {
                                  return (
                                    acc2 + forceToNumber(curr2?.dailyAmount)
                                  );
                                }, 0)
                              );
                            }, 0)
                          : 0;
                        return itemsData?.id === allRow?.id
                          ? //find the pli that we want to edit and edit/create the key
                            {
                              ...itemsData,
                              lock: true,
                              applied: false,
                              // maxWeeklyAmount: itemsData?.rent / 4,
                              appliedAmount: forceToNumber(
                                tempApplied?.toFixed(2) ?? 0
                              ),
                              //here we set the pli amount equal to the daily amount times the number of days
                              // appliedAmount: forceToNumber(
                              //   tempDaily * howManyDays
                              // ),
                              rentalDetails: {
                                ...itemsData?.rentalDetails,
                                endDate,
                                startDate,
                                details: tempDetails,
                                // details: details?.map((detailsData) => ({
                                //   ...detailsData,
                                //   charged: false,
                                //   //here we set the weekly amount equal to the daily amount times the number of days in this week
                                //   weeklyAmount:
                                //     tempDaily * detailsData?.days?.length,
                                //   lock: false,
                                //   applied: false,
                                //   days: detailsData?.days?.map((daysData) => ({
                                //     ...daysData,
                                //     applied: false,
                                //     charged: false,
                                //     lock: false,
                                //     dailyAmount: tempDaily,
                                //   })),
                                // })),
                              },
                            }
                          : //if this is not the pli that we are editing return it unchanged
                            itemsData;
                      }),
                    }
                  : //if this is not the elevation that we are editing return it unchanged
                    lineItemsData
            ),
          }
        : //if this is not the selected service return it unchanged
          serviceData
    ),
  };

  // here we save the newly created rental to context
  setFakeRentals(newFakeRental);
  setUnchangingFakeRentals(newFakeRental);
};

//this function tell the user why the check all is disabled
export const getDisabledTooltipContent = ({
  selectedService,
  fakeRentals,
  isWritable,
  elevationData,
  allRow,
}) => {
  //here we find the pli in witch the date input is on
  const data = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === elevationData?.elevationId
    )
    ?.items?.find(({ id }) => id === allRow?.id);

  //the case where write mode is off
  return !isWritable
    ? "Enable write mode to edit!"
    : //the case where this pli is charged
    !!data?.charged
    ? "This pli is charged you cant change period!!"
    : //the case where non of those above are true
      "";
};
