import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const inspections = (updatedDynamicStates, IdHandler) => ({
  inspectionType: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "inspectionType"
    );

    return {
      title: "Service Inspection Type",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionType",
        content: Object.entries(data)?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: updatedDynamicStates?.[IdHandler]?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Inspection for " + key,
          contentsHandler: " Inspections for " + key,
        })),
      },

      id: IdHandler,
      width: "390px",
    };
  },

  inspectionStatus: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "inspectionStatus"
    );

    return {
      title: "Service Inspection Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionStatus",
        content: Object.entries(data)?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: updatedDynamicStates?.[IdHandler]?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Inspection for " + key,
          contentsHandler: " Inspections for " + key,
        })),
      },

      id: IdHandler,
      width: "390px",
    };
  },

  inspectedBy: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler]?.map((el) => el?.inspectedBy) || [],
      "nameOfUser"
    );

    return {
      title: "Service Inspection By",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectedBy",
        content: Object.entries(data)?.map(([key, value], index) => ({
          key: index,
          title: key,
          taskNumber: updatedDynamicStates?.[IdHandler]?.length,
          taskDone: value,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Inspection for " + key,
          contentsHandler: " Inspections for " + key,
        })),
      },

      id: IdHandler,
      width: "390px",
    };
  },
});
