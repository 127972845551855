export const servicesElevationProcess = ({
  service = {},
  elevation = {},
  result = {},
}) => {
  console.log("servicesElevationProcess", { service, elevation });
  switch (service.serviceId.toString()) {
    case "1":
      let temp = "";
      service?.serviceOptions[0]?.forEach((primaryElevation, idx) => {
        temp += "Phase " + (idx + 1) + ": " + primaryElevation + "\n";
      });
      console.log("temp", temp);
  }
};

/**
1- Sidewalk Shed!


 */
