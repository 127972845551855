import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  dayjsNY,
  getOffsetTz,
} from "../../../../../../DateComponents/contants/DayjsNY";

dayjs.extend(customParseFormat);

/**
 * Function that gets a date object and changes only the timezone
 * keeping the date and time the same
 */
export function parseInTz(value, customFormat) {
  return dayjs.tz(
    dayjs(value, customFormat).format(),
    "YYYY-MM-DDTHH:mm:ss",
    getOffsetTz(value, customFormat)
  );
}
/**
 * Function that calculates how many days should be added to a
 * time field after date changes
 */
export function getDateOffset(prevDate, field) {
  return dayjsNY(field)
    .startOf("day")
    .diff(dayjsNY(prevDate).startOf("day"), "days");
}

/**
 * Function that sets the date and month of a date to a value
 * @param {dayjs.Dayjs} value The field to change
 * @param {dayjs.Dayjs} date The date we want to take the data from
 * @param {Number} customDay A specific day to set
 */
export function setDateMonth(value, date, customDay) {
  return dayjsNY(value)
    .set("year", dayjs(date).get("year"))
    .set("month", dayjs(date).get("month"))
    .set("date", customDay || dayjs(date).get("date"));
}

/**
 * Function that sets the minutes and hours of a time to a value
 * @param {dayjs.Dayjs} value The field to change
 * @param {dayjs.Dayjs} time The time we want to get the data from
 */
export function setHourMinute(value, time) {
  return dayjsNY(value)
    .hour(dayjs(time).hour())
    .minute(dayjs(time).minute())
    .second(0)
    .millisecond(0);
}

/**
 * Function that handles the changes of dates in the dispatch modal
 */
export function changeDate({ existingFields, prevDate }) {
  let selectedDate = existingFields["date"];
  let newFields = {};
  for (const key in existingFields) {
    if (
      key.includes("DepartAt") ||
      key.includes("ArriveBy") ||
      key.includes("TimeExit")
    ) {
      if (!!existingFields[key]) {
        /**
         * We check if the picker date had difference with the previous date
         */
        let offset = getDateOffset(prevDate, existingFields[key]);

        if (offset >= 0) {
          newFields[key] = setDateMonth(
            existingFields[key],
            dayjsNY(selectedDate).add(offset, "days")
          );
        } else {
          newFields[key] = setDateMonth(
            existingFields[key],
            dayjsNY(selectedDate).subtract(offset, "days")
          );
        }
      }
    }
  }
  return newFields;
}

/**
 * Function that changes the date of fields starting from an index
 */
export function changeRouteDates({ existingFields, index, routeLength }) {
  let selectedDate = existingFields["date"];
  let newFields = {};
  //if the caller is a route
  if (index !== null) {
    for (let i = index; i <= routeLength; i++) {
      let c = i === routeLength;
      let baseSelector = c ? "Return" : "";
      let indexSelector = c ? "" : i;
      if (!!existingFields[`${baseSelector}ArriveBy#${indexSelector}`]) {
        newFields[`${baseSelector}ArriveBy#${indexSelector}`] = dayjsNY(
          existingFields[`${baseSelector}ArriveBy#${indexSelector}`]
        ).add(1, "day");
      }
      if (!c && !!existingFields[`${baseSelector}TimeExit#${indexSelector}`]) {
        newFields[`${baseSelector}TimeExit#${indexSelector}`] = dayjsNY(
          existingFields[`${baseSelector}TimeExit#${indexSelector}`]
        ).add(1, "day");
      }
      if (
        i !== index &&
        !!existingFields[`${baseSelector}DepartAt#${indexSelector}`]
      ) {
        newFields[`${baseSelector}DepartAt#${indexSelector}`] = dayjsNY(
          existingFields[`${baseSelector}DepartAt#${indexSelector}`]
        ).add(1, "day");
      }
    }
  } else {
    if (!!existingFields["ReturnArriveBy#"]) {
      newFields["ReturnArriveBy#"] = dayjsNY(
        existingFields["ReturnArriveBy#"]
      ).add(1, "day");
    }
  }

  return newFields;
}

/**
 * Function that reduces the dates of the time pickers from 0 to an index
 */
export function reduceRouteDates({ existingFields, index }) {
  /**
   * This operation happens when we must make a depart at a certain date and time
   * Used when calculating times in reverse for a request
   */
  let newFields = {};

  for (let i = 0; i <= index; i++) {
    if (!!existingFields[`DepartAt#${i}`]) {
      newFields[`DepartAt#${i}`] = dayjsNY(
        existingFields[`DepartAt#${i}`]
      ).subtract(1, "d");
    }
    if (i !== index) {
      if (!!existingFields[`ArriveBy#${i}`]) {
        newFields[`ArriveBy#${i}`] = dayjsNY(
          existingFields[`ArriveBy#${i}`]
        ).subtract(1, "d");
      }
      if (!!existingFields[`TimeExit#${i}`]) {
        newFields[`TimeExit#${i}`] = dayjsNY(
          existingFields[`TimeExit#${i}`]
        ).subtract(1, "d");
      }
    }
  }

  return newFields;
}
