import types from "../../types";

const initialState = {
  sessionNotifications: {
    allLogs: [],
    newLogs: [],
    newCount: 0,
  },
};

const sessionNotificationsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case types.SET_SESSION_NOTIFICATIONS: {
      return {
        ...state,
        sessionNotifications: {
          ...state.sessionNotifications,
          allLogs: payload,
          newCount: 0,
        },
      };
    }
    case types.ADD_SESSION_NOTIFICATION: {
      if (
        state?.sessionNotifications?.allLogs?.find(
          (log) => log?.logId === payload?.logId
        )
      ) {
        return state;
      }
      return {
        ...state,
        sessionNotifications: {
          ...state.sessionNotifications,
          allLogs: [...state.sessionNotifications.allLogs, payload],
          newLogs: [...state.sessionNotifications.newLogs, payload],
          newCount: state.sessionNotifications.newCount + 1,
        },
      };
    }
    case types.RESET_NEW_SESSION_NOTIFICATIONS: {
      return {
        ...state,
        sessionNotifications: {
          ...state.sessionNotifications,
          allLogs: [],
          newLogs: [],
          newCount: 0,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default sessionNotificationsReducer;

export const updateReduxSession = (type, body) => {
  const types = {
    add: "ADD_SESSION_NOTIFICATION",
    resetNew: "RESET_NEW_SESSION_NOTIFICATIONS",
    set: "SET_SESSION_NOTIFICATIONS",
  };

  return { type: types[type], payload: body };
};
