import { Fragment, useState } from "react";
import dayjs from "dayjs";
import { Modal, Button, message } from "antd";

import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { formatNumber } from "../../../../../utils";
import { dateFormat } from "../utils/routeFields";
import DispatchMapsCard from "./DispatchMapsCard";
import { MapMarkerIcon, FlagIcon } from "../../../../../../../assets";
import { RightArrow } from "../../../../../BasePage/src";
import { XIcon } from "../../../../../Communication/assets";

import "./RouteShower.scss";

function getTimeLabel(routeDate, firstRoute, selectedDate) {
  let diff = 0;
  let label = <span></span>;
  if (!!selectedDate && !!routeDate) {
    diff = dayjsNY(routeDate)
      .startOf("d")
      .diff(dayjsNY(selectedDate).startOf("d"), "d");
  } else if (!!routeDate && !!firstRoute) {
    diff = dayjsNY(routeDate)
      .startOf("d")
      .diff(dayjsNY(firstRoute).startOf("d"), "d");
  }
  if (diff > 0) {
    label = <span style={{ color: "#ff4d4f" }}>&nbsp;{"(Next Day)"}</span>;
  } else if (diff < 0) {
    label = <span style={{ color: "#ff4d4f" }}>&nbsp;{"(Previous Day)"}</span>;
  }

  return label;
}

function RouteShower({
  open,
  onCancel,
  title = "Route details",
  routes,
  showCargoInfo = true,
  selectedDate = null,
  isDarkMode,
}) {
  const [mapsVisible, setMapsVisible] = useState(null);

  async function openMap(origin, destination, index) {
    let itineraryRoute = routes?.[index]?.["itinerary"];
    if (!itineraryRoute?.length) {
      itineraryRoute = routes?.find(({ itinerary }) => {
        const arr = [
          itinerary?.[0]?.["pickUpLocation"],
          itinerary?.[itinerary?.length - 1]?.["dropOffLocation"],
        ].filter(Boolean);

        return [origin, destination].every((e) => arr.includes(e));
      })?.itinerary;
    }

    const directionService = new window.google.maps.DirectionsService();
    let results;

    try {
      if (itineraryRoute) {
        results = await Promise.all(
          itineraryRoute.map((itinerary) =>
            directionService.route({
              destination: itinerary.dropOffCoordinates,
              origin: itinerary.pickUpCoordinates,
              travelMode: itinerary.travelMode,
              avoidFerries: false,
              avoidHighways: true,
              avoidTolls: true,
              waypoints:
                !itinerary?.waypoints || !itinerary?.waypoints?.length
                  ? undefined
                  : itinerary.waypoints.map(({ location, stopover }) => ({
                      location: new google.maps.LatLng(location),
                      stopover,
                    })),
            })
          )
        );
      } else {
        results = await directionService.route({
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        });
      }
    } catch (err) {
      message.warning("Something went wrong while opening the map");
      console.log("Error opening map: ", err);
      return;
    }

    setMapsVisible(results);
  }

  function parseLength(val) {
    return formatNumber(val * 0.000621371, {
      unit: "mile",
      fixed: 1,
      fallback: "--",
    });
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        closable: true,
        title: selectedDate
          ? `${title} - ${dayjsNY(selectedDate).format(dateFormat)}`
          : title,
        footer: null,
        className: `routeDetailsShower ${
          isDarkMode && "routeDetailsShowerDark"
        }`,
        closeIcon: <XIcon />,
        "data-testid": "route-show-modal",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {routes?.map((route, i) => (
          <Fragment key={`route-${i}`}>
            {route?.routeName && (
              <div
                className={`routeNo ${route?.fromAudit ? "auditNo" : ""}`}
              >{`${route?.routeName}${
                route?.actualArrive ? " (Audited)" : ""
              }`}</div>
            )}
            {route?.fromAudit && (
              <div className="fromAuditHeader">Created From Audit</div>
            )}
            <div className="singleRouteContainer">
              <div className="routeLocationContainer">
                <div className="routeLocation">
                  <MapMarkerIcon fill="#7AC14D" />
                  {!!route?.pickupLocation
                    ? route?.pickupLocation
                    : !!route?.pickUpLocation
                    ? route?.pickUpLocation
                    : "No pickup selected"}
                </div>
                <div className="arrow">
                  <RightArrow fill="black" />
                </div>
                <div className="routeLocation">
                  <FlagIcon fill="#F04F4E" />
                  {!!route?.dropOffLocation
                    ? route?.dropOffLocation
                    : "No pickup selected"}
                </div>
              </div>
              <div
                className={`${
                  showCargoInfo ? "routeInfoSection" : "routeInfoSectionNoCargo"
                }`}
              >
                <div className="routeInfo">
                  <strong>Truck:&nbsp;</strong>
                  <span>{route?.fleetName ?? "No Truck"}</span>
                </div>
                <div className="routeInfo">
                  <strong>Driver:&nbsp;</strong>
                  <span>{route?.driverName ?? "No Driver"}</span>
                </div>
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong>Cargo:&nbsp;</strong>
                    <span>{route?.cargo}</span>
                  </div>
                )}
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong>Distance:&nbsp;</strong>
                    <span>
                      {routes?.length === 2 && i === 1
                        ? parseLength(routes[0]?.routeLength)
                        : parseLength(route?.routeLength)}
                    </span>
                  </div>
                )}
                <div className="routeInfo">
                  <strong>
                    Depart
                    {getTimeLabel(
                      route?.departAt,
                      routes[0]?.departAt,
                      selectedDate
                    )}
                    :&nbsp;
                  </strong>
                  <span style={{ color: !!route?.departAt ? "black" : "red" }}>
                    {!!route?.departAt
                      ? dayjs
                          .tz(dayjs(route?.departAt))
                          .format("MMM DD YY, HH:mm")
                      : "No time selected"}
                  </span>
                </div>
                <div className="routeInfo">
                  <strong>
                    Arrive
                    {getTimeLabel(
                      route?.arriveBy,
                      routes[0]?.departAt,
                      selectedDate
                    )}
                    :&nbsp;
                  </strong>
                  <span style={{ color: !!route?.arriveBy ? "black" : "red" }}>
                    {!!route?.arriveBy
                      ? dayjs
                          .tz(dayjs(route?.arriveBy))
                          .format("MMM DD YY, HH:mm")
                      : "No time selected"}
                  </span>
                </div>
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong>Duration:&nbsp;</strong>
                    <span>{route?.timeScheduled}</span>
                  </div>
                )}
                {i !== routes?.length - 1 && showCargoInfo ? (
                  <div className="routeInfo">
                    <strong>
                      Exit
                      {getTimeLabel(
                        route?.timeExit,
                        routes[0]?.departAt,
                        selectedDate
                      )}
                      :&nbsp;
                    </strong>
                    <span
                      style={{ color: !!route?.timeExit ? "black" : "red" }}
                    >
                      {!!route?.timeExit
                        ? dayjs
                            .tz(dayjs(route?.timeExit || undefined))
                            .format("MMM DD YY, HH:mm")
                        : "No time selected"}
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong>Paperwork Collect Status:&nbsp;</strong>
                    <span>{route?.paperworkCollectStatus}</span>
                  </div>
                )}
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong>Variance:&nbsp;</strong>
                    <span>{route?.routeVariance ? "Yes" : "No"}</span>
                  </div>
                )}
                {showCargoInfo && route?.actualDepart && (
                  <div className="routeInfo">
                    <strong>Actual Depart:&nbsp;</strong>
                    <span>
                      {dayjsNY(route?.actualDepart).format("MMM DD YY, HH:mm")}
                    </span>
                  </div>
                )}
                {showCargoInfo && route?.actualArrive && (
                  <div className="routeInfo">
                    <strong>Actual Arrive:&nbsp;</strong>
                    <span>
                      {dayjsNY(route?.actualArrive).format("MMM DD YY, HH:mm")}
                    </span>
                  </div>
                )}
                {showCargoInfo && !route?.actualDepart && (
                  <div className="routeInfo">
                    <strong></strong>
                    <span></span>
                  </div>
                )}
                {showCargoInfo && !route?.actualArrive && (
                  <div className="routeInfo">
                    <strong></strong>
                    <span></span>
                  </div>
                )}
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong></strong>
                    <span></span>
                  </div>
                )}
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong></strong>
                    <span></span>
                  </div>
                )}
                {showCargoInfo && (
                  <div className="routeInfo">
                    <strong></strong>
                    <span></span>
                  </div>
                )}
                {showCargoInfo && (
                  <div className="routeInfo mapButton">
                    <Button
                      type="primary"
                      onClick={() => {
                        openMap(
                          route?.pickUpLocation,
                          route?.dropOffLocation,
                          i
                        );
                      }}
                      style={{ borderRadius: "5px" }}
                    >
                      Open maps directions
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      <DispatchMapsCard
        {...{
          open: !!mapsVisible,
          onCancel() {
            setMapsVisible(null);
          },
          directions: mapsVisible,
          isDarkMode,
        }}
      />
    </Modal>
  );
}

export default RouteShower;
