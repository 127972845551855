import { getMonthsTimestamps } from "../../../../../utils"
import { months } from "../../../../addWidgetsModalData"

export const maintenance = ({ tables }) => {
  return {
    createdAt: () => ({
      content: (() => {
        let data = []
        for (let i = 0; i < 12; i++) {
          data = [
            ...data,
            {
              color: "#71CF48",
              unfilledColor: "#F5F5F5",
              percent: (
                (tables?.Maintenance?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.createdAt)
                  ?.filter(
                    (b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i]
                  )?.length /
                  tables?.Maintenance?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                    .length) *
                100
              ).toFixed(1),
              showInfo: true,
              status: months[i],
              done: tables?.Maintenance?.filter((b) => b.createdAt >= getMonthsTimestamps()[0])
                ?.map((a) => a?.createdAt)
                ?.filter((b) => b >= getMonthsTimestamps()[i] && b <= getMonthsTimestamps("end")[i])
                ?.length,
            },
          ]
        }
        return data
      })(),
    }), //createdAt
  } //Maintenance
}
