import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../../../../../../../Estimations/DataEntryGrid/models/Service";
import { roundToDecimals } from "../../../../../../../../../../utils";

export const handleChangeRetainage = ({
  closeRetainageModal,
  dispatchContext,
  retainageState,
  serviceId,
  fakeSOV,
}) => {
  const newSOV = {
    ...fakeSOV,
    services: fakeSOV?.services?.map((serviceData) =>
      serviceData?.serviceId === serviceId
        ? {
            ...serviceData,
            serviceOptions: [
              serviceData?.serviceOptions[0]?.map((elevationData) => ({
                ...elevationData,
                amounts: elevationData?.amounts?.map((eventData) => {
                  const { amount } = eventData;
                  const retainage = roundToDecimals(
                    (amount * retainageState) / 100,
                    2
                  ); // Calculate retainage
                  const retainagePercentage = forceToNumber(retainageState); // Calculate retainage percentage
                  const collectable_amount = roundToDecimals(
                    amount - retainage,
                    2
                  ); // Get collectable amount without retainage
                  return {
                    ...eventData,
                    retainage,
                    retainagePercentage,
                    collectable_amount,
                  };
                }),
              })),
            ],
            retainage: retainageState,
          }
        : serviceData
    ),
  };

  console.log("new sov", newSOV);
  // Set new SOV
  dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });

  closeRetainageModal();
};

export const handleChangeRetForAll = ({
  closeRetainageModal = () => {},
  dispatchContext,
  retainageState,
  // serviceId,
  fakeSOV,
}) => {
  const newSOV = {
    ...fakeSOV,
    services: fakeSOV?.services?.map((serviceData) => {
      if (checkIfServiceIsHoist(serviceData)) {
        return {
          ...serviceData,
          serviceOptions: [
            serviceData?.serviceOptions[0]?.map((elevationData) => ({
              ...elevationData,
              amounts: elevationData?.amounts?.map((eventData) => {
                const { amount } = eventData;
                const retainage = roundToDecimals(
                  (amount * retainageState) / 100,
                  2
                ); // Calculate retainage
                const retainagePercentage = forceToNumber(retainageState); // Calculate retainage percentage
                const collectable_amount = roundToDecimals(
                  amount - retainage,
                  2
                ); // Get collectable amount without retainage
                return {
                  ...eventData,
                  retainage,
                  retainagePercentage,
                  collectable_amount,
                };
              }),
            })),
          ],
          retainage: retainageState,
        };
      } else {
        return {
          ...serviceData,
          amounts: serviceData?.amounts?.map((event) => {
            const rate = event.rate; // Initial rate of event
            const amount = roundToDecimals(
              (serviceData.totalPrice * rate) / 100,
              2
            ); // Calculate amount based on rate
            const retainage = roundToDecimals(
              (amount * retainageState) / 100,
              2
            ); // Calculate retainage
            const collectable_amount = roundToDecimals(amount - retainage, 2); // Get collectable amount without retainage
            console.log("event", retainageState);
            const retainagePercentage = retainageState; // Calculate retainage percentage

            return {
              ...event,
              retainage,
              collectable_amount,
              retainagePercentage,
            };
          }),
          retainage: retainageState,
        };
      }
    }),
  };

  console.log("new sov", newSOV);
  // Set new SOV
  dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });

  closeRetainageModal();
};
