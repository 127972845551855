import { Progress } from "antd";
import { useEffect, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import "./ProgressBar.scss";
const ProgressBar = ({ params, edited, setNewValues, setIsEditable }) => {
  const [values, setValues] = useState(params);

  if (
    values.content.value === 100 ||
    document.getElementById("progressBarValue")?.innerText === "100%"
  ) {
    setIsEditable(false);
  }

  const status = document
    ?.getElementById("stepperStatus")
    ?.getElementsByClassName("ant-steps-item-active")[0]
    ?.getElementsByClassName("ant-steps-icon")[0]?.innerHTML;

  if (parseInt(status) <= 4 && parseInt(status) > 1) {
    setIsEditable(true);
  }

  useEffect(() => {
    if (edited) {
      keys(edited).map((el) => {
        values.content.value = edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
    }
  }, [JSON.stringify(edited)]);

  return (
    <div className="progressBarContainer">
      <Progress
        strokeColor={{
          "0%": "#108ee9",
          "100%": "#87d068",
        }}
        percent={values.content.value}
      />
    </div>
  );
};
export default ProgressBar;
