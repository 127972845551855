export const incidents = (
  circleProgressPicker,
  IdHandler,
  updatedDynamicStates
) => {
  return {
    driverName: () => ({
      title: "Incidents Driver Name",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "driverName",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.driverName?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.driverName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }),

    fleetName: () => ({
      title: "Incidents Fleet Name",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "fleetName",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.fleetName?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.fleetName?.toLowerCase() === circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }),

    incidentStatus: () => ({
      title: "Incidents Status",
      BodyComponent: "CircleProgresBar",
      type: "CircleProgresBar",
      BodyComponentParams: {
        table: "Incidents",
        field: "incidentStatus",
        content: circleProgressPicker,
        color: "green",
        percent: (
          (updatedDynamicStates[IdHandler]?.filter(
            (a) =>
              a?.incidentStatus?.toLowerCase() ===
              circleProgressPicker?.toLowerCase()
          )?.length /
            updatedDynamicStates[IdHandler]?.length) *
          100
        ).toFixed(1),
        showInfo: true,
        status: circleProgressPicker,
        data: updatedDynamicStates[IdHandler]?.filter(
          (a) =>
            a?.incidentStatus?.toLowerCase() ===
            circleProgressPicker?.toLowerCase()
        )?.length,
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
