import React from "react";
import ChargeSave from "./components/ChargeSave";
import ChargeTitle from "./components/ChargeTitle";
import ChargeAmounts from "./components/ChargeAmounts";
import ChargeDisabled from "./components/ChargeDisabled";
import { useRedux } from "../../../../../../../hooks/useRedux";
import { useCreateChargeContext } from "../../context/NewChargeItemContext";
import DarkModeToggle from "../../../../../../../../commonComponents/DarkModeToggle/DarkModeToggle";
import AccountingWriteMode from "../../../../../../../../../components/commonComponents/AccountingWriteMode/AccountingWriteMode";

import "./Header.scss";

export const Header = () => {
  const [fakeCharges] = useRedux("fakeCharges");
  const [isWritable, setIsWritable] = useRedux("chargesIsWritable");
  const [darkMode, setDarkMode] = useRedux("chargesDarkMode");
  const [objectData] = useRedux("chargeItemsModal");

  const categoryFrom = objectData?.categoryFrom;
  const selectedCatFrom = objectData?.selectedCatFrom;
  const fromRequisition = objectData?.fromObject;
  const currentCharge = objectData?.currentCharge;

  const selectedCategoryFrom = selectedCatFrom
    ? selectedCatFrom
    : fromRequisition
    ? `${categoryFrom} ${fromRequisition?.[0]?.rentalNumber}`
    : `${currentCharge?.categoryFrom} ${currentCharge?.categoryNumber}`;

  return (
    <div className="charge-main-header">
      <div className="charge-title-header-left">
        <ChargeTitle {...{ type: objectData?.type, currentCharge }} />

        {currentCharge && (
          <AccountingWriteMode
            {...{
              isWritable,
              recordId: objectData?.chargeId
                ? objectData?.chargeId
                : objectData?.editCharge?.chargeId,
              onSwitchFunction: setIsWritable,
              darkMode,
              disableSwitch: currentCharge?.invoices?.length > 0,
              disabilityReason:
                currentCharge?.invoices?.length > 0 ? (
                  <ChargeDisabled {...{ currentCharge }} />
                ) : undefined,
              projectId: fakeCharges?.projectId,
            }}
          />
        )}
      </div>
      <div
        className="charge-title-header-right"
        style={currentCharge ? { width: "60%" } : { width: "100%" }}
      >
        <div className="darkModeContainer">
          <DarkModeToggle
            {...{
              onClickFunction: () => setDarkMode(!darkMode),
              darkMode,
            }}
          />
        </div>
        <span className="chargeCategoryFrom">{selectedCategoryFrom}</span>

        <ChargeAmounts />
        <ChargeSave {...{ currentCharge, isWritable }} />
      </div>
    </div>
  );
};
