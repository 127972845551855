import { Doughnut } from "react-chartjs-2";

function PieChart({ params, isDarkMode }) {
  let { rowData } = params || {};

  let {
    amount_due,
    fine_amount,
    interest_amount,
    payment_amount,
    penalty_amount,
    reduction_amount,
  } = rowData || {};

  const labels = [
    "Paid",
    "Amount Due",
    "Reduction Amount",
    "Fine Amount",
    "Interest Amount",
    "Penalty Amount",
  ];
  const colors = [
    "#1FC74C",
    "#ff9c46",
    "#a2b814",
    "#bc5090",
    "#ff6361",
    "#58508d",
  ];

  const pieData = {
    labels,
    datasets: [
      {
        label: "payment status",
        backgroundColor: ["#1FC74C", "#ff9c46"],
        data: [parseInt(payment_amount), parseInt(amount_due)],
      },
      {
        label: "fine info",
        backgroundColor: ["#a2b814", "#bc5090", "#ff6361", "#58508d"],
        data: [
          parseInt(reduction_amount),
          parseInt(fine_amount),
          parseInt(interest_amount),
          parseInt(penalty_amount),
        ],
      },
    ],
  };

  return (
    <div>
      <Doughnut
        data={pieData}
        height={291}
        options={{
          maintainAspectRatio: false,
          // fillStyle: {
          //   color: isDarkMode ? "#fff" : "#333238",
          //   border: "1px solid red",
          // },
          plugins: {
            legend: {
              labels: {
                generateLabels: () =>
                  labels.map((label, i) => ({
                    fillStyle: colors[i],
                    hidden: false,
                    index: i,
                    lineWidth: 2,
                    strokeStyle: isDarkMode ? "#12131b" : "#fff",
                    text: label,
                    textAlign: undefined,
                    fontColor: isDarkMode ? "#fff" : "#333238",
                  })),
              },
              onClick: (mouseEvent, legendItem, legend) => {
                // toggle the visibility of the dataset from what it currently is
                legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden =
                  legend.chart.isDatasetVisible(legendItem.datasetIndex);
                legend.chart.update();
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const labelIndex =
                    context.datasetIndex * 2 + context.dataIndex;
                  return (
                    context.chart.data.labels[labelIndex] +
                    ": " +
                    context.formattedValue
                  );
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export default PieChart;
