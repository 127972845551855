import { Bar } from "react-chartjs-2";
import "./BarChartFullScreen.scss";
import { SmallLoadableComp } from "../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";

const BarChartFull = ({ params, leadsData }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    animation: undefined,
    maintainAspectRatio: false,
  };
  const loading = params.datasets[0].data.length === 0 ? true : false;
  return (
    <div className="longBarChartBody">
      <div className="BarChartFull">
        <SmallLoadableComp loading={loading}>
          <Bar height={10} data={params} options={options} />
        </SmallLoadableComp>
      </div>
    </div>
  );
};
export default BarChartFull;
