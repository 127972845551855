import { getRandomColor } from "../../../../utils";

export const inspections = (updatedDynamicStates, IdHandler, programFields) => {
  const statuses = programFields
    ?.find((el) => el?.fieldName === "Statuses Of Categories")
    ?.fieldOptions?.Inspection?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.statusName]: curr?.statusColor,
      }),
      {}
    );

  return {
    inspectionType: () => ({
      title: "Service Inspection Type",
      BodyComponent: "Progress",
      type: "Progress",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionType",
        filterType: { type: "dashboardId" },
        secondaryField: "projectName",
        id: IdHandler,

        statusData: (updatedDynamicStates?.[IdHandler] || []).map((a) => ({
          label: a?.inspectionType,
          value: a?.projectName,
          color: statuses?.[a?.inspectionType] || getRandomColor(),
          id: a?.inspectionId,
        })),
      },

      id: IdHandler,
      width: "790px",
    }),

    inspectionStatus: () => ({
      title: "Service Inspection Status",
      BodyComponent: "Progress",
      type: "Progress",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectionStatus",
        filterType: { type: "dashboardId" },
        secondaryField: "projectName",
        id: IdHandler,

        statusData: (updatedDynamicStates?.[IdHandler] || []).map((a) => ({
          label: a?.inspectionStatus,
          value: a?.projectName,
          color: statuses?.[a?.inspectionStatus] || getRandomColor(),
          id: a?.inspectionId,
        })),
      },

      id: IdHandler,
      width: "790px",
    }),

    inspectedBy: () => ({
      title: "Service Inspected By",
      BodyComponent: "Progress",
      type: "Progress",

      BodyComponentParams: {
        table: "Inspections",
        field: "inspectedBy",
        filterType: { type: "dashboardId" },
        secondaryField: "projectName",
        id: IdHandler,

        statusData: (updatedDynamicStates?.[IdHandler] || []).map((a) => ({
          label: a?.inspectedBy,
          value: a?.projectName,
          color: statuses?.[a?.inspectedBy] || getRandomColor(),
          id: a?.inspectionId,
        })),
      },

      id: IdHandler,
      width: "790px",
    }),
  };
};
