const gridFilters = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "DEG Name",
    type: "Dropdown",
    key: "degName",
  },
  {
    globalKeyId: "8fewfwbc3-0b8a-400f-b352-6354cb0f22ec",
    label: "DEG Status",
    type: "Dropdown",
    key: "degStatus",
  },
  // {
  //   globalKeyId: "8fdedbc3-0b8aweee-b352-6354cb0f22ec",
  //   label: "Company",
  //   type: "Dropdown",
  //   key: "company",
  // },
  // {
  //   globalKeyId: "8fdedbc3-xxxx-400f-b352-6354cb0f22ec",
  //   label: "Reg Amount",
  //   type: "Dropdown",
  //   key: "regAmount",
  // },
  // {
  //   globalKeyId: "6agabnc3-ytgh-f450-b52g-6354cb0f22eg",
  //   label: "Rate",
  //   type: "Dropdown",
  //   key: "rate",
  // },
  // {
  //   globalKeyId: "c7af5156-5840-45fd-962f-aa574f0d5f10",
  //   label: "OT Amount",
  //   type: "Dropdown",
  //   key: "otAmount",
  // },
  // {
  //   globalKeyId: "42794e39-05f8-437f-bd87-4b404901a440",
  //   label: "Total Amount",
  //   type: "Dropdown",
  //   key: "totalAmount",
  // },
  // {
  //   globalKeyId: "445345c4-5978-4dee-8c2f-92276ccab017",
  //   label: "Punch In Location",
  //   type: "Dropdown",
  //   key: "punchInLocation",
  // },
  // {
  //   globalKeyId: "258a5d41-0a57-4e45-9b34-eb18be161911",
  //   label: "Punch Out Location",
  //   type: "Dropdown",
  //   key: "punchOutLocation",
  // },
  // {
  //   globalKeyId: "74e95c3b-360b-4384-b74b-21b33af7b842",
  //   label: "Distance From Jobsite",
  //   type: "Dropdown",
  //   key: "distanceFromJobsite",
  // },
  // {
  //   globalKeyId: "ac810ced-788d-4ce3-a6f9-123081326d38",
  //   label: "Jobsite Match",
  //   type: "Dropdown",
  //   key: "jobsiteMatch",
  // },
  // {
  //   globalKeyId: "637b05d7-a28c-4278-a3aa-bf6b0a750326",
  //   label: "SOW",
  //   type: "Dropdown",
  //   key: "sow",
  // },
  // {
  //   globalKeyId: "27460cca-0b8e-4353-9389-e6ade02fd9fe",
  //   label: "Scheduling",
  //   type: "Dropdown",
  //   key: "scheduling",
  // },
];

export default gridFilters;
