import React from "react";
import "./FormTextEditor.scss";
import { LabeledInput } from "../../../../SidebarPages/Fleet/components";
import RichTextEditor from "../../../../commonComponents/RichTextEditor/RichTextEditor";

const FormTextEditor = ({
  label,
  onChange = () => {},
  className = "",
  style = { width: "100%" },
  value = "",
  disabled = false,

  required = false,
  form,
  formItemName = "",
  placeholder = "",
  isDarkMode,
  initialValue = "",
  noFormItem = false,
  ...rest
}) => {
  return (
    <LabeledInput
      {...{
        required,
        style,
        label,
        disabled,
        formItemName,
        form,
        initialValue,
        noFormItem,
        ...rest,
      }}
    >
      <RichTextEditor
        {...{
          value,
          onChange,
          disabled,
          placeholder,
          initialValue,
        }}
      />
    </LabeledInput>
  );
};

export default FormTextEditor;
