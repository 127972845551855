export const MODAL_TITLE = "Next Step";
export const FIELD_NAME = "Next Step Configuration";
export const NO_STEPS_MESSAGE = "No steps configured at the moment.";
export const STEPS_INFO_MESSAGE =
  "saved successfully! Please select the next steps below.";

export const AUTOMATION_INFO_MESSAGE =
  "You've chosen automation. Please proceed by entering the required data for each selected step.";

export const OPEN_STEP_MSG = "Opening next step. Please wait...";

export const SUCCESS_OPEN_STEP_MSG = "Next step opened successfully.";
export const ERROR_OPEN_STEP_MSG = "Error opening next step.";

export const MAIN_RECORD = "Current record";

export const SUCCESS_STEP_MESSAGE = "All Steps created successfully.";
export const ERROR_STEP_MESSAGE =
  "There was a problem creating all steps. Try again later.";

export const ALERT_ERROR_MESSAGE =
  "Remove this step for automation. Manual intervention required.";

export const yesNoOptions = ["Yes", "No"];

export const recordName = {
  Projects: { name: "projectAddress", id: "projectId" },
  Fleet: { name: "fleetName", id: "fleetId" },
};
