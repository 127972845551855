import { boroughs } from "../../../../addWidgetsModalData";
import { uniq } from "lodash";

export const leads = ({ tables, programFields }) => {
  return {
    leadAddress: () => ({
      content: boroughs?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Leads?.filter(
            (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
          ).length /
            tables?.Leads?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status,
        done: tables?.Leads?.filter(
          (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
        ).length,
      })),
    }),
    leadStatus: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Lead Status Types")
        ?.fieldOptions?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Leads?.filter((a) => a?.leadStatus === status).length /
              tables?.Leads?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Leads?.filter((a) => a?.leadStatus === status).length,
        })),
    }),

    projectExecutive: () => ({
      content: uniq(tables?.Leads?.map((a) => a?.projectExecutive))
        ?.filter(Boolean)
        ?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Leads?.filter((a) => a?.projectExecutive === status)
              .length /
              tables?.Leads?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Leads?.filter((a) => a?.projectExecutive === status)
            .length,
        })),
    }),
  };
};
