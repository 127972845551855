import React from "react";

import { InspectionModal } from "../../../../XComponents";

const EndInspectionModal = ({ visible, setVisible, onEnd }) => {
  return (
    <InspectionModal
      visible={visible}
      setVisible={setVisible}
      maskClosable={false}
      footer={[
        {
          text: "cancel",
          onClick: () => setVisible(false),
          primary: false,
        },
        {
          text: "end",
          onClick: () => onEnd(),
          danger: true,
        },
      ]}
    >
      <div style={{ height: "150px" }}>
        <div className="forceToInspectModalTitle">
          Are you sure you want to terminate and lose this inspection?
        </div>
        <div>You can't undo this action.</div>
      </div>
    </InspectionModal>
  );
};

export default EndInspectionModal;
