import dayjs from "dayjs"
import { formatDobDate } from "."

export const formatDobData = (data = [], currentFilters = []) =>
  data.map((item = {}) => ({
    ...item,
    ...currentFilters.reduce(
      (acc, { key, type }) =>
        ["DatePicker", "DateRange"].includes(type)
          ? {
              ...acc,
              [key]: dayjs(formatDobDate(item[key]), "MM/DD/YYYY").valueOf(),
            }
          : acc,
      {}
    ),
  }))
