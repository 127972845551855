import uniq from "lodash/uniq";

export const defects = ({ tables }) => {
  return {
    defectStatus: () => ({
      content: uniq(tables?.Defects?.map((a) => a?.defectStatus))?.map(
        (defectStatus, index) => ({
          key: index,
          title: defectStatus,
          taskNumber: tables?.Defects?.length,
          taskDone: tables?.Defects?.filter(
            (a) => a?.defectStatus === defectStatus
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: defectStatus,
          contentsHandler: defectStatus,
        })
      ),
    }),

    defectName: () => ({
      content: uniq(tables?.Defects?.map((a) => a?.defectName))?.map(
        (defectName, index) => ({
          key: index,
          title: defectName,
          taskNumber: tables?.Defects?.length,
          taskDone: tables?.Defects?.filter((a) => a?.defectName === defectName)
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: defectName,
          contentsHandler: defectName,
        })
      ),
    }),

    driverName: () => ({
      content: uniq(tables?.Defects?.map((a) => a?.driverName))?.map(
        (driverName, index) => ({
          key: index,
          title: driverName,
          taskNumber: tables?.Defects?.length,
          taskDone: tables?.Defects?.filter((a) => a?.driverName === driverName)
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Defect By " + driverName,
          contentsHandler: " Defects By " + driverName,
        })
      ),
    }),

    fleetName: () => ({
      content: uniq(tables?.Defects?.map((a) => a?.fleetName))?.map(
        (fleetName, index) => ({
          key: index,
          title: fleetName,
          taskNumber: tables?.Defects?.length,
          taskDone: tables?.Defects?.filter((a) => a?.fleetName === fleetName)
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Defect By " + fleetName,
          contentsHandler: " Defects By " + fleetName,
        })
      ),
    }),

    defectPriority: () => ({
      content: uniq(tables?.Defects?.map((a) => a?.defectPriority))?.map(
        (defectPriority, index) => ({
          key: index,
          title: defectPriority,
          taskNumber: tables?.Defects?.length,
          taskDone: tables?.Defects?.filter(
            (a) => a?.defectPriority === defectPriority
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: defectPriority,
          contentsHandler: defectPriority,
        })
      ),
    }),

    inspectionName: () => ({
      content: uniq(tables?.Defects?.map((a) => a?.inspectionName))?.map(
        (inspectionName, index) => ({
          key: index,
          title: inspectionName,
          taskNumber: tables?.Defects?.length,
          taskDone: tables?.Defects?.filter(
            (a) => a?.inspectionName === inspectionName
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: " Defect By " + inspectionName,
          contentsHandler: " Defects By " + inspectionName,
        })
      ),
    }),
  };
};
