/**
 * This file will try to read formulas written in Settings > Pricing > Pricing config
 * */

/**
 * @param pli {SimplePLI} - PLI
 * @param unit {{unitName: String, formula: String, unitDescription: String}}
 * */
export function generateCellValue(pli, unit) {
  console.log("pliunit", {
    pli,
    unit,
  });
  if (unit.formula.length > 0 && unit?.addonType !== "service addon") {
    let mathematicalExpression = replaceDimensionNamesWithValues(
      pli,
      unit.formula
    );

    try {
      return eval(mathematicalExpression) + " " + unit.unitName;
    } catch (e) {
      console.error("Can not calculate formula!", e);
      return "N/A";
    }
  } else {
    return unit.unitName;
  }
}

function replaceDimensionNamesWithValues(pli, formula) {
  for (const d of Object.keys(pli)) {
    formula = formula.replaceAll(new RegExp("\\b" + d, "g"), pli[d]);
  }
  return formula;
}

// const dimensionKeywords = ["width", "length", "height"]
