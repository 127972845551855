/**
 * Handles the selection of a document record.
 *
 * @param {string} value - The selected value.
 * @param {Object} fetchedData - The fetched data.
 * @param {Object} opt - Additional options.
 * @param {string} opt.recordName - The name of the record.
 * @param {Function} setFieldsValue - The function to set field values.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @param {Object} selectedMainField - The selected main field.
 * @param {Object} selectedMainField.apiData - The API data.
 * @param {string} selectedMainField.apiData.table - The table name.
 * @param {string} selectedMainField.apiData.primaryKey - The primary key.
 */
const onDocRecordSelect = ({
  value,
  fetchedData = {},
  opt = { recordName: "" },
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
  selectedMainField = {
    apiData: { table: "", primaryKey: "" },
  },
}) => {
  const arrayKey =
    selectedMainField?.apiData?.table === "scheduling"
      ? "schedules"
      : selectedMainField?.apiData?.table;

  const selectedRecord = fetchedData[arrayKey]?.find(
    (record) => record[selectedMainField?.apiData?.primaryKey] === value
  );

  dispatchRequestData({
    type: "DOCUMENTATION_RECORD_SELECT",
    payload: opt?.recordName,
  });

  if (selectedRecord?.hasOwnProperty("teamsConfiguration")) {
    const { teamsConfiguration } = selectedRecord;

    if (teamsConfiguration?.length) {
      setFieldsValue({
        team: teamsConfiguration?.map((el) => el?.value),
      });

      dispatchRequestData({
        type: "SELECTED_TEAM",
        payload: teamsConfiguration?.map((el) => ({
          value: el?.value,
          members: el?.members,
        })),
      });
    }
  }
};

export default onDocRecordSelect;
