import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  message,
  DatePicker,
  Input,
  InputNumber,
  Select,
} from "antd";
import moment from "moment";
import {
  MondayButton,
  RichTextEditor,
} from "../../../../../../../commonComponents";
import { RefreshIcon } from "../../../../../../Communication/assets";
import { CalendarIcon, SaveIcon } from "../../../../../../BasePage/src";
import "./RentalTermsTO.scss";
import { Close } from "../../../../../../DynamicView/src";
import { useRedux } from "../../../../../../hooks";
import dayjs from "dayjs";
import { InfoIcon } from "../../../../../../Inspections/assets";
import { useSelector } from "react-redux";
function RentalTermsTO({ service }) {
  const [form] = Form.useForm();
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [visible, setVisible] = useState(false);
  const [darkMode] = useRedux("estimationsDarkMode");
  const { programFields } = useSelector((state) => state.programFields);
  const [valueDays, setValueDays] = useState(28);
  const initialPayingTerms = gridData?.find(
    ({ serviceId = "" }) => serviceId === service?.serviceId
  )?.rentalPaymentTerms;
  const initialValues = {
    input: initialPayingTerms?.name ? initialPayingTerms?.name : "",
    payingTerms: initialPayingTerms?.description
      ? initialPayingTerms?.description
      : "",
    dateRange: initialPayingTerms?.range ? initialPayingTerms?.range : "",
    startingDate: !!initialPayingTerms?.startingDate
      ? dayjs(initialPayingTerms?.startingDate).tz("America/New_York")
      : undefined,
    // valueDays: initialPayingTerms?.valueDays
    //   ? initialPayingTerms?.valueDays
    //   : 28,
  };
  const [includeWeeks, setIncludeWeeks] = useState(
    !initialPayingTerms?.startingDate
  );
  const isButtonClickable = true; // else "buttonRentalTermsNotClickable";
  const rentalTos =
    programFields?.find(
      ({ fieldId }) => fieldId === "26354s4f-23f-28jkb3f23f-dsfndfgn-fgn"
    ) ?? [];

  console.log("🚀 ~ file: RentalTermsTO.jsx:64 ~ rentalTos", rentalTos);
  const toggleOpen = (open) => {
    setVisible(open);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    const temp = rentalTos?.fieldOptions?.find(
      ({ termId = "" }) => termId === value
    );
    console.log("temp", temp);
    let toSet = {
      input: temp?.name,
      payingTerms: temp?.description,
      dateRange: "",
      startingDate: undefined,
      // dateRange: temp?.range,
      // startingDate: temp?.range,
    };
    if (temp?.range >= 0) {
      toSet.dateRange = temp?.range;
      setIncludeWeeks(true);
    } else {
      toSet.startingDate = dayjs(temp?.range, "MM/DD/YYYY");
      setIncludeWeeks(false);
    }
    form.setFieldsValue(toSet);
  };

  const handleChangeDays = (value) => {
    console.log(`selected ${value}`);
    setValueDays(value);
  };

  const onSave = () => {
    let rentalTermsTemp = {
      name: form.getFieldsValue()?.input,
      ...(includeWeeks
        ? { range: form.getFieldValue("dateRange") }
        : { startingDate: form.getFieldValue("startingDate").format() }),
      description: form.getFieldsValue()?.payingTerms,
      valueDays: valueDays,
    };
    console.log(
      "🚀 ~ file: RentalTermsTO.jsx:57 ~ onSave ~ rentalTermsTemp:",
      rentalTermsTemp
    );
    if (!rentalTermsTemp?.name) {
      message.error("Please enter a name");
      return;
    }
    if (!rentalTermsTemp?.description) {
      message.error("Please enter a description");
      return;
    }
    // if (!rentalTermsTemp?.startingDate && !rentalTermsTemp?.range >= 0) {
    //   message.error("Please enter a range");
    //   return;
    // }
    const tempData = gridData;
    let myService = tempData.find(
      ({ serviceId = "" }) => serviceId === service.serviceId
    );
    if (!myService) return;
    myService.rentalPaymentTerms = rentalTermsTemp;
    console.log("myService", { myService, tempData });
    setTakeOffTableData(tempData);
    setTimeout(() => {
      toggleOpen(false);
      message.success("Rental Terms saved successfully");
    }, 0);
  };
  return (
    <>
      <Button
        style={
          {
            //  marginRight: 10
          }
        }
        onClick={() => toggleOpen(true)}
      >
        Rental Terms
      </Button>
      {visible && (
        <Modal
          className={`RentalTermsTO__modal__${
            darkMode ? "darkMode" : "lightMode"
          }`}
          width={904}
          title="You are now setting up Rental Terms"
          open={visible}
          maskClosable={false}
          onCancel={() => toggleOpen(false)}
          //   onOk={onSave}
          footer={
            <div className="RentalTermsTO__modal_footer">
              <MondayButton
                className="mondayButtonRed"
                Icon={<Close />}
                onClick={() => toggleOpen(false)}
              >
                Close & Don't Save
              </MondayButton>
              <MondayButton Icon={<SaveIcon />} onClick={onSave}>
                {" "}
                Save Rental Terms
              </MondayButton>
            </div>
          }
          centered
        >
          <div className="informationContainer">
            <InfoIcon /> Please create rental payment term by filling this form.{" "}
            <Select
              // defaultValue="lucy"
              placeholder="Select a term"
              // style={{ width: 120 }}
              onChange={handleChange}
              options={rentalTos?.fieldOptions?.map(
                ({ name = "", termId = "" }) => {
                  return { label: name, value: termId };
                }
              )}
            />{" "}
            <Select
              // defaultValue="lucy"
              placeholder="Select month days term (in days)"
              style={{ width: 200 }}
              value={valueDays}
              onChange={handleChangeDays}
              // onChange={handleChange}
              options={rentalDaysTerms}
            />
          </div>
          <Form {...{ form, initialValues, preserve: false }}>
            {/* pick name */}
            <div className="container">
              <div className="nameInputContainer">
                <div className="nameInputText">Name</div>
                <Form.Item {...{ name: "input" }}>
                  <Input className="nameInput" />
                </Form.Item>
              </div>{" "}
              <div className="inputSwitcherContainer">
                <div className="switcherHeader">
                  {includeWeeks ? "Number of weeks" : "Starting date"}
                </div>{" "}
                <div className="switcherBody">
                  {!!includeWeeks ? (
                    <Form.Item {...{ name: "dateRange" }}>
                      <InputNumber className="dateInput" min={0} />
                    </Form.Item>
                  ) : (
                    <Form.Item {...{ name: "startingDate" }}>
                      <DatePicker
                        className="dateInput"
                        dropdownClassName="filter-by-date-picker-dropdown"
                        suffixIcon={<CalendarIcon />}
                        format={"MM/DD/YYYY"}
                      />
                    </Form.Item>
                  )}
                  <MondayButton
                    className="mondayButtonBlue"
                    onClick={() =>
                      setIncludeWeeks((initialValue) => !initialValue)
                    }
                    Icon={<RefreshIcon />}
                  >
                    Switch
                  </MondayButton>
                </div>
              </div>
              {/* pick descriptions */}
            </div>{" "}
            <div className="rentalPayingTermsDescriptions">
              <RichTextEditor
                {...{
                  name: "payingTerms",
                }}
              />
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default RentalTermsTO;

export const rentalDaysTerms = [
  { label: "28 days", value: 28 },
  { label: "1 full month", value: "monthly" },
];
