import { SimplePLI } from "./GeneralModels";

export class FencePLI extends SimplePLI {
  serviceId = 4;

  length;
  height;
  material;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateFencePLI extends SimplePLI {
  serviceId = 4;

  length;
  height;
  material;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
