import React, { useState } from "react";

import { dispatchIcon, forceToInspectIcon } from "../../../assets";
import { compareIncluding } from "../../../../utils";

const ActionButton = ({
  icon,
  setVisible,
  setCurrentProject,
  data,
  disabled = false,
}) => (
  <img
    className={disabled ? "disabledActionButton" : ""}
    src={icon}
    alt={icon}
    onClick={
      disabled
        ? () => {}
        : () => {
            setCurrentProject(data);
            setVisible(true);
          }
    }
  />
);

const ActionCell = ({
  data,
  setDispatchModalVisible,
  setForceToInspectModalVisible,
  setCurrentProject,
}) => {
  return (
    <div className="actionCell">
      {/* <div className="actionButton" onMouseEnter={() => setHover(true)}>
        <img src={actionsIcon} alt={actionsIcon} />
      </div> */}
      {/* {hover && ( */}
      <div className="actionButton actionButtonsContainer">
        <ActionButton
          icon={dispatchIcon}
          setVisible={setDispatchModalVisible}
          setCurrentProject={setCurrentProject}
          data={data}
          disabled={compareIncluding(data?.inspectionStatus, "dispatched")}
        />
        <ActionButton
          icon={forceToInspectIcon}
          setVisible={setForceToInspectModalVisible}
          setCurrentProject={setCurrentProject}
          data={data}
          disabled={compareIncluding(data?.inspectionStatus, "pending")}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default ActionCell;
