import { Button } from "antd";
import { useState } from "react";

import ServicesForEach from "./ServicesForEach/ServicesForEach";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { roundToDecimals } from "../../../../utils/roundToDecimals";
import { numberWithCommas } from "../../../../utils/numberWithCommas";
import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";

import "./EstimationService.css";

export default function EstimationServiceTotal({ estimations }) {
  const [estimationSelected, setEstimationSelected] = useState(null);

  const handleServicesVisibility = (e) => {
    if (estimationSelected === e) {
      return setEstimationSelected(null);
    }
    setEstimationSelected(e);
  };

  return estimations?.map((estimation, key) => {
    const totalPrice = getTotalServicesPrice(estimation?.services);
    return (
      <div className="estimates_values_wrapper">
        {!isNaN(totalPrice) ? (
          <div index={key} className="estimation_title">
            <Button
              onClick={() => handleServicesVisibility(key)}
              icon={
                estimationSelected === key ? (
                  <MinusOutlined style={{ fontSize: "12px", color: "#fff" }} />
                ) : (
                  <PlusOutlined style={{ fontSize: "12px", color: "#fff" }} />
                )
              }
            />
            <h4>{estimation.name}</h4>
            <div className="est_horizontal_line">
              <hr></hr>
            </div>
            <div className="est_price_wrapper">
              {isNaN(totalPrice)
                ? "-"
                : numberWithCommas(roundToDecimals(totalPrice, 2))}
            </div>
          </div>
        ) : null}
        <div
          className={
            estimationSelected === key
              ? "services_wrapper_show"
              : "services_wrapper_hidden"
          }
        >
          {estimationSelected === key ? (
            <ServicesForEach estimation={estimation} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  });
}
