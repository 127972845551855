import dayjs from "dayjs";
import { formatNumber } from "../../../../../../../SidebarPages/utils";

/**
 * Function that return a duration text given the milliseconds passed
 * @param {number} durationValue
 * @param {string} durationUnit defaults to "milliseconds"
 * @returns The parsed duration string and the duration object
 */
function formatDurationDeg(durationValue, durationUnit = "milliseconds") {
  let duration = dayjs.duration({ [durationUnit]: durationValue });

  let d = duration.asDays();
  let h = duration.asHours();
  let m = duration.asMinutes();
  let durationText = "";

  if (parseInt(d)) {
    durationText += `${formatNumber(d, { unit: "day", fixed: 0 })} `;
  }

  if (parseInt(h)) {
    durationText += `${formatNumber(h - parseInt(d) * 24, {
      unit: "hour",
      fixed: 0,
    })} `;
  }

  durationText += `${formatNumber(m - parseInt(h) * 60, {
    unit: "minute",
    fixed: 0,
  })}`;

  return { text: durationText, durationObject: duration };
}

export default formatDurationDeg;
