export const styleSettings = {
  fontFamily: "sans-serif",
  colorText: "#1c1c1c",
  colorStrong: "#101010",
  colorTable: "#454545",
};

export const styleInvoice = {
  footerInvoiceCont: {
    boxSizing: "border-box",
    width: "590px",
    height: "110px",
    display: "grid",
    gridTemplateColumns: "65% 35%",
    gridTemplateRows: "auto",
    alignContent: "start",
    gap: "0",
  },

  textSide: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    padding: "5px 5px",
    justifySelf: "center",
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
  },
  textSideItem: {
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorText,
    fontSize: "8px",
    fontWeight: "200",
    textAlign: "center",
    wordWrap: "break-word",
  },

  itemsTable: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    justifySelf: "end",
    borderLeft: `1px solid ${styleSettings.colorTable}`,
    borderRight: `1px solid ${styleSettings.colorTable}`,
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto",
  },
  itemDatas: {
    width: "100%",
    height: "fit-content",
    padding: "2px 10px",
    borderBottom: `1px solid ${styleSettings.colorTable}`,
    display: "grid",
    gridTemplateColumns: "70% 30%",
    gridTemplateRows: "auto",
    alignContent: "center",
  },
  itemStrongText: {
    gridArea: "1/1/2/2",
    width: "100%",
    height: "fit-content",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorStrong,
    textAlign: "start",
    fontSize: "9px",
    fontWeight: "800",
  },
  itemLightText: {
    gridArea: "1/2/2/-1",
    width: "100%",
    height: "fit-content",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorText,
    textAlign: "end",
    fontSize: "9px",
    fontWeight: "800",
  },
  pageNumberText: {
    gridArea: "2/1/3/-1",
    width: "100%",
    height: "fit-content",
    marginTop: "10px",
    fontFamily: styleSettings.fontFamily,
    color: styleSettings.colorStrong,
    textAlign: "end",
    fontSize: "10px",
    fontWeight: "800",
  },
};
