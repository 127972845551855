// returns an ordinal formatted floor from a grid input e.g.: 122 -> 122nd floor level
const toOrdinalFloor = (params) => {
  if (params.value) {
    const value = parseInt(params.value);
    const j = value % 10,
      k = value % 100;
    if (j === 1 && k !== 11) {
      return value + "st floor level";
    } else if (j === 2 && k !== 12) {
      return value + "nd floor level";
    } else if (j === 3 && k !== 13) {
      return value + "rd floor level";
    } else if (isNaN(value)) {
      return params.value;
    } else {
      return value + "th floor level";
    }
  } else {
    return "-";
  }
};

export default toOrdinalFloor;
