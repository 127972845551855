import { useEffect, useRef, useState } from "react";
import { LeftArrow } from "../../../../../SidebarPages/BasePage/src";
import "./ScrollableContainer.scss";

/**
 * A scrollable container component that allows horizontal scrolling with arrows and mouse wheel.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the container element.
 * @param {ReactNode} props.children - The content to be rendered inside the container.
 * @param {number} [props.scrollValue=90] - The amount of pixels to scroll when the arrows are clicked.
 * @param {string} [props.bodyClassName] - The class name for the container body element.
 * @param {boolean} [props.wheelScroll=true] - Determines whether scrolling with the mouse wheel is enabled.
 * @param {boolean} [props.showArrows=true] - Determines whether the scroll arrows are visible.
 * @param {number} [props.wheelScrollSpeed=100] - The speed of scrolling with the mouse wheel.
 * @param {boolean} [props.transparentArrows=false] - Determines whether the scroll arrows are partly transparent and container has full width.
 * @returns {JSX.Element} The rendered ScrollableContainer component.
 */
function ScrollableContainer({
  className,
  children,
  scrollValue = 90,
  bodyClassName,
  wheelScroll = true,
  showArrows = true,
  wheelScrollSpeed = 100,
  transparentArrows = false,
}) {
  const containerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (showArrows) {
          setShowLeftArrow(container.scrollLeft > 0);
          setShowRightArrow(
            container.scrollLeft < container.scrollWidth - container.clientWidth
          );
        }
      };

      container.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const scrollLeftHandler = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= scrollValue;
    }
  };

  const scrollRightHandler = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += scrollValue;
    }
  };

  const handleWheel = (event) => {
    const delta = Math.max(-1, Math.min(1, event.deltaY));
    const newScrollLeft =
      containerRef.current.scrollLeft + delta * wheelScrollSpeed;
    containerRef.current.scrollLeft = newScrollLeft;
    // event.preventDefault();
  };

  return (
    <div
      data-testid="scrollable-container"
      className={`${className} scrollable-container`}
    >
      {showLeftArrow && (
        <div
          data-testid="scrollable-container-arrows"
          onClick={scrollLeftHandler}
          className={`scrollable-container-arrows ${
            transparentArrows && "left-arrow"
          }`}
        >
          <LeftArrow fill="currentColor" />
        </div>
      )}
      <div
        data-testid="scrollable-container-body"
        className={`${bodyClassName} scrollable-container-body`}
        ref={containerRef}
        onWheel={(e) => {
          wheelScroll && handleWheel(e);
        }}
      >
        {children}
      </div>
      {showRightArrow && (
        <div
          data-testid="scrollable-container-arrows"
          onClick={scrollRightHandler}
          className={`scrollable-container-arrows ${
            transparentArrows && "right-arrow"
          }`}
        >
          <LeftArrow
            fill="currentColor"
            style={{ transform: "rotate(180deg)" }}
          />
        </div>
      )}
    </div>
  );
}

export default ScrollableContainer;
