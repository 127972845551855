import { message } from "antd";
import {
  evaluateCornerExpression,
  evaluateMathExpression,
  getMaxNoFeet,
  toFeetInchesFormatter,
  toFixedFormatter,
} from "../formatters";
import { evaluateMonth } from "../formatters/evaluators";
import { toOrdinalFloor } from "../polyfillers";
import { convertFeetToInches } from "./hoist";
import { forceToNumber } from "src/components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const systemScaffold = [
  {
    headerName: "ID",
    headerTooltip: "ID",
    field: "id",
    minWidth: 35,
    width: 35,
    editable: false,
  },
  {
    headerName: "Approved",
    headerTooltip: "Approved",
    field: "approved",
    minWidth: 150,
    width: 150,
    editable: false,
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "L",
    headerTooltip: "Length",
    field: "length",
    width: 150,
    suppressSizeToFit: true,
    resize: true,
    valueSetter: (params) => {
      console.log("getter:,", params);
      params.data.length = convertFeetAndInchesToFeetDecimal(params.newValue);
      return true;
    },
    // valueFormatter: evaluateMathExpression,
    // cellEditor: "DimensionEditor",
    // cellRenderer: toFeetInchesFormatter,
  },
  {
    headerName: "H",
    headerTooltip: "Height",
    field: "height",
    width: 250,
    suppressSizeToFit: true,
    resize: true,
    // valueFormatter: evaluateMathExpression,
    // cellEditor: "DimensionEditor",
    // cellRenderer: toFeetInchesFormatter,
    valueSetter: (params) => {
      console.log("getter:,", params);
      params.data.height = convertFeetAndInchesToFeetDecimal(params.newValue);
      return true;
    },
  },
  {
    headerName: "W",
    headerTooltip: "Width",
    field: "width",
    width: 150,
    suppressSizeToFit: true,
    resize: true,
    // hide: true,
    // valueFormatter: evaluateMathExpression,
    valueSetter: (params) => {
      console.log("getter:,", params);
      params.data.width = convertFeetAndInchesToFeetDecimal(params.newValue);
      return true;
    },

    // cellEditor: "DimensionEditor",
    // cellRenderer: toFeetInchesFormatter,
  },
  {
    headerName: "From Floor",
    headerTooltip: "From Floor",
    field: "fromFloor",
    width: 120,
    valueFormatter: toOrdinalFloor,
  },
  {
    headerName: "From Floor Note",
    headerTooltip: "From Floor Note",
    field: "fromFloorNote",
    width: 120,
  },
  {
    headerName: "To Floor",
    headerTooltip: "To Floor",
    field: "toFloor",
    width: 120,
    valueFormatter: toOrdinalFloor,
  },
  {
    headerName: "To Floor Note",
    headerTooltip: "To Floor Note",
    field: "toFloorNote",
    width: 120,
  },
  {
    headerName: "Incl. Corners",
    headerTooltip: "Incl. Corners",
    field: "includedCorners",
    width: 120,
    valueFormatter: evaluateCornerExpression,
  },
  {
    headerName: "Addons",
    headerTooltip: "Addons",
    field: "addons",
    width: 200,
    minWidth: 140,
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    cellRendererParams: {
      suppressCount: true,
      // checkbox: true,
      innerRenderer: "SidewalkShedAddonsRenderer",
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
    },
    valueFormatter: (params) => {
      if (params?.data?.addons) {
        return `(${params.data?.addons?.length})`;
      } else {
        return "N/A";
      }
    },
  },
  {
    headerName: "Description",
    headerTooltip: "Description",
    field: "description",
    width: 150,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "General Notes",
    headerTooltip: "General Notes",
    field: "note",
    width: 150,
    cellRenderer: "rtfEditor",
  },
  {
    headerName: "Custom",
    headerTooltip: "Custom",
    field: "custom",
    minWidth: 40,
    width: 40,
    editable: false,
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "Sqf.",
    headerTooltip: "Sqf.",
    field: "sqft",
    minWidth: 80,
    width: 80,
    editable: false,
    valueGetter: (params) => {
      if (
        params.data.hasOwnProperty("length") &&
        params.data.hasOwnProperty("height")
      ) {
        return (
          Math.round(
            getMaxNoFeet(params.data.length) *
              getMaxNoFeet(params.data.height) *
              100
          ) / 100
        );
      } else {
        return "N/A";
      }
    },
  },
  // {
  //   headerName: "PPU",
  //   headerTooltip: "Price Per Unit",
  //   field: "ppu",
  //   cellRenderer: "SidewalkShedPPUAdvisor",
  //   minWidth: 100,
  //   width: 200,
  // },
  {
    headerName: "PPU",
    headerTooltip: "Price Per Unit",
    cellRenderer: "PriceTablePPURenderer",
    field: "ppu",
  },
  {
    headerName: "Rent",
    headerTooltip: "Rent",
    field: "rent",
    width: 80,
    cellRenderer: "SidewalkShedRentAdvisor",
  },
  {
    headerName: "Months",
    headerTooltip: "Months",
    field: "months",
    width: 80,
    valueFormatter: evaluateMonth,
  },
  {
    headerName: "Price",
    headerTooltip: "Price",
    field: "price",
    hide: false,
    width: 80,
    getQuickFilterText: () => "",
  },
  {
    headerName: "Lock",
    field: "lock",
    cellRenderer: "CheckboxRenderer",
    minWidth: 70,
    width: 70,
    editable: false,
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    valueFormatter: ({ value = 0 }) => `${value * 100}%`,
    editable: false,
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    valueFormatter: toFixedFormatter,
    editable: false,
  },
  {
    headerName: "Total",
    field: "totalPrice",
    valueFormatter: toFixedFormatter,
    editable: false,
  },
];

function convertFeetAndInchesToFeetDecimal(inputN) {
  try {
    let feetInches = inputN.toString();
    let feet = feetInches.split(".")[0] || 0;
    let inches = feetInches.split(".")[1] || 0;
    // Calculate the total in decimal feet
    const totalFeet = forceToNumber(feet) + forceToNumber(inches) / 12;

    // Round to two decimal places

    return totalFeet.toFixed(2);
  } catch (e) {
    message.error("Invalid input. 11.5 => 11 ft and 5 inches");

    return 0;
  }
}
