import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card } from "./Card";
import { useState } from "react";

function DraggableSort({ itemsToOrder = [], setItemsToOrder }) {
  const [cardState, setCardState] = useState(itemsToOrder);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const savedItems = [...cardState];
    const [movedItem] = savedItems.splice(result.source.index, 1);
    savedItems.splice(result.destination.index, 0, movedItem);
    setCardState(savedItems);
    setItemsToOrder(savedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="cardsDroppableId">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={style}
          >
            {cardState.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Card
                      key={item.id}
                      id={item.id}
                      text={item.label}
                      isDragging={snapshot.isDragging}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableSort;

const style = {
  width: 500,
  padding: 0,
  height: 600,
  overflowY: "scroll",
  overflowX: "hidden",
};
