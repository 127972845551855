import dayjs from "dayjs";
import { Modal, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useState, useContext, useCallback, useEffect } from "react";

import DegModalContext from "../DegModalContext";
import { groupEntriesInShifts, degFilterHandler } from "../utils";
import { otFilterData, overtimeColumns } from "./overtimeColumns";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import createPDF from "../../../../../../../../integrations/AgGridToPdf";
import { FilterIcon } from "../../../../../../../SidebarPages/BasePage/src";
import { ExcelIconWhite, PdfWhiteIcon } from "../../../../../../../../assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import Filter from "../../../../../../../SidebarPages/BasePage/components/Filter/Filter";
import {
  formatCurrency,
  compareIncluding,
} from "../../../../../../../SidebarPages/utils";

import "./OvertimeNamesModal.scss";

const OvertimeNamesModal = ({ open, onCancel }) => {
  const { analytics, crews, jobsites, rowData, degName, isDarkMode } =
    useContext(DegModalContext);
  const { base64 } = useSelector((state) => state.base64);
  const [overtimeShifts] = useState(
    groupEntriesInShifts({
      analytics,
      crews,
      jobsites,
      filterOvertime: true,
      rowData,
    })
  );
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [filtersData, setFiltersData] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);

  const location = useLocation();

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    setColumnApi(param.columnApi);
  }, []);

  function clearFilters() {
    setFiltersData({});
  }

  function exportGridToExcel() {
    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        const data = params.node.beans.data;
        if (
          headerName === "Work Hours" ||
          headerName === "Break Hours" ||
          headerName === "Overtime Hours"
        ) {
          return `${value || 0} h`;
        } else if (headerName === "Google Sheet Link") {
          return value?.googleSheetLink;
        } else if (headerName === "Clock In" || headerName === "Clock Out") {
          return dayjsNY(value).format("MMM/D/YY hh:mm a");
        } else if (headerName === "Punch Time") {
          return dayjs(value).format("hh:mm A");
        } else if (headerName === "Total Reg. Amount") {
          return formatCurrency(value || 0);
        } else if (headerName === "Total OT. Amount") {
          if (
            // data?.payrollType === "Prevailing Wage" ||
            data?.payrollType === "Open Shop" ||
            data?.payrollType === "Certified Payroll"
          ) {
            return formatCurrency(0);
          } else {
            return formatCurrency(data?.otAmount || 0);
          }
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  }

  function exportGridToPdf() {
    let rows = [];
    gridApi.forEachNode(({ data }) => {
      Object.assign(data, {
        firstClockIn: dayjsNY(data.firstClockIn).format("MMM/D/YY hh:mm a"),
        clockOut: dayjsNY(data.clockOut).format("MMM/D/YY hh:mm a"),
      });
      rows.push(data);
    });

    return createPDF({
      action: "download",
      gridApi: gridApi,
      rowData: rows,
      history: location?.pathname?.split("/")?.[1],
      historyTab: location?.pathname,
      gridColumnApi: columnApi,
      base64,
      masterDetail: true,
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE:
          `Overtime Statistics ${degName}` || "Overtime Statistics New DEG",
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  }

  useEffect(() => {
    degFilterHandler({ gridApi, filtersData, propFilterData: otFilterData });
  }, [JSON.stringify(filtersData), gridApi]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        onCancel();
        updateDocumentTitle(); // reset document title to "Lead Manager";
      }}
      title="Overtime Statistics"
      data-testid="overtime-names-modal"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Overtime Statistics" });
      }}
      className={`overtimeNamesModal ${isDarkMode && "overtimeNamesModalDark"}`}
      wrapClassName="overtimeNamesWrap"
      closable={true}
      closeIcon={<XIcon />}
      footer={null}
      centered
    >
      <div className="gridHeader">
        <MondayButton
          className="mondayButtonBlue"
          Icon={<FilterIcon />}
          onClick={() => setFilterOpen(true)}
          data-testid="overtime-names-filter-btn"
        >
          Open Filters
        </MondayButton>
        <Tooltip title="Export to PDF">
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={exportGridToPdf}
          >
            <PdfWhiteIcon width={15} height={15} />
          </MondayButton>
        </Tooltip>
        <Tooltip title="Export to Excel">
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={exportGridToExcel}
          >
            <ExcelIconWhite width={15} height={15} />
          </MondayButton>
        </Tooltip>
      </div>
      <div
        className={`overtimeGridContainer ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData: overtimeShifts,
            columnDefs: overtimeColumns,
            onGridReady,
            animateRows: true,
            pagination: true,
            paginationPageSize: 9,
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
            sideBar: {
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
            },
          }}
        />
      </div>
      {filterOpen && (
        <Filter
          getFilters={(val) => {
            setFiltersData(structuredClone(val));
          }}
          setOpen={setFilterOpen}
          filters={otFilterData}
          filtersData={filtersData}
          rowData={!!overtimeShifts?.length ? overtimeShifts : []}
          title={"Overtime Statistics"}
          clearFilters={clearFilters}
        />
      )}
    </Modal>
  );
};

export default OvertimeNamesModal;
