import { Suspense, useEffect, useMemo, useState } from "react";
import {
  FieldDashboardTable,
  FieldHeader,
  FieldUserProfile,
} from "./Components";
import "./Field.scss";
import { FieldCtx } from "./FieldContext";
import { LoadableComp } from "../../SidebarPages/XComponents";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ForemanProject from "./Components/ForemanProject/ForemanProject";
import { LanguageProvider } from "./Language/LanguageContext";
import { useSelector } from "react-redux";
import { message } from "antd";
import { updateDocumentTitle } from "../../../utils";
import { isEmpty } from "lodash";
import fetchForemanRowData from "./helpers/fetchForemanRowData";

function FieldUsersLayout(props) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState(null);

  const projectId = location?.pathname.includes("foremanProject")
    ? location?.pathname?.split("/")[2]
    : null;

  useEffect(() => {
    updateDocumentTitle({ newTitle: "Projects" });

    (async () =>
      await fetchForemanRowData({
        userConfiguration,
      })
        .then((res) => {
          setRowData(res);
          setLoading(false);
        })
        .catch((error) => {
          message.log("Failed to fetch data", error);
          setLoading(false);
        }))();
  }, []);

  useEffect(() => {
    if (projectId && !loading) {
      if (isEmpty(selectedProject)) {
        const found = rowData?.find((row) => row.projectId === projectId);
        if (!isEmpty(found)) {
          setSelectedProject(found);
        } else {
          navigate("/field");
        }
      } else if (
        !!selectedProject?.projectId &&
        selectedProject?.projectId !== projectId
      ) {
        setSelectedProject(rowData?.find((row) => row.projectId === projectId));
      }
    } else if (!projectId) {
      setSelectedProject(null);
    }
  }, [projectId, selectedProject, loading]);

  return (
    <LanguageProvider>
      <FieldCtx.Provider value={{}}>
        <div className="field-view-container">
          <FieldHeader authUser={props.props.authenticatedUser} />
          <div className="field-body-section">
            <Suspense fallback={<LoadableComp loading={true}></LoadableComp>}>
              <Routes>
                <Route path="/" element={<Navigate to="/field" replace />} />
                <Route
                  path="/field"
                  element={
                    <FieldDashboardTable
                      {...{ rowData, setSelectedProject, loading }}
                    />
                  }
                />
                <Route path="/field/profile" element={<FieldUserProfile />} />
                <Route
                  path={"/foremanProject/:id"}
                  element={
                    <ForemanProject
                      {...{
                        selectedProject,
                        loading,
                      }}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </div>
      </FieldCtx.Provider>
    </LanguageProvider>
  );
}

export default FieldUsersLayout;
