import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CardTemplate } from "../../../../pages/Settings/settingsComponents/Management/EditUser/components";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { SearchOutlined } from "@ant-design/icons";
import { FilterIcon } from "../../../../SidebarPages/BasePage/src";
import "src/components/pages/Settings/settingsComponents/Management/EditUser/components/UserList/NewUserList.scss";
import SessionUserListItem from "./SessionUserListItem";
import { loginStatusColors } from "../../../../pages/Settings/settingsComponents/AllUsers/allUsersData";
import { fetchData } from "../../../../SidebarPages/Fleet/utils";
import { SmallLoadableComp } from "../../../../Sidebars/components";
import { useSessionSocket } from "../../../../../hooks";
import updateSessions from "../helpers/updateSessionsArray";
import {
  applyFiltersSessionUsers,
  sortSessionUsers,
} from "../helpers/sortSessions";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { useSelector } from "react-redux";
import SessionUsersFilterDrawer from "./SessionUsersFilterDrawer";
import getAllRolesOptions from "../helpers/getAllRolesOptions";

function SessionUserList({ isDarkMode, online }) {
  const [userConfiguration, setUserConfiguration] = useState({});
  const { programFields } = useSelector((state) => state.programFields);
  const { userGroups } = useSelector((state) => state.userGroups);

  const { socket: wsSessions } = useSessionSocket();

  const [searchValue, setSearchValue] = useState("");
  const [filterByStatus, setFilterByStatus] = useState(null);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filters, setFilters] = useState({});

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const dispatch = useDispatch();

  function checkIdle(obj) {
    let newObj = { ...obj };
    const sessionIds = Object.keys(newObj);
    const allIdle =
      sessionIds?.every((sessionId) => newObj?.[sessionId]?.isIdle) || false;
    const maxIdleTime = Math.max(
      ...(sessionIds?.map((sessionId) => newObj?.[sessionId]?.idleTime) || null)
    );
    return { isIdle: allIdle, idleTime: maxIdleTime };
  }

  function checkIsOnline(obj) {
    let newObj = { ...obj };
    const sessionIds = Object.keys(newObj);
    return (
      sessionIds?.some((sessionId) => newObj?.[sessionId]?.isOnline) || false
    );
  }
  // function addNameOfUser(obj) {
  //   let newObj = { ...obj };
  //   function findName(id) {
  //     return (
  //       userConfiguration?.allUsers?.Items?.find(
  //         (user) => user?.identityId === id
  //       )?.nameOfUser || ""
  //     );
  //   }

  //   Object.keys(newObj?.statuses?.dev || {}).forEach((id) => {
  //     newObj.statuses.dev[id].nameOfUser = findName(id);
  //   });
  //   Object.keys(newObj?.statuses?.prod || {}).forEach((id) => {
  //     newObj.statuses.prod[id].nameOfUser = findName(id);
  //   });

  //   return newObj;
  // }

  const onlineStatusesHandler = useCallback(
    (msg) => {
      const message = JSON.parse(msg.data);
      const { request, body } = message;

      const env = process.env.NODE_ENV === "production" ? "prod" : "dev";
      if (request === "online-statuses") {
        //...handle online status changes
        const updatedUsers = [
          ...users.map((user) => {
            if (body?.statuses?.[env]?.[user?.identityId]) {
              return {
                ...user,
                onlineStatus:
                  checkIsOnline(
                    body?.statuses?.[env]?.[user?.identityId]?.sessions || {}
                  ) || false,
                // !!body?.statuses?.[env]?.[user?.identityId]?.onlineStatus ||
                // false,
                idleStatus:
                  // checkIdle(
                  //   body?.statuses?.[env]?.[user?.identityId]?.sessions || {}
                  // ) || { isIdle: false, idleTime: null },
                  body?.statuses?.[env]?.[user?.identityId]?.idleStatus ||
                  false,
                activeSessions:
                  updateSessions({
                    allSessions: user?.activeSessions?.map((el) => {
                      const { wsStatus, isIdle, ...session } = el;
                      return session;
                    }),
                    identityId: user?.identityId,
                    statuses: body?.statuses?.[env],
                  }) || [],
                ...(body?.statuses?.[env]?.[user?.identityId]?.lastSeen
                  ? {
                      lastSeen:
                        body?.statuses?.[env]?.[user?.identityId]?.lastSeen,
                    }
                  : {}),
              };
            } else return user;
          }),
        ];
        // dispatch(UserConfig({...userConfiguration, allUsers: { Items: updatedUsers}}));
        setUsers(updatedUsers);
      }
    },
    [userConfiguration]
  );

  useEffect(() => {
    if (wsSessions.readyState !== WebSocket.OPEN) {
      // console.log("online FALSE");

      return;
    }
    // console.log("online TRUE");
    try {
      wsSessions.addEventListener("message", onlineStatusesHandler);
      wsSessions.send(
        JSON.stringify({ request: "get-online-statuses", body: {} })
      );
    } catch (e) {
      console.log("Error sending get-online-statuses request", e);
    }

    return () => {
      wsSessions.removeEventListener("message", onlineStatusesHandler);
    };
  }, [onlineStatusesHandler, wsSessions]);

  useEffect(() => {
    fetchData("userConfiguration")
      .then((res) => {
        setUserConfiguration(res);
        setUsers(res?.allUsers?.Items?.filter((el) => !el?.isSuspended) || []);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setUserConfiguration({});
        setUsers([]);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredUsers(applyFiltersSessionUsers(users, filters));
  }, [users, filters]);

  // const filteredUsersMemo = useMemo(() => {
  //   return sortSessionUsers(users, filterByStatus);
  // }, [users, filterByStatus]);

  const searchedUsers = useMemo(() => {
    if (!searchValue) {
      return filteredUsers;
    } else {
      return filteredUsers.filter((user) =>
        user.nameOfUser.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  }, [searchValue, filteredUsers]);

  const allDepartments = useMemo(
    () =>
      programFields?.find(({ fieldName }) => fieldName === "Departments")
        ?.fieldOptions,
    [programFields]
  );

  const allRoles = useMemo(() => {
    return getAllRolesOptions({
      userGroups,
      departments: filters?.departments,
    });
  }, [filters]);

  const clearFilters = () => {
    setFilteredUsers(applyFiltersSessionUsers(users, {}));
    setFilters({});
    setSearchValue("");
    setFiltersVisible(false);
  };

  const applyFilters = () => {
    setFilteredUsers(applyFiltersSessionUsers(users, filters));
    setFiltersVisible(false);
  };

  const hasFilters = useMemo(() => {
    return (
      Object.keys(filters)?.some((key) => filters?.[key]?.length > 0) ||
      !!searchValue
    );
  }, [searchValue, filters]);

  return (
    <CardTemplate
      {...{
        className: `user-list-container ${
          isDarkMode ? "user-list-container-dark" : ""
        } session-user-list`,
        isDarkMode,
      }}
    >
      <div className="usersSearch">
        <InputComponent
          id="searchUsers"
          placeholder="Search User..."
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          prefix={<SearchOutlined />}
        />
        <div className="session-filters-buttons">
          <MondayButton
            {...{
              "data-testid": "filter-button",
              onClick: () => {
                setFiltersVisible(true);
              },
              className: "mondayButtonGrey",
              Icon: <FilterIcon />,
            }}
          >
            Filters
          </MondayButton>
          {hasFilters && (
            <MondayButton
              {...{
                "data-testid": "clear-filters-button",
                onClick: clearFilters,
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            />
          )}
        </div>
      </div>
      <div className="user-list-table">
        <div className="user-list-table-header">
          <span className="user-header">User</span>
          <span className="department-header">Department</span>
          <span
            className="status-header"
            style={{
              color:
                !filterByStatus || filterByStatus === "Clear"
                  ? isDarkMode
                    ? "#fff"
                    : "#323338"
                  : filterByStatus === "Online"
                  ? loginStatusColors.loggedIn
                  : filterByStatus === "Offline"
                  ? loginStatusColors.loggedOut
                  : loginStatusColors.suspended,
            }}
          >
            {!filterByStatus || filterByStatus === "Clear"
              ? "Status"
              : filterByStatus}{" "}
            {`(${searchedUsers?.length || 0})`}
            {/* <Popover
              {...{
                placement: "left",
                trigger: "hover",
                overlayClassName: `popoverLinkOverview ${
                  isDarkMode && "darkPopoverLinkOverview"
                }`,
                content: (
                  <div
                    className={`linksContainer ${
                      isDarkMode && "linksContainerDark"
                    }`}
                  >
                    {["Online", "Offline", "Logged Out", "Clear"].map(
                      (status, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setSearchValue("");
                              setFilterByStatus(status);
                            }}
                            className="popoverContentItem"
                          >
                            {status}
                          </div>
                        );
                      }
                    )}
                  </div>
                ),
              }}
            >
              <FilterIcon style={{ fill: isDarkMode ? "#fff" : "#323338" }} />
            </Popover> */}
          </span>
        </div>
        <SmallLoadableComp {...{ loading }}>
          <div className="user-list-table-body">
            {searchedUsers.map((user) => (
              <SessionUserListItem
                key={user?.identityId}
                {...{
                  data: user,
                  value: user?.nameOfUser,
                }}
              />
            ))}
          </div>
        </SmallLoadableComp>
      </div>
      {filtersVisible && (
        <SessionUsersFilterDrawer
          {...{
            filtersVisible,
            setFiltersVisible,
            filters,
            setFilters,
            clearFilters,
            allDepartments,
            allRoles,
            isDarkMode,
          }}
        />
      )}
    </CardTemplate>
  );
}

export default SessionUserList;
