import {
  teamSelect,
  projectManagerSelect,
  assignedToObject,
  REQUEST_API_KEYS,
} from "..";
import { debounce, uniqBy } from "lodash";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import IndividualLoader from "../../../../../IndividualLoader";
import sortFields from "../sortFields";

/**
 * Generates an array of input fields for the permit drawing form.
 *
 * @param {Array} teams - The array of teams.
 * @param {Array} projects - The array of projects.
 * @param {Array} accounts - The array of accounts.
 * @param {string} requestId - The ID of the request.
 * @param {Array} inputFields - The array of input fields.
 * @param {boolean} isDarkMode - Indicates whether the dark mode is enabled.
 * @param {Array} projectManagers - The array of project managers.
 * @param {Object} currentPageKey - The current page key.
 * @param {boolean} recordsLoading - Indicates whether the records are loading.
 * @param {Array} projectExecutives - The array of project executives.
 * @param {boolean} globalDisabled - Indicates whether all fields are disabled.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {string} selectedMainField - The selected main field.
 * @param {boolean} allFieldsDisabled - Indicates whether all fields are disabled.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} setRecordsLoading - The function to set the records loading state.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The sorted array of input fields.
 */
const permitDrawingInputFields = ({
  teams = [],
  projects = [],
  accounts = [],
  requestId = "",
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  currentPageKey = null,
  recordsLoading = false,
  projectExecutives = [],
  globalDisabled = false,
  userConfiguration = {},
  onTeamSelect = () => {},
  selectedMainField = null,
  allFieldsDisabled = false,
  onMainFieldSelect = () => {},
  setRecordsLoading = () => {},
  dispatchRequestData = () => {},
}) => {
  const onSearch = debounce(
    async (searchValue) =>
      await searchFetchInput({
        table: "projects",
        idKey: "projectId",
        arrayKey: "projects",
        searchKey: "projectName",
        searchValue,
        keysToInclude: REQUEST_API_KEYS["Permit Drawing"].projects,
        setLoading: setRecordsLoading,
        setRecords: (res) =>
          dispatchRequestData({
            type: "SEARCH_FETCH",
            payload: {
              projects: userAccessRecords(
                userConfiguration,
                uniqBy([...(res || []), ...(projects || [])], "projectId")
              ),
            },
          }),
        customSetRecords: true,
        setCurrentPageKey: (val) => {
          currentPageKey.current = val;
        },
        userConfiguration,
      }),
    500
  );

  const onPopupScroll = async (e) =>
    handlePopupScroll({
      e,
      table: "projects",
      idKey: "projectId",
      currentPageKey: currentPageKey.current.projects,
      keysToInclude: REQUEST_API_KEYS["Permit Drawing"].projects,
      setLoading: setRecordsLoading,
      setRecords: (res) => {
        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload: {
            projects: userAccessRecords(
              userConfiguration,
              uniqBy([...(res || []), ...(projects || [])], "projectId")
            ),
          },
        });
      },
      userConfiguration,
      setCurrentPageKey: (val) => {
        currentPageKey.current = { projects: val };
      },
      customSetRecords: true,
    });

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  // const reducedAccounts = (accounts || [])?.reduce(
  //   (acc, curr) => ({
  //     ...acc,
  //     [curr?.accountId]: curr,
  //   }),
  //   {}
  // );

  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;

    // delete field?.defaultField;

    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "jobsiteAddress") {
      delete field?.options;

      Object.assign(field, {
        customOptions: (projects || [])?.map((el) => ({
          value: el?.projectId,

          label:
            `${el?.projectName} ` +
            ` ― ` +
            ` Client : ${el?.accountName || "N/A"}`,

          recordName: el?.projectName,
        })),

        disabled: !!allFieldsDisabled || globalDisabled || !!requestId,

        style: { minWidth: 300 },
        showSearch: true,
        allowClear: true,

        onSelect: (val, opt) =>
          onMainFieldSelect({
            val,
            opt: { category: "projects", recordName: opt?.recordName },
          }),

        onClear: () => onMainFieldSelect({ val: "", clear: true }),

        onSearch,

        onPopupScroll,

        dropdownRender,
      });
    }

    if (formItemName === "projectExecutive") {
      delete field?.options;

      Object.assign(field, {
        disabled:
          !!allFieldsDisabled ||
          globalDisabled ||
          !!selectedMainField?.projectExecutive,

        options: (projectExecutives || [])?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
      });
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Permit Drawings",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "client") {
      delete field?.options;

      Object.assign(field, {
        disabled: true,
        customOptions: (accounts || [])?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default permitDrawingInputFields;
