import { Checkbox, message } from "antd";
import Swal from "sweetalert2";
import { useRedux } from "../../../../../../../../../hooks/useRedux";
import {
  forceToNumber,
  validateThenSavePayment,
} from "../../../../utils/checkers";
import { calculateInvoicePaidItem } from "../../utils/calculators";
import { WithTooltip } from "../../../../../../../../../../commonComponents";

const PaymentCheckbox = ({ data, paymentData, checked, indeterminate }) => {
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable] = useRedux("paymentIsWritable");

  const isDisabled =
    paymentData && (!isWritable || data?.invoiceAmountDue === 0);

  const handleCheckBoxChange = () => {
    const inputPayment = checked || indeterminate ? 0 : data.invoiceAmountDue;
    const currentInvoiceDue = data?.invoiceAmountDue;
    let tempApplied =
      fakePayment?.invoices?.reduce((a, b) => {
        if (b?.invoiceNumber !== data?.invoiceNumber) {
          return a + forceToNumber(b.invoicePaidAmount);
        } else {
          return a;
        }
      }, 0) + inputPayment;

    if (tempApplied > fakePayment?.appliedAmount) {
      message.info("You can't apply more than the total payment amount.");
      let tempAmount =
        fakePayment?.appliedAmount - (tempApplied - inputPayment);
      let toSet =
        currentInvoiceDue <= tempAmount ? currentInvoiceDue : tempAmount;
      console.log("toSet", {
        toSet,
        currentInvoiceDue,
        tempAmount,
        data,
        applied: fakePayment?.appliedAmount,
        tempApplied: tempApplied - inputPayment,
      });
      const newFakePayment = calculateInvoicePaidItem({
        fakePayment,
        invoicePaidNumber: data?.invoiceNumber,
        inputPayment: forceToNumber(toSet),
        checked,
      });

      setFakePayment(validateThenSavePayment(newFakePayment));
      return;
    } else {
      const newFakePayment = calculateInvoicePaidItem({
        fakePayment,
        invoicePaidNumber: data?.invoiceNumber,
        inputPayment,
        checked,
      });

      setFakePayment(validateThenSavePayment(newFakePayment));
    }
  };

  return (
    <div className="payment-project-item">
      <WithTooltip tooltipCategory="Payments" tooltipKey="selectInvoice">
        <Checkbox
          className="payment-project-checkbox"
          indeterminate={indeterminate}
          disabled={isDisabled}
          checked={checked}
          onClick={() => handleCheckBoxChange()}
        />
      </WithTooltip>
    </div>
  );
};

export default PaymentCheckbox;
