import { get_progress_status } from "../../helpers/styles";
import CustomProgressBar from "../../../../commonComponents/CustomProgress/CustomProgressBar";
import "./ItemStatisticsView.scss";
import {
  progressInPerc,
  statusTextAgGrid,
} from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
export const ItemStatisticsView = ({
  item_state,
  statistics: { scheduled, remaining },
  d1,
  d2,
  onlyPercentage,
  typeOfWorkType,
}) => {
  let unit = d2 === undefined ? "Lnf." : "Sqf.";
  if (onlyPercentage) unit = "%";
  const progress_status_class = get_progress_status({
    scheduled,
    progress: item_state?.totalProgress || 0,
  });

  const progressInPercentage = progressInPerc(
    item_state?.totalProgress,
    d1,
    d2,
    item_state
  );

  return (
    <div className={"item-statistics-view"}>
      <span>
        <b style={{ marginLeft: 10, fontWeight: 600 }}> Progress</b>
        <span className={progress_status_class}>
          <CustomProgressBar progressDone={progressInPercentage || 0} />
        </span>
        <span style={{ marginLeft: 10 }}>
          {item_state?.totalProgress} {unit}
        </span>
      </span>
      <span>
        <b style={{ marginLeft: 10, fontWeight: 600 }}>Status</b>
        <div
          className={progress_status_class}
          style={{
            background: "red",
            color: "#fff",
            // marginTop: 5,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: 200,
            height: 32,
            borderRadius: 2,
          }}
        >
          {isNaN(item_state?.totalProgress)
            ? statusTextAgGrid[typeOfWorkType]?.notStarted
            : item_state?.totalProgress >= 1 &&
              item_state?.totalProgress <
                (d2 === undefined
                  ? item_state[d1]
                  : item_state[d1] * item_state[d2])
            ? statusTextAgGrid[typeOfWorkType]?.inProgress
            : Number(item_state?.totalProgress) === Number(scheduled)
            ? statusTextAgGrid[typeOfWorkType]?.finished
            : Number(item_state?.totalProgress) ===
              (d2 === undefined
                ? item_state[d1]
                : item_state[d1] * item_state[d2])
            ? statusTextAgGrid[typeOfWorkType]?.finished
            : item_state?.totalProgress > scheduled
            ? statusTextAgGrid[typeOfWorkType]?.over
            : statusTextAgGrid[typeOfWorkType]?.notStarted}
        </div>
      </span>
      <span>
        <b style={{ marginLeft: 10, fontWeight: 600 }}>Remaining</b>
        {
          <span className={progress_status_class}>
            <CustomProgressBar progressDone={100 - progressInPercentage || 0} />
          </span>
        }
        <span style={{ marginLeft: 10 }}>
          {remaining} {unit}
        </span>
      </span>
    </div>
  );
};
