import { Tooltip, Checkbox } from "antd";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../../../../commonComponents";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../Roles/src";
import { PlusIcon } from "../../../../../../../assets";
import { camelCaseToNormalText } from "../../../../../../commonComponents/RowDataGridModal/components/utils";

export function RecordsToUpdateFooter({
  disabled,
  onClose,
  onSave,
  saveText = "Confirm",
  saveClassName = "mondayButtonGreen",
  Icon = <TickIcon />,
  checkRecords = {},
}) {
  const { visible, onClick, checkDisabled, loading } = checkRecords;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 20,
      }}
    >
      {visible ? (
        <MondayButton
          {...{
            onClick,
            disabled: checkDisabled,
            className: "mondayButtonBlue",
            Icon: <PlusIcon />,
            loading,
          }}
        >
          Check Records
        </MondayButton>
      ) : (
        <>
          <MondayButton
            {...{
              className: "mondayButtonRed",
              Icon: <XIcon />,
              onClick: onClose,
            }}
          >
            Close
          </MondayButton>
          <MondayButton
            {...{
              onClick: onSave,
              Icon,
              disabled,
              className: saveClassName,
            }}
          >
            {saveText}
          </MondayButton>
        </>
      )}
    </div>
  );
}

export function CollapseExtra({
  topic,
  searchInput,
  setSearchInput,
  onCheckAllChange,
  checkAll,
  indeterminate,
}) {
  const normalTopic = camelCaseToNormalText(topic);
  return (
    <div className="extraContainer">
      <InputComponent
        {...{
          value: searchInput[topic],
          type: "input",
          className: "searchInput",
          placeholder: `Search ${normalTopic}...`,
          onChange: (e) =>
            setSearchInput((prev) => ({
              ...prev,
              [topic]: e.target.value,
            })),
          onClear: () =>
            setSearchInput((prev) => ({
              ...prev,
              [topic]: "",
            })),
          allowClear: true,
        }}
      />
    </div>
  );
}

export const TopicTitle = ({
  topic,
  onCheckAllChange,
  checkAll,
  indeterminate,
}) => {
  const normalTopic = camelCaseToNormalText(topic);
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        alignItems: "center",
      }}
    >
      <Tooltip
        {...{
          title: `Check All ${normalTopic}`,
        }}
      >
        <Checkbox
          {...{
            onChange: onCheckAllChange,
            checked: checkAll,
            indeterminate,
          }}
        />
      </Tooltip>
      <span>{normalTopic}</span>
    </div>
  );
};
