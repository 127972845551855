import { camelToTitle } from "src/utils";
import { formatNumber } from "../../utils";
import { FleetType, SideInfoType, MappedSectionType } from "../types";

type FleetKeys = Array<keyof FleetType>;

const defaultKeys: FleetKeys = [
  "fleetName",
  "fleetStatus",
  "fleetMake",
  "fleetYear",
];

const DATE_FORMAT = "MMM DD, YYYY";

function getSideVehicleInfo(
  vehicle: FleetType,
  colors?: MappedSectionType,
  propKeys?: FleetKeys
): SideInfoType {
  const config: SideInfoType = [];
  const keys = propKeys ?? defaultKeys;

  if (!vehicle) {
    return config;
  }

  for (const property of keys) {
    const key = property as keyof FleetType;
    const label = camelToTitle(key);

    const data = {
      value: vehicle[key] || "No Data",
    };

    if (key === "fleetStatus") {
      if (!colors) {
        continue;
      }

      data["color"] =
        colors.statuses.find(({ status }) => status === vehicle.fleetStatus)
          ?.color || "transparent";
    } else if (key === "createdAt") {
      data["format"] = DATE_FORMAT;
    } else if (key.includes("Date") || key.includes("Exp")) {
      data["format"] = DATE_FORMAT;
    } else if (key === "odometerReading") {
      data["value"] = formatNumber(+data["value"], { unit: "mile" });
    } else if (key === "fleetName") {
      data["to"] = `/fleets/${vehicle.fleetId}`;
    }

    config.push({
      label,
      data,
    });
  }

  return config;
}

export default getSideVehicleInfo;
