import { categories } from "../../utils/constants";

export const mapEstimationsWithApplicationInfo = (result, responses) => {
  const applications = responses[categories?.indexOf("applications")];

  return result?.map((estimation) => ({
    ...estimation,
    ...(estimation?.includedTo
      ? {
          applicationInfo: (() => {
            const application = applications?.find(
              ({ applicationId }) => applicationId === estimation?.includedTo
            );
            return {
              applicationNo: application?.applicationNo,
              scheduleId: application?.scheduleId,
            };
          })(),
        }
      : {}),
  }));
};
