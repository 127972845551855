import { FileTextOutlined } from "@ant-design/icons";
import { MondayButton } from "../../../../../../../commonComponents";
import { useState } from "react";
import MultiplyNotesDrawer from "./MultiplyNotesDrawer";
import { filterTables } from "../../../../../../../../utils";
import { Tooltip } from "antd";

const MultiplyNotes = ({ notes }) => {
  const [drawerState, setDrawerState] = useState(false);
  const [clickedShape, setClickedShape] = useState();
  const [currNotes, setCurrNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickedShape = async (type) => {
    setClickedShape(type);
    setLoading(true);
    if (type) {
      return await filterTables("notes", "recordId", type.shapeId).then(
        (res) => {
          setCurrNotes(res);
          setLoading(false);
        }
      );
    } else {
      setCurrNotes([]);
      setLoading(false);
    }
  };

  const handleDrawer = (notes) => {
    if (typeof notes !== "boolean") {
      setDrawerState(notes);
    }
    if (typeof notes === "boolean") {
      setDrawerState(false);
      setCurrNotes([]);
      setClickedShape();
    }
  };

  return (
    <div>
      <Tooltip title="Notes">
        <MondayButton
          Icon={<FileTextOutlined />}
          className="mondayButtonBlue"
          onClick={() => handleDrawer(notes)}
        />
      </Tooltip>

      {drawerState && (
        <MultiplyNotesDrawer
          open={drawerState}
          setOpen={() => handleDrawer(false)}
          handleClickedShape={handleClickedShape}
          currNotes={currNotes}
          clickedShape={clickedShape}
          setCurrNotes={setCurrNotes}
          loading={loading}
        />
      )}
    </div>
  );
};

export default MultiplyNotes;
