/**
 *
 * @param {Array} departments list of departments for select component
 * @param {form} form  form component
 * @returns
 */
export const SignupFormData = (
  departments,
  roles,
  setRoleOptions,
  form,
  isDarkMode
) => [
  {
    label: "First Name",
    required: true,
    formItemName: "firstName",
    placeholder: "Type here...",
    className: "formElement",
    rules: [
      {
        pattern: new RegExp("^[A-Z][a-z]{1,19}$"),
        message: "Please enter a valid name",
      },
    ],
  },
  {
    label: "Last Name",
    required: true,
    formItemName: "lastName",
    placeholder: "Type here...",
    className: "formElement",
    rules: [
      {
        pattern: new RegExp("^[A-Z][a-z]{1,19}$"),
        message: "Please enter a valid surname",
      },
    ],
  },
  {
    label: "Department",
    required: true,
    formItemName: "department",
    placeholder: "Select",
    className: "formElement",
    dropdownClassName: isDarkMode ? "darkDropDown" : "",
    type: "select",
    onSelect: (e) => setRoleOptions(e),
    options: departments,
  },
  {
    label: "Role",
    required: true,
    formItemName: "role",
    placeholder: "Select",
    className: "formElement",
    type: "select",
    dropdownClassName: isDarkMode ? "darkDropDown" : "",
    options: roles || [],
  },
  {
    required: true,
    label: "Phone Nr.",
    formItemName: "phone_number",
    placeholder: "Type here...",
    disableCountryCode: false,
    type: "Phone",
    onlyUsPrefix: false,
    // mask: "(111) 111-1111",
    className: "formElement",
    rules: [
      {
        require: true,
        message: "Please complete the mobile number",
      },
    ],
  },
  {
    label: "Email",
    required: true,
    formItemName: "username",
    placeholder: "emailhere@email.com",
    className: "formElement",
    rules: [
      {
        type: "email",
        message: "Please enter a valid email",
      },
    ],
  },

  {
    label: "Password",
    required: true,
    formItemName: "password",
    placeholder: "*********",
    className: "formElement",
    typePassword: true,
  },
  {
    label: "Confirm Password",
    required: true,
    formItemName: "confirmPassword",
    placeholder: "*********",
    className: "formElement",
    typePassword: true,
    rules: [
      {
        validator: (_, value) =>
          !!value
            ? form.getFieldValue("password") === value
              ? Promise.resolve()
              : Promise.reject(new Error("Passwords are not the same"))
            : Promise.resolve(),
      },
    ],
  },
];
/**
 * Json array for confirm form
 */
export const ConfirmData = [
  {
    label: "Verification Code",
    required: true,
    formItemName: "verifyCode",
    placeholder: "Verification Code",
    className: "formElement",
  },
];
