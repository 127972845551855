import { Form, Button } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import "./RichText.scss";
import ReactQuill from "react-quill";

const RichText = ({
  form,
  formItemName,
  initialValue,
  disabled = false,
  setValue,
  rules = [],
  onChange,
  value,
  placeholder,
}) => {
  return (
    <div className="richTextComp">
      {form && (
        <Form.Item
          name={formItemName}
          rules={rules}
          initialValue={initialValue}
        />
      )}
      <ReactQuill
        disabled={disabled}
        initialValue={initialValue}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          // onChange(e);
          return form
            ? form.setFieldsValue({ [formItemName]: e })
            : setValue(e);
        }}
      />
      {/* <Editor
        disabled={disabled}
        init={{
          plugins: "link image code codesample insertdatetime",
          toolbar:
            "code | undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | code" +
            "removeformat insertdatetime ",
        }}
        // value={value}
        initialValue={initialValue}
        onChange={onChange}
        onEditorChange={(e) => {
          return form
            ? form.setFieldsValue({ [formItemName]: e })
            : setValue(e);
        }}
      /> */}
    </div>
  );
};
export default RichText;
