import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../DEG/components/modalComponents/utils/cellFunctions";

export const analyticColumns = [
  {
    title: "Employee Full Name",
    dataIndex: "employeeFullName",
    key: "employeeFullName",
  },
  {
    title: "Employee Id",
    dataIndex: "employeeId",
    key: "employeeId",
  },
  {
    title: "Punch Date",
    dataIndex: "punchDate",
    key: "punchDate",
    render: (data) => dayjsNY(data).format(DEG_DATE_FORMAT),
  },
  {
    title: "Punch Time",
    dataIndex: "punchTime",
    key: "punchTime",
    render: (data) => dayjsNY(data).format(DEG_TIME_FORMAT),
  },
  {
    title: "Punch Type",
    dataIndex: "punchType",
    key: "punchType",
  },
  {
    title: "Jobsite Match",
    dataIndex: "jobsiteMatch",
    key: "jobsiteMatch",
    render: (data) => data.jobName,
  },
];
