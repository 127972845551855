import Logout from "../../../../Header/components/Logout/Logout";
import "./FieldHeader.scss";
import { NavLink, useNavigate } from "react-router-dom";
import UserAvatar from "../../../../Header/components/user-avatar/UserAvatar";
import { useMediaQuery } from "react-responsive";
import FieldMobileHeader from "./Mobile/FieldMobileHeader";
import LanguageSelector from "../../Language/LanguageSelector";
import { useSelector } from "react-redux";
function FieldHeader({ authUser }) {
  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const navigate = useNavigate();
  const nameOfUser = userConfiguration?.nameOfUser || "Field";
  return isMobileView ? (
    <div className="field-title-section">
      <h3
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/field");
        }}
      >
        {nameOfUser}
      </h3>
      <div className="field-header-right-actions">
        <NavLink to={{ pathname: "/field/profile" }}>
          <UserAvatar />
        </NavLink>
        <Logout />
        <LanguageSelector />
      </div>
    </div>
  ) : (
    <FieldMobileHeader />
  );
}

export default FieldHeader;
