import { Input } from 'antd'
import './Email.scss'
const FormItemEmail = ({ value, form, name, disabled }) => {
  return (
    <div className="EditEmail">
      <Input
        className="EditEmailComponent"
        defaultValue={value}
        disabled={disabled}
        onChange={(e) => form.setFieldsValue({ [name]: e.target.value })}
      />
    </div>
  )
}
export default FormItemEmail
