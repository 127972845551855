import {
  appliedRentalGenerator,
  totalitiesTaxGen,
} from "./RentalBreakdownFooterFunctions";
import { formatCurrency } from "../../../../../../../../../utils";
import { useRedux } from "../../../../../../../../../hooks";
import "./RentalBreakdownFooter.scss";
// import { useStateContext } from "../../../../../Context/Context"
import AnimatedNumber from "react-animated-number";
import CustomProgressBar from "../../../../../../../../../../commonComponents/CustomProgress/CustomProgressBar";

const RentalBreakdownFooter = ({ elevationData }) => {
  // const { darkMode, fakeRentals } = useStateContext()

  const [selectedService] = useRedux("rentalsSelectedService");
  const [darkMode] = useRedux("rentalsDarkMode");
  const [fakeRentals] = useRedux("fakeRentals");

  //
  const appliedToRows = appliedRentalGenerator({
    fakeRentals,
    elevationData,
    selectedService,
  });
  const totalitiesAndTax = totalitiesTaxGen({
    fakeRentals,
    elevationData,
    selectedService,
  });
  console.log("appliedToRows", totalitiesAndTax);

  return (
    <div
      className={
        darkMode ? "RentalBreakdownFooterDark" : "RentalBreakdownFooterLight"
      }
    >
      <div className="footerInformationContainer">
        <div className="footerTextContainer">Applied Rent:</div>
        <div
          className="footerValueContainer"
          style={
            appliedToRows === 0 ? { color: "#FE4C4A" } : { color: "#71CF48" }
          }
        >
          <AnimatedNumber
            value={appliedToRows}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        <div className="footerTextContainer">Tax:</div>
        <div
          className="footerValueContainer"
          style={
            appliedToRows === 0 ? { color: "#FE4C4A" } : { color: "#71CF48" }
          }
        >
          <AnimatedNumber
            value={totalitiesAndTax?.totalTax}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        <div className="footerTextContainer">
          <strong>Total:</strong>
        </div>
        <div
          className="footerValueContainer"
          style={
            appliedToRows === 0 ? { color: "#FE4C4A" } : { color: "#71CF48" }
          }
        >
          <AnimatedNumber
            value={totalitiesAndTax?.totalPrice}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        <div className="footerTextContainer">
          <strong>Retainage:</strong>
        </div>
        <div
          className="footerValueContainer"
          style={
            appliedToRows === 0 ? { color: "#FE4C4A" } : { color: "#71CF48" }
          }
        >
          <AnimatedNumber
            value={totalitiesAndTax?.retainageAmount || 0}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default RentalBreakdownFooter;
