import { axesTooltipCustom } from "../../../../../../helpers/axesTooltip";

export const chartConfigs = [
  {
    chartName: "department",
    groupKey: "departmentName",
    xAxisName: "Department Name",
  },
  {
    chartName: "role",
    groupKey: "groupName",
    xAxisName: "Role",
  },
];

export const getChartingOptions = (data) => {
  let department = new Set();
  let role = new Set();

  data.forEach((item) => {
    const userName = item.departmentName || "No Data";
    const userRole = item.groupName || "No Data";

    department.add(userName);
    role.add(userRole);
  });

  const countOnMake = {
    title: {
      text: "Total Users Count",
    },
    subtitle: {
      text: "Based on Department",
    },
    series:
      Array.from(department).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Users"),
          },
        };
      }) || [],
  };

  const countOnModel = {
    title: {
      text: "Total Users Count",
    },
    subtitle: {
      text: "Based on Role",
    },
    series:
      Array.from(role).map((item) => {
        return {
          type: "bar",
          xKey: "xAxisName",
          yKey: item,
          yName: item,
          tooltip: {
            renderer: (params) => axesTooltipCustom(params, "Users"),
          },
        };
      }) || [],
  };

  const chartingOptions = [
    {
      optionTitle: "Show total Users count based on Department",
      params: countOnMake,
      key: "department",
    },
    {
      optionTitle: "Show total Users count based on Role",
      params: countOnModel,
      key: "role",
    },
  ];

  return chartingOptions;
};
