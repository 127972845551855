/**
 * Generates a redirect URL based on the provided category and record ID.
 *
 * @param {string} category - The category of the record.
 * @param {string} recordId - The ID of the record.
 * @returns {string|null} - The redirect URL if both category and record ID are provided, otherwise null.
 */
const requestNotifRedirect = (category, recordId) => {
  if (!!category && !!recordId) {
    return `${category}/${recordId}`;
  }
  return null;
};

export default requestNotifRedirect;
