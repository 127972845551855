import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { message, Modal, Switch } from "antd";
import { GridApi } from "ag-grid-enterprise";
import { CellEditingStoppedEvent, ColumnApi } from "ag-grid-community";

import columnDefs from "./columnDefs";
import locationsColumns from "./locationsColumns";
import { useDispatchProgramFields } from "src/hooks";
import confirmationColumns from "./confirmationColumns";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import {
  isYard,
  stringSimilarity,
  FleetsLiveContext,
  generateExcelReport,
} from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { isLoggedIn } from "src/components/Header/components/GoogleEvents/googleClientAPI/authenticate";
import LiveAuditContext from "../../../../LiveAuditContext";
import { MondayButton, WarningModal } from "src/components/commonComponents";
import { ReportCheck } from "../../../../../LiveReportsView/components/ReportViewController/components";
import { GridTemplateCard } from "src/components/pages/Settings/settingsComponents/FleetLiveSheets/components";
import {
  RightArrow,
  LeftArrow,
} from "src/components/SidebarPages/BasePage/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { UploadIcon } from "src/components/SidebarPages/Documentation/View/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { InfoIconBlue } from "src/components/Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";

import "./AuditReportModal.scss";

/**
 * @typedef RowData
 * @property {string} fleetId
 * @property {string} fleetName
 * @property {string} auditStatus
 *
 * @typedef ConfirmationRow
 * @property {string} fleetId
 * @property {string} fleetName
 * @property {number} lastRow
 * @property {string} sheetId
 * @property {string} sheetName
 *
 * @typedef ResourceDataType
 * @property {string} range
 * @property {"ROWS"} majorDimension
 * @property {string[][]} values
 *
 * @typedef ResourceType
 * @property {"RAW"} valueInputOption
 * @property {ResourceDataType} data
 * @property {boolean} includeValuesInResponse
 *
 * @typedef AddressMatchRows
 * @property {string} location
 * @property {string} locationMatch
 * @property {boolean} isDefaultMatch
 */

function AuditReportModal({ open, onCancel }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { onVehicleSelect } = useContext(LiveAuditContext);
  const {
    lastRows,
    locations,
    isWritable,
    uploadFields,
    selectedDate,
    globalAudits,
    sheetLocations,
    showWritableInfo,
    broadcastUpdates,
    googleAccessToken,
    plannedTripsForDate,
    googleSignInHandler,
    externalBatchUpload,
    updateProgramFields,
    uploadToDriveHandler,
  } = useContext(FleetsLiveContext);

  const [gridApi, setGridApi] = useState(/** @type {GridApi} */ (undefined));
  const [rowData, setRowData] = useState(/** @type {RowData[]} */ ([]));
  const [columnApi, setColumnApi] = useState(
    /** @type {ColumnApi} */ (undefined)
  );
  const [saveLocations, setSaveLocations] = useState(true);
  const [confirmationToggle, setConfirmationToggle] = useState(
    /** @type {"SHEETS"|"LOCATIONS"} */ ("SHEETS")
  );
  const [addressMatches, setAddressMatches] = useState(
    /** @type {AddressMatchRows[]} */ ([])
  );
  const [confirmationRows, setConfirmationRows] = useState(
    /** @type {ConfirmationRow[]} */ ([])
  );
  const [confirmationStep, setConfirmationStep] = useState(
    /** @type {boolean} */ (false)
  );
  const [uploadWarning, setUploadWarning] = useState(
    /** @type {"ALL"|ConfirmationRow|undefined} */ (undefined)
  );

  const { dispatchFields } = useDispatchProgramFields(
    uploadFields?.fieldId,
    uploadFields?.fieldName
  );

  const onCellEditingStopped = useCallback(
    /** @param {CellEditingStoppedEvent} event */
    (event) => {
      const { data } = event;

      if (confirmationToggle === "LOCATIONS") {
        setAddressMatches((prev) => {
          const prevIndex = prev?.findIndex(
            ({ location }) => location === data?.location
          );

          if (prevIndex === -1) {
            return prev;
          }

          prev[prevIndex] = {
            ...data,
            isDefaultMatch:
              event.newValue === event.oldValue ? data?.isDefaultMatch : false,
          };
          return [...prev];
        });
      } else {
        setConfirmationRows((prev) => {
          const prevIndex = prev?.findIndex(
            ({ fleetId }) => fleetId === data?.fleetId
          );
          if (prevIndex === -1) {
            return prev;
          }

          prev[prevIndex] = { ...data };
          return [...prev];
        });
      }
    },
    [confirmationToggle]
  );

  useEffect(() => {
    const tmpRowData = [];

    for (const vehicle of locations) {
      const { fleetId, fleetName } = vehicle;
      const auditForVehicle = globalAudits?.["alertChanges"]?.[fleetId];
      if (!auditForVehicle) {
        tmpRowData.push({
          fleetName,
          fleetId,
          auditStatus: "PENDING",
        });
      } else {
        if (
          auditForVehicle?.auditStatus === "UPLOADED" ||
          auditForVehicle?.auditStatus === "COMPLETED"
        ) {
          tmpRowData.push({
            fleetName,
            fleetId,
            auditStatus: auditForVehicle?.auditStatus,
          });
        } else {
          const ids = [];
          for (const alertId in auditForVehicle?.["changed"]) {
            let id = auditForVehicle?.["changed"]?.[alertId]?.["activityId"];
            if (id) {
              if (!ids.includes(id)) {
                ids.push(id);
              }
            }
          }

          if (ids?.length && plannedTripsForDate?.[fleetId]?.length) {
            if (
              plannedTripsForDate[fleetId]?.every(({ activityId }) =>
                ids?.includes(activityId)
              )
            ) {
              tmpRowData.push({
                fleetName,
                fleetId,
                auditStatus: "COMPLETED",
              });
            } else {
              tmpRowData.push({
                fleetName,
                fleetId,
                auditStatus: "PENDING",
              });
            }
          }
        }
      }
    }

    setRowData(tmpRowData);
    if (gridApi) {
      gridApi.redrawRows();
    }
  }, [locations, globalAudits, plannedTripsForDate, gridApi, columnApi]);

  useEffect(() => {
    /** @type {AddressMatchRows[]} */
    const matchingData = [];
    const locationsSet = new Set();

    for (const vehicle of confirmationRows) {
      const { fleetId: selectedVehicle } = vehicle;

      const audits = globalAudits?.["alertChanges"]?.[selectedVehicle];

      const rowDataAudit = rowData.find(
        ({ fleetId }) => fleetId === selectedVehicle
      )?.auditStatus;
      if (rowDataAudit !== "COMPLETED") {
        continue;
      }

      for (const alertId in audits?.["changed"] || {}) {
        if (!audits?.["changed"]?.[alertId]?.["activityId"]) {
          continue;
        }

        let auditedAlert = audits?.["changed"]?.[alertId];
        let activity = plannedTripsForDate?.[selectedVehicle]?.find(
          ({ activityId }) => activityId === auditedAlert?.activityId
        );

        if (!activity) {
          continue;
        }

        if (!isYard(activity?.pickUpLocation)) {
          locationsSet.add(activity?.pickUpLocation);
        }

        if (!isYard(activity?.dropOffLocation)) {
          locationsSet.add(activity?.dropOffLocation);
        }
      }
    }

    const key = process.env.NODE_ENV === "production" ? "prod" : "dev";

    locationsSet.forEach((location) => {
      if (uploadFields?.fieldOptions?.[key]?.locationMatches?.[location]) {
        matchingData.push({
          location,
          locationMatch:
            uploadFields?.fieldOptions?.[key]?.locationMatches?.[location],
          isDefaultMatch: true,
        });
      } else {
        //matching function
        const [mostLikely] = [...sheetLocations].sort(
          (a, b) =>
            stringSimilarity(b, location) - stringSimilarity(a, location)
        );

        matchingData.push({
          location,
          locationMatch: mostLikely,
          isDefaultMatch: false,
        });
      }
    });

    setAddressMatches(matchingData);
  }, [
    confirmationRows,
    globalAudits,
    plannedTripsForDate,
    rowData,
    uploadFields,
    sheetLocations,
  ]);

  useEffect(() => {
    const tmpData = [];
    for (const data of rowData) {
      if (data?.auditStatus !== "COMPLETED") {
        continue;
      }

      const { fleetId, fleetName } = data;

      const key = process.env.NODE_ENV === "production" ? "prod" : "dev";
      const vehicleUploadConfig =
        uploadFields?.fieldOptions?.[key]?.vehicleConfigs?.[fleetId];
      const lastVehicleRow = lastRows?.[vehicleUploadConfig?.sheetName];

      tmpData.push({
        fleetName,
        fleetId,
        sheetName: vehicleUploadConfig?.sheetName,
        sheetId: vehicleUploadConfig?.sheetId,
        lastRow: lastVehicleRow,
      });
    }

    setConfirmationRows(tmpData);
    if (gridApi) {
      gridApi?.redrawRows();
    }
  }, [rowData, lastRows, uploadFields, confirmationStep, gridApi, columnApi]);

  const goToAudit = useCallback((data) => {
    onVehicleSelect(data?.fleetId);
    onCancel();
  }, []);

  async function onConfirm() {
    if (!isWritable) {
      showWritableInfo();
      return;
    } else if (!confirmationRows?.length) {
      void message.warning({
        content:
          'There needs to be at least one "Completed" audit in order to proceed',
        key: "noCompleted",
      });
      return;
    } else if (!isLoggedIn()) {
      googleSignInHandler(() => {
        if (!confirmationStep) {
          gridApi.setRowData(confirmationRows);
          gridApi.setColumnDefs(confirmationColumns);
          gridApi.redrawRows();

          setTimeout(() => {
            columnApi.autoSizeAllColumns(false);
          }, 100);

          setConfirmationStep(true);
          return;
        } else {
          const v = validateConfirmation();
          if (v) {
            setUploadWarning("ALL");
          }
        }
      });
      return;
    } else {
      if (confirmationStep) {
        const v = validateConfirmation();
        if (v) {
          setUploadWarning("ALL");
        }
      }
    }

    if (!confirmationStep) {
      gridApi.setRowData(confirmationRows);
      gridApi.setColumnDefs(confirmationColumns);
      gridApi.redrawRows();

      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);

      setConfirmationStep(true);
      return;
    }
  }

  function validateConfirmation() {
    for (const row of confirmationRows) {
      if (!row.sheetId || !row.sheetName) {
        message.warning({
          content: `Configuration for ${row.fleetName} is incomplete. Pleas fill all of the fields`,
          key: "validation",
        });
        return false;
      } else if (+row.lastRow < 0) {
        message.warning({
          content: `Configuration for ${row.fleetName} has start row less than 0!`,
          key: "validation",
        });
        return false;
      }
    }

    for (const row of addressMatches) {
      if (!row.locationMatch) {
        message.warning({
          content: `"${row.location}" has been left unmatched, please specify a location match`,
          key: "validation",
        });
        return false;
      }
    }

    return true;
  }

  function back() {
    if (!confirmationStep) {
      onCancel();
      return;
    }

    gridApi.setRowData(rowData);
    gridApi.setColumnDefs(columnDefs);
    gridApi.redrawRows();

    setConfirmationStep(false);
  }

  function onDeleteRow(data) {
    if (!isWritable) {
      showWritableInfo();
      return;
    }

    setConfirmationRows((prev) =>
      prev.filter(({ fleetId }) => fleetId !== data?.fleetId)
    );

    if (gridApi) {
      gridApi.redrawRows();
    }
  }

  async function uploadAllHandler() {
    /** @type {Record<string, ResourceDataType[]>} */
    const dataForSheet = {};
    const vehicles = [];
    const { auditId, alertChanges } = globalAudits;
    const updatedAudit = {
      alertChanges: {
        ...alertChanges,
      },
    };

    const addressesMap = addressMatches.reduce(
      (acc, val) => ({
        ...acc,
        [val.location]: val.locationMatch,
      }),
      {}
    );

    for (const row of confirmationRows) {
      const { fleetId, lastRow, sheetId, sheetName } = row;

      /** @type {string[][]} */
      const values = generateExcelReport({
        audits: globalAudits?.["alertChanges"]?.[fleetId],
        plannedTripsForDate,
        selectedVehicle: fleetId,
        forDriveUpload: true,
        addressesMap,
      });

      dataForSheet[sheetId] = [
        ...(dataForSheet[sheetId] || []),
        {
          majorDimension: "ROWS",
          range: `${sheetName}!${lastRow + 1}:${lastRow + values?.length}`,
          values,
        },
      ];

      updatedAudit["alertChanges"][fleetId]["auditStatus"] = "UPLOADED";

      vehicles.push(fleetId);
    }

    void message.loading({
      content: "Please wait for the data to be uploaded...",
      key: "batchUpload",
    });

    await Promise.all(
      Object.keys(dataForSheet).map((spreadsheetId) => {
        return gapi.client.sheets.spreadsheets.values.batchUpdate({
          spreadsheetId,
          access_token: googleAccessToken,
          resource: {
            includeValuesInResponse: false,
            valueInputOption: "RAW",
            data: dataForSheet[spreadsheetId],
          },
        });
      })
    )
      .then(async () => {
        return API.put("fleetAudits", `/fleetAudits/${auditId}`, {
          body: {
            ...updatedAudit,
          },
        });
      })
      .then(async () => {
        if (!saveLocations) {
          return Promise.resolve();
        }

        const key = process.env.NODE_ENV === "production" ? "prod" : "dev";

        const newFields = {
          ...uploadFields,
        };

        const newFieldOptions = {
          ...(uploadFields?.fieldOptions || {}),
          [key]: {
            ...(uploadFields?.fieldOptions?.[key] || {}),
            locationMatches: {
              ...(uploadFields?.fieldOptions?.[key]?.locationMatches || {}),
              ...addressesMap,
            },
          },
        };

        newFields["fieldOptions"] = newFieldOptions;

        return await dispatchFields(newFieldOptions).then(() => {
          broadcastUpdates({
            request: "field-config",
            field: newFields,
          });

          updateProgramFields(newFields, true);
        });
      })
      .then(() => {
        void message.success({
          content: "Audits uploaded successfully",
          key: "batchUpload",
        });

        back();
        onCancel();

        broadcastUpdates({
          request: "batch-upload",
          vehicles,
        });

        externalBatchUpload({ vehicles });
      })
      .catch((err) => {
        console.log("ERROR UPLOADING DATA: ", { err });
        void message.error({
          content:
            "Something went wrong while trying to upload data, please check the fields and try again",
          key: "batchUpload",
        });
      });
  }

  function toggleHandler(checked) {
    if (checked) {
      gridApi.setRowData(addressMatches);
      gridApi.setColumnDefs(locationsColumns);
      gridApi.redrawRows();

      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);

      setConfirmationToggle("LOCATIONS");
    } else {
      gridApi.setRowData(confirmationRows);
      gridApi.setColumnDefs(confirmationColumns);
      gridApi.redrawRows();

      setTimeout(() => {
        columnApi.autoSizeAllColumns(false);
      }, 100);

      setConfirmationToggle("SHEETS");
    }
  }

  function onUpload(data) {
    setUploadWarning(data);
  }

  return (
    <Fragment>
      <Modal
        {...{
          open,
          onCancel,
          title: `Audit Report - ${dayjsNY(selectedDate).format(
            "MMM DD, YYYY"
          )}`,
          centered: true,
          className: `audit-report-modal ${
            isDarkMode ? "audit-report-modal-dark" : ""
          }`,
          closeIcon: <XIcon />,
          footer: [
            <MondayButton
              {...{
                Icon: confirmationStep ? <LeftArrow /> : <XIcon />,
                className: confirmationStep
                  ? "mondayButtonRed"
                  : "mondayButtonRed",
                onClick: back,
              }}
              key="cancel"
            >
              {confirmationStep ? "Go Back" : "Cancel"}
            </MondayButton>,
            <MondayButton
              {...{
                Icon: !confirmationStep ? <RightArrow /> : <UploadIcon />,
                onClick: onConfirm,
                className: !confirmationStep ? "mondayButtonBlue" : "",
                disabled: !confirmationRows?.length,
              }}
              key="confirm"
            >
              {!confirmationStep ? "Upload Completed" : "Confirm and Upload"}
            </MondayButton>,
          ],
        }}
      >
        {confirmationStep ? (
          <div className="confirmation-info">
            <InfoIconBlue />
            <span>
              This is an overview of the upload data, feel free to make any
              changes if necessary
            </span>
          </div>
        ) : null}
        <GridTemplateCard
          {...{
            columnDefs: !confirmationStep
              ? columnDefs
              : confirmationToggle === "LOCATIONS"
              ? locationsColumns
              : confirmationColumns,
            rowData: !confirmationStep
              ? rowData
              : confirmationToggle === "LOCATIONS"
              ? addressMatches
              : confirmationRows,
            className: `report-modal-grid ${
              confirmationStep ? "confirmation-grid" : ""
            }`,
            additionalActions: confirmationStep
              ? [
                  confirmationToggle === "LOCATIONS" ? (
                    <ReportCheck
                      label="Save Locations"
                      checked={saveLocations}
                      onChange={setSaveLocations}
                      key="saveLocations"
                    />
                  ) : null,
                  <Switch
                    className="writeReadSwitchContainer"
                    key="switch"
                    onChange={toggleHandler}
                    checked={confirmationToggle === "LOCATIONS"}
                    unCheckedChildren="Sheets Overview"
                    checkedChildren="Locations Overview"
                  />,
                ].filter(Boolean)
              : null,
            title: null,
            gridProps: {
              onGridReady(api) {
                setGridApi(api.api);
                setColumnApi(api.columnApi);
              },
              onCellEditingStopped,
              pagination: false,
              rowGroupPanelShow: "onlyWhenGrouping",
            },
            context: {
              onUpload,
              goToAudit,
              isWritable,
              onDeleteRow,
              showWritableInfo,
            },
            hideHeader: true,
          }}
        />
      </Modal>
      {uploadWarning ? (
        <WarningModal
          visible={uploadWarning}
          setVisible={setUploadWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
          key="cancelModal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              {uploadWarning === "ALL"
                ? "Are you sure you want to upload the audit? Please make sure all the fields are filled correctly before proceeding. You will not be able to make any further changes to the selected audits after the upload"
                : `Are you sure you want to upload the audit? Once you upload you will not be able to make any more changes to the audit of ${dayjsNY(
                    selectedDate
                  ).format("MM DD, YYYY")} for ${uploadWarning?.fleetName}`}
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  setUploadWarning(undefined);
                }}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (uploadWarning === "ALL") {
                    void uploadAllHandler();
                  } else {
                    void uploadToDriveHandler({
                      fleetId: uploadWarning?.fleetId,
                    });
                  }

                  setUploadWarning(undefined);
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      ) : null}
    </Fragment>
  );
}

export default AuditReportModal;
