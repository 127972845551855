import dayjs from "dayjs";
function filterByKeys(arr, keys) {
  return arr.map((obj) => {
    const newObj = {};
    keys.forEach((key) => {
      try {
        const value = eval(`obj.${key}`);
        if (!!value) {
          newObj[key] = value;
        } else {
          newObj[key] = `${obj?.safetyApplicationCategory} [DRAFT] - ${dayjs(
            obj?.createdAt
          ).format("MM/DD/YYYY hh:mm A")}`;
        }
      } catch (e) {
        console.log("error", e);
      }
    });
    const oldKeys = Object.keys(newObj);
    const renamedObj = newObj;
    renamedObj["id"] = renamedObj[oldKeys[0]];
    delete renamedObj[oldKeys[0]];
    renamedObj["name"] = renamedObj[oldKeys[1]];
    delete renamedObj[oldKeys[1]];
    return renamedObj;
  });
}

function safetyFiltering(res, selectedCategory, projectId) {
  let result = res;
  // ?.filter(
  //   (item) =>
  //     !item?.safetyApplicationObject?.hasOwnProperty("isDraft") ||
  //     !item?.safetyApplicationObject?.isDraft
  // );

  if (projectId) {
    result = res?.filter((item) => item?.projectId === projectId);
  }
  const safety =
    selectedCategory === "Property Damage"
      ? filterByKeys(result, [
          "safetyApplicationId",
          "safetyApplicationObject?.addressOfDamagedProperty",
        ])
      : selectedCategory === "Personal Injury"
      ? filterByKeys(result, [
          "safetyApplicationId",
          "safetyApplicationObject?.fullNameOfInjuredIndividual",
        ])
      : selectedCategory === "Vehicle Damage"
      ? filterByKeys(result, [
          "safetyApplicationId",
          "safetyApplicationObject?.addressOfIncident",
        ])
      : selectedCategory === "Other Trade Incident"
      ? filterByKeys(result, [
          "safetyApplicationId",
          "safetyApplicationObject?.fullNameOfInjuredIndividual",
        ])
      : [];
  return safety;
}

export default safetyFiltering;
