import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import {
  degFilterHandler,
  groupEntriesInShifts,
  getNotFoundEmployees,
  gridCustomOverlayLoading,
} from "../utils";
import {
  columnDefs,
  gridFilterData,
  excelColumnsConfig,
} from "./degStepColumns";
import DegHeader from "../DegHeader/DegHeader";
import { EMPTY_ROW } from "../utils/degParser";
import DegModalContext from "../DegModalContext";
import FooterTotals from "../FooterTotals/FooterTotals";
import { DEG_DATE_FORMAT } from "../utils/cellFunctions";
import { OVERHEAD_ENTRY_TYPES } from "../../degModalData";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import Filter from "../../../../../../../SidebarPages/BasePage/components/Filter/Filter";

let degExternalFilter = {
  showHr: true,
  showOnlyHr: false,
  showIncorrect: true,
  showIncomplete: true,
  showOnlyIncorrect: false,
  showOnlyIncomplete: false,
};

function DegStep() {
  const {
    crews,
    rowData,
    degName,
    jobsites,
    notFound,
    emptyData,
    analytics,
    rowToEdit,
    isLastStep,
    isDarkMode,
    setNotFound,
    setEmptyData,
    getEntryLogs,
    incorrectData,
    setDegGridApi,
    degStepFilters,
    entriesActions,
    warningFilters,
    addEntryAction,
    setDegColumnApi,
    jobsiteServices,
    scheduleMatching,
    addActionToRegister,
    degGridApi: gridApi,
    filtersFromSelection,
    updateStatisticsHandler,
    setFiltersFromSelection,
  } = useContext(DegModalContext);

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [fieldSelected, setFieldSelected] = useState(false);
  const [actionsDisabled, setActionsDisabled] = useState(true);
  const [filtersData, setFiltersData] = useState(
    JSON.parse(sessionStorage.getItem("degStepFilters")) || degStepFilters || {}
  );

  const headerRef = useRef();

  const { employeeWeekTotals, employeesHoursPerDay, generalOverheadTotal } =
    analytics;

  const {
    hrHighlighted,
    incorrectHighlighted,
    incompleteHighlighted,
    missingLunchHighlighted,
    overheadEntriesHighlighted,
  } = warningFilters;

  const paginationPageSize = Math.floor((window.innerHeight - 340) / 42);

  const onGridReady = useCallback((param) => {
    setDegGridApi(param.api);
    setDegColumnApi(param.columnApi);
    if (!rowData?.length && rowToEdit?.degId) {
      param.api.showLoadingOverlay();
    }
    param.columnApi.autoSizeAllColumns(false);
  }, []);

  const onFirstDataRendered = useCallback(function ({ api, columnApi }) {
    const degColumnsState = JSON.parse(
      sessionStorage.getItem("degStepColumns")
    );

    if (!degColumnsState?.length) {
      columnApi.autoSizeAllColumns(false);
      sessionStorage.setItem(
        "degStepColumns",
        JSON.stringify(columnApi.getColumnState())
      );
    } else {
      columnApi.applyColumnState({
        state: degColumnsState,
        applyOrder: true,
      });
    }
    api.hideOverlay();
  }, []);

  const onComponentStateChanged = useCallback(({ api }) => {
    if (!!rowData?.length) {
    }
  }, []);

  const getRowId = useCallback((param) => {
    return param?.data?.entryId;
  }, []);

  const getRowStyle = useCallback(
    (param) => {
      if (incorrectHighlighted) {
        if (incorrectData.includes(param?.node?.data?.entryId)) {
          return { background: "rgba(245, 76, 74, 0.6)" };
        }
      }
      if (incompleteHighlighted) {
        if (emptyData?.includes(param?.node?.data?.entryId)) {
          return { background: "#dfab0199" };
        }
      }
      if (hrHighlighted) {
        if (param?.node?.data?.punchType === "HR") {
          return { background: "#1bd60a50" };
        }
      }
      if (missingLunchHighlighted) {
        if (param?.node?.data?.missingLunch) {
          return { background: "rgba(223, 171, 1, 0.6)" };
        }
      }
      if (overheadEntriesHighlighted) {
        if (OVERHEAD_ENTRY_TYPES.includes(param?.node?.data?.punchType)) {
          return { background: "rgba(143, 101, 177, 0.4)" };
        }
      }
    },
    [
      rowData,
      emptyData,
      isLastStep,
      hrHighlighted,
      incorrectData,
      incorrectHighlighted,
      incompleteHighlighted,
      overheadEntriesHighlighted,
    ]
  );

  const isExternalFilterPresent = useCallback(() => {
    return (
      !degExternalFilter.showIncomplete ||
      !degExternalFilter.showIncorrect ||
      !degExternalFilter.showHr ||
      degExternalFilter.showOnlyIncomplete ||
      degExternalFilter.showOnlyIncorrect ||
      degExternalFilter.showOnlyHr
    );
  }, [degExternalFilter]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      if (degExternalFilter.showOnlyIncorrect) {
        return incorrectData?.includes(data?.entryId);
      } else if (degExternalFilter.showOnlyIncomplete) {
        return emptyData?.includes(data?.entryId);
      } else if (degExternalFilter.showOnlyHr) {
        return data?.punchType === "HR";
      } else {
        if (data?.punchType === "HR") {
          return degExternalFilter?.showHr;
        }
        if (incorrectData?.includes(data?.entryId)) {
          return degExternalFilter.showIncorrect;
        }
        if (emptyData?.includes(data?.entryId)) {
          return degExternalFilter.showIncomplete;
        }
        return true;
      }
    },
    [degExternalFilter, incorrectData, emptyData]
  );

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: FooterTotals,
          key: "footerTotals",
          align: "left",
        },
      ],
    };
  }, []);

  const groupByShift = useMemo(() => {
    if (analytics && !!crews?.length && !!jobsites?.length) {
      return groupEntriesInShifts({
        analytics: analytics,
        crews,
        jobsites,
        rowData,
      });
    }
    return [];
  }, [analytics, crews, jobsites, rowData]);

  const memoColumnDefs = useMemo(() => {
    const degColumnsState = JSON.parse(
      sessionStorage.getItem("degStepColumns")
    );

    if (fieldSelected) {
      const columns = columnDefs({
        notFound,
        isLastStep,
        openEntryLogs,
        jobsiteServices,
        setEditModalData,
        setAddEmployeeModal,
        employeesHoursPerDay,
        editModalData: getEditEntry,
      }).map((column) =>
        column.field === fieldSelected
          ? column
          : { ...column, getQuickFilterText: () => "" }
      );

      let modifiedColumns = [];
      if (degColumnsState?.length) {
        for (let i = 0; i < degColumnsState?.length; i++) {
          const el = degColumnsState[i];
          const elIndex = columns.findIndex(
            ({ field, colId }) => colId === el?.colId || field === el?.colId
          );
          if (columns?.[elIndex]) {
            modifiedColumns.push(columns[elIndex]);
          }
        }
      }

      return !!degColumnsState?.length ? modifiedColumns : columns;
    } else {
      const columns = columnDefs({
        notFound,
        isLastStep,
        openEntryLogs,
        jobsiteServices,
        setEditModalData,
        setAddEmployeeModal,
        employeesHoursPerDay,
        editModalData: getEditEntry,
      });

      let modifiedColumns = [];
      if (degColumnsState?.length) {
        for (let i = 0; i < degColumnsState?.length; i++) {
          const el = degColumnsState[i];
          const elIndex = columns.findIndex(
            ({ field, colId }) => colId === el?.colId || field === el?.colId
          );
          if (columns?.[elIndex]) {
            modifiedColumns.push(columns[elIndex]);
          }
        }
      }

      return !!degColumnsState?.length ? modifiedColumns : columns;
    }
  }, [
    fieldSelected,
    isLastStep,
    notFound,
    employeesHoursPerDay,
    jobsiteServices,
  ]);

  function getColumnState(event) {
    if (
      event?.finished &&
      (event.source === "uiColumnMoved" || event.source === "uiColumnResized")
    ) {
      const columnOrder = event.columnApi.getColumnState();
      sessionStorage.setItem("degStepColumns", JSON.stringify(columnOrder));
    }
  }

  function getEditEntry() {
    if (headerRef?.current) {
      return headerRef.current.getEditEntry();
    }
  }

  function setEditModalData(data) {
    if (headerRef?.current) {
      headerRef.current.setEditEntry(data);
    }
  }

  function setAddEmployeeModal(employee) {
    if (headerRef?.current) {
      headerRef.current.setAddEmployee(employee);
    }
  }

  function setShiftBreakWarning(visible) {
    if (headerRef?.current) {
      headerRef.current.setShiftBreakWarning(visible);
    }
  }

  async function openEntryLogs(entryId) {
    await getEntryLogs(entryId);
    if (headerRef?.current) {
      headerRef.current.setEntryLogVisible(entryId);
    }
  }

  function onSearch(e) {
    if (gridApi) {
      gridApi.setFilterModel({
        uploadName: {
          filterType: "text",
          type: "contains",
          filter: e,
        },
      });
    }
  }

  function addData() {
    if (gridApi) {
      let entryId = uuidv4();
      gridApi.applyTransaction({
        addIndex: 0,
        add: [{ ...EMPTY_ROW, entryId }],
      });

      setEmptyData([...emptyData, entryId]);
      addEntryAction({ type: "new", entry: [{ ...EMPTY_ROW, entryId }] });
      addActionToRegister({
        type: "new",
        newActions: [{ ...EMPTY_ROW, entryId }],
      });
    }
  }

  function removeData(checkForShiftBreak = true) {
    if (gridApi) {
      let employeesToUpdate = [];
      let brokenShift = [];
      let rowsToRemove = gridApi.getSelectedNodes()?.map(({ data }) => {
        employeesToUpdate.push(data?.employeeId);
        brokenShift = checkForShiftBreak
          ? brokenShift.concat(
              groupByShift.find(
                (shift) =>
                  shift?.employeeId === data?.employeeId &&
                  shift.firstClockIn <= data.punchTimeStamp &&
                  data.punchTimeStamp <= shift.clockOut
              )
            )
          : [];
        return data;
      });
      brokenShift = _.uniqBy(
        brokenShift?.filter(Boolean),
        ({ shiftId }) => shiftId
      );
      if (!!brokenShift.length) {
        setShiftBreakWarning(brokenShift);
        return;
      } else {
        gridApi.applyTransaction({
          remove: rowsToRemove,
        });
        updateStatisticsHandler(employeesToUpdate.filter(Boolean));

        setNotFound(
          getNotFoundEmployees({
            gridApi,
            crews,
          })
        );
      }

      if (rowsToRemove?.some(({ entryId }) => emptyData?.includes(entryId))) {
        setEmptyData(
          emptyData?.filter((entryId) => {
            return !rowsToRemove?.find(({ entryId: e }) => e === entryId);
          })
        );
      }

      const removeActions = {
        editedEntries: [],
        newEntries: [],
        removedEntries: [],
      };

      rowsToRemove.forEach((row) => {
        let editIncluded = entriesActions?.editedEntries.findIndex(
          (el) => el?.entryId === row?.entryId
        );
        let newIncluded = entriesActions?.newEntries.findIndex(
          (el) => el?.entryId === row?.entryId
        );

        if (editIncluded > -1) {
          Object.assign(removeActions, {
            ["editedEntries"]: removeActions.editedEntries.concat(row),
          });
        } else if (newIncluded > -1) {
          Object.assign(removeActions, {
            ["newEntries"]: removeActions.newEntries.concat(row),
          });
        } else {
          Object.assign(removeActions, {
            ["removedEntries"]: removeActions.removedEntries.concat(row),
          });
        }
      });

      addActionToRegister({ type: "remove", removeActions });
      addEntryAction({
        type: "remove",
        entry: rowsToRemove,
      });
    }
  }

  function duplicateRows() {
    if (gridApi) {
      let employeesToUpdate = [];
      let existingEmptyEntries = [];
      let add = gridApi.getSelectedNodes()?.map(({ data }) => {
        let entryId = uuidv4();
        if (emptyData?.includes(data?.entryId)) {
          existingEmptyEntries.push(entryId);
        }
        employeesToUpdate.push(data?.employeeId);
        return { ...data, entryId };
      });
      gridApi.applyTransaction({
        addIndex: 0,
        add,
      });
      updateStatisticsHandler(employeesToUpdate.filter(Boolean));
      setEmptyData([...emptyData, ...existingEmptyEntries]);
      addActionToRegister({
        type: "new",
        newActions: add,
      });
      addEntryAction({ type: "new", entry: add });
    }
  }

  function onSelectionChanged() {
    if (!gridApi.getSelectedNodes()?.length) {
      setActionsDisabled(true);
    } else {
      setActionsDisabled(false);
    }
  }

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setSearchInput(e);
  }

  function clearFilters() {
    setFiltersData({distanceFromJob: []});
    setFiltersFromSelection({});
    sessionStorage.setItem("degStepFilters", JSON.stringify({}));
  }

  function showHasFilters() {
    return !!Object.keys(filtersData)?.filter((key) => filtersData[key])
      ?.length;
  }

  function updateExternalFilter(filter) {
    if (gridApi) {
      degExternalFilter = { ...degExternalFilter, ...filter };
      gridApi.onFilterChanged();
    }
  }

  useEffect(() => {
    return () => {
      degExternalFilter = {
        showIncomplete: true,
        showOnlyIncomplete: false,
        showIncorrect: true,
        showOnlyIncorrect: false,
        showHr: true,
        showOnlyHr: false,
      };
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      let rowsToRefresh = [];
      gridApi.forEachNode(({ data }) => {
        if (
          !!emptyData?.includes(data?.entryId) ||
          incorrectData.includes(data?.entryId) ||
          OVERHEAD_ENTRY_TYPES.includes(data?.punchType) ||
          data?.punchType === "HR"
        ) {
          rowsToRefresh.push(data?.entryId);
        }
      });
      gridApi.redrawRows({
        rowNodes: rowsToRefresh.map((entryId) => {
          return entryId && gridApi.getRowNode(entryId);
        }),
      });
    }
  }, [
    emptyData,
    hrHighlighted,
    incorrectData,
    incorrectHighlighted,
    incompleteHighlighted,
    overheadEntriesHighlighted,
  ]);

  useEffect(() => {
    degFilterHandler({ gridApi, filtersData });
  }, [JSON.stringify(filtersData), gridApi]);

  useEffect(() => {
    if (!!Object.keys(filtersFromSelection || {})?.length) {
      setFiltersData(filtersFromSelection);
    }
  }, [filtersFromSelection]);

  return (
    <div className="deg-step" data-testid="deg-step">
      <DegHeader
        ref={headerRef}
        {...{
          setFilterOpen,
          filterOpen,
          searchInput,
          onFilterTextChange,
          onSearch,
          setFieldSelected,
          notFound,
          actionsDisabled,
          duplicateRows,
          addData,
          showHasFilters,
          updateExternalFilter,
          degExternalFilter,
          removeData,
        }}
      />
      <div
        className={`deg-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            columnDefs: memoColumnDefs,
            onGridReady,
            onColumnMoved: getColumnState,
            onColumnResized: _.debounce(getColumnState, 300),
            rowGroupPanelShow: "always",
            getRowId,
            statusBar,
            getRowStyle,
            pagination: true,
            paginationPageSize,
            animateRows: true,
            onSelectionChanged,
            isExternalFilterPresent,
            doesExternalFilterPass,
            onFirstDataRendered,
            onComponentStateChanged,
            defaultExcelExportParams: {
              fileName: !!analytics?.dateRange?.length
                ? `Week(${dayjsNY(
                    analytics?.dateRange?.[0]
                  ).week()}) (${dayjsNY(analytics?.dateRange?.[0]).format(
                    DEG_DATE_FORMAT
                  )} - ${dayjsNY(analytics?.dateRange?.[1]).format(
                    DEG_DATE_FORMAT
                  )}) Entries`
                : degName,
              columnKeys: excelColumnsConfig,
            },
            context: {
              rowToEdit,
              scheduleMatching,
              employeeWeekTotals,
              generalOverheadTotal,
            },
            suppressRowClickSelection: true,
            enableCellChangeFlash: true,
            overlayLoadingTemplate: gridCustomOverlayLoading(),
            rowSelection: "multiple",
            sideBar: {
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
            },
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
              useValueFormatterForExport: true,
              useValueParserForImport: true,
            },
          }}
        />
      </div>
      {filterOpen && (
        <Filter
          getFilters={(val) => {
            setFiltersData(structuredClone(val));
            sessionStorage.setItem("degStepFilters", JSON.stringify(val));
          }}
          setOpen={setFilterOpen}
          filters={gridFilterData}
          filtersData={filtersData}
          rowData={!!rowData?.length ? rowData : []}
          title={degName}
          clearFilters={clearFilters}
        />
      )}
    </div>
  );
}

export default DegStep;
