import React, { useState } from "react";
import "./HeaderInternalChat.scss";
import { Switch } from "antd";
import {
  ChatBotIcon,
  DashboardIconButton,
  SwitchThemeIcon,
  TickIcon,
} from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import CustomMondaySwitch from "../CustomMondaySwitch/CustomMondaySwitch";
import { VirtualAssistant } from "../../../commonComponents";
const HeaderInternalChat = ({ checked, setChecked }) => {
  const navigate = useNavigate();
  return (
    <div className="internalHeaderInfo">
      <div className="dateInfo">
        <span>Date today:</span>
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date())}
      </div>
      <div className="internalHeaderActions">
        <CustomMondaySwitch
          {...{
            checked,
            onToggleSwitch: () => setChecked(!checked),
            onText: "Virtual Assistant",
            offText: "Virtual Assistant",
            width: 180,
            removeIcon: false,
            onIcon: <TickIcon style={{ fill: "#00A464" }} />,
            colors: { on: "#00A464", off: "lightGrey" },
          }}
        />
        <SwitchThemeIcon cursor="pointer" />
        <DashboardIconButton
          cursor="pointer"
          onClick={() => navigate("/dashboard")}
        />
      </div>
    </div>
  );
};

export default HeaderInternalChat;
