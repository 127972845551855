import { useContext, Fragment } from "react";
import { useSelector } from "react-redux";

import { StoreType } from "../../../../SidebarPages/FleetMaintenanceView/types";
import VirtualAssistantContext from "../../data/VirtualAssistantContext";
import AssistantChatHeader from "../AssistantChatHeader/AssistantChatHeader";
import AssistantChatFooter from "../AssistantChatFooter/AssistantChatFooter";
import AssistantInput from "../AssistantInput/AssistantInput";
import AssistantChatBody from "../AssistantChatBody/AssistantChatBody";
import AssistantConnectingView from "../AssistantConnectingView/AssistantConnectingView";

import "./AssistantChatContainer.scss";
import AssistantErrorView from "../AssistantErrorView/AssistantErrorView";

function AssistantChatContainer() {
  const { isDarkMode } = useSelector(
    /** @param {StoreType} state */
    (state) => state.darkMode
  );

  const { status, chatResized } = useContext(VirtualAssistantContext);

  const isConnecting = ["Connecting", "BotConnecting"].includes(status);
  const isError = status === "Error";

  return (
    <div
      className={`assistant-chat-container ${
        isDarkMode ? "assistant-chat-container-dark" : ""
      } ${isConnecting || isError ? "chat-connecting" : "chat-connected"} ${
        chatResized ? "chat-resized" : ""
      }`}
      data-testid="assistant-container"
    >
      <AssistantChatHeader />
      {isConnecting ? (
        <AssistantConnectingView status={status} />
      ) : isError ? (
        <AssistantErrorView />
      ) : (
        <Fragment>
          <AssistantChatBody />
          <AssistantInput />
        </Fragment>
      )}
      <AssistantChatFooter />
    </div>
  );
}

export default AssistantChatContainer;
