import {
  useState,
  Fragment,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";

import { FleetsLiveContext } from "../../../../utils";
import LiveAuditContext from "../../LiveAuditContext";
import { WarningModal, MondayButton } from "src/components/commonComponents";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { WarningIcon } from "src/icons";

import "./AuditFooter.scss";

const AuditFooter = forwardRef((_, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { isWritable } = useContext(FleetsLiveContext);
  const { onCancelChanges, onChangesSaved, selectedVehicle } =
    useContext(LiveAuditContext);

  const [changesMade, setChangesMade] = useState(false);
  const [warningVisible, setWarningVisible] = useState("");

  useEffect(() => {
    if (!isWritable) {
      setChangesMade(false);
    }
  }, [isWritable]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onChangesMade() {
          if (!changesMade) {
            setChangesMade(true);
          }
        },
        resetChangesStatus() {
          setChangesMade(false);
        },
      };
    },
    [changesMade]
  );

  return (
    <Fragment>
      <div
        className={`audit-controller ${
          isDarkMode ? "audit-controller-dark" : ""
        }`}
        style={{
          display: selectedVehicle ? "flex" : "none",
        }}
      >
        <MondayButton
          Icon={<XIcon />}
          className="mondayButtonRed"
          disabled={!changesMade}
          onClick={() => {
            setWarningVisible("CANCEL");
          }}
        >
          Cancel Changes
        </MondayButton>
        <MondayButton
          Icon={<TickIcon />}
          onClick={() => {
            setWarningVisible("SAVE");
          }}
          disabled={!changesMade}
        >
          Save Changes
        </MondayButton>
      </div>
      {warningVisible && (
        <WarningModal
          visible={!!warningVisible}
          setVisible={setWarningVisible}
          darkMode={isDarkMode}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p style={{ textAlign: "center" }}>
              {warningVisible === "CANCEL"
                ? "All the changes you made will be cancelled. Are you sure you want to proceed?"
                : "All the changes you made will be permanent. Are you sure you want to save?"}
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setWarningVisible(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (warningVisible === "CANCEL") {
                    onCancelChanges();
                  } else {
                    onChangesSaved();
                  }

                  setChangesMade(false);
                  setWarningVisible(false);
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
});

export default AuditFooter;
