import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import "./LongText.scss";
import { InfoIcon } from "../../../Inspections/assets";
import { useSelector } from "react-redux";

const Description = ({ params, edited, setNewValues }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [values, setValues] = useState(params);
  const [rowData, setRowData] = useState(null);

  const currentURL = window?.location?.pathname;

  const getRowData = async () => {
    await API.get(
      `${
        currentURL.split("/")[1].includes("permit")
          ? "permitDrawings"
          : currentURL.split("/")[1] === "fleets"
          ? "fleet"
          : currentURL.split("/")[1]
      }`,
      `/${
        currentURL.split("/")[1].includes("permit")
          ? "permitDrawings"
          : currentURL.split("/")[1] === "fleets"
          ? "fleet"
          : currentURL.split("/")[1]
      }/${currentURL.split("/")[2]}`
    ).then((r) => setRowData(r));
  };

  useEffect(() => {
    async function fetchRowData() {
      if (!!currentURL) {
        getRowData();
      }
    }
    fetchRowData();
  }, [currentURL, edited]);

  useEffect(() => {
    if (edited) {
      keys(edited)?.map((el) => {
        values.content.value = edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
      getRowData();
    }
  }, [JSON.stringify(edited)]);

  return (
    <div className={`DescriptionCard ${isDarkMode && "DescriptionCardDark"}`}>
      {params?.content?.value === '<p><br data-mce-bogus="1"></p>' ? (
        <div className="noDscText">
          <InfoIcon />
          No description found
        </div>
      ) : params?.content?.value === "" ? (
        <div className="noDscText">
          <InfoIcon />
          No description found
        </div>
      ) : (
        <div
          className="descriptionValue"
          dangerouslySetInnerHTML={{
            __html:
              params?.content?.value === '<p><br data-mce-bogus="1"></p>'
                ? "<div>i</div>"
                : params?.content?.value === ""
                ? "<div>i</div>"
                : params?.content?.value,
          }}
        />
      )}
      {/* <div className="noDscText">
        <InfoIcon />
        No description found
      </div>
      <div
        className="descriptionValue"
        dangerouslySetInnerHTML={{
          __html:
            params?.content?.value === '<p><br data-mce-bogus="1"></p>'
              ? "<div>i</div>"
              : params?.content?.value === ""
              ? "<div>i</div>"
              : params?.content?.value,
        }}
      /> */}
    </div>
  );
};
export default Description;
