import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

//function to generate reminder days from task start date to task deadline
export const generateReminderDays = (updatedTask, setUpdatedTask) => {
  const { taskDeadline, taskStartDate, remindMe = {} } = updatedTask;
  const { reminderType, reminderValue, reminderTime } = remindMe;

  if (!!taskDeadline && !!taskStartDate && reminderValue >= 1) {
    const parsedReminderTime =
      dayjsNY?.(taskStartDate)?.format()?.split("T")[0] +
      "T" +
      dayjsNY(reminderTime)?.format()?.split("T")[1];

    let time = dayjsNY?.(parsedReminderTime)
      ?.add(
        reminderType !== "Hours" ? reminderValue - 1 : reminderValue,
        reminderType
      )
      ?.valueOf();

    const now = dayjsNY()?.valueOf();
    const temp = [];

    while (time <= taskDeadline) {
      if (now < time) {
        temp?.push(time);
      }

      time = dayjsNY(time)?.add(reminderValue, reminderType)?.valueOf();
    }

    setUpdatedTask(() => ({
      ...updatedTask,
      remindMe: { ...(updatedTask?.remindMe || {}), reminderDays: temp },
    }));

    return temp;
  } else {
    setUpdatedTask(() => ({
      ...updatedTask,
      remindMe: { ...(updatedTask?.remindMe || {}), reminderDays: [] },
    }));
  }
};
