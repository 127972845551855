import { InputComponent } from "../../../../../../../../components";
import SignControllerButtons from "./SignControllerButtons";

const SignFieldComponent = ({
  label,
  formItemName,
  value = null,
  inputType,
  onSelect,
  options,
  disabled,
  src,
  setSignatureModalVisible,
  sign,
  setSign,
  setWhichSignToChange,
  existingSignature,
  signDisabled,
  removeDisabled,
}) => {
  return (
    <div>
      <InputComponent
        label={label}
        formItemName={formItemName}
        className="signatureSelector"
        type={inputType}
        onSelect={onSelect}
        options={options}
        placeholder="Select"
        showSearch
        required
        disabled={disabled}
        value={value}
      />
      <div className="signContainer">
        <img src={src} alt="" />
      </div>
      <SignControllerButtons
        setSignatureModalVisible={setSignatureModalVisible}
        sign={sign}
        setSign={setSign}
        setWhichSignToChange={setWhichSignToChange}
        existingSignature={existingSignature}
        signDisabled={signDisabled}
        removeDisabled={removeDisabled}
      />
    </div>
  );
};

export default SignFieldComponent;
