import { preventDuplicate } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { formatCurrency, getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";
import {
  costChartData,
  getProjectCostData,
} from "../../../addWidgetsModalData";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const projectCost = ({ programFields, tables }) => {
  const data = tables?.["Project Cost"];

  return {
    companyEmployees: () => ({
      labels: uniq(data?.map((a) => a?.company)),
      data: Object.values(
        repeatedElementSum(preventDuplicate(data, "employeeId"), "company")
      ),
      backgroundColor: uniq(data?.map((a) => getRandomColor())),
    }),

    jobsiteAddress: () => ({
      labels: uniq(data?.map((a) => a?.jobsiteMatch?.jobAddress))?.filter(
        Boolean
      ),
      data: Object.values(
        repeatedElementSum(preventDuplicate(data, "employeeId"), "jobsiteId")
      ),
      backgroundColor: uniq(data?.map((a) => getRandomColor())),
    }),

    punchType: () => ({
      labels: uniq(data?.map((a) => a?.punchType)),
      data: Object.values(
        repeatedElementSum(preventDuplicate(data, "employeeId"), "punchType")
      ),
      backgroundColor: uniq(data?.map((a) => getRandomColor())),
    }),

    role: () => ({
      labels: uniq(data?.map((a) => a?.role)),
      data: Object.values(
        repeatedElementSum(preventDuplicate(data, "employeeId"), "role")
      ),
      backgroundColor: uniq(data?.map((a) => getRandomColor())),
    }),

    jobsiteTotalCost: () => {
      const costDispersion = getProjectCostData({
        data,
        jobsites: tables?.Jobsites,
      });

      const dataSets = costChartData(
        costDispersion,
        "jobsiteAddress",
        "total",
        formatCurrency
      );
      return {
        labels: dataSets?.labels,
        data: dataSets?.data,
        backgroundColor: dataSets?.backgroundColor,
      };
    },

    jobsiteTotalHours: () => {
      const costDispersion = getProjectCostData({
        data,
        jobsites: tables?.Jobsites,
      });

      const dataSets = costChartData(
        costDispersion,
        "jobsiteAddress",
        "totalWorkHours"
      );

      return {
        labels: dataSets?.labels,
        data: dataSets?.data,
        backgroundColor: dataSets?.backgroundColor,
      };
    },
  };
};
