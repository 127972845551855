import { useState } from "react";
import { Tooltip } from "antd";

import MondayButton from "../MondayButton/MondayButton";
import { LogsIcon } from "../../SidebarPages/DynamicView/src";
import MultiLevelTreeLogs from "../MultiLevelTreeLogs/MultiLevelTreeLogs";
import { fetchAllData, showErrorMsg, showLoadingMsg } from "../../../utils";

const DynamicEditLogs = ({ filterKey, filterValue, title }) => {
  const [logsVisible, setLogsVisible] = useState(false);
  const [editLogs, setEditLogs] = useState([]);

  const onButtonClickHandler = () => {
    showLoadingMsg({ content: "Fetching logs, please wait..." });
    if (!editLogs.length && filterKey && filterValue) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: filterKey,
                  value: filterValue,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          setEditLogs(res);
          setLogsVisible(true);
        })
        .catch((err) =>
          showErrorMsg({
            content:
              "An error occurred while fetching logs. Please try again later.",
          })
        );
      return;
    }
    showErrorMsg({
      content: "An error occurred while fetching logs. Please try again later.",
    });
  };

  return (
    <>
      <Tooltip title="Edit Logs">
        <MondayButton
          hasIcon={false}
          className="mondayButtonBlue"
          onClick={onButtonClickHandler}
          id={"editLogs-btn"}
          Icon={<LogsIcon style={{ fill: "#fff" }} />}
        />
      </Tooltip>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: (value) => setLogsVisible(value),
            logsData: editLogs || [],
            title,
          }}
        />
      )}
    </>
  );
};

export default DynamicEditLogs;
