import getTripsFromActualActivities from "./getTripsFromActualActivities";

/**
 * Function that makes a comparison between the initial states
 * of the activities for the date and the user changes
 */
function saveAuditChanges({
  trips,
  fleetId = "",
  actualActivities,
  auditsForTruck = {},
  newAuditedActivities = [],
  linkedStops: newLinkedStops = [],
  discardedStops: newDiscardedStops = [],
}) {
  let discarded = [...(auditsForTruck?.discarded || [])];
  let discardedStops = [
    ...(auditsForTruck?.discardedStops || []),
    ...newDiscardedStops,
  ];
  let linkedStops = [...(auditsForTruck?.linkedStops || [])];
  let changed = {
    ...(auditsForTruck?.changed || {}),
  };

  let changesForTruck = {
    [fleetId]: {
      discarded,
      changed,
      discardedStops,
      linkedStops,
    },
  };

  let initialTripsState = getTripsFromActualActivities(actualActivities, {
    needsValidation: false,
    auditsForTruck,
  });

  for (let i = 0; i < initialTripsState?.length; i++) {
    let trip = initialTripsState[i];
    let newTripState = undefined;

    if (trip["alertUUID"] !== trips?.[i]?.["alertUUID"]) {
      //here the trip is not in the index it was initially on
      //it may have changed index or been discarded
      newTripState = trips?.find(
        ({ alertUUID }) => alertUUID === trip["alertUUID"]
      );
      if (!newTripState) {
        discarded.push(trip["alertUUID"]);
        continue;
      } else {
        if (
          trip["alertDateTime"] !== newTripState?.["alertDateTime"] ||
          newTripState?.["audited"]
        ) {
          let activityId = undefined;
          if (newTripState?.["audited"]) {
            let index = newAuditedActivities?.findIndex(({ alerts = [] }) =>
              alerts?.includes(trip["alertUUID"])
            );
            if (index > -1) {
              activityId = newAuditedActivities?.[index]?.["id"];
            }
          }

          changed[trip["alertUUID"]] = {
            alertDateTime: newTripState?.["alertDateTime"],
            audited: newTripState?.["audited"] || false,
            activityId,
          };
          continue;
        }
      }
    } else {
      if (
        trip["alertDateTime"] !== trips?.[i]?.["alertDateTime"] ||
        trips?.[i]?.["audited"]
      ) {
        let activityId = undefined;
        if (trips?.[i]?.["audited"]) {
          let index = newAuditedActivities?.findIndex(({ alerts = [] }) =>
            alerts?.includes(trip["alertUUID"])
          );
          if (index > -1) {
            activityId = newAuditedActivities[index]["id"];
          }
        }

        changed[trip["alertUUID"]] = {
          alertDateTime: trips?.[i]?.["alertDateTime"],
          audited: trips?.[i]?.["audited"] || false,
          activityId,
        };
        continue;
      }
    }
  }

  /**
   * The state of the linked stops always holds
   * the newly created stops, so we can put them in the changed object
   */
  for (const linked of newLinkedStops) {
    let start = trips?.find(({ alertUUID }) => alertUUID === linked?.beginUUID);
    let end = trips?.find(({ alertUUID }) => alertUUID === linked?.endUUID);

    linkedStops.push(linked);

    if (start) {
      let activityId = undefined;
      if (start?.["audited"]) {
        let index = newAuditedActivities?.findIndex(({ alerts = [] }) =>
          alerts?.includes(start?.["alertUUID"])
        );

        if (index > -1) {
          activityId = newAuditedActivities[index]["id"];
        }
      }

      changed[start["alertUUID"]] = {
        alertDateTime: start?.["alertDateTime"],
        audited: start?.["audited"] || false,
        activityId,
      };
    } else {
      discarded.push(linked?.beginUUID);
    }

    if (end) {
      let activityId = undefined;
      if (end?.["audited"]) {
        let index = newAuditedActivities?.findIndex(({ alerts = [] }) =>
          alerts?.includes(end?.["alertUUID"])
        );

        if (index > -1) {
          activityId = newAuditedActivities[index]["id"];
        }
      }

      changed[end["alertUUID"]] = {
        alertDateTime: end?.["alertDateTime"],
        audited: end?.["audited"] || false,
        activityId,
      };
    } else {
      discarded.push(linked?.endUUID);
    }
  }

  return changesForTruck;
}

export default saveAuditChanges;
