import { useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";

import { compareIncluding } from "../../../../../../../utils";
import {
  CheckAccessRights,
  fetchAllData,
  getCognitosForNotification,
} from "../../../../../../../../../utils";
import {
  DownloadIcon,
  EmailIcon,
  Pdf,
} from "../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../hooks";
import { documentDefinition } from "./documentDefinition";
import createPDF, {
  PREVIEW,
} from "../../../../../../../../../integrations/createPDF";
import { LogsIcon } from "../../../../../../../DynamicView/src";
import { exportPaymentsExcel } from "../PaymentTable/utils/export";
import { savePdfToDrive } from "../../../../../../../Safety/safetyPdf";
import {
  MondayButton,
  MultiLevelTreeLogs,
  WithTooltip,
} from "../../../../../../../../commonComponents";
import { Notes } from "../../../../../../../../commonComponents/Notes/Notes";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import EmailBox from "../../../../../../../Communication/components/EmailBox/EmailBox";
import Report from "../../../../../../../DynamicView/Pages/Overview/components/Report/Report";
import DynamicTemplateDesc from "../PaymentForm/components/DynamicTemplateDesc/DynamicTemplateDesc";
import { UndoRedoButtons } from "../../../../../../../../commonComponents/UndoRedoButtons/UndoRedoButtons";
import broadcastNotification from "../../../../../../../../../helpers/controllers/broadcastNotification";

import "./ControlPanel.scss";
import { openEmailBox } from "../../../../../../../Communication/functions";
import { useEditLogs } from "../../../../../../../../../hooks";

const ControlPanel = ({
  canUndo,
  canRedo,
  fakePayment,
  paymentData,
  undoClickHandler,
  redoClickHandler,
  selectedClientProjects,
}) => {
  const [pdfId, setPdfId] = useState([]);
  const [editLogs, setEditLogs] = useState([]);
  const [logsVisible, setLogsVisible] = useState(false);
  const [isEmailBoxOpen, setIsEmailBoxOpen] = useState(false);
  const [visibleReport, setVisibleReport] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [emailBoxes, setEmailBoxes] = useState([]);

  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const [isWritable] = useRedux("paymentIsWritable");
  const { saveAddedLogs } = useEditLogs();

  const driveRequest = driveApi({ accessToken });

  const [{ paymentId, paymentNumber, teamsConfiguration } = {}] =
    paymentData || [];

  const {
    googleDriveFolderIds = {},
    projectExecutive = "",
    paymentTerm = [],
  } = selectedClientProjects[0] || {};

  const { projectId: tempGoogleDriveFolderId } = googleDriveFolderIds || {};

  const openEditLogsHandler = () => {
    if (!isWritable) return;

    const hideLoading = message.loading(
      "Retrieving log data. Please wait...",
      0
    );

    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: paymentId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((result) => {
        setEditLogs(result);
        setLogsVisible(true);
        hideLoading();
      })
      .catch((err) => {
        hideLoading();
        message.error(
          "Sorry, we couldn't retrieve the logs at the moment. Please try again later."
        );
      });
  };

  const onExportToExcel = () => {
    const paymentDetails = {
      projectExecutive: projectExecutive || "",
      paymentTerm: paymentTerm?.[0] || false, // format of object is {days: int, description, id, rate: int, value: string (title)}
    };
    return exportPaymentsExcel({
      payments: fakePayment,
      paymentDetails,
    });
  };

  function sendEmailNotification() {
    broadcastNotification(
      "42",
      "onPaymentEmail",
      [
        {
          common: userConfiguration?.nameOfUser,
        },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: authenticatedUser?.sub,
          cognitos: getCognitosForNotification(
            userConfiguration,
            teamsConfiguration
          ),
        },
      ],
      paymentId
    );
    saveAddedLogs({
      recordId: paymentId,
      recordName: `Payment #${paymentNumber}`,
      actionType: "Email",
      category: "Payments",
      topic: `Payment #${paymentNumber}`,
      currentData: { User: "Sent an email for this record" },
    }).then((res) => {
      setEditLogs((prev) => [...prev, ...res]);
    });
  }

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setIsEmailBoxOpen(false);
    }
  }

  const generatePaymentPdfHandler = async () => {
    const pdfTitle = `Payment #${paymentNumber}`; // Account Name - ${accountName}`;
    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;

    createPDF({
      action: PREVIEW,
      docDefinition: documentDefinition(pdfTitle, companyLogo, fakePayment),
      // title: pdfTitle,
    });
    await savePdfToDrive({
      docDefinition: documentDefinition(pdfTitle, companyLogo, fakePayment),
      accessToken,
      folderId: tempGoogleDriveFolderId,
      title: `Payment #${paymentNumber}`,
    }).then((res) => {
      setPdfId(res?.id);
    });
  };

  const Icons = [
    {
      title: "Email",
      Icon: EmailIcon,
      onClick: () => setIsEmailBoxOpen((prev) => !prev),
      tooltipKey: "openEmail",
      visible: !!paymentId,
      disabled: !isWritable,
    },
    {
      title: "View Report",
      Icon: Pdf,
      onClick: () => setVisibleReport(true),
      visible: !!paymentId,
      disabled: !isWritable,
      tooltipKey: "showReport",
    },
    {
      title: "Export to PDF",
      Icon: Pdf,
      onClick: () => generatePaymentPdfHandler(),
      visible: !!paymentId,
      disabled: !isWritable,
      tooltipKey: "exportPdf",
    },
    {
      title: "Export to Excel",
      Icon: DownloadIcon,
      onClick: () => onExportToExcel(),
      visible: true,
      disabled: !isWritable,
      tooltipKey: "exportExcel",
    },
  ]?.filter(Boolean);

  const sendEmailProps = {
    email: {
      onClose: () => {
        if (pdfId) driveRequest.deleteDriveItem(pdfId);
        setIsEmailBoxOpen(false);
      },
      setEmails: () => {},
      rowData: paymentData?.[0],
      onSave: (e, type, setLoading) => {
        onEmailSave({ e, type, setLoading });
      },
      sendEmailNotification,
      isRedirected: [pdfId],
      isFromCharge: true,
      proppedTemplateCat: "Payments",
    },

    reports: {
      rowData: paymentData,
      onClose: () => {
        if (attachments.length > 0) setAttachments([]);
        setEmailBoxes([]);
      },
      attachments,
      openEmailBox,
    },
  };

  return (
    <>
      <div
        className={
          isDarkMode ? "invoiceFormContainer-dark" : "invoiceFormContainer"
        }
      >
        <div className="control-panel-payment-container ">
          <div className="control-panel-payment-left">
            <UndoRedoButtons
              {...{
                canUndo,
                canRedo,
                undoClickHandler,
                redoClickHandler,
                tooltipCategory: "Payments",
              }}
            />
          </div>
          <div className="control-panel-payment-right">
            <div className="control-panel-payment-item">
              <DynamicTemplateDesc />
            </div>
            <div>
              {paymentData && (
                <>
                  <WithTooltip tooltipCategory="Payments" tooltipKey="showLogs">
                    <span onClick={openEditLogsHandler}>
                      <LogsIcon
                        style={{
                          fill: !isWritable ? "#323338" : "#fff",
                          borderRadius: "5px",
                          margin: "auto",
                        }}
                      />
                    </span>
                  </WithTooltip>
                  {CheckAccessRights(
                    userConfiguration?.routeConfig?.find(
                      ({ title }) => title === "Payments/View"
                    )?.children,
                    "Notes"
                  ) && (
                    <div className="control-panel-payment-item">
                      <Notes
                        noteModalName={`${fakePayment.accountName} | Payment ${fakePayment?.paymentNumber}`}
                        rowId={fakePayment?.paymentId}
                        topicCategory="Payments"
                        tooltipCategory
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {Icons.map(
              (
                { title, onClick, Icon, visible, disabled, tooltipKey },
                index
              ) => {
                return (
                  <MondayButton
                    key={index}
                    disabled={disabled}
                    Icon={<Icon />}
                    className={
                      disabled ? "mondayButtonGrey" : "mondayButtonBlue"
                    }
                    onClick={onClick}
                    style={{ display: visible ? "block" : "none" }}
                    tooltipCategory="Payments"
                    tooltipKey={tooltipKey}
                  >
                    {title}
                  </MondayButton>
                );
              }
            )}
          </div>
        </div>
        {isEmailBoxOpen ||
          (emailBoxes?.length > 0 && (
            <div>
              <EmailBox
                {...(isEmailBoxOpen
                  ? { ...sendEmailProps?.email }
                  : { ...sendEmailProps?.reports })}
              />
            </div>
          ))}
      </div>
      {visibleReport && (
        <Report
          {...{
            isModalVisible: visibleReport,
            onCancel: () => setVisibleReport(false),
            customCategoryName: "Receive Payment Report",
            customPartitionKeys: { identityId: "paymentId" },
            recordId: fakePayment.paymentId,
            setVisibleReport,
            emailBoxes,
            setEmailBoxes,
            setAttachments,
            sendByEmail: true,
          }}
        />
      )}
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: editLogs || [],
            title: "Payments Logs",
          }}
        />
      )}
    </>
  );
};

export default ControlPanel;
