import { message } from "antd";
import axios from "axios";
import * as FileSaver from "file-saver";

/**
 * Exports the given HTML content to a DOCX file.
 * @async
 * @param {Object} options - The export options.
 * @param {string} [options.customFileName="Document"] - The custom file name for the exported file.
 * @param {string} options.html - The HTML content to be exported.
 * @param {ExportWordOthersType} [options.others={}] - Additional options for the export.
 * @returns {Promise<void>} - A promise that resolves when the export is completed.
 */
export async function exportDOCX({
  customFileName = "Document",
  html = "",
  others = {},
  replacePadding = true,
}) {
  const fileName = customFileName + ".docx";
  // !!customFileName
  //   ? customFileName?.replace(/\W+/g, "-").toLowerCase() + ".docx"
  //   : customFileName;
  const url =
    "https://leadmanager-express-backend.ue.r.appspot.com/api/export/docx";
  // const url = "http://localhost:8080/api/export/docx";

  if (!html) {
    message.error({
      content: "No content to export.",
      key: "exporting",
    });
    return;
  }

  message.loading({
    content: "File is being processed. Please wait...",
    duration: 10,
    key: "exporting",
  });
  await axios
    .post(
      url,
      {
        apiKey: "5e550158-dc8c-4814-b8d3-db8a8341bf88",
        html,
        others: {
          orientation: "portrait",
          replacePadding,
          fileName,
          ...(others || {}),
        },
      },
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      console.log({ response });
      const url2 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download", `${fileName || "document"}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      message.success({
        content: "File is ready for download.",
        key: "exporting",
      });
    })
    .catch((err) => {
      message.error({
        content: "Error exporting file.",
        key: "exporting",
      });
      console.log({ err });
    });
}
