import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const inspections = ({ tables, programFields }) => {
  return {
    inspectedBy: () => {
      const data = repeatedElementSum(
        tables?.Inspections?.map((el) => el?.inspectedBy) || [],
        "nameOfUser"
      );

      return {
        labels: Object.keys(data),
        data: Object.values(data),
        backgroundColor: Object.keys(data).map(() => getRandomColor()),
      };
    },

    inspectionType: () => {
      const data = repeatedElementSum(
        tables?.Inspections || [],
        "inspectionType"
      );

      return {
        labels: Object.keys(data),
        data: Object.values(data),
        backgroundColor: Object.keys(data).map(() => getRandomColor()),
      };
    },

    inspectionStatus: () => {
      const data = repeatedElementSum(
        tables?.Inspections || [],
        "inspectionStatus"
      );

      const statuses =
        programFields
          ?.find((el) => el?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Inspection?.reduce(
            (acc, curr) => ({
              ...acc,
              [curr?.statusName]: curr?.statusColor,
            }),
            {}
          ) || [];

      return {
        labels: Object.keys(data),
        data: Object.values(data),
        backgroundColor: Object.keys(data).map(
          (el) => statuses?.[el] || getRandomColor()
        ),
      };
    },
  };
};
