import "./LeftAlignRenderer.scss";

/**
 * Custom cell renderer component that simply allows for the text
 * to be aligned to the left. The component can be called from the
 * columnDefs by name. The "value" prop is the value the component
 * gets from the grid. The other props can be passed to the component
 * by adding the "cellRendererParams" to the columnDefs
 * Ca Klajdi qe eshte
 */
function LeftAlignRenderer({ value, fallback, formatter, customValue }) {
  let val = value;

  if (customValue) {
    val = customValue;
  } else {
    if (!val && isNaN(+val)) {
      val = fallback || "";
    }
  }

  return (
    <span className="ag-cell-text-left-align">
      {formatter ? formatter(val) : val}
    </span>
  );
}

export default LeftAlignRenderer;
