import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react";
import { useState, useContext } from "react";

import DegModalContext from "../DegModalContext";
import { getRolesCost } from "../utils/getCategoryCost";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { detailColDefs, mainColDefs } from "./categoryCostModalData";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";

import "./CategoryCostModal.scss";

function CategoryCostModal({ open, onCancel }) {
  const { analytics, crews, isDarkMode } = useContext(DegModalContext);

  const [rowData] = useState(getRolesCost({ analytics, crews }));

  return (
    <Modal
      title="Category Cost"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Category Cost" });
      }}
      open={open}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager";
        onCancel();
      }}
      closable={true}
      closeIcon={<XIcon />}
      className={`categoryCostModal ${isDarkMode && "categoryCostModalDark"}`}
      data-testid="category-cost-modal"
      centered
      footer={[
        <MondayButton
          className="mondayButtonRed"
          data-testid="close-category-cost-modal-btn"
          Icon={<XIcon />}
          key="1"
          onClick={() => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            onCancel();
          }}
        >
          Close
        </MondayButton>,
      ]}
    >
      <div
        className={`cost-dispersion-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            columnDefs: mainColDefs(),
            detailCellRendererParams: detailColDefs(),
            masterDetail: true,
            rowSelection: "multiple",
            pagination: false,
            detailRowHeight: 300,
            gridOptions: {
              alwaysShowHorizontalScroll: true,
              alwaysShowVerticalScroll: true,
            },
            suppressRowClickSelection: true,
            animateRows: true,
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
    </Modal>
  );
}

export default CategoryCostModal;
