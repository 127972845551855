import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BorderedTextCard,
  InputComponent,
  NormalSizedModal,
} from "../../../components";
import { API } from "aws-amplify";
import {
  footerButtons,
  dateFormat,
  timeFormat,
  violationKeyTranslate,
  dynamicInputFields,
} from "./newViolationModalData";
import axios from "axios";
import { filterTables } from "../../../../../../utils/filterTables";
import "./NewViolationModal.scss";
import { Form, message, Tour, Modal, Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { compareIncluding } from "../../../../utils";
import { apiRoutes, fetchData } from "../../../utils";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import {
  MondayButton,
  ProgressComponent,
  SortModalFields,
} from "../../../../../commonComponents";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as CloseIcon } from "../../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../../icons/warningTriangle.svg";
import { useRedux } from "../../../../hooks/useRedux";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { fleetFields as updateFleetFields } from "../../../../../../actions";
import { XIcon } from "../../../../Communication/assets";
import { useCreateDriveFolders } from "../../../../../../hooks/useCreateDriveFolders";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { AddIcon } from "../../../../../Header/components/GoogleEvents/assets";
import DynamicTeamModal from "../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import {
  getCognitosForNotification,
  updateTeamsConfiguration,
} from "../../../../../../utils";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import { getSelectedBaseRecords } from "src/utils/searchFetchForInput";
import { TransferIconNew } from "src/components/SidebarPages/DynamicView/src";
import { useProgressComponent } from "../../../../../../hooks";

const NewViolationModal = ({
  visible,
  setVisible,
  refreshTable = () => {},
  defaultData,
  fleetName = false,
  driverName = false,
  isNextStep,
}) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [documentUploaded, setDocumentUploaded] = useState();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [users, setUsers] = useState([]);
  const [driveFolderId, setDriveFolderId] = useState();

  const [fileNames, setFileNames] = useState([]);
  const [projects, setProjects] = useState([]);
  // const [licenseClass, setLicenseClass] = useState([])
  const [fleet, setFleet] = useState();
  const [form] = Form.useForm();
  const [violationStatus, setViolationStatus] = useState([]);
  const [isViolationStatusPaid, setIsViolationStatusPaid] = useState(false);
  const [fleetId, setFleetId] = useState();
  const [fleetNameFromPath, setFleetNameFromPath] = useState();
  const [licensePlate, setLicensePlate] = useState();
  const [driverId, setDriverId] = useState();
  const [driverRequired, setDriverRequired] = useState(true);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [saving, setSaving] = useState();
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [verifyUrl, setVerifyUrl] = useState();
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const { fleetFields = [] } = useSelector((state) => state.fleetFields);
  const { programFields = [] } = useSelector((state) => state.programFields);
  const driveFunctions = useCreateDriveFolders("Fleet Inspections");
  const driveRequest = driveApi({ accessToken });
  let { pathname } = useLocation() || {};
  const [ViolationRowData] = useRedux("ViolationRowData");
  const { rowData } = ViolationRowData || {};
  const splitLocation = pathname.split("/");
  const oldFolderId = useRef();
  const [fleetViolationTeams, setFleetViolationTeams] = useState([]);
  const [pdfModal, setPdfModal] = useState();

  const [currentPageKey, setCurrentPageKey] = useState();
  const [loadingRecords, setLoadingRecords] = useState();
  const projectSearchRef = useRef("");

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Fleets Violations",
    actionType: "Create",
  });

  const [open, setOpen] = useState(false);
  const [syncedSuccessfully, setSyncedSuccessfully] = useState(false);
  const [accessRight] = useState(
    userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Fleets Violations/View")
      ?.children?.find(({ title }) => title === "Overview")
  );

  const checkVehicle = Form.useWatch("vehicle", form);
  const checkIssueDate = Form.useWatch("issuedDate", form);

  const navigate = useNavigate();

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Fleet Management")
    ?.subCategories[0].videos.find((item, i) => i === 7)?.videoLink;

  const [sortOpen, setSortOpen] = useState(false);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.violationModal
  );

  const routedDriverID =
    !!compareIncluding(pathname, "/fleet/drivers") && splitLocation[3];

  const routedFleetID =
    !compareIncluding(pathname, "overview") &&
    splitLocation.length === 3 &&
    splitLocation[2];

  const validateViolationNumber = () =>
    !!rowData
      ?.filter?.(
        ({ fleetName }) =>
          fleetName ===
          fleet?.find(
            ({ fleetId }) => fleetId === form.getFieldValue("vehicle")
          )?.fleetName
      )
      ?.find(
        ({ summons_number, violation }) =>
          compareIncluding(summons_number, form.getFieldValue("violation#")) &&
          compareIncluding(violation, form.getFieldValue("violation"))
      )
      ? false
      : true;

  function getAccessRights(childTitle) {
    return (accessRight?.children || [])?.find(
      ({ title }) => title === childTitle
    );
  }

  async function passSaveHandler(redirect) {
    // if (!!validateViolationNumber()) {
    //   try {
    await onSave(redirect);
    //     form.resetFields();
    //   } catch {}
    // } else {
    //   setOpenWarningModal(true);
    // }
  }

  //validates the input fields and checks if a document has been uploaded

  async function validateFormFields(redirect = true) {
    // form.validateFields(getRequiredFieldNames());
    // if (documentUploaded) {
    let requiredFields = getRequiredFieldNames();
    // for (const name of requiredFields) {
    //   if (!form.getFieldValue(name)) {
    //     message.error("Please fill in all required fields!");
    //     return;
    //   }
    // }
    await passSaveHandler(redirect);
    // } else {
    //   message.error("Please upload the necessary documents!");
    // }
  }

  //gets the names and ids of the uploaded files
  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    let uploadedFileNames = [];

    for (const { name, id } of uploadedFiles) {
      uploadedFileNames.push({ name, id, uploadedAt: dayjs().valueOf() });
    }

    setFileNames(
      !![...fileNames]?.filter((item) =>
        compareIncluding(item.uploaderId, uploaderId)
      ).length
        ? [...fileNames]?.map((item) =>
            !compareIncluding(item?.uploaderId, uploaderId)
              ? item
              : {
                  ...item,
                  files: [...item?.files, ...uploadedFileNames],
                }
          )
        : [...fileNames, { uploaderId, files: [...uploadedFileNames] }]
    );

    setDocumentUploaded(true);
  };

  //handles the deletion of files from the google drive when editing the violation
  const onFileDelete = async ({ uploaderId, id }) => {
    const newFileNames = fileNames?.map((item) => {
      const { uploaderId: filesUploaderId, files = [] } = item;

      return compareIncluding(filesUploaderId, uploaderId)
        ? {
            ...item,
            files: files?.filter(({ id: existingId }) => existingId !== id),
          }
        : item;
    });

    driveRequest.deleteDriveItem(id);
    setFileNames(newFileNames);
    setFilesToBeDeleted([...filesToBeDeleted, id]);
  };

  //Posts the new violation to the database
  const onSave = async (redirect) => {
    const key = "savingMsg";
    message.loading({ content: "Saving...", key });
    setSaving(true);
    setVisibleCreationProgress(true);

    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam
    );
    setSelectedTeam([]);

    updateProgressStatus({ updatingRecord: "executing" });
    await API.post(apiRoutes.fleetViolations, `/${apiRoutes.fleetViolations}`, {
      body: {
        amount_due:
          parseInt(form.getFieldValue("liabilityAmount")) -
          parseInt(form.getFieldValue("amountPaid") || 0) +
          parseInt(form.getFieldValue("interestAmount") || 0) +
          parseInt(form.getFieldValue("penaltyAmount") || 0) -
          parseInt(form.getFieldValue("reductionAmount") || 0),
        summons_number: form.getFieldValue("violation#").toString(),
        summons_image:
          !!defaultData && defaultData?.summons_image
            ? defaultData?.summons_image
            : form.getFieldValue("verify")?.toString() || "",
        violationLocation: form.getFieldValue("violationLocation"),
        teamsConfiguration,
        projectId: projects?.find(({ projectName }) =>
          compareIncluding(projectName, form.getFieldValue("jobsite"))
        )?.projectId,
        state: form
          .getFieldValue("violationLocation")
          .split(",")
          [
            form.getFieldValue("violationLocation").split(",").length - 2
          ]?.trim(),
        county: form
          .getFieldValue("violationLocation")
          .split(",")
          [
            form.getFieldValue("violationLocation").split(",").length - 3
          ]?.trim(),
        driver: drivers.find((driver) =>
          compareIncluding(driver.driverId, form.getFieldValue("driver"))
        ).driverName,
        fleetId: form.getFieldValue("vehicle"),
        fleetName: fleet?.find(
          ({ fleetId }) => fleetId === form.getFieldValue("vehicle")
        )?.fleetName,
        fine_amount: parseInt(form.getFieldValue("liabilityAmount")),
        interest_amount: parseInt(form.getFieldValue("interestAmount")),
        penalty_amount: parseInt(form.getFieldValue("penaltyAmount")),
        reduction_amount: parseInt(form.getFieldValue("reductionAmount")),
        googleDriveFolderIds: { violationObject: driveFolderId },
        issue_date:
          moment(form.getFieldValue("issuedDate")).startOf("day").unix() * 1000,
        violation_time: moment(form.getFieldValue("issuedDate")).unix() * 1000,
        issuing_agency: form.getFieldValue("issuingAgency"),
        jobsite: form.getFieldValue("jobsite"),
        license_type: form.getFieldValue("licenseType"),
        plate: fleet?.find(
          ({ fleetId }) => fleetId === form.getFieldValue("vehicle")
        )?.licensePlate,
        payment_amount: parseInt(form.getFieldValue("amountPaid")),
        isPaid: form.getFieldValue("paymentStatus"),
        violation: form.getFieldValue("violation"),
        violation_status: form.getFieldValue("violationStatus"),
        receivedDate: !!form.getFieldValue("receivedDate")
          ? moment(form.getFieldValue("receivedDate")).startOf("day").unix() *
            1000
          : "",
        receivedBy: form.getFieldValue("receivedBy"),
        details: form.getFieldValue("details"),
        driveFileId: fileNames[0].files,
        teamsConfiguration,
      },
    })
      .then((r) => {
        //set progress of creating record as 100%
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });
        setVisibleCreationProgress({ ...r, redirect }); // save data to generate logs in case of any fail

        refreshTable([r]);

        broadcastNotification(
          "11",
          "onViolationCreation",
          [
            {
              common: userConfiguration?.nameOfUser,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
              cognitos: getCognitosForNotification(
                userConfiguration,
                teamsConfiguration
              ),
            },
          ],
          r?.violationId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
        message.success({ content: "Saved!", key, duration: 3 });
        setSaving(false);
        form.resetFields();
        setFileNames([]);
        setDocumentUploaded(false);
      })
      .catch((e) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        console.error("Violation post failed ", e);
      });
  };

  //hides the modal, resets the input fields and deletes all the uploaded files from GDrive
  const onCancel = () => {
    driveFunctions.delete();
    form.resetFields();
    setCancelModalVisible(false);
    setVisible(false);
    // fileNames.forEach(({ files = [] }) => {
    //   for (const { id } of files) {
    //     driveRequest.deleteDriveItem(id);
    //   }
    // });
    setFileNames([]);
    setDocumentUploaded(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  //Mock data
  const tempAgency = ["Test Agency"];
  useEffect(() => {
    if (fleetFields) {
      setViolationStatus(
        programFields
          ?.find((item) => item.fieldName === "Statuses Of Categories")
          .fieldOptions["Fleets Violations"].filter(({ status }) => status)
          .map(({ statusName }) => statusName)
      );
    }
  }, [fleetFields]);

  useEffect(() => {
    // onChange of Received Date or Vehicle reCreates the drive folder to match the Violation Date
    if (!!checkVehicle && !!checkIssueDate && !!fleet?.length) {
      driveFunctions.delete();
      const vehicle = fleet?.find(({ fleetId }) => fleetId === checkVehicle);
      const fleetFolderId = vehicle?.googleDriveFolderIds?.violationObject;
      const folderName = dayjs(checkIssueDate).format("MM/DD/YYYY, hh:mm:ss");

      driveFunctions
        .create({
          parentFolderName: folderName,
          parentId: fleetFolderId,
        })
        .then((res) => {
          setDriveFolderId(res.parentFolder);
        })
        ?.catch((err) => {
          console.error("Error creating folder: ", err);
        });
      message.destroy("projectDriveFolders");
      message.destroy("windowMessages");
    }
  }, [checkVehicle, checkIssueDate]);

  //Fetches data from the API and populates fields depending on whether we are creating a violation for a vehicle or a driver
  useEffect(() => {
    Promise.all([
      fetchData("drivers"),
      // fetchData("projects", "projects", "projectId"),
      getSelectedBaseRecords({
        idKey: "projectId",
        table: "projects",
        setLoading: setLoadingRecords,
        setRecords: setProjects,
        userConfiguration,
        setCurrentPageKey,
      }),
      fetchData("fleet"),
      fetchData("teams"),
    ])
      .then(([driversData, _, fleetData, teamsData]) => {
        !!routedDriverID &&
          form.setFieldsValue({
            licenseType: driversData.find(({ driverId: arrDriverId }) =>
              compareIncluding(arrDriverId, routedDriverID)
            )?.DlClass,
          });
        setDrivers(driversData);
        setUsers(userConfiguration.allUsers.Items);
        // setProjects(projectsData.projects);
        // getSelectedBaseRecords({
        //   idKey: "projectId",
        //   table: "projects",
        //   setLoading: setLoadingRecords,
        //   setRecords: setProjects,
        //   userConfiguration,
        //   setCurrentPageKey,
        // });
        setFleet(fleetData);

        if (!!routedFleetID) {
          let f = fleetData?.find((el) => el?.fleetId === routedFleetID);
          if (!!f) {
            setSelectedTeam(f?.teamsConfiguration || []);
            setLicensePlate(f?.licensePlate);
            form.setFieldValue("licensePlate", f?.licensePlate);
            form.setFieldValue(
              "violationTeam",
              (f?.teamsConfiguration || [])?.map((team) => team?.value)
            );
          }
        }

        setAllTeams(teamsData);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });

    if (!!routedDriverID) {
      setDriverId(routedDriverID);
      form.setFieldsValue({ driver: routedDriverID });
    } else if (!!routedFleetID) {
      setFleetId(routedFleetID);
      API.get("fleet", `/fleet/${routedFleetID}`)
        .then((res) => {
          let fleetName = res?.fleetName;
          form.setFieldsValue({ vehicle: res?.fleetId });
          setFleetNameFromPath(fleetName);
        })
        .catch((err) => {
          console.error("Error getting truck: ", err);
        });
    }
    form.setFieldsValue({ driverIdentified: "Yes", paymentStatus: "No" });
  }, []);

  // new shortcut from sidebar needs fleetFields to be filled
  useEffect(() => {
    if (!fleetFields?.length > 0) {
      API.get("fleetFields", `/fleetFields`).then((res) =>
        dispatch(updateFleetFields(res))
      );
    }
    API.get("teams", "/teams").then((teams) => {
      setFleetViolationTeams(
        teams?.map((team) => ({
          label: team.teamName,
          value: team.teamName,
          members: team.members?.map(
            ({ identityId, nameOfUser, cognitoUserId = "" }) => {
              return {
                identityId,
                nameOfUser,
                cognitoUserId,
              };
            }
          ),
        }))
      );
    });
  }, []);

  function resetOnSync() {
    let nr = form.getFieldValue("violation#");
    let driverIdentified = form.getFieldValue("driverIdentified");
    let driver = form.getFieldValue("driver");
    let vehicle = "",
      licensePlate = "";
    if (!!routedFleetID) {
      vehicle = form.getFieldValue("vehicle");
      licensePlate = form.getFieldValue("licensePlate");
    }
    form.resetFields();
    form.setFieldsValue({
      "violation#": nr,
      driverIdentified,
      paymentStatus: "No",
      driver,
      vehicle,
      licensePlate,
    });
  }

  /**
   * Function that auto-fills the modal data with a violation
   * @param {Object} data The values to be distributed
   * @param {Boolean} fromClick Distinguishes between the auto-filling on default data and auto-filling on "Sync" click
   */
  function populateData(data, fromClick) {
    setDriverRequired(!!data?.driverId ?? !!form?.getFieldValue("driver"));
    let tmpFormValues = {
      paymentStatus: "No",
      driverIdentified:
        !!data?.driverId || !!form?.getFieldValue("driver") ? "Yes" : "No",
      verify: data?.summons_image?.url ?? "",
      vehicle: "",
    };

    for (const key in violationKeyTranslate) {
      if (key === "violationLocation") {
        tmpFormValues[key] = violationKeyTranslate[key];
      } else if (key.includes("Date")) {
        tmpFormValues[key] = moment.unix(
          moment(data[violationKeyTranslate[key]]).format("X")
        );
      } else {
        tmpFormValues[key] = data[violationKeyTranslate[key]];
      }
    }

    let tmpFleet;
    if (routedFleetID) {
      tmpFleet = fleet?.find(({ fleetId: id }) => id === routedFleetID);
    } else {
      tmpFleet = fleet?.find((el) => `${el?.licensePlate}` === data?.plate);
    }

    if (!!tmpFleet) {
      tmpFormValues.vehicle = routedFleetID || tmpFleet?.fleetId;
      tmpFormValues.licensePlate = tmpFleet?.licensePlate;
      setSelectedTeam(tmpFleet?.teamsConfiguration || []);
      form.setFieldValue(
        "violationTeam",
        (tmpFleet?.teamsConfiguration || [])?.map((item) => item?.value)
      );
    } else {
      message.warning(
        <>
          <span>
            This violation has been issued to a vehicle that's not in our
            records. Plate:{" "}
          </span>
          <span style={{ color: "#1065A4", fontWeight: "500" }}>
            {data?.plate}
          </span>
        </>,
        5
      );
      resetOnSync();
      return;
    }

    if (!!form.getFieldValue("driver")) {
      tmpFormValues["driver"] = form.getFieldValue("driver");
      tmpFormValues["driverIdentified"] = "Yes";

      if (!!form.getFieldValue("licenseType")) {
        tmpFormValues["licenseType"] = form.getFieldValue("licenseType");
      }
    }

    if (tmpFormValues["driverIdentified"] === "Yes") setDriverRequired(true);
    else setDriverRequired(false);

    if (parseInt(data?.amount_due) === 0) {
      tmpFormValues["paymentStatus"] = "Yes";
    }

    if (!!form?.getFieldValue("vehicle")) {
      if (
        form?.getFieldValue("vehicle") !== tmpFormValues["vehicle"] &&
        window.location.pathname !== "/fleets/overview"
      ) {
        message.warning(
          <>
            <span>Careful! This violation was issued to another fleet: </span>

            <span style={{ color: "#1065A4", fontWeight: "500" }}>
              {tmpFleet?.fleetName}
            </span>
          </>
        );
        resetOnSync();
        return;
      }
    }

    fromClick && message.success("Violation Found!");

    if (!!tmpFormValues?.verify) {
      setVerifyUrl(tmpFormValues?.verify);
    }
    setSyncedSuccessfully(true);
    form.setFieldsValue({
      ...tmpFormValues,
    });
  }

  //spreads the default data into form fields
  useEffect(() => {
    if (!!defaultData && !!fleet) {
      populateData(defaultData);
    }
  }, [JSON.stringify(defaultData), fleet]);

  async function syncViolation(fromClick = false) {
    const config = {
      method: "get",
      url: `https://data.cityofnewyork.us/resource/nc67-uf89.json?summons_number=${form?.getFieldValue(
        "violation#"
      )}`,
    };

    await axios(config)
      .then(async (res) => {
        await filterTables(
          "fleetViolations",
          "summons_number",
          form?.getFieldValue("violation#")
        ).then((r) => {
          if (r?.length > 0) {
            message.warning(
              <>
                <span>This violation already exists in our records in: </span>
                <span style={{ color: "#1065A4", fontWeight: "500" }}>
                  {r[0]?.fleetName}
                </span>
                <span>, please check the corresponding record.</span>
              </>
            );
            resetOnSync();
            return;
          } else {
            populateData(res?.data?.[0], fromClick);
          }
        });
      })
      .catch((err) => {
        console.error("Error: ", err);
        message.error("Could not find matching violation!");
        resetOnSync();
      });
  }

  /**
   * Function that calculates the total amount
   * @returns The total violation amount
   */
  function calculateTotalAmount() {
    let newFineAmount =
      parseFloat(form.getFieldValue("liabilityAmount") || 0) +
      parseFloat(form.getFieldValue("interestAmount") || 0) +
      parseFloat(form.getFieldValue("penaltyAmount") || 0) -
      parseFloat(form.getFieldValue("reductionAmount") || 0);

    return newFineAmount;
  }

  /**
   * Function that changes the payment amount and the payment status on value changes
   */
  function changePayment(formItemName) {
    if (!!formItemName) {
      form.getFieldValue(formItemName) === "" &&
        form.setFieldValue(formItemName, 0);
      if (form.getFieldValue("violationStatus") === "Paid") {
        calculateTotalAmount() < 0 &&
          form.setFieldValue(
            "reductionAmount",
            parseInt(form.getFieldValue("reductionAmount")) +
              calculateTotalAmount()
          );
        form.setFieldValue("amountPaid", calculateTotalAmount());
      }
    }

    if (!form.getFieldValue("amountPaid")) {
      form.setFieldValue("paymentStatus", "No");
      return;
    }
    if (form.getFieldValue("amountPaid") > calculateTotalAmount()) {
      form.setFieldsValue({ paymentStatus: "No" });
    } else if (form.getFieldValue("amountPaid") < calculateTotalAmount()) {
      form.setFieldValue("paymentStatus", "No");
    } else {
      form.setFieldValue("paymentStatus", "Yes");
    }
  }

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "violationModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Violations Information": document.querySelector(".generalInformation"),
      "Sync Violation": document.querySelector(".syncIcon"),
      "Violation Amount": document.querySelector(".amount"),
      "Additional Information": document.querySelector(
        ".additionalInformation"
      ),
      "Verify Violation": document.querySelector(".linkBtn"),
      "Upload Violation": document.querySelector(
        ".googlePickerButtonContainer"
      ),
      Details: document.querySelector(".details"),
      "Save Violation": document.querySelector(".saveBtn"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  function onTeamSelect(_, data) {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((e) => e),
      },
    ]);
  }

  function onTeamDeselect(_, data) {
    setSelectedTeam((prev) => prev?.filter((el) => el?.value !== data.value));
    if (form.getFieldValue("violationTeam")?.length < 1) {
      setSelectedTeam([]);
    }
  }

  const ClearOptions = () => {
    setOpen(false);
  };

  const populatedInputFields = dynamicInputFields({
    syncedSuccessfully,
    syncViolation,
    form,
    isDarkMode,
    projects,
    setIsViolationStatusPaid,
    isViolationStatusPaid,
    calculateTotalAmount,
    drivers,
    driverName,
    driverId,
    setDriverRequired,
    driverRequired,
    fleet,
    fleetName,
    fleetId,
    setPdfModal,
    fleetNameFromPath,
    violationStatusOptions: !!violationStatus ? violationStatus : [],
    driverOptions: drivers.map(
      ({ driverName, driverLastName, driverId }, i) => ({
        label: !!driverLastName
          ? `${driverName} ${driverLastName}`
          : driverName,
        value: driverId,
      })
    ),
    fleetOptions: fleet?.map(({ fleetName, fleetId }) => ({
      label: fleetName,
      value: fleetId,
    })),
    agencyOptions: tempAgency.map((item) => ({
      label: item,
      value: item,
    })),
    projectOptions: projects?.map(({ projectName }) => ({
      label: projectName,
      value: projectName,
    })),
    receivedByOptions: users.map(({ nameOfUser }) => ({
      label: nameOfUser,
      value: nameOfUser,
    })),
    setSelectedTeam,
    dateFormat,
    timeFormat,
    verifyUrl,
    accessToken,
    onPickerSuccess,
    onFileDelete,
    driveFolderId,
    fileNames,
    routedDriverID,
    changePayment,
    currentPageKey,
    setCurrentPageKey,
    setLoadingRecords,
    setProjects,
    userConfiguration,
    projectSearchRef,
  });

  function filterFields() {
    let tmp =
      {
        "General Information": populatedInputFields["General Information"],
        "Violation Amount": populatedInputFields["Violation Amount"],
        "Additional Information":
          populatedInputFields["Additional Information"],
      } || {};
    Object.keys(tmp).forEach((key) => {
      let current = tmp[key];
      let filtered = sortFieldsObj?.[key];
      if (filtered) {
        current = current?.sort((a, b) => {
          const aIndex = filtered[a.formItemName];
          const bIndex = filtered[b.formItemName];
          return aIndex - bIndex;
        });
      }
    });
    return tmp;
  }
  const detailsFields = filterFields();

  function getRequiredFieldNames() {
    const additionalInformationValues = populatedInputFields[
      "Additional Information"
    ]
      ?.filter(
        ({ required, formItemName }) =>
          !!required &&
          formItemName !== "goTo" &&
          formItemName !== "uploadButton"
      )
      ?.map(({ formItemName }) => formItemName);
    const generalInformationValues = populatedInputFields["General Information"]
      ?.filter(
        ({ required, formItemName }) =>
          !!required && formItemName !== "syncViolation"
      )
      ?.map(({ formItemName }) => formItemName);
    const violationAmountValues = populatedInputFields["Violation Amount"]
      ?.filter(({ required }) => !!required)
      ?.map(({ formItemName }) => formItemName);
    const detailsValues = populatedInputFields["Details"]
      ?.filter(({ required }) => !!required)
      ?.map(({ formItemName }) => formItemName);

    const allFormValues = [
      ...additionalInformationValues,
      ...generalInformationValues,
      ...violationAmountValues,
      ...detailsValues,
    ].filter(Boolean);

    return allFormValues;
  }

  return (
    <>
      <NormalSizedModal
        visible={visible}
        title={
          <CustomModalHeader
            {...{
              title: "Violations",
              onClick: () => {
                setTourOpen(true);
              },
              style: { width: "95%" },
              headerInfo: () => {
                return (
                  userConfiguration.groupName === "Admin" && (
                    <Tooltip title="Sort Fields">
                      <TransferIconNew
                        {...{
                          className: "sortFieldsIcon",
                          onClick: () => {
                            setSortOpen(true);
                          },
                        }}
                      />
                    </Tooltip>
                  )
                );
              },
            }}
          />
        }
        className={`newViolationModalContainer ${
          isDarkMode && "newViolationModalContainerDark"
        }`}
        onCancel={() => {
          if (!formValuesChanged) {
            setVisible(false);
          } else {
            setCancelModalVisible(true);
          }
        }}
        newFooterDesign={footerButtons({
          onCancel: () => {
            if (!formValuesChanged && !defaultData) {
              setVisible(false);
            } else {
              setCancelModalVisible(true);
            }
          },
          onSave: () => validateFormFields(),
          onOptionClick: () => validateFormFields(false),
          saving,
          suffix: <DownOutlined />,
        })}
        centered
        closable={true}
        closeIcon={<XIcon style={{ marginRight: 10 }} />}
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormValuesChanged(true);
          }}
        >
          <div className="inputContainer">
            <BorderedTextCard
              className="generalInformation"
              title={"General Information"}
              isDarkMode={isDarkMode}
            >
              {RenderDynamicComponents(detailsFields["General Information"], {
                form,
              })}

              {getAccessRights("Teams")?.write ? (
                <InputComponent
                  {...{
                    required: true,
                    allowClear: false,
                    label: "Team",
                    formItemName: "violationTeam",
                    placeholder: "Select team...",
                    mode: "multiple",
                    type: "select",
                    dropdownClassName: isDarkMode && "darkDropDown",
                    showSearch: true,
                    customOptions: allTeams?.map((team) => ({
                      label: team.teamName,
                      value: team.teamName,
                      members: team.members?.map(
                        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
                          identityId,
                          nameOfUser,
                          cognitoUserId,
                        })
                      ),
                    })),
                    onSelect: onTeamSelect,
                    onDeselect: onTeamDeselect,
                  }}
                />
              ) : (
                <></>
              )}
              {!!selectedTeam?.length && getAccessRights("Teams")?.write ? (
                <MondayButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  Icon={<AddIcon />}
                >
                  Members
                </MondayButton>
              ) : (
                <></>
              )}
              {getAccessRights("Teams") ? (
                <DynamicAvatars
                  title={"Violations Team"}
                  isDarkMode={isDarkMode}
                  members={selectedTeam.reduce(
                    (acc, { members }) => [...acc, ...members],
                    []
                  )}
                />
              ) : (
                <></>
              )}
            </BorderedTextCard>
            <div className="amount-information-container">
              <BorderedTextCard
                className="amount"
                title={"Violation Amount"}
                isDarkMode={isDarkMode}
              >
                {RenderDynamicComponents(detailsFields["Violation Amount"], {
                  form,
                })}
              </BorderedTextCard>
              <BorderedTextCard
                title={"Additional Information"}
                className="additionalInformation"
                isDarkMode={isDarkMode}
              >
                {RenderDynamicComponents(
                  detailsFields["Additional Information"],
                  { form }
                )}
              </BorderedTextCard>
            </div>
            <BorderedTextCard
              className={"details"}
              title={"Details"}
              isDarkMode={isDarkMode}
            >
              {RenderDynamicComponents(populatedInputFields["Details"], {
                form,
              })}
            </BorderedTextCard>
          </div>
        </Form>
      </NormalSizedModal>
      {pdfModal && (
        <Modal
          {...{
            open: pdfModal,
            centered: true,
            onCancel() {
              setPdfModal();
            },
            title: "Violation document",
            footer: null,
            className: `document-modal-container${
              isDarkMode ? " document-dark" : ""
            }`,
            closeIcon: <XIcon />,
          }}
        >
          <iframe
            src={verifyUrl}
            type="application/pdf"
            height={"100%"}
            width={"100%"}
          />
        </Modal>
      )}
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<Tick />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {openWarningModal && (
        <WarningModal
          visible={openWarningModal}
          setVisible={setOpenWarningModal}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <div style={{ fontSize: 15, marginBottom: 10 }}>
              There is an existing violation with the same <b>number</b> and{" "}
              <b>name</b>
            </div>
            <div style={{ fontWeight: 700, fontSize: 15, marginBottom: 20 }}>
              Are you sure you want to continue?
            </div>
            <div className="buttons">
              <MondayButton
                onClick={() => setOpenWarningModal(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  onSave();
                  setOpenWarningModal(false);
                  form.resetFields();
                  setVisible(false);
                }}
                Icon={<Tick />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            setSelectedTeam,
            ClearOptions,
            isDarkMode,
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Violation Tutorial",
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Violation",
            rowData: detailsFields,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "violationModal",
            options: Object.keys(detailsFields).map((key) => ({
              label: key,
              value: key,
            })),
            currentSort: sortFieldsObj,
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Fleets Violations",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              const { redirect, violationId } = visibleCreationProgress || {};

              !!redirect &&
                !!violationId &&
                !!!isNextStep &&
                navigate(`/fleetViolations/${violationId}`);

              setVisible(false);
            },
          }}
        />
      )}
    </>
  );
};

export default NewViolationModal;
