import { Button, DatePicker, Form, message, Modal, Popover } from "antd";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { SaveIcon } from "../../../../../../../../../../BasePage/src";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../Context/Context";
import { getNYDate } from "../../../../../../utils/dateFunctions";
import "./ApplicationDateAdjust.scss";
import moment from "moment";
function ApplicationDateAdjust() {
  const { isWritable, isAutoSaving, fakeApplication, darkMode } =
    useStateContext();

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatchContext = useDispatchContext();

  if (!fakeApplication) return <></>;
  const createdAtChanger = ({
    fakeApplication,
    dispatchContext,
    applicationDateXlsx,
  }) => {
    const newApplication = {
      ...fakeApplication,
      applicationDateXlsx,
    };
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
    message.success("Application date changed successfully");
    form.resetFields();
    setVisible(false);
  };

  const submitHandler = (values) => {
    console.log("ccccccccccc", values);
    createdAtChanger({
      fakeApplication,
      dispatchContext,

      applicationDateXlsx: values?.applicationDate
        // .add(10, "hour")
        .valueOf(),
    });
  };

  // console.log("ccccccccccccccccc", {
  //   timestamp: dayjs(fakeApplication?.applicationDateXlsx),
  //   nydate: getNYDate(fakeApplication?.applicationDateXlsx).formatted,
  //   dayjs: dayjs(
  //     getNYDate(fakeApplication?.applicationDateXlsx).formatted
  //   ).format("YYYY-MM-DD"),
  // });

  return (
    <>
      <div
        onClick={() => {
          if (isWritable) setVisible(true);
          else
            message.error(
              "You need Write access to change Application Date",
              2
            );
        }}
        className="application-created-at-wrapper"
      >
        {" "}
        <Popover
          content={
            <span>
              Click on the date to change <strong>Application Date</strong>
            </span>
          }
          title={`This record was created on: ${
            getNYDate(fakeApplication?.createdAt).formatted
          }.`}
        >
          <span style={{ color: "#F3C178", cursor: "pointer" }}>
            {fakeApplication?.applicationDateXlsx
              ? getNYDate(fakeApplication?.applicationDateXlsx).formatted
              : "Please pick a date"}
          </span>
        </Popover>
      </div>{" "}
      {visible && !!fakeApplication && (
        <Modal
          wrapClassName={
            darkMode
              ? "applicationDateAdjustDark"
              : "applicationDateAdjustLight"
          }
          title={"Change Created Application Date"}
          open={visible}
          onCancel={() => setVisible(false)}
          footer={null}
          width={500}
          centered
        >
          {" "}
          <Form onFinish={(res) => submitHandler(res)}>
            <div className="application-date-adjust">
              <Form.Item
                label={"Select Date"}
                name="applicationDate"
                rules={[
                  {
                    required: true,
                    message: "Please select a date",
                  },
                ]}
              >
                <DatePicker
                  defaultValue={
                    fakeApplication?.applicationDateXlsx
                      ? dayjs.tz(fakeApplication?.applicationDateXlsx)
                      : dayjs.tz(fakeApplication?.createdAt)
                  }
                  format={"MM/DD/YYYY"}
                />
              </Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "30px",
                  marginBottom: "-30px",
                }}
              >
                <Form.Item>
                  <MondayButton
                    type="primary"
                    htmlType="submit"
                    Icon={<SaveIcon />}
                  >
                    Save Changes
                  </MondayButton>
                </Form.Item>{" "}
              </div>
            </div>{" "}
          </Form>
        </Modal>
      )}{" "}
    </>
  );
}

export default ApplicationDateAdjust;
