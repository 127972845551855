import "./PortalGeofences.scss";

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { API } from "aws-amplify";
import { Tag, message } from "antd";
import { MultiLevelTreeLogs } from "../../../../../commonComponents";

import { AgGridReact } from "ag-grid-react";
import { getColumnDefs, excelColumnsConfig } from "./utils/getColumnDefs";

import AgGridPageInput from "src/components/SidebarPages/BasePage/components/AgGridPageInput/AgGridPageInput";

import { FilterOutlined } from "@ant-design/icons";

import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";

import FilterModal from "./components/FilterModal";
import ShowDetailsCreationModal from "./components/ShowDetailsCreationModal";
import Header from "./components/Header";

import { fetchAllData, gridSidebar } from "../../../../../../utils";
import { SmallLoadableComp } from "../../../../../Sidebars/components";
import { curr, prev } from "./utils/logsFunctions";
import { NoAccessPage } from "./components/NoAccessPage";
import { categoriesWithGeofence } from "./utils/categoriesWithGeofence";
import { useEditLogs } from "../../../../../../hooks";
import {
  createMessageElement,
  createNewElement,
  movePaginationInput,
} from "./utilsForPagination";

const TIME_FORMAT = "hh:mm a";
const DATE_FORMAT = "MM/DD/YYYY";

export const defaultColDef = {
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
  editable: false,
  sortable: true,
  flex: 1,
  filter: true,
  suppressSizeToFit: true,
};

const PortalGeofences = ({
  getNonGeofences,
  getGeofences,
  allDataFromResponse,
  setGetNonGeofences,
  setGetGeofences,
  rowData,
  switchData,
  setSwitchData,
  loadingWithStatusMessage,
  setPaginationChanged,
  haveOtherData,
}) => {
  const hostOrigin = window.location.origin;
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [openModal, setOpenModal] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [logs, setLogs] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [fieldSelected, setFieldSelected] = useState();
  const [searchText, setSearchText] = useState("");

  const [geoFenceInfo, setGeoFenceInfo] = useState([]);
  const [unsavedLogs, setUnsavedLogs] = useState([]);
  const { saveAddedLogs } = useEditLogs();

  const onGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: AgGridPageInput,
        key: "paginationInput",
        align: "right",
      },
    ],
  };

  const modalHandler = (data) => {
    setOpenModal(data);
  };

  const retrieveLogs = async (id) => {
    message.loading("Showing logs...");

    const filters = [
      {
        conditions: [
          {
            operator: "AND",
            column: "recordId",
            value: id,
            formula: "contains",
          },
        ],
      },
    ];

    await fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        ...(filters && { filters: JSON.stringify(filters) }),
      },
    })
      .then((res) => {
        const filteredArray = res
          .filter(({ label }) => label === "Geofence")
          .flatMap((el) => {
            // Differentiates between logs saved with the correct spelling
            if (el?.hasOwnProperty("previusData"))
              tmpIdentifier = "previusData";

            const {
              member,
              updatedAt,
              label,
              previousData,
              currentData,
              updatedKeys,
              actionType,
              nameOfUser,
              recordId,
              topic,
              category,
            } = el;

            message.destroy();
            message.success("Logs retrieved successfully!");
            setLogs(true);

            // If updatedKeys is not provided, the log was not returned
            return !!updatedKeys?.length
              ? (Array.isArray(updatedKeys) ? updatedKeys : []).map((key) => {
                  const { details = "" } = key || {};
                  return {
                    // ...destructuredData,
                    member,
                    updatedAt: dayjsNY(updatedAt).format(
                      `${DATE_FORMAT} ${TIME_FORMAT}`
                    ),
                    details: !previousData ? "Creation" : details,
                    label,
                    previousData,
                    currentData,
                    actionType,
                    nameOfUser,
                    recordId,
                    topic,
                    category,
                  };
                })
              : [];
          });

        if (filteredArray.length === 0) {
          message.destroy();
          message.info(
            "This record doesn't contains previous and updated values and can't be opened",
            5
          );
        }

        setLogsData(filteredArray);
      })
      .catch((error) => {
        console.error("Error fetching logs: ", error);
      });
  };

  const handleSave = () => {
    const updatedNonContent = getNonGeofences.map((content) => {
      if (content.recordId === openModal.recordId) {
        return {
          ...content,
          geoFenceInfo: geoFenceInfo,
          geofencesQuantity: geoFenceInfo.length,
        };
      }
      return content;
    });

    const updatedContent = getGeofences.map((content) => {
      if (content.recordId === openModal.recordId) {
        return {
          ...content,
          geoFenceInfo: geoFenceInfo,
          geofencesQuantity: geoFenceInfo.length,
        };
      }
      return content;
    });

    const apiName = openModal.isDrawedIn.toLowerCase();
    const recordId = openModal.recordId;

    API.put(apiName, `/${apiName}/${recordId}`, {
      body: {
        geoFenceInfo: geoFenceInfo,
      },
    })
      .then(() => {
        message.success("GeoFence saved successfully");
        setGetNonGeofences(updatedNonContent);
        setGetGeofences(updatedContent);
        setOpenModal(false);
      })
      .catch(() => {
        message.error("There was a problem Saving Data");
      });

    if (unsavedLogs.length !== 0) {
      unsavedLogs.map((geo) => {
        return postLogs(
          prev(geo),
          curr(geo, geo.prevGeoFenceInfo),
          "Geofence",
          geo.actionType
          // {
          //   onAction: dynamicOnAction(geo.actionType),
          //   commonNext: geo?.title,
          // }
        );
      });
    }
  };

  function closeResetFiels() {
    setShowFilter(false);
    setFilters({});
    setGetGeofences(rowData);
    setGetNonGeofences(rowData);
  }

  const onFilterTextChange = (e) => {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setSearchText(e);
  };

  const dynamicData = useMemo(() => {
    return !switchData
      ? allDataFromResponse["withGeofence"]
      : allDataFromResponse["nonGeofence"];
  }, [handleFilterFinish]);

  function handleFilterFinish({ copyOfFilters = false }) {
    const { byCategory, byQuantity, byNotes } = copyOfFilters
      ? copyOfFilters
      : filters;

    const dynamicQuantity = (a, b) =>
      byQuantity
        ? byQuantity === "Higher in fence quantity"
          ? b.geoFenceInfo.length - a.geoFenceInfo.length
          : a.geoFenceInfo.length - b.geoFenceInfo.length
        : byNotes
        ? byNotes === "Higher in Notes Quantity"
          ? b.notesQuantity.length - a.notesQuantity.length
          : a.notesQuantity.length - b.notesQuantity.length
        : "";

    const filteredData = dynamicData
      .filter(({ isDrawedIn }) =>
        byCategory && byCategory.length > 0
          ? byCategory.includes(isDrawedIn)
          : true
      )
      .sort(dynamicQuantity);

    if (!switchData) {
      setGetGeofences(filteredData);
    } else {
      setGetNonGeofences(filteredData);
    }
    setShowFilter(false);
  }

  function postLogs(prev, curr, label, actionType = "Edit") {
    let logObject = {
      recordId: openModal?.recordId,
      recordName: openModal?.recordAddress,
      category: openModal?.isDrawedIn,
      currentData: curr,
      actionType,
      topic: hostOrigin,
      label: label,
      previousData: prev,
      updatedKeys: [{ details: "See Details" }],
    };
    saveAddedLogs(logObject).then(() => setUnsavedLogs([]));
  }

  const updatedAccess = Array.isArray(loadingWithStatusMessage.message)
    ? loadingWithStatusMessage?.message?.filter((el) => el !== false)
    : "";

  const haveAccess = Array.isArray(updatedAccess)
    ? updatedAccess.length !== 0
    : true;

  const handlePagination = (totalPages, currentPage) => {
    const customButtonId = "customNextButton";
    const messageId = "fetchedAllDataMessage";

    let existingCustomButton = document.getElementById(customButtonId);
    if (existingCustomButton) {
      existingCustomButton.remove();
    }

    let existingMessage = document.getElementById(messageId);
    if (existingMessage) {
      existingMessage.remove();
    }

    if (totalPages - 1 === currentPage) {
      let nextButton = document?.querySelector(
        '[aria-label="Next Page"][class="ag-paging-button ag-disabled"]'
      );

      if (nextButton) {
        if (haveOtherData) {
          let newItem = createNewElement();
          newItem.addEventListener("click", () => {
            setPaginationChanged((prev) => prev + 1);
          });
          nextButton.parentElement.appendChild(newItem);
          movePaginationInput();
        } else {
          let messageItem = createMessageElement();
          nextButton.parentElement.appendChild(messageItem);
        }
      }
    }
  };

  return haveAccess ? (
    <div
      className={`recordsWithGeofencesCard ${
        isDarkMode && "recordsWithGeofencesCardDark"
      }`}
    >
      {/* <button
        onClick={() => {
          if (haveOtherData) {
            setPaginationChanged((prev) => prev + 1);
          } else {
            setPaginationChanged(0);
          }
        }}
      >
        Get data
      </button> */}

      <div className="recordsWithGeofencesHeader">
        <Header
          {...{
            gridColumnApi,
            gridApi,
            searchText,
            setFieldSelected,
            onFilterTextChange,
            getColumnDefs,
            modalHandler,
            switchData,
            getNonGeofences,
            rowData,
            setSwitchData,
            setFilters,
            setShowFilter,
            getGeofences,
          }}
        />
        {showFilter && (
          <FilterModal
            {...{
              showFilter,
              closeResetFiels,
              handleFilterFinish,
              setFilters,
              filters,
              updatedAccess,
            }}
          />
        )}
      </div>

      <div
        style={{ position: "relative" }}
        className={` ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        {Object.values(filters).length !== 0 && !showFilter && (
          <div style={{ padding: "5px 30px 5px" }}>
            {Object.entries(filters)
              .filter(([key, value]) => value !== undefined)
              .map(([key, value]) => (
                <React.Fragment key={key}>
                  {Array.isArray(value) ? (
                    value.map((item, index) => (
                      <Tag
                        icon={<FilterOutlined />}
                        key={`${key}_${index}`}
                        color="blue"
                      >
                        {item}
                      </Tag>
                    ))
                  ) : (
                    <Tag icon={<FilterOutlined />} key={key} color="blue">
                      {value}
                    </Tag>
                  )}
                </React.Fragment>
              ))}
          </div>
        )}

        <SmallLoadableComp loading={loadingWithStatusMessage.loading} />
        <AgGridReact
          {...{
            rowData: rowData?.filter(
              ({ recordAddress, isDrawedIn }) =>
                recordAddress?.toLowerCase() || isDrawedIn?.toLowerCase()
            ),
            columnDefs: getColumnDefs({
              modalHandler,
              retrieveLogs,
            }),
            defaultColDef: defaultColDef,
            pagination: true,
            paginationPageSize: 15,
            cacheBlockSize: 15,
            onGridReady: onGridReady,
            statusBar,
            defaultExcelExportParams: {
              columnKeys: excelColumnsConfig,
              fileName: "Geofence",
            },
            sideBar: gridSidebar,
            onPaginationChanged: (e) => {
              const { totalPages, currentPage } = e.api.paginationProxy;
              handlePagination(totalPages, currentPage);
            },
          }}
        />
        {logs && (
          <MultiLevelTreeLogs
            {...{
              visible: logs,
              setVisible: setLogs,
              logsData: logsData || [],
              title: "Displaying Logs Related to Geofence Actions",
              isCostumed: true,
            }}
          />
        )}
        {openModal && (
          <ShowDetailsCreationModal
            {...{
              openModal,
              setOpenModal,
              handleSave,
              setGeoFenceInfo,
              isDarkMode,
              setUnsavedLogs,
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <NoAccessPage
      description={`This page shows data for portal geofences which uses ${categoriesWithGeofence
        .map(({ categoryName }) => categoryName)
        .join(
          ", "
        )}. Please check access in these categories, or contact software team!`}
      title="You don't have access to view this page"
    />
  );
};

export default PortalGeofences;
