export const DATE_KEY = {
  "Property Damage": "dateOfPropertyDamage",
  "Personal Injury": "dateOfInjury",
  "Other Trade Incident": "dateOfIncident",
};

export const ADDRESS_FIELD = {
  "Property Damage": "addressOfDamagedProperty",
  "Personal Injury": "addressOfInjury",
  "Other Trade Incident": "incidentAddress",
};

export const DRIVE_FOLDER_KEY = {
  "Property Damage": "addressOfDamagedProperty",
  "Personal Injury": "fullNameOfInjuredIndividual",
  "Other Trade Incident": "fullNameOfInjuredIndividual",
};

export const NAME_KEY = {
  "Property Damage": "addressOfDamagedProperty",
  "Personal Injury": "fullNameOfInjuredIndividual",
  "Other Trade Incident": "fullNameOfInjuredIndividual",
};

export const EDIT_ACTION = {
  "Property Damage": "onPropertyDamageEdit",
  "Personal Injury": "onPersonalInjuryEdit",
  "Other Trade Incident": "onOtherTradeIncidentEdit",
};

export const CREATE_ACTION = {
  "Property Damage": "onPropertyDamageCreation",
  "Personal Injury": "onPersonalInjuryCreation",
  "Other Trade Incident": "onOtherTradeIncidentCreation",
};

export const DELETE_ACTION = {
  "Property Damage": "onPropertyDamageDeletion",
  "Personal Injury": "onPersonalInjuryDeletion",
  "Other Trade Incident": "onOtherTradeIncidentDeletion",
};

export const SAFETY_BODY_CAT = {
  "Property Damage": "Property Damage Incident",
  "Personal Injury": "Personal Injury Incident",
  "Other Trade Incident": "Other Incident",
};
