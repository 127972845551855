/**
 * Removes search parameters from the URL.
 */
function removeSearchParams() {
  let tabPosition = "";
  if (
    window.location.search.includes("todosOpen") ||
    window.location.search.includes("emailHref")
  ) {
    if (window.location.search.includes("tab")) {
      tabPosition =
        window.location.search.split("&")[0] +
        window.location.search.split("&")[1];
    }
    window.history.replaceState(
      {},
      _,
      `${document.location.pathname}${tabPosition}`
    );
  }
}

export default removeSearchParams;
