import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Modal } from "antd";

import { useDispatchProgramFields } from "src/hooks";
import { AUDIT_FIELD_NAME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { MondayButton } from "src/components/commonComponents";
import { SheetsFieldType } from "../../../../../../SidebarPages/FleetMaintenanceView/types";
import { TickIcon } from "../../../Roles/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "./VehicleSheetsConfig.scss";

function VehicleSheetsConfig({ open, onCancel, onNewConfig = () => {} }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);

  const [form] = Form.useForm();

  /** @type {SheetsFieldType|undefined} */
  const sheetsConfig = useMemo(() => {
    if (!programFields) {
      return undefined;
    }

    return programFields?.find(
      ({ fieldName }) => fieldName === AUDIT_FIELD_NAME
    );
  }, [programFields]);

  useEffect(() => {
    if (!sheetsConfig) {
      return;
    }

    const key = process.env.NODE_ENV === "production" ? "prod" : "dev";

    form.setFieldsValue({
      defaultVehicleSheets: sheetsConfig.fieldOptions?.[key]?.defaultSheetId,
      lastRowId: sheetsConfig.fieldOptions?.[key]?.lastRowConfig?.sheetId,
      lastRowName: sheetsConfig.fieldOptions?.[key]?.lastRowConfig?.sheetName,
    });
  }, []);

  const { dispatchFields } = useDispatchProgramFields(
    sheetsConfig?.fieldId,
    sheetsConfig?.fieldName
  );

  async function onConfirm() {
    try {
      var { defaultVehicleSheets, lastRowId, lastRowName } =
        await form.validateFields();
    } catch {
      return;
    }

    try {
      const key = process.env.NODE_ENV === "production" ? "prod" : "dev";

      await dispatchFields({
        ...(sheetsConfig?.fieldOptions || {}),
        [key]: {
          ...(sheetsConfig?.fieldOptions?.[key] || {}),
          defaultSheetId: defaultVehicleSheets,
          lastRowConfig: {
            sheetName: lastRowName,
            sheetId: lastRowId,
          },
        },
      });

      setTimeout(() => {
        onNewConfig();
      }, 1000);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      onCancel();
    }
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        title: "Vehicle Sheets Configuration",
        centered: true,
        className: `vehicle-sheets-configuration ${
          isDarkMode ? "vehicle-sheets-config-dark" : ""
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
              key: "cancel",
            }}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onConfirm,
              key: "confirm",
            }}
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <Form form={form}>
        <InputComponent
          {...{
            label: "Default Vehicle Sheet Ids",
            formItemName: "defaultVehicleSheets",
            required: true,
            onBlur() {
              if (form.getFieldValue("lastRowId")) {
                return;
              }
              form.setFieldValue(
                "lastRowId",
                form.getFieldValue("defaultVehicleSheets")
              );
            },
          }}
        />
        <InputComponent
          {...{
            label: "Last Row Sheet Id",
            formItemName: "lastRowId",
            required: true,
          }}
        />
        <InputComponent
          {...{
            label: "Last Row Sheet Name",
            formItemName: "lastRowName",
            required: true,
          }}
        />
      </Form>
    </Modal>
  );
}

export default VehicleSheetsConfig;
