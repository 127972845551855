import { PRINT_PAGE_CONFIGS } from "../../../data";

/**
 * @typedef {keyof typeof PRINT_PAGE_CONFIGS} PageSize
 */

/**
 * @typedef TextType
 * @property {string} text
 * @property {Boolean} [header]
 * @property {Boolean} [bold]
 * @property {string} [class]
 * @property {string} [link]
 * @property {string|number} [colSpan] - Used in tables
 * @property {string|number} [rowSpan] - Used in tables
 */

/**
 * @typedef ImageType
 * @property {string} src
 * @property {string|number} [height]
 * @property {string|number} [width]
 * @property {string} [link]
 * @property {string} [class]
 * @property {number} [index=1]
 */

/**
 * @typedef ListType
 * @property {object[]} items
 * @property {string} [class]
 * @property {number} [index=1]
 * @property {string} [type="ul"] - ul or ol
 * @property {string} [listClass] - class for the list
 * @property {string} [itemClass] - class for the list items
 * /

/**
 * @typedef {string|TextType|ImageType} RowDataType
 */

/**
 * @typedef TableType
 * @property {string[]|TextType[]} cols
 * @property {Array<RowDataType[]>} rows
 * @property {string} [class]
 * @property {number} [index=2]
 */

/**
 * @typedef ParagraphType
 * @property {RowDataType[]} paragraphs
 * @property {number} [index=0]
 */

/**
 * @example
 * dataSections: [
 *   {
 *     text: {
 *       paragraphs: [
 *         {
 *           text: "This is a test text",
 *           bold: true,
 *         },
 *         "<p>Custom HTML</p>",
 *         {
 *           text: "This is a link to YouTube",
 *           link: "https://www.youtube.com",
 *         },
 *         "Just a simple string",
 *       ],
 *     },
 *     image: {
 *       src: "<base64>",
 *       class: "stretch",
 *     },
 *     table: {
 *       class: "ta",
 *       cols: ["Header 1", "Header 2"],
 *       rows: [
 *         ["Text 1", { text: "Text 2", bold: true }],
 *         [{ text: "Text 3", bold: true, class: "red" }, "Text 4"],
 *       ],
 *     },
 *   },
 * ],
 * styles: {
 *   stretch: {
 *     width: "40%",
 *     "aspect-ratio": "1",
 *   },
 *   red: {
 *     "background-color": "red",
 *   },
 *   "data-section": {
 *     display: "flex",
 *     "justify-content": "flex-start",
 *     "align-items": "flex-start",
 *     "flex-direction": "column",
 *     gap: "1rem",
 *   },
 * },
 */

/**
 * @template T
 * @typedef {Record<string, T>} Dictionary
 */

/**
 * @typedef {Dictionary<Dictionary<string>>} StylesType
 */

/**
 * @typedef DataSection
 * @property {ParagraphType} [text]
 * @property {TableType} [table]
 * @property {ImageType} [image]
 * @property {string} [class]
 */

/**
 * Function that generates the body of a HTML document
 * in order for it to be exported
 * @param {Object} config
 * @param {DataSection[]} config.dataSections The data to map to the report
 * @param {StylesType} config.styles CSS rule list {class: {...properties}}
 * @param {string} [config.title=16] The document data
 * @param {number|string} [config.sectionSpacing=16]
 * @param {boolean} [config.landscape=false]
 * @param {PageSize} [config.pageSize]
 * @param {Dictionary<StylesType>} [config.queries]
 * @returns The HTML string representation
 */
function generateHTMLTemplate({
  dataSections,
  styles,
  title = "New Document",
  sectionSpacing = 16,
  landscape = false,
  pageSize = "A4",
  queries = {},
}) {
  /**
   * The idea is that the body of the document will be mapped in
   * sections in a column order. A section is just a div element with
   * 100% width, all the elements will be rendered inside these sections.
   * The document body has a class of "main-document-body" and the sections
   * have a default class of "data-section", this makes it easier to style
   * through the "styles" parameter. The "styles" needs to be an object
   * where the key is the class and the value will be directly appended to
   * the style tag with the format "property": "value";
   */
  let document = `<head>
          <meta charset="UTF-8" content="text/html" />
                <title>${title}</title>
                <style type="text/css">
                * { 
                  margin: 0; 
                  padding: 0;  
                } 
                
                .main-document-body {
                  height: fit-content; 
                  min-height: 297mm;
                  width: 210mm; 
                  display: flex; 
                  justify-content: flex-start; 
                  align-items: flex-start; 
                  flex-direction: column;
                  background-color: "#fff";
                  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
                  color: #323338;
                }
                
                .data-section {
                  background-color: "#fff";
                  height: fit-content;
                  width: 100%;
                  margin-bottom: ${getGap(sectionSpacing)};
                }

                table, th, td {
                  border: 1px solid;
                }
                
                table {
                  border-collapse: collapse;
                  width: calc(100% - 2px);
                }

                @media print{
                  .table {
                    font-size: 1rem !important;
                  }
                  .table > tbody > tr > td {
                    padding: 10px 5px !important;
                  }
                  .table > tbody > tr > th {
                    padding: 10px 5px !important;
                  }
                }
                `;

  for (const className in styles) {
    document = document + `\n.${className} {\n`;
    for (const style in styles[className]) {
      document = document + `${style}: ${styles[className][style]};\n`;
    }
    document = document + `}\n`;
  }

  for (const query in queries) {
    document = document + `\n${query} {`;

    for (const className in queries[query]) {
      document = document + `\n.${className} {\n`;
      for (const style in queries[query][className]) {
        document =
          document + `${style}: ${queries[query][className][style]};\n`;
      }
      document = document + `}\n`;
    }

    document = document + `}\n`;
  }

  document =
    document +
    `\n@page {
      size: ${pageSize} ${landscape ? "landscape" : "portrait"};
    }\n`;

  document = document + `</style>\n</head>\n<body class="main-document-body">`;

  for (const section of dataSections) {
    document = document + mapDataSection({ ...section });
  }

  document = document + `\n</body>`;

  return document;
}

/**
 * Function that maps different data types in the template
 * @param {DataSection} config
 * @returns The document body as per the configurations
 */
function mapDataSection(config) {
  /**
   * The idea is to make the process of creating a PDF simpler and more
   * customizable through the data mapping and the configurations for each element
   */
  let res = `<div class="data-section ${config?.class || ""}">`;
  let sectionMapOrder = ["text", "image", "table", "list"].filter((e) =>
    config.hasOwnProperty(e)
  );

  /**
   * To put the sections in a specific order, every item needs to be indexed
   */
  if (
    ["text", "image", "table", "list"].every(
      (e) => !isNaN(+config?.[e]?.["index"])
    )
  ) {
    sectionMapOrder = Object.keys(config).sort(
      (a, b) => config[a]["index"] - config[b]["index"]
    );
  }

  for (const sectionOrder of sectionMapOrder) {
    switch (sectionOrder) {
      case "text":
        res = res + processText(config.text.paragraphs);
        break;
      case "image":
        res = res + processImg(config.image);
        break;
      case "table":
        res = res + generateTable(config.table);
        break;
      case "list":
        res = res + processList(config.list);
    }
  }

  res = res + `</div>`;

  return res;
}

/**
 * Processes the text passed in the config
 * @param {RowDataType[]} rows
 * @param {string} [customTag]
 */
function processText(rows, customTag) {
  let r = ``;
  let tt = [rows].flat();

  for (const row of tt) {
    if (typeof row === "string" || typeof row === "number") {
      if (customTag) {
        r = r + `<${customTag}>${row}</${customTag}>`;
      } else {
        if (/<[^>]+>/g.test(row)) {
          r = r + `${row}`;
        } else {
          r = r + `<span>${row}</span>`;
        }
      }
    } else if (row?.hasOwnProperty("src")) {
      r = r + processImg(row);
    } else if (row?.hasOwnProperty("text")) {
      r = r + processTextObject(row, customTag);
    }
  }

  return r;
}

/**
 * Function that processes the text object
 * @param {TextType} text
 * @param {string} [customTag]
 */
function processTextObject(text, customTag) {
  return `<${
    customTag
      ? customTag
      : text?.link
      ? "a"
      : text?.header
      ? "h1"
      : text?.bold
      ? "b"
      : "span"
  }${text?.class ? ` class="${text.class}"` : ""}${
    text?.link ? ` href="${text?.link}"` : ""
  }${text?.bold ? ` style="font-weight: 600;"` : ""}${
    text?.link ? ` target="_blank"` : ""
  }${text?.colSpan ? ` colspan="${text.colSpan}"` : ""}${
    text?.rowSpan ? `rowspan="${text.rowSpan}"` : ""
  } >${text.text}</${
    customTag
      ? customTag
      : text?.link
      ? "a"
      : text?.header
      ? "h1"
      : text?.bold
      ? "b"
      : "span"
  }>`;
}

/**
 * Processes and img
 * @param {ImageType} img
 */
function processImg(img) {
  return `<img alt="img" class="${img?.class || ""}" href="${
    img?.link || ""
  }" src="${img.src}" height="${img?.height || ""}" width="${
    img?.width || ""
  }" />`;
}

/**
 * Processes list
 * @param {ListType} list
 */
function processList(list) {
  let l = `<${list?.type || "ul"} class="${list?.listClass || ""}">`;
  for (const item of list.items) {
    if (item?.customRender) {
      l = l + item;
    } else {
      l = l + `<li class="${list?.itemClass || ""}">${item}</li>`;
    }
  }
  l = l + `</${list.type || "ul"}>`;
  return l;
}

/**
 * Generates an HTML table
 * @param {TableType} table
 */
function generateTable(table) {
  let t = `<table class="${table?.class || ""}">`;
  if (table.cols.length) {
    t = t + `<tr>`;
    for (const field of table.cols) {
      t = t + processText(field, "th");
    }

    t = t + `</tr>`;
  }

  for (const row of table.rows) {
    t = t + `<tr>`;
    for (const rowData of row) {
      t = t + `<td>`;
      t = t + processText(rowData);
      t = t + `</td>`;
    }
    t = t + `</tr>`;
  }

  t = t + `</table>`;
  return t;
}

/**
 * @param {number|string} spacing
 */
function getGap(spacing) {
  if (!isNaN(+spacing)) {
    return `${spacing}px`;
  }

  return spacing;
}

export default generateHTMLTemplate;
