import { useState } from "react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EyeFilled, HistoryOutlined } from "@ant-design/icons";
import {
  editUserStepData,
  filterRoutes,
  updateGoogleDrivePermissions,
} from "../utils";
import {
  fetchAllData,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../utils";
import CloneUserAccess from "./CloneUserAccess";
import { TickIcon } from "../../../../../Roles/src";
import { TourGuide } from "../../../../../Roles/common";
import {
  ExportOrEmailDropdown,
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../../../../commonComponents";
import { updateUserKeysUser } from "../../../../../../../../../actions";
import { ReactComponent as Back } from "../../../../../Roles/src/back.svg";
import { CopyIconWhite } from "../../../../../../../../SidebarPages/DynamicView/src";
import RoleWarningModal from "../../../../../Roles/components/UserRoles/components/RoleWarningModal";
import PreviewAccessModal from "../../../../../Roles/components/UserRoles/components/PreviewAccessModal/PreviewAccessModal";
import { removeDescription } from "../utils/filterRoutes";
import {
  onGeneratePDF,
  exportToExcel,
  uploadExcelToDrive,
} from "../utils/exportPdfExcel";
import PdfHeadersModal from "../../../../../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { tableHeaders } from "../../../constants/tableHeaders";
import useExportHeadersModal from "../../../../../../../../../hooks/useExportHeadersModal";
import { isEmpty } from "lodash";
import { useEditLogs } from "../../../../../../../../../hooks";

const UserFooter = ({
  form,
  currentUser,
  foldersAccess,
  allFoldersAccess,
  superAdminRoleData,
  accessConfiguration,
  unchangedData,
  setUnchangedData,
}) => {
  const [personalEditLogs, setPersonalEditLogs] = useState([]);
  const [openCloneAccess, setOpenCloneAccess] = useState(false);
  const [logsModalVisible, setLogsModalVisible] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [previewAccessVisible, setPreviewAccessVisible] = useState(false);

  const { base64 } = useSelector((state) => state.base64);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const { departmentName, identityId } = useParams();

  const { Route, Sidebar } = accessConfiguration;

  const previewAccessData = { Route, Sidebar };

  let logs = {
    actionType: "Edit",
    category: `User Access ${currentUser.nameOfUser}`,
    recordId: identityId,
    currentData: {
      route: {},
      sidebar: {},
      "Drive Access": {},
    },
    previousData: {
      route: {},
      sidebar: {},
      "Drive Access": {},
    },
    recordName: "",
  };

  const fetchPersonalEditLogs = async () => {
    showLoadingMsg({ content: "Fetching Logs. Please wait..." });
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: identityId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((res) => {
        setPersonalEditLogs(res);
        showSuccessMsg({ content: "Logs fetched successfully." });
        setLogsModalVisible(true);
      })
      .catch((err) => {
        showErrorMsg({ content: "Error fetching logs." });
        console.log(err);
      });
  };

  const generateLogForRoute = (route1, route2) => {
    Object.keys(route1).forEach((key) => {
      if (
        key === "children" &&
        Array.isArray(route1[key]) &&
        Array.isArray(route2[key])
      ) {
        route1[key].forEach((child, index) => {
          generateLogForRoute(child, route2[key][index]);
        });
      } else if (route1[key] instanceof Object) {
        generateLogForRoute(route1[key], route2[key]);
      } else if (route1[key] !== route2[key]) {
        logs.currentData.route[route1.title] = {
          ...(logs.currentData.route[route1.title] || {}),
          [key]: route2[key] ? "Yes" : "No",
        };
        logs.previousData.route[route1.title] = {
          ...(logs.previousData.route[route1.title] || {}),
          [key]: route1[key] ? "Yes" : "No",
        };
      }
    });
  };

  const generateLogForSidebar = (unchangedData) => {
    unchangedData.Sidebar.map((route, index) => {
      if (route.read !== accessConfiguration.Sidebar[index].read) {
        logs.currentData.sidebar[route.title] = accessConfiguration.Sidebar[
          index
        ].read
          ? "Yes"
          : "No";
        logs.previousData.sidebar[route.title] = route.read ? "Yes" : "No";
      }
    });
  };

  function generateLogsForDriveAccess(currentDataObj, previousDataObj) {
    let newPreviousDataObj = {};
    Object.keys(previousDataObj).forEach((key) => {
      if (previousDataObj[key] === undefined) {
        newPreviousDataObj[key] = "none";
      } else {
        newPreviousDataObj[key] = previousDataObj[key];
      }
    });
    Object.keys(currentDataObj).forEach((key) => {
      if (currentDataObj[key] !== newPreviousDataObj[key]) {
        logs.currentData["Drive Access"][key] = currentDataObj[key];
        logs.previousData["Drive Access"][key] = newPreviousDataObj[key];
      }
    });
  }

  const removeEmptyKeys = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (isEmpty(obj[key])) {
        delete obj[key];
      }
    });
    return obj;
  };

  const saveChanges = () => {
    let tmpUnchangedData = { ...unchangedData };
    setUnchangedData(accessConfiguration);
    showLoadingMsg({ content: "Saving changes. Please wait..." });

    let routeConfig = removeDescription(
      filterRoutes(accessConfiguration.Route)
    );

    const configToUpdate = {
      sidebarConfig: accessConfiguration.Sidebar.filter(
        (sidebar) => sidebar.read
      ),
      routeConfig,
      //   tasksConfig: { canAssignToOthers: true }, //accessConfiguration.tasksConfig,
      //   groupName: "Admin", //accessConfiguration.groupName,
      //   isCustom: accessConfiguration.isCustom !== undefined ? "false" : "true",
    };

    API.put("userConfiguration", `/userConfiguration/${identityId}`, {
      body: {
        ...configToUpdate,
      },
    })
      .then(async () => {
        showLoadingMsg({
          content: "Saving drive access rights. Please wait...",
        });
        dispatch(updateUserKeysUser({ identityId, ...configToUpdate }));
        await updateGoogleDrivePermissions({
          form,
          accessToken,
          currentUser,
          foldersAccess,
          hotCredentials,
          allFoldersAccess,
        }).then(() => {
          showSuccessMsg({ content: "Changes saved successfully." });
        });
        generateLogForRoute(tmpUnchangedData.Route, accessConfiguration.Route);
        generateLogForSidebar(tmpUnchangedData);
        generateLogsForDriveAccess(form.getFieldsValue(), foldersAccess);
        removeEmptyKeys(logs.currentData);
        removeEmptyKeys(logs.previousData);
        saveAddedLogs(logs);
      })
      .catch((error) => {
        // showErrorMsg({ content: "Error saving changes." });
        console.log({ error });
      })
      .finally(() => {
        setWarningModalVisible(false);
      });
  };

  const additionalData = {
    accessConfiguration,
    selectedUser: currentUser,
    log: logs,
    base64,
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData,
      onGeneratePDF,
      exportToExcel,
    });

  return (
    <>
      <div className="submitButtonContainer">
        <div className="left">
          <MondayButton
            className="backButton"
            Icon={<Back />}
            onClick={() => navigate(-1)}
          >
            Back
          </MondayButton>
          <TourGuide
            {...{
              tab: 7,
              category: "userAccessRightsChanges",
              stepsData: editUserStepData,
            }}
          />
        </div>
        <div className="right">
          <MondayButton
            {...{
              id: "logsButton",
              className: "mondayButtonBlue logsButton iconButton",
              Icon: <HistoryOutlined width={18} />,
              onClick: fetchPersonalEditLogs,
            }}
          />
          <MondayButton
            className="mondayButtonBlue iconButton"
            Icon={<CopyIconWhite />}
            onClick={() => setOpenCloneAccess(true)}
          />
          <MondayButton
            id={"previewButton"}
            className="mondayButtonBlue iconButton"
            Icon={<EyeFilled style={{ fontSize: "22px", color: "#fff" }} />}
            onClick={() => setPreviewAccessVisible(true)}
          />
          <ExportOrEmailDropdown
            {...{
              rowData: true,
              onGeneratePDF: (action, getDocDefinition) =>
                getDocDefinition
                  ? onGeneratePDF(
                      action,
                      additionalData,
                      getDocDefinition,
                      null,
                      saveAddedLogs
                    )
                  : getHeadersModal(action, getDocDefinition),
              exportGridToExcel: (getDocDefinition) =>
                getDocDefinition
                  ? exportToExcel(
                      accessConfiguration,
                      additionalData,
                      getDocDefinition,
                      saveAddedLogs
                    )
                  : getHeadersModal(false, getDocDefinition, "excel"),
              uploadExcelToDrive: (driveRequest, driveFolderId) =>
                uploadExcelToDrive(additionalData, driveRequest, driveFolderId),
              defaultDocDefinition: true,
              title: `${currentUser.nameOfUser}_${currentUser.departmentName}_${currentUser.groupName}`,
            }}
          />
          <MondayButton
            id={"submitButton"}
            className="mondayButton"
            Icon={<TickIcon />}
            onClick={() => setWarningModalVisible(true)}
          >
            Submit
          </MondayButton>
        </div>
      </div>

      {logsModalVisible && (
        <MultiLevelTreeLogs
          title={`User Access ${currentUser.nameOfUser} Logs`}
          visible={logsModalVisible}
          setVisible={setLogsModalVisible}
          logsData={personalEditLogs}
        />
      )}

      {previewAccessVisible && (
        <PreviewAccessModal
          {...{
            previewAccessData,
            previewAccessVisible,
            setPreviewAccessVisible,
          }}
        />
      )}

      {warningModalVisible && (
        <RoleWarningModal
          {...{ saveChanges, warningModalVisible, setWarningModalVisible }}
        />
      )}

      {openCloneAccess && (
        <CloneUserAccess
          {...{
            openCloneAccess,
            setOpenCloneAccess,
            currentUser,
          }}
        />
      )}
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: tableHeaders,
            title: "User Access",
            nested: true,
          }}
        />
      )}
    </>
  );
};

export default UserFooter;
