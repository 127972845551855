import moment from "moment";
import {
  getCurrentPayment,
  rentalDataExtractor,
} from "./ControlPanelComponents/ExportToExcel/exportToExcelFunctions";
import { checkIfServiceIsHoist } from "../../../../../../../../Estimations/DataEntryGrid/models/Service";

//this function changes status of the application
export const statusChangeHandler = ({
  fakeApplication,
  dispatchContext,
  status,
}) => {
  //the new application with the new status
  const newApplication = {
    ...fakeApplication,
    applicationStatus: status,
  };

  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
};

export const datesChangeHandler = ({
  fakeApplication,
  dispatchContext,
  dates,
}) => {
  //the new application with the new status
  // console.log("dates", { dates, fakeApplication });
  const newApplication = {
    ...fakeApplication,
    periodTo: dates?.selectedMonth,
    dueDate: dates?.dueDate,
  };
  dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
};

//just an adapter that is used to change the classnames of the status button based on which statues they have picked
export const classNameAdapter = {
  Approved: "approvedStatus",
  Sent: "sentStatus",
  Draft: "draftStatus",
  Paid: "paidStatus",
};

export const totalCalculatorChanges = (application, applications) => {
  let tempAmount = 0;
  let tempRetainage = 0;
  let currentPeriod = 0;
  let currentRetainage = 0;
  let totalRentalWithTax = 0;
  let totalCreditRental = 0;
  let rentalRet = 0;
  let rentalThis = 0;
  let testThisRetainage = 0;
  let reducedAmount = 0;
  let currentRentalsRetainage = 0;
  let CURRENT_RENTAL_RETAINAGE = 0;

  // console.log("checking Input", application, applications);

  // Current Application Calculations

  if (!!application) {
    let services = application?.services || [];
    if (services?.length > 0) {
      services.forEach((service) => {
        if (!checkIfServiceIsHoist(service)) {
          tempAmount += Number(service.totalPrice);
          let amounts = service?.amounts || [];
          if (amounts?.length > 0) {
            amounts.forEach((amount) => {
              currentPeriod += Number(amount.thisPeriod);
              reducedAmount += Number(amount.reducedAmount);
              currentRetainage += Number(
                (amount.thisPeriod * amount.retainagePercentage) / 100 || 0
              );
              // console.log("currentRetainage1", currentRetainage);
              tempRetainage += Number(amount.retainage);
              testThisRetainage += Number(
                (amount.thisPeriod * amount.retainagePercentage) / 100 || 0
              );
              // console.log("testThisRetainage1", testThisRetainage);
            });
          }
          // tempRetainage +=
          // 	service?.retainage > 0 ? (service.retainage / 100) * service.totalPrice : 0;
        } else if (checkIfServiceIsHoist(service)) {
          let serviceOptions = service?.serviceOptions || [];
          if (serviceOptions.length > 0) {
            serviceOptions?.forEach((serviceO) => {
              if (Array.isArray(serviceO)) {
                serviceO.forEach((serviceO2) => {
                  // console.log("serviceO2", serviceO2);
                  tempAmount += Number(serviceO2.totalPrice);
                  tempRetainage +=
                    service?.retainage > 0
                      ? (service.retainage / 100) * serviceO2.totalPrice
                      : 0;
                  let temp =
                    serviceO2?.amounts
                      ?.map((a) => {
                        reducedAmount += Number(a.reducedAmount);
                        return a?.thisPeriod;
                      })
                      ?.reduce((acc, curr) => (acc = acc + curr), 0) || 0;
                  // console.log("temphoist", temp);
                  currentPeriod += Number(temp);
                  currentRetainage += Number(
                    (temp * service.retainage) / 100 || 0
                  );
                  testThisRetainage += Number(
                    (temp * service.retainage) / 100 || 0
                  );
                  // console.log("testThisRetaiange2", testThisRetainage);
                });
              }
            });
          }
        }
      });
    }
    if (application?.includedRentals?.length > 0) {
      application?.includedRentals?.forEach(({ services = [] }) => {
        services.forEach((el) => {
          // console.log("my credit element", el);
          let taxAmount = el?.appliedAmount * el?.taxRate || 0;
          let retainage =
            el?.retainage || 0 > 0
              ? (el?.retainage / 100) * el?.appliedAmount
              : 0;
          let credit =
            (el?.serviceLevelCredit || 0) +
            (el?.serviceLevelCredit || 0) * (el?.taxRate || 0);
          totalRentalWithTax +=
            Number(el?.appliedAmount) +
            Number(taxAmount) +
            Number(retainage) +
            Number(credit);
          rentalThis +=
            Number(el?.appliedAmount) + Number(credit) + Number(taxAmount);
          totalCreditRental += Number(credit);
          rentalRet += Number(retainage);
          currentRentalsRetainage +=
            Number(retainage) + Number(retainage) * Number(el?.taxRate);
          testThisRetainage += Number(retainage);

          ////////////////

          let totalRentAmount = el?.appliedAmount + taxAmount + credit;
          let currentRentalAm =
            el?.retainage || 0 > 0
              ? (el?.retainage / 100) * totalRentAmount
              : 0;
          // console.log("this included rental", credit);
          // totalRentalWithTax +=
          //   Number(el?.appliedAmount) +
          //   Number(taxAmount) +
          //   Number(retainage) +
          //   Number(credit);
          // rentalThis +=
          //   Number(el?.appliedAmount) + Number(credit) + Number(taxAmount);
          // totalCreditRental += Number(credit);
          // rentalRet += Number(retainage);
          CURRENT_RENTAL_RETAINAGE += Number(currentRentalAm);
        });
      });
    }
  }

  // If there are previous applications, classify them as previous and calculate their retainage

  // Other Applications Calculations (Previous Applications from Current)
  const otherReqs = applications?.filter(
    (el) =>
      el?.applicationNo < application?.applicationNo &&
      el?.scheduleId === application?.scheduleId
  );

  // Other Rentals Calculations included in Application (Previous Applications from Current)

  const othersRentalData = otherReqs
    ?.map((el) => {
      const temo = rentalDataExtractor({
        fakeApplication: el,
      });
      // console.log("tempppo", temo);
      return rentalDataExtractor({
        fakeApplication: el,
      })
        ?.filter(Boolean)
        ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
        ?.map((e) => ({
          ...e,
          isPrevious: true,
          // retainage: !!application?.prevRentalsRetainage
          //   ? application?.prevRentalsRetainage
          //   : e?.retainage,
          // prevRet: e?.retainage,
        }));
    })
    .flatMap((e) => e);

  othersRentalData?.forEach((el) => {
    // let retainage =
    //   el?.prevRet || 0 > 0 ? (el?.prevRet / 100) * el?.amountForThisMonth : 0;
    let retainage = el?.retainage;
    // reducedAmount += Number(el?.retainage);
    currentRentalsRetainage +=
      Number(retainage) + Number(retainage) * Number(el?.taxRate || 0);
    // console.log("my other rental data", {
    //   retainage: retainage,
    //   appliedAmount: el?.appliedAmount,
    //   taxRate: el?.taxRate,
    //   currentRentalsRetainage,
    //   el,
    // });
  });

  const lastRequisition =
    otherReqs?.length > 0
      ? otherReqs.sort((a, b) => b?.createdAt - a?.createdAt)[0]
      : null;
  const {
    currentPaymentDue = 0,
    thisPeriodRetainage = 0,
    retaiangeForAll = 0,
    lastRetainage = 0,
  } = getCurrentPayment({
    prev: lastRequisition,
    next: application,
    othersRentalData,
    otherReqs,
  });

  // console.log("total application calculator", {
  //   totalAmount: currentPaymentDue,
  //   reducedAmount,
  //   currentRentalsRetainage,
  //   totalRetainage: tempRetainage + rentalRet,
  //   currentPeriod: currentPeriod + rentalThis,
  //   currentRetainage: currentRetainage + rentalRet,
  //   // currentRetainage: thisPeriodRetainage,
  //   othersRentalData,
  //   totalRentalWithTax,
  //   thisPeriodRetainage,
  //   prevRentalsRet: application,
  // });
  // console.log("currentRetainage3", {
  //   lastRetainage,
  //   retaiangeForAll,
  //   CURRENT_RENTAL_RETAINAGE,
  // });

  return {
    // totalAmount: tempAmount + totalRentalWithTax - totalCreditRental,
    totalAmount: currentPaymentDue,
    // totalRetainage: tempRetainage + rentalRet,
    totalRetainage: retaiangeForAll,
    currentPeriod: currentPeriod + rentalThis,
    currentRetainage: currentRetainage + rentalRet,
    currentRetainage: thisPeriodRetainage,
    totalRentalWithTax,
    thisRentalsRetainage: currentRentalsRetainage,
    currentPaymentDue,
    thisPeriodRetainage:
      reducedAmount > 0
        ? reducedAmount + currentRentalsRetainage
        : lastRetainage - retaiangeForAll,
  };
};
export const totalCalculator = (application, applications) => {
  let tempAmount = 0;
  let tempRetainage = 0;
  let currentPeriod = 0;
  let currentRetainage = 0;
  let totalRentalWithTax = 0;
  let totalCreditRental = 0;
  let rentalRet = 0;
  let rentalThis = 0;
  let testThisRetainage = 0;
  let reducedAmount = 0;
  let currentRentalsRetainage = 0;
  let CURRENT_RENTAL_RETAINAGE = 0;

  console.log("checking Input", application, applications);

  // Current Application Calculations

  if (!!application) {
    let services = application?.services || [];
    if (services?.length > 0) {
      services.forEach((service) => {
        if (!checkIfServiceIsHoist(service)) {
          tempAmount += Number(service.totalPrice);
          let amounts = service?.amounts || [];
          if (amounts?.length > 0) {
            amounts.forEach((amount) => {
              currentPeriod += Number(amount.thisPeriod);
              reducedAmount += Number(amount.reducedAmount);
              currentRetainage += Number(
                (amount.thisPeriod * amount.retainagePercentage) / 100 || 0
              );
              // console.log("currentRetainage1", currentRetainage);
              tempRetainage += Number(amount.retainage);
              testThisRetainage += Number(
                (amount.thisPeriod * amount.retainagePercentage) / 100 || 0
              );
              // console.log("testThisRetainage1", testThisRetainage);
            });
          }
          // tempRetainage +=
          // 	service?.retainage > 0 ? (service.retainage / 100) * service.totalPrice : 0;
        } else if (checkIfServiceIsHoist(service)) {
          let serviceOptions = service?.serviceOptions || [];
          if (serviceOptions.length > 0) {
            serviceOptions?.forEach((serviceO) => {
              if (Array.isArray(serviceO)) {
                serviceO.forEach((serviceO2) => {
                  // console.log("serviceO2", serviceO2);
                  tempAmount += Number(serviceO2.totalPrice);
                  tempRetainage +=
                    service?.retainage > 0
                      ? (service.retainage / 100) * serviceO2.totalPrice
                      : 0;
                  let temp =
                    serviceO2?.amounts
                      ?.map((a) => {
                        reducedAmount += Number(a.reducedAmount);
                        return a?.thisPeriod;
                      })
                      ?.reduce((acc, curr) => (acc = acc + curr), 0) || 0;
                  // console.log("temphoist", temp);
                  currentPeriod += Number(temp);
                  currentRetainage += Number(
                    (temp * service.retainage) / 100 || 0
                  );
                  testThisRetainage += Number(
                    (temp * service.retainage) / 100 || 0
                  );
                  // console.log("testThisRetaiange2", testThisRetainage);
                });
              }
            });
          }
        }
      });
    }
    if (application?.includedRentals?.length > 0) {
      application?.includedRentals?.forEach(({ services = [] }) => {
        services.forEach((el) => {
          // console.log("my credit element", el);
          let taxAmount = el?.appliedAmount * el?.taxRate || 0;
          let retainage =
            el?.retainage || 0 > 0
              ? (el?.retainage / 100) * el?.appliedAmount
              : 0;
          let credit =
            (el?.serviceLevelCredit || 0) +
            (el?.serviceLevelCredit || 0) * (el?.taxRate || 0);
          totalRentalWithTax +=
            Number(el?.appliedAmount) +
            Number(taxAmount) +
            Number(retainage) +
            Number(credit);
          rentalThis +=
            Number(el?.appliedAmount) + Number(credit) + Number(taxAmount);
          totalCreditRental += Number(credit);
          rentalRet += Number(retainage);
          currentRentalsRetainage +=
            Number(retainage) + Number(retainage) * Number(el?.taxRate);
          testThisRetainage += Number(retainage);

          ////////////////

          let totalRentAmount = el?.appliedAmount + taxAmount + credit;
          let currentRentalAm =
            el?.retainage || 0 > 0
              ? (el?.retainage / 100) * totalRentAmount
              : 0;
          // console.log("this included rental", credit);
          // totalRentalWithTax +=
          //   Number(el?.appliedAmount) +
          //   Number(taxAmount) +
          //   Number(retainage) +
          //   Number(credit);
          // rentalThis +=
          //   Number(el?.appliedAmount) + Number(credit) + Number(taxAmount);
          // totalCreditRental += Number(credit);
          // rentalRet += Number(retainage);
          CURRENT_RENTAL_RETAINAGE += Number(currentRentalAm);
        });
      });
    }
  }

  // If there are previous applications, classify them as previous and calculate their retainage

  // Other Applications Calculations (Previous Applications from Current)
  const otherReqs = applications?.filter(
    (el) =>
      el?.applicationNo < application?.applicationNo &&
      el?.scheduleId === application?.scheduleId
  );

  // Other Rentals Calculations included in Application (Previous Applications from Current)

  const othersRentalData = otherReqs
    ?.map((el) => {
      const temo = rentalDataExtractor({
        fakeApplication: el,
      });
      // console.log("tempppo", temo);
      return rentalDataExtractor({
        fakeApplication: el,
      })
        ?.filter(Boolean)
        ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
        ?.map((e) => ({
          ...e,
          isPrevious: true,
          // retainage: !!application?.prevRentalsRetainage
          //   ? application?.prevRentalsRetainage
          //   : e?.retainage,
          // prevRet: e?.retainage,
        }));
    })
    .flatMap((e) => e);

  othersRentalData?.forEach((el) => {
    // let retainage =
    //   el?.prevRet || 0 > 0 ? (el?.prevRet / 100) * el?.amountForThisMonth : 0;
    let retainage = el?.retainage;
    // reducedAmount += Number(el?.retainage);
    currentRentalsRetainage +=
      Number(retainage) + Number(retainage) * Number(el?.taxRate || 0);
    // console.log("my other rental data", {
    //   retainage: retainage,
    //   appliedAmount: el?.appliedAmount,
    //   taxRate: el?.taxRate,
    //   currentRentalsRetainage,
    //   el,
    // });
  });

  const lastRequisition =
    otherReqs?.length > 0
      ? otherReqs.sort((a, b) => b?.createdAt - a?.createdAt)[0]
      : null;
  const {
    currentPaymentDue = 0,
    thisPeriodRetainage = 0,
    retaiangeForAll = 0,
    lastRetainage = 0,
  } = getCurrentPayment({
    prev: lastRequisition,
    next: application,
    othersRentalData,
  });

  // console.log("total application calculator", {
  //   totalAmount: currentPaymentDue,
  //   reducedAmount,
  //   currentRentalsRetainage,
  //   totalRetainage: tempRetainage + rentalRet,
  //   currentPeriod: currentPeriod + rentalThis,
  //   currentRetainage: currentRetainage + rentalRet,
  //   // currentRetainage: thisPeriodRetainage,
  //   othersRentalData,
  //   totalRentalWithTax,
  //   thisPeriodRetainage,
  //   prevRentalsRet: application,
  // });
  // console.log("currentRetainage3", {
  //   lastRetainage,
  //   retaiangeForAll,
  //   CURRENT_RENTAL_RETAINAGE,
  // });

  return {
    // totalAmount: tempAmount + totalRentalWithTax - totalCreditRental,
    totalAmount: currentPaymentDue,
    // totalRetainage: tempRetainage + rentalRet,
    totalRetainage: retaiangeForAll,
    currentPeriod: currentPeriod + rentalThis,
    currentRetainage: currentRetainage + rentalRet,
    currentRetainage: thisPeriodRetainage,
    totalRentalWithTax,
    thisRentalsRetainage: currentRentalsRetainage,
    currentPaymentDue,
    thisPeriodRetainage:
      reducedAmount > 0
        ? reducedAmount + currentRentalsRetainage
        : lastRetainage - retaiangeForAll,
  };
};
