import { useSelector } from "react-redux";
import { debounce, uniqBy } from "lodash";
import { MenuOutlined } from "@ant-design/icons";
import { ChangeEvent, useContext, useMemo, useRef, useState } from "react";

import {
  TeamSidebarItem,
  JobsiteSidebarItem,
  EmployeeSidebarItem,
  ScheduleSidebarItem,
} from "..";
import { ScheduleType } from "../../payrollLiveTypes";
import PayrollLiveContext from "../../PayrollLiveContext";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./PayrollLiveMapSidebar.scss";

function PayrollLiveMapSidebar() {
  const {
    schedules,
    setFilters,
    filteredCrews,
    employeesReport,
    filteredEmployees,
    filteredSchedules,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const [toggleOpen, setToggleOpen] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<Array<string>>([]);
  const [selectedData, setSelectedData] = useState<
    "employees" | "schedules" | "crews"
  >("schedules");

  const inputRef = useRef(null);
  const dataHolderRef = useRef(null);
  const controlPanelRef = useRef(null);

  const sidebarData = useMemo(() => {
    const teamsIncluded = uniqBy(employeesReport, (el) => el?.crewTeamId).map(
      (el) => el.crewTeamId
    );
    let crews = [];
    for (let i = 0; i < filteredCrews.length; i++) {
      const team = filteredCrews[i];
      if (teamsIncluded.includes(team?.crewTeamId)) {
        let teamMembers = team.crewMembers.map((el) =>
          Number(el?.employeeId?.replace("GMNY Construction-", ""))
        );
        const teamObj = {
          crewTeamName: team?.crewTeamName,
          crewForeman: team?.crewForeman,
          crewTeamId: team?.crewTeamId,
          crewMembers: team?.crewMembers,
          members: filteredEmployees.flatMap((el) =>
            teamMembers.includes(Number(el?.employeeNumber)) ? el : []
          ),
        };
        crews.push(teamObj);
      }
    }

    return {
      employees: filteredEmployees,
      schedules: filteredSchedules,
      crews,
    };
  }, [filteredSchedules, filteredEmployees, employeesReport, filteredCrews]);

  function onSearch(inputVal: string) {
    if (selectedData === "schedules") {
      const selectedSchedules = schedules.flatMap(
        (sch: ScheduleType & { jobAddress: string }) =>
          !!inputVal &&
          (sch?.scheduleAddress || sch?.jobAddress)
            .toLowerCase()
            .includes(inputVal.toLowerCase())
            ? sch.projectId
            : []
      );
      const empMatchOfSchedules = employeesReport.flatMap((emp) =>
        selectedSchedules.includes(emp?.projectId) ? emp.employeeNumber : []
      );

      setFilters((prev) => ({
        ...prev,
        scheduleAddress: inputVal,
        employeeNumber: empMatchOfSchedules,
      }));
    } else if (selectedData === "employees") {
      setFilters((prev) => ({ ...prev, employeeSearch: inputVal }));
    } else if (selectedData === "crews") {
      setFilters((prev) => ({ ...prev, crewSearch: inputVal }));
    }
  }

  function onClearSearch() {
    setFilters((prev) => ({
      ...prev,
      scheduleAddress: "",
      employeeSearch: "",
      employeeNumber: [],
    }));
    setInputValue("");
  }

  function changeSelectedData(
    selectedData: "schedules" | "employees" | "crews"
  ) {
    setSelectedData(selectedData);
    onClearSearch();
  }

  function toggleSidebar() {
    setToggleOpen((prev) => {
      if (!prev) {
        const animation = [
          {
            opacity: 0,
          },
          {
            opacity: 1,
          },
        ];
        const timing = {
          duration: 300,
          transition: "ease-put",
          rangeStart: "0%",
          rangeEnd: "100%",
        };
        controlPanelRef.current?.animate?.(animation, timing);
        dataHolderRef.current?.animate?.(animation, timing);
      }
      return !prev;
    });
  }

  const totalCounts = useMemo(() => {
    const jobsIncluded = uniqBy(
      (employeesReport || [])?.filter(
        (el) => !!el?.jobsiteId && !el?.projectId
      ),
      (el) => el.jobsiteId
    );

    const schedulesIncluded = uniqBy(
      (employeesReport || [])?.filter((el) => !!el?.projectId),
      (el) => el.projectId
    );

    const crewsIncluded = uniqBy(
      employeesReport.filter((el) => !!el?.crewTeamId),
      (el) => el?.crewTeamId
    );

    const employeesIncluded = employeesReport?.filter(
      (el) => el.liveStatus !== "No Punch"
    );

    return {
      crews: `Total Crews on Site ${crewsIncluded?.length || 0}`,
      schedules: `Total Working Jobs ${
        jobsIncluded?.length + schedulesIncluded?.length || 0
      }`,
      employees: `Total Employees on Site ${employeesIncluded?.length}`,
    };
  }, [employeesReport?.length]);

  return (
    <div
      className={`map-sidebar ${darkMode ? "map-sidebar-dark" : ""} ${
        toggleOpen ? "open-sidebar" : "closed-sidebar"
      }`}
    >
      <div className="sidebar-control-panel" ref={controlPanelRef}>
        <div className="data-type-select">
          <div className="menu-icon" onClick={toggleSidebar}>
            <MenuOutlined />
          </div>
          {toggleOpen ? (
            <div className="sidebar-tabs">
              <div
                className={`tab-item ${
                  selectedData === "schedules" ? "active" : ""
                }`}
                onClick={() => changeSelectedData("schedules")}
              >
                Schedules
              </div>
              <div
                className={`tab-item ${
                  selectedData === "crews" ? "active" : ""
                }`}
                onClick={() => changeSelectedData("crews")}
              >
                Crews
              </div>
              <div
                className={`tab-item ${
                  selectedData === "employees" ? "active" : ""
                }`}
                onClick={() => changeSelectedData("employees")}
              >
                Employees
              </div>
            </div>
          ) : null}
        </div>
        <div className="search-field">
          {toggleOpen ? (
            <InputComponent
              type="input"
              inputRef={inputRef}
              placeholder="Search..."
              value={inputValue}
              allowClear
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
                debounce(onSearch, 600)(e.target.value);
              }}
            />
          ) : null}
          {toggleOpen ? (
            <div className="data-total-count">
              {totalCounts?.[selectedData] || ""}
            </div>
          ) : null}
        </div>
      </div>
      <div className="data-holder-container" ref={dataHolderRef}>
        {toggleOpen
          ? sidebarData[selectedData].flatMap((data) => {
              if (selectedData === "schedules") {
                return data?.jobsiteId ? (
                  <JobsiteSidebarItem {...data} />
                ) : (
                  <ScheduleSidebarItem {...data} />
                );
              } else if (selectedData === "crews") {
                return (
                  <TeamSidebarItem
                    {...{ ...data, selectedEmployees, setSelectedEmployees }}
                  />
                );
              } else {
                return (
                  <EmployeeSidebarItem
                    {...{
                      ...data,
                      selectedEmployees,
                      setSelectedEmployees,
                    }}
                  />
                );
              }
            })
          : null}
      </div>
    </div>
  );
}

export default PayrollLiveMapSidebar;
