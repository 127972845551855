import { useState } from "react";
import "./FilterEvents.scss";
import { Select } from "antd";

const FilterButton = ({ title, changeEventTimeline, onFilter, style }) => {
  const handleFilter = () => {
    changeEventTimeline(title);
    onFilter(title);
  };

  return (
    <button className="filter_button" onClick={handleFilter} style={style}>
      {title}
    </button>
  );
};

const btns = [
  "Today",
  "This Week",
  "This Month",
  "Next Month",
  "This Year",
  "All",
];

function FilterEvents({
  calendars,
  handleSelect,
  changeEventTimeline,
  selectedCalendar,
}) {
  const [active, setActive] = useState("This Week");
  const onFilter = (name) => {
    setActive(name);
  };

  return (
    <div className="filter_container">
      <div className="filter_title">
        <strong>Filter:</strong>
      </div>
      <Select
        className="select-status"
        style={{ width: "200px", marginLeft: "5px" }}
        showSearch
        placeholder="Select a calendar"
        defaultValue={
          selectedCalendar ||
          calendars?.find((el) => el?.primary === true)?.id ||
          "primary"
        }
        onChange={(e) => {
          handleSelect(e);
        }}
      >
        {calendars?.map((calendarObj) => (
          <Select.Option key={calendarObj.id} value={calendarObj.id}>
            {calendarObj.summary}
          </Select.Option>
        ))}
      </Select>
      <div className="filter_btns">
        {btns.map((el, idx) => (
          <FilterButton
            key={idx}
            title={el}
            changeEventTimeline={changeEventTimeline}
            onFilter={onFilter}
            style={active === el ? activeStyle : null}
          />
        ))}
      </div>
    </div>
  );
}

export default FilterEvents;
const activeStyle = {
  background: " #1264a3",
  color: "white",
  border: "1px solid #1264a3",
};
