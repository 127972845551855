import { useRef, useEffect } from "react";

const useStickyElement = () => {
  const originalRef = useRef();
  const fakeRef = useRef();

  useEffect(() => {
    const element = fakeRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].boundingClientRect.top < 0) {
          originalRef.current?.classList?.add("fixed");
        } else {
          originalRef.current?.classList?.remove("fixed");
        }
      },
      {
        threshold: 1,
      }
    );

    observer.observe(element);

    return () => observer.unobserve(element);
  }, []);

  return [originalRef, fakeRef];
};

export default useStickyElement;
