import dayjs from "dayjs"

export const violations = ({ tables }) => {
  return {
    amount_due: () => ({
      statusData: tables?.Violations?.map((a) => {
        return {
          label: a?.violationLocation,
          value: a?.fine_amount,
          color: "#ECDE7D",
        }
      }),
    }), //amount_due
    issue_date: () => ({
      statusData: tables?.Violations?.map((a) => {
        return {
          label: a?.violationLocation,
          value: dayjs(a?.issue_date)?.format("MM/DD/YYYY"),
          color: "#ECDE7D",
        }
      }),
    }), //issue_date
  } //Violations
}
