import { useEffect, useState } from "react";
import { fetchDataAPI } from "../helpers/fetchDataApi";

export const useStepData = (category) => {
  const [dataMappings, setDataMappings] = useState({});

  const projectExecutives =
    dataMappings?.executives?.map((executive) => ({
      id: executive.peId,
      label: `${executive.firstName} ${executive.lastName}`,
      value: `${executive.firstName} ${executive.lastName}`,
    })) || [];

  const fetchDataByCategory = async (category) => {
    const allData = fetchDataAPI.all || [];
    const categoryData = fetchDataAPI[category] || [];
    const results = {};

    await Promise.all(
      [...allData, ...categoryData].map(async (item) => {
        const { function: fetchFunction, ...params } = item;

        const data = await fetchFunction(params);

        if (params.listOfKeys)
          results[params.resultKey] = data.map((item) => ({
            id: item[params.listOfKeys[0]],
            label: item[params.listOfKeys[1]],
            value: item[params.listOfKeys[1]],
          }));
        else results[params.resultKey] = data;
      })
    );

    return results;
  };

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      const results = await fetchDataByCategory(category);

      const newDataMappings = {};
      Object.keys(results).forEach((key) => {
        if (results[key]) {
          newDataMappings[key] = results[key];
          return;
        }
        newDataMappings[key] = results;
      });

      setDataMappings((prevDataMappings) => ({
        ...prevDataMappings,
        ...newDataMappings,
      }));
    };

    fetchDataAndSetState();
  }, [category]);

  return {
    ...dataMappings,
    projectExecutives,
  };
};
