export const routeData = {
  Leads: "/leads",
  Opportunities: "/opportunities",
  Projects: "/projects",
  Documentation: "/documentation",
  Estimations: "/estimations",
  "Add-ons": "/estimations",
  Scheduling: "/scheduling",
  Applications: "/accounting",
  Rentals: "/accounting",
  Charges: "/accounting",
  Invoices: "/accounting",
  Fleet: "/fleets/overview",
  Dispatching: "/fleets/overview?tab=Dispatch",
  Violations: "/fleets/overview?tab=Violations",
  Inspections: "/inspections",
  Maintenance: "/fleets/overview",
  Incidents: "/incidents",
  Defects: "/fleetDefects",
  "Work Orders": "/workOrders",
  Claims: "/claims",
  Safety: "/safety",
  Approvals: "/approvals",
  "Fleet Inspections": "/fleetInspections",
};

export const routeDataObject = {
  Leads: { leadStatus: undefined, borough: undefined },
  Opportunities: { opportunityStage: undefined, opportunityStatus: undefined },
  Projects: {
    tabPosition: "Projects",
    projectStatus: undefined,
    attorney: undefined,
    expeditor: undefined,
  },
  Documentation: { docType: undefined },
  Estimations: {
    tabPosition: "Estimations",
    engProgress: undefined,
    archProgress: undefined,
    estSTATUS: undefined,
    archAssignedTo: undefined,
    engAssignedTo: undefined,
    statusByArch: undefined,
    statusByEng: undefined,
  },
  Applications: {
    applicationStatus: undefined,
    tabPosition: "Requisition",
  },
  Rentals: {
    rentalStatus: undefined,
    tabPosition: "Rentals",
  },
  Charges: {
    chargeStatus: undefined,
    tabPosition: "Charges",
  },
  Invoices: {
    invoiceStatus: undefined,
    tabPosition: "Invoice",
  },
  "Add-ons": { amount: undefined },
  Scheduling: { typeOfWork: undefined },
  Fleet: {
    fleetYear: undefined,
    registrationExpDate: undefined,
    inspectionExpDate: undefined,
  },
  Dispatching: {
    fleetName: undefined,
    dispatchedBy: undefined,
    driverName: undefined,
    tabPosition: "Dispatch",
  },
  Violations: {
    amount_due: undefined,
    issue_date: undefined,
    tabPosition: "Violations",
  },
  Inspections: {
    inspectedBy: undefined,
    driverName: undefined,
    createdAt: undefined,
    tabPosition: "Inspections",
  },
  Maintenance: { createdAt: undefined, tabPosition: "Maintenance" },
  Defects: {
    defectStatus: undefined,
    defectName: undefined,
    fleetName: undefined,
    driverName: undefined,
    defectPriority: undefined,
    inspectionName: undefined,
    tabPosition: "Defects",
  },
  // Incidents: {
  //   driverName: undefined,
  //   fleetName: undefined,
  //   incidentAddress: undefined,
  //   incidentStatus: undefined,
  //   NYPD: undefined,
  //   FDNY: undefined,
  //   PARAMEDICS: undefined,
  //   OSHA: undefined,
  //   dobBestSquad: undefined,
  //   incidentWitnessed: undefined,
  //   injuredPerson: undefined,
  //   tabPosition: "Incidents",
  // },
  // Tasks: {
  //   tabPosition: "Tasks",
  //   taskStatus: undefined,
  //   taskTopic: undefined,
  //   taskAssignedTo: undefined,
  //   taskStartDate: undefined,
  // },
};

export const progressAdapter = {
  Leads: {
    leadStatus: {
      key: "leadStatus",
      value: "value",
    },
    leadAddress: {
      key: "borough",
      value: "value",
    },
  },
  Opportunities: { opportunityStage: undefined, opportunityStatus: undefined },
  Projects: {
    projectStatus: undefined,
    attorney: undefined,
    expeditor: undefined,
  },
  Documentation: { docType: undefined },
  Estimations: {
    engProgress: undefined,
    archProgress: undefined,
    estSTATUS: undefined,
    archAssignedTo: undefined,
    engAssignedTo: undefined,
    statusByArch: undefined,
    statusByEng: undefined,
  },
  "Add-ons": { amount: undefined },
  Scheduling: { typeOfWork: undefined },
  Fleet: {
    fleetYear: undefined,
    registrationExpDate: undefined,
    inspectionExpDate: undefined,
  },
  Dispatching: {
    fleetName: undefined,
    dispatchedBy: undefined,
    driverName: undefined,
    tabPosition: "Dispatch",
  },
  Violations: {
    amount_due: undefined,
    issue_date: undefined,
    tabPosition: "Violations",
  },
  Inspections: {
    inspectedBy: undefined,
    driverName: undefined,
    createdAt: undefined,
    tabPosition: "Inspections",
  },
  Maintenance: { createdAt: undefined, tabPosition: "Maintenance" },
  Defects: {
    defectStatus: undefined,
    defectName: undefined,
    fleetName: undefined,
    driverName: undefined,
    defectPriority: undefined,
    inspectionName: undefined,
  },
  Incidents: {
    driverName: undefined,
    fleetName: undefined,
    incidentAddress: undefined,
    incidentStatus: undefined,
    NYPD: undefined,
    FDNY: undefined,
    PARAMEDICS: undefined,
    OSHA: undefined,
    dobBestSquad: undefined,
    incidentWitnessed: undefined,
    injuredPerson: undefined,
  },
};

export const monthsAdapter = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};
