import { forceToNumber } from "../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../../../../../../../utils";
import { useStateContext } from "../../../../../../Context/Context";
import ChangeSOVAmount from "../ChangeSOVAmount/ChangeSOVAmount";
import "./Information.scss";

const Information = ({ service }) => {
  const { darkMode } = useStateContext();

  const retainage = +service?.amounts
    ?.reduce((acc, curr) => acc + forceToNumber(curr?.retainage), 0)
    ?.toFixed(2);

  return (
    <div
      className={
        darkMode ? "informationContainerDark" : "informationContainerLight"
      }
    >
      {/* {service?.typeOfSOV && (
        <div className="sovHeaderInformationContainer">
          <div className="sovHeaderLabel">Template:</div>
          <div className="sovHeaderData">{service.typeOfSOV}</div>
        </div>
      )} */}
      <div className="sovHeaderInformationContainer">
        <div className="sovHeaderLabel">Total Amount</div>
        <div className="sovHeaderData">
          {formatCurrency(service.totalPrice, "USD")}
        </div>
      </div>
      <div className="sovHeaderInformationContainer">
        <div className="sovHeaderLabel">Retainage:</div>
        <div className="sovHeaderData">
          {formatCurrency(retainage, "USD")}{" "}
          <strong style={{ color: "#F6CB51" }}>
            ({service?.retainage + "%"})
          </strong>
        </div>
      </div>
      <div className="">
        <ChangeSOVAmount service={service} />
      </div>
    </div>
  );
};

export default Information;
