import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import uniq from "lodash/uniq";
import searchSafetyInspectionAddress from "../utils/searchSafetyInspectionAddress";
import { Checkbox, Tooltip } from "antd";

/**
 * Separates crew members based on the selected foreman.
 * @param {Array} foremen - The list of foremen.
 * @param {string} selectedForeman - The selected foreman.
 * @param {Array} crewMembers - The list of crew members.
 * @returns {Object} - An object containing custom options, class name, and mode.
 */
function separateCrewMembers(foremen, selectedForeman, crewMembers) {
  if (!!selectedForeman) {
    let crew = foremen.find((el) => el?.value === selectedForeman) || [];
    return {
      customOptions: [
        {
          label: "Under Foreman",
          options: crew?.members?.map((e) => ({
            value: e.crewId,
            label: e.crewName,
          })),
        },
        {
          label: "Other Members",
          options: crewMembers?.filter(
            (e) => !crew.members?.find((el) => el.crewId === e.value)
          ),
        },
      ],
      className: "multipleSelect",
      mode: "multiple",
    };
  } else {
    return {
      customOptions: crewMembers,
      className: "multipleSelect",
      mode: "multiple",
    };
  }
}

/**
 * Fixes the form fields based on the provided parameters and returns the updated fields.
 *
 * @param {Array} dynamicFields - The dynamic fields array.
 * @param {Object} descriptionValues - The description values object.
 * @param {Array} schedulesForDate - The schedules for date array.
 * @param {boolean} addressDisabled - The address disabled flag.
 * @param {Array} projects - The projects array.
 * @param {Function} setDescriptionValues - The function to set description values.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {Function} setRequiredTextArea - The function to set required text area.
 * @param {Function} changeDateOfIncident - The function to change date of incident.
 * @param {Function} onChangeAddress - The function to change address.
 * @param {Array} foremen - The foremen array.
 * @param {Array} crewMembers - The crew members array.
 * @param {Object} selectedForeman - The selected foreman object.
 * @param {Object} scopeProject - The scope project object.
 * @param {Array} fleet - The fleet array.
 * @param {Array} drivers - The drivers array.
 * @param {Object} addressSelectionInfo - The address selection info object.
 * @param {Function} navigate - The navigate function.
 * @param {string} defaultId - The default ID.
 * @param {string} propAddress - The prop address.
 * @param {Function} setProjects - The function to set projects.
 * @returns {Array} The updated fields array.
 */
export function fixFormFields({
  dynamicFields = [],
  descriptionValues = {},
  schedulesForDate = [],
  addressDisabled = false,
  projects = [],
  setDescriptionValues = () => {},
  userConfiguration = {},
  setRequiredTextArea = () => {},
  changeDateOfIncident = () => {},
  onChangeAddress = () => {},
  foremen = [],
  crewMembers = [],
  selectedForeman = null,
  scopeProject = {},
  fleet = [],
  drivers = [],
  addressSelectionInfo,
  navigate,
  defaultId,
  propAddress = "",
  setProjects = () => {},
  scopeOfWorkOptions,
  setScopeOfWorkOptions,
}) {
  let fields = [];

  let projectsWithServices = [];

  if (projects?.length) {
    projectsWithServices =
      (projects || [])?.filter((el) => Object.keys(el?.services).length > 0) ||
      [];
  }
  for (let i = 0; i < dynamicFields?.length; i++) {
    let field = structuredClone(dynamicFields[i]);

    delete field["placeholder"];

    if (field.type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        ...(field.label.includes("Foreman")
          ? { customOptions: foremen }
          : field.label.includes("Members")
          ? separateCrewMembers(foremen, selectedForeman, crewMembers)
          : {}),
      };
      if (field.label.includes("Members")) {
        delete field["options"];
      }
    } else if (field.type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    } else if (field.type === "textEditor") {
      field = {
        ...field,
        style: { width: "100%" },
        value: descriptionValues[field.formItemName],
        onChange(val) {
          setDescriptionValues((prev) => ({
            ...prev,
            [field.formItemName]: val,
          }));
        },
      };
      if (field.required === true) {
        setRequiredTextArea((prev) => uniq(prev.concat(field.formItemName)));
      }
    }

    if (
      field.formItemName === "inspectionAddress" ||
      field.formItemName === "jobsiteAddress"
    ) {
      field = {
        ...field,
        onSelect: onChangeAddress,
        disabled: addressDisabled,
        initialValue: propAddress,
        groupedOptions: [
          {
            label: "Projects",
            options: projectsWithServices
              ?.map((e) => ({
                key: e?.projectName + Math.random(),
                value: e?.projectName,
                label: e?.projectName,
              }))
              .sort((a, b) => a?.value?.localeCompare(b?.value)),
          },
        ],
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchSafetyInspectionAddress({
            searchValue,
            setProjects,
            userConfiguration,
          }),
      };
    } else if (field.formItemName === "reportPreparedBy") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: userConfiguration.allUsers.Items.map((e) => ({
          value: e.identityId,
          label: e.nameOfUser,
        })),
      };
    } else if (
      field.formItemName === "inspectionDate" ||
      field.formItemName === "reportPreparedOn" ||
      field.formItemName === "lastInspection"
    ) {
      field = {
        ...field,
        ...(field.formItemName === "inspectionDate"
          ? { onChange: changeDateOfIncident }
          : {}),
        disabledDate(e) {
          return e > dayjsNY().add(1, "day").startOf("D").valueOf();
        },
      };
    } else if (field.formItemName === "dateOfInspection") {
      field = {
        ...field,
        onChange: changeDateOfIncident,
        // disabledDate(e) {
        //   return e > dayjsNY().add(1, "day").startOf("D").valueOf();
        // },
      };
    }

    if (field.formItemName === "truck") {
      field = {
        ...field,
        customOptions: fleet?.map((e) => ({
          value: e.fleetId,
          label: e.fleetName,
        })),
      };
    }

    if (field.formItemName === "fullNameOfDriver") {
      field = {
        ...field,
        customOptions: drivers?.map((driver) => {
          return {
            value: driver.driverId,
            label: driver.driverName,
          };
        }),
      };
    }

    if (field.formItemName === "scopeOfWorkBeingPerformed") {
      console.log("field:", { field, scopeProject });
      field.label = (
        <>
          Scope of Work Being Performed
          <Tooltip
            {...{
              title: scopeOfWorkOptions
                ? "Switch to options from Settings"
                : "Switch to options from Project",
            }}
          >
            <Checkbox
              style={{ marginLeft: "10px" }}
              checked={scopeOfWorkOptions}
              onChange={() => {
                setScopeOfWorkOptions(!scopeOfWorkOptions);
              }}
            />
          </Tooltip>
        </>
      );

      if (scopeOfWorkOptions) {
        field.customOptions = Object.entries(scopeProject?.services || {})?.map(
          ([key, value], index) => {
            return {
              label: `Est ${index + 1}`,
              options: value
                ?.filter((e) => e?.isScope === false)
                ?.map((e) => {
                  return {
                    value: e?.label,
                    label: e?.label,
                  };
                }),
            };
          }
        );
      } else {
        field.customOptions = field.options.map((opt) => {
          return {
            value: opt,
            label: opt,
          };
        });
      }
    }

    if (
      field.formItemName === "inspectorName" ||
      field.formItemName === "nameOfSafetyInspector"
    ) {
      delete field["options"];
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: userConfiguration?.allUsers?.Items?.filter(
          (e) => e?.groupName === "Inspector"
        ).map((e) => ({
          value: e.identityId,
          label: e.nameOfUser,
        })),
      };
    }
    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );
    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);
      //   addConditionalFields({
      //     fields,
      //     fieldCategoryIndex,
      //     typeOfEquipment,
      //     field,
      //   });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      //   addConditionalFields({
      //     fields,
      //     fieldCategoryIndex: field.length - 1,
      //     typeOfEquipment,
      //     field,
      //   });
    }

    // if (namesToDisable.includes(field.formItemName)) {
    //   field.disabled = disabledFieldsForIncident;
    // }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );
  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }
  // if (
  //   defaultId &&
  //   (!!addressSelectionInfo?.projectId || !!addressSelectionInfo?.scheduleId)
  // ) {
  //   let generalInfoField = fields?.find(
  //     ({ title }) => title === "General Information"
  //   );
  //   let addressFieldIndex = (generalInfoField?.fields || [])?.findIndex(
  //     ({ formItemName }) => formItemName === "inspectionAddress"
  //   );

  //   if (addressFieldIndex > -1) {
  //     let tmp = [...generalInfoField?.fields];
  //     tmp.splice(addressFieldIndex + 1, 0, {
  //       customComponent: MondayButtonIcon,
  //       componentProps: {
  //         Icon: <NewTabIcon />,
  //         onClick: () => {
  //           !!addressSelectionInfo?.projectId
  //             ? navigate(`/projects/${addressSelectionInfo?.projectId}`)
  //             : !!addressSelectionInfo?.scheduleId
  //             ? navigate(`/scheduling/${addressSelectionInfo?.scheduleId}`)
  //             : null;
  //         },
  //         name: !!addressSelectionInfo?.projectId
  //           ? "Go to Project"
  //           : !!addressSelectionInfo?.scheduleId
  //           ? "Go to Schedule"
  //           : null,
  //       },
  //     });
  //     generalInfoField.fields = [...tmp];
  //   }
  // }

  return fields;
}
