/**
 *
 * @param {String} recordId
 * @param {Array} projects
 * @param {Array} inspectionTypes - the types of inspections that we get from programFields
 * @param {Function} dispatchRequestData - the pointer to the dispatch function of the request reducer
 */
const serviceInspectionPopulate = ({
  recordId = "",
  projects = [],
  requestType = "",
  inspectionTypes = [],
  dispatchRequestData = () => {},
}) => {
  if (requestType !== "Service Inspection") return;

  /**
   * If there are no projects, we set the inspection options to the default value
   */
  if (!projects?.length) {
    return dispatchRequestData({
      type: "INSPECTION_OPTIONS",
      payload: ["Pre-Installation Inspection"],
    });
  }

  const services =
    projects?.find((el) => el?.projectId === recordId)?.services || {};

  const objectFromArrays = Object.values(services || {}).flat();

  /**
   * If there are no services, we set the inspection options to the default value
   */
  if (!objectFromArrays?.length) {
    return dispatchRequestData({
      type: "INSPECTION_OPTIONS",
      payload: ["Pre-Installation Inspection"],
    });
  }

  /**
   * We get the unique services from the services array of the project
   */
  const uniqueServices = Array.from(
    new Set(objectFromArrays.map((el) => el?.label))
  );

  /**
   * If the unique services array includes the "Hoist" service, we return the inspection types
   * else we filter out the "Hoist" inspection type
   */
  const inspectionFieldOptions = (uniqueServices || [])?.includes("Hoist")
    ? inspectionTypes?.map((el) => el?.typeName)
    : inspectionTypes
        ?.map((el) => el?.typeName)
        ?.filter((el) => el !== "Hoist");

  return dispatchRequestData({
    type: "INSPECTION_OPTIONS",
    payload: inspectionFieldOptions,
  });
};

export default serviceInspectionPopulate;
