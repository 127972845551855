import moment from "moment";
import { CustomSelectBox } from "../components/CustomSelectBox";
import NumberComponent from "../components/NumberComponent";
import {
  ChargedAllDaysIcon,
  ChargedSomeDaysIcon,
  NotCharged,
} from "../../../../../../DynamicView/src";
import { calculateStatusForEstimationAndRental } from "./calculateStatusForEstimationAndRental";
import { Tooltip } from "antd";
import { formatCurrency } from "../../../../../../utils";
import { calculateTaxAmountForRowData } from "./calculateTaxAmountForRowData";
import { calculateTotalForRowData } from "./calculateTotalForRowData";

export const getColumnDefs = ({ visible }) => [
  {
    headerName: "Select",
    enableColResize: false,
    menuTabs: false,
    sortable: false,
    maxWidth: 80,
    cellRendererFramework: ({ data }) => CustomSelectBox({ data }),
  },
  {
    headerName: "Label",
    field: "label",
    resizable: true,
    maxWidth: 150,
    cellRendererFramework: ({ value: label }) => (
      <div className="cellContainer">{label}</div>
    ),
  },
  {
    headerName: "Start Period",
    field: "information",
    maxWidth: 150,
    cellRendererFramework: ({ value: information, data }) => (
      <div className="cellContainer">
        {moment(
          visible?.categoryFrom === "Rental"
            ? data?.startDate
            : information?.start
        ).format("MM/DD/YYYY")}
      </div>
    ),
  },
  {
    headerName: "End Period",
    field: "information",
    maxWidth: 150,
    cellRendererFramework: ({ value: information, data }) => (
      <div className="cellContainer">
        {moment(
          visible?.categoryFrom === "Rental" ? data?.endDate : information?.end
        ).format("MM/DD/YYYY")}
      </div>
    ),
  },
  {
    headerName: "No.",
    field: "information",
    suppressMenu: true,
    maxWidth: 70,
    cellRendererFramework: NumberComponent,
  },
  {
    headerName: "Charge Status",
    field: "data",
    maxWidth: 150,
    suppressMenu: true,
    cellRendererFramework: ({ value, data }) => {
      const iconForCharges = {
        "Not Charged": <NotCharged />,
        "Practically Charged": <ChargedSomeDaysIcon />,
        "Fully Charged": <ChargedAllDaysIcon />,
      };
      const chargeStatus = calculateStatusForEstimationAndRental(
        value,
        data,
        visible
      );

      return (
        <div className="chargeStatusContainer">
          <Tooltip
            title={
              chargeStatus?.title !== "Not Charged" ? (
                <div>
                  <div>
                    {visible?.categoryFrom === "Invoice"
                      ? `Paid: ${chargeStatus?.paid}`
                      : `Charged: ${chargeStatus?.charged}`}
                  </div>
                  <div>
                    {chargeStatus?.credited &&
                      `Credited: ${chargeStatus?.credited}`}
                  </div>
                </div>
              ) : (
                ""
              )
            }
          >
            <div className={chargeStatus?.title}>
              {iconForCharges[chargeStatus.title]}
            </div>
          </Tooltip>
        </div>
      );
    },
  },
  ...(visible?.categoryFrom === ("Estimation" || "Schedule Of Value")
    ? [
        {
          headerName: "Invoiced %",
          field: "totalInvoicedAmount",
          suppressMenu: true,
          maxWidth: 150,
          cellRendererFramework: ({ value: totalInvoicedAmount, data }) => {
            return (
              <Tooltip title={formatCurrency(totalInvoicedAmount)}>
                <div className="cellContainer">
                  {parseFloat(
                    (totalInvoicedAmount /
                      calculateTotalForRowData(
                        data?.data,
                        data,
                        visible?.categoryFrom
                      )) *
                      100 || 0
                  ).toFixed(2)}
                  %
                </div>
              </Tooltip>
            );
          },
        },
      ]
    : []),
  {
    headerName: "Description",
    field: "description",
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    cellRendererFramework: ({ value }) => value,
  },
  ...(visible?.categoryFrom !== "Requisition"
    ? [
        {
          headerName: "Tax Amount",
          field: "data",
          maxWidth: 150,
          cellRendererFramework: ({ value: data, data: rData }) => (
            <div
              style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                fontSize: 16,
                fontWeight: 700,
                color: "#4F4F4F",
              }}
            >
              {formatCurrency(
                visible?.categoryFrom === "Invoice"
                  ? rData?.totalInvoiceAmount || 0 - rData?.subtotal || 0
                  : visible?.categoryFrom === "Requisition"
                  ? 0
                  : calculateTaxAmountForRowData(
                      data,
                      rData,
                      visible?.categoryFrom === "Rental",
                      visible
                    )
              )}
            </div>
          ),
        },
      ]
    : []),
  {
    headerName: "Total",
    field: "data",
    maxWidth: 150,
    cellRendererFramework: ({ value: data, data: rData }) => (
      <div
        style={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          fontSize: 16,
          fontWeight: 700,
          color: "#4F4F4F",
        }}
      >
        {formatCurrency(
          calculateTotalForRowData(data, rData, visible?.categoryFrom)
        )}
      </div>
    ),
  },
];
