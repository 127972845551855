import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";
import { formatCurrency } from "../../../../utils";
import { getAmountPerService } from "../../../addWidgetsModalData";

export const estimations = (updatedDynamicStates, IdHandler) => {
  return {
    engProgress: () => ({
      title: "Engineer Progress",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "engProgress",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: `${a?.engProgress || 0}%`,
            color: "#6D8299",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
    archProgress: () => ({
      title: "Architect Progress",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "archProgress",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: `${a?.archProgress || 0}%`,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    estSTATUS: () => ({
      title: "Estimations Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "estSTATUS",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.estSTATUS,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //estSTATUS
    archAssignedTo: () => ({
      title: "Architect Assigned To",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "archAssignedTo",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.archAssignedTo,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //archAssignedTo
    engAssignedTo: () => ({
      title: "Engineer Assigned To",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "engAssignedTo",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.engAssignedTo,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //engAssignedTo
    statusByArch: () => ({
      title: "Status By Architect",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByArch",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.statusByArch,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //statusByArch
    statusByEng: () => ({
      title: "Status By Engineers",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByEng",
        secondaryField: "jobSiteAddress",
        // field: "jobSiteAddress",
        // secondaryField: "statusByEng",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.statusByEng,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }), //statusByEng

    projectExecutive: () => ({
      title: "Project Executive",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "projectExecutive",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: a?.projectExecutive,
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    amount: () => ({
      title: "Total Estimation Amount",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "amount",
        secondaryField: "jobSiteAddress",
        id: IdHandler,
        filterType: { type: "dashboardId" },
        noTap: true,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.jobSiteAddress,
            value: formatCurrency(
              parseFloat(getTotalServicesPrice(a?.services))
            ),
            color: "#ECDE7D",
            id: a?.estimationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    amountForServices: () => ({
      title: "Total Estimation Amount per Service",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Estimations",
        field: "amountForServices",
        secondaryField: "services",
        id: IdHandler,
        statusData: Object.entries(
          getAmountPerService(updatedDynamicStates?.[IdHandler] || [])
        ).map(([label, value]) => ({
          label,
          value: formatCurrency(value),
          color: "#ECDE7D",
        })),
      },
      id: IdHandler,
      width: "790px",
    }),
  }; //Estimations
};
