export const styleSettings = {
  titlesFontfontFamily: "sans-serif",
  titlesBg: "#E5E5E6",
  titlesColor: "101010",
  titlesFontSize: "9px",
  titlesFontWeight: "800",
  textColor: "#1c1c1c",
  textFontFamily: `'Courier New', Courier, monospace`,
  textFontSize: "8px",
  textFontWeight: "200",
  borderColor: "#454545",
};

export const styleInvoice = {
  invoicePaper: {
    boxSizing: "border-box",
    width: "612px",
    height: "791px",
    padding: "20px 0",
    boxShadow: "0 0 1px  black",
    display: "grid",
    gridTemplateColumns: "590px",
    gridTemplateRows: "auto",
    justifyContent: "center",
    alignContent: "start",
  },
  invoiceTable: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    maxHeight: "481px",
    borderLeft: `1px solid ${styleSettings.borderColor}`,
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto",
    gap: 0,
  },
  headerTable: {
    gridArea: "1/1/2/-1",
    width: "100%",
    height: "auto",
    backgroundColor: styleSettings.titlesBg,
    display: "grid",
    gridTemplateColumns: "95px 209px 95px 95px 95px",
    gridTemplateRows: "100%",
  },
  headerItem: {
    gridArea: "auto",
    width: "100%",
    height: "10px",
    borderTop: `1px solid ${styleSettings.borderColor}`,
    borderBottom: `1px solid ${styleSettings.borderColor}`,
    borderRight: `1px solid ${styleSettings.borderColor}`,
    padding: "10px 0",
    fontFamily: styleSettings.titlesFontfontFamily,
    color: styleSettings.titlesColor,
    fontSize: styleSettings.titlesFontSize,
    fontWeight: styleSettings.titlesFontWeight,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
  datatableCont: {
    gridArea: "2/1/3/-1",
    width: "100%",
    height: "auto",
    maxHeight: "461px",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto",
    alignContent: "start",
  },
  itemTable: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    maxHeight: "461px",
    display: "grid",
    gridTemplateColumns: "95px 209px 95px 95px 95px",
    gridTemplateRows: "fit-content",
  },
  itemData: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    padding: "5px 5px",
    borderBottom: `1px solid ${styleSettings.borderColor}`,
    borderRight: `1px solid ${styleSettings.borderColor}`,
    display: "grid",
    gridTemplateColumns: "95%",
    gridTemplateRows: "auto",
    justifyContent: "center",
    textAlign: "start",
  },
  itemDataStart: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    lineHeight: "8px",
    padding: "5px 5px",
    borderBottom: `1px solid ${styleSettings.borderColor}`,
    borderRight: `1px solid ${styleSettings.borderColor}`,
    fontFamily: styleSettings.textFontFamily,
    fontSize: styleSettings.textFontSize,
    fontWeight: styleSettings.textFontWeight,
    overflowWrap: "brewak-word",
    textAlign: "center",
    marginBottom: 0,
  },
  lineText: {
    gridArea: "auto",
    width: "100%",
    height: "fit-content",
    lineHeight: "8px",
    fontFamily: styleSettings.textFontFamily,
    fontSize: styleSettings.textFontSize,
    fontWeight: styleSettings.textFontWeight,
    textAlign: "start",
    marginBottom: 1,
  },
  itemDataEnd: {
    gridArea: "auto",
    width: "100%",
    height: "auto",
    lineHeight: "8px",
    padding: "5px 5px",
    borderBottom: `1px solid ${styleSettings.borderColor}`,
    borderRight: `1px solid ${styleSettings.borderColor}`,
    fontFamily: styleSettings.textFontFamily,
    fontSize: styleSettings.textFontSize,
    fontWeight: styleSettings.textFontWeight,
    textAlign: "end",
    marginBottom: 0,
  },
  highLight: {
    color: "red",
  },
};
