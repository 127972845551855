import dayjs from "dayjs";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { TrashIcon } from "../../../../Communication/assets";
import { EyeIcon, LogsIcon } from "../../../../DynamicView/src";
import { XIcon } from "../../../../Communication/assets";
import {
  EmailIcon,
  ExportPdfIcon,
  FilledEyeIcon,
} from "../../../../../../assets";
import { Tooltip } from "antd";
import { viewSafetyUsed } from "../../../Inspections/Modal/NewSafetyInspection";
import { viewClaimsModal } from "../NewPersonalInjuryModal";
import { TasksIconNew } from "../../../../../../icons";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { Notes } from "../../../../../commonComponents/Notes/Notes";

function populateFields(form) {
  form.setFieldsValue({
    dateOfInjury: dayjs("2023-07-05T10:07:19.781Z"),
    timeOfInjury: dayjs("2023-07-05T13:00:00.774Z"),
    addressOfInjury: "180 Water Street, New York, NY, USA",
    "exposureLocation/AreaOfInjury": "Location",
    fullNameOfInjuredIndividual: "Some Dude",
    cellPhoneNumberOfInjuredIndividual: "13213213213",
    jobTitleOfInjuredIndividual: "Service Worker",
    weatherDetails: "Sunny",
    serviceBeingDone: "Other",
    typeOfAccident: "Electrocutions",
    bodyOfInjuryAffected: "Neck",
    typeOfEquipmentInvolved: "Other",
    immediateActionsTakenFollowingTheIncident: "Uber",
    didAnyOfTheFollowingContributeToTheOccurrence:
      "Injured worker failed to use available equipment or toll",
    didInjuredIndividualReturnToWorkOnTheSameDay: "No",
    "name(s)OfWitnesses": "Some other dude",
    reportPreparedOn: dayjs("2023-07-05T10:09:14.306Z"),
    reportPreparedBy: "Some Other Other Dude",
    otherService: "Some other service",
    installOrRemoval: "Install",
    "wasThereMoreThanOne(1)CrewOn-site": "Yes",
    fullNameOfForeman: "06672b4f-0d9f-4aa5-9433-bee69ccecdf3",
    areThereCamerasOnSiteOrAround: "Yes",
    otherEquipment: "Other Equipment",
    "wereConstructionMaterialsInvolved?": "Yes",
    descriptionOfInjury: "<p>some description</p>",
    statementOfWitnesses: "<p>Some statement</p>",
    wasAnyoneElseAffectedByThisInjury: "<p>N/A</p>",
    ifYesExplain: "<p>Yes</p>",
    "rootCauseOfAccident(IfAbleToDetermine)": "<p>Some Cause</p>",
  });
}

function modalFooter({
  onCancel = () => {},
  onSave = () => {},
  form,
  rowObject,
  onDelete = () => {},
  onShowLogs = () => {},
  onGeneratePdf = () => {},
  onEmail = () => {},
  defaultId,
  claimsUsingSafety = [],
  selectedSafety = [],
  hasReport = false,
  onReportView = () => {},
  setTasksVisible = () => {},
  selectedTeam,
  isDarkMode,
  changes,
  safetyName,
}) {
  return [
    <>
      {!!defaultId && (
        <div className="buttonContainer">
          <MondayButton
            {...{
              Icon: <TrashIcon />,
              className: "mondayButtonRed",
              onClick: onDelete,
            }}
          >
            Delete
          </MondayButton>
          <MondayButton
            {...{
              Icon: <LogsIcon />,
              className: "mondayButtonBlue",
              onClick: onShowLogs,
            }}
          >
            Show Logs
          </MondayButton>
          <MondayButton
            {...{
              Icon: <ExportPdfIcon />,
              className: "mondayButtonBlue",
              onClick: onGeneratePdf,
            }}
          >
            Export To Pdf
          </MondayButton>
          {hasReport && (
            <MondayButton
              {...{
                Icon: <ExportPdfIcon />,
                className: "mondayButtonBlue",
                onClick: onReportView,
              }}
            >
              View Report
            </MondayButton>
          )}
          <MondayButton
            {...{
              Icon: <TasksIconNew />,
              className: "mondayButtonBlue",
              onClick() {
                setTasksVisible(true);
              },
            }}
          >
            Tasks
          </MondayButton>
          <MondayButton
            {...{
              Icon: <EmailIcon />,
              className: "mondayButtonBlue",
              onClick: onEmail,
            }}
          >
            Email
          </MondayButton>
          <div className="safety-notes-button">
            <Notes
              {...{
                noteModalName: safetyName,
                rowId: defaultId,
                topicCategory: "Personal Injury",
              }}
            />
          </div>
          {claimsUsingSafety.length || selectedSafety.length ? (
            <Tooltip
              title={
                claimsUsingSafety.length
                  ? "View Claims using this Safety"
                  : selectedSafety.length
                  ? "View used Safety Report"
                  : ""
              }
            >
              <MondayButton
                {...{
                  Icon: <FilledEyeIcon style={{ height: 23, width: 23 }} />,
                  className: "mondayButtonBlue previewButton",
                  onClick: () =>
                    claimsUsingSafety.length
                      ? viewClaimsModal(claimsUsingSafety)
                      : selectedSafety.length
                      ? viewSafetyUsed(selectedSafety)
                      : null,
                }}
              ></MondayButton>
            </Tooltip>
          ) : null}
        </div>
      )}
      <div className="buttonContainer">
        <DynamicAvatars
          {...{
            title: "Team",
            isDarkMode: isDarkMode,
            members: selectedTeam.reduce(
              (acc, { members }) => [...acc, ...members],
              []
            ),
          }}
        />
        <MondayButton
          {...{
            Icon: <XIcon />,
            className: "mondayButtonRed",
            onClick: onCancel,
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          {...{
            Icon: <TickIcon />,
            onClick: onSave,
            disabled: !changes || claimsUsingSafety.length > 0,
          }}
        >
          Save
        </MondayButton>
      </div>
    </>,
  ].filter(Boolean);
}

export default modalFooter;
