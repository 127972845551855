import { Form, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import { replaceArrayElement } from "../../../../../../utils";
import { useRedux } from "../../../../hooks/useRedux";
import { useTakeOffContext } from "../../context";
import { AddonsPreview, Footer, PricingRow, TotalsSection } from "./components";
import { HoistPriceCalculatorContext } from "./context";
import { rowFields } from "./data";
import FloorDetails from "./components/FloorDetails/FloorDetails";
import { FloorDetailsProvider } from "./components/FloorDetails/context/floorDetailsContext";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { MondayButton } from "../../../../../commonComponents";

const HoistPriceCalculatorModalContent = ({ onCancel, elevation, indices }) => {
  // Existing elevation pricing details
  const { pricing, type: elevationHoistType, items } = elevation;
  const [gridData] = useRedux("takeOffTableData");
  const [configurationByType, setConfigurationByType] = useState({});
  const { serviceIndex = 0, optionIndex = 0, elevationIndex = 0 } = indices;
  console.log("elevation", elevation);
  const currHoist = gridData?.[serviceIndex] || {};
  const addonsPreviewRef = useRef();
  console.log(currHoist, "currHoist");
  // Form instance and it's destructuring
  const [form] = Form.useForm();
  const { getFieldValue, getFieldsValue, setFieldsValue } = form;

  // Take-off context values
  const contextValues = useTakeOffContext();
  const { pricingData, updateStateAndSave } = contextValues;

  const {
    taxRate = 0,
    isTaxable = false,
    priceScheme = {},
  } = gridData?.find((service) => service?.serviceId === currHoist?.serviceId);

  // Hoist Pricing Data and it's destructuring
  const hoistPricingData = pricingData.find(
    ({ serviceId }) => serviceId == currHoist?.serviceId
  );
  const { pricingObject } = hoistPricingData;
  const { defaultPriceSchemeId, priceSchemes = [] } = pricingObject || {};

  // State of the active price scheme set by the selector
  const [activePriceSchemeId, setActivePriceSchemeId] = useState(
    defaultPriceSchemeId || priceSchemes[0]?.id
  );

  // destructures the active price scheme
  // const { configurationByType = {} } =
  //   priceSchemes.find(({ id }) => id === activePriceSchemeId) ||
  //   priceScheme ||
  //   {};

  const configuration = configurationByType[elevationHoistType];

  // Total price of addons
  const addonsTotal = forceToNumber(
    elevation?.floorAddonsPriceConfig?.totalities?.priceWithoutTax
  );
  const rentAddons = forceToNumber(
    elevation?.floorAddonsPriceConfig?.totalities?.rentWithoutTax
  );
  // const addonsTotal = items
  //   .flatMap(({ addons = [] }) =>
  //     addons.filter(
  //       ({ name, price }) =>
  //         !!price &&
  //         !configuration
  //           ?.map(({ formItemName }) => formItemName)
  //           .includes(name.id)
  //     )
  //   )
  //   .reduce((acc, { price }) => (acc += +price), 0);

  // Calculates subtotal, tax amount and total
  const getTotals = () => {
    const getConfigSum = (whichValue) =>
      configuration
        ?.map(({ formItemName }) =>
          getFieldValue(`${formItemName}-${whichValue}`)
        )
        .reduce((acc, curr) => (acc += curr), 0);

    const configSubtotal = getConfigSum("price");
    const rentalsTotal = getConfigSum("rental");

    // const subtotal = configSubtotal + rentalsTotal + addonsTotal;
    const subtotal = configSubtotal + addonsTotal;

    const taxAmount = isTaxable ? subtotal * taxRate : 0;

    return {
      taxRate,
      taxAmount,
      addonsTotal,
      rentalsTotal: rentAddons + rentalsTotal,
      price: subtotal,
      totalPrice: subtotal + taxAmount,
    };
  };

  // Saves data in elevation
  const onSave = () => {
    const { serviceIndex, optionIndex } = indices;
    const service = gridData[serviceIndex];

    const configurationFormNames = configuration?.map(
      ({ formItemName }) => formItemName
    );

    console.log("onSave", getFieldsValue());

    const updatedElevation = {
      ...elevation,
      ...getTotals(),
      pricing: {
        priceSchemeId: activePriceSchemeId,

        // "values" is used when reopening <HoistPriceCalculatorModal />
        values: getFieldsValue(),

        // "overview" is used in <HoistPricingInfoModal />
        overview: configuration?.map(
          ({ label, formItemName, type, config }) => [
            {
              label,
              type,
              value:
                type === "select"
                  ? config.find(
                      ({ value }) => value == getFieldValue(formItemName)
                    )?.label
                  : getFieldValue(formItemName),
            },
            ...rowFields?.map((field) => ({
              label: field,
              value: getFieldValue(`${formItemName}-${field}`),
            })),
          ]
        ),

        // "addonsOverview" is also used in <HoistPricingInfoModal />
        // addonsOverview: addonsPreviewRef.current.getValue(),
      },

      // updates addons price
      items: elevation?.items?.map?.((pli) => ({
        ...pli,
        addons: pli?.addons?.map?.((addon) =>
          configurationFormNames.includes(addon?.name?.id)
            ? {
                ...addon,
                price:
                  +(addon.units || 0) * +getFieldValue(`${addon.name.id}-ppu`),
                totalPrice:
                  +(addon.units || 0) * +getFieldValue(`${addon.name.id}-ppu`),
              }
            : addon
        ),
      })),
    };

    const updatedService = {
      ...service,
      serviceOptions: service.serviceOptions?.map((option, idx) =>
        idx === optionIndex
          ? replaceArrayElement(option, updatedElevation, "elevationId")
          : option
      ),
    };

    console.log("updatedService", updatedService);

    updateStateAndSave(
      replaceArrayElement(gridData, updatedService, "serviceId")
    );
    onCancel();
  };

  const welcomePerformer = () => {
    let OLD_CONFIG = { id: null, values: [] };
    const { priceSchemeId = null, values = [] } = pricing || {};
    if (!!priceSchemeId) {
      OLD_CONFIG.id = priceSchemeId;
      OLD_CONFIG.values = values;
    } else {
      // first, we check if it is dual / single elevator (Hoist)
      console.log("firstCheck", { elevation, values });
      if (elevation?.type === "dual") {
        let dualConfig = priceSchemes?.find((e) => e?.type === "dual") || null;
        if (dualConfig) {
          OLD_CONFIG.id = dualConfig?.id;
          OLD_CONFIG.values = dualConfig?.configurationByType?.dual;
        }
      } else {
        let singleConfig =
          priceSchemes?.find((e) => e?.type === "single") || null;
        if (singleConfig) {
          OLD_CONFIG.id = singleConfig?.id;
          OLD_CONFIG.values = singleConfig?.configurationByType?.single;
        }
      }
    }
    const { configurationByType = {} } =
      priceSchemes.find(({ id }) => id === activePriceSchemeId) ||
      priceScheme ||
      {};
    console.log("configurationByType", OLD_CONFIG);
    setConfigurationByType(configurationByType);
    setActivePriceSchemeId(OLD_CONFIG.id);
    // let formValues = {};
    // OLD_CONFIG.values.forEach((e) => {
    //   formValues[`${e.formItemName}`] = 0;
    // });
    // setFieldsValue(formValues);
  };

  useEffect(() => {
    welcomePerformer();
  }, []);

  // useEffect(() => {
  //   if (!pricing) return;

  //   const { priceSchemeId, values } = pricing;
  //   setActivePriceSchemeId(priceSchemeId);
  //   setFieldsValue(values);
  // }, [pricing]);

  const onCheck = () => {
    console.log("onCheck", getFieldsValue());
  };
  console.log("hoistCalculator", {
    form,
    elevation,
    addonsTotal,
    configuration,
    getTotals,
    pricingObject,
    activePriceSchemeId,
  });

  return (
    <HoistPriceCalculatorContext.Provider
      value={{ form, elevation, addonsTotal, configuration, getTotals }}
    >
      <MondayButton onClick={onCheck}>Check</MondayButton>
      {!!configuration && (
        <Form form={form} colon={false}>
          <div className="hoist-price-calculator-modal-content">
            <Select
              className="hoist-price-scheme-selector"
              value={activePriceSchemeId}
              onSelect={setActivePriceSchemeId}
              options={priceSchemes.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />

            <div className="pricing-section">
              {configuration?.map((config) => (
                <PricingRow {...{ config, key: config.formItemName }} />
              ))}
            </div>

            <div className="total-cards-container">
              {/* <AddonsPreview ref={addonsPreviewRef} />{" "} */}
              <FloorDetailsProvider>
                <FloorDetails ref={addonsPreviewRef} indices={indices} />
              </FloorDetailsProvider>
              <TotalsSection />
            </div>

            <Footer onCancel={onCancel} onSave={onSave} />
          </div>
        </Form>
      )}
    </HoistPriceCalculatorContext.Provider>
  );
};

export default HoistPriceCalculatorModalContent;
