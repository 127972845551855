import React, { useMemo, useState } from "react";
import { formatCurrency } from "../../../../../../../../../utils";
import "./AgGridMathful.scss";
import AnimatedNumber from "react-animated-number";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { forceToNumber } from "../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

function AgGridMathful({ darkMode, event, style = {} }) {
  const [show, setShow] = useState(false);
  const totalities = useMemo(() => {
    if (event) {
      let cellRanges = event.api.getCellRanges();
      console.log("cellRanges", cellRanges);
      if (cellRanges) {
        setShow(true);
        var sum = 0;
        var average = 0;
        var min = 0;
        var max = 0;
        var count = 0;
        cellRanges.forEach(function (range) {
          // get starting and ending row, remember rowEnd could be before rowStart
          var startRow = Math.min(
            range?.startRow?.rowIndex,
            range?.endRow?.rowIndex
          );
          var endRow = Math.max(
            range?.startRow?.rowIndex,
            range?.endRow?.rowIndex
          );
          for (var rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
            range?.columns?.forEach(function (column) {
              var rowModel = event.api.getModel();
              var rowNode = rowModel.getRow(rowIndex);
              var value = event.api.getValue(column, rowNode);
              if (typeof value === "number") {
                sum += value;
                min = min === 0 ? value : Math.min(min, value);
                max = Math.max(max, value);
                count++;
              }
            });
          }
        });
        average = sum / count || 0;
        return { sum, average, min, max };
      }
      return { sum: 0, average: 0, min: 0, max: 0 };
    }
  }, [event]);

  return show ? (
    <div
      className={darkMode ? "dark-ag-grid-mathful" : "light-ag-grid-mathful"}
      style={style}
    >
      <div className="sum">
        Sum:{" "}
        <AnimatedNumber
          style={{
            transition: "0.8s ease-out",
            transitionProperty: "background-color, color, opacity",
            color: "#7AC14D",
          }}
          value={forceToNumber(totalities?.sum)}
          formatValue={(value) => formatCurrency(value)}
        />
      </div>
      <div className="average">
        Average:{" "}
        <AnimatedNumber
          style={{
            transition: "0.8s ease-out",
            transitionProperty: "background-color, color, opacity",
            color: "#FCC94A",
          }}
          value={forceToNumber(totalities?.average)}
          formatValue={(value) => formatCurrency(value)}
        />
      </div>
      <div className="max">
        Max:{" "}
        <AnimatedNumber
          style={{
            transition: "0.8s ease-out",
            transitionProperty: "background-color, color, opacity",
            color: "#7AC14D",
          }}
          value={forceToNumber(totalities?.max)}
          formatValue={(value) => formatCurrency(value)}
        />
      </div>
      <div className="min">
        Min:{" "}
        <AnimatedNumber
          style={{
            transition: "0.8s ease-out",
            transitionProperty: "background-color, color, opacity",
            color: "#F04F4E",
          }}
          value={forceToNumber(totalities?.min)}
          formatValue={(value) => formatCurrency(value)}
        />
      </div>
      <span className="hide-btn" onClick={() => setShow(false)}>
        Hide
      </span>
    </div>
  ) : (
    ""
  );
}

export default AgGridMathful;
