export const generateInputFields = () => [
  {
    label: "Email Address",
    onContextMenu: () => false,
    name: "contactEmail",
    type: "text",
    placeholder: "Email Address...",
    className: "inputForm",
    required: true,
    rules: [
      { required: true, type: "email", message: "Please enter a valid email" },
    ],
  },
];

export const generateContactInputFields = ({ contactRoles = [] }) => [
  {
    label: "First Name",
    onContextMenu: () => false,
    name: "additionalContactFirstName",
    type: "text",
    placeholder: "First Name...",
    className: "inputForm",
  },
  {
    label: "Last Name",
    onContextMenu: () => false,
    name: "additionalContactLastName",
    type: "text",
    placeholder: "Last Name...",
    className: "inputForm",
  },
  {
    label: "Contact Role",
    onContextMenu: () => false,
    name: "additionalContactRole",
    type: "select",
    placeholder: "Contact Role...",
    className: "inputForm",
    customOptions: contactRoles,
  },
  {
    label: "Email Address",
    onContextMenu: () => false,
    name: "additionalContactEmail",
    type: "text",
    placeholder: "Email Address...",
    className: "inputForm",
    required: true,
    rules: [
      { required: true, type: "email", message: "Please enter a valid email" },
    ],
  },
  {
    label: "Phone",
    onContextMenu: () => false,
    name: "additionalContactPhone",
    type: "phone",
    placeholder: "Phone...",
    className: "inputForm",
  },
  {
    label: "Phone Extension",
    onContextMenu: () => false,
    name: "additionalContactPhoneExtension",
    type: "text",
    placeholder: "Phone Extension...",
    className: "inputForm",
  },
  {
    label: "Mobile",
    onContextMenu: () => false,
    name: "additionalContactMobile",
    type: "phone",
    placeholder: "Mobile...",
    className: "inputForm",
  },
  {
    label: "Shipping Address",
    onContextMenu: () => false,
    name: "additionalContactShippingAddress",
    type: "placesautocomplete",
    placeholder: "Shipping Address...",
    className: "inputForm",
  },
];

export const CONTACT_MESSAGE =
  "Primary Contact has already been created. You can proceed with the next steps, or if needed, create additional contacts as required.";

export const style = {
  information: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    width: "100%",
    height: "100%",
  },
  additionalContactContainer: {
    marginTop: "20px",
    padding: "20px",
  },
  additionalContactItems: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
  },
  closeIcon: {
    margin: "10px",
    alignSelf: "flex-end",
    padding: "5px",
    width: "24px",
    height: "24px",
    border: "1px solid black",
    borderRadius: "5px",
    cursor: "pointer",
  },
};
