import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Includes from "./includes/Includes";
import Excludes from "./excludes/Excludes";
import { useRedux } from "../../../../hooks/useRedux";

function IncludesExcludes({ service, serviceIndex, updateStateAndSave }) {
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const [gridData] = useRedux("takeOffTableData");
  const [agGridKey, setAgGridKey] = useState(Math.random());

  const particulateOptions = useMemo(() => {
    const items = {
      includes: [],
      excludes: [],
    };
    if (!!service.label) {
      const currentService = serviceDefinitions.find(
        ({ serviceName = "" }) => serviceName === service?.label
      );
      if (!!currentService) {
        const { includes = [], excludes = [] } = currentService;
        items.includes = includes?.map(({ name = "", id = "" }) => ({
          label: name?.replace(/<\/?[^>]+(>|$)/g, ""),
          onClick: () => console.log("Action 1 clicked"),
          value: id,
        }));
        items.excludes = excludes?.map(({ name = "", id = "" }) => ({
          label: name?.replace(/<\/?[^>]+(>|$)/g, ""),
          onClick: () => console.log("Action 1 clicked"),
          value: id,
        }));
        return items;
      }
      return items;
    }
    return items;
  }, [serviceDefinitions, service?.label]);

  const props = {
    particulateOptions,
    updateStateAndSave,
    gridData,
    service: gridData?.find((el, i) => el?.serviceId === service?.serviceId),
    serviceIndex,
    agGridKey,
  };
  console.log("my props", props);

  return (
    <>
      <div className="addonsContainer" style={{ height: "100%" }}>
        {" "}
        <Includes {...props} />
        <Excludes {...props} />
      </div>{" "}
    </>
  );
}

export default React.memo(IncludesExcludes);
