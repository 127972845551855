import { useState } from "react";
import { Modal, Tooltip } from "antd";
import { MondayButton } from "../commonComponents";
import "./VideoModal.scss";
import PlayVideoTutorial from "../pages/Settings/settingsComponents/Pricing/subcomponents/PricingTour/PlayVideoTutorial";
import { useSelector } from "react-redux";
import htmlParser from "../../utils/htmlParser";
import { CloseOutlined } from "@ant-design/icons";

const VideoModal = ({ modalOpen, setModalOpen, title }) => {
  const { programFields = [] } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState();

  const videos = [];

  const videoTutorials = programFields.find(
    (item) => item.fieldName === "Portal Video Tutorials"
  );

  if (videoTutorials) {
    videoTutorials.fieldOptions.forEach((category) => {
      if (category.subCategories) {
        category.subCategories.forEach((subCat) => {
          if (subCat.videos) {
            subCat.videos.forEach((video) => {
              if (
                video.hasOwnProperty("categories") &&
                video.categories.includes(title)
              ) {
                videos.push({
                  name: video.videoName,
                  link: video.videoLink,
                  category: subCat.subCategoryName,
                  length: video.lengthVideoInMin,
                  description: video.description,
                });
              }
            });
          }
        });
      }
    });
  }

  const handleClick = (video) => {
    setSelectedVideo(video);
    setShowVideoTutorial(true);
  };

  return (
    <>
      <Modal
        title="Video Tutorials"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
        className={`videoModal ${isDarkMode && "videoModal-dark"}`}
      >
        <div className="videos">
          {videos?.map((video, index) => (
            <Tooltip
              title={video.description ? htmlParser(video.description) : ""}
            >
              <div className="video-container" key={index}>
                <div className="video" onClick={() => handleClick(video)}>
                  <div className="video-icon"></div>
                  <div className="video-info">
                    <h1 className="title">{video.name}</h1>
                    <div className="body">
                      <span className="category-name">{video.category}</span>
                      <div className="circle" />
                      <span className="video-length">
                        Video length: {video.length}min
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      </Modal>
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            open: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: selectedVideo.link,
            title: selectedVideo.name,
          }}
        />
      )}
    </>
  );
};

export default VideoModal;
