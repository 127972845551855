import React from "react";
import "./styles.scss";

class StatusPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {
        count: 0,
        sum: 0,
      },
    };
  }

  setVisible(visible) {
    this.setState({ visible });
  }

  isVisible() {
    return this.state.visible;
  }

  render() {
    if (this.state.visible) {
      return (
        <div className="statusPanelContainer">
          <div>
            <span className="statusPanelComponent">
              Average:{" "}
              <span>
                {(
                  Math.round(
                    (this.state.data.sum / this.state.data.count) * 100
                  ) / 100
                ).toString()}
              </span>
              Count: <span>{this.state.data.count}</span>
              Sum: <span>{this.state.data.sum}</span>
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default StatusPanel;
