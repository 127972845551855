import { message } from "antd";
import { wsRefGetter } from "../../App";
import { Buffer } from "buffer";

/**
 * Function that handles the message received
 * @param {MessageEvent<{request: string, body:any}>} event
 * @param {WebSocket} notificationWS
 */
function messageHandlerFunction(event, notificationWS) {
  try {
    const message = JSON.parse(event?.data);

    if (
      message?.body?.originUrl ===
      `/${
        notificationWS.url.split("/")[notificationWS.url.split("/").length - 1]
      }`
    ) {
      if (message.request === "notificationSent") {
        const {
          body: { notificationObj },
        } = message;
        return notificationObj || "notificationSent";
      } else if (message.request === "error") {
        return false;
      }
    }
    return false;
  } catch (err) {
    console.error("Error parsing WebSocket message:", err);
    return false;
  }
}

/**
 * @typedef {Record<string, any>} TemplateParams
 *
 * @typedef UsersInfo
 * @property {string} userName - nameOfUser of the logged user
 * @property {string} currentUser - cognitoId of the logged user
 * @property {string[]} cognitos - Receiving users
 *
 * @param {String} topic - Example "10"
 * @param {String} action - Example "onCreation"
 * @param {[TemplateParams, UsersInfo]} actionData - array with Objects,
 *     First object should contain keys included in template content or other keys.
 *     Second object should contain keys userName:"Name of user", currentUser:"cognito ids of user".
 * @param {String} id - id if it is included in template {{id}}
 */
const broadcastNotification = (topic, action, actionData, id) => {
  // Log input parameters

  return new Promise((resolve, reject) => {
    const notificationWS = wsRefGetter();

    /**
     * THE RESOLVE FUNCTION NEEDS TO BE PUT AS AN EVENT LISTENER
     *
     * Adding the resolve function to the "onmessage" property of the websocket
     * overwrites the notification handlers in "NotificationUtil.js"
     *
     * After the notification is broadcasted and we get the
     * confirmation, we make the function remove itself, that
     * way we don't have multiple event listeners for the same thing
     */
    function socketHandler(event) {
      try {
        // Parse the message from the server
        const message = JSON.parse(event.data);

        // Check if it's an error message
        if (message?.request === "error") {
          reject(new Error(message.body.error)); // Reject the promise with the error
        } else {
          resolve(messageHandlerFunction(event, notificationWS));
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
        reject(error);
      } finally {
        notificationWS.removeEventListener("message", socketHandler);
      }
    }

    try {
      // Log size of actionData
      const sizeInKiloBytes =
        Buffer.byteLength(JSON.stringify(actionData), "utf8") / 1024;

      if (sizeInKiloBytes >= 390 && process.env.NODE_ENV !== "production") {
        message.error(
          "Please check broadcastNotification because actionData size is too large " +
            "topic: " +
            topic +
            " action: " +
            action
        );
        resolve(false);
        return;
      }

      // Log missing data cases
      if (!topic || !action || !actionData || actionData?.length < 2) {
        console.error("broadcastNotification missed necessary data!");
        console.error({
          topic,
          action,
          actionDataLength: actionData?.length,
        });
        resolve(false);
        return;
      }

      // Log WebSocket status
      if (notificationWS?.readyState === WebSocket.OPEN) {
        notificationWS.addEventListener("message", socketHandler);

        notificationWS.send(
          JSON.stringify({
            request: "broadcast-notification",
            body: { topic, action, actionData, id },
          })
        );
      } else {
        console.error("broadcastNotification: WS not open");
        resolve(false);
      }
    } catch (e) {
      notificationWS.removeEventListener("message", socketHandler);

      console.error("broadcastNotification Error: ", e);
      reject(e); // Reject the promise with the caught error
    }
  });
};

export default broadcastNotification;
