import { useSelector } from "react-redux";
import { ExportPreview } from "../../../../commonComponents";
import AddRemoveFields from "../../../Safety/SafetyModal/components/SafetyExportPreview/components/AddRemoveFields";
import SortReportFields from "../../../Safety/SafetyModal/components/SafetyExportPreview/components/SortReportFields";
import {
  convertSafetyReport,
  safetyExportApplyFilters,
} from "../../../Safety/SafetyModal/components/SafetyExportPreview/helpers";
import { convertToDoReport, exportToDoData } from "../../forms/todoPdf";

function ToDoExportPreview({
  exportPreview,
  setExportPreview,
  rowObject,
  topicCategories,
}) {
  const { accessToken } = useSelector((state) => state.accessToken);
  return exportPreview ? (
    <ExportPreview
      {...{
        onCancel: () => setExportPreview(false),
        open: exportPreview,
        modalTitle: "Task PDF Preview",
        documentTitle: "To Do " + rowObject?.todoName,
        keyName: "todoView",
        applyFilters: safetyExportApplyFilters,
        asyncInitialData: true,
        getInitialData: async () =>
          await exportToDoData({
            rowObject,
            relatedTo: rowObject?.relatedTo,
            topicCategories,
            accessToken,
          }),
        // convertData: convertSafetyReport,
        convertData: convertToDoReport,
        tabs: [
          {
            key: "add/remove",
            label: "Add/Remove",
            component: AddRemoveFields,
          },
          {
            key: "sort",
            label: "Sort",
            component: SortReportFields,
          },
        ],
        customStyles,
        defaultHeaderSection: [
          {
            text: {
              paragraphs: [
                {
                  text: "Title: " + rowObject?.todoName,
                  class: "safety-title",
                  bold: true,
                  header: true,
                },
              ],
            },
          },
        ],
      }}
    />
  ) : null;
}

export default ToDoExportPreview;

const customStyles = {
  "todo-comment": {
    "padding-left": "1rem",
    "margin-bottom": "1rem",
    gap: "10px",
    display: "grid",
  },
  "todo-comment-list": {
    "list-style": "none",
    padding: 0,
  },
};
