import {
  AutoComplete,
  Button,
  Checkbox,
  message,
  Popover,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { MondayButton } from "../../../../../../../commonComponents";
import { Tick } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import * as moment from "moment";
import {
  SearchIcon,
  InfoIconBlue,
  TruckIcon,
  UserIcon,
  InfoIconWhite,
} from "./HelpingComponents/icons";
import {
  MultipleDispatchesModal,
  NewDispatchModal,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals";
import { useSelector } from "react-redux";
import { CREW_FLEET_POPOVER_TYPE } from "../../CrewTrucksSelectPopover";
import SelectDriverModal from "./SelectDriverModal";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { toOrdinal } from "../../../../helpers/creators";
import LoadableComp from "../../../../../../../SidebarPages/XComponents/LoadableComp";

const TrucksModal = ({
  dayIndex,
  scheduleDay,
  fleetInfo: { fleet, drivers, fleetDispatching },
  inputFieldsPopover,
  setInputFieldsPopover,
  projects,
  scheduleAddress,
  typeOfWork,
  onPopoverVisibilityChanges,
  view,
  onSave,
  setVisible,
  setAbleToSave,
  allFleetActivities,
  allSchedules,
  setToBeScheduled,
  toBeScheduled,
  checkForPrevSchedules,
  scheduleId,
  editMode,
}) => {
  const [filteredTrucks, setFilteredTrucks] = useState([]);
  const [dispatchFormVisible, setDispatchFormVisible] = useState({
    openModal: false,
    data: {},
  });
  const [fleetActivities, setFleetActivities] = useState([]);
  const [pickUpLocation, setPickUpLocation] = useState(null);
  const [dispatchButtonDisabled, setDispatchButtonDisabled] = useState(
    fleetActivities?.length === 0
  );
  const [selectDriverPerFleet, setSelectDriverPerFleet] = useState({});
  const [selectDriverModalVisible, setSelectDriverModalVisible] = useState();
  const [allScheduleDays, setAllScheduleDays] = useState([]);
  const disabled = scheduleDay?.isNotAvailable();

  const handleSelectFleet = (fleetSelected, fleetId) => {
    let fleetActivitiesCopied = structuredClone(fleetActivities);
    if (fleetSelected) {
      const fleetTemp = [filteredTrucks?.length ? filteredTrucks : fleet]
        ?.flat()
        ?.find((t) => t?.fleetId === fleetId);
      let fleetActivity = {
        fleetId: fleetTemp?.fleetId,
        fleetName: fleetTemp?.fleetName,
        dispatchDate: moment(scheduleDay?.startDate).valueOf(),
        routes: [
          {
            departAt: moment(scheduleDay?.startDate).valueOf(),
            arriveBy: moment(scheduleDay?.endDate).valueOf(),
            pickUpLocation:
              pickUpLocation ||
              "2899-2701, Arthur Kill Rd, New York, NY, 10309, USA",
            dropOffLocation: scheduleAddress,
            cargo: "Loaded",
          },
          {
            departAt: moment(scheduleDay?.endDate).valueOf(),
            pickUpLocation: scheduleAddress,
            dropOffLocation:
              pickUpLocation ||
              "2899-2701, Arthur Kill Rd, New York, NY, 10309, USA",
            cargo: "Empty",
          },
        ],
      };
      if (
        allFleetActivities?.some(
          (f) =>
            f?.fleetId === fleetActivity?.fleetId &&
            moment(f?.startingTime).format("MM/DD/YYYY HH:mm") ===
              moment(scheduleDay?.startDate).format("MM/DD/YYYY HH:mm")
        )
      ) {
        message.error("This truck is already scheduled for this day");
      } else {
        fleetActivitiesCopied.push(fleetActivity);
      }
    } else {
      let fleetActivitiesNew = [];
      for (const fa of fleetActivitiesCopied) {
        if (fa.fleetId === fleetId) {
          fa.driverName = undefined;
          fa.driverId = undefined;
          fa.routes = [];
          continue;
        }
        fleetActivitiesNew.push(fa);
      }
      fleetActivitiesCopied = fleetActivitiesNew;
    }
    setFleetActivities(fleetActivitiesCopied);
  };

  const handleSelectDriver = (driver, fleetId) => {
    const fleetActivitiesCopied = fleetActivities?.map((t) => {
      if (t.fleetId === fleetId) {
        return {
          ...t,
          driverId: driver?.driverId,
          driverName: driver?.driverName,
        };
      } else return { ...t };
    });
    if (
      allFleetActivities?.some((f) => {
        const t = fleetActivitiesCopied?.find(
          (fl) => fl?.driverId === driver?.driverId
        );
        f?.fleetId === t?.fleetId &&
          f?.driverId === driver?.driverId &&
          moment(t?.routes[0]?.departAt).isAfter(moment(f?.startingTime)) &&
          moment(t?.routes[1]?.departAt).isAfter(
            moment(f?.timeExitingTheLocation)
          );
      })
    ) {
      message.error("This driver is already scheduled for this day");
    } else setFleetActivities(fleetActivitiesCopied);
  };

  const onCancel = () => {
    setVisible(false);
    setFleetActivities(inputFieldsPopover[dayIndex]?.fleet);
    onPopoverVisibilityChanges(false, false);
  };

  const onSearch = (string) => {
    const filtered =
      fleet?.filter((truck) =>
        truck?.fleetName
          .toLowerCase()
          .trim()
          .includes(string.toLowerCase().trim())
      ) || [];
    setFilteredTrucks([...filtered]);
  };

  const receiveFleetDispatching = (fleetDispatching) => {
    setFleetActivities(fleetDispatching);
  };

  const onConfirm = (fleetActivitiesArg) => {
    setAbleToSave(true);
    scheduleDay.fleet = fleetActivitiesArg;
    onPopoverVisibilityChanges(
      false,
      true,
      scheduleDay,
      dayIndex,
      CREW_FLEET_POPOVER_TYPE.FLEET
    );
  };

  useEffect(() => {
    !!scheduleDay && setFleetActivities(scheduleDay?.fleet);
  }, [scheduleDay]);

  useEffect(() => {
    setDispatchButtonDisabled(fleetActivities?.length === 0);
  }, [fleetActivities]);

  useEffect(() => {
    setAllScheduleDays(allSchedules.map((r) => r?.scheduleDays).flat());
  }, [allSchedules, allFleetActivities]);

  return (
    <>
      {" "}
      <div className="trucks-modal-body">
        <div className="trucks-modal-body-header">
          <div className="trucks-modal-body-header-info">
            <span>
              <InfoIconBlue />
            </span>
            <p>{`${scheduleAddress}  -  ${toOrdinal(
              scheduleDay?.day
            )} Day  -  ${moment(scheduleDay?.startDate).format(
              "MM/DD/YYYY HH:mm"
            )}`}</p>
          </div>
          <div className="trucks-modal-body-header-functionality">
            <div className="trucks-modal-search-div">
              <AutoComplete
                className="trucks-modal-search-comp"
                placeholder="Search filters"
                onChange={(e) => onSearch(e)}
              ></AutoComplete>
              <div className="trucks-modal-search-logo">
                <SearchIcon />
              </div>
            </div>
            <MondayButton
              Icon={<XIcon />}
              className="trucks-modal-button mondayButtonRed"
              onClick={() => onCancel()}
            >
              Cancel
            </MondayButton>
            <MondayButton
              disabled={
                fleetActivities?.some((el) => !el.driverName) ||
                fleetActivities?.some((el) => el?.driverName.length === 0)
              }
              Icon={<Tick />}
              className="trucks-modal-button"
              onClick={() =>
                view === "calendar-view"
                  ? onSave(fleetActivities)
                  : onConfirm(fleetActivities)
              }
            >
              Confirm
            </MondayButton>
          </div>
        </div>
        <div className="trucks-modal-body-content">
          <table className="trucks-modal-body-content-table">
            <thead>
              <tr>
                <th>Select</th>
                <th>Fleet Name</th>
                <th>License Plate</th>
                <th>Info</th>
                <th>Driver</th>
                <th>Dispatch</th>
              </tr>
            </thead>
            <tbody>
              {[filteredTrucks?.length ? filteredTrucks : fleet]
                ?.flat()
                ?.map((item, index) => {
                  const fleetId = item?.fleetId;
                  const fleetActivity = fleetActivities?.find(
                    (t) => t?.fleetId === fleetId
                  );
                  const fleetSelected = fleetActivity !== undefined;
                  const isAvailable = item?.fleetStatus
                    ?.toLowerCase()
                    .includes("active");

                  return (
                    <tr key={item?.fleetId}>
                      <td>
                        <Checkbox
                          disabled={!isAvailable}
                          className="trucks-modal-checkbox"
                          checked={fleetSelected}
                          onChange={
                            (e) =>
                              // !!isAvailable
                              // ? disabled
                              // ? false
                              // :
                              handleSelectFleet(e.target.checked, fleetId)
                            // : false
                          }
                        ></Checkbox>
                      </td>
                      <td>{item?.fleetName}</td>
                      <td>{item?.licensePlate}</td>
                      <td>
                        <Tooltip
                          key={item?.fleetId}
                          overlayClassName="trucks-modal-tooltip"
                          title={
                            <ul>
                              <li>{`Fleet Status: ${item?.fleetStatus}`}</li>
                              <li>{`Company registered: ${
                                !!item?.companyRegistered
                                  ? item?.companyRegistered
                                  : "none"
                              }`}</li>
                              <li>{`Registration Date: ${
                                moment(item?.registrationDate).format(
                                  "MM/DD/YYYY"
                                ) || ""
                              }`}</li>
                              <li>{`Year: ${item?.fleetYear}`}</li>
                              <li>
                                {`Inspection Expiring Date: ${moment(
                                  item?.inspectionExpDate
                                ).format("MM/DD/YYYY")}` || ""}
                              </li>
                              <li>
                                {`Registration Expiring Date: ${moment(
                                  item?.registrationExpDate
                                ).format("MM/DD/YYYY")}` || ""}
                              </li>
                              <li>
                                {`Insurance Date: ${moment(
                                  item?.insuranceDate
                                )?.format("MM/DD/YYYY")}` || ""}
                              </li>
                              <li>{`DMV Number: ${item?.dmvNumber}`}</li>
                              <li>{`Max capacity: ${item?.maxCapacity}`}</li>
                            </ul>
                          }
                          placement="rightTop"
                          trigger={"click"}
                        >
                          <Button
                            style={{
                              width: 32,
                              height: 32,
                              padding: 0,
                              background: "#1264a3 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                            }}
                            icon={<InfoIconWhite />}
                          ></Button>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          key={item?.fleetId}
                          overlayClassName="trucks-modal-tooltip select-driver-tooltip"
                          title={`${
                            (fleetSelected
                              ? drivers?.find(
                                  (fa) =>
                                    fa?.driverId === fleetActivity?.driverId
                                )?.driverName
                              : "Select Driver") || "Select Driver"
                          }`}
                          placement="top"
                          trigger="hover"
                          destroyTooltipOnHide={true}
                        >
                          <div
                            key={item?.fleetId}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <MondayButton
                              // {...{ key: fleetId }}
                              disabled={
                                !fleetActivities?.filter(
                                  (el) => el?.fleetId === item?.fleetId
                                )?.length
                              }
                              className="mondayButtonBlue trucks-modal-button"
                              containerClassName="truck-modal-mondayButton"
                              Icon={<UserIcon />}
                              onClick={() => {
                                setSelectDriverPerFleet(item);
                                setSelectDriverModalVisible(true);
                              }}
                              style={
                                !fleetActivities?.filter(
                                  (el) => el?.fleetId === item?.fleetId
                                )?.length
                                  ? {
                                      margin: 0,
                                      width: 150,
                                      pointerEvents: "none",
                                    }
                                  : { margin: 0, width: 150 }
                              }
                            >
                              {`${
                                (fleetSelected
                                  ? drivers?.find(
                                      (fa) =>
                                        fa?.driverId === fleetActivity?.driverId
                                    )?.driverName
                                  : "Select Driver") || "Select Driver"
                              }`}
                            </MondayButton>
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <MondayButton
                          disabled={
                            !fleetActivities?.filter(
                              (el) => el?.fleetId === item?.fleetId
                            )?.length
                          }
                          onClick={() =>
                            setDispatchFormVisible({
                              openModal: true,
                              data: {
                                fleetId,
                                driverId: fleetActivity?.driverId || "",
                                // saveHandler: () => {},
                                schedule: {
                                  scheduleAddress,
                                  scheduleId,
                                  typeOfWork,
                                  scheduleDays: [
                                    { startDate: scheduleDay?.startDate },
                                  ],
                                },
                              },
                            })
                          }
                          {...{
                            className: "mondayButtonBlue",
                          }}
                        >
                          Dispatch
                        </MondayButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {!!dispatchFormVisible?.openModal && (
        <NewDispatchModal
          {...{
            visible: dispatchFormVisible,
            setVisible: setDispatchFormVisible,
            defaultData: dispatchFormVisible?.data,
          }}
        />
        // <MultipleDispatchesModal
        //   {...{
        //     fleet,
        //     drivers,
        //     fleetDispatching,
        //     projects,
        //     visible: dispatchFormVisible,
        //     setVisible: setDispatchFormVisible,
        //     defaultConfiguration: fleetActivities,
        //     receiveFleetDispatching,
        //   }}
        // />
      )}
      {selectDriverModalVisible && (
        <SelectDriverModal
          {...{
            drivers: drivers?.filter(
              (driver) =>
                !fleetActivities.some((el) => el?.driverId === driver?.driverId)
            ),
            visible: selectDriverModalVisible,
            setVisible: setSelectDriverModalVisible,
            handleSelectDriver,
            fleet: selectDriverPerFleet,
            setSelectDriverPerFleet,
          }}
        />
      )}
    </>
  );
};

export default React.memo(TrucksModal);
