import moment from "moment";

export const footerButtons = ({
  onCancel,
  onSave,
  onOptionClick,
  suffix,
  saving,
  proppedDate,
}) => [
  {
    text: "Cancel Changes",
    cancel: true,
    onClick: onCancel,
  },
  {
    text: !proppedDate ? "Save & Close" : "Save & Next",
    primary: true,
    dropdownDisabled: saving,
    disabled: saving,
    onClick: onSave,
    options: !proppedDate && ["Save & New"],
    onOptionClick: onOptionClick,
    suffix: !proppedDate && suffix,
  },
];
export const timeFormat = "hh:mm a";
export const dateFormat = "MM/DD/YYYY";
export const cargoState = ["Empty", "Loaded"];
export const trafficModels = [
  {
    label: "Optimistic",
    type: "optimistic",
  },
  {
    label: "Best Guess",
    type: "bestguess",
  },
  {
    label: "Pessimistic",
    type: "pessimistic",
  },
];

export const dataGridColumns = [
  {
    headerName: "Truck#",
    field: "fleetName",
    filter: "agTextColumnFilter",
    resizable: true,
  },
  {
    headerName: "Drop off Location",
    field: "dropOffLocation",
    resizable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Driver",
    field: "driverName",
    resizable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Depart At Date",
    field: "dispatchDate",
    resizable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Depart At",
    field: "departAt",
    resizable: true,
    cellRenderer: (params) => moment(params.value).format(timeFormat),
    sort: "desc",
  },
  {
    headerName: "Arrive by Date",
    field: "dispatchDate",
    resizable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Arrive by",
    field: "arriveBy",
    resizable: true,
    cellRenderer: (params) => moment(params.value).format(timeFormat),
  },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
  },
  {
    headerName: "Date",
    field: "dispatchDate",
    hide: true,
    suppressToolPanel: true,
    filter: "agNumberColumnFilter",
  },
];

export const dispatchFilters = ({
  trucks = [],
  drivers = [],
  dropOffLocations = [],
  date = [],
}) => [
  {
    label: "Truck#",
    type: "select",
    column: "fleetName",
    formItemName: "dispatchTruckFilter",
    filterType: "equals",
    options: ["All", ...trucks],
  },
  {
    label: "Driver",
    type: "select",
    column: "driverName",
    formItemName: "dispatchDriverFilter",
    filterType: "equals",
    options: ["All", ...drivers],
  },
  {
    label: "Jobsite",
    type: "select",
    column: "dropOffLocation",
    filterType: "equals",
    formItemName: "dispatchDropOffLocationFilter",
    options: ["All", ...dropOffLocations],
  },
  {
    label: "Date",
    type: "select",
    column: "dispatchDate",
    filterType: "equals",
    formItemName: "dispatchDateFilter",
    options: ["All", ...date],
  },
];
