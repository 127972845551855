import React from "react";
import { generateInputFields } from "./helpers";
import { NextStepInputs } from "../NextStepInputs";

const Charge = ({ title, inputData, setInputData }) => {
  const inputFields = generateInputFields();

  const isRequisition = title === "Requisitions";

  return <NextStepInputs {...{ inputData, setInputData, inputFields }} />;
};

export default Charge;
