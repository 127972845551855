import { NavLink } from "react-router-dom";
import { ProfileIcon } from "../../../../../../icons/Settings";
import { ProjectIcon } from "../../../../../SidebarPages/Scheduling/GanttChart/assets/OtherIcons";
import { Menu } from "antd";
import { LightningIcon } from "../../../assets/images";

const getItems = () => [
  {
    key: "1",
    label: "Profile",
    path: "/field/profile",
    icon: <ProfileIcon />,
  },
  {
    key: "2",
    label: "Projects",
    path: "/field",
    icon: <ProjectIcon />,
  },
];

const getMenuItems = ({ setOpenMenu, navigate, t }) =>
  getItems().map((item) => ({
    key: item.path,
    // label: item.label,
    icon: item.icon,
    label: (
      <div
        onClick={() => {
          setOpenMenu(false);
          navigate(item.path);
        }}
      >
        {t(item.label)}
      </div>
    ),
  }));

export default getMenuItems;
