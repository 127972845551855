import { Skeleton, Space } from "antd";
import React from "react";
import { v4 as uuidv4 } from "uuid";

function ActiveItemSkeleton() {
  return (
    <div>
      {[...Array(15)].map((_) => {
        return (
          <div
            key={uuidv4()}
            style={{
              padding: 8,
              display: "flex",
              justifyContent: "space-between",
            }}
            className="notifications-skeleton-container"
          >
            <div style={{ display: "flex", width: "70%" }}>
              <Skeleton
                avatar={{
                  shape: "square",
                  size: "small",
                  style: { verticalAlign: "middle" },
                }}
                title={{ width: "100%" }}
                paragraph={false}
                active
              />
            </div>
            <Space size={10} style={{ marginTop: 5 }}>
              <Skeleton.Input active size="small" style={{ height: 16 }} />
              <Skeleton.Button active size="small" style={{ height: 16 }} />
            </Space>
          </div>
        );
      })}
    </div>
  );
}

export default ActiveItemSkeleton;
