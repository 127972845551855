import { useState } from "react";
import "./ListSearch.scss";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { SearchIcon } from "../../../../SidebarPages/Communication/assets";
import { useSelector } from "react-redux";

/**
 * ListSearch component.
 *
 * @component
 * @param {Array} rowData - The array of data to be displayed in the list.
 * @param {string} className - The additional CSS class name for the component.
 * @param {string} placeholder - The placeholder text for the search input.
 * @param {Function} mapFunction - The function used to map and render each item in the list.
 * @param {Function} searchFunction - The function used to filter the list based on search input.
 * @param {Function} CustomHeaderController - The custom header component for the list.
 * @returns {JSX.Element} The rendered ListSearch component.
 */
const ListSearch = ({
  rowData = [],
  className = "",
  placeholder = "Search",
  mapFunction = () => {},
  searchFunction = () => {},
  CustomHeaderController = () => {},
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [searchValue, setSearchValue] = useState("");

  /**
   * Represents an item in the ListSearch component.
   *
   * @component
   * @param {ReactNode} children - The content of the item.
   * @param {string} [itemKey=""] - The unique key of the item.
   * @param {boolean} [active=false] - Indicates if the item is active.
   * @param {function} [onClick=() => {}] - The function to be called when the item is clicked.
   * @returns {JSX.Element} The rendered ListSearchItem component.
   */
  const ListSearchItem = ({
    children,
    itemKey = "",
    active = false,
    onClick = () => {},
  }) => (
    <div
      className={`list-search-item ${active ? "list-search-item-active" : ""}`}
      key={itemKey}
      onClick={onClick}
      id={`list-search-item-${itemKey}`}
    >
      {children}
    </div>
  );

  return (
    <div
      className={`list-search-content ${className} ${
        isDarkMode ? "list-search-content-dark" : ""
      }`}
    >
      <InputComponent
        {...{
          placeholder,
          noFormItem: true,
          prefix: <SearchIcon />,
          type: "input",
          onChange: (e) => setSearchValue(e?.target?.value || e),
        }}
      />
      <CustomHeaderController />
      <div className="list-search-body">
        {rowData
          ?.filter(
            searchValue?.length
              ? (el) => searchFunction(el, searchValue)
              : () => true
          )
          .map((el, index) => mapFunction(el, index, ListSearchItem))}
      </div>
    </div>
  );
};

export default ListSearch;
