import React from "react";
import NewCheckBox from "../NewCheckBox/NewCheckBox";
import "./NewCheckBoxWithLabel.scss";

const NewCheckBoxWithLabel = ({
  checked,
  tooltip,
  label,
  className = "",
  style,
  onClick,
  disabled = false,
  indeterminate = false,
}) => {
  return (
    <div
      className={`checkbox-with-label-container ${className}`}
      style={style || {}}
    >
      <NewCheckBox {...{ checked, tooltip, onClick, disabled, indeterminate }}>
        {label}
      </NewCheckBox>
    </div>
  );
};

export default NewCheckBoxWithLabel;
