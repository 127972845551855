import { message } from "antd";
import { API } from "aws-amplify";
import {
  getChangedData,
  parseText,
} from "../../SidebarPages/Accounting/components/utilities";
import { findTutorialSteps } from "../CustomModalHeader/CustomModalHeader";
import { compareIncluding } from "../../SidebarPages/utils";
import createPDF from "../../../integrations/createPDF";
import htmlParser from "../../../utils/htmlParser";
import { getAllTextFromTags } from "../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dispatchFunctions";
import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";

export const postNote = async (note, saveAddedLogs) => {
  message.loading("Saving changes... Please Wait!");

  // get changes
  let changedData = getChangedData(note, {});

  // save log
  saveAddedLogs({
    recordId: note.recordId,
    recordName: note.modalName,
    category: "Notes",
    topic: note.topicCategory,
    currentData: { noteCreation: changedData.curr },
    previousData: { noteCreation: changedData.prev },
  });

  return await API.post("notes", `/notes`, { body: note })
    .then((res) => {
      message.destroy();
      message.success("Note Added Successfully");
      return res;
    })
    .catch((e) => console.error(e));
};

export const deleteNote = (
  noteId,
  saveAddedLogs,
  rowNotes,
  fakeNotes,
  setFakeNotes,
  setRowNotes
) => {
  message.loading("Saving changes... Please Wait!");
  // store the note that will be deleted
  const noteToDelete = rowNotes.find((note) => note.noteId === noteId);
  // get changes
  let result = getChangedData({}, noteToDelete);

  // add this change in DB
  saveAddedLogs({
    recordId: noteToDelete.recordId,
    recordName: noteToDelete.modalName,
    actionType: "Delete",
    category: "Notes",
    topic: noteToDelete.topicCategory,
    currentData: { noteDeletion: result.curr },
    previousData: { noteDeletion: result.prev },
  });

  API.del("notes", `/notes/${noteId}`).then(() => {
    message.destroy();
    message.success("Note Deleted Successfully!");
    setRowNotes((prev) => prev.filter((note) => note.noteId !== noteId));
    if (Array.isArray(fakeNotes)) {
      const updatedNotes = fakeNotes.filter((note) => note.noteId !== noteId);
      setFakeNotes(updatedNotes);
    }
  });
};

export const updateReplies = async (
  noteId,
  replies,
  saveAddedLogs,
  rowNotes,
  fakeNotes,
  setFakeNotes,
  setRowNotes
) => {
  message.loading("Saving changes... Please Wait!");
  // store the old state of the reply that will be updated
  const replyToUpdate = rowNotes.find((note) => note.noteId === noteId);
  // get changes
  let result = getChangedData(
    { ...replyToUpdate, replies: replies },
    replyToUpdate
  );

  // add this change in DB
  saveAddedLogs({
    recordId: replyToUpdate.recordId,
    recordName: replyToUpdate.modalName,
    actionType: "Edit",
    category: "Notes",
    topic: replyToUpdate.topicCategory,
    currentData: {
      [replyToUpdate.relatedTo]: { replies: result.curr, dummyKey: 0 },
    },
    previousData: {
      [replyToUpdate.relatedTo]: { replies: result.prev, dummyKey: 0 },
    },
  });

  return await API.put("notes", `/notes/${noteId}`, {
    body: { repliesVisibility: false, replies },
  }).then(() => {
    message.destroy();
    message.warning("Replies Updated Successfully");
    if (Array.isArray(fakeNotes)) {
      const updatedNotes = fakeNotes.map((note) =>
        note.noteId === noteId
          ? {
              ...note,
              replies: replies,
            }
          : note
      );
      setFakeNotes(updatedNotes);
    }
    setRowNotes(
      rowNotes.map((note) =>
        note.noteId === noteId
          ? {
              ...note,
              repliesVisibility: true,
              replies: replies,
            }
          : note
      )
    );
  });
};

export const updateNote = async (
  noteId,
  newComment,
  privateOnlyTo,
  rowNotes,
  saveAddedLogs,
  fakeNotes,
  setFakeNotes,
  setRowNotes
) => {
  message.loading("Saving changes... Please Wait!");
  // store the note that will be updated
  const noteToUpdate = rowNotes.find((note) => note.noteId === noteId);
  // get changes
  let result = getChangedData(
    { ...noteToUpdate, noteComment: newComment },
    noteToUpdate
  );

  // add this change in DB
  saveAddedLogs({
    recordId: noteToUpdate.recordId,
    recordName: noteToUpdate.modalName,
    actionType: "Edit",
    category: "Notes",
    topic: noteToUpdate.topicCategory,
    currentData: {
      [noteToUpdate.relatedTo]: { editedNote: result.curr, dummyKey: 0 },
    },
    previousData: {
      [noteToUpdate.relatedTo]: { editedNote: result.prev, dummyKey: 0 },
    },
  });

  const body = {
    commentChanges: [
      {
        previous: noteToUpdate.noteComment,
        current: newComment,
        updatedAt: Date.now(),
      },
      ...(noteToUpdate?.commentChanges || []),
    ],
    noteComment: newComment,
    privateOnlyTo,
  };

  return await API.put("notes", `/notes/${noteId}`, { body })
    .then(() => {
      message.destroy();
      message.warning("Note Updated Successfully");
      setRowNotes(
        rowNotes.map((note) =>
          note.noteId === noteId ? { ...note, ...body } : note
        )
      );
      if (Array.isArray(fakeNotes)) {
        const updatedNotes = fakeNotes.map((note) =>
          note.noteId === noteId ? { ...note, ...body } : note
        );
        setFakeNotes(updatedNotes);
      }
    })
    .catch((err) => console.log(err));
};

export const notesTourSteps = ({ programFields, isDarkMode, rowNotes }) => {
  const collapsedCard = document.querySelector(".ant-collapse-item-active");

  function stepsMapHelper(title) {
    const stepsMap = {
      "Search Note": document.querySelector(".notes_search"),
      "Note Card": !!collapsedCard
        ? collapsedCard
        : document.querySelector(".ant-collapse-item"),
      "Note Content": document.querySelector(".comment_wrapper "),
      "Add Note": document.querySelector(".write-note-btn"),
      "Add Replies": document.querySelector(".reply_btn"),
      "Quick Note": document.querySelector(".comment-input-container"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    const firstLastSteps = newSteps.slice(0, 1).concat(newSteps.slice(-1));

    return rowNotes?.length > 0
      ? !!collapsedCard
        ? newSteps
        : newSteps.filter((_, i) => i !== 2 && i !== 3)
      : firstLastSteps;
  }
  const tourSteps = mapRefs(findTutorialSteps("notesSideModal", programFields));

  return tourSteps?.filter(Boolean) || [];
};

export const notesDocDefinition = (title, dynamicPdf, companyLogo) => {
  return {
    // pageOrientation: orientation ? orientation : "portrait",
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo, //?.current?.base64,
            width: 100,
          },
          {
            stack: [
              {
                text: `Notes for: ${title ? title : ""}`,
                // style: ["strong", "large"],
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
          {
            text: `Date : ${dayjsNY().format(DEG_DATE_FORMAT)}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      dynamicPdf?.flatMap((item) => item),
    ],
    images: {
      logo: companyLogo, //?.current?.base64,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
      category: { fontSize: 10, color: "#4a4a4a" },
      commentContent: { margin: [10, 0, 10, 10] },
      replyContent: { margin: [20, 0, 10, 10] },
      replyHeader: { fontSize: 12, color: "#4a4a4a" },
    },
  };
};

const pdfNoteBorderedSection = ({ body = [], noColor = false }) => {
  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };

  let formattedBody;

  if (body.length == 0)
    formattedBody = [
      {
        columns: [
          {
            width: "*",
            text: "No records to show",
            alignment: "center",
            style: ["lastColumn"],
          },
        ],
      },
    ];

  if (body.length !== 0) {
    formattedBody = body?.map((item) => {
      return [
        {
          text: item.author,
          style: ["lastColumn", "strong", "subheader"],
        },
        {
          columns: [
            {
              width: "30%",
              text: item.category,
              alignment: "left",
              style: ["category"],
            },
            {
              width: "70%",
              text: dayjsNY(item?.createdAt).format(
                `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
              ),
              alignment: "right",
              style: ["category"],
            },
          ],
        },
        {
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
          </svg>`,
          width: 525,
          height: 2,
          style: ["subheader"],
        },
        {
          text: "Comment: ",
          width: "100%",
          alignment: "left",
          style: ["lastColumn"],
        },
        {
          columns: [
            {
              width: item?.noteComment ? "50%" : "10%",
              text: getAllTextFromTags(htmlParser(item.noteComment))
                .filter((el) => el.trim())
                .join(""),
              alignment: "left",
              style: ["lastColumn", "replyContent"],
            },
          ],
        },

        {
          text: "Replies: ",
          width: "100%",
          alignment: "left",
          style: ["lastColumn"],
        },
        ...item?.replies?.map((reply) => {
          return [
            {
              width: "100%",
              columns: [
                {
                  width: "50%",
                  text: reply?.author,
                  alignment: "left",
                  style: ["strong", "replyHeader", "replyContent"],
                },
                {
                  width: "50%",
                  text: dayjsNY(reply?.createdAt).format(
                    `${DEG_DATE_FORMAT}, ${DEG_TIME_FORMAT}`
                  ),
                  alignment: "right",
                  style: ["category", "replyContent"],
                },
              ],
            },
            {
              width: reply.noteComment ? "50%" : "10%",
              text: parseText(htmlParser(reply.replyContent)),
              alignment: "left",
              style: ["replyHeader", "replyContent"],
              styles: {
                margins: [30, 0, 0, 0],
              },
            },
          ];
        }),
        {
          width: "100%",
          text: "",
          style: ["outerTable"],
        },
      ];
    });
  }

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: formattedBody.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
};

const configPdfData = ({ title, body }) => [
  pdfNoteBorderedSection({
    title,
    body,
  }),
];

export const generateNotePdf = ({ notesProps, base64, exportFilter }) => {
  const body = exportFilter?.length
    ? notesProps?.filter(({ author }) => exportFilter.includes(author))
    : notesProps;

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  createPDF({
    action: "open",
    docDefinition: notesDocDefinition(
      body?.[0]?.relatedTo,
      // "TITLE",
      configPdfData({ title: "Notes", body }),
      (companyLogo = companyLogo)
    ),
  });
};

export const mockedUseSelector = {
  userConfiguration: {
    nameOfUser: "nameOfUser",
    cognitoUserId: "cognitoUserId",
    routeConfig: [{ title: "Notes/View", path: "/", children: [{}] }],
    allUsers: {
      Items: [
        { nameOfUser: "nameOfUser", cognitoUserId: "cognitoUserId" },
        { nameOfUser: "user2", cognitoUserId: "cognito2" },
      ],
    },
  },
  programFields: [
    {
      fieldName: "Tutorials Steps",
      fieldOptions: [{ categoryName: "notesSideModal", steps: [{}] }],
    },
    {
      fieldName: "Categories for Notes",
      fieldOptions: { Projects: [{ categoryName: "Call" }] },
    },
  ],
  darkMode: false,
};
