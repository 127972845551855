const gridFilters = [
  {
    globalKeyId: "0ed9de23-047a-4d83-bdaa-2e3bc009de13",
    label: "Vehicle",
    type: "Dropdown",
    key: "fleetName",
  },
  {
    globalKeyId: "9974e9b3-c284-4d07-a500-fb057e762b97",
    label: "From Date",
    type: "DateRange",
    key: "beginDate",
  },
  {
    globalKeyId: "85971661-1441-4ea9-8ced-89b469dcabf8",
    label: "To Date",
    type: "DateRange",
    key: "endDate",
  },
  {
    globalKeyId: "85971661-1441-4ea9-8ced-89b469dcabf9",
    label: "Stop Type",
    type: "Dropdown",
    key: "stopType",
  },
  {
    globalKeyId: "b365e438-eb4e-42c6-9c9e-97bcacef5e23",
    label: "Duration",
    type: "Dropdown",
    key: "duration",
  },
  {
    globalKeyId: "1c01426a-5a9d-48b9-a00a-4993d3afdfdb",
    label: "Reason",
    type: "Dropdown",
    key: "reason",
  },
];

export default gridFilters;
