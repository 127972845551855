import { useMediaQuery } from "react-responsive";
import { Stepper } from "../../../../commonComponents";
import { InputComponent } from "../../../Fleet/components";

/**
 * Renders the SafetyStatus component.
 *
 * @component
 * @param {Object} form - The form object.
 * @param {number} [currentStep=0] - The current step.
 * @param {string} [safetyStatus=""] - The safety status.
 * @param {boolean} [isDarkMode=false] - Indicates if dark mode is enabled.
 * @param {Array} [statusesColors=[]] - The array of status colors.
 * @param {Function} [setChanges=() => {}] - The function to set changes.
 * @param {Function} [setCurrentStep=() => {}] - The function to set the current step.
 * @param {boolean} [disabledAllFields=false] - Indicates if all fields are disabled.
 * @returns {JSX.Element} The rendered SafetyStatus component.
 */
function SafetyStatus({
  form,
  currentStep = 0,
  safetyStatus = "",
  isDarkMode = false,
  statusesColors = [],
  setChanges = () => {},
  setCurrentStep = () => {},
  disabledAllFields = false,
}) {
  const isMobileView = useMediaQuery({
    query: "(max-width: 575px)",
  });

  const steps = statusesColors
    ?.map(({ statusName }) => ({ title: statusName }))
    ?.filter((el) => {
      if (safetyStatus !== "Proceeded to Claim") {
        return el.title !== "Proceeded to Claim";
      } else {
        return el;
      }
    });

  if (isMobileView) {
    return (
      <div className="stepper-section-mobile">
        <InputComponent
          {...{
            label: "Status",
            type: "select",
            formItemName: "stepper",
            dropdownClassName: isDarkMode && "darkDropDown",
            customOptions: statusesColors?.map(({ statusId, statusName }) => ({
              value: statusId,
              label: statusName,
            })),
            onSelect(e) {
              setCurrentStep(e);
              setChanges(true);
            },
            defaultValue: currentStep,
            disabled: disabledAllFields,
          }}
        />
      </div>
    );
  }
  return (
    <div className="stepper-section">
      <Stepper
        {...{
          setCurrentStep(e) {
            form.setFieldValue("stepper", e);
            setCurrentStep(e);
            setChanges(true);
          },
          currentStep,
          steps,
          statusesColors,
          stepperClassName: "stepper-component",
          stepRenderer: false,
          disabled: disabledAllFields,
        }}
      />
    </div>
  );
}

export default SafetyStatus;
