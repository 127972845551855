import {
  useRef,
  Fragment,
  useState,
  useEffect,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Modal, Image, message, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import { StoreType, DriveFile } from "../../types";
import { driveApi } from "src/integrations/DriveRequest";
import { MondayButton } from "src/components/commonComponents";
import { SmallLoadableComp } from "src/components/Sidebars/components";
import ContainerScrollArrow from "../ContainerScrollArrow/ContainerScrollArrow";
import { GoTasks, ReportIcon } from "src/icons";
import { PdfDocIcon, ExcelIcon, WordDocIcon } from "src/assets";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "./GalleryCard.scss";
import { ItemType } from "antd/lib/menu/interface";

type GalleryPropsType = {
  className?: string;
  title?: ReactNode;
  headerActions?: ItemType[];
  noFilesDescription?: ReactNode;
  parentFolderId: string;
};

export type GalleryHandle = {
  getSources: () => Promise<string[]>;
};

const GalleryCard = forwardRef<GalleryHandle, GalleryPropsType>(
  function GalleryCard(props, ref) {
    const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
    const { accessToken } = useSelector(
      (state: StoreType) => state.accessToken
    );

    const [files, setFiles] = useState<DriveFile[]>([]);
    const [sources, setSources] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [previewSrc, setPreviewSrc] = useState<string>();

    const { getFilesByFolderId, getImageSrc } = driveApi({ accessToken });

    const containerRef = useRef<HTMLDivElement>(null);

    const {
      className = "",
      parentFolderId,
      headerActions = [],
      title = "Uploaded Files",
      noFilesDescription = "No files were uploaded",
    } = props;

    useEffect(() => {
      setLoading(true);

      if (parentFolderId) {
        getFilesByFolderId(parentFolderId)
          .then((r) => r.json())
          .then(async (r: { files: DriveFile[] }) => {
            const f = (r.files || []).filter(
              ({ mimeType }) => !mimeType.includes("folder")
            );

            setFiles(f);
            return Promise.allSettled(
              f.map((file) => {
                return getImageSrc(file.id);
              })
            );
          })
          .then((r) => {
            setSources(
              r.map((res) => (res.status === "fulfilled" ? res.value.blob : ""))
            );

            setLoading(false);
          })
          .catch((err) => {
            console.log("Error getting files: ", err);
            message.error({
              content:
                "Something went wrong while trying to get the file uploads!",
              key: "filesError",
            });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }, [parentFolderId]);

    async function blobToBase64(blob: Blob): Promise<string> {
      return await new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          async getSources() {
            let arr: string[] = [];
            const tmp = await Promise.all(
              sources.map((uri) =>
                fetch(uri)
                  .then((r) => r.blob())
                  .then((r) => r)
              )
            ).catch((err) => {
              console.log("Error getting files: ", err);
              return [];
            });

            for (const t of tmp) {
              const b = await blobToBase64(t);
              arr.push(b);
            }

            return arr;
          },
        };
      },
      [sources]
    );

    return (
      <Fragment>
        <div
          className={`inspection-grid-card gallery-card-container ${
            isDarkMode ? "grid-card-dark" : ""
          } ${className ?? ""}`}
        >
          {title ? (
            <div className="card-title">
              <div className="card-title-text">{title}</div>
              {!!headerActions?.length && (
                <Dropdown
                  trigger={["click", "hover"]}
                  placement="bottomRight"
                  overlayClassName={`header-action-dropdown ${
                    isDarkMode ? "dropdown-dark" : ""
                  }`}
                  menu={{
                    items: headerActions,
                  }}
                >
                  <EllipsisOutlined
                    style={{ transform: "rotate(90deg)", cursor: "pointer" }}
                  />
                </Dropdown>
              )}
            </div>
          ) : null}
          <div className={`card-body ${title ? "with-title" : "no-title"}`}>
            {!loading && !!files.length && (
              <ContainerScrollArrow
                direction="LEFT"
                containerRef={containerRef}
              />
            )}
            <div className="body-files-container" ref={containerRef}>
              <SmallLoadableComp loading={loading} darkMode={isDarkMode}>
                {files.length ? (
                  files.map((e, index) => {
                    return e.mimeType.includes("image") ? (
                      <Image
                        src={sources[index]}
                        className="image-container"
                        key={e.id}
                      />
                    ) : (
                      <div className="file-container" key={e.id}>
                        <div className="frame-icon">
                          {e.mimeType.includes("pdf") ? (
                            <PdfDocIcon />
                          ) : e.mimeType.includes("word") ? (
                            <WordDocIcon />
                          ) : e.mimeType.includes("excel") ||
                            e.mimeType.includes("openxmlformats") ||
                            e.mimeType.includes("spreadsheet") ? (
                            <ExcelIcon />
                          ) : (
                            <ReportIcon
                              fill={isDarkMode ? "#fff" : undefined}
                            />
                          )}
                        </div>
                        <span className="file-name" title={e.name}>
                          {e.name}
                        </span>
                        {/** @ts-ignore */}
                        <MondayButton
                          className="mondayButtonBlue"
                          Icon={<GoTasks />}
                          onClick={() => {
                            if (e.mimeType.includes("pdf")) {
                              setPreviewSrc(sources[index]);
                            } else {
                              window.open(
                                e.webViewLink,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }
                          }}
                        >
                          Preview
                        </MondayButton>
                      </div>
                    );
                  })
                ) : (
                  <span className="no-files-found">{noFilesDescription}</span>
                )}
              </SmallLoadableComp>
            </div>
            {!loading && !!files.length && (
              <ContainerScrollArrow
                direction="RIGHT"
                containerRef={containerRef}
              />
            )}
          </div>
        </div>
        <Modal
          {...{
            open: !!previewSrc,
            onCancel() {
              setPreviewSrc("");
            },
            footer: null,
            destroyOnClose: true,
            centered: true,
            closeIcon: <XIcon />,
            title: "Document Preview",
            className: `document-modal-container${
              isDarkMode ? " document-dark" : ""
            }`,
          }}
        >
          <iframe src={previewSrc} height="100%" width="100%" />
        </Modal>
      </Fragment>
    );
  }
);

export default GalleryCard;
