import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";

const PLAIN_AUDIT = {
  changed: {},
  discarded: [],
  linkedStops: [],
  stopReasons: [],
  timeChanges: {},
  createdAlerts: {},
  discardedStops: [],
  idleViewChanges: [],
  afterHoursChanges: [],
  auditedActivities: [],
  auditStatus: "PENDING",
};

function rangeValidator(log, log2) {
  return (
    log.eventRange[0] === log2.eventRange[0] &&
    log.eventRange[1] === log2.eventRange[1]
  );
}

function idValidator(log, log2) {
  return log.eventId === log2.eventId;
}

/**
 * Function that handles automatically assigning house
 * addresses within a certain radius
 */
function houseAssign({
  rowData,
  globalAudits,
  distanceInFeet,
  addressesField,
  clearEntries = false,
}) {
  const changeLogs = {};
  const tmpAudits = { ...globalAudits };

  if (!tmpAudits?.["alertChanges"]) {
    tmpAudits["alertChanges"] = {};

    if (clearEntries) {
      return tmpAudits;
    }
  }

  for (const fleetId in tmpAudits["alertChanges"]) {
    tmpAudits["alertChanges"][fleetId]["afterHoursChanges"] = (
      tmpAudits["alertChanges"][fleetId]["afterHoursChanges"] || []
    ).filter(({ field, value }) => {
      return !(field === "routeType" && value === "Home");
    });
  }

  if (clearEntries) {
    return tmpAudits;
  }

  for (const row of rowData) {
    if (!row?.driver?.driverId || !row?.position) {
      continue;
    }

    if (!addressesField[row.driver.driverId]) {
      continue;
    }

    const addressesRes = addressesField[row.driver.driverId].map(
      ({ position }) =>
        withinRadius(row.position, position, distanceInFeet).withinRange
    );

    if (!addressesRes.some((e) => !!e)) {
      continue;
    }

    let log = {
      discarded: false,
      eventId: undefined,
      eventRange: undefined,
      field: "routeType",
      value: "Home",
    };

    if (row.type.includes("GEOFENCE")) {
      log.eventId = row.eventId;
    } else {
      log.eventRange = row.eventRange;
    }

    changeLogs[row.fleetId] = [...(changeLogs[row.fleetId] || []), log];
  }

  for (const fleetId in changeLogs) {
    if (!tmpAudits["alertChanges"]?.[fleetId]) {
      tmpAudits["alertChanges"][fleetId] = structuredClone(PLAIN_AUDIT);
    }

    for (const log of changeLogs[fleetId]) {
      const validator = log?.eventId ? idValidator : rangeValidator;

      const prevLogIndex = changeLogs[fleetId].findIndex((prevLog) => {
        return validator(log, prevLog) && prevLog["field"] === "routeType";
      });

      if (prevLogIndex < 0) {
        tmpAudits["alertChanges"][fleetId]["afterHoursChanges"].splice(
          tmpAudits["alertChanges"][fleetId]["afterHoursChanges"].length,
          0,
          log
        );
      } else {
        tmpAudits["alertChanges"][fleetId]["afterHoursChanges"].splice(
          prevLogIndex,
          1,
          log
        );
      }
    }
  }

  return tmpAudits;
}

export default houseAssign;
