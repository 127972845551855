import { message } from "antd";
import {
  getItemID,
  serviceColorCodes,
} from "../../../../../../helpers/constants/forProject";

let pdfMake = require("pdfmake/build/pdfmake.js");
let htmlToPdfmake = require("html-to-pdfmake");

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

export function formatDimensions(length, height, width) {
  function evaluateMathExpression(fullExpression, dimension) {
    function evaluate(expression) {
      try {
        // eslint-disable-next-line
        let result = eval(expression);
        if (result > 0) {
          return `${Math.round(result * 100) / 100}′${dimension}`;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }

    if (fullExpression) {
      if (fullExpression.toString().includes(">")) {
        let expressions = fullExpression.split(">");
        return `${evaluate(expressions[0])} - ${evaluate(expressions[1])}`;
      } else {
        return evaluate(fullExpression);
      }
    } else {
      return null;
    }
  }

  let formattedDimensions = [];
  for (const dimension of [
    evaluateMathExpression(length, "L"),
    evaluateMathExpression(height, "H"),
    evaluateMathExpression(width, "W"),
  ]) {
    if (dimension != null) {
      formattedDimensions.push(dimension);
    }
  }

  return formattedDimensions.join(" x ") || "";
}

function formatHTML(prefix, content) {
  if (content) {
    return [prefix, htmlToPdfmake(content)[0]];
  } else return [];
}

export function exportPDF(data, address) {
  const hideLoading = message.loading(
    "File is being processed. Please wait...",
    0
  );
  const fileName = address.replace(/\W+/g, "-").toLowerCase() + ".pdf";
  let docContent = [
    {
      text: address,
      fontSize: 20,
    },
    {
      text: "(Client: [placeholder])",
      fontSize: 12,
      margin: [0, 0, 0, 15],
    },
  ];

  data.forEach((service) => {
    docContent.push({
      text: service.label,
      fontSize: 14,
      ...serviceColorCodes[service.serviceId],
      margin: [0, 2],
    });
    service.serviceOptions.forEach((option, index) => {
      if (service.serviceOptions.length > 1) {
        docContent.push({
          text: `Option ${index + 1}`,
          fontSize: 14,
          color: "#000000",
          background: "#ffff00",
          margin: [12, 2, 0, 2],
        });
      }
      let currentElevation;
      option.forEach((item) => {
        if (Object.keys(item).length > 2 && item.approved) {
          if (currentElevation !== item.elevation) {
            currentElevation = item.elevation;
            if (currentElevation) {
              docContent.push({
                text: ["-    ", htmlToPdfmake(item.elevation)[0]],
                fontSize: 11,
                bold: true,
                decoration: "underline",
                margin: [12, 2, 0, 2],
              });
            }
          }
          docContent.push({
            text: [
              {
                text: getItemID(service.serviceId, index, item.id),
                color: "#696969",
                italics: true,
              },
              "   —   ",
              formatDimensions(item.length, item.height, item.width),
              ...formatHTML(" ", item.description),
              ...formatHTML("\nNote: ", item.note),
            ],
            fontSize: 10,
            margin: [12, 2, 0, 2],
          });
        }
      });
    });
  });

  const docDefinition = {
    content: docContent,
    defaultStyle: {
      font: "Roboto",
    },
    pageSize: "A4",
    pageMargins: [60, 40, 60, 40],
  };

  try {
    pdfMake.createPdf(docDefinition).download(fileName);
    hideLoading();
    message.success("Export successful.");
  } catch (err) {
    hideLoading();
    message.error("Something went wrong...");
  }
}
