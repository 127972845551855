import types from "../../types";

const initialState = {
  userImages: {},
};

const userImagesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_NEW_IMAGE: {
      return { ...state, userImages: { ...state.userImages, ...payload } };
    }

    default:
      return state;
  }
};

export default userImagesReducer;
