import { updateScheduleOfValue } from "../../../../../../../../../../actions/scheduleOfValues";
import { defaultColDef, columnDefs, frameworkComponents } from "./GridData";
import { withContext } from "../../../../Context/Context";
import "./service-content.scss";
import {
  getContextMenuItems,
  cellValueChanged,
  onCellKeyPress,
  getRowNodeId,
  appendRow,
  removeRow,
} from "./GridData/GridUtils";

import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import { Component } from "react";
import { message } from "antd";
import { toggleTaxEventHoist } from "./GridData/GridUtils/toggleTaxEventHoist";
import { companyDetails } from "../../../../../../../../../../helpers/constants";
import { removeRentEventHoist } from "./GridData/GridUtils/removeRetEventHoist";

class ServiceContent extends Component {
  setSOV = (newSOV) => {
    this.props.dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });
  };

  setErrors = (errors) => {
    this.props.dispatchContext({ type: "SET_ERRORS", payload: errors });
  };

  // Resizes grid row heights
  resizeGridRows = (gridApi) => {
    gridApi.resetRowHeights();
  };

  // Called when a cell editing has started.
  cellEditingStarted = (params) => {
    if (!this.props.stateContext.isWritable) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
    if (this.props.isLoading) {
      params.api.stopEditing(true);
      message.error("Please wait until it finishes updating");
    }
  };

  // Called when a cell editing has stopped. Used to resize rows when not in focus
  cellEditingStopped = (params) => {
    this.resizeGridRows(params.api);
  };

  // Appends a row below another row
  appendRowInGrid = (eventId) => {
    appendRow(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.elevation,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors
    );
  };

  // Removes a row
  removeRowInGrid = (eventId) => {
    removeRow(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.elevation,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors
    );
  };

  makeRowTaxable = (eventId, data) => {
    toggleTaxEventHoist(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.elevation,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors,
      data,
      this.props.project
    );
    console.log("makeRowTaxable", eventId, data);
  };

  removeRetainage = (eventId, data) => {
    return removeRentEventHoist(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.elevation,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors,
      data,
      this.props.project
    );
  };
  render() {
    const darkMode = this.props.stateContext.darkMode;

    return (
      <div className="sov-service-content-hoist">
        {/* <ResizableBox
          className="sov-resizable"
          minConstraints={[0, 0]}
          maxConstraints={[0, 1500]}
          width="auto"
          height={350}
          axis="y"
          handle={
            <div className="applicationBodyResizableGripContainer">
              <div
                className="leftSize"
                style={{ background: this.props.borderColor }}
              />
              <div
                className="grabberContainer"
                style={{ background: this.props.borderColor }}
              >
                <div className="gripLines" />
                <div className="gripLines" />
                <div className="gripLines" />
              </div>
              <div
                className="rightSize"
                style={{ background: this.props.borderColor }}
              />
            </div>
          }
        > */}
        <div
          className={
            darkMode
              ? "sov-service-content-grid-dark agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
              : "sov-service-content-grid-light agGridRentalsDetailsStyleLight ag-theme-alpine"
          }
        >
          <AgGridReact
            immutableData={true}
            domLayout="autoHeight"
            undoRedoCellEditing={true}
            enableRangeSelection={false}
            rowSelection="single"
            enableCellChangeFlash={true}
            undoRedoCellEditingLimit={50}
            rowHeight={40}
            defaultColDef={defaultColDef}
            rowData={this.props.elevation?.amounts || []}
            rowClassRules={{
              "ag-grid-taxed-sov-row": (params) => params.data.taxAmount > 0,
            }}
            columnDefs={columnDefs({
              serviceLabel: this.props.service.label,
              estimationId: this.props.service.estimationId,
              elevation: this.props.elevation,
              events: [
                ...this.props.serviceEvents?.listOfEvents,
                ...(this.props?.hoistDetails?.[this.props.service.estimationId]
                  ?.find(
                    (el) =>
                      el?.elevationId === this.props.elevation?.elevationId
                  )
                  ?.jumps?.map((jump) => jump.name) || []),
              ],
              appendRowInGrid: this.appendRowInGrid,
              removeRowInGrid: this.removeRowInGrid,
              makeRowTaxable: this.makeRowTaxable,
              removeRetainage: this.removeRetainage,
            })}
            getContextMenuItems={(params) =>
              getContextMenuItems(
                params,
                this.appendRowInGrid,
                this.removeRowInGrid,
                this.props.elevation,
                [
                  ...this.props.serviceEvents?.listOfEvents,
                  ...(this.props?.hoistDetails?.[
                    this.props.service.estimationId
                  ]
                    ?.find(
                      (el) =>
                        el?.elevationId === this.props.elevation?.elevationId
                    )
                    ?.jumps?.map((jump) => jump.name) || []),
                ]
              )
            }
            onCellEditingStarted={(params) => this.cellEditingStarted(params)}
            onCellEditingStopped={(params) => this.cellEditingStopped(params)}
            onCellValueChanged={(params) =>
              cellValueChanged(
                params,
                this.props.service,
                this.props.elevation,
                this.props.stateContext.fakeSOV,
                this.setSOV,
                this.setErrors
              )
            }
            frameworkComponents={frameworkComponents}
            getRowNodeId={getRowNodeId}
            onCellKeyPress={onCellKeyPress}
            stopEditingWhenGridLosesFocus={true}
            suppressDragLeaveHidesColumns={true}
            allowDragFromColumnsToolPanel={true}
          />
        </div>
        {/* </ResizableBox> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyName: companyDetails.companyName,
  isLoading: state?.scheduleOfValues?.isLoading,
});

export default connect(mapStateToProps, { updateScheduleOfValue })(
  withContext(ServiceContent)
);

// import { useParams } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
// import { message } from 'antd'
// import { AgGridReact } from 'ag-grid-react'

// import InfoDetails from './InfoDetails/InfoDetails'
// import { defaultColDef, columnDefs, frameworkComponents } from './GridData'
// import { getRowNodeId, onCellKeyPress, getContextMenuItems, appendRow, removeRow, cellValueChanged } from './GridData/GridUtils'
// import { useStateContext, useDispatchContext } from '../../../../Context/Context'
// import { updateScheduleOfValue } from '../../../../../../../../../../actions/scheduleOfValues'
// import './service-content.css'

// const ServiceContent = ({ service, elevation, borderColor, serviceEvents, hoistDetails }) => {
//   const { scheduleId } = useParams()
//   const { isLoading } = useSelector(state => state.scheduleOfValues)
//   const dispatch = useDispatch()

//   // Context state & dispatch
//   const { isWritable, isAutoSaving, fakeSOV } = useStateContext()
//   const dispatchContext = useDispatchContext()

//   const setSOV = newSOV => {
//     dispatchContext({ type: 'SET_FAKE_SOV', payload: newSOV })
//   }

//   const handleSave = newSOV => {
//     if (isAutoSaving) {
//       dispatch(updateScheduleOfValue( scheduleId, newSOV))
//     }
//   }

//   const setErrors = errors => {
//     dispatchContext({ type: 'SET_ERRORS', payload: errors })
//   }

//   // Resizes grid row heights
//   const resizeGridRows = gridApi => {
//     gridApi.resetRowHeights()
//   }

//   // Called when a cell editing has started.
//   const cellEditingStarted = params => {
//     if (!isWritable) {
//       params.api.stopEditing(true)
//       message.error('Please enable write mode')
//     }
//     if (isLoading) {
//       params.api.stopEditing(true)
//       message.error('Please wait until it finishes updating')
//     }
//   }

//   // Called when a cell editing has stopped. Used to resize rows when not in focus
//   const cellEditingStopped = params => {
//     resizeGridRows(params.api)
//   }

//   // Appends a row below another row
//   const appendRowInGrid = eventId => {
//     appendRow(eventId, isWritable, isLoading, service, elevation, fakeSOV, setSOV, setErrors)
//   }

//   // Removes a row
//   const removeRowInGrid = eventId => {
//     removeRow(eventId, isWritable, isLoading, service, elevation, fakeSOV, setSOV, handleSave, setErrors)
//   }

//   return (
//     <div className='sov-service-content'>
//       <ResizableBox
//         className='sov-resizable'
//         minConstraints={[0, 100]}
//         maxConstraints={[0, 1500]}
//         width='auto'
//         height={350}
//         axis='y'
//         handle={
//           <div className='sov-resizable-grid-resizer'>
//             <div className='sov-resizable-grid-resizer-inner-left' style={{ background: borderColor }} />
//             <div className='sov-resizable-grid-resizer-handler' style={{ background: borderColor }} >
//               <div className='sov-resizable-grid-resizer-handler-grip'/>
//               <div className='sov-resizable-grid-resizer-handler-grip'/>
//               <div className='sov-resizable-grid-resizer-handler-grip'/>
//             </div>
//             <div className='sov-resizable-grid-resizer-inner-right' style={{ background: borderColor }} />
//           </div>
//         }
//       >
//         <div className='sov-service-content-grid ag-theme-material'>
//           <AgGridReact
//             immutableData={true}
//             domLayout='normal'
//             undoRedoCellEditing={true}
//             enableRangeSelection={false}
//             rowSelection='single'
//             enableCellChangeFlash={true}
//             undoRedoCellEditingLimit={50}
//             defaultColDef={defaultColDef}
//             rowData={elevation?.amounts || []}
//             columnDefs={columnDefs({
//               serviceLabel: service.label,
//               estimationId: service.estimationId,
//               elevation,
//               hoistDetails,
//               events: [...serviceEvents?.listOfEvents, ...(hoistDetails?.[service.estimationId]?.find(el => el?.elevationId === elevation?.elevationId)?.jumps?.map(jump => jump.name) || [])]
//             })}
//             getContextMenuItems={params => getContextMenuItems(
//               params,
//               appendRowInGrid,
//               removeRowInGrid,
//               elevation,
//               [...serviceEvents?.listOfEvents, ...(hoistDetails?.[service.estimationId]?.find(el => el?.elevationId === elevation?.elevationId)?.jumps?.map(jump => jump.name) || [])]
//             )}
//             onCellEditingStarted={params => cellEditingStarted(params)}
//             onCellEditingStopped={params => cellEditingStopped(params)}
//             onCellValueChanged={params => cellValueChanged(params, service, elevation, fakeSOV, setSOV, handleSave, setErrors)}
//             frameworkComponents={frameworkComponents}
//             getRowNodeId={getRowNodeId}
//             onCellKeyPress={onCellKeyPress}
//             stopEditingWhenGridLosesFocus={true}
//             suppressDragLeaveHidesColumns={true}
//             allowDragFromColumnsToolPanel={true}
//           />
//         </div>
//         <InfoDetails
//           elevation={elevation}
//           events={[...serviceEvents?.listOfEvents, ...(hoistDetails?.[service.estimationId]?.find(el => el?.elevationId === elevation?.elevationId)?.jumps?.map(jump => jump.name) || [])]}
//         />
//       </ResizableBox>
//     </div>
//   )
// }

// export default ServiceContent
