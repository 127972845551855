import { Popover } from "antd";
import { List } from "../../../../../SidebarPages/DynamicView/cardComponent";

export const HoistJumpRenderer = (params) => {
  // console.log(params.data)

  const columnNames = [
    { fieldTitle: "Floor", fieldName: "floor" },
    { fieldTitle: "Stop", fieldName: "stop" },
  ];

  const rowData = params.data.floors.map((f) => ({
    ...f,
    stop: f.stop.toString(),
  }));

  return (
    <div>
      <Popover
        overlayClassName={"pli-history-popover"}
        trigger={["hover", "click"]}
        content={<List {...{ params: { columnNames, rowData } }} />}
      >
        <span className={"schedule-history-button"}>{params.value}</span>
      </Popover>
    </div>
  );
};
