import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const createPDF = ({ action, docDefinition, title }) => {
  pdfMake.createPdf(docDefinition)[action](title);
};

export default createPDF;

export * from "./pdfActions";
