import { useSelector } from "react-redux";
import { Form } from "antd";

import { loadLivePreference } from "../../../../utils";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { FilterIcon } from "src/components/SidebarPages/BasePage/src";
import { ClearFilterIcon } from "src/components/SidebarPages/DynamicView/src";

import "./IdleDurationPicker.scss";

function IdleDurationPicker({ onDurationSelect = () => {} }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [form] = Form.useForm();

  async function filterHandler() {
    await form
      .validateFields()
      .then(({ duration, equalizer, unit }) => {
        onDurationSelect(duration, equalizer, unit);
      })
      .catch(() => {});
  }

  return (
    <Form
      form={form}
      className={`idle-duration-picker ${
        isDarkMode ? "trips-picker-form-dark" : ""
      }`}
      initialValues={{
        duration:
          loadLivePreference("liveIdleDuration") === -1
            ? null
            : loadLivePreference("liveIdleDuration"),
        equalizer: loadLivePreference("liveIdleOperation"),
        unit: loadLivePreference("liveIdleDurationUnit"),
      }}
    >
      <div className="time-select-section">
        <InputComponent
          {...{
            type: "select",
            required: true,
            showSearch: false,
            allowClear: false,
            label: "Operation",
            formItemName: "equalizer",
            dropdownClassName: isDarkMode ? "darkDropDown" : "",
            customOptions: [
              { label: "Greater Than", value: "gt" },
              { label: "Less Than", value: "lt" },
              { label: "Equal", value: "eq" },
            ],
          }}
        />
        <InputComponent
          {...{
            required: true,
            typeNumber: true,
            label: "Duration",
            inputAllowClear: false,
            formItemName: "duration",
            placeholder: "Duration...",
            rules: [
              {
                async validator(_, v) {
                  const value = +v;
                  if (isNaN(v)) {
                    return Promise.reject("*Only numbers are allowed");
                  }

                  if (v < 0) {
                    return Promise.reject("*Negative values are not allowed");
                  }

                  return Promise.resolve();
                },
              },
            ],
            addonAfter: (
              <InputComponent
                {...{
                  label: "",
                  type: "select",
                  showArrow: false,
                  showSearch: false,
                  allowClear: false,
                  formItemName: "unit",
                  className: "idle-addon-after-select",
                  dropdownClassName: isDarkMode ? "darkDropDown" : "",
                  customOptions: [
                    { label: "Minutes", value: "m" },
                    { label: "Hours", value: "h" },
                    { label: "Days", value: "d" },
                  ],
                }}
              />
            ),
          }}
        />
      </div>
      <div className="buttons-section">
        <MondayButton
          Icon={<ClearFilterIcon />}
          className="mondayButtonRed"
          onClick={() => {
            onDurationSelect(-1);
          }}
        >
          Clear Filter
        </MondayButton>
        <MondayButton
          Icon={<FilterIcon />}
          className="mondayButtonBlue"
          onClick={filterHandler}
        >
          Filter
        </MondayButton>
      </div>
    </Form>
  );
}

export default IdleDurationPicker;
