import uniq from "lodash/uniq";

const parameterRegex = /((?:Parameters!)\w+)/g;

export const extractDatasetParameters = (datasets = []) =>
  uniq(
    datasets
      .filter(({ Filters }) => !!Filters)
      .flatMap(({ Filters }) =>
        Filters.flatMap(({ FilterValues }) =>
          FilterValues.flatMap((str) => {
            parameterRegex.lastIndex = 0;
            return parameterRegex.exec(str);
          })
        )
      )
  );

export const extractReportParameters = (report = {}) => {
  const { definition = {} } = report;
  const { ReportParameters = [] } = definition;

  return ReportParameters.map((param) => param?.Name);
};
