export const claims = (IdHandler, programFields, updatedDynamicStates) => {
  const data = updatedDynamicStates?.[IdHandler] || [];
  const claimStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Claims;

  return {
    claimStatus: () => ({
      title: "Claims Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Claims",
        field: "claimStatus",
        labels: claimStatus?.map(({ statusName }) => statusName),
        datasets: [
          {
            label: "My First Dataset",
            data: claimStatus?.map(
              (status) => data?.filter((a) => a?.claimStatus === status)?.length
            ),
            backgroundColor: claimStatus?.map(({ statusColor }) => statusColor),
            borderWidth: 1,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
