import { Tooltip } from "antd";
import { ChangeOrderIcon } from "../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import "./ChangeOrderIndicator.scss";

const ChangeOrderIndicator = () => {
  return (
    <Tooltip title="Change Order">
      <div className="changeOrderIndicatorContainer">
        <ChangeOrderIcon />
      </div>
    </Tooltip>
  );
};

export default ChangeOrderIndicator;
