import { Checkbox } from "antd";
import SelectEditor from "./SelectEditor";

const locationsColumns = [
  {
    headerName: "Location",
    field: "location",
  },
  {
    headerName: "Location Match",
    field: "locationMatch",
    editable({ context }) {
      if (!context?.isWritable) {
        context?.showWritableInfo && context.showWritableInfo();
      }
      return context?.isWritable;
    },
    cellEditor: SelectEditor,
  },
  {
    headerName: "Is Default Match",
    field: "isDefaultMatch",
    cellStyle: {
      display: "grid",
      placeItems: "center",
    },
    cellRenderer({ value }) {
      return (
        <Checkbox
          defaultChecked={!!value}
          onClick={(e) => {
            e.preventDefault();
            e.target.checked = value;
          }}
        />
      );
    },
  },
];

export default locationsColumns;
