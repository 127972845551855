import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import { DayStatus } from "../../../../../../Scheduling/Subcomponents/DayStatusChangeModal/DayStatuses";
import ScheduleDay from "../../../../../../Scheduling/models/ScheduleDay";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

/**
 * Adds a new schedule day to the selected schedule.
 *
 * @param {number} dayToAddIndex - The index where the new day should be added.
 * @param {Date} suggestedStartDate - The suggested start date for the new day.
 * @param {Date} suggestedEndDate - The suggested end date for the new day.
 * @param {object} selectedSchedule - The selected schedule object.
 * @returns {array} - The updated schedule days array.
 */
function addNewDay(
  dayToAddIndex,
  suggestedStartDate,
  suggestedEndDate,
  selectedSchedule
  // fullWeatherArray
) {
  const scheduleDaysCopy = _.cloneDeep(selectedSchedule?.scheduleDays);
  let newScheduleDay;

  if (suggestedStartDate && suggestedEndDate) {
    newScheduleDay = new ScheduleDay(
      dayToAddIndex + 2,
      dayjsNY(suggestedStartDate),
      dayjsNY(suggestedEndDate)
    );
    newScheduleDay.setWeather([]);
  } else {
    const element = document.getElementById("days-container-scroll");

    if (element) {
      setTimeout(() => {
        element.scrollTop({
          left: 0,
          top: element.scrollHeight + 200,
          behavior: "smooth",
        });
      }, 0);
    }

    const enabledScheduleDays = scheduleDays_copy
      ?.sort?.((a, b) => a?.startDate - b?.startDate)
      ?.filter?.((sch) => sch?.status !== "Postponed");

    newScheduleDay = ScheduleDay.NextScheduleDay(
      {
        id: uuidv4(),
        day: enabledScheduleDays[enabledScheduleDays?.length - 1]?.day + 1,
        startDate: dayjs
          .tz(enabledScheduleDays[enabledScheduleDays?.length - 1]?.startDate)
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 }),
        endDate: dayjs
          .tz(enabledScheduleDays[enabledScheduleDays?.length - 1]?.endDate)
          .set({ hour: 16, minute: 0, second: 0, millisecond: 0 }),
        crews: [],
        fleet: [],
        weather: [],
        image: "",
        color: "",
        status: DayStatus?.Confirmed,
        linkedDays: {
          comesFrom: undefined,
          postponedTo: undefined,
        },
        notes: [],
      },
      []
    );

    let lastFriday = dayjs(
      enabledScheduleDays[enabledScheduleDays?.length - 1]?.startDate
    );
    newScheduleDay = ScheduleDay.NextScheduleDay(
      {
        id: uuidv4(),
        day: enabledScheduleDays[enabledScheduleDays.length - 1]?.day + 1,
        startDate: dayjs
          .tz(`${lastFriday}`)
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
          .add(2, "day"),
        endDate: dayjs
          .tz(`${lastFriday}`)
          .set({ hour: 16, minute: 0, second: 0, millisecond: 0 })
          .add(2, "day"),
        crews: [],
        fleet: [],
        weather: [],
        image: "",
        color: "",
        status: DayStatus?.Confirmed,
        linkedDays: {
          comesFrom: undefined,
          postponedTo: undefined,
        },
        notes: [],
      },
      []
    );
  }
  scheduleDaysCopy.splice(dayToAddIndex + 1, 0, newScheduleDay);

  scheduleDaysCopy?.forEach?.((day, index) => {
    day.day = index + 1;
  });

  return scheduleDaysCopy;
}

export default addNewDay;
