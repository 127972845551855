import types from "../../types";

const initialState = {
  programFields: undefined,
};

const programFieldsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PROGRAM_FIELDS:
      return {
        ...state,
        programFields: payload,
      };

    case types.SET_PROGRAM_FIELD_BY_NAME:
      return {
        ...state,
        programFields: state.programFields.map((progField) => {
          if (progField.fieldName === payload.fieldName) {
            return {
              ...progField,
              fieldOptions: payload.fieldOptions,
            };
          } else return progField;
        }),
      };

    case types.SET_PROGRAM_FIELD_BY_ID:
      return {
        ...state,
        programFields: state.programFields.map((progField) => {
          if (progField.fieldId === payload.fieldId) {
            return {
              ...progField,
              fieldOptions: payload.fieldOptions,
            };
          } else return progField;
        }),
      };

    default:
      return state;
  }
};

export default programFieldsReducer;
