import React, { useReducer, createContext, useContext } from "react";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { FORMULAS_HOIST_ADDONS } from "../utils/formulasForAddonsPrice";
const initialState = {
  floorDetailsRows: {},
  totalities: {
    priceWithoutTax: 0,
    rentWithoutTax: 0,
    priceWithTax: 0,
    rentWithTax: 0,
    taxAmount: 0,
    taxRate: 0,
  },
};

//Action Types
export const FLOOR_DETAILS_TYPES = {
  INITIALIZE: "INITIALIZE",
  APPLYFORMULA: "APPLYFORMULA",
  UNIT_PRICE_CHANGE: "UNIT_PRICE_CHANGE",
  RENT_CHANGE: "RENT_CHANGE",
  MANUAL_PRICE_CHANGE: "MANUAL_PRICE_CHANGE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case FLOOR_DETAILS_TYPES.INITIALIZE:
      return {
        ...state,
        ...(initializeFloorAddons(action.payload) || {}),
      };
    case FLOOR_DETAILS_TYPES.APPLYFORMULA:
      return {
        ...state,
        value: action.payload,
      };
    case FLOOR_DETAILS_TYPES.UNIT_PRICE_CHANGE: {
      return {
        ...state,
        ...(changeUnitPriceMultiples(action.payload, state) || {}),
      };
    }
    case FLOOR_DETAILS_TYPES.RENT_CHANGE: {
      return {
        ...state,
        ...(changeRentAll(action.payload, state) || {}),
      };
    }
    default:
      return state;
  }
};
const FloorDetailsContext = createContext();

export const useFloorDetailsContext = () => {
  return useContext(FloorDetailsContext);
};
export const FloorDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FloorDetailsContext.Provider value={{ state, dispatch }}>
      {children}
    </FloorDetailsContext.Provider>
  );
};

const initializeFloorAddons = (elevation) => {
  //
  if (elevation?.floorAddonsPriceConfig) {
    return {
      floorDetailsRows:
        elevation?.floorAddonsPriceConfig?.floorDetailsRows || {},
      totalities: elevation?.floorAddonsPriceConfig?.totalities || {},
    };
  }

  let ADDONS_HOLDER = {}; // Structure will be name of addons and properties
  console.log("initializeFloorAddons", elevation);
  let TOTALITIES_HOLDER = {
    priceWithoutTax: 0,
    rentWithoutTax: 0,
    priceWithTax: 0,
    rentWithTax: 0,
    taxAmount: 0,
    taxRate: 0,
  };
  // check the floors of elevations first!

  let floors = elevation?.items || [];

  if (floors.length > 0) {
    floors?.forEach((floor) => {
      let floorLocation = floor?.floor;
      let floorHeight = floor?.floor_height;
      let addons = floor?.addons || [];

      addons.forEach((addon) => {
        let addonname = addon?.addonname || "";
        let properAddon = initializeProperAddonFormat(addonname, addon);
        properAddon.floor = floorLocation;
        properAddon.rentRate = 5;
        TOTALITIES_HOLDER.priceWithoutTax += forceToNumber(
          properAddon?.totalPrice || 0
        );
        TOTALITIES_HOLDER.rentWithoutTax += forceToNumber(
          properAddon?.rent || 0
        );
        if (
          !!ADDONS_HOLDER?.[addonname] &&
          Array.isArray(ADDONS_HOLDER?.[addonname])
        ) {
          ADDONS_HOLDER[addonname].push(properAddon);
        } else {
          ADDONS_HOLDER[addonname] = [properAddon];
        }
      });
    });
  }
  let toReturn = [];
  Object.values(ADDONS_HOLDER).forEach((addonsArr) => {
    if (Array.isArray(addonsArr)) {
      addonsArr.forEach((addon) => {
        toReturn.push(addon);
      });
    }
  });
  console.log("ADDONS_HOLDER", ADDONS_HOLDER);
  return {
    floorDetailsRows: toReturn,
    totalities: {
      ...TOTALITIES_HOLDER,
    },
  };
};

const changeUnitPriceMultiples = (editObjectRefs, currState) => {
  const { editReferences = {}, elevation = {} } = editObjectRefs;
  console.log("changeUnitPriceMultiples", { editReferences, currState });
  let totalities = currState?.totalities || {};
  // First we find all the addons that are affected by this change
  let allAddons = currState?.floorDetailsRows || [];
  if (Array.isArray(allAddons)) {
    allAddons.forEach((addon) => {
      if (
        addon?.name?.toLowerCase() === editReferences?.addonName?.toLowerCase()
      ) {
        addon.unitPrice = editReferences?.newValue;
        addon.totalPrice = forceToNumber(
          applyFormulaFromArray(addon.formula, addon)
        );
      }
    });
  }
  totalities.priceWithoutTax = allAddons.reduce(
    (acc, curr) => acc + forceToNumber(curr?.totalPrice || 0),
    0
  );
  return { floorDetailsRows: allAddons, totalities };
};
const changeRentSingular = (editObjectRefs, currState) => {
  const { editReferences = {}, elevation = {} } = editObjectRefs;
  let totalities = currState?.totalities || {};

  console.log("changeUnitPriceMultiples", { editReferences, currState });
  // First we find all the addons that are affected by this change
  let allAddons = currState?.floorDetailsRows || [];
  if (Array.isArray(allAddons)) {
    allAddons.forEach((addon) => {
      if (
        addon?.name?.toLowerCase() ===
          editReferences?.addonName?.toLowerCase() &&
        addon?.floor === editReferences?.floor
      ) {
        addon.rent = editReferences?.newValue;
        // addon.totalPrice = forceToNumber(
        //   applyFormulaFromArray(addon.formula, addon)
        // );
      }
    });
  }
  totalities.rentWithoutTax = allAddons.reduce(
    (acc, curr) => acc + forceToNumber(curr?.rent || 0),
    0
  );
  return { floorDetailsRows: allAddons, totalities };
};
const changeRentAll = (editObjectRefs, currState) => {
  const { editReferences = {}, elevation = {} } = editObjectRefs;
  let totalities = currState?.totalities || {};

  console.log("changeUnitPriceMultiples", { editReferences, currState });
  // First we find all the addons that are affected by this change
  let allAddons = currState?.floorDetailsRows || [];
  if (Array.isArray(allAddons)) {
    allAddons.forEach((addon) => {
      if (
        addon?.name?.toLowerCase() === editReferences?.addonName?.toLowerCase()
        // addon?.floor === editReferences?.floor
      ) {
        addon.rentRate = editReferences?.newValue;
        // addon.totalPrice = forceToNumber(
        //   applyFormulaFromArray(addon.formula, addon)
        // );
      }
    });
  }
  console.log("allAddons", allAddons);
  totalities.rentWithoutTax = allAddons.reduce(
    (acc, curr) => acc + forceToNumber(curr?.rent || 0),
    0
  );
  return { floorDetailsRows: allAddons, totalities };
};

const initializeProperAddonFormat = (addonname, addon) => {
  if (!!FORMULAS_HOIST_ADDONS?.[addonname]) {
    let keys = Object.keys(FORMULAS_HOIST_ADDONS[addonname]) || [];
    let result = {};
    result.name = addonname;
    keys.forEach((key) => {
      result[key] = addon?.[key] || FORMULAS_HOIST_ADDONS[addonname][key];
    });
    if (!!result?.formula && result?.formula?.length > 0) {
      result.totalPrice = forceToNumber(
        applyFormulaFromArray(result.formula, result)
      );
    }
    return result;
  } else {
    addon.name = addonname;
    return addon;
  }
};

const applyFormulaFromArray = (formulaInArr, addon) => {
  let formulaToEval = "";
  const objToExplore = structuredClone(addon);
  formulaInArr?.forEach((item) => {
    if (
      item !== "+" &&
      item !== "-" &&
      item !== "*" &&
      item !== "/" &&
      item !== "(" &&
      item !== ")"
    ) {
      let findItem;
      Object.keys(objToExplore).forEach((key) => {
        if (key?.toLowerCase() === item?.toLowerCase()) {
          findItem = objToExplore[key];
        }
      });

      if (!!findItem) {
        formulaToEval += forceToNumber(findItem || 0);
      } else {
        if (typeof parseFloat(item) === "number") formulaToEval += item;
      }
    } else {
      formulaToEval += item;
    }
  });
  console.log("formulaToEval", formulaToEval);
  if (formulaToEval.length > 0) {
    try {
      let res = forceToNumber(eval(formulaToEval));
      return res;
    } catch (e) {
      console.error("error in applyFormulaFromArray", e);
      return 0;
    }
  } else {
    return 0;
  }
};
