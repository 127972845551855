import React from "react";
import { Stepper } from "../../../commonComponents";
import "./ProposalBodyStepper.scss";
function ProposalBodyStepper() {
  return (
    <div
      style={{
        marginTop: "20px",
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      {" "}
      <Stepper
        current={0}
        items={[
          {
            title: "Drafts",
            // description: "This is a description.",
          },
          {
            title: "Action Required",
            // description,
          },
          {
            title: "Waiting for Client",
            // description,
          },
          {
            title: "Finalized",
            // description,
          },
          {
            title: "To Be Approved",
            // description,
          },
          {
            title: "Approved",
            // description,
          },
        ]}
        stepRenderer={false}
        type="navigation"
        componentContainerClassName="proposalBuilderWrapper"
      />
    </div>
  );
}

export default ProposalBodyStepper;
