import React from "react";
import {Checkbox} from 'antd';

export class CheckboxRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.value,
    }
  }

  handleCheck = (e) => {
    this.setState({checked: e.target.checked});
    this.props.node.setDataValue(this.props.column.colId, e.target.checked);
    
  }

  render() {
    return (
      <Checkbox
        checked={this.state.checked}
      />
    )
  }
}