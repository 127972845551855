import React, { useState } from "react";
import "./ProposalDocumentMerger.scss";
import { NormalSizedModal } from "../../../../SidebarPages/Fleet/components";
import { MondayButton } from "../../../../commonComponents";
import MergePdfs from "../MergePdfs/MergePdfs";
import { MergeIcon } from "./assets";
import { useSelector } from "react-redux";

function ProposalDocumentMerger() {
  const [visible, setVisible] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => setVisible(true)}
        Icon={<MergeIcon />}
      >
        Document Merger
      </MondayButton>
      {visible && (
        <NormalSizedModal
          {...{
            className: `firstProposalPageModal${isDarkMode ? "Dark" : ""}`,
            visible,
            setVisible,
            title: "Document Merger",
            closable: true,
            centered: true,
          }}
        >
          <MergePdfs />
        </NormalSizedModal>
      )}
    </>
  );
}

export default ProposalDocumentMerger;
