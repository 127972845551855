export const attachPriceSchemes = (data, priceSchemes) => {
  console.log("attachPriceSchemes", data, priceSchemes);
  // Check if data is valid structurally & we can iterate
  if (!Array.isArray(data) || !Array.isArray(priceSchemes)) return data;
  for (const service of data) {
    // Make sure service doesn't have a manually configured priceScheme.
    if (!service?.priceScheme) {
      const applicableScheme =
        priceSchemes.find(
          (ps) => ps?.serviceId?.toString() === service?.serviceId?.toString()
        ) || false;
      if (applicableScheme) {
        // Check if the service has a priceSchemeType default configured.
        const defaultPriceSchemeId =
          applicableScheme?.pricingObject?.defaultPriceSchemeId;
        if (!!defaultPriceSchemeId) {
          // we now find the default price scheme
          const defaultPriceScheme =
            applicableScheme?.pricingObject?.priceSchemes?.find(
              (scheme) =>
                scheme?.id?.toString() === defaultPriceSchemeId?.toString()
            );
          if (!!defaultPriceScheme) {
            service.priceScheme = defaultPriceScheme;
          }
        }
      }
    }
    // return service;
  }
  console.log("what i am returning", data);
  return data;
};
