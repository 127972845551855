import { DeleteIcon } from "../../../Roles/src";

export const DeleteReportTemplate = ({ data, setConfirm }) => {
  return (
    <>
      <DeleteIcon
        style={{ cursor: "pointer" }}
        fill="#FE4C4A"
        onClick={() => {
          setConfirm(data);
        }}
      />
    </>
  );
};
