import React from "react";
import { MondayButton } from "../../../../commonComponents";
import { SwitcherOutlined } from "@ant-design/icons";
import { Badge, Button, Popover, Switch } from "antd";
import "./SwitchSchedules.scss";
import { typeOfWorkColors } from "../DataAgGrid/columnDefs";
import Swal from "sweetalert2";
import { CompareIcon } from "../../../../SidebarPages/Estimations/components/EstVersionsTab/assets";
import { DynamicTable } from "../../../../commonComponents/DynamicTable/DynamicTable";
import { RedirectIcon } from "../../../../../icons";
const SwitchSchedules = ({
  switchSchedules = () => {},
  schedules = [],
  rowData = {},
  ableToSave = false,
}) => {
  const rowDataTable = schedules?.map((a) => {
    return {
      scheduleName: {
        value: a?.scheduleName?.trim(),
        onClick: () => {
          if (ableToSave !== true) {
            switchSchedules(a?.scheduleId);
          } else {
            Swal.fire({
              title: "Are you sure?",
              text: "You have unsaved changes!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, discard changes and leave!",
            }).then((result) => {
              if (result.isConfirmed) {
                switchSchedules(a?.scheduleId);
              }
            });
          }
        },

        style: {
          paddingLeft: 5,
          paddingRight: 5,
          width: "fit-content",
          fontWeight: 600,
          cursor: "pointer",
          border: `1px solid #DDE3E5`,
          color: "#0f5c97",
        },
      },
      typeOfWork: {
        value: a?.typeOfWork,
        style: {
          paddingLeft: 5,
          paddingRight: 5,
          width: "fit-content",
          fontWeight: 600,
          border: "1px solid #DDE3E5",
          color: typeOfWorkColors?.[a?.typeOfWork],
        },
      },
    };
  });

  return (
    <div>
      <Popover
        title={null}
        // title={`You are currently in ${rowData?.scheduleName} - ${rowData?.typeOfWork}`}
        trigger={"click"}
        content={
          <>
            {schedules?.length > 0 ? (
              <DynamicTable
                {...{
                  columnDefs: [
                    {
                      headerName: "Switch to",
                      field: "scheduleName",
                    },
                    {
                      headerName: "Type of Work",
                      field: "typeOfWork",
                    },
                  ],
                  rowData: rowDataTable,
                  tableHeaderTr: {
                    textAlign: "left",
                  },
                  tableStyle: {
                    width: "100%",
                    border: "1px solid #DDE3E5",
                    borderCollapse: "collapse",
                  },
                  tableHeaderStyle: {
                    border: "1px solid #DDE3E5",
                    background: "#0f5c97",
                    paddingLeft: 5,
                    color: "#fff",
                  },
                }}
              />
            ) : (
              // </div>
              // <div
              //   style={{
              //     display: "flex",
              //     flexDirection: "column",
              //     gap: 10,
              //     height: "fit-content",
              //     maxHeight: 800,
              //     flexWrap: "wrap",
              //   }}
              // >
              //   {schedules?.map((item, index) => (
              //     <div
              //       style={{
              //         display: "flex",
              //         alignItems: "center",
              //         gap: 15,
              //       }}
              //     >
              //       <MondayButton
              // onClick={() => {
              //   if (ableToSave !== true) {
              //     switchSchedules(item?.scheduleId);
              //   } else {
              //     Swal.fire({
              //       title: "Are you sure?",
              //       text: "You have unsaved changes!",
              //       icon: "warning",
              //       showCancelButton: true,
              //       confirmButtonColor: "#3085d6",
              //       cancelButtonColor: "#d33",
              //       confirmButtonText:
              //         "Yes, discard changes and leave!",
              //     }).then((result) => {
              //       if (result.isConfirmed) {
              //         switchSchedules(item?.scheduleId);
              //       }
              //     });
              //   }
              // }}
              //         {...{
              //           Icon: <SwitcherOutlined />,
              //           key: index,
              //           className: "mondayButtonBlue",
              //         }}
              //       >{`Switch to ${item?.scheduleName}`}</MondayButton>
              //       <span
              //         style={{
              //           background: typeOfWorkColors?.[item?.typeOfWork],
              //           borderRadius: 5,
              //           padding: 5,
              //           color: "#fff",
              //           fontWeight: 600,
              //           textTransform: "uppercase",
              //           fontSize: 12,
              //           border: "none",
              //         }}
              //       >
              //         {item?.typeOfWork}
              //       </span>
              //     </div>
              //     // <Button
              //     //   key={index}
              //     // onClick={() => {
              //     //   if (ableToSave !== true) {
              //     //     switchSchedules(item?.scheduleId);
              //     //   } else {
              //     //     Swal.fire({
              //     //       title: "Are you sure?",
              //     //       text: "You have unsaved changes!",
              //     //       icon: "warning",
              //     //       showCancelButton: true,
              //     //       confirmButtonColor: "#3085d6",
              //     //       cancelButtonColor: "#d33",
              //     //       confirmButtonText: "Yes, discard changes and leave!",
              //     //     }).then((result) => {
              //     //       if (result.isConfirmed) {
              //     //         switchSchedules(item?.scheduleId);
              //     //       }
              //     //     });
              //     //   }
              //     // }}
              //     //   className="switchSchedulesPopoverButton"
              //     //   style={{
              //     //     backgroundColor: typeOfWorkColors?.[item?.typeOfWork],
              //     //     color: "#fff",
              //     //   }}
              //     // >
              //     // Go to  {item?.scheduleName} - {item?.typeOfWork}
              //     // </Button>
              //   ))}
              // </div>
              <span style={{ fontWeight: 600, color: "red" }}>
                There are no other schedules for this project!
              </span>
            )}
          </>
        }
        overlayClassName="switchSchedulesPopover"
      >
        <MondayButton
          {...{
            className: "mondayButtonYellow",
            Icon: <CompareIcon />,
          }}
        >
          Switch Schedules
        </MondayButton>
      </Popover>
    </div>
  );
};

export default SwitchSchedules;
