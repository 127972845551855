import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useProgramFields } from "../../../../../hooks";
import { RequestModalContext, useRequestModalContext } from "../store";

import {
  claimRequestFields,
  dispatchRequestFields,
  documentationRequestFields,
  estimationRequestFields,
  fleetInventoryRequestFields,
  incidentRequestFields,
  opportunityRequestFields,
  permitDrawingRequestFields,
  projectRequestFields,
  safetyInspectionRequestFields,
  scheduleChangeRequestFields,
  schedulingRequestFields,
  serviceInspectionRequestFields,
  workOrderRequestFields,
  changeOrderRequestFields,
} from "./formData";

import { onTeamSelect } from "./fieldSelectHandlers";

/**
 * Custom hook that returns the request fields based on the provided request type.
 *
 * @param {string} requestType - The type of the request.
 * @returns {Object} An object containing the request fields.
 */
const useRequestFields = ({ requestType = "" }) => {
  // We access the data that we need from the context
  const {
    requestData: {
      fetchedData = {},
      selectedTeam = [],
      BIN = [],
      selectedMainField = {},
      ...requestData
    },
    currentPageKey,
    inputFields: { [requestType]: inputFields = [] },
    ...rest
  } = useRequestModalContext();

  const { userConfiguration = {} } = useSelector((state) => state.userConfig);

  const {
    ["Project Managers"]: projectManagers,
    ["Safety Inspections"]: safetyInspectionCategories,
    ["Inspection Types"]: inspectionTypes,
  } = useProgramFields();

  const requestFieldFunctions = useCallback(
    (params) => {
      // We assign the necessary parameters to the params object
      Object.assign(params, {
        onTeamSelect: (_, data, clear, action) =>
          onTeamSelect({
            data,
            clear,
            action,
            selectedTeam,
            getFieldValue: params.getFieldValue,
            dispatchRequestData: rest?.dispatchRequestData,
          }),

        userConfiguration,
        projectManagers,
        inputFields,
      });

      return {
        "Permit Drawing": () => {
          return permitDrawingRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
          });
        },
        Estimation: () => {
          return estimationRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
            BIN,
          });
        },
        Scheduling: () => {
          return schedulingRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
          });
        },
        Dispatch: () => {
          return dispatchRequestFields({ ...rest, ...fetchedData, ...params });
        },
        "Safety Inspection": () => {
          return safetyInspectionRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
            safetyInspectionCategories,
            safetyOptions: requestData?.safetyOptions,
          });
        },
        Project: () => {
          return projectRequestFields({ ...rest, ...fetchedData, ...params });
        },
        Claim: () => {
          return claimRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
            safetyOptions: requestData?.safetyOptions,
          });
        },
        Incident: () => {
          return incidentRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
            safetyOptions: requestData?.safetyOptions,
          });
        },
        "Service Inspection": () => {
          return serviceInspectionRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
            inspectionTypes,
            inspectionOptions: requestData?.inspectionOptions,
          });
        },
        "Schedule Change": () => {
          return scheduleChangeRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
          });
        },
        Documentation: () => {
          return documentationRequestFields({
            ...rest,
            ...params,
            fetchedData,
            selectedMainField,
          });
        },
        Opportunity: () => {
          return opportunityRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
          });
        },
        "Work Order": () => {
          return workOrderRequestFields({ ...rest, ...fetchedData, ...params });
        },
        "Fleet Inventory": () => {
          return fleetInventoryRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
          });
        },
        "Change Order": () => {
          return changeOrderRequestFields({
            ...rest,
            ...fetchedData,
            ...params,
          });
        },
      };
    },
    [
      requestType,
      JSON.stringify(fetchedData),
      JSON.stringify(inputFields),
      JSON.stringify(selectedTeam),
      BIN,
    ]
  );

  try {
    if (!requestFieldFunctions({})?.[requestType])
      return { requestFields: () => [] };

    const requestFields = (params) => {
      return requestFieldFunctions(params)?.[requestType]();
    };

    return { requestFields };
  } catch (err) {
    console.log(err);
    return { requestFields: () => [] };
  }
};

export default useRequestFields;
