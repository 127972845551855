import dayjs from "dayjs";
import { getInfoForRentalServices } from "../../../../utilities";
import { categoriesTypeKey } from "../../../utils/constants";

export const updateGroupTablesColumnDefs = (columnDefs, selectedCategory) => {
  //header name of column def to remove/add
  const headerName = "SOV No.";
  if (selectedCategory === "applications") {
    //Index of column def to removed or added
    const indexOfEl = columnDefs.findIndex(
      (el) => el.headerName === headerName
    );
    //If column def doesn't already exists then add it
    if (!(indexOfEl >= 0))
      columnDefs = [
        ...columnDefs.splice(0, columnDefs.length - 1),
        {
          headerName,
          field: "SOVNo",
          cellRendererFramework: ({ value }) => (
            <div style={{ textAlign: "center" }}>{value}</div>
          ),
        },
        columnDefs[columnDefs.length - 1],
      ];
    //If the selected category is not application then remove SOV No. column def
  } else {
    const indexToRemove = columnDefs.findIndex((el) => {
      return el.headerName === headerName;
    });
    if (indexToRemove >= 0)
      columnDefs = [
        ...columnDefs.slice(0, indexToRemove),
        ...columnDefs.slice(indexToRemove + 1, columnDefs.length),
      ];
  }
  /*** Removing/adding STATUS column def ***/
  //Getting the categories that have this property
  const catTypesThatHaveStatus = Object.keys(categoriesTypeKey);
  const indexOfStatus = columnDefs.findIndex(
    (el) => el.headerName === "STATUS"
  );
  //Checking if the selected status is in the categories that have STATUS
  //and if status column def is not already in the column defs
  //if the above is true then add STATUS column def in column defs
  if (catTypesThatHaveStatus.includes(selectedCategory) && indexOfStatus < 0) {
    columnDefs = [
      ...columnDefs.slice(0, 3),
      {
        headerName: "STATUS",
        field: "status",
        cellRendererFramework: (props) => (
          <div style={{ textAlign: "center" }}>{props.value}</div>
        ),
      },
      ...columnDefs.slice(3, columnDefs.length),
    ];
    //If not then remove STATUS from column defs
  } else {
    if (indexOfStatus > -1) {
      columnDefs = [
        ...columnDefs.slice(0, indexOfStatus),
        ...columnDefs.slice(indexOfStatus + 1, columnDefs.length),
      ];
    }
  }
  if (selectedCategory === "rentals") {
    columnDefs = [
      ...columnDefs.slice(0, columnDefs.length - 2),
      {
        headerName: "Start Period",
        field: "services",
        maxWidth: 150,
        cellRendererFramework: ({ data }) => {
          const information = getInfoForRentalServices(data);
          const maxStartDate = Object.keys(information)?.reduce((prev, key) => {
            const startDate = information[key]?.reduce(
              (prev, el) => (el?.startDate < prev ? el?.startDate : prev),
              information[key][0]?.startDate
            );
            return startDate < prev ? startDate : prev;
          }, information?.[Object.keys(information)?.[0]]?.[0]?.startDate);
          return (
            <div className="cellContainer">
              {dayjs(maxStartDate).format("MM/DD/YYYY")}
            </div>
          );
        },
      },
      {
        headerName: "End Period",
        field: "services",
        maxWidth: 150,
        cellRendererFramework: ({ data }) => {
          const information = getInfoForRentalServices(data);
          const maxEndDate = Object.keys(information)?.reduce((prev, key) => {
            const endDate = information[key]?.reduce(
              (prev, el) => (el?.endDate > prev ? el?.endDate : prev),
              information[key][0]?.endDate
            );
            return endDate > prev ? endDate : prev;
          }, information?.[Object.keys(information)?.[0]]?.[0]?.endDate);
          return (
            <div className="cellContainer">
              {dayjs(maxEndDate).format("MM/DD/YYYY")}
            </div>
          );
        },
      },
      ...columnDefs.slice(columnDefs.length - 2, columnDefs.length),
    ];
  } else
    columnDefs = [
      ...columnDefs?.filter(
        (col) =>
          !(
            col?.headerName === "Start Period" ||
            col?.headerName === "End Period"
          )
      ),
    ];

  if (selectedCategory === "charges") {
    columnDefs = [
      ...columnDefs.slice(0, columnDefs.length - 2),
      {
        headerName: "Category From",
        field: "categoryFrom",
        maxWidth: 150,
        cellRendererFramework: ({ value: categoryFrom, data }) => {
          return (
            <div className="cellContainer">
              {categoryFrom} {data?.categoryNumber}
            </div>
          );
        },
      },
      ...columnDefs.slice(columnDefs.length - 2, columnDefs.length),
    ];
  } else
    columnDefs = columnDefs?.filter(({ field }) => field !== "categoryFrom");

  return [...columnDefs];
};
