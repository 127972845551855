import Decimal from "decimal.js-light";
import { checkIfServiceIsHoist } from "../../../../../../../Estimations/DataEntryGrid/models/Service";

Decimal.config({ precision: 32 });

export const getErrors = (scheduleOfValue) => {
  // Get errors for each service
  const serviceErrors = scheduleOfValue?.services.map((service) => {
    if (checkIfServiceIsHoist(service)) {
      return {
        service: `${service.label}/${service.estimationId}`,
        label: "Hoist",
        elevations: service?.serviceOptions?.[0]?.map((serviceOption) => {
          const eventsError = serviceOption?.amounts?.some(
            (amount) => !amount.name
          ); // True if any event name is empty
          const sumOfRatesError = serviceOption?.amounts?.reduce(
            (acc, cur) =>
              new Decimal(acc).plus(new Decimal(Number(cur?.rate).toFixed(2))),
            new Decimal(0)
          ); // True if sum of rates exceeds 100%
          console.log("sumOfRatesError2", sumOfRatesError);
          const differenceWarning =
            serviceOption?.amounts?.length > 0 &&
            serviceOption.amounts?.[serviceOption.amounts?.length - 1]
              .difference > 0
              ? true
              : false; // True if difference has more amounts left
          const differenceError =
            serviceOption?.amounts?.length > 0 &&
            serviceOption.amounts?.[serviceOption.amounts?.length - 1]
              .difference < 0
              ? true
              : false; // True if difference is out of balance

          // Return object with errors for each service
          return {
            elevationId: serviceOption?.elevationId,
            eventsError,
            sumOfRatesError,
            differenceWarning,
            differenceError,
          };
        }),
      };
    } else {
      console.log("service", service);
      const eventsError = service.amounts?.some((amount) => !amount.name); // True if any event name is empty
      const sumOfRatesError = service.amounts?.reduce(
        (acc, cur) =>
          new Decimal(acc).plus(new Decimal(Number(cur.rate).toFixed(2))),
        new Decimal(0)
      ); // True if sum of rates exceeds 100%

      const differenceWarning =
        service.amounts?.length > 0 &&
        service.amounts?.[service.amounts?.length - 1].difference > 0
          ? true
          : false; // True if difference has more amounts left
      const differenceError =
        service.amounts?.length > 0 &&
        service.amounts?.[service.amounts?.length - 1].difference < -0.01
          ? true
          : false; // True if difference is out of balance

      // Return object with errors for each service
      return {
        service: `${service.label}/${service.estimationId}`,
        eventsError,
        sumOfRatesError,
        differenceWarning,
        differenceError,
      };
    }
  });

  // Initialize an empty object for errors of all services
  let errors = {};
  serviceErrors?.forEach((serviceError) => {
    // Initialize an empty object for errors of this service
    let error = {};

    if (serviceError?.label) {
      serviceError?.elevations?.forEach((elevation) => {
        console.log("elevation", elevation);
        if (elevation.eventsError) {
          error[`${elevation?.elevationId}/event`] = "Name must not be empty";
        }
        if (elevation.sumOfRatesError > 100.5) {
          error[`${elevation?.elevationId}/sumOfRates`] =
            "Rates should not exceed 100%";
        }
        if (elevation.differenceWarning) {
          error[`${elevation?.elevationId}/differenceWarning`] =
            "You still have some amounts left";
        }
        if (elevation.differenceError) {
          error[`${elevation?.elevationId}/differenceError`] =
            "You are out of balance";
        }
      });
    } else {
      if (serviceError.eventsError) {
        error.event = "Name must not be empty";
      }
      if (serviceError.sumOfRatesError > 100.5) {
        error.sumOfRates = "Rates should not exceed 100%";
      }
      if (serviceError.differenceWarning) {
        error.differenceWarning = "You still have some amounts left";
      }
      if (serviceError.differenceError) {
        error.differenceError = "You are out of balance";
      }
    }

    // Add errors of this service to errors object of all services. If this service doesnt have any errors it will be empty object
    errors = {
      ...errors,
      [serviceError.service]: {
        ...error,
      },
    };
  });
  console.log("errors", errors);
  return errors;
};
