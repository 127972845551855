import { formatCurrency } from "../../../../../../../../../../../../../../utils"
import { useRedux } from "../../../../../../../../../../../../../../hooks"
import { appliedAmountCalculation } from "../../rentalDetailsFunctions"
import "./ModalHeader.scss"

// import { useStateContext } from "../../../../../../../../../../Context/Context"

import { Tooltip } from "antd"

const ModalHeader = ({ elevationData, allRow, selectedWeek }) => {
  const [unchangingFakeRentals] = useRedux("unchangingFakeRentals")
  const [selectedService] = useRedux("rentalsSelectedService")

  // const { unchangingFakeRentals, selectedService } = useStateContext()
  // const [isWritable ] = useRedux("rentalsIsWritable")
  // const [fakeRentals, setFakeRentals] = useRedux("fakeRentals")
  // const [darkMode ] = useRedux("rentalsDarkMode")
  // const [color ] = useRedux("rentalsColor")
  // const [isAutoSaving ] = useRedux("rentalsIsAutoSaving")

  const { appliedToDays, appliedToPli, difference } = appliedAmountCalculation({
    unchangingFakeRentals,
    selectedService,
    elevationData,
    allRow,
  })

  return (
    <div className="tittleContainerRentalsDetails">
      <div className="tittleContainer">Details</div>
      <div className="informationContainer">
        <div className="textContainer">Applied:</div>
        <div
          className="valueContainer"
          style={appliedToDays === 0 ? { color: "#FE4C4A" } : { color: "#71CF48" }}
        >
          {formatCurrency(appliedToDays)}
        </div>
      </div>

      <div className="informationContainer">
        <div className="textContainer">Applied to Pli:</div>
        <div
          className="valueContainer"
          style={appliedToPli === 0 ? { color: "#FE4C4A" } : { color: "#71CF48" }}
        >
          {formatCurrency(appliedToPli)}
        </div>
      </div>

      <Tooltip
        title={
          difference > 0
            ? "You have unapplied amount!!"
            : difference < 0
            ? "You have applied more amount that in pli!!"
            : ""
        }
      >
        <div className="informationContainer">
          <div className="textContainer">Difference:</div>
          <div
            className="valueContainer"
            style={difference !== 0 ? { color: "#FE4C4A" } : { color: "#71CF48" }}
          >
            {formatCurrency(difference)}
          </div>
          {difference !== 0 && <div className="iconRentalContainer">!</div>}
        </div>
      </Tooltip>
    </div>
  )
}

export default ModalHeader
