/**
 * @param {Array} programFields Programfields array state
 * @param {String} title Title of Table to get panels from programFields
 * @param {Object} [keysToUpdate] object with updated or new keys
 * @param {Array} [keysToRemove] Array with keys to delete current filter
 * @returns {Array} Array with filters object for current table
 */
export const getFiltersObject = (
  programFields,
  title,
  keysToUpdate,
  keysToRemove
) => {
  let arrayToReturn = []; // array to return

  // current array with filters object for given title from programfields
  const filtersArray =
    programFields?.find((prog) => prog.fieldName === "Global Filter Keys")
      ?.fieldOptions[title] || [];

  if (!!keysToUpdate && !Array.isArray(keysToUpdate)) {
    //first we get all keys to update
    const updatedObjects = Object.keys(keysToUpdate).reduce((acc, key) => {
      // then we check if that key exists
      const filterObj = filtersArray.find((obj) => obj.key === key);

      //modify label to display with capitalized letter
      const modifyLabel = key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());

      // if not exist and has key add it
      if (!filterObj && keysToUpdate[key]) {
        acc.push({
          key,
          type: "Dropdown",
          label: modifyLabel,
          value: keysToUpdate[key],
        });
      } // if exists modify it
      else {
        if (filterObj) {
          keysToUpdate[key] &&
            acc.push({ ...filterObj, value: keysToUpdate[key] });
        }
      }
      return acc;
    }, []);

    const mergedArr = updatedObjects.concat(filtersArray).reduce((acc, cur) => {
      const duplicate = acc.find((item) => item.key === cur.key);
      if (!duplicate) {
        acc.push(cur);
      }
      return acc;
    }, []);
    arrayToReturn = mergedArr;
  } else {
    arrayToReturn = filtersArray;
  }

  //if you want to avoid objects with given keys just pass their keys
  if (keysToRemove?.length > 0) {
    const updatedArray = arrayToReturn.filter(
      (item) => !keysToRemove.includes(item.key)
    );
    arrayToReturn = updatedArray;
  }
  return arrayToReturn.filter(Boolean);
};
