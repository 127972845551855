import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Modal, Form, message } from "antd";

import { TickIcon } from "../../../Roles/src";
import { InfoIcon } from "../../../../../../../assets";
import { MondayButton } from "../../../../../../commonComponents";
import { LeftArrow } from "../../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { generateNewLog } from "../../../../../../../hooks/useLogManagement";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { formatMembersOptions } from "../../../CrewTeams/components/crewTeamModalData";
import CustomTransfer from "../../../../../../commonComponents/CustomTransfer/CustomTransfer";
import { forceToNumber } from "../../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

import "./CreateTeamModal.scss";

function CreateTeamModal({
  open,
  onCancel,
  foremanData,
  members,
  refreshTable,
  closeMemberModal,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [allTeams, setAllTeams] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [availableMembers, setAvailableMembers] = useState([]);

  const [form] = Form.useForm();

  function membersList() {
    return availableMembers.flatMap((member) => {
      if (selectedMembers.some((id) => id === member?.crewId)) {
        return {
          crewName: member.crewName,
          crewId: member.crewId,
          employeeId: member.employeeId,
        };
      } else {
        return [];
      }
    });
  }

  function bodyObj(foreman) {
    return {
      crewTeamId: uuidv4(),
      company: foremanData?.accountName,
      crewTeamName: form.getFieldValue("crewTeamName"),
      crewForeman: {
        crewId: foreman?.crewId,
        crewName: foreman?.crewName,
        employeeId: foreman?.employeeId,
      },
      crewMembers: membersList(),
      createdAt: Date.now(),
      createdBy: {
        nameOfUser: userConfiguration.nameOfUser,
        identityId: userConfiguration.identityId,
      },
    };
  }

  function updateMembersForeman(foreman) {
    const tmpMembers = membersList();
    try {
      tmpMembers.forEach((member) => {
        API.put("crews", `/crews/${member?.crewId}`, {
          body: {
            foreman: foreman?.crewId,
          },
        });
      });
    } catch (err) {
      message.error("There was a problem updating members of the team");
      console.log("Error updating members: ", err);
    }
  }

  function createMemberData() {
    const duplicateEmployeeId = members?.find(
      (crewEl) =>
        (crewEl?.employeeId || "")?.trim() === foremanData?.employeeId?.trim()
    );

    const body = {
      crewName: foremanData.crewName,
      employeeId: foremanData?.employeeId,
      employeeRate: forceToNumber(foremanData?.employeeRate),
      salaryType: foremanData?.salaryType,
      employeeNumber: foremanData?.employeeNumber,
      members: membersList(),
      foreman: !!foremanData?.foreman,
      crewPosition: foremanData?.crewPosition || "",
      accountName: foremanData?.accountName || "",
      teamsConfiguration: [],
      createdAt: Date.now(),
      createdBy: userConfiguration?.nameOfUser,
    };

    if (duplicateEmployeeId) {
      message.error("Crew with this Employee Id already exists!");
      return false;
    } else {
      return API.post("crews", "/crews", {
        body,
      })
        .then((res) => res)
        .catch((err) => {
          message.error(
            `There was a problem creating ${foremanData?.crewName}`
          );
          console.log("Error creating foreman: ", err);
          return false;
        });
    }
  }

  function onCreateTeamAndMember() {
    message.loading({
      key: "onCreateTeamAndMember",
      content: "Creating Member and Team...",
      duration: 0,
    });
    try {
      form.validateFields().then(async () => {
        const createdForeman = await createMemberData();

        if (!createdForeman) {
          return;
        }

        const newTeam = bodyObj(createdForeman);

        const exists = allTeams.find(
          ({ crewTeamName }) =>
            crewTeamName.toLowerCase() ===
            form.getFieldValue("crewTeamName").toLowerCase()
        );
        if (!!exists) {
          return message.error({
            key: "onCreateTeamAndMember",
            content: `${exists?.crewTeamName} already exists!`,
            duration: 2,
          });
        }

        API.post("crewTeams", "/crewTeams", {
          body: newTeam,
        }).then((res) => {
          message.success({
            key: "onCreateTeamAndMember",
            content: "Team saved successfully!",
            duration: 2,
          });
          generateNewLog(
            "Create",
            res,
            {
              crewName: "Does not exists",
              company: "Does not exists",
              createdAt: "Does not exists",
              crewTeamName: "Does not exists",
            },
            [{ collapsable: true }]
          );
          updateMembersForeman(createdForeman);
          refreshTable(createdForeman);
          onCancel();
          closeMemberModal();
        });
      });
    } catch (error) {
      message.error({
        key: "onCreateTeamAndMember",
        content: "There was a problem saving this team!",
        duration: 2,
      });
      console.log("Error saving crew team: ", error);
    }
  }

  useEffect(() => {
    if (members?.length) {
      const busyMembers = members
        .filter(({ foreman, members }) => foreman === true && members?.length)
        .reduce((acc, { members }) => {
          return [...acc, ...members];
        }, []);

      setAvailableMembers(formatMembersOptions(members, busyMembers));
    }
  }, [members]);

  useEffect(() => {
    API.get("crewTeams", "/crewTeams").then((res) => setAllTeams(res));
  }, []);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      title="Create a New Crew"
      className={`create-team-modal ${isDarkMode && "create-team-modal-dark"}`}
      footer={[
        <MondayButton
          onClick={onCancel}
          Icon={<LeftArrow />}
          className="mondayButtonBlue"
        >
          Go Back
        </MondayButton>,
        <MondayButton
          onClick={onCreateTeamAndMember}
          Icon={<TickIcon width={17} height={17} />}
        >
          Create
        </MondayButton>,
      ]}
    >
      <section className="info-modal-section">
        <InfoIcon />
        <p>
          Since <span className="bold-text">{foremanData.crewName} </span>
          will lead a crew, you will now enter the crew name and select crew
          members.
        </p>
      </section>
      <Form form={form}>
        <InputComponent
          formItemName={"crewTeamName"}
          required={true}
          label={"Crew Name"}
          placeholder="Enter here..."
          dataTestid={"crew-name-input"}
        />
      </Form>
      <section className="members-selection">
        <CustomTransfer
          {...{
            redSectionTitle: "Available Crew Members",
            greenSectionTitle: "Selected Crew Members",
            dataSource: availableMembers?.flatMap(
              ({ crewId, crewName, disabled = false }) =>
                disabled
                  ? []
                  : {
                      key: crewId,
                      title: crewName,
                    }
            ),
            onChange: (member) => {
              setSelectedMembers(member);
            },
            value: selectedMembers,
          }}
        />
      </section>
    </Modal>
  );
}

export default CreateTeamModal;
