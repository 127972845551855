/**
 *
 * @param {Array} relatedToOptions - the related to options
 * @param {Object} userConfiguration - the user configuration object
 * @returns
 */
function relatedToAccess({ relatedToOptions = [], userConfiguration = {} }) {
  // this executes recursively, if userConfiguration.routeConfig has children it recalls the function to flat all routes to check access
  const buildRouteConfig = (config) => {
    return config.reduce((acc, curr) => {
      if (curr.children) {
        acc = { ...acc, ...buildRouteConfig(curr.children) };
      }
      return {
        ...acc,
        [curr?.title]: curr?.write
          ? curr?.write
          : true && curr?.read
          ? curr?.read
          : true,
      };
    }, {});
  };

  const routeConfig = userConfiguration?.routeConfig
    ? buildRouteConfig(userConfiguration.routeConfig)
    : {};

  const filteredOptions = relatedToOptions?.filter(
    (el) => routeConfig?.[el?.value]
  );

  return filteredOptions;
}

export default relatedToAccess;
