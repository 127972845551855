import { Modal } from "antd";
import { useSelector } from "react-redux";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import MondayButton from "../MondayButton/MondayButton";
import { updateDocumentTitle } from "src/utils";
import RowDataGrid from "./RowDataGrid";

import "./RowDataGridModal.scss";

/**
@param {Boolean} visible - When to show grid data modal
@param {Function} setVisible - Show hide modal fundtion
@param {String} title - Title of modal
@param {Array} rowData - Array with data
@param {Object} propColumnDefs - Should contain only of these keys "avoidKeys" or "columns"  
*/
const RowDataGridModal = ({
  visible,
  setVisible = () => {},
  title,
  rowData = [],
  propColumnDefs,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const handleClose = () => {
    setVisible(false);
    updateDocumentTitle({ newTitle: "Monitoring" }); // reset document title to "Lead Manager";
  };

  return (
    <Modal
      className={`rowData-grid-modal ${
        isDarkMode && "rowData-grid-modal-dark"
      }`}
      title={title || "Row Data Modal"}
      afterOpenChange={(event) => {
        title && event && updateDocumentTitle({ newTitle: title });
      }}
      width="100%"
      centered
      open={visible}
      onCancel={handleClose}
      closeIcon={<XIcon />}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={handleClose}
          >
            Cancel
          </MondayButton>
          <div style={{ display: "flex", gap: "10px" }}></div>
        </div>
      }
    >
      <RowDataGrid {...{ title, rowData, propColumnDefs }} />
    </Modal>
  );
};

export default RowDataGridModal;
