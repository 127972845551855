async function getSubFoldersList(folderId, driveRequest, parentFolderName) {
  let result = [];
  try {
    result = await driveRequest
      .getFilesByFolderId(folderId)
      .then((res) => res.json())
      .then(({ files }) => {
        return files.filter(
          ({ mimeType }) => mimeType === "application/vnd.google-apps.folder"
        );
      });
  } catch (error) {
    console.log("getSubFoldersList", { error, folderId, parentFolderName });
  }
  return result;
}

/**
 * @param {String} folderId is the id of the folder
 * @param {String} email is the email of the user
 * @param {Function} driveRequest is the driveApi
 * @returns a list of the email addresses of the users who have access to the folder
 */
export async function getPermissionList(folderId, email, driveRequest) {
  let result = [];
  try {
    result = await driveRequest
      .permissionList(folderId)
      .then((res) => res.json())
      .then(({ permissions }) => {
        return (
          permissions.find(({ emailAddress }) => emailAddress === email) ||
          "none"
        );
      });
  } catch (error) {
    console.error({ error });
  }

  return result;
}

function findPermission(permissions, email) {
  return (
    permissions.find(({ emailAddress }) => emailAddress === email) || "none"
  );
}

export async function getFolderAccess(
  folderId,
  email,
  parentFolderName,
  setLoading,
  driveRequest,
  prevParentName = "",
  permission = null
) {
  let result = {};
  try {
    setLoading(true);
    let subfolders = [];
    let permissions = {};
    await getSubFoldersList(folderId, driveRequest, parentFolderName)
      .then((subfoldersRes) => {
        subfolders = subfoldersRes;
      })
      .catch((e) => console.error(e));
    if (!permission) {
      await getPermissionList(folderId, email, driveRequest).then(
        (permissionsRes) => {
          permissions = permissionsRes;
        }
      );
    } else {
      permissions = permission;
    }
    result = {
      [parentFolderName]: {
        permissions:
          typeof permissions === "string" ? permissions : permissions?.role,
        path: prevParentName,
        subfolders: {},
        folderId,
        permissionId: typeof permissions === "string" ? "" : permissions?.id,
      },
    };
    if (subfolders.length) {
      await Promise.all(
        subfolders.map(async (subfolder) => {
          const currPermission = findPermission(subfolder?.permissions, email);
          return await getFolderAccess(
            subfolder.id,
            email,
            subfolder.name,
            setLoading,
            driveRequest,
            `${prevParentName}-|-${parentFolderName}-|-`,
            currPermission
          ).then((res) => {
            if (res) {
              result = {
                [parentFolderName]: {
                  ...result[parentFolderName],
                  path: prevParentName,
                  subfolders: {
                    ...result[parentFolderName].subfolders,
                    ...res,
                  },
                },
              };
            }
          });
        })
      ).catch((e) => console.error(e));
    }
  } catch (error) {
    console.error(error);
  }

  return result;
}
