import React, { useState } from "react";
import { LogoutIcon } from "../../../../assets/icons/index";
import { LogoutModal } from "./LogoutModal";
import { WithTooltip } from "../../../commonComponents";

function Logout() {
  const [open, setOpen] = useState(false);
  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="logout">
        <LogoutIcon onClick={() => setOpen(true)} className="header-icon" />
      </WithTooltip>

      {open && (
        <LogoutModal
          warningModalVisible={open}
          setWarningModalVisible={setOpen}
        />
      )}
    </div>
  );
}

export default Logout;
