export const pdfTextFormatter = (history, rowData, params, historyTab) => {
    // console.log("dat11111aa", { history, rowData, params, historyTab })

    const value = rowData?.map((rowData) => {
        return {
            accounts: historyTab === "/accounts"
                ? params.PDF_DOCUMENT_TITLE : rowData?.accountName,
            projects: historyTab === "/projects"
                ? params?.PDF_DOCUMENT_TITLE : rowData?.projectName,
            contacts: historyTab === "/contacts"
                ? params.PDF_DOCUMENT_TITLE : rowData?.contactAccountName,
            projectName: rowData?.projectName,
            jobSiteAddress: rowData?.jobSiteAddress,
            opportunityAddress: rowData?.opportunityAddress,
            scheduleAddress: rowData?.scheduleAddress,
            taskRelatedTo: rowData?.taskRelatedTo,
            contactAccountName: rowData?.contactAccountName
        } || {}
    });
    if (history === "projects" && historyTab !== "/projects") {
        if (value[0]?.projectName !== undefined) return value[0]?.projectName;
        if (value[0]?.jobSiteAddress !== undefined) return value[0]?.jobSiteAddress;
        if (value[0]?.opportunityAddress !== undefined) return value[0]?.opportunityAddress;
        if (value[0]?.scheduleAddress !== undefined) return value[0]?.scheduleAddress;
        if (value[0]?.taskRelatedTo !== undefined) return value[0]?.taskRelatedTo;
    }
    else if (history === "opportunities" || history === "accounts" || history === "contacts" || history === "leads") {
        if (value[0]?.taskRelatedTo !== undefined) return value[0]?.taskRelatedTo;
        if (value[0]?.accounts !== undefined) return value[0]?.accounts
        if (value[0]?.contacts !== undefined) return value[0]?.contacts
    }
    else if (history === "contacts" && historyTab !== '/contacts' || history === "accounts" && historyTab !== "/accounts") {
        if (value[0]?.contactAccountName !== undefined) return value[0]?.contactAccountName;
    }
    else if (value[0]?.accounts === "/accounts" || value[0]?.projects === "/projects" || value[0]?.contacts === "/contacts") {
        return params?.PDF_DOCUMENT_TITLE
    }
    return params?.PDF_DOCUMENT_TITLE;
}

