/**
 * @param {string} s
 * @param {number} len
 */
function getNGrams(s, len) {
  s = " ".repeat(len - 1) + s.toLowerCase() + " ".repeat(len - 1);
  let v = new Array(s.length - len + 1);
  for (let i = 0; i < v.length; i++) {
    v[i] = s.slice(i, i + len);
  }
  return v;
}

/**
 * Gives a similarity approximation index [0 - 1]
 * @param {string} str1
 * @param {string} str2
 * @param {number} [gramSize=2]
 */
function stringSimilarity(str1, str2, gramSize = 2) {
  if (!str1?.length || !str2?.length) {
    return 0.0;
  }

  let s1 = str1.length < str2.length ? str1 : str2;
  let s2 = str1.length < str2.length ? str2 : str1;

  let pairs1 = getNGrams(s1, gramSize);
  let pairs2 = getNGrams(s2, gramSize);
  let set = new Set(pairs1);

  let total = pairs2.length;
  let hits = 0;
  for (let item of pairs2) {
    if (set.delete(item)) {
      hits++;
    }
  }
  return hits / total;
}

export default stringSimilarity;
