import { updateDriveFolders } from "../../../../../../../../Header/forms/ClientModal/utils";

const driveUploadItemsPromise = async ({
  driveUpload,
  newId,
  driveRequest,
}) => {
  const { id, parent } = driveUpload;
  return new Promise(async (resolve, reject) => {
    const ids = await updateDriveFolders({
      folderToBeUpdated: id,
      existingParentId: parent,
      newParentId: newId,
      driveRequest,
    });
    if (!ids) {
      reject({
        error: "Error updating folder",
        upload: driveUpload,
        ids,
      });
    } else {
      console.log({ ids, driveUpload });
      resolve({ ids, upload: driveUpload });
    }
  });
};

export async function moveToCorrectFolder(
  uploads,
  parentId,
  driveRequest,
  failedUploads
) {
  const promiseArray = [];
  if (Array.isArray(uploads) && uploads?.length > 0) {
    uploads.forEach((upload) => {
      const { status, statusAfterUpdate = "" } = upload;
      if (
        (statusAfterUpdate && statusAfterUpdate === "Failed") ||
        status !== "Not in Drive"
      ) {
        promiseArray.push(
          driveUploadItemsPromise({
            driveUpload: upload,
            newId: parentId,
            driveRequest,
          })
        );
      }
    });
  }

  promiseArray.length > 0 &&
    (await Promise.allSettled(promiseArray).then((r) => {
      console.log("r:", { r });
      r.map((el) => {
        console.log("r.map ~ el:", { el });
        if (el.status === "rejected") {
          failedUploads.push(el.reason.upload);
          el.reason.upload.statusAfterUpdate = "Failed";
        } else {
          el.value.upload.statusAfterUpdate = "Fixed";
        }
      });
    }));

  return uploads;
}
