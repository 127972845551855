import { useNavigate } from "react-router-dom";
import { routeData, routeDataObject } from "../HistoryData";

export const useFilteredHistory = (tableName, proppedFilter = {}) => {
  const navigate = useNavigate();

  const historyFilter = (filter = proppedFilter) => {
    navigate(routeData[tableName], {
      state: {
        ...routeDataObject[tableName],
        chartFilter: { ...filter },
      },
    });
  };
  return historyFilter;
};
