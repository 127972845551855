import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const approvals = ({ programFields, tables, userConfiguration }) => {
  const reducedUsers = (userConfiguration?.allUsers?.Items || [])?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr?.cognitoUserId]: curr,
    }),
    {}
  );
  return {
    userRequesting: () => {
      const data = repeatedElementSum(
        tables?.Approvals || [],
        "userRequesting"
      );

      return {
        labels: Object.keys(data)?.map(
          (key) => reducedUsers?.[key]?.nameOfUser || key
        ),

        data: Object.values(data),

        backgroundColor: Object.keys(data).map(() => getRandomColor()),
      };
    },

    assignedTo: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "assignedTo");

      return {
        labels: Object.keys(data)?.map(
          (key) => reducedUsers?.[key]?.nameOfUser || key
        ),

        data: Object.values(data),

        backgroundColor: Object.keys(data).map(() => getRandomColor()),
      };
    },

    category: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "category");

      return {
        labels: Object.keys(data)?.map((el) => el?.capitalize() || el),

        data: Object.values(data),

        backgroundColor: Object.keys(data).map(() => getRandomColor()),
      };
    },

    responseFrom: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "responseFrom");

      return {
        labels: Object.keys(data)?.map((el) => el || "No Response"),

        data: Object.values(data),

        backgroundColor: Object.keys(data).map(() => getRandomColor()),
      };
    },

    requestType: () => {
      const data = repeatedElementSum(tables?.Approvals || [], "requestType");

      const statusColors =
        programFields?.find((el) => el?.fieldName === "Status colors")
          ?.fieldOptions?.Approvals?.requestType || [];

      return {
        labels: Object.keys(data),

        data: Object.values(data),

        backgroundColor: Object.keys(data).map(
          (el) =>
            statusColors?.find((a) => a?.statusName === el)?.statusColor ||
            getRandomColor()
        ),
      };
    },
  };
};
