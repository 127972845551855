/**
 * Function that retrieves the data of the last row of a sheet
 * @param {Object} data
 * @param {string|Number} data.lastRow
 * @param {string} data.sheetName
 * @param {string} data.spreadsheetId
 * @param {string} data.googleAccessToken
 */
async function checkDriveLastRow({
  lastRow,
  sheetName,
  spreadsheetId,
  googleAccessToken,
}) {
  if (!window?.gapi) {
    return;
  }

  return await gapi.client.sheets.spreadsheets.values
    .get({
      spreadsheetId,
      range: `'${sheetName}'!${lastRow}:${lastRow}`,
      dateTimeRenderOption: "SERIAL_NUMBER",
      majorDimension: "ROWS",
      valueRenderOption: "FORMATTED_VALUE",
      access_token: googleAccessToken,
    })
    .then(({ result = {} }) => {
      return result?.values || [];
    });
}

export default checkDriveLastRow;
