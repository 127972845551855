import types from "../../types";

const initialState = {
  topicCategories: [],
};

const topicCategoriesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_TOPIC_CATEGORIES:
      return {
        ...state,
        topicCategories: payload,
      };
    case types.SET_TOPIC_CATEGORIES_BY_ID: {
      const updatedCategories = state.topicCategories.map((category) =>
        category.categoryId === payload.categoryId
          ? { ...category, ...payload }
          : category
      );

      return {
        ...state,
        topicCategories: updatedCategories,
      };
    }

    default:
      return state;
  }
};

export default topicCategoriesReducer;
