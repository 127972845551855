import { Card, Modal } from "antd";
import React from "react";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import "./ProgressCrews.scss";
const ProgressCrews = ({ viewCrews = {}, onCancel = () => {} }) => {
  const { visible, crews } = viewCrews || {};

  return (
    <div>
      <Modal
        {...{
          open: visible,
          centered: true,
          title: "Crews",
          onCancel,
          closeIcon: <XIcon />,
          width: "fit-content",
          maxWidth: 1200,
          className: "progress-crews-track-modal",
          footer: (
            <MondayButton
              onClick={onCancel}
              {...{ className: "mondayButtonRed", Icon: <XIcon /> }}
            >
              Close
            </MondayButton>
          ),
        }}
      >
        <div
          style={{ display: "flex", gap: 10, flexWrap: "wrap", width: "100%" }}
        >
          {Object.entries(crews || {})?.map(([key, val]) => {
            return (
              <Card title={key} className="crew-progress-card">
                <div>
                  {val?.map((item) => {
                    return (
                      <ul>
                        <li>{item?.crewName}</li>
                      </ul>
                    );
                  })}
                </div>
              </Card>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default ProgressCrews;
